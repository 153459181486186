<button
  *ngIf="appConfigs?.length"
  class="nav-item-menu-button"
  type="button"
  mat-icon-button
  [attr.aria-label]="'cohesityApps' | translate"
  cogDataId="app-pillars-switcher"
  [matTooltip]="'cohesityApps' | translate"
  [matMenuTriggerFor]="pillarMenu">
  <cog-icon shape="apps"></cog-icon>
</button>
<mat-menu #pillarMenu="matMenu" cogDataId="app-pillars-menu" class="app-pillars-switcher-menu-panel">
  <ng-container *ngFor="let pillar of apps">
    <mat-divider *ngIf="pillar.name === 'protection'"></mat-divider>
    <a
      mat-menu-item
      class="app-pillar-menu-item"
      *ngIf="!pillar.apps"
      [href]="getAppHref(pillar)"
      (click)="goto(pillar, $event)"
      id="{{ pillar.name }}-nav-anchor"
      [inheritDataId]="true"
      [cogDataId]="'item-' + pillar.name">
      <cog-icon [shape]="pillar.icon"></cog-icon>
      <span>{{ pillar.name | translate }}</span>
    </a>
    <button
      mat-menu-item
      class="app-pillar-menu-item"
      *ngIf="pillar.apps?.length"
      [matMenuTriggerFor]="appMenu"
      [matMenuTriggerData]="pillar"
      [inheritDataId]="true"
      [cogDataId]="'item-' + pillar.name">
      <cog-icon [shape]="pillar.icon"></cog-icon>
      <span>{{ pillar.name | translate }}</span>
    </button>
  </ng-container>
</mat-menu>
<mat-menu #appMenu="matMenu" cogDataId="app-pillars-sub-menu" class="app-pillars-switcher-menu-panel">
  <ng-template matMenuContent let-subApps="apps">
    <a
      mat-menu-item
      class="app-pillar-menu-item"
      *ngFor="let app of subApps"
      [href]="getAppHref(app)"
      (click)="goto(app, $event)"
      id="{{ app.name }}-nav-anchor"
      [inheritDataId]="true"
      [cogDataId]="'item-' + app.name">
      {{ app.name | translate }}
    </a>
  </ng-template>
</mat-menu>
