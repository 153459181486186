import { CommonModule } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import {
  MatLegacyColumnDef as MatColumnDef,
  MatLegacyTable as MatTable,
  MatLegacyTableModule as MatTableModule,
} from '@angular/material/legacy-table';
import { IrisContextService, flagEnabled, isDGaasScope } from '@cohesity/iris-core';
import { AutoDestroyable } from '@cohesity/utils';
import { TranslateModule } from '@ngx-translate/core';
import { get } from 'lodash';

import { RbacModule } from '@cohesity/data-govern/shared';
import { ChatPromptToggleComponent } from './chat-prompt-toggle';

@Component({
  standalone: true,
  selector: 'dg-ar-ai-bot-column',
  templateUrl: './ai-bot-column.component.html',
  styleUrls: ['./ai-bot-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ChatPromptToggleComponent,
    CommonModule,
    MatMenuModule,
    MatTableModule,
    RbacModule,
    TranslateModule,
  ],
})
export class AiBotColumnComponent extends AutoDestroyable implements OnInit, AfterViewChecked {

  /**
   * The position in which the tenant columns needs to be inserted.
   * Default is the second column in the table.
   */
  @Input() position?: number;

  /**
   * The template reference for the organization column.
   */
  @ViewChild(MatColumnDef, { static: true }) tenantColumnDef: MatColumnDef;

  /**
   * The column name for the tenant.
   */
  colName = 'aiBot';

  aiBotPluginEnabled = flagEnabled(this.irisCtx.irisContext, 'aiBotPluginEnabled') && isDGaasScope(this.irisCtx.irisContext);

  /**
   * Constructor
   */
  constructor(
    @Optional() private table: MatTable<any>,
    private cdRef: ChangeDetectorRef,
    private irisCtx: IrisContextService,
  ) {
    super();
  }

  /**
   * Init the component.
   */
  ngOnInit() {
    if (!this.aiBotPluginEnabled) {
      return;
    }

    // Update the tenant as a column in the existing definition.
    this.tenantColumnDef.name = this.colName;

    if (this.table) {
      this.cdRef.detectChanges();
      this.table.addColumnDef(this.tenantColumnDef);
    }
  }

  /**
   * Triggers after the view is checked.
   */
  ngAfterViewChecked(): void {
    if (!this.aiBotPluginEnabled) {
      return;
    }

    const headerColumns = get(this.table, '_headerRowDefs[0].columns', []);
    const rowColumns = get(this.table, '_rowDefs[0].columns', []);

    // Do not add the tenant column in header if it already contains the column or contains bulk-actions column
    if (!headerColumns.includes(this.colName) && !headerColumns.includes('bulk-actions')) {
      headerColumns.splice(this.position || headerColumns.length, 0, this.colName);
    }

    // If the tenant column is not updated in the column definition
    if (!rowColumns.includes(this.colName)) {
      rowColumns.splice(this.position || headerColumns.length, 0, this.colName);
    }
  }
}
