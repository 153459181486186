import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RowIdDirective } from './row-id.directive';

@NgModule({
  declarations: [RowIdDirective],
  exports: [RowIdDirective],
  imports: [
    CommonModule
  ]
})
export class RowIdModule { }
