import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { IncidenceId } from '@cohesity/api/argus';
import { AppStatus, HasCustomRBACPermissions } from '@cohesity/data-govern/shared';
import { KeyedSelectionModel } from '@cohesity/helix';
import {
  IrisContextService,
  emptyLinkFn,
  emptyLinkParamsFn,
  flagEnabled
} from '@cohesity/iris-core';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';

import { shareReplay } from 'rxjs/operators';
import { SecurityDialogService } from '../../dialogs';
import { AnomalyAlert } from '../../security-shared.models';
import { SecurityService } from '../../services';

@Component({
  selector: 'dg-ar-anomaly-table',
  templateUrl: './anomaly-table.component.html',
  styleUrls: ['./anomaly-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AnomalyTableComponent extends AutoDestroyable implements OnChanges {

  /**
   * The state to use for anomaly object details.
   */
  @Input() objectDetailState = 'anti-ransomware-details';

  /**
   * The state to use for anomaly recoveries.
   */
  @Input() recoveryState = 'anti-ransomware-recovery';

  /**
   * Service response object
   */
  @Input() data: AnomalyAlert[] = [];

  /**
   * To show / hide spinner.
   */
  @Input() isLoading = false;

  /**
   * Table selection object
   */
  @Input() tableSelection?: KeyedSelectionModel<AnomalyAlert>;

  /** The app installation status for a given incidence */
  @Input() appStatusByIncidenceId = new Map<IncidenceId, AppStatus>();

  /**
   * Flag to show data hawk related columns(th,dc)
   */
  @Input() showDataHawkColumns = false;

  /**
   * Columns to be displayed
   */
  displayColumns = this.getColumnDefinition();

  /**
   * The state to use for anomaly object details.
   */
  @Input() getObjectDetailLinkFn = emptyLinkFn;

  /**
   * The state params to use for anomaly object details.
   */
  @Input() getObjectDetailLinkParamsFn = emptyLinkParamsFn;

  /**
   * The state to use for anomaly recoveries.
   */
  @Input() getRecoveryLinkFn = emptyLinkFn;

  /**
   * The state to use for install app.
   */
  @Input() getInstallAppLinkFn = emptyLinkFn;

  /**
   * Return the install app link params.
   */
  @Input() getInstallAppLinkParamsFn = emptyLinkParamsFn;

  /**
   * recover anomalies bulk action clicked.
   */
  @Output() recoverAnomalies = new EventEmitter<AnomalyAlert[]>();

  /**
   * Anomaly strength setting value
   */
  readonly anomalyStrengthThreshold$ = this.securityService?.anomalyStrengthThreshold$?.pipe(shareReplay(1));

  /**
   * Emit reload event when data needs to be updated
   */
  @Output() readonly reload = new EventEmitter();

  /**
   * Function to return whether the select all option would be available.
   *
   * @return True if any row is selectable.
   */
  canSelect = () => HasCustomRBACPermissions(['ALERT_MODIFY', 'RESTORE_MODIFY'], this.irisCtx.irisContext);

  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private irisCtx: IrisContextService,
    private securityDialogService: SecurityDialogService,
    readonly securityService: SecurityService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.showDataHawkColumns) {
      this.displayColumns = this.getColumnDefinition();
    }
  }

  /**
   * adds datahawk columns depending on feature flags and showDataHawkColumns flag
   */
  getColumnDefinition(): string[] {
    return [
      'object',
      'system',
      'latestTimestampUsecs',
      'strength',
      this.showDataHawkColumns &&
        flagEnabled(this.irisCtx.irisContext, 'dataHawkThreatDetectionOnRWare')
        ? 'threatDetection' : null,
      this.showDataHawkColumns ? 'sensitivePatterns' : null,
    ].filter(Boolean);
  }

  /**
   * Custom sorting logic for the provider data table.
   *
   * @param   sort   Current sort state of the table.
   */
  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.data = data;
      return;
    }

    this.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'object':
          return this.compare(a?.properties?.object || '', b?.properties?.object || '', isAsc);
        case 'strength':
          return this.compare(+(a?.properties?.anomalyStrength || 0), +(b?.properties?.anomalyStrength || 0), isAsc);
        case 'source':
          return this.compare(a?.properties?.source || '', b?.properties?.source || '', isAsc);
        default:
          return 0;
      }
    });
  }

  /**
   * Utility compare method for table sorting.
   *
   * @param    a      Number or string to compare.
   * @param    b      Number or string to compare.
   * @param    isAsc  Whether sorting direction is ascending.
   */
  private compare(a: number | string, b: number | string, isAsc: boolean = true): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  /**
   * Updates selected alerts to resolved status.
   */
  resolveAlerts() {
    this.securityDialogService
      .launchAnomalyResolveDialog(this.tableSelection.selected)
      .pipe(this.untilDestroy())
      .subscribe(() => {
        this.tableSelection.clear();
        this.reload.emit();
      }, this.ajaxHandlerService.handler);
  }
}
