/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PerformActionOnClonesRequest } from '../models/perform-action-on-clones-request';
import { TdmObjects } from '../models/tdm-objects';
import { TdmObject } from '../models/tdm-object';
import { TdmObjectTimelineEvents } from '../models/tdm-object-timeline-events';
import { TdmSnapshot } from '../models/tdm-snapshot';
import { UpdateTdmSnapshotRequest } from '../models/update-tdm-snapshot-request';
import { TdmTasks } from '../models/tdm-tasks';
import { TdmTask } from '../models/tdm-task';
import { CreateTdmTaskRequest } from '../models/create-tdm-task-request';
@Injectable({
  providedIn: 'root',
})
class TestDataManagementService extends __BaseService {
  static readonly PerformActionOnClonesPath = '/tdm/clones/actions';
  static readonly GetTdmObjectsPath = '/tdm/objects';
  static readonly GetTdmObjectByIdPath = '/tdm/objects/{id}';
  static readonly GetTdmTimelineEventsByObjectIdPath = '/tdm/objects/{id}/timeline-events';
  static readonly UpdateTdmSnapshotByIdPath = '/tdm/snapshots/{id}';
  static readonly DeleteTdmSnapshotByIdPath = '/tdm/snapshots/{id}';
  static readonly GetTdmTasksPath = '/tdm/tasks';
  static readonly CreateTdmTaskPath = '/tdm/tasks';
  static readonly GetTdmTaskByIdPath = '/tdm/tasks/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Perform actions on clones
   *
   * Performs various actions on clones. Supports actions on multiple clones.
   * @param params The `TestDataManagementService.PerformActionOnClonesParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to perform an action on multiple clones.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  PerformActionOnClonesResponse(params: TestDataManagementService.PerformActionOnClonesParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/tdm/clones/actions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Perform actions on clones
   *
   * Performs various actions on clones. Supports actions on multiple clones.
   * @param params The `TestDataManagementService.PerformActionOnClonesParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to perform an action on multiple clones.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  PerformActionOnClones(params: TestDataManagementService.PerformActionOnClonesParams): __Observable<null> {
    return this.PerformActionOnClonesResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get all TDM objects
   *
   * Get all TDM objects matching specified optional filter criteria.
   * @param params The `TestDataManagementService.GetTdmObjectsParams` containing the following parameters:
   *
   * - `taskIds`: Get the objects belonging to the specified TDM task IDs.
   *
   * - `statuses`: Get the objects matching specified statuses.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `paginationCookie`: Get the next set of objects by specifying the cookie string, as received from the server in the last call.
   *
   * - `name`: Get the objects matching specified name.
   *
   * - `ids`: Get the objects matching specifies IDs.
   *
   * - `environments`: Get the objects matching specified environments.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetTdmObjectsResponse(params: TestDataManagementService.GetTdmObjectsParams): __Observable<__StrictHttpResponse<TdmObjects>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.taskIds || []).forEach(val => {if (val != null) __params = __params.append('taskIds', val.toString())});
    (params.statuses || []).forEach(val => {if (val != null) __params = __params.append('statuses', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.paginationCookie != null) __params = __params.set('paginationCookie', params.paginationCookie.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.environments || []).forEach(val => {if (val != null) __params = __params.append('environments', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/tdm/objects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TdmObjects>;
      })
    );
  }
  /**
   * Get all TDM objects
   *
   * Get all TDM objects matching specified optional filter criteria.
   * @param params The `TestDataManagementService.GetTdmObjectsParams` containing the following parameters:
   *
   * - `taskIds`: Get the objects belonging to the specified TDM task IDs.
   *
   * - `statuses`: Get the objects matching specified statuses.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `paginationCookie`: Get the next set of objects by specifying the cookie string, as received from the server in the last call.
   *
   * - `name`: Get the objects matching specified name.
   *
   * - `ids`: Get the objects matching specifies IDs.
   *
   * - `environments`: Get the objects matching specified environments.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetTdmObjects(params: TestDataManagementService.GetTdmObjectsParams): __Observable<TdmObjects> {
    return this.GetTdmObjectsResponse(params).pipe(
      __map(_r => _r.body as TdmObjects)
    );
  }

  /**
   * Get TDM object by ID
   *
   * Get a TDM object by specifying its ID.
   * @param params The `TestDataManagementService.GetTdmObjectByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the TDM object.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetTdmObjectByIdResponse(params: TestDataManagementService.GetTdmObjectByIdParams): __Observable<__StrictHttpResponse<TdmObject>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/tdm/objects/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TdmObject>;
      })
    );
  }
  /**
   * Get TDM object by ID
   *
   * Get a TDM object by specifying its ID.
   * @param params The `TestDataManagementService.GetTdmObjectByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the TDM object.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetTdmObjectById(params: TestDataManagementService.GetTdmObjectByIdParams): __Observable<TdmObject> {
    return this.GetTdmObjectByIdResponse(params).pipe(
      __map(_r => _r.body as TdmObject)
    );
  }

  /**
   * Get timeline events of object
   *
   * Get the collection of timeline events of a TDM object by specifying its ID.
   * @param params The `TestDataManagementService.GetTdmTimelineEventsByObjectIdParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the TDM object.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `createdBefore`: Get the events created before the specified time (in usecs from epoch).
   *
   * - `createdAfter`: Get the events created after the specified time (in usecs from epoch).
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetTdmTimelineEventsByObjectIdResponse(params: TestDataManagementService.GetTdmTimelineEventsByObjectIdParams): __Observable<__StrictHttpResponse<TdmObjectTimelineEvents>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.createdBefore != null) __params = __params.set('createdBefore', params.createdBefore.toString());
    if (params.createdAfter != null) __params = __params.set('createdAfter', params.createdAfter.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/tdm/objects/${encodeURIComponent(params.id)}/timeline-events`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TdmObjectTimelineEvents>;
      })
    );
  }
  /**
   * Get timeline events of object
   *
   * Get the collection of timeline events of a TDM object by specifying its ID.
   * @param params The `TestDataManagementService.GetTdmTimelineEventsByObjectIdParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the TDM object.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `createdBefore`: Get the events created before the specified time (in usecs from epoch).
   *
   * - `createdAfter`: Get the events created after the specified time (in usecs from epoch).
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetTdmTimelineEventsByObjectId(params: TestDataManagementService.GetTdmTimelineEventsByObjectIdParams): __Observable<TdmObjectTimelineEvents> {
    return this.GetTdmTimelineEventsByObjectIdResponse(params).pipe(
      __map(_r => _r.body as TdmObjectTimelineEvents)
    );
  }

  /**
   * Update a snapshot by ID
   *
   * Update the details of a snapshot by specifying its ID.
   * @param params The `TestDataManagementService.UpdateTdmSnapshotByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the snapshot.
   *
   * - `body`: Specifies the parameters to update the snapshot.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateTdmSnapshotByIdResponse(params: TestDataManagementService.UpdateTdmSnapshotByIdParams): __Observable<__StrictHttpResponse<TdmSnapshot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/tdm/snapshots/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TdmSnapshot>;
      })
    );
  }
  /**
   * Update a snapshot by ID
   *
   * Update the details of a snapshot by specifying its ID.
   * @param params The `TestDataManagementService.UpdateTdmSnapshotByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the snapshot.
   *
   * - `body`: Specifies the parameters to update the snapshot.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateTdmSnapshotById(params: TestDataManagementService.UpdateTdmSnapshotByIdParams): __Observable<TdmSnapshot> {
    return this.UpdateTdmSnapshotByIdResponse(params).pipe(
      __map(_r => _r.body as TdmSnapshot)
    );
  }

  /**
   * Delete a snapshot by ID
   *
   * Delete a snapshot by specifying its ID.
   * @param params The `TestDataManagementService.DeleteTdmSnapshotByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the snapshot.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteTdmSnapshotByIdResponse(params: TestDataManagementService.DeleteTdmSnapshotByIdParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/tdm/snapshots/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a snapshot by ID
   *
   * Delete a snapshot by specifying its ID.
   * @param params The `TestDataManagementService.DeleteTdmSnapshotByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the snapshot.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteTdmSnapshotById(params: TestDataManagementService.DeleteTdmSnapshotByIdParams): __Observable<null> {
    return this.DeleteTdmSnapshotByIdResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get all TDM tasks
   *
   * Get all the TDM tasks matching specified optional filter criteria.
   * @param params The `TestDataManagementService.GetTdmTasksParams` containing the following parameters:
   *
   * - `statuses`: Get the tasks matching specified statuses.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `paginationCookie`: Get the next set of tasks by specifying the cookie string, as received from the server in the last call.
   *
   * - `objectIds`: Get the tasks for the specified TDM object IDs.
   *
   * - `ids`: Get the tasks matching specified IDs.
   *
   * - `environments`: Get the tasks matching specified environments.
   *
   * - `createdBeforeUsecs`: Get the tasks created before the specified time (in usecs from epoch).
   *
   * - `createdAfterUsecs`: Get the tasks created after the specified time (in usecs from epoch).
   *
   * - `actions`: Get the tasks matching specified actions.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetTdmTasksResponse(params: TestDataManagementService.GetTdmTasksParams): __Observable<__StrictHttpResponse<TdmTasks>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.statuses || []).forEach(val => {if (val != null) __params = __params.append('statuses', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.paginationCookie != null) __params = __params.set('paginationCookie', params.paginationCookie.toString());
    (params.objectIds || []).forEach(val => {if (val != null) __params = __params.append('objectIds', val.toString())});
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.environments || []).forEach(val => {if (val != null) __params = __params.append('environments', val.toString())});
    if (params.createdBeforeUsecs != null) __params = __params.set('createdBeforeUsecs', params.createdBeforeUsecs.toString());
    if (params.createdAfterUsecs != null) __params = __params.set('createdAfterUsecs', params.createdAfterUsecs.toString());
    (params.actions || []).forEach(val => {if (val != null) __params = __params.append('actions', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/tdm/tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TdmTasks>;
      })
    );
  }
  /**
   * Get all TDM tasks
   *
   * Get all the TDM tasks matching specified optional filter criteria.
   * @param params The `TestDataManagementService.GetTdmTasksParams` containing the following parameters:
   *
   * - `statuses`: Get the tasks matching specified statuses.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `paginationCookie`: Get the next set of tasks by specifying the cookie string, as received from the server in the last call.
   *
   * - `objectIds`: Get the tasks for the specified TDM object IDs.
   *
   * - `ids`: Get the tasks matching specified IDs.
   *
   * - `environments`: Get the tasks matching specified environments.
   *
   * - `createdBeforeUsecs`: Get the tasks created before the specified time (in usecs from epoch).
   *
   * - `createdAfterUsecs`: Get the tasks created after the specified time (in usecs from epoch).
   *
   * - `actions`: Get the tasks matching specified actions.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetTdmTasks(params: TestDataManagementService.GetTdmTasksParams): __Observable<TdmTasks> {
    return this.GetTdmTasksResponse(params).pipe(
      __map(_r => _r.body as TdmTasks)
    );
  }

  /**
   * Create a TDM task
   *
   * Create a task for the Test Data Management (TDM) workflow.
   * @param params The `TestDataManagementService.CreateTdmTaskParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a TDM task.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateTdmTaskResponse(params: TestDataManagementService.CreateTdmTaskParams): __Observable<__StrictHttpResponse<TdmTask>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/tdm/tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TdmTask>;
      })
    );
  }
  /**
   * Create a TDM task
   *
   * Create a task for the Test Data Management (TDM) workflow.
   * @param params The `TestDataManagementService.CreateTdmTaskParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a TDM task.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateTdmTask(params: TestDataManagementService.CreateTdmTaskParams): __Observable<TdmTask> {
    return this.CreateTdmTaskResponse(params).pipe(
      __map(_r => _r.body as TdmTask)
    );
  }

  /**
   * Get a TDM task by ID
   *
   * Get a TDM task by ID.
   * @param params The `TestDataManagementService.GetTdmTaskByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the TDM task.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetTdmTaskByIdResponse(params: TestDataManagementService.GetTdmTaskByIdParams): __Observable<__StrictHttpResponse<TdmTask>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/tdm/tasks/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TdmTask>;
      })
    );
  }
  /**
   * Get a TDM task by ID
   *
   * Get a TDM task by ID.
   * @param params The `TestDataManagementService.GetTdmTaskByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the TDM task.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetTdmTaskById(params: TestDataManagementService.GetTdmTaskByIdParams): __Observable<TdmTask> {
    return this.GetTdmTaskByIdResponse(params).pipe(
      __map(_r => _r.body as TdmTask)
    );
  }
}

module TestDataManagementService {

  /**
   * Parameters for PerformActionOnClones
   */
  export interface PerformActionOnClonesParams {

    /**
     * Specifies the parameters to perform an action on multiple clones.
     */
    body: PerformActionOnClonesRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetTdmObjects
   */
  export interface GetTdmObjectsParams {

    /**
     * Get the objects belonging to the specified TDM task IDs.
     */
    taskIds?: Array<string>;

    /**
     * Get the objects matching specified statuses.
     */
    statuses?: Array<'scheduled' | 'inProgress' | 'succeeded' | 'failed' | 'canceled'>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Get the next set of objects by specifying the cookie string, as received from the server in the last call.
     */
    paginationCookie?: string;

    /**
     * Get the objects matching specified name.
     */
    name?: string;

    /**
     * Get the objects matching specifies IDs.
     */
    ids?: Array<string>;

    /**
     * Get the objects matching specified environments.
     */
    environments?: Array<'kSQL' | 'kOracle'>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetTdmObjectById
   */
  export interface GetTdmObjectByIdParams {

    /**
     * Specifies the ID of the TDM object.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetTdmTimelineEventsByObjectId
   */
  export interface GetTdmTimelineEventsByObjectIdParams {

    /**
     * Specifies the ID of the TDM object.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Get the events created before the specified time (in usecs from epoch).
     */
    createdBefore?: number;

    /**
     * Get the events created after the specified time (in usecs from epoch).
     */
    createdAfter?: number;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateTdmSnapshotById
   */
  export interface UpdateTdmSnapshotByIdParams {

    /**
     * Specifies the ID of the snapshot.
     */
    id: string;

    /**
     * Specifies the parameters to update the snapshot.
     */
    body: UpdateTdmSnapshotRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteTdmSnapshotById
   */
  export interface DeleteTdmSnapshotByIdParams {

    /**
     * Specifies the ID of the snapshot.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetTdmTasks
   */
  export interface GetTdmTasksParams {

    /**
     * Get the tasks matching specified statuses.
     */
    statuses?: Array<'scheduled' | 'inProgress' | 'succeeded' | 'failed' | 'canceled'>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Get the next set of tasks by specifying the cookie string, as received from the server in the last call.
     */
    paginationCookie?: string;

    /**
     * Get the tasks for the specified TDM object IDs.
     */
    objectIds?: Array<string>;

    /**
     * Get the tasks matching specified IDs.
     */
    ids?: Array<string>;

    /**
     * Get the tasks matching specified environments.
     */
    environments?: Array<'kSQL' | 'kOracle'>;

    /**
     * Get the tasks created before the specified time (in usecs from epoch).
     */
    createdBeforeUsecs?: number;

    /**
     * Get the tasks created after the specified time (in usecs from epoch).
     */
    createdAfterUsecs?: number;

    /**
     * Get the tasks matching specified actions.
     */
    actions?: Array<'clone' | 'teardown' | 'refresh' | 'snapshot' | 'rewind'>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateTdmTask
   */
  export interface CreateTdmTaskParams {

    /**
     * Specifies the parameters to create a TDM task.
     */
    body: CreateTdmTaskRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetTdmTaskById
   */
  export interface GetTdmTaskByIdParams {

    /**
     * Specifies the ID of the TDM task.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { TestDataManagementService }
