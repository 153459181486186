import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, TemplateRef } from '@angular/core';

@Component({
  selector: 'coh-restore-page',
  templateUrl: './restore-page.component.html',
  styleUrls: ['./restore-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestorePageComponent {
  /**
   * The header title goes at the top of the page. If nothing is specified, it will show 'New Recovery.'
   */
  @Input() headerTitle: string;

  /**
   * The page title shows in the page content header, it is usually the type of object being restored.
   */
  @Input() pageTitle: string;

  /**
   * The page icon shows in the page content header, it is usually the icon for the type of object being restored.
   */
  @Input() pageIcon: string;

  /**
   * If the page header is set, the page title and page icon will be ignored, and the page header will be rendered
   * inside of the cog-page-nav component. This allows a component to override the default display to implement
   * tabs or some other custom page header.
   */
  @Input() pageHeader: TemplateRef<any>;

  /**
   * The close event is emitted whenever the user clicks the back button on the page.
   */
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter<void>();
}
