// Component: Overview

;(function(angular, undefined) {
  'use strict';

  angular.module('C.appsManagement')
    .controller('appOverviewCtrl', AppOverviewCtrlFn)
    .component('appOverview', {
      templateUrl:
      'app/apps-management/app-store/app-details/overview/overview.html',
      controller: 'appOverviewCtrl',
      bindings: {
        // @type   {Object}   App specific details
        app: '<',
      }
    });

  /**
   * @ngdoc component
   * @name C.appsManagement:overview
   * @function AppOverviewCtrlFn
   *
   * @description
   * Provides overview for specified application.
   *
   * @example
     <app-overview
       app="$ctrl.appDetails">
     </app-overview>
   */
  function AppOverviewCtrlFn() {
    var $ctrl = this;
  }
})(angular);
