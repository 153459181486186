import { yfilesDevLicense, yfilesProdLicense } from '@cohesity/helix-yfiles';

/**
 * This file can be replaced during build by using the `fileReplacements` array.
 * `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
 * The list of file replacements can be found in `angular.json`.
 */

export const environment = {
  get heliosProduction(): boolean {
    return window.document.location.hostname === HeliosProductionHostname;
  },
  get yfilesLicense(): any {
    return this.heliosProduction ? yfilesProdLicense : yfilesDevLicense;
  }
};

/**
 * Production hostname for Helios.
 */
export const HeliosProductionHostname = 'helios.cohesity.com';
