import {
  Component,
  OnChanges,
  Input,
  ViewEncapsulation,
} from '@angular/core';

import { ByteSize } from './byte-size.model';
import { ByteSizeService } from './byte-size.service';
import { ByteScale } from './file-size';
import { NgIf } from '@angular/common';

/**
 * Convert provided Number of Bytes into humanize byte size with its unit.
 */
@Component({
  selector: 'cog-byte-size',
  templateUrl: './byte-size.component.html',
  styleUrls: ['./byte-size.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf]
  // TODO(veetesh): after changing change detection strategy to OnPush causes
  // unit test failure because detectChanges() is not updating the model changes
  // in the view.
  // https://github.com/angular/angular/issues/12313
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ByteSizeComponent implements OnChanges {
  /**
   * The Input bytes to show.
   */
  @Input() bytes: number;

  /**
   * The number of decimal places to display.
   */
  @Input() precision = 1;

  /**
   * Scaling options for bytes.
   */
  @Input() byteScale: ByteScale = 'base2';

  /**
   * Specify specific units to convert the bytes
   */
  @Input() unit = '';

  /**
   * Use 'N/A' instead of '-'
   */
  @Input() useNaLetters = false;

  /**
   * The converted input bytes into byte size object.
   */
  byteSize: ByteSize;

  constructor(private byteSizeService: ByteSizeService) {}

  /**
   * Re-compute byteSize on input bytes changes.
   */
  ngOnChanges() {
    this.byteSize = this.byteSizeService.bytesToSize(
      this.bytes,
      this.precision,
      this.useNaLetters,
      this.unit,
      this.byteScale
    );
  }
}
