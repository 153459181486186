import { Pipe, PipeTransform } from '@angular/core';

import { RecoveryListItem, RecoveryTaskStatus, SelfServiceRecoveryType } from '../models';

/**
 * Map of recovery list item status and the corresponding icon shape.
 */
const itemStatusIconMap: Record<SelfServiceRecoveryType, Partial<Record<RecoveryTaskStatus, string>>> = {
  [SelfServiceRecoveryType.kMailbox]: {
    [RecoveryTaskStatus.Canceled]: 'helix:object-o365-mailbox-cancel',
    [RecoveryTaskStatus.Completed]: 'helix:object-o365-mailbox-success',
    [RecoveryTaskStatus.Failed]: 'helix:object-o365-mailbox-error',
    [RecoveryTaskStatus.InitiatingRecovery]: 'helix:object-o365-mailbox-running',
    [RecoveryTaskStatus.InProgress]: 'helix:object-o365-mailbox-running',
    [RecoveryTaskStatus.PstAvailable]: 'helix:object-o365-mailbox-success',
    [RecoveryTaskStatus.PstUnavailable]: 'helix:object-o365-mailbox-success',
  },
  [SelfServiceRecoveryType.kOneDrive]: {
    [RecoveryTaskStatus.Canceled]: 'helix:object-o365-onedrive-cancel',
    [RecoveryTaskStatus.Completed]: 'helix:object-o365-onedrive-success',
    [RecoveryTaskStatus.Failed]: 'helix:object-o365-onedrive-error',
    [RecoveryTaskStatus.InitiatingRecovery]: 'helix:object-o365-onedrive-running',
    [RecoveryTaskStatus.InProgress]: 'helix:object-o365-onedrive-running',
  },
};

/**
 * The default icon for a recovery list item, irrespective of it's status.
 */
const defaultTypeIcon: Record<SelfServiceRecoveryType, string> = {
  [SelfServiceRecoveryType.kMailbox]: 'helix:object-o365-mailbox',
  [SelfServiceRecoveryType.kOneDrive]: 'helix:object-o365-onedrive',
};

/**
 * Returns the cog-icon shape for an M365 self service recovery list item.
 *
 * @example
 * {{ recoveryListItem | cohM365RecoveryListItemIcon: status }}
 */
@Pipe({ name: 'cohM365RecoveryListItemIcon' })
export class RecoveryListItemIconPipe implements PipeTransform {
  transform(value: RecoveryListItem, status: RecoveryTaskStatus): string {
    return itemStatusIconMap[value.recoveryType]?.[status] ?? defaultTypeIcon[value.recoveryType];
  }
}
