import { Injectable } from '@angular/core';
import { ClusterConfigApiService, ClusterConfigParams } from '@cohesity/api/argus';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ClusterInfoService {
  /**
   * security integration apps info behaviour subject
   */
  private _clusterConfig$ = new BehaviorSubject<ClusterConfigParams>(null);

  /**
   * clusterConfig info observable
   */
  readonly clusterConfig$ = this._clusterConfig$.asObservable();

  constructor(private clusterConfigService: ClusterConfigApiService) {}

  /**
   * Loads cluster config data to observable
   */
  loadData() {
    return this.clusterConfigService.getClusterConfig().pipe(tap(data => this._clusterConfig$.next(data.config)));
  }
}
