<ng-container *ngIf="dataReady">
  <cog-statlist valueSize="md">
    <cog-statlist-item
      [label]="'total' | translate">
      {{ successfulCloudVaults + unsuccessfulCloudVaults }}
    </cog-statlist-item>
    <cog-statlist-item
      [label]="'succeeded' | translate"
      labelLegend="success">
      {{ successfulCloudVaults }}
    </cog-statlist-item>
    <cog-statlist-item
      [label]="'failed' | translate"
      labelLegend="critical">
      {{ unsuccessfulCloudVaults }}
    </cog-statlist-item>
  </cog-statlist>

  <cog-bar-chart
    *ngIf="successfulCloudVaults; else blankCard"
    [categories]="barChartCategories"
    [seriesData]="barSeriesOptions"
    [customChartOptions]="barCustomOptions">
  </cog-bar-chart>
</ng-container>

<ng-template #blankCard>
  <cog-blank-card size="sm" [message]="'noDataAvailable' | translate" class="blank-card"></cog-blank-card>
</ng-template>
