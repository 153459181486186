import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RpaasRegionInfo } from '@cohesity/api/v2';
import { VaultMenuAction, VaultMenuActionEvent } from '@cohesity/data-govern/security-center';
import { NavItem } from '@cohesity/helix';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { ObservableInput } from 'ngx-observable-input';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * @description
 * Cloud vault actions menu.
 */
@Component({
  selector: 'coh-vault-table-actions',
  templateUrl: './vault-table-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VaultTableActionsComponent implements OnInit {
  /**
   * Vault on which menu item will be based on.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('vault') vault$: Observable<RpaasRegionInfo>;

  /**
   * Dispatches menu action event.
   */
  @Output() readonly selected = new EventEmitter<VaultMenuActionEvent>();

  /**
   * Table row action column menu items.
   */
  menuActions$: Observable<NavItem[]>;

  constructor(private irisCtx: IrisContextService) {}

  ngOnInit() {
    this.menuActions$ = this.vault$.pipe(
      map(vault => {
        const menuItems: NavItem[] = [];
        const isProvisioned = vault.provisionStatus?.status === 'Completed';

        if (flagEnabled(this.irisCtx.irisContext, 'rpaasBackupWindow')) {
          menuItems.push({
            id: VaultMenuAction.EditBackupWindow,
            displayName: VaultMenuAction.EditBackupWindow,
            isEnabled: isProvisioned,
          });
        }

        return menuItems;
      })
    );
  }

  /**
   * Handles row menu item click.
   *
   * @param item Menu item that was clicked.
   * @param vault Vault data.
   */
  menuHandler(item: NavItem, vault: RpaasRegionInfo) {
    this.selected.emit({
      action: item.id as VaultMenuAction,
      vault,
    });
  }
}
