import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCalendarCellCssClasses, MatCalendarHeader } from '@angular/material/datepicker';
import {
  LegacyDateAdapter as DateAdapter,
  MAT_LEGACY_DATE_FORMATS as MAT_DATE_FORMATS,
  MatLegacyDateFormats as MatDateFormats,
} from '@angular/material/legacy-core';
import moment from 'moment';
import { Subject } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { HelixIntlService } from '../../helix-intl.service';
import { CalendarDirective } from '../calendar';
import { MaxDateOptions, MinDateOptions } from '../date-utils/date-options';
import { checkMoment, DateAdapterUtils, DateRange, Timeframe, timeRangeParameterMap } from '../date-utils/public_api';
import { BannerStatus } from '../banner/banner.model';

/**
 * Banner for date range filter.
 */
export interface DateRangeBanner {
  status: BannerStatus;
  message: string;
}

/**
 * The date range picker component.
 *
 * @example
 *  <cog-date-range-picker
 *    [formControl]="dateRange"
 *  ></cog-date-range-picker>
 */
@Component({
  selector: 'cog-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangePickerComponent),
      multi: true,
    },
  ],
})
export class DateRangePickerComponent<D> implements ControlValueAccessor, OnDestroy, AfterViewInit {
  /**
   * uuid helps us to generate unique mat-radio-group name which makes mat-radio-button selection
   * mutually exclusive if multiple mat-radio-group instances are present on the DOM simultaneously.
   */
  readonly uuid = uuid();

  /**
   * The time range map is used to generate unique cogDataId for a given timeframe.
   */
  readonly timeRangeParameterMap = timeRangeParameterMap;

  /**
   * The selected date range values.
   */
  dateRange: DateRange<D> = {
    timeframe: null,
    start: null,
    end: null,
  };

  @Input()
  get value(): DateRange<D> | null {
    return this.dateRange;
  }

  set value(dateRange: DateRange<D> | null) {
    this.writeValue(dateRange);
  }

  /**
   * Event emitted with new date range is selection.
   */
  @Output() changes = new EventEmitter<DateRange<D> | null>();

  /**
   * Displays timeframe selector.
   */
  @Input() useTimeframe = false;

  /**
   * does not allow guessing of time frame
   */
  @Input() enableGuessTimeframe = false;

  /**
   * If enabled then allow time selection for selected start and end date range.
   */
  @Input() enablePreciseDateRangeSelection = false;

  /**
   * If provided, user will be able to select an option with this label, which
   * will present an additional option having this label and providing no date
   * range in the filter value. NOTE: Requires @Input() useTimeframe to be true.
   */
  @Input() noDateOptionLabel = '';

  /**
   * Available timeframe options.
   */
  @Input() timeframeOptions: Timeframe[] = [
    Timeframe.Day,
    Timeframe.Week,
    Timeframe.Month,
    Timeframe.Quarter,
    Timeframe.Custom,
    Timeframe.Forever,
  ];

  /**
   * Indicates whether to show the calendar picker option.
   */
  get showCalendarPickers() {
    return this.timeframeOptions.includes(Timeframe.Custom);
  }

  /**
   * Set maximum number of days selectable in date range between start and end dates.
   */
  @Input() maxRange: number;

  /**
   * formatted last date to be allowed for selection
   */
  private _maxDate: D = this.dateAdapterUtils.getMaxDate(MaxDateOptions.Today);

  /**
   * last date to be allowed for selection
   */
  @Input() set maxDate(maxDate: MaxDateOptions | D)  {
    this._maxDate = this.dateAdapterUtils.getMaxDate(maxDate);
  }

  /**
   * getter function for maxDate
   */
  get maxDate(): D {
    return this._maxDate;
  }

  /**
   * formatted selected option for setting the min date (eg. dates before 6 months in the past can not be selected)
   */
  private _minDate: D = this.dateAdapterUtils.getMinDate(MinDateOptions.Forever);

  /**
   * selected option for setting the min date (eg. dates before 6 months in the past can not be selected)
   */
  @Input() set minDate(minDate: MinDateOptions | D)  {
    this._minDate = this.dateAdapterUtils.getMinDate(minDate);
  }

  /**
   * getter function for minDate
   */
  get minDate(): D {
    return this._minDate;
  }

  /**
   * Allow parent to dynamically set banner.
   */
  _banner: DateRangeBanner;
  @Input() set banner(banner: DateRangeBanner) {
    this._banner = banner;
  }

  get banner(): DateRangeBanner {
    return this._banner;
  }

  /**
   * Indicate which date field will be updated next on user click and used to implement 2 click date range selection eg.
   * 1st click will select the start date and 2nd one is to select the end date.
   */
  private turn: keyof Pick<DateRange<D>, 'start' | 'end'> = 'start';

  /**
   * A date representing the period (month or year) to start the start calendar in.
   */
  startAt: D;

  /**
   * A date representing the next period (month or year) to start the end calendar in.
   */
  nextToStartAt: D;

  /**
   * The custom date range header component.
   */
  headerComponent = DateRangePickerHeaderComponent;

  /**
   * The stream which wil be finished when component is destroyed.
   */
  private destroy = new Subject<void>();

  /**
   * The internally used helix CalendarDirective component instances.
   */
  @ViewChildren(CalendarDirective) calendarDirectives: QueryList<CalendarDirective<D>>;

  constructor(
    @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
    private changeDetectorRef: ChangeDetectorRef,
    private dateAdapter: DateAdapter<D>,
    private dateAdapterUtils: DateAdapterUtils<D>,
    public intlSvc: HelixIntlService,
  ) {
    this.nextToStartAt = this.dateAdapter.today();
    this.startAt = this.dateAdapter.addCalendarMonths(this.nextToStartAt, -1);
  }

  /**
   * Refresh the view to next tick to update the custom header.
   */
  ngAfterViewInit() {
    setTimeout(this.refresh);
  }

  /**
   * Trigger destroy subject to clean up subscriptions.
   */
  ngOnDestroy() {
    this.destroy.next();
  }

  /**
   * Refresh the start & end date calendars.
   *
   * @param setActiveDate If true then set the active date else not.
   */
  refresh = (setActiveDate = true) => {
    this.calendarDirectives.forEach((calendarDirective, index) => {
      // Setting active date which will bring currently selected date in focus.
      if (setActiveDate) {
        // Use end or maxDate or today
        let calendarEnd: D = (this.dateRange.end || this.maxDate || this.nextToStartAt);

        // dateAdapter is Moment in some case and won't work if parameter is not moment date.
        // dateAdapter in unit test is Date so force convert moment date won't work. Need to check if date
        // was moment date originally.
        if (moment.isMoment(calendarDirective.calendar.activeDate)) {
          calendarEnd = moment(calendarEnd) as D;
        }
        checkMoment(calendarEnd);
        calendarDirective.calendar.activeDate = !index ?
          // 1st calendar should focus on the month before end of the date range
          this.dateAdapter.addCalendarMonths(calendarEnd, -1) :
          // 2nd calendar should focus on the month of the end of the date range.
          calendarEnd;
      }
      calendarDirective.refresh();
    });
  };

  /**
   * Get the label of timeframe option.
   *
   * @param    timeframe Timeframe option.
   * @returns  Timeframe label.
   */
  getLabel(timeframe: Timeframe): string {
    if (timeframe === Timeframe.NoDate) {
      return this.noDateOptionLabel;
    }
    return this.intlSvc.timeframe[Timeframe[timeframe]];
  }

  /**
   * Set calendar when timeframe option is selected.
   *
   * @param    timeframe  The selected timeframe value.
   */
  onTimeframeChange(timeframe: Timeframe) {
    this.dateRange = this.dateAdapterUtils.getGenericDateRange(timeframe);
    this.onChange(this.dateRange);
    this.refresh();
  }

  /**
   * Handles day selection in the month view.
   *
   * @param   date   The selected date.
   */
  selectDay(date: D) {
    const { start, end, timeframe } = this.dateRange;
    let momentStart: any, momentEnd: any;

    // Set custom timeframe because user is choosing the date manually.
    if (timeframe) {
      this.dateRange.timeframe = Timeframe.Custom;
    }

    if (!start) {
      // if start does not exist in dateRange then current selection is the start date and next turn should be end
      this.dateRange.start = date;
      this.turn = 'end';
    } else {
      // start exists in dateRange check for end
      let endDate = end;

      // if end does not exist in dateRange and start does current selected date is end date
      if (!endDate) {
        endDate = date;
      }

      const compareStart = this.dateAdapter.compareDate(date, start);

      // endDate becomes invalid date when selecting the first date of date range so need to check.
      const compareEnd = (!endDate as unknown instanceof Date || !isNaN(endDate as number)) ?
        this.dateAdapter.compareDate(date, endDate) : undefined;
      const { turn } = this;

      // swap start and end dates if user clicks dates out of order i.e. start is clicked after end
      if (turn === 'start' && compareEnd > 0) {
        // selection of start date after already selected end date
        this.dateRange.start = endDate;
        this.dateRange.end = date;
      } else if (turn === 'end' && compareStart < 0) {
        // selection of end date before already selected start date
        this.dateRange.end = start;
        this.dateRange.start = date;
      } else {
        // selecting the date according to turn (generic case)
        this.dateRange[turn] = date;
        // toggling the turn.
        this.turn = this.turn === 'start' ? 'end' : 'start';
      }
    }

    if (this.maxRange) {
      const { start: startDate, end: endDate } = this.dateRange;
      const dayDiff = moment(endDate).diff(startDate, 'day');

      if (dayDiff > this.maxRange) {
        // Make sure the start and end dates are the same type. Otherwise, it will mess up in date adapter.
        // setting dates in range if difference in dates is > maxRange
        if (date === endDate) {
          momentStart = moment(endDate).subtract(this.maxRange, 'day');
          this.dateRange.start = moment.isMoment(endDate) ? momentStart :
            this.dateAdapterUtils.momentToDate(momentStart);
        } else if (date === startDate) {
          momentEnd = moment(startDate).add(this.maxRange, 'day');
          this.dateRange.end = moment.isMoment(startDate) ? momentEnd : this.dateAdapterUtils.momentToDate(momentEnd);
        }
      }
    }

    // Make sure the start and end dates are the same type. Otherwise, it will mess up in date adapter.
    // end set to end of day
    momentEnd = moment(this.dateRange.end).endOf('day');
    this.dateRange.end = moment.isMoment(this.dateRange.end) ? momentEnd :
      this.dateAdapterUtils.momentToDate(momentEnd);
    // start set to start of day
    momentStart = moment(this.dateRange.start).startOf('day');
    this.dateRange.start = moment.isMoment(this.dateRange.start) ? momentStart :
      this.dateAdapterUtils.momentToDate(momentStart);

    this.onChange(this.dateRange);

    // refreshing the calendars to update the view.
    this.refresh(false);
  }

  /**
   * Handles start or end date time selection.
   *
   * @param   dateRange   The partial dateRange having updated start or end date.
   */
  selectTime(dateRange: Partial<DateRange<D>>) {
    // updating current date range start or end date whichever user had changed.
    this.onChange(this.dateRange = { ...this.dateRange, ...dateRange });

    // refreshing the calendars to update the view.
    this.refresh(false);
  }

  /**
   * Return the formatted date string.
   *
   * @param date The date.
   * @returns The formatted date string.
   */
  getDisplayDate(date: D): string {
    return this.dateAdapter.format(date, this.dateFormats.display.dateInput);
  }

  /**
   * Add/Remove the provided number of months from the start and end date used to go next and previous month.
   *
   * @example
   *   this.addMonths(-1); // go to previous month.
   *   this.addMonths(1);  // go to next month.
   *   this.addMonths(2);  // go to next to next month.
   *
   * @param months The number of numbers to add or remove.
   */
  addMonths(months: number) {
    const {
      first: {
        calendar: leftCalendar,
      },
      last: {
        calendar: rightCalendar
      }
    } = this.calendarDirectives;

    leftCalendar.activeDate = this.dateAdapter.addCalendarMonths(leftCalendar.activeDate, months);
    rightCalendar.activeDate = this.dateAdapter.addCalendarMonths(rightCalendar.activeDate, months);
  }

  /**
   * Return date cell classes to highlight in between dates.
   *
   * @param    date   The date object to test.
   * @return   The date cell class name.
   */
  getDateCellClass = (date: D): MatCalendarCellCssClasses => {
    checkMoment(date);
    checkMoment(this.dateRange.start);
    checkMoment(this.dateRange.end);
    const compareWithStartDate = this.dateRange.start && this.dateAdapter.compareDate(date, this.dateRange.start);

    // this.dateRange.end becomes invalid date when selecting the first date of date range so need to check.
    const compareWithEndDate = this.dateRange.end &&
      (!this.dateRange.end as unknown instanceof Date || !isNaN(this.dateRange.end as number)) &&
      this.dateAdapter.compareDate(date, this.dateRange.end);

    // don't use the result of above comparison of date is not valid.
    const isValidStartDate = typeof compareWithStartDate === 'number';
    const isValidEndDate = typeof compareWithEndDate === 'number';

    if (isValidStartDate && isValidEndDate && compareWithStartDate === 0 && compareWithEndDate === 0) {
      return 'is-same-start-and-end-date';
    }

    if (this.dateRange.start && isValidStartDate && compareWithStartDate === 0) {
      const css = ['is-start-date'];

      if (this.dateRange.end) {
        css.push('has-end');
      }

      return css;
    }

    if (this.dateRange.end && isValidEndDate && compareWithEndDate === 0) {
      const css = ['is-end-date'];

      if (this.dateRange.start) {
        css.push('has-start');
      }

      return css;
    }

    if (isValidStartDate && isValidEndDate && compareWithStartDate > 0 && compareWithEndDate < 0) {
      return 'in-between-date-range';
    }

    return '';
  };

  /**
   * Update the view on model changes is request programmatic via forms API.
   *
   * This method is called by the forms API to write to the view when programmatic changes from model to view are
   * requested.
   *
   * @param   dateRange   The new date range value.
   */
  writeValue(dateRange: DateRange<D>) {
    if (dateRange) {
      const { start, end, timeframe } = dateRange;

      if (!start && !end && timeframe !== Timeframe.Custom) {
        // Setting date range for valid timeframe if start & end date is not set.
        // Update the internal date range model.
        this.dateRange = this.dateAdapterUtils.getGenericDateRange(timeframe);

        // Update the external model asynchronously.
        setTimeout(() => this.onChange(this.dateRange));
      } else if (start && end && timeframe === Timeframe.Custom && this.enableGuessTimeframe) {
        // try guessing the timeframe from start & end time if its not explicitly set i.e. its value
        // is null or equals to Timeframe.Custom.
        const currTimeframe = this.dateAdapterUtils.guessTimeframe(dateRange, this.timeframeOptions);

        // skipping the external model and updating only the internal model because guessing timeframe
        // doesn't affect the input start & end date this saves 1 extra update digest cycle.
        this.dateRange = { ...dateRange, timeframe: currTimeframe };
      } else {
        // updating the internal form new values.
        this.dateRange = dateRange;
      }
    }

    // Triggering digest loop to sync model and view(template).
    this.changeDetectorRef.detectChanges();
    this.refresh();
  }

  /**
   * This method is called by the forms API on initialization to update the form
   * model when values propagate from the view to the model.
   */
  registerOnChange(fn: any) {
    const orgOnChange = this.onChange;

    this.onChange = (...args) => {
      // calling the original on changes callback defined below which will emit the changes output event.
      orgOnChange.call(this, ...args);

      // calling to on changes fn provided by FormControl.
      fn.call(this, ...args);
    };
  }

  /**
   * Registers a callback function is called by the forms API on initialization
   * to update the form model on blur.
   */
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  /**
   * The placeholder method populated by forms API registerOnChange method which
   * is used to update changes from view to modal.
   */
  onChange = (dateRange: DateRange<D>) => {
    this.changes.emit(dateRange);
  };

  /**
   * The placeholder method populated by forms API registerOnTouched method which
   * is used to mark a form field should be considered blurred or "touched".
   */
  onTouched = () => {};

  /**
   * Function that is called by the forms API when the control status changes to
   * or from 'DISABLED'. Depending on the status, it enables or disables the
   * appropriate DOM element.
   */
  setDisabledState(_isDisabled: boolean) {}
}

/**
 * @description
 * The date range picker header component.
 * // TODO(alex): Resolve circular dependency with DateRangePickerComponent
 */
@Component({
  selector: 'cog-date-range-picker-header',
  styleUrls: ['./date-range-picker-header/date-range-picker-header.component.scss'],
  templateUrl: './date-range-picker-header/date-range-picker-header.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class DateRangePickerHeaderComponent<D> {
  /**
   * hidden default header provided by angular mat calendar component
   */
  @ViewChildren(MatCalendarHeader) private matCalendarHeader: QueryList<MatCalendarHeader<D>>;

  /**
   * Indicates whether to show the previous button or not.
   */
  get showPreviousButton(): boolean {
    // previous button should only be shown in the first calendar
    if (this.dateRangePickerComponent.calendarDirectives?.first === this.calendarDirective) {
      // going in the past beyond the minDate should not be allowed
      // since we are using custom calendar header we need to use the previousEnabled function separately
      return this.matCalendarHeader.first.previousEnabled();
    }
    return false;
  }

  /**
   * Indicates whether to show the next button or not.
   */
  get showNextButton(): boolean {
    // next button should only be shown in the second calendar
    if (this.dateRangePickerComponent.calendarDirectives?.last === this.calendarDirective) {
      // going in the future beyond the maxDate should not be allowed
      // since we are using custom calendar header we need to use the nextEnabled function separately
      return this.matCalendarHeader.last.nextEnabled();
    }
    return false;
  }

  constructor(
    @Inject(CalendarDirective) private calendarDirective: CalendarDirective<D>,
    @Inject(DateRangePickerComponent) private dateRangePickerComponent: DateRangePickerComponent<D>
  ) {}

  /**
   * Go back to previous month.
   */
  previousClicked() {
    this.dateRangePickerComponent.addMonths(-1);
  }

  /**
   * Go to next month.
   */
  nextClicked() {
    this.dateRangePickerComponent.addMonths(1);
  }
}
