// Component: Install App

; (function (angular) {
  'use strict';

  angular.module('C.appsManagement')
    .controller('InstallAppCtrl', InstallAppCtrlFn)
    .component('installApp', {
      templateUrl: 'app/apps-management/app-store/install-app/install-app.html',
      controller: 'InstallAppCtrl',
      bindings: {
        /**
         * App information that will be installed on the selected clusters
         * from this component.
         *
         * @type {object}
         */
        app: '=',
      },
      require: {
        cModalHoc: '^^?cModalHoc',
      }
    });

  function InstallAppCtrlFn(_, $state, Cart, $rootScope, cMessage, $q, evalAJAX,
    AppStoreService, UpgradeClusterService, CHART_COLORS, ClusterService, $translate) {
    var $ctrl = this;

    _.assign($ctrl, {
      cart: Cart.newCart(
        function keyGeneratorFn(item) {
          return [item.clusterId, item.clusterIncarnationId].join();
        },
        function isForbiddenFn(item) {
          if (item._installed !== undefined) {
            switch ($ctrl.app._operationType) {
              case 'install':
                return item._installed;

              case 'upgrade':
                return !item._installed || !item._appUpgradable;

              default:
                return !item._installed;
            }
          }

          return $ctrl.app._operationType === 'uninstall' || $ctrl.app._operationType === 'upgrade';
        }
      ),
      CHART_COLORS: CHART_COLORS,
      isLoading: false,
      remoteClusters: [],
      unoperationalClusters: 0,

      // Methods
      appVersionChange: appVersionChange,
      isClustersAvailable: isClustersAvailable,
      isClusterCompatible: isClusterCompatible,
      toggleSelectAll: toggleSelectAll,

      // Component life-cycle methods
      $onInit: $onInit,
    });

    /**
     * Initializes the controller
     *
     * @method   $onInit
     */
    function $onInit() {
      if ($ctrl.cModalHoc) {
        $ctrl.cModalHoc.onModalSave = manageAppOnClusters;
        $ctrl.cModalHoc.onModalCancel = $ctrl.app.appState === 'kAvailable' ?
          installAppLater : onModalClose;
        $ctrl.cModalHoc.onModalClose = onModalClose;
      }
      _getAllClusters();
    }

    /**
     * Fetches the list of app version compatible clusters versions.
     *
     * @method   _getAllClusters
     */
    function _getAllClusters() {
      $ctrl.isLoading = true;
      var promises = {
        clusters: $rootScope.basicClusterInfo.mcmMode ?
          UpgradeClusterService.getMcmClustersForUpgrade() : $q.resolve(),
        clusterCompatibleVersionList:
          AppStoreService.getClusterCompatibleAppVersions($ctrl.app.appId),
      };

      return $q.all(promises).then(function onSuccess(responses) {
        $ctrl.clusterVersionList = responses.clusterCompatibleVersionList;

        // If there are no compatible cluster versions available then exit
        // from the function.
        if (!$ctrl.clusterVersionList.length) {
          return;
        }

        $ctrl.remoteClusters = _.differenceWith(
          responses.clusters,
          $ctrl.clusterVersionList,
          function mapCompatibleVersion(remoteCluster, cluster) {
            if (remoteCluster.clusterId === cluster.clusterInfo.clusterId) {
              if (cluster.myProdAppVersionInfo) {
                remoteCluster._prodAppVersion = {
                  'id': cluster.myProdAppVersionInfo.versionId,
                  'name': cluster.myProdAppVersionInfo.versionName,
                  'selected': true,
                };
              }

              if (cluster.LatestCompatibleAppVersionInfo) {
                remoteCluster._latestAppVersion = {
                  'id': cluster.LatestCompatibleAppVersionInfo.versionId,
                  'name': cluster.LatestCompatibleAppVersionInfo.versionName,
                  'selected': cluster.myProdAppVersionInfo ? false : true,
                  'hidden': (cluster.LatestCompatibleAppVersionInfo.versionId
                    === cluster.myProdAppVersionInfo.versionId),
                };
              }

              if (cluster.InstalledAppVersionInfo) {
                remoteCluster._installed = true;
                remoteCluster._forceReboot = false;

                if ($ctrl.app._operationType === 'upgrade') {
                  var currentVersion = cluster.InstalledAppVersionInfo.versionId;

                  // Check whether installed version is same as latest compatible version.
                  if (cluster.LatestCompatibleAppVersionInfo.versionId === currentVersion) {
                    remoteCluster._latestAppVersion = {
                      ...remoteCluster._latestAppVersion,
                      'isDuplicate': true,
                    };
                  }

                  // Check whether installed version is same as prod app version.
                  if (cluster.myProdAppVersionInfo.versionId === currentVersion) {
                    remoteCluster._prodAppVersion = {
                      ...remoteCluster._prodAppVersion,
                      'isDuplicate': true,
                    };
                  }

                  // Check whether installed version is greater than either of prod and
                  // latest compatible app version and avaiable for upgrade.
                  if (cluster.LatestCompatibleAppVersionInfo.versionId > currentVersion || cluster.myProdAppVersionInfo.versionId > currentVersion) {
                    remoteCluster._appUpgradable = true;
                  }
                }
              }
            }
          }
        );

        // Filtering the clusters based on the operation type for each clusters.
        $ctrl.remoteClusters = $ctrl.remoteClusters.filter((cluster) => {
          if ((!cluster._latestAppVersion || (cluster._latestAppVersion &&
            cluster._latestAppVersion.id === -1)) && $ctrl.app._operationType
            !== 'uninstall') {
            cluster._incompatible = true;
          }
          return !$ctrl.cart.isForbidden(cluster);
        });

        var countMap = _.groupBy($ctrl.remoteClusters,
          function getClusterCount(cluster) {
            if (cluster.softwareVersion) {
              var clusterVersion = cluster.softwareVersion &&
              ClusterService.parseSoftwareVersionString(
                cluster.softwareVersion).version;
              cluster._upgradeRequired = $ctrl.app._operationType === 'install'
                && !AppStoreService.checkUpgradability(clusterVersion);
            } else {
              cluster._upgradeRequired = false;
            }
            return (cluster._installed && cluster._installed !== undefined)
              ? 'installed' : 'uninstalled';
          }
        );

        let connectedClusters = [];
        let upgradeClusters = [];
        let notConnectedClusters = [];
        let notConnectedUpgradeClusters = [];
        $ctrl.remoteClusters.forEach((cluster) => {
          if (cluster.connectedToCluster && !cluster._upgradeRequired && !cluster._incompatible) {
            // Getting Clusters which is connected to clusters, and which does not
            // require upgrade and compatible.
            connectedClusters.push(cluster);
          } else if (cluster.connectedToCluster && (cluster._upgradeRequired || cluster._incompatible)) {
            // Getting Clusters which is connected to clusters, and which requires
            // cluster upgrade and incompatible.
            upgradeClusters.push(cluster);
          } else if (!cluster.connectedToCluster && !cluster._upgradeRequired && !cluster._incompatible) {
            // Getting Clusters which is not connected to clusters, and which does not requires
            // cluster upgrade and compatible.
            notConnectedClusters.push(cluster);
          } else {
            // Getting Clusters which is not connected to clusters.
            notConnectedUpgradeClusters.push(cluster);
          }
        });

        // Merging all the clusters in the required form(sortable format).
        $ctrl.remoteClusters = connectedClusters.concat(upgradeClusters, notConnectedClusters, notConnectedUpgradeClusters);

        countMap.upgraded = _.groupBy($ctrl.remoteClusters,
          function getUpgradedClusterCount(cluster) {
            if (isClusterCompatible(cluster)) {
              return (cluster._installed && cluster._installed !== undefined)
                ? 'installed' : 'uninstalled';
            };
          }
        );

        if ($ctrl.app._operationType !== 'install') {
          $ctrl.unoperationalClusters = (countMap.uninstalled &&
            countMap.uninstalled.length) || 0;
        } else {
          if ($ctrl.app.clusters && !$ctrl.remoteClusters.length) {
            $ctrl.cModalHoc.resolve.actionButtonKey = undefined;
            $ctrl.cModalHoc.resolve.closeButtonKey = undefined;
          }
          $ctrl.unoperationalClusters = (countMap.installed &&
            countMap.installed.length || 0) + (countMap.upgraded.uninstalled
              && countMap.upgraded.uninstalled.length || 0);
        }
      })
      .finally(function gotClusterDetails() {
        $ctrl.isLoading = false;
      });
    }

    /**
     * checks whether clusters are compatible for selection or not
     *
     * @param    {object} cluster cluster info
     * @method   isClusterCompatible
     * @return   {boolean}   return boolean value based on cluster and app
     *                       version compatibility.
     */
    function isClusterCompatible(cluster) {
      if (cluster._incompatible && cluster._incompatible !== undefined) {
        return cluster._incompatible;
      }

      return (cluster._upgradeRequired ? true : !cluster._latestAppVersion
        && !cluster._prodAppVersion);
    }

    /**
     * Provides transformed cluster list
     *
     * @method   untransformClusterList
     * @param    {Array} clusterList   list of selected clusters
     * @return   {Array}               transformed list of selected cluster
     *                                 objects
     */
    function untransformClusterList(clusterList) {
      return clusterList.map(function getClusterDetails(cluster) {
        var clusterObj = {
          clusterInfo: {
            clusterId: cluster.clusterId,
            incarnationId: cluster.clusterIncarnationId,
          }
        };

        // Updating payload fields for app install and upgrade.
        if ($ctrl.app._operationType !== 'uninstall') {
          var appVersion = cluster._prodAppVersion && cluster._prodAppVersion.id;

          if (!cluster._prodAppVersion || cluster._prodAppVersion.isDuplicate || cluster._latestAppVersion.selected) {
            appVersion = cluster._latestAppVersion.id;
          }

          clusterObj.appInfo = {
            'appVersionId': appVersion,
          };

          // Updating fields for app upgrade.
          if ($ctrl.app._operationType === 'upgrade') {
            clusterObj.appInfo = {
              ...clusterObj.appInfo,
              'forceUpdate': cluster._forceReboot,
            };
          }
        }
        return clusterObj;
      })
    }

    /**
     * checks whether clusters are available for selection
     *
     * @method   isClustersAvailable
     * @return   {boolean}   return boolean value based on cluster available and
     *                       operation type
     */
    function isClustersAvailable() {
      if ($ctrl.app._operationType === 'install') {
        return !$ctrl.remoteClusters.length;
      }
      return false;
    }

    /**
     * selects and unselects all available clusters for an app
     *
     * @method   toggleSelectAll
     */
    function toggleSelectAll() {
      if ($ctrl.cart.cartList.length
        === ($ctrl.remoteClusters.length - $ctrl.unoperationalClusters)) {
        $ctrl.cart.removeAll();
      } else {
        // Select all clusters which has compatible cluster and app version.
        $ctrl.cart.addAll(
          _.filter(
            $ctrl.remoteClusters,
            function getUpgradedClusters(cluster) {
              return !cluster._upgradeRequired && !cluster._incompatible && (cluster._latestAppVersion || cluster._prodAppVersion);
            }
          )
        );
      }
    }

    /**
     * Method called on change of app version selection.
     *
     * @method   appVersionChange
     * @param {number} index   selected cluster index
     */
    function appVersionChange(index) {
      this.remoteClusters[index]._prodAppVersion.selected =
        !this.remoteClusters[index]._prodAppVersion.selected;
      this.remoteClusters[index]._latestAppVersion.selected =
        !this.remoteClusters[index]._latestAppVersion.selected;
    }

    /**
     * go to apps management page
     *
     * @method  goToAppListPage
     */
    function goToAppListPage() {
      $state.go('apps-management', {}, { reload: true });
    }

    /**
    * close the modal or redirect user to apps page
    *
    * @method  onModalClose
    */
    function onModalClose() {
      if ($ctrl.cModalHoc.bindings.inModal) {
        return $state.reload();
      }
      goToAppListPage();
    };

    /**
    * provides list of clusters required for app purchase/installation
    *
    * @method  getClusterList
    * @return {object}   returns object which has list of clusters information
    */
    function getClusterList() {
      return {
        clusterAppInfoArr: untransformClusterList($ctrl.cart.cartList)
      };
    }

    /**
     * purchase an app for selected clusters
     *
     * @method   purchaseAppOnSelectedClusters
     * @return   {promise}                       returns promise based on the
     *                                           operation type
     */
    function purchaseAppOnSelectedClusters() {
      $ctrl.isLoading = true;
      var params = {
        appId: $ctrl.app.appId,
        version: $ctrl.app.version,
        operationType: $ctrl.app._operationType,
      };

      return AppStoreService.purchaseApp(params).then(
        function appPurchaseSuccess(response) {
          cMessage.success({
            titleKey: 'apps.purchaseSuccessTitle',
          });
          goToAppListPage();
        }, evalAJAX.errorMessage)
        .finally(function appPurchaseDone() {
          $ctrl.isLoading = false;
        });
    }

    /**
     * purchase/install/upgrade/uninstall app for selected clusters
     *
     * @method   modifyAppState
     * @return   {promise}        returns promise based on the operation
     *                            type
     */
    function modifyAppState() {
      if ($ctrl.app._isEulaAccepted) {
        $ctrl.isLoading = true;
        var appOperationInfo = {
          install: {
            _titleKey: 'apps.installSuccessTitle',
            _requestType: 'post',
          },
          uninstall: {
            _titleKey: 'apps.uninstallSuccessTitle',
            _requestType: 'delete',
          },
          upgrade: {
            _titleKey: 'apps.upgradeSuccessTitle',
            _requestType: 'put',
          },
        };
        var params = {
          appId: $ctrl.app.appId,
          operationType: $ctrl.app._operationType,
          requestType: appOperationInfo[$ctrl.app._operationType]._requestType,
          version: $ctrl.app.version,
        };

        var clusterDetails = getClusterList();

        return AppStoreService.manageApp(params, clusterDetails).then(
          function manageAppSuccess(response) {
            cMessage.success({
              titleKey: appOperationInfo[$ctrl.app._operationType]._titleKey,
            });
            goToAppListPage();
          }, evalAJAX.errorMessage)
          .finally(function manageAppDone() {
            $ctrl.isLoading = false;
          });
      }

      $ctrl.app._isEulaAccepted = true;
      // Making the Terms and condition null here when click of agree and continue.
      $ctrl.cModalHoc.resolve.titleKey = null;
      $ctrl.cModalHoc.resolve.customTitle = $translate.instant('apps.selectClustersToInstallApp', { appName: $ctrl.app.appName });
      $ctrl.cModalHoc.resolve.actionButtonKey = 'install';
      $ctrl.cModalHoc.resolve.closeButtonKey = 'installLater';
      return $q.defer().promise;
    }

    /**
     * install app for selected clusters
     *
     * @method   manageAppOnClusters
     * @return   {function}   The function which will be called to install app
     *                        on selected clusters
     */
    function manageAppOnClusters() {
      return modifyAppState();
    }

    /**
     * purchase app to be installed on selected clusters for later
     *
     * @method   installAppLater
     * @return   {function}   The function which will be called to purchase app
     *                        on selected clusters and install for later
     */
    function installAppLater() {
      if ($ctrl.app._isEulaAccepted) {
        $ctrl.app._operationType = 'purchased';
        return purchaseAppOnSelectedClusters();
      }
      return $state.reload();
    }

  }
})(angular);
