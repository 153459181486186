<ng-content></ng-content>

<!-- Select Column is automatically added whenever a selection model is present -->
<ng-container #selectColumn matColumnDef="select">

  <!-- If is flex is set, use the mat-header-cell and mat-cell elements, which
    are based on flex box -->
  <ng-container *ngIf="isFlex">
    <mat-header-cell *matHeaderCellDef>
      <ng-container *ngTemplateOutlet="defaultSelectAllTemplate">
      </ng-container>
    </mat-header-cell>

    <mat-cell *matCellDef="let row; index as i">
      <ng-container *ngTemplateOutlet="defaultSelectRowTemplate;
            context: {
              $implicit: {
                row: row,
                index: i
              }
            }">
      </ng-container>
    </mat-cell>
  </ng-container>

  <!-- If this isn't a flex table, use the standard html table elements -->
  <ng-container *ngIf="!isFlex">
      <th mat-header-cell *matHeaderCellDef>
        <ng-container *ngTemplateOutlet="defaultSelectAllTemplate">
        </ng-container>
      </th>

      <td mat-cell *matCellDef="let row; index as i">
        <ng-container *ngTemplateOutlet="defaultSelectRowTemplate;
            context: {
              $implicit: {
                row: row,
                index: i
              }
            }">
        </ng-container>
      </td>
    </ng-container>
</ng-container>

<!-- Default template for the select all checkbox. This should be hidden for single select tables -->
<ng-template #defaultSelectAllTemplate>
  <mat-checkbox id="{{name}}-select-all"
    *ngIf="selection.isMultipleSelection()"
    (change)="$event && toggleSelectAll()"
    [checked]="selection.hasValue() && isAllSelected()"
    [indeterminate]="selection.hasValue() && !isAllSelected()"
    [disabled]="!canSelectAnyRows()">
  </mat-checkbox>
</ng-template>

<!-- Default template for select row. This shows a checkboxes or radio button depending
  on the selection model. -->
<ng-template #defaultSelectRowTemplate let-ctx>
  <mat-checkbox
    id="{{name}}-select-row-{{ctx.index}}"
    *ngIf="selection?.isMultipleSelection()"
    (change)="$event && selection.toggle(ctx.row)"
    [checked]="selection.isSelected(ctx.row)"
    [disabled]="!canSelectRow(ctx.row)">
  </mat-checkbox>

  <mat-radio-button id="{{name}}-select-row-{{ctx.index}}"
    *ngIf="!selection?.isMultipleSelection()"
    (change)="$event && selection.toggle(ctx.row)"
    [checked]="selection.isSelected(ctx.row)"
    [disabled]="!canSelectRow(ctx.row)">
  </mat-radio-button>
</ng-template>
