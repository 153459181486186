<div
  *ngIf="banners.length > 0"
  role="region"
  [attr.aria-label]="getLabel('title')"
  class="banners-container active-banner-{{ bannerIndex }} {{ banners.get(bannerIndex)?.status || 'info' }}">
  <div class="banners-navigator" *ngIf="banners.length > 1">
    <button
      cogDataId="prev-banner"
      mat-icon-button
      type="button"
      [attr.aria-label]="getLabel('previous')"
      (click)="goPrev()">
      <cog-icon shape="chevron_left"></cog-icon>
    </button>
    <div class="navigator-text">
      {{ (bannerIndex + 1 | number) + '/' + (banners.length | number) }}
    </div>
    <button
      cogDataId="next-banner"
      mat-icon-button
      type="button"
      [attr.aria-label]="getLabel('next')"
      (click)="goNext()">
      <cog-icon shape="chevron_right"></cog-icon>
    </button>
  </div>

  <div class="banners-content">
    <ng-content></ng-content>
    <ng-template #bannerTemplate></ng-template>
  </div>

  <div class="banners-close-btn" *ngIf="showCloseButton">
    <button
      cogDataId="close-banner"
      mat-icon-button
      type="button"
      [attr.aria-label]="getLabel('dismiss')"
      (click)="close()">
      <cog-icon shape="close"></cog-icon>
    </button>
  </div>
</div>
