import { Inject, Injectable } from '@angular/core';
import { chain, toString } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { SharepointItemMetadataType } from '@cohesity/api/private';
import { AutoDestroyable } from '@cohesity/utils';

import { Document, DocumentsResponse, DocumentStat } from './folder-browser.models';
import { FolderBrowserProvider, folderBrowserToken } from './folder-browser.provider';

/**
 * Service to implement Folder-Browser. Provides the utility functions to
 * use folder browsing for any component. Maintains internal cache to speed up
 * the browsing.
 *
 * @example
 *    1. Create a service (fbProviderService) which provides the folder browser
 *       service the listFiles/listVolumes funcitons. (which makes the API call)
 *       => export class fbProviderService implements FolderBrowserProvider {
 *            listFiles: () => API response
 *            listVolumes?: () => API response.
 *            usesVolumes: true for calling listVolumes instead of listFiles.
 *          }
 *
 *    2. In the component that you need to use folder browsing, provide the
 *       required providers
 *       => providers: [
 *            folderBrowserProviderFn(fbProviderService),
 *            FolderBrowserService,
 *          ],
 *
 *    3. Use the folder browser methods inside the component by importing it in
 *       the constructor
 *       => constructor(public fbContext: FolderBrowserService)
 *       => fbContext.browseToPath()
 *          fbContext.currentDirectory$
 *          fbContext.currentPath
 *          fbContext.isLoading
 *          fbContext.directoryCookie
 *          fbContext.volumeCookie
 *          fbContext.getMenuItems()
 *          fbContext.reset()
 */
@Injectable()
export class FolderBrowserService extends AutoDestroyable {
  /**
   * Observable of the files currently being shown.
   */
  currentDirectory$ = new BehaviorSubject([]);

  /**
   * The currently selected path.
   */
  currentPath: string;

  /**
   * Specifies if file browser does not have any supported root level directories to browse.
   * TODO(Tauseef): Handle the addition of non-browsable volumes.
   */
  hasNoSupportedVolumes = false;

  /**
   * To show the loader or not.
   */
  isLoading$ = new BehaviorSubject(false);

  /**
   * Number of records to return in an api call.
   */
  maxEntries = 100;

  /**
   * Gets the directory cookie for the current path. If it exists, this means we're in a large folder,
   * and will need to use the cookie to fetch the next set of files.
   *
   * Note - Using lodash's toString method as sometimes the cookie is '0'
   * and we added a direct check which returned false. This also takes care of
   * null/undefined.
   */
  get directoryCookie(): string {
    const currentPath = this.currentPath || '/';
    return toString(this.directoryCache[currentPath]?.cookie);
  }

  /**
   * Gets the volume cookie.
   *
   * Note - Using lodash's toString method as sometimes the cookie is '0'
   * and we added a direct check which returned false. This also takes care of
   * null/undefined.
   */
  get volumeCookie(): string {
    return toString(this.volumeCache?.cookie);
  }

  /**
   * Cache all directories, keyed by path. If we need to fetch more values for a directory, they will be updated here.
   */
  private directoryCache: {
    [path: string]: DocumentsResponse;
  } = {};

  /**
   * Cache the volume response
   */
  private volumeCache: DocumentsResponse;

  /**
   * Inject folderBrowserProvider to use the service for API calls etc.
   */
  constructor(@Inject(folderBrowserToken) private folderBrowserProvider: FolderBrowserProvider) {
    super();
  }

  /**
   * Browses to the specified path. If the path is not set, then it should browse to the
   * list of volumes. This can also be used to load more items for the curerntly selected
   * path.
   *
   * @param   fullPath   The full path to browse to.
   * @param   fetchMore  If true, this will attempt to load more items.
   */
  browseToPath(fullPath?: string, fetchMore?: boolean) {
    // If full path is not set we need to set it to / here, otherwise the directory cookies will not work for
    // folders with larger than 1k files.
    if (!this.folderBrowserProvider.usesVolumes) {
      fullPath = fullPath || '/';
    }
    this.getDirectories(fullPath, fetchMore)
      .pipe(this.untilDestroy())
      .subscribe(results => {
        // if fetchMore, then the next page of documents is saved in the directory
        // cache along with its current contents. Return the documents from the cache.
        if (fetchMore) {
          this.currentDirectory$.next(this.directoryCache[fullPath].documents);
        } else {
          this.currentDirectory$.next(results);
        }
        this.currentPath = this.trimPrefix(fullPath);
      });
  }

  /**
   * Look up stats for a file, to check if it exists and what kind of file it is.
   *
   * @param fullPath The full path to check
   * @param keepLoaderEnabled Incase of cascading calls, this boolean
   *                          determines whether getFileStat(...) should
   *                          disable the loader.
   * @returns  An observable with the DocumentStat response.
   */
  getFileStat(fullPath: string, keepLoaderEnabled: boolean): Observable<DocumentStat> {
    fullPath = fullPath || '/';
    if (this.volumeCache?.documents.find(vol => vol.name === fullPath)) {
      return of({
        type: 'Volume'
      });
    }

    this.isLoading$.next(true);
    return this.folderBrowserProvider.statFile(fullPath, this.splitPath(fullPath), {
      volumeCookie: this.volumeCookie,
    }).pipe(
      // This will through if the path does not exist. The result will be null and the UI can show an error message
      catchError(() => of(null)),
      finalize(() => {
        // FileStat may additionally be called during a reset triggered by a
        // snapshot change. In such cases fileStat is followed by browseToPath
        // call and the loader should be kept visible.
        if (!keepLoaderEnabled) {
          this.isLoading$.next(false);
        }
      })
    );
  }

  /**
   * Fetches a list of files to use as a drop down menu, the items should be siblings to the selected
   * path. This is passed as a function in the html template, so it is defined as a property on the class
   * in order to maintain it's this context.
   *
   * @param   fullPath   The path of the item to show sibling of, starting with the volume name
   * @returns An observable of File[]
   */
  getMenuItems = (fullPath: string) => {
    if (!fullPath && this.folderBrowserProvider.usesVolumes) {
      return of(this.volumeCache.documents);
    }
    fullPath = fullPath || (this.folderBrowserProvider.usesVolumes && '/');
    return this.getDirectories(fullPath)
      .pipe(map(files => files.filter(file => file.isFolder)));
  };

  /**
   * Returns the sorting criteria for browse workflows.
   * By default, sorts the documents by folder and name.
   *
   * In SharePoint Sites, folders (in alphabetical order) should be displayed above
   * lists (also in alphabetical order).
   *
   * @param document  The document currently under consideration: files, folders, SP Lists, etc.
   * @returns A list of keys used for sorting.
   */
  private getSortingCriteria = (doc: Document): (string|boolean)[] => {
    const doctype: number = doc.dirEntry?.fstatInfo?.sharepointItemMetadata?.type ?? -1;

    // True iff the document is a SharePointList.
    const isSharePointList: boolean = (doctype === SharepointItemMetadataType.kSiteList);
    return [!doc.isFolder, isSharePointList, doc.name.toLowerCase()];
  };

  /**
   * Function which calls the API if cookie is not set or returns the cached
   * documents.
   *
   * @param   fullPath   The full path to browse to, starting with the volume name.
   * @param   fetchMore  If true, this will attempt to load more items. It will throw an
   *                     error if the cookie is not set.
   * @param keepLoaderEnabled Incase of cascading calls, this boolean
   *                          determines whether getDirectories(...) should
   *                          disable the loader.
   */
  private getDirectories(fullPath: string,
    fetchMore?: boolean,
    keepLoaderEnabled?: boolean) {
    const shouldUseVolumeCache = !fullPath && this.folderBrowserProvider.usesVolumes;
    fullPath = fullPath || '/';
    switch (true) {
      case (shouldUseVolumeCache && !!this.volumeCache): {
        return of(this.volumeCache.documents);
      }
      case (!shouldUseVolumeCache && !!this.directoryCache[fullPath] && !fetchMore): {
        return  of(this.directoryCache[fullPath].documents);
      }
      // Throws an error if fetchMore is true and no cookie is set.
      case !shouldUseVolumeCache && fetchMore && !this.directoryCookie: {
        throw new Error('trying to fetch more items but none are available');
      }
      default: {
        const listDirectoryFn = shouldUseVolumeCache ? this.folderBrowserProvider.listVolumes() :
          this.folderBrowserProvider.listFiles(
            fullPath,
            fetchMore,
            this.splitPath(fullPath),
            {
              directoryCookie: this.directoryCookie,
              volumeCookie: this.volumeCookie,
            },
            this.maxEntries);
        this.isLoading$.next(true);

        return listDirectoryFn
          .pipe(
            tap(res => {
              // Add the key 'isFolder' and sort the documents by folder and
              // name by default.
              // Also, add the key sanitizedPath that can be used to display
              // paths in UI having prefixes.
              res.documents = chain(res.documents)
                .map(document => ({
                  ...document,
                  isFolder: ['Volume', 'Directory'].includes(document.type),
                  sanitizedPath: this.trimPrefix(fullPath),
                }))
                .sortBy(document => this.getSortingCriteria(document))
                .value();

              if (shouldUseVolumeCache) {
                // Show the root volume list. Set hasNoSupportedVolumes to true if there are no volumes.
                this.hasNoSupportedVolumes = res.documents.length === 0;
                this.volumeCache = res;
              } else {
                if (!fetchMore) {
                  this.directoryCache[fullPath] = res;
                } else {
                  this.directoryCache[fullPath] = {
                    cookie: res.cookie || null,
                    documents: this.directoryCache[fullPath].documents.concat(...res.documents),
                  };
                }
              }
            }),
            map(res => res.documents),
            finalize(() => {
              // Incase of Snapshot change after user has browsed certain path,
              // the browse is attempted to start from the current path in
              // view on the selected snapshot. In such cases, the loader is
              // to be kept enabled.
              if (!keepLoaderEnabled) {
                this.isLoading$.next(false);
              }
            }));
      }
    }
  }

  /**
   * Splits a path into volume and path components.
   * This matches the beginning of the path to the list of cached volumes to determine
   * the volume name. The path info alone is not sufficient for extracting the volume name.
   *
   * TODO(tauseef): Make this splitPath compatible with different FS.
   *
   * @param   fullPath   The path, including the volume.
   * @returns An array of the volume and path. If there are no volumes, the first array entry
   *          will be null.
   */
  splitPath(fullPath: string = '/'): [string, string] {
    if (!this.volumeCache || !this.folderBrowserProvider.usesVolumes) {
      return [null, fullPath];
    }
    // sort volume cache by longest first.
    const volumes = this.volumeCache.documents.map(vol => vol.name).sort((a, b) => b.length - a.length);
    let beg = 0;
    // 'path' will actually store the path of the item inside the best matched
    // 'volume'. The best matched volume will be the one which is actually
    // present in the this.currentPath as a whole.
    let volume, path, volFound = false, checkCurrentPath = false;
    do {
      const vols = volumes.slice(beg);
      const volumeInd = vols.findIndex(vol => fullPath.startsWith(vol));
      volume = vols[volumeInd];
      path = fullPath.substr(volume?.length || 0);
      if (!path.startsWith('/')) {
        path = '/' + path;
      }
      beg = volumeInd + 1;

      // if this.currentPath is a substring of fullPath, we need to check it
      // for whole volume matching also.
      if(this.currentPath) {
        if(fullPath.includes(this.currentPath)){
          checkCurrentPath = true;
        }
      }

      if (checkCurrentPath) {
        // check if the current selected 'volume' (from volumes) is part of
        // this.currentPath as a whole. If yes, then return this volume and
        // path pair otherwise iterate over the volumes again to find the next
        // best match.
        volFound = this.currentPath.includes(volume) &&
          (volume === '/' || this.currentPath[this.currentPath.indexOf(volume)
          + volume.length] === undefined ||
          this.currentPath[this.currentPath.indexOf(volume) +
          volume.length] === '/');
      }
    } while (checkCurrentPath && !volFound);

    return [volume, path];
  }

  /**
   * Resets the cache on destroy.
   */
  reset() {
    this.volumeCache = null;
    this.directoryCache = {};

    // When we reset the snapshot, we should maintain the current path if possible. However, because we have just
    // reset the volume cache, we may need to fetch the volumes again (if applicable) on the new snapshot before we
    // can properly to a file stat. If the file is present, then we can stay on the same folder, otherwise, we can
    // go back to the root folder.
    const volumes$ = this.folderBrowserProvider.usesVolumes ?
      this.getDirectories(null, false, true) : of(null);
    volumes$.pipe(
      switchMap(() => {
        // If the reset is triggered without any path(client is viewing only
        // the volumes), do not make a stat call & disable loader.
        if (!this.currentPath) {
          this.isLoading$.next(false);
          return of(null);
        }
        return this.getFileStat(this.currentPath, true);
      }),
    ).subscribe(stat => {
      const newPath = ['Volume', 'Directory'].includes(stat?.type) ? this.currentPath : null;
      this.browseToPath(newPath);
    }, () => this.browseToPath(null));
  }

  /**
   * Resets the cache on destroy in case of O365.
   */
   resetO365() {
    this.volumeCache = null;
    this.directoryCache = {};
    this.currentDirectory$.next([]);

    // When we reset the snapshot in case of O365, we don't make the stat call,
    // therefore we don't have info about the validity of current path, so we
    // reset the browse to root directory.
    this.browseToPath(null);

  }


  /**
   * TODO(Vipul): Create a separate component for O365 on lines of
   * volume-selection-detail component and move this logic there
   *
   * Trims prefix for fullPaths and populates sanitizedPath
   * e.g. {/OneDrives/Onedrive-<driveId>/metadata/rocksdb/}.../<name>
   * The entire string inside {} is a prefix to be removed in case of O365
   * non-indexed browse. This is for display purposes only as we don't want to
   * expose these prefixes to the user.
   *
   * @returns a string with fullPath sanitized of prefixes
   */
  trimPrefix(path: string): string {
    const path_tokens = path?.split('/');
    if (path_tokens?.length > 4 && path_tokens[3] === 'metadata' &&
      path_tokens[4] === 'rocksdb') {
      path = '/' + path_tokens.slice(5).join('/');
    }
    return path;
  }
}
