import { ComponentRef, Type } from '@angular/core';
import { AbstractControl, FormControlStatus } from '@angular/forms';

import { FormSectionComponent } from './form-section.component';

export interface BuilderConfig {
  sections: Type<FormSectionComponent<any, any, any>>[];
  saveForm: () => void;
  goBack: () => void;
  canShowSaveForm: () => boolean;
}

export interface FormSectionRef<FormModel> {
  name: keyof FormModel;
  componentRef: ComponentRef<FormSectionComponent<any, any, any>>;
}

export type FormSectionRefByName<FormModel> = Record<keyof FormModel, FormSectionRef<FormModel>>;

export type FormBuilderControl<D> = {[key in keyof D]: AbstractControl<D[key]|null>} | null;

export type FormBuilderControlValues<D> = {[key in keyof D]: D[key]};

export interface FormGroupValue<Key, Value> {
  key?: Key;
  value: Value;
  status: FormControlStatus;
  valid: boolean;
  invalid: boolean;
  enabled: boolean;
  disabled: boolean;
  mode: FormSectionMode;
}

export type FormGroupValueMap<FormModel> = {
  [Key in keyof FormModel]: FormGroupValue<Key, FormModel[Key]>;
};

export type FormSectionMode = 'view' | 'edit';

export class ControlFlow<FormModel> {
  get formControl() {
    return this.formSectionRef.componentRef.instance.formControl;
  }

  constructor(
    public formSectionRef: FormSectionRef<FormModel>,
    public isValidValue: () => boolean,
    public childrens: ControlFlow<FormModel>[] = [],
  ) {}
}

export const enableControlFlow = <FormModel>(node: ControlFlow<FormModel>) => {
  node.formSectionRef.componentRef.instance.formControl.enable({ emitEvent: false });
};

export const disableControlFlow = <FormModel>(node: ControlFlow<FormModel>) => {
  node.formSectionRef.componentRef.instance.formControl.disable({ emitEvent: false });
};

export const disableAllDescendantControlFlow = <FormModel>(node: ControlFlow<FormModel>) => {
  (node.childrens || []).forEach(children => {
    disableControlFlow(children);
    disableAllDescendantControlFlow(children);
  });
};

export const pruneControlFlow = <FormModel>(
  node: ControlFlow<FormModel>,
  filterCondition: (cNode: ControlFlow<FormModel>) => boolean = () => true,
) => {
  if (!filterCondition(node)) {
    return null;
  }

  return {
    ...node,
    childrens: (node.childrens || []).filter(children => pruneControlFlow(children, filterCondition)),
  };
};

export const forEachControlFlow = <FormModel>(
  node: ControlFlow<FormModel>,
  callback: (cNode: ControlFlow<FormModel>) => boolean = () => true,
) => {
  if (callback(node)) {
    (node.childrens || []).forEach(children => {
      forEachControlFlow(children, callback);
    });
  }
};

export const enableImmediateChildrensControlFlow = <FormModel>(node: ControlFlow<FormModel>) => {
  (node.childrens || []).forEach(children => enableControlFlow(children));
};
