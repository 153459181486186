<coh-protection-source-detail [nodeContext]="nodeContext">
  <!--  Azure SaaS connection badge -->
  <div class="warning-container" *ngIf="showInfoBadge">
    <cog-icon class="margin-right-sm" shape="helix:status-error!critical" size="sm"></cog-icon>
    <cog-info-badge [label]="('sources.azure.withoutSaasConnection' | translate)" state="warning">
    </cog-info-badge>
  </div>
  <coh-protection-source-metadata
    class="metadata"
    [compact]="true"
    [nodeContext]="nodeContext">
  </coh-protection-source-metadata>
</coh-protection-source-detail>
