import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UdaConnectParams } from '@cohesity/api/v1';
import { UdaRegistrationParams } from '@cohesity/api/v2';
import { FormField, FormPanel } from '@cohesity/helix';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { fromControlValueChange, genericFormFieldValidationErrorFn } from '@cohesity/shared-forms';
import { TranslateService } from '@ngx-translate/core';
import {
  Controls,
  NgxSubFormComponent,
  subformComponentProviders,
  takeUntilDestroyed,
  TypedFormGroup,
} from 'ngx-sub-form';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

import { DynamicFormComponent } from 'src/app/modules/restore/restore-shared';
import {
  convertFieldValues,
  DynamicForm,
  getCustomFieldDefaultValue,
  getHostOSTypeLabelKey,
  UdaConnectorConfigService,
  UdaCustomArgs,
  UdaSourceTypeOSConfig,
} from 'src/app/shared';
import { UdaRegistrationFormComponentProperties } from 'src/app/modules/sources-shared/models';
import { UdaSourceContextService } from 'src/app/modules/sources-shared/services/uda-source-context.service';
import { ManagedSourceFormParams } from './models/managed-source-form.params';

/**
 * Source registration form component for UDA connectors for which the UI is
 * managed by the framework. These include connectors which rely on the default
 * UI or UIs managed via the 'Dynamic Config' framework.
 */
@Component({
  selector: 'coh-uda-managed-source',
  templateUrl: './managed-source.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [subformComponentProviders(UdaManagedSourceComponent)]
})
export class UdaManagedSourceComponent
  extends NgxSubFormComponent<ManagedSourceFormParams>
  implements OnInit, AfterContentInit {

  /**
   * The parent form group.
   */
  @Input() parent: TypedFormGroup<ManagedSourceFormParams>;

  /**
   * The parent form control.
   */
  @Input() formControl: AbstractControl;

  /**
   * Whether the form has been rendered in edit mode.
   */
  @Input() editMode = false;

  /**
   * UDA source params. These will only be available if an existing source is
   * being edited.
   */
  @Input() udaParams: UdaConnectParams;

  /**
   * Specifies if UDA source supports dynamic config. Defaults to true which
   * means it is assumed that newly registered source will always support
   * dynamic config.
   */
  isDynamicConfigSupported = true;

  /**
   * Whether UDA Dynamic Config feature flag is enabled or not.
   */
  isDynamicConfigFeatureEnabled = flagEnabled(this.contextService.irisContext, 'udaDynamicConfig');

  /**
   * Function that return the label key for a given host os type.
   */
  getHostOSTypeLabelKey = getHostOSTypeLabelKey;

  /**
   * Stores the registration custom field parameters received from api call in edit mode.
   */
  udaEditCustomArgs: UdaCustomArgs = {};

  /**
   * Stores the config for registration workflow.
   */
  udaRegistrationConfig: UdaSourceTypeOSConfig['registrationWorkflow'];

  /**
   * Callback function to show validation errors for generic form field.
   */
  validationErrorFn = genericFormFieldValidationErrorFn(
    (key, params?) => this.translate.instant(key, params)
  );

  constructor(
    private cdr: ChangeDetectorRef,
    private contextService: IrisContextService,
    private translate: TranslateService,
    public configService: UdaConnectorConfigService,
    public udaSourceContextService: UdaSourceContextService,
  ) {
    super();
  }

  ngOnInit() {
    if (this.editMode) {

      // Set the dynamic config based on the source in the edit mode.
      this.isDynamicConfigSupported = this.udaParams?.capabilities?.dynamicConfig ?? false;

      // Convert array of objects having properties 'key' and 'value' as
      // strings to object of 'key, value' pair. The conversion of string value
      // to specific data type happens implicitly once assigned to the form
      // control.
      (this.udaParams?.sourceRegistrationArguments ?? []).forEach((keyVal) => {
        this.udaEditCustomArgs[keyVal.key] = keyVal.value as string;
      });
    }
  }

  ngAfterContentInit() {
    if (this.isDynamicConfigFeatureEnabled) {
      // OS type is mandatory if UDA Dynamic Config support is enabled, else we
      // make this optional. Host OS type selector is hidden when the feature
      // flag is diabled.
      this.formGroupControls.hostOsType.setValidators(Validators.required);

      // On change of OS type selection, reset the rest of the form.
      combineLatest([
        fromControlValueChange(this.formGroupControls.hostOsType, true /** emitOnNull */),
        this.configService.udaSourceHostOsTypeToConfigs$
      ])
        .pipe(
          filter(([hostOsType, configMap]) => Boolean(hostOsType) && Boolean(configMap)),
          takeUntilDestroyed(this)
        )
        .subscribe(([hostOsType, configMap]) => {
          // Remove all the controls every time the host OS type changes to
          // ensure no stale values exist.
          Object.keys(this.formGroupControls.sourceRegistrationArguments?.controls || {}).forEach(key => {
            (this.formGroupControls.sourceRegistrationArguments as UntypedFormGroup).removeControl(key);
          });

          this.udaRegistrationConfig = configMap.get(
            this.udaSourceContextService.selectedSourceType + hostOsType
          )?.registrationWorkflow;

          if (this.isDynamicConfigSupported) {
            if (!this.editMode) {
              this.setPrimaryFieldDefaultValues(this.udaRegistrationConfig?.primaryFields);
            }
            this.createDynamicFormFieldControls(this.udaRegistrationConfig?.dynamicForm);
          }

          this.formGroup.updateValueAndValidity();
          this.cdr.detectChanges();
        });
    } else {
      this.formGroupControls.hostOsType.clearValidators();
    }
  }

  /**
   * Sets the default values fetched from dynamic configs for the primary
   * fields in the non edit mode.
   *
   * @param  primaryFields  Config parameters to customize primary fields.
   */
  setPrimaryFieldDefaultValues(primaryFields: UdaRegistrationParams['primaryFields']): void {

    // Capture the current form value to extract the defaults set by the
    // component at this stage.
    const formValue = this.formGroupValues.primaryFieldParams;
    this.formGroupControls.primaryFieldParams.setValue({
      hosts: formValue?.hosts ?? [],
      mountDir: null,
      mountView: primaryFields?.mountView?.defaultValue ?? false,
      showCustomOptions: false,
      credentials: formValue?.credentials ?? null,
      scriptDir: primaryFields?.scriptDir?.defaultValue ?? null,
      sourceArgs: primaryFields?.sourceRegistrationArgs?.defaultValue ?? null
    });
  }

  /**
   * This method creates the custom field controls and also sets the default
   * values. In non-edit mode, the optional default values are fetched from the
   * dynamic config. Whereas in edit mode, these values come from the backend.
   *
   * @param   dynamicForm   Config parameters for the custom fields
   */
  private createDynamicFormFieldControls(dynamicForm: DynamicForm): void {
    if (!dynamicForm) {
      return;
    }
    // Set the form control for the custom fields based on selected source
    // type and host os type.
    (dynamicForm.panels ?? []).forEach((panel) => {
      (panel.fields ?? []).forEach((field) => {
        this.addFormControlForFormField(field);
      });
    });
  }

  /**
   * Helper method to process the given form field configuration and add a corresponding
   * form control for it to the 'sourceRegistrationArguments' form group. This will also
   * recursively check if the form field has any nested form panels and if so, then
   * process it's fields as well.
   *
   * (Currently only 'radioGroup' field type has support for nested form panels.)
   *
   * @param   field   The form field config for which a form control needs to be added to
   *                  the 'sourceRegistrationArguments' form group.
   */
  private addFormControlForFormField(field: FormField) {
    this.formGroupControls.sourceRegistrationArguments.addControl(
      field.key,
      new UntypedFormControl(this.getFormControlValue(field))
    );

    // For radioGroups, check if any of the associated radio buttons have an
    // optional form panel attached. If present then recursively process it's
    // linked form panels as well.
    if (field.type === 'radioGroup') {
      (field.typeConfig?.radioGroupConfig.radioButtons || []).forEach((radio) => {
        (radio?.formPanel?.fields || []).forEach((subField) => {
          this.addFormControlForFormField(subField);
        });
      });
    }
  }

  getFormControlValue(field: FormField): string | number | boolean {
    if (this.editMode) {
      // In edit mode form values are received from backend and set in the form control.
      const val = (this.udaEditCustomArgs[field.key] as string) ?? null;
      return convertFieldValues(field.type, val);
    } else {
      // In non-edit mode, fetch the default value for the control from the dynamic config.
      return getCustomFieldDefaultValue(field);
    }
  }

  /**
   * Check if the form panel needs to be rendered by default.
   * For the create mode, we always render the panel as collapsed. For the edit
   * mode, we check if any values inside the panel have been set or not. If
   * yes, then we render as expanded.
   *
   * @param   panel   Form panel configuration.
   * @returns True if the panel need to be rendered as expanded by default.
   */
  isPanelExpandedByDefault(panel: FormPanel) {
    if (!this.editMode) {
      return false;
    }

    return (panel.fields || []).some((field) => {
      const fieldKey: string = field.key;
      return !!this.udaEditCustomArgs[fieldKey];
    });
  }

  /**
   * Get the form control for a custom field based on its unique key.
   *
   * @param  key  Unique key against which the custom field value will be stored.
   * @returns form control based on the key
   */
  getControlByKey = (key: string): UntypedFormControl =>
    this.formGroupControls.sourceRegistrationArguments.get(key) as UntypedFormControl;

  /**
   * Getter for returning the raw value of the UDA primary field registration form.
   *
   * @returns Raw value for the primary field registration form.
   */
  getRawValue(): ManagedSourceFormParams {
    return this.formGroup.getRawValue();
  }

  protected getFormControls(): Controls<ManagedSourceFormParams> {
    return {
      hostOsType: new UntypedFormControl(null),
      primaryFieldParams: new UntypedFormControl(null),
      sourceRegistrationArguments: new UntypedFormGroup({}),
    };
  }
}

/**
 * Wrapper to support rendering of UDA source registration form component
 * inside dynamic form portal.
 */
@Component({
  template: `
    <coh-uda-managed-source
      [parent]="parent"
      [formControl]="formControl"
      [editMode]="properties?.editMode || false"
      [udaParams]="properties?.adapterParams || null">
    </coh-uda-managed-source>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UdaManagedSourceWrapperComponent
  implements DynamicFormComponent<UdaManagedSourceComponent, ManagedSourceFormParams> {

  /**
   * Additional properties passed for this protection item.
   */
  properties: UdaRegistrationFormComponentProperties;

  /**
   * The instance of the actual registration form component.
   */
  @ViewChild(UdaManagedSourceComponent, { static: true })
  instance: UdaManagedSourceComponent;

  constructor(
    @Inject(AbstractControl) readonly formControl: AbstractControl,
    @Inject(UntypedFormGroup) readonly parent: TypedFormGroup<ManagedSourceFormParams>
  ) {}
}
