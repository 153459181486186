import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

/**
 * Data structure for the dialog component.
 */
interface DialogContext {
  /**
   * Title of the dialog.
   */
  title?: string;

  /**
   * Custom message to be displayed in the dialog body.
   */
  message?: string;

  /**
   * If previous delete failed and threw an error. Applicable only for Force delete.
   */
  errorMessage?: string;
}

/**
 * A generic delete confirmation dialog that allows user to customize message, title, and button labels.
 */
@Component({
  selector: 'coh-force-delete-confirmation-dialog',
  templateUrl: './force-delete-confirmation-dialog.component.html',
  styleUrls: ['./force-delete-confirmation-dialog.component.scss']
})
export class ForceDeleteConfirmationDialogComponent {
  /**
   * Title of dialog.
   */
  title: string;

  /**
   * Message/Body of dialog.
   */
  message: string;

  /**
   * Cancel button label. Default to 'Cancel'.
   */
  cancelLabel = 'cancel';

  /**
   * Confirmation button label. Default to 'Delete'.
   */
  confirmLabel = 'forceRemoveConnections';

  /**
   * The keyword the user has to type by default.
   */
  keywordLabel = 'yes';

  /**
   * Form group to validate.
   */
  formGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ForceDeleteConfirmationDialogComponent>,
    private fb: FormBuilder,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public context: DialogContext
  ) {
    if (context) {
      if (context.message) {
        this.message = context.message;
      }
      if (context.title) {
        this.title = context.title;
      }
    }
    this.formGroup = this.fb.group({
      confirmDelete: new FormControl(null, [
        Validators.required,
        Validators.pattern(new RegExp(`^${this.translateService.instant(this.keywordLabel)}$`, 'i'))
      ]),
    });
  }

  /**
   * Method called to emit delete event.
   */
  onDelete(mode: string) {
    this.dialogRef.close(mode);
  }
}
