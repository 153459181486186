import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { isDGaasScope, isRpaasScope, IrisContextService } from '../../../../iris-context';
import { BaseEntitlementBannerConfig } from '../base-entitlement-banner.config';
import { EntitlementKeys } from '../../../../app-configs';

@Injectable({
  providedIn: 'root',
})
export class RansomwareInfoEntitlementBanner extends BaseEntitlementBannerConfig {

  /**
   * The unique id for this entitlement banner
   */
  id = 'ransomware-info-entitlement-banner';

  /**
   * The key that identifieds the entitlement object
   * within the subscription data returned by the api
   */
  key = EntitlementKeys.Ransomware;

  /**
   * the scope that this banner should be available in
   */
  isScope = (ctx) => isDGaasScope(ctx) || isRpaasScope(ctx);

  constructor(protected irisCtx: IrisContextService, protected translateService: TranslateService) {
    super(irisCtx, translateService);
  }
}
