import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnInit, HostListener } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { AutoDestroyable } from '@cohesity/utils';
import { ObservableInput } from 'ngx-observable-input';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Document } from '../folder-browser.models';

/**
 * This is an address bar component used for browsing file volumes. It displays each folder in a row with a drop down
 * menu to allow selecting siblings of that path element.
 */
@Component({
  selector: 'coh-address-bar',
  templateUrl: './address-bar.component.html',
  styleUrls: ['./address-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressBarComponent extends AutoDestroyable implements OnInit {
  /**
   * Whether to allow user input or not.
   */
  @Input() allowUserInput = false;

  /**
   * Whether to only accept user input - useful when there are no browsable volumes available.
   */
  @Input() allowOnlyUserInput = false;

  /**
   * Icon to use for the reset path button.
   */
  @Input() resetIcon = 'home!outline';

  /**
   * The current path as a string. This should always start with the volume being browsed.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput(null) @Input('currentPath') currentPath$: Observable<string>;

  /**
   * A callback to a function that should return an observable of sibling menu items for a given directory
   */
  @Input() getMenuItemsFn: (path) => Observable<Document[]>;

  /**
   * Event is emitted when a user uses a menu item to browse to a specific directory.
   */
  @Output() browseToPath = new EventEmitter<string>();

  /**
   * Event is emitted when a user uses a menu item to browse to a specific directory.
   */
  @Output() browseToUserInputPath = new EventEmitter<string>();

  /**
   * An observable of the current path, split into an array
   */
  get pathTokens$(): Observable<string[]> {
    return this.currentPath$.pipe(map(path => (path ? path.split('/') : [])));
  }

  /**
   * This tracks whether the user has clicked on the search button.
   */
  showPathInput = false;

  /**
   * This determines whether the path input should be shown, either because the user has
   * selected it, or because it has been forced open.
   */
  get pathInputActive(): boolean {
    return this.allowOnlyUserInput || (this.allowUserInput && this.showPathInput);
  }

  /**
   * Form control for the path input field.
   */
  pathInputControl = new UntypedFormControl(null);

  /**
   * Listen for the escape key to close the search bar.
   */
  @HostListener('window:keyup.escape')
  onKeyDown() {
    this.showPathInput = false;
  }

  constructor() {
    super();
  }

  ngOnInit() {
    this.currentPath$.pipe(this.untilDestroy()).subscribe(path => this.pathInputControl.setValue(path));
  }

  /**
   * Callback method to get menu items for a given directory.
   */
  getMenuItems(pathIndex: number): Observable<Document[]> {
    return this.pathTokens$.pipe(
      map(pathTokens => pathTokens.filter((name, index) => index < pathIndex).join('/')),
      switchMap(menuPath => this.getMenuItemsFn(menuPath))
    );
  }

  /**
   * Browse to a path based on user input
   *
   * @param path The user's path.
   */
  browseToPathInput(path: string) {
    const cleaned = path.trim().split('\\').join('/');
    this.browseToUserInputPath.emit(cleaned);
  }

  /**
   * Show the path input
   *
   * @param input The input field to focus
   */
  showInput(input: MatInput) {
    this.showPathInput = true;
    setTimeout(() => {
      input.focus();
    });
  }
}
