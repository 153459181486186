import { Injectable } from '@angular/core';
import { ClusterConfig } from '@cohesity/iris-core';
import { forkJoin, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AjsUpgradeService } from './ajs-upgrade.service';
import { WorkflowAccessContextService } from './workflow-access-context.service';

/**
 * UserScopeService contains method and variables related to logged-in user scope.
 */
@Injectable({
  providedIn: 'root'
})
export class UserScopeService {
  /**
   * Legacy AJS services.
   */
  private ajsClusterService: any;
  private ajsUserService: any;

  constructor(
    ajsUpgrade: AjsUpgradeService,
    private workflowAccessContextService: WorkflowAccessContextService,

  ) {
    this.ajsUserService = ajsUpgrade.get('UserService');
    this.ajsClusterService = ajsUpgrade.get('ClusterService');
  }

  /**
   * Update the current user context by fetching right cluster info and user privilege.
   *
   * 1. used during cluster scope switching aka SPOG.
   * 2. used during tenant impersonating.
   * 3. used during switch account/tenants (currently available only for SSO group users).
   *
   * @param   clusterDefaults   The cluster info to preserve after fetching new cluster info
   * @return  Promise resolved with new clusterInfo and user privs on success else failed with error.
   */
  changeUserContext(clusterDefaults?: ClusterConfig): Observable<any> {
    return forkJoin({
      clusterInfo: this.ajsClusterService.getClusterInfo(undefined, clusterDefaults),
      userInfo: this.ajsUserService.updateSessionUser(clusterDefaults),
    }).pipe(tap(() => {
      // clear the cached workflow access context.
      this.workflowAccessContextService.clearWorkflowAccessContext();
    }));
  }
}
