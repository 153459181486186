import { ReportColumnConfigs } from '../common-report-items/custom-reports.service';
import { ReportSection } from '../iris-reporting.model';
import {
  getStorageConsumptionColumns,
  storageConsumptionCommonColumns,
} from './common-report-config-items';

/**
 * This is the layout config for the storage consumption by system report.
 */
export const storageConsumptionSystemReport: ReportSection[] = [
  {
    title: 'reporting.summary',
    items: [
      {
        id: 'statlistItem',
        componentIds: ['801'],
        componentNames: ['StorageConsumptionByClusterSummary'],
        params: {
          items: [
            {
              title: 'reporting.systems',
              translate: true,
              measurement: {
                valueKey: 'countSystemId',
                dataType: 'number',
              },
            },
            {
              title: 'reporting.totalCapacity',
              translate: true,
              measurement: {
                valueKey: 'sumTotalCapacityBytes',
                dataType: 'bytes',
              },
            },
            {
              title: 'reporting.storageConsumedWithResiliency',
              translate: true,
              measurement: {
                valueKey: 'sumScResiliencyBytes',
                dataType: 'bytes',
              },
            },
          ],
        },
      },
    ],
  },
  {
    items: [
      {
        id: 'chartItem',
        componentIds: ['802'],
        componentNames: ['StorageConsumptionByClusterTrend'],
        title: 'reporting.usageTrend',
        params: {
          chartType: 'line',
          measurements: [
            {
              valueKey: 'usagePercent',
              dataType: 'percentage'
            },
          ],
          dimensions: [
            {
              dataType: 'date',
              dimensionKey: 'timestampUsecs',
            },
            {
              dimensionKey: 'systemName',
            },
          ],
        },
      },
    ],
  },
  {
    title: 'reporting.data',
    isFullData: true,
    items: [
      {
        id: 'tableItem',
        componentIds: ['800'],
        componentNames: ['AllStorageConsumptionByCluster'],
        params: {
          columns: [
            {
              title: 'systemName',
              translate: true,
              key: 'systemName',
              icon: {
                dataType: 'clusterIcon',
              },
              primary: {
                key: 'systemName',
                dataType: 'text',
              },
              // subtitle: {
              //   key: 'systemType',
              //   dataType: 'systemType',
              // },
            },
            {
              title: 'totalCapacity',
              translate: 'true',
              key: 'totalCapacityBytes',
              primary: {
                key: 'totalCapacityBytes',
                dataType: 'bytes',
              },
            },
            {
              title: 'usagePercentage',
              translate: 'true',
              key: 'usagePercent',
              primary: {
                key: 'usagePercent',
                dataType: 'number',
                renderParams: {
                  suffix: '%',
                  format: '1.0-2',
                },
              },
            },
            ...getStorageConsumptionColumns('AllStorageConsumptionByCluster'),
          ],
        },
      },
    ],
  },
];

/**
 * This is metadata needed to determine available chart customizations.
 */
export const storageConsumptionSystemColumns: ReportColumnConfigs = {
  systemName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  systemId: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  totalCapacityBytes: {
    aggs: ['sum', 'min', 'max', 'avg'],
    dataType: 'bytes',
  },
  ...storageConsumptionCommonColumns,
  usagePercent: {
    aggs: ['sum', 'min', 'max', 'avg'],
    dataType: 'number',
  },
};
