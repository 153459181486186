<div *ngIf="udaParams">
  <div class="dl-row" *ngIf="udaParams.sourceType">
    <dt>{{ 'sourceType' | translate }}</dt>
    <dd>{{ sourceTypeLabel }}</dd>
  </div>
  <div class="dl-row" *ngIf="!!udaParams.hostType">
    <dt>{{ 'osType' | translate }}</dt>
    <dd>{{ ('hostType.' + udaParams.hostType) | translate }}</dd>
  </div>
  <div class="dl-row" *ngIf="udaParams.hosts">
    <dt>{{ 'hosts' | translate }}</dt>
    <dd>{{ udaParams.hosts?.join(", ") }}</dd>
  </div>
  <div class="dl-row" *ngIf="udaParams.mountView && udaParams.mountDir">
    <dt>{{ 'mountPath' | translate }}</dt>
    <dd>{{ udaParams.mountDir }}</dd>
  </div>
  <div class="dl-row" *ngIf="udaParams.scriptDir">
    <dt>{{ 'datasourceAgentInstallationPath' | translate }}</dt>
    <dd>{{ udaParams.scriptDir }}</dd>
  </div>
  <div class="dl-row" *ngIf="udaParams.sourceArgs && !(disableSourceArgs$ | async)">
    <dt>{{ 'sourceRegistrationOptions' | translate }}</dt>
    <dd>{{ udaParams.sourceArgs }}</dd>
  </div>
  <ng-container *ngFor="let arg of (customArguments$ | async)">
    <div class="dl-row uda-custom-arg">
      <dt>{{ arg.label }}</dt>
      <dd>{{ arg.value }}</dd>
    </div>
  </ng-container>
</div>
