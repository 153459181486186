import {
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Inject,
  InjectionToken,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SkipSelf,
} from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { HelixTrackingEvent, EventTrackingService } from '../../event-tracking.service';

/**
 * In order to set up the injection properly and avoid a circular reference, we need to use a class
 * for the base provided object.
 * See: https://angular.io/guide/dependency-injection-navtree
 */
export abstract class DataIdProvider {
  /**
   * The data id property.
   */
  readonly dataId: string;

  /**
   * The event tracking id property. When this is present, this value
   * is used instead of dataId for analytics tracking.
   */
  readonly eventTrackingId: string;

  /**
   * An observable that fires any time the data id has changed.
   */
  readonly idChanged$: Observable<void>;
}

/**
 * This injection token can be set to override the dafault data id name.
 */
export const DATA_ID_ATTRIBUTE = new InjectionToken<string>('cogDataId');

/**
 * If DATA_ID_DIRECTIVE is not set, the directive will use 'data-testid' for it's value. This is one of the values
 * that cypress automatically looks for when using the selector playground.
 */
const defaultDataIdAttribute = 'data-testid';

/**
 * Add the cogDataid to any element to add an ID that can be used for automation testing. The id will be
 * derived from the id specified on the element and any ancestor ids.
 *
 * @example
 * The following template:
 * <div cogDataId="parent">
 *   <div cogDataId="child" [inheritDataId]="true"></div>
 *   <div cogDataId="child-2"></div>
 * </div>
 *
 * Will Yield:
 * <div data-testid="parent">
 *   <div data-testid="parent-child"></div>
 *   <div data-testid="child-2"></div>
 * </div>
 */
@Directive({
  selector: '[cogDataId]',
  providers: [
    {
      provide: DataIdProvider,
      useExisting: forwardRef(() => DataIdDirective),
    },
  ],
  standalone: true
})
export class DataIdDirective implements OnChanges, DataIdProvider, OnInit, OnDestroy {
  /**
   * Sets the id for the current element.
   */
  @Input('cogDataId') elementId: string;

  /**
   * This property can be used to enable inheriting the parent's data id.
   */
  @Input() inheritDataId = false;

  /**
   * Optional. If this is provided, this is favored of cogDataId in event tracking service.
   */
  @Input() eventTrackingId: string;

  /**
   * Optional. Use this logging event for id and properties if provided.
   */
  @Input() trackingEvent: HelixTrackingEvent;

  /**
   * Gets the data id for the element, including the parent's if it exists and inheriting
   * the data id is enabled.
   */
  get dataId(): string {
    const base = this.parent && this.inheritDataId ? this.parent.dataId : undefined;
    return base ? [base, this.elementId].join('-') : this.elementId;
  }

  /**
   * Gets the event tracking id for the element, including the parent's if it exists and inheriting
   * the event tracking id is enabled.
   */
  get eventTrackingIdValue(): string {
    const base = this.parent?.eventTrackingId && this.inheritDataId ? this.parent.eventTrackingId : undefined;
    return base ? [base, this.eventTrackingId].join('-') : this.eventTrackingId;
  }

  /**
   * Subject that fires any time the id is changed
   */
  readonly idChanged$ = new Subject<void>();

  /**
   * Subscription for changes on a parent id.
   */
  private parentChangeSub: Subscription;

  /**
   * Listen to click event on this element and dispatch a logging event.
   */
  @HostListener('click') onClick() {
    this.eventTrackingService.send({
      id: this.elementId,
      element: this.elementRef.nativeElement,
      dataId: this.dataId,
      eventTrackingId: this.eventTrackingIdValue,
      ...this.trackingEvent,
    });
  }

  constructor(
    @Optional() @SkipSelf() private parent: DataIdProvider,
    @Optional() @Inject(DATA_ID_ATTRIBUTE) private dataIdAttribute: string,
    private elementRef: ElementRef,
    private eventTrackingService: EventTrackingService,
  ) {
    if (!dataIdAttribute) {
      this.dataIdAttribute = defaultDataIdAttribute;
    }
  }

  /**
   * If the id inherits a parent id, it should listen for changes on that id and update itself as needed
   */
  ngOnInit() {
    if (this.parent && this.inheritDataId) {
      this.parentChangeSub = this.parent.idChanged$.subscribe(() => {
        this.upateId();
      });
    }
  }

  /**
   * Cleanup subscriptions if they were set.
   */
  ngOnDestroy() {
    if (this.parentChangeSub) {
      this.parentChangeSub.unsubscribe();
      this.parentChangeSub = null;
    }
  }

  /**
   * Set the attribute when the component initializes.
   */
  ngOnChanges() {
    this.upateId();
  }

  /**
   * Upadte the id and fire a change event.
   */
  private upateId() {
    this.elementRef.nativeElement.setAttribute(
      this.dataIdAttribute, (this.dataId || '').toLocaleLowerCase()
    );
    this.idChanged$.next();
  }
}
