import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * This service is used for the dynamic report.
 */
@Injectable()
export class DynamicReportService {

  /**
   * Observable of whether it is under print media.
   */
  isPrintMedia$: Observable<boolean>;

  /**
   * This is a bit of a hack to determine if we are running the context of JSReport's
   * PDF Export. For some reason, the media queries either do not get set or match for
   * print during pdf export.
   */
  get isJsReportPDF(): boolean {
    return window.location.origin === 'file://';
  }

  constructor(
    breakpointObserver: BreakpointObserver
  ) {
    this.isPrintMedia$ = breakpointObserver.observe('print').pipe(
      map(queryList => queryList.matches || this.isJsReportPDF)
    );
  }

  /**
   * Converts title string to id string.
   *
   * @param    title  Title string.
   * @returns  ID string.
   */
  titleToId(title: string): string {
    return title?.replace(/\s/g, '-');
  }
}
