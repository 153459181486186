import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { GenerateCohesityCertificateModel } from '../generate-cohesity-certificate-interface/generate-cohesity-certificate-interface.model';

@Component({
  selector: 'coh-generate-cohesity-certificate-dialog',
  templateUrl: './generate-cohesity-certificate-dialog.component.html',
})
export class GenerateCohesityCertificateDialogComponent {

  /**
   * List of possible type to select
   */
  interfaceData: GenerateCohesityCertificateModel | null = null;

  /**
   * Constructor
   *
   * @param dialogRef
   */
  constructor(
    private dialogRef: MatDialogRef<GenerateCohesityCertificateDialogComponent>,
  ) {}

  /**
   * Set the data of generate cohesity certificate interface
   *
   * @param interfaceDataParam
   */
  setGenerateCohesityCertificateInterfaceData(interfaceDataParam: GenerateCohesityCertificateModel | null) {
    this.interfaceData = interfaceDataParam;
  }

  /**
   * Close dialog and send the form data
   */
  onGenerate() {
    this.dialogRef.close(this.interfaceData);
  }
}
