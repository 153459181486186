import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ProtectionGroup, ProtectionGroupServiceApi } from '@cohesity/api/v2';
import { SnackBarService } from '@cohesity/helix';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

/**
 * @description
 * Cassandra Set Primary (Run Now) modal window.
 */
@Component({
  selector: 'coh-cassandra-set-primary-action-modal',
  templateUrl: './cassandra-set-primary-modal.component.html',
  styleUrls: ['./cassandra-set-primary-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CassandraSetPrimaryModalComponent extends AutoDestroyable {
  /**
   * Protection Group instance used to populate form data.
   */
  readonly protectionGroup: ProtectionGroup;

  /**
   * The form group for opt out.
   */
  confirmFormGroup = new UntypedFormGroup({
    confirmInput: new UntypedFormControl('', [Validators.required, Validators.pattern('^([yY][eE][sS])$')])
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: { protectionGroup: ProtectionGroup },
    private ajaxService: AjaxHandlerService,
    private dialogRef: MatDialogRef<CassandraSetPrimaryModalComponent>,
    private protectionGroupService: ProtectionGroupServiceApi,
    private snackbar: SnackBarService,
    private translate: TranslateService,
  ) {
    super();
    this.protectionGroup = data.protectionGroup;
  }

  /**
   * Handles form submit action.
   */
  onSubmit() {
    this.protectionGroupService.CreateProtectionGroupRun({
      id: this.protectionGroup.id,
      body: {
        runType: 'kLog',
        cassandraParams: {
          setPrimaryForLog: true
        },
        targetsConfig: {
          usePolicyDefaults: true
        }
      },
    }).pipe(
      this.untilDestroy(),
      catchError(err => {
        this.ajaxService.handler(err);
        return throwError(err);
      })
    )
      .subscribe((res) => {
        if (res) {
          this.snackbar.open(this.translate.instant('cassandra.setPrimary.success'));
        }
        this.dialogRef.close(true);
      });
  }
}
