// Module & Component Controller: cPageHeader
import { environment } from 'src/environments/environment';

;(function(angular, undefined) {

  angular.module('C.pageHeader', [])
    .controller('PageHeaderCtrl', cPageHeaderFn)
    .component('cPageHeader', {
      transclude: {
        pageTitle: '?pageTitle',
        pageTitleComponent: '?pageTitleComponent',
        pageActions: '?pageActions',
      },
      controller: 'PageHeaderCtrl',
      templateUrl: 'app/global/c-page-header/c-page-header.html',
    });


  /**
   * @ngdoc component
   * @name C.pageHeader:cPageHeader
   * @function
   *
   * @description
   * Layout component that provides a page header container with appropriate
   * stylings built-in. Styles disabled via disabled attribute sniffing. Use
   * ngDisabled to disable the styling programatically. This is primarily for
   * modal/cSlideModal implementation.
   *
   * @example
      <!-- Leveraging multi transcludes -->
      <c-page-header ng-disabled="$ctrl.inModal">
        <page-title>Page Header</page-title>
        <page-actions>
          <button class="c-btn-primary">Page Action</button>
        <page-actions>
      </c-page-header>

      <!-- Using a custom page title component -->
      <c-page-header>
        <page-title-component>
          <custom-component>...</custom-component>
        </page-title-component>
      </c-page-header>

      <!-- Using fallback transclusion for special cases -->
      <c-page-header>
        <h1>Some Crazy Implementation Here</h1>
        <c-content-editable>
          Crazy Implementation Description<
        /c-content-editable>
      </c-page-header>
   */
  function cPageHeaderFn($attrs, $transclude, $timeout) {
    var $ctrl = this;
    var body;

    angular.extend($ctrl, {
      $onInit: setPageHeaderStyles,
      $doChange: setPageHeaderStyles,
      $onDestroy: $onDestroy,
      isSlotFilled: $transclude.isSlotFilled,
    });

    /**
     * Component initialization function
     *
     * @method   setPageHeaderStyles
     */
    function setPageHeaderStyles() {

      // Toggle body class in a $timeout to give cSlideModal implementations a
      // digest cycle to establish the ngDisabled value.
      $timeout(function toggleBodyClass() {
        /**
         * Don't add or remove the body class if component is 'disabled'. Styling
         * won't be applied anyway, and there's a good chance there is a
         * cPageHeader on the underlying page that is relying on and managing the
         * class.
         */
        if ($attrs.disabled) { return; }

        body = angular.element('body');
        body.addClass('has-c-page-header');

        /**
         * Add the helios in-frame class only when the app is set to run in helios-iframe mode
         * This helps to customizing styles to accomodate the app layout running inside an
         * iframe.
         *
         * Mostly the older Angular JS pages will need some custom style overriden.
         */
        if (environment.heliosInFrame) {
          body.addClass('in-helios-frame');
        }

        // an extra digest cycle on addition of css class is unnecessary
      }, 0, false);
    }

    /**
     * clean up function for end of component lifecycle
     *
     * @method   $onDestroy
     */
    function $onDestroy() {
      if ($attrs.disabled || !body) { return; }

      body.removeClass('has-c-page-header');
      body.removeClass('in-helios-frame');
    }

  }

})(angular);
