import { Injectable } from '@angular/core';
import { IrisContextService, isPiaasScope } from '@cohesity/iris-core';
import { Transition } from '@uirouter/core';
import { GuardPriority, GuardResult, StateGuard } from 'src/app/core/state/state-guard';

/**
 * The set of Platform Insights states.
 */
const appStates = new Set([
  'reporting',
]);

/**
 * State guard to ensure the iris service context is set to Platform Insights for Platform Insights routes.
 */
@Injectable({ providedIn: 'root' })
export class PlatformInsightsGuard implements StateGuard {
  /**
   * Run this at app priority
   */
  guardPriority = GuardPriority.App;

  constructor(private irisContextService: IrisContextService) { }

  /**
   * Run the Platform Insights context guard.
   *
   * @param transition ui router transition
   * @returns allow Platform Insights route, otherwise redirect with serviceType to switch to
   * @returns Allow Platform Insights route when the serviceType is set to Platform Insights
   * else initiate a switch to Platform Insights.
   */
  onStart(transition: Transition): GuardResult {
    const toState = transition.to();
    const toParams = transition.params();
    const isPlatformInsightsState = appStates.has(toState.name);
    const hasPlatformInsightsServiceType = toParams.serviceType === 'piaas';
    const piaasScope = isPiaasScope(this.irisContextService.irisContext);

    if (isPlatformInsightsState && piaasScope && !hasPlatformInsightsServiceType) {
      return transition.targetState().withParams({ serviceType: 'piaas' });
    }
  }
}
