import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * @description
 *
 * Progress bar that displays duration label and percent progress bar.
 */
@Component({
  selector: 'cog-duration-progress',
  templateUrl: './duration-progress.component.html',
  styleUrls: ['./duration-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DurationProgressComponent  {
  /**
   * Duration in milliseconds.
   */
  @Input() durationMs: number;

  /**
   * Progress bar percentage.
   */
  @Input() progress = 0;
}
