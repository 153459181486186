<div class="selected-title" cogDataId="summary" [inheritDataId]="true">
  {{"dg.td.scan.selected" | translate}} ({{selectedObjects.length}})
</div>

<!-- Cart info messages -->
<ng-content></ng-content>

<cog-table [source]="selectedObjects" [persistSelection]="true"
  name="object-selection-cart-table">
  <table mat-table>
    <ng-container matColumnDef="detail">
      <td mat-cell *matCellDef="let row; let index = index" class="no-padding">
        <dg-scan-object-search-item-detail
          [obj]="row"
          [autoTruncate]="true"
          [enableRemove]="true"
          [showInstallConnectorLink]="false"
          (remove)="removeFromCart.emit($event)">
        </dg-scan-object-search-item-detail>
      </td>
    </ng-container>
    <tr mat-row *matRowDef="let row; columns: ['detail']" ></tr>
  </table>
  <mat-paginator [length]="selectedObjects?.length" [hidePageSize]="true" [pageSizeOptions]="[10]"  [pageSize]="10">
  </mat-paginator>
</cog-table>
