import { Injectable } from '@angular/core';
import { McmClusterInfo } from '@cohesity/api/private';
import {
  HeliosSearchIndexedObjectsRequest,
  HeliosSearchIndexedObjectsResponseBody,
  ObjectsSearchResponseBody,
  SearchIndexedObjectsRequest,
  SearchServiceApi,
} from '@cohesity/api/v2';
import { NavItem, SnackBarService } from '@cohesity/helix';
import { flagEnabled, IrisContextService, isHeliosTenantUser, isMcm } from '@cohesity/iris-core';
import { AjaxHandlerService, ExportToCsvService } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { escapeRegExp } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { NavService } from 'src/app/core/app-layout/nav.service';
import { OrganizationsService, StateManagementService } from 'src/app/core/services';

import { checkClusterVersion } from '../../object-details-shared/object-action-utils';
import { GlobalSearchFileItem } from '../models/global-search-file-item.model';
import { GlobalSearchFilters } from '../models/global-search-filters.model';
import { GlobalSearchItem } from '../models/global-search-item.model';
import { GlobalSearchObjectItem } from '../models/global-search-object-item.model';
import { GlobalSearchPageSearchResult } from '../models/global-search-page-search-result.model';
import { GlobalSearchResultType } from '../models/global-search-result-type.model';
import { GlobalSearchFiltersService } from './global-search-filters.service';
import { GlobalSearchInfoService } from './global-search-info.service';
import { GlobalSearchResultsTypeService } from './global-search-results-type.service';

/**
 * Interface for searchObjects function's options.
 */
interface SearchObjectsOptions {
  /**
   * Maximum number of objects to fetch.
   */
  count?: number;

  /**
   * Filters to pass for the results.
   */
  filters?: GlobalSearchFilters;
}

/**
 * Service to load results for input results (the dropdown underneath the global
 * search input text type in th app bar) and global search search results page.
 */
@Injectable({
  providedIn: 'root',
})
export class GlobalSearchResultsService {
  /*
   * Map of registered clusters and their details.
   */
  clusterDetailsMap: Record<number, McmClusterInfo> = {};

  /**
   * Map of registered locations and their names.
   */
  locationNameMap: Record<string, string> = {};

  /**
   * Map of tenantIds and their names.
   */
  tenantsNameMap: Record<string, string> = {};

  /**
   * Behavior subject to hold the current global search search string.
   */
  private _searchString$ = new BehaviorSubject<string>('');

  /**
   * Observable to emit the current global search search string.
   */
  searchString$ = this._searchString$.asObservable();

  /**
   * Function to get the current global search search string.
   */
  get searchString(): string {
    return this._searchString$.value;
  }

  /**
   * Function to set the current global search search string.
   */
  set searchString(value: string) {
    this._searchString$.next(value);
  }

  /**
   * Whether there's a pending API call for the input results.
   */
  private _loadingInputResults$ = new BehaviorSubject<boolean>(false);

  /**
   * Observable to emit whether there's a pending API call for the input results.
   */
  loadingInputResults$ = this._loadingInputResults$.asObservable();

  /**
   * Whether there's a pending API call for the results page.
   */
  private _loadingPageResults$ = new BehaviorSubject<boolean>(false);

  /**
   * Observable to emit whether there's a pending API call for the results page.
   */
  loadingPageResults$ = this._loadingPageResults$.asObservable();

  /**
   * Behavior subject to hold the search results for the input results.
   */
  private _inputSearchResults$ = new BehaviorSubject<GlobalSearchItem[]>(undefined);

  /**
   * Observable to emit search results for the input results.
   */
  inputSearchResults$ = this._inputSearchResults$.asObservable();

  /**
   * Behavior subject to hold the search results for the results page.
   */
  private _pageSearchResults$ = new BehaviorSubject<GlobalSearchPageSearchResult>(undefined);

  /**
   * Observable to emit search results for the results page.
   */
  pageSearchResults$ = this._pageSearchResults$.asObservable();

  /**
   * Behavior subject to hold the filtered nav items results for the input results.
   */
  private _filteredNavItems$ = new BehaviorSubject<NavItem[]>([]);

  /**
   * Observable to emit search filtered nav items for the input results.
   */
  readonly filteredNavItems$ = this._filteredNavItems$.asObservable();

  /**
   * Subject to trigger fetching results for input results.
   */
  private loadInputSearchResults$ = new Subject<null>();

  /**
   * Subject to trigger fetching results for results page.
   */
  private loadPageSearchResults$ = new Subject<null>();

  /**
   * Subject to trigger filtering nav items for input results.
   */
  private loadInputNavItemsResults$ = new Subject<null>();

  /**
   * Array of states which can be navigated to from global search.
   */
  private allNavItems$ = new BehaviorSubject<NavItem[]>([]);

  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private exportToCsvService: ExportToCsvService,
    private globalSearchFiltersService: GlobalSearchFiltersService,
    private globalSearchInfoService: GlobalSearchInfoService,
    private globalSearchResultsTypeService: GlobalSearchResultsTypeService,
    private irisContextService: IrisContextService,
    private navService: NavService,
    private organizationsService: OrganizationsService,
    private searchServiceApi: SearchServiceApi,
    private snackBarService: SnackBarService,
    private stateManagementService: StateManagementService,
    private translateService: TranslateService) {
    this.initialize();
  }

  /**
   * Function to be called to trigger fetching results for input results.
   */
  loadInputSearchResults() {
    this.loadInputSearchResults$.next(null);
    this.loadInputNavItemsResults$.next(null);
  }

  /**
   * Function to be called to trigger fetching results for results page.
   */
  loadPageSearchResults() {
    this.globalSearchInfoService.loading$.pipe(
      // If the global search info service is loading, wait for it to finish.
      filter(value => !value),
      take(1)
    ).subscribe(() => {
      this.loadPageSearchResults$.next(null);
    });
  }

  /**
   * Function to clear the filters object of the results page.
   */
  clearPageSearchResults() {
    this._pageSearchResults$.next(undefined);
  }

  /**
   * Function to return the cluster id with the latest software version,
   * give an array of cluster ids.
   *
   * @param clusterIds The array of cluster ids.
   * @return The cluster id with latest software version.
   */
  getLatestClusterId(clusterIds: number[]): number {
    if (!clusterIds.length) {
      return;
    }

    if (clusterIds.some(id => !this.clusterDetailsMap[id])) {
      // If one of the clusters is not present in the map, return the first
      // cluster id.
      return clusterIds[0];
    }

    const clusters = clusterIds.map(id => this.clusterDetailsMap[id]);

    return clusters.sort((clusterA, clusterB) =>
      checkClusterVersion(clusterA.softwareVersion, clusterB.softwareVersion) ? -1 : 1
    )[0].clusterId;
  }

  /**
   * Function to download the current search results as a CSV file.
   */
  downloadCsv() {
    const filters = this.globalSearchFiltersService.filters;

    this.searchObjects(this.searchString, {
      filters,
      count: 5000,
    }).subscribe(value => {
      this.exportToCsvService.downloadCsv(value?.objects || [], 'objects');
    });
  }

  /**
   * Function to initialize the results service.
   */
  private initialize() {
    this.setupClusterDetailsMap();
    this.setupSearchableNavItems();
    this.setupInputSearchResults();
    this.setupPageSearchResults();
    this.setupNavSearchResults();
    this.setupTenantDetailsMap();
  }

  /**
   * Function to setup the the cluster details map object.
   */
  private setupClusterDetailsMap() {
    combineLatest([
      this.globalSearchInfoService.clusters$,
      this.globalSearchInfoService.locationNameMap$,
    ]).pipe(
      filter(([clusters, locationNameMap]) => Boolean(clusters && locationNameMap)),
    ).subscribe(([clusters, locationNameMap]) => {
      this.locationNameMap = locationNameMap;

      const clusterDetailsMap = {};

      for (const cluster of clusters) {
        clusterDetailsMap[cluster.clusterId] = cluster;
        this.locationNameMap[cluster.clusterId] = cluster.clusterName;
      }

      this.clusterDetailsMap = clusterDetailsMap;
    });
  }

  /**
   * Function to setup the the tenantsName map object.
   */
  private setupTenantDetailsMap() {
    this.globalSearchInfoService.tenants$.pipe(
      filter(() => !isHeliosTenantUser(this.irisContextService.irisContext)),
    ).subscribe(tenants => {
      this.tenantsNameMap = {};
      (tenants || []).forEach(tenant => this.tenantsNameMap[tenant.id] = tenant.name);
    });
  }

  /**
   * Function to setup items for navigation items search.
   *
   * TODO: In future, this should be moved into its own service and should
   * have more items.
   */
  private setupSearchableNavItems() {
    // Combine all nav service nav items.
    combineLatest([
      this.navService.customizedNavList$,
      this.navService.dashboardsList$,
      this.navService.helpList$,
    ]).subscribe(([navList, dashboardsList, helpList]) => {
      // Store all the available nav items.
      const navItems = [];

      for (const navItem of navList) {
        // Flatten the nav items if there is a sub nav list.
        if (navItem.subNavList?.length) {
          for (const subNavItem of navItem.subNavList) {
            navItems.push({
              ...subNavItem,

              // Sub nav items do not have icons, use the parent icon instead.
              icon: subNavItem.icon || navItem.icon,

              // The value is displayed as "Data Protection > Sources", etc.
              displayName: this.translateService.instant('labelGreaterThanValue', {
                label: this.translateService.instant(navItem.displayName),
                value: this.translateService.instant(subNavItem.displayName),
              }),
            });
          }
        } else {
          navItems.push({
            ...navItem,
            displayName: navItem.displayName?.length ? this.translateService.instant(navItem.displayName) : '',
          });
        }
      }

      for (const dashboardItem of dashboardsList) {
        navItems.push({
          ...dashboardItem,

          // The value is displayed as "Dashboards > MS SQL", etc.
          displayName: this.translateService.instant('labelGreaterThanValue', {
            label: this.translateService.instant('dashboards'),
            value: this.translateService.instant(dashboardItem.displayName),
          }),
        });
      }

      for (const helpItem of helpList) {
        navItems.push({
          ...helpItem,

          // Help nav items do not have icons, use the generic help icon instead.
          icon: helpItem.icon || 'help_outline',
          displayName: this.translateService.instant(helpItem.displayName),
        });
      }

      const searchableNavItems = navItems.filter(navItem =>
        // If the nav item has a state, check whether the user has access to the
        // state. The nav items with action and hrefs are always accessible here.
        (navItem.state ? this.stateManagementService.canUserAccessState(
          navItem.state, navItem.stateParams, true
        ) : true) && !navItem.hidden
      );

      this.allNavItems$.next(searchableNavItems);
    });
  }

  /**
   * Function to setup updating results for input dropdown.
   */
  private setupInputSearchResults() {
    // Fetch results for input results.
    this.loadInputSearchResults$.pipe(
      // Reload on type change.
      switchMap(() => this.globalSearchResultsTypeService.selectedType$),

      tap(() => this._loadingInputResults$.next(true)),
      debounceTime(
        flagEnabled(this.irisContextService.irisContext, 'slowGlobalSearchDebounce') ? 600 : 300
      ),

      // Load the results based on selected type.
      switchMap(() => {
        if (!this.searchString) {
          // If there is no search string, set the results to "null". Without this,
          // when the field is cleared, and then retyped in, the old results are
          // flashed before the new results are shown.
          return of(null);
        }

        const selectedType = this.globalSearchResultsTypeService.selectedType;
        const filters = this.globalSearchFiltersService.filters;

        if (selectedType === GlobalSearchResultType.File) {
          return this.searchFiles(this.searchString, {filters, count: 5}).pipe(
            map(result => result?.files?.map(object =>
              new GlobalSearchFileItem(object, {
                locationNameMap: this.locationNameMap,
                clusterDetailsMap: this.clusterDetailsMap,
              }) as GlobalSearchItem
            ))
          );
        }

        if (selectedType === GlobalSearchResultType.Object) {
          return this.searchObjects(this.searchString, {filters, count: 5}).pipe(
            map(result => result?.objects?.map(object =>
              new GlobalSearchObjectItem(object, {
                locationNameMap: this.locationNameMap,
                clusterDetailsMap: this.clusterDetailsMap,
                tenantsNameMap: this.tenantsNameMap,
              }) as GlobalSearchItem
            ))
          );
        }
      }),

      // finalize will not work here
      tap(() => this._loadingInputResults$.next(false)),
    ).subscribe(
      value => this._inputSearchResults$.next(value),
      error => this.ajaxHandlerService.errorMessage(error)
    );
  }

  /**
   * Function to setup updating results for results page table.
   */
  private setupPageSearchResults() {
    // Fetch results for results page.
    this.loadPageSearchResults$.pipe(
      filter(() => Boolean(this.searchString)),

      tap(() => this._loadingPageResults$.next(true)),
      debounceTime(
        flagEnabled(this.irisContextService.irisContext, 'slowGlobalSearchDebounce') ? 600 : 300
      ),

      // Load the results based on selected type.
      switchMap(() => {
        const selectedType = this.globalSearchResultsTypeService.selectedType;
        const filters = this.globalSearchFiltersService.filters;

        if (selectedType === GlobalSearchResultType.File) {
          return this.searchFiles(this.searchString, {filters}).pipe(
            map(result => ({
              count: null,
              searchResults: result?.files?.map(object =>
                new GlobalSearchFileItem(object, {
                  locationNameMap: this.locationNameMap,
                  clusterDetailsMap: this.clusterDetailsMap,
                }) as GlobalSearchItem
              ),
            }))
          );
        }

        if (selectedType === GlobalSearchResultType.Object) {
          return this.searchObjects(this.searchString, {filters}).pipe(
            map(results => ({
              count: results.count,
              searchResults: results?.objects?.map(object =>
                new GlobalSearchObjectItem(object, {
                  locationNameMap: this.locationNameMap,
                  clusterDetailsMap: this.clusterDetailsMap,
                  tenantsNameMap: this.tenantsNameMap,
                }) as GlobalSearchItem
              ),
            })),
          );
        }
      }),

      // finalize will not work here
      tap(() => this._loadingPageResults$.next(false)),
    ).subscribe(value => {
      this._pageSearchResults$.next({
        count: value.count,
        searchResults: value.searchResults,
      });
    }, error => this.ajaxHandlerService.errorMessage(error));
  }

  /**
   * Function to setup updating results for results page table.
   */
  private setupNavSearchResults() {
    this.loadInputNavItemsResults$.pipe(
      map(() => this.searchString),

      // Filter nav results based on search string
      switchMap(searchString =>
        this.allNavItems$.pipe(
          map(navItems => navItems.filter(navItem =>
            navItem.displayName.match(
              // Support using "*" wildcard in search string, this is to make
              // search behavior consistent for navigation search and objects
              // search.
              new RegExp(searchString.split('*').map(escapeRegExp).join('.*'), 'i')
            )
          ))
        )
      ),
    ).subscribe(value => this._filteredNavItems$.next(value));
  }

  /**
   * Function to call the helios search files API with search string and options.
   *
   * @param searchString The search string to fetch results of.
   * @param options Optional. Additional to options to filter results using.
   */
  private searchFiles(
    searchString: string, options?: SearchObjectsOptions
  ): Observable<HeliosSearchIndexedObjectsResponseBody> {
    const {
      fileParams,
      clusterIdentifiers,
    } = this.globalSearchFiltersService.getFileParamsFromFilters(options.filters);

    const apiBody: HeliosSearchIndexedObjectsRequest = {
      count: options?.count || 100,
      fileParams: {
        ...fileParams,
        searchString,
      },
      objectType: 'Files',
    };

    if (isMcm(this.irisContextService.irisContext)) {
      return this.searchServiceApi.GlobalSearchIndexedObjects({
        ...apiBody,
        clusterIdentifiers,
      });
    }

    const clusterInfo = this.irisContextService.irisContext.clusterInfo;

    return this.searchServiceApi.SearchIndexedObjects({
      body: apiBody as SearchIndexedObjectsRequest,
    }).pipe(
      map(response => ({
        ...response,
        files: (response?.files || []).map(file => ({
          ...file,
          clusterIdentifier: `${clusterInfo.id}:${clusterInfo.incarnationId}`,
        }))
      }))
    );
  }

  /**
   * Function to call the search-objects API with search string and options.
   *
   * @param searchString The search string to fetch results of.
   * @param options Optional. Additional to options to filter results using.
   */
  private searchObjects(
    searchString: string, options?: SearchObjectsOptions
  ): Observable<ObjectsSearchResponseBody> {
    const params: SearchServiceApi.SearchObjectsParams = {
      searchString,
      count: options?.count || 100,
      includeTenants: true,
      ...(
        options?.filters &&
        this.globalSearchFiltersService.getObjectParamsFromFilters(options.filters)
      ),
    };

    return this.searchServiceApi.SearchObjects(params).pipe(
      map(result => ({
        ...result,

        // objectProtectionInfos can sometimes be sent as null for certain
        // objects in bad state.
        objects: result.objects.filter(object => object.objectProtectionInfos?.length),
      })),
      catchError(error => {
        this.snackBarService.open(error?.error?.message, 'error');

        return of(null);
      })
    );
  }
}
