<!-- View Snapshot Permissions component-->
<button
  *ngIf="!readOnly"
  mat-stroked-button
  color="primary"
  type="button"
  cogDataId="add-new-snapshot-permission-action"
  (click)="addSnapshotPermission()">
  <cog-icon shape="add"></cog-icon>
  {{'add' | translate}}
</button>

<cog-table name="view-snapshot-permissions-list-table"
  *ngIf="(permissionsList?.length > 0) && domainPrincipalsHash"
  [source]="permissionsList"
  class="no-margin-left">
  <table mat-table>
    <!-- Principal Name -->
    <ng-container matColumnDef="principal">
      <th mat-header-cell cogDataId="principal-col" *matHeaderCellDef>
        {{'principal' | translate}}
      </th>
      <td mat-cell *matCellDef="let item" cogDataId="sid-{{item.sid}}">
        {{getPrincipalName(item.sid)}}
      </td>
    </ng-container>

    <!-- Access Type -->
    <ng-container matColumnDef="type">
      <th mat-header-cell cogDataId="permission-type" *matHeaderCellDef>
        {{'type' | translate}}
      </th>
      <td mat-cell *matCellDef="let item" cogDataId="permission-{{item.type}}">
        {{smbPermissions.types[item.type] | translate}}
      </td>
    </ng-container>

    <!-- Actions -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell id="item-actions-col" *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let item; index as i" (click)="$event.stopPropagation()" class="column-actions-single-icon">
        <div class="flex-row actions-menu-container" *ngIf="!readOnly">
          <button mat-icon-button
            type="button"
            (click)="removeSnapshotPermission(item)"
            cogDataId="remove-snapshot-permission-{{i}}-button">
            <cog-icon shape="helix:clear"></cog-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</cog-table>

<div class="c-message-inline icon-message warn margin-top"
  *ngIf="!permissionsList?.length">
  {{'views.snapshots.warning.noPermissionsSpecified' | translate}}
</div>
