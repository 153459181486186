<div class="item-value">
  <ng-content></ng-content>
</div>

<label *ngIf="label || htmlLabel" class="flex-row-center">
  <!-- Label legend -->
  <div *ngIf="labelLegend" class="label-legend {{labelLegend}}"></div>

  <!-- Icon -->
  <!-- This can accept regular icons as well as material icons. please see example section in .ts file. -->
  <cog-icon
    aria-hidden="true"
    *ngIf="iconName"
    [ngClass]="iconClass"
    [shape]="iconName"
    [size]="iconSize">
  </cog-icon>

  <!-- If no iconName provided, then create simple span with iconClass-->
  <span *ngIf="!iconName && iconClass" class="{{iconClass}}"></span>

  <span *ngIf="label">
    {{label}}
  </span>
  <span *ngIf="!label && htmlLabel" [innerHTML]="htmlLabel" class="html-label"></span>
</label>
