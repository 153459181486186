import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AjsUpgradeService } from 'src/app/core/services';

import { FilterLookups, FilterOption } from './recover-search-filters.service';

/**
 * Recovery search field can be used in places of ajs-search/c-search. It provides its own input
 * controls to drive the search, but relies on c-search and search-service to manage filters and
 * the actual searching.
 *
 * @example
 * <coh-recover-search-field
 *   #searchField
 *   recoverButtonLabel="{{recoverLabel | translate: {n: selectedItems.length} }}"
 *   [filters]="filters"
 *   [filterLookups]="searchFilters.filterLookups"
 *   [selectedItems]="selectedItems"
 *   [searchPublicApi]="true"
 *   [itemChipTemplate]="chipTemplate"
 *   searchType="adObjects"
 *   placeholder="{{'cSearch.searchLabel.adObjects' | translate}}"
 *   [preProcessResultsFn]="preProcessResults">
 * </coh-recover-search-field>
 * ...
 * <cog-table *ngIf="searchField.searchResults.length" [source]="searchField.searchResults">
 *   ...
 * </cog-table>
 */
@Component({
  selector: 'coh-recover-search-field',
  templateUrl: './recover-search-field.component.html',
  styleUrls: ['./recover-search-field.component.scss'],
})
export class RecoverSearchFieldComponent implements OnInit, OnDestroy, AfterViewInit {
  /**
   * The type of object being searched for.
   */
  @Input() searchType: string;

  /**
   * Specify whether the search is to be
   * executed on Private or Public IRIS APIs.
   */
  @Input() searchPublicApi = false;

  /**
   * The placeholder value to show in the search input.
   */
  @Input() placeholder: string;

  /**
   * Filter lookups to use for c-search service. The keys in this map to query params
   * that can be sent to the server. The values will be filled with the results from
   * the apis. Those values are used to populate possible filter values in the
   * search component and to map back to ids to send to the search api.
   */
  @Input() filterLookups: FilterLookups;

  /**
   * Properties to pass to c-search that can be applied to the search.
   */
  @Input() filters: FilterOption[];

  /**
   * A callback function pass to the c-search, which will preprocess search results.
   */
  @Input() preProcessResultsFn: (any) => void;

  /**
   * A template to render a selected chip in the input. If this is not set, the
   * value will be rendered as a string value.
   */
  @Input() itemChipTemplate: TemplateRef<any>;

  /**
   * Pre-filled query if any.
   */
  @Input() preQuery: string;

  /**
   * This is set to true if there were no results from the current search.
   */
  isEmpty = false;

  /**
   * Label to use for the recovery button.
   */
  @Input() recoverButtonLabel: string;

  /**
   * Emits a recover event when the user selects the recover button.
   */
  @Output() recoverItems = new EventEmitter<any[]>();

  /**
   * The form control for the search input.
   */
  searchControl = new UntypedFormControl();

  /**
   * An observable of the search query with a debounce time set.
   */
  searchQuery$: Observable<string> = this.searchControl.valueChanges.pipe(debounceTime(900));

  /**
   * This is bound to c-search and is used to determine whether to show the loading spinner
   * in the input.
   */
  searchLoading = false;

  /**
   * Results from c-search component
   */
  @Input() searchResults: any[];

  /**
   * Change event for the search results.
   */
  @Output() searchResultsChange = new EventEmitter<any[]>();

  /**
   * A list of selected items to show in the mat chip list (if enabled). If this property
   * is not set, the search field will support a single recovery only.
   */
  @Input() selectedItems: any[];

  /**
   * Change event is emitted when the user removes a selected item.
   */
  @Output() removeSelectedItem = new EventEmitter<any>();

  /**
   * Determines whether the add filters section in c-search should be visible or not.
   */
  showAddFilters = false;

  /**
   * Endpoint retrieved from searchService to use for search queries
   */
  get endpoint(): string {
    return this.searchPublicApi
      ? this.searchService.getPublicSearchURL(this.searchType)
      : this.searchService.getSearchUrl(this.searchType);
  }

  /**
   * Whether this field is configured to select multiple items or not.
   */
  get multiItem(): boolean {
    return !!this.selectedItems;
  }

  /**
   * Creates the search id based on the search type.
   */
  get searchId(): string {
    return `recover-${this.searchType}-search`;
  }

  /**
   * legacy ajs search service
   */
  private searchService: any;

  /**
   * Legacy cSearchService
   */
  private cSearchService: any;

  constructor(ajsUpgrade: AjsUpgradeService) {
    this.searchService = ajsUpgrade.get('SearchService');
    this.cSearchService = ajsUpgrade.get('cSearchService');
  }

  /**
   * Uses cSearchService to add a filter.
   *
   * @param   filter   The filter to add.
   */
  addFilter(filter: FilterOption) {
    this.cSearchService.addFilter(this.searchId, filter);
  }

  /**
   * Uses cSearchService to remove a filter.
   *
   * @param   filter   The filter to remove.
   */
  removeFilter(filter: FilterOption) {
    this.cSearchService.removeFilter(this.searchId, filter);
  }


  /**
   * Update the search results when the ajs component emits a change.
   *
   * @param   results   The new search results.
   */
  setSearchResults(results: any[] ) {
    // Empty lists or a list with an 'isEmpty' object should trigger showing the no results label.
    this.isEmpty = results && (results.length === 0 || results.find(row => row.isEmpty));

    // Use an empty list if there are no results.
    this.searchResults = this.isEmpty ? [] : results;
    this.searchResultsChange.emit(this.searchResults);
  }

  /**
   * Initialize the search endpoint and reset the searchResults value.
   */
  ngOnInit() {
    this.searchResults = [];
  }

  /**
   * Pre-fill search query if applicable.
   */
  ngAfterViewInit() {
    if (this.preQuery) {
      this.searchControl.setValue(this.preQuery);
    }
  }

  /**
   * Clear the search configuration when the component is destroyed.
   */
  ngOnDestroy() {
    this.cSearchService.destroy(this.searchId);
  }
}
