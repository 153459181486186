import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

import { DataIdModule } from '../data-id';
import { DateRangePickerModule } from '../date-range-picker';
import { IconModule } from '../icon';
import { DateRangeInputComponent } from './date-range-input.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    DateRangeInputComponent
  ],
  imports: [
    CommonModule,
    DataIdModule,
    DateRangePickerModule,
    FormsModule,
    IconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    TranslateModule
  ],
  providers: [DatePipe],
  exports: [DateRangeInputComponent]
})
export class DateRangeInputModule { }
