import { DataRenderer, IconDataRendererComponent } from '@cohesity/helix';

/**
 * Renders a view icon.
 */
export const viewIconRenderer = {
  transform: (renderValues: DataRenderer<string, undefined, undefined>) => ({
    ...renderValues,
    value: 'helix:view',
  }),
  component: IconDataRendererComponent,
};
