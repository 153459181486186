/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { WhitelabelingSetting } from '../models/whitelabeling-setting';
import { Success } from '../models/success';
@Injectable({
  providedIn: 'root',
})
class CustomizeUIService extends __BaseService {
  static readonly GetWhitelabelingSettingsPath = '/mcm/customize-ui/settings';
  static readonly CreateWhitelabelingSettingsPath = '/mcm/customize-ui/settings';
  static readonly DeleteWhitelabelingSettingsPath = '/mcm/customize-ui/settings';
  static readonly UpdateWhitelabelingSettingsPath = '/mcm/customize-ui/settings';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get whitelabeling settings.
   *
   * Get the whitelabeling setting based on passed filters
   * @param settingKeys Specifies whitelabeling setting types to find
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetWhitelabelingSettingsResponse(settingKeys?: Array<'mastheadDetails' | 'customizedURLDetails' | 'customizedLoginPageDetails' | 'documentationLabelingDetails'>,
    regionId?: string): __Observable<__StrictHttpResponse<WhitelabelingSetting>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (settingKeys || []).forEach(val => {if (val != null) __params = __params.append('settingKeys', val.toString())});
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/customize-ui/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WhitelabelingSetting>;
      })
    );
  }
  /**
   * Get whitelabeling settings.
   *
   * Get the whitelabeling setting based on passed filters
   * @param settingKeys Specifies whitelabeling setting types to find
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetWhitelabelingSettings(settingKeys?: Array<'mastheadDetails' | 'customizedURLDetails' | 'customizedLoginPageDetails' | 'documentationLabelingDetails'>,
    regionId?: string): __Observable<WhitelabelingSetting> {
    return this.GetWhitelabelingSettingsResponse(settingKeys, regionId).pipe(
      __map(_r => _r.body as WhitelabelingSetting)
    );
  }

  /**
   * Create whitelabeling settings.
   *
   * Create the whitelabeling setting based on passed filters
   * @param body Specifies whitelabeling setting details to create.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  CreateWhitelabelingSettingsResponse(body: WhitelabelingSetting,
    regionId?: string): __Observable<__StrictHttpResponse<WhitelabelingSetting>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/customize-ui/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WhitelabelingSetting>;
      })
    );
  }
  /**
   * Create whitelabeling settings.
   *
   * Create the whitelabeling setting based on passed filters
   * @param body Specifies whitelabeling setting details to create.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  CreateWhitelabelingSettings(body: WhitelabelingSetting,
    regionId?: string): __Observable<WhitelabelingSetting> {
    return this.CreateWhitelabelingSettingsResponse(body, regionId).pipe(
      __map(_r => _r.body as WhitelabelingSetting)
    );
  }

  /**
   * Delete whitelabeling settings.
   *
   * Delete the whitelabeling setting based on passed filters if not filters passed it will delete complete setting for that accountid
   * @param settingKeys Specifies whitelabeling setting types to delete
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  DeleteWhitelabelingSettingsResponse(settingKeys?: Array<'mastheadDetails' | 'customizedURLDetails' | 'customizedLoginPageDetails' | 'documentationLabelingDetails'>,
    regionId?: string): __Observable<__StrictHttpResponse<Success>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (settingKeys || []).forEach(val => {if (val != null) __params = __params.append('settingKeys', val.toString())});
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/mcm/customize-ui/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Success>;
      })
    );
  }
  /**
   * Delete whitelabeling settings.
   *
   * Delete the whitelabeling setting based on passed filters if not filters passed it will delete complete setting for that accountid
   * @param settingKeys Specifies whitelabeling setting types to delete
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  DeleteWhitelabelingSettings(settingKeys?: Array<'mastheadDetails' | 'customizedURLDetails' | 'customizedLoginPageDetails' | 'documentationLabelingDetails'>,
    regionId?: string): __Observable<Success> {
    return this.DeleteWhitelabelingSettingsResponse(settingKeys, regionId).pipe(
      __map(_r => _r.body as Success)
    );
  }

  /**
   * Update whitelabeling settings.
   *
   * Update the whitelabeling setting based on passed filters
   * @param body Specifies whitelabeling setting details to create.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  UpdateWhitelabelingSettingsResponse(body: WhitelabelingSetting,
    regionId?: string): __Observable<__StrictHttpResponse<WhitelabelingSetting>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/mcm/customize-ui/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WhitelabelingSetting>;
      })
    );
  }
  /**
   * Update whitelabeling settings.
   *
   * Update the whitelabeling setting based on passed filters
   * @param body Specifies whitelabeling setting details to create.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  UpdateWhitelabelingSettings(body: WhitelabelingSetting,
    regionId?: string): __Observable<WhitelabelingSetting> {
    return this.UpdateWhitelabelingSettingsResponse(body, regionId).pipe(
      __map(_r => _r.body as WhitelabelingSetting)
    );
  }
}

module CustomizeUIService {
}

export { CustomizeUIService }
