import { Injectable } from '@angular/core';
import {
  SaaSConnectorGroup,
  ConnectorGroupConfig,
  GetConnectorGroupUpdateStatusResult,
  GetStatsRequest,
  GetStatsResult,
  ListRigelGroupResult,
  ListRigelResult,
  MetricLabel,
  Rigel,
  RigelGroup,
  RigelImage,
  RigelmgmtServiceApi,
  UpdateConnectionResult,
  DecommissionCloudRigelStatusResult,
  ProvisionCloudRigelStatusResult,
  UpgradeRigelGroupRequest,
} from '@cohesity/api/rms';
import { ConnectionConfig, SourceServiceApi } from '@cohesity/api/v2';
import { NavItem, SnackBarService } from '@cohesity/helix';
import { dmsTenantId, flagEnabled, getUserTenantId, IrisContextService, isIbmBaaSEnabled } from '@cohesity/iris-core';
import { DeleteConfirmationDialogComponent } from '@cohesity/shared-dialogs';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import moment from 'moment';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, Subject, timer } from 'rxjs';
import {
  catchError,
  delay,
  filter,
  map,
  share,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import { ViewPortInfoDialogComponent } from 'src/app/modules/dms/regions/view-port-info/view-port-info.component';
import { DialogService, PollingService } from 'src/app/core/services';

import {
  ConnectionSetupDialogComponent,
} from '../../sources-shared/components/connection-setup-dialog/connection-setup-dialog.component';
import {
  EditConnectorGroupNameDialogComponent
} from './connections-common/edit-connector-group-name-dialog/edit-connector-group-name-dialog.component';
import { BandwidthUsageComponent } from './modals/bandwidth-usage/bandwidth-usage.component';
import {
  DmsConnectionTokenDialogComponent,
} from './modals/dms-connection-token-dialog/dms-connection-token-dialog.component';
import {
  ForceDeleteConfirmationDialogComponent,
} from './modals/force-delete-confirmation-dialog/force-delete-confirmation-dialog.component';
import { RenameConnectionDialogComponent } from './modals/rename-connection-dialog/rename-connection-dialog.component';
import { DmsConnection, DmsConnectionMetric, DmsConnectionStatus } from './models/dms-connection';
import { DmsConnector } from './models/dms-connector';
import { DmsConnectorGroup } from './models/dms-connector-group';
import { Environment } from 'src/app/shared';
import { ConnectionData } from '../dms-registrations/aws/dms-aws-saas-connection/dms-aws-saas-connection.component';
import { RetryConnectionDialogComponent } from './modals/retry-connection-dialog/retry-connection-dialog.component';
import { CommonDmsClusterConnectionService } from './common-dms-cluster-connection.service';

/** Default max no. of connectors or connections. */
const defaultMaxRecordLimit = 1000;

/** Reload page wait time */
const reloadWaitMs = 200;

/** Poll connection status time interval */
const pollStatusMs = 30000;

/** Timeframe value options. */
const timeframeValues = ['day', 'week', 'month', 'quarter', 'year'];

/** Mapping from timeframe to no. of days. */
const timeframeDaysMap = {
  day: 1,
  week: 7,
  month: 30,
  quarter: 120,
  year: 365
};

/** Mapping from timeframe to interval values, used to get time series data. */
const timeframeIntervalsMap = {
  day: 7200,
  week: 43200,
  month: 172800,
  quarter: 604800,
  year: 2592000
};

/** Default timeframe used to get stats. */
export const defaultTimeframe = 'day';

/**
 * Mapping from ID to menu items.
 */
export interface IdNavItemsMap { [id: number]: NavItem[] }

/**
 * Provide Rigel connection, connector API service.
 */
@Injectable()
export class DmsConnectionService extends AutoDestroyable {
  /**
   * Timeframe value used to get stats.
   */
  private timeframe = defaultTimeframe;

  /**
   * Behavior Subject of mapping of connection ID to connection actions.
   */
  private connectionsActionsSubject = new BehaviorSubject<IdNavItemsMap>({});

  /**
   * Observable of mapping of connection ID to connection actions.
   */
  connectionsActions$ = this.connectionsActionsSubject.asObservable();

  /**
   * Behavior Subject of mapping of connector ID to connector actions.
   */
  private connectorsActionsSubject = new BehaviorSubject<IdNavItemsMap>({});

  /**
   * Observable of mapping of connector ID to connector actions.
   */
  connectorsActions$ = this.connectorsActionsSubject.asObservable();

  /**
   * Subject of connector group update result.
   */
  private connectionStatusSubject = new Subject<GetConnectorGroupUpdateStatusResult>();

  /**
   * Observable of connector group update result.
   */
  connectionStatus$ = this.connectionStatusSubject.asObservable();

  /**
   * Subject for stopping polling connection status.
   */
  stopPollMap: Record<number, Subject<void>> = {};

  /**
   * True if force delete is confirmed on a connection.
   */
  isForceDelete = false;

  constructor(
    private ajaxService: AjaxHandlerService,
    private commonDmsClusterConnectionService: CommonDmsClusterConnectionService,
    private dialogService: DialogService,
    private irisCtx: IrisContextService,
    private mcmSourceService: SourceServiceApi,
    private pollingService: PollingService,
    private rigelService: RigelmgmtServiceApi,
    private snackBarService: SnackBarService,
    private state: StateService,
    private translate: TranslateService
  ) {
    super();
   }

  /**
   * Calculates utilization from metric.
   *
   * @param     metric  Metric info for a connection or connector
   * @returns   Utilization in percentage.
   */
  getUtilization(metric: DmsConnectionMetric): number {
    return Math.round((metric.Cpu + metric.Memory) / 2) || 0;
  }

  /**
   * Checks if the connection/connector is healthy based on utilization
   *
   * @param    utilization  Utilization in percentage.
   * @returns  True if healthy.
   */
  isHealthy(utilization: number): boolean {
    return utilization < 80;
  }

  /**
   * Gets the DMaaS connections.
   *
   * @returns   A list of DMaaS connections.
   */
  getConnections(
    id?: number,
    connections?: ConnectionConfig[],
    fetchConnectorGroups = false,
  ): Observable<DmsConnection[]> {
    const params = {
      tenantId: getUserTenantId(this.irisCtx.irisContext),
      maxRecordLimit: defaultMaxRecordLimit,
      fetchConnectorGroups,
    } as RigelmgmtServiceApi.GetRigelGroupsParams;

    if (id) {
      params.groupId = id;
    }

    if (connections) { // For AWS/Azure Adapter.
      const connections$ = forkJoin(connections.map(connection => this.rigelService.GetRigelGroups({
          tenantId: dmsTenantId(this.irisCtx.irisContext),
          groupId: connection.connectionId,
          getConnectionStatus: true,
          fetchConnectorGroups: true
        })
        .pipe(
          map((response: ListRigelGroupResult) => (response.rigelGroups || [])
            .map((rigelGroup: RigelGroup) => new DmsConnection(rigelGroup))),
          catchError(err => {
            this.ajaxService.errorMessage(err);
            return of([]);
          }),
        )))
      .pipe(
        map((dmsConnectionsArray: DmsConnection[][]) => [].concat(...dmsConnectionsArray))
      );
      return connections$;
    }
    const connectionsList: Observable<DmsConnection[]> =
      this.commonDmsClusterConnectionService.getConnectors(id, params);;
    if (!isIbmBaaSEnabled(this.irisCtx.irisContext)) {
      connectionsList.pipe(
        tap(connectionList => connectionList.forEach(c => this.pollConnectionStatus(c))),
      );
    }
    return connectionsList;
  }

  /**
   * Gets DMaaS connection by ID.
   *
   * @param     id                    Connection ID.
   * @param     fetchConnectorGroups  Fetches connector groups info if true.
   * @returns   A DMaaS connection.
   */
  getConnection(id: number, fetchConnectorGroups = true): Observable<DmsConnection> {
    return this.getConnections(id, null, fetchConnectorGroups).pipe(
      map(connections => connections?.length ? new DmsConnection(connections[0]) : null),
      shareReplay(1),
    );
  }

  /**
   * Fetch source associated with the connection and update connection.
   *
   * @param connection  Connection object.
   * @param isConnectorGroup  True if connection is a connector group.
   */
  getSource(connection: DmsConnection, isConnectorGroup = false) {
    connection = this.commonDmsClusterConnectionService.fetchSources(connection);
    if (!isIbmBaaSEnabled(this.irisCtx.irisContext)) {
      if (!isConnectorGroup) {
        connection.actions = this.getConnectionActions(connection);
      }
    }
  }

  /**
   * Fetch connection by id.
   *
   * @param id  Connection ID
   * @param isConnectorGroup  True if id it is a connector group
   * @returns Observable of connection
   */
  getConnectionWithSource(id: number, isConnectorGroup = false): Observable<DmsConnection> {
    return this.getConnection(id).pipe(
      filter(connection => !!connection),

      // Returns connection sooner instead of waiting for source so that
      // page load can be faster.
      tap(connection => this.getSource(connection, isConnectorGroup))
    );
  }

  /**
   * Polls connection status until complete or fail, then reload connection.
   *
   * @param connection Connection
   */
  pollConnectionStatus(connection: DmsConnection, delayMs = pollStatusMs) {
    if (!connection.connectorGroupRequestId) {
      return;
    }

    const stopPoll: Subject<void> = new Subject();

    this.stopPollMap[connection.groupId] = stopPoll;
    timer(delayMs, delayMs).pipe(
      switchMap(() => this.rigelService.GetConnectorGroupUpdateStatus(connection.connectorGroupRequestId)),
      share(),
      takeUntil(stopPoll),
    ).subscribe(
      (result: GetConnectorGroupUpdateStatusResult) => {
        this.connectionStatusSubject.next(result);
        if (result.status !== 'InProgress') {
          stopPoll.next();
          delete this.stopPollMap[connection.groupId];
          if (result.status === 'Failed') {
            this.ajaxService.errorMessage({error: result});
          }
        }
      },
      err => {
        delete this.stopPollMap[connection.groupId];
        this.ajaxService.errorMessage(err);
      },
    );
  }

  /**
   * Stop polling connection status. It is called when user leaves page.
   */
  stopPollConnectionStatus() {
    Object.values(this.stopPollMap).forEach((stopPoll: Subject<void>) => stopPoll.next());
    this.stopPollMap = {};
  }
  /**
   * Update connector groups of a connection.
   *
   * @param    connection       Connection that contains connector groups to be updated.
   * @param    connectorGroups  Connector Groups to be updated.
   * @returns  Observable of request Id.
   */
  updateConnectorGroups(
    connection: DmsConnection,
    connectorGroups: SaaSConnectorGroup[]
  ): Observable<string> {
    const configs: ConnectorGroupConfig[] = connectorGroups
      .filter(group => group.connectors?.length)
      .map(group => {
        const config: ConnectorGroupConfig = {
          connectorGroupId: group.connectorGroupId,
          connectorGroupName: group.connectorGroupName,
          connectorIds: group.connectors.map(connector => connector.rigelGuid),
          isUngroup: !!group.isUngroup,
        };

        if (!group.isUngroup) {
          config.action = group.connectorGroupId ? 'Update' : 'Add';
        }
        return config;
      });
    configs.push(...connection.connectorGroups
      .filter(group => !connectorGroups.includes(group))
      .map(group => ({
        action: 'Delete',
        connectorGroupId: group.connectorGroupId,
        connectorGroupName: group.connectorGroupName,
        isUngroup: !!group.isUngroup,
      } as ConnectorGroupConfig))
    );

    return this.rigelService.UpdateConnections({
      ConnectionId: connection.groupId,
      connectorGroupConfigs: configs,
    }).pipe(
      catchError(err => {
        this.ajaxService.errorMessage(err);
        return of(null);
      }),
      map(result => {
        if (result?.errorMessage) {
          this.ajaxService.errorMessage({ error: result });
        }
        return result?.requestId;
      }),
    );
  }

  /**
   * Deletes the DMaaS connection. Show message if user cannot delete connection.
   *
   * @param connection  Connection.
   */
  deleteConnection(connection: DmsConnection) {
    let dialogObservable$: Observable<unknown>;

    // If the user is deleting the connection for the first time or retrying delete,
    // show delete confirmation dialog
    if (connection?.sourceObjects?.length || connection?.clusterSourceObjects?.length) {
      if (connection?.isForceDeleteEnabled) {
        const data = {
          title: 'sources.aws.removeSaasConnections',
          message: 'sources.aws.forceRemoveSaasConnectionsMessage',
        };
        // If the user is trying to force delete a connection,
        // show force delete confirmation dialog
        dialogObservable$ = this.dialogService.showDialog(ForceDeleteConfirmationDialogComponent, data);
      } else {
        const data = {
          title: 'deleteConnection',
          message: 'cannotDeleteConnectionDescription',
        };

        dialogObservable$ = this.dialogService.showDialog(DeleteConfirmationDialogComponent, data);
      }
    } else {
      const data = {
        confirmButtonLabel: 'delete',
        declineButtonLabel: 'cancel',
        title: 'deleteConnection',
        copy: 'areYouSure',
      };

      dialogObservable$ = this.dialogService.simpleDialog(null, data);
    }

    this.reloadAfterDelete(
      dialogObservable$.pipe(
        map(mode => {
          this.isForceDelete = mode === 'forceDelete';
          return Boolean(mode);
        }),
        filter(Boolean),
        switchMap(() => this.commonDmsClusterConnectionService.deleteConnection(connection, this.isForceDelete)),
        (map(() => {
          if (connection?.requestId) {
            const connectionParams = {
              groupId: connection.groupId,
              requestId: connection.requestId
            };

            // While decommission is in progress, poll for decommission status every 30s to get the
            // status of the connectors. If the decommission has failed with an error, then
            // stop polling and display the error message to the user.
              this.pollRigelDecommissionStatus(connectionParams)
                .pipe(this.untilDestroy())
                .subscribe(decommissionStatus => {
                  // Get decommission status params and map to connection object
                  connection = this.getCloudStatus(decommissionStatus, connection);
                });
            }
          })
        )
      ),
      'deletingConnection'
    );
  }

  /**
   * Retries the DMaaS connection.
   *
   * @param connection  Connection.
   */
  retryConnection(connection: DmsConnection) {
    this.dialogService.showDialog(RetryConnectionDialogComponent, {
      data: connection,
    })
    .pipe(
      this.untilDestroy(),
    ).subscribe((numConnectors: number) => {
      // If numConnectors have been updated, call UpdateConnection api.
      if (numConnectors) {
        const currentNumConnectors = connection.expectedNumberOfRigels;
        connection.numConnectors = numConnectors;
        this.rigelService.UpdateConnection({
          ConnectionId: connection.groupId,
          currentNumOfConnectors: currentNumConnectors,
          numberOfConnectors: connection.numConnectors,
        })
        .pipe(
          this.untilDestroy(),
        ).subscribe(
          () => {
            this.state.reload();
            this.snackBarService.open(this.translate.instant('retryConnectionSuccess'));
          },
          this.ajaxService.handler
        );
      }
    });
  }

  /**
   * Gets the DMaaS connections.
   *
   * @param     regionId  Region of the connection
   * @returns   A list of DMaaS connections.
   */
  getConnectionDownloadLink(regionId: string): Observable<string> {
    return this.rigelService.GetRigelImage(regionId).pipe(
      filter((response: RigelImage) => Boolean(response?.downloadLink?.length)),
      map((response: RigelImage) => response.downloadLink)
    );
  }

  /**
   * Download the connection installer image.
   *
   * @param    connection   Connection
   */
  download(connection: DmsConnection) {
    this.getConnectionDownloadLink(connection.regionId).subscribe(
      downloadLink => window.open(downloadLink, '_blank'),
      err => this.ajaxService.errorMessage(err)
    );
  }

  /**
   * Update connections actions based on connections.
   *
   * @param connections  Connections
   */
  updateConnectionsActions(connections: DmsConnection[]) {
    const mapping = this.connectionsActionsSubject.getValue();

    connections.forEach(connection => mapping[connection.groupId] = this.getConnectionActions(connection));
    this.connectionsActionsSubject.next(mapping);
  }

  /**
   * Gets the menu actions for a connection.
   *
   * @param    connection   Connection
   * @returns  Menu actions
   */
  getConnectionActions(connection: DmsConnection, environment?: Environment): NavItem[] {
    return [
      flagEnabled(this.irisCtx.irisContext, 'rigelConnectionBandwidthRules') && {
        displayName: 'bandwidthUsageOptions',
        icon: 'settings',
        action: () => this.openBandwidthLimiterOptions(connection),
      },

      flagEnabled(this.irisCtx.irisContext, 'dmsConnectorGrouping') && {
        displayName: 'manageConnectors',
        icon: 'linear_scale',
        action: () => {
          if (connection.sourceObjects?.some(s => s.environment === 'kVMware') ||
            environment === Environment.kVMware) {
            this.state.go('dms-connection.edit-groups', {id: connection.groupId});
          } else {
            this.dialogService.simpleDialog(null, {
              title: 'cannotManageConnectors',
              copy: 'cannotManageConnectorsDescription',
              hideDeclineButton: true,
            }).subscribe();
          }
        },
      },

      {
        displayName: 'connectionToken',
        icon: 'remove_red_eye!outline',
        action: () => this.dialogService.showDialog(
          DmsConnectionTokenDialogComponent,
          connection,
          { disableClose: false }
        ),
      },

      // TODO(ang): Add RBAC check when it is available
      {
        displayName: 'deleteConnection',
        icon: 'delete_outline',
        action: () => this.deleteConnection(connection),
      },
      // If rigel provision has failed, show retry option.
      {
        displayName: 'retryConnection',
        icon: 'helix-retry',
        hidden: !['ProvisionFailed'].includes(connection?.cloudStatus?.status),
        action: () => this.retryConnection(connection),
      },
      // Hide download installer option for AWS and Azure sources.
      {
        displayName: 'downloadInstaller',
        icon: 'get_app!outline',
        hidden: connection.sourceObjects?.some(source => ['kAWS', 'kAzure']
          .includes(source?.environment)) || ['kAWS', 'kAzure'].includes(connection?.environment),
        action: () => this.download(connection),
      },
      {
        displayName: 'renameConnection',
        icon: 'edit',
        action: () => this.openRenameConnectionDialog(connection),
      },
      {
        displayName: 'dms.port.requirement',
        icon: 'info!outline',
        action: () => this.openPortInfoDialog(connection),
      }
    ].filter(Boolean);
  }

  /**
   * Gets menu actions for a connector group.
   *
   * @param    connectorGroup  Connector group or connection object.
   * @param    groupNames  Other connector group names.
   * @returns  Menu actions
   */
  getConnectorGroupActions(connectorGroup: DmsConnectorGroup | DmsConnection, groupNames: string[]): NavItem[] {
    const actions = [
      flagEnabled(this.irisCtx.irisContext, 'rigelConnectionBandwidthRules') && {
        displayName: 'bandwidthUsageOptions',
        icon: 'settings',
        action: () => this.openBandwidthLimiterOptions(connectorGroup),
      },
    ];

    if (!(connectorGroup as DmsConnectorGroup).isUngroup) {
      const ConnectionId = (connectorGroup as DmsConnectorGroup).connectorGroupId ||
        (connectorGroup as DmsConnection).groupId;
      const name = (connectorGroup as DmsConnectorGroup).connectorGroupName ||
        (connectorGroup as DmsConnection).groupName;

      actions.push({
        displayName: 'renameConnectorGroup',
        icon: 'edit!outline',
        action: () => {
          let updatedName: string;

          this.dialogService.showDialog(
            EditConnectorGroupNameDialogComponent,
            {
              name,
              otherNames: groupNames,
            },
          ).pipe(
            filter(result => !!result),
            switchMap((updatedConnectorGroupName: string) => {
              updatedName = updatedConnectorGroupName;
              return this.rigelService.UpdateConnection({
                ConnectionId,
                ConnectionName: updatedName,
              });
            }),
          ).subscribe(
            (result: UpdateConnectionResult) => {
              if (result.errorMessage) {
                this.ajaxService.errorMessage({error: result});
              } else {
                this.snackBarService.open(this.translate.instant('connectorGroupRenamed'));
                if ((connectorGroup as DmsConnectorGroup).connectorGroupName) {
                  (connectorGroup as DmsConnectorGroup).connectorGroupName = updatedName;
                } else {
                  (connectorGroup as DmsConnection).groupName = updatedName;
                }
              }
            },
            err => this.ajaxService.errorMessage(err)
          );
        },
      });
    }
    return actions.filter(Boolean);
  }

  /**
   * Gets the DMaaS connectors by Connection ID.
   *
   * @param     id  Connection or Connector ID.
   * @param     getSupportChannelConfig  Get also support channel info if true.
   * @param     isConnector  id is a connector ID if true, otherwise connection id
   * @returns   A list of DMaaS connectors.
   */
  getConnectors(id: number, getSupportChannelConfig = false, isConnector = false): Observable<DmsConnector[]> {
    const params: RigelmgmtServiceApi.GetRigelsParams = {
      tenantId: getUserTenantId(this.irisCtx.irisContext),
      getSupportChannelConfig,
      maxRecordLimit: defaultMaxRecordLimit,
    };

    if (isConnector) {
      params.rigelGuid = id;
    } else {
      params.groupId = id;
    }

    return this.rigelService.GetRigels(params).pipe(
      catchError(() => of(null)),
      map((response: ListRigelResult) => response?.rigels?.length ?
        response.rigels.map((rigel: Rigel) => new DmsConnector(rigel)) :
        []
      ),
      // Setup connector actions
      tap((connectors: DmsConnector[]) => this.updateConnectorAction(connectors)),
    );
  }

  /**
   * Update connector action based on connectors and parent (connector group).
   *
   * @param connectors  Connector list.
   * @param connectorGroup  Parent connector group.
   * @param connection  Connection that contains the connector.
   */
  updateConnectorAction(
    connectors: DmsConnector[],
    connectorGroup?: DmsConnectorGroup,
    connection?: DmsConnection,
  ) {
    const mapping = this.connectorsActionsSubject.getValue();

    connectors.forEach(connector => mapping[connector.rigelGuid] =
      this.getConnectorActions(connector, connectorGroup, connection));
    this.connectorsActionsSubject.next(mapping);
  }

  /**
   * Deletes the DMaaS connector.
   *
   * @param connector  Connector.
   * @param connectorGroup  Connector group.
   * @param connection  Connection that contains the connector.
   */
  deleteConnector(
    connector: DmsConnector,
    connectorGroup?: DmsConnectorGroup,
    connection?: DmsConnection,
    mode?: string
  ) {
    let description: string;

    if (connectorGroup?.connectors?.length === 1 &&
      connectorGroup?.sourceInfos?.length &&
      flagEnabled(this.irisCtx.irisContext, 'dmsConnectorGrouping')) {
      description = 'cannotDeleteConnectorDescription';
    } else if (connection?.numConnectors === 1 && connection?.sources?.length) {
      description = 'cannotDeleteConnectorDescriptionConnection';
    }

    if (description) {
      this.dialogService.simpleDialog(null, {
        title: 'cannotDeleteConnector',
        copy: description,
        hideDeclineButton: true,
      }).subscribe();
      return;
    }
    // Check if it is a force delete action and show appropriate delete dialog.
    if (mode === 'retry') {
      const data = {
        title: 'sources.aws.removeSaasConnector',
        message: 'sources.aws.forceRemoveSaasConnectorMessage',
      };

      this.reloadAfterDelete(
        this.dialogService.showDialog(ForceDeleteConfirmationDialogComponent, data).pipe(
          map(actionType => {
            this.isForceDelete = actionType === 'forceDelete';
            return Boolean(actionType);
          }),
          filter(Boolean),
          switchMap(() => this.rigelService.DeleteRigels({
            rigelGuid: connector.rigelGuid,
            tenantId: getUserTenantId(this.irisCtx.irisContext),
            forceDelete: Boolean(this.isForceDelete),
          }))
        ),
        'deletingConnector'
      );
    } else {
      const data = {
        title: 'sources.aws.removeSaasConnector',
        message: this.translate.instant('sources.aws.removeSaasConnectorMessage'),
        confirmLabel: 'removeConnector',
      };

      this.reloadAfterDelete(
        this.dialogService.showDialog(DeleteConfirmationDialogComponent, data).pipe(
          filter(Boolean),
          switchMap(() => this.rigelService.DeleteRigels({
            rigelGuid: connector.rigelGuid,
            tenantId: getUserTenantId(this.irisCtx.irisContext),
          }))
        ),
        'deletingConnector'
      );
    }
  }

  /**
   * Opens bandwidth rules config modal.
   *
   * @param  group  Connection to configure rules for.
   */
  openBandwidthLimiterOptions(group: DmsConnection | DmsConnectorGroup) {
    this.dialogService.showDialog(
      BandwidthUsageComponent,
      group,
    ).pipe(
      take(1),
    ).subscribe((message: string) => {
      if (message) {
        this.snackBarService.open(this.translate.instant(message));
      }
    });
  }

  /**
   * Opens rename connection dialog.
   *
   * @param  connection  Connection to rename.
   */
  openRenameConnectionDialog(connection: DmsConnection) {
    const dialogRef = this.dialogService.showDialog(RenameConnectionDialogComponent, connection);

    dialogRef.pipe(take(1)).subscribe((message: string) => {
      if (message) {
        this.snackBarService.open(this.translate.instant(message));
      }
    });
  }

  /**
   * Opens port information dialog.
   *
   * @param connection  Connection where associated region/port info is to be displayed.
   */
  openPortInfoDialog(connection: DmsConnection) {
    this.dialogService.showDialog(ViewPortInfoDialogComponent, {
      id: connection.regionId,
      name: connection.groupName,
    });
  }

  /**
   * Opens bandwidth rules config modal.
   */
  openNewConnectionDialog() {
    return this.dialogService.showDialog(ConnectionSetupDialogComponent, { isNewConnection: true })
      .pipe(tap((message: string) => {
        if (message) {
          this.snackBarService.open(this.translate.instant(message));
        }
      }));
  }

  /**
   * Reloads page after delete action.
   *
   * @param dialog$  observable returned from dialog service.
   * @param message  message to display when delete success.
   */
  reloadAfterDelete(dialog$: Observable<any>, message: string) {
    dialog$.pipe(
      // Wait some time for the backend to be updated after delete before reload
      delay(reloadWaitMs)
    ).subscribe(
      () => {
        this.snackBarService.open(this.translate.instant(message));
        this.state.reload();
      },
      err => this.ajaxService.errorMessage(err)
    );
  }

  /**
   * Toggle DMaaS connector support channel.
   *
   * @param connector  Connector.
   */
  toggleSupportChannel(connector: DmsConnector, isCurrentlyDisabled: boolean) {
    // Enable support channel if it is currently disabled
    const isEnabled = isCurrentlyDisabled;
    const endTimeUsecs = isEnabled ? moment().add(1, 'd').valueOf() * 1000 :
      connector.supportChannelEndTimeUsecs;

    this.rigelService.UpdateRigelSupportChannel({
      rigelGuid: connector.rigelGuid,
      isEnabled,
      endTimeUsecs,
    }).subscribe(
      () => this.snackBarService.open(this.translate.instant(
        isEnabled ? 'supportChannelEnabled' : 'supportChannelDisabled'
      )),
      err => this.ajaxService.errorMessage(err)
    );
  }

  /**
   * Gets menu actions for a connector.
   *
   * @param    connector  Connector.
   * @param    connectorGroup  Connector group.
   * @param    connection  Connection that contains the connector.
   * @returns  Menu actions
   */
  getConnectorActions(
    connector: DmsConnector,
    connectorGroup?: DmsConnectorGroup,
    connection?: DmsConnection,
    environment?: Environment
  ): NavItem[] {
    return [
      {
        displayName: 'removeFromConnection',
        icon: 'delete_outline',
        hidden: environment === Environment.kAWS ? connector.deletionStatus === 'deleting': false,
        action: () => this.deleteConnector(connector, connectorGroup, connection || connectorGroup.parent)
      },
      {
        displayName: 'retryDeletion',
        hidden: environment === Environment.kAWS ? connector.deletionStatus !== 'deleting' &&
          connection?.cloudStatus?.status !== 'Failed' : true,
        icon: 'helix-retry',
        action: () => this.deleteConnector(connector, connectorGroup, connection || connectorGroup.parent, 'retry')
      },
    ];
  }

  /**
   * Sets the timeframe parameter value for getting stats data.
   *
   * @param    timeframe  Timeframe value.
   */
  setTimeframe(timeframe: string) {
    this.timeframe = timeframeValues.includes(timeframe) ? timeframe : defaultTimeframe;
  }

  /**
   * Gets Time series rollup interval.
   *
   * @returns  Rollup interval.
   */
  getInterval(): number {
    return timeframeIntervalsMap[this.timeframe];
  }

  /**
   * Gets the default params for getting rigel stats API.
   *
   * @param     id  Connection ID or Connector ID.
   * @param     metrics  Metrics to be retrieved.
   * @param     isConnector  True if the params are for connector.
   * @param     useTimeframe  True if use preset timeframe in the service.
   * @param     isUngroup  True if the connector group contains ungrouped connectors.
   * @param     connectorGroupId  Connector group ID.
   * @returns   Default params for getting rigel stats API.
   */
  getParams(
    id: number,
    metrics: string[],
    isConnector = false,
    useTimeframe = false,
    isUngroup = false,
    connectorGroupId?: number,
  ): GetStatsRequest {
    const endTime = moment();
    const startTime = useTimeframe ?
      moment(endTime).subtract(timeframeDaysMap[this.timeframe], 'd') :
      moment(endTime).subtract(10, 'm');

    const params = {
      metrics,
      metricLabels: [
        {
          name: isConnector ? 'ConnectorId' : 'ConnectionId',
          value: String(id)
        },
        {
          name: 'TenantId',
          value: getUserTenantId(this.irisCtx.irisContext)
        }
      ] as MetricLabel[],
      startTimeUsecs: startTime.valueOf() * 1000,
      endTimeUsecs: endTime.valueOf() * 1000
    } as GetStatsRequest;

    if (isUngroup) {
      params.metricLabels.push({ name: 'IsUngrouped', value: 'true' });
    }
    if (connectorGroupId) {
      params.metricLabels.push({ name: 'ConnectorGroupId', value: String(connectorGroupId) });
    }
    if (metrics.some(metric => ['TotalNumBytesWritten', 'TotalNumBytesRead', 'WriteIos', 'ReadIos'].includes(metric))) {
      params.rollupFunction = 'Rate';
      if (!isConnector) {
        // We need to accumulate/sum the rate of connectors for connection
        params.aggregationFunction = 'Sum';
      }
    } else {
      params.rollupFunction = useTimeframe ? 'Avg' : 'Latest';
      if (!isConnector) {
        params.aggregationFunction = 'Avg';
      }
    }
    return params;
  }

  /**
   * Polls the time series data for a connection or connector.
   *
   * @param     id                Connection ID or Connector ID.
   * @param     connectorGroupId  Connector group ID.
   * @param     isConnector       True if the params are for connector.
   * @param     pollInterval      Poll interval, default to poll every 5 minutes.
   * @param     rollupInterval    Rollup interval, default to 5 minutes.
   * @returns   Observable of time series data.
   */
  pollTimeSeries(
    id: number,
    connectorGroupId?: number,
    isConnector = false,
    isCpuMemory = false,
    pollInterval = 300000,
    rollupInterval = 300,
  ): Observable<number[][][]> {
    const metrics = isCpuMemory ?
      [['Cpu'], ['Memory']] : [['TotalNumBytesWritten'], ['TotalNumBytesRead']];

    return timer(0, pollInterval).pipe(
      switchMap(() =>
        combineLatest([
          this.getTimeSeries(id, connectorGroupId, isConnector, rollupInterval, metrics[0]),
          this.getTimeSeries(id, connectorGroupId, isConnector, rollupInterval, metrics[1]),
        ])
      )
    );
  }

  /**
   * Gets the time series data for a connection or connector.
   *
   * @param     id                  Connection ID or Connector ID.
   * @param     connectorGroupId    Connector group ID.
   * @param     isConnector         True if the params are for connector.
   * @param     rollupIntervalSecs  Rollup Interval.
   * @param     metrics             Metrics to be retrieved.
   * @returns   Observable of time series data.
   */
  getTimeSeries(
    id: number,
    connectorGroupId?: number,
    isConnector = false,
    rollupIntervalSecs?: number,
    metrics = ['TotalNumBytesWritten']
  ): Observable<number[][]> {
    const params = {
      ...this.getParams(id, metrics, isConnector, true, false, connectorGroupId),
      rollupIntervalSecs: rollupIntervalSecs || this.getInterval(),
    } as GetStatsRequest;

    return (
      isConnector ?
        this.rigelService.GetConnectorStats(params) :
        this.rigelService.GetConnectionStats(params)
    ).pipe(
      catchError(() => of(undefined)),
      map((response: GetStatsResult) =>
        (response?.metricValues ?
          response.metricValues.map(metricValue => [
            (metricValue.timestampUsecs || 0) / 1000,
            metricValue.value || 0 ]
          ) :
          []
        )
      )
    );
  }

  /**
   * Gets the stats for a connection or connector.
   *
   * @param     id  Connection ID or Connector ID.
   * @param     isConnector  True if the params are for connector.
   * @param     metrics List of metrics to be retrieved
   * @param     useTimeframe  True if use preset timeframe in the service.
   * @param     isUngroup  True if the connector group contains ungrouped connectors.
   * @param     connectorGroupId  Connector group ID.
   * @returns   Observable of metric.
   */
  getStats(
    id: number,
    isConnector = false,
    metrics = [ 'Cpu', 'Memory' ],
    useTimeframe = false,
    isUngroup = false,
    connectorGroupId?: number,
  ): Observable<DmsConnectionMetric> {
    const params = this.getParams(id, metrics, isConnector, useTimeframe, isUngroup, connectorGroupId);

    return (
      isConnector ?
        this.rigelService.GetConnectorStats(params) :
        this.rigelService.GetConnectionStats(params)
    ).pipe(
      catchError(() => of(undefined)),
      map((response: GetStatsResult) =>
        (response?.metricValues ?
          response.metricValues.reduce((result, metric) => {
            result[metric.metric.toString()] = metric.value;
            return result;
          }, {}) :
          {}) as DmsConnectionMetric
      )
    );
  }

  /**
   * Gets all the stats for a connection or connector.
   *
   * @param     id  Connection ID or Connector ID.
   * @param     isConnector  True if the params are for connector.
   * @param     useTimeframe  True if use preset timeframe in the service.
   * @param     isUngroup  True if the connector group contains ungrouped connectors.
   * @param     connectorGroupId  Connector group ID.
   * @returns   Observable of metric.
   */
  getAllStats(
    id: number,
    isConnector = false,
    useTimeframe = false,
    isUngroup = false,
    connectorGroupId?: number,
  ): Observable<DmsConnectionMetric> {
    return combineLatest([
      this.getStats(id, isConnector, [ 'Cpu', 'Memory' ], useTimeframe, isUngroup, connectorGroupId),
      this.getStats(id, isConnector, [ 'WriteIos', 'ReadIos' ], useTimeframe, isUngroup, connectorGroupId),
    ]).pipe(
      map(([stats1, stats2]) => ({ ...stats1, ...stats2 }))
    );
  }

  /**
   * Get provision status of AWS rigel.
   *
   * @param     connectionParams  DmsConnectionStatus.
   *
   * @returns   Observable of ProvisionCloudRigelStatusResult.
   */
  pollRigelProvisionStatus(connectionParams: DmsConnectionStatus): Observable<ProvisionCloudRigelStatusResult>  {
    return this.rigelService.ProvisionCloudRigelStatus(connectionParams?.requestId);
  }

  /**
   * Get decommission status of rigel.
   *
   * @param     connectionParams  DmsConnectionStatus.
   *
   * @returns   Observable of DecommissionCloudRigelStatusResult.
   */
  pollRigelDecommissionStatus(connectionParams: DmsConnectionStatus): Observable<DecommissionCloudRigelStatusResult>  {
    return this.rigelService.DecommissionCloudRigelStatus(connectionParams?.requestId);
  }

  /**
   * Common function to get status details of a connection provision/decommission.
   *
   * @param     statusObject  ProvisionCloudRigelStatusResult | DecommissionCloudRigelStatusResult.
   *
   * @returns   Updated connection object.
   */
  getCloudStatus(statusObject: ProvisionCloudRigelStatusResult | DecommissionCloudRigelStatusResult,
    connection: ConnectionData | DmsConnection): ConnectionData | DmsConnection {
    connection.cloudStatus = statusObject;

    const auditMessagesArray = statusObject?.auditMessages?.split('\n');
    if (auditMessagesArray?.length) {
      auditMessagesArray.splice(0, 1);

      // Progress status to be displayed as timeline events
      connection.auditMessages = [{
        messages: auditMessagesArray,
        timestampSecs: statusObject.startTimeSecs
      }];
    }
    // If connection has an error message, add it to audit message array.
    if (connection.cloudStatus.errorMessage) {
      connection.auditMessages.map(item => {
        item.messages.push(connection.cloudStatus.errorMessage);
      });
    }
    connection.isForceDeleteEnabled ? statusObject?.status === 'Failed' : false;
    if (statusObject?.status === 'Failed') {
      connection.isForceDeleteEnabled = true;
    }
    connection.cloudStatus.status = statusObject?.status;
    return connection;
  }

  /**
   * Set value for upgradeNeeded field if one or more connectors in the connection
   * needs an upgrade.
   *
   * @params    connection  DmsConnection.
   */
  setConnectionUpgradeValue(connection: DmsConnection) {
    connection.connectorGroups.forEach(groups => {
      connection.upgradeNeeded = groups.connectors.some(connector => connector.upgradeNeeded) &&
        flagEnabled(this.irisCtx.irisContext, 'enableRigelUpgrades');
    });
  }

  /**
   * Post call to upgrade the required connectors in a connection.
   *
   * @params    params  UpgradeRigelGroupRequest.
   */
  upgradeConnectors(params: UpgradeRigelGroupRequest, connection: DmsConnection) {
    const data = {
      confirmButtonLabel: 'yes',
      declineButtonLabel: 'no',
      title: 'upgradeConnectors',
      copy: 'upgradeConnectorsMessage',
    };

    this.dialogService.simpleDialog(null, data).pipe(
      this.untilDestroy(),
      filter(Boolean),
      switchMap(() => this.rigelService.UpgradeRigelGroup(params)),
      switchMap((response: any) => this.rigelService.UpgradeRigelGroupStatus(response.upgradeRequestId)))
      .subscribe((upgradeStatusResponse) => {
        this.state.reload();
        connection.upgradeStatus = upgradeStatusResponse.state;
        this.snackBarService.open(this.translate.instant('connectors.upgradeInProgress'));
      },
      err => this.ajaxService.errorMessage(err)
    );
  }

  /**
   * Set value for upgradeNeeded field if one or more connectors in the connection
   * needs an upgrade.
   *
   * @params    connection  DmsConnection.
   */
  getUpgradeStatusString(connection: DmsConnection): string {
    switch (connection.upgradeStatus) {
      case 'InProgress':
        return this.translate.instant('rigel.upgradeStatus.upgrading');
      case 'Failed':
        return this.translate.instant('rigel.upgradeStatus.failed');
      case 'Success':
        return this.translate.instant('rigel.upgradeStatus.success');
      default:
        return this.translate.instant('rigel.upgradeStatus.needsUpgrade');
    }
  }

  /**
   * Call UpgradeRigelGroupStatus api to get the current status of the rigel upgrades.
   *
   * @params    connection  DmsConnection.
   */
  getRigelUpgradeStatus(connection: DmsConnection) {
    this.rigelService.UpgradeRigelGroupStatus(connection.upgradeRequestId).
      pipe(this.untilDestroy()).subscribe((upgradeStatusResponse) => {
      connection.upgradeStatus = upgradeStatusResponse.state;
      connection.upgradeStatusObject = upgradeStatusResponse;
    });
  }
}
