import { Component, HostBinding, Input, TemplateRef, ViewEncapsulation } from '@angular/core';

/**
 * Support options for Statlist item spacing. Impacts the amount of space
 * between the items in this the list.
 */
export type StatlistItemSpacing =
  'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'stretch' | 'grid' | 'space-evenly' | 'equal-width' | 'flex-wrap';

/**
 * Align the content or items accordingly.
 */
export type AlignmentOptions = 'center' | 'left' | 'right';

/**
 * This component displays a list of stats (or data points) in a horizontal list.
 * Optionally, it can be vertically displayed by adding a vertical attribute with true value.
 *
 * @example
 *   Simple Usage:
 *   <cog-statlist [title]="'criticalEvents' | translate">
 *     <cog-statlist-item>
 *       24
 *     </cog-statlist-item>
 *   </cog-statlist>
 *
 *   Vertical List:
 *   <cog-statlist [title]="'criticalEvents' | translate" [vertical]="true">
 *     [stat list items here]
 *   </cog-statlist>
 *
 *   Large Values:
 *   <cog-statlist [title]="'criticalEvents' | translate" valueSize="lg">
 *     <cog-statlist-item>
 *       24
 *     </cog-statlist-item>
 *   </cog-statlist>
 */
@Component({
  selector: 'cog-statlist',
  templateUrl: './statlist.component.html',
  styleUrls: ['./statlist.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StatlistComponent {
  /**
   * Optional title to be displayed above the statlist.
   */
  @Input() title: string;

  /**
   * Optional templete to render. This will take precedence over the title if it is set.
   */
  @Input() titleTemplate: TemplateRef<any>;

  /**
   * Text size for the values in the list.
   */
  @Input() valueSize: 'sm' | 'md' | 'lg' = 'sm';

  /**
   * Position of the statlist items.
   */
  @Input() contentAlign: AlignmentOptions = 'left';

  /**
   * Alignment of item label, icon and value
   */
  @Input() itemAlign: AlignmentOptions = 'left';

  /**
   * Optional item spacing to add padding between items. If not provided items
   * will be stretched to fill available space. If provided, items will collapse
   * with the padding specified provided between each item.
   */
  @Input() itemSpacing: StatlistItemSpacing = 'stretch';

  /**
   * Indicates if stat value and label text wrapping should be allowed in
   * horizontal mode. NOTE: Always allowed in vertical mode, as alignment is
   * less of an issue in such scenarios.
   */
  @HostBinding('class.statlist-wrappable')
  @Input() allowWrapping: false;

  /**
   * If vertical input is provided and true, add the necessary class.
   */
  @HostBinding('class.statlist-vertical') @Input() vertical = false;

  /**
   * Add a class to indicate horizontal layout.
   */
  @HostBinding('class.statlist-horizontal')
  get horizontal() {
    return !this.vertical;
  }
}
