export enum DirEntryType {
  kFile = 1,
  kDirectory,
  kSymlink,
}

export enum SharepointItemMetadataType {
  /**
   * The type is unknown. It could be a new type not defined above, or any
   * of the above types
   */
  kUnspecified,

  /**
   * This is a file in the sharepoint site
   */
  kFile,

  /**
   * This is a folder in sharepoint site
   */
  kDirectory,

  /**
   * This is a document library in sharepoint site
   */
  kSiteDoclib,

  /**
   * This is a list in sharepoint site. Lists defined as GenericList by
   * Msft (baseType 0) are set with this type.
   */
  kSiteList,
}

/**
 * Info about a sharepoint item.
 */
export interface SharepointItemMetadata {
  type?: SharepointItemMetadataType;
}

/**
 * Represents fstat result on a file.
 */
export interface FileStatInfo {
  /**
   * If this is a file, the size of the file as returned by stat.
   */
  size?: number;

  /**
   * If this is a file, the mtime as returned by stat.
   */
  mtimeUsecs?: number;

  /**
   * The type of this entity. This field will not be populated for ReadDir
   * results, since the DirEntry already contains the type information.
   */
  type?: DirEntryType;

  /**
   * Source inode id metadata for certain adapters e.g. Netapp.
   */
  backupSourceInodeId?: number;

  /**
   * Metadata for the sharepoint item in browse. This will be set only if we
   * are quering for sharepoint site. This is used to further different between
   * item types, for example a kDirectory item in sharepoint site could be a
   * Document library (kSiteDoclib), list (kSiteList) or folder (kDirectory)
   */
  sharepointItemMetadata?: SharepointItemMetadata;
}

/**
 * DirEntry is the struct to represent a file or a folder.
 */
export interface DirEntry {
  /**
   * DirEntryType is the type of entry i.e. file/folder.
   */
  type?: DirEntryType;

  /**
   * Name is the name of the file or folder. For /test/file.txt, name will be
   * file.txt.
   */
  name?: string;

  /**
   * FullPath is the full path of the file/directory.
   */
  fullPath?: string;

  /**
   * FstatInfo is the stat information for the file.
   */
  fstatInfo?: FileStatInfo;
}

/**
 * ReadDirResult is the struct to return the result of read directory.
 */
export interface ReadDirResult {
  /**
   * Entries is the array of files and folders that are immediate children
   * of the parent directory specified in the request.
   */
  entries?: DirEntry[];

  /**
   * Cookie is used for paginating results. If ReadVMDirResult is returning
   * partial results, this field will be set. Supplying this cookie will
   * resume listing from where this result left off.
   */
  cookie?: string;
}
