<mat-list-item (click)="markVisited()">

  <div class="c-media" [uiSref]="uiSref" [uiParams]="uiParams" [uiOptions]="{ reload: true }">
    <div class="c-media-object">
      <mat-icon [color]="iconThemePalette">{{iconName}}</mat-icon>
    </div>
    <div class="c-media-body">
      <div [innerHTML]="getNotificationContent() || (stringKey | translate:item)"></div>
      <small class="meta-info">
        {{item.createdTimeSecs * 1000000 | humanizeFromUsecs}}
      </small>
    </div>
  </div>

  <div class="icn-sm icn-cancel" (click)="dismissNotification($event)" *ngIf="!updating"></div>
  <cog-spinner *ngIf="updating" size="xs"></cog-spinner>

</mat-list-item>
