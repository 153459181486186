/**
 * An enum for cell colors in the inventory table
 */
export enum CellColorStates {
  // critical error
  critical = 'critical',
  // error with high impact
  high = 'high',
  // wanring with medium severity
  medium = 'medium',
  // waring with low severity
  low = 'low',
  // green/success state
  ok = 'ok',
  // actionable state
  info = 'info',
  // unknown/indeterminate state
  unknown = 'unknown',
  // no background
  none = 'none',
}
