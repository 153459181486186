import { AfterViewInit, Component, Input, QueryList, ViewChildren } from '@angular/core';
import { FiltersComponent, TreeSelectValuePropertyFilterComponent, ValueFilterSelection } from '@cohesity/helix';
import { EnvGroup, Environment } from '@cohesity/iris-shared-constants';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { BaseObjectFilterComponent } from '../base-object-filter';
import { EnvironmentGroupsService } from '../environment-groups.service';

/**
 * This is a reusable filter that can be used to filter for environment groups.
 */
@Component({
  selector: 'coh-environment-group-tree-filter',
  templateUrl: './environment-group-tree-filter.component.html',
})
export class EnvironmentGroupTreeFilterComponent extends BaseObjectFilterComponent<Environment[]>
  implements AfterViewInit {
  /**
   * The preLabel to be displayed for the filter.
   */
  @Input() preLabel = '';

  /**
   * The name of the property containing the sourceTypes.
   */
  @Input() property = 'sourceType';

  /**
   * The label for the filter.
   */
  @Input() label = this.translateService.instant('sourceType');

  /**
   * Position of filter inside cog-filters.
   */
  @Input() position: number;

  /*
   * Show only the top level for the following groups.
   */
  @Input() showOnlyTopLevelFor: EnvGroup[] = [];

  /**
   * The internal filter component - we need to listen for changes on this.
   */
  @ViewChildren(TreeSelectValuePropertyFilterComponent)
  private internalFilterComponents: QueryList<TreeSelectValuePropertyFilterComponent>;

  constructor(
    filters: FiltersComponent,
    private environmentGroupsService: EnvironmentGroupsService,
    private translateService: TranslateService,
  ) {
    super(filters);
  }

  /**
   * Whenever we refresh the page/use back button, the routerGlobals params
   * are used to set value of this filter. The parameters are of the form
   * [{value:...}] - they don't contain labels.
   * Without a label, it ends up showing "undefined" in UI. Each component
   * which is using environment-group-filter has to itself make sure to set
   * a label for each value at the time of extracting params from routerGlobals
   * (this code would be duplicated in each parent).
   * To avoid this, the component itself sets a corrected value whenever it
   * detects a value change (while at the same time making sure we don't get
   * stuck in cycle of setValue->value changes).
   */
  ngAfterViewInit() {
    // This observable will emit selected value every time a non-null value is selected.
    const selectionChange = this.internalFilterComponents.changes.pipe(
      filter(_ => !!this.internalFilterComponents.first),
      switchMap(_ => this.internalFilterComponents.first.filter.currentValue$),
      map(selectedItem => selectedItem?.value),
      filter(value => !!value && value.length > 0),
    );

    // This observable will emit a map of value->label. Ideally, it will emit only once.
    const optionsChange = this.filterOptions$.pipe(
      map(this.environmentGroupsService.optionsToValueLabelMap),
    );

    combineLatest([optionsChange, selectionChange]
      ).pipe(this.untilDestroy(),
      ).subscribe(([optionsMap, selection]) => {
        let whetherToReplace = false;
        const newSelection = [];
        for (const item of selection) {
          // To make sure there are no cycles, we ensure we're not simply
          // adding label: <any falsy value>
          if (!item?.label && item?.value &&
              (item.value in optionsMap) && !!optionsMap[item.value]) {
            whetherToReplace = true;
            newSelection.push({...item, label: optionsMap[item.value] });
          } else {
            newSelection.push(item);
          }
        }
        if (whetherToReplace) {
          this.filters.setValue(this.property, newSelection);
        }
      });
  }

  /**
   * Get applicable environments and return as filter values.
   */
  getFilterValues(): Observable<ValueFilterSelection[]> {
    // Amazon EC2 instance (Snapshot Manager) exists as kAWSNative in labels
    // (job_type) thus we are mapping EC2 to AWSNative for consumption in Alert Labels
    return this.environmentGroupsService.getFilterValues(this.showOnlyTopLevelFor)
      .pipe(
        map(sourceFilters => {
          if (sourceFilters.find(sourceFilter => sourceFilter.value === 'aws')?.
              subItems?.find(subVal => subVal.value === Environment.kAWSSnapshotManager)?.value) {
            sourceFilters.find(sourceFilter => sourceFilter.value === 'aws').
              subItems.find(subVal => subVal.value === Environment.kAWSSnapshotManager).value = Environment.kAWSNative;
          }

          return sourceFilters;
        })
      );
  }
}
