import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { KubernetesProtectionSource, ProtectionSourceNode, RegisteredSourceInfo } from '@cohesity/api/v1';

/**
 * Kubernetes source details settings components is used to display the
 * registered K8 source's configuration values.
 */
@Component({
  selector: 'coh-kubernetes-source-details-settings',
  templateUrl: './k8-source-details-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KubernetesSourceDetailsSettingsComponent {
  /**
   * Input source.
   */
  @Input() source: ProtectionSourceNode;

  /**
   * Getter to retrieve k8 protection source info from source input.
   */
  get kubernetesInfo(): KubernetesProtectionSource {
    return this.source?.protectionSource?.kubernetesProtectionSource;
  }

  /**
   * Getter to retrieve source registration info.
   */
  get registrationInfo(): RegisteredSourceInfo {
    return this.source?.registrationInfo;
  }
}
