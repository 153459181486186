// Component: Modify snmp component

;(function(angular, undefined) {
  'use strict';

  angular.module('C.snmp')
    .component('editSnmp', {
      templateUrl: 'app/admin/snmp/edit.html',
      controller: editSnmpCtrlFn,
    });

  function editSnmpCtrlFn(_, $scope, $state, evalAJAX,
    SNMPService, FORMATS, cMessage, SNMP_CONST) {

    var $ctrl = this;

    var config = {};

    _.assign($ctrl, {
      // Constants
      SNMP_CONST: SNMP_CONST,

      // UI state
      FORMATS: FORMATS,
      config: config,
      state: SNMPService.state,

      // Other methods
      addReadUser: addReadUser,
      addWriteUser: addWriteUser,
      cancel: cancel,
      removeUser: removeUser,
      selectVersion: selectVersion,
      submitSnmpConfig: submitSnmpConfig,

      // Component lifecycle methods
      $onInit: $onInit,
    });

    /**
     * Initialize and fetch data
     *
     * @method   $onInit
     */
    function $onInit() {
      fetchConfig();
    }

    /**
     * Fetches the SNMP config
     *
     * @method   fetchConfig
     */
    function fetchConfig() {
      SNMPService.fetchConfig()
        .then(function fetchedConfig(config) {
          _.assign($ctrl.config, config);
        }, evalAJAX.errorMessage);
    }

    /**
     * Submit the SNMP config to the server
     *
     * @method     submitSnmpConfig
     */
    function submitSnmpConfig() {
      if ($scope.form.formSNMPConfig.$invalid) {
        return false;
      }

      SNMPService.submitConfig($ctrl.config)
        .then(function submitConfigDone(config) {
          $ctrl.config = config;
          $state.go('snmp-view');
        }, evalAJAX.errorMessage);
    }

    /**
     * Adds a new read user to form. It will use trapuser config as template
     *
     * @method   addReadUser
     */
    function addReadUser() {
      config.readUser = SNMPService.newSNMPUser(
        config.version,
        'kReadUser',
        config.trapUser
      );
    }

    /**
     * Adds a new write user to form. It will use trapuser config as template
     *
     * @method   addWriteUser
     */
    function addWriteUser() {
      config.writeUser = SNMPService.newSNMPUser(
        config.version,
        'kReadWriteUser',
        config.trapUser
      );
    }


    /**
     * Remove a user from the config of given type
     *
     * @method   removeUser
     * @param    {string}   userType   either readUser, writeUser or trapUser
     */
    function removeUser(userType) {
      config[userType] = undefined;
    }

    /**
     * Cancel any changes made in the form and go back to the read-only
     * view.
     *
     * @method     cancel
     */
    function cancel() {
      $state.go('snmp-view');
    }

    /**
     * Makes the version selection in the form. It preserves the entered
     * values from previous version.
     *
     * @method   selectVersion
     * @param    {Object}     item      Selected item
     * @param    {string}     version   Selected version
     */
    function selectVersion(item, version) {
      SNMP_CONST.userKey.forEach(function updateUsers(userKey) {
        if (config[userKey]) {
          config[userKey] = SNMPService.newSNMPUser(
            config.version,
            config[userKey].userType);
        }
      });
    }
  }
})(angular);
