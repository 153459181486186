import { DataPool, DataSetObject } from '@cohesity/api/kepler';
import { decorateInterface } from '@cohesity/utils';

/**
 * Represents location info associated with a data pool
 */
export interface DataPoolsLocationInfo {
/**
 * Id of the DataPoolsLocationInfo. Currently set to the Id of the site for
 * this data pool.
 */
  id: string;

/**
 * Name of the DataPoolsLocationInfo. Currently set to the name of the site for
 * this data pool.
 */
  name: string;

/**
 * Name of the city for this location.
 */
  cityName: string;

/**
 * Name of the region for this location.
 */
  regionName: string;

/**
 * Name of the country for this location.
 */
  countryName: string;
}

/**
 * Decorates the Data Pool interface by adding convenience properties.
 */
export class DecoratedDataPool extends decorateInterface<DataPool>() {
  /**
   * Locations of the objects inside this data pool.
   */
  readonly locations: DataPoolsLocationInfo[] = [];

  /**
   * Types of objects inside this data pool.
   */
  readonly objectTypes: DataSetObject['environment'][] = [];

  /**
   * The number of objects inside this data pool.
   */
  readonly objects: number = 0;

  /**
   * A map of per environment objects inside this data pool.
   */
  readonly envObjectsMap = new Map<DataSetObject['environment'], unknown[]>();

  /**
   * Size of the data pool in bytes.
   */
  readonly sizeBytes: number = 0;

  constructor(dataPool: DataPool) {
    super(dataPool);

    const locations: Record<string, DataPoolsLocationInfo> = {};
    const objectTypes = new Set<DataSetObject['environment']>();
    let objects = 0;

    for (const siteDataSet of dataPool.siteDataSets) {
      const { id, name, locationDetails } = siteDataSet.siteDetails;
      const cityName = locationDetails?.city ?? '';
      const regionName = locationDetails?.regionName ?? '';
      const countryName = locationDetails?.countryName ?? '';
      locations[id] = { id, name, cityName, regionName, countryName };

      siteDataSet.dataSets.forEach(dataSet => {
        objectTypes.add(dataSet.objects[0].environment);
        const dataSetObject = dataSet.objects[0];

        switch (dataSetObject.environment) {
          case 'vmware':
            this.envObjectsMap.set('vmware', dataSetObject.vmwareParams.objects);
            objects += dataSetObject.vmwareParams.objects.length;
            break;
          case 'view':
            this.envObjectsMap.set('view', dataSetObject.viewParams.objects);
            objects += dataSetObject.viewParams.objects.length;
            break;
        }
      });
    }

    this.locations.push(...Object.values(locations));
    this.objectTypes.push(...objectTypes);
    this.objects = objects;
    this.sizeBytes = dataPool.logicalSizeBytes;
  }
}
