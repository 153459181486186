/* eslint-disable no-unsafe-optional-chaining */
import { Component, Input, OnInit } from '@angular/core';
import { ProtectionPolicyResponse } from '@cohesity/api/v2';
import { TranslateService } from '@ngx-translate/core';
import { HighchartsComponent } from '@cohesity/helix';
import { SeriesSankeyOptions} from 'highcharts';

@Component({
  selector: 'coh-policy-illustration',
  templateUrl: './policy-illustration.component.html',
  styleUrls: ['./policy-illustration.component.scss'],
})
export class PolicyIllustrationComponent extends HighchartsComponent<SeriesSankeyOptions> implements OnInit {
  /**
   * Protection Policy with details.
   */
  @Input() policy: ProtectionPolicyResponse;

  constructor(
    private translate: TranslateService,
  ) {
    super({
      title: 'Policy',
      chart: {
        styledMode: true,
      },
      accessibility: {
        point: {
          valueDescriptionFormat: '{index}. {point.from} to {point.to}, {point.weight}.'
        }
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false
      },
      series: [{
        type: 'sankey',
        name: 'Policy Illustration'
      }]
    });
  }

  ngOnInit() {
    this.chartOptions.series[0]['data'] = this.getData();
    this.chartOptions.series[0]['keys'] = ['from', 'to', 'weight'];
    this.render();
  }

  private getData(): any {
    const data = [];
    const clusterIdNameMap = {};
    for (const config of this.policy?.cascadedTargetsConfig) {
      for (const replTarget of config.remoteTargets?.replicationTargets) {
        data.push([config.sourceClusterId, replTarget.remoteTargetConfig.clusterId, 1]);
        clusterIdNameMap[replTarget.remoteTargetConfig.clusterId] =
          replTarget.remoteTargetConfig.clusterName;
      }

      for (const archTarget of config.remoteTargets?.archivalTargets) {
        data.push([config.sourceClusterId, `${archTarget.targetId}:${archTarget.targetType}(Archival)`, 1]);
      }
    }

    for (let idx = 0; idx < data.length; idx++) {
      if (clusterIdNameMap[data[idx][0]]) {
        data[idx][0] = `${clusterIdNameMap[data[idx][0]]}(Replication)`;
      }

      if (clusterIdNameMap[data[idx][1]]) {
        data[idx][1] = `${clusterIdNameMap[data[idx][1]]}(Replication)`;
      }
    }
    return data;
  }
}
