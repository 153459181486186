import { Injectable } from '@angular/core';
import { WindowRef } from '@cohesity/helix';
import {
  appConfigMap,
  flagEnabled,
  getAccessibleAppConfigs,
  getActivePluginConfigs,
  IrisContextService,
  isLoggedIn,
  isMcm
} from '@cohesity/iris-core';
import { AppLoaderService, PluginLoaderService } from '@cohesity/micro-frontend';
import { filter, map, take, tap } from 'rxjs/operators';
import { GuardPriority, GuardResult, StateGuard } from 'src/app/core/state/state-guard';
import { environment } from 'src/environments/environment';

/**
 * App loader guard to initialize app loader on route changes after successful user login.
 */
@Injectable({
  providedIn: 'root',
})
export class AppLoaderGuard implements StateGuard {
  readonly heliosMcmClusterRemotePluginsExportName = 'COG_REMOTE_PLUGINS';

  /**
   * This guard should be run before guards with App priority
   */
  guardPriority = GuardPriority.SingleSpaApp;

  constructor(
    private appLoaderService: AppLoaderService,
    private irisCtx: IrisContextService,
    private pluginLoaderService: PluginLoaderService,
    private windowRef: WindowRef,
  ) { }

  /**
   * This is run on the onStart ui router transition.
   */
  onStart(): GuardResult {
    if (!this.appLoaderService.isStarted) {
      this.irisCtx.irisContext$.pipe(
        filter(irisCtx => isLoggedIn(irisCtx) && isMcm(irisCtx) && flagEnabled(irisCtx, 'microFrontendEnabled')),
        take(1),
        map(irisCtx => {
          if (environment.heliosInFrame && flagEnabled(irisCtx, 'heliosMcmClusterRemotePluginsEnabled')) {
            return {
              irisCtx,
              appConfigs: [],
              pluginConfigs: this.windowRef.nativeWindow.parent?.[this.heliosMcmClusterRemotePluginsExportName] || [],
            };
          }

          const configs = getAccessibleAppConfigs(irisCtx);

          // Always register SiteContinuity v2 application.
          // This is a hack that we have to do because we want to present SiteContinuity v1 and v2 both as a single
          // application.
          if (!configs.find(item => item.name === 'siteContinuityV2')) {
            configs.push(appConfigMap.siteContinuityV2);
          }

          return {
            irisCtx,
            appConfigs: configs,
            pluginConfigs: getActivePluginConfigs(irisCtx),
          };
        }),
        tap(({ irisCtx, appConfigs, pluginConfigs }) => {
          this.appLoaderService.start(appConfigs);
          this.pluginLoaderService.start(pluginConfigs);

          if (!environment.heliosInFrame && flagEnabled(irisCtx, 'heliosMcmClusterRemotePluginsEnabled')) {
            this.windowRef.nativeWindow[this.heliosMcmClusterRemotePluginsExportName] = pluginConfigs;
          }
        }),
      ).subscribe();
    }
  }
}
