import { Injectable } from '@angular/core';
import { Cluster } from '@cohesity/api/v1';
import { BANNER_PRIORITY, BannerConfig } from '@cohesity/helix';
import { BaseBannerConfig, flagEnabled, IrisContext, IrisContextService, isMcmOnPrem } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClusterService } from 'src/app/core/services';


/**
 * Banner to notify user that cluster upgrade is in progress.
 */
@Injectable({
  providedIn: 'root',
})
export class ClusterUpgradeInProgressBannerConfig extends BaseBannerConfig {
  /**
   * Unique banner representation
   */
  id = 'ng-helios-cluster-upgrade-in-progress-banner';

  /**
   * provides banner config as observable
   */
  bannerInfo$ = combineLatest([this.irisCtx.irisContext$, this.clusterService.getClusterInfo()]).pipe(
    map(
      ([ctx, clusterInfo]) =>
        ([{
          id: this.id,
          priority: BANNER_PRIORITY.HIGH,
          isVisible: this.allowBanner(ctx, clusterInfo),
          status: 'info',
          allowClose: true,
          text: this.translateService.instant('clusterUpgrade.banner.info'),
          closeCallback: this.closeCallback,
          actionText: this.translateService.instant('view'),
          actionCallback: () => {
            this.stateService.go('cluster-upgrade');
          },
        } as BannerConfig])
    )
  );

  constructor(
    private irisCtx: IrisContextService,
    private stateService: StateService,
    private translateService: TranslateService,
    private clusterService: ClusterService,
  ) {
    super();
  }

  /**
   * Allow banner based on context
   *
   * @param ctx iris context
   * @param clusterInfo cluster information
   * @returns true/false based on context
   */
  allowBanner(ctx: IrisContext, clusterInfo: Cluster) {
    return (
      this.isLoggedIn(ctx) &&
      !ctx.selectedScope._allClusters &&
      !this.isBannerAcknowledged(this.id) &&
      flagEnabled(ctx, 'ngClusterUpdate') &&
      !isMcmOnPrem(ctx) &&
      clusterInfo.currentOperation === 'kUpgrade'
    );
  }
}
