import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Api } from '../api.constants';
import { App } from '../private-models';

/**
 * Mcm Apps management service.
 */
@Injectable({
  providedIn: 'root',
})
export class McmAppsManagementServiceApi {
  constructor(private http: HttpClient) {}

  /**
   * Get the cluster's connection status with helios.
   *
   * @return A McmApp observable of the result.
   */
  getApps(): Observable<App[]> {
    return this.http.get<App[]>(Api.mcm('apps'));
  }
}
