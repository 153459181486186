<span class="duration-label cog-chart">
  {{ (runDuration$ | async) ? (runDuration$ | async | humanizeDuration) : 'queued' | translate }}
</span>
<div class="run-progress-container">
  <mat-progress-bar
    color="primary"
    mode="determinate"
    [value]="percentageCompleted$ | async">
  </mat-progress-bar>
  <span
    *ngIf="label && (percentageCompleted$ | async); let percentageCompleted"
    class="run-progress-label"
    [innerHTML]="label | translate: { percentFinished: (percentageCompleted | number:'1.0-0') }">
  </span>
</div>
