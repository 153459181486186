<mat-form-field>
  <mat-label>{{ label }}</mat-label>
  <input type="number"
    matInput
    [formControl]="control"
    [placeholder]="config?.placeholder ? config?.placeholder : ''">
  <mat-hint *ngIf="config?.description">
    {{ config?.description }}
  </mat-hint>
  <mat-error *ngIf="control.hasError('required')">
    {{ validationErrorFn( 'required', 'number', label, { numberConfig: config } ) }}
  </mat-error>
  <mat-error *ngIf="control.hasError('min')">
    {{ validationErrorFn( 'min', 'number', label, { numberConfig: config } ) }}
  </mat-error>
  <mat-error *ngIf="control.hasError('max')">
    {{ validationErrorFn( 'max', 'number', label, { numberConfig: config } ) }}
  </mat-error>
</mat-form-field>
