import { MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';

export type DialogSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'delete';

const DialogSizeMap: Record<DialogSize, Partial<MatDialogConfig>> = {
  // Adjusted minimum width for a more compact layout
  'delete': {
    minWidth: '15vw',
  },
  'xs': {
    minWidth: '50vw',
  },
  // Adjusted minimum width for a more balanced layout
  'sm': {
    minWidth: '60vw',
  },
  // Standard minimum width for a comfortable layout
  'md': {
    minWidth: '65vw',
  },
  // Adjusted minimum width for a more expansive layout
  'lg': {
    minWidth: '75vw',
  },
  // Increased minimum width for a more spacious layout
  'xl': {
    minWidth: '80vw',
  },
};

export const getDialogConfig = <D>(
  size: DialogSize,
  config: Partial<MatDialogConfig<D>>,
): MatDialogConfig<D> => ({ ...config, ...DialogSizeMap[size] });
