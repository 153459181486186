import { forwardRef, Provider } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';

/** The ControlValueAccessor OnChange fn */
export type OnChange<T> = (value: T) => void;

/** The ControlValueAccessor OnTouched fn */
export type OnTouched = () => void;

/**
 * Used to provide a `ControlValueAccessor` for form controls.
 *
 * @param component Component which implements CVA
 * @returns value accessor provider
 */
export const valueAccessorProvider = <T>(component: T): Provider => ({
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => component),
  multi: true,
});

/**
 * Used to provide validators for form controls.
 *
 * @param component Component which implements CVA
 * @returns validators provider
 */
export const validatorsProvider = <T>(component: T): Provider => ({
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => component),
  multi: true,
});

/**
 * This class extends the Angular ControlValueAccessor to add strict value type.
 * A component class can implement this interface to impose strict types while
 * incorporating CVA interface.
 */
export interface TypedControlValueAccessor<T> extends ControlValueAccessor {
  writeValue(value: T): void;
  registerOnChange(fn: (value: T) => void): void;
  registerOnTouched(fn: () => void): void;
}

/**
 * A generic validator for a form control, form group or form array. In case of form group or form array, every
 * control is checked and the errors are accumulated.
 *
 * @param control The control object.
 * @returns An object of validation errors or null if there are no errors found with the specified control.
 */
export function genericControlValidator(control: AbstractControl): ValidationErrors | null {
  let errors: ValidationErrors = {};

  if (control instanceof FormGroup) {
    Object.entries(control.controls).forEach(([key, ctrl]) => {
      if (ctrl.errors) {
        errors[key] = ctrl.errors;
      }
    });
  } else if (control instanceof FormArray) {
    for (let i = 0; i < control.length; i++) {
      const ctrl = control.at(i);

      if (ctrl.errors) {
        errors[i] = ctrl.errors;
      }
    }
  } else if (control.errors) {
    errors = { ...errors, ...control.errors };
  }

  return Object.keys(errors).length ? errors : null;
}
