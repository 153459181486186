/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EntityProto } from '../models/entity-proto';
import { EntitySchemaProto } from '../models/entity-schema-proto';
import { TimeSeriesSchemaResponse } from '../models/time-series-schema-response';
import { MetricDataBlock } from '../models/metric-data-block';
import { Task } from '../models/task';
@Injectable({
  providedIn: 'root',
})
class StatisticsService extends __BaseService {
  static readonly GetEntitiesPath = '/public/statistics/entities';
  static readonly GetEntitiesSchemaPath = '/public/statistics/entitiesSchema';
  static readonly GetEntitySchemaByNamePath = '/public/statistics/entitiesSchema/{schemaName}';
  static readonly GetTimeSeriesSchemaPath = '/public/statistics/timeSeriesSchema';
  static readonly GetTimeSeriesStatsPath = '/public/statistics/timeSeriesStats';
  static readonly GetTasksPath = '/public/tasks/status';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Lists the entities for the specified schema.
   *
   * An entity is an object found on the Cohesity Cluster, such as a disk or a
   * Node.
   * In the Cohesity Dashboard, similar functionality is provided in Advanced
   * Diagnostics.
   * @param params The `StatisticsService.GetEntitiesParams` containing the following parameters:
   *
   * - `schemaName`: Specifies the entity schema to search for entities.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `metricNames`: Specifies the list of metric names to return such as 'kRandomIos'
   *   which corresponds to 'Random IOs' in Advanced Diagnostics
   *   of the Cohesity Dashboard.
   *
   * - `maxEntities`: Specifies the maximum entities returned in the result.
   *   By default this field is 500.
   *
   * - `includeAggrMetricSources`: Specifies whether to include the sources of aggregate metrics of an
   *   entity.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetEntitiesResponse(params: StatisticsService.GetEntitiesParams): __Observable<__StrictHttpResponse<Array<EntityProto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.schemaName != null) __params = __params.set('schemaName', params.schemaName.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.metricNames || []).forEach(val => {if (val != null) __params = __params.append('metricNames', val.toString())});
    if (params.maxEntities != null) __params = __params.set('maxEntities', params.maxEntities.toString());
    if (params.includeAggrMetricSources != null) __params = __params.set('includeAggrMetricSources', params.includeAggrMetricSources.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/statistics/entities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EntityProto>>;
      })
    );
  }
  /**
   * Lists the entities for the specified schema.
   *
   * An entity is an object found on the Cohesity Cluster, such as a disk or a
   * Node.
   * In the Cohesity Dashboard, similar functionality is provided in Advanced
   * Diagnostics.
   * @param params The `StatisticsService.GetEntitiesParams` containing the following parameters:
   *
   * - `schemaName`: Specifies the entity schema to search for entities.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `metricNames`: Specifies the list of metric names to return such as 'kRandomIos'
   *   which corresponds to 'Random IOs' in Advanced Diagnostics
   *   of the Cohesity Dashboard.
   *
   * - `maxEntities`: Specifies the maximum entities returned in the result.
   *   By default this field is 500.
   *
   * - `includeAggrMetricSources`: Specifies whether to include the sources of aggregate metrics of an
   *   entity.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetEntities(params: StatisticsService.GetEntitiesParams): __Observable<Array<EntityProto>> {
    return this.GetEntitiesResponse(params).pipe(
      __map(_r => _r.body as Array<EntityProto>)
    );
  }

  /**
   * List the entity schemas filtered by the specified parameters.
   *
   * An entity schema specifies the meta-data associated with entity such as
   * the list of attributes and a time series of data.
   * For example, for a Disk entity, the entity schema specifies the Node that is
   * using this Disk, the type of the Disk, and Metrics about the Disk such as Space
   * Usage, Read IOs and Write IOs. Metrics define data points (time series data)
   * to track over a period of time for a specific interval.
   * If no parameters are specified, all entity schemas found on the Cohesity
   * Cluster are returned.
   * Specifying parameters filters the results that are returned.
   * In the Cohesity Dashboard, similar functionality is provided in Advanced
   * Diagnostics.
   * @param params The `StatisticsService.GetEntitiesSchemaParams` containing the following parameters:
   *
   * - `schemaNames`: Specifies the list of schema names to filter by such as
   *   'kIceboxJobVaultStats' which corresponds to 'External Target Job Stats'
   *   in Advanced Diagnostics of the Cohesity Dashboard.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `metricNames`: Specifies the list of metric names to filter by such as 'kRandomIos'
   *   which corresponds to 'Random IOs' in Advanced Diagnostics
   *   of the Cohesity Dashboard.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetEntitiesSchemaResponse(params: StatisticsService.GetEntitiesSchemaParams): __Observable<__StrictHttpResponse<Array<EntitySchemaProto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.schemaNames || []).forEach(val => {if (val != null) __params = __params.append('schemaNames', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.metricNames || []).forEach(val => {if (val != null) __params = __params.append('metricNames', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/statistics/entitiesSchema`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EntitySchemaProto>>;
      })
    );
  }
  /**
   * List the entity schemas filtered by the specified parameters.
   *
   * An entity schema specifies the meta-data associated with entity such as
   * the list of attributes and a time series of data.
   * For example, for a Disk entity, the entity schema specifies the Node that is
   * using this Disk, the type of the Disk, and Metrics about the Disk such as Space
   * Usage, Read IOs and Write IOs. Metrics define data points (time series data)
   * to track over a period of time for a specific interval.
   * If no parameters are specified, all entity schemas found on the Cohesity
   * Cluster are returned.
   * Specifying parameters filters the results that are returned.
   * In the Cohesity Dashboard, similar functionality is provided in Advanced
   * Diagnostics.
   * @param params The `StatisticsService.GetEntitiesSchemaParams` containing the following parameters:
   *
   * - `schemaNames`: Specifies the list of schema names to filter by such as
   *   'kIceboxJobVaultStats' which corresponds to 'External Target Job Stats'
   *   in Advanced Diagnostics of the Cohesity Dashboard.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `metricNames`: Specifies the list of metric names to filter by such as 'kRandomIos'
   *   which corresponds to 'Random IOs' in Advanced Diagnostics
   *   of the Cohesity Dashboard.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetEntitiesSchema(params: StatisticsService.GetEntitiesSchemaParams): __Observable<Array<EntitySchemaProto>> {
    return this.GetEntitiesSchemaResponse(params).pipe(
      __map(_r => _r.body as Array<EntitySchemaProto>)
    );
  }

  /**
   * Get the entity schema for the specified schema.
   *
   * An entity schema specifies the meta-data associated with entity such as the
   * list of attributes and a time series of data.
   * For example, for a Disk entity, the entity schema specifies the Node that is
   * using this Disk, the type of the Disk, and Metrics about the Disk such as
   * Space Usage, Read IOs and Write IOs. Metrics define data points (time series
   * data) to track over a period of time for a specific interval.
   * In the Cohesity Dashboard, similar functionality is provided in Advanced
   * Diagnostics.
   * @param params The `StatisticsService.GetEntitySchemaByNameParams` containing the following parameters:
   *
   * - `schemaName`: Name of the Schema
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetEntitySchemaByNameResponse(params: StatisticsService.GetEntitySchemaByNameParams): __Observable<__StrictHttpResponse<Array<EntitySchemaProto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/statistics/entitiesSchema/${encodeURIComponent(params.schemaName)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EntitySchemaProto>>;
      })
    );
  }
  /**
   * Get the entity schema for the specified schema.
   *
   * An entity schema specifies the meta-data associated with entity such as the
   * list of attributes and a time series of data.
   * For example, for a Disk entity, the entity schema specifies the Node that is
   * using this Disk, the type of the Disk, and Metrics about the Disk such as
   * Space Usage, Read IOs and Write IOs. Metrics define data points (time series
   * data) to track over a period of time for a specific interval.
   * In the Cohesity Dashboard, similar functionality is provided in Advanced
   * Diagnostics.
   * @param params The `StatisticsService.GetEntitySchemaByNameParams` containing the following parameters:
   *
   * - `schemaName`: Name of the Schema
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetEntitySchemaByName(params: StatisticsService.GetEntitySchemaByNameParams): __Observable<Array<EntitySchemaProto>> {
    return this.GetEntitySchemaByNameResponse(params).pipe(
      __map(_r => _r.body as Array<EntitySchemaProto>)
    );
  }

  /**
   * Gets the Apollo schema information for an entity to list a series of data
   * points.
   * @param params The `StatisticsService.GetTimeSeriesSchemaParams` containing the following parameters:
   *
   * - `entityType`: Specifies the type of the entity.
   *   The following entity types are available:
   *   cluster, viewbox.
   *   EntityType represents the various values for the entity type.
   *   'Cluster' indicates entity type of Cluster.
   *   'StorageDomain' indicates entity type of Storage Domain.
   *
   * - `entityName`: Specifies the name of the entity.
   *
   * - `entityId`: Specifies the id of the entity.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetTimeSeriesSchemaResponse(params: StatisticsService.GetTimeSeriesSchemaParams): __Observable<__StrictHttpResponse<TimeSeriesSchemaResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.entityType != null) __params = __params.set('entityType', params.entityType.toString());
    if (params.entityName != null) __params = __params.set('entityName', params.entityName.toString());
    if (params.entityId != null) __params = __params.set('entityId', params.entityId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/statistics/timeSeriesSchema`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TimeSeriesSchemaResponse>;
      })
    );
  }
  /**
   * Gets the Apollo schema information for an entity to list a series of data
   * points.
   * @param params The `StatisticsService.GetTimeSeriesSchemaParams` containing the following parameters:
   *
   * - `entityType`: Specifies the type of the entity.
   *   The following entity types are available:
   *   cluster, viewbox.
   *   EntityType represents the various values for the entity type.
   *   'Cluster' indicates entity type of Cluster.
   *   'StorageDomain' indicates entity type of Storage Domain.
   *
   * - `entityName`: Specifies the name of the entity.
   *
   * - `entityId`: Specifies the id of the entity.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetTimeSeriesSchema(params: StatisticsService.GetTimeSeriesSchemaParams): __Observable<TimeSeriesSchemaResponse> {
    return this.GetTimeSeriesSchemaResponse(params).pipe(
      __map(_r => _r.body as TimeSeriesSchemaResponse)
    );
  }

  /**
   * List a series of data points for an entity of a metric in a schema,
   * during the specified time period.
   *
   * A Metric specifies a data point (such as CPU usage and IOPS) to track over a
   * period of time.
   * For example for a disk in the Cluster, you can report on the 'Disk Health'
   * (kDiskAwaitTimeMsecs) Metric of the 'Disk Health Metrics' (kSentryDiskStats)
   * Schema for the last week.
   * You must specify the 'k' names as input and not the descriptive names.
   * You must also specify the id of the entity that you are reporting on such as
   * a Cluster, disk drive, job, etc.
   * Get the entityId by running the GET /public/statistics/entities operation.
   * In the Cohesity Dashboard, similar functionality is provided in Advanced
   * Diagnostics.
   * @param params The `StatisticsService.GetTimeSeriesStatsParams` containing the following parameters:
   *
   * - `startTimeMsecs`: Specifies the start time for the series of metric data.
   *   Specify the start time as a Unix epoch Timestamp (in milliseconds).
   *
   * - `schemaName`: Specifies the name of entity schema such as 'kIceboxJobVaultStats'.
   *
   * - `metricName`: Specifies the name of the metric such as the 'kDiskAwaitTimeMsecs'
   *   which is displayed as 'Disk Health' in Advanced Diagnostics
   *   of the Cohesity Dashboard.
   *
   * - `rollupIntervalSecs`: Specifies the time interval granularity (in seconds)
   *   for the specified rollup function.
   *   Only specify a value if Rollup function is also specified.
   *
   * - `rollupFunction`: Specifies the rollup function to apply to the data points for
   *   the time interval specified by rollupInternalSecs.
   *   The following rollup functions are available:
   *   sum, average, count, max, min, median, percentile95, latest,
   *   and rate. For more information about the functions, see the
   *   Advanced Diagnostics in the Cohesity online help.
   *   If not specified, raw data is returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `prorateDataPoints`: Specifies to create pro rated data point for every rollup interval instead
   *   of returning the actual raw data points. This should be used only when
   *   rollup function is provided.
   *
   * - `includeGrowthChange`: Specifies if the response should return the difference of a data point
   *   with the previous datapoint. Used for determining the
   *   change in growth rate. Datapoint could be +x, 0, -x
   *   showing the growth is up, no change or down respectively.
   *
   * - `entityIdList`: Specifies an entity id list represented as a string. The stats result will
   *   be the sum over all these entities. If both EntityIdList and EntityId are
   *   specified, EntityId will be ignored.
   *
   * - `entityId`: Specifies the id of the entity represented as a string.
   *   Get the entityId by running the GET
   *   public/statistics/entities operation.
   *
   * - `endTimeMsecs`: Specifies the end time for the series of metric data.
   *   Specify the end time as a Unix epoch Timestamp (in milliseconds).
   *   If not specified, the data points up to the current time are returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetTimeSeriesStatsResponse(params: StatisticsService.GetTimeSeriesStatsParams): __Observable<__StrictHttpResponse<MetricDataBlock>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startTimeMsecs != null) __params = __params.set('startTimeMsecs', params.startTimeMsecs.toString());
    if (params.schemaName != null) __params = __params.set('schemaName', params.schemaName.toString());
    if (params.metricName != null) __params = __params.set('metricName', params.metricName.toString());
    if (params.rollupIntervalSecs != null) __params = __params.set('rollupIntervalSecs', params.rollupIntervalSecs.toString());
    if (params.rollupFunction != null) __params = __params.set('rollupFunction', params.rollupFunction.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.prorateDataPoints != null) __params = __params.set('prorateDataPoints', params.prorateDataPoints.toString());
    if (params.includeGrowthChange != null) __params = __params.set('includeGrowthChange', params.includeGrowthChange.toString());
    (params.entityIdList || []).forEach(val => {if (val != null) __params = __params.append('entityIdList', val.toString())});
    if (params.entityId != null) __params = __params.set('entityId', params.entityId.toString());
    if (params.endTimeMsecs != null) __params = __params.set('endTimeMsecs', params.endTimeMsecs.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/statistics/timeSeriesStats`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MetricDataBlock>;
      })
    );
  }
  /**
   * List a series of data points for an entity of a metric in a schema,
   * during the specified time period.
   *
   * A Metric specifies a data point (such as CPU usage and IOPS) to track over a
   * period of time.
   * For example for a disk in the Cluster, you can report on the 'Disk Health'
   * (kDiskAwaitTimeMsecs) Metric of the 'Disk Health Metrics' (kSentryDiskStats)
   * Schema for the last week.
   * You must specify the 'k' names as input and not the descriptive names.
   * You must also specify the id of the entity that you are reporting on such as
   * a Cluster, disk drive, job, etc.
   * Get the entityId by running the GET /public/statistics/entities operation.
   * In the Cohesity Dashboard, similar functionality is provided in Advanced
   * Diagnostics.
   * @param params The `StatisticsService.GetTimeSeriesStatsParams` containing the following parameters:
   *
   * - `startTimeMsecs`: Specifies the start time for the series of metric data.
   *   Specify the start time as a Unix epoch Timestamp (in milliseconds).
   *
   * - `schemaName`: Specifies the name of entity schema such as 'kIceboxJobVaultStats'.
   *
   * - `metricName`: Specifies the name of the metric such as the 'kDiskAwaitTimeMsecs'
   *   which is displayed as 'Disk Health' in Advanced Diagnostics
   *   of the Cohesity Dashboard.
   *
   * - `rollupIntervalSecs`: Specifies the time interval granularity (in seconds)
   *   for the specified rollup function.
   *   Only specify a value if Rollup function is also specified.
   *
   * - `rollupFunction`: Specifies the rollup function to apply to the data points for
   *   the time interval specified by rollupInternalSecs.
   *   The following rollup functions are available:
   *   sum, average, count, max, min, median, percentile95, latest,
   *   and rate. For more information about the functions, see the
   *   Advanced Diagnostics in the Cohesity online help.
   *   If not specified, raw data is returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `prorateDataPoints`: Specifies to create pro rated data point for every rollup interval instead
   *   of returning the actual raw data points. This should be used only when
   *   rollup function is provided.
   *
   * - `includeGrowthChange`: Specifies if the response should return the difference of a data point
   *   with the previous datapoint. Used for determining the
   *   change in growth rate. Datapoint could be +x, 0, -x
   *   showing the growth is up, no change or down respectively.
   *
   * - `entityIdList`: Specifies an entity id list represented as a string. The stats result will
   *   be the sum over all these entities. If both EntityIdList and EntityId are
   *   specified, EntityId will be ignored.
   *
   * - `entityId`: Specifies the id of the entity represented as a string.
   *   Get the entityId by running the GET
   *   public/statistics/entities operation.
   *
   * - `endTimeMsecs`: Specifies the end time for the series of metric data.
   *   Specify the end time as a Unix epoch Timestamp (in milliseconds).
   *   If not specified, the data points up to the current time are returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetTimeSeriesStats(params: StatisticsService.GetTimeSeriesStatsParams): __Observable<MetricDataBlock> {
    return this.GetTimeSeriesStatsResponse(params).pipe(
      __map(_r => _r.body as MetricDataBlock)
    );
  }

  /**
   * Gets the progress and status of tasks.
   * @param params The `StatisticsService.GetTasksParams` containing the following parameters:
   *
   * - `taskPaths`: Specifies a list of tasks path by which to filter the results. Otherwise
   *   all of the tasks will be returned.
   *
   * - `startTimeSeconds`: Specifies a start time by which to filter tasks. Including a value
   *   here will result in tasks only being included if they started after
   *   the time specified.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `maxTasks`: Specifies the maximum number of tasks to display. Defaults to 100.
   *
   * - `includeFinishedTasks`: Specifies whether or not to include finished tasks. By default,
   *   Pulse will only include unfinished tasks.
   *
   * - `excludeSubTasks`: Specifies whether or not to include subtasks. By default all subtasks
   *   of any task matching a query will be returned.
   *
   * - `endTimeSeconds`: Specifies an end time by which to filter tasks. Including a value
   *   here will result in tasks only being included if the ended before
   *   this time.
   *
   * - `attributes`: If specified, tasks matching the current query are further filtered by
   *   these KeyValuePairs. This gives client an ability to search by custom
   *   attributes that they specified during the task creation. Only the Tasks
   *   having 'all' of the specified key=value pairs will be returned.
   *   Attributes passed in should be separated by commas and each must follow
   *   the pattern "name:type:value". Valid types are "kInt64", "kDouble",
   *   "kString", and "kBytes".
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetTasksResponse(params: StatisticsService.GetTasksParams): __Observable<__StrictHttpResponse<Array<Task>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.taskPaths || []).forEach(val => {if (val != null) __params = __params.append('taskPaths', val.toString())});
    if (params.startTimeSeconds != null) __params = __params.set('startTimeSeconds', params.startTimeSeconds.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.maxTasks != null) __params = __params.set('maxTasks', params.maxTasks.toString());
    if (params.includeFinishedTasks != null) __params = __params.set('includeFinishedTasks', params.includeFinishedTasks.toString());
    if (params.excludeSubTasks != null) __params = __params.set('excludeSubTasks', params.excludeSubTasks.toString());
    if (params.endTimeSeconds != null) __params = __params.set('endTimeSeconds', params.endTimeSeconds.toString());
    (params.attributes || []).forEach(val => {if (val != null) __params = __params.append('attributes', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/tasks/status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Task>>;
      })
    );
  }
  /**
   * Gets the progress and status of tasks.
   * @param params The `StatisticsService.GetTasksParams` containing the following parameters:
   *
   * - `taskPaths`: Specifies a list of tasks path by which to filter the results. Otherwise
   *   all of the tasks will be returned.
   *
   * - `startTimeSeconds`: Specifies a start time by which to filter tasks. Including a value
   *   here will result in tasks only being included if they started after
   *   the time specified.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `maxTasks`: Specifies the maximum number of tasks to display. Defaults to 100.
   *
   * - `includeFinishedTasks`: Specifies whether or not to include finished tasks. By default,
   *   Pulse will only include unfinished tasks.
   *
   * - `excludeSubTasks`: Specifies whether or not to include subtasks. By default all subtasks
   *   of any task matching a query will be returned.
   *
   * - `endTimeSeconds`: Specifies an end time by which to filter tasks. Including a value
   *   here will result in tasks only being included if the ended before
   *   this time.
   *
   * - `attributes`: If specified, tasks matching the current query are further filtered by
   *   these KeyValuePairs. This gives client an ability to search by custom
   *   attributes that they specified during the task creation. Only the Tasks
   *   having 'all' of the specified key=value pairs will be returned.
   *   Attributes passed in should be separated by commas and each must follow
   *   the pattern "name:type:value". Valid types are "kInt64", "kDouble",
   *   "kString", and "kBytes".
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetTasks(params: StatisticsService.GetTasksParams): __Observable<Array<Task>> {
    return this.GetTasksResponse(params).pipe(
      __map(_r => _r.body as Array<Task>)
    );
  }
}

module StatisticsService {

  /**
   * Parameters for GetEntities
   */
  export interface GetEntitiesParams {

    /**
     * Specifies the entity schema to search for entities.
     */
    schemaName: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the list of metric names to return such as 'kRandomIos'
     * which corresponds to 'Random IOs' in Advanced Diagnostics
     * of the Cohesity Dashboard.
     */
    metricNames?: Array<string>;

    /**
     * Specifies the maximum entities returned in the result.
     * By default this field is 500.
     */
    maxEntities?: number;

    /**
     * Specifies whether to include the sources of aggregate metrics of an
     * entity.
     */
    includeAggrMetricSources?: boolean;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetEntitiesSchema
   */
  export interface GetEntitiesSchemaParams {

    /**
     * Specifies the list of schema names to filter by such as
     * 'kIceboxJobVaultStats' which corresponds to 'External Target Job Stats'
     * in Advanced Diagnostics of the Cohesity Dashboard.
     */
    schemaNames?: Array<string>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the list of metric names to filter by such as 'kRandomIos'
     * which corresponds to 'Random IOs' in Advanced Diagnostics
     * of the Cohesity Dashboard.
     */
    metricNames?: Array<string>;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetEntitySchemaByName
   */
  export interface GetEntitySchemaByNameParams {

    /**
     * Name of the Schema
     */
    schemaName: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetTimeSeriesSchema
   */
  export interface GetTimeSeriesSchemaParams {

    /**
     * Specifies the type of the entity.
     * The following entity types are available:
     * cluster, viewbox.
     * EntityType represents the various values for the entity type.
     * 'Cluster' indicates entity type of Cluster.
     * 'StorageDomain' indicates entity type of Storage Domain.
     */
    entityType: 'Cluster' | 'StorageDomain';

    /**
     * Specifies the name of the entity.
     */
    entityName: string;

    /**
     * Specifies the id of the entity.
     */
    entityId: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetTimeSeriesStats
   */
  export interface GetTimeSeriesStatsParams {

    /**
     * Specifies the start time for the series of metric data.
     * Specify the start time as a Unix epoch Timestamp (in milliseconds).
     */
    startTimeMsecs: number;

    /**
     * Specifies the name of entity schema such as 'kIceboxJobVaultStats'.
     */
    schemaName: string;

    /**
     * Specifies the name of the metric such as the 'kDiskAwaitTimeMsecs'
     * which is displayed as 'Disk Health' in Advanced Diagnostics
     * of the Cohesity Dashboard.
     */
    metricName: string;

    /**
     * Specifies the time interval granularity (in seconds)
     * for the specified rollup function.
     * Only specify a value if Rollup function is also specified.
     */
    rollupIntervalSecs?: number;

    /**
     * Specifies the rollup function to apply to the data points for
     * the time interval specified by rollupInternalSecs.
     * The following rollup functions are available:
     * sum, average, count, max, min, median, percentile95, latest,
     * and rate. For more information about the functions, see the
     * Advanced Diagnostics in the Cohesity online help.
     * If not specified, raw data is returned.
     */
    rollupFunction?: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies to create pro rated data point for every rollup interval instead
     * of returning the actual raw data points. This should be used only when
     * rollup function is provided.
     */
    prorateDataPoints?: boolean;

    /**
     * Specifies if the response should return the difference of a data point
     * with the previous datapoint. Used for determining the
     * change in growth rate. Datapoint could be +x, 0, -x
     * showing the growth is up, no change or down respectively.
     */
    includeGrowthChange?: boolean;

    /**
     * Specifies an entity id list represented as a string. The stats result will
     * be the sum over all these entities. If both EntityIdList and EntityId are
     * specified, EntityId will be ignored.
     */
    entityIdList?: Array<string>;

    /**
     * Specifies the id of the entity represented as a string.
     * Get the entityId by running the GET
     * public/statistics/entities operation.
     */
    entityId?: string;

    /**
     * Specifies the end time for the series of metric data.
     * Specify the end time as a Unix epoch Timestamp (in milliseconds).
     * If not specified, the data points up to the current time are returned.
     */
    endTimeMsecs?: number;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetTasks
   */
  export interface GetTasksParams {

    /**
     * Specifies a list of tasks path by which to filter the results. Otherwise
     * all of the tasks will be returned.
     */
    taskPaths?: Array<string>;

    /**
     * Specifies a start time by which to filter tasks. Including a value
     * here will result in tasks only being included if they started after
     * the time specified.
     */
    startTimeSeconds?: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the maximum number of tasks to display. Defaults to 100.
     */
    maxTasks?: number;

    /**
     * Specifies whether or not to include finished tasks. By default,
     * Pulse will only include unfinished tasks.
     */
    includeFinishedTasks?: boolean;

    /**
     * Specifies whether or not to include subtasks. By default all subtasks
     * of any task matching a query will be returned.
     */
    excludeSubTasks?: boolean;

    /**
     * Specifies an end time by which to filter tasks. Including a value
     * here will result in tasks only being included if the ended before
     * this time.
     */
    endTimeSeconds?: number;

    /**
     * If specified, tasks matching the current query are further filtered by
     * these KeyValuePairs. This gives client an ability to search by custom
     * attributes that they specified during the task creation. Only the Tasks
     * having 'all' of the specified key=value pairs will be returned.
     * Attributes passed in should be separated by commas and each must follow
     * the pattern "name:type:value". Valid types are "kInt64", "kDouble",
     * "kString", and "kBytes".
     */
    attributes?: Array<string>;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { StatisticsService }
