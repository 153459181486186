import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyChipInputEvent as MatChipInputEvent, MatLegacyChipsModule, MatLegacyChipList as MatChipList } from '@angular/material/legacy-chips';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import { DataIdDirective } from '../data-id/data-id.directive';

export type NonEmptyArray<Type> = [Type, ...Type[]];

@Component({
  selector: 'cog-chip-list-input',
  templateUrl: './chip-list-input.component.html',
  styleUrls: ['./chip-list-input.component.scss'],
  standalone: true,
  imports: [
    DataIdDirective,
    MatFormFieldModule,
    NgIf,
    MatLegacyChipsModule,
    NgFor,
    MatIconModule,
    MatLegacyButtonModule,
    MatLegacyTooltipModule,
    TranslateModule,
    NgClass
  ],
})
export class ChipListInputComponent {
  constructor() {}

  /**
   * Reference to the chip list component.
   */
  @ViewChild('chipList') chipList: MatChipList;

  /**
   * Display-only prefix for each chip value.
   */
  @Input() chipDisplayPrefix = '';

  /**
   * Hint message for the chip list component.
   */
  @Input() hintMessage = 'chipListInput.hint';

  /**
   * Error message for the chip list component.
   */
  @Input() errorMessage = 'chipListInput.error';

  /**
   * Error message for the invalid input.
   */
  invalidErrorMessage = this.errorMessage;

  /**
   * Id will be used to generate unique data-testid for the component.
   */
  @Input() id?: string;

  /**
   * Label for the chip list component.
   */
  @Input() label: string;

  /**
   * Control for the chip list component. This control will be used to store the list of chips.
   */
  @Input() control: FormControl<null | string[]>;

  /**
   * Whether the formcontrol is required or not.
   */
  @Input() isRequired = false;

  /**
   * Whether the formcontrol should be full width.
   */
  @Input() fullWidth: boolean;

  /**
   * Whether to show or hide the copy button. Defaults to true.
   */
  @Input() showCopyButton = true;

  /**
   * Separator keys for chip list component.
   * Note: First element in the array will be used as the default separator key for operations like "copy to clipboard".
   */
  @Input() separatorKeys: NonEmptyArray<{ key: number; char: string }> = [
    {
      key: COMMA,
      char: ',',
    },
    {
      key: ENTER,
      char: '\n',
    },
    {
      key: SPACE,
      char: ' ',
    }
  ];

  /**
   * List of invalid chip values. This will be used to show error message.
   */
  invalidChipValues: string;

  /**
   * Validator function to validate the chip input.
   */
  @Input() validatorFn?: (element: string) => boolean = () => true;

  /**
   * Function to transform the chip value before adding to the list.
   */
  @Input() transformBeforeAdd?: (element: string) => string = (element) => element;

  get separatorRegex() {
    return new RegExp(`[${this.separatorKeys.map((key) => key.char).join('')}]`);
  }

  get separatorKeyCodes() {
    return this.separatorKeys.map((key) => key.key);
  }

  /**
   * Removes chips from the chipValue list.
   *
   * @param chipValue string value to be removed from the chip list
   */
  handleChipRemove(chipValue: string) {
    const newList = (this.control.value || []).filter((value) => value !== chipValue);
    this.control.setValue(newList);
  }

  /**
   * Validates and add chips into the chipValue list.
   *
   * @param value string value to be validated and added to the chip list
   * @returns
   */
  private validateAddChips(value: string) {
    const chips = (value || '')
      .split(this.separatorRegex)
      .map((val) => val.trim())
      .filter(Boolean);
    const uniqueChips = [...new Set(chips)];

    const invalid = [];
    const valid = [];
    uniqueChips.forEach((c) => {
      const chip = this.transformBeforeAdd(c);
      if (this.validatorFn(chip)) {
        valid.push(chip);
        return;
      }
      invalid.push(chip);
    });

    if (valid.length) {
      // adding valid chips
      const newList = [...(this.control.value || []), ...valid];
      this.control.setValue([...new Set(newList)]);
    }

    this.updateErrorState(invalid);
  }

  private updateErrorState(invalidChips: string[]): void {
    if (invalidChips.length) {
      this.chipList.errorState = true;
      this.invalidChipValues = invalidChips.join(', ');
      this.invalidErrorMessage = this.errorMessage;
    } else {
      this.chipList.errorState = this.isRequired && !(this.control.value || []).length;
      this.invalidErrorMessage = this.chipList.errorState ? 'errors.required' : '';
      this.invalidChipValues = '';
    }
  }

  /**
   * Adds chips into the list.
   *
   * @param event event object
   */
  handleChipAdd(event: MatChipInputEvent) {
    event.chipInput.clear();
    this.validateAddChips(event.value);
  }

  /**
   * Adds pasted string into the list.
   */
  handleChipPaste(event: ClipboardEvent) {
    event.preventDefault();
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    this.validateAddChips(pastedText);
  }

  /**
   * Copies items present in chip list into the clipboard.
   */
  handleChipCopy() {
    const chips = this.control.value || [];
    const text = chips.join(this.separatorKeys[0].char);
    navigator.clipboard.writeText(text);
  }


  /**
   * Manually triggers validation and error state update on form submit.
   */
  markAsTouchedAndValidate() {
    this.control.markAsTouched();
    this.updateErrorState([]);
  }
}
