// Component: c-filter-applied.

;(function(angular, undefined) {
  'use strict';

  angular.module('C.cFilters')
    .component('cFilterApplied', {
      // Attribute bindings
      bindings: {
        /**
         * list of applied filter to be shown in the pills.
         *
         * @type   {Array}
         */
        fields: '<',
      },
      require: {
        // find c-filters controller who is exposing utility functions like
        // how clear a field and clear all fields.
        cFilters: '^^cFilters',
      },
      templateUrl: 'app/global/c-filters/c-filter-applied.html',
    });

})(angular);
