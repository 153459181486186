import { commonGrowthColumns, commonGrowthReportConfigItems } from './common-growth-report-config-items';
import { ReportColumnConfigs } from '../common-report-items/custom-reports.service';
import { ReportSection } from '../iris-reporting.model';

/**
 * This is the layout config for the object growth report.
 */
export const objectGrowthReport: ReportSection[] = [
  {
    title: 'reporting.summary',
    items: [
      {
        id: 'statlistItem',
        componentIds: ['1701'],
        componentNames: ['ObjectGrowthGlanceBar'],
        params: {
          items: [
            {
              title: 'objects',
              translate: true,
              measurement: {
                valueKey: 'countObjectUuid',
                dataType: 'number',
              },
            },
            ...commonGrowthReportConfigItems.glanceBar,
          ],
        },
      },
    ],
  },
  {
    items: [
      {
        id: 'chartItem',
        componentIds: ['1702'],
        componentNames: ['ObjectGrowthByType'],
        title: 'reporting.sourceDataByObjectType',
        params: {
          chartType: 'pie',
          measurements: [
            {
              valueKey: 'sumSrcDataBytes',
              dataType: 'bytes',
            },
          ],
          dimensions: [
            {
              dimensionKey: 'environment',
              maxValues: 3,
            },
          ],
        },
      },
      {
        id: 'tableItem',
        componentIds: ['1703'],
        componentNames: ['ObjectGrowthTopMovers'],
        title: 'reporting.topMoversByObjectData',
        params: {
          noHeader: true,
          columns: [
            {
              title: '',
              translate: true,
              key: 'objectName',
              icon: {
                key: 'objectType',
                dataType: 'objectTypeIcon',
                renderParams: {
                  environmentKey: 'environment',
                },
              },
              primary: {
                key: 'objectName',
                dataType: 'text',
                renderParams: {
                  width: 'lg',
                },
              },
              subtitle: {
                key: 'environment',
                dataType: 'environmentName',
              },
            },
            {
              title: '',
              key: 'srcDataDailyGrowthRate',
              primary: {
                key: 'srcDataDailyGrowthRate',
                dataType: 'bytes',
              },
            },
          ],
        },
      },
    ],
  },
  {
    title: 'reporting.data',
    isFullData: true,
    items: [
      {
        id: 'tableItem',
        componentIds: ['1700'],
        componentNames: ['AllObjectsGrowth'],
        params: {
          columns: [
            {
              title: 'objectName',
              translate: true,
              key: 'objectName',
              icon: {
                key: 'objectType',
                dataType: 'objectTypeIcon',
                renderParams: {
                  environmentKey: 'environment',
                },
              },
              primary: {
                key: 'objectName',
                dataType: 'text',
                renderParams: {
                  width: 'lg',
                },
              },
              subtitle: {
                key: 'environment',
                dataType: 'environmentName',
              },
            },
            {
              title: 'source',
              translate: true,
              key: 'sourceName',
              primary: {
                key: 'sourceName',
                dataType: 'text',
                renderParams: {
                  width: 'lg',
                },
              },
            },
            ...commonGrowthReportConfigItems.table,
          ],
        },
      },
    ],
  },
];

/**
 * This is metadata needed to determine available chart customizations.
 */
export const objectGrowthColumns: ReportColumnConfigs = {
  objectName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  objectUuid: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  ...commonGrowthColumns
};
