/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiSizerConfiguration as __Configuration } from '../cohesity-api-sizer-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AddProtectionGroupDefaults } from '../models/add-protection-group-defaults';
import { AddNodeDefaults } from '../models/add-node-defaults';
import { EditStorageDomainDefaults } from '../models/edit-storage-domain-defaults';
import { SimulationSummary } from '../models/simulation-summary';
import { SimulationId } from '../models/simulation-id';
import { Simulation } from '../models/simulation';
@Injectable({
  providedIn: 'root',
})
class SimulatorService extends __BaseService {
  static readonly getProtectionGroupDefaultsPath = '/defaults/addProtectionGroup';
  static readonly getAddNodeDefaultsPath = '/defaults/addNode';
  static readonly getEditStorageDomainDefaultsPath = '/defaults/editStorageDomain';
  static readonly getAllSimulationsPath = '/simulations';
  static readonly simulationsPostPath = '/simulations';
  static readonly simulationsIdDeletePath = '/simulations/{id}';
  static readonly simulationsIdGetPath = '/simulations/{id}';
  static readonly simulationsIdPutPath = '/simulations/{id}';
  static readonly simulationsIdRunPostPath = '/simulations/{id}/run';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get the default parameters for add protection group event
   *
   * Get the default parameters for add protection group event
   * @return Successfully got all the defaults
   */
  getProtectionGroupDefaultsResponse(): __Observable<__StrictHttpResponse<AddProtectionGroupDefaults>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/defaults/addProtectionGroup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AddProtectionGroupDefaults>;
      })
    );
  }
  /**
   * Get the default parameters for add protection group event
   *
   * Get the default parameters for add protection group event
   * @return Successfully got all the defaults
   */
  getProtectionGroupDefaults(): __Observable<AddProtectionGroupDefaults> {
    return this.getProtectionGroupDefaultsResponse().pipe(
      __map(_r => _r.body as AddProtectionGroupDefaults)
    );
  }

  /**
   * Get the default parameters for add node event
   *
   * Get the default parameters for add node event
   * @return Successfully got all the defaults
   */
  getAddNodeDefaultsResponse(): __Observable<__StrictHttpResponse<AddNodeDefaults>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/defaults/addNode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AddNodeDefaults>;
      })
    );
  }
  /**
   * Get the default parameters for add node event
   *
   * Get the default parameters for add node event
   * @return Successfully got all the defaults
   */
  getAddNodeDefaults(): __Observable<AddNodeDefaults> {
    return this.getAddNodeDefaultsResponse().pipe(
      __map(_r => _r.body as AddNodeDefaults)
    );
  }

  /**
   * Get the default parameters for edit storage domain event
   *
   * Get the default parameters for edit storage domain event
   * @return Successfully got all the defaults
   */
  getEditStorageDomainDefaultsResponse(): __Observable<__StrictHttpResponse<EditStorageDomainDefaults>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/defaults/editStorageDomain`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EditStorageDomainDefaults>;
      })
    );
  }
  /**
   * Get the default parameters for edit storage domain event
   *
   * Get the default parameters for edit storage domain event
   * @return Successfully got all the defaults
   */
  getEditStorageDomainDefaults(): __Observable<EditStorageDomainDefaults> {
    return this.getEditStorageDomainDefaultsResponse().pipe(
      __map(_r => _r.body as EditStorageDomainDefaults)
    );
  }

  /**
   * Get simulation summary of all existing simulations
   *
   * REST client can get list of all existing simulations using this operation
   * @return success
   */
  getAllSimulationsResponse(): __Observable<__StrictHttpResponse<Array<SimulationSummary>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/simulations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SimulationSummary>>;
      })
    );
  }
  /**
   * Get simulation summary of all existing simulations
   *
   * REST client can get list of all existing simulations using this operation
   * @return success
   */
  getAllSimulations(): __Observable<Array<SimulationSummary>> {
    return this.getAllSimulationsResponse().pipe(
      __map(_r => _r.body as Array<SimulationSummary>)
    );
  }

  /**
   * Create a new simulation
   *
   * This method lets client create a simulation A client can add the following events to a simulation * Add Job * Remove Job * Edit Job * Add Node * Add view * Adjust Storage Domain * Edit Policy * If the simulation is created successfully then a json object is returned in response which contains the simulation-id. Simulation schema is supplied in the request-body.
   * @param body Add simulation information and the desired events
   * @return Simulation created successfully
   */
  simulationsPostResponse(body: Simulation): __Observable<__StrictHttpResponse<SimulationId>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/simulations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SimulationId>;
      })
    );
  }
  /**
   * Create a new simulation
   *
   * This method lets client create a simulation A client can add the following events to a simulation * Add Job * Remove Job * Edit Job * Add Node * Add view * Adjust Storage Domain * Edit Policy * If the simulation is created successfully then a json object is returned in response which contains the simulation-id. Simulation schema is supplied in the request-body.
   * @param body Add simulation information and the desired events
   * @return Simulation created successfully
   */
  simulationsPost(body: Simulation): __Observable<SimulationId> {
    return this.simulationsPostResponse(body).pipe(
      __map(_r => _r.body as SimulationId)
    );
  }

  /**
   * Deletes a given simulation by simulation-id.
   *
   * REST client can delete a simulation using simulation-id. This will delete the existing simulation, it's associated events and results if any. **_simulation-id_** is specified as a path parameter.
   * @param id undefined
   */
  simulationsIdDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/simulations/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes a given simulation by simulation-id.
   *
   * REST client can delete a simulation using simulation-id. This will delete the existing simulation, it's associated events and results if any. **_simulation-id_** is specified as a path parameter.
   * @param id undefined
   */
  simulationsIdDelete(id: number): __Observable<null> {
    return this.simulationsIdDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns a simulation schema.
   *
   * This operation allows the REST client to fetch a particular simulation using a **_simulation-id_**. Simulation-id is specified as a path parameter. The returned response is a **_simulation schema_** (json object)
   *   which contains the result of simulation if available. The result is comprised of  **_storage-consumed_** and **_performance stress_**.
   *   Storage consumed consists of baseline data and simulation predictions  for capacity.  Performance stress is comprised of cluster-stress and source-stress results.
   * @param id undefined
   * @return Success
   */
  simulationsIdGetResponse(id: number): __Observable<__StrictHttpResponse<Simulation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/simulations/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Simulation>;
      })
    );
  }
  /**
   * Returns a simulation schema.
   *
   * This operation allows the REST client to fetch a particular simulation using a **_simulation-id_**. Simulation-id is specified as a path parameter. The returned response is a **_simulation schema_** (json object)
   *   which contains the result of simulation if available. The result is comprised of  **_storage-consumed_** and **_performance stress_**.
   *   Storage consumed consists of baseline data and simulation predictions  for capacity.  Performance stress is comprised of cluster-stress and source-stress results.
   * @param id undefined
   * @return Success
   */
  simulationsIdGet(id: number): __Observable<Simulation> {
    return this.simulationsIdGetResponse(id).pipe(
      __map(_r => _r.body as Simulation)
    );
  }

  /**
   * Update an existing simulation using simulation-id.
   *
   * This method allows the client to make updates in an existing simulation. Client needs to specify the **_simulation-id_** as a path parameter to make changes in the desired simulation. **_simulation schema_** is supplied in the request body
   * @param params The `SimulatorService.SimulationsIdPutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `body`: Update the simulation events.
   */
  simulationsIdPutResponse(params: SimulatorService.SimulationsIdPutParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/simulations/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update an existing simulation using simulation-id.
   *
   * This method allows the client to make updates in an existing simulation. Client needs to specify the **_simulation-id_** as a path parameter to make changes in the desired simulation. **_simulation schema_** is supplied in the request body
   * @param params The `SimulatorService.SimulationsIdPutParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `body`: Update the simulation events.
   */
  simulationsIdPut(params: SimulatorService.SimulationsIdPutParams): __Observable<null> {
    return this.simulationsIdPutResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Trigger simulation run using simulation-id.
   *
   * After a simulation simulation is successfully saved, a user can run the particular simulation. REST client needs to specify the **_simulation-id_** as a path parameter.
   * @param id undefined
   */
  simulationsIdRunPostResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/simulations/${encodeURIComponent(id)}/run`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Trigger simulation run using simulation-id.
   *
   * After a simulation simulation is successfully saved, a user can run the particular simulation. REST client needs to specify the **_simulation-id_** as a path parameter.
   * @param id undefined
   */
  simulationsIdRunPost(id: number): __Observable<null> {
    return this.simulationsIdRunPostResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module SimulatorService {

  /**
   * Parameters for simulationsIdPut
   */
  export interface SimulationsIdPutParams {
    id: number;

    /**
     * Update the simulation events.
     */
    body: Simulation;
  }
}

export { SimulatorService }
