import { Injectable } from '@angular/core';
import { ProtectionSourceNode } from '@cohesity/api/v1';

import { BaseProtectionSourceService } from '../shared/base-protection-source.service';
import { UdaSourceDataNode } from './uda-source-data-node';

/**
 * Source tree service for UDA.
 */
@Injectable({
  providedIn: 'root',
})
export class UdaSourceTreeService extends BaseProtectionSourceService<UdaSourceDataNode> {

  constructor() {
    super();
  }

  /**
   * Transforms the node object from the api into a Protection Source Tree node to pass to the tree.
   *
   * @param   node   The original node.
   * @param   level  The level in the tree.
   * @return  A UdaSourceDataNode that can be displayed in the tree.
   */
  transformData(node: ProtectionSourceNode, level: number): UdaSourceDataNode {
    return new UdaSourceDataNode(node, level);
  }
}
