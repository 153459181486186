// Module: Cloud Edition Deployment Checklist Page

;(function(angular) {
  'use strict';

  angular.module('C.cloudEditionManager')
    .component('cloudDeploymentChecklist', {
      bindings: {
        /**
         * the type of cloudEdition deploy target
         *
         * @type   {String}
         */
        type: '<',
      },
      controller: 'cloudDeploymentChecklistCtrl',
      templateUrl:
        'app/platform/cloud-edition-manager/deployment-checklist.html',
    })
    .controller('cloudDeploymentChecklistCtrl', cloudDeploymentChecklistCtrlFn);

  /**
   * @ngdoc component
   * @name C.cloudEditionManager:awsDeploymentChecklist
   * @function
   *
   * @description
   * Show Deployment Checklist for CE deployment to AWS
   */
  function cloudDeploymentChecklistCtrlFn(_) {
    var $ctrl = this;

    $ctrl.steps = {
      aws: [
        'virtualPrivateCloudBraced',
        'subnet',
        'securityGroup',
        'clusterDomainName',
        'dnsServers',
        'ntpServers',
      ],
      azure: [
        'computeResourceGroup',
        'storageResourceGroup',
        'virtualNetwork',
        'subnet',
        'networkResourceGroup',
        'clusterDomainName',
        'ntpServers',
        'dnsServers',
      ],
      gcp: [
        'gcpProjectId',
        'virtualPrivateNetwork',
        'subnet',
        'vpcConnector',
        'clusterDomainName',
        'ntpServers',
        'dnsServers',
      ]
    };
  }

})(angular);