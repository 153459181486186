import { Component, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'coh-import-external-certificate-dialog',
  templateUrl: './import-external-certificate-dialog.component.html',
  styleUrls: ['./import-external-certificate-dialog.component.scss'],
})
export class ImportExternalCertificateDialogComponent {

  /**
   * Holds the reference to the stepper items.
   */
  @ViewChild(MatStepper) stepperRef: MatStepper;

  selectedFileContent: any;

  selectedFileName: string;

  certificateUsage: string[];
  importAsTrusted: boolean;

  passcode: string;

  isUploadFormValid = false;
  isSettingsFormValid = false;

  isUploadStep = true;

  get stepperWorkflow(): any {
    switch (this.stepperRef?.selectedIndex) {
      case 0: return 'beforeUpload';
      case 1: return 'afterUpload';
    }
  }

  /**
   * Constructor
   *
   * @param dialogRef
   */
  constructor(
    private dialogRef: MatDialogRef<ImportExternalCertificateDialogComponent>,
  ) {}

  /**
   * Set the data of upload import external certificate interface
   *
   * @param data
   */
  interfaceUploadDataFile(data: any) {
    this.passcode = data.passcode;
    this.selectedFileContent = data.uploadFile;
    this.selectedFileName = data.uploadFileName;

    if (this.selectedFileContent && this.passcode) {
      this.isUploadFormValid = true;
    } else {
      this.isUploadFormValid = false;
    }
  }

  /**
   * Set the data of settings import external certificate interface
   *
   * @param data
   */
  interfaceSettingsDataFile(data: any) {
    this.certificateUsage = data.certificateUsage;
    this.importAsTrusted = data.certificateUsage;

    if (this.certificateUsage) {
      this.isSettingsFormValid = true;
    } else {
      this.isSettingsFormValid = false;
    }
  }

  /**
   * Change step to upload
   */
  onBack() {
    this.stepperRef.previous();
    this.isUploadStep = true;
  }

  /**
   * Change step to settings
   */
  onContinue() {
    this.stepperRef.next();
    this.isUploadStep = false;
  }

  /**
   * Close dialog and send the selected data
   */
  onComplete() {
    this.dialogRef.close(
      [
        this.selectedFileContent,
        this.selectedFileName,
        this.passcode,
        this.certificateUsage,
        this.importAsTrusted
      ]
    );
  }
}
