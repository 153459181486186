import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ProtectionGroup, ProtectionGroups, ProtectionGroupServiceApi } from '@cohesity/api/v2';
import { finalize, take } from 'rxjs/operators';
import { AutoDestroyable } from '@cohesity/utils';

/**
 * @description
 * Component providing messaging regarding how many groups/jobs failed their latest run.
 *
 * @example
 *  <coh-run-failures-status></coh-run-failures-status>
 */
@Component({
  selector: 'coh-run-failures-status',
  templateUrl: './run-failures-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./run-failures-status.component.scss'],
})
export class RunFailuresStatusComponent extends AutoDestroyable implements OnInit {

  /**
   * Indicates if data is loading.
   */
  isLoading = true;

  /**
   * The run statistics.
   */
  failedGroups: ProtectionGroup[] = [];

  /**
   * Provides state params depending on whether there are failures or
   * not.
   */
  get stateParams() {
    return !this.failedGroups.length ? {} : {
      // The protection group state takes a single status and maps it to
      // all schedule params. Therefore, its only necessary to send this
      // param in order to see groups having any failing schedule.
      lastRunStatus: 'Failed',
    };
  }

  /**
   * Constructor.
   */
  constructor(
    private groupsService: ProtectionGroupServiceApi,
    private change: ChangeDetectorRef,
  ) {
    super();
  }

  /**
   * Initialize component and load stats data.
   */
  ngOnInit() {
    // TODO: type params as ProtectionGroupServiceApi.GetProtectionGroupsParams
    // when YAML is updated to include lastRunAnyStatus param.
    const params: any = {
      // Considering any type of failure for the count of group failures.
      lastRunAnyStatus: ['Failed'],
      isDeleted: false,

      // Must include the run info. This param gets pased to magneto and then
      // the API layer filters based on this run info so not including run info
      // results in the API being unable to filter by status.
      includeLastRunInfo: true,
      includeTenants: true,
    };

    this.groupsService.GetProtectionGroups(params)
      .pipe(
        take(1),
        finalize(() => this.isLoading = false)
      )
      .subscribe((resp: ProtectionGroups)  => {
        this.failedGroups = resp.protectionGroups || [];
        // Manually filter the response to double check, as proxying older
        // cluster APIs will result in all groups being returned regardless of
        // the params, as the params were added in 6.5.1.
        // If the returned results contain a failure in any of their schedules
        // then they should be counted as a failed group.
        this.failedGroups = (resp.protectionGroups || []).filter(
          group => group.lastRun?.localBackupInfo?.status === 'Failed' ||
            (group.lastRun?.archivalInfo?.archivalTargetResults || []).some(
              targetResult => targetResult.status === 'Failed') ||
            (group.lastRun?.cloudSpinInfo?.cloudSpinTargetResults || []).some(
              targetResult => targetResult.status === 'Failed') ||
            (group.lastRun?.replicationInfo?.replicationTargetResults || []).some(
              targetResult => targetResult.status === 'Failed')
        );
        this.change.markForCheck();
      });
  }
}
