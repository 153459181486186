import { InjectionToken, Provider, Type } from '@angular/core';

/**
 * Define a translate function type, which can be passed as an input to the reporting components.
 */
export type TranslateFn = (key: string, params?: any) => string;

/**
 * This interface must be implemented by any data renderer.
 */
export interface DataRenderer<ValueType, ParamsType, SecondaryType> {

  /**
   * The primary value for the renderer to show
   */
  value: ValueType;

  /**
   * An object with additional property that will determine how the value should
   * be rendered. If this is set, the renderer params should also be set and include
   * proprty names that can be read from here.
   */
  secondaryValue: SecondaryType;

  /**
   * The renderer config for the icon/title/subtitle being rendered
   */
  renderParams: ParamsType;

  /**
   * The translate function is not used by all renderers, but is included as
   * an input for any that need it.
   */
  translate: TranslateFn;
}

export type DataRendererProvider =
  Type<DataRenderer<any, any, any>> |
  {
    transform: (renderValues: DataRenderer<any, any, any>) => DataRenderer<any, any, any>;
    component: Type<DataRenderer<any, any, any>>;
  };

/**
 * A map of dataTypes to their renderer components.
 */
export interface DataRenderers {
  [dataType: string]: DataRendererProvider;
}


/**
 * Injection token used to look up available renderers for a data type
 */
export const DATA_RENDERERS = new InjectionToken<DataRenderers>('HelixDataRenderers');

/**
 * Provide function to simplify specifying renderer providers. Note that this can be used multiple times so that
 * components can provide custom table providers.
 *
 * @param renderers The renderer map
 * @returns The provider object
 */
export function dataRendererProvider(renderers: DataRenderers): Provider {
  return {
    provide: DATA_RENDERERS,
    useValue: renderers,
    multi: true,
  };
}
