<cog-table class="standard-width"
  [source]="browseResults"
  [selection]="selection"
  [persistSelection]="true"
  [canSelectRowFn]="canSelectObject"
  name="browse-search-results-table">
  <table mat-table>
    <ng-container matColumnDef="path">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'path' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <cog-icon class="name-icon" [shape]="row.type === 'Directory' ? 'helix:object-folder' : 'description!outline'"></cog-icon>
        <div class="name">
          <span class="user-data-xl" cogDataId="path-detail-{{row.name}}" [inheritDataId]="true">
            {{row.name}}
          </span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="size">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'size' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <span class="user-data">{{ row.SizeBytes | byteSize }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="lastModified">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'lastModified' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <span class="user-data">{{ row.lastModifiedTimeUsecs | date:'medium' }}</span>
      </td>
    </ng-container>
    <!-- NoData Footer -->
    <ng-container matColumnDef="noData">
      <td mat-footer-cell *matFooterCellDef>
        {{ 'noSearchResults' | translate }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay" (click)="toggleObjectSelection(row)"></tr>
    <tr class="nodata-row" mat-footer-row [class.hidden]="!noData" *matFooterRowDef="['noData']"></tr>
  </table>
</cog-table>
