<mat-divider></mat-divider>

<h4 class="margin-top-lg no-margin-bottom">{{'objectKeys' | translate}}</h4>
<div class="cog-caption" [innerHtml]="'views.objectKeyPattern.hint' | translate"></div>

<div class="flex-row-md margin-top">
  <!-- Object key type -->
  <ng-container *ngTemplateOutlet="selectTemplate;
    context: {
      id: 'select-object-key-pattern',
      controlName: 'keyType',
      options: objectKeyTypes
    }">
  </ng-container>

  <!-- Flat key type -->
  <ng-container *ngIf="form.formGroup.value.keyType === 'Flat'">
    <ng-container *ngTemplateOutlet="selectTemplate;
      context: {
        id: 'select-flat-key-type',
        controlName: 'flatType',
        options: flatKeyTypes
      }">
    </ng-container>
  </ng-container>

  <!-- Structured key length -->
  <ng-container *ngIf="form.formGroup.value.keyType === 'Flat' && form.formGroup.value.flatType ==='Structured'">
    <ng-container *ngTemplateOutlet="selectTemplate;
      context: {
        id: 'select-structured-key-length',
        controlName: 'keyLength',
        options: structuredKeyLengths
      }">
    </ng-container>
  </ng-container>
</div>

<mat-divider class="margin-bottom-lg"></mat-divider>

<!-- Template for the selector -->
<ng-template #selectTemplate
  let-id="id"
  let-controlName="controlName"
  let-options="options">
  <mat-form-field>
    <mat-label>{{ 'views.objectKeyPattern.label.' + controlName | translate }}</mat-label>

    <mat-select [cogDataId]="id" [formControl]="form.formGroup.controls[controlName]">
      <mat-select-trigger>
        {{ 'views.objectKeyPattern.type.' + form.formGroup.value[controlName] | translate }}
      </mat-select-trigger>
      <mat-option *ngFor="let key of options"
        [value]="key"
        [inheritDataId]="true"
        cogDataId="option-{{key}}">
        <div class="option-text">
          {{ 'views.objectKeyPattern.type.' + key | translate }}
          <div class="cog-chart">{{ 'views.objectKeyPattern.typeDescription.' + key | translate}}</div>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>
