<cog-settings-list-item [formGroup]="formGroup"
  [invalid]="formControl.invalid"
  [label]="'description' | translate"
  *ngIf="!protectionGroup?.id || !protectionGroup?.editingProtectedObject">
  <cog-settings-list-item-value-display>
    {{formControl.value || ('none' | translate)}}
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor [formGroup]="formGroup"
    cogDataId="settings-description">
    <mat-form-field>
      <input [formControlName]="name"
        [inheritDataId]="true"
        [placeholder]="'description' | translate"
        cogDataId="value"
        matInput>
    </mat-form-field>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
