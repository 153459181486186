import { ReportColumnConfigs } from '../common-report-items/custom-reports.service';
import { ReportSection } from '../iris-reporting.model';

/**
 * This is the layout config for the M365 Backup Storage Consumption report.
 */
export const m365StorageConsumptionReport: ReportSection[] = [
  {
    title: 'reporting.summary',
    items: [
      {
        id: 'statlistItem',
        componentIds: ['1901'],
        componentNames: ['M365BackupStorageUsageSummary'],
        params: {
          items: [
            {
              title: 'reporting.totalConsumption',
              translate: true,
              measurement: {
                valueKey: 'sumPeakFrontEndSizeBytes',
                dataType: 'bytes',
              }
            },
            {
              title: 'reporting.totalObjectsProtected',
              translate: true,
              measurement: {
                valueKey: 'countObjectUuid',
                dataType: 'number',
              },
            },
            {
              title: 'reporting.numberOfSources',
              translate: true,
              measurement: {
                valueKey: 'countDistinctSourceUuid',
                dataType: 'number',
              },
            },
          ],
        },
      },
    ],
  },
  {
    items: [
      {
        id: 'chartItem',
        componentIds: ['1903'],
        componentNames: ['ObjectCountByType'],
        title: 'reporting.numberOfObjectsByObjectType',
        params: {
          chartType: 'pie',
          measurements: [
            {
              valueKey: 'countObjectUuid',
              dataType: 'number',
            },
          ],
          dimensions: [
            {
              dimensionKey: 'objectType',
              maxValues: 6,
            },
          ],
        },
      },
      {
        id: 'chartItem',
        componentIds: ['1905'],
        componentNames: ['MonthlyUsageByObjectType'],
        title: 'reporting.peakUsagePerMonth',
        params: {
          chartType: 'stacked',
          measurements: [
            {
              valueKey: 'sumPeakFrontEndSizeBytes',
              dataType: 'bytes'
            },
          ],
          dimensions: [
            {
              dataType: 'date',
              dimensionKey: 'startTimeUsecs',
            },
            {
              dimensionKey: 'objectType',
            },
          ],
        },
      },
    ],
  },
  {
    items: [
      {
        id: 'chartItem',
        componentIds: ['1902'],
        componentNames: ['UsageByObjectType'],
        title: 'reporting.currentUsageByObjectType',
        params: {
          chartType: 'pie',
          measurements: [
            {
              valueKey: 'sumPeakFrontEndSizeBytes',
              dataType: 'bytes',
            },
          ],
          dimensions: [
            {
              dimensionKey: 'objectType',
              maxValues: 6,
            },
          ],
        },
      },
      {
        id: 'tableItem',
        componentIds: ['1904'],
        componentNames: ['TopConsumers'],
        title: 'reporting.top5Consumers',
        params: {
          noHeader: true,
          columns: [
            {
              title: '',
              translate: true,
              key: 'objectName',
              icon: {
                key: 'objectType',
                dataType: 'objectTypeIcon',
                renderParams: {
                  environmentKey: 'environment',
                },
              },
              primary: {
                key: 'objectName',
                dataType: 'text',
                renderParams: {
                  width: 'lg',
                },
              },
              subtitle: {
                key: 'environment',
                dataType: 'environmentName',
              },
            },
            {
              title: '',
              key: 'avgFrontEndSizeBytes',
              primary: {
                key: 'avgFrontEndSizeBytes',
                dataType: 'bytes',
              },
            },
          ],
        },
      },
    ],
  },
  {
    title: 'reporting.data',
    isFullData: true,
    items: [
      {
        id: 'tableItem',
        componentIds: ['1900'],
        componentNames: ['AllM365BackupStorageUsage'],
        params: {
          columns: [
            {
              title: 'objectName',
              translate: true,
              key: 'objectName',
              icon: {
                key: 'objectType',
                dataType: 'objectTypeIcon',
                renderParams: {
                  environmentKey: 'environment',
                },
              },
              primary: {
                key: 'objectName',
                dataType: 'text',
                renderParams: {
                  width: 'lg',
                },
              },
            },
            {
              title: 'source',
              translate: true,
              key: 'sourceName',
              primary: {
                key: 'sourceName',
                dataType: 'text',
                renderParams: {
                  width: 'lg',
                },
              },
            },
            {
              title: 'system',
              translate: 'true',
              key: 'system',
              primary: {
                key: 'system',
                dataType: 'text',
              },
            },
            {
              title: 'averageUsage',
              translate: true,
              hasSeparator: true,
              key: 'avgFrontEndSizeBytes',
              primary: {
                key: 'avgFrontEndSizeBytes',
                dataType: 'bytes',
              },
            },
            {
              title: 'peakUsage',
              translate: true,
              hasSeparator: true,
              key: 'peakFrontEndSizeBytes',
              primary: {
                key: 'peakFrontEndSizeBytes',
                dataType: 'bytes',
              },
            },
            {
              title: 'dailyChangeRate',
              translate: true,
              key: 'avgDailyChangeRateBytes',
              primary: {
                key: 'avgDailyChangeRateBytes',
                dataType: 'bytes',
              },
            },
          ],
        },
      },
    ],
  },
];

/**
 * This is metadata needed to determine available chart customizations.
 */
export const m365StorageConsumptionColumns: ReportColumnConfigs = {
  objectName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  objectUuid: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  environment: {
    aggs: ['count'],
    canGroup: true,
    maxGroupingValues: 3,
    dataType: 'text',
  },
  sourceName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  sourceUuid: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  system: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  systemId: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  avgDailyChangeRateBytes: {
    aggs: ['min', 'max', 'avg'],
    dataType: 'bytes',
  },
  peakFrontEndSizeBytes: {
    aggs: ['min', 'max', 'avg'],
    dataType: 'bytes',
  },
  avgFrontEndSizeBytes: {
    aggs: ['sum', 'min', 'max', 'avg'],
    dataType: 'bytes',
  },
};
