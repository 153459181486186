import { Component } from '@angular/core';
import { HostSettingsCheckResult } from '@cohesity/api/v1';
import { PopoverRef } from '@cohesity/helix';

@Component({
  selector: 'coh-health-checks-popover',
  templateUrl: './health-checks-popover.component.html',
  styleUrls: [ './health-checks-popover.component.scss' ],
})
export class HealthChecksPopoverComponent {
  checks: HostSettingsCheckResult[];

  constructor(readonly popoverRef: PopoverRef) {
    this.checks = popoverRef.data;
  }
}
