// Support Service

;(function (angular, undefined) {
  'use strict';

  angular.module('C').service('SupportService', SupportServiceFn);

  function SupportServiceFn($rootScope, $window, ClusterService, evalAJAX) {

    var supportDetails = '';
    var supportAJAX = {
      clusterStatsSuccess: false,
      clusterInfoSuccess: false
    };

    var checkSupportAJAX = function () {
      var text = $rootScope.text.servicesSupportService;
      if (supportAJAX.clusterStatsSuccess && supportAJAX.clusterInfoSuccess) {
        var mailToLink = "mailto:support@cohesity.com" + "?subject=" +
          escape(text.supportMessageSubject) +
          "&body=" + escape(supportDetails);

        // opening in same window, as attempting to open this via $window.open
        // triggers pop-up blockers due to delay from ajax lookups
        $window.location.href = mailToLink;
        supportAJAX = {
          clusterStatsSuccess: false,
          clusterInfoSuccess: false
        };
      }
    };

    return {
      createSupportTicket: createSupportTicket,
    };

    // Public Functions
    function createSupportTicket() {
      var text = $rootScope.text.servicesSupportService;
      var params = {
        fetchStats: true
      };

      supportDetails = text.supportMessageHeader;

      ClusterService.getClusterInfo(params).then(
        function getInfoSuccess(r) {
          supportAJAX.clusterInfoSuccess = true;
          supportDetails = supportDetails + JSON.stringify(r.data);
          checkSupportAJAX();
        },
        evalAJAX.errorMessage
      );

      ClusterService.getClusterStats().then(
        function getStatsSuccess(r) {
          supportAJAX.clusterStatsSuccess = true;
          supportDetails = supportDetails + JSON.stringify(r.data);
          checkSupportAJAX();
        },
        evalAJAX.errorMessage
      );
    }

  }

})(angular);
