<a class="run-failures"
  *ngIf="!isLoading"
  uiSref="protection-group.groups"
  [uiParams]="stateParams">
  <cog-icon iconSize="sm"
    [shape]="failedGroups.length ? 'helix:status-error!critical' : 'helix:status-success!success'">
  </cog-icon>
  <div class="run-failures-label"
    [class.run-failures-emphasis]="failedGroups.length"
    [ngSwitch]="failedGroups.length">
    <span *ngSwitchCase="0">{{ 'runFailuresStatus.noFailures' | translate }}</span>
    <span *ngSwitchCase="1">{{ 'runFailuresStatus.oneFailure' | translate }}</span>
    <span *ngSwitchDefault>{{ 'runFailuresStatus.nFailures' | translate : { n: failedGroups.length } }}</span>
  </div>
</a>
