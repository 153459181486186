import { Injectable } from '@angular/core';
import { Transition } from '@uirouter/core';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { GuardPriority, GuardResult, StateGuard } from 'src/app/core/state/state-guard';

const ngReportState = 'reporting.list';
const oldReportState = 'helios-reports.builtin';

/**
 * This guard listens for redirects to old helios report pages and forwards them to
 * the ngReport page.
 */
@Injectable({
  providedIn: 'root'
})
export class ReportingGuard implements StateGuard {
  /**
   * This guard priority is set to App, which indicates it should run as part of
   * the main application after all bootstrapping and initializing is completed.
   */
  guardPriority = GuardPriority.App;

  /**
   * Listen to all transitions to old report state.
   */
  matchCriteria: any = {
    to: state => oldReportState === state.name,
  };

  constructor(private irisCtx: IrisContextService) {}

  /**
   * Redirect to old report page to ngReport page.
   *
   * @param   transition  The ui router transition
   * @returns Redirect to ngReport page.
   */
  onStart(transition: Transition): GuardResult {
    const redirectEnabled = flagEnabled(this.irisCtx.irisContext, 'ngReportRedirect');
    const params = transition.params();
    const to = transition.to().name;

    if (to === oldReportState && redirectEnabled && !params.force) {
      return transition.router.stateService.target(ngReportState);
    }

    // Follow the default behavior.
    return true;
  }
}
