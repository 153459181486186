/**
 * The RestoreType maps the type of the restore task to private API compatible
 * integer constant.
 *
 * In case of public API call, the generated constant value of `type` in
 * projects/cohesity-api/src/generated/models/restore-task.ts
 * could have been used, but need to keep this mapping until we move away from
 * private APIs.
 */
export enum RestoreType {
  kRecoverVMs = 1,
  kCloneVMs = 2,
  kRestoreFiles = 3,
  kRecoverApp = 4,
  kCloneView = 5,
  kMountVolumes = 6,
  kCloneApp = 7,
  kRecoverSanVolume = 8,
  kConvertAndDeployVMs = 9,
  kMountFileVolume = 10,
  kSystem = 11,
  kRecoverVolumes = 12,
  kDeployVMs = 13,
  kDownloadFiles = 14,
  kRecoverEmails = 15,
  kRecoverDisks = 16,
  kCloneRefreshApp = 17,
  kCloneAppView = 18,
  kRecoverNamespaces = 19,
}
