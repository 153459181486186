import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ObjectServiceApi } from '@cohesity/api/v2';
import { AjaxHandlerService, ClearSubscriptions, Poller, PollingConfig } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs/esm';
import { BehaviorSubject } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import {
  RecoveryItemDetailsDialogInput,
  RecoveryListItem,
  RecoveryListItemRowAction,
  RecoveryListItemRowActionId,
  RecoveryTaskProgressInfo,
  RecoveryTaskStatus,
  RecoveryTaskStatusInfo,
  SelfServiceRecoveryType,
} from '../../models';
import { RecoveryItemDetailsDialogComponent } from '../recovery-item-details-dialog/recovery-item-details-dialog.component';

/**
 * Renders the self-serve dashboard for M365 users.
 *
 * @example
 * <coh-m365-self-service-dashboard>
 * </coh-m365-self-service-dashboard>
 */
@Component({
  selector: 'coh-m365-self-service-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent extends ClearSubscriptions implements OnInit {
  /**
   * Specifies the username for the self-Service user logged in for MSFT.
   */
  @Input() username: string;

  /**
   * Specifies whether the user info is being currently loaded.
   */
  @Input() loadingUserInfo$: BehaviorSubject<boolean>;

  /**
   * Emits when the user clicks on a recovery card.
   */
  @Output() recoveryCardClick = new EventEmitter<SelfServiceRecoveryType>();

  /**
   * The possible actions on a recovery row.
   */
  readonly recoveryRowActions: RecoveryListItemRowAction[] = [
    { id: RecoveryListItemRowActionId.showItems, displayName: 'showRecoveredItems' },
  ];

  /**
   * Indicates whether the data is loading.
   */
  readonly loading$ = new BehaviorSubject<boolean>(true);

  /**
   * The list of recoveries.
   */
  readonly recoveries$ = new BehaviorSubject<RecoveryListItem[]>([]);

  /**
   * Specifies the enum values for Self Service recovery types.
   */
  readonly selfServiceRecoveryType = SelfServiceRecoveryType;

  /**
   * Specifies the in-memory map to optimize lookups and async updates coming
   * from /progressMonitors API. Maps recovery ID to progress percentage.
   */
  recoveryIdToProgressMap = new Map<string, number>();

  /**
   * Specifies the in-memory map to optimize lookups and async updates coming
   * from /recoveries/:id API. Maps recovery ID to recovery task status.
   */
  recoveryIdToStatusMap = new Map<string, RecoveryTaskStatus>();

  /**
   * Specifies the poller for restore activity.
   */
  taskPoller: Poller<RecoveryListItem[]>;

  constructor(
    private ajaxHandler: AjaxHandlerService,
    private matDialog: MatDialog,
    private objectsApi: ObjectServiceApi,
    private translateService:  TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    const now = dayjs().endOf('day');
    const params: ObjectServiceApi.GetMcmObjectsActivityParams = {
      body: {
        activityTypes: ['Restore'],

        // TODO(tauseef): Check if there is a need to fetch activities over
        // 1 month.
        fromTimeUsecs: now.subtract(1, 'day').startOf('day').valueOf() * 1000,
        toTimeUsecs: now.valueOf() * 1000,
        environments: ['kO365'],
        excludeStats: true,
      },
    };

    const pollingConfig: PollingConfig<any> = {
      acceptFirstIteration: false,

      // Poll every 5 secs.
      interval: 5,
      maxRetries: 1,

      // This query is sent to iris_exec without Object IDs. Iris_exec ensures
      // that the corresponding ObjectIDs accessible to the current user is
      // added as parameter. This is to ensure users can only see activities
      // associated with their mailbox and onedrive.
      iterator: () => this.objectsApi.GetMcmObjectsActivity(params).pipe(
        finalize(() => this.loading$.next(false)),
        map(resp => (resp?.activity ?? []).reduce(
          (accumulator, activity) => {
            // Only add those activities which are not present in the current
            // in-memory map.
            if (!this.recoveryIdToProgressMap.has(activity?.recoveryParams?.id)) {
              const decoratedRecoveryItem = new RecoveryListItem(activity);

              // Init the in-memory maps with 0 progress percentage & status
              // received from the /activity API.
              this.recoveryIdToProgressMap.set(
                decoratedRecoveryItem.recoveryParams?.id, 0);
              this.recoveryIdToStatusMap.set(
                decoratedRecoveryItem.recoveryParams?.id, decoratedRecoveryItem.publicStatus);
              accumulator.push(decoratedRecoveryItem);
            }
            return accumulator;
          }, []))),
      shouldContinue: () => true,
    };

    this.taskPoller = new Poller(pollingConfig);
    this.subscriptions.push(
      this.taskPoller.poller.subscribe(
        tasks => {
          const allTasks = [...tasks, ...this.recoveries$.value];
          return this.recoveries$.next(allTasks);
        },
        err => this.ajaxHandler.errorMessage(err)
      )
    );
  }

  ngOnDestroy() {
    this.taskPoller.finish();
  }

  /**
   * Callback for the "click" event on an action on a recovery list item.
   *
   * @param action The action which was taken.
   * @param item The recovery list item.
   */
  onRowItemActionClick(action: RecoveryListItemRowAction, item: RecoveryListItem): void {
    if (action.id === RecoveryListItemRowActionId.showItems) {
      this.openRecoveryItemDetailsDialog(item);
      return;
    }

    throw new Error(`Unsupported action ${action.id} on item ${item.name}`);
  }

  /**
   * Fetches the current value of progress percentage within in-memory map
   * 'recoveryIdToProgressMap' for the given recoveryId.
   *
   * @param recoveryId Specifies the recovery task ID.
   * @returns percentage completion of task
   */
  getTaskProgress(recoveryId): number {
    return this.recoveryIdToProgressMap.get(recoveryId) || 0;
  }

  /**
   * Fetches the current value of recovery status within in-memory map
   * 'recoveryIdToStatusMap' for the given recoveryId.
   *
   * @param recoveryId Specifies the recovery task ID.
   * @returns recovery task status
   */
  getTaskStatus(recoveryId): RecoveryTaskStatus {
    return this.recoveryIdToStatusMap.get(recoveryId);
  }

  /**
   * Updates the in-memory map recoveryIdToProgressMap with the percentage
   * completion against the recovery ID specified within 'progressInfo'.
   *
   * @param progressInfo Specifies the recovery task progress info.
   */
  handleTaskProgressUpdate(progressInfo: RecoveryTaskProgressInfo) {
    if (!progressInfo || !progressInfo.recoveryId || !progressInfo.percentageCompleted) {
      return;
    }
    this.recoveryIdToProgressMap.set(
      progressInfo.recoveryId,
      Math.round(progressInfo.percentageCompleted));
  }

  /**
   * Updates the in-memory map recoveryIdToStatusMap with the recovery task
   * status against the recovery ID specified within 'taskInfo'.
   *
   * @param info Specifies the recovery task info
   */
  handleTaskStatusUpdate(taskInfo: RecoveryTaskStatusInfo) {
    if (!taskInfo || !taskInfo.recoveryId || !taskInfo.status) {
      return;
    }
    this.recoveryIdToStatusMap.set(taskInfo.recoveryId, taskInfo.status);
  }

  /**
   * Determines the string of the form <restore_task_status><percentage_completion>.
   *
   * @param recoveryId Specifies the recovery task ID.
   * @returns User facing string containing both status and percentage completion
   */
  getTaskStatusWithProgress(recoveryId): string {
    const taskStatus = this.getTaskStatus(recoveryId);
    if (taskStatus === RecoveryTaskStatus.InProgress) {
      return this.translateService.instant(taskStatus) + ' ' +
        String(this.getTaskProgress(recoveryId)) + '%';
    }
    return this.translateService.instant(taskStatus);
  }


  /**
   * Opens the dialog to show a recovery list item details.
   *
   * @param item The recovery list item.
   */
  openRecoveryItemDetailsDialog(item: RecoveryListItem): void {
    const data: RecoveryItemDetailsDialogInput = {
      recoveryId: item.recoveryParams.id,
      regionId: item.regionId,
      clusterId: item.clusterId
    };

    this.subscriptions.push(
      this.matDialog.open(RecoveryItemDetailsDialogComponent, { data }).afterClosed().subscribe()
    );
  }

  /**
   * Determines the attribute on which to run the trackBy function for the
   * ngFor loop for the recovery listing.
   *
   * @param idx Specifies the index of the recovery in the Array for ngFor
   * @param recovery Specifies the recovery object
   * @returns ID of the recovery.
   */
  recoveryTrackByFn(idx: number, recovery: RecoveryListItem): string {
    return recovery?.recoveryParams?.id;
  }
}
