// MODULE: Network Parent Page
import { getConfigByKey } from '@cohesity/iris-core';

;(function _iife(angular, undefined) {
  'use strict';

  angular.module('C.networking', ['C.vipsSummary', 'C.vips', 'C.hostMapping'])
    .config(NetworkConfigFn)
    .controller('networkController', NetworkControllerFn);

  function NetworkConfigFn($stateProvider) {
    var accessPrivilege = 'VLAN_VIEW && !$root.isTenantUser() && !$root.isHeliosTenantUser()';
    $stateProvider
      .state('networking', {
        url: '^/platform/networking',
        help: 'admin_cluster_vlans',
        templateUrl: 'app/platform/network/network.html',
        controller: 'networkController as $ctrl',
        redirectTo: 'networking.summary',
        canAccess: accessPrivilege,
      })
      .state('networking.summary', {
        url: '^/platform/networking',
        help: 'admin_cluster_vlans',
        canAccess: accessPrivilege,
        templateUrl: 'app/platform/network/summary.html',
        controller: 'vipsSummaryCtrl as $ctrl',
        parentState: 'networking',
      })
      .state('networking.vips', {
        url: '^/platform/networking/vips',
        help: 'admin_cluster_partition_new',
        // Not providing VIP listing view for tenant in MVP.
        canAccess: accessPrivilege,
        parentState: 'networking',
        component: 'vipList',
      })
      .state('networking.vlans', {
        url: '^/platform/vlans',
        help: 'admin_cluster_vlans',
        // Not providing VLAN listing view for tenant in MVP.
        canAccess: accessPrivilege,
        parentState: 'networking',
        templateUrl: 'app/platform/vlans/vlans.html',
      })
      .state('networking.hostMapping', {
        url: '^/platform/hostmapping',
        help: 'admin_cluster_partitions',
        canAccess: accessPrivilege,
        parentState: 'networking',
        templateUrl: 'app/platform/host-mapping/host-mapping.html',
        controller: 'hostMappingCtrl as $ctrl',
      });
  }

  /**
   * (controller) main Networking logic
   *
   * @method  NetworkControllerFn
   * @param   {object}  _   Lodash utility library
   */
  function NetworkControllerFn(_,$rootScope, FEATURE_FLAGS, ClusterService, NgNetworkingService, NgIrisContextService) {
    var $ctrl = this;
    var tabs = [
      {
        headingKey: 'summary',
        route: 'networking.summary',
      }];

    // Add combined vips/vlans tab if feature flag is enabled.
    if (FEATURE_FLAGS.ngVipsVlanEnabled) {
      tabs.push({
        headingKey: 'VIPs/VLANs',
        route: 'networking.vipsVlans',
      });
    } else {
      tabs.push({
        headingKey: 'VLANs',
        route: 'networking.vlans',
        },
        {
          headingKey: 'VIPs',
          route: 'networking.vips',
      });
    }
    if (getConfigByKey(NgIrisContextService.irisContext, 'networking.hostMapping', true)) {
      tabs.push({
        headingKey: 'hostMapping',
        route: 'networking.hostMapping',
      });
    }
    tabs.push({
      headingKey: 'firewall',
      route: 'networking.firewall',
    });

    if (FEATURE_FLAGS.ipmiSettingsEnabled && ClusterService.clusterInfo._isPhysicalInstall) {
      tabs.push({
        headingKey: 'ipmi',
        route: 'networking.ipmi',
      });
    }
    if (FEATURE_FLAGS.staticRoutesEnabled && getConfigByKey(NgIrisContextService.irisContext, 'networking.routes', true)) {
      tabs.push({
        headingKey: 'routes',
        route: 'networking.routes',
      });
    }
    _.assign($ctrl, {
      // variables
      tabs: tabs,
      openResetNetworkingDialog: openResetNetworkingDialog,
      networkResetAllowed: getConfigByKey(NgIrisContextService.irisContext, 'networking.networkReset', true),
      userModifyAccess: $rootScope.user.privs.CLUSTER_MODIFY,
    });

    /**
     * open reset networking dialog
     *
     * @method  openResetNetworkingDialog
     */
    function openResetNetworkingDialog() {
      NgNetworkingService.openNetworkResetActionDialog();
    }

  }
})(angular);
