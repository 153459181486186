// COMPONENT: Create Recover Button

;(function(angular, undefined) {

  angular.module('C.createRecoverButton',
    ['C.contextMenu', 'C.restoreService', 'C.vmdkRecovery'])
    .component('createRecoverButton', {
      templateUrl: 'app/global/c-recover-button/create-recover-button.html',
      controller: createRecoverButtonFn,
      bindings: {
        // button label
        label: '<?',
      },
    });

  function createRecoverButtonFn($attrs, NavStateService, SHARED_CLASSNAME) {

    var $ctrl = this;

    $ctrl.$onInit = function onInit() {
      $ctrl.label = $ctrl.label || 'recover';

      // add a common class on this component which is helpful to add
      // separator margin between subsequest similar/same components
      $attrs.$addClass(SHARED_CLASSNAME.contextBtn);

      $ctrl.recoverTypeItems = NavStateService.getRecoverStates();
    };

  }
})(angular);
