import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DataTreeNodeContext } from '../data-tree-detail.directive';
import { DataTreeControl } from '../shared/data-tree-control';

/**
 * This component renders the expand collapse button for a data-tree-table along with
 * appropriate padding based on the node's level.
 */
@Component({
  selector: 'cog-data-tree-table-row-expand',
  templateUrl: './data-tree-table-row-expand.component.html',
  styleUrls: ['./data-tree-table-row-expand.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTreeTableRowExpandComponent {
  /**
   * The node context.
   */
  @Input() context: DataTreeNodeContext<any>;

  /**
   * Gets the node from the context.
   */
  get node(): any {
    return this.context.node;
  }

  /**
   * Get's the tree control from the context.
   */
  get treeControl(): DataTreeControl<any> {
    return this.context.treeControl;
  }

  /**
   * Returns an array of the length of the current level which can be used to add spacing
   * to the tree row.
   */
  get paddingArray(): any[] {
    return Array(this.node ? this.node.level : 0);
  }

  /**
   * Expand or collapse a node when the button is clicked.
   */
  toggleRowExand() {
    this.treeControl.toggle(this.node);
  }
}
