import { AggregatedSubtreeInfo, ProtectionSource, ProtectionSourceNode } from '@cohesity/api/v1';
import { DataTreeSelection } from '@cohesity/helix';
import { SourceTreeNode } from '@cohesity/iris-source-tree';

import { Environment, ObjectTypeToIconMap, OsVariantIconMap, SourceKeys } from '../../../constants';

/**
 * A base data node class for protection sources. This should be extended for each indiviual environment type.
 */
export class ProtectionSourceDataNode<EnvType = any, WorkloadType = any>
  implements SourceTreeNode<ProtectionSourceNode> {
  /**
   * The display name of the current node.
   */
  get name(): string {
    return this.data.protectionSource.name;
  }

  /**
   * Whether the node can be expanded. This uses the length of nodes by default. In order to
   * show applicationNodes as children, this would need to be overridden.
   */
  get expandable(): boolean {
    const children = this.children;
    return Array.isArray(children) && children.length > 0;
  }

  /**
   * The node's protection source information. This is typed as any in the api, but casted here
   * to the correct thype.
   */
  get protectionSource(): ProtectionSource {
    return this.data.protectionSource;
  }

  /**
   * The node's environment specific source information. This is based on the protection source's
   * environment, not the tree's environmanet. Kept as any because it depends on the environment.
   */
  get envSource(): EnvType {
    const sourceKey = SourceKeys[this.protectionSource.environment];
    return this.protectionSource[sourceKey];
  }

  /**
   * The source's id. This is usually a number, but is sometimes presented as a string when combining
   * tag ids.
   */
  get id(): number | string {
    return this.protectionSource.id;
  }

  /**
   * Convert the id property to an array of ids. Multiple ids are currently only used by tags.
   */
  get ids(): string[] {
    if (typeof(this.id) === 'string') {
      return this.id.split('_');
    } else {
      return [String(this.id)];
    }
  }

  /**
   * Similar to isGloballyExcluded, this allows a node to be ignored when a parent is auto protected, however, the
   * reason for exclusion is because the node is already selected rather than because it is excluded everywhere
   */
  get ineligibleForAutoSelect(): boolean {
    return false;
  }

  /**
   * Checks whether the node uses a persistent agent.
   */
  get isAgentInstalled(): boolean {
    return !!(this.envSource as any)?.hasPersistentAgent;
  }

  /**
   * Whether the node is an application host of any kind.
   */
  get isApplicationHost(): boolean {
    return !!(this.data.registrationInfo && this.data.registrationInfo.environments);
  }

  /**
   * Whether a node could be selected in the tree. If this is false, the checkbox will be hidden
   * in the tree. This is different from canSelect, which can be used to disable a checkbox.
   */
  get isSelectable(): boolean {
    return true;
  }

  /**
   * Whether the current node is a tag or not. This is determined by the node type.
   */
  get isTag(): boolean {
    return this.type === 'kTag';
  }

  /**
   * Whether the current node is a Pure Protection Group.
   */
  get isPureProtectionGroup(): boolean {
    return this.type === 'kPureProtectionGroup';
  }

  /**
   * Whether the current node is a IBM FlashSystem Protection Group.
   */
  get isIbmFlashSystemProtectionGroup(): boolean {
    return this.type === 'kVolumeGroup';
  }

  /**
   * The type of the object, based on its environment.
   */
  get type(): string {
    // This is cast to any because there is no good way to get this from the EnvType param.
    // However, all of them contain a type property, so this is safe.
    return (this.envSource as any)?.type;
  }

  /**
   * The type of the object, based on its environment.
   */
  get hostType(): string {
    // This is cast to any because there is no good way to get this from the EnvType param.
    // All protection sources that include host type use this property name.
    return (this.envSource as any).hostType;
  }

  /**
   * Gets the protection summary for the tree's environment.
   * Note that this uses the object's environment rather than the protection source's so that
   * information will match the tree's environment.
   */
  get protectedSummary(): AggregatedSubtreeInfo {
    return (this.data.protectedSourcesSummary || []).find(sum => sum.environment === this.environment) || {};
  }

  /**
   * Gets the on protected objects summary for the tree's environment.
   * Note that this uses the object's environment rather than the protection source's so that
   * information will match the tree's environment.
   */
  get unprotectedSummary(): AggregatedSubtreeInfo {
    return (this.data.unprotectedSourcesSummary || []).find(sum => sum.environment === this.environment) || {};
  }

  /**
   * Gets a count of protected objects for this environment.
   */
  get protectedCount(): number {
    return this.protectedSummary.leavesCount || 0;
  }

  /**
   * Gets a count of unprotected objects for this environment.
   */
  get unprotectedCount(): number {
    return this.unprotectedSummary.leavesCount || 0;
  }

  /**
   * True if all objects are protected or the object has an object protection spec applied to it.
   */
  get protected(): boolean {
    return (!!this.protectedCount && !this.unprotectedCount) || this.isObjectProtected;
  }

  /**
   * True if some objects are protected and some are not.
   */
  get partialProtected(): boolean {
    return !!this.protectedCount && !!this.unprotectedCount;
  }

  /**
   * Gets the logical size of all leaf children by summing the protected and unprotected
   * logical size.
   */
  get logicalSize(): number {
    return (this.protectedSummary.totalLogicalSize || 0) + (this.unprotectedSummary.totalLogicalSize || 0);
  }

  /**
   * Gets the total leaf count for the node, based on the protection summary
   * objects.
   */
  get leafCount(): number {
    return this.protectedCount + this.unprotectedCount;
  }

  /**
   * Gets the list of any children for this node.
   */
  get children(): ProtectionSourceNode[] {
    return this.data.applicationNodes || this.data.nodes;
  }

  /**
   * Get the ids of this node's direct children.
   */
  get childIds(): number[] {
    if (this.expandable) {
      return this.children?.map(child => child.protectionSource.id);
    }
    return [];
  }

  /**
   * Returns parent host name of the selected oracle database.
   *
   * @return parent host name.
   */
  get parentHostName(): string {
    return null;
  }

  /**
   * Store the explicitly set icon for this node
   */
  protected _icon: string;

  /**
   * Get the helix icon name to use for rendering the icon next to the tree node.
   * The node only attempts to render the helix icon if this getter returns a non
   * null value. Otherwise it falls back to using the old icons, whose names are
   * computed using the sourceIcon pipe.
   *
   * If different naming convention is required for the icons than what this getter
   * method provides, it can be overridden in the adapter.
   */
  get icon(): string {
    if (this._icon) {
      return this._icon;
    }
    return this.objectIcon;
  }

  /**
   * Use this to explicitly set an icon for the node.
   * If the _icon property is set using this, the getter above will return this
   * instead of calculating the icon string from IconMap
   *
   * @param icon The icon string to be set
   */
  set icon(icon: string) {
    this._icon = icon;
  }

  /**
   * Method called to determine & return object icon based on source node info.
   */
  get objectIcon(): string {
    const envIconMap = ObjectTypeToIconMap[this.protectionSource.environment];
    let iconName =  envIconMap && envIconMap[this.type];

    if (!iconName) {
      return null;
    }
    if (OsVariantIconMap[iconName] && this.hostType) {
      iconName =  OsVariantIconMap[iconName][this.hostType] || iconName;
    }

    // Object nodes which are auto protected should show the auto protected icon too
    const suffix = (this.isAutoProtectedByObject || (!this.isLeaf && this.isObjectProtected)) ? '-auto' :
      this.protected ? '-protected' :
      this.partialProtected ? '-partial' : '';

    return iconName.concat(suffix);
  }

  /**
   * Returns true if this object has an object protection spec applied by a parent.
   */
  get isAutoProtectedByObject(): boolean {
    return this.isObjectProtected && !!this.data.objectProtectionInfo?.autoProtectParentId;
  }

  /**
   * For object protection only, gets the id of the protected object autoprotecting this one.
   */
  get parentAutoProtectedObjectId(): number {
    return this.data.objectProtectionInfo?.autoProtectParentId;
  }

  /**
   * Default value for isLeaf is false.
   */
  get isLeaf(): boolean {
    return false;
  }

  /**
   * Returns true if thiss object has an object protection spec applied to it, either directly on the node or on a
   * parent.
   */
  get isObjectProtected(): boolean {
    return !!this.data.objectProtectionInfo?.hasActiveObjectProtectionSpec;
  }

  /**
   * Get a list of group owners if there are any
   */
  get groupOwners(): string[] {
    return (this.data.entityPermissionInfo?.groups || []).map(group => group.groupName);
  }

  /**
   * Get the Tenant owner if there is one
   */
  get tenantOwner(): string {
    return this.data.entityPermissionInfo?.tenant?.name;
  }

  /**
   * Get a list of user owners if there are any
   */
  get userOwners(): string[] {
    return (this.data.entityPermissionInfo?.users || []).map(user => user.userName);
  }

  /**
   * Get the level of the node in auto protected view.
   * By default, keep the list flat in auto protected view.
   */
  get levelInAutoProtectedView(): number {
    return !this.isLeaf ? 0 : 1;
  }

  /**
   * Specifies whether the node supports viewing the last run status within
   * the source tree.
   */
  get shouldShowLastRunStatus(): boolean {
    return this.isLeaf;
  }

  /**
   * Specifies whether the node supports viewing the last run SLA within the
   * source tree.
   */
  get shouldShowLastRunSla(): boolean {
    return this.isLeaf;
  }

  /**
   * Specifies the workload type for the current node.
   */
  workloadType: WorkloadType;

  constructor(
    public environment: Environment,
    public data: ProtectionSourceNode,
    public level: number,
    readonly tagIds: number[] = [],
    private exclusiveProtection: boolean = false
  ) {
  }

  /**
   * Used as a callback function to determine if a node can be selected.
   *
   * @param   currentSelection   The tree's current selection state can sometimes determine what
   *                             items can be selected.
   * @param   isSingleSelect     Does the data tree have singleSelection enabled
   * @return  True if the ndoe can be selected.
   */
  canSelect(_currentSelection: DataTreeSelection<any>, _isSingleSelect?: boolean): boolean {
    // A tag should only be selectable if it has children.
    return true && !(this.isTag && !this.expandable);
  }

  /**
   * Used as a callback function to determine if a node can be auto protected.
   *
   * @param   currentSelection   The tree's current selection state can sometimes determine what
   *                             items can be auto protected.
   * @return  True if the ndoe can be auto protected.
   */
  canAutoSelect(_currentSelection: DataTreeSelection<any>): boolean {
    // Default is that any non-leaf entity or tag can be auto selected.
    return !this.isLeaf || this.isTag;
  }

  /**
   * Used as a callback function to determine if a node can be excluded from auto protection.
   *
   * @param   currentSelection   The tree's current selection state can sometimes determine what
   *                             items can be excluded from auto protection.
   * @return  True if the ndoe can be excluded from auto protection.
   */
  canExclude(_currentSelection: DataTreeSelection<any>): boolean {
    // Tags can always be exlcuded.
    return true || this.isTag;
  }

  /**
   * Determines if a node is globally excluded from protection.
   */
  get isGloballyExcluded(): boolean {
    return false;
  }

  /**
   * Used as a callback function to return adapter specific checkbox tooltip
   * text. This should be overwritten in classes extending this if adapters need
   * custom text. Look at getCheckboxTooltip() function in job-source-tree
   * component for more details.
   *
   * Example return: {
   *    translateKey: 'sourceTree.tooltip.autoProtectAncestorEnabled',
   *    translateParams: [
   *      { paramKey: 'sourceType', paramValue: 'vCenter' },
   *      ...
   *    ],
   * }
   *
   * @return  It returns undefined by default.
   */
  getCheckBoxToolTip(): TooltipContext {
    return;
  }

  /**
   * Can provide more info text which would be populated inside a tooltip
   */
  get moreInfoText(): string | null {
    return null;
  }
}

/**
 * interface object for translation param which has name of param and value of the param
 */

export interface TooltipTranslationParam {
  /**
   * Name for translation param used in translate service. Example: sourceType
   */
  paramName: string;

  /**
   * Value for translation param used in translate service. It should be translation
   * key as it will be translated in job-source-tree component.
   * Example: sourceTree.tooltip.autoProtectAncestorEnabled
   */
  paramValue: string;
}

/**
 * interface object used to translate custom tooltip string returned by adapters
 */
export interface TooltipContext {

  /**
   * translation key to be used to translate text
   */
  translateKey: string;

  /**
   * Optional List of translation params object.
   */
  translateParams?: TooltipTranslationParam[];
}
