<ng-container *ngFor="let measurement of formGroupControls.measurements.controls">
  <mat-form-field>
    <mat-label>{{translate('measurement')}}</mat-label>
    <mat-select [value]="measurement.value.label"
      (selectionChange)="updateControl(measurement, availableMeasurements, $event.value)">
      <mat-option *ngFor="let item of availableMeasurements" [value]="item.label">
        {{ translate('reporting.dataType.' + item.label) }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>
<mat-form-field>
  <mat-label>{{translate('xAxis')}}</mat-label>
  <mat-select [value]="formGroupValues.xAxis?.label"
    (selectionChange)="updateControl(formGroupControls.xAxis, availableAxisDimensions, $event.value)">
    <mat-option *ngFor="let item of availableAxisDimensions" [value]="item.label">
      {{ translate('reporting.dataType.' + item.label) }}
    </mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field>
  <mat-label>{{translate('groupBy')}}</mat-label>
  <mat-select [value]="formGroupValues.groupBy?.label"
    (selectionChange)="updateControl(formGroupControls.groupBy, availableGroupDimensions, $event.value)">
    <mat-option>{{translate('none')}}</mat-option>
    <mat-option *ngFor="let item of availableGroupDimensions" [value]="item.label">{{ translate('reporting.dataType.' + item.label) }}</mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field>
  <mat-label>{{translate('chartType')}}</mat-label>
  <mat-select [formControl]="formGroupControls.chartType">
    <mat-option *ngFor="let item of availableCharts" [value]="item.chartType">
      {{ translate(item.label) }}
    </mat-option>
  </mat-select>
</mat-form-field>
