import { Component, HostBinding, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NetworkInformationServiceServiceApi, NisNetgroup, NisProvider, NisProviders } from '@cohesity/api/v2';
import { AjaxHandlerService } from '@cohesity/utils';
import { Controls, NgxSubFormComponent } from 'ngx-sub-form';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { NISService } from 'src/app/modules/access-management/on-prem-access-management/nis/nis.service';

import { ModifyNetgroupWhitelistFormModel } from './modify-netgroup-whitelist-form.model';

/**
 * Modify netgroup whitelist dialog parameters
 */
export interface ModifyNetgroupWhitelistDialogParams {
  /**
   * Whether is edit mode or not.
   */
  isEditMode: boolean;

  /**
   * The current netgroup whitelist to be modified.
   */
  netgroupWhitelist?: NisNetgroup;

  /**
   * Whether modify the global netgroup whitelist or not.
   */
  modifyGlobalNetgroup?: boolean;
}

/**
 * The dialog component used to create or edit netgroup whitelist.
 */
@Component({
  selector: 'coh-modify-netgroup-whitelist-dialog',
  templateUrl: './modify-netgroup-whitelist-dialog.component.html',
  styleUrls: ['./modify-netgroup-whitelist-dialog.component.scss'],
})
export class ModifyNetgroupWhitelistDialogComponent extends NgxSubFormComponent<ModifyNetgroupWhitelistFormModel>
  implements OnInit {

  /**
   * Declare hidden class for the current dialog. Default to false.
   */
  @HostBinding('class.hidden') dialogHidden = false;

  /**
   * Whether the form is being submiting or not.
   */
  submiting = false;

  /**
   * Whether it's editing mode or creating mode.
   */
  isEditMode = false;

  /**
   * List of async keystone data.
   */
  allNisProviders$: Observable<NisProvider[]>;

  /**
   * Get the form controls needed for the sub form.
   *
   * @returns   The form controls object.
   */
  getFormControls(): Controls<ModifyNetgroupWhitelistFormModel> {
    return {
      name: new UntypedFormControl(null, Validators.required),
      domain: new UntypedFormControl(null, Validators.required),
      nfsAccess: new UntypedFormControl('kReadWrite'),
      nfsSquash: new UntypedFormControl('kNone'),
    };
  }

  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private nisApiService: NetworkInformationServiceServiceApi,
    public dialogRef: MatDialogRef<ModifyNetgroupWhitelistDialogComponent>,
    private nisService: NISService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogParams: ModifyNetgroupWhitelistDialogParams
  ) {
    super();
  }

  ngOnInit() {
    this.isEditMode = this.dialogParams.isEditMode;
    if (this.isEditMode) {
      this.initForm();
    }

    this.getNisList();
  }

  /**
   * Init form with current keystone value when it's under edit mode.
   */
  initForm() {
    const { name, domain, nfsAccess, nfsSquash } = this.dialogParams.netgroupWhitelist;

    const currentNetgroupWhitelist: ModifyNetgroupWhitelistFormModel = {
      name: name,
      domain: domain,
      nfsAccess: nfsAccess || null,
      nfsSquash: nfsSquash || null,
    };

    this.writeValue(currentNetgroupWhitelist);
    this.formGroupControls.name.disable();
  }

  /**
   * Call api to get all nis providers.
   */
  getNisList() {
    this.allNisProviders$ = this.nisService.getNisProviderList()
      .pipe(
        catchError(e => {
          this.ajaxHandlerService.handler(e);
          return of([]);
        }),
        map((data: NisProviders) => data.nisProviders || []),
      );
  }

  /**
   * Add new netgroup whitelist or edit existing netgroup whitelist.
   *
   * @param   isEditMode   Whether it's editing mode or creating mode.
   */
  modifyNetgroupWhitelist(isEditMode: boolean) {
    this.submiting = true;

    const { name, domain, nfsAccess, nfsSquash } = this.formGroupValues;
    const currentNetGroup: NisNetgroup = {
      name: name,
      domain: domain,
      nfsAccess: nfsAccess,
      nfsSquash: nfsSquash,
    };
    let apiParam: NetworkInformationServiceServiceApi.UpdateNisNetgroupByNameParams |
      NetworkInformationServiceServiceApi.CreateNisNetgroupParams;

    // If don't want to modify global netgroup whitelist, then just close the dialog with updated netgroup object.
    if (!this.dialogParams.modifyGlobalNetgroup) {
      this.dialogRef.close(currentNetGroup);
      return;
    }

    if (isEditMode) {
      // edit mode
      apiParam = {
        name: this.dialogParams.netgroupWhitelist.name,
        body: currentNetGroup,
      };
    } else {
      // create mode
      apiParam = {
        body: currentNetGroup,
      };
    }

    const apiRequest$ = isEditMode ?
      this.nisApiService.UpdateNisNetgroupByName(
        apiParam as NetworkInformationServiceServiceApi.UpdateNisNetgroupByNameParams) :
      this.nisApiService.CreateNisNetgroup(apiParam as NetworkInformationServiceServiceApi.CreateNisNetgroupParams);

    // make the API call.
    apiRequest$
      .pipe(finalize(() => this.submiting = false))
      .subscribe(
        (netgroup: NisNetgroup) => {
          this.dialogRef.close(netgroup);
        },
        error => this.ajaxHandlerService.handler(error)
      );
  }

  /**
   * Helper function to hide or show the current dialog.
   */
  toggleDialogVisibility(isChildDialogOpen: boolean) {
    // when child dialog is open, hide the current dialog.
    // when child dialog is closed, show the current dialog.
    this.dialogHidden = isChildDialogOpen;
  }
}
