import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { TranslateFn } from '../../data-renderers/data-renderer.model';
import { ColumnConfig } from '../column-config.model';

@Component({
  selector: 'cog-table-settings-menu',
  templateUrl: './table-settings-menu.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class TableSettingsMenuComponent {
  /**
   * Translate function.
   */
  @Input() translate: TranslateFn;

  /**
   * The initial columns input.
   */
  @Input() columns: ColumnConfig[];

  /**
   * Indicates wheather the menu is disabled or not.
   */
  @Input() disabled = false;

  /**
   * Enable column reordering.
   */
  @Input() enableReordering = false;

  /**
   * Fires whenever the user edits the columns.
   */
  @Output() columnsChange = new EventEmitter<ColumnConfig[]>();
}
