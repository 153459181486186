<div class="statlist-container"
  *ngIf="!stillLegend"
  [ngClass]="{
    'active': isChartActive,
    'hidden': !showStillStatListValue
  }">
  <cog-statlist [ngClass]="colorSetClass">
    <cog-statlist-item
      *ngFor="let seriesInfo of chartSeriesInfo"
      [label]="((!isChartActive && seriesInfo?.stillLabel) || seriesInfo?.name) | translate"
      [labelLegend]="seriesInfo?.labelLegend">
      <span [innerHTML]="(!isChartActive && seriesInfo?.stillValue) || currentStatList[seriesInfo?.name]"></span>
    </cog-statlist-item>
  </cog-statlist>
</div>

<div class="still-statlist-container"
  *ngIf="stillLegend"
  [ngClass]="{
    'hidden': !showStillStatListValue
  }">
  <h6 class="no-margin-bottom">{{lastPointTime}}</h6>
  <cog-statlist [ngClass]="colorSetClass">
    <cog-statlist-item
      *ngFor="let seriesInfo of chartSeriesInfo"
      [label]="(seriesInfo.stillLabel || seriesInfo.name) | translate"
      [labelLegend]="seriesInfo.labelLegend">
     <span [innerHTML]="lastStatList[seriesInfo.name]"></span>
    </cog-statlist-item>
  </cog-statlist>
</div>

<div class="chart-container">
  <cog-line-chart #lineChart
    [cohChartGradient]="name"
    [chartOptions]="chartConfig"
    [seriesData]="chartSeriesData"
    (hoverPositionChange)="onHoveringChart($event)"
    [colorSetClass]="colorSetClass"
    [height]="chartHeight">
  </cog-line-chart>
  <div *ngIf="showStillStatListValue"
    aria-live="assertive"
    aria-relevant="all"
    [innerHTML]="tooltipContent"
    class="tooltip-content">
  </div>
</div>
