import { Component, Input } from '@angular/core';
import { Environment } from 'src/app/shared/constants';
import { FiltersComponent, ValueFilterSelection } from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import { BaseObjectFilterComponent } from './../base-object-filter';

@Component({
  selector: 'coh-object-type-filter',
  templateUrl: './object-type-filter.component.html',
})
export class ObjectTypeFilterComponent extends BaseObjectFilterComponent<Environment[]> {
  /**
   * The name of the property environments
   */
  @Input() property = 'environment';

  constructor(private translate: TranslateService, filters: FiltersComponent) {
    super(filters);
  }

  /**
   * Fetch protection sources and map to filter values.
   */
  getFilterValues(): Observable<ValueFilterSelection[]> {
    return of(
      (this.requestParams || []).map(env => ({
        label: this.translate.instant(`enum.environment.${env}`),
        value: env,
      }))
    );
  }
}
