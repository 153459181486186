import { Injectable } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root',
})
export class UploadHostsFileService {
  constructor(
    private dialogService: DialogService, ) {
  }

  /**
   * Open dialog to reset Networking confirmation.
   *
   * @method showUploadHostsFileConfirmationDialog
   * @returns Observable of the dialog result.
   */
  openUploadHostsFileDialog(): Observable<MatDialogRef<any>> {
    const dialogConfig = {
      width: '35vw',
    };
    return this.dialogService.showDialog('upload-hosts-file-confirmation-dialog', null, dialogConfig);
  }
}
