import { NgModule } from '@angular/core';

import { YesNoPipe } from './yes-no.pipe';

/**
 * App Module. This is the root angular module.
 */
@NgModule({
  declarations: [YesNoPipe],
  exports: [YesNoPipe],
  providers: [YesNoPipe],
})
export class YesNoModule { }
