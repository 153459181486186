import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';

import { IrisRouterModule } from '../routing';
import { defaultBannerConfigs } from './configs';

const IMPORT_MODULES = [
  CohesityHelixModule,
  CommonModule,
  IrisRouterModule,
  TranslateModule.forChild(),
];

/**
 * This a module to add required banner in the IRIS UI Platform.
 */
@NgModule({
  imports: [...IMPORT_MODULES],
  providers: [...defaultBannerConfigs],
})
export class BannerConfigsModule {}
