/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HeliosAwsResponse } from '../models/helios-aws-response';
import { HeliosAwsRequest } from '../models/helios-aws-request';
@Injectable({
  providedIn: 'root',
})
class AwsRegistrationService extends __BaseService {
  static readonly OnboardAwsCustomerPath = '/mcm/aws-registration';
  static readonly VerifyAwsTokenPath = '/mcm/aws-registration/verify';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Onboards a new AWS Customer to DMaaS
   *
   * Captures the onboarding request from AWS Market place.
   * @param x-amzn-marketplace-token The Amazon Marketplace token with Product info.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  OnboardAwsCustomerResponse(xAmznMarketplaceToken: string,
    regionId?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (xAmznMarketplaceToken != null) { __formData.append('x-amzn-marketplace-token', xAmznMarketplaceToken as string | Blob);}
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/aws-registration`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Onboards a new AWS Customer to DMaaS
   *
   * Captures the onboarding request from AWS Market place.
   * @param x-amzn-marketplace-token The Amazon Marketplace token with Product info.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  OnboardAwsCustomer(xAmznMarketplaceToken: string,
    regionId?: string): __Observable<null> {
    return this.OnboardAwsCustomerResponse(xAmznMarketplaceToken, regionId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Onboards a new AWS Customer to DMaaS
   *
   * Captures the onboarding request from AWS Market place.
   * @param body Specifies the parameters to create an Identity Provider.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  VerifyAwsTokenResponse(body: HeliosAwsRequest,
    regionId?: string): __Observable<__StrictHttpResponse<HeliosAwsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/aws-registration/verify`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HeliosAwsResponse>;
      })
    );
  }
  /**
   * Onboards a new AWS Customer to DMaaS
   *
   * Captures the onboarding request from AWS Market place.
   * @param body Specifies the parameters to create an Identity Provider.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  VerifyAwsToken(body: HeliosAwsRequest,
    regionId?: string): __Observable<HeliosAwsResponse> {
    return this.VerifyAwsTokenResponse(body, regionId).pipe(
      __map(_r => _r.body as HeliosAwsResponse)
    );
  }
}

module AwsRegistrationService {
}

export { AwsRegistrationService }
