<ng-container *ngIf="status">
  <div *ngIf="!showProgress" class="pulse-status-container">
    <cog-icon [shape]="{status: status, type: 'status'} | statusIconMap"></cog-icon>
    <div>{{ 'enum.taskStatus.' + status | translate }}</div>
  </div>
  <span *ngIf="showProgress">
    <coh-pulse [taskPath]="taskPath" (taskFinish)="taskFinish.emit()"></coh-pulse>
  </span>
</ng-container>
<ng-container *ngIf="!status">{{ 'naNotApplicable' | translate }}</ng-container>
