import { Component, Input } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Controls, NgxSubFormComponent, subformComponentProviders } from 'ngx-sub-form';

import { JobType } from '../../constants';
import { BandwidthThrottling } from '../../models';

/**
 * Allows adapters to specify bandwidth throttling per stream for a job.
 */
@Component({
  selector: 'coh-settings-list-bandwidth-throttling',
  templateUrl: './settings-list-bandwidth-throttling.component.html',
  styleUrls: ['./settings-list-bandwidth-throttling.component.scss'],
  providers: subformComponentProviders(SettingsListBandwidthThrottlingComponent)
})
export class SettingsListBandwidthThrottlingComponent extends NgxSubFormComponent<BandwidthThrottling> {

  /**
   * Returns job type backup/recovery.
   */
  @Input() jobType?: JobType = 'Recovery';

  getDefaultValues(): Partial<BandwidthThrottling> {
    return {
      enabled: false,
      value: 10
    };
  }

  /**
   * Returns a collection of form controls for the subform template.
   */
  protected getFormControls(): Controls<BandwidthThrottling> {
    return {
      enabled : new UntypedFormControl(null),
      value: new UntypedFormControl(null, Validators.min(0)),
    };
  }
}
