/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AntivirusServiceGroups } from '../models/antivirus-service-groups';
import { AntivirusServiceGroup } from '../models/antivirus-service-group';
import { CreateAntivirusServiceGroupParams } from '../models/create-antivirus-service-group-params';
import { IcapUriConnectionStatusList } from '../models/icap-uri-connection-status-list';
import { InfectedFiles } from '../models/infected-files';
import { UpdateInfectedFilesList } from '../models/update-infected-files-list';
import { UpdateInfectedFilesParameters } from '../models/update-infected-files-parameters';
import { DeleteInfectedFiles } from '../models/delete-infected-files';
import { DeleteInfectedFilesParameters } from '../models/delete-infected-files-parameters';
import { DeleteInfectedObjects } from '../models/delete-infected-objects';
import { DeleteInfectedObjectsParameters } from '../models/delete-infected-objects-parameters';
@Injectable({
  providedIn: 'root',
})
class AntivirusServiceService extends __BaseService {
  static readonly GetAntivirusServiceGroupsPath = '/antivirus-service/groups';
  static readonly CreateAntivirusGroupPath = '/antivirus-service/groups';
  static readonly UpdateAntivirusGroupPath = '/antivirus-service/groups/{id}';
  static readonly DeleteAntivirusGroupPath = '/antivirus-service/groups/{id}';
  static readonly GetIcapUriConnectionStatusPath = '/antivirus-service/icap-uri-connection-status';
  static readonly GetInfectedFilesPath = '/antivirus-service/infected-files';
  static readonly UpdateInfectedFilesPath = '/antivirus-service/infected-files';
  static readonly DeleteInfectedFilesPath = '/antivirus-service/infected-files';
  static readonly DeleteInfectedObjectsPath = '/antivirus-service/infected-objects';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get Antivirus Service groups.
   *
   * Get Antivirus Service groups.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetAntivirusServiceGroupsResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<AntivirusServiceGroups>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/antivirus-service/groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AntivirusServiceGroups>;
      })
    );
  }
  /**
   * Get Antivirus Service groups.
   *
   * Get Antivirus Service groups.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetAntivirusServiceGroups(regionId?: string,
    accessClusterId?: number): __Observable<AntivirusServiceGroups> {
    return this.GetAntivirusServiceGroupsResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as AntivirusServiceGroups)
    );
  }

  /**
   * Create an Antivirus Service group.
   *
   * Create Antivirus Service group.
   * @param params The `AntivirusServiceService.CreateAntivirusGroupParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create antivirus service group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateAntivirusGroupResponse(params: AntivirusServiceService.CreateAntivirusGroupParams): __Observable<__StrictHttpResponse<AntivirusServiceGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/antivirus-service/groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AntivirusServiceGroup>;
      })
    );
  }
  /**
   * Create an Antivirus Service group.
   *
   * Create Antivirus Service group.
   * @param params The `AntivirusServiceService.CreateAntivirusGroupParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create antivirus service group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateAntivirusGroup(params: AntivirusServiceService.CreateAntivirusGroupParams): __Observable<AntivirusServiceGroup> {
    return this.CreateAntivirusGroupResponse(params).pipe(
      __map(_r => _r.body as AntivirusServiceGroup)
    );
  }

  /**
   * Update an Antivirus Service group with given parameters or if state is specified, enable or disable given group.
   *
   * Update an Antivirus Service group.
   * @param params The `AntivirusServiceService.UpdateAntivirusGroupParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Antivirus Group to update.
   *
   * - `body`: Specifies the parameters to update antivirus service group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateAntivirusGroupResponse(params: AntivirusServiceService.UpdateAntivirusGroupParams): __Observable<__StrictHttpResponse<AntivirusServiceGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/antivirus-service/groups/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AntivirusServiceGroup>;
      })
    );
  }
  /**
   * Update an Antivirus Service group with given parameters or if state is specified, enable or disable given group.
   *
   * Update an Antivirus Service group.
   * @param params The `AntivirusServiceService.UpdateAntivirusGroupParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Antivirus Group to update.
   *
   * - `body`: Specifies the parameters to update antivirus service group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateAntivirusGroup(params: AntivirusServiceService.UpdateAntivirusGroupParams): __Observable<AntivirusServiceGroup> {
    return this.UpdateAntivirusGroupResponse(params).pipe(
      __map(_r => _r.body as AntivirusServiceGroup)
    );
  }

  /**
   * Delete an Antivirus Service group
   *
   * Deletes an Antivirus service group based on given id.
   * @param params The `AntivirusServiceService.DeleteAntivirusGroupParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Antivirus Group to delete.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteAntivirusGroupResponse(params: AntivirusServiceService.DeleteAntivirusGroupParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/antivirus-service/groups/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete an Antivirus Service group
   *
   * Deletes an Antivirus service group based on given id.
   * @param params The `AntivirusServiceService.DeleteAntivirusGroupParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Antivirus Group to delete.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteAntivirusGroup(params: AntivirusServiceService.DeleteAntivirusGroupParams): __Observable<null> {
    return this.DeleteAntivirusGroupResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get ICAP Uri connection status.
   *
   * Get ICAP Uri connection status.
   * @param params The `AntivirusServiceService.GetIcapUriConnectionStatusParams` containing the following parameters:
   *
   * - `uris`: Specifies a list of URIs to check connection status.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetIcapUriConnectionStatusResponse(params: AntivirusServiceService.GetIcapUriConnectionStatusParams): __Observable<__StrictHttpResponse<IcapUriConnectionStatusList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.uris || []).forEach(val => {if (val != null) __params = __params.append('uris', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/antivirus-service/icap-uri-connection-status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IcapUriConnectionStatusList>;
      })
    );
  }
  /**
   * Get ICAP Uri connection status.
   *
   * Get ICAP Uri connection status.
   * @param params The `AntivirusServiceService.GetIcapUriConnectionStatusParams` containing the following parameters:
   *
   * - `uris`: Specifies a list of URIs to check connection status.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetIcapUriConnectionStatus(params: AntivirusServiceService.GetIcapUriConnectionStatusParams): __Observable<IcapUriConnectionStatusList> {
    return this.GetIcapUriConnectionStatusResponse(params).pipe(
      __map(_r => _r.body as IcapUriConnectionStatusList)
    );
  }

  /**
   * Get infected entities.
   *
   * Get infected entities.
   * @param params The `AntivirusServiceService.GetInfectedFilesParams` containing the following parameters:
   *
   * - `viewIds`: Specifies a list of view ids. Only infected entities from these views will be returned.
   *
   * - `states`: Specifies the file states.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `path`: Specifies the file path.
   *
   * - `maxCount`: Specifies the max number of files to be returned.
   *
   * - `cookie`: Specifies the pagination cookie.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetInfectedFilesResponse(params: AntivirusServiceService.GetInfectedFilesParams): __Observable<__StrictHttpResponse<InfectedFiles>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.viewIds || []).forEach(val => {if (val != null) __params = __params.append('viewIds', val.toString())});
    (params.states || []).forEach(val => {if (val != null) __params = __params.append('states', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.path != null) __params = __params.set('path', params.path.toString());
    if (params.maxCount != null) __params = __params.set('maxCount', params.maxCount.toString());
    if (params.cookie != null) __params = __params.set('cookie', params.cookie.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/antivirus-service/infected-files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<InfectedFiles>;
      })
    );
  }
  /**
   * Get infected entities.
   *
   * Get infected entities.
   * @param params The `AntivirusServiceService.GetInfectedFilesParams` containing the following parameters:
   *
   * - `viewIds`: Specifies a list of view ids. Only infected entities from these views will be returned.
   *
   * - `states`: Specifies the file states.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `path`: Specifies the file path.
   *
   * - `maxCount`: Specifies the max number of files to be returned.
   *
   * - `cookie`: Specifies the pagination cookie.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetInfectedFiles(params: AntivirusServiceService.GetInfectedFilesParams): __Observable<InfectedFiles> {
    return this.GetInfectedFilesResponse(params).pipe(
      __map(_r => _r.body as InfectedFiles)
    );
  }

  /**
   * Update infected entities state.
   *
   * Update infected entities state.
   * @param params The `AntivirusServiceService.UpdateInfectedFilesParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters of infected entities to be updated.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateInfectedFilesResponse(params: AntivirusServiceService.UpdateInfectedFilesParams): __Observable<__StrictHttpResponse<UpdateInfectedFilesList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/antivirus-service/infected-files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateInfectedFilesList>;
      })
    );
  }
  /**
   * Update infected entities state.
   *
   * Update infected entities state.
   * @param params The `AntivirusServiceService.UpdateInfectedFilesParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters of infected entities to be updated.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateInfectedFiles(params: AntivirusServiceService.UpdateInfectedFilesParams): __Observable<UpdateInfectedFilesList> {
    return this.UpdateInfectedFilesResponse(params).pipe(
      __map(_r => _r.body as UpdateInfectedFilesList)
    );
  }

  /**
   * Delete infected files.
   *
   * Delete infected files.
   * @param params The `AntivirusServiceService.DeleteInfectedFilesParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters of infected files to be deleted.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  DeleteInfectedFilesResponse(params: AntivirusServiceService.DeleteInfectedFilesParams): __Observable<__StrictHttpResponse<DeleteInfectedFiles>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/antivirus-service/infected-files`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DeleteInfectedFiles>;
      })
    );
  }
  /**
   * Delete infected files.
   *
   * Delete infected files.
   * @param params The `AntivirusServiceService.DeleteInfectedFilesParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters of infected files to be deleted.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  DeleteInfectedFiles(params: AntivirusServiceService.DeleteInfectedFilesParams): __Observable<DeleteInfectedFiles> {
    return this.DeleteInfectedFilesResponse(params).pipe(
      __map(_r => _r.body as DeleteInfectedFiles)
    );
  }

  /**
   * Delete infected objects permanently.
   *
   * Delete infected objects permanently.
   * @param params The `AntivirusServiceService.DeleteInfectedObjectsParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters of infected objects to be deleted.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  DeleteInfectedObjectsResponse(params: AntivirusServiceService.DeleteInfectedObjectsParams): __Observable<__StrictHttpResponse<DeleteInfectedObjects>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/antivirus-service/infected-objects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DeleteInfectedObjects>;
      })
    );
  }
  /**
   * Delete infected objects permanently.
   *
   * Delete infected objects permanently.
   * @param params The `AntivirusServiceService.DeleteInfectedObjectsParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters of infected objects to be deleted.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  DeleteInfectedObjects(params: AntivirusServiceService.DeleteInfectedObjectsParams): __Observable<DeleteInfectedObjects> {
    return this.DeleteInfectedObjectsResponse(params).pipe(
      __map(_r => _r.body as DeleteInfectedObjects)
    );
  }
}

module AntivirusServiceService {

  /**
   * Parameters for CreateAntivirusGroup
   */
  export interface CreateAntivirusGroupParams {

    /**
     * Specifies the parameters to create antivirus service group.
     */
    body: CreateAntivirusServiceGroupParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateAntivirusGroup
   */
  export interface UpdateAntivirusGroupParams {

    /**
     * Specifies a unique id of the Antivirus Group to update.
     */
    id: number;

    /**
     * Specifies the parameters to update antivirus service group.
     */
    body: AntivirusServiceGroup;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteAntivirusGroup
   */
  export interface DeleteAntivirusGroupParams {

    /**
     * Specifies a unique id of the Antivirus Group to delete.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetIcapUriConnectionStatus
   */
  export interface GetIcapUriConnectionStatusParams {

    /**
     * Specifies a list of URIs to check connection status.
     */
    uris?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetInfectedFiles
   */
  export interface GetInfectedFilesParams {

    /**
     * Specifies a list of view ids. Only infected entities from these views will be returned.
     */
    viewIds?: Array<number>;

    /**
     * Specifies the file states.
     */
    states?: Array<'Quarantined' | 'Unquarantined'>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the file path.
     */
    path?: string;

    /**
     * Specifies the max number of files to be returned.
     */
    maxCount?: number;

    /**
     * Specifies the pagination cookie.
     */
    cookie?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateInfectedFiles
   */
  export interface UpdateInfectedFilesParams {

    /**
     * Specifies the parameters of infected entities to be updated.
     */
    body: UpdateInfectedFilesParameters;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteInfectedFiles
   */
  export interface DeleteInfectedFilesParams {

    /**
     * Specifies the parameters of infected files to be deleted.
     */
    body: DeleteInfectedFilesParameters;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteInfectedObjects
   */
  export interface DeleteInfectedObjectsParams {

    /**
     * Specifies the parameters of infected objects to be deleted.
     */
    body: DeleteInfectedObjectsParameters;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { AntivirusServiceService }
