import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DayVal } from '@cohesity/utils';

/**
 * This component allows user to input day of week.
 */
@Component({
  selector: 'coh-day-of-week-selector',
  styleUrls: ['./day-of-week-selector.component.scss'],
  templateUrl: './day-of-week-selector.component.html'
})
export class DayOfWeekSelectorComponent {
  /**
   * Day of week list.
   */
  dayOfWeekList = DayVal;

  /**
   * Specifies label for day of week selector.
   */
  @Input() label?: string;

  /**
   * Form control for day of week selector.
   */
  @Input() control: UntypedFormControl;

  /**
   * Allow single selection only.
   */
  @Input() singleOnly = false;
}
