<div class="flex-end dialog-header">
  <h2 mat-dialog-title>{{ title | translate }}</h2>
</div>

<mat-dialog-content class="margin-bottom dialog-content">
  <cog-banner>
    {{ description | translate }}
  </cog-banner>
  <h4>
    {{ subtitle | translate }}
  </h4>
  <table mat-table [dataSource]="features">
    <ng-container matColumnDef="name">
      <td mat-cell *matCellDef="let feature"> {{ feature.name }} </td>
    </ng-container>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <mat-paginator></mat-paginator>
  </table>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-button
    mat-dialog-close
    cogSubmitButton
    cdkFocusInitial
  >
    {{ 'close' | translate }}
  </button>
</mat-dialog-actions>
