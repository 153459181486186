import { Clipboard } from '@angular/cdk/clipboard';
import { Injectable } from '@angular/core';
import {
  CloudProvider,
  DmaasRegion,
  DmaasRegions,
  DmsServiceApi,
  TenantRegionInfo,
  TenantRegions
} from '@cohesity/api/dms';
import { CreateRigelGroupRequest, RigelConnectivityEndpoints, RigelmgmtServiceApi } from '@cohesity/api/rms';
import { DataSourceConnection, DataSourceConnectionList, DataSourceConnectionServiceApi } from '@cohesity/api/v2';
import { SnackBarService } from '@cohesity/helix';
import { flagEnabled, getUserTenantId, IrisContextService } from '@cohesity/iris-core';
import { AjaxHandlerService } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { isNil } from 'lodash';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, map } from 'rxjs/operators';

import { Connection, PortInformation } from '../interfaces';
import { isConnectorBasedSourceRegistrationEnabled } from 'src/app/modules/sources-shared/utils';


@Injectable()
export class ConnectionSetupService {
  /**
   * Indicate if SaaS Create Connection should replace source registration form.
   * `coh-connection-setup-dialog` template has to be added to source registration form to toggle it.
   */
  readonly createConnection$ = new BehaviorSubject<boolean>(false);

  /**
   * Outbound ports that are needed for the ports to work.
   * Note that the ports 11117 and 29991 will be filtered out later.
   * It is not removed here because some logic still requires it.
   */
  private outboundPorts: PortInformation[] = [
    {
      port: 11117,
      portInfo: 'TCP',
      target: '[fqdn].dmaas.helios.cohesity.com',
      direction: 'outgoing',
    },
    {
      port: 29991,
      portInfo: 'TCP',
      target: '[fqdn].dmaas.helios.cohesity.com',
      direction: 'outgoing',
    },
    {
      port: 443,
      portInfo: 'TCP',
      target: '[fqdn].dmaas.helios.cohesity.com',
      direction: 'outgoing',
    },
  ];

  /**
   * Holds an observable to whether the connection setup is loading.
   */
  loading$ = new BehaviorSubject<boolean>(false);

  /**
   * Holds an observable to what is the current selected connection.
   */
  selectedConnection$ = new BehaviorSubject<Connection>(null);

  /**
   * Holds an observable to all the active connections.
   */
  connections$ = new BehaviorSubject<Connection[]>(null);

  /**
   * Holds an observable to configured regions.
   */
  availableRegions$ = new BehaviorSubject<DmaasRegion[]>(null);

  /**
   * Holds an observable to list of configured regions.
   */
  configuredRegions$ = new BehaviorSubject<TenantRegionInfo[]>(null);

  /**
   * Holds an observable with the FQDN of the selected connection.
   */
  portInformation$ = new BehaviorSubject<PortInformation[]>(null);

  constructor(
    private ajaxService: AjaxHandlerService,
    private clipboard: Clipboard,
    private dmsService: DmsServiceApi,
    private irisCtx: IrisContextService,
    private rmsService: RigelmgmtServiceApi,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    private clusterRigelService: DataSourceConnectionServiceApi,
  ) { }

  /**
   * Gets Port information from region.
   *
   * @param    region  Configured region.
   * @returns  Ports information.
   */
  getPortInformation(region: TenantRegionInfo) {
    const portInfo = this.outboundPorts.map(port => {
      port.target = port.port === 443 ? region.tenantS3Endpoint : region.tenantFqdn;
      return port;
    });

    if (this.irisCtx.irisContext.featureFlags.portInfoEnhancements) {
      const sub = this.rmsService.GetConnectivityEndpoints();
      sub.pipe(catchError(() => of(null)))
      .subscribe((endpoints: RigelConnectivityEndpoints) => {

        if (endpoints) {
          const uniqueTargets = [...new Set(portInfo.filter(item => item.port !== 443).map(item => item.target))];
          uniqueTargets.forEach(target => {
            portInfo.push({
              direction: 'outgoing',
              port: 443,
              portInfo: 'TCP',
              target: target
            });
          });
          this.outboundPorts.forEach(port => {
            portInfo.push({
              direction: 'outgoing',
              port: port.port,
              portInfo: 'TCP',
              target: endpoints.dataplaneEndpointFqdn
            });
          });
          portInfo.push({
            direction: 'outgoing',
            port: 443,
            portInfo: 'TCP',
            target: `${endpoints.dataplaneS3Bucket}.s3.${endpoints.dataplaneS3BucketRegion}.amazonaws.com`
          });
        }

        // The ports 11117, 29991 are only for older releases and will be in documentation
        // so they are removed for newer release to avoid confusion.
        let portsToRemove = [11117, 29991];

        // NOTE - Added 11117 port for RDS Ingest usecase. Will remove this when
        // cohesity_cp starts supporting it.
        if (flagEnabled(this.irisCtx.irisContext, 'awsPostgresDbAuthorization')) {
          portsToRemove = portsToRemove.filter(port => port !== 11117);
        }

        this.portInformation$.next(portInfo.filter(p => !portsToRemove.includes(p.port)));
      });
    } else {
      this.portInformation$.next(portInfo);
    }
  }

  /**
   * Get the latest port information based on the current selected connection.
   */
  GetPortInformationForRegion() {
    combineLatest([
      this.configuredRegions$,
      this.selectedConnection$,
    ]).pipe(
      filter(([ regions, connection ]) => !isNil(regions) && !isNil(connection)))
      .subscribe(([regions, connection]) => {
        const selectedRegion = regions.find(region => region.regionId === connection.regionId);
        this.getPortInformation(selectedRegion);
      }
      );
  }

  /**
   * Gets all configured connections.
   *
   * @param skipLoading Skips loading status
   */
  GetConnectionsForTenant(skipLoading: boolean = false): void {
    if(isConnectorBasedSourceRegistrationEnabled(this.irisCtx.irisContext)){
      this.GetDataSourceConnectionsForClusterTenant();
    } else {
      this.GetRigelConnectionsForDmsTenant(skipLoading);
    }
  }

  /**
   * Gets all configured connections for the DMS tenant.
   */
  GetRigelConnectionsForDmsTenant(skipLoading: boolean = false) {

    if (!skipLoading) {
      this.loading$.next(true);
    }

    forkJoin([
      this.dmsService.GetRegions(),
      this.rmsService.GetRigelGroups({
        tenantId: getUserTenantId(this.irisCtx.irisContext),
        fetchConnectorGroups: true,
      })
    ]).pipe(
      finalize(() => this.loading$.next(false)),
      map(([region, connections]): Connection[] => {
        const allRegions = region?.regions;
        if (!allRegions || !connections.rigelGroups) {
          return;
        }

        return connections.rigelGroups?.map((conn): Connection => {
          let icon;
          switch (conn.status) {
            case 'NotConnected':
              icon = 'helix:status-error!critical';
              break;
            case 'SetupInProgress':
              icon = 'helix:status-info-alt!info';
              break;
            default:
              icon = 'helix:status-success!success';
          }

          const mappedConnection = {
            ...conn,
            id: conn.groupId,
            regionName: allRegions.find(r => r.id === conn.regionId)?.location,
            icon,
          };
          return mappedConnection;
        });
      })
    ).subscribe(
      connections => this.connections$.next(connections),
      err => this.ajaxService.errorMessage(err),
    );
  }

  /**
   * Fetch connections for cluster tenant
   */
  GetDataSourceConnectionsForClusterTenant(): void {
    const clusterConnectionParams = {
      tenantId: getUserTenantId(this.irisCtx.irisContext),
    } as DataSourceConnectionServiceApi.GetDataSourceConnectionParams;

    this.clusterRigelService
      .GetDataSourceConnection(clusterConnectionParams)
      .pipe(
        map((response: DataSourceConnectionList) =>
          (response.connections || []).map(
            (rigelGroup: DataSourceConnection) =>
              ({
                groupId: null,
                id: rigelGroup.connectionId,
                groupName: rigelGroup.connectionName,
              })
          )
        )
      )
      .subscribe(
        connections => this.connections$.next(connections),
        err => this.ajaxService.errorMessage(err)
      );
  }

  /**
   * Creates a new SaaS connection.
   */
  CreateConnection(regionId: string) {
    const param: CreateRigelGroupRequest = {
      regionId,
      tenantId: getUserTenantId(this.irisCtx.irisContext),
    };

    forkJoin([
      this.rmsService.CreateRigelGroup(param),
      this.rmsService.GetRigelImage(regionId),
      this.dmsService.GetRegions(),
    ]).pipe(
      map(([rigelGroup, rigelImage, region]): Connection => ({
        ...rigelGroup,
        id: rigelGroup.groupId,
        regionId,
        imageUrl: rigelImage.downloadLink,
        imageUrlVHD: rigelImage.downloadLinkVHD,
        regionName: region.regions.find(r => r.id === regionId)?.name
      }))
    ).subscribe(
      connection => {
        this.selectedConnection$.next(connection);
        this.GetPortInformationForRegion();
      },
      err => this.ajaxService.errorMessage(err),
    );
  }

  /**
   * Gets a status of a particular connection based on groupId and regionId for helios or cluster.
   */
  GetConnection(groupId: string | number, regionId: string, fetchToken = true) {
    if(isConnectorBasedSourceRegistrationEnabled(this.irisCtx.irisContext)){
      this.getDataSourceConnectionForClusterTenant(groupId as string);
    } else {
      this.getConnectionForDms(groupId as number, regionId, fetchToken);
    }
  }

  /**
   * Fetch connection for cluster tenant based on group ID
   *
   * @param groupId ID of the connection
   */
  getDataSourceConnectionForClusterTenant(groupId: string): void {
    const clusterConnectionParams = {
      tenantId: getUserTenantId(this.irisCtx.irisContext),
      connectionIds: [groupId]
    } as DataSourceConnectionServiceApi.GetDataSourceConnectionParams;

    this.clusterRigelService
      .GetDataSourceConnection(clusterConnectionParams)
      .pipe(
        map((response: DataSourceConnectionList) =>
          (response.connections || []).map(
            (rigelGroup: DataSourceConnection) =>
              ({
                groupId: null,
                id: rigelGroup.connectionId,
                groupName: rigelGroup.connectionName,
                numberOfConnectors: rigelGroup.connectorIds?.length,
              })
          )
        )
      )
      .subscribe(
        connections => this.selectedConnection$.next(connections?.[0]),
        err => this.ajaxService.errorMessage(err)
      );
  }

  /**
   * Gets a status of a particular connection based on groupId and regionId.
   */
  getConnectionForDms(groupId: number, regionId: string, fetchToken = true) {
    forkJoin([
      this.dmsService.GetRegions(),
      this.rmsService.GetRigelGroups({
        groupId,
        tenantId: getUserTenantId(this.irisCtx.irisContext),
        fetchToken,
      }),
      this.rmsService.GetRigelImage(regionId),
    ]).pipe(
      map(([regions, result, image]): Connection => {
        const selectedGroup = result.rigelGroups[0];
        const selectedRegion = regions.regions.find(r => r.id === selectedGroup.regionId);
        return {
          ...selectedGroup,
          id: selectedGroup.groupId,
          regionName: selectedRegion.name,
          imageUrl: image.downloadLink,
          imageUrlVHD: image.downloadLinkVHD,
        };
      })
    ).subscribe(
      connection => this.selectedConnection$.next(connection),
      err => this.ajaxService.errorMessage(err),
    );
  }

  /**
   * Delete Connection by ID.
   *
   * @param    connectionId  Connection ID.
   * @returns  Observable of delete result.
   */
  DeleteConnection(connectionId: number): Observable<null> {
    return this.rmsService.DeleteRigelConnection({
      tenantId: getUserTenantId(this.irisCtx.irisContext),
      connectionId,
    }).pipe(
      catchError((error) => {
        this.ajaxService.errorMessage(error);
        return throwError(error);
      }),
    );
  }

  /**
   * Fetches list of configured regions.
   *
   * @param type Specifies cloud provider
   */
  GetAvailableRegions(type?: CloudProvider) {
    forkJoin([
      this.dmsService.GetRegions(),
      this.dmsService.GetTenantRegions({
        tenantId: getUserTenantId(this.irisCtx.irisContext),
      })
    ]).subscribe(
      ([regions, configuredRegion]: [DmaasRegions, TenantRegions]) => {
        const availableRegion = configuredRegion.tenantRegionInfoList
          .map(tenant => regions.regions.find(r =>
            r.id === tenant.regionId && tenant.provisionStatus.status === 'Completed'
            && (type ? type === r.type:  true)
          ))
          .filter(Boolean).sort((a, b) => a.name.localeCompare(b.name));

        this.configuredRegions$.next(configuredRegion?.tenantRegionInfoList);
        this.availableRegions$.next(availableRegion);
      }
    );
  }


  /**
   * Handles callback when creating new SaaS connection.
   */
  createNewConnection() {
    this.createConnection$.next(true);
  }

  /**
   * Handles event when SaaS connection form is canceled.
   */
  closeCreateConnection() {
    this.createConnection$.next(false);
  }


  /**
   * Copies the content to clipboard.
   */
  copyClaimToken() {
    const value = this.selectedConnection$?.value?.claimToken;

    if (!value) {
      return;
    }

    this.clipboard.copy(value);
    const msg = this.translateService.instant('copiedToClipboard', {
      item: value,
    });
    this.snackBarService.open(msg, 'success');
  }

  /**
   * Cleans up currently populated values.
   */
  reset() {
    this.selectedConnection$.next(null);
    this.portInformation$.next(null);
    this.configuredRegions$.next(null);
  }
}
