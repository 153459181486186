import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { DataFilterValue, FiltersComponent } from '@cohesity/helix';
import { AjaxHandlerService, AsyncBehaviorSubject, AutoDestroyable, updateWithStatus } from '@cohesity/utils';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { isEqual } from 'lodash';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { ListConfiguration, ListWithPagination } from './list-configuration.model';
import { DateParameter, ListParameter, SearchParameter } from './list-parameter.model';

/**
 * Default page size.
 */
export const defaultPageSize = 25;

/**
 * Default page size options.
 */
export const defaultPageSizeOptions = [10, 25, 50];

/**
 * List page component with filters.
 * If showing ListItem in a timeline, it will need to have status, statusIcon and serverity attributes.
 */
@Component({
  selector: 'coh-list-with-filter',
  templateUrl: './list-with-filter.component.html',
  styleUrls: ['./list-with-filter.component.scss'],
})
export class ListWithFilterComponent<ListItem> extends AutoDestroyable implements AfterViewInit, OnInit {
  /**
   * Holds the reference of the filter component.
   */
  @ViewChild(FiltersComponent) filtersComponent: FiltersComponent;

  /**
   * Default page size options.
   */
  defaultPageSizeOptions = defaultPageSizeOptions;

  /**
   * List component configuration.
   */
  _config: ListConfiguration<ListItem>;

  @Input() set config(config: ListConfiguration<ListItem>) {
    if (config) {
      this._config = config;
      this.configColumns = this.config.columns?.map(c => c.name)?.filter(c => c !== 'select');
      this.configColumns.push('actions');
    }
  };

  get config(): ListConfiguration<ListItem> {
    return this._config;
  }

  /**
   * Output filtered List.
   */
  @Output() filteredList = new EventEmitter<ListItem[]>();

  /**
   * List of columns for table configuration (show/hide columns).
   */
  configColumns: string[];

  /**
   * The async items list subject with the api call status.
   */
  private listItemsSubject = new AsyncBehaviorSubject<ListItem[]>(null);

  /**
   * True if the items list call is currently loading.
   */
  loading$ = this.listItemsSubject.pipe(map(val => val?.loading));

  /**
   * The items list
   */
  listItems$ = this.listItemsSubject.pipe(
    map(value => value?.result),
  );

  /**
   * Parameters Subject from filter values for API call.
   */
  private paramsSubject = new BehaviorSubject<any>({});

  /**
   * Async Parameters from filter values for API call.
   */
  params$ = this.paramsSubject.asObservable();

  /**
   * Async search string from search filter value to filter API result.
   */
  searchString$ = new BehaviorSubject<string>(null);

  /**
   * Pagination event.
   * TODO: Add server-side pagination support for table, client-side pagination support for timeline.
   */
  page$ = new BehaviorSubject<PageEvent>(null);

  /**
   * Current page size.
   */
  get pageSize(): number {
    return this.page$.value?.pageSize || this.config.defaultPageSize || defaultPageSize;
  }

  /**
   * Current page index used for display. It maybe reset by params change without pagination event.
   */
  pageIndex = 0;

  /**
   * True if it is a new page event. Used to reset pagination parameters if false.
   */
  newPageEvent = false;

  /**
   * Total number of items in backend.
   */
  total = 0;

  /**
   * Stores the selected rows.
   */
  selection = new SelectionModel<ListItem>(true, []);

  /**
   * Returns list of values parameter configs.
   */
  get valueParameters(): ListParameter[] {
    return this.config?.params?.filter(param => param.type === 'value') as ListParameter[];
  }

  /**
   * Returns search filter parameter config.
   */
  get searchParameter(): SearchParameter {
    return this.config?.params?.find(param => param.type === 'search') as SearchParameter;
  }

  /**
   * Returns date range parameter config.
   */
  get dateRangeParameter(): DateParameter {
    return this.config?.params?.find(param => param.type === 'date') as DateParameter;
  }

  /**
   * Function to return whether a row can be selected.
   *
   * @return True if row is selectable.
   */
  canSelectRow = () => !!this.config?.bulkActions;

  /**
   * Function to return whether the select all option would be available.
   *
   * @return True if any row is selectable.
   */
  canSelectAnyRow = () => !!this.config?.bulkActions;

  /**
   * Function to return whether the select all option would be available.
   *
   * @return True if any row is selectable.
   */
  isAllSelectedFn = () => this.selection &&
    this.selection.selected.length === this.listItemsSubject.value?.result?.length;

  constructor(
    private ajaxHandler: AjaxHandlerService,
    private state: StateService,
    private uiRouterGlobals: UIRouterGlobals,
  ) {
    super();
  }

  ngOnInit() {
    let updated = false;
    const params = this.uiRouterGlobals.params;
    const defaultParams = this.config?.params?.filter(param => !!param.defaultParamValue);

    // Set default parameters and reload page if needed.
    defaultParams?.forEach(config => {
      if (!params[config.name] && (config.type !== 'date' || (!params.startTime && !params.endTime))) {
        params[config.name] = config.defaultParamValue;
        updated = true;
      }
    });
    if (updated) {
      this.state.go(this.uiRouterGlobals.current?.name, params, { notify: false });
    }

    // Set table data if data is provided.
    if (this.config?.data?.length) {
      this.listItemsSubject.next({
        result: this.config.data,
        loading: false,
        success: true,
        error: undefined,
      });
    }

    this.updatePaginationFromParams();
  }

  ngAfterViewInit() {
    this.updateFiltersFromParams();
    this.setupParams();
    combineLatest([
      combineLatest([this.params$, this.page$]).pipe(
        distinctUntilChanged(isEqual),
        switchMap(([params, page]) => {
          if (this.config?.fetchData) {
            if (this.newPageEvent) {
              this.newPageEvent = false;
            } else if (page?.pageIndex) {
              // Reset page number if params change (not newPageEvent).
              this.pageIndex = 0;
              page = {
                ...page,
                pageIndex: 0,
              };
            }
            this.updatePaginationParams(page);
            return this.config.fetchData(params, page);
          }
          return of(this.config?.data || []);
        }),
      ),
      this.searchString$,
    ]).pipe(
      this.untilDestroy(),
      map(result => this.filterList(result)),
      tap(result => this.filteredList.emit(result)),
      updateWithStatus(this.listItemsSubject),
      this.ajaxHandler.catchAndHandleError(),
    ).subscribe(
      // The above Observable is not completed so finalize in AsyncBehaviorSubject is not called.
      // Need the following workaround to reset loading flag.
      () => this.listItemsSubject.next({ ...this.listItemsSubject.value, loading: false }),
      () => this.listItemsSubject.next({ ...this.listItemsSubject.value, loading: false }),
    );
  }

  /**
   * Function to filter data model for table.
   *
   * @param items  Table items.
   * @param searchString  Search string.
   */
  private filterList(
    [items, searchString]: [ListItem[] | ListWithPagination<ListItem>, string],
  ): ListItem[] {
    const { data, total } = (items || {}) as ListWithPagination<ListItem>;

    if (data) {
      this.total = total || 0;
      items = data;
    }
    if (!searchString) {
      return items as ListItem[];
    }

    const searchColumns = (this.config?.params.find(param => param.type === 'search') as SearchParameter)?.columns;

    if (!searchColumns?.length) {
      return items as ListItem[];
    }

    return (items as ListItem[])?.filter((item) =>
      searchColumns?.some(column => {
        const columnValue = item[column];

        if (typeof columnValue === 'string' && columnValue?.includes(searchString)) {
          return true;
        }
        if (columnValue?.length &&
          typeof columnValue.some === 'function' &&
          columnValue.some(value => value.includes(searchString))) {
          return true;
        }
        return false;
      })
    );
  }

  /**
   * Setup async params and search string from filter value changes.
   */
  setupParams() {
    this.filtersComponent?.filterValues$?.pipe(
      debounceTime(5),
      this.untilDestroy(),
      tap(filters => {
        this.updateUrlParams(filters);
        this.updateSearchString(filters);
      }),
      map(filters => {
        const params: any = {};

        filters.forEach(filter => {
          const paramConfig = this.config?.params?.find(config => config.name === filter.key);

          if (paramConfig.type === 'date') {
            Object.assign(params, (paramConfig as DateParameter).toApiValue(filter.value));
          } else if (paramConfig.type === 'value') {
            params[paramConfig.name] = filter.value?.map(value => value.value) || [];
          }
        });
        return params;
      }),
      distinctUntilChanged(isEqual),
    ).subscribe(
      params => this.paramsSubject.next(params)
    );
  }

  /**
   * Set the table filters based on any values provided via state/url params.
   */
  private updateFiltersFromParams() {
    const params = this.uiRouterGlobals.params;

    (this.config?.params || []).forEach(paramConfig => {
      if (paramConfig.type === 'date') {
        (paramConfig as DateParameter).setFilterValue(params);
      } else if (params[paramConfig.name]) {
        if (paramConfig.type === 'search') {
          this.filtersComponent.setValue(paramConfig.name, params[paramConfig.name]);
        }
      }
    });
  }

  /**
   * Update pagination from params.
   */
  private updatePaginationFromParams() {
    const { pageNumber, pageSize } = this.uiRouterGlobals.params || {} as any;

    if (pageNumber && pageSize) {
      this.newPageEvent = true;
      this.pageIndex = pageNumber > 0 ? pageNumber - 1 : 0;
      this.page$.next({ pageIndex: this.pageIndex, pageSize: pageSize || this.pageSize, length: 0 });
    }
  }

  /**
   * Update pagination parameters in URL.
   *
   * @param page  Page event object.
   */
  private updatePaginationParams(page: PageEvent) {
    if (this.config?.serverSidePagination && page) {
      const params = {...this.uiRouterGlobals.params};

      params.pageNumber = this.pageIndex + 1;
      params.pageSize = this.pageSize;
      this.state.go(this.uiRouterGlobals.current?.name, params, {notify: false, inherit: false});
    }
  }

  /**
   * Update URL parameters from filter options.
   *
   * @param filters  Filters being set by user.
   */
  private updateUrlParams(filters: DataFilterValue<any>[]) {
    const params = { ...this.uiRouterGlobals.params };

    this.config?.params?.map(param => param.name).forEach(key => delete params[key]);
    if (this.config?.params?.some(param => param.type === 'date')) {
      delete params.startTime;
      delete params.endTime;
    }

    // Update the dynamic state params so navigating away from and back to
    // this page will restore the params.
    filters.forEach(dataFilter => {
      const paramConfig = this.config?.params?.find(param => param.name === dataFilter.key);

      if (paramConfig.type === 'date') {
        Object.assign(params, (paramConfig as DateParameter).toParamValue(dataFilter.value));
      } else if (params[paramConfig.name] !== dataFilter.value) {
        if (dataFilter.value) {
          if (paramConfig.allowMultiple) {
            if (dataFilter.value?.length) {
              params[paramConfig.name] = dataFilter.value?.map(value => value.value) || [];
            } else {
              delete params[paramConfig.name];
            }
          } else {
            params[paramConfig.name] = dataFilter.value?.[0]?.value || dataFilter.value;
          }
        } else {
          delete params[paramConfig.name];
        }
      }
    });

    // Fire a state change so url params get updated. `reloadOnSearch: false`
    // in state config will prevent this from reloading the entire state.
    this.state.go(this.uiRouterGlobals.current?.name, params, { notify: false, inherit: false });
  }

  /**
   * Stream search string from filter options.
   *
   * @param filters  Filters being set by user.
   */
  private updateSearchString(filters: DataFilterValue<any>[]) {
    const paramConfig = this.config?.params?.find(config => config.type === 'search');
    const searchFilter = filters?.find(filter => filter.key === paramConfig?.name);

    if (searchFilter?.value !== this.searchString$.value) {
      this.searchString$.next(searchFilter?.value);
    }
  }

  /**
   * Call config rowAction if it is defined.
   *
   * @param row Table row.
   */
  action(row: ListItem) {
    if (this.config?.rowAction) {
      this.config.rowAction(row);
    }
  }

  /**
   * Handles changes to pagination.
   *
   * @param event   Pagination change event.
   */
  paginatorChange(event: PageEvent) {
    if (this.config?.serverSidePagination) {
      this.newPageEvent = true;
      this.pageIndex = event.pageIndex;
      this.page$.next(event);
    }
  }
}
