// Component: cDbMigrationTaskSettings

(function _iife(angular) {
  'use strict';

  angular.module('C.recovery')
    .controller('CDbMigrationTaskSettingsController',
      CDbMigrationTaskSettingsController)
    .component('cDbMigrationTaskSettings', {
      bindings: {
        restoreTask: '<',
      },
      controller: 'CDbMigrationTaskSettingsController',
      templateUrl: 'app/protection/recovery/detail/c-database-migration-' +
        'details/c-database-migration-settings.component.html',
    });

  /**
   * Db Migration Task Settings Controller
   *
   * @function   CDbMigrationTaskSettingsController
   */
  function CDbMigrationTaskSettingsController() {}

})(angular);
