import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { ErrorTypes } from 'src/app/shared/constants';

import { BaseProtectionBuilderComponent } from '../../base-protection-builder/base-protection-builder.component';
import { BaseProtectionItemProperties, IgnorableErrorTypes } from '../../../models';

/**
 * Available settings for ignorable error settings
 */
export interface IgnorableErrorWSettingsProperties extends BaseProtectionItemProperties {
  /**
   * True if the component is to be used as a standalone component.
   */
  standalone: boolean;
}
@Component({
  selector: 'coh-settings-list-ignorable-errors',
  templateUrl: './settings-list-ignorable-errors.component.html',
})
export class SettingsListIgnorableErrorsComponent extends BaseProtectionBuilderComponent<
  string[],
  IgnorableErrorWSettingsProperties
> {
  /**
   * Internal FormGroup for managing time and timezone values.
   */
  private ignorableErrorsFormGroup = new UntypedFormControl([]);

  /**
   * Possible options for selection.
   */
  errorOptions: IgnorableErrorTypes = [ErrorTypes.kEOF, ErrorTypes.kNonExistent];

  /**
   * Returns the list of values with translate key added
   */
  getFormValue(): string[] {
    return this.formControl.value.map(value => `enum.error.${value}`);
  }

  /**
   * Override of addFormControl from BaseProtectionBuilderComponent so this component's FormControl isn't required.
   */
  addFormControl() {
    if (this.properties?.standalone) {
      this.setDefaultValues(this.formControl.value);
    }
    this.formGroup.addControl(this.name, this.ignorableErrorsFormGroup);
  }

  /**
   * Form control init.
   */
  initFormControl() {
    if (this.protectionGroup?.id) {
      this.setDefaultValues(this.protectionGroup.ignorableErrors || []);
    }
  }

  /**
   * Set the default values to the form.
   *
   * @param param ignorable error list
   */
  setDefaultValues(ignorableErrors: string[]) {
    this.ignorableErrorsFormGroup.setValue(ignorableErrors);
  }

  /**
   * Handles the selection of errors to be ignored for protection group.
   *
   * @param event  Mat Checkbox change event
   * @param option The selected option.
   */
  handleErrorSelection(event: MatCheckboxChange, option: string) {
    let errorList = this.formControl.value || [];
    if (event.checked) {
      errorList.push(option);
    } else {
      errorList = errorList.filter(error => error !== option);
    }

    this.formControl.setValue(errorList);
    this.formControl.updateValueAndValidity();
  }
}
