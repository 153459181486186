import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { ProtectionJob, ProtectionSourceNode } from '@cohesity/api/v1';
import {
  DataTreeControl,
  DataTreeSelection,
  DataTreeSelectionModel,
  DataTreeSource,
  DataTreeTransformer,
} from '@cohesity/helix';
import {
  ExcludeFilter,
  ExcludeOptions,
  SourceSelection,
  SourceTreeFilters,
  SourceTreeService,
  TagCategory,
} from '@cohesity/iris-source-tree';
import { difference, isEqual } from 'lodash';
import { Observable, of } from 'rxjs';

import { Environment, SourceKeys } from '../../../constants';
import { ProtectionSourceDataNode } from './protection-source-data-node';
import { ProtectionSourceDataTransformer } from './protection-source-data-transformer';
import { ProtectionSourceDetailComponent } from './protection-source-detail/protection-source-detail.component';
import { ProtectionSourceFilterOptions } from './protection-source-filter-options';
import { ProtectionSourceMetadataComponent } from './protection-source-metadata/protection-source-metadata.component';

/**
 * A source tree service for Protection Source Nodes. This should be extended for each adapter type
 * The base class provides common implementations that work for most nodes.
 */
@Injectable()
export abstract class BaseProtectionSourceService<T extends ProtectionSourceDataNode>
  implements SourceTreeService<ProtectionSourceNode, T, ProtectionJob> {
  /**
   * The Protection Job object which is needed by some adapters for tree logic.
   */
  job: ProtectionJob;

  /**
   * The dataTreeSelectionModel in use.
   */
  dataTreeSelection: DataTreeSelectionModel<T>;

  /**
   * The previous selection (used for comparison & delta detection).
   */
  previousSelection: DataTreeSelection<T>;

  /**
   * Missing Objects cache to be used in async mode. Keeps track of objects which
   * are already loaded to avoid loading the objects again.
   */
  missingObjectsCache = {};

  /**
   * Exclusion filters to exclude objects from auto protected hosts
   */
  excludeFilters: ExcludeFilter[];

  /**
   * Environment of protection source.
   */
  readonly environment: Environment;

  /**
   * The data tree source.
   */
  readonly dataTreeSource: DataTreeSource<ProtectionSourceNode>;

  /**
   * Read only access to the tree control.
   */
  readonly treeControl: DataTreeControl<T>;

  /**
   * Read only access to the filters. This contains the current state of applied filter and their
   * available options
   */
  readonly filters: SourceTreeFilters<T>;

  /**
   * Filter options provides the sepcific filter implementation for protection source nodes.
   */
  filterOptions: ProtectionSourceFilterOptions<T>;

  /**
   * Read only access to the tree transformer. This is used by the data source to convert
   * the api response into a format that the tree can display.
   */
  readonly treeTransformer: DataTreeTransformer<ProtectionSourceNode>;

  /**
   * The component to render for a tree's details. This can be overridden by a different service to
   * render a different tree component.
   */
  detailComponents: ComponentType<any>[] = [ProtectionSourceDetailComponent, ProtectionSourceMetadataComponent];

  /**
   * In most cases, this should be false, but for environments which use special params as part of the
   * protection group config, this should be set to true in order to avoid having them show in the
   * slection summaries.
   */
  hideSpecialParamsInSummary = false;

  /**
   * Gets the last refreshed time for the protection source. This assumes that the data property is an
   * array with one item, containing the refresh time. If this is not present, the source is not refreshable
   * and it will return undefined.
   */
  get lastRefreshedUsecs(): number {
    return this.dataTreeSource.data && this.dataTreeSource.data[0]?.registrationInfo?.refreshTimeUsecs;
  }

  constructor(public canOverwriteObjectProtection = false) {
    this.treeTransformer = new ProtectionSourceDataTransformer(this.transformData.bind(this));
    this.treeControl = new DataTreeControl<T>(this.treeTransformer.getLevel, this.treeTransformer.getExpandable);
    this.dataTreeSource = new DataTreeSource(this.treeTransformer, this.treeControl);
    this.filters = new SourceTreeFilters(this.treeControl);
    this.filterOptions = new ProtectionSourceFilterOptions(this.filters, this.treeControl);
  }

  /**
   * Handles filter exclusions when job is loaded or selection is modified.
   * This is done at adapter specific handlers.
   *
   * @param   filters    Exclude filters applied on the source tree
   * @param   regionId   Dms Region Id
   */
  handleFilterExclusions(_filters: ExcludeFilter[], _regionId: string) {
    return undefined;
  }

  /**
   * Applies the exclusions on the source tree nodes.
   *
   * @param   nodeIds   Node ids which are to be excluded
   */
  applyExclusions(_nodeIds: number[]) {
    return undefined;
  }

  /**
   * Returns the name of a node.
   *
   * @param   node The node.
   * @returns The nodes name.
   */
  getName(node: T): string {
    return node.name;
  }

  /**
   * Returns exclude options
   *
   * @param excludeFilters exclude filters
   * @returns exclude options
   */
  getExcludeOptions(_excludeFilters: ExcludeFilter[]): ExcludeOptions {
    return undefined;
  }

  /**
   * Gets a component to render for a source's special parameters. This does not apply to every node
   * and can be null for certain data types. This returns null by default, but can be overridden to
   * provide source-specific configuration options.
   *
   * @param   node   The node to get an options component for.
   * @return  The options component to render.
   */
  getSpecialParametersComponent(_node: T): ComponentType<any> {
    return undefined;
  }

  /**
   * Parse a list of tree nodes to determine available tags that can be filtered by.
   *
   * @param   allNodes   The complete list of nodes.
   * @param   selection  The current selection.
   */
  getTagCategories(_allNodes: T[], _selection: DataTreeSelection<T>): TagCategory[] {
    return [];
  }

  /**
   * Convert the data tree selection model to the job selection model.
   *
   * @param   selection   The selection from the tree.
   * @return  The job selection info.
   */
  transformFromDataTreeSelection(selection: DataTreeSelection<T>): SourceSelection {
    const sourceIds = selection.selected
      .filter(item => !item.expandable)
      .concat(selection.autoSelected)
      .map(item => Number(item.id));
    return {
      excludeSourceIds: selection.excluded.map(item => Number(item.id)),
      sourceIds: sourceIds,
      sourceSpecialParameters: Object.values(selection.options || {}).filter(Boolean),
    };
  }

  /**
   * Convert source selection to the data tree selection model.
   * source ids can be either selected items or auto selected items, nodes with children are
   * assumed to be auto selected. Nodes can be in the tree multiple times, and should not be
   * duplicated in the selection.
   *
   * @param   allNodes         The unfiltered list of tree nodes.
   * @param   sourceSelection  The job selection.
   * @return  A data tree selection model.
   */
  transformToDataTreeSelection(allNodes: T[], sourceSelection: SourceSelection): DataTreeSelection<T> {
    const sourceMap: any = {};
    const selection: DataTreeSelection<T> = {
      autoSelected: [],
      excluded: [],
      selected: [],
      options: {},
    };

    if (!sourceSelection) {
      return selection;
    }

    const nodesLength = allNodes.length;
    for (let i = 0; i < nodesLength; i++) {
      const node = allNodes[i];

      if ((sourceSelection.sourceIds || []).includes(Number(node.id)) && !sourceMap[node.id]) {
        sourceMap[node.id] = node;
        if (node.canAutoSelect(null)) {
          selection.autoSelected.push(node);
        } else {
          selection.selected.push(node);
        }
      } else if ((sourceSelection.excludeSourceIds || []).includes(Number(node.id)) && !sourceMap[node.id]) {
        sourceMap[node.id] = node;
        selection.excluded.push(node);
      }
    }

    if (sourceSelection.sourceSpecialParameters) {
      selection.options = {};
      sourceSelection.sourceSpecialParameters.forEach(params => {
        selection.options[params.sourceId] = params;
      });
    }
    return selection;
  }

  /**
   * Check for kRootContainers in the api response and unwrap them so they are hidden in the source
   * tree.
   *
   * @param   nodes   The top level protection source nodes
   * @return  Either the same list or nodes, or the root container child nodes.
   */
  unwrapRootContainers(nodes: ProtectionSourceNode[]): ProtectionSourceNode[] {
    return nodes.reduce((mappedNodes, node) => {
      const sourceKey = SourceKeys[node.protectionSource.environment];
      const envSource = node.protectionSource[sourceKey];
      if (envSource.type === 'kRootContainer') {
        mappedNodes.push(...(node.nodes || []));
      } else {
        mappedNodes.push(node);
      }
      return mappedNodes;
    }, []);
  }

  /**
   * This callback gets called for each node when the tree is first loaded.
   * By default, the tree expands every node in the tree. This can be overridden to prevent
   * expanding the entire tree on load.
   *
   * @param   treeNode   The treenode to check.
   * @return  True if the node should be expanded by default.
   */
  shouldExpandNodeOnLoad(_treeNode: T): boolean {
    return true;
  }

  /**
   * This should be implemented separately for each adapter to provide adapater-specific configuration.
   *
   * @param   sourceNode  The source node.
   * @param   level       The node's deptch within the tree.
   * @return  A transformed data tree node.
   */
  abstract transformData(sourceNode: ProtectionSourceNode, level: number): T;

  /**
   * The subscribe handler for selection changes.
   *
   * @param   selection   The current selection.
   */
  selectionChangeHandler(selection: DataTreeSelection<T>): any {
    return (this.previousSelection = selection);
  }

  /**
   * Runs a check to find descendants of an auto protected node that already have object protection applied to them
   *
   * @param autoSelected The auto selected node to check
   * @returns A list of auto protected nodes.
   */
  getAlreadyObjectProtectedDescendants(autoSelected: T) {
    if (!this.dataTreeSelection.isAutoSelected(autoSelected)) {
      return;
    }

    // Look for object-protected nodes which are children of this node, but protected by something else
    return this.treeControl.allDataNodes.filter(node =>
      node.isObjectProtected &&
      node.parentAutoProtectedObjectId !== autoSelected.id &&
      this.dataTreeSelection.isAutoSelectedByAncestor(node, autoSelected)
    );
  }

  /**
   * Gets a list of already protected objects that we would need to remove protection for in order to apply
   * the new protection settings
   *
   * @param editingObjectId  The object id for the spec we were initially editing.
   * @returns A list of objects that need to be unprotected in order to protect the current selection.
   */
  getAlreadyProtectedObjects(editingObjectId?: number): T[] {
    const nodeMap = new Map<string | number, T>();

    if (!this.canOverwriteObjectProtection) {
      return [];
    }

    this.treeControl.allDataNodes.forEach(node => {
      const inCurrentSelection =
        // Direct selection of a leaf node
        (node.isLeaf && this.dataTreeSelection.isSelected(node)) ||

        // Direct auto selection
        this.dataTreeSelection.isAutoSelected(node) ||

        // Ancestor is Auto protected
        (
          !this.dataTreeSelection.isExcluded(node) &&
          !this.dataTreeSelection.isAncestorExcluded(node) &&
          this.dataTreeSelection.isAncestorAutoSelected(node)
        );

      // Find objects which are object protected, except when we are editing, and they are children of the current
      // object being edting.
      if (inCurrentSelection && node.isObjectProtected &&
        (!node.isAutoProtectedByObject || node.parentAutoProtectedObjectId !== editingObjectId)) {
        nodeMap.set(node.id, node);
      }
    });
    return [...nodeMap.values()].filter(node =>
      // Don't include the editing object if we are in edit mode.
      (node.id !== editingObjectId) &&

      // If a node's parent is already in the map, then we don't need to show it here.
      !nodeMap.has(node.parentAutoProtectedObjectId)
    );
  }

  /**
   * Determines if the selection has changed or not.
   * TODO: This needs more work/UTs
   *
   * @param    selection   The current selection.
   * @returns  True if the selection has changed from the previous.
   */
  hasSelectionChanged(selection: DataTreeSelection<T>): boolean {
    return !isEqual(selection, this.previousSelection);
  }

  /**
   * Gets the deltas between the previous selection and the current.
   * TODO: This needs more work/UTs
   *
   * @param     selection   The current selection.
   * @returns   The deltas between current and previous selections.
   */
  getSelectionDeltas(selection: DataTreeSelection<T>): DataTreeSelection<T> {
    const delta: DataTreeSelection<T> = {
      autoSelected: [],
      excluded: [],
      options: {},
      selected: [],
    };

    if (!this.previousSelection) {
      return selection;
    }

    for (const property in selection) {
      if (Object.prototype.hasOwnProperty.call(selection, property) && Array.isArray(selection[property])) {
        delta[property] = difference(selection[property], this.previousSelection[property]);
      }
    }

    return delta;
  }

  /**
   * Loads missing objects if the source tree is working in an async mode. By
   * default returns empty observable as the source tree is loaded entirely.
   *
   * @param     allNodes          List of all data tree source nodes.
   * @param     sourceSelection   Data tree selection.
   * @returns   Observable indicating that the source tree has now been loaded
   */
  loadMissingObjects(_allNodes: T[], _sourceSelection: SourceSelection): Observable<any> {
    return of(null);
  }

  /**
   * abstract method which needs to be overridden by an adapter's source tree service.
   */
  clearSelection() {}
}
