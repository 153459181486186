import { ProtectionSourceNode } from '@cohesity/api/v1';
import { DataTreeSelection } from '@cohesity/helix';
import { IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { Environment } from 'src/app/shared/constants';

import { PhysicalSourceDataNode } from '../physical-source-data-node';

/**
 * Represents an Physical files source node and job tree selection behavior.
 */
export class PhysicalFilesSourceDataNode extends PhysicalSourceDataNode {
  /**
   * This indicates whether the node is part of the job currently being edited or viewed.
   */
  inCurrentJob = false;

  constructor(data: ProtectionSourceNode, readonly level: number, private irisContextService: IrisContextService) {
    super(Environment.kPhysicalFiles , data, level);
  }

  /**
   * Gets volume mount types on the node.
   */
  get volumeMountTypes(): string[] {
    // Get different type of mount types present on source.
    const mountTypes: string[] = (this.envSource.volumes || []).map(item => item.mountType).filter(Boolean);
    return [...new Set(mountTypes)];
  }

  /**
   * Checks if the node can be selected. If the host type of the node matches
   * with the host type in the selected nodes, then the node can be selected.
   * For DMaaS, protected node cannot be selected, a single node can be selected
   * at a time.
   *
   * @param    currentSelection   Current Selection
   * @return   Returns true if node can be selected, false otherwise
   */
  canSelect(currentSelection: DataTreeSelection<any>): boolean {
    // Todo (Geetha) Update DMS conditions once multi server protection implemented
    if (isDmsScope(this.irisContextService.irisContext)) {
      if (this.protected && !this.inCurrentJob) {
        return false;
      } else if (currentSelection.selected[0]) {
        return currentSelection.selected[0].data.protectionSource.id === this.data.protectionSource.id;
      }
      return true;
    } else if (currentSelection.selected[0]) {
      const selectedHostType = currentSelection.selected[0].nodeHostType;
      return selectedHostType === this.nodeHostType;
    } else {
      return this.type !== 'kGroup';
    }
  }

  /**
   * Determines whether the agent installed  is upgradable or not.
   */
  get isUpgradable(): boolean {
    return this.envSource?.agents?.[0]?.upgradability === 'kUpgradable' && !this.isAgentUpgrading
      && this.upgradeStatus !== 'kScheduled';
  }

  /**
   * Determines the upgrade status of the agent.
   */
  get upgradeStatus(): string {
    return this.envSource?.agents?.[0]?.upgradeStatus;
  }

  /**
   * Determines whether the agent upgrade is inprogress.
   */
  get isAgentUpgrading(): boolean {
    return ['kAccepted', 'kStarted'].includes(this.upgradeStatus);
  }

  /**
   * Return the upgrade status message of the agent.
   */
  get upgradeAgentError(): string {
    return this.envSource?.agents?.[0]?.upgradeStatusMessage;
  }
}
