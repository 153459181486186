import { Component } from '@angular/core';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { PopoverRef } from '@cohesity/helix';

import { AzureBackupType, SourceKeys } from '../../constants';

export interface AzureSqlPopoverData {
  nodeContext: ProtectionSourceNode;
}

@Component({
  selector: 'coh-azure-sql-popover',
  templateUrl: './azure-sql-popover.component.html',
  styleUrls: ['./azure-sql-popover.component.scss'],
})
export class AzureSqlPopoverComponent {
  /**
   * Access to the current node property.
   */
  get node(): ProtectionSourceNode {
    return this.popoverRef.data;
  }

  /**
   * Access to the Azure SQL Protection Source object.
   */
  get azureSqlSource(): any {
    const key = SourceKeys[this.node.protectionSource.environment];
    return this.node.protectionSource[key];
  }

  /**
   * Returns sum of the protected and unprotected logical sizes.
   */
  get totalLogicalSize(): number {
    return this.node.protectedSourcesSummary?.[0]?.totalLogicalSize +
      this.node.unprotectedSourcesSummary?.[0]?.totalLogicalSize;
  }

  /**
   * Whether node is protected or not
   */
  isNodeProtected: boolean;

  /**
   * Constructor.
   */
  constructor(
    private popoverRef: PopoverRef<ProtectionSourceNode>
  ) {
    const protectionSummary = this.node.protectedSourcesSummary;

    this.isNodeProtected = !!(protectionSummary &&
      protectionSummary[0].leavesCount && protectionSummary[0].leavesCount > 0);

    if (!this.popoverRef || !this.popoverRef.data) {
      throw new Error('Popover data is not defined');
    }
  }

  /**
   * Returns whether Azure SQL node has popover or not based on node specific logic
   *
   * @param    node Protection source node
   * @returns  true/false whether node has popover or not
   */
  static hasPopover(node: ProtectionSourceNode): boolean {
    const key = SourceKeys[node.protectionSource.environment];

    return [
      AzureBackupType.kSQLDatabase,
      AzureBackupType.kSQLServer,
      AzureBackupType.kSQLManagedInstance,
    ].includes(node.protectionSource[key].type);
  }
}
