<div class="app-breadcrumb-container cog-h6 margin-bottom-xs" *ngIf="show">
  <div *ngFor="let breadcrumb of breadcrumbs; let last = last">
    <ng-container *ngIf="!sameState(breadcrumb, last)">
      <a
        *ngIf="uiRouterGlobals"
        [cogDataId]="'app-breadcrumb-' + breadcrumb.title"
        class="app-breadcrumb-item"
        [uiSref]="breadcrumb.name"
        [uiParams]="breadcrumb.params">{{ breadcrumb.title | translate }}</a>
      <a
        *ngIf="!uiRouterGlobals"
        [cogDataId]="'app-breadcrumb-' + breadcrumb.title"
        class="app-breadcrumb-item"
        href="{{ breadcrumb.href }}">{{ breadcrumb.title | translate }}</a>
    </ng-container>
    <span *ngIf="sameState(breadcrumb, last)">{{ breadcrumb.title | translate }}</span>
    <span *ngIf="!last" class="margin-right-xs margin-left-xs">/</span>
  </div>
</div>
