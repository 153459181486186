<coh-source-tree-controls
  *ngIf="treeService"
  (refreshSourceTree)="refreshSourceTree.emit()"
  [showRefreshSourceTree]="false"
  (expandTo)="expandToNodes($event)"
  [filters]="filters"
>
</coh-source-tree-controls>

<!--
  Use a static tree for the tree's header columns so that it can remain 'sticky' and not
  interfere with the table's virtual scroll.
 -->
<mat-table>
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef="let ctx">
      {{ 'object' | translate }}
    </mat-header-cell>
  </ng-container>

  <ng-container matColumnDef="detail">
    <mat-header-cell *matHeaderCellDef="let ctx">
      {{ 'details' | translate }}
    </mat-header-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef="let ctx"></mat-header-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="['name', 'detail', 'actions']"></mat-header-row>
</mat-table>

<cog-data-tree-table
  *ngIf="treeService"
  [data]="dataTreeSource"
  [treeControl]="treeService.treeControl"
  [treeSelection]="dataTreeSelection"
>
  <mat-table>
    <ng-container matColumnDef="name">
      <mat-cell *matCellDef="let ctx">
        <cog-data-tree-table-row-expand [context]="ctx"></cog-data-tree-table-row-expand>
        <ng-container *ngFor="let detail of treeService.detailComponents.slice(0, 1)">
          <cog-data-tree-detail-outlet [component]="detail" [nodeContext]="ctx"> </cog-data-tree-detail-outlet>
        </ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="detail">
      <mat-cell *matCellDef="let ctx">
        <ng-container *ngFor="let detail of treeService.detailComponents.slice(1)">
          <cog-data-tree-detail-outlet class="no-margin" [component]="detail" [nodeContext]="ctx">
          </cog-data-tree-detail-outlet>
        </ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-cell *matCellDef="let ctx">
        <ng-container *ngIf="treeService.getContextMenuItems && treeService.getContextMenuItems(ctx.node) as menuItems">
          <coh-nav-item-menu
            [ariaLabel]="'objectActionsMenu' | translate"
            cogDataId="source-tree-context-menu-{{ ctx.node.name }}"
            *ngIf="menuItems.length"
            [navItems]="menuItems"
          >
          </coh-nav-item-menu>
        </ng-container>
      </mat-cell>
    </ng-container>

    <mat-row *matRowDef="let row; columns: ['name', 'detail', 'actions']"></mat-row>
  </mat-table>
</cog-data-tree-table>
