import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { NoWhitespaceValidator } from '@cohesity/shared-forms';
import {
  ArrayPropertyKey,
  ArrayPropertyValue,
  Controls,
  NgxFormWithArrayControls,
  NgxSubFormRemapComponent,
  subformComponentProviders,
  takeUntilDestroyed,
} from 'ngx-sub-form';

import { EntityTypeToIconMap } from '../../../model/nosql-common';
import { NoSqlObjectRenameOption } from '../../../model/nosql-form-options';
import { NoSqlSearchResult } from '../../../model/nosql-search-results';
import { RestorePointSelection } from '../../../model/restore-point-selection';
import { NoSqlService } from '../../../services/nosql.services';
import { ObjectRenameForm } from './recovery-rename.model';

/**
 * Settings list item to display object rename option.
 */
@Component({
  selector: 'coh-settings-list-object-rename',
  templateUrl: './settings-list-object-rename.component.html',
  styleUrls: ['./settings-list-object-rename.component.scss'],
  providers: subformComponentProviders(SettingsListObjectRenameComponent),
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsListObjectRenameComponent
  extends NgxSubFormRemapComponent<NoSqlObjectRenameOption, ObjectRenameForm>
  implements NgxFormWithArrayControls<ObjectRenameForm>, OnChanges, OnInit {

  constructor(
    private noSqlService: NoSqlService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  /**
   * The selected recovery points to show details for.
   */
  @Input() objects: RestorePointSelection[] = [];

  /**
   * Enable Individual rename option.
   */
  @Input() enableIndividualRename = true;

  /**
   * NoSql selected objects.
   */
  private selectedObjects: NoSqlSearchResult[];

  /**
   * Listen for changes to the inputs and outputs and update the component
   * bindings if necessary.
   *
   * @param   changes   sumary of changed properties on the component.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.objects && changes.objects.currentValue) {
      this.selectedObjects = this.noSqlService.getNoSqlSelectedObjects(this.objects);
      if (this.selectedObjects.length > 0) {
        this.createForm();
      }
    }
    // Select bulk rename option if Individual Rename option is disabled
    if (changes.enableIndividualRename && !changes.enableIndividualRename.currentValue) {
      this.formGroupControls.bulkRename.setValue(true);
    }
  }

  ngOnInit() {
    // Re-initialise objectDetails formArray.
    this.formGroupControls.bulkRename.valueChanges
    .pipe(takeUntilDestroyed(this))
    .subscribe(() => {
      this.formGroupControls.suffix.reset();
      this.createForm();
    });
  }

  /**
   * Create objects rename option form.
   */
  createForm() {
    this.formGroupControls.objectDetails.clear();
    this.selectedObjects.map(object => {
      this.formGroupControls.objectDetails.push(
        this.createFormArrayControl('objectDetails', {
          name: object.name,
          renameTo: '',
          icon: EntityTypeToIconMap[object.environment][object.objectType],
        }),
      );
    });
    this.cdr.detectChanges();
  }

  protected transformToFormGroup(apiModel: NoSqlObjectRenameOption,
    defaults: Partial<ObjectRenameForm>): ObjectRenameForm {
    if (apiModel === null) {
      return defaults as ObjectRenameForm;
    }
    return {
      bulkRename: !!apiModel.suffix,
      suffix: apiModel.suffix,
      objectDetails: apiModel.objectDetails.map((item) => ({
        name: item.name,
        renameTo: item.renameTo
      }))
    };
  }

  protected transformFromFormGroup(form: ObjectRenameForm): NoSqlObjectRenameOption {
    return {
      suffix: form.suffix,
      objectDetails: form.objectDetails,
    };
  }

  getDefaultValues(): Partial<ObjectRenameForm> {
    return {
      suffix: '',
      bulkRename: true,
      objectDetails: []
    };
  }

  protected getFormControls(): Controls<ObjectRenameForm> {
    return {
      suffix: new UntypedFormControl(null, NoWhitespaceValidator),
      objectDetails: new UntypedFormArray([]),
      bulkRename: new UntypedFormControl(null)
    };
  }

  public createFormArrayControl(
    key: ArrayPropertyKey<ObjectRenameForm> | undefined,
    value: ArrayPropertyValue<ObjectRenameForm>): UntypedFormControl {
    return new UntypedFormControl(value);
  }
}
