/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TransferTimeConfigurations } from '../models/transfer-time-configurations';
import { TransferTimeConfiguration } from '../models/transfer-time-configuration';
@Injectable({
  providedIn: 'root',
})
class FortKnoxService extends __BaseService {
  static readonly GetFortknoxTransferTimeConfigPath = '/mcm/fortknox/vaults/transfer-time-configs';
  static readonly UpdateFortknoxTransferTimeConfigPath = '/mcm/fortknox/vaults/transfer-time-configs';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get transfer time configurations.
   *
   * Get data transfer time configurations of FortKnox vaults. These are backup data transfer configurations of FortKnox vaults.
   * @param params The `FortKnoxService.GetFortknoxTransferTimeConfigParams` containing the following parameters:
   *
   * - `regionIds`: This field is deprecated. Please use globalVaultIds field instead. List of FortKnox region IDs.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `globalVaultIds`: List of FortKnox Global vault IDs.
   *
   * @return Success
   */
  GetFortknoxTransferTimeConfigResponse(params: FortKnoxService.GetFortknoxTransferTimeConfigParams): __Observable<__StrictHttpResponse<TransferTimeConfigurations>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.regionIds || []).forEach(val => {if (val != null) __params = __params.append('regionIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.globalVaultIds || []).forEach(val => {if (val != null) __params = __params.append('globalVaultIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/fortknox/vaults/transfer-time-configs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TransferTimeConfigurations>;
      })
    );
  }
  /**
   * Get transfer time configurations.
   *
   * Get data transfer time configurations of FortKnox vaults. These are backup data transfer configurations of FortKnox vaults.
   * @param params The `FortKnoxService.GetFortknoxTransferTimeConfigParams` containing the following parameters:
   *
   * - `regionIds`: This field is deprecated. Please use globalVaultIds field instead. List of FortKnox region IDs.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `globalVaultIds`: List of FortKnox Global vault IDs.
   *
   * @return Success
   */
  GetFortknoxTransferTimeConfig(params: FortKnoxService.GetFortknoxTransferTimeConfigParams): __Observable<TransferTimeConfigurations> {
    return this.GetFortknoxTransferTimeConfigResponse(params).pipe(
      __map(_r => _r.body as TransferTimeConfigurations)
    );
  }

  /**
   * Update transfer time configuration.
   *
   * Update data transfer time configuration of a FortKnox vault. Backups to FortKnox vaults are done during transfer times. These configurations apply only for backups.
   * @param body Parameters to update transfer time configuraion of a FortKnox vault.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  UpdateFortknoxTransferTimeConfigResponse(body: TransferTimeConfiguration,
    regionId?: string): __Observable<__StrictHttpResponse<TransferTimeConfiguration>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/mcm/fortknox/vaults/transfer-time-configs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TransferTimeConfiguration>;
      })
    );
  }
  /**
   * Update transfer time configuration.
   *
   * Update data transfer time configuration of a FortKnox vault. Backups to FortKnox vaults are done during transfer times. These configurations apply only for backups.
   * @param body Parameters to update transfer time configuraion of a FortKnox vault.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  UpdateFortknoxTransferTimeConfig(body: TransferTimeConfiguration,
    regionId?: string): __Observable<TransferTimeConfiguration> {
    return this.UpdateFortknoxTransferTimeConfigResponse(body, regionId).pipe(
      __map(_r => _r.body as TransferTimeConfiguration)
    );
  }
}

module FortKnoxService {

  /**
   * Parameters for GetFortknoxTransferTimeConfig
   */
  export interface GetFortknoxTransferTimeConfigParams {

    /**
     * This field is deprecated. Please use globalVaultIds field instead. List of FortKnox region IDs.
     */
    regionIds?: null | Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * List of FortKnox Global vault IDs.
     */
    globalVaultIds?: null | Array<string>;
  }
}

export { FortKnoxService }
