/**
 * Snapshot Info object from snpashot ID.
 */
export interface SnapshotObject {
  clusterId: number;
  clusterIncarnationId: number;
  jobId: number;
  jobInstanceId: number;
  runStartTimeUsecs: number;
  objectId: number;
}

/**
 * Get snapshot detail from snapshot ID.
 *
 * @param snapshotId  Snapshot ID string.
 * @param snapshot object.
 */
export function getSnapshotObject(snapshotId: string): SnapshotObject {
  if (snapshotId) {
    try {
      const {
        a_clusterId: clusterId,
        b_clusterIncarnationId: clusterIncarnationId,
        c_jobId: jobId,
        e_jobInstanceId: jobInstanceId,
        f_runStartTimeUsecs: runStartTimeUsecs,
        g_objectId: objectId,
      } = JSON.parse(atob(snapshotId)) || {};

      return {clusterId, clusterIncarnationId, jobId, jobInstanceId, runStartTimeUsecs, objectId};
    } catch {
      return null;
    }
  }
}
