import { Component } from '@angular/core';
import { PopoverRef } from '@cohesity/helix';
import { PasswordRulesPopoverData } from '../../models';

@Component({
  selector: 'coh-password-rules-popover',
  templateUrl: './password-rules-popover.component.html',
  styleUrls: ['./password-rules-popover.component.scss'],
})
export class PasswordRulesPopoverComponent {
  constructor(public popoverRef: PopoverRef<PasswordRulesPopoverData>) { }
}
