import { Directive, Input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';

/**
 * @description
 * Structural directive to conditionally add/remove column to/from a table.
 *
 * @example
 *    <ng-container *cohToggleColumn="columns; pos 2; name 'organization';if hasOrgViewPrivilege"
 *      matColumnDef="organization">
 *      <th mat-header-cell *matHeaderCellDef>Organization</th>
 *      <td mat-cell *matCellDef="let policy">{{policy.tenantIds}}</td>
 *    </ng-container>
 */
@Directive({
  selector: '[cohToggleColumn]'
})
export class ToggleColumnDirective implements OnChanges {

  /**
   * Specifies the table column def list.
   */
  @Input('cohToggleColumn') columns: string[];

  /**
   * Specifies the position of the column to be added.
   * Default to the first column.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('cohToggleColumnPos') colPos = 0;

  /**
   * Specifies the column name.
   */
  @Input('cohToggleColumnName') name = '';

  /**
   * Specifies whether table should show the column.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('cohToggleColumnIf') enabled = true;

  constructor(
    private viewContainer: ViewContainerRef,
    private template: TemplateRef<any>,
  ) { }

  ngOnChanges() {
    // Only render the view if enabled is true.
    if (this.enabled) {
      // Adding new column to the table displayed columns.
      if (this.columns && !this.columns.includes(this.name)) {
        this.columns.splice(this.colPos, 0, this.name);
      }
      this.viewContainer.createEmbeddedView(this.template);
    } else {
      this.viewContainer.clear();
    }
  }
}
