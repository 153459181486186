import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

/**
 * @description
 * Angular Material slider component with input showing value of the slider with
 * a suffix unit that can be added to the value
 *
 * @example
 *   <!-- Material Slider with input and unit suffix -->
 *   <coh-range-slider
 *     class="col-xs-6"
 *     [control]="control"
 *     [label]="label"
 *     [min]="1"
 *     [max]="100"
 *     suffix="%">
 *   </coh-range-slider>
 */
@Component({
  selector: 'coh-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss']
})
export class RangeSliderComponent {
  /**
   * Label for slider
   */
  @Input() label: string;

  /**
   * Upper limit for slider
   */
  @Input() max = 100;

  /**
   * Lower limit for slider
   */
  @Input() min = 1;

  /**
   * Suffix as unit indicator
   */
  @Input() suffix: string;

  /**
   * Form control object
   */
  @Input() control: UntypedFormControl;
}
