import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CollectionViewer, ListRange } from '@angular/cdk/collections';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

import { HelixIntlService } from '../../../helix-intl.service';
import { DataTreeFilterUtils } from '../shared/data-tree-filter-utils';
import { DataTreeControl } from './../shared/data-tree-control';
import { DataTreeSource, DataTreeFilter } from './../shared/data-tree-source';
import { DataTreeNode } from './../shared/data-tree.model';

/**
 * Data tree auto complete select field.
 *
 * @example
 * <cog-data-tree-select [multiSelect]="false"
 *  [formControl]="singleSelect"
 *  [nameFn]="nameFn"
 *  [iconFn]="iconFn"
 *  [canSelectFn]="canSelectFn"
 *  [data]="dataTreeSource"
 *  label="Single Select">
 * </cog-data-tree-select>
 */
@Component({
  selector: 'cog-data-tree-select',
  templateUrl: './data-tree-select.component.html',
  styleUrls: ['./data-tree-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: DataTreeSelectComponent,
    },
  ],
})
export class DataTreeSelectComponent<T extends DataTreeNode<any>>
implements MatFormFieldControl<T | T[]>, ControlValueAccessor, AfterViewInit, OnDestroy, OnInit, CollectionViewer {
  /**
   * Unique ID to use for MatFormFieldControl implementation.
   */
  static nextId = 0;

  /**
   * All of the nodes currently shown in the tree.
   */
  allNodes: DataTreeNode<T>[] = [];

  /**
   * Control type for MatFormFieldControl
   */
  controlType = 'data-tree-select';

  /**
   * Private property for filters
   */
  private _filters: DataTreeFilter<T>[] = [];

  /**
   * Sets filters to apply to the data source. These filters are applied on the flat list
   * after it has been transformed, but before the search filters have been applied.
   */
  @Input() set filters(filters: DataTreeFilter<T>[]) {
    this.cleanupSubscriptions();
    this._filters = filters || [];
    this.setupSubscriptions();
  }

  /**
   * Gets the filters currently applied to the data source.
   */
  get filters(): DataTreeFilter<T>[] {
    return this._filters;
  }

  /**
   * Private property for data source
   */
  private _data: DataTreeSource<T>;
  /**
   * The data source for the tree.
   */
  @Input() set data(data: DataTreeSource<T>) {
    this.cleanupSubscriptions();
    this._data = data;
    this.setupSubscriptions();
  }

  /**
   * Retrieves the data property.
   */
  get data(): DataTreeSource<T> {
    return this._data;
  }

  /**
   * Data stream with the view of the tree
   */
  dataStream$: Observable<DataTreeNode<T>[]>;

  /**
   * Subscription for the node list to be cleaned up on destroy.
   */
  dataSub: Subscription;

  /**
   * List of Ids used for aria-described by property, separated by a space.
   */
  @HostBinding('attr.aria-describedby') describedBy = '';

  /**
   * A detail template to use to render the selected node.
   */
  @Input() detailTemplate: TemplateRef<any>;

  /**
   * A label to show in the dropdown when on items match the autocomplete search.
   */
  @Input() noResultsLabel: string;

  /**
   * A custom trigger template to use. If this is a single select, this will render the text for the dropdown.
   * If it is multiselect, the template will render inside of a mat-chip.
   */
  @Input() triggerTemplate: TemplateRef<any>;

  /**
   * Which size to use (large means mat select which needs two lines of text).
   */
  @Input() size: 'large' | 'medium' = 'medium';

  /**
   * Whether the MatFormFieldControl is disabled
   */
  private _disabled = false;

  /**
   * Set the disabled property for the MatFormFieldControl
   */
  set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled);
    this._disabled ? this.selectControl.disable() : this.selectControl.enable();
    this.cdr.markForCheck();
    this.stateChanges.next();
  }

  /**
   * Get the disabled property for the MatFormFieldControl
   */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  /**
   * MatFormFieldControl for error state. Currently unused.
   */
  get errorState(): boolean {
    if (!this.matSelect || !this.ngControl) {
      return false;
    }
    return this.matSelect.ngControl.touched && this.ngControl.invalid;
  }

  /**
   * Whether the MatFormFieldControl is currently empty.
   */
  get empty(): boolean {
    return !this.matSelect || this.matSelect.empty;
  }

  /**
   * Gets height of the virtual scroller. This needs to be calculated dynamically so that it can go and shrink
   * based on the number of results.
   */
  get scrollerHeight(): string {
    const count = this.allNodes.length;
    const height = Math.min(Math.max(1, count), 7);
    return `${height * 3}rem`;
  }

  /**
   * Whether the MatFormFieldControl is currently focused or not.
   */
  focused = false;

  /**
   * Hint text to show below the
   */
  @Input() hintText: string;

  /**
   * Default id for MatFormFieldControl implementation.
   */
  @HostBinding() id = `data-tree-select-${DataTreeSelectComponent.nextId++}`;

  /**
   * The mat form field label.
   */
  @Input() label: string;

  /**
   * Whether to allow multi select or not. This cannot be changed after the component
   * has been created.
   */
  @Input() multiSelect = false;

  /**
   * The input placeholder
   */
  private _placeholder: string;

  /**
   * Set the placeholder text.
   */
  @Input() set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }

  /**
   * Get the placeholder text.
   */
  get placeholder(): string {
    return this._placeholder;
  }

  /**
   * Whether the MatFormFieldControl is required or not.
   */
  private _required = false;

  /**
   * Set MatFormFieldControl required
   */
  @Input()
  set required(required) {
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }

  /**
   * Get MatFormFieldControl required
   */
  get required() {
    return this._required;
  }

  /**
   * A reference to the underlying mat select object.
   */
  @ViewChild(MatSelect, { static: false }) matSelect: MatSelect;

  /**
   * The data tree uses virtual scrolling to enable strong performance. This scroller emits changes whenever the
   * viewport has been modified.
   */
  @ViewChild(CdkVirtualScrollViewport, { static: false }) scroller: CdkVirtualScrollViewport;

  /**
   * The control for the node search input.
   */
  searchControl = new UntypedFormControl();

  /**
   * The control for mat-select, this drives the main form value.
   */
  selectControl = new UntypedFormControl();

  /**
   * When entered into the searchControl, these values trigger adding a node.
   */
  separatorKeysCodes: number[] = [ENTER, COMMA];

  /**
   * Whether the MatFormFieldControl label should float or not.
   */
  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  /**
   * MatFormField: notify of state change updates
   */
  stateChanges = new Subject<void>();

  /**
   * Get the tree control from the data source.
   */
  get treeControl(): DataTreeControl<any> {
    return this.data && this.data.treeControl;
  }

  /**
   * The current form control value
   */
  private _value: T | T[];
  /**
   * The current form control value
   *
   * @return   The current value of the form control
   */
  get value(): T | T[] {
    return this._value;
  }

  /**
   * Sets the form control value and propogates the change
   *
   * @param   value   The form control value to set
   */
  set value(value: T | T[]) {
    this._value = value;
    this.propagateChange(value);
    this.stateChanges.next();
  }

  /**
   * Collection viewer implementation
   */
  viewChange = new BehaviorSubject<ListRange>({ start: 0, end: Number.MAX_VALUE });

  /**
   * This is a bit of a hack to allow the multiSelect flag to work. mat-select can't change the
   * multiple selection after it has been created, so we set it behind an ngIf until
   * after initialized is set to true within ngOnInit. If you try to set multiSelect after
   * the component has been initialized, mat-select will through the expected errors.
   *
   */
  initialized = false;

  /**
   * Cleanup subscriptions
   */
  private destroy = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private elRef: ElementRef<HTMLElement>,
    private fm: FocusMonitor,
    readonly intl: HelixIntlService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin && !this.disabled;
      this.stateChanges.next();
    });
  }

  /**
   * Function to compare the option values with the selected values.
   *
   * @param   o1   The value from an option
   * @param   o2   The value from the selection
   * @returns   Whether the items are the same.
   */
  compareWith(o1: T, o2: T): boolean {
    return o1 && o2 && o1.id === o2.id;
  }

  ngOnInit() {
    // Set initialized to true so that the mat-select can render
    this.initialized = true;
  }

  ngAfterViewInit() {
    if (this.matSelect && this.scroller) {
      // The material autocomplete component does not support virtual scrolling.
      // The only way to make keyboard navigation work with scrolling the panel,
      // is to override the internal _setScrollTop mechanism and have it scroll
      // the vritual scroller instead.
      (this.matSelect as any)._scrollActiveOptionIntoView = () => {
        const activeItem = this.matSelect._keyManager.activeItem;
        const index = this.allNodes.indexOf(activeItem.value);
        this.scroller.scrollToIndex(Math.max(0, index - 3));
      };

      // Mat select clears and updates the selection any time the options change.
      // because this component uses virtual scrolling, the options change frequently
      // and may not include the selected item, causing the option to disappear from
      // the selection. This code overwrites matSelect._selectValue to compare against
      // _all_ of the available nodes instead of just the currently rendered ones.
      (this.matSelect as any)._selectOptionByValue = (value) => {
        // Check if there's an option in the current view first
        let correspondingOption = this.matSelect.options.find(option =>
          option.value != null && (this.matSelect as any)._compareWith(option.value, value)
        );

        // If not, check allDataNodes for the node and fake the option
        if (!correspondingOption) {
          const correspondingNode = (this.treeControl.allDataNodes || []).find(option =>
            option != null && (this.matSelect as any)._compareWith(option, value)
          );
          // Do not clear the selection if multiSelect is enabled as it clears off the checkbox selection
          // everytime we collapse and expand the root node toggle.
          if (correspondingNode && !this.multiSelect) {
            correspondingOption = {
              value: correspondingNode,
              select: () => null,
              deselect: () => null
            } as any;
          }
        }
        if (correspondingOption) {
          (this.matSelect as any)._selectionModel.select(correspondingOption);
        }
        return correspondingOption;
      };

      // Reset any subscriptions now that the scroller has been rendered, otherwise virtual
      // scrolling will not work and the component will render very slowly for large data sets
      this.cleanupSubscriptions();
      this.setupSubscriptions();
    }
  }

  /**
   * Callback function to look up a node's name. This must be set for the component to work.
   */
  @Input() nameFn: (T) => string = () => 'Specify a Name Function';

  /**
   * Callback function to look up a node's icon. This is optional
   */
  @Input() iconFn: (T) => string = () => null;

  /**
   * Callback to determine if a node can be selected or not. By default this will allow
   * leaf node selection.
   */
  @Input() canSelectFn: (T) => boolean = node => this.treeControl.isExpandable(node);

  /**
   * Get padding for a node in the dropdown based on it's level.
   *
   * @param   node         The current node.
   * @param   isMatOption  False if the padding is for an option group, true if it is for an option.
   * @return  The padding value in rem.
   */
  getOptionPadding(node: DataTreeNode<T>, isMatOption = false): string {
    let padding = node.level * 2;
    if (isMatOption) {
      // Mat option needs slightly more padding than mat groups, and slightly more
      // padding when multi select is enabled.
      padding += this.multiSelect ? 1 : 0.5;
    }
    return `${padding}rem`;
  }

  /**
   * Expand or collapse a node
   *
   * @param   node   The current node.
   */
  toggleNodeExpansion(node: DataTreeNode<T>) {
    this.treeControl.toggle(node);
  }

  /**
   * MatFormFieldControl calls this whenever the container is clicked. Use this to set focus on the input control.
   */
  onContainerClick() {
    if (this.matSelect) {
      this.matSelect.onContainerClick();
    }
  }

  /**
   * Opens the mat select container.
   */
  open() {
    this.matSelect.open();
  }

  /**
   * ControlValueAccessor implementatinon for disabled
   *
   * @param   disabled  The form control state.
   */
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  /**
   * Sets the described by ids.
   *
   * @param   ids  The list of ids.
   */
  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  /**
   * Update subscriptions whenever the data input changes
   */
  setupSubscriptions() {
    if (!this.data) {
      return;
    }

    // Update the scroller when it changes.
    if (this.scroller && this.data.scroller !== this.scroller) {
      this.data.scroller = this.scroller;
      this.cdr.detectChanges();
    }

    // Initialize data with the provided filters.
    this.data.filters = [...this.filters];

    // Flattened data changes whenever the source changes. This sets up the tree
    // to expand all nodes initially
    this.data.filteredData$
      .pipe(
        filter(nodes => !!nodes.length),
        takeUntil(this.destroy),
        debounceTime(5)
      )
      .subscribe(() => {
        this.treeControl.expandAll();
        this.cdr.detectChanges();
      });

    // Mark for check when the current view changes - a filter is applied, or a node
    // is expanded.
    this.data.currentView$.pipe(takeUntil(this.destroy)).subscribe(() => this.cdr.markForCheck());

    // Subscribe to the datasource to get the filtered and virtual nodes to show
    // in the mat option.
    this.dataSub = this.data.connect(this).subscribe(nodes => {
      this.allNodes = nodes;
      this.cdr.detectChanges();
    });

    // Update the filter whenever the query changes
    this.searchControl.valueChanges.pipe(takeUntil(this.destroy)).subscribe(query => {
      if (query instanceof Object) {
        return;
      }
      const filters = [...this.filters];
      if (query) {
        filters.push((nodeList: DataTreeNode<T>[]) =>
          DataTreeFilterUtils.searchFilter(
            nodeList,
            this.treeControl,
            (node: any) => this.nameFn(node).toLowerCase().indexOf(query.trim().toLowerCase()) !== -1
          )
        );
      }
      this.data.filters = filters;
      this.cdr.detectChanges();
    });

    // Keep the value in sync with the control value
    this.selectControl.valueChanges.pipe(takeUntil(this.destroy)).subscribe(value => (this.value = value));

    // Update when the overlay is closed
    if (this.matSelect) {
      this.matSelect._closedStream.pipe(takeUntil(this.destroy)).subscribe(() => this.stateChanges.next());
    }

  }

  /**
   * Clean up subscriptions when the data changes or the component is destroyed
   */
  cleanupSubscriptions() {
    this.destroy.next();
    if (this._data) {
      this._data.disconnect();
    }
    if (this.dataSub) {
      this.dataSub.unsubscribe();
      this.dataSub = null;
    }
  }

  ngOnDestroy() {
    this.initialized = false;
    this.cleanupSubscriptions();
    this.stateChanges.complete();
  }

  /**
   * Callback to pass the change event back to a form control. Defaults to a noop
   */
  propagateChange = (_value: T | T[]) => {};

  /**
   * Callback to pass the onTouch event back to a form control. Defaults to a noop
   */
  propagateOnTouch = () => {};

  /**
   * Update the view with a value passed from a form
   *
   * @param   value   the new form control value
   */
  writeValue(value: T | T[]) {
    this.value = value;
    if (this.selectControl) {
      this.selectControl.setValue(value);
    }
  }

  /**
   * Registers a change event handler to use to propogate changes
   *
   * @param   fn   the callback function
   */
  registerOnChange(fn: (value: T[]) => any) {
    this.propagateChange = fn;
  }

  /**
   * Register on touched event handler.
   */
  registerOnTouched(fn: () => any) {
    this.propagateOnTouch = fn;
  }

  /**
   * Removes a node from the selection.
   *
   * @param   index   The index of the item to remove
   */
  removeSelection(index: number) {
    const selection = this.selectControl.value;
    selection.splice(index, 1);
    this.selectControl.setValue(selection);
    this.cdr.detectChanges();
  }
}
