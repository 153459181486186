/* eslint-disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for CohesityApiAlertStream services
 */
@Injectable({
  providedIn: 'root',
})
export class CohesityApiAlertStreamConfiguration {
  rootUrl: string = '/v2/mcm/alert-publisher';
}

export interface CohesityApiAlertStreamConfigurationInterface {
  rootUrl?: string;
}
