import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';

import { ClusterFilterComponent } from './cluster-filter/cluster-filter.component';
import { DomainFilterComponent } from './domain-filter/domain-filter.component';
import { EnvironmentGroupFilterComponent } from './environment-group-filter/environment-group-filter.component';
import {
  EnvironmentGroupTreeFilterComponent,
} from './environment-group-tree-filter/environment-group-tree-filter.component';
import { ObjectFilterComponent } from './object-filter/object-filter.component';
import { ObjectTypeFilterComponent } from './object-type-filter/object-type-filter.component';
import { ProtectionGroupFilterComponent } from './protection-group-filter/protection-group-filter.component';
import { RegionFilterComponent } from './region-filter/region-filter.component';
import { RoleFilterComponent } from './role-filter/role-filter.component';
import { SourceFilterComponent } from './source-filter/source-filter.component';
import { StorageDomainFilterComponent } from './storage-domain-filter/storage-domain-filter.component';
import { TenantFilterComponent } from './tenant-filter/tenant-filter.component';

const components = [
  ClusterFilterComponent,
  DomainFilterComponent,
  EnvironmentGroupFilterComponent,
  EnvironmentGroupTreeFilterComponent,
  ObjectFilterComponent,
  ObjectTypeFilterComponent,
  ProtectionGroupFilterComponent,
  RegionFilterComponent,
  RoleFilterComponent,
  SourceFilterComponent,
  StorageDomainFilterComponent,
  TenantFilterComponent,
];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [CommonModule, CohesityHelixModule, TranslateModule],
})
export class CommonFiltersModule {}
