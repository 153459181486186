import { Component, Input } from '@angular/core';
import { RoleServiceApi } from '@cohesity/api/v2';
import { FiltersComponent, ValueFilterSelection } from '@cohesity/helix';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseObjectFilterComponent } from './../base-object-filter';

@Component({
  selector: 'coh-role-filter',
  templateUrl: './role-filter.component.html',
})
export class RoleFilterComponent extends BaseObjectFilterComponent<any>  {
  /**
   * The name of the property containing the role property.
   */
  @Input() property = 'role';

   /**
    * Specify whether allow multiple selection or not.
    */
  @Input() allowMultiple = false;

  constructor(
    filters: FiltersComponent,
    private rolesService: RoleServiceApi) {
    super(filters);
  }

  /**
   * Fetch active directories when the component initializes or the params change.
   */
  getFilterValues(): Observable<ValueFilterSelection[]> {
    const params = this.requestParams || {};
    return this.rolesService.GetRoles(params).pipe(
      map(roles => roles.roles.map(role => ({
        label: role.label,
        value: role.name,
      }))
    ));
  }
}
