import { ComponentType } from '@angular/cdk/portal';
import { ExtensionPointConfig } from '../extension-point-config';
import { ComponentExtensionImpl } from '../component-outlet-extension/component-extension-config';

/**
 * Table column extension type
 */
export const tableColumnExtension = 'tableColumnExtension';

/**
 * Configuration model for table column extensions. This is used to configure information needed
 * render a table column.
 */
export interface TableColumnExtensionPointConfig<DataType = any> extends ExtensionPointConfig {
  /**
   * the type must be 'tableColumnExtension'
   */
  type: 'tableColumnExtension';

  /**
   * The label for the column. This should be an already-translated string.
   */
  label: string;

  /**
   * The name to use for the column def
   */
  columnName: string;

  /**
   * The component used to render the cell data.
   */
  cellComponent: ComponentType<ComponentExtensionImpl<DataType>>;
}
