import { BrowserAuthOptions, BrowserSystemOptions, CacheOptions } from '@azure/msal-browser';
import { LogLevel, IPublicClientApplication, PublicClientApplication, BrowserCacheLocation } from '@azure/msal-browser';

/**
 * Deployment environments
 */
export type HeliosEnviroment = 'local' | 'test1' | 'test2' | 'sandbox1' | 'sandbox2';

/**
 * Redirect URL for popup authentication workflow
 */
export const popupWorkflowRedirectUri = `https://${window.document.location.hostname}/assets/redirect-pages/blank-page.html`;

/**
 * Return enviroment based on hostname
 *
 * @returns current deployment environment
 */
export const getCurrentEnvironment = (): HeliosEnviroment => {
  const environmentByHostname = {
    'my-docker-ip.cohesitycloud.co': 'local',
    'helios-test1.cohesitycloud.co': 'test1',
    'helios-test2.cohesitycloud.co': 'test2',
    'helios-sandbox.cohesity.com': 'sandbox1',
    'helios-sandbox2.cohesity.com': 'sandbox2',
  };

  if (environmentByHostname[window.document.location.hostname]) {
    return environmentByHostname[window.document.location.hostname];
  }

  console.warn('Missing public client auth configuration for current environment. Defaulting to local config.');
  return 'local';
};

/**
 * Specifies the Dev mode Public Client Auth Configuration for different dev environments.
 */
// TODO: Nidhi Kulkarni: Change client ids for dev environments once they are available
const selfServiceConfigByDevEnvironment: Record<HeliosEnviroment, BrowserAuthOptions> = {
  local: {
    clientId: '93922f13-e922-49fb-833c-f073eec47865',
    authority: 'https://login.microsoftonline.com/organizations',
    redirectUri: 'https://my-docker-ip.cohesitycloud.co/self-service-portal/auth',
  },
  test1: {
    clientId: 'f0275e53-e7f7-475b-b322-aef1bba08416',
    authority: 'https://login.microsoftonline.com/organizations',
    redirectUri: 'https://helios-test1.cohesitycloud.co/self-service-portal/auth',
  },
  test2: {
    clientId: '53c0ab4c-493c-4a63-b19e-7be7a83975ec',
    authority: 'https://login.microsoftonline.com/organizations',
    redirectUri: 'https://helios-test2.cohesitycloud.co/self-service-portal/auth',
  },
  sandbox1: {
    clientId: 'c9cc5d9e-365e-4b0a-b9dd-7d790cfe0f6c',
    authority: 'https://login.microsoftonline.com/organizations',
    redirectUri: 'https://helios-sandbox.cohesity.com/self-service-portal/auth',
  },
  sandbox2: {
    clientId: '6a9d81dd-78b3-4db3-9258-7b82ddc383c2',
    authority: 'https://login.microsoftonline.com/organizations',
    redirectUri: 'https://helios-sandbox2.cohesity.com/self-service-portal/auth',
  },
};

/**
 * Specifies the common cache configuration for creating instance of MSAL
 * Public client.
 * Refer iris/node_modules/@azure/msal-browser/dist/config/Configuration.d.ts
 * for details.
 */
export const cacheConfigForMsalPublicClient: CacheOptions = {
  cacheLocation: BrowserCacheLocation.LocalStorage,
  storeAuthStateInCookie: true, // IE is unsupported.
};

/**
 * Specifies the common browser configuration for creating instance of MSAL
 * Public client.
 * Refer iris/node_modules/@azure/msal-browser/dist/config/Configuration.d.ts
 * for details.
 */
export const browserSystemOptions: BrowserSystemOptions = {
  loggerOptions: {
    loggerCallback: (logLevel: LogLevel, message: string) => {
      // TODO(tauseef): Remove this once dev work is complete.
      console.log(message);
    },
    logLevel: LogLevel.Info,
    piiLoggingEnabled: false,
  },
};

/**
 * Specifies the Dev mode Public Client Auth Configuration for MSFT.
 * Refer @azure/msal-browser/dist/config/Configuration.d.ts for details.
 */
export const getSelfServiceConfigForDev = (): BrowserAuthOptions =>
  selfServiceConfigByDevEnvironment[getCurrentEnvironment()];

/**
 * Specifies the Prod mode Public Client Auth Configuration for MSFT.
 * Refer @azure/msal-browser/dist/config/Configuration.d.ts for details.
 */
export const selfServiceConfigForProd: BrowserAuthOptions = {
  clientId: 'f9a0e41f-bd16-4271-b9a8-7b4d60c18b21',
  authority: 'https://login.microsoftonline.com/organizations',
  redirectUri: 'https://helios.cohesity.com/self-service-portal/auth',
};

/**
 * Specifies the function to create the Public client for MSAL within the
 * DEV environment.
 *
 * @returns DEV instance for Public Client
 */
export const msalInstanceFactoryForDev = (): IPublicClientApplication =>
  new PublicClientApplication({
    auth: getSelfServiceConfigForDev(),
    cache: cacheConfigForMsalPublicClient,
    system: browserSystemOptions,
  });

/**
 * Specifies the function to create the Public client for MSAL within the
 * PROD environment.
 *
 * @returns PROD instance for Public Client
 */
export const msalInstanceFactoryForProd = (): IPublicClientApplication =>
  new PublicClientApplication({
    auth: selfServiceConfigForProd,
    cache: cacheConfigForMsalPublicClient,
    system: browserSystemOptions,
  });
