import { FilteredObject } from '@cohesity/api/v2';
import { DataFilterValue, DataTreeControl, DataTreeFilter, DataTreeFilterUtils, DataTreeNode } from '@cohesity/helix';
import { IrisContextAccessFn } from '@cohesity/iris-core';
import { BehaviorSubject, combineLatest, isObservable, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

/**
 * These are named filter types that can be used to identify specific
 * filters and set default views depending on how the source tree is
 * being used.
 */
export enum ViewFilterType {
  Physical = 'physical',
  Folder = 'folder',
  Flat = 'flat',
  Tag = 'tag',
  SimpleTag = 'simple-tag',
  Label = 'label',
}

/**
 * Values to use in an expand to menu in the source tree controls.
 */
export interface ExpandToOption<TreeNodeType> {
  /**
   * The options display label.
   */
  label: string;

  /**
   * A callback function to determine if a node should be expanded or not.
   *
   * @param node The node type
   * @returns whether to expand the node.
   */
  expandNodeFilter: (node: TreeNodeType) => boolean;
}

/**
 * Object to describe tag attributes.
 */
export interface TagAttribute {
  /**
   * The tag display name.
   */
  name: string;

  /**
   * The tag id.
   */
  id: number;

  /**
   * Options tag uuid.
   */
  uuid?: string;
}

/**
 * A tag category is a set of tag attributes grouped by name.
 */
export interface TagCategory {
  /**
   * The name of the tag category.
   */
  name: string;

  /**
   * Typically, this is an array of one item, but this also includes the ability to
   * include a combined set of (ie, a filter on tag1 and tag2).
   */
  tagGroups: TagAttribute[][];
}

/**
 * Interface for displaying filter options on the filters component.
 */
export interface FilterOption {
  /**
   * Optional access function that can be set to enable or disable a filter depending on the cluster context.
   */
  canAccess?: IrisContextAccessFn;

  /**
   * ID to use for identifying the filter as well as for data ids.
   * This can be a view filter type, or any arbitrary string.
   */
  id: ViewFilterType | string;

  /**
   * Filter callback. This is optional - an 'all' filter would simply not set a filter value.
   * The filter can also be an observable of a callback function.
   */
  filter?: DataTreeFilter<any> | Observable<DataTreeFilter<any>>;

  /**
   * Optional label for the filter.
   */
  label?: string;

  /**
   * This prevents a filter from being cleared. This allows the tree to force a specific filter
   */
  disableClear?: boolean;
}

/**
 * Interface for displaying filter options on the filters component.
 */
export interface ViewFilterOption extends FilterOption {
  /**
   * Optional icon to show with the filter.
   */
  icon?: string;

  /**
   * This is only valid for view filters. If this is set, a separate 'expand to' menu will be shown
   * with options to expand or collapse the tree to a given node level.
   */
  expandToOptions?: ExpandToOption<any>[];

  /**
   * Optional property to set if this is a tag filter. Tag filters get special handling in the source
   * tree controls UI. They render has View filters but have an additional UI component for them.
   */
  isTag?: boolean;

  /**
   * Optional property to set if this is a Label filter.
   * Label filters are same as tags but are called labels for Kubernetes.
   */
  isTagLabel?: boolean;

  /**
   * Optional tooltip for the filter.
   */
  tooltip?: string;

  /**
   * Optional property to set if this is a flat view filter.
   */
  isFlatView?: boolean;
}

/**
 * Filter group contains enough information to construct a view property filter, including the
 * filter label, id, and specific filer values.
 */
export interface FilterGroup {
  /**
   * Id for the filter, can be used to look up or modify the filter type.
   */
  id: string;

  /**
   * Label to show for the quick filter button.
   */
  label: string;

  /**
   * Specific tree filters to show with this group.
   */
  options: FilterOption[];

  /**
   * Optional flag to denote whether a filterGroup must be shown on quick-protect dialog and full page form.
   */
  alwaysShow?: boolean;
}

/**
 * Exclude options to be sent to exclude filters dialog.
 */
export interface ExcludeOptions {
  /**
   * List of object ids that are autoprotected by the user.
   */
  autoSelectedSourceIds: number[];

  /**
   * Existing exclude filters if previously set.
   */
  filters: ExcludeFilter[];

  /**
   * Specifies exclusion description detail.
   */
  exclusionDescription?: string;
}

/**
 * Exclude filter object
 */
export interface ExcludeFilter {
  /**
   * Filter string, wildcard or regular expression patterns can be used.
   */
  filterString: string;

  /**
   * Determines if the filter string is a regular expression or not.
   */
  isRegularExpression?: boolean;
}

/**
 * Exclude filters dialog when closed, emits exclude results object.
 */
export interface ExcludeResults {
  /**
   * List of object ids which are to be excluded.
   */
  filteredObjects: FilteredObject[];

  /**
   * List of exclude filters.
   */
  filters: ExcludeFilter[];
}

/**
 * A source tree service for Protection Source Nodes. This should be extended for each adapter type
 * The base class provides common implementations that work for most nodes.
 */
export class SourceTreeFilters<TreeNodeType extends DataTreeNode<any>> {
  /**
   * Behavior subject for avilable tags.
   */
  private _availableTags$ = new BehaviorSubject<TagCategory[]>([]);

  /**
   * Behavior subject for filter options.
   */
  private _filterGroups$ = new BehaviorSubject<FilterGroup[]>([]);

  private _filterValues$ = new BehaviorSubject<DataFilterValue<any>[]>([]);

  /**
   * Behavior subject for selected tags.
   */
  private _selectedTags$ = new BehaviorSubject<TagAttribute[]>([]);

  /**
   * Behavior subject for selected view filter.
   */
  private _selectedViewFilter$ = new BehaviorSubject<ViewFilterOption>(undefined);

  /**
   * Behavior subject to track a selected object id filter.
   */
  private _objectIdFilter$ = new BehaviorSubject<number>(undefined);

  /**
   * Behavior subject for view filters.
   */
  private _viewFilters$ = new BehaviorSubject<ViewFilterOption[]>([]);

  /**
   * By default the first view will always be selected. This can be set to override the default
   * based on an id. If the requested id is not present in the filters, the filters will fallback
   * to the first view.
   */
  private _defaultViewFilterId: ViewFilterType | string;

  /**
   * If set to true, enables tag exclusion in auto-protection of non-leaf nodes other than tags.
   */
  private _enableTagExclusion = false;

  /**
   * Sets default view filter type.
   */
  set defaultViewFilterId(filterType: ViewFilterType | string) {
    this._defaultViewFilterId = filterType;
    this.setDefaultFilter();
  }

  /**
   * Returns default view filter type.
   */
  get defaultViewFilterId(): ViewFilterType | string {
    return this._defaultViewFilterId;
  }

  /**
   * A list of all available tags, grouped by category. This value is calculated from the
   * tree itself.
   */
  readonly availableTags$: Observable<TagCategory[]> = this._availableTags$.asObservable();

  /**
   * Default filter options are shown in a drop down on the filter controls. These are used to
   * search for specific values in the tree and display them. For instance, which items are protected
   * or unprotected, etc... An adapter can add more items to this list in order to search for specific
   * options such as vm's with vmware tools installed, etc...
   */
  readonly filterGroups$: Observable<FilterGroup[]> = this._filterGroups$.asObservable();

  readonly filterValues$: Observable<DataFilterValue<any>[]> = this._filterValues$.asObservable();


  /**
   * Sets an object id filter. This can be used to restrict the view of the tree to a single object's tree.
   */
  set objectIdFilter(objectId: number) {
    this._objectIdFilter$.next(objectId);
  }

  /**
   * The currently selected filter tags
   */
  readonly selectedTags$ = this._selectedTags$.asObservable();

  /**
   * The currently selected view filter. This is not set in the base version of the service.
   */
  readonly selectedViewFilter$ = this._selectedViewFilter$.asObservable();

  readonly searchQuery$ = this.filterValues$.pipe(
    map(filterValues => filterValues.find(value => value.key === 'search')),
    map(searchFilter => searchFilter?.value ?? null)
  );

  /**
   * An observable of the current data tree filters applied. This combines the search,
   * view, and filter options into a single stream that can be passed to the tree data source.
   */
  readonly searchFilters$ = this.filterValues$.pipe(
    // Only care about filter configs that actually have value set
    map(filterValues => filterValues.filter(filterValue => Boolean(filterValue.value))),

    // Get the selected value from the filter.
    map(filterValues =>
      (filterValues || []).map(groupValue => {
        if (typeof groupValue.value === 'string') {
          return this.getSearchFilter(groupValue.value);
        } else {
          return groupValue.value[0]?.value;
        }
      })
    ),

    // Make sure everything is an observable
    map(filters => filters.map(searchFilter => (isObservable(searchFilter) ? searchFilter : of(searchFilter)))),
    switchMap(filters => filters.length ? combineLatest(filters) : of([]))
  );

  readonly treeFilters$: Observable<DataTreeFilter<TreeNodeType>[]> = combineLatest([
    this.searchFilters$,
    this._selectedViewFilter$.pipe(switchMap(this.toFilter)),
    this._objectIdFilter$.pipe(map(objectId => this.getObjectIdFilter(objectId)))
  ]).pipe(
    distinctUntilChanged(),
    debounceTime(300),
    map(([searchFilters, viewFilter, objectIdFilter]) =>
      // If view filter is present, run it first since it requires only one pass through the list and
      // can potentially change the filter levels
      [objectIdFilter, viewFilter, ...searchFilters].filter(Boolean)
    )
  );

  /**
   * View filters are shown as a button toggle group on the filter controls. These are used to show a
   * specific view of the tree structure. This is used mostly for vcenter trees to show folder, physical
   * or flat hierarchies.
   */
  readonly viewFilters$: Observable<ViewFilterOption[]> = this._viewFilters$.asObservable();

  constructor(private treeControl: DataTreeControl<TreeNodeType>) {}

  /**
   * Creates a data tree filter from the search query.
   *
   * @param   searchQuery  The search query.
   * @return  A filter with a callback that will match based on the query.
   */
  getSearchFilter(searchQuery: string): DataTreeFilter<TreeNodeType> {
    if (!searchQuery) {
      return undefined;
    }
    return (nodeList: any[]) =>
      DataTreeFilterUtils.searchFilter(nodeList, this.treeControl, (node: any) =>
        node.name.toLowerCase().indexOf(searchQuery.trim().toLowerCase()) !== -1);
  }

  /**
   * Set _enableTagExclusion.
   */
  setEnableTagExclusion(isTagExclusionEnabled: boolean) {
    this._enableTagExclusion = isTagExclusionEnabled;
  }

  /**
   * Returns the value of _enableTagExclusion.
   */
  getTagExclusionEnabled(): boolean {
    return this._enableTagExclusion;
  }

  /**
   * Creates a data tree filter from an object id.
   *
   * @param   objectId  The object id to filter on.
   * @return  A filter with a callback that will match based on the object id.
   */
  getObjectIdFilter(objectId: number): DataTreeFilter<TreeNodeType> {
    if (!objectId) {
      return undefined;
    }
    return (nodeList: any[]) =>
      DataTreeFilterUtils.searchFilter(nodeList, this.treeControl, (node: any) => {
        const currentNode = this.treeControl.getNode(objectId);
        // Include all the tag nodes if the current node is not a tag node and not a leaf node.
        // This is useful for excluding tags in autoprotection of a non-tag node.
        const showtagNode = this._enableTagExclusion && node?.isTag && !currentNode?.isTag && !currentNode?.isLeaf;
        return Number(node.id) === objectId || node.tagIds.includes(objectId) || showtagNode ||
          this.treeControl.checkAnyAncestor(node, ancestor => String(ancestor.id) === String(objectId));
      });
  }

  /**
   * Reset all of the filters to their default values.
   */
  resetFilters() {
    this.setFilterGroups(this._filterGroups$.value);
    this.setViewFilters(this._viewFilters$.value);
    this.setSelectedTags([]);
  }

  /**
   * Sets the available filter options. These are generally search filters on the tree's
   * data and do not modify the structure.
   *
   * @param   filterGroups   A list of filter groups.
   */
  setFilterGroups(filterGroups: FilterGroup[]) {
    this._filterGroups$.next(filterGroups);
  }

  /**
   * Adds a new set of filter options for the source tree.
   *
   * @param   filterGroup   The group to add.
   */
  addFilterGroup(filterGroup: FilterGroup) {
    const groups = this._filterGroups$.value;
    const groupIndex = groups.findIndex(group => group.id === filterGroup.id);
    if (groupIndex !== -1) {
      groups.splice(groupIndex, 1, filterGroup);
    } else {
      groups.push(filterGroup);
    }
    this._filterGroups$.next([...groups]);
  }

  /**
   * Add a filter option to an existing group. For instance, the job source tree adds a 'selected' option to the
   * main object filter and the vmware tree adds vm tools and sql filters to th object filter as well.
   *
   * @param   groupId   The group id to update. The method will throw an error if it does not exist.
   * @param   newFilter The new filter option to add. If the filter already exists in the options group,
   *                    it will be replaced with the new instance.
   * @param   index     Optional index to add the filter, if this is not set, the filter will be
   *                    appended to the existing options.
   */
  addToFilterGroup(groupId: string, newFilter: FilterOption, index?: number) {
    const groups = this._filterGroups$.value;
    const group = groups.find(filterGroup => filterGroup.id === groupId);
    if (!group) {
      throw new Error(`group id ${groupId} not found`);
    }
    const existingIndex = group.options.findIndex(option => option.id === newFilter.id);
    if (existingIndex !== -1) {
      group.options.splice(existingIndex, 1, newFilter);
    } else if (index !== undefined && index < group.options.length) {
      group.options.splice(index, 0, newFilter);
    } else {
      group.options = group.options.concat(newFilter);
    }
    this._filterGroups$.next([...groups]);
  }

  /**
   * Sets the available view filter options. These generally used to display what kind of
   * data is shown and may modify the tree structure. They can be selected at the same time
   * as a filter option.
   *
   * @param   viewFilters   A list of view filter options.
   */
  setViewFilters(viewFilters: ViewFilterOption[]) {
    this._viewFilters$.next(viewFilters);
    this.setDefaultFilter();
  }

  /**
   * Sets default filter type from available filters.
   */
  setDefaultFilter() {
    const viewFilters = this._viewFilters$.value;
    const defaultFilterId = this._defaultViewFilterId;

    if (viewFilters?.length) {
      const defaultFilter =
        viewFilters.find(filter => filter.id && filter.id === 'ShowAll') ||
        (defaultFilterId && viewFilters.find(filter => filter.id && filter.id === defaultFilterId)) ||
        viewFilters[0];
      this.setSelectedViewFilter(defaultFilter);
    }
  }

  /**
   * Returns current value of view filters.
   */
  getViewFilters(): ViewFilterOption[] {
    return this._viewFilters$.value;
  }

  /**
   * Sets available tags for the current data set. If this is not set, no tag filters
   * will be shown.
   *
   * @param   availableTags   Available tag groups.
   */
  setAvailabletags(availableTags: TagCategory[]) {
    this._availableTags$.next(availableTags);
  }

  /**
   * Sets the selected tags to filter on.
   *
   * @param   selectedTags   The selected tags.
   */
  setSelectedTags(selectedTags: TagAttribute[]) {
    this._selectedTags$.next(selectedTags);
  }

  /**
   * Sets the selected view filter.
   *
   * @param   selected   The selected view filter.
   */
  setSelectedViewFilter(selected: FilterOption) {
    this._selectedViewFilter$.next(selected);
  }

  /**
   * Update the filter values when items are selected from the helix filters.
   *
   * @param filterValues The new filter values.
   */
  setFilterValues(filterValues: DataFilterValue<any>[]) {
    this._filterValues$.next(filterValues);
  }

  /**
   * The filter option filter can be a function, an observable of a function, or null.
   * Use switch map to return an observable of the value.
   *
   * @param   option   The filter option to check
   * @returns an observable of option.filter
   */
  private toFilter(option: FilterOption): Observable<DataTreeFilter<TreeNodeType>> {
    if (!option || !option.filter) {
      return of(null);
    }
    return typeof option.filter === 'function' ? of(option.filter) : option.filter;
  }
}
