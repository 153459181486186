import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { HelixIntlService } from '../../../../helix-intl.service';
import { DateRangeBanner } from '../../../date-range-picker/date-range-picker.component';
import { MaxDateOptions, MinDateOptions } from '../../../date-utils/date-options';
import { DateAdapterUtils, DateRange, Timeframe, timeframeLabels } from '../../../date-utils/public_api';
import { DataFilter, DateFilterRange } from '../../comparators';
import { FilterDefParams } from '../../filters/filter-def.directive';
import { DisableApplyButton } from '../../filters/quick-filter/quick-filter.component';

/**
 * @description
 * Date range picker for filtering `DataSource` components.
 *
 * @example
 *    <cog-date-range-filter *cogFilterDef="
 *        let filter;
 *        let params = params;
 *        key: 'registered';
 *        label: 'Registered';
 *        filterType: 'dateRange';
 *        quickFilter: true"
 *      [filterDefParams]="params"
 *      [filter]="filter"
 *      [useTimeframe]="true"
 *      [selectedRange]="regRange">
 *    </cog-date-range-filter>
 */
@Component({
  selector: 'cog-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DateRangeFilterComponent<D> implements OnInit, OnDestroy {
  /**
   * The filter associated with this component
   */
  @Input() filter: DataFilter<DateFilterRange<D>>;

  /**
   * Params from `FilterDefDirective` instance.
   */
  @Input() filterDefParams: FilterDefParams;

  /**
   * Whether its form style filter i.e without borders
   */
  @Input() formStyle = false;

  /**
   * Static label to preface the dynamic chip label.
   */
  @Input() preLabel = '';

  /**
   * Hides the Custom time frame option.
   */
  _noCustomRange = false;
  @Input() set noCustomRange(noCustomRange: boolean) {
    this._noCustomRange = noCustomRange;

    const index = this.timeframeOptions?.findIndex(option => option === Timeframe.Custom);

    if (noCustomRange) {
      // If set to have no custom range and custom range is found, remove it.
      if (index !== -1) {
        this.timeframeOptions.splice(index, 1);

        // always call setValue() to update UI when option list is updated.
        const dateRange = this.dateAdapterUtils.getGenericDateRange(
          this.selectedRange?.timeframe === Timeframe.Custom ? Timeframe.CurrentMonth : this.selectedRange?.timeframe
        );

        this.dateRange.setValue(dateRange, { emitEvent: false });
        this.setValue();
      }

    // if set to have custom range and custom range is not found, add it.
    } else if (index === -1) {
      const length = this.timeframeOptions?.length;

      if (length) {
        if (this.timeframeOptions[length - 1] === Timeframe.NoDate) {
          this.timeframeOptions.splice(length - 1, 0, Timeframe.Custom);
        } else {
          this.timeframeOptions.push(Timeframe.Custom);
        }
      } else {
        this.timeframeOptions = [Timeframe.Custom];
      }

      // always call setValue() to update UI when option list is updated.
      if (this.selectedRange?.timeframe) {
        const dateRange = this.dateAdapterUtils.getGenericDateRange(this.selectedRange.timeframe);

        this.dateRange.setValue(dateRange, { emitEvent: false });
        this.setValue();
      }
    }
  }

  get noCustomRange(): boolean {
    return this._noCustomRange;
  }

  /**
   * does not allow guessing of time frame
   */
  @Input() enableGuessTimeframe = false;

  /**
   * If enabled then allow time selection for selected start and end date range.
   */
  @Input() enablePreciseDateRangeSelection = false;

  /**
   * If provided, user will be able to select an option with this label, which
   * will present an additional option having this label and providing no date
   * range in the filter value. NOTE: Requires @Input() useTimeframe to be true.
   */
  @Input() noDateOptionLabel = '';

  /**
   * Assigns date range value to the filter.
   */
  @Input() set selectedRange(range: DateFilterRange<D>) {
    if (range) {
      const { start, end, timeframe } = range;
      this.dateRange.setValue({ start, end, timeframe });
      this.setValue();
    }
  }

  /**
   * Returns date range from filter.
   */
  get selectedRange(): DateFilterRange<D> {
    const { value: filterValue } = this.filter.currentValue$;
    if (filterValue) {
      return filterValue.value;
    }
  }

  @Input() timeframeOptions: Timeframe[] = [
    Timeframe.Past1Hour,
    Timeframe.Past12Hours,
    Timeframe.Past24Hours,
    Timeframe.Past7Days,
    Timeframe.Past30Days,
  ];

  /**
   * Whether to show clear button.
   */
  @Input() noClear = false;

  /**
   * Sets max number of days selectable by the user.
   */
  @Input() maxRange: number;

  /**
   * Displays timeframe selector.
   */
  @Input() useTimeframe = false;

  /**
   * earliest date in the past which can be allowed
   */
  @Input() minDate: MinDateOptions | D = MinDateOptions.Forever;

  /**
   * if selection in the future is to be allowed (after the current moment)
   */
  @Input() maxDate: MaxDateOptions | D = MaxDateOptions.Today;

  /**
   * Allow parent to dynamically set banner.
   */
  @Input() banner: DateRangeBanner;

  /**
   * Disable apply button if defined.
   */
  @Input() disableApplyButton: DisableApplyButton;

  /**
   * Event emitted with new date range is selection.
   */
  @Output() changes = new EventEmitter<DateRange<D> | null>();

  /**
   * Event emitted with custom button is clicked.
   */
  @Output() customButtonClicked = new EventEmitter<void>();

  /**
   * Form control for date range picker component.
   */
  readonly dateRange = new UntypedFormControl();

  /**
   * Clean up observable subscriptions when component is destroyed.
   */
  private readonly destroy$ = new Subject();

  constructor(
    private dateAdapterUtils: DateAdapterUtils<D>,
    public intlSvc: HelixIntlService,
  ) {}

  ngOnInit() {
    // add "Custom" radio button option to timeframe selector
    if (!this.noCustomRange) {
      this.timeframeOptions = [
        ...this.timeframeOptions,
        Timeframe.Custom,
      ];
    }

    if (this.useTimeframe && this.noDateOptionLabel) {
      this.timeframeOptions = [
        ...this.timeframeOptions,
        Timeframe.NoDate,
      ];
    }

    this.filter.currentValue$.pipe(
      takeUntil(this.destroy$),
      filter(filterValue => {
        if (filterValue && filterValue.value) {
          const { start, end, timeframe, timeframeLabel } = filterValue.value;

          // checking custom date range (setting it to a standard timeframe option if it is a standard option)
          if (start && end && timeframe === Timeframe.Custom  && this.enableGuessTimeframe) {
            const currTimeframe =
              this.dateAdapterUtils.guessTimeframe(filterValue.value as DateRange<D>, this.timeframeOptions);

            // Update the internal date range model.
            this.dateRange.setValue({ start, end, timeframe: currTimeframe }, { emitEvent: false });

            // skip this event and wait for cog-filter to emit the same which will be
            // processed normally.
            this.setValue();
            return false;
          }

          // Setting date range for valid timeframe if start & end date is not set.
          if (!start && !end && timeframe !== Timeframe.Custom) {
            const dateRange = this.dateAdapterUtils.getGenericDateRange(timeframe);

            // Update the internal date range model.
            this.dateRange.setValue(dateRange, { emitEvent: false });

            // Trigger the cog-filters update.
            this.setValue();

            // skip this event and wait for cog-filter to emit the same which will be
            // processed normally.
            return false;
          }

          // Setting timeframe label if timeframe is there but timeframe label is missing.
          if (timeframe && !timeframeLabel) {
            // skip this event and wait for cog-filter to emit the same which will be
            // processed normally.
            this.setValue();
            return false;
          }
        }

        return true;
      }),
      tap(filterValue => {
        if (filterValue && filterValue.value) {
          const { start, end, timeframe, timeframeLabel } = filterValue.value;
          this.dateRange.setValue({ start, end, timeframe, timeframeLabel }, { emitEvent: false });
        } else {
          this.clearFilters();
        }
      }),
    ).subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  /**
   * Clears filter values and resets date picker calendar date.
   */
  clearFilters = () => {
    this.dateRange.setValue({
      start: null,
      end: null,
    });
  };

  /**
   * Applies filter values from date picker component.
   */
  applyFilters = () => {
    this.setValue();
  };

  /**
   * Handle function for when quick filter menu is closed.
   */
  handleMenuClose() {
    // When the menu is closed, clear selection which were never applied.
    this.dateRange.setValue({ ...this.filter.currentValue$.value?.value });
  }

  /**
   * Handle function for when quick filter menu is opened.
   */
  handleMenuOpen() {
    // wait for overlay to open
    setTimeout(() => {
      // get checked radio button
      let checkRadio = document.querySelector(`.cdk-overlay-container .mat-radio-button.mat-radio-checked input`);

      if (!checkRadio) {
        // get radio button
        checkRadio = document.querySelector(`.cdk-overlay-container .mat-radio-button input`);
      }

      (checkRadio as HTMLElement)?.focus();
    });
  }

  /**
   * Sets date range filter value based on the selected internal date range.
   */
  private setValue() {
    const { start, end, timeframe } = (this.dateRange.value || {}) as DateRange<D>;
    const { value: currentValue } = this.filter.currentValue$;

    // Noting to set if prev and new date range is same.
    if (
      this.dateAdapterUtils.isSameDateRange(
        currentValue?.value as DateRange<D>,
        this.dateRange.value,
        !!this.noDateOptionLabel,
      ) &&
      (timeframe ? !!currentValue?.value?.timeframeLabel : true)
    ) {
      return;
    }

    if ((start && end) || this.noDateOptionLabel) {
      const newValue: DateFilterRange<D> = {
        start,
        end,
        timeframe,
        timeframeLabel: timeframe ? this.getLabel(timeframe) : undefined,
      };

      this.filter.setValue(newValue);
    } else {
      this.filter.removeValue(currentValue);
    }
  }

  /**
   * Get the label of timeframe option.
   *
   * @param    timeframe Timeframe option.
   * @returns  Timeframe label.
   */
  getLabel(timeframe: Timeframe): string {
    if (timeframe === Timeframe.NoDate) {
      return this.noDateOptionLabel;
    }
    return this.intlSvc.dataFilters.dateRange.timeframe[timeframeLabels[timeframe]];
  }
}
