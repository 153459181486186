// Component: Deploy Cloud Edition to GCP

;(function(angular) {
  'use strict';

  angular.module('C.cloudEditionManager')
    .component('deployGcpCloudEdition', {
      templateUrl: 'app/platform/cloud-edition-manager/gcp/deploy-gcp.html',
      controller: 'deployGCPCtrl',
    })
    .controller('deployGCPCtrl', deployGCPCtrlFn);

  /**
   * @ngdoc component
   * @name C.cloudEditionManager:deployGcpCloudEdition
   * @function
   *
   * @description
   * Deploy a new Cohesity DataPlatform for cloud on GCP
   */
  function deployGCPCtrlFn(_, $rootScope, $state, $q, evalAJAX, cMessage,
    SlideModalService, CloudEditionManagerService, FORMATS) {

    var $ctrl = this;
    var newGCPDeployOptions = {
      accessCredentials: {},
      cloudType: 'gcp',
      deploymentConfig: {
        clusterName: '',
        dnsServer: ['8.8.8.8'],
        domainName: ['eng.cohesity.com'],
        enableClusterEncryption: true,
        instanceType: '',
        clusterImage: '',
        numInstances: 3,
        networkTags: [],
        ntpServer: ['time.nist.gov', 'pool.ntp.org'],
        projectId: '',
        region: '',
        subnet: '',
        vpcConnector: '',
        vpc: '',
        zone: '',
      },
      jobName: '',
      email: [$rootScope.user.username],
   };

    _.assign($ctrl, {
      // properties
      currentStep: 1,
      deployTarget: {},
      images: [],
      FORMATS: FORMATS,
      steps: [{
        titleKey: 'gcpCredentials',
      }, {
        titleKey: 'cloudEdition.cloudEnvironmentDetails',
      }, {
        titleKey: 'cloudEdition.dataPlatformForCloudDetails',
      }],
      submitButtonText: 'verify',
      tags: [],
      uploadConfig: {
        usePadding: true,
        icon: 'icn-license-file',
        onFileSelect: onFileSelect,
      },

      // methods
      $onInit: onInit,
      back: back,
      cancel: cancel,
      processForm: processForm,
      selectGCPVpc: selectGCPVpc,
      selectGCPProject: selectGCPProject,
      selectGCPRegion: selectGCPRegion,
      showPolicyModal: CloudEditionManagerService.showPolicyModal,
    });

    /**
     * Activate the controller
     *
     * @method   onInit
     */
    function onInit() {
      CloudEditionManagerService.getCENodeTypes('gcp')
        .then(function cePromisesReslved(resp) {
          $ctrl.nodeTypes = resp;
        }, evalAJAX.errorMessage);

      _.assign($ctrl.deployTarget, newGCPDeployOptions);

      CloudEditionManagerService.showChecklistModal('gcp');
    }

    /**
     * Callback function for when an indentity file is selected.
     * It sets certain metadata for the CE deployment.
     *
     * @param {Object} file The selected file object
     */
    function onFileSelect(file) {
      var deploymentConfig = $ctrl.deployTarget.deploymentConfig;

      $ctrl.file = file;
      $ctrl.verified = false;
      $ctrl.submitButtonText = 'verify';

      $ctrl.projectIds = [];
      $ctrl.regions = [];
      $ctrl.vpcs = [];
      $ctrl.zones = [];
      $ctrl.subnets = [];
      $ctrl.vpcConnectors = [];

      // reset values if previously selected
      deploymentConfig.projectId =
        deploymentConfig.region =
          deploymentConfig.zone =
            deploymentConfig.vpc =
              deploymentConfig.subnet =
                deploymentConfig.vpcConnector = undefined;
    }

    /**
     * Callback after ProjectId selection to shortlist regions
     *
     * @method  selectGCPProject
     * @param   {String}  projectId  The selected Project ID
     */
    function selectGCPProject(projectId) {
      var deploymentConfig = $ctrl.deployTarget.deploymentConfig;

      $ctrl.loadingRegions = true;

      $ctrl.regions = [];
      $ctrl.vpcs = [];
      $ctrl.zones = [];
      $ctrl.subnets = [];
      $ctrl.vpcConnectors = [];

      // reset values if previously selected
      deploymentConfig.region =
        deploymentConfig.zone =
          deploymentConfig.vpc =
            deploymentConfig.subnet =
              deploymentConfig.vpcConnector = undefined;

      CloudEditionManagerService.getGCPRegions({
        accessCredentials: $ctrl.deployTarget.accessCredentials,
        project: projectId,
        cloudType: 'gcp',
      }).then(function gotRegions(data) {
        $ctrl.regions = data;
      }, evalAJAX.errorMessage)
      .finally(function getGCPRegionsFinally() {
        $ctrl.loadingRegions = false;
      });
    }

    /**
     * Callback after Region selection to shortlist vpc etc
     *
     * @method  selectGCPRegion
     * @param   {String}  region  The selected Region ID
     */
    function selectGCPRegion(region) {
      var deploymentConfig = $ctrl.deployTarget.deploymentConfig;

      $ctrl.loadingRegionDetails = true;

      $ctrl.vpcs = [];
      $ctrl.zones = [];
      $ctrl.subnets = [];
      $ctrl.vpcConnectors = [];

      // reset values if previously selected
      deploymentConfig.zone =
        deploymentConfig.vpc =
          deploymentConfig.subnet =
            deploymentConfig.vpcConnector = undefined;

      CloudEditionManagerService.getGCPRegionDetails({
        accessCredentials: $ctrl.deployTarget.accessCredentials,
        project: $ctrl.deployTarget.deploymentConfig.projectId,
        region: region,
        cloudType: 'gcp',
      }).then(function gotRegions(data) {

        _.each(_.get(data, 'local.vpcs'), function eachLocalData(value, key) {
          $ctrl.vpcs.push({
            id: key,
            group: 'local',
            data: value,
          });
        });

        _.each(_.get(data, 'shared.vpcs'), function eachSharedData(value, key) {
          $ctrl.vpcs.push({
            id: key,
            group: 'shared',
            data: _.assign(value, {sharedProjectId: data.shared.project}),
          });
        });

        $ctrl.zones = data.zones;
      }, evalAJAX.errorMessage)
      .finally(function getGCPRegionsFinally() {
        $ctrl.loadingRegionDetails = false;
      });
    }

    /**
     * Callback after VPC selection to shortlist corressponding subnets and
     * vpc connectors
     *
     * @method  selectGCPVpc
     * @param   {Object}  vpc  The selected VPC Object
     */
    function selectGCPVpc(vpc) {
      var deploymentConfig = $ctrl.deployTarget.deploymentConfig;

      $ctrl.vpcConnectors = [];

      $ctrl.subnets = vpc.data.subnets;
      _.each(vpc.data.vpcConnectors,
        function eachConnectorObj(connector, location) {
          _.each(connector, function eachConnector(value) {
            $ctrl.vpcConnectors = $ctrl.vpcConnectors.concat({
              name: value,
              location: location,
            });
        });
      });

      deploymentConfig.sharedProjectId = vpc.data.sharedProjectId;

      // reset values if previously selected
      deploymentConfig.subnet =
        $ctrl.vpcConnector =
          deploymentConfig.vpcConnector = undefined;
    }

    /**
     * Submit the form to the server
     *
     * @method  processForm
     * @param   {object}  form   The form object to process
     */
    function processForm(form) {
      if (!form.$valid) {
        return false;
      }

      var regionsData = {};
      var fileReader;

      switch ($ctrl.currentStep) {

        // Step 1 (verification)
        case 1:
          if (!$ctrl.verified) {
            $ctrl.submitting = true;

            regionsData.cloudType = $ctrl.deployTarget.cloudType;

            fileReader = new FileReader();

            fileReader.onload = function fileLoaded(e) {
              $ctrl.deployTarget.accessCredentials =
                JSON.parse(fileReader.result);

              regionsData.accessCredentials =
                $ctrl.deployTarget.accessCredentials;

              // make service call to verify
              CloudEditionManagerService
                .validateCloudCredentials(regionsData)
                .then(function gotRegionDetails(resp) {
                  $ctrl.verified = true;
                  $ctrl.submitButtonText = 'continue';
                  $ctrl.currentStep = 2;
                  $ctrl.projectIds = resp;

                  // reset the form validations for submit
                  form.$setPristine();
                }, evalAJAX.errorMessage)
                .finally(function getGCPRegionsDetailsFinally() {
                  $ctrl.submitting = false;
                });
            };

            fileReader.onerror = function fileLoadFailed(err) {
              cMessage.error({
                title: 'errorReadingFile',
              });

              $ctrl.submitting = false;
            };

            fileReader.readAsText($ctrl.file);


          // if verification is already done once, go directly to next step
          } else {
            $ctrl.currentStep = 2;
            form.$setPristine();
          }

          break;

        // Step 2
        case 2:
          $ctrl.currentStep = 3;
          $ctrl.submitButtonText = 'deploy';

          // reset the form validations for submit
          form.$setPristine();
          break;

        // Step 3 (submission)
        case 3:
          $ctrl.submitting = true;

          CloudEditionManagerService
            .deployCloudEdition($ctrl.deployTarget, $ctrl.tags)
            .catch(function failedCECreate(resp) {
              evalAJAX.errorMessage(resp);
              $ctrl.submitting = false;
            });

          break;
      }
    }

    /**
     * Go back one step in the form flow.
     *
     * @method     back
     */
    function back() {
      $ctrl.currentStep--;
      $ctrl.submitButtonText = 'next';
    }

    /**
     * Cancel this flow. Go back to cloud editions list view.
     *
     * @method     cancel
     */
    function cancel() {
      $state.go('cloud-edition-manager');
    }
  }

})(angular);