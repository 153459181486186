import { envGroups } from '@cohesity/iris-shared-constants';
import { forEach } from 'lodash';

/**
 * Order of status icon to show when the list has multiple different status.
 */
export const statusPriority: IconStatus[] = [
  'error',
  'error!critical',
  'warning',
  'warning!warning',
  'cancel',
  'cancel!warning',
  'skip',
  'skip!warning',
  'running',
  'in-progress',
  'in-progress-alt!outline',
  'success',
  'success!success',
  'protect-partial',
  'protect',
  'protect-auto',
];

/**
 * Generic Object status type.
 */
type CommonStatusType =
  'kCancel' |
  'kError' |
  'kFailure' |
  'kInfo' |
  'kRunning' |
  'kScheduled' |
  'kSkipped' |
  'kSuccess' |
  'kWarning';

/**
 * Precheck status type.
 */
type PreCheckStatusType =
  'pPassed' |
  'pFailed' |
  'pSkipped' |
  'pWarning' |
  'pError' |
  'pRunning';


/**
 * Cluster opertion status type.
 */
type ClusterOperationStatusType =
  'pInProgress' |
  'pSuccess' |
  'pFailed' |
  'pPaused' |
  'pPartiallyCompleted' |
  'pCanceled' |
  'pSkipped' |
  'pError';

/**
 * Protection status type.
 */
export type ProtectionStatusType =
  'Accepted' |
  'Canceled' |
  'Canceling' |
  'Failed' |
  'Missed' |
  'OnHold' |
  'Running' |
  'Skipped' |
  'Succeeded' |
  'SucceededWithWarning' |
  'Paused' |
  'Pausing' |
  'Finalizing' |
  'Resuming';

/**
 * Status used in protection run target snapshot result.
 */
export type RunStatus =
  'kCanceled' |
  'kCanceling' |
  'kCurrentAttemptPaused' |
  'kCurrentAttemptPausing' |
  'kCurrentAttemptResuming' |
  'kFailed' |
  'kInProgress' |
  'kSkipped' |
  'kSuccessful' |
  'kWaitingForNextAttempt' |
  'kWaitingForOlderBackupRun' |
  'kWarning';

/**
 * VM Migration status type.
 */
export type MigrationStatusV1 =
 'kCanceled' |
 'kCanceling' |
 'kError' |
 'kFailure' |
 'kInfo' |
 'kOnHold' |
 'kOnHoldError' |
 'kRunning' |
 'kScheduled' |
 'kSuccess' |
 'kFinalizing' |
 'kWarning';

/**
 * Clone destroy status type from v1.
 */
export type DestroyStatusTypeV1 = 'destroyError' | 'destroySuccess' | 'destroyRunning';

/**
 * DataAccess session status definition.
 */
export type DataAccessSessionStatusType = 'Pending' | 'Established' | 'Terminated' | 'RefreshInitiated';

/**
 * Status used for kepler object runs.
 */
export type KeplerObjectStatus =
  'kCreated' |
  'kCompleted' |
  'kFailed' |
  'kCanceled' |
  'kCanceling' |
  'kDeleted' |
  'kDeleting' |
  'kPending' |
  'kRunning';

/**
 * Status used by Kepler Activities.
 */
export type KeplerActivityStatus =
  'Running' |
  'Completed' |
  'Failed' |
  'Canceled' |
  'Canceling' |
  'Deleted' |
  'Deleting' |
  'DeletionFailed' |
  'PartiallyCompleted';

/**
 * Protection run object status type.
 */
export type ProtectionRunObjectStatus = ProtectionStatusType | RunStatus;

/**
 * V1 Progress Monitor API status.
 */
export type ProgressMonitorStatus = CommonStatusType | 'kGarbageCollected' | 'kCanceled';

/**
 * Map Progress Monitor V1 API status to string value.
 */
export const progressMonitorStatus: { [key: string]: ProgressMonitorStatus } = {
  0: 'kRunning',
  1: 'kSuccess',
  2: 'kError',
  3: 'kGarbageCollected',
  4: 'kCanceled',
};

/**
 * All Object status type.
 */
export type StatusType = null |
  CommonStatusType |
  PreCheckStatusType |
  ClusterOperationStatusType |
  KeplerActivityStatus |
  KeplerObjectStatus |
  ProtectionRunObjectStatus |
  ProtectionStatusType |
  MigrationStatusV1 |
  ProgressMonitorStatus |
  DestroyStatusTypeV1 |
  'InProgress' | 'Submitted' |
  DataAccessSessionStatusType;

/**
 * Object that contains status, used to show a list of statuses in tooltip.
 */
export interface StatusObject {
  /**
   * Id of object for API calls.
   */
  id?: number;

  /**
   * Name of object for display purpose.
   */
  name?: string;

  /**
   * Type of object.
   */
  type?: string;

  /**
   * Object status.
   */
  status?: StatusType;

  /**
   * State for UIRouter state management
   */
  state?: string;

  /**
   * State parameters for UIRouter state management
   */
  stateParams?: any;
}

/**
 * Icon names for environment category.
 */
type IconEnvGroup =
  'remote-adapter' |
  'application' |
  'aws' |
  'azure' |
  'database' |
  'hadoop' |
  'kubernetes' |
  'nas' |
  'physical-server' |
  'storage-array' |
  'view' |
  'virtual-machine' |
  'office365' |
  'salesforce' |
  'bucket';

/**
 * Icon names for status category.
 */
type IconStatus =
  'error' |
  'error!critical' |
  'warning' |
  'warning!warning' |
  'info' |
  'in-progress' |
  'in-progress!info' |
  'in-progress-alt!info' |
  'in-progress-alt!outline' |
  'paused' |
  'skip' |
  'skip!warning' |
  'success' |
  'success!success' |
  'cancel' |
  'cancel!warning' |
  'scheduled' |
  'running' |
  'protect-partial' |
  'protect' |
  'protect-auto';

/**
 * Mapping status type to helix icon status.
 */
type Mapping = { [key in StatusType]?: IconStatus };

/**
 * Type of settings for icon based on type/category.
 */
interface IconSettings {
  [key: string]: {
    /**
     * Icon name prefix such as cluster, sla.
     */
    iconType: string;

    /**
     * Mapping from backend enum value (usually status) to icon name postfix.
     */
    mapping: Mapping;

    /**
     * Optional labels that should be used with each icon type.
     */
    labels?: { [key in StatusType]?: string };
  };
}

/**
 * Mapping of environment group to icon name.
 */
const environmentMap: { [key: string]: IconEnvGroup } = {
  adapter: 'remote-adapter',
  applications: 'application',
  aws: 'aws',
  azure: 'azure',
  dbs: 'database',
  hadoop: 'hadoop',
  kubernetes: 'kubernetes',
  nas: 'nas',
  physical: 'physical-server',
  san: 'storage-array',
  views: 'view',
  vms: 'virtual-machine',
  office365: 'application',
  salesforce: 'salesforce',
  s3: 'bucket',
};

/**
 * Mapping for status value to icon name postfix.
 */
export const statusMap: Mapping = {
  Canceled: 'cancel!warning',
  Canceling: 'cancel!warning',
  Completed: 'success!success',
  Deleted: 'success!success',
  Deleting: 'in-progress-alt!info',
  DeletionFailed: 'error!critical',
  destroyError: 'error!critical',
  destroySuccess: 'success!success',
  destroyRunning: 'in-progress-alt!info',
  Failed: 'error!critical',
  kCancel: 'cancel!warning',
  kCanceled: 'cancel!warning',
  kCanceling: 'cancel!warning',
  kCompleted: 'success!success',
  kCreated: 'success!success',
  kDeleted: 'success!success',
  kDeleting: 'in-progress-alt!info',
  kFinalizing: 'in-progress-alt!info',
  kError: 'error!critical',
  kFailed: 'error!critical',
  kFailure: 'error!critical',
  kGarbageCollected: 'success!success',
  kInfo: 'info',
  kInProgress: 'in-progress',
  kOnHold: 'success!success',
  kOnHoldError: 'warning!warning',
  kPending: 'info',
  kRunning: 'in-progress-alt!info',
  kScheduled: 'scheduled',
  kSkipped: 'skip!warning',
  kSuccess: 'success!success',
  pPassed: 'success!success',
  pSuccess: 'success!success',
  pRunning: 'in-progress-alt!info',
  pWarning: 'warning!warning',
  pError: 'error!critical',
  pFailed: 'error!critical',
  pSkipped: 'warning!warning',
  pPartiallyCompleted: 'warning!warning',
  pInProgress: 'in-progress',
  pCanceled: 'cancel!warning',
  kSuccessful: 'success!success',
  kWaitingForNextAttempt: 'in-progress',
  kWaitingForOlderBackupRun: 'in-progress',
  kWarning: 'warning!warning',
  Accepted: 'in-progress',
  InProgress: 'in-progress!info',
  Missed: 'warning!warning',
  OnHold: 'in-progress',
  Running: 'in-progress-alt!info',
  PartiallyCompleted: 'info',
  Skipped: 'skip!warning',
  Submitted: 'in-progress-alt!info',
  Succeeded: 'success!success',
  SucceededWithWarning: 'warning!warning',
  Finalizing: 'in-progress-alt!info',
  Pending: 'in-progress',
  RefreshInitiated: 'in-progress-alt!info',
  Established: 'success!success',
  Terminated: 'success!success',
};

/**
 * Mapping for generic object status value to icon name postfix.
 * This may become unnecessary when the values for all other objects are defined
 */
export const objectStatusMap: Mapping = {
  kCancel: 'cancel',
  kError: 'error',
  kRunning: 'running',
  kSkipped: 'skip',
  kSuccess: 'success',
  kScheduled: 'scheduled',
  kWarning: 'warning',
};

/**
 * Mapping for Protection status value to icon name postfix.
 */
const protectionStatusMap: Mapping = {
  Accepted: 'running',
  Canceled: 'cancel',
  Canceling: 'cancel',
  Failed: 'error',
  Missed: 'warning',
  OnHold: 'running',
  Running: 'running',
  Skipped: 'skip',
  Succeeded: 'success',
  SucceededWithWarning: 'warning',
  Paused: 'paused',
};

/**
 * @description
 * Mapping of value such as environment, status to icon settings
 */
export const iconSettings: IconSettings = {
  environment: {
    iconType: 'group',
    mapping: {},
  },
  status: {
    iconType: 'status',
    mapping: statusMap,
  },
  cluster: {
    iconType: 'cluster',
    mapping: protectionStatusMap,
  },
  cloud: {
    iconType: 'cloud',
    mapping: protectionStatusMap,
  },
  'cloud-backup': {
    iconType: 'cloud-backup',
    mapping: objectStatusMap,
  },
  'cloud-spin': {
    iconType: 'cloud-spin',
    mapping: protectionStatusMap,
  },
  indexing: {
    iconType: 'indexing',
    mapping: objectStatusMap,
  },
  replication: {
    iconType: 'replication',
    mapping: protectionStatusMap,
  },
  sla: {
    iconType: 'sla',
    mapping: objectStatusMap,
    labels: {
      kSuccess: 'slaMet',
      kWarning: 'slaMissed'
    }
  },
  tape: {
    iconType: 'tape',
    mapping: protectionStatusMap,
  },
  vault: {
    iconType: 'vault',
    mapping: protectionStatusMap,
  },
};

//  Map environment enum to environment icons
forEach(envGroups, (environments: string[], envGroup: string) => {
  const { mapping } = iconSettings.environment;

  environments.forEach(env => {
    if (!mapping[env]) {
      mapping[env] = environmentMap[envGroup];
    }
  });
});
