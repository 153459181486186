import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { filter } from 'rxjs/operators';

import { GlobalFsExclusions, ProtectionItemName } from '../../../models';
import { BaseProtectionBuilderComponent } from '../../base-protection-builder/base-protection-builder.component';
import { ModifyProtectionService } from '../../../services/modify-protection.service';

@Component({
  selector: 'coh-settings-global-exclude-fs',
  templateUrl: './settings-list-global-exclude-fs.component.html',
})
export class SettingsListGlobalExcludeFSComponent
  extends BaseProtectionBuilderComponent<GlobalFsExclusions, any> implements OnInit {

  /**
   * Default value for exclude fs.
   */
  _value: GlobalFsExclusions = {
    fsTypes: []
  };

  /**
   * Mat chip list's separator key codes.
   */
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  /**
   * MountPoint input ref.
   */
  @ViewChild('mountPointInput') mountPointInput: ElementRef<HTMLInputElement>;

  /**
   * Global exclude FS form group to be attached to the settings list form group.
   */
  globalExcludeFSFormGroup = new UntypedFormGroup({
    fsTypes: new UntypedFormControl([])
  });

  constructor(private modifyProtectionService: ModifyProtectionService) {
    super();
  }

  /**
   * Get the form control from within global exclude form group.
   *
   * @return   Form control of fsTypes.
   */
  get globalExcludeFSControl(): UntypedFormControl {
    return this.globalExcludeFSFormGroup.get('fsTypes') as UntypedFormControl;
  }

  ngOnInit() {
    // Determine if there are any selected ndoes
    this.modifyProtectionService.onControlChange(ProtectionItemName.Objects).pipe(
      filter(value => !!value.sourceTree)
    ).subscribe(() => {
      this.formGroup.addControl(this.name, this.globalExcludeFSFormGroup);
    });
  }

  /**
   * Method called to update mount point selection.
   *
   * @param mountType    Added/removed mount type
   * @param isRemovable  True if allowing mount point to be removed from the list.
   *                     Otherwise, do nothing if the mountType already exists in the list.
   */
  updateGlobalExcludeFS(mountType: string, isRemovable = true) {
    let skipGlobalFSTypeVec = [];
    const fsTypes = this.globalExcludeFSFormGroup.get('fsTypes').value;
    if (fsTypes) {
      skipGlobalFSTypeVec = fsTypes;
    }

    const index = skipGlobalFSTypeVec.indexOf(mountType);

    if (index > -1 && isRemovable) {
      skipGlobalFSTypeVec.splice(index, 1);
    } else {
      skipGlobalFSTypeVec.push(mountType);
    }
    const skipFSVec = new UntypedFormControl(skipGlobalFSTypeVec);
    this.globalExcludeFSFormGroup.setControl('fsTypes', skipFSVec);
  }

  /**
   * Triggers on adding new mount point for skipping nested mount point types globally.
   *
   * @param   event   MatChipInputEvent.
   */
  addFSFormControl(event: MatChipInputEvent) {
    const mountPoint = (event.value || '').trim();
    if (mountPoint) {
      this.updateGlobalExcludeFS(mountPoint, false);
    }
    // Reset input value after adding
    this.mountPointInput.nativeElement.value = '';
  }

  /**
   * Function to remove a mountPoint from the exclude list.
   *
   * @param   mountPoint   Mount Type to be removed.
   */
  removeFSFormControl(mountPoint: string) {
    this.updateGlobalExcludeFS(mountPoint);
  }

  /**
   * Add the globalExcludeFSFormGroup as control.
   */
  addFormControl() {
    this.formGroup.addControl(this.name, this.globalExcludeFSFormGroup);
  }

  /**
   * Form control init.
   */
  initFormControl() {
    if (!this.protectionGroup || !this.protectionGroup.globalExcludeFS) {
      return;
    }
    const skipFSVec = new UntypedFormControl(this.protectionGroup.globalExcludeFS);
    this.globalExcludeFSFormGroup.setControl('fsTypes', skipFSVec);
  }
}
