/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CreateEmailOtpRequestBody } from '../models/create-email-otp-request-body';
import { HeliosMfa } from '../models/helios-mfa';
import { CreateAuthenticatorKeyResponse } from '../models/create-authenticator-key-response';
import { CreateAuthenticatorKeyBody } from '../models/create-authenticator-key-body';
import { MfaConfigInfo } from '../models/mfa-config-info';
import { SupportMfaConfigInfo } from '../models/support-mfa-config-info';
import { UpdateMFAResult } from '../models/update-mfaresult';
import { VerifyTotpResult } from '../models/verify-totp-result';
import { VerifyTotpRequest } from '../models/verify-totp-request';
import { TotpKeyInfo } from '../models/totp-key-info';
import { CreateTotpKeyRequestBody } from '../models/create-totp-key-request-body';
@Injectable({
  providedIn: 'root',
})
class MFAService extends __BaseService {
  static readonly CreateEmailOtpPath = '/email-otp';
  static readonly GetMfaPreferencesPath = '/mcm/mfa';
  static readonly UpdateMfaPreferencesPath = '/mcm/mfa';
  static readonly CreateAuthenticatorKeyPath = '/mcm/mfa/authenticator-key';
  static readonly GetMFAConfigPath = '/mfa-config';
  static readonly UpdateMFAConfigPath = '/mfa-config';
  static readonly SendEmailOtpPath = '/send-email-otp';
  static readonly GetSupportMFAConfigPath = '/support-user/mfa';
  static readonly UpdateSupportMFAConfigPath = '/support-user/mfa';
  static readonly SendSupportEmailOtpPath = '/support-user/send-email-otp';
  static readonly VerifySupportUserTotpPath = '/support-user/verify-totp';
  static readonly CreateTotpKeyPath = '/totp-key';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Creates a new OTP to be sent to the user email.
   *
   * Creates a new One Time Password for the user email. This is used for API login.
   * @param params The `MFAService.CreateEmailOtpParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `body`: Specifies the parameters to send email OTP.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  CreateEmailOtpResponse(params: MFAService.CreateEmailOtpParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/email-otp`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Creates a new OTP to be sent to the user email.
   *
   * Creates a new One Time Password for the user email. This is used for API login.
   * @param params The `MFAService.CreateEmailOtpParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `body`: Specifies the parameters to send email OTP.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  CreateEmailOtp(params: MFAService.CreateEmailOtpParams): __Observable<null> {
    return this.CreateEmailOtpResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get MFA Preferences
   *
   * Gets the Multi Factor Authentication configuration for the account.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetMfaPreferencesResponse(regionId?: string): __Observable<__StrictHttpResponse<HeliosMfa>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/mfa`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HeliosMfa>;
      })
    );
  }
  /**
   * Get MFA Preferences
   *
   * Gets the Multi Factor Authentication configuration for the account.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetMfaPreferences(regionId?: string): __Observable<HeliosMfa> {
    return this.GetMfaPreferencesResponse(regionId).pipe(
      __map(_r => _r.body as HeliosMfa)
    );
  }

  /**
   * Update MFA Preferences
   *
   * Updates the Multi Factor Authentication configuration for the account.
   * @param body Specifies the parameters to support MFA.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  UpdateMfaPreferencesResponse(body: HeliosMfa,
    regionId?: string): __Observable<__StrictHttpResponse<HeliosMfa>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/mcm/mfa`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HeliosMfa>;
      })
    );
  }
  /**
   * Update MFA Preferences
   *
   * Updates the Multi Factor Authentication configuration for the account.
   * @param body Specifies the parameters to support MFA.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  UpdateMfaPreferences(body: HeliosMfa,
    regionId?: string): __Observable<HeliosMfa> {
    return this.UpdateMfaPreferencesResponse(body, regionId).pipe(
      __map(_r => _r.body as HeliosMfa)
    );
  }

  /**
   * Initiate OTP from Helios.
   *
   * Initiate Time based OTP Setup or send Email OTP from Helios for the current user.
   * @param body Parameters required for initiating OTP.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  CreateAuthenticatorKeyResponse(body: CreateAuthenticatorKeyBody,
    regionId?: string): __Observable<__StrictHttpResponse<CreateAuthenticatorKeyResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/mfa/authenticator-key`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateAuthenticatorKeyResponse>;
      })
    );
  }
  /**
   * Initiate OTP from Helios.
   *
   * Initiate Time based OTP Setup or send Email OTP from Helios for the current user.
   * @param body Parameters required for initiating OTP.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  CreateAuthenticatorKey(body: CreateAuthenticatorKeyBody,
    regionId?: string): __Observable<CreateAuthenticatorKeyResponse> {
    return this.CreateAuthenticatorKeyResponse(body, regionId).pipe(
      __map(_r => _r.body as CreateAuthenticatorKeyResponse)
    );
  }

  /**
   * Returns the current MFA configuration.
   *
   * Returns the current MFA configuration for the cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetMFAConfigResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<MfaConfigInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mfa-config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MfaConfigInfo>;
      })
    );
  }
  /**
   * Returns the current MFA configuration.
   *
   * Returns the current MFA configuration for the cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetMFAConfig(regionId?: string,
    accessClusterId?: number): __Observable<MfaConfigInfo> {
    return this.GetMFAConfigResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as MfaConfigInfo)
    );
  }

  /**
   * Stores the updated MFA configuration.
   *
   * Stores the updated MFA configuration for the cluster.
   * @param params The `MFAService.UpdateMFAConfigParams` containing the following parameters:
   *
   * - `body`: The update request for the MFA Settings
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateMFAConfigResponse(params: MFAService.UpdateMFAConfigParams): __Observable<__StrictHttpResponse<MfaConfigInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/mfa-config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MfaConfigInfo>;
      })
    );
  }
  /**
   * Stores the updated MFA configuration.
   *
   * Stores the updated MFA configuration for the cluster.
   * @param params The `MFAService.UpdateMFAConfigParams` containing the following parameters:
   *
   * - `body`: The update request for the MFA Settings
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateMFAConfig(params: MFAService.UpdateMFAConfigParams): __Observable<MfaConfigInfo> {
    return this.UpdateMFAConfigResponse(params).pipe(
      __map(_r => _r.body as MfaConfigInfo)
    );
  }

  /**
   * Creates a new OTP to be sent to the user email.
   *
   * Creates a new One Time Password for the user email
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  SendEmailOtpResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/send-email-otp`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Creates a new OTP to be sent to the user email.
   *
   * Creates a new One Time Password for the user email
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  SendEmailOtp(regionId?: string,
    accessClusterId?: number): __Observable<null> {
    return this.SendEmailOtpResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns the current MFA configuration.
   *
   * Returns the current MFA configuration for support user.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetSupportMFAConfigResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<SupportMfaConfigInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/support-user/mfa`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SupportMfaConfigInfo>;
      })
    );
  }
  /**
   * Returns the current MFA configuration.
   *
   * Returns the current MFA configuration for support user.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetSupportMFAConfig(regionId?: string,
    accessClusterId?: number): __Observable<SupportMfaConfigInfo> {
    return this.GetSupportMFAConfigResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as SupportMfaConfigInfo)
    );
  }

  /**
   * Stores the updated MFA configuration.
   *
   * Update MFA configuration for support user.
   * @param params The `MFAService.UpdateSupportMFAConfigParams` containing the following parameters:
   *
   * - `body`: The update request for the MFA Settings
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateSupportMFAConfigResponse(params: MFAService.UpdateSupportMFAConfigParams): __Observable<__StrictHttpResponse<UpdateMFAResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/support-user/mfa`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateMFAResult>;
      })
    );
  }
  /**
   * Stores the updated MFA configuration.
   *
   * Update MFA configuration for support user.
   * @param params The `MFAService.UpdateSupportMFAConfigParams` containing the following parameters:
   *
   * - `body`: The update request for the MFA Settings
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateSupportMFAConfig(params: MFAService.UpdateSupportMFAConfigParams): __Observable<UpdateMFAResult> {
    return this.UpdateSupportMFAConfigResponse(params).pipe(
      __map(_r => _r.body as UpdateMFAResult)
    );
  }

  /**
   * Creates a new OTP to be sent to the linux support user email.
   *
   * Creates a new one time password for linux support user email
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  SendSupportEmailOtpResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/support-user/send-email-otp`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Creates a new OTP to be sent to the linux support user email.
   *
   * Creates a new one time password for linux support user email
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  SendSupportEmailOtp(regionId?: string,
    accessClusterId?: number): __Observable<null> {
    return this.SendSupportEmailOtpResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Verify the totp code for support user.
   *
   * Verify totp code for support user.
   * @param params The `MFAService.VerifySupportUserTotpParams` containing the following parameters:
   *
   * - `body`: Totp code to be verified.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  VerifySupportUserTotpResponse(params: MFAService.VerifySupportUserTotpParams): __Observable<__StrictHttpResponse<VerifyTotpResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/support-user/verify-totp`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VerifyTotpResult>;
      })
    );
  }
  /**
   * Verify the totp code for support user.
   *
   * Verify totp code for support user.
   * @param params The `MFAService.VerifySupportUserTotpParams` containing the following parameters:
   *
   * - `body`: Totp code to be verified.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  VerifySupportUserTotp(params: MFAService.VerifySupportUserTotpParams): __Observable<VerifyTotpResult> {
    return this.VerifySupportUserTotpResponse(params).pipe(
      __map(_r => _r.body as VerifyTotpResult)
    );
  }

  /**
   * Create a new TOTP secret URI and store the secret key.
   *
   * Create a TOTP key.
   * @param params The `MFAService.CreateTotpKeyParams` containing the following parameters:
   *
   * - `body`: Specifies the key id for creating the TOTP key.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateTotpKeyResponse(params: MFAService.CreateTotpKeyParams): __Observable<__StrictHttpResponse<TotpKeyInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/totp-key`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TotpKeyInfo>;
      })
    );
  }
  /**
   * Create a new TOTP secret URI and store the secret key.
   *
   * Create a TOTP key.
   * @param params The `MFAService.CreateTotpKeyParams` containing the following parameters:
   *
   * - `body`: Specifies the key id for creating the TOTP key.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateTotpKey(params: MFAService.CreateTotpKeyParams): __Observable<TotpKeyInfo> {
    return this.CreateTotpKeyResponse(params).pipe(
      __map(_r => _r.body as TotpKeyInfo)
    );
  }
}

module MFAService {

  /**
   * Parameters for CreateEmailOtp
   */
  export interface CreateEmailOtpParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the parameters to send email OTP.
     */
    body?: CreateEmailOtpRequestBody;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateMFAConfig
   */
  export interface UpdateMFAConfigParams {

    /**
     * The update request for the MFA Settings
     */
    body: MfaConfigInfo;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateSupportMFAConfig
   */
  export interface UpdateSupportMFAConfigParams {

    /**
     * The update request for the MFA Settings
     */
    body: SupportMfaConfigInfo;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for VerifySupportUserTotp
   */
  export interface VerifySupportUserTotpParams {

    /**
     * Totp code to be verified.
     */
    body: VerifyTotpRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateTotpKey
   */
  export interface CreateTotpKeyParams {

    /**
     * Specifies the key id for creating the TOTP key.
     */
    body: CreateTotpKeyRequestBody;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { MFAService }
