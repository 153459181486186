import { Injectable, SkipSelf, StaticProvider } from '@angular/core';
import { cloneDeep } from 'lodash';

import { IrisContext, IrisContextService } from '../iris-context';
import { LocalDataCacheService } from './local-data-cache.service';

/**
 * Method-specific cache configuration information
 */
export interface CacheMethodConfig {
  /**
   * The name of the method to proxy via the cache
   */
  method: string;

  /**
   * Optional callback to modify params before the cache id is derived from them. This does not change the
   * actual params that will be passed to the method, but gives a chance to remove things like timestamps from the
   * params before we generate the cache id.
   */
  paramDecorator?: (params: unknown[]) => unknown;

  /**
   * A callback to determine if the cache should be enabled or not.
   */
  isEnabled: (ctx: IrisContext) => boolean;

  /**
   * The amount of time the item should be valid in the cache. This defaults to 6 hours.
   */
  validityPeriod?: number;

  /**
   * A version associated with the object. When breaking changes are made in the frontend, the version can
   * be incremented to ensure that we don't return old data.
   */
  version?: number;
}

/**
 * Helper to configure a cache provider at a provider level. This will provide a copy of the service object that
 * will override the methods as specified.
 *
 * @param type The claass type to cache
 * @param cacheId The base cache id for methods in this service
 * @param cacheConfig  A list of methods and params that should be cached
 * @returns A provider factory
 */
export function cacheProxyServiceProvider(
  type: unknown,
  cacheId: string,
  cacheConfig: CacheMethodConfig[]
): StaticProvider {
  return {
    provide: type,
    deps: [[new SkipSelf(), type], ApiCacheProxyHelperService],
    useFactory: (api: unknown, proxyHelper: ApiCacheProxyHelperService) =>
      proxyHelper.proxyServiceInstance(api, cacheId, cacheConfig),
  };
}

/**
 * This service simplifies proxying methods at an api service level.
 */
@Injectable({ providedIn: 'root' })
export class ApiCacheProxyHelperService {
  constructor(private cacheService: LocalDataCacheService, private irisContext: IrisContextService) {}

  /**
   * Creates a copy of a service that proxies into the original instance, but connects the specified methods to
   * the local data cache.
   *
   * @param service The service instance.
   * @param cacheId The base cache id.
   * @param cacheConfig Config information for each method.
   * @returns A proxied instance of the class.
   */
  proxyServiceInstance<ServiceType>(
    service: ServiceType,
    cacheId: string,
    cacheConfig: CacheMethodConfig[]
  ): ServiceType {
    // Copy the properties - note that changing these won't modify the original service at all
    const newInstance: ServiceType = { ...service };

    // Copy the prototype of the service.
    Object.setPrototypeOf(newInstance, Object.getPrototypeOf(service));

    // Wrap all of the methods that we want to cache.
    cacheConfig.forEach(({ method, paramDecorator, isEnabled, validityPeriod, version }) => {
      newInstance[method] = (...params) => {
        if (!isEnabled(this.irisContext.irisContext)) {
          return service[method].call(newInstance, ...params);
        }
        return this.cacheService.cacheObservable(service[method].call(newInstance, ...params), {
          cacheId: `${cacheId}:${method}`,

          // use stringify to deep copy params, so that if they get modified here, it doesn't affect the actual
          // api call.
          params: paramDecorator ? paramDecorator(cloneDeep(params)) : params,
          validityPeriod,
          version,
        });
      };
    });
    return newInstance;
  }
}
