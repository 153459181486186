import { Injectable } from '@angular/core';
import { ProtectionSourceNode } from '@cohesity/api/v1';

import { BaseProtectionSourceService } from '../shared/base-protection-source.service';
import { CouchbaseSourceDataNode } from './couchbase-source-data-node';

/**
 * Source tree service for Couchbase.
 */
@Injectable({
  providedIn: 'root',
})
export class CouchbaseSourceTreeService extends BaseProtectionSourceService<CouchbaseSourceDataNode> {

  constructor() {
    super();
  }

  /**
   * Transforms the node object from the api into a Protection Source Tree node to pass to the tree.
   *
   * @param   node   The original node.
   * @param   level  The level in the tree.
   * @return  An CouchbaseSourceDataNode that can be displayed in the tree.
   */
  transformData(node: ProtectionSourceNode, level: number): CouchbaseSourceDataNode {
    return new CouchbaseSourceDataNode(node, level);
  }
}
