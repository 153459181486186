import { BasePortalOutlet, CdkPortalOutlet, ComponentPortal, TemplatePortal, PortalModule } from '@angular/cdk/portal';
import {
  Component,
  ComponentRef,
  EmbeddedViewRef,
  Inject,
  Injector,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { HelixIntlService } from '../../helix-intl.service';

import { DialogData } from './app-panel.service';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';



/**
 * @description
 * Container for rendering components in AppPanel overlay.
 * Note: This component is managed by AppPanelService. You don't need to add it explicitly to your template.
 */
@Component({
  selector: 'cog-app-panel',
  templateUrl: './app-panel.component.html',
  styleUrls: ['./app-panel.component.scss'],
  standalone: true,
  imports: [PortalModule, MatLegacyButtonModule, MatIconModule]
})
export class AppPanelComponent extends BasePortalOutlet implements OnDestroy {

  /**
   * PortalOutlet where component will be rendered.
   */
  @ViewChild(CdkPortalOutlet, { static: true }) private _portalOutlet: CdkPortalOutlet;

  constructor(
    private dialogRef: MatDialogRef<AppPanelComponent>,
    private injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public intl: HelixIntlService) {
    super();
  }

  /**
   * Attaches a portal component to AppPanelComponent.
   *
   * @param  portal  ComponentOrTemplate.
   */
  attach(portal: any): any {
    if (portal instanceof TemplateRef) {
      return this.attachTemplatePortal(
        new TemplatePortal(portal, undefined, <any>{
          dialogRef: this.dialogRef
        }));
    } else {
      const injector = this.createInjector();
      return this.attachComponentPortal(new ComponentPortal(portal, undefined, injector));
    }
  }

  /**
   * Whether AppPanelComponent has an attached portal.
   */
  hasAttached(): boolean {
    if (this._portalOutlet) {
      return this._portalOutlet.hasAttached();
    }

    return false;
  }

  /**
   * Destroys component.
   */
  ngOnDestroy() {
    this.dialogRef.close();
    this.dispose();
  }

  /**
   * Attaches the given TemplatePortal to this PortlHost as an embedded View.
   *
   * @param    portal   Portal to be attached.
   * @returns           Reference to the created embedded view.
   */
  attachTemplatePortal<T>(portal: TemplatePortal<T>): EmbeddedViewRef<T> {
    if (!this._portalOutlet.hasAttached()) {
      return this._portalOutlet.attachTemplatePortal(portal);
    }
  }

  /**
   * Attach the given ComponentPortal to this PortalOutlet using the ComponentFactoryResolver.
   *
   * @param    portal  Portal to be attached to the portal outlet.
   * @returns          Reference to the created component.
   */
  attachComponentPortal<C>(portal: ComponentPortal<C>): ComponentRef<C> {
    if (!this._portalOutlet.hasAttached()) {
      return this._portalOutlet.attachComponentPortal(portal);
    }
  }

  /**
   * Handles close button click event and closes active overlay.
   */
  close() {
    this.dialogRef.close();
  }

  /**
   * Returns injector that will be passed to AppPanelComponent and a component that it attaches.
   */
  private createInjector(): Injector {
    return Injector.create({ parent: this.injector, providers: [{ provide: MatDialogRef, useValue: this.dialogRef }] });
  }
}
