<div class="invisible">
  <ng-template cohFormSectionHost></ng-template>
</div>

<ng-container *ngIf="!isLoading; else spinner">
  <div cogFormFocusedHighlight>
    <ng-container *ngIf="formSectionRefs$ | async as visibleFormSectionRefs">
      <ng-container *ngFor="let formSectionRef of visibleFormSectionRefs">
        <ng-container *ngIf="isFormSectionVisible(formSectionRef)">
            <mat-card cogFormGroup *ngIf="
              formSectionRef.componentRef.instance?.formSectionEditMode?.templateRef &&
              formSectionRef.componentRef.instance?.mode === 'edit'
            ">
              <ng-container
                *ngTemplateOutlet="formSectionRef.componentRef.instance.formSectionEditMode.templateRef">
              </ng-container>
            </mat-card>

            <mat-card cogFormGroup *ngIf="
              formSectionRef.componentRef.instance?.formSectionViewMode?.templateRef &&
              formSectionRef.componentRef.instance?.mode === 'view'
            ">
              <div class="flex-row-md">
                <div class="flex-grow">
                  <ng-container
                    *ngTemplateOutlet="formSectionRef.componentRef.instance.formSectionViewMode.templateRef">
                  </ng-container>
                </div>

                <ng-container
                  *ngIf="
                    formSectionRef.componentRef.instance?.formSectionViewCta?.templateRef
                  ">
                  <ng-container
                    *ngTemplateOutlet="
                      formSectionRef.componentRef.instance?.formSectionViewCta?.templateRef
                    ">
                  </ng-container>
                </ng-container>
              </div>
            </mat-card>

          <div class="margin-top-lg margin-bottom-lg" *ngIf="
            formSectionRef.componentRef.instance?.formSectionNavigator?.templateRef &&
            formSectionRef.componentRef.instance?.mode === 'edit'
          ">
            <ng-container *ngTemplateOutlet="
              formSectionRef.componentRef.instance.formSectionNavigator.templateRef;
              context: { componentRef: formSectionRef.componentRef }
            "
            ></ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <div class="margin-top-lg margin-bottom-lg" *ngIf="canShowSaveForm()">
    <ng-container *ngTemplateOutlet="defaultSaveButton"></ng-container>
  </div>
</ng-container>

<ng-template #defaultSaveButton>
  <div class="flex-row-sm">
    <button
      [disabled]="isSubmitting || formGroup.invalid"
      cogDataId="start-scan"
      mat-button
      cogSubmitButton
      [loading]="isSubmitting"
      (click)="saveForm()">
      {{ saveButtonTitle ? saveButtonTitle : ('create' | translate) }}
    </button>
    <button
      [disabled]="isSubmitting"
      mat-button
      cogCancelButton
      cogDataId="cancel-scan"
      (click)="goBack()">
      {{ 'cancel' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #spinner>
  <cog-spinner></cog-spinner>
</ng-template>
