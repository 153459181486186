import { CommonRecoverObjectSnapshotParams, RecoverVmwareSnapshotParams } from '@cohesity/api/v2';

import { SnapshotTargetType } from './snapshot-target-type';

/**
 * Data model for Recovery Detail page object table.
 */
export class RecoveryObject {
  /**
   * Recovery object ID.
   */
  id: string;

  /**
   * UUID.
   */
  uuid: string;

  /**
   * Recovery object snapshot ID.
   */
  snapshotId: string;

  /**
   * Recovery object source ID.
   */
  sourceId: string;

  /**
   * Recovery object source name.
   */
  sourceName: string;

  /**
   * Name.
   */
  name: string;

  /**
   * Full name including prefix, suffix.
   */
  fullName: string;

  /**
   * Protection Group ID.
   */
  protectionGroupId: string;

  /**
   * Protection Group Name.
   */
  protectionGroupName: string;

  /**
   * Snapshot Target type.
   */
  snapshotTargetType: SnapshotTargetType;

  /**
   * Archival Target Info.
   */
  archivalTargetInfo: any;

  /**
   * Environment.
   */
  environment: string;

  /**
   * OS Type.
   */
  osType: string;

  /**
   * Object Type.
   */
  objectType: string;

  /**
   * Recovery object ID.
   */
  logicalSize: number;

  /**
   * The snapshot creation time.
   */
  creationTime: number;

  /**
   * The start time of last run task.
   */
  startTime: number;

  /**
   * Duration of last run.
   */
  durationMs?: number;

  /**
   * The status.
   */
  status: string;

  /**
   * The tear down status.
   */
  tearDownStatus?: string;

  /**
   * Number of tear down attempt.
   */
  tearDownAttempts?: number;

  /**
   * Error messages.
   */
  messages: string[];

  /**
   * Storage domain id for the recovery.
   */
  storageDomainId: number;

  /**
   * An ID used to query the progress of currently running task.
   */
  progressTaskId: string;

  /**
   * The datastore migration status.
   */
  datastoreMigrationStatus: string;

  /**
   * An ID used to query the progress of currently datastore migration task.
   */
  datastoreMigrationProgressTaskId: string;

  /**
   * The instant recovery status.
   */
  instantRecoveryStatus: string;

  /**
   * An ID used to query the progress of currently instant recovery task.
   */
  instantRecoveryProgressTaskId: string;


  /**
   * The snapshot timestamp (in usecs from epoch) of the object which was used in this recovery.
   */
  recoveryPoint: number;

  /**
   * The total size of the object restored.
   */
  bytesRestored: number;

  /**
   * Specifies granular restore metadata info.
   */
  granularRestoreInfo?: string;

  /**
   * Task Id of the recovery.
   */
  taskId?: number;

  /**
   * Specifies whether the current selection is a point-in-time between the snapshots.
   */
  isPointInTime?: boolean;

  /**
   * Constructor.
   *
   * @param   reference   Return data structure of Common Recovery Object from API
   */
  constructor(public reference: CommonRecoverObjectSnapshotParams, renameParams?: any) {
    const {
      snapshotId,
      snapshotCreationTimeUsecs,
      pointInTimeUsecs,
      startTimeUsecs,
      endTimeUsecs,
      status,
      progressTaskId,
      snapshotTargetType,
      storageDomainId,
      archivalTargetInfo,
      protectionGroupId,
      protectionGroupName,
      messages,
      bytesRestored,
      objectInfo: {
        id,
        uuid,
        sourceId,
        sourceName,
        name,
        environment,
        osType,
        objectType,
        logicalSizeBytes
      } = {}
    }: CommonRecoverObjectSnapshotParams = reference;

    this.id = id.toString();
    this.uuid = uuid;
    this.snapshotId = snapshotId;
    this.sourceId = sourceId ? sourceId.toString() : undefined;
    this.sourceName = sourceName;
    this.storageDomainId = storageDomainId;
    this.fullName = this.name = name;
    if (renameParams) {
      this.fullName = `${renameParams.prefix || ''}${name}${renameParams.suffix || ''}`;
    }

    this.protectionGroupId = protectionGroupId;
    this.protectionGroupName = protectionGroupName;
    this.snapshotTargetType = snapshotTargetType;
    this.archivalTargetInfo = archivalTargetInfo;
    this.environment = environment;
    this.osType = osType;
    this.objectType = objectType;
    this.logicalSize = logicalSizeBytes;

    this.status = status;
    this.messages = messages || [];
    this.creationTime = snapshotCreationTimeUsecs;
    this.startTime = startTimeUsecs;
    if (endTimeUsecs && startTimeUsecs) {
      this.durationMs = Math.round((endTimeUsecs - startTimeUsecs) / 1000);
    }
    this.bytesRestored = bytesRestored;
    this.progressTaskId = progressTaskId;
    this.recoveryPoint = pointInTimeUsecs || snapshotCreationTimeUsecs;
    this.isPointInTime = !!pointInTimeUsecs;
    this.setVmwareParams(reference as RecoverVmwareSnapshotParams);
  }

  /**
   * Check if datastoreMigrationInfo and instantRecoveryInfo exist and set vmware attributes accordingly.
   *
   * @param   vmwareParams   Recovery Object data structure from API
   */
  setVmwareParams(vmwareParams: RecoverVmwareSnapshotParams) {
    if (!vmwareParams.instantRecoveryInfo) {
      return;
    }

    const {
      status: instantRecoveryStatus,
      progressTaskId: instantRecoveryProgressTaskId
    } = vmwareParams.instantRecoveryInfo;

    this.instantRecoveryStatus = instantRecoveryStatus;
    this.instantRecoveryProgressTaskId = instantRecoveryProgressTaskId;

    if (vmwareParams.datastoreMigrationInfo) {
      const {
        status: datastoreMigrationStatus,
        progressTaskId: datastoreMigrationProgressTaskId
      } = vmwareParams.datastoreMigrationInfo;

      this.datastoreMigrationStatus = datastoreMigrationStatus;
      this.datastoreMigrationProgressTaskId = datastoreMigrationProgressTaskId;
    }
  }
}
