// Service: recovery store

;(function(angular, undefined) {
  'use strict';

  angular.module('C.recoveryStore', [])
    .service('RecoveryStore', RecoveryStoreFn);

  /**
   * @ngdoc service
   * @name C.recoveryStore:RecoveryStore
   * @function
   *
   * @description
   * Provides a sharable store for the storage volume shared data.
   * This uses the Javascript define property along with getter and setter
   * internally.
   *
   * Also state can be shared b/n components without worrying about reference.
   *
   * @example
   *
   * 1. Create a new store.
   *    RecoveryStore.init({object})
   *
   * 2. Get the store which is already initialized
   *    RecoveryStore.get()
   *
   * 3. Clear an existing store.
   *    RecoveryStore.clear()
   */
  function RecoveryStoreFn(_, $log) {
    // The actual state is stored in _store where as
    // keys of the store is used for binding the getters and setters.
    var store = {};
    var _store = {};

    return {
      init: _initStore,
      get: _getStore,
      clear: _clearStore,
    };

    /**
     * Initialize a new store
     *
     * @method _initStore
     * @param {object} newStore
     */
    function _initStore(newStore) {
      // if the store already exists throw an error
      if (!_.isEmpty(store)) {
        $log.error(
          'C.recoveryStore:RecoveryStore:',
          'Please clear the existing store before initializing a new one.'
        );
      }

      // assign setters and getters
      Object.keys(newStore).forEach(function eachKey(key) {
        Object.defineProperty(store, key, {
          get: function getCacheProperty() {
            return _store[key];
          },
          set: function setCacheProperty(newValue) {
            _store[key] = newValue;
            return _store[key];
          },
        });

        store[key] = newStore[key];
      });
      return _getStore();
    }

    /**
     * Clear an existing store
     *
     * @method    _clearStore
     */
    function _clearStore() {
      // clear both state and the properties.
      _store = {};
      store = {};
    }

    /**
     * Get the state initialized store
     *
     * @method    _getStore
     */
    function _getStore() {
      return store;
    }
  }
})(angular);
