import { Injectable } from '@angular/core';
import { joinUrl, normalizeUrl } from '@cohesity/helix';

import { ProjectAssetConfig } from '../micro-frontend.models';
import { MicroFrontendOptions } from './micro-frontend.options';

// In single-spa, the assets need to be loaded from a dynamic location,
// instead of hard coded to `/assets`. We use webpack public path for this.
// See https://webpack.js.org/guides/public-path/#root
declare let __webpack_public_path__: string;

/**
 * Auto Detect the public path.
 */
(() => {
  let publicPath: string;

  try {
    const allScripts = Array.from(document.getElementsByTagName('script')).filter(el => el.src);

    if (allScripts.length) {
      const scriptUrl = allScripts[allScripts.length - 1].src;
      publicPath = normalizeUrl(new URL(scriptUrl).pathname.split('/').slice(0, -1).filter(Boolean).join('/'));

      __webpack_public_path__ = publicPath ? `/${publicPath}/`: publicPath;
    }
  } catch (error) {
    publicPath = '';
  }
})();

@Injectable()
export class MicroFrontendService {
  get appConfig() {
    return this.microFrontendOptions.options.appConfig;
  }

  get projectConfig() {
    return this.microFrontendOptions.options.projectConfig;
  }

  constructor(private microFrontendOptions: MicroFrontendOptions) {}

  /**
   * Get the root container class for the app.
   *
   * @returns Return the root container class for the app.
   */
  getRootContainerClassList = () => [
    this.appConfig.rootContainerClassName,
    'mat-typography'
  ];

  /**
   * Indicating whether the app should be active for a given current url.
   *
   * @returns Return true if url for the app matches with configured urlSegment else return false.
   */
  isAppRoute = () => !!window.location.pathname.match(new RegExp(`^${this.appConfig.urlSegment}.*`));

  /**
   * Return the app base href.
   *
   * @returns The app base href value.
   */
  getAppBaseHref = () => this.isAppRoute() ? this.appConfig.urlSegment : '/';

  /**
   * Return the argus app's public path.
   *
   * @returns The webpack's public path.
   */
  getPublicPath = (): string => {
    let publicPath: string;

    // Catching exception because while running jest unit test webpack public path will not be defined.
    try {
      publicPath = __webpack_public_path__;
    } catch (error) {
      publicPath = '';
    }

    return publicPath;
  };

  /**
   * Add public path as a suffix to input url.
   *
   * @param url The url.
   * @returns The modified url.
   */
  addPublicPath = (url: string): string => `/${joinUrl([this.getPublicPath(), url])}`;

  /**
   * Get the list of static assets relative path for a given app assets config.
   *
   * @param assets The static assets configured for the app.
   * @returns The list of static assets relative path for a given app assets config.
   */
  getAssetPaths = (assets: ProjectAssetConfig[]): string[] => (
    assets.map(asset => {
      if (typeof asset === 'string') {
        // eg. assets =['apps/argus/src/intercom.js']
        const fileName = normalizeUrl(asset).replace(new RegExp(`^${this.projectConfig.sourceRoot}`, 'gmi'), '');
        return normalizeUrl(fileName);
      } else if (asset.glob.includes('*')) {
        if (normalizeUrl(asset.output) === '/') {
          console.error('getAssetPaths: external assets should not be kept at project root / folder consider moving it inside a folder /lib', asset);
          return;
        }

        // eg. assets =[{ glob: '**/*', input: 'libs/helix/helix-assets', output: 'helix-assets' }]
        return normalizeUrl(asset.output as string);
      }

      // eg. assets =[{ glob: 'feature-flags.json', input: 'apps/argus/src/', output: '/' }]
      return joinUrl([asset.output, asset.glob]);
    }).filter(Boolean)
  );

  /**
   * Get the assets regex for matching project's static assets.
   *
   * @returns The regex for matching project's static assets.
   */
  getAssetRegex = () => new RegExp(`^/{0,1}(${normalizeUrl(this.getPublicPath())}/){0,1}((${this.getAssetPaths(this.projectConfig.assets).join('|')}).*)`, 'mi');
}
