<ng-container *ngIf="scanInfo">
  <div class="status-container">
    <div class="c-task-status-pill" [ngClass]="{'c-task-error-pill': ['kCritical', 'kHigh'].includes(scanInfo?.scanStatus)}">
      {{ (scanInfo?.scanStatus || 'kUnknown') | translate }}
    </div>
  </div>
  <!-- TODO: enable this once on demand scan with snapshot is supported by cyberscan -->
  <!-- <button mat-icon-button
    *ngIf="scanParams"
    cogDataId="btn-vulscan-start-scan-{{ name }}"
    (click)="startScan()"
    [matTooltip]="'startVulScan' | translate"
    matTooltipPosition="above">
    <cog-icon shape="filter_center_focus"></cog-icon>
  </button>
  <button mat-icon-button
    *ngIf="scanInfo?.result?.reportUrl && extensionData?.clusterId"
    cogDataId="btn-vulscan-download-{{ name }}"
    (click)="download()"
    [matTooltip]="'downloadVulReport' | translate"
    matTooltipPosition="above">
    <cog-icon shape="insert_chart_outlined"></cog-icon>
  </button> -->
</ng-container>
