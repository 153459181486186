import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyNativeDateModule as MatNativeDateModule } from '@angular/material/legacy-core';
import { TranslateModule } from '@ngx-translate/core';

import { PipesModule } from '../pipes';
import { CalendarHeaderComponent } from './calendar-header/calendar-header.component';
import { CalendarComponent } from './calendar.component';

const components = [
  CalendarComponent,
  CalendarHeaderComponent,
];

@NgModule({
  imports: [
    MatDatepickerModule,
    MatIconModule,
    MatNativeDateModule,
    MatButtonModule,
    PipesModule,
    TranslateModule.forChild(),
  ],
  declarations: [
    ...components,
  ],
  exports: [
    ...components,
  ],
})
export class CalendarModule { }
