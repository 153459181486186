import {
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { RegisterRemoteCluster } from '@cohesity/api/v1';
import { ExternalTarget, StorageDomain, StorageDomainServiceApi } from '@cohesity/api/v2';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { ItemPickerFormControl } from '@cohesity/shared-forms';
import { StateService } from '@uirouter/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, filter, finalize, map } from 'rxjs/operators';
import { ClusterService, DialogService, PassthroughOptionsService, RemoteClusterService } from 'src/app/core/services';
import {
  NgceCreateStorageDomainComponent,
} from 'src/app/modules/cluster/storage-domains/ngce-create-storage-domain-dialog/ngce-create-storage-domain-dialog.component';

import { ONLY_OWN } from '../../directives';

/**
 * This component implements the view box selector for use within angular forms.
 * The component will fetch a list of storage domains and show them in a
 * mat-select box. It implements ControlValueAccessor so that it can be used
 * within angular's form modules. The model value will be the id of the
 * storage domain. The control adds a button to create a new storage domain to
 * the select options.
 *
 * @example
 * <coh-storage-domain-selector formControlName="storageDomain">
 * </coh-storage-domain-selector>
 */
@Component({
  selector: 'coh-storage-domain-selector',
  templateUrl: './storage-domain-selector.component.html',
  styleUrls: ['./storage-domain-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StorageDomainSelectorComponent),
      multi: true,
    },
  ],
})
export class StorageDomainSelectorComponent extends ItemPickerFormControl<number>
  implements OnInit, OnChanges {
  /**
   * Whether add new should be enabled
   */
  @Input() public addNewEnable = false;

  /**
   * Whether the component should show in read only mode
   */
  @Input() public readOnly = false;

  /**
   * Default storage domain id to select
   */
  @Input() defaultStorageDomainId: number;

  /**
   * Optionally pass the remote cluster details to list the
   * storage domains in that remote cluster.
   */
  @Input() remoteCluster: RegisterRemoteCluster;

  /**
   * Float label passed on to determine mat-form-field behavior.
   */
  @Input() floatLabel = 'always';

  /**
   * Optionally pass the label to be displayed as the placeholder.
   */
  @Input() labelKey = 'storageDomain';

  /**
   * Optional. If enabled, policy selector will have searching.
   */
  @Input() allowSearch = true;

  /**
   * Optional. ID to prepend to the ID in the template.
   */
  @Input() id = '';

  /**
   * Optional. If enabled, autoselect the singular option.
   */
  @Input() autoSelectSd = true;

  /**
   * Optional. If true, select DefaultStorageDomain by default.
   */
  @Input() autoSelectDefaultStorageDomain? = false;

  /**
   * Optionally pass a function to check if the selection should be disabled or
   * not.
   */
  @Input() isSelectionDisabled: (storageDomain: StorageDomain) => boolean;

  /**
   * Optional. If true, storage domain selection is required by parent form.
   */
  @Input() storageDomainRequired = false;

  /**
   * Whether to hide/show the synopsis.
   */
  @Input() hideSynopsis = false;

  /**
   * Associated cluster id to fetch list of storage domains.
   * Passing accessClusterId as input will override the cluster id from PassthroughOptionsService
   */
  @Input() accessClusterId: null | number = null;

  /**
   * Indicated whether it's used for archive services view/template. Default to false.
   */
  @Input() isForArchiveServices = false;

  /**
   * Whether this is used in a remote clusters registration flow.
   */
  @Input() isForRemoteClustersRegistration = false;

  /**
   * Optionally pass storage domain list to avoid fetching from API.
   */
  @Input() storageDomainList?: null | StorageDomain[] = null;

  /**
   * Optionally pass handleSelection to handle when selection is changed.
   */
  @Output() handleSelection = new EventEmitter<StorageDomain>();

  /**
   * Form Control for storage domain.
   */
  storageDomainControl = new UntypedFormControl();

  /**
   * Form Control for searching the values.
   */
  searchCtrl = new UntypedFormControl();

  /**
   * Whether an API call is pending right now.
   */
  loading = false;

  /**
   * Gets the currently selected view object
   *
   * @return   The selected view
   */
  get selectedView(): StorageDomain {
    if (this.value && this.storageDomains) {
      return this.storageDomains.find(view => view.id === this.value);
    }
    return undefined;
  }

  /**
   * Convenience/passthrough function for improved getter name.
   */
  get selectedStorageDomain(): StorageDomain {
    return this.selectedView;
  }

  /**
   * Return true if cluster is NGCE.
   */
  get cloudEditionEnabled(): boolean {
    return this.clusterService.isClusterNGCE;
  }

  /**
   * Observable containing all filtered view boxes.
   */
  storageDomains$ = new BehaviorSubject<StorageDomain[]>([]);

  /**
   * The loaded initial viewboxes.
   */
  private storageDomains: StorageDomain[];

  /**
   * Used to register new view boxes
   */
  private ajsViewBoxService: any;

  /**
   * Creates a new component
   *
   * @param   injector            use to look up the legacy view box service.
   *                              The ajs upgrade service doesn't work with forward ref
   * @param   viewBoxesService    The view box api service
   * @param   [onlyOwnService]    If included, will be used to decorate api params to
   *                              add get own entity params.
   */
  constructor(
    private injector: Injector,
    private clusterService: ClusterService,
    private dialogService: DialogService,
    private irisCtx: IrisContextService,
    private passthroughOptionsService: PassthroughOptionsService,
    private remoteClusterService: RemoteClusterService,
    private stateService: StateService,
    private storageDomainService: StorageDomainServiceApi,
    @Optional() @Inject(ONLY_OWN) private onlyOwn: boolean = false
  ) {
    super();
  }

  /**
   * Initialize and retrieve the viewbox list
   */
  ngOnInit() {
    if (this.storageDomainRequired) {
      this.storageDomainControl.setValidators((Validators.required));
      this.storageDomainControl.updateValueAndValidity();
    }

    this.ajsViewBoxService = this.injector.get<{[key: string]: Function}>('$injector' as any).get('ViewBoxService');

    if (this.storageDomainList) {
      this.initStorageDomains(this.storageDomainList);
    } else {
      this.loading = true;
      const params = {
        includeTenants: true,
        ...this.passthroughOptionsService.requestParams,
        ...(this.onlyOwn && !this.isForRemoteClustersRegistration && { includeTenants: false }),
        ...(this.accessClusterId ? { accessClusterId: this.accessClusterId } : {})
      };
      // If remote cluster exists then get the storage domains linked to that remote cluster.
      (!this.remoteCluster ? this.storageDomainService.GetStorageDomains(params).pipe(
        map(resp => resp.storageDomains)
      ) : this.remoteClusterService.getRemoteViewBoxes(this.remoteCluster))
        .pipe(
          this.untilDestroy(),
          finalize(() => this.loading = false)
        )
        .subscribe(storageDomains => this.initStorageDomains(storageDomains));
    }

    if (this.allowSearch) {
      // If allows search is set, filter results.
      this.searchCtrl.valueChanges.pipe(
        debounceTime(150),
        this.untilDestroy(),
      ).subscribe(searchString => {
        const searchStringRegex = new RegExp(searchString, 'i');
        const filteredValues = this.storageDomains.filter(
          (value) => value.name.search(searchStringRegex) > -1
        );

        this.storageDomains$.next(filteredValues);
      });
    }

    if (this.id) {
      this.id = this.id + '-';
    }
  }

  /**
   * Initialize the storage domain list.
   *
   * @param storageDomains
   */
  initStorageDomains(storageDomains: StorageDomain[]) {
    if (this.isForArchiveServices) {
      storageDomains = storageDomains.filter(sd => !sd.storagePolicy?.deduplicationParams?.enabled);
    }
    this.storageDomains = storageDomains || [];
    this.storageDomains$.next(storageDomains);

    if (this.defaultStorageDomainId) {
      this.value = this.defaultStorageDomainId;
    } else if ((this.autoSelectSd && this.storageDomains.length === 1) ||
      (this.autoSelectDefaultStorageDomain && this.storageDomains.length > 0)) {
      let storageDomain: StorageDomain;

      if (this.autoSelectDefaultStorageDomain) {
        storageDomain = this.storageDomains.find(sd => sd.name === 'DefaultStorageDomain');
      } else {
        // If there's only one storage domain in the system,
        // select it by default.
        storageDomain = this.storageDomains[0];
      }

      this.value = storageDomain.id;
      this.storageDomainControl.setValue(this.value);
    } else if (this.autoOpen && !this.readOnly) {
      this.matSelect.open();
    }
  }

  /**
   * Update storage domain on changes.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.defaultStorageDomainId && this.defaultStorageDomainId) {
      this.value = this.defaultStorageDomainId;
      this.storageDomainControl.setValue(this.value);
    }
  }

  /**
   * Handle adding new storage, then updates and selects the list when finished
   */
  addStorage() {
    if (this.cloudEditionEnabled) {
      this.dialogService.showDialog(NgceCreateStorageDomainComponent)
      .subscribe((newViewBox: ExternalTarget) => {
        const viewBoxId = newViewBox?.cloudDomains.length ?
          newViewBox?.cloudDomains[0]?.viewBoxId :
          undefined;
        if (viewBoxId) {
          this.getStorageDomainDetailsById(viewBoxId);
        }
      });
    } else if (flagEnabled(this.irisCtx.irisContext, 'ngStorageDomainsModify')) {
      this.dialogService.showDialog('ng-modify-storage-domain-dialog', {}, {
        panelClass: 'modify-storage-domain-container',
        minWidth: '100vw',
        height: '100vh',
      }).pipe(
        filter(value => Boolean(value)),
        this.untilDestroy()
      ).subscribe((newSD: StorageDomain) => {
        if (newSD) {
          this.updateStorageDomainsLists(newSD);
        }
      });
    } else {
      this.ajsViewBoxService.newViewBoxModal().then(newViewBox => {
        const storageDomain: StorageDomain = this.ajsViewBoxService.transformViewBoxToStorageDomain(newViewBox);
        this.updateStorageDomainsLists(storageDomain);
      });
    }
  }

  /**
   * Fetches the storage domain details from API.
   * This is required as NGCE uses external target PUT
   * API for creation/updation of storage domains.
   *
   * @param id StorageDomain Id
   */
  getStorageDomainDetailsById(id: number) {
    this.storageDomainService.GetStorageDomainById({id})
      .pipe(
        this.untilDestroy(),
      )
      .subscribe((storageDomain: StorageDomain) => {
        if (storageDomain?.id) {
          this.updateStorageDomainsLists(storageDomain);
        }
      });
  }

  /**
   * Update the existing list of storage domains.
   *
   * @param newViewBox StorageDomain Object
   */
  updateStorageDomainsLists(newViewBox) {
    this.storageDomains.push(newViewBox);
    this.storageDomains$.next(this.storageDomains);
    this.value = newViewBox.id;
    this.storageDomainControl.setValue(newViewBox.id);
  }

  /**
   * Check if the option is disabled or not.
   *
   * @param storageDomain The StorageDomain to check.
   * @returns True, if disabled, else false.
   */
  isDisabled(storageDomain: StorageDomain) {
    return this.isSelectionDisabled && this.isSelectionDisabled(storageDomain);
  }


  /**
   * Callback when a view box is selected.
   *
   * @param  storageDomain The view box clicked.
   */
  selectStorageDomain(storageDomain: StorageDomain) {
    if (this.handleSelection.observers.length) {
      this.handleSelection.emit(storageDomain);

      if (!this.isDisabled(storageDomain)) {
        this.storageDomainControl.setValue(storageDomain.id);
      }
    }
  }
}
