<cog-value-property-filter *cogFilterDef="let filter;
    let params = params;
    quickFilter true;
    key: filterGroup.id;
    label: filterGroup.label | translate;
    filterType: 'valueProperty'"
  [class.hidden]="hideFilter"
  [filterDefParams]="params"
  [autoApply]="true"
  [noClear]="disableClear"
  [filter]="filter"
  [allowMultiple]="false"
  [alphaSort]="false"
  [filterValues]="filterValues">
</cog-value-property-filter>
