import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Controls, NgxSubFormComponent, subformComponentProviders } from 'ngx-sub-form';

import { NoSqlObjectProperty } from '../../../model/nosql-form-options';

@Component({
  selector: 'coh-settings-list-object-property-item',
  templateUrl: './settings-list-object-property-item.component.html',
  styleUrls: ['./settings-list-object-property-item.component.scss'],
  providers: subformComponentProviders(SettingsListObjectPropertyItemComponent),
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsListObjectPropertyItemComponent extends NgxSubFormComponent<NoSqlObjectProperty> {

  /**
   * Object properties keys.
   */
  @Input() propertyKeys: string[];

  /**
   * Output emitter to add object property.
   */
  @Output() addProp = new EventEmitter();

  /**
   * Output emitter to remove object property.
   */
  @Output() removeProp = new EventEmitter();

  protected getFormControls(): Controls<NoSqlObjectProperty> {
    return {
      key: new UntypedFormControl(''),
      value: new UntypedFormControl(''),
    };
  }

  /**
   * Handler for adding object property.
   */
  addProperty() {
    this.addProp.emit();
  }

  /**
   * Handler to removing object property.
   */
  removeProperty() {
    this.removeProp.emit();
  }

  /**
   * Handler to removing object property.
   */
  allowAddingProperty(): boolean {
    let invalid = false;
    if (!this.formGroupValues.key || !this.formGroupValues.value) {
      invalid = true;
    }
    return invalid;
  }
}
