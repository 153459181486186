// cMessage Service

;(function(angular, undefined) {
  'use strict';

  var currentMsg;
  var currentMsgOptions;

  angular
    .module('C')
    .service('cMessage', cMessageFn);

  /**
   * @ngdoc service
   * @name cMessage
   *
   * @description
   * Service for displaying a stylized message to the user.
   *
   * @examples

     // displays an info cMessage with context provided to be parsed, to be
     // closed after 6000ms but will survive/persist state changes
     cMessage.info({
        // key for string in ui.json, in this case:
        // 'welcomeToPlanet': 'Welcome to {{::planet}}',
        titleKey: 'welcomeToPlanet',

        // provides the values needed for inclusion in the titleKey string
        titleKeyContext: {
          planet: 'Venus',
        },

        // key for string in ui.json, in this case:
        // 'helloWorldName': 'Hello World, {{::name}}',
        textKey: 'helloWorldName',

        // provides the values needed for inclusion in the textKey string
        textKeyContext: {
          name: 'Steve',
        },

        // providing a timeout value will result in the message self closing
        timeout: 6000,

        // use persist option to prevent the message/modal close on state change
        persist: true,
     });

     // displays a standard informational cMessage
     cMessage.info({
        textKey: 'helloWorld',
     });

   */
  function cMessageFn($uibModal) {

    return {
      info: info,
      success: success,
      warn: warn,
      error: error,
    };

    /**
     * shows an information cMessage
     *
     * @param      {object}   opts    The options
     * @return     {promise}  uibModalInstance result, so calling controllers
     *                        can react via .then(success/failure)
     */
    function info(opts) {
      opts.type = 'info';

      return showMessage(opts);
    }

    /**
     * shows an success cMessage.
     * default behavior provides a 4 second timeout that persists state changes,
     * as this is a common scenario for success messages.
     *
     * @param      {object}   opts    The options
     * @return     {promise}  uibModalInstance result, so calling controllers
     *                        can react via .then(success/failure)
     */
    function success(opts) {

      opts = angular.merge({
        timeout: 6000,
        persist: true,
      }, opts);

      opts.type = 'success';

      return showMessage(opts);
    }

    /**
     * shows a warning cMessage
     *
     * @param      {object}   opts    The options
     * @return     {promise}  uibModalInstance result, so calling controllers
     *                        can react via .then(success/failure)
     */
    function warn(opts) {
      opts.type = 'warn';

      return showMessage(opts);
    }

    /**
     * shows an error cMessage
     *
     * @param      {object}   opts    The options
     * @return     {promise}  uibModalInstance result, so calling controllers
     *                        can react via .then(success/failure)
     */
    function error(opts) {
      opts.type = 'error';

      // 15 second default value to match ajax-handler.service.ts.
      opts.duration = opts.duration || 15000;

      return showMessage(opts);
    }

    /**
     * private function which handles displaying a cMessage
     *
     * @param      {object}   opts    The options
     * @return     {promise}  uibModalInstance result, so calling controllers
     *                        can react via .then(success/failure)
     */
    function showMessage(opts) {

      var modalConfig;

      opts = angular.extend({
        titleKey: '',
        textKey: '',
        acknowledgeTextKey: '',
        dismissTextKey: '',
        type: 'info',
        persist: false,
        dialog: !!opts.acknowledgeTextKey || !!opts.dismissTextKey,
        timeout: 0,
      }, opts);

      // Sanitize a potentially user-entered value before displaying as html.
      opts.textKey = _sanitizeHtml(opts.textKey);

      modalConfig = {
        // Disabling animation to better emulate Angular Material Snack Bar.
        backdrop: false,
        windowTemplateUrl: 'app/global/c-message/c-message-modal-window.html',
        windowClass: 'c-message-window',
        templateUrl: 'app/global/c-message/c-message-content.html',
        openedClass: 'c-message-open',
        controller: messageControllerFn,
        resolve: {
          opts: opts,
        }
      };

      // If the current message is a dialog, we want to leave it in play.
      // It will be hidden via CSS so it will return to view once the new
      // cMessage is closed/dismissed.
      if (currentMsg && !currentMsgOptions.dialog) {
        currentMsg.dismiss('auto closing to make room for another cMessage');
      }

      currentMsg = $uibModal.open(modalConfig);
      currentMsgOptions = opts;

      currentMsg.closed.then(function cleanUp(resp) {
        currentMsg = undefined;
        currentMsgOptions = undefined;
        return resp;
      });

      return currentMsg.result;

    }

    /**
     * Sanitizes a string with HTML characters. Then it can be safely rendered
     * in the template.
     *
     * @method   _sanitizeHtml
     * @param    {String}    str    A string to sanitize of HTML.
     * @return   {String}    The sanitized string.
     */
    function _sanitizeHtml(str) {
      return str
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/"/g, '&quot;');
    }

    /* @ngInject */
    function messageControllerFn(
      $scope, $timeout, $transitions, $uibModalInstance, opts) {

      var timeoutPromise;

      /**
       * provides inline styles for the .top-bar
       * this allows for inline styles to be generated for
       * transition properties based on cMessage timeout value.
       *
       * @return     {Object}  The inline styles.
       */
      $scope.getInlineStyles = function getInlineStyles() {

        // when no timeout is requested, inline styles aren't necessary.
        if (!opts.timeout) {
          return {};
        }

        var transitionDuration = [opts.timeout / 1000, 's'].join('');

        return {
          'transition-duration': transitionDuration
        };
      };

      /**
       * indicates if a countdown/timeout to close the cMessage is active
       *
       * @return     {boolean}  True if timeout active, False otherwise.
       */
      $scope.isTimeoutActive = function isTimeoutActive() {
        return !!timeoutPromise;
      };

      /**
       * Prevent an in-progress cMessage timeout close from happenning.
       */
      $scope.abortTimeout = function abortTimeout() {
        $timeout.cancel(timeoutPromise);
        timeoutPromise = undefined;
      };

      /**
       * closes the cMessage with dismiss() to pass to the recieving failure
       * function
       */
      $scope.dismissDialog = function dismissDialog() {
        $scope.abortTimeout();
        $uibModalInstance.dismiss('dialog dismissed');
      };

      /**
       * closes the cMessage with close() to pass to the recieving success
       * function
       */
      $scope.acknowledgeDialog = function acknowledgeDialog() {
        $scope.abortTimeout();
        $uibModalInstance.close('dialog success');
      };

      /**
       * function to get this controller up and running
       */
      function activate() {
        var removeHandler;

        // expose options to the template
        $scope.opts = opts;

        // If a timeout value was provided for the cMessage, initiate a $timeout
        // to dismiss the modal after the specified number of milliseconds. The
        // timeout countdown is started inside of a timeout to allow time for
        // the inline transition styles to be applied to the div before the
        // modifier class is added. Without this delay, there will be no
        // animation/transition.
        if (opts.timeout) {
          $timeout(function setTimeout() {
            timeoutPromise = $timeout($uibModalInstance.dismiss, opts.timeout);
          }, 50);
        }

        // if the cMessage wasn't requested to "persist" through state changes,
        // setup a listener to dismiss the modal onStart of state transition.
        if (!opts.persist) {
          removeHandler = $transitions.onStart({}, function closeModalFn() {
            $uibModalInstance.dismiss('state change');
          });

          $uibModalInstance.closed.then(removeHandler);
        }

      }

      activate();

    }

  }

})(angular);
