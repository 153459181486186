import { AppModuleConfig, StateAccessMap } from 'src/app/app-module.config';
import { EnvItems } from 'src/app/shared';

export const ProtectionBuilderConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'protection-builder.**',
      url: '/protection/protect',
      loadChildren: () => import('./protection-builder-routing.module').then(m => m.ProtectionBuilderRoutingModule),
    },
  ],
  allClusterMap: {
    'protection-builder': {
      singleClusterState: 'protection-builder',
      allClustersState: 'protection-builder',
      globalContext: true,
    }
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'protection-builder': ctx => {
        const { ngEnvironments, environments, cloudJobType, office365WorkloadType } = ctx.stateParams;
        const environmentList = (ngEnvironments || environments || []);

        if (cloudJobType) {
          return ctx.canAccessSomeEnvItems(
            environmentList.reduce((out, env) => (
              out.concat([cloudJobType].map(jobType => (
                { environment: jobType, sourceEnvironment: env }
              )))), []) as EnvItems[]);
        }
        if (office365WorkloadType) {
          return ctx.canAccessSomeEnvItems(
            environmentList.reduce((out, env) => (
              out.concat([office365WorkloadType].map(workloadType => (
                { environment: env, entity: workloadType, sourceEnvironment: env }
              )))), []) as EnvItems[]);
        }

        return ctx.canAccessSomeEnv(environmentList);
      },
    };
  },
};
