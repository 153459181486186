import { Component, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';

import { BaseProtectionBuilderComponent } from '../../base-protection-builder/base-protection-builder.component';

/**
 * The interface of pause note form.
 */
export interface PauseNote {
  // Indicates if future runs are paused.
  pausedFutureRunsEnabled: boolean;
  // Indicates if adding a note is enabled.
  addNoteEnabled: boolean;
  // The pause note value.
  pauseNote: string;
}

/**
 * The interface of pause note form.
 */
interface PauseNoteFormGroup {
  // Form control for pausedFutureRunsEnabled.
  pausedFutureRunsEnabled: FormControl<boolean>;
  // Form control for addNoteEnabled.
  addNoteEnabled: FormControl<boolean>;
  // Form control for pauseNote.
  pauseNote: FormControl<string>;
}

/**
 * Default value for pause note.
 */
export const DefaultPauseNote: PauseNote = {
  // Default value for pausedFutureRunsEnabled.
  pausedFutureRunsEnabled: false,
  // Default value for addNoteEnabled.
  addNoteEnabled: true,
  // Default value for pauseNote.
  pauseNote: ''
};

@Component({
  selector: 'coh-settings-list-ispaused',
  templateUrl: './settings-list-ispaused.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class SettingsListIsPausedComponent
  extends BaseProtectionBuilderComponent<PauseNote, any> {
  /**
   * By default pause note value.
   */
  readonly pausedNote = '';

  /**
   * Maximum characters for note.
   */
  readonly noteMaxLength = 128;

  /**
   * By default pause note is disabled.
   */
  _value: PauseNote = DefaultPauseNote;

  /**
   * Form group to hold end date value.
   */
  pauseNoteFormGroup = new FormGroup<PauseNoteFormGroup>({
    // Form control for indicating if future runs are paused.
    pausedFutureRunsEnabled: new FormControl<boolean>(
      this.value.pausedFutureRunsEnabled,
      Validators.required
    ),
    // Form control for enabling/disabling note addition.
    addNoteEnabled: new FormControl<boolean>(this.value.addNoteEnabled ?? false),
    // Form control for the actual pause note text.
    pauseNote: new FormControl<string>(this.value.pauseNote ?? '')
  });

  /**
   * Override of addFormControl from BaseProtectionBuilderComponent
   * so this component's FormControl isn't required.
   */
  addFormControl() {
    this.formGroup.addControl(this.name, this.pauseNoteFormGroup);
  }

  constructor(
    private irisContextService: IrisContextService,
  ) {
    super();
  }

  /**
   * Initializes the form controls based on the current state of the protection group.
   */
  initFormControl() {
    // Determine if the protection is paused and if note addition is enabled.
    const isPaused = this.protectionGroup?.isPaused ?? DefaultPauseNote.pausedFutureRunsEnabled;
    const addNoteEnabled = !!this.protectionGroup?.pausedNote;

    // Handle the initial state of the form controls.
    this.handlePausedFutureRunsEnabledControlsChange(
      isPaused,
      addNoteEnabled,
      this.protectionGroup?.pausedNote ?? ''
    );

    // Subscribe to changes in the paused future runs enabled control.
    this.pauseNoteFormGroup.controls.pausedFutureRunsEnabled.valueChanges.subscribe(value => {
      this.handlePausedFutureRunsEnabledControlsChange(value);
    });

    // Subscribe to changes in the add note enabled control.
    this.pauseNoteFormGroup.controls.addNoteEnabled.valueChanges.subscribe(value => {
      this.handleAddNoteEnabledControlsChange(value);
    });
  }


  /**
   * Updates the form controls based on the paused future runs enabled state.
   */
  private handlePausedFutureRunsEnabledControlsChange(
    pausedFutureRunsEnabled: boolean,
    addNoteEnabled: boolean = false,
    pauseNote: string = ''
  ) {
    // Update the form control for pausedFutureRunsEnabled
    this.formControl.patchValue({ pausedFutureRunsEnabled }, { emitEvent: false });

    // Determine if the note addition should be enabled based on the current state.
    const isEnabled = pausedFutureRunsEnabled &&
      this.flagEnabled('protectionRunPauseNoteEnabled') &&
      addNoteEnabled;
    this.updatePauseNoteValidators(isEnabled);

    // Update form control values based on the state
    this.formControl.patchValue({
      addNoteEnabled: isEnabled,
      pauseNote: isEnabled ? pauseNote : DefaultPauseNote.pauseNote
    });

    // Run validation after updating the form control values
    this.pauseNoteFormGroup.controls.pauseNote.updateValueAndValidity();
  }

  /**
   * Updates the form controls based on the state of adding a note.
   *
   * @param addNoteEnabled Indicates if adding a note is enabled.
   * This method updates the form control values for addNoteEnabled and resets
   * the pauseNote to the default value.
   * It also checks if the note should be enabled based on the current state
   * and updates validators accordingly.
   */
  private handleAddNoteEnabledControlsChange(addNoteEnabled: boolean) {
    // Update the form control for addNoteEnabled and reset pauseNote to default.
    this.formControl.patchValue({
      addNoteEnabled,
      pauseNote: DefaultPauseNote.pauseNote
    }, { emitEvent: false });


    // Determine if the note addition should be enabled based on the current state
    const shouldEnable = this.flagEnabled('protectionRunPauseNoteEnabled') &&
    addNoteEnabled;
    this.updatePauseNoteValidators(shouldEnable);

    // Run validation after updating the form control values
    this.pauseNoteFormGroup.controls.pauseNote.updateValueAndValidity();
  }

  /**
   * Updates the validators for the pause note form control based on whether
   * adding a note is enabled.
   *
   * @param addNoteEnabled Indicates if adding a note is enabled.
   * If adding a note is enabled, required and max length validators are added
   * to the pause note control.
   * If not, those validators are removed.
   */
  private updatePauseNoteValidators(addNoteEnabled: boolean) {
    const pauseNoteValidators = [Validators.required, Validators.maxLength(this.noteMaxLength)];
    const pauseNoteControl = this.pauseNoteFormGroup.controls.pauseNote;

    if (addNoteEnabled) {
      pauseNoteControl.addValidators(pauseNoteValidators);
    } else {
      pauseNoteControl.removeValidators(pauseNoteValidators);
    }
  }

  /**
   * Utility method to check if a feature flag is enabled or not.
   *
   * @param flagName The flag name
   * @returns True, if the feature flag is enabled.
   */
  flagEnabled(flagName: string): boolean {
    return flagEnabled(this.irisContextService.irisContext, flagName);
  }
}
