<mat-form-field
  [ngClass]="{'hide-label' : hideLabel}"
  [floatLabel]="hideLabel ? 'never' : 'always'">
  <mat-label aria-hidden="true">{{'language' | translate}}</mat-label>
  <mat-select id="select-locale"
    cogDataId="select-locale"
    disableOptionCentering
    [aria-label]="'language' | translate"
    [placeholder]="'placeholderSelect' | translate"
    [(value)]="selectedLocale"
    (valueChange)="selectedLocaleValueChange()">
    <mat-option id="select-locale-{{i}}"
      cogDataId="select-locale-option-{{locale.value}}"
      *ngFor="let locale of localesList; index as i"
      [value]="locale.value">
      {{locale.name}}
    </mat-option>
  </mat-select>
</mat-form-field>
