// Component: cUserAtDomainPicker

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.userAtDomainPicker', [])
    .component('cUserAtDomainPicker', {
      bindings: {
        id: '@?',
        name: '@',

        /**
         * @type       {Boolean}   True to show the 'Unix User ID' option.
         *                         False by default.
         */
        allowUnixId: '<?',

        /**
         * @type       {String}   Translation key for Principals ui-select
         *                        placeholder when any AD domain is selected.
         *                        Default: 'Search...'
         */
        placeholderSearchKey: '@?',

        /**
         * @type       {String}   Translation key for Principals ui-select
         *                        placeholder when 'All Domains' is selected.
         *                        Default: 'Select...'
         */
        placeholderSelectKey: '@?',

        /**
         * @type       {Boolean}   True to show only users.. no groups.
         *                         False by default.
         */
        showOnlyUsers: '<?',

        /**
         * @type       {Boolean}   True to exclude computers from the SMB query.
         *                         False by default.
         */
        excludeComputers: '<?',

        /**
         * @type       {Boolean}   True to hide the LOCAL domain.
         *                         False by default.
         */
        hideLocalDomain: '<?',

        /**
         * @type       {String}    Model value of the field
         */
        userId: '<ngModel',

        /**
         * @type       {Array}     Array of domains offered by ui-select
         */
        domains: '<?',

        /**
         * @type       {Object}    Hash of domains:principals offered by
         *                         ui-select
         */
        principals: '=?',

        /**
         * @type       {Boolean}   True to exclude LOCAL users which have not
         *                         been assigned a primary group. False by
         *                         default.
         */
        constrainLocalFileAccess: '<?',

        /**
         * @type       {Boolean}   True to show message when user select "LOCAL" domain.
         *                         False by default.
         */
        restrictLocalUsers: '<?',
      },
      require: {
        ngModel: 'ngModel',
      },
      controller: cUserAtDomainPickerFn,
      templateUrl:
        'app/global/c-user-at-domain-picker/c-user-at-domain-picker.html'
    });

  /**
   * @ngdoc component
   * @name C.userAtDomainPicker:cUserAtDomainPicker
   * @function
   *
   * @description
   * A pairing of ui-select for picking domain/principal
   *
   * @example
      <example module="C">
        <c-user-at-domain-picker
          id="smb-owner"
          name="smbOwner"
          ng-model="view.smbPermissionsInfo.ownerSid"
          principals="flowSettings.principals"
          domains="flowSettings.domains">
        </c-user-at-domain-picker>
      </example>

      <example module="C">
        <c-user-at-domain-picker
          id="override-principal"
          name="overridePrincipal"
          ng-model="shared.tempUserQuotas.addOverride.sid"
          show-only-users="true"
          disabled="addingOverride"
          placeholder-search-key="placeholderSearchPrincipals"
          ng-if="view.protocolAccess === 'kSMBOnly'">
        </c-user-at-domain-picker>
      </example>

   */

  function cUserAtDomainPickerFn(_, $q, evalAJAX, ActiveDirectoryService,
    ClusterService, GroupService, UserService, $attrs) {

    var $ctrl = this;

    var allDomainsKey = 'allDomains';
    var unixUserIdKey = 'unixUserId';

    var emptiedPrincipalsHash = {};

    var initialPrincipalsProvided;

    _.assign($ctrl, {
      $onInit: $onInit,
      $onChanges: $onChanges,

      getPlaceholderKey: getPlaceholderKey,
      groupDomainsFn: groupDomainsFn,
      groupPrincipalsFn: groupPrincipalsFn,
      isSearchEnabled: isSearchEnabled,
      isUnixUserIdSelected: isUnixUserIdSelected,
      onEnterUnixId: onEnterUnixId,
      onSelectDomain: onSelectDomain,
      onSelectPrincipal: onSelectPrincipal,
      searchPrincipals: searchPrincipals,
    });

    /**
     * Sets up list of domains and hash of Principals.
     *
     * @method     $onInit
     */
    function $onInit() {
      var promises;

      // Set flag for principals provided.
      initialPrincipalsProvided = !!$ctrl.principals;

      // Bind $attrs for disabled state pass through.
      $ctrl.$attrs = $attrs;

      // Set default values for optional params.
      $ctrl.principals = $ctrl.principals || {};
      $ctrl.showOnlyUsers = $ctrl.showOnlyUsers || false;
      $ctrl.excludeComputers = $ctrl.excludeComputers || $ctrl.showOnlyUsers;
      $ctrl.allowUnixId = $ctrl.allowUnixId || false;
      $ctrl.hideLocalDomain = $ctrl.hideLocalDomain || false;
      $ctrl.constrainLocalFileAccess = $ctrl.constrainLocalFileAccess || false;
      $ctrl.restrictLocalUsers = $ctrl.restrictLocalUsers || false;

      // Populate a constrained list of domains with provided values, or take
      // the full list from cache in ActiveDirectoryService.
      $ctrl.domains = $ctrl.domains ||
        ActiveDirectoryService.allTrustedDomainsCache || [];

      // Enforce the presence or absence of LOCAL domain.
      if ($ctrl.hideLocalDomain && $ctrl.domains.includes('LOCAL')) {
        $ctrl.domains = $ctrl.domains.slice(1);
      } else if (!$ctrl.hideLocalDomain && !$ctrl.domains.includes('LOCAL')) {
        $ctrl.domains.unshift('LOCAL');
      }

      // If list of principals already includes a well-known sid, then add "All
      // Domains" option at head of list. This is for display only. We do not
      // populate any additional principals in this group.
      if (!$ctrl.domains.includes(allDomainsKey) &&
        $ctrl.principals.allDomains &&
        $ctrl.principals.allDomains[$ctrl.userId]) {
        $ctrl.domains.unshift(allDomainsKey);
      }

      // If enabled, append "Unix User ID" option to the list of domains.
      if ($ctrl.allowUnixId && !$ctrl.domains.includes(unixUserIdKey)) {
        $ctrl.domains.push(unixUserIdKey);
      }

      // Stub the Principals hash.
      $ctrl.principals = $ctrl.principals || {};

      // Stub out the Principals hash for each domain.
      $ctrl.domains.forEach(function stubDomainHash(domain) {
        if (!$ctrl.principals[domain]) {
          $ctrl.principals[domain] = {};
        }
      });

      if (!_.keys($ctrl.principals.LOCAL).length) {
        promises = {
          users: UserService.getAllUsers({domain: 'LOCAL'}),
        };

        if (!$ctrl.showOnlyUsers) {
          promises.groups = GroupService.getGroups({domain: 'LOCAL'});
        }

        $ctrl.loading = true;

        return $q.all(promises).then(function getAllSuccess(response) {
          // Add each group to the hash of principals by domain.
          _.forEach(response.groups, function forEachGroup(group) {
            $ctrl.principals[group.domain][group.sid] = group;
          });

          // Add each user to the hash of principals by domain.
          _.forEach(response.users, function forEachGroup(user) {
           /**
            *  Add to principals hash:
            *   All AD users
            *   Any LOCAL user
            *     if assigned a primary group OR
            *     `constrainLocalFileAccess` flag is false.
            */
            if (!$ctrl.constrainLocalFileAccess || !!user.primaryGroupName ||
              user.domain !== 'LOCAL') {
              $ctrl.principals[user.domain][user.sid] = user;
            }
          });

        }).finally(
          function getAllFinally() {
            $ctrl.loading = false;
            _afterDependencies();
          }
        );
      }

      _afterDependencies();
    }

    /**
     * Final init after dependencies have been fetched.
     *
     * @method    _afterDependencies
     */
    function _afterDependencies() {
      // Save a copy of an emptied Principals hash, with all SMB
      // principals cleared.
      emptiedPrincipalsHash = _getEmptiedPrincipalsHash();

      // Pre-select default domain and Principal.
      _preselectDefault();
    }

    /**
     * Binds the change listener which updates the local `unixUid` and `sid`
     * properties when the ng-model is changed externally.
     *
     * @method     $onChanges
     *
     * @param      {object}  changesObj  The changes object
     */
    function $onChanges(changesObj) {
      var domains = changesObj.domains;
      var userId = changesObj.userId;
      var newId;

      if (domains && domains.currentValue !== domains.previousValue) {
        $onInit();
      }

      if (userId && userId.currentValue !== userId.previousValue) {
        newId = userId.currentValue;

        if (_isSid(newId)) {
          // New value is an SID
          $ctrl.sid = newId;
        } else if (angular.isNumber(newId)) {
          // New value is a UnixUID
          $ctrl.unixUid = newId;
        } else {
          // New value is undefined, so set both SID and UnixUID accordingly.
          $ctrl.sid = $ctrl.unixUid = undefined;
        }
      }
    }

    /**
     * Determines if value is an SID. All SIDs are strings and start with the
     * letter 'S'. Conversely, all UnixUIDs are true numbers.
     *
     * @method     _isSid
     * @param      {String|Number}  val     The value
     * @return     {Boolean}        True if SID, False otherwise.
     */
    function _isSid(val) {
      return val && /^S/.test(val);
    }

    /**
     * Updates the external ngModel. Called on changes to either template
     * ngModel input.
     *
     * @method     _updateModel
     */
    function _updateModel(id) {
      $ctrl.ngModel.$setViewValue(id);
    }

    /**
     * Pre-selects the default value if one exists, and only if a hash of
     * Principals was provided. Otherwise pre-selects the first AD domain.
     *
     * @method     _preselectDefault
     */
    function _preselectDefault() {
      // Set the default selection index which should neither be "All Domains"
      // nor "LOCAL", if they exist.
      var index = 0;

      if (!$ctrl.domains) {
        return;
      }

      if ($ctrl.domains.length > 1 && $ctrl.domains.includes('LOCAL')) {
        index += 1;
      }
      if ($ctrl.domains.length > 2 && $ctrl.domains.includes(allDomainsKey)) {
        index += 1;
      }

      if (_isSid($ctrl.userId)) {
        // The `userId` value is a SID.
        $ctrl.sid = $ctrl.userId;

        // Select the appropriate domain or 'All Domains'.
        if (initialPrincipalsProvided && $ctrl.userId) {
          $ctrl.selectedDomain = Object.keys($ctrl.principals).find(
            function siftDomains(domainKey) {
              return Object.keys($ctrl.principals[domainKey]).some(
                function siftPrincipals(principalKey) {
                  return principalKey === $ctrl.userId;
                }
              );
            }
          ) || $ctrl.domains[index];
        }
      } else if (angular.isNumber($ctrl.userId)) {
        // The `userId` value is a Unix User ID.
        $ctrl.unixUid = $ctrl.userId;

        // Select the Unix User ID option.
        $ctrl.selectedDomain = $ctrl.domains[unixUserIdKey];
      } else {
        // The `userId` value is undefined. Select the first domain in the list.
        $ctrl.selectedDomain =
          $ctrl.domains[$ctrl.domains.length === 1 ? 0 : index];
      }

      $ctrl.isUnixUserIdSelected = $ctrl.selectedDomain === unixUserIdKey;
    }

    /**
     * Searches AD for partial matches.
     *
     * @method     searchPrincipals
     * @param      {string}  queryStr  The query string
     */
    function searchPrincipals(user, domain) {
      var params = {
        search: user,
        domain: domain,
        includeComputers: !$ctrl.excludeComputers,
      };

      if (!user || ['LOCAL', allDomainsKey].includes(domain)) {
        return;
      }

      $ctrl.searchingPrincipals = true;

      ActiveDirectoryService.getDomainPrincipals(params).then(
        function addPrincipalsToHash(principals) {
          principals = principals.reduce(
            function reducePrincipals(principals, principal) {
              // Filter out any exclusions.
              if (($ctrl.showOnlyUsers && principal.objectClass !== 'kUser') ||
                ($ctrl.excludeComputers &&
                  principal.objectClass === 'kComputer')) {
                return principals;
              }

              // Stub the domain hash if non-existent.
              $ctrl.principals[domain] = $ctrl.principals[domain] || {};

              // Insert the Principal in the domain:principal hash.
              $ctrl.principals[domain][principal.sid] = principal;

              principals.push(principal);

              return principals;
            }, []
          );
        },
        evalAJAX.errorMessage
      ).finally(
        function searchPrincipalsFinally() {
          $ctrl.searchingPrincipals = false;
        }
      );
    }

    /**
     * Groups Principals by Users and Groups.
     *
     * @method  groupPrincipalsFn
     * @param   {Object}  principal  Principal from ui-select
     * @return  {String}  The group translation key
     */
    function groupPrincipalsFn(listItem) {
      var label;

      if ($ctrl.showOnlyUsers || !listItem.value) {
        return;
      }

      switch (listItem.value.objectClass) {
        case 'kUser':
          label = 'users';
          break;
        case 'kGroup':
          label = 'groups';
          break;
        case 'kComputer':
          label = 'others';
          break;
      }

      return label;
    }

    /**
     * On-change domains ui-select, sets $ctrl.sid model to be undefined.
     *
     * @method     onSelectDomain
     */
    function onSelectDomain() {
      $ctrl.isUnixUserIdSelected = $ctrl.selectedDomain === unixUserIdKey;
      if (!$ctrl.isUnixUserIdSelected) {
        $ctrl.sid = $ctrl.unixUid = undefined;
        _updateModel();
      }
    }

    /**
     * Creates a new hash which is an emptied copy of the $ctrl.principals hash.
     * But the "LOCAL" and "All Domains" options remains untouched because we
     * never clear those groups.
     *
     * @method     _getEmptiedPrincipalsHash
     * @return     {Object}  An empty copy of the $ctrl.principals hash.
     */
    function _getEmptiedPrincipalsHash() {
      var emptiedHash = angular.copy($ctrl.principals);

      Object.keys(emptiedHash).forEach(
        function loopDomains(domainKey) {
          // Do not clear the "All Domains" or "LOCAL" domains.
          if (!['LOCAL', allDomainsKey].includes(domainKey)) {
            emptiedHash[domainKey] = {};
          }
        }
      );
      return emptiedHash;
    }

    /**
     * On-change Principals ui-select, reset/clear the hash of Principals.
     *
     * @method     onSelectPrincipal
     */
    function onSelectPrincipal() {
      ActiveDirectoryService
        .addPrincipalToCache($ctrl.principals[$ctrl.selectedDomain][$ctrl.sid]);

      $ctrl.principals = angular.copy(emptiedPrincipalsHash);
      _updateModel($ctrl.sid);

      if ($ctrl.ngChange) {
        $ctrl.ngChange($ctrl.sid);
      }
    }

    /**
     * On-change Unix User ID.
     *
     * @method     onEnterUnixId
     */
    function onEnterUnixId() {
      _updateModel($ctrl.unixUid);
      if ($ctrl.ngChange) {
        $ctrl.ngChange($ctrl.unixUid);
      }
    }

    /**
     * Determines whether ui-select search should be enabled.
     *
     * @method     isSearchEnabled
     * @return     {Boolean}  true if should enable search.
     */
    function isSearchEnabled() {
      return $ctrl.selectedDomain !== allDomainsKey;
    }

    /**
     * Determines whether the "Unix User ID" option is selected.
     *
     * @method     isUnixUserIdSelected
     * @return     {Boolean}  true if "Unix User ID" is selected.
     */
    function isUnixUserIdSelected() {
      return $ctrl.selectedDomain === unixUserIdKey;
    }

    /**
     * Gets the placeholder key for the Principals ui-select. The defaults are
     * as follows:
     *    When 'All Domains' is selected: 'Select...'
     *    When any AD domain is selected: 'Search AD User...'
     *
     * @method     getPlaceholderKey
     *
     * @return     {String}  The placeholder key.
     */
    function getPlaceholderKey() {
      return isSearchEnabled() ?
        $ctrl.placeholderSearchKey || 'placeholderSearchPrincipals' :
        $ctrl.placeholderSelectKey || 'placeholderSelect' ;
    }

    /**
     * Groups the domains ui-select choices, but only if it is a mixed-mode
     * View.
     *
     * @param      {Object}  item    The ui-select item
     * @return     {String}  Translation key for group name
     */
    function groupDomainsFn(item) {
      if (!$ctrl.allowUnixId) {
        return;
      }

      return item === unixUserIdKey ?
        'unixUsers' : 'activeDirectoryDomains';
    }

  }

})(angular);
