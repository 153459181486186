// Module: Alerts Detail

;(function(angular, undefined) {
  'use strict';

  angular.module('C.alerts')
    .controller('alertsResolutionDetailCtrl', AlertsResolutionDetailCtrlFn);

  function AlertsResolutionDetailCtrlFn(_, $scope, $transition$, evalAJAX,
    AlertService, StateManagementService) {
    var id = $transition$.params().id;

    // used to keep the view from process until the data is back from the
    // api. this prevents javascript errors from invalid date processing
    // in DateTimeService
    $scope.dataReady = false;

    /**
     * Navigates to previous state.
     */
    $scope.goBack = function goBack() {
      StateManagementService.goToPreviousState();
    };

    // hit the API and get the details for this alert
    AlertService.getResolution(id).then(function(result){
      if(evalAJAX.success(result)) {
        $scope.theResolution = result.data;
        // now get the alert details
        AlertService.getAlerts({
          resolutionIdList: [
            $scope.theResolution.resolutionDetails.resolutionId
          ],
          maxAlerts: 1000
        }).then(function onSuccess(result) {
          if (evalAJAX.success(result)) {
            $scope.resolutionAlerts = result.data;
            $scope.dataReady = true;
          }
        });
      }
    });
  }

})(angular);
