import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { Controls, NgxSubFormComponent, subformComponentProviders, takeUntilDestroyed } from 'ngx-sub-form';

import { SecurityCriteriaListFormModel } from '../../models';

@Component({
  selector: 'dg-pa-security-criteria-select-list-component',
  templateUrl: './security-criteria-select-list.component.html',
  styleUrls: ['./security-criteria-select-list.component.scss'],
  providers: subformComponentProviders(SecurityCriteriaSelectListComponent),
})
export class SecurityCriteriaSelectListComponent
  extends NgxSubFormComponent<SecurityCriteriaListFormModel> implements OnInit {
  /**
   * Open and close mat-expansion-panel
   */
  panelOpenState = false;

  /**
   * Counting matbox where value is true.
   */
  enabledSecurityCriteria: number;

  /**
   * Category name
   */
  @Input() category: string;

  /**
   * NgOninit lifecycle hook.
   */
  ngOnInit() {
    this.formGroupControls.criteriaList.valueChanges
      .pipe(takeUntilDestroyed(this))
      .subscribe(result => {
        this.enabledSecurityCriteria = result?.filter(res => res?.isEnabled)?.length;
      });
  }

  /**
   * Get the form controls needed for the sub form.
   *
   * @returns   The form controls object.
   */
  protected getFormControls(): Controls<SecurityCriteriaListFormModel> {
    return { criteriaList: new UntypedFormArray([]) };
  }

}
