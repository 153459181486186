<mat-form-field class="date-range" (click)="openCalendar()">
  <input matInput readonly [value]="currentRange" [disabled]="disabled">
  <cog-icon matSuffix size="xs" shape="calendar_today"></cog-icon>
</mat-form-field>
<input #menuTrigger="matMenuTrigger" matInput readonly [value]="" [matMenuTriggerFor]="matMenu" />
<mat-menu #matMenu [xPosition]="xPosition" class="date-picker-menu">
  <form (click)="formClick($event)" (keydown)="formKeyDown($event)">
    <div class="picker-container">
      <cog-date-range-picker
        [formControl]="rangeControl"
        [useTimeframe]="useTimeframe"
        [noDateOptionLabel]="noDateOptionLabel"
        [enableGuessTimeframe]="enableGuessTimeframe"
        [enablePreciseDateRangeSelection]="enablePreciseDateRangeSelection"
        [maxRange]="maxRange"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [timeframeOptions]="timeframeOptions">
      </cog-date-range-picker>
    </div>
    <div class="picker-buttons-container">
      <button mat-flat-button color="primary" cogDataId="picker-apply-button" (click)="apply()">{{'apply' | translate }}</button>
      <button mat-button color="primary" cogDataId="picker-cancel-button" (click)="cancel()">{{'cancel' | translate }}</button>
    </div>
  </form>
</mat-menu>
