import { ChangeDetectorRef, Component, ComponentRef, Input, OnChanges, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { AutoDestroyable } from '@cohesity/utils';
import { DynamicComponentLoaderService } from '../dynamic-component-loader.service';

/**
 * This is a parent component for loading and displaying dynamic components. When
 * a componentId is specified, this will use the DynamicComponentLoaderService to
 * load and display a component from a lazy-loaded module.
 *
 * @example
 *   dialogService.showDialog('dialog-id');
 *   or
 *   <coh-component-loader componentId="component-id"></coh-component-loader>
 *
 */
@Component({
  selector: 'coh-component-loader',
  template: '<cog-spinner *ngIf="loading"></cog-spinner><div #outlet></div>',
})
export class ComponentLoaderComponent extends AutoDestroyable implements OnChanges {
  /**
   * The ViewContainerRef to render the dynamic content to.
   */
  @ViewChild('outlet', { read: ViewContainerRef, static: true }) _outlet: ViewContainerRef | undefined;

  /**
   * Used to determine if the component is being loaded or not. This will be false
   * after the component finished loaded.
   */
  loading = true;

  /**
   * Pass arbitrary inputs to the dynamically loaded component.
   */
  @Input() componentInputs: any;

  /**
   * Pass arbitrary output handler to the dynamically loaded component.
   */
  @Input() componentOutputs: any;

  /**
   * A references to the dynamically loaded component.
   */
  private componentRef: ComponentRef<any>;

  constructor(private loader: DynamicComponentLoaderService, private cdr: ChangeDetectorRef) {
    super();
  }

  /**
   * Sets the id of the component to load. The id should be included in the
   * app-dynamic-components manifest as well as in the component's module.
   *
   * @param   componentId   A unique id of the component to load.
   */
  @Input() set componentId(componentId: string) {
    this.loader
      .getComponentFactory(componentId)
      .pipe(this.untilDestroy())
      .subscribe(componentFactory => {
        if (!this._outlet) {
          return;
        }
        this.loading = false;
        this.componentRef = this._outlet.createComponent(componentFactory);
        this.updateComponentBindings();
        this.cdr.detectChanges();
      });
  }

  /**
   * Update the inputs and outputs on the component instance and run change
   * detection on it.
   */
  private updateComponentBindings() {
    // This method won't do anything unless the component has been loaded.
    if (!this.componentRef) {
      return;
    }

    if (this.componentInputs) {
      Object.assign(this.componentRef.instance, this.componentInputs);
    }

    if (this.componentOutputs) {
      this.cleanUpSubscriptions();

      Object.keys(this.componentOutputs).forEach(eventName => {
        const outputEvent = this.componentRef.instance[eventName];
        if (outputEvent && outputEvent.subscribe) {
          outputEvent.pipe(takeUntil(this._destroy)).subscribe(event => this.componentOutputs[eventName](event));
        }
      });
    }

    this.componentRef.changeDetectorRef.detectChanges();
  }

  /**
   * Listen for changes to the inputs and outputs and update the component
   * bindings if necessary.
   *
   * @param   changes   sumary of changed properties on the component.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.componentInputs || changes.componentOutputs) {
      this.updateComponentBindings();
    }
  }
}
