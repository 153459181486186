import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { DmaasRegion, DmaasRegions, DmsServiceApi, TenantRegionInfo, TenantRegions } from '@cohesity/api/dms';
import { ReportsServiceApi } from '@cohesity/api/reporting';
import {
  DataProtectUsage,
  HeliosAccountsServiceApi,
  HeliosDataProtectStatsServiceApi,
  McmSources,
  McmTenantMigrationStatus,
  SourceServiceApi
} from '@cohesity/api/v2';
import {
  flagEnabled,
  getUserAnyTenantId,
  getUserTenantId,
  IrisContextService,
  isAzureSubscriptionActive,
  isDmsAwsSubscriptionActive,
  isHeliosTenantUser,
  isSaasServiceUser
} from '@cohesity/iris-core';
import { AjaxHandlerService } from '@cohesity/utils';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { Region } from 'src/app/models';
import { AsyncBehaviorSubject, updateWithStatus } from 'src/app/util';

/**
 * Default date filter range used for making reporting API calls.
 */
export const defaultDateRange = 'past30days';

// TODO(Sam): DMS Yaml work for these states are being implemented.
// Replace it after to actual Backend enums.
export type ConfiguredState = 'NotDone' | 'InProgress' | 'PartiallyDone' | 'Done' | null;

/**
 * Data structure for all API calls in getRegions.
 */
export type AllRegionData = [TenantRegions, DmaasRegions, McmSources, DataProtectUsage];

/**
 * Default polling interval.
 */
const defaultPollIntervalMs = 20000;

@Injectable({
  providedIn: 'root'
})
export class DmsService {

  /**
   * The local storage key for storing region Id
   */
  readonly key = 'C.selectedRegion';

  /**
   * Holds the observable for list of regions available to be configured for the user.
   */
  regions$ = new BehaviorSubject<DmaasRegion[]>(null);

  /**
   * Holds the observable for list of available regions to be configured for the user.
   */
  availableRegions$ = new BehaviorSubject<DmaasRegion[]>(null);

  /**
   * Observable which tell whether the DMS account needs to be setup with regions to
   * continue with additional workflows.
   */
  isSetupDone$ = new BehaviorSubject<ConfiguredState>(null);

  /**
   * Holds the value of the configured state.
   */
  configuredState: ConfiguredState;

  /**
   * Holds the list of configured regions.
   */
  private _configuredRegions$ = new AsyncBehaviorSubject<TenantRegions>();

  /**
   * Holds an observable to the list of configured regions.
   */
  readonly configuredRegions$ = this._configuredRegions$.pipe(map(info => info.result));

  /**
   * List of configured Regions for an account
   */
  configuredRegionIds: string[] = [];

  /**
   * Return regions as an observable.
   */
  private userRegions$: Observable<DmaasRegion[]>;

   /**
    * Behavior subject to trigger updating the regions list.
    */
  private refreshUserRegions$ = new BehaviorSubject(null);

  /**
   * Holds whether tenant is getting migrated.
   */
  isTenantMigrating$ = new BehaviorSubject<McmTenantMigrationStatus>(null);

  /**
   * Behavior subject of tenant regions for regions landing page.
   */
  tenantRegionsSubject = new AsyncBehaviorSubject<Region[]>();

  /**
   * Async tenant regions for regions landing page.
   */
  tenantRegions$ = this.tenantRegionsSubject.asObservable();

  constructor(
    readonly dmsService: DmsServiceApi,
    readonly ajaxHandler: AjaxHandlerService,
    private reportsService: ReportsServiceApi,
    private irisContextService: IrisContextService,
    private sourceService: SourceServiceApi,
    private accountService: HeliosAccountsServiceApi,
    private usageService: HeliosDataProtectStatsServiceApi,
  ) {
    this.userRegions$ = this.getUserRegions$();

    // If the logged in user's dms privilege changes, refresh the regions list.
    this.irisContextService.irisContext$.pipe(
      map(value => isSaasServiceUser(value)),
      distinctUntilChanged(),
    ).subscribe(() => this.refreshUserRegions());
  }

  /**
   * Get all the configurable regions for DMaaS.
   */
  getRegions() {
    this.dmsService.GetRegions().pipe(
      map(data => this.filterAccountBasedRegion(data))
    ).subscribe(
      data => this.regions$.next(data),
      err => this.ajaxHandler.errorMessage(err)
    );
  }

  /**
   * Get all the available regions that can be configured for the user.
   */
  getAvailableRegions() {
    combineLatest([
      this.configuredRegions$,
      this.dmsService.GetRegions()
    ]).subscribe(
      ([regions, data]) => {
        const accountRegions = this.filterAccountBasedRegion(data);

        // If the tenant is not configured, then send all the tenants.
        const availableRegions = accountRegions.filter(region => !regions?.tenantRegionInfoList ||
          !regions.tenantRegionInfoList.some(tenant => tenant.regionId === region.id));
        this.availableRegions$.next(availableRegions);
      },
      err => this.ajaxHandler.errorMessage(err)
    );
  }

  /**
   * Get the regions associated to the DmaaS user.
   *
   * @param   params      The TenantID params to be sent.
   * @param   forceQuery  Determines whether to use existing data or fetch new data.
   */
  getUserTenantRegions(params?: DmsServiceApi.GetTenantRegionsParams, forceQuery?: boolean): Observable<TenantRegions> {
    if (forceQuery || (!this._configuredRegions$.value.result && !this._configuredRegions$.value.loading)) {
      this.dmsService.GetTenantRegions(params).pipe(
        tap(regions => this.processStatus(regions)),
        updateWithStatus(this._configuredRegions$),
        catchError(err => {
          this.configuredState = 'NotDone';
          this.isSetupDone$.next(this.configuredState);
          return throwError(err);
        })
      ).subscribe();
    }

    return this.configuredRegions$.pipe(
      filter(regions => !!regions),
      tap(regions => this.processStatus(regions)),
      take(1)
    );
  }

  processStatus(regions: TenantRegions) {
    const assignedRegions: TenantRegionInfo[] = regions && regions.tenantRegionInfoList;

    // 1. If there are no regions assigned, then setup is not done.
    // 2. If all region are provisioned, then setup is done.
    // 3. If some region are provisioned, then setup is partially done.
    // 4. If regions are still being provisioned, then setup is in progress.
    switch (true) {
      case !assignedRegions:
        this.configuredState = 'NotDone';
        break;
      case assignedRegions.every(tenant => tenant.provisionStatus.status === 'Failed'):
        this.configuredState = 'PartiallyDone';
        break;
      case assignedRegions.some(tenant => tenant.provisionStatus.status === 'Completed'):
        this.configuredState = 'Done';
        break;
      default:
        this.configuredState = 'InProgress';
    }

    if (assignedRegions?.length) {
      this.configuredRegionIds = assignedRegions
        .filter(tenant => tenant.provisionStatus.status === 'Completed')
        .map(r => r.regionId);
    }

    this.isSetupDone$.next(this.configuredState);
  }

  /**
   * Returns an observable of configured regions for the user.
   *
   * @return Observable of dms regions.
   */
  getConfiguredRegions(): Observable<DmaasRegion[]> {
    return this.userRegions$;
  }

  /**
   * Returns the aggregated FETB used for the current month
   *
   * @returns  An observable with current month usage.
   */
  getAggregatedUsage(): Observable<number> {
    const startTime = new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime();
    const endTime = new Date().getTime();
    return this.reportsService.GetReportPreview({
      id: 'service-consumption',
      body: {
        componentIds: ['501'],
        filters: [{
          attribute: 'systemId',
          filterType: 'Systems',
          systemsFilterParams: {
            systemIds: this.configuredRegionIds,
          }
        }, {
          attribute: 'date',
          filterType: 'TimeRange',
          timeRangeFilterParams: {
            lowerBound: startTime * 1000,
            upperBound: endTime * 1000,
          }
        }]
      }
    }).pipe(
      map(aggReport => {
        // The type of components.data is {}, typing it as a generic any.
        const currentUsageSize: any = aggReport.components[0]?.data && aggReport.components[0]?.data[0];
        return currentUsageSize?.sumPeakFrontEndSizeBytes || 0;
      })
    );
  }

  /**
   * Function to refresh regions observable.
   */
  refreshUserRegions() {
    this.refreshUserRegions$.next(null);
  }

  /**
   * Gets the list of configured regions.
   *
   * @param   regionIds  Optional region Id list
   * @param   excludeStats   Whether to exclude the protection stats for sources
   * @param   optimize   Get Sources by regions returned from regions API call and do not call get usage stats.
   * @returns An observable of the configured regions.
   */
  getTenantRegions(
    regionIds?: string[],
    excludeStats = false,
    optimize = false,
  ): Observable<Region[]> {
    const params: DmsServiceApi.GetTenantRegionsParams = {
      tenantId: getUserTenantId(this.irisContextService.irisContext)
    };
    if (regionIds?.length) {
      params.regionIds = regionIds;
    }
    const sourcesParams: SourceServiceApi.McmGetProtectionSourcesParams = {};
    if (excludeStats) {
      sourcesParams.excludeProtectionStats = true;
    }
    const subscription: Observable<AllRegionData> = forkJoin([
      this.dmsService.GetTenantRegions(params),
      this.dmsService.GetRegions(),
      optimize ? of(null) : this.sourceService.McmGetProtectionSources(sourcesParams),
      optimize ? of(null) : this.usageService.GetDataProtectUsage({regionIds: this.configuredRegionIds}),
    ]);

    return subscription.pipe(
      map(([configuredRegions, regionList, sourceList, dataProtectUsage]: AllRegionData) => {
        const regions = (configuredRegions?.tenantRegionInfoList ?? []).map(
          (region): Region => new Region(
            region,
            regionList.regions,
            sourceList?.sources || [],
          )
        );

        Region.updateUsage(regions, dataProtectUsage);
        return regions;
      }),
    );
  }

  /**
   * Update sources for regions.
   *
   * @param regions  All regions.
   * @param reset    used for updateWithStatus().
   */
  updateSources(regions: Region[], reset: boolean) {
    this.sourceService.McmGetProtectionSources({
      excludeProtectionStats: true,
      regionIds: regions?.[0]?.regions.map(region => region.id),
    }).pipe(
      map(sourceList => {
        regions.forEach(region => region.sources = sourceList.sources);

        // Need to create a new array object so that the components will be updated.
        return [...regions];
      }),
      updateWithStatus(this.tenantRegionsSubject, reset, !reset),
    ).subscribe();
  }

  /**
   * Update usage data for regions.
   *
   * @params  regions  All regions.
   * @params  reset    used for updateWithStatus().
   */
  updateUsage(regions: Region[], reset:  boolean) {
    this.usageService.GetDataProtectUsage({regionIds: this.configuredRegionIds}).pipe(
      map(dataProtectUsage => {
        Region.updateUsage(regions, dataProtectUsage);

        // Need to create a new array object so that the components will be updated.
        return [...regions];
      }),
      updateWithStatus(this.tenantRegionsSubject, reset, !reset),
    ).subscribe();
  }

  /**
   * Fetch tenant regions.
   *
   * @param regionIds  Optional region IDs for retrieval.
   * @param reset  Reset data if true.
   */
  fetchTenantRegions(regionIds?: string[], reset = true) {
    this.getTenantRegions(regionIds, true, true)
      .pipe(
        map((regions: Region[]) => {
          if (this.tenantRegionsSubject.value?.result) {
            const updatedRegions: Region[] = [];
            const allRegions = this.tenantRegionsSubject.value?.result;

            // Need to create a new array to trigger table display update.
            allRegions?.forEach(region => {
              const matchedRegion = regions?.find(r => r?.id === region?.id);

              updatedRegions.push(matchedRegion ? matchedRegion : region);
            });
            return updatedRegions;
          }
          return regions;
        }),

        // getTenantRegions above will not call get sources/usage stats, which will be called below so that component
        // does not need to wait for this API call to render.
        tap(regions => {
          if (regions?.length) {
            this.updateSources(regions, reset);
            this.updateUsage(regions, reset);
          }
        }),
        updateWithStatus(this.tenantRegionsSubject, reset, !reset),
      )
      .subscribe();
  }

  /**
   * Poll tenant regions. Note that this only polls regions with in progress status.
   * The resulting list will be the same as before except the in progress regions
   * could be updated from each poll.
   *
   * @param pollIntervalMs  Poll interval time in ms.
   */
  pollTenantRegions(pollIntervalMs = defaultPollIntervalMs): Observable<void> {
    return this.tenantRegions$.pipe(
      filter(state => !state.loading && !state.error && !!state.result),
      map(state => state.result),

      // Since fetchTenantRegions will update tenantRegions$ 3 times with optimize (regions, sources, usage are
      // updated to the same variable separately). We use the following comparison function to avoid updates from
      // sources/usage to trigger the poll, by comparing the region objects in the array, since sources/usage update
      // will not update the region object pointers.
      distinctUntilChanged((a, b) => {
        if (a?.length !== b?.length) {
          return false;
        }
        for (let i = 0; i < a?.length; i++) {
          if (a[i] !== b[i]) {
            return false;
          }
        }
        return true;
      }),
      delay(pollIntervalMs),
      tap((regions: Region[]) => {
        const inProgressRegionIds = regions.filter(r => r.isInProgress).map(r => r.id);

        if (inProgressRegionIds?.length) {
          this.fetchTenantRegions(inProgressRegionIds, false);
        }
      }),
      map(() => undefined)
    );
  }

  /**
   * Function to return all configured regions of a user.
   *
   * @return Observable of dms regions.
   */
  private getUserRegions$(): Observable<DmaasRegion[]> {
    const emptyRegions$ = of({regions: []});
    const emptyTenantRegions$ = of({tenantRegionInfoList: []});

    return this.refreshUserRegions$.pipe(
      map(() => {
        // Only make regions API calls for DMS users.
        if (isSaasServiceUser(this.irisContextService.irisContext) &&
          !isHeliosTenantUser(this.irisContextService.irisContext)) {
          const tenantId = getUserAnyTenantId(this.irisContextService.irisContext);

          return [
            this.dmsService.GetRegions().pipe(catchError(() => emptyRegions$)),
            this.dmsService.GetTenantRegions({tenantId}).pipe(catchError(() => emptyTenantRegions$)),
          ];
        }

        // Return empty array for non DMS users.
        return [emptyRegions$, emptyTenantRegions$];
      }),
      switchMap(regions$ => forkJoin(regions$).pipe(
        map(([regions, tenantRegions]: [DmaasRegions, any]) => {
          const regionsArr = regions?.regions || [];
          const tenantRegionsArr = tenantRegions?.tenantRegionInfoList || [];
          const tenantRegionIds = tenantRegionsArr.map(region => region.regionId);

          return regionsArr.filter(region => tenantRegionIds.includes(region.id));
        }),
      )),
      shareReplay(1),
    );
  }

  /**
   * Fetches the tenant migration status for the account.
   */
  getTenantMigrationStatus() {
    this.accountService.McmGetTenantMigrationStatus().subscribe(
      data => this.isTenantMigrating$.next(data),
      err => this.ajaxHandler.errorMessage(err),
    );
  }

  /**
   * Filters account based regions by checking the enabled feature flag for regions.
   *
   * @param   data  The available regions
   * @returns The list of regions available to the account.
   */
  private filterAccountBasedRegion(data: DmaasRegions): DmaasRegion[] {
    const { irisContext } = this.irisContextService;
    return data.regions.filter(region => {
      if (region.type === 'Aws' && isDmsAwsSubscriptionActive(irisContext)) {
        return flagEnabled(irisContext, `dmsAwsRegion-${region.id}`);
      } else if (region.type === 'Azure' && flagEnabled(irisContext, 'azureRegions') &&
        isAzureSubscriptionActive(irisContext)) {
        return flagEnabled(irisContext, `dmsAzureRegion-${region.id}`);
      }
    });
  }
}

/**
 * Downgrade DmsService for legacy AngularJS use.
 */
declare const angular;
angular
  .module('C')
  .factory('NgDmsService', downgradeInjectable(DmsService) as any);
