import { Component, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

/**
 * Data structure for the dialog.
 */
export interface EditConnectorGroupNameData {
  name: string;
  otherNames?: string[];
}

/**
 * This component allows user to edit connector group name.
 */
@Component({
  selector: 'coh-edit-connector-group-name-dialog',
  styleUrls: ['./edit-connector-group-name-dialog.component.scss'],
  templateUrl: './edit-connector-group-name-dialog.component.html'
})
export class EditConnectorGroupNameDialogComponent {
  /**
   * Group name form control.
   */
  groupNameControl = new UntypedFormControl();

  /**
   * Constructor.
   */
  constructor(
    private dialogRef: MatDialogRef<EditConnectorGroupNameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditConnectorGroupNameData,
  ) {
    if (this.data?.name) {
      this.groupNameControl.setValue(this.data.name);
      this.groupNameControl.updateValueAndValidity();
    }
  }

  /**
   * Save group name.
   */
  submit() {
    this.groupNameControl.setErrors(null);
    if (this.data?.otherNames &&
      this.data.otherNames.some(name => name === this.groupNameControl.value)) {
      this.groupNameControl.setErrors({ duplicate: true });
      return;
    }
    this.dialogRef.close(this.groupNameControl.value);
  }
}
