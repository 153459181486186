<ng-template #loader>
  <div class="spinner">
    <cog-spinner></cog-spinner>
  </div>
</ng-template>
<ng-template #itemsTemplate let-items>
  <div class="messages">
    <ul>
      <li *ngFor="let item of items">{{ item.eventMsg }}</li>
    </ul>
  </div>
</ng-template>
<div class="coh-dialog-header">
  <h2 mat-dialog-title>{{ data.title }}</h2>
  <button class="coh-dialog-close-button" type="button" mat-icon-button mat-dialog-close>
    <cog-icon shape="close"></cog-icon>
  </button>
</div>
<div class="c-message-inline icon-message error" *ngIf="data.errorMessages?.length">
  <p *ngFor="let message of data.errorMessages">{{ message }}</p>
</div>
<mat-dialog-content class="coh-dialog-content">
  <mat-tab-group *ngIf="isProtectionRunStatsEnabled">
    <mat-tab [label]="'statistics' | translate">
      <coh-timeline-statistics
        [startTimeUsecs]="data.startTimeUsecs"
        [runId]="data.runId"
        [objectTaskPath]="data.objectTaskPath"
        [runTaskPath]="data.runTaskPath"
        [environment]="data.environment"
        [id]="data.id"
      >
      </coh-timeline-statistics>
    </mat-tab>
    <mat-tab [label]="'logs' | translate">
      <ng-container *ngIf="events$ | async as events; else loader">
        <cog-activity-timeline *ngIf="events?.length > 0"
          [events]="events"
          [itemsTemplate]="itemsTemplate">
        </cog-activity-timeline>
        <h3 *ngIf="events?.length && !(attempts$ | async)?.length" translate>noTaskActivityFound</h3>
      </ng-container>
      <ng-container *ngFor="let attempt of attempts$ | async; index as i">
        <ng-container *ngIf="attempt?.length">
          <h4 translate>
            {{ 'protectionJobsDetailsRunRunProtection.prevAttemptLabel.' + i }}
          </h4>
          <cog-activity-timeline
            [events]="attempt"
            [itemsTemplate]="itemsTemplate">
          </cog-activity-timeline>
        </ng-container>
      </ng-container>
    </mat-tab>
  </mat-tab-group>

  <!-- If no Protection Runs Stats, then show Logs without the lone Logs tab -->
  <ng-container *ngIf="!isProtectionRunStatsEnabled">
    <ng-container *ngIf="events$ | async as events; else loader">
      <cog-activity-timeline *ngIf="events?.length > 0"
        [events]="events"
        [itemsTemplate]="itemsTemplate">
      </cog-activity-timeline>
      <div *ngIf="!events?.length && !data?.taskAttempts$" class="flex-row-xs vertical-align">
        <h3 cogDataId="generating-log-text">{{ 'generatingLog' | translate }}</h3>
        <cog-spinner cogDataId="generating-spinner" class="padding-bottom padding-left-sm" size="xs"></cog-spinner>
      </div>
      <h3 *ngIf="!events?.length && !data?.taskAttempts?.length" translate>noTaskActivityFound</h3>
    </ng-container>
    <ng-container *ngFor="let attempt of attempts$ | async; index as i">
      <ng-container *ngIf="attempt?.length">
        <h4 translate>
          {{ 'protectionJobsDetailsRunRunProtection.prevAttemptLabel.' + i }}
        </h4>
        <cog-activity-timeline
          [events]="attempt"
          [itemsTemplate]="itemsTemplate">
        </cog-activity-timeline>
      </ng-container>
    </ng-container>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    *ngIf="showDownloadLogsOption() && data?.downloadInclusionExclusionReportsUrl && (events$ | async)"
    type="button"
    cogDataId="download-inclusion-exclusion-report"
    (click)="downloadInclusionExclusionReport()"
    color="primary"
    mat-flat-button>
    {{'downloadInclusionExclusionReports' | translate}}
  </button>
  <button
    *ngIf="showDownloadLogsOption() && data?.downloadSuccessLogsUrl && (events$ | async)"
    type="button"
    cogDataId="download-success-logs-report"
    (click)="downloadSuccessLogs()"
    color="primary"
    mat-flat-button>
    {{'downloadSuccessLogs' | translate}}
  </button>
  <button
    *ngIf="showDownloadLogsOption() && data?.downloadLogsUrl && (events$ | async)"
    type="button"
    cogDataId="download-error-logs-report"
    (click)="downloadDebugLogs()"
    color="primary"
    mat-flat-button>
    {{'downloadErrors' | translate}}
  </button>
  <button
    mat-dialog-close
    cogCancelButton
    class="margin-right"
    cogDataId="close-error-logs-report"
    color="primary"
    mat-flat-button>
    {{'close' | translate}}
  </button>
</mat-dialog-actions>
