import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SecurityPlan } from '@cohesity/api/secops';
import { NavItem } from '@cohesity/helix';
import { AjaxHandlerService, AutoDestroyable, FilterValuesList } from '@cohesity/utils';
import { finalize } from 'rxjs/operators';

import { SecurityPlansModel } from '../../models/security-rules.model';
import { PostureAdvisorService } from '../../services';

@Component({
  selector: 'dg-pa-security-rules-page',
  templateUrl: './security-rules-page.component.html',
  styleUrls: ['./security-rules-page.component.scss'],
})
export class SecurityRulesPageComponent extends AutoDestroyable implements OnInit {
  /**
   * Inform parent 'edit rule' has been clicked
   */
  @Output() editRuleClick = new EventEmitter<string>();

  /**
   * Inform parent that 'create rule' has been clicked
   */
  @Output() createRuleClick = new EventEmitter<string>();

  /**
   * Inform parent that 'rule detail' has been clicked
   */
  @Output() ruleDetailClick = new EventEmitter<string>();

  /**
   * Columns to be displayed in the UI. Holds score table column names for standard presentation.
   */
  columns: string[] = ['name', 'strength', 'protectedCluster', 'outOfPlanCluster', 'action'];

  /**
   * Filters data for cog-value-property-filter [filterValues]
   */
  filterValuesList: FilterValuesList = {};

  /**
   * Loading status of the component.
   */
  loading = false;

  /**
   * Security plan data
   */
  securityRulesData: SecurityPlansModel;

  /**
   * NavItem for edit delete and duplicate
   */
  actions: NavItem[] = [
    {
      id: 'edit',
      displayName: 'edit',
    },
  ];

  constructor(
    private cdr: ChangeDetectorRef,
    readonly ajaxService: AjaxHandlerService,
    private securityAdvisorService: PostureAdvisorService,
  ) {
    super();
  }

  /**
   * OnInit lifecycle hook.
   */
  ngOnInit() {
    this.getSecurityPlans();
  }

  /**
   * Getting all security plan details.
   */
  getSecurityPlans() {
    this.loading = true;
    this.securityAdvisorService.getSecurityPlans()
      .pipe(
        this.untilDestroy(),
        finalize(() => (this.loading = false))
      )
      .subscribe(response => {
        this.securityRulesData = response;
        this.cdr.detectChanges();
      }, err => this.ajaxService.errorMessage(err));
  }

  /**
   * Create security rule.
   */
  createRule() {
    this.createRuleClick.emit();
  }

  /**
   * Handler for mat table row action click.
   *
   * @param actions The action which was clicked.
   * @param rowData Row data of type SecurityPlan.
   */
  rowActionsHandler(action: string, rowData: SecurityPlan) {
    switch (action) {
      case 'edit':
        this.editRuleClick.emit(rowData.id);
        break;
    }
  }
}
