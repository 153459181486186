import { AppModuleConfig, StateAccessMap } from 'src/app/app-module.config';
import { DynamicComponentManifest } from 'src/app/dynamic-component-loader';

import { ActivityConfig } from './modules/activity/activity.config';
import { DashboardConfig } from './modules/dashboard/dashboard.config';
import { OnboardingConfig } from './modules/onboarding/onboarding.config';
import { RpaasPolicyConfig } from './modules/rpaas-policy/rpaas-policy.config';
import { VaultsConfig } from './modules/vaults/vaults.config';

/**
 * Dyanmic components (dialogs) used by rpaas
 */
export const rpaasDynamicComponents: DynamicComponentManifest[] = [
  {
    componentId: 'add-rpaas-vault',
    loadChildren: () => import('./modules/vaults/vaults.module').then(m => m.VaultsModule),
  },
];

/**
 * All of the states and access configurations for rpaas are kept here.
 */
export const RpaasConfig: AppModuleConfig = {
  futureStates: [
    ...ActivityConfig.futureStates,
    ...DashboardConfig.futureStates,
    ...OnboardingConfig.futureStates,
    ...RpaasPolicyConfig.futureStates,
    ...VaultsConfig.futureStates,
  ],
  allClusterMap: {
    ...ActivityConfig.allClusterMap,
    ...DashboardConfig.allClusterMap,
    ...OnboardingConfig.allClusterMap,
    ...VaultsConfig.allClusterMap,
    ...RpaasPolicyConfig.allClusterMap,
  },
  getStateAccessMap(): StateAccessMap {
    return {
      ...ActivityConfig.getStateAccessMap(),
      ...DashboardConfig.getStateAccessMap(),
      ...OnboardingConfig.getStateAccessMap(),
      ...RpaasPolicyConfig.getStateAccessMap(),
      ...VaultsConfig.getStateAccessMap(),
    };
  },
};
