<div class="list-item-column-container">
  <coh-list-item-column-layout
    [class.overflow-column-layout]="!!config.template || config.handleOverflow"
    [row]="row"
    [columnValue]="displayValue"
    [config]="config"
    [params]="params"
    [icon]="getIcon()"
    [popoverData]="config.popover ? row : null"
    [matTooltip]="config.tooltip ? row[config.tooltip] : null">
  </coh-list-item-column-layout>
  <button
    mat-icon-button
    type="button"
    *ngIf="!config.template && hasMultipleValues"
    class="margin-left-sm"
    cogDataId="btn-plus-{{ config.name }}"
    [cogPopover]="tooltip">
    {{ 'plusCount' | translate: { count: columnValue.length - 1 } }}
  </button>
</div>

<ng-template #tooltip>
  <div class="multi-value-column-container">
    <ng-container *ngFor="let item of columnValue; first as isFirst">
      <coh-list-item-column-layout
        class="hover-element"
        *ngIf="!isFirst"
        [row]="row"
        [columnValue]="item"
        [config]="config"
        [params]="params"
        [icon]="getIcon(item)">
      </coh-list-item-column-layout>
    </ng-container>
  </div>
</ng-template>
