import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { BannerModule, defineWebComponents } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';

import { SnapshotRecoveryPluginComponent } from './snapshot-recovery-plugin.component';

const webComponents = [SnapshotRecoveryPluginComponent];

@NgModule({
  imports: [
    BannerModule,
    CommonModule,
    TranslateModule,
  ],
  exports: [SnapshotRecoveryPluginComponent],
  declarations: [SnapshotRecoveryPluginComponent],
})
export class SnapshotRecoveryPluginModule {
  constructor(injector: Injector) {
    defineWebComponents(injector, webComponents);
  }
}
