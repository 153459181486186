import { Injectable } from '@angular/core';
import {
  AgentInformation,
  ClusterNetworkingEndpoint,
  ClusterNetworkingResourceInformation,
  OracleHost,
  PhysicalProtectionSource,
} from '@cohesity/api/v1';
import { OracleDatabaseHost } from '@cohesity/api/v2';
import { PhysicalEntityType } from '@cohesity/iris-shared-constants';
import { Memoize } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';

import { REGEX_FORMATS } from '../../constants';
import { ClusterNetworkingResourceType } from '../../constants/agent.constants';
import {
  DatabaseOpenMode,
  OracleDatabaseType,
  OracleDataGuardDBRole,
  OracleDataGuardStandbyType,
} from '../../constants/oracle.constants';
import { DecoratedProtectionSourceNode } from '../../models';
import { AjsClusterService } from '../../ng-upgrade/services';
import { OracleSourceDataNode } from '../../source-tree/protection-source/oracle/oracle-source-data-node';

/**
 * Specifies an OracleDatabaseHost with additional properties extending the interface 'OracleDatabaseHost'.
 */
export interface DecoratedOracleDatabaseHost extends OracleDatabaseHost {
  hostAddress?: string;
  fqdn?: string;
  isInJob?: boolean;
  isMnmcSupported?: boolean;
  recommendedChannels?: number;
  sbtLibraryPath?: string;
}

@Injectable({
  providedIn: 'root'
})
export class OracleUtilityService {
  /**
   * Specifies a map from the translation key to its translated value for reusablility.
   */
  private translatedStringMap = new Map<string, string>();

  /**
   * ctor
   */
  constructor(
    private translateService: TranslateService,
    private ajsClusterService: AjsClusterService
  ) { }

  /**
   * Helper method to fetch the translated string. If the translation for the key doesn't exist, the same is translated
   * and added to the internal Map.
   *
   * @param     key   Specifies the key to be translated.
   * @returns   Translated string value for the key provided.
   */
  private getTranslatedString(key: string): string {
    if (this.translatedStringMap.has(key)) {
      return this.translatedStringMap.get(key);
    } else {
      const translatedKey = this.translateService.instant(key);
      this.translatedStringMap.set(key, translatedKey);
      return translatedKey;
    }
  }

  /**
   * Returns the string representation for Oracle DataGuard configuration type.
   * Refer 'OracleDataGuardDBRole' within iris/src/app/shared/constants/oracle.constants.ts for details.
   *
   * @param     node   Specifies the Protection Source Node(DB)
   * @returns   Data Guard configuration string
   */
  private getDataGuardConfigTypeString(node: DecoratedProtectionSourceNode): string {
    const dataGuardConfigTypeString = this.getTranslatedString('enum.oracleConfigurationType.dataguard');
    const dataGuardInfo = get(node, 'protectionSource.oracleProtectionSource.dataGuardInfo');
    if (!dataGuardInfo) {
      return '';
    }

    switch (dataGuardInfo.role) {
      case(OracleDataGuardDBRole.kPrimary):
        return dataGuardConfigTypeString.concat('-', this.getTranslatedString('primary'));
      case(OracleDataGuardDBRole.kStandby):
        return dataGuardConfigTypeString.concat('-', this.getTranslatedString('standby'));
    }

    return '';
  }

  /**
   * Returns the string representation for the database type.
   * Refer 'OracleDatabaseType' within iris/src/app/shared/constants/oracle.constants.ts for details.
   *
   * @param     node   Specifies the Protection Source Node(DB)
   * @returns   Database type
   */
  getDatabaseTypeString(node: DecoratedProtectionSourceNode): string {
    const dbType = get(node, 'protectionSource.oracleProtectionSource.dbType');
    if (!dbType) {
      return '';
    }

    switch (dbType) {
      case OracleDatabaseType.kSingleInstance:
        // Active-Passive based on parent host
        if (get(node, '_parentHostProtectionSource.type') === PhysicalEntityType.kOracleAPCluster) {
          return this.getTranslatedString('enum.oracleDatabaseType.kAPDatabase');
        }

        // Single Instance
        return this.getTranslatedString('enum.oracleDatabaseType.kSingleInstance');
      case OracleDatabaseType.kRACDatabase:
        return this.getTranslatedString('enum.oracleDatabaseType.kRACDatabase');
    }

    return '';
  }

  /**
   * Returns the string representation for Oracle Multitenant configuration.
   * The multitenant architecture enables an Oracle database to function as a multitenant container database (CDB).
   * A CDB includes zero, one, or many customer-created pluggable databases (PDBs).
   *
   * @param     node   Specifies the Protection Source Node(DB)
   * @returns   Multitenant configuration type
   */
  private getMultitenantContainerConfigTypeString(node: DecoratedProtectionSourceNode): string {
    const containerDatabaseInfo = get(node, 'protectionSource.oracleProtectionSource.containerDatabaseInfo');
    if (!containerDatabaseInfo) {
      return '';
    }

    return this.getTranslatedString('enum.oracleDatabaseType.kCdb');
  }

  /**
   * Returns the string representation for the Standby database for Oracle DataGuard configuration.
   * Refer 'OracleDataGuardStandbyType' within iris/src/app/shared/constants/oracle.constants.ts for details.
   *
   * @param     node   Specifies the Protection Source Node(DB)
   * @returns   Data Guard Standby configuration type.
   */
  getDataGuardStandbyTypeString(node: DecoratedProtectionSourceNode): string {
    const dataGuardStandbyType = get(node, 'protectionSource.oracleProtectionSource.dataGuardInfo.type');

    if (!dataGuardStandbyType) {
      return '';
    }

    switch (dataGuardStandbyType) {
      case OracleDataGuardStandbyType.kPhysical:
        return this.getTranslatedString('physical');
      case OracleDataGuardStandbyType.kLogical:
        return this.getTranslatedString('logical');
      case OracleDataGuardStandbyType.kSnapshot:
        return this.getTranslatedString('snapshot');
    }
    return '';
  }

  /**
   * Returns the string representation for the given database configuration. This internally encapsulates the following
   * configurations:
   *
   *  1. DataGuard Configuration.
   *  2. Database Type.
   *  3. MultitenantContainer Configuration.
   *
   * @param     node   Specifies the Protection Source Node(DB)
   * @returns   Database configuration
   */
  getDatabaseConfigTypeString(node: DecoratedProtectionSourceNode): string {
    const dataGuardConfigName = this.getDataGuardConfigTypeString(node);
    const databaseTypeName = this.getDatabaseTypeString(node);
    const multitenantContainerConfigName = this.getMultitenantContainerConfigTypeString(node);

    let databaseConfigTypeString = '';
    if (dataGuardConfigName) {
      databaseConfigTypeString = databaseConfigTypeString.concat(dataGuardConfigName, '-');
    }
    if (databaseTypeName) {
      databaseConfigTypeString = databaseConfigTypeString.concat(databaseTypeName);
    }
    if (multitenantContainerConfigName) {
      databaseConfigTypeString = databaseConfigTypeString.concat('-', multitenantContainerConfigName);
    }

    return databaseConfigTypeString;
  }

  /**
   * Returns the filtered 'clusterNetworkingInfoList' based on the 'type' parameter specified by the caller.
   *
   * @param     clusterNetworkingInfoList   Specifies the List of ClusterNetworkingResourceInformation to be filtered
   * @param     type                        Specifies the Cluster Networking Resource Type
   * @returns   Filtered Cluster networking resource info list.
   */
  filterClusterNetworkingResourceInformation(
    clusterNetworkingInfoList: ClusterNetworkingResourceInformation[],
    type: ClusterNetworkingResourceType): ClusterNetworkingResourceInformation[] {
    if (!clusterNetworkingInfoList) {
      return [];
    }

    return clusterNetworkingInfoList.filter(clusterNetworkingInfo => clusterNetworkingInfo.type === type);
  }

  /**
   * Iterates through the Agents Info list on the parent host of the current database node and returns the Set of
   * IP addresses of the physical hosts supporting Oracle Multi Node & Multi Channel for backup/restore.
   *
   * TODO(tauseef): Currently 'parentHost' is a getter available on the DataTree Node context during the Protection
   * Group creation flow which will not be present during the restore flow. Figure out a way to access the parent host.
   *
   * @param     parentProtectionSource   Specifies the parent host for the oracle source.
   * @returns   Set of IP Addresses of the physical hosts supporting MNMC.
   */
  getParentHostAddressSupportingMnmc(parentProtectionSource: PhysicalProtectionSource): Set<string> {
    const hostAddressSupportingMNMC = new Set<string>();
    const physicalAgentList = parentProtectionSource?.agents || [];
    const agentIdToHostAddress = this.getAgentIdToHostAddressMap(parentProtectionSource);
    physicalAgentList.forEach(agent => {
      if (agent.oracleMultiNodeChannelSupported) {

        // Populate all available host address of endpoints from agentIdToHostAddress
        if (agentIdToHostAddress[agent.id]?.length) {
          agentIdToHostAddress[agent.id].forEach(entry => hostAddressSupportingMNMC.add(entry));
        }
      }
    });
    return hostAddressSupportingMNMC;
  }

  /**
   * Gets agent id to host address map from parent node protectionSource object.
   * This function returns a one to many map i.e. multiple ips can point to one agent id.
   *
   * @param node    Specifies the Oracle data source node
   */
  @Memoize()
  getAgentIdToHostAddressMap(physicalProtectionSource: PhysicalProtectionSource): { [key: string]: string[]} {
    // Note: This function is used with ajs code as well. Any changes must be made keeping legacy code in mind.
    const agentIdToHostAddressMap = {};
    const networkingResourceList = get(physicalProtectionSource, 'networkingInfo.resourceVec', []);
    const agents = (physicalProtectionSource?.agents || []) as AgentInformation[];

    // Handle Parent Node details for a standalone host running database.
    if (physicalProtectionSource?.type === PhysicalEntityType.kHost) {
      for (let i = 0; i < agents.length; i++) {
        const agent = agents[i];
        if (!agent.id || !agent.name) {
          continue;
        }
        agentIdToHostAddressMap[agent.id] = [physicalProtectionSource.name];
        break;
      }
      return agentIdToHostAddressMap;
    }

    const standaloneNetworkingResourceList = this.filterClusterNetworkingResourceInformation(
      networkingResourceList, ClusterNetworkingResourceType.kServer);

    if (!standaloneNetworkingResourceList.length) {
      return agentIdToHostAddressMap;
    }

    for (let i = 0; i < agents.length; i++) {
      const agent = agents[i];
      if (!agent.id || !agent.name) {
        continue;
      }
      agentIdToHostAddressMap[agent.id] = [];

      // prepare a map for all the ips in resourvec to that point to same agent.
      for (let j = 0; j < standaloneNetworkingResourceList.length; j++) {
        const standaloneNetworkingResourceItem = standaloneNetworkingResourceList[j];
        const { endpoints } = standaloneNetworkingResourceItem;
        const tempHostAddressArray = [];
        let found = false;
        if (endpoints && endpoints.length) {
          for (let k = 0; k < endpoints.length; k++) {
            const endpointItem = endpoints[k];
            const hostAddress = endpointItem.ipv4Addr || endpointItem.ipv6Addr || endpointItem.fqdn;
            tempHostAddressArray.push(hostAddress);
            if ([endpointItem.ipv4Addr, endpointItem.ipv6Addr, endpointItem.fqdn].includes(agent.name)) {
              found = true;
            }
          }
          if (found) {
            // this is the resourceVec entry that has the agent ip. copy all the endpoints in this respourceVec
            // in the agentIdToHostAddressMap.
            tempHostAddressArray.forEach(hostAddress => agentIdToHostAddressMap[agent.id].push(hostAddress));
          }
        }
      }
    }

    return agentIdToHostAddressMap;
  }

  /**
   * Gets host address to agent id map from parent node protectionSource object.
   * This function returns a many to one map i.e. multiple hosts can point to one agent.
   *
   * @param node    Specifies the Oracle data source node
   */
  @Memoize()
  getHostAddressToAgentIdMap(physicalProtectionSource: PhysicalProtectionSource): { [key: string]: string} {
    const hostAddressToAgentId = {};
    const agentIdToHostAddress = this.getAgentIdToHostAddressMap(physicalProtectionSource);

    for (const id of Object.keys(agentIdToHostAddress)) {
      agentIdToHostAddress[id].forEach(hostAddress => {
        hostAddressToAgentId[hostAddress] = id;
      });
    }
    return hostAddressToAgentId;
  }

  /**
   * Returns the parent node list available for backup/restore by iterating and filtering through the cluster
   * networking resource list for 'standalone' servers.
   *
   * @param     node       Specifies the Oracle source data node
   * @param     isRestore  Specifies if it is a restore task or not.
   * @returns   Array of available ClusterNetworkingEndpoint.
   */
  getAvailableParentNodeList(node: OracleSourceDataNode, isRestore = false, physicalProtectionSource?:
    PhysicalProtectionSource): ClusterNetworkingEndpoint[] {
    const parentHost = node?.parentHost;
    const networkingResourceList = isRestore ?
      (physicalProtectionSource?.networkingInfo?.resourceVec || []) :
      get(parentHost, 'data.protectionSource.physicalProtectionSource.networkingInfo.resourceVec', []);

    // Handle Parent Node details for a standalone host running database.
    if (!networkingResourceList?.length) {
      if (isRestore && physicalProtectionSource?.type === PhysicalEntityType.kHost) {
        return [{
          ipv4Addr: physicalProtectionSource.name,
          fqdn: physicalProtectionSource.hostName,
        }];
      }

      if (parentHost?.type === PhysicalEntityType.kHost) {
        return [{
          ipv4Addr: parentHost.protectionSource.physicalProtectionSource.name,
          fqdn: parentHost.protectionSource.physicalProtectionSource.hostName,
        }];
      }
      return [];
    }

    const standaloneNetworkingResourceList = this.filterClusterNetworkingResourceInformation(
      networkingResourceList, ClusterNetworkingResourceType.kServer);

    if (!standaloneNetworkingResourceList?.length) {
      return [];
    }

    const availableParentNodeList = new Array<ClusterNetworkingEndpoint>();
    standaloneNetworkingResourceList.map(networkResource => {
      if (networkResource.endpoints && networkResource.endpoints.length) {
        // Filtering the list of preferred endpoint and disallowing private endpoints to show up during
        // MNMC node selection.
        const preferredEndpoints = networkResource.endpoints.filter(endpoint => endpoint.isPreferredEndpoint);

        if (preferredEndpoints?.length) {
          availableParentNodeList.push(preferredEndpoints[0]);
        } else {
          availableParentNodeList.push(networkResource.endpoints[0]);
        }
      }
    });

    if (!isRestore) {
      const databaseHostIpAddresses = this.getDatabaseHostsIpAddresses(node);

      // In case of single instance oracle server filtering out multiple kServer entries
      // based on available database hosts ipAddresses.
      if (databaseHostIpAddresses?.length && node.databaseType === OracleDatabaseType.kSingleInstance) {
        return availableParentNodeList.filter(value => databaseHostIpAddresses.includes(value.ipv4Addr ||
          value.ipv6Addr || value.fqdn));
      }
    }
    return availableParentNodeList;
  }

  /**
   * Returns the list of Hosts avaialbale for the given Oracle database node.
   *
   * @param     node   Specifies the Oracle source data node
   * @returns   Array of Oracle Hosts.
   */
  getDatabaseHostList(node: OracleSourceDataNode): OracleHost[] {
    return get(node, 'protectionSource.oracleProtectionSource.hosts', []);
  }

  /**
   * Generates a Map of IP address for the database host to its array index within the Hosts array.
   * This is required to improve lookups determine nodes available for Multi node backup/restore.
   *
   * @param     node   Specifies the Oracle source data node
   * @returns   Map of Host IP to its Array Index within the Oracle Hosts Array.
   */
  getDatabaseHostIndexMap(node: OracleSourceDataNode): Map<string, number> {
    const databaseHostIndexMap = new Map<string, number>();
    if (!node) {
      return databaseHostIndexMap;
    }
    const oracleHostList = this.getDatabaseHostList(node);
    if (!oracleHostList.length) {
      return databaseHostIndexMap;
    }

    // Prepare the host index map.
    oracleHostList.forEach((host, index) => {
      if (host.ipAddresses && host.ipAddresses.length) {
        // Add the index of the Host data against the IP Address as the key for faster lookups.
        host.ipAddresses.forEach(ipAddress => {
          databaseHostIndexMap.set(ipAddress, index);
        });
      }
    });
    return databaseHostIndexMap;
  }

  /**
   * Returns a list of IP address within the database Hosts array.
   * This is required to improve lookups determine nodes available for Multi node backup/restore.
   *
   * @param     node   Specifies the Oracle source data node
   * @returns   Array of Host IP addresses within the Oracle database Hosts Array.
   */
  getDatabaseHostsIpAddresses(node: OracleSourceDataNode): string[] {
    const databaseHostIpAddresses: string[] = [];

    if (!node) {
      return databaseHostIpAddresses;
    }

    const oracleHostList = this.getDatabaseHostList(node);

    if (!oracleHostList?.length) {
      return databaseHostIpAddresses;
    }

    oracleHostList.forEach((host) => {
      if (host?.ipAddresses?.length) {
        host.ipAddresses.forEach(ipAddress => {
          databaseHostIpAddresses.push(ipAddress);
        });
      }
    });
    return databaseHostIpAddresses;
  }

  /**
   * Determines the number of Oracle RMAN channels.
   * An RMAN channel represents one stream of data to a device, and
   * corresponds to one database server session.
   *
   * @param    cpuCount   Specifies the number of CPU in the host
   * @return   Number of channels available for backup
   */
  calculateOracleChannnels(cpuCount): number {
    const nodeCount = this.ajsClusterService.clusterInfo.nodeCount;

    if (!cpuCount) {
      return nodeCount;
    }
    return Math.min(nodeCount, 2 * cpuCount);
  }

  /**
   * Determines the string representation for the database Open mode.
   *
   * @param    openMode   Specifies the open mode for the database.
   * @return   String representation of the database open mode.
   */
  getDatabaseOpenModeString(openMode: DatabaseOpenMode): string {
    if (!openMode) {
      return '';
    }
    return this.getTranslatedString(openMode);
  }

  /**
   * Converts hostAddress to hostId in databasehost.
   *
   * @param databaseHost    DecoratedOracleDatabaseHost object
   */
  patchHostIdInDatabaseNodeList(
    nodes: DecoratedOracleDatabaseHost[],
    parentNode: OracleSourceDataNode
  ): DecoratedOracleDatabaseHost[] {
    if (!nodes) {
      return null;
    }

    const hostAddressToAgentId = this.getHostAddressToAgentIdMap(
      parentNode.parentHost.data.protectionSource.physicalProtectionSource);
    return nodes.map(node => {
      const host = node.hostId;
      return {
        ...node,
        hostId: isNaN(host as any) ? hostAddressToAgentId[host] : host,
      };
    });
  }

  /**
   * Determines whether numeric values or not.
   *
   * @param    value input value
   * @returns Boolean to determine whether value is number or not.
   */
  isStringNumeric(value: string): boolean {
    if (!value) {
      return;
    }

    // Stripping quotes in case of values eg:"'123M'", "'234'".
    value = value?.replace(/"|'/g, '');

    if (value.length > 1) {
      // Removing last character for checking value eg: 123M
      return value?.trim()?.slice(0, -1)?.match(REGEX_FORMATS.wholeNumbers)?.length > 0;
    }
    return false;
  }

  /**
   * Returns the string representation for the database entity type.
   *
   * @param   type Selected parent host entity type
   * @returns string representation of selected db instance entity type.
   */
  getDbEntityTypeString(type: string): string {
    if (!type) {
      return '';
    }

    switch (type) {
      // Single Instance
      case PhysicalEntityType.kHost:
        return this.getTranslatedString('enum.oracleDatabaseType.kSingleInstance');

      // Active-Passive based on parent host
      case PhysicalEntityType.kOracleAPCluster:
        return this.getTranslatedString('enum.oracleDatabaseType.kAPDatabase');

      case PhysicalEntityType.kOracleRACCluster:
        return this.getTranslatedString('enum.oracleDatabaseType.kRACDatabase');
    }
    return '';
  }

  /**
   * Returns oracle db type based on selected parent entity type.
   *
   * @param   type Selected parent entity type
   * @returns oracle database type.
   */
  getOracleDatabaseTypeBasedOnParentHost(type: string): OracleDatabaseType {
    if (!type) {
      return;
    }

    switch (type) {
      // Single Instance
      case PhysicalEntityType.kHost:
        return OracleDatabaseType.kSingleInstance;

      // Active-Passive/Rac Cluster based on parent host
      case PhysicalEntityType.kOracleAPCluster:
      case PhysicalEntityType.kOracleRACCluster:
        return OracleDatabaseType.kRACDatabase;
    }
  }

  /**
   * Method called to compare and check if the oracle db version are same or not.
   *
   * For eg:
   * In case 12.1.x is compared with 12.1 the below function would return true.
   * where as 19.0.x is compared with 12.1 then it would return false.
   *
   * @param sourceDbVersion source db version to be compared
   * @param versionStrToCompare version string to be compared with source db version
   * @returns True if database version are equal, else returns false.
   */
  checkOracleDbVersionAreEqual(sourceDbVersion: string, versionStrToCompare: string): boolean {
    if (!sourceDbVersion?.trim()?.length || !versionStrToCompare?.trim()?.length) {
      return false;
    }
    const sourceVersionArr = sourceDbVersion?.trim()?.split('.');
    const targetVersionArr = versionStrToCompare?.trim()?.split('.');

    return sourceVersionArr[0] === targetVersionArr[0] && sourceVersionArr[1] === targetVersionArr[1];
  }
}
