import { Injectable } from '@angular/core';
import { NetworkInformationServiceServiceApi, NisProviders, NisProvider, NisNetgroup } from '@cohesity/api/v2';
import { Observable } from 'rxjs';

/**
 * This service holds the NIS API functions.
 */
@Injectable({
  providedIn: 'root'
})
export class NISService {
  constructor(private nisApiService: NetworkInformationServiceServiceApi) {}

  /**
   * Get all available NisProviders.
   *
   * @returns   Observable of NisProviders.
   */
  getNisProviderList(): Observable<NisProviders> {
    return this.nisApiService.GetNisProviders({domainNames: []});
  }

  /**
   * Create new NIS provider.
   *
   * @params   newNIS   New NIS object.
   * @returns  Observable of successfully created NisProvider.
   */
  createNisProvider(newNIS: NisProvider): Observable<NisProvider> {
    return this.nisApiService.CreateNisProvider({body: newNIS});
  }

  /**
   * Update current NIS provider.
   *
   * @param   updateddNIS   Updated NIS object.
   * @returns Observable of updated NIS provider.
   */
  editNisProvider(updateddNIS: NisProvider): Observable<NisProvider> {
    const param: NetworkInformationServiceServiceApi.UpdateNisProviderByDomainNameParams = {
      domain: updateddNIS.domain,
      body: updateddNIS,
    };
    return this.nisApiService.UpdateNisProviderByDomainName(param);
  }

  /**
   * Delete current NIS provider.
   *
   * @param   domainName   The domain name of the delete NIS provider.
   */
  deleteNisProvider(domainName: string): Observable<null> {
    return this.nisApiService.DeleteNisProviderByDomainName({domain: domainName});
  }

  /**
   * Delete current Netgroup whitelist
   *
   * @param   netgroup   The netgroup whitelist to be deleted.
   */
  deleteNetgroup(netgroup: NisNetgroup): Observable<null> {
    const deleteParam: NetworkInformationServiceServiceApi.DeleteNisNetgroupByNameParams = {
      name: netgroup.name,
      body: netgroup,
    };
    return this.nisApiService.DeleteNisNetgroupByName(deleteParam);
  }

}
