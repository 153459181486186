<mat-radio-group
  [formControl]="control"
  class="coh-radio-group"
  cogDataId="radio-group"
  [inheritDataId]="true">
  <mat-radio-button
    *ngFor="let button of config?.radioButtons"
    [value]="button.value"
    [cogDataId]="button.id">
    <mat-label>{{ button.label }}</mat-label>
  </mat-radio-button>
</mat-radio-group>

<div *ngIf="!!formControlGetterFn" [cogDataId]="(formPanel$ | async)?.id">
  <div *ngFor="let field of (formPanel$ | async)?.fields" [cogDataId]="field.id">
    <ng-container [ngSwitch]="field.type">
      <cog-string-form-field *ngSwitchCase="'string'"
        cogDataId="string-form-field"
        [inheritDataId]="true"
        [label]="field.label"
        [config]="field.typeConfig?.stringConfig"
        [control]="formControlGetterFn(field.key)"
        [validationErrorFn]="validationErrorFn">
      </cog-string-form-field>
      <cog-password-form-field *ngSwitchCase="'password'"
        cogDataId="password-form-field"
        [inheritDataId]="true"
        [label]="field.label"
        [config]="field.typeConfig?.passwordConfig"
        [control]="formControlGetterFn(field.key)"
        [validationErrorFn]="validationErrorFn">
      </cog-password-form-field>
      <cog-number-form-field *ngSwitchCase="'number'"
        cogDataId="number-form-field"
        [inheritDataId]="true"
        [label]="field.label"
        [config]="field.typeConfig?.numberConfig"
        [control]="formControlGetterFn(field.key)"
        [validationErrorFn]="validationErrorFn">
      </cog-number-form-field>
      <cog-boolean-form-field *ngSwitchCase="'boolean'"
        cogDataId="boolean-form-field"
        [inheritDataId]="true"
        [label]="field.label"
        [config]="field.typeConfig?.booleanConfig"
        [control]="formControlGetterFn(field.key)"
        [validationErrorFn]="validationErrorFn">
      </cog-boolean-form-field>
      <cog-radio-group-form-field *ngSwitchCase="'radioGroup'"
        cogDataId="radio-group-form-field"
        [inheritDataId]="true"
        [label]="field.label"
        [config]="field.typeConfig?.radioGroupConfig"
        [control]="formControlGetterFn(field.key)"
        [validationErrorFn]="validationErrorFn"
        [formControlGetterFn]="formControlGetterFn">
      </cog-radio-group-form-field>
    </ng-container>
  </div>
</div>
