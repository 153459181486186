<form
  [formGroup]="groupDeleteForm"
  cohValidateOnSubmit
  (submit)="onDelete()">
  <h1 mat-dialog-title>
    {{ (isSnapshotDeletionOnly ? 'deleteJob.deleteAllSnapshotsFromJob' : 'deleteJob.deleteProtectionJob')
      | translate }}
  </h1>
  <div mat-dialog-content>
    <div>
      {{ (isSnapshotDeletionOnly ? 'protectionGroups.deleteSnapshotsPrompt' : 'protectionGroups.deleteGroupPrompt')
        | translate : { name: data.protectionGroup.name } }}
    </div>
    <cog-banner class="margin-top" status="info">
      {{ (storageArraySnapshotPolicyEnabled ? 'deleteJob.infoMessage.storageArraySnapshots' : 'deleteJob.infoMessage')
        | translate }}
    </cog-banner>
    <cog-banner *ngIf="groupDeleteForm.controls.enableSnapshotDeletion.value && (hasCloudVaultSnapshots$ | async)"
      status="warn"
      class="margin-top">
      <p>{{ 'cloudVaultSnapshotsWarning' | translate }}</p>
    </cog-banner>
    <div class="margin-top margin-bottom" *ngIf="!isSnapshotDeletionOnly">
      <mat-slide-toggle
        *ngIf="!data.protectionGroup.isDeleted;"
        color ="primary"
        formControlName="enableSnapshotDeletion">
        {{ 'deleteSnapshots' | translate }}
     </mat-slide-toggle>
    </div>
    <mat-form-field class="margin-top">
      <input cogDataId="confirm-delete-view-input"
        matInput
        placeholder="{{'typeYesToConfirm' | translate}}"
        formControlName="confirmDelete"
        autocomplete="off">
        <mat-error cogDataId="confirm-required-invalid"
          *ngIf="groupDeleteForm.controls.confirmDelete.errors?.required">
          {{'errors.required' | translate}}
        </mat-error>
        <mat-error cogDataId="confirm-pattern-pattern"
          *ngIf="groupDeleteForm.controls.confirmDelete.errors?.pattern">
          {{'mustInputYes' | translate}}
        </mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions align="end">
    <button
      mat-button
      mat-dialog-close
      color="primary"
      cogDataId="cancel-delete-group">
      {{ 'cancel' | translate }}
    </button>
    <button
      *ngIf="!isSnapshotDeletionOnly"
      mat-flat-button
      type="submit"
      color="primary"
      cdkFocusInitial
      cogDataId="delete-group-only">
      {{ 'deleteGroup' | translate }}
    </button>
    <button
      *ngIf="isSnapshotDeletionOnly"
      mat-flat-button
      type="button"
      (click)="deleteSnapshots()"
      color="primary"
      cdkFocusInitial
      cogDataId="delete-snapshots">
      {{ 'deleteSnapshots' | translate }}
  </button>
  </div>
</form>
