import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { ProtectionSourceNode } from '@cohesity/api/v1';

import { BaseProtectionSourceService } from '../shared/base-protection-source.service';
import { SfdcSourceDataNode } from './sfdc-source-data-node';
import { SfdcFieldsOptionsComponent } from './sfdc-fields-options/sfdc-fields-options.component';
import { SourceSelection } from '@cohesity/iris-source-tree';
import { DataTreeSelection } from '@cohesity/helix';
import { EnvironmentSourceSpecialParameters } from 'src/app/shared/constants';
/**
 * Source tree service for Salesforce.
 */
@Injectable({
  providedIn: 'root',
})
export class SfdcSourceTreeService extends BaseProtectionSourceService<SfdcSourceDataNode> {

  constructor() {
    super();
  }

  /**
   * Gets a component to render for a source's special parameters. This does not apply to every node
   * and can be null for certain data types.
   */
  getSpecialParametersComponent(node: SfdcSourceDataNode): ComponentType<any> {
    if (node.type === 'kObject') {
      return SfdcFieldsOptionsComponent;
    }

    return null;
  }

  /**
   * Transforms the node object from the api into a Protection Source Tree node to pass to the tree.
   *
   * @param   node   The original node.
   * @param   level  The level in the tree.
   * @return  A SfdcSourceDataNode that can be displayed in the tree.
   */
  transformData(node: ProtectionSourceNode, level: number): SfdcSourceDataNode {
    return new SfdcSourceDataNode(node, level);
  }

  /**
   * Convert source selection to the data tree selection model.
   *
   * @param   allNodes         The unfiltered list of tree nodes.
   * @param   sourceSelection  The job selection.
   * @return  A data tree selection model.
   */
  transformToDataTreeSelection(
    allNodes: SfdcSourceDataNode[],
    sourceSelection: SourceSelection
  ): DataTreeSelection<SfdcSourceDataNode> {
    const treeSelection = super.transformToDataTreeSelection(allNodes, sourceSelection);

    if (!sourceSelection) {
      return treeSelection;
    }

    const { sourceSpecialParameters } = sourceSelection;

    if (sourceSpecialParameters?.length > 0) {
      treeSelection.options = {};
      const sfdcSpecialParameters = sourceSpecialParameters[0][EnvironmentSourceSpecialParameters.kSfdc];
      if (sfdcSpecialParameters) {
        sfdcSpecialParameters.excludefields?.forEach(params => {
          treeSelection.options[params.objectId] = params;
        });
      } else {
        sourceSpecialParameters.forEach(params => {
          if (params.objectId) {
            treeSelection.options[params.objectId] = params;
          }
        });
      }
    }
    return treeSelection;
  }
}
