import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { NgElement, WithProperties } from '@angular/elements';

export type OutputProps<T> = {
  [property in keyof T]: T;
};

export interface OutputEventListener<T> {
  key: keyof T;
  listener: EventListenerOrEventListenerObject;
}

@Component({
  selector: 'cog-web-component',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebComponent<T> implements OnChanges, OnDestroy {
  /** The web component name */
  @Input() name: string;

  /** The instance of web component */
  private customElement: NgElement & WithProperties<T>;

  /** The component's output event listners */
  private outputEventListeners: OutputEventListener<T>[] = [];

  constructor(private host: ElementRef<HTMLElement>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.name) {
      this.clean();
      if (this.name) {
        this.render();
      }
    }
  }

  ngOnDestroy(): void {
    this.clean();
  }

  /**
   * Clear the rendred element and event listners.
   */
  private clean() {
    // clearnup the event listners.
    this.outputEventListeners.forEach(
      ({ key, listener }) => this.customElement.removeEventListener(key as string, listener)
    );
    this.outputEventListeners = [];

    this.host.nativeElement.innerHTML = '';
  }

  /**
   * Render the provided web component.
   */
  private render() {
    this.customElement = document.createElement(this.name) as any;
    this.host.nativeElement.appendChild(this.customElement);
  }

  /**
   * Set the input props for the web component.
   *
   * @param inputProps The input props with values.
   */
  updateInput(inputProps: Partial<WithProperties<T>>) {
    if (this.customElement) {
      Object.keys(inputProps).forEach(key => this.customElement[key] = inputProps[key]);
    }
  }

  /**
   * Set the output event listner for the web component.
   *
   * @param outputProps The output props with parent compoent's instaince ref for emitting the event.
   */
  setupOutput(outputProps: Partial<OutputProps<T>>) {
    if (this.customElement) {
      Object.keys(outputProps).forEach((key) => {
        const parentThis = outputProps[key];
        const listener = event => parentThis[key].emit(event?.detail);

        // keeping the list of listener which will be cleared on component destory.
        this.outputEventListeners.push({ key: key as keyof T, listener });
        this.customElement.addEventListener(key as any as string, listener);
      });
    }
  }
}
