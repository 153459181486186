import { Injectable } from '@angular/core';
import { McmClusterServiceApi } from '@cohesity/api/private';
import { IrisContextService, isClusterScope, isDmsScope, isDmsSelfServiceUser } from '@cohesity/iris-core';
import { HookMatchCriteria, Transition } from '@uirouter/core';
import { map, switchMap } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services/dialog.service';
import { GuardPriority, GuardResult, StateGuard } from 'src/app/core/state/state-guard';
import { ClusterSelectorComponent } from 'src/app/shared';

/**
 * List of states the user can visit before onboarding setup has been completed.
 */
const recoveryStates = [
  'active-directory-recovery-search',
  'clone-view.search',
  'instant-volume-mount-ng',
  'kubernetes-recovery.search',
  'migrate-vm-ng',
  'recover-azure-sql-ng',
  'recover-cassandra-ng',
  'recover-couchbase-ng',
  'recover-db',
  'recover-exchange-ng',
  'recover-files-ng',
  'recover-hbase-ng',
  'recover-hdfs-ng',
  'recover-hive-ng',
  'recover-kubernetes-ng',
  'recover-mongodb-ng',
  'recover-ms-sql',
  'recover-office365-ng',
  'recover-office365.search.mailboxes',
  'recover-office365.search.onedrives',
  'recover-office365.search.sharePoint',
  'recover-physical-server-ng',
  'recover-rds-ng',
  'recover-sfdc-ng',
  'recover-s3-ng',
  'recover-storage-volume-ng',
  'recover-uda-ng',
  'recover-virtual-disk-ng',
  'recover-vm-ng',
  'recover-aws-db-ng',
];

/**
 * This state guards entry to cluster recovery flows cominig from any time a user is in a non-cluster context.
 * When activated, it will show a dialog asking the user to choose a cluster on which to perform a recovery.
 */
@Injectable({ providedIn: 'root' })
export class McmRecoverGuard implements StateGuard {
  /**
   * Run this at app priority
   */
  guardPriority = GuardPriority.App;

  /**
   * Match on all the recovery states defined above.
   */
  matchCriteria: HookMatchCriteria = {
    to: state => recoveryStates.includes(state.name),
  };

  constructor(
    private irisContextService: IrisContextService,
    private clustersApi: McmClusterServiceApi,
    private dialogService: DialogService
  ) {}

  /**
   * Check if the user is in a cluster scope, and if not, show a dialog, and add the cid param to the transition.
   *
   * @param transition ui router transition
   * @returns The guard result.
   */
  onStart(transition: Transition): GuardResult {
    const target = transition.targetState();
    const params = target.params();

    // If we are in a cluster, or have a cid or region param set, we can ignore this guard.
    if (
      isClusterScope(this.irisContextService.irisContext) ||
      isDmsScope(this.irisContextService.irisContext) ||
      isDmsSelfServiceUser(this.irisContextService.irisContext) ||
      params.cid ||
      params.regionId
    ) {
      return true;
    }

    // If we are in rpaas, we need too choose a cluster to run the recovery on
    return this.clustersApi.getClustersConnectionStatus().pipe(
      map(result => result || []),
      switchMap(availableClusters =>
        this.dialogService.showDialog(ClusterSelectorComponent, {
          title: 'selectCluster',
          subtitle: 'selectClusterToRecover',
          availableClusters,
        })
      ),
      map((clusterId: number) => {
        if (!clusterId) {
          return false;
        }
        return transition.router.stateService.target(
          target.name(),
          {
            ...params,
            cid: clusterId,
          },
          target.options()
        );
      })
    );
  }
}
