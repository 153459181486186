import { Injectable } from '@angular/core';
import { DataFilterValue } from '@cohesity/helix';
import { hasDataClassificationSku, hasThreatProtectionSku, IrisContextService, isRpaasUser } from '@cohesity/iris-core';
import { Moment } from 'moment';
import { BehaviorSubject } from 'rxjs';

/**
 * Represents a date filter value.
 */
export interface FilterValue {
 /**
  * The start date.
  */
  start: Moment;

  /**
   * The end date.
   */
  end: Moment;

  /**
   * The timeframe represented as a number.
   */
  timeframe: number;

  /**
   * The label for the timeframe.
   */
  timeframeLabel: string;
}

/**
 * The available dashboards in security center
 */
export const securityCenterDashboards = {
  securityPosture: 'securityPosture',
  threatProtection: 'threatProtection',
  dataSensitivity: 'dataSensitivity',
  cyberVaulting: 'cyberVaulting'
};


/**
 * The icons to display in Dashboard tabs based on dashboard score
 */
export const dashboardTabIconMap = {
  'high': 'helix:status-error!critical',
  'high-active': 'helix:status-error-alt!critical',
  'medium': 'helix:status-warning!warning',
  'medium-active': 'helix:status-warning-alt!warning',
  'low': 'helix:status-success!success',
  'low-active': 'helix:status-success-alt!success',
  'blocked': 'block',
  'blocked-active': 'block',
  'locked': 'lock!outline',
  'locked-active': 'lock',
};

/**
 * Contains the context related to Security Center Dashboard.
 */
@Injectable()
export class SecurityCenterDashboardService {
  /**
   * Track the current value of the date range filter.
   */
  private readonly dateRangeFilterSubject = new BehaviorSubject<DataFilterValue<FilterValue>>(null);

  /**
   * Track the current value of the date range filter.
   */
  readonly dateRangeFilter$ = this.dateRangeFilterSubject.asObservable();

  /**
   * Subject to show / hide date range filter.
   */
  private readonly _showDateRangeFilter = new BehaviorSubject<boolean>(true);

  /**
   * Observable to show / hide date range filter.
   */
  readonly showDateRangeFilter$ = this._showDateRangeFilter.asObservable();

  /**
   * Subject to indicate when to navigate to security posture
   */
  readonly navigateToUrl = new BehaviorSubject<string>(null);

  /**
   * Subject to indicate when to navigate to anti ransomware summary
   */
  readonly navigateToAntiRansomwareSummary = new BehaviorSubject<boolean>(null);

  /**
   * Subject to indicate whether paywall is needed for dashboards
   */
  private paywallNeededSubject = new BehaviorSubject<{[key: string]: boolean}>({});

  /**
   * Observable for components to know whether paywall is needed for dashboards
   */
  paywallNeeded$ = this.paywallNeededSubject.asObservable();

  /**
   * Map for determining dashboard tab icon and color
   */
  dashboardTabStatuses$ = new BehaviorSubject<{[key: string]: string}>({});

  constructor(
    readonly irisCtx: IrisContextService
  ) {
    Object.keys(securityCenterDashboards).forEach(dashboard => {
      this.dashboardTabStatuses$.next({...this.dashboardTabStatuses$.value, ...{
        [dashboard] : ''
      }});
    });

    this.setPaywallStatus();
  }

  /**
   * Set the paywall statuses for various dashboards
   */
  setPaywallStatus() {
    this.paywallNeededSubject.next({
      [securityCenterDashboards.threatProtection]: !hasThreatProtectionSku(this.irisCtx.irisContext),
      [securityCenterDashboards.dataSensitivity]: !hasDataClassificationSku(this.irisCtx.irisContext),
      [securityCenterDashboards.cyberVaulting]: !isRpaasUser(this.irisCtx.irisContext),
    });
  }

  /**
   * Set the date filter. When set, all api calls will be filtered to within these dates
   *
   * @param startDate   The start date
   * @param endDate     The end date
   */
  setDateRangeFilter(dateFilterValue: DataFilterValue<FilterValue>) {
    if (dateFilterValue) {
      this.dateRangeFilterSubject.next(dateFilterValue);
    }
  }

  /**
   * Set the status for a dashboard tab
   *
   * @param key The key for dashboard whose status is to be set
   * @param status The status to be set
   */
  setDashboardTabStatus(key: string, status: string) {
    const dashboardStatus = this.dashboardTabStatuses$.value;
    dashboardStatus[key] = status;

    this.dashboardTabStatuses$.next(dashboardStatus);
  }

  /**
   * Toggles the date range filter.
   *
   * @param event - A boolean value indicating whether to hide the date range filter or not.
   */
  showDateRangeFilter(event: boolean) {
    this._showDateRangeFilter.next(event);
  }
}
