/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DataSourceId } from '../models/data-source-id';
import { DomainName } from '../models/domain-name';
import { GetPrincipalsResponse } from '../models/get-principals-response';
import { PaginationToken } from '../models/pagination-token';
import { PrincipalId } from '../models/principal-id';
import { PrincipalType } from '../models/principal-type';

@Injectable({
  providedIn: 'root',
})
export class UsersAndGroupsApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPrincipals
   */
  static readonly GetPrincipalsPath = '/principals';

  /**
   * Get the list of Principals (Users and Groups).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPrincipals()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPrincipals$Response(params?: {

    /**
     * Specifies the Principal name.
     */
    principalName?: string;

    /**
     * Specifies the Principal ids.
     */
    ids?: null | Array<PrincipalId>;

    /**
     * Specifies the Principal types (User or Group).
     */
    types?: null | Array<PrincipalType>;

    /**
     * Specifies the data source ids.
     */
    dataSourceIds?: null | Array<DataSourceId>;

    /**
     * Specifies the active directory domain names
     */
    domainNames?: null | Array<DomainName>;

    /**
     * Specifies the maximum number of Principals returned per response and it should be less that 1000.
     */
    maxResults?: null | number;

    /**
     * Specifies the pagination token used to get next set of Principals.
     */
    paginationToken?: PaginationToken;

  }): Observable<StrictHttpResponse<GetPrincipalsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UsersAndGroupsApiService.GetPrincipalsPath, 'get');
    if (params) {

      rb.query('principalName', params.principalName, {});
      rb.query('ids', params.ids, {});
      rb.query('types', params.types, {});
      rb.query('dataSourceIds', params.dataSourceIds, {});
      rb.query('domainNames', params.domainNames, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('paginationToken', params.paginationToken, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetPrincipalsResponse>;
      })
    );
  }

  /**
   * Get the list of Principals (Users and Groups).
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPrincipals$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPrincipals(params?: {

    /**
     * Specifies the Principal name.
     */
    principalName?: string;

    /**
     * Specifies the Principal ids.
     */
    ids?: null | Array<PrincipalId>;

    /**
     * Specifies the Principal types (User or Group).
     */
    types?: null | Array<PrincipalType>;

    /**
     * Specifies the data source ids.
     */
    dataSourceIds?: null | Array<DataSourceId>;

    /**
     * Specifies the active directory domain names
     */
    domainNames?: null | Array<DomainName>;

    /**
     * Specifies the maximum number of Principals returned per response and it should be less that 1000.
     */
    maxResults?: null | number;

    /**
     * Specifies the pagination token used to get next set of Principals.
     */
    paginationToken?: PaginationToken;

  }): Observable<GetPrincipalsResponse> {

    return this.getPrincipals$Response(params).pipe(
      map((r: StrictHttpResponse<GetPrincipalsResponse>) => r.body as GetPrincipalsResponse)
    );
  }

}
