import { Component } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { filter, startWith } from 'rxjs/operators';
import {
  BaseProtectionBuilderComponent
} from 'src/app/modules/protection/protection-builder/components/base-protection-builder/base-protection-builder.component';
import { ProtectionItemName } from 'src/app/modules/protection/protection-builder/models';
import { Environment } from 'src/app/shared/constants';

/**
 * Type for a controller.
 */
type ControllerType = 'kScsi' | 'kIde' | 'kSata';

/**
 * Interface for a single excluded disk.
 */
interface Disk {
  controllerType: ControllerType;
  busNumber: number;
  unitNumber: number;
}

/**
 * Interface for disk exclusions and excluding physical RDM.
 */
interface DiskExclusions {
  excludeDisks: {
    enabled: boolean;
    disks?: Disk[];
  };
  excludePhysicalRdm: boolean;
}

/**
 * Default value for disk exclusions.
 */
export const DefaultDiskExclusions: DiskExclusions = {
  excludeDisks: {
    enabled: false,
    disks: [],
  },
  excludePhysicalRdm: false,
};

@Component({
  selector: 'coh-settings-list-disk-exclusions',
  templateUrl: './settings-list-disk-exclusions.component.html',
  styleUrls: ['./settings-list-disk-exclusions.component.scss']
})
export class SettingsListDiskExclusionsComponent
  extends BaseProtectionBuilderComponent<DiskExclusions, any> {

  /**
   * Default value for this form group.
   */
  _value = DefaultDiskExclusions;

  /**
   * Variable to hold the current environment.
   */
  environment: Environment;

  /**
   * Disk exclusions form group for the parent adapter form.
   */
  exclusionFormGroup = new UntypedFormGroup({
    excludeDisks: new UntypedFormGroup({
      enabled: new UntypedFormControl(false, Validators.required),
      disks: new UntypedFormArray([]),
    }),
    excludePhysicalRdm: new UntypedFormControl(false, Validators.required),
  });

  /**
   * Get excluded disks form group.
   *
   * @return   FormGroup of excluded disks.
   */
  get excludeDisksFormGroup(): UntypedFormGroup {
    return this.exclusionFormGroup.get('excludeDisks') as UntypedFormGroup;
  }

  /**
   * Get the enabled control within excluded disks form group.
   *
   * @return   FormControl of the enabled toggle.
   */
  get excludeDisksEnabledControl(): UntypedFormControl {
    return this.excludeDisksFormGroup.get('enabled') as UntypedFormControl;
  }

  /**
   * Get the array of disk form controls from within excluded disks form group.
   *
   * @return   FormArray of disks controls.
   */
  get excludeDisksDisksControl(): UntypedFormArray {
    return this.excludeDisksFormGroup.get('disks') as UntypedFormArray;
  }

  /**
   * Attach internal form group to the parent component.
   */
  addFormControl() {
    this.formGroup.addControl(this.name, this.exclusionFormGroup);
  }

  /**
   * Add a new disk to excluded disks.
   *
   * @param   value   default disk value.
   */
  addDiskFormControl(value = {} as any) {
    const {controllerType, busNumber, unitNumber} = value;

    this.excludeDisksDisksControl.push(
      new UntypedFormGroup({
        controllerType: new UntypedFormControl(controllerType, Validators.required),
        busNumber: new UntypedFormControl(
          busNumber, [Validators.required, Validators.min(0)]
        ),
        unitNumber: new UntypedFormControl(
          unitNumber, [Validators.required, Validators.min(0)]
        ),
      })
    );
  }

  /**
   * Remove a disk at an index.
   *
   * @param   index   index to remove the disk at.
   */
  removeDiskFormControl(index) {
    this.excludeDisksDisksControl.removeAt(index);
  }

  /**
   * Form control init.
   */
  initFormControl() {
    const sourceFormControl = this.formGroup.parent.get(ProtectionItemName.Source);

    if (sourceFormControl) {
      sourceFormControl.valueChanges.pipe(
        this.untilDestroy(),
        startWith(sourceFormControl.value),
        filter(value => !!value)
      ).subscribe(value => {
        this.environment = value.protectionSource.environment;
      });
    }

    if (this.protectionGroup) {
      let {
        excludeDisks,
        excludePhysicalRdm,
      } = this.protectionGroup;

      // Backend sends excludeDisks as null if it is not set
      excludeDisks = excludeDisks || [];
      excludePhysicalRdm = excludePhysicalRdm || false;

      for (const excludeDisk of excludeDisks) {
        // Create a new form control item for each new existing disk.
        this.addDiskFormControl(excludeDisk);
      }

      this.value = {
        excludeDisks: {
          enabled: Boolean(excludeDisks.length),
          disks: excludeDisks,
        },
        excludePhysicalRdm,
      };
      this.formControl.setValue(this.value);
    }

    this.excludeDisksEnabledControl.valueChanges.subscribe(value => {
      // If excluded disks is disabled, remove the disks FormArray, if enabled,
      // add it again.
      if (value) {
        this.excludeDisksFormGroup.addControl(
          'disks', new UntypedFormArray([], Validators.required)
        );
      } else {
        this.excludeDisksFormGroup.removeControl('disks');
      }
    });
  }
}
