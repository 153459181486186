<cog-card-section-glancebar>
  <ul class="stat-list stat-list-rel-height" *ngIf="summaryData?.totalViews > 0">
    <li class="stat-group total-stats">
      <cog-statlist valueSize="md" [titleTemplate]="totalTitle">
        <ng-template #totalTitle>
          {{ 'total' | translate }}
        </ng-template>
        <cog-statlist-item [label]="'views' | translate" cogDataId="total-views-val">
          {{ summaryData.totalViews | number }}
        </cog-statlist-item>
      </cog-statlist>
    </li>
    <li class="stat-group protection-stats">
      <cog-statlist valueSize="md" [titleTemplate]="protectedTitle">
        <ng-template #protectedTitle>
          {{ 'protected' | translate }}
        </ng-template>
        <cog-statlist-item [label]="'takingSnapshots' | translate" cogDataId="snapshots-val">
          {{summaryData.protection?.takingSnapshots | number}}
        </cog-statlist-item>
        <cog-statlist-item [label]="'replicatingOut' | translate" cogDataId="replicating-out-val">
          {{summaryData.protection?.replicatingOut | number}}
        </cog-statlist-item>
        <cog-statlist-item [label]="'replicatingIn' | translate" cogDataId="replicating-in-val">
          {{summaryData.protection?.replicatingIn | number}}
        </cog-statlist-item>
      </cog-statlist>
    </li>
    <li class="stat-group consumption-stats">
      <cog-statlist valueSize="md" [titleTemplate]="consumptionTitle">
        <ng-template #consumptionTitle>
          <div class="info flex-row vertical-align margin-bottom-sm">
            {{ 'consumption' | translate }}
            <cog-icon shape="info"
              class="margin-left-xs"
              size="sm"
              [matTooltip]="'dashboard.fileServices.consumption.dataUpdate.tooltip' | translate"
              matTooltipPosition="right">
            </cog-icon>
          </div>
        </ng-template>
        <cog-statlist-item [label]="'logical' | translate"
          [matTooltip]="'dashboard.fileServices.consumption.logical.tooltip' | translate"
          matTooltipPosition="above"
           cogDataId="logical-val">
          <cog-byte-size [bytes]="summaryData.consumption?.logical" precision="2"></cog-byte-size>
        </cog-statlist-item>
        <cog-statlist-item [label]="'dataReduction' | translate"
          [matTooltip]="'dashboard.fileServices.consumption.dataReduction.tooltip' | translate"
          matTooltipPosition="above"
          cogDataId="data-reduction-val">
          {{summaryData.consumption?.dataReduction | number : '1.2-2'}}
          <span><small>x</small></span>
        </cog-statlist-item>
        <cog-statlist-item [label]="'physical' | translate"
          [matTooltip]="'dashboard.fileServices.consumption.physical.tooltip' | translate"
          matTooltipPosition="above"
          cogDataId="physical-val">
          <cog-byte-size [bytes]="summaryData.consumption?.physical" precision="2"></cog-byte-size>
        </cog-statlist-item>
        <cog-statlist-item [label]="'resiliencyImpact' | translate"
          [matTooltip]="'dashboard.fileServices.consumption.resiliencyImpact.tooltip' | translate"
          matTooltipPosition="above"
          cogDataId="resiliency-val">
          <cog-byte-size [bytes]="summaryData.consumption?.resiliencyImpact" precision="2"></cog-byte-size>
        </cog-statlist-item>
        <cog-statlist-item [label]="'storageConsumed' | translate"
          [matTooltip]="'dashboard.fileServices.consumption.storageConsumed.tooltip' | translate"
          matTooltipPosition="above"
          cogDataId="consumed-val">
          <cog-byte-size [bytes]="summaryData.consumption?.storageConsumed" precision="2"></cog-byte-size>
        </cog-statlist-item>
      </cog-statlist>
    </li>
  </ul>
</cog-card-section-glancebar>
