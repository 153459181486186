import { Injectable } from '@angular/core';
import { flagEnabled, IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { StateService } from '@uirouter/core';
import { StateManagementService } from 'src/app/core/services';
import { RestoreConfigService } from 'src/app/modules/restore/restore-shared';
import { Environment, RecoveryAction } from 'src/app/shared/constants';

import { ObjectActionCreator } from './object-action-creator';
import { ObjectActionProvider } from './object-action-provider';
import { ObjectInfoService } from './object-info.service';
import { ProtectedObject } from '@cohesity/api/v2';

@Injectable()
export class HyperVObjectActionProvider extends ObjectActionProvider {
  /**
   * The provider for this service is manually set up in object-actions-menu.service, which must provide
   * the list of providers as an array in the correct order. In order to maintain some kind of sanity,
   * the providers are listed here, they should match the order of the constructor args.
   */
  static hyperVObjectActionProviderDependencies = [
    ObjectInfoService,
    RestoreConfigService,
    StateManagementService,
    StateService,
    ObjectActionCreator,
    IrisContextService,
  ];

  exclusiveProtection = false;

  supportsBulkActions = true;

  constructor(
    readonly objectStore: ObjectInfoService,
    readonly restoreConfig: RestoreConfigService,
    readonly stateManagementService: StateManagementService,
    readonly stateService: StateService,
    readonly actionCreator: ObjectActionCreator,
    readonly irisContextService: IrisContextService,
  ) {
    super(objectStore, restoreConfig, stateManagementService, stateService, actionCreator, irisContextService);
  }

  /**
   * Returns whether current user is in DMaaS scope.
   */
  get isDmsContext(): boolean {
    return isDmsScope(this.irisContextService.irisContext);
  }

  /**
   * Checks a protected object and recovery action against the available restore configuration
   * to determine if the provided action is applicable.
   *
   * @param object The protected object
   * @param simpleObject The simple object
   * @param actionType A recovery action type
   * @returns True if the action can be applied to this object.
   */
  canRecover(
    actionType: RecoveryAction,
    object?: ProtectedObject,
  ): boolean {
    const config = this.restoreConfig.recoveryFormOptions[Environment.kHyperV];

    // Hiding Recover VM option on DMaas if feature flag hyperVStreamCopyRecovery is false
    const hideRecoverVMOption = this.isDmsContext && actionType === RecoveryAction.RecoverVMs &&
      !flagEnabled(this.irisContextService.irisContext, 'hyperVStreamCopyRecovery');

    return object?.latestSnapshotsInfo && config && !!config[actionType] && !hideRecoverVMOption;
  }
}
