import { Injectable } from '@angular/core';
import { IrisContextService, isSmartFilesScope } from '@cohesity/iris-core';
import { Transition } from '@uirouter/core';
import { GuardPriority, GuardResult, StateGuard } from 'src/app/core/state/state-guard';

/**
 * The set of SmartFiles states.
 */
const appStates = new Set([
  'helios-smartfiles-dashboard',
  'software-upgrade.cluster-list',
  'reporting',
]);

/**
 * State guard to ensure the iris service context is set to SmartFiles for SmartFiles routes.
 */
@Injectable({ providedIn: 'root' })
export class SmartFilesGuard implements StateGuard {
  /**
   * Run this at app priority
   */
  guardPriority = GuardPriority.App;

  constructor(private irisContextService: IrisContextService) { }

  /**
   * Run the SmartFiles context guard.
   *
   * @param transition ui router transition
   * @returns allow SmartFiles route, otherwise redirect with serviceType to switch to
   * @returns Allow SmartFiles route when the serviceType is set to SmartFiles
   * else initiate a switch to SmartFiles.
   */
  onStart(transition: Transition): GuardResult {
    const toState = transition.to();
    const toParams = transition.params();
    const isSmartFilesState = appStates.has(toState.name);
    const hasSmartFilesServiceType = toParams.serviceType === 'smartFiles';
    const smartFilesScope = isSmartFilesScope(this.irisContextService.irisContext);

    if (isSmartFilesState && smartFilesScope && !hasSmartFilesServiceType) {
      return transition.targetState().withParams({ serviceType: 'smartFiles' });
    }
  }
}
