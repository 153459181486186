<mat-form-field>
  <mat-label>{{ label }}</mat-label>

  <input
    type="text"
    matInput
    cogDataId="search-input"
    [inheritDataId]="true"
    [formControl]="searchInput"
    [matAutocomplete]="auto">

  <mat-progress-bar class="search-progress-bar" mode="indeterminate" *ngIf="searching$ | async"></mat-progress-bar>

  <mat-autocomplete
    #auto="matAutocomplete"
    autoActiveFirstOption
    cogDataId="autocomplete"
    [inheritDataId]="true"
    [displayWith]="displayWithFn">
    <mat-option
      *ngFor="let option of locations$ | async"
      cogDataId="option-{{ option?.city }}"
      [value]="option"
      [inheritDataId]="true">
      <div class="location-option">
        <span class="location-city">{{ option?.city }}</span>
        <span class="location-state-country">{{ option?.state }}, {{ option?.country }}</span>
      </div>
    </mat-option>
  </mat-autocomplete>

  <mat-error *ngIf="searchInput.errors?.required">
    {{ 'errors.required' | translate }}
  </mat-error>

  <mat-error *ngIf="searchInput.errors?.invalid">
    {{ 'errors.invalid' | translate }}
  </mat-error>
</mat-form-field>
