import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  InjectionToken,
  Injector,
  NgZone,
  Type,
} from '@angular/core';
import { GeneralPath, INode, IRenderContext, NodeStyleBase, SvgVisual } from 'yfiles';

/**
 * An injection token that contains the node rendered by this style instance
 */
export const NODE_INSTANCE = new InjectionToken<INode>('node-style-node-instance');

/**
 * An injection token that includes the node's render context. This can be used to determine the current zoom
 * level.
 */
export const NODE_RENDER_CONTEXT = new InjectionToken<IRenderContext>('node-style-node-render-context');

/**
 * Supported shape options for the node. This Needs to match the shape that the Angular component draws (ie circle or
 * square) so that edges will be drawn up to the rendered boundaries of the node.
 */
export type NodeComponentShape = 'rect' | 'ellipse';

/**
 * Yfiles uses "styles" to control how individual nodes, edges, or containers are rendered. This implementation is
 * designed to adapt the yfiles style to an angular component.
 */
export class NodeComponentStyle<T extends Type<T>> extends NodeStyleBase {
  /**
   * The angular component ref
   */
  private compRef: ComponentRef<T>;

  constructor(
    private readonly injector: Injector,
    private componentType: Type<T>,
    private resolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private zone: NgZone,
    readonly componentShape: NodeComponentShape
  ) {
    super();
  }

  /**
   * Gets the outline of the node, so that edges can be drawn properly
   *
   * @param node The node
   * @returns the node outline
   */
  getOutline(node: INode): GeneralPath | null {
    if (this.componentShape === 'ellipse') {
      const outline = new GeneralPath();
      outline.appendEllipse(node.layout, false);
      return outline;
    }
    return super.getOutline(node);
  }

  /**
   * creates the svg, and the angular component
   *
   * @param renderContext The render context
   * @param node the current node
   * @returns The new node svg
   */
  createVisual(renderContext: IRenderContext, node: INode): SvgVisual {
    const componentInjector = Injector.create({
      providers: [
        {
          provide: NODE_INSTANCE,
          useValue: node,
        },
        {
          provide: NODE_RENDER_CONTEXT,
          useValue: renderContext,
        },
      ],
      parent: this.injector,
    });

    // Create an svg group  at the node's layout position
    const g = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    g.setAttribute('transform', 'translate(' + node.layout.x + ' ' + node.layout.y + ')');

    // Retrieve the factory for the component type and create a new instance. Then add it to the svg
    const componentFactory = this.resolver.resolveComponentFactory(this.componentType);
    this.compRef = componentFactory.create(componentInjector, undefined, g);
    this.appRef.attachView(this.compRef.hostView);

    // Assign the NodeComponent's item input property
    // Not sure if we need this?
    // (g as any)['data-compRef'] = this.compRef ;

    const svgVisual = new SvgVisual(g);
    renderContext.setDisposeCallback(svgVisual, (context: IRenderContext) => {
      // need to clean up after the visual is actually removed
      const listener = () => {
        if (this.compRef) {
          this.appRef.detachView(this.compRef.hostView);
          if ((this.compRef.instance as any).ngOnDestroy){
            (this.compRef.instance as any).ngOnDestroy();
          }
        }
        context.canvasComponent?.removeUpdatedVisualListener(listener);
      };
      context.canvasComponent?.addUpdatedVisualListener(listener);
      return null;
    });
    return svgVisual;
  }

  /**
   * Updates the node's SVG whenever its position changes. This can either be because it was moved, or it was
   * removed from the screen
   *
   * @param renderContext The render context
   * @param oldVisual The previously created visual
   * @param node The current node
   * @returns The updated svg
   */
  updateVisual(renderContext: IRenderContext, oldVisual: SvgVisual, node: INode): SvgVisual {
    if (oldVisual && oldVisual.svgElement) {
      const g = oldVisual.svgElement;
      g.setAttribute('transform', 'translate(' + node.layout.x + ' ' + node.layout.y + ')');
      if (this.compRef) {
        this.appRef.attachView(this.compRef.hostView);
        this.compRef.changeDetectorRef.detectChanges();
      }

      // TODO: See if the above is sufficient to get the updated zoom context, otherwise rethink the strategy of
      // using injection tokens on the node and switch to using @Inputs
      // this.zone.run(() => {
      //   // run inside the zone so Angular will update the NodeComponent
      //   (g as any)['data-compRef'].instance.zoom = renderContext.zoom;
      // });
      return oldVisual;
    }
    return this.createVisual(renderContext, node);
  }
}
