<div class="advanced-search-row">
  <div class="advanced-search-row search-box"
    [class.search-view]="searchView"
    [class.status-success]="isStatusSuccess"
    [class.status-critical]="isStatusCritical">
    <cog-icon
      class="search-indicator-icon"
      *ngIf="searchView"
      shape="helix:search"
      size="lg"
      [ariaLabel]="'search'">
    </cog-icon>
    <textarea #searchInput
      *ngIf="useTextarea"
      matInput
      type="text"
      role="search"
      class="search-wrapper-input"
      [inheritDataId]="true"
      cogDataId="log-search-input"
      [attr.aria-label]="'search'"
      [matAutocomplete]="auto"
      [formControl]="searchCtrl"
      [placeholder]="(inputFocused ? typingPlaceholder : null) || placeholder"
      (focus)="handleFocus()"
      (blur)="inputFocused = false"
      (keydown)="onInputKeyDown($event)"
      (click)="updateCursorLocation()"
      (keyup)="updateCursorLocation()"></textarea>

    <input #searchInput
      *ngIf="!useTextarea"
      type="text"
      matInput
      role="search"
      class="search-wrapper-input"
      [inheritDataId]="true"
      cogDataId="log-search-input"
      [attr.aria-label]="'search'"
      [matAutocomplete]="auto"
      [formControl]="searchCtrl"
      [placeholder]="(inputFocused ? typingPlaceholder : null) || placeholder"
      (focus)="handleFocus()"
      (blur)="inputFocused = false"
      (keydown)="onInputKeyDown($event)"
      (click)="updateCursorLocation()"
      (keyup)="updateCursorLocation()">

    <div class="search-box-actions">
      <div class="search-box-action" *ngIf="searchView && searchQuery">
        <cog-icon
          shape="close"
          size="lg"
          [ariaLabel]="'Clear'"
          (click)="clearSearch()">
        </cog-icon>
      </div>
      <div class="search-box-action" *ngIf="helpLink">
        <cog-icon
          [matTooltip]="helpLinkTooltip"
          [matTooltipDisabled]="!helpLinkTooltip"
          shape="help_outline"
          size="lg"
          [ariaLabel]="'info'"
          (click)="openHelpLink()">
        </cog-icon>
      </div>
    </div>
  </div>

  <button mat-flat-button cogSubmitButton *ngIf="searchView" (click)="triggerSearchOnEnter()">
    {{ searchLabel }}
  </button>
</div>

<mat-autocomplete
  #auto="matAutocomplete"
  [autoActiveFirstOption]="false"
  class="cog-advanced-search"
  (optionSelected)="fixCursorLocation()">
  <mat-optgroup *ngIf="suggestion?.resultValues?.length" [label]="suggestedValuesLabel">
    <ng-container *ngIf="!isLoadingSuggestion">
      <mat-option *ngFor="let result of suggestion?.resultValues" [value]="result.modelValue || result.value">
        <ng-container *ngTemplateOutlet="resultTemplate; context: { result: result }"></ng-container>
      </mat-option>
    </ng-container>

    <mat-option *ngIf="isLoadingSuggestion">
      <cog-spinner size="sm"></cog-spinner>
    </mat-option>
  </mat-optgroup>

  <mat-optgroup *ngIf="suggestion?.results?.length" [label]="valueFormatsLabel">
    <mat-option *ngFor="let result of suggestion?.results" [value]="result.modelValue || result.value">
      <ng-container *ngTemplateOutlet="resultTemplate; context: { result: result }"></ng-container>
    </mat-option>
  </mat-optgroup>
  <mat-option
    *ngIf="footerOption"
    class="sticky-option bottom"
    [value]="searchQuery"
    (click)="handleFoolterOptionClick($event)">
    <ng-container [ngTemplateOutlet]="footerOption.templateRef"></ng-container>
  </mat-option>
</mat-autocomplete>

<ng-template #resultTemplate let-result="result">
  <!-- result label will be present only for suggestion results and will not be present for resultValues -->
  <span *ngIf="result.label && !result.value && result.label !== 'EOF'">
    {{ translationResult(result) }}
  </span>
  <ng-container *ngIf="result.labelParts">
    <strong>{{ result.labelParts.highlighted }}</strong>
    <span>{{ result.labelParts.remaining }}</span>
  </ng-container>
</ng-template>
