<cog-settings-list-item [invalid]="control?.invalid"
  cogFormGroup
  [label]="label"
  [isReadOnly]="readOnly || control?.disabled">
  <cog-settings-list-item-value-display>
    {{ control?.value | boolLabel: trueLabel : falseLabel }}
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor>
    <mat-slide-toggle [formControl]="control"
      [inheritDataId]="true"
      cogDataId="toggle-{{ label }}"
      [disabled]="readOnly">
      {{ toggleLabel }}
    </mat-slide-toggle>
    <ng-content></ng-content>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
