import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RangePipeModule } from '@cohesity/data-govern/shared';
import { BannerModule, IconModule } from '@cohesity/helix';
import { IrisRouterModule } from '@cohesity/iris-core';
import { TranslateModule } from '@ngx-translate/core';

import { ScanRunStatusComponent } from './scan-run-status.component';
import { NaLabelModule } from '@cohesity/utils';

const COMPONENTS = [ScanRunStatusComponent];

@NgModule({
  imports: [
    BannerModule,
    CommonModule,
    IconModule,
    IrisRouterModule,
    NaLabelModule,
    RangePipeModule,
    TranslateModule,
  ],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
})
export class ScanRunStatusModule {}
