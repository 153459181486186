import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { KeyedSelectionModel, CanSelectRowFn } from '@cohesity/helix';
import { Environment } from '@cohesity/iris-shared-constants';
import { AutoDestroyable } from '@cohesity/utils';
import { DialogService } from 'src/app/core/services';

import { RestoreSearchResult } from '../../model/restore-search-result';
import { RecoverVmVmwareProtectionGroupDialogComponent } from 'src/app/modules/restore/hypervisor-shared/vmware/components/recover-vm-vmware-protection-group-dialog/recover-vm-vmware-protection-group-dialog.component';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { ProtectionGroupSearchResult } from '../../model/protection-group-search-result';

/**
 * Displays the results of any restore object search.
 */
@Component({
  selector: 'coh-object-search-results',
  templateUrl: './object-search-results.component.html',
  styleUrls: ['./object-search-results.component.scss'],
})
export class ObjectSearchResultsComponent extends AutoDestroyable implements OnInit {
  /**
   * The search results to display.
   */
  @Input() searchResults: RestoreSearchResult[];

  /**
   * The selection model for the search results. This could be single or multi-select.
   * This uses a keyed selection model and persists the selection between table reloads,
   * so that the selection can be maintained as data is swapped out with each search.
   * This can be null to remove any checkboxes or radio buttons from the form.
   */
  @Input() selection: KeyedSelectionModel<RestoreSearchResult>;

  /**
   * Template to render row details. If no template is specified, a default one will be rendered
   * instead with whatever information can be derived from the ProtectedObject info.
   */
  @Input() detailTemplate: TemplateRef<any>;

  /**
   * List of selected object ids.
   * Any objects in this list should not be selectable.
   */
  @Input() implicitObjectIds: (number|string)[] = [];

  /**
   * To disable interaction with the component.
   */
  @Input() disabled = false;

  /**
   * Flag that when a search returned no results.
   */
  @Input() noData = false;

  // Flag for vmware ui improvement.
  recoveryVmwareUiImprovementEnabled: boolean;

  /**
   * Determine whether a row should be enabled in the selection results. The main case where
   * a row would be disabled is if an object is included in an already-selected protection group
   * or if this component is marked disabled.
   */
  canSelectObject: CanSelectRowFn<RestoreSearchResult> = (object: RestoreSearchResult) => {
    if (this.disabled || !this.selection) {
      return false;
    }

    // The object is already selected, then it can not be selected again. This may happen if the same object is
    // protected by multiple protection groups.
    const alreadySelected = this.selection.selected.some(
      item => item.id === object.id && item.protectionGroupId !== object.protectionGroupId
    );

    // in case of pure, there is only single selection, so no need to disable same object
    // in multiple protection groups.
    if (alreadySelected && ![Environment.kPure, Environment.kIbmFlashSystem].includes(
      object.environment as Environment)) {
      return false;
    }

    // The object isn't explicitly selected, but is included in a list of selected
    // items. Don't allow selecting it again.
    if (this.implicitObjectIds.includes(object.id)) {
      return false;
    }

    // if an object with requiresExclusiveSelection is selected, and it's not this object, return false
    // if this object has requiresExclusiveSelection and there is any selection, return false
    if (this.selection.isSelected(object) || this.selection.isEmpty()) {
      return true;
    }

    if (this.selection.selected[0].requiresExclusiveSelection || object.requiresExclusiveSelection) {
      return false;
    }

    if (this.selection.selected[0].protectionType &&
      this.selection.selected[0].protectionType !== object.protectionType) {

      return false;
    }

    // Default case allows for selection.
    return true;
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private irisCtx: IrisContextService,) {
    super();
  }

  ngOnInit() {
    this.recoveryVmwareUiImprovementEnabled = flagEnabled(this.irisCtx.irisContext, 'recoveryVmwareUiImprovement');
    // Shows dialog to confirm objects in protection group
    // for vmware and if users confirm then only it is selected.
    if (this.selection) {
      this.selection.changed.pipe(this.untilDestroy()).subscribe(() => this.cdr.detectChanges());
      if (this.recoveryVmwareUiImprovementEnabled) {
        this.selection.changed.subscribe(selectionObject => {
          if (selectionObject?.added) {
            selectionObject.added.forEach(selectedObject => {
              if (selectedObject.environment === Environment.kVMware &&
                selectedObject.resultType === ProtectionGroupSearchResult.protectionGroupResultType) {
                  this.dialogService.showDialog(RecoverVmVmwareProtectionGroupDialogComponent, selectedObject)
                    .subscribe((result: boolean) => {
                      if (!result) {
                        this.selection.toggle(selectedObject);
                      }
                    });
              }
            });
          }
        });
      }
    }
  }

  /**
   * Toggles the selection of an item.
   *
   * @param   object   The object to toggle.
   */
  toggleObjectSelection(object: RestoreSearchResult) {
    if (this.selection && this.canSelectObject(object)) {
      this.selection.toggle(object);
    }
  }
}
