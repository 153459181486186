import { sensitivityTagNames } from '@cohesity/shared/tags';
import { CellColorStates } from './cell-colors.constants';

/**
 * An enum for all possible classification statuses that could be displayed in the classification column
 */
export enum ClassificationStatus {
  high = 'high',
  medium = 'medium',
  low = 'low',
  none = 'none',
  noScans = 'noScans',
  unknown = 'unknown',
}

/**
 * A map of classification status to the corresponding display label key
 */
export const classificationStatusLabelKeys: Record<ClassificationStatus, string> = {
  [ClassificationStatus.high]: 'high',
  [ClassificationStatus.medium]: 'medium',
  [ClassificationStatus.low]: 'low',
  [ClassificationStatus.none]: 'none',
  [ClassificationStatus.unknown]: '-',
  [ClassificationStatus.noScans]: 'scanNow',
};

/**
 * A map of classification status to the background cell color
 */
export const classificationStatusColors: Record<ClassificationStatus, CellColorStates> = {
  [ClassificationStatus.high]: CellColorStates.high,
  [ClassificationStatus.medium]: CellColorStates.medium,
  [ClassificationStatus.low]: CellColorStates.low,
  [ClassificationStatus.none]: CellColorStates.ok,
  [ClassificationStatus.unknown]: CellColorStates.unknown,
  [ClassificationStatus.noScans]: CellColorStates.info,
};

/**
 * Mapped tag names for the classifications statuses
 */
export const classificationTagNames: Record<ClassificationStatus, string> = {
  [ClassificationStatus.high]: sensitivityTagNames.high,
  [ClassificationStatus.medium]: sensitivityTagNames.medium,
  [ClassificationStatus.low]: sensitivityTagNames.low,
  [ClassificationStatus.none]: sensitivityTagNames.none,
  [ClassificationStatus.noScans]: '',
  [ClassificationStatus.unknown]: ''
};
