/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UdaConfigParams } from '../models/uda-config-params';
import { UdaConnectorConfigParams } from '../models/uda-connector-config-params';
import { UdaConfigsList } from '../models/uda-configs-list';
@Injectable({
  providedIn: 'root',
})
class UdaConnectorConfigService extends __BaseService {
  static readonly GetConnectorConfigsPath = '/uda/connector-configs';
  static readonly UpdateUdaConnectorConfigPath = '/uda/connector-configs';
  static readonly CreateUdaConnectorConfigPath = '/uda/connector-configs';
  static readonly GetUdaConnectorConfigRawPath = '/uda/connector-configs/raw';
  static readonly GetConnectorConfigsByIdPath = '/uda/connector-configs/{id}';
  static readonly DeleteUdaConnectorConfigPath = '/uda/connector-configs/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get the workflow Parameters for all the sources
   *
   * Get the workflow Parameters for all the sources.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Array of objects with source info and parameters to customize registration, protection and recovery workflows for all the uda sources.
   */
  GetConnectorConfigsResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<Array<UdaConfigParams>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/uda/connector-configs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UdaConfigParams>>;
      })
    );
  }
  /**
   * Get the workflow Parameters for all the sources
   *
   * Get the workflow Parameters for all the sources.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Array of objects with source info and parameters to customize registration, protection and recovery workflows for all the uda sources.
   */
  GetConnectorConfigs(regionId?: string,
    accessClusterId?: number): __Observable<Array<UdaConfigParams>> {
    return this.GetConnectorConfigsResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as Array<UdaConfigParams>)
    );
  }

  /**
   * Update a UDA connector config
   *
   * Updates the config in gandalf via iris proxy.
   * @param params The `UdaConnectorConfigService.UpdateUdaConnectorConfigParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to update the UDA config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  UpdateUdaConnectorConfigResponse(params: UdaConnectorConfigService.UpdateUdaConnectorConfigParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/uda/connector-configs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update a UDA connector config
   *
   * Updates the config in gandalf via iris proxy.
   * @param params The `UdaConnectorConfigService.UpdateUdaConnectorConfigParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to update the UDA config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  UpdateUdaConnectorConfig(params: UdaConnectorConfigService.UpdateUdaConnectorConfigParams): __Observable<null> {
    return this.UpdateUdaConnectorConfigResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Create a new UDA connector config
   *
   * Stores the new config in gandalf via iris proxy.
   * @param params The `UdaConnectorConfigService.CreateUdaConnectorConfigParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a new UDA config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  CreateUdaConnectorConfigResponse(params: UdaConnectorConfigService.CreateUdaConnectorConfigParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/uda/connector-configs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Create a new UDA connector config
   *
   * Stores the new config in gandalf via iris proxy.
   * @param params The `UdaConnectorConfigService.CreateUdaConnectorConfigParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a new UDA config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  CreateUdaConnectorConfig(params: UdaConnectorConfigService.CreateUdaConnectorConfigParams): __Observable<null> {
    return this.CreateUdaConnectorConfigResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get UDA connector config
   *
   * Get the UDA config from gandalf via iris proxy.
   * @param params The `UdaConnectorConfigService.GetUdaConnectorConfigRawParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `id`: Specifies the id of the UDA config.
   *
   * - `filterIndexConfig`: If true, returns index config only as response.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return success.
   */
  GetUdaConnectorConfigRawResponse(params: UdaConnectorConfigService.GetUdaConnectorConfigRawParams): __Observable<__StrictHttpResponse<UdaConfigsList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.filterIndexConfig != null) __params = __params.set('filterIndexConfig', params.filterIndexConfig.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/uda/connector-configs/raw`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UdaConfigsList>;
      })
    );
  }
  /**
   * Get UDA connector config
   *
   * Get the UDA config from gandalf via iris proxy.
   * @param params The `UdaConnectorConfigService.GetUdaConnectorConfigRawParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `id`: Specifies the id of the UDA config.
   *
   * - `filterIndexConfig`: If true, returns index config only as response.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return success.
   */
  GetUdaConnectorConfigRaw(params: UdaConnectorConfigService.GetUdaConnectorConfigRawParams): __Observable<UdaConfigsList> {
    return this.GetUdaConnectorConfigRawResponse(params).pipe(
      __map(_r => _r.body as UdaConfigsList)
    );
  }

  /**
   * Get the Parameters of a source based on id
   *
   * Get the Parameters of a source based on id.
   * @param params The `UdaConnectorConfigService.GetConnectorConfigsByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Uda Source.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Object with source info and parameters to customize registration, protection and recovery workflows for the given source id.
   */
  GetConnectorConfigsByIdResponse(params: UdaConnectorConfigService.GetConnectorConfigsByIdParams): __Observable<__StrictHttpResponse<UdaConfigParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/uda/connector-configs/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UdaConfigParams>;
      })
    );
  }
  /**
   * Get the Parameters of a source based on id
   *
   * Get the Parameters of a source based on id.
   * @param params The `UdaConnectorConfigService.GetConnectorConfigsByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Uda Source.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Object with source info and parameters to customize registration, protection and recovery workflows for the given source id.
   */
  GetConnectorConfigsById(params: UdaConnectorConfigService.GetConnectorConfigsByIdParams): __Observable<UdaConfigParams> {
    return this.GetConnectorConfigsByIdResponse(params).pipe(
      __map(_r => _r.body as UdaConfigParams)
    );
  }

  /**
   * Delete a UDA connector config
   *
   * Delete the config in gandalf via iris proxy.
   * @param params The `UdaConnectorConfigService.DeleteUdaConnectorConfigParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the UDA
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteUdaConnectorConfigResponse(params: UdaConnectorConfigService.DeleteUdaConnectorConfigParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/uda/connector-configs/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a UDA connector config
   *
   * Delete the config in gandalf via iris proxy.
   * @param params The `UdaConnectorConfigService.DeleteUdaConnectorConfigParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the UDA
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteUdaConnectorConfig(params: UdaConnectorConfigService.DeleteUdaConnectorConfigParams): __Observable<null> {
    return this.DeleteUdaConnectorConfigResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module UdaConnectorConfigService {

  /**
   * Parameters for UpdateUdaConnectorConfig
   */
  export interface UpdateUdaConnectorConfigParams {

    /**
     * Specifies the parameters to update the UDA config.
     */
    body: UdaConnectorConfigParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateUdaConnectorConfig
   */
  export interface CreateUdaConnectorConfigParams {

    /**
     * Specifies the parameters to create a new UDA config.
     */
    body: UdaConnectorConfigParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetUdaConnectorConfigRaw
   */
  export interface GetUdaConnectorConfigRawParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the id of the UDA config.
     */
    id?: number;

    /**
     * If true, returns index config only as response.
     */
    filterIndexConfig?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetConnectorConfigsById
   */
  export interface GetConnectorConfigsByIdParams {

    /**
     * Specifies the id of the Uda Source.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteUdaConnectorConfig
   */
  export interface DeleteUdaConnectorConfigParams {

    /**
     * Specifies the id of the UDA
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { UdaConnectorConfigService }
