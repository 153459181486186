import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { CustomChartInput, Dimensions, Measurements, ReportItemFilter } from '../../../iris-reporting.model';
import { BaseReportItemRendererComponent } from '../base-report-item-renderer.component';
import { ChartType, CustomChartConfig } from '../chart-config-form/chart-config-form.component';

/**
 * This report item renders a chart and allows users to customize its details.
 */
@Component({
  selector: 'iris-rpt-chart-item',
  templateUrl: './chart-item.component.html',
  styleUrls: ['./chart-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartItemComponent extends BaseReportItemRendererComponent implements OnChanges, CustomChartInput {
  /**
   * Initial measurement values.
   */
  @Input() measurements: Measurements;

  /**
   * Initial dimension values.
   */
  @Input() dimensions: Dimensions;

  /**
   * The initial chart type.
   */
  @Input() chartType: ChartType;

  /**
   * whether to show percent with number in legend or not. showing percent data along with the data.
   */
  @Input() showPercentInLegend = false;

  /**
   * for pie legend is not clickable. To enable we are using this.
   */
  @Input() isLegendClickable = false;

  /**
   * Emit sort event when user changes sort.
   */
  @Output() itemFilterChange = new EventEmitter<ReportItemFilter>();

  /**
   * Edit template that the report item can use to render edit controls for this component.
   */
  @ViewChild('editTemplate', { static: true }) editTemplate: TemplateRef<any>;

  /**
   * The chart config option that can be passed to the form.
   */
  chartConfig: CustomChartConfig = null;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnChanges() {
    this.chartConfig = {
      measurements: this.measurements,
      dimensions: this.dimensions,
      chartType: this.chartType,
    };
  }

  /**
   * Update the chart config and emit a change event.
   *
   * @param config The new chart config
   */
  onConfigChange(config: CustomChartConfig) {
    this.chartConfig = config;
    this.itemEdited.emit(config);
    this.cdr.detectChanges();
  }

  /**
   * Handles filter change including filter, sort.
   *
   * @param event  filter change event.
   */
  onFilterChange(event: ReportItemFilter) {
    this.itemFilterChange.emit(event);
  }
}
