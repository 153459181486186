import { ObjectSnapshot } from '@cohesity/api/v2';
import { RecoveryOsType } from 'src/app/shared';

import { RestorePointSelection } from '../model/restore-point-selection';

/**
 * Possible snapshot types. A fortknox snapshot would have an archival target type, but fortknox ownershipt context.
 */
export type SnapshotType = 'Local' | 'Archival' | 'FortKnox' | 'StorageArraySnapshot';

/**
 * Type for an object where the key is a snapshot target type, and value is of a
 * generic passed type.
 */
export type SnapshotTargetTypeRecord<T> = Partial<Record<SnapshotType, T>>;

/**
 * Function to get a restore point selection from an object snapshot.
 *
 * @param snapshot The object snapshot.
 * @param sourceName The source name of the object.
 *                   Without this, some abbreviated may not work.
 * @param additionalOptions Additional options that can be passed to construct the object info.
 *                          Currently supports osType and objectType
 * @return The restore point selection object based on the snapshot provided.
 */
export function getRestorePointSelectionFromSnapshot(
  snapshot: ObjectSnapshot,
  sourceName: string,
  additionalOptions: {
    osType?: RecoveryOsType;
    objectType?: string;
    environment?: string;
  } = {}
): RestorePointSelection {
  if (!snapshot) {
    return;
  }

  const value: RestorePointSelection = {
    objectInfo: {
      environment: (additionalOptions?.environment || snapshot.environment),
      id: snapshot.objectId,
      name: snapshot.objectName,
      regionId: snapshot.regionId,
      accessClusterId: snapshot.clusterId,
      recoveryOsType: additionalOptions?.osType,
      protectionGroupId: snapshot.protectionGroupId,
      protectionGroupName: snapshot.protectionGroupName,
      resultType: 'object',
      sourceId: snapshot.sourceId,
      objectType: additionalOptions?.objectType,
      parentSourceName: sourceName,
      storageDomainId: snapshot.storageDomainId,
      sourceInfo: {
        id: snapshot.sourceId,
        name: sourceName,
        environment: (additionalOptions?.environment || snapshot.environment) as any,
      },
      sourceEnvironment: snapshot.environment,
    },
    objectIds: [snapshot.objectId],
    snapshot,
    restorePointId: snapshot.id,
    timestampUsecs: snapshot.snapshotTimestampUsecs,
    isPointInTime: snapshot.runType === 'kHydrateCDP',
    snapshotRunType: snapshot.runType,
  };

  if (snapshot.externalTargetInfo) {
    value.archiveTargetInfo = {...snapshot.externalTargetInfo};
  }

  return value;
}
