// Controller: Storage by Servers

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.reports')
    .controller('reportsStorageVmsController', reportsStorageVmsControllerFn);

  function reportsStorageVmsControllerFn($scope, $filter, $translate, $q,
    ReportsService, evalAJAX, cUtils, SourceService, cReportsControlsUtil,
    ReportsUtil) {

    var chartMaxJobsToDisplay = 20;

    $scope.SourceService = SourceService;

    $scope.storageVMsConfig =
      ReportsUtil.getReportConfig('kStorageConsumedByServersReport')();

    $scope.getData = getData;
    $scope.downloadReport = downloadReport;

    $scope.dataReady = false;
    $scope.vms = [];
    $scope.nDaysLabel = 7;
    $scope.itemsPerPage = 20;

    /**
     * init function for initial report and listeners setup
     */
    function init() {
      var defaultFilters;

      defaultFilters = cReportsControlsUtil.getDefaultFilters({
        lastNDays: 7,
        viewBox: undefined,
        registeredSourceIds: [],
      });

      getData(defaultFilters);
    }

    /**
     * the byteSize filter does not support < 0 values and -1 is specific
     * to this report so we will manually translate if needed
     *
     * If the physicalSizeBytesOnPrimary is set to -1 it means it is undefined,
     * we can't use undefined because it throws of the st-sort functionality
     * in our table, so we translate -1 to "n/a" after the table has been
     * sorted
     *
     * @param    {Number}   value
     */
    $scope.displayPhysicalSizeBytesOnPrimary =
      function displayPhysicalSizeBytesOnPrimary(value) {
        if (value === -1) {
          return $translate.instant('naNotApplicable');
        }

        return $filter('byteSize')(value);
      };

    /**
     * builds a params object based on current filter settings
     *
     * @param      {object}    filters    filters from c-reports-controls
     *
     * @return {Object} params object for API submission
     */
    function getParams(filters) {
      var params = {
        msecsBeforeEndTime: (filters.lastNDays * 86400000),
        sourceIds: filters.registeredSourceIds || [],
        jobIds: filters.jobId,
      };

      if (filters.viewBox && filters.viewBox.viewBoxId) {
        params.viewBoxIds = [filters.viewBox.viewBoxId];
      }

      return params;
    }


    /**
     * requests a csv download via ReportsService
     */
    function downloadReport() {
      var params = getParams(cReportsControlsUtil.filters);
      params.outputFormat = 'csv';

      ReportsService.getStorageByVM(params);
    }

    /**
     * Gets and parses the report data from the API
     *
     * @method   getData
     * @param    {object}   filters   filters from c-reports-controls
     */
    function getData(filters) {
      $scope.dataReady = false;
      $scope.nDaysLabel = filters.lastNDays;
      ReportsService.getStorageByVM(getParams(filters)).then(
        function getStorageByVmSuccess(response) {
          $scope.vms = response.map(function mapVms(vm) {
            var dataPointsLastIndex;

            // if this property is undefined we need to set it to -1
            // so the table can be sorted correctly
            if (vm.physicalSizeBytesOnPrimary === undefined) {
              vm.physicalSizeBytesOnPrimary = -1;
            }

            if (vm.dataPoints && vm.dataPoints.length) {
              dataPointsLastIndex = vm.dataPoints.length - 1;
              // calculate and store some values on the object so they can be sorted on
              vm._snapshotTimeUsecs = vm.dataPoints[dataPointsLastIndex].snapshotTimeUsecs;
              vm._logicalSizeBytes = vm.dataPoints[dataPointsLastIndex].logicalSizeBytes;
            }

            if (vm.varPhysicalOnPrimaryPct !== undefined &&
              vm.varPhysicalOnPrimaryPct !== null) {
              vm._physicalVariance =
                cUtils.round(vm.varPhysicalOnPrimaryPct, 2) + '%';
            }

            return vm;
          });

          buildDataChart(angular.copy(response));
        }, evalAJAX.errorMessage)
      .finally(function getStorageByVmFinally() {
        $scope.dataReady = true;
      });
    }

    /**
     * Build data objects for VMs by size chart
     *
     * @param  {Object} data
     * @return {Void}
     */
    function buildDataChart(data) {
      var bytesData = [];
      var bytesCategories = [];
      var sizeUnit;

      var processedData = $filter('filter')(data,
        function filterData(value, index) {
          return value.hasOwnProperty('physicalSizeBytesOnPrimary');
        });

      function compare(a, b) {
        if (a.name < b.name) {
          return -1;
        }

        if (a.name > b.name) {
          return 1;
        }

        return 0;
      }

      for (var i = 1; i < processedData.length;) {
        processedData.sort(compare);

        if (processedData[i - 1].name === processedData[i].name) {
          processedData[i - 1].physicalSizeBytesOnPrimary =
            (processedData[i - 1].physicalSizeBytesOnPrimary + processedData[i].physicalSizeBytesOnPrimary);

          processedData.splice(i, 1);
        } else {
          i++;
        }
      }

      processedData = $filter('orderBy')(processedData, 'physicalSizeBytesOnPrimary', true);
      processedData = $filter('limitTo')(processedData, chartMaxJobsToDisplay);

      $scope.topVMsStorageChart.categories = [];

      angular.forEach(processedData, function(object, index) {
      // find the units for the largest job and use it for our yAxis labels and conversions
        if (index === 0) {
          sizeUnit = cUtils.bytesToSize(object.physicalSizeBytesOnPrimary).unit;
        }

        bytesData.push([
          object.name,
          object.physicalSizeBytesOnPrimary >= 0 ?
            cUtils.bytesToUnit(object.physicalSizeBytesOnPrimary, sizeUnit) :
            $translate.instant('naNotApplicable')
        ]);

        bytesCategories.push(object.name);
      });

      // update chart labels to match scale/unit of largest item
      $scope.topVMsStorageChart.yAxis.labels.format = '{value} ' + sizeUnit;
      $scope.topVMsStorageChart.tooltip.pointFormat = '<b>{point.y} ' + sizeUnit + '</b>';

      $scope.topVMsStorageChart.series[0].data = bytesData;
      $scope.topVMsStorageChart.xAxis.categories = bytesCategories;
    }

    // Config object for topVMsStorageChart
    $scope.topVMsStorageChart = {
      chartType: 'basic',
      loading: false,
      series: [{
        name: 'MB',
        data: []
      }],
      chart: {
        height: 300
      },
      yAxis: {
        labels: {
          format: '{value} MB'
        },
        allowDecimals: false,
        title: {
          text: null
        }
      },
      tooltip: {
        pointFormat: '<b>{point.y} MB</b>'
      },
      xAxis: {
        categories: [],
        labels: {
          rotation: -45,
          style: {
            whiteSpace: "nowrap"
          },
          formatter: function() {
            // do truncation here and return string
            if (this.value.length > 15) {
              return this.value.slice(0, 15) + '...';
            } else {
              return this.value;
            }
          }
        }
      }
    };

    // run setup function on load
    init();
  }
})(angular);
