import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { ProtectionGroup, ProtectionGroupServiceApi } from '@cohesity/api/v2';
import { AutoDestroyable } from '@cohesity/utils';
import { Controls } from 'ngx-sub-form';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProtectionGroupOptions, ProtectionGroupValue } from 'src/app/modules/protection/protection-shared';
import { EnvironmentType } from 'src/app/shared';

@Component({
  selector: 'coh-select-protection-group',
  templateUrl: './select-protection-group.component.html',
  styleUrls: ['./select-protection-group.component.scss'],
})
export class SelectProtectionGroupComponent extends AutoDestroyable implements OnInit {

  /**
   * Environment type to filter protection groups list
   */
  @Input() environment: EnvironmentType;

  /**
   * Default protection group value to initialize values for the component
   */
  @Input() groupValue: ProtectionGroupValue;

  /**
   * Indicates that the fields be disabled and the component be used in a
   * read-only mode.
   */
  @Input() readOnly = false;

  /**
   * Expose ProtectionGroupOptions enum for the view
   */
  protectionGroupOptions = ProtectionGroupOptions;

   /**
    * List of Protection Groups returned from the API.
    */
  groups: ProtectionGroup[];

  /**
   * List of Protection Groups filtered by search term to display in the dropdown.
   */
  filteredGroups: ProtectionGroup[];

  /**
   * Control for filtering the groups list.
   */
  groupsFilterControl = new FormControl();

  /**
   * Emits the component value to the parent component.
   */
  @Output() valueChange = new EventEmitter<ProtectionGroupValue>();

  /**
   * Component form group.
   */
  protectionGroupFormGroup: UntypedFormGroup = new UntypedFormGroup({
    type: new UntypedFormControl(ProtectionGroupOptions.new, Validators.required),
    name: new UntypedFormControl(''),
    existingId: new UntypedFormControl(''),
  });

  constructor(private protectionUserServiceApi: ProtectionGroupServiceApi) {
    super();
  }

  /**
   * Getter for groupType Form Control.
   */
  get groupTypeFormControl(): UntypedFormControl {
    return this.protectionGroupFormGroup.get('type') as UntypedFormControl;
  }

  /**
   * Getter for name Form Control.
   */
  get nameFormControl(): UntypedFormControl {
    return this.protectionGroupFormGroup.get('name') as UntypedFormControl;
  }

  /**
   * Getter for existing group Form Control.
   */
  get existingGroupFormControl(): UntypedFormControl {
    return this.protectionGroupFormGroup.get('existingId') as UntypedFormControl;
  }

  ngOnInit() {
    // Fetch a new set of groups whenever the environment changes.
    this.getExistingGroups(this.environment).subscribe(groups => {
      this.groups = groups;
      this.filteredGroups = groups;
    });

    // Filter the groups list based on the search term.
    this.groupsFilterControl.valueChanges.subscribe((val: any) => {
      const name = (val?.name ?? val)?.trim()?.toLowerCase() || '';
      this.filteredGroups = this.groups.filter(group => group.name.toLowerCase().includes(name));
      // If the search term does not match any group, set an error.
      if (!name) {
        this.groupsFilterControl.setErrors({ required: true });
      } else if (this.filteredGroups.length === 0) {
        this.groupsFilterControl.setErrors({ invalidOption: true });
      } else {
        this.groupsFilterControl.setErrors(null);
      }
    });

    // Initialize the component with default values if available.
    if (this.groupValue) {
      this.groupTypeFormControl.setValue(this.groupValue.type);
      if (this.groupValue.name) {
        this.nameFormControl.setValue(this.groupValue.name);
      } else if (this.groupValue.existingId) {
        this.existingGroupFormControl.setValue(this.groupValue.existingId);
      }
    }

    if (this.readOnly) {
      this.groupTypeFormControl.disable();
      this.nameFormControl.disable();
      this.existingGroupFormControl.disable();
    }

    // Emits the values that are changed at any time.
    this.protectionGroupFormGroup.valueChanges
      .pipe(this.untilDestroy())
      .subscribe(value => {
        this.valueChange.emit(value);
      });
  }

  /**
   * Fetches existing groups from the api
   *
   * @param   environment   The environment type to query for.
   * @returns A list of active protection groups matching the selected environment.
   */
  private getExistingGroups(environment: EnvironmentType): Observable<ProtectionGroup[]> {
    return this.protectionUserServiceApi
      .GetProtectionGroups({
        environments: [environment] as any,
        isActive: true,
      })
      .pipe(map(groups => groups.protectionGroups));
  }

  /**
   * Get form controls.
   *
   * @returns   Default value of form.
   */
  protected getFormControls(): Controls<ProtectionGroupValue> {
    return {
      type: new UntypedFormControl(null),
      name: new UntypedFormControl(null),
      existingId: new UntypedFormControl(null),
    };
  }

  /**
   * Get default values of the form.
   *
   * @returns   Default value of form.
   */
  getDefaultValues(): Partial<ProtectionGroupValue> {
    return {
      type: ProtectionGroupOptions.new,
      name: '',
    };
  }


  /**
   * Handles the selection of a protection group from the dropdown.
   *
   * @param event MatLegacyAutocompleteSelectedEvent
   */
  onSelectionChange(event: MatLegacyAutocompleteSelectedEvent) {
    this.protectionGroupFormGroup.get('existingId').setValue(event.option.value.id);
  }

  /**
   * Display function for the protection group dropdown.
   *
   * @param group ProtectionGroup
   * @returns string The name of the protection group.
   */
  displayWithFn(group: ProtectionGroup): string {
    return group?.name;
  }
}
