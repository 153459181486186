import { Injectable } from '@angular/core';
import { NavItem, updateNavBaseHref } from '@cohesity/helix';
import { AppName, AppType, IrisContextService, PillarType, appConfigMap, translateAppName } from '@cohesity/iris-core';
import { getNavList } from '@cohesity/sizer-libs/shared';
import { TranslateService } from '@ngx-translate/core';

import { ServiceNavProvider } from '../service-nav-provider';

/**
 * This Service configures the Security Center view navigation menu with iris.
 */
@Injectable({ providedIn: 'root' })
export class PlatformInsightsNavService implements ServiceNavProvider {
  pillar: PillarType = 'insights';

  app: AppType = 'piaas';

  private appName: AppName = 'platformInsights';

  private appConfig = appConfigMap[this.appName];

  get serviceNavTitle(): string {
    return translateAppName(this.translate)(this.appName);
  }

  showNavBranding = true;

  serviceNavTitleIcon = this.appConfig.icon;

  serviceNavTitleClass = 'platformInsights';

  getNavItems(): NavItem[] {
    return updateNavBaseHref(getNavList(this.translate, this.irisContext.irisContext), appConfigMap['sizer'].urlSegment);
  }

  constructor(
    private translate: TranslateService,
    private irisContext: IrisContextService
  ) {}
}
