import { ComponentType } from '@angular/cdk/portal';
import { Type } from '@angular/core';
import { DynamicFormComponent } from 'src/app/modules/restore/restore-shared';
import { UdaRegistrationFormTransformer } from 'src/app/modules/sources-shared/models';
import {
  UdaGenericSourceFormTransformer,
  UdaGenericSourceWrapperComponent,
  UdaManagedSourceFormTransformer,
  UdaManagedSourceWrapperComponent,
} from 'src/app/modules/sources/uda-connectors';
import { UdaSourceType } from 'src/app/shared';

/**
 * UDA registration form config is used so specify a form component and
 * transformer class to use for a given UDA source type.
 */
export interface UdaRegistrationFormConfig {

  /**
   * Form component maps to a Component type that can placed inside of a form
   * using a cdk component portal.
   */
  formComponent?: ComponentType<DynamicFormComponent<any>>;

  /**
   * Form transformer is used to convert to and from the api model to the form
   * model.
   */
  formTransformer: Type<UdaRegistrationFormTransformer<any>>;
}

/**
 * Type for holding the registration form config data for the UDA connectors.
 */
export type RegistrationFormOptions = {
  [key in UdaSourceType]?: UdaRegistrationFormConfig;
};

/**
 * Specifies the registration form config for a given UDA source type. This
 * consists of a dynamic form component to load for registration and a
 * transformer to convert between the api model and the form model.
 */
export const registrationFormOptions: RegistrationFormOptions = {
  'kCockroachDB': {
    formTransformer: UdaManagedSourceFormTransformer,
    formComponent: UdaManagedSourceWrapperComponent,
  },
  'kDB2': {
    formTransformer: UdaManagedSourceFormTransformer,
    formComponent: UdaManagedSourceWrapperComponent,
  },
  'kMariaDB': {
    formTransformer: UdaManagedSourceFormTransformer,
    formComponent: UdaManagedSourceWrapperComponent,
  },
  'kMySQL': {
    formTransformer: UdaManagedSourceFormTransformer,
    formComponent: UdaManagedSourceWrapperComponent,
  },
  'kPostgreSQL': {
    formTransformer: UdaManagedSourceFormTransformer,
    formComponent: UdaManagedSourceWrapperComponent,
  },
  'kSapASE': {
    formTransformer: UdaManagedSourceFormTransformer,
    formComponent: UdaManagedSourceWrapperComponent,
  },
  'kSapHana': {
    formTransformer: UdaManagedSourceFormTransformer,
    formComponent: UdaManagedSourceWrapperComponent,
  },
  'kSapMaxDB': {
    formTransformer: UdaManagedSourceFormTransformer,
    formComponent: UdaManagedSourceWrapperComponent,
  },
  'kSapOracle': {
    formTransformer: UdaManagedSourceFormTransformer,
    formComponent: UdaManagedSourceWrapperComponent,
  },
  'kSapSybase': {
    formTransformer: UdaManagedSourceFormTransformer,
    formComponent: UdaManagedSourceWrapperComponent,
  },
  'kSapSybaseIQ': {
    formTransformer: UdaManagedSourceFormTransformer,
    formComponent: UdaManagedSourceWrapperComponent,
  },
  'kOther': {
    formTransformer: UdaGenericSourceFormTransformer,
    formComponent: UdaGenericSourceWrapperComponent,
  }
};
