import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AngularMaterialModule } from '../../angular-material.module';
import { ChipListInputComponent } from './chip-list-input.component';
import { DataIdModule } from '../data-id/data-id.module';

const comps = [
  ChipListInputComponent,
];

@NgModule({
  exports: comps,
  imports: [
    AngularMaterialModule,
    CommonModule,
    DataIdModule,
    TranslateModule,
    ...comps
  ]
})
export class ChipListInputModule { }
