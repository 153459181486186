// COMPONENT: Recover as Mount Point: Search step

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.recoverAsMountPoint')
    .controller('recoverAsMountPointSearchController', recoverAsMountPointSearchControllerFn);

  function recoverAsMountPointSearchControllerFn($rootScope, $scope, $state,
    DateTimeService, SearchService, ENUM_HOST_TYPE_CONVERSION) {
    /**
     * Default list of Filters for use in the cSearchFilters.
     *
     * @example
        {
          property: {String},
          display: {String|Fn=},
          primary: {Bool=},
          locked: {Bool=},
          transformFn: {Fn=},
          value: {Array|Integer|String|Bool=}
        }
     * @type       {Array}
     */
    var defaultFilterOptions = [];

    angular.extend($scope, {
      // GENERAL SCOPE VARS
      contextActions: {},
      // TEXT STRINGS
      text: $rootScope.text.protectionRecoveryMountPointMountPointSearchText,

      // ERRORS
      errorText: $rootScope.text.protectionRecoveryMountPointMountPointSearch1ErrorText,

      // SCOPE METHODS
      disableVmSelection: disableVmSelection,
      preProcessSearchResults: preProcessSearchResults,
      selectSnapshotIndex: selectSnapshotIndex,
    });

    defaultFilterOptions = [{
      property: 'registeredSourceIds',
      display: $scope.text.sourceName,
      transformFn: sourceIdFromName,
      locked: false
    }, {
      display: $scope.text.serverType,
      property: 'entityTypes',
      transformFn: transformEntityTypeFilter,
      locked: false
    }, {
      property: 'viewBoxIds',
      display: $scope.text.viewBox,
      transformFn: viewBoxIdFromName,
      locked: false
    }, {
      property: 'jobIds',
      display: $scope.text.protectionJob,
      transformFn: jobIdFromName,
      locked: false
    }, {
      property: 'fromTimeUsecs',
      display: $scope.text.startDate,
      transformFn: DateTimeService.dateToUsecs,
      locked: false
    }, {
      property: 'toTimeUsecs',
      display: $scope.text.endDate,
      transformFn: DateTimeService.dateToUsecs,
      locked: false
    }, {
      property: 'vmName',
      primary: true
    }];


    // WATCHERS
    // Update the contextActions object for the new paged set
    $scope.$watchCollection('shared.pagedResults', buildContextActions);


    // METHODS
    /**
     * Initialize all the things!
     *
     * @method     mountPointSearchInit
     */
    function mountPointSearchInit() {
      initSearchConfig();
    }

    /**
     * checks if the vm selection should be disabled.
     *
     * @param     {Object}   row    object containing entity details
     * @return    {Boolean}  is HyperV VM of type windows
     */
    function disableVmSelection(row) {
      var hostType;

      if (row._entityKey === 'hypervEntity') {
        hostType =
          _.get(row, 'vmDocument.objectId.entity.hypervEntity.vmInfo.hostType');

        // disable hypervEntity of linux host type.
        return hostType === ENUM_HOST_TYPE_CONVERSION.kLinux;
      }

      if (row._entityKey === 'physicalEntity') {
        hostType =
          _.get(row, 'vmDocument.objectId.entity.physicalEntity.hostType');

        // disable physicalEntity of aix and solaris host type.
        return hostType === ENUM_HOST_TYPE_CONVERSION.kAix ||
          hostType === ENUM_HOST_TYPE_CONVERSION.kSolaris;
      }

      return false;
    }

    /**
     * Builds context actions with the given entities.
     *
     * @method     buildContextActions
     * @param      {Array|object}  entities  One or more Entities
     * @return     {Object}        The updated context actions.
     */
    function buildContextActions(entities) {
      entities = [].concat(entities || []);
      entities.forEach(function eachEntityFn(entity) {
        $scope.contextActions[entity._id] = [
          {
            display: $scope.text.selectServer,
            action: function selectEntityAction() {
              $scope.addToCart(entity);
            }
          }
        ];
      });
      return $scope.contextActions;
    }

    /**
     * Pre-process the search results. Uses the SearchService's transform
     * method under the hood + a little more.
     *
     * @method     preProcessSearchResults
     * @param      {object}  results  The server's raw response object
     * @return     {array}   The results of the SearchService's transformer
     */
    function preProcessSearchResults(results) {
      var resp = SearchService.transformServerResults(results);
      if (!resp.length) {
        return [{
          isEmpty: true
        }];
      }
      return resp;
    }

    /**
     * Transform a single, or an array of Source names to Source IDs
     *
     * @method     sourceIdFromName
     * @param      {object|array}  names   Array Source names
     * @return     {array}         Array of Source IDs
     */
    function sourceIdFromName(names) {
      var out = [];
      if (names) {
        names = [].concat(names);
        return $scope.shared.filterLookups.registeredSourceIds
          .reduce(function matchSources(sources, source) {
            if (names.includes(source[source._entityKey].name)) {
              sources.push(source.id);
            }
            return sources;
          }, []);
      }
      return out;
    }

    /**
     * Transforms an entityType filter object to its filter key
     *
     * @method     transformEntityTypeFilter
     * @param      {Object}  selections  selected Entity type filters
     * @return     {String}  The filter key
     */
    function transformEntityTypeFilter(selections) {
      var out = [];
      if (selections) {
        selections = [].concat(selections);
        selections.forEach(function getEntityTypeKeys(entityTypeFilter) {
          out = out.concat(entityTypeFilter.value);
        });
      }
      return out;
    }

    /**
     * TransformFn for viewBox search filter
     *
     * @method     viewBoxIdFromName
     * @param      {object|array}  viewBoxes  The viewBox names to get the
     *                                        IDs for
     * @return     {array}         The viewBox ids
     */
    function viewBoxIdFromName(viewBoxes) {
      var out = [];
      if (viewBoxes) {
        viewBoxes = [].concat(viewBoxes);
        return $scope.shared.filterLookups.viewBoxIds
          .reduce(function matchViewboxes(boxes, vb) {
            if (viewBoxes.includes(vb.name)) {
              boxes.push(vb.id);
            }
            return boxes;
          }, []);
      }
      return out;
    }

    /**
     * Transform an array of Job names to Job IDs
     *
     * @method     jobIdFromName
     * @param      {Array}  names   Array of Job names
     * @return     {Array}          Array of Job IDs
     */
    function jobIdFromName(names) {
      var out = [];
      if (names) {
        names = [].concat(names);
        return $scope.shared.filterLookups.jobIds
          .reduce(function matchJobs(jobs, job) {
            if (names.includes(job.jobName)) {
              jobs.push(job.jobId);
            }
            return jobs;
          }, []);
      }
      return out;
    }

    /**
     * Find the index of the selected snapshot and set it on the
     * row._snapshotIndex
     *
     * @method     selectSnapshotIndex
     * @param      {Object}   row        Row entity
     * @param      {Integer}  timestamp  Usecs timestamp
     */
    function selectSnapshotIndex(row, timestamp) {
      // Since we don't have access to the index of the selected item,
      // we'll find it and set row._snapshotIndex here.
      row._versions.some(function findSnapshotFn(ver, ii) {
        if (timestamp === ver.instanceId.jobStartTimeUsecs) {
          // Set the index
          row._snapshotIndex = ii;
          return true;
        }
        return false;
      });
      // Clear the tmp var
      $scope.tmpSnapshotUsecs = undefined;
    }

    /**
     * Init the shared scope object.
     *
     * @method     initSearchConfig
     */
    function initSearchConfig() {
      angular.extend($scope.shared, {
        searchId: 'mount-point-search',
        results: [],
        pagedResults: [],
        filters: angular.copy(defaultFilterOptions),
        selectedResults: [],
        endpoint: SearchService.getSearchUrl('mountPoint')
      });
    }


    // Initialize!
    mountPointSearchInit();
  }

})(angular);
