import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { RecoveryCardConfig, SelfServiceRecoveryType } from '../../models';

/**
 * Mapping of recovery card types and their configurations.
 */
const recoveryTypeCardConfigs: Record<SelfServiceRecoveryType, RecoveryCardConfig> = {
  [SelfServiceRecoveryType.kMailbox]: {
    icon: 'helix:object-o365-mailbox',
    title: 'm365Mailbox',
    description: 'm365.selfServe.mailboxCard.description',
  },
  [SelfServiceRecoveryType.kOneDrive]: {
    icon: 'helix:object-o365-onedrive',
    title: 'm365OneDrive',
    description: 'm365.selfServe.oneDriveCard.description',
  },
};

/**
 * Renders a card to allow users to launch a recovery workflow inside self-service portal.
 *
 * @example
 * <coh-m365-self-service-recovery-card type="recoveryType">
 * </coh-m365-self-service-recovery-card>
 */
@Component({
  selector: 'coh-m365-self-service-recovery-card',
  templateUrl: './recovery-card.component.html',
  styleUrls: ['./recovery-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class RecoveryCardComponent implements OnInit {
  /**
   * The type of the card.
   */
  @Input() type!: SelfServiceRecoveryType;

  /**
   * Returns a space-separated list of CSS classes to apply to the component host.
   */
  @HostBinding('class')
  get appliedClass(): string {
    return `type-${this.type}`;
  }

  /**
   * Card configuration derived using the specified type.
   */
  cardConfig: RecoveryCardConfig;

  ngOnInit(): void {
    this.cardConfig = { ...recoveryTypeCardConfigs[this.type] };
  }
}
