// Service: ViewBoxModalService

(function(angular, undefined) {
  'use strict';

  angular.module('C')
    .service('ViewBoxModalService', ViewBoxModalServiceFn)
    .controller('ViewBoxModalController', ViewBoxModalControllerFn);

  function ViewBoxModalServiceFn($uibModal) {

    this.showDeleteModal = function(viewBox) {
      var modalOptions = {
        backdrop: 'static',
        controller: 'ViewBoxModalController as $ctrl',
        keyboard: true,
        modalFade: true,
        templateUrl: 'app/platform/viewboxes/delete-viewbox-modal.html',
        resolve: {
          viewBox: function resolveViewBox() { return viewBox; },
        }
      };

      return $uibModal.open(modalOptions).result;
    };

  }


  function ViewBoxModalControllerFn(_, ViewBoxService, evalAJAX,
    $uibModalInstance, viewBox) {
    var $ctrl = this;

    _.assign($ctrl, {
      viewBox: viewBox,
      cancelModal: cancelModal,
      deleteViewBox: deleteViewBox,
    });

    /**
     * Deletes the Storage Domain.
     *
     * @method   deleteViewBox
     */
    function deleteViewBox() {
      $ctrl.deleting = true;
      ViewBoxService.deleteViewBox(viewBox.id).then(
        $uibModalInstance.close,
        evalAJAX.errorMessage
      ).finally(function deleteViewBoxFinally() {
        $ctrl.deleting = false;
      });
    }

    /**
     * Close the modal without deleting the Storage Domain.
     *
     * @method   deleteViewBox
     */
    function cancelModal() {
      $uibModalInstance.dismiss('cancel');
    }
  }
})(angular);
