import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IrisContextService } from '@cohesity/iris-core';
import { UserMfaToken } from 'src/app/modules/login/mfa';
import { Routes } from '../constants';

@Component({
  selector: 'coh-mfa-auth-app',
  templateUrl: './auth-app.component.html',
  styleUrls: ['./auth-app.component.scss']
})
export class AuthAppComponent {

  /**
   * The user information with the token credentials.
   */
  @Input() userMfaInfo: UserMfaToken;

  /**
   * Hide continue button if set to true.
   */
  @Input() disableContinueButton = false;

  /**
   * If true, Hide TOTP secret key.
   */
  hideSecretKey = true;

  /**
   * Move to the next phase
   */
  @Output() next = new EventEmitter();

  /**
   * Showing alternate method button
   */
  @Input() showAlternateMethod: boolean;

  /**
   * alternate button handler
   */
  @Output() alternateActionHandler = new EventEmitter();

  /**
   * Returns the documentation link for MFA.
   */
  get docLink(): string {

    // Get the cluster version
    const {clusterSoftwareVersion = ''} = this.irisCtx.irisContext.clusterInfo;
    let clusterVersion: string = clusterSoftwareVersion.split('_')[0];
    // Remove any patch release version.
    clusterVersion = clusterVersion.replace(/[^0-9.]/g, '');

    // Replace any . version to underscore, as tech doc url replace . with _
    clusterVersion = clusterVersion.replace(/[.]/gi, '_');

    // Trim any clusterVersion ending with _0, 6_4_0 => 6_4.
    clusterVersion = clusterVersion.replace(/(_0)$/g, '');

    clusterVersion = clusterVersion.length === 0 ? '6_6' : clusterVersion;
    return `${Routes.helpRoot.remote}/${clusterVersion}/Web/UserGuide/` +
      'Content/Dashboard/Admin/mfa-local-user.htm';
  }

  constructor(private irisCtx: IrisContextService) {}

}
