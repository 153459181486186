import { ComponentType } from '@angular/cdk/portal';
import { ChangeDetectorRef, Directive, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DataTreeNode, DataTreeSelectionChangeEvent, DataTreeSelectionModel, DataTreeSource } from '@cohesity/helix';
import { refreshableSourceEnvs } from '@cohesity/iris-shared-constants';
import { AutoDestroyable } from '@cohesity/utils';
import { UIRouterGlobals } from '@uirouter/core';
import { flattenDeep } from 'lodash';
import { combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, skip } from 'rxjs/operators';

import {
  IncludeProtectedObjectsDialogComponent,
} from './include-protected-objects-dialog/include-protected-objects-dialog.component';
import { SourceSelection } from './source-selection';
import { ExcludeOptions, ExcludeResults, ExpandToOption, SourceTreeFilters } from './source-tree-filters';
import { SourceTreeServiceFactory } from './source-tree-service.factory';
import { SourceTreeService } from './source-tree.service';

/**
 * This is a base component that can be extended and used by source tree components for specific use cases
 * This manages the main work of configuring the data source so that components that use this can focus
 * on presentation details.
 */
@Directive()
export abstract class BaseSourceTreeComponent<NodeType, TreeNodeType extends DataTreeNode<NodeType>>
  extends AutoDestroyable
  implements OnChanges {
  /**
   * If true, a more compact version of the job source tree will be displayed.
   * This includes width, spacing changes and hiding source tree actions (
   * Show All, Expand To and Refresh).
   */
  @Input() compact = false;

  /**
   * The current environment - this determines which service to load.
   */
  @Input() environment;

  /**
   * Set when we have any workload type defined.
   */
  @Input() workloadType: string = undefined;

  /**
   * An array of nodes in a tree structure.
   */
  @Input() data: NodeType[];

  /**
   * This type represents the selection model in terms of the job or other data structure interacting with this
   * tree. The tree service will convert this to a data tree selection model, and then back again to an externally
   * facing selection. When the tree is used as a form component this will usually be used as the component's value.
   */
  @Input() selection: SourceSelection;

  /**
   * Is only one node allowed to be selected at a time?
   * Use radio button instead of checkbox in this case.
   */
  @Input() isSingleSelect: boolean;

  /**
   * Is the source tree being rendered in readOnly mode.
   * No modification to data selection is allowed in this case.
   */
  @Input() readOnly: boolean;

  /**
   * Emits an event to refresh the source tree.
   */
  @Output() refreshSourceTree = new EventEmitter<void>();

  /**
   * Emits an event whenever the source tree service is initialized or updated
   */
  @Output() sourceTreeInitalized = new EventEmitter<void>();

  /**
   * The data tree selection model.
   */
  dataTreeSelection: DataTreeSelectionModel<TreeNodeType>;

  /**
   * The data tree source.
   */
  dataTreeSource: DataTreeSource<NodeType>;

  /**
   * This class manages the filters that can be shown for the tree, and which are currently active. When this changes
   * the tree data source should be updated.
   */
  filters: SourceTreeFilters<TreeNodeType>;

  /**
   * The tree service is created by the factory service and is specific to an adapter.
   */
  treeService: SourceTreeService<NodeType, TreeNodeType>;

  /**
   * Set of all object ids which are missing from the source tree.
   */
  missingObjectIds: Set<number>;

  /**
   * Exclude options that are sent to exclude filters dialog
   */
  excludeOptions: ExcludeOptions;

  constructor(readonly cdr: ChangeDetectorRef,
    private transformerFactory: SourceTreeServiceFactory,
    private uiRouterGlobal: UIRouterGlobals,
    private checkAlreadyProtectedChildren = false,
    protected dialog: MatDialog = null,
  ) {
    super();
  }

  /**
   * Optional region id to get sources from. This is set from the
   * region id state param if present.
   */
  get regionId(): string {
    return this.uiRouterGlobal?.params?.regionId;
  }

  /**
   * Gets the exclusion filters to exclude objects from the source tree service.
   */
  get excludeFilters() {
    return this.treeService.excludeFilters;
  }

  /**
   * Sets the updated exclusion filters to the source tree service.
   */
  set excludeFilters(filters) {
    this.treeService.excludeFilters = filters;
  }

  /**
   * Return whether to show the "Refresh" button for source tree or not.
   */
  get showRefreshSourceTree(): boolean {
    return refreshableSourceEnvs.includes(this.environment);
  }

  /**
   * Update the component when inputs change
   *
   * @param   changes   A map of changed objects.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.environment && this.environment) {
      this.initService();
    }

    if (changes.data && this.data) {
      this.initData();
    }

    if (changes.selection && this.selection) {
      this.initSelection();
    }

  }

  /**
   * Reinitialize the service whenver the environment is changed.
   */
  initService() {
    this.cleanUpSubscriptions();

    this.treeService = this.transformerFactory.getSourceTreeService(this.environment);
    this.filters = this.treeService.filters;
    this.dataTreeSource = this.treeService.dataTreeSource;
    this.dataTreeSelection =
      new DataTreeSelectionModel(this.treeService.treeControl, this.isSingleSelect, this.readOnly);
    this.treeService.dataTreeSelection = this.dataTreeSelection;

    // Reset view filter defaults based on environment workload type
    if (this.treeService.initViewFilterDefaults) {
      this.filters = this.treeService.initViewFilterDefaults(this.workloadType);
    }
    this.filters.resetFilters();

    // Attaches the value changes from the filters for environment specific logic
    if (this.treeService.attachFilterValueChangeListener) {
      this.treeService.attachFilterValueChangeListener(this.workloadType,
        (onChangeFn) => {
        this.filters.filterValues$.pipe(this.untilDestroy(), skip(1),
          distinctUntilChanged()).subscribe(value => {
            // Updates filters based on current filter changes
            this.filters = onChangeFn.call(this, value);
        });
      });
    }

    // Reset Exclude filters when the source tree is loaded for first time
    this.excludeFilters = undefined;

    // Update the data source any time the filters change
    this.filters.treeFilters$
      .pipe(this.untilDestroy())
      .subscribe((filters: any) => (this.dataTreeSource.filters = filters));

    // Update the expanded nodes when the filter changes
    // This approach may be too aggressive...
    this.dataTreeSource.filteredData$.pipe(
      this.untilDestroy(),
      debounceTime(5)
      ).subscribe(filteredNodes => {
        const expandNodes = filteredNodes.filter(
          (node: TreeNodeType) =>
            this.treeService.treeTransformer.getExpandable(node) && this.treeService.shouldExpandNodeOnLoad(node)
        );
        this.treeService.treeControl.expansionModel.select(...(expandNodes as TreeNodeType[]));
      });

    // Call initData in case the data was set before the environment was.
    this.initData();

    this.sourceTreeInitalized.emit();

    // Listen for changes to the selection model and update the form control value.
    this.handleSelectionChange();
  }

  /**
   * Handle changes to the selection model
   */
  handleSelectionChange() {
    // Use the flattened data list to generate a list of available tags each time it changes.
    combineLatest([this.dataTreeSource.flattenedData$, this.dataTreeSelection.changes$])
      .pipe(
        // At the moment, the data tree selection emits several changes events when it is being modified.
        // The debounce time here prevents this from running unnecessarily.
        debounceTime(5),
        this.untilDestroy()
      )
      .subscribe(([allData, selection]) => {
        // Exclude options to be sent to exclude filters dialog
        this.excludeOptions = this.getExcludeOptions(this.excludeFilters);

        // Adapter tree service applies the exclusions on the source tree
        this.treeService.handleFilterExclusions(this.excludeFilters, this.regionId);
        this.filters.setAvailabletags(this.treeService.getTagCategories(allData as TreeNodeType[], selection));
      });
  }

  /**
   * Handles user-initiated selection changes.
   *
   * @param    ev   The change event.
   */
  handleUserSelectionChange(ev: DataTreeSelectionChangeEvent<TreeNodeType>) {
    this.autoProtectObjectCheck(ev);

    if (typeof this.treeService.selectionChangeHandler === 'function') {
      return this.treeService.selectionChangeHandler(ev.selection, ev);
    }
  }

  /**
   * This method listens for any time a user auto selects an object, and then checks that object's descendants to find
   * any objects that already have object protection applied. If objects are found, we present a dialog asking the user
   * if they want to overwrite protection or not. If they choose no, we will automaticaally exclude all of the protected
   * objects.
   *
   * This logic will be skipped if this.checkAlreadyProtectedChildren is set to false.
   *
   * @param event The selection change event.
   */
  autoProtectObjectCheck(event: DataTreeSelectionChangeEvent<TreeNodeType>) {
    const { type, node: autoSelected } = event;
    if (
      type !== 'autoSelect' ||
      !this.dataTreeSelection.isAutoSelected(autoSelected) ||
      !this.checkAlreadyProtectedChildren ||
      !this.dialog
    ) {
      return;
    }

    // Look for object-protected nodes which are children of this node, but protected by something else
    const alreadyProtectedChildren = this.treeService.getAlreadyObjectProtectedDescendants(autoSelected);
    if (!alreadyProtectedChildren.length) {
      return;
    }

    // If the tree service is not configured to overwrite protection, we will automatically exclude the objects
    // It's possible that the user can un-exclude them later, and rely on whatever magneto's default behavior would be
    // but we will not overwrite/unprotect the protection if they do.
    if (!this.treeService.canOverwriteObjectProtection) {
      this.dataTreeSelection.excludeNodes(alreadyProtectedChildren);
      return;
    }

    // If we can overwrite existing protection, we will show a dialog asking the user's intent, and either exclude the
    // selection, or leave it as is, counting on our later code to unprotect the necessary objects.
    this.dialog.open(IncludeProtectedObjectsDialogComponent, {
      width: '75vw',
    }).afterClosed().pipe(
      this.untilDestroy(),
      filter(result => !result)
    ).subscribe(() => {
      this.dataTreeSelection.excludeNodes(alreadyProtectedChildren);
    });
  }

  /**
   * Update the data on the data tree source whenever it changes.
   */
  initData() {
    if (!this.data || !this.treeService) {
      return;
    }

    this.missingObjectIds = new Set<number>();
    this.dataTreeSource.data = this.treeService.unwrapRootContainers(this.data);
  }

  /**
   * This callback function is called when exclude filters dialog is closed. It
   * updates the source tree with newly updated exclusions.
   *
   * @param   excludeResults   The exclude filters dialog result
   */
  updateExclusions(excludeResults: ExcludeResults) {
    if (!excludeResults) {
      return;
    }
    const nodeIds = excludeResults.filteredObjects.map(object => object.id);

    // Update the exclude filters value
    this.excludeFilters = excludeResults.filters;

    // Update the exclude options to be sent to exclude filters form
    this.excludeOptions = this.getExcludeOptions(this.excludeFilters);

    // Apply the exclusions in the source tree
    this.treeService.applyExclusions(nodeIds);
  }

  /**
   * Gets exclude filters options when there are changes in seletion
   *
   * @param     excludeFilters  Exclude filters to be sent to exclude dialog
   * @return    Exclude Options to be sent to exclude dialog
   */
  getExcludeOptions(excludeFilters): ExcludeOptions {
    if (!this.treeService.excludeComponent) {
      return;
    }

    const autoSelectedSourceIds = (this.treeService.dataTreeSelection
      .currentSelection.autoSelected || []).map((node) => node.id);

    return this.treeService.getExcludeOptions(excludeFilters) || {
      autoSelectedSourceIds: autoSelectedSourceIds as number[],
      filters: excludeFilters
    };
  }

  /**
   * Get the component to render exclusion options
   *
   * @returns Compoenent to render
   */
  get excludeComponent(): ComponentType<any> {
    return this.treeService?.excludeComponent;
  }

  /**
   * Expands the nodes based on the specified expand options.
   *
   * @param   options   The selecgted expand to options.
   */
  expandToNodes(options: ExpandToOption<TreeNodeType>) {
    const control = this.treeService.treeControl;
    if (!options || !control) {
      return;
    }
    control.expansionModel.clear();
    const expandNodes = control.allDataNodes.filter((node) => options.expandNodeFilter(node));
    control.expansionModel.select(...expandNodes);
  }

  /**
   * Function to check whether some of the objects present in the protection
   * group are missing from the source tree.
   */
  checkMissingObjects() {
    // Loads missing objects if the source tree is working in an async mode. By
    // default returns empty observable as the source tree is loaded entirely.
    this.treeService.loadMissingObjects(
      this.treeService.treeControl.allDataNodes,
      this.selection).subscribe(() => {
        // Some of these keys can be null, so use "|| []" to assign a default value
        // over default using destructing.
        const allObjectIds = [
          ...(this.selection.sourceIds || []),
          ...(this.selection.excludeSourceIds || []),

          // Use _.flattenDeep over Array.prototype.flat for compatibility
          ...flattenDeep(this.selection.excludeVmTagIds) as any,
          ...flattenDeep(this.selection.vmTagIds) as any,
        ];

        this.missingObjectIds = this.missingObjectIds || new Set<number>();

        for (const objectId of allObjectIds) {
          // Loop through all the protection group object ids and find out if any of
          // those object ids do not exist in the source tree.
          if (!this.treeService.treeControl.hasNode(objectId)) {
            this.missingObjectIds.add(objectId);
          } else if (this.missingObjectIds?.has(objectId)) {
            // Delete the missing objects cache for the object id
            delete this.treeService.missingObjectsCache[objectId];
            this.missingObjectIds.delete(objectId);
          }
        }
      });
  }

  /**
   * Whenever the selection changes, transform it to the data tree selection model and update the selection.
   */
  initSelection() {
    if (this.selection && this.treeService && this.treeService.treeControl.dataNodes) {
      this.checkMissingObjects();
      this.treeService.previousSelection = this.dataTreeSelection.currentSelection;
      this.dataTreeSelection.currentSelection = this.treeService.transformToDataTreeSelection(
        this.treeService.treeControl.allDataNodes,
        this.selection
      ) as any;
    }
  }

  /**
   * Clears the current selection of objects.
   */
  clearSelection() {
    if (typeof this.treeService.clearSelection === 'function') {
      this.treeService.clearSelection();
    }
  }
}
