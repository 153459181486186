import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { HelixAssetUrlPipe } from '../helix-assets';
import { getIcons as getBaseIcons, IconList, IconService } from './icon.service';

/**
 * These are all of the icons currently defined specifically for IBM Aqua theme.
 */
export const getIbmIcons = (): IconList => {
  const icnBase = 'i/icn-ibm-aqua';
  return {
    ...getBaseIcons(),

    'autoprotect-checkbox': `${icnBase}/core/autoprotect-checkbox.svg`,
    'autoprotect-checkbox-excluded': `${icnBase}/core/autoprotect-checkbox-excluded.svg`,
    download: `${icnBase}/core/download.svg`,
    'half-eye': `${icnBase}/core/half-eye.svg`,
    'hierarchy-flat': `${icnBase}/core/hierarchy-flat.svg`,
    'hierarchy-folder': `${icnBase}/core/hierarchy-folder.svg`,
    'hierarchy-physical': `${icnBase}/core/hierarchy-physical.svg`,
    'hierarchy-tag': `${icnBase}/core/hierarchy-tag.svg`,
    'menu-open': `${icnBase}/core/menu-open.svg`,
    'nav-collapsed': `${icnBase}/core/nav-collapsed.svg`,
    'nav-expanded': `${icnBase}/core/nav-expanded.svg`,
    protection: `${icnBase}/core/protection.svg`,
    system: `${icnBase}/core/system.svg`,
    'status-error': `${icnBase}/status/status-error.svg`,
    'status-info': `${icnBase}/status/status-info.svg`,
    'status-success': `${icnBase}/status/status-success.svg`,
    'status-warning': `${icnBase}/status/status-warning.svg`,
  };
};

/**
 * This service registers helix icons with the MatIconRegistry.
 * Each icon can be accessed with `helix:<iconName>`
 */
@Injectable({
  providedIn: 'root',
})
export class IbmAquaIconService extends IconService {
  constructor(
    protected helixAssetUrlPipe: HelixAssetUrlPipe,
    protected iconRegistry: MatIconRegistry,
    protected sanitizer: DomSanitizer
  ) {
    super(helixAssetUrlPipe, iconRegistry, sanitizer);
  }

  /**
   * Initial registration of all Helix Icons with the MatIconRegistry for IBM Aqua theme.
   */
  initializeIcons() {
    if (!this.initialized) {
      this.registerAllIcons(getIbmIcons());
      this.initialized = true;
    }
  }

  /**
   * Update icon registration
   */
  updateIcons() {
    this.registerAllIcons(getIbmIcons());
  }
}
