import { Injectable } from '@angular/core';
import {
  UdaConfigParams,
  UdaHostOsSpecificParams,
} from '@cohesity/api/v2';
import { flagEnabled, IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  UdaConnectorConfigService,
  UdaDmsSupportedSourceTypes,
  UdaOnPremSupportedSourceTypes,
  udaDmsSourceTypeToFeatureFlagMap,
  udaOnPremSourceTypeToFeatureFlagMap,
} from 'src/app/shared';

/**
 * This service encapsulates the shared state that needs to be accessed by the
 * UDA registration container component and any UDA connector's registration
 * component that needs to rely on dynamic config. Currently only the managed
 * UDA source component relies on it for sharing state.
 */
@Injectable()
export class UdaSourceContextService {

  /**
   * List of enabled source types.
   *
   * This depends on whether any OS types are enabled for the
   * give source types. Every source type & OS type combination has a
   * corresponding UI feature flag, which is checked to compute this list.
   */
  sourceTypes$: Observable<string[]>;

   /**
    * List of the supported OS types for the selected source type.
    *
    * In order to compute this list, it is ensured that the feature flag is
    * enabled for the source type + os type combination.
    */
  private readonly hostOsTypes = new BehaviorSubject<string[]>([]);

   /**
    * Public observable for the private host OS type list.
    */
  readonly hostOsTypes$: Observable<string[]> = this.hostOsTypes.asObservable();

   /**
    * Whether UDA Dynamic Config feature flag is enabled or not.
    */
  private readonly isDynamicConfigFeatureEnabled = flagEnabled(this.contextService.irisContext, 'udaDynamicConfig');

   /**
    * Selected UDA source type.
    */
  private readonly _selectedSourceType$ = new BehaviorSubject<string>(null);

   /**
    * Set the selected source type for registration.
    *
    * @param   sourceType   The source type selected by the user for registration.
    */
  set selectedSourceType(sourceType: string) {
    this._selectedSourceType$.next(sourceType);
  }

   /**
    * Get the selected source type value in the behavior subject.
    *
    * @returns Selected source type.
    */
  get selectedSourceType(): string {
    return this._selectedSourceType$.value;
  }

  /**
   * Indicates if the current user is in DMaaS context.
   */
  get isDmsScope(): boolean {
    return isDmsScope(this.contextService.irisContext);
  }

  constructor(
    private configService: UdaConnectorConfigService,
    private contextService: IrisContextService,
  ) {
    this.initialiseSourceTypeList();

    // If this not in DMaaS scope and the 'udaDynamicConfig' feature flag is
    // enabled, set up a host OS list generator. This list will depend on the
    // UDA source type selected by the user.
    if (!this.isDmsScope && this.isDynamicConfigFeatureEnabled) {
      this.setUpHostOsTypeListGenerator();
    }
  }

  /**
   * Compute a list of source types for which atleast a single OS type config
   * is enabled via its feature flag.
   */
  private initialiseSourceTypeList() {
    if (this.isDmsScope) {
      // There is no dynamic config on DMaaS. We use a list of hard coded UDA
      // source types on DMaaS.
      this.sourceTypes$ = of(UdaDmsSupportedSourceTypes).pipe(
        map(types => types.filter(type => {
          // Filter out the UDA source types for which the all of it feature
          // flags have been disabled.
          const featureFlags = udaDmsSourceTypeToFeatureFlagMap[type] || [];
          return !featureFlags?.length ||
            featureFlags.some(featureFlag => flagEnabled(this.contextService.irisContext, featureFlag));
        }))
      );
    } else {
      if (this.isDynamicConfigFeatureEnabled) {
        this.sourceTypes$ = this.configService.udaConfigs$.pipe(
          map((sourceTypeConfigs: UdaConfigParams[]) => {
            const enabledConfigs = sourceTypeConfigs.filter((sourceTypeConfig) =>
              (sourceTypeConfig.hostOsSpecificConfigurations || [])
                .some(osConfig => this.isHostOsConfigEnabled(osConfig)));

            return enabledConfigs.map(enabledConfig => enabledConfig.index.sourceType).sort();
          })
        );
      } else {
        // If 'udaDynamicConfig' feature flag is disabled, use the legacy
        // hardcoded list of supported UDA source types.
        this.sourceTypes$ = of(UdaOnPremSupportedSourceTypes).pipe(
          map(types => types.filter(type => {
            // Filter out the UDA source types for which the all of it feature
            // flags have been disabled.
            const featureFlags = udaOnPremSourceTypeToFeatureFlagMap[type] || [];
            return !featureFlags?.length ||
              featureFlags.some(featureFlag => flagEnabled(this.contextService.irisContext, featureFlag));
          }))
        );
      }
    }
  }

  /**
   * Listen to the selected source type change and compute a list of supported
   * OS types for it.
   */
  private setUpHostOsTypeListGenerator() {
    combineLatest([
      this.configService.udaConfigs$,
      this._selectedSourceType$
    ])
      .pipe(
        filter(([udaConfigs, selectedSourceType]) => !!udaConfigs && !!selectedSourceType)
      )
      .subscribe(([udaConfigs, selectedSourceType]) => {
        const selectedSourceTypeConfig = udaConfigs.find(config => config.index.sourceType === selectedSourceType);
        const enabledOsType = (selectedSourceTypeConfig.hostOsSpecificConfigurations || [])
          .filter(osConfig => this.isHostOsConfigEnabled(osConfig))
          .map(osConfig => this.configService.convertHostOsTypeToUIFormat(osConfig.hostOsType))
          .sort();
        this.hostOsTypes.next(enabledOsType);
      });
  }

  /**
   * Check if OS configuration's feature flag has been enabled or not. If the
   * flag is not specified for the config, it treated as 'enabled'.
   *
   * @param   osConfig   OS configuration object for a UDA source type.
   * @returns True if the OS type is enabled.
   */
  private isHostOsConfigEnabled(osConfig: UdaHostOsSpecificParams) {
    const uiFeatureFlag = osConfig?.index?.uiFeatureFlag;
    if (typeof uiFeatureFlag !== 'string') {
      return true;
    }
    return flagEnabled(this.contextService.irisContext, uiFeatureFlag);
  }
}
