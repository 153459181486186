import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const protectModify = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngProtectionGroupGlobalModify &&
  ctx.FEATURE_FLAGS.ngProtectionGroupViewModify &&
  ctx.PROTECTION_MODIFY;

export const ViewConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'protection-builder-view.**',
      url: '/protection/protect/view',
      loadChildren: () => import('./view.module').then(m => m.ViewModule),
    },
  ],
  getStateAccessMap(): StateAccessMap {
    return {
      'protection-builder-view.create': protectModify,
      'protection-builder-view.edit': protectModify,
    };
  },
};
