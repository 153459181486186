// MODULE: Advanced Diagnostics Controller

;(function(angular, undefined) {
  'use strict';

  // Specifies a map of schemas and the desired entity search parameter key
  var SCHEMA_ENTITY_MAP = {
    kBridgeViewPerfStats: 'viewName',
    kBridgeViewLogicalStats: 'viewName',
    kBridgeWorkloadGranularLevelPerfStats: 'entityPrefix',
    FaultTolerance: 'entity',
  };

  // hashed on currentSelections.metric.metricUnit.type to determine which
  // chart to place the data in. All time related metrics will be converted
  // and added to msecsChart
  var metricTypesToCharts = {
    // kBytes
    0: 'bytesChart',
    // kTimeUsecs
    1: 'msecsChart',
    // kTimeMsecs
    2: 'msecsChart',
    // kTimeSecs
    3: 'msecsChart',
    // kTimeMins
    4: 'msecsChart',
    // kCounter
    5: 'numChart',
    // kTempCentigrade
    6: 'numChart',
    // kTempFahrenheit
    7: 'numChart',
    // kRpm
    8: 'numChart',
    // kPercentage
    9: 'percentageChart'
  };

  angular.module('C.statistics')
    .controller('DiagnosticsController', DiagnosticsControllerFn);

  function DiagnosticsControllerFn($rootScope, $scope, $q, $filter,
    $state, $timeout, evalAJAX, cMessage, cFocus, ViewBoxService, $translate,
    StatsService, JobRunsService, DateTimeService, DiskService,
    ExternalTargetService, CHART_COLORS, FEATURE_FLAGS) {

    /** @type {Object} map lookup of acive plots/series, so we can prevent duplicates */
    var activePlots = {};

    /** @type {Array} colors to be used for series */

    // Color blind safe swatch: Japanese Cudo Colors
    var customColors = [
      '#0072b2', // blue
      '#009e73', // green
      '#e69f2e', // orange
      '#d55e00', // red
      '#cc79a7', // pink
      '#56b4e9', // blue
      '#f0e442', // yellow
    ];

    $scope.currentSelections = {
      metric: undefined,
      entity: undefined,
      interval: undefined
    };

    angular.extend($scope.text, $rootScope.text.monitoringDiagnosticsDiagnostics);

    $scope.errorText = angular.extend($scope.errorText || {}, $rootScope.text.monitoringDiagnosticsDiagnostics);

    // default show job runs to false
    $scope.show = {
      jobRuns: false,
    };

    /**
     * handles toggling of the show/hide job runs switch
     */
    $scope.showJobRunsToggled = function showJobRunsToggled() {
      // if jobRuns were revealed, charts need to be updated.
      // if it was hidden there's nothing to do
      if ($scope.show.jobRuns) {
        $scope.updateCharts();
      }
    };

    /**
     * @type {Object} form values are inserted into this object and then it
     *                is used as-is to call the stats API
     */
    $scope.newPlotOptions = {
      startTimeMsecs: null,
      endTimeMsecs: null,
      rollupFunction: '',
      rollupIntervalSecs: null
    };

    /** @type {Boolean} controls ng-disable and ng-required on the custom interval field */
    $scope.customInterval = false;

    /**
     * list of intervals.
     * will be updated dynamically based on metric selection
     * @type {Array}
     */
    $scope.intervalList = [];

    /**
     * list of available rollup functions
     * will be updated dynamically based on metric and schema selection
     * @type {Array}
     */
    $scope.rollupFnList = [];

    // schema, entity, and metric lists will be populated
    // via API calls
    $scope.schemaList = [];
    $scope.entityList = [];
    $scope.metricList = [];

    // Job Runs Chart
    $scope.jobRunsChart = {
      chart: {
        type: 'line',
        // height will be updated based on jobs loaded
        height: 100,
        marginTop: 1,
        // 1 pixel margins on left and right prevent
        // plotBorder from being cut off
        marginRight: 1,
        marginLeft: 1,
        marginBottom: 1
      },
      title: {
        text: null
      },
      series: [],
      colors: customColors,
      xAxis: $scope.xAxisShared,
      yAxis: {
        tickInterval: 1,
        labels: {
          enabled: false
        },
        allowDecimals: false,
        startOnTick: false,
        endOnTick: false,
        title: {
          text: null
        },
        minPadding: 0.2,
        maxPadding: 0.2
      },
      legend: {
        enabled: false
      },
      tooltip: {
        formatter: function() {
          return '<b>' + this.series.name + '</b><br>' +
            DateTimeService.msecsToFormattedDate(this.point.options.from) +
            ' - ' + DateTimeService.msecsToFormattedDate(this.point.options.to);
        }
      },
      plotOptions: {
        line: {
          lineWidth: 9,
          marker: {
            enabled: false
          },
          dataLabels: {
            enabled: true,
            align: 'left',
            formatter: function() {
              return this.point.options && this.point.options.label;
            }
          }
        }
      }
    };

    // Bytes Chart
    $scope.bytesChart = {
      chartType: 'bytesLine',
      compact: true,
      loading: true,
      rawSeriesCount: 0,
      colorsInUse: {},
      chart: {
        zoomType: 'x',
        height: 150,
        resetZoomButton: {
          theme: {
            display: 'none'
          }
        }
      },
      series: [],
      xAxis: $scope.xAxisShared,
      yAxis: {
        min: 0,
        labels: {
          // to be updated based on calculations (GiB, TiB, etc)
          format: ''
        }
      },
      tooltip: {
        // to be updated based on calculations (GiB, TiB, etc)
        valueSuffix: ''
      },
      legend: {
        enabled: false
      }
    };

    // IOPS Chart
    $scope.iopsChart = {
      chartType: 'line',
      compact: true,
      loading: true,
      rawSeriesCount: 0,
      colorsInUse: {},
      chart: {
        zoomType: 'x',
        height: 150,
        resetZoomButton: {
          theme: {
            display: 'none'
          }
        }
      },
      series: [],
      xAxis: $scope.xAxisShared,
      yAxis: {
        labels: {
          format: '{value} IOs'
        },
        min: 0,
        showFirstLabel: false
      },
      tooltip: {
        valueSuffix: ' IOs',
      },
      legend: {
        enabled: false
      }
    };

    // msecs Chart
    $scope.msecsChart = {
      chartType: 'line',
      compact: true,
      loading: true,
      rawSeriesCount: 0,
      colorsInUse: {},
      chart: {
        zoomType: 'x',
        height: 150,
        resetZoomButton: {
          theme: {
            display: 'none'
          }
        }
      },
      series: [],
      xAxis: $scope.xAxisShared,
      yAxis: {
        labels: {
          format: '{value} ' + $scope.text.ms
        },
        min: 0,
        showFirstLabel: false
      },
      tooltip: {
        valueSuffix: ' ms'
      },
      legend: {
        enabled: false
      }
    };

    // Percentage Chart
    $scope.percentageChart = {
      chartType: 'line',
      compact: true,
      loading: true,
      rawSeriesCount: 0,
      colorsInUse: {},
      chart: {
        zoomType: 'x',
        height: 150,
        resetZoomButton: {
          theme: {
            display: 'none'
          }
        }
      },
      series: [],
      xAxis: $scope.xAxisShared,
      yAxis: {
        labels: {
          format: '{value}%'
        },
        min: 0,
        max: 100,
        showFirstLabel: false
      },
      tooltip: {
        valueSuffix: '%'
      },
      legend: {
        enabled: false
      }
    };

    // Number Chart
    $scope.numChart = {
      chartType: 'line',
      compact: true,
      loading: true,
      rawSeriesCount: 0,
      colorsInUse: {},
      chart: {
        zoomType: 'x',
        height: 150,
        resetZoomButton: {
          theme: {
            display: 'none'
          }
        }
      },
      series: [],
      xAxis: $scope.xAxisShared,
      yAxis: {
        min: 0,
        showFirstLabel: false
      },
      tooltip: {
        // Preserve provided decimals, this prevents IO values from having .00
        // displayed for IO values as they are always whole numbers.
        valueDecimals: undefined,
      },
      legend: {
        enabled: false
      }
    };

    const rollupFnList = [{
      name: $scope.text.rollups.sum,
      value: 'sum'
    }, {
      name: $scope.text.rollups.average,
      value: 'average'
    }, {
      name: $scope.text.rollups.count,
      value: 'count'
    }, {
      name: $scope.text.rollups.max,
      value: 'max'
    }, {
      name: $scope.text.rollups.min,
      value: 'min'
    }, {
      name: $scope.text.rollups.median,
      value: 'median'
    }, {
      name: $scope.text.rollups.rate,
      value: 'rate'
    }, {
      name: $scope.text.rollups.percentile95,
      value: 'percentile95'
    }, {
      name: $scope.text.rollups.latest,
      value: 'latest'
    }, {
      name: $scope.text.rollups.raw,
      value: ''
    }];

    const irisStatsMap = {
      'kLatency': rollupFnList,
      'kThroughput': [{
        name: $scope.text.rollups.average,
        value: 'average'
      }, {
        name: $scope.text.rollups.median,
        value: 'median'
      }, {
        name: $scope.text.rollups.sum, // does not consider 0-values
        value: 'sum'
      }, {
        name: $scope.text.rollups.count, // plots 0-values
        value: 'count'
      }, {
        name: $scope.text.rollups.raw,
        value: ''
      }],
      'kErrors': [{
        name: $scope.text.rollups.average,
        value: 'average'
      }, {
        name: $scope.text.rollups.median,
        value: 'median'
      }, {
        name: $scope.text.rollups.sum, // does not consider 0-values
        value: 'sum'
      }, {
        name: $scope.text.rollups.count, // plots 0-values
        value: 'count'
      }, {
        name: $scope.text.rollups.raw,
        value: ''
      }],
      'kResponseCode': [{
        name: $scope.text.rollups.raw,
        value: ''
      }],
      'kResponseTime': rollupFnList,
      'kResponseSize': [{
        name: $scope.text.rollups.average,
        value: 'average'
      }, {
        name: $scope.text.rollups.median,
        value: 'median'
      }, {
        name: $scope.text.rollups.max,
        value: 'max'
      }, {
        name: $scope.text.rollups.min,
        value: 'min'
      }, {
        name: $scope.text.rollups.latest,
        value: 'latest'
      }, {
        name: $scope.text.rollups.raw,
        value: ''
      }],
    };

    const magnetoStatsMap = {
      'kStatusCode': [{
        name: $scope.text.rollups.raw,
        value: ''
      }],
      'kResponseTime': rollupFnList,
      'kResponseSize': [{
        name: $scope.text.rollups.average,
        value: 'average'
      }, {
        name: $scope.text.rollups.median,
        value: 'median'
      }, {
        name: $scope.text.rollups.max,
        value: 'max'
      }, {
        name: $scope.text.rollups.median,
        value: 'median'
      }, {
        name: $scope.text.rollups.latest,
        value: 'latest'
      }, {
        name: $scope.text.rollups.raw,
        value: ''
      }],
      'kThroughput': [{
        name: $scope.text.rollups.average,
        value: 'average'
      }, {
        name: $scope.text.rollups.median,
        value: 'median'
      }, {
        name: $scope.text.rollups.sum, // does not consider 0-values
        value: 'sum'
      }, {
        name: $scope.text.rollups.count, // plots 0-values
        value: 'count'
      }, {
        name: $scope.text.rollups.raw,
        value: ''
      }],
    };

    /**
     *
     * Fetches list of Entities matching provided params.
     *
     * @param  {String}  params   Query params for API.
     */
    function _getEntities(params) {
      const schemaName = $scope.newPlotOptions.schemaName;

      params = Object.assign({
        metricNames: $scope.currentSelections.metric,
        schemaName: schemaName,
      }, params);

      // call the API and get info for this schema,
      // using it to polulate the  entity lists
      $scope.fetchingEnties = true;
      StatsService.getEntities(params).then(
        function getEntitiesSuccess(entities) {

          $scope.entityList = entities.map(
            function entityListMap(entity, index) {
              var entityIdObj = entity.entityId;
              var entityIdType = entityIdObj.entityId.type;
              var attributeVecKey = entity.attributeVec[0].key;
              var entityName;
              var entityIdName;
              var locationTag;
              var entityId;
              var idKey;
              var vaultId;
              var vaultIdString;
              var vaultName;

              // check for provided name,
              angular.forEach(
                entity.attributeVec,
                function forEachAttrVec(attrib) {
                  if (attrib.key === 'Name') {
                    if (attrib.value.hasOwnProperty('data') &&
                      attrib.value.data.hasOwnProperty('stringValue')) {
                        entityName = attrib.value.data.stringValue;
                    }
                  }
                }
              );

              if (entityIdType === 0) {
                entityIdName = entityIdObj.entityId.data.int64Value;
              } else if (entityIdType === 2) {
                entityIdName = entityIdObj.entityId.data.stringValue;
              }

              // if we didn't find an entity name on the API return,
              // lets look it up in our map
              if (!entityName) {
                switch (true) {

                  // special case for kIceboxJobVaultStats, as name needs
                  // to be constructed from multiple ids
                  case schemaName === 'kIceboxJobVaultStats':
                    entityName = [];
                    entity.attributeVec.some(function findJobName(attribute) {
                      if (attribute.key === 'JobName') {

                        // add the job name
                        entityName.push(attribute.value.data.stringValue);

                        return true;
                      }
                    });
                    entity.attributeVec.some(function findJobName(attribute) {
                      if (attribute.key === 'VaultId') {

                        // add the vault name
                        entityName.push($scope.entityNameMap.VaultId[attribute.value.data.int64Value] || $scope.text.unknownExtTarget);

                        return true;
                      }
                    });
                    entityName = entityName.join(', ');
                    break;

                  case schemaName === 'kIceboxVaultStats':
                    entityName = [];
                    entity.attributeVec.some(function findVaultName(attribute) {
                      if (attribute.key === 'VaultName') {
                        // add the vault name
                        let name = [$scope.text.externalTargetLabel, attribute.value.data.stringValue];

                        // If valut is unregistered
                        if (!$scope.entityNameMap.VaultId[
                          entity.attributeVec.find(att => att.key === 'VaultId').value.data.int64Value
                        ]) {
                          name.push(' (' + $translate.instant('unregistered') + ')');
                        }

                        entityName.push(name.join(''));

                        return true;
                      }
                    });
                    break;

                  case $scope.entityNameMap.hasOwnProperty(attributeVecKey) &&
                    typeof $scope.entityNameMap[attributeVecKey] == 'function':
                      entityName =
                        $scope.entityNameMap[attributeVecKey](entityIdName);
                    break;

                  case $scope.entityNameMap.hasOwnProperty(attributeVecKey) &&
                    $scope.entityNameMap[attributeVecKey].hasOwnProperty(entityIdName):
                      entityName =
                        $scope.entityNameMap[attributeVecKey][entityIdName];
                    break;

                    // if attributeVecKey is "TierId" based then we need to dig out the id
                    // and look it up in the appropriate name map
                  case attributeVecKey.indexOf('TierId') > -1:

                    // currently only supporting local and cloud tier
                    locationTag = /:Cloud/.test(entityIdName) ?
                      $scope.text.cloudTag : $scope.text.localTag;

                    // currently only supporting Cluster and View Box tiering
                    idKey = (attributeVecKey === 'ClusterTierId') ?
                      'ClusterId' : 'ViewBoxId';
                    entityId = entityIdName.slice(0, entityIdName.indexOf(':'));

                    // Get the vault id so it can be added to the entity name
                    vaultId = entityIdName.split(':').pop();
                    vaultIdString = $scope.entityNameMap.VaultId[vaultId];
                    vaultName = vaultIdString && vaultIdString.split(':').pop();

                    entityName =
                      [$scope.entityNameMap[idKey][entityId], locationTag, vaultName].join('');
                    break;
                  default:

                    // TODO: check for NAME string value and use that if present
                    entityName = entityIdName;
                }
              }

              return {
                name: entityName,
                value: entityIdName,
                attributeVecKey: attributeVecKey,
                selected: index === 0
              };
            }
          );

          // finally, sort the list alphabetically
          $scope.entityList = $filter('orderBy')($scope.entityList, 'name');

        },
        evalAJAX.errorMessage
      ).finally(function promiseFulfilled() {
        $scope.fetchingEnties = false;
      });
    }

    /**
     * Fetches list of Entities matching provided partial name.
     * This only works for View names as of ENG-36006 in 6.6.0a.
     *
     * @param  {String}  searchTerm   Partial name of entity.
     */
    $scope.refreshEntities = function refreshEntities(searchTerm) {
      const trimmedSearchTerm = searchTerm.trim();
      if (!trimmedSearchTerm || !$scope.isEntityTypeAheadSearchEnabled) {
        return;
      }
      const keyName = $scope.entitySearchKey;
      _getEntities({ [keyName]: trimmedSearchTerm });
    };

    /**
     * sets the schema in $scope.newPlotOptions and loads the relevant
     * metrics and entities into the lists
     *
     * @param      {Object}  schema  Object from $scope.schemaList
     */
    $scope.schemaSelected = function schemaSelected(schema) {
      $scope.schema = schema;
      $scope.metricList = $filter('orderBy')(schema.timeSeriesDescriptorVec, 'metricDescriptiveName');
      $scope.isEntityTypeAheadSearchEnabled = !!SCHEMA_ENTITY_MAP[schema.name];
      $scope.entitySearchKey = SCHEMA_ENTITY_MAP[schema.name];
      $scope.intervalList = [];
      $scope.currentSelections.metric =
        $scope.currentSelections.interval =
        $scope.newPlotOptions.rollupIntervalSecs = undefined;
      $scope.currentSelections.entities = [];

      const params = { maxEntities: 1000 };
      if ($scope.isEntityTypeAheadSearchEnabled) {
        params[SCHEMA_ENTITY_MAP[schema.name]] = '';
        params.maxEntities = 100;
      }

      _getEntities(params);
    };

    /**
     * Function to update the rollup function list and the interval list.
     *
     * @param {Object} metric The metric
     */
    $scope.updateIntervalAndRollupFunctionLists =
      function updateIntervalAndRollupFunctionLists(metric) {
      const schemaName = $scope.newPlotOptions.schemaName;

      if (schemaName.startsWith('kIris')) {
        $scope.rollupFnList = irisStatsMap[metric.metricName];
      } else if (schemaName === ('kMagnetoAPIStats')) {
        $scope.rollupFnList = magnetoStatsMap[metric.metricName];
      } else {
        $scope.rollupFnList = rollupFnList;
      }

      $scope.updateIntervalList(metric);
    }

    /**
     * updates the interval list based on provided metric. Called from
     * ng-change of currentSelections.metric
     *
     * @param      {Object}  metric      The metric
     * @return     {Array}   list of interval options, for use with
     *                       ui-select
     */
    $scope.updateIntervalList = function updateIntervalList(metric) {

      var intervalList = [];

      // indicates the scaling factor of the base interval for
      // each position in the generated intervalList
      var scalingFactorsHash = {
        90: [1, 6, 10, 20, 40, 960],
        120: [1, 5, 10, 20, 30, 720],
        3600: [1, 6, 12, 24],
        standard: [1, 5, 10, 20, 480],
      };

      var baseInterval = (metric.aggregationDescriptor && metric.aggregationDescriptor.aggregationIntervalSec) ?
        metric.aggregationDescriptor.aggregationIntervalSec :
        StatsService.getDefaultRollupInterval();

      var scalingFactors =
        scalingFactorsHash[baseInterval] || scalingFactorsHash.standard;

      scalingFactors.forEach(
        function scaleInterval(factor) {
          intervalList.push(baseInterval * factor);
        }
      );

      intervalList.push('custom');

      $scope.intervalList = intervalList;
      $scope.currentSelections.interval = baseInterval;
      $scope.newPlotOptions.rollupIntervalSecs = baseInterval;

      return intervalList;

    };

    /**
     * sets the newPlotOptions interval value
     * @param {Object} interval list item from $scope.intervalList
     * @return {Void}
     */
    $scope.setInterval = function setInterval(interval) {
      if (interval === 'custom') {
        $scope.customInterval = true;
        cFocus('customInterval', true);
      } else {
        $scope.customInterval = false;
        $scope.newPlotOptions.rollupIntervalSecs = interval;
      }
    };

    /**
     * if form is valid, this function will call
     * addSeries with the appropriate chart defined
     * @return {Void}
     */
    $scope.addPlot = function addPlot() {
      if ($scope.frmPlotOptions.$invalid) {
        return;
      }

      const plotOptions = angular.copy($scope.newPlotOptions);
      plotOptions.metricName = $scope.currentSelections.metric.metricName;
      // saving metricUnit.type for proper conversions within this controller.
      // it is not needed by the API, but this is a convenient and logical
      // place to store the value
      plotOptions.metricUnitType = $scope.currentSelections.metric.metricUnit.type;
      if (plotOptions.rollupFunction === '') {
        // if no rollup function is defined (raw)
        // then remove rollup related options before
        // options get sent to API
        delete plotOptions.rollupFunction;
        delete plotOptions.rollupIntervalSecs;
      }

      const timeParams = $scope.getTimeParams();

      const plotsToAdd = [];
      $scope.currentSelections.entities.forEach((entity) => {
        const options = angular.copy(plotOptions);
        options.entityId = entity.value;
        options.entityName = entity.name;
        // checking for duplicate plots
        const plotKey = JSON.stringify(options);
        if (activePlots.hasOwnProperty(plotKey)) {
          return;
        }
        // plot options
        activePlots[plotKey] = true;
        options.startTimeMsecs = timeParams.startTimeMsecs;
        options.endTimeMsecs = timeParams.endTimeMsecs;
        options.range = $scope.dateInfo.currentRange;
        plotsToAdd.push(options);
      });

      // if no plots to add, return
      if (plotsToAdd.length === 0) {
          cMessage.info({
            titleKey: 'monitoringDiagnosticsDiagnostics.dupePlot.title',
            textKey: 'monitoringDiagnosticsDiagnostics.dupePlot.text',
            timeout: 4000,
          });
        return;
      };

      if (metricTypesToCharts[$scope.currentSelections.metric.metricUnit.type] && plotOptions.rollupFunction !== 'count') {
        addSeries(metricTypesToCharts[$scope.currentSelections.metric.metricUnit.type], plotsToAdd);
      } else {
        // unrecongized metric type or 'count' rollupFunction,
        // add to general numChart
        addSeries('numChart', plotsToAdd);
      }

      // wrap in $timeout, as the submit button calls the containing
      // addPlot function, which in essence immediately sets the form
      // to submitted upon completion of the function run
      $timeout(function resetFormFn() {
        $scope.frmPlotOptions.$setPristine();
        $scope.frmPlotOptions.$setUntouched();
      });
    };

    /**
     * sets up our parameters based on current values and
     * calls the various functions that handle chart setup
     * @return {Void}
     */
    $scope.updateCharts = function updateCharts() {

      var timeParams = $scope.getTimeParams(true);

      if ($scope.bytesChart.series.length) {
        buildChart('bytesChart', timeParams);
      }

      if ($scope.iopsChart.series.length) {
        buildChart('iopsChart', timeParams);
      }

      if ($scope.msecsChart.series.length) {
        buildChart('msecsChart', timeParams);
      }

      if ($scope.percentageChart.series.length) {
        buildChart('percentageChart', timeParams);
      }

      if ($scope.numChart.series.length) {
        buildChart('numChart', timeParams);
      }

      // always show alerts chart as its our shared axis
      $scope.buildAlertsChart(timeParams);

      if ($scope.show.jobRuns) {
        buildJobRunsChart(timeParams);
      }
    };

    // when the parent controller broadcasts updateCharts message
    // obey the broadcast and run the function
    $scope.$on('updateCharts', $scope.updateCharts);


    /**
     * add a series to the named chart
     * @param {String} chartName name of the chart on $scope (ex: 'bytesChart' = $scope.bytesChart)
     * @param {Array<Object>} plotsToAdd arry of options config object to be provided to API
     */
    function addSeries(chartName, plotsToAdd) {
      plotsToAdd.forEach((plotOptions) => {
        const seriesName = [
          plotOptions.entityName,
          $scope.currentSelections.metric.metricDescriptiveName,
          (plotOptions.rollupFunction || $scope.text.raw)
        ].join(', ');
        const seriesObj = {
          name: seriesName,
          plotOptions: plotOptions,
          color: getUniqueColor(chartName),
        }
        if (plotOptions.rollupFunction) {
          $scope[chartName].series.push({
            ...seriesObj,
            pointInterval: plotOptions.rollupIntervalSecs * 1000,
            pointStart: plotOptions.startTimeMsecs,
          });
        } else {
          $scope[chartName].series.push({ ...seriesObj });
          $scope[chartName].rawSeriesCount++;
          // add placeholder/fake series to chart if not already present
          if ($scope[chartName].rawSeriesCount === 1) {
            // add placeholder series in first position for easy removal
            $scope[chartName].series.unshift(StatsService.getFakeSeries(plotOptions));
          };
        }
      });

      $scope.updateCharts();
    }

    /**
     * loops through the list of colors and returns the first
     * color it finds that is not currently represented in the
     * specified chart.
     * @param  {String} chartName to check for color usage
     * @return {String}           hex color value
     */
    function getUniqueColor(chartName) {
      var numColors = customColors.length;
      var index = 0;
      for (index; index < numColors; index++) {
        if (!$scope[chartName].colorsInUse.hasOwnProperty(customColors[index])) {
          $scope[chartName].colorsInUse[customColors[index]] = true;
          return customColors[index];
        }
      }
    }

    /**
     * remove a series from a chart object
     * @param  {String} chartName name of $scope based chart object
     * @param  {Integer} index    of the series data to be removed
     * @return {Void}
     */
    $scope.removeSeries = function removeSeries(chartName, index) {
      var plotOptions = {};

      // if this chart has a placeholder series, it will be filtered out
      // by ng-repeat and we need to add 1 to the recieved index
      if ($scope[chartName].rawSeriesCount > 0) {
        index++;
      }

      // remove color from our map so it can be reused
      delete $scope[chartName].colorsInUse[$scope[chartName].series[index].color];

      // make a reference copy of plotOptions,
      // so we can still check its values after removing the series
      plotOptions = angular.copy($scope[chartName].series[index].plotOptions);

      // remove the requested series
      $scope[chartName].series.splice(index, 1);

      // determine if we removed  a raw data set,
      // if so, need to reduce the rawSeriesCount property and check if the
      // placeholder series also needs to be removed (if this is/was the only raw set)
      if (!plotOptions.hasOwnProperty('rollupFunction')) {
        $scope[chartName].rawSeriesCount--;
        if ($scope[chartName].rawSeriesCount === 0) {
          // remove placeholder series, which should always
          // be in index position 0 as it was added with unshift()
          $scope[chartName].series.splice(0, 1);
        }
      }

      // normalize the object according to how it was stored in
      // activePlots{} hashmap by setting start and end as null, and
      // removing range property
      plotOptions.startTimeMsecs = null;
      plotOptions.endTimeMsecs = null;
      if (plotOptions.hasOwnProperty('range')) {
        delete plotOptions.range;
      }

      // remove series form activePlots.
      delete activePlots[JSON.stringify(plotOptions)];

      // remove series from selection
      if ($scope.currentSelections.metric === plotOptions.metricName) {
        $scope.currentSelections.entities = $scope.currentSelections.entities?.filter(
          (entity) => entity.value !== plotOptions.entityId
        ) || [];
      }
    };


    /**
     * calls API and constructs data for bytes chart
     *
     * @param      {String}  chartName   name of the chart variable on
     *                                   $scope (ex: 'bytesChart' =
     *                                   $scope.bytesChart)
     * @param      {Object}  timeParams  as returned from
     *                                   StatsService.getTimeRangeParams
     */
    function buildChart(chartName, timeParams) {

      var seriesLen = $scope[chartName].series.length;
      var x = 0;
      var seriesPlotOptions = [];
      var promiseArray = [];
      /** @type {Number} used to offset series indexing when a placholder series is present */
      var placeholderIndexBump = 0;

      // set chart as loading
      $scope[chartName].loading = true;

      for (x; x < seriesLen; x++) {
        $scope[chartName].series[x].data = [];

        // dont add api call for placeholder series,
        // just rebuild the using getFakeSeries
        if ($scope[chartName].series[x].hasOwnProperty('placeholder')) {
          placeholderIndexBump = 1;
          // repopulate placeholder data in case
          // date range or period has changed
          $scope[chartName].series[x] = StatsService.getFakeSeries(timeParams);
          seriesPlotOptions[x] = {};
        } else {
          seriesPlotOptions[x] = $scope[chartName].series[x].plotOptions;
          seriesPlotOptions[x].startTimeMsecs = timeParams.startTimeMsecs;
          seriesPlotOptions[x].endTimeMsecs = timeParams.endTimeMsecs;
          promiseArray.push(StatsService.getTimeSeries(seriesPlotOptions[x]));
        }
      }

      $q.all(promiseArray).then(
        function qAllSuccess(response) {
          var responseLength = response.length;
          var y = 0;
          var dataSet = [];
          var seriesIndex;

          for (y; y < responseLength; y++) {

            dataSet = [];
            seriesIndex = y + placeholderIndexBump;

            if (response[y].data && response[y].data.dataPointVec) {

              dataSet = response[y].data.dataPointVec;

              if (!seriesPlotOptions[seriesIndex].rollupFunction) {
                dataSet = StatsService.buildRawDataSet(dataSet);
              } else {
                // adjust params.rollup based on the saved rollup
                // for the particular series, rather than that provided
                // by our param generating function
                timeParams.rollupIntervalSecs = $scope[chartName].series[seriesIndex].plotOptions.rollupIntervalSecs;
                dataSet = StatsService.buildIntervalDataSet(dataSet, timeParams);
              }

              if (chartName === 'msecsChart') {
                if (seriesPlotOptions[seriesIndex].hasOwnProperty('rollupFunction')) {
                  switch (seriesPlotOptions[seriesIndex].metricUnitType) {
                    case 1:
                      // 1: kTimeUsecs
                      dataSet = dataSet.map(usecsToMsecs);
                      break;
                    case 2:
                      // 2: kTimeMsecs
                      // do nothing, already in msecs
                      break;
                    case 3:
                      // 3: kTimeSecs
                      dataSet = dataSet.map(secsToMsecs);
                      break;
                    case 4:
                      // 4: kTimeMins
                      dataSet = dataSet.map(minsToMsecs);
                      break;
                  }
                } else {
                  dataSet = dataSet.map(rawToMsecsWrapper(seriesPlotOptions[seriesIndex].metricUnitType));
                }
              }

            }
            // update chartConfig object with new values
            $scope[chartName].series[seriesIndex].data = dataSet;
            if (seriesPlotOptions[seriesIndex].hasOwnProperty('rollupFunction')) {

              // Append per second to the yAxis/tooltips if rollup is 'rate'
              if (chartName === 'bytesChart' && seriesPlotOptions[seriesIndex].rollupFunction === 'rate') {
                const textAppendKey = $scope.text.perSec;

                // Only show per sec in the yAxis if all of the metrics have 'rate' as their rollup fns.
                if (seriesPlotOptions.some(opts => opts.rollupFunction !== 'rate')) {
                  $scope[chartName].yAxisLabelAppend = null;
                } else {
                  $scope[chartName].yAxisLabelAppend = textAppendKey;
                }

                $scope[chartName].series[seriesIndex].tooltipValueAppend = textAppendKey;
              }

              $scope[chartName].series[seriesIndex].pointInterval = timeParams.rollupIntervalSecs * 1000;
              $scope[chartName].series[seriesIndex].pointStart = timeParams.startTimeMsecs;
            }
          }
        },
        evalAJAX.errorMessage
      ).finally(
        function qAllFinally() {
          $scope[chartName].loading = false;
        }
      );
    }

    /**
     * calls API and builds a job runs data set for charting
     * @param  {Object} params params as returned from StatsService.getTimeRangeParams
     * @return {Void}
     */
    function buildJobRunsChart(timeParams) {
      var jobRunsParams = {
        excludeTasks: true,
        startTimeUsecs: timeParams.startTimeMsecs * 1000, // ms to usecs
        endTimeUsecs: timeParams.endTimeMsecs * 1000 // ms to usecs
      };

      $scope.jobRunsChart.series = [];
      $scope.jobRunsChart.loading = true;

      JobRunsService.getJobRuns(jobRunsParams).then(
        function getJobRunsSuccess(jobs) {
          var chartJobs = [];

          angular.forEach(jobs, function(currentJob, jobIndex) {
            var chartJob = {
              name: currentJob.backupJobRuns.jobDescription.name,
              data: []
            };
            // reverse the runs array, as the API returns newest first
            // and highcharts expects data to be provided oldest first
            currentJob.backupJobRuns.protectionRuns.reverse();
            angular.forEach(currentJob.backupJobRuns.protectionRuns, function(currentRun, runIndex) {
              chartJob.data.push({
                x: currentRun.backupRun.base.startTimeUsecs / 1000,
                y: jobIndex + 1,
                from: currentRun.backupRun.base.startTimeUsecs / 1000,
                to: currentRun.backupRun.base.endTimeUsecs / 1000 || new Date().getTime()
              }, {
                x: currentRun.backupRun.base.endTimeUsecs / 1000 || new Date().getTime(),
                y: jobIndex + 1,
                from: currentRun.backupRun.base.startTimeUsecs / 1000,
                to: currentRun.backupRun.base.endTimeUsecs / 1000 || new Date().getTime()
              });
              // add a null value between intervals to create line disconnect
              if (currentJob.backupJobRuns.protectionRuns[runIndex + 1]) {
                chartJob.data.push(
                  [(currentRun.backupRun.base.endTimeUsecs + currentJob.backupJobRuns.protectionRuns[runIndex + 1].startTimeUsecs) / (2 * 1000), null]
                );
              }
            });
            chartJobs.push(chartJob);
          });
          // add a fake series to lineup our job runs on the shared xaxis
          chartJobs.unshift(StatsService.getFakeSeries(timeParams));
          $scope.jobRunsChart.series = chartJobs;
          // height should be the number of plotted jobs * 9px height of line,
          // + 30px to allow some breating room
          $scope.jobRunsChart.chart.height = (chartJobs.length - 1) * 9 + 30;
        },
        function getJobRunsFailure(response) {
          evalAJAX.errorMessage(response);
          $scope.show.jobRuns = false;
        }
      ).finally(
        function getJobRunsFinally() {
          $scope.jobRunsChart.loading = false;
        }
      );
    }

    /**
     * used for mapping usec data arrays into msec values
     * @param  {Integer} datapoint microseconds value to be converted
     * @return {Float}           milliseconds
     */
    function usecsToMsecs(datapoint) {
      // if value null or 0 return unchanged
      if (!datapoint) {
        return datapoint;
      } else {
        return datapoint / 1000;
      }
    }

    /**
     * used for mapping seconds data arrays into msec values
     * @param  {Integer} datapoint microseconds value to be converted
     * @return {Float}           milliseconds
     */
    function secsToMsecs(datapoint) {
      // if value null or 0 return unchanged
      if (!datapoint) {
        return datapoint;
      } else {
        return datapoint * 1000;
      }
    }

    /**
     * used for mapping minute data arrays into msec values
     * @param  {Integer} datapoint micro value to be converted
     * @return {Float}             milliseconds
     */
    function minsToMsecs(datapoint) {
      // if value null or 0 return unchanged
      if (!datapoint) {
        return datapoint;
      } else {
        // 60 seconds in a minute converted to msecs
        return secsToMsecs(datapoint * 60);
      }
    }

    /**
     * used for mapping RAW usec data arrays into msec values
     *
     * @param      {Array}   datapoint       [timestamp, timeValue]
     * @param      {Integer} metricUnitType  The metric unit type
     * @return     {Array}   [timestamp, msecValue]
     */
    function rawToMsecs(datapoint, metricUnitType) {
      var out = [datapoint[0]];
      switch (metricUnitType) {
        case 1:
          // 1: kTimeUsecs
          out.push(usecsToMsecs(datapoint[1]));
          break;
        case 2:
          // 2: kTimeMsecs
          out.push(datapoint[1]);
          break;
        case 3:
          // 3: kTimeSecs
          out.push(secsToMsecs(datapoint[1]));
          break;
        case 4:
          // 4: kTimeMins
          out.push(minsToMsecs(datapoint[1]));
          break;
      }
      return out;
    }

    /**
     * pass through function for using rawToMsecs() via Array.prototype.map()
     * from within a loop while providing a reference to the metricUnitType thats
     * associated with the particular loop item
     *
     * @param      {Integer}  metricUnitType  The metric unit type
     * @return     {Function} mapping function that calls rawToMsecs
     */
    function rawToMsecsWrapper(metricUnitType) {
      return function mapRawFn(datapoint) {
        return rawToMsecs(datapoint, metricUnitType);
      };
    }

    /**
     * used to filter out placeholder facets in the template ng-repeats
     * @param  {Object}  series object from chart configs
     * @return {Boolean}        weather the series is a placeholder or not
     */
    $scope.isNotPlaceholder = function isNotPlaceholder(series) {
      return !series.hasOwnProperty('placeholder');
    };

    /**
     * look up disk information and add it to the entityNameMap
     * for better entity dropdown names
     * @return {Void}
     */
    function getDiskNames() {
      var params = {
        includeMarkedForRemoval: true
      };
      DiskService.getDisks(params).then(
        function getDisksSuccess(response) {
          angular.forEach(response.data, function(disk) {

            var diskName = [
              $scope.text.diskLabel, disk.id,
              ' ',
              disk.storageTier,
              ' (',
              disk.currentNodeIp,
              ')'
            ].join('');

            $scope.entityNameMap.DiskId[disk.id] = diskName;
          });
        }
        // no error handling. worst case scenario the disk IDs are displayed
      );
    }

    /**
     * gets the names of internal viewboxes for display in entity dropdown.
     * names are added to $scope.entityNameMap as created and setup in _statistics.js
     * @return {Void}
     */
    function getInternalViewBoxes() {
      ViewBoxService.getViewBoxes({
        includeHidden: true
      }).then(
        function getViewBoxesSuccess(viewBoxes) {
          angular.forEach(viewBoxes, function viewBoxesForEach(viewBox) {
            var viewBoxName = [$scope.text.viewboxLabel, viewBox.name].join('');
            $scope.entityNameMap.ViewBoxId[viewBox.id] = viewBoxName;
          });
        }
      );
    }

    /**
     * gets the names of internal viewboxes for display in entity dropdown.
     * names are added to $scope.entityNameMap as created and setup in _statistics.js
     * @return {Void}
     */
    function getExternalTargets() {
      ExternalTargetService.getTargets(null, {includeMarkedForRemoval: true}).then(
        function getTargetsSuccess(extTargets) {
          extTargets.forEach(function viewBoxesForEach(extTarget) {
            var targetName = [$scope.text.externalTargetLabel, extTarget.name].join('');
            if (extTarget.removalState === 'kMarkedForRemoval') {
              targetName += (' (' + $translate.instant('unregistered') + ')');
            }
            $scope.entityNameMap.VaultId[extTarget.id] = targetName;
          });
        }
      );
    }

    /**
     * Sets the function for displaying workload Ids in entity dropdown.
     * @return {Void}
     */
    function setWorkloadTypeMapping() {
      $scope.entityNameMap.ClusterWorkloadId = function clusterWorkloadId(workloadId) {
        var keys;
        if (workloadId) {
          keys = workloadId.split(':');
          if (keys && keys.length > 0 && $scope.entityNameMap.hasOwnProperty('ClusterId') &&
            $scope.entityNameMap.ClusterId.hasOwnProperty(keys[0])) {
            keys[0] = $scope.entityNameMap.ClusterId[keys[0]];
            return keys.join(':');
          }
        }
        return workloadId;
      };
      $scope.entityNameMap.ViewBoxWorkloadId = function viewBoxWorkloadId(workloadId) {
        var keys;
        if (workloadId) {
          keys = workloadId.split(':');
          if (keys && keys.length > 0 && $scope.entityNameMap.hasOwnProperty('ViewBoxId') &&
            $scope.entityNameMap.ViewBoxId.hasOwnProperty(keys[0])) {
            keys[0] = $scope.entityNameMap.ViewBoxId[keys[0]];
            return keys.join(':');
          }
        }
        return workloadId;
      };
    }

    /**
     * function to load the list of available schemas from the API
     * this is only run once, on initial page load
     * @return {Void}
     */
    function loadSchemas() {
      var schemasOpts = {
        includeInternalSchemas: !!$state.params.internal
      };
      StatsService.getEntitiesSchema(schemasOpts).then(
        function getEntitiesSchemaSuccess(schemas) {
          const irisMagnetoStatsSchemas = [
            'kIrisGetAPIStats', 'kIrisPostAPIStats', 'kIrisPutAPIStats',
            'kIrisDeleteAPIStats', 'kMagnetoAPIStats'];
          if (!FEATURE_FLAGS.irisMagnetoStatsInAdvDiagTool) {
            schemas = schemas.filter(
              schema => !irisMagnetoStatsSchemas.includes(schema.name));
          }
          $scope.schemaList = $filter('orderBy')(schemas, 'schemaDescriptiveName');
        },
        evalAJAX.errorMessage
      );
    }

    // load schemas and update charts to get things going
    loadSchemas();
    getDiskNames();
    getInternalViewBoxes();
    getExternalTargets();
    setWorkloadTypeMapping();
    $scope.updateCharts();
  }

}(angular));
