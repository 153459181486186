import { Component, Input } from '@angular/core';
import { StorageDomainServiceApi } from '@cohesity/api/v2';
import { ValueFilterSelection, FiltersComponent } from '@cohesity/helix';
import { IrisContextService, isDmsUser } from '@cohesity/iris-core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseObjectFilterComponent } from './../base-object-filter';

/**
 * This is a reusable filter that can be used to filter for storage domains
 */
@Component({
  selector: 'coh-storage-domain-filter',
  templateUrl: './storage-domain-filter.component.html',
})
export class StorageDomainFilterComponent extends BaseObjectFilterComponent<StorageDomainServiceApi.GetStorageDomainsParams> {
  /**
   * The name of the property containing the storage domain id.
   */
  @Input() property = 'storageDomain';

  /**
   * Storage domains are not used at all in dmaas. Use this check to completely disable this filter for dmaas.
   */
  get isDmsUser(): boolean {
    return isDmsUser(this.irisContext.irisContext);
  }

  constructor(
    private irisContext: IrisContextService,
    private storageDomainService: StorageDomainServiceApi,
    filters: FiltersComponent
  ) {
    super(filters);
  }

  /**
   * Fetch storage domains when the component initializes or the params change.
   */
  getFilterValues(): Observable<ValueFilterSelection[]> {
    if (this.isDmsUser) {
      return of([]);
    }
    const params = {
      includeTenants: true,
      ...(this.requestParams || {}),
    };
    return this.storageDomainService.GetStorageDomains(params).pipe(
      map(resp =>
        resp?.storageDomains.map(viewBox => ({
          label: viewBox.name,
          value: viewBox.id,
        }))
      )
    );
  }
}
