/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityReportingApiConfiguration as __Configuration } from '../cohesity-reporting-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Schedules } from '../models/schedules';
import { Schedule } from '../models/schedule';
import { UpdateSchedulesState } from '../models/update-schedules-state';
import { UpdateSchedulesStateRequest } from '../models/update-schedules-state-request';
import { SchedulesDao } from '../models/schedules-dao';
import { ScheduleDao } from '../models/schedule-dao';
@Injectable({
  providedIn: 'root',
})
class SchedulesService extends __BaseService {
  static readonly GetSchedulesPath = '/public/schedules';
  static readonly CreateSchedulePath = '/public/schedules';
  static readonly GetScheduleByIdPath = '/public/schedules/{id}';
  static readonly UpdateSchedulePath = '/public/schedules/{id}';
  static readonly DeleteSchedulePath = '/public/schedules/{id}';
  static readonly RunScheduleOnDemandPath = '/public/schedules/{id}/run';
  static readonly UpdateSchedulesStatePath = '/public/schedules/state';
  static readonly GetActiveSchedulesPath = '/private/schedules';
  static readonly GetScheduleDaoByIdPath = '/private/schedules/{id}';
  static readonly UpdateScheduleDaoPath = '/private/schedules/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List all schedules.
   *
   * Fetches list of all schedules accessible to the logged in user.
   * @param params The `SchedulesService.GetSchedulesParams` containing the following parameters:
   *
   * - `state`: The state of schedules which should be fetched. Can be Active/Inactive. If this is nil, all schedules are returned.
   *
   * - `reportIds`: The reportIds for which schedules need to be fetched.
   *
   * - `includeLatestTaskInfo`: Specifies whether to include latest task information. If not specified or false, latest task information will not be sent.
   *
   * - `ids`: "The Ids for which schedules need to be fetched."
   *
   * - `emailIds`: The email ids for which schedules need to be fetched.
   *
   * @return Success
   */
  GetSchedulesResponse(params: SchedulesService.GetSchedulesParams): __Observable<__StrictHttpResponse<Schedules>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.state != null) __params = __params.set('state', params.state.toString());
    (params.reportIds || []).forEach(val => {if (val != null) __params = __params.append('reportIds', val.toString())});
    if (params.includeLatestTaskInfo != null) __params = __params.set('includeLatestTaskInfo', params.includeLatestTaskInfo.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.emailIds || []).forEach(val => {if (val != null) __params = __params.append('emailIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/schedules`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Schedules>;
      })
    );
  }
  /**
   * List all schedules.
   *
   * Fetches list of all schedules accessible to the logged in user.
   * @param params The `SchedulesService.GetSchedulesParams` containing the following parameters:
   *
   * - `state`: The state of schedules which should be fetched. Can be Active/Inactive. If this is nil, all schedules are returned.
   *
   * - `reportIds`: The reportIds for which schedules need to be fetched.
   *
   * - `includeLatestTaskInfo`: Specifies whether to include latest task information. If not specified or false, latest task information will not be sent.
   *
   * - `ids`: "The Ids for which schedules need to be fetched."
   *
   * - `emailIds`: The email ids for which schedules need to be fetched.
   *
   * @return Success
   */
  GetSchedules(params: SchedulesService.GetSchedulesParams): __Observable<Schedules> {
    return this.GetSchedulesResponse(params).pipe(
      __map(_r => _r.body as Schedules)
    );
  }

  /**
   * Create a Schedule
   *
   * Create a schedule for a configured report.
   * @param body Request body params in order to schedule a given report.
   * @return Success
   */
  CreateScheduleResponse(body: Schedule): __Observable<__StrictHttpResponse<Schedule>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/schedules`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Schedule>;
      })
    );
  }
  /**
   * Create a Schedule
   *
   * Create a schedule for a configured report.
   * @param body Request body params in order to schedule a given report.
   * @return Success
   */
  CreateSchedule(body: Schedule): __Observable<Schedule> {
    return this.CreateScheduleResponse(body).pipe(
      __map(_r => _r.body as Schedule)
    );
  }

  /**
   * Fetch a Schedule
   *
   * Get a report schedule for a given id.
   * @param id Specifies the id of the schedule.
   * @return Success
   */
  GetScheduleByIdResponse(id: string): __Observable<__StrictHttpResponse<Schedule>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/schedules/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Schedule>;
      })
    );
  }
  /**
   * Fetch a Schedule
   *
   * Get a report schedule for a given id.
   * @param id Specifies the id of the schedule.
   * @return Success
   */
  GetScheduleById(id: string): __Observable<Schedule> {
    return this.GetScheduleByIdResponse(id).pipe(
      __map(_r => _r.body as Schedule)
    );
  }

  /**
   * Update a Schedule
   *
   * Update schedule for a given id.
   * @param params The `SchedulesService.UpdateScheduleParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the schedule.
   *
   * - `body`: Request body params in order to update a schedule.
   *
   * @return Success
   */
  UpdateScheduleResponse(params: SchedulesService.UpdateScheduleParams): __Observable<__StrictHttpResponse<Schedule>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/schedules/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Schedule>;
      })
    );
  }
  /**
   * Update a Schedule
   *
   * Update schedule for a given id.
   * @param params The `SchedulesService.UpdateScheduleParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the schedule.
   *
   * - `body`: Request body params in order to update a schedule.
   *
   * @return Success
   */
  UpdateSchedule(params: SchedulesService.UpdateScheduleParams): __Observable<Schedule> {
    return this.UpdateScheduleResponse(params).pipe(
      __map(_r => _r.body as Schedule)
    );
  }

  /**
   * Delete a Schedule
   *
   * Delete schedule for a given id.
   * @param id Specifies the id of the schedule to delete.
   */
  DeleteScheduleResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/schedules/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a Schedule
   *
   * Delete schedule for a given id.
   * @param id Specifies the id of the schedule to delete.
   */
  DeleteSchedule(id: string): __Observable<null> {
    return this.DeleteScheduleResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Run a schedule on demand.
   *
   * Runs the schedule on the demand.
   * @param id Specifies the id of the schedule.
   */
  RunScheduleOnDemandResponse(id: null | string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/schedules/${encodeURIComponent(id)}/run`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Run a schedule on demand.
   *
   * Runs the schedule on the demand.
   * @param id Specifies the id of the schedule.
   */
  RunScheduleOnDemand(id: null | string): __Observable<null> {
    return this.RunScheduleOnDemandResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update Schedules State
   *
   * Update report schedules state.
   * @param body Request body params to update schedules state.
   * @return Success
   */
  UpdateSchedulesStateResponse(body: UpdateSchedulesStateRequest): __Observable<__StrictHttpResponse<UpdateSchedulesState>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/schedules/state`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateSchedulesState>;
      })
    );
  }
  /**
   * Update Schedules State
   *
   * Update report schedules state.
   * @param body Request body params to update schedules state.
   * @return Success
   */
  UpdateSchedulesState(body: UpdateSchedulesStateRequest): __Observable<UpdateSchedulesState> {
    return this.UpdateSchedulesStateResponse(body).pipe(
      __map(_r => _r.body as UpdateSchedulesState)
    );
  }

  /**
   * Get active schedules.
   *
   * Get all active schedules given a current time.
   * @param params The `SchedulesService.GetActiveSchedulesParams` containing the following parameters:
   *
   * - `toleranceHours`: Specifies the maximum amount of time in hours which the scheduler can be down without missing schedules. Will fetch all active schedules between CurrentTime and CurrentTime-ToleranceHours. ToleranceHours can maximum be 24. Defaults to 0 (30 mins) if nil.
   *
   * - `currentTimeSecs`: "Specifies the current time in Secs from which to fetch active
   *   schedules. Fetches all active schedules within a specified
   *   tolerance period."
   *
   * @return Success
   */
  GetActiveSchedulesResponse(params: SchedulesService.GetActiveSchedulesParams): __Observable<__StrictHttpResponse<SchedulesDao>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.toleranceHours != null) __params = __params.set('toleranceHours', params.toleranceHours.toString());
    if (params.currentTimeSecs != null) __params = __params.set('currentTimeSecs', params.currentTimeSecs.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/private/schedules`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SchedulesDao>;
      })
    );
  }
  /**
   * Get active schedules.
   *
   * Get all active schedules given a current time.
   * @param params The `SchedulesService.GetActiveSchedulesParams` containing the following parameters:
   *
   * - `toleranceHours`: Specifies the maximum amount of time in hours which the scheduler can be down without missing schedules. Will fetch all active schedules between CurrentTime and CurrentTime-ToleranceHours. ToleranceHours can maximum be 24. Defaults to 0 (30 mins) if nil.
   *
   * - `currentTimeSecs`: "Specifies the current time in Secs from which to fetch active
   *   schedules. Fetches all active schedules within a specified
   *   tolerance period."
   *
   * @return Success
   */
  GetActiveSchedules(params: SchedulesService.GetActiveSchedulesParams): __Observable<SchedulesDao> {
    return this.GetActiveSchedulesResponse(params).pipe(
      __map(_r => _r.body as SchedulesDao)
    );
  }

  /**
   * Fetch a scheduleDao
   *
   * Fetch a scheduleDao for a given id.
   * @param id Specifies the id of the schedule.
   * @return Success
   */
  GetScheduleDaoByIdResponse(id: string): __Observable<__StrictHttpResponse<ScheduleDao>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/private/schedules/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ScheduleDao>;
      })
    );
  }
  /**
   * Fetch a scheduleDao
   *
   * Fetch a scheduleDao for a given id.
   * @param id Specifies the id of the schedule.
   * @return Success
   */
  GetScheduleDaoById(id: string): __Observable<ScheduleDao> {
    return this.GetScheduleDaoByIdResponse(id).pipe(
      __map(_r => _r.body as ScheduleDao)
    );
  }

  /**
   * Update a ScheduleDao
   *
   * Update ScheduleDao for a given id. Currently, this can only modify the last queued timestamp of the schedule. Other fields are ignored.
   * @param params The `SchedulesService.UpdateScheduleDaoParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the schedule.
   *
   * - `body`: Request body params in order to update a schedule.
   *
   * @return Success
   */
  UpdateScheduleDaoResponse(params: SchedulesService.UpdateScheduleDaoParams): __Observable<__StrictHttpResponse<ScheduleDao>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/private/schedules/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ScheduleDao>;
      })
    );
  }
  /**
   * Update a ScheduleDao
   *
   * Update ScheduleDao for a given id. Currently, this can only modify the last queued timestamp of the schedule. Other fields are ignored.
   * @param params The `SchedulesService.UpdateScheduleDaoParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the schedule.
   *
   * - `body`: Request body params in order to update a schedule.
   *
   * @return Success
   */
  UpdateScheduleDao(params: SchedulesService.UpdateScheduleDaoParams): __Observable<ScheduleDao> {
    return this.UpdateScheduleDaoResponse(params).pipe(
      __map(_r => _r.body as ScheduleDao)
    );
  }
}

module SchedulesService {

  /**
   * Parameters for GetSchedules
   */
  export interface GetSchedulesParams {

    /**
     * The state of schedules which should be fetched. Can be Active/Inactive. If this is nil, all schedules are returned.
     */
    state?: null | 'Active' | 'Inactive';

    /**
     * The reportIds for which schedules need to be fetched.
     */
    reportIds?: null | Array<string>;

    /**
     * Specifies whether to include latest task information. If not specified or false, latest task information will not be sent.
     */
    includeLatestTaskInfo?: null | boolean;

    /**
     * "The Ids for which schedules need to be fetched."
     */
    ids?: null | Array<string>;

    /**
     * The email ids for which schedules need to be fetched.
     */
    emailIds?: null | Array<string>;
  }

  /**
   * Parameters for UpdateSchedule
   */
  export interface UpdateScheduleParams {

    /**
     * Specifies the id of the schedule.
     */
    id: string;

    /**
     * Request body params in order to update a schedule.
     */
    body: Schedule;
  }

  /**
   * Parameters for GetActiveSchedules
   */
  export interface GetActiveSchedulesParams {

    /**
     * Specifies the maximum amount of time in hours which the scheduler can be down without missing schedules. Will fetch all active schedules between CurrentTime and CurrentTime-ToleranceHours. ToleranceHours can maximum be 24. Defaults to 0 (30 mins) if nil.
     */
    toleranceHours?: null | number;

    /**
     * "Specifies the current time in Secs from which to fetch active
     * schedules. Fetches all active schedules within a specified
     * tolerance period."
     */
    currentTimeSecs?: null | number;
  }

  /**
   * Parameters for UpdateScheduleDao
   */
  export interface UpdateScheduleDaoParams {

    /**
     * Specifies the id of the schedule.
     */
    id: string;

    /**
     * Request body params in order to update a schedule.
     */
    body: ScheduleDao;
  }
}

export { SchedulesService }
