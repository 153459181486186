/**
 * List of global state params. These are applied to all ui router states defined in the application
 */
export const globalStateParams = {
  // cid param is used to change/establish cluster context.
  cid: {
    type: 'int',
    value: null,
    squash: true,
    _persistOnImpersonation: true,
  },

  // serviceType param is used to specify a specific application service or scope to switch to.
  serviceType: {
    type: 'string',
    value: null,
    squash: true,
  },

  // lockClusterId is used on app load to establish a UI lock on a
  // particular cluster. This will
  lockClusterId: {
    type: 'int',
    value: null,
    squash: true,
    _persistOnImpersonation: true,
  },

  // isAuthenticated is used to determine whether user is authenticated.
  isAuthenticated: {
    type: 'bool',
    value: false,
    squash: true,
    _persistOnImpersonation: true,
  },

  // heliosAuthenticated is used to determine whether user is authenticated.
  heliosAuthenticated: {
    type: 'bool',
    value: false,
    squash: true,
    _persistOnImpersonation: true,
  },

  // This is passed in for logout state to redirect the user back to IBM Hub.
  redirect: {
    type: 'string',
    _persistOnImpersonation: false,
  },

  // This is passed in for logout state to force the redirect in debug/development.
  forceRedirect: {
    type: 'bool',
    _persistOnImpersonation: false,
  },

  // impersonatedOrgId is used to preserve impersonation over reload
  impersonatedOrgId: {
    type: 'string',
    squash: true,
    _persistOnImpersonation: true,
  },

  // syncLocalImpersonationToUrl is used to sync local impersonation status to url
  //  used when local impersonation is removed
  syncLocalImpersonationToUrl: {
    type: 'bool',
    squash: true,

    // adding default value null to make this global param optional which is
    // not persisted in the url and without this default value, scope
    // selection doesn't work properly. issue details can be found at
    // 1. https://jira.cohesity.com/browse/FI-18023
    value: null,
    _persistInUrl: false,
    _persistOnImpersonation: true,
  },

  // orgId is used to preserve impersonation over reload
  orgId: {
    type: 'string',
    squash: true,
    _persistOnImpersonation: true,
  },

  // syncLocalSwitchAccountToUrl is used to sync local switchAccount to url
  //  used when local switch is removed
  syncLocalSwitchAccountToUrl: {
    type: 'bool',
    squash: true,

    // adding default value null to make this global param optional which is
    // not persisted in the url and without this default value, scope
    // selection doesn't work properly. issue details can be found at
    // 1. https://jira.cohesity.com/browse/FI-18023
    value: null,
    _persistInUrl: false,
    _persistOnImpersonation: true,
  },

  // regionId is used to make API calls which are region specific for a
  // DmaaS user.
  regionId: {
    type: 'string',
    squash: true,
    _persistOnImpersonation: true,
  },

  // This param is only set when the UI is opened in a start product tour mode.
  // When this param is set, HeliosAnalyticsService will use it to start the
  // corresponding product tour.
  product_tour_id: {
    type: 'int',
    squash: true,
    _persistOnImpersonation: false,
  },
};
