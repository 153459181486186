import { BmrSchedule, FullSchedule, IncrementalSchedule, LogSchedule } from '@cohesity/api/v2';

import { Granularity, GranularityInverse } from '../shared/constants';
import { ScheduleSelectorValue } from '@cohesity/shared-forms';
import { TimePeriodValue } from '@cohesity/utils';

/**
 * Type for schedule unit.
 */
export type ScheduleUnit = 'Minutes' | 'Hours' | 'Days' | 'Weeks' | 'Months' | 'Years';

/**
 * Type for week of month for month schedule.
 */
export type WeekOfMonth = 'First' | 'Second' | 'Third' | 'Fourth' | 'Last';

/**
 * Type of day of year in year schedule.
 */
export type DayOfYear = 'First' | 'Last';

/**
 * Type for run schedule of incremental, full, log, and bmr backups.
 */
export type RunSchedule = IncrementalSchedule | FullSchedule | LogSchedule | BmrSchedule;

/**
 * Utility function to convert ScheduleSelectorValue formModel to a RunSchedule API model.
 *
 * @param   formModel  ScheduleSelectorValue FormModel.
 * @return  RunSchedule model or undefined if formModel is invalid.
 */
export function toRunSchedule(formModel: ScheduleSelectorValue): RunSchedule {
  if (!formModel || !formModel.timePeriod) {
    return;
  }

  const { type, value: frequency = 1 } = formModel.timePeriod;

  const schedule: RunSchedule = {
    unit: type as ScheduleUnit,
  };

  switch (type) {
    case 'Minutes':
      schedule.minuteSchedule = { frequency };
      break;
    case 'Hours':
      schedule.hourSchedule = { frequency };
      break;
    case 'Days':
      schedule.daySchedule = { frequency };
      break;
    case 'Weeks':
      schedule.weekSchedule = {
        dayOfWeek: formModel.days
      };
      break;
    case 'Months':
      if (formModel.dayCount?.type === 'Date') {
        schedule.monthSchedule = {
          dayOfMonth: formModel.dayOfMonth,
        };
      } else {
        schedule.monthSchedule = {
          dayOfWeek: [formModel.day],
          weekOfMonth: formModel.dayCount?.type as WeekOfMonth,
        };
      }
      break;
    case 'Years':
      schedule.yearSchedule = {
        dayOfYear: formModel.dayOfYear?.type as DayOfYear,
      };
      break;
  }

  return schedule;
}

/**
 * Utility function to convert RunSchedule API module to a ScheduleSelectorValue formModel.
 *
 * @param   schedule  RunSchedule from API.
 * @return  SchedulingPolicy model or undefined if schedule is invalid.
 */
export function fromRunSchedule(schedule: RunSchedule): ScheduleSelectorValue {
  if (!schedule) {
    return;
  }
  let scheduleSelector: ScheduleSelectorValue;

  const { unit } = schedule;

  switch (unit) {
    case 'Minutes': {
      const { minuteSchedule } = schedule as IncrementalSchedule | LogSchedule;
      scheduleSelector = {
        timePeriod: { type: unit, value: minuteSchedule.frequency }
      };
      break;
    }
    case 'Hours': {
      const { hourSchedule } = schedule as IncrementalSchedule | LogSchedule;
      scheduleSelector = {
        timePeriod: { type: unit, value: hourSchedule.frequency }
      };
      break;
    }
    case 'Days': {
      const { daySchedule } = schedule as IncrementalSchedule | FullSchedule;
      scheduleSelector = {
        timePeriod: { type: unit, value: daySchedule.frequency }
      };
      break;
    }
    case 'Weeks': {
      const { weekSchedule } = schedule as IncrementalSchedule | FullSchedule | BmrSchedule;
      scheduleSelector = {
        timePeriod: { type: unit },
        days: weekSchedule.dayOfWeek,
      };
      break;
    }
    case 'Months': {
      const { monthSchedule } = schedule as IncrementalSchedule | FullSchedule | BmrSchedule;
      if (monthSchedule.dayOfMonth) {
        scheduleSelector = {
          timePeriod: { type: unit },
          dayCount: { type: 'Date' },
          dayOfMonth: monthSchedule.dayOfMonth,
        };
      } else if (monthSchedule.weekOfMonth && monthSchedule.dayOfWeek) {
        scheduleSelector = {
          timePeriod: { type: unit },
          dayCount: {
            type: monthSchedule.weekOfMonth,
          },
          day: monthSchedule.dayOfWeek[0],
        };
      }
      break;
    }
    case 'Years': {
      const { yearSchedule } = schedule as IncrementalSchedule;
      scheduleSelector = {
        timePeriod: { type: unit },
        dayOfYear: {
          type: yearSchedule.dayOfYear,
        },
      };
      break;
    }
  }

  return scheduleSelector;
}

/**
 * Converts retention API model to TimePeriodValue
 *
 * @param   retention   Retention API model.
 * @param   granularType Optional param, If the type is granular or not
 * @return  Corresponding TimePeriodValue.
 */
export function retentionToTimePeriod(retention: any, granularType?: boolean): TimePeriodValue {
  if (!retention) {
    return;
  }
  const { duration, unit } = retention;

  return { type: granularType ? GranularityInverse[unit] : unit, value: duration };
}

/**
 * Converts timePeriodValue to retention API model.
 *
 * @param   retention    TimePeriodValue.
 * @param   granularType Optional param, If the type should be granular or not
 * @return  Corresponding Retention model.
 */
export function timePeriodToRetention(timePeriod: TimePeriodValue, granularType?: boolean): any {
  if (!timePeriod) {
    return;
  }
  const { type, value } = timePeriod;

  return { unit: granularType ? Granularity[type] : type, duration: value };
}

/**
 * Get the duration of a schedule.
 *
 * @param schedule The schedule.
 * @returns The schedule duration in minutes.
 */
export function getScheduleDuration(schedule: IncrementalSchedule | LogSchedule): number {
  const scheduleUnitMap: Record<
    IncrementalSchedule['unit'] | LogSchedule['unit'],
    { key: string; multiplier: number }
  > = {
    Minutes: { key: 'minuteSchedule', multiplier: 1 },
    Hours: { key: 'hourSchedule', multiplier: 60 },
    Days: { key: 'daySchedule', multiplier: 60 * 24 },
    Weeks: { key: 'weekSchedule', multiplier: 60 * 24 * 7 },
    Months: { key: 'monthSchedule', multiplier: 60 * 24 * 30 },
    Years: { key: 'yearSchedule', multiplier: 60 * 24 * 365 },
  };
  const scheduleValue = scheduleUnitMap[schedule?.unit];

  if (!scheduleValue) {
    return;
  }

  return schedule[scheduleValue.key].frequency * scheduleValue.multiplier;
}
