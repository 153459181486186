// Disk Service

;(function(angular, undefined) {

  angular.module('C.diskService', [])
    .service('DiskService', diskServiceFn);

  function diskServiceFn($http, API) {

    return {
      getDisks: getDisks
    };

    function getDisks() {
      return $http({
        method: 'get',
        url: API.private('disks'),
      });
    }
  }

})(angular);
