<!-- NFS All Squash options -->
<div>
  <h4 class="no-margin" *ngIf="label">{{label}}
    <cog-icon
      inline="true"
      size="sm"
      class="padding-left-sm"
      shape="helix:status-info!outline"
      [matTooltip]="(label === 'All Squash' ? 'allSquashHint' : 'rootSquashHint') | translate"
      matTooltipPosition="right">
    </cog-icon>
  </h4>
  <div>
    <mat-form-field>
      <mat-label translate>userIdUid</mat-label>
      <input
        type="number"
        matInput
        min="0"
        cogDataId="nfs-{{label}}-uid-field"
        [inheritDataId]="true"
        [formControl]="formGroupControls.uid">
      <mat-error cogDataId="nfs-{{label}}-invalid-text"
        *ngIf="formGroupControls.uid.hasError('required')">
        {{ 'errors.required' | translate }}
      </mat-error>
      <mat-error cogDataId="nfs-{{label}}-invalid-text"
        *ngIf="formGroupControls.uid.hasError('min')">
        {{ 'errors.positiveInteger' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="padding-left">
      <mat-label translate>groupIdGid</mat-label>
      <input
        type="number"
        matInput
        min="0"
        [inheritDataId]="true"
        cogDataId="nfs-{{label}}-gid-field"
        [formControl]="formGroupControls.gid">
      <mat-error cogDataId="nfs-{{label}}-invalid-text"
        *ngIf="formGroupControls.gid.hasError('required')">
        {{ 'errors.required' | translate }}
      </mat-error>
      <mat-error cogDataId="nfs-{{label}}-invalid-text"
        *ngIf="formGroupControls.gid.hasError('min')">
        {{ 'errors.positiveInteger' | translate }}
      </mat-error>
    </mat-form-field>
    <cog-banner status="warn" class="item" *ngIf="showAllSquashWarning">
      <p>{{ 'allSquashWarning' | translate }}</p>
    </cog-banner>
  </div>
</div>
