import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { Resource } from '@cohesity/api/guardian';
import { DataIdModule, FormsModule } from '@cohesity/helix';
import { CohesitySharedFormsModule, NoEmptyArrayValidator } from '@cohesity/shared-forms';
import { ClearSubscriptions } from '@cohesity/utils';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { ResourceNode } from '../../models';
import { transformArrayToTree } from '../../utils/resource-tree.utils';
import { ResourceSelectionDetailsComponent } from '../resource-selection-details/resource-selection-details.component';
import { ResourceTreeTableComponent } from '../resources-tree-table/resources-tree-table.component';

/**
 * The interface to pass request data to this dialog.
 */
export interface ResourcesDialogData {
  /**
   * IDs of selected resources
   */
  resourceIds?: string[];

  /**
   * List of resources
   */
  resources: Resource[];

  /**
   * Map of resouces by ID
   */
  resourcesByIdMap: Map<string, Resource>;

  /**
   * Indicates whether dialog has to be opened in read only mode
   */
  readOnly?: boolean;
}

/**
 * The interface to return a response to the caller.
 */
export interface ResourcesDialogResponse {
  /**
   * IDs of selected resources
   */
  resourceIds: string[];
}

@Component({
  standalone: true,
  imports: [
    ResourceSelectionDetailsComponent,
    CohesitySharedFormsModule,
    CommonModule,
    DataIdModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    ResourceTreeTableComponent,
    TranslateModule,
  ],
  selector: 'coh-resources-dialog',
  templateUrl: './resources-dialog.component.html',
  styleUrls: ['./resources-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ResourcesDialogComponent extends ClearSubscriptions implements OnInit {
  /**
   * Data injected into the dialog by parent component.
   */
  readonly data: ResourcesDialogData = inject(MAT_DIALOG_DATA);

  /**
   * Resources in tree form
   */
  resourcesTree$ = new BehaviorSubject<ResourceNode[]>([]);

  /**
   * Resources selected by the user
   */
  selectedResources$ = new BehaviorSubject<Resource[]>(this.getSelectedResources(this.data.resourceIds));

  /**
   * Resource selection form.
   */
  formGroup = this.formBuilder.group({
    resourceIds: [this.data.resourceIds || [], [NoEmptyArrayValidator]],
  });

  constructor(private dialogRef: MatDialogRef<ResourcesDialogResponse | undefined>, private formBuilder: FormBuilder) {
    super();
    this.resourcesTree$.next(transformArrayToTree(this.data.resources));
  }

  ngOnInit() {
    this.subscriptions.push(
      this.formGroup.controls.resourceIds.valueChanges.subscribe(ids => {
        this.selectedResources$.next(this.getSelectedResources(ids));
      })
    );

    if (this.data.readOnly) {
      this.formGroup.disable();
    }
  }

  /**
   * Returns the resources that are selected by the user.
   *
   * @param ids The IDs of the selected resources
   * @returns The selected resources
   */
  getSelectedResources(ids: string[] = []): Resource[] {
    return ids.map(id => this.data.resourcesByIdMap.get(id));
  }

  /**
   * Handler for the 'validatedSubmit' event on the form.
   */
  onSubmit(): void {
    this.dialogRef.close(this.formGroup.value);
  }
}
