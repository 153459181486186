import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { GetProtectionRunStatsBody, ObjectStatsInfo } from '@cohesity/api/v2';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { envGroups, Environment } from '@cohesity/iris-shared-constants';
import { AutoDestroyable } from '@cohesity/utils';
import { BehaviorSubject, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RunProgressStatsPollerService } from 'src/app/core/services/run-progress-stats-poller.service';

/**
 * Interface for errors in the stats data.
 */
export interface Errors {
  /**
   * Total number of errors.
   */
  Total?: number;

  /**
   * Number of errors pertaining to File backups.
   */
  File?: number;

  /**
   * Number of errors pertaining to Folder backups.
   */
  Folder?: number;

  /**
   * Number of errors that are persistent.
   */
  Persistent?: number;

  /**
   * Number of errors that are intermittent.
   */
  Intermittent?: number;

  /**
   * Number of errors pertaining to the discovery phase.
   */
  Discovery?: number;

  /**
   * Number of errors pertaining to Ingestion phase.
   */
  Ingestion?: number;
}

@Component({
  selector: 'coh-timeline-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineStatisticsComponent extends AutoDestroyable implements OnInit {
  /**
   * BehaviorSubject that contains the latest value of the stats.
   */
  stats: BehaviorSubject<ObjectStatsInfo>;

  /**
   * BehaviorSubject that contains the latest value of the errors in stats.
   */
  errorClasses: BehaviorSubject<Errors>;

  /**
   * BehaviorSubject that contains the latest value of the last updated time.
   */
  lastUpdated: BehaviorSubject<number>;

  /**
   * The difference between the current time and start time of the protection run.
   */
  duration: BehaviorSubject<number>;

  /**
   * Start time of the protection run in micro secs.
   */
  @Input() startTimeUsecs?: number;

  /**
   * Id of the protection run.
   */
  @Input() runId?: string;

  /**
   * path to the object task backup op within the run.
   */
  @Input() objectTaskPath?: string;

  /**
   * path to the run task backup op.
   */
  @Input() runTaskPath?: string;

  /**
   * Object ID.
   */
  @Input() id?: number;

  /**
   * Environment pertaining to the protection run.
   */
  @Input() environment?: string;

  /**
   * Property that checks if feature flag protectionRunStatistics is enabled.
   */
  isProtectionRunStatsEnabled: boolean;

  constructor(
    private pollerService: RunProgressStatsPollerService,
    private irisCtx: IrisContextService,
  ) {
    super();
    this.stats = new BehaviorSubject<ObjectStatsInfo>({});
    this.errorClasses = new BehaviorSubject<Errors>({});
    this.lastUpdated = new BehaviorSubject<number>(0);
    this.duration = new BehaviorSubject<number>(0);
  }

  ngOnInit() {
    this.isProtectionRunStatsEnabled = flagEnabled(
      this.irisCtx.irisContext, 'protectionRunStatistics'
    );
    this.poll();

    if (envGroups.nas.includes(this.environment as Environment)) {
      this.lastUpdated.next(Date.now());
      this.duration.next(Date.now() - this.startTimeUsecs / 1_000);
      this.getLatestStats();
    }
  }

  poll() {
    if (envGroups.nas.includes(this.environment as Environment)) {
      this.pollerService.pollRunStats(
        this.runId, 60000, null, this.runTaskPath, [this.id], [this.objectTaskPath]
      )
      .pipe(this.untilDestroy())
      .subscribe((runProgress: GetProtectionRunStatsBody) => {
        const stats = runProgress.localRun?.objects?.filter(obj => obj.id === this.id)?.[0];
        const errors = { Total: stats.backupGenericStats?.numErrors };
        stats.backupGenericStats?.errorClasses.forEach(errorClass => {
          errors[errorClass.className] = errorClass.count;
        });
        this.lastUpdated.next(Date.now());
        this.duration.next(Date.now() - this.startTimeUsecs / 1_000);
        this.stats.next(stats);
        this.errorClasses.next(errors);
      });
    }
  }

  /**
   * executed when user clicks the refresh icon in the stats tab.
   */
  onRefreshClick() {
    this.lastUpdated.next(Date.now());
    this.duration.next(Date.now() - this.startTimeUsecs / 1_000);
    this.getLatestStats();
  }

  /**
   * creates an observable to fetch the latest stats.
   */
  getLatestStats() {
    this.pollerService.getRunStats(this.runId,
      {
        objects: [this.id],
        includeEventLogs: true,
        objectTaskPaths: [this.objectTaskPath],
        runTaskPath: this.runTaskPath,
      }).pipe(
      catchError(() => of<GetProtectionRunStatsBody>({}))).
      subscribe((runProgress: GetProtectionRunStatsBody) => {
        const stats = runProgress.localRun?.objects?.filter(obj => obj.id === this.id)?.[0];
        const errors = { Total: stats.backupGenericStats?.numErrors };
        stats.backupGenericStats?.errorClasses.forEach(errorClass => {
          errors[errorClass.className] = errorClass.count;
        });
        this.stats.next(stats);
        this.errorClasses.next(errors);
      });
  }
}
