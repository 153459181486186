<p class="margin-top bolder">{{ 'importExternalCertificateDialog.selectCertificateSettingsTitle' | translate }}</p>
<p class="margin-top">{{ 'importExternalCertificateDialog.selectCertificateSettingsSubtitle' | translate }}</p>

<form [formGroup]="formGroup" class="flex-container">

    <mat-form-field class="col-xs-12">
        <mat-label>{{ 'importExternalCertificateDialog.selectCertificateUsage' | translate }}</mat-label>
        <mat-select
            formControlName="certificateUsage"
            (selectionChange)="handleInterfaceData()"
            multiple
        >
            <mat-select-trigger>
                <mat-chip-list>
                    <mat-chip
                        class="selected-chip"
                        *ngFor="let usage of formGroup.get('certificateUsage').value; let i=index"
                        [removable]="true"
                        (removed)="removeCertificateUsage(i)">
                        {{ usage }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </mat-select-trigger>
            <mat-option *ngFor="let usage of certificateUsageList" [value]="usage">
                {{usage | translate}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <p class="bolder">{{ 'importExternalCertificateDialog.importAsTrustedTitle' | translate }}</p>

    <div class="col-xs-12">
        <mat-slide-toggle
            formControlName="importAsTrusted"
            (change)="handleInterfaceData()">
            <span class="bolder">
                {{ 'importExternalCertificateDialog.importAsTrustedLabel' | translate }}
            </span>
        </mat-slide-toggle>
    </div>
</form>