import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const certificateManagementViewAccess = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.pkiCertificateManagement && ctx.CLUSTER_MODIFY;

export const CertificateManagementConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'certificate-management.**',
      url: '^/certificate-management',
      loadChildren: () => import('./certificate-management.module').then(m => m.CertificateManagementModule),
    },
  ],
  getStateAccessMap(): StateAccessMap {
    return {
      'certificate-management': certificateManagementViewAccess,
      'certificate-management.managed': certificateManagementViewAccess,
      'certificate-management.external': certificateManagementViewAccess,
      'certificate-management.authority': certificateManagementViewAccess,
    };
  },
};
