<h4 mat-dialog-title>
  {{'attention' | translate}}
</h4>
<mat-dialog-content>
  <span [innerHTML]="content"></span>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button cogCancelButton cogDataId="cancel-banner" [mat-dialog-close]="false">
    {{'cancel' | translate}}
  </button>
  <button mat-button cogSubmitButton cogDataId="accept-banner" [mat-dialog-close]="true" color="primary" cdkFocusInitial>
    {{'acknowledge' | translate}}
  </button>
</mat-dialog-actions>
