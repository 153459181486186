import { FlexibleConnectedPositionStrategy } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { McmClusterConnectionStatus } from '@cohesity/api/private';
import { FortknoxServiceApi, RpaasRegionInfo } from '@cohesity/api/v2';
import {
  awsStorageClasses,
  azureStorageClasses,
  FortknoxAzureStorageClass,
  rpaasAwsMinClusterVersion,
  rpaasAzureMinClusterVersion,
  rpaasMinClusterVersion,
  RpaasStateService,
} from '@cohesity/data-govern/security-center';
import { PopoverRef, SnackBarService } from '@cohesity/helix';
import { AjaxHandlerService } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { checkClusterVersion } from 'src/app/modules/object-details-shared';

/**
 * Input for the unpaired popover component
 */
export interface UnpairedClusterPopoverData {
  /**
   * The list of configured vaults.
   */
  vault: RpaasRegionInfo;

  /**
   * A list of all clusters and their versions
   */
  clusters: McmClusterConnectionStatus[];
}
/**
 * Internal interface that corresponds to the table rows
 */
interface UnpairedClusterInfo {
  /**
   * The cluster name
   */
  clusterName: string;

  /**
   * The cluster name
   */
  clusterId: number;

  /**
   * Sotware version
   */
  softwareVersion: string;

  /**
   * The reason the cluster is not paired
   */
  unpairedReason?: 'Version' | 'Unknown' | 'Failed' | 'InProgress' | 'Disconnected';

  /**
   * Optional message explaining why the cluster is not paired. This is used
   * when the actual attemp failed or is currently in progress.
   */
  message?: string;
}

/**
 * This renders vaults within a table, it is similar too the dmaas regions table and intended to be used with the
 * region map component.
 */
@Component({
  selector: 'coh-unpaired-cluster-popover',
  templateUrl: './unpaired-cluster-popover.component.html',
  styleUrls: ['./unpaired-cluster-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnpairedClusterPopoverComponent implements OnInit {
  /**
   * A list of all unpaired clusters
   */
  unpairedClusters: UnpairedClusterInfo[] = [];

  /**
   * Min version required to pair clusters
   */
  get minVersion(): string {
    if (this.isAzureVault) {
      return rpaasAzureMinClusterVersion;
    } else if (this.isAwsVault) {
      return rpaasAwsMinClusterVersion;
    }

    return rpaasMinClusterVersion;
  }

  /**
   * Checks if any clusters failed because the version is invalid. If so, we will show
   * an additional banner
   */
  get hasVersionError(): boolean {
    return this.unpairedClusters.some(cluster => cluster.unpairedReason === 'Version');
  }

  /**
   * True if we can attempt to pair the clusters again. We can't do this if the clusters are in
   * progress, or have an unsupported version
   */
  get allowRetry(): boolean {
    return this.unpairedClusters.some(cluster => ['Failed', 'Unknown'].includes(cluster.unpairedReason));
  }

  /**
   * This popover is used with AWS vault
   */
  isAwsVault = false;

  /**
   * This popover is used with Azure vault
   */
  isAzureVault = false;

  constructor(
    private popoverRef: PopoverRef<UnpairedClusterPopoverData>,
    private ajaxHandler: AjaxHandlerService,
    private snackbarService: SnackBarService,
    private rpaasState: RpaasStateService,
    private fortknoxService: FortknoxServiceApi,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    const { clusters, vault } = this.popoverRef.data;

    this.isAwsVault = awsStorageClasses.includes(vault.storageClass);
    this.isAzureVault = azureStorageClasses.includes(vault.storageClass as FortknoxAzureStorageClass);

    this.unpairedClusters = clusters
      .map(cluster => {
        const pairingInfo = vault.pairingInfo?.find(info => info.clusterId === cluster.clusterId)?.pairingStatus;
        const pairingStatus = pairingInfo?.status;
        let unpairedReason = 'Unknown';
        let message = pairingInfo?.message;

        if (!cluster.connectedToCluster) {
          unpairedReason = 'Disconnected';
          message = this.translate.instant('rpaas.disconnected');
        } else if (pairingStatus === 'Completed') {
          return null;
        } else if (
          cluster.softwareVersion &&
          ((this.isAwsVault && !checkClusterVersion(cluster.softwareVersion, rpaasAwsMinClusterVersion)) ||
            (this.isAzureVault && !checkClusterVersion(cluster.softwareVersion, rpaasAzureMinClusterVersion)))
        ) {
          unpairedReason = 'Version';
        } else if (pairingStatus === 'InProgress') {
          unpairedReason = 'InProgress';
        } else if (pairingStatus === 'Failed') {
          unpairedReason = 'Failed';
        }
        return {
          clusterName: cluster.name,
          clusterId: cluster.clusterId,
          softwareVersion: cluster.softwareVersion || this.translate.instant('unknown'),
          unpairedReason,
          message,
        } as UnpairedClusterInfo;
      })
      .filter(cluster => !!cluster);

    // If the popover is near the edge of the screen, it sometimes will go off the edge of the screen
    // We need to update the placement, and also add an offset to the position strategy so it will
    // have some space at the bottom of the screen.
    setTimeout(() => {
      const positionStrategy = this.popoverRef.overlay.getConfig()
        .positionStrategy as FlexibleConnectedPositionStrategy;
      positionStrategy.withDefaultOffsetY(-32);
      this.popoverRef.overlay.updatePositionStrategy(positionStrategy);
      this.popoverRef.overlay.updatePosition();
    }, 20);
  }

  /**
   * Retrying pairing all of the clusters that we can with this vault.
   */
  retryPairing() {
    this.fortknoxService
      .PairFortknoxVaultsClusters({
        clusterIds: this.unpairedClusters
          .filter(cluster => ['Failed', 'Unknown'].includes(cluster.unpairedReason))
          .map(cluster => cluster.clusterId),
        globalVaultIds: [this.popoverRef.data.vault.globalVaultId],
      })
      .pipe(
        finalize(() => {
          this.rpaasState.fetchRpaasRegions();
          this.popoverRef.close();
        })
      )
      .subscribe(() => {
        this.snackbarService.open(this.translate.instant('rpaas.retryPairingSubmitted'));
      }, this.ajaxHandler.handler);
  }
}
