import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const externalTargetsAccess = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngVaults && ctx.CLUSTER_VIEW;

export const ExternalTargetsConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'external-targets-ng.**',
      url: '/external-targets',
      loadChildren: () => import('./external-targets.module').then(m => m.ExternalTargetsModule),
    },
  ],
  allClusterMap: {
    'external-targets': {
      heliosOnly: false,
      allClustersState: 'dashboards'
    }
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'external-targets-ng': externalTargetsAccess,
      'external-targets-ng.list': externalTargetsAccess,
      'external-targets-ng.settings': externalTargetsAccess,
      'external-targets-ng.view': externalTargetsAccess,
    };
  },
};
