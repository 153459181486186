<ng-container *ngIf="isProtectionRunStatsEnabled &&
  stats | async as stats; else loader">
  <div class="statistics-parent">
    <div class="statistics-backup-time">
      <div class="stats-data stats-current-time">
        <h4 class="header-data-value">{{ duration | async | humanizeDuration }}</h4>
        <p class="data-label">{{ 'currentTime' | translate }}</p>
      </div>
      <div class="stats-data stats-estimated-time">
        <h4 class="header-data-value">{{ stats.backupGenericStats?.estimatedBackupTime | humanizeDuration }}</h4>
        <p class="data-label">{{ 'estimatedTotalBackupTime' | translate }}</p>
      </div>
    </div>
    <div class="statistics-last-updated">
      <span>
        <h4 class="header-data-value">{{ lastUpdated | async | cohDate: 'long' }}</h4>
        <button
          (click)="onRefreshClick()"
          cogDataId="stats-refresh-button"
          mat-icon-button
          type="button">
          <cog-icon shape="refresh"></cog-icon>
        </button>
      </span>
      <p class="data-label">{{ 'lastUpdated' | translate }}</p>
    </div>
    <div class="statistics-progress">
      <h3>{{ 'progress' | translate }}</h3>
      <div class="file-discovery-progress stage-progress">
        <h4>{{ 'fileDiscovery' | translate }}</h4>
        <div class="stage-progress-data">
          <div class="stats-data">
            <p class="data-value">{{ stats.nasStats?.filesDiscovered }}</p>
            <p class="data-label">{{ 'discoveredFiles' | translate }}</p>
          </div>
          <div class="stats-data">
            <p class="data-value">{{ stats.nasStats?.fileDiscoveryRate }}/min</p>
            <p class="data-label">{{ 'speed' | translate }}</p>
          </div>
          <div class="stats-data">
            <p class="data-value">{{ stats.nasStats?.runTime | humanizeDuration }}</p>
            <p class="data-label">{{ 'runTimeNoSpace' | translate }}</p>
          </div>
        </div>
      </div>
      <div class="analysis-progress stage-progress">
        <h4>{{ 'analysis' | translate }}</h4>
        <div class="stage-progress-data">
          <div class="stats-data">
            <p class="data-value">{{ stats.nasStats?.filesAnalyzed }}</p>
            <p class="data-label">{{ 'analyzed' | translate }}</p>
          </div>
          <div class="stats-data">
            <p class="data-value">{{ stats.nasStats?.fileAnalysisRate }}/min</p>
            <p class="data-label">{{ 'speed' | translate }}</p>
          </div>
          <div class="stats-data">
            <p class="data-value">{{ stats.backupGenericStats?.remainingDataIngested | byteSize }}</p>
            <p class="data-label">{{ 'toBeIngested' | translate }}</p>
          </div>
        </div>
      </div>
      <div class="ingestion-progress stage-progress">
        <h4>{{ 'ingestion' | translate }}</h4>
        <div class="stage-progress-data">
          <div class="stats-data">
            <p class="data-label"> {{ 'files' | translate }} </p>
            <p class="data-value">{{ stats.nasStats?.filesIngested }}</p>
            <p class="data-label">{{ 'completedFiles' | translate }}</p>
          </div>
          <div class="stats-data">
            <p class="data-value">{{ stats.nasStats?.fileIngestionRate }}/min</p>
            <p class="data-label">{{ 'speed' | translate }}</p>
          </div>
          <div class="stats-data">
            <p class="data-label"> {{ 'data' | translate }} </p>
            <p class="data-value">{{ stats.backupGenericStats?.dataIngested | byteSize}}</p>
            <p class="data-label">{{ 'dataIngested' | translate }}</p>
          </div>
          <div class="stats-data">
            <p class="data-value">{{ stats.backupGenericStats?.dataIngestionRate | byteSize }}/min</p>
            <p class="data-label">{{ 'speed' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="statistics-errors" *ngIf="errorClasses | async as errors">
      <h3>{{ 'errors' | translate }}</h3>
      <div class="total-errors">
        <div class="stats-data">
          <p class="data-value">{{ errors.Total || 0 }}</p>
          <p class="data-label">{{ 'total' | translate }}</p>
        </div>
      </div>
      <div class="errors-classification">
        <h4>{{ 'classification' | translate }}</h4>
        <div class="stage-progress-data">
          <div class="stats-data">
            <p class="data-value">{{ errors.File || 0 }}</p>
            <p class="data-label">{{ 'files' | translate }}</p>
          </div>
          <div class="stats-data">
            <p class="data-value">{{ errors.Folder || 0 }}</p>
            <p class="data-label">{{ 'folders' | translate }}</p>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="stage-progress-data">
          <div class="stats-data">
            <p class="data-value">{{ errors.Persistent || 0 }}</p>
            <p class="data-label">{{ 'persistent' | translate }}</p>
          </div>
          <div class="stats-data">
            <p class="data-value">{{ errors.Intermittent || 0 }}</p>
            <p class="data-label">{{ 'intermittent' | translate }}</p>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="stage-progress-data">
          <div class="stats-data">
            <p class="data-value">{{ errors.Discovery || 0 }}</p>
            <p class="data-label">{{ 'fileDiscovery' | translate }}</p>
          </div>
          <div class="stats-data">
            <p class="data-value">{{ errors.Ingestion || 0 }}</p>
            <p class="data-label">{{ 'ingestion' | translate }}</p>
          </div>
        </div>
        <mat-divider></mat-divider>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loader>
  <div class="spinner">
    <cog-spinner></cog-spinner>
  </div>
</ng-template>
