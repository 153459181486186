// Component: Database Migration (HotStandby)
import { recoveryGroup } from 'src/app/shared/constants';

/**
 * NOTE: At inception time, this only ever applies to SQL.
 */

(function _iife(angular) {
  'use strict';

  angular.module('C.dbRestore')
    .config(dbMigrationConfig);

  /**
   * Config Fn.
   *
   * @function   dbMigrationConfig
   * @param      {Object}   $stateProvider  StateProvider Service.
   */
  function dbMigrationConfig($stateProvider) {
    var restoreModifyAccess = ctx => {
      const envItems = recoveryGroup.dbMigration.map(env => {
        return { environment: 'kSQL', hostEnvironment: env };
      });

      return ctx.RESTORE_MODIFY && ctx.canAccessSomeEnvItems(envItems) &&
        !ctx.isIbmBaas;
    };

    // Configure the Recover states & routes
    $stateProvider
      .state('migrate-db', {
        url: '^/protection/migrate-database',
        redirectTo: 'migrate-db.search',

        // TODO (spencer): Get all these help keys
        help: 'migrate_db',
        title: 'Migrate Database',
        canAccess: restoreModifyAccess,
        parentState: 'recover-local',
        params: {
          dbType: 'sql',
          dbMigration: true,
          flowType: 'recover',
        },
        controller: 'restoreDBParentController as $ctrl',
        templateUrl: 'app/protection/recovery/db/db-parent.html',
      })
      .state('migrate-db.search', {
        url: '/search',
        help: 'migrate_db',
        title: 'Migrate Database: Select Object',
        canAccess: restoreModifyAccess,
        parentState: 'recover-local',
        views: {
          'canvas@migrate-db': {
            templateUrl: 'app/protection/recovery/db/db-search.html',
            controller: 'restoreDBSearchController as $ctrl',
          },
        },
      })
      .state('migrate-db.options', {
        url: '/options?{jobId}&{entityId}&{jobInstanceId}',
        help: 'migrate_db',
        title: 'Migrate Database: Options',
        canAccess: restoreModifyAccess,
        params: {
          failover: false,
          jobId: { type: 'string' },
          entityId: { type: 'string' },
          jobInstanceId: { type: 'string' },
        },
        parentState: 'recover-local',
        views: {
          'canvas@migrate-db': {
            templateUrl: 'app/protection/recovery/db/db-options.html',
            controller: 'restoreDBOptionsController as $ctrl',
          },
        },
      });
  }
})(angular);
