import { Pipe, PipeTransform } from '@angular/core';
import { StorageDomain } from '@cohesity/api/v2';
import { TranslateService } from '@ngx-translate/core';

/**
 * This pipe used to provides a string value representing a storageDomain's dedupe policy
 * (both post and inline combined).
 *
 * @example
 *   {{ viewbox | ngViewBoxDedupeStatus}}     Inline
 */
@Pipe({
  name: 'ngViewBoxDedupeStatus',
})
export class NgViewBoxDedupeStatusPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  /**
   * Implements the pipe.
   *
   * @param   storageDomain   The storage domain object.
   * @return  storage domain deduplication status.
   */
  transform(storageDomain: StorageDomain): string {
    if (!storageDomain ||
      !storageDomain.storagePolicy) {
      return this.translate.instant('naNotAvailableLetters');
    }
    const {deduplicationParams} = storageDomain.storagePolicy;

    switch (true) {
      case !deduplicationParams:
        return this.translate.instant('no');

      // inline
      case (deduplicationParams.enabled && deduplicationParams.inlineEnabled):
        return this.translate.instant('inline');

      // post dedupe
      case (deduplicationParams.enabled && !deduplicationParams.inlineEnabled):
        return this.translate.instant('post');

      // some other case, fallback for uncertainty
      default:
        return this.translate.instant('naNotAvailableLetters');
    }
  }
}
