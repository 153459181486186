import {
  _DisposeViewRepeaterStrategy,
  _RecycleViewRepeaterStrategy,
  _VIEW_REPEATER_STRATEGY,
  _ViewRepeater,
  _ViewRepeaterItemChanged,
  _ViewRepeaterItemContext,
  _ViewRepeaterItemContextFactory,
  _ViewRepeaterItemValueResolver,
} from '@angular/cdk/collections';
import { Directive, EmbeddedViewRef, Inject, Injectable, Input, IterableChanges, OnInit, ViewContainerRef } from '@angular/core';

/**
 * View repeater strategy, which is intended to optimize rendering for data tree rows. The cdk implementation for
 * _RecycleViewRepeaterStrategy caches removed views so that they can be added back to the dom as new items are added
 * This is much faster than re-rendering them from scratch.
 * The problem is that the CDK implementation breaks if a component is removed while there is an animation in progress,
 * which is easy to reproduce by clicking expand/collapse and then immediately scrolling down the screen. This
 * implementation adds a wait time after a node is removed and before it is added to the cache to be available for reuse
 * in order to prevent this error.
 *
 * Unfortunately, the necessary methods in the base class are private, so this uses some not ideal methods to overwrite
 * them.
 */
export class TreeViewRepeaterStrategy<T, R, C extends _ViewRepeaterItemContext<T>>
  extends _RecycleViewRepeaterStrategy<T, R, C> {

  /**
   * Amount of time to wait to ensure that animations are complete.
   */
  waitTimeAfterRemoval = 2000;

  constructor() {
    super();
    // Save the original method
    const maybeCache: (view: EmbeddedViewRef<C>, viewContainerRef: ViewContainerRef) => void
        = (this as any)._maybeCacheView;

    // Overwrite the original method to add a delay before caching the view.
    (this as any)._maybeCacheView = (view: EmbeddedViewRef<C>, viewContainerRef: ViewContainerRef) => {
      setTimeout(() => maybeCache.call(this, view, viewContainerRef), this.waitTimeAfterRemoval);
    };
  }
}

/**
 * View repeater factory that lets use choose which strategy to use for the data tree table.
 */
@Injectable()
export class ViewRepeaterFactory<T, R, C extends _ViewRepeaterItemContext<T>>
implements _ViewRepeater<T, R, C> {

  /**
   * The current view repeater strategy.
   */
  strategy: _ViewRepeater<T, R, C> = new _DisposeViewRepeaterStrategy();

  applyChanges(changes: IterableChanges<R>,
    viewContainerRef: ViewContainerRef,
    itemContextFactory: _ViewRepeaterItemContextFactory<T, R, C>,
    itemValueResolver: _ViewRepeaterItemValueResolver<T, R>,
    itemViewChanged?: _ViewRepeaterItemChanged<R, C>) {
    this.strategy?.applyChanges(changes, viewContainerRef, itemContextFactory, itemValueResolver, itemViewChanged);
  }

  detach() {
    this.strategy?.detach();
  }
}

/**
 * Enables the tree view repeater strategy, which reuses rows during rendering where possible.
 */
@Directive({
  selector: 'mat-table[cogRecycleRows], table[mat-table][cogRecycleRows]',
  providers: [
    {provide: _VIEW_REPEATER_STRATEGY, useClass: ViewRepeaterFactory},
  ],
})
export class DataTreeViewRepeaterDirective implements OnInit {

  /**
   * The view repeater strategy.
   *
   * Dispose is the default used by the Angular CDK. It removes views as they are scrolled out of view and creates new
   * ones as they are added
   *
   * Recycle is provided by Angular CDK, and caches views as they are scrolled out of view. New views are added from the
   * cache when possible, or else created. Animationos can cause this to break - if a view has an animation on it, it
   * will show as being removed from the view container ref, but will fail when it is added back.
   *
   * TreeView is the same as Recycle, but with a setTimeout that waits for a set period before making a node available
   * for reuse.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('cogRecycleRows') strategy: 'Dispose' | 'Recycle' | 'TreeView' = 'TreeView';

  constructor(@Inject(_VIEW_REPEATER_STRATEGY) private viewRepeater: ViewRepeaterFactory<any, any, any>) {
  }

  ngOnInit() {
    switch (this.strategy) {
      case 'Recycle': {
        const recycle = new _RecycleViewRepeaterStrategy();
        recycle.viewCacheSize = 60;
        this.viewRepeater.strategy = recycle;
        break;
      }
      case 'TreeView': {
        const treeView = new TreeViewRepeaterStrategy();
        treeView.viewCacheSize = 200;
        this.viewRepeater.strategy = treeView;
        break;
      }
      case 'Dispose':
      default:
        this.viewRepeater.strategy = new _DisposeViewRepeaterStrategy();
    }
  }
}
