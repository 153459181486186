import { isOneHeliosAppliance } from '@cohesity/iris-core';
import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

/**
 * Returns true if user has access of appliance manager, false otherwise.
 */
const isApplianceManagerAccess = (ctx: StateAccessContext) => isOneHeliosAppliance(ctx.irisContext);

/**
 * Configuration for the appliance nanager states.
 */
export const ApplianceManagerConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'appliance-manager.**',
      url: '/appliance-manager/dashboard',
      loadChildren: () =>
        import('./appliance-manager-dashboard/appliance-manager-dashboard.module').then(
          m => m.ApplianceManagerDashboardSetupModule
        ),
    },
    {
      name: 'appliance-manager-setup.**',
      url: '/appliance-manager/setup',
      loadChildren: () =>
        import('./appliance-manager-cluster-setup/appliance-manager-cluster-setup.module').then(
          m => m.ApplianceManagerClusterSetupModule
        ),
    },
    {
      name: 'appliance-manager-third-party-services.**',
      url: '/appliance-manager/third-party-services',
      loadChildren: () =>
        import('./appliance-manager-third-party-services/appliance-manager-third-party-services.module').then(
          m => m.ApplianceManagerDashboardSetupModule
        ),
    },
  ],
  getStateAccessMap(): StateAccessMap {
    return {
      'appliance-manager': isApplianceManagerAccess,
      'appliance-manager-setup': isApplianceManagerAccess,
      'appliance-manager-third-party-services': isApplianceManagerAccess,
    };
  },
};
