/**
 * Generates a map in a format like
 * {'Tag Name': 'prefix' + 'tagNameKey'}
 *
 * @param tagNames tag name mapping for which translation keys need to be generated
 * @param prefix prefix for the translation keys
 * @returns map of generated translation keys against a tag name
 */
function generateTransalationKeys(tagNames: Record<string, string>, prefix: string): Record<string, string> {
  return Object.entries(tagNames).reduce((acc, value) => {
    acc[value[1]] = `${prefix}${value[0]}`;
    return acc;
  }, {});
}

/**
 * System tags defined for the ransomware detection
 */
export const ransomwareTagNames = {
  critical: 'Critical Ransomware Detected',
  warning: 'Warning Ransomware Detected',
  none: 'No Ransomware Detected',
} as const;

/**
 * Translation key mapping for the ransomware tags
 */
export const ransomwareTagTranslationKeys = generateTransalationKeys(ransomwareTagNames, 'tags.ransomware.');

/**
 * System tags defined for the threat detection
 */
export const threatTagNames = {
  detected: 'Threats Matched',
  none: 'No Threats Matched',
} as const;

/**
 * Translation key mapping for the threat tags
 */
export const threatTagTranslationKeys = generateTransalationKeys(threatTagNames, 'tags.threats.');

/**
 * System tags defined for the sensitivity detection
 */
export const sensitivityTagNames = {
  high: 'High Sensitivity Detected',
  medium: 'Medium Sensitivity Detected',
  low: 'Low Sensitivity Detected',
  none: 'No Sensitivity Detected',
} as const;

/**
 * Translation key mapping for the sensitivity tags
 */
export const sensitivityTagTranslationKeys = generateTransalationKeys(sensitivityTagNames, 'tags.sensitivity.');
