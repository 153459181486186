import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { getAccessibleAppConfigs } from '../../../app-configs';
import { IrisContextService, hasClusters } from '../../../iris-context';
import { PillarItem } from '../../app-pillars.model';
import { meetsClusterRequirmentsForApp } from '../../app-pillars.utils';

/**
 * App pillar component.
 * This displays various messages for each app in the data cloud
 * depending on entitlements and other requirements
 */
@Component({
  selector: 'coh-app-pillar-landing',
  templateUrl: './app-pillar-landing.component.html',
  styleUrls: ['./app-pillar-landing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AppPillarLandingComponent implements OnInit {
  /** App data. */
  @Input() app: PillarItem;

  /**
   * Whether to show user how to claim a cluster because this app requires it
   */
  showClusterClaimComponent = false;

  constructor(
    private irisContext: IrisContextService,
    protected translateService: TranslateService) {}

  ngOnInit(): void {
    // Cluster manager does not need a sku, just clusters.  If no clusters display help link.
    if (this.app.app === 'clusterManager') {
      this.showClusterClaimComponent = !hasClusters(this.irisContext.irisContext);
    } else {
      // If you do have a sku for the app, but no clusters, display the help link.
      this.showClusterClaimComponent = getAccessibleAppConfigs(this.irisContext.irisContext)
        .find(c => c.app === this.app.app) &&
        !meetsClusterRequirmentsForApp(this.app.app, this.irisContext.irisContext);
    }
  }
}
