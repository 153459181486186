// Component: GlobalSearchProtect

;(function(angular, undefined) {
  'use strict';

  angular.module('C.globalSearch')
    .controller('GlobalSearchProtectCtrl', GlobalSearchProtectCtrlFn)
    .component('globalSearchProtect', {
      bindings: {
        // @type {Object}  A Global Search result object.
        searchResult: '<',

        // @type {object} A hash (by id) of remote clusters
        remoteClustersHash: '<',
      },
      controller: 'GlobalSearchProtectCtrl',
      templateUrl:
        'app/global-search/global-search-protect/global-search-protect.html',
    });

  function GlobalSearchProtectCtrlFn(_, $state, $rootScope,
    PubJobService, PolicyService, PubJobServiceFormatter, JobFlowService,
    evalAJAX, cMessage, ENV_GROUPS, SOURCE_TYPE_GROUPS, FEATURE_FLAGS) {

    var $ctrl = this;

    _.assign($ctrl, {
      // Lifecycle hooks
      $onInit: $onInit,

      // Controller methods
      isParentSourceSelected: isParentSourceSelected,
      loadCompatibleJobs: loadCompatibleJobs,
      loadJobsAndPolicies: loadJobsAndPolicies,
      loadRpoPolicies: loadRpoPolicies,
      protectWithNewJob: protectWithNewJob,
      protectWithNewRpoPolicy: protectWithNewRpoPolicy,
      submitProtectionForm: submitProtectionForm,

      // Controller properties
      clustersWithObject: [],
      compatibleJobs: [],

      // TODO: clean-up this flag and its implementation points once databases
      // can reliably be added to existing Jobs.
      dbAddToJobSupport: false,
      environment: undefined,
      newJobOptions: [],
      loadingJobs: false,
      loadingRpoPolicies: false,
      selectedCluster: undefined,
      selectedJob: undefined,
      selectedRpoPolicy: undefined,
      submitting: false,

      // Type of protection a user can select
      protectTypes: ['rpoPolicy', 'job'],
    });

    /**
     * Initialization function.
     *
     * @method   $onInit
     */
    function $onInit() {
      var clusterIdHash = {};

      $ctrl.environment = $ctrl.searchResult.source.environment;
      _addNewJobOptions($ctrl.searchResult);

      // Loop through source Uid list and add any clusters to the list if said
      // cluster exists in remoteClustersHash. If it doesn't exist in the hash
      // it was likely unregistered or may have a connection problem.
      ($ctrl.searchResult.protectionSourceUidList || []).forEach(
        function loopUidList(ids) {
          if ($ctrl.remoteClustersHash[ids.clusterId] &&
            !clusterIdHash[ids.clusterId]) {
            clusterIdHash[ids.clusterId] = true;
            $ctrl.clustersWithObject.push(
              $ctrl.remoteClustersHash[ids.clusterId]);
          }
        }
      );

      // Set the first (and possibly only) connected cluster as selected in
      // Helios.
      if ($rootScope.basicClusterInfo.mcmMode) {
        $ctrl.selectedCluster = $ctrl.clustersWithObject.find(
          function findCluster(cluster) {
            return cluster.connectedToCluster;
          }
        );
      } else {
        $ctrl.selectedCluster = $ctrl.clustersWithObject[0];
      }

      // Determine whether RPO Policy protection feature is enabled
      $ctrl.rpoPolicyEnabled = FEATURE_FLAGS.rpoPolicyEnabled &&
        ENV_GROUPS.rpoAdapters.includes($ctrl.environment);

      // Default option to protect is RPO Policy
      $ctrl.protectType = $ctrl.rpoPolicyEnabled &&
        !$ctrl.isParentSourceSelected() ? 'rpoPolicy' : 'job';
      loadJobsAndPolicies($ctrl.selectedCluster, $ctrl.environment);
    }

    /**
     * Determine whether a parent source entity is selected or not
     *
     * @return   {boolean}   Returns true if parent source is selected, false
     *                       otherwise
     */
    function isParentSourceSelected() {
      return $ctrl.searchResult.protectionSourceUidList[0].parentSourceId === -1;
    }

    /**
     * Handles 'add to existing job' form submission.
     *
     * @param {object}   form   Angular form object
     */
    function submitProtectionForm(form) {
      var ids = _getClusterSpecificIds($ctrl.selectedCluster);

      if (form.$invalid) {
        return;
      }

      $ctrl.submitting = true;

      if ($ctrl.protectType === 'rpoPolicy') {
        addToRpoPolicy(ids);
      } else {
        addToJob(ids);
      }
    }

    /**
     * Adds to source to rpo policy.
     *
     * @method   addToRpoPolicy
     * @param    {object}   ids   The identifiers object
     * @return   {object}   a promise to resolve the request
     */
    function addToRpoPolicy(ids) {
      var data = {
        rpoPolicyId: $ctrl.selectedRpoPolicy.id,
        protectionSourceEnvironment: $ctrl.searchResult.source.environment,
        protectionSourceIds: [ids.sourceId],
      }

      return PolicyService.addProtectionObjectInCluster(data, ids.clusterId).then(
        function addToRpoPolicySuccess() {
          cMessage.success({
            textKey: 'objectAddedToRpoPolicy',
            textKeyContext: {
              objectName: $ctrl.searchResult.source.name,
              rpoPolicyName: $ctrl.selectedRpoPolicy.name,
              policyId: $ctrl.selectedRpoPolicy.id,
            }
          });
          $ctrl.submitting = false;
        }
      );
    }

    /**
     * Adds a source to protectionn job.
     *
     * @method   addToJob
     * @param    {object}   ids   The identifiers object
     */
    function addToJob(ids) {
      var theJob = _.cloneDeep($ctrl.selectedJob);

      // TODO: Consider getting the jobTree for each Job in the list in order
      // to determine compatibilty between the object and the Job. This is
      // likely only necessary for SQL. Currently, we aren't allowing 'add to
      // job' functionality do the complexities of determining if a DB is
      // compatible with a given DB.
      PubJobService.getJobTree(theJob, ids.clusterId).then(
        function getTreeFinally(jobTree) {
          // Find the source node in the tree so we'll have all the decorators
          // necessary for use with JobFlowService function call.
          // TODO: may need to message user if the node isn't found, as adding
          // the object to the Job isn't really possible without it.
          var theNode = PubJobServiceFormatter.findNodesByNodeIds(
            jobTree.tree, [ids.sourceId])[0];

          JobFlowService.addParamSourcesToJob(
            [theNode.protectionSource], theJob, jobTree);

          PubJobServiceFormatter.addSelectedSourcesToJob(theJob, jobTree);

          PubJobService.updateJobOnCluster(theJob, ids.clusterId).then(
            function updateSuccess() {
              cMessage.success({
                textKey: 'objectAddedToJob',
                textKeyContext: {
                  objectName: $ctrl.searchResult.source.name,
                  jobName: theJob.name,
                }
              });
              $ctrl.submitting = false;
            },
            evalAJAX.errorMessage
          ).finally(
            function updateFinallyFn() {
              $ctrl.submitting = false;
            }
          );
        },
        function getTreeError(resp) {
          evalAJAX.errorMessage(resp);
          $ctrl.submitting = false;
        }
      );
    }

    /**
     * Loads list of jobs and RPO policies
     *
     * @method   loadJobsAndPolicies
     * @param    {object}   cluster       The cluster
     * @param    {string}   environment   The environment
     */
    function loadJobsAndPolicies(cluster, environment) {
      var ids = _getClusterSpecificIds(cluster);
      loadCompatibleJobs(ids);

      if ($ctrl.rpoPolicyEnabled) {
        loadRpoPolicies(ids.clusterId);
      }
    }

    /**
     * Load compatible Jobs from the provided cluster for the object.
     *
     * @method   loadCompatibleJobs
     * @param    {object}   ids   The identifiers object
     * @return   {object}   a promise to resolve the request
     */
    function loadCompatibleJobs(ids) {
      var env = $ctrl.searchResult.source.environment;
      var params = {
        // TODO: How to determine objects eligible for protection by multiple
        // job environments (vm vs sql, physical files vs physical cbt)?
        environments: env,
        isDeleted: false,
        isActive: true,
        onlyReturnBasicSummary: true,
      };

      $ctrl.compatibleJobs.length = 0;
      $ctrl.selectedJob = undefined;
      $ctrl.loadingJobs = true;
      return PubJobService.getJobsFromCluster(params, ids.clusterId).then(
        function getJobsSucess(jobs) {
          // Filter the jobs by parentSource since the API doesn't provide this
          // fuctionality via params and each Job can only support a single
          // parent source.
          return $ctrl.compatibleJobs =
            ENV_GROUPS.cohesityGroups.includes(env) ?
              jobs : _.filter(jobs, { 'parentSourceId': ids.parentSourceId });
        },
        evalAJAX.errorMessage
      ).finally(
        function jobsFinallyFn() {
          $ctrl.loadingJobs = false;
        }
      );

    }

    /**
     * Loads RPO policies list
     *
     * @method   loadRpoPolicies
     * @param    {string}   clusterId   The cluster identifier
     * @return   {object}   a promise to resolve the request
     */
    function loadRpoPolicies(clusterId) {
      $ctrl.loadingRpoPolicies = true;
      return PolicyService.getPoliciesFromCluster(undefined, clusterId).then(
        function getPoliciesSuccess(policies) {
          $ctrl.rpoPolicies = _.filter(policies, { type: 'kRPO'});

          if ($ctrl.rpoPolicies.length === 1) {
            // If there's only one RPO Policy present in the system, select it
            // by default as "Default Policy" to be used for protection.
            $ctrl.selectedRpoPolicy = $ctrl.rpoPolicies[0];
          }

          return $ctrl.rpoPolicies;
        },
        evalAJAX.errorMessage
      ).finally(function finallyFn() {
        $ctrl.loadingRpoPolicies = false;
      });
    }

    /**
     * Handles routing to the new job flow based on selected 'new job' option.
     * Note: In most cases there will only be a single option presented.
     *
     * @param   {string}   option   The selected "add new" option.
     */
    function protectWithNewJob(option) {

      var environments = (option === 'newJobFileBased') ?
        ['kPhysicalFiles'] : undefined;

      var ids = _getClusterSpecificIds($ctrl.selectedCluster);

      var toStateParams =
        _getProtectionParams($ctrl.searchResult.source, ids, environments);

      $state.go('job-modify', toStateParams);
    }

    /**
     * Goes to the Create RPO Policy workflow to add a new RPO policy
     *
     * @method   protectWithNewRpoPolicy
     */
    function protectWithNewRpoPolicy() {
      $state.go('protection-policy.rpo-modify');
    }

    /**
     * Returns a hash of ids relevant to the provided cluster.
     *
     * @param   {object}   cluster   The cluster for which to lookup ids object.
     * @returns {object}   ids relevant to the provided cluster.
     */
    function _getClusterSpecificIds(cluster) {
      var cid = cluster.clusterId || cluster.id;
      return _.find(
        $ctrl.searchResult.protectionSourceUidList, { clusterId: cid }) || {};
    }

    /**
     * Adds "new job" options to the controller.
     *
     * @method   _addNewJobOptions
     * @param    {object}   result   The result
     */
    function _addNewJobOptions(result) {
      var hostType;

      switch (result.source.environment) {
        case 'kPhysical':
          hostType = result.source.physicalProtectionSource.hostType;
          if (SOURCE_TYPE_GROUPS.physicalBlockBased.includes(hostType)) {
            $ctrl.newJobOptions.push('newJobBlockBased');
          }
          if (SOURCE_TYPE_GROUPS.physicalFileBased.includes(hostType)) {
            $ctrl.newJobOptions.push('newJobFileBased');
          }
          break;
        default:
          $ctrl.newJobOptions.push('newJob');
      }
    }

    /**
     * Gets the job flow protection parameters for protecting a specific
     * protectionSource (non-root node).
     *
     * @method   _getProtectionParams
     * @param    {Object}   protectionSource   The protection source
     * @param    {Object}   ids                list of relevant ids
     * @param    {Array}    [environments]     The environments
     * @return   {Object}   The job flow protection params.
     */
    function _getProtectionParams(protectionSource, ids, environments) {
      // Make a copy of protectionSource so updates to its id below don't update
      // by reference, as this protectionSource{} will be re-used for other
      // clusters that this object might exist on.
      var source = _.cloneDeep(protectionSource);
      var belongsToCohesityGroup =
        ENV_GROUPS.cohesityGroups.includes(protectionSource.environment);

      var parentSourceId;

      // In the event that parentSourceId is -1, then this object IS the parent
      // source and its id should be used as parentSourceId. Unless the source
      // belongs to an environment from ENV_GROUPS.cohesityGroup[] in which case
      // set to undefined as magneto gives garbage results in these cases.
      switch (true) {
        case belongsToCohesityGroup:
          // Leave parentSourceId undefined.
          break;

        case ids.parentSourceId === -1:
          // The object itself is a parentSource. Set the param as such.
          parentSourceId = ids.sourceId;
          break;

        default:
          parentSourceId = ids.parentSourceId;
      }

      // ensure the source's id matches up, as it is returned from API
      // with a zero value, and job flow needs it to correctly match that of
      // the cluster being protected on.
      source.id = ids.sourceId;

      environments = environments || [source.environment];

      return {
        cid: ids.clusterId,
        parentSourceId: parentSourceId,
        environments: environments,

        // Special handling for cohesityGroups[], as their parent source isn't
        // really a parent source and global search (actually, magneto) won't
        // give us the wrapper as a parent id. This mechanism allows the job
        // flow to simply select it since there can be only one such wrapper for
        // a given environment.
        parentSourceEnvironment: belongsToCohesityGroup ?
            protectionSource.environment : undefined,

        // If this is an actual registered parent source (as indicated by a
        // parentSourceId value of -1) with exception of cohesityGroups objects
        // don't pass it for protection, otherwise do pass it for protection.
        protectSources: (ids.parentSourceId === -1 && !belongsToCohesityGroup) ?
          undefined : [source],

        // Enable Quick Protection only for On-Prem clusters. For Helios, We
        // Transition inside the iFrame.
        quickProtect: !$rootScope.basicClusterInfo.mcmMode,
      };
    }
  }

})(angular);
