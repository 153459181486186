/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TenantDmaasCerts } from '../models/tenant-dmaas-certs';
import { AddDmaasTenantCertRequest } from '../models/add-dmaas-tenant-cert-request';
@Injectable({
  providedIn: 'root',
})
class DMaaSTenantCertificateService extends __BaseService {
  static readonly GetDmaasTenantCertsPath = '/dmaas-tenant-certificate';
  static readonly AddDmaasTenantCertPath = '/dmaas-tenant-certificate';
  static readonly DeleteDmaasTenantCertPath = '/dmaas-tenant-certificate/{tenantId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get DMaaS tenant certificates on the cluster.
   *
   * Get DMaaS tenant certificates on the cluster.
   * @param params The `DMaaSTenantCertificateService.GetDmaasTenantCertsParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which tenants are returned. If no tenant id is specified, all tenant certificates are returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetDmaasTenantCertsResponse(params: DMaaSTenantCertificateService.GetDmaasTenantCertsParams): __Observable<__StrictHttpResponse<TenantDmaasCerts>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/dmaas-tenant-certificate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantDmaasCerts>;
      })
    );
  }
  /**
   * Get DMaaS tenant certificates on the cluster.
   *
   * Get DMaaS tenant certificates on the cluster.
   * @param params The `DMaaSTenantCertificateService.GetDmaasTenantCertsParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which tenants are returned. If no tenant id is specified, all tenant certificates are returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetDmaasTenantCerts(params: DMaaSTenantCertificateService.GetDmaasTenantCertsParams): __Observable<TenantDmaasCerts> {
    return this.GetDmaasTenantCertsResponse(params).pipe(
      __map(_r => _r.body as TenantDmaasCerts)
    );
  }

  /**
   * Add a DMaaS tenant certificate to the cluster.
   *
   * Add a DMaaS tenant certificate to the cluster.
   * @param params The `DMaaSTenantCertificateService.AddDmaasTenantCertParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to add the tenant certificate.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  AddDmaasTenantCertResponse(params: DMaaSTenantCertificateService.AddDmaasTenantCertParams): __Observable<__StrictHttpResponse<AddDmaasTenantCertRequest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/dmaas-tenant-certificate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AddDmaasTenantCertRequest>;
      })
    );
  }
  /**
   * Add a DMaaS tenant certificate to the cluster.
   *
   * Add a DMaaS tenant certificate to the cluster.
   * @param params The `DMaaSTenantCertificateService.AddDmaasTenantCertParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to add the tenant certificate.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  AddDmaasTenantCert(params: DMaaSTenantCertificateService.AddDmaasTenantCertParams): __Observable<AddDmaasTenantCertRequest> {
    return this.AddDmaasTenantCertResponse(params).pipe(
      __map(_r => _r.body as AddDmaasTenantCertRequest)
    );
  }

  /**
   * Delete a tenant certificate.
   *
   * Delete a tenant certificate.
   * @param params The `DMaaSTenantCertificateService.DeleteDmaasTenantCertParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the id of tenant.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteDmaasTenantCertResponse(params: DMaaSTenantCertificateService.DeleteDmaasTenantCertParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/dmaas-tenant-certificate/${encodeURIComponent(params.tenantId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a tenant certificate.
   *
   * Delete a tenant certificate.
   * @param params The `DMaaSTenantCertificateService.DeleteDmaasTenantCertParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the id of tenant.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteDmaasTenantCert(params: DMaaSTenantCertificateService.DeleteDmaasTenantCertParams): __Observable<null> {
    return this.DeleteDmaasTenantCertResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module DMaaSTenantCertificateService {

  /**
   * Parameters for GetDmaasTenantCerts
   */
  export interface GetDmaasTenantCertsParams {

    /**
     * TenantIds contains ids of the tenants for which tenants are returned. If no tenant id is specified, all tenant certificates are returned.
     */
    tenantIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for AddDmaasTenantCert
   */
  export interface AddDmaasTenantCertParams {

    /**
     * Specifies the parameters to add the tenant certificate.
     */
    body: AddDmaasTenantCertRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteDmaasTenantCert
   */
  export interface DeleteDmaasTenantCertParams {

    /**
     * Specifies the id of tenant.
     */
    tenantId: null | string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { DMaaSTenantCertificateService }
