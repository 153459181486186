// Controller: Cluster Setup, Cluster Confirm
// TODO(spencer): Cleanup tasks: indentation, ngAnnotate pattern, @jsdocs, etc.

angular.module('C.clusterSetup')
  .controller(
    'clusterSetupConfirmController', ['$rootScope', '$scope', '$state', '$stateParams', 'ClusterService', 'localStorageService',
      function clusterSetupConfirmControllerFn($rootScope, $scope, $state, $stateParams, ClusterService, localStorageService) {

        $scope.text = $rootScope.text.adminClusterSetupConfirm;

        $scope.loginUrl = null;

        // Configuration object for progress monitor
        $scope.progress = {
          inProgress: false,
          timeRemaining: null,
          currentOperation: null,
          percentageCompleted: null
        };

        /**
         * Gets the localStorage object with the count of nodes configured in
         * this new cluster.
         *
         * @method   newClusterNodeCount
         * @return   {integer}   count of nodes in this new cluster
         */
        $scope.localStorageNewClusterNodeCount = function localStorageNewClusterNodeCount() {
          return localStorageService.get('newClusterNodeCount');
        };

        $scope.restoreSettingsFromLocalStorage();

        ClusterService.bringupStatus(5000, 6).then(
          function setupSuccess(response) {
            // update progress
            if (!response.inProgress) {
              $scope.setupComplete = true;
            }
            if (response.warningsFound) {
              $scope.warningsFound = true;
            }
            // if response.details are returned, let's update $scope.details
            if (response.details) {
              $scope.details = response.details;
            }
            // Clean up local storage data on success
            $scope.clearSettingsFromLocalStorage();
          },
          function setupError(response) {
            $scope.failure = true;
            $scope.setupComplete = true;
            $scope.errorMessage = response.errorMessage;
            // if response.details are returned, let's update $scope.details
            if (response.details) {
              $scope.details = response.details;
            }
          },
          function setupNotify(response) {
            // update progress
            if (!response) {
              // If the response is null,
              // let's update scope.progress with previous values, if they exist
              $scope.progress = {
                inProgress: $scope.progress.inProgress || false,
                timeRemaining: $scope.progress.timeRemaining || null,
                currentOperation: $scope.progress.currentOperation || null,
                percentageCompleted: $scope.progress.percentageCompleted || null
              };
              $scope.internalError = true;
            } else {
              // If the following properties are present, let's update their scope equivalants,
              // If they are not present, likely there is a server error thus we will retain their previously set values
              // If they have not been previously set, we must initialize them with a null or false value
              $scope.progress = {
                inProgress: response.inProgress ? response.inProgress : $scope.progress.inProgress || false,
                timeRemaining: response.timeRemaining ? response.timeRemaining : $scope.progress.timeRemaining || null,
                currentOperation: response.currentOperation ? response.currentOperation : $scope.progress.currentOperation || null,
                percentageCompleted: response.percentageCompleted ? response.percentageCompleted : $scope.progress.percentageCompleted || null
              };

              // if response.details are returned, let's update $scope.details
              if (response.details) {
                $scope.details = response.details;
              }

              if (response.loginUrl) {
                $scope.loginUrl = response.loginUrl;
              }

              $scope.internalError = false;
            }

          }
        ).finally(
          function getTaskFinally(response) {
            // all done, display message to user
            $scope.loginUrl = response.loginUrl;
            $scope.setupComplete = true;
          }
        );

        /**
         * Go back to previous state
         */
        $scope.goBack = function goBack() {
          $state.go('cluster-setup.details', $stateParams, {reload: true});
        };

      }
    ]);
