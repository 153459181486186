<div class="source-meta-grouping">
  <h5 class="title">{{'agentInformation' | translate}}</h5>

  <ul>
    <!-- Agent Status -->
    <li class="source-meta-line-item">
      <span class="source-meta-label">{{'status' | translate}}</span>
      <span class="status-info" *ngIf="node?.registrationInfo.authenticationStatus === 'kPending'">
        {{'registering' | translate}}
      </span>
      <span class="status-critical" *ngIf="node?.registrationInfo.authenticationErrorMessage">
        {{'registrationError' | translate}} {{'[' + node?.registrationInfo.authenticationErrorMessage + ']'}}
      </span>
      <span [attr.class]="isConnectionError ? 'status-critical' : 'status-ok'"
        *ngIf="node?.registrationInfo.authenticationStatus === 'kFinished' &&
        !node?.registrationInfo.authenticationErrorMessage">
        {{isConnectionError | boolLabel:'refreshError':'healthy'}}
        {{isConnectionError ? '[' + node?.registrationInfo.refreshErrorMessage + ']' : ''}}
      </span>
    </li>

    <!-- CBT status -->
    <li class="source-meta-line-item" *ngIf="fileCbtStatus">
      <span class="source-meta-label">{{'fileCbtStatus' | translate}}</span>
      {{ fileCbtStatus | translate}}
    </li>
    <li class="source-meta-line-item" *ngIf="volCbtStatus">
      <span class="source-meta-label">{{'volumeCbtStatus' | translate}}</span>
      {{ volCbtStatus | translate}}
    </li>

    <!-- Agent Version-->
    <li *ngIf="node?.registrationInfo.authenticationStatus === 'kFinished'"
      class="source-meta-line-item flex-row-xs">
      <span class="source-meta-label no-margin">{{'version' | translate}}</span>
      <span>{{agents[0]?.version | naLabel}}</span>
      <span *ngIf="agents[0]?.upgradability" [attr.class]='upgradabilityIcons'>
        ({{ ('enum.agentUpgradability.' + agents[0]?.upgradability) | translate }})
      </span>
    </li>

    <!-- Registration -->
    <li class="source-meta-line-item">
      <span class="source-meta-label">{{'registrationDate' | translate}}</span>
      {{node?.registrationInfo.registrationTimeUsecs | cogDate}}
    </li>

    <!-- Last Refreshed -->
    <li class="source-meta-line-item">
      <span class="source-meta-label">{{'lastRefreshed' | translate}}</span>
      {{node?.registrationInfo.refreshTimeUsecs | cogDate}}
    </li>

  </ul>
</div>
