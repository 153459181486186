import { Injectable } from '@angular/core';
import { GetVulScanResultParams, GetVulScanVersionResult, RestoreTasksApiService } from '@cohesity/api/private';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';

/**
 * This provides service for snapshot info component.
 */
@Injectable({
  providedIn: 'root',
})
export class SnapshotInfoService {
  /**
   * Stores VulScan parameters in a array for bulk get.
   */
  private vulScanParamsQueue$ = new BehaviorSubject<GetVulScanResultParams[]>([]);

  /**
   * Cache VulScan result.
   */
  private vulScanResultCache$ = new BehaviorSubject<GetVulScanVersionResult[]>(null);

  /**
   * Constructor.
   */
  constructor(
    private restoreTasksApi: RestoreTasksApiService
  ) {
    this.vulScanParamsQueue$.pipe(
      debounceTime(100),
      filter(queue => Boolean(queue && queue.length)),

      // combine data of all snapshots so that only one API call is made
      map((queue: GetVulScanResultParams[]) => {
        const versions = queue.map(p => p.versions[0]);
        return { ...queue[0], versions };
      }),
      switchMap((params: GetVulScanResultParams) => this.restoreTasksApi.getVulScanResult(params))
    ).subscribe((response: GetVulScanVersionResult[]) => {
      this.vulScanResultCache$.next(response);
      this.vulScanParamsQueue$.next([]);
    });
  }


  /**
   * Gets the scan result of all snapshots in the table in one API call.
   * Checks if params queue is empty before so that only one call is made.
   *
   * @param    params  Individual GetVulScanResultParams
   * @returns  Observable of list of GetVulScanVersionResult
   */
  bulkGetVulScanResult(params: GetVulScanResultParams): Observable<GetVulScanVersionResult> {
    // Add parameters to the queue
    this.vulScanParamsQueue$.next([
      ...this.vulScanParamsQueue$.value,
      params
    ]);

    // Search the bulk result by matching params and return
    return this.vulScanResultCache$.pipe(
      filter(response => !!response),
      map(bulkResult => {
        const { instanceId } = params.versions[0];

        return bulkResult.find(result =>
          result.instanceId.jobInstanceId === instanceId.jobInstanceId &&
          result.instanceId.jobStartTimeUsecs === instanceId.jobStartTimeUsecs
        );
      })
    );
  }
}
