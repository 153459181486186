// Modal: Node List

;(function (angular, undefined) {
  'use strict';

  angular.module('C.nodeList', [])
    .service('nodeList', nodeListFn);

  function nodeListFn($uibModal, NodeService, NODE_REMOVAL_STATE_CLASS,
    NODE_REMOVAL_STATE_LABEL) {

    var selectedNodes = [];

    var modalDefaults = {
      backdrop: 'static',
      keyboard: true,
      modalFade: true,
      templateUrl: 'app/platform/nodes/nodeList.html',
      size: 'lg',
    };

    var modalOptions = {
      closeButtonText: '',
      actionButtonText: '',
      title: '',
      content: '',
    };

    this.showModal =
      function showModal(customModalDefaults, customModalOptions) {
        selectedNodes = [];

        if (!customModalDefaults) {
          customModalDefaults = {};
        }

        return this.show(customModalDefaults, customModalOptions);
      };

    this.show = function show(customModalDefaults, customModalOptions) {
      //Create temp objects to work with since we're in a singleton service
      var tempModalDefaults = {};
      var tempModalOptions = {};

      // Map uib modal custom defaults to modal defaults defined in service.
      _.assign(tempModalDefaults, modalDefaults, customModalDefaults);

      // Map modal.html $scope custom properties to defaults defined in service.
      _.assign(tempModalOptions, modalOptions, customModalOptions);

      if (!tempModalDefaults.controller) {
        tempModalDefaults.controller = defaultNodeListModalCtrl;
      }

      return $uibModal.open(tempModalDefaults).result;

      /** @ngInject */
      function defaultNodeListModalCtrl($scope, $uibModalInstance) {
        $scope.modalOptions = tempModalOptions;

        $scope.NODE_REMOVAL_STATE_CLASS = NODE_REMOVAL_STATE_CLASS;
        $scope.NODE_REMOVAL_STATE_LABEL = NODE_REMOVAL_STATE_LABEL;

        /**
         * resolves the modal, returning an array of selected nodes
         *
         * @return {Void}
         */
        $scope.ok = function ok() {
          $uibModalInstance.close(selectedNodes);
        };

        /**
         * cancels/dismisses the modal
         *
         * @return {Void}
         */
        $scope.close = function close() {
          $uibModalInstance.dismiss('cancel');
        };

        /**
         * toggles the selection of a given node
         *
         * @param  {Object} node to be toggled
         * @param  {Object} e    click event
         * @return {Void}
         */
        $scope.clickNode = function clickNode(node, e) {
          e.stopPropagation();
          var i = selectedNodes.indexOf(node);
          if (i === -1) {
            selectedNodes.push(node);
            node.selected = true;
          } else {
            selectedNodes.splice(i, 1);
            node.selected = false;
          }
        };

        /**
         * requests a list of unassigned nodes from the API via NodeService
         *
         * @return {Void}
         */
        function getNodes() {
          var opts = {
            includeOnlyUnassignedNodes: true
          };

          $scope.unassignedNodes = [];
          $scope.nodesLoaded = false;

          NodeService.getClusterNodes(opts).then(
            function getNodesSuccess(response) {

              var existingNodesHashmap = {};

              // create the hashmap of existing nodes
              $scope.modalOptions.existingNodes.forEach(
                function mapExistingNode(node) {
                  existingNodesHashmap[node.id] = true;
                }
              );

              $scope.unassignedNodes = response.data || [];

              // removing any nodes found in existingNodes.
              // This case would be if the user added the nodes, hasn't
              // saved the partition updates yet, and then reopens this modal
              $scope.unassignedNodes = $scope.unassignedNodes.filter(
                function filterOutSelected(node) {
                  return !existingNodesHashmap.hasOwnProperty(node.id);
                }
              );

            }
          ).finally(
            function getNodesFinally() {
              $scope.nodesLoaded = true;
            }
          );
        }

        getNodes();
      }
    };

  }

})(angular);
