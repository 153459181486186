import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateFormatPickerComponent } from './date-format-picker.component';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { AngularMaterialModule } from '@cohesity/helix';

@NgModule({
  declarations: [DateFormatPickerComponent],
  imports: [
    CommonModule,
    AngularMaterialModule
  ],
  exports: [DateFormatPickerComponent]
})
export class DateFormatPickerModule { }
