import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clusterHealthStatusIcon',
  standalone: true,
})
export class ClusterHealthStatusIconPipe implements PipeTransform {
  /**
   * Transform cluster health status to corresponding icon
   *
   * @param value range
   * @returns cluster health status icon
   */
  transform(value: boolean): string {
    return value ? 'helix:status-success!success' : 'helix:status-error!critical';
  }
}
