import { Injectable } from '@angular/core';
import { IocIntegrationStat, IoCsIntegrationsApiService } from '@cohesity/api/argus';
import { DspmRun, DSPMServiceApi, Integration, SecurityIntegrationServiceApi } from '@cohesity/api/v2';
import { HasCustomRBACPermissions } from '@cohesity/data-govern/shared';
import { IrisContextService } from '@cohesity/iris-core';
import { AjaxHandlerService } from '@cohesity/utils';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SecurityIntegrationService {
  /**
   * security integration apps info behaviour subject
   */
  private _integrations$ = new BehaviorSubject<Integration[]>([]);

  /**
   * security integrations info obsevable
   */
  readonly integrations$ = this._integrations$.asObservable();

  /**
   * DSPM runs info behaviour subject
   */
  private _dspmRuns$ = new BehaviorSubject<DspmRun[]>([]);

  /**
   * DSPM runs info obsevable
   */
  readonly dspmRuns$ = this._dspmRuns$.asObservable();

  /**
   * IOC integration stats behaviour subject
   */
  private _iocIntegrationStats$ = new BehaviorSubject<IocIntegrationStat[]>([]);

  /**
   * IOC integration stats obsevable
   */
  readonly iocIntegrationStats$ = this._iocIntegrationStats$.asObservable();

  constructor(
    private ajaxService: AjaxHandlerService,
    private dspmService: DSPMServiceApi,
    private irisCtx: IrisContextService,
    private integrationStatsService: IoCsIntegrationsApiService,
    private securityIntegration: SecurityIntegrationServiceApi
  ) {}

  /**
   * Fetch all security (dspm) integrations
   */
  getSecurityIntegrations(
    params: SecurityIntegrationServiceApi.ListSecurityIntegrationsParams = {}
  ): Observable<Integration[]> {
    const hasPermission = HasCustomRBACPermissions(['SECURITY_INTEGRATION_VIEW'], this.irisCtx.irisContext);

    if (!hasPermission) {
      return of(null);
    }

    return this.securityIntegration.ListSecurityIntegrations(params).pipe(
      map(({ integrations = [] }) => integrations),
      tap(integrations => {
        this._integrations$.next(integrations);
      }),
      switchMap(integrations => {
        if (integrations?.length > 0) {
          return combineLatest([
            this.dspmService.GetDspmRuns({
              integrationIds: [integrations.map(integration => integration.integrationId).toString()],
              lastRunOnly: true,
              runType: 'Ingestion',
            }),
            this.integrationStatsService.getIocIntegrationsStats(),
          ]);
        }
        return of(null);
      }),
      tap((response) => {
        if (response) {
          const [dspmResponse, integrationStats] = response;
          this._dspmRuns$.next(dspmResponse?.runs);
          this._iocIntegrationStats$.next(integrationStats);
        }
      }),
      catchError(err => {
        this.ajaxService.handler(err);
        return of(null);
      })
    );
  }
}
