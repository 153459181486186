<cog-settings-list-item [formGroup]="formGroup"
  [invalid]="formControl.invalid"
  [label]="'skipFileOnErrors' | translate">
  <cog-settings-list-item-value-display>
    {{formControl.value | boolLabel}}
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor [formGroup]="formGroup"
    cogDataId="settings-enable-skip-file-on-errors">
    <mat-slide-toggle [formControlName]="name"
      [inheritDataId]="true"
      cogDataId="toggle">
      {{formControl.value | boolLabel}}
    </mat-slide-toggle>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
