import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ViewProtocol } from '@cohesity/api/v2';
import { CreateForm, createFormProviders } from '@cohesity/shared-forms';
import { isNFSProtocolSelected, isSMBProtocolSelected } from 'src/app/modules/views-shared';

import { CreateViewFormService } from '../../../services';

/**
 * Internal form interface.
 */
interface MostSecureSettingForm {
  mostSecureSettings: boolean;
}

@Component({
  selector: 'coh-most-secure-settings',
  templateUrl: './most-secure-settings.component.html',
  providers: createFormProviders(MostSecureSettingsComponent),
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MostSecureSettingsComponent {

  /**
   * The selected protocol objects.
   */
  @Input() selectedProtocols?: ViewProtocol[];

  /**
   * Initialize the form.
   */
  form = new CreateForm<boolean, MostSecureSettingForm>(this, {
    formControls: {
      mostSecureSettings: new FormControl<boolean>(false),
    },
    transformFromFormGroup: () => this.form.formGroup.value.mostSecureSettings,
    transformToFormGroup: mostSecureSettings => ({ mostSecureSettings }),
  });

  /**
   * Indicates whether the list of protocols includes SMB.
   */
  get hasSmbProtocol() {
    return isSMBProtocolSelected(this.selectedProtocols);
  }

  /**
   * Indicates whether the list of protocols includes any NFS.
   */
  get hasNfsProtocol() {
    return isNFSProtocolSelected(this.selectedProtocols);
  }

  constructor(
    private createViewFormService: CreateViewFormService
  ) {}
}
