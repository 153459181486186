// Component: Select Cluster

;(function(angular, undefined) {
  'use strict';

  var componentName = 'selectCluster';
  var configOptions = {
    controller: 'SelectClusterDropdownCtrl',
    templateUrl: 'app/global/select-cluster/select-cluster.html',

    // Attribute bindings
    bindings: {
      /**
       * Optional. If provided then ngModel will be set with the value of the
       * property looked up inside cluster info for example
       * if 'clusterId' is provided then ngModel will contain clusterId of
       * selected cluster.
       *
       * @type   {String}
       */
      resultAs: '@?',

      /**
       * Optional. If provided then will be used to determine if a cluster can
       * be selected or not else by default all clusters are selectable.
       *
       * @type   {Function}
       */
      isChoiceDisabled: '&?',

      /**
       * Optional. If provided then will be used to determine whether to select
       * all clusters initialing when clusters are fetched.
       *
       * @type   {Boolean}
       */
      defaultSelectAll: '<?',

      /**
       * Optional attribute sniffed value, if attribute is present then allow
       * multiple clusters selection.
       */
      // multiple: '?'

      /**
       * Optional attribute sniffed value, if attribute is present then show
       * select all clusters options and would work only with multiple selection
       * mode.
       */
      // showSelectAllOption: '?'

      /**
       * Optional attribute sniffed value, if attribute is present then show
       * cluster's meta info.
       */
      // showMetaInfo: '?'

      /**
       * Optional attribute sniffed value, if attribute is present the uiSelect
       * component will open automatically on initialization if the model is
       * empty.
       */
      // autoOpenIfEmpty: '?'

      /**
       * Optional attribute sniffed value, if attribute is present then show
       *  add new cluster option.
       */
      // addNewEnabled: '?'

      /**
       * Optional If prevent and true then use MCM APIs to get data else use
       * on-prem cluster APIs.
       */
      mcmMode: '@?',
    },

    // Required outside things. These show up on `this.ngModel` in the
    // controller.
    require: {
      ngModel: 'ngModel',
    },
  };

  angular.module('C.selectCluster', ['ui.select'])
    .controller('SelectClusterDropdownCtrl', SelectClusterDropdownCtrlFn)
    .component(componentName, configOptions);

  /**
   * $ngdoc Component
   * @name C.selectCluster:selectCluster
   * @scope
   * @link
   *
   * @requires ngModel
   * @function
   * @description
   *   Provides a dropdown listing registered remote clusters and allow
   *   selection of one or many such cluster based on provided configuration.
   *
   * @example
      <select-cluster
        name="cluster"
        required
        ng-model="$ctrl.selectedCluster"></select-cluster>

      <select-cluster
        name="cluster"
        result-as="clusterId"
        ng-model="$ctrl.selectedClusterId"></select-cluster>
   */
  function SelectClusterDropdownCtrlFn(_, $attrs, evalAJAX,
    RemoteClusterService) {

    var $ctrl = this;

    _.assign($ctrl, {
      // binding $attrs for disabled state pass through
      $attrs: $attrs,

      // component properties
      clustersList: [],
      selectedCluster: undefined,
      selectedClusters: [],

      // component methods
      selectAll: selectAll,
      updateExternalModel: updateExternalModel,

      // lifecycle hooks
      $onInit: $onInit,
    });

    /**
     * Initialize this component.
     *
     * @method     $onInit
     */
    function $onInit() {
      _.assign($ctrl, {
        id: [($attrs.id || componentName), 'ui-select'].join('-'),
        showMetaInfo: $attrs.hasOwnProperty('showMetaInfo'),
        showSelectAllOption: $attrs.hasOwnProperty('showSelectAllOption'),
        multiple: $attrs.hasOwnProperty('multiple'),
        mcmMode: !!$ctrl.mcmMode,
        addNewCluster:
          $attrs.hasOwnProperty('addNewEnabled') ? addNewCluster : undefined,
      });

      // fetch list of clusters.
      getClusters();
    }

    /**
     * Fetches list of clusters.
     *
     * @method     getClusters
     */
    function getClusters() {
      $ctrl.loading = true;

      // don't include access cluster in case of helios env because in helios
      // iris is running in the mock mode.
      RemoteClusterService
        .getRemoteAccessClusters(!$ctrl.mcmMode /* include self */)
        .then(function getClusters(clusters) {
          $ctrl.clustersList = clusters;

          // selecting all clusters.
          if ($ctrl.defaultSelectAll) {
            $ctrl.selectAll(true);
          }

          // setup external model update hook when select-cluster is ready.
          // update internal model on external model changes.
          $ctrl.ngModel.$render = updateInternalModel;

          // manually update the internal model to initialize them.
          updateInternalModel();
        }, evalAJAX.errorMessage)
        .finally(function finallyGetClusters() {
          $ctrl.loading = false;
        });
    }

    /**
     * Update internal model with external model changes.
     *
     * @method   updateInternalModel
     */
    function updateInternalModel() {
      $ctrl.selectedClusters = [];
      $ctrl.selectedCluster = undefined;

      if ($ctrl.ngModel.$isEmpty($ctrl.ngModel.$viewValue)) {
        return;
      }

      if ($ctrl.multiple) {
        $ctrl.clustersList.forEach(function eachCluster(cluster) {
          if (_.find($ctrl.ngModel.$viewValue, getEqualSelectionFn(cluster))) {
            $ctrl.selectedClusters.push(cluster);
          }
        });
      } else {
        $ctrl.clustersList.some(function eachCluster(cluster) {
          if (_.isEqual($ctrl.ngModel.$viewValue, getProperty(cluster))) {
            $ctrl.selectedCluster = cluster;
            return true;
          }
          return false;
        });
      }
    }

    /**
     * Return a fn used to determine if selected item is equals to provided
     * cluster.
     *
     * @method   getEqualSelectionFn
     * @param    {Object}     cluster   The Cluster info.
     * @return   {function}   The function used to determine if selected item
     *                        is equals to provided cluster.
     */
    function getEqualSelectionFn(cluster) {
      /**
       * Determines if selected item is equals to cluster.
       *
       * @method   isEqualSelection
       * @param    {Object}    item   The item to test.
       * @return   {boolean}   Return True if selected item equals to cluster.
       */
      return function isEqualSelection(item) {
        return _.isEqual(item, getProperty(cluster));
      };
    }

    /**
     * Update external model value on internal model changes.
     *
     * @method   updateExternalModel
     */
    function updateExternalModel() {
      var value;

      if ($ctrl.multiple) {
        value = _.map($ctrl.selectedClusters, getProperty);

        // if no selected value then set value to null to make original required
        // validation works.
        value = value.length ? value : null;
      } else {
        value = getProperty($ctrl.selectedCluster) || null;
      }

      $ctrl.isAllSelected =
        $ctrl.selectedClusters.length === $ctrl.clustersList.length;

      // update external mode
      $ctrl.ngModel.$setViewValue(value);
    }

    /**
     * Returns the cluster property that needed to be in the selected cluster
     * info.
     *
     * @method   getProperty
     * @param    {Object}    cluster   The cluster info.
     * @return   {any}       Returns the cluster property that needed to be in
     *                       the selected cluster info.
     */
    function getProperty(cluster) {
      return $ctrl.resultAs ? _.get(cluster, $ctrl.resultAs) : cluster;
    }

    /**
     * Select all clusters.
     *
     * @method   selectAll
     * @param    status   True, if select all is selected, else false.
     */
    function selectAll(status) {
      $ctrl.isAllSelected = status;
      $ctrl.selectedClusters = status ? $ctrl.clustersList : [];
      updateExternalModel();
    }

    /**
     * Launch add cluster workflow.
     *
     * @method   addNewCluster
     */
    function addNewCluster() {
      RemoteClusterService.registerRemoteSlider();
    }

  }
})(angular);
