import { Injectable } from '@angular/core';
import { BANNER_PRIORITY, BannerConfig } from '@cohesity/helix';
import {
  BaseBannerConfig,
  flagEnabled,
  IrisContext,
  IrisContextService,
  isRpaasScope,
  isRpaasUser,
} from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { quorumGroupStateParams, RpaasQuorumService } from 'src/app/app-services/rpaas/shared/services/rpaas-quorum.service';

@Injectable({
  providedIn: 'root',
})
export class RpaasQuorumBannerConfig extends BaseBannerConfig {
  /**
   * Unique banner representation
   */
  id = 'rpaas-quorum-banner';

  /**
   * provides banner config as observable
   */
  bannerInfo$ = combineLatest([
    this.irisCtx.irisContext$,
    this.rpaasQuorumService.hasRpaasQuorumGroups$,
    this.rpaasQuorumService.hasPartiallyConfiguredGroup$,
  ]).pipe(
    map(
      ([ctx, hasGroups, hasPartialGroup]) =>
        ([{
          id: this.id,
          priority: BANNER_PRIORITY.HIGH,
          isVisible: this.allowBanner(ctx, hasGroups),
          status: 'warn',
          allowClose: false,
          text: this.translateService.instant(
            hasPartialGroup ? 'banner.rpaasQuorum.missingPermission' : 'banner.rpaasQuorum.info'
          ),
          actionText: this.translateService.instant('createQuorumGroup'),
          actionCallback: () => {
            this.stateService.go('helios-security-quorum-groups-add', quorumGroupStateParams);
          },
        } as BannerConfig])
    )
  );

  constructor(
    private irisCtx: IrisContextService,
    private rpaasQuorumService: RpaasQuorumService,
    private stateService: StateService,
    private translateService: TranslateService
  ) {
    super();
  }

  /**
   * Allow banner based on context
   *
   * @param ctx Iris context
   * @param hasGroups has group boolean
   * @returns true/false based on context
   */
  allowBanner(ctx: IrisContext, hasGroups: boolean) {
    return (
      !hasGroups &&
      ctx?.user?.privilegeIds?.length &&
      flagEnabled(ctx, 'rpaasEnabled') &&
      isRpaasScope(ctx) &&
      isRpaasUser(ctx)
    );
  }
}
