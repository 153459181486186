<mat-form-field class="filter-input-field" floatLabel="never">
  <input
    type="text"
    matInput
    cohAutoFocus
    [placeholder]="'dg.td.scan.objectSearchPlaceholder' | translate"
    [formControl]="searchControl"
    (keyup.enter)="enterKeyUp.next(searchControl.value)"
    cogDataId="object-search-input"
    [inheritDataId]="true"/>

  <mat-progress-bar
    class="searching"
    mode="indeterminate"
    *ngIf="searchPending">
  </mat-progress-bar>
</mat-form-field>
