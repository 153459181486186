// COMPONENT:  Recover NetApp & NAS parent controller
import { recoveryGroup } from 'src/app/shared/constants';

;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.restoreNas';
  var moduleDeps = ['C.restoreCommon', 'C.jobRunsService'];

  // @type {array}  - List of permitted flowTypes. Expecting 2nd 'clone' type in
  //                  future.
  var flowTypes = ['recover'];
  var flowType = flowTypes[0];
  var stateNameRoot = [flowType, 'nas'].join('-');
  var stateNames = [
      flowType,
      [stateNameRoot, 'search'].join('.'),
      [stateNameRoot, 'options'].join('.'),
  ];

  angular
    .module(moduleName, moduleDeps)
    .config(ConfigFn)
    .controller('nasRestoreParentController', nasRestoreParentControllerFn);

  /**
   *  Config: States & Routing
   **************************************************************************/
  function ConfigFn($stateProvider) {
    var restoreModifyAccess = ctx => {
      return ctx.RESTORE_MODIFY &&
        !ctx.FEATURE_FLAGS.restoreStorageVolume &&
        ctx.canAccessSomeEnv(recoveryGroup.nas);
    };

    $stateProvider
      .state('recover-nas', {
        url: '^/protection/recovery/nas',
        title: 'Recover NAS',
        params: {
          flowType: flowTypes[0],
        },
        canAccess: restoreModifyAccess,
        parentState: 'recover',
        views: {
          '': {
            templateUrl: 'app/views/page-layouts/ls.html',
            controller: 'nasRestoreParentController',
          },
          'col-l@recover-nas': {
            templateUrl: 'app/protection/recovery/nas/nas.parent.html',
          },
        },
        redirectTo: 'recover-nas.search'
      })
      .state('recover-nas.search', {
        url: '/search',
        help: 'protection_recovery_nas_search',
        title: 'Recover NAS: Select Volume',
        canAccess: restoreModifyAccess,
        parentState: 'recover-nas',
        views: {
          'canvas@recover-nas': {
            controller: 'nasRestoreSearchController',
            templateUrl: 'app/protection/recovery/nas/nas.search.html',
          },
        },
      })
      .state('recover-nas.options', {
        url: '/options?{entityId}&{parentId}&{jobId}&{jobInstanceId}',
        help: 'protection_recovery_nas_options',
        title: 'Recover NAS: Task Options',
        canAccess: restoreModifyAccess,
        parentState: 'recover-nas',
        params: {
          entityId: { type: 'string' },
          parentId: { type: 'string' },
          jobId: { type: 'string' },
          jobInstanceId: { type: 'string' },
        },
        views: {
          'canvas@recover-nas': {
            controller: 'nasRestoreOptionsController',
            templateUrl: 'app/protection/recovery/nas/nas.options.html',
          },
        },
      });
  }


  /**
   * recoverNas Controller
   **************************************************************************/
   function nasRestoreParentControllerFn($rootScope,
     $scope, $state, $q, evalAJAX, ExternalTargetService,
     SearchService, cSearchService, SourceService, RestoreService,
     ViewBoxService, JobService, ClusterService, ViewService, ENV_GROUPS,
     cUtils) {

    /**
     * The default RecoverArg object. Require properties are uncommented.
     * @type   {RecoverArg}
     */
    var defaultTask = {
      action: 10,
      // continueRestoreOnError: undefined,
      name: '',
      // kMountFileVolume
      objects: [],
      restoreParentSource: undefined,
      viewName: '',
    };

    angular.extend($scope, {
      // GENERAL SCOPE VARS
      shared: {
        cart: [],
        filters: [],
        filterLookups: {},
        pagedResults: [],
        results: [],
        searchId: 'nas-search',
        searchEndpoint: SearchService.getSearchUrl('nas'),
        selectedVlanTarget: undefined,
        task: angular.copy(defaultTask)
      },

      // SCOPE METHODS
      addToCart: addToCart,
      setupSteps: setupSteps,
      startFlowOver: startFlowOver,
      submitTask: submitTask,
      updateTaskWithObjects: RestoreService.generateRestoreTaskObjects,
    });


    // WATCHERS
    $scope.$watch('shared.cart', cartUpdateHandler, true);


    /**
     * Returns text key for FS Protocol mode: NFS, SMB, or Mixed Mode.
     *
     * @method     getModeTextKey
     * @param      {object}  entity  The entity
     * @return     {string}  Mode string
     */
    $scope.getModeTextKey = function getModeTextKey(source) {
      var entity = source.vmDocument.objectId.entity;

      // The proto needs reconstruction to match what is expected by a shared
      // method in SourceService
      var constructedSource = {
        entity: source.registeredSource,
        type: source._type,
        _entityKey: source._entityKey
      };

      switch(entity.type) {
        case 9:
          return SourceService.areProtocolsSupported(constructedSource, [0, 1]) ?
            'mixedMode' :
            SourceService.getFSProtocols(
              entity.netappEntity.volumeInfo.dataProtocolVec,
              9
            );

        case 11:
          return SourceService.getFSProtocols(
            [entity.genericNasEntity.protocol],
            11
            );

        case 14:
          return SourceService.areProtocolsSupported(constructedSource, [0, 1]) ?
            'mixedMode' :
            SourceService.getFSProtocols(
              entity.isilonEntity.mountPointInfo.supportedProtocolVec,
              14
            );
      }
    };

    $scope.getFSProtocols = SourceService.getFSProtocols;


    // METHODS
    /**
     * Activate this ngController!
     *
     * @method     activate
     */
    function activate() {
      // Pick up the flowType from the state params
      flowType = $scope.flowType = $state.params.flowType || flowTypes[0];

      // A couple convenience Bools for knowing which type of flow we're in.
      $scope.isRecover = (flowType === flowTypes[0]);
      $scope.isClone = !$scope.isRecover;

      setupSteps();
      fetchDependencies();
    }

    /**
     * Handles $watch events for the task cart.
     *
     * @method   cartUpdateHandler
     * @param    {array}   nv   The current cart value
     * @param    {array}   ov   The previous cart value
     */
    function cartUpdateHandler(nv, ov) {
      // Empty cart or same entity
      if (!nv || !nv.length || angular.equals(nv, ov)) {
        return;
      }

      $scope.shared.task.objects = $scope.updateTaskWithObjects(nv);
    }

    /**
     * Convenience to start the flow over (first step, which can change
     * depending on how the flow is accessed).
     *
     * @method   startFlowOver
     */
    function startFlowOver() {
      $state.go(stateNames[1], undefined, {
        location: 'replace',
        reload: true
      });
    }

    /**
     * Determines if the given object is already in the task cart.
     *
     * @method   isInCart
     * @param    {object}   entity   A single search result object
     * @return   {boolean}           True if the object is already in the
     *                               cart
     */
    function isInCart(entity) {
      return $scope.shared.cart.some(function findInCart(cartEntity) {
        if (cartEntity._id === entity._id) {
          return true;
        }
      });
    }

    /**
     * Sets up this flow's steps.
     *
     * @method   setupSteps
     */
    function setupSteps() {

      stateNameRoot = [flowType, 'nas'].join('-');
      stateNames = [
        flowType,
        [stateNameRoot, 'search'].join('.'),
        [stateNameRoot, 'options'].join('.'),
      ];

    }

    /**
     * Adds an item to the cart and transitions to the next step.
     *
     * @method   addToCart
     * @param    {object}    item      The item to add to the cart
     * @param    {boolean}   proceed   Proceed to next step when true.
     *                                 Default: true
     */
    function addToCart(item, proceed) {
      proceed = angular.isDefined(proceed) ? proceed : true;

      // Validate that this is a useful result object.
      if (!item || !item.vmDocument || !item._snapshot) {
        return;
      }
      // Add _archiveTarget if no local snapshots exist.
      angular.extend(item, {
        _archiveTarget: (item._snapshot.replicaInfo &&
          Array.isArray(item._snapshot.replicaInfo.replicaVec) &&
          item._snapshot.replicaInfo.replicaVec[0] &&
          item._snapshot.replicaInfo.replicaVec[0].target &&
          (1 !== item._snapshot.replicaInfo.replicaVec[0].target.type)) ?
          // It's not local, use it. First replica will be local.
          item._snapshot.replicaInfo.replicaVec[0] :
          // It's local, don't set it.
          undefined
      });

      $scope.shared.cart = [item];

      if (proceed) {
        $state.go(stateNames[2]);
      }
    }

    /**
     * Fetch dependencies from the server. Mostly for filter lookups.
     *
     * @method     fetchDependencies
     */
    function fetchDependencies() {
      var depsPromises = [
        // viewBoxes
        ViewBoxService.getOwnViewBoxes(),

        // ParentSources: kNetapp & kGenericNas environments
        SourceService.getSources({
          onlyReturnOneLevel: true,
          envTypes: cUtils.onlyNumbers(ENV_GROUPS.nas)
        }),

        // All protected NAS Volumes
        SourceService.getEntitiesOfType({
          environmentTypes: ['kNetapp', 'kGenericNas'],
          netappEntityTypes: 'kVolume',
          genericNasEntityTypes: 'kHost',
          isProtected: true,
        }),

        // All NAS jobs
        JobService.getJobs({envTypes: cUtils.onlyNumbers(ENV_GROUPS.nas)}),

        // Get the external targets
        $rootScope.user.privs.CLUSTER_EXTERNAL_TARGET_VIEW ?
          ExternalTargetService.getTargets() : $q.resolve([]),

        // Fetch QoS Policies
        ViewService.getQosPrincipals(),
      ];

      return $q.all(depsPromises).then(function allFetched(responses) {
        initFilterLookups();
        if (Array.isArray(responses[0])) {
          $scope.shared.filterLookups.viewBoxIds = responses[0];
        }
        if (responses[1] && responses[1].entityHierarchy &&
          responses[1].entityHierarchy.children) {
            $scope.shared.filterLookups.registeredSourceIds =
              responses[1].entityHierarchy.children;
        }
        if (Array.isArray(responses[2])) {
          $scope.shared.filterLookups.entityIds = responses[2];
        }
        if (Array.isArray(responses[3])) {
          $scope.shared.filterLookups.jobIds = responses[3].map(
            function augmentJobsFn(job) {
              return angular.merge(job, {
                jobName: job.name
              });
            }
          );
        }
        if (Array.isArray(responses[4])) {
          $scope.shared.filterLookups.externalTargets =
            angular.copy(ExternalTargetService.targetNameMapById);
        }
        $scope.clusters = $scope.shared.filterLookups.clusters =
          ClusterService.updateClusterHash(ClusterService.clusterInfo);

        $scope.shared.filterLookups.qosPolicies = responses[5];
      },
      evalAJAX.errorMessage);
    }

    /**
     * Sets up the lookups skeleton needed for this flow
     *
     * @method     initFilterLookups
     */
    function initFilterLookups() {
      $scope.shared.filterLookups = angular.extend({
        entityIds: [],
        viewBoxIds: [],
        registeredSourceIds: [],
        jobIds: []
      }, $scope.shared.filterLookups);
    }

    /**
     * Submit the task as configured.
     *
     * @method   submitTask
     * @param    {object}   form   The form object
     */
    function submitTask(form) {
      if (!form || form.$invalid) {
        return false;
      }

      $scope.shared.task.restoreVlanParams = RestoreService.getVlanParams(
        $scope.shared.selectedVlanTarget
      );

      $scope.isSubmitting = true;
      RestoreService.restoreVM($scope.shared.task).then(
        function taskAcceptedSuccess(restoreTask) {
          // TODO: document the scenario in which this restoreTask might come
          // back as falsy. Currently unknown, but based on code it seems like
          // a real scenario.
          var targetType = (restoreTask &&
            restoreTask.performRestoreTaskState.objects[0].archivalTarget) ?
              'archive' : 'local';
          var stateName =
            RestoreService.getRestoreTaskDetailStateName('recover', targetType);
          var stateParams = {
            id: restoreTask && restoreTask.performRestoreTaskState.base.taskId,
          };

          $state.go(stateName, stateParams);
        },
        evalAJAX.errorMessage
      )
      .finally(
        function taskAcceptedFinally() {
          $scope.isSubmitting = false;
        }
      );

    }

    // Activate!
    activate();

  }


})(angular);
