// Service: PhysicalServerRecoveryService contains method for physical server
// recovery controller to populate $scope values/functionalities to be used in
// the template.

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.physicalServerRecovery')
    .service('PhysicalServerRecoveryService', physicalServerRecoveryServiceFn);

  function physicalServerRecoveryServiceFn($rootScope, $state, $q, $timeout,
    $stateParams, cMessage, SlideModalService, SourceService, ViewBoxService,
    DateTimeService, JobService, SearchService, RestoreService, evalAJAX,
    ENUM_HOST_TYPE, FEATURE_FLAGS) {

    var physicalServerRecoveryService = {

      // Getters for state object
      getActionTypeOptions: getActionTypeOptions,
      getCart: getCart,
      getOStype: getOStype,
      getRecoverStateNames: getRecoverStateNames,
      getSharedState: getSharedState,
      getSnapshotVolumes: getSnapshotVolumes,
      getTargetVolumeLabel: getTargetVolumeLabel,
      getTask: getTask,
      getTaskActionType: getTaskActionType,

      // Helper methods
      addToCart: addToCart,
      clearCart: clearCart,
      fetchDependencies: fetchDependencies,
      getVolumes: getVolumes,
      initRecoverTask: initRecoverTask,
      initSearchConfig: initSearchConfig,
      isTargetDisabled: isTargetDisabled,
      selectRestorePoint: selectRestorePoint,
      selectTarget: selectTarget,
      selectTargetVolume: selectTargetVolume,
      selectVolumes: selectVolumes,
      setupAbbreviatedFlow: setupAbbreviatedFlow,
      setAllVolumesSelected: setAllVolumesSelected,
      startFlowOver: startFlowOver,
      submitTask: submitTask,
      toggleAllVolumes: toggleAllVolumes,
      resetSelectedSnapshot: resetSelectedSnapshot,
      resetSourceTargetMapping: resetSourceTargetMapping,
      unsetTargetVolume: unsetTargetVolume,
      updateTaskWithArchiveTarget: updateTaskWithArchiveTarget,
      updateTaskWithEntity: updateTaskWithEntity,
      updateTaskWithSnapshot: updateTaskWithSnapshot,
      updateTaskWithTargetEntity: updateTaskWithTargetEntity,
    };

    var defaultRestoreTaskState = {

      // @type       {string} The task name
      name: undefined,

      // @type       {string} The task restore type,
      // available recover types for physical server are
      // [kSelect, kSystem, kRecoverVolumes]
      action: undefined,

      // @type       {array} The object(s) to recover BMR/Volumes from (single
      // item in an array)
      objects: [],

      // @type       {object} Mount disk params
      mountVolumesParams: {

        // @type       {object} The chosen target Entity (P2P, or VM2VM)
        targetEntity: undefined,

        // @type       {array} Array of volume name Strings
        volumeNameVec: undefined,

        // @type       {object} VMware specific params
        vmwareParams: {

          // No UI for this yet
          // @type       {bool}
          bringDisksOnline: false,

          // @type       {object} Target Entity admin credentials
          targetEntityCredentials: undefined,
        },
      },

      // @type       {object} Recover volume params
      recoverVolumesParams: {

        // @type       {object} The chosen target Entity (P2P, or VM2VM)
        targetEntity: undefined,

        // @type       {array} Array of volume mapping data
        mappingVec: undefined,
      },
    };

    /**
     * Default list of Filters for use in the cSearchFilters.
     *
     * @example
        {
          property: {String},
          display: {String|Fn=},
          primary: {Bool=},
          locked: {Bool=},
          transformFn: {Fn=},
          value: {Array|Integer|String|Bool=}
        }
     * @type       {Array}
     */
    var defaultFilterOptions = [{
      property: 'registeredSourceIds',
      display: $rootScope.text.sourceName,
      transformFn: sourceIdFromName,
      locked: false
    }, {
      display: $rootScope.text.serverType,
      property: 'entityTypes',
      transformFn: transformEntityTypeFilter,
      locked: false
    }, {
      property: 'viewBoxIds',
      display: $rootScope.text.viewBox,
      transformFn: viewBoxIdFromName,
      locked: false
    }, {
      property: 'jobIds',
      display: $rootScope.text.protectionJob,
      transformFn: jobIdFromName,
      locked: false
    }, {
      property: 'fromTimeUsecs',
      display: $rootScope.text.startDate,
      transformFn: DateTimeService.dateToUsecs,
      locked: false
    }, {
      property: 'toTimeUsecs',
      display: $rootScope.text.endDate,
      transformFn: DateTimeService.dateToUsecs,
      locked: false
    }, {
      property: 'vmName',
      primary: true
    }];

    // Recover types. For physical server recover flow, available recover types
    // are BMR (also known as system) and Volume.
    // @type       {Array}
    var actionTypeOptions = [
      {
        textKey: 'bareMetalRecovery',
        kValue: 'kSystem',
      },
      {
        textKey: 'volume',
        kValue: 'kRecoverVolumes',
      },
    ];

    // Volume Recover types. For physical server Volume recover flow, available
    // recover types are regular Recover and Instant Mount.
    // @type       {Array}
    var volumeRecoverTypes = [
      'kMountVolumes',
      'kRecoverVolumes',
    ];

    /**
    * Ordered list of state names in this recovery flow
    *
    * @type       {Array}
    */
    var recoverStateNames = [
      'recover',
      'recover-physical-server.search',
      'recover-physical-server.options',
    ];

    // This is shared object across all the steps in this recovery flow
    var sharedState = {
      task: {},

      // @type       {array} The object(s) to recover BMR/Volumes
      cart: [],
      showDbs: {},
      selectedVlanTarget: undefined,
      snapshotVolumes: [],

      isFailover: false,
      areAllVolumesSelected: false,
      allSourceVolumesSelected: false,
      showRecoverOptions: false,

      // can be useful in future
      isClone: false,
      isRecover: false,
    };


    /**
     * Gets the recover state names.
     *
     * @method   getRecoverStateNames
     * @return   {string}   The recover state names.
     */
    function getRecoverStateNames() {
      return recoverStateNames;
    }

    /**
     * Gets the shared state.
     *
     * @method   getSharedState
     * @return   {object}   The shared state.
     */
    function getSharedState() {
      return sharedState;
    }

    /**
     * Gets the cart which has entities in it.
     *
     * @method   getCart
     * @return   {array}   The cart array with entity objects.
     */
    function getCart() {
      return sharedState.cart;
    }

    /**
     * Gets the task.
     *
     * @method   getTask
     * @return   {Object}   The Physical server recover or Instant mount volume
     *                      recover task.
     */
    function getTask() {
      return sharedState.task;
    }

    /**
     * Gets the task action type / recovery type.
     *
     * @method   getTaskActionType
     * @return   {string}   The task action/recover type.
     */
    function getTaskActionType() {
      return sharedState.task.action;
    }

    /**
     * Sets the task action type.
     *
     * @method   setTaskActionType
     * @param    {string}   action   The action
     */
    function setTaskActionType(action) {
      sharedState.task.action = action;
    }

    /**
     * Sets the default task action type.
     *
     * @method   setDefaultTaskActionType
     * @param    {object}   entity   The entity
     */
    function setDefaultTaskActionType(entity) {
      // Physical volume recover option feature flag protected
      if (!FEATURE_FLAGS.physicalVolumeRecover) {
        // kSystem action is the only available action
        return setTaskActionType(actionTypeOptions[0].kValue);
      }

      if (entity._systemSnapshots.length && entity._volumeSnapshots.length) {
        setTaskActionType();
      } else if (entity._systemSnapshots.length) {
        setTaskActionType(actionTypeOptions[0].kValue);
      } else if (entity._volumeSnapshots.length) {
        setTaskActionType(actionTypeOptions[1].kValue);
      }
    }

    /**
     * Gets the snapshot volumes.
     *
     * @method   getSnapshotVolumes
     * @return   {array}   The snapshot volumes.
     */
    function getSnapshotVolumes() {
      return sharedState.snapshotVolumes;
    }

    /**
     * Gets the action type options.
     *
     * @method   getActionTypeOptions
     * @return   {array}   The action type options.
     */
    function getActionTypeOptions() {
      return actionTypeOptions;
    }

    /**
     * Initializes recover task with defaults
     *
     * @method     initRecoverTask
     */
    function initRecoverTask() {
      return sharedState.task = angular.merge({}, defaultRestoreTaskState, {
        name: RestoreService.getDefaultTaskName('recover',
          $rootScope.text.physicalServer)
      });
    }

    /**
     * Toggles the "Select all Volumes" option and does some cleanup when
     * the checkbox is checked.
     *
     * @method   setAllVolumesSelected
     * @param    {boolean=}   areAllSelected   True if all volumes are to
     *                                         be selected, False otherwise.
     */
    function setAllVolumesSelected(areAllVolumesSelected) {
      sharedState.areAllVolumesSelected = !!areAllVolumesSelected;

      // This is an inverted toggle, in that checking it hides additional
      // controls, and unchecking displays them:
      // Unchecked = cherry pick disks;
      // Checked = mount everything.
      if (areAllVolumesSelected) {

        // The user has checked "Select all Volumes". When the user checks
        // this, it means they don't want to cherry pick any volumes, but
        // instead mount all protected disks for the VMDK. So we will
        // clear any selection (deselect) and send nothing, which Magneto
        // interperets literally as "mount all protected disks."

        // Slight delay to allow animation to complete then empty the
        // selection.
        $timeout(deselectAllVolumes, 75);
      }
    }

    /**
     * Private method that deselects all volumes from the task model AND in the
     * options for uiSelect.
     *
     * @method   deselectAllVolumes
     */
    function deselectAllVolumes() {
      if (sharedState.task.mountVolumesParams) {
        sharedState.task.mountVolumesParams.volumeNameVec = undefined;
      }

      // Deselect all options
      sharedState.snapshotVolumes.forEach(function deselectAll(volume) {
        volume.selected = false;
      });
    }

    /**
     * Update the task with the given Entity and any other task defaults
     * that are entity related.
     *
     * @method     updateTaskWithEntity
     * @param      {Object}  entity  The Entity
     */
    function updateTaskWithEntity(entity) {
      sharedState.task.objects = [entity.vmDocument.objectId];

      // If the new entity is a different type than any previously
      // selected targetEntity, clear targetEntity & restoreParentSource
      // params out. They have to be the same type.
      if (sharedState.task.mountVolumesParams.targetEntity &&
        sharedState.task.mountVolumesParams.targetEntity.type !==
        entity.vmDocument.objectId.entity.type) {
        angular.extend(sharedState.task, {
          restoreParentSource: undefined,
          mountVolumesParams:
            angular.copy(defaultRestoreTaskState.mountVolumesParams),
        });
      }

      // If the new entity is a different type than any previously
      // selected targetEntity, clear targetEntity & restoreParentSource
      // params out. They have to be the same type.
      if (sharedState.task.recoverVolumesParams.targetEntity &&
        sharedState.task.recoverVolumesParams.targetEntity.type !==
        entity.vmDocument.objectId.entity.type) {
        angular.extend(sharedState.task, {
          restoreParentSource: undefined,
          recoverVolumesParams:
            angular.copy(defaultRestoreTaskState.recoverVolumesParams),
        });
      }
    }

    /**
     * Update the task with the given restore Target
     *
     * @method     updateTaskWithTargetEntity
     * @param      {object}  target  The target Entity
     */
    function updateTaskWithTargetEntity(target) {

      // Check to see if this entity exists locally in this cluster
      SourceService.getEntitiesById(target.id).then(
        function getEntitiesSuccess(resp) {
          // If the response is not empty, we have the target data locally.
          // Use it... wisely
          resp = Array.isArray(resp) && resp[0];
          if (angular.isObject(resp)) {
            angular.merge(sharedState.task, {
              // parentId will exist for VM but not physical.
              restoreParentSource: target.parentId ?
                {id: target.parentId} : undefined,
              mountVolumesParams: {
                targetEntity: resp,
              },
              recoverVolumesParams: {
                targetEntity: resp,
              },
            });
          }
        }
      );
    }

    /**
     * Update the task with the given snapshot
     *
     * @method     updateTaskWithSnapshot
     * @param      {object}  snapshot  The entity snapshot
     *
     * TODO: Code works only for one entity, need to make it work on multuple
     *       entities when we support it.
     */
    function updateTaskWithSnapshot(snapshot) {
      var instance = snapshot && snapshot.instanceId;
      if (instance) {
        angular.merge(sharedState.task.objects[0], {
          jobInstanceId: instance.jobInstanceId,
          startTimeUsecs: instance.jobStartTimeUsecs,
        });

        angular.merge(sharedState.cart[0]._snapshot.instanceId, {
          jobInstanceId: instance.jobInstanceId,
          startTimeUsecs: instance.jobStartTimeUsecs,
          jobStartTimeUsecs: instance.jobStartTimeUsecs,
        });
      }
    }

    /**
     * Update the task with the given archiveTarget
     *
     * @method     updateTaskWithArchiveTarget
     * @param      {object}  target  The archiveTarget
     */
    function updateTaskWithArchiveTarget(target) {
      angular.merge(sharedState.task.objects[0], {
        archivalTarget: target && target.target &&
          target.target.archivalTarget
      });
    }

    /**
     * Reset the selected snapshot
     *
     * @method   resetSelectedSnapshot
     * @param    {object}   entity   The task entity
     */
    function resetSelectedSnapshot(entity) {
      sharedState.cart[0]._snapshot = getDefaultSnapshot(entity);
    }

    /**
     * Reset the source target map array to default value.
     *
     * @method   resetSourceTargetMapping
     */
    function resetSourceTargetMapping() {
      sharedState.task.recoverVolumesParams.mappingVec = undefined;
    }

    /**
     * Gets the default snapshot.
     *
     * @method   getDefaultSnapshot
     * @param    {object}   entity   The task entity
     * @return   {object}   The default snapshot.
     */
    function getDefaultSnapshot(entity) {
      return sharedState.task.action === 'kSystem' ?
        entity._systemSnapshots[0] :
        entity._volumeSnapshots[0];
    }

    /**
     * Gets the volumes for the currently selected entity and snapshot and
     * sets them up in shared state object.
     *
     * @method     getVolumes
     */
    function getVolumes(entity) {
      var params = {};
      var unmountableCount = 0;

      // From the task: entityId jobUidObjectId, jobInstanceId,
      // jobStartTimeUsecs, attemptNum
      params = angular.extend({
          entityId: entity.vmDocument.objectId.entity.id,
          jobId: entity.vmDocument.objectId.jobId,
          jobUidObjectId: entity.vmDocument.objectId.jobUid.objectId,
        },
        entity.vmDocument.objectId.jobUid,
        entity._snapshot.instanceId);

      // if we are in 'Instant Volume Mount' recovery flow we only want to get
      // supported volumes
      if ($state.current.name === 'recover-physical-server.options') {
        params.supportedVolumesOnly = false;
      }

      // Some pre-fetch resets
      sharedState.loadingVolumes = true;
      sharedState.snapshotVolumes.length = 0;
      sharedState.volumesUnknown = false;

      RestoreService
        .getVolumeInfo(params)
        .then(function getVolumeInfoSuccess(resp) {
          sharedState.snapshotVolumes =
            resp.volumeInfos.sort(sortByDisplayName)
              .map(function selectOptionsMapper(volume) {

                // For VMs. only NTFS volumes can be mounted at this time.
                var isMountable = !entity._isVM ||
                  ['ntfs', 'refs'].includes(volume.filesystemType.toLowerCase());

                // Increment the count of unmountable volumes if
                // this is not mountable
                unmountableCount += (!isMountable) ? 1 : 0;

                return angular.extend(volume, {
                  name: volume.displayName || volume.name,
                  value: volume.name,
                  filesystemType: volume.filesystemType,

                  // For whatever reason, volume.isSupported can
                  // be true, but if it's not an NTFS volume we
                  // can't mount it.
                  disabled: !isMountable,
                });

              });
        })
        .finally(function getVolumeInfoFinally() {
          sharedState.volumesUnknown = !sharedState.snapshotVolumes.length;
          sharedState.noSupportedVolumes = !sharedState.volumesUnknown &&
            entity._isVM &&
            (unmountableCount === sharedState.snapshotVolumes.length);
          sharedState.loadingVolumes = false;

          if (sharedState.volumesUnknown) {
            sharedState.task.mountVolumesParams.vmwareParams.bringDisksOnline =
              false;
            setAllVolumesSelected(true);
          }

        });
    }

    /**
     * Array.filter Fn to sort by `displayName` property (ascending)
     *
     * @method     sortByDisplayName
     * @param      {object}   a       An object
     * @param      {object}   b       Another object
     * @return     {integer}  Sorted by 1 or -1
     */
    function sortByDisplayName(a, b) {
      return (a.displayName < b.displayName) ? -1 : 1;
    }

    /**
     * Gets the OS type.
     *
     * @method     getOStype
     * @param      {object}  entity  The entity
     * @return     {string}  The OS type string
     */
    function getOStype(entity) {
      if (entity._isPhysical) {
        return ENUM_HOST_TYPE[entity.vmDocument.objectId.entity.physicalEntity.hostType] ||
          'unknown';
      }
      return entity.vmDocument.osType || 'unknown';
    }

    /**
     * Removes the given Entity from the cart
     *
     * @method     clearCart
     */
    function clearCart() {
      sharedState.cart.length = 0;
    }

    /**
     * Shared function to start the flow over and replaces the browser history
     *
     * @method     startFlowOver
     */
    function startFlowOver() {
      $state.go(recoverStateNames[1], undefined, {
        location: 'replace'
      });
    }

    /**
     * Adds the given entity to the task Cart. Currently enforces single
     * item restrictions
     *
     * @method     addToCart
     * @param      {Object}  entity  Server Entity
     */
    function addToCart(entity) {
      entity._systemSnapshots = [];
      entity._volumeSnapshots = [];

      // Split version array into system snapshots and volume snapshots arrays
      // so that to populate corresponding snapshots on select spanshot modal
      entity.vmDocument.versions.forEach(function splitVersion(version) {
        // type 3 is for system backup type
        if (version.scheduledBackupType === 3) {
          entity._systemSnapshots.push(version);
        } else {
          entity._volumeSnapshots.push(version);
        }
      });

      sharedState.cart = [entity];

      $state.go(recoverStateNames[2], {
        entity: entity.vmDocument.objectId.entity,
        host: entity.registeredSource,
        jobId: entity.vmDocument.objectId.jobId
      }, false).then(function() {
        setDefaultTaskActionType(entity);
      });
    }

    /**
     * Submit the task for restoration
     *
     * @method     submitTask
     */
    function submitTask(form) {
      sharedState.isSubmitting = true;

      if (getTaskActionType() === 'kSystem') {
        // Delete mountVolumesParams since this is not required to create BMR
        // recovery task
        sharedState.task.mountVolumesParams = undefined;
        sharedState.task.recoverVolumesParams = undefined;
      } else {
        setVolumeRecoverySettings();
      }

      // Set aws glacier retrieval type from sharedState.retrievalOption into
      // vaultRestoreParams.glacier.retrievalType
      if (sharedState.retrievalOption) {
        _.set(sharedState.task, 'vaultRestoreParams.glacier.retrievalType',
          sharedState.retrievalOption);
      }

      return RestoreService.restoreVM(sharedState.task)
        .then(function taskAcceptedFn(restoreTask) {
          var taskId = restoreTask.performRestoreTaskState.base.taskId;

          // Handle the response
          sharedState.restoreTask = restoreTask;
          clearCart();

          // Navigate to task detail page
          if (restoreTask.performRestoreTaskState.objects[0].archivalTarget) {
            $state.go('recover-detail-archive', {id: taskId});
          } else {
            $state.go('recover-detail-local', {id: taskId});
          }
        }, evalAJAX.errorMessage)
        .finally(function taskSubmittedFn() {
          sharedState.isSubmitting = false;
        });
    }

    /**
     * Sets the instant volume mount settings.
     *
     * @method   setVolumeRecoverySettings
     */
    function setVolumeRecoverySettings() {

      if (sharedState.showRecoverOptions) {
        // Instant Mount flow
        setTaskActionType(volumeRecoverTypes[0]);
        sharedState.task.recoverVolumesParams = undefined;

        // If the volumes vec is an empty list, delete because it means
        // mount all volumes.
        if (sharedState.task.mountVolumesParams.volumeNameVec &&
          !sharedState.task.mountVolumesParams.volumeNameVec.length) {
            sharedState.task.mountVolumesParams.volumeNameVec = undefined;
        }
      } else {
        // Recover Volume flow. Remove Instant mount param
        setTaskActionType(volumeRecoverTypes[1]);
        sharedState.task.mountVolumesParams = undefined;
      }

      sharedState.task.restoreVlanParams = RestoreService.getVlanParams(
        sharedState.selectedVlanTarget
      );
    }

     /**
     * Sets up the lookups skeleton needed for this flow
     *
     * @method     initFilterLookups
     */
    function initFilterLookups() {
      sharedState.filterLookups = sharedState.filterLookups || {
        entityIds: [],
        entityTypes: [{
          display: $rootScope.text.physicalServers,
          value: 'kPhysical'
        }, {
          display: $rootScope.text.vms,
          value: 'kVMware'
        }],
        viewBoxIds: [],
        registeredSourceIds: [],
        jobIds: []
      };
    }

    /**
     * Fetch dependencies from the server. Mostly for filter lookups.
     *
     * @method     fetchDependencies
     */
    function fetchDependencies() {
      var promises = [
        // viewBoxes
        ViewBoxService.getOwnViewBoxes(),
        // ParentSources
        SourceService.getEntitiesOfType({
          environmentTypes: ['kVMware', 'kPhysical'],
          vmwareEntityTypes: ['kVCenter'],
          physicalEntityTypes: ['kHost']
        }),

        // All protected Servers (VM+Physical)
        SourceService.getServers(true),

        // All Server jobs - VM(1), SQL(3), and Physical(6)
        JobService.getJobs({envTypes: [1, 3, 6]}),
      ];

      return $q.all(promises)
        .then(function allFetched(responses) {
          initFilterLookups();
          if (Array.isArray(responses[0])) {
            sharedState.filterLookups.viewBoxIds = responses[0];
          }
          if (Array.isArray(responses[1])) {
            sharedState.filterLookups.registeredSourceIds = responses[1];
          }
          if (Array.isArray(responses[2])) {
            sharedState.filterLookups.entityIds = responses[2];
          }
          if (Array.isArray(responses[3])) {
            sharedState.filterLookups.jobIds = responses[3].map(
              function augmentJobsFn(job) {
                return angular.merge(job, {
                  jobName: job.name,
                });
              }
            );
          }
        });
    }

    /**
     * Init the shared scope object.
     *
     * @method     initSearchConfig
     */
    function initSearchConfig() {
      angular.extend(sharedState, {
        searchId: 'physical-server-search',
        results: [],
        pagedResults: [],
        filters: angular.copy(defaultFilterOptions),
        selectedResults: [],
        endpoint: SearchService.getSearchUrl('physicalserver')
      });
    }

    /**
     * Transform a single, or an array of Source names to Source IDs
     *
     * @method     sourceIdFromName
     * @param      {object|array}  names   Array Source names
     * @return     {array}         Array of Source IDs
     */
    function sourceIdFromName(names) {
      var out = [];
      if (names) {
        names = [].concat(names);
        return sharedState.filterLookups.registeredSourceIds
          .reduce(function matchSources(sources, source) {
            if (names.includes(source.entity.vmwareEntity.name)) {
              sources.push(source.entity.id);
            }
            return sources;
          }, []);
      }
      return out;
    }

    /**
     * Transforms an entityType filter object to its filter key
     *
     * @method     transformEntityTypeFilter
     * @param      {Object}  selections  selected Entity type filters
     * @return     {String}  The filter key
     */
    function transformEntityTypeFilter(selections) {
      var out = [];
      if (selections) {
        selections = [].concat(selections);
        selections.forEach(function getEntityTypeKeys(entityTypeFilter) {
          out.push(entityTypeFilter.value);
        });
      }
      return out;
    }

    /**
     * TransformFn for viewBox search filter
     *
     * @method     viewBoxIdFromName
     * @param      {object|array}  viewBoxes  The viewBox names to get the
     *                                        IDs for
     * @return     {array}         The viewBox ids
     */
    function viewBoxIdFromName(viewBoxes) {
      var out = [];
      if (viewBoxes) {
        viewBoxes = [].concat(viewBoxes);
        return sharedState.filterLookups.viewBoxIds
          .reduce(function matchViewboxes(boxes, vb) {
            if (viewBoxes.includes(vb.name)) {
              boxes.push(vb.id);
            }
            return boxes;
          }, []);
      }
      return out;
    }

    /**
     * Transform an array of Job names to Job IDs
     *
     * @method     jobIdFromName
     * @param      {Array}  names   Array of Job names
     * @return     {Array}          Array of Job IDs
     */
    function jobIdFromName(names) {
      var out = [];
      if (names) {
        names = [].concat(names);
        return sharedState.filterLookups.jobIds
          .reduce(function matchJobs(jobs, job) {
            if (names.includes(job.jobName)) {
              jobs.push(job.jobId);
            }
            return jobs;
          }, []);
      }
      return out;
    }

    /**
     * Open the snapshot selector to let the user choose a different restore
     * point.
     *
     * @method     selectRestorePoint
     * @param      {object}  row     The search result entity in question
     */
    function selectRestorePoint(row) {
      row.vmDocument.versions =
        sharedState.task.action === 'kSystem' ?
          row._systemSnapshots :
          row._volumeSnapshots;

      var modalOpts = {
        templateUrl: 'app/protection/recovery/common/snapshot-selector/common.snapshot-selector.html',
        controller: 'commonRestoreSnapshotModalController',
        size: 'lg',
        resolve: {
          task: angular.copy(sharedState.task),
          entity: row,
        }
      };

      SlideModalService
        .newModal(modalOpts)
        .then(function snapshotSelectedFn(resp) {

          angular.extend(row, {
            _snapshot: resp.snapshot,
            _archiveTarget: resp.archiveTarget,
          });

          getVolumes(row /* entity */); // Update the volumes with selected snapshot.
          updateTaskWithSnapshot(row._snapshot);
          updateTaskWithArchiveTarget(row._archiveTarget);
        });
    }

    /**
     * Remove an entry from mappingVec on unchecking a source volume
     *
     * @method   unsetTargetVolume
     * @param    {object}   volume   The source volume
     */
    function unsetTargetVolume(volume) {
      // Remove entry from mappingVec on unchecking a source volume
      if (!volume.selected) {
        sharedState.task.recoverVolumesParams.mappingVec =
          (sharedState.task.recoverVolumesParams.mappingVec || []).filter(
            function remove(vol) {
              return vol.srcGuid !== volume.volumeGuid;
            });
        volume._targetVolume = undefined;
      }
    }

    /**
     * Applies the selected volumes from uiSelect to the task model
     *
     * @method   selectVolumes
     * @param    {array}   volumes   The list of selected volumes. Can be empty.
     */
    function selectVolumes(volumes) {
      sharedState.task.mountVolumesParams.volumeNameVec = volumes
        .map(function volumeVecMapper(volume) {
          return volume.value || volume.name;
        });
    }

    /**
     * Create/update source target map
     *
     * @method   selectTargetVolume
     * @param    {object}   volume  Target volume.
     */
    function selectTargetVolume(volume) {
      if (!isSourceAlreadyAssignedTarget(volume)) {
        sharedState.task.recoverVolumesParams.mappingVec =
          sharedState.task.recoverVolumesParams.mappingVec || [];
        sharedState.task.recoverVolumesParams.mappingVec.push({
          srcGuid: volume.volumeGuid,
          dstGuid: volume._targetVolume.volumeGuid ||
                    volume._targetVolume.devicePath,
        });

        // Source volume should be checked on selecting target volume for it
        volume.selected = true;
      }
    }

    /**
     * Determines if source already assigned a target and update the destination
     * with newly selected target
     *
     * @method   isSourceAlreadyAssignTarget
     * @param    {object}    volume   The volume
     * @return   {boolean}   True if source already assign target,
     *                       False otherwise.
     */
    function isSourceAlreadyAssignedTarget(volume) {
      return (sharedState.task.recoverVolumesParams.mappingVec || []).some(
        function checkSourceVolume(vol) {
          if (vol.srcGuid === volume.volumeGuid) {
            vol.dstGuid = volume._targetVolume.volumeGuid;
            return true;
          }
      });
    }

    /**
     * Determines if target disabled.
     *
     * @method   isTargetDisabled
     * @param    {object}    volume Source volume
     * @param    {object}    target Target volume
     * @return   {boolean}   True if target is already selected for other source
     *                       volume, target size is less than source volume's
     *                       size, target doesn't have volumeGuid or target is
     *                       boot volume otherwise False.
     */
    function isTargetDisabled(volume, target) {
      return (sharedState.task.recoverVolumesParams.mappingVec || []).some(
        function checkTargetVolume(vol) {
          return vol.dstGuid === target.volumeGuid &&
            vol.srcGuid !== volume.volumeGuid;
        }) ||
        target.logicalSizeBytes < volume.diskVec[0].partitionVec[0].length ||
        target.isBootVolume || !target.volumeGuid;
    }

    /**
     * Check all and uncheck all
     *
     * @method   toggleAllVolumes
     */
    function toggleAllVolumes() {
      sharedState.snapshotVolumes.forEach(function toggleVolume(volume) {
        volume.selected = sharedState.allSourceVolumesSelected;
      });
    }

    /**
     * Open the Browse for Server Modal and select a new Target.
     *
     * @method     selectTarget
     */
    function selectTarget() {
      // Determine entityType (vm or physical)
      var envType = sharedState.task.objects[0].entity.type;
      var filters = {
        // Manually excluding standalone ESXi targets until supported
        excludeTypes: [10],
        requireVmTools: false,
        singleSelect: true,
      };

      // Physical Server environment, set host type (windows/linux) filtering
      var hostType = envType === 6 ?
        sharedState.task.objects[0].entity.physicalEntity.hostType :
        undefined;

      // Open modal with type restrictions
      SourceService.browseForLeafEntities(envType, hostType, filters)
        .then(updateTaskWithTargetEntity);
    }

    /**
     * Gets the target volume label in the required format.
     * e.g "V:\ (vdrive)"
     * NOTE: move this to $filter if this become useful in other context
     *
     * @method   getTargetVolumeLabel
     * @param    {object}   volume   The volume
     * @return   {string}   The target volume label.
     */
    function getTargetVolumeLabel(volume) {
      var label = '';
      if (volume && volume.mountPointVec) {
        label = [
          label,
          volume.mountPointVec[0]].join('');
      }
      if (volume && volume.volumeLabel) {
        label = [
          label,
          ' (',
          volume.volumeLabel,
          ')'].join('');
      }

      return label;
    }

    /**
     * Function to make some state object changes for Abbreviated flow
     *
     * @method     setupAbbreviatedFlow
     */
    function setupAbbreviatedFlow() {

      var foundMatch;

      sharedState.isAbbreviatedFlow = true;

      // Fetch by entityId & jobId
      SearchService.serverSearch({
        entityIds: $stateParams.entityId,
        jobIds: $stateParams.jobId,
        registeredSourceIds: $stateParams.hostId,
      })
      .then(function getServerSuccess(resp) {
        if (resp && resp.length) {
          // Parse the response for the matching entity and add it to the cart
          foundMatch = resp.some(function findMatchingEntity(entity) {
            // Using == here because these $stateParams are strings
            if ($stateParams.entityId == entity.vmDocument.objectId.entity.id &&
              $stateParams.jobId == entity._jobId) {

              // If we've selected a jobInstanceId, match it and assign that
              // snapshot
              if ($stateParams.jobInstanceId) {
                entity.vmDocument.versions.some(
                  function snapshotFinder(run) {
                    if (run.instanceId.jobInstanceId ===
                        $stateParams.jobInstanceId) {
                      entity._snapshot = run;
                      return true;
                    }
                  }
                );
              }

              // Add it to the cart!
              addToCart(entity);

              // Force to show Volume recover type and hide system recover type
              entity._systemSnapshots.length = 0;

              return true;
            }
          });
        }

        // No matching entity was found, show an error
        if (!foundMatch) {
          cMessage.error({
            titleKey: 'noServerFound',
            textKey: 'noServerFoundErrorCopy',
          });
        }
      });
    }


    return physicalServerRecoveryService;
  }
})(angular);
