/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UnregisterClustersResponse } from '../models/unregister-clusters-response';
import { UnregisterClustersRequest } from '../models/unregister-clusters-request';
import { McmRemoteTargets } from '../models/mcm-remote-targets';
import { UpgradeClustersResponse } from '../models/upgrade-clusters-response';
import { UpgradeClustersRequest } from '../models/upgrade-clusters-request';
@Injectable({
  providedIn: 'root',
})
class HeliosClustersService extends __BaseService {
  static readonly McmRegistrationClusterOpPath = '/public/mcm/clusters/registrations';
  static readonly McmRemoteTargetsPath = '/public/mcm/clusters/remoteTargets';
  static readonly McmUpgradeClusterOpPath = '/public/mcm/upgradeClusters';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Specifies the request to unregister clusters on Helios.
   * @param regionId This field represents the region id.
   * @param body Request params for uuregistering cluster.
   * @return Success
   */
  McmRegistrationClusterOpResponse(regionId?: string,
    body?: UnregisterClustersRequest): __Observable<__StrictHttpResponse<UnregisterClustersResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/mcm/clusters/registrations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UnregisterClustersResponse>;
      })
    );
  }
  /**
   * Specifies the request to unregister clusters on Helios.
   * @param regionId This field represents the region id.
   * @param body Request params for uuregistering cluster.
   * @return Success
   */
  McmRegistrationClusterOp(regionId?: string,
    body?: UnregisterClustersRequest): __Observable<UnregisterClustersResponse> {
    return this.McmRegistrationClusterOpResponse(regionId, body).pipe(
      __map(_r => _r.body as UnregisterClustersResponse)
    );
  }

  /**
   * Fetch the replication clusters and archival targets for given set of clusters.
   *
   * Fetch the replication clusters and archival targets for given set of clusters.
   * @param regionId This field represents the region id.
   * @param clusterIdentifiers Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   * @return Success
   */
  McmRemoteTargetsResponse(regionId?: string,
    clusterIdentifiers?: null | Array<string>): __Observable<__StrictHttpResponse<McmRemoteTargets>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    (clusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('clusterIdentifiers', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/mcm/clusters/remoteTargets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmRemoteTargets>;
      })
    );
  }
  /**
   * Fetch the replication clusters and archival targets for given set of clusters.
   *
   * Fetch the replication clusters and archival targets for given set of clusters.
   * @param regionId This field represents the region id.
   * @param clusterIdentifiers Specifies the list of cluster identifiers. Format is clusterId:clusterIncarnationId.
   * @return Success
   */
  McmRemoteTargets(regionId?: string,
    clusterIdentifiers?: null | Array<string>): __Observable<McmRemoteTargets> {
    return this.McmRemoteTargetsResponse(regionId, clusterIdentifiers).pipe(
      __map(_r => _r.body as McmRemoteTargets)
    );
  }

  /**
   * Specifies the request to upgrade clusters from Helios.
   * @param regionId This field represents the region id.
   * @param body Request params for upgrade cluster.
   * @return Success
   */
  McmUpgradeClusterOpResponse(regionId?: string,
    body?: UpgradeClustersRequest): __Observable<__StrictHttpResponse<Array<UpgradeClustersResponse>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/mcm/upgradeClusters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UpgradeClustersResponse>>;
      })
    );
  }
  /**
   * Specifies the request to upgrade clusters from Helios.
   * @param regionId This field represents the region id.
   * @param body Request params for upgrade cluster.
   * @return Success
   */
  McmUpgradeClusterOp(regionId?: string,
    body?: UpgradeClustersRequest): __Observable<Array<UpgradeClustersResponse>> {
    return this.McmUpgradeClusterOpResponse(regionId, body).pipe(
      __map(_r => _r.body as Array<UpgradeClustersResponse>)
    );
  }
}

module HeliosClustersService {
}

export { HeliosClustersService }
