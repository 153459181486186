// Module: IdP List

;(function(angular) {
  'use strict';

  angular
    .module('C.apiKeys')
    .controller('apiKeyListCtrl', apiKeyListCtrlFn);

  /**
   * Controller for the List view
   */
  function apiKeyListCtrlFn(_, $state, UserService, FEATURE_FLAGS) {
    var $ctrl = this;
    $ctrl.user = UserService.user;

    // Component Declaration
    _.assign($ctrl, {
      // Component Methods
      $onInit: $onInit,

      // Component Variables
      loading: true,
      showTopButton: false,
    });

    /**
     * Init the controller
     *
     * @method   $onInit
     */
    function $onInit() {
      _getApiKeys();
      $ctrl.showTopButton = FEATURE_FLAGS.ngUsersAndGroups;
    }

    /**
     * Retrieves the list of API Keys
     *
     * @method   _getApiKeys
     */
    function _getApiKeys() {
      $ctrl.loading = true;
      return UserService.getApiKeys().then(function getApiKeys(apiKeys) {
        $ctrl.apiKeys = apiKeys.map(_addStatus).map(_addContextMenu);
      }).finally(function finalizeApiKeys() {
        $ctrl.loading = false;
      });
    }

    /**
     * Function to add the status value to the API Key to sort on.
     *
     * @method   _addStatus
     * @param    {object}    apiKey    An API Key
     * @return   {object}    An updated API Key
     */
    function _addStatus(apiKey) {
      apiKey._status = String([apiKey.isActive, apiKey.isExpired])
      return apiKey;
    }

    /**
     * Add context menu actions to given API Key by reference
     *
     * @method   _addContextMenu
     * @param    {object}    apiKey    An API Key
     * @return   {object}    An updated API Key
     */
    function _addContextMenu(apiKey) {
      apiKey._contextItems = [];

      if ($ctrl.user.sid === apiKey.ownerUserSid || $ctrl.user.privs.PRINCIPAL_MODIFY) {
        // An expired API Key cannot be rotated.
        if (!apiKey.isExpired) {
          apiKey._contextItems.push({
            icon: 'icn-refresh',
            translateKey: 'rotateApiKey',
            action: function rotateApiKey() {
              $ctrl.loading = true;


              UserService.rotateApiKey(apiKey).finally(
                function finalizePromise() {
                  $ctrl.loading = false;
                }
              );
            },
          });
        }

        // Allow API Key updating if the API Key belongs to the logged in user,
        // or if logged in user has PRINCIPAL_MODIFY privilege.
        apiKey._contextItems.push(
          {
            icon: 'icn-edit',
            translateKey: 'edit',
            action: function editApiKey() {
              $state.go('modify-api-key', {
                id: apiKey.id,
                userSid: apiKey.ownerUserSid,
              });
            },
          },
          {
            icon: 'icn-delete',
            translateKey: 'delete',
            action: function deleteApiKey() {
              $ctrl.loading = true;

              UserService.deleteApiKey(apiKey).then(_getApiKeys);
            },
          }
        );
      }

      return apiKey;
    }
  }
})(angular);
