import { Injectable } from '@angular/core';
import { ProtectedObject } from '@cohesity/api/v2';
import { NavItem } from '@cohesity/helix';
import { StateService } from '@uirouter/core';
import { PassthroughOptions } from 'src/app/shared';

import { ProtectionGroupService } from '../protection-group-shared';
import { ObjectSearchResult, RestorePointSelection } from '../restore/restore-shared';
import { ObjectActionCreator } from './object-action-creator';
import { ProtectedObjectsService } from './protected-objects.service';

@Injectable({
  providedIn: 'root',
})
export class KubernetesObjectActionCreator extends ObjectActionCreator {
  constructor(
    objectsService: ProtectedObjectsService,
    protectionGroupService: ProtectionGroupService,
    stateService: StateService) {
    super(objectsService, protectionGroupService, stateService);
  }

  /**
   * Creates and returns a kubernetes action for the supplied protected object.
   *
   * @param object The protected object.
   * @param restorePointSelection The Restore point selection of the selected object.
   * @param objectOptions The object's passthrough options.
   * @returns The recovery NavItem object.
   */
  createBulkKubernetesRecoverAction(
    objects: ProtectedObject[],
    restorePointSelection?: RestorePointSelection,
    objectOptions: PassthroughOptions = {}
  ): NavItem {
    if (objects.length > 1 && restorePointSelection) {
      // Only one object is allowed if a restore point selection parameter is
      // provided.
      throw new Error('Only one object can be provided with a restore point selection.');
    }

    // Either use the provided restore point selection, or select the
    // default(latest) restore point selection from the object.
    const restorePoints = restorePointSelection ? [restorePointSelection] : objects.map(object => (
      new ObjectSearchResult(object as Required<ProtectedObject>).defaultRestorePointSelection
    ));

    return {
      displayName: 'recover',
      icon: 'restore',
      state: 'recover-kubernetes-ng',
      stateParams: {
        restorePoints,
        cid: objectOptions.accessClusterId,
        regionId: objectOptions.regionId,
      },
    };
  }
}
