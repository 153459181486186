import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NisProvider } from '@cohesity/api/v2';
import { AutoDestroyable } from '@cohesity/utils';
import { DialogService } from 'src/app/core/services';
import {
  ModifyNisDialogComponent,
  ModifyNISDialogParams,
} from 'src/app/modules/access-management/on-prem-access-management/nis/modify-nis-dialog/modify-nis-dialog.component';

/**
 * NIS provider selector component.
 *
 * @example
 * <coh-nis-provider-selector
 *  [control]="someFormControl"
 *  [allNisProviders]="allNisProviders"
 *  [showBackArrow]="true"
 *  (childDialogOpenStatus)="someAction()">
 * </coh-nis-provider-selector>
 */
@Component({
  selector: 'coh-nis-provider-selector',
  templateUrl: './nis-provider-selector.component.html',
})
export class NisProviderSelectorComponent extends AutoDestroyable {
  /**
   * Form control.
   */
  @Input() control: UntypedFormControl;

  /**
   * Array of values for the dropdown.
   */
  @Input() allNisProviders: NisProvider[] = [];

  /**
   * Whether to show back arrow for child NIS dialog. Default to false.
   */
  @Input() showBackArrow = false;

  /**
   * Classname for the <mat-form-field> element. Default to false.
   */
  @Input() className = '';

  /**
   * Value for the <mat-form-field floatLabel> attribute. Default to 'auto'.
   */
  @Input() floatLabel = 'auto';

  /**
   * Whether edit action is disabled or not. Default to false.
   */
  @Input() editDisabled = false;

  /**
   * Event emitter whether the child dialog(modify-nis-dialog) is open or closed.
   */
  @Output() readonly childDialogOpenStatus = new EventEmitter<boolean>();

  /**
   * Event emitter that new nis provider is added.
   */
  @Output() readonly nisProviderAdded = new EventEmitter<NisProvider>();

  constructor(private dialogService: DialogService) {
    super();
  }

  /**
   * Open ModifyNisDialog and add new NIS provider.
   */
  addNISProvider() {
    // Emit the childDialogOpenStatus event with true value.
    this.childDialogOpenStatus.emit(true);

    const dialogParam: ModifyNISDialogParams = {
      isEditMode: false,
      showBackArrow: this.showBackArrow,
    };

    this.dialogService
      .showDialog(ModifyNisDialogComponent, dialogParam)
      .pipe(this.untilDestroy())
      .subscribe((res: NisProvider) => {
        // Reset form value.
        if (res) {
          this.control.setValue(res.domain);
          this.nisProviderAdded.emit(res);
        } else {
          this.control.setValue(this.control.value);
          this.nisProviderAdded.emit();
        }

        // Emit childDialogOpenStatus event with false value.
        this.childDialogOpenStatus.emit(false);
      });
  }
}
