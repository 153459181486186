import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { HeliosLoginConfiguration } from '@cohesity/api/v2';
import { IrisContextService, isMcmGovCloud } from '@cohesity/iris-core';

/**
 * This service is used to rendered the necessary tags for Helios Login.
 */
@Injectable({
  providedIn: 'root'
})
export class SalesforceLoginRenderer {

  /**
   * Helps in rendering the element for salesforce embedded login.
   */
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    private irisContextService: IrisContextService,
    public metaService: Meta) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * Adds the necessary tags and scripts for rendering Salesforce embedded login.
   *
   * @param   info   The Helios Login Configuration
   */
  addSfdcTags(info: HeliosLoginConfiguration) {
    this.metaService.addTags([
      { name: 'salesforce-client-id', content: info.clientId },
      { name: 'salesforce-redirect-uri', content: info.redirectUrl },
      { name: 'salesforce-community', content: info.portalUrl },
      { name: 'salesforce-mode', content: 'inline' },
      { name: 'salesforce-use-min-js', content: 'true' },
      { name: 'salesforce-mask-redirects', content: 'false' },
      { name: 'salesforce-server-callback', content: 'true' },
      { name: 'salesforce-target', content: '#salesforce-login' },
      { name: 'salesforce-login-handler', content: 'onLogin' },
      { name: 'salesforce-logout-handler', content: 'onLogout' },
      { name: 'salesforce-allowed-domains', content: `${window.location.hostname}` },
      { name: 'salesforce-self-register-starturl-enabled', content: 'false' },
      { name: 'salesforce-server-starturl', content: 'authenticate' },
      { name: 'salesforce-forgot-password-enabled', content: 'true' },
    ]);

    const link = this.renderer.createElement('link');
    link.setAttribute('href', `${info.portalUrl}/servlet/servlet.loginwidgetcontroller?type=css`);
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');

    const script = this.renderer.createElement('script');
    script.setAttribute('src',
      `${info.portalUrl}/servlet/servlet.loginwidgetcontroller?type=javascript_widget&cacheMaxAge=2`);
    script.setAttribute('async', '');
    script.setAttribute('defer', '');
    this.renderer.appendChild(this.document.head, link);
    this.renderer.appendChild(this.document.head, script);

    if (isMcmGovCloud(this.irisContextService.irisContext)) {
      // Turn off autocomplete in case of GovCloud
      this.turnOffAutocomplete();
    }
  }

  /**
   * Turn off autocomplete for username and password fields.
   */
  turnOffAutocomplete() {
    const interval = setInterval(() => {
      const usernameInput = document.querySelector('#sfid-username');
      const passwordInput = document.querySelector('#sfid-password');

      if (usernameInput && passwordInput) {
        usernameInput.setAttribute('autocomplete', 'off');
        passwordInput.setAttribute('autocomplete', 'off');

        clearInterval(interval);
      }
    }, 50);
  }
}
