// Component: SQL Dashboard

(function _iife(angular) {

  angular.module('C.sqlDashboard')
    .controller('SqlDashboardController', DashboardCtrlFn)
    .component('sqlDashboard', {
      templateUrl: 'app/dashboard/sql/sql-dashboard.html',
      controller: 'SqlDashboardController',
    });

  /**
   * @ngdoc component
   * @name C.sqlDashboard:sqlDashboard
   *
   * @description
   * SQL Dashboard Main Page
   *
   * @example
   * <sql-dashboard></sql-dashboard>
   */
  function DashboardCtrlFn(_, $q, $state, evalAJAX, PollTaskStatus,
    SourceService, SqlDashboardService, SimpleCacheService) {
    var $ctrl = this;
    var isDoneDeferred = $q.defer();
    var pollIntervalSec = 60 * 8;

    _.assign($ctrl, {
      downloadCohesityAgent: SourceService.downloadAgentsModal,
      $onInit: $onInit,
      $onDestroy: $onDestroy,
    });

    $ctrl.tabConfig = [
      {
        headingKey: 'hosts',
        route: 'sql-dashboard.hosts',
      },
      {
        headingKey: 'databases',
        route: 'sql-dashboard.databases',
      },
    ];

    // list of dashboard widgets config
    $ctrl.widgets = [{
        component: 'wSqlStatus',
        posX: 1,
        posY: 1,
        colSpan: 3,
        bindings: {
          data: '$ctrl.status.status',
        },
      },
      {
        component: 'wSqlAlerts',
        posX: 1,
        posY: 2,
        bindings: {
          data: '$ctrl.alerts',
        },
      },
      {
        component: 'wSqlJobRuns',
        posX: 1,
        posY: 3,
        bindings: {
          data: '$ctrl.status.jobRuns',
        },
      },
      {
        component: 'wSqlDbStatus',
        posX: 2,
        posY: 2,
        rowSpan: 2,
        bindings: {
          data: '$ctrl.status.dbStatus',
        },
      },
      {
        component: 'wSqlRecoveryLog',
        posX: 3,
        posY: 2,
        rowSpan: 2,
        bindings: {
          data: '$ctrl.status.restoreTasks',
        },
      }
    ];

    /**
     * On onInit, set up the polling for the data call
     */
    function $onInit() {
      // Reset REST cache
      SimpleCacheService.clearCache();

      PollTaskStatus.createPoller({
        interval: pollIntervalSec,
        isDonePromise: isDoneDeferred.promise,
        iteratorFn: getData,
      });

      PollTaskStatus.createPoller({
        interval: pollIntervalSec,
        isDonePromise: isDoneDeferred.promise,
        iteratorFn: getAlerts,
      });
    }

    /**
     * on component destroy end polling for data.
     *
     * @method   $onDestroy
     */
    function $onDestroy() {
      isDoneDeferred.resolve();
    }

    /**
     * Make the actual call to retreive data
     *
     * @method   getData
     * @return   {object}   Promise for the dashboard request
     */
    function getData() {
      $ctrl.status = undefined;
      return SqlDashboardService.getDashboard().then(
        function onSuccess(status) {
          $ctrl.status = status;
        }, evalAJAX.errorMessage
      );
    }

    /**
     * Gets the Alerts. This is done separately from the dashboard due to
     * performance issues causing alerts to take longer to load.
     * TODO(dcoblentz) merge back with getData once alerts issues are addressed.
     *
     * @method   getAlerts
     * @return   {object}   Promise for the alerts request
     */
    function getAlerts() {
      $ctrl.alerts = undefined;
      return SqlDashboardService.getSqlAlerts().then(
        function onSuccess(alerts) {
          $ctrl.alerts = alerts;
        }, evalAJAX.errorMessage
      );
    }

  }

})(angular);
