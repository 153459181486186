<div class="flex-row margin-top" [cogDataId]="'chip-input-' + (id || label)">
  <mat-form-field class="chip-extension-field" [ngClass]="{'full-width': fullWidth}">
    <mat-label *ngIf="label" [cogDataId]="'label'" [inheritDataId]="true">{{label | translate}}</mat-label>
    <mat-chip-list #chipList>
      <mat-chip
        *ngFor="let extension of (control?.value || [])"
        [cogDataId]="'chip-' + extension"
        [inheritDataId]="true"
        (removed)="handleChipRemove(extension)">
        {{chipDisplayPrefix}}{{extension}}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
      <input [matChipInputFor]="chipList"
        name="scenarioName"
        [cogDataId]="'input'"
        [inheritDataId]="true"
        [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="handleChipAdd($event)"
        (paste)="handleChipPaste($event)">
    </mat-chip-list>
    <mat-hint *ngIf="hintMessage" [cogDataId]="'hint'" [inheritDataId]="true">{{hintMessage | translate}}</mat-hint>
    <mat-error *ngIf="invalidErrorMessage && chipList.errorState" [cogDataId]="'error'" [inheritDataId]="true">
      {{invalidErrorMessage | translate: {invalid: invalidChipValues} }}
    </mat-error>
  </mat-form-field>
  <button type="button"
    *ngIf="showCopyButton"
    mat-icon-button
    [cogDataId]="'copy-button'"
    [inheritDataId]="true"
    [matTooltip]="'copyToClipboard' | translate"
    class="margin-top-sm copy-chip-input-icon"
    [disabled]="chipList.errorState || !control?.value?.length"
    (click)="handleChipCopy()">
    <mat-icon>file_copy</mat-icon>
  </button>
</div>
