// Directive: isolateForm
;(function(angular, undefined) {

  angular.module('C').directive('isolateForm', isolateFormFn);

  /**
   * @ngdoc directive
   * @name C.directive:isolateForm
   * @description
   *   This directive should be attached to a nested ngForm to isolate it from
   *   its parent form, preventing it's validation errors from blocking the
   *   successful submission of the parent form
   *
   * @restrict 'E'
   * @example
     <form name="parentForm" ng-submit="submitForm()" novalidate>
      <input type="text" name="parentField">
      <div ng-form="childForm" isolate-form>
        <!-- with isolateForm added to ngForm, this childField's 'required'
        validation won't prevent the parentForm from submitting-->
        <input type="text" name="childField" required>
      </div>
     </form>
   */
  function isolateFormFn() {

    return {
      restrict: 'A',
      require: '?form',
      link: linkFn,
    };

    /**
     * Add an isolated Form controller into parent Form isolated Form hash
     * for future reference, you can use this reference to check or control the
     * isolated Form status.
     *
     * @method   addFormHash
     * @param    {Object}    parentForm       The parent Form controller.
     * @param    {Object}    formController   The Form controller to add.
     * @return   {function}  returns a fn which when called will remove the
     *                       added form ctrl from parent Form.
     */
    function addFormHash(parentForm, formCtrl) {
      if (!parentForm._isolatedFormHash) {
        parentForm._isolatedFormHash = {};
      }

      parentForm._isolatedFormHash[formCtrl.$name] = formCtrl;

      return function removeFromHash() {
        parentForm._isolatedFormHash[formCtrl.$name] = undefined;
      };
    }


    function linkFn(scope, element, attrs, formController) {

      var parentForm;
      var removeFormFromHash;

      if (!formController) {
        return;
      }

      // Note this uses private API $$parentForm
      parentForm = formController.$$parentForm;

      if (!parentForm) {
        return;
      }

      // Remove this form from parent controller
      parentForm.$removeControl(formController);

      // Keep a separate list of isolated Form inside parent from
      removeFormFromHash = addFormHash(parentForm, formController);

      // Remove added Form on isolated Form scope destroy
      scope.$on('$destroy', removeFormFromHash);
    }

  }

})(angular);
