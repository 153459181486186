import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts a string into an icon name.
 *
 * @example
 * <cog-icon [shape]="object.ioFilterStatusIcon | cdpIoFilterIcon"></cog-icon>
 */
@Pipe({
  name: 'cdpIoFilterIcon'
})
export class CdpIoFilterIconPipe implements PipeTransform {
  /**
   * Converts a status sting to an icon name.
   *
   * @param   statusObject  Status object
   * @returns The full icon name used in cog-icon
   */
  transform(ioFilterStatus: string): string {
    // TODO(lily): Adding other cases.
    switch (ioFilterStatus) {
      case 'IOActive':
        return 'helix:status-success!success';

      case 'IOActivationInProgress':
      case 'WaitingForCDPPolicyAttach':
      case 'IOInactive':
        return 'helix:status-warning!warning';

      case 'NotInstalled':
        return 'helix:status-error!critical';
    }
  }
}
