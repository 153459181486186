import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { RecoveryObject } from '../../model/recovery-object';
import { Recovery } from '../../model/recovery';
import { RecoveryObjectPopoverComponent } from '../recovery-object-popover/recovery-object-popover.component';
import { SelectionModel } from '@angular/cdk/collections';
import { StateService } from '@uirouter/core';
import { AjaxHandlerService } from '@cohesity/utils';
import { RestoreTasksServiceApi } from '@cohesity/api/v1';
import { SnackBarService } from '@cohesity/helix';
import { DialogService, PassthroughOptionsService } from 'src/app/core/services';
import { RestoreTasksApiService } from '@cohesity/api/private';
import { mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { RecoveryServiceApi } from '@cohesity/api/v2';

/**
 * Recovery detail page vm migration objects table.
 *
 * @example
 * <coh-vm-migration-object-table [recovery]="recovery"></coh-vm-migration-object-table>
 */
@Component({
  selector: 'coh-vm-migration-object-table',
  styleUrls: ['./vm-migration-object-table.component.scss'],
  templateUrl: './vm-migration-object-table.component.html',
  encapsulation: ViewEncapsulation.None
})
export class VmMigrationObjectTableComponent {
  /**
   * Recovery
   */
  @Input() recovery: Recovery;

  /**
   * Recovery Id
   */
  @Input() recoveryId: string;

  /**
   * Old Recovery data (restore task in old UI)
   *
   * Typed it to any as the v1 api has no current model.
   * This is temporary and will be updated once v2 api is available.
   */
  private _recoveryV1: any;

  /**
   * Setter for old Recovery data (restore task in old UI) and update object teardown data.
   */
  @Input() set recoveryV1(recoveryV1: any) {
    this._recoveryV1 = recoveryV1;
    // Method to update recoveryObject from old V1 api.
    this.parseTaskInformation();
  }

  /**
   * Getter for old Recovery data (restore task in old UI).
   *
   * Typed it to any as the v1 api has no current model.
   * This is temporary and will be updated once v2 api is available.
   */
  get recoveryV1(): any {
    return this._recoveryV1;
  }

  /**
   * Notify parent the protection group when its progress completes.
   */
  @Output() readonly progressComplete = new EventEmitter<Recovery>();

  /**
   * Recovery object popover component.
   */
  popover = RecoveryObjectPopoverComponent;

  /**
   * RecoverObject
   */
  recoveryObjects: RecoveryObject[];

  /**
   * Recovery object table default column names (only single progress).
   */
  readonly vmMigrationColumns = [
    'name',
    'recoveryPoint',
    'status',
    'sync',
  ];

  /**
   * Getter for recovery object table columns.
   */
  get columns(): string[] {
    return this.vmMigrationColumns;
  }

  /**
   * Stores the selected rows.
   */
  selection = new SelectionModel<RecoveryObject>(true, []);

  /**
   * Whether to show the bulk recovery actions for the objects table.
   */
  get showBulkRecoveryActionIcons(): boolean {
    return this.selection?.selected.every(object => object.status === 'kOnHoldError');
  }

  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private passthroughOptionsService: PassthroughOptionsService,
    private restoreTaskService: RestoreTasksServiceApi,
    private restoreTasksApi: RestoreTasksApiService,
    private snackBarService: SnackBarService,
    private state: StateService,
    private translate: TranslateService,
    private dialogService: DialogService,
    private recoveriesService: RecoveryServiceApi,
  ) {}

  /**
   * Performs bulk sync operation on the selected VMs
   */
  bulkSync() {
    // Get child restore task ids from selection
    const childRestoreTaskIds = this.selection.selected.map(object => object.taskId);
    this.syncVmMigrationTask(this.recovery, childRestoreTaskIds);
  }


  /**
   * Performs sync operation on one selected VM
   */
  syncVM(object: RecoveryObject) {
    this.syncVmMigrationTask(this.recovery, [object.taskId]);
  }

  /**
   * Transition to migration runs page
   */
  getRecoveryRunsDetail(object: RecoveryObject) {
    const subTaskId = object.taskId;
    this.state.go('recovery.runs', { id: this.recoveryId, subTaskId });
  }

  /**
   * A VM multistage restore task(VM migration) consists of subTasks containing one or more object restores.
   * This method parses the subTasks and maps the old v1 api params to the new v2 api params.
   *
   * @return   A flat list of object restores.
   */
  parseTaskInformation() {
    const subTasks = this.recoveryV1?.restoreSubTaskWrapperProtoVec;
    // Walk through each subTask and update the recoveryObjects array
    subTasks?.forEach(task => {
      const baseParams = task.performRestoreTaskState.base;
      let status: string = baseParams?.publicStatus;
      const taskId: number = baseParams?.taskId;
      const object = task.performRestoreTaskState.objects[0];
      const id = object.entity?.id?.toString();
      const progressTaskId = task.performRestoreTaskState?.progressMonitorTaskPath;
      const latestSnapshot = task.performRestoreTaskState?.multiStageRestoreTaskState?.syncTimeUsecs;
      const error: string = baseParams?.error?.errorMsg;

      // If status is kOnHold with an error, then new status Pending sync is shown.
      if (status === 'kOnHold' && !!error) {
        status = 'kOnHoldError';
      }
      // If there is no snapshot and no error in the response, it means the migration
      // has just started. Show appropriate status for this scenario.
      if (status === 'kOnHold' && !error && !latestSnapshot) {
        status = 'kRunning';
      }

      // Mapping v1 params to v2 params
      this.recovery.objects.forEach(recoveryObject => {
        if (recoveryObject.id === id) {
          recoveryObject.status = status;
          recoveryObject.taskId = taskId;
          recoveryObject.progressTaskId = progressTaskId;
          recoveryObject.recoveryPoint = latestSnapshot;
          recoveryObject.messages[0] = error;
        }
        this.recoveryObjects = this.recovery.objects;
      });
    });
  }

  /**
   * Sync VM migration task(VMware Only).
   *
   * @param   id  The migration task id.
   *
   * TODO(Sowmya): Added this service here temporarily as calling recovery service
   * causes circular dependency. Will move this to recovery service once
   * menu action UX change is done.
   */
  syncVmMigrationTask(recovery: Recovery, childRestoreTaskIds = []) {
    const restoreTaskParams: any = {
      body: {
        restoreTaskId: recovery.legacyId,
        childRestoreTaskIds: childRestoreTaskIds,
        options: {
          multiStageRestoreAction : 'kUpdate'
        }
      }
    };

    this.restoreTaskService.UpdateRestoreTask(restoreTaskParams).pipe(
      mergeMap(() => this.restoreTasksApi.getRestoreTask(recovery.legacyId,
        this.passthroughOptionsService.requestHeaders))
    ).subscribe(() => {
      this.state.reload();
      this.snackBarService.open(this.translate.instant('vm.syncMigrationModalSuccess'));
    },
    error => this.ajaxHandlerService.handler(error)
  );
  }

  /**
   * Performs cancel operation on one selected VM
   *
   * TODO(Sowmya): Added this service here temporarily as calling recovery service
   * causes circular dependency. Will move this to recovery service once
   * menu action UX change is done.
   */
  cancelVmMigration() {
    const migrateCanceldata = {
      confirmButtonLabel: 'cancelMigration',
      declineButtonLabel: 'continueMigration',
      title: 'cancelMigration',
      copy: 'vm.cancelMigrationModalConfirmation',
    };

    this.dialogService.simpleDialog(null, migrateCanceldata).subscribe((proceed: boolean) => {
      if (proceed) {
        this.recoveriesService.CancelRecoveryById({
          id: this.recoveryId,
        }).subscribe(
          () => {
            this.state.reload();
            this.snackBarService.open(this.translate.instant('vm.cancelMigrationModalSuccess'));
          },
          error => this.ajaxHandlerService.handler(error)
        );
      }
    });
  }

  /**
   * Track by function for the data table
   *
   * @param    index  Item index.
   * @param    item   The item.
   * @returns  The item id.
   */
  trackById(index: number, item: Recovery): string {
    return item.id;
  }
}
