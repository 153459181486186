import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AttributeFilter, FiltersServiceApi } from '@cohesity/api/reporting';
import { FiltersComponent, ValueFilterSelection } from '@cohesity/helix';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseReportFilterComponent } from '../base-report-filter.component';

@Component({
  selector: 'iris-rpt-policy-filter',
  templateUrl: './policy-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolicyFilterComponent extends BaseReportFilterComponent {

  constructor(filters: FiltersComponent, private api: FiltersServiceApi) {
    super(filters, 'policyId');
  }

  getFilterValues(): Observable<ValueFilterSelection[]> {
    return this.api.GetResources({ resourceType: 'Policies' }).pipe(
      map(response => response?.policies || []),
      map(policies =>
        policies.map(policy => ({
          label: this.getLabelWithSystemName(policy.name, policy.isGlobalPolicy ? undefined : policy.systemName),
          value: policy.id,
        }))
      )
    );
  }

  toApiValue(filterValues: ValueFilterSelection[]): AttributeFilter {
    if (!filterValues?.length) {
      return null;
    }
    return {
      attribute: this.property,
      filterType: 'In',
      inFilterParams: {
        attributeDataType: 'String',
        stringFilterValues: filterValues.map(value => value.value as string),
        attributeLabels: filterValues.map(value => value.label),
      }
    };
  }

  fromApiValue(apiValue: AttributeFilter): ValueFilterSelection[] {
    return (apiValue?.inFilterParams.stringFilterValues || []).map((value, index) => ({
      label: apiValue.inFilterParams.attributeLabels[index],
      value: value,
    }));
  }
}
