<cog-settings-list-item cogFormGroup
  [formGroup]="formGroup"
  [invalid]="formGroup.invalid"
  [label]="'rename' | translate">
  <cog-settings-list-item-value-display>
    {{ (isAnyObjectRenamed ? 'objectsRenamed' : 'none') | translate }}
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor>
    <div class="option-container">
      <div class="option-head">
        <h4 class="rename-col1">{{ 'originalName' | translate }}</h4>
        <h4 class="rename-col2">{{ 'newName' | translate }}</h4>
      </div>
      <div class="option-body">
        <ng-container [formArrayName]="formControlNames.objectRenameDetails"
          *ngFor="let renameControl of formGroupControls.objectRenameDetails.controls; let i=index">
          <coh-settings-list-object-rename-item [formControl]="renameControl">
          </coh-settings-list-object-rename-item>
        </ng-container>
      </div>
    </div>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
