<cog-table name="reovery-objects-table"
  reflowScrollSize="sm"
  *ngIf="!customComponent"
  [source]="recoveryObjects || []">
  <table mat-table
    matSort
    matSortActive="startTime"
    matSortDirection="desc"
    [trackBy]="trackById">

    <ng-container matColumnDef="name">
      <th mat-header-cell cogDataId="object-name-col" *matHeaderCellDef mat-sort-header>
        {{ 'object' | translate }}
      </th>
      <td mat-cell *matCellDef="let object">
        <div class="cog-table-object">
          <cog-icon  [shape]="object.objectType | recoveryObjectIcon:
            object.environment:
            object.osType:
            recovery.action">
          </cog-icon>
          <div class="cog-table-object-body">
            <div class="flex-truncate-wrapper">
              <div class="cog-table-object-name flex-truncate-text">
                {{ object.fullName }}
                <ng-container *ngIf="labelTemplate">
                  <ng-container *ngTemplateOutlet="labelTemplate; context: {object: object}">
                  </ng-container>
                </ng-container>
              </div>
              <span class="icn-view"
                [cogPopover]="popover"
                [popoverData]="object"
                (click)="$event.stopPropagation()">
              </span>
              <cog-tag *ngIf="object.tearDownStatus">
                {{ object.tearDownStatus | translate }}
              </cog-tag>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="targetType">
      <th cogDataId="object-target-type-col" mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'recoveredFrom' | translate }}
      </th>
      <td mat-cell *matCellDef="let object">
        <div class="c-media c-media-align-center" matTooltip="{{ object.archivalTargetInfo?.targetName | snapshotLocation }}">
          <cog-icon class="c-media-object target-type"
            [shape]="object.snapshotTargetType | backupTargetIcon: object.archivalTargetInfo">
          </cog-icon>
          <span class="c-media-body">
            {{ object.snapshotTargetType | backupTargetType: object.archivalTargetInfo }}
          </span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="recoveryPoint">
      <th cogDataId="object-creation-time-col" mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'recoveryPoint' | translate }}
      </th>
      <td mat-cell *matCellDef="let object">
        {{ object.recoveryPoint | cohDate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th cogDataId="object-status-col" mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'status' | translate }}
      </th>
      <td mat-cell *matCellDef="let object">
        <coh-pulse-status
          [status]="object.status"
          [taskPath]="object.progressTaskId"
          (taskFinish)="progressComplete.emit(object)">
        </coh-pulse-status>
      </td>
    </ng-container>

    <ng-container matColumnDef="instantRecoveryStatus">
      <th cogDataId="object-ir-status-col" mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'instantRecovery' | translate }}
      </th>
      <td mat-cell *matCellDef="let object">
        <coh-pulse-status
          [status]="object.instantRecoveryStatus"
          [taskPath]="object.instantRecoveryProgressTaskId"
          (taskFinish)="progressComplete.emit(object)">
        </coh-pulse-status>
      </td>
    </ng-container>

    <ng-container matColumnDef="datastoreMigrationStatus">
      <th cogDataId="object-dm-status-col" mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'datastoreMigration' | translate }}
      </th>
      <td mat-cell *matCellDef="let object">
        <coh-pulse-status
          [status]="object.datastoreMigrationStatus"
          [taskPath]="object.datastoreMigrationProgressTaskId"
          (taskFinish)="progressComplete.emit(object)">
        </coh-pulse-status>
      </td>
    </ng-container>

    <ng-container matColumnDef="startTime">
      <th cogDataId="object-start-time-col" mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'startTime' | translate }}
      </th>
      <td mat-cell *matCellDef="let object">
        {{ object.startTime | cohDate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="tearDownAttempts">
      <th cogDataId="object-teardown-tries-col" mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'tearDownTries' | translate }}
      </th>
      <td mat-cell *matCellDef="let object">
        {{ object.tearDownAttempts | number }}
      </td>
    </ng-container>

    <ng-container matColumnDef="durationMs">
      <th cogDataId="object-duration-col" mat-header-cell *matHeaderCellDef mat-sort-header colspan="2">
        {{ 'duration' | translate }}
      </th>
      <td mat-cell *matCellDef="let object">
        {{ object.durationMs | humanizeDuration }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"
      [cogDataId]="'object-anchor-' + (row.name | lowercase)"
      [class.no-activity-row]="!row.progressTaskId?.length || recovery?.environment === environment.kOracle"
      (click)="openActivityModal(row)"></tr>

    <!-- NoData Footer -->
    <ng-container matColumnDef="noData">
      <td [attr.colspan]="columns.length" mat-footer-cell *matFooterCellDef>
        {{ 'noObjectsFound' | translate }}
      </td>
    </ng-container>

    <tr class="nodata-row" mat-footer-row [class.hidden]="recoveryObjects?.length" *matFooterRowDef="['noData']"></tr>
  </table>
  <mat-paginator *ngIf="showPaginator"></mat-paginator>
</cog-table>
<ng-template *ngIf="customComponent" [cdkPortalOutlet]="customComponent"></ng-template>
