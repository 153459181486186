import { Directive, InjectionToken } from '@angular/core';

/**
 * Provides an ONLY_OWN injection token to all children of the element that added
 * this directive. This allows you to add the directive once for a page and have it be
 * applied automatically to all children of that page.
 */
export const ONLY_OWN = new InjectionToken<boolean>('onlyOwn');

/**
 * ONLY_OWN injectable token provider used when multitenancy is enabled to fetch source objects,
 * storage domains, views, policy etc things belonging to there tenant eg.
 * 1. Service Provider can't protect VMs owned by Tenants.
 * 2. Service Provider can't use storage domain assigned to tenant when tenantViewboxSharingEnabled is false.
 * 3. Service Provider can use storage domain assigned to tenant when tenantViewboxSharingEnabled is true.
 */
export const OnlyOwnProvider = {
  provide: ONLY_OWN,
  useValue: true,
};

/**
 * Components which need to use the value of any own for certain multitenancy settings
 * can check the value of the ONLY_OWN injection token to determine which api methods
 * to call to return appropriate objects for that user.
 */
@Directive({
  selector: '[cohOnlyOwn]',
  providers: [OnlyOwnProvider],
})
export class OnlyOwnDirective {
  constructor() {}
}
