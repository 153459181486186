import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ClusterService } from '../services/cluster.service';

@Injectable()
export class RemoteClustersInterceptor implements HttpInterceptor {
  constructor(
    private clusterService: ClusterService,
  ) {}

  /**
   * Full path of the remoteClusters API
   */
  remoteClustersPath = '/irisservices/api/v1/public/remoteClusters';

  /**
   * Intercepts http requests made to /remoteClusters API in MCM.
   *
   * @param request The http request. Request interceptors modify this.
   * @param next The http handler. Response interceptors listen for
   *             events on this.
   * @return An observable of HttpEvent based on next.handle()
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // /remoteClusters API does not work correctly in MCM context and if a
    // clusterId is present in the URL, it will not return the correct response.
    if (this.clusterService.isMcm &&
      request.method === 'GET' &&
      request.url.startsWith(this.remoteClustersPath + '/')) {
      // Make a call to the list /remoteClusters API instead
      const clusterId = request.url.slice(this.remoteClustersPath.length).replace(/\//g, '');
      const newRequest = request.clone({url: this.remoteClustersPath});

      // Return a modified response
      return next.handle(newRequest).pipe(map(event =>
        event instanceof HttpResponse
          ? event.clone({
            // Filter on the cluster id which was present in URL
            body: event.body.filter(item => String(item.clusterId) === clusterId)
          })
          : event
        )
      );
    }

    return next.handle(request);
  }
}
