<div class="object-name">
  <cog-icon [shape]="node.protectionSource | objectIcon : { isProtected: isNodeProtected }">
  </cog-icon>
  <h4 class="title">{{node.protectionSource.name}}</h4>
</div>


<!-- General Information -->
<div class="source-meta-grouping object-info-list">

  <ul>
    <!-- Instance ID -->
    <li class="source-meta-line-item" *ngIf="awsSource.type === 'kEC2Instance'">
      <span class="source-meta-label">{{'instanceId' | translate}}</span>
      {{awsSource.resourceId}}
    </li>

    <!-- Instance type -->
    <li class="source-meta-line-item" *ngIf="awsSource.type !== 'kEC2Instance'">
      <span class="source-meta-label">{{'type' | translate}}</span>
      {{type | translate}}
    </li>

    <!-- Instance type -->
    <li class="source-meta-line-item" *ngIf="awsSource.dbEngineId">
      <span class="source-meta-label">{{'dbEngineId' | translate}}</span>
      {{awsSource.dbEngineId | translate}}
    </li>

    <!-- OS Type -->
    <li class="source-meta-line-item" *ngIf="awsSource.hostType">
      <span class="source-meta-label">{{'type' | translate}}</span>
      {{('hostType.' + awsSource.hostType) | translate}}
    </li>

    <!-- Instance Size -->
    <li class="source-meta-line-item" *ngIf="node.logicalSize">
      <span class="source-meta-label">{{'size' | translate}}</span>
      {{node.logicalSize | byteSize}}
    </li>

    <!-- Region -->
    <li class="source-meta-line-item">
      <span class="source-meta-label">{{'awsRegion' | translate}}</span>
      {{awsSource.regionId | translate}}
    </li>
  </ul>
</div>

<coh-protection-status-meta class="margin-top-lg"
  [node]="node"
  [protectionLabel]="'sourceTreePub.tooltips.protected' | translate">
</coh-protection-status-meta>
