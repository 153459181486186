// Service: JobFlowService

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.jobModify')
    .service('JobFlowService', JobFlowServiceFn);

  function JobFlowServiceFn(
    _, PubJobServiceFormatter, ENV_GROUPS, FEATURE_FLAGS, cUtils) {

    // This Service's API
    return {
      addParamSourcesToJob: addParamSourcesToJob,
      addViewToJob: addViewToJob,
      updateJobBasedOnTree: updateJobBasedOnTree,
      removeMissingSourceIds: removeMissingSourceIds,
      addMissingSourceIds: addMissingSourceIds,
    };

    /**
     * Updates job and tree (by reference) so that the provided sources are
     * marked for protection in both.
     *
     * @param {array}    protectSources   Pre-defined sources to be protected.
     * @param {object}   job              The job configuration to update.
     * @param {object}   jobTree          The jobTree to update selection on.
     */
    function addParamSourcesToJob(protectSources, job, jobTree) {
      var sourceSpecialParams = {};

      // Find the nodes in the tree based on the passed params. This provides
      // the proper reference to the node, and includes any ancestor nodes for
      // the node.
      var preProtectNodes = PubJobServiceFormatter.findNodes(
        jobTree.tree,
        function compareFn(node) {
          return _.some(protectSources, { 'id': node.protectionSource.id });
        }
      );

      preProtectNodes.forEach(function selectNodeFn(node) {
        PubJobServiceFormatter.selectNode(node, {
          autoProtect:
            ENV_GROUPS.autoProtectSupported.includes(job.environment),
          tree: jobTree.tree,
          selectedObjectsCounts: jobTree.selectedCounts
        });
      });

      updateJobBasedOnTree(job, jobTree.tree);

      // For physical files Jobs, Its necessary to inject a default backup
      // path param. Nothing will be backed up without it so API will reject.
      if (job.environment === 'kPhysicalFiles') {
        var hostType = _.get(protectSources[0],
          'physicalProtectionSource.hostType');

        sourceSpecialParams = {
          sourceId: job.sourceIds[0],
          physicalSpecialParameters: {
            filePaths: [{
              backupFilePath: cUtils.getDefaultRootPath(hostType),
              skipNestedVolumes: true,
            }],
          },
        };

        angular.merge(job, {
          sourceSpecialParameters: [sourceSpecialParams]
        });
      }
    }

    /**
     * Updates the provided Job (by reference) to reflect the selections made in
     * the provided tree object.
     *
     * @method  updateJobBasedOnTree
     * @param   {object}   job    Job object to update based on tree selection
     * @param   {object}   tree   The tree to use for updating the Job.
     */
    function updateJobBasedOnTree(job, tree) {
      if (job.environment === 'kView') {
        addViewToJob(job._protectedView, job);

        // Remove the View object from the Job. Its not the same as a View
        // source object and isn't useful to maintain outside of this context.
        job._protectedView = undefined;
        return;
      }

      // Empty all "selection" related arrays, as they will be rebuilt.
      job.sourceIds.length = 0;
      job.excludeSourceIds.length = 0;
      job.vmTagIds.length = 0;
      job.excludeVmTagIds.length = 0;

      /**
       * Rebuild the job's sourceIds[], excludeSourceIds[], vmTagIds[], and
       * excludeVmTagIds[] arrays
       */
      tree.forEach(function loopNodesFn(node) {
        _processBranchForSourcesAndExclusions(node, job);
      });
    }

    /**
     * Adds a provided View to the Job object for new Job creation.
     *
     * @method   addViewToJob
     * @param    {object}   view   The View
     * @param    {object}   job    The Job
     * @return   {object}   The updated Job object (also updated by reference)
     */
    function addViewToJob(view, job) {
      /* Per API specification, when creating a new View Job parentSourceId,
       * sourceIds, and excludeSourceIds should not be provided to the API.
       */
      job.parentSourceId = undefined;
      job.sourceIds = undefined;
      job.excludeSourceIds = undefined;

      if (!view) {
        return job;
      }

      job.viewBoxId = view.viewBoxId;
      job.viewName = view.name;
      job._viewBoxName = view.viewBoxName;

      /*
       * Create a faux View protectionSource object for template display.
       */
      job._viewSource = {
        name: job.viewName,
        viewProtectionSource: {
          id: {
            id: view.viewId,
          },
        },
      };

      return job;
    }

    /**
     * Updates job configuration and source special parameters to remove missing
     * sourceIds in jobTree.
     *
     * @method  removeMissingSourceIds
     * @param   {Object} jobTree    Tree to get missing sourceIds.
     * @param   {Object} job        Job configuration to be updated.
     * @return  {void}
     */
    function removeMissingSourceIds(jobTree, job) {
      jobTree.missingSourceIds.forEach(
        function removeMissingIds(sourceId) {
          var index;

          job.sourceSpecialParameters.forEach(function eachParam(parameter) {
            if (parameter.sourceId == sourceId) {
              // Delete the complete key-value pair rather than setting it to
              // undefined to avoid `undefined` being mapped as a value in
              // syncSourceSpecialParametersMapAndList() function.
              delete job._sourceSpecialParametersMap[sourceId];
              PubJobServiceFormatter.syncSourceSpecialParametersMapAndList(job);
            }
          });

          index = job.sourceIds.indexOf(sourceId);

          if (~index) {
            job.sourceIds.splice(index, 1);
          }
      });
    }

    /**
     * Updates job configuration to add missing sourceIds based on the granular
     * selection.
     *
     * @method  addMissingSourceIds
     * @param   {Object} job        Job configuration to be updated.
     * @return  {void}
     */
    function addMissingSourceIds(job) {
      if (job.missingEntities && job.missingEntities.length) {
        job.missingEntities.forEach(
          function addMissingIds(entity) {
            if (!entity.isSelected) {
              job.sourceIds.push(entity.id);
            }
          });
      }
    }

    /**
     * Runs through a provided tree branch and adds its nodes to
     * $scope.job.sources or $scope.job.excludeSources as appropriate.
     *
     * @method   _processBranchForSourcesAndExclusions
     * @param    {object}   node   The current node being processed.
     * @param    {object}   job    The job object to update.
     */
    function _processBranchForSourcesAndExclusions(node, job) {
      switch (job.environment) {
        case 'kAzure':
          if (node._isLeaf && node._isSelected) {
            _addNodeToJobSources(node, job);
          }
          break;

        case 'kPhysical':
        case 'kPure':
        case 'kNimble':
        case 'kFlashBlade':
          if (node._isSelected) {
            _addNodeToJobSources(node, job);
          }
          break;

        default:
          // default processing serves VM, AWS, SQL, GCP & Office365 flow.

          switch (true) {
            // If a node is directly auto protected, it should be explicitly
            // represented in the Job. Add it.
            case node._isAutoProtected:
              _addNodeToJobSources(node, job);
              break;

            // If a node is directly excluded, it should be explicitly
            // represented in the Job. Exclude it.
            case node._isExcluded:
              _addNodeToJobExcludeSources(node, job);
              break;

            // Selected leaf nodes that are not covered by tag or ancestor Auto
            // Protection should be explicitly represented in the Job. Add it to
            // job.sources[]
            case !node._isAncestorAutoProtected &&
              !node._isTagAutoProtected &&
              node._isLeaf &&
              node._isSelected:
              _addNodeToJobSources(node, job);
              break;

            // If this is a SQL VM on VMware, and the feature is disabled, just
            // add the host to the job and none of the children because we only
            // support Volume-based SQL protection on VMware as of 6.0.1.
            case (!FEATURE_FLAGS.protectSqlFileBasedVmware &&
              job.environment === 'kSQL' &&
              node._environment === 'kVMware'):
              if (node._isSelected) {
                return _addNodeToJobSources(node, job);
              }
              break;
          }
      }

      if (Array.isArray(node.nodes)) {
        node.nodes.forEach(function recurseNodesFn(node) {
          _processBranchForSourcesAndExclusions(node, job);
        });
      }
    }

    /**
     * handles adding a node to the job's sources list. Updating job by ref.
     *
     * @method     _addNodeToJobSources
     * @param      {Object}  node    The node to add
     * @param      {Object}  job     The job object to update
     */
    function _addNodeToJobSources(node, job) {
      var alreadyInSources;

      if (node._isTagBranch) {
        // This is a derived tagBranch and needs special handling.
        job.vmTagIds.push(node.protectionSource.id);
        return;
      }

      // check all existing sources[] to ensure this
      // entity isn't already represented. This is
      // necessary as duplicate VM's selection related
      // booleans are marked to match each other
      alreadyInSources = job.sourceIds.some(function findSource(id) {
        return id === node.protectionSource.id;
      });

      if (!alreadyInSources) {
        job.sourceIds.push(node.protectionSource.id);
      }
    }

    /**
     * handles adding a node to excludeSources[]
     *
     * @param      {Object}  node    The node to exclude
     * @param      {Object}  job     The job object to update
     */
    function _addNodeToJobExcludeSources(node, job) {
      var alreadyExcluded;

      if (node._isTagBranch) {
        // This is a derived tagBranch and needs special handling.
        job.excludeVmTagIds.push(node.protectionSource.id);
        return;
      }

      // check all existing excludeSources[] to ensure this entity isn't already
      // represented. This is necessary as duplicate VM's selection related
      // booleans are marked to match each other.
      alreadyExcluded = job.excludeSourceIds.some(function findSource(id) {
        return id === node.protectionSource.id;
      });

      if (!alreadyExcluded) {
        job.excludeSourceIds.push(node.protectionSource.id);
      }
    }

  }

})(angular);
