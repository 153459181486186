<coh-source-special-parameters [form]="form" [nodeContext]="nodeContext"
  [label]="excludeAzureDisks?.length ?
    (excludeAzureDisks.length === 1 ? ('cSourceTreeNode.volumesExcludedSingular' | translate) :
      ('cSourceTreeNode.volumesExcludedPlural' | translate : {n: excludeAzureDisks.length})) :
    ('cSourceTreeNode.allVolumes' | translate)"
  (dialogOpened)="updateForm()"
  (saved)="onSave()"
  [dialogHeaderTemplate]="dialogHeaderTemplate">
  <div *ngIf="form" [formGroup]="form" class="azure-disk-exclusion-options">
    <div class="c-tile">
      <div class="info margin-top">
        {{'job.settings.excludeAzureDisks' | translate}}
      </div>
    </div>

    <ng-template #blankCard>
      <h1>{{ 'job.settings.noEbsVolumes' | translate }}</h1>
    </ng-template>

    <ng-container *ngIf="azureDisks?.length; else blankCard">
      <dl class="c-dl" formArrayName="volumes">
        <div class="dl-row">
          <dd class="azure-disk-exclusion-options-customized-dd">
            <div class="azure-disk-exclusion-options-name-header">
              {{'name' | translate}}
            </div>
          </dd>
        </div>
        <div class="dl-row" *ngFor="let diskInfo of diskInfoFormArray.controls; let i = index">
          <dd class="azure-disk-exclusion-options-customized-dd">
            <div class="azure-disk-details">
              <mat-checkbox [formControlName]="i"
                cogDataId="azure-disk-{{azureDisks[i].name}}"
                matTooltip="{{azureDisks[i].isOsDisk ? ('job.settings.rootEbsVolumeTooltip' | translate) : null}}"
                matTooltipPosition="after">
              </mat-checkbox>
              <div class="c-media c-media-align-center margin-left-sm">
                <cog-icon shape="helix:object-volume"></cog-icon>
                <div class="c-media-body margin-left-sm">
                  <div class="flex-truncate-wrapper">
                    <div class="flex-truncate-text detail-label user-data-sm auto-truncate"
                      [matTooltip]="azureDisks[i].name ? azureDisks[i].name : 'naNotAvailable' | translate"
                      matTooltipPosition="after">
                      {{ azureDisks[i].name ? azureDisks[i].name : 'naNotAvailable' | translate }}
                    </div>
                    <small class="margin-left-sm azure-disk-exclusion-options-size">{{ azureDisks[i].sizeBytes | byteSize }}</small>
                  </div>
                </div>
              </div>
            </div>
          </dd>
        </div>
      </dl>
    </ng-container>
  </div>
</coh-source-special-parameters>

<ng-template #dialogHeaderTemplate let-dialogRef="dialogRef">
  <div class="azure-disk-exclusion-options-dialog-header">
    <button class="back-button"
      (click)="dialogRef.close(false)"
      cogDataId="azure-options-back-button"
      mat-icon-button
      type="button">
      <cog-icon [shape]="backButtonIcon || 'arrow_back'"></cog-icon>
    </button>
    <h1 mat-dialog-title matTooltip="{{'serverOptions.volumesFor' | translate: {name: nodeContext.node.name} }}"
      matTooltipPosition="below">
      {{'serverOptions.volumesFor' | translate: {name: nodeContext.node.name} }}
    </h1>
  </div>
</ng-template>
