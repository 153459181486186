import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalModule, MsalService } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';

import { AuthenticatorService } from '../../services/authenticator.service';
import { AuthenticatorComponent } from './authenticator.component';

/**
 * Creates the Guard configuration for MSAL with the Interaction type.
 *
 * @returns Instance of MSAL Guard config.
 */
export function MSALGuardConfigFactory(authenticatorService: AuthenticatorService): MsalGuardConfiguration {
  return {
    interactionType: authenticatorService.isPopupWorkflowEnabled ? InteractionType.Popup : InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],

      // Ensure the user is given the option to choose the account or sign
      // through new one.
      prompt: 'select_account'
    },
  };
}

@NgModule({
  declarations: [
    AuthenticatorComponent
  ],
  imports: [
    CommonModule,
    CohesityHelixModule,
    MsalModule,
    TranslateModule.forChild(),
  ],
  exports: [
    AuthenticatorComponent
  ],
  providers: [
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
      deps: [AuthenticatorService]
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ]
})
export class M365AuthenticatorModule {}
