<form cohValidateOnSubmit [formGroup]="formGroup" (validatedSubmit)="onSubmit()">
  <h2 mat-dialog-title>
    {{ 'helios.accessManagement.accessScope.' + (data.mode === 'edit' ? 'editTitle' : 'addTitle') | translate }}
  </h2>

  <mat-dialog-content>
    <div class="margin-bottom">
      {{ 'helios.accessManagement.accessScope.instruction' | translate }}
    </div>

    <mat-form-field class="full-width-field">
      <mat-label>{{ 'name' | translate }}</mat-label>
      <input cohAutoFocus cogDataId="name-input" [inheritDataId]="true" matInput formControlName="name" />

      <mat-error *ngIf="formGroup.controls.name.errors?.required" cogDataId="name-field-error-message">
        {{ 'errors.required' | translate }}
      </mat-error>
      <mat-error *ngIf="formGroup.controls.name.errors?.maxlength" cogDataId="name-length-error-message">
        {{ 'errors.maxlength' | translate: { value: 64 } }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width-field">
      <mat-label>{{ 'description' | translate }}</mat-label>
      <input cohAutoFocus cogDataId="description-input" [inheritDataId]="true" matInput formControlName="description" />

      <mat-error *ngIf="formGroup.controls.description.errors?.required" cogDataId="description-field-error-message">
        {{ 'errors.required' | translate }}
      </mat-error>
      <mat-error *ngIf="formGroup.controls.description.errors?.maxlength" cogDataId="description-length-error-message">
        {{ 'errors.maxlength' | translate: { value: 256 } }}
      </mat-error>
    </mat-form-field>

    <mat-action-list>
      <mat-list-item
        (click)="onAccessibleItemsClick()"
        cogDataId="select-resources"
        [inheritDataId]="true"
        class="resource-selector">
        <cog-icon
          mat-list-icon
          shape="{{
            formGroup.controls.resources.value?.length
              ? 'helix:object-vmware-vcenter-success'
              : 'helix:object-vmware-vcenter'
          }}"></cog-icon>
        <h5 mat-line>{{ 'accessibleItems' | translate }}</h5>
        <span mat-line *ngIf="!formGroup.controls.resources.value?.length" class="selected-items-caption">{{
          'noSelection' | translate
        }}</span>
        <coh-resource-selection-details
          mat-line
          *ngIf="formGroup.controls.resources.value?.length"
          [resources]="formGroup.controls.resources.value"></coh-resource-selection-details>
        <button mat-icon-button type="button" cogDataId="select-resources-edit-button" class="editor-icon">
          <cog-icon shape="edit!outline"></cog-icon>
        </button>
      </mat-list-item>
      <mat-error *ngIf="formGroup.controls.resources.touched && formGroup.controls.resources.errors?.required">
        {{ 'errors.required' | translate }}
      </mat-error>
    </mat-action-list>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button type="button" mat-button cogCancelButton cogDataId="cancel-button" [inheritDataId]="true" mat-dialog-close>
      {{ 'cancel' | translate }}
    </button>

    <button
      type="submit"
      mat-button
      cogSubmitButton
      cogDataId="add-button"
      [inheritDataId]="true"
      [disabled]="data.readOnly || (submitting$ | async)"
      [loading]="submitting$ | async">
      <ng-container *ngIf="data.mode === 'add'">{{ 'add' | translate }}</ng-container>
      <ng-container *ngIf="data.mode === 'edit'">{{ 'save' | translate }}</ng-container>
    </button>
  </mat-dialog-actions>
</form>
