<coh-protection-item
  [formGroup]="systemKeyspacesBackupFormGroup"
  [title]="'systemKeyspacesBackup' | translate"
  [readOnly]="properties.readOnly">
  <ng-container *ngIf="!properties.readOnly">
    <mat-slide-toggle id="system-keyspace-selection"
      formControlName="systemKeyspaceSelection">
      {{'systemKeyspacesBackup' | translate}}
    </mat-slide-toggle>
    <cog-banner status="info" class="log-info margin-top-sm">
      {{'systemKeyspacesBackupInfo' | translate}}
    </cog-banner>
  </ng-container>

  <cog-settings-list *ngIf="properties.readOnly" [alwaysOpen]="true" [readOnly]="true">
    <cog-settings-list-item [label]="'systemKeyspacesBackup' | translate" class="system-keyspaces-settings-list-item">
      <cog-settings-list-item-value-display>
        {{ (formControl.value ? 'yes' : 'no') | translate }}
      </cog-settings-list-item-value-display>
    </cog-settings-list-item>
  </cog-settings-list>
</coh-protection-item>
