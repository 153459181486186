import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { targetIcon, TargetType } from '../../../models';

/**
 * @description
 *
 * Displays type of backup icon and some stats about backup within each group action section.
 */
@Component({
  selector: 'coh-group-action-target-info',
  templateUrl: './group-action-target-info.component.html',
  styleUrls: ['./group-action-target-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupActionTargetInfoComponent {
  /**
   * Group action section target type like backup, archival or replication
   */
  @Input() type: TargetType;

  /**
   * Indicates if backup target is scheduled / enabled to be submitted.
   */
  @Input() scheduled = true;

  /**
   * Group action section icon with status.
   */
  get icon(): string {
    // TODO: need vault icon with schedule icon decoration
    if (this.type === 'cloudVault') {
      return `helix:vault${this.scheduled ? '' : '-cancel'}`;
    }

    return `helix:${targetIcon[this.type]}-${this.scheduled ? 'scheduled' : 'cancel'}`;
  }
}
