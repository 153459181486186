import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Api } from '@cohesity/api/private';
import { Recovery, RecoveryServiceApi } from '@cohesity/api/v2';
import { ClearSubscriptions, Poller, PollingConfig } from '@cohesity/utils';
import dayjs from 'dayjs/esm';
import { BehaviorSubject, EMPTY, Subscription } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { RecoveryListItem, RecoveryTaskProgressInfo, RecoveryTaskStatus, RecoveryTaskStatusInfo } from '../../models';
import { PARAMETER_CODEC, convertApiStatusToSelfServiceTaskStatus } from '../../utils/utils';
import { ProgressService } from '../../services/progress.service';

/**
 * Renders the component to show metadata & errors about a single recovery task.
 *
 * @example
 * <coh-m365-recovery-item-metadata [recoveryItem]='recoveryItem'>
 * </coh-m365-recovery-item-metadata>
 *
 * @description
 * The MCM API /activity served from ControlPlane doesn't have metadata
 * details. Metadata about a recovery task composes of the below items:
 * 1. Public Status
 * 2. Progress Percentage
 * 3. Size of data restored
 * 4. Count of items restored
 *
 * 1,3 & 4 are computed through the response of the API /recoveries/:id which
 * is a call to DataPlane.
 * 2 is computed only for running tasks through the API /progressMonitors which
 * is a call to DataPlane.
 */
@Component({
  selector: 'coh-m365-recovery-item-metadata',
  templateUrl: './recovery-item-metadata.component.html',
  styleUrls: ['./recovery-item-metadata.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RecoveryItemMetadataComponent extends ClearSubscriptions implements OnInit {
  /**
   * Specifies the recovery task whose metadata is to be rendered.
   */
  @Input() recoveryItem: RecoveryListItem;

  /**
   * Specifies the Output property for sending restore task update events.
   * This property is explicitly used for updating progress percentage received
   * from the /progressMonitor API implemented within ProgressService.
   */
  @Output() progressUpdateEvent = new EventEmitter<RecoveryTaskProgressInfo>();

  /**
   * Specifies the output property for sending restore task update events.
   * This property is explicitly used for updating task status recieved from
   * the /recoveries/:id API implemented within RecoveryServiceApi.
   */
  @Output() statusUpdateEvent = new EventEmitter<RecoveryTaskStatusInfo>();

  /**
   * Specifies the total size in bytes restored as part of this task.
   */
  totalSizeBytes: number;

  /**
   * Specifies the total number of items restored as part of this task.
   */
  totalItems: number;

  /**
   * Specifies any recovery message which should be shown to the user in case
   * of error.
   */
  recoveryMessage: string;

  /**
   * Specifies whether the recovery task has any error.
   */
  hasError: boolean;

  /**
   * Specifies the expiry time for files available download.
   * This is applicable for either Mailbox PST or OneDrive Download tasks.
   */
  downloadFileExpiryTimeUsecs: number;

  /**
   * Specifies the direct link to trigger download of the files as part of this
   * recovery task. This is applicable for either Mailbox PST or OneDrive
   * Download tasks.
   */
  downloadLink: string;

  /**
   * Specifies whether the recovery task has a link for Mailbox PST file.
   */
  hasDownloadLinkForPst: boolean;

  /**
   * Specifies whether the recovery task has a link for OneDrive Zip file.
   */
  hasDownloadLinkForOneDrive: boolean;

  /**
   * Specifies whether the metadata is being currently loaded.
   */
  readonly loading$ = new BehaviorSubject<boolean>(true);

  constructor(
    private progressService: ProgressService,
    private recoveryApi: RecoveryServiceApi,
  ) {
    super();
  }

  ngOnInit(): void {
    // Step 1: Push subscription for fetching recovery details from DataPlane.
    this.subscriptions.push(
      this.fetchRecoveryDetails()
    );

    // Step 2: Fetch Progress if the task is running.
    if (this.recoveryItem.publicStatus ===  RecoveryTaskStatus.InProgress) {
      const pollingConfig: PollingConfig<any> = {
        acceptFirstIteration: false,

        // Poll every 30 secs.
        interval: 30,
        maxRetries: 1,
        iterator: () => this.progressService.getTaskProgess(this.recoveryItem?.recoveryParams?.progressTaskId,
          this.recoveryItem.regionId,
          this.recoveryItem.clusterId),
        shouldContinue: (progressInfo) => !this.progressService.isTaskComplete(progressInfo)
      };

      // Create Poller.
      const taskPoller = new Poller(pollingConfig);
      this.subscriptions.push(
        taskPoller.poller.subscribe(progressInfo => {
          const percentageCompleted = progressInfo?.resultGroupVec?.[0]?.taskVec?.[0]?.progress?.percentFinished;

          // Make an API cal to DataPlane to update recovery metadata if task
          // is complete.
          if (this.progressService.isTaskComplete(progressInfo)) {
            this.fetchRecoveryDetails();
          }
          this.progressUpdateEvent.emit({
            recoveryId: this.recoveryItem.recoveryParams?.id,
            percentageCompleted
          });
        })
      );
    }
  }

  /**
   * Makes a dataplane call to fetch the details about recovery task.
   *
   * @returns Subscription for the API GetRecoveryById(...).
   */
  fetchRecoveryDetails(): Subscription {
    return this.recoveryApi
      .GetRecoveryById({
        id: this.recoveryItem.recoveryParams?.id,
        regionId: this.recoveryItem.regionId,
        accessClusterId: this.recoveryItem.clusterId })
      .pipe(
        finalize(() => this.loading$.next(false)),

        // Ignore the error while fetching recovery details.
        catchError(_ => EMPTY)
      ).subscribe(recovery => this.setRecoveryMetadata(recovery));
  }

  /**
   * Processes the recovery task to set the various metadata fields for the
   * current recovery. This includes the following:
   * 1. Size of data restored.
   * 2. Count of items restored.
   * 3. Error message to be rendered for Successful/Unsuccesful tasks.
   * 4. Determine the download link for Mailbox PST/ OneDrive Download tasks.
   *
   * @param recovery Specifies the recovery task received from the Passthrough
   *                 call to the DataPlane.
   * @returns void
   */
  setRecoveryMetadata(recovery: Recovery) {
    if (!recovery) {
      return;
    }

    // Set the status of this recovery task.
    const recoveryTaskStatusInfo: RecoveryTaskStatusInfo = {
      recoveryId: recovery.id,
      status: convertApiStatusToSelfServiceTaskStatus(recovery.status)
    };

    // NOTE: recovery.office365Params?.objects if present, is guaranteed to be
    // of length 1 since this workflow is within Self-Serve and can only have
    // recoveries associated with 1 Magneto Object/Entity.

    // Step 1 - Set total size restored.
    if (recovery.office365Params?.objects?.[0]?.bytesRestored) {
      this.totalSizeBytes = recovery.office365Params.objects[0].bytesRestored;
    }

    // Step 2 - Set total items restored.
    this.totalItems = this.computeItemsRestored(recovery);

    // Step 3 - Set recovery message for Unsuccessful tasks.
    if (recovery.status !== 'Succeeded' && !!recovery?.messages?.[0]) {
      this.hasError = true;
      this.recoveryMessage = recovery.messages[0];
    } else {
      // Step 4 - Set recovery message for Successful tasks.
      // Check for Download Files expiration time for PST task.
      if (recovery.recoveryAction === 'ConvertToPst') {
        const pstExpirationTimeUsecs =
          Number(recovery.office365Params.downloadFileAndFolderParams?.expiryTimeUsecs);
        const timeNowUsecs = dayjs().unix() * 1000 * 1000;
        if (pstExpirationTimeUsecs < timeNowUsecs) {
          // PST has expired.
          this.hasError = true;
          this.recoveryMessage = 'm365.selfServe.pstExpired';
          recoveryTaskStatusInfo.status = RecoveryTaskStatus.PstUnavailable;
        } else {
          if (recovery.office365Params?.downloadFileAndFolderParams?.downloadFilePath) {
            // PST is valid.
            this.hasDownloadLinkForPst = true;
            this.downloadLink = this.getRecoveryDownloadUrl(recovery.id);
            this.downloadFileExpiryTimeUsecs = pstExpirationTimeUsecs;
            recoveryTaskStatusInfo.status = RecoveryTaskStatus.PstAvailable;
          }
        }
      }

      // Check for Download Files expiration time for OneDrive tasks.
      if (recovery.recoveryAction === 'DownloadFilesAndFolders') {
        // TODO(tauseef): Enhance this to support expiration time.
        if (recovery.office365Params?.downloadFileAndFolderParams?.downloadFilePath) {
          this.hasDownloadLinkForOneDrive = true;
          this.downloadLink = this.getRecoveryDownloadUrl(recovery.id);
        }
      }
    }

    // Emit task status update.
    this.statusUpdateEvent.emit(recoveryTaskStatusInfo);
  }

  /**
   * Get download File URL string for a recovery by ID.
   *
   * @param    id  Recovery ID
   * @returns  Download File URL string
   */
  getRecoveryDownloadUrl(recoveryId: string): string {
    if (!recoveryId) {
      return null;
    }

    let params: HttpParams = new HttpParams({
      encoder: PARAMETER_CODEC
    });

    if (this.recoveryItem.clusterId) {
      params = params.append('clusterId', String(this.recoveryItem.clusterId));
    } else if (this.recoveryItem.regionId) {
      params = params.append('regionId', String(this.recoveryItem.regionId));
    }

    params = params.append('includeTenants', 'true');
    return Api.publicV2(`data-protect/recoveries/${recoveryId}/downloadFiles?${params.toString()}`);
  }

  /**
   * Determines the count of restored items within this current task.
   *
   * @param recovery Specifies the recovery task received from the Passthrough
   *                 call to the DataPlane.
   * @returns Count of items restored.
   */
  computeItemsRestored(recovery: Recovery): number {
    let itemCount = 0;

    // Mailbox PST download task.
    if (recovery.office365Params?.recoverMailboxParams?.objects?.length) {
      // The length is guaranteed to be 1 since this is within Self Service.
      const containerObject =
        recovery.office365Params?.recoverMailboxParams?.objects[0];
      if (containerObject?.mailboxParams?.recoverFolders?.length) {
        containerObject.mailboxParams.recoverFolders.forEach(folder => {
          // Count the entire folder as 1 if restoreEntireFolder is true.
          // Count the items within folder if restoreEntireFolder is false.
          if (folder.recoverEntireFolder) {
            ++itemCount;
          } else if (folder.itemIds?.length) {
            itemCount = itemCount + folder.itemIds.length;
          }
        });
      }

      return itemCount;
    }

    // OneDrive Download Files task.
    if (recovery.office365Params?.downloadFileAndFolderParams?.filesAndFolders?.length) {
      // Count directly the array of items.
      itemCount =
        recovery.office365Params.downloadFileAndFolderParams.filesAndFolders.length;
      return itemCount;
    }

    // OneDrive Restore Task.
    if (recovery.office365Params?.recoverOneDriveParams?.objects?.length) {
      // The length is guaranteed to be 1 since this is within Self Service.
      const containerObject =
        recovery.office365Params?.recoverOneDriveParams?.objects[0];
      if (containerObject?.oneDriveParams?.[0]?.recoverItems?.length) {
        itemCount = containerObject.oneDriveParams[0].recoverItems.length;
        return itemCount;
      }
    }

    return 0;
  }
}
