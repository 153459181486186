<div class="dl-row">
  <dt translate="sources.mongodb.seeds"></dt>
  <dd>{{ (mongodbParams?.seeds || []).join(', ') }}</dd>
</div>
<div class="dl-row">
  <dt translate="backupType"></dt>
  <dd *ngIf="mongodbParams?.useSecondaryForBackup; else elseBlock">
    {{ 'sources.mongodb.secondary.node' | translate }} ({{ mongodbParams?.secondaryNodeTag }})
  </dd>
  <ng-template #elseBlock>
    <dd>{{ 'primaryNode' | translate }}</dd>
  </ng-template>
</div>
<div class="dl-row">
  <dt translate="sslRequirement"></dt>
  <dd>{{ mongodbParams?.requiresSsl | boolLabel }}</dd>
</div>
