import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActiveDirectoryPrincipal, ActiveDirectoryServiceApi, Group, GroupsServiceApi, UserParameters } from '@cohesity/api/v1';
import { UserParams } from '@cohesity/api/v2';
import { AutoDestroyable } from '@cohesity/utils';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, finalize, switchMap, tap } from 'rxjs/operators';
import { UserService } from 'src/app/core/services';

@Component({
  selector: 'coh-user-at-domain-picker',
  templateUrl: './user-at-domain-picker.component.html',
  styleUrls: ['./user-at-domain-picker.component.scss'],
})
export class UserAtDomainPickerComponent extends AutoDestroyable implements OnInit {

  /**
   * The unique id for this instance.
   */
  @Input() dataId = '';

  /**
   * The pre-selected sid.
   */
  @Input() sid = '';

  /**
   * The pre-defined unix user id. This is optional.
   */
  @Input()  unixUserId;

  /**
   * The list of domains to choose from.
   */
  @Input() domains: string[] = [];

  /**
   * The hash of known principals for lookup.
   */
  @Input() principalsHash = {};

  /**
   * Determines whether user domain picker is readOnly or not.
   */
  @Input() readOnly = false;

  /**
   * Whether to show the 'Unix User ID' option or not. Default to false.
   */
  @Input() allowUnixId = false;

  /**
   * Whether to exclude LOCAL user which have not been assigned a primary group or not.
   * Default to false.
   */
  @Input() constrainLocalFileAccess = false;

  /**
   * Whether to show users only or not. Default to false.
   */
  @Input() usersOnly = false;

  /**
   * Remove principal required validator.
   *
   * For ENG-315648, create share is blocked when without specifying a super user.
   */
  @Input() removeValidator = false;

  /**
   * The updated sid.
   */
  @Output() sidChange = new Subject<string>();

  /**
   * Specifies a callback to handle principal selection change.
   */
  @Output() selectPrincipal = new EventEmitter<ActiveDirectoryPrincipal | number>();

  /**
   * Local users hash.
   */
  localUsersHash: UserParams[] = [];

  /**
   * Local groups hash.
   */
  localGroupsHash: Group[] = [];

  /**
   * List of Groups to show in the autocomplete drop down list.
   */
  filteredGroups: Group[] = [];

  /**
   * List of Principals to show in the autocomplete drop down list.
   */
  filteredPrincipals: ActiveDirectoryPrincipal[] | UserParams[] | Group[] = [];

  /**
   * List of Users to show in the autocomplete drop down list.
   */
  filteredUsers: UserParams[] = [];

  /**
   * Form Group for the component.
   */
  userAtDomainForm: UntypedFormGroup;

  /**
   * Domain groups. Domains are grouped by active directory and unix user.
   */
  domainGroups: any[];

  /**
   * Indicates whether principals are being fetched.
   */
  isLoading = false;

  /**
   * Unix user key.
   */
  unixUserIdKey = 'unixUserId';

  /**
   * Whether unix user is selected as domain. Default to false.
   */
  isUnixUserIdSelected = false;

  constructor(
    private activeDirectoryServiceApi: ActiveDirectoryServiceApi,
    private ajsUserService: UserService,
    private groupsService: GroupsServiceApi,
    private fb: UntypedFormBuilder,
  ) {
    super();
  }

  /**
   * Initializes the component.
   */
  ngOnInit() {
    // Stub the principals hash if not provided.
    this.principalsHash = this.principalsHash || {};

    // Group domains.
    this.groupDomains();

    // Initialize the form controls.
    this.userAtDomainForm = this.fb.group({
      domain: this.unixUserId ? [this.unixUserIdKey] : [this.getSelectedDomain(this.sid)],
      principal: [this.getPrincipalBySid(this.sid), Validators.required],
      unixUserId: [this.unixUserId || null],
    });

    if (this.removeValidator) {
      this.userAtDomainForm.get('principal').clearValidators();
      this.userAtDomainForm.get('principal').updateValueAndValidity();
    }

    if (this.readOnly) {
      this.userAtDomainForm.get('domain').disable();
    }

    // get LOCAL user and LOCAL group hash
    this.getLocalUserAndGroup();

    // Set isUnixUserIdSelected value.
    this.setUnixUserIdSelectedValue();

    // Watch `domain` form control, then reset `unixUserId` and `principal` form control validators.
    this.userAtDomainForm
      .get('domain')
      .valueChanges
      .pipe(this.untilDestroy())
      .subscribe(selectedDomain => {
        // clear principal
        this.userAtDomainForm.get('principal').setValue('');

        // set validator.
        if (selectedDomain === 'unixUserId') {
          this.userAtDomainForm.get('unixUserId').setValidators([Validators.required]);
          this.userAtDomainForm.get('principal').setValidators([]);
        } else {
          this.userAtDomainForm.get('principal').setValidators([Validators.required]);
          this.userAtDomainForm.get('unixUserId').setValidators([]);
        }

        // update control.
        this.userAtDomainForm.get('unixUserId').updateValueAndValidity();
        this.userAtDomainForm.get('principal').updateValueAndValidity();
      });

    // Watch the `principal` form control for user input and fetch matching
    // principals for the autocomplete drop down.
    this.userAtDomainForm
      .get('principal')
      .valueChanges
      .pipe(
        this.untilDestroy(),
        debounceTime(300),
        tap(() => this.isLoading = true),
        switchMap(v => {
          const value = v?.trim();
          // when principal selection is cleared, emit it out.
          // because when principal is empty, onSelectPrincipal function is not been triggered.
          if (!value) {
            this.isLoading = false;
            return of([]);
          } else {
            return this.searchPrincipals(value, this.userAtDomainForm.controls.domain.value)
              .pipe(
                this.untilDestroy(),
                tap((principals) => {
                  // Add the returned principals to hash for quick look up.
                  principals?.forEach(principal => {
                    this.principalsHash[principal.sid] = principal;
                  });
                }), finalize(() => this.isLoading = false));
          }
        })
      )
      .subscribe(principals => {
        this.filteredPrincipals = principals;
      });

    // Watch the unixUserId form control.
    this.userAtDomainForm
      .get('unixUserId')
      .valueChanges
      .pipe(
        this.untilDestroy(),
        debounceTime(300))
      .subscribe((updatedUnixId: number) => {
        // Emit unix id
        if (updatedUnixId) {
          this.selectPrincipal.emit(updatedUnixId);
        }
      });

    // Disable user domain picker form during readOnly action.
    if (this.readOnly) {
      this.userAtDomainForm.disable();
    }
  }

  /**
   * Fetches a list of principals which partially match the user input.
   */
  searchPrincipals(search: string, domain: string): Observable<ActiveDirectoryPrincipal[]> {
    if (domain === 'LOCAL') {

      // If Local Domain is selected, filter users and groups based on search value.
      if (search && typeof search === 'string' && search.length > 0) {
        const searchValue = search.toLowerCase();

        // Filtering users list.
        this.filteredUsers = this.localUsersHash.filter(
          user => user.username.toLowerCase().indexOf(searchValue) === 0);

        // Filtering groups list.
        this.filteredGroups = this.localGroupsHash.filter(
          group => group.name.toLowerCase().indexOf(searchValue) === 0);
      } else {
        this.filteredUsers = [...this.localUsersHash];
        this.filteredGroups = [...this.localGroupsHash];
      }

      // If select LOCAL, directly assign localUsersHash and localGroupsHash to the result.
      return of([...this.localUsersHash, ...this.localGroupsHash]);
    } else {
      // If select domain, make principal API to get all available principals.
      const params = {
        search: search || undefined,
        domain: domain || undefined,
      };

      return this.activeDirectoryServiceApi.SearchActiveDirectoryPrincipals(params);
    }
  }

  /**
   * On selecting a principal from the mat-autocomplete, emit the sid and principal to external model.
   */
  onSelectPrincipal(principal: ActiveDirectoryPrincipal) {
    this.sidChange.next(principal.sid);
    this.selectPrincipal.emit(principal);
  }

  /**
   * Returns the display name of a principal. If fullName/username/name are all missing, then display sid.
   * Used by the mat-autocomplete component.
   *
   * @param   principal   selected principal
   * @returns   The displayed value.
   */
  getPrincipalName(principal: ActiveDirectoryPrincipal | UserParams | Group): string {
    return (principal as ActiveDirectoryPrincipal).fullName ||
      (principal as UserParams).username ||
      (principal as Group).name ||
      principal.sid;
  }

  /**
   * Provided an sid, returns the matching principal from hash. If no match,
   * then return a dummy object with sid as the display name.
   */
  getPrincipalBySid(sid: string): ActiveDirectoryPrincipal {
    return this.principalsHash[sid] || {
      sid: sid,
      fullName: sid,
    };
  }

  /**
   * Returns the principal's domain. If not found, then return first domain in
   * the list. If none, then return null.
   *
   * @param     sid   Selected principal sid
   * @returns   A domain or null
   */
  getSelectedDomain(sid: string): string | null {
    const selectedPrincipal = this.principalsHash[sid];
    if (!selectedPrincipal) {
      return this.domains[0] || null;
    }

    const selectedDomain = this.domains.find(domain => domain === selectedPrincipal.domain);

    return selectedDomain || this.domains[0] || null;
  }

  /**
   * Helper function to get all local users and local groups.
   */
  private getLocalUserAndGroup () {
    this.ajsUserService.getAllUsers({domain: 'LOCAL'})
      .pipe(this.untilDestroy())
      .subscribe((users: UserParams[]) => {
        users?.forEach((user: UserParameters) => {
          // Add local user
          // if assigned a primary group OR
          // `constrainLocalFileAccess` flag is false.
          if (!this.constrainLocalFileAccess || !!user.primaryGroupName) {
            this.localUsersHash.push(user);
            this.filteredUsers.push(user);
          }
        });
      });

    if (!this.usersOnly) {
      this.groupsService.GetGroups({domain: 'LOCAL'})
        .pipe(this.untilDestroy())
        .subscribe((groups: Group[]) => {
          this.localGroupsHash = [...groups];
          this.filteredGroups = [...groups];
        });
    }
  }

  /**
   * Helper function to set isUnixUserIdSelected value.
   * The value of isUnixUserIdSelected determines whether to show principle selector or unix id input.
   */
  private setUnixUserIdSelectedValue() {
    // When component init, isUnixUserIdSelected is set to be true when unixUserId provided.
    this.isUnixUserIdSelected = !!this.unixUserId;

    // then watch the domain form control and set isUnixUserIdSelected value dynamically.
    this.userAtDomainForm
      .get('domain')
      .valueChanges
      .pipe(this.untilDestroy())
      .subscribe((selectedDomain) => {
        this.isUnixUserIdSelected = selectedDomain === this.unixUserIdKey;
      });
  }

  /**
   * Helper functions to group all available domains.
   * Typically there are two groups:
   * The first group is active directory domains.
   * The second group is unix users.
   */
  private groupDomains() {
    // Default group: active directory domains.
    this.domainGroups = [
      {
        name: 'activeDirectoryDomains',
        value: this.domains,
      }
    ];

    // If allowUnixId enabled, append "Unix User ID" option to the list of domain groups.
    if (this.allowUnixId && !this.domains.includes(this.unixUserIdKey)) {
      this.domainGroups.push({
        name: 'unixUsers',
        value: [this.unixUserIdKey],
      });
    }
  }
}
