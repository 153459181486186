// Component: Browse Apps

import { isClusterScope } from '@cohesity/iris-core';

;(function(angular, undefined) {
  'use strict';

  angular.module('C.appsManagement')
    .controller('BrowseAppsCtrl', BrowseAppsCtrlFn)
    .component('browseApps', {
      templateUrl: 'app/apps-management/app-store/store/store.html',
      controller: 'BrowseAppsCtrl'
    });

  function BrowseAppsCtrlFn(_, AppStoreService, $state, evalAJAX, $window, NgIrisContextService, APPS_CONST) {
    let $ctrl = this;

    _.assign($ctrl, {
      // data
      appList: [],
      categories: [{id: 'allCategories', name: 'allCategories'}],
      filterByCategory: null,
      isLoading: false,
      originalAppsList: [],

      // Methods
      getAppActionText: getAppActionText,
      launchInstallAppModal: launchInstallAppModal,
      onSearch: onSearch,
      onSelectCategory: onSelectCategory,

      // Component life-cycle methods
      $onInit: $onInit,
    });

    /**
     * Initializes the controller
     *
     * @method   $onInit
     */
    function $onInit() {
      if (isClusterScope(NgIrisContextService.irisContext)) {
        // auto navigate to "My Apps tab if in cluster scope"
        $state.go('apps-management.apps');
        return;
      }
      _fetchAppRelatedDetails();
    }

    /**
     * Fetches appstore base url along with list of all apps and categories
     *
     * @method   _fetchAppRelatedDetails
     */
    function _fetchAppRelatedDetails() {
      $ctrl.isLoading = true;
      AppStoreService.getApps({list: 'all'}).then(function getApp(response) {
        $ctrl.appList = response.appList;
        $ctrl.originalAppsList = _.cloneDeep($ctrl.appList);
        $ctrl.categories = $ctrl.categories.concat(response._categories);
      }, evalAJAX.errorMessage
      ).finally(function finallyAfterResponse() {
        $ctrl.isLoading = false;
      });
    }

    /**
     * Fetches the list of all apps based on selected category
     *
     * @method   onSelectCategory
     */
    function onSelectCategory() {
      if ($ctrl.filterByCategory.id !== 'allCategories') {
        $ctrl.isLoading = true;
        var param = {category: $ctrl.filterByCategory.id};
        AppStoreService.getApps(param)
        .then(function success(response) {
          $ctrl.appList = response.appList;
        })
        .finally(function fetchAppsDone() {
          $ctrl.isLoading = false;
        });
      } else {
        $ctrl.appList = _.cloneDeep($ctrl.originalAppsList);
      }
    }

    /**
     * Merge app state with search results from app list
     *
     * @method   transformSearchResults
     */
    // TODO: Update this once API is available via backend to fetch appState for
    //       list of apps
    function transformSearchResults() {
      if ($ctrl.appList && $ctrl.appList.length > 0) {
        $ctrl.appList.map(function mapSearchResults(value, index) {
          $ctrl.originalAppsList.map(function fetchAppState(appObj) {
            if (appObj.appId === value.appId) {
              $ctrl.appList[index]['appState'] = appObj.appState;
            }
          });
        });
      }
    }

    /**
     * Fetches the list of all apps based on search text
     *
     * @method   onSearch
     */
    function onSearch() {
      if ($ctrl.searchText) {
        $ctrl.isLoading = true;
        var searchParam = {searchText: $ctrl.searchText};
        AppStoreService.getApps(searchParam)
        .then(function success(response) {
          $ctrl.appList = response.appList;
          transformSearchResults();
        })
        .finally(function fetchAppsDone() {
          $ctrl.isLoading = false;
        });
      } else {
        $ctrl.filterByCategory = $ctrl.categories[0];
        $ctrl.appList = _.cloneDeep($ctrl.originalAppsList);
      }
    }

    /**
     * launches app specific user agreement modal to purchase app
     *
     * @method   launchInstallAppModal
     * @param   {object} app   app specific information
     */
    function launchInstallAppModal(app) {
      if(APPS_CONST.secCenterIntegratedAppNames.includes(app.appName)) {
        $window.open(`/datahawk/security/integration/${app.appId}/details/overview`, '_blank').focus();
      } else {
        _.get(app, 'applicationDetails.appType') === 'integration' ?
          $window.open(app.applicationDetails.appUrl, '_blank').focus() :
          AppStoreService.launchInstallAppModal(app);
      }
    }

    /**
     * get app button text
     *
     * @method   getAppActionText
     * @param   {object} app   app specific information
     * @return   {string}       returns 'integration' or appstate
     *                            for container apps
     */
    function getAppActionText(app) {
      return AppStoreService.getAppActionText(app);
    }

  }

})(angular);
