/**
 * Alerts Severity
 */
export const alertsSeverityList = [
  'kCritical',
  'kWarning',
  'kInfo',
];

/**
 * Alerts Date Range
 */
export const alertsDateRangeList = [
  'last24Hours',
  'last7Days',
  'last13Weeks',
  'custom',
];

/**
 * Alerts Severity (used in ajs app only)
 */
export enum AlertsSeverity {
  kCritical = 'Critical',
  kWarning = 'Warning',
  kInfo = 'Info',
}

/**
 * Alerts Status (used in ajs app only)
 */
export enum AlertsStatus {
  kOpen = 'Open',
  kSuppressed = 'Suppressed',
  kResolved = 'Resolved',
  kNote = 'Note',
}

/**
 * Alerts Status
 */
export const alertsStatusList = [
  'kOpen',
  'kSuppressed',
  'kResolved',
  'kNote',
];

/**
 * Alerts Type Bucket
 */
export const alertsTypeBucketList = [
  'kSoftware',
  'kHardware',
  'kService',
  'kOther',
];

/**
 * Alerts Icon Mapping.
 */
export enum AlertsIconMap {
  kCritical = 'error',
  kWarning = 'warning',
  kInfo = 'info',
}

/**
 * Alert legends mapping(critical, warning, info).
 */
export enum AlertLegendsMap {
  kCritical = 'critical',
  kWarning = 'warn',
  kInfo = 'info',
}
