import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { IconModule } from '../icon/icon.module';
import { DarkModeToggleComponent } from './dark-mode-toggle.component';

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    DarkModeToggleComponent
  ],
  exports: [DarkModeToggleComponent]
})
export class DarkModeToggleModule { }
