/* eslint-disable no-useless-escape */
// Unicode regex patterns.
// ES6 supports the powerful unicode regex (\p{L} for word characters) but
// you have to enumerate ranges in ES5.
// All Unicode Word characters \p{L} is roughly the following:
export const allUnicodeWordCharacters = ['\u00AA-\uFFDC'];

export const REGEX_FORMATS = {
  IPv4: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,

  // Cidr notation for IPv4 e.g. 198.51.100.1/24
  CIDRIPv4: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/(3[0-2]|[12]?[0-9])$/,

  // Cidr notation for IPv4 restricted to 16 bits
  CIDRIPv416Bits: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/16$/,

  // Cidr notation for IPv4 restricted to 16 bits
  CIDRIPv4Upto22Bits: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/([1-9]|1[0-9]|2[0-2]){1}$/,

  // Cidr notation for IPv6 e.g. 2400:0:0:1000::/64
  CIDRIPv6: /^s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:)))(%.+)?s*(\/([0-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8]))?$/,

  ipOrUrl: /^((?:http|ftp)s?:\/\/)(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}\.?|[A-Z0-9-]{2,}\.?)|localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::\d+)?(?:\/?|[\/?]\S+)$/i,

  // Allows IPv4 and IPv6 ip addresses
  IPv4AndIPv6: /((^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/,

  // Allow only Ipv6 address.
  IPv6: /((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?$/,

  // Allows only a fully qualified domain name (fqdn).
  fqdnRegExp: /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/,

  // Allows basic ascii alphanumeric characters plus period, colon, and slash.
  // This is a loose validation for ipv4/ipv6 in slash notation.
  ipSlashNotation: /^[a-zA-Z0-9.:]+\/[a-zA-Z0-9.]+$/,

  // email regex
  email: /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/,

  // Allows basic ascii alphanumeric.
  // Presently (06/21/2017) used only for Partition name.
  alphanumeric: /^[a-zA-Z0-9]*$/,

  // Allows basic ascii alphanumeric, lowercase only.
  // Presently (06/21/2017) this pattern is only used for recovery task
  // prefix/suffix for cloud only (Non-cloud uses the `names` pattern).
  // Technically FE validation is not required because the backend does not
  // throw an error with other characters. It converts upper to lower and
  // strips non-ascii characters. However, we want to prevent a situation
  // whereby the user enters values in Chinese and they are completely
  // stripped, thus eliminating the distinctive naming.
  lowerAlphanumeric: /^[a-z0-9]*$/,

  // Allows ascii word characters and _ - .
  alphanumericPlus: /^[\w.-]*$/,

  // Allows at most two decimal.
  atMostTwoDecimal: /^\d+(\.\d{1,2})?$/,

  // Azure VM Names can start with alphabet or number followed by aplha,
  // number, underscore, hypen or period and must end with alphabet, number or
  // underscore
  azureVMName: /^[a-zA-Z0-9]+[a-zA-Z0-9_.-]*[_a-zA-Z0-9]+$/,

  // Azure prefix must begin with alphabet or number and can have aplha,
  // number, underscore, hypen or period in it
  azurePrefix: /^[a-zA-Z0-9]+[a-zA-Z0-9_.-]*$/,

  // Azure suffix must end with alphabet, number or underscore and can have
  // aplha, number, underscore, hypen or period in it
  azureSuffix: /^[_.-a-zA-Z0-9]*[_a-zA-Z0-9]+$/,

  // Azure tag names must not contain these characters <, >, %, &, \, ?, /
  azureTagName: /^[^<>%&\\?/]+$/,

  // AWS VM Names' first letter must be lower alphabet, followed by lower
  // alphanumerics or hyphens and must end with lower alphanumeric.
  awsVMName: /^[a-zA-Z][-a-zA-Z0-9]*/,

  // GCP VM Names' first letter must be lower alphabet, followed by lower
  // alphanumerics or hyphens and must end with lower alphanumeric.
  gcpVMName: /^[a-z]+[-a-z0-9]*[a-z0-9]+$/,

  // First letter must be lower alphabet, followed by lower alphanumerics or
  // hyphens.
  gcpPrefix: /^[a-z]+[-a-z0-9]*$/,

  // Lower alphanumerics or hyphens ending with lower alphanumeric
  gcpSuffix: /^[-a-z0-9]*[a-z0-9]+$/,

  // Allows basic ascii alphanumeric and underscores(_).
  oracleDatabaseName: /^[a-zA-Z0-9_]*$/,

  // If start with + (ASM destination), no trailing slash or whitespace.
  // Otherwise, no whitespace.
  oracleDataFileDestination: /^(((\+)[^\/\s]*)|(^[^\\\s+]\S*))$/,

  // Allows positive decimal numbers.
  positiveNumbers: /^\d*[.]?\d*?$/,

  // Allows positive Arabic numerals only.
  positiveIntegers: /^\d+$/,

  // Allows Arabic numerals, both positive and negative.
  wholeNumbers: /^(0|-?[1-9][0-9]*)$/,

  // Allows Arabic numerals plus the period.
  subnetMask: /^[0-9.]+$/,

  // IP/Hostname validation
  // TODO: Enhance this pattern to validate international hostnames?
  validationIpLoose: /^[a-zA-Z0-9.:_\-]+$/,

  // Includes all word characters, spaces, dash and period.
  // Standard character set for most names in our app.
  names: new RegExp('^[' + allUnicodeWordCharacters + '\\w-.\\s]*$'),

  // Viewbox name validation is more restrictive than most other
  // names: Only word characters and the dash. No spaces and no period
  // allowed.
  fileSystemName: new RegExp('^[' + allUnicodeWordCharacters + '\\w-]*$'),

  // We do not allow special URL characters: / ? #
  //
  // Also, AngularJS unencodes the semicolon (';') before sending API request
  // which is parsed as a terminating query string character in golang code.
  // So we disallow this as well.
  viewName: /^[^/?#;]*$/,

  // We do not allow special URL characters: / ? # ;
  ruleName: /^[^/?#;]*$/,

  // Share Names follow the same rules as View names, but have an additional
  // constraint in that they may not have a name comprised solely of one or
  // two periods.
  //
  // TODO (David): If we ever remove View name character restrictions, switch
  // back to the commented regex which only limits periods.
  //
  // shareName: /^((?!^\.{1,2}$).)*$/,
  shareName: /^((?!^\.{1,2}$)(?!\/)(?!\?)(?!\#)(?!\;).)*$/,

  // Tenant Name Validations.
  tenantName: /^([a-zA-Z0-9][a-zA-Z0-9\\-]*[a-zA-Z0-9])$/,

  // File Extension Validation
  extension: /^[a-zA-Z0-9.,\s]*$/,

  // NetBIOS computer name convention https://support.microsoft.com/en-us/kb/188997#mt1
  // plus additional disallowed chars (https://cohesity.atlassian.net/browse/ENG-8094?focusedCommentId=47098&page=com.atlassian.jira.plugin.system.issuetabpanels:comment-tabpanel#comment-47098)
  // Because jshint throws on the escaped \< char which actually is valid
  // jshint -W049
  netBIOScomputerName: /^(?=[^\.])[^\!\@\(\)\*\+\=\|\\\:\;\"\?\<\>\,\s]+$/,

  // Less than 32 characters and can include only letters, numbers, and the
  // following characters: '- . # @ + : _' (including spaces)
  SNMP: /^[a-zA-Z0-9.#@=_ +:-]{0,32}$/,

  // NAS file Inclusions/Exclusions must have either a /prefix or *postfix.
  nasInclusionFilter: /^[\/\*]+.*$/,

  // delimiters for multiple selections in ui select
  uiSelectDelimiters: 'SPACE|,|/',

  // WindowsFilePath
  windowsFilePathRegex: /^[a-z, A-Z]:\\(?:[^\\/:*?"<>|\r\n]+\\)*[^\\/:*?"<>|\r\n]*$/,

  // WindowsExcludeFilePath - does not need to be a valid windows path, can
  // be a regex like temp/*, */temp/* etc. only :, \, ., *, ?, -, ' ' special
  // chars are allowed
  windowsExcludeFilePathRegex: /^((?![<>|"]).)*$/,

  // Regex for the types of clusters.
  // Virtual Edition Cluster can be of the type 'VirtualEdition8' and
  // 'VirtualEdition16'. So it will always have the start with
  // 'VirtualEdition'.
  // Update: there is a space between virtual edition in hardwareModel field.
  // Regex is updated to match with zero or more spaces.
  virtualEditionCluster: /^virtual\s*edition/i,

  // Value of hardwareInfo.productModel would always start with
  // 'Virtual ROBO'
  virtualRobo: /^Virtual ROBO/i,

  // Value of hardwareInfo.chassisType would start with
  // 'Virtual' for CE Clusters
  virtual: /^Virtual/i,

  // Regex for yes.
  yes: /^([yY][eE][sS])$/i,

  // Value of hardwareInfo.productModelType would begin RoboNode.
  physicalRobo: /^RoboNode/i,

  // Match any non-whitespace characters
  noWhiteSpace: /^\S*$/,

  // Determine whether the hardware model belongs to C6k platform.
  c6kPlatform: /^C6\d{3}$/i,

  // Match the type for host alias/machine accounts. Max 15 characters and
  // alphanumeric including '-'.
  hostAlias: /^([a-zA-Z0-9][a-zA-Z0-9\-]{0,13}[a-zA-Z0-9])$/i,

  // Role names may include all ascii word characters and _ - . and space
  roleName: /^[\w-. ]*$/,

  // NFS directory names may not include a forward slash.
  nfsDirName: /^[^/]*$/,

  // SMB directcory names may not include < > : " / \ | ? *
  // Ref: https://docs.microsoft.com/en-us/windows/win32/fileio/naming-a-file
  smbDirName: /^[^<>:"/\|?*]*$/,

  // Regex for valid microsoft smtp nicknames.
  // Ref: https://bit.ly/3nLUnEX
  smtpNicknameRegExp: /^[^@\s]+$/,

  // Regex for valid SMB username which may not include @
  smbUsername: /^[^@]*$/,

  // Regex for domain name (IP or FQDN)
  domainName: /^([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9_\-]{0,61}[a-zA-Z0-9])(\.([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9_\-]{0,61}[a-zA-Z0-9]))*$/,

  // Basic validation for certificate
  certificate: /^-----BEGIN CERTIFICATE-----(((?!-----BEGIN CERTIFICATE-----)[\s\S])*)-----END CERTIFICATE-----\s{0,2}$/,

  // Regex for valid icap server
  icapUrl: '^((icap):\\/\\/)?(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\\.)+(?:[A-Z]{2,6}\\.?|[A-Z0-9-]{2,}\\.?)|\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3})(?::\\d+)?(?:\\/?|[\\/?]\\S+)$',

  // Regex for valid custome kms key format.
  customKmsKey: '^arn:aws:kms:.+:[0-9]{12}:key/(([a-zA-Z0-9])+[-]?)+$',

  // Pattern for AWS RDS Database identifier.
  // Ref: https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/CHAP_Limits.html#RDS_Limits.Constraints
  rdsDbInstanceIdentifier: /^[a-z]$|^[a-z]((?!\-{2})[a-z0-9\-])*[a-z0-9]$/i,

  /**
   * Prefix for Postgres Dbs. Should use only alphabets, digits and underscores
   */
  postgresPrefix: /^[a-z_][a-z0-9_]*$/,

  /**
   * Suffix for Postgres Dbs. Should use only alphabets, digits and underscores
   */
  postgresSuffix: /^[a-z0-9_]*$/,

  /*
   * Validates a hostname according to common hostname rules.
   *
   * A valid hostname label:
   * - Must be between 1 and 63 characters long.
   * - Must start and end with an alphanumeric character.
   * - Can contain alphanumeric characters and hyphens.
   * - Must not start or end with a hyphen.
   *
   * * Examples of valid hostname labels:
   * - example
   * - my-hostname
   * - subdomain123
   */
  hostName: /^(?!-)[A-Za-z0-9-]{1,63}(?<!-)$/,

  /**
   * Path prefix. Should not start and end with '/' and should not have consecutive '//'
   */
  pathPrefix: /^(?!\/)[^/]+(\/[^/]+)*$/,

  /**
   * S3 Bucket ARN.
   * Should start with arn:aws:s3:::
   * Should consist only of lowercase letters, numbers, dots (.), and hyphens (-) after arn:aws:s3:::
   * Should contain minimum 3 and maximum 63 characters after arn:aws:s3:::
   */
  s3BucketArn: /^arn:aws:s3:::[a-z0-9\-.]{3,63}$/
};
