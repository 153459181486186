import { ReportColumnConfigs } from '../common-report-items/custom-reports.service';
import { ReportSection } from '../iris-reporting.model';

/**
 * This is the layout config for the system connections report.
 */
export const systemConnectionsReport: ReportSection[] = [
  {
    title: 'reporting.summary',
    items: [
      {
        id: 'statlistItem',
        componentIds: ['1101'],
        componentNames: ['ClusterConnectionsSummary'],
        params: {
          items: [
            {
              title: 'reporting.connectivityRate',
              translate: true,
              showChart: true,
              measurement: {
                valueKey: 'countSystemId',
                dataType: 'percent',
                filter: {
                  filterKey: 'connectionStatus',
                  value: 'Connected',
                },
                aggregation: 'percent',
              },
            },
            {
              title: 'reporting.systems',
              translate: true,
              measurement: {
                valueKey: 'countSystemId',
                dataType: 'number',
              },
            },
            {
              title: 'reporting.connected',
              translate: true,
              measurement: {
                valueKey: 'countSystemId',
                dataType: 'number',
                filter: {
                  filterKey: 'connectionStatus',
                  value: 'Connected',
                },
              },
            },
            {
              title: 'reporting.disconnected',
              translate: true,
              measurement: {
                valueKey: 'countSystemId',
                dataType: 'number',
                filter: {
                  filterKey: 'connectionStatus',
                  value: 'Disconnected',
                },
              },
            },
          ],
        },
      },
    ],
  },
  // {
  //   items: [
  //     {
  //       id: 'lineChartItem',
  //       componentNames: ['ClusterConnectionsTrend'],
  //       title: 'reporting.systemConnectionStatusTrend',
  //       params: {
  //         measurements: [
  //           {
  //             valueKey: 'countSystemId',
  //             dataType: 'number'
  //           },
  //         ],
  //         dimensions: [
  //           {
  //             dataType: 'date',
  //             dimensionKey: 'timestampUsecs',
  //           },
  //           {
  //             dimensionKey: 'connectionStatus',
  //           },
  //         ],
  //       },
  //     },
  //   ],
  // },
  {
    title: 'reporting.data',
    isFullData: true,
    items: [
      {
        id: 'tableItem',
        componentIds: ['1100'],
        componentNames: ['AllClusterConnections'],
        params: {
          columns: [
            {
              title: 'systemName',
              enabled: true,
              translate: true,
              key: 'systemName',
              icon: {
                dataType: 'clusterIcon',
              },
              primary: {
                key: 'systemName',
                dataType: 'text',
              },
              // subtitle: {
              //   key: 'systemType',
              //   dataType: 'text',
              // },
            },
            {
              title: 'connectionStatus',
              enabled: true,
              translate: 'true',
              key: 'connectionStatus',
              primary: {
                key: 'connectionStatus',
                dataType: 'enumType',
              },
              icon: {
                key: 'connectionStatus',
                dataType: 'connectionStatusIcon',
              },
            },
            {
              title: 'reporting.totalDisconnectedTime',
              enabled: true,
              translate: 'true',
              key: 'totalDisconnectedTimeUsecs',
              sortStart: 'desc',
              primary: {
                key: 'totalDisconnectedTimeUsecs',
                dataType: 'duration',
                renderParams: {
                  usecs: true,
                },
              },
            },
            {
              title: 'reporting.disconnectionTimePercentage',
              enabled: true,
              translate: 'true',
              key: 'disconnectionTimePercent',
              primary: {
                key: 'disconnectionTimePercent',
                dataType: 'number',
                renderParams: {
                  suffix: '%',
                  format: '1.0-2',
                  limit: 0.01,
                },
              },
            },
            {
              title: 'reporting.disconnections',
              translate: 'true',
              key: 'totalDisconnectionEventsCount',
              primary: {
                key: 'totalDisconnectionEventsCount',
                dataType: 'number',
              },
            },
            {
              title: 'reporting.mostRecentDisconnection',
              translate: true,
              key: 'lastDisconnectionTimestampUsecs',
              primary: {
                key: 'lastDisconnectionTimestampUsecs',
                dataType: 'date',
                renderParams: {
                  usecs: true,
                },
              },
            },
          ],
        },
      },
    ],
  },
];

/**
 * This is metadata needed to determine available chart customizations.
 */
export const systemConnectionsColumns: ReportColumnConfigs = {
  systemName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  systemId: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  // systemType: {
  //   aggs: ['count'],
  //   canGroup: true,
  //   maxGroupingValues: 3,
  //   dataType: 'text',
  // },
  connectionStatus: {
    aggs: [],
    canGroup: true,
    dataType: 'text',
  },
  totalDisconnectedTimeUsecs: {
    aggs: ['sum'],
    dataType: 'number'
  },
  disconnectionTimePercent: {
    aggs: ['avg'],
    dataType: 'number'
  },
  totalDisconnectionEventsCount: {
    aggs: ['sum'],
    dataType: 'number',
  },
  lastDisconnectionTimestampUsecs: {
    aggs: [],
    dataType: 'date'
  },
};
