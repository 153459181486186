import { Injectable } from '@angular/core';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { DataTreeSelection } from '@cohesity/helix';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { SourceSelection, TagCategory } from '@cohesity/iris-source-tree';
import { TranslateService } from '@ngx-translate/core';

import { Environment } from '@cohesity/iris-shared-constants';
import { BaseProtectionSourceService } from '../shared/base-protection-source.service';
import { GCPDiskExclusionOptionsComponent } from './disk-exclusion-options/gcp-disk-exclusion-options.component';
import { GcpSourceDataNode } from './gcp-source-data-node';
import { GcpViewFilters } from './gcp-view-filters';

/**
 * Tree service for gcp
 */
@Injectable({
  providedIn: 'root',
})
export class GcpSourceTreeService extends BaseProtectionSourceService<GcpSourceDataNode> {
  /**
   * A reference to gcp view filters, to show a physical, flat list view.
   */
  gcpViewFilters: GcpViewFilters<GcpSourceDataNode>;

  constructor(
    private translate: TranslateService,
    public irisCtx: IrisContextService,
  ) {
    super();
    this.gcpViewFilters = new GcpViewFilters(
      this.filters,
      this.treeTransformer,
      this.treeControl,
      this.irisCtx,
    );
  }

  /**
   * Returns whether a node is a leaf or not. This can be used to calculate selection totals.
   */
  isLeaf(treeNode: GcpSourceDataNode): boolean {
    return treeNode.isLeaf;
  }

  /**
   * Override the unwrap root contains to remove unnecessary nodes for source tree.
   *
   * @param  tree   The list of nodes from the raw api response.
   * @returns   The unwrapped root container, with system databases grouped together.
   *
   */
  unwrapRootContainers(tree: ProtectionSourceNode[]): ProtectionSourceNode[] {
    const filteredTree = [];
    const nodesAllowedWithNoChildren = 'kProject';

    tree.forEach(node => {
      const nodeType = node.protectionSource.gcpProtectionSource.type;
      const isLeaf = ['kVirtualMachine', 'kTag'].includes(nodeType);

      if (!['kIAMUser', 'kProject', 'kRegion', 'kAvailabilityZone',
        'kVirtualMachine', 'kTag', 'kLabel', 'kMetadata']
          .includes(node.protectionSource.gcpProtectionSource.type)) {
        return;
      }

      if (node.nodes && node.nodes.length) {
        node.nodes = this.unwrapRootContainers(node.nodes);
      }

      // accept a node if it is a valid leaf, or has valid children
      if (isLeaf || (node.nodes && node.nodes.length) ||
        nodesAllowedWithNoChildren.includes(node.protectionSource.gcpProtectionSource.type)) {

        filteredTree.push(node);
      }
    });

    return super.unwrapRootContainers(filteredTree);
  }

  /**
   * Only expand certain node types by default.
   *
   * @param   treeNode   The treenode to check.
   * @return  True if the node should be expanded by default.
   */
  shouldExpandNodeOnLoad(treeNode: GcpSourceDataNode): boolean {
    return ['kIAMUser', 'kAvailabilityZone', 'kRegion'].includes(treeNode.type);
  }

  /**
   * Transforms the node object from the api into a Protection Source Tree node to pass to the tree.
   *
   * @param   node   The original node.
   * @param   level  The level in the tree.
   * @return  An GcpSourceDataNode that can be displayed in the tree.
   */
  transformData(node: ProtectionSourceNode, level: number): GcpSourceDataNode {
    return new GcpSourceDataNode(node, level, this.irisCtx);
  }

  /**
   * Parse a list of tree nodes to determine available tags that can be filtered by.
   *
   * @param   allNodes   The complete list of nodes.
   * @param   selection  The current selection.
   * @returns A list of tag categories that can be filtered by.
   */
  getTagCategories(
    allNodes: GcpSourceDataNode[],
    selection: DataTreeSelection<GcpSourceDataNode>
  ): TagCategory[] {
    const tagNodes = allNodes.filter(node => node.isTag);
    const tagMap: any = {};
    const tagTypeMap: any = {};
    const networkTagGroups: any[] = [];
    const labelTagGroups: any[] = [];
    const customMetadataTagGroups: any[] = [];
    const tags: TagCategory[] = [];

    // Add gcp tag type to map for all the tags associated with vms.
    allNodes.filter(node => node.envSource.type === 'kVirtualMachine').forEach(vmNode => {
      vmNode.protectionSource.gcpProtectionSource.tagAttributes?.forEach(vmTag => {
        tagTypeMap[vmTag.id] = vmTag.gcpTagType;
      });
    });

    // All available tags, grouped by category
    tagNodes.forEach(tagNode => {
      tagMap[tagNode.protectionSource.id] = tagNode.protectionSource.name;

      switch (tagTypeMap[tagNode.protectionSource.id]) {
        case 'kNetworkTag':
          networkTagGroups.push([{
            id: tagNode.protectionSource.id,
            name: tagNode.protectionSource.name,
          }]);
          break;
        case 'kLabel':
          labelTagGroups.push([{
            id: tagNode.protectionSource.id,
            name: tagNode.protectionSource.name,
          }]);
          break;
        case 'kCustomMetadata':
          customMetadataTagGroups.push([{
            id: tagNode.protectionSource.id,
            name: tagNode.protectionSource.name,
          }]);
          break;
      }
    });

    tags.push(...[{
      name: this.translate.instant('labels'),
      tagGroups: labelTagGroups,
    }, {
      name: this.translate.instant('networkTags'),
      tagGroups: networkTagGroups,
    }, {
      name: this.translate.instant('customMetadata'),
      tagGroups: customMetadataTagGroups,
    }]);

    // Add a category for currently excluded tags
    const excludedTags = (selection.excluded || []).filter(node => node.type === 'kTag');
    if (excludedTags.length) {
      tags.unshift({
        name: this.translate.instant('excluded'),
        tagGroups: excludedTags.map(tagNode =>
          tagNode.ids.map(tagId => ({
            id: Number(tagId),
            name: tagMap[tagId],
          }))
        ),
      });
    }

    // Add a category for currently auto protected tags
    const autoProtectedTags = (selection.autoSelected || []).filter(node =>  node.type === 'kTag');
    if (autoProtectedTags.length) {
      tags.unshift({
        name: this.translate.instant('autoProtected'),
        tagGroups: autoProtectedTags.map(tagNode =>
          tagNode.ids.reduce((tagsUnderCategory, tagId) => {
            if (tagMap[tagId]) {
              tagsUnderCategory.push({
                id: Number(tagId),
                name: tagMap[tagId],
              });
            }
            return tagsUnderCategory;
          }, [])
        ),
      });
    }

    return tags;
  }

  /**
   * Convert the data tree selection model to the job selection model.
   *
   * @param   selection   The selection from the tree.
   * @return  The job selection info.
   */
  transformFromDataTreeSelection(selection: DataTreeSelection<GcpSourceDataNode>): SourceSelection {
    // Sources include explicitly selected leaf node, and auto selected items
    const autoSelected = selection.autoSelected.filter(item => !item.isTag);
    const sources = selection.selected.filter(item => item.isLeaf).concat(autoSelected);

    return {
      // non-tag excluded items
      excludeSourceIds: selection.excluded.filter(item => !item.isTag).map(item => Number(item.id)),

      // excluded tags. The ids for these need to be converted to number since the ids property splits
      // a string and leaves them as strings. This comes out to an array of arrays.
      excludeVmTagIds: selection.excluded
        .filter(item => item.isTag)
        .map(item => item.ids.map(id => Number(id))),

      // non-tag source ids
      sourceIds: sources.map(item => Number(item.id)),

      // Special source params
      sourceSpecialParameters: Object.values(selection.options || {}).filter(Boolean),

      // tag source ids, an array of arrays.
      vmTagIds: selection.autoSelected.filter(item => item.isTag).map(item => item.ids.map(id => Number(id))),
    };
  }

  /**
   * Convert source selection to the data tree selection model.
   * source ids can be either selected items or auto selected items, nodes with children are
   * assumed to be auto selected. Nodes can be in the tree multiple times, and should not be
   * duplicated in the selection.
   *
   * @param   allNodes         The unfiltered list of tree nodes.
   * @param   sourceSelection  The job selection.
   * @return  A data tree selection model.
   */
  transformToDataTreeSelection(
    allNodes: GcpSourceDataNode[],
    sourceSelection: SourceSelection
  ): DataTreeSelection<GcpSourceDataNode> {
    const treeSelection = super.transformToDataTreeSelection(allNodes, sourceSelection);

    if (sourceSelection.vmTagIds) {
      sourceSelection.vmTagIds.forEach(tagIds =>
        treeSelection.autoSelected.push(this.gcpViewFilters.createTagNode(tagIds, allNodes))
      );
    }

    if (sourceSelection.excludeVmTagIds) {
      sourceSelection.excludeVmTagIds.forEach(tagIds =>
        treeSelection.excluded.push(this.gcpViewFilters.createTagNode(tagIds, allNodes))
      );
    }
    return treeSelection;
  }

  /**
   * Gets a component to render for a source's special parameters.
   */
   getSpecialParametersComponent(node: GcpSourceDataNode) {
    if (flagEnabled(this.irisCtx.irisContext, 'gcpDiskExclusions') &&
      node.environment === Environment.kGCP &&
      node.type === 'kVirtualMachine') {
      return GCPDiskExclusionOptionsComponent;
    }

    return null;
  }
}
