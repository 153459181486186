// Component: Content Editable directive

;(function(angular, undefined) {
  'use strict';

  angular.module('C.contentEditable', ['ngSanitize'])
    .directive('cContentEditable', cContentEditableDirectiveFn);

  /**
   * @ngdoc directive
   * @name C.directive:contentEditable
   *
   * @description
   *   use this directive to make editable element.
   *   use ng-model to enable 2 way binding and add appropriate name attribute
   *   so that this field can found under formModel.
   *
   * @restrict 'A'
   * @requires ngModel
   *
   * @example
     <fieldset>
      <label for="editableDiv">
        Editable Division Element
      </label>
      <div c-content-editable
        name="editableDiv"
        ng-model="editableDiv"
        placeholder="Editable Division Element Placeholder."></div>
      </fieldset>
   */
  function cContentEditableDirectiveFn($sce) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function cContentEditableLinkFn(scope, element, attrs, ngModel) {

        /**
         * when to update the model
         *
         * @type   {string}    space separated event name's.
         */
        var domChangeEvent = 'blur keyup change';

        /**
         * when to reset the scroll position
         *
         * @type   {string}    space separated event name's.
         */
        var restoreScrollEvent = 'blur';

        // enable editing
        element.attr('contenteditable', 'true');

        /**
         * reflect dom changes to model.
         *
         * @method   read
         */
        function read() {
          var value = element.text().trim();

          // When we clear the content editable the browser leaves a <br> behind
          // so don't set value to model
          if (value === '<br>' || value === '') {
            value = undefined;
          }

          ngModel.$setViewValue(value);
        }

        /**
         * reflect model changes to dom.
         *
         * @method   $render
         */
        ngModel.$render = function cContentEditableRenderFn() {
          element.html($sce.getTrustedHtml(ngModel.$viewValue || ''));
        };

        /**
         * DOM change event handler
         *
         * @method   domChangeHandler
         */
        function domChangeHandler() {
          scope.$evalAsync(read);
        }

        /**
         * reset the scroll position
         *
         * @method   resetScrollHandler
         */
        function resetScrollHandler() {
          element[0].scrollTop = 0;
        }

        // setup element binding
        element.on(domChangeEvent, domChangeHandler);
        element.on(restoreScrollEvent, resetScrollHandler);

        // de-register binding on scope destroy.
        scope.$on('$destroy', function cContentEditableDestroyFn() {
          element.off(domChangeEvent, domChangeHandler);
          element.off(restoreScrollEvent, resetScrollHandler);
        });
      }
    };
  }
})(angular);
