import { Injectable } from '@angular/core';
import { AccessScope, AccessScopesApiService, Resource, ResourcesApiService } from '@cohesity/api/guardian';
import { SnackBarService } from '@cohesity/helix';
import { getMcmAccountId, getUserTenantId, hasPrivilege, IrisContextService } from '@cohesity/iris-core';
import { DeleteConfirmationDialogComponent } from '@cohesity/shared-dialogs';
import { AjaxHandlerService, DialogService } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { filter, finalize, switchMap } from 'rxjs/operators';

import {
  AccessScopeDialogData,
  AccessScopeDialogResponse,
  AddUpdateAccessScopeDialogComponent,
} from '../components/add-update-access-scope-dialog/add-update-access-scope-dialog.component';
import { AccessTableRow, AddAccessDialogInput, GetAccessScopeParams } from '../models';
import { transformApiResponseToTableData } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class AccessScopeListingService {
  /**
   * Indicates whether the component data is loading.
   */
  readonly loading$ = new BehaviorSubject<boolean>(false);

  /**
   * The data to show in the table.
   */
  readonly tableData$ = new BehaviorSubject<AccessTableRow[]>([]);

  /**
   * A map of access scope by ID
   */
  scopeByIdMap: Map<string, AccessScope>;

  /**
   * List of resources.
   */
  readonly resources$ = new BehaviorSubject<Resource[]>([]);

  /**
   * Map of resources by ID.
   */
  resourcesByIdMap: Map<string, Resource>;

  /**
   * Indicates whether user has only viewer privileges
   * on access scopes that he is associated with.
   */
  get isReadOnlyUser(): boolean {
    return !hasPrivilege(this.irisCtx.irisContext, 'ACCESS_SCOPE_MANAGE');
  }

  constructor(
    private ajaxHandler: AjaxHandlerService,
    private accessScopesApiService: AccessScopesApiService,
    private irisCtx: IrisContextService,
    private resourcesApiService: ResourcesApiService,
    private dialogService: DialogService,
    private snackbar: SnackBarService,
    private translate: TranslateService
  ) {}

  /**
   * Fetch data from backend
   */
  fetchData() {
    this.loading$.next(true);

    const params: GetAccessScopeParams = {
      account_id: getMcmAccountId(this.irisCtx.irisContext),
      tenant_id: getUserTenantId(this.irisCtx.irisContext),
    };

    if (this.isReadOnlyUser) {
      params.memberId = this.irisCtx.irisContext.user.sid;
    }

    forkJoin([this.accessScopesApiService.getAccessScopes(params), this.resourcesApiService.getResources(params)])
      .pipe(
        finalize(() => {
          this.loading$.next(false);
        })
      )
      .subscribe(([{ accessScopes }, { resources }]) => {
        this.scopeByIdMap = new Map((accessScopes || []).map(scope => [scope.id, scope]));
        this.resources$.next(resources);
        this.resourcesByIdMap = new Map((resources || []).map(resource => [resource.id, resource]));
        this.tableData$.next(transformApiResponseToTableData(accessScopes, this.resourcesByIdMap));
      }, this.ajaxHandler.handler);
  }

  /**
   * Handler for add or edit access role button click
   *
   * @param mode Indicates whether dialog is opened in add or edit mode
   * @param accessScopeRow row being updated
   */
  onAddEditButtonClick(mode: AddAccessDialogInput['mode'] = 'add', accessScopeRow: AccessTableRow = null): void {
    this.dialogService
      .open<AddUpdateAccessScopeDialogComponent, AccessScopeDialogResponse, AccessScopeDialogData>(
        AddUpdateAccessScopeDialogComponent,
        {
          data: {
            mode,
            resources: this.resources$.getValue(),
            accessScope: accessScopeRow ? this.scopeByIdMap.get(accessScopeRow.id) : null,
            selectedResources: accessScopeRow?.resources || [],
            resourcesByIdMap: this.resourcesByIdMap,
            readOnly: this.isReadOnlyUser,
          },
        }
      )
      .pipe(filter(result => !!result))
      .subscribe(({ accessScope }: AccessScopeDialogResponse) => {
        const tableData = this.tableData$.getValue();
        const index = tableData.findIndex(row => row.id === accessScope.id);
        const updatedRow = transformApiResponseToTableData([accessScope], this.resourcesByIdMap)?.[0];
        if (index !== -1) {
          tableData.splice(index, 1, updatedRow);
          this.tableData$.next([...tableData]);
        } else {
          this.tableData$.next([updatedRow, ...tableData]);
        }
        this.scopeByIdMap.set(accessScope.id, accessScope);

        const snackbarMessage =
          mode === 'edit'
            ? 'helios.accessManagement.accessScope.updateSuccess'
            : 'helios.accessManagement.accessScope.creationSuccess';
        this.snackbar.open(this.translate.instant(snackbarMessage));
      });
  }

  /**
   * Display delete confirmation dialog and trigger delete on confirmation
   *
   * @param access scope id
   */
  openDeleteDialog(id: string): void {
    this.dialogService
      .open(DeleteConfirmationDialogComponent, {
        data: {
          title: 'deleteAccessScope',
          message: 'helios.accessManagement.accessScope.deleteConfirmationDialogMessage',
          confirmLabel: 'delete',
        },
      })
      .pipe(
        filter(Boolean),
        switchMap(() =>
          this.accessScopesApiService.deleteAccessScopeById({
            account_id: getMcmAccountId(this.irisCtx.irisContext),
            tenant_id: getUserTenantId(this.irisCtx.irisContext),
            id,
          })
        )
      )
      .subscribe(
        _ => {
          const updateTableData = this.tableData$.getValue().filter(row => row.id !== id);
          this.tableData$.next(updateTableData);
          this.snackbar.open(this.translate.instant('helios.accessManagement.accessScope.deletionSuccess'));
        },
        errorResponse => {
          const errorMessage = JSON.parse(errorResponse.error)?.errorMessage;
          this.snackbar.open(errorMessage, 'error', true);
        }
      );
  }
}
