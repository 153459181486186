import { OracleArchiveLogInfo } from '@cohesity/api/v2';
import { RecoveryAction } from '@cohesity/iris-shared-constants';
import { RecoverOracleFormOptions } from 'src/app/modules/restore/recover-oracle/model';
import { CreateRecoveryForm } from 'src/app/modules/restore/restore-shared';

/**
 * Specifies the type of Oracle Database.
 *
 * A database instance is a set of memory structures that manage database files. A database is a set of physical files
 * on disk created by the CREATE DATABASE statement. The instance manages its associated data and serves the users of
 * the database.
 * Every running Oracle database is associated with at least one Oracle database instance.
 *
 * Refer https://docs.oracle.com/cd/E11882_01/server.112/e40540/startup.htm#CNCPT601 for a better understanding of
 * Database Instance Structure.
 */
export enum OracleDatabaseType {
  /**
   * Single Instance Database have a one-to-one relationship between the Oracle database and the instance.
   */
  kSingleInstance = 'kSingleInstance',

  /**
   * RAC environments have a one-to-many relationship between the database and the instances.
   */
  kRACDatabase = 'kRACDatabase',
}

/**
 * Specifies the type of Roles a database running in a DataGuard configuration can take.
 *
 * A Data Guard configuration contains one production database, also referred to as the primary database, that
 * functions in the primary role. The primary database can be either a single-instance Oracle database or an Oracle
 * Real Application Clusters database.
 * A standby database is a transactionally consistent copy of the primary database. Similar to a primary database, a
 * standby database can be either a single-instance Oracle database or an Oracle Real Application Cluster database.
 */
export enum OracleDataGuardDBRole {
  /**
   * 'kPrimary' indicates that the current database is primary database.
   */
  kPrimary = 'kPrimary',

  /**
   * This is for mapping against private entities for 'kPrimary' role.
   */
  kPrimaryInt = 1,

  /**
   * 'kStandby' indicates that the current database is standby database.
   */
  kStandby = 'kStandby',

  /**
   * This is for mapping against private entities for 'kStandby' role.
   */
  kStandbyInt = 2,
}

/**
 * Specifies the type of the standby database within the Oracle DataGuard configuration.
 */
export enum OracleDataGuardStandbyType {
  /**
   * 'kPhysical' indicates that the current database provides a physically identical copy of the primary database,
   * with on disk structures identical to the primary database on a block-for-block basis. It is kept synchronized with
   * the primary database through Redo Apply, which recovers the redo data received from the primary database and
   * applies the redo to the physical standby database.
   */
  kPhysical = 'kPhysical',

  /**
   * 'kLogical' indicates that the current database provides the same logical information as the production database,
   * although the physical structure can be different. It is kept synchronized with the primary database through
   * SQL Apply, which transforms the data in the redo received from the primary database into SQL statements and then
   * executing the SQL statements on the standby database.
   */
  kLogical = 'kLogical',

  /**
   * 'kSnapshot' indicates that the current database is a fully updateable standby created by converting a physical
   * standby database into a snasphot standby database. It receives and archives but does not apply redo data from a
   * primary database.
   */
  kSnapshot = 'kSnapshot',
}

/**
 * Specifies the OPEN_MODE type for the Oracle database.
 */
export enum DatabaseOpenMode {
  /**
   * 'kMounted' indicates that the database is open in Mounted mode.
   */
  kMounted = 'kMounted',

  /**
   * 'kReadWrite' indicates that the database is open in R/W mode.
   */
  kReadWrite = 'kReadWrite',

  /**
   * 'kReadOnly' indicates that the database is open in ReadOnly mode.
   */
  kReadOnly = 'kReadOnly',

  /**
   * 'kMigrate' inidcates that the database is open in Migrate mode.
   */
  kMigrate = 'kMigrate',
}

/**
 * Enum for Oracle RMAN backup types.
 */
export enum OracleBackupType {
  /**
   * ImageCopy RMAN backup type.
   */
  kImageCopy = 'kImageCopy',

  /**
   * Sbt RMAN backup type.
   */
  kSbt = 'kSbt',

  /**
   * BackupSets RMAN backup type.
   */
  kBackupSets = 'kBackupSets',
}

/**
 * Enum for Oracle object types.
 */
export enum OracleObjectType {
  /**
   * Conatiner database type.
   */
  kCDB = 'kCDB',

  /**
   * Database type.
   */
  kDatabase = 'kDatabase',

  /**
   * Pluggable database type.
   */
  kPDB = 'kPDB',
}

/**
 * Specifies the type of Oracle multi node selection.
 */
export enum NodeSelectionType {
  /**
   * Auto node selection.
   */
  Auto = 'auto',

  /**
   * Manual node selection.
   */
  Manual = 'manual',
}

/**
 * Cohesity controlled pfile value.
 */
export const CohesityControlledPfileValue = '__cohesity_controlled_pfile_value__';

/**
 * This is the form interface for creating a recovery of Oracle object.
 */
export interface OracleRecoveryForm extends CreateRecoveryForm<RecoverOracleFormOptions> {
  /**
   * Specifies the recovery type whether at object level or archive log recovery.
   */
  recoveryType?: OracleRecoveryType;

  /**
   * Specifies restore task type (eg. Recovery or Validation task)
   */
  taskType?: OracleRestoreTaskType;

  /**
   * Specifies archive log restore info.
   */
  oracleArchiveLogInfo?: OracleArchiveLogInfo;

  /**
   * Specifies if tde enabled PDB is selected for granular restore.
   */
  isPdbTdeRestore?: boolean;
}

/*
 * Recovery related constants
 ******************************************************************************/

export const OracleRestoreType = RecoveryAction.RecoverApps;

/**
 * Specifies the type of Oracle RMAN backup requested.
 */
export type OracleRmanBackupType = 'kImageCopy' | 'kBackupSets' | 'kSbt';

/**
 * Specifies oracle recovery type.
 */
export enum OracleRecoveryType {
  // Specifies Database point in time restore type.
  Database = 'database',

  // Specifies archive log restore type.
  ArchiveLogs = 'archiveLogs',
}

/**
 * Specifies oracle recovery task type eg: Restore Datasbase/ Recovery Validation .
 */
export enum OracleRestoreTaskType {
  // Specifies recover action type.
  Recovery = 'recovery',

  // Specifies recvery validation action type.
  RecoveryValidation = 'recoveryValidation'
}

/**
 * Archive log selection type.
 */
export type ArchiveLogViewSelectionType = 'Time' | 'Scn' | 'Sequence';

/**
 * Archive log range type enum definition.
 */
export enum OracleArchiveLogRangeType {
  Sequence = 'Sequence',
  Scn = 'Scn',
  Time = 'Time',
}

/**
 * Enum for the possible node settings types.
 */
export enum NodeSettingsType {
  Backup = 'backup',
  Restore = 'recovery',
}

/**
 * Enum for recover to options for recovery details.
 */
export enum OracleRecoverToOptions {
  /**
   * This option will put the recovered object(s) back to their original source.
   */
  originalLocation,

  /**
   * This option will put the recovered object(s) to a new source.
   */
  newLocation,

  /**
   * This option will put the recovered object(s) into a new view.
   */
  newView,

  /**
   * This option will allow instant recovery.
   */
  multiStageRestore = 'multiStageRestore',
}

/**
 * Enum for archive log deletion unit.
 */
export enum ArchiveLogRetentionUnit {
  Hours = 'Hours',
  Days = 'Days',
}
