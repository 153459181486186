import { ElementRef, Injectable, inject } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AppPanelComponent, NavItem, ThemeService } from '@cohesity/helix';
import { IS_IBM_AQUA_ENV } from '@cohesity/shared/core';
import { StateService, TransitionService, UIRouterGlobals } from '@uirouter/core';
import { isEmpty } from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, combineLatest, fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ArgusStates } from 'src/app/app-modules';
import { ClusterConfig } from 'src/app/models';
import { Routes } from 'src/app/shared/constants';
import { environment } from 'src/environments/environment';

import { AjsStateDeclaration } from '../state/app-state-declaration';
import { GuardPriority } from '../state/state-guard';
import { AppStateService } from './app-state.service';
import { ClusterService } from './cluster.service';
import { StateManagementService } from './state-management.service';
import { UserService } from './user.service';

/**
 * Frame Options determines whether to hide or show the iframe.
 * If we have to show, then it shows the iframe to show the data.
 */
export interface FrameOptions {

  /**
   * Holds whether to show the iframe or not.
   */
  show: boolean;

  /**
   * The state to navigate to in the UI.
   */
  navigateTo?: string;

  /**
   * State Params if any.
   */
  stateParams?: any;

  /**
   * The selected cluster.
   */
  cluster?: ClusterConfig;
}

interface HeliosStateCompatibility {

  /**
   * The min helios version to support this state.
   */
  minHeliosVersion?: string;

  /**
   * The fallback state incase min helios version does not match.
   */
  fallback?: string;
}

interface HeliosCompatibilityMap {
  [key: string]: HeliosStateCompatibility;
}

/**
 * This service handles the processing of the iframe logic when rendering the
 * content in Helios.
 */
@Injectable({
  providedIn: 'root'
})
export class McmViewService {
  /**
   * Determines whether the UI is running in IBM Aqua mode.
   */
  readonly isIBMAquaEnv = inject(IS_IBM_AQUA_ENV);

  /**
   * Represents the current selected cluster.
   */
  private _selectedCluster: ClusterConfig;

  /**
   * Holds whether to show the frame to replace the content.
   */
  private _showFrame = false;

  /**
   * Holds the fallback state if the current state is not supported.
   */
  private _heliosCompatibilityMap: HeliosCompatibilityMap = {
    'protection-group.groups': {
      minHeliosVersion: 'he141',
      fallback: 'jobs'
    },
    'ng-storage': {
      minHeliosVersion: 'he14',
      fallback: 'storage'
    },
    'devops.clone' : {
      minHeliosVersion: 'he12',
      fallback: 'clone-view',
    },
    'sources-ng': {
      minHeliosVersion: 'he141',
      fallback: 'sources-deprecated',
    },
    'performance': {
      minHeliosVersion: 'he141',
      fallback: 'performance-deprecated',
    },
    'protection-policy.policies': {
      minHeliosVersion: 'he141',
      fallback: 'policies',
    },
    'protection-builder': {
      minHeliosVersion: 'he141',
      fallback: 'job-modify',
    },
    'recovery.list' : {
      minHeliosVersion: 'he15',
      fallback: 'recover',
    },
    'data-tiering.dashboard': {
      minHeliosVersion: 'he16',
      fallback: 'dataMigration',
    },
    'ng-remote-clusters': {
      minHeliosVersion: 'he18',
      fallback: 'remote-clusters'
    },
    'external-targets-ng': {
      minHeliosVersion: 'he17',
      fallback: 'external-targets'
    },
    'health': {
      minHeliosVersion: 'he18',
      fallback: 'alerts.content'
    },
    'ng-audit-logs': {
      minHeliosVersion: 'he18',
      fallback: 'audit.cluster'
    }
  };

  /**
   * Excluded States that are not be rendered in the nav item list.
   */
  private _heliosIframeExcludedStates = {
    he111: [
      'antivirus',
      'dataMigration',
      'security-advisor',
      'account-security',
      'file-services-consumption-details',
      'file-services-performance-details',
      'file-services-client-distribution-details'
    ],
    he12: [
      'security-advisor',
      'account-security',
      'file-services-consumption-details',
      'file-services-performance-details',
      'file-services-client-distribution-details',
    ],
    he13: [
      'security-advisor',
      'account-security',
      'file-services-consumption-details',
      'file-services-performance-details',
      'file-services-client-distribution-details',
    ],
    he14: [
      'security-advisor',
      'account-security',
      'file-services-consumption-details',
      'file-services-performance-details',
      'file-services-client-distribution-details',
    ],
    he141: [
      'security-advisor',
      'account-security',
      'file-services-consumption-details',
      'file-services-performance-details',
      'file-services-client-distribution-details',
    ],
    he15: [
      'security-advisor',
      'account-security',
      'file-services-consumption-details',
      'file-services-performance-details',
      'file-services-client-distribution-details',
    ],
    he151: [
      'security-advisor',
      'account-security',
      'file-services-consumption-details',
      'file-services-performance-details',
      'file-services-client-distribution-details',
    ],
    he16: [
      'account-security',
      'file-services-consumption-details',
      'file-services-performance-details',
      'file-services-client-distribution-details',
    ],
    he18: [
      'account-security',
    ],
    general: [
      'dashboard',
      'field-messages',
      'search',
      'license',
      'reporting.blank',
      // exclusing micro-frontned app states to prevent iframe to load them essentially preventing
      // us from inception issue eg. https://jira.cohesity.com/browse/ENG-304141
      ...ArgusStates.map(({ name }) => name),
    ],
  };

  /**
   * An observable which holds any change in cluster context.
   */
  selectedCluster$ = new BehaviorSubject<ClusterConfig | null>(null);

  /**
   * An observable of the service which lets the consumers know whether to show or hide frame.
   */
  showFrame$ = new BehaviorSubject<boolean>(this._showFrame);

  /**
   * An observable of the service which lets the consumers on when to update the frame.
   */
  updateFrame$ = new BehaviorSubject<FrameOptions>({show: this._showFrame});

  /**
   * Holds the iframe reference for communication between states.
   */
  iframe: ElementRef;

  /**
   * Force update IFrame state via hard reload. This will push
   * new state to {@link updateFrame$}
   */
  forceUpdateFrame = false;


  /**
   * Init the service.
   */
  constructor(private stateService: StateService,
    private routerGlobals: UIRouterGlobals,
    private transitionService: TransitionService,
    private themeService: ThemeService,
    private appStateService: AppStateService,
    private userService: UserService,
    private stateManagementService: StateManagementService,
    private clusterService: ClusterService,
    private dialog: MatDialog,
    private logger: NGXLogger) {

    // This service is meant only to run on the master IRIS container for Helios.
    // When running inside an iFrame, we do not want to determine which Helios version
    // needs to loaded, as the master container handles it.
    if (environment.heliosInFrame) {
      this.addThemeChangeListener();
      return;
    }

    // Close the dialog in the parent window whenever there is an iframe opened
    combineLatest([this.showFrame$, this.dialog.afterOpened]).pipe(
      // Only emit when iframe is visible and a dialog has opened
      filter(([showFrame, dialogRef]) => Boolean(showFrame && dialogRef))
    ).subscribe(([, dialogRef]) => {
      if (dialogRef.componentInstance instanceof AppPanelComponent) {
        // Don't close the dialog if it is an app panel, as this is generally
        // opened from masthead, which is never part of the iframe.
        // TODO: There might be more dialogs which could be allowed.
        return;
      }

      dialogRef.close();
    });

    // Listen for the change in selected cluster scope.
    this.appStateService.selectedScope$.subscribe(cluster => this.selectedCluster = cluster);

    this.transitionService.onBefore({}, (trans) => {
      // Do not process this information for non-MCM features.
      if (!this.clusterService.isMcm) {
        return;
      }
      // If the user is not logged in. The hide the frame, the user is not allowed
      // navigate any child states.
      // If the state is one of the excluded state to not show in the frame, then return.
      if (!this.userService.isLoggedIn ||
        this._heliosIframeExcludedStates.general.some(state => trans.to()?.name?.includes(state))) {
        this.showFrame$.next(false);
        return;
      }

      const show = this.selectedCluster && !!this.selectedCluster.heliosVersion;
      this.showFrame$.next(show);
    }, { priority: GuardPriority.Bootstrap });

    // Listen on any state change and determine whether the iframe needs to be
    // loaded.
    this.transitionService.onStart({}, trans => {
      // Do not process this information for non-MCM features.
      if (!this.clusterService.isMcm) {
        return;
      }

      const show = this.showFrame$.value;

      // To perform the seamless transition within the iframe. We determine whether
      // the state object is exposed through the existing angular scope.
      // This is not a final solution, TODO(Sam), Look into the bi-direction communication
      // through iframe postMessage.
      if (show) {
        const finalState = trans.to();

        // If the current cluster is not able to transition to end state.
        // Abort the current transition and navigate to the clusters desired
        // state.
        if (!this.canAccessState(finalState.name)) {
          const matchedState = this.getTransitionState(finalState.name);

          // Do not do recursive calls if the same state is matched.
          if (matchedState !== finalState.name) {
            return this.stateService.target(matchedState, trans.params(), {reload: true});
          }
        }

        let navigateTo = this.getTransitionState(finalState.name);

        // try to capture iframe state
        let childState;

        try {
          childState = this.iframe &&
          this.iframe.nativeElement.contentWindow &&
          this.iframe.nativeElement.contentWindow.angular &&
          this.iframe.nativeElement.contentWindow.angular.element('html')?.scope()?.$state;
        } catch (error) {
          this.logger.error('Failed to capture IFrame child state', error);
        }

        const params = Object.assign({}, trans.params());

        // The $state is exposed then use it to traverse the entire iframe.
        if (childState && !this.forceUpdateFrame) {
          // Special case for performance and other states later on.
          switch (true) {
            // If anything matches performance, like performance deprecated.
            // In the iframe load the performance state.
            case finalState.name.includes('performance') && !this.matchesMinVersion('he70'):
              navigateTo = 'performance';
              break;
            case finalState.name.includes('reports'):
              navigateTo = 'reports';
              break;
            default:
              navigateTo = finalState.name;
          }
          // This is going to start a transition in iframe app ui router state
          childState.go(navigateTo, params);
          setTimeout(() => this.keepFrameInFocus(), 5000);
        } else {
          this.updateFrame$.next({
            show,
            cluster: this._selectedCluster,
            navigateTo,
            stateParams: params,
          });
        }
        return true;
      }
    }, { priority: GuardPriority.Bootstrap });
  }

  /**
   * Passes the focuses from the parent to the iframe that is loaded.
   */
  private keepFrameInFocus(): void {

    // It seems there is a problem with the hybrid angular router where
    // instantiating a $state.go call to the new angular state. It does
    // not resolve the template completely until the iframe in which it is
    // called, comes into focus. BTW document.focus() does not seem to do
    // the trick.
    // TODO(Sam): Explore an alternative.
    const iFrameDoc = this.iframe.nativeElement.contentDocument;
    if (iFrameDoc) {
      iFrameDoc.documentElement.click();
    }

  }

  /**
   * Returns the transition state when transiting into the iframe.
   */
  private getTransitionState(stateName: string): string {

    // When switching from all cluster states such as global search or
    // cluster-management, etc.. Get the corresponding single cluster state.
    // This will make sure that the iframe always loads in single cluster
    // context and not in multi-cluster states and then switch.
    // This will avoid the unnecessary error message such as
    // 1. Clusters not associated with account
    // 2. Loading Cohesity Demo Cluster.
    const stateMap = this.stateService.get(stateName);
    const allClusterContext = this.stateManagementService.allClusterMap[stateName];
    const config = allClusterContext || (stateMap as AjsStateDeclaration).allClustersSupport;
    if (typeof config === 'object') {
      return config.singleClusterState;
    }
    return this.getStateForHeliosVersion(stateName);
  }

  /**
   * Returns the current selected cluster.
   */
  get selectedCluster(): ClusterConfig {
    return this._selectedCluster || {};
  }

  /**
   * Set the current selected cluster.
   * 1. Updates the selected cluster observable.
   * 2. Performs a check to determine whether to load the iframe.
   */
  set selectedCluster(clusterConfig: ClusterConfig) {
    // Do not process any information for non-MCM features.
    if (!this.clusterService.isMcm) {
      return;
    }

    if (!clusterConfig) {
      return;
    }

    this._selectedCluster = clusterConfig;

    // Determine which state to land when the cluster switch happens.
    // Usually the state map determines the final state based on the cluster version.
    const currentState = this.routerGlobals.transition ?
      this.routerGlobals.transition.to() : this.routerGlobals.current;
    const currentStateParams = this.routerGlobals.transition ?
      this.routerGlobals.transition.params() : this.routerGlobals.params;
    const navigateTo = this.getTransitionState(currentState.name);
    const excludedStates = this._heliosIframeExcludedStates.general;

    this._showFrame = !!this._selectedCluster.heliosVersion &&
      currentState.name.length &&
      !excludedStates.some(state => currentState.name.includes(state));

    // Update the navigation and set the observable.
    this.updateFrame$.next({
      show: this._showFrame,
      navigateTo,
      cluster: this._selectedCluster,
      stateParams: currentStateParams,
    });

    // Update the showFrame observable.
    this.showFrame$.next(this._showFrame);

    // Reload the state to update the nav items for for iframe scenarios
    // or when specifically interacting with a cluster. This allows for
    // dashboard state (which is not iframed for a cluster) to also refresh.
    if (this._showFrame) {
      // This case handles the running transition explicitly because
      // doing hard refresh, if this block hits, it is observed
      // that state is yet not state in $current and it remains empty ('')
      // i.e. initial state of ui-router which gives error on $state.reload
      // (earlier implementation)
      // Also, it is not logically correct to do a reload in middle of ongoing
      // transition. Consider at this moment, A ---> B is active, $state.reload
      // will abort this transition and reload state A which seems logical incorrect.
      // The desired state B should be retargeted in such a case.
      if (this.stateService.transition?.isActive()) {
        const toState = this.stateService.transition.to();
        const toStateParams = this.stateService.transition.params();
        this.stateService.transition.abort();
        this.stateService.go(toState, toStateParams, { reload: true, inherit: false });
      } else {
        this.stateService.reload();
      }
    }
  }

  /**
   * Returns true if the current cluster is all cluster in Helios Mode.
   */
  get isHeliosAllCluster(): boolean {
    return this.clusterService.isMcm && this.selectedCluster._allClusters;
  }

  /**
   * Returns true if the cluster id is supposed to load in iframe.
   *
   * @param clusterId The cluster Id to determine whether it loads in frame
   */
  isClusterLoadedinIFrame(clusterId: number): boolean {
    if (!this.clusterService.isMcm || environment.heliosInFrame) {
      return false;
    }

    return !!this.appStateService.remoteClusterList
      .find(cluster => cluster.clusterId === clusterId)?.heliosVersion;
  }

  /**
   * Returns the matching state from the compatabile matrix
   *
   * @param   stateName   the state name to be mapped.
   * @return  The mapped state object or empty.
   */
  private getMappedState(stateName: string): HeliosStateCompatibility {
    const mappedState = Object.keys(this._heliosCompatibilityMap).find(stateKey => stateName.includes(stateKey));
    return this._heliosCompatibilityMap[mappedState] || {};
  }

  /**
   * Returns the stateName based on the whether Helios Version Supports the state.
   *
   * @param   stateName         The stateName to be returned.
   * @returns The state to be loaded for the Helios Version.
   */
  private getStateForHeliosVersion(stateName: string): string {
    const stateMap: HeliosStateCompatibility = this.getMappedState(stateName);
    return this.canAccessState(stateName) ? stateName : stateMap.fallback || '';
  }

  /**
   * Returns the whether Helios Version Supports the state.
   *
   * @param   stateName         The stateName to be returned.
   * @returns Returns true, if the state is available in the version..
   */
  canAccessState(stateName: string, clusterId?: string): boolean {
    // If running in an iframe, then this state is available to be shown
    // in the cluster.
    if (environment.heliosInFrame) {
      return true;
    }

    // If not running in Helios mode, then this state is available to be shown
    // in the cluster.
    if (!this.clusterService.isMcm) {
      return true;
    }

    let cluster = this.selectedCluster;

    // If the current cluster is not loaded, then the navigation to the state is not
    // yet ready to be transitioned to.
    if (isEmpty(cluster)) {
      return false;
    }

    // In case of global search, if a clusterId is sent, make sure the cluster
    // is compatible with navigateTo state.
    if (parseInt(String(clusterId), 10)) {
      cluster = this.appStateService.remoteClusterList.find((c: ClusterConfig) =>
        c.clusterId === parseInt(clusterId, 10)
      ) || {};
    }

    const stateMap = this.getMappedState(stateName);
    const clusterHeliosVersion = this.getNumberedHeliosVersion(cluster);
    // TODO(Sam): Create a util to compare helios version rather than simple string
    // comparison.
    return !cluster.heliosVersion || !stateMap.minHeliosVersion ||
      clusterHeliosVersion >= stateMap.minHeliosVersion;
  }

  /**
   * Simple query to determine whether the current helios version is compatible with a specified minimum version.
   *
   * @param minVersion String version number.
   * @returns True if the specified minimum version is supported.
   */
  matchesMinVersion(minVersion = 'he111') {
    const clusterHeliosVersion = this.getNumberedHeliosVersion(this.selectedCluster);
    return clusterHeliosVersion >= minVersion;
  }

  /**
   * Returns the numbered helios version for a cluster
   * e.g. he16, he18 etc.
   * Starting 7.x clusters, heliosVersion are named as cluster/hexx
   * This function will strip 'cluster/' from heliosVersion.
   *
   * @param cluster cluster context
   *
   * @returns heliosVersion for that cluster
   */
  getNumberedHeliosVersion(cluster: ClusterConfig): string {
    if (cluster.heliosVersion?.startsWith('cluster')) {
      return cluster.heliosVersion.substring(cluster.heliosVersion.indexOf('/') + 1);
    }
    return cluster.heliosVersion;
  }

  /**
   * Returns a fresh copy of navList for main navigation.
   *
   * @returns The list of nav items.
   */
  getNavItems(): NavItem[] {
    const navList: NavItem[] = [
      {
        displayName: 'dataProtection',
        state: 'jobs',
        icon: 'helix:protection',
        subNavList: [
          {
            displayName: 'protection',
            state: this.getStateForHeliosVersion('protection-group.groups'),
          },
          {
            displayName: 'recoveries',
            state: this.getStateForHeliosVersion('recovery.list'),
          },
          {
            displayName: 'sources',
            state: this.getStateForHeliosVersion('sources-ng'),
          },
          {
            displayName: 'policies',
            state: this.getStateForHeliosVersion('protection-policy.policies'),
          },
          {
            displayName: 'cloudRetrieve',
            state: 'cloud-retrieval',
          },
          !this.isIBMAquaEnv && {
            displayName: 'Runbooks',
            state: 'dr-runbook',
          },
        ],
      },
      {
        displayName: 'infrastructure',
        state: 'cluster',
        icon: 'iris:inventory',
        subNavList: [
          {
            displayName: 'remoteClusters',
            state: this.matchesMinVersion('he72') ? 'ng-remote-clusters' : 'remote-clusters',
          },
          {
            displayName: 'externalTarget',
            state: this.getStateForHeliosVersion('external-targets-ng'),
          },
          {
            displayName: 'flashBlade',
            state: 'remote-disks',
          },
        ],
      },
      !this.isIBMAquaEnv && {
        displayName: this.matchesMinVersion('he18') ? 'smartFiles' : 'fileServices',
        state: 'ng-views',
        icon: 'helix:file-services',
        subNavList: [
          {
            displayName: 'views',
            state: this.getStateForHeliosVersion('ng-views.views'),
          },
          {
            displayName: 'shares',
            state: this.matchesMinVersion('he72') ? 'ng-views.views.all-shares' : 'nfs.shares',
          },
          {
            displayName: 'consumption',
            state: 'file-services-consumption-details',
          },
          {
            displayName: 'performance',
            state: 'file-services-performance-details',
          },
          {
            displayName: 'clientConnections',
            state: 'file-services-client-distribution-details',
          },
          {
            displayName: 'nasTiering',
            state: this.getStateForHeliosVersion('data-tiering.dashboard'),
          },
          {
            displayName: 'antivirus',
            state: 'antivirus',
          },
        ],
      },
      // The Security advisor page is same as the Account security page, which was renamed in the 7.x release
      // The Security advisor page is only available for 6.6.x and 6.8.x clusters
      // Since, we don't know the container name for the future releases, similar to he16 and he18 for 7.x release,
      // I have added the following hardcoded condition to hide security advisor for clusters other than 6.6.x and 6.8.x
      ['he16','he18'].includes(this.getNumberedHeliosVersion(this.selectedCluster)) && {
        displayName: 'securityTools',
        state: 'security-advisor',
        icon: 'fingerprint',
        subNavList: [
          {
            displayName: 'securityAdvisor',
            state: 'security-advisor',
          },
        ]
      },
      {
        displayName: 'testAndDev',
        state: this.getStateForHeliosVersion('devops.clone'),
        icon: 'build!outline',
        subNavList: [],
      },
      !this.isIBMAquaEnv && {
        displayName: 'marketplace',
        state: 'apps-management',
        icon: 'store!outline',
        subNavList: [
          this.matchesMinVersion('he18') ? {
            displayName: 'summary',
            state: 'app-store-dashboard'
          } : null,
          {
            displayName: 'myApps',
            state: 'apps-management',
          },
          {
            displayName: 'allApps',
            state: undefined,
            isEnabled: true,
            isVisible: true,
            href: Routes.marketplace,
          },
        ].filter(Boolean),
      },
      {
        displayName: 'system',
        state: 'cluster',
        icon: 'helix:system',
        subNavList: [
          {
            displayName: 'health',
            state: this.getStateForHeliosVersion('health'),
          },
          {
            displayName: 'storage',
            state: this.getStateForHeliosVersion('ng-storage'),
          },
          {
            displayName: 'monitoringPerformance',
            state: 'performance-deprecated',
          },
          {
            displayName: 'advancedDiagnostics',
            state: 'diagnostics',
          },
          {
            displayName: 'auditLogs',
            state: this.getStateForHeliosVersion('ng-audit-logs'),
          },
          this.clusterService.isMcmOnPrem ? null : {
            displayName: 'fieldMessages',
            state: 'field-messages',
          },
        ],
      },
      {
        displayName: 'reporting',
        state: 'reporting.blank',
        icon: 'assessment!outline',
        subNavList: [],
      },
      {
        displayName: 'settings',
        state: 'cluster',
        icon: 'settings!outline',
        subNavList: [
          // TODO(Sam): Refactor this logic into Nav Service for passthroughs.
          {
            displayName: 'summary',
            state: 'cluster.summary',
          },
          {
            // TODO(jeff): Confirm rename to "Identity Management". This will have many touchpoints.
            displayName: 'accessManagement',
            state: this.matchesMinVersion('he72') ? 'on-prem-access-management' : 'access-management',
          },
          // The Security advisor page is same as the Account security page, which was renamed in the 7.x release
          {
            displayName: 'accountSecurity',
            state: 'account-security',
          },
          {
            displayName: 'networking',
            state: 'networking',
          },
          {
            displayName: 'snmp',
            state: 'snmp-view',
          },
          {
            displayName: 'upgrade',
            state: 'cluster-upgrade',
          },

          // TODO(jeff): Confirm this location. Not including in confluence doc.
          {
            displayName: 'License',
            state: 'license-deprecated',
          },

          // TODO(jeff): verify and implement "Multitenancy" name change. This has many touch points.
          {
            displayName: 'organizations',
            state: 'access-management.tenants',
          },
        ]
      },
    ];
    return this.excludeNavItems(navList);
  }

  /**
   * Returns the filtered list of NavItem with excluded nav states &
   * its children nav items
   *
   * @returns The list of nav items.
   */
  excludeNavItems(navList: NavItem[]): NavItem[] {
    return navList.filter(navItem => {
      if (navItem) {
        // removing parent node If condition satisfy for parent node to remove state from helios IFrame
        const parentStates = this._heliosIframeExcludedStates[this.getNumberedHeliosVersion(this.selectedCluster)];
        const isExcluded = parentStates && parentStates.includes(navItem.state);
        if (isExcluded) {
          return false;
        }
        // keeping only valid sub nav items by removing null values.
        navItem.subNavList = (navItem.subNavList || []).filter(subNavItem => {
          if (!subNavItem) {
            return false;
          }

          const states = this._heliosIframeExcludedStates[this.getNumberedHeliosVersion(this.selectedCluster)];
          if (!states) {
            return true;
          }
          return !states.includes(subNavItem.state);
        });
        return true;
      }

      // removing the parent nav it navItem is not defined for him.
      return false;
    });
  }

  /**
   * Watch for changes in theme key in local storage.
   */
  addThemeChangeListener() {
    if (this.isIBMAquaEnv) {
      return ;
    }
    fromEvent<StorageEvent>(window, 'storage').pipe(
      filter(ev => ev.key === 'helix.theme')
    ).subscribe(ev => this.themeService.setTheme(ev.newValue));
  }
}
