// Module: Partitions Magagement Page

;(function (angular, undefined) {
  'use strict';

  angular
    .module('C.partitions')
    .controller('partitionsController', partitionsControllerFn);

  function partitionsControllerFn($rootScope, $scope, $state, PartitionService,
    ViewBoxService, cModal, evalAJAX, cUtils) {

    var getClusterPartitions;

    $scope.cUtils = cUtils;

    $scope.text = $rootScope.text.platformPartitionsPartitions;

    $scope.partitionListReady = false;
    $scope.nodeDialogActive = false;
    $scope.partitions = [];
    $scope.viewBoxes = [];

    /**
     * interfacaes the PartitionService to retrieve an array of partitions
     *
     * @method   getClusterPartitions
     */
    getClusterPartitions = function getClusterPartitions() {
      PartitionService.getPartitions().then(
        function getPartitionsSuccess(partitions) {
          $scope.partitions = partitions;
          $scope.getViewBoxes();
        },
        function getPartitionsFailure(response) {
          evalAJAX.errorMessage(response);
        }
      ).finally(
        function getPartitionsFinally() {
          $scope.partitionListReady = true;
          if (!$scope.partitions.length) {
            $state.go('partition', { mode: 'new' });
          }
        }
      );
    };

    /**
     * Gets all view boxes
     *
     * @method   getViewBoxes
     */
    $scope.getViewBoxes = function getViewBoxes() {
      ViewBoxService.getViewBoxes({}).then(
        function getViewBoxesSuccess(viewBoxes) {
          $scope.viewBoxes = viewBoxes;
          if ($scope.viewBoxes.length > 0) {
            $scope.countViewBoxesByPartition();
          }
        },
        evalAJAX.errorMessage
      );
    };

    /**
     * Counts ViewBoxes by Partition
     * updates Partition view box count for total view boxes per partition
     *
     * @method   countViewBoxesByPartition
     */
    $scope.countViewBoxesByPartition = function countViewBoxesByPartition() {

      var viewboxCountGroupedByParition = $scope.viewBoxes.reduce(
        function eachViewbox(acc, viewbox) {
          if (!acc[viewbox.clusterPartitionId]) {
            acc[viewbox.clusterPartitionId] = 0;
          }

          acc[viewbox.clusterPartitionId]++;
          return acc;
        },
        {}
      );

      $scope.partitions = $scope.partitions.map(
        function eachParition(partition) {
          partition.viewBoxCount = viewboxCountGroupedByParition[partition.id];
          return partition;
        }
      );
    };


    /**
     * Delete the requested partition.
     *
     * @method   deletePartition
     * @param    {object}   partition   partition to delete
     */
    $scope.deletePartition = function deletePartition(partition) {
      // Does this partition have Viewboxes?
      ViewBoxService.getViewBoxesByPartitionId(partition.id).then(
        function getViewBoxesSuccess(response) {
          var options;
          var contentString;

          // If partition has at least one view box, we cannot delete it.
          if (response.data.length > 0) {
            // Prepare the modal.
            options = {
              actionButtonText: $scope.text.ok,
              title: $scope.text.cannotDeleteTitle,
              content: [
                $scope.text.cannotDelete,
                '<strong>',
                partition.name,
                '</strong>',
                $scope.text.cannotDelete2
              ].join(''),
              hideCancelButton: true,
            };

            // Show the modal.
            cModal.showModal({}, options);
          } else {
            // There are no view boxes, so we are safe to delete.
            // Prepare the modal.
            contentString = [
              $scope.text.deletePartitionText,
              '<strong>',
              partition.name,
              '</strong>',
              $scope.text.deletePartitionText2
            ].join('');

            options = {
              closeButtonText: $scope.text.cancel,
              actionButtonText: $scope.text.deleteText,
              title: $scope.text.deletePartitionTitle,
              content: contentString,
            };

            // Show the modal.
            cModal.showModal({}, options).then(function onCloseModal() {
              // Prepare delete view box call.
              PartitionService.deletePartition(partition.id).then(
                function onDeletePartitionSuccess() {
                  // refresh the partitions list
                  getClusterPartitions();
                  $rootScope.$broadcast('partitions:update');
                },
                evalAJAX.errorMessage
              );
            });
          }
        },
        evalAJAX.errorMessage
      );
    };

    getClusterPartitions();
  }

})(angular);
