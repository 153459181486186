import { NgModule } from '@angular/core';
import { ByteSizeModule } from '@cohesity/helix';
import { HumanizeFromNumbersPipeModule } from '@cohesity/utils';
import { TranslateModule } from '@ngx-translate/core';

import { MetricValuePipe } from './metric-value.pipe';

const PIPES = [
  MetricValuePipe,
];

@NgModule({
  imports: [
    ByteSizeModule,
    HumanizeFromNumbersPipeModule,
    TranslateModule.forChild(),
  ],
  providers: [...PIPES],
  exports: [...PIPES],
  declarations: [...PIPES],
})
export class MetricValuePipeModule {}
