/* tslint:disable */

/**
 * Specifies tags action async request status.
 */
export enum TagsAsyncReqStatus {
  Scheduled = 'Scheduled',
  Running = 'Running',
  Completed = 'Completed',
  Failed = 'Failed'
}
