<ng-container *ngIf="nodeContext">
  <span *ngIf="showAutoSelectLabel">{{autoSelectedLabel}}</span>
  <span *ngIf="showExcludeLabel">{{excludedLabel}}</span>

  <button mat-icon-button
    id="data-tree-exclude-{{node.id}}"
    cogDataId="exclude"
    [inheritDataId]="true"
    type="button"
    *ngIf="node.isTag && (nodeContext.canExclude || nodeContext.excluded) && !isAutoSelected"
    (click)="handleExclusionClick()"
    [matTooltip]="excludeTooltip">
    <cog-icon shape="helix:status-cancel" [color]="nodeContext.excluded ? 'warn' : undefined"></cog-icon>
  </button>

  <button mat-icon-button
    id="data-tree-auto-select-{{node.id}}"
    cogDataId="autoselect"
    [inheritDataId]="true"
    type="button"
    *ngIf="nodeContext.canAutoSelect || isAutoSelected && !nodeContext.excluded"
    (click)="handleAutoSelectClick()"
    [matTooltip]="autoSelectTooltip">
    <cog-icon [shape]="autoSelectIcon"></cog-icon>
  </button>
</ng-container>
