import { Directive, Injector, ElementRef } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'ajs-health-settings',
})
export class HealthSettingsComponent extends UpgradeComponent {
  constructor(injector: Injector, elementRef: ElementRef) {
    super('cAlertsSettingComponent', elementRef, injector);
  }
}
