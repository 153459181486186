import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import * as Highcharts from 'highcharts';
import Heatmap from 'highcharts/modules/heatmap';

import { HighchartsComponent } from '../core';

/**
 * This is needed as highcharts throws error 17: The requested series type does not exist.
 */
Heatmap(Highcharts);

/**
 * @description
 * Heatmap chart is used to show hot spots in the series.
 *
 * @example
 *    <cog-heatmap-chart
 *      [seriesData]="seriesData"
 *      [customChartOptions]="chartOptions"
 *      colorSetClass="custom-theme">
 *    </cog-heatmap-chart>
 *
 * Sample series data is as follows;
 * seriesData = SeriesHeatmapOptions[] = [
 *   {
 *    type: 'heatmap',
 *    data: [
 *     [1422972000000, 1, 92],
 *     [1422975600000, 1, 58],
 *     [1422982800000, 1, 92],
 *     [1422986400000, 1, 22],
 *     [1422990000000, 1, 92],
 *     [1422993600000, 1, 12]
 *   ],
 * }];
 */
@Component({
  selector: 'cog-heatmap-chart',
  templateUrl: './heatmap-chart.component.html',
  styleUrls: ['./heatmap-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class HeatmapChartComponent extends HighchartsComponent<Highcharts.SeriesHeatmapOptions> {

  /**
   * Color scheme for chart If not, uses default colors
   */
  @Input() colorSetClass: string;

  constructor() {
    super({
      chart: {
        type: 'heatmap',
        styledMode: true,
        backgroundColor: 'transparent',
      },
      time: {
        useUTC: false
      },
      credits: {
        enabled: false,
      },
      title: null,
      xAxis: {
        type: 'datetime',
        startOnTick: true,
        endOnTick: false,
      },
      yAxis: {
        title: null,
      },
      colorAxis: [],
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      series: [],
    } as Highcharts.Options);
  }
}
