// COMPONENT: Restore Progress

;(function(angular, undefined) {
  'use strict';

  /**
   * @ngdoc component
   * @name C.restoreProgress:cRestoreProgress
   * @function
   *
   * @description
   * Displays standarized progress meter panel during restore process
   * Uses c-pulse for displaying current task description and progress bar
   *
   * @example
      <c-restore-progress
        ng-if="restoreInProgress"
        endpoint="{{taskStatusURL}}"
        header="{{::text.header}}">
      </c-restore-progress>
   */
  var modName = 'C.restoreProgress';
  var modDeps = [];
  var componentName = 'cRestoreProgress';
  var options = {
    // These are bound to the controller, not the scope
    bindings: {
      endpoint: '@',
      header: '@',
      callback: '<',
    },
    controller: cRestoreProgressFn,
    templateUrl: 'app/global/c-restore-progress/c-restore-progress.html'
  };

  angular
    .module(modName, modDeps)
    .component(componentName, options);

  cRestoreProgressFn.$inject = ['$rootScope'];
  function cRestoreProgressFn($rootScope) {

    var $ctrl = this;

    /**
     * Initilization function
     *
     * @method  $onInit
     */
    $ctrl.$onInit = function $onInit() {
      // Fallback text if none provided by the contextual controller
      $ctrl.text = $rootScope.text.cRestoreProgess;

      $ctrl.header = $ctrl.header || $ctrl.text.header;
    };

  }

})(angular);
