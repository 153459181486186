import { Injectable } from '@angular/core';
import { DmaasRegion } from '@cohesity/api/dms';
import { McmClusterInfo, McmClusterServiceApi } from '@cohesity/api/private';
import { ProtectionSource, ProtectionSourcesServiceApi } from '@cohesity/api/v1';
import { SourceServiceApi, HeliosTenant, TenantServiceApi, McmSource } from '@cohesity/api/v2';
import {
  IrisContextService,
  isDmsOnlyUser,
  isDmsScope,
  isDmsUser,
  isHeliosTenantUser,
  isMcm,
  isMcmSaaS,
  isOrganizationEnabled,
} from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of, Subscription, zip } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { DmsService, OrganizationsService } from 'src/app/core/services';
import { Environment } from 'src/app/shared';
import { AsyncBehaviorSubject, updateWithStatus } from 'src/app/util';

/**
 * Interface for an object which contains items for global search result.
 */
interface GlobalSearchItems {
  /**
   * Array of clusters for global search result.
   */
  clusters: McmClusterInfo[];

  /**
   * Map of location ids and their names for global search result.
   */
  locationNameMap: Record<string, string>;

  /**
   * Array of regions for global search result.
   */
  regions: DmaasRegion[];

  /**
   * Array of sources for global search result.
   */
  sources: GlobalSearchSource[];

  /**
   * Array of tenants for global search result.
   */
  tenants: HeliosTenant[];
}

/**
 * Type of global search sources values.
 * The source can either be derived from v2 MCM APIs, or v1 source registration
 * info APIs.
 */
export type GlobalSearchSource = McmSource | ProtectionSource;

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchInfoService {
  /**
   * Whether the is user is DMaaS only user.
   */
  isDmsOnlyUser: boolean;

  /**
   * Whether the user is a DMaaS user.
   */
  isDmsUser: boolean;

  /**
   * Whether the user is in DMaaS scope.
   */
  isDmsScope: boolean;

  /**
   * Whether the app is in mcm mode.
   */
  isMcm: boolean;

  /**
   * Whether the app is in mcm saas mode.
   */
  isMcmSaas: boolean;

  /**
   * Whether the user is a tenant user.
   */
  isHeliosTenantUser: boolean;

  /**
   * Behavior subject for an object which contains items for global search result.
   */
  private globalSearchItems$ = new AsyncBehaviorSubject<GlobalSearchItems>(null);

  /**
   * Observable of whether global search items are loading.
   * In AsyncBehaviorSubject, the initial seeded value for loading is false,
   * therefore always return loading as true if `value.result` is not set.
   */
  loading$ = this.globalSearchItems$.pipe(map(value => value?.result ? value?.loading : true));

  /**
   * Observable of all available clusters.
   */
  clusters$ = this.globalSearchItems$.pipe(map(value => value?.result?.clusters));

  /**
   * Observable of registered locations and their names.
   */
  locationNameMap$ = this.globalSearchItems$.pipe(map(value => value?.result?.locationNameMap));

  /**
   * Observable of all available regions.
   */
  regions$ = this.globalSearchItems$.pipe(map(value => value?.result?.regions));

  /**
   * Observable of all sources values.
   */
  sources$ = this.globalSearchItems$.pipe(map(value => value?.result?.sources));

  /**
   * Observable of all available tenants.
   */
  tenants$ = this.globalSearchItems$.pipe(map(value => value?.result?.tenants));

  /**
   * Subscription to fetch global search items.
   */
  private subscription: Subscription;

  constructor(
    private dmsService: DmsService,
    private heliosProtectionSourcesServiceApi: SourceServiceApi,
    private heliosTenantService: TenantServiceApi,
    private irisContextService: IrisContextService,
    private mcmClusterServiceApi: McmClusterServiceApi,
    private organizationsService: OrganizationsService,
    private protectionSourcesServiceApi: ProtectionSourcesServiceApi,
    private translateService: TranslateService,
  ) {
  }

  /**
   * Function to initialize global search info service. This function will
   * load all resources and dependencies for global search.
   */
  initGlobalSearchInfoService() {
    this.isDmsOnlyUser = isDmsOnlyUser(this.irisContextService.irisContext);
    this.isDmsScope = isDmsScope(this.irisContextService.irisContext);
    this.isDmsUser = isDmsUser(this.irisContextService.irisContext);
    this.isMcm = isMcm(this.irisContextService.irisContext);
    this.isMcmSaas = isMcmSaaS(this.irisContextService.irisContext);
    this.isHeliosTenantUser = isHeliosTenantUser(this.irisContextService.irisContext);

    // Load all different info items for global search.
    this.loadInfoItems();
  }

  /**
   * Function to load global search info items to be used by other global search
   * services and components.
   */
  private loadInfoItems() {
    if (this.subscription && !this.subscription.closed) {
      // If this function gets called again, cancel the previous subscription.
      this.subscription.unsubscribe();
    }

    let clusters$: Observable<McmClusterInfo[]>;
    let sources$: Observable<GlobalSearchSource[]>;
    let tenants$: Observable<HeliosTenant[]>;

    if (this.isMcm) {
      clusters$ = this.mcmClusterServiceApi.getUpgradeInfo().pipe(
        catchError(() => of({upgradeInfo: []})),
        map(response => response?.upgradeInfo || [])
      );

      tenants$ = this.irisContextService.irisContext$.pipe(
        map(ctx => isOrganizationEnabled(ctx)),
        take(1),
        switchMap(value => {
          if (value) {
            return zip(
              this.heliosTenantService.GetHeliosTenants({managedOnHelios: true}),
              this.organizationsService.organizations$,
            ).pipe(
              catchError(() => of({tenants: []})),
              map(res => {
                const validTenants = res[1]?.tenantAccesses?.map(access => access.tenantId) || [];
                return res[0]?.tenants?.filter(tenant => validTenants.includes(tenant.id)) || [];
              })
            );
          }

          return of([]);
        }),
      );

      const params: SourceServiceApi.McmGetProtectionSourcesParams = {};

      if (this.isDmsOnlyUser && !params.regionIds?.length
        && !this.irisContextService.irisContext.featureFlags.ngGlobalSearchHelios) {
        // In DMS scope, if no region filter is provided, then this API
        // returns sources from all regions and clusters, which is not
        // desirable.
        params.regionIds = this.dmsService.configuredRegionIds;
      }

      sources$ = this.heliosProtectionSourcesServiceApi.McmGetProtectionSources({
        ...params,
        excludeProtectionStats: true,
      }).pipe(
        catchError(() => of({sources: []})),
        map(response => (response?.sources || []).filter(source => {
          // Only show physical sources which have applications registered to them.
          if (source.environment === Environment.kPhysical) {
            return (source.sourceInfoList || []).some(sourceInfo => Boolean(sourceInfo.applications?.length));
          }

          return true;
        })),
      );
    } else {
      clusters$ = of([{
        clusterId: this.irisContextService.irisContext.clusterInfo.id,
        clusterName: this.translateService.instant('local'),
      }]);
      tenants$ = of([]);
      sources$ = this.protectionSourcesServiceApi.ListProtectionSourcesRegistrationInfo({
        allUnderHierarchy: false,
      }).pipe(
        catchError(() => of({rootNodes: []})),
        map(response => (response?.rootNodes || []).filter(rootNode => {
          // Only show physical sources which have applications registered to them.
          if (rootNode.rootNode === Environment.kPhysical) {
            return Boolean(rootNode.registrationInfo?.environments?.length);
          }

          return true;
        })),

        map(result => result.map(source => source.rootNode)),
      );
    }

    this.subscription = forkJoin([
      clusters$,
      tenants$,
      sources$,
      this.dmsService.getConfiguredRegions().pipe(take(1)),
    ]).pipe(
      map(([clusters, tenants, sources, regions]) => {
        const locationNameMap = {};

        for (const region of regions) {
          locationNameMap[region.id] = region.name;
        }

        for (const cluster of clusters) {
          locationNameMap[cluster.clusterId] = cluster.clusterName;
        }

        return {clusters, tenants, sources, regions, locationNameMap};
      }),
      updateWithStatus(this.globalSearchItems$)
    ).subscribe();
  }
}
