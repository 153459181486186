import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DataTreeNodeContext } from '../data-tree-detail.directive';
import { DataTreeControl } from '../shared/data-tree-control';
import { DataTreeSelectionModel } from '../shared/data-tree-selection-model';
import { DataTreeSource } from '../shared/data-tree-source';
import { DataTreeNode } from '../shared/data-tree.model';
import { DataTreeTableDataSource } from './data-tree-table-data-source';

/**
 * This is the root component for a data tree table. It can be used to render any hierachical data within a mat-table
 * and provide additional logic around selecting individual items as well as auto selecting (and excluding)
 * portions of the tree hierarchy.
 *
 * The strings used in this example come from DataTreeIntl, which can be replaced via dependency
 * injection for the specific cases needed.
 */
@Component({
  selector: 'cog-data-tree-table',
  templateUrl: './data-tree-table.component.html',
  styleUrls: ['./data-tree-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DataTreeTableComponent<T extends DataTreeNode<any>>
implements AfterContentInit, OnChanges, OnDestroy {

  /**
   * Add the cog-table class to the component so that it inherits styling.
   */
  @HostBinding('class.cog-table') tableClass = true;

  /**
   * A reference to the material data table.
   */
  @ContentChild(MatTable, { static: false }) readonly table: MatTable<DataTreeNodeContext<T>>;

  /**
   * The data source for the tree.
   */
  @Input() data: DataTreeSource<T>;

  /**
   * The tree control provides methods for working with the tree's structure, such as finding ancestors or
   * descendant nodes as well as keeping track of which nodes are currently expanded. The data source subscribes
   * to changes from the tree control to determine which nodes should be rendered.
   */
  @Input() treeControl: DataTreeControl<T>;

  /**
   * The tree's current selection model. This tracks items which have been selected, auto selected, or excluded. It also
   * provides a mechanism to add custom selection data to a given node if necessary.
   */
  @Input() treeSelection: DataTreeSelectionModel<T>;

  /**
   * Optional function used to decorate the node's context when it is generated.
   */
  @Input() nodeDecoratorFn: (ctx: DataTreeNodeContext<T>) => DataTreeNodeContext<T> = null;

  /**
   * The data tree uses virtual scrolling to enable strong performance. This scroller emits changes whenever the
   * viewport has been modified.
   */
  @ViewChild(CdkVirtualScrollViewport, { static: false }) set scroller(scroller: CdkVirtualScrollViewport) {
    if (scroller && this.data.scroller !== scroller) {
      this.data.scroller = scroller;
      this.cdr.detectChanges();
    }
  }

  /**
   * A dummy table that can be used to display a fixed header
   */
  @ViewChild('headerTable', { static: true }) readonly headerTable: MatTable<any>;

  /**
   * Column names for the header table to use. These are read from the main tables header row.
   */
  columnNames = [];

  /**
   * The data source that is set on the mat table instance.
   */
  tableDataSource: DataTreeTableDataSource<T>;

  /**
   * Use to clean up subscriptions on destroy.
   */
  private destroy = new Subject<void>();

  /**
   * This flag is set to true after ngAfterContenxt has been run. It prevents
   * the data source from being initialized before everything is ready.
   */
  private contentInitialized = false;

  constructor(private cdr: ChangeDetectorRef) {}

  /**
   * Initialize the Content Children to configure the table and data source
   */
  ngAfterContentInit() {
    if (!this.table) {
      throw new Error('No Material Table Found');
    }
    this.contentInitialized = true;
    this.initializeDataSource();

    // Watch for changes to the external table in order to update the dummy header table.
    this.table._contentColumnDefs.changes.pipe(takeUntil(this.destroy)).subscribe(() => {
      this.updateColumnNames();
    });
  }

  /**
   * Set the data source when the data input changes.
   *
   * @param changes Map of changed properties.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && this.data) {
      this.initializeDataSource();
    }
  }

  /**
   * Cleanup the change detection listener on destroy.
   */
  ngOnDestroy() {
    this.destroy.next();
  }

  /**
   * Track nodes by their id. This allows the dom elements to be reused
   * while the tree is scrolling rather than re-added each time.
   *
   * @param index The node's index in the list
   * @returns The index as a string.
   */
  trackById(index: number, item: any): string {
    return `${item.node.id}`;
  }

  /**
   * Configure the datasource for the table.
   */
  private initializeDataSource() {
    // Don't do anything if this gets called before the component has finished
    // initializing.
    if (!this.table || !this.data || !this.contentInitialized) {
      return;
    }
    if (this.tableDataSource) {
      this.tableDataSource.disconnect();
    }
    const isFlexTable = !(this.table as any)._isNativeHtmlTable;
    if (!isFlexTable) {
      throw new Error('Data Tree Table requires a flex layout rather than a standard table layout');
    }

    this.tableDataSource = new DataTreeTableDataSource(
      this.data,
      this.treeSelection,
      this.treeControl,
      this.nodeDecoratorFn
    );

    // Track by index will force the table to reuse rows rather than render new ones from scratch
    this.table.trackBy = this.trackById;


    this.table.dataSource = this.tableDataSource;

    // Need change detection when the current view or the selection changes. Either of these will trigger updating
    // rendered data
    this.tableDataSource.renderedData.pipe(takeUntil(this.destroy)).subscribe(() => this.cdr.markForCheck());

    this.updateColumnNames();

    // Certain cases cause the table to not render it's data correctly after it has been initialized. Forcing a call to
    // ngAfterContentChecked after we set the dataSource forces the rendering code to re-run and make everything work.
    setTimeout(() => this.table.ngAfterContentChecked());
  }

  /**
   * Adds column defs to our dummy table and update the column names for the dummy header table.
   */
  private updateColumnNames() {
    if (this.table._contentHeaderRowDefs.length) {
      this.columnNames = this.table._contentColumnDefs.filter(def => !!def.headerCell).map(def => def.name);
      this.table._contentColumnDefs.forEach(col => {
        this.headerTable.addColumnDef(col);
      });
      this.cdr.detectChanges();
    }
  }
}
