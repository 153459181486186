<ng-container *ngIf="actions$ | async; let actions">
    <ng-container *ngFor="let item of actions; index as index">
      <ng-container *ngIf="index < wrapAfter">
        <button *ngIf="item.state"
          mat-icon-button
          type="button"
          cogDataId="action-button-{{item.displayName}}"
          eventTrackingId="action-button-{{item.displayName}}"
          class="action-button"
          [class.menu-opened]="navItemMenu.isOpen"
          [inheritDataId]="true"
          [uiSref]="item.state"
          [uiParams]="item.stateParams"
          [matTooltip]="item.displayName | translate">
          <cog-icon [shape]="item.icon"></cog-icon>
        </button>

        <button *ngIf="item.action"
          mat-icon-button
          type="button"
          cogDataId="action-button-{{item.displayName}}"
          eventTrackingId="action-button-{{item.displayName}}"
          class="action-button"
          [class.menu-opened]="navItemMenu.isOpen"
          [inheritDataId]="true"
          [matTooltip]="item.displayName | translate"
          (click)="item.action(item)">
          <cog-icon [shape]="item.icon"></cog-icon>
        </button>
      </ng-container>
    </ng-container>

    <!--
      TODO(milind.luthra) : see if above can be totally replaced with this menu using exposeIconCount.
      Currently, it's not possible because the ngIf block with item.state is unhandled in the menu.
    -->
    <coh-nav-item-menu #navItemMenu
      [inheritDataId]="true"
      cogDataId="action-button-menu"
      eventTrackingId="actions-button"
      [navItems]="actions.slice(wrapAfter)">
    </coh-nav-item-menu>
</ng-container>
