import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AzureDiskInfo } from '@cohesity/api/v1';
import { DataTreeNodeContext, DataTreeNodeDetail } from '@cohesity/helix';
import { AzureSourceDataNode } from '../azure-source-data-node';

interface AzureSpecialParameters {
  excludeAzureDisks: string[];
}

interface SourceSpecialParameters {
  azureSpecialParameters: AzureSpecialParameters;
}

/**
 * This component is used to set and update source special params for azure instance nodes.
 * It is not created directly, but returned from a call to getSpecialParametersComponent
 * in AzureSourceTreeService.
 */
@Component({
  selector: 'coh-azure-disk-exclusion-options',
  templateUrl: './azure-disk-exclusion-options.component.html',
  styleUrls: ['./azure-disk-exclusion-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AzureDiskExclusionOptionsComponent implements DataTreeNodeDetail<AzureSourceDataNode> {
  /**
   * The node context, including info about the node and it's selection status.
   */
  @Input() nodeContext: DataTreeNodeContext<AzureSourceDataNode>;

  /**
   * Gets the node from the nodeContext.
   */
  get node(): AzureSourceDataNode {
    return this.nodeContext.node;
  }

  /**
   * Gets the current options for the node. This either gets them from the selection options, or
   * the default params for the node.
   */
  get currentOptions(): SourceSpecialParameters {
    return this.nodeContext.selection.getOptionsForNode(this.node.id) || {};
  }

  /**
   * Gets the list of excluded azure disks, if any, from the special parameters.
   */
  get excludeAzureDisks(): string[] {
    return (this.currentOptions.azureSpecialParameters || {}).excludeAzureDisks || [];
  }

  /**
   * Get the available azure disks that can be excluded.
   */
  get azureDisks(): AzureDiskInfo[] {
    return this.node.diskInfoList || [];
  }

  /**
   * Form Array getter.
   */
  get diskInfoFormArray(): UntypedFormArray {
    return this.form.controls.volumes as UntypedFormArray;
  }

  /**
   * The form to contain the options.
   */
  form: UntypedFormGroup;

  /**
   * Whether form values are changed.
   */
  showExcludeAzureDiskInfoListWarning = false;

  constructor(private fb: UntypedFormBuilder, private cdr: ChangeDetectorRef) {}

  /**
   * Updates the form based on the current options setting in preparation for displaying the form
   * dialog.
   */
  updateForm() {
    this.form = this.fb.group({
      volumes:  new UntypedFormArray(
        this.azureDisks.map(diskInfo => new UntypedFormControl({
          value: !this.excludeAzureDisks.includes(diskInfo.name),
          disabled: diskInfo.isOsDisk,
        }))
      ),
    });

    this.form.valueChanges.subscribe(() => {
      const excludedDisks = (this.form.getRawValue().volumes || [])
        .map((checked, i) => checked ? null : this.azureDisks[i].name)
        .filter(name => name !== null);
      this.showExcludeAzureDiskInfoListWarning = excludedDisks.length;
    });

    this.showExcludeAzureDiskInfoListWarning = this.excludeAzureDisks?.length > 0;
  }

  /**
   * Updates the selection options after the form has been saved and the dialog has been closed.
   */
  onSave() {
    const options = {
      sourceId: this.node.id,
      azureSpecialParameters: {
        excludeAzureDisks: (this.form.getRawValue().volumes || [])
          .map((checked, i) => checked ? null : this.azureDisks[i].name)
          .filter(name => name !== null),
      },
    };

    if (options.azureSpecialParameters.excludeAzureDisks.length) {
      this.nodeContext.selection.setOptionsForNode(this.node.id, options);
    } else {
      this.nodeContext.selection.removeOptionsForNode(this.node.id);
    }

    this.cdr.detectChanges();
    this.showExcludeAzureDiskInfoListWarning = false;
  }
}
