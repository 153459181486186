/**
 * Specifies the various types of indexed documents.
 * This corresponds to the enum DataType within main/yoda/db/documents.proto
 * Refer the same file for any Metadata info for the below document types.
 */
export enum IndexedDocumentType {
  /**
   * Specifies if the indexed document is a file.
   */
  kFile = 0,

  /**
   * Specifies if the indexed document is a directory.
   */
  kDirectory = 1,

  /**
   * Specifies if the indexed document is a symlink.
   */
  kSymlink = 2,

  /**
   * Specifies if the indexed document is an Email.
   * Details are captured within 'MailboxItemMetadata'.
   */
  kEmail = 3,

  /**
   * Specifies if the indexed document is an Email folder.
   * Details are captured within 'MailboxItemMetadata'.
   */
  kEmailFolder = 4,

  /**
   * Specifies if the indexed document is an Active directory object.
   * Details are captured within 'ADObjectMetadata'.
   */
  kADObject = 5,

  /**
   * Specifies if the indexed document is an Exchange Mailbox.
   * Details are captured within 'ExchangeMailboxMetadata'.
   */
  kExchangeMailbox = 6,

  /**
   * Specifies if the indexed document is an item within OneDrive.
   * This can correspond to an Office365 User's OneDrive or a SharePoint Site's Document library.
   * Details are captured within 'OneDriveMetadata'.
   */
  kOneDrive = 7,

  /**
   * Specifies if the indexed document is an item within NoSQL databases.
   * Details are captured within 'NoSqlMetadata'.
   */
  kNoSql = 8,
}
