/**
 * List of integration statuses types.
 */
export enum IntegrationStatus {
  Active = 'active',
  Error = 'error',
  NotStarted = 'notStarted',
  Paused = 'paused',
  SyncInProgress = 'syncInProgress'
}

/**
 * Integration type
 */
export enum IntegrationType {
  DLP = 'dlp',
  DSPM = 'dspm',
  SIEM = 'siem',
  SOAR = 'soar',
  TI = 'ti',
}

/**
 * SecurityCenter Integration Names
 */
export enum IntegrationName {
  CiscoSecureX = 'Cisco SecureX',
  CrowdStrikeFalconLogScale = 'CrowdStrike Falcon LogScale',
  Cyera = 'Cyera',
  Dasera = 'Dasera',
  MicrosoftSentinel = 'Microsoft Sentinel',
  Normalyze = 'Normalyze',
  PaloAltoNetworksCortexXSoar = 'Palo Alto Networks Cortex XSOAR',
  ServiceNowSecurityOrchestrator = 'ServiceNow Security Orchestrator',
  Wiz = 'Wiz',
  ZscalerDataProtection = 'Zscaler Data Protection',
  CrowdStrikeFalconIntelligence = 'CrowdStrike Falcon Intelligence',
}

/**
 * TI vendors
 */
export enum TiVendor {
  CrowdStrike = 'CrowdStrike'
}

/**
 * Integration action type
 */
export enum IntegrationActionType {
  Resume = 'resume',
  Pause = 'pause',
  Edit = 'edit',
  Delete = 'delete',
  View = 'view',
  ViewDocumentation = 'viewDocumentation',
  Run = 'run',
}

/**
 * Integration click callback function
 */
export type IntegrationClickCallbackFn = () => void;

/**
 * Integration configure callback function
 */
export type IntegrationConfigureCallbackFn = () => void;

/**
 * Custom action callback function
 */
export type IntegrationActionCallbackFn = (actionType: IntegrationActionType) => void;

export interface IntegrationAction {
  /**
   * Integration action type
   */
  actionType: IntegrationActionType;

  /**
   * Integration action callback method
   */
  actionCallbackFn: IntegrationActionCallbackFn;
}

/**
 * MyIntegration card configs
 */
export interface IntegrationConfig {
  /**
   * Integration name
   */
  integrationName: IntegrationName;

  /**
   * Integration grouped category
   */
  integrationType: IntegrationType;

  /**
   * Integration logo url
   */
  logoUrl: string;

  /**
   * Indicates whether intergration is active or not
   */
  status?: IntegrationStatus;

  /**
   * subtext description of integration
   */
  subtles?: string[];

  /**
   * Integration menu actions
   */
  actions?: IntegrationAction[];

  /**
   * Trigger when user click on integration name
   */
  clickIntegration?: IntegrationClickCallbackFn;
}

export interface IntegrationDetail {
  /**
   * Integration app ID
   */
  appId: number;

  /**
   * Integration name
   */
  integrationName: IntegrationName;

  /**
   * Integration grouped category
   */
  integrationType: IntegrationType;

  /**
   * Integration app doc url
   */
  appUrl: string;

  /**
   * Whether user have permission to access any modification
   */
  allowAccess: boolean;

  /**
   * App configuration disable text
   */
  configureTooltipText: string;

  /**
   * Whether app is configurable or not
   */
  isAppConfigurable: boolean;

  /**
   * Whether app is confiured or not
   */
  isAppConfigured: boolean;

  /**
   * Integration logo url
   */
  logoUrl: string;

  /**
   * Integration app description
   */
  shortOverview: string;

  /**
   * Integration configure action
   */
  configure?: IntegrationConfigureCallbackFn;
}
