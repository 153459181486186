<ng-container *ngIf="!filterDefParams.quickFilter">
  <h4 *ngIf="filter.label" class="title">{{ filter.label }}</h4>
  <ng-container *ngTemplateOutlet="filterTemplate"></ng-container>
</ng-container>

<ng-container *ngIf="filterDefParams.quickFilter">
  <cog-quick-filter
    [cogDataId]="filter.label"
    [hideApplyButton]="autoApply"
    [hideButtons]="autoClose"
    [inheritDataId]="true"
    [label]="filter.label"
    [preLabel]="preLabel"
    [note]="filter.note"
    [filterTags]="filter.tagValues$ | async"
    [lockedMessage]="filter.lockedMessage"
    [noClear]="noClear"
    [formStyle]="formStyle"
    (applied)="applyFilters()"
    (cleared)="clearFilters()"
    (menuClose)="handleMenuClose()"
    [xPosition]="filterDefParams.quickFilterPositionX">
    <ng-container *ngTemplateOutlet="filterTemplate"></ng-container>
  </cog-quick-filter>
</ng-container>

<ng-template #filterTemplate>
  <mat-form-field *ngIf="asyncSearch || filterValues?.length > filterValuesSearchThreshold"
    class="cog-tree-select-value-property-filter-search">
    <input cogDataId="search"
      [attr.aria-label]="intlSvc.search"
      [inheritDataId]="true"
      type="text"
      placeholder="{{intlSvc.search}}"
      matInput
      [formControl]="stringSearchControl">
  </mat-form-field>

  <div class="cog-tree-select-value-property-filter-list">
    <mat-tree [dataSource]="nestedDataSource"
      [treeControl]="nestedTreeControl"
      cogDataId="tree"
      [inheritDataId]="true"
      #filterOptions >

      <mat-tree-node
        *matTreeNodeDef="let node"
        matTreeNodeToggle
        matTreeNodePadding
        class = "padding-right-lg"
        [cogDataId]="getLabel(node.filter.label,'leaf-item')"
        [inheritDataId]="true">
        <button mat-icon-button disabled><mat-icon></mat-icon></button>
        <mat-checkbox class="checklist-leaf-node"
          [checked]="selectionModel.isSelected(node.filter)"
          (change)="leafItemSelectionToggle(node)">
          {{node.filter.label}}
        </mat-checkbox>
      </mat-tree-node>

      <mat-nested-tree-node
        *matTreeNodeDef="let node; when: hasNestedChild"
        matTreeNodePadding
        class = "padding-right-lg"
        [cogDataId]="getLabel(node.filter.label,'parent-item')"
        [inheritDataId]="true">
        <div *ngIf ="showExpandCollapse">
          <button mat-icon-button matTreeNodeToggle>
            <mat-icon class="mat-icon-rtl-mirror">
              {{nestedTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          <mat-checkbox [checked]="descendantsAllSelected(node)"
            [indeterminate]="descendantsPartiallySelected(node)"
            (change)="selectionToggle(node)">
            {{node.filter.label}}
          </mat-checkbox>
        </div>

        <div *ngIf="!showExpandCollapse">
          <button mat-icon-button disabled><mat-icon></mat-icon></button>
          <mat-checkbox [checked]="descendantsAllSelected(node)"
          [indeterminate]="descendantsPartiallySelected(node)"
          (change)="selectionToggle(node)">
            {{node.filter.label}}
          </mat-checkbox>
        </div>

        <ul *ngIf = "showExpandCollapse && nestedTreeControl.isExpanded(node)" class = "padding-left">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>

        <ul *ngIf = "!showExpandCollapse" class = "padding-left">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </mat-nested-tree-node>
    </mat-tree>
  </div>
</ng-template>
