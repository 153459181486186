// Decorator: uiSelect add-new directive

;(function(angular, undefined) {
  'use strict';

  angular.module('ui.select')
    .config(uiSelectAddNewConfig);

  function uiSelectAddNewConfig($provide) {
    $provide.decorator('uiSelectChoicesDirective', addNewDecorator);
  }

  /**
   * @ngdoc  decorator
   * @name        ui.select
   * @method      addNew
   *
   * @description
   * Decorate ui-select-choices to show an "add new thing" link. It can be
   * enabled/disabled, and positioned at the top or bottom of the ui-select
   * options list. There is a default label but you should specify a custom one
   * in most cases.
   *
   * Emulated bindings:
   *    {
   *      // Required!
   *      addNew: '&?',
   *
   *      // Default: 'addNew'
   *      addNewLabel: '@?',
   *
   *      // Default: true
   *      addNewEnable: '=?',
   *
   *      // Default: 'warnings.privs'
   *      // Should be ui.json string key. If not provided, component assumes
   *      // a disabled state is the result of lack of appropriate privs.
   *      addNewDisabledTooltip: '@?',
   *    }
   *
   * @example
      <ui-select>
        <ui-select-choices repeat add-new="addNewTarget">
          ...
        </ui-select-choices>
      </ui-select>

      <ui-select>
        <ui-select-choices repeat
          add-new="addNewTarget"
          add-new-label="addNewThingKey"
          add-new-enable="::user.privs.PROPERTY"
          add-new-tooltip="::user.privs.PROPERTY ? undefined : 'warnings.privs'">
          ...
        </ui-select-choices>
      </ui-select>
   */
  function addNewDecorator($delegate) {
    var uiChoices = $delegate[0];
    var compile = uiChoices.compile;

    /**
     * Proxy the compileFn so we can proxy the LinkFn it returns and inject our
     * on linkFn.
     *
     * @method   compile
     * @return   {function}   Angular link Fn.
     */
    uiChoices.compile = function proxyCompileFn() {
      // Cache the existing linkFn to execute after our decorator.
      var linkFn = compile.apply(this, arguments);

      return function proxyLinkFn(scope, elem, attrs) {
        var addNewFn = scope.$eval(attrs.addNew);

        if (angular.isFunction(addNewFn)) {
          scope.addNew = true;

          // Use the custom label, or use the default.
          scope.addNewLabel = attrs.addNewLabel || 'placeholderAddNew';

          // Allow for privileges-based disabling
          scope.addNewEnable = !attrs.hasOwnProperty('addNewEnable') ||
            scope.$eval(attrs.addNewEnable);

          // Allow a custom tooltip be added for disabled addNew option.
          scope.addNewDisabledTooltip =
            attrs.hasOwnProperty('addNewDisabledTooltip') ?
              attrs.addNewDisabledTooltip : 'warnings.privs';
        }

        /**
         * Add new click handler. Fires the defined function from the addNew
         * attribute and closes the select panel.
         *
         * @method   addNewHandler
         */
        scope.addNewHandler = function addNewHandler() {
          if (!scope.addNew || !scope.addNewEnable) { return; }

          scope.$select.close();
          addNewFn();
        };

        // update add-new-enable dynamically.
        scope.$watch(
          function watchAddNewEnable(scope) {
            return scope.$eval(attrs.addNewEnable);
          },

          function onBindingChanges (newValue, oldValue) {
            if ( newValue !== oldValue ) {
              scope.addNewEnable = newValue;
            }
          }
        );

        // Execute the default linkFn now.
        return linkFn.apply(this, arguments);
      };
    };

    return $delegate;
  }

})(angular);
