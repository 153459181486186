// COMPONENT: Sources

;(function(angular, undefined) {

  angular
    .module('C.sources')
    .controller('sourcesControllerNew', sourcesControllerFn);

  function sourcesControllerFn(_, $q, $transition$, SourceService,
    PubSourceService, SourcesUtil, NavStateService, evalAJAX, ENUM_ENV_TYPE,
    ENV_GROUPS, $scope, smoothScroll, $timeout, TenantService,
    NgMcmViewService) {

    var $ctrl = this;
    var loadSourceTimeout;

    _.assign($ctrl, {
      $onInit: onInit,
      ENUM_ENV_TYPE: ENUM_ENV_TYPE,
      downloadCohesityAgent: SourceService.downloadAgentsModal,
      filterSourceList: filterSourceList,
      filterSourceListForTenants: filterSourceListForTenants,
      getSourceEnvironmentString: getSourceEnvironmentString,
      isLogicalSizeAvailable: PubSourceService.isLogicalSizeAvailable,
      isProtectedSizeAvailable: PubSourceService.isProtectedSizeAvailable,
      registerSourceItems: NavStateService.getRegisterSourceStates(),
      sourceGroups: [],
      showBulkUpgradeOption: showBulkUpgradeOption,
      state: { filtering: false, },
      upgradeAllAgents: upgradeAllAgents,
    });

    /**
     * init function calls necessary method to get sources data
     */
    function onInit() {

      // In case the application is loaded as part of helios iframe.
      // We don't want the state transition to load the current old AJS code.
      if (NgMcmViewService.showFrame$ && NgMcmViewService.showFrame$.value) {
        return;
      }

      var delayedRefresh = $transition$.params().delayedRefresh;

      _loadSourceData();

      // Add an artificial delay for the backend to process any possible changes
      // that might be underway from some externally take action. For example,
      // if the user is redirected here from a registration page and the
      // registration is async. This time gap will hopefully  provide enough
      // time for the async operation to complete.
      // TODO: it would be better if individual sources that had a registration
      // task in progress were refreshed instead of doing this wholesale, as
      // this introduces the possiblity of bug as seen here: ENG-63367.
      if (delayedRefresh) {
        loadSourceTimeout = $timeout(_loadSourceData, 10000);
      }
    }

    /**
     * Loads and formats data need for template display. Optionally, can filter
     * according to the tenants.
     *
     * @method   _loadSourceData
     * @param    {Array}   [tenantIds]   the list of tenant ids for filtering
     */
    function _loadSourceData(tenantIds) {
      var promises = {
        getSourcesInfo: PubSourceService.getSourcesWithReconciledOwnerInfo(
          TenantService.extendWithTenantParams(
            {includeEntityPermissionInfo: true}, tenantIds || [])),
      };

      $ctrl.sourceListLoaded = false;
      $ctrl.sourceGroups.length = 0;
      $q.all(promises).then(
        function getSources(responses) {
          $ctrl.rootNodes = responses.getSourcesInfo.rootNodes || [];

          /* Removing Views for 6.0.
          $ctrl.rootNodes =
            $ctrl.rootNodes.concat(
              _formatViews(responses.getSourcesInfo.views));
          */

          $ctrl.sourceStats = {
            appCount: 0,
            sourcesCount: 0,
            stats: _.get(responses, 'getSourcesInfo.stats', {
              protectedCount: 0,
              unprotectedCount: 0,
              protectedSize: 0,
              unprotectedSize: 0,
            })
          };

          $ctrl.sourceGroups =
            SourcesUtil.groupSources($ctrl.rootNodes, 'rootNode.environment');

          Object.keys($ctrl.sourceGroups).forEach(function calGroupSize(env) {
            if (ENV_GROUPS.applicationSources.includes(env)) {
              $ctrl.sourceStats.appCount += $ctrl.sourceGroups[env].length;
            } else {
              $ctrl.sourceStats.sourcesCount += $ctrl.sourceGroups[env].length;
            }
          });

          _addContextMenuToRootNodes($ctrl.sourceGroups);
        },
        evalAJAX.errorMessage
      ).finally(
        function getSourcesDone() {
          $ctrl.sourceListLoaded = true;
          $ctrl.hasNoSource = _.isEmpty($ctrl.sourceGroups);
          var scrollToEnvironment = $transition$.params().scrollToEnvironment;

          // Handle scrolling in a timeout to allow content to be rendered, as
          // the $ctrl.sourceListLoaded boolean was *just* toggled.
          if (scrollToEnvironment) {
            $timeout(_scrollToEnvironment(scrollToEnvironment));
          }

        }
      );
    }

    /**
     * when deeplinking into sources page scroll to the correct element
     * @param    {String}    scrollToEnvironment    element to scroll to
     */
    function _scrollToEnvironment(scrollToEnvironment) {
      var scrollToElements;
      var scrollOptions;

      scrollToElements =
        angular.element.find('#' + scrollToEnvironment);

      if (scrollToElements.length) {
        scrollOptions = {
          // account for fixed header height when scrolling plus a bit
          // of breathing room
          offset: angular.element('header[main-nav]').height() + 70,
          duration: 500,
          easing: 'easeInOutQuad',
        };

        smoothScroll(scrollToElements[0], scrollOptions);
      }
    }

    /**
     * Format views to have the same data structure as the rest of our sources.
     * Allows for consistent implementation in the template.
     *
     * @method   _formatViews
     * @param    {Array}   views   array of View objects
     * @return   {Array}   array of formatted View objects
     */
    function _formatViews(views) {
      return views.map(function formatView(view) {
        return {
          rootNode: {
            name: view.view.name,
            environment: 'kView',
            id: view.view.viewId,
          },
          view: view,
          stats: view.stats,
          _environment: 'kView',
          _iconClass: 'icn-entity-view',
        };
      });
    }

    /**
     * Custom method to effeciently filter our sources list using regex.
     *
     * @method   filterSourceList
     * @param    {boolean}   query   The query
     */
    function filterSourceList(query) {
      var re = new RegExp(query, 'gi');

      var filteredNodes = $ctrl.rootNodes.filter(
        function filterNodes(source) {
          if (source.rootNode.name.match(re)) {
            return source;
          }
        });

      $ctrl.state.filtering = query ? true : false;

      _updateAfterFiltering(filteredNodes);
    }

    /**
     * Filters the sources based on the tenant filter selected.
     *
     * @method   filterSourceListForTenants
     */
    function filterSourceListForTenants() {
      _loadSourceData($ctrl.sourceTenantFilter);
    }

    /**
     * Internal function which updates the sources and sourceGroups after the
     * nodes are filtered.
     *
     * @method   _updateAfterFiltering
     * @param    {filteredNodes}   filteredNodes
     */
    function _updateAfterFiltering(filteredNodes) {
      $ctrl.sourceGroups =
        SourcesUtil.groupSources(filteredNodes, 'rootNode.environment');

      _addContextMenuToRootNodes($ctrl.sourceGroups);
      $ctrl.hasNoSource = _.isEmpty($ctrl.sourceGroups);
    }

    /**
     * Callback function for every action item.
     * Waits for backend to process the change and loads source data.
     *
     * @method   _waitAndLoadSourceData
     */
    function _waitAndLoadSourceData() {
      // binding undefined as a default argument for _loadSourceData calls
      _loadSourceData.bind(null, undefined)();

      // Add an artificial delay of 10 secs for the backend to process the
      // change before reloading the source data. This will (hopefully)
      // give us the updated source details.
      $timeout(_loadSourceData.bind(null, undefined), 10000);
    }

    /**
     * Add a _contextMenu property to each source in the provided sourceGroups.
     *
     * @method   _addContextMenuToRootNodes
     * @param   {array}   Sources to add context menu info to
     */
    function _addContextMenuToRootNodes(sourceGroups) {
      var i;
      var source;

      for (var environment in sourceGroups) {
        for (i = 0; i < sourceGroups[environment].length; i++) {
          source = sourceGroups[environment][i];
          source._contextMenu = SourcesUtil.generateContextMenu(
            source, environment, _waitAndLoadSourceData);
        }
      }
    }

    /**
     * Upgrades the all Agents
     *
     * @method   upgradeAllAgents
     * @param    {array}   sources   The array with source entity
     */
    function upgradeAllAgents(sources) {
      var upgradeAgentConfig;
      var oldVersion;
      var hasVcsSource = false;
      var agentIds = [];
      var names = [];

      sources.forEach(function eachSource(source) {
        // Filter out the sources which are upgradable and are owned by the
        // logged in user.
        if (source._agent._isUpgradable &&
          !source._isConnectionError && !source._isRegError &&
          source._owner.isSourceOwner) {
          hasVcsSource = hasVcsSource || source._isVcsCluster;
          agentIds =
            agentIds.concat(PubSourceService.getAgentsToUpgrade(source));

          names.push(source.rootNode.name);

          if (!oldVersion) {
            oldVersion = _.get(source, '_agent.version', '');
          }
        }
      });

      upgradeAgentConfig = {
        agentIds: agentIds,
        names: names,
        oldVersion: oldVersion,
        isMulipleAgentsUpgrade: names.length > 1,
        hasVcsSource: hasVcsSource
      };

      SourcesUtil.upgradeAgent(upgradeAgentConfig);
    }

     /**
     * Determine if there's any agent to upgrade
     *
     * @method   showBulkUpgradeOption
     * @param   {string, array}   entityType, sources   The entity type, The
     *                            array with source entity
     * @return   {boolean}        True if there any agent to upgrade,
     *                            otherwise False
     */
    function showBulkUpgradeOption(entityType, sources) {
      // Exit early for non Physical entities
      if (entityType !== 'kPhysical') {
        return false;
      }

      // Checks if any source is owned by logged in the user and is also
      // upgradable.
      return _.reduce(sources, function reduceSources(acc, source) {
        return acc || (source._agent._isUpgradable &&
          source._owner.isSourceOwner);
      }, false);
    }

    /**
     * Gets the correct environment string for a given source.
     * Some environments use special UI only display strings (Azure (Gov)).
     * If an environment is underscore prefixed, it means its a UI only string.
     * For sending the correct string to the backend, we need to look for the
     * correct env string in the source object.
     *
     * @method getSourceEnvironmentString
     *
     * @param {String}  envString  The UI dispaly string which has to be checked
     * @param {Object}  source     Source object in which environment is
     *                             to be searched
     *
     * @return
     */
    function getSourceEnvironmentString(envString, source) {
      return (
        _.indexOf(envString, '_') === 0 ? source._environment : envString);
    }

    // clean up on $scope destroy. removes timeout if active
    $scope.$on('$destroy', function sourceScopeDestroy() {
      $timeout.cancel(loadSourceTimeout);
    });

  }
})(angular);
