// COMPONENT:  Cloud Retrieval: Search Controller

;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.cloud-retrieval';

  angular
    .module(moduleName)
    .component('cloudRetrievalSearch', {
      controller: CloudRetrievalSearchControllerFn,
      templateUrl: 'app/protection/cloud-retrieval/cloud-retrival-search.html',
    });


  /**
   * CloudRetrievalSearch Controller
   **************************************************************************/
  function CloudRetrievalSearchControllerFn(
    $state, $stateParams, $q, $timeout, cMessage, evalAJAX,
    ExternalTargetService, moment, SearchService, RestoreService,
    DateRangerService) {

    var ctrl = this;

    angular.extend(ctrl, {
      // General Vars
      cloudSearchForm: {},
      isBulkRetrievalSupported: false,
      encryptionKeyFiles: [],
      dateRange: getDefaultDateRange(),
      externalSearchRequest: {
        // clusterMatchString: undefined,
        // encryptionKeys: [
        //   {
        //      key: string,
        //      keyUid: {},
        //      vaultId: int,
        //      vaultName: string,
        //   }
        // ],
        // endTimeUsecs: undefined,
        // jobMatchString: undefined,
        searchJobName: RestoreService.getDefaultTaskName('cloud', 'search'),
        // startTimeUsecs: undefined,
        // vaultId: undefined,
      },

      // Scope Methods
      // selectTargetOrRegisterNew: selectTargetOrRegisterNew,
      onTargetSelect: onTargetSelect,
      submitSearch: submitSearch,
      updateSearchRange: updateSearchRange,
      filesSelected: filesSelected,
    });


    // METHODS
    /**
     * Activate this Controller!
     *
     * @method   activate
     */
    function activate() {

      // Initialize the search dates with the configured default.
      updateSearchRange(getDefaultDateRange());

      // Set up a seeded search (re-searching an existing search).
      if ($stateParams.queryParams) {
        bootstrapSearch($stateParams.queryParams);
      }
    }

    /**
     * Convert stateParams to prepopulated search (for use when re-doing a
     * previously submitted search).
     *
     * @method   bootstrapSearch
     * @param    {object}   params   The parameters
     */
    function bootstrapSearch(params) {
      angular.extend(ctrl.externalSearchRequest, {
        clusterMatchString: params.clusterMatchString,
        endTimeUsecs: params.endTimeUsecs,
        jobMatchString: params.jobMatchString,
        startTimeUsecs: params.startTimeUsecs,
        vaultId: params.vaultId,
      });
    }

    /**
     * Generates a default date range object compatible with cDateRanger. It's
     * aware of stateParams in case an existing search is passed in to re-search
     * with the same parameters.
     *
     * @method   getDefaultDateRange
     * @return   {object}   The default date range.
     */
    function getDefaultDateRange() {
      var endDate = $stateParams.queryParams ?
        moment($stateParams.queryParams.endTimeUsecs / 1000) :
        moment().endOf('day');
      var startDate = $stateParams.queryParams ?
        moment($stateParams.queryParams.startTimeUsecs / 1000) :
        moment().subtract(1, 'year').startOf('day');

      return angular.merge(
        DateRangerService.getDateRangerValues(),
        {
          endDate: endDate.toDate(),
          range: 'custom',
          startDate: startDate.toDate(),
        }
      );
    }

    /**
     * Handles encryption key files when selected.
     *
     * @method     filesSelected
     * @param      {array}   files   List of selected files.
     */
    function filesSelected(files) {
      ctrl.encryptionKeyFiles = files;
    }

    /**
     * Callback for selection of external target source
     *
     * @methos   onTargetSelect
     */
    function onTargetSelect(selectedTarget) {
      ctrl.externalSearchRequest.vaultId = selectedTarget.id;

      ExternalTargetService.isBulkRetrievalSupported(selectedTarget.id)
        .then(function supportCallSuccess(isSupported) {
          ctrl.isBulkRetrievalSupported = isSupported;
        });
    }

    /**
     * Uploads key files.
     *
     * @method   uploadKeys
     * @return   {object}   Promise to resolve with an empty response.
     */
    function uploadKeys() {
      var deferred = $q.defer();

      if (!ctrl.encryptionKeyFiles || !ctrl.encryptionKeyFiles.length) {
        // Return a dummy promise if there are no files to upload
        return $q.resolve(undefined);
      }

      ctrl.submitting = true;

      // otherwise return the real promise.
      ExternalTargetService
        .uploadEncryptionKeys(ctrl.externalSearchRequest.vaultId, ctrl.encryptionKeyFiles)
        .then(
          function filesUploaded(resp) {
            $timeout(function delay2() {
              deferred.resolve(resp);
            }, 1000);
          },
          function filesUploadError(resp) {
            evalAJAX.errorMessage(resp);
            $timeout(function delay3() {
              ctrl.submitting = false;
              deferred.reject(resp);
            }, 1000);
          }
        );

      return deferred.promise;
    }

    /**
     * Submit the search task.
     *
     * @method    submitSearch
     * @param     {object}   form   The ngForm object form the scope.
     */
    function submitSearch(form) {
      // Because Icebox can take several seconds to create a new Search Job
      // record, we delay transitioning post-submit to that detail view to allow
      // that to be populated with base data.
      var successDelay = 5000;

      if (!form || !form.$valid) {
        return;
      }

      ctrl.submitting = true;

      return uploadKeys().then(function keysUploaded() {
        return SearchService.submitRemoteSearch(ctrl.externalSearchRequest).then(
          function submitSuccess(resp) {
            var modifiedResp = angular.extend({}, resp, {
              taskId: resp.id,
            });

            cMessage.success({
              textKey: 'cloudRetrievalSearch.postSubmitSuccessMessage',
            });

            $timeout(function delay1() {
              $state.go('cloud-retrieval-search-details', modifiedResp);
            }, successDelay);

          },
          evalAJAX.errorMessage
        );
      });
    }

    /**
     * Convenience method to set the loading boolean true.
     *
     * @method    loadingStarted
     * @param     {*}   [resp]   Any promise response.
     * @returns   {*}   The promise response passed directly through.
     */
    function loadingStarted(resp) {
      ctrl.loading = true;
      return resp;
    }

    /**
     * Convenience method to set the loading boolean false.
     *
     * @method    loadingCompleted
     * @param     {*}   [resp]   Any promise response.
     * @returns   {*}   The promise response passed directly through.
     */
    function loadingCompleted(resp) {
      ctrl.loading = false;
      return resp;
    }

    /**
     * Update the search dates range when the user changes it.
     *
     * @method     updateSearchRange
     * @param      {object}  range   cDateRanger dates object.
     */
    function updateSearchRange(range) {
      if (!(range && range.startDate && range.endDate)) {
        return;
      }

      ctrl.externalSearchRequest.startTimeUsecs = range.startDate * 1000;
      ctrl.externalSearchRequest.endTimeUsecs = range.endDate * 1000;
    }

    // Activate!
    activate();
  }

})(angular);
