import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ProtectedObjectInfo, RunNowActionObjectLevelParams } from '@cohesity/api/v2';
import { AjaxHandlerService } from '@cohesity/utils';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ProtectionPolicyService } from 'src/app/shared/policy';

/**
 * Run Now object dialog actions.
 */
export enum ObjectRunNowAction {
  Cancel = 'cancel',
  FullBackup = 'fullBackup',
  Incremental = 'incremental',
  Log = 'log',
}

/**
 * Map of run now action and back up type.
 */
export const ObjectRunNowActionTypeMap: Partial<
  Record<ObjectRunNowAction, RunNowActionObjectLevelParams['backupType']>
> = {
  [ObjectRunNowAction.FullBackup]: 'kFull',
  [ObjectRunNowAction.Incremental]: 'kRegular',
  [ObjectRunNowAction.Log]: 'kLog',
};

/**
 * @description
 *
 * Run Now object modal dialog.
 */
@Component({
  selector: 'coh-object-run-now-dialog',
  templateUrl: './object-run-now-dialog.component.html',
  styleUrls: ['./object-run-now-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectRunNowDialogComponent implements OnInit {

  /**
   * ObjectRunNowAction reference for use inside of template.
   */
  ObjectRunNowAction = ObjectRunNowAction;

  /**
   * Whether to allow log backup action in the run now dialog.
   */
  allowLogBackup = false;

  ngOnInit() {
    const policyId = this.data.selectedObjects[0].objectBackupConfiguration.policyId;
    const environment = this.data?.selectedObjects[0].environment;

    if (['kSQL', 'kOracle'].includes(environment)) {
      this.allowLogBackup = true;
    } else if (environment === 'kUDA') {
      this.protectionPolicyService.getPolicyById(policyId).pipe(
        catchError(error => {
          this.ajaxHandlerService.handler(error);
          return throwError(error);
        }),
        finalize(() => this.cdr.detectChanges())
      ).subscribe(policy => {
        if (policy.backupPolicy?.log) {
          this.allowLogBackup = true;
        }
      });
    }
  }

  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) readonly data: {
      runningObjects: ProtectedObjectInfo[];
      selectedObjects: ProtectedObjectInfo[];
    },
    private protectionPolicyService: ProtectionPolicyService,
  ) {}
}
