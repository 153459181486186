import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { DataTreeNodeContext, DataTreeNodeDetail } from '@cohesity/helix';
import { HealthChecksPopoverComponent } from 'src/app/shared/source-health-checks';

import { SqlSourceDataNode } from '../sql-source-data-node';


/**
 * Details view for a SQL-source based node. This renders a protected/partial protected icon and the node's name.
 *
 * @example
 * Manually added:
 * <cog-data-tree ...>
 *   <ng-container *cogDataTreeDetail="let ctx">
 *     <coh-sql-source-details [nodeContext]="ctx"></coh-sql-source-details>
 *   </ng-container>
 * </cog-data-tree>
 *
 * Dyanmically Added:
 * <cog-data-tree ...>
 *   <ng-container *cogDataTreeDetail="let ctx">
 *     <cog-data-tree-detail-outlet [component]="treeService.detailComponent" [nodeContext]="ctx">
 *     </cog-data-tree-detail-outlet>
 *   </ng-container>
 * </cog-data-tree>
 */
@Component({
  selector: 'coh-sql-source-details',
  templateUrl: './sql-source-details.component.html',
  styleUrls: [ './sql-source-details.component.scss' ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SqlSourceDetailsComponent implements DataTreeNodeDetail<SqlSourceDataNode> {
  /**
   * The component to show in the health checks popover.
   */
  healthChecksComponent = HealthChecksPopoverComponent;

  /**
   * The node context, including info about the node and it's selection status.
   */
  @Input() nodeContext: DataTreeNodeContext<SqlSourceDataNode>;

  /**
   * Access to the current node property.
   */
  get node(): SqlSourceDataNode {
    return this.nodeContext && this.nodeContext.node;
  }

  /**
   * Adds a class when health checks are detected, as well as provides a getter for determining the same.
   */
  @HostBinding('class.health-checks-problem')
  get hasHealthChecksProblem(): boolean {
    return this.node.hasHealthCheckProblems;
  }
}
