/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SchedulerProto } from '../models/scheduler-proto';
import { SchedulerProto_SchedulerJob } from '../models/scheduler-proto-_scheduler-job';
import { SchedulerProto_SchedulerJob_ScheduleJobParameters_ReportJobParameter } from '../models/scheduler-proto-_scheduler-job-_schedule-job-parameters-_report-job-parameter';
@Injectable({
  providedIn: 'root',
})
class SchedulerService extends __BaseService {
  static readonly GetSchedulerJobsPath = '/public/scheduler';
  static readonly UpdateSchedulerJobPath = '/public/scheduler';
  static readonly CreateSchedulerJobPath = '/public/scheduler';
  static readonly DeleteSchedulerJobsPath = '/public/scheduler';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List the email report schedule jobs that are scheduled to run.
   *
   * Returns all the email report scheduler jobs that are scheduled to run.
   * An email report scheduler job generates a report with the specified parameters
   * and sends that report to the specified email accounts according to the defined
   * schedule.
   * @param params The `SchedulerService.GetSchedulerJobsParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `ids`: Specifies ids of scheduler jobs to fetch.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetSchedulerJobsResponse(params: SchedulerService.GetSchedulerJobsParams): __Observable<__StrictHttpResponse<SchedulerProto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/scheduler`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SchedulerProto>;
      })
    );
  }
  /**
   * List the email report schedule jobs that are scheduled to run.
   *
   * Returns all the email report scheduler jobs that are scheduled to run.
   * An email report scheduler job generates a report with the specified parameters
   * and sends that report to the specified email accounts according to the defined
   * schedule.
   * @param params The `SchedulerService.GetSchedulerJobsParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `ids`: Specifies ids of scheduler jobs to fetch.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetSchedulerJobs(params: SchedulerService.GetSchedulerJobsParams): __Observable<SchedulerProto> {
    return this.GetSchedulerJobsResponse(params).pipe(
      __map(_r => _r.body as SchedulerProto)
    );
  }

  /**
   * Update an existing email report schedule job.
   *
   * Returns the updated email report scheduler job.
   * @param params The `SchedulerService.UpdateSchedulerJobParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Update Job Parameter.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateSchedulerJobResponse(params: SchedulerService.UpdateSchedulerJobParams): __Observable<__StrictHttpResponse<SchedulerProto_SchedulerJob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/scheduler`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SchedulerProto_SchedulerJob>;
      })
    );
  }
  /**
   * Update an existing email report schedule job.
   *
   * Returns the updated email report scheduler job.
   * @param params The `SchedulerService.UpdateSchedulerJobParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Update Job Parameter.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateSchedulerJob(params: SchedulerService.UpdateSchedulerJobParams): __Observable<SchedulerProto_SchedulerJob> {
    return this.UpdateSchedulerJobResponse(params).pipe(
      __map(_r => _r.body as SchedulerProto_SchedulerJob)
    );
  }

  /**
   * Create an email report scheduler job.
   *
   * Returns the created email report scheduler job.
   * An email report scheduler job generates a report with the specified parameters
   * and sends that report to the specified email accounts according to the defined
   * schedule.
   * This operation may also be used to send a report once (with no schedule),
   * by setting the EnableRecurring field to false.
   * @param params The `SchedulerService.CreateSchedulerJobParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success or Success
   */
  CreateSchedulerJobResponse(params: SchedulerService.CreateSchedulerJobParams): __Observable<__StrictHttpResponse<SchedulerProto_SchedulerJob_ScheduleJobParameters_ReportJobParameter | SchedulerProto_SchedulerJob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/scheduler`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SchedulerProto_SchedulerJob_ScheduleJobParameters_ReportJobParameter | SchedulerProto_SchedulerJob>;
      })
    );
  }
  /**
   * Create an email report scheduler job.
   *
   * Returns the created email report scheduler job.
   * An email report scheduler job generates a report with the specified parameters
   * and sends that report to the specified email accounts according to the defined
   * schedule.
   * This operation may also be used to send a report once (with no schedule),
   * by setting the EnableRecurring field to false.
   * @param params The `SchedulerService.CreateSchedulerJobParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success or Success
   */
  CreateSchedulerJob(params: SchedulerService.CreateSchedulerJobParams): __Observable<SchedulerProto_SchedulerJob_ScheduleJobParameters_ReportJobParameter | SchedulerProto_SchedulerJob> {
    return this.CreateSchedulerJobResponse(params).pipe(
      __map(_r => _r.body as SchedulerProto_SchedulerJob_ScheduleJobParameters_ReportJobParameter | SchedulerProto_SchedulerJob)
    );
  }

  /**
   * Delete one or more email report schedule jobs.
   *
   * Specify a list of email report schedule job ids to unschedule and delete.
   * @param params The `SchedulerService.DeleteSchedulerJobsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `ids`: Array of ids
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteSchedulerJobsResponse(params: SchedulerService.DeleteSchedulerJobsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.ids;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/scheduler`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete one or more email report schedule jobs.
   *
   * Specify a list of email report schedule job ids to unschedule and delete.
   * @param params The `SchedulerService.DeleteSchedulerJobsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `ids`: Array of ids
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteSchedulerJobs(params: SchedulerService.DeleteSchedulerJobsParams): __Observable<null> {
    return this.DeleteSchedulerJobsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module SchedulerService {

  /**
   * Parameters for GetSchedulerJobs
   */
  export interface GetSchedulerJobsParams {

    /**
     * TenantIds contains ids of the tenants for which objects are to be
     * returned.
     */
    tenantIds?: Array<string>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies ids of scheduler jobs to fetch.
     */
    ids?: Array<number>;

    /**
     * AllUnderHierarchy specifies if objects of all the tenants under the
     * hierarchy of the logged in user's organization should be returned.
     */
    allUnderHierarchy?: boolean;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateSchedulerJob
   */
  export interface UpdateSchedulerJobParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Update Job Parameter.
     */
    body?: SchedulerProto_SchedulerJob;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateSchedulerJob
   */
  export interface CreateSchedulerJobParams {
    body: SchedulerProto_SchedulerJob;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteSchedulerJobs
   */
  export interface DeleteSchedulerJobsParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Array of ids
     */
    ids?: Array<number>;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { SchedulerService }
