// Directive, Module & Controller: cToggle

;(function(angular, undefined) {
  'use strict';

  var modName = 'C.toggle';
  var modDeps = [];
  var componentName = 'cToggle';
  var options = {
    bindings: {
      falseValue: '<?',
      trueValue: '<?',
      ngDisabled: '<?',
    },
    require: {
      ngModelCtrl: 'ngModel',
    },
    transclude: true,
    controller: cToggleControllerFn,
    templateUrl: 'app/global/c-toggle/c-toggle.html',
  };

  angular
    .module(modName, modDeps)
    .component(componentName, options);

  /**
   * @ngdoc component
   * @name C.toggle:cToggle
   * @function
   *
   * @description
   * Toggle switch (checkbox) component
   * You can provide true-value and false-value. Please note that it will be
   * evaluated. If you want to provide the string add single quotes around it.
   *
   * @example
     <c-toggle ng-model="configObj.isTurnedOn">
       Label content here
     </c-toggle>
  */
  function cToggleControllerFn() {

    var $ctrl = this;
    var trueValue;
    var falseValue;

    /**
     * component initialization function
     *
     * @method   $onInit
     */
    $ctrl.$onInit = function $onInit() {

      // Determine true/false values, defaulting to true/false. This approach
      // allows for inversion of true/false values, or any type of value for
      // that matter.
      trueValue = $ctrl.hasOwnProperty('trueValue') ?
        $ctrl.trueValue : true;
      falseValue = $ctrl.hasOwnProperty('falseValue') ?
        $ctrl.falseValue : false;

      // Add a formatter function to keep internal $ctrl.toggleValue in sync
      // with any external (programatic) changes made to the model.
      $ctrl.ngModelCtrl.$formatters.push(
        function outsideModelSyncFn(modelValue) {
          $ctrl.toggleValue = angular.equals(modelValue, trueValue);
          return $ctrl.toggleValue;
        }
      );

      // Add a parser which will convert the internal true/false value into the
      // true/false binding values (or true/false if binding isn't provided).
      $ctrl.ngModelCtrl.$parsers.push(function outsideModelParseFn(modelValue) {
        return modelValue ? trueValue : falseValue;
      });

    };
  }

})(angular);
