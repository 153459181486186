import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatStepperModule } from '@angular/material/stepper';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { CohesitySharedFormsModule } from '@cohesity/shared-forms';
import { TranslateModule } from '@ngx-translate/core';

import { PipesModule } from '../pipes';
import {
  CertificateAuthorityInterfaceComponent,
} from './certificate-authority/certificate-authority-interface/certificate-authority-interface.component';
import {
  CertificateAuthoritySettingsDialogComponent,
} from './certificate-authority/certificate-authority-settings-dialog/certificate-authority-settings-dialog.component';
import { DownloadAgentsComponent } from './download-agents/download-agents.component';
import { DownloadCliDialogComponent } from './download-cli/download-cli-dialog.component';
import {
  GenerateCertificateSigninRequestDialogComponent,
} from './generate-certificate-signin-request/generate-certificate-signin-request-dialog/generate-certificate-signin-request-dialog.component';
import {
  GenerateCertificateSigninRequestInterfaceComponent,
} from './generate-certificate-signin-request/generate-certificate-signin-request-interface/generate-certificate-signin-request-interface.component';
import {
  GenerateCohesityCertificateDialogComponent,
} from './generate-cohesity-certificate/generate-cohesity-certificate-dialog/generate-cohesity-certificate-dialog.component';
import {
  GenerateCohesityCertificateInterfaceComponent,
} from './generate-cohesity-certificate/generate-cohesity-certificate-interface/generate-cohesity-certificate-interface.component';
import { HeliosDefaultLandingAppComponent } from './helios-default-landing-app/helios-default-landing-app.component';
import {
  ImportExternalCertificateDialogComponent,
} from './import-external-certificate/import-external-certificate-dialog/import-external-certificate-dialog.component';
import {
  SettingsImportExternalCertificateInterfaceComponent,
} from './import-external-certificate/settings-import-external-certificate-interface/settings-import-external-certificate-interface.component';
import {
  UploadImportExternalCertificateInterfaceComponent,
} from './import-external-certificate/upload-import-external-certificate-interface/upload-import-external-certificate-interface.component';
import { SimpleDialogComponent } from './simple-dialog/simple-dialog.component';
import { TimelineStatisticsComponent } from './timeline-dialog/statistics/statistics.component';
import { TimelineDialogComponent } from './timeline-dialog/timeline-dialog.component';
import { HeliosTenantMigrationDialogComponent } from './helios-tenant-migration/helios-tenant-migration-dialog.component';

const COMPONENTS = [
  DownloadAgentsComponent,
  DownloadCliDialogComponent,
  SimpleDialogComponent,
  TimelineDialogComponent,
  TimelineStatisticsComponent,
  CertificateAuthoritySettingsDialogComponent,
  CertificateAuthorityInterfaceComponent,
  GenerateCertificateSigninRequestDialogComponent,
  GenerateCertificateSigninRequestInterfaceComponent,
  GenerateCohesityCertificateDialogComponent,
  GenerateCohesityCertificateInterfaceComponent,
  ImportExternalCertificateDialogComponent,
  UploadImportExternalCertificateInterfaceComponent,
  SettingsImportExternalCertificateInterfaceComponent,
  HeliosDefaultLandingAppComponent,
  HeliosTenantMigrationDialogComponent,
];

/**
 * Module for reusable dialogs.
 */
@NgModule({
  imports: [
    CohesityHelixModule,
    CommonModule,
    FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    PipesModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    MatButtonModule,
    MatStepperModule,
    MatDialogModule,
    TranslateModule.forChild(),
    CohesitySharedFormsModule,
  ],
  declarations: COMPONENTS,
  exports: [...COMPONENTS],
})
export class DialogsModule { }
