import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { filter, startWith, tap } from 'rxjs/operators';
import { crashConsistentBackupEnvs, Environment } from 'src/app/shared/constants';

import {
  BaseProtectionBuilderComponent,
} from '../../../../protection-builder/components/base-protection-builder/base-protection-builder.component';
import { SourceTreeState } from '../../../../protection-builder/components/form-controls';
import { ProtectionItemName } from '../../../../protection-builder/models';

interface AppConsistentBackup {
  appConsistentBackups: boolean;
  crashConsistentBackup: boolean;
  vssBackupType?: boolean;
}

/**
 * Default value for app consistent backup.
 */
export const DefaultAppConsistentBackup = {
  appConsistentBackups: false,
  crashConsistentBackup: true,
};

@Component({
  selector: 'coh-settings-list-app-consistent-backups',
  templateUrl: './settings-list-app-consistent-backups.component.html',
})
export class SettingsListAppConsistentBackupsComponent
  extends BaseProtectionBuilderComponent<AppConsistentBackup, any> {
  /**
   * Default app consistent backups value.
   */
  _value: AppConsistentBackup = DefaultAppConsistentBackup;

  constructor(
    private irisContextService: IrisContextService,
  ) {
    super();
  }

  /**
   * Form group for app consistent backup.
   */
  appConsistentBackupsFormGroup: UntypedFormGroup = new UntypedFormGroup({
    appConsistentBackups: new UntypedFormControl(false, Validators.required),

    // By default have the crashConsistentBackup control which will be
    // removed based on the environment of the source.
    crashConsistentBackup: new UntypedFormControl(true, Validators.required),
  });

  /**
   * Store environment of the current protection group.
   */
  environment: Environment;

  /**
   * Name of objects which do not have vmware tools installed.
   */
  missingVmwareToolsObjects: Set<string> = new Set();

  /**
   * Name of objects which do not have Nutanix Guest Tool installed.
   */
  missingNgtObjects: Set<string> = new Set();

  /**
   * Indicates if user is in DMaaS Scope or not.
   */
  get isDmsContext(): boolean {
    return isDmsScope(this.irisContextService.irisContext);
  }

  /**
   * Get the value of app consistent backups control.
   */
  get appConsistentBackupsValue(): boolean {
    return this.appConsistentBackupsFormGroup.get('appConsistentBackups').value;
  }

  /**
   * Whether to show crash consistent backup option. This option is only shown
   * if app consistent backup is turned on and the showCrashConsistentBackup$
   * observable property is set true from parent.
   *
   * @return   Boolean to show crash consistent backup.
   */
  get showCrashConsistentBackup(): boolean {
    return this.appConsistentBackupsValue &&
      Boolean(this.appConsistentBackupsFormGroup.get('crashConsistentBackup'));
  }

  /**
   * Get the value of crash consistent backup control.
   */
  get crashConsistentBackupValue(): boolean {
    return this.appConsistentBackupsValue && this.showCrashConsistentBackup
      && this.appConsistentBackupsFormGroup.get('crashConsistentBackup').value;
  }


  /**
   * Whether to show vss backup type option. This option is only shown
   * if app consistent backup is turned on.
   *
   * @return   Boolean to show vss backup type.
   */
  get showVssBackupType(): boolean {
    return this.appConsistentBackupsValue &&
      Boolean(this.appConsistentBackupsFormGroup.get('vssBackupType'));
  }

  /**
   * Get the value of vss backup type control.
   */
  get vssBackupTypeValue(): boolean {
    return this.appConsistentBackupsValue && this.showVssBackupType &&
      this.appConsistentBackupsFormGroup.get('vssBackupType').value;
  }

  /**
   * Add the app consistent form group as control.
   */
  addFormControl() {
    this.formGroup.addControl(this.name, this.appConsistentBackupsFormGroup);
  }

  /**
   * Function to set value of crash consistent backup to true.
   */
  enableCrashConsistent() {
    if (this.appConsistentBackupsValue && this.showCrashConsistentBackup) {
      this.appConsistentBackupsFormGroup.get('crashConsistentBackup').setValue(true);
    }
  }

  /**
   * Form control init.
   */
  initFormControl() {
    const sourceFormControl = this.formGroup.parent.get(ProtectionItemName.Source);
    const objectsFormControl = this.formGroup.parent.get(ProtectionItemName.Objects);

    if (sourceFormControl) {
      sourceFormControl.valueChanges.pipe(
        this.untilDestroy(),
        startWith(sourceFormControl.value),
        filter(value => !!value)
      ).subscribe(value => {
        this.environment = value.protectionSource.environment;

        if (crashConsistentBackupEnvs.includes(this.environment)) {
          // If the current source environment supports having a crash
          // consistent backup environment, add the form control.
          this.appConsistentBackupsFormGroup.addControl(
            'crashConsistentBackup',
            new UntypedFormControl(this.value.crashConsistentBackup, Validators.required)
          );
        } else {
          // Otherwise remove the form control
          this.appConsistentBackupsFormGroup.removeControl(
            'crashConsistentBackup'
          );
        }

        // Show Vss back up type control only if environment is HyperV
        if (this.environment === Environment.kHyperV && this.isDmsContext) {
          this.appConsistentBackupsFormGroup.addControl(
            'vssBackupType',
            new UntypedFormControl(this.value.vssBackupType)
          );
        } else {
          // Otherwise remove the form control
          this.appConsistentBackupsFormGroup.removeControl(
            'vssBackupType'
          );
        }
      });
    }

    if (objectsFormControl) {
      objectsFormControl.valueChanges.pipe(
        this.untilDestroy(),
        startWith(objectsFormControl.value),
        filter(value => Boolean(value)),
        tap(() => {
          this.missingVmwareToolsObjects.clear();
          this.missingNgtObjects.clear();
        })
      ).subscribe((value: SourceTreeState) => {
        if (this.environment === Environment.kVMware || this.environment === Environment.kAcropolis) {
          // If app consistent backup (quiesce) is turned on for VMware, iterate
          // through the selected objects in the source tree and and collect the
          // names of objects where VMware tools is not installed. When VMware
          // tools is installed on a VM, the quiesce will be a no op.
          let allProtectedVms = [];

          (value.selection.sourceIds || []).forEach(nodeId => {
            const node = value.sourceTree.treeService.treeControl?.getNode(nodeId);

            if (!node) {
              return;
            }

            if (Array.isArray(node.data.nodes)) {
              // If the selected source node id is a folder/vCenter which is auto
              // protected, fetch all child nodes nested under it.
              allProtectedVms.push(...this.getChildNodes(node.data));
            } else {
              allProtectedVms.push(node.data);
            }
          });

          // Filter out excluded objects and nodes that are not of type kVirtualMachine,
          // toolsRunningStatus and ngtCapabilities are only applicable for VMs.
          allProtectedVms = allProtectedVms.filter(
            node => node.protectionSource.vmWareProtectionSource?.type === 'kVirtualMachine' &&
            !(value.selection.excludeSourceIds || []).includes(node.protectionSource.id));

          if (this.environment === Environment.kVMware) {
            for (const protectedNodeData of allProtectedVms) {
              const {
                name,
                vmWareProtectionSource: {toolsRunningStatus = 'kUnknown'} = {},
              } = protectedNodeData.protectionSource;

              if (['kUnknown', 'kGuestToolsNotRunning'].includes(toolsRunningStatus)) {
                // VMware tools exist if tools running status is
                // kGuestToolsExecutingScripts or kGuestToolsRunning.
                this.missingVmwareToolsObjects.add(name);
              }
            }
          } else if (this.environment === Environment.kAcropolis) {
            for (const protectedNodeData of allProtectedVms) {
              const {
                name,
                acropolisProtectionSource: {ngtReachable = false, ngtCapabilities = []} = {},
              } = protectedNodeData.protectionSource;

              if (!ngtReachable || !ngtCapabilities.includes('kVssSnapshot')) {
                // Nutanix Guest Tool exist if tools is reachable.
                this.missingNgtObjects.add(name);
              }
            }
          }
        } else {
          // Do not detect presence of VMware tools if not vmware not AHV environment.
          this.missingVmwareToolsObjects.clear();
          this.missingNgtObjects.clear();
        }
      });
    }

    if (this.protectionGroup?.id) {
      this.value = {
        appConsistentBackups: Boolean(this.protectionGroup.appConsistentBackups),
        crashConsistentBackup: Boolean(this.protectionGroup.crashConsistentBackup),
        vssBackupType: Boolean(this.protectionGroup.vssBackupType),
      };

      if (this.value.appConsistentBackups) {
        // Add the form control if it doesn't exist
        this.appConsistentBackupsFormGroup.addControl(
          'crashConsistentBackup',
          new UntypedFormControl(this.value.crashConsistentBackup, Validators.required)
        );
        this.value.crashConsistentBackup = Boolean(this.protectionGroup.crashConsistentBackup);

        if (this.isDmsContext) {
          // Add vss backup type form control if it doesn't exist
          this.appConsistentBackupsFormGroup.addControl(
            'vssBackupType',
            new UntypedFormControl(this.value.vssBackupType)
          );
          this.value.vssBackupType = Boolean(this.protectionGroup.vssBackupType);
        }

        this.formControl.setValue(this.value);
      }
    }
  }

  /**
   * Utility method to get all the child nodes under a protection source node.
   * This is used to check the selected vms if they are missing VM tools.
   *
   * @param    node   ProtectionSourceNode
   * @returns  List of all nested child nodes under the current node.
   */
  private getChildNodes(node: ProtectionSourceNode): ProtectionSourceNode[] {
    const childNodes = [];
    if (Array.isArray(node.nodes)) {
      for (const child of node.nodes) {
        childNodes.push(...this.getChildNodes(child));
      }
    } else {
      childNodes.push(node);
    }
    return childNodes;
  }
}
