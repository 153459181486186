import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Api } from '../api.constants';
import { AppItem, AppItemDetails } from '../private-models';

/**
 * Mcm AppStore service.
 */
@Injectable({
  providedIn: 'root',
})
export class McmAppStoreServiceApi {
  constructor(private http: HttpClient) {}

  /**
   * Get all apps list from app service.
   *
   * @return Apps list.
   */
  getApps(): Observable<AppItem[]> {
    return this.http.get<AppItem[]>(Api.mcm('appstore/app?list=all'));
  }

  /**
   * Get app details by id.
   *
   * @param id app id.
   * @return AppStore app details.
   */
  getAppById(id: string): Observable<AppItemDetails> {
    return this.http.get<AppItemDetails>(Api.mcm(`appstore/app/${id}`));
  }
}
