/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PrincipalList } from '../models/principal-list';
import { Principal } from '../models/principal';
import { PrincipalParams } from '../models/principal-params';
import { Success } from '../models/success';
@Injectable({
  providedIn: 'root',
})
class HeliosPrincipalsService extends __BaseService {
  static readonly GetPrincipalsPath = '/mcm/principals';
  static readonly CreatePrincipalPath = '/mcm/principals';
  static readonly GetPrincipalByIdPath = '/mcm/principals/{sid}';
  static readonly UpdatePrincipalPath = '/mcm/principals/{sid}';
  static readonly DeletePrincipalPath = '/mcm/principals/{sid}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get all principals on helios.
   *
   * Get all principals on helios. Note: This api is only supported in Multi-Cluster Manager (MCM).
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetPrincipalsResponse(regionId?: string): __Observable<__StrictHttpResponse<PrincipalList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/principals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PrincipalList>;
      })
    );
  }
  /**
   * Get all principals on helios.
   *
   * Get all principals on helios. Note: This api is only supported in Multi-Cluster Manager (MCM).
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetPrincipals(regionId?: string): __Observable<PrincipalList> {
    return this.GetPrincipalsResponse(regionId).pipe(
      __map(_r => _r.body as PrincipalList)
    );
  }

  /**
   * Create a principal on helios.
   *
   * Create a principal on helios. Note: This api is only supported in Multi-Cluster Manager (MCM).
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param body Request body params to create a principal
   * @return Success
   */
  CreatePrincipalResponse(regionId?: string,
    body?: PrincipalParams): __Observable<__StrictHttpResponse<Principal>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/principals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Principal>;
      })
    );
  }
  /**
   * Create a principal on helios.
   *
   * Create a principal on helios. Note: This api is only supported in Multi-Cluster Manager (MCM).
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param body Request body params to create a principal
   * @return Success
   */
  CreatePrincipal(regionId?: string,
    body?: PrincipalParams): __Observable<Principal> {
    return this.CreatePrincipalResponse(regionId, body).pipe(
      __map(_r => _r.body as Principal)
    );
  }

  /**
   * Get a specified principal details.
   *
   * Get a specified principal details. Note: This api is only supported in Multi-Cluster Manager (MCM).
   * @param sid Specifies the SID of the Principal.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetPrincipalByIdResponse(sid: string,
    regionId?: string): __Observable<__StrictHttpResponse<Principal>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/principals/${encodeURIComponent(sid)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Principal>;
      })
    );
  }
  /**
   * Get a specified principal details.
   *
   * Get a specified principal details. Note: This api is only supported in Multi-Cluster Manager (MCM).
   * @param sid Specifies the SID of the Principal.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetPrincipalById(sid: string,
    regionId?: string): __Observable<Principal> {
    return this.GetPrincipalByIdResponse(sid, regionId).pipe(
      __map(_r => _r.body as Principal)
    );
  }

  /**
   * Update a specified principal.
   *
   * Update a specified principal. Note: This api is only supported in Multi-Cluster Manager (MCM).
   * @param params The `HeliosPrincipalsService.UpdatePrincipalParams` containing the following parameters:
   *
   * - `sid`: Specifies the SID of the Principal.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `body`: Request body params to update the principal
   *
   * @return Success
   */
  UpdatePrincipalResponse(params: HeliosPrincipalsService.UpdatePrincipalParams): __Observable<__StrictHttpResponse<Principal>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/mcm/principals/${encodeURIComponent(params.sid)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Principal>;
      })
    );
  }
  /**
   * Update a specified principal.
   *
   * Update a specified principal. Note: This api is only supported in Multi-Cluster Manager (MCM).
   * @param params The `HeliosPrincipalsService.UpdatePrincipalParams` containing the following parameters:
   *
   * - `sid`: Specifies the SID of the Principal.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `body`: Request body params to update the principal
   *
   * @return Success
   */
  UpdatePrincipal(params: HeliosPrincipalsService.UpdatePrincipalParams): __Observable<Principal> {
    return this.UpdatePrincipalResponse(params).pipe(
      __map(_r => _r.body as Principal)
    );
  }

  /**
   * Delete a specified principal.
   *
   * Delete a specified principal. Note: This api is only supported in Multi-Cluster Manager (MCM).
   * @param sid Specifies the SID of the Principal.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  DeletePrincipalResponse(sid: string,
    regionId?: string): __Observable<__StrictHttpResponse<Success>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/mcm/principals/${encodeURIComponent(sid)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Success>;
      })
    );
  }
  /**
   * Delete a specified principal.
   *
   * Delete a specified principal. Note: This api is only supported in Multi-Cluster Manager (MCM).
   * @param sid Specifies the SID of the Principal.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  DeletePrincipal(sid: string,
    regionId?: string): __Observable<Success> {
    return this.DeletePrincipalResponse(sid, regionId).pipe(
      __map(_r => _r.body as Success)
    );
  }
}

module HeliosPrincipalsService {

  /**
   * Parameters for UpdatePrincipal
   */
  export interface UpdatePrincipalParams {

    /**
     * Specifies the SID of the Principal.
     */
    sid: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Request body params to update the principal
     */
    body?: PrincipalParams;
  }
}

export { HeliosPrincipalsService }
