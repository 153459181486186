import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { NavItem } from '@cohesity/helix';

@Component({
  selector: 'coh-split-button',
  templateUrl: './split-button.component.html',
  styleUrls: ['./split-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SplitButtonComponent implements OnChanges {
  /**
   * Color scheme for the button.
   */
  @Input() color: 'primary' | 'accent' | 'warn' = 'primary';

  /**
   * Items for the split button.
   *
   * Note: A lot of NavItem properties are unsupported.
   */
  @Input() navItems: NavItem[] = [];

  /**
   * Stored filtered nav items in this array.
   */
  filteredNavItems: NavItem[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.navItems) {
      return;
    }

    this.filteredNavItems = this.navItems?.filter(navItem => !navItem.hidden) || [];
  }
}
