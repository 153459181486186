import { Injectable } from '@angular/core';
import {
  FormFieldParams,
  FormPanelParams,
  NumberFormFieldParams,
  RadioGroupFormFieldParams,
  UdaConfigParams,
  UdaConnectorConfigServiceApi,
  UdaDynamicFormParams,
  UdaHostOsSpecificParams,
} from '@cohesity/api/v2';
import { FieldType, FormField, FormPanel, NumberConfig, RadioGroupConfig } from '@cohesity/helix';
import { Observable, } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { DynamicForm, UdaSourceTypeOSConfig } from '../models';

/**
 * This services fetches the configs using an api call and provides the configs
 * in a suitable way to be used by different UDA workflows.
 */
@Injectable({
  providedIn: 'root'
})
export class UdaConnectorConfigService {
  /**
   * Stores the values of UDA configs for all the sources.
   */
  udaConfigs$: Observable<UdaConfigParams[]>;

  /**
   * Stores the values of UDA sources types.
   */
  udaSourceTypes$: Observable<string[]>;

  /**
   * Maps UDA source type to source label.
   */
  udaSourceTypeToLabel$: Observable<Map<string, string>>;

  /**
   * Maps UDA source type to host operating system types.
   */
  udaSourceTypeToHostOsTypes$: Observable<Map<string, string[]>>;

  /**
   * Maps UDA source type and host os type to workflow configs (registration,
   * protection and recovery).
   */
  udaSourceHostOsTypeToConfigs$: Observable<Map<string, UdaSourceTypeOSConfig>>;

  constructor(private udaConnectorConfigApi: UdaConnectorConfigServiceApi) {
    this.init();
  }

  /**
   * This function invokes the http request to get UDA configs from the server.
   * Then it stores the configs in different maps to be consumed by other applications.
   */
  private init(): void {

    // Make the Http request and filter out the valid configs. Cache it to
    // prevent further network calls.
    this.udaConfigs$ = this.udaConnectorConfigApi.GetConnectorConfigs().pipe(
      map(configs => (configs ?? []).filter(
        config => !!config?.index?.sourceType
          && !!config.index?.label
          && !!config?.hostOsSpecificConfigurations
          && !!config.hostOsSpecificConfigurations.length
        )
      ), shareReplay()
    );

    this.udaSourceTypes$ = this.udaConfigs$.pipe(
      map((configs) => configs.map(config => config.index.sourceType))
    );

    this.udaSourceTypeToLabel$ = this.udaConfigs$.pipe(map((configs) => {
      const udaSourceTypeToLabel = new Map<string, string>();
      (configs ?? []).forEach(config => {
        udaSourceTypeToLabel.set(config.index.sourceType, config.index.label);
      });
      return udaSourceTypeToLabel;
    }));

    this.udaSourceTypeToHostOsTypes$ = this.udaConfigs$.pipe(map(configs => {
      const udaSourceTypeToHostOsTypes = new Map<string, string[]>();
      (configs ?? []).forEach(config => {
        const hostOsTypes = config.hostOsSpecificConfigurations
          .map(hostOsConfig => this.convertHostOsTypeToUIFormat(hostOsConfig.hostOsType));
        udaSourceTypeToHostOsTypes.set(config.index.sourceType, hostOsTypes);
      });
      return udaSourceTypeToHostOsTypes;
    }));

    this.udaSourceHostOsTypeToConfigs$ = this.udaConfigs$.pipe(map(configs => {
      const udaSourceHostOsTypeToConfigs = new Map<string, UdaSourceTypeOSConfig>();
      (configs ?? []).forEach(config => {
        config.hostOsSpecificConfigurations.forEach(hostOsConfig => {
          udaSourceHostOsTypeToConfigs.set(
            config.index.sourceType + this.convertHostOsTypeToUIFormat(hostOsConfig?.hostOsType),
            this.convertUdaWorkflowConfig(hostOsConfig)
          );
        });
      });
      return udaSourceHostOsTypeToConfigs;
    }));
  }

  /**
   * The UI uses the hostOsType as kLinux etc. But the api call sends it as
   * KLinux. This function convert the hostOsType received from api call to the
   * UI format.
   *
   * @param   hostOsType   Host os type in backend format eg. KLinux.
   * @returns Host os type in UI format eg. kLinux.
   */
  convertHostOsTypeToUIFormat(hostOsType: string): string {
    return hostOsType[0].toLocaleLowerCase() + hostOsType.substr(1);
  }

  /**
   * Converts the workflow configs ( registration, protection, recovery ) for a
   * given source type and host os type suitable for UI use.
   *
   * @param   osSpecificConfig   Host os specific configurations for UDA
   * workflows in backend format.
   * @returns Workflow configurations in the UI format.
   */
  convertUdaWorkflowConfig(osSpecificConfig: UdaHostOsSpecificParams): UdaSourceTypeOSConfig {
    return {
      index: osSpecificConfig.index,
      registrationWorkflow: {
        primaryFields: osSpecificConfig?.registration?.primaryFields,
        dynamicForm: this.convertDynamicFormParams(
          osSpecificConfig?.registration?.dynamicForm
        )
      },
      protectionWorkflow: {
        primaryFields: osSpecificConfig?.protection?.primaryFields,
        dynamicForm: this.convertDynamicFormParams(
          osSpecificConfig?.protection?.dynamicForm
        )
      },
      recoveryWorkflow: {
        primaryFields: osSpecificConfig?.recovery?.primaryFields,
        dynamicForm: this.convertDynamicFormParams(
          osSpecificConfig?.recovery?.dynamicForm
        )
      }
    };
  }

  /**
   * Converts the dynamic form params received from the config API to a format consumable by the UI.
   *
   * @param   dynamicForm   Dynamic form params received from the API.
   * @returns Dynamic form params in the UI consumable 'DynamicForm' format.
   */
  private convertDynamicFormParams(dynamicForm: UdaDynamicFormParams): DynamicForm {
    const formPanels: FormPanel[] = [];
    for (const panel of (dynamicForm?.panels ?? [])) {
      const formPanel: FormPanel = this.convertFormPanelParams(panel);
      formPanels.push(formPanel);
    }
    return {
      panels: formPanels,
    };
  }

  /**
   * Converts the form panel params received from the config API to a format consumable by the UI.
   *
   * @param   panel   Form panel params received from the API.
   * @returns Panel params in the UI consumable 'FormPanel' format.
   */
  private convertFormPanelParams(panel: FormPanelParams): FormPanel {
    if (!panel) {
      return null;
    }

    const formFields: FormField[] = [];
    for (const field of (panel.fields ?? [])) {
      const formField: FormField = this.convertFormFieldParams(field);
      formFields.push(formField);
    }
    return {
      id: panel.id,
      title: panel.title,
      optional: panel.optional,
      fields: formFields,
    };
  }

  /**
   * Converts the form field params received from the config API to a format consumable by the UI.
   *
   * @param   field   Form field params received from the API.
   * @returns Form field params in the UI consumable 'FormField' format.
   */
  private convertFormFieldParams(field: FormFieldParams): FormField {
    if (!field) {
      return null;
    }

    return {
      id: field.id,
      key: field.key,
      label: field.label,
      type: this.convertToFieldType(field.type),
      typeConfig: {
        stringConfig: field.stringConfig,
        passwordConfig: field.passwordConfig,
        booleanConfig: field.booleanConfig,
        numberConfig: this.convertNumberFormFieldParams(field.numberConfig),
        radioGroupConfig: this.convertRadioGroupFormFieldParams(field.radioGroupConfig),
      }
    };
  }

  /**
   * Converts the number form field params received from the config API to a format consumable by the UI.
   *
   * @param   numberConfig   Number form field params received from the API.
   * @returns Number form field params in the UI consumable 'NumberConfig' format.
   */
  convertNumberFormFieldParams(numberConfig: NumberFormFieldParams): NumberConfig {
    if (!numberConfig) {
      return null;
    }

    return {
      defaultValue: numberConfig.defaultValue ? +numberConfig.defaultValue : null,
      description: numberConfig.description,
      placeholder: numberConfig.placeholder,
      required: numberConfig.required,
      maximumValue: numberConfig.maximumValue ? +numberConfig.maximumValue : null,
      minimumValue: numberConfig.minimumValue ? +numberConfig.minimumValue : null,
    };
  }

  /**
   * Converts the radio group form field params received from the config API to a format consumable by the UI.
   *
   * @param   radioGroupConfig   Radio group form field params received from the API.
   * @returns Radio group form field params in the UI consumable 'RadioGroupConfig' format.
   */
  private convertRadioGroupFormFieldParams(radioGroupConfig: RadioGroupFormFieldParams): RadioGroupConfig {
    if (!radioGroupConfig) {
      return null;
    }

    return {
      defaultValue: radioGroupConfig.defaultValue,
      required: radioGroupConfig.required,
      radioButtons: (radioGroupConfig.radioButtons || []).map((radioButton) => ({
        id: radioButton.id,
        value: radioButton.value,
        label: radioButton.label,
        formPanel: this.convertFormPanelParams(radioButton.panel)
      }))
    };
  }

  /**
   * Converts the string field type to enum to be consumed by
   * generic-form-field.
   *
   * @param   type   Field type in string.
   * @returns Field type as enum to be used by generic-form-field.
   */
  convertToFieldType(type: string): FieldType {
    let newType: FieldType;
    switch (type) {
      case 'KString':
        newType = 'string';
        break;
      case 'KPassword':
        newType = 'password';
        break;
      case 'KNumber':
        newType = 'number';
        break;
      case 'KBoolean':
        newType = 'boolean';
        break;
      case 'KRadioGroup':
        newType = 'radioGroup';
        break;
    }
    return newType;
  }
}
