import { AppModuleConfig, StateAccessMap, StateAccessContext } from 'src/app/app-module.config';

const nisAccess = (ctx: StateAccessContext) => ctx.NIS_VIEW && ctx.FEATURE_FLAGS.nisEnabled;

export const NisConfig: AppModuleConfig = {
  futureStates: [],

  getStateAccessMap(): StateAccessMap {
    return {
      'access-management.nis': nisAccess,
    };
  },
};
