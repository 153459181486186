import { Object } from '@cohesity/api/v2';
import { RecoveryOsType } from 'src/app/shared';

import { FileSearchResult } from './file-search-result';
import { RestoreSearchResult } from './restore-search-result';

/**
 * This class groups multiple file search results together into a single objec that can be used
 * within a restore point selection. This assumes that all of the files share the same source id,
 * object it, and protection group.
 */
export class FileSearchResultGroup implements RestoreSearchResult {
  /**
   * Static property for the result type. Which can be referenced by other classes.
   */
  static readonly fileResultGroupType = 'fileGroup';

  // The below properties are implementations for the RestoreSearchResult interface.
  readonly resultType = FileSearchResultGroup.fileResultGroupType;
  readonly name = null;
  readonly id: number;
  readonly isUnixPath: boolean;
  readonly environment: string;
  readonly sourceEnvironment: string;
  readonly sourceId: number;
  readonly parentSourceName: string;
  readonly objectId: number;
  readonly objectName: string;
  readonly recoveryOsType: RecoveryOsType;
  readonly protectionGroupId: string;
  readonly protectionGroupName: string;
  readonly restoreTimestampUsecs: number;
  readonly storageDomainId: number;
  readonly supportedNasMountProtocols: string[];
  readonly sourceInfo: Object;

  /**
   * Gets a list of file ids that can be set as object ids so that the selection summary count will be correct.
   */
  get fileIds(): string[] {
    return this.files.map(file => file.id);
  }

  constructor(public files: FileSearchResult[]) {
    const file = files[0];
    this.id = file.objectId;
    this.isUnixPath = file.isUnixPath;
    this.name = file.name;
    this.environment = file.environment;
    this.sourceEnvironment = file.sourceEnvironment;
    this.sourceId = file.sourceId;
    this.parentSourceName = file.parentSourceName;
    this.objectId = file.objectId;
    this.objectName = file.objectName;
    this.recoveryOsType = file.recoveryOsType;
    this.protectionGroupId = file.protectionGroupId;
    this.protectionGroupName = file.protectionGroupName;
    this.restoreTimestampUsecs = file.restoreTimestampUsecs;
    this.storageDomainId = file.storageDomainId;
    this.supportedNasMountProtocols = file.supportedNasMountProtocols;
    this.sourceInfo = file.sourceInfo;
  }
}
