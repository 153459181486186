import { Component, NgModule } from '@angular/core';
import { appConfigMap } from '@cohesity/iris-core';
import { UIRouterModule } from '@uirouter/angular';

import { AppModuleConfig, CanAccess } from '../app-module.config';
import { AppStateDeclaration } from '../core';

const viewAccess: CanAccess = ctx => ctx.DRAAS_VIEW && ctx.FEATURE_FLAGS.siteCon2App;
const appConfig = appConfigMap.siteContinuityV2;

/**
 * A placeholder component for the UI router.
 */
@Component({ template: '' })
class DummyComponent {}

/**
 * The collection of allowed query params in the application. Without this declared in the state params, UI router
 * doesn't recognize the query params to be passed to the SiteContinuity app.
 */
const appQueryParams = [
  'drSite',
  'endTime',
  'mode',
  'primarySite',
  'severities',
  'siteId',
  'slaStatus',
  'startTime',
  'status',
  'type',
];

/**
 * The application states.
 */
const states: AppStateDeclaration[] = [
  {
    name: 'siteContinuity',
    url: `^${appConfig.urlSegment}/:slug?${appQueryParams.join('&')}`,
    title: 'SiteContinuity',
    component: DummyComponent,
    data: { hideAppFrame: true },
    params: {
      slug: {
        type: 'string',
        raw: true,
        value: '',
        dynamic: true,
        squash: true,
      },
    },
  },
];

/**
 * Defines the SiteContinuity app module. This is used as a placeholder module
 * to load the SiteContinuity SPA within Iris.
 */
@NgModule({
  declarations: [DummyComponent],
  imports: [UIRouterModule.forChild({ states })],
})
export class SiteContinuityAppModule {}

/**
 * The application module config object.
 */
export const SiteContinuityAppConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'siteContinuity.**',
      url: appConfig.urlSegment,
      loadChildren: () => SiteContinuityAppModule,
    },
  ],
  allClusterMap: {
    siteContinuity: {
      heliosOnly: true,
      globalContext: true,
      singleClusterState: 'dashboard',
      allClustersState: 'siteContinuity',
    },
  },
  getStateAccessMap() {
    return { siteContinuity: viewAccess };
  },
};
