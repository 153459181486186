<a *ngIf="isClusterScope"
  cogDashcard
  [title]="title"
  [subtitle]="'objectsMeetingSlaLast24Hours' | translate"
  uiSref="jobs"
  uiParams="{ slaViolation: true }">
  <ng-container *ngTemplateOutlet="cardTemplate"></ng-container>
</a>

<cog-dashcard *ngIf="!isClusterScope" [title]="title"
  [subtitle]="'objectsMeetingSlaLast24Hours' | translate">
  <ng-container *ngTemplateOutlet="cardTemplate"></ng-container>
</cog-dashcard>

<ng-template #cardTemplate>
  <cog-dashcard-layout-standard *ngIf="complianceDonutData && !isLoading">
    <cog-dashcard-chart>
      <cog-donut-chart colorSetClass="consumption"
        [isClickable]="true"
        [seriesData]="complianceDonutData.series"
        [customChartOptions]="customChartOptions">
        <span>
          {{compliancePct}}<small>%</small>
        </span>
      </cog-donut-chart>
    </cog-dashcard-chart>

    <cog-dashcard-legend>
      <cog-statlist valueSize="sm" [vertical]="true">
        <a cogStatlistItem *ngFor="let series of complianceDonutData.series; index as i;"
          (click)="goToReport()"
          [label]="series.data[0].y | number"
          [labelLegend]="'highcharts-series-' + i">
          {{series.name | translate}}
        </a>
      </cog-statlist>
    </cog-dashcard-legend>
    <cog-dashcard-footer>
      <coh-missed-sla-status *ngIf="isClusterScope"></coh-missed-sla-status>
    </cog-dashcard-footer>
  </cog-dashcard-layout-standard>
  <cog-spinner *ngIf="isLoading"></cog-spinner>
  <coh-blank-card type="view" *ngIf="!complianceDonutData && !isLoading"></coh-blank-card>
</ng-template>
