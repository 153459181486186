import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LegacyTooltipPosition as TooltipPosition } from '@angular/material/legacy-tooltip';

/**
 * Cog info badge
 *
 * @example
 * <cog-info-badge [label]="'dummy data'"></cog-info-badge>
 */
@Component({
  selector: 'cog-info-badge',
  templateUrl: './info-badge.component.html',
  styleUrls: ['./info-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoBadgeComponent {
  /**
   * Label to show.
   */
  @Input() label: string;

  /**
   * State of the badge
   */
  @Input() state: 'info' | 'success' | 'warning' | 'error' = 'info';

  /**
   * Optional Tooltip message of the badge.
   */
  @Input() tooltipMsg?: string;

  /**
   * Tooltip position of the badge.
   */
  @Input() tooltipPosition: TooltipPosition = 'right';
}
