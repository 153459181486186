import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { HumanizeFromNumbersPipeModule } from '@cohesity/utils';
import { TranslateModule } from '@ngx-translate/core';

import { FileAnalysisComponent } from './file-analysis.component';

const COMPONENTS = [FileAnalysisComponent];

@NgModule({
  imports: [
    CommonModule,
    CohesityHelixModule,
    HumanizeFromNumbersPipeModule,
    TranslateModule
  ],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
})
export class FileAnalysisModule {}
