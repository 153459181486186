import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { UtilPipesModule } from '@cohesity/utils';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AddItemOptionComponent } from './add-item-option/add-item-option.component';
import { CertificateInputComponent } from './certificate-input/certificate-input.component';
import { ChangePasswordFormComponent } from './change-password-form/change-password-form.component';
import { CredentialsComponent } from './credentials/credentials.component';
import { DayOfMonthSelectorComponent } from './day-of-month-selector/day-of-month-selector.component';
import { DayOfWeekSelectorComponent } from './day-of-week-selector/day-of-week-selector.component';
import { DualTimePeriodSelectorComponent } from './dual-time-period-selector/dual-time-period-selector.component';
import { DurationSelectorComponent } from './duration-selector/duration-selector.component';
import { EmailChipInputComponent } from './email-chip-input/email-chip-input.component';
import { FormArrayActionControlComponent } from './form-array-action-control/form-array-action-control.component';
import { FormFieldObjectSelectorComponent } from './form-field-object-selector/form-field-object-selector.component';
import {
  FormSectionActionsDirective,
  FormSectionEditDirective,
  FormSectionInfoDirective,
  FormSectionSummaryDirective,
  FormSectionWithSummaryComponent,
} from './form-section-with-summary/form-section-with-summary.component';
import { LocationSelectorComponent } from './location-selector/location-selector.component';
import { RetentionSelectorComponent } from './retention-selector/retention-selector.component';
import { ScheduleAndTimeSelectorComponent } from './schedule-and-time-selector/schedule-and-time-selector.component';
import { ScheduleSelectorComponent } from './schedule-selector/schedule-selector.component';
import {
  SearchableSelectItemTemplateDirective,
  SearchableSelectTriggerTemplateDirective,
} from './searchable-select/item-template.directive';
import { SearchableSelectComponent } from './searchable-select/searchable-select.component';
import { SimpleChipListInputComponent } from './simple-chip-list-input/simple-chip-list-input.component';
import { TextualConfirmationInputComponent } from './textual-confirmation-input/textual-confirmation-input.component';
import { TimeFieldComponent } from './time-field/time-field.component';
import { TimePeriodSelectorComponent } from './time-period-selector/time-period-selector.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { TimezoneSelectorComponent } from './timezone-selector/timezone-selector.component';
import { ValidateOnSubmitDirective } from './validate-on-submit.directive';
import { MatchValueValidator } from './validators/match-value.directive';

const COMPONENTS = [
  AddItemOptionComponent,
  CertificateInputComponent,
  ChangePasswordFormComponent,
  CredentialsComponent,
  DayOfMonthSelectorComponent,
  DayOfWeekSelectorComponent,
  DualTimePeriodSelectorComponent,
  DurationSelectorComponent,
  EmailChipInputComponent,
  FormArrayActionControlComponent,
  FormFieldObjectSelectorComponent,
  FormSectionActionsDirective,
  FormSectionEditDirective,
  FormSectionInfoDirective,
  FormSectionSummaryDirective,
  FormSectionWithSummaryComponent,
  LocationSelectorComponent,
  MatchValueValidator,
  RetentionSelectorComponent,
  ScheduleAndTimeSelectorComponent,
  ScheduleSelectorComponent,
  SearchableSelectComponent,
  SearchableSelectItemTemplateDirective,
  SearchableSelectTriggerTemplateDirective,
  SimpleChipListInputComponent,
  TextualConfirmationInputComponent,
  TimeFieldComponent,
  TimePeriodSelectorComponent,
  TimePickerComponent,
  TimezoneSelectorComponent,
  ValidateOnSubmitDirective,
];

/**
 * Module for forms related components. This includes components for working with
 * specific types of data (TimePeriodSelector), and components for displaying
 * a single piece of data in a custom form control (TimePicker).
 */
@NgModule({
  imports: [
    CohesityHelixModule,
    CommonModule,
    MatAutocompleteModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    ScrollingModule,
    UtilPipesModule,
    TranslateModule.forChild(),
  ],
  declarations: [COMPONENTS],
  exports: [...COMPONENTS, MatAutocompleteModule, ReactiveFormsModule],
})
export class CohesitySharedFormsModule {}
