// Component: cEmailScheduler

;(function(angular, undefined) {
  'use strict';

  var options = {
    controller: 'emailSchedulerCtrl',
    templateUrl:
      'app/reports/components/c-email-scheduler/c-email-scheduler.html',
    bindings: {

      // since this is a modal any values we pass in the resolve object when
      // opening a modal will be available to us here
      resolve: '<',

      // the modal close method
      close: '&',

      // the modal dismiss method
      dismiss: '&',
    },
  };

  angular
    .module('C.emailScheduler', ['C.heliosReports', 'C.HeliosReportsService'])
    .controller('emailSchedulerCtrl', EmailSchedulerCtrlFn)
    .component('cEmailScheduler', options);

  function EmailSchedulerCtrlFn(_, $translate, EMAIL_SCHEDULER_FORMATS,
    DAY_PERIODICITY, ReportsService, evalAJAX, cMessage,
    HeliosReportsService, HELIOS_EMAIL_SCHEDULER_FORMATS) {

    var $ctrl = this;

    var filters;
    var config;

    /**
     * Initialization function.
     *
     * @method   $onInit
     */
    $ctrl.$onInit = function $onInit() {
      filters = $ctrl.resolve.filters;
      config = $ctrl.resolve.sidebarConfig;

      _.assign($ctrl, {
        reportConfig: config,
        EMAIL_SCHEDULER_FORMATS: $ctrl.resolve.mcmMode ?
          HELIOS_EMAIL_SCHEDULER_FORMATS : EMAIL_SCHEDULER_FORMATS,
        DAY_PERIODICITY: DAY_PERIODICITY,
      });

      $ctrl.addEmail = addEmail;
      $ctrl.submitSchedule = submitSchedule;

      _.assign(config, {
        emailSchedulerMode: true,
        showHeader: $ctrl.resolve.sidebarConfig.showHeader || false,
        showReportsSelector: false,
        showApplyFiltersBtn: false,
        showDateRangeFilter: false,
        showReportTimeSpanFilter:
          (config.showReportTimeSpanFilter === false) ? false : true,
      });

      // Extend the sideBarConfig to make the c-reports-control aware that it is
      // open inside the email schedule modal.
      _.assign($ctrl.resolve.sidebarConfig, {
        emailSchedulerMode: true,
      });

      /* Object that holds the selected values from the email-scheduler modal,
       * if we are editing a scheduled report it will set these values from
       * the filters
       */
      $ctrl.selected = {
        scheduleDays: filters.scheduleDays,
        emails: _.isArray(filters.receiverEmails) &&
          filters.receiverEmails.length ? filters.receiverEmails : null,
        mailSubject: filters.mailSubject ?
          filters.mailSubject : $translate.instant('defaultEmailSubject'),

        // HTML should be the default format type
        format: filters.outputFormat || $ctrl.EMAIL_SCHEDULER_FORMATS[1],
        enableRecurringEmail: true,
        consecutiveFailures: filters.consecutiveFailures,
        scheduleTime: {
          hour: filters.scheduleHour || 0,
          minute: 0
        },
        rollup: filters.rollup,

        // Picking schedule timezone else pick it from timezone filter which is
        // present only for Protected Objects Heatmap report.
        timezone: filters.scheduleTimezone || (config.showTimezoneFilter ?
          filters.timezone : undefined),
        id: filters.id,
      };

      $ctrl.updateDateField = updateDateField;
      updateDateField();
    };

    /**
     * calls the appropriate function according to the current mode of the modal
     *
     * @method   submitSchedule
     * @param    {object}   form   The form
     */
    function submitSchedule(form) {
      if (form.$invalid) {
        return;
      }

      var saveFn = ReportsService[$ctrl.resolve.isEditMode ?
        'updateReportSchedule' : 'createReportSchedule'];

      if ($ctrl.resolve.mcmMode) {
        saveFn = HeliosReportsService.upsertReportSchedule;
      }

      $ctrl.saving = true;
      saveFn(filters, $ctrl.selected, $ctrl.resolve.sidebarConfig)
        .then(function modifiedSchedule(data) {
          cMessage.success({
            textKey: $ctrl.resolve.isEditMode ?
              'reports.scheduleUpdated' : 'reports.scheduleCreated',
            timeout: 3000,
          });

          $ctrl.close({ $value: data });
        }, evalAJAX.errorMessage)
        .finally(function modifiedScheduleFinally() {
          $ctrl.saving = false;
        });
    }

    /**
     * verifies that the email inputed is a valid email
     *
     * @param    {string}    email    email address
     */
    function addEmail(email) {
      return email;
    }

    /**
     * Switch between date range and time span fields based on recurringEmail value
     * The field that needs to be shown is decided based on reportType
     */
    function updateDateField() {
      const dateRangeField = 'dateRange';
      const timeSpanField = 'reportTimeSpan';

      if ($ctrl.selected.enableRecurringEmail) {
        $ctrl.dateFieldType = timeSpanField;
      } else {
        $ctrl.dateFieldType = dateRangeField;
      }
    }
  }
})(angular);
