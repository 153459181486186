import { Timeframe } from '@cohesity/helix';

/**
 * Timeframe options for support user.
 */
export const supportUserTimeframeOptions = [
  Timeframe.Day,
  Timeframe.Week,
  Timeframe.Month,
  Timeframe.Custom,
];

/**
 * Timeframe options for sales user.
 */
export const salesUserTimeframeOptions = [
  Timeframe.Day,
  Timeframe.Week,
  Timeframe.Month,
  Timeframe.Quarter,
  Timeframe.HalfYear,
  Timeframe.Custom,
];

/**
 * Default timeframe options for user.
 */
export const defaultUserTimeframeOptions = [ ...salesUserTimeframeOptions, Timeframe.Forever ];
