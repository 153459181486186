import {
  Directive,
  Host,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { MatGridList } from '@angular/material/grid-list';
import { ReflowService, ViewportSize } from './reflow.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { takeUntil } from 'rxjs/operators';
import { DashboardReflowRule, dashboardReflowConfigMap, defaultGridConfig, TileConfig, defaultTileConfig, defaultReflowGridConfigs } from './grid-reflow.model';

/**
 * This directive helps simplify the application of reflow column definitions based on view size.
 *
 * @example
 * <mat-grid-list
 *   class="cog-dash-grid" cogGridListReflowCols>
 * @example
 * <mat-grid-list
 *   class="cog-dash-grid" cogGridListReflowCols="3-cols">
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mat-grid-list [cogGridListReflowCols]',
})
export class GridListReflowColsDirective implements OnDestroy, OnInit {
  /**
   * This input is optional.  It can be one of the keys
   * from the dashboardReflowConfigMap.
   * If left unassigned, the directive will use the default
   * to reflow the grid list.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('cogGridListReflowCols') reflowRule: DashboardReflowRule;

  /**
   * Provides an observable that tiles can subscribe to
   * to get updates on the latest gridConfig based on
   * viewport size changes
   */
  private _tileConfig = new BehaviorSubject<TileConfig>(defaultTileConfig);

  public tileConfig$ = this._tileConfig.asObservable();

  /**
   * Use to clean up subscriptions on destroy.
   */
  private destroy = new Subject<void>();

  constructor(
    @Host() private matGridList: MatGridList,
    private reflowService: ReflowService,
    private logger: NGXLogger
  ) {
    matGridList.cols = dashboardReflowConfigMap.default.lg.columns;
  }

  /**
   * This method subscribes this directive to currentViewport$ events
   * and adjust the host grid accordingly as viewport size changes
   */
  ngOnInit() {
    // if developer provided an invalid value, let them know before defaulting it
    if (this.reflowRule.length > 0 && !dashboardReflowConfigMap[this.reflowRule]) {
      this.logger.warn(`The configuration you provided ('${this.reflowRule}')
        for cogGridListReflowCols can not be found.`);
      this.reflowRule = 'default';
    } else if (this.reflowRule.length === 0) {
      this.reflowRule = 'default';
    }
    this.reflowService.currentViewport$.pipe(takeUntil(this.destroy))
      .subscribe((viewport) => {
        this.setGridConfigForViewport(viewport, this.reflowRule);
      });
  }

  /**
   * This method will configure the grid and tiles according to the viewport
   * and the reflow rule.  If either params are missing, it will default
   *
   * @param viewport
   * @param reflowRule
   */
  setGridConfigForViewport(viewport = ViewportSize.lg, reflowRule = 'default') {
    const allViewportSizeMap = dashboardReflowConfigMap[reflowRule] || defaultReflowGridConfigs;
    const dashboardConfigMap = allViewportSizeMap[viewport] || defaultGridConfig;
    this.matGridList.cols = dashboardConfigMap.columns;
    this._tileConfig.next(dashboardConfigMap.tileConfig);
  };

  ngOnDestroy() {
    this.destroy.next();
  }
}
