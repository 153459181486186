<ng-container *ngIf="hasRegisterPrivileges">
  <button cogDataId="register-dms-source-btn"
    *ngIf="buttonType === 'default'"
    mat-flat-button
    color="primary"
    [matMenuTriggerFor]="menu">
    {{'register' | translate}}
    <cog-icon shape="expand_more!outline"></cog-icon>
  </button>

  <button cogDataId="register-dms-source-btn"
    *ngIf="buttonType === 'stroke'"
    mat-stroked-button
    color="primary"
    [matMenuTriggerFor]="menu">
    <cog-icon shape="add"></cog-icon>
    {{'registerSource' | translate}}
  </button>
</ng-container>

<mat-menu #menu="matMenu">
  <ng-container  *ngFor="let source of availableSources">
    <button mat-menu-item
      cogDataId="{{source.name}}-btn"
      (click)="source.action && source.action()">
      {{ source.displayName | translate }}
    </button>
  </ng-container>
</mat-menu>
