import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { McmClusterIdentifier, Role, TenantAccessResult } from '@cohesity/api/v2';
import { ClusterConfig } from '@cohesity/iris-core';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { find, map } from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { combineLatest } from 'rxjs';
import { filter, finalize, startWith } from 'rxjs/operators';
import {
  AjsUpgradeService,
  AppStateService,
  HeliosAccessManagementService,
  McmViewService,
  OrganizationsService,
  TenantScopeService,
  TenantService,
} from 'src/app/core/services';

import { Organization } from '../models/organization.model';

interface TenantListItem {
  name: string;
  id: string;
  role: string[];
  roleLabel: string[];
  clusters: McmClusterIdentifier[];
}

/**
 * @description
 * Tenant switch component for display selected tenant
 * in application header toolbar
 *
 * @example
 * <coh-tenant-switch></coh-tenant-switch>
 */
@Component({
  selector: 'coh-tenant-switch',
  templateUrl: './tenant-switch.component.html',
  styleUrls: ['./tenant-switch.component.scss']
})
export class TenantSwitchComponent extends AutoDestroyable implements OnInit {

  /**
   * Element reference to cogPopover directer to access its methods
   */
  @ViewChild('cogPopover') element;

  /**
   * Legacy tenant service
   */
  ajsTenantService: any;

  /**
   * The search control used to filter out the organizations list.
   */
  orgSearchCtrl = new UntypedFormControl('');

  /**
   * Variable to check loading of organization
   */
  loading = false;

  /**
   * Organization List
   */
  organizationList: TenantListItem[] = [];

  /**
   * List of filtered Organizations by their name.
   */
  filteredOrganizations: Organization[] = [];

  /**
   * Variable for organization placeholder.
   */
  organization = this.translateService.instant('organization');

  /**
   * All Organizations Object
   */
  allOrganizations = {
    name: this.translateService.instant('allOrganizations'),
    role: 'Administrator',
    id: 0
  };

  /**
   * Variable for storing selected organization
   */
  selectedOrganization: any;

  /**
   * Cached value indicating if the input is currently focused.
   */
  private _isFocused = false;

  /**
   * Getter for value currently indicating if input is focused.
   */
  get focused() {
    return this._isFocused;
  }

  /**
   * Setter for value currently indicating if input is focused.
   */
  set focused(newVal: boolean) {
    this._isFocused = newVal;
  }

  /**
   * Cached value indicating if user is currently hovering the filter.
   */
  private _isHovered = false;

  /**
   * Getter for value indicating if user is currently hovering the filter.
   */
  get hovered() {
    return this._isHovered;
  }

  /**
   * Setter for value indicating if user is currently hovering the filter.
   */
  set hovered(newVal: boolean) {
    this._isHovered = newVal;
  }

  /**
   * Indicates if the filter should be expanded.
   */
  get expanded(): boolean {
    return this.focused || this.hovered || !!this.orgSearchCtrl.value;
  }

  constructor(
    private accessManagementService: HeliosAccessManagementService,
    private ajsUpgrade: AjsUpgradeService,
    private ajaxHandlerService: AjaxHandlerService,
    private logger: NGXLogger,
    private mcmViewService: McmViewService,
    private ngTenantService: TenantService,
    private appStateService: AppStateService,
    private state: StateService,
    private tenantScopeService: TenantScopeService,
    private translateService: TranslateService,
    private organizationsService: OrganizationsService
  ) {
    super();
    this.ajsTenantService = this.ajsUpgrade.get('TenantService');
  }

  ngOnInit(): void {

    this.fetchOrganizationsAccess();
    this.ngTenantService.impersonatedTenant$.pipe(
      this.untilDestroy()
    ).subscribe(() => this.setSelectedOrganization());
    this.selectedOrganization = this.allOrganizations;
    this.setSelectedOrganization();

  }

  /**
   * Set the currently selected organization in the switcher
   */
  setSelectedOrganization() {
    const impersonatedTenant = this.ngTenantService.impersonatedTenant;

    // set to a particular tenant
    // or reset to ALL ORGANIZATIONS
    if (impersonatedTenant) {
      this.selectedOrganization = impersonatedTenant;
    } else if (!impersonatedTenant && this.selectedOrganization?.id !== this.allOrganizations.id) {
      // reset to ALL ORGANIZATIONS
      this.selectedOrganization = this.allOrganizations;
    }
  }

  /**
   * Initialize Filter
   */
  initFilter() {
    this.orgSearchCtrl.valueChanges.pipe(
      this.untilDestroy(),
      startWith('')).
      subscribe((organization: string | null) => {
        this.filteredOrganizations = organization ? this._filter(organization) : this.organizationList.slice();
      });
  }

  /**
   * Function to filter list based on Organization name
   *
   * @param value Organization name
   */
  private _filter(value: string): TenantListItem[] {
    const filterValue = value.toLowerCase();
    return this.organizationList.filter(organization => organization?.name.toLowerCase().indexOf(filterValue) === 0);
  }

  /**
   * Get Organizations access assigned to logged in user
   */
  fetchOrganizationsAccess() {

    combineLatest([
      this.appStateService.selectedScope$,
      this.organizationsService.organizations$,
      this.accessManagementService.getRoles(),
    ]).pipe(
      filter(([scope]) => !!scope),
      this.untilDestroy(),
      finalize(() => this.loading = false)
    )
      .subscribe(([selectedScope, tenantAccesses, roles]: [ClusterConfig, TenantAccessResult, Role[]]) => {
        this.organizationList = (tenantAccesses?.tenantAccesses || []).map(org => ({
          name: org?.tenantName || '',
          id: org.tenantId,
          role: org.roles,
          roleLabel: map(org.roles, (role) => find(roles, { name: role }).label),
          clusters: org.clusters,
        }));

        // Only show organizations that have access to selected scope
        if (!selectedScope._allClusters) {
          this.organizationList = this.organizationList.filter(org =>
            !!(org.clusters.find(cluster => cluster.clusterId === selectedScope.clusterId &&
              cluster.clusterIncarnationId === selectedScope.clusterIncarnationId))
          );
        }

        this.initFilter();
      },
        error => this.ajaxHandlerService.handler(error)
      );
  }

  /**
   * Handler for organization select in organization list
   *
   * @param organization selected organization object
   */
  onOrganizationSelect(organization: Organization) {
    this.selectedOrganization = organization;
    this.ngTenantService.impersonatedTenant = { tenantId: organization.id, ...organization };
    this.onTenantScopeSwitch();
  }

  /**
   * Handler for All Organization select in organization list
   */
  onAllOrganizationSelect() {
    this.selectedOrganization = this.allOrganizations;
    this.ngTenantService.clearImpersonatedTenant();
    this.onTenantScopeSwitch();
  }

  /**
   * Common recipe when tenant scope is switched
   */
  onTenantScopeSwitch() {
    this.tenantScopeService.updateUserContext().then(
      () => {
        setTimeout(() => {
          this.mcmViewService.forceUpdateFrame = true;
          this.state.reload().catch((err) => {
            this.logger.error('Failed to reload state while impersonation', err);
          }).finally(() => {
            this.mcmViewService.forceUpdateFrame = false;
          });
        }, 0);
      }
    ).catch((err) => this.logger.error('Failed to update user context while impersonation', err));

    this.element.popOverRef.close();
  }
}
