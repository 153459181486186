;(function(angular, undefined) {

  angular.module('C.formShutters')
    .component('cFormShuttersPanel', {
      bindings: {
        disabled: '<',
        disabledTooltipKey: '@',

        // Optionally adjust UI for a nested set of shutters. Default: false
        nested: '<?',
      },
      require: {
        parentCtrl: '^cFormShutters',
      },
      transclude: true,
      controller: cFormShuttersPanelFn,
      controllerAs: 'panelCtrl',
      templateUrl: 'app/global/c-form-shutters/c-form-shutters-panel.html',
    });

  /**
   * @ngdoc component
   * @name C.formShutters:cFormShuttersPanel
   * @function
   *
   * @description
   * An individual panel to be included in cFormShutters.
   *
   * @example
     See _c-form-shutters.js for example.
   */
  function cFormShuttersPanelFn($element, $animate) {
    var panelCtrl = this;

    angular.extend(panelCtrl, {
      $onInit: $onInit,
      checkForErrors: checkForErrors,
      toggle: toggle,
      handleContentClick: handleContentClick,
    });

    /**
     * Component initialization function
     *
     * @method   $onInit
     */
    function $onInit() {
      panelCtrl.forcedOpen = false;
      panelCtrl.open = false;
      panelCtrl.parentCtrl.registerPanel(panelCtrl);
      panelCtrl.disabledTooltipKey = panelCtrl.disabledTooltipKey || 'disabled';
    }

    /**
     * Opens or closes the item panel.
     *
     * @method   toggle
     * @param    {boolean}   open   Indicates if panel should be opened or
     *                              closed. If value not provided, the function
     *                              simply toggles the current state.
     */
    function toggle(open) {
      if (panelCtrl.disabled) { return; }

      panelCtrl.open = angular.isDefined(open) ?
        open : !panelCtrl.open;

      if (panelCtrl.open) {
        $animate.addClass($element, 'open');
        // TODO: attempt to focus first input if opened
      } else {
        $animate.removeClass($element, 'open');
      }

    }

    /**
     * Click handler for when user clicks on the a shutter's content.
     *
     * @method   handleContentClick
     */
    function handleContentClick() {
      // If the panel is disabled or already open, do nothing.
      if (panelCtrl.disabled || panelCtrl.open) { return; }

      // otherwise open the panel.
      toggle();
    }

    /**
     * Sniffs for ng-invalid class to determine if there are form validation\
     * errors contained in the item's panel, in which case the panel is opened
     * so the errors will be visible to the user.
     *
     * @method   checkForErrors
     */
    function checkForErrors() {
      var hasError = /\bng-invalid\b/.test($element[0].innerHTML);

      // update when error state changes.
      if (panelCtrl.forcedOpen !== hasError) {
        panelCtrl.forcedOpen = hasError;

        // force open to show fields with error.
        if (panelCtrl.forcedOpen) {
          panelCtrl.toggle(true);
        }
      }
    }
  }

})(angular);
