<cog-table name="reovery-objects-table"
  [source]="recoveryObjects || []"
  [selection]="selection">

  <cog-table-multi-action>
    <span [matTooltip]="'bulkVmSync' | translate" *ngIf="showBulkRecoveryActionIcons">
      <button mat-icon-button
        cogDataId="btn-sync-migration"
        [inheritDataId]="true"
        color="primary"
        (click)="bulkSync()">
        <mat-icon color="accent">autorenew</mat-icon>
      </button>
    </span>
  </cog-table-multi-action>

  <table mat-table
    matSort
    matSortActive="recoveryPoint"
    matSortDirection="desc"
    [trackBy]="trackById">

    <!-- Object Name -->
    <ng-container matColumnDef="name">
      <th mat-header-cell cogDataId="object-name-col" *matHeaderCellDef mat-sort-header>
        {{ 'object' | translate }}
      </th>
      <td mat-cell *matCellDef="let object">
        <a class="cog-table-object-name pg-name-anchor"
          cohStopPropagation
          [cogDataId]="'object-anchor-' + object.fullName"
          (click)="getRecoveryRunsDetail(object)">
          <div class="cog-table-object">
            <cog-icon  [shape]="object.objectType | recoveryObjectIcon:
              object.environment:
              object.osType:
              recovery.action">
            </cog-icon>
            <div class="cog-table-object-body">
              <div class="flex-truncate-wrapper">
                <div class="cog-table-object-name flex-truncate-text">
                  {{ object.fullName }}
                </div>
                <span class="icn-view"
                  [cogPopover]="popover"
                  [popoverData]="object"
                  (click)="$event.stopPropagation()">
              </span>
              </div>
            </div>
          </div>
        </a>
      </td>
    </ng-container>

    <!-- Recovery point -->
    <ng-container matColumnDef="recoveryPoint">
      <th cogDataId="object-creation-time-col" mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'lastSyncedSnapshot' | translate }}
      </th>
      <td mat-cell *matCellDef="let object">
        <div class="cog-table-object">
          <cog-icon *ngIf="object.recoveryPoint" shape="helix:cluster"></cog-icon>
          {{ (object.recoveryPoint | cohDate) || '-' }}
        </div>
      </td>
    </ng-container>

    <!-- Sync status -->
    <ng-container matColumnDef="status">
      <th cogDataId="object-status-col" mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'status' | translate }}
      </th>
      <td mat-cell *matCellDef="let object" [matTooltip]="object.messages[0]">
        <coh-pulse-status
          [status]="object.status"
          [taskPath]="object.progressTaskId"
          (taskFinish)="progressComplete.emit(object)">
        </coh-pulse-status>
      </td>
    </ng-container>

    <!-- Action icons -->
    <ng-container matColumnDef="sync">
      <th cogDataId="object-creation-time-col" mat-header-cell *matHeaderCellDef>
      </th>
      <td mat-cell class="action-cell" *matCellDef="let object">
        <span [matTooltip]="'syncVm' | translate"
          *ngIf="['kOnHoldError'].includes(object.status)">
          <button mat-icon-button
            cogDataId="btn-sync-vm-migration"
            [inheritDataId]="true"
            color="primary"
            (click)="syncVM(object)">
            <cog-icon shape="autorenew" color="accent"></cog-icon>
          </button>
        </span>
        <span [matTooltip]="'cancelMigration' | translate"
          *ngIf="['kRunning', 'kInProgress', 'kOnHold', 'kOnHoldError'].includes(object.status)">
          <button mat-icon-button
            cogDataId="btn-cancel-vm-migration"
            [inheritDataId]="true"
            color="primary"
            (click)="cancelVmMigration()">
            <cog-icon shape="clear" color="accent"></cog-icon>
          </button>
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"
      [cogDataId]="'object-anchor-' + (row.name | lowercase)"
      [class.no-activity-row]="!row.progressTaskId?.length"></tr>

    <!-- NoData Footer -->
    <ng-container matColumnDef="noData">
      <td [attr.colspan]="columns.length" mat-footer-cell *matFooterCellDef>
        {{ 'noObjectsFound' | translate }}
      </td>
    </ng-container>

    <tr class="nodata-row" mat-footer-row [class.hidden]="recoveryObjects?.length" *matFooterRowDef="['noData']"></tr>
  </table>
  <mat-paginator></mat-paginator>
</cog-table>
