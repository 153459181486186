// Module: LDAP

;(function(angular) {
  'use strict';

  var configOptions = {
    bindings: {
      /**
       * Required attribute. The id of the LDAP that is to be viewed. Works both
       * for modal and page view.
       */
      ldapId: '<',

      /**
       * Optional attribute. If present then LDAP edit/delete is not allowed
       * else those options will be shown.
       */
      disableModifications: '<?',

      /**
       * Optional attribute. If present, then this component is opened inside a
       * modal.
       */
      inModal: '<?',
    },
    controller: 'viewLdapController',
    templateUrl: 'app/admin/access-management/ldap/view.html',
  };

  angular
    .module('C.ldap')
    .controller('viewLdapController', ViewLdapControllerFn)
    .component('ldapView', configOptions);

  /**
   * Controller for the Details view
   */
  function ViewLdapControllerFn(_, $state, $log, evalAJAX, cModal,
    LdapService) {

    var $ctrl = this;

    _.assign($ctrl, {
      ldap: {},

      // Methods
      $onInit: $onInit,
      removeLdapProvider: removeLdapProvider,
    });

    /**
     * Initializes the controller.
     *
     * @method   $onInit
     */
    function $onInit() {
      var ldapId = $ctrl.ldapId;

      LdapService.getLdapProviderById(ldapId).then(
        function getLdapProvidersSuccess(ldap) {
          $ctrl.ldap = ldap;

          // If no match, then leave.
          if (!$ctrl.ldap) {
            $log.error('Could not find specified LDAP Provider with ID: ' +
              $state.params.id);
            return $state.go('access-management.ldap');
          }

          // Disable modifications (edit/delete) if the user is not an owner of
          // the LDAP.
          if (!$ctrl.ldap._isLdapOwner) {
            $ctrl.disableModifications = true;
          }

          // Query for connection status.
          LdapService.getConnectionStatus($ctrl.ldap);
        },
        evalAJAX.errorMessage
      ).finally(function getLdapProvidersFinally() {
        $ctrl.dataReady = true;
      });
    }

    /**
     * Removes the given LDAP Provider. Pops a challenge modal to confirm the
     * action.
     *
     * TODO(David): Move to service.
     *
     * @method     removeLdapProvider
     * @param      {Object}  ldap  LDAP config object
     * @return     {Object}  $uibModal instance
     */
    function removeLdapProvider(ldap) {
      var modalConfig = {
        controller: DeleteLdapControllerFn,
        resolve: {
          ldap: function resolveLdap() { return ldap; }
        },
      };

      var modalOptions = {
        titleKey: 'ldap.removeLdapProvider.title',
        contentKey: 'ldap.removeLdapProvider.text',
        contentKeyContext: ldap,
        actionButtonKey: 'delete',
        closeButtonKey: 'cancel',
      };

      cModal.standardModal(modalConfig, modalOptions).then(
        function removeLdapModalSuccess() {
          return $state.go('access-management.ldap');
        }
      );
    }
  }

  /* @ngInject */
  function DeleteLdapControllerFn($uibModalInstance, LdapService, evalAJAX,
    ldap) {

    var $ctrl = this;

    /**
     * Handles onClick the primary button in the standardModal.
     */
    $ctrl.ok = function okayThen() {
      $ctrl.submitting = true;
      LdapService.removeLdapProvider(ldap).then(
        $uibModalInstance.close,
        evalAJAX.errorMessage
      ).finally(function deleteFinally() {
        $ctrl.submitting = false;
      });
    };
  }

})(angular);
