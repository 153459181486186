import moment, { Moment } from 'moment';

/**
 * Enum for minimum Date Options
 */
export enum MinDateOptions {
  Past3Months = 'Past3Months',
  Past6Months = 'Past6Months',
  PastYear = 'PastYear',
  Forever = 'Forever',
  Today = 'Today',
}

/**
 * Enum for maximum Date Options
 */
export enum MaxDateOptions {
  Forever = 'Forever',
  Today = 'Today'
}

/**
 * minDateOption to minDate map
 */
export const minDateOptionsMap: Map<MinDateOptions, Moment | null> = new Map([
  [MinDateOptions.Past3Months, moment().subtract(3, 'M')],
  [MinDateOptions.Past6Months, moment().subtract(6, 'M')],
  [MinDateOptions.PastYear, moment().subtract(1, 'y')],
  [MinDateOptions.Forever, null],
  [MinDateOptions.Today, moment()],
]);

/**
 * maxDateOption to maxDate map
 */
export const maxDateOptionsMap: Map<MaxDateOptions, Moment | null> = new Map([
  [MaxDateOptions.Today, moment()],
  [MaxDateOptions.Forever, null],
]);
