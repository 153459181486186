import { ProtectionSourceNode, SourceSpecialParameter } from '@cohesity/api/v1';
import { Environment } from 'src/app/shared/constants';

import { ProtectionSourceDataNode } from '../shared/protection-source-data-node';

/**
 * Represents an active directory source node and job tree selection behavior.
 */
export class AdSourceDataNode extends ProtectionSourceDataNode {
  /**
   * This indicates whether the node is part of the job currently being edited or viewed.
   */
  inCurrentJob = false;

  /**
   * Domain controllers can not be selected, only the host can. This hides the checkbox
   * for domain controllers.
   */
  get isSelectable() {
    return this.type !== 'kDomainController';
  }

  /**
   * Override expandable property to check for nodes or application node.
   */
  get expandable(): boolean {
    return (
      (this.data.nodes && !!this.data.nodes.length) ||
      (this.data.applicationNodes && !!this.data.applicationNodes.length)
    );
  }

  constructor(data: ProtectionSourceNode, readonly level: number) {
    super(Environment.kAD, data, level);
  }

  /**
   * Auto select is disabled for kAD
   */
  canAutoSelect(): boolean {
    return false;
  }

  /**
   * Exclusion is disabled for kAD
   */
  canExclude(): boolean {
    return false;
  }

  /**
   * A node can be selected if it is not protected or if it is in the current job. This function is
   * defined as a property so that 'this' is bound correctly.
   */
  canSelect = () => (this.inCurrentJob || !this.protected) && Array.isArray(this.data.applicationNodes);

  /**
   * Gets default source special params for an active directory object.
   *
   * @return   Source parameters that include the application Entity ids.
   */
  getDefaultSourceParameters(): SourceSpecialParameter {
    return {
      sourceId: Number(this.id),
      adSpecialParameters: {
        applicationEntityIds: this.data.applicationNodes.map((app: ProtectionSourceNode) => app.protectionSource.id),
      },
    };
  }
}
