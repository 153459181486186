import { Injectable } from '@angular/core';

import { addQueryParams } from '../../util';

/** The helix assets options */
export interface HelixAssets {
  // The cache buster param key name.
  cacheBusterParam?: string;

  // The cache buster param value.
  cacheBusterValue?: string;

  // The helix assets location.
  location?: string;
}

/**
 * Return the new url after adding the cache buster query params.
 *
 * @param urls The urls.
 * @param options The Helix assets.
 * @returns Return the new url after adding the cache buster query params.
 */
export const addCacheBuster = (urls: string[], options: HelixAssets) => addQueryParams(
  urls,
  options.cacheBusterValue ? { [options.cacheBusterParam]: options.cacheBusterValue } : {},
);

@Injectable({ providedIn: 'root' })
export class HelixAssetsOptions {
  /**
   * The base path to load helix assets from.
   */
  readonly default: HelixAssets = Object.freeze({
    cacheBusterParam: 'build',
    cacheBusterValue: null,
    location: 'helix-assets',
  });

  private _options: HelixAssets = { ...this.default };

  get options(): HelixAssets {
    return this._options;
  }

  set options(newOptions: Partial<HelixAssets>) {
    this._options = {
      ...this.default,
      ...newOptions,
    };
  }
}
