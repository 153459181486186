import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { TenantServiceApi } from '@cohesity/api/v1';
import { View, ViewServiceApi, StorageDomainServiceApi } from '@cohesity/api/v2';
import {
  DataFilterValue,
  FiltersComponent,
  IsAllSelectedFn,
  KeyedSelectionModel,
  TableComponent,
  ToggleSelectAllFn,
  ValueFilterSelection,
} from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';
import { get, uniqBy } from 'lodash';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { debounceTime, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';

import { AjsClusterService } from '../../ng-upgrade/services';
import { ItemPickerFormControl } from '@cohesity/shared-forms';
import { ViewSelectionDataSource } from './view-selection-data-source';

@Component({
  selector: 'coh-view-selection',
  templateUrl: './view-selection.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ViewSelectionComponent),
      multi: true,
    },
  ],
})
export class ViewSelectionComponent extends ItemPickerFormControl<number> implements OnInit, OnChanges  {

  /**
   * Indicates whether multiTenancy is enabled or not.
   */
  multiTenancyEnabled = false;

  /**
   * Pre-selected view.
   */
  @Input() selectedView: View;

  /**
   * Pre-selected views (for multiSelect).
   */
  @Input() selectedViews: View[];

  /**
   * Allow selecting multiple views if True
   */
  @Input() multiSelect = false;

  /**
   * Allow view selection with different SD as well. Disabled by default.
   */
  @Input() allowMultiStorageDomainView = false;

  /**
   * In case of Helios pass through calls, this binding specifies the cluster
   * ID from which the views should be fetched.
   */
  @Input() accessClusterId: number;

  /**
   * Selection event emitter.
   */
  @Output() selectViewChange = new EventEmitter<any>();

  /**
   * Stores the selected views.
   */
  selection: SelectionModel<View>;

  /**
   * Views table column names.
   */
  readonly columns = [
    'name',
    'storageDomainName',
  ];

  /**
   * Views data for the table.
   */
  views: View[] = [];

  /**
   * Indicates whether we are waiting for response from get Views API.
   */
  loading$ = new BehaviorSubject(false);

  /**
   * Holds the reference of the filter component.
   */
  @ViewChild(FiltersComponent, { static: true }) filters: FiltersComponent;


  /** Table instance */
  @ViewChild(TableComponent, { static: false }) viewsTable: TableComponent<any>;


  /**
   * Observable of storage domain filter.
   */
  storageDomainFilterList$: Observable<ValueFilterSelection[]>;

  /**
   * Observable of organization filter.
   */
  organizationFilterList$: Observable<ValueFilterSelection[]>;

  constructor(
    private ajsClusterService: AjsClusterService,
    private irisCtx: IrisContextService,
    private storageDomainService: StorageDomainServiceApi,
    private tenantService: TenantServiceApi,
    private translate: TranslateService,
    public viewSelectionDataSource: ViewSelectionDataSource,
    private viewService: ViewServiceApi,
  ) {
    super();
    this.multiTenancyEnabled = this.ajsClusterService.clusterInfo.multiTenancyEnabled;
  }

  ngOnInit() {
    // Instantiate selection model based on multiSelect property
    this.selection =  new KeyedSelectionModel<View>((value) => value.viewId, this.multiSelect, []);

    // listen on selection change.
    // Emit the new selection event to parent component.
    this.selection.changed.subscribe(value => {
      // Add the view checked by user.
      value.added.forEach(newView => {
        if (!this.selectedViews.some(view => view.viewId === newView.viewId)) {
          this.selectedViews.push(newView);
        }
      });

      // Remove the views unchecked by user.
      value.removed.forEach(removeView => this.selectedViews.splice(
        this.selectedViews.findIndex(view => view.viewId === removeView.viewId), 1)
      );

      this.selectViewChange.emit(this.selectedViews);
      this.setStorageDomainFilter();
    });

    // Get all available filters.
    this.initFilterSettings();

    // Get all views.
    this.getViews();

    // Binding could pass undefined. Initialize this for safety.
    this.selectedViews = this.selectedViews || [];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedView || changes.selectedViews) {
      this.renderSelection();
    }
  }

  /**
   * When page load, call GetViewBoxes and GetTenants API to load all viewboxes and tenants.
   * These values are used to construct this.storageDomainFilterList and this.organizationFilterList.
   */
  private initFilterSettings() {

    // init storage domain filter list.
    this.storageDomainFilterList$ = this.storageDomainService.GetStorageDomains({
      includeTenants: true,
      ...(this.accessClusterId ? { accessClusterId: this.accessClusterId } : {})
    })
    .pipe(
      shareReplay(1),
      map(rst => rst.storageDomains.filter(viewbox => !viewbox.directArchiveEnabled).map(viewbox => ({
        label: viewbox.name,
        value: viewbox.id,
      }))),
      tap((response: any[]) => {
        if (response.length) {
          if (this.selectedViews.length) {
            const selectedViewBoxId = this.selectedViews[0].storageDomainId;
            const selectedViewBoxIdInResponse = response.filter(
              viewBox => viewBox.value === selectedViewBoxId);
            if (selectedViewBoxIdInResponse.length) {
              this.filters.setValue('storageDomain', [{
                label: selectedViewBoxIdInResponse[0].label,
                value: selectedViewBoxIdInResponse[0].value,
              }]);
            }
          } else {
            // Set the first Storage Domain as selected in the filter if not in edit mode.
            this.filters.setValue('storageDomain', [{
              label: response[0].label,
              value: response[0].value,
            }]);
          }
        }
      })
    );

    // init organization filter list.
    if (this.multiTenancyEnabled) {
      this.organizationFilterList$ = this.tenantService.GetTenants({})
      .pipe(
        shareReplay(1),
        map(rst => rst.map(org => ({
          label: org.name,
          value: org.tenantId,
        }))),
      );
    }
  }

  /**
   * Load views with filters.
   */
  getViews() {
    this.filters.filterValues$.pipe(
      debounceTime(300),
      tap(() => this.loading$.next(true)),
      map(filter => {
        // generate filter params
        const viewNames = this.generateFilterParam(filter, 'name') as string[];
        const appliedStorageDomainFilters = this.generateFilterParam(filter, 'storageDomain') as number[];
        const appliedOrganizationFilters = this.generateFilterParam(filter, 'organization') as string[];

        const viewParams: ViewServiceApi.GetViewsParams = {
          viewNames: viewNames,
          storageDomainIds: appliedStorageDomainFilters,
          tenantIds: appliedOrganizationFilters,
          matchPartialNames: Boolean(viewNames.length),
          includeInactive: flagEnabled(this.irisCtx.irisContext, 'showDRViewsInViewsLandingPage'),
          maxCount: 2000,
          includeProtectionGroups: true,
          includeStats: false,
          includeTenants: false,
          ...(this.accessClusterId ? { accessClusterId: this.accessClusterId } : {}),
        };

        return viewParams;
      }),
      switchMap(viewParams => this.viewService.GetViews(viewParams)),
      map(views => get(views, 'views') ? views.views : []),
      tap({
        next: () => this.loading$.next(false),
        error: e => {
          this.loading$.next(false);
          return throwError(e);
        }
      }),
      this.untilDestroy(),
    ).subscribe(views => {
      this.views = views;
      this.renderSelection();
    });
  }

  /**
   * Private function to generate StatsService.GetViewBoxStatsParams.
   *
   * @param   selectedFilters   applied filters.
   * @param   filterType   Filter type. Possible values are 'storageDomain', 'organization' or 'name'.
   *
   * @return  viewbox id list, or organization name list, or view name list.
   */
  private generateFilterParam(selectedFilters: DataFilterValue<any, any>[], filterType: string): (string | number)[] {
    const filters: (string | number)[] = [];
    const index = selectedFilters.findIndex(filter => filter.key === filterType);

    if (index > -1) {
      // For view name filter, selectedFilters[index].value is a single string,
      // Just push it into filters.
      if (filterType === 'name') {
        filters.push(selectedFilters[index].value);
      } else {
        // For storage domain and organization filter, selectedFilters[index].value is an array
        // Need to loop through the whole list and push each item into filters.
        selectedFilters[index].value.forEach((filterItem) => filters.push(filterItem.value));
      }
    }

    return filters;
  }

  /**
   * For multiselect case, apply storageDomain filter as soon as a view is selected
   * to avoid view selection across storage domains
   */
  private setStorageDomainFilter() {
    if (this.multiSelect && !this.allowMultiStorageDomainView) {
      const selected = this.selection.selected;

      // Only apply the filter for first selection
      if (selected.length === 1) {
        this.filters.setValue('storageDomain', [{
          label: selected[0].storageDomainName,
          value: selected[0].storageDomainId,
        }]);

        this.filters.lockFilter('storageDomain', this.translate.instant('recovery.lockedFilter'));

      // clear the filter when all selections are removed
      } else if (selected.length === 0) {
        this.filters.setValue('storageDomain', []);
        this.filters.unlockFilter('storageDomain');
      }
    }
  }

  /**
   * Listen on view table rendering, and update selected item.
   *
   * @param   views   Views in the table.
   */
  renderSelection() {
    if (this.selectedView) {
      const seletedView = this.views.find((view: View) => view.viewId === this.selectedView.viewId);
      this.selection.select(seletedView);
    }

    if (this.selectedViews?.length && this.views.length) {
      const selectedViewIds = this.selectedViews.map(view => view.viewId);
      const selectedViews = this.views.filter((view: View) => selectedViewIds.includes(view.viewId));

      // Create a union of the previously selected views and the new views,
      // then remove duplicates.
      this.selectedViews = uniqBy(this.selectedViews.concat(...selectedViews),
        view => view.viewId);

      selectedViews.forEach((selectedView) => {
        this.selection.select(selectedView);
      });
      this.selectViewChange.emit(this.selectedViews);
    }
  }

  /**
   * Determines if all rows are selected. This ignores rows which are not selectable.
   * This method is defined as a property so that the this value will be bound
   * properly when passed as an input to the table component.
   *
   * @return   whether all possible rows have been selected.
   */
  isAllSelected: IsAllSelectedFn = () => !this.viewsTable.renderedData.some(row => !this.selection.isSelected(row));

  /**
   * Handles the select all button. Only selectable rows should be toggled on or off.
   * This method is defined as a property so that the this value will be bound
   * properly when passed as an input to the table component.
   *
   * @param   event   Material checkbox event.
   */
  toggleAllSelection: ToggleSelectAllFn = (event: MatCheckboxChange) => {
    if (!event.checked) {
      this.selection.deselect(...this.viewsTable.renderedData);
    } else {
      this.viewsTable.renderedData.forEach(row => this.selection.select(row));
    }
  };
}
