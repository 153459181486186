<p>
  {{isRegistered
    ? (isConnected | boolLabel: 'heliosConnected': 'heliosNotConnected')
    : 'heliosNotConfigured' | translate}}
</p>

<a #heliosLink
  (click)="close()"
  cogDataId="helios-status-link"
  cohStopPropagation
  tabindex="1"
  uiSref="helios">
  {{(isRegistered ? 'manageConnection' : 'enableHelios') | translate}}
</a>

<button (click)="close()"
  [matTooltip]="'close' | translate"
  cogDataId="helios-status-close"
  cohStopPropagation
  mat-icon-button
  tabindex="1"
  type="button">
  <cog-icon shape="close" size="sm"></cog-icon>
</button>
