import { Injectable } from '@angular/core';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { Transition } from '@uirouter/core';
import { filter, map } from 'rxjs/operators';
import { LoginBannerComponent } from 'src/app/shared/login-banner/login-banner.component';

import { DialogService, UserService } from '../../services';
import { GuardPriority, GuardResult, StateGuard } from '../state-guard';

@Injectable({
  providedIn: 'root'
})
export class BannerGuard implements StateGuard {

  /**
   * The guard priority for the State.
   */
  guardPriority = GuardPriority.Banner;

  /**
   * Match for all the incoming states except login and change-password.
   */
  matchCriteria: any = {
    to: 'login',
  };

  /**
   * constructor
   */
  constructor(
    private dialogService: DialogService,
    private irisCtx: IrisContextService,
    private userService: UserService,
  ) {}

  /**
   * OnStart of UI Router State Transition
   */
  onStart(transition: Transition): GuardResult {
    const ctx = this.irisCtx.irisContext;

    /**
     * These are the cases to be handled.
     * 1. Login Banner, needs to be handled both in UI and backend.
     * 2. Banner Accepted in the local Storage. Should be false (Refreshing should not bring
     *    out the modal again.)
     * 3. Show the dialog, if the user accept then proceed with transition or forbidden.
     */
    if (['forbidden', 'logout', 'logout-ng'].includes(transition.to().name)) {
      return true;
    }

    // If no login banner defined from backend, no need to check feature flag loginBannerSkippedAndRedirectSso
    // to skip dialog and redirect directly.
    if (!(flagEnabled(ctx, 'loginBanner') && this.irisCtx.irisContext.basicClusterInfo.bannerEnabled)) {
      return this.redirect();
    }

    if (flagEnabled(ctx, 'loginBannerSkippedAndRedirectSso')) {
      return this.redirect();
    }

    return this.dialogService.showDialog(LoginBannerComponent).pipe(
      filter(result => result !== undefined),
      map((acceptedStatus: boolean) => {
        if (acceptedStatus) {
          return this.redirect();
        }
        return transition.router.stateService.target('forbidden');
      })
    );
  }

  /**
   * Redirect to SSO page.
   *
   * @returns True if redirect.
   */
  redirect(): boolean {
    // Check for special IDP/sso case, in which user should be immediately
    // redirect to the SSO login page.
    const ctx = this.irisCtx.irisContext;

    if (ctx.basicClusterInfo?.idpConfigured &&
      flagEnabled(ctx, 'idpEnabled') &&
      flagEnabled(ctx, 'autoSsoRedirect')) {
      this.userService.authenticateIDP();

      // Prevent the transition as the above function call will trigger
      // a browser redirect to the SSO url.
      return false;
    }
    return true;
  }
}
