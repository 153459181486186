import { Component, Input } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Environment } from '@cohesity/api/v2';
import { Controls, NgxSubFormComponent, subformComponentProviders } from 'ngx-sub-form';

import { JobStreamConcurrencyMap, JobType } from '../../constants';
import { Concurrency } from './concurrency-form.model';

/**
 * Allows adapters to specify concurrency per stream for a job.
 */
@Component({
  selector: 'coh-settings-list-concurrency',
  templateUrl: './settings-list-concurrency.component.html',
  providers: subformComponentProviders(SettingsListConcurrencyComponent)
})
export class SettingsListConcurrencyComponent extends NgxSubFormComponent<Concurrency> {

  /**
   * Environment type for the backup/recovery job.
   */
  @Input() environment?: Environment;

  /**
   * Returns job type backup/recovery.
   */
  @Input() jobType?: JobType = 'Recovery';

  getDefaultValues(): Partial<Concurrency> {
    return {
      value: this.environment ? JobStreamConcurrencyMap[this.environment as string] : null
    };
  }

  /**
   * Returns a collection of form controls for the subform template.
   */
  protected getFormControls(): Controls<Concurrency> {
    return {
      value: new UntypedFormControl(null, Validators.min(0))
    };
  }
}
