import { Component, Input } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ViewProtocol } from '@cohesity/api/v2';
import { CreateForm, createFormProviders } from '@cohesity/shared-forms';

import { CreateViewBasicForm } from '../../../models';
import { CreateViewFormService, ViewsService } from '../../../services';

/**
 * Internal form interface.
 */
interface ViewNameForm {
  name: CreateViewBasicForm['name'];
}

@Component({
  selector: 'coh-create-view-name',
  templateUrl: './view-name.component.html',
  providers: createFormProviders(ViewNameComponent),
})
export class ViewNameComponent {
  /**
   * The selected protocols
   */
  @Input() selectedProtocols?: ViewProtocol[];

  /**
   * Whether the name is used for template.
   */
  isTemplateMode = this.createViewFormService.isTemplateMode;

  /**
   * Initialize the form.
   */
  form = new CreateForm<ViewNameForm['name'], ViewNameForm>(this, {
    formControls: {
      name: new UntypedFormControl(null, Validators.required),
    },
    transformFromFormGroup: () => (this.form.formGroup.value.name || '').trim(),
    transformToFormGroup: name => ({ name }),
  });

  /**
   * Validate view name according to the protocols selected.
   */
  get viewNameWarnings() {
    return this.viewsService.getViewNameWarnings(this.form.value, this.selectedProtocols ?? []);
  }

  constructor(private createViewFormService: CreateViewFormService, private viewsService: ViewsService) {}
}
