import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RangePipeModule } from '@cohesity/data-govern/shared';
import { BannerModule, IconModule } from '@cohesity/helix';
import { IrisRouterModule } from '@cohesity/iris-core';
import { TranslateModule } from '@ngx-translate/core';

import { ScanRunStatusModule } from '../scan-run-status/scan-run-status.module';
import { DcScanRunStatsComponent } from './dc-scan-run-stats.component';


@NgModule({
  declarations: [
    DcScanRunStatsComponent
  ],
  imports: [
    BannerModule,
    CommonModule,
    IconModule,
    IrisRouterModule,
    RangePipeModule,
    ScanRunStatusModule,
    TranslateModule,
  ],
  exports: [
    DcScanRunStatsComponent
  ]
})
export class DcScanRunStatsModule { }
