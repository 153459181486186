import { Injectable } from '@angular/core';
import { DmaasRegion, DmaasRegions, DmsServiceApi } from '@cohesity/api/dms';
import { McmClusterConnectionStatus, McmClusterServiceApi } from '@cohesity/api/private';
import { FortknoxServiceApi, RpaasRegionInfo, RpaasServiceApi } from '@cohesity/api/v2';
import {
  awsColdStorageEntitlement,
  awsWarmStorageEntitlement,
  azureColdStorageEntitlement,
  azureHotStorageEntitlement,
  flagEnabled,
  IrisContextService,
} from '@cohesity/iris-core';
import { AsyncBehaviorSubject, updateWithStatus } from '@cohesity/utils';
import { isEqual } from 'lodash';
import { Observable } from 'rxjs';
import { delay, distinctUntilChanged, filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';

import { FortknoxStorageClass, vaultProvisionInProgressStatuses } from '../../constants';

@Injectable()
export class RpaasStateService {

  /**
   * Async behavior subject to track the cluster connection stats
   */
  private clustersListSubject = new AsyncBehaviorSubject<McmClusterConnectionStatus[]>();

  /**
   * Rpaas Regions subject
   */
  private rpaasRegionsSubject = new AsyncBehaviorSubject<RpaasRegionInfo[]>();

  /**
   * List of dmaas regions, filtered by which feature flags are currently enabled.
   */
  private dmsRegionsSubject = new AsyncBehaviorSubject<DmaasRegion[]>();

  /**
   * Clusters List Observable
   */
  clustersList$ = this.clustersListSubject.asObservable();

  /**
   * A map of cluster ids to cluster connection status values.
   */
  clusterMap$: Observable<Map<string, McmClusterConnectionStatus>> = this.clustersListSubject.pipe(
    filter(apiCall => !apiCall.loading && !!apiCall.result),
    map(apiCall => apiCall.result.reduce((allClusters, cluster) => {
      allClusters.set(`${cluster.clusterId}:${cluster.clusterIncarnationId}`, cluster);
      return allClusters;
    }, new Map<string, McmClusterConnectionStatus>()))
  );

  /**
   * Rpaas Regions State Observable
   */
  rpaasRegions$ = this.rpaasRegionsSubject.pipe(distinctUntilChanged((a, b) => isEqual(a,b)));

  /**
   * A map of rpaas region ids to cluster to region info values
   */
  rpaasRegionMap$: Observable<Map<string, RpaasRegionInfo>> = this.rpaasRegions$.pipe(
    filter(apiCall => !apiCall.loading && !!apiCall.result),
    map(apiCall => apiCall.result.reduce((allRegions, region) => {
      allRegions.set(region.globalVaultId, region);
      allRegions.set(region.vaultName, region);
      return allRegions;
    }, new Map<string, RpaasRegionInfo>()))
  );

  /**
   * List of dmaas regions, filtered by which feature flags are currently enabled.
   */
  dmsRegions$ = this.dmsRegionsSubject.asObservable();

  /**
   * Backup windows based on regions.
   */
  readonly backupWindows$ = this.rpaasRegions$.pipe(
    filter(regions => !!regions.result),
    switchMap(regions =>
      this.fortknoxService.GetVaultTransferTimeConfig(null, regions.result.map(region => region.globalVaultId))
    ),
    shareReplay(1)
  );

  constructor(
    private clustersApi: McmClusterServiceApi,
    private fortknoxService: FortknoxServiceApi,
    private rpaasService: RpaasServiceApi,
    private dmsService: DmsServiceApi,
    private irisContext: IrisContextService,
  ) {}

  /**
   * Fetches registered clusters
   *
   * @parm   allowCached  Don't trigger an api call if we already have this dat
   */
  fetchClusterList(allowCached = false) {
    if ((allowCached && !!this.clustersListSubject.value?.result) || this.clustersListSubject.value?.loading) {
      return;
    }
    this.clustersApi.getClustersConnectionStatus().pipe(updateWithStatus(this.clustersListSubject)).subscribe();
  }

  /**
   * Fetches configured rpaas regions
   *
   * @param  skipLoading  If the fetch is happening in the background, while polling fo a provisioning status update,
   *                      we should skip the loading flag on the state so that the screen doesn't continue flash to a
   *                      loading state.
   * @parm   allowCached  Don't trigger an api call if we already have this dat
   */
  fetchRpaasRegions(skipLoading = false, allowCached = false) {
    if ((allowCached && !!this.rpaasRegionsSubject.value?.result) || this.rpaasRegionsSubject.value?.loading) {
      return;
    }

    if (flagEnabled(this.irisContext.irisContext, 'rpaasVaultsProvisionApi')) {
      this.fortknoxService.GetFortknoxVaults()
        .pipe(
          map(res => res?.fortknoxVaultInfoList.map(vaultInfo => ({
            globalVaultId: vaultInfo.globalVaultId,
            pairingInfo: vaultInfo.clusterPairingInfo,
            provisionStatus: vaultInfo.provisionStatus,
            regionId: vaultInfo.azureParams?.regionId || vaultInfo.awsParams?.regionId,
            regionProvisionType: vaultInfo.kmsKeyType,
            storageClass: (vaultInfo.azureParams?.vaultParams?.storageClass ||
              vaultInfo.awsParams?.vaultParams?.storageClass) as FortknoxStorageClass,
            vaultName: vaultInfo.vaultName,
          } as RpaasRegionInfo)) || []),
          updateWithStatus(this.rpaasRegionsSubject, false, skipLoading),
        )
        .subscribe();
    } else {
      this.rpaasService.GetFortKnoxVaults()
        .pipe(
          map(res => res?.vaults || []),
          tap(regions => regions.forEach(region => {
            if (!region.vaultName && !flagEnabled(this.irisContext.irisContext, 'rpaasMultiVaultRegions')) {
              region.vaultName = `${region.regionId}-vault`;
            }
          })),
          updateWithStatus(this.rpaasRegionsSubject, false, skipLoading),
        )
        .subscribe();
    }

  }

  /**
   * This creates an observable that will poll for updates whenever there are vaults in the process of being
   * provisioned
   *
   * @params  pollTime  The amount of time to wait before fetching the list again.
   * @returns An obserbable - as long as this is subscribed to, the polling will continue.
   */
  pollProvisioningRegions(pollTime = 5000): Observable<void> {
    return this.rpaasRegionsSubject
      .pipe(
        filter(state => !state.loading && !state.error && !!state.result),
        map(state => state.result),
        distinctUntilChanged(),
        filter(regions =>
          !!regions.find(r => vaultProvisionInProgressStatuses.includes(r.provisionStatus?.status)) ||
          !!regions.find(r => r.pairingInfo?.some(pairing => pairing.pairingStatus?.status === 'InProgress')),
        ),
        delay(pollTime),
        tap(() => this.fetchRpaasRegions(true)),
        map(() => undefined)
      );
  }

  /**
   * Returns regions applicable to FortKnox setup.
   *
   * @param regions Regions API response
   * @returns Filtered list applicable to FortKnox setup.
   */
  private filterRegions(regions: DmaasRegions): DmaasRegion[] {
    const { irisContext } = this.irisContext;
    const awsWarmVaultActive = awsWarmStorageEntitlement(irisContext)?.isActive;
    const awsColdVaultActive = awsColdStorageEntitlement(irisContext)?.isActive;
    const azureColdActive = azureColdStorageEntitlement(irisContext)?.isActive;
    const azureHotActive = azureHotStorageEntitlement(irisContext)?.isActive;

    return regions?.regions?.filter(region => {
      if (region.type === 'Aws' && (awsWarmVaultActive || awsColdVaultActive)) {
        return flagEnabled(irisContext, `dmsAwsRegion-${region.id}`);
      } else if (region.type === 'Azure' && (azureColdActive || azureHotActive)) {
        return flagEnabled(irisContext, 'rpaasVaultsProvisionApi') &&
          flagEnabled(irisContext, 'rpaasAzureVaultSetup') &&
          flagEnabled(irisContext, `dmsAzureRegion-${region.id}`);
      }
    }) ?? [];
  }

  /**
   * Looks up available dms regions, filtered by which flags are enabled.
   */
  fetchDmsRegions() {
    this.dmsService.GetRegions()
      .pipe(
        map(resp => this.filterRegions(resp)),
        take(1),
        updateWithStatus(this.dmsRegionsSubject)
      )
      .subscribe();
  }
}
