import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BaseRendererComponent } from '../base-renderer.component';

/**
 * Renders a simple bytes value.
 */
@Component({
  selector: 'cog-bytes-data-renderer',
  template: '{{ (value || 0) | byteSize }}',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BytesDataRendererComponent extends BaseRendererComponent<number> {
}
