import { COMMA, SPACE } from '@angular/cdk/keycodes';
import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';

/**
 * Data model for email chip object.
 */
export interface EmailChip {
  /**
   * Email address.
   */
  value: string;

  /**
   * Whether the email pass validation.
   */
  error?: boolean;
}

/**
 * This component allows user to input multiple emails and display them as chip.
 */
@Component({
  selector: 'coh-email-chip-input',
  styleUrls: ['./email-chip-input.component.scss'],
  templateUrl: './email-chip-input.component.html'
})
export class EmailChipInputComponent {
  /**
   * Form control for email input.
   */
  @Input() control: UntypedFormControl;

  /**
   * Requires at least 1 email if true.
   */
  @Input() required = false;

  /**
   * Represent which key codes are used to separate inputs.
   */
  separatorKeysCodes: number[] = [COMMA, SPACE];

  /**
   * Add user input email as chip and validate the format.
   *
   * @param event  Mat-chip event object.
   */
  add(event: MatChipInputEvent) {
    const { input, value } = event;

    if ((value || '').trim()) {
      const valid = !Validators.email({ value } as AbstractControl);
      this.control.value.push({value: value.trim(), error: !valid});
      if (!valid) {
        this.control.setErrors({email: true});
      }
    }

    if (input) {
      input.value = '';
    }
  }

  /**
   * Removes email chip.
   *
   * @param index  Index of chip in the list.
   */
  remove(index: number) {
    let valid = true;

    this.control.value.splice(index, 1);
    this.control.value.forEach(email => {
      if (email.error) {
        valid = false;
      }
    });
    if (valid) {
      this.control.setErrors(this.control.value.length || !this.required ? null : {required: true});
    }
  }
}
