<cog-value-property-filter *cogFilterDef="let filter;
    let params = params;
    quickFilter true;
    key: property;
    label: 'storageDomain' | translate;
    filterType: 'valueProperty';
    position: position"
  [class.hidden]="hideFilter || isDmsUser"
  [filterDefParams]="params"
  [filter]="filter"
  [allowMultiple]="true"
  [filterValues]="filterOptions$ | async">
</cog-value-property-filter>
