import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { AffectedFile, FileChangeType } from '@cohesity/api/argus';
import { Api } from '@cohesity/api/private';
import { DataSearchResult } from '@cohesity/helix';
import { camelCase, isEmpty } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { AffectedFilesFilterArguments, RegularFileOperation } from '../security-shared.models';
import { SnapshotInfo } from './../security-shared.models';
import { FileDataSource, RegularFileSearchFilter } from './file-data-source';

/**
 * Data source implementation for the files search table.
 */
@Injectable()
export class RegularFileDataSource extends FileDataSource {
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Executes file search
   *
   * @param   filter    The currently applied filter.
   * @param   page      The current page info.
   * @param   sort      Sort info - currently not enabled in the table and ignored here.
   * @return  An observable with the data search results.
   */
  getData(
    filter: RegularFileSearchFilter,
    page: PageEvent,
    _sort: Sort | null | void
  ): Observable<DataSearchResult<any>> {
    if (!filter) {
      return of(undefined);
    }

    this.loadingData$.next(true);
    this.hasData$.next(false);

    const { pageSize, pageIndex } = page;
    const { clusterId, jobId, snapshot1TimeUsecs, snapshot2TimeUsecs } = filter;

    return this.getAffectedFiles({
      clusterId,
      jobId,
      snapshot1TimeUsecs,
      snapshot2TimeUsecs,
      pageCount: pageSize,
      pageNumber: pageIndex,
    }).pipe(
      finalize(() => this.loadingData$.next(false)),
      map((response: SnapshotInfo) => {
        // Handle empty response
        if (isEmpty(response) || response.numPages === 0) {
          return this.emptyDataSet();
        } else {
          this.hasData$.next(true);
          return {
            totalSize: response.numPages * response.results.length,
            data: this.transformAffectedFiles(response?.results || []),
          };
        }
      }),
      catchError(() =>
        /*
         * No need to show errors, since the API is available 6.3 onwards.
         * So return undefined and handle it in template.
         */
        this.emptyDataSet()
      )
    );
  }

  /**
   * Gets list of affected files.
   *
   * @param params   contains objects required for web service
   */
  getAffectedFiles(params: any): Observable<SnapshotInfo> {
    const httpParams = new HttpParams()
      .set('jobId', params.jobId.toString())
      .set('snapshot1TimeUsecs', params.snapshot1TimeUsecs)
      .set('snapshot2TimeUsecs', params.snapshot2TimeUsecs)
      .set('pageCount', params.pageCount)
      .set('pageNumber', params.pageNumber);

    const url = Api.public('snapshots/changelog');
    return this.http.get(url, {
      headers: { clusterId: params.clusterId.toString() },
      params: httpParams,
    });
  }

  /**
   * Sets required filter data based on table source
   *
   * @param filters  anomaly and classification info
   */
  setFilters(filters: AffectedFilesFilterArguments) {
    const { clusterId, properties } = filters.anomaly;

    this.filter = {
      jobId: +properties.jobId,
      snapshot1TimeUsecs: +properties.jobStartTimeUsecs,
      snapshot2TimeUsecs: +filters.selectedPoint.x,
      clusterId,
    };
  }

  /**
   * Converts different file objects to common AffectedFile
   *
   * @param fileOperation change type file object
   * @returns converted file object
   */
  transformAffectedFiles(fileOperations: RegularFileOperation[]): AffectedFile[] {
    return fileOperations.map(fileOperation => {
      let changeType: FileChangeType;
      switch (camelCase(fileOperation.operation)) {
        case 'kDeleted':
          changeType = FileChangeType.KDeleted;
          break;

        case 'kAdded':
        case 'kModified':
          changeType = FileChangeType.KAddedOrModified;
          break;

        default:
          changeType = FileChangeType.KAny;
      }

      return {
        changeType,
        filePath: fileOperation.filename,
      };
    });
  }

  /**
   * Emit observable and return undefined.
   */
  emptyDataSet() {
    this.hasData$.next(false);
    return of(undefined);
  }

  /**
   * Resets all essential data when component is destroyed
   */
  cleanUp() {
    this.hasData$.next(false);
  }
}
