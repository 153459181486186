import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { Environment } from '@cohesity/iris-shared-constants';

import { RecoveryTaskService } from '../../services';

/**
 * The default recovery setting dialog input.
 */
export interface DefaultRecoverySettingsDialogInput {
  environments?: Environment[];
}

/**
 * Dialog to show default settings information before user proceeds with recovery
 */
@Component({
  templateUrl: './default-recovery-settings.component.html',
  styleUrls: ['./default-recovery-settings.component.scss'],
})
export class DefaultRecoverySettingsComponent {
  // Default Recovery settings labels and values
  readonly defaultRecoverySettings = this.recoveryTaskService.getRecoverySettings()
    .filter(({ environment }) =>
      flagEnabled(this.irisCtx.irisContext, 'dataHawkAntiRansomwareRecoveryDialogToShowAllEnvironmentEnabled') ?
        true :
        (this.data?.environments?.includes(environment) ?? true)
    );

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: DefaultRecoverySettingsDialogInput,
    private recoveryTaskService: RecoveryTaskService,
    private irisCtx: IrisContextService,
  ) { }
}
