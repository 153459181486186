import { IntegrationStatus } from '../../integration-configs';

/**
 * List of integration statuses.
 */
export const IntegrationStatuses: IntegrationStatus[] = [
  IntegrationStatus.Active,
  IntegrationStatus.Error,
  IntegrationStatus.Paused,
];

/**
 * Type for integration status icon map.
 */
export type IntegrationStatusToIconMapType = Record<IntegrationStatus, string>;

/**
 * Map of integration status and icons.
 */
export const IntegrationStatusToIconMap: IntegrationStatusToIconMapType = Object.freeze({
  [IntegrationStatus.Active]: 'helix:status-success!success',
  [IntegrationStatus.Error]: 'helix:status-error!critical',
  [IntegrationStatus.NotStarted]: 'helix:status-error!critical',
  [IntegrationStatus.Paused]: 'pause_circle_outline!warning',
  [IntegrationStatus.SyncInProgress]: 'helix:status-in-progress',
});
