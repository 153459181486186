import { HeliosSaasMfa } from '@cohesity/api/v2';
import { IrisContext } from '@cohesity/iris-core';

/**
 * Function to return the timestamp of when the grace period will end.
 *
 * @param irisContext The current iris context.
 * @return The grace period timestamp.
 */
export function getHeliosMfaGracePeriod(irisContext: IrisContext): number {
  return irisContext.mfaPreferences?.helios?.heliosSaasConfig?.gracePeriodEndTimeUsecs;
}

/**
 * Function to return the number of days left in the grace period.
 *
 * @param irisContext The current iris context.
 * @return The grace period days.
 */
export function getHeliosMfaGracePeriodDays(irisContext: IrisContext): number {
  const heliosMfaGracePeriod = getHeliosMfaGracePeriod(irisContext);

  if (!heliosMfaGracePeriod) {
    return;
  }

  // Get the number of days until an MFA decision has to be made.
  return Math.floor((heliosMfaGracePeriod / 1000 - Date.now()) / (24 * 60 * 60 * 1000));
}

/**
 * Function to return the status of Helios MFA.
 *
 * @param irisContext The current iris context.
 * @return The MFA status.
 */
export function getHeliosMfaStatus(irisContext: IrisContext): HeliosSaasMfa['mfaStatus'] {
  return irisContext.mfaPreferences?.helios?.heliosSaasConfig?.mfaStatus;
}

/**
 * Function to return whether the grace period for MFA has expired.
 *
 * @param irisContext The current iris context.
 * @return True if grace period is expired.
 */
export function heliosMfaGracePeriodExpired(irisContext: IrisContext): boolean {
  if (getHeliosMfaStatus(irisContext) !== 'Pending') {
    return false;
  }

  return Date.now() > getHeliosMfaGracePeriod(irisContext) / 1000;
}
