import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RbacModule } from '@cohesity/data-govern/shared';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { HumanizeFromNumbersPipeModule, UtilPipesModule } from '@cohesity/utils';
import { TranslateModule } from '@ngx-translate/core';

import { InlineFileDataSource, RegularFileDataSource } from '../../file-data-source';
import { ChangeTypeFilterModule, PatternFilterModule } from '../../filters';
import { ChangeTypeStatsModule } from '../change-type-stats';
import { SnapshotIndicatorModule } from '../snapshot-indicator';
import { AffectedFilesComponent } from './affected-files.component';

const COMPONENTS = [AffectedFilesComponent];

@NgModule({
  imports: [
    ChangeTypeFilterModule,
    ChangeTypeStatsModule,
    CohesityHelixModule,
    CommonModule,
    HumanizeFromNumbersPipeModule,
    PatternFilterModule,
    RbacModule,
    SnapshotIndicatorModule,
    TranslateModule,
    UtilPipesModule,
  ],
  providers: [InlineFileDataSource, RegularFileDataSource],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
})
export class AffectedFilesModule {}
