import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { ArchivalConfig, DataLockConfig } from '@cohesity/api/v2';
import { ObservableInput } from 'ngx-observable-input';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PolicyUtils } from 'src/app/modules/policy-shared/protection-policy-utils';
import { AutoDestroyable, TimePeriodSelectorForm, TypedFormGroup } from 'src/app/shared';
import { PolicyResponse } from 'src/app/shared/policy';

import { ArchivalTargetType, BaseTargetForm, GroupActionArchiveFormModel, ProtectionGroup, RetentionUnit } from '../../../models';

/**
 * Group Action Archive From section populated from policy information.
 */
export class GroupActionArchiveForm extends TypedFormGroup<GroupActionArchiveFormModel> implements BaseTargetForm {
  /**
   * Archive target name.
   */
  get targetName(): string {
    return this.get('target')?.value?.vaultName;
  }

  /**
   * Archive target ID.
   */
  get targetId(): number {
    return this.get('target')?.value?.vaultId;
  }

  /**
   * Target selector control.
   */
  get targetControl(): AbstractControl {
    return this.get('target');
  }

  /**
   * Retention units like 'Week', 'Day', 'Month'.
   */
  get retentionUnit(): RetentionUnit {
    return this.get('retention')?.value?.type;
  }

  /**
   * Retention duration number.
   */
  get retentionDuration(): number {
    return this.get('retention')?.value?.value;
  }

  /**
   * Archival retention form group.
   */
  get retentionForm(): TimePeriodSelectorForm {
    return this.get('retention') as TimePeriodSelectorForm;
  }

  /**
   * True if this archive form is enabled and its values should be submitted.
   */
  get enabled(): boolean {
    return this.get('enabled')?.value;
  }

  /**
   * Archival target type.
   */
  readonly targetType: ArchivalTargetType;

  constructor(initialValue: GroupActionArchiveFormModel) {
    super({
      enabled: new UntypedFormControl(initialValue?.enabled),
      onlySuccessful: new UntypedFormControl(initialValue?.onlySuccessful),
      retention: new TimePeriodSelectorForm(initialValue?.retention, PolicyUtils.retentionTypeOptions),
      target: new UntypedFormControl(initialValue?.target, Validators.required),
    });

    this.targetType = initialValue?.target?.targetType;

    this.markAsTouched();
  }
}

/**
 * Group Action Archive Form.
 */
export class GroupActionArchiveFormArray extends UntypedFormArray {

  constructor(archivalTargets: ArchivalConfig[]) {
    super(archivalTargets.map(archivalTarget =>
      new GroupActionArchiveForm({
        enabled: true,
        onlySuccessful: true,
        retention: {
          value: archivalTarget.retention.duration,
          type: archivalTarget.retention.unit,
        },
        target: {
          vaultName: archivalTarget.targetName,
          vaultId: archivalTarget.targetId,
          targetType: archivalTarget.targetType,
        },
      })
    ));
  }
}

/**
 * @description
 *
 * Group action modal archive form.
 */
@Component({
  selector: 'coh-group-archive-form',
  templateUrl: './group-archive-form.component.html',
  styleUrls: ['./group-archive-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class GroupArchiveFormComponent extends AutoDestroyable implements OnInit {
  /**
   * Protection group instance.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('protectionGroup') protectionGroup$: Observable<ProtectionGroup>;

  /**
   * Protection group policy data.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('policy') policy$: Observable<PolicyResponse>;

  /**
   * Group Action Archive form group.
   */
  @Input() formGroup: GroupActionArchiveForm;

  /**
   * BehaviorSubject that stores the dataLock config of the current target.
   */
  readonly dataLockConfig$ = new BehaviorSubject<DataLockConfig>(null);

  constructor() {
    super();
  }

  ngOnInit() {
    combineLatest([
      this.formGroup?.targetControl.valueChanges,
      this.policy$,
    ]).pipe(
      this.untilDestroy(),
      map(([currentTarget, policy]) => {
        const archivalTargets = (policy.remoteTargetPolicy?.archivalTargets ?? []) as ArchivalConfig[];
        return archivalTargets.find(target => target.targetId === currentTarget?.vaultId);
      }),
    ).subscribe(target => this.dataLockConfig$.next(target?.retention?.dataLockConfig));
  }
}
