<div [formGroup]="form">
  <div class="schedule-selector-row" [class.schedule-selector-full-width]="fullWidth">
    <mat-form-field>
      <mat-label>{{ 'every' | translate }}</mat-label>
      <mat-select
        [formControl]="form.get('every')"
        cogDataId="every-select"
        [inheritDataId]="true">
        <mat-option
          *ngFor="let item of everyList"
          [value]="item"
          [cogDataId]="'every-option-' + item.value"
          [inheritDataId]="true">
          {{ item.label | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <coh-day-of-week-selector
      *ngIf="form.value?.every?.value === 'week'"
      class="margin-left"
      [control]="form.get('dayOfWeek')"
      [singleOnly]="!allowMultiple"
      cogDataId="day-of-week-select"
      [inheritDataId]="true">
    </coh-day-of-week-selector>
    <coh-day-of-month-selector
      *ngIf="form.value?.every?.value === 'month'"
      class="margin-left"
      [control]="form.get('dayOfMonth')"
      [singleOnly]="!allowMultiple"
      cogDataId="day-of-month-select"
      [inheritDataId]="true">
    </coh-day-of-month-selector>
  </div>
  <div class="schedule-selector-row" [class.schedule-selector-full-width]="fullWidth">
    <mat-form-field>
      <mat-label>{{ 'atAlt' | translate }}</mat-label>
      <mat-select
        [formControl]="form.get('at')"
        cogDataId="at-select"
        [inheritDataId]="true">
        <mat-option
          *ngFor="let item of timeList"
          [value]="item"
          [cogDataId]="'at-option-' + item.value"
          [inheritDataId]="true">
          {{ item.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <coh-timezone-selector
      [formControl]="form.get('timezone')"
      class="margin-left"
      [label]="'timeZone' | translate"
      cogDataId="timezone-picker"
      [inheritDataId]="true">
    </coh-timezone-selector>
  </div>
</div>
