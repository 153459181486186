/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { OdpRemoteClusters } from '../models/odp-remote-clusters';
import { OdpRemoteCluster } from '../models/odp-remote-cluster';
import { CreateOdpRemoteClusterParams } from '../models/create-odp-remote-cluster-params';
import { UpdateOdpRemoteClusterParams } from '../models/update-odp-remote-cluster-params';
import { RemoteClusters } from '../models/remote-clusters';
import { RemoteCluster } from '../models/remote-cluster';
import { RegisterRemoteClusterParameters } from '../models/register-remote-cluster-parameters';
import { RemoteClusterParams } from '../models/remote-cluster-params';
import { ValidateRemoteClusterConnectionParams } from '../models/validate-remote-cluster-connection-params';
import { ReplicationEncryptionKey } from '../models/replication-encryption-key';
@Injectable({
  providedIn: 'root',
})
class RemoteClustersService extends __BaseService {
  static readonly GetOdpRemoteClustersPath = '/odp-remote-clusters';
  static readonly CreateOdpRemoteClusterPath = '/odp-remote-clusters';
  static readonly GetOdpRemoteClusterByIdPath = '/odp-remote-clusters/{clusterId}';
  static readonly UpdateOdpRemoteClusterPath = '/odp-remote-clusters/{clusterId}';
  static readonly GetRemoteClustersPath = '/remote-clusters';
  static readonly RegisterRemoteClusterPath = '/remote-clusters';
  static readonly ValidateRemoteClusterPath = '/remote-clusters/validate';
  static readonly GetRemoteClusterByIdPath = '/remote-clusters/{clusterId}';
  static readonly UpdateRemoteClusterPath = '/remote-clusters/{clusterId}';
  static readonly DeleteRemoteClusterPath = '/remote-clusters/{clusterId}';
  static readonly GetReplicationEncryptionKeyPath = '/replicationEncryptionKey';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get ODP Remote Cluster configs.
   *
   * Get ODP Remote Cluster configs.
   * @param params The `RemoteClustersService.GetOdpRemoteClustersParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which ODP Remote Clusters are to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: If true, the response will include ODP Remote Clusters which were created by all tenants which the current user has permission to see. If false, then only ODP Remote Clusters created by the current user will be returned.
   *
   * - `clusterNames`: Specifies a list of ODP Remote Cluster names to filter.
   *
   * - `clusterIds`: Specifies a list of ODP Remote Cluster ids to filter.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetOdpRemoteClustersResponse(params: RemoteClustersService.GetOdpRemoteClustersParams): __Observable<__StrictHttpResponse<OdpRemoteClusters>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    (params.clusterNames || []).forEach(val => {if (val != null) __params = __params.append('clusterNames', val.toString())});
    (params.clusterIds || []).forEach(val => {if (val != null) __params = __params.append('clusterIds', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/odp-remote-clusters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OdpRemoteClusters>;
      })
    );
  }
  /**
   * Get ODP Remote Cluster configs.
   *
   * Get ODP Remote Cluster configs.
   * @param params The `RemoteClustersService.GetOdpRemoteClustersParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which ODP Remote Clusters are to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: If true, the response will include ODP Remote Clusters which were created by all tenants which the current user has permission to see. If false, then only ODP Remote Clusters created by the current user will be returned.
   *
   * - `clusterNames`: Specifies a list of ODP Remote Cluster names to filter.
   *
   * - `clusterIds`: Specifies a list of ODP Remote Cluster ids to filter.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetOdpRemoteClusters(params: RemoteClustersService.GetOdpRemoteClustersParams): __Observable<OdpRemoteClusters> {
    return this.GetOdpRemoteClustersResponse(params).pipe(
      __map(_r => _r.body as OdpRemoteClusters)
    );
  }

  /**
   * Create an ODP Remote Cluster config.
   *
   * Create an ODP Remote Cluster config.
   * @param params The `RemoteClustersService.CreateOdpRemoteClusterParams` containing the following parameters:
   *
   * - `body`: Specifies the request to create ODP Remote Cluster config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateOdpRemoteClusterResponse(params: RemoteClustersService.CreateOdpRemoteClusterParams): __Observable<__StrictHttpResponse<OdpRemoteCluster>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/odp-remote-clusters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OdpRemoteCluster>;
      })
    );
  }
  /**
   * Create an ODP Remote Cluster config.
   *
   * Create an ODP Remote Cluster config.
   * @param params The `RemoteClustersService.CreateOdpRemoteClusterParams` containing the following parameters:
   *
   * - `body`: Specifies the request to create ODP Remote Cluster config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateOdpRemoteCluster(params: RemoteClustersService.CreateOdpRemoteClusterParams): __Observable<OdpRemoteCluster> {
    return this.CreateOdpRemoteClusterResponse(params).pipe(
      __map(_r => _r.body as OdpRemoteCluster)
    );
  }

  /**
   * Get ODP Remote Cluster config by id.
   *
   * Get ODP Remote Cluster config by id.
   * @param params The `RemoteClustersService.GetOdpRemoteClusterByIdParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the id of ODP Remote Cluster to fetch.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetOdpRemoteClusterByIdResponse(params: RemoteClustersService.GetOdpRemoteClusterByIdParams): __Observable<__StrictHttpResponse<OdpRemoteCluster>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/odp-remote-clusters/${encodeURIComponent(params.clusterId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OdpRemoteCluster>;
      })
    );
  }
  /**
   * Get ODP Remote Cluster config by id.
   *
   * Get ODP Remote Cluster config by id.
   * @param params The `RemoteClustersService.GetOdpRemoteClusterByIdParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the id of ODP Remote Cluster to fetch.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetOdpRemoteClusterById(params: RemoteClustersService.GetOdpRemoteClusterByIdParams): __Observable<OdpRemoteCluster> {
    return this.GetOdpRemoteClusterByIdResponse(params).pipe(
      __map(_r => _r.body as OdpRemoteCluster)
    );
  }

  /**
   * Update an ODP Remote Cluster config.
   *
   * Update an ODP Remote Cluster config.
   * @param params The `RemoteClustersService.UpdateOdpRemoteClusterParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the id of ODP Remote Cluster to update.
   *
   * - `body`: Specifies the request to update ODP Remote Cluster config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateOdpRemoteClusterResponse(params: RemoteClustersService.UpdateOdpRemoteClusterParams): __Observable<__StrictHttpResponse<OdpRemoteCluster>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/odp-remote-clusters/${encodeURIComponent(params.clusterId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OdpRemoteCluster>;
      })
    );
  }
  /**
   * Update an ODP Remote Cluster config.
   *
   * Update an ODP Remote Cluster config.
   * @param params The `RemoteClustersService.UpdateOdpRemoteClusterParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the id of ODP Remote Cluster to update.
   *
   * - `body`: Specifies the request to update ODP Remote Cluster config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateOdpRemoteCluster(params: RemoteClustersService.UpdateOdpRemoteClusterParams): __Observable<OdpRemoteCluster> {
    return this.UpdateOdpRemoteClusterResponse(params).pipe(
      __map(_r => _r.body as OdpRemoteCluster)
    );
  }

  /**
   * Get all registered Remote Clusters.
   *
   * List the Remote Clusters that are registered on this local Cluster and that matches the filter criteria specified using parameters.
   * @param params The `RemoteClustersService.GetRemoteClustersParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `purpose`: Specifies the purpose for which the remote cluster is being registered.
   *
   * - `nodeAddresses`: Specifies a list of Remote Cluster IPs to filter.
   *
   * - `clusterNames`: Specifies a list of Remote Cluster names to filter.
   *
   * - `clusterIds`: Specifies a list of Remote Cluster ids to filter.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRemoteClustersResponse(params: RemoteClustersService.GetRemoteClustersParams): __Observable<__StrictHttpResponse<RemoteClusters>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.purpose || []).forEach(val => {if (val != null) __params = __params.append('purpose', val.toString())});
    (params.nodeAddresses || []).forEach(val => {if (val != null) __params = __params.append('nodeAddresses', val.toString())});
    (params.clusterNames || []).forEach(val => {if (val != null) __params = __params.append('clusterNames', val.toString())});
    (params.clusterIds || []).forEach(val => {if (val != null) __params = __params.append('clusterIds', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/remote-clusters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RemoteClusters>;
      })
    );
  }
  /**
   * Get all registered Remote Clusters.
   *
   * List the Remote Clusters that are registered on this local Cluster and that matches the filter criteria specified using parameters.
   * @param params The `RemoteClustersService.GetRemoteClustersParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `purpose`: Specifies the purpose for which the remote cluster is being registered.
   *
   * - `nodeAddresses`: Specifies a list of Remote Cluster IPs to filter.
   *
   * - `clusterNames`: Specifies a list of Remote Cluster names to filter.
   *
   * - `clusterIds`: Specifies a list of Remote Cluster ids to filter.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRemoteClusters(params: RemoteClustersService.GetRemoteClustersParams): __Observable<RemoteClusters> {
    return this.GetRemoteClustersResponse(params).pipe(
      __map(_r => _r.body as RemoteClusters)
    );
  }

  /**
   * Register a Remote Cluster.
   *
   * Register a Remote Cluster on this local cluster for remote access and/or replication.
   * @param params The `RemoteClustersService.RegisterRemoteClusterParams` containing the following parameters:
   *
   * - `body`: Specifies the request to register Remote Cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RegisterRemoteClusterResponse(params: RemoteClustersService.RegisterRemoteClusterParams): __Observable<__StrictHttpResponse<RemoteCluster>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/remote-clusters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RemoteCluster>;
      })
    );
  }
  /**
   * Register a Remote Cluster.
   *
   * Register a Remote Cluster on this local cluster for remote access and/or replication.
   * @param params The `RemoteClustersService.RegisterRemoteClusterParams` containing the following parameters:
   *
   * - `body`: Specifies the request to register Remote Cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RegisterRemoteCluster(params: RemoteClustersService.RegisterRemoteClusterParams): __Observable<RemoteCluster> {
    return this.RegisterRemoteClusterResponse(params).pipe(
      __map(_r => _r.body as RemoteCluster)
    );
  }

  /**
   * Validate Remote Cluster config.
   *
   * Validate a Remote Cluster credentials. If includeRemoteClusterMetadata is true, response will include the remote cluster metadata.
   * @param params The `RemoteClustersService.ValidateRemoteClusterParams` containing the following parameters:
   *
   * - `body`: Specifies the request to validate Remote Cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeMetadata`: Specifies if Remote Cluster metadata should be included in the response.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ValidateRemoteClusterResponse(params: RemoteClustersService.ValidateRemoteClusterParams): __Observable<__StrictHttpResponse<RemoteClusterParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeMetadata != null) __params = __params.set('includeMetadata', params.includeMetadata.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/remote-clusters/validate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RemoteClusterParams>;
      })
    );
  }
  /**
   * Validate Remote Cluster config.
   *
   * Validate a Remote Cluster credentials. If includeRemoteClusterMetadata is true, response will include the remote cluster metadata.
   * @param params The `RemoteClustersService.ValidateRemoteClusterParams` containing the following parameters:
   *
   * - `body`: Specifies the request to validate Remote Cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeMetadata`: Specifies if Remote Cluster metadata should be included in the response.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ValidateRemoteCluster(params: RemoteClustersService.ValidateRemoteClusterParams): __Observable<RemoteClusterParams> {
    return this.ValidateRemoteClusterResponse(params).pipe(
      __map(_r => _r.body as RemoteClusterParams)
    );
  }

  /**
   * Get Remote Cluster config by id.
   *
   * Get Remote Cluster config by cluster id.
   * @param params The `RemoteClustersService.GetRemoteClusterByIdParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the cluster id of Remote Cluster to fetch.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRemoteClusterByIdResponse(params: RemoteClustersService.GetRemoteClusterByIdParams): __Observable<__StrictHttpResponse<RemoteCluster>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/remote-clusters/${encodeURIComponent(params.clusterId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RemoteCluster>;
      })
    );
  }
  /**
   * Get Remote Cluster config by id.
   *
   * Get Remote Cluster config by cluster id.
   * @param params The `RemoteClustersService.GetRemoteClusterByIdParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the cluster id of Remote Cluster to fetch.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRemoteClusterById(params: RemoteClustersService.GetRemoteClusterByIdParams): __Observable<RemoteCluster> {
    return this.GetRemoteClusterByIdResponse(params).pipe(
      __map(_r => _r.body as RemoteCluster)
    );
  }

  /**
   * Update a Remote Cluster config.
   *
   * Update the connection settings of the specified Remote Cluster that is registered on this Cluster.
   * @param params The `RemoteClustersService.UpdateRemoteClusterParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the cluster id of the Remote Cluster to update.
   *
   * - `body`: Specifies the request to update Remote Cluster config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateRemoteClusterResponse(params: RemoteClustersService.UpdateRemoteClusterParams): __Observable<__StrictHttpResponse<RemoteCluster>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/remote-clusters/${encodeURIComponent(params.clusterId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RemoteCluster>;
      })
    );
  }
  /**
   * Update a Remote Cluster config.
   *
   * Update the connection settings of the specified Remote Cluster that is registered on this Cluster.
   * @param params The `RemoteClustersService.UpdateRemoteClusterParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the cluster id of the Remote Cluster to update.
   *
   * - `body`: Specifies the request to update Remote Cluster config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateRemoteCluster(params: RemoteClustersService.UpdateRemoteClusterParams): __Observable<RemoteCluster> {
    return this.UpdateRemoteClusterResponse(params).pipe(
      __map(_r => _r.body as RemoteCluster)
    );
  }

  /**
   * Unregister a Remote Cluster.
   *
   * Unregister an external Remote Cluster.
   * @param params The `RemoteClustersService.DeleteRemoteClusterParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the cluster id of the Remote Cluster to unregister.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteRemoteClusterResponse(params: RemoteClustersService.DeleteRemoteClusterParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/remote-clusters/${encodeURIComponent(params.clusterId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Unregister a Remote Cluster.
   *
   * Unregister an external Remote Cluster.
   * @param params The `RemoteClustersService.DeleteRemoteClusterParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the cluster id of the Remote Cluster to unregister.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteRemoteCluster(params: RemoteClustersService.DeleteRemoteClusterParams): __Observable<null> {
    return this.DeleteRemoteClusterResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get Replication Encryption Key
   *
   * List the Replication Encryption Key. It is used for encrypting replication data between this Cluster and a remote Cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetReplicationEncryptionKeyResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<ReplicationEncryptionKey>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/replicationEncryptionKey`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReplicationEncryptionKey>;
      })
    );
  }
  /**
   * Get Replication Encryption Key
   *
   * List the Replication Encryption Key. It is used for encrypting replication data between this Cluster and a remote Cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetReplicationEncryptionKey(regionId?: string,
    accessClusterId?: number): __Observable<ReplicationEncryptionKey> {
    return this.GetReplicationEncryptionKeyResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as ReplicationEncryptionKey)
    );
  }
}

module RemoteClustersService {

  /**
   * Parameters for GetOdpRemoteClusters
   */
  export interface GetOdpRemoteClustersParams {

    /**
     * TenantIds contains ids of the tenants for which ODP Remote Clusters are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * If true, the response will include ODP Remote Clusters which were created by all tenants which the current user has permission to see. If false, then only ODP Remote Clusters created by the current user will be returned.
     */
    includeTenants?: boolean;

    /**
     * Specifies a list of ODP Remote Cluster names to filter.
     */
    clusterNames?: Array<string>;

    /**
     * Specifies a list of ODP Remote Cluster ids to filter.
     */
    clusterIds?: Array<number>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateOdpRemoteCluster
   */
  export interface CreateOdpRemoteClusterParams {

    /**
     * Specifies the request to create ODP Remote Cluster config.
     */
    body: CreateOdpRemoteClusterParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetOdpRemoteClusterById
   */
  export interface GetOdpRemoteClusterByIdParams {

    /**
     * Specifies the id of ODP Remote Cluster to fetch.
     */
    clusterId: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateOdpRemoteCluster
   */
  export interface UpdateOdpRemoteClusterParams {

    /**
     * Specifies the id of ODP Remote Cluster to update.
     */
    clusterId: number;

    /**
     * Specifies the request to update ODP Remote Cluster config.
     */
    body: UpdateOdpRemoteClusterParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetRemoteClusters
   */
  export interface GetRemoteClustersParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the purpose for which the remote cluster is being registered.
     */
    purpose?: Array<'Replication' | 'RemoteAccess'>;

    /**
     * Specifies a list of Remote Cluster IPs to filter.
     */
    nodeAddresses?: Array<string>;

    /**
     * Specifies a list of Remote Cluster names to filter.
     */
    clusterNames?: Array<string>;

    /**
     * Specifies a list of Remote Cluster ids to filter.
     */
    clusterIds?: Array<number>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for RegisterRemoteCluster
   */
  export interface RegisterRemoteClusterParams {

    /**
     * Specifies the request to register Remote Cluster.
     */
    body: RegisterRemoteClusterParameters;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ValidateRemoteCluster
   */
  export interface ValidateRemoteClusterParams {

    /**
     * Specifies the request to validate Remote Cluster.
     */
    body: ValidateRemoteClusterConnectionParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies if Remote Cluster metadata should be included in the response.
     */
    includeMetadata?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetRemoteClusterById
   */
  export interface GetRemoteClusterByIdParams {

    /**
     * Specifies the cluster id of Remote Cluster to fetch.
     */
    clusterId: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateRemoteCluster
   */
  export interface UpdateRemoteClusterParams {

    /**
     * Specifies the cluster id of the Remote Cluster to update.
     */
    clusterId: number;

    /**
     * Specifies the request to update Remote Cluster config.
     */
    body: RemoteCluster;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteRemoteCluster
   */
  export interface DeleteRemoteClusterParams {

    /**
     * Specifies the cluster id of the Remote Cluster to unregister.
     */
    clusterId: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { RemoteClustersService }
