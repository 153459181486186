import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { LocationValues, MultiSubnetFormValues } from '@cohesity/api/private';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormArray, Validators, UntypedFormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';

/**
 * @description
 * This component enables fleet setting for GCP.
 *
 * @example
 *   Simple Usage:
 *   <coh-gcp-fleet-instance-settings
 *    [view]= modify | new
 *    [hypervisor] = "hypervisorSource"
 *    (valueupdate) = "emits the value of forms">
 *   </coh-gcp-fleet-instance-settings>
 */

/**
 * Fleet Locations that the user can select via DropDowns.
 * viewValue - The user sees this in the UI.
 * value - The value to be passed in the API.
 * helperText - Help Text at the bottom of each option describing the usage.
 *
 */
interface FleetLocationOptions {
  viewValue: string;
  value: LocationValues;
  helperText: string;
}

/**
 * The interface for form output. Need to emit the form validity and the form
 * value in the AJS usecase.
 */
interface MultiSubnetFormOutputModel {
  value: MultiSubnetFormValues;
  valid: boolean;
}

@Component({
  selector: 'coh-gcp-fleet-instance-settings',
  templateUrl: './gcp-fleet-instance-settings.component.html',
  styleUrls: ['./gcp-fleet-instance-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GCPFleetInstanceSettingsComponent implements OnInit, OnDestroy {
  /**
   * The current view. 'new' is for registration of the source. 'modify' is to edit the source.
   */
  @Input() view: 'new' | 'modify';

  /**
   * The object that contains registration data related to the entity.
   */
  @Input() hypervisor: any;

  /**
   * Actual function that will emit the changes to the parent component.
   */
  @Output() valueUpdate = new EventEmitter<MultiSubnetFormOutputModel>();

  formChangeSubscription: Subscription;

  /**
   * FormGroup that will hold the values.
   * locations will hold values for desired location in order of priority.
   * customSubnets will hold values for various subnets in order of priority.
   */
  fleetControl = new UntypedFormGroup({
    locations: new UntypedFormArray([], [Validators.required]),
    customSubnets: new UntypedFormControl(null),
  });

  /**
   * Holds various location and related data.
   */
  fleetLocations: FleetLocationOptions[] = [
    {
      viewValue: this.translate.instant('gcpMultiSubnet.sourceVMViewValue'),
      value: 'kSourceVM',
      helperText: this.translate.instant('gcpMultiSubnet.sourceVMHelperText'),
    },
    // TODO: Enable this option when backend is ready.
    // {
    //   viewValue: this.translate.instant('gcpMultiSubnet.ceViewValue'),
    //   value: 'kCluster',
    //   helperText: this.translate.instant('gcpMultiSubnet.ceHelperText'),
    // },
    {
      viewValue: this.translate.instant('gcpMultiSubnet.customVMViewValue'),
      value: 'kCustom',
      helperText: this.translate.instant('gcpMultiSubnet.customVMHelperText'),
    },
  ];

  /**
   * get method to get locations FormArray from the FormGroup
   *
   * @return FormArray - locations
   */
  get locationGetter(): UntypedFormArray {
    return this.fleetControl.get('locations') as UntypedFormArray;
  }

  /**
   * Whether to show the subnet settings or not. One of the fleet instance
   * should be Custom.
   */
  get showSubnetSettings(): boolean {
    return this.view === 'modify' && this.fleetControl.value.locations.includes('kCustom');
  }

  constructor(private translate: TranslateService, private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    const gcpEntity = this.hypervisor.entity.gcpEntity;

    if (!gcpEntity.gcpFleetParams) {
      gcpEntity.gcpFleetParams = {};
    }

    if (!gcpEntity.gcpFleetParams.fleetNetworkParamsVec) {
      gcpEntity.gcpFleetParams.fleetNetworkParamsVec = [];
    }

    /**
     * If you are in edit mode the value will be already present if you have registered with multisubnet feature.
     * If not, then we need to init the 1st formcontrol value.
     */
    if (gcpEntity.gcpFleetParams.fleetNetworkParamsVec.length !== 0) {
      const size = gcpEntity.gcpFleetParams.fleetNetworkParamsVec.length;
      for (let i = 0; i < size; i++) {
        const { fleetSubnetType, networkParamsVec } = gcpEntity.gcpFleetParams.fleetNetworkParamsVec[i];
        this.locationGetter.push(this.formBuilder.control(null, [Validators.required]));
        this.locationGetter.at(i).setValue(fleetSubnetType);

        if (fleetSubnetType === 'kCustom' && networkParamsVec?.length) {
          this.fleetControl.controls.customSubnets.setValue(networkParamsVec);
        }
      }
    } else {
      this.locationGetter.push(this.formBuilder.control(null, [Validators.required]));
    }

    this.formChangeSubscription = this.fleetControl.valueChanges.subscribe(selectedFormValues => {
      // If the subnet settings are hidden, ignore the errors in that control.
      if (!this.showSubnetSettings) {
        this.fleetControl.controls.customSubnets.setErrors(null);
      }

      this.valueUpdate.emit({ value: selectedFormValues, valid: this.fleetControl.valid });
    });
  }

  ngOnDestroy(): void {
    if(this.formChangeSubscription) {
      this.formChangeSubscription.unsubscribe();
    }
  }
  /**
   * Adding a FormControl to FormArray
   *
   * @param index index is needed here to map to fleetSubnetPriority
   */
  addLocation() {
    this.locationGetter.push(this.formBuilder.control(null, [Validators.required]));
  }

  /**
   * Removes a FormControl at index'th position
   *
   * @param index index to remove from FormArray
   */
  removeLocation(index: number) {
    this.locationGetter.removeAt(index);
  }

  /**
   * Exclude options if they are already selected in some other dropdown.
   *
   * @param index indexth number dropdown (for location)
   * @param value value of the option
   * @returns whether this option is disabled or not.
   */
  isOptionDisabled(index: number, value: string): boolean {
    for (let i = 0; i < this.locationGetter.controls.length; i++) {
      if (i !== index) {
        const fc = this.locationGetter.at(i) as UntypedFormControl;
        if (value === fc.value) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Restrict number of drop down to 3.
   * Disable adding another location till we select current location.
   *
   * @param index index'th number dropdown (for location)
   * @returns whether adding next dropdown is disabled or not
   */
  isAddLocationDisabled(index: number): boolean {
    if (index === 2) {
      return true;
    }
    const fc = this.locationGetter.at(index) as UntypedFormControl;
    return fc.value === null;
  }

  /**
   * Helper function for mat-trigger.
   *
   * @param index index'th number dropdown.
   * @returns string - corresponding to the value of FormControl at index.
   */
  viewValue(index: number) {
    const fc = this.locationGetter.at(index) as UntypedFormControl;

    for (let i = 0; i < this.fleetLocations.length; i++) {
      if (this.fleetLocations[i].value === fc.value) {
        return this.fleetLocations[i].viewValue;
      }
    }
  }
}
