<ng-container *ngIf="navItem">
  <ng-container [ngSwitch]="navButtonType">
    <ng-container *ngSwitchCase="'flat'">
      <!-- Show this if nav item is a flat button -->
      <ng-container *ngTemplateOutlet="flatButton"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'icon'">
      <!-- Show this if nav item is an icon button -->
      <ng-container *ngTemplateOutlet="iconButton"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'menu'">
      <!-- Show this if nav item is an menu button -->
      <ng-container *ngTemplateOutlet="menuButton"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'flatIcon'">
      <!-- Show this if nav item is a flat icon button -->
      <ng-container *ngTemplateOutlet="flatIconButton"></ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<!-- Template for the flat button -->
<ng-template #flatButton>
  <button *ngIf="navItem.action"
    (click)="navItem.action()"
    [attr.aria-label]="navItem.displayName"
    [class]="buttonClassName"
    [cogDataId]="'navitem-flat-button-action-' + navItem.displayName"
    [color]="color"
    [disabled]="!!navItem.disabled"
    [inheritDataId]="true"
    mat-flat-button
    type="button">
    {{navItem.displayName}}
  </button>

  <button *ngIf="navItem.state"
    [attr.aria-label]="navItem.displayName"
    [class]="buttonClassName"
    [cogDataId]="'navitem-flat-button-state-' + navItem.displayName"
    [color]="color"
    [disabled]="!!navItem.disabled"
    [inheritDataId]="true"
    [uiParams]="navItem.stateParams"
    [uiSref]="navItem.state"
    mat-flat-button
    type="button">
    {{navItem.displayName}}
  </button>
</ng-template>

<!-- Template for the icon button -->
<ng-template #iconButton>
  <button *ngIf="navItem.action"
    (click)="navItem.action()"
    [attr.aria-label]="navItem.displayName"
    [class]="buttonClassName"
    [cogDataId]="'navitem-icon-button-action-' + navItem.displayName"
    [color]="color"
    [disabled]="!!navItem.disabled"
    [inheritDataId]="true"
    [matTooltip]="navItem.displayName"
    mat-icon-button
    type="button">
    <cog-icon [color]="color" [shape]="navItem.icon"></cog-icon>
  </button>

  <button *ngIf="navItem.state"
    [attr.aria-label]="navItem.displayName"
    [class]="buttonClassName"
    [cogDataId]="'navitem-icon-button-state-' + navItem.displayName"
    [disabled]="!!navItem.disabled"
    [inheritDataId]="true"
    [uiParams]="navItem.stateParams"
    [uiSref]="navItem.state"
    mat-icon-button
    type="button">
    <cog-icon [color]="color" [shape]="navItem.icon"></cog-icon>
  </button>
</ng-template>

<!-- Template for the menu button -->
<ng-template #menuButton>
  <button *ngIf="navItem.action"
    (click)="navItem.action()"
    [attr.aria-label]="navItem.displayName"
    [cogDataId]="'navitem-menu-button-action-' + navItem.displayName"
    [disabled]="!!navItem.disabled"
    [inheritDataId]="true"
    mat-menu-item
    type="button">
    {{navItem.displayName}}
  </button>

  <button *ngIf="navItem.state"
    [attr.aria-label]="navItem.displayName"
    [cogDataId]="'navitem-menu-button-state-' + navItem.displayName"
    [disabled]="!!navItem.disabled"
    [inheritDataId]="true"
    [uiParams]="navItem.stateParams"
    [uiSref]="navItem.state"
    mat-menu-item
    type="button">
    {{navItem.displayName}}
  </button>
</ng-template>

<!-- Template for the flat icon button -->
<ng-template #flatIconButton>
  <button *ngIf="navItem.action"
    (click)="navItem.action()"
    [attr.aria-label]="navItem.displayName"
    [class]="buttonClassName"
    [cogDataId]="'navitem-flat-icon-button-action-' + navItem.displayName"
    [color]="color"
    [disabled]="!!navItem.disabled"
    [inheritDataId]="true"
    [matTooltip]="navItem.displayName"
    cog-button-small
    mat-stroked-button
    type="button">
    <cog-icon [shape]="navItem.icon"></cog-icon>
    {{navItem.displayName}}
  </button>

  <button *ngIf="navItem.state"
    [attr.aria-label]="navItem.displayName"
    [class]="buttonClassName"
    [cogDataId]="'navitem-flat-icon-button-state-' + navItem.displayName"
    [color]="color"
    [disabled]="!!navItem.disabled"
    [inheritDataId]="true"
    [uiParams]="navItem.stateParams"
    [uiSref]="navItem.state"
    cog-button-small
    mat-stroked-button
    type="button">
    <cog-icon [shape]="navItem.icon"></cog-icon>
    {{navItem.displayName}}
  </button>
</ng-template>
