// Service: MCM(aka Helios) protection job services formatters.

;(function(angular, undefined) {
  'use strict';

  angular.module('C.mcmJobServiceFormatter', ['C'])
    .service('McmJobServiceFormatter', McmJobServiceFormatterFn);

  function McmJobServiceFormatterFn(_, HeliosService) {

    return {
      transformJob: transformJob,
    };

    /**
     * Transform protection Job info with helper properties for quick lookups.
     *
     * @method   transformJob
     * @param    {Object}   job   The protection job info object from backend
     *   mcmData.ProtectionJob proto from
     *   iris/go/server/data/mcm/protection_jobs_data.go file.
     * @return   {Object}   returns transformed job object.
     */
    function transformJob(job) {
      job = job || {};

      _.assign(job, {
        // generating globally unique uuid for protection job which will be like
        // <clusterId:clusterIncarnationId:jobId>
        _uuid: HeliosService.generateUuid(job, job.id),
      });

      return job;
    }

  }

}(angular));
