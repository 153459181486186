// Component: Tapes: Display tape related info for Restore tasks.

;(function(angular, undefined) {
  'use strict';

  /**
   * @ngdoc component
   * @name C.tapes:cTape
   * @function
   *
   * @description
   *   Displays a list of tapes required for a (created or theoretical) restore
   *   task.
   *
   * @example
     <c-tapes task="restoreTaskObj"></c-tapes>
     <c-tapes params="getMediaParams"></c-tapes>
   */
  var modName = 'C.tapes';
  var modDeps = [];
  var componentName = 'cTapes';
  var options = {
    // These are bound to the controller, not the scope
    bindings: {
      /**
       * Either task or combination of params and objects should be provided.
       * task is sufficient for existing/created restore tasks. Params and
       * objects (if only wanting specific VMs rather than entire job run)
       * should be provided for theoretical/uncreated tasks.
       */
      task: '=?',
      params: '=?',
      objects: '=?',
    },

    // The controller
    controller: 'TapesCtrl',

    // URL to the template
    templateUrl: 'app/global/c-tapes/c-tapes.html',
  };

  angular
    .module(modName, modDeps)
    .controller('TapesCtrl', cTapesFn)
    .component(componentName, options);

  function cTapesFn($rootScope, $scope, ExternalTargetService, evalAJAX) {

    var $ctrl = this;

    $ctrl.text = $rootScope.text.cTapes;

    /**
     * Get the list of media/tapes required for the provided restore task.
     *
     * @method     getMedia
     */
    $ctrl.getMedia = function getMedia() {
      $ctrl.loading = true;
      $ctrl.task = $ctrl.task || undefined;

      if ($ctrl.params && $ctrl.objects) {
        // need to map objects into params.entityIds[]
        $ctrl.params.entityIds = $ctrl.objects
          .filter(function filterEmptyEntities(object) {
            return !!object.entity;
          })
          .map(function entityIdMapper(object) {
            return object.entity.id;
          }
        );

        if (!$ctrl.params.entityIds.length) {
          $ctrl.params.entityIds = undefined;
        }

      } else {
        $ctrl.params = {};
      }

      ExternalTargetService.getArchiveMediaInfo($ctrl.params, $ctrl.task).then(
        function getMediaSuccess(mediaList) {
          $ctrl.mediaList = mediaList;
        },

        evalAJAX.errorMessage
      ).finally(
        function getMediaFinally() {
          $ctrl.loading = false;
        }
      );
    };

    /**
     * initialize the component
     *
     * @method   onInit
     */
    $ctrl.$onInit = function onInit() {
      $ctrl.getMedia();

      /**
       * If $ctrl.params is provided, we are dealing with a task currently being
       * created. User has the option to change snapshot selection, and we
       * should listen to and react to such changes.
       */
      if ($ctrl.params) {
        $scope.$watch('$ctrl.params',
          function paramsWatcher(newParams, oldParams) {
            // check for oldParams to ensure this doesn't fire on initial
            // component load.
            if (oldParams && !angular.equals(newParams, oldParams)) {
              $ctrl.getMedia();
            }
          }
        );
      }
    };

  }

})(angular);
