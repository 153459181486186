// Controller: VIPs summary

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.vipsSummary', [])
    .controller('vipsSummaryCtrl', vipsSummaryCtrlFn);

  /**
   * The main logic of networking summary
   *
   * @method  vipsSummaryCtrlFn
   * @param   {object}   _ Lodash utility library
   * @param   {object}   NetworkService
   * @param   {object}   evalAJAX
   */
  function vipsSummaryCtrlFn(_, NetworkService, evalAJAX) {

    var $ctrl = this;

    _.assign($ctrl, {
      interfaceGroups: [],
      airgapStatus: false,

      $onInit: $onInit,
      enable: enable
    });

    /**
     * Fetch interface groups to initialize Summary view.
     *
     * @method   $onInit
     */
    function $onInit() {
      $ctrl.loadingInterfaces = true;
      $ctrl.fetchingStatus = true;
      $ctrl.airgapSupported = NetworkService.isAirgapSupported();
      NetworkService.getInterfaceGroups()
        .then(function gotInterfaceGroups(interfaceGroups) {
          interfaceGroups.forEach(function iterateInterfaceGroups(ifaceGroup) {
            if (ifaceGroup.nodeInterfacePairs) {
              ifaceGroup.nodeInterfacePairs.sort(function compareNodeInterface(
                nodeInterface1, nodeInterface2) {
                return nodeInterface1.nodeId - nodeInterface2.nodeId;
              });
            }
          });
          $ctrl.interfaceGroups = interfaceGroups;
        }, evalAJAX.errorMessage)
        .finally(function getInterfaceGroupsFinally() {
          $ctrl.loadingInterfaces = false;
        });
      if ($ctrl.airgapSupported) {
        NetworkService.getClusterAirgapStatus()
        .then(function gotStatus(status) {
          $ctrl.fetchingStatus = false;
          $ctrl.airgapStatus = status;
        });
      }
    }

    function enable(){
      $ctrl.fetchingStatus = true;
      let status = '';
      if($ctrl.airgapStatus){
        status = 'Disable';
      } else {
        status = 'Enable';
      }
      NetworkService.updateClusterAirgapStatus(status).then(
        function updatedStatus(updatedStatus) {
          const status = updatedStatus.airgapStatus === 'Enable';
          $ctrl.airgapStatus = status;
          $ctrl.fetchingStatus = false;
        },
        evalAJAX.errorMessage
      )
    }
  }

})(angular);
