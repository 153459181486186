<coh-form-field-object-selector
  [control]="control"
  [inheritDataId]="true"
  [label]="'qosPolicy' | translate"
  [loading]="loading"
  [required]="true"
  [values]="qosPolicies$ | async"
  cogDataId="view-qos-policy"
  optionKey="name">
</coh-form-field-object-selector>
