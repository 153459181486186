import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ProtectionGroupServiceApi } from '@cohesity/api/v2';
import { MomentDatePipe } from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { DialogService, LocaleService } from 'src/app/core/services';
import {
  Environment,
  JobEnvParamsV2,
  NoSqlRecoverySnapshotParams,
  RecoverSourceEnvParamsV2,
  TimelineDialogComponent,
  TimelineDialogData
} from 'src/app/shared';

import { NoSQLRecoveryObject } from '../../model/nosql-recovery-object';
import { Recovery } from '../../model/recovery';
import { RecoveryObject } from '../../model/recovery-object';
import { NoSqlService } from '../../services/nosql.services';
import { RecoveryObjectPopoverComponent } from '../recovery-object-popover/recovery-object-popover.component';

/**
 * Recovery detail page objects table.
 * TODO(karan.sood): Since this is being used by adapters other than NoSQL & Hadoop, change
 * the selector name to make it more generic.
 *
 * @example
 * <coh-nosql-recovery-object-table [recovery]="recovery"></coh-nosql-recovery-object-table>
 */
@Component({
  selector: 'coh-nosql-recovery-object-table',
  templateUrl: './nosql-recovery-object-table.component.html',
  providers: [MomentDatePipe],
})
export class NoSQLRecoveryObjectTableComponent implements OnInit {
  /**
   * Recovery job for which recovered objects table to be shown.
   */
  @Input() recovery: Recovery;

  /**
   * Notify parent the protection group when its progress completes.
   */
  @Output() readonly progressComplete = new EventEmitter<Recovery>();

  /**
   * Recovery object popover component.
   */
  popover = RecoveryObjectPopoverComponent;

  /**
   * RecoverObject for NoSQL Hadoop connectors.
   */
  recoveryObjects: NoSQLRecoveryObject[];

  /**
   * Table columns for recovery object table.
   */
  tableColumns: string[] = ['name', 'recoveryPoint'];

  /**
   * Flag to check if the recover job for a CDP workload.
   */
  isCdpRestore = false;

  constructor(
    private dialogService: DialogService,
    private localeService: LocaleService,
    private momentDatePipe: MomentDatePipe,
    private noSqlService: NoSqlService,
    private translate: TranslateService,
    private protectionGroupService: ProtectionGroupServiceApi,
  ) { }

  ngOnInit() {
    this.isCdpRestore = this.computeCdpRestoreStatus();
    this.setTableColumns();
    this.setRecoveryObjects();
  }

  /**
   * Check if the restore job is CDP based.
   *
   * @returns   True if job is a CDP restore job, false otherwise.
   */
  computeCdpRestoreStatus(): boolean {
    const environment: string = this.recovery.environment;

    // e.g. cassandraParams, hdfsParams, etc.
    const adapterParams = JobEnvParamsV2[environment];

    // e.g. recoverCassandraParams, recoverHdfsParams, etc.
    const recoverJobParams = RecoverSourceEnvParamsV2[environment];
    const snapshotParams = (
      (this.recovery.reference[adapterParams][recoverJobParams].snapshots || []) as NoSqlRecoverySnapshotParams[]
    )[0];
    return !!snapshotParams?.pointInTimeUsecs;
  }

  /**
   * Formats the recovery point time to a format suitable for display.
   * Non CDP jobs show time upto 'minute' granularity. For CDP jobs, seconds are
   * also shown.
   *
   * @timestampUsecs    Recovery point timestamp.
   * @returns           Formatted time string for display.
   */
  formatRecoveryPointTime(timestampUsecs: number): string {
    return this.isCdpRestore ?
      this.momentDatePipe.transform(timestampUsecs, `#${this.localeService.dateLocale.fullDateTimeFormat12hour}`) :
      this.momentDatePipe.transform(timestampUsecs);
  }

  /**
   * Set table columns.
   */
  setTableColumns() {
    switch (this.recovery.environment) {
      case Environment.kCassandra:
      case Environment.kMongoDB:
      case Environment.kHBase:
      case Environment.kUDA:
        this.tableColumns.push('rename');
        break;
    }
  }

  /**
   * Set the recovery objects based on Environment type.
   * NoSQL/Hadoop connectors have different recovery schema.
   * If the recovery job is of type NoSQL or Hadoop create RecoveryObject[]
   * from the SnapshotParams provided in recovery schema.
   */
  private setRecoveryObjects() {
    this.recoveryObjects =
      this.noSqlService.getRecoveryObjectsForNoSqlRecoveryJob(this.recovery);
    const snapshotParams = this.noSqlService.getSnapshotsForNoSqlRecoveryJob(this.recovery)[0];

    if (!this.recoveryObjects[0]?.protectionGroupName && snapshotParams?.protectionGroupId) {
      // For Object recovery protection name is not populated.
      this.protectionGroupService.GetProtectionGroupById({
        id: snapshotParams?.protectionGroupId
      }).pipe(map(protectionGroup => {
        this.recoveryObjects.forEach((recObject) => {
          recObject.protectionGroupName = protectionGroup.name;
        });
      })).subscribe();
    }
  }

  /**
   * Opens modal window with timeline for pulse logs.
   *
   * @param     name             Name of the Recovery job.
   * @param     progressTaskId   progressTaskId of the Recovery job.
   */
  openActivityModal({ name = '-', progressTaskId, messages }: RecoveryObject) {
    if (progressTaskId) {
      this.dialogService.showDialog(TimelineDialogComponent, {
        title: this.translate.instant('recoveryActivityFor', { name }),
        errorMessages: messages,
        task: progressTaskId,
      } as TimelineDialogData, { disableClose: false });
    }
  }

  /**
   * Track by function for the data table
   *
   * @param    index  Item index.
   * @param    item   The item.
   * @returns  The item id.
   */
  trackById(index: number, item: Recovery): string {
    return item.id;
  }
}
