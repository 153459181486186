import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';
import { hasSourceModifyPrivilege } from 'src/app/shared/helper-utils';

const sourceModify = (ctx: StateAccessContext) =>
  ctx.PROTECTION_SOURCE_MODIFY &&
  ctx.FEATURE_FLAGS.sqlOneClickRegister &&
  hasSourceModifyPrivilege(ctx, 'sql') &&
  ctx.workflow.backupAndRecovery.kSQL.host.kPhysical;

export const SqlSourceConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'mssql-register.**',
      url: '/protection/sources/ms-sql/new',
      loadChildren: () => import('./ms-sql.module').then(m => m.MsSqlSourceModule)
    },
  ],
  getStateAccessMap(): StateAccessMap {
    return {
      'mssql-register': sourceModify,
    };
  },
};
