// Directive: Context Sensitive Help

;(function(angular, undefined) {
  'use strict';

  // This list represents the converted alert ids that are currently documented.
  // we will fall back to defaultAlertCshId if a derived alert ID isn't in this
  // list. This list will be retrieved from help docs upon loading of the first
  // helpLinkAlertCode directive.
  var documentedAlertIds = [];

  angular.module('C.help', [])
    // Attaches a click event to any element. onClick evaluates $state object
    // and redirects to help page accordingly.
    .directive('helpLink', helpLinkFn);

  function helpLinkFn($state, $http, Routes, ClusterService, HELP_MAP, $rootScope) {
    return {
      link: cHelpLinkFn,
      restrict: 'A',
      scope: {
        helpKeyInline: '@?',
        helpLinkAlertCode: '@?',
        helpLinkAlertSeverity: '@?',
      },
    };

    function cHelpLinkFn(scope, elm, attrs) {

      var helpWindow;

      // If this is an alert code based help link and the list of
      // known/documented alerts hasn't already been loaded, do so now.
      if (attrs.helpLinkAlertCode && !documentedAlertIds.length) {
        populateDocumentedAlertIds();
      }

      /**
       * Resolves the Url for the help link based on provided helpKey and
       * cluster setting for local/remote help.
       *
       * @method   getHelpUrl
       * @return   {string}   The help url.
       */
      function getHelpUrl() {
        var isDocumentationLocal = false;
        var isCloudInstall = false;
        var isAzureInstall = false;
        var isAWSInstall = false;
        var isGCPInstall = false;
        var isHeliosAllCluster = false;
        var isAlertCodeLink = !!scope.helpLinkAlertCode && !!scope.helpLinkAlertSeverity;
        var helpQueryParams = '';
        var clusterId;
        var id;
        var helpRoot;
        var helpSubdir;

        if (ClusterService.clusterInfo) {
          // show local docs to tenant's as they are lacking salesforce account needed to access remote docs.
          isDocumentationLocal = ClusterService.clusterInfo.isDocumentationLocal || $rootScope.isTenantUser();
          isCloudInstall = ClusterService.clusterInfo._isCloudInstall;
          isGCPInstall = ClusterService.clusterInfo._isGCPInstall;
          isAWSInstall = ClusterService.clusterInfo._isAWSInstall;
          isAzureInstall = ClusterService.clusterInfo._isAzureInstall;
          clusterId = ClusterService.clusterInfo.id;
          isHeliosAllCluster = ClusterService.basicClusterInfo.mcmMode &&
            ClusterService.clusterInfo._allClusters;
          helpQueryParams = ['?clusterId=', clusterId].join('');
        }

        switch (true) {
          case !!scope.helpKeyInline:
            id = getHelpIdFromMap(scope.helpKeyInline);
            break;

          case isAlertCodeLink:
            id = getHelpIdFromAlertCode(scope.helpLinkAlertCode, scope.helpLinkAlertSeverity);
            break;

          case isCloudInstall && !!$state.current.cloudHelp:
            id = getHelpIdFromMap($state.current.cloudHelp);
            break;

          default:
            id = getHelpIdFromMap($state.current.help || 'index');
        }

        helpRoot = Routes.helpRoot.local;

        switch(true) {
          // If current context is in Helios MultiCluster Mode. Switch to
          // Helios Docs.
          case isHeliosAllCluster && !isAlertCodeLink:
            helpRoot = [
              Routes.helpRoot.remote,
              Routes.helpRoot.heliosSuffix,
            ].join('');
            break;
          // If cluster is configured to use web docs, add the correct sub
          // directory to the URL based on cluster type.
          case !isDocumentationLocal || (isHeliosAllCluster && isAlertCodeLink):
            helpRoot = [
              Routes.helpRoot.remote,
              getTechDocVersionForCluster(),
            ].join('');

            if (isAzureInstall) {
              helpSubdir = Routes.helpSubdir.azureCloud;
            } else if (isAWSInstall) {
              helpSubdir = Routes.helpSubdir.amazonCloud;
            } else if (isGCPInstall) {
              helpSubdir = Routes.helpSubdir.googleCloud;
            } else {
              helpSubdir = Routes.helpSubdir.physical;
            }

            helpRoot = helpRoot.concat(helpSubdir);
            break;
        }

        return [
          helpRoot,
          Routes.helpIndex,
          '#cshid=',
          id,
          helpQueryParams,
        ].join('');
      }

      /**
       * Gets the help identifier from the help map or provides index as
       * default.
       *
       * @method   getHelpIdFromMap
       * @param    {string}    helpKey   The string help key
       * @return   {integer}   The help identifier from map.
       */
      function getHelpIdFromMap(helpKey) {
        return (helpKey && HELP_MAP[helpKey]) ?
          HELP_MAP[helpKey] :

          // Defaults to index
          HELP_MAP.index;
      }

      /**
       * Returns the proper cluster version for the selected cluster.
       *
       * @method    getTechDocVersionForCluster
       * @returns   {string}   The matching techdoc url.
       */
      function getTechDocVersionForCluster() {
        var clusterSoftwareVersion = ClusterService.clusterInfo.clusterSoftwareVersion;
        var clusterVersion = clusterSoftwareVersion.split('_')[0];
        // Remove any patch release version.
        clusterVersion = clusterVersion.replace(/[^0-9.]/g, '');

        // Replace any . version to underscore, as tech doc url replace . with _
        clusterVersion = clusterVersion.replace(/[.]/gi, '_');

        // Trim any clusterVersion ending with _0, 6_4_0 => 6_4.
        clusterVersion = clusterVersion.replace(/(_0)$/g, '');
        return `/${clusterVersion}/`;
      }

      /**
       * Retrieves a list of generated known/documented alert ids from the a
       * dark site text file.
       *
       * @method   populateDocumentedAlertIds
       */
      function populateDocumentedAlertIds() {

        var knownAlertsListUrl = [
          Routes.helpRoot.local,
          Routes.helpAlertList,
        ].join('');

        $http.get(knownAlertsListUrl).then(
          function getKnownAlertsSuccess(resp) {
            if (resp.data) {
              // Replace any whitespace characters (including new line) and split
              // the file contents on comma.
              documentedAlertIds = resp.data.replace(/\s/g, '').split(',');
            }
          }
        );
      }

      /**
       * Transforms an alert code into a CSH help id based on rules provided by
       * doc team in ENG-12780.
       *
       * @method   getHelpIdFromAlertCode
       * @param    {string}   alertCode       The alert code
       * @param    {String}   alertSeverity   The alert severity enum
       * @return   {string}   The help identifier from alert code.
       */
      function getHelpIdFromAlertCode(alertCode, alertSeverity) {

        var defaultAlertCshId = 999999999;

        var severityIntMap = {
          kCritical: 1,
          kWarning: 2,
          kInfo: 3
        };

        if (!alertCode) {
          // Defaults to index
          return HELP_MAP.index;
        }

        alertCode = alertCode
          // Remove all non numeric characters and (assumed) leading 0.
          .replace(/\D/g, '').substring(1);

        // add a 9 to the beginning and end with numeric representation of
        // severity
        alertCode = ['9', alertCode, severityIntMap[alertSeverity]].join('');

        // if the derived alertCode isn't known to be documented,
        // fall back to the default alert CSH id.
        if (!documentedAlertIds.includes(alertCode)) {
          return defaultAlertCshId;
        }

        return alertCode;
      }

      /**
       * Click handler for CSH links.
       *
       * @method   clickHelpElement
       */
      function clickHelpElement() {
        var helpUrl = getHelpUrl();

        if (!helpWindow || helpWindow.closed) {
          helpWindow = window.open(helpUrl);
        } else {
          helpWindow.location = helpUrl;
          helpWindow.focus();
        }
      }

      // listen for click events on the help link
      elm.click(clickHelpElement);

    }
  }

}(angular));
