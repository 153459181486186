import {
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { LegacyThemePalette as ThemePalette } from '@angular/material/legacy-core';
import { MatIconRegistry, MatIconModule } from '@angular/material/icon';
import { of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';

import { IconService } from './icon.service';
import { NgIf } from '@angular/common';

/**
 * Direction type for icons.
 */
export type IconDirection = 'up' | 'right' | 'down' | 'left';

/**
 * Size types for icons.
 */
export type IconSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl' | 'xxxxl';

/**
 * Options for setting icon status.
 */
export type IconStatus = 'info' | 'success' | 'warning' | 'critical' | 'scheduled' | 'skip';

/**
 * Options that can be specified in the type string.
 */
export type IconOption = ThemePalette | IconDirection | IconStatus | 'solid' | 'outline';

/**
 * Parses svg name and icon set from the shape input. Svgs are specified as:
 * `iconSet:name`, or simply `name` if the default icon set is used. Further
 * options can be specified with additional values separated by `:`. For
 * instance: `iconSet:name!down` would rotate the icon 180 degrees.
 * This returns an array of strings with the icon set, icon name, and
 * additional options.
 *
 * @return the parsed shape value.
 */
export const parseIconSvgName = (shape: string): string[] => {
  const [icon, options] = shape.split('!');
  const iconOptions = [...(icon.includes(':') ? icon.split(':') : ['', icon])];
  if (options) {
    iconOptions.push(...options.split(':'));
  }
  return iconOptions;
};

/**
 * The Helix Icon component wraps mat-icon in order to provide a unified api that can support font-based
 * material icons and svg icons. The icon is set using the shape property. cog-icon also supports several
 * additional properties that can be used to style icons. SVG icons can be registered with the MatIconRegistry
 * service, either as resource urls or string literals. SVG icons can also be organized into separate,
 * namespaced icon sets. The syntax for specifying an svg icon is iconSetName:iconName!option1:option2.
 *
 * @example
 * <!-- Material Icon -->
 * <cog-icon shape="home"></cog-icon>
 * <!-- SVG Icon -->
 * <cog-icon shape="demo:iconName"></cog-icon>
 * <!-- Helix Icon -->
 * <cog-icon shape="helix:cluster"></cog-icon>
 */
@Component({
  templateUrl: './icon.component.html',
  selector: 'cog-icon',
  exportAs: 'cogIcon',
  styleUrls: ['./icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [NgIf, MatIconModule]
})
export class IconComponent implements OnChanges {
  /**
   * Color property is passed directly to mat icon. This supports the same theme
   * colors as mat-icon
   *
   * @example
   * <cog-icon shape="home" color="accent"></cog-icon>
   *
   */
  @Input()
  color: ThemePalette;

  /**
   * A string to be used in the role attribute of the icon.
   * Icon role can be set to provide additional information for screen reader.
   * We set it to none when we have just a CSS image wich does not need a role.
   *
   * @example
   * <cog-icon role="none" shape="arrow_downward" size="sm"></cog-icon>
   */
  @Input()
  role: string;

  /**
   * Icon status can be set to provide additional colors for icons.
   */
  @Input()
  status: IconStatus;

  /**
   * Shorthand to specify the rotation of the icon. This could also be done by applying styles
   * directly on the element.
   *
   * @example
   * <cog-icon shape="home" dir="down"></cog-icon>
   */
  @Input()
  dir: IconDirection;

  /**
   * A string to be used in the aria-label attribute of the icon.
   */
  @Input()
  ariaLabel: string;

  /**
   * Inline property is passed directly to mat icon. This this will automatically
   * size the icon to match the font size of the element the icon is contained in.
   *
   * <cog-icon shape="home" [inline]="true"></cog-icon>
   */
  @HostBinding('class.inline')
  @Input()
  inline = false;

  /**
   * If set, this will render the shape as a material icon font value.
   */
  matIcon: string;

  /**
   * The shape property can be either an svg icon or a mat icon name. If it is an
   * svg icon, the svgIcon property will be set, if not the matIcon property will
   * be set. Values after a `!` will be interpreted as options which can be applied
   * to the icon. This allows icon options to be part of the icon string when they
   * are passed to another component that makes it difficult to set them on the
   * component directly.
   * General format is:
   * <iconSet>:<iconName>!<option1>:<option2>
   *
   * The only required part of the format is the icon name.
   *
   * @example
   * home // material icon
   * home!outline // material icon, outline styling
   * home!down // upside down material icon
   * type-active-directory // svg icon with no namespace
   * helix:error // svg icon in helix namespace
   * helix:error!down // svg icon in helix namespace upside down
   * helix:error!down:solid // solid svg icon upside down
   * helix:error!critical // error icon set to critical color
   */
  @Input()
  shape: string;

  /**
   * If set, this will apply a width and height to the icon. That value should be specified as
   * a string. If no units are specified 'rem' will be appended to the value. Defaults to md.
   *
   * @example
   * <cog-icon size="lg" shape="house"></cog-icon>>
   */
  @Input()
  size: IconSize;

  /**
   * Solid icons are provided by default. For icons which support it, this can
   * be set to false to display an outline icon.
   */
  @HostBinding('class.cog-icon-solid')
  @Input()
  solid = true;

  /**
   * Reduces the opacity of the icon so that it appears as inactive.
   */
  @HostBinding('class.inactive')
  @Input()
  inactive = false;

  /**
   * If set, this will render the shape as an svg icon.
   */
  svgIcon: string;

  /**
   * Bind the up class to the host
   */
  @HostBinding('class.cog-icon-up')
  get up(): boolean {
    return this.dir === 'up';
  }

  /**
   * Bind the right class to the host
   */
  @HostBinding('class.cog-icon-right')
  get right(): boolean {
    return this.dir === 'right';
  }

  /**
   * Bind the down class to the host
   */
  @HostBinding('class.cog-icon-down')
  get down(): boolean {
    return this.dir === 'down';
  }

  /**
   * Bind the left class to the host
   */
  @HostBinding('class.cog-icon-left')
  get left(): boolean {
    return this.dir === 'left';
  }

  /**
   * Bind the xs class to the host
   */
  @HostBinding('class.cog-icon-xs')
  get xs(): boolean {
    return this.size === 'xs';
  }

  /**
   * Bind the sm class to the host
   */
  @HostBinding('class.cog-icon-sm')
  get sm(): boolean {
    return this.size === 'sm';
  }

  /**
   * Bind the md class to the host
   */
  @HostBinding('class.cog-icon-md')
  get md(): boolean {
    return this.size === 'md';
  }

  /**
   * Bind the lg class to the host
   */
  @HostBinding('class.cog-icon-lg')
  get lg(): boolean {
    return this.size === 'lg';
  }

  /**
   * Bind the xl class to the host
   */
  @HostBinding('class.cog-icon-xl')
  get xl(): boolean {
    return this.size === 'xl';
  }

  /**
   * Bind the xl class to the host
   */
  @HostBinding('class.cog-icon-xxl')
  get xxl(): boolean {
    return this.size === 'xxl';
  }

  /**
   * Bind the xl class to the host
   */
  @HostBinding('class.cog-icon-xxxl')
  get xxxl(): boolean {
    return this.size === 'xxxl';
  }

  /**
   * Bind the xl class to the host
   */
  @HostBinding('class.cog-icon-xxxxl')
  get xxxxl(): boolean {
    return this.size === 'xxxxl';
  }

  /**
   * Bind the status-info class to the host
   */
  @HostBinding('class.cog-icon-default')
  get default(): boolean {
    return ['scheduled', 'skip'].includes(this.status);
  }

  /**
   * Bind the status-info class to the host
   */
  @HostBinding('class.cog-icon-info')
  get info(): boolean {
    return this.status === 'info';
  }

  /**
   * Bind the status-success class to the host
   */
  @HostBinding('class.cog-icon-success')
  get success(): boolean {
    return this.status === 'success';
  }

  /**
   * Bind the status-warning class to the host
   */
  @HostBinding('class.cog-icon-warning')
  get warning(): boolean {
    return this.status === 'warning';
  }

  /**
   * Bind the status-critical class to the host
   */
  @HostBinding('class.cog-icon-critical')
  get critical(): boolean {
    return this.status === 'critical';
  }

  /**
   * This event is fired whenever the icon's shape input is resolved and we have set a matIcon or an svgIcon property.
   */
  @Output() iconLoaded = new EventEmitter<void>();

  constructor(
    @Attribute('aria-hidden') public ariaHidden: string,
    private cdr: ChangeDetectorRef,
    private iconRegistry: MatIconRegistry,
    iconService: IconService,
    public _elementRef: ElementRef
  ) {
    iconService.initializeIcons();
  }

  /**
   * Updates the component whenever the shape is changed.
   *
   * @param   changes   The changes object.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.shape && !this.shape) {
      // If the shape has been cleared, make sure everything else is cleared.
      this.svgIcon = undefined;
      this.matIcon = undefined;
    } else if (changes.shape && this.shape) {
      const parsedShape = parseIconSvgName(this.shape);

      // Parse additional options on the shape string and update the class
      // with this. This will override inputs
      parsedShape.forEach((prop: string, index: number) => {
        if (index > 1) {
          switch (prop as IconOption) {
            case 'outline':
              this.solid = false;
              break;
            case 'solid':
              this.solid = true;
              break;
            case 'up':
            case 'right':
            case 'down':
            case 'left':
              this.dir = prop as IconDirection;
              break;
            case 'info':
            case 'success':
            case 'warning':
            case 'critical':
            case 'scheduled':
            case 'skip':
              this.status = prop as IconStatus;
              break;
            case 'primary':
            case 'accent':
            case 'warn':
              this.color = prop as ThemePalette;
              break;
          }
        }
      });

      // If the new icon shape is set, try looking it up in the icon registry and
      // use that to determine whether to show a matIcon or an svgIcon.
      this.iconRegistry
        .getNamedSvgIcon(parsedShape[1], parsedShape[0])
        .pipe(
          take(1),
          map(() => true),
          catchError(() => of(false)),
          tap(isSvg => {
            this.svgIcon = isSvg ? `${parsedShape[0]}:${parsedShape[1]}` : undefined;
            this.matIcon = !isSvg ? parsedShape[1] : undefined;
            this.iconLoaded.next();
            this.cdr.markForCheck();
          })
        )
        .subscribe();
    }
  }
}
