/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ExternalTargets } from '../models/external-targets';
import { ExternalTarget } from '../models/external-target';
import { ArchivalMediaInfo } from '../models/archival-media-info';
import { GlobalBandwidthSettings } from '../models/global-bandwidth-settings';
@Injectable({
  providedIn: 'root',
})
class ExternalTargetService extends __BaseService {
  static readonly GetExternalTargetsPath = '/data-protect/external-targets';
  static readonly CreateExternalTargetPath = '/data-protect/external-targets';
  static readonly GetExternalTargetMediaInfoPath = '/data-protect/external-targets/media-info';
  static readonly GetExternalTargetSettingsPath = '/data-protect/external-targets/settings';
  static readonly UpdateExternalTargetSettingsPath = '/data-protect/external-targets/settings';
  static readonly GetExternalTargetByIdPath = '/data-protect/external-targets/{id}';
  static readonly UpdateExternalTargetPath = '/data-protect/external-targets/{id}';
  static readonly DeleteExternalTargetPath = '/data-protect/external-targets/{id}';
  static readonly GetExternalTargetEncryptionKeyInfoPath = '/data-protect/external-targets/{id}/encryption-key';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get the list of External Targets.
   *
   * Get the list of External Targets.
   * @param params The `ExternalTargetService.GetExternalTargetsParams` containing the following parameters:
   *
   * - `storageTypes`: Filter by a list of External Target storage types. Nas option in archival_target_storage_type will soon be deprecated. Please use NAS instead.
   *
   * - `storageClasses`: Filter by a list of External Target storage classes.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `purposeTypes`: Filter by a list of External Target purpose types.
   *
   * - `ownershipContexts`: Specifies whether how this external target is being consumed either Local or FortKnox.
   *
   * - `names`: Filter by a list of External Target names.
   *
   * - `ids`: Filter by a list of External Target ids.
   *
   * - `globalIds`: Filter by a list of External Target global ids.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetExternalTargetsResponse(params: ExternalTargetService.GetExternalTargetsParams): __Observable<__StrictHttpResponse<ExternalTargets>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.storageTypes || []).forEach(val => {if (val != null) __params = __params.append('storageTypes', val.toString())});
    (params.storageClasses || []).forEach(val => {if (val != null) __params = __params.append('storageClasses', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.purposeTypes || []).forEach(val => {if (val != null) __params = __params.append('purposeTypes', val.toString())});
    (params.ownershipContexts || []).forEach(val => {if (val != null) __params = __params.append('ownershipContexts', val.toString())});
    (params.names || []).forEach(val => {if (val != null) __params = __params.append('names', val.toString())});
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.globalIds || []).forEach(val => {if (val != null) __params = __params.append('globalIds', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/external-targets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExternalTargets>;
      })
    );
  }
  /**
   * Get the list of External Targets.
   *
   * Get the list of External Targets.
   * @param params The `ExternalTargetService.GetExternalTargetsParams` containing the following parameters:
   *
   * - `storageTypes`: Filter by a list of External Target storage types. Nas option in archival_target_storage_type will soon be deprecated. Please use NAS instead.
   *
   * - `storageClasses`: Filter by a list of External Target storage classes.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `purposeTypes`: Filter by a list of External Target purpose types.
   *
   * - `ownershipContexts`: Specifies whether how this external target is being consumed either Local or FortKnox.
   *
   * - `names`: Filter by a list of External Target names.
   *
   * - `ids`: Filter by a list of External Target ids.
   *
   * - `globalIds`: Filter by a list of External Target global ids.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetExternalTargets(params: ExternalTargetService.GetExternalTargetsParams): __Observable<ExternalTargets> {
    return this.GetExternalTargetsResponse(params).pipe(
      __map(_r => _r.body as ExternalTargets)
    );
  }

  /**
   * Create a External Target.
   *
   * Create a External Target.
   * @param params The `ExternalTargetService.CreateExternalTargetParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a External Target.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateExternalTargetResponse(params: ExternalTargetService.CreateExternalTargetParams): __Observable<__StrictHttpResponse<ExternalTarget>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/external-targets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExternalTarget>;
      })
    );
  }
  /**
   * Create a External Target.
   *
   * Create a External Target.
   * @param params The `ExternalTargetService.CreateExternalTargetParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a External Target.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateExternalTarget(params: ExternalTargetService.CreateExternalTargetParams): __Observable<ExternalTarget> {
    return this.CreateExternalTargetResponse(params).pipe(
      __map(_r => _r.body as ExternalTarget)
    );
  }

  /**
   * List archive media information
   *
   * Returns the media information about the specified archive service uid (such as a QStar tape archive service).
   * @param params The `ExternalTargetService.GetExternalTargetMediaInfoParams` containing the following parameters:
   *
   * - `clusterIncarnationId`: Specifies the incarnation Id of the Cohesity cluster which archived to a QStart media target.
   *
   * - `clusterId`: Specifies the id of the Cohesity cluster which archived to a QStart media target.
   *
   * - `archivalJobId`: Specifies the id of the Job that archived to a QStar media Vault.
   *
   * - `restoreTaskId`: Specifies the id of the restore task to optionally filter by.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `entityIds`: Specifies an array of entityIds to optionally filter by. An entityId is a unique id for a VM assigned by the Cohesity Cluster.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetExternalTargetMediaInfoResponse(params: ExternalTargetService.GetExternalTargetMediaInfoParams): __Observable<__StrictHttpResponse<ArchivalMediaInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.clusterIncarnationId != null) __params = __params.set('clusterIncarnationId', params.clusterIncarnationId.toString());
    if (params.clusterId != null) __params = __params.set('clusterId', params.clusterId.toString());
    if (params.archivalJobId != null) __params = __params.set('archivalJobId', params.archivalJobId.toString());
    if (params.restoreTaskId != null) __params = __params.set('restoreTaskId', params.restoreTaskId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.entityIds || []).forEach(val => {if (val != null) __params = __params.append('entityIds', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/external-targets/media-info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ArchivalMediaInfo>;
      })
    );
  }
  /**
   * List archive media information
   *
   * Returns the media information about the specified archive service uid (such as a QStar tape archive service).
   * @param params The `ExternalTargetService.GetExternalTargetMediaInfoParams` containing the following parameters:
   *
   * - `clusterIncarnationId`: Specifies the incarnation Id of the Cohesity cluster which archived to a QStart media target.
   *
   * - `clusterId`: Specifies the id of the Cohesity cluster which archived to a QStart media target.
   *
   * - `archivalJobId`: Specifies the id of the Job that archived to a QStar media Vault.
   *
   * - `restoreTaskId`: Specifies the id of the restore task to optionally filter by.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `entityIds`: Specifies an array of entityIds to optionally filter by. An entityId is a unique id for a VM assigned by the Cohesity Cluster.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetExternalTargetMediaInfo(params: ExternalTargetService.GetExternalTargetMediaInfoParams): __Observable<ArchivalMediaInfo> {
    return this.GetExternalTargetMediaInfoResponse(params).pipe(
      __map(_r => _r.body as ArchivalMediaInfo)
    );
  }

  /**
   * Get the list of External Target Settings.
   *
   * Get the list of External Target Settings
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetExternalTargetSettingsResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<ExternalTarget>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/external-targets/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExternalTarget>;
      })
    );
  }
  /**
   * Get the list of External Target Settings.
   *
   * Get the list of External Target Settings
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetExternalTargetSettings(regionId?: string,
    accessClusterId?: number): __Observable<ExternalTarget> {
    return this.GetExternalTargetSettingsResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as ExternalTarget)
    );
  }

  /**
   * Update External Target Settings
   *
   * Update External Target Settings
   * @param params The `ExternalTargetService.UpdateExternalTargetSettingsParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to update a External Target Settings.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateExternalTargetSettingsResponse(params: ExternalTargetService.UpdateExternalTargetSettingsParams): __Observable<__StrictHttpResponse<GlobalBandwidthSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/data-protect/external-targets/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GlobalBandwidthSettings>;
      })
    );
  }
  /**
   * Update External Target Settings
   *
   * Update External Target Settings
   * @param params The `ExternalTargetService.UpdateExternalTargetSettingsParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to update a External Target Settings.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateExternalTargetSettings(params: ExternalTargetService.UpdateExternalTargetSettingsParams): __Observable<GlobalBandwidthSettings> {
    return this.UpdateExternalTargetSettingsResponse(params).pipe(
      __map(_r => _r.body as GlobalBandwidthSettings)
    );
  }

  /**
   * List details about single External Target.
   *
   * Returns the External Target corresponding to the specified Group id.
   * @param params The `ExternalTargetService.GetExternalTargetByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the External Target.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetExternalTargetByIdResponse(params: ExternalTargetService.GetExternalTargetByIdParams): __Observable<__StrictHttpResponse<ExternalTarget>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/external-targets/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExternalTarget>;
      })
    );
  }
  /**
   * List details about single External Target.
   *
   * Returns the External Target corresponding to the specified Group id.
   * @param params The `ExternalTargetService.GetExternalTargetByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the External Target.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetExternalTargetById(params: ExternalTargetService.GetExternalTargetByIdParams): __Observable<ExternalTarget> {
    return this.GetExternalTargetByIdResponse(params).pipe(
      __map(_r => _r.body as ExternalTarget)
    );
  }

  /**
   * Update a External Target.
   *
   * Update the specified External Target.
   * @param params The `ExternalTargetService.UpdateExternalTargetParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the External Target.
   *
   * - `body`: Specifies the parameters to update a External Target.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateExternalTargetResponse(params: ExternalTargetService.UpdateExternalTargetParams): __Observable<__StrictHttpResponse<ExternalTarget>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/data-protect/external-targets/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExternalTarget>;
      })
    );
  }
  /**
   * Update a External Target.
   *
   * Update the specified External Target.
   * @param params The `ExternalTargetService.UpdateExternalTargetParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the External Target.
   *
   * - `body`: Specifies the parameters to update a External Target.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateExternalTarget(params: ExternalTargetService.UpdateExternalTargetParams): __Observable<ExternalTarget> {
    return this.UpdateExternalTargetResponse(params).pipe(
      __map(_r => _r.body as ExternalTarget)
    );
  }

  /**
   * Delete a External Target.
   *
   * Returns Success if the External Target is deleted.
   * @param params The `ExternalTargetService.DeleteExternalTargetParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the External Target.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `forceDelete`: Specifies whether to force delete the External target.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteExternalTargetResponse(params: ExternalTargetService.DeleteExternalTargetParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.forceDelete != null) __params = __params.set('forceDelete', params.forceDelete.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/data-protect/external-targets/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a External Target.
   *
   * Returns Success if the External Target is deleted.
   * @param params The `ExternalTargetService.DeleteExternalTargetParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the External Target.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `forceDelete`: Specifies whether to force delete the External target.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteExternalTarget(params: ExternalTargetService.DeleteExternalTargetParams): __Observable<null> {
    return this.DeleteExternalTargetResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get the encryption key info for an external target
   *
   * Get the encryption key info for an external target
   * @param params The `ExternalTargetService.GetExternalTargetEncryptionKeyInfoParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the External Target.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetExternalTargetEncryptionKeyInfoResponse(params: ExternalTargetService.GetExternalTargetEncryptionKeyInfoParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/external-targets/${encodeURIComponent(params.id)}/encryption-key`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Get the encryption key info for an external target
   *
   * Get the encryption key info for an external target
   * @param params The `ExternalTargetService.GetExternalTargetEncryptionKeyInfoParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the External Target.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetExternalTargetEncryptionKeyInfo(params: ExternalTargetService.GetExternalTargetEncryptionKeyInfoParams): __Observable<Blob> {
    return this.GetExternalTargetEncryptionKeyInfoResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }
}

module ExternalTargetService {

  /**
   * Parameters for GetExternalTargets
   */
  export interface GetExternalTargetsParams {

    /**
     * Filter by a list of External Target storage types. Nas option in archival_target_storage_type will soon be deprecated. Please use NAS instead.
     */
    storageTypes?: Array<'Azure' | 'Google' | 'AWS' | 'Oracle' | 'Nas' | 'NAS' | 'QStarTape' | 'S3Compatible'>;

    /**
     * Filter by a list of External Target storage classes.
     */
    storageClasses?: Array<'AmazonS3Standard' | 'AmazonS3StandardIA' | 'AmazonS3OneZoneIA' | 'AmazonS3IntelligentTiering' | 'AmazonS3Glacier' | 'AmazonS3GlacierDeepArchive' | 'AmazonGlacier' | 'AmazonS3GlacierIR' | 'AzureArchiveBlob' | 'AzureCoolBlob' | 'AzureHotBlob' | 'GCPStandard' | 'GCPColdline' | 'GCPNearline' | 'OracleObjectStorage' | 'OracleArchiveStorage' | 'S3CompatibleRegular' | 'S3CompatibleTapeBased'>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Filter by a list of External Target purpose types.
     */
    purposeTypes?: Array<'Archival' | 'Tiering' | 'Rpaas'>;

    /**
     * Specifies whether how this external target is being consumed either Local or FortKnox.
     */
    ownershipContexts?: Array<'Local' | 'FortKnox'>;

    /**
     * Filter by a list of External Target names.
     */
    names?: Array<string>;

    /**
     * Filter by a list of External Target ids.
     */
    ids?: Array<number>;

    /**
     * Filter by a list of External Target global ids.
     */
    globalIds?: Array<string>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateExternalTarget
   */
  export interface CreateExternalTargetParams {

    /**
     * Specifies the parameters to create a External Target.
     */
    body: ExternalTarget;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetExternalTargetMediaInfo
   */
  export interface GetExternalTargetMediaInfoParams {

    /**
     * Specifies the incarnation Id of the Cohesity cluster which archived to a QStart media target.
     */
    clusterIncarnationId: number;

    /**
     * Specifies the id of the Cohesity cluster which archived to a QStart media target.
     */
    clusterId: number;

    /**
     * Specifies the id of the Job that archived to a QStar media Vault.
     */
    archivalJobId: number;

    /**
     * Specifies the id of the restore task to optionally filter by.
     */
    restoreTaskId?: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies an array of entityIds to optionally filter by. An entityId is a unique id for a VM assigned by the Cohesity Cluster.
     */
    entityIds?: Array<number>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateExternalTargetSettings
   */
  export interface UpdateExternalTargetSettingsParams {

    /**
     * Specifies the parameters to update a External Target Settings.
     */
    body: GlobalBandwidthSettings;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetExternalTargetById
   */
  export interface GetExternalTargetByIdParams {

    /**
     * Specifies a unique id of the External Target.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateExternalTarget
   */
  export interface UpdateExternalTargetParams {

    /**
     * Specifies the id of the External Target.
     */
    id: number;

    /**
     * Specifies the parameters to update a External Target.
     */
    body: ExternalTarget;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteExternalTarget
   */
  export interface DeleteExternalTargetParams {

    /**
     * Specifies a unique id of the External Target.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies whether to force delete the External target.
     */
    forceDelete?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetExternalTargetEncryptionKeyInfo
   */
  export interface GetExternalTargetEncryptionKeyInfoParams {

    /**
     * Specifies the id of the External Target.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { ExternalTargetService }
