// Reports: Directory Quotas Per View

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.reports')
    .controller('reportsDirQuotasController', reportsDirQuotasControllerFn);

  function reportsDirQuotasControllerFn($state, $q, $filter, ViewService,
    ReportsUtil, evalAJAX, cReportsControlsUtil) {

    var quotasCtrl = this;

    angular.extend(quotasCtrl, {
      filters: cReportsControlsUtil.filters,
      dirQuotasConfig: ReportsUtil.getReportConfig('kDirQuotasReport')(),

      // Public Methods
      downloadDirQuotasReport: downloadDirQuotasReport,
      getDirQuotas: getDirQuotas,
      updateFiltersCb: updateFiltersCb,

      // List of Quotas & Usage for display in the report
      quotaList: [],
    });

    /**
     * Loads Controller Data
     *
     * If we are deep linking we need to load data based of query params in the
     * URL
     *
     * Calls API to populate data for Type Ahead
     *
     * @method     _activate
     */
    function _activate() {
      quotasCtrl.dataset = {};

      cReportsControlsUtil.getDefaultFilters();

      $q.all(quotasCtrl.dirQuotasConfig.datasetPromiseObject).then(
        function getDataset(dataset) {
          quotasCtrl.dataset = dataset;

          // Put on scope any pre-filtered values.
          quotasCtrl.viewName = $state.params.viewName;

          // If any query params, then set the filters accordingly.
          if (quotasCtrl.viewName) {
            quotasCtrl.activeFilters = true;
            cReportsControlsUtil.updateFilters({
              typeAhead: {
                // Need to add the name as id because the name is the id as
                // well.
                id: quotasCtrl.viewName,
                name: quotasCtrl.viewName,
              },
              viewName: quotasCtrl.viewName,
            });

            getDirQuotas(cReportsControlsUtil.filters);
          }
        }, evalAJAX.errorMessage)
      .finally(function getDatasetFinally() {
        quotasCtrl.reportsControlsDataReady = true;
      });
    }

    /**
     * Returns params object for API Call
     *
     * @method     _getParams
     *
     * @param      {Object}  filters  filters obj from c-reports-controls
     * @return     {Object}  params object for API Call
     */
    function _getParams(filters) {
      return {
        viewName: filters.viewName,
        maxEntries: undefined,
        cookie: undefined,
      };
    }

    /**
     * Initiates CSV download.
     *
     * @method     downloadDirQuotasReport
     */
    function downloadDirQuotasReport() {
      var params = _getParams(cReportsControlsUtil.filters);
      params.outputFormat = 'csv';

      ViewService.getDirectoryQuotas(params);
    }

    /**
     * Refreshes data when applying new filters
     *
     * @method     getDirQuotas
     *
     * @param      {object}  filters  filters obj from c-reports-controls
     */
    function getDirQuotas(filters) {
      var requestParams = _getParams(filters);

      quotasCtrl.showReportPerDir = !requestParams.viewName;
      quotasCtrl.summaryReady = false;
      quotasCtrl.dataReady = false;
      quotasCtrl.quotaList.length = 0;


      // Get quotas and usage
      ViewService.getDirectoryQuotas(requestParams)
        .then(function getQuotasReportSuccess(response) {
          quotasCtrl.quotaList = response.quotas || [];
        },
        _.noop
      ).finally(function getQuotasReportFinally() {
        quotasCtrl.dataReady = true;
      });
    }

    /**
     * When new filters are set update the url for context
     *
     * This is how you update the browser URL without reloading the controller.
     *
     * Also we need to call new API query after filters are updated to get new
     * data based off updated filters.
     *
     * @param      {object}  filters  filters from c-reports-controls
     */
    function updateFiltersCb(filters) {
      quotasCtrl.activeFilters = true;

      // Transform value property name to match expected.
      filters.viewName = filters.typeAhead && filters.typeAhead.name;

      // Put these on controller scope for broader access. Also, the filters
      // properties are strings so we convert empty strings to undefined` for
      // proper handling as query params. Also in the subsequent `if` block.
      quotasCtrl.viewName = filters.viewName = filters.viewName || undefined;

      if (!filters.viewName) {
        _resetReport();
        return;
      }

      $state.go('.', {
        viewName: filters.viewName,
      });

      getDirQuotas(filters);
    }

    /**
     * Clears the objects filter. This resets the scope values so the UI can go
     * back to its initial state.
     *
     * @method     _resetReport
     */
    function _resetReport() {
      quotasCtrl.quotaList.length = 0;
      quotasCtrl.activeFilters = false;

      $state.go('.', {}, {inherit: false});
    }

    _activate();
  }
})(angular);
