<cog-settings-list-item [formGroup]="formGroup"
  [invalid]="formControl.invalid"
  [label]="'exclusions' | translate">
  <cog-settings-list-item-value-display>
    <p>
      {{'excludeDisksColon' | translate}} {{excludeDisksEnabledControl.value | boolLabel}}
    </p>
    <p>
      {{'labelColonValue' | translate: {
        label: 'protectionGroups.excludePhysicalRdmDisks' | translate,
        value: exclusionFormGroup.get('excludePhysicalRdm').value | boolLabel } }}
    </p>
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor [formGroup]="exclusionFormGroup"
    cogDataId="settings-disk-exclusions">
    <mat-slide-toggle [formControl]="excludeDisksEnabledControl"
      [inheritDataId]="true"
      cogDataId="exclude-disks-toggle">
      {{'excludeDisks' | translate}}
    </mat-slide-toggle>
    <div *ngIf="excludeDisksEnabledControl.value"
      class="margin-top margin-bottom-sm">
      <cog-banner status="info" class="margin-bottom-sm"> {{'job.settings.excludeDisks.selectDisks' | translate}}</cog-banner>
      <div *ngFor="let diskFormGroup of excludeDisksDisksControl.controls; let index = index;"
        [formGroup]="diskFormGroup"
        class="disk">
        <mat-form-field class="controller-type-field">
          <mat-select [formControl]="diskFormGroup.get('controllerType')"
            id="policy-selector"
            [inheritDataId]="true"
            cogDataId="select"
            placeholder="{{'controllerType' | translate}}">
            <mat-option [inheritDataId]="true"
              cogDataId="option-scsi"
              value="kScsi">
              SCSI
            </mat-option>
            <mat-option [inheritDataId]="true"
              cogDataId="option-ide"
              value="kIde">
              IDE
            </mat-option>
            <mat-option [inheritDataId]="true"
              cogDataId="option-sata"
              value="kSata">
              SATA
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="controller-bus-number-field">
          <mat-label translate>controllerBusNumber</mat-label>
          <input [formControl]="diskFormGroup.get('busNumber')"
            [inheritDataId]="true"
            cogDataId="bus-number-value"
            matInput
            min="0"
            required
            type="number">
          <mat-error *ngIf="diskFormGroup.get('busNumber').invalid">
            {{'errors.required' | translate}}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="unit-number-field">
          <mat-label translate>unitNumber</mat-label>
          <input [formControl]="diskFormGroup.get('unitNumber')"
            [inheritDataId]="true"
            cogDataId="unit-number-value"
            matInput
            min="0"
            required
            type="number">
          <mat-error *ngIf="diskFormGroup.get('unitNumber').invalid">
            {{'errors.required' | translate}}
          </mat-error>
        </mat-form-field>
        <button (click)="removeDiskFormControl(index)"
          mat-icon-button
          type="button">
          <cog-icon shape="clear"></cog-icon>
        </button>
      </div>
      <button (click)="addDiskFormControl()"
        [inheritDataId]="true"
        cogDataId="add-button"
        class="margin-bottom-sm"
        color="primary"
        mat-stroked-button
        type="button">
        <cog-icon shape="add"></cog-icon>
        {{'add' | translate}}
      </button>
    </div>
    <ng-container>
      <mat-slide-toggle class="margin-top-sm"
        [inheritDataId]="true"
        cogDataId="exclude-rdm-toggle"
        formControlName="excludePhysicalRdm">
        {{'protectionGroups.excludePhysicalRdmDisks' | translate}}
      </mat-slide-toggle>
      <mat-hint>
        {{'protectionGroups.excludePhysicalRdmDisks.hint' | translate}}
      </mat-hint>
    </ng-container>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
