import { Injector } from '@angular/core';
import { createCustomElement } from '@angular/elements';

/**
 * A utility function to create web component.
 *
 * @param component  The class of web component
 * @param selector   The selector to be used for the web component
 * @param injector   Injector
 * @param prefix     Prefix of selector
 */
export function createElement(
  component: any,
  selector: string,
  injector: Injector,
  prefix: string = 'coe'
) {
  const name = `${prefix}-${selector}`;

  if (!customElements.get(name)) {
    const ngComponent = createCustomElement(component, { injector });
    customElements.define(name, ngComponent);
  }
}
