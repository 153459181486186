import { CursorLocation, GetRawSuggestionFn } from './advanced-search.models';

/**
 * Advanced Search Suggester acts as an abstract interface, used for providing suggestions for the advanced
 * search component. Concrete implementation of this class provides the suggestions for value formats that
 * need to be shown to the user based on current input and cursor location.
 * Exisitng concrete implementations:
 * 1. AntlrSuggester - Uses the antlr4ts library to generate suggestions.
 * (libs/helix/src/lib/components/advanced-search/antlr-suggester.ts)
 */
export abstract class AdvancedSearchSuggester {
  /**
   * The search query string input by user.
   */
  searchQuery: string;

  /**
   * The current cursor location.
   */
  cursorLocation: CursorLocation;

  /**
   * Used to get suggestions for value formats.
   *
   * @param searchQuery     The search query.
   * @param cursorLocation  Current cursor location.
   */
  abstract getRawSuggestion: GetRawSuggestionFn;
}
