/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { IdentityProviders } from '../models/identity-providers';
import { IdentityProvider } from '../models/identity-provider';
import { CreateOrUpdateIdentityProviderRequest } from '../models/create-or-update-identity-provider-request';
@Injectable({
  providedIn: 'root',
})
class HeliosIdentityProviderService extends __BaseService {
  static readonly GetMcmIdentityProvidersPath = '/mcm/identity-providers';
  static readonly CreateMcmIdentityProviderPath = '/mcm/identity-providers';
  static readonly UpdateMcmIdentityProviderPath = '/mcm/identity-providers/{idpId}';
  static readonly DeleteMcmIdentityProviderPath = '/mcm/identity-providers/{idpId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get the list of IDP configurations
   *
   * Get the list of Identity Providers (IDP) configurations.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param ids Filter by a list of IDP ids.
   * @return Success
   */
  GetMcmIdentityProvidersResponse(regionId?: string,
    ids?: Array<number>): __Observable<__StrictHttpResponse<IdentityProviders>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    (ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/identity-providers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IdentityProviders>;
      })
    );
  }
  /**
   * Get the list of IDP configurations
   *
   * Get the list of Identity Providers (IDP) configurations.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param ids Filter by a list of IDP ids.
   * @return Success
   */
  GetMcmIdentityProviders(regionId?: string,
    ids?: Array<number>): __Observable<IdentityProviders> {
    return this.GetMcmIdentityProvidersResponse(regionId, ids).pipe(
      __map(_r => _r.body as IdentityProviders)
    );
  }

  /**
   * Create an Identity Provider Configuration
   *
   * Create Identity Provider (IDP) Configuration. This API defines new version of IDP APIs. The current implementation of the API supports integrating OIDC protocol.
   * @param body Specifies the parameters to create an Identity Provider.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  CreateMcmIdentityProviderResponse(body: CreateOrUpdateIdentityProviderRequest,
    regionId?: string): __Observable<__StrictHttpResponse<IdentityProvider>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/identity-providers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IdentityProvider>;
      })
    );
  }
  /**
   * Create an Identity Provider Configuration
   *
   * Create Identity Provider (IDP) Configuration. This API defines new version of IDP APIs. The current implementation of the API supports integrating OIDC protocol.
   * @param body Specifies the parameters to create an Identity Provider.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  CreateMcmIdentityProvider(body: CreateOrUpdateIdentityProviderRequest,
    regionId?: string): __Observable<IdentityProvider> {
    return this.CreateMcmIdentityProviderResponse(body, regionId).pipe(
      __map(_r => _r.body as IdentityProvider)
    );
  }

  /**
   * Update an IDP Configuration
   *
   * Update the specified Identity Provider Configuration. Please note that though this is a PUT call, we will be disabling updating certain fields such as signonProtocol, issuerId, etc.
   * @param params The `HeliosIdentityProviderService.UpdateMcmIdentityProviderParams` containing the following parameters:
   *
   * - `idpId`: Specifies the ID of the IDP configuration.
   *
   * - `body`: Specifies the parameters to update IDP configuration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  UpdateMcmIdentityProviderResponse(params: HeliosIdentityProviderService.UpdateMcmIdentityProviderParams): __Observable<__StrictHttpResponse<IdentityProvider>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/mcm/identity-providers/${encodeURIComponent(params.idpId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IdentityProvider>;
      })
    );
  }
  /**
   * Update an IDP Configuration
   *
   * Update the specified Identity Provider Configuration. Please note that though this is a PUT call, we will be disabling updating certain fields such as signonProtocol, issuerId, etc.
   * @param params The `HeliosIdentityProviderService.UpdateMcmIdentityProviderParams` containing the following parameters:
   *
   * - `idpId`: Specifies the ID of the IDP configuration.
   *
   * - `body`: Specifies the parameters to update IDP configuration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  UpdateMcmIdentityProvider(params: HeliosIdentityProviderService.UpdateMcmIdentityProviderParams): __Observable<IdentityProvider> {
    return this.UpdateMcmIdentityProviderResponse(params).pipe(
      __map(_r => _r.body as IdentityProvider)
    );
  }

  /**
   * Delete an IDP configuration
   *
   * Returns Success if the Identity Provider configuration is deleted.
   * @param idpId Specifies the unique ID of the IDP.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  DeleteMcmIdentityProviderResponse(idpId: number,
    regionId?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/mcm/identity-providers/${encodeURIComponent(idpId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete an IDP configuration
   *
   * Returns Success if the Identity Provider configuration is deleted.
   * @param idpId Specifies the unique ID of the IDP.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  DeleteMcmIdentityProvider(idpId: number,
    regionId?: string): __Observable<null> {
    return this.DeleteMcmIdentityProviderResponse(idpId, regionId).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module HeliosIdentityProviderService {

  /**
   * Parameters for UpdateMcmIdentityProvider
   */
  export interface UpdateMcmIdentityProviderParams {

    /**
     * Specifies the ID of the IDP configuration.
     */
    idpId: number;

    /**
     * Specifies the parameters to update IDP configuration.
     */
    body: CreateOrUpdateIdentityProviderRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;
  }
}

export { HeliosIdentityProviderService }
