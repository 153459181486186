<div class="auth-modes text-center">
  <button
    *ngIf="currentAuthMode !== 'local' && authModes.includes('local')"
    class="auth-mode-back cog-link-button"
    cogDataId="auth-mode-back"
    (click)="changeAuthMode('local')">
    {{(currentAuthMode === 'sso' ? 'login.withoutSSO': 'login.withoutAD') | translate}}
  </button>
  <button
    *ngIf="currentAuthMode !== 'salesforce' &&
    currentAuthMode !== 'local' &&
    authModes.includes('salesforce') &&
    !authModes.includes('local')"
    class="cog-link-button"
    (click)="changeAuthMode('salesforce')"
    cogDataId="auth-mode-salesforce">
    {{(currentAuthMode === 'sso' ? 'login.withoutSSO': 'login.withoutAD') | translate}}
  </button>
  <button
    *ngIf="currentAuthMode !== 'ad' && authModes.includes('ad')"
    class="cog-link-button"
    (click)="changeAuthMode('ad')"
    cogDataId="auth-mode-ad">
    {{ 'login.signInWithAzureAd' | translate }}
  </button>
  <button
    *ngIf="currentAuthMode !== 'sso' && authModes.includes('sso')"
    class="cog-link-button"
    (click)="changeAuthMode('sso')"
    cogDataId="auth-mode-sso">
    {{'login.signInWithSSO' | translate}}
  </button>
</div>