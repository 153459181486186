import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AutoDestroyable } from '@cohesity/utils';
import { ObservableInput } from 'ngx-observable-input';
import { merge, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';

/**
 * Search field for recovery search. This emits changes when a user inputs a value. Search
 * is automatically triggered based on changes, or when a user presses enter.
 */
@Component({
  selector: 'coh-object-search-field',
  templateUrl: './object-search-field.component.html',
  styleUrls: ['./object-search-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectSearchFieldComponent extends AutoDestroyable implements OnInit {
  /**
   * Placeholder text to display in the input field before a user enters a value.
   */
  @Input() placeholderText: string;

  /**
   * Flag to show a loading spinner when the query is running.
   */
  @Input() searchPending = false;

  /**
   * ObservableInput for default query string.
   * This is used to update the query value from the advanced search dialog.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('query') query$: Subject<string>;

  /**
   * Output indicating that a search should be triggered.
   */
  @Output() queryChanged = new EventEmitter<string>();

  /**
   * The input search control.
   */
  searchControl = new UntypedFormControl();

  /**
   * Subject that triggers when a user presses enter.
   */
  keyUp = new Subject<string>();

  constructor(
    private irisCtxService: IrisContextService,
  ) {
    super();
  }

  /**
   * Listen for input changes on a debounce time, or enter events. Don't emit the event unless the search
   * query has changed.
   */
  ngOnInit() {
    // Update the search control value without emitting event to update the query string
    // when user searches from the advanced search dialog.
    this.query$.pipe(
      this.untilDestroy(),
      distinctUntilChanged(),
      filter(query => this.searchControl.value !== query)
    ).subscribe((query: string) => {
      this.searchControl.setValue(query, { onlySelf: true, emitEvent: false });
    });

    if (this.autoSearchEnabled) {
      merge(this.searchControl.valueChanges.pipe(debounceTime(2000)), this.keyUp)
        .pipe(
          this.untilDestroy(),
          distinctUntilChanged()
        )
        .subscribe(query => this.queryChanged.emit(query));
    }
  }

  /**
   * @returns True if auto search is enabled.
   */
  get autoSearchEnabled(): boolean {
    return flagEnabled(this.irisCtxService.irisContext, 'autoSearchEnabled');
  }
}
