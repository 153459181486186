import { Injectable } from '@angular/core';
import { GetRegistrationInfoResponse, ProtectionSourcesServiceApi } from '@cohesity/api/v1';
import {
  IndexedObjectSnapshot,
  ObjectServiceApi,
  ObjectSnapshot,
  ProtectedObject,
  ProtectionGroup,
  ProtectionGroups,
  ProtectionGroupServiceApi,
  SearchIndexedObjectsRequest,
  SearchServiceApi,
} from '@cohesity/api/v2';
import { DataFilterValue, DateFilterRange, SnackBarService, ValueFilterSelection } from '@cohesity/helix';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { maxBy } from 'lodash';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, finalize, map, shareReplay, tap } from 'rxjs/operators';
import { Environment, JobEnvParamsV2, leafObjectTypeMap, parentObjectTypeMap, RecoveryAction, sourceTypesDisplayNames } from 'src/app/shared';

import { ObjectSearchProvider } from '../../restore-shared';
import {
  EntityObjectTypesEnvMap,
  FilterParams,
  ObjectTypeToEntityTypeMap,
  SearchResponseEnvParamsMap,
  SourceObjectTypeEnvMap,
  SourceObjectTypeEnvParamsMap,
} from '../../restore-shared/model/nosql-common';
import { NoSqlSearchResultGroup } from '../../restore-shared/model/nosql-search-result-group';
import {
  NoSqlObjectSearchResult,
  NoSqlProtectionGroupSearchResult,
  NoSqlSearchResult,
} from '../../restore-shared/model/nosql-search-results';
import { RestorePointSelection } from '../../restore-shared/model/restore-point-selection';
import { RestoreSearchResult } from '../../restore-shared/model/restore-search-result';
import { DecoratedIndexedObjectSnapshot } from '../snapshot-selector';
import { RestoreConfigService } from './restore-config.service';

/**
 * Convenience type to reference a data filter value for a property filter
 */
type PropertyDataFilterValue = DataFilterValue<ProtectedObject, ValueFilterSelection[]>;

/**
 * Convenience type to reference a data filter value for a date range filter
 */
type DateRangeDataFilterValue = DataFilterValue<ProtectedObject, DateFilterRange<Date>>;

/* Spilter string for NoSQL Objects
*/
export const noSqlPathSplitter: { [key in Environment]?: string } = {
 kCassandra: '.',
 kHBase: ':',
 kHive: '.',
 kMongoDB: '.',
};

/**
 * This is a recover search provider specific to vm only. It uses the restore service to do a normal
 * object search, then adds protection groups to the results. It also includes utilities selecting a
 * specific protection group run.
 */
@Injectable()
export class RecoverNoSqlSearchService implements ObjectSearchProvider {

  /**
   * This is the restore operation type this restore service is configured for. Each restore component is
   * responsible for setting this value. This is used to look up all of the environments that should be
   * included in search filters.
   */
  restoreTypes: RecoveryAction[];

  /**
   * The list of environments to use for search queries. This is determined by the
   * list of registered adapters registered for for RecoverVMs
   */
  get searchEnvironment(): Environment {
    return this.restoreConfig.getSearchEnvironments(this.restoreTypes)[0];
  }

  /**
   * Map of protection group ids and their names.
   */
  private protectionGroupsMap = new Map<string, string>();

  /**
   * Wrapper observable for the get protection gorups API call.
   */
  private getProtectionGroups$: Observable<Map<string, string>>;

  /**
   * Implicit Object Ids list behavior subject.
   */
  private implicitObjectIds = new BehaviorSubject<(number | string)[]>([]);

  /**
   * The list of runs implicit object Ids that are part of the already
   * included in the selection as part of a parent object's selection.
   */
  implicitObjectIds$ = this.implicitObjectIds.asObservable();

  /**
   * Object type values to populate the object type filter that can be applied
   * to the recovery search results.
   */
  objectTypeFilterValues: Observable<ValueFilterSelection[]>;

  /**
   * Environment specific source param name for the V2 search Indexed Objects API request object.
   */
  get searchRequestEnvParamsKey(): string {
    return JobEnvParamsV2[this.searchEnvironment];
  }

  /**
   * Environment specific recovery source object types for V2 search Indexed Objects API request object.
   */
  get noSqlSourceObjectType(): string {
    return SourceObjectTypeEnvMap[this.searchEnvironment];
  }

  /**
   * Environment specific objects list param name the for V2 search Indexed Objects API response.
   */
  get searchResponseEnvParamsKey(): string {
    return SearchResponseEnvParamsMap[this.searchEnvironment];
  }

  /**
   * Environment specific object type filter values.
   */
  get noSqlEntityObjectTypes(): string[] {
    return EntityObjectTypesEnvMap[this.searchEnvironment];
  }

  /**
   * Indicates whether the search service is for Cassandra Hot Standby.
   */
  private _isCassandraHotStandby = new BehaviorSubject<boolean>(false);

  set isCassandraHotStandby(value: boolean) {
    this._isCassandraHotStandby.next(value);
  }

  get isCassandraHotStandby() {
    return this._isCassandraHotStandby.value;
  }

  constructor(
    private objectsService: ObjectServiceApi,
    private protectionGroupService: ProtectionGroupServiceApi,
    private protectionSourcesApi: ProtectionSourcesServiceApi,
    private irisContext: IrisContextService,
    private restoreConfig: RestoreConfigService,
    private translate: TranslateService,
    private searchService: SearchServiceApi,
    private snackBar: SnackBarService) {
  }

  /**
   * Initialization function for the service. This needs to be called by every connector using
   * this service and the appropriate restore types need to be supplied.
   *
   * @param   restoreTypes   Environment specific restore types.
   */
  init(restoreTypes: RecoveryAction[]) {
    this.restoreTypes = restoreTypes;
    this.getProtectionGroups$ = this.getProtectionGroupsObservable();

    // Initialize the object type filter values.
    // This step is skipped for HDFS as it does not have linked entity types in the backend
    // for non-cluster level entities. This check can be removed, once file/folder level
    // entities are mapped in backend.
    // (As of now this objectTypeFilterValues is only being used in MongoDB.)
    if (this.searchEnvironment !== Environment.kHdfs) {
      this.objectTypeFilterValues = of(
        (this.noSqlEntityObjectTypes || []).map(objectType => {

          // Get the recovery object type to entity type mapping.
          // E.g.: MongoCollection -> kCollection
          const entityType = ObjectTypeToEntityTypeMap[this.searchEnvironment][objectType];
          const objectTypeTranslationKey = sourceTypesDisplayNames[this.searchEnvironment][entityType];
          return {
            label: this.translate.instant(objectTypeTranslationKey),
            value: objectType
          };
        })
      );
    }
  }

  /**
   * Initialize the observable for fetching the protection groups.
   * The initialization has been kept in a function instead of inlining it becasue it
   * depends on the searchEnvironment value being populated, which happens after the
   * 'init' function is called.
   */
  private getProtectionGroupsObservable(): Observable<Map<string, string>> {
    return this.protectionGroupService.GetProtectionGroups({
      environments: [this.searchEnvironment] as any,
    }).pipe(
      map(groups => groups.protectionGroups),
      map(groups => groups.reduce((groupMap, group) => {
        groupMap.set(group.id, group.name);
        return groupMap;
      }, new Map<string, string>())),
      tap(protectionGroupsMap => this.protectionGroupsMap = protectionGroupsMap),
      shareReplay(1),
    );
  }

  /**
   * Check whether the restore is a PITR of Mongo CDP entity/entities.
   *
   * @param   restorePoint   Restore point selection model.
   * @returns Whether restore is being performed for Mongo CDP entities and is
   * of type PITR.
   */
  private isMongoCdpRestore(restorePoint: RestorePointSelection): boolean {
    const objectInfo = restorePoint?.objectInfo as NoSqlSearchResultGroup;
    return objectInfo.environment === Environment.kMongoDB &&
      restorePoint.isPointInTime &&
      objectInfo?.cdpObjectInfo?.cdpEnabled;
  }

  /**
   * Compute the implicit objects ids for MongoDB based on the object selection. The
   * objects corresponding to these ids should be disabled for selection.
   *
   * @param   searchResults     Recovery search list list.
   * @param   restorePoint      Restore point selection model.
   */
  private computeMongoCdpImplicitObjectIds(searchResults: RestoreSearchResult[],
    restorePoint: RestorePointSelection): (string | number)[] {

    if (!this.isMongoCdpRestore(restorePoint)) {
      return [];
    }

    if (flagEnabled(this.irisContext.irisContext, 'mongoDBEnableClusterAndDatabasePITR')) {
      // If the selected object is a MongoDB CDP entity, then we need to disable selection
      // of protection groups. We don't need to disable any entity, whether it is a MongoDB
      // collection or database, because for a protection group that has a CDP protection policy,
      // all the entities will be CDP always, and we support PITR for both collection and database.
      return (searchResults || [])
        .filter(searchResult => searchResult.resultType === NoSqlProtectionGroupSearchResult.searchResultType)
        .map(searchResult => searchResult.id);
    }
    // If the selected object is a mongo CDP collection, then we need to filter out
    // everything from the search results except MongoDB collections. This is because
    // for a mongo CDP restore, only collections which have CDP enabled can be restored
    // as part of the job and hence we need to prevent selection of any object other than
    // these. We don't need to check if the collections are CDP while filtering because
    // for a protection group that has a CDP protection policy, all the collections will
    // be CDP always.
    return (searchResults || [])
      .filter(searchResult => searchResult.objectType !== ObjectTypeToEntityTypeMap.kMongoDB.MongoCollections)
      .map(searchResult => searchResult.id);
  }

  /**
   * Compute the implicit objects ids that are included in the object selection implicitly
   * because of a parent entity being selected.
   *
   * @param   searchResults     Recovery search list list.
   * @param   restorePoint      Restore point selection model.
   */
  computeImplicitObjectIds(searchResults: RestoreSearchResult[], restorePoint: RestorePointSelection) {
    const objectSelection = restorePoint?.objectInfo as NoSqlSearchResultGroup;

    const selections = objectSelection &&
      objectSelection.noSqlObjectSearchResults &&
      objectSelection.noSqlObjectSearchResults.length ?
      objectSelection.noSqlObjectSearchResults : [];

    // If there are no selections or there are no search results, clear out the
    // implicit object ids list.
    if (!selections.length || !searchResults || !searchResults.length) {
      this.implicitObjectIds.next([]);
      return;
    }

    let ids = [];

    if (selections[0].resultType === NoSqlProtectionGroupSearchResult.searchResultType) {

      // If a protection group has been selected:-
      // Leaving aside the selected protection group, add all the remaining search results
      // to the implicit object ids list. This ensures that they are disabled for selection
      // till the search results are naturally filterted out. There is a slight lag while
      // the natural filtering happens, and we want to disable user selection till then.
      // This needs more work and can be handled better via APIs.
      ids = searchResults.filter(r => r.id !== objectSelection.id).map(r => r.id);
    } else {

      // If a non-protection group is selected, compute a list of visible search results which:-
      // 1. Do not belong in the protection group of the object selection OR
      // 2. Do not belong to the storage domain of the object selection.
      // 3. Parent object is selected, disable selection of all the children objects
      // and add them to the implicit object ids list.
      // This ensure that when a non-protection group has been selected, all the search
      // results that don't belong in the selected protection group and storage domain, are
      // disabled for user selection till the search filters are applied and search results
      // are filtered out automatically.
      // This piggy backs on the implicit object ids use case and needs to be reworked to
      // use a better approach.
      const protectionGroupId = objectSelection.protectionGroupId;
      const storageDomainId = objectSelection.storageDomainId;
      ids = (searchResults || [])
        .filter(searchResult =>
          searchResult.protectionGroupId !== protectionGroupId ||
          searchResult.storageDomainId !== storageDomainId
        )
        .map(r => r.id);

      // Check for Parent objects in selection, if parent is selected add its children in
      // ImplicitObjectIds list so that selection for these will be disabled
      selections.forEach(sel => {
        if (parentObjectTypeMap[this.searchEnvironment].includes(sel.objectType)) {
          const childIds = (searchResults || [])
            .filter(searchResult =>
              leafObjectTypeMap[searchResult.environment] === searchResult.objectType
            ).filter(children => {
              const indexedNameVec = children.name.split(noSqlPathSplitter[this.searchEnvironment]);
              return sel.name === indexedNameVec[0];
            })
            .map(r => r.id);
          ids.push(...childIds);
        }
      });

      if (this.searchEnvironment === Environment.kMongoDB) {
        ids.concat(this.computeMongoCdpImplicitObjectIds(searchResults, restorePoint));
      }
    }

    this.implicitObjectIds.next(ids);
  }

  /**
   * Compute the children Ids that are included in the object selection implicitly
   * because of a parent entity being selected.
   *
   * @param   searchResults     Recovery search list list.
   * @return  Filtered list without children.
   */
  computeChildrenFilterList(searchResults: NoSqlSearchResult[]): NoSqlSearchResult[] {
    let childIds = [];
    // Check for Parent objects in selection, if parent is selected add its children are
    // in the list
    searchResults.forEach(sel => {
      if (parentObjectTypeMap[this.searchEnvironment].includes(sel.objectType)) {
        childIds = (searchResults || [])
          .filter(searchResult =>
            leafObjectTypeMap[searchResult.environment] === searchResult.objectType
          ).filter(children => {
            const indexedNameVec = children.name.split(noSqlPathSplitter[this.searchEnvironment]);
            return sel.name === indexedNameVec[0];
          })
          .map(r => r.id);
      }
    });
    return searchResults.filter(r => !childIds.includes(r.id));
  }

  /**
   * Gets a default RestorePointSelection for a selected file. This calls either
   * GetObjectSnapshots or GetIndexedObjectSnapshots based on the search result type.
   *
   * @param   searchResult   The selected search result.
   * @return  A restore point selection object with the latest snapshot info.
   */
  getDefaultRestorePointSelection(
    searchResult: NoSqlSearchResult
  ): Observable<RestorePointSelection> {
    return searchResult.resultType === NoSqlProtectionGroupSearchResult.searchResultType ?
      this.GetProtectedObjectSnapshots(searchResult) : this.GetIndexedObjectSnapshots(searchResult);
  }

  /**
   *
   * Gets the indexed object id for NoSQL. If entity-level RBAC is supported,
   * this is the entity id, else it is the source id corresponding to the entity.
   *
   * @param searchResult The selected search result.
   * @result The id corresponding to this indexed object.
   *
   */
  getIndexedObjectIdForNoSQL(searchResult: NoSqlSearchResult): number {
    if (flagEnabled(this.irisContext.irisContext, 'nosqlEntityLevelRbacSupport')) {
      return searchResult.objectId;
    }
    return searchResult.sourceId;
  }

  /**
   * Gets a default RestorePointSelection for a selected file. This uses the Indexed object snapshot
   * api to look up available snapshots and chooses the most recent one.
   *
   * @param   searchResult   The selected search result.
   * @return  A restore point selection object with the latest snapshot info.
   */
  GetIndexedObjectSnapshots(
    searchResult: NoSqlSearchResult
  ): Observable<RestorePointSelection> {
    return this.objectsService
      .GetIndexedObjectSnapshots({
        protectionGroupId: searchResult.protectionGroupId,
        objectId: this.getIndexedObjectIdForNoSQL(searchResult),
        indexedObjectName: searchResult.indexedObjectId,
        includeIndexedSnapshotsOnly: true,
      })
      .pipe(
        catchError(err => {
          this.snackBar.open(
            err?.error?.message ??
            this.translate.instant('errors.serverStatusError', {
              statusCode: err?.status,
            }),
            'error'
          );
          throw new Error(err);
        }),
        tap(response => {
          if (!(response?.snapshots?.length)) {
            throw new Error(this.translate.instant('noSnapshotsFound'));
          }
        }),
        map(response => response.snapshots.map(snapshot => new DecoratedIndexedObjectSnapshot(snapshot))),

        // Filter the snapshots by the protection group id of the search result. This is needed because for source
        // cluster objects, the snapshots returned can be from multiple protection groups which protect that source.
        map(snapshots => snapshots.filter(snapshot => snapshot.protectionGroupId === searchResult.protectionGroupId)),
        // Filter the snapshots by the snapshot target type 'Local'. If there are no local snapshot available it will
        // return existing snapshots objects.
        map(snapshots => {
          const localSnapshots = snapshots.filter(snapshot => !snapshot.externalTargetInfo);
          return localSnapshots.length ? localSnapshots : snapshots;
        }),
        map(snapshots => maxBy(snapshots, 'snapshotTimestampUsecs') as IndexedObjectSnapshot),
        map((snapshot: IndexedObjectSnapshot) => ({
          objectInfo: new NoSqlSearchResultGroup([searchResult]),
          objectIds: [searchResult.sourceId],
          restorePointId: snapshot.objectSnapshotid,
          timestampUsecs: snapshot.snapshotTimestampUsecs,
          archiveTargetInfo: snapshot.externalTargetInfo,
        }))
      );
  }

  /**
   * Gets a default RestorePointSelection for a selected file. This uses the object snapshot api to look
   * up available snapshots and chooses the most recent one.
   *
   * @param   searchResult   The selected search result.
   * @return  A restore point selection object with the latest snapshot info.
   */
  GetProtectedObjectSnapshots(searchResult: NoSqlSearchResult): Observable<RestorePointSelection> {
    return this.objectsService.GetObjectSnapshots(
      {
        id: searchResult.sourceId,
        protectionGroupIds: [searchResult.protectionGroupId]
      }
    ).pipe(
      catchError(err => {
        this.snackBar.open(
          err?.error?.message ??
          this.translate.instant('errors.serverStatusError', {
            statusCode: err?.status,
          }),
          'error'
        );
        throw new Error(err);
      }),
      tap(response => {
        if (!(response?.snapshots?.length)) {
          throw new Error(this.translate.instant('noSnapshotsFound'));
        }
      }),

      map(response => response.snapshots || []),
      // Filter the snapshots by the snapshot target type 'Local'. If there are no local snapshot available it will
      // return existing snapshots objects.
      map((snapshots) => {
        const localSnapshots = snapshots.filter((snapshot) => snapshot.snapshotTargetType === 'Local');
        return localSnapshots.length ? localSnapshots : snapshots;
      }),
      map(snapshots => maxBy(snapshots, 'snapshotTimestampUsecs')),
      map(snapshot => ({
        objectInfo: new NoSqlSearchResultGroup([searchResult]),
        objectIds: [searchResult.sourceId],
        restorePointId: snapshot.id,
        timestampUsecs: snapshot.snapshotTimestampUsecs,
        archiveTargetInfo: snapshot.externalTargetInfo,
      }))
    );
  }

  /**
   * Gets a latest RestorePointSelection for a selected protected objects. This uses the object snapshot api to look
   * up available snapshots and chooses the most recent one.
   *
   * @param   sourceId   The protected object Id.
   * @param   protectionGroupId   The protected group Id.
   * @return  A restore point selection object with the latest snapshot info.
   */
  GetObjectSnapshots(sourceId: number, protectionGroupId: string): Observable<ObjectSnapshot> {
    return this.objectsService.GetObjectSnapshots(
      {
        id: sourceId,
        protectionGroupIds: [protectionGroupId]
      }
    ).pipe(
      catchError(err => {
        this.snackBar.open(
          err?.error?.message ??
          this.translate.instant('errors.serverStatusError', {
            statusCode: err?.status,
          }),
          'error'
        );
        throw new Error(err);
      }),
      tap(response => {
        if (!(response?.snapshots?.length)) {
          throw new Error(this.translate.instant('noSnapshotsFound'));
        }
      }),

      map(response => response.snapshots || []),
      // Filter the snapshots by the snapshot target type 'Local'. If there are no local snapshot available it will
      // return existing snapshots objects.
      map((snapshots) => {
        const localSnapshots = snapshots.filter((snapshot) => snapshot.snapshotTargetType === 'Local');
        return localSnapshots.length ? localSnapshots : snapshots;
      }),
      map(snapshots => maxBy(snapshots, 'snapshotTimestampUsecs'))
    );
  }

  /**
   * Get the common filter params derived from the filter selections for the search APIs.
   *
   * @param   filters   The filters to use for the search.
   * @returns Filter params object.
   */
  private getFilterParams(filters: DataFilterValue<any>[] = []): FilterParams {
    const sourceFilter: PropertyDataFilterValue = filters.find(srcFilter => srcFilter.key === 'source');
    const protectionGroupFilter: PropertyDataFilterValue = filters.find(pgFilter => pgFilter.key === 'protectionGroup');
    const storageDomainFilter: PropertyDataFilterValue = filters.find(sdFilter => sdFilter.key === 'storageDomain');
    const dateRangeFilter: DateRangeDataFilterValue = filters.find(drFilter => drFilter.key === 'dateRange');
    const objectTypeFilter: PropertyDataFilterValue = filters.find(objFilter => objFilter.key === 'objectType');

    const filterParams: FilterParams = {
      filterSnapshotFromUsecs: null,
      filterSnapshotToUsecs: null,
    };

    filterParams.sourceIds = sourceFilter ?
      sourceFilter.value.map((entry: ValueFilterSelection) => entry.value as number) : [];
    filterParams.protectionGroupIds = protectionGroupFilter ? protectionGroupFilter.value.map(
      (entry: ValueFilterSelection) => entry.value as string
    ) : [];
    filterParams.storageDomainIds = storageDomainFilter ?
      storageDomainFilter.value.map((entry: ValueFilterSelection) => entry.value as number) : [];

    if (dateRangeFilter && dateRangeFilter.value) {
      if (dateRangeFilter.value.start) {
        filterParams.filterSnapshotFromUsecs = dateRangeFilter.value.start.valueOf() * 1000;
      }

      if (dateRangeFilter.value.end) {
        filterParams.filterSnapshotToUsecs = dateRangeFilter.value.end.valueOf() * 1000;
      }
    }

    if (objectTypeFilter?.value) {
      filterParams.objectTypes = (objectTypeFilter?.value || []).map(entry => entry.value);
    }

    return filterParams;
  }

  /**
   * Fetch a list of indexed objects matching the search criteria.
   *
   * @param   query   The search query
   * @param   filters The filters to use for the search.
   * @returns List of indexed objects matching the search criteria.
   */
  private searchForIndexedObjects(
    query: string,
    filters: DataFilterValue<any>[] = []
  ): Observable<NoSqlObjectSearchResult[]> {
    const { objectTypes, sourceIds, protectionGroupIds, storageDomainIds } = this.getFilterParams(filters);

    const params: SearchIndexedObjectsRequest = {
      [this.searchRequestEnvParamsKey]: {

        // If the object types filter exists, then use the selection (if any), else supply all the possible
        // object types to ensure they are all fetched in the search results.
        [SourceObjectTypeEnvParamsMap[this.searchEnvironment]]: objectTypes?.length ?
          objectTypes : this.noSqlEntityObjectTypes,
        searchString: query,
      },
      objectType: this.noSqlSourceObjectType as any,
    };

    params[this.searchRequestEnvParamsKey].sourceIds = sourceIds;
    params.protectionGroupIds = protectionGroupIds;
    params.storageDomainIds = storageDomainIds;

    return forkJoin([
      this.searchService.SearchIndexedObjects({ body: params }),
      this.getProtectionGroups$,
    ]).pipe(
      catchError(err => {
        this.snackBar.open(
          err?.error?.message ??
          this.translate.instant('errors.serverStatusError', {
            statusCode: err?.status,
          }),
          'error'
        );
        throw new Error(err);
      }),
      map(([result, _]) => result || {}),
      map(result => result[this.searchResponseEnvParamsKey] || []),
      map(
        indexedObjects => indexedObjects.map(object => new NoSqlObjectSearchResult(object, this.protectionGroupsMap))
      ),
    );
  }

  /**
   * Fetch a list of protected objects matching the search criteria.
   *
   * @param   query   The search query
   * @param   filters The filters to use for the search.
   * @returns List of protected objects matching the search criteria.
   */
  private searchForProtectedObjects(
    query: string,
    filters: DataFilterValue<any>[] = []
  ): Observable<NoSqlProtectionGroupSearchResult[]> {
    const params: SearchServiceApi.SearchProtectedObjectsParams = {
      searchString: query,
      environments: [this.searchEnvironment] as any,
      snapshotActions: this.restoreTypes as any,
    };

    Object.assign(params, this.getFilterParams(filters));

    return this.searchService.SearchProtectedObjects(params).pipe(
      catchError(err => {
        this.snackBar.open(
          err?.error?.message ??
          this.translate.instant('errors.serverStatusError', {
            statusCode: err?.status,
          }),
          'error'
        );
        throw new Error(err);
      }),
      map(res => res.objects),
      map(objects => {
        const results = [];
        objects.forEach(object => {

          // Every object returned can be part of multiple sources. For each of these sources, we need to create
          // a protection groups that can be added to the list of search results.
          (object.latestSnapshotsInfo || []).forEach(o => results.push({ ...object, latestSnapshotsInfo: [o] }));
        });
        return results;
      }),
      map(
        (objects: Required<ProtectedObject>[]) => objects.map(object => new NoSqlProtectionGroupSearchResult(object,
            this.irisContext))
      )
    );
  }

  /**
   * Use the regular search, then add protection groups to the result.
   *
   * @param   query   The search query
   * @param   filter  The applied filters.
   * @returns Observable of an array of matching objects.
   */
  doObjectSearch(query: string, filters: DataFilterValue<any>[] = []): Observable<RestoreSearchResult[]> {
    // In case of Cassandra mirroring protection job is not reuired at the time of recovery
    // ENG link:: ENG-313237 and ENG-313434
    return !this.isCassandraHotStandby ? forkJoin([

      // This is used for fetching the source cluster objects and then computing the protection
      // groups from the list.
      this.searchForProtectedObjects(query, filters),

      // Fetch the indexed entity objects.
      this.searchForIndexedObjects(query, filters),
    ]).pipe(
      map(([protectedObjectsResults, indexedObjectsResults]) =>
        ([...protectedObjectsResults, ...indexedObjectsResults]))
    ) : this.searchForIndexedObjects(query, filters);
  }

  /**
   * Gets all registered source details.
   *
   * @param env Enviornment type to filter the result.
   * @returns  Observable of registered mongodb source names.
   */
  getSourcesDetailsByEnvironment(env: string): Observable<GetRegistrationInfoResponse> {
    return this.protectionSourcesApi.ListProtectionSourcesRegistrationInfo({
      environments: [Environment[env]]
    });
  }

  /**
   * Gets protection group by Id.
   *
   * @param id Protection group Id.
   * @param loading$ Loading subject.
   * @returns  Observable of protection group object.
   */
  getProtectionGroupById(id: string, loading$: BehaviorSubject<boolean>): Observable<ProtectionGroup> {
    return this.protectionGroupService.GetProtectionGroupById({ id: id })
      .pipe(
        filter(group => !!group),
        finalize(() => loading$.next(false)),
      );
  }

  /**
   * Gets protection group by Enviornment.
   *
   * @param env Enviornment type to filter the result.
   * @param loading$ Loading subject.
   * @returns  Observable of protection group list.
   */
  getProtectionGroupByEnv(env: string, loading$: BehaviorSubject<boolean>): Observable<ProtectionGroups> {
    return this.protectionGroupService.GetProtectionGroups({
      environments: [Environment[env]]
    }).pipe(
      filter(groups => !!groups.protectionGroups),
      finalize(() => loading$.next(false)),
    );
  }
}
