// DIRECTIVE: cInterpolate

;(function(angular, undefined) {
    'use strict';

    var modName = 'C.interpolate';
    var modDeps = ['ngSanitize'];

    angular.module(modName, modDeps)
        .directive('cInterpolate', DirInterpolate);

    /**
     * @ngdoc directive
     * @name  cInterpolate
     *
     * @description
     * Allows you to use the interpolate service easily from within a template,
     * permitting one to define a complex template string (ie. for i18n) and
     * immediately use it in the template without interruption from the
     * controller.
     *
     * cInterpolate expression is evaluated against the current template $scope.
     *
     * Based on https://github.com/angular/angular.js/blob/v1.4.6/src/ng/directive/ngBind.js#L186
     *
     * @param  {expression}  cInterpolate  {@link guide/expression Expression}
     *                                     to evaluate.
     *
     * @example
       // $scope.text.world = 'Humperdink!';
       // $scope.text.hellowWorld = 'Hello {{::text.world}}';

       <h5 c-interpolate="::text.hellowWorld"></h5>

         becomes

       <h5>Hello Humperdink!</h5>
     */
    DirInterpolate.$inject = ['$compile', '$interpolate', '$parse', '$sce'];
    function DirInterpolate($compile, $interpolate, $parse, $sce) {
        var dirInterpolate = {
            restrict: 'A',
            compile: compileFn
        };
        var rxTemplateTag = /(?:{{)([^\}]+)(?:}})/gm;

        function compileFn(tElem, tAttrs) {
            var ngInterpGetter = $parse(tAttrs.cInterpolate);
            var ngInterpWatch = $parse(tAttrs.cInterpolate, function getStringValue(value) {
                return (value || '').toString();
            });
            var watchSet = [ngInterpWatch];
            // Adds .ng-bind to the element
            $compile.$$addBindingClass(tElem);

            /**
             * Transforms an array of expressions for scope.$watchGroup into
             * trusted HTML functions for interpolation. Ignores function
             * expressions, but operates on string expressions (var names)
             *
             * @method     groupWatchParserFn
             * @param      {array}  group   The list of expressions
             * @return     {array}  The modified list of expressions
             */
            function groupWatchParserFn(group) {
                return group.map(function groupMapper(expression) {
                    // Leave it alone if the expression is already a Fn
                    if (angular.isFunction(expression)) {
                        return expression;
                    }
                    // Parse it as trusted (doesn't remove non-standard attribs like
                    // `$sce.getTrustedHtml` does)
                    return $sce.parseAsHtml(expression);
                });
            }

            /**
             * Standard Angular directive Link Fn.
             *
             * @method     linkFn
             * @param      {object}   scope   The scope of this directive
             * @param      {element}  elem    The element this directive is
             *                                linked to
             * @param      {object}   attrs   Hash of directive attributes
             */
            return function linkFn(scope, elem, attrs) {
                var baseString = ngInterpGetter(scope) || '';
                // any vars found within the parsed string to the list of vars
                // to watch for changes.
                watchSet = watchSet.concat(getVarsList(baseString));

                // Enables standard angular debugging features, ie.
                // `angular.element($0).scope()` in the console
                $compile.$$addBindingInfo(elem, attrs.cInterpolate);
                scope.$watchGroup(groupWatchParserFn(watchSet), function cInterpWatchSubVars() {
                    elem.html($interpolate(baseString)(scope));
                    $compile(elem.contents())(scope);
                });
            };
        }

        /**
         * Extract the ngTemplate tag vars from a given string
         *
         * @method     getVarsList
         * @param      {string}  string  A string containing angular template
         *                               tags
         * @return     {Array}   List of vars found within the given string
         */
        function getVarsList(string) {
            var out = [];
            var results = string ? string.match(rxTemplateTag) : [];
            if (results && results.length) {
                out = results.map(function varMapper(_var) {
                    return _var.replace(/({{|}})/gm, '');
                });
            }
            return out;
        }

        return dirInterpolate;
    }

})(angular);
