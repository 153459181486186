import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SfdcObjectFields } from '@cohesity/api/v1';
import { DataTreeNodeContext, DataTreeNodeDetail } from '@cohesity/helix';
import { BehaviorSubject } from 'rxjs';
import { RecoverSfdcSearchService } from 'src/app/modules/restore/recover-sfdc/services/recover-sfdc-search.service';

import { SfdcSourceDataNode } from '../sfdc-source-data-node';

/**
 * This component is used to set and update source special params for salesforce nodes.
 * It is not created directly, but returned from a call to getSpecialParametersComponent
 * in SfdcSourceTreeService.
 */
@Component({
  selector: 'coh-sfdc-fields-options',
  templateUrl: './sfdc-fields-options.component.html',
  styleUrls: ['./sfdc-fields-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SfdcFieldsOptionsComponent implements DataTreeNodeDetail<SfdcSourceDataNode> {
  /**
   * The node context, including info about the node and it's selection status.
   */
  @Input() nodeContext: DataTreeNodeContext<SfdcSourceDataNode>;

  /**
   * Gets the node from the nodeContext.
   */
  get node(): SfdcSourceDataNode {
    return this.nodeContext.node;
  }

  /**
   * The snapshot value.
   */
  value: string[] = [];

  /**
   * The columns to show in the table.
   */
  tableColumns: string[] = ['name'];

  /**
   * The list of all the fields for an Object.
   */
  fields$ = new BehaviorSubject<SfdcObjectFields[]>([]);

  /**
   * Whether the list of all the records is currently loading.
   */
  fieldsLoading$ = new BehaviorSubject<boolean>(true);

  /**
   * Gets the current options for the node. This either gets them from the selection options, or
   * the default params for the node.
   */
  get currentOptions() {
    return this.nodeContext.selection.getOptionsForNode(this.node.id);
  }

  /**
   * The form to contain the options.
   */
  form: UntypedFormGroup = new UntypedFormGroup({
    excludeFields: new UntypedFormControl([], Validators.required),
  });

  constructor(
    private cdr: ChangeDetectorRef,
    private recoverSfdcSearchService: RecoverSfdcSearchService) { }

  /**
   * Updates the form based on the current options setting in preparation for displaying the form
   * dialog.
   */
  updateForm() {
    this.fieldsLoading$.next(true);
    const excludedFields = this.currentOptions?.excludeFieldNames || [];
    this.form.setValue({ excludeFields: excludedFields });
    this.form.updateValueAndValidity();
    this.recoverSfdcSearchService.getObjectFields(this.nodeContext.node.id as number)
      .subscribe(resp => {
        if (resp?.length > 0) {
          this.fields$.next(resp);
          this.fieldsLoading$.next(false);
        }
      });
  }

  /**
   * Updates the selection options after the form has been saved and the dialog has been closed.
   */
  onSaved() {
    const options = {
      objectId: this.node.id,
      excludeFieldNames: this.form.value?.excludeFields || [],
    };

    this.nodeContext.selection.setOptionsForNode(this.node.id, options);
    this.cdr.detectChanges();
  }

  /**
   * Returns a string representation of excluded fields for an objects.
   * For example, 'Name, Description, etc.'
   *
   * @returns  list of excluded fields. e.g. 'Name, Description, etc.'.
   */
  getExcludedFields(): string {
    return (this.form.value?.excludeFields || []).toString();
  }
}
