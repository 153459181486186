import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { AttributeFilter } from '@cohesity/api/reporting';
import {
  DateFilterRange,
  DateRangeBanner,
  DisableApplyButton,
  FilterDefDirective,
  FiltersComponent,
  getDateRangeFilter,
  Timeframe,
  TranslateFn,
} from '@cohesity/helix';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import moment, { Moment } from 'moment';

import { ReportFilterComponent } from '../base-report-filter.component';
import { getTimeframeFromDateRange } from '../filters-map.model';

@Component({
  selector: 'iris-rpt-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeFilterComponent
  implements OnInit, OnDestroy, AfterViewInit, ReportFilterComponent<DateFilterRange<Moment>> {
  /**
   * The time range attribute name
   */
  readonly property = 'date';

  /**
   * If the filter is set to readonly, it should not make _any_ api calls or allow
   * a user to change it's value. It should also hide itself if it does not have a value.
   */
  @Input() readOnly = false;

  /**
   * Translate function to use for report items.
   */
  @Input() translate: TranslateFn;

  /**
   * Supported user contexts
   */
  @Input() supportedContexts: string[];

  /**
   * True if filters are used for scheduling report.
   */
  @Input() isScheduleFilter: boolean;

  /**
   * True if filters are used for on demand report during scheduling report.
   */
  _isOnDemand: boolean;
  @Input() set isOnDemand(isOnDemand: boolean) {
    this._isOnDemand = isOnDemand;
  }

  get isOnDemand(): boolean {
    return this._isOnDemand;
  }

  /**
   * User input long date range.
   */
  longDateRange: DateFilterRange<Moment>;

  /**
   * If ii long date range, can only support on demand/async report.
   */
  _isLongDateRange = false;
  set isLongDateRange(isLongDateRange: boolean) {
    this._isLongDateRange = isLongDateRange;
  }

  get isLongDateRange(): boolean {
    return flagEnabled(this.irisCtx.irisContext, 'asyncReportEnabled') && !this.isScheduleFilter &&
      this._isLongDateRange;
  }

  /**
   * Date Range Banner.
   */
  _banner: DateRangeBanner;
  set banner(banner: DateRangeBanner) {
    this._banner = banner;
  }

  get banner(): DateRangeBanner {
    return this.isLongDateRange ? this._banner : null;
  }

  _disableApplyButton: DisableApplyButton;
  set disableApplyButton(disableApplyButton: DisableApplyButton) {
    this._disableApplyButton = disableApplyButton;
  }

  get disableApplyButton(): DisableApplyButton {
    return this.isLongDateRange ? this._disableApplyButton : null;
  }

  /**
   * Button is clicked to create report instead of setting filter options.
   */
  @Output() createReport = new EventEmitter<any>();

  /**
   * The filter def directive for the filter
   */
  @ViewChild(FilterDefDirective, { static: true }) filterDef: FilterDefDirective;

  /**
   * Timeframe options.
   */
  timeframeOptions: Timeframe[];

  constructor(
    @Optional() private irisCtx: IrisContextService,
    readonly filters: FiltersComponent,
  ) {
    this.createReport = new EventEmitter<void>();
  }

  toApiValue(filterValues: DateFilterRange<Moment>): AttributeFilter {
    let dateRange;

    switch (filterValues.timeframe) {
      case Timeframe.Past1Hour:
        dateRange = 'Last1Hour';
        break;
      case Timeframe.Past24Hours:
        dateRange = 'Last24Hours';
        break;
      case Timeframe.Past7Days:
        dateRange = 'Last7Days';
        break;
      case Timeframe.Past30Days:
        dateRange = 'Last30Days';
        break;
      case Timeframe.Past90Days:
        dateRange = 'Last90Days';
        break;
      case Timeframe.Past180Days:
        dateRange = 'Last180Days';
        break;
      case Timeframe.Past365Days:
        dateRange = 'Last365Days';
        break;
      case Timeframe.PastMonth:
        dateRange = 'LastMonth';
        break;
      case Timeframe.Past3Months:
        dateRange = 'Last3Months';
        break;
      case Timeframe.Past6Months:
        dateRange = 'Last6Months';
        break;
      case Timeframe.PastYear:
        dateRange = 'LastYear';
        break;
      case Timeframe.CurrentMonth:
        dateRange = 'CurrentMonth';
        break;
      case Timeframe.CurrentYear:
        dateRange = 'CurrentYear';
        break;
    }

    return {
      attribute: this.property,
      filterType: 'TimeRange',
      timeRangeFilterParams: {
        dateRange,
        lowerBound: filterValues.start?.valueOf() * 1000,
        upperBound: filterValues.end?.valueOf() * 1000,
      },
    };
  }

  fromApiValue(apiValue: AttributeFilter): DateFilterRange<any> {
    if (apiValue?.timeRangeFilterParams) {
      const { lowerBound, upperBound, dateRange } = apiValue.timeRangeFilterParams;
      let start: Moment;
      let end: Moment;
      const timeframe: Timeframe = getTimeframeFromDateRange(dateRange);

      if (lowerBound) {
        start = moment(lowerBound / 1000);
      }
      if (upperBound) {
        end = moment(upperBound / 1000);
      }
      if (start || end || timeframe) {
        return { start, end, timeframe };
      }
    }
    return null;
  }

  /**
   * Change if date range is more than a month or start date is more than 3 months ago.
   *
   * @param dateRange
   */
  onDateRangeChange(dateRange: DateFilterRange<Moment>) {
    if (!this.isScheduleFilter && dateRange) {
      this.isLongDateRange =
        (!dateRange.timeframe &&
          (dateRange.end?.diff(dateRange.start, 'days', true) > 31 ||
            moment().diff(dateRange.start, 'months', true) > 3)
        ) || false;
      if (this.isLongDateRange) {
        this.longDateRange = dateRange;
      }
    }
  }

  ngOnInit() {
    this.banner = {
      status: 'info',
      message: this.translate('reporting.dateRange.longDateRangeMessage'),
    };
    this.disableApplyButton = {
      disable: true,
      customButtonLabel: this.translate('createReport'),
    };
    if (this.filters && this.filterDef) {
      this.filters.addFilterDef(this.filterDef);
    }
    if (this.irisCtx?.irisContext?.featureFlags?.ngReportLongDateRange) {
      this.timeframeOptions = [
        Timeframe.Past1Hour,
        Timeframe.Past24Hours,
        Timeframe.Past7Days,
        Timeframe.Past30Days,
        Timeframe.Past90Days,
        Timeframe.Past180Days,
        Timeframe.Past365Days,
        Timeframe.PastMonth,
        Timeframe.Past3Months,
        Timeframe.Past6Months,
        Timeframe.PastYear,
        Timeframe.CurrentMonth,
        Timeframe.CurrentYear,
      ];
    } else {
      this.timeframeOptions = [
        Timeframe.Past1Hour,
        Timeframe.Past24Hours,
        Timeframe.Past7Days,
        Timeframe.Past30Days,
        Timeframe.PastMonth,
        Timeframe.CurrentMonth,
      ];
    }
  }

  ngAfterViewInit() {
    if (!this.readOnly) {
      this.filters.setValue(this.property, getDateRangeFilter('currentmonth'));
    }
  }

  ngOnDestroy() {
    if (this.filters && this.filterDef) {
      this.filters.removeFilterDef(this.filterDef);
    }
  }
}
