// Component: Instance run settings

;(function(angular, undefined) {
  'use strict';

  angular.module('C.appsManagement')
    .component('instanceRunSettings', {
      templateUrl: 'app/apps-management/instance-run-settings/instance-run-settings.html',
      controller: instanceRunSettingsCtrlFn,
      bindings: {
        // @type   {object}   Instance object
        instance: '<',
      },
    });

  /**
   * @ngdoc component
   * @name C.appsManagement:instanceRunSettings
   * @function instanceRunSettingsCtrlFn
   *
   * @description
   * Displays run settings of application instance
   *
   * @example
     <instance-run-settings
       instance="$ctrl.instance">
     </instance-run-settings>
   */
  function instanceRunSettingsCtrlFn(_, $translate) {
    var $ctrl = this;

    // Totol number of columns for displaying view names.
    // Please adjust column size in template if you change this number.
    var TOTAL_VIEW_COLS = 4;

    _.assign($ctrl, {
      readViewRows: [],
      writeViewRows: [],
      protectedObjectsPrivilege: 'kNone',
      summary:{
        numReadViews: 0,
        numWriteViews: 0,
      },
      showViewNames: false,
      hasViewNames: false,
      toggleViewKey: 'show',

      // Methods
      toggleView: toggleView,

      // Component lifecycle methods
      $onChanges: $onChanges,
    });

    /**
     * Handle changes to inputs
     *
     * @method    $onChanges
     * @param     {object}   changes   angularjs component change object
     */
    function $onChanges(changes) {
      if(changes.instance) {
        _setData(changes.instance.currentValue.settings);
      }
    }

    /**
     * Toggles display of view names
     *
     * @method    toggleView
     */
    function toggleView() {
      $ctrl.showViewNames = !$ctrl.showViewNames;
      $ctrl.toggleViewKey = $ctrl.showViewNames? 'hide':'show';
    }

    /**
     * Set data required for template using instance object.
     *
     * @method    _setData
     * @param     {object}   settings   appInstance settings object
     */
    function _setData(settings) {
      var readViewPrivType = _.get(
        settings, 'readViewPrivileges.privilegesType', 'kNone');
      var writeViewPrivType = _.get(
        settings, 'readWriteViewPrivileges.privilegesType', 'kNone');

      $ctrl.readViewRows = _.chunk(settings._readViewNames, TOTAL_VIEW_COLS);
      $ctrl.writeViewRows = _.chunk(settings._writeViewNames, TOTAL_VIEW_COLS);

      $ctrl.summary.numReadViews = settings._readViewNames.length;
      $ctrl.summary.numWriteViews = settings._writeViewNames.length;

      if(readViewPrivType !== 'kSpecific') {
        $ctrl.summary.numReadViews = $translate.instant(readViewPrivType);
      }

      if(writeViewPrivType !== 'kSpecific') {
        $ctrl.summary.numWriteViews = $translate.instant(writeViewPrivType);
      }

      $ctrl.hasViewNames = settings._readViewNames.length ||
        settings._writeViewNames.length;

      $ctrl.protectedObjectsPrivilege = _.get(
        settings, 'protectedObjectPrivileges.protectedObjectsprivilegesType', 'kNone')
    }
  }
})(angular);
