import { AzureProtectionSource, ProtectionSourceNode } from '@cohesity/api/v1';
import { ToFormGroup } from '@cohesity/shared-forms';
import {
  RegionSubscription
} from 'src/app/modules/protection/protection-builder/components/settings-list-items/settings-list-sas-url/region-subscription.interface';
import { DmsConnection } from 'src/app/modules/sources/shared';

/**
 * Represents a pair of a node and its ancestor at a higher hierarchy level
 */
interface AncestorNodePair {
  ancestor: ProtectionSourceNode;
  node: ProtectionSourceNode;
}

/**
 *  Filters nodes of a particular type without descendant child nodes
 *
 *  @param nodes The array of protection nodes
 *  @param type The type of nodes to be returned
 *  @param includeDescendantNodes Specifies if descendant nodes of child nodes should be included
 */
export function extractNodesByType(nodes: ProtectionSourceNode[], type: string, includeDescendantNodes = false): ProtectionSourceNode[] {
  return (nodes || [])
    .filter(node => type === node.protectionSource.azureProtectionSource.type)
    .map(node => includeDescendantNodes ? node : ({
      ...node,
      nodes: undefined,
    }));
}

/**
 * Resets the form controls specified
 *
 * @param form The formGroup
 * @param keys The form controls to be reset
 */
export function resetFormControlValues<T>(
  form: ToFormGroup<T>,
  keys: (keyof T)[]) {
  (keys || []).forEach(key => {
    if (form) {
      form[(key as string)].setValue(null);
    }
  });
}


/**
 * Get the child nodes of a parent node given its id, type of child nodes and the list of nodes.
 *
 * @param id    Id of the parent node.
 * @param nodes List of all nodes.
 * @param type  Type of the child nodes to filter.
 *
 * @returns     The list of child nodes of given type.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function getChildNodesByType(
  id: number,
  nodes: ProtectionSourceNode[],
  type: AzureProtectionSource['type'] | string,
  includeDescendants = true): ProtectionSourceNode[] {
  const parentNode = nodes.find(vn => vn.protectionSource.id === id);
  return extractNodesByType(parentNode.nodes, type, includeDescendants);
}

/**
 * Given a tree, get all the nodes of specific type and belong to given location.
 *
 * @param source  Root node of the tree.
 * @param type        Type of nodes to filter.
 * @param location    Location of the nodes.
 *
 * @returns           The list of child nodes of given type and location.
 */
export function getNodesByType(
  source: ProtectionSourceNode,
  type: AzureProtectionSource['type'],
  location?: string): ProtectionSourceNode[] {
  const result = [];
  (source?.nodes ?? []).forEach((node: ProtectionSourceNode) => {
    const nodeData = node.protectionSource.azureProtectionSource;
    if (nodeData.type === type) {
      if (!location) {
        result.push(node);
      } else if (nodeData.location === location) {
        result.push(node);
      }
    } else if (node.nodes?.length) {
      // Only traverse nodes with descendant child nodes
      result.push(...getNodesByType(node, type, location));
    }
  });

  return result;
}

/**
 * Given a tree, get a node and the ancestor of a specific type.
 *
 * @param root  Root node of the tree.
 * @param nodeId  Id of target node of the nodes.
 * @param ancestorType Type of node ancestor to return at any level in the tree.
 *
 * @returns  The list of child nodes of given type and location.
 */
export function getNodeAndAncestor(root: ProtectionSourceNode, nodeId: number,
  ancestorType: AzureProtectionSource['type']): AncestorNodePair {
  let ancestor = null;
  let targetNode = null;
  const stack = [root];
  while (stack.length) {
    const node = stack.pop();
    if (node.protectionSource.id === nodeId) {
      targetNode = node;
    }
    if (!targetNode && node.nodes?.length) {
      // Types will be unique at different levels
      if (node.protectionSource?.azureProtectionSource?.type === ancestorType) {
        // Set Ancestor without child nodes
        ancestor = { ...node, nodes: null };
      }
      node.nodes.forEach(childNode => stack.push(childNode));
    }
  }
  return {
    ancestor,
    node: targetNode,
  };
}

/**
 * Given a set of objectIds and a source, return the list of unique subscription and regions
 * they belong to
 *
 * @param objectIds The list of object Ids
 * @param source The list of object sources
 */
export function extractRegionSubscriptionPair(objectIds: number[], source: ProtectionSourceNode): RegionSubscription[] {
  const pairData =  objectIds.reduce((pairMap, objectId) => {
    const nodeAndSub = getNodeAndAncestor(source, objectId, 'kSubscription');
    if(nodeAndSub?.ancestor && nodeAndSub?.node) {
      const pair = {
        subscription: nodeAndSub.ancestor.protectionSource?.name,
        region: nodeAndSub.node.protectionSource?.azureProtectionSource?.location
      };
      const key = `${pair.subscription}${pair.region}`;
      if(!pairMap.has(nodeAndSub)) {
        pairMap.set(key, pair);
      }
    }
    return pairMap;
  }, new Map());
  return Array.from(pairData.values());
}


/**
 * Filter the tree to a given hierarchy and remove all other objects.
 *
 * @param   nodes   The list of nodes to filter
 * @param   types   An array of types to filter by. This should be in the hierarchy order starting with
 *                  the top level node. Only trees that include the entire structure will be returned,
 *                  child nodes that are not in the hiearchy will not be included in the filtered tree.
 * @param location The region key
 * @param allowUnavailableDescendants It specifies filtering of tree objects
 * to available node depth based on specified types
 * @returns A filtered version of the tree. Nodes are copied so that the original values are not modified.
 */
export function filterTreeHierarchy(
  nodes: ProtectionSourceNode[],
  types: string[],
  location?: string,
  allowUnavailableDescendants?: boolean): ProtectionSourceNode[] {

  if (!types || !types.length) {
    return null;
  }

  const filtered = (nodes || [])
    .filter(node =>
      types[0] === node.protectionSource.azureProtectionSource.type &&
      (!location || !node.protectionSource.azureProtectionSource.location ||
        node.protectionSource.azureProtectionSource.location === location))
    .map(node => ({
      ...node,
      nodes: node.nodes?.length ?
        filterTreeHierarchy(node.nodes, types.slice(1), location, allowUnavailableDescendants) : [],
    }))
    .filter(node => types.length === 1 || (node.nodes && node.nodes.length) || allowUnavailableDescendants);

  return filtered;
}

/**
 * Extracts an azure object resource name from resourceId
 * Key Values: subscriptions | resourceGroups | providers | virtualNetworks | subnets
 *
 * @param resourceId The resource ID of the Azure Object
 * @param key The target resourceType literal in the resourceID URI
 */
export function extractResourceName (resourceId: string, key: string) {
  const leftKey = `/${key}/`;
  const index = resourceId.indexOf(leftKey);
  if (index < 0) {
    return null;
  }
  const index2 = resourceId.indexOf('/', index + leftKey.length);
  if (index2 < 0) {
    return null;
  }
  return resourceId.substring(index + leftKey.length, index2);
}

/**
 * Builds a network resource group form hierarchy model based on regional virtual network data
 *
 * @param resourceGroupData List of resource groups in the selected subscription
 * @param regionData List of region vnet hierarchy data available in selected subscription
 * @param location Selected resource group region
 */
export function extractRegionalNetworkResourceData(
  resourceGroupData: ProtectionSourceNode[],
  regionData: ProtectionSourceNode[],
  location: string): ProtectionSourceNode[] {
  // Find selected resource group regionNode from region data
  const regionVNetData = regionData.find(node => node.protectionSource?.name === location);
  if(regionVNetData) {

    // Categorize virtual networks into resource groups
    const rgVNetMap = <Map<string, ProtectionSourceNode[]>>regionVNetData.nodes.reduce(
      (rgMap: Map<string, ProtectionSourceNode[]>, node: ProtectionSourceNode) => {
        const key = extractResourceName(node.protectionSource.azureProtectionSource.resourceId, 'resourceGroups');
        if(!rgMap.has(key)) {
          rgMap.set(key, [node]);
        } else {
          const nodes = rgMap.get(key);
          nodes.push(node);
          rgMap.set(key, nodes);
        }
        return rgMap;
      }, new Map<string, ProtectionSourceNode[]>());

    // Build new resourcegroup vnet mapping based on extracted regional subnets
    const networkResourceGroups = [];
    resourceGroupData.forEach(resourceGroup => {
      if(rgVNetMap.has(resourceGroup.protectionSource.name)) {
        networkResourceGroups.push({
          ...resourceGroup,
          nodes: rgVNetMap.get(resourceGroup.protectionSource.name)
        });
      }
    });
    return networkResourceGroups;
  }
  return [];
}

/**
 * Extract vnet IDs from the saas connections
 *
 * @param source The source node
 * @param connections The source saas connections
 */
export function getConnectionVNetIds(source: ProtectionSourceNode, connections: DmsConnection[]): number[] {
  if(!source || !connections?.length) {
    return [];
  }
  const vnetResourceIds = connections.map(conn => conn.rigelCloudInfraInfo?.azureRigelInfraInfo?.vnet);
  return getNodesByType(source, 'kVirtualNetwork')
    .filter(node => vnetResourceIds.includes(node.protectionSource?.azureProtectionSource?.resourceId))
    .map(node => node.protectionSource.id);
}
