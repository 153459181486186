import { ClusterIdentifierId, IncidenceList, PolicyRunResult, PolicyRunResultType } from '@cohesity/api/argus';
import { SnapshotTag } from '@cohesity/api/v2';
import { getHighestSensitivityCount } from '@cohesity/data-govern/shared';
import { getClusterIdentifier } from '@cohesity/utils';
import { mapKeys } from 'lodash';
import { AnomalyAlert } from '../security-shared.models';

export type AnomaliesTagsMap = Map<ClusterIdentifierId, SnapshotTag[]>;

/**
 * Transforms incidences opt for anomalies
 *
 * @param incidences ransomware incidences
 * @returns anomalies
 */
export const transformAnomalies = (
  incidences: IncidenceList,
  anomaliesTagsMap?: AnomaliesTagsMap,
): AnomalyAlert[] => (incidences || []).map(incidence => {
  const { antiRansomwareDetails } = incidence;
  const mapper = {
    clusterId: 'cid',
    clusterName: 'cluster',
    entityName: 'object',
    latestAnomalousSnapshotUsecs: 'latestTimestampUsecs',
    sourceName: 'source',
  };
  const properties = mapKeys(antiRansomwareDetails, (_, key) => mapper[key] || key);

  return {
    clusterName: properties.cluster,
    clusterId: properties.cid,
    incidence,
    id: properties.alertId,
    properties,
    vaults: antiRansomwareDetails.vaults,
    dedupTimestamps: [+properties.latestTimestampUsecs],
    latestTimestampUsecs: +properties.latestTimestampUsecs,
    lastSensitivityRunDetails: antiRansomwareDetails.lastSensitivityRunDetails,
    lastThreatScanDetails: antiRansomwareDetails.lastThreatScanDetails,
    highestSensitivityCount: getHighestSensitivityCount(
      antiRansomwareDetails.lastSensitivityRunDetails?.patternSensitivityCount || []
    ),
    tags: anomaliesTagsMap?.get(getClusterIdentifier(antiRansomwareDetails)) || [],
    tooltipContext: {
      cluster: properties.cluster,
      environment: properties.environment,
      latestTimestampUsecs: properties.latestTimestampUsecs,
      object: properties.object,
      organization: properties.organization,
      protectionGroupId: properties.protectionGroupId,
      policy: properties.policy,
      size: properties.size,
      snapshotCount: properties.snapshotCount,
      source: properties.source,
      type: properties.type,
    },
  } as AnomalyAlert;
});

/**
 * Get the node value for the given policy.
 *
 * @param result The policy run result node.
 * @returns The node value.
 */
export const getNodeValue = (result: PolicyRunResult): number => {
  const files = result.results?.[0]?.files;
  return result.type === PolicyRunResultType.pattern
    ? (files?.addedOrModifiedFileCount || 0) + (files?.deletedFileCount || 0) + (files.unaffectedFileCount || 0)
    : undefined;
};
