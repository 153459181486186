// MODULE: Job Run Archive Tasks Detail Page

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.jobRunDetails')
    .controller('jobRunDetailsArchiveController', jobRunDetailsArchiveControllerFn);

  function jobRunDetailsArchiveControllerFn(
    _, $scope, $state, $q, $rootScope, ClusterService, SourceService,
    DeleteJobRunsModal, PollTaskStatus, JobRunsService, cUtils, DateTimeService,
    JobService, evalAJAX, ENUM_COPY_TASK_STATUS, ExternalTargetService) {

    var isTapeView = $scope.isTapeView = $state.$current.name.includes('tape');
    var isArchiveView = $scope.isArchiveView = $state.$current.name.includes('archive');

    // Caching a jobRun request so that we can prevent multiple fetches when
    // multiple tasks are present on this run.
    // @type  {object}   Eventually set as a promise
    var jobRunPromise;

    $scope.entityNamesHash = {};

    $scope.ENUM_COPY_TASK_STATUS = ENUM_COPY_TASK_STATUS;

    $scope.isTaskRunning = JobRunsService.isCopyTaskRunning;

    $scope.text = _.assign($scope.text, $rootScope.text.jobsDetailsRunArchive);

    $scope.jobRun = {};
    $scope.archiveData = {
      ready: false,
    };
    $scope.tasks = [];
    $scope.externalTargets = {};
    $scope.clusterCurrentTimeUsecs = undefined;
    $scope.pulses = {};
    $scope.progressTasksHash = {};
    $scope.expandedRows = {};

    /**
     * Tab Configuration
     * @type {Array}
     */
    $scope.cTabConfig = [{
      name: $scope.text.backupRun,
      value: 'job-run-details.protection',
      params: {
        instanceId: $state.params.instanceId,
        id: $state.params.id,
        startTimeUsecs: $state.params.startTimeUsecs
      }
    }];

    /**
     * Activate!
     *
     * @method    activate
     */
    function activate() {
      getData();
    }

    /**
     * Request all External Targets and add to map for quick reference in
     * template.
     *
     * @method   getExternalTargets
     */
    function getExternalTargets() {
      return ExternalTargetService.getTargets().then(
        function getExternalTargetsSuccess(targets) {
          $scope.externalTargets = targets.reduce(
            function idNameHashReducer(_targets, target) {
              _targets[target.id] = target.name;
              return _targets;
            },
            {}
          );
        }
      );
    }

    /**
     * Request data from API and parse it into a useful state.
     *
     * @method   getData
     */
    function getData() {
      var promiseArray = [
        ClusterService.getClusterInfo(),
        getJobRunTasks(),
        getExternalTargets(),
        getLatestJobRun(),
      ];

      // Fire off promise array
      $q.all(promiseArray).then(
        function getPromiseSuccess(r) {
          if(r[0] && r[0].data.currentTimeMsecs) {
            // Set Current Cluster Time in scope var
            $scope.clusterCurrentTimeUsecs = r[0].data.currentTimeMsecs * 1000;
          }

          if(r[1] && r[1][0] && r[1][0].backupJobRuns) {
            $scope.jobRun = r[1][0].backupJobRuns.protectionRuns[0];

            // Disable "Delete" for archive if it is "DirectArchive" and also
            // the latest archive run
            $scope.disableDeleteArchive =
              r[1][0].backupJobRuns.jobDescription.isDirectArchiveEnabled &&
              (_.get(r[1][0],
                'backupJobRuns.protectionRuns[0].copyRun.jobInstanceId') ===
                _.get(r[3][0],
                  'backupJobRuns.protectionRuns[0].copyRun.jobInstanceId'));

            // No Replication tasks. Move along.
            if (!$scope.jobRun._hasTapeTask &&
              !$scope.jobRun._hasCloudTask &&
              !$scope.jobRun._hasReplicationTask) {

              $state.go('job-run-details.protection', {
                id: $state.params.id,
                instanceId: $state.params.instanceId,
                startTimeUsecs: $state.params.startTimeUsecs,
              });
            }

            if ($scope.jobRun._hasReplicationTask) {
              $scope.cTabConfig.push({
                icon: 'remote',
                name: $scope.text.replication,
                value: 'job-run-details.replication',
                params: {
                  id: $state.params.id,
                  instanceId: $state.params.instanceId,
                  startTimeUsecs: $state.params.startTimeUsecs,
                },
              });
            }

            if ($scope.jobRun._hasTapeTask) {
              $scope.cTabConfig.push({
                icon: 'tape',
                name: $scope.text.tape,
                value: 'job-run-details.tape',
                params: {
                  id: $state.params.id,
                  instanceId: $state.params.instanceId,
                  startTimeUsecs: $state.params.startTimeUsecs,
                },
              });
            }

            if ($scope.jobRun._hasCloudTask) {
              // Enable the Archive Tab
              $scope.cTabConfig.push({
                icons: 'cloud',
                name: $scope.text.archive,
                value: 'job-run-details.archive',
                params: {
                  id: $state.params.id,
                  instanceId: $state.params.instanceId,
                  startTimeUsecs: $state.params.startTimeUsecs,
                },
              });
            }

            $scope.entityNamesHash =
              prepareEntityNamesHash($scope.jobRun.backupRun.latestFinishedTasks);

            if (!$scope.jobDescription.isDirectArchiveEnabled) {
              // Start pulse polling for each task
              _.each($scope.tasks, getPulseData);
            }
          }
        },
        evalAJAX.errorMessage
      ).finally(
        function afterGetJobRuns() {
          $scope.archiveData.ready = true;
        }
      );

    }

    /**
     * Gets the pulse data.
     *
     * @method   getPulseData
     * @return   {object}   Promise to resolve with the results of the
     *                      pollingIterator Fn.
     */
    function getPulseData(task) {
      return PollTaskStatus.createPoller({
        interval: 5,
        isDoneFn: _.bind(isPollingDone, this, task),
        iteratorFn: _.bind(pollingIterator, this, task),
        scope: $scope,
      });
    }

    /**
     * Function executed at every iteration of the poller.
     *
     * @method   pollingIterator
     * @return   {object}   Promise to resolve with the poller response.
     */
    function pollingIterator(task) {
      // Using the yoda progress monitor root path, fetch their pulse data
      var pulseOptions = {
        taskPathVec: _.get(task, 'archivalInfo.progressMonitorTaskPath'),
        includeFinishedTasks: true,
        excludeSubTasks: false,
      };

      $scope.polling = !isPollingDone(task);

      return !$scope.polling ?

        // Resolve early with nothing if there are no active indexing subtasks.
        // This will prevent unnecessary polling.
        $q.resolve([]) :

        // Otherwise we will poll for the indexing subtasks
        PollTaskStatus.getProgress(pulseOptions)
          .then(function pulseReceived(resp) {
            if (resp.resultGroupVec[0].taskVec[0]) {
              // progressMonitorTaskPath is a combination of taskUid keys.
              // So it is a unique hash.
              $scope.progressTasksHash
                [task.archivalInfo.progressMonitorTaskPath] =
                  resp.resultGroupVec[0].taskVec[0];
            }
          }, evalAJAX.errorMessage)
          .finally(function finallyHandler() {
            $scope.indexingDataReady = true;
          });
    }

    /**
     * Determines if polling is done.
     *
     * @method   isPollingDone
     * @return   {boolean}   True if polling done, False otherwise.
     */
    function isPollingDone(task) {
      return !!_.get($scope.progressTasksHash
        [_.get(task, 'archivalInfo.progressMonitorTaskPath')],
          'progress.endTimeSecs');
    }

    /**
     * Prepares an entity hash with key as entity id and value as entity
     * displayname
     *
     * @method   prepareEntityNamesHash
     * @param    {Array}    tasks   The tasks
     * @return   {Object}   The entity hash
     */
    function prepareEntityNamesHash(tasks) {
      return tasks.reduce(function prepareHash(accumulator, task) {
        accumulator[task.base.jobInstanceId] =
          task.base.sources[0].source.displayName;
        return accumulator;
      }, {});
    }

    /**
     * Fetch the Job Run data, process it a little,  and set a few scope
     * properties.
     *
     * @method    getJobRunTasks
     * @returns   {object}   Promise to resolve with the jobRun, or the full
     *                       response if error.
     */
    function getJobRunTasks() {
      var params = {
        id: +$state.params.id,
        exactMatchStartTimeUsecs: $state.params.startTimeUsecs || undefined,
      };

      return jobRunPromise = JobRunsService.getJobRuns(params).then(
        function jobRunsReceived(resp) {
          var run = resp[0].backupJobRuns.protectionRuns[0];
          // First, we add active tasks
          var tasks = run.copyRun.activeTasks || [];
          // Second, we add finished tasks
          tasks.push.apply(
            tasks,
            run.copyRun.finishedTasks || []
          );

          // Loop though all tasks and update them with properties to be
          // consumed by template.
          $scope.tasks = tasks.reduce(function tasksReducer(_tasks, task) {
            var isTapeTask = $scope.isTapeTask(task);
            var updatedTask;

            // If this is an archival task
            if (task.snapshotTarget && task.snapshotTarget.type === 3) {
              // ... And this is either tape view and this is a tape task, OR
              if ((isTapeView && isTapeTask) ||
                // This is archive view and not tape task
                (isArchiveView && !isTapeTask)) {

                updatedTask = updateTaskObject(task);

                _tasks.push(updatedTask);
              }
            }
            return _tasks;
          }, []);

          // Update some scope properties
          $scope.jobDescription = resp[0].backupJobRuns.jobDescription;
          $scope.jobRun = resp[0].backupJobRuns.protectionRuns[0];
          $scope.entityKey =
            SourceService.getEntityKey(
              resp[0].backupJobRuns.jobDescription.parentSource.type
            );

          // Pass the response through for other promise handlers to deal with
          return resp;
        },
        evalAJAX.errorMessage
      )
      .finally(function jobRunsError() {
        // Always empty the promise holder when a fetch is completed.
        jobRunPromise = undefined;
      });
    }

    /**
     * Get the latest job run. This is needed for directArchive jobs to
     * determine whether a snapshot can be deleted or not.
     * Latest snapshot of directArchive type can't be deleted.
     *
     * @method    getLatestJobRun
     * @returns   {object}   Promise to resolve with the jobRun.
     */
    function getLatestJobRun() {
      var params = {
        id: +$state.params.id,
        numRuns: 1
      };

      return jobRunPromise = JobRunsService.getJobRuns(params).then(
        function jobRunsReceived(resp) {
          return resp;
        },
        evalAJAX.errorMessage
      )
      .finally(function jobRunsError() {
        jobRunPromise = undefined;
      });
    }

    /**
     * Provides the archive schedule type for the given archive task
     *
     * @param      {object}  task    The archive task
     * @return     {string}  The archive schedule type
     */
    $scope.getArchiveScheduleType = function getArchiveScheduleType(task) {
      if (!task || !task.archivalInfo ||
        angular.isUndefined(task.archivalInfo.isIncrementalArchive)) {
        return $scope.text.na;
      }
      return task.archivalInfo.isIncrementalArchive ?
        $scope.text.incremental : $scope.text.full;
    };

    /**
     * Fn executed every time the poller iterates, until done.
     *
     * @method    progressUpdated
     * @param     {object|string}   data   The poller response object, or string
     *                                     if error or complete.
     */
    $scope.progressUpdated = function progressUpdated(data) {
      // If the jobRun data is currently being retrieved, or if this jobRun has
      // no active tasks, short-circuit because the run has completed. Could
      // also look for exitence of copyRun.activeTasks.
      if (jobRunPromise !== undefined ||
        ($scope.jobRun.copyTasks && !$scope.jobRun.copyTasks.endTimeUsecs)) {
        return;
      }

      // Run isn't done, refresh the jobRun data.
      getJobRunTasks();
    };

    /**
     * Delete the archived snapshots for the archival task
     *
     * @method   deleteArchivedSnapshot
     * @param    {Object}   task   The archival task
     */
    $scope.deleteArchivedSnapshot = function deleteArchivedSnapshot(task) {
      // Show a challenge modal to prevent accidental deletion
      DeleteJobRunsModal.showModal(false, true, true).then(
        function deleteJobConfirmed() {
          var archivalTarget = task.snapshotTarget.archivalTarget;
          var deleteData = {
            archivalTargets: [{
             copyTaskUid: task.taskUid,
             target: {
               id: archivalTarget.vaultId,
               name: archivalTarget.name,
               type: archivalTarget.type,
             },
             daysToKeep: 0,
            }],
            jobUid: task.jobUid,
            replicationTargets: [],
            runStartTimeUsecs: $scope.jobRun.copyRun.runStartTimeUsecs,
          };

          JobService.editJobRun(deleteData)
            .then($state.reload, evalAJAX.errorMessage);
        }
      );
    };

    /**
     * Updates a task object with convenience properties to be consumend by
     * template.
     *
     * @method   updateTaskObject
     * @param    {Object}   task   The Task object.
     * @return   {Object}   The updated Task object.
     */
    function updateTaskObject(task) {
      var endTimeUsecs = (task.archivalInfo && task.archivalInfo.endTimeUsecs) ?
        task.archivalInfo.endTimeUsecs : Date.clusterNow() * 1000;
      task._active = task.status < 3;

      task._isExpired = task.expiryTimeUsecs &&
        task.expiryTimeUsecs <= DateTimeService.getCurrentUsecs();

      task._progressMonitorUrl = task.archivalInfo &&
        task.archivalInfo.progressMonitorTaskPath && [
          'progressMonitors?=includeFinishedTasks=true&taskPathVec=',
          task.archivalInfo.progressMonitorTaskPath
        ].join('');

      if (task.archivalInfo &&
        task.archivalInfo.logicalBytesTransferred &&
        task.archivalInfo.startTimeUsecs) {

        // Cloud target rate
        task._rate = [
          cUtils.bytesToSize(task.archivalInfo.logicalBytesTransferred /
          ((endTimeUsecs - task.archivalInfo.startTimeUsecs) / 1000000)).string,
          $scope.text.perSecond
        ].join('');

      } else if (task.archivalInfo &&
        task.archivalInfo.avgLogicalTransferRateBps) {

        // Tape target rate
        task._rate = [
          cUtils.bytesToSize(obj.archivalInfo.avgLogicalTransferRateBps).string,
          $scope.text.perSecond
        ].join('');

      }
      task._parentSource =
        SourceService.getEntityName($scope.jobDescription.parentSource);

      return task;
    }

    /**
     * Toggles the expanded state of subTask
     * @param {Object}  key  key of row to be expanded/collapsed
     */
    $scope.toggleExpandedRow = function toggleExpandedRow(key) {
      $scope.expandedRows[key]  = !$scope.expandedRows[key];
    };

    // run setup function on first load
    activate();

  }

})(angular);
