// module: Cluster-Connect for Connected Site

;(function (angular) {
  'use strict';

  angular
    .module('C.clusterClaim', ["C.upload", "C.licenseUploadDownload"])
    .config(configFn)
    .component('connectedSite', {
      controller: 'connectedSiteCtrl as $ctrl',
      templateUrl: 'app/cluster-connect/cluster-connect-connected-site.html',
      bindings: {
        // signifies the type of registration, saas or on-cluster
        registrationType: '@',
      }
    })
    .controller('connectedSiteCtrl', connectedSiteCtrlFn);

  /**
   * Config Fn for this module.
   */
  function configFn($stateProvider) {
    $stateProvider
      .state('connected-site', {
        title: 'Connected Site',
        help: 'cluster_claim_connected',
        url: '^/connectedSite/{registrationType}',
        component: 'connectedSite',
        canAccess: 'true',
        params: {
          registrationType: { type: 'string' },
        },
        resolve: {
          registrationType: function setRegistrationType($transition$) {
            return $transition$.params().registrationType;
          },
        },
      });
  }

  function connectedSiteCtrlFn(_, SlideModalService, ClusterService,
    $state, HeliosService, LicenseServerService, cMessage) {
    var $ctrl = this;

    _.assign($ctrl, {
      // Life Cycle Method
      $onInit: $onInit,

      //method
      openConnectionModal: openConnectionModal,
      setRegistrationType: setRegistrationType,
      onConnectClick: onConnectClick,

      //Properties
      supportedFeatures: [
        {
          name: 'singleClusterManagement',
          sassSupport: true,
          onPremSupport: true,
        },
        {
          name: 'multiClusterManagement',
          sassSupport: true,
          onPremSupport: false,
        },
        {
          name: 'supportAutomation',
          sassSupport: true,
          onPremSupport: false,
        },
        {
          name: 'clusterClaim.whatIfAnalysis',
          sassSupport: true,
          onPremSupport: false,
        },
        {
          name: 'clusterClaim.smartLicensing',
          sassSupport: true,
          onPremSupport: false,
        },
      ],
    });

    /**
     * Compoonent Initialization Function
     *
     * @method $onInit
     */
    function $onInit() {
      $ctrl.existingCustomer = !ClusterService.isNewCluster();
      $ctrl.showSkipOption = ClusterService.clusterInfo.licenseState
        .failedAttempts >= 3;
    }

    /**
     * This method handles the connection modal config and
     * configures the modal dynamically
     *
     * @method   openConnectionModal
     */
    function openConnectionModal() {
      var modalConfig = {
        autoHeight: true,
        resolve: {
          actionButtonKey: false,
          closeButtonKey: false,
          innerComponent: 'licesningOptionModal',
          bindings: {
            registrationType: $ctrl.registrationType
          }
        }
      };

      return SlideModalService.newModal(modalConfig);
    }

    /**
     * function to control, what happens after connect click
     *
     * @method onConnectClick
     */
    function onConnectClick() {
      // If an existing customer with 'helios enabled' is trying to activate
      // license using 'Saas', directly update its License State.
      // If he's using 'On-Prem' option instead, take him to license upload screen.
      if ($ctrl.existingCustomer  &&
        HeliosService.heliosStatus._isRegistered) {

        if ($ctrl.registrationType === 'saas') {
          LicenseServerService.clusterClaimInstantSyncup()
          ClusterService.updateLicenseState('claimed')
            .then(function changeState() {
              $state.goHome();
              cMessage.success({
                titleKey: 'licenseActivated',
                textKey: 'clusterConnect.darkSite.licenseUploadSuccessMessage',
              });
            });
        } else {
          $state.go('cluster-connect-dark-site');
        }
      } else {
        openConnectionModal();
      }
    }

    /**
     * sets the registration type to saas/on-prem
     *
     * @method   setRegistrationType
     */
    function setRegistrationType(registrationType) {
      $ctrl.registrationType = registrationType;
    };
  }
})(angular);
