// Module: Manage Roles

;(function(angular) {
  'use strict';

  angular
    .module('C.manageRoles', ['C.seeMore'])
    .config(configFn)
    .controller('manageRolesController', manageRolesControllerFn);

  function configFn($stateProvider, $urlRouterProvider) {
    $stateProvider
      .state('access-management.roles', {
        title: 'Cohesity Roles Management',
        url: '^/admin/access/roles',
        help: 'admin_roles',
        canAccess: 'PRINCIPAL_VIEW',
        parentState: 'access-management',
        views: {
          'tab-view': {
            controller: 'manageRolesController',
            controllerAs: '$ctrl',
            templateUrl: 'app/admin/access-management/roles/roles.html',
          },
        },
      })
      .state('new-role', {
        title: 'Add Role',
        url: '^/admin/access/roles/new',
        help: 'admin_roles_new',
        canAccess: '!$root.isTenantUser() && PRINCIPAL_MODIFY',
        params: {
          roleCopy: { type: 'any' },
        },
        parentState: 'access-management.roles',
        controller: 'editRoleController',
        templateUrl: 'app/admin/access-management/roles/edit.html',
      })
      .state('edit-role', {
        title: 'Edit Role',
        url: '^/admin/access/roles/edit/{name}',
        help: 'admin_roles_new',
        canAccess: 'PRINCIPAL_VIEW',
        params: {
          name: { type: 'string' },
        },
        controller: 'editRoleController',
        templateUrl: 'app/admin/access-management/roles/edit.html',
        parentState: 'access-management.roles',
      });
  }

  function manageRolesControllerFn($rootScope, $scope, $state, $q,
    $interpolate, cModal, UserService, evalAJAX, TenantService, $translate) {

    var $ctrl = this;

    // List of all roles
    $scope.allRoles = [];

    // Hash of roles selected in the UI
    $scope.selectedRoles = {};

    $scope.defaultRoles = [];
    $scope.customRoles = [];

    _.assign($ctrl, {
      filters: {
        tenantIds: [],
      },

      // Component Lifecycle methods.
      $onInit: $onInit,

      // Methods exposed to the template.
      getAllRoles: getAllRoles,
    });

    /**
     * Update the list of selected Roles. If action is not supplied, default
     * action = 'add'
     *
     * @method     updateSelected
     * @param      {object}  role       A role object
     * @param      {string}  [action]   action to apply
     */
    function updateSelected(role, action) {
      if (action === 'remove') {
        delete $scope.selectedRoles[role.name];
      } else {
        $scope.selectedRoles[role.name] = role;
      }
    }

    /**
     * Toggle all checkboxes, excluding default system roles
     *
     * @method     toggleAllCheckboxes
     * @param      {boolean}  checked      whether the item was checked
     */
    function toggleAllCheckboxes(checked) {
      var action = checked ? 'add' : 'remove';

      $scope.allRoles.forEach(function toggleAllCheckboxesForEach(role) {
        if (!$scope.defaultRoles.includes(role.name)) {
          updateSelected(role, action);
        }
      });
    }

    /**
     * Toggle selection state of all roles/rows
     * Used by ng-click for the select all checkbox
     *
     * @method     toggleSelectAll
     * @param      {object}  e       The event (click)
     */
    $scope.toggleSelectAll = function toggleSelectAll(e) {
      toggleAllCheckboxes(e.target.checked);
    };

    /**
     * Toggle selection state for a single role/row
     * Used by ng-click for each checkbox
     *
     * @method     updateSelection
     * @param      {object}  e       The event (click)
     * @param      {object}  role    A role object
     */
    $scope.updateSelection = function updateSelection(e, role) {
      updateSelected(role, e.target.checked ? 'add' : 'remove');
    };

    /**
     * Test whether a provided role is selected
     * Used by ng-checked for each checkbox
     *
     * @method     isSelected
     * @param      {object}   role    a role object
     * @return     {boolean}  true if selected, false otherwise.
     */
    $scope.isSelected = function isSelected(role) {
      return !!$scope.selectedRoles[role.name];
    };

    /**
     * Test whether no roles are selected
     * Used by ng-disabled for Delete button
     *
     * @method     isNothingSelected
     * @return     {boolean}  true if none selected, false otherwise.
     */
    $scope.isNothingSelected = function isNothingSelected() {
      return !Object.keys($scope.selectedRoles).length;
    };

    /**
     * Test whether all roles are selected
     * Used by ng-checked for the select all checkbox
     *
     * @method     areAllSelected
     * @return     {boolean}  true if all selected, false otherwise.
     */
    $scope.areAllSelected = function areAllSelected() {
      return Object.keys($scope.selectedRoles).length ===
        $scope.allRoles.length - $scope.defaultRoles.length;
    };

    /**
     * Go to the add role page with a role copied
     *
     * @method     copyRole
     * @param      {object}  role    A role object
     */
    $scope.copyRole = function copyRole(role) {
      const {label, description, privileges} = role;

      $state.go('new-role', {
        roleCopy: {
          label: $translate.instant('copyOfLabel', {label}),
          description,
          privileges,
          isCustomRole: true,
          _isRoleOwner: true,
        },
      });
    };

    /**
     * Go to the edit role view
     *
     * @method     editRole
     * @param      {object}  role    A role object
     */
    $scope.editRole = function editRole(role) {
      $state.go('edit-role', {
        name: role.name,
      });
    };

    /**
     * Delete selected roles and update list
     *
     * @method     deleteRoles
     * @param      {Object}  role    A single role to delete. If absent, will
     *                               look for $scope.selectedRoles
     */
    $scope.deleteRoles = function deleteRoles(role) {
      var names = [];
      var rolesToDelete = {};
      var modalConfig = {
        controller: deleteRolesModalControllerFn,
        resolve: {
          names: function resolveNames() {
            return names;
          }
        },
      };
      var modalOptions;

      // Populate rolesToDelete with the single role argument
      // or else the entire set of selectedRoles
      if (role) {
        rolesToDelete[role.name] = role;
      } else {
        rolesToDelete = $scope.selectedRoles;
      }

      names = Object.keys(rolesToDelete);

      modalOptions = {
        titleKey: names.length === 1 ?
          'roles.delete.single.title' : 'roles.delete.multiple.title',
        contentKey: names.length === 1 ?
          'roles.delete.single.text' : 'roles.delete.multiple.text',
        contentKeyContext: { namesToDelete: names.join(', ') },
      };

      // Display the modal
      cModal.standardModal(modalConfig, modalOptions).then(getAllRoles);
    };

    /**
     * Process the roles response and add to list of allRoles
     *
     * @method     integrateRoles
     * @param      {array}  roles   List of role objects
     */
    function processRoles(roles) {
      angular.forEach(roles, function integrateRole(role) {
        if (role.isCustomRole) {
          $scope.customRoles.push(role.name);
        } else {
          $scope.defaultRoles.push(role.name);
        }

        $scope.allRoles.push(role);
      });
    }

    /**
     * Call API service to get all roles
     *
     * @method     getAllRoles
     */
    function getAllRoles() {
      var params = TenantService.extendWithTenantParams({
        _includeTenantInfo: true,
      }, $ctrl.filters.tenantIds);

      $scope.loading = true;

      UserService.getAllRoles(params).then(
        function getAllRolesSuccess(r) {
          toggleAllCheckboxes(false);
          $scope.allRoles.length = 0;
          processRoles(r);
        },

        evalAJAX.errorMessage
      ).finally(
        function getAllRolesFinally() {
          $scope.loading = false;
        }
      );
    }

    /**
     * Activate the controller
     *
     * @method     $onInit
     */
    function $onInit() {
      getAllRoles();
    }
  }

  /* @ngInject */
  function deleteRolesModalControllerFn($uibModalInstance, UserService,
    evalAJAX, names) {
    var $ctrl = this;

    $ctrl.ok = function okayThen() {
      $ctrl.submitting = true;
      UserService.deleteRoles({names: names}).then(
        $uibModalInstance.close,
        evalAJAX.errorMessage
      ).finally(function deleteFinally() {
        $ctrl.submitting = false;
      });
    };
  }

})(angular);
