import { Injectable } from '@angular/core';
import { flagEnabled, IrisContextService, isClusterScope, isLoggedIn, isMcmSaaS } from '@cohesity/iris-core';
import { TargetState, Transition } from '@uirouter/core';
import { AppStateService, DmsService, ScopeSelectorService, StateManagementService } from 'src/app/core/services';
import { getHeliosMfaStatus, heliosMfaGracePeriodExpired } from 'src/app/util';

import { GuardPriority, GuardResult, StateGuard } from '../state-guard';
import { environment } from 'src/environments/environment';

/**
 * State to redirect to for MFA prompt.
 */
const MfaPromptState = 'helios-mfa-prompt';

@Injectable({
  providedIn: 'root',
})
export class HeliosMfaPromptGuard implements StateGuard {
  /**
   * This guard priority is set to App, which indicates it should run as part of
   * the main application after all bootstrapping and initializing is completed.
   */
  guardPriority = GuardPriority.App;

  /**
   * Match to all pages except when going to MFA prompt page.
   */
  matchCriteria = {
    to: state => !state.name.startsWith(MfaPromptState),
  };

  constructor(
    private irisContextService: IrisContextService,
    private stateManagementService: StateManagementService,
    private appStateService: AppStateService,
    private scopeSelectorService: ScopeSelectorService,
    private dmsService: DmsService,
  ) {
  }

  /**
   * Function to return the transition target based on current MFA status
   * for Helios SaaS users.
   *
   * Nothing needs to be done if status is "OptIn" or "OptOut" as user has
   * explicitly made a decision.
   * If the status is "Pending", user is good to continue using the application
   * as long as the grace period has not expired.
   * If status is "Unknown", force user to make a decision through mfa prompt.
   *
   * @param transition
   */
  getTransitionTarget(transition: Transition): boolean | TargetState {
    if ((getHeliosMfaStatus(this.irisContextService.irisContext) === 'Unknown'
        || heliosMfaGracePeriodExpired(this.irisContextService.irisContext))
      && this.stateManagementService.canUserAccessState(MfaPromptState)) {
      // If the user can change the MFA config, redirect to prompt with MFA form.
      return transition.router.stateService.target(MfaPromptState);
    }

    if (heliosMfaGracePeriodExpired(this.irisContextService.irisContext)
      && this.stateManagementService.canUserAccessState(`${MfaPromptState}.expired`)) {
      // If the user cannot change the MFA config, redirect to a notice.
      return transition.router.stateService.target(`${MfaPromptState}.expired`);
    }

    return true;
  }

  /**
   * Guard to check state transitions for MFA config.
   *
   * The purpose of this workflow is to force the users to make a decision on MFA.
   * The default value is "Unknown". The users can either =>
   *  Enable it (OptIn)
   *  Disable it (OptOut)
   *  Snooze the decision for up to 30 days (Pending)
   *
   * @param   transition  The UI router transition
   * @return a redirect transition.
   */
  onStart(transition: Transition): GuardResult {
    if (!isLoggedIn(this.irisContextService.irisContext)
      || !isMcmSaaS(this.irisContextService.irisContext)
      || !this.irisContextService?.irisContext?.privs?.MCM_MODIFY_MFA
      || environment.heliosInFrame
      || !flagEnabled(this.irisContextService.irisContext, 'mfaHeliosSaas')
      || !flagEnabled(this.irisContextService.irisContext, 'mfaHeliosSaasPrompt')) {
      // The MFA prompt is only for logged in Helios SaaS users.
      return;
    }

    if (isClusterScope(this.irisContextService.irisContext)
      && getHeliosMfaStatus(this.irisContextService.irisContext) === 'Unknown') {
      // Switch to a Helios page first if in a cluster scope before showing the prompt.
      const context = this.appStateService.remoteClusterList.find(
        cluster => (
          cluster._globalContext || cluster._allClusters || cluster._serviceType === 'dms'
        )
      );

      return this.scopeSelectorService.switchScope(context);
    }

    return this.getTransitionTarget(transition);
  }
}
