// Module: Partitions

;(function (angular, undefined) {
  'use strict';

  angular
    .module('C.partitions', [])
    .config(configFn);

  function configFn($stateProvider) {
    $stateProvider
      .state('cluster.partitions', {
        name: 'Cohesity Partition Summary',
        url: '^/platform/partitions',
        help: 'admin_cluster_partitions',
        title: 'Manage Partitions',
        canAccess: 'FEATURE_FLAGS.multiplePartitions && CLUSTER_VIEW',
        parentState: 'cluster',
        views: {
          'cluster-content@cluster': {
            templateUrl: 'app/platform/partitions/partitions.html',
            controller: 'partitionsController',
          }
        }
      })
      .state('partition', {
        url: '^/platform/partitions/{mode}/{id}',
        params: {
          mode: { type: 'string' },
          id: { type: 'string', value: null },
        },
        help: 'admin_cluster_partitions',
        title: 'Partition',
        canAccess: 'CLUSTER_MODIFY',
        parentState: 'cluster.partitions',
        templateUrl: 'app/platform/partitions/detail/detail.html',
        controller: 'partitionDetailController',
      });
  }

})(angular);
