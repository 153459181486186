import { ProtectionGroup as ProtectionGroupApi, Tenant as TenantApi } from '@cohesity/api/v2';
import { get } from 'lodash';
import {
  cloudGroups,
  CloudJobType,
  envGroups,
  Environment,
  HasTenant,
  JobEnvParamsV2,
  mixinTenant,
  TaskStatus,
  Tenant,
} from 'src/app/shared';
import { AwsDBTypes, AwsS3Types } from 'src/app/shared/constants/cloud.constants';

import { ProtectionRun } from './protection-run.models';


/**
 * Base Data model for Protection landing page table.
 */
class ProtectionGroupBase implements HasTenant {
  /**
   * Group ID.
   */
  id: string;

  /**
   * Legacy Instance ID.
   */
  instanceId: number;

  /**
   * Legacy Job ID.
   */
  jobId: number;

  /**
   * Name.
   */
  name: string;

  /**
   * Total objects size in bytes.
   */
  totalObjectSize: number;

  /**
   * Environment.
   */
  environment: string;

  /**
   * Allow Parallel Runs.
   */
  allowParallelRuns: boolean;

  /**
   * The ID of policy used.
   */
  policyId: string;

  /**
   * Indicates if group's policy primary backup target is archival.
   * This field is updated externally based on policy data.
   */
  hasCadPolicy = false;

  /**
   * Indicates if the group's policy has cascadedTargetsConfig.
   */
  hasCascadedReplicationPolicy = false;

  /**
   * The start time of last run task.
   */
  startTime: number;

  /**
   * Duration of last run.
   */
  duration?: number;

  /**
   * The statuses of task. Can be foreign, pasued, deleted.
   */
  taskStatuses: string[];

  /**
   * If group is active. Needed to check allowed actions.
   */
  isActive: boolean;

  /**
   * If group is paused. Needed to check allowed actions.
   */
  isPaused: boolean;

  /**
   * If group is deleted. Needed to check allowed actions.
   */
  isDeleted: boolean;

  /**
   * True if group is protected once.
   */
  isProtectOnce: boolean;

  /**
   * An ID used to query the progress of currently running task in the group.
   */
  progressTaskId: string;

  /**
   * Some groups have protectionType to identify the job type
   */
  protectionType: string;

  /**
   * Source name of the protection group
   */
  sourceName: string;

  /**
   * Custom name of the source corresponding to the protection group
   */
  customSourceName: string;

  /**
   * Source ID of the protection group
   */
  sourceId: number;

  /**
   * Api provided list of "permissions" for multi-tenancy assignments. Technically,
   * a protection group can only belong to a single tenant, but this is a shared
   * interface implemented by the Iris API.
   */
  permissions: TenantApi[];

  /**
   * UI mapping of permissions to TenantColumnComponent compatible object.
   * Named in the singular as thats how old APIs were implemented.
   */
  tenant: Tenant[];

  /**
   * The id of the tenant associated with this protection group. For simple filtering.
   */
  tenantId: string;

  /**
   * Last run for this group.
   */
  lastRun: ProtectionRun;

  /**
   * The cluster this protection group is present in.
   */
  clusterId: number;

  /**
   * The region this protection group is present in.
   */
  regionId: string;

  /**
   * Paused note provided by user containing the reasoning for pausing future runs if any.
   */
  pausedNote: string;

  /**
   * The user who last paused this protection group.
   */
  lastPausedByUsername: string;

  /**
   * Specifies the timestamp (in microseconds. from epoch) for last paused this protection group.
   */
  lastPauseModificationTimeUsecs: number;

  /**
   * Returns true if Protection Group is a "Cloud" kind.
   */
  get isCloudGroup(): boolean {
    return cloudGroups.cloud.includes(this.environment as Environment);
  }

  /**
   * Returns true if Protection Group is of type 'Cloud Snapshot Manager'.
   * For such cases, there is no data read/write on local cluster.
   *
   * @return  True if Protection Group is CSM.
   */
  get isCSMGroup(): boolean {
    const envParams = this.getEnvParams();
    return envParams.protectionType === CloudJobType.kSnapshotManager;
  }

  /**
   * Returns true if Protection Group is of type 'AWS RDS'.
   * For such cases, there is no data read/write on local cluster.
   *
   * @return  True if Protection Group is RDS.
   */
  get isRDSGroup(): boolean {
    const envParams = this.getEnvParams();
    return envParams.protectionType === CloudJobType.kRDSSnapshotManager;
  }

  /**
   * Returns true if Protection Group is of type 'AWS S3'.
   * For such cases, there is no data read/write on local cluster.
   *
   * @return  True if Protection Group is S3.
   */
  get isS3Group(): boolean {
    const envParams = this.getEnvParams();
    return envParams.protectionType === CloudJobType.kAwsS3;
  }

  /**
   * Returns true if Protection Group is of type 'AWS RDS'.
   * For such cases, there is no data read/write on local cluster.
   *
   * @return  True if Protection Group is RDS.
   */
  get isAuroraGroup(): boolean {
    const envParams = this.getEnvParams();
    return envParams.protectionType === CloudJobType.kAuroraSnapshotManager;
  }

  /**
   * Returns true if Protection Group has only remotely managed snapshots.
   * True for AWS Remote snapshots like CSM and RDS.
   *
   * @return  True if Protection Group is Remotely Managed.
   */
  get isRemotelyManagedGroup(): boolean {
    return this.isCSMGroup || this.isRDSGroup || this.isAuroraGroup;
  }

  /**
   * Returns true if Protection Group uses Physical Agent for backup.
   *
   * @return  True if Protection Group uses physical agent.
   */
  get isAgentBasedGroup(): boolean {
    const envParams = this.getEnvParams();
    return envParams.protectionType === CloudJobType.kAgent;
  }

  /**
   * Returns true if a Cloud Protection Group uses Native API for backup.
   *
   * @return  True if Protection Group uses Native API.
   */
  get isNativeGroup(): boolean {
    const envParams = this.getEnvParams();
    return envParams.protectionType === CloudJobType.kNative;
  }

  /**
   * Returns true if Protection Group uses CloudArchive Direct.
   *
   * @return  True if Protection Group uses CloudArchive Direct.
   */
  get isDirectArchiveGroup(): boolean {
    const envParams = this.getEnvParams();
    return !!envParams.directCloudArchive;
  }

  /**
   * Returns true if Protection Group uses SnapDiff.
   *
   * @return  True if Protection Group uses SnapDiff.
   */
  get isSnapDiffGroup(): boolean {
    const envParams = this.getEnvParams();
    return !!envParams.snapMirrorConfig;
  }

  /**
   * Returns true if Protection Group is externally triggered.
   *
   * @return  True if Protection Group is triggered externally.
   */
  get isExternallyTriggered(): boolean {
    return !!this.getEnvParams().externallyTriggeredJobParams;
  }

  /**
   * Constructor.
   *
   * @param   reference   Return data structure of Protection Group object from API
   */
  constructor(public reference: ProtectionGroupApi) {
    // TODO: Fix typing here with new PG models.
    const {
      clusterId,
      environment,
      id,
      isActive,
      isDeleted,
      isPaused,
      isProtectOnce = false,
      lastRun,
      name,
      permissions,
      policyId,
      regionId,
      pausedNote = '',
      lastPausedByUsername = '',
      lastPauseModificationTimeUsecs = ''
    } = reference as any;

    this.lastRun = new ProtectionRun({
      protectionGroupId: id,
      protectionGroupName: name,
      ...lastRun
    });

    this.id = id;
    this.jobId = id && +id.split(':').pop();
    this.instanceId = this.lastRun.protectionGroupInstanceId;
    this.name = name;
    this.environment = this.getOverriddenEnvironment(reference);
    this.totalObjectSize = this.lastRun.totalBytes;
    this.policyId = policyId;
    this.startTime = this.lastRun.startTimeUsecs;
    this.duration = this.lastRun.duration;
    this.progressTaskId = this.lastRun.progressTaskId;
    this.regionId = regionId;
    this.clusterId = clusterId;
    this.pausedNote = pausedNote;
    this.lastPausedByUsername = lastPausedByUsername;
    this.lastPauseModificationTimeUsecs = lastPauseModificationTimeUsecs;

    if (this.environment === Environment.kPhysicalFiles) {
      this.allowParallelRuns =
        Boolean(reference?.[JobEnvParamsV2[environment]]?.fileProtectionTypeParams?.allowParallelRuns);
    }

    // Get sourcename from environment specific params
    this.sourceName = get(reference, [JobEnvParamsV2[environment], 'sourceName']);
    this.customSourceName = get(reference, [JobEnvParamsV2[environment], 'customSourceName']);
    this.sourceId = get(reference, [JobEnvParamsV2[environment], 'sourceId']);

    // group is active if the isActive property is missing
    this.isActive = isActive === undefined ? true : isActive;
    this.isPaused = isPaused;
    this.isDeleted = isDeleted;
    this.isProtectOnce = isProtectOnce;
    this.taskStatuses = [];

    // Failover ready is only applicable for VMware as per ENG-338705
    // Hiding this for All nas adapters
    // ENG-338705 created new issue (ENG-444508). For actual failover ready jobs
    // of all nas adapters it was not shwoing label.
    // To address this added additional check.
    // This is still not complete solution as for paused jobs we dont get last
    // run info. Will need api change to add a flag at group level for failover
    // job.
    if (isActive === false &&
      (!envGroups.nas.includes(environment) || reference.lastRun?.isReplicationRun)) {
      this.taskStatuses.push('kForeign');
    }
    if (isPaused) {
      this.taskStatuses.push('kPaused');
    }
    if (isDeleted) {
      this.taskStatuses.push('kDeleted');
    }

    this.permissions = permissions || [];
    this.tenant = this.permissions.map((permission: TenantApi) => {
      // A protection group can only belong to a single tenant. Safe to assign
      // this id as the group's tenantId.
      this.tenantId = permission.id;

      return {
        tenantId: permission.id,
        name: permission.name,
      };
    });

    this.setProtectionType();
  }

  /**
   * Gets last run backup status for sorting purpose.
   *
   * @returns Last run backup status
   */
  get backupStatus(): string {
    return this.lastRun?.status;
  }

  /**
   * Returns SLA status string value.
   */
  get slaStatus(): string {
    return this.lastRun?.slaStatus?.status;
  }

  /**
   * Returns whether the provided protection group is CDP enabled.
   *
   * @returns `true` if the protection group is CDP enabled, `false` otherwise.
   */
  get isCdpEnabled(): boolean {
    return this.reference.vmwareParams?.objects.some(object => object.cdpInfo?.cdpEnabled);
  }

  /**
   * Check if protection group is at rest, i.e., not canceling nor running.
   *
   * @param   isSqlParallel   Feature flag sqlParallelLogRuns
   * @returns true if protection group is at rest
   */
  isAtRest(isSqlParallel: boolean): boolean {
    const { status } = this.lastRun;
    if (status) {
      if (status === TaskStatus.Canceling ||
        (
          status === TaskStatus.Running &&
          !(isSqlParallel && this.environment === Environment.kSQL)
        )
      ) {
        return false;
      }
    }
    return true;
  }

  /**
   * Checks if the protection group has any ongoing archival tasks within its
   * last run.
   *
   * @returns boolean
   */
  hasActiveArchivalRun(): boolean {
    if (this.lastRun?.isReplicationRun && this.lastRun?.archivalTargets?.length) {
      for (const target of this.lastRun.archivalTargets) {
        if (target.status === TaskStatus.Running) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Checks if the protection group has any ongoing replication tasks within its
   * last run.
   *
   * @returns boolean
   */
  hasActiveReplicationRun(): boolean {
    if (this.lastRun?.isReplicationRun && this.lastRun?.replicationTargets?.length) {
      for (const target of this.lastRun.replicationTargets) {
        if (target.status === TaskStatus.Running) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Check if last run of a protection group is paused.
   *
   * @returns true if protection last run of protection group is paused.
   */
  isLastRunPaused(): boolean {
    return this.lastRun.status === TaskStatus.Paused;
  }

  /**
   * Check if protection group is a Log backup job.
   *
   * @returns true if protection group is a Log backup job.
   */
  get isCassandraLogbackup(): boolean {
    const envParams = this.getEnvParams();
    return this.environment === Environment.kCassandra && !!envParams?.isLogBackup;
  }

  /**
   * Returns correct environment type based on protectionType.
   *
   * @param reference ProtectionGroupApi object
   * @returns Environment string
   */
  getOverriddenEnvironment(reference: ProtectionGroupApi): string {
    // For kPhysical job, if protectionType is kFile, environement should be
    // kPhysicalFiles.
    if (reference.environment === Environment.kPhysical) {
      const protectionType = reference.physicalParams.protectionType;
      return protectionType === 'kFile' ? Environment.kPhysicalFiles : Environment.kPhysical;
    }

    return reference.environment;
  }

  /**
   * Some groups need special handling for Icons since the icon depends on
   * 'protectionType' in addition to the environment.
   *
   * @param reference ProtectionGroupApi object
   */
  setProtectionType() {
    const envParams = this.getEnvParams();

    if (envParams && envParams.protectionType &&
      [...AwsDBTypes, ...AwsS3Types].includes(envParams.protectionType)) {
      this.protectionType = envParams.protectionType;
    }

  }

  /**
   * get the envParams for the group
   */
  getEnvParams(): any {
    const env = this.reference.environment;
    return this.reference[JobEnvParamsV2[env]] || {};
  }
}

/**
 * A placeholder for protection group with tenant info (cannot be used directly as class).
 */
const _ProtectionGroupBase = mixinTenant(ProtectionGroupBase);

/**
 * The actual class used in the application.
 */
export class ProtectionGroup extends _ProtectionGroupBase {}
