<!-- Enable Pin View to SSD section -->
<div class="padding-bottom-sm">
  <mat-slide-toggle
    cogDataId="enable-pin-view-to-ssd"
    [formControl]="formGroupControls.enabled">
    {{'views.pinViewToSSD' | translate}}
  </mat-slide-toggle>
</div>

<div *ngIf="formGroup.value?.enabled">
  <!-- Scan timeout seconds -->
  <coh-retention-selector
    class="padding-left"
    cogDataId="pin-view-duration"
    [control]="formGroupControls.pinnedTimeSecs"
    [id]="pin-view-duration"
    label="duration"
    [min]="1"
    defaultGrain="Forever"
    [omitGrains]="['None', 'Seconds', 'Weeks']"
    placeholder="placeholderNumber"
    [multiplierFactor]="1"
    (valueChange)="onChangeRetention($event)">
  </coh-retention-selector>
</div>
