// Service: Restore (Public API integration)
// This file contains the Public.API service calls we make for restore.

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.pubRestoreService', [])
    .service('PubRestoreService', PubRestoreServiceFn)
    .constant('ENV_TO_DBTYPE', {
      kSQL: 'sql',
      kOracle: 'oracle',
    });

  function PubRestoreServiceFn(_, $http, $state, API, evalAJAX,
    PubRestoreServiceFormatter) {
    return  {
      getFileVersions: getFileVersions,
      getRestoreTaskById: getRestoreTaskById,
      restore: restore,
      restoreFiles: restoreFiles,
      retryRestoreTask: retryRestoreTask,
    };

    /**
     * Recover objects
     *
     * @param   {Object}   restoreTask   {
     *                                     name: 'test',
     *                                     objects: [...],
     *                                     ...
     *                                   }
     *
     * @return   {object}   returns a promise
     */
    function restore(restoreTask) {
      return $http({
        method: 'post',
        url: API.public('restore/recover'),
        data: restoreTask,
      }).then(function reduceResponse(resp) {
        return resp.data;
      });
    }

    /**
     * Restore files
     *
     * @param     {Object}   restoreTask  {
     *                                      name: 'test',
     *                                      filenames: [...],
     *                                      sourceObjectInfo: {...}
     *                                      isFileBasedVolumeRestore: true,
     *                                      ....
     *                                    }
     *
     * @return    {object}   returns a promise
     */
    function restoreFiles(restoreTask) {
      return $http({
        method: 'post',
        url: API.public('restore/files'),
        data: restoreTask,
      }).then(function reduceResponse(resp) {
        return resp.data;
      });
    }

    /**
     * Retries the given restore task.
     *
     * @method   retryRestoreTask
     * @param    {object}   restoreTask   The task to resubmit.
     * @return   {object}   Promise carrying the restore task.
     */
    function retryRestoreTask(restoreTask) {
      var service = restore;

      switch (restoreTask.type) {
        // files
        case 'kRestoreFiles':
          service = restoreFiles;
          break;
      }

      return service(restoreTask)
        .then(function taskAcceptedSuccess(restoreResponse) {
          // TODO(sanath): Move restore to a seperate component and handle
          //               Redirection there.
          //               ENG-47136
          var stateName = PubRestoreServiceFormatter
            .getRestoreTaskDetailStateName(restoreResponse);
          var stateParams = { id: restoreResponse.id, };

          // Move to the new state.
          $state.go(stateName, stateParams);
        },
        evalAJAX.errorMessage
      );
    }

    /**
     * Get the restore Task Details by Id.
     *
     * @method   getRestoreTaskById
     * @param    {number}   taskId   task Id for which you need details.
     * @return   {object}   Promise carrying the restore task details.
     */
    function getRestoreTaskById(taskId) {
      return $http({
        method: 'GET',
        url: API.public('restore/tasks/' + taskId),
      }).then(function onTaskDetailsResponse(response) {
        return response.data || {};
      });
    }

    /**
     * Fetches the document versions using the public API and returns a promise
     * with resolved versions.
     *
     * @method   getFileVersions
     * @param    {param}    fileVersionArg   Specifies the parameters for
     *                                       request.
     * @return   {object}   Promise with resolved file versions.
     */
    function getFileVersions(fileVersionArg) {
      return $http({
        method: 'get',
        url: API.public('restore/files/snapshotsInformation'),
        params: fileVersionArg,
      }).then(function onSuccess(response) {
        return PubRestoreServiceFormatter
          .decoratePublicFileVersions(response.data);
      });
    }
  }
}(angular));
