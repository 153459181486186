<label class="archive-target-option margin-right-xs"
  *ngFor="let target of replicas; index as index">
  <input type="radio"
    id="select-stored-source-snapshot-radio-{{index}}"
    class="sr-only"
    [class.read-only]="!allowSelection"
    name="stored-source-{{index}}"
    (click)="selectSnapshotTarget(target)"
    [checked]="target === selectedTarget">
  <mat-icon class="icn-md"
    [ngClass]="target | snapshotTargetIcon"
    matTooltip="{{isLocal(target) ? ('local' | translate) : target.target.archivalTarget.name}}">
  </mat-icon>
</label>