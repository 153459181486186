<div class="activity-item {{ customClass }}" (click)="click.emit()">
  <div
    class="activity-status"
    [ngClass]="severity"
    [matTooltip]="iconTooltip"
    [matTooltipClass]="multiLineTooltip ? 'activity-item-multi-line-tooltip' : null">
    <cog-icon [shape]="icon"></cog-icon>
  </div>

  <ng-content></ng-content>
</div>
