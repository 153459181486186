/* eslint-disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for CohesityKeplerApi services
 */
@Injectable({
  providedIn: 'root',
})
export class CohesityKeplerApiConfiguration {
  rootUrl: string = '/v2/mcm';
}

export interface CohesityKeplerApiConfigurationInterface {
  rootUrl?: string;
}
