// Module: LDAP Providers

;(function(angular) {
  'use strict';

  angular
    .module('C.ldap', [])
    .config(configFn);

  function configFn($stateProvider) {
    var viewAccess = 'AD_LDAP_VIEW';
    var modifyAccess = 'AD_LDAP_MODIFY';

    $stateProvider
      .state('access-management.ldap', {
        title: 'LDAP Provider Management',
        url: '^/admin/access/ldap',
        help: 'admin_ldap',
        canAccess: viewAccess,
        parentState: 'access-management',
        views: {
          'tab-view': {
            component: 'ldapList',
          },
        },
      })

      // The add-dap state can also be loaded in a slide modal using
      // LdapService.newLdapProviderModal()
      .state('add-ldap', {
        title: 'LDAP Provider Management',
        url: '^/admin/access/ldap/add',
        help: 'admin_ldap',
        canAccess: modifyAccess,
        parentState: 'access-management.ldap',
        controller: 'addLdapController as $ctrl',
        templateUrl: 'app/admin/access-management/ldap/modify.html',
        resolve: {
          // object to resolve $uibModalInstance for state controller use
          $uibModalInstance: function resolveUibModalInstance() {
            return false;
          },
        },
      })
      .state('edit-ldap', {
        title: 'LDAP Provider Management',
        url: '^/admin/access/ldap/edit/{id}',
        help: 'admin_ldap',
        parentState: 'access-management.ldap',
        canAccess: modifyAccess,
        params: {
          id: { type: 'string' },
        },
        controller: 'editLdapController as $ctrl',
        templateUrl: 'app/admin/access-management/ldap/modify.html',
      })
      .state('view-ldap', {
        title: 'LDAP Provider Management',
        url: '^/admin/access/ldap/{id}',
        help: 'admin_ldap',
        canAccess: viewAccess,
        params: {
          id: { type: 'int' },
        },
        component: 'ldapView',
        parentState: 'access-management.ldap',
        resolve: {
          // Resolve the state params as a binding to work with both modal and
          // page view
          ldapId: function resolveLdapId($transition$) {
            return $transition$.params().id;
          },
        },
      });
  }

})(angular);
