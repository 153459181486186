/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { S3AbacServerResponse } from '../models/s3abac-server-response';
import { S3AbacServerUpdateRequestParams } from '../models/s3abac-server-update-request-params';
import { S3AbacServersResponse } from '../models/s3abac-servers-response';
import { S3AbacServerCreateRequestParams } from '../models/s3abac-server-create-request-params';
@Injectable({
  providedIn: 'root',
})
class ObjectAuthServiceService extends __BaseService {
  static readonly GetS3AbacServerByIdPath = '/s3-abac-servers/{id}';
  static readonly UpdateS3AbacServerByIdPath = '/s3-abac-servers/{id}';
  static readonly DeleteS3AbacServerByIdPath = '/s3-abac-servers/{id}';
  static readonly GetS3AbacServerPath = '/s3-abac-servers';
  static readonly CreateS3AbacServerPath = '/s3-abac-servers';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a S3 ABAC server by Id.
   *
   * Returns S3 ABAC server details for a given id.
   * @param params The `ObjectAuthServiceService.GetS3AbacServerByIdParams` containing the following parameters:
   *
   * - `id`: Specifies Id of a S3 ABAC server.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetS3AbacServerByIdResponse(params: ObjectAuthServiceService.GetS3AbacServerByIdParams): __Observable<__StrictHttpResponse<S3AbacServerResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/s3-abac-servers/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<S3AbacServerResponse>;
      })
    );
  }
  /**
   * Get a S3 ABAC server by Id.
   *
   * Returns S3 ABAC server details for a given id.
   * @param params The `ObjectAuthServiceService.GetS3AbacServerByIdParams` containing the following parameters:
   *
   * - `id`: Specifies Id of a S3 ABAC server.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetS3AbacServerById(params: ObjectAuthServiceService.GetS3AbacServerByIdParams): __Observable<S3AbacServerResponse> {
    return this.GetS3AbacServerByIdResponse(params).pipe(
      __map(_r => _r.body as S3AbacServerResponse)
    );
  }

  /**
   * Update a S3 ABAC server by Id.
   *
   * Updates the existing S3 ABAC server details specified by server id.
   * ABAC private key is not required to update server details.
   * @param params The `ObjectAuthServiceService.UpdateS3AbacServerByIdParams` containing the following parameters:
   *
   * - `id`: Specifies Id of a S3 ABAC server.
   *
   * - `body`: Request to update a S3 ABAC server.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateS3AbacServerByIdResponse(params: ObjectAuthServiceService.UpdateS3AbacServerByIdParams): __Observable<__StrictHttpResponse<S3AbacServerResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/s3-abac-servers/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<S3AbacServerResponse>;
      })
    );
  }
  /**
   * Update a S3 ABAC server by Id.
   *
   * Updates the existing S3 ABAC server details specified by server id.
   * ABAC private key is not required to update server details.
   * @param params The `ObjectAuthServiceService.UpdateS3AbacServerByIdParams` containing the following parameters:
   *
   * - `id`: Specifies Id of a S3 ABAC server.
   *
   * - `body`: Request to update a S3 ABAC server.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateS3AbacServerById(params: ObjectAuthServiceService.UpdateS3AbacServerByIdParams): __Observable<S3AbacServerResponse> {
    return this.UpdateS3AbacServerByIdResponse(params).pipe(
      __map(_r => _r.body as S3AbacServerResponse)
    );
  }

  /**
   * Delete a S3 ABAC server by Id.
   *
   * Deletes S3 ABAC server specified by server id.
   * @param params The `ObjectAuthServiceService.DeleteS3AbacServerByIdParams` containing the following parameters:
   *
   * - `id`: Specifies Id of a S3 ABAC server.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteS3AbacServerByIdResponse(params: ObjectAuthServiceService.DeleteS3AbacServerByIdParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/s3-abac-servers/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a S3 ABAC server by Id.
   *
   * Deletes S3 ABAC server specified by server id.
   * @param params The `ObjectAuthServiceService.DeleteS3AbacServerByIdParams` containing the following parameters:
   *
   * - `id`: Specifies Id of a S3 ABAC server.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteS3AbacServerById(params: ObjectAuthServiceService.DeleteS3AbacServerByIdParams): __Observable<null> {
    return this.DeleteS3AbacServerByIdResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * List S3 ABAC servers.
   *
   * Lists S3 ABAC servers filtered by id. If no id is provided then a list
   * of all S3 ABAC servers is returned.
   * @param params The `ObjectAuthServiceService.GetS3AbacServerParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `ids`: Filter by a list of S3 ABAC Server IDs.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetS3AbacServerResponse(params: ObjectAuthServiceService.GetS3AbacServerParams): __Observable<__StrictHttpResponse<S3AbacServersResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/s3-abac-servers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<S3AbacServersResponse>;
      })
    );
  }
  /**
   * List S3 ABAC servers.
   *
   * Lists S3 ABAC servers filtered by id. If no id is provided then a list
   * of all S3 ABAC servers is returned.
   * @param params The `ObjectAuthServiceService.GetS3AbacServerParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `ids`: Filter by a list of S3 ABAC Server IDs.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetS3AbacServer(params: ObjectAuthServiceService.GetS3AbacServerParams): __Observable<S3AbacServersResponse> {
    return this.GetS3AbacServerResponse(params).pipe(
      __map(_r => _r.body as S3AbacServersResponse)
    );
  }

  /**
   * Create a S3 ABAC server.
   *
   * Adds S3 ABAC server details in Cohesity cluster configuration and
   * prepare files on disk required to connect to S3 ABAC server. There is
   * only be one S3 ABAC server per tenant.
   * @param params The `ObjectAuthServiceService.CreateS3AbacServerParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a S3 ABAC server entry.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateS3AbacServerResponse(params: ObjectAuthServiceService.CreateS3AbacServerParams): __Observable<__StrictHttpResponse<S3AbacServerResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/s3-abac-servers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<S3AbacServerResponse>;
      })
    );
  }
  /**
   * Create a S3 ABAC server.
   *
   * Adds S3 ABAC server details in Cohesity cluster configuration and
   * prepare files on disk required to connect to S3 ABAC server. There is
   * only be one S3 ABAC server per tenant.
   * @param params The `ObjectAuthServiceService.CreateS3AbacServerParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a S3 ABAC server entry.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateS3AbacServer(params: ObjectAuthServiceService.CreateS3AbacServerParams): __Observable<S3AbacServerResponse> {
    return this.CreateS3AbacServerResponse(params).pipe(
      __map(_r => _r.body as S3AbacServerResponse)
    );
  }
}

module ObjectAuthServiceService {

  /**
   * Parameters for GetS3AbacServerById
   */
  export interface GetS3AbacServerByIdParams {

    /**
     * Specifies Id of a S3 ABAC server.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateS3AbacServerById
   */
  export interface UpdateS3AbacServerByIdParams {

    /**
     * Specifies Id of a S3 ABAC server.
     */
    id: number;

    /**
     * Request to update a S3 ABAC server.
     */
    body: S3AbacServerUpdateRequestParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteS3AbacServerById
   */
  export interface DeleteS3AbacServerByIdParams {

    /**
     * Specifies Id of a S3 ABAC server.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetS3AbacServer
   */
  export interface GetS3AbacServerParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Filter by a list of S3 ABAC Server IDs.
     */
    ids?: Array<number>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateS3AbacServer
   */
  export interface CreateS3AbacServerParams {

    /**
     * Specifies the parameters to create a S3 ABAC server entry.
     */
    body: S3AbacServerCreateRequestParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { ObjectAuthServiceService }
