import { Injectable } from '@angular/core';
import { CoreMonitoringService } from '@cohesity/iris-core';
import { heliosProductionHostnames } from 'src/environments/app-environment.model';
import { environment } from 'src/environments/environment';
import { VERSION } from 'src/environments/version';

/**
 * Service to initialize Datadog RUM for Helios.
 */
@Injectable({
  providedIn: 'root',
})
export abstract class HeliosMonitoringService {
  constructor(private coreMonitoringService: CoreMonitoringService) {
  }

  /**
   * Initializes Datadog RUM.
   */
  initDatadog() {
    this.coreMonitoringService.initDatadog({
      environment: this.getMonitoringEnvironment(),
      version: VERSION.hash,
    });
  }

  /**
   * Returns the monitoring environment based on the hostname and the environment configuration.
   *
   * @returns The monitoring environment.
   */
  private getMonitoringEnvironment(): string {
    if (heliosProductionHostnames.includes(window.document.location.hostname) && environment.production) {
      // Use 'production' as environment if UI is deployed in production.
      return 'production';
    }

    // Otherwise use 'development' as environment name.
    return 'development';
  }
}
