<coh-dynamic-form-portal class="object-params-section"
  [component]="formComponent"
  [control]="formGroup.get('objectParams')"
  [parentForm]="formGroup">
</coh-dynamic-form-portal>
<cog-settings-list class="common-options-settings-list"
  [alwaysOpen]="true"
  [readOnly]="true"
  [formGroup]="formGroup">
  <cog-settings-list-item cogFormGroup [label]="'recoveredBy' | translate">
    <cog-settings-list-item-value-display>
      {{ recovery.userName }}
    </cog-settings-list-item-value-display>
  </cog-settings-list-item>
  <cog-settings-list-item cogFormGroup [label]="'recoveryType' | translate">
    <cog-settings-list-item-value-display>
      {{ (recovery.type ? 'enum.recoveryType.' + recovery.type : 'naNotAvailable') | translate }}
    </cog-settings-list-item-value-display>
  </cog-settings-list-item>
</cog-settings-list>
