import { Pipe, PipeTransform } from '@angular/core';

import { ByteSize } from './byte-size.model';
import { ByteSizeService } from './byte-size.service';

/**
 * Convert provided Number of Bytes into humanize byte size with its unit.
 */
@Pipe({
  name: 'byteSize',
  pure: true,
  standalone: true
})
export class ByteSizePipe implements PipeTransform {
  constructor(private byteSizeService: ByteSizeService) { }

  /**
   * Converts provided input byte size into humanize byte size with its unit.
   */
  transform(
    rawBytes: number,
    asHtml: boolean = false,
    htmlElem: string = '',
    precision?: number,
    useNaLetters?: boolean
  ): string {

    const bytes: ByteSize = this.byteSizeService.bytesToSize(rawBytes, precision, useNaLetters);
    let outStr = bytes.displayValue;

    if (asHtml) {
      outStr = htmlElem ?
        `${bytes.size} <${htmlElem}>${bytes.unit}</${htmlElem}>` :
        bytes.asHtml;
    }

    return outStr;
  }
}
