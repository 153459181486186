import { CommonModule } from '@angular/common';
import { Compiler, Injector, NgModule } from '@angular/core';
import { SnapshotRecoveryPluginModule } from '@cohesity/data-govern/threat-detection';
import { WebComponentModule } from '@cohesity/helix';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { environment } from 'src/environments/environment';

import { SnapshotSelectorPluginComponent } from './snapshot-selector-plugin.component';

@NgModule({
  declarations: [SnapshotSelectorPluginComponent],
  exports: [SnapshotSelectorPluginComponent],
  imports: [
    CommonModule,
    WebComponentModule,
  ],
})
export class SnapshotSelectorPluginModule {
  constructor(
    private compiler: Compiler,
    private injector: Injector,
    private irisCtx: IrisContextService,
  ) {
    if (
      // Skipping argus threat detection scnapshot recovery plugin in helios iframe build
      // because plugin will be loaded using SPA parcel from latest helios control plane build.
      !environment.heliosInFrame &&
      flagEnabled(this.irisCtx.irisContext, 'dataHawkIrisSnapshotRecoveryPluginEnabled')
    ) {
      this.compiler.compileModuleSync(SnapshotRecoveryPluginModule).create(this.injector);
    }
  }
}
