// Component: Select or Add View

;(function(angular, undefined) {
  'use strict';

  var componentName = 'selectView';
  var configOptions = {
    controller: 'SelectViewCtrl',
    templateUrl: 'app/global/select-view/select-view.html',

    // Attribute bindings
    bindings: {
      /**
       * Specify a default View by this ID if found in the list. NOTE: This is
       * Bridge View id, not to be confused with a protectionSource.id for a
       * View protectionSource (magneto managed).Until a View is protected,
       * it is not registered as protectionSource.
       *
       * @type   {number}
       */
      defaultId: '<?',

      /**
       * If results should be limited to a particular View Box, the id of the
       * View Box should be bound here.
       *
       * @type   {number}
       */
      viewBoxId: '<?',

      /**
       * If this attribute is present on the element, only Views with nfs mount
       * paths will be displayed for selection. Others will be filtered out
       * after API retrieval.
       *
       * @type   {boolean}   nfsOnly
       */
       nfsOnly: '<?',

       /**
        * If this attribute is present on the element, creating a new View will
        * have certainly Remote Adapter specific settings enforced. For
        * instance, the View will only be allowed to use 'nfs' or 'all'
        * protocols.
        *
        * @type   {boolean}   remoteAdapater
        */
       remoteAdapter: '<?',

       /**
        * If this attribute is present on the element, the ui-select-choices
        * will be filtered accordingly.
        *
        * @type   {function}   filterFn
        */
       filterFn: '<?',

       /**
        * If this attribute is present on the element, do not sure the Add New
        * View option.
        *
        * @type   {boolean}   disableAddNew
        */
       disableAddNew: '<?',

       /**
        * Optionally, if the attribute is present, only get the owned views,
        * else get all the views.
        */
       onlyOwn: '<?',
    },

    // Required outside things. These show up on `this.ngModel` in the
    // controller.
    require: {
      ngModel: 'ngModel',
    },
  };

  angular.module('C.selectView', ['ui.select'])
    .controller('SelectViewCtrl', SelectViewCtrlFn)
    .component(componentName, configOptions);

  /**
   * $ngdoc Component
   * @name C.selectView:selectView
   * @scope
   * @link
   *
   * @requires ngModel
   * @methodOf angular.Module|AUTO.
   * @function
   * @description
   *   Provides a dropdown component for selecting or adding a new View.
   *
   * @example
       <select-view ng-model="$ctrl.selectedView"></select-view>
   */
  function SelectViewCtrlFn($attrs, ViewService, ViewBoxService, evalAJAX) {
    var $ctrl = this;

    angular.extend($ctrl, {
      // binding $attrs for disabled state pass through
      $attrs: $attrs,
      views: [],
      $onInit: $onInit,
      $onChanges: $onChanges,
      filterList: filterList,
      selectOrAddView: selectOrAddView,
    });


    /**
     * Initialize this component.
     *
     * @method     init
     */
    function $onInit() {
      angular.extend($ctrl, {
        id: [($attrs.id || componentName), 'ui-select'].join('-'),
        name: [componentName, $attrs.name, Date.now()].join('-'),
      });
      getViews();
    }

    /**
     * Componenent lifecycle function to handle changes to single direction
     * binding values.
     *
     * @method   $onChange
     * @param    {object}   changesObj   The change object, provided by Angular.
     */
    function $onChanges(changesObj) {
      if (changesObj.viewBoxId) {
        // View Box id was changed... need to update the list of Views.
        getViews();
      }
    }

    /**
     * Set the model with the provided ViewBox, or open the new View modal
     * if no View provided, selecting the newly created View on
     * successful create.
     *
     * @method   selectOrAddView
     * @param    {object}   [view]   The selected View
     */
    function selectOrAddView(view) {
      if (view && view.viewId) {
        return setSelection(view);
      }

      ViewBoxService.createViewInViewBox($ctrl.viewBoxId, !!$ctrl.remoteAdapter)
        .then(
          function modalResolved(newView) {
            $ctrl.views.unshift(newView);
            setSelection(newView);
          },
          function modalCanceled() {
            setSelection(undefined);
          }
        );
    }

    /**
     * Sets the selection in this component and in ngModel simultaneously.
     *
     * @method   setSelection
     * @param    {object}   [view]   The View.
     */
    function setSelection(view) {
      $ctrl.ngModel.$setViewValue($ctrl.selectedView = view);
    }

    /**
     * Gets the Views
     *
     * @method   getViews
     * @return   {object}   Promise to resolve with the requested data.
     */
    function getViews() {
      var params = !$ctrl.viewBoxId ? undefined : {
        viewBoxIds: [$ctrl.viewBoxId],
      };
      var getViews = $ctrl.onlyOwn ? ViewService.getOwnViews :
        ViewService.getViews;

      $ctrl.views.length = 0;
      $ctrl.loading = true;

      return getViews(params).then(
        function thenHandler(views) {
          var defaultFound;

          if ($ctrl.nfsOnly) {
            views = views.filter(function filterForNfs(view) {
              return !!view.nfsMountPath;
            });
          }

          $ctrl.views = views;

          /**
           * If a View is currently selected, make it the default so it will be
           * reselected in the event that it exists in the newly retrieved
           * results.
           */
          $ctrl.defaultId = $ctrl.selectedView ?
            $ctrl.selectedView.id : $ctrl.defaultId;

          /**
           * if a defaultId was provided (or set), find it in the list of
           * policies and assign it to the internal model
           */
          if ($ctrl.defaultId) {
            defaultFound = $ctrl.views.some(function findDefault(view) {
              if (view.viewId === $ctrl.defaultId) {
                $ctrl.selectOrAddView(view);
                return true;
              }
            });

            if (!defaultFound) {
              // The default wasn't found. Clear the internal model.
              $ctrl.setSelection();
            }
          }
        },
        evalAJAX.errorMessage
      ).finally(function getViewsFinally() {
        $ctrl.loading = false;
      });
    }

    /**
     * Filters out any item according to provided filter fn.
     *
     * @method      filterList
     * @param       {Object}     item     Item to test.
     * @returns     {Boolean}    false if want to exclude.
     */
    function filterList(item) {
      return !$ctrl.filterFn ? true : $ctrl.filterFn(item);
    }
  }


})(angular);
