// Component: Select or Register Parent Source

;(function(angular, undefined) {
  'use strict';

  var componentName = 'selectParentSource';
  var configOptions = {
    controller: 'SelectParentSourceCtrl',
    templateUrl: 'app/global/select-parent-source/select-parent-source.html',

    // Attribute bindings
    bindings: {
      /**
       * Optional single value or array of kEnvironment strings for which to
       * display in the dropdown.
       *
       * Note: undefined will show *all* registered Sources.
       *
       * Default: undefined
       *
       * @type   {array}
       */
      environments: '<?',

      /**
       * Optional array of kEnvironment IDs to which the selection of
       * "add new source" is to be restricted.
       *
       * Note: undefined will allow *all* kinds of sources to be registered.
       *
       * Default: undefined
       *
       * @type   {number[]}
       */
      allowedEnvTypes: '<?',

      /**
       * Output of the retrieved sources.
       *
       * WARNING: Do not use this for input. It is intended purely for exposing
       * the fetched & filtered sources.
       */
      sources: '=?',

      /**
       * Optionally disable registering new sources within.
       *
       * Default: enabled.
       *
       * @type   {boolean}
       */
      addNew: '<?',

      /**
       * Specify a default source by this ID if found in the list.
       *
       * @type   {number}
       */
      defaultId: '=?',

      /**
       * Specify a default environment and the first source having this
       * environment will be selected by default. This is particularly useful
       * for environments in ENV_GROUPS.cohesityGroups[], as it can safely be
       * assumed that there will only be one root source of these environments.
       *
       * @type   {string}
       */
      defaultEnvironment: '@?',

      /**
       * The option to "add/register new source" will not be shown
       * if this is passed as True.
       *
       * @type   {Boolean}
       */
      hideAddNew: '<?',

      /**
       * Optional attribute sniffed value, if attribute is present the uiSelect
       * component will open automatically on initialization if the model is
       * empty.
       */
      // autoOpenIfEmpty: '?'

      /**
       * Optional attribute sniffed value, if attribute is present then list all
       * sources owned by the logged in user organization.
       */
      // only-own: '?'

      /**
       * Optional function to determine if a source should be prevented from
       * selection
       *
       * @type  {Function}
       */
      isSourceDisabledFn: '&?',
    },

    // Required outside things. These show up on `this.ngModel` in the
    // controller.
    require: {
      ngModel: 'ngModel',
    },
  };

  angular.module('C.selectParentSource', ['C.filters'])
    .controller('SelectParentSourceCtrl', selectParentSourceCtrl)
    .component(componentName, configOptions);

  /**
   * $ngdoc Component
   * @name C.selectParentSource:selectParentSource
   * @scope
   * @link
   *
   * @requires ngModel
   * @function
   * @description
   *   Provides a dropdown list of parent sources based on provided
   *   environments.
   *
   * @example
      <select-parent-source environments="['kVMware', 'kHyperV']">
      </select-parent-source>
   */
  function selectParentSourceCtrl($attrs, $filter, evalAJAX, SourceService,
    PubSourceService, ENV_TYPE_CONVERSION, ENV_GROUPS) {

    var $ctrl = this;

    angular.extend($ctrl, {
      // binding $attrs for disabled state pass through
      $attrs: $attrs,
      onlyOwn: $attrs.hasOwnProperty('onlyOwn'),
      sources: [],
      selectOrRegisterSource: selectOrRegisterSource,

      // lifecycle hooks
      $onInit: $onInit,
      $onChanges: $onChanges,

      // methods
      isSourceDisabled: isSourceDisabled,
    });

    /**
     * Initialize this component.
     *
     * @method     init
     */
    function $onInit() {
      angular.extend($ctrl, {
        name: [componentName, $attrs.name, Date.now()].join('-'),
        id: [($attrs.id || componentName), 'ui-select'].join('-'),
        isAddNewSupported: _isAddNewSupported(),
      });

      if ($ctrl.ngModel) {
        $ctrl.ngModel.$render = function updateInternalModalOnExternalChange() {
          $ctrl.selectedSource = $ctrl.ngModel.$modelValue;
        };
      }
    }

    /**
     * Set the model with the given source, or if it's the addNew source, pop
     * the Register Source modal. When that's done, select the newly created
     * source.
     *
     * @method   selectOrRegisterSource
     * @param    {object}   source   The selected Source
     */
    function selectOrRegisterSource(source) {
      var environment;

      if (source && source.protectionSource) {
        return _setSelection(source);
      }

      // TODO(jeff): make this smarter so that more than one option is presented
      // for registration if there are more than one environments passed in.
      // For example, SQL registration might need more than one reg type.
      environment = Array.isArray($ctrl.environments) ?
        $ctrl.environments[0] :
        $ctrl.environments;

        // TODO(Tauseef): Create a public version for this method.
      SourceService.registerSourceSlider(
        ENV_TYPE_CONVERSION[environment], $ctrl.allowedEnvTypes).then(
        function modalSuccess(source) {
          var handler;

          // In case of public sources the environment type is present on the
          // source itself.
          // NOTE: The last 'or' condition source.type is added to fix ENG-50529
          //       i.e make 6.1.1 ui compatible with 6.0.1 backend
          var sourceType = source.environment ?
            ENV_TYPE_CONVERSION[source.environment]:
            source.entity.type || source.type;

          // need different handling logic depending on wether the source
          // environment will have an artifical "cohesityGroup" parent wrapper.
          handler = ENV_GROUPS.cohesityGroups.includes(sourceType) ?
            _handleCohesityGroupReg : _handleStandardSourceReg;

          $ctrl.loading = true;

          return handler(source).finally(
            function handlerFinally() {
              $ctrl.loading = false;
            }
          );
        },
        function modalCanceled() {
          // NOTE: do not assign pass function directly as error/cancel handler
          // as its important that parameter get set to undefined.
          _setSelection();
        }
      );
    }

    /**
     * Handles response from registration cSlideModal when the registered source
     * is a standard (non artifically grouped) source. Gets the public version
     * of the source, adds it to the list of sources, and selects it.
     *
     * @method   _handleStandardSourceReg
     * @param    {object}    source           The newly registered
     *                                        private/public API source.
     * @return   {object}    promise to resolve handling of registration
     *                       response
     */
    function _handleStandardSourceReg(source) {
      var params = {
        id: source.id || source.entity.id,
      };

      return _getRootNodesFn()(params).then(
        function getPublicSource(publicApiSources) {
          var publicSource = publicApiSources[0];

          $ctrl.sources.unshift(publicSource);
          $ctrl.sources = _sortSourcesByType($ctrl.sources);
          return _setSelection(publicSource);
        },
        evalAJAX.errorMessage
      );
    }

    /**
     * Handles response from registration cSlideModal when the registered source
     * belongs to a ENV_GROUPS.cohesityGroups. As registered sources for such
     * environments get lumped into a parent wrapper source.
     *
     * @method   _handleCohesityGroupReg
     * @param    {object}   privateSource   The newly registered private API
     *                                      source
     * @return   {object}   promise to resolve handling of registration response
     */
    function _handleCohesityGroupReg(privateSource) {
      // Source is of a type/environment that has an artifical
      // wrapper/grouping. It is necessary to determine if the parent wrapper
      // is already part of $ctrl.sources[], and mark it selected if so. If
      // its not currently included in $ctrl.sources[], then $ctrl.sources[]
      // needs to be updated.
      var foundParentSource = $ctrl.sources.find(
        function findParentSource(loopSource) {
          return loopSource.protectionSource &&
            loopSource.protectionSource.enviroment ===
              ENV_TYPE_CONVERSION[privateSource.entity.type];
        }
      );

      if (foundParentSource) {
        return _setSelection(foundParentSource);
      }

      // Parent wrapper wasn't found. Pull a fresh set from the API, which
      // should include the parent wrapper. Then it can be found and selected.
      // This is likely the first child source to be registered for the
      // environment type.
      return _getData().then(
        function getDataSuccess() {
          var parentSource = $ctrl.sources.find(function loopSources(source) {
            return source.protectionSource &&
              source.protectionSource.environment ===
                ENV_TYPE_CONVERSION[privateSource.entity.type];
          });

          _setSelection(parentSource);
        }
      );
    }

    /**
     * Sets the selection of the external model and internal model.
     *
     * @method   _setSelection
     * @param    {object}   [source]   The source.
     * @return   {object}   The external model view value.
     */
    function _setSelection(source) {
      $ctrl.selectedSource = source;

      return $ctrl.ngModel.$setViewValue(source);
    }

    /**
     * Determines if addNew is supported.
     *
     * @method   _isAddNewSupported
     * @return   {boolean}   True if addNew is supported, False otherwise.
     */
    function _isAddNewSupported() {
      if ($ctrl.hideAddNew) {
        return false;
      }

      // TODO(spencer): For now SQL Jobs are not supported as the registration
      // flow of an SQL isn't handle. This will likely change with new SQL
      // implementation.
      return !$ctrl.environments.includes('kSQL');
    }

    /**
     * Return a fn used to get own root nodes or all basis of $ctrl.onlyOwn.
     *
     * @method   _getRootNodesFn
     * @return   {Function}   The Fn used to get root nodes.
     */
    function _getRootNodesFn() {
      return PubSourceService[$ctrl.onlyOwn ?
        'getOwnRootNodes' : 'getRootNodes'];
    }

    /**
     * Gets the Source entities.
     *
     * @method   _getData
     * @return   {object}   Promise to resolve with the requested data.
     */
    function _getData() {
      var params = { environments: $ctrl.environments };

      $ctrl.loading = true;

      return _getRootNodesFn()(params)
        .then(function thenHandler(sources) {

          $ctrl.sources = _sortSourcesByType(sources);
          // if a defaultId was provided, find it in the list of sources and
          // assign it to the internal model
          if ($ctrl.defaultId) {
            $ctrl.sources.some(function findDefaultId(source) {
              if (source.protectionSource &&
                source.protectionSource.id === $ctrl.defaultId) {
                $ctrl.selectOrRegisterSource(source);

                return true;
              }
            });
          }

          if ($ctrl.defaultEnvironment) {
            $ctrl.sources.find(function findDefaultEnv(source) {
              if (source.protectionSource &&
                source.protectionSource.environment ===
                  $ctrl.defaultEnvironment) {
                $ctrl.selectOrRegisterSource(source);
              }
            });
          }

        }).finally(function getRoodNodesFinally() {
          $ctrl.loading = false;
        });
    }

    /**
     * Sorts sources[] by source type (also decorating with UI friendly string)
     * for grouping purposes.
     *
     * @method   _sortSourcesByType
     * @param    {array}   sources   The sources
     */
    function _sortSourcesByType(sources) {
      sources = sources || [];
      return _.sortBy(sources, function sortByType(source) {
        source._typeStr = $filter('sourceType')(source.protectionSource);
        return source._type;
      });
    }

    /**
     * Handles changes to one-way bound properties.
     *
     * @method   $onChanges
     * @param    {object}   changesObj   The changes object
     */
    function $onChanges(changesObj) {
      if (changesObj.environments) {
        _getData(changesObj);
      }
    }

    /**
     * Function to determine whether a source should be disabled
     *
     * @method isSourceDisabled
     * @param  {Object}  source  The source to be checked
     * @return {Boolean} True if the source should be diabled
     */
    function isSourceDisabled(source) {
      if (!$ctrl.isSourceDisabledFn) {
        return false;
      } else {
        return $ctrl.isSourceDisabledFn({source: source});
      }
    }

  }
})(angular);
