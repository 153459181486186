import { NavBanner, NavItem, UpdateNavActiveStatus } from '@cohesity/helix';
import { AppType, IrisContext, PillarType } from '@cohesity/iris-core';

import { StateManagementService } from '../core/services';

/**
 * This interface is used to provide navigation information for a given serviice.
 */
export interface ServiceNavProvider {
  /**
   * Optional service title to show in the navigation drawer
   */
  readonly serviceNavTitle?: string;

  /**
   * Optional service nav subtitle to be shown below to selected scope icon.
   */
  readonly serviceNavSubtitle?: string;

  /**
   * Optional icon to show in the navigation drawer next to the title
   */
  readonly serviceNavTitleIcon?: string;

  /**
   * Indicates if the nav branding (logo and title) should be displayed.
   */
  readonly showNavBranding?: boolean;

  /**
   * A class for modifying the visuals of the service nav banner/title.
   */
  readonly serviceNavTitleClass?: string;

  /**
   * Which pillar this nav belongs to. It is for banner display.
   */
  readonly pillar?: PillarType;

  /**
   * Which app this nav belongs to. It is for banner display.
   */
  readonly app?: AppType;

  /**
   * Fn used to update the nav active status.
   */
  updateNavActiveStatus?: UpdateNavActiveStatus;

  /**
   * Get the main nav items for this service
   *
   * @param irisContext The current cluster context.
   * @returns An array of nav items.
   */
  getNavItems(irisContext: IrisContext, stateManagementService?: StateManagementService): NavItem[];

  /**
   * If the service has dashboards it should provide such a listing via this method.
   *
   * @param irisContext The current cluster context.
   * @returns An array of nav items.
   */
  getDashboardItems?(irisContext: IrisContext): NavItem[];

  /**
   * An optional method to determine if a given service nav item should be disabled or not.
   */
  isNavItemDisabled?(navItem: NavItem, irisContext: IrisContext): boolean;
}

/**
 * Map ServiceNavProvider to NavBanner data structure used in Helix.
 *
 * @param provider  Map from ServiceNavProvider.
 * @returns Map to NavBanner.
 */
export function getNavBanner (
  provider: ServiceNavProvider,
): NavBanner {
  return {
    title: provider.serviceNavTitle,
    subtitle: provider.serviceNavSubtitle,
    logo: provider.serviceNavTitleIcon,
    showBranding: provider.showNavBranding,
    modifierClass: provider.serviceNavTitleClass,
  };
}
