<h1 mat-dialog-title>{{ 'deleteDirectoryQuota' | translate }}</h1>

<mat-dialog-content>
  <p [innerHtml]="'deleteDirectoryQuotaConfirm'
    | translate : {logicalSize: dialogParams.directoryQuota.quotaPolicy.hardLimitBytes | byteSize}">
  </p>
  <p>{{dialogParams.directoryQuota.directoryPath}}</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button cogCancelButton mat-dialog-close type="button" class="margin-right">
    {{ 'cancel' | translate }}
  </button>
  <button mat-raised-button cogSubmitButton type="submit" color="warn" (click)="deleteQuota()">
    {{ 'delete' | translate }}
  </button>
</mat-dialog-actions>
