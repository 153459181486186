import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { StatlistReportItemInput } from '../../../iris-reporting.model';
import { BaseReportItemRendererComponent } from '../base-report-item-renderer.component';

/**
 * Displays information in the form of settings list
 */
@Component({
  selector: 'iris-rpt-settings-list-item',
  templateUrl: './settings-list-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsListItemComponent extends BaseReportItemRendererComponent implements OnChanges {
  /**
   * Items displayed in settings list
   */
  @Input() items: StatlistReportItemInput[] = [];

  constructor() {
    super();
  }

  ngOnChanges(): void {
    if (this.items && this.data) {
      this.items.forEach((item) => {
        if (item.measurement) {
          item.value = this.data?.[0]?.[item.measurement.valueKey];
        }
      });
    }
  }
}
