<ul class="c-ul-inline no-margin meta-data synopsis" *ngIf="policy">

  <!-- Protection policy type -->
  <li *ngIf="policyType">{{policyType}}</li>


  <!-- Policy name -->
  <li *ngIf="showName">{{ policy.name }}</li>

  <!-- Backup Policy -->
  <ng-container *ngIf="policy.backupPolicy as backupPolicy">
    <!-- Incremental Backup Policy -->
    <li *ngIf="backupPolicy.regular?.incremental?.schedule">{{ incrementalBackUpLabel }}</li>

    <!-- Retention -->
    <li *ngIf="backupPolicy.regular?.retention">{{ incrementalRetentionLabel }}</li>

    <!-- Full Backup Policy -->
    <li *ngIf="backupPolicy.regular?.full">
      <ng-container *ngIf="isProtectOnce(); else full">{{ 'policySynopsis.protectOnce' | translate }}</ng-container>
      <ng-template #full>{{ 'policySynopsis.full' | translate }}</ng-template>
    </li>

     <!-- Full Backups Array -->
     <li *ngIf="backupPolicy.regular?.fullBackups?.length as length">
      <ng-container *ngTemplateOutlet="backupCopyTpl;
        context: { type: 'policySynopsis.full', length: length }">
      </ng-container>
    </li>

    <!-- Log Backup -->
    <li *ngIf="backupPolicy.log">{{ 'logBackup' | translate }}</li>

    <!-- BMR Backup -->
    <li *ngIf="backupPolicy.bmr">{{ 'bmrBackup' | translate }}</li>

    <!-- Continuous Data Protection Policy -->
    <li *ngIf="backupPolicy.cdp">{{ 'cdp' | translate }}</li>

    <!-- Storage Array Snapshot Policy -->
    <li *ngIf="storageArraySnapshotEnabled && backupPolicy.storageArraySnapshot">
      {{ 'storageArraySnapshot' | translate }}
    </li>

    <!-- DataLock -->
    <li *ngIf="backupPolicy.regular?.retention?.dataLockConfig?.mode === 'Compliance'">
      {{ dataLockLabel }}
    </li>
  </ng-container>

  <!-- Remote Target Policy -->
  <ng-container *ngIf="policy.remoteTargetPolicy as remoteTargetPolicy">
    <!-- Replication -->
    <li *ngIf="remoteTargetPolicy.replicationTargets?.length as length">
      <ng-container *ngTemplateOutlet="backupCopyTpl;
        context: { type: 'replicate', length: length }">
      </ng-container>
    </li>

    <!-- Cloud Archive -->
    <li *ngIf="remoteTargetPolicy.archivalTargets?.length as length">
      <ng-container *ngTemplateOutlet="backupCopyTpl;
        context: { type: 'archive', length: length }">
      </ng-container>
    </li>

    <!-- Cloud Vault -->
    <li *ngIf="remoteTargetPolicy.rpaasTargets?.length as length">
      <ng-container *ngTemplateOutlet="backupCopyTpl;
        context: { type: 'cloudVault', length: length }">
      </ng-container>
    </li>

    <!-- Cloudspin -->
    <li *ngIf="remoteTargetPolicy.cloudSpinTargets?.length as length">
      <ng-container *ngTemplateOutlet="backupCopyTpl;
        context: { type: 'cloudDeploy', length: length }">
      </ng-container>
    </li>
  </ng-container>
</ul>

<!-- Remote Target Policy template for Replication, Cloud Archive, and CloudSpin -->
<ng-template #backupCopyTpl let-type="type" let-length="length">
  <ng-container *ngIf="length === 1">{{ type | translate }}</ng-container>
  <ng-container *ngIf="length > 1">{{ type + 'NTimes' | translate: { n: length } }}</ng-container>
</ng-template>
