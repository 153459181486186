import { AppModuleConfig, StateAccessMap, StateAccessContext } from 'src/app/app-module.config';

const activeDirectoryViewAccess = (ctx: StateAccessContext) => ctx.AD_LDAP_VIEW && ctx.FEATURE_FLAGS.ngActiveDirectory;
const activeDirectoryModifyAccess =
  (ctx: StateAccessContext) => ctx.AD_LDAP_MODIFY && ctx.FEATURE_FLAGS.ngActiveDirectory;

export const ActiveDirectoryConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'active-directory-add.**',
      url: '^/access/active-directory/ng-add',
      loadChildren: () => import('./active-directory.module').then(m => m.ActiveDirectoryModule),
    },
    {
      name: 'active-directory-view.**',
      url: '/access/active-directory/view/{id}',
      loadChildren: () => import('./active-directory.module').then(m => m.ActiveDirectoryModule),
    },
  ],

  getStateAccessMap(): StateAccessMap {
    return {
      'active-directory-add': activeDirectoryModifyAccess,
      'active-directory-view': activeDirectoryViewAccess,
    };
  },
};
