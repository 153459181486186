/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AccessTokenResponse } from '../models/access-token-response';
import { CreateAccessTokenRequestParams } from '../models/create-access-token-request-params';
@Injectable({
  providedIn: 'root',
})
class AccessTokenService extends __BaseService {
  static readonly CreateAccessTokenPath = '/access-tokens';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Create a new API access token
   *
   * Before making other REST API requests, your REST client must make a an access token request with a valid Cohesity username and password. This POST request returns an access token and type in the response that is generated by the Cohesity Cluster. Subsequent requests to other Cohesity REST API operations must specify the returned access token and type by setting 'Authorization' in the http header in the following format:
   * Authorization: token_type access_token
   * The generated token is valid for 24 hours. If a request is made with an expired token, the 'Token expired' error message is returned. Add code to your REST client to check for this error and request another access token before reissuing the request.
   * @param params The `AccessTokenService.CreateAccessTokenParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to generate an access token
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateAccessTokenResponse(params: AccessTokenService.CreateAccessTokenParams): __Observable<__StrictHttpResponse<AccessTokenResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/access-tokens`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AccessTokenResponse>;
      })
    );
  }
  /**
   * Create a new API access token
   *
   * Before making other REST API requests, your REST client must make a an access token request with a valid Cohesity username and password. This POST request returns an access token and type in the response that is generated by the Cohesity Cluster. Subsequent requests to other Cohesity REST API operations must specify the returned access token and type by setting 'Authorization' in the http header in the following format:
   * Authorization: token_type access_token
   * The generated token is valid for 24 hours. If a request is made with an expired token, the 'Token expired' error message is returned. Add code to your REST client to check for this error and request another access token before reissuing the request.
   * @param params The `AccessTokenService.CreateAccessTokenParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to generate an access token
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateAccessToken(params: AccessTokenService.CreateAccessTokenParams): __Observable<AccessTokenResponse> {
    return this.CreateAccessTokenResponse(params).pipe(
      __map(_r => _r.body as AccessTokenResponse)
    );
  }
}

module AccessTokenService {

  /**
   * Parameters for CreateAccessToken
   */
  export interface CreateAccessTokenParams {

    /**
     * Specifies the parameters to generate an access token
     */
    body: CreateAccessTokenRequestParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { AccessTokenService }
