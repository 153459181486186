// Component: sql dashboard status glancebar

;(function(angular, undefined) {

  var componentConfig = {
    controller: 'SqlStatusController',
    templateUrl: 'app/global/c-widgets/sql/status/status.html',
    bindings: {
      data: '<'
    },
  };

  angular.module('C.widgets')
    .controller('SqlStatusController', sqlStatusCtrlFn)
    .component('wSqlStatus', componentConfig);

  /**
   * @ngdoc component
   * @name C.widgets:wSqlStatus
   *
   * @param {Object} data status info for sql dashboard:
   *                      {
   *                        hostCount: number;
   *                        instanceCount: number;
   *                        dbCount: number;
   *                        protectedDbCount: number;
   *                        protectedLogicalSize: number;
   *                        jobCount: number;
   *                        aagCount: number;
   *                        protectedAagCount: number;
   *                        recoveryCount: number;
   *                        cloneCount: number;
   *                      }
   *
   * @description
   * Status Glancebar for SQL dasbhoard. If any of the expected
   * properties are not shown, they will be automatically hidden.
   *
   * @example
   * <w-sql-status data="$ctrl.sqlStatus"></w-sql-status>
   */
  function sqlStatusCtrlFn($filter, $translate) {
    var $ctrl = this;

    _.assign($ctrl, {
      $onChanges: $onChanges
    });

    /**
     * Update the stat list config on changes
     *
     * @param {SimpleChanges} changes
     */
    function $onChanges(changes) {
      if (changes.data) {
        $ctrl.status = !$ctrl.data ? [] : [
          {
            label: $ctrl.data.hostCount === 1 ? 'host' : 'hosts',
            value: $ctrl.data.hostCount,
          },
          {
            label: $ctrl.data.instanceCount === 1 ? 'instance' :'instances',
            value: $ctrl.data.instanceCount,
          },
          {
            label: 'protectedDatabases',
            valueKey: !isNaN($ctrl.data.protectedDbCount) &&
              !isNaN($ctrl.data.dbCount) ? 'nOfTotalTemplate' : undefined,
            valueContext: {
              n: $ctrl.data.protectedDbCount,
              total: $ctrl.data.dbCount,
            },
          },
          {
            label: 'protectedSize',
            value: $filter('byteSize')($ctrl.data.protectedLogicalSize, true),
          },
          {
            label: $ctrl.data.jobCount === 1 ? 'sqlJob' : 'sqlJobs',
            value: $ctrl.data.jobCount,
          },
          {
            label: 'protectedAags',
            valueKey: !isNaN($ctrl.data.protectedAagCount) &&
                !isNaN($ctrl.data.aagCount) ? 'nOfTotalTemplate' : undefined,
            valueContext: {
              n: $ctrl.data.protectedAagCount,
              total: $ctrl.data.aagCount,
            }
          },
          {
            label: 'recoveriesLast24Hrs',
            value: $ctrl.data.recoveryCount,
          },
          {
            label: 'clonesLast24Hrs',
            value: $ctrl.data.cloneCount,
          },
        ];
      }
    }
  }

})(angular);
