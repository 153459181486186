<ng-container [matColumnDef]="colName" #tenant>
  <th mat-header-cell *matHeaderCellDef mat-sort-header [attr.disabled]="!sort" cogDataId="organization-col">
    {{ 'organization' | translate }}
  </th>
  <td mat-cell *matCellDef="let row">

    <!-- If there is no tenant array supplied or a tenant object. -->
    <span *ngIf="internalResolve ? (!row.tenantId && !row.tenantIds?.length) :
      (!row.tenant?.length && !row.tenant?.name)">
      {{ 'naNotApplicable' | translate }}
    </span>

    <!-- Render tenant as a single entry if they come as an object. -->
    <a class="coh-tenant-column-link"
      *ngIf="internalResolve ? row.tenantId : row.tenant?.name"
      [irisRouterLink]="getOrgSref()"
      [params]="getOrgUiParams(internalResolve ? row.tenantId : row.tenant?.tenantId)">
      {{ (internalResolve ? tenantsMap[row.tenantId] : row.tenant)?.name }}
    </a>

    <!-- Render multiple tenant in the same line separated by comma. -->
    <ng-container *ngIf="internalResolve ? row.tenantIds?.length : row.tenant?.length">
      <a class="coh-tenant-column-link"
        [irisRouterLink]="getOrgSref()"
        [params]="getOrgUiParams(internalResolve ? row.tenantIds[0] : row.tenant[0].tenantId)">
        {{ (internalResolve ? tenantsMap[row.tenantIds[0]] : row.tenant[0])?.name }}
      </a>
      <a class="coh-tenant-column-link"
        *ngIf="(internalResolve ? row.tenantIds : row.tenant)?.length > 1"
        [matMenuTriggerFor]="orgMenu"
        (click)="stopPropagation($event)">
        , +{{ (internalResolve ? row.tenantIds : row.tenant)?.length - 1 }}
      </a>
      <mat-menu #orgMenu="matMenu">
        <ng-container *ngIf="internalResolve">
          <span mat-menu-item *ngFor="let tenantId of row.tenantIds | slice:1">
            <a [irisRouterLink]="getOrgSref()" [params]="getOrgUiParams(tenantId)">
              {{ tenantsMap[tenantId]?.name }}
            </a>
          </span>
        </ng-container>

        <ng-container *ngIf="!internalResolve">
          <span mat-menu-item *ngFor="let tenant of row.tenant | slice:1">
            <a [irisRouterLink]="getOrgSref()" [params]="getOrgUiParams(tenant.tenantId)">
              {{ tenant.name }}
            </a>
          </span>
        </ng-container>
      </mat-menu>
    </ng-container>
  </td>
</ng-container>
