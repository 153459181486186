import { GcpProtectionSource, ProtectionSourceNode } from '@cohesity/api/v1';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { Environment } from 'src/app/shared/constants';

import { ProtectionSourceDataNode } from '../shared/protection-source-data-node';
import { HasMetadata, SourceNodeMetadata } from '../shared/protection-source-metadata/source-node-metadata';

/**
 * Extract tags from a gcp ProtectionSourceNode. This is outside the class since it is
 * called from the constructor before the class has been completely initialized.
 *
 * @param   data   A protection source node
 * @returns Any applied tags ids or an empty list.
 */
function findTags(data: ProtectionSourceNode): number[] {
  return (data.protectionSource.gcpProtectionSource.tagAttributes || []).map(tag => tag.id);
}

/**
 * Represents an active GCP source node and job tree selection behavior.
 */
export class GcpSourceDataNode extends ProtectionSourceDataNode<GcpProtectionSource>
  implements HasMetadata {

  readonly metadata: SourceNodeMetadata;

  constructor(
    data: ProtectionSourceNode,
    readonly level: number,
    private irisCtx: IrisContextService,
  ) {
    super(Environment.kGCP, data, level, findTags(data));

    if (this.isTag) {
      this.metadata = {
        leafCount: this.children && this.children.length || 0
      };
    } else {
      this.metadata = {
        logicalSize: this.logicalSize,
        leafCount: this.type !== 'kVirtualMachine' ? this.leafCount : undefined,
      };
    }
  }

  /**
   * Can this node be auto-protected?
   *
   * @return   True if this node can be autoSelected/auto-protected.
   */
  canAutoSelect(): boolean {
    return flagEnabled(this.irisCtx.irisContext, 'gcpAutoprotectEnabled') && (this.expandable || this.isTag);
  }

  /**
   * Whether the node can be expanded.
   */
  get expandable(): boolean {
    return this.data.protectionSource.gcpProtectionSource.type === 'kProject'
      || super.expandable;
  }

  /**
   * Whether the node is a leaf which can be directly selected or not.
   */
  get isLeaf() {
    const leafNodes = ['kVirtualMachine'];

    return leafNodes.includes(this.type);
  }

  /**
   * Whether the current node is a tag or not. This is determined by the node type.
   */
  get isTag(): boolean {
    return ['kTag', 'kLabel', 'kMetadata'].includes(this.type);
  }
}
