import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { AutoDestroyable } from '@cohesity/utils';
import { ShowHideEditControlContextService } from '../../services/show-hide-edit-control-context.service';

@Component({
  selector: 'coh-show-hide-edit-control-wrapper',
  templateUrl: './show-hide-edit-control-wrapper.component.html',
  styleUrls: ['./show-hide-edit-control-wrapper.component.scss'],
})
export class ShowHideEditControlWrapperComponent extends AutoDestroyable {
  /**
   * Icon shape to display for the view/edit mode.
   */
  @Input() iconShape: string;

  /**
   * Title of the item.
   */
  @Input() title: string;

  /**
   * Whether the item is in an invalid state.
   */
  @Input() invalid = false;

  /**
   * Whether the item is in success state.
   */
  @Input() success = false;

  /**
   * View mode, if enabled it will not expand the control.
   */
  @Input() viewOnlyMode = false;

  /**
   * Emit the new value of editControlVisible.
   */
  @Output() editControlVisibleChange = new EventEmitter<boolean>(false);

  /**
   * Behavior Subject to keep the value of whether the item is in edit state or not.
   */
  @HostBinding('class.control-active') private _editControlVisible = false;

  /**
   * Getter for editControlVisible value.
   */
  get editControlVisible(): boolean {
    return this._editControlVisible;
  }

  /**
   * Setter for editControlVisible value.
   */
  set editControlVisible(value: boolean) {
    if (this.viewOnlyMode) {
      return;
    }

    if (value) {
      // At any time, only one item should be in edit state, so notify the
      // remaining items when this component goes into edit state.
      this.showHideEditControlContextService.notifyItemOpened(this);
    }

    this.editControlVisibleChange.emit(value);
    this._editControlVisible = value;
  }

  /**
   * Variable to store whether to show errors or not.
   */
  private _showErrors = false;

  /**
   * Get value for show errors.
   */
  get showErrors(): boolean {
    return this._showErrors && this.invalid;
  }

  constructor(private showHideEditControlContextService: ShowHideEditControlContextService) {
    super();

    this.showHideEditControlContextService.itemOpened$.pipe(
      this.untilDestroy()
    ).subscribe(value => {
      // If any other item is opened, close the current one.
      if (value !== this) {
        this.editControlVisible = false;
      }
    });

    this.showHideEditControlContextService.itemShowErrors$.pipe(
      this.untilDestroy()
    ).subscribe(value => {
      // If the form has been tried to be submitted, show errors.
      this._showErrors = value;
    });
  }
}
