import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig, MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { SnackBarComponent } from './snack-bar.component';

/**
 * Type for snack bar statuses.
 */
export type SnackBarStatus = 'success' | 'info' | 'warn' | 'error';

/**
 * Snack Bar data interface.
 */
export interface SnackBarData {

  // Specifies the snack bar status.
  status: SnackBarStatus;

  // Specifies the message text.
  messageText: string;

  // Specifies the optional action button text.
  actionText?: string;

  // Specifies whether user can dismiss the snackbar.
  // This should be set to true by default unless opting out.
  allowDismiss?: boolean;
}

/**
 * Default Snackbar Config used when opening a snack bar.
 */
const defaultSnackbarConfig: MatSnackBarConfig = {

  // The horizontal position to place the snack bar.
  horizontalPosition: 'left',

  // The vertical position to place the snack bar.
  verticalPosition: 'bottom',

  // CSS class to be added to the snack bar container.
  panelClass: ['cog-snack-bar-container'],
};

/**
 * @description
 * Snackbar service that wraps MatSnackBar service to dispatch Material snack bar messages.
 *
 * @example
 *   // Open a simple snack bar
 *   this.snackbarService.open(messageText);
 *
 *   // Open an actionable snack bar
 *   this.snackbarService.openWithAction(messageText, actionText).subscribe(() => {
 *     // Performs action on button clicked.
 *   });
 */
@Injectable({
  providedIn: 'root',
})
export class SnackBarService {

  /**
   * Default duration for the snackbar message. This is not part of
   * defaultSnackbarConfig since this is only applied to non error snackbar messages.
   */
  public static defaultSnackbarDuration = 6000;

  /**
   * if is persisting snackbar
   */
  public persistSnackbar = false;

  /**
   * Document reference.
   */
  private document?: Document;

  constructor(
    private snackBar: MatSnackBar,
    @Inject(DOCUMENT) document: any,
  ) {
    // Typing document as any and then assigning as Document type to avoid Angular complaining about being unable to
    // resolve type Document. See:
    // https://github.com/angular/angular/issues/20351#issuecomment-446025223
    this.document = document as Document;
  }

  /**
   * Opens a simple snack bar. If action is provided, the snackbar doesn't
   * return the subscription for it.
   *
   * @param    messageText  The message to display.
   * @param    status       The snack bar status. Default to success.
   * @param    config       Custom snack bar config if any.
   * @param    allowDismiss Whether the snack bar can be dismissed. Default to true.
   * @param    actionText   The label for the snack bar action.
   * @return   Reference to a snack bar dispatched from the snack bar service.
   */
  open(
    messageText: string,
    status = 'success',
    allowDismiss = true,
    config = defaultSnackbarConfig,
    actionText = null,
  ): MatSnackBarRef<any> {
    return this.openSnackBar(messageText, actionText || undefined, status, allowDismiss, {
      ...defaultSnackbarConfig,
      ...config
    });
  }

  /**
   * Opens a snack bar with a message and an action.
   *
   * This function doesn't return the snackbar ref, if that is needed, use
   * open() instead.
   *
   * @param    messageText  The message to display.
   * @param    actionText   The label for the snack bar action.
   * @param    status       The snack bar status.
   * @param    allowDismiss Whether the snack bar can be dismissed. Default to true.
   * @param    config       Custom snack bar config if any.
   * @return   Observable that is notified when the snack bar action is called.
   */
  openWithAction(
    messageText: string,
    actionText: string,
    status = 'success',
    allowDismiss = true,
    config = defaultSnackbarConfig
  ): Observable<void> {
    return this.openSnackBar(messageText, actionText, status, allowDismiss, {
      ...defaultSnackbarConfig,
      ...config,
    }).onAction();
  }

  /**
   * Dismisses the currently visible snack bar.
   */
  dismiss() {
    return this.snackBar.dismiss();
  }

  /**
   * Used internally to open a material snack bar with supplied config and data.
   *
   * @param    messageText  The message to display.
   * @param    actionText   The label for the snack bar action.
   * @param    status       The snack bar status.
   * @param    allowDismiss Whether the snack bar can be dismissed. Default to true.
   * @param    config       Custom snack bar config if any.
   * @return   Reference to a snack bar dispatched from the snack bar service.
   */
  private openSnackBar(
    messageText: string,
    actionText?: string,
    status = 'success',
    allowDismiss = true,
    config = defaultSnackbarConfig
  ): MatSnackBarRef<any> {
    const snackbarConfig = {
      ...config,
      data: { messageText, actionText, status, allowDismiss },
    };

    if (status !== 'error' && !Object.prototype.hasOwnProperty.call(snackbarConfig, 'duration')) {
      // Wait for a default duration before automatically dismissing the snack bar.
      snackbarConfig.duration = SnackBarService.defaultSnackbarDuration;
    }

    if (this.persistSnackbar && Object.prototype.hasOwnProperty.call(snackbarConfig, 'duration')) {
      delete snackbarConfig.duration;
    }

    const snackBarRef = this.snackBar.openFromComponent(SnackBarComponent, snackbarConfig);

    // Manually update the position of the snack-bar.
    // Ideally, we should be able to pass the container to specify where
    // the snack bar should belong to. Until we have an official support
    // for this functionality, will need to manually calculate the overlay position.
    // More info: https://github.com/angular/components/issues/7764
    snackBarRef.afterOpened().pipe(first()).subscribe(() => this.setPosition());
    return snackBarRef;
  }

  /**
   * Sets the position of the snackBarContainer to the bottom left corner of
   * the last dialog container if opening over a dialog.
   * Otherwise, show the snack bar at a standard position.
   */
  private setPosition() {
    if (!this.document) {
      return;
    }

    const dialogContainers = this.document.querySelectorAll('.mat-dialog-container');
    const snackBar = this.document.querySelector('.cog-snack-bar-container');
    const overlay = snackBar.closest('.cdk-overlay-pane');

    // There is a dialog container, adjust the position before showing the snack bar.
    if (dialogContainers?.length && overlay) {
      const lastDialog = [].slice.call(dialogContainers).pop();

      // Get the position of the dialog
      const diaLogRect = lastDialog.getBoundingClientRect();
      const { clientHeight } = this.document.documentElement;
      const offset = 16;

      // Set the position for the snack bar overlay
      overlay.setAttribute('style', `
        margin-left: ${diaLogRect.left + offset}px;
        margin-bottom: ${clientHeight - diaLogRect.bottom - offset}px;
      `);

      // Show the snack bar
      snackBar.setAttribute('style', `
        margin-left: 0px;
        visibility: visible;
      `);
    } else {
      // Show the snack bar
      snackBar.setAttribute('style', 'visibility: visible;');
    }
  }
}
