import { ClusterId, ClusterIncarnationId, ObjectId } from '@cohesity/api/argus';
import { GaiaIndexStorageInfo, Object as SourceObject, ObjectProtectionInfo, SearchObject } from '@cohesity/api/v2';

export enum SupportedOsTypes {
  'windows' = 'kWindows',
  'linux' = 'kLinux'
}

/**
 * Search object type per each protection
 */
export interface SearchObjectPerSource {
  id: ConcatenatedObjectId;
  /**
   * Specifies the object info on each cluster.
   */
  objectProtectionInfo?: ProtectionInfo;

  /**
   * Specifies the Source Object information.
   */
  sourceInfo?: SourceObject;
  object: SearchObject;
}

export type ConcatenatedObjectId = `${ClusterId}:${ClusterIncarnationId}:${ObjectId}`;

export interface ProtectionInfo extends ObjectProtectionInfo {
  /** Specifies the name of the object. */
  name?: null | string;

  cloudProvider?: null | GaiaIndexStorageInfo['providerType'];
}

/**
 * Enum for valid Snapshot Selection Types in settings section of Scan Form
 */
export enum SnapshotSelectionType {
  mostRecent = 'most-recent',
  snapshotCount = 'snapshot-count',
  dateRange = 'date-range',
}
