<div class="selected-title">
  {{'selectedNItems' | translate : { items: selectedObjects?.length} }}
</div>

<div  *ngIf="!hideSnapshot" class="snapshot-row">
  <div class="mat-body snapshot-line">
    <cog-icon class="display-icon"
      shape="history"></cog-icon>
    <div>{{formatRecoveryPointTime(restorePointSelection.timestampUsecs)}}</div>
  </div>
  <div class="target-type snapshot-line mat-caption">
    <cog-icon class="display-icon"
      [shape]="typeIcon | backupTargetIcon : restorePointSelection.archiveTargetInfo"
      size="sm"></cog-icon>
      {{'fromTarget' |
      translate: {target: restorePointSelection.archiveTargetInfo?.targetName ||
        ((!cloudEditionEnabled ? 'local' : 'cloud') | translate)} }}
  </div>
  <button mat-icon-button
    class="edit-restore-point-button"
    type="button"
    cogDataId="edit-restore-point-{{restorePointSelection.objectInfo.name}}"
    *ngIf="showEdit"
    (click)=editRestorePoint.emit()>
    <cog-icon shape="edit!outline"></cog-icon>
  </button>
</div>

<mat-list>
  <mat-list-item *ngFor="let object of selectedObjects">
    <ng-container
      *ngTemplateOutlet="detailTemplate; context: {$implicit: object}">
    </ng-container>

    <button mat-icon-button
      class="remove-item-button"
      type="button"
      (click)="deselectObject.emit(object)"
      cogDataId="remove-object-{{object.name}}">
      <cog-icon shape="close"></cog-icon>
    </button>
  </mat-list-item>
</mat-list>
