import { ArchivalTargetSummaryInfo, IndexedObjectSnapshot, ObjectSnapshot } from '@cohesity/api/v2';
import { decorateInterface } from '@cohesity/utils';

/**
 * Decorated Indexed Object Snapshot to make it more consistent with the ObjectSnapshot type
 */
export class DecoratedIndexedObjectSnapshot extends decorateInterface<IndexedObjectSnapshot>() {
  /**
   * Indexed object snapshots are assumed to have had indexing completed.
   */
  readonly indexingStatus = 'Done';

  /**
   * The snapshot target type.
   */
  get snapshotTargetType(): ObjectSnapshot['snapshotTargetType'] {
    return this.externalTargetInfo ? 'Archival' : 'Local';
  }

  /**
   * The snapshot ownership context.
   */
  get ownershipContext(): ArchivalTargetSummaryInfo['ownershipContext'] {
    return this.externalTargetInfo?.ownershipContext || 'Local';
  }

  /**
   * The snapshot id.
   */
  get id(): string {
    return this.objectSnapshotid;
  }

  constructor(snapshot: IndexedObjectSnapshot) {
    super(snapshot);
  }
}
