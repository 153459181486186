import { flagEnabled } from './iris-context-util';
import { IrisContext } from './iris-context.model';

/**
 * Returns true if the current user is a Sizer configured user.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if user is Sizer user.
 */
export function isSizerUser(irisContext: IrisContext): boolean {
  return flagEnabled(irisContext, 'liveSizerEnabled');
  // || Boolean(irisContext?.user?.salesforceAccount?.isSizerUser);
}

/**
 * Returns true if the current user is in Sizer scope.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if the user in Sizer context.
 */
export function isSizerScope(irisContext: IrisContext): boolean {
  return Boolean(isSizerUser(irisContext) && irisContext?.selectedScope?._serviceType === 'piaas');
}
