/* eslint-disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CohesityApiConfiguration, CohesityApiConfigurationInterface } from './cohesity-api-configuration';

import { AccessTokensService } from './services/access-tokens.service';
import { ActiveDirectoryService } from './services/active-directory.service';
import { AlertsService } from './services/alerts.service';
import { AnalyticsService } from './services/analytics.service';
import { AntivirusServiceGroupService } from './services/antivirus-service-group.service';
import { AppInstanceService } from './services/app-instance.service';
import { AppService } from './services/app.service';
import { AuditService } from './services/audit.service';
import { BannerService } from './services/banner.service';
import { ClusterService } from './services/cluster.service';
import { CertificatesService } from './services/certificates.service';
import { ClustersService } from './services/clusters.service';
import { ClusterPartitionsService } from './services/cluster-partitions.service';
import { DashboardService } from './services/dashboard.service';
import { ExportService } from './services/export.service';
import { NodesService } from './services/nodes.service';
import { GroupsService } from './services/groups.service';
import { IdpsService } from './services/idps.service';
import { ImportService } from './services/import.service';
import { InterfaceService } from './services/interface.service';
import { InterfaceGroupService } from './services/interface-group.service';
import { IpService } from './services/ip.service';
import { KmsConfigurationResponseService } from './services/kms-configuration-response.service';
import { KmsUpdateRequestParametersService } from './services/kms-update-request-parameters.service';
import { KmsConfigurationService } from './services/kms-configuration.service';
import { LdapProviderService } from './services/ldap-provider.service';
import { HeliosClustersService } from './services/helios-clusters.service';
import { HeliosPrivilegesService } from './services/helios-privileges.service';
import { HeliosPushNotificationsService } from './services/helios-push-notifications.service';
import { HeliosRolesService } from './services/helios-roles.service';
import { HeliosGlobalSearchService } from './services/helios-global-search.service';
import { HeliosStatsService } from './services/helios-stats.service';
import { HeliosSupportService } from './services/helios-support.service';
import { HeliosUsersService } from './services/helios-users.service';
import { UsersService } from './services/users.service';
import { MonitoringService } from './services/monitoring.service';
import { NetworkService } from './services/network.service';
import { ViewsService } from './services/views.service';
import { PackagesService } from './services/packages.service';
import { ProtectionSourcesService } from './services/protection-sources.service';
import { CustomReportingService } from './services/custom-reporting.service';
import { PrincipalsService } from './services/principals.service';
import { PrivilegesService } from './services/privileges.service';
import { ProtectionJobsService } from './services/protection-jobs.service';
import { ProtectionObjectsService } from './services/protection-objects.service';
import { ProtectionPoliciesService } from './services/protection-policies.service';
import { ProtectionRunsService } from './services/protection-runs.service';
import { RemoteClusterService } from './services/remote-cluster.service';
import { RemoteRestoreService } from './services/remote-restore.service';
import { ReportsService } from './services/reports.service';
import { RestoreTasksService } from './services/restore-tasks.service';
import { CloneRefreshTasksService } from './services/clone-refresh-tasks.service';
import { RolesService } from './services/roles.service';
import { RoutesService } from './services/routes.service';
import { SchedulerService } from './services/scheduler.service';
import { SearchService } from './services/search.service';
import { NotificationsService } from './services/notifications.service';
import { PreferencesService } from './services/preferences.service';
import { SMBFileOpensService } from './services/smbfile-opens.service';
import { StatisticsService } from './services/statistics.service';
import { StatsService } from './services/stats.service';
import { TenantService } from './services/tenant.service';
import { VaultsService } from './services/vaults.service';
import { ViewBoxesService } from './services/view-boxes.service';
import { VlanService } from './services/vlan.service';
import { BifrostConnectorService } from './services/bifrost-connector.service';

/**
 * Provider for all CohesityApi services, plus CohesityApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    CohesityApiConfiguration,
    AccessTokensService,
    ActiveDirectoryService,
    AlertsService,
    AnalyticsService,
    AntivirusServiceGroupService,
    AppInstanceService,
    AppService,
    AuditService,
    BannerService,
    ClusterService,
    CertificatesService,
    ClustersService,
    ClusterPartitionsService,
    DashboardService,
    ExportService,
    NodesService,
    GroupsService,
    IdpsService,
    ImportService,
    InterfaceService,
    InterfaceGroupService,
    IpService,
    KmsConfigurationResponseService,
    KmsUpdateRequestParametersService,
    KmsConfigurationService,
    LdapProviderService,
    HeliosClustersService,
    HeliosPrivilegesService,
    HeliosPushNotificationsService,
    HeliosRolesService,
    HeliosGlobalSearchService,
    HeliosStatsService,
    HeliosSupportService,
    HeliosUsersService,
    UsersService,
    MonitoringService,
    NetworkService,
    ViewsService,
    PackagesService,
    ProtectionSourcesService,
    CustomReportingService,
    PrincipalsService,
    PrivilegesService,
    ProtectionJobsService,
    ProtectionObjectsService,
    ProtectionPoliciesService,
    ProtectionRunsService,
    RemoteClusterService,
    RemoteRestoreService,
    ReportsService,
    RestoreTasksService,
    CloneRefreshTasksService,
    RolesService,
    RoutesService,
    SchedulerService,
    SearchService,
    NotificationsService,
    PreferencesService,
    SMBFileOpensService,
    StatisticsService,
    StatsService,
    TenantService,
    VaultsService,
    ViewBoxesService,
    VlanService,
    BifrostConnectorService
  ],
})
export class CohesityApiModule {
  static forRoot(customParams: CohesityApiConfigurationInterface): ModuleWithProviders<CohesityApiModule> {
    return {
      ngModule: CohesityApiModule,
      providers: [
        {
          provide: CohesityApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
