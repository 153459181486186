<ng-container [formGroup]="formGroup">
  <mat-card cogFormGroup>
    <ng-container *ngIf="!readOnly">
      <h2>{{'selectVolumes' | translate}}</h2>

      <cog-spinner *ngIf="loading"></cog-spinner>

      <ng-container *ngIf="!loading">
        <div *ngIf="!volumes?.length"
          class="c-message-inline icon-message info"
          [innerHTML]="'unableToFetchVolumes' | translate: {
            name: restorePoint?.objectInfo.name,
            time: (restorePoint?.timestampUsecs | cohDate)
          }">
        </div>

        <div class="row margin-bottom-sm">
          <div class="col-xs-2" translate>protectionRecoveryPhysicalServer.labelSelectAllVolumes</div>
          <div class="col-xs-3">
            <mat-slide-toggle [formControl]="formGroupControls.selectAllVolumes"
              [inheritDataId]="true"
              cogDataId="recover-select-volume-all">
            </mat-slide-toggle>
          </div>
        </div>

        <div class="row" *ngIf="!formGroupControls.selectAllVolumes.value && volumes?.length">
          <div class="col-xs-5">
            <mat-form-field>
              <mat-label translate>selectOneOrMoreOption</mat-label>
              <mat-select [formControl]="formGroupControls.volumes"
                [inheritDataId]="true"
                cogDataId="recover-select-volume-individual"
                multiple>
                <mat-select-trigger *ngIf="formGroupControls.volumes.value as selectedVolumes">
                  {{ selectedVolumes | simpleList: { propKey: 'name' } }}
                </mat-select-trigger>
                <mat-option *ngFor="let volume of volumes"
                  [value]="volume"
                  [inheritDataId]="true"
                  cogDataId="option-{{volume.name}}"
                  class="volume-option">
                  <strong>{{ volume.name }}</strong>
                  <ul class="c-ul-inline margin-top-xs meta-data">
                    <li *ngIf="volume.filesystemType">
                      {{ 'fileSystemTypeColon' | translate }} {{ volume.filesystemType }}
                    </li>
                    <li *ngIf="volume.volumeSizeInBytes">
                      {{ 'volumeSizeColon' | translate }} {{ volume.volumeSizeInBytes | byteSize }}
                    </li>
                  </ul>
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-error *ngIf="formGroupControls.volumes.touched && formGroupControls.volumes.errors?.required">
              {{'errors.required' | translate}}
            </mat-error>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <cog-settings-list *ngIf="readOnly"
      class="recover-to-settings-list"
      [alwaysOpen]="true"
      [readOnly]="true"
      [formGroup]="formGroup">
      <cog-settings-list-item
        [label]="'protectionRecoveryPhysicalServer.labelSelectAllVolumes' | translate"
        cogFormGroup>
        <cog-settings-list-item-value-display>
          {{ formGroupControls.selectAllVolumes.value | boolLabel: 'on' : 'off' }}
        </cog-settings-list-item-value-display>
      </cog-settings-list-item>
      <cog-settings-list-item
        *ngIf="!formGroupControls.selectAllVolumes.value"
        [label]="'selectedVolumes' | translate"
        cogFormGroup>
        <cog-settings-list-item-value-display>
          {{ getSelectedVolumes() }}
        </cog-settings-list-item-value-display>
      </cog-settings-list-item>
    </cog-settings-list>
  </mat-card>
</ng-container>
