// Controller: Objects Protected By Multiple Jobs Report

;(function(angular, undefined) {
  'use strict';

  angular.module('C.reports')
    .controller('reportsVmsInMultipleJobsController',
      ReportsVmsInMultipleJobsControllerFn);

  function ReportsVmsInMultipleJobsControllerFn(
    $rootScope, $scope, $q, ReportsService, evalAJAX,
    cUtils, SourceService, JobService, ViewBoxCacheService, ViewBoxService,
    PolicyService, cReportsControlsUtil, ReportsUtil) {

    $scope.text = $rootScope.text.reportsJobsVmsVms;

    $scope.dataReady = false;
    $scope.getData = getData;
    $scope.viewBoxHash = {};
    $scope.policyHash = {};
    $scope.jobs = [];
    $scope.vms = [];
    $scope.downloadMultipleJobsReport = downloadMultipleJobsReport;

    $scope.multipleJobsControlsConfig =
      ReportsUtil.getReportConfig('kObjectsProtectedByMultipleGroupsReport')();

    /**
     * Configuration Array for custom sorting cSelect list
     * @type {Array}
     */
    $scope.sortOptions = [{
      value: '_vmName',
      name: $scope.text.storage.vms.sortByName,
    }, {
      value: '_sourceName',
      name: $scope.text.storage.vms.sortByParentSource,
    }, {
      value: '_jobCount',
      name: $scope.text.storage.vms.sortByJobCount,
    }];


    /**
     * Activate this controller.
     *
     * @method    activate
     */
    function activate() {
      var defaultFilters;
      var promiseObject = {};

      promiseObject = {
        registeredSources: ReportsUtil.getSources(),
      };

      $q.all(promiseObject).then(function(data) {
        $scope.dataset = data;
      }, evalAJAX.errorMessage).finally(function() {
        $scope.reportsControlsDataReady = true;
      });

      defaultFilters = cReportsControlsUtil.getDefaultFilters({
        registeredSourceIds: [],
      });

      // run setup function on first load
      getData(defaultFilters);
    }


    /**
     * Custom sort method for c-table-nested
     * @param  {string} prop the property on which to be sorted
     */
    $scope.sortVms = function sortVms(prop) {
      $scope.vms.sort(cUtils.sortObjectsByProp(prop));

      if (prop === '_jobCount') {
        $scope.vms.reverse();
      }
    };

    /**
     * Maps VMs to Jobs,
     * returns new object with relevant job info for a given jobId
     * to be combined with VM info
     * @param  {id}     jobId
     * @return {obj}    object {
     *                       jobNames : Job Names associated with this VM (array),
     *                       parentSource: name of Parent Source (string),
     *                       repeats: frequency of this job run (string),
     *                       end: end time for this job (string)
     *                  }
     */
    function parseObjectForProps(id) {
      var len = $scope.jobs.length;
      var jobs = $scope.jobs;
      var job;

      // Match jobIds of VM object to jobIds in $scope.jobs
      for (var i = 0; i < len; i++) {
        job = jobs[i].backupJob;
        if (job.jobId === id) {
          return {
            _jobName: job.name,
            _jobId: job.jobId,
            _sourceName: SourceService.getEntityName(job.parentSource),
            _parentSourceObject: job.parentSource,
            _repeats: JobService.getRepeatString(
              ($scope.policyHash[job.policyId] || {}).incrementalSchedulingPolicy, job),
            _ends: JobService.getEndString(job),
            _vbId: job.viewBoxId,
          };
        }
      }
      return {};
    }

    /**
     * returns params object for api call
     *
     * @param      {object}    filters    filters from c-reports-controls
     *
     * @return    {object}               filter values to be passed to API
     */
    function getParams(filters) {
      return ReportsService.extendWithTenantParams({
        sourceIds: filters.registeredSourceIds || [],
      });
    }

    /**
     * Fetch the report data.
     *
     * @param      {object}    filters    filters from c-reports-controls
     */
    function getData(filters) {
      $scope.dataReady = false;

      const promises = {
        reportData: ReportsService.getObjectsToJobs(getParams(filters)),
        policies: PolicyService.getPolicies(),
        viewBoxes: ViewBoxService.getViewBoxes(),
      };

      // TODO: cleanup this logic and template. these are no
      // longer strictly VMs and this naming is now confusing.
      $q.all(promises).then(
        function allPromisesSuccess(resp) {
          $scope.vms.length = 0;

          if (!resp.reportData) { return; }

          // Build the necessary hashmaps for data display.
          (resp.policies || []).forEach(policy => $scope.policyHash[policy.id] = policy);
          (resp.viewBoxes || []).forEach(viewBox => $scope.viewBoxHash[viewBox.id] = viewBox);

          $scope.jobs = resp.reportData.jobDescriptionList || [];
          if (resp.reportData.objectsToJobs && resp.reportData.objectsToJobs.length) {
            $scope.vms = resp.reportData.objectsToJobs.map(
              function object2jobMapper(vm) {
                var VMJobs = vm.jobIds.map(parseObjectForProps);
                return angular.extend(vm.object, {
                  _vmName: SourceService.getEntityName(vm.object),
                  _vbName: VMJobs[0]._vbName,
                  _jobs: VMJobs,
                  _jobCount: VMJobs.length,
                  _sourceName: VMJobs[0]._sourceName,
                  _parentSourceObject: VMJobs[0]._parentSourceObject,
                });
              }
            );
          }
        },
        evalAJAX.errorMessage
      ).finally(
        function getObjectsToJobsFinally() {
          $scope.dataReady = true;
        }
      );
    }

    /**
     * Initiates CSV download
     */
    function downloadMultipleJobsReport() {
      var params = getParams(cReportsControlsUtil.filters);
      params.outputFormat = 'csv';
      ReportsService.getMultipleJobs(params);
    }

    activate();
  }

})(angular);
