import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared';

import { ImpersonationIndicatorComponent } from './impersonation-indicator/impersonation-indicator.component';

const COMPONENTS = [
  ImpersonationIndicatorComponent
];

@NgModule({
  declarations: COMPONENTS,
  exports: COMPONENTS,
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class TenantImpersonationModule { }
