// Component: cStorageVolumeNasRecoveryOptions

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.storageVolumeNasRecoveryOptions', [
      'C.pubRestoreService',
      'C.NasVolume',
    ])
    .component('cStorageVolumeNasRecoveryOptions', {
      bindings: {
        /**
         * Page Config
         *
         * @param  pageConfig    routeParams and any other params required for
         *                       component init
         *                       {
         *                         entityId: '',
         *                         jobId: '',
         *                         jobInstanceId: '',
         *                         parentId: '',
         *                       }
         * @type   {object}
         */
        pageConfig: '<',

        /**
         *  addToCart callback function defined in parent view
         *
         * @method   addToCart
         * @type     {function}
         */
        addToCart: '=',

        /**
         *  getModeTextKey callback function defined in parent view
         *
         * @method   getModeTextKey
         * @type     {function}
         * @return   {string}
         */
        getModeTextKey: '=',

        /**
         *  startFlowOver callback function defined in the parent view
         *
         * @method   startFlowOver
         * @type     {function}
         */
        startFlowOver: '=',
      },
      templateUrl:
        'app/protection/recovery/storage-volume/nas.options.html',
      controller: cStorageVolumeNasOptionsCtrlFn,
    });

  function cStorageVolumeNasOptionsCtrlFn(
    _, $state, cUtils, evalAJAX, ENUM_ENV_TYPE, TARGET_LOCATION, ENV_GROUPS,
    SearchServiceFormatter, PubRestoreService, PubRestoreServiceFormatter,
    RestoreService, SlideModalService, SourceService, RecoveryStore,
    SearchService, cMessage, SNAPSHOT_TARGET_TYPE) {
    var $ctrl = this;

    // declare component methods
    _.assign($ctrl, {
      selectRestorePoint: selectRestorePoint,
      showCRestore: false,
      submitTask: submitTask,
      selectVolume: selectVolume,

      // Component lifecycle methods
      $onInit: $onInit,
    });

    /**
     * Initilaize data
     *
     * @method _initializeCtrl
     */
    function _initializeCtrl() {
      var _shared = RecoveryStore.get();

      _.assign($ctrl, {
        nasEnvGroups: _.clone(ENV_GROUPS.nas),
        shared: _shared,
        targetLocation: 'cohesityView',
      });
    }

    /**
     * Component init function
     *
     * @method $onInit
     */
    function $onInit() {
      // Initialize data
      _initializeCtrl();

      // if it is directArchive job, filter local snapshots out
      _filterDirectArchiveSnapshots();

      // Recover directly from the a job backup instance
      if ($ctrl.pageConfig.entityId || $ctrl.pageConfig.jonInstanceId) {
        _setupAbbreviatedFlow();

      // Edge Case: When the recover flow is initialized manually
      //            without required params.
      } else if (!$ctrl.shared.cart.length) {
        $ctrl.startFlowOver();
      } else {
        _setupDefaults();
      }
    }

    /**
     * If the storage volume job has directArchive enabled, filter out
     * local snapshots from cart data
     *
     * @method  _filterDirectArchiveSnapshots
     */
    function _filterDirectArchiveSnapshots() {
      if (_.get($ctrl, 'shared.cart[0]')) {
        RestoreService.filterDirectArchiveSnapshots($ctrl.shared.cart[0]._jobId)
          .then(function promiseResolved(promiseResolver) {
            var promiseObj = promiseResolver();
            var filterDirectArchive = promiseObj.filterDirectArchive;
            var isDirectArchiveEnabled = promiseObj.isDirectArchiveEnabled;

            _.each($ctrl.shared.cart, function eachCartItem(item) {
              item.vmDocument.versions =
                filterDirectArchive(item.vmDocument.versions);

              var firstSnapshot = item.vmDocument.versions[0];

              if (isDirectArchiveEnabled && firstSnapshot) {
                var archiveTarget = firstSnapshot.replicaInfo &&
                  firstSnapshot.replicaInfo.replicaVec[0];

                // Select the first snapshot by default
                _.assign($ctrl.shared.cart[0], {
                  _snapshot: firstSnapshot,

                  // Used in private api
                  _archiveTarget: archiveTarget,
                  _pubSnapshot: SearchServiceFormatter
                    .getPublicSnapshot(firstSnapshot),

                  // Used in public api
                  _archivalTarget: SearchServiceFormatter
                    .getArchiveTargetPub(archiveTarget),
                });
              }
            });

            $ctrl.showCRestore = true;
          });
      }
    }

    /**
     * Function to make some scope changes for Direct object selection.
     *
     * @method   _setupAbbreviatedFlow
     */
    function _setupAbbreviatedFlow() {
      var foundMatch = false;
      $ctrl.isLoading = true;

      // Fetch by entityId & jobId
      SearchService.nasSearch({
        entityIds: $ctrl.pageConfig.entityId,
        jobIds: [$ctrl.pageConfig.jobId],
        registeredSourceIds: $ctrl.pageConfig.parentId,
      })
      .then(function getNasSuccess(resp) {
        if (resp && resp.length) {
          // Parse the response for the matching entity and add it to the cart
          foundMatch = resp.some(
            function findMatchingEntity(entity, ii) {
              var vmDoc = entity.vmDocument;
              // Using == in here because stateParams are strings.
              // First, check if entity & job ids are the same.
              if (($ctrl.pageConfig.entityId == vmDoc.objectId.entity.id &&
                $ctrl.pageConfig.jobId == vmDoc.objectId.jobId) ||

                // otherwise check if jobId is the same (in the absence of an
                // entityId)
                (!$ctrl.pageConfig.entityId &&
                  $ctrl.pageConfig.jobId == vmDoc.objectId.jobId)) {

                // Preselect snapshot?
                if ($ctrl.pageConfig.jobInstanceId) {
                  entity._snapshot = vmDoc.versions.find(
                    function findSnapshot(run) {
                      return $ctrl.pageConfig.jobInstanceId ==
                        run.instanceId.jobInstanceId;
                    }
                  );
                  entity._pubSnapshot =
                    SearchServiceFormatter.getPublicSnapshot(entity._snapshot);
                }

                // Add it to the cart now
                $ctrl.addToCart(entity, false);

                // if it is directArchive job, filter local snapshots out
                _filterDirectArchiveSnapshots();

                // More setup after the cart has been updated.
                _setupDefaults();

                return true;
              }
            }
          );
        }

        // No matching entity was found, show an error
        if (!foundMatch) {
          cMessage.error({
            titleKey: 'noVolumeFound',
            textKey: 'recover.nas.options.noEntityFound'
          });
        }

      }, evalAJAX.errorMessage)
      .finally(function getNasFinally() {
        $ctrl.isLoading = false;
      });
    }

    /**
     * Set the default task name, view names and Qos policy etc
     *
     * @method   _setupDefaults
     */
    function _setupDefaults() {
      var jobTypeInfix = $ctrl.shared.cart[0] &&
        ENUM_ENV_TYPE[$ctrl.shared.cart[0]._type];

      // specific for 'cohesityView' target location
      $ctrl.shared.viewName = $ctrl.shared.defaultViewName =
        RestoreService.getDefaultTaskName('Mount', jobTypeInfix);

      $ctrl.shared.name = $ctrl.shared.defaultTaskName =
        RestoreService.getDefaultTaskName($ctrl.flowType, jobTypeInfix);

      // set the default qos
      $ctrl.shared.qos =
        _.get($ctrl.shared, 'filterLookups.qosPolicies[0].name');
    }

    /**
     * Open the snapshot selector to let the user choose a different restore
     * point.
     *
     * @method   selectRestorePoint
     * @param    {object}  row     The search result entity in question
     */
    function selectRestorePoint(row) {
      var modalOpts = {
        templateUrl: 'app/protection/recovery/common/snapshot-selector/common.snapshot-selector.html',
        controller: 'commonRestoreSnapshotModalController',
        size: 'lg',
        resolve: {
          task: angular.copy($ctrl.shared.task),
          entity: row,
        }
      };

      SlideModalService
        .newModal(modalOpts)
        .then(function snapshotSelectedFn(resp) {
          _.assign(row, {
            _snapshot: resp.snapshot,

            // Used in private api
            _archiveTarget: resp.archiveTarget,
            _pubSnapshot: resp.pubSnapshot,

            // Used in public api
            _archivalTarget: resp.archivalTarget,
          });
        });
    }

    /**
     * select a volume for alternate nas location
     *
     * @method selectVolume
     */
    function selectVolume() {
      //NAS
      var _envTypes = cUtils.onlyNumbers($ctrl.nasEnvGroups);
      var _filters = {
        requireVmTools: false,
        singleSelect: true,
        alwaysShowSource: true,
        canSelectNode: function disableNodes(node) {
          return _.get(node._envProtectionSource, 'volumeInfo.type') !== 'kDataProtection' &&
            _.intersection(
              $ctrl.shared.cart[0]._dataProtocols
                .map(cUtils.getDataProtocolFromKValue),
              node._dataProtocols.map(cUtils.getDataProtocolFromKValue)
            ).length;
        },
      };

      SourceService.browseForLeafEntities(_envTypes, undefined, _filters).then(
        function selectSourceSuccess(entity) {
          $ctrl.shared.target.alternate = entity;
        }
      );
    }

    /**
     * Sets Vlan Parameters
     *
     * @method    _setRestoreType
     */
    function _setVlanParams() {
      // set the default vlan parameter object
      $ctrl.shared.task.vlanParameters = RestoreService.getVlanParams(
        $ctrl.shared.selectedVlanTarget,
        true
      );
    }

    /**
     * set target location specific params.
     *
     * @method   _setTargetLocationSpecificParams
     */
    function _setTargetLocationSpecificParams() {
      switch ($ctrl.targetLocation) {
        case TARGET_LOCATION.cohesityView:
          // fill in the task
          _.assign($ctrl.shared.task, {
            // objects
            objects: PubRestoreServiceFormatter
              .generateRestoreTaskObjects($ctrl.shared.cart),

            // restore type
            type: 'kMountFileVolume',

            // mount view name
            viewName: $ctrl.shared.viewName,

            // qos
            restoreViewParameters: {
              qos: {
                principalName: $ctrl.shared.qos,
              },
            },
          });
          break;

        case TARGET_LOCATION.original:
          _.assign($ctrl.shared.task, {
            // object info
            sourceObjectInfo: PubRestoreServiceFormatter
              .generateRestoreTaskObjects($ctrl.shared.cart)[0],

            // target volume id
            targetSourceId: $ctrl.shared.cart[0]._protectionSourceId,

            // target volume parent source id
            targetParentSourceId: $ctrl.shared.cart[0]._parentId,

            // flag to differntiate between files vs volume restore
            isFileBasedVolumeRestore: true,

            // TODO(sanath): check again with magneto why this is needed.
            filenames: ['/'],

            // other options
            overwrite: $ctrl.shared.overwrite,
            preserveAttributes: $ctrl.shared.preserveAttributes,
            continueOnError: $ctrl.shared.continueOnError,
          });
          break;

        case TARGET_LOCATION.alternate:
          _.assign($ctrl.shared.task, {
            // object info
            sourceObjectInfo: PubRestoreServiceFormatter
              .generateRestoreTaskObjects($ctrl.shared.cart)[0],

            // target volume id
            targetSourceId: $ctrl.shared.target.alternate.id,

            // target volume parent source id
            targetParentSourceId: $ctrl.shared.target.alternate.parentId,

            // flag to differntiate between files vs volume restore
            isFileBasedVolumeRestore: true,

            // TODO(sanath): check again with magneto why this is needed.
            filenames: ['/'],
            newBaseDirectory: '/',

            // other options
            overwrite: $ctrl.shared.overwrite,
            preserveAttributes: $ctrl.shared.preserveAttributes,
            continueOnError: $ctrl.shared.continueOnError,
          });
          break;
      }
    }

    /**
     * set recover post request params
     *
     * @method   setRecoverParams
     */
    function _setRequestParams() {
      $ctrl.shared.task.name = $ctrl.shared.name;
      _setTargetLocationSpecificParams();
      _setVlanParams();
    }

    /**
     * Submit the task as configured.
     *
     * @method   submitTask
     * @param    {object}   form   The form object
     */
    function submitTask(form) {
      var restoreServiceFuncName = 'restore';
      if (!form || form.$invalid) { return false; }

      _setRequestParams();

      $ctrl.isSubmitting = true;

      // we make a files recovery call for restore to original / restore to
      // alternate location
      if ($ctrl.targetLocation !== TARGET_LOCATION.cohesityView) {
        restoreServiceFuncName = 'restoreFiles';
      }

      PubRestoreService[restoreServiceFuncName]($ctrl.shared.task).then(
        function taskAcceptedSuccess(restoreResponse) {
          var targetType =
            restoreResponse.objects[0].archivalTarget ? 'archive' : 'local';
          var stateName =
            RestoreService.getRestoreTaskDetailStateName('recover', targetType);
          var stateParams = { id: restoreResponse.id, };

          // Clear the shared store to keep things sane.
          RecoveryStore.clear();

          // Move to the next state.
          $state.go(stateName, stateParams);
        },
        evalAJAX.errorMessage
      )
      .finally(
        function taskAcceptedFinally() {
          $ctrl.isSubmitting = false;
        }
      );
    }
  }
})(angular);
