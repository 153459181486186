import { ClusterConfigList } from '@cohesity/api/argus';
import { ValueFilterSelection } from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';

/**
 * Generate filter values for location filter based on given cluster list
 *
 * @param clusterList list of clusters
 * @param translateService translate service to generate translations for unknown/empty value
 * @returns filter values
 */
export function setLocationOptions(clusterList: ClusterConfigList,
  translateService: TranslateService): ValueFilterSelection[] {
  const options = Array.from(new Set(clusterList.map(c => c.location ?? 'unknown')));
  return options.map(location => ({
    label: location === 'unknown' ? translateService.instant('unknown') : location,
    value: location
  }));
}

/**
 * Generate filter values for software version filter based on given cluster list
 *
 * @param clusterList list of clusters
 * @param translateService translate service to generate translations for unknown/empty value
 * @returns filter values
 */
export function setSoftwareVersionOptions(clusterList: ClusterConfigList,
  translateService: TranslateService): ValueFilterSelection[] {
  const options = Array.from(new Set(clusterList.map(c => c.currentVersion ?? 'unknown')));
  return options.map(version => ({
    label: version === 'unknown' ? translateService.instant('unknown') : version,
    value: version
  }));
}
