import {
  AfterViewInit,
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Inject,
  InjectionToken,
  Injector,
  Input,
  NgZone,
  OnDestroy,
  Output,
  Type,
  ViewEncapsulation,
} from '@angular/core';
import { ObservableInput } from 'ngx-observable-input';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import {
  BezierEdgeStyle,
  ComponentArrangementStyles,
  ComponentLayout,
  CurveConnectionStyle,
  EdgeRouter,
  EdgeRouterEdgeRoutingStyle,
  ExteriorLabelModel,
  ExteriorLabelModelPosition,
  GraphComponent,
  GraphViewerInputMode,
  HierarchicLayout,
  IGraph,
  ILabel,
  ILayoutAlgorithm,
  INode,
  LayoutOrientation,
  PenaltySettings,
  Point,
  Rect,
  Size,
  YDimension,
} from 'yfiles';

import { GraphComponentService } from '../../shared/graph-component.service';
import { GraphLayoutService } from '../../shared/graph-layout.service';
import { NodeComponentShape, NodeComponentStyle } from '../../styles/node-component-style';
import StyledArrowRenderer from '../../styles/styled-arrow-renderer';
import { StyledBezierEdgeRenderer } from '../../styles/styled-bezier-edge-renderer';

/**
 * This is a minimum set of properties that a graph node should have in order to render.
 */
export interface TopologyGraphNode {
  /**
   * A unique id for the node, this is used for lookups, and it must match the from or to properties on the edge
   */
  id: string;

  /**
   * An optional label for a node
   */
  label?: string;

  /**
   * Optional value to set the placement for a node label. If this is not set, the graph will default to 'South'
   */
  labelPlacement?: 'North' | 'South';
}

/**
 * This is a minimum set of properties that a graph edge should have in order to render
 */
export interface TopologyGraphEdge {
  /**
   * The id of the source node
   */
  from: string;

  /**
   * The id of the target node
   */
  to: string;

  /**
   * Optional style to set on the edge svg group. The 'edge' style will automatically be set.
   */
  style?: string;
}

/**
 * This is an injection token that can be used to get the currently selected node. It can be used by node
 * components to determine if they are selected or not and to change their rendering mechanisms.
 */
export const TOPOLOGY_SELECTION = new InjectionToken<Observable<INode>>('topology-graph-node-selection');

/**
 * Topology graph sets up a fairly simple graph that works well for simple, directed graphs.
 * It uses an organic layout, and should produce results similar to a force directed graph layout.
 *
 * See the topology stories in the helix doc app for more information.
 */
@Component({
  selector: 'hyf-topology-graph',
  templateUrl: './topology-graph.component.html',
  styleUrls: ['./topology-graph.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    // Provides the graph component service so that this, and any children can access the main graph object
    GraphComponentService,

    // Provides a layout component, so that this and any node children can triggger relayout if needed
    GraphLayoutService,

    // Provides n injection token that can be used by renderer components to determine their selection status
    {
      provide: TOPOLOGY_SELECTION,
      useValue: new BehaviorSubject<INode>(null),
    },
  ],
})
export class TopologyGraphComponent implements AfterViewInit, OnDestroy {
  /**
   * The canvas clicked listener for the graph input. This is saved as a property so that it can be removed
   * when the component is cleaned up.
   */
  private canvasClickedListener: () => void;

  /**
   * The current INode selection
   */
  private currentSelection: INode;

  /**
   * A subscription that tracks changes to nodes and edges and updates the graph
   */
  private dataSub: Subscription;

  /**
   * A reference to the actual graph. The graph contains the actual nodes, edges, and labels used in the graph
   */
  private graph: IGraph;

  /**
   * A reference to the main graph component. The graphComponent contains the div rendering the graph, and includes
   * layout, input, selection, and graph information.
   */
  private graphComponent!: GraphComponent;

  /**
   * When an item is selected, the topology graph temporarily removes its label and adds it back when the item is
   * deselectedd. This tracks the removed label so that it can be added back.
   */
  private hiddenLabel: ILabel = null;

  /**
   * The selection change listener for the graph. This is saved as a property so that it can be removed
   * when the component is cleaned up.
   */
  private selectionChangeListener: () => void;

  /**
   * The nodes used for the graph
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('nodes') nodes$: Observable<TopologyGraphNode[]>;

  /**
   * The edges used for the graph
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('edges') edges$: Observable<TopologyGraphEdge[]>;

  /**
   * A default component used to render a node. This should use an svg-based template file
   */
  @Input() defaultNodeComponent: Type<any>;

  /**
   * A default height to apply to all nodes
   */
  @Input() defaultNodeHeight = 80;

  /**
   * The default shape of a node - this can be an ellipse or rect. This sets the boundary of the node so that edges will
   * be drawn up to the visible part of the node.
   */
  @Input() defaultNodeShape: NodeComponentShape = 'ellipse';

  /**
   * A default width to apply to all nodes
   */
  @Input() defaultNodeWidth = 80;

  /**
   * A default height to apply to a node when it is selected
   */
  @Input() selectedNodeHeight = 300;

  /**
   * A default width to apply to a node when it is selected
   */
  @Input() selectedNodeWidth = 300;

  /**
   * This event gets emitted whenever a node is selected in the graph.
   */
  @Output() nodeSelected = new EventEmitter<TopologyGraphNode>();

  constructor(
    private injector: Injector,
    private resolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private zone: NgZone,
    private graphComponentService: GraphComponentService,
    private graphLayoutService: GraphLayoutService,
    @Inject(TOPOLOGY_SELECTION) private nodeSelection$: BehaviorSubject<INode>
  ) {}

  ngAfterViewInit() {
    // Init the graph component and graph
    this.graphComponent = this.graphComponentService.getGraphComponent();
    this.graph = this.graphComponent.graph;

    // specify node and edge styles for newly created items
    this.setDefaultStyles();

    // Setup the selection handling
    this.selectionChangeListener = () => this.handleNodeSelection();
    this.graphComponent.addCurrentItemChangedListener(this.selectionChangeListener);

    // Set the graph to deselect an item if you click on the canvas
    this.canvasClickedListener = () => this.handleCanvasClicked();
    const inputMode = new GraphViewerInputMode();
    inputMode.addCanvasClickedListener(this.canvasClickedListener);
    this.graphComponent.inputMode = inputMode;

    // Subscribe to edge and node changes and create and render the graph
    this.dataSub = combineLatest([this.nodes$, this.edges$])
      .pipe(
        // These are often cleared and reset at the same time, add a debounce
        // so that we don't reset the graph too often
        debounceTime(1),

        filter(([nodes, edges]) => !!nodes && !!edges)
      )
      .subscribe(async ([nodes, edges]) => {
        this.createGraph(nodes, edges);

        // setup and run the layout
        this.graphLayoutService.layout = this.initLayout();
        await this.graphLayoutService.runLayout();
        await this.graphComponent.fitGraphBounds();
      });
  }

  /**
   * Handles a node selection
   *
   * 1. When a node is selected, update it's layout to a larger size, and hide its label
   * 2. When a node is deselected, resize it back to it's original size, and add its label back
   * 3. After the selection is updated, run the graph layout and either fit the graph to the current view or
   *    zoom in on the selected node
   * 4. Update the nodeSelection$ observable with the INode (used by node renderers)
   * 5. Emit the nodeSelected event with the node's tag (used by parent components)
   *
   * Note that this code is using async and await. yFiles relies heavily on promises, so it seems cleaner to stick
   * with promises for anything internal to the library and expose any external apis via observables.
   */
  async handleNodeSelection() {
    // Deselect a node if needed
    if (this.currentSelection) {
      this.updateNodeSizeInPlace(this.currentSelection, this.defaultNodeWidth, this.defaultNodeHeight);
      if (this.hiddenLabel) {
        this.graph.addLabel(this.currentSelection, this.hiddenLabel.text);
      }
    }

    // Update the selection
    this.currentSelection = this.graphComponent.currentItem as INode;
    this.nodeSelected.emit(this.currentSelection?.tag);
    this.nodeSelection$.next(this.currentSelection);

    // Select the node, or handle when there is no selection
    if (this.currentSelection) {
      this.hiddenLabel = this.currentSelection.labels?.first();
      if (this.hiddenLabel) {
        this.graph.remove(this.hiddenLabel);
      }
      this.updateNodeSizeInPlace(this.currentSelection, this.selectedNodeWidth, this.selectedNodeHeight);
      await this.graphLayoutService.runLayout(0);
      await this.zoomToNode(this.currentSelection);
    } else {
      await this.graphLayoutService.runLayout(0);
      await this.graphComponent.fitContent();
    }
  }

  /**
   * Handle the canvas clicked action and deselect the current item.
   */
  handleCanvasClicked() {
    this.graphComponent.currentItem = null;
  }

  ngOnDestroy() {
    if (!this.dataSub?.closed) {
      this.dataSub.unsubscribe();
      this.dataSub = null;
    }

    if (this.selectionChangeListener) {
      this.graphComponent.removeCurrentItemChangedListener(this.selectionChangeListener);
      this.selectionChangeListener = null;
    }

    if (this.canvasClickedListener) {
      (this.graphComponent.inputMode as GraphViewerInputMode).removeCanvasClickedListener(this.canvasClickedListener);
      this.canvasClickedListener = null;
    }
  }

  /**
   * This updates a node's layout to a new position and size, attempting to maintain the node's center.
   *
   * @param node   The node to update
   * @param newWidth The new width to set
   * @param newHeight The new height to set
   */
  updateNodeSizeInPlace(node: INode, newWidth: number, newHeight: number) {
    // Determine whether the new x and y values will be less than or greater than the current ones based on whether the
    // new height and width is larger or smaller than the current one.
    const inverseX = node.layout.width < newWidth ? -1 : 1;
    const inverseY = node.layout.height < newHeight ? -1 : 1;
    this.graph.setNodeLayout(
      node,
      new Rect(
        node.layout.x - (newWidth / 2) * inverseX,
        node.layout.y - (newHeight / 2) * inverseY,
        newWidth,
        newHeight
      )
    );
  }

  /**
   * Zooms in on a node, and sets the zoom to 1
   *
   * @param node The node to zoom in on
   */
  async zoomToNode(node: INode) {
    const rect = node.layout;
    const center = new Point(rect.x + rect.width / 2, rect.y + rect.height / 2);
    await this.graphComponent.zoomToAnimated(center, 1);
  }

  /**
   * Add nodes and edges to the graph
   *
   * @param nodes The nodes to add
   * @param edges The edges to add
   */
  createGraph(nodes: TopologyGraphNode[], edges: TopologyGraphEdge[]) {
    const nodeMap: { [name: string]: INode } = {};

    // Clear the graph before we start anything
    this.graph.clear();

    nodes.forEach(nodeData => {
      const node = this.graph.createNode({
        tag: nodeData,
      });
      if (nodeData.label) {
        // Currently support only North and South placements, default to South if nothing is set.
        const placement = nodeData.labelPlacement === 'North' ? ExteriorLabelModel.NORTH : ExteriorLabelModel.SOUTH;
        this.graph.addLabel(node, nodeData.label, placement);
      }
      nodeMap[nodeData.id] = node;
    });

    edges.forEach(edgeData => {
      const fromNode = nodeMap[edgeData.from];
      const toNode = nodeMap[edgeData.to];
      if (fromNode && toNode) {
        this.graph.createEdge({
          source: fromNode,
          target: toNode,
          tag: edgeData,
        });
      }
    });
  }

  /**
   * Initializes the graph layout. This combines an OrganicLayout and an EdgeRouter. The Organiclayout handles the
   * nodes and the EdgeRouter handles the edges
   *
   * @returns The new layout
   */
  initLayout(): ILayoutAlgorithm {
    const hierarchicLayhout = new HierarchicLayout();
    hierarchicLayhout.layoutOrientation = LayoutOrientation.TOP_TO_BOTTOM;
    hierarchicLayhout.considerNodeLabels = true;
    hierarchicLayhout.nodeToNodeDistance = 60;

    const componentLayout = new ComponentLayout(hierarchicLayhout);
    componentLayout.style = ComponentArrangementStyles.PACKED_RECTANGLE;

    const { offsetHeight, offsetWidth } = this.graphComponent.div;
    componentLayout.preferredSize = new YDimension(offsetWidth, offsetHeight);

    const edgeLayout = new EdgeRouter(componentLayout);
    edgeLayout.considerNodeLabels = true;
    edgeLayout.rerouting = true;
    edgeLayout.integratedEdgeLabeling = true;
    edgeLayout.defaultEdgeLayoutDescriptor.penaltySettings = PenaltySettings.OPTIMIZATION_EDGE_BENDS;
    edgeLayout.defaultEdgeLayoutDescriptor.targetCurveConnectionStyle = CurveConnectionStyle.KEEP_PORT;
    edgeLayout.defaultEdgeLayoutDescriptor.sourceCurveConnectionStyle = CurveConnectionStyle.KEEP_PORT;
    edgeLayout.defaultEdgeLayoutDescriptor.routingStyle = EdgeRouterEdgeRoutingStyle.CURVED;
    edgeLayout.defaultEdgeLayoutDescriptor.curveUTurnSymmetry = 0.5;

    return edgeLayout;
  }

  /**
   * Sets the default styles on the graph. Thiss is used for all new nodes, edges, labels, aand arrows. This could
   * be updated on a per node basis in the future if needed. To do it, we would need to update the createGraph function
   * to provide specific styles based on the node and edge inputs.
   *
   */
  private setDefaultStyles() {
    this.graph.nodeDefaults.size = new Size(this.defaultNodeWidth, this.defaultNodeHeight);

    if (this.defaultNodeComponent) {
      this.graph.nodeDefaults.style = new NodeComponentStyle(
        this.injector,
        this.defaultNodeComponent,
        this.resolver,
        this.appRef,
        this.zone,
        this.defaultNodeShape
      );
    }

    const labelModel = new ExteriorLabelModel({ insets: 5 });
    this.graph.nodeDefaults.labels.layoutParameter = labelModel.createParameter(ExteriorLabelModelPosition.SOUTH);

    const bezierEdgeStyle = new BezierEdgeStyle(new StyledBezierEdgeRenderer('style'));
    bezierEdgeStyle.targetArrow = new StyledArrowRenderer();
    this.graph.edgeDefaults.style = bezierEdgeStyle;
  }
}
