// Service: MCM(aka Helios) sources API services.

;(function(angular, undefined) {
  'use strict';

  angular.module('C.mcmSourceService', ['C.mcmSourceServiceFormatter'])
    .service('McmSourceService', McmSourceServiceFn);

  function McmSourceServiceFn(API, $http, McmSourceServiceFormatter) {

    return {
      getRootNodes: getRootNodes,
      resolveRootNodesDetails: resolveRootNodesDetails,
    };

    /**
     * Get the list of registered root nodes.
     *
     * @method   getRootNodes
     * @param    {object}   [params={}]   The parameters {
     *    clusterUids:  {Array[String]}   filter by provided mcm cluster uuids.
     *                                    <clusterId:clusterIncarnationId>
     * }
     * @return   {object}   Promise resolved with root nodes else rejected
     *                      with error.
     */
    function getRootNodes(params) {
      params = params || {};
      return $http({
        method: 'get',
        url: API.mcm('protectionSources/registeredSources'),
        params: params,
      }).then(
        function getRootNodesSuccess(resp) {
          return (resp.data || []).map(
            McmSourceServiceFormatter.decorateSource);
        }
      );
    }

    /**
     * Resolve the uuid in the provided list of items with its details.
     *
     * @method   resolveRootNodesDetails
     * @param    {Object[]}  list   The list of items having uuid that need
     * to resolved with node details.
     * @param    {String}    [pathToUuid='uuid']   The path to reach uuid
     * inside a item in the list and default considered as 'uuid'
     * @return   {Object}    Promise resolved with list having detailed sources
     * info if present else rejected with error.
     */
    function resolveRootNodesDetails(list, pathToUuid) {
      pathToUuid = pathToUuid || 'uuid';

      // construct path to reach root node info or list.
      var parts = pathToUuid.split('.');

      // removing last part which is the uuid to get the target location
      // where we will be placing the resolved node info.
      parts.pop();
      var pathToInfo = parts.concat('_rootNode').join('.');
      var pathToInfoList = parts.concat('_rootNodes').join('.');

      return getRootNodes().then(
        function gotRootNodes(rootNodes) {
          var rootNodesMap = _.keyBy(rootNodes, 'uuid');
          /**
           * Return root node info for provided identifier from received
           * rootNodesMap.
           *
           * @method  _getInfo
           * @param   {String}   identifier   The identifier.
           * @return  {Object}   Return root node info for the provided
           *                     identifier.
           */
          function _getInfo(identifier) {
            return rootNodesMap[identifier];
          }

          // loop over list and add rootNodeInfo.
          return list.map(function eachItem(item) {
            var identifiersToResolve = _.get(item, pathToUuid);

            // resolving specially for single or multiple case.
            if (_.isArray(identifiersToResolve)) {
              _.set(item, pathToInfoList, identifiersToResolve.map(_getInfo));
            } else {
              _.set(item, pathToInfo, _getInfo(identifiersToResolve));
            }

            return item;
          });
      });
    }

  }

}(angular));
