// Module: Cluster replication encryption key

;(function(angular, undefined) {
  'use strict';

  var configOptions = {
    bindings: {
      cluster: '=',
    },
    controller: 'ReplicationEncryptionKeyCtrl',
    templateUrl: 'app/remote-clusters/modify/replication-encryption-key.html',
  };

  angular.module('C.remoteClusters')
    .controller('ReplicationEncryptionKeyCtrl', replicationEncryptionKeyCtrlFn)
    .component('replicationEncryptionKey', configOptions);

  function replicationEncryptionKeyCtrlFn(_, RemoteClusterService) {
    var $ctrl = this;

    _.assign($ctrl, {
      // UI states
      generatingKey: false,

      // component methods used in the view
      generateKey: generateKey,
    });

    /**
     * generates a new encryption Key.
     *
     * @method     generateKey
     */
    function generateKey() {
      $ctrl.generatingKey = true;

      return RemoteClusterService.generateEncryptionKey().then(
        function keyGenerationSuccess(key) {
          $ctrl.cluster.encryptionKey = key;
        }
      ).finally(
        function afterKeyGeneration() {
          $ctrl.generatingKey = false;
        }
      );
    }
  }

})(angular);
