// Component: Clone View: SNapshot Selector Controller

;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.cloneView';

  angular
    .module(moduleName)
    .controller('viewSnapshotModalController', viewSnapshotModalControllerFn);

  function viewSnapshotModalControllerFn($rootScope, $scope, $state, $filter,
    ExternalTargetService, evalAJAX, $q, RestoreService, SearchService, task,
    entity) {

    var allowedTargetTypes = [0, 1, 3, 4];
    var allowedTargetTypeIcons = {
      1: 'local',
      3: 'cloud',
      4: 'tape',
    };

    angular.extend($scope, {
      // General Scope Vars
      entity: entity,
      targetFilter: 0,
      externalTargetsMap: {},

      // Text Strings
      text: $rootScope.text.devopsCloneViewsViewSnapshotSelectorModalText,

      // Scope Methods
      saveSnapshotSelection: saveSnapshotSelection,
      setArchiveTarget: setArchiveTarget,
      filterTargetType: filterTargetType,
      updateTargetFilter: updateTargetFilter,
      getTargetFilterOptions: getTargetFilterOptions,
      getArchiveTargetTooltip: ExternalTargetService.getTargetName,
      setSnapshot: setSnapshot,
    });

    /**
     * Activate all the things!
     *
     * @method   activate
     */
    function activate() {
      var snapshot;
      var archiveTarget;

      // Get usable (restorable) versions
      entity.vmDocument.versions =
        RestoreService.getRestorableVersions(entity.vmDocument.versions);

      // If this isn't a deleted view, insert `undefined` at the head of the
      // list to allow selection of the current view (not from a snapshot)
      if (!entity._isDeleted && !entity._isInactive) {
        entity.vmDocument.versions.unshift(undefined);
      }

      // Set the snapshot and archivalTarget from the entity coming in if we've
      // previously selected either of them.
      snapshot = findEqualSnapshot(entity._snapshot);
      archiveTarget = findEqualArchivalTarget(snapshot, entity._archiveTarget);
      setSnapshot(snapshot, archiveTarget);

      $scope.targetFilterOptions = getTargetFilterOptions();
      updateTargetFilter();

      if ($rootScope.user.privs.CLUSTER_EXTERNAL_TARGET_VIEW) {
        getExternalTargetsList();
      }
    }

    /**
     * Gets the archive target tooltip string.
     *
     * @method   getArchiveTargetTooltip
     * @param    {object}   archiveTarget   The archive target
     * @return   {string}   The archive target tooltip.
     */
    function getArchiveTargetTooltip(archiveTarget) {
      var tooltip;

      switch (true) {
        // Show the vault name for tape & cloud targets
        case (~[3, 4].indexOf(archiveTarget.target.type)):
          tooltip = $scope.externalTargetsMap[archiveTarget.target.id] &&
            $scope.externalTargetsMap[archiveTarget.target.id].name;
          break;

        // Just show the vault type for anything else
        default:
          tooltip = $scope.text.tooltips[archiveTarget.target.type];
      }

      // In case the specific vault wasn't identified, fall back on the vault
      // type
      return tooltip || $scope.text.tooltips[archiveTarget.target.type];
    }

    /**
     * Get the list of external targets and create a map of them by id.
     *
     * @method   getExternalTargetsList
     */
    function getExternalTargetsList() {
      ExternalTargetService.getTargets()
        .then(function getTargetsSuccessFn(targets) {
          $scope.externalTargetsMap = targets.reduce(
            function targetsMapperFn(_targets, target) {
              _targets[target.id] = target;

              return _targets;
            },

            {}
          );
        });
    }

    /**
     * Find an equal snapshot in the list since the passed in versions are
     * copies and not references to the same objects.
     *
     * @method   findEqualSnapshot
     * @param    {object}   snapshot   Out-modal Entity snapshot
     * @return   {object}   Reference to in-modal equal snapshot
     */
    function findEqualSnapshot(snapshot) {
      var equalSnapshot;

      if (snapshot) {
        entity.vmDocument.versions.some(function findSnapshotFn(snap) {
          if (snap &&
            (snapshot.instanceId.jobInstanceId === snap.instanceId.jobInstanceId)) {
            equalSnapshot = snap;
          }

          return equalSnapshot;
        });
      }

      return equalSnapshot;
    }

    /**
     * Find an equal archivalTarget on the given snapshot
     *
     * @method   findEqualArchivalTarget
     * @param    {object}   snapshot         The snapshot to search
     * @param    {object}   archivalTarget   Out-modal archivalTarget to find
     * @return   {object}   Reference to in-modal archivalTarget that matches,
     *                      or undefined if no match is found.
     */
    function findEqualArchivalTarget(snapshot, archivalTarget) {
      var equalTarget;

      if (archivalTarget && snapshot && snapshot.replicaInfo &&
        snapshot.replicaInfo.replicaVec) {
        snapshot.replicaInfo.replicaVec
          .some(function targetFinderFn(vec) {
            if (ExternalTargetService.isSameTarget(archivalTarget.target, vec.target)) {
              equalTarget = vec;
            }

            // If a match was found, this will be true and this loop terminates.
            return !!equalTarget;
          });
      }

      return equalTarget;
    }

    /**
     * cMultiselect update handler to update the filter on the list of snapshots
     *
     * @method   updateTargetFilter
     * @param    {integer}   selection   Target type to apply. 0 clears the
     *                                   filter
     */
    function updateTargetFilter(selection) {
      $scope.targetFilter = (selection) ? selection.value : 0;
      $scope.versions = (!$scope.targetFilter) ?

        // No filter. Show all versions
        entity.vmDocument.versions :

        // Filter selected, lets apply it
        entity.vmDocument.versions.filter(filterTargetType);
    }

    /**
     * Generates a list of cMultiselect compatible options from a list of
     * allowed target types. See `allowedTargetTypes` above for the allowed
     * types.
     *
     * @method   getTargetFilterOptions
     * @return   {array}   List of cMultiselect options
     */
    function getTargetFilterOptions() {
      return allowedTargetTypes.map(function optionMapperFn(type) {
        return {
          selected: type === $scope.targetFilter,
          icon: allowedTargetTypeIcons[type] || undefined,
          disabled: false,
          name: $scope.text.tooltips[type],
          value: type,
        };
      });
    }

    /**
     * JS filter Function to filter list of snapshots by selected target Type.
     *
     * @method   filterTargetType
     * @param    {object}    val    The Snapshot object
     * @param    {integer}   ii     The Snapshot's index in the list
     * @param    {array}     list   The list to be filtered
     * @return   {boolean}   True keeps the val row. False filters it out.
     */
    function filterTargetType(val, ii, list) {
      return !$scope.targetFilter ||
        snapshotHasTargetType(val, $scope.targetFilter);
    }

    /**
     * Determines if a given snapshot has any targets of the given type.
     *
     * @method   snapshotHasTargetType
     * @param    {object}    snapshot     The snapshot object
     * @param    {integer}   targetType   The target type: [1,3,4]
     * @return   {boolean}   True if found. False otherwise.
     */
    function snapshotHasTargetType(snapshot, targetType) {
      return !snapshot || !targetType || snapshot.replicaInfo.replicaVec
        .some(function findTargetTypeFn(target) {
          switch (targetType) {
            case 3:

              // cloud
              return target.target.archivalTarget &&
                target.target.archivalTarget.type === 0;

            case 4:

              // tape
              return target.target.archivalTarget &&
                target.target.archivalTarget.type === 1;

            default:
              return targetType === target.target.type;
          }

        });
    }

    /**
     * Finds an archiveTarget by it's ID for the selected entity snapshot.
     *
     * @method   findArchiveTargetById
     * @param    {string}   [archiveId]   The archiveUid.objectId
     * @return   {object}   The found object, or undefined.
     */
    function findArchiveTargetById(archiveId) {
      var out;

      if (archiveId) {
        $scope._snapshot.replicaInfo.replicaVec
          .some(function findTargetFn(target) {
            if (target.archiveUid &&
                target.archiveUid.objectId === archiveId) {
              out = target;

              return true;
            }
          });
      }

      return out;
    }

    /**
     * Sets the archive target.
     *
     * @method   setArchiveTarget
     * @param    {object}   [archiveTarget]   The archive target. If empty,
     *                                        defaults to first available on
     *                                        currently selected version.
     * @return   {object}   The set archiveTarget
     */
    function setArchiveTarget(archiveTarget) {
      switch (true) {
        // Explicitly set the target
        case (angular.isDefined(archiveTarget)):
          $scope._archiveTarget = archiveTarget;
          break;

        // No snapshot, so no archive (current view)
        case (angular.isUndefined($scope._snapshot)):
          $scope._archiveTarget = undefined;
          break;

        // archvieTarget set on the entity, lets use it
        case ($scope._snapshot && entity._archiveTarget):
          $scope._archiveTarget = entity._archiveTarget;
          break;

        // Finally, use the first in the list for the selected snapshot
        default:
          $scope._archiveTarget = $scope._snapshot.replicaInfo &&
            $scope._snapshot.replicaInfo.replicaVec[0];
      }

      return $scope._archiveTarget;
    }

    /**
     * Sets the snapshot.
     *
     * @method   setSnapshot
     * @param    {object}   [snapshot]        The restore point selected. if
     *                                        empty, defaults to the index set
     *                                        on the `entity`.
     * @param    {object}   [archiveTarget]   Optional archiveTarget to set too.
     * @return   {object}   The set snapshot
     */
    function setSnapshot(snapshot, archiveTarget) {
      $scope._snapshot = snapshot;
      setArchiveTarget(archiveTarget);

      return $scope._snapshot;
    }

    /**
     * Prepare the modal output and close it out.
     *
     * @method   saveSnapshotSelection
     */
    function saveSnapshotSelection() {
      var result = {
        snapshot: $scope._snapshot,
        archiveTarget:
          ($scope._archiveTarget && 1 !== $scope._archiveTarget.target.type) ?
          $scope._archiveTarget :
          undefined,
      };

      $scope.$close(result);
    }

    // Initialize!
    activate();
  }

})(angular);
