<cog-settings-list-item [formGroup]="formGroup"
  *ngIf="formControl"
  [invalid]="formControl.invalid"
  [label]="'protectionGroups.parallelRunsLabel' | translate">
  <cog-settings-list-item-value-display>
    {{formControl.value | boolLabel}}
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor [formGroup]="formGroup"
    cogDataId="settings-objects-in-concurrent-runs">
    <mat-slide-toggle [formControlName]="name"
      [inheritDataId]="true"
      cogDataId="toggle">
      {{'protectionGroups.parallelRunsLabel' | translate}}
    </mat-slide-toggle>
    <mat-hint>
      {{'protectionGroups.parallelRunsHint' | translate}}
    </mat-hint>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
