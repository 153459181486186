<h1 mat-dialog-title cogDataId="view-cluster-preference-dialog-title">
  {{ 'dg.clusterConfig.scanOrder' | translate }}
</h1>
<mat-divider class="margin-bottom"></mat-divider>
<mat-dialog-content>
  <dg-cr-cluster-order-list
    *ngIf="hasConfiguredClusters"
    [loading]="isLoading"
    [clusterOrder]="orderedClusterList"
    [scanOnReplicaEnabled]="scanOnReplicaEnabled"
    [showScanOnReplicaEnabled]="orderedClusterList?.length && !isLoading ? true : false"
    [isMinimalMode]="true">
  </dg-cr-cluster-order-list>
  <cog-blank-card
    *ngIf="!hasConfiguredClusters"
    type="argus:no-cluster-configured"
    size="sm"
    iconSize="sm"
    message="{{ 'dg.clusterConfig.noClustersIncludedInScanOrder' | translate }}">
  </cog-blank-card>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <span class="full-width flex-row vertical-align flex-justify-space-between">
    <span>
      <button
        [dgHasPermissions]="['DGAAS_MANAGE_CLUSTER_SCANNING_ORDER']"
        mat-stroked-button
        mat-dialog-close
        cogDataId="edit-scan-order"
        (click)="editScanOrder()">
        {{ 'dg.clusterReplication.editScanOrder' | translate }}
      </button>
    </span>
    <button
      mat-flat-button
      mat-dialog-close
      cdkfocusinitial
      cogDataId="close-view-cluster-preference-dialog"
      color="primary">
      {{ 'close' | translate }}
    </button>
  </span>
</mat-dialog-actions>
