import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Controls, NgxSubFormComponent, subformComponentProviders } from 'ngx-sub-form';

import { MfaSecurityToken } from '../../interfaces/mfa-verification';

@Component({
  selector: 'coh-token-verification',
  templateUrl: './token-verification.component.html',
  providers: subformComponentProviders(TokenVerificationComponent),
})
export class TokenVerificationComponent extends NgxSubFormComponent<MfaSecurityToken> {

  /**
   * Returns the form controls for the group.
   *
   * @returns The form controls
   */
  getFormControls(): Controls<MfaSecurityToken> {
    return {
      securityToken: new UntypedFormControl(null, Validators.required),
    };
  }

}
