import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AjaxHandlerService } from '@cohesity/utils';
import { Controls, DataInput, NgxRootFormComponent, subformComponentProviders, takeUntilDestroyed } from 'ngx-sub-form';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonDmsClusterConnectionService } from '../../common-dms-cluster-connection.service';

import { DmsConnection } from '../../models/dms-connection';

/**
 * Rename connection form definition.
 */
export interface RenameConnectionForm {
  name: string;
}

/**
 * @description
 * Modal dialog for renaming SaaS Connection.
 */
@Component({
  selector: 'coh-rename-connection-dialog',
  templateUrl: './rename-connection-dialog.component.html',
  providers: subformComponentProviders(RenameConnectionDialogComponent),
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenameConnectionDialogComponent extends NgxRootFormComponent<RenameConnectionForm> implements OnInit {
  /**
   * Ngx form data input.
   */
  @DataInput()
  dataInput: Required<RenameConnectionForm>;

  /**
   * Ngx form data output.
   */
  @Output()
  readonly dataOutput = new EventEmitter<RenameConnectionForm>();

  /**
   * Subject for disabling user inputs when form is updating.
   */
  readonly busy$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly connection: DmsConnection,
    readonly dialogRef: MatDialogRef<RenameConnectionDialogComponent>,
    private ajaxHandler: AjaxHandlerService,
    private commonDmsClusterConnectionService: CommonDmsClusterConnectionService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.dataInput = { name: this.connection.groupName };
  }

  /**
   * Implementation of the abstracted NgxSubForm.
   * Returns the form controls that are linked to this NgxSubFormComponent.
   */
  getFormControls(): Controls<RenameConnectionForm> {
    return {
      name: new UntypedFormControl('', [Validators.required]),
    };
  }

  /**
   * Handles rename connection form submit.
   */
  onSubmit() {
    this.busy$.next(true);
    this.commonDmsClusterConnectionService.renameConnection(
      this.connection, this.formGroupControls.name.value)
      .pipe(
        takeUntilDestroyed(this),
        catchError(error => {
          this.ajaxHandler.handler(error);
          this.busy$.next(false);
          return throwError(new Error(error));
        })
      )
      .subscribe(() => {
        this.connection.groupName = this.formGroupControls.name.value;
        this.dialogRef.close('connectionNameUpdated');
      });
  }
}
