import { BehaviorSubject, merge, Observable } from 'rxjs';
import { debounceTime, map, shareReplay } from 'rxjs/operators';
import { KeyedSelectionModel } from '../../../util/index';
import { DataTreeControl } from './data-tree-control';
import { DataTreeNode } from './data-tree.model';

/**
 * This is a map of item ids to arbitrary options that can be set for each item.
 */
export type SelectionOptions = Record<string | number, any>;

/**
 * This represents all of the currently selected data in the tree.
 */
export interface DataTreeSelection<T extends DataTreeNode<any>> {
  /**
   * Nodes that have been auto selected. This does not include
   * the children of an auto selected node.
   */
  autoSelected?: T[];

  /**
   * Nodes that have been excluded from auto selection. For this to be
   * valid, an ancestor must be auto selected.
   */
  excluded?: T[];

  /**
   * Per node specific options.
   */
  options?: SelectionOptions;

  /**
   * Nodes that have been explicitly selected via checkboxes
   */
  selected?: T[];
}

/**
 * The data tree selection model.
 */
export class DataTreeSelectionModel<T extends DataTreeNode<any>> {
  /**
   * Emits changes whenever a change to a selection model is made.
   */
  changes$: Observable<DataTreeSelection<T>>;

  /**
   * Gets the current selection, including all selected, autoselected, excluded, and node options.
   */
  get currentSelection(): DataTreeSelection<T> {
    return {
      selected: this.selectionModel.selected,
      excluded: this.exclusionModel.selected,
      autoSelected: this.autoSelectionModel.selected,
      options: this.selectionOptions$.value,
    };
  }

  /**
   * Sets the current including all selected, autoselected, excluded, and node options.
   *
   * @param   selection   The new selection.
   */
  set currentSelection(selection: DataTreeSelection<T>) {
    this.selectionModel.clear();
    this.autoSelectionModel.clear();
    this.exclusionModel.clear();

    if (selection.selected) {
      selection.selected.forEach(selected => this.toggleNodeSelection(selected));
    }

    if (selection.autoSelected) {
      selection.autoSelected.forEach(auto => this.toggleNodeAutoSelection(auto));
    }

    if (selection.excluded) {
      selection.excluded.forEach(exclude => this.toggleNodeExclude(exclude));
    }

    this.selectionOptions$.next(selection.options);
  }

  /**
   * Selection model to track auto selected nodes.
   */
  private autoSelectionModel: KeyedSelectionModel<T>;

  /**
   * Selection model to rack excluded nodes.
   */
  private exclusionModel: KeyedSelectionModel<T>;

  /**
   * Selection model to track selected nodes.
   */
  private selectionModel: KeyedSelectionModel<T>;

  /**
   * Tracks node-specific options.
   */
  private selectionOptions$ = new BehaviorSubject<SelectionOptions>({});

  constructor(private treeControl: DataTreeControl<T>, public isSingleSelect?: boolean,
    public readOnly = false) {

    this.initializeUnderlyingModels(isSingleSelect);
  }

  /**
   * Returns true if a node can be selected. This uses a callback function to evaluate the selection rules.
   * It also disables selection for items which are already auto selected.
   *
   * @param   node   The node to check.
   * @return  True if the node can be selected.
   */
  canSelectNode(node: T): boolean {
    return (
      !this.readOnly &&
      !this.autoSelectionModel.isSelected(node) &&
      !(this.isAncestorAutoSelected(node) && !node.ineligibleForAutoSelect) &&
      node.canSelect(this.currentSelection, this.isSingleSelect)
    );
  }

  /**
   * Returns true if a node can be auto selected. This uses a callback function to evaluate the auto selection rules.
   * Selected items can be auto selected, but already auto selected or excluded items cannot be.
   *
   * @param   node   The node to check.
   * @return  True if the node can be auto selected.
   */
  canAutoSelectNode(node: T): boolean {
    return (
      !(this.isSingleSelect || this.readOnly) &&
      !this.autoSelectionModel.isSelected(node) &&
      !this.isAncestorAutoSelected(node) &&
      !this.exclusionModel.isSelected(node) &&
      node.canAutoSelect(this.currentSelection)
    );
  }

  /**
   * Returns true if a node can be excluded from auto selection. This uses a callback function to evaluate the
   * exclusion rules. Nodes with auto selected parents which are not already excluded can be excluded.
   *
   * @param   node   The node to check.
   * @return  True if the node can be exluded.
   */
  canExcludeNode(node: T): boolean {
    return (
      !(this.isSingleSelect || this.readOnly) &&
      (this.isAncestorAutoSelected(node) || node.isTag) &&
      !this.exclusionModel.isSelected(node) &&
      !this.isAncestorExcluded(node) &&
      node.canExclude(this.currentSelection)
    );
  }

  /**
   * Checks if all of a node's descendeants are selected. This and descendantsPartiallySelected
   * can be used to determine the checkbox state of a parent node.
   *
   * @param   node   The parent node to check.
   * @return  True if all descendeants are selected.
   */
  descendantsAllSelected(node?: T): boolean {
    return this.treeControl.checkAllDescendants(node, child => this.selectionModel.isSelected(child));
  }

  /**
   * Checks if all of a node's descendants are selected regardless of the node being visible or not.
   *
   * @param   node   The parent node to check.
   * @return  True if all descendants are selected.
   */
  descendantsAllSelectedUnfiltered(node?: T): boolean {
    return this.treeControl.checkAllDescendantsUnfiltered(node, child => this.selectionModel.isSelected(child));
  }

  /**
   * Checks returns true, if some, but not all of a node's descendents are selected.
   *
   * @param   node   The parent node to check.
   * @return  True if some, but not all descendeants are selected.
   */
  descendantsPartiallySelected(node?: T): boolean {
    return (
      this.treeControl.checkSomeDescendants(node, child => this.selectionModel.isSelected(child)) &&
      !this.descendantsAllSelected(node)
    );
  }

  /**
   * Checks returns true, if some, but not all of a node's descendants are selected
   * regardless of the node being visible or not.
   *
   * @param   node   The parent node to check.
   * @return  True if some, but not all descendants are selected.
   */
  descendantsPartiallySelectedUnfiltered(node?: T): boolean {
    return (
      this.treeControl.checkSomeDescendantsUnfiltered(node, child => this.selectionModel.isSelected(child)) &&
      !this.descendantsAllSelectedUnfiltered(node)
    );
  }

  /**
   * Looks up specific options set for a node. This will return undefined if there are no options set.
   *
   * @param   id   The id of the node to look up options for.
   * @return  Custom options, if any, that have been set for that node.
   */
  getOptionsForNode(id: string | number): any {
    return this.selectionOptions$.value[id];
  }

  /**
   * Checks if the current node should show as auto selected.
   *
   * @param   node   The node to check.
   * @return  True if the node is auto selected, or any of it's parents are.
   */
  isAutoSelected(node: T): boolean {
    return this.autoSelectionModel.isSelected(node);
  }

  /**
   * Checks if any ancestor of the current node is auto selected.
   *
   * @param   node   The node to check.
   * @return  True if any ancestor is auto selected.
   */
  isAncestorAutoSelected(node: T): boolean {
    // If the node has tag ids, see if any of them are included in the auto selection model
    if (this.treeControl.matchTagSelection(this.autoSelectionModel.selected, node)) {
      return true;
    }
    if (!this.autoSelectionModel.selected?.length) {
      return false;
    }
    return this.treeControl.checkAnyAncestor(node, parent => this.autoSelectionModel.isSelected(parent));
  }

  /**
   * Checks if any ancestor of the current node is excluded
   *
   * @param   node   The node to check.
   * @return  True if any ancestor is excluded.
   */
  isAncestorExcluded(node: T): boolean {
    // If the node has tag ids, see if any of them are included in the exclusion model
    if (this.treeControl.matchTagSelection(this.exclusionModel.selected, node)) {
      return true;
    }
    if (!this.exclusionModel.selected?.length) {
      return false;
    }
    return this.treeControl.checkAnyAncestor(node, parent => this.exclusionModel.isSelected(parent));
  }

  /**
   * Checks if a node is auto protected by a specific node.
   *
   * @param   node      The node to check
   * @param   ancestor  The ancestor to check against
   * @returns True if this node is auto protected by this ancestor.
   */
  isAutoSelectedByAncestor(node: T, ancestor: T) {
    // If the ancestor isn't auto protoected, or the node is excluded, we can just return false
    if (!this.isAutoSelected(ancestor) || this.isExcluded(node) || this.isAncestorExcluded(node)) {
      return false;
    }

    // If the ancestor is a tag, just check if the node includes this tag
    if (ancestor.isTag) {
      return !!node.tagIds.find(tagId => tagId === ancestor.id);
    }

    // Otherwise, walk up and see if this is one of the node's ancestors.
    return this.treeControl.checkAnyAncestor(node, parent => parent.id === ancestor.id);
  }

  /**
   * Checks if the current node should show as excluded.
   *
   * @param   node   The node to check.
   * @return  True if the node is excluded, or any of it's parents are.
   */
  isExcluded(node: T): boolean {
    return this.exclusionModel.isSelected(node);
  }

  /**
   * Checks if the current node should show as selected.
   *
   * @param   node   The node to check.
   * @return  True if the node is selected or all of its children are.
   */
  isSelected(node: T): boolean {
    return this.selectionModel.isSelected(node);
  }

  /**
   * Sets or updates the options for a node and emits a new value on the selection options
   * behavior subject.
   *
   * @param   id      The id of the node to set options for.
   * @param   options The options to set.
   */
  setOptionsForNode(id: string | number, option: any) {
    this.selectionOptions$.next({
      ...this.selectionOptions$.value,
      [id]: option,
    });
  }

  /**
   * Remove options for a node.
   *
   * @param   id      The id of the node to remove options for.
   */
  removeOptionsForNode(id: string | number) {
    const selectionOptions = this.selectionOptions$.value;
    delete selectionOptions[id];
    this.selectionOptions$.next(selectionOptions);
  }

  /**
   * Toggles the auto selection of a node. This also deselects all descendants of the current node.
   *
   * @param   node   The node to toggle auto select for.
   */
  toggleNodeAutoSelection(node: T) {
    if (!this.readOnly && !node.canAutoSelect(this.currentSelection)) {
      return;
    }
    this.autoSelectionModel.toggle(node);

    if (this.autoSelectionModel.isSelected(node)) {
      // Deselect this node and all descendants if the node is switching to auto select.
      this.selectionModel.deselect(...this.treeControl.getDescendants(node), node);

      // Deselect any auto protected descendants.
      this.autoSelectionModel.deselect(...this.treeControl.getDescendants(node));
    } else {
      // Unexclude all descendents if the node is turning off auto protect.
      this.exclusionModel.deselect(...this.treeControl.getDescendants(node));
    }
  }

  /**
   * Toggles a node's exclusion state.
   *
   * @param   node            The node to toggle exclude for.
   * @param   forceExclusion  True to force exclusion regardless of canExclude logic.
   *                          Set this to true to programatically allow setting exclusion via filter
   *                          at a tree level but disallow exclusion on clicking individual nodes.
   */
  toggleNodeExclude(node: T, forceExclusion = false) {
    if (!this.readOnly && !node.canExclude(this.currentSelection) && !forceExclusion) {
      return;
    }
    this.exclusionModel.toggle(node);
  }

  /**
   * Excludes a set of nodes from the selection.
   *
   * @param nodes The nodes to exclude, nodes which cannot be excluded will be ignored.
   */
  excludeNodes(nodes: T[]) {
    if (this.readOnly) {
      return;
    }
    this.exclusionModel.select(...nodes.filter(node => node.canExclude(this.currentSelection)));
  }

  /**
   * Toggles a node's selection state.
   *
   * @param   node   The node to toggle selection for.
   */
  toggleNodeSelection(node?: T) {
    if (!this.readOnly && !node.isSelectable && !this.canSelectNode(node)) {
      return;
    }
    this.selectionModel.toggle(node);
    const currentSelection = this.currentSelection;

    if (!this.isSingleSelect) {
      // Don't use this class' version of canSelectNode in order to cache the current selection
      // and not recreate each time.
      const descendants = this.treeControl.getDescendants(node).filter(desc => desc.canSelect(currentSelection));

      this.selectionModel.isSelected(node)
        ? this.selectionModel.select(...descendants)
        : this.selectionModel.deselect(...descendants);

      // Force update for the parent
      descendants.every(child => this.selectionModel.isSelected(child));

      this.treeControl.forEachAncestor(node, parent => {
        if (!this.canSelectNode(parent)) {
          return;
        }
        const nodeSelected = this.selectionModel.isSelected(parent);
        const descAllSelected = this.descendantsAllSelectedUnfiltered(parent);

        if (nodeSelected && !descAllSelected) {
          this.selectionModel.deselect(parent);
        } else if (!nodeSelected && descAllSelected) {
          this.selectionModel.select(parent);
        }
      });
    }
  }

  /**
   * Toggles select all for the entire tree. If all nodes are selected, this will deselect them.
   * If some or no nodes are selected, this will select all.
   */
  toggleSelectAll() {
    if (this.readOnly || this.isSingleSelect) {
      return;
    }

    const currentSelection = this.currentSelection;
    const allSelected = this.descendantsAllSelected();

    // Don't use this class' version of canSelectNode in order to cache the current selection
    // and not recreate each time.
    const descendants = this.treeControl.getDescendants().filter(desc => desc.canSelect(currentSelection));

    if (allSelected) {
      this.selectionModel.deselect(...descendants);
    } else {
      this.selectionModel.select(...descendants);
    }
  }

  /**
   * Toggle the isSingleSelect property of this and the underlying models.
   *
   * @param isSingleSelect The current singleSelection state
   */
  toggleSelectionMode(isSingleSelect: boolean) {
    this.isSingleSelect = isSingleSelect;
    this.initializeUnderlyingModels(isSingleSelect);
  }

  /**
   * Create the underlying models for this class and and change handler for them
   *
   * @param isSingleSelect  The current singleSelection state
   */
  initializeUnderlyingModels(isSingleSelect: boolean) {
    this.autoSelectionModel = new KeyedSelectionModel(node => node.id, !isSingleSelect);
    this.exclusionModel = new KeyedSelectionModel(node => node.id, !isSingleSelect);
    this.selectionModel = new KeyedSelectionModel(node => node.id, !isSingleSelect);

    this.changes$ = merge(
      this.autoSelectionModel.changed,
      this.selectionModel.changed,
      this.exclusionModel.changed,
      this.selectionOptions$
    ).pipe(
      // Adding a debounce time here prevents each individual change to
      // an internal selection time from causing the change emitter to fire.
      debounceTime(5),
      map(() => this.currentSelection),
      shareReplay(1)
    );
  }
}
