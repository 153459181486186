import { ChangeDetectorRef, Directive, HostListener } from '@angular/core';

/**
 * Directive to trigger change detection on hovering an element with matTooltip.
 * This should only be used when we need to have tooltips inside a virtual
 * scrollable container which the mat tooltips do not function correctly due
 * to a bug in change detection.
 * Another scenario where matTooltip may be buggy is with *ngFor loop, in that case
 * please try trackBy function first to see if the issue is resolved using trackBy.
 *
 * @example
 *   <span cogTooltipChangeDetection matTooltip="Tooltip"></span>
 */
@Directive({
  selector: '[cogTooltipChangeDetection]',
})
export class TooltipChangeDetectionDirective {

  constructor(private cdr: ChangeDetectorRef) {}

  /**
   * Triggers change detection on mouseenter and mousemove.
   */
  @HostListener('mouseenter', ['$event'])
  @HostListener('mousemove', ['$event'])
  onMouseEvent() {
    this.cdr.detectChanges();
  }
}
