import {
  FortknoxAwsVaultReqParams,
  FortknoxAzureVaultReqParams,
  KmsKeyBasicInformation,
  ProvisionStatus,
  RpaasRegionInfo,
} from '@cohesity/api/v2';

/**
 * Statuses that indicate that vault provision is still in progress.
 */
export const vaultProvisionInProgressStatuses: ProvisionStatus['status'][] = ['Waiting', 'InProgress'];

/**
 * The minimum AWS cluster version which supports FortKnox.
 */
export const rpaasAwsMinClusterVersion = '6.8';

/**
 * The minimum Azure cluster version which supports FortKnox.
 */
export const rpaasAzureMinClusterVersion = '7.0.1';

/**
 * The minimum cluster version which supports rpaas.
 */
export const rpaasMinClusterVersion = '6.8';

/**
 * Api only defines pairing status as a string, these are the possible values.
 */
export type PairingStatus = 'Failed' | 'InProgress' | 'Completed' | 'Waiting';

/**
 * Vault menu actions.
 */
export enum VaultMenuAction {
  EditBackupWindow = 'editVaultingWindow',
}

/**
 * Vault menu action event.
 */
export interface VaultMenuActionEvent {
  action: VaultMenuAction;
  vault: RpaasRegionInfo;
}

/**
 * Type of region encryption.
 */
export type RegionEncryptionType = KmsKeyBasicInformation['kmsKeyType'];

/**
 * Available AWS storage class types
 */
export type FortknoxAwsStorageClass = FortknoxAwsVaultReqParams['storageClass'] | RpaasRegionInfo['storageClass'];

/**
 * Available Azure storage class types
 */
export type FortknoxAzureStorageClass = FortknoxAzureVaultReqParams['storageClass'];

/**
 * Available storage class types
 */
export type FortknoxStorageClass = FortknoxAwsStorageClass | FortknoxAzureStorageClass;

/**
 * List of all AWS storage classes
 */
export const awsStorageClasses: FortknoxAwsStorageClass[] = [
  'AmazonS3Glacier',
  'AmazonS3StandardIA',
  'kAmazonS3Glacier',
  'kAmazonS3StandardIA',
];

/**
 * List of all Azure storage classes
 */
export const azureStorageClasses: FortknoxAzureStorageClass[] = ['AzureCoolBlob', 'AzureArchiveBlob'];

/**
 * List of all available cold storage classes
 */
export const coldStorageClasses: FortknoxStorageClass[] = [
  'AmazonS3Glacier',
  'AzureArchiveBlob',
  'kAmazonS3Glacier'
];

/**
 * List of all available warm storage classes
 */
export const warmStorageClasses: FortknoxStorageClass[] = [
  'AmazonS3StandardIA',
  'AzureCoolBlob',
  'kAmazonS3StandardIA'
];
