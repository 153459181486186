<div [formGroup]="form.formGroup">
  <h6 class="flex-row vertical-align">
    {{ 'category' | translate }}

    <!-- When customer upgrades from 6.4 or earlier, we must infer and assign a category to existing Views. This message
    explains to the user. -->
    <cog-icon size="sm"
      shape="info"
      cogDataId="inferred-category-info"
      class="margin-left-xs"
      *ngIf="isEditMode && isCategoryInferred"
      matTooltip="{{'views.inferredCategory.description' | translate}}"
      matTooltipPosition="right">
    </cog-icon>
  </h6>

  <mat-radio-group class="flex-row-md"
    formControlName="category"
    cogDataId="view-category-input">
    <mat-radio-button *ngFor="let category of categories"
      [value]="category"
      [disabled]="isCategoryDisabled(category)"
      cogDataId="category-{{category}}">
      <span class="flex-row vertical-align">
        {{ ('enum.view.category.' + category) | translate }}

        <!-- May not create Object Services Views via Helios. -->
        <cog-icon size="sm"
          shape="info"
          inline="true"
          class="margin-left-xs"
          *ngIf="isHelios && category === 'ObjectServices'"
          matTooltip="{{'views.create.helios.noObjectServices' | translate}}">
        </cog-icon>
      </span>
    </mat-radio-button>
  </mat-radio-group>
</div>
