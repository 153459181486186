import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'coh-settings-list-preserve-custom-attributes',
  templateUrl: './settings-list-preserve-custom-attributes.component.html',
})
export class SettingsListPreserveCustomAttributesComponent {
  /**
   * The form control for the settings list item.
   */
  @Input() control: UntypedFormControl;

  /**
   * Alternate Label to use for the control.
   */
  @Input() label: string;

  /**
   * Determines if the setting should be disabled.
   */
  @Input() isDisabled = false;
}
