import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, CdkDragStart, DragRef } from '@angular/cdk/drag-drop';

/**
 * Utility class to support multiple drag and drop since Angular does not support
 * it in the current version.
 *
 * This class supports user-defined data structure where Group is the class that
 * dragged Items can be dropped to. Group can be an array contains items directly
 * (itemsName is not defined). Or it can be a class with attribute (itemsName) which
 * contains the items.
 *
 * Example: Look at component EditConnectorGroupComponent which use this class.
 * Based on the example code in this thread: https://github.com/angular/components/issues/13807
 *
 * Further enhancement: make it a customizable component. Note that an attempt is
 * made to do it but have troubles making the drag and drop hierarchy works. The
 * Output hook (cdkDropListDropped) in cdkDropList was not called.
 */
export class MultiDragDrop<Group = any, Item = any> {
  /**
   * Stores the dragging reference when dragging.
   */
  dragging: DragRef = null;

  /**
   * Items selected.
   */
  selections = new SelectionModel<Item>(true, []);

  /**
   * Stores the list of items selected with shift key.
   */
  currentSelectionSpan = new SelectionModel<Item>(true, []);

  /**
   * Stores the last selected item.
   */
  lastSingleSelection: Item;

  /**
   * Stores the group with selection.
   */
  selectedGroup: Group;

  /**
   * Constructor.
   *
   * @param groups  List of user-defined objects that is the list or contains the list of items
   * @param itemsName  Name of attribute that contains the items. If not provided, group is the list.
   * @param detectChanges  Function to be called when there are changes.
   */
  constructor(
    public groups: Group[],
    public itemsName: string = null,
    public detectChanges = () => {}
  ) {
  }

  /**
   * Returns the list of items.
   *
   * @param group  Group object.
   */
  getItemList(group: Group): Item[] {
    return this.itemsName ? group[this.itemsName] : group;
  }

  /**
   * Returns true if item is selected.
   *
   * @param item  Item to be checked.
   */
  isSelected(item: Item): boolean {
    return this.selections.isSelected(item);
  }

  /**
   * Handles when user click on an item. It will handle when shift or ctrl are pressed.
   *
   * @param event  Mouse click event.
   * @param item  Item which is clicked.
   */
  select(event: MouseEvent, item: Item) {
    const selectedGroup = this.groups.find(group => this.getItemList(group)?.includes(item));

    if (!this.selections?.selected.length) {
      // if nothing selected yet, init selection mode and select.
      this.selections.select(item);
      this.lastSingleSelection = item;
      this.selectedGroup = selectedGroup;
    } else if (event.metaKey || event.ctrlKey) {
      // if holding ctrl / cmd
      if (this.selections.isSelected(item)) {
        this.selections.deselect(item);
        this.lastSingleSelection = null;
      } else {
        if (selectedGroup !== this.selectedGroup) {
          this.selections.clear();
        }
        this.selections.select(item);
        this.lastSingleSelection = item;
        this.selectedGroup = selectedGroup;
      }
    } else if (
      event.shiftKey &&
      this.lastSingleSelection &&
      this.lastSingleSelection !== item &&
      selectedGroup === this.selectedGroup
    ) {
      // if holding shift, add group to selection and currentSelectionSpan

      // clear previous shift selection
      if (this.currentSelectionSpan?.selected.length) {
        this.selections.selected.forEach(selection => {
          if (this.currentSelectionSpan.isSelected(selection)) {
            this.selections.deselect(selection);
          }
        });
        this.currentSelectionSpan.clear();
      }

      // build new currentSelectionSpan
      const itemList = this.getItemList(selectedGroup);
      const fromTo = [
        itemList?.findIndex(i => i === this.lastSingleSelection),
        itemList?.findIndex(i => i === item),
      ];
      const from = Math.min(...fromTo);
      const to = Math.max(...fromTo);

      for (let i = from; i <= to; i++) {
        this.currentSelectionSpan.select(itemList[i]);
      }

      // select currentSelectionSpan
      this.currentSelectionSpan?.selected.forEach(selection => {
        if (!this.selections.isSelected(selection)) {
          this.selections.select(selection);
        }
      });
    } else {
      // Select only this item or clear selections.
      const alreadySelected = this.selections.isSelected(item);

      if ((!alreadySelected && !event.shiftKey) ||
        (alreadySelected && this.selections?.selected.length > 1)) {
        this.clearSelection();
        this.selections.clear();
        this.selections.select(item);
        this.lastSingleSelection = item;
        this.selectedGroup = selectedGroup;
      } else if (alreadySelected) {
        this.clearSelection();
      }
    }

    if (!event.shiftKey) {
      this.currentSelectionSpan?.clear();
    }
    this.detectChanges();
  }

  /**
   * Clears selections.
   */
  clearSelection() {
    if (this.selections?.selected.length) {
      this.selections.clear();
      this.currentSelectionSpan?.clear();
      this.lastSingleSelection = null;
      this.selectedGroup = null;
      this.detectChanges();
    }
  }

  /**
   * Selects all items in the group.
   *
   * @param group  The group object.
   */
  selectAll(group: Item[]) {
    if (group && this.selections.selected.length !== group.length) {
      group.forEach(item => this.selections.select(item));
      this.currentSelectionSpan?.clear();
      this.lastSingleSelection = null;
      this.detectChanges();
    }
  }

  /**
   * Handles when dragging started and stores the dragging reference.
   *
   * @param event  Drag start event.
   * @param item  Item being dragged.
   */
  dragStarted(event: CdkDragStart<Item[]>, item: Item) {
    const data = this.selections?.selected.length ? this.selections.selected : [item];

    this.dragging = event.source._dragRef;
    event.source.data = data;
  }

  /**
   * Handles when dragging ended and clear dragging reference.
   */
  dragEnded() {
    this.dragging = null;
  }

  /**
   * Handles when dragged items are dropped.  Clears selection.
   *
   * @param event  Drop event.
   */
  dragDropped(event: CdkDragDrop<any>) {
    if (!event.item?.data?.length) {
      return;
    }
    this.dragging = null;
    setTimeout(() => this.clearSelection());
  }

  /**
   * Handles when dragged items are dropped in to a group.
   *
   * @param event  Drop event.
   */
  drop(event: CdkDragDrop<any>) {
    if (!event.item?.data?.length) {
      return;
    }

    let insertAt = event.currentIndex;

    // Finds out the insertion point. It can be affected by the selected items before it
    // if inserted in the same group.
    if (event.previousContainer === event.container && this.selections?.selected.length > 1) {
      const selections = [...this.selections.selected];

      selections.splice(-1, 1);
      insertAt -= selections.reduce((sum, item) => {
        if (event.container.data.findIndex(i => i === item) > insertAt) {
          return sum;
        }
        return sum + 1;
      }, 0);
    }

    // Remove inserted items.
    event.item.data.forEach((item) => {
      event.previousContainer.data.splice(
        event.previousContainer.data.findIndex(previousItem => previousItem === item),
        1,
      );
    });

    // Insert items.
    event.container.data.splice(insertAt, 0, ...event.item.data);
    setTimeout(() => this.detectChanges());
  }
}
