/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityReportingApiConfiguration as __Configuration } from '../cohesity-reporting-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ReportTypeAttributes } from '../models/report-type-attributes';
import { Reports } from '../models/reports';
import { Report } from '../models/report';
import { ReportPreview } from '../models/report-preview';
import { ReportPreviewParams } from '../models/report-preview-params';
import { ExportReportParams } from '../models/export-report-params';
import { OnDemandReportRequestsInfos } from '../models/on-demand-report-requests-infos';
import { OnDemandReportRequestInfo } from '../models/on-demand-report-request-info';
import { GenerateOnDemandReportRequest } from '../models/generate-on-demand-report-request';
@Injectable({
  providedIn: 'root',
})
class ReportsService extends __BaseService {
  static readonly GetReportTypePath = '/public/reports/report-types/{reportType}';
  static readonly GetReportsPath = '/public/reports';
  static readonly GetReportByIdPath = '/public/reports/{id}';
  static readonly GetReportPreviewPath = '/public/reports/{id}/preview';
  static readonly ExportReportPath = '/public/reports/{id}/export';
  static readonly GetOnDemandReportRequestsPath = '/public/reports/requests';
  static readonly GenerateOnDemandReportPath = '/public/reports/requests';
  static readonly GetOnDemandReportRequestByIdPath = '/public/reports/requests/{id}';
  static readonly CancelOnDemandReportRequestPath = '/public/reports/requests/{id}/cancel';
  static readonly DownloadOnDemandReportPath = '/public/reports/requests/{id}/artifacts/{format}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List properties of a report type
   *
   * Fetches list of properties of a report type.
   * @param reportType Specifies the report type.
   * @return Success
   */
  GetReportTypeResponse(reportType: 'ClusterConnections' | 'ClusterConnectionsTrend' | 'ProtectedUnprotectedObjects' | 'ProtectedObjects' | 'ProtectionActivity' | 'ProtectionGroupSummary' | 'ProtectionRuns' | 'ProtectionRunsTrend' | 'StorageConsumptionByObjects' | 'StorageConsumptionByProtectionGroups' | 'StorageConsumptionByClusters' | 'StorageConsumptionByStorageDomains' | 'StorageConsumptionByOrganizations' | 'StorageConsumptionByClustersTrend' | 'StorageConsumptionByViews' | 'ServiceConsumption' | 'ServiceConsumptionTrend' | 'Recovery' | 'Failures' | 'ProtectionSummaryBySystem' | 'DataTransferredToExternalTargets' | 'DataTransferredToExternalTargetsTrend' | 'SourceGrowth' | 'ObjectGrowth' | 'M365BackupStorageUsage' | 'M365BackupStorageUsageTrend' | 'M365Compliance'): __Observable<__StrictHttpResponse<ReportTypeAttributes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/reports/report-types/${encodeURIComponent(reportType)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReportTypeAttributes>;
      })
    );
  }
  /**
   * List properties of a report type
   *
   * Fetches list of properties of a report type.
   * @param reportType Specifies the report type.
   * @return Success
   */
  GetReportType(reportType: 'ClusterConnections' | 'ClusterConnectionsTrend' | 'ProtectedUnprotectedObjects' | 'ProtectedObjects' | 'ProtectionActivity' | 'ProtectionGroupSummary' | 'ProtectionRuns' | 'ProtectionRunsTrend' | 'StorageConsumptionByObjects' | 'StorageConsumptionByProtectionGroups' | 'StorageConsumptionByClusters' | 'StorageConsumptionByStorageDomains' | 'StorageConsumptionByOrganizations' | 'StorageConsumptionByClustersTrend' | 'StorageConsumptionByViews' | 'ServiceConsumption' | 'ServiceConsumptionTrend' | 'Recovery' | 'Failures' | 'ProtectionSummaryBySystem' | 'DataTransferredToExternalTargets' | 'DataTransferredToExternalTargetsTrend' | 'SourceGrowth' | 'ObjectGrowth' | 'M365BackupStorageUsage' | 'M365BackupStorageUsageTrend' | 'M365Compliance'): __Observable<ReportTypeAttributes> {
    return this.GetReportTypeResponse(reportType).pipe(
      __map(_r => _r.body as ReportTypeAttributes)
    );
  }

  /**
   * List Reports
   *
   * Fetches list of all reports accessible by logged in user.
   * @param params The `ReportsService.GetReportsParams` containing the following parameters:
   *
   * - `userContext`: Specifies the user context to filter reports.
   *
   * - `ids`: Specifies the ids of reports to fetch.
   *
   * @return Success
   */
  GetReportsResponse(params: ReportsService.GetReportsParams): __Observable<__StrictHttpResponse<Reports>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userContext != null) __params = __params.set('userContext', params.userContext.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/reports`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Reports>;
      })
    );
  }
  /**
   * List Reports
   *
   * Fetches list of all reports accessible by logged in user.
   * @param params The `ReportsService.GetReportsParams` containing the following parameters:
   *
   * - `userContext`: Specifies the user context to filter reports.
   *
   * - `ids`: Specifies the ids of reports to fetch.
   *
   * @return Success
   */
  GetReports(params: ReportsService.GetReportsParams): __Observable<Reports> {
    return this.GetReportsResponse(params).pipe(
      __map(_r => _r.body as Reports)
    );
  }

  /**
   * Fetch a Report
   *
   * Get a report for a given id.
   * @param id Specifies the id of the report.
   * @return Success
   */
  GetReportByIdResponse(id: string): __Observable<__StrictHttpResponse<Report>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/reports/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Report>;
      })
    );
  }
  /**
   * Fetch a Report
   *
   * Get a report for a given id.
   * @param id Specifies the id of the report.
   * @return Success
   */
  GetReportById(id: string): __Observable<Report> {
    return this.GetReportByIdResponse(id).pipe(
      __map(_r => _r.body as Report)
    );
  }

  /**
   * Fetch a Report Preview
   *
   * Get preview of a configured report.
   * @param params The `ReportsService.GetReportPreviewParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the report.
   *
   * - `body`: Request body params in order to preview a given report.
   *
   * @return Success
   */
  GetReportPreviewResponse(params: ReportsService.GetReportPreviewParams): __Observable<__StrictHttpResponse<ReportPreview>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/reports/${encodeURIComponent(params.id)}/preview`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReportPreview>;
      })
    );
  }
  /**
   * Fetch a Report Preview
   *
   * Get preview of a configured report.
   * @param params The `ReportsService.GetReportPreviewParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the report.
   *
   * - `body`: Request body params in order to preview a given report.
   *
   * @return Success
   */
  GetReportPreview(params: ReportsService.GetReportPreviewParams): __Observable<ReportPreview> {
    return this.GetReportPreviewResponse(params).pipe(
      __map(_r => _r.body as ReportPreview)
    );
  }

  /**
   * Export a Report
   *
   * Export a configured report.
   * @param params The `ReportsService.ExportReportParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the report.
   *
   * - `body`: Request body params in order to export a given report.
   *
   * @return A PDF or XLS or CSV file
   */
  ExportReportResponse(params: ReportsService.ExportReportParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/reports/${encodeURIComponent(params.id)}/export`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Export a Report
   *
   * Export a configured report.
   * @param params The `ReportsService.ExportReportParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the report.
   *
   * - `body`: Request body params in order to export a given report.
   *
   * @return A PDF or XLS or CSV file
   */
  ExportReport(params: ReportsService.ExportReportParams): __Observable<Blob> {
    return this.ExportReportResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Get on demand report requests
   *
   * Retrieves a list of all report requests. Users can filter the requests by status, report type, and date-time range. The size of each page by default will be 100 records
   * @param params The `ReportsService.GetOnDemandReportRequestsParams` containing the following parameters:
   *
   * - `statuses`: Specifies the request status to filter by.
   *
   * - `requestIds`: Specifies the request id to filter by.
   *
   * - `reportIds`: Specifies the type of the report to filter by.
   *
   * - `pageSize`: Specifies size of the page.
   *
   * - `pageNumber`: Specifies the page number.Indexed at 1
   *
   * @return Success
   */
  GetOnDemandReportRequestsResponse(params: ReportsService.GetOnDemandReportRequestsParams): __Observable<__StrictHttpResponse<OnDemandReportRequestsInfos>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.statuses || []).forEach(val => {if (val != null) __params = __params.append('statuses', val.toString())});
    (params.requestIds || []).forEach(val => {if (val != null) __params = __params.append('requestIds', val.toString())});
    (params.reportIds || []).forEach(val => {if (val != null) __params = __params.append('reportIds', val.toString())});
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/reports/requests`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OnDemandReportRequestsInfos>;
      })
    );
  }
  /**
   * Get on demand report requests
   *
   * Retrieves a list of all report requests. Users can filter the requests by status, report type, and date-time range. The size of each page by default will be 100 records
   * @param params The `ReportsService.GetOnDemandReportRequestsParams` containing the following parameters:
   *
   * - `statuses`: Specifies the request status to filter by.
   *
   * - `requestIds`: Specifies the request id to filter by.
   *
   * - `reportIds`: Specifies the type of the report to filter by.
   *
   * - `pageSize`: Specifies size of the page.
   *
   * - `pageNumber`: Specifies the page number.Indexed at 1
   *
   * @return Success
   */
  GetOnDemandReportRequests(params: ReportsService.GetOnDemandReportRequestsParams): __Observable<OnDemandReportRequestsInfos> {
    return this.GetOnDemandReportRequestsResponse(params).pipe(
      __map(_r => _r.body as OnDemandReportRequestsInfos)
    );
  }

  /**
   * Create a new report request.
   *
   * Submits a report requests with user requested params for format and date-time range and type of report
   * @param body Request body params in order to generate an on demand report
   * @return Success
   */
  GenerateOnDemandReportResponse(body: GenerateOnDemandReportRequest): __Observable<__StrictHttpResponse<OnDemandReportRequestInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/reports/requests`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OnDemandReportRequestInfo>;
      })
    );
  }
  /**
   * Create a new report request.
   *
   * Submits a report requests with user requested params for format and date-time range and type of report
   * @param body Request body params in order to generate an on demand report
   * @return Success
   */
  GenerateOnDemandReport(body: GenerateOnDemandReportRequest): __Observable<OnDemandReportRequestInfo> {
    return this.GenerateOnDemandReportResponse(body).pipe(
      __map(_r => _r.body as OnDemandReportRequestInfo)
    );
  }

  /**
   * Get the details of a report request
   * @param id Specifies the id of the request
   * @return Success
   */
  GetOnDemandReportRequestByIdResponse(id: string): __Observable<__StrictHttpResponse<OnDemandReportRequestInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/reports/requests/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OnDemandReportRequestInfo>;
      })
    );
  }
  /**
   * Get the details of a report request
   * @param id Specifies the id of the request
   * @return Success
   */
  GetOnDemandReportRequestById(id: string): __Observable<OnDemandReportRequestInfo> {
    return this.GetOnDemandReportRequestByIdResponse(id).pipe(
      __map(_r => _r.body as OnDemandReportRequestInfo)
    );
  }

  /**
   * Cancel on demand report request.
   * @param id Specifies the id of the request
   */
  CancelOnDemandReportRequestResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/reports/requests/${encodeURIComponent(id)}/cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Cancel on demand report request.
   * @param id Specifies the id of the request
   */
  CancelOnDemandReportRequest(id: string): __Observable<null> {
    return this.CancelOnDemandReportRequestResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Download generated report from a previous request
   * @param params The `ReportsService.DownloadOnDemandReportParams` containing the following parameters:
   *
   * - `id`: Specifies the request id for which report must be fetched.
   *
   * - `format`: Specifies the format in which the report is requested.
   *
   * @return The file pased on the artifact type.
   */
  DownloadOnDemandReportResponse(params: ReportsService.DownloadOnDemandReportParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/reports/requests/${encodeURIComponent(params.id)}/artifacts/${encodeURIComponent(params.format)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Download generated report from a previous request
   * @param params The `ReportsService.DownloadOnDemandReportParams` containing the following parameters:
   *
   * - `id`: Specifies the request id for which report must be fetched.
   *
   * - `format`: Specifies the format in which the report is requested.
   *
   * @return The file pased on the artifact type.
   */
  DownloadOnDemandReport(params: ReportsService.DownloadOnDemandReportParams): __Observable<Blob> {
    return this.DownloadOnDemandReportResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }
}

module ReportsService {

  /**
   * Parameters for GetReports
   */
  export interface GetReportsParams {

    /**
     * Specifies the user context to filter reports.
     */
    userContext?: null | 'DMaaS' | 'Hybrid' | 'MCM' | 'MCMTenant' | 'FortKnox' | 'SmartFiles';

    /**
     * Specifies the ids of reports to fetch.
     */
    ids?: null | Array<string>;
  }

  /**
   * Parameters for GetReportPreview
   */
  export interface GetReportPreviewParams {

    /**
     * Specifies the id of the report.
     */
    id: string;

    /**
     * Request body params in order to preview a given report.
     */
    body: ReportPreviewParams;
  }

  /**
   * Parameters for ExportReport
   */
  export interface ExportReportParams {

    /**
     * Specifies the id of the report.
     */
    id: string;

    /**
     * Request body params in order to export a given report.
     */
    body: ExportReportParams;
  }

  /**
   * Parameters for GetOnDemandReportRequests
   */
  export interface GetOnDemandReportRequestsParams {

    /**
     * Specifies the request status to filter by.
     */
    statuses?: Array<'Submitted' | 'InProgress' | 'Succeeded' | 'Failed' | 'Canceled'>;

    /**
     * Specifies the request id to filter by.
     */
    requestIds?: Array<string>;

    /**
     * Specifies the type of the report to filter by.
     */
    reportIds?: Array<string>;

    /**
     * Specifies size of the page.
     */
    pageSize?: number;

    /**
     * Specifies the page number.Indexed at 1
     */
    pageNumber?: number;
  }

  /**
   * Parameters for DownloadOnDemandReport
   */
  export interface DownloadOnDemandReportParams {

    /**
     * Specifies the request id for which report must be fetched.
     */
    id: string;

    /**
     * Specifies the format in which the report is requested.
     */
    format: 'PDF' | 'XLS' | 'CSV';
  }
}

export { ReportsService }
