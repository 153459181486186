import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { downgradeComponent } from '@angular/upgrade/static';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { AngularMaterialModule, CohesityHelixModule, SpinnerComponent } from '@cohesity/helix';
import { BannerConfigsModule, IrisContextModule, TenantColumnModule } from '@cohesity/iris-core';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { HIGHCHARTS_MODULES } from 'angular-highcharts';
import { QRCodeModule } from 'angularx-qrcode';
import { HighchartsChartModule } from 'highcharts-angular';
import * as highchartsMore from 'highcharts/highcharts-more';
import * as highchartsAccessibility from 'highcharts/modules/accessibility';
import * as highchartsSankey from 'highcharts/modules/sankey';
import * as highchartsSolidGauge from 'highcharts/modules/solid-gauge';
import * as highchartsSunburst from 'highcharts/modules/sunburst';
import * as highchartsTimeline from 'highcharts/modules/timeline';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { InternalDnsEntryModule } from '../modules/admin/networking/internal-dns/internal-dns.module';
import { SourceDetailsModule } from '../modules/sources-shared/source-details/source-details.module';
import { ActionButtonsModule } from './action-buttons';
import { AuthAppComponent } from './auth-app/auth-app.component';
import { BoardSelectComponent } from './board-select/board-select.component';
import { BytesSizeSelectorComponent } from './byte-size-selector/byte-size-selector.component';
import { StatlistMultiSeriesChart } from './charts/statlist-multi-series-chart';
import { ClipboardCtaModule } from './clipboard/clipboard-cta.module';
import { ClusterSelectorComponent } from './cluster-selector/cluster-selector.component';
import { CommonFiltersModule } from './common-filters';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConnectionStatusComponent } from './connection-status/connection-status.component';
import { DashcardsModule } from './dashcards';
import { DateFormatPickerModule } from './date-format-picker/date-format-picker.module';
import { DialogsModule } from './dialogs';
import { DIRECTIVES, OnlyOwnProvider } from './directives';
import { DmsRegisterSourceComponent } from './dms-register-source/dms-register-source.component';
import { FormsModule } from './forms';
import { GlanceBarComponent } from './glance-bar/glance-bar.component';
import { LocalizationModule } from './localization/localization.module';
import { LoginBannerComponent } from './login-banner/login-banner.component';
import { McmViewComponent } from './mcm-view/mcm-view.component';
import { MultitaskTableModule } from './multitask-table/multitask-table.module';
import { NavItemButtonComponent } from './nav-item-button/nav-item-button.component';
import { NavItemMenuComponent } from './nav-item-menu/nav-item-menu.component';
import { NgUpgradeModule } from './ng-upgrade';
import { AdvancedConfigItemComponent, AdvancedConfigListComponent } from './nosql';
import { OracleNodeSettingsComponent } from './oracle/components/oracle-node-settings/oracle-node-settings.component';
import { PluggableDbListComponent } from './oracle/components/pluggable-db-list/pluggable-db-list.component';
import { PageDialogModule } from './page-dialog';
import { PipesModule } from './pipes';
import { PolicyIllustrationComponent, PolicySummaryComponent, PolicySynopsisComponent } from './policy';
import { PulseModule } from './pulse';
import { PulseEntityComponent } from './pulse-entity/pulse-entity.component';
import { PulseStatusComponent } from './pulse-status/pulse-status.component';
import { RangeSliderComponent } from './range-slider/range-slider.component';
import { SettingsListReaderWriterComponent } from './reader-writer/settings-list-reader-writer.component';
import { RoleSelectComponent } from './role-select/role-select.component';
import { RouterTabsModule } from './router-tabs/router-tabs.module';
import { ShowHideEditControlModule } from './show-hide-edit-control';
import { SourceHealthChecksModule } from './source-health-checks/source-health-checks.module';
import { SourceMetaModule } from './source-meta';
import { SplitButtonComponent } from './split-button/split-button.component';
import { SqlProtectionModule } from './sql/sql-protection.module';
import { StatListComponent } from './stat-list/stat-list.component';
import { StatusIconContainerComponent } from './status-icon-container/status-icon-container.component';
import { StatusIconComponent } from './status-icon/status-icon.component';
import { SupportUserComponent } from './support-user/components/main/support-user.component';
import {
  PasswordRulesPopoverComponent,
} from './support-user/components/password-rules-popover-component/password-rules-popover.component';
import {
  UpdateLinuxUserPasswordDialogComponent,
} from './support-user/components/update-password-dialog/update-password-dialog.component';
import { TableActionsComponent } from './table-actions/table-actions.component';
import { TableModule } from './table/table.module';
import { TenantSwitchComponent } from './tenant-switch/tenant-switch.component';
import { TextInputListItemComponent } from './text-input-list/text-input-list-item/text-input-list-item.component';
import { TextInputListComponent } from './text-input-list/text-input-list.component';
import { TimeFormatPickerModule } from './time-format-picker/time-format-picker.module';
import { TimeframeSelectorComponent } from './timeframe-selector/timeframe-selector.component';
import { TitleSelectorComponent } from './title-selector/title-selector.component';
import { ToggleMessengerComponent } from './toggle-messenger/toggle-messenger.component';
import { UploadButtonComponent } from './upload-button/upload-button.component';

// Provide additional highcharts modules for use in coh-sunburst-chart.
// The AOT compiler does not support function expressions and arrow functions,
// also called lambda functions.
// https://angular.io/guide/aot-compiler#no-arrow-functions
export function highchartsModulesFactory(): any[] {
  // Default imports from mocked jest files get loaded slightly differently than they do from within angular
  // The code below checks for the presence of a default export and uses that first, and falls back to the
  // module itself otherwise.
  return [
    highchartsAccessibility.default || highchartsAccessibility,
    highchartsMore.default || highchartsMore,
    highchartsSolidGauge.default || highchartsSolidGauge,
    highchartsSunburst.default || highchartsSunburst,
    highchartsTimeline.default || highchartsTimeline,
    highchartsSankey.default || highchartsSankey,
  ];
}

const COMPONENTS = [
  AdvancedConfigItemComponent,
  AdvancedConfigListComponent,
  AuthAppComponent,
  BoardSelectComponent,
  BytesSizeSelectorComponent,
  ClusterSelectorComponent,
  ConfirmDialogComponent,
  ConnectionStatusComponent,
  DmsRegisterSourceComponent,
  GlanceBarComponent,
  LoginBannerComponent,
  McmViewComponent,
  NavItemButtonComponent,
  NavItemMenuComponent,
  OracleNodeSettingsComponent,
  PasswordRulesPopoverComponent,
  PluggableDbListComponent,
  PolicySummaryComponent,
  PolicySynopsisComponent,
  PolicyIllustrationComponent,
  PulseEntityComponent,
  PulseStatusComponent,
  RangeSliderComponent,
  RoleSelectComponent,
  SettingsListReaderWriterComponent,
  SplitButtonComponent,
  StatListComponent,
  StatlistMultiSeriesChart,
  StatusIconComponent,
  StatusIconContainerComponent,
  SupportUserComponent,
  TableActionsComponent,
  TenantSwitchComponent,
  TextInputListComponent,
  TextInputListItemComponent,
  TimeframeSelectorComponent,
  TitleSelectorComponent,
  ToggleMessengerComponent,
  UpdateLinuxUserPasswordDialogComponent,
  UploadButtonComponent,
];

const IMPORT_EXPORT_MODULES = [
  ActionButtonsModule,
  BannerConfigsModule,
  ClipboardCtaModule,
  ClipboardModule,
  CohesityHelixModule,
  CommonFiltersModule,
  CommonModule,
  DashcardsModule,
  DateFormatPickerModule,
  DialogsModule,
  FormsModule,
  HighchartsChartModule,
  InternalDnsEntryModule,
  IrisContextModule,
  LocalizationModule,
  MatSliderModule,
  MultitaskTableModule,
  NgxMatSelectSearchModule,
  NgUpgradeModule,
  PageDialogModule,
  PipesModule,
  PulseModule,
  QRCodeModule,
  RouterTabsModule,
  ShowHideEditControlModule,
  SourceDetailsModule,
  SourceHealthChecksModule,
  SourceMetaModule,
  SqlProtectionModule,
  TableModule,
  TenantColumnModule,
  TimeFormatPickerModule,
  UIRouterModule,
];

/**
 * Shared Module.
 */
@NgModule({
  providers: [
    DecimalPipe,
    {
      provide: HIGHCHARTS_MODULES,
      useFactory: highchartsModulesFactory,
    },
    OnlyOwnProvider,
  ],
  imports: [
    ...IMPORT_EXPORT_MODULES,
    TranslateModule.forChild(),
  ],
  declarations: [
    ...DIRECTIVES,
    ...COMPONENTS,
  ],
  exports: [
    ...DIRECTIVES,
    ...COMPONENTS,
    ...IMPORT_EXPORT_MODULES,
    AngularMaterialModule,
    TranslateModule,
  ],
})
export class SharedModule {}

// AJS Downgrade
declare const angular: any;
angular
  .module('C')
  .directive('cohBoardSelect', downgradeComponent({
    component: BoardSelectComponent,
  }))
  .directive('ngSpinner', downgradeComponent({
    component: SpinnerComponent,
  }))
  // Move SupportUserComponent and UpdateLinuxUserPasswordDialogComponent
  // to lazy loaded module when there is no longer any need for downgrade of this component.
  .directive('cohSupportUser', downgradeComponent({
    component: SupportUserComponent,
  }));
