import { Component, Input } from '@angular/core';
import { ActiveDirectoryServiceApi } from '@cohesity/api/v2';
import { FiltersComponent, ValueFilterSelection } from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseObjectFilterComponent } from './../base-object-filter';

@Component({
  selector: 'coh-domain-filter',
  templateUrl: './domain-filter.component.html',
})
export class DomainFilterComponent extends BaseObjectFilterComponent<any> {
  /**
   * The name of the property containing the active directory domain.
   */
  @Input() property = 'domain';

   /**
    * Specify whether allow multiple selection or not.
    */
  @Input() allowMultiple = false;

  constructor(
    public activeDirectoryService: ActiveDirectoryServiceApi,
    filters: FiltersComponent,
    public translateService: TranslateService
  ) {
    super(filters);
  }

  /**
   * Fetch active directories when the component initializes or the params change.
   */
  getFilterValues(): Observable<ValueFilterSelection[]> {
    const params = this.requestParams || {};
    return this.activeDirectoryService.GetActiveDirectory(params).pipe(
      map(ads => [
        {
          label: 'LOCAL',
          value: 'LOCAL',
        },
        ...(ads?.activeDirectories || [])
          .map(ad => ([
            ad,
            ...(ad.trustedDomainParams?.trustedDomains || []),
          ]))
          .flat()
          .map(domain => ({
            label: domain.domainName,
            value: domain.domainName,
          })),
      ])
    );
  }
}
