// Component: Clone Button

;(function(angular, undefined) {

  angular.module('C.cloneButton', ['C.contextMenu'])
    .component('cCloneButton', {
      templateUrl: 'app/global/c-clone-button/c-clone-button.html',
      controller: cloneButtonFn,
      bindings: {
        // button label
        label: '<?',
      },
    });

  function cloneButtonFn($attrs, SHARED_CLASSNAME, NavStateService) {
    var $ctrl = this;

    $ctrl.$onInit = function onInit() {
      $ctrl.label = $ctrl.label || 'clone';

      // add a common class on this component which is helpful to add
      // separator margin between subsequest similar/same components
      $attrs.$addClass(SHARED_CLASSNAME.contextBtn);

      $ctrl.cloneTypeItems = NavStateService.getCloneStates();
    }
  }
})(angular);
