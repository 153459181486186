import { Cluster } from '@cohesity/api/v1';
import { StorageDomain } from '@cohesity/api/v2';

/**
 * Customized cluster information object.
 */
export interface ClusterInfo extends Cluster {
  /**
   * Specifies whether it's virtual edition cluster or not.
   */
  _isVirtualEditionCluster: boolean;

  /**
   * Specifies failure domain number.
   */
  _failureDomainCount: number;
}

/**
 * Customized storage domain object.
 */
export interface StorageDomainInfo extends StorageDomain {
  _physicalUsed: number;
  _physicalQuota: number;
  _logicalManaged: number;
  _deduplication: string;
  _compression: string;
  _encryption: string;
  _cloudTier: string;
}

export interface StorageDomainFormModel {
  /**
   * Specifies the authentication provider.
   */
  authenticationProvider: AuthenticationProvider;

  /**
   * Specifies whether compression is enabled or not.
   */
  compressionEnabled: boolean;

  /**
   * Specifies whether inline compression is enabled or not.
   */
  compressionInlineEnabled?: boolean;

  /**
   * Specifies the cluster partition id of the Storage Domain.
   */
  clusterPartitionId: number;

  /**
   * Specifies the cluster partition name of the Storage Domain.
   */
  clusterPartitionName?: string;

  /**
   * Specifies whether deduplication is enabled or not.
   */
  deduplicationEnabled: boolean;

  /**
   * Specifies whether inline dedup is enabled or not.
   */
  deduplicationInlineEnabled?: boolean;

  /**
   * Specify encryption information.
   */
  encryption?: Encryption;

  /**
   * Specify the Storage Domain name.
   */
  name: string;

  /**
   * Specifies the Storage Domain id.
   */
  id: number;

  /**
   * Specify storage domain quota.
   */
  storageDomainQuotaSettings?: QuotaSettings;

  /**
   * Specify view quota.
   */
  viewQuotaSettings?: QuotaSettings;

  /**
   * Specify cloud tier settings.
   */
  cloudTierSettings?: CloudTierSettings;

  /**
   * Specify fault tolerance and redundancy settings.
   */
  faultToleranceSettings?: FaultToleranceSettings;
}

export interface FaultToleranceSettings {
  /**
   * Specify erasure coding is enabled or not.
   */
  erasureCodingEnabled?: boolean;

  /**
   * Specify whether inline erasure coding is enabled.
   * This field is applicable only if erasureCodingEnabled is set to true.
   */
  erasureCodingInlineEnabled?: boolean;

  /**
   * ErasureCoding string. Only used for form control.
   */
  erasureCoding?: string;

  /**
   * Specify the number of disk failures to tolerate for a Storage Domain.
   * By default, this field is 1 for cluster with three or more nodes.
   * If erasure coding is enabled, this field will be the same as numCodedStripes.
   */
  numDiskFailuresTolerated?: number;

  /**
   * Specifies the number of domain failures to tolerate for a Storage Domain.
   * By default this field is replication factor minus 1 for replication chunk files
   * and is the same as numCodedStripes for erasure coding chunk files.
   */
  numDomainFailuresTolerated?: number;

  /**
   * Specify the number of coded stripes.
   */
  numCodedStripes?: number;

  /**
   * Specify the number of data stripes.
   */
  numDataStripes?: number;
}

export interface QuotaSettings {
  /**
   * Specify when the usage goes above an alert threshold percentage.
   */
  alertPercentage?: number;

  /**
   * Specify a quota limit on the usage allowed for the resource.
   */
  quotaLimit?: number;

  /**
   * Specify whether quota  alert is enabled or not.
   */
  quotaAlertEnabled?: boolean;

  /**
   * Specify whether quota is enabled or not.
   */
  quotaEnabled?: boolean;
}

export interface CloudTierSettings {
  /**
   * Specify whether cloud tier is enabled or not.
   */
  cloudTierEnabled?: boolean;

  /**
   * External target id.
   */
  externalTargetId?: number;

  /**
   * Specify a time in seconds when cloud down water fall starts.
   */
  thresholdTime?: number;

  /**
   * Specify whether storage domain space utilization threshold is enabled or not.
   */
  spaceUtilizationThresholdEnabled?: boolean;

  /**
   * Specify the threshold percentage for cloud down water fall.
   */
  spaceUtilizationThresholdPercentage?: number;
}

export interface AuthenticationProvider {
  /**
   * Authentication type.
   */
  authenticationProviderType: 'none' | 'linkedProviders' | 'activeDirectoryProvider' | 'ldapProvider';

  /**
   * ID of ldap provider.
   */
  ldapProviderId?: number;

  /**
   * Name of active directory provider.
   */
  adDomainName?: string;

  /**
   * Linked provider.
   */
  linkedProvider?: string;
}

/**
 * KMS Service Types.
 */
export enum KmsServiceTypes {
  kInternalKMS = 'kInternalKMS',
  kCryptsoftKMS = 'kCryptsoftKMS',
  kAwsKMS = 'kAwsKMS',
  kAzureKMS = 'kAzureKMS',
}

export interface Encryption {
  /**
   * Specify whether encryption is enabled or not.
   */
  encryptionEnabled: boolean;

  /**
   * KMS service type.
   */
  kmsType?: null | KmsServiceTypes;

  /**
   * KMS service id.
   */
  kmsServiceId?: number;
}

export interface LinkedProvider {
  /**
   * Name of active directory provider.
   */
  adName: string;

  /**
   * ID of active directory provider.
   */
  adId: number;

  /**
   * Name of ldap provider.
   */
  ldapName: string;

  /**
   * ID of ldap provider.
   */
  ldapId: number;
}
