/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiAlertServerConfiguration as __Configuration } from '../cohesity-api-alert-server-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Alert } from '../models/alert';
import { FireAlertArgs } from '../models/fire-alert-args';
import { UpdateAlertStateArgs } from '../models/update-alert-state-args';

/**
 * Alerts persisted in the system
 */
@Injectable({
  providedIn: 'root',
})
class AlertsService extends __BaseService {
  static readonly GetAlertsPath = '/alerts';
  static readonly FireAlertPath = '/alerts';
  static readonly UpdateAlertStatePath = '/alerts/{id}/state';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List the Alerts on the Cohesity Cluster
   *
   * Multiple status values can be provided with comma separated strings
   * @param params The `AlertsService.GetAlertsParams` containing the following parameters:
   *
   * - `maxAlerts`: Specifies the maximum number of latest Alerts to be returned.
   *
   * - `accountId`: Current Account Id.
   *
   * - `tenantIds`: TenantId contains ids of the current tenant for which objects are to be returned.
   *
   * - `startTimeUsecs`: Specifies start time Unix epoch time in microseconds to filter alerts by.
   *
   * - `endTimeUsecs`: Specifies end time Unix epoch time in microseconds to filter alerts by.
   *
   * - `clusterIds`: ClusterId contains ids of the current tenant for which objects are to be returned.
   *
   * - `alertTypeList`: Specifies list of Alert Type Ids to filter alerts.
   *
   * - `alertTypeBucketList`: Specifies list of Alert type bucket to filter alerts.
   *
   * - `alertStateList`: Specifies list of Alert States to filter alerts.
   *
   * - `alertSeverityList`: Specifies list of Alert severity to filter alerts.
   *
   * - `alertPropertyValueList`: Specifies list of the alert property value, multiple values for one key should be joined by '|'
   *
   * - `alertPropertyKeyList`: Specifies list of the alert property keys to be query.
   *
   * - `alertNameList`: Specifies list of Alert names to filter alerts.
   *
   * - `alertIdList`: Specifies list of Alert ids to filter alerts.
   *
   * - `alertCategoryList`: Specifies list of Alert Categories.
   *
   * @return Success
   */
  GetAlertsResponse(params: AlertsService.GetAlertsParams): __Observable<__StrictHttpResponse<Array<Alert>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.maxAlerts != null) __params = __params.set('maxAlerts', params.maxAlerts.toString());
    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    (params.clusterIds || []).forEach(val => {if (val != null) __params = __params.append('clusterIds', val.toString())});
    (params.alertTypeList || []).forEach(val => {if (val != null) __params = __params.append('alertTypeList', val.toString())});
    (params.alertTypeBucketList || []).forEach(val => {if (val != null) __params = __params.append('alertTypeBucketList', val.toString())});
    (params.alertStateList || []).forEach(val => {if (val != null) __params = __params.append('alertStateList', val.toString())});
    (params.alertSeverityList || []).forEach(val => {if (val != null) __params = __params.append('alertSeverityList', val.toString())});
    (params.alertPropertyValueList || []).forEach(val => {if (val != null) __params = __params.append('alertPropertyValueList', val.toString())});
    (params.alertPropertyKeyList || []).forEach(val => {if (val != null) __params = __params.append('alertPropertyKeyList', val.toString())});
    (params.alertNameList || []).forEach(val => {if (val != null) __params = __params.append('alertNameList', val.toString())});
    (params.alertIdList || []).forEach(val => {if (val != null) __params = __params.append('alertIdList', val.toString())});
    (params.alertCategoryList || []).forEach(val => {if (val != null) __params = __params.append('alertCategoryList', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/alerts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Alert>>;
      })
    );
  }
  /**
   * List the Alerts on the Cohesity Cluster
   *
   * Multiple status values can be provided with comma separated strings
   * @param params The `AlertsService.GetAlertsParams` containing the following parameters:
   *
   * - `maxAlerts`: Specifies the maximum number of latest Alerts to be returned.
   *
   * - `accountId`: Current Account Id.
   *
   * - `tenantIds`: TenantId contains ids of the current tenant for which objects are to be returned.
   *
   * - `startTimeUsecs`: Specifies start time Unix epoch time in microseconds to filter alerts by.
   *
   * - `endTimeUsecs`: Specifies end time Unix epoch time in microseconds to filter alerts by.
   *
   * - `clusterIds`: ClusterId contains ids of the current tenant for which objects are to be returned.
   *
   * - `alertTypeList`: Specifies list of Alert Type Ids to filter alerts.
   *
   * - `alertTypeBucketList`: Specifies list of Alert type bucket to filter alerts.
   *
   * - `alertStateList`: Specifies list of Alert States to filter alerts.
   *
   * - `alertSeverityList`: Specifies list of Alert severity to filter alerts.
   *
   * - `alertPropertyValueList`: Specifies list of the alert property value, multiple values for one key should be joined by '|'
   *
   * - `alertPropertyKeyList`: Specifies list of the alert property keys to be query.
   *
   * - `alertNameList`: Specifies list of Alert names to filter alerts.
   *
   * - `alertIdList`: Specifies list of Alert ids to filter alerts.
   *
   * - `alertCategoryList`: Specifies list of Alert Categories.
   *
   * @return Success
   */
  GetAlerts(params: AlertsService.GetAlertsParams): __Observable<Array<Alert>> {
    return this.GetAlertsResponse(params).pipe(
      __map(_r => _r.body as Array<Alert>)
    );
  }

  /**
   * Fire an alert
   *
   * Fire an alert. If a new duplicate alert is raised after suppressed time the suppressed state should change to open, else the new duplicate alert should be ignored.
   * @param body Arguments to fire an alert.
   */
  FireAlertResponse(body: FireAlertArgs): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/alerts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Fire an alert
   *
   * Fire an alert. If a new duplicate alert is raised after suppressed time the suppressed state should change to open, else the new duplicate alert should be ignored.
   * @param body Arguments to fire an alert.
   */
  FireAlert(body: FireAlertArgs): __Observable<null> {
    return this.FireAlertResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update alert state
   *
   * Update an alert state
   * @param params The `AlertsService.UpdateAlertStateParams` containing the following parameters:
   *
   * - `id`: Specifies the unique alert id.
   *
   * - `body`: Arguments to update an alert state.
   */
  UpdateAlertStateResponse(params: AlertsService.UpdateAlertStateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/alerts/${encodeURIComponent(params.id)}/state`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update alert state
   *
   * Update an alert state
   * @param params The `AlertsService.UpdateAlertStateParams` containing the following parameters:
   *
   * - `id`: Specifies the unique alert id.
   *
   * - `body`: Arguments to update an alert state.
   */
  UpdateAlertState(params: AlertsService.UpdateAlertStateParams): __Observable<null> {
    return this.UpdateAlertStateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module AlertsService {

  /**
   * Parameters for GetAlerts
   */
  export interface GetAlertsParams {

    /**
     * Specifies the maximum number of latest Alerts to be returned.
     */
    maxAlerts: number;

    /**
     * Current Account Id.
     */
    accountId: string;

    /**
     * TenantId contains ids of the current tenant for which objects are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * Specifies start time Unix epoch time in microseconds to filter alerts by.
     */
    startTimeUsecs?: number;

    /**
     * Specifies end time Unix epoch time in microseconds to filter alerts by.
     */
    endTimeUsecs?: number;

    /**
     * ClusterId contains ids of the current tenant for which objects are to be returned.
     */
    clusterIds?: Array<number>;

    /**
     * Specifies list of Alert Type Ids to filter alerts.
     */
    alertTypeList?: Array<number>;

    /**
     * Specifies list of Alert type bucket to filter alerts.
     */
    alertTypeBucketList?: Array<'DataService' | 'Maintenance' | 'Software' | 'Hardware'>;

    /**
     * Specifies list of Alert States to filter alerts.
     */
    alertStateList?: Array<'Open' | 'Resolved' | 'Note' | 'Suppressed'>;

    /**
     * Specifies list of Alert severity to filter alerts.
     */
    alertSeverityList?: Array<'Critical' | 'Warning' | 'Info'>;

    /**
     * Specifies list of the alert property value, multiple values for one key should be joined by '|'
     */
    alertPropertyValueList?: Array<string>;

    /**
     * Specifies list of the alert property keys to be query.
     */
    alertPropertyKeyList?: Array<string>;

    /**
     * Specifies list of Alert names to filter alerts.
     */
    alertNameList?: Array<string>;

    /**
     * Specifies list of Alert ids to filter alerts.
     */
    alertIdList?: Array<number>;

    /**
     * Specifies list of Alert Categories.
     */
    alertCategoryList?: Array<string>;
  }

  /**
   * Parameters for UpdateAlertState
   */
  export interface UpdateAlertStateParams {

    /**
     * Specifies the unique alert id.
     */
    id: string;

    /**
     * Arguments to update an alert state.
     */
    body: UpdateAlertStateArgs;
  }
}

export { AlertsService }
