import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { downgradeComponent } from '@angular/upgrade/static';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { AngularMaterialModule, CohesityHelixModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';
import { DnsVipsDetailsComponent } from './dns-vips-details/dns-vips-details.component';
import { DnsVipsEntryDialogComponent } from './dns-vips-entry-dialog/dns-vips-entry-dialog.component';
import { DnsVipsEntryListComponent } from './dns-vips-entry-list/dns-vips-entry-list.component';
import { DnsVipsEntryComponent } from './dns-vips-entry/dns-vips-entry.component';

// AJS Downgrade
declare const angular: any;
angular
  .module('C')
  .directive('cohDnsVipsEntryList', downgradeComponent({
    component: DnsVipsEntryListComponent,
    inputs: ['model', 'ipPreference'],
  }));

const COMPONENTS = [
  DnsVipsEntryListComponent,
  DnsVipsEntryComponent,
  DnsVipsDetailsComponent,
  DnsVipsEntryDialogComponent
];

@NgModule({
  imports: [
    AngularMaterialModule,
    CohesityHelixModule,
    CommonModule,
    FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    MatSelectModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    TranslateModule.forChild(),
  ],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})

export class InternalDnsEntryModule { }
