// Component: Upload/Download License

;(function(angular) {
  'use strict';

  angular
    .module('C.licenseServer')
    .controller('UploadDownloadModalCtrl', UploadDownloadModalCtrlFn)
    .component('uploadDownloadModal', {
      templateUrl: 'app/license/upload-download-modal.html',
      controller: 'UploadDownloadModalCtrl',
      require: {
        cModalHoc: '^^?cModalHoc',
      },
    });

  function UploadDownloadModalCtrlFn(LicenseServerService, $rootScope, API,
    cMessage, ClusterService) {
    var $ctrl = this;

    $ctrl.isHelios = $rootScope.basicClusterInfo.mcmMode;

    //config to be provided to c-upload component
    $ctrl.config = {
      confirmBtnKey: 'submit',
      cancelBtnKey: 'cancel',
      headers: $rootScope.basicClusterInfo.mcmMode ?
        { download: false } : undefined,
      confirmCallback: fileUploadedConfirmation,
      cancelCb: cancelFileUpload,
      download: LicenseServerService.downloadAuditReport,
      icon: 'icn-license-file',
    };

    [$ctrl.config.heading, $ctrl.config.url] =
      !$ctrl.isHelios ? ['uploadLicenseFile', API.private('nexus/license/upload')] :
        ['uploadAuditReport',
          '/mcm/minos/licensing/v1/cluster/upload_audit_report'];

    /**
     * function to be called when file is uploaded successfully
     *
     * @param {object} response
     * {
     *   clusterId: {integer},
     *   generatedOn: {epoch time},
     *   validTill: {epoch ti},
     *   LicenseProto: null,
     * }
     *
     * when user is uploading audit report (on helios), he is supposed to get
     * license file detail in response
     *
     * when user is uploading license file (on cluster), no response is
     * required
     */
    function fileUploadedConfirmation(response) {
      // on helios, upload audit file and show response.
      // on cluster, upload license file close the modal and
      // show success message
      if ($ctrl.isHelios) {
        $ctrl.license = response;
        $ctrl.license.downloadLicenseCb = function downloadLicenseCb() {
          LicenseServerService.downloadLicenseFile($ctrl.license.clusterId);
        };
      } else {
        if (ClusterService.clusterInfo.licenseState.state !== 'kClaimed') {
          ClusterService.updateLicenseState('claimed');
          cMessage.success({
            titleKey: 'licenseActivated',
            textKey: 'clusterConnect.darkSite.licenseUploadSuccessMessage',
          });
        } else {
          cMessage.success({
            textKey: 'license.uploadSuccessfully',
          });
        };
        $ctrl.cModalHoc.close();
      }
    }

    /**
     * close the modal
     *
     * @method cancelFileUpload
     */
    function cancelFileUpload() {
      $ctrl.cModalHoc.close();
    }
  }
})(angular);
