import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input,
  ContentChild,
  TemplateRef
} from '@angular/core';
import { MomentDatePipe } from '@cohesity/helix';
import { ClusterService } from 'src/app/core/services/cluster.service';
import { LocaleService } from 'src/app/core/services';
import { RestorePointSelection } from '../../model/restore-point-selection';
import { RestoreSearchResult } from '../../model/restore-search-result';

/**
 * This component renders the selection panel for recoveries that use a single
 * restore point with multiple items grouped together. It is similar to
 * RestorePointSelectionDetailComponent, but where that component works with a list
 * of RestorePointSelections, this works with a single RestorePointSelection and a
 * list of items associated with it.
 *
 * This component is generally used with the object search form component:
 *
 * @example
 *
 * <coh-object-search-form cohFormSectionEdit ...
 *   [selectionListTemplate]="selectionListTemplate">
 * ...
 *  <ng-template #selectionListTemplate>
 *   <coh-restore-point-grouped-selection-detail *ngIf="selectionCount > 0" [restorePointSelection]="restorePoint"
 *     [selectedObjects]="fileSelection.files"
 *     (editRestorePoint)="editSnapshot(restorePoint)"
 *     (deselectObject)="deselectObject($event)">
 *     <!-- This is a template to render each indivual selected file -->
 *     <ng-template let-file>
 *       <cog-icon class="margin-right-sm"
 *         [shape]="getObjectIcon(file)"></cog-icon>
 *       <div class="mat-body user-data-lg">{{file.name}}</div>
 *     </ng-template>
 *   </coh-restore-point-grouped-selection-detail>
 * </ng-template>
 */
@Component({
  selector: 'coh-restore-point-grouped-selection-detail',
  templateUrl: './restore-point-grouped-selection-detail.component.html',
  styleUrls: ['./restore-point-grouped-selection-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MomentDatePipe],
})
export class RestorePointGroupedSelectionDetailComponent {

  /**
   * Set this to false to disable editing a restore point.
   */
  @Input() allowEdit = true;

  /**
   * Event is emitted when the user chooses to edit the restore point. The edit button
   * will only be visible if an event handler is attached to the component.
   */
  @Output() editRestorePoint = new EventEmitter();

  /**
   * The currently selected restore point selection
   */
  @Input() restorePointSelection: RestorePointSelection;

  /**
   * The selected objects associated with the restore point selection
   */
  @Input() selectedObjects: RestoreSearchResult[];

  /**
   * This event is emitted when a user remove an object from the selection
   * by clicking the x inside of the list.
   */
  @Output() deselectObject = new EventEmitter<RestoreSearchResult>();

  /**
   * Indicates if snapshot selection will be shown or hidden
   */
  @Input() hideSnapshot = false;

  /**
   * This component expects a template to be passed via ng-content that can be used
   * to render a mat-list-item for each object. The template context will contain
   * the object.
   *
   * @example
   *  <coh-restore-point-grouped-selection-detail
   *     <!-- This is a template to render each indivual selected file -->
   *     <ng-template let-file>
   *       <cog-icon class="margin-right-sm"
   *         [shape]="getObjectIcon(file)"></cog-icon>
   *       <div class="mat-body user-data-lg">{{file.name}}</div>
   *     </ng-template>
   *   </coh-restore-point-grouped-selection-detail>
   */
  @ContentChild(TemplateRef, { static: true}) detailTemplate: TemplateRef<any>;

  constructor(
    private clusterInfo: ClusterService,
    private localeService: LocaleService,
    private momentDatePipe: MomentDatePipe,
  ) {}

  /**
   * Gets the icon to display for the selected snapshot target
   */
  get typeIcon(): string {
    return this.restorePointSelection.archiveTargetInfo
      ? this.restorePointSelection.archiveTargetInfo.targetType
      : 'local';
  }

  /**
   * Whether to show the edit button or not.
   */
  get showEdit(): boolean {
    return this.allowEdit &&  !!this.editRestorePoint.observers.length;
  }

  /**
   * Return true if cluster is NGCE.
   */
  get cloudEditionEnabled(): boolean {
    return this.clusterInfo.isClusterNGCE;
  }

  /**
   * Formats the recovery point time to a format suitable for display.
   * Non CDP/PITR jobs show time upto 'minute' granularity. For CDP jobs, seconds are
   * also shown.
   *
   * @timestampUsecs    Recovery point timestamp.
   * @returns           Formatted time string for display.
   */
  formatRecoveryPointTime(timestampUsecs: number): string {
    return this.restorePointSelection?.isPointInTime ?
      this.momentDatePipe.transform(timestampUsecs, `#${this.localeService.dateLocale.fullDateTimeFormat12hour}`) :
      this.momentDatePipe.transform(timestampUsecs);
  }
}
