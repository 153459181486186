import { Component, Input } from '@angular/core';
import { CouchbaseConnectParams, ProtectionSourceTreeInfo } from '@cohesity/api/v1';
import { get } from 'lodash';

/**
 * Couchbase source details settings components. Used for displaying the
 * registered Couchbase source's configuration values.
 */
@Component({
  selector: 'coh-couchbase-source-details-settings',
  templateUrl: './source-details-settings.component.html'
})
export class CouchbaseSourceDetailsSettingsComponent {

  /**
   * Input source.
   */
  @Input() source: ProtectionSourceTreeInfo;

  /**
   * Getter for retrieving CouchbaseParams from source input.
   */
  get couchbaseParams(): CouchbaseConnectParams {
    return get(this.source, 'registrationInfo.couchbaseParams') as CouchbaseConnectParams;
  }
}
