import { AppModuleConfig, CanAccess, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const clusterConfigAccess: CanAccess = (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.importExportEnabled;

export const ImportExportConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'cluster-config-backup.**',
      url: '^/cluster-config/backup',
      loadChildren: () => import('./import-export.module').then(m => m.ImportExportModule)
    },
    {
      name: 'cluster-config-restore.**',
      url: '^/cluster-config/restore',
      loadChildren: () => import('./import-export.module').then(m => m.ImportExportModule)
    },
    {
      name: 'cluster-config-detail.**',
      url: '^/cluster-config/detail',
      loadChildren: () => import('./import-export.module').then(m => m.ImportExportModule)
    },
  ],

  allClusterMap: {
    'cluster-config-backup': {
      globalContext: true,
    },
    'cluster-config-restore': {
      globalContext: true,
    },
    'cluster-config-detail': {
      globalContext: true,
    },
    'cluster-config-detail.home': {
      globalContext: true,
    },
    'cluster-config-detail.activity': {
      globalContext: true,
    },
  },

  getStateAccessMap(): StateAccessMap {
    return {
      'cluster-config-backup': clusterConfigAccess,
      'cluster-config-restore': clusterConfigAccess,
      'cluster-config-detail': clusterConfigAccess,
      'cluster-config-detail.home': clusterConfigAccess,
      'cluster-config-detail.activity': clusterConfigAccess,
    };
  }
};
