// Module: Policy Manager

;(function(angular) {
  'use strict';

  angular.module('C.policies', ['C.policyService', 'C.jobRunsService',
    'C.focus'])
    .config(policiesModuleConfigFn);

  /**
   * policy module configuration
   */
  function policiesModuleConfigFn($stateProvider) {
    var globalPoliciesViewAccess = [
      'basicClusterInfo.mcmMode',
      'PROTECTION_POLICY_VIEW',
      'FEATURE_FLAGS.globalPoliciesEnabled'
    ].join(' && ');

    $stateProvider
      .state('policies', {
        url: '/protection/policies',
        help: 'protection_policies',
        title: 'Policy Manager',
        canAccess: 'PROTECTION_POLICY_VIEW',
        allClustersSupport: {
          singleClusterState: 'policies',
          allClustersState: 'global-policies',
        },
        template: '<policies />',
      })
      .state('global-policies', {
        url: '^/protection/global-policies',
        title: 'Policy Template Manager',
        canAccess: globalPoliciesViewAccess,
        parentState: 'policies',
        allClustersSupport: {
          heliosOnly: true,
          singleClusterState: 'policies',
          allClustersState: 'global-policies',
        },
        component: 'globalPolicies',
      })
      .state('policy', {
        url: '^/protection/policies/policy/{policyId}',
        help: 'protection_policies_policy',
        title: 'Policy Details',
        canAccess: 'PROTECTION_POLICY_VIEW',
        params: {
          policyId: { type: 'string' },
        },
        parentState: 'policies',
        templateUrl: 'app/protection/policies/policy/policy.html',
        controller: 'policyController',
      })
      .state('policy-modify', {
        url: '^/protection/policies/modify/{policyId}',
        help: 'protection_policies_new_options',
        title: 'Modify Policy',
        canAccess: 'PROTECTION_POLICY_MODIFY',
        parentState: 'policies',
        params: {
          policyId: { type: 'string', value: '' },

          // if user is making a copy of a policy, this param will be passed.
          copyPolicy: undefined,
          isGlobalPolicy: false,
        },
        templateUrl: 'app/protection/policies/modify/modify.html',
        controller: 'policyModifyController',
      });
  }

}(angular));
