import { Injectable } from '@angular/core';
import {
  HeliosPoliciesResponseWithPagination,
  HeliosPolicyRequest,
  HeliosPolicyResponse,
  PolicyServiceApi,
  ProtectionPolicyRequest,
  ProtectionPolicyResponse,
  ProtectionPolicyResponseWithPagination,
} from '@cohesity/api/v2';
import { flagEnabled, IrisContextService, isAllClustersScope, isDmsScope, isDraasScope, isMcm } from '@cohesity/iris-core';
import { Observable } from 'rxjs';
import { PassthroughOptionsService } from 'src/app/core/services';

/**
 * Type for Policy Request.
 */
export type PolicyRequest = ProtectionPolicyRequest | HeliosPolicyRequest;

/**
 * Type for Policy Response.
 */
export type PolicyResponse = ProtectionPolicyResponse | HeliosPolicyResponse;

/**
 * Type for Policy Response With Pagination.
 */
export type PolicyResponseWithPagination =
  ProtectionPolicyResponseWithPagination | HeliosPoliciesResponseWithPagination;

/**
 * Policy Service to make respective API calls based on context.
 */
@Injectable({
  providedIn: 'root',
})
export class ProtectionPolicyService {

  /**
   * True if this is in Helios all cluster context.
   */
  get isAllCluster(): boolean {
    return isAllClustersScope(this.irisCtx.irisContext);
  }

  /**
   * Indicates if the current user is in DMaaS context.
   */
  get isDmsScope(): boolean {
    return isDmsScope(this.irisCtx.irisContext);
  }

  /**
   * Indicates if the current user is in DRaaS context.
   */
  get isDraasScope(): boolean {
    return isDraasScope(this.irisCtx.irisContext);
  }

  /**
   * Indicates if the current user is in MCM context.
   */
  get isMcm(): boolean {
    return isMcm(this.irisCtx.irisContext);
  }

  /**
   * True if policy template feature flag is enabled.
   */
  get policyTemplateEnabled(): boolean {
    return flagEnabled(this.irisCtx.irisContext, 'ngPolicyTemplate');
  }

  constructor(
    private irisCtx: IrisContextService,
    private passthroughOptionsService: PassthroughOptionsService,
    private policyService: PolicyServiceApi,
  ) {
  }

  /**
   * Creates new policy.
   *
   * @policy   Policy request payload.
   * @returns  Observable of policy response.
   */
  createPolicy(policy: PolicyRequest): Observable<PolicyResponse> {
    if (this.isAllCluster || this.isDmsScope || this.isDraasScope) {
      return this.policyService.CreateHeliosPolicy(policy as HeliosPolicyRequest);
    }

    return this.policyService.CreateProtectionPolicy({
      body: policy as ProtectionPolicyRequest,
      accessClusterId: this.passthroughOptionsService.accessClusterId,
    });
  }

  /**
   * Updates policy.
   *
   * @policy   Policy request payload.
   * @returns  Observable of policy response.
   */
  updatePolicy(policy: PolicyRequest): Observable<PolicyResponse> {
    if (this.isAllCluster || this.isDmsScope || this.isDraasScope) {
      return this.policyService.UpdateHeliosPolicy({
        id: (policy as any).id,
        body: policy as HeliosPolicyRequest
      });
    } else if (this.isMcm) {
      const heliosPolicy = policy as HeliosPolicyResponse;
      return this.policyService.UpdateHeliosPolicy({
        id: heliosPolicy.id,
        body: {
          ...heliosPolicy,
          type: 'OnPremPolicy',
        },
      });
    }
    return this.policyService.UpdateProtectionPolicy({
      id: (policy as any).id,
      body: policy as ProtectionPolicyRequest,
      accessClusterId: this.passthroughOptionsService.accessClusterId,
    });
  }

  /**
   * Gets list of policies.
   *
   * @returns  Observable of policy response with pagination.
   */
  getPolicies(accessClusterId?: number): Observable<PolicyResponseWithPagination> {
    if (!accessClusterId && (this.isAllCluster || this.isDmsScope || this.isDraasScope)) {
      return this.getHeliosPolicies();
    }

    return this.policyService.GetProtectionPolicies({
      excludeLinkedPolicies: !this.policyTemplateEnabled,
      accessClusterId,
    });
  }

  /**
   * Gets policy by id
   *
   * @returns  Observable of policy response
   */
  getPolicyById(id: string): Observable<PolicyResponse> {
    if (this.isAllCluster || this.isDmsScope || this.isDraasScope) {
      return this.policyService.GetHeliosPolicyById(id);
    }
    return this.policyService.GetProtectionPolicyById({
      id,
    });
  }

  /**
   * Gets list of helios policies.
   *
   * @params   params The API params to pass to backend
   * @returns  Observable of policy response with pagination.
   */
  getHeliosPolicies(
    params?: PolicyServiceApi.GetHeliosPoliciesParams
  ): Observable<HeliosPoliciesResponseWithPagination> {

    params = Object.assign({
      types: [(this.isDmsScope || this.isDraasScope) ? 'DMaaSPolicy' : 'OnPremPolicy']
    }, params || {});
    return this.policyService.GetHeliosPolicies(params);
  }
}
