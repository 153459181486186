import { Component } from '@angular/core';
import { Routes } from 'src/app/shared/constants';

/**
 * This component displays a list of cli download for different OS in a dialog
 * and allow users to download by clicking on the icon
 *
 * @example
 *   Simple Usage by MatDialog (inject MatDialog as this.dialog):
 *     this.dialog.open(DownloadCliDialogComponent);
 */
@Component({
  selector: 'coh-download-cli-dialog',
  styleUrls: ['./download-cli-dialog.component.scss'],
  templateUrl: './download-cli-dialog.component.html'
})
export class DownloadCliDialogComponent {
  /**
   * List of download files (name and URL) for different OS
   */
  downloadList = Object.keys(Routes.cliBinary).sort().reverse().map(key => ({
    name: `downloadCliModal.cliVersion.${key}`,
    url: Routes.cliBinary[key]
  }));
}
