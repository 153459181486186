/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { KerberosProviders } from '../models/kerberos-providers';
import { KerberosProvider } from '../models/kerberos-provider';
import { RegisterOrUpdateKerberosProviderRequest } from '../models/register-or-update-kerberos-provider-request';
import { UnregisterKerberosProvider } from '../models/unregister-kerberos-provider';
import { UnregisterKerberosRequest } from '../models/unregister-kerberos-request';
@Injectable({
  providedIn: 'root',
})
class KerberosProviderService extends __BaseService {
  static readonly GetKerberosProvidersPath = '/kerberos-providers';
  static readonly RegisterKerberosProviderPath = '/kerberos-providers/register';
  static readonly GetKerberosProviderByIdPath = '/kerberos-providers/{id}';
  static readonly UpdateKerberosProviderPath = '/kerberos-providers/{id}';
  static readonly UnregisterKerberosProviderPath = '/kerberos-providers/{id}/unregister';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get the list of Kerberos Providers.
   *
   * Get the list of Kerberos Authentication Providers.
   * @param params The `KerberosProviderService.GetKerberosProvidersParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `realmNames`: Filter by a list of realm names.
   *
   * - `kdcServers`: Filter by a list of KDC servers.
   *
   * - `ids`: Filter by a list of Kerberos Provider Ids.
   *
   * - `hasLDAP`: Filter by whether LDAP is associated with the provider.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetKerberosProvidersResponse(params: KerberosProviderService.GetKerberosProvidersParams): __Observable<__StrictHttpResponse<KerberosProviders>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.realmNames || []).forEach(val => {if (val != null) __params = __params.append('realmNames', val.toString())});
    (params.kdcServers || []).forEach(val => {if (val != null) __params = __params.append('kdcServers', val.toString())});
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.hasLDAP != null) __params = __params.set('hasLDAP', params.hasLDAP.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/kerberos-providers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<KerberosProviders>;
      })
    );
  }
  /**
   * Get the list of Kerberos Providers.
   *
   * Get the list of Kerberos Authentication Providers.
   * @param params The `KerberosProviderService.GetKerberosProvidersParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `realmNames`: Filter by a list of realm names.
   *
   * - `kdcServers`: Filter by a list of KDC servers.
   *
   * - `ids`: Filter by a list of Kerberos Provider Ids.
   *
   * - `hasLDAP`: Filter by whether LDAP is associated with the provider.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetKerberosProviders(params: KerberosProviderService.GetKerberosProvidersParams): __Observable<KerberosProviders> {
    return this.GetKerberosProvidersResponse(params).pipe(
      __map(_r => _r.body as KerberosProviders)
    );
  }

  /**
   * Register a Kerberos Authentication Provider.
   *
   * Register a Kerberos Authentication Provider.
   * @param params The `KerberosProviderService.RegisterKerberosProviderParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to Register a Kerberos Provider.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RegisterKerberosProviderResponse(params: KerberosProviderService.RegisterKerberosProviderParams): __Observable<__StrictHttpResponse<KerberosProvider>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/kerberos-providers/register`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<KerberosProvider>;
      })
    );
  }
  /**
   * Register a Kerberos Authentication Provider.
   *
   * Register a Kerberos Authentication Provider.
   * @param params The `KerberosProviderService.RegisterKerberosProviderParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to Register a Kerberos Provider.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RegisterKerberosProvider(params: KerberosProviderService.RegisterKerberosProviderParams): __Observable<KerberosProvider> {
    return this.RegisterKerberosProviderResponse(params).pipe(
      __map(_r => _r.body as KerberosProvider)
    );
  }

  /**
   * Get the Registered Kerberos Provider by id.
   *
   * Get the Registered Kerberos Provider by id.
   * @param params The `KerberosProviderService.GetKerberosProviderByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id which will be of the pattern
   *   cluster_id:clusterincarnation_id:resource_id.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetKerberosProviderByIdResponse(params: KerberosProviderService.GetKerberosProviderByIdParams): __Observable<__StrictHttpResponse<KerberosProvider>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/kerberos-providers/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<KerberosProvider>;
      })
    );
  }
  /**
   * Get the Registered Kerberos Provider by id.
   *
   * Get the Registered Kerberos Provider by id.
   * @param params The `KerberosProviderService.GetKerberosProviderByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id which will be of the pattern
   *   cluster_id:clusterincarnation_id:resource_id.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetKerberosProviderById(params: KerberosProviderService.GetKerberosProviderByIdParams): __Observable<KerberosProvider> {
    return this.GetKerberosProviderByIdResponse(params).pipe(
      __map(_r => _r.body as KerberosProvider)
    );
  }

  /**
   * Update the Kerberos Provider Registration.
   *
   * Update the Kerberos Provider Registration.
   * @param params The `KerberosProviderService.UpdateKerberosProviderParams` containing the following parameters:
   *
   * - `id`: Specifies the id which will be of the pattern
   *   cluster_id:clusterincarnation_id:resource_id.
   *
   * - `body`: Request to update a Kerberos Provider.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateKerberosProviderResponse(params: KerberosProviderService.UpdateKerberosProviderParams): __Observable<__StrictHttpResponse<KerberosProvider>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/kerberos-providers/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<KerberosProvider>;
      })
    );
  }
  /**
   * Update the Kerberos Provider Registration.
   *
   * Update the Kerberos Provider Registration.
   * @param params The `KerberosProviderService.UpdateKerberosProviderParams` containing the following parameters:
   *
   * - `id`: Specifies the id which will be of the pattern
   *   cluster_id:clusterincarnation_id:resource_id.
   *
   * - `body`: Request to update a Kerberos Provider.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateKerberosProvider(params: KerberosProviderService.UpdateKerberosProviderParams): __Observable<KerberosProvider> {
    return this.UpdateKerberosProviderResponse(params).pipe(
      __map(_r => _r.body as KerberosProvider)
    );
  }

  /**
   * Unregister a Kerberos Provider.
   *
   * Unregister a Kerberos Provider.
   * @param params The `KerberosProviderService.UnregisterKerberosProviderParams` containing the following parameters:
   *
   * - `id`: Specifies the id.
   *
   * - `body`: Request to unregister a Kerberos Provider.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UnregisterKerberosProviderResponse(params: KerberosProviderService.UnregisterKerberosProviderParams): __Observable<__StrictHttpResponse<UnregisterKerberosProvider>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/kerberos-providers/${encodeURIComponent(params.id)}/unregister`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UnregisterKerberosProvider>;
      })
    );
  }
  /**
   * Unregister a Kerberos Provider.
   *
   * Unregister a Kerberos Provider.
   * @param params The `KerberosProviderService.UnregisterKerberosProviderParams` containing the following parameters:
   *
   * - `id`: Specifies the id.
   *
   * - `body`: Request to unregister a Kerberos Provider.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UnregisterKerberosProvider(params: KerberosProviderService.UnregisterKerberosProviderParams): __Observable<UnregisterKerberosProvider> {
    return this.UnregisterKerberosProviderResponse(params).pipe(
      __map(_r => _r.body as UnregisterKerberosProvider)
    );
  }
}

module KerberosProviderService {

  /**
   * Parameters for GetKerberosProviders
   */
  export interface GetKerberosProvidersParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Filter by a list of realm names.
     */
    realmNames?: Array<string>;

    /**
     * Filter by a list of KDC servers.
     */
    kdcServers?: Array<string>;

    /**
     * Filter by a list of Kerberos Provider Ids.
     */
    ids?: Array<number>;

    /**
     * Filter by whether LDAP is associated with the provider.
     */
    hasLDAP?: null | boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for RegisterKerberosProvider
   */
  export interface RegisterKerberosProviderParams {

    /**
     * Specifies the parameters to Register a Kerberos Provider.
     */
    body: RegisterOrUpdateKerberosProviderRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetKerberosProviderById
   */
  export interface GetKerberosProviderByIdParams {

    /**
     * Specifies the id which will be of the pattern
     * cluster_id:clusterincarnation_id:resource_id.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateKerberosProvider
   */
  export interface UpdateKerberosProviderParams {

    /**
     * Specifies the id which will be of the pattern
     * cluster_id:clusterincarnation_id:resource_id.
     */
    id: string;

    /**
     * Request to update a Kerberos Provider.
     */
    body: RegisterOrUpdateKerberosProviderRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UnregisterKerberosProvider
   */
  export interface UnregisterKerberosProviderParams {

    /**
     * Specifies the id.
     */
    id: string;

    /**
     * Request to unregister a Kerberos Provider.
     */
    body: UnregisterKerberosRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { KerberosProviderService }
