import { Injectable } from '@angular/core';
import { NavItem } from '@cohesity/helix';
import { IrisContextService, isMcm } from '@cohesity/iris-core';
import { StateService } from '@uirouter/core';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { DialogService, StateManagementService } from 'src/app/core/services';
import { RestoreConfigService } from 'src/app/modules/restore/restore-shared';
import { Environment, RecoveryAction } from 'src/app/shared/constants';
import { VmSourceDataNode } from 'src/app/shared/source-tree/protection-source/vm/vm-source-data-node';

import { ObjectActionProvider } from './object-action-provider';
import { ObjectInfoService } from './object-info.service';
import { SimpleObjectInfo } from './object-menu-provider';
import { VmwareObjectActionCreator } from './vmware-object-action-creator';

/**
 * This is a simple implementation for construction object menu actions. It has some simpPle
 * logic around auto protection, and can be sconfigured to restrict items to only be protected
 * by a single job.
 */
@Injectable()
export class VmwareObjectActionProvider extends ObjectActionProvider {

  /**
   * The provider for this service is manually set up in object-actions-menu.service, which must provide
   * the list of providers as an array in the correct order. In order to maintain some kind of sanity,
   * the providers are listed here, they should match the order of the constructor args.
   */
  static vmwareObjectActionProviderDependencies = [
    ObjectInfoService,
    RestoreConfigService,
    DialogService,
    StateManagementService,
    StateService,
    VmwareObjectActionCreator,
    IrisContextService,
  ];

  exclusiveProtection = false;

  constructor(
    objectStore: ObjectInfoService,
    restoreConfig: RestoreConfigService,
    private dialog: DialogService,
    stateManagementService: StateManagementService,
    stateService: StateService,
    readonly actionCreator: VmwareObjectActionCreator,
    irisContextService: IrisContextService,
  ) {
    super(objectStore, restoreConfig, stateManagementService, stateService, actionCreator, irisContextService);
  }

  /**
   * Returns whether the supplied object is CDP compatible, and the IO filter can be upgraded on it.
   *
   * @param object The VMware source data node object.
   * @returns true if the IO filter is upgradable, false otherwise.
   */
  canUpgradeCdpIoFilter(object: VmSourceDataNode): boolean {
    return (object as any).cdpFilterUpgradable;
  }

  /**
   * Returns whether the supplied object is CDP compatible, and the IO filter can be uninstalled from it.
   *
   * @param object the VMware source data node object.
   * @returns true if the IO filter is uninstallable, false otherwise.
   */
  canUninstallCdpIoFilter(object: VmSourceDataNode): boolean {
    return !!(object as any).cdpInfo;
  }

  /**
   * Creates and returns the CDP IO Filter upgrade action for the specified object.
   *
   * @param object The object the protect.
   * @returns An observable, which yields the NavItem or null.
   */
  getCdpIoFilterUpgradeAction(object: VmSourceDataNode): Observable<NavItem> {
    if (!this.canUpgradeCdpIoFilter(object)) {
      return of(null);
    }

    const action = this.actionCreator.createCdpIoFilterUpgradeAction(() =>
      this.dialog.showDialog('cdp-io-filter-upgrade-dialog', { object: object.protectionSource }, { width: '600px' })
    );

    return of(action);
  }

  /**
   * Creates and returns the CDP Io Filter uninstall action for the specified object.
   *
   * @param object The object the protect.
   * @returns An observable, which yields the NavItem or null.
   */
  getCdpIoFilterUninstallAction(object: VmSourceDataNode): Observable<NavItem> {
    if (!this.canUninstallCdpIoFilter(object)) {
      return of(null);
    }

    const action = this.actionCreator.createCdpIoFilterUninstallAction(() =>
      this.dialog.showDialog('cdp-io-filter-uninstall-dialog', { object: object.protectionSource }, { width: '600px' })
    );

    return of(action);
  }

  /**
   * Get application-related actions for the object, register, edit, or unregister
   *
   * @param object The Object info
   * @param node  The v1 source data node
   * @returns Applicable actions
   */
  getApplicationActions(object: SimpleObjectInfo, node: VmSourceDataNode): Observable<NavItem[]> {
    if (node.hasConnectionStateProblem) {
      return of([]);
    }

    if (node.isApplicationHost) {
      return of([
        this.actionCreator.createManageApplicationAction(node.data, 'edit'),
        this.actionCreator.createManageApplicationAction(node.data, 'unregister')
      ]);
    } else if (node.type === 'kVirtualMachine' && !object.regionId) {
      return of([
        this.actionCreator.createRegisterApplicationAction(object)
      ]);
    }

    return of([]);
  }

  getObjectActions(object: SimpleObjectInfo): Observable<NavItem[]> {
    if (isMcm(this.irisContextService.irisContext)) {
      // Do not make additional API calls for users in MCM mode.
      return super.getObjectActions(object);
    }

    return this.getSourceDataNode(object).pipe(
      switchMap(vmSourceNode =>
        combineLatest([
          super.getObjectActions(object),
          this.getApplicationActions(object, vmSourceNode),
          this.getCdpIoFilterUpgradeAction(vmSourceNode),
          this.getCdpIoFilterUninstallAction(vmSourceNode),
        ])
      ),
      map(([baseActions, appActions, ...vmwareActions]) => [...baseActions, ...appActions, ...vmwareActions]),
      map(actions => actions.filter(action => !!action))
    );
  }

  /**
   * Override the parent method so that we only make lookups for leaf nodes.
   *
   * @param object The protected object.
   * @param type The type of recovery action.
   * @returns An observable, which yields the NavItem or null.
   */
  getRecoveryAction(object: SimpleObjectInfo, type: RecoveryAction): Observable<NavItem> {
    if (isMcm(this.irisContextService.irisContext)) {
      // Do not make additional API calls for users in MCM mode.
      return super.getRecoveryAction(object, type);
    }

    return this.getSourceDataNode(object).pipe(
      switchMap(dataNode =>
        dataNode.envSource.type === 'kVirtualMachine' ? super.getRecoveryAction(object, type) : of(null)
      )
    );
  }

  private getSourceDataNode(object: SimpleObjectInfo): Observable<VmSourceDataNode> {
    const protectionSource = object.v1Object
      ? of(object.v1Object)
      : this.objectStore.getV1ObjectInfo(
        object.id,
        { accessClusterId: object.accessClusterId, regionId: object.regionId }
      ).pipe(
          filter(entry => !entry.loading),
          map(entry => entry.item)
        );
    return protectionSource.pipe(map(source => new VmSourceDataNode(Environment.kVMware, source, 0)));
  }
}
