import { Directive, ElementRef, Renderer2 } from '@angular/core';

/**
 * Add consistent styling to a cancel button. Note, the button still
 * must have the mat-button property applied for this to work.
 *
 * @example
 * <button mat-button cogCancelButton>Cancel</button>
 */
@Directive({
  selector: 'button [cogCancelButton]',
})
export class CancelButtonDirective {
  constructor(elementRef: ElementRef, renderer: Renderer2) {
    const nativeElement = elementRef.nativeElement as HTMLElement;
    renderer.addClass(nativeElement, 'mat-button');
    renderer.setAttribute(nativeElement, 'type', 'button');
  }
}
