/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AuditLogs } from '../models/audit-logs';
import { AuditLogsActions } from '../models/audit-logs-actions';
import { AuditLogsEntityTypes } from '../models/audit-logs-entity-types';
import { FilerAuditLogConfigs } from '../models/filer-audit-log-configs';
import { HeliosAuditLogs } from '../models/helios-audit-logs';
import { HeliosAuditLogsActions } from '../models/helios-audit-logs-actions';
import { HeliosAuditLogUsers } from '../models/helios-audit-log-users';
import { HeliosAuditLogsEntityTypes } from '../models/helios-audit-logs-entity-types';
import { PublishHeliosAuditLogRequest } from '../models/publish-helios-audit-log-request';
import { HeliosAuditLogSettings } from '../models/helios-audit-log-settings';
@Injectable({
  providedIn: 'root',
})
class AuditLogService extends __BaseService {
  static readonly GetAuditLogsPath = '/audit-logs';
  static readonly GetAuditLogsActionsPath = '/audit-logs/actions';
  static readonly GetAuditLogsEntityTypesPath = '/audit-logs/entity-types';
  static readonly GetFilerAuditLogConfigsPath = '/audit-logs/filer-configs';
  static readonly UpdateFilerAuditLogConfigsPath = '/audit-logs/filer-configs';
  static readonly GetHeliosAuditLogsPath = '/mcm/audit-logs';
  static readonly GetHeliosAuditLogsActionsPath = '/mcm/audit-logs/actions';
  static readonly GetHeliosAuditLogsClusterUsersPath = '/mcm/audit-logs/clusterUsers';
  static readonly DownloadHeliosAuditLogsPath = '/mcm/audit-logs/download';
  static readonly GetHeliosAuditLogsEntityTypesPath = '/mcm/audit-logs/entity-types';
  static readonly PublishHeliosAuditLogPath = '/mcm/audit-logs/publish';
  static readonly GetHeliosAuditLogSettingsPath = '/mcm/audit-logs/settings';
  static readonly UpdateHeliosAuditLogSettingsPath = '/mcm/audit-logs/settings';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get cluster audit logs.
   *
   * Get a cluster audit logs.
   * @param params The `AuditLogService.GetAuditLogsParams` containing the following parameters:
   *
   * - `usernames`: Specifies a list of usernames, only audit logs made by these users will be returned.
   *
   * - `tenantIds`: Specifies a list of tenant ids, only audit logs made by these tenants will be returned.
   *
   * - `startTimeUsecs`: Specifies a unix timestamp in microseconds, only audit logs made after this time will be returned.
   *
   * - `startIndex`: Specifies a start index. The oldest logs before this index will skipped, only audit logs from this index will be fetched.
   *
   * - `searchString`: Search audit logs by 'entityName' or 'details'.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: If true, the response will include Protection Groups which were created by all tenants which the current user has permission to see. If false, then only Protection Groups created by the current user will be returned.
   *
   * - `entityTypes`: Specifies a list of entity types, only audit logs containing these entity types will be returned.
   *
   * - `endTimeUsecs`: Specifies a unix timestamp in microseconds, only audit logs made before this time will be returned.
   *
   * - `domains`: Specifies a list of domains, only audit logs made by user in these domains will be returned.
   *
   * - `count`: Specifies the number of indexed obejcts to be fetched from the specified start index.
   *
   * - `actions`: Specifies a list of actions, only audit logs containing these actions will be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAuditLogsResponse(params: AuditLogService.GetAuditLogsParams): __Observable<__StrictHttpResponse<AuditLogs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.usernames || []).forEach(val => {if (val != null) __params = __params.append('usernames', val.toString())});
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    if (params.startIndex != null) __params = __params.set('startIndex', params.startIndex.toString());
    if (params.searchString != null) __params = __params.set('searchString', params.searchString.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    (params.entityTypes || []).forEach(val => {if (val != null) __params = __params.append('entityTypes', val.toString())});
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    (params.domains || []).forEach(val => {if (val != null) __params = __params.append('domains', val.toString())});
    if (params.count != null) __params = __params.set('count', params.count.toString());
    (params.actions || []).forEach(val => {if (val != null) __params = __params.append('actions', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/audit-logs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AuditLogs>;
      })
    );
  }
  /**
   * Get cluster audit logs.
   *
   * Get a cluster audit logs.
   * @param params The `AuditLogService.GetAuditLogsParams` containing the following parameters:
   *
   * - `usernames`: Specifies a list of usernames, only audit logs made by these users will be returned.
   *
   * - `tenantIds`: Specifies a list of tenant ids, only audit logs made by these tenants will be returned.
   *
   * - `startTimeUsecs`: Specifies a unix timestamp in microseconds, only audit logs made after this time will be returned.
   *
   * - `startIndex`: Specifies a start index. The oldest logs before this index will skipped, only audit logs from this index will be fetched.
   *
   * - `searchString`: Search audit logs by 'entityName' or 'details'.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: If true, the response will include Protection Groups which were created by all tenants which the current user has permission to see. If false, then only Protection Groups created by the current user will be returned.
   *
   * - `entityTypes`: Specifies a list of entity types, only audit logs containing these entity types will be returned.
   *
   * - `endTimeUsecs`: Specifies a unix timestamp in microseconds, only audit logs made before this time will be returned.
   *
   * - `domains`: Specifies a list of domains, only audit logs made by user in these domains will be returned.
   *
   * - `count`: Specifies the number of indexed obejcts to be fetched from the specified start index.
   *
   * - `actions`: Specifies a list of actions, only audit logs containing these actions will be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAuditLogs(params: AuditLogService.GetAuditLogsParams): __Observable<AuditLogs> {
    return this.GetAuditLogsResponse(params).pipe(
      __map(_r => _r.body as AuditLogs)
    );
  }

  /**
   * Get cluster audit logs actions.
   *
   * Get all actions of cluster audit logs.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetAuditLogsActionsResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<AuditLogsActions>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/audit-logs/actions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AuditLogsActions>;
      })
    );
  }
  /**
   * Get cluster audit logs actions.
   *
   * Get all actions of cluster audit logs.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetAuditLogsActions(regionId?: string,
    accessClusterId?: number): __Observable<AuditLogsActions> {
    return this.GetAuditLogsActionsResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as AuditLogsActions)
    );
  }

  /**
   * Get cluster audit logs entity types.
   *
   * Get all entity types of cluster audit logs.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetAuditLogsEntityTypesResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<AuditLogsEntityTypes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/audit-logs/entity-types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AuditLogsEntityTypes>;
      })
    );
  }
  /**
   * Get cluster audit logs entity types.
   *
   * Get all entity types of cluster audit logs.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetAuditLogsEntityTypes(regionId?: string,
    accessClusterId?: number): __Observable<AuditLogsEntityTypes> {
    return this.GetAuditLogsEntityTypesResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as AuditLogsEntityTypes)
    );
  }

  /**
   * Get filer audit log configs.
   *
   * Get filer audit log configs.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetFilerAuditLogConfigsResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<FilerAuditLogConfigs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/audit-logs/filer-configs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FilerAuditLogConfigs>;
      })
    );
  }
  /**
   * Get filer audit log configs.
   *
   * Get filer audit log configs.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetFilerAuditLogConfigs(regionId?: string,
    accessClusterId?: number): __Observable<FilerAuditLogConfigs> {
    return this.GetFilerAuditLogConfigsResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as FilerAuditLogConfigs)
    );
  }

  /**
   * Update filer audit log configs.
   *
   * Update filer audit log configs.
   * @param params The `AuditLogService.UpdateFilerAuditLogConfigsParams` containing the following parameters:
   *
   * - `body`: Specifies the filer audit log config to update.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateFilerAuditLogConfigsResponse(params: AuditLogService.UpdateFilerAuditLogConfigsParams): __Observable<__StrictHttpResponse<FilerAuditLogConfigs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/audit-logs/filer-configs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FilerAuditLogConfigs>;
      })
    );
  }
  /**
   * Update filer audit log configs.
   *
   * Update filer audit log configs.
   * @param params The `AuditLogService.UpdateFilerAuditLogConfigsParams` containing the following parameters:
   *
   * - `body`: Specifies the filer audit log config to update.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateFilerAuditLogConfigs(params: AuditLogService.UpdateFilerAuditLogConfigsParams): __Observable<FilerAuditLogConfigs> {
    return this.UpdateFilerAuditLogConfigsResponse(params).pipe(
      __map(_r => _r.body as FilerAuditLogConfigs)
    );
  }

  /**
   * Get helios audit logs.
   *
   * Get helios audit logs.
   * @param params The `AuditLogService.GetHeliosAuditLogsParams` containing the following parameters:
   *
   * - `usernames`: Specifies a list of usernames, only audit logs made by these users will be returned.
   *
   * - `tenantIds`: Specifies a list of tenant ids, only audit logs made by these tenants will be returned. These has to be local tenant Id.
   *
   * - `startTimeUsecs`: Specifies a unix timestamp in microseconds, only audit logs made after this time will be returned.
   *
   * - `startIndex`: Specifies a start index. The oldest logs before this index will skipped, only audit logs from this index will be fetched.
   *
   * - `serviceContext`: Returns the audit logs for a list of filter service context. Passing service context only one at a time is supported as of now
   *
   * - `searchString`: Search audit logs by 'entityName' or 'details'.
   *
   * - `regionIds`: Specifies the list of region ids to filter.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: If true, the response will include Protection Groups which were created by all tenants which the current user has permission to see. If false, then only Protection Groups created by the current user will be returned.
   *
   * - `includeHeliosLogs`: Specifies if helios audit logs need to be fetched or not from the index. This is deprecated. Use serviceContext instead.
   *
   * - `includeDmaasLogs`: Specifies if dmaas audit logs need to be fetched or not from the index. This is deprecated. Use serviceContext instead.
   *
   * - `entityTypes`: Specifies a list of entity types, only audit logs containing these entity types will be returned.
   *
   * - `entityIds`: Specifies a list of entity ids, only audit logs containing these entity ids will be returned.
   *
   * - `endTimeUsecs`: Specifies a unix timestamp in microseconds, only audit logs made before this time will be returned.
   *
   * - `domains`: Specifies a list of domains, only audit logs made by user in these domains will be returned.
   *
   * - `count`: Specifies the number of indexed objects to be fetched from the index.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. The format is clusterId:clusterIncarnationId.
   *
   * - `actions`: Specifies a list of actions, only audit logs containing these actions will be returned.
   *
   * @return Success
   */
  GetHeliosAuditLogsResponse(params: AuditLogService.GetHeliosAuditLogsParams): __Observable<__StrictHttpResponse<HeliosAuditLogs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.usernames || []).forEach(val => {if (val != null) __params = __params.append('usernames', val.toString())});
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    if (params.startIndex != null) __params = __params.set('startIndex', params.startIndex.toString());
    (params.serviceContext || []).forEach(val => {if (val != null) __params = __params.append('serviceContext', val.toString())});
    if (params.searchString != null) __params = __params.set('searchString', params.searchString.toString());
    (params.regionIds || []).forEach(val => {if (val != null) __params = __params.append('regionIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.includeHeliosLogs != null) __params = __params.set('includeHeliosLogs', params.includeHeliosLogs.toString());
    if (params.includeDmaasLogs != null) __params = __params.set('includeDmaasLogs', params.includeDmaasLogs.toString());
    (params.entityTypes || []).forEach(val => {if (val != null) __params = __params.append('entityTypes', val.toString())});
    (params.entityIds || []).forEach(val => {if (val != null) __params = __params.append('entityIds', val.toString())});
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    (params.domains || []).forEach(val => {if (val != null) __params = __params.append('domains', val.toString())});
    if (params.count != null) __params = __params.set('count', params.count.toString());
    (params.clusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('clusterIdentifiers', val.toString())});
    (params.actions || []).forEach(val => {if (val != null) __params = __params.append('actions', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/audit-logs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HeliosAuditLogs>;
      })
    );
  }
  /**
   * Get helios audit logs.
   *
   * Get helios audit logs.
   * @param params The `AuditLogService.GetHeliosAuditLogsParams` containing the following parameters:
   *
   * - `usernames`: Specifies a list of usernames, only audit logs made by these users will be returned.
   *
   * - `tenantIds`: Specifies a list of tenant ids, only audit logs made by these tenants will be returned. These has to be local tenant Id.
   *
   * - `startTimeUsecs`: Specifies a unix timestamp in microseconds, only audit logs made after this time will be returned.
   *
   * - `startIndex`: Specifies a start index. The oldest logs before this index will skipped, only audit logs from this index will be fetched.
   *
   * - `serviceContext`: Returns the audit logs for a list of filter service context. Passing service context only one at a time is supported as of now
   *
   * - `searchString`: Search audit logs by 'entityName' or 'details'.
   *
   * - `regionIds`: Specifies the list of region ids to filter.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: If true, the response will include Protection Groups which were created by all tenants which the current user has permission to see. If false, then only Protection Groups created by the current user will be returned.
   *
   * - `includeHeliosLogs`: Specifies if helios audit logs need to be fetched or not from the index. This is deprecated. Use serviceContext instead.
   *
   * - `includeDmaasLogs`: Specifies if dmaas audit logs need to be fetched or not from the index. This is deprecated. Use serviceContext instead.
   *
   * - `entityTypes`: Specifies a list of entity types, only audit logs containing these entity types will be returned.
   *
   * - `entityIds`: Specifies a list of entity ids, only audit logs containing these entity ids will be returned.
   *
   * - `endTimeUsecs`: Specifies a unix timestamp in microseconds, only audit logs made before this time will be returned.
   *
   * - `domains`: Specifies a list of domains, only audit logs made by user in these domains will be returned.
   *
   * - `count`: Specifies the number of indexed objects to be fetched from the index.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. The format is clusterId:clusterIncarnationId.
   *
   * - `actions`: Specifies a list of actions, only audit logs containing these actions will be returned.
   *
   * @return Success
   */
  GetHeliosAuditLogs(params: AuditLogService.GetHeliosAuditLogsParams): __Observable<HeliosAuditLogs> {
    return this.GetHeliosAuditLogsResponse(params).pipe(
      __map(_r => _r.body as HeliosAuditLogs)
    );
  }

  /**
   * Get helios audit logs actions.
   *
   * Get all actions of helios audit logs.
   * @param service Get audit logs actions by service.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetHeliosAuditLogsActionsResponse(service?: null | 'Dmaas' | 'Mcm' | 'DataGovern' | 'FortKnox' | 'SiteContinuity' | 'SiteContinuity2' | 'DataInsights',
    regionId?: string): __Observable<__StrictHttpResponse<HeliosAuditLogsActions>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (service != null) __params = __params.set('service', service.toString());
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/audit-logs/actions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HeliosAuditLogsActions>;
      })
    );
  }
  /**
   * Get helios audit logs actions.
   *
   * Get all actions of helios audit logs.
   * @param service Get audit logs actions by service.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetHeliosAuditLogsActions(service?: null | 'Dmaas' | 'Mcm' | 'DataGovern' | 'FortKnox' | 'SiteContinuity' | 'SiteContinuity2' | 'DataInsights',
    regionId?: string): __Observable<HeliosAuditLogsActions> {
    return this.GetHeliosAuditLogsActionsResponse(service, regionId).pipe(
      __map(_r => _r.body as HeliosAuditLogsActions)
    );
  }

  /**
   * Get helios audit logs cluster users.
   *
   * Get all cluster users of helios audit logs.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetHeliosAuditLogsClusterUsersResponse(regionId?: string): __Observable<__StrictHttpResponse<HeliosAuditLogUsers>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/audit-logs/clusterUsers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HeliosAuditLogUsers>;
      })
    );
  }
  /**
   * Get helios audit logs cluster users.
   *
   * Get all cluster users of helios audit logs.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetHeliosAuditLogsClusterUsers(regionId?: string): __Observable<HeliosAuditLogUsers> {
    return this.GetHeliosAuditLogsClusterUsersResponse(regionId).pipe(
      __map(_r => _r.body as HeliosAuditLogUsers)
    );
  }

  /**
   * Download helios audit logs.
   *
   * Download helios audit logs.
   * @param params The `AuditLogService.DownloadHeliosAuditLogsParams` containing the following parameters:
   *
   * - `usernames`: Specifies a list of usernames, only audit logs made by these users will be returned.
   *
   * - `tenantIds`: Specifies a list of tenant ids, only audit logs made by these tenants will be returned.
   *
   * - `startTimeUsecs`: Specifies a unix timestamp in microseconds, only audit logs made after this time will be returned.
   *
   * - `startIndex`: Specifies a start index. The oldest logs before this index will skipped, only audit logs from this index will be fetched.
   *
   * - `serviceContext`: Returns the audit logs for a list of filter service context. Passing service context only one at a time is supported as of now
   *
   * - `searchString`: Search audit logs by 'entityName' or 'details'.
   *
   * - `regionIds`: Specifies the list of region ids to filter.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: If true, the response will include Protection Groups which were created by all tenants which the current user has permission to see. If false, then only Protection Groups created by the current user will be returned.
   *
   * - `includeHeliosLogs`: Specifies if helios audit logs need to be fetched or not from the index.
   *
   * - `includeDmaasLogs`: Specifies if dmaas audit logs need to be fetched or not from the index.
   *
   * - `entityTypes`: Specifies a list of entity types, only audit logs containing these entity types will be returned.
   *
   * - `endTimeUsecs`: Specifies a unix timestamp in microseconds, only audit logs made before this time will be returned.
   *
   * - `domains`: Specifies a list of domains, only audit logs made by user in these domains will be returned.
   *
   * - `count`: Specifies the number of indexed objects to be fetched from the index.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. The format is clusterId:clusterIncarnationId.
   *
   * - `actions`: Specifies a list of actions, only audit logs containing these actions will be returned.
   *
   * @return Download file for Audit Logs.
   */
  DownloadHeliosAuditLogsResponse(params: AuditLogService.DownloadHeliosAuditLogsParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.usernames || []).forEach(val => {if (val != null) __params = __params.append('usernames', val.toString())});
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    if (params.startIndex != null) __params = __params.set('startIndex', params.startIndex.toString());
    (params.serviceContext || []).forEach(val => {if (val != null) __params = __params.append('serviceContext', val.toString())});
    if (params.searchString != null) __params = __params.set('searchString', params.searchString.toString());
    (params.regionIds || []).forEach(val => {if (val != null) __params = __params.append('regionIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.includeHeliosLogs != null) __params = __params.set('includeHeliosLogs', params.includeHeliosLogs.toString());
    if (params.includeDmaasLogs != null) __params = __params.set('includeDmaasLogs', params.includeDmaasLogs.toString());
    (params.entityTypes || []).forEach(val => {if (val != null) __params = __params.append('entityTypes', val.toString())});
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    (params.domains || []).forEach(val => {if (val != null) __params = __params.append('domains', val.toString())});
    if (params.count != null) __params = __params.set('count', params.count.toString());
    (params.clusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('clusterIdentifiers', val.toString())});
    (params.actions || []).forEach(val => {if (val != null) __params = __params.append('actions', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/audit-logs/download`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Download helios audit logs.
   *
   * Download helios audit logs.
   * @param params The `AuditLogService.DownloadHeliosAuditLogsParams` containing the following parameters:
   *
   * - `usernames`: Specifies a list of usernames, only audit logs made by these users will be returned.
   *
   * - `tenantIds`: Specifies a list of tenant ids, only audit logs made by these tenants will be returned.
   *
   * - `startTimeUsecs`: Specifies a unix timestamp in microseconds, only audit logs made after this time will be returned.
   *
   * - `startIndex`: Specifies a start index. The oldest logs before this index will skipped, only audit logs from this index will be fetched.
   *
   * - `serviceContext`: Returns the audit logs for a list of filter service context. Passing service context only one at a time is supported as of now
   *
   * - `searchString`: Search audit logs by 'entityName' or 'details'.
   *
   * - `regionIds`: Specifies the list of region ids to filter.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: If true, the response will include Protection Groups which were created by all tenants which the current user has permission to see. If false, then only Protection Groups created by the current user will be returned.
   *
   * - `includeHeliosLogs`: Specifies if helios audit logs need to be fetched or not from the index.
   *
   * - `includeDmaasLogs`: Specifies if dmaas audit logs need to be fetched or not from the index.
   *
   * - `entityTypes`: Specifies a list of entity types, only audit logs containing these entity types will be returned.
   *
   * - `endTimeUsecs`: Specifies a unix timestamp in microseconds, only audit logs made before this time will be returned.
   *
   * - `domains`: Specifies a list of domains, only audit logs made by user in these domains will be returned.
   *
   * - `count`: Specifies the number of indexed objects to be fetched from the index.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers. The format is clusterId:clusterIncarnationId.
   *
   * - `actions`: Specifies a list of actions, only audit logs containing these actions will be returned.
   *
   * @return Download file for Audit Logs.
   */
  DownloadHeliosAuditLogs(params: AuditLogService.DownloadHeliosAuditLogsParams): __Observable<Blob> {
    return this.DownloadHeliosAuditLogsResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Get helios audit logs entity types.
   *
   * Get all entity types of helios audit logs.
   * @param service Get audit logs entity type by service.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetHeliosAuditLogsEntityTypesResponse(service?: null | 'Dmaas' | 'Mcm' | 'DataGovern' | 'FortKnox' | 'SiteContinuity' | 'SiteContinuity2' | 'DataInsights',
    regionId?: string): __Observable<__StrictHttpResponse<HeliosAuditLogsEntityTypes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (service != null) __params = __params.set('service', service.toString());
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/audit-logs/entity-types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HeliosAuditLogsEntityTypes>;
      })
    );
  }
  /**
   * Get helios audit logs entity types.
   *
   * Get all entity types of helios audit logs.
   * @param service Get audit logs entity type by service.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetHeliosAuditLogsEntityTypes(service?: null | 'Dmaas' | 'Mcm' | 'DataGovern' | 'FortKnox' | 'SiteContinuity' | 'SiteContinuity2' | 'DataInsights',
    regionId?: string): __Observable<HeliosAuditLogsEntityTypes> {
    return this.GetHeliosAuditLogsEntityTypesResponse(service, regionId).pipe(
      __map(_r => _r.body as HeliosAuditLogsEntityTypes)
    );
  }

  /**
   * Publish helios audit log.
   *
   * Publish Helios Audit Log
   * @param body Specifies the parameters to publish helios audit log.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  PublishHeliosAuditLogResponse(body: PublishHeliosAuditLogRequest,
    regionId?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/audit-logs/publish`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Publish helios audit log.
   *
   * Publish Helios Audit Log
   * @param body Specifies the parameters to publish helios audit log.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  PublishHeliosAuditLog(body: PublishHeliosAuditLogRequest,
    regionId?: string): __Observable<null> {
    return this.PublishHeliosAuditLogResponse(body, regionId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get Helios Audit Log Settings.
   *
   * Returns a list of Helios audit log settings.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetHeliosAuditLogSettingsResponse(regionId?: string): __Observable<__StrictHttpResponse<HeliosAuditLogSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/audit-logs/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HeliosAuditLogSettings>;
      })
    );
  }
  /**
   * Get Helios Audit Log Settings.
   *
   * Returns a list of Helios audit log settings.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetHeliosAuditLogSettings(regionId?: string): __Observable<HeliosAuditLogSettings> {
    return this.GetHeliosAuditLogSettingsResponse(regionId).pipe(
      __map(_r => _r.body as HeliosAuditLogSettings)
    );
  }

  /**
   * Update Helios Audit Log Settings.
   *
   * Updates Helios audit log settings.
   * @param body undefined
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  UpdateHeliosAuditLogSettingsResponse(body: HeliosAuditLogSettings,
    regionId?: string): __Observable<__StrictHttpResponse<HeliosAuditLogSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/mcm/audit-logs/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HeliosAuditLogSettings>;
      })
    );
  }
  /**
   * Update Helios Audit Log Settings.
   *
   * Updates Helios audit log settings.
   * @param body undefined
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  UpdateHeliosAuditLogSettings(body: HeliosAuditLogSettings,
    regionId?: string): __Observable<HeliosAuditLogSettings> {
    return this.UpdateHeliosAuditLogSettingsResponse(body, regionId).pipe(
      __map(_r => _r.body as HeliosAuditLogSettings)
    );
  }
}

module AuditLogService {

  /**
   * Parameters for GetAuditLogs
   */
  export interface GetAuditLogsParams {

    /**
     * Specifies a list of usernames, only audit logs made by these users will be returned.
     */
    usernames?: null | Array<string>;

    /**
     * Specifies a list of tenant ids, only audit logs made by these tenants will be returned.
     */
    tenantIds?: null | Array<string>;

    /**
     * Specifies a unix timestamp in microseconds, only audit logs made after this time will be returned.
     */
    startTimeUsecs?: null | number;

    /**
     * Specifies a start index. The oldest logs before this index will skipped, only audit logs from this index will be fetched.
     */
    startIndex?: null | number;

    /**
     * Search audit logs by 'entityName' or 'details'.
     */
    searchString?: null | string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * If true, the response will include Protection Groups which were created by all tenants which the current user has permission to see. If false, then only Protection Groups created by the current user will be returned.
     */
    includeTenants?: null | boolean;

    /**
     * Specifies a list of entity types, only audit logs containing these entity types will be returned.
     */
    entityTypes?: null | Array<'ClusterPartition' | 'StorageDomain' | 'View' | 'Share' | 'Node' | 'Disk' | 'Cluster' | 'Vlan' | 'User' | 'ApiKey' | 'Chassis' | 'SslCertificate' | 'ProtectionGroup' | 'Source' | 'RecoveryTask' | 'SmtpServer' | 'EncryptionKey' | 'ProtectionPolicy' | 'Alert' | 'Resolution' | 'AlertNotificationRule' | 'Vault' | 'RemoteCluster' | 'ActiveDirectory' | 'KerberosProvider' | 'Ldap' | 'AntivirusServiceGroup' | 'InfectedFile' | 'PreferredDomainController' | 'Group' | 'Role' | 'ProtectionRun' | 'SearchJob' | 'PhysicalAgent' | 'CloneTask' | 'CloneRefreshTask' | 'Network' | 'Interface' | 'NetworkInerfaceGroup' | 'Scheduler' | 'ProxyServer' | 'StaticRoute' | 'Ip' | 'Qos' | 'KmsConfiguration' | 'CloudSpin' | 'Tenant' | 'IdpConfiguration' | 'App' | 'HeliosEvent' | 'Object' | 'ClusterServices' | 'AccessToken' | 'SnmpConfig' | 'IoTier' | 'ServiceFlag' | 'SupportServer' | 'Csr' | 'Keystone' | 'SwiftRoles' | 'Tags' | 'Nis' | 'Snapshot' | 'HybridExtender' | 'DataTieringAnalysisGroup' | 'DataTieringDowntierTask' | 'DataTieringUptierTask' | 'TrustedCA' | 'AMQPTargetConfiguration' | 'Patch' | 'Hotfix' | 'NisNetgroup' | 'QuorumGroup' | 'QuorumApplication' | 'BifrostConnection' | 'BifrostConnector' | 'HostMapping' | 'FirewallSetting'>;

    /**
     * Specifies a unix timestamp in microseconds, only audit logs made before this time will be returned.
     */
    endTimeUsecs?: null | number;

    /**
     * Specifies a list of domains, only audit logs made by user in these domains will be returned.
     */
    domains?: null | Array<string>;

    /**
     * Specifies the number of indexed obejcts to be fetched from the specified start index.
     */
    count?: null | number;

    /**
     * Specifies a list of actions, only audit logs containing these actions will be returned.
     */
    actions?: null | Array<'Login' | 'Logout' | 'Create' | 'Modify' | 'Delete' | 'Activate' | 'Deactivate' | 'Pause' | 'Resume' | 'RunNow' | 'Clone' | 'Recover' | 'Cancel' | 'Register' | 'Unregister' | 'Update' | 'Refresh' | 'Upgrade' | 'Upload' | 'Download' | 'Rename' | 'Accept' | 'Mark' | 'Close' | 'Join' | 'DisJoin' | 'Overwrite' | 'MarkRemoval' | 'CloudSpin' | 'Assign' | 'Unassign' | 'NotificationRule' | 'ScheduleReport' | 'Install' | 'Uninstall' | 'Stop' | 'Start' | 'Restart' | 'RunDiagnostics' | 'Apply' | 'Revert' | 'Import' | 'Validate' | 'ClusterExpand' | 'Fetch' | 'Search' | 'PrepareFailover' | 'Failover' | 'PrepareFailback' | 'Failback' | 'Teardown' | 'RetryReplication' | 'ManualSync' | 'Protect' | 'RetryExecution' | 'Add' | 'Schedule'>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateFilerAuditLogConfigs
   */
  export interface UpdateFilerAuditLogConfigsParams {

    /**
     * Specifies the filer audit log config to update.
     */
    body: FilerAuditLogConfigs;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetHeliosAuditLogs
   */
  export interface GetHeliosAuditLogsParams {

    /**
     * Specifies a list of usernames, only audit logs made by these users will be returned.
     */
    usernames?: null | Array<string>;

    /**
     * Specifies a list of tenant ids, only audit logs made by these tenants will be returned. These has to be local tenant Id.
     */
    tenantIds?: null | Array<string>;

    /**
     * Specifies a unix timestamp in microseconds, only audit logs made after this time will be returned.
     */
    startTimeUsecs?: null | number;

    /**
     * Specifies a start index. The oldest logs before this index will skipped, only audit logs from this index will be fetched.
     */
    startIndex?: null | number;

    /**
     * Returns the audit logs for a list of filter service context. Passing service context only one at a time is supported as of now
     */
    serviceContext?: null | Array<'Dmaas' | 'Mcm' | 'DataGovern' | 'FortKnox' | 'SiteContinuity' | 'SiteContinuity2' | 'DataInsights'>;

    /**
     * Search audit logs by 'entityName' or 'details'.
     */
    searchString?: null | string;

    /**
     * Specifies the list of region ids to filter.
     */
    regionIds?: null | Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * If true, the response will include Protection Groups which were created by all tenants which the current user has permission to see. If false, then only Protection Groups created by the current user will be returned.
     */
    includeTenants?: null | boolean;

    /**
     * Specifies if helios audit logs need to be fetched or not from the index. This is deprecated. Use serviceContext instead.
     */
    includeHeliosLogs?: null | boolean;

    /**
     * Specifies if dmaas audit logs need to be fetched or not from the index. This is deprecated. Use serviceContext instead.
     */
    includeDmaasLogs?: null | boolean;

    /**
     * Specifies a list of entity types, only audit logs containing these entity types will be returned.
     */
    entityTypes?: null | Array<'ClusterPartition' | 'StorageDomain' | 'View' | 'Share' | 'Node' | 'Disk' | 'Cluster' | 'Vlan' | 'User' | 'ApiKey' | 'Chassis' | 'SslCertificate' | 'ProtectionGroup' | 'Source' | 'RecoveryTask' | 'SmtpServer' | 'EncryptionKey' | 'ProtectionPolicy' | 'Alert' | 'Resolution' | 'AlertNotificationRule' | 'Vault' | 'RemoteCluster' | 'ActiveDirectory' | 'KerberosProvider' | 'Ldap' | 'AntivirusServiceGroup' | 'InfectedFile' | 'PreferredDomainController' | 'Group' | 'Role' | 'ProtectionRun' | 'SearchJob' | 'PhysicalAgent' | 'CloneTask' | 'CloneRefreshTask' | 'Network' | 'Interface' | 'NetworkInerfaceGroup' | 'Scheduler' | 'ProxyServer' | 'StaticRoute' | 'Ip' | 'Qos' | 'KmsConfiguration' | 'CloudSpin' | 'Tenant' | 'IdpConfiguration' | 'App' | 'HeliosEvent' | 'Object' | 'ClusterServices' | 'AccessToken' | 'SnmpConfig' | 'IoTier' | 'ServiceFlag' | 'SupportServer' | 'Csr' | 'Keystone' | 'SwiftRoles' | 'Tags' | 'Nis' | 'Snapshot' | 'HybridExtender' | 'DataTieringAnalysisGroup' | 'DataTieringDowntierTask' | 'DataTieringUptierTask' | 'TrustedCA' | 'AMQPTargetConfiguration' | 'Patch' | 'Hotfix' | 'NisNetgroup' | 'QuorumGroup' | 'QuorumApplication' | 'BifrostConnection' | 'BifrostConnector' | 'HostMapping' | 'FirewallSetting' | 'WhitelabelingSetting' | 'AuditLogSetting' | 'GflagRecipe' | 'Gflag' | 'RPaasRegion' | 'RPaaSOnboarding' | 'ClusterUpgrade' | 'Region' | 'SaasConnector' | 'DataPool' | 'DRPlan' | 'Site' | 'Application'>;

    /**
     * Specifies a list of entity ids, only audit logs containing these entity ids will be returned.
     */
    entityIds?: null | Array<string>;

    /**
     * Specifies a unix timestamp in microseconds, only audit logs made before this time will be returned.
     */
    endTimeUsecs?: null | number;

    /**
     * Specifies a list of domains, only audit logs made by user in these domains will be returned.
     */
    domains?: null | Array<string>;

    /**
     * Specifies the number of indexed objects to be fetched from the index.
     */
    count?: null | number;

    /**
     * Specifies the list of cluster identifiers. The format is clusterId:clusterIncarnationId.
     */
    clusterIdentifiers?: null | Array<string>;

    /**
     * Specifies a list of actions, only audit logs containing these actions will be returned.
     */
    actions?: null | Array<string>;
  }

  /**
   * Parameters for DownloadHeliosAuditLogs
   */
  export interface DownloadHeliosAuditLogsParams {

    /**
     * Specifies a list of usernames, only audit logs made by these users will be returned.
     */
    usernames?: null | Array<string>;

    /**
     * Specifies a list of tenant ids, only audit logs made by these tenants will be returned.
     */
    tenantIds?: null | Array<string>;

    /**
     * Specifies a unix timestamp in microseconds, only audit logs made after this time will be returned.
     */
    startTimeUsecs?: null | number;

    /**
     * Specifies a start index. The oldest logs before this index will skipped, only audit logs from this index will be fetched.
     */
    startIndex?: null | number;

    /**
     * Returns the audit logs for a list of filter service context. Passing service context only one at a time is supported as of now
     */
    serviceContext?: null | Array<'Dmaas' | 'Mcm' | 'DataGovern' | 'FortKnox' | 'SiteContinuity' | 'SiteContinuity2' | 'DataInsights'>;

    /**
     * Search audit logs by 'entityName' or 'details'.
     */
    searchString?: null | string;

    /**
     * Specifies the list of region ids to filter.
     */
    regionIds?: null | Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * If true, the response will include Protection Groups which were created by all tenants which the current user has permission to see. If false, then only Protection Groups created by the current user will be returned.
     */
    includeTenants?: null | boolean;

    /**
     * Specifies if helios audit logs need to be fetched or not from the index.
     */
    includeHeliosLogs?: null | boolean;

    /**
     * Specifies if dmaas audit logs need to be fetched or not from the index.
     */
    includeDmaasLogs?: null | boolean;

    /**
     * Specifies a list of entity types, only audit logs containing these entity types will be returned.
     */
    entityTypes?: null | Array<string>;

    /**
     * Specifies a unix timestamp in microseconds, only audit logs made before this time will be returned.
     */
    endTimeUsecs?: null | number;

    /**
     * Specifies a list of domains, only audit logs made by user in these domains will be returned.
     */
    domains?: null | Array<string>;

    /**
     * Specifies the number of indexed objects to be fetched from the index.
     */
    count?: null | number;

    /**
     * Specifies the list of cluster identifiers. The format is clusterId:clusterIncarnationId.
     */
    clusterIdentifiers?: null | Array<string>;

    /**
     * Specifies a list of actions, only audit logs containing these actions will be returned.
     */
    actions?: null | Array<string>;
  }
}

export { AuditLogService }
