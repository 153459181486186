<div *ngIf="!optional">
  <div *ngIf="title"
    class="cog-form-panel-title panel-expanded">
    {{ title }}
  </div>
  <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
</div>
<div *ngIf="optional">
  <mat-slide-toggle #allowToggle
    class="cog-form-panel-title"
    [class.panel-expanded] = "allowToggle.checked">
    {{ title }}
  </mat-slide-toggle>
  <div [@openClose]="allowToggle.checked ? 'open' : 'close'">
    <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
  </div>
</div>
<ng-template #tempOutlet>
  <ng-content></ng-content>
</ng-template>
