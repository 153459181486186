import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { RecoveredOrClonedVmsRenameConfig } from '@cohesity/api/v2';
import { Environment } from '@cohesity/iris-shared-constants';
import { Controls, NgxSubFormRemapComponent, subformComponentProviders, takeUntilDestroyed } from 'ngx-sub-form';
import { REGEX_FORMATS } from 'src/app/shared/constants/formats.constants';
import { PureProtectionTypes } from 'src/app/shared/source-tree/protection-source/pure/pure.constants';

@Component({
  selector: 'coh-settings-list-recovery-options-rename',
  templateUrl: './settings-list-recovery-options-rename.component.html',
  styleUrls: ['./settings-list-recovery-options-rename.component.scss'],
  providers: subformComponentProviders(SettingsListRecoveryOptionsRenameComponent),
})
export class SettingsListRecoveryOptionsRenameComponent
  extends NgxSubFormRemapComponent<RecoveredOrClonedVmsRenameConfig, RecoveredOrClonedVmsRenameConfig>
  implements OnInit, OnChanges {
  // TODO: add explicit constructor

  /**
   * Optional. The length available for renaming.
   * Some environments have a limit on the length of vm name.
   */
  @Input() availableNameLength: number;

  /**
   * Optional. A pattern to validate prefix name.
   */
  @Input() prefixPattern: RegExp;

  /**
   * Optional. A pattern to validate suffix name.
   */
  @Input() suffixPattern: RegExp;

  /**
   * Optional. Specify a label for the rename settings list. By default it will
   *  be called "Rename".
   */
  @Input() label: string;

  /**
   * Require either prefix or suffix value.
   */
  @Input() requirePrefixOrSuffix = false;

  /**
   * Optional: custom error message for invalid prefix.
   */
  @Input() customPrefixError: string;

  /**
   * Optional: custom error message for invalid suffix.
   */
  @Input() customSuffixError: string;

  /**
   * Optional: If it is a VM migration or recovery.
   */
  @Input() isVmMigration = false;

  /**
   * Optional: environment of the underlying adapter.
   */
  @Input() environment: Environment;

  /**
   * Optional: object type in kPure.
   */
  @Input() objectType: string;

  /**
   * Component on change.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.requirePrefixOrSuffix) {
      this.formGroup.updateValueAndValidity();
    }
  }

  /**
   * Initialize the component and set pattern validators for input fields.
   */
  ngOnInit() {
    this.formGroupControls['prefix'].setValidators([Validators.pattern(this.getPrefixPatternValidator())]);
    this.formGroupControls['suffix'].setValidators([Validators.pattern(this.getSuffixPatternValidator())]);

    this.formGroup.valueChanges.pipe(takeUntilDestroyed(this)).subscribe(value => {
      const {prefix, suffix} = value;

      if (this.requirePrefixOrSuffix && !prefix?.trim()?.length && !suffix?.trim()?.length) {
        // If requirePrefixOrSuffix is configured, either a prefix or
        // suffix is required.
        this.formGroup.setErrors({
          ...this.formGroup.errors,
          prefixOrSuffixRequired: true,
        });
      }
    });
  }

  /**
   * Function to return all the form controls.
   *
   * @return The form controls object.
   */
  protected getFormControls(): Controls<RecoveredOrClonedVmsRenameConfig> {
    // TODO(pg-vmr): Cache prefix and suffix value in localeStorage.
    return {
      prefix: new UntypedFormControl('copy-'),
      suffix: new UntypedFormControl(''),
    };
  }

  /**
   * Returns the maximum allowed length for a VM's prefix.
   * The VM name length is a combination of vm name length, prefix length and suffix length.
   */
  getPrefixMaxLength(): number {
    // default maxLength is infinity
    if (!this.availableNameLength) {
      return 9999;
    }

    // For prefix, consider suffix's length and vice versa
    const usedLength = this.formGroupValues['suffix'].length;

    // If max length is negative, consider it 0.
    return Math.max((this.availableNameLength - usedLength), 0);
  }

  /**
   * Returns the maximum allowed length for a VM's suffix.
   * The VM name length is a combination of vm name length, prefix length and suffix length.
   */
  getSuffixMaxLength(): number {
    // default maxLength is infinity
    if (!this.availableNameLength) {
      return 9999;
    }

    // For prefix, consider suffix's length and vice versa
    const usedLength = this.formGroupValues['prefix'].length;

    // If max length is negative, consider it 0.
    return Math.max((this.availableNameLength - usedLength), 0);
  }

  /**
   * Get a RegEx pattern for validating prefix of VM name.
   */
  private getPrefixPatternValidator(): RegExp {
    return this.prefixPattern || REGEX_FORMATS.names;
  }

  /**
   * Get a RegEx pattern for validating suffix of VM name.
   */
  private getSuffixPatternValidator(): RegExp {
    return this.suffixPattern || REGEX_FORMATS.names;
  }

  protected transformToFormGroup(
    obj: RecoveredOrClonedVmsRenameConfig | null
  ): RecoveredOrClonedVmsRenameConfig {
    const {prefix = !this.isVmMigration && obj === null ? 'copy-' : '', suffix = ''} = obj || {};

    return {
      prefix,
      suffix,
    };
  }

  protected transformFromFormGroup(): RecoveredOrClonedVmsRenameConfig | null {
    const {prefix = '', suffix = ''} = this.formGroup.value || {};

    if (!prefix && !suffix) {
      // If both prefix and suffix are not set, set the entire rename key
      // value as null to indicate to API that there is no rename is present,
      // without this, the API will error out.
      return null;
    }

    // Do not return values as '' (empty strings), but use null to indicate
    // no prefix or suffix value. The UI currently doesn't support explicitly
    // setting prefix or suffix value as '' (empty strings).
    return {
      prefix: prefix || null,
      suffix: suffix || null,
    };
  }

  /**
   * Whether or not to display the info banner.
   */
  get shouldDisplayInfoBanner(): boolean {
    return this.environment === Environment.kPure &&
      this.objectType === PureProtectionTypes.kPureProtectionGroup;
  }
}
