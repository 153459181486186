<div [formGroup]="formGroup" class="item-container">
  <div class="object-wrapper rename-col1">
    <div class="object-icon">
      <cog-icon [shape]="formGroupValues.icon">
      </cog-icon>
    </div>
    <div class="object-detail">
      <span class="object-name user-data-xl" cogDataId="object-detail">
        {{ formGroupValues.name }}
      </span>
    </div>
  </div>
  <div class="rename-wrapper rename-col2">
    <mat-form-field>
      <input matInput
        [formControlName]="formControlNames.renameTo"
        [placeholder]="'newName' | translate">
      <mat-error *ngIf="formGroupErrors?.renameTo?.whitespace">
        {{ 'errors.patterns.noWhiteSpace' | translate }}
      </mat-error>
    </mat-form-field>
  </div>
</div>
