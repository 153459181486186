<div class="flex-row-sm baseline-align">
  <div><h3 translate="notifications"></h3></div>
  <a id="dismiss-all-notifs-anchor" (click)="dismissAll()" *ngIf="notificationsInfo?.unreadCount">
    {{'clearAll' | translate}}
  </a>
</div>

<ng-container *ngIf="!basicClusterInfo.mcmMode">
  <ng-container *ngTemplateOutlet="eventsListTemplate"></ng-container>
</ng-container>

<mat-tab-group *ngIf="basicClusterInfo.mcmMode">
  <mat-tab [label]="'events' | translate">
      <ng-container *ngTemplateOutlet="eventsListTemplate"></ng-container>
  </mat-tab>
  <mat-tab [label]="'fieldMessages' | translate">
    <!-- Field Messages List -->
    <mat-list *ngIf="!loading; else loadingSpinner">
      <ng-container *ngIf="fieldMessageTasks.length; else noNotificationsTemplate">
        <coh-notifications-item *ngFor="let notif of fieldMessageTasks" [item]="notif"></coh-notifications-item>
      </ng-container>
    </mat-list>
  </mat-tab>
</mat-tab-group>

<!-- Events List -->
<ng-template #eventsListTemplate>
  <mat-list *ngIf="!loading; else loadingSpinner">
    <ng-container *ngIf="eventTasks.length; else noNotificationsTemplate">
      <coh-notifications-item *ngFor="let notif of eventTasks" [item]="notif">
      </coh-notifications-item>
    </ng-container>
  </mat-list>
</ng-template>

<ng-template #noNotificationsTemplate>
  <div class="no-notifications"></div>
  <div class="no-notifications-msg">
    <p>{{'youreAllCaughtUp' | translate}}</p>
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <cog-spinner class="margin-top"></cog-spinner>
</ng-template>
