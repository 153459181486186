// MODULE: Cluster Setup, Nodes Setup

;(function(angular, undefined) {

  angular.module('C.clusterSetup')
    .controller('clusterSetupNodesController', clusterSetupNodesControllerFn);

  function clusterSetupNodesControllerFn(
    $rootScope, $scope, $state, IP_FAMILY) {

    $scope.text = $rootScope.text.adminClusterSetupNodes;

    $scope.IP_FAMILY = IP_FAMILY;

    $scope.isPhysicalRobo = $rootScope.isPhysicalRobo;

    /**
     * activation function
     */
    function activate() {
      if ($scope.setupMode) {
        $scope.restoreSettingsFromLocalStorage();
      }

      if (!$scope.shared.discoveredNodes.length) {
        if ($scope.setupMode) {
          $state.go('cluster-setup.detect');
        } else {
          $state.go('add-nodes');
        }
      }

      if (!$scope.setupMode) {
        // expandClusterData.nodes should be cleared on arrival. This
        // will prevent nodes from getting duplicated if user comes
        // back to this step from the VIPs step while adding a node
        // to the cluster.
        $scope.expandClusterData.nodes.length = 0;
      }
    }

    /**
     * Direct user to the next step in their current flow
     * If not in setup mode, add selected nodes to expandClusterData
     */
    $scope.nextStep = function nextStep() {

      if ($scope.formSetupNodes.$invalid) {
        return;
      }

      if ($scope.setupMode) {
        $state.go('cluster-setup.details');
      } else {
        // Configure data for expand nodes
        angular.forEach($scope.shared.discoveredNodes,
          function addSelectedNode(node) {
            if (node.selected) {
              $scope.expandClusterData.nodes.push({
                id: node.nodeId,
                hostname: node.hostname,
                ip: $scope.shared.ipFamily === IP_FAMILY.IPv6 ? node._ipv6 : node._ipv4,
                ipmiIp: $scope.shared.disableIpmiNetworkingConfig ? null : node._ipmiIp,
                ipmiGateway: $scope.shared.disableIpmiNetworkingConfig ? null : node._ipmiGateway,
                ipmiSubnetMask: $scope.shared.disableIpmiNetworkingConfig ? null : node._ipmiSubnetMask,
                useAsComputeNode: node._useAsComputeNode
              });
            }
          }
        );
        var rackAssignment = {};
        if ($scope.shared.customFailureDomainsEnabled) {
          angular.forEach($scope.shared.discoveredChassis,
            function addRackAssignment(chassis) {
              if (!chassis.isRackPreAssigned && chassis.assignedRack) {
                rackAssignment[chassis.chassisSerial] = chassis.assignedRack.id;
              }
            });
          $scope.expandClusterData.chassisSerialToRackIdMap = rackAssignment;
        }
        $state.go('add-nodes.vips');
      }
    };

    /**
     * Function to handle click of back button.
     * Go to node discovery page, if not in setup mode.
     * Go to Nodes, Chassis detect state if it is a physical robo node.
     * Go back to select state for everything else.
     */
    $scope.goBackState = function goBackState() {
      switch(true) {
        // Physical ROBO.
        case $scope.isPhysicalRobo:
          $state.go('cluster-setup.detect');
          break;

        // Not Setup mode.
        case !$scope.setupMode:
          $state.go('add-nodes.discover');
          break;

        // Default case.
        default:
          $state.go('cluster-setup.select');
      }
    };

    /**
     * Evaluates if given chassis has selected nodes
     * for use during add node flow
     * @param  {string} chassis chassisSerial
     * @return {boolean}
     */
    $scope.selectedNodesInChassis = function selectedNodesInChassis(chassis) {
      var i = 0;
      var len = $scope.shared.discoveredNodes.length;
      for (i; i < len; i++) {
        if ($scope.shared.discoveredNodes[i].selected &&
          $scope.shared.discoveredNodes[i].chassisSerial === chassis) {
          return true;
        }
      }
      return false;
    };

    /**
     * Toggles use node as a compute node selection.
     * @param node node object
     */
    $scope.toggleComputeNodeSelection = function toggleComputeNodeSelection(node) {
      node._useAsComputeNode = !node._useAsComputeNode;
    };

    /**
     * Reset some of form inputs on change of ip preference.
     */
    $scope.resetInputs = function resetInputs() {
      $scope.shared.cluster.clusterGateway = '';
      $scope.shared.cluster.clusterSubnetCidrLen = '';
    };

    /**
     * Return true if ipmi ip is not an invalid ip(0.0.0.0).
     *
     * @method  validateInvalidIp

     * @param   {String}  ipmiIp   The ipmi ip address input.
     * @return  {Boolean} True if ipmi ip is not invalid. False otherwise.
     */
    $scope.validateInvalidIp = function validateInvalidIp(ipmiIp) {
      // this function runs when component first time mount but it's not dirty. So added below condition to run only when form is dirty
      if ($scope.formSetupNodes.$dirty && ipmiIp) {
        // validation for duplicate IPMI ips
        const selectedNodes = $scope.shared.discoveredNodes.filter(node => node.chassisSerial && node.selected);
        const hasDuplicateIP = selectedNodes.map(node => node._ipmiIp).filter(_ipmiIP => _ipmiIP === ipmiIp).length > 0;
        if (hasDuplicateIP) {
          return false;
        }
      }
      return true;
    };

    activate();
  }

})(angular);
