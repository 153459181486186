import { Component, Input } from '@angular/core';
import { NavState, NavStateService } from './nav-state.service';

/**
 * Recover action button for adding recovery on dashboard or landing page.
 *
 * @example
 *   <coh-recover-button></coh-recover-button>
 */
@Component({
  selector: 'coh-recover-button',
  templateUrl: './recover-button.component.html',
})
export class RecoverButtonComponent {
  /**
   * List of all sources which can be protected.
   */
  @Input() sources: NavState[] = this.navStateService.getRecoverStates();

  /**
   * Indicates the desired color of the button.
   * NOTE: Doesn't apply to FAB implementations.
   */
  @Input() isSecondary = false;

  /**
   * Specifies whether the button style is FAB.
   */
  @Input() isFab = false;

  /**
   * Returns the Angular Material button class to be used.
   */
  get buttonClass(): string {
    return this.isSecondary ? 'mat-stroked-button' : 'mat-flat-button';
  }

  /**
   * Constructor.
   */
  constructor(
    private navStateService: NavStateService
  ) {
  }
}
