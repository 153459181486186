<h3 class="no-margin" translate>mfa.verify.verifyUsingEmail</h3>
<small translate>mfa.verify.verifyUsingEmailDesc</small>
<div>
  <button class="email-code-btn"
    type="button"
    cogDataId="email-code-btn"
    mat-button
    color="primary"
    (click)="emailCode()">
    {{'mfa.verify.emailMyCode' | translate}}
  </button>
</div>
<p class="margin-top-lg" translate>mfa.verify.codeFromEmail</p>

<form class="coh-form" [formGroup]="formGroup">
  <mat-form-field class="token-input-field" floatLabel="always">
    <mat-label translate>securityCode</mat-label>
    <input cogDataId="mfa-email-verification"
      type="email"
      matInput
      formControlName="emailToken">
    <mat-error *ngIf="formGroupControls.emailToken.invalid">
      {{'errors.required' | translate}}
    </mat-error>
  </mat-form-field>
</form>
