<p translate>mfa.verify.authDescription</p>

<form class="coh-form" [formGroup]="formGroup">
  <mat-form-field floatLabel="always">
    <mat-label translate>securityCode</mat-label>
    <input cogDataId="mfa-email-verification"
      type="email"
      matInput
      [formControlName]="formControlNames.securityToken">
    <mat-error *ngIf="formGroupControls.securityToken.invalid">
      {{'errors.required' | translate}}
    </mat-error>
  </mat-form-field>
</form>
