<div class="schedule-selector-container flex-row-md end-align"
  [ngClass]="className"
  [formGroup]="form">
  <coh-time-period-selector
    *ngIf="!hidePeriodicitySelector"
    id="{{id}}-periodicity"
    cogDataId="periodicity"
    [inheritDataId]="true"
    class="time-period-form-field periodicity-time-selector"
    [label]="label"
    [ariaLabelPrefix]="ariaLabelPrefix"
    [valueLimit]="periodicityValueLimit"
    (valueChange)="updateValue($event)"
    [class.sm]="requiresDaysofWeekSelector || requiresDayOfMonthSelector || requiresDayOfYearSelector"
    [form]="form.timePeriod">
  </coh-time-period-selector>

  <ng-container *ngIf="requiresDayOfYearSelector">
    <coh-time-period-selector
      cogDataId="day-of-year"
      [inheritDataId]="true"
      class="time-period-form-field sm"
      [label]="'on' | translate"
      [ariaLabelPrefix]="ariaLabelPrefix"
      [form]="form.dayOfYear">
    </coh-time-period-selector>
  </ng-container>

  <div *ngIf="requiresDayCountSelector">
    <coh-time-period-selector
      id="{{id}}-week-of-month"
      cogDataId="week-of-month"
      [inheritDataId]="true"
      class="time-period-form-field sm"
      [label]="'on' | translate"
      [ariaLabelPrefix]="ariaLabelPrefix"
      [form]="form.dayCount">
    </coh-time-period-selector>
  </div>

  <div *ngIf="requiresDaysofWeekSelector">
    <mat-form-field class="days-form-field"
      [class.lg]="!requiresDayCountSelector">

      <mat-label *ngIf="!requiresDayCountSelector">{{'on' | translate}}</mat-label>

      <!-- Multiple Days of Week Chip List -->
      <mat-chip-list *ngIf="!requiresDayCountSelector"
        formControlName="days"
        [selectable]="true"
        [multiple]="true"
        color="primary"
        [attr.aria-label]="ariaLabelPrefix ? (ariaLabelPrefix + 'OnDayOfWeek' | translate) : ''">
        <mat-chip class="chip"
          id="{{id}}-day-of-week-chip-{{day.key}}"
          cogDataId="day-of-week-chip-{{day.key}}"
          [inheritDataId]="true"
          *ngFor="let day of DayVal | enumKeys"
          #chip="matChip"
          [selectable]="true"
          [removable]="false"
          [value]="day.key"
          (click)="chip.toggleSelected(true)">
          <div class="chip-label">{{day.key | dayVal:true}}</div>
        </mat-chip>
      </mat-chip-list>
      <mat-error *ngIf="form?.days?.invalid">
        <span translate>
          {{form.getErrorMessage('days')}}
        </span>
      </mat-error>

      <!-- Single Day of Week Selector -->
      <mat-select id="{{id}}-days-select"
        cogDataId="select-days"
        [inheritDataId]="true"
        *ngIf="requiresDayCountSelector"
        [aria-label]="ariaLabelPrefix ? (ariaLabelPrefix + 'DayOfWeek' | translate) : ''"
        formControlName="day">
        <mat-option *ngFor="let day of DayVal | enumKeys"
          id="{{id}}-day-option-{{day.key}}"
          cogDataId="day-option-{{day.key}}"
          [inheritDataId]="true"
          [value]="day.key">
          {{day.key | dayVal}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- Day of Month calendar-->
  <div *ngIf="requiresDayOfMonthSelector">
    <mat-form-field class="sm">
      <input type="number" matInput
        formControlName="dayOfMonth"
        cogDataId="day-of-month-input"
        [inheritDataId]="true"
        (click)="menuTrigger.openMenu()"
        min="1" max="31">
      <button type="button"
        matSuffix mat-icon-button
        #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="calendarView"
        cogDataId="day-of-month-calendar-button"
        [inheritDataId]="true">
        <cog-icon size="sm" shape="event"></cog-icon>
      </button>
      <mat-error *ngIf="form?.dayOfMonth?.invalid">
        {{form.getErrorMessage('dayOfMonth') | translate: { minValue: 1, maxValue: 31 } }}
      </mat-error>
    </mat-form-field>
    <mat-menu #calendarView="matMenu" [overlapTrigger]="false">
      <div class="calendar-view-container">
        <button mat-icon-button
          type="button"
          *ngFor="let day of daysInMonth"
          [class.mat-calendar-body-selected]="form.value?.dayOfMonth === day"
          cogDataId="day-of-month-{{day}}"
          [inheritDataId]="true"
          (click)="updateDayOfMonth(day)">
          {{day}}
        </button>
      </div>
    </mat-menu>
  </div>
</div>
