// HTTP Interceptor Service: Service Context
// Add the Cohesity Service Context for calls made from AJS Service.

;(function(angular, undefined) {
  'use strict';

  angular.module('C')
  .service('ServiceContextInterceptor', ServiceContextInterceptorFn)
  .config(function InterceptConfig($httpProvider) {
    $httpProvider.interceptors.push('ServiceContextInterceptor');
  });

  function ServiceContextInterceptorFn(_, $injector) {
    var $rootScope;
    var NgAppServiceManager;
    var serviceTypeHeader = 'X-Cohesity-Service-Context';

    return {
      request: function requestInterceptor(req) {
        var headers = req.headers;
        $rootScope = $rootScope || $injector.get('$rootScope');
        NgAppServiceManager =
          NgAppServiceManager || $injector.get('NgAppServiceManager');

        if (!_.get($rootScope, 'basicClusterInfo.mcmMode', false)) {
          return req;
        }

        if (!headers.hasOwnProperty(serviceTypeHeader)) {
          headers[serviceTypeHeader] = NgAppServiceManager.getAuditTag();
        }

        return req;
      }
    };
  }

})(angular);
