import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { BannerConfig, BannerLocation, Location } from './app-banner.model';

@Injectable({
  providedIn: 'root',
})
export class AppBannerService {
  /**
   * Collection of all registered locations
   */
  private locations: { [key: string]: Location } = {};

  /**
   * Returns the Location object that matches given location identifier
   *
   * @param  location  location identifier
   */
  private getLocation(location: string): Location {
    if (!this.locations[location]) {
      const behaviorSubject = new BehaviorSubject<BannerConfig[]>(undefined);
      this.locations[location] = {
        behaviorSubject,
        observable: behaviorSubject.asObservable(),
      };
    }

    return this.locations[location];
  }

  /**
   * Returns the observable from Location object that matches given location identifier
   *
   * @param  location  location identifier
   */
  getLocation$(location: string): Observable<BannerConfig[]> {
    return this.getLocation(location).observable;
  }

  /**
   * Appends a banner to cog-banners
   *
   * @param  config  contains all banner params
   */
  add(config: BannerConfig) {
    const location = config.location ?? BannerLocation.top;
    const behaviorSubject = this.getLocation(location).behaviorSubject;
    if (this.hasBannerActive(config.id, location)) {
      return;
    }

    const banners = this.getBannersInLocation(location) || [];
    banners.push(config);
    banners.sort((a, b) => (a.priority > b.priority ? -1 : 1));
    behaviorSubject.next(banners);
  }

  /**
   * Removes the current banner from banner pagination
   *
   * @param bannerId  Unique string
   * @param location  location identifier
   */
  remove(bannerId: string, location = BannerLocation.top) {
    if (bannerId) {
      const bannerList = this.removeBanner(bannerId, location);
      this.getLocation(location).behaviorSubject.next(bannerList);
      return;
    }
    this.getLocation(location).behaviorSubject.next(undefined);
  }

  /**
   * Returns all the banners in the location.
   *
   * @param   location  The location of the banner. Default is top
   * @returns List of banner in the given location.
   */
  private getBannersInLocation(location = BannerLocation.top): BannerConfig[] {
    const behaviorSubject = this.getLocation(location).behaviorSubject;
    return behaviorSubject.value;
  }

  /**
   * Returns the index if the banner exist.
   *
   * @param   bannerId   Unique string
   * @param   location   The location to in which we query for the banner present.
   * @returns The index of the banner, else -1.
   */
  private bannerIndex(bannerId: string, location = BannerLocation.top): number {
    const banners = this.getBannersInLocation(location);
    return banners?.findIndex(config => config.id === bannerId);
  }

  /**
   * Returns whether the banner is in the queue to be rendered.
   *
   * @param   bannerId   Unique string
   * @param   location   The location to in which we query for the banner present.
   * @returns Returns True, if the banner is present.
   */
  private hasBannerActive(bannerId: string, location = BannerLocation.top): boolean {
    return this.bannerIndex(bannerId, location) >= 0;
  }

  /**
   * Removes the banner from the given location
   *
   * @param   bannerId   Unique string
   * @param   location   The location to in which we query for the banner present.
   * @returns Returns the list of banner after removing from list if present.
   */
  private removeBanner(bannerId: string, location = BannerLocation.top): BannerConfig[] {
    const banners = this.getBannersInLocation(location);
    const bannerIndex = this.bannerIndex(bannerId, location);
    if (bannerIndex >= 0) {
      banners.splice(bannerIndex, 1);
    }
    return banners;
  }
}
