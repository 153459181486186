import { Injectable } from '@angular/core';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { HookMatchCriteria, StateDeclaration, StateObject, Transition } from '@uirouter/core';
import { McmViewService } from 'src/app/core/services';
import { GuardPriority, GuardResult, StateGuard } from 'src/app/core/state/state-guard';

/**
 * State guard to control transition b/w new and old data tiering pages bases on the feature flag value.
 */
@Injectable({
  providedIn: 'root',
})
export class DataTieringGuard implements StateGuard {
  /**
   * Data tiering guard should run after app got bootstrapped.
   */
  guardPriority = GuardPriority.App;

  /**
   * Specifies the old to new state mapping for data tiering pages.
   */
  private readonly stateMapping = new Map<string, string>([
    ['dataMigration', 'data-tiering.dashboard'],
    ['file-stubbing-modify', 'data-tiering'],
    ['protection-builder-data-migration', 'data-tiering'],
  ]);

  private readonly stateMappingInverse = new Map<string, string>();

  /**
   * The data tiering guard matching criteria.
   */
  matchCriteria: HookMatchCriteria = {
    to: (() => {
      // IIFE is used to create the closure of all states here used inside HookMatchCriterion.
      const allStates = new Set<string>();

      this.stateMapping.forEach((newState, oldState) => {
        this.stateMappingInverse.set(newState, oldState);

        // run this data tiering guard for both old and new states.
        [oldState, newState].forEach(state => {
          allStates.add(state);

          // run this guard for future and create & edit pages for both old and new states.
          allStates.add(`${state}.**`);
          allStates.add(`${state}.create`);
          allStates.add(`${state}.edit`);
        });
      });

      // the data tiering hook matching criterion method.
      return (state: StateObject) => allStates.has(state.name);
    })(),
  };

  constructor(private irisCtx: IrisContextService, private mcmViewService: McmViewService) {}

  /**
   * Ensures the transition going new data tiering page if flag is enabled else allow transition to old states.
   *
   * @param   transition  The ui router transition.
   * @returns Redirect to valid target state.
   */
  onStart(transition: Transition): GuardResult {
    const toState = transition.to();
    const params = transition.params();

    // do nothing if new data tiering is disabled.
    if (!flagEnabled(this.irisCtx.irisContext, 'dataTieringEnabled')) {
      return;
    }

    // compute the valid target state.
    const validStateName = this.getValidState(toState, params);

    // got to valid target state of current transition is going to invalid state.
    if (validStateName !== toState.name && this.mcmViewService.canAccessState(validStateName)) {
      return transition.router.stateService.target(validStateName, params, transition.options());
    }
  }

  /**
   * Return the valid target state name for the provided state & params.
   *
   * @param toState The target state.
   * @param params  The target params.
   * @returns  The valid target state name.
   */
  private getValidState(toState: StateDeclaration, params: any): string {
    // extract the parent and child target state names.
    const [parent, child] = toState.name.split('.');
    let suffix = child ? `.${child}` : '';
    let validState = '';

    if (params.id) {
      suffix = '.edit';
    }

    if (this.stateMapping.has(parent)) {
      validState = `${this.stateMapping.get(parent)}${suffix}`;
    } else if (this.stateMappingInverse.has(parent)) {
      validState = toState.name;
    }

    return validState;
  }
}
