// COMPONENT:  Cloud Restore: Remote Restore Tasks

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.cloud-retrieval')
    .component('cloudRestoreTasks', {
      controller: cloudRestoreTasksControllerFn,
      templateUrl: 'app/protection/cloud-retrieval/cloud-restore-tasks.html',
    });

  /**
   * cloudRestoreTasks Controller
   **************************************************************************/
  function cloudRestoreTasksControllerFn(
    $scope, $state, $q, cUtils, evalAJAX, RestoreService, PollTaskStatus,
    moment, ExternalTargetService, SearchService, cMessage, ENV_GROUPS,
    IRIS_VAULT_JOB_STATUS, FEATURE_FLAGS) {

    var ctrl = this;
    var taskPathsHash = {};

    angular.extend(ctrl, {
      $onInit: $onInit,

      // General Template Vars
      restoreTasks: [],
      IRIS_VAULT_JOB_STATUS: IRIS_VAULT_JOB_STATUS,

      // Methods
      isIndexingComplete: ExternalTargetService.isIndexingComplete,
      isSnapshotDownloadComplete: ExternalTargetService.isSnapshotDownloadComplete,
      failedStatus: [2, 3],
    });

    // METHODS
    /**
     * Activate this ngController!
     *
     * @method   $onInit
     */
    function $onInit() {
      getRestoreTasks();
    }


    /**
     * Fetch the active and competed cloud retrieval tasks and expose them to
     * the template.
     *
     * @method   getRestoreTasks
     */
    function getRestoreTasks() {
      getData().then(getPulseData);
    }

    /**
     * Gets the submitted restoreTasks data and binds it to the controller.
     *
     * @method   getData
     * @return   {object}   Promise to resolve with the requested restoreTasks.
     */
    function getData() {
      return RestoreService
        .getRemoteRestoreTasks()
        .then(
          function thenHandler(resp) {
            // Reset the glancebar stats because they're about to get rebuilt.
            resetGlanceStats();

            // Process the response further for local use.
            ctrl.restoreTasks = resp.reduce(processTasks, []);
          },

          evalAJAX.errorMessage
        )
        .finally(function finallyHandler() {
          ctrl.isDataReady = true;
        });
    }

    /**
     * Gets the pulse data.
     *
     * @method   getPulseData
     * @return   {object}   Promise to resolve with the results of the
     *                      pollingIterator Fn.
     */
    function getPulseData() {
      var pulsePaths = getPathsFromHash();

      return PollTaskStatus.createPoller({
        interval: 60,
        isDoneFn: isPollingDone,
        iteratorFn: pollingIterator,
        scope: $scope,
      });
    }

    /**
     * Determines if polling is done.
     *
     * @method   isPollingDone
     * @return   {boolean}   True if polling done, False otherwise.
     */
    function isPollingDone() {
      return !!ctrl.restoreTasks.length && !getPathsFromHash().length;
    }

    /**
     * Function executed at every iteration of the poller.
     *
     * @method   pollingIterator
     * @return   {object}   Promise to resolve with the poller response.
     */
    function pollingIterator() {
      // Using the current hash of paths, fetch their pulse data
      var pulseOptions = {
        taskPathVec: getPathsFromHash(),
        includeFinishedTasks: true,
      };

      return !pulseOptions.taskPathVec.length ?

        // Resolve early with nothing if there are no active icebox jobs. This
        // will prevent unnecessary polling.
        $q.resolve([]) :

        // Otherwise we will poll any found unfinished icebox jobs.
        PollTaskStatus.getProgress(pulseOptions)
          .then(function pulseReceived(resp) {
            if (!resp.resultGroupVec || resp.error) {
              return [];
            }

            resp.resultGroupVec.forEach(updateTaskWithPulse);

            return resp.resultGroupVec;
          });
    }

    /**
     * Array#forEach iterator that updates the restoreTask associated with the
     * given pulse object.
     *
     * @method   updateTaskWithPulse
     * @param    {object}   singleResultGroupVec   The single result group item.
     */
    function updateTaskWithPulse(singleResultGroupVec) {
      // With this pulse response, locate it's path in taskPathsHash and
      getPathsFromHash().find(function findAndUpdatePulse(taskVecPath) {
        var taskVecPropertyOnRestoreTask;
        var restoreTask;
        var taskVec =
          (singleResultGroupVec.taskVec && singleResultGroupVec.taskVec[0]) ||
          undefined;

        // Quick check if this is the task we're looking for. No? Continue
        // looking.
        if (!taskVec || !taskVecPath.includes(taskVec.taskPath)) {
          return;
        }

        restoreTask = getTaskFromHash(taskVecPath);

        switch (true) {
          case (restoreTask._indexingPath === taskVecPath):
            taskVecPropertyOnRestoreTask = '_indexingTaskVec';
            break;

          case (restoreTask._snapshotPath === taskVecPath):
            taskVecPropertyOnRestoreTask = '_snapshotTaskVec';
            break;
        }

        restoreTask[taskVecPropertyOnRestoreTask] = taskVec;


        restoreTask._actions = generateTaskActions(restoreTask);

        // When a match is completed, remove it's path from taskPathsHash to
        // prevent polling it again.
        if (taskVec.progress.endTimeSecs) {
          removeTaskPath(taskVecPath);
        }
      });
    }

    /**
     * Gets the list of pulse taskPaths in the taskPathsHash
     *
     * @method   getPathsFromHash
     * @return   {array}   The list of pulse paths.
     */
    function getPathsFromHash() {
      return Object.keys(taskPathsHash);
    }

    /**
     * Removes a task path from the hash.
     *
     * @method   removeTaskPath
     * @param    {string}   path   The path
     * @return   {object}   The updated (reduced) hash
     */
    function removeTaskPath(path) {
      if (taskPathsHash[path]) {
        // Using delete to maintain a clean hash for when it's converted to an
        // array of paths.
        delete taskPathsHash[path];
      }

      return taskPathsHash;
    }

    /**
     * Helper method to fetch the task from the Hash by path.
     *
     * @method   getTaskFromHash
     * @param    {string}   path   The path
     * @return   {object}   The found restoreTask
     */
    function getTaskFromHash(path) {
      return taskPathsHash[path];
    }

    /**
     * Array#reduce iterator that processes each task.
     *
     * @method   processTasks
     * @param    {array}    tasks   accumulated tasks list.
     * @param    {object}   task    The task.
     * @return   {array}    The updated accumulated list of processed tasks.
     */
    function processTasks(tasks, task) {
      task._isIndexingDone =
          ExternalTargetService.isIndexingComplete(task);

      task._isSnapshotDownloadDone =
          ExternalTargetService.isSnapshotDownloadComplete(task);

      task._isExpired =
        ExternalTargetService.isTaskExpired(task.currentSnapshotStatus) ||
        ExternalTargetService.isTaskExpired(task.currentIndexingStatus);

      task._actions = generateTaskActions(task);

      task.currentIndexingStatus._duration =
        (task.currentIndexingStatus.indexingTaskEndTimeUsecs ||
          Date.clusterNow() * 1000) -
        task.currentIndexingStatus.indexingTaskStartTimeUsecs;

      updateTaskPathsHash(task);
      udpateGlanceStats(task);

      return tasks.concat(task);
    }

    /**
     * Updates glance bar stats.
     *
     * @method   udpateGlanceStats
     * @param    {object}   task   Icebox job
     */
    function udpateGlanceStats(task) {
      var indexing = task.currentIndexingStatus || {};
      var snapshotting = task.currentSnapshotStatus || {};
      var rxSuccess = /succeeded/i;

      /**
       * Value by which to increment the running tally if an indexing sub-task
       * is currently running. 0 if there's no user-defined indexing sub-task
       * *or* there is a user-defined sub-task and it's done; or 1 otherwise.
       *
       * @type   {integer}
       */
      var indexingRunningIncrementer =
        (task._hasIndexingTask && task._isIndexingDone) ||
        !task._hasIndexingTask ?
        0 : 1;

      /**
       * Value by which to increment the running tally if a snapshot retrieval
       * sub-task is cuerrently running. 0 if it's done, or 1 otherwise.
       *
       * @type   {integer}
       */
      var snapshottingRunningIncrementer = task._isSnapshotDownloadDone ? 0 : 1;

      // Job (row) level
      // Increment the counter if either task is running
      ctrl.glanceBarStats.running +=
        (snapshottingRunningIncrementer || indexingRunningIncrementer ? 1 : 0);

      // Tasks (column) level
      if (task._hasIndexingTask) {
        ctrl.glanceBarStats.success +=
          rxSuccess.test(indexing.indexingTaskStatus) ? 1 : 0;

        ctrl.glanceBarStats.errors += indexing.error ? 1 : 0;
      }

      if (task._hasSnapshottingTask) {
        ctrl.glanceBarStats.success +=
          rxSuccess.test(snapshotting.snapshotTaskStatus) ? 1 : 0;

        ctrl.glanceBarStats.errors += snapshotting.error ? 1 : 0;
      }
    }

    /**
     * Resets the glance bar stats.
     *
     * @method   resetGlanceStats
     */
    function resetGlanceStats() {
      ctrl.glanceBarStats = {
        running: 0,
        success: 0,
        errors: 0,
      };
    }

    /**
     * Given a restoreTask, update the hash of tasks still in progress. Each
     * iteration of the poller can potentially reduce this hash as tasks
     * comeplete to prevent additional work when completed.
     *
     * @method   updateTaskPathsHash
     * @param    {object}   task   The restoreTask
     */
    function updateTaskPathsHash(task) {
      /**
       * Each potential property in pollableTaskProperties[] (and it's companion
       * taskPathProperties[]) lets us dynamically access the parts of the
       * restoreTask proto later on when processing pulse responses to retrieve
       * the `progressMonitorTask` property for each of the two types of icebox
       * sub-tasks each restoreTask can have.
       */
      var pollableTaskProperties = [];

      // Parallel array of property names to bind the path string found in the
      // above properties for easy access.
      var taskPathProperties = [];

      // Sanity check. Terminate if this task doesn't exist, for some bizzare
      // reason.
      if (!task) {
        return;
      }

      if (task._hasIndexingTask && !task._isIndexingDone) {
        // For example, when looking at
        // task.currentIndexingStatus.progressMonitorTask, copy that property to
        // task._indexingPath.
        pollableTaskProperties.push('currentIndexingStatus');
        taskPathProperties.push('_indexingPath');
      }

      if (task._hasSnapshottingTask && !task._isSnapshotDownloadDone) {
        pollableTaskProperties.push('currentSnapshotStatus');
        taskPathProperties.push('_snapshotPath');
      }

      pollableTaskProperties.forEach(function eachPollableProp(prop, ii) {
        /**
         * Example: prop = currentSnapshotStatus; ii = 0;
         *
         * This iterator attaches the path to the restoreTask for hash map
         * reference as: task._snapshotPath === 'the_progress_monitor_task_path'
         * and taskPathsHash[path] = a reference to this restoreTask.
         *
         * This way, when updateTaskWithPulse() runs, it can quickly access the
         * progressMonitorTask path without traversing the proto, and update the
         * hash as various icebox jobs complete (the pollers).
         */
        var typedTask = task[prop];
        var path = typedTask.progressMonitorTask;

        // path should be unique, but in case it's not, lets not add duplicates
        // because progressMonitors wil return duplicate results.
        if (path && !taskPathsHash[path]) {
          task[taskPathProperties[ii]] = path;

          // Also update the taskPathsHash with this path.
          taskPathsHash[path] = task;
        }
      });
    }

    /**
     * Generates the list of actions for the given task, in it's current state.
     *
     * @method   generateTaskActions
     * @param    {object}   task   The remote download task
     * @return   {array}    The list of actions.
     */

    // TODO(spencer): Move this to a service. But which service??
    function generateTaskActions(task) {
      var jobData = task.remoteProtectionJobInformation;
      var env = jobData.environment;
      var localJobUid = task.localProtectionJobUid;
      var snapshotInfo = task.currentSnapshotStatus || {};
      var indexingInfo = task.currentIndexingStatus || {};
      var actions = [];
      var toStateParams;
      var recoverInstantVolumeMountAtion;

      // Add Cancel Job Action
      if ((task._hasIndexingTask && !task._isIndexingDone) ||
        (task._hasSnapshottingTask && !task._isSnapshotDownloadDone)) {

        actions.push({
          translateKey: 'cancelDownload',
          icon: 'icn-cancel',
          action: function() {
            var promises = {};

            if (!task._isIndexingDone && indexingInfo.indexingTaskUid) {
              promises.indexingJob =
                ExternalTargetService
                  .stopVaultJob(indexingInfo.indexingTaskUid);
            }

            if (!task._isSnapshotDownloadDone && snapshotInfo.snapshotTaskUid) {
              promises.snapshotJob =
                ExternalTargetService
                  .stopVaultJob(snapshotInfo.snapshotTaskUid);
            }

            $q.all(promises)
              .then(function stopVaultJobSuccess() {
                cMessage.success({
                  titleKey: 'cloudRestoreTasks.runRequestSuccessTitle',
                  textKey: 'cloudRestoreTasks.runRequestSuccessBody',
                });
              }, evalAJAX.errorMessage);
          },
        });
      }

      // If this snapshot download task is completed, we can restore some types
      // from here.
      if (ExternalTargetService.isOverallTaskDone(task) && localJobUid) {
        toStateParams = {
          cloudRetrieve: true,
          jobId: localJobUid.id,
          jobInstanceId: snapshotInfo.jobRunId,
          jobRunStartTime: snapshotInfo.snapshotTimeUsecs,
          jobUid: localJobUid,
        };

        if (!task._isExpired) {
          // Relative to ENUM_ENV_TYPE
          switch (true) {

            // Hypervisor environments
            case ENV_GROUPS.hypervisor.includes(env):

              // If snapshot download has an error or no snapshot is retrieved,
              // we can't continue.
              if (snapshotInfo.error || !snapshotInfo.jobRunId) {
                break;
              }

              // Specific Restore Point restore shortcuts
              actions.push(
                {
                  translateKey: 'recoverDownloadedSnapshot',
                  icon: 'icn-recover',
                  state: 'recover-vm.recover-options',
                  stateParams: toStateParams,
                }
              );

              // disallow clone option for acropolis/awsNative/azureNative
              if (!ENV_GROUPS.cloneUnsupported.includes(env)) {

                actions.push({
                  translateKey: 'cloneDownloadedSnapshot',
                  icon: 'icn-recover',
                  state: 'clone-vms.clone-options',
                  stateParams: toStateParams,
                });
              }

              break;

            // SQL environments
            case env === 'kSQL':
              toStateParams.dbType = 'sql';

              // If snapshot download has an error, we can't continue.
              if (!task._isSnapshotDownloadDone || snapshotInfo.error) {
                break;
              }

              actions.push(
                {
                  translateKey: 'recoverDownloadedSnapshot',
                  icon: 'icn-recover',
                  state: 'recover-db.options',
                  stateParams: toStateParams,
                },
                {
                  translateKey: 'cloneDownloadedSnapshot',
                  icon: 'icn-clone',
                  state: 'clone-db.options',
                  stateParams: toStateParams,
                }
              );
              break;

            // SAN environments
            case ENV_GROUPS.san.includes(env):

              // If snapshot download has an error, we can't continue.
              if (!task._isSnapshotDownloadDone || snapshotInfo.error) {
                break;
              }

              actions.push({
                translateKey: 'recoverDownloadedSnapshot',
                icon: 'icn-recover',
                action: function() {
                  /**
                   * This action is "dumb." Because a Pure PJ can protect
                   * multiple volumes but a Pure Recovery canonly recover 1,
                   * we have no way from here to know which volume to choose
                   * for recovery. So we blindly grab the first matching result
                   * by jobId (+jobInstanceId). This will need some thought
                   * and modification from UX to get it right. But it does work.
                   */
                  getPureVolume(toStateParams.jobId, toStateParams.jobInstanceId)
                    .then(function pureVolumeFound(pureVolume) {
                      var toStateName = FEATURE_FLAGS.restoreStorageVolume ?
                        'recover-storage-volume.pure-options' :
                        'recover-pure.options';
                      $state.go(toStateName,
                        angular.extend(
                          {entityId: pureVolume.vmDocument.objectId.entity.id,},
                          toStateParams));
                    });
                },
              });
              break;

            // View environments
            case env === 'kView':

              // If snapshot download has an error, we can't continue.
              if (!task._isSnapshotDownloadDone || snapshotInfo.error) {
                break;
              }

              actions.push({
                translateKey: 'cloneDownloadedSnapshot',
                icon: 'icn-clone',
                action: function() {
                  getView(toStateParams.jobId, toStateParams.jobInstanceId).then(
                    function viewFound(view) {
                      $state.go('clone-view.options', angular.extend({
                        view: view.vmDocument.objectId.entity,
                        protected: true,
                        snapshotUsecs: view._snapshotUsecs,
                      }, toStateParams));
                    }
                  );
                },
              });
              break;
          }
        }
      }

      // TODO(spencer): Uncomment and finish coding this when there's actually
      // pulse to display. When this lands, logs will also have an expiry time
      // after the task expiry time. Proto changes TBD in 5.0.
      // actions.push({
      //   translateKey: 'showLog',
      //   icon: 'icn-view',
      //   action: function() {
      //     showPulseModal(task);
      //   },
      // });

      return actions;
    }

    /**
     * Get a view by jobId & jobInstanceId.
     *
     * @method   getView
     * @param    {number}   jobId             The job ID
     * @param    {number}   [jobInstanceId]   The job instance ID
     * @return   {object}   Promise to resovle with the found view, or
     *                      undefined.
     */
    function getView(jobId, jobInstanceId) {
      return SearchService.viewSearch({ jobIds: jobId }).then(
        function viewReceived(views) {
          // Find the matching View. Handles multiple search results until a
          // match is found. Will be undefined if no match is found.
          return views.find(function eachView(view) {
            // Is this a job match?
            if (jobId === view.vmDocument.objectId.jobId) {
              // We have a job match. If no jobInstanceId was passed, then we're
              // done. Otherwise...
              return !jobInstanceId ||

                // Search versions for a matching jobInstanceId.
                view.vmDocument.versions.some(function eachRun(run) {
                  if (run.instanceId.jobInstanceId === jobInstanceId) {
                    // Attach a shortcut to the jobInstanceId's snapshotUsecs
                    // for use outside this Fn. Returns true and stops this
                    // loop.
                    return view._snapshotUsecs = run.snapshotTimestampUsecs;
                  }
                });
            }
          });
        },

        evalAJAX.errorMessage
      );
    }

    /**
     * Gets the Pure Volumes.
     *
     * NOTE: This is partially complete. Because a Pure PJ can protect multiple
     * volumes, but a Pure Recovery can only recover a single volume, we have no
     * way of determining from here which volume to pre-select. So this function
     * blindly selects the first result with a matching jobId (+jobInstanceId).
     *
     * @method   getPureVolume
     * @param    {number}   jobId             The job id.
     * @param    {number}   [jobInstanceId]   The job instance id.
     * @return   {object}   Promise to resolve with the found Pure job, or
     *                      undefined.
     */
    function getPureVolume(jobId, jobInstanceId) {
      return SearchService.pureSearch({ jobIds: jobId }).then(
        function pureVolumeReceived(volumes) {
          // In all the results, find the first result with thie given jobId
          return volumes.find(function eachJob(volume) {
            // When that's a match...
            if (volume.vmDocument.objectId.jobId === jobId) {
              // Return true if no run was specified, OR
              return !jobInstanceId || volume.vmDocument.versions.some(
                function eachJobRun(run) {
                  // if specified, find the given run in the runs list by
                  // jobInstanceId
                  return jobInstanceId === run.instanceId.jobInstanceId;
                }
              );
            }
          });
        },

        evalAJAX.errorMessage
      );
    }

  }

})(angular);
