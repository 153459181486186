import { Component, Input } from '@angular/core';
import { ProtectionSourceTreeInfo, SfdcParams } from '@cohesity/api/v1';
import { get } from 'lodash';

/**
 * Sfdc source details settings components. Used for displaying the
 * registered Sfdc source's configuration values.
 */
@Component({
  selector: 'coh-sfdc-source-details-settings',
  templateUrl: './source-details-settings.component.html'
})
export class SfdcSourceDetailsSettingsComponent {

  /**
   * Input source.
   */
  @Input() source: ProtectionSourceTreeInfo;

  /**
   * Getter for retrieving SfdcParams from source input.
   */
  get sfdcParams(): SfdcParams {
    return get(this.source, 'registrationInfo.sfdcParams') as SfdcParams;
  }

  /**
   * Getter for retrieving total number of salesforce user licenses in the organization.
   */
  get usedSalesforceLicenses(): number {
    return this.source?.rootNode?.sfdcProtectionSource?.orgInfo?.usedSfLicenses || 0;
  }
}
