import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

/**
 * This component shows the icons for objects in recovery search tables. It sets the
 * icon size, adds a circular background to the the icon, and allows the display to
 * toggle between the icon and a checked button.
 *
 * @example
 * <coh-search-object-icon iconClass="icn-{{row.adObjectMetaData | adObjectIcon}}"
 *   [selected]="isObjectSelected(row)">
 * </coh-search-object-icon>
 */
@Component({
  selector: 'coh-search-object-icon',
  templateUrl: './search-object-icon.component.html',
  styleUrls: ['./search-object-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchObjectIconComponent {
  /**
   * This is the shape to use for the icon. This will map to a cog-icon shape. Use this for
   * material or inline svg icons.
   */
  @Input() iconShape: string;

  /**
   * If set, the icon will be set using a style, use this for legacy icons.
   */
  @Input() iconClass: string;

  /**
   * Whether the icon is selected or not. If selected, this will render a check icon.
   */
  @Input() @HostBinding('class.selected') selected = false;

}
