import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { QuorumError } from '../models/quorum-error.interface';

/**
 * This interceptor checks for two things -
 *  1) Whether an API call is returning status code 202 for Quorum security
 *     feature. In case of 202 error, user will be notified that the operation
 *     (request) that was made is under Quorum policy and needs approval.
 *  2) Whether a quorum group needs to be created to make the API call.
 */
@Injectable()
export class QuorumHttpInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map(response => {
        const httpResponse = response as HttpResponse<any>;

        // Check for when a quorum request is created for the API call.
        const quorumResponse = httpResponse?.status === 202
          && httpResponse?.body?.quorumResponse;

        if (quorumResponse) {
          // Throw an error, so that the API request's subscribe block is not
          // executed. This error is later intercepted in AppAjaxHandlerService.
          // eslint-disable-next-line
          throw<QuorumError> {
            name: 'QuorumError',
            options: {
              type: 'ApprovalRequired',
              id: quorumResponse?.id,
              isRequestExistsAlready: quorumResponse?.isRequestExistsAlready,
            }
          };
        }

        // Check for when a quorum needs to be created first before making
        // the API call.
        // TODO: This hardcoded to only recovery vault error, negotiate
        // the return of a token instead from backend.
        const quorumGroupRequired = !httpResponse?.ok
          && httpResponse?.body?.errorCode === 'KValidationError'
          && httpResponse?.body?.message === 'Set quorom group to restrict recovery from vault.';

        if (quorumGroupRequired) {
          // Throw an error, so that the API request's subscribe block is not
          // executed. This error is later intercepted in AppAjaxHandlerService.
          // eslint-disable-next-line
          throw<QuorumError> {
            name: 'QuorumError',
            options: {
              type: 'GroupRequired',
            }
          };
        }

        return response;
      })
    );
  }
}
