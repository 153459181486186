import { Injectable } from '@angular/core';
import { Tenant } from '@cohesity/api/v1';
import { BehaviorSubject } from 'rxjs';

/**
 * Tenant (aka organization, multi-tenancy, MT) management service contains.
 *
 * 1. impersonated tenant state.
 */
@Injectable({
  providedIn: 'root'
})
export class TenantService {
  /**
   * Holds the impersonated tenant object as an observable.
   *
   * @example
   *   // how to get current impersonated tenant.
   *   const tenant = TenantService.impersonatedTenant;
   *
   *   // how to get current impersonated tenant's tenantId.
   *   const tenantId = TenantService.impersonatedTenantId;
   *
   *   // how to update impersonated tenant.
   *   TenantService.impersonatedTenant = newTenant;
   *
   *   // how to clear impersonated tenant.
   *   TenantService.clearImpersonatedTenant();
   *
   *   // how to subscribe for impersonated tenant updates.
   *   // NOTE: don't forget to unsubscribe once done to prevent un-necessary update notifications.
   *   const subscription = TenantService.impersonatedTenant$.subscribe({
   *     next: tenant => {
   *       // do something with updated impersonated tenant.
   *     },
   *   });
   *   subscription.unsubscribe();
   */
  impersonatedTenant$ = new BehaviorSubject<Tenant>(undefined);

  /**
   * Gets the impersonated tenant Id.
   *
   * @returns  The impersonated tenant Id.
   */
  get impersonatedTenantId(): string {
    return this.impersonatedTenant && this.impersonatedTenant.tenantId;
  }

  /**
   * Get the impersonated tenant object.
   *
   * @returns The impersonated tenant object.
   */
  get impersonatedTenant(): Tenant {
    return this.impersonatedTenant$.getValue();
  }

  /**
   * Set the impersonation tenant object.
   *
   * @param  tenant  The new tenant object to impersonate.
   */
  set impersonatedTenant(tenant: Tenant) {
    // NOTE: for falsy values setting it to undefined because isEntityOwner
    // performs === checks to determine whether an job is owned by a tenant.
    this.impersonatedTenant$.next(tenant || undefined);
  }

  /**
   * Clear the impersonated tenant.
   */
  clearImpersonatedTenant() {
    this.impersonatedTenant = undefined;
  }
}
