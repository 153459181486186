<dg-ar-scan-run-status *ngIf="!incidence?.antiRansomwareDetails?.isBaas; else notApplicable"
  [skuType]="SkuTypeEnum.threatProtect"
  [environment]="incidence?.antiRansomwareDetails?.environment"
  [scanStatus]="lastThRunDetails?.health?.status"
  [message]="lastThRunDetails?.health?.message"
  [appStatus]="appStatus"
  [getInstallAppLinkFn]="getInstallAppLinkFn"
  [getInstallAppLinkParamsFn]="getInstallAppLinkParamsFn"
  [adapterAccessService]="thAdapterAccessService">
  <dg-td-threat-matched-status [health]="lastThRunDetails?.health" [count]="lastThRunDetails?.stats?.totalIocCount">
  </dg-td-threat-matched-status>
</dg-ar-scan-run-status>

<ng-template #notApplicable>
  {{ 'naNotApplicableLetters' | translate }}
</ng-template>
