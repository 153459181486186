/**
 * Generic task status for protection and recovery.
 * Order reflects how it shows in filter options by default.
 */
export enum TaskStatus {
  Running = 'Running',
  Succeeded = 'Succeeded',
  SucceededWithWarning = 'SucceededWithWarning',
  Failed = 'Failed',
  Canceled = 'Canceled',
  Canceling = 'Canceling',
  Skipped = 'Skipped',
  Accepted = 'Accepted',
  Paused = 'Paused',
  Missed = 'Missed',
  OnHold = 'OnHold'
}

/**
 * List of generic task status for protection and recovery.
 */
export const taskStatusList = Object.values(TaskStatus);

/**
 * Subitem values enum for finer grain protetion status filtering.
 */
export enum ProtectionCopyTasks {
  Backup = 'Backup',
  Replication = 'Replication',
  Archival = 'Archival',
  CloudSpin = 'CloudSpin'
}

/**
 * Showing all of the status in filters is undesirable (some of them are rare or non-existent).
 */
export const protectionStatusFilterList =
  taskStatusList.filter(status => !['Accepted', 'OnHold', 'Missed'].includes(status));

/**
 * Not all task status are valid for recovery filters.
 */
export const recoveryStatusFilterList =
  taskStatusList.filter(status => !['Paused'].includes(status));

/**
 * Generic tear down status for recovery.
 */
export enum TearDownStatus {
  DestroyScheduled = 'DestroyScheduled',
  Destroying = 'Destroying',
  Destroyed = 'Destroyed',
  DestroyError = 'DestroyError'
}

/**
 * List of generic tear down status for recovery.
 */
export const tearDownStatusList = Object.values(TearDownStatus);
