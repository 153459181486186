<!-- Icon if the result type is file -->
<cog-icon *ngIf="object.resultType === globalSearchResultType.File"
  [shape]="object.reference.type | fileIcon">
</cog-icon>

<!-- Icon if the result type is object -->
<cog-icon *ngIf="object.resultType === globalSearchResultType.Object"
  [matTooltip]="'enum.sourceType.' + object.environment + '.' + object.type | translate"
  [matTooltipDisabled]="!object?.type?.length"
  [shape]="object.reference | objectIcon: {isProtected: object.isProtected}">
</cog-icon>
