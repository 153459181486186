// COMPONENT: cIconProtected

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.iconProtected', ['ui.bootstrap'])
    .component('cIconProtected', {
      bindings: {
        protected: '<',
        labelProtected: '@?',
        labelUnprotected: '@?',
      },
      controller: cIconProtectedFn,
      templateUrl: 'app/global/c-icon-protected/c-icon-protected.html',
    });

  /**
   * @ngdoc component
   * @name C.iconProtected:cIconProtected
   * @function
   *
   * @description
   * Reusable component provides icon and text indication of whether object is
   * protected or unprotected.
   *
   * @example
      <icon-protected
        protected="node._isProtected"
        label-protected="serverProtected"
        label-unprotected="serverUnprotected">
      </icon-protected>
   */
  function cIconProtectedFn() {
    var $ctrl = this;

    $ctrl.label = {
      protected: $ctrl.labelProtected === 'false' ?
        undefined :
        $ctrl.labelProtected || 'objectProtected',
      unprotected: $ctrl.labelUnprotected === 'false' ?
        undefined :
        $ctrl.labelUnprotected || 'objectUnprotected',
    };
  }

})(angular);
