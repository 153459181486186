import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';

import { IconModule } from '../icon/icon.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { CancelButtonDirective } from './cancel-button/cancel-button.directive';
import { FloatingFormMenuDirective } from './floating-form-menu/floating-form-menu.directive';
import { FormFocusedHighlightDirective } from './form-focused-highlight/form-focused-highlight.directive';
import { FormGroupDirective } from './form-group/form-group.directive';
import { FormPanelComponent } from './form-panel/form-panel.component';
import { ButtonSpinnerComponent, LoadingButtonDirective } from './loading-button/loading-button.directive';
import { PasswordVisibilityButtonComponent } from './password-visibility-button/password-visibility-button.component';
import { SubmitButtonDirective, SubmitSpinnerComponent } from './submit-button/submit-button.directives';

const COMPONENTS = [PasswordVisibilityButtonComponent];

const DIRECTIVES = [
  CancelButtonDirective,
  FloatingFormMenuDirective,
  FormFocusedHighlightDirective,
  FormGroupDirective,
  FormPanelComponent,
  LoadingButtonDirective,
  SubmitButtonDirective,
];

@NgModule({
  imports: [CommonModule, SpinnerModule, MatCardModule, MatSlideToggleModule, IconModule, MatButtonModule],
  declarations: [...DIRECTIVES, ...COMPONENTS, ButtonSpinnerComponent, SubmitSpinnerComponent],
  exports: [...DIRECTIVES, ...COMPONENTS],
})
export class FormsModule {}
