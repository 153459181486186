<div (click)="editControlVisible = true"
  [class.invalid]="showErrors"
  [class.show-item-active]="editControlVisible"
  class="show-item"
  cogDataId="coh-show-hide-edit-control-wrapper">

  <!-- Keep separate icons for showErrors and !showErrors as showErrors has a
    fill, and dynamically updating the shape doesn't update the fill -->
  <cog-icon *ngIf="showErrors"
    class="margin-bottom-xs"
    shape="helix:status-error!critical">
  </cog-icon>
  <cog-icon *ngIf="!showErrors"
    [shape]="iconShape + (success ? '-success' : '')"
    class="margin-bottom-xs">
  </cog-icon>
  <div class="item-details">
    <div class="margin-left">{{ title }}</div>
    <div *ngIf="!editControlVisible"
      [inheritDataId]="true"
      class="margin-left margin-top-xs item-display"
      cogDataId="display">

      <!-- Show content for view mode -->
      <ng-content select="coh-show-hide-edit-control-summary"></ng-content>

      <!-- Show an error if the user tried to submit the form, and the form was
        invalid. -->
      <ul *ngIf="showErrors" class="c-ul-inline no-margin meta-data">
        <li>
          {{'pleaseSelectValidValueFor' | translate: {name: title} }}
        </li>
      </ul>
    </div>
  </div>

  <!-- Button to toggle to view mode -->
  <button (click)="(editControlVisible = false) || $event.stopPropagation()"
    *ngIf="editControlVisible"
    class="hide-item-editor"
    cogDataId="hide-item-editor"
    mat-icon-button
    type="button">
    <cog-icon shape="clear"></cog-icon>
  </button>

  <!-- Icon to toggle to edit mode (the row is clickable, thus no button). -->
  <cog-icon *ngIf="!editControlVisible"
    class="show-item-editor-icon"
    matSuffix
    shape="create">
  </cog-icon>
</div>

<!-- Show content for edit mode -->
<div *ngIf="editControlVisible"
  [inheritDataId]="true"
  class="item-edit"
  cogDataId="edit">
  <ng-content select="coh-show-hide-edit-control-edit"></ng-content>
</div>
