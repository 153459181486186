/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RegisteredRemoteStorageList } from '../models/registered-remote-storage-list';
import { RemoteStorageInfo } from '../models/remote-storage-info';
@Injectable({
  providedIn: 'root',
})
class RemoteStorageService extends __BaseService {
  static readonly GetRegisteredRemoteStorageListPath = '/remote-storage';
  static readonly RegisterNewRemoteStoragePath = '/remote-storage';
  static readonly GetRemoteStorageDetailsPath = '/remote-storage/{id}';
  static readonly DeleteRemoteStorageRegistrationPath = '/remote-storage/{id}';
  static readonly UpdateRemoteStorageRegistrationPath = '/remote-storage/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get Registered Remote Storage Servers List
   *
   * Get summary about list of registered remote storage servers.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetRegisteredRemoteStorageListResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<RegisteredRemoteStorageList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/remote-storage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RegisteredRemoteStorageList>;
      })
    );
  }
  /**
   * Get Registered Remote Storage Servers List
   *
   * Get summary about list of registered remote storage servers.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetRegisteredRemoteStorageList(regionId?: string,
    accessClusterId?: number): __Observable<RegisteredRemoteStorageList> {
    return this.GetRegisteredRemoteStorageListResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as RegisteredRemoteStorageList)
    );
  }

  /**
   * Register Remote Storage
   *
   * Register a remote storage to be used for disaggregated storage.
   * @param params The `RemoteStorageService.RegisterNewRemoteStorageParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to register a remote storage management server.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RegisterNewRemoteStorageResponse(params: RemoteStorageService.RegisterNewRemoteStorageParams): __Observable<__StrictHttpResponse<RemoteStorageInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/remote-storage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RemoteStorageInfo>;
      })
    );
  }
  /**
   * Register Remote Storage
   *
   * Register a remote storage to be used for disaggregated storage.
   * @param params The `RemoteStorageService.RegisterNewRemoteStorageParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to register a remote storage management server.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RegisterNewRemoteStorage(params: RemoteStorageService.RegisterNewRemoteStorageParams): __Observable<RemoteStorageInfo> {
    return this.RegisterNewRemoteStorageResponse(params).pipe(
      __map(_r => _r.body as RemoteStorageInfo)
    );
  }

  /**
   * Get remote storage details
   *
   * Get details of remote storage given by id.
   * @param params The `RemoteStorageService.GetRemoteStorageDetailsParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the registered remote storage.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeAvailableSpace`: Specifies whether to include available capacity on remote storage.
   *
   * - `includeAvailableDataVips`: Specifies whether to include available data vips on remote storage.
   *
   * - `includeArrayInfo`: Includes flashblade specific info like name, software os and version of pure flashblade.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRemoteStorageDetailsResponse(params: RemoteStorageService.GetRemoteStorageDetailsParams): __Observable<__StrictHttpResponse<RemoteStorageInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeAvailableSpace != null) __params = __params.set('includeAvailableSpace', params.includeAvailableSpace.toString());
    if (params.includeAvailableDataVips != null) __params = __params.set('includeAvailableDataVips', params.includeAvailableDataVips.toString());
    if (params.includeArrayInfo != null) __params = __params.set('includeArrayInfo', params.includeArrayInfo.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/remote-storage/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RemoteStorageInfo>;
      })
    );
  }
  /**
   * Get remote storage details
   *
   * Get details of remote storage given by id.
   * @param params The `RemoteStorageService.GetRemoteStorageDetailsParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the registered remote storage.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeAvailableSpace`: Specifies whether to include available capacity on remote storage.
   *
   * - `includeAvailableDataVips`: Specifies whether to include available data vips on remote storage.
   *
   * - `includeArrayInfo`: Includes flashblade specific info like name, software os and version of pure flashblade.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRemoteStorageDetails(params: RemoteStorageService.GetRemoteStorageDetailsParams): __Observable<RemoteStorageInfo> {
    return this.GetRemoteStorageDetailsResponse(params).pipe(
      __map(_r => _r.body as RemoteStorageInfo)
    );
  }

  /**
   * Delete Remote Storage Registration
   *
   * Delete remote storage registration.
   * @param params The `RemoteStorageService.DeleteRemoteStorageRegistrationParams` containing the following parameters:
   *
   * - `id`: Specifies the registration id of the registered remote storage.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteRemoteStorageRegistrationResponse(params: RemoteStorageService.DeleteRemoteStorageRegistrationParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/remote-storage/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete Remote Storage Registration
   *
   * Delete remote storage registration.
   * @param params The `RemoteStorageService.DeleteRemoteStorageRegistrationParams` containing the following parameters:
   *
   * - `id`: Specifies the registration id of the registered remote storage.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteRemoteStorageRegistration(params: RemoteStorageService.DeleteRemoteStorageRegistrationParams): __Observable<null> {
    return this.DeleteRemoteStorageRegistrationResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update Remote Storage Config
   *
   * Update Registered Remote Storage Config.
   * @param params The `RemoteStorageService.UpdateRemoteStorageRegistrationParams` containing the following parameters:
   *
   * - `id`: Specifies the registration id of the registered remote storage.
   *
   * - `body`: Specifies the parameters to update the registration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateRemoteStorageRegistrationResponse(params: RemoteStorageService.UpdateRemoteStorageRegistrationParams): __Observable<__StrictHttpResponse<RemoteStorageInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/remote-storage/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RemoteStorageInfo>;
      })
    );
  }
  /**
   * Update Remote Storage Config
   *
   * Update Registered Remote Storage Config.
   * @param params The `RemoteStorageService.UpdateRemoteStorageRegistrationParams` containing the following parameters:
   *
   * - `id`: Specifies the registration id of the registered remote storage.
   *
   * - `body`: Specifies the parameters to update the registration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateRemoteStorageRegistration(params: RemoteStorageService.UpdateRemoteStorageRegistrationParams): __Observable<RemoteStorageInfo> {
    return this.UpdateRemoteStorageRegistrationResponse(params).pipe(
      __map(_r => _r.body as RemoteStorageInfo)
    );
  }
}

module RemoteStorageService {

  /**
   * Parameters for RegisterNewRemoteStorage
   */
  export interface RegisterNewRemoteStorageParams {

    /**
     * Specifies the parameters to register a remote storage management server.
     */
    body: RemoteStorageInfo;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetRemoteStorageDetails
   */
  export interface GetRemoteStorageDetailsParams {

    /**
     * Specifies the id of the registered remote storage.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies whether to include available capacity on remote storage.
     */
    includeAvailableSpace?: boolean;

    /**
     * Specifies whether to include available data vips on remote storage.
     */
    includeAvailableDataVips?: boolean;

    /**
     * Includes flashblade specific info like name, software os and version of pure flashblade.
     */
    includeArrayInfo?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteRemoteStorageRegistration
   */
  export interface DeleteRemoteStorageRegistrationParams {

    /**
     * Specifies the registration id of the registered remote storage.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateRemoteStorageRegistration
   */
  export interface UpdateRemoteStorageRegistrationParams {

    /**
     * Specifies the registration id of the registered remote storage.
     */
    id: number;

    /**
     * Specifies the parameters to update the registration.
     */
    body: RemoteStorageInfo;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { RemoteStorageService }
