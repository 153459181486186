// Component: Office 365 Search Parameters Info Component

;(function (angular, undefined) {
  'use strict';

  angular
    .module('C.office365Recovery')
    .component('office365SearchParameter', {
      bindings: {
        /**
         * searchInfo
         * @type   {object}   Specifies the details about search
         */
        searchInfo: '<',
        /**
         * toggleVisibility
         * @type   {function}   Callback function to toggle visibility of this
         *                      search parameter container.
         */
        toggleVisibility: '&'
      },
      templateUrl:
        'app/protection/recovery/office365/search/parameters/parameters.html',
    });
})(angular);