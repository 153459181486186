import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { MatLegacyColumnDef as MatColumnDef, MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { TenantServiceApi } from '@cohesity/api/v1';
import { TenantServiceApi as TenantServiceApiV2 } from '@cohesity/api/v2';
import { AutoDestroyable } from '@cohesity/utils';
import { get, keyBy } from 'lodash';
import { of } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';

import { hasPrivilege, IrisContextService, isAllClustersScope } from './../iris-context';

/**
 * This component streamlines the addition of tenant column to any table.
 *
 * @description
 * Render the tenant column for the mat-table.
 * The component also takes a position in which the column needs to be inserted.
 * The component assumes the dataSource of the table has a field called `tenant`
 * and the type of the tenant is either TenantInfo or Array<TenantInfo>. If you
 * don't want to use the tenant in the data, and use tenantId, tenantIds then
 * pass the option `internalResolve` to resolve the tenants internally.
 *
 * @example
 * <table mat-table>
 *  <coh-tenant-column position="2"></coh-tenant-column>
 * </table>
 *
 * or
 *
 * <mat-table>
 *  <coh-tenant-column></coh-tenant-column>
 * </mat-table>
 *
 * <coh-tenant-column [internalResolve]="true"></coh-tenant-column>
 */
@Component({
  selector: 'coh-tenant-column',
  templateUrl: './tenant-column.component.html',
  styleUrls: ['./tenant-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TenantColumnComponent extends AutoDestroyable implements OnInit, AfterViewChecked {

  /**
   * The position in which the tenant columns needs to be inserted.
   * Default is the second column in the table.
   */
  @Input() position = 1;

  /**
   * Whether the column is sortable.
   */
  @Input() sort = false;

  /**
   * Optional argument internalResolve if you want this component to resolve the
   * tenant details internally.
   */
  @Input() internalResolve = false;

  /**
   * The template reference for the organization column.
   */
  @ViewChild(MatColumnDef, { static: true }) tenantColumnDef: MatColumnDef;

  /**
   * The column name for the tenant.
   */
  colName = 'tenant';

  /**
   * Internally caches the tenants if internalResolve is true.
   */
  tenantsMap = {};

  /**
   * Check is all cluster mode enabled or not.
   */
  get isAllCluster() {
    return isAllClustersScope(this.irisCtx.irisContext);
  }

  /**
   * Constructor
   */
  constructor(
    @Optional() private table: MatTable<any>,
    private cdRef: ChangeDetectorRef,
    private heliosTenantService: TenantServiceApiV2,
    private irisCtx: IrisContextService,
    private tenantService: TenantServiceApi,
  ) {
    super();
  }

  /**
   * Init the component.
   */
  ngOnInit() {
    if (!hasPrivilege(this.irisCtx.irisContext, 'ORGANIZATION_VIEW')) {
      return;
    }

    if (this.sort) {
      this.colName = 'organizationName';
    }

    // Update the tenant as a column in the existing definition.
    this.tenantColumnDef.name = this.colName;
    if (this.table) {
      this.cdRef.detectChanges();
      this.table.addColumnDef(this.tenantColumnDef);

      if (this.internalResolve) {
        of(this.isAllCluster)
          .pipe(
            this.untilDestroy(),
            finalize(() => this.cdRef.detectChanges()),
            switchMap(isAllCluster => {
              if (isAllCluster) {
                return this.heliosTenantService.GetHeliosTenants({ managedOnHelios: true })
                  .pipe(map(response => keyBy(response.tenants, 'id')));
              }

              return this.tenantService.GetTenants({}).pipe(map(tenants => keyBy(tenants, 'tenantId')));
            }),
            tap(tenantsMap => this.tenantsMap = tenantsMap),
          )
          .subscribe();
      }
    }
  }

  /**
   * Triggers after the view is checked.
   */
  ngAfterViewChecked(): void {
    if (!hasPrivilege(this.irisCtx.irisContext, 'ORGANIZATION_VIEW')) {
      return;
    }

    const headerColumns = get(this.table, '_headerRowDefs[0].columns', []);
    const rowColumns = get(this.table, '_rowDefs[0].columns', []);

    // Do not add the tenant column in header if it already contains the column or contains bulk-actions column
    if (!headerColumns.includes(this.colName) && !headerColumns.includes('bulk-actions')) {
      headerColumns.splice(this.position, 0, this.colName);
    }

    // If the tenant column is not updated in the column definition
    if (!rowColumns.includes(this.colName)) {
      rowColumns.splice(this.position, 0, this.colName);
    }
  }

  /**
   * Stops the event bubble to get propagated up the chain.
   *
   * @param   event   The click event
   */
  stopPropagation(event) {
    event.stopPropagation();
  }

  /**
   * Return the state.
   *
   * @returns state
   */
  getOrgSref() {
    return this.isAllCluster ? 'organizations.view' : 'view-tenant';
  }

  /**
   * Return the parameters required by state
   *
   * @param tenantId The tenant id.
   * @returns params required by state
   */
  getOrgUiParams(tenantId: string) {
    return this.isAllCluster ? { tenantId } : { id: tenantId };
  }

}
