import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Vlan } from '@cohesity/api/v1';
import { Controls, NgxSubFormComponent, subformComponentProviders, takeUntilDestroyed } from 'ngx-sub-form';
import { BehaviorSubject } from 'rxjs';
import { ClusterInterface } from 'src/app/shared';

@Component({
  selector: 'coh-settings-list-network-selector',
  templateUrl: './settings-list-network-selector.component.html',
  styleUrls: ['./settings-list-network-selector.component.scss'],
  providers: subformComponentProviders(SettingsListNetworkSelectorComponent),
})
export class SettingsListNetworkSelectorComponent extends NgxSubFormComponent<ClusterInterface> implements OnInit {
  /**
   * An observable stream of vlans.
   */
  @Input() vlans$ = new BehaviorSubject<Vlan[]>([]);

  ngOnInit() {
    this.formGroupControls.auto.valueChanges.pipe(
      takeUntilDestroyed(this)
    ).subscribe(value => {
      if (value) {
        this.formGroupControls.interfaceGroup.setValue(null);
        this.formGroupControls.interfaceGroup.setValidators(null);
        this.formGroupControls.interfaceGroup.setErrors(null);
      } else {
        this.formGroupControls.interfaceGroup.setValidators(Validators.required);
      }
      this.formGroupControls.interfaceGroup.updateValueAndValidity();
    });
  }

  getDefaultValues(): Partial<ClusterInterface> {
    return {
      auto: true,
    };
  }

  /**
   * Function to return all the form controls.
   *
   * @return The form controls object.
   */
  protected getFormControls(): Controls<ClusterInterface> {
    return {
      auto: new FormControl(),
      interfaceGroup: new FormControl(),
    };
  }
}
