<cog-banner class="margin-bottom" status="warn" *ngIf="groupMessage">
  <span>
    {{ groupMessage }}
  </span>
</cog-banner>
<div class="header">
  <div class="filters">
    <div class="calendar-header" [class.hidden]="viewState === 'table'">
      <h1 class="sel-date-header">
        {{ calendarDate$ | async | cohDate: 'longDate' }}
      </h1>
      <cog-filters #calendarFilters>
        <cog-value-property-filter
          *cogFilterDef="
            let params = params;
            let filter;
            key: 'runType';
            label: 'allBackupTypes' | translate;
            quickFilter: true;
            filterType: 'valueProperty'"
          [allowMultiple]="false"
          [filterDefParams]="params"
          [filter]="filter"
          [filterValues]="filterValuesList.backupType">
        </cog-value-property-filter>
      </cog-filters>
    </div>
    <div>
      <cog-filters #tableFilters [class.hidden]="viewState === 'calendar'">
        <cog-date-range-filter id="group-runs-table-date-range-filter"
          *cogFilterDef="
            let filter;
            let params = params;
            key: 'startDate';
            label: 'startTime' | translate;
            filterType: 'dateRange';
            quickFilter: true"
          [filter]="filter"
          [filterDefParams]="params"
          [useTimeframe]="true"
          [selectedRange]="tableDateRange$ | async">
        </cog-date-range-filter>
        <cog-value-property-filter id="group-runs-table-run-type-filter"
          *cogFilterDef="
            let params = params;
            let filter;
            key: 'runType';
            label: 'backupType' | translate;
            quickFilter: true;
            filterType: 'valueProperty'"
          [allowMultiple]="false"
          [autoApply]="true"
          [filterDefParams]="params"
          [filter]="filter"
          [filterValues]="filterValuesList.backupType">
        </cog-value-property-filter>
        <ng-container *ngIf="(group$ | async)?.isExternallyTriggered">
          <cog-value-property-filter cogDataId="group-runs-table-backup-tag-filter"
            *cogFilterDef="
              let params = params;
              let filter;
              key: 'externallyTriggeredBackupTag';
              quickFilter: true;
              label: 'tags' | translate;
              filterType: 'valueProperty'"
            [filter]="filter"
            [filterDefParams]="params"
            [allowMultiple]="true"
            [filterValues]="filterValuesList.tags">
          </cog-value-property-filter>
        </ng-container>
      </cog-filters>
    </div>
  </div>
  <div class="toggle-controls" *ngIf="viewState$ | async as viewState">
    <cog-slicer [compact]="false">
      <mat-button-toggle-group
        [value]="viewState"
        (change)="stateChangeHandler($event)">
        <mat-button-toggle id="group-runs-table-select"
          value="table"
          [matTooltip]="'list' | translate">
          <cog-icon shape="list"></cog-icon>
        </mat-button-toggle>
        <mat-button-toggle id="group-runs-calendar-select"
          value="calendar"
          [matTooltip]="'calendar' | translate">
          <cog-icon shape="date_range"></cog-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </cog-slicer>
  </div>
</div>
<ng-container [ngSwitch]="viewState">
  <ng-container *ngSwitchCase="'calendar'">
    <coh-runs-limit-banner [totalRuns]="totalRunsCount"
      [runsLimit]="calendarRunsLimit"
      (viewAllClicked)="viewAllCalendarRuns($event)">
    </coh-runs-limit-banner>
    <div class="calendar-view-container">
      <div class="calendar-container">
        <coh-calendar cogDataId="group-runs-calendar"
          [markedDates]="markedDates$ | async"
          [activeDates]="activeDates$ | async"
          [selectedDate]="calendarDate$ | async"
          (dateChanged)="dateChangeHandler($event)">
        </coh-calendar>
      </div>
      <div class="runs-list-container">
        <coh-runs-list *ngIf="selectedDayRuns$ | async as selectedDayRuns; else spinner"
          cogDataId="group-runs-list"
          [selectedRun]="selectedRun"
          [hasObjectCount]="!!(object$ | async) === false"
          [hasActions]="!!(object$ | async) === false"
          [hasDataWritten]="hasDataWritten$ | async"
          [hasDataRead]="hasDataRead$ | async"
          (selectionChanged)="runListSelectionChanged($event)"
          [runs]="selectedDayRuns">
        </coh-runs-list>
      </div>
    </div>
  </ng-container>
  <div *ngSwitchCase="'table'">
    <coh-runs-table *ngIf="tableRuns$ | async as tableRuns; else spinner"
      cogDataId="group-runs-table"
      [object]="object$ | async"
      [group]="group$ | async"
      [runs]="tableRuns"
      [environment]="environment$ | async"
      [runsLimit]="tableRunsLimit"
      [totalRuns]="totalRunsCount"
      (viewAllClicked)="viewAllTableRuns($event)">
    </coh-runs-table>
  </div>
</ng-container>
<ng-template #spinner>
  <cog-spinner></cog-spinner>
</ng-template>
