import {
  DefaultErrorStrategy,
  InputMismatchException,
  Parser,
  ParserRuleContext,
  RecognitionException,
  Token,
} from 'antlr4ts';

/**
 * Advanced search custom error strategy used to bubble up exception occurred at the leaf node.
 */
export class ErrorStrategy extends DefaultErrorStrategy {
  /**
   * Capture the exception before calling the default recover from exception method.
   *
   * @param recognizer The parser instance.
   * @param exception The recognition exception to recover.
   */
  public recover(recognizer: Parser, exception: RecognitionException) {
    // bubble up the exception to the top of the AST.
    for (let context: ParserRuleContext | undefined = recognizer.context; context; context = context.parent) {
      context.exception = exception;
    }

    // continue to default recover error strategy.
    super.recover(recognizer, exception);
  }

  /**
   * Capture the exception before the parser tries to recover from it because on successfully recovery it wont
   * throw an exception hence we will not be able to show suggestions.
   *
   * @param recognizer The parser instance.
   * @returns The token instance which should be treated as the successful result of the match.
   */
  public recoverInline(recognizer: Parser): Token {
    const exception = new InputMismatchException(recognizer);

    // bubble up the exception to the top of the AST.
    for (let context: ParserRuleContext | undefined = recognizer.context; context; context = context.parent) {
      context.exception = exception;
    }

    // continue to default recoverInline error strategy.
    return super.recoverInline(recognizer);
  }
}
