<button
  mat-stroked-button
  cogDataId="select-snapshot-button"
  type="button"
  class="cog-quick-filter-button selected"
  [matMenuTriggerFor]="snapshotMenu"
>
  {{ restorePoint.timestampUsecs | cohDate }}
  <cog-icon
    [inline]="true"
    size="sm"
    class="margin-right-xs"
    [shape]="snapshotSelectorUtilsService.getSnapshotIcon(restorePointSelection.snapshot)"
  ></cog-icon>

  <cog-icon shape="arrow_drop_down" size="sm"></cog-icon>
</button>

<mat-menu #snapshotMenu="matMenu" backdropClass="coh-snapshot-selector-menu">
  <ng-template matMenuContent>
    <div class="flex-column menu-content-wrapper" cohStopPropagation>
      <div class="margin-bottom">
        <coh-snapshot-selector
          [object]="restorePoint"
          [protectionGroupId]="restorePoint.objectInfo.protectionGroupId"
          [snapshotActions]="snapshotActions"
          [objectActionKeys]="objectActionKeys"
          (snapshotChanged)="onSnapshotChange($event)"
        >
        </coh-snapshot-selector>
      </div>

      <div class="flex-row flex-justify-flex-end">
        <button cogDataId="cancel-snapshot-change-button" cogCancelButton (click)="onCancelClick()">
          {{ 'cancel' | translate }}
        </button>

        <button cogDataId="apply-snapshot-change-button" cogSubmitButton (click)="onApplyClick()">
          {{ 'apply' | translate }}
        </button>
      </div>
    </div>
  </ng-template>
</mat-menu>
