import { Component, Input, OnInit } from '@angular/core';
import { TenantServiceApi } from '@cohesity/api/v1';
import { TenantServiceApi as TenantServiceApiV2 } from '@cohesity/api/v2';
import { FiltersComponent, ValueFilterSelection } from '@cohesity/helix';
import { getUserPrivilegeCheck, IrisContextService, isAllClustersScope } from '@cohesity/iris-core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseObjectFilterComponent } from './../base-object-filter';

/**
 * Tenant filter which leverages cog-filters and makes the modified
 * API call to filter the given table for the selected Tenant.
 *
 * @example
 *   <coh-tenant-filter></coh-tenant-filter>
 */
@Component({
  selector: 'coh-tenant-filter',
  templateUrl: './tenant-filter.component.html',
})
export class TenantFilterComponent extends BaseObjectFilterComponent<any> implements OnInit {
  /**
   * The name of the property containing the tenant ids.
   */
  @Input() property = 'tenantIds';

  /**
   * Check if the logged in user has the privilege to view organizations.
   * Use this to also hide the filter when its false.
   */
  hasOrgViewPrivilege = false;

  /**
   * Check if all cluster mode enabled or not.
   */
  isAllCluster = false;

  constructor(
    filters: FiltersComponent,
    private heliosTenantsService: TenantServiceApiV2,
    private irisCtx: IrisContextService,
    private tenantService: TenantServiceApi,
  ) {
    super(filters);
  }

  ngOnInit() {
    this.hasOrgViewPrivilege = getUserPrivilegeCheck('ORGANIZATION_VIEW')(this.irisCtx.irisContext);
    this.isAllCluster = isAllClustersScope(this.irisCtx.irisContext);

    // This is needed else in case tenant logs in; API call will still be made.
    if (!this.hasOrgViewPrivilege) {
      return;
    }

    super.ngOnInit();
  }

  /**
   * Get the list of tenants.
   */
  getFilterValues(): Observable<ValueFilterSelection[]> {
    return this.isAllCluster ?
      this.heliosTenantsService.GetHeliosTenants({ managedOnHelios: true }).pipe(
        map(tenants =>
          (tenants.tenants || []).map(tenant => ({
            label: tenant.name,
            value: tenant.id
          }))
        )
      )
      : this.hasOrgViewPrivilege ? this.tenantService.GetTenants({}).pipe(
        map(tenants =>
          tenants.map(tenant => ({
            label: tenant.name,
            value: tenant.tenantId,
          }))
        )
      ) : of([]);
  }
}
