<form [formGroup]="unregisterForm">
  <h2 mat-dialog-title translate>unregisterSource</h2>
  <div mat-dialog-content>
    <p [innerHTML]="'dms.unregisterSourceName' | translate:{
      name: name,
      type: type
    }" class="margin-bottom"></p>
    <!-- To be added when rigel unregister is implemented. -->
    <!-- <mat-checkbox cogDataId="remove-rigel-checkbox" formControlName="removeRigel">
      <span [innerHTML]="'removeRigelConnectionWithSource' | translate:{rigelName:rigelName}">
      </span>
    </mat-checkbox> -->
    <mat-form-field [hideRequiredMarker]="true">
      <input cogDataId="confirm-unregister-source"
        required
        matInput
        [placeholder]="'typeYesToConfirm' | translate"
        formControlName="confirmUnregister">
      <mat-error id="confirm-required-invalid" *ngIf="unregisterForm.controls.confirmUnregister.errors?.required">
        {{'errors.required' | translate}}
      </mat-error>
      <mat-error id="confirm-pattern-pattern" *ngIf="unregisterForm.controls.confirmUnregister.errors?.pattern">
        {{'mustInputYes' | translate}}
      </mat-error>
    </mat-form-field>
  </div>
  <mat-dialog-actions align="end">
    <button mat-stroked-button cogCancelButton [mat-dialog-close]="false"
      [disabled]="loading"
      cogDataId="cancel-unregister-btn">
      {{'cancel' | translate}}
    </button>
    <button mat-flat-button cogSubmitButton
      [loading]="loading"
      [disabled]="loading || !unregisterForm.valid"
      cogDataId="unregister-btn"
      color="warn" (click)="unregister()">
      {{'unregister' | translate}}
    </button>
  </mat-dialog-actions>
</form>
