import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ProtectionRun } from '../../models/protection-run.models';


/**
 * @description
 * Protection Group Run list item component used for Runs Details page.
 */
@Component({
  selector: 'coh-runs-list',
  templateUrl: './runs-list.component.html',
  styleUrls: ['./runs-list.component.scss'],
})
export class RunsListComponent {
  /**
   * Show data read label.
   */
  @Input() hasDataRead = true;

  /**
   * Show data write label.
   */
  @Input() hasDataWritten = true;

  /**
   * Show number of objects protected and total object count.
   */
  @Input() hasObjectCount = true;

  /**
   * Indicates if list items should display run action items.
   */
  @Input() hasActions = true;

  /**
   * SelectionModel for run list items.
   */
  private readonly selectionModel = new SelectionModel<ProtectionRun>(false, []);

  /**
   * `ProtectionRun` items used to generate `GroupRunsListItemComponent`.
   */
  private _runs: ProtectionRun[];

  /**
   * Sets protection run list.
   */
  @Input() set runs(runs: ProtectionRun[]) {
    this._runs = runs;
  }

  /**
   * Returns `ProtectionRun` list.
   */
  get runs(): ProtectionRun[] {
    return this._runs;
  }

  /**
   * Sets run selection.
   */
  @Input() set selectedRun(run: ProtectionRun) {
    this.selectionModel.select(run);
  }

  /**
   * Returns selected run.
   */
  get selectedRun(): ProtectionRun {
    return this.selectionModel.selected[0];
  }

  /**
   * Emits selected run when selection changes.
   */
  @Output() readonly selectionChanged = new EventEmitter<ProtectionRun>();

  /**
   * Handles run selection change.
   *
   * @param  run  `ProtectionRun` that will be select, any already selected items will be deselected.
   */
  selectionHandler(run: ProtectionRun) {
    this.selectionChanged.emit(run);
  }

  /**
   * Checks if specified run is selected.
   *
   * @param    run  `ProtectionRun` item to check for selection in selection model.
   * @returns       True is `run` is selected in selection model.
   */
  isSelected(run: ProtectionRun): boolean {
    return this.selectionModel.isSelected(run);
  }
}
