import { Office365MailboxItemType, Office365SearchType } from 'src/app/shared/constants';
import { CreateRecoveryForm, RestorePointSelection } from '../../restore-shared';

/**
 * This is the form interface for creating a recovery of Office365 object.
 */
export interface CreateRecoveryFormO365<ParamType> extends CreateRecoveryForm<ParamType> {
  /**
   * Specifies the recovery type whether at object level or granular recovery.
   */
  recoveryType?: Office365SearchType;

  /**
   * Specifies the granularity of the search for public folder.
   */
  publicFolderGranularSearchType?: string;
}

/**
 * Specifies the form interface for Office365 Granular Recovery
 */
export interface GranularRecoveryFormO365 {
  /**
   * Specifies the array of objects with their snapshot id selected for recovery.
   */
  objects: RestorePointSelection[];

  /**
   * Specifies the granular search type for Office365 Public Folder contents.
   */
  publicFolderGranularSearchType?: string;

  /**
   * Specifies the granular search type for non Public Folder contents.
   */
  granularSearchType?: string;
}

/**
 * Specifies a document type model for PublicFolder.
 */
export interface PublicFolderDocType {
  /**
   * Specifies the type of the document.
   */
  itemType: string;

  /**
   * Specifies the name of the document.
   */
  itemName: string;
}

/**
 * Specifies the Office365 recovery object model.
 */
export interface Office365RecoveryObject {
  /**
   * A an array of protected objects and their selected snapshots and targets.
   */
  objects: RestorePointSelection[];
}

/**
 * Specifies the types of emails.
 */
export enum EmailType {
  kAllEmails = 'allEmails',
  kOnlyEmailsWithAttachments = 'onlyEmailsWithAttachments',
  kOnlyEmailsWithoutAttachments = 'onlyEmailsWithoutAttachments',
}

/**
 * Specifies the granular search parameters for Mailbox items.
 */
export interface MailboxItemSearchParameters {
  /**
   * Specifies the type of item being searched for.
   */
  itemType: Office365MailboxItemType;

  /**
   * Specifies the folder names being searched for. This parameter is used when
   * 'itemType' is set to 'Office365MailboxItemType.kFolder'.
   *
   * The folderNames are ORed within and ANDed with other filters.
   */
  folderNames?: string[];

  /**
   * Specifies the folder names within which the items(emails) are being
   * searched for. This parameter is used when 'itemType' is set to
   * 'Office365MailboxItemType.kEmail'.
   *
   * The folderNames are ORed within and ANDed with other filters.
   */
  searchInFolders?: string[];

  /**
   * Specifies the email subject. This is currently a prefix search.
   */
  emailSubject?: string;

  /**
   * Specifies the sender's address which is currently used for exact matching.
   */
  senderAddress?: string;

  /**
   * Specifies the list of recipients' email addresses.
   * The addresses are ORed within and ANDed with other filters.
   */
  recipientAddresses?: string[];

  /**
   * Specifies the list of CC recipients' email addresses.
   * The addresses are ORed within and ANDed with other filters.
   */
  ccRecipientAddresses?: string[];

  /**
   * Specifies the list of BCC recipients' email addresses.
   * The addresses are ORed within and ANDed with other filters.
   */
  bccRecipientAddresses?: string[];

  /**
   * Specifies the date range within which the mailbox item was received.
   * TODO(tauseef): Fix the API to work on both received and sent timestamps.
   * Current implmentation only matches on received timestamps.
   */
  dateRange?: DateRange;

  /**
   * Specifies the email type.
   */
  emailType?: EmailType;

  /**
   * Specifies the last backup date range for the item.
   * TODO(tauseef): Implement this in future.
   */
  lastBackup?: DateRange;

  /**
   * Specifies the custom query(like Jira QL/Cohesity QL(Argus))
   * TODO(tauseef): Implement this in future.
   */
  customQuery?: string;

  /**
   * Specifies the list of string which will be matched against the content of
   * the mailbox item.
   * TODO(tauseef): Implement this in future.
   */
  hasWords?: string[];
}

/**
 * Specifies the enum for Office365 Task Status
 */
export enum MailboxTaskStatusType {
  All = 'All',
  Completed = 'Completed',
  Deferred = 'Deferred',
  InProgress = 'InProgress',
  NotStarted = 'NotStarted',
  WaitingOnOthers = 'WaitingOnOthers',
}

/**
 * Specifies the granular search parameters for mailbox non-email items.
 */
export interface MailboxNonEmailItemSearchParameters {
  /**
   * Specifies the search string query.
   * This parameter is mapped to:
   *  - emailSubject for a Calendar item
   *  - firstName for a Contact item
   *  - subject for Task and Note item
   */
  searchString: string;

  /**
   * Specifies the organizer's address for a Calendar item.
   */
  organizerAddress?: string;

  /**
   * Specifies the list of invitee's email addresses for a Calendar item.
   * The addresses are ORed within and ANDed with other filters.
   */
  inviteeAddresses?: string[];

  /**
   * Specifies the date range within which the Calendar item is created.
   */
  invitationDateRange?: DateRange;

  /**
   * Filters the contacts with specified text in last name.
   */
  lastName?: string;

  /**
   * Filters the contact items which have specified text in email address.
   */
  emailAddress?: string;

  /**
   * Specifies the date range within which a Note item is created.
   */
  noteCreationDateRange?: DateRange;

  /**
   * Specifies the date range within which a Note item is modified.
   */
  noteModificationDateRange?: DateRange;

  /**
   * Specifies the date range within which a Task item is created.
   */
  taskCreationDateRange?: DateRange;

  /**
   * Specifies the date range within which a Task item is due.
   */
  taskDueDateRange?: DateRange;

  /**
   * Specifies a list of task item status types.
   */
  taskStatusTypes?: string[];
}

/**
 * Specifies the Date Range for the Mailbox item's receive date or sent date.
 */
interface DateRange {
  start: Date;
  end: Date;
}

/**
 * Specifies the Date Range for the Mailbox item's receive date or sent date.
 */
interface SizeRange {
  lower: number;
  upper: number;
}

// Document Library model
/**
 * Enum for document library types.
 */
export enum DocumentLibraryFileType {
  Document = 'Document',
  Excel = 'Excel',
  Powerpoint = 'Powerpoint',
  Image = 'Image',
  OneNote = 'OneNote',
}

/**
 * Specifies the Office365 Document library item type mappings.
 */
export enum DocumentLibraryItemType {
  File = 'File',
  Folder = 'Folder',
  Both = 'Both',
}

/**
 * Specifies the granular search parameters for SharePoint Document library items.
 */
export interface DocumentLibrarySearchParameters {
  /**
   * Specifies the type of item being searched for.
   */
  itemType: DocumentLibraryItemType;

  /**
   * Specifies the search string to filter the files/folders.
   */
  searchString: string;

  /**
   * Specifies a list of document library types.
   */
  categoryTypes?: DocumentLibraryFileType[];

  /**
   * Specifies the date range within which the file/folder is created.
   */
  creationDateRange?: DateRange;

  /**
   * Specifies the list of owner names to filter on owner of the file/folder.
   */
  ownerNames?: string[];

  /**
   * Specifies the date range within which the file/folder was last modified.
   */
  lastModifiedDateRange?: DateRange;

  /**
   * Specifies the date range within which the file/folder was backed up.
   */
  lastBackedUpDateRange?: DateRange;

  /**
   * Specifies the size range fot the file/folder.
   */
  sizeRange?: SizeRange;

  /**
   * Specifies the custom query(like Jira QL/Cohesity QL(Argus))
   * Not being used.
   */
  customQuery?: string;
}

/**
 * Specifies a common interface to hold properties for M365 recovery options.
 * This is used to pass properties to the dynamic component holding the
 * recovery settings by the parent.
 */
export interface RecoveryOptionsProperties {
  /**
   * Specifies whether the restore is being triggerred at an object level.
   */
  isObjectLevelRestore: boolean;
}
