import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AngularMaterialModule } from '../../angular-material.module';
import { DataIdModule } from '../data-id/data-id.module';
import { IconModule } from '../icon/icon.module';
import { ActionMenuItemComponent } from './action-menu-item/action-menu-item.component';
import { ActionsMenuComponent } from './actions-menu.component';


const COMPONENTS = [
  ActionsMenuComponent,
  ActionMenuItemComponent
];

@NgModule({
  imports: [
    AngularMaterialModule,
    CommonModule,
    DataIdModule,
    IconModule
  ],
  declarations: COMPONENTS,
  exports: [...COMPONENTS]
})
export class ActionsMenuModule { }
