<cog-nested-value-property-filter *cogFilterDef="let filter;
  let params = params;
  quickFilter true;
  key: property;
  label: translate(label);
  filterType: 'valueProperty'"
  [filterDefParams]="params"
  [filterValues]="filterOptions$ | async"
  [filter]="filter"
  [trackFilters]="true">
</cog-nested-value-property-filter>
