import { Injectable } from '@angular/core';
import { McmClusterServiceApi } from '@cohesity/api/private';
import { ProtectionSourceNode, ProtectionSourcesServiceApi } from '@cohesity/api/v1';
import {
  McmObjectActivity,
  McmObjectLastRunActivities,
  ObjectServiceApi,
  ProtectedObject,
  ProtectedObjectInfo,
  SearchServiceApi,
} from '@cohesity/api/v2';
import { flagEnabled, IrisContextService, isDmsScope, isMcm } from '@cohesity/iris-core';
import { ItemId, LazyItemStore, StoreEntry } from '@cohesity/utils';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, take } from 'rxjs/operators';
import { PassthroughOptionsService } from 'src/app/core/services';
import {
  Environment,
  JobEnvParamsV2,
  Office365ActionKeyProtectionTypeMap,
  OracleObjectType,
} from 'src/app/shared/constants';
import { PassthroughOptions } from 'src/app/shared/models';

import { checkObjectActionKeyForMultiWorkloadSupport, clusterSupportsObjectStore } from './object-action-utils';

/**
 * Interface for an object which has a fullId key.
 */
interface FullIdObject<T> {
  /**
   * The string full id of the object.
   */
  fullId: ItemId;

  /**
   * Reference to the orginal object.
   */
  reference: T;
}

/**
 * Interface for additional parameters for object info service.
 */
export interface ObjectInfoServiceOptions extends PassthroughOptions {
  /**
   * Specifies the workload/backup type.
   */
  actionKey?: any;
}

/**
 * Type for the value returned by ObjectStoreGetFunction.
 */
type ObjectStoreReturnType<T> = Observable<StoreEntry<T>>;

/**
 * Type for the value returned by ObjectStoreGetFunction.
 */
type ObjectStoreItemsReturnType<T> = Observable<StoreEntry<T>[]>;

/**
 * Type for the function used to return objects from the object store.
 */
export type ObjectStoreGetFunction<T> = (id: ItemId, options?: ObjectInfoServiceOptions) => ObjectStoreReturnType<T>;

/**
 * Type for the function used to return objects from the object store.
 */
export type ObjectStoreGetItemsFunction<T> = (
  objects: {
    id: ItemId;
    options?: ObjectInfoServiceOptions;
  }[]
) => ObjectStoreItemsReturnType<T>;

/**
 * This uses lazy stores to look up and cache details for individual objects.
 * The object id can be passed in format of "objectId" or
 * "objectId:accessClusterId:regionId". If a accessClusterId or regionId is
 * present, it is used for making the appropriate passthrough calls.
 */
@Injectable()
export class ObjectInfoService {
  /**
   * Function to get protected object from the protectedObjects store.
   */
  getProtectedObject: ObjectStoreGetFunction<ProtectedObject>;

  /**
   * Function to get protected objects from the protectedObjects store.
   */
  getProtectedObjects: ObjectStoreGetItemsFunction<ProtectedObject>;

  /**
   * Function to getlast run actvities from the object activity store.
   */
  getLastRunActivity: ObjectStoreGetFunction<McmObjectActivity>;

  /**
   * Function to getlast run actvities from the object activity store.
   */
  getLastRunActivities: ObjectStoreGetItemsFunction<McmObjectActivity>;

  /**
   * Function to get object info from the objectInfo store.
   */
  getObjectInfo: ObjectStoreGetFunction<ProtectedObjectInfo>;

  /**
   * Function to get object infos from the objectInfo store.
   */
  getObjectInfos: ObjectStoreGetItemsFunction<ProtectedObjectInfo>;

  /**
   * Function to get v1 object from the v1ObjectInfo store.
   */
  getV1ObjectInfo: ObjectStoreGetFunction<ProtectionSourceNode>;

  /**
   * Function to get v1 objects from the v1ObjectInfo store.
   */
  getV1ObjectInfos: ObjectStoreGetItemsFunction<ProtectionSourceNode>;

  /**
   * A store of protected objects. Requesting objects from the store
   * will trigger API calls if they are not already present.
   */
  private protectedObjects: LazyItemStore<FullIdObject<ProtectedObject>>;

  /**
   * A store with information on protected objects, including protection and
   * last run details.
   */
  private objectInfo: LazyItemStore<FullIdObject<ProtectedObjectInfo>>;

  /**
   * A store with v1 object info. The api does not support multiple lookups at
   * once, so code that relies on multiple look up (like the source tree), should
   * cache v1 object info and avoid relying on this.
   */
  private v1ObjectInfo: LazyItemStore<FullIdObject<ProtectionSourceNode>>;

  /**
   * A store with last run activity info. This is only supported in helios.
   */
  private lastRunInfo: LazyItemStore<FullIdObject<McmObjectActivity>>;

  /**
   * Whether the app is in mcm mode.
   */
  isMcm: boolean;

  /**
   * Whether loading clusters currently.
   */
  clustersLoading$ = new BehaviorSubject<boolean>(true);

  /**
   * Map of cluster ids and whether they support object store.
   */
  clusterObjectStoreSupportMap: Record<string, boolean> = {};

  /**
   * Behavior subject to store whether protected objects are being loaded.
   */
  private protectedObjectsLoading = new BehaviorSubject<boolean>(false);

  /**
   * Observable for whether protected objects are being loaded.
   */
  protectedObjectsLoading$ = this.protectedObjectsLoading.asObservable();

  /**
   * Behavior subject to store whether objects are being loaded.
   */
  private objectsLoading = new BehaviorSubject<boolean>(false);

  /**
   * Observable for whether objects are being loaded.
   */
  objectsLoading$ = this.objectsLoading.asObservable();

  /**
   * Behavior subject to store whether v1 objects are being loaded.
   */
  private v1ObjectsLoading = new BehaviorSubject<boolean>(false);

  /**
   * Observable for whether v1 objects are being loaded.
   */
  v1ObjectsLoading$ = this.v1ObjectsLoading.asObservable();

  /**
   * Whether object info service is loading.
   */
  loading$ = combineLatest([this.protectedObjectsLoading, this.objectsLoading, this.v1ObjectsLoading]).pipe(
    map(([...values]) => values.some(value => value))
  );

  /**
   * Set a default action key to use during api calls if none are specified for a given object.
   *
   */
  defaultActionKey: string;

  constructor(
    private searchService: SearchServiceApi,
    private objectService: ObjectServiceApi,
    private sourceService: ProtectionSourcesServiceApi,
    private passthroughOptionsService: PassthroughOptionsService,
    private mcmClusterServiceApi: McmClusterServiceApi,
    private irisContextService: IrisContextService
  ) {
    this.isMcm = isMcm(this.irisContextService.irisContext);

    if (this.isMcm) {
      // Load all cluster details when in mcm mode. This is used to determine
      // whether a v2 API call should be blocked for an object in a cluster
      // which doesn't supports it.
      this.mcmClusterServiceApi
        .getUpgradeInfo()
        .pipe(
          catchError(() => of({ upgradeInfo: [] })),
          finalize(() => this.clustersLoading$.next(false))
        )
        .subscribe(value => {
          const clusters = value?.upgradeInfo || [];

          for (const cluster of clusters) {
            this.clusterObjectStoreSupportMap[cluster.clusterId] = clusterSupportsObjectStore(cluster);
          }
        });
    } else {
      this.clustersLoading$.next(false);
    }

    this.protectedObjects = new LazyItemStore(
      object => object.fullId,

      // Group ids into the clusters or regions they belong to and query
      // the clusters or regions separately.
      (ids: ItemId[]) =>
        forkJoin(this.getGroupedIds(ids).map(groupedIds => this.fetchProtectedObjects(groupedIds))).pipe(
          map(result => result.reduce((acc, item) => [...acc, ...item], []))
        ),
      500
    );

    this.objectInfo = new LazyItemStore(
      object => object.fullId,

      // Group ids into the clusters or regions they belong to and query
      // the clusters or regions separately.
      (ids: ItemId[]) =>
        forkJoin(this.getGroupedIds(ids).map(groupedIds => this.fetchObjectInfo(groupedIds))).pipe(
          map(result => result.reduce((acc, item) => [...acc, ...item], []))
        ),
      500
    );

    // Set up a lazy store with a max request size of one since the
    // v1 source api doesn't support looking up multiple objects at
    // a time.
    this.v1ObjectInfo = new LazyItemStore(
      object => object.fullId,

      // Group ids into the clusters or regions they belong to and query
      // the clusters or regions separately.
      (ids: ItemId[]) =>
        forkJoin(this.getGroupedIds(ids).map(groupedIds => this.fetchV1Object(groupedIds))).pipe(
          map(result => result.reduce((acc, item) => [...acc, ...item], []))
        ),
      1
    );

    this.lastRunInfo = new LazyItemStore(
      object => object.fullId,

      // Group ids into the clusters or regions they belong to and query
      // the clusters or regions separately.
      (ids: ItemId[]) =>
        forkJoin(this.getGroupedIds(ids).map(groupedIds => this.fetchLastRunActivities(groupedIds))).pipe(
          map(result => result.reduce((acc, item) => [...acc, ...item], []))
        ),
      500
    );

    const storeLookup = <T>(store: LazyItemStore<FullIdObject<T>>) => (
      id: ItemId,
      options?: ObjectInfoServiceOptions
    ) =>
      store.getItem(this.getFullObjectId(id, options)).pipe(
        map(value => ({
          ...value,
          item: value?.item?.reference,
        }))
      );

    const storeBulkLookup = <T>(store: LazyItemStore<FullIdObject<T>>) => (
      objects: {
        id: ItemId;
        options?: ObjectInfoServiceOptions;
      }[]
    ) =>
      store.getItems(objects.map(object => this.getFullObjectId(object.id, object.options))).pipe(
        map(values =>
          values.map(value => ({
            ...value,
            item: value?.item?.reference,
          }))
        )
      );

    this.getProtectedObject = storeLookup(this.protectedObjects);
    this.getProtectedObjects = storeBulkLookup(this.protectedObjects);
    this.getObjectInfo = storeLookup(this.objectInfo);
    this.getObjectInfos = storeBulkLookup(this.objectInfo);
    this.getV1ObjectInfo = storeLookup(this.v1ObjectInfo);
    this.getV1ObjectInfos = storeBulkLookup(this.v1ObjectInfo);
    this.protectedObjects.connect().subscribe();
    this.objectInfo.connect().subscribe();
    this.v1ObjectInfo.connect().subscribe();

    if (isDmsScope(this.irisContextService.irisContext) &&
      flagEnabled(this.irisContextService.irisContext, 'ngSourceDetailsLastRunMcmFilters')) {
      this.getLastRunActivity = storeLookup(this.lastRunInfo);
      this.getLastRunActivities = storeBulkLookup(this.lastRunInfo);
      this.lastRunInfo.connect().subscribe();
    }
  }

  /**
   * Return full object id in the format "objectId:accessClusterId:regionId:actionKey".
   *
   * @param id The id of object to return the full id of.
   * @param options Optional. Optional pasthrough and action key options.
   * @return The full node id.
   */
  getFullObjectId(id: ItemId, options: ObjectInfoServiceOptions = {}): ItemId {
    // Use the provided access cluster id, otherwise use injected accessClusterId.
    const accessClusterId = options.accessClusterId || this.passthroughOptionsService.accessClusterId;

    // Use the provided region id, otherwise use injected regionId.
    const regionId = options.regionId || this.passthroughOptionsService.regionId;

    return [id, accessClusterId, regionId, options.actionKey || this.defaultActionKey].join(':');
  }

  /**
   * Function to accept an array of ids in the format
   * "objectId:accessClusterId:regionId" and return them grouped by common
   * accessClusterId and regionId.
   *
   * @param ids Array of ids.
   * @return The grouped ids.
   */
  getGroupedIds(ids: ItemId[]): string[][] {
    return Object.values(
      ids.reduce((result, id) => {
        const [, accessClusterId, regionId, actionKey] = String(id).split(':');
        const key = [accessClusterId, regionId, actionKey].join();

        result[key] = result[key] || [];
        result[key].push(id);

        return result;
      }, {})
    );
  }

  /**
   * Refreshes the cached data in each of the stores for an object.
   *
   * @param   objectId   The object's id.
   * @param   options    Optional. Options from where to fetch the node.
   */
  refreshObjectInfo(objectId: ItemId, options: ObjectInfoServiceOptions = {}) {
    const fullId = this.getFullObjectId(objectId, options);
    this.protectedObjects.getItem(fullId, false).subscribe();
    this.objectInfo.getItem(fullId, false).subscribe();
    this.v1ObjectInfo.getItem(fullId, false).subscribe();
    this.lastRunInfo.getItem(fullId, false).subscribe();
  }

  /**
   * Clears all cached data for the store. While refresh updates a single object,
   * this will clear data for all objects.
   */
  reset() {
    this.protectedObjects.reset();
    this.objectInfo.reset();
    this.v1ObjectInfo.reset();
    this.lastRunInfo.reset();
    this.defaultActionKey = null;
  }

  /**
   * Fetches protected objects for a set of ids using the search api
   *
   * @param   objectIds The object ids to look up
   * @returns An observable of matching protected objects.
   */
  fetchProtectedObjects(objectIds: string[]): Observable<FullIdObject<ProtectedObject>[]> {
    const accessClusterId = objectIds[0].split(':')[1] ? Number(objectIds[0].split(':')[1]) : null;
    const [, , regionId, actionKey] = objectIds[0].split(':');
    let environments;

    let objectActionKey = null;
    if (checkObjectActionKeyForMultiWorkloadSupport(actionKey as Environment)) {
      objectActionKey = actionKey;
    }
    this.protectedObjectsLoading.next(true);

    // In case of physical source both the environments needs to be passed here
    // otherwise v2 protected objects doesnt return the file based objects.
    if (objectActionKey === Environment.kPhysical) {
      environments = [Environment.kPhysical, Environment.kPhysicalFiles];
    }

    // TODO(tauseef): Derive the snapshot action type to specify the same
    // within SearchProtectedObjects API.
    return this.clustersLoading$.pipe(
      filter(value => !value),
      take(1),
      switchMap(() => {
        if (this.isMcm && accessClusterId && !this.clusterObjectStoreSupportMap[accessClusterId]) {
          // Return null as reference if the cluster does not support v2 API
          // calls required for object store. Only applicable in mcm mode.
          return of(
            objectIds.map(id => ({
              fullId: this.getFullObjectId(id, { accessClusterId, regionId, actionKey: objectActionKey }),
              reference: null,
            }))
          ) as Observable<FullIdObject<ProtectedObject>[]>;
        }

        return this.searchService
          .SearchProtectedObjects({
            objectIds: objectIds.map(objectId => Number(String(objectId).split(':')[0])),
            accessClusterId,
            regionId,
            objectActionKey,
            environments,
          })
          .pipe(
            catchError(() => of({ objects: [] })),
            finalize(() => this.protectedObjectsLoading.next(false)),
            map(result =>
              (result?.objects || []).filter(item => item?.objectType !== OracleObjectType.kPDB).map(object => ({
                fullId: this.getFullObjectId(object.id, { accessClusterId, regionId, actionKey: objectActionKey }),
                reference: object,
              }))
            )
          );
      })
    );
  }

  /**
   * Fetches protected objects for a set of ids using the protected objects api.
   *
   * @param   ids The ids to look up.
   * @returns An observable of matching protected objects.
   */
  fetchObjectInfo(ids: string[]): Observable<FullIdObject<ProtectedObjectInfo>[]> {
    const accessClusterId = ids[0].split(':')[1] ? Number(ids[0].split(':')[1]) : null;
    const [, , regionId, objectActionKey] = ids[0].split(':');

    let actionKeyList = null;

    // We rely on getting both AWSNative and AWSSnapshotManager in case the id
    // contains either of the keys. At the moment, the API doesn't allow us to
    // set two values per id, we keep it null.
    if (checkObjectActionKeyForMultiWorkloadSupport(objectActionKey as Environment) &&
        (objectActionKey as Environment) !== Environment.kAWSSnapshotManager &&
        (objectActionKey as Environment) !== Environment.kAWSNative) {
      // Current API mandates a 1:1 mapping between object ID & action key.
      //
      // TODO(tauseef): This can potentially lead to URL length exceeding
      // character limits for GET requests within browser. Remove this once
      // the API removes the mandates.
      actionKeyList = Array(ids.length).fill(objectActionKey) as ObjectServiceApi
        .GetProtectedObjectsOfAnyTypeParams['objectActionKeys'];
    }

    this.protectedObjectsLoading.next(true);

    return this.clustersLoading$.pipe(
      filter(value => !value),
      take(1),
      switchMap(() => {
        if (this.isMcm && accessClusterId && !this.clusterObjectStoreSupportMap[accessClusterId]) {
          // Return null as reference if the cluster does not support v2 API
          // calls required for object store. Only applicable in mcm mode.
          return of(
            ids.map(id => ({
              fullId: this.getFullObjectId(id, { accessClusterId, regionId, actionKey: objectActionKey }),
              reference: null,
            }))
          ) as Observable<FullIdObject<ProtectedObjectInfo>[]>;
        }

        return this.objectService
          .GetProtectedObjectsOfAnyType({
            ids: ids.map(id => Number(id.split(':')[0])),
            includeLastRunInfo: true,
            accessClusterId,
            regionId,
            objectActionKeys: actionKeyList,
          })
          .pipe(
            catchError(() => of({ objects: [] })),
            finalize(() => this.protectedObjectsLoading.next(false)),
            map(result => {
              const protectionInfos = {};
              return (result?.objects || [])
                // Sorting based on startTimeUsecs, so that latest snapshot info
                // is used when we have duplicate ids
                .sort((a, b) => (a?.lastRun?.localSnapshotInfo?.snapshotInfo?.startTimeUsecs || 0)
                  - (b?.lastRun?.localSnapshotInfo?.snapshotInfo?.startTimeUsecs || 0)
                )
                .map(object => {
                  const fullObjectId = this.getFullObjectId(
                    object.id, { accessClusterId, regionId, actionKey: objectActionKey }
                  );
                  // To aggregate all previous runs for same object
                  // All runs are required to show them in popover for physical workload
                  protectionInfos[fullObjectId] = [...(protectionInfos[fullObjectId] || []), { ...object }];

                  return ({
                    fullId: fullObjectId,
                    reference: { ...object, lastRuns: protectionInfos[fullObjectId] },
                  });
                });
            }),
            map(result =>
              // HACK: Currently the V2 API is ignoring the `objectActionKeys` param.
              // To show the correct results, filter on the objectActionKey on the client side.
              result.filter(item => {
                const environment = item.reference?.environment;
                const objectBackupConfiguration = item.reference?.objectBackupConfiguration;
                const paramsKey = JobEnvParamsV2[environment];
                const protectionType = Office365ActionKeyProtectionTypeMap[objectActionKey];
                const objectProtectionType = objectBackupConfiguration?.[paramsKey]?.objectProtectionType;

                if (environment === Environment.kO365 && objectActionKey && objectProtectionType) {
                  return objectProtectionType === protectionType;
                }

                return true;
              })
            )
          );
      })
    );
  }

  /**
   * Fetches details for a given source.
   *
   * @param   ids   The ids to look up.
   */
  fetchV1Object(ids: string[]): Observable<FullIdObject<ProtectionSourceNode>[]> {
    if (ids.length !== 1) {
      throw new Error('Fetching V1 Object only works for one object at a time');
    }

    const accessClusterId = ids[0].split(':')[1] ? Number(ids[0].split(':')[1]) : null;
    const [, , regionId, objectActionKey] = ids[0].split(':');

    return this.sourceService
      .ListProtectionSources({
        id: Number(ids[0].split(':')[0]),
        allUnderHierarchy: false,
        accessClusterId,
        regionId,
      })
      .pipe(
        catchError(() => of([] as ProtectionSourceNode[])),
        map(result =>
          result.map(source => ({
            fullId: this.getFullObjectId(source.protectionSource.id, {
              accessClusterId,
              regionId,
              actionKey: objectActionKey,
            }),
            reference: source,
          }))
        )
      );
  }

  /**
   * Fetches run detail activities for a set of objects.
   *
   * @param ids The ids to look up.
   * @returns An observable of matching activity runs.
   */
  fetchLastRunActivities(ids: string[]): Observable<FullIdObject<McmObjectActivity>[]> {
    if (!isDmsScope(this.irisContextService.irisContext)) {
      return of([]);
    }
    const accessClusterId = ids[0].split(':')[1] ? Number(ids[0].split(':')[1]) : null;
    const [, , regionId, objectActionKey] = ids[0].split(':');

    return this.objectService
      .GetMcmObjectsLastRunActivity({
        body: {
          objectIdentifiers: ids.map(id => ({
            objectId: Number(id.split(':')[0]),
            regionId,
          })),
          includeDetails: true,
          excludeStats: true,
          isProtected: true,

          // include workload type when it is available.
        },
        regionIds: [regionId],
      })
      .pipe(
        catchError(() => of({ objectLastRuns: [] } as McmObjectLastRunActivities)),
        map(result =>
          (result?.objectLastRuns || []).map((run: McmObjectActivity) => ({
            fullId: this.getFullObjectId(run.object.id, {
              accessClusterId,
              regionId,
              actionKey: objectActionKey,
            }),
            reference: run,
          }))
        )
      );
  }
}
