<button [attr.aria-label]="'help' | translate"
  (click)="toggleMessenger()"
  [matBadge]="heliosAnalyticsService.intercomUnreadCount$ | async"
  [matBadgeHidden]="(heliosAnalyticsService.intercomUnreadCount$ | async) === 0"
  cogDataId="nav-help-button"
  id="top-nav-help"
  mat-icon-button
  matBadgeColor="primary"
  matBadgePosition="right"
  matBadgeSize="small"
  type="button">
  <mat-icon>help_outline</mat-icon>
</button>
