// Module & Controller: Jobs page
import { Environment } from 'src/app/shared/constants';

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.jobs', ['C.policyService', 'C.createJobButton'])
    .config(ConfigFn)
    .controller('JobsController', JobsControllerFn);

  // Jobs states config
  function ConfigFn($stateProvider) {
    $stateProvider
      .state('jobs', {
        url: '/protection/jobs?' + [
          '{protectedObject}',
          '{jobType}',
          '{lastRunStatus}',
          '{lastRunSlaStatus}',
          '{jobRunErrors}'
        ].join('&'),
        params: {
          protectedObject: {
            type: 'string',
            value: '',
            dynamic: true,
            squash: true,
          },
          jobType: {
            type: 'string',
            value: '',
            dynamic: true,
            squash: true,
          },
          lastRunStatus: {
            type: 'string',
            value: '',
            dynamic: true,
            squash: true,
          },
          lastRunSlaStatus: {
            type: 'string',
            dynamic: true,
            squash: true,
          },
          jobRunErrors: {
            type: 'cBool',
            dynamic: true,
            squash: true,
          },
        },
        help: 'protection_jobs',
        title: 'Protection Jobs',
        canAccess: 'PROTECTION_VIEW',
        templateUrl: 'app/protection/jobs/jobs.html',
        controller: 'JobsController',
      });
  }

  // Jobs controller
  function JobsControllerFn(_, $rootScope, $scope, $state, $timeout, $q,
    $httpParamSerializer, $interval, JobActionService, DateTimeService,
    JobRunsService, JobService, SourceService, evalAJAX, cUtils, UserService,
    PolicyService, ExternalTargetService, TenantService, ENV_TYPE_CONVERSION,
    ENV_GROUPS, CHART_COLORS, FEATURE_FLAGS, ENUM_BACKUP_JOB_STATUS,
    ENUM_BACKUP_JOB_STATUS_LABEL_CLASSNAME, JOBS_RELOAD_INTERVAL_MILLISECONDS,
    AdaptorAccessService) {

    // hash map of policies based on id
    var allPolicies = {};
    var promiseArray = [];

    // Promise returned by $interval for reloading jobs at an interval
    var jobsIntervalPromise;

    $scope.ENUM_BACKUP_JOB_STATUS = ENUM_BACKUP_JOB_STATUS;
    $scope.ENUM_BACKUP_JOB_STATUS_LABEL_CLASSNAME =
      ENUM_BACKUP_JOB_STATUS_LABEL_CLASSNAME;
    $scope.preferredDateFormat = DateTimeService.getPreferredDateFormat();

    $scope.getJobStatusIcon = JobRunsService.getJobStatusIcon;

    // TODO: Remove
    $scope.text = $rootScope.text.jobs;

    $scope.jobs = [];
    $scope.jobListReady = false;
    $scope.filters = {
      activeJobFilter: null,
      jobTypeFilter: [],
      jobTenantFilter: [],
      lastRunStatusFilter: null,
      lastRunSlaFilter: null,
    };
    $scope.totals = {
      cancel: 0,
      error: 0,
      running: 0,
      sla: 0,
      success: 0,
      total: 0,
      warning: 0,
    };

    /**
     * List of available job types to filter the listing by. Options are hidden
     * when `display` prop is falsey (undefined).
     *
     * @type {Array}  - List of {
     *   display: @string,
     *   enum: @array[integers],
     *   icon: @string?
     * }
     */
    $scope.jobTypes = [
      {
        display: 'job.summary.allProtectionJobTypes',
        environments: [],
        enum: [],
        kVal: 'kOther',
      },
      {
        display: 'vmServers',
        environments: cUtils.onlyStrings(ENV_GROUPS.hypervisor),
        enum: cUtils.onlyNumbers(ENV_GROUPS.hypervisor),
        icon: 'icn-type-vm',
        kVal: 'kVM',
      },
      {
        display: 'physicalServers',
        environments: cUtils.onlyStrings(ENV_GROUPS.physical),
        enum: cUtils.onlyNumbers(ENV_GROUPS.physical),
        icon: 'icn-type-physical',
        kVal: 'kPhysical',
      },
      {
        display: 'views',
        environments: [Environment.kView],
        enum: [4],
        icon: 'icn-type-view',
        kVal: 'kFile',
      },
      {
        display: 'remoteAdapter',
        environments: [Environment.kPuppeteer],
        enum: [5],
        icon: 'icn-type-remote-adapter',
        kVal: 'kPuppeteer',
      },
      {
        display: 'msSql',
        environments: [Environment.kSQL],
        enum: [3],
        icon: 'icn-type-sql',
        kVal: 'kDB',
      },
      {
        // If Nimble is enabled, only show a unified Storage Volumes filter.
        ...(FEATURE_FLAGS.nimbleEnabled ? {
          display: 'storageVolumes',
          environments: cUtils.onlyStrings(ENV_GROUPS.san),
          enum: cUtils.onlyNumbers(ENV_GROUPS.san),
        } : {
          display: 'pureVolumes',
          environments: [Environment.kPure],
          enum: [7],
        }),
        icon: 'icn-type-pure',
        // TODO(pg): What is the below key used for?
        kVal: 'kPureVolume',
      },
      {
        display: 'nasVolumes',
        environments: cUtils.onlyStrings(ENV_GROUPS.nas),
        enum: cUtils.onlyNumbers(ENV_GROUPS.nas),
        icon: 'icn-type-nas',
        kVal: 'kNAS',
      },
      {
        display: 'oracle',
        environments: [Environment.kOracle],
        enum: [19],
        icon: 'icn-type-oracle',
        kVal: 'kOracle',
      },
      {
        display: 'office365',
        environments: cUtils.onlyStrings(ENV_GROUPS.office365),
        enum: cUtils.onlyNumbers(ENV_GROUPS.office365),
        icon: 'icn-type-office365',
        kVal: 'kO365Outlook',
      },
      {
        display: 'activeDirectory',
        environments: [Environment.kAD],
        enum: [29],
        icon: 'icn-type-active-directory',
        kVal: 'kAD',
      },
      {
        display: 'kubernetes',
        environments: [Environment.kKubernetes],
        enum: [34],
        icon: 'icn-type-kubernetes',
        kVal: 'kKubernetes',
      },
    ].filter(({ environments }) => {
      return !environments.length ||
        AdaptorAccessService.canAccessSomeEnv(environments);
    });

    // TODO(Sam): When Multi-Tenancy is designed for Helios, We will add
    // privileges to individual adapters. For now, GCPBaaS is the only Helios
    // Multi-Tenant Scenario. ETA for Helios Multi-Tenancy is HE-2019.07.2
    if (UserService.isHeliosTenantUser()) {
      $scope.jobTypes = [
        {
          display: 'vmServers',
          enum: cUtils.onlyNumbers(ENV_GROUPS.hypervisor),
          icon: 'icn-type-vm',
          kVal: 'kVM',
        },
      ];
    }

    $scope.filterListOptions = [{
      value: 'all',
      name: $scope.text.allJobs,
    }, {
      value: 'active',
      name: $scope.text.activeJobs,
    }, {
      value: 'inactive',
      name: $scope.text.inactiveJobs,
    }, {
      value: 'deleted',
      name: $scope.text.deletedJobs,
    }];

    /** SLA filter options. */
    $scope.slaFilterOptions = ['all', 'kPass', 'kFail'];

    /** Last Run Status filter options. */
    $scope.statusFilterOptions = ['all', 'kSuccess', 'kError', 'kRunning', 'kCanceled'];

    /**
     * Loads all the job data.
     */
    function loadData() {
      $scope.jobListReady = false;

      // call getSources without processing response to ensure our sources list
      // is cached before user creates a job
      promiseArray.push(
        SourceService.getSources({
          onlyReturnOneLevel: true
        }, true)
      );

      // get all policies for policyId mapping
      if ($rootScope.user.privs.PROTECTION_POLICY_VIEW) {
        promiseArray.push(
          PolicyService.getPoliciesHashmap().then(
            function getPoliciesSuccess(policiesHashMap) {
              allPolicies = policiesHashMap;
            }
          )
        );
      }

      // If ExternalTargetService.targetNameMapById is empty, we need to build
      // it.
      if ($rootScope.user.privs.CLUSTER_EXTERNAL_TARGET_VIEW &&
        angular.equals({}, ExternalTargetService.targetNameMapById)) {
        promiseArray.push(
          ExternalTargetService.getTargets()
        );
      }

      // FIRE ALL REQUESTS
      $q.all(promiseArray)
        .then(
          angular.noop,
          evalAJAX.errorMessage
        )
        // try to complete the page load even if some promises are rejected, for
        // instances, if External Targets (vaults/) aren't fetched successfully
        // we can likely still load the Job runs
        .finally(
          getJobSummaryData
        );

    }

    /**
     * Gets Job Summary Data
     */
    function getJobSummaryData() {
      var params = buildParams();

      JobRunsService.getJobSummary(params).then(
        function getJobSummarySuccess(r) {
          var progressMonitorParams;

          // Clear out jobs array before we add new results
          $scope.jobs.length = 0;

          // call the function to build the job status chart
          buildJobStatusChartData(r.data);

          if (r.data && r.data.length) {
            angular.forEach(r.data,
              function processJobData(job) {
                // Add policy object for the job if it exists in policies hash
                // mapping
                job.backupJobSummary._policy =
                  allPolicies[job.backupJobSummary.jobDescription.policyId] || null;

                // Add Progress Monitor Support if there's a task path
                if (_.get(job.backupJobSummary, 'lastProtectionRun.backupRun.activeAttempt.base.progressMonitorTaskPath')) {
                  progressMonitorParams = {
                    taskPathVec: job.backupJobSummary.lastProtectionRun.backupRun.activeAttempt.base.progressMonitorTaskPath,
                    includeFinishedTasks: true,
                    excludeSubTasks: true
                  };
                  job.backupJobSummary._jobStatusUrl = [
                    'progressMonitors?=',
                    $httpParamSerializer(progressMonitorParams)
                  ].join('');

                  job.backupJobSummary._pollingCallback = function pollCallback(status) {
                    if (status === 'completed') {
                      job.backupJobSummary.lastProtectionRun.backupRun.base._status = 2.1;
                      updateJobSummaryChartData();
                      // Get Updated Job Summary Data from Magneto
                      getJobSummaryData();
                    }
                    if (status === 'error') {
                      job.backupJobSummary.lastProtectionRun.backupRun.base._status = 2.2;
                      updateJobSummaryChartData();
                      // Get Updated Job Summary Data from Magneto
                      getJobSummaryData();
                    }
                    // Update Copy Task info
                    job.backupJobSummary._copyTasks = JobRunsService.getCopyTasks(
                      job.backupJobSummary.lastProtectionRun,
                      job.backupJobSummary.jobDescription.jobUid,
                      job.backupJobSummary.jobDescription.isActive
                    );
                    // Update Actions Menu Configuration
                    job.backupJobSummary._actionsMenu =
                      getActionsMenuConfig(job.backupJobSummary);
                  };
                }

                // Add Copy Task info
                job.backupJobSummary._copyTasks = JobRunsService.getCopyTasks(
                  job.backupJobSummary.lastProtectionRun,
                  job.backupJobSummary.jobDescription.jobUid,
                  job.backupJobSummary.jobDescription.isActive
                );

                // Add Actions Menu Configuration
                job.backupJobSummary._actionsMenu =
                  getActionsMenuConfig(job.backupJobSummary);

                job.selectDisabled = 'tenants' in job;

                // Add this processed job to $scope.jobs
                $scope.jobs.push(job);
              }
            );
          }
        }
      )
      .finally(function afterGetAllJobs() {
        $scope.jobStatusChart.loading = false;
      });
    }

    /**
     * Updates the charts with latest Magneto data
     * NOTE this does not refresh the jobs list
     */
    function updateJobSummaryChartData() {
      var params = buildParams();

      JobRunsService.getJobSummary(params).then(
        function getJobSummarySuccess(r) {
          // call the function to build the job status chart
          buildJobStatusChartData(r.data);
        }
      );
    }

    /**
     * Configure params for Job Summary API call
     * Prams are based on $scope.filters
     *
     * @return {Object} params
     */
    function buildParams() {
      var params = {
        pruneExclusionSources: true,
        includeJobsWithoutRun: true,
        isDeleted: false,
        numRuns: 1000,
        onlyReturnJobDescription: false,
        onlyReturnBasicSummary: true,
        _includeTenantInfo: true,
      };

      if ($scope.filters.jobTypeFilter && $scope.filters.jobTypeFilter.length) {
        params.envTypes = $scope.filters.jobTypeFilter;
      }

      if ($scope.filters.activeJobFilter) {
        switch ($scope.filters.activeJobFilter) {
          case 'all':
            params.isDeleted = false;
            break;

          case 'active':
            params.isActive = true;
            break;

          case 'inactive':
            params.isActive = false;
            break;

          case 'deleted':
            params.isDeleted = true;
        }
      }

      if ($scope.filters.lastRunStatusFilter) {
        switch ($scope.filters.lastRunStatusFilter) {
          case 'all':
            params.statuses = undefined;
            break;
          case 'kSuccess':
            params.statuses = ['kBackupJobSuccess'];
            break;
          case 'kError':
            params.statuses = ['kBackupJobFailure'];
            break;
          case 'kRunning':
            params.statuses = ['kBackupJobRunning'];
            break;
          case 'kCanceled':
            params.statuses = ['kBackupJobCanceled'];
            break;
        }
      }

      if ($scope.filters.lastRunSlaFilter) {
        switch ($scope.filters.lastRunSlaFilter) {
          case 'all':
            params.isLastRunSlaViolated = undefined;
            break;
          case 'kPass':
            params.isLastRunSlaViolated = false;
            break;
          case 'kFail':
            params.isLastRunSlaViolated = true;
            break;
        }
      }

      return TenantService.extendWithTenantParams(params,
        $scope.filters.jobTenantFilter);
    }

    $scope.jobStatusChart = {
      chartType: 'donut',
      titleText: 'totalJobs',
      loading: true,
      colors: [
        CHART_COLORS.brand,
        CHART_COLORS.green,
        CHART_COLORS.yellow,
        CHART_COLORS.red,
        CHART_COLORS.gold,
      ],
      chart: {
        height: 250,
        marginBottom: 35,
        spacingBottom: 0
      },
      series: [{
        type: 'pie',
        name: 'jobs',
        data: []
      }]
    };

    function buildJobStatusChartData(backupJobs) {
      var totalJobCount = 0;
      var job;
      var index = 0;

      // hashed per ENUM_BACKUP_JOB_STATUS
      var jobCounts = {
        0: 0,
        1: 0,
        2: 0,
        2.1: 0,
        2.2: 0,
        2.3: 0,
        3: 0,
        3.1: 0,
      };

      if (backupJobs && backupJobs.length) {
        for (index; index < backupJobs.length; index++) {
          job = backupJobs[index];
          if (job.backupJobSummary.lastProtectionRun) {
            job.backupJobSummary.lastProtectionRun.backupRun.base._status = JobService.getStatus(job.backupJobSummary.lastProtectionRun.backupRun);
            job.backupJobSummary.lastProtectionRun.backupRun.base._errorMsg = JobService.getErrorMsg(job.backupJobSummary.lastProtectionRun.backupRun);
          }
          // only add the job to our chart if it isn't deleted and has a last run
          if (!job.backupJobSummary.jobDescription.deletionStatus &&
           job.backupJobSummary.lastProtectionRun) {
            // increment status counter for job runs chart
            jobCounts[job.backupJobSummary.lastProtectionRun.backupRun.base._status]++;
            // increment total job count
            totalJobCount++;
          }
        }

        // Set this count for At A Glance Totals
        $scope.totals.running = jobCounts[1] + jobCounts[3.1];

        $scope.jobStatusChart.series[0].data = [
          [ENUM_BACKUP_JOB_STATUS[1], (jobCounts[1] + jobCounts[3.1])],
          [ENUM_BACKUP_JOB_STATUS[2.1], jobCounts[2.1]],
          [ENUM_BACKUP_JOB_STATUS[2.3], jobCounts[2.3]],
          [ENUM_BACKUP_JOB_STATUS[2.2], jobCounts[2.2]],
          [ENUM_BACKUP_JOB_STATUS[3], jobCounts[3]]
        ];
      }

      $scope.jobListReady = true;
    }

    $scope.jobsRunningChart = {
      // start and end date are displayed in HTML below the chart
      startDate: null,
      endDate: null,
      chartType: 'stacked',
      loading: true,
      series: [{
        name: $scope.text.jobsRunningChart.labelRunning,
        data: []
      }, {
        name: $scope.text.jobsRunningChart.labelSuccess,
        data: []
      }, {
        name: $scope.text.jobsRunningChart.labelWarning,
        data: []
      }, {
        name: $scope.text.jobsRunningChart.labelError,
        data: []
      }, {
        name: $scope.text.jobsRunningChart.labelCanceled,
        data: []
      }],
      chart: {
        height: 250,
        marginBottom: 35,
        spacingBottom: 0
      },
      legend: {
        align: 'left',
        verticalAlign: 'top',
        x: -8,
        y: -16
      },
      tooltip: {
        pointFormat: '<b>{point.y:.1f} jobs</b>'
      },
      yAxis: {
        allowDecimals: false,
        title: {
          text: 'Job Runs',
          align: 'high',
          offset: 35,
          rotation: 90,
          y: 115,
          style: {
            fontSize: '11px',
            lineHeight: '12px',
            color: '#868686'
          }
        }
      },
      xAxis: {
        categories: [],
        labels: {
          staggerLines: 1,
          step: 2
        }
      },
      plotOptions: {
        series: {
          pointWidth: 12
        }
      }
    };

    function buildRunsChart() {
      var seriesActiveData = [];
      var seriesSuccessData = [];
      var seriesErrorData = [];
      var seriesWarningData = [];
      var seriesCanceledData = [];
      var seriesViolationData = [];

      // properties of runsPerHour will be structured like (0-23)
      // 0: {
      //     numActiveRuns: 0,
      //     numSuccessfulRuns: 0,
      //     numWarningRuns: 0,
      //     numErrorRuns: 0,
      //     numViolationRuns: 0,
      //     startUsecs: ####,
      //     endUsecs: ####
      // }
      var categories = [];
      var dayAgoHour;
      var currentHourMaxUsecs;
      var currentHourMinUsecs;
      var job;
      var jobRun;
      var jobStartTime;
      var jobEndTime;
      var hourStart;
      var hourEnd;
      var runsPerHour = {};
      var totalRuns = 0;
      var usecsPerHour = 3600000000;
      var now = new Date(Date.clusterNow());
      var dayAgo = new Date(Date.clusterNow());
      var categoryDate = new Date(Date.clusterNow());
      var runsChartDateFormat = DateTimeService.getShortHourMeridianFormat();

      // adjust dayAgo back a day
      dayAgo.setDate(dayAgo.getDate() - 1);

      // categories start one day ago
      categoryDate.setDate(categoryDate.getDate() - 1);

      // but shift forward an hour, as we only want 23 hours ago + current hour
      categoryDate.setHours(categoryDate.getHours() + 1);

      $scope.jobsRunningChart.startDate = DateTimeService.formatDate(dayAgo, $scope.preferredDateFormat);
      $scope.jobsRunningChart.endDate = DateTimeService.formatDate(now, $scope.preferredDateFormat);

      for (var hourIndex = 0; hourIndex < 24; hourIndex++) {
        categories.push(DateTimeService.formatDate(categoryDate, runsChartDateFormat));
        categoryDate.setHours(categoryDate.getHours() + 1);
      }
      $scope.jobsRunningChart.xAxis.categories = categories;

      var runsParams = {
        endTimeUsecs: DateTimeService.dateToUsecs(now),
        excludeTasks: true,
        pruneExclusionSources: true,
        numRuns: 1000,
        startTimeUsecs: DateTimeService.dateToUsecs(dayAgo),
      };

      JobRunsService.getJobRuns(runsParams).then(
        function getJobRunsSuccess(jobs) {
          currentHourMinUsecs = DateTimeService.dateToUsecs(dayAgo);
          currentHourMaxUsecs = DateTimeService.dateToUsecs(dayAgo) + usecsPerHour;
          for (var hour = 0; hour < 24; hour++) {
            runsPerHour[hour] = {
              numActiveRuns: 0,
              numSuccessfulRuns: 0,
              numErrorRuns: 0,
              numWarningRuns: 0,
              numCanceledRuns: 0,
              numSlaViolationRuns: 0,
              startUsecs: currentHourMinUsecs,
              endUsecs: currentHourMaxUsecs,
            };

            // increment our max and min values
            currentHourMinUsecs = currentHourMaxUsecs + 1;
            currentHourMaxUsecs = currentHourMaxUsecs + usecsPerHour;
          }

          for (var jobIndex = 0; jobIndex < jobs.length; jobIndex++) {
            job = jobs[jobIndex];
            if (job.backupJobRuns.protectionRuns) {
              for (var runIndex = 0; runIndex < job.backupJobRuns.protectionRuns.length; runIndex++) {
                jobRun = job.backupJobRuns.protectionRuns[runIndex].backupRun;
                jobStartTime = jobRun.base.startTimeUsecs;
                jobEndTime = jobRun.base.endTimeUsecs;
                jobRun.base.status = JobService.getStatus(jobRun);
                for (var x = 0; x < 24; x++) {
                  hourStart = runsPerHour[x].startUsecs;
                  hourEnd = runsPerHour[x].endUsecs;
                  if (jobStartTime >= hourStart && jobStartTime <= hourEnd) {

                    // count SLA violations separate from job status,
                    // as a job in violation of SLA will also have
                    // a success/error/warning/canceled status
                    if (jobRun.base.slaViolated) {
                      runsPerHour[x].numSlaViolationRuns++;
                    }

                    switch (jobRun.base.status) {
                      case 2.1:
                        runsPerHour[x].numSuccessfulRuns++;
                        break;
                      case 2.2:
                        runsPerHour[x].numErrorRuns++;
                        break;
                      case 2.3:
                        runsPerHour[x].numWarningRuns++;
                        break;
                      case 3:
                        runsPerHour[x].numCanceledRuns++;
                        break;
                      default:
                        runsPerHour[x].numActiveRuns++;
                    }

                    // exit the loop, as the match was found and counted
                    break;
                  }
                }
              }
            }
          }

          for (var y = 0; y < 24; y++) {
            seriesActiveData.push(runsPerHour[y].numActiveRuns);
            seriesSuccessData.push(runsPerHour[y].numSuccessfulRuns);
            seriesWarningData.push(runsPerHour[y].numWarningRuns);
            seriesErrorData.push(runsPerHour[y].numErrorRuns);
            seriesCanceledData.push(runsPerHour[y].numCanceledRuns);
            seriesViolationData.push(runsPerHour[y].numSlaViolationRuns);
          }

          $scope.jobsRunningChart.series[0].data = seriesActiveData;
          $scope.jobsRunningChart.series[1].data = seriesSuccessData;
          $scope.jobsRunningChart.series[2].data = seriesWarningData;
          $scope.jobsRunningChart.series[3].data = seriesErrorData;
          $scope.jobsRunningChart.series[4].data = seriesCanceledData;

          $scope.totals.success = getSumArray(seriesSuccessData);
          $scope.totals.warning = getSumArray(seriesWarningData);
          $scope.totals.error = getSumArray(seriesErrorData);
          $scope.totals.cancel = getSumArray(seriesCanceledData);
          $scope.totals.sla = getSumArray(seriesViolationData);
          $scope.totals.total = getSumArray([
            $scope.totals.success,
            $scope.totals.error,
            $scope.totals.cancel,
            $scope.totals.warning,
          ]);

        },
        evalAJAX.errorMessage
      ).finally(
        function getJobRunsFinally() {
          $scope.jobsRunningChart.loading = false;
        }
      );
    }

    /**
     * Returns the Sum of an Array
     * @param  {Array}
     * @return {Int}
     */
    function getSumArray(array) {
      return array.reduce(
        function combineValues(previousValue, currentValue) {
          return (previousValue + currentValue);
        }
      );
    }

    /**
     * Get Actions Config: returns a configuration object for contextual actions
     * menu based on job status
     *
     * @param    {Object}   job   Job object
     * @return   {Array}          Contextual Menu Configuration Objects
     */
    function getActionsMenuConfig(job) {
      var config = [];

      if (JobActionService.isValidJobAction('start', job)) {
        config.push(
          JobActionService.getJobAction('start', job,
            function startJobCallback() {
              // reload general page data on a timeout to give the job time to
              // actually start. don't rebuild runs per hour chart as it
              // shouldn't have changed
              $timeout(loadData, 2500);
            }
          )
        );
      }

      if (JobActionService.isValidJobAction('failover', job)) {
        config.push(
          JobActionService.getJobAction('failover', job,
            function failoverCallback(jobUid, source) {

              // when failing over a cloud source,
              // go to clone flow instead of recover flow.
              if (ENV_GROUPS.cloudDeploySources.includes(
                  ENV_TYPE_CONVERSION[source.type])) {
                $state.go('clone-vms.clone-options', {
                  sourceEntity: source,
                  entityId: source.id,
                  entityType: source.type,
                  jobId: jobUid.objectId,
                  jobUid: jobUid,
                });

                return;
              }

              // initializes failover flow after user accepts confirmation modal
              $state.go('recover-vm.recover-options', {
                sourceEntity: source,
                jobId: jobUid.objectId,
                jobUid: jobUid,
              });
            }
          )
        );
      }

      if (JobActionService.isValidJobAction('pause', job)) {
        config.push(
          JobActionService.getJobAction('pause', job, loadData)
        );
      }

      if (JobActionService.isValidJobAction('resume', job)) {
        config.push(
          JobActionService.getJobAction('resume', job, loadData)
        );
      }

      if (JobActionService.isValidJobAction('cancel', job)) {
        config.push(
          JobActionService.getJobAction('cancel', job,
            function cancelCallback() {
              // reload the page after a brief timeout
              $timeout(loadData, 2500);
            }
          )
        );
      }

      if (JobActionService.isValidJobAction('edit', job)) {
        config.push(
          JobActionService.getJobAction('edit', job)
        );
      }

      if (JobActionService.isValidJobAction('delete', job)) {
        config.push(
          JobActionService.getJobAction('delete', job, loadData)
        );
      }

      if (JobActionService.isValidJobAction('deleteSnapshots', job)) {
        config.push(
          JobActionService.getJobAction('deleteSnapshots', job, loadData)
        );
      }

      if (JobActionService.isValidJobAction('deactivate', job,
        {policy: job._policy})) {
        config.push(
          JobActionService.getJobAction('deactivate', job, loadData)
        );
      }

      return config;
    }

    /**
     * Keep reloading the data from the API at an interval specified
     */
    if (FEATURE_FLAGS.enableJobsReload) {
      jobsIntervalPromise = $interval(function reLoadData() {
        if (!document.hidden && !$scope.jobStatusChart.loading) {

          // Only reload if the page is visible and no pending loading
          loadData();
        }
      }, JOBS_RELOAD_INTERVAL_MILLISECONDS);
    }

    // Debounce until all the params from url and localStorage
    // have been set to the ui-select inputs to ensure the data is
    // loaded only once on page load
    let customLoadData =
      _.debounce(function customLoadDataDebounce() {
        // Once the page load is finished remove the debounce,
        // so that the loadData is called immediatley on changing a filter
        customLoadData = loadData;
        return loadData();
      }, 200);

    (function handleInitialLoadData() {
      // To maintain the count of number of ui-select thats been initialized
      let counter = 0;

      /**
       * Generates an onInit method for ui-select with c-sync.
       * Execute the loadData once all ui-select have been initiated
       * and their values have been set from c-sync
       */
      $scope.makeUiSelectCSyncOnInit =
        function makeUiSelectCSyncOnInit() {
          counter++;
          return function uiSelectCSyncOnInit() {
            counter--;
            if (!counter) {
              customLoadData();
            }
          };
        };
    })();

    /**
     * When filter changes, get new jobs
     * @param  {Object} nv  new filter object
     * @param  {Object} ov  old filter object
     */
    $scope.$watchCollection(
      'filters',
      function filterJobs(nv, ov) {
        if (nv !== ov) {
          customLoadData();
        }
      }
    );

    /**
     * Updates params for filtering in JobRunsService.getJobSummary
     * @param {string} filterKey
     */
    $scope.selectFilterListOption = function selectFilterListOption(filterKey) {
      $scope.filters.activeJobFilter = filterKey;
    };

    /**
     * Sends resume command for all selected jobs.
     */
    $scope.resumeSelectedJobs = function resumeSelectedJobs() {
      JobService.resumeSelectedJobs($scope.jobs).then(loadData);
    };

    /**
     * Sends pause command for all selected jobs.
     */
    $scope.pauseSelectedJobs = function pauseSelectedJobs() {
      JobService.pauseSelectedJobs($scope.jobs).then(loadData);
    };

    /**
     * Checks if any selected job is in pause state.
     *
     * @return {boolean}  True if any selected job is in paused state.
     */
    $scope.hasPausedJobsSelected = function hasPausedJobsSelected() {
      return _.some($scope.jobs, function someHasPausedJobsSelected(job) {
        return job.isSelected &&
          job.backupJobSummary.jobDescription.isPaused === true;
      });
    };

    /**
     * Checks if any selected job is in resume state.
     *
     * @return {boolean} True if any selected job is in resume state.
     */
    $scope.hasResumedJobsSelected = function hasResumedJobsSelected() {
      return _.some($scope.jobs, function someHasPausedJobsSelected(job) {
        return job.isSelected &&
          !job.backupJobSummary.jobDescription.isPaused;
      });
    };

    /**
     * Function to clear the polling timer when the scope is destroyed
     */
    if (FEATURE_FLAGS.enableJobsReload) {
      $scope.$on('$destroy', function onScopeDestroy() {
        if (jobsIntervalPromise) {
          $interval.cancel(jobsIntervalPromise);
          jobsIntervalPromise = undefined;
        }
      });
    }

    buildRunsChart();
  }

})(angular);
