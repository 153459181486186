import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { RecoverToOptions } from '../../model/recover-to-options';

/**
 * Labels using server.
 */
const locationLabels = {
  new: 'newLocation',
  original: 'originalLocation'
};

/**
 * Labels using view.
 */
const viewLabels = {
  new: 'newView',
  original: 'originalView'
};

/**
 * Labels using location.
 */
const serverLabels = {
  new: 'newServer',
  original: 'originalServer'
};

/**
 * Specifies an interface for Radio Group item for additional recovery items.
 */
export interface RadioGroupItem {
  /**
   * Specifies the untranslated label string.
   */
  label: string;

  /**
   * Specifies the enum within RecoverToOptions.
   */
  value: RecoverToOptions;
}

@Component({
  selector: 'coh-recover-to-radio-group',
  templateUrl: './recover-to-radio-group.component.html',
  styleUrls: ['./recover-to-radio-group.component.scss']
})
export class RecoverToRadioGroupComponent {
  /**
   * If the component should be displayed as read-only.
   */
  @Input() readOnly: boolean;

  /**
   * Form group for display purpose only used by settings-list.
   */
  @Input() formGroup: UntypedFormGroup;

  /**
   * Form control for recover to radio group.
   */
  @Input() control: UntypedFormControl;

  /**
   * Whether to show "Original Location" recover to option.
   */
  @Input() showOriginalLocation = true;

  /**
   * Whether to show "New Location" recover to option.
   */
  @Input() showNewLocation = true;

  /**
   * Whether to disabled "New Location" recover to option.
   */
  @Input() disableNewLocation = false;

  /**
   * Whether to show "New Cohesity View" recover to option.
   */
  @Input() showNewView = false;

  /**
   * Whether to use server labels.
   */
  @Input() showServerLabels = false;

  /**
   * Whether to use server labels.
   */
  @Input() showOriginalView = false;

  /**
   * Whether to use view labels.
   */
  @Input() showViewLabels = false;

  /**
   * Specifies an array of RadioGroupItem which can be used by the parent
   * component to render additional radio options within recovery radio group.
   */
  @Input() customRadioItemList: RadioGroupItem[] = [];

  /**
   * Get the labels using server or location.
   */
  get labels(): any {
    if (this.showServerLabels) {
      return serverLabels;
    } else if (this.showViewLabels) {
      return viewLabels;
    }
    return locationLabels;
  }

  /**
   * Get the target view name.
   */
  get newView(): any {
    return this.formGroup?.value?.newView?.view;
  }

  /**
   * Expose recover to type options for view.
   */
  recoverToOptions = RecoverToOptions;
}
