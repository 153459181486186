import { Component, Input } from '@angular/core';
import { CommonRecoverObjectSnapshotParams } from '@cohesity/api/v2';
import { TranslateService } from '@ngx-translate/core';
import { enumGroupMap } from 'src/app/shared';

/**
 * Recovery summary description for landing page.
 *
 * @example
 * <coh-recovery-description [objects]="recovery.objects"></coh-recovery-description>
 */
@Component({
  selector: 'coh-recovery-description',
  templateUrl: './recovery-description.component.html'
})
export class RecoveryDescriptionComponent {
  /**
   * List of recovery objects.
   */
  private _objects: CommonRecoverObjectSnapshotParams[];

  /**
   * Sets recovery objects.
   */
  @Input() set objects(objects: CommonRecoverObjectSnapshotParams[]) {
    this._objects = objects;
    this.updateUnitsText();
  }

  /**
   * Returns recovery objects.
   */
  get objects(): CommonRecoverObjectSnapshotParams[] {
    return this._objects;
  }

  /**
   * Enum environment and group text for objects.
   */
  unitsText: string;

  /**
   * Constructor.
   */
  constructor(
    private translate: TranslateService
  ) {}

  /**
   * Updates unitsText for display.
   */
  updateUnitsText() {
    const [{
      objectInfo: {
        environment = null
      } = {}
    } = {}] = this.objects || [];
    const group = enumGroupMap[environment];

    if (environment && group) {
      // TODO(ang): Add checking of environment group and display different text
      this.unitsText = [
        this.translate.instant('enum.environment.' + environment),
        this.translate.instant('enum.envGroup.' + group)
      ].join(' ');
    } else {
      this.unitsText = this.translate.instant('objects');
    }
  }
}
