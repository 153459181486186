import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Notifications } from '@cohesity/api/v1';
import { interval, merge } from 'rxjs';
import { filter, startWith, switchMap } from 'rxjs/operators';
import { AutoDestroyable } from '@cohesity/utils';
import { AjsUpgradeService } from 'src/app/core/services/ajs-upgrade.service';
import { NotificationService } from '../../services';
import { IrisContextService, isLoggedIn } from '@cohesity/iris-core';

const pulsePollingInterval = 5 * 60 * 1000;

@Component({
  selector: 'coh-notifications-indicator',
  templateUrl: './notifications-indicator.component.html',
  styleUrls: ['./notifications-indicator.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationsIndicatorComponent extends AutoDestroyable implements OnInit {
  /**
   * NOTE: Using ajs service as swagger documentation for notification PATCH
   * endpoints is missing params information, making generated services unusable.
   * Upgraded AngularJS Notifcation Service
   */
  ajsNotifService: any;

  /**
   * Notification details as returned from API/service
   */
  notificationsInfo: Notifications;

  constructor(
    ajsUpgrade: AjsUpgradeService,
    private notifsService: NotificationService,
    private irisContext: IrisContextService) {
    super();
    this.ajsNotifService = ajsUpgrade.get('NotificationService');
  }

  /**
   * Initialization function
   */
  ngOnInit() {
    merge(interval(pulsePollingInterval), this.notifsService.forceFetchNotifMeta$)
      .pipe(
        startWith(0),
        filter(() => isLoggedIn(this.irisContext.irisContext)),
        switchMap(() => this.ajsNotifService.getNotificationMetaInfo()),
        this.untilDestroy()
      )
      .subscribe(notifInfo => (this.notificationsInfo = notifInfo));
  }
}
