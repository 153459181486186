import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NisProvider } from '@cohesity/api/v2';
import { AjaxHandlerService } from '@cohesity/utils';
import { Controls, NgxSubFormComponent } from 'ngx-sub-form';
import { finalize } from 'rxjs/operators';

import { NISService } from '../nis.service';
import { ModifyNisFormModel } from './modify-nis-form.model';


export interface ModifyNISDialogParams {
  isEditMode: boolean;
  nis?: NisProvider;
  showBackArrow: boolean;
}

@Component({
  selector: 'coh-modify-nis-dialog',
  templateUrl: './modify-nis-dialog.component.html',
  styleUrls: ['./modify-nis-dialog.component.scss'],
})
export class ModifyNisDialogComponent extends NgxSubFormComponent<ModifyNisFormModel> implements OnInit {
  /**
   * Material chip keyboard events which adding the new chip.
   */
  readonly separatorKeysCodes = [ENTER, COMMA, SPACE];

  /**
   * Whether is modifying form or not.
   */
  saving = false;

  getDefaultValues(): Partial<ModifyNisFormModel> {
    return {
      nisSlaveServers: []
    };
  }

  /**
   * Get the form controls needed for the sub form.
   *
   * @returns   The form controls object.
   */
  getFormControls(): Controls<ModifyNisFormModel> {
    return {
      nisDomainName: new UntypedFormControl(null, Validators.required),
      nisMasterServer: new UntypedFormControl(null, Validators.required),
      nisSlaveServers: new UntypedFormControl(null),
    };
  }

  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private dialogRef: MatDialogRef<ModifyNisDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogParams: ModifyNISDialogParams,
    private nisService: NISService,
  ) {
    super();
  }

  ngOnInit() {
    if (this.dialogParams.isEditMode) {
      this.initForm();
    }
  }

  /**
   * When is under edit mode, initlize the form with current NIS object.
   */
  initForm() {
    const {domain, masterServerHostname, slaveServers} = this.dialogParams.nis;
    const currentNIS: ModifyNisFormModel = {
      nisDomainName: domain,
      nisMasterServer: masterServerHostname,
      nisSlaveServers: slaveServers || [],
    };
    this.writeValue(currentNIS);
    this.formGroupControls.nisDomainName.disable();
  }

  /**
   * Helper function to add new slave server.
   */
  addSlaveServer(event: MatChipInputEvent) {
    const input = event.input;
    const value = (event.value || '').trim();

    // Add slave server
    if (value) {
      this.formGroupControls.nisSlaveServers.value.push(value.trim());
    }

    // Clear the input value
    if (input) {
      input.value = '';
    }
  }

  /**
   * Helper function to remove a slave server.
   */
  removeSlaveServer(index: number) {
    this.formGroupControls.nisSlaveServers.value.splice(index, 1);
  }

  /**
   * Create or edit NIS.
   *
   * @param   isEditMode   Whether is edit mode or create mode.
   */
  modifyNIS(isEditMode: boolean) {
    this.saving = true;
    const { nisDomainName, nisMasterServer, nisSlaveServers} = this.formGroupValues;
    const param: NisProvider = {
      domain: nisDomainName,
      masterServerHostname: nisMasterServer,
      slaveServers: nisSlaveServers,
    };
    const apiRequest$ = isEditMode ? this.nisService.editNisProvider(param) : this.nisService.createNisProvider(param);

    // make the api call.
    apiRequest$
      .pipe(finalize(() => this.saving = false))
      .subscribe(
        (nisProvider: NisProvider) => this.dialogRef.close(nisProvider),
        error => this.ajaxHandlerService.handler(error));
  }

  /**
   * Close dialog.
   */
  closeDialog() {
    this.dialogRef.close();
  }

}
