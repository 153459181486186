import { Pipe, PipeTransform } from '@angular/core';

import { NaLabelPipeService, NaLabelPipeTransformFn } from './na-label-pipe.service';

@Pipe({ name: 'naLabel' })
export class NaLabelPipe implements PipeTransform {
  constructor(private naLabelPipeService: NaLabelPipeService) { }

  transform: NaLabelPipeTransformFn = (...args) => this.naLabelPipeService.transform(...args);
}
