/**
 * This is the main entry point for the application. It loads the angularjs
 * files and then bootstraps the angular app.
 */
import { enableProdMode, NgZone } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { UIRouter, UrlService } from '@uirouter/core';

// And import our AngularJS module
import './third-party-includes';
import './ajs-require';

import { AppModule } from './app/app.module';
import { stateGuards } from './app/core/state/guards';
import { StateGuardService } from './app/core/state/state-guard.service';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(platformRef => {
    // In app.js, ui-router is uses to deferIntercept to wait to start listening for
    // route changes. After the app has finished bootstrapping, we can go ahead and
    // finish initializing it.
    function startUiRouter() {
      const urlService: UrlService = platformRef.injector.get(UIRouter).urlService;

      // Before starting the router, initialize the state guards. These are responsible for
      // bootstrapping the application in the proper order.
      const stateGuardService: StateGuardService = platformRef.injector.get(StateGuardService);
      stateGuardService.registerStateGuards(stateGuards);

      urlService.listen();
      urlService.sync();
    }

    platformRef.injector.get<NgZone>(NgZone).run(() => {
      startUiRouter();
    });
  })
  .catch(err => console.log(err));
