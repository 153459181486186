<mat-form-field [hideRequiredMarker]="true">
  <mat-label>{{label}}</mat-label>
  <input
    type="time"
    [placeholder]="timeFormat"
    step="{{showSeconds ? 1 : 60}}"
    required
    matInput
    [formControl]="formGroupControls.value"
    (change)="onTimeChange($event)">
</mat-form-field>
