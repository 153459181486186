import { Component, Input, Output } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { Subject } from 'rxjs';

import { SnapshotSelectorView } from '../../models/snapshot-selector-view';

@Component({
  selector: 'coh-snapshot-selector-view-switcher',
  templateUrl: './view-switcher.component.html',
  styleUrls: ['./view-switcher.component.scss'],
})
export class SnapshotSelectorViewSwitcherComponent {
  /**
   * The initial view to select.
   */
  @Input() view: SnapshotSelectorView = 'list';

  /**
   * Default View Switcher's view to render on initial dialog render.
   */
  @Input() defaultViewSwitcherView: SnapshotSelectorView;

  /**
   * This event is emitted whenever there is a change in the current view.
   */
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change = new Subject<SnapshotSelectorView>();

  /**
   * Serves as a callback for the `change` event of button toggle.
   */
  onToggleGroupChange(event: MatButtonToggleChange) {
    this.view = event.value;
    this.change.next(this.view);
  }
}
