import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Input, ViewRef } from '@angular/core';

/**
 * Directive to set focus on an element when it finishes initializing the view.
 *
 * @example
 *  <input *ngIf="showInput" matInput name="input" cohAutoFocus>
 */
@Directive({
  selector: '[cohAutoFocus]'
})
export class AutoFocusDirective implements AfterViewInit {
  /**
   * Override directive to not autofocus the provided element.
   */
  @Input('cohAutoFocus') enableAutoFocus = true;

  private readonly nativeElement?: HTMLElement;

  constructor(
    element: ElementRef<HTMLElement>,
    private cdr: ChangeDetectorRef,
  ) {
    this.nativeElement = element.nativeElement;
  }

  ngAfterViewInit() {
    if (!this.enableAutoFocus) {
      return;
    }

    setTimeout(() => {
      if (this.nativeElement && !!this.cdr && !(this.cdr as ViewRef).destroyed) {
        this.nativeElement.focus();
        this.cdr.detectChanges();
      }
    });
  }
}
