import { ProtectionSourceNode } from '@cohesity/api/v1';
import {
  DataTreeFilter,
  DataTreeTransformer,
} from '@cohesity/helix';
import {
  SourceTreeFilters,
  ViewFilterOption,
  ViewFilterType,
} from '@cohesity/iris-source-tree';

import { IbmFlashSystemSourceDataNode } from './ibmflashsystem-source-data-node';
import { IbmProtectionTypes } from './ibmflashsystem.constants';

/**
 * Provide view filters for pure sources. This includes options to filter for
 * physical, or flat hierarchies.
 */
export class IbmFlashSystemViewFilters {
  /**
   * These are the default view filters.
   */
  defaultViewFilters: ViewFilterOption[];

  constructor(
    private filters: SourceTreeFilters<IbmFlashSystemSourceDataNode>,
    private dataTransformer: DataTreeTransformer<ProtectionSourceNode>,
  ) {
    this.defaultViewFilters = [
      {
        id: ViewFilterType.Physical,
        tooltip: 'sourceTreePub.tooltips.ibmView',
        filter: this.filterIbmFlashSystemProtectionGroups,
        icon: 'helix:hierarchy-physical',
      },
      {
        id: ViewFilterType.Flat,
        tooltip: 'volumeView',
        filter: this.filterIndependentVolumes,
        icon: 'helix:hierarchy-flat',
      },
    ];

    this.filters.setViewFilters(this.defaultViewFilters);
  }

  /**
   * Filter callback function to show the physical tree hierarchy of a pure cluster.
   */
  filterIbmFlashSystemProtectionGroups: DataTreeFilter<any> = (nodes: IbmFlashSystemSourceDataNode[]) => {
    if (!nodes?.length) {
      return [];
    }
    const childNodeIDs = new Set<number | string>();
    const rootNodes: IbmFlashSystemSourceDataNode[] = [];

    // get the id of all the children of all the ppgNodes.
    // maintain a list of ppgNodes.
    nodes.forEach(node => {
      if ((node.envSource as any).type === IbmProtectionTypes.kVolumeGroup &&
        Boolean(node.childIds.length)) {
        for (const childId of node.childIds) {
          childNodeIDs.add(childId);
        }
        rootNodes.push(node);
      }
    });
    const childNodesMap = new Map<string | number, IbmFlashSystemSourceDataNode>();

    // create a mapping of a child (volume) id and itself.
    nodes.forEach(node => {
      if (childNodeIDs.has(node.id)) {
        childNodesMap.set(node.id, node);
      }
    });

    // push the storage array node.
    const filteredView = [this.dataTransformer.transformData(nodes[0]?.data, 0)];

    // Filtered view expects all nodes to be run through the transformData function and that all
    // children of a ppgNode, must immediately succeed it in the list.
    rootNodes.forEach(ibmNode => {
      filteredView.push(this.dataTransformer.transformData(ibmNode?.data, 1));
      for (const childId of ibmNode.childIds) {
        if (childNodesMap.get(childId)) {
          filteredView.push(this.dataTransformer.transformData(
            childNodesMap.get(childId)?.data, 2));
        }
      }
    });

    return filteredView;
  };

  /**
   * Filter callback to show a flat list of volumes in a ibm cluster
   */
  filterIndependentVolumes: DataTreeFilter<any> = (nodes: IbmFlashSystemSourceDataNode[]) => {
    const seenNodes = new Set<number | string>();
    return nodes
      .filter(node => {
        const matched = (node.envSource as any).type === IbmProtectionTypes.kVolume;
        if (!matched || seenNodes.has(node.id)) {
          return false;
        }
        seenNodes.add(node.id);
        return true;
      })
      .map(node => this.dataTransformer.transformData(node.data, 0))
      .sort((a: IbmFlashSystemSourceDataNode, b: IbmFlashSystemSourceDataNode) => a.name.localeCompare(b.name));
  };
}
