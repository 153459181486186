import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

import { DataIdModule } from '../data-id';
import { IconModule } from '../icon';
import { CarouselIndicatorsComponent } from './carousel-indicators/carousel-indicators.component';
import { CarouselItemComponent } from './carousel-item/carousel-item.component';
import { CarouselService } from './carousel.service';
import { CarouselComponent } from './carousel/carousel.component';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';

@NgModule({
  imports: [
    CommonModule,
    DataIdModule,
    IconModule,
    MatButtonModule,
    MatRadioModule,
    CarouselComponent,
    CarouselItemComponent,
    CarouselIndicatorsComponent
  ],
  providers: [
    CarouselService
  ],
  exports: [
    CarouselComponent,
    CarouselItemComponent,
  ]
})
export class CarouselModule { }
