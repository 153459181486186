import { Injector, reflectComponentType, Type } from '@angular/core';
import { createCustomElement } from '@angular/elements';

/**
 * Define the provided web components.
 *
 * @param injector The injector service instance.
 * @param components The list of components defined as a web components.
 */
export const defineWebComponents = (injector: Injector, components: Type<any>[]) => {
  components.forEach(component => {
    const metadata = reflectComponentType(component);
    const webComponentName = `web-${metadata.selector}`;

    if (customElements.get(webComponentName)) {
      console.error(`
        Helix defineWebComponents(): The web component "${webComponentName}" has already been registered
        with class ${customElements.get(webComponentName)}
        hence it can't be re-registerd with new class ${component}
      `);
    } else {
      const customElement = createCustomElement(component, { injector });
      customElements.define(webComponentName, customElement);
    }
  });
};
