<mat-table #headerTable>
  <mat-header-row *matHeaderRowDef="columnNames"></mat-header-row>
</mat-table>
<ng-content></ng-content>
<cdk-virtual-scroll-viewport
  *ngIf="data && treeSelection && treeControl.dataNodes"
  itemSize="48"
  minBufferPx="1000"
  maxBufferPx="2000"
>
  <ng-container *cdkVirtualFor="let item of data.currentView$ | async"></ng-container>
  <ng-content select="mat-table"></ng-content>
</cdk-virtual-scroll-viewport>
