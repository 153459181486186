import {
  AfterViewInit,
  Component,
  EventEmitter,
  Output
} from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl } from '@angular/forms';
import { AutoDestroyable } from '@cohesity/utils';
import { of } from 'rxjs';

@Component({
  selector: 'coh-settings-import-external-certificate-interface',
  templateUrl: './settings-import-external-certificate-interface.component.html',
  styleUrls: ['./settings-import-external-certificate-interface.component.scss'],
})
export class SettingsImportExternalCertificateInterfaceComponent extends AutoDestroyable implements AfterViewInit {

  @Output()
  interfaceDataFile:
    EventEmitter<any> = new EventEmitter<any>();

  formGroup = new FormGroup({
    certificateUsage: new UntypedFormControl(null),
    importAsTrusted: new FormControl(false),
  });

  /**
   * List of Certificate usage
   */
  certificateUsageList: string[] = [];

  constructor() {
    super();
  }

  /**
   * AfterViewInit lifecycle hook.
   */
  ngAfterViewInit () {
    this.getCertificateUsageList().pipe(
      this.untilDestroy()
    ).subscribe((res) => {
      this.certificateUsageList = res;
    });
  }

  getCertificateUsageList() {
    // TODO - To be implemented on API Integration
    return of(['Cluster certificate', 'Ephemeral agent']);
  }

  /**
   * Send a object with content, certificateUsage and importeAsTrusted
   */
  handleInterfaceData() {
    this.interfaceDataFile.emit(
      {
        certificateUsage: this.formGroup.controls.certificateUsage.value,
        importAsTrusted: this.formGroup.controls.importAsTrusted.value,
      }
    );
  }

  /**
   * Remove certificate usage at index in certificate usage list.
   *
   * @param   index index of certificate usage in certificate usage list.
   */
  removeCertificateUsage(index: number) {
      this.certificateUsageList.splice(index, 1);
      this.formGroup.patchValue({certificateUsage: this.certificateUsageList});
      this.formGroup.controls.certificateUsage.markAsDirty();
  }
}
