<div>
  <div>
    <h1 mat-dialog-title class="banner-title">
      {{ 'sasUrlInfoBannerTitle' | translate }}
    </h1>
  </div>
  <div [formGroup]="privateEndPointFormGroup">
    <cog-banner class="margin-top banner-text" status="info">
      <span>{{ 'sasUrlInfoBannerText' | translate }}</span>
    </cog-banner>
    <h3 class="margin-top sub-title">
      {{ 'sasUrlInfoBannerDesc' | translate }}
    </h3>
    <ng-container formArrayName="privateEndpointFields">
      <ng-container *ngFor="let privateEndpointField of privateEndpointFields.controls; let i = index">
        <div class="rows-nowrap">
          <coh-app-private-endpoints-dialog-row
            [privateEndpointField]="getPrivateEndpointField(i)"
            [protectionSource$]="protectionSource$"
            [restrictedVNETs]="data.restrictedVNets$ | async">
          </coh-app-private-endpoints-dialog-row>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div mat-dialog-actions align="end">
    <button cohCancelButton mat-button mat-dialog-close cogdataid="cancel" (click)="cancel()">
      {{ 'cancel' | translate }}
    </button>
    <button
      class="margin-left"
      cohSubmitButton
      color="primary"
      mat-flat-button
      type="button"
      cogdataid="save"
      (click)="save()">
      {{ 'save' | translate }}
    </button>
  </div>
</div>
