// MODULE: Job Run Details

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.jobRunDetails', ['C.policyService', 'C.dbRestore'])
    .config(ConfigFn)
    .controller('jobRunDetailsParentController', jobRunDetailsParentControllerFn)
    .controller('ModifyLegalHoldController', ModifyLegalHoldControllerFn);

  function ConfigFn($stateProvider, $urlRouterProvider) {
    var viewAccess = 'PROTECTION_VIEW';

    $stateProvider
      .state('job-run-details', {
        url: '/protection/job/{id}/run/{instanceId}/{startTimeUsecs}/',
        help: 'protection_job_details_vms',
        title: 'Job Run Details',
        canAccess: viewAccess,
        params: {
          id: { type: 'string' },
          instanceId: { type: 'string' },
          startTimeUsecs: { type: 'string' },
        },
        parentState: 'jobs',
        views: {
          '': {
            templateUrl: 'app/views/page-layouts/ls.html'
          },
          'col-l@job-run-details': {
            templateUrl: 'app/protection/jobs/details/run/run.html',
            controller: 'jobRunDetailsParentController'
          }
        }
      })
      .state('job-run-details.protection', {
        url: '^/protection/job/{id}/run/{instanceId}/{startTimeUsecs}/protection',
        help: 'protection_job_details_vms',
        title: 'Job Run Details',
        canAccess: viewAccess,
        params: {
          id: { type: 'string' },
          instanceId: { type: 'string' },
          startTimeUsecs: { type: 'string' },
        },
        parentState: 'jobs',
        views: {
          'content@job-run-details': {
            templateUrl: 'app/protection/jobs/details/run/run-protection.html',
            controller: 'jobRunDetailsProtectionController'
          }
        }
      })
      .state('job-run-details.file-stubbing-runs', {
        url: '^/datamigration/job/{id}/run/{instanceId}/{startTimeUsecs}',
        params: {
          id: { type: 'string' },
          instanceId: { type: 'string' },
          startTimeUsecs: { type: 'string' },
          fileStubbing: true,
        },
        help: 'platform_datamigration',
        title: 'Job Run Details',
        canAccess: viewAccess,
        parentState: 'dataMigration',
        views: {
          'content@job-run-details': {
            templateUrl: 'app/protection/jobs/details/run/run-protection.html',
            controller: 'jobRunDetailsProtectionController'
          }
        }
      })
      .state('job-run-details.replication', {
        url: '^/protection/job/{id}/run/{instanceId}/{startTimeUsecs}/replication',
        params: {
          id: { type: 'string' },
          instanceId: { type: 'string' },
          startTimeUsecs: { type: 'string' },

          // Optionally, a taskUid can be provided if a particular replication
          // task should be shown at the top of the list/ngRepeat.
          taskUid: undefined,
        },
        help: 'protection_job_details_vms',
        title: 'Job Run Details',
        canAccess: viewAccess,
        parentState: 'jobs',
        views: {
          'content@job-run-details': {
            templateUrl: 'app/protection/jobs/details/run/run-replication.html',
            controller: 'jobRunDetailsReplicationController'
          }
        }
      })
      .state('job-run-details.clouddeploy', {
        url: '^/protection/job/{id}/run/{instanceId}/{startTimeUsecs}/clouddeploy',
        help: 'protection_job_details_vms',
        title: 'Job Run Details',
        canAccess: viewAccess,
        params: {
          id: { type: 'string' },
          instanceId: { type: 'string' },
          startTimeUsecs: { type: 'string' },
        },
        parentState: 'jobs',
        views: {
          'content@job-run-details': {
            templateUrl: 'app/protection/jobs/details/run/run-cloud-deploy.html',
            controller: 'jobRunDetailsCloudDeployController'
          }
        }
      })
      .state('job-run-details.archive', {
        url: '^/protection/job/{id}/run/{instanceId}/{startTimeUsecs}/archive',
        help: 'protection_job_details_vms',
        title: 'Job Run Details',
        canAccess: viewAccess,
        params: {
          id: { type: 'string' },
          instanceId: { type: 'string' },
          startTimeUsecs: { type: 'string' },
        },
        parentState: 'jobs',
        views: {
          'content@job-run-details': {
            templateUrl: 'app/protection/jobs/details/run/run-archive.html',
            controller: 'jobRunDetailsArchiveController'
          }
        }
      })
      .state('job-run-details.tape', {
        url: '^/protection/job/{id}/run/{instanceId}/{startTimeUsecs}/tape',
        help: 'protection_job_details_vms',
        title: 'Job Run Details',
        canAccess: viewAccess,
        params: {
          id: { type: 'string' },
          instanceId: { type: 'string' },
          startTimeUsecs: { type: 'string' },
        },
        parentState: 'jobs',
        views: {
          'content@job-run-details': {
            templateUrl: 'app/protection/jobs/details/run/run-archive.html',
            controller: 'jobRunDetailsArchiveController'
          }
        }
      })
      .state('job-run-details.indexing', {
        url: '^/protection/job/{id}/run/{instanceId}/{startTimeUsecs}/indexing',
        help: 'protection_job_details_vms',
        title: 'Job Run Details',
        canAccess: viewAccess,
        params: {
          id: { type: 'string' },
          instanceId: { type: 'string' },
          startTimeUsecs: { type: 'string' },
        },
        parentState: 'jobs',
        views: {
          'content@job-run-details': {
            templateUrl: 'app/protection/jobs/details/run/run-indexing.html',
            controller: 'jobRunDetailsIndexingController'
          }
        }
      });
  }

  function jobRunDetailsParentControllerFn(
    $rootScope, $scope, $state, $interpolate, $timeout, evalAJAX, $filter, $q,
    PolicyService, JobRunsService, JobActionFormatter, cModal, cMessage,
    SlideModalService, ENUM_JOB_RUN_TYPE, ENV_GROUPS, ENUM_ARCHIVAL_TARGET,
    ENV_TYPE_CONVERSION, FEATURE_FLAGS, StateManagementService, SearchService,
    PubJobService) {

    $scope.ENUM_JOB_RUN_TYPE = ENUM_JOB_RUN_TYPE;
    $scope.ENV_GROUPS = ENV_GROUPS;

    /**
     * Text translations for Job Run Details
     * @type {Object}
     */
    $scope.text = $rootScope.text.protectionJobsDetailsRun_run;

    $scope.jobRun = {};
    $scope.jobDescription = {};
    $scope.totalVms = false;
    $scope.dataReady = false;

    /**
     * Page Back button URL state.
     */
    $scope.goToProtectionJobsLabel = FEATURE_FLAGS.ngProtectionGroup ?
      'goToProtectionGroup' :
      'protectionJobsDetailsRunRunProtection.goToProtectionJobs';

    /**
     * Page Back button label.
     */
    $scope.jobDetailState = FEATURE_FLAGS.ngProtectionGroup ?
      'protection-group.group.details' : 'job-details';

    $scope.shared = {
      /**
       * Config Map of snapshots that are selected to perform actions on
       */
      selectedObjectsMap: {},

      /**
       * Is Select All checked?
       * @type {Boolean}
       */
      selectAllCheckbox: false,

      /**
       * Do runs with snapshots exist?
       * @type {Boolean}
       */
      hasDeleteableObjects: false,
    };

    //total summary data for file stubbing job
    $scope.totals = {
      total: 0,
      numComplete: 0,
      numError: 0,
      numWarning: 0,
      numCancel: 0,
      numMigratedInProgress: 0,
      numDataMigrated: 0,
    },

    //only for file stubbing job
    $scope.isFileStubbingJob = !!$state.params.fileStubbing;

    /**
     * Initialize this Controller manages state variables kicks off data
     * function.
     *
     * @method   activate
     */
    function activate() {
      // ensure we have the correct params or redirect
      if (!$state.params || !$state.params.id || !$state.params.instanceId || !$state.params.startTimeUsecs) {
        StateManagementService.goToPreviousState();
      }

      /**
       * Tab Configuration
       * @type {Array}
       */
      $scope.cTabConfig = [{
        name: $scope.text.backupTask,
        value: $scope.isFileStubbingJob ? 'job-run-details.file-stubbing-runs' :
          'job-run-details.protection',
      }, {
        name: $scope.text.replicationTask,
        value: 'job-run-details.replication',
        hidden: true,
      }, {
        name: $scope.text.cloudTask,
        value: 'job-run-details.archive',
        hidden: true,
      }, {
        name: $scope.text.tapeTask,
        value: 'job-run-details.tape',
        hidden: true,
      }, {
        name: $scope.text.cloudDeployTask,
        value: 'job-run-details.clouddeploy',
        hidden: true,
      }, {
        name: $scope.text.indexingTask,
        value: 'job-run-details.indexing',
        hidden: true,
      }];

      // url transition is taken care inside a watcher below so to prevent state
      // change from cTabs providing (noState: true) to all tab config.
      $scope.cTabConfig.forEach(function eachTab(tab) {
        tab.noState = true;
      });

      // Request Job Run data
      getData();

      if ($state.current.name === 'job-run-details') {
        $scope.currentTab = 'job-run-details.protection';
        $state.go('job-run-details.protection', $state.params, {
          reload: false
        });
      } else {
        $scope.currentTab = $state.current.name;
      }
    }

    /**
     * Request data from API and store it in $scope.jobRun.
     *
     * @method   getData
     */
    function getData() {
      var promises = [];
      promises.push(PubJobService.getJobs({
        ids: [+$state.params.id],
        onlyReturnBasicSummary: true,
        includeLastRunAndStats: true,
      }));
      promises.push(JobRunsService.getJobRuns({
        id: +$state.params.id,
        exactMatchStartTimeUsecs: $state.params.startTimeUsecs || undefined,
        onlyReturnDataMigrationJobs: $scope.isFileStubbingJob,
        // The following parameter is used to reduce the response payload
        //  to contain only data relevant for object runs page.
        useCaseType: 'objectRunsPage',
      }));

      $scope.jobRunDataReady = false;

      $q.allSettled(promises).then(
        function getJobRunsSuccess([pubJobsResp, jobsResp]) {
          $scope.lastRun = pubJobsResp.resp[0].lastRun;

          var jobs = jobsResp.resp;
          var job;

          if (!jobs.length) {
            // No run info was returned
            // show message and redirect user back to jobs landing
            cMessage.error({
              textKey: 'jobDetails.unknownJobRun.text',
              persist: true,
            });
            $state.go('jobs');
            return;
          }

          // based on provided params, there will
          // only be a single job returned
          job = jobs[0];

          /**
           * Assign job data to $scope.job
           * @type {Object}
           */
          $scope.job = job.backupJobRuns;

          /**
           * Assign protection run data to $scope.jobRun
           * @type {Object}
           */
          $scope.jobRun = job.backupJobRuns.protectionRuns[0];

          /**
           * Assign Job Description data to $scope.jobDescription
           * @type {Object}
           */
          $scope.jobDescription = job.backupJobRuns.jobDescription;

          // Reveal CTabs
          if ($scope.jobRun._hasReplicationTask) {
            $scope.cTabConfig[1].hidden = false;
          }
          if ($scope.jobRun._hasCloudTask) {
            // Enable the Archive Tab
            $scope.cTabConfig[2].hidden = false;
          }
          if ($scope.jobRun._hasTapeTask) {
            // Enable the Tape Tab
            $scope.cTabConfig[3].hidden = false;
          }
          if ($scope.jobRun._hasCloudDeployTask) {
            // Enable the CloudSpin Tab
            $scope.cTabConfig[4].hidden = false;
          }
          if ($scope.jobRun._hasIndexingTask) {
            // Enable the Indexing Tab
            $scope.cTabConfig[5].hidden = false;
          }

          $scope.backButtonUrlParams = getBackButtonUrlParams();

          // Get Job Run Policy Name
          PolicyService.getPolicy($scope.jobDescription.policyId).then(
            function getPolicySuccess(policy) {
              $scope.policy = policy;
            }
          );

          // Add Copy Task info
          $scope.jobRun.backupRun._copyTasks = JobRunsService.getCopyTasks(
            $scope.jobRun,
            $scope.jobDescription.jobUid,
            $scope.jobDescription.isActive
          );

           // Build Job Run Actions Menu
          getJobRunActions($scope.job.jobDescription, $scope.jobRun.backupRun);

          // Build file stubbing task run glance bar
          if ($scope.isFileStubbingJob) {
            _buildFileStubbingGlanceBar(jobs[0]);
          }

          // broadcast that run data is ready
          // Sub controllers will kick off corresponding operations
          $scope.jobRunDataReady = true;
          $scope.$broadcast('jobRunDataReady');

        },
        function getJobRunsError() {
          // no results returned, likely an invalid job id
          // show message and redirect user back to jobs listing
          cMessage.error({
            textKey: 'jobDetails.unknownJobId.text',
            persist: true,
          });
          $state.go('jobs');
        }
      ).finally(
        function afterGetJobRuns() {
          $scope.dataReady = true;
        }
      );
    }

    /**
     * Returns URL params to go back to Job/Group details page.
     *
     * @method   _buildFileStubbingGlanceBar
     * @returns {object}    url param object
     */
    function getBackButtonUrlParams() {
      var jobUid = $scope.jobDescription.jobUid;

      if (!jobUid) {
        return null;
      }

      return FEATURE_FLAGS.ngProtectionGroup && !$scope.isFileStubbingJob ? {
        groupId: jobUid.clusterId + ':' + jobUid.clusterIncarnationId +
          ':' + jobUid.objectId
      } : {
          id: jobUid.objectId
        };
    }

    /**
     * Build file stubbing task run glance bar.
     *
     * @method   _buildFileStubbingGlanceBar
     * @param    {Object}   job   Job details.
     */
    function _buildFileStubbingGlanceBar(job) {
      var runs = {
        total: 0,
        numComplete: 0,
        numError: 0,
        numWarning: 0,
        numCancel: 0,
        numMigratedInProgress: 0,
        numDataMigrated: 0,
      };

      angular.forEach(job.backupJobRuns.protectionRuns, function(jobRun) {
        if (jobRun.backupRun.base.hasOwnProperty('totalBytesTiered')) {
          runs.numDataMigrated += jobRun.backupRun.base.totalBytesTiered;
        } else {
          runs.numDataMigrated += jobRun.backupRun.base.totalBytesReadFromSource;
        }

        switch (jobRun.backupRun.base._status) {
        case 2.1:
          runs.numComplete++;
          break;
        case 2.2:
          runs.numError++;
          break;
        case 2.3:
          runs.numWarning++;
          break;
        case 3.1:
          runs.numCancel++;
          break;
        default:
          runs.numActive++;
          break;
        }
      });

      runs.total = runs.numComplete +
        runs.numError +
        runs.numWarning +
        runs.numCancel;

      $scope.totals = runs;

    }

    /**
     * Gets the job runs actions.
     *
     * @method   getJobRunsActions
     */
    function getJobRunActions(job, run) {
      $scope.actions = [];

      // edit job run is not allowed for the user who is not from the
      // organization owning the job.
      if (!job._isJobOwner) {
        return;
      }

      if (JobRunsService.isValidJobRunAction('edit', run,
        $scope.job.jobDescription, $scope.jobRun.copyRun)) {
          $scope.actions.push(
            JobRunsService.getJobRunAction('edit', job, run)
          );
      }
    }

    /**
     * Generate context menu action links for each job run's tasks (primarily
     * based on the protected object's entity type).
     *
     * @method    generateActionItems
     * @param     {object}   run       Job run object
     * @param     {object}   runTask   A Job Run's finished(?) task object.
     * @returns   {array}    The list of actions. Can be empty.
     */
    $scope.generateActionItems = function generateActionItems(run, runTask) {
      var display;
      var state;

      var items = [];
      var jobId = $scope.job.jobDescription.jobId;
      var jobUid = $scope.job.jobDescription.jobUid;
      var sqlBackupJobParams =
        $scope.jobDescription.envBackupParams.sqlBackupJobParams;
      var isNativeSnapshotJob =
        ENV_GROUPS.nativeSnapshotTypes.includes(run.backupRun.base.type);
      var areSnapshotsRemotelyManaged = ENV_GROUPS
        .remoteSnapshotManagementSupported.includes(run.backupRun.base.type);
      var taskId = runTask._sourceObject.id;

      // Ref ENUM_BACKUP_JOB_STATUS
      var completedTaskStatuses = [2, 2.1, 2.3];

      // If there's no task object or the task hasn't completed, exit early.
      if (!runTask || !completedTaskStatuses.includes(runTask._status) ||
        // In case of a remote job, if copy run (replication has failed) we
        // should not show context menu actions
        ($scope.jobDescription._isRemoteJob && run.copyRun.error) ||

        // Exchange type does not have context menu actions.
        run.backupRun.base.type === ENV_TYPE_CONVERSION.kExchange) {
        return items;
      }

      // Legal Hold Actions
      if ($rootScope.user.privs.DATA_SECURITY &&
        !$scope.jobRun.backupRun._legalHoldEnabled &&
        !_.get(run.backupRun, '_entitiesOnLegalHold', []).includes(taskId)) {
        items.push({
          icon: 'icn-add-legalhold',
          translateKey: 'addLegalHold',
          action: $scope.generateLegalHoldPayload.bind(null, true, taskId),
        });
      } else if ($rootScope.user.privs.DATA_SECURITY &&
        ($scope.jobRun.backupRun._legalHoldEnabled ||
          _.get(run.backupRun, '_entitiesOnLegalHold', []).includes(taskId))) {
        items.push({
          icon: 'icn-remove-legalhold',
          translateKey: 'removeLegalHold',
          action: $scope.generateLegalHoldPayload.bind(null, false, taskId),
        });
      }

      switch (true) {
        // View
        case runTask._normalizedEntity.entityKey === 'viewEntity' &&
          $rootScope.user.privs.CLONE_MODIFY:
          items.push({
            icon: 'icn-clone',
            display: $scope.text.cloneView,
            state: 'clone-view.options',
            // Protected View (spec snapshot): view, protected=true,
            // snapshotUsecs, jobId. See _clone-view.js for latest stateParams
            // for abbreviated protected clone view flow.
            stateParams: {
              jobId: jobId,
              protected: true,
              snapshotUsecs: runTask.base.startTimeUsecs,
              view: runTask.base.sources[0].source,
            },
          });
          break;

        // VM (excluding cloud VMs)
        case ENV_GROUPS.hypervisor
          .includes(runTask._normalizedEntity.environmentType) &&
            !ENV_GROUPS.cloudSources
              .includes(runTask._normalizedEntity.environmentType):

          // This is not a SQL job, OR it's a SQL job and full backup run.
          if (runTask.base.type !== 3 ||
            (runTask.base.type === 3 && runTask.base.backupType !== 2)) {

            if ($rootScope.user.privs.RESTORE_MODIFY) {
              items.push({
                icon: 'icn-recover',
                display: $scope.text.recoverVm,
                state: 'recover-vm.recover-options',
                stateParams: {
                  entityId: runTask._normalizedEntity.id,
                  jobId: jobId,
                  jobInstanceId: runTask.base.jobInstanceId,
                  jobRunStartTime: runTask.base.startTimeUsecs,
                  jobUid: jobUid,
                  entityIds: [runTask._normalizedEntity.id],
                  jobIds: [jobId],
                },
              });
            }

            if (canCloneVm(runTask._normalizedEntity)) {
              items.push({
                icon: 'icn-clone',
                display: $scope.text.cloneVm,
                state: 'clone-vms.clone-options',
                stateParams: {
                  entityId: runTask._normalizedEntity.id,
                  jobId: jobId,
                  jobInstanceId: runTask.base.jobInstanceId,
                },
              });
            }
          }
          break;

        // Pure
        case runTask._normalizedEntity.entityKey === 'pureEntity' &&
          $rootScope.user.privs.RESTORE_MODIFY:
          items.push({
            icon: 'icn-recover',
            display: $scope.text.recoverSanVolume,
            state: FEATURE_FLAGS.restoreStorageVolume ?
              'recover-storage-volume.pure-options' :
              'recover-pure.options',
            // From _recover-pure.js: ?entityId&parentId&jobId&jobInstanceId
            stateParams: {
              entityId: runTask._normalizedEntity.id,
              jobId: jobId,
              jobInstanceId: runTask.base.jobInstanceId,
              parentId: runTask._normalizedEntity.parentId,
            },
          });
          break;

        // NAS Adapter
        case ENV_GROUPS.nas
          .includes(runTask._normalizedEntity.environmentType) &&
          $rootScope.user.privs.RESTORE_MODIFY:
          items.push({
            icon: 'icn-recover',
            display: $scope.text.recoverNasVolume,
            state: FEATURE_FLAGS.restoreStorageVolume ?
              'recover-storage-volume.nas-options' :
              'recover-nas.options',
            // From _recover-nas.js: ?entityId&parentId&jobId&jobInstanceId
            stateParams: {
              entityId: runTask._normalizedEntity.id,
              jobId: jobId,
              jobInstanceId: runTask.base.jobInstanceId,
            },
          });
          break;

        // Physical or Cloud Sources
        case runTask._normalizedEntity.entityKey === 'physicalEntity' ||
          ENV_GROUPS.cloudSources
            .includes(runTask._normalizedEntity.environmentType):
          // If job task is not a SQL Log and not a file-based job.
          // TODO (David): Remove the file-based restriction after backend
          // supports instant mount of file-based backups, possibly 5.0.
          if ($rootScope.user.privs.RESTORE_MODIFY &&
            runTask.base.backupType !== 2 &&
            ![13,19].includes(runTask.base.type)) {
            // TODO (Maulik): Remove SQL file based restriction when backend
            // supports instant mount of SQL file-based backups
            if (sqlBackupJobParams && sqlBackupJobParams.fullBackupType !== 0) {
              break;
            }

            // for sources using physical agent, we only support
            // instant volume mount. For native snapshots, we support recovery.
            if (isNativeSnapshotJob || areSnapshotsRemotelyManaged) {
              display = $scope.text.recoverVm;
              state = 'recover-vm.recover-options';

            // Amazon RDS case
            } else if (run.backupRun.base.type ===
              ENV_TYPE_CONVERSION.kRDSSnapshotManager) {

              display = $scope.text.recoverVm;
              state = 'recover-rds.options';
            } else {
              display = $scope.text.instantVolumeMount;
              state = 'recover-mount-point.options';
            }

            items.push({
              icon: 'icn-recover',
              display: display,
              state: state,

              // From _recover-mount-point.js: ?entityId&hostId&jobId&jobInstanceId
              stateParams: {
                entityId: runTask._normalizedEntity.id,
                jobId: jobId,
                jobInstanceId: runTask.base.jobInstanceId,
                jobRunStartTime: runTask.base.startTimeUsecs,
                jobUid: jobUid,
              },
            });
          }
          break;
      }
      const browsableEnvs = SearchService.getBrowsableEnvironments();
      const env = ENV_TYPE_CONVERSION[runTask._normalizedEntity.environmentType];
      if (browsableEnvs.includes(env)) {
        items.push({
          translateKey: 'recoverFiles',
          icon: 'icn-recover',
          state: 'recover-files.search',
          stateParams: {
            browseSourceId: runTask._normalizedEntity.id,
            browseSourceName: runTask._normalizedEntity.name,
          },
        });
      }

      return $filter('orderBy')(items, 'display', true);
    };

    /**
     * Determines ability to clone VM.
     *
     * @method   canCloneVm
     * @param    {object}    normalizedEntity   The entity
     * @return   {boolean}   True if able to clone VM, False otherwise.
     */
    function canCloneVm(normalizedEntity) {
      // Not a clonable VM type and/or no permissions to clone. Exit early.
      if (!$rootScope.user.privs.CLONE_MODIFY ||
        !ENV_GROUPS.hypervisor.includes(normalizedEntity.environmentType) ||
        normalizedEntity.isVmTemplate) {
        return false;
      }

      switch (normalizedEntity.entityKey) {
        case 'acropolisEntity':
          return $rootScope.FEATURE_FLAGS.acropolisClone;

        // All other types clone is permitted.
        default:
          return true;
      }
    }

    // Watch currentTab for changes
    $scope.$watch(
      'currentTab',
      function changeState(newValue, oldValue) {
        if (newValue !== oldValue) {
          // Set value of tab based on current state
          $scope.currentTab = newValue;
          // Manually kick off getData when navigating to protection from another tab
          if (newValue === 'job-run-details.protection') {
            getData();
          }
          $state.go(newValue, $state.params, {
            reload: false
          });
        }
      }
    );

    /**
     * Cancel a copy task
     *
     * @method   cancelTask
     * @param    {Object}     task    object containing the task info
     */
    $scope.cancelTask = function cancelTask(task) {
      var copyTaskUid = task.taskUid;
      var taskTypeString;
      var taskName = '';

      // The task to be cancelled can be a replication/cloudArchive/cloudSpin
      // task. Use the appropriate string in the modal.
      switch (task.snapshotTarget.type) {
        case 4:
          taskTypeString = 'cloudDeploy';
          taskName =
            task.snapshotTarget.cloudDeployTarget.targetEntity.displayName;
          break;

        case 3:
          taskTypeString = 'cloudArchive';
          taskName = task.snapshotTarget.archivalTarget.name;
          break;

        case 5:
          taskTypeString = 'replication';
          taskName =
            task.snapshotTarget.cloudDeployTarget.targetEntity.displayName;
          break;

        default:
          taskTypeString = 'copy';
          taskName = task.snapshotTarget.replicationTarget.clusterName;
      }

      var options = {
        title: $scope.text.cancelTaskModal.title,
        titleKeyContext: {taskType: taskTypeString},
        content:
          $interpolate($scope.text.cancelTaskModal.text)({name: taskName}),
        closeButtonText: $scope.text.cancelTaskModal.cancel,
        actionButtonText: $scope.text.cancelTaskModal.cancelTask
      };

      cModal.showModal({}, options).then(
        function showModal(r) {
          var jobRunParams = {
            jobId: $scope.jobDescription.jobId,
            copyTaskUid: {
              id: copyTaskUid.objectId,
              clusterId: copyTaskUid.clusterId,
              clusterIncarnationId: copyTaskUid.clusterIncarnationId
            }
          };

          JobRunsService.cancelJobRun(parseInt($scope.jobDescription.jobId, 10), jobRunParams)
            .then(
              function cancelTaskSuccess() {
                // successfully submitted cancel request, show success message
                // and reload data
                cMessage.success({
                  textKey: 'jobDetails.cancelSuccess.text',
                  textKeyContext: {taskType: taskTypeString},
                });

                $timeout(
                  // Give magneto a brief delay to update this job
                  // before refreshing the page again
                  function refreshPageDelay() {
                    $state.reload();
                  },
                  300
                );
              },
              evalAJAX.errorMessage
            );
        }
      );
    };

    /**
     * Determines if an archive task target type is tape.
     *
     * @method   isTapeTask
     * @param    {Object}     task    Archive Task as returned from getJobRuns
     * @return   {Boolean}    True if this task is a tape task
     */
    $scope.isTapeTask = function isTapeTask(task) {
      return !!(task.snapshotTarget &&
        task.snapshotTarget.archivalTarget &&
        task.snapshotTarget.archivalTarget.type === 1);
    };

    /**
     * Opens the warnings modal for the given VM
     *
     * @method     openObjectWarnings
     * @param      {object}    task    task for which warnings are to be displayed
     */
    $scope.openObjectWarnings = function openObjectWarnings(task, jobRun) {
      var config = {
        controller: /*@ngInject*/
          function objectWarningsControllerFn($scope, task, NgProtectionRunsService) {
            $scope.task = task;

            /**
             * Indicates if error logs can be downloaded.
             *
             * @return {boolean}   true if error logs can be downloaded.
             */
            $scope.canDownloadErrorLogs = function canDownloadErrorLogs() {
              return !!FEATURE_FLAGS.downloadErrorLogsEnabled
                && jobRun._isJobRunLocal
                && ENV_GROUPS.nas.includes(jobRun.backupRun.base.type);
            };

            /**
             * Downloads error logs for a given object
             *
             * @method     downloadErrorLogs
             * @param      {object}    task   task for which error logs are downloaded
             */
            $scope.downloadErrorLogs = function downloadErrorLogs(task) {
              let jobUid = task.base.primaryJobUid;

              const apiParams = {
                id: jobUid.clusterId + ':' + jobUid.clusterIncarnationId +
                  ':' + jobUid.objectId,
                runId: jobUid.objectId + ':' + task.base.startTimeUsecs,
                objectId: task._sourceObject.id
              }

              NgProtectionRunsService.downloadErrorLogs(apiParams);
            };
          },
        templateUrl: 'app/protection/jobs/details/run/inc/inc-modal-object-warnings.html',
        size: 'lg',
        resolve: {
          task: depsResolver(task)
        },
      };

      SlideModalService.newModal(config);
    };

    $scope.noLocalSnapshotAvailable =
      function noLocalSnapshotAvailable(jobRun, task) {
      return task.snapshotDeleted ||
        task._snapshotMarkedForDeletion ||
        (jobRun.backupRun.base.status === 2 && !jobRun._hasLocalSnapshot);
    };

    /**
     * presents a confirmation modal before deleting objects.
     *
     * @method    deleteObjects
     */
    $scope.deleteObjects = function deleteObjects() {
      var archivalTargets = JobRunsService.getArchivalTargets($scope.jobRun.copyRun, true);
      var replicationTargets = JobRunsService.getReplicationTargets($scope.jobRun.copyRun, true);

      JobRunsService
        .getDeleteObjectsModal($scope.shared.selectedObjectsMap,
          archivalTargets, replicationTargets)
        .then(function deleteJobConfirmed(allowArchiveRecovery) {
          var data = formatDeleteJobRunsData(archivalTargets, allowArchiveRecovery);

          JobRunsService
            .deleteJobRuns(data)
            .then(function deleteJobRunsSuccess(response) {
              $timeout(
                // Give magneto a brief delay to update this job before
                // refreshing the page again.
                function refreshPageDelay() {
                  $state.go('job-run-details.protection', {
                    id: $state.params.id,
                  }, {
                    reload: true,
                  });
                },
                300
              );
            },
            evalAJAX.errorMessage
          );
        }
      );
    };

    /**
     * Formats the data for deleteObjects API request
     *
     * @method   formatDeleteJobRunsData
     * @param    {Array}     archivalTargets        List of all archival Targets
     * @param    {Boolean}   allowArchiveRecovery   If false, archive data will
     *                                              also be deleted.
     * @return   {Object}    Formatted data for API request
     */
    function formatDeleteJobRunsData(archivalTargets, allowArchiveRecovery) {
      var data = {
        jobRuns: []
      };

      var sourceIds = $scope.getSelectedObjectIds();

      data.jobRuns.push({
        copyRunTargets: [{
          daysToKeep: 0,
          type: 'kLocal',
        }],
        runStartTimeUsecs: parseInt($state.params.startTimeUsecs, 10),
        jobUid:
          JobActionFormatter.formatJobUid($scope.jobRun.backupRun.base.jobUid),
        sourceIds: sourceIds,
      });

      // Format data according to the response
      if (!allowArchiveRecovery) {
        archivalTargets.forEach(function addArchiveData(target) {
          data.jobRuns[0].copyRunTargets.push({
            daysToKeep: 0,
            type: 'kArchival',
            archivalTarget: {
              vaultName: target.name,
              vaultId: target.vaultId,
              vaultType: ENUM_ARCHIVAL_TARGET[target.type],
            },
          });
        });
      }

      return data;
    }

    /**
     * Gets the selected object identifiers.
     *
     * @method   getSelectedObjectIds
     * @return   {Array}   The selected object identifiers.
     */
    $scope.getSelectedObjectIds = function getSelectedObjectIds() {
      var sourceIds = [];
      _.forEach(
        $scope.shared.selectedObjectsMap,
        function selectObjectsForLegalHold(objectName, sourceId) {
          if (objectName !== false) {
            sourceIds.push(parseInt(sourceId, 10));
          }
        }
      );
      return sourceIds;
    };

    /**
     * Opens a confirmation modal when legal hold is added or removed from a
     * run object.
     *
     * @method   _openLegalHoldModal
     * @param    {object}    requestObject     The decorated request object
     * @param    {boolean}   enableLegalHold   True if legal hold is being
     *                                         added, False if legal hold is
     *                                         removed.
     */
    function _openLegalHoldModal(requestObject, enableLegalHold) {
      var modalConfig = {
        controller: 'ModifyLegalHoldController',
        templateUrl: 'app/protection/jobs/modals/confirm-object-legal-hold.html',
        resolve: {
          requestObject: function resolveFn() { return requestObject; }
        },
      };
      var modalOptions;
      if (enableLegalHold) {
        // Add Legal Hold Modal options
        modalOptions = {
          severity: 'warn',
          titleKey: 'addLegalHold',
          actionButtonKey: 'addLegalHold',
          closeButtonKey: 'cancel',
        };
      } else {
        // Remove Legal Hold Modal options
        modalOptions = {
          severity: 'warn',
          titleKey: 'removeLegalHold',
          actionButtonKey: 'removeLegalHold',
          closeButtonKey: 'cancel',
        };
      }

      cModal.standardModal(modalConfig, modalOptions).then(
        function modifyLegalHold() {
          $timeout(
            // Give magneto a brief delay to update this job
            // before refreshing the page again
            function refreshPageDelay() {
              $state.go('job-run-details.protection',
                $state.params, { reload: true });
            },
            300
          );
        },
        angular.noop
      );
    }

    /**
     * Creates and formats the desired request object when the add/remove legal
     * hold request is fired on a list of run objects.
     *
     * @method   generateLegalHoldPayload
     * @param    {boolean}   enableLegalHold   True if legal hold is being
     *                                         added, False if legal hold is
     *                                         removed.
     * @aparam   {integer}   taskId            Task id passed when legal hold
     *                                         set via context menu
     */
    $scope.generateLegalHoldPayload =
      function generateLegalHoldPayload(enableLegalHold, taskId) {
      //TODO(maulik): Merging Delete and Legal hold logic in one place.
      var requestObject = { jobRuns: [] };
      var sourceIds = taskId ? [taskId] : $scope.getSelectedObjectIds();
      var replicationTargets = JobRunsService.getReplicationTargets($scope.jobRun.copyRun, true);
      var archivalTargets = JobRunsService.getArchivalTargets($scope.jobRun.copyRun, true);

      // Prepare default payload with local snapshot
      requestObject.jobRuns.push({
        copyRunTargets: [
          {
            holdForLegalPurpose: enableLegalHold,
            type: 'kLocal'
          }
        ],
        runStartTimeUsecs: parseInt($state.params.startTimeUsecs, 10),
        jobUid: JobActionFormatter.formatJobUid(
          $scope.jobRun.backupRun.base.jobUid
        ),
        sourceIds: sourceIds
      });

      // Add Archival targets to payload
      archivalTargets.forEach(function loopOverArchivalTargets(target) {
        requestObject.jobRuns[0].copyRunTargets.push({
          type: 'kArchival',
          holdForLegalPurpose: enableLegalHold,
          archivalTarget: {
            vaultName: target.name,
            vaultId: target.vaultId,
            vaultType: ENUM_ARCHIVAL_TARGET[target.type]
          }
        });
      });

      // Add Replication targets to payload
      replicationTargets.forEach(function loopOverReplicationTargets(target) {
        requestObject.jobRuns[0].copyRunTargets.push({
          type: 'kRemote',
          holdForLegalPurpose: enableLegalHold,
          replicationTarget: {
            clusterName: target.clusterName,
            clusterId: target.clusterId
          }
        });
      });

      _openLegalHoldModal(requestObject, enableLegalHold);
    };

    /**
     * Ng-friendly wrapper for deps resolve functions. Reduces typing.
     *
     * @method   depsResolver
     * @param    {Object}   dep   Value to return in the resolve.
     * @return   {*}              The dependency passed through.
     */
    function depsResolver(dep) {
      return function innerResolver() {
        return dep;
      };
    }

    activate();
  }

  /**
     * Controller function for Legal Hold Confirmation Modal.
     *
     * @class   ModifyLegalHoldControllerFn (name)
     * @param   {object}   $uibModalInstance   The uib modal instance
     * @param   {object}   evalAJAX            The eval ajax
     * @param   {object}   requestObject       The request object
     */
  function ModifyLegalHoldControllerFn($uibModalInstance, evalAJAX,
    requestObject, JobRunsService) {
    var $ctrl = this;
    $ctrl.holdForLegalPurpose =
      requestObject.jobRuns[0].copyRunTargets[0].holdForLegalPurpose;

    /**
     * Handles onClick the primary button in the standardModal.
     */
    $ctrl.ok = function okayThen() {
      $ctrl.submitting = true;
      JobRunsService.setLegalHold(requestObject)
        .then($uibModalInstance.close, evalAJAX.errorMessage)
        .finally(function finallyFn() {
          $ctrl.submitting = false;
        });
    };
  }

})(angular);
