<cog-settings-list-item cogFormGroup
  [formGroup]="formGroup"
  [label]="'objectsProperties' | translate">
  <cog-settings-list-item-value-display>
    <p>
      {{ getValueDisplay(formGroupValues.objectDetails) }}
    </p>
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor>
    <div class="option-container">
      <div class="option-head">
        <h4 translate class="property-col1">{{ 'objects' | translate }}</h4>
        <h4 translate class="property-col2">{{ 'keys' | translate }}</h4>
        <h4 translate class="property-col3">{{ 'values' | translate }}</h4>
      </div>
      <div class="option-body">
        <ng-container [formArrayName]="formControlNames.objectDetails"
          *ngFor="let control of formGroupControls.objectDetails.controls">
          <coh-settings-list-object-property-details
            [formControl]="control"
            [environment]="environment">
          </coh-settings-list-object-property-details>
        </ng-container>
      </div>
    </div>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
