import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { FileSize } from '@cohesity/helix';
import { Subject } from 'rxjs';

import { REGEX_FORMATS } from '../constants/formats.constants';

/**
 * Scale factor unit definition.
 */
interface ScaleFactorDef {
  /**
   * Label for scale factor.
   */
  label: string;

  /**
   * Factor value required for conversion.
   */
  factor: number;

  /**
   * Specifies the unit name.
   */
  unit: string;
}

/**
 * @description
 * Customized Bytes size selection component which provides input to record byte size
 * along with bytes unit selector.
 *
 * @example
 *  <coh-byte-size-selector
 *    [id]="templateId"
 *    [required]="false"
 *    [allowDecimal]="true"
 *    [units]="units"
 *    [label]="fieldName"
 *    [useAsBits]="true"
 *    [value]="defaultValue"
 *    [min]="minValue"
 *    [max]="maxValue"
 *    [control]="formControl"
 *    (valueChange)="valueChangeHandler($event)">
 *  </coh-byte-size-selector>
 */
@Component({
  selector: 'coh-byte-size-selector',
  templateUrl: './byte-size-selector.component.html',
  styleUrls: ['./byte-size-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BytesSizeSelectorComponent implements OnInit, OnChanges {
  /**
   * Specifies to allow decimal numbers in the input. Default is false.
   */
  @Input() allowDecimal = false;

  /**
   * Label for form field.
   */
  @Input() label: string;

  /**
   * Return true if inline label should be used.
   */
  @Input() useInlineLabel = false;

  /**
   * Scaled minimum value for validation.
   */
  @Input() min: number;

  /**
   * Raw maximum value for validation.
   */
  @Input() max: number;

  /**
   * Template id for reference.
   */
  @Input() id: string;

  /**
   * Array of bytes units visible and unit selector will be hidden if
   * only one unit is visible.
   */
  @Input() units: string[] = [];

  /**
   * Form control for Scaled value.
   */
  @Input() control: UntypedFormControl;

  /**
   * Whether the field is required or not.
   */
  @Input() required = true;

  /**
   * Setter method for default scaled value.
   */
  @Input()
  set value(scaledValue: number) {
    this.control.setValue(scaledValue);
    this.updateValidators();
    // Ensure onInit has been called
    if (this.scaleFactor.value) {
      this.calculateScaledValue();
    }
  }

  /**
   * Returns scaled bytes value.
   */
  get value(): number {
    return this.control.value;
  }

  /**
   * Determines whether to use bits scale factors or not.
   */
  @Input() useAsBits = false;

  /**
   * Updates the output scaled value.
   */
  @Output() valueChange = new Subject<number>();

  /**
   * Indicate whether edit is allowed or not.
   */
  @Input() readonly ?= false;

  /**
   * Bit scale factors definition.
   */
  bitScaleFactors: ScaleFactorDef[] = [
    {
      label: 'filesize.bits',
      factor: FileSize.bit,
      unit: 'Bits',
    },
    {
      label: 'filesizeAbbrevations.kilobits',
      factor: FileSize.kilobit,
      unit: 'Kb',
    },
    {
      label: 'filesizeAbbrevations.megabits',
      factor: FileSize.megabit,
      unit: 'Mb',
    },
    {
      label: 'filesizeAbbrevations.gigabits',
      factor: FileSize.gigabit,
      unit: 'Gb',
    },
    {
      label: 'filesizeAbbrevations.terabits',
      factor: FileSize.terabit,
      unit: 'Tb',
    },
    {
      label: 'filesizeAbbrevations.petabits',
      factor: FileSize.petabit,
      unit: 'Pb',
    },
  ];

  /**
   * Bytes scale factor defintion.
   */
  byteScaleFactors: ScaleFactorDef[] = [
    {
      label: 'filesize.bytes',
      factor: FileSize.byte,
      unit: 'Bytes',
    },
    {
      label: 'filesizeAbbrevations.kilobytes',
      factor: FileSize.kilobyte,
      unit: 'KB',
    },
    {
      label: 'filesizeAbbrevations.megabytes',
      factor: FileSize.megabyte,
      unit: 'MB',
    },
    {
      label: 'filesizeAbbrevations.gigabytes',
      factor: FileSize.gigabyte,
      unit: 'GB',
    },
    {
      label: 'filesizeAbbrevations.terabytes',
      factor: FileSize.terabyte,
      unit: 'TB',
    },
    {
      label: 'filesizeAbbrevations.petabytes',
      factor: FileSize.petabyte,
      unit: 'PB',
    },
  ];

  /**
   * Step value for decimal range of values.
   */
  decimalStepValue = 0.01;

  /**
   * Default step value.
   */
  stepValue = 1;

  /**
   * Array of scale factors to be displayed.
   */
  scaleFactors: ScaleFactorDef[] = [];

  /**
   * Selected scale factor.
   */
  scaleFactor = new UntypedFormControl(null);

  /**
   * Scaled value.
   */
  scaledValue = new UntypedFormControl(null);

  /**
   * Init Component.
   */
  ngOnInit() {
    this.scaleFactors = this.useAsBits ? this.bitScaleFactors : this.byteScaleFactors;

    // Filtering scale factors list if list of units are provided.
    if (this.units && this.units.length) {
      this.scaleFactors = this.scaleFactors.filter((scale: ScaleFactorDef) => this.units.includes(scale.unit));
    }

    // Default to GB/Gb or smallest.
    this.scaleFactor.setValue(
      this.scaleFactors.find(scale => scale.factor === FileSize.gigabyte) || this.scaleFactors[0]
    );

    /**
     * Disable form field if there is only one scale factor.
     */
    if (this.scaleFactors.length === 1) {
      this.scaleFactor.disable();
    }

    // Calculates scaled value on init based on provided configuration.
    this.calculateScaledValue();
  }

  /**
   * Handling input changes and validations accordingly.
   *
   * @param  changes  simple changes object
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.value || changes.max || changes.min || changes.required) {
      this.updateValidators();
    }
  }

  /**
   * Method called to sync model values.
   */
  updateScaledValue(value: number) {
    const newValue = +value;

    // Instead of rounding it off the value, reducing to nearest valid value using floor.
    this.value = newValue > 0 ? (Math.floor(newValue * this.scaleFactor.value.factor)) : newValue;

    if (!this.value) {
      this.calculateScaledValue();
    }
    this.valueChange.next(this.value);
  }

  /**
   * Method called to update validators based on value change.
   */
  updateValidators() {
    const validators = [
      Validators.pattern(REGEX_FORMATS.positiveNumbers),
      Validators.min(this.min),
      Validators.max(this.max),
    ];

    if (this.required) {
      validators.push(Validators.required);
    }

    this.control.setValidators(validators);
    this.control.updateValueAndValidity();
  }

  /**
   * Method called to calculate the scaled value and rounds to nearest whole number.
   */
  calculateScaledValue() {
    if (!this.scaleFactors) {
      return;
    }

    let bytes = this.value;

    // Find the highest matching scale with a scaledValue greater than one (1)
    // and set it.
    this.scaleFactors.some((scale) => {
      const scaledValue = bytes / scale.factor;

      if (scaledValue >= 1) {
        this.scaleFactor.setValue(scale);
      } else {
        // Round scaledValue to a whole number and then convert back to bytes.
        // It's rounded off instead of floor in this case because to display
        // the most natural user-facing value.
        return bytes = parseFloat((bytes / this.scaleFactor.value.factor).toFixed(2))
          * this.scaleFactor.value.factor;
      }
    });

    // Adjust for the set scale
    this.scaledValue.setValue(bytes / this.scaleFactor.value.factor);
  }

}
