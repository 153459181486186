import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

import { DayVal } from '@cohesity/utils';
import { LimitInterval } from '../time-period-selector/time-period-selector-form';
import { ScheduleSelectorForm } from './schedule-selector-form';
import { PolicyScheduleUnit } from './schedule-selector-form';

/**
 * The standard min/max configuration for hourly number input.
 */
const standardHourlyLimitInterval: LimitInterval = {
  min: 1,
  max: 365000,
};

/**
 * The DMaaS/DataProtect min/max configuration for default hourly input.
 */
const dmsDefaultHourlyLimitInterval: LimitInterval = {
  // Currently only allowing DMaaS hourly runs at 12+ hours.
  min: 12,
  max: 365000,
};

/**
 * The DMaaS/DataProtect min/max configuration for hourly input
 * if dmsIncreaseBackupFrequency feature flag is enabled.
 */
const dmsHourlyLimitInterval: LimitInterval = {
  // Allowing DMaaS hourly runs at per the users desire if feature flag
  // is enabled for the account.
  min: 1,
  max: 365000,
};

/**
 * Schedule Selector component for scheduling backup in protection policy.
 */
@Component({
  selector: 'coh-schedule-selector',
  templateUrl: './schedule-selector.component.html',
  styleUrls: ['./schedule-selector.component.scss'],
})
export class ScheduleSelectorComponent implements OnDestroy {
  /**
   * Array of numbers from 1 to 31 to represent 31 days in a month.
   */
  readonly daysInMonth = [...Array(31).keys()].map(x => ++x);

  // Save a reference to the enum
  DayVal = DayVal;

  // The Schedule Selector FormGroup
  @Input() form: ScheduleSelectorForm;

  // ID of the scheduleSelector
  @Input() id = '';

  // Time period label
  @Input() label: string;

  // Optional className for custom styling
  @Input() className = '';

  /**
   * Indicates if the user is currently operating in DMaaS scope.
   */
  @Input() isDmsScope = false;

  /**
   * True if the dmsIncreaseBackupFrequency is set to true
   */
  @Input() dmsIncreaseBackupFrequency = false;

  /**
   * Indicates the frequent DMS backups should be allowed (based on
   * account flag value, 0 is disabled)
   */
  @Input() dmsBackupHourlyFrequency = 0;

  /**
   * Whether to show the periodicity selector or not.
   */
  @Input() hidePeriodicitySelector = false;

  /**
   * Prefix value for aria-label.
   */
  @Input() ariaLabelPrefix: string;

  /**
   * Emits the value when set.
   */
  @Output() valueChange = new EventEmitter<number>();

  /**
   * Get dayValue.
   *
   * @return The current form value for day.
   */
  get dayValue(): string {
    return this.form.value.day || '';
  }

  /**
   * Get daysValue.
   *
   * @return The current form value for days.
   */
  get daysValue(): string[] {
    return this.form.value.days || [];
  }

  /**
   * Get timePeriod typeControl value.
   *
   * @return The current form value for timePeriod type.
   */
  get timePeriodType(): string {
    return this.form.timePeriod.value.type;
  }

  /**
   * Get timePeriod valueControl value.
   *
   * @return The current form value for timePeriod value.
   */
  get timePeriodValue(): number {
    return this.form.timePeriod.value.value;
  }

  /**
   * Returns true if the current type option requires daysOfWeek selector;
   * otherwise false.
   */
  get requiresDaysofWeekSelector(): boolean {
    return (
      this.form.typeOptionsWithDaysofWeek.includes(this.form.timePeriod.typeControl.value) &&
      this.form.dayCount?.value?.type !== 'Date'
    );
  }

  /**
   * Returns true if requires dayCount selector.
   */
  get requiresDayCountSelector(): boolean {
    return this.form.timePeriod.typeControl.value === PolicyScheduleUnit.Months;
  }

  /**
   * Returns true if requires dayOfYear selector.
   */
  get requiresDayOfYearSelector(): boolean {
    return this.form.timePeriod.typeControl.value === PolicyScheduleUnit.Years;
  }

  /**
   * Returns true if requires dayOfMonth calendar selector.
   */
  get requiresDayOfMonthSelector(): boolean {
    return (
      this.form.timePeriod.typeControl.value === PolicyScheduleUnit.Months && this.form.dayCount?.value?.type === 'Date'
    );
  }

  /**
   * Provides the min/max values based on the current scenario.
   */
  get periodicityValueLimit(): LimitInterval {
    const isHourly = this.form.timePeriod.typeControl.value === PolicyScheduleUnit.Hours;

    if (isHourly && this.isDmsScope) {
      if (this.dmsBackupHourlyFrequency) {
        dmsDefaultHourlyLimitInterval.min = this.dmsBackupHourlyFrequency;
        return dmsDefaultHourlyLimitInterval;
      }
      return this.dmsIncreaseBackupFrequency ? dmsHourlyLimitInterval : dmsDefaultHourlyLimitInterval;
    }

    return standardHourlyLimitInterval;
  }

  /**
   * Updates the change to valueChange subject for parent component to use.
   *
   * @param event Change Handler
   */
  updateValue(event) {
    this.valueChange.emit(event);
  }

  /**
   * On destroy of component.
   */
  ngOnDestroy() {
    if (this.form) {
      this.form.cleanup();
    }
  }

  /**
   * Updates dayOfMonth form control value on calendar date change.
   *
   * @param   day   day of the month.
   */
  updateDayOfMonth(day: number) {
    this.form.dayOfMonth.setValue(day);
  }
}
