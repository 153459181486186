<div class="cog-page-wrapper" cdkScrollable>
  <!--
    This additional div allows the sticky header to be contained by an element
    that is the full height of the content rather than just the scrollable area
    height. This ensures the sticky header will work consistently in
    supported browsers.
  -->
  <div class="cog-page-content-wrapper">
    <div class="cog-page-header" [class.hidden]="backdropRef">
      <ng-content select="cog-page-header"></ng-content>
    </div>
    <cog-page-backdrop></cog-page-backdrop>
    <ng-container *ngIf="!hasPageDrawer">
      <ng-container *ngTemplateOutlet="pageContent"></ng-container>
    </ng-container>


    <!-- page drawer layout -->
    <mat-drawer-container
      *ngIf="hasPageDrawer"
      autosize
      class="mat-drawer-transition"
      [ngClass]="{ 'expanded': showPageDrawer, 'collapsed': !showPageDrawer }">
      <!--
        mat-drawer will be always open and we will be toggling b/w page drawer
        and page drawer anchor conditionally hence autosize options us used to
        dynamically adjust the mat-drawer.
       -->
      <mat-drawer mode="side" opened="true">
        <div class="cog-page-drawer-content">
          <ng-content *ngIf="showPageDrawer" select="cog-page-drawer"></ng-content>
          <mat-card *ngIf="!showPageDrawer" (click)="togglePageDrawer()">
            <ng-content select="cog-page-drawer-anchor"></ng-content>
          </mat-card>
        </div>
      </mat-drawer>

      <mat-drawer-content>
        <ng-container *ngTemplateOutlet="pageContent"></ng-container>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</div>

<!-- page content layout template -->
<ng-template #pageContent>
  <div class="cog-page-content" [class.cog-page-has-top-banners]="hasTopBanners$ | async">
    <ng-content></ng-content>
  </div>
</ng-template>
