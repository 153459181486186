import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AttributeFilter, FiltersServiceApi } from '@cohesity/api/reporting';
import { FiltersComponent, ValueFilterSelection } from '@cohesity/helix';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseReportFilterComponent } from '../base-report-filter.component';

@Component({
  selector: 'iris-rpt-target-type-filter',
  templateUrl: './target-type-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TargetTypeFilterComponent extends BaseReportFilterComponent {
  constructor(
    filters: FiltersComponent,
    private api: FiltersServiceApi,
  ) {
    super(filters, 'targetType');
  }

  getFilterValues(): Observable<ValueFilterSelection[]> {
    return this.api.GetResources({ resourceType: 'ExternalTargets' }).pipe(
      map(response => (response?.externalTargets || []).map(target => target.targetType)),

      // Convert to a set and back to an array to remove duplicates environments
      map(types => [...new Set(types)].sort()),

      // Convert to filter value
      map(types => types.map(type => ({
        label: this.translate(`enum.targetType.${type}`),
        value: type,
      }))),
    );
  }

  toApiValue(filterValues: ValueFilterSelection[]): AttributeFilter {
    if (!filterValues?.length) {
      return null;
    }
    return {
      attribute: this.property,
      filterType: 'In',
      inFilterParams: {
        attributeDataType: 'String',
        stringFilterValues: filterValues.map(value => value.value as string),
        attributeLabels: filterValues.map(value => value.label),
      },
    };
  }

  fromApiValue(apiValue: AttributeFilter): ValueFilterSelection[] {
    return (apiValue?.inFilterParams.stringFilterValues || []).map((value, index) => ({
      label: apiValue.inFilterParams.attributeLabels[index],
      value: value,
    }));
  }
}
