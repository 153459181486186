import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, shareReplay, startWith, tap } from 'rxjs/operators';
import { rxjs7DebounceTime } from './rxjs';

/**
 * Use this to queue multiple items and combine them into a single batch.
 * The max size and the debounce time can be configured.
 *
 */
export class BatchedQueue<Item = any> {
  /**
   * The batch items observable. Subscribe to this observable to trigger to combine
   * the items into a single batch
   */
  batchedQueue$: Observable<Item[]>;

  /**
   * A queue of pending items
   */
  private queue = new BehaviorSubject<Item[]>([]);

  /**
   * A method to trigger the batched items if more than the limit is reached before
   * the debounce time.
   */
  private clearTrigger = new BehaviorSubject<string>(null);

  constructor(public maxItemSize: number = 20, debounceTimeMs: number = 10) {
    this.batchedQueue$ = combineLatest([
      this.queue.pipe(rxjs7DebounceTime(debounceTimeMs), startWith([])),
      this.clearTrigger,
    ]).pipe(
      map(() => this.queue.value),
      filter(items => !!items.length),
      tap(() => this.queue.next([]), shareReplay(1))
    );
  }

  /**
   * Add an item to the queue.
   *
   * @param   item   The item to queue.
   */
  push(item: Item) {
    if (this.queue.value.includes(item)) {
      return;
    }
    if (this.queue.value.length >= this.maxItemSize) {
      this.clearTrigger.next('next');
      this.queue.next([item]);
    } else {
      this.queue.next([...this.queue.value, item]);
    }
  }
}
