<cog-statlist valueSize="sm" [itemSpacing]="itemSpacing">
  <ng-container *ngFor="let stat of stats">
    <ng-container
      *ngTemplateOutlet="stat.state ?
        stateItemTemplate : statelessItemTemplate;
        context: { $implicit: stat }">
    </ng-container>
  </ng-container>
</cog-statlist>

<ng-template #stateItemTemplate let-stat>
  <div>
    <a cogStatlistItem
      [label]="stat.label | translate"
      [uiSref]="stat.state"
      [uiParams]="stat.stateParams"
      cogDataId="stat-list-item-{{ stat.label }}">
      <ng-container *ngTemplateOutlet="statListContent; context: { stat: stat }"></ng-container>
    </a>
  </div>
</ng-template>

<ng-template #statelessItemTemplate let-stat>
  <cog-statlist-item cogDataId="stat-list-item-{{ stat.label }}"
    [label]="stat.label | translate">
    <ng-container *ngTemplateOutlet="statListContent; context: { stat: stat }"></ng-container>
  </cog-statlist-item>
</ng-template>


<ng-template #statListContent let-stat="stat">
  <div class="stat-value">
    <div *ngIf="stat.icon" class="basic-icon">
      <cog-icon [shape]="stat.icon"></cog-icon>
    </div>
    <coh-status-icon class="icon" *ngIf="stat.status" [status]="stat.status" cohStopPropagation></coh-status-icon>
    <span class="value"
      [class.has-offset]="stat.status?.length > 1">
      <div class="progress-bar-container" *ngIf="stat.progress >= 0 && stat.progress < 100; else displayValue">
        <span *ngIf="stat.duration" class="duration-label cog-chart">
          {{ stat.duration | humanizeDuration }}
        </span>
        <mat-progress-bar color="primary"
          mode="determinate"
          [value]="stat.progress">
        </mat-progress-bar>
      </div>
      <ng-template #displayValue>
        <span>{{ stat.value }}</span>
      </ng-template>
    </span>
  </div>
</ng-template>
