<fieldset class="sub-form-fragment" [formGroup]="formGroup">
  <div class="overwrite-container">
    <mat-slide-toggle
      class="margin-top-sm"
      [formControl]="overwrite_"
      [inheritDataId]="true"
      cogDataId="bandwidth-throttling-toggle">
      {{ 'recovery.object.overwriteExisting' | translate }}
    </mat-slide-toggle>
    <div class="overwrite-body" *ngIf="showWarning">
      <div class="icon-container">
        <cog-icon shape="report_problem"></cog-icon>
      </div>
      <div class="warning-container">
        <span>{{ 'recovery.overwrite.warning' | translate }}</span>
        <mat-form-field>
          <input
            matInput
            [inheritDataId]="true"
            (blur)="setConfirmValue($event)"
            [placeholder]="'typeYesToConfirm' | translate"
            cogDataId="continue-input"
            [formControlName]="formControlNames.continue">
        </mat-form-field>
      </div>
    </div>
  </div>
</fieldset>
