<ng-container *ngIf="securityRuleDetails$ | async; let securityRuleDetails">
  <h2 translate class="margin-bottom-lg">clusters</h2>
  <cog-statlist horizontal="true" valueSize="md" class="margin-bottom-lg" itemSpacing="md">
    <cog-pie-chart
      class="piechart-container"
      [percentage]="{
        value: securityRuleDetails.outOfPlanClustersPercentage,
        status: 'critical'}">
    </cog-pie-chart>
    <cog-statlist-item [label]="'outOfRule' | translate">
      {{ securityRuleDetails.outOfPlanClustersPercentage / 100 | percent}}
    </cog-statlist-item>
    <cog-statlist-item [label]="'outOfRuleClusters' | translate">
      {{ securityRuleDetails.outOfPlanClusters | number}}
    </cog-statlist-item>
    <cog-statlist-item [label]="'inRuleClusters' | translate">
      {{ securityRuleDetails.protectedClusters | number}}
    </cog-statlist-item>
  </cog-statlist>

  <!-- Table -->
  <cog-table name="rule-details-clusters-table"
    *ngIf="securityRuleDetails?.clusters"
    [source]="securityRuleDetails?.clusters">
    <table multiTemplateDataRows mat-table matSort matSortActive="cluster" matSortDirection="asc">

      <!-- Cluster -->
      <ng-container matColumnDef="cluster">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cogDataId="cluster-name-header">
          {{ 'cluster' | translate }}
        </th>
        <td mat-cell *matCellDef="let row;index as i" cogDataId="row-cluster-name-{{i}}">
          {{ row.clusterName }}
        </td>
      </ng-container>

      <!-- Strength -->
      <ng-container matColumnDef="strength">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cogDataId="strength-header">
          {{ 'strength' | translate }}
        </th>
        <td mat-cell *matCellDef="let row;index as i" cogDataId="row-strength-{{i}}">
          <div class="table-piechart"
            *ngIf="securityRuleDetails.totalSecurityCriteriaLength !== null; else noStrengthTemplate">
            <cog-pie-chart
              [percentage]="{
                value: !!securityRuleDetails.totalSecurityCriteriaLength ?
                (securityRuleDetails.strength / securityRuleDetails.totalSecurityCriteriaLength) * 100: 0,
                status: 'success'}">
            </cog-pie-chart>
            <div class="piechart-text">
              {{ securityRuleDetails.strength }}/{{ securityRuleDetails.totalSecurityCriteriaLength }}
            </div>
          </div>

          <ng-template #noStrengthTemplate>
            <span>{{ 'naNotAvailable' | translate }}</span>
          </ng-template>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns: columns;index as i" cogDataId="cluster-row-{{i}}"></tr>
    </table>
    <mat-paginator></mat-paginator>
  </cog-table>
</ng-container>
