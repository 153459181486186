import { Pipe, PipeTransform } from '@angular/core';
import { StorageDomain } from '@cohesity/api/v2';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';

/**
 * This pipe used to provides a string value representing a storageDomain's encryption setting.
 *
 * @example
 *   {{ viewbox | ngViewBoxEncryption}}     Yes
 */
@Pipe({
  name: 'ngViewBoxEncryption'
})
export class NgViewBoxEncryptionPipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
    private irisContextService: IrisContextService
  ) { }

  /**
   * Implements the pipe.
   *
   * @param   storageDomain   The storage domain object.
   * @return  storage domain encryption setting.
   */
  transform(storageDomain: StorageDomain): string {

    if (!storageDomain ||
      !storageDomain.storagePolicy) {
      return this.translate.instant('naNotAvailableLetters');
    }
    const { encryptionType, aesEncryptionMode } = storageDomain.storagePolicy;
    switch (encryptionType) {
      case undefined:
      case 'None':
        return this.translate.instant('disabled');
      case 'Strong':
        if (flagEnabled(this.irisContextService.irisContext, 'encryptionKeySizeDisplay') ||
          flagEnabled(this.irisContextService.irisContext, 'encryptionAESGCM')) {
          switch (aesEncryptionMode) {
            case 'GCM':
              return this.translate.instant('aesGCMEncryption');
            case 'CBC':
              return this.translate.instant('aesCBCEncryption');
          }
        } else {
          return this.translate.instant('enabled');
        }
        break;
      case 'Weak':
        return this.translate.instant('enabled');
      default:
        // some other case, fallback for uncertainty
        return this.translate.instant('naNotAvailableLetters');
    }
  }

}
