// MODULE: Date Picker Input
// Extends UI-Bootstrap DatePicker
// initDate : (optional) initial date
// format : (optional) string in JavaScript Date Format eg: 'yyyy-MM-dd'
// callback : function to execute on change event ({time object})

;(function(angular, undefined) {

  angular.module('C.datePicker', [])
    .directive('cDatePicker', datePickerFn);


  function datePickerFn($rootScope, DateTimeService) {

    return {
      restrict: 'E',
      require: '?ngModel',
      scope: {
        ngModel: '=?',
        initDate: '=?',
        minDate: '=?',
        maxDate: '=?',
        format: '=?',
        callback: '&',
        name: '@',
        showPlaceholder: '<?',
      },
      templateUrl: 'app/global/cDatePicker/cDatePicker.html',
      link: linkFn,
    };

    function linkFn(scope, elem, attrs, ngModelCtrl) {
      /**
       * TODO:
       * - make required an option rather than always on
       */

      /**
       * initialization function set the date and call the dateChange function
       *
       * @method   activate
       */
      function activate() {
        if (ngModelCtrl && ngModelCtrl.$viewValue && !scope.initDate) {
          scope.initDate = ngModelCtrl.$viewValue;
        }

        scope.placeholder = scope.showPlaceholder ?
          DateTimeService.getDatePickerPlaceholder() : undefined;

        if (scope.initDate) {
          // no need to call dateChange if initDate was passed in.
          scope.setDate(scope.initDate);
        } else {
          // initialize to "new Date()"?
          scope.dateChange();
        }
      }

      // watch for ng-disabled value changes on c-date-picker and set our
      // scope boolean accordingly.
      scope.$parent.$watch(attrs.ngDisabled, function(newValue, oldValue) {
        scope.isDisabled = newValue;
        if (newValue) {
          scope.clear();
        } else {
          activate();
        }
      });

      // convenience functions and variables
      scope.datepickerFormat = scope.format ?
        scope.format : DateTimeService.getDatePickerFormat();

      scope.required = attrs.hasOwnProperty('required') ? true : false;

      /**
       * clear the date value
       */
      scope.clear = function clear() {
        scope.date = null;
      };

      // default the opened state for the datepicker popup
      scope.opened = false;

      /**
       * set scope.opened to true, which triggers the datepicker to open
       * @param  {Object} $event event Object
       */
      scope.open = function open($event) {
        $event.preventDefault();
        $event.stopPropagation();
        scope.opened = !scope.opened;
      };

      // Watch for changes to initDate to update the date from outside this component
      scope.$watch('initDate', function watchInitDate(nv, ov) {
        if (nv !== ov) {
          scope.setDate(nv);
        }
      });

      if (ngModelCtrl) {
        scope.$watch(function() {
          return ngModelCtrl.$viewValue;
        }, function(nv, ov) {
          // don't update value if model has no value
          if (nv) {
            scope.setDate(ngModelCtrl.$viewValue);
          }
        });
      }

      /**
       * sets the scope.date value based on a passed in Date object
       * @param {Object} theDate   date object
       */
      scope.setDate = function setDate(theDate) {
        scope.date = new Date(theDate);
      };

      /**
       * passes the callback function the updated date via an object
       */
      scope.dateChange = function dateChange() {
        if (typeof scope.callback === 'function') {
          scope.callback({
            date: scope.date
          });
        }
        if (ngModelCtrl && ngModelCtrl.$viewValue !== scope.date) {
          ngModelCtrl.$setViewValue(scope.date);
        }
      };

      /**
       * Setup the min/max model validation.
       *
       * @method   setupMinMaxValidation
       */
      function setupMinMaxValidation() {
        // Add validator function for min value if the binding exists.
        if (angular.isDefined(scope.minDate)) {
          ngModelCtrl.$validators.minDate = function minDateCheck(modelVal) {
            if (!modelVal) {
              return false;
            }
            return moment(scope.minDate).startOf('day').diff(modelVal) <= 0;
          };
        }

        // Add validator function for max value if the binding exists.
        if (angular.isDefined(scope.maxDate)) {
          ngModelCtrl.$validators.maxDate = function maxDateCheck(modelVal) {
            if (!modelVal) {
              return false;
            }
            return moment(scope.maxDate).endOf('day').diff(modelVal) >= 0;
          };
        }
      }

      setupMinMaxValidation();
      activate();
    }
  }
})(angular);
