import moment, { Moment } from 'moment';

/**
 * Date format for displaying in search results.
 *
 * TODO(alex.logashov): This should depend on locale.
 */
export const timeFormat = 'MMM DD, YYYY';

/**
 * No. of milliseconds in one day.
 */
export const msPerDay = 86400000;

/**
 * Timeframe values.
 * Enum values represent each key in milliseconds.
 */
export enum Timeframe {
  Past1Hour = (msPerDay / 24) * -1,
  Past12Hours = (msPerDay / 2) * -1,
  Past24Hours = (msPerDay + 1) * -1,
  Past7Days = (msPerDay * 7) * -1,
  Past30Days = (msPerDay * 30) * -1,
  Past60Days = (msPerDay * 60) * -1,
  Past90Days = (msPerDay * 90) * -1,
  Past180Days = (msPerDay * 180) * -1,
  Past365Days = (msPerDay * 365) * -1,
  PastMonth = (msPerDay * 30 + 1) * -1,
  Past3Months = (msPerDay * 90 + 1) * -1,
  Past6Months = (msPerDay * 180 + 1) * -1,
  PastYear = (msPerDay * 365 + 1) * -1,
  CurrentMonth = (msPerDay * 30 + 2) * -1,
  CurrentYear = (msPerDay * 365 + 2) * -1,
  Day = Math.abs(Timeframe.Past24Hours),
  Week = Math.abs(Timeframe.Past7Days),
  Month = Math.abs(Timeframe.Past30Days),
  Quarter = Math.abs(Timeframe.Past3Months),
  HalfYear = Math.abs(Timeframe.Past6Months),
  Year = Math.abs(Timeframe.PastYear),
  Custom = undefined,
  NoDate = -1,
  Forever = -2,
}

/**
 * variable having all values from the enum except Custom(undefined), NoDate(-1) and Forever(-2).
 * i.e. only standard radio button selectable options
 */
export const timeframeOptionsValues =
  (Object.values(Timeframe).filter(val => !isNaN(Number(val)) && val !== -1 && val !== -2)).map(val => Number(val));

/**
 * Mapping from timeRange parameter to filter timeframe value.
 */
export const timeframeMap: { [key in string]: Timeframe } = {
  past1Hour: Timeframe.Past1Hour,
  past12Hour: Timeframe.Past12Hours,
  past24hours: Timeframe.Past24Hours,
  past7days: Timeframe.Past7Days,
  past30days: Timeframe.Past30Days,
  past60days: Timeframe.Past60Days,
  past90days: Timeframe.Past90Days,
  past180days: Timeframe.Past180Days,
  past365days: Timeframe.Past365Days,
  pastmonth: Timeframe.PastMonth,
  past3months: Timeframe.Past3Months,
  past6months: Timeframe.Past6Months,
  pastyear: Timeframe.PastYear,
  currentmonth: Timeframe.CurrentMonth,
  currentyear: Timeframe.CurrentYear,
  day: Timeframe.Day,
  week: Timeframe.Week,
  month: Timeframe.Month,
  quarter: Timeframe.Quarter,
  halfYear: Timeframe.HalfYear,
  year: Timeframe.Year,
  custom: Timeframe.Custom,
  noDate: Timeframe.NoDate,
  forever: Timeframe.Forever,
};

/**
 * Reverse mapping of above: from filter timeframe value to timeRange parameter.
 */
export const timeRangeParameterMap: { [key in Timeframe]: string } = {};
Object.keys(timeframeMap).forEach(key => timeRangeParameterMap[timeframeMap[key]] = key);

/**
 * Map timeframe values to HelixIntlService translate values.
 */
export const timeframeLabels = {
  [Timeframe.Past1Hour]: 'past1Hour',
  [Timeframe.Past12Hours]: 'past12Hours',
  [Timeframe.Past24Hours]: 'past24Hours',
  [Timeframe.Past7Days]: 'past7Days',
  [Timeframe.Past30Days]: 'past30Days',
  [Timeframe.Past60Days]: 'past60days',
  [Timeframe.Past90Days]: 'past90Days',
  [Timeframe.Past180Days]: 'past180Days',
  [Timeframe.Past365Days]: 'past365Days',
  [Timeframe.PastMonth]: 'pastMonth',
  [Timeframe.Past3Months]: 'past3Months',
  [Timeframe.Past6Months]: 'past6Months',
  [Timeframe.PastYear]: 'pastYear',
  [Timeframe.CurrentMonth]: 'currentMonth',
  [Timeframe.CurrentYear]: 'currentYear',
  [Timeframe.Day]: 'day',
  [Timeframe.Week]: 'week',
  [Timeframe.Month]: 'month',
  [Timeframe.Quarter]: 'quarter',
  [Timeframe.HalfYear]: 'halfYear',
  [Timeframe.Year]: 'year',
  [Timeframe.Custom]: 'custom',
  [Timeframe.NoDate]: 'noDate',
  [Timeframe.Forever]: 'forever',
};

/**
 * List of un-common Timeframes.
 */
export const UncommonTimeframes = [Timeframe.Custom, Timeframe.NoDate, Timeframe.Forever];

/**
 * Date range Input/Output interface object.
 */
export interface DateRange<D> {
  /**
   * The selected timeframe.
   */
  timeframe?: Timeframe;

  /**
   * The selected start date.
   */
  start: D;

  /**
   * The selected end date.
   */
  end: D;
}

/**
 * Get the date range having start & end dare of Moment type for a given timeframe.
 *
 * @deprecated moment.js is planned to be removed from the source code. Please use 'getDateRange' for obtaining the date
 * ranges. Note that the 'getDateRange' works with the native Date.
 * @param timeframe The timeframe.
 * @returns The date range having start & end date of Moment type.
 */
export const getMomentDateRange = (timeframe: Timeframe): DateRange<Moment> => {
  const now = moment();
  const bigBang = moment(0);
  const timeframeStr = Timeframe[timeframe];
  const result: DateRange<Moment> = {
    end: null,
    start: null,
    timeframe: timeframeStr ? timeframe : Timeframe.Custom,
  };

  switch (result.timeframe) {
    case Timeframe.Past1Hour:
      result.start = now.clone().subtract(1, 'h');
      result.end = now.clone();
      break;
    case Timeframe.Past12Hours:
      result.start = now.clone().subtract(12, 'h');
      result.end = now.clone();
      break;
    case Timeframe.Past24Hours:
      result.start = now.clone().subtract(1, 'd');
      result.end = now.clone();
      break;
    case Timeframe.Past7Days:
      result.start = now.clone().subtract(6, 'd').startOf('day');
      result.end = now.clone().endOf('day');
      break;
    case Timeframe.Past30Days:
      result.start = now.clone().subtract(29, 'd').startOf('day');
      result.end = now.clone().endOf('day');
      break;
    case Timeframe.Past60Days:
      result.start = now.clone().subtract(59, 'd').startOf('day');
      result.end = now.clone().endOf('day');
      break;
    case Timeframe.Past90Days:
      result.start = now.clone().subtract(89, 'd').startOf('day');
      result.end = now.clone().endOf('day');
      break;
    case Timeframe.Past180Days:
      result.start = now.clone().subtract(179, 'd').startOf('day');
      result.end = now.clone().endOf('day');
      break;
    case Timeframe.Past365Days:
      result.start = now.clone().subtract(364, 'd').startOf('day');
      result.end = now.clone().endOf('day');
      break;
    case Timeframe.PastMonth:
      result.start = now.clone().subtract(1, 'M').startOf('month');
      result.end = now.clone().subtract(1, 'M').endOf('month');
      break;
    case Timeframe.Past3Months:
      result.start = now.clone().subtract(3, 'M').startOf('month');
      result.end = now.clone().subtract(1, 'M').endOf('month');
      break;
    case Timeframe.Past6Months:
      result.start = now.clone().subtract(6, 'M').startOf('month');
      result.end = now.clone().subtract(1, 'M').endOf('month');
      break;
    case Timeframe.PastYear:
      result.start = now.clone().subtract(1, 'y').startOf('year');
      result.end = now.clone().subtract(1, 'y').endOf('year');
      break;
    case Timeframe.CurrentMonth:
      result.start = now.clone().startOf('month');
      result.end = now.clone().endOf('day');
      break;
    case Timeframe.CurrentYear:
      result.start = now.clone().startOf('year');
      result.end = now.clone().endOf('day');
      break;
    case Timeframe.Day:
      result.start = now.clone();
      result.end = now.clone();
      break;
    case Timeframe.Week:
      result.start = now.clone();
      result.end = now.clone().add(7, 'd');
      break;
    case Timeframe.Month:
      result.start = now.clone();
      result.end = now.clone().add(30, 'd');
      break;
    case Timeframe.Quarter:
      result.start = now.clone();
      result.end = now.clone().add(13, 'w');
      break;
    case Timeframe.HalfYear:
      result.start = now.clone();
      result.end = now.clone().add(6, 'M');
      break;
    case Timeframe.Year:
      result.start = now.clone();
      result.end = now.clone().add(1, 'y');
      break;
    case Timeframe.Custom:
      result.start = now.clone();
      result.end = now.clone();
      break;
    case Timeframe.NoDate:
      // Not leveraging date, set both to Epoch.
      result.start = bigBang.clone();
      result.end = bigBang.clone();
      break;
    case Timeframe.Forever:
      result.start = bigBang.clone();
      result.end = undefined;
      break;
  }

  return result;
};

/**
 * Get the date range having start & end dare of Native Date type for a given timeframe.
 *
 * @param timeframe The timeframe.
 * @returns The date range having start & end date of Native Date type.
 */
export const getDateRange = (timeframe: Timeframe): DateRange<Date> => {
  const dateRange = getMomentDateRange(timeframe);
  return { start: dateRange.start.toDate(), end: dateRange.end?.toDate(), timeframe: dateRange.timeframe };
};
