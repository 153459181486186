// COMPONENT: Clone View: Search controller

;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.cloneView';

  angular
    .module(moduleName)
    .controller('CloneViewSearchCtrl', cloneViewSearchCtrlFn);

  /**
   * cloneViewSearch Controller
   **************************************************************************/
  function cloneViewSearchCtrlFn($rootScope, $scope) {

    var defaultFilterProps;

    angular.extend($scope, {
      // GENERAL SCOPE VARS

      // TEXT STRINGS
      text: $rootScope.text.devopsCloneViewsViewSearchText,

      // ERRORS
      errorText: $rootScope.text.devopsCloneViewsViewSearch1ErrorText,
    });

    defaultFilterProps = [{
      display: 'protectionJob',
      property: 'jobIds',
      transformFn: jobIdFromName,
      locked: false,
      disabled: false
    }, {
      display: 'virtualMachine',
      property: 'entityIds',
      // TODO: Need this transformFn too
      transformFn: undefined,
      locked: false,
      disabled: false
    }, {
      display: 'viewBox',
      property: 'viewBoxIds',
      transformFn: viewBoxIdFromName,
      locked: false,
      disabled: false
    }, {
      display: 'source',
      property: 'registeredSourceIds',
      transformFn: sourceIdFromName,
      locked: false,
      disabled: false
    }, {
      primary: true,
      property: 'vmName'
    }];


    // METHODS
    /**
     * Initialize all the things!
     *
     * @method     cloneViewSearchInit
     */
    function cloneViewSearchInit() {
      $scope.shared.filters = angular.copy(defaultFilterProps);
    }

    /**
     * Transform a single, or an array of Source names to Source IDs
     *
     * @method     sourceIdFromName
     * @param      {object|array}  names   Array Source names
     * @return     {array}         Array of Source IDs
     */
    function sourceIdFromName(names) {
      if (names) {
        names = [].concat(names);
        return $scope.shared.filterLookups.registeredSourceIds
          .reduce(function matchSources(sources, source) {
            if (!!~names.indexOf(source.entity.vmwareEntity.name)) {
              sources.push(source.entity.id);
            }
            return sources;
          }, []);
      }
      return [];
    }

    /**
     * TransformFn for viewBox search filter
     *
     * @method     viewBoxIdFromName
     * @param      {object|array}  viewBoxes  The viewBox names to get the
     *                                        IDs for
     * @return     {array}         The viewBox ids
     */
    function viewBoxIdFromName(viewBoxes) {
      if (viewBoxes) {
        viewBoxes = [].concat(viewBoxes);
        return $scope.shared.filterLookups.viewBoxIds
          .reduce(function matchViewboxes(boxes, vb) {
            if (!!~viewBoxes.indexOf(vb.name)) {
              boxes.push(vb.id);
            }
            return boxes;
          }, []);
      }
      return [];
    }

    /**
     * Transform an array of Job names to Job IDs
     *
     * @method     jobIdFromName
     * @param      {Array}  names   Array of Job names
     * @return     {Array}          Array of Job IDs
     */
    function jobIdFromName(names) {
      if (names) {
        names = [].concat(names);
        return $scope.shared.filterLookups.jobIds
          .reduce(function matchJobs(jobs, job) {
            if (!!~names.indexOf(job.jobName)) {
              jobs.push(job.jobId);
            }
            return jobs;
          }, []);
      }
      return [];
    }


    // Initialize!
    cloneViewSearchInit();
  }

})(angular);
