import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { DataIdModule, IconModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';
import { TagPipesModule } from '../../pipes';
import { ColoredTagComponent } from './colored-tag.component';
import { ColoredTagsComponent } from './colored-tags/colored-tags.component';

const components = [ColoredTagComponent, ColoredTagsComponent];
@NgModule({
  declarations: components,
  exports: components,
  imports: [
    CommonModule,
    DataIdModule,
    IconModule,
    MatButtonModule,
    MatChipsModule,
    MatTooltipModule,
    TagPipesModule,
    TranslateModule,
  ],
})
export class ColoredTagModule {}
