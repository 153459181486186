/* eslint-disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CohesityApiSizerConfiguration, CohesityApiSizerConfigurationInterface } from './cohesity-api-sizer-configuration';

import { SimulatorService } from './services/simulator.service';

/**
 * Provider for all CohesityApiSizer services, plus CohesityApiSizerConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    CohesityApiSizerConfiguration,
    SimulatorService
  ],
})
export class CohesityApiSizerModule {
  static forRoot(customParams: CohesityApiSizerConfigurationInterface): ModuleWithProviders<CohesityApiSizerModule> {
    return {
      ngModule: CohesityApiSizerModule,
      providers: [
        {
          provide: CohesityApiSizerConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
