/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityKeplerApiConfiguration as __Configuration } from '../cohesity-kepler-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DrActivities } from '../models/dr-activities';
import { DrActivity } from '../models/dr-activity';
import { DrPlanRunResource } from '../models/dr-plan-run-resource';
@Injectable({
  providedIn: 'root',
})
class StatsService extends __BaseService {
  static readonly GetDrActivitiesPath = '/site-continuity/activities';
  static readonly GetDrActivityByIdPath = '/site-continuity/activities/{id}';
  static readonly GetDrObjectActivitiesByObjectIdPath = '/site-continuity/activities/{id}/object-activity/{objectId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets a list of DR Activity Log.
   *
   * Gets a list of DR Activity Log.
   * @param params The `StatsService.GetDrActivitiesParams` containing the following parameters:
   *
   * - `types`: If this is defined, only the activities which are of the specified types will be returned.
   *
   * - `toTimeUsecs`: Specifies the timestamp in Unix time epoch in microseconds to get Disater Recovery Activity logs. This filter will return the activites having finish time less than equal to toTimeUsecs.
   *
   * - `statuses`: If this is defined, only the activities which match the specified status values will be returned.
   *
   * - `orderByStartTime`: Specifies the order for DR plan runs that will be returned based on run start time.
   *
   * - `maxActivities`: Specifies the maximum number of activites to fetch in the response.
   *
   * - `lastRunOnly`: Specifies if the query should return only the last run.
   *
   * - `ids`: If this is defined, only the activities which match the specified IDs will be returned.
   *
   * - `fromTimeUsecs`: Specifies the timestamp in Unix time epoch in microseconds to get Disater Recovery Activity logs. This filter will return the activites having start time greater than eqaul to fromTimeUsecs.
   *
   * - `drPlanId`: Filter the activity response by DR plan id.
   *
   * @return Response of a successful get DR activites operation.
   */
  GetDrActivitiesResponse(params: StatsService.GetDrActivitiesParams): __Observable<__StrictHttpResponse<DrActivities>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.types || []).forEach(val => {if (val != null) __params = __params.append('types', val.toString())});
    if (params.toTimeUsecs != null) __params = __params.set('toTimeUsecs', params.toTimeUsecs.toString());
    (params.statuses || []).forEach(val => {if (val != null) __params = __params.append('statuses', val.toString())});
    if (params.orderByStartTime != null) __params = __params.set('orderByStartTime', params.orderByStartTime.toString());
    if (params.maxActivities != null) __params = __params.set('maxActivities', params.maxActivities.toString());
    if (params.lastRunOnly != null) __params = __params.set('lastRunOnly', params.lastRunOnly.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.fromTimeUsecs != null) __params = __params.set('fromTimeUsecs', params.fromTimeUsecs.toString());
    if (params.drPlanId != null) __params = __params.set('drPlanId', params.drPlanId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/site-continuity/activities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DrActivities>;
      })
    );
  }
  /**
   * Gets a list of DR Activity Log.
   *
   * Gets a list of DR Activity Log.
   * @param params The `StatsService.GetDrActivitiesParams` containing the following parameters:
   *
   * - `types`: If this is defined, only the activities which are of the specified types will be returned.
   *
   * - `toTimeUsecs`: Specifies the timestamp in Unix time epoch in microseconds to get Disater Recovery Activity logs. This filter will return the activites having finish time less than equal to toTimeUsecs.
   *
   * - `statuses`: If this is defined, only the activities which match the specified status values will be returned.
   *
   * - `orderByStartTime`: Specifies the order for DR plan runs that will be returned based on run start time.
   *
   * - `maxActivities`: Specifies the maximum number of activites to fetch in the response.
   *
   * - `lastRunOnly`: Specifies if the query should return only the last run.
   *
   * - `ids`: If this is defined, only the activities which match the specified IDs will be returned.
   *
   * - `fromTimeUsecs`: Specifies the timestamp in Unix time epoch in microseconds to get Disater Recovery Activity logs. This filter will return the activites having start time greater than eqaul to fromTimeUsecs.
   *
   * - `drPlanId`: Filter the activity response by DR plan id.
   *
   * @return Response of a successful get DR activites operation.
   */
  GetDrActivities(params: StatsService.GetDrActivitiesParams): __Observable<DrActivities> {
    return this.GetDrActivitiesResponse(params).pipe(
      __map(_r => _r.body as DrActivities)
    );
  }

  /**
   * Get a DR Activity by ID
   *
   * Get a DR Activity by ID.
   * @param id Specifies the ID of the DR Activity.
   * @return Response of a successful get DR activity operation by ID.
   */
  GetDrActivityByIdResponse(id: number): __Observable<__StrictHttpResponse<DrActivity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/site-continuity/activities/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DrActivity>;
      })
    );
  }
  /**
   * Get a DR Activity by ID
   *
   * Get a DR Activity by ID.
   * @param id Specifies the ID of the DR Activity.
   * @return Response of a successful get DR activity operation by ID.
   */
  GetDrActivityById(id: number): __Observable<DrActivity> {
    return this.GetDrActivityByIdResponse(id).pipe(
      __map(_r => _r.body as DrActivity)
    );
  }

  /**
   * Get an Object's Activity within the DR Activity.
   *
   * Gets an Object's Activity within the DR Activity.
   * @param params The `StatsService.GetDrObjectActivitiesByObjectIdParams` containing the following parameters:
   *
   * - `objectId`: Specifies the ID of the object.
   *
   * - `id`: Specifies the ID of the DR Activity.
   *
   * @return Response of a successful get DR Object activity operation by id.
   */
  GetDrObjectActivitiesByObjectIdResponse(params: StatsService.GetDrObjectActivitiesByObjectIdParams): __Observable<__StrictHttpResponse<DrPlanRunResource>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/site-continuity/activities/${encodeURIComponent(params.id)}/object-activity/${encodeURIComponent(params.objectId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DrPlanRunResource>;
      })
    );
  }
  /**
   * Get an Object's Activity within the DR Activity.
   *
   * Gets an Object's Activity within the DR Activity.
   * @param params The `StatsService.GetDrObjectActivitiesByObjectIdParams` containing the following parameters:
   *
   * - `objectId`: Specifies the ID of the object.
   *
   * - `id`: Specifies the ID of the DR Activity.
   *
   * @return Response of a successful get DR Object activity operation by id.
   */
  GetDrObjectActivitiesByObjectId(params: StatsService.GetDrObjectActivitiesByObjectIdParams): __Observable<DrPlanRunResource> {
    return this.GetDrObjectActivitiesByObjectIdResponse(params).pipe(
      __map(_r => _r.body as DrPlanRunResource)
    );
  }
}

module StatsService {

  /**
   * Parameters for GetDrActivities
   */
  export interface GetDrActivitiesParams {

    /**
     * If this is defined, only the activities which are of the specified types will be returned.
     */
    types?: Array<'PreFailback' | 'Failback' | 'PreFailover' | 'Failover' | 'TestFailover' | 'PlannedFailover' | 'PlannedFailback'>;

    /**
     * Specifies the timestamp in Unix time epoch in microseconds to get Disater Recovery Activity logs. This filter will return the activites having finish time less than equal to toTimeUsecs.
     */
    toTimeUsecs?: number;

    /**
     * If this is defined, only the activities which match the specified status values will be returned.
     */
    statuses?: Array<'Running' | 'Completed' | 'PartiallyCompleted' | 'Failed' | 'Canceled' | 'Canceling' | 'Deleted' | 'Deleting' | 'DeletionFailed'>;

    /**
     * Specifies the order for DR plan runs that will be returned based on run start time.
     */
    orderByStartTime?: 'asc' | 'desc';

    /**
     * Specifies the maximum number of activites to fetch in the response.
     */
    maxActivities?: number;

    /**
     * Specifies if the query should return only the last run.
     */
    lastRunOnly?: boolean;

    /**
     * If this is defined, only the activities which match the specified IDs will be returned.
     */
    ids?: Array<number>;

    /**
     * Specifies the timestamp in Unix time epoch in microseconds to get Disater Recovery Activity logs. This filter will return the activites having start time greater than eqaul to fromTimeUsecs.
     */
    fromTimeUsecs?: number;

    /**
     * Filter the activity response by DR plan id.
     */
    drPlanId?: number;
  }

  /**
   * Parameters for GetDrObjectActivitiesByObjectId
   */
  export interface GetDrObjectActivitiesByObjectIdParams {

    /**
     * Specifies the ID of the object.
     */
    objectId: number;

    /**
     * Specifies the ID of the DR Activity.
     */
    id: number;
  }
}

export { StatsService }
