// Job name directive
//
// This directive can be used to output a job name.
// The name will be linked to the details page for the job
// if it has a lastJobRun object. Otherwise it will just be text
// The name will have a strickthrough if the job has been deleted.
//
// How to use, where job is a scope object $scope.job that represents
// the return from at least one job API (see below):
// <job-name job="job"></job>
//
// Output:
// see job-name.html
//
// This directive assumes a combined object from
// two API calls: getJobs & getJobSummary, data structure
// should remain as returned from API. Having both sets of
// data is not required, but will make this more functional.
// For instance, lastJobRun is only returned from getJobSummary
//

angular.module('C.jobName', [])
    .directive('jobName', ['$rootScope',
        function($rootScope) {
            return {
                restrict: 'E',
                scope: {
                    job: '=job'
                },
                templateUrl: 'app/global/job-name/job-name.html',
                link: function(scope, elem, attrs) {
                    scope.text = $rootScope.text.globalJobNameJobName;

                    // determine values to be used in the template.
                    // the source of the values depends on if scope.job
                    // includes data from getJobs, getJobSummary, or both.

                    if (scope.job.backupJob) {
                        // data comes from getJobs
                        scope.jobId = scope.job.backupJob.jobId;
                        scope.jobName = scope.job.backupJob.name;
                    }

                    // not an else if, as additional data points are
                    // available (deletionStatus) if this object is provided
                    if (scope.job.backupJobSummary) {
                        // data comes from getJobSummary
                        scope.jobId = scope.job.backupJobSummary.jobDescription.jobId;
                        scope.jobName = scope.job.backupJobSummary.jobDescription.name;
                    }

                }
            };
        }
    ]);
