import { AppModuleConfig, CanAccess, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';
import { Environment } from 'src/app/shared/constants';
import { hasSourceModifyPrivilege } from 'src/app/shared/helper-utils';

const physicalFilesModify = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngProtectionGroupGlobalModify &&
  ctx.FEATURE_FLAGS.ngProtectionGroupPhysicalFilesModify &&
  ctx.PROTECTION_MODIFY;

const physicalBlockModify = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngProtectionGroupGlobalModify &&
  ctx.FEATURE_FLAGS.ngProtectionGroupPhysicalBlockModify &&
  ctx.PROTECTION_MODIFY;

// Removing the preceding `k` character to make it compatible with hasSourceModifyPrivilege().
const key = Environment.kPhysical.slice(1).toLowerCase();
const modifyAccess: CanAccess = (ctx) => hasSourceModifyPrivilege(ctx, key);

const physicalServerModify = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngProtectionGroupGlobalModify &&
  modifyAccess && ctx.workflow.backupAndRecovery.kPhysical &&
  ctx.FEATURE_FLAGS.ngRegisterPhysicalDialogEnabled;

export const PhysicalConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'protection-builder-physical-files.**',
      url: '/protection/protect/physical-files',
      loadChildren: () => import('./physical.module').then(m => m.PhysicalModule),
    },
    {
      name: 'protection-builder-physical-block.**',
      url: '/protection/protect/physical-block',
      loadChildren: () => import('./physical.module').then(m => m.PhysicalModule),
    },
    {
      name: 'physicalServer.**',
      url: '/protection/protect/register',
      loadChildren: () => import('./physical.module').then(m => m.PhysicalModule),
    }
  ],
  allClusterMap: {
    'protection-builder-physical-files.create': true,
    'protection-builder-physical-files.edit': true,
    'protection-builder-physical-files.edit-object': true
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'protection-builder-physical-files.create': physicalFilesModify,
      'protection-builder-physical-files.edit': physicalFilesModify,
      'protection-builder-physical-block.create': physicalBlockModify,
      'protection-builder-physical-block.edit': physicalBlockModify,
      'protection-builder-physical-files.edit-object': physicalFilesModify,
      'physicalServer.new': physicalServerModify,
      'physicalServer.edit': physicalServerModify,
    };
  },
};
