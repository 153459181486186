import { Component, Input } from '@angular/core';
import { ColumnConfiguration } from '../list-configuration.model';

/**
 * Column of List Item Component.
 */
@Component({
  selector: 'coh-list-item-column',
  templateUrl: './list-item-column.component.html',
  styleUrls: ['./list-item-column.component.scss'],
})
export class ListItemColumnComponent<ListItem> {
  /** List item data. */
  @Input() row: ListItem;

  /** List item column configuration. */
  @Input() config: ColumnConfiguration;

  /**
   * Column value.
   */
  get columnValue(): any {
    return this.row[this.config.name];
  }

  /**
   * Returns true if column value has multiple values.
   */
  get hasMultipleValues(): boolean {
    // Don't show array content if type is arrayLength.
    if (this.config.type === 'arrayLength') {
      return false;
    }
    return Array.isArray(this.columnValue) && this.columnValue.length > 1;
  }

  /**
   * Display value of column.
   */
  get displayValue(): any {
    if (this.config.durationStartTime) {
      // Returns duration in ms.
      if (this.columnValue && this.columnValue > 0) {
        return Math.round(((this.columnValue || 0) - (this.row[this.config.durationStartTime] || 0)) / 1000);
      }
      return null;
    }
    if (this.hasMultipleValues) {
      return this.columnValue[0];
    }
    if (Array.isArray(this.columnValue) && this.columnValue.length === 0) {
      return null;
    }
    return this.columnValue;
  }

  /**
   * Parameters for URL.
   * TODO: add other configurable parameters if needed.
   */
  get params(): any {
    if (this.config.state) {
      if (this.config.paramsMap) {
        const result = {};

        Object.entries(this.config.paramsMap).forEach(([key, value]) => result[value] = this.row[key]);
        return result;
      }
      return { id: this.row['id'] };
    }
    return null;
  }

  /**
   * Get icon string from column value.
   *
   * @param    value  column value.
   * @returns  Icon string.
   */
  getIcon(value?: any): any {
    if (this.config.icon) {
      if (value) {
        return value[this.config.icon];
      }
      if (this.hasMultipleValues) {
        return this.displayValue[this.config.icon];
      }
      return this.row[this.config.icon];
    }
    return null;
  }
}
