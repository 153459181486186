import { NgModule } from '@angular/core';
import { SharedCoreModule } from '@cohesity/shared/core';

import { UserPanelComponent } from './user-panel.component';

const components = [UserPanelComponent];

@NgModule({
  imports: [SharedCoreModule],
  declarations: [components],
  exports: [components],
})
export class UserPanelModule {}
