import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { IrisContextService } from '@cohesity/iris-core';
import { Environment } from '@cohesity/iris-shared-constants';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AnomalyAlert, FileRecoveryMetadata, SnapshotActionConfirmationParams } from './../security-shared.models';
import {
  AnomalyResolveConfirmationComponent,
} from './anomaly-resolve-confirmation/anomaly-resolve-confirmation.component';
import {
  AntiRansomwareSettingsComponent,
  AntiRasnomwareSettingsDialogInput,
} from './anti-ransomware-settings/anti-ransomware-settings.component';
import { DefaultRecoverySettingsComponent, DefaultRecoverySettingsDialogInput } from './default-recovery-settings';
import {
  IgnoreConfirmationComponent,
} from './ignore-dialog/ignore-confirmation.component';
import { RecoveryConfirmationComponent } from './recovery-confirmation/recovery-confirmation.component';
import {
  RecoverySnapshotSelectorComponent,
  RecoverySnapshotSelectorDialogInput,
} from './recovery-snapshot-selector-dialog/recovery-snapshot-selector.component';
import {
  SnapshotActionConfirmationComponent,
} from './snapshot-action-confirmation/snapshot-action-confirmation.component';

@Injectable({
  providedIn: 'root',
})
export class SecurityDialogService {
  constructor(private dialog: MatDialog, private irisCtx: IrisContextService) {}

  /**
   * Launch the dialog box for security rule
   *
   * @returns Mat dialog ref
   */
  launchSecurityRuleDialog(
    data?: AntiRasnomwareSettingsDialogInput
  ): MatDialogRef<AntiRansomwareSettingsComponent> {
    return this.dialog.open(AntiRansomwareSettingsComponent, { data });
  }

  /**
   * Launch the confirmation dialog to start the recovery.
   *
   * @returns The observable emitting confirmation or rejection status.
   */
  launchRecoveryConfirmationDialog(): Observable<any> {
    const dialogRef = this.dialog.open<RecoveryConfirmationComponent, void, any>(RecoveryConfirmationComponent, {});
    return dialogRef.afterClosed().pipe(filter(Boolean));
  }

  /**
   * Launch the recovery settings dialog.
   *
   * @returns The observable with dialog resolution status.
   */
  launchRecoverySettingsDialog(environments?: Environment[]): MatDialogRef<DefaultRecoverySettingsComponent> {
    return this.dialog.open<DefaultRecoverySettingsComponent, DefaultRecoverySettingsDialogInput, any>(
      DefaultRecoverySettingsComponent,
      {
        data: { environments },
        maxWidth: 'unset',
        width: '30rem',
      }
    );
  }

  /**
   * Launch the confirmation dialog to ignore the anomaly.
   *
   * @param alertId The alert id.
   * @returns The observable emitting confirmation or rejection status.
   */
  launchIgnoreConfirmationDialog(anomaly: AnomalyAlert): Observable<any> {
    const dialogRef = this.dialog.open<IgnoreConfirmationComponent, AnomalyAlert, any>(
      IgnoreConfirmationComponent,
      { width: '30rem', data: anomaly }
    );
    return dialogRef.afterClosed().pipe(filter(Boolean));
  }

  /**
   * Launch the dialog for selecting recovery point.
   *
   * @param showCredentials Indicates whether to show credentials fields or not.
   * @param object The original location of the object.
   * @param jobStartTimeUsecs The backup jobs start time.
   * @param cleanDataPoints The available clean snapshots.
   * @returns The observable emitting selected recovery point.
   */
  launchRecoverySnapshotSelectorDialog(
    showCredentials: boolean,
    object: string,
    jobStartTimeUsecs: string,
    cleanDataPoints: string[]
  ): Observable<FileRecoveryMetadata> {
    const dialogRef = this.dialog.open<
      RecoverySnapshotSelectorComponent,
      RecoverySnapshotSelectorDialogInput,
      FileRecoveryMetadata
      >(RecoverySnapshotSelectorComponent, { data: { showCredentials, object, jobStartTimeUsecs, cleanDataPoints } });
    return dialogRef.afterClosed().pipe(filter(dialogMetadata => !!dialogMetadata));
  }

  /**
   * Launch the dialog for snapshot action
   *
   * @param dialogData required params for dialog
   * @returns The observable emitting confirmation/cancel status.
   */
  launchSnapshotActionDialog(dialogData: SnapshotActionConfirmationParams): Observable<boolean> {
    const dialogRef = this.dialog.open<SnapshotActionConfirmationComponent, SnapshotActionConfirmationParams, boolean>(
      SnapshotActionConfirmationComponent,
      { width: '30rem', data: dialogData }
    );
    return dialogRef.afterClosed().pipe(filter(confirmed => confirmed));
  }

  /**
   * Launch the dialog for anomaly resolution
   *
   * @param anomalies Anomaly list
   * @returns The observable emitting resolve/cancel status.
   */
  launchAnomalyResolveDialog(anomalies: AnomalyAlert[]): Observable<boolean> {
    const dialogRef = this.dialog.open<AnomalyResolveConfirmationComponent, AnomalyAlert[], boolean>(
      AnomalyResolveConfirmationComponent,
      { data: anomalies }
    );
    return dialogRef.afterClosed().pipe(filter(confirmed => confirmed));
  }
}
