// Service: MCM(aka Helios) protection job API services.

;(function(angular, undefined) {
  'use strict';

  angular.module('C.mcmJobService', ['C.mcmJobServiceFormatter'])
    .service('McmJobService', McmJobServiceFn);

  function McmJobServiceFn(API, $http, McmJobServiceFormatter) {

    return {
      getJobs: getJobs,
    };

    /**
     * Get the list of protection jobs info.
     *
     * @method   getJobs
     * @param    {object}   [params={}]   The parameters {
     *    clusterUids:  {Array[String]}   filter by provided mcm cluster uuids.
     *                                    <clusterId:clusterIncarnationId>
     *    searchString: {String}          Search by job name text string.
     * }
     * @return   {object}   Promise resolved with protection jobs else rejected
     *                      with error.
     */
    function getJobs(params) {
      params = params || {};
      return $http({
        method: 'get',
        url: API.mcm('protectionJobs'),
        params: params,
      }).then(
        function getJobsSuccess(resp) {
          return (resp.data || []).map(McmJobServiceFormatter.transformJob);
        }
      );
    }

  }

}(angular));
