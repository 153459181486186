import { ChangeDetectionStrategy, Component, OnChanges, SimpleChanges } from '@angular/core';
import { BooleanConfig, BaseFormFieldDirective } from '../../models/index';

/**
 * Render a boolean toggle on the UI screen
 *
 * @example
 * <cog-boolean-form-field [label]="label" ...></cog-boolean-form-field>
 */
@Component({
  selector: 'cog-boolean-form-field',
  templateUrl: './boolean-form-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BooleanFormFieldComponent extends BaseFormFieldDirective<BooleanConfig> implements OnChanges {

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config || changes.control) {

      // This component could have been reset. Upon re-init, if the control has
      // no value assigned to it, and a default value exists in it's config,
      // assign that to control.
      if ((typeof this.control.value !== 'boolean') && (typeof this.config?.defaultValue === 'boolean')) {
        this.control.setValue(this.config.defaultValue);
      }
      this.control.updateValueAndValidity();
    }
  }
}
