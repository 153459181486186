import { Pipe, PipeTransform } from '@angular/core';
import { ByteSizeService } from '@cohesity/helix';
import { HumanizeFromNumbersPipe } from '@cohesity/utils';
import { isNil } from 'lodash';

import { DataPoint, MetricType, MetricTypeValue, MetricTypeValueType } from './../../security-shared.models';


/**
 * Normalizing numeric value if less than 0
 *
 * @param value numeric value
 */
const normalizeMetricValue = (value: number) => value <= 0 ? 0 : value;

/**
 * This pipe returns transformed metric value.
 */
@Pipe({
  name: 'metricValue',
  pure: true,
})
export class MetricValuePipe implements PipeTransform {
  constructor(
    private byteSizeService: ByteSizeService,
    private humanizeFromNumbersPipe: HumanizeFromNumbersPipe,
  ) {}

  /**
   * Return the transformed metric value.
   *
   * @param metricType The metric type.
   * @param dataPoint The dataPoint containing the metric value.
   * @returns The transformed metric value.
   */
  transform(metricType: MetricType | string, dataPoint: DataPoint): string | null {
    const metricValue = dataPoint?.[metricType];

    if (!metricType || isNil(metricValue) || isNaN(metricValue)) {
      return '-';
    }

    const normalizeValue = normalizeMetricValue(metricValue);

    switch (MetricTypeValue[metricType]) {
      case MetricTypeValueType.bytes: {
        return this.byteSizeService.bytesToSize(normalizeValue).displayValue;
      }
      case MetricTypeValueType.humanizeFromNumber: {
        return this.humanizeFromNumbersPipe.transform(normalizeValue);
      }
      case MetricTypeValueType.number:
      default: {
        return normalizeValue.toString();
      }
    }
  }
}
