import { DrActivity, DrActivityStatusDetails, DrPlan } from '@cohesity/api/kepler';

/**
 * Dr Plan execution status type.
 */
export const DrPlanExecutionStatus: DrPlan['status'][] = [
  'Activating',
  'FailbackDone',
  'FailbackFailed',
  'FailbackReady',
  'FailbackRunning',
  'FailoverDone',
  'FailoverFailed',
  'FailoverReady',
  'FailoverRunning',
  'NotReady',
  'PrepareFailbackFailed',
  'PrepareFailbackRunning',
  'PrepareFailoverFailed',
  'PrepareFailoverRunning',
  'ReplicatingForFailback',
  'ReplicatingForFailover',
  'ReplicationForFailbackFailed',
  'ReplicationForFailbackScheduled',
  'ReplicationForFailoverFailed',
  'ReplicationForFailoverScheduled',
  'Syncing',
  'TeardownFailed',
  'TeardownReady',
  'TeardownRunning',
];

/**
 * Disaster Recovery execution status icon map.
 */
export const DrExecutionStatusIconsMap: Record<DrPlan['status'], string> = {
  Activating: 'helix:dr-activating',
  FailbackDone: 'helix:failback-success',
  FailbackFailed: 'helix:failback-error',
  FailbackPartiallyDone: 'helix:failback-partial',
  FailbackReady: 'helix:failback-protected',
  FailbackRunning: 'helix:failback-running',
  FailoverDone: 'helix:failover-success',
  FailoverFailed: 'helix:failover-error',
  FailoverPartiallyDone: 'helix:failover-partial',
  FailoverReady: 'helix:failover-protected',
  FailoverRunning: 'helix:failover-running',
  NotReady: 'helix:failover-not-ready',
  PlannedFailbackCanceling: 'helix:failback-cancel',
  PlannedFailbackCancelationFailed: 'helix:failback-error',
  PlannedFailbackFailed: 'helix:failback-error',
  PlannedFailbackFinalizationFailed: 'helix:failback-error',
  PlannedFailbackFinalizing: 'helix:failback-running',
  PlannedFailbackPartiallyDone: 'helix:failback-partial',
  PlannedFailbackRunning: 'helix:failback-running',
  PlannedFailoverCanceling: 'helix:failover-cancel',
  PlannedFailoverCancelationFailed: 'helix:failover-error',
  PlannedFailoverFailed: 'helix:failover-error',
  PlannedFailoverFinalizationFailed: 'helix:failover-error',
  PlannedFailoverFinalizing: 'helix:failover-running',
  PlannedFailoverPartiallyDone: 'helix:failover-partial',
  PlannedFailoverRunning: 'helix:failover-running',
  PrepareFailbackFailed: 'helix:prepare-failback-error',
  PrepareFailbackRunning: 'helix:prepare-failback-running',
  PrepareFailoverFailed: 'helix:prepare-failover-failed',
  PrepareFailoverRunning: 'helix:prepare-failover-running',
  ReplicatingForFailback: 'helix:replication-running',
  ReplicatingForFailover: 'helix:replication-running',
  ReplicationForFailbackFailed: 'helix:replication-error',
  ReplicationForFailbackScheduled: 'helix:replication-scheduled',
  ReplicationForFailoverFailed: 'helix:replication-error',
  ReplicationForFailoverScheduled: 'helix:replication-scheduled',
  StandbyFailoverDone: 'helix:failover-success',
  Syncing: 'helix:failover-running',
  TeardownFailed: 'helix:teardown-error',
  TeardownReady: 'helix:teardown-protected',
  TeardownRunning: 'helix:teardown-running',
};

/**
 * List of DR execution statuses that are in progress.
 */
export const DrExecutionInProgressStatuses: DrPlan['status'][] = [
  'Activating',
  'FailbackRunning',
  'FailoverRunning',
  'PlannedFailbackCanceling',
  'PlannedFailbackFinalizing',
  'PlannedFailbackRunning',
  'PlannedFailoverCanceling',
  'PlannedFailoverFinalizing',
  'PlannedFailoverRunning',
  'PrepareFailbackRunning',
  'PrepareFailoverRunning',
  'ReplicatingForFailback',
  'ReplicatingForFailover',
  'Syncing',
  'TeardownRunning',
];

/**
 * List of DR execution statuses which decide whether the plan can be torn
 * down.
 */
export const TearableDrPlanStatuses: DrPlan['status'][] = [
  'TeardownReady',
  'FailoverFailed',
  'PrepareFailbackFailed',
  'FailbackFailed',
  'TeardownFailed',
];

/**
 * List of tearable execution statuses specific for View DR.
 */
export const TearableDrPlanStatusesForViews: DrPlan['status'][] = [
  'FailbackFailed',
  'FailoverFailed',
  'PlannedFailbackFailed',
  'PlannedFailbackFinalizationFailed',
  'PlannedFailoverFailed',
  'PlannedFailoverFinalizationFailed',
  'TeardownFailed',
];

/**
 * List of DR execution statuses that are in progress.
 */
export const DrExecutionErrorStatuses: DrPlan['status'][] = [
  'FailbackFailed',
  'FailoverFailed',
  'NotReady',
  'PrepareFailbackFailed',
  'PrepareFailoverFailed',
  'ReplicationForFailbackFailed',
  'ReplicationForFailoverFailed',
  'TeardownFailed',
];

/**
 * Dr Plan activity status definition.
 */
export const DrPlanActivityStatuses: DrActivityStatusDetails['status'][] = [
  'Running',
  'Completed',
  'Failed',
  'Canceled',
  'Canceling',
  'Deleted',
  'Deleting',
  'DeletionFailed',
];

/**
 * Dr Plan exection statuses for which we can show the progress tooltip.
 */
export const DrExecutionStatusesWithProgressTooltip: DrPlan['status'][] = [
  'ReplicatingForFailover',
  'ReplicatingForFailback',
];

/**
 * Map of running, failed, scheduled replication DR Execution statuses.
 */
export const DrExecutionReplicationStatuses: Record<string, DrPlan['status'][]> = {
  running: ['ReplicatingForFailover', 'ReplicatingForFailback'],
  failed: ['ReplicationForFailoverFailed', 'ReplicationForFailbackFailed'],
  scheduled: ['ReplicationForFailoverScheduled', 'ReplicationForFailbackScheduled'],
};

/**
 * List of deletable execution statuses specific for View DR.
 */
export const DrExecutionDeletableStatusesForViews: DrPlan['status'][] = [
  'FailbackDone',
  'FailbackReady',
  'FailoverDone',
  'FailoverReady',
  'PrepareFailbackFailed',
  'PrepareFailoverFailed',
  'ReplicationForFailbackFailed',
  'ReplicationForFailoverFailed',
  'TeardownFailed',
];

/**
 * Partial DR Plan failover statuses.
 */
export const DrPlanPartialFailoverStatus: DrPlan['status'][] = [
  'FailoverPartiallyDone',
  'PlannedFailoverPartiallyDone'
];

/**
 * Partial DR Plan failback statuses.
 */
export const DrPlanPartialFailbackStatus: DrPlan['status'][] = [
  'FailbackPartiallyDone',
  'PlannedFailbackPartiallyDone'
];

/**
 * Partial DR Plan execution statuses.
 */
export const DrPlanPartialExecutionStatus: DrPlan['status'][] = [
  ...DrPlanPartialFailoverStatus,
  ...DrPlanPartialFailbackStatus,
];

/**
 * List of editable statuses for a DR plan.
 */
export const EditableDrPlanStatuses: DrPlan['status'][] = [
  'NotReady',
  'FailbackReady',
  'FailoverReady',
  'Syncing',
];

/**
 * List of actity types that corresponds to Failback.
 */
export const FailbackActivityTypes: DrActivity['type'][] = [
  'PreFailback',
  'Failback',
  'PlannedFailback',
];
