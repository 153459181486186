import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[cogStepper]'
})
export class StepperDirective implements AfterViewInit {

  constructor(private renderer: Renderer2, private el: ElementRef) { }

  /**
   * AfterViewInit lifecycle hook
   *
   * Get horizontal stepper header and set display to none
   */
  ngAfterViewInit() {
    const horizontalHeaderElement = this.el.nativeElement.querySelector('.mat-horizontal-stepper-header-container');
    if (horizontalHeaderElement) {
      this.renderer.setStyle(horizontalHeaderElement, 'display', 'none');
    }
  }
}
