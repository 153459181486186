import { Injectable } from '@angular/core';
import { ProtectionSourceNode, ProtectionSourcesServiceApi } from '@cohesity/api/v1';
import { IrisContextService } from '@cohesity/iris-core';
import { Environment } from '@cohesity/iris-shared-constants';
import { SourceTreeDataProvider } from '@cohesity/iris-source-tree';
import { Observable } from 'rxjs';
import { PassthroughOptionsService } from 'src/app/core/services';

@Injectable()
export class PhysicalFilesSourceDataProvider implements SourceTreeDataProvider<ProtectionSourceNode, any> {
  /**
   * Page size to use for loading the source tree.
   */
  private pageSize = 15000;

  constructor(
    readonly sourceService: ProtectionSourcesServiceApi,
    readonly irisContext: IrisContextService,
    readonly passthroughOptionsService: PassthroughOptionsService,
  ) {}

  /**
   *
   * Returns the PhysicalFiles source hierarchy for the given source Id.
   *
   * @param sourceId            The parent id to load the tree for.
   * @param environment         The environment to loadd the tree for.
   * @param allUnderHierarchy   Whether to include all objects all nodes a user has access to
   * @returns An observable with the tree data of size pageSize.
   */
  fetchTree(
    sourceId: number,
    environment: Environment,
    allUnderHierarchy?: boolean
  ): Observable<ProtectionSourceNode[]> {

    return this.sourceService
      .ListProtectionSources({
        id: sourceId,
        nodeId: sourceId,
        allUnderHierarchy: allUnderHierarchy || false,
        pageSize: this.pageSize,
        ...this.passthroughOptionsService.requestParams,
      });
  }
}
