import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataIdDirective } from './data-id.directive';

@NgModule({
  exports: [DataIdDirective],
  imports: [
    CommonModule,
    DataIdDirective
  ]
})
export class DataIdModule { }
