<ul *ngIf="storageDomain" class="c-ul-inline meta-data synopsis">
  <!-- In order to show this element, `encryptionType` must be defined but
      not equal to 'None' -->
      <li *ngIf="storageDomain.storagePolicy.encryptionType &&
      storageDomain.storagePolicy.encryptionType !== 'None'">
      {{'encryptColon' | translate}} {{storageDomain | ngViewBoxEncryption}}
    </li>

  <!-- Storage domain name -->
  <li *ngIf="showName">{{ storageDomain.name }}</li>

  <!-- Storage domain deduplication value -->
  <li *ngIf="storageDomain.storagePolicy.deduplicationParams.enabled">
    {{'dedupeColon' | translate}} {{storageDomain | viewBoxDedupeStatus}}
  </li>

  <!-- In order to show this element, `compressionPolicy` must be defined but
      not equal to 'None' -->
  <li *ngIf="storageDomain.storagePolicy?.compressionParams?.type &&
    storageDomain.storagePolicy.compressionParams.type !== 'None'">
    {{'compColon' | translate}} {{storageDomain | viewBoxCompression}}
  </li>

  <li *ngIf="storageDomain.tenantIds?.length">
    {{'organizationColon' | translate}} {{storageDomain.tenantIds[0]}}
  </li>
</ul>
