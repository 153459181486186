import { Component, EventEmitter, HostBinding, HostListener, Input, Output, TemplateRef } from '@angular/core';
import { OPEN_CLOSE_ANIMATION } from '../../../animations/index';

import { EventTrackingService } from '../../../event-tracking.service';
import { HelixIntlService } from '../../../helix-intl.service';
import { SettingsListComponent } from '../settings-list.component';

/**
 * Used inside of aSettingsListComponent to display a single setting (or group of related settings) and allow for
 * editing the related values.
 *
 * See demo application for an implementation example.
 */
@Component({
  selector: 'cog-settings-list-item',
  templateUrl: './settings-list-item.component.html',
  styleUrls: ['./settings-list-item.component.scss'],
  animations: [
    OPEN_CLOSE_ANIMATION,
  ],
})
export class SettingsListItemComponent {
  /**
   * Whether the current settings list item is invalid.
   */
  private _invalid: boolean;

  /**
   * Sets the the invalidity value of the current settings list item
   */
  @Input() set invalid(value: boolean) {
    this._invalid = value;
    if (this._invalid) {
      this.editMode = true;
    }
  }

  /**
   * Returns the current status of invalidity of the settings list item
   */
  get invalid(): boolean {
    return this._invalid;
  }

  /**
   * Label to display for the settings list item.
   */
  @Input() label: string;

  /**
   * Detailed description of the setting list item.
   */
  @Input() description?: string;

  /**
   * Reset to default link. Optional.
   */
  @Input() resetToDefault?: string;

  /**
   * Template to render the label  of the settings list item.
   */
  @Input() labelTemplate: TemplateRef<any>;

  /**
   * Optional action icon string which appears adjacent to delete button in view mode.
   */
  @Input() actionIcon: string;

  /**
   * Layout for column display.
   */
  @Input() columnLayout = false;

  /**
   * Optional setting to make readonly for settings list item.
   */
  @Input() isReadOnly: boolean;

  @Input() valueChanged: boolean;

  /**
   * Flag to determine width of the label.
   */
  @Input() labelWidth: 'sm' | 'md' | 'lg' = 'md';

  /**
   * Output event emitter to emit event on click of action icon.
   */
  @Output() actionClicked = new EventEmitter();

  /**
   * Output event emitter to emit reset to default values action.
   */
  @Output() resetAction = new EventEmitter();

  /**
   * Output event emitter to emit the value of the edit mode.
   */
  @Output() editModeChanged = new EventEmitter<boolean>();

  /**
   * To be set by parent SettingsListComponent if it is set to alwaysEdit mode.
   */
  get alwaysEdit(): boolean {
    return this.settingsList.alwaysEdit;
  }

  /**
   * Cache value for whether the component is in edit mode or not.
   */
  private _editMode = false;

  /**
   * Class binding and getter for editMode:boolean
   */
  @HostBinding('class.is-editing')
  get editMode(): boolean {
    return !this.readOnly && (this.alwaysEdit || this.invalid || this._editMode);
  }

  /**
   * Setter for editMode.
   */
  set editMode(newVal: boolean) {
    this._editMode = newVal;
  }

  /**
   * Class binding and getter for readOnly
   */
  @HostBinding('class.read-only')
  get readOnly(): boolean {
    return this.settingsList.readOnly || this.isReadOnly;
  }

  /**
   * HostListener to toggle visibility. This should open the edit panel when
   * clicking anywhere in the closed/view state. Otherwise it does nothing,
   * though this logic is handled in toggleEditMode() based on event parameter.
   */
  @HostListener('click') onClick() {
    if (!this.readOnly) {
      this.toggleEditMode();
    }
  }

  /**
   * Setter to open in Edit Mode by default.
   */
  @Input() set firstOpen(newVal: boolean) {
    this.editMode = newVal;
  }

  /**
   * Toggles editMode:boolean value.
   */
  toggleEditMode(event?: MouseEvent) {
    if (event) {
      // This was a click event registered in the template. Stop propagation so
      // the hostlistener doesn't undo the toggle.
      event.stopPropagation();
      event.stopImmediatePropagation();
    } else if (this.editMode) {
      // No event was passed so this came from the HostListener. Since user is
      // already editting, do nothing.
      return;
    }
    this.editMode = !this.editMode;

    this.editModeChanged.emit(this.editMode);

    this.eventTrackingService.send({
      id: 'settings-list-item-edit-toggle',
      properties: {
        name: this.label,
        value: this.editMode,
      },
    });
  }

  /**
   * Handle click of action icon button.
   */
  onActionClick(event: MouseEvent) {
    if (event) {
      event.stopPropagation();
      event.stopImmediatePropagation();
      this.actionClicked.emit();
    }
  }

  constructor(
    public intlService: HelixIntlService,
    private settingsList: SettingsListComponent,
    private eventTrackingService: EventTrackingService) {
  }
}
