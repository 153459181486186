import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { QoS, QosPoliciesResult, StorageDomain, Template, ViewServiceApi } from '@cohesity/api/v2';
import { IrisContextService, flagEnabled } from '@cohesity/iris-core';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { AjsUpgradeService, ClusterService } from 'src/app/core/services';
import { StorageDomainSelectorComponent } from 'src/app/shared';


import { DefaultProtocolListByCategory, DefaultProtocolListByCategoryWithNFS4, DefaultQosPolicyNameByCategory } from '../../models';
import {
  RecommendedStorageDomainSelectorComponent,
} from '../recommended-storage-domain-selector/recommended-storage-domain-selector.component';

/**
 * View category model definition.
 */
interface ViewCategory {
  /**
   * Category label.
   */
  label: string;

  /**
   * Category value eg: BackupTarget, FileServices, ObjectServices.
   */
  value: string;
}

/**
 * File Services protocol model definition.
 */
export interface FileServicesProtocolModel {
  /**
   * FileServices protocol label.
   */
  label: string;

  /**
   * Determines whether the protocol is selected or not.
   */
  selected: boolean;

  /**
   * FileServices protocol value.
   */
  value: string;

  /**
   * Specifies to hide/show the protocol.
   */
  hidden?: boolean;
}

/**
 * Modify view details component.
 *
 * @example
 *   <coh-modify-view-details></coh-modify-view-details>
 */
@Component({
  selector: 'coh-modify-view-details',
  templateUrl: './modify-view-details.component.html',
  styleUrls: ['./modify-view-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModifyViewDetailsComponent extends AutoDestroyable implements OnInit, AfterViewInit {
  /**
   * Template id information for view creation.
   */
  @Input() templateId: number;

  /**
   * Determines whether it is view or view template.
   */
  @Input() isViewTemplate = false;

  /**
   * Determines if there are any warnings wrt view name.
   */
  @Input() nameWarningKeys: string[] = [];

  /**
   * Default selected storage domain. It's optional and used when creating view from a storage domain.
   */
  @Input() defaultStorageDomainId?: number;

  /**
   * Form gorup control options.
   */
  @Input() parentFormGroup: UntypedFormGroup;

  /**
   * Event emitted on updating viewbox selection.
   */
  @Output() viewBoxChange = new EventEmitter<StorageDomain>();

  /**
   * Indicates whether cluster is running in Helios mode.
   */
  isHelios: boolean;

  /**
   * Indicates whether View is in edit mode.
   */
  isEditMode: boolean;

  /**
   * Date time service.
   */
  private dateTimeService: any;

  /**
   * Returns valid minimal date required for lock until datepicker validation.
   *
   * @return   date   Minimal date required for datepicker
   */
  get minDataLockExpiryDate() {
    return new Date(this.parentFormGroup.controls.dataLockExpiryUsecs.value / 1000);
  }

  /**
   * Storage domain selector component reference for view creation flow.
   */
  @ViewChild(StorageDomainSelectorComponent, { static: false })
  sdSelector: StorageDomainSelectorComponent;

  /**
   * Recommended Storage domain selector component reference for view creation flow.
   */
  @ViewChild(RecommendedStorageDomainSelectorComponent, { static: false })
  recommendedSdSelector: RecommendedStorageDomainSelectorComponent;

  /**
   * Array of view categories.
   */
  categories: ViewCategory[] = [
    {
      label: 'fileShares',
      value: 'FileServices',
    },
    {
      label: 'backupTarget',
      value: 'BackupTarget',
    },
    {
      label: 'objectServices',
      value: 'ObjectServices',
    }
  ];

  /**
   * Array of qos policies available.
   */
  qosPolicies: QoS[];

  /**
   * Selected view template information.
   */
  @Input() templateInfo: Template;

  /**
   * Determines whether to recommend a Storage Domain. We do this only when
   * creating a View from a built-in template.
   */
  get isViewboxRecommended(): boolean {
    return !!this.templateId && this.templateInfo.isDefault;
  }

  /**
   * Specifies whether swift access protocol is selected or not.
   */
  get isSwiftOnly(): boolean {
    return this.viewProtocols.includes('Swift');
  }

  /**
   * Specifies if S3 protocol is selected look for atleast one read/write protocol.
   */
  get hasReadOnlyProtocol(): boolean {
    const readWriteProtocols = this.parentFormGroup.controls.protocolAccess.value
      .filter(protocol => protocol.mode === 'ReadWrite');
    return readWriteProtocols.length > 0;
  }

  /**
   * Returns selected view protcol list.
   */
  get viewProtocols(): string[] {
    return (this.parentFormGroup.controls.protocolAccess.value || []).map(protocol => protocol.type);
  }

  /**
   * Returns the default list of protocols for the selected category.
   */
  get defaultProtocolList(): any {
    const category = this.parentFormGroup.controls.category.value;
    let protocolList = flagEnabled(this.irisCtx.irisContext, 'viewSettingsSecureByDefault') ?
      DefaultProtocolListByCategoryWithNFS4[category] || [] :
      DefaultProtocolListByCategory[category] || [];

    if (this.isHelios) {
      protocolList = protocolList.filter(obj => obj.type !== 'S3');
    }

    return protocolList;
  }

  /**
   * Returns error for a form field.
   */
  public hasError = (controlName: string, errorName: string) =>
    this.parentFormGroup.controls[controlName].hasError(errorName);

  constructor(
    ajsUpgrade: AjsUpgradeService,
    private clusterService: ClusterService,
    private evalAjax: AjaxHandlerService,
    private viewsService: ViewServiceApi,
    private irisCtx: IrisContextService,
  ) {
    super();
    this.dateTimeService = ajsUpgrade.get('DateTimeService');
    this.isHelios = !!clusterService.isMcm;

    if (flagEnabled(this.irisCtx.irisContext, 'ngViewArchiveServicesCategory')) {
      this.categories.push({
        label: 'archiveServices',
        value: 'ArchiveServices',
      });
    }
  }

  /**
   * Init Component.
   */
  ngOnInit() {
    this.isEditMode = !!this.parentFormGroup?.value.viewId;

    this.viewsService.GetQosPolicies().pipe(
      this.untilDestroy()
    ).subscribe((response: QosPoliciesResult) => {
      if (response?.qosPolicies) {
        this.qosPolicies = response.qosPolicies;
      }
    }, this.evalAjax.errorMessage);

    if (this.parentFormGroup) {
      this.parentFormGroup.get('swiftUsername').valueChanges.pipe(
        this.untilDestroy()
      ).subscribe(value => {
        if (value) {
          this.parentFormGroup.get('swiftUserDomain').setValidators(Validators.required);
        } else {
          this.parentFormGroup.get('swiftUserDomain').setValidators(null);
        }
      });
    }
  }

  /**
   * Specifies whether category should disabled or not.
   *
   * @param   value   category value.
   * @returns         boolean to specify category is disabled or not.
   */
  isCategoryDisabled(value: string): boolean {
    const selectedCategory = this.parentFormGroup.controls.category.value;

    // User should be able to override the category when it was inferred for
    // legacy Views or any View created without a Category.
    if (this.parentFormGroup.value.isCategoryInferred) {
      return false;
    }

    // User may not create Object Services Views during Helios sessions.
    if (this.isHelios && value === 'ObjectServices') {
      return true;
    }

    // If editing an Object Services View, user may not change to a different category.
    if (this.isEditMode && selectedCategory === 'ObjectServices') {
      return value !== 'ObjectServices';
    }

    // If editing any other type of View, user may not change to Object Services.
    return this.isEditMode && value === 'ObjectServices';
  }

  /**
   * Method called to update with default qos value based on category.
   */
  updateQosPolicy() {
    const defaultPolicy = this.qosPolicies.find(qos => qos.name ===
      DefaultQosPolicyNameByCategory[this.parentFormGroup.controls.category.value]);

    // In case of view creation mode setting default value as 'TestAndDev High'
    // else will populate with form value.
    const policy = this.qosPolicies.find(qos => (qos.name === defaultPolicy.name));
    this.parentFormGroup.controls.qos.setValue(policy);
  }


  /**
   * After View Init.
   */
  ngAfterViewInit() {
    const selector = this.isViewboxRecommended ? 'recommendedSdSelector' : 'sdSelector';

    if (this[selector]) {
      this.viewBoxChange.emit(this[selector].selectedStorageDomain);

      this[selector].valueChange.pipe(
        this.untilDestroy()
      ).subscribe(() => {
        this.viewBoxChange.emit(this[selector].selectedStorageDomain);
      });
    }
  }

  /**
   * Method called on updating category value.
   */
  categoryChange() {

    // setting default protocol values on updating category.
    this.parentFormGroup.controls.protocolAccess.setValue(this.defaultProtocolList);

    this.updateQosPolicy();
  }

  /**
   * Method called when override date is set.
   *
   * @param   date  Override lock date
   */
  setLockOverrideTimestamp(date: Date) {
    if (date) {
      date = this.dateTimeService.endOfDay(date);
      this.parentFormGroup.controls.dataLockExpiryUsecs.setValue(this.dateTimeService.dateToUsecs(date));
    }
  }
}
