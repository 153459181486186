import { Injectable } from '@angular/core';
import { NavItem } from '@cohesity/helix';
import { flagEnabled, IrisContext, isMcmOnPrem } from '@cohesity/iris-core';
import { StateManagementService } from 'src/app/core/services';

import { ClusterManagerNavService } from './cluster-manager-nav.service';

/**
 * This Service overrides the configurations of ClusterManagerNavService for the project Aqua.
 */
@Injectable({ providedIn: 'root' })
export class IBMClusterManagerNavService extends ClusterManagerNavService {
  /**
   * hides the nav branding (logo and title).
   */
  get showNavBranding(): boolean {
    return false;
  }

  /**
   * used to show the cluster dropdown on nav banner in classic way
   */
  get useClassicClusterDropdown(): boolean {
    return false;
  }

  /**
   * used to show the runbooks nav option
   */
  get shouldShowRunbooks(): boolean {
    return false;
  }

  /**
   * Get the main nav items for this service
   *
   * @param ctx The current cluster context.
   * @param stateManagementService state management context.
   * @returns An array of nav items.
   */
  override getNavItems(ctx: IrisContext, stateManagementService: StateManagementService): NavItem[] {
    let navList: NavItem[] = this.getStandardNavList(ctx, stateManagementService);

    if (this.isAllClustersScope) {
      navList = [
        this.getDashboardNavs(ctx, stateManagementService),
        this.getSecurityNavs(ctx),
        this.getSystemNavs(ctx),
        this.getReportingNavItem(ctx, 'mcm'),
        this.getSettingsNavItem(ctx),
      ];
    }

    return navList;
  }

  /**
   * Returns a copy of navList for main navigation.
   *
   * @returns The list of nav items.
   */
  override getStandardNavList(irisContext: IrisContext, stateManagementService: StateManagementService): NavItem[] {
    const navList: NavItem[] = [
      // Add the dashboards
      this.getDashboardNavs(irisContext, stateManagementService),

      // Data Protection Section
      this.getStandardDataProtectionNavOptions(irisContext),

      // Infrastructure
      ...this.getInfrastructureNavs(irisContext),

      // Test & Dev
      this.getStandardTestNDevNavOptions(),

      // System Section
      this.getStandardSystemNavOptions(irisContext),

      // reporting
      this.getReportingNavItem(irisContext, 'onPrem'),

      // settings
      this.getStandardSettingsNavOptions(irisContext),
    ];

    return this.getValidNavItems(navList);
  }

  /**
   * Get the settings nav items for this service
   *
   * @param ctx The current cluster context.
   * @returns An array of settings nav items.
   */
  private getSettingsNavItem(ctx: IrisContext): NavItem {
    return {
      displayName: 'settings',
      state: 'cluster-upgrades',
      icon: 'settings!outline',
      subNavList: [
        {
          displayName: 'clusters',
          state: this.showNGClusterManagement ? 'software-upgrade.cluster-list' : 'cluster-upgrades',
        },
        {
          displayName: 'accessManagement',
          state: 'helios-access-management',
        },
        isMcmOnPrem(ctx)
          ? null
          : {
              displayName: 'license',
              state: 'license.dashboard',
            },
        flagEnabled(ctx, 'ibmAquaDataPlatformForCloudEnabled')
          ? {
              displayName: 'cloudEdition.dataPlatformForCloud',
              state: 'cloud-edition-manager',
            }
          : null,
      ].filter(navItem => !!navItem),
    };
  }

  /**
   * Returns a fresh copy of navItems[] for dashboard states.
   *
   * @returns The list of dashboard items.
   */
  override getDashboardItems(irisContext: IrisContext): NavItem[] {
    // Dashboard items not applicable to IBM context
    const excludedItems: NavItem['displayName'][] = ['smartFiles'];
    const dashboardItems: NavItem[] = super.getDashboardItems(irisContext);

    // Filter out falsy values.
    return dashboardItems.filter(Boolean).filter(item => !excludedItems.includes(item.displayName));
  }
}
