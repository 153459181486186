import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA, MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';

import { HelixIntlService } from '../../helix-intl.service';
import { SnackBarData } from './snack-bar.service';


/**
 * Mapping providing an icon shape value for each this.data.status value.
 */
const statusToShape = {
  error: 'helix:status-error',
  info: undefined,
  success: undefined,
  warn: 'helix:status-warning',
};

/**
 * @description
 * A component used to open as a snack bar.
 * This should only be called internally by the SnackBarService.
 */
@Component({
  selector: 'cog-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackBarComponent {
  /**
   * Adds a class to the component for style targeting.
   */
  @HostBinding('class.cog-snack-bar') cogClass = true;

  /**
   * Binds the success class for the success status.
   */
  @HostBinding('class.cog-snack-bar-success')
  get success(): boolean {
    return this.data.status === 'success';
  }

  /**
   * Binds the info class for the info status.
   */
  @HostBinding('class.cog-snack-bar-info')
  get info(): boolean {
    return this.data.status === 'info';
  }

  /**
   * Binds the warn class for the warn status.
   */
  @HostBinding('class.cog-snack-bar-warn')
  get warn(): boolean {
    return this.data.status === 'warn';
  }

  /**
   * Binds the error class for the error status.
   */
  @HostBinding('class.cog-snack-bar-error')
  get error(): boolean {
    return this.data.status === 'error';
  }

  /**
   * Binds the class indicating snackbar will have an icon visible.
   */
  @HostBinding('class.cog-snack-bar-has-icon')
  get hasIcon(): boolean {
    return Boolean(this.statusShape);
  }

  /**
   * Specifies if the action button should be shown.
   */
  get hasAction(): boolean {
    return !!this.data.actionText;
  }

  /**
   * Specifies if the dismiss button should be shown.
   */
  get allowDismiss(): boolean {
    return !!this.data.allowDismiss;
  }

  /**
   * Provides the status icon shape if appropriate
   */
  get statusShape(): string {
    return statusToShape[this.data.status];
  }

  /**
   * Data that was injected into the snack bar.
   */
  data: SnackBarData;


  constructor(
    public snackBarRef: MatSnackBarRef<SnackBarComponent>,
    readonly intl: HelixIntlService,
    @Inject(MAT_SNACK_BAR_DATA) data: SnackBarData
  ) {
    this.data = data;
  }



  /**
   * Function to copy the snackbar message.
   *
   * @param message The message to copy.
   */
  copyMessage(message: string) {
    if (!this.error) {
      // Only copy error messages as other messages are typically hard coded
      // and shouldn't be overflowing.
      return;
    }

    navigator.clipboard.writeText(message);
  }
}
