<div class="quick-filters" [class.column-alignment]="alignment === 'column'">
  <div class="quick-filter filters-label" *ngIf="alignment !== 'column' && showFilterLabel">
    {{intl.dataFilters.filtersColon}}
  </div>
  <div class="quick-filter" [class.hidden]="def.hidden" *ngFor="let def of quickFilterDefs">
    <h4 *ngIf="def.filterGroup && def.quickFilter && alignment === 'column' && def.quickFilterGroupLabel">
      {{ def.quickFilterGroupLabel }}
    </h4>
    <ng-container *ngIf="def.quickFilter">
      <ng-container *ngTemplateOutlet="def.template; context: def.context"></ng-container>
    </ng-container>
    <cog-quick-filter *ngIf="def.filterGroup && !def.quickFilter"
      [filterTags]="groupFilterTags[def.filterGroup]"
      [label]="def.filterGroup"
      (applied)="applyGroupFilters(def.filterGroup)"
      (cleared)="clearGroupFilters(def.filterGroup)">
      <ng-container *ngTemplateOutlet="groupFilters; context: {
          groupFilters: groupFilterDefs[def.filterGroup],
          groupName: def.filterGroup
        }">
      </ng-container>
    </cog-quick-filter>
  </div>
  <div class="quick-filter"
    *ngIf="extraFilterDefs && extraFilterDefs.length">
    <cog-quick-filter-button *ngIf="extraFilterTags$ | async as extraFilterTags"
      [isSelected]="extraFilterTags.length > 0"
      [label]="intl.dataFilters.moreFilters(extraFilterTags.length)"
      (click)="toggleFilters()">
    </cog-quick-filter-button>
  </div>
</div>

<ng-template #groupFilters let-group="groupName" let-filters="groupFilters">
  <div class="cog-filters group-filters">
    <div class="group-filter" *ngFor="let def of filters">
      <ng-container *ngTemplateOutlet="def.template; context: def.context"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #backdropFilters>
  <cog-page-header>
    <mat-toolbar>
      <mat-toolbar-row class="filter-header">
        <button cogDataId="toggle-backdrop-filters"
          type="button"
          mat-icon-button
          (click)="toggleFilters()">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <cog-applied-filters [filterTags]="extraFilterTags$ | async"></cog-applied-filters>
      </mat-toolbar-row>
    </mat-toolbar>
  </cog-page-header>
  <div class="backdrop-filters">
    <ng-container  *ngFor="let def of extraFilterDefs">
      <div class="backdrop-filter">
        <ng-container *ngTemplateOutlet="def.template; context: def.context"></ng-container>
      </div>
    </ng-container>
  </div>
</ng-template>
