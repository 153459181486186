<cog-settings-list-item [formGroup]="formGroup"
  [invalid]="globalExcludeFormGroup.invalid"
  [label]="'globalExcludePaths' | translate">
  <cog-settings-list-item-value-display>
    <span>
      {{'protectionGroups.excluded' | translate: {
        excluded: globalExcludePathsControl.controls.length | number}
      }}
    </span>
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor [formGroup]="globalExcludeFormGroup"
    cogDataId="settings-global-excludes">
    <h2>{{ 'exclude' | translate }}</h2>

    <div *ngFor="let formControl of globalExcludePathsControl.controls; let index = index;">
      <mat-form-field>
        <input [formControl]="formControl" matInput required
          cogDataId="exclude-path" [inheritDataId]="true">
        <mat-icon *ngIf="showFolderIcon" [inline]="true" matSuffix>
          folder_open
        </mat-icon>
        <mat-error>
          {{'errors.required' | translate}}
        </mat-error>
      </mat-form-field>
      <button (click)="removePathFormControl(index)"
        cogDataId="remove-exclude-path"
        [inheritDataId]="true"
        mat-icon-button
        type="button">
        <cog-icon shape="clear" size="sm"></cog-icon>
      </button>
    </div>
    <button (click)="addPathFormControl()"
      cogDataId="add-exclude-path"
      [inheritDataId]="true"
      color="primary"
      mat-stroked-button
      type="button">
      <cog-icon shape="add"></cog-icon>
      {{'add' | translate}}
    </button>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
