<cog-settings-list-item
  [invalid]="startTimeFormGroup?.invalid"
  [label]="'startTime' | translate"
  cogFormGroup>
  <cog-settings-list-item-value-display>
    <ul class="c-ul-inline"
      *ngIf="requiresStartTime; else notApplicable">
      <li>{{startTimeFormGroup.value.time | timeObjectToDate}}</li>
      <li>{{startTimeFormGroup.value.timeZone}}</li>
    </ul>
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor [formGroup]="startTimeFormGroup">
    <div class="flex-row-sm"
      *ngIf="requiresStartTime; else noStartTimeRequiredMsg">
      <coh-time-picker formControlName="time"
        [label]="'time' | translate">
      </coh-time-picker>
      <coh-timezone-selector formControlName="timeZone"
        [label]="'timeZone' | translate">
      </coh-timezone-selector>
    </div>
  </cog-settings-list-item-value-editor>

</cog-settings-list-item>

<ng-template #noStartTimeRequiredMsg>
  <cog-banner  status="info">
    {{ (isProtectOncePolicy ? 'jobSettingsList.startTimeNotApplicable' :
      'jobSettingsList.startTimeNotNeeded' ) | translate }}
  </cog-banner>
</ng-template>

<ng-template #notApplicable>
  {{ 'notApplicable' | translate }}
</ng-template>
