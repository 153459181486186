import { Injectable } from '@angular/core';
import { ObjectSnapshot, Recovery } from '@cohesity/api/v2';
import { Subject } from 'rxjs';

import { RestorePointSelection } from './restore-point-selection';

/**
 * Recovery form context is used to keep track of global properties or settings that affect an entire
 * recovery flow and its behavior. For instance, as part of a failover flow the target source id is
 * specified as a state param and the form should automatically set the recovery type to new vm,
 * select the source, and prevent further editing it.
 * Since this is flag affects the _behavior_ of the form rather than it's values, it is difficult to
 * tract using the form values alone.
 *
 * Recovery Form Context allows for options to be set on the parent form which can be accessed by child
 * components via dependency injection to determine what the correct behavior should be. This object is
 * shared for the root form component and all of its children. It is reset whenever a new recovery is
 * initiated.
 *
 */
@Injectable()
export class RecoveryFormContextService {
  /**
   * This property specifies a target source that should be selected and locked.
   * This is used for protection group failover.
   */
  lockedTargetSourceId: number = null;

  /**
   * This property can be set in an abbreviated flow and used by component to initalize their values to their
   * values. The transformers methods should do most of this configuration, but this can be used to simplify
   * configuring values that require making api calls in order to initialize properly.
   */
  initialRecoveryConfig: Recovery;

  /**
   * This property is used to notify the form component the specific snapshot is selected.
   */
  restorePoint$ = new Subject<ObjectSnapshot>();

  /**
   * The flag indicates whether the form component is in a file-recovery mode.
   */
  isFileRecovery: boolean;

  /**
   * The flag indicates whether the form component is in a read-only mode like showing
   * at the options tab of recovery detail page.
   */
  isReadOnly: boolean;

  /**
   * Special job type like 'kRDSSnapshotManager' etc.
   */
  protectionType: string;

  /**
   * backup type integer representing backup types like incremental, kStorageArraySnapshot, etc
   */
  backupType?: number;

  /**
   * This property is used to notify the form component the objects selected to be recovered.
   */
  recoveryObjects$ = new Subject<RestorePointSelection[]>();

  /**
   * The flag indicates whether the originally backed up VM is still available in the entity hierarchy.
   * This drives UX logic to determine target selection options for VM FLR resubmit workflows. Defaults to true
   */
  recoveryVMAvailable = true;
}
