<form
  [formGroup]="groupUnprotectForm"
  cohValidateOnSubmit
  (validatedSubmit)="submitDelete()">
  <ng-container *ngIf="objectsLength === 1">
    <h1 *ngIf="firstObjectName" mat-dialog-title>
      {{ 'unprotectObjectDialog.unprotectName' | translate: { name: firstObjectName } }}
    </h1>
    <h1 *ngIf="!firstObjectName" mat-dialog-title>
      {{ 'unprotectObject' | translate }}
    </h1>
    <div mat-dialog-content class="content">
      <cog-banner *ngIf="isAnyAutoProtected(data?.objects)" status="info" class="margin-bottom">
        <p>{{ 'unprotectObjectDialog.autoProtectedObject' | translate }}</p>
      </cog-banner>
      <div class="margin-bottom">
        <ng-container *ngIf="firstObjectName">
          {{ 'unprotectObjectDialog.unprotectObject' | translate : { name: firstObjectName } }}
        </ng-container>
        <ng-container *ngIf="!firstObjectName">
          {{ 'unprotectObjectDialog.unprotectSelectedObject' | translate }}
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="objectsLength > 1">
    <h1 mat-dialog-title>{{ 'unprotectObjectDialog.title' | translate }}</h1>
    <div mat-dialog-content class="content">
      <cog-banner *ngIf="isAnyAutoProtected(data?.objects)" status="info" class="margin-bottom">
        <p>{{ 'unprotectObjectDialog.autoProtectedObjects' | translate }}</p>
      </cog-banner>
      {{ 'unprotectObjectDialog.unprotectObjects' | translate }}
    </div>
  </ng-container>
  <div class="margin-top" *ngIf="showSnapshotDeletionToggle">
    <mat-slide-toggle *ngIf="context.irisContext.featureFlags.dmsDataLock"
      color="primary"
      formControlName="enableSnapshotDeletion">
      {{ 'deleteSnapshots' | translate }}
    </mat-slide-toggle>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button
      mat-dialog-close
      color="primary"
      cogDataId="unprotect-cancel">
      {{ 'cancel' | translate }}
    </button>
    <button mat-flat-button
      mat-dialog-close
      cdkFocusInitial
      type="submit"
      color="primary"
      cogDataId="unprotect-only">
      {{ 'unprotect' | translate }}
    </button>
  </div>
</form>
