import { DataRenderer, TextDataRendererComponent } from '@cohesity/helix';

/**
 * Renders a DR plan Run Status, Action and IP config values,
 * prepending it with a prefix and calling translate on it.
 */
export const drPlanTextRenderer = {
  transform: (renderValues: DataRenderer<string, undefined, undefined>) => ({
    ...renderValues,
    value: renderValues.value ? renderValues.translate(`enum.drPlan.${renderValues.value}`) : '',
  }),
  component: TextDataRendererComponent,
};
