/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiAlertServerConfiguration as __Configuration } from '../cohesity-api-alert-server-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AlertMetadata } from '../models/alert-metadata';
import { AlertCategoryWithBucket } from '../models/alert-category-with-bucket';
import { AlertLabelsWithType } from '../models/alert-labels-with-type';
import { AlertTypeBucket } from '../models/alert-type-bucket';

/**
 * Alert metadata in the system
 */
@Injectable({
  providedIn: 'root',
})
class AlertMetadataService extends __BaseService {
  static readonly GetAlertMetadataPath = '/alerts/metadata';
  static readonly RegisterAlertTypesPath = '/alerts/metadata';
  static readonly GetAlertCategoriesPath = '/alerts/metadata/category';
  static readonly GetAlertLabelsPath = '/alerts/metadata/labels';
  static readonly GetAlertTypeBucketsPath = '/alerts/metadata/typebucket';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get alert names registered in Cohesity.
   *
   * Get alert metadata registered in Cohesity.
   * @param params The `AlertMetadataService.GetAlertMetadataParams` containing the following parameters:
   *
   * - `accountId`: Current Account Id.
   *
   * - `tenantId`: Tenant Id of the user.
   *
   * - `alertTypeBucketList`: Specifies list of Alert type bucket to filter alerts.
   *
   * - `alertNameList`: Specifies list of Alert names to filter alerts.
   *
   * - `alertCategoryList`: Specifies list of Alert Categories.
   *
   * @return Success
   */
  GetAlertMetadataResponse(params: AlertMetadataService.GetAlertMetadataParams): __Observable<__StrictHttpResponse<Array<AlertMetadata>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    (params.alertTypeBucketList || []).forEach(val => {if (val != null) __params = __params.append('alertTypeBucketList', val.toString())});
    (params.alertNameList || []).forEach(val => {if (val != null) __params = __params.append('alertNameList', val.toString())});
    (params.alertCategoryList || []).forEach(val => {if (val != null) __params = __params.append('alertCategoryList', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/alerts/metadata`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AlertMetadata>>;
      })
    );
  }
  /**
   * Get alert names registered in Cohesity.
   *
   * Get alert metadata registered in Cohesity.
   * @param params The `AlertMetadataService.GetAlertMetadataParams` containing the following parameters:
   *
   * - `accountId`: Current Account Id.
   *
   * - `tenantId`: Tenant Id of the user.
   *
   * - `alertTypeBucketList`: Specifies list of Alert type bucket to filter alerts.
   *
   * - `alertNameList`: Specifies list of Alert names to filter alerts.
   *
   * - `alertCategoryList`: Specifies list of Alert Categories.
   *
   * @return Success
   */
  GetAlertMetadata(params: AlertMetadataService.GetAlertMetadataParams): __Observable<Array<AlertMetadata>> {
    return this.GetAlertMetadataResponse(params).pipe(
      __map(_r => _r.body as Array<AlertMetadata>)
    );
  }

  /**
   * Register a batch of alert type metadata.
   *
   * Register a batch of alert type metadata.
   * @param params The `AlertMetadataService.RegisterAlertTypesParams` containing the following parameters:
   *
   * - `accountId`: Current Account Id.
   *
   * - `body`: alert type metadata batch to be registered.
   */
  RegisterAlertTypesResponse(params: AlertMetadataService.RegisterAlertTypesParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/alerts/metadata`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Register a batch of alert type metadata.
   *
   * Register a batch of alert type metadata.
   * @param params The `AlertMetadataService.RegisterAlertTypesParams` containing the following parameters:
   *
   * - `accountId`: Current Account Id.
   *
   * - `body`: alert type metadata batch to be registered.
   */
  RegisterAlertTypes(params: AlertMetadataService.RegisterAlertTypesParams): __Observable<null> {
    return this.RegisterAlertTypesResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get alert categories defined by Cohesity.
   *
   * Get alert categories defined by Cohesity.
   * @param params The `AlertMetadataService.GetAlertCategoriesParams` containing the following parameters:
   *
   * - `accountId`: Current Account Id.
   *
   * - `tenantId`: Tenant Id of the user.
   *
   * - `alertTypeBucketList`: Specifies list of Alert type bucket to filter alerts.
   *
   * @return Success
   */
  GetAlertCategoriesResponse(params: AlertMetadataService.GetAlertCategoriesParams): __Observable<__StrictHttpResponse<Array<AlertCategoryWithBucket>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    (params.alertTypeBucketList || []).forEach(val => {if (val != null) __params = __params.append('alertTypeBucketList', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/alerts/metadata/category`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AlertCategoryWithBucket>>;
      })
    );
  }
  /**
   * Get alert categories defined by Cohesity.
   *
   * Get alert categories defined by Cohesity.
   * @param params The `AlertMetadataService.GetAlertCategoriesParams` containing the following parameters:
   *
   * - `accountId`: Current Account Id.
   *
   * - `tenantId`: Tenant Id of the user.
   *
   * - `alertTypeBucketList`: Specifies list of Alert type bucket to filter alerts.
   *
   * @return Success
   */
  GetAlertCategories(params: AlertMetadataService.GetAlertCategoriesParams): __Observable<Array<AlertCategoryWithBucket>> {
    return this.GetAlertCategoriesResponse(params).pipe(
      __map(_r => _r.body as Array<AlertCategoryWithBucket>)
    );
  }

  /**
   * Get alert labels defined by Cohesity.
   *
   * Get alert labels defined by Cohesity.
   * @param labelType Specifies the label type for which labels are required.
   * @return Success
   */
  GetAlertLabelsResponse(labelType?: string): __Observable<__StrictHttpResponse<Array<AlertLabelsWithType>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (labelType != null) __params = __params.set('labelType', labelType.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/alerts/metadata/labels`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AlertLabelsWithType>>;
      })
    );
  }
  /**
   * Get alert labels defined by Cohesity.
   *
   * Get alert labels defined by Cohesity.
   * @param labelType Specifies the label type for which labels are required.
   * @return Success
   */
  GetAlertLabels(labelType?: string): __Observable<Array<AlertLabelsWithType>> {
    return this.GetAlertLabelsResponse(labelType).pipe(
      __map(_r => _r.body as Array<AlertLabelsWithType>)
    );
  }

  /**
   * Get alert typebuckets defined by Cohesity.
   *
   * Get alert typebuckets defined by Cohesity.
   * @param params The `AlertMetadataService.GetAlertTypeBucketsParams` containing the following parameters:
   *
   * - `accountId`: Current Account Id.
   *
   * - `tenantId`: Tenant Id of the user.
   *
   * @return Success
   */
  GetAlertTypeBucketsResponse(params: AlertMetadataService.GetAlertTypeBucketsParams): __Observable<__StrictHttpResponse<Array<AlertTypeBucket>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/alerts/metadata/typebucket`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AlertTypeBucket>>;
      })
    );
  }
  /**
   * Get alert typebuckets defined by Cohesity.
   *
   * Get alert typebuckets defined by Cohesity.
   * @param params The `AlertMetadataService.GetAlertTypeBucketsParams` containing the following parameters:
   *
   * - `accountId`: Current Account Id.
   *
   * - `tenantId`: Tenant Id of the user.
   *
   * @return Success
   */
  GetAlertTypeBuckets(params: AlertMetadataService.GetAlertTypeBucketsParams): __Observable<Array<AlertTypeBucket>> {
    return this.GetAlertTypeBucketsResponse(params).pipe(
      __map(_r => _r.body as Array<AlertTypeBucket>)
    );
  }
}

module AlertMetadataService {

  /**
   * Parameters for GetAlertMetadata
   */
  export interface GetAlertMetadataParams {

    /**
     * Current Account Id.
     */
    accountId: string;

    /**
     * Tenant Id of the user.
     */
    tenantId?: string;

    /**
     * Specifies list of Alert type bucket to filter alerts.
     */
    alertTypeBucketList?: Array<'DataService' | 'Maintenance' | 'Software' | 'Hardware'>;

    /**
     * Specifies list of Alert names to filter alerts.
     */
    alertNameList?: Array<string>;

    /**
     * Specifies list of Alert Categories.
     */
    alertCategoryList?: Array<string>;
  }

  /**
   * Parameters for RegisterAlertTypes
   */
  export interface RegisterAlertTypesParams {

    /**
     * Current Account Id.
     */
    accountId: string;

    /**
     * alert type metadata batch to be registered.
     */
    body?: Array<AlertMetadata>;
  }

  /**
   * Parameters for GetAlertCategories
   */
  export interface GetAlertCategoriesParams {

    /**
     * Current Account Id.
     */
    accountId: string;

    /**
     * Tenant Id of the user.
     */
    tenantId?: string;

    /**
     * Specifies list of Alert type bucket to filter alerts.
     */
    alertTypeBucketList?: Array<'DataService' | 'Maintenance' | 'Software' | 'Hardware'>;
  }

  /**
   * Parameters for GetAlertTypeBuckets
   */
  export interface GetAlertTypeBucketsParams {

    /**
     * Current Account Id.
     */
    accountId: string;

    /**
     * Tenant Id of the user.
     */
    tenantId?: string;
  }
}

export { AlertMetadataService }
