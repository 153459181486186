import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const protectModify = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngProtectionGroupGlobalModify &&
  ctx.FEATURE_FLAGS.ngProtectionGroupCouchbaseModify &&
  ctx.PROTECTION_MODIFY;

export const CouchbaseConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'protection-builder-couchbase.**',
      url: '/protection/protect/couchbase',
      loadChildren: () => import('./couchbase.module').then(m => m.CouchbaseModule),
    }
  ],
  getStateAccessMap(): StateAccessMap {
    return {
      'protection-builder-couchbase.create': protectModify,
      'protection-builder-couchbase.edit': protectModify,
    };
  }
};
