// Module: Add Vips

;(function(angular, undefined) {

  angular.module('C.addNodes')
    .controller('addVipsController', addVipsControllerFn);

  function addVipsControllerFn(
    $rootScope, $scope, $state, FORMATS, _, StateManagementService) {

    /**
     * initialization function
     *
     * @method     activate
     */
    function activate() {
      if ($scope.expandClusterData && !$scope.expandClusterData.nodes.length &&
        $scope.shared.discoveredNodes.length) {
        // Put the selected nodes inside the expandClusterData if
        // expandClusterData.node doesn't exist.
        _.assign($scope.expandClusterData.nodes,
          _.filter($scope.shared.discoveredNodes, 'selected')
          .map(function mapSelectedNodes(node) {
            return {
              id: node.nodeId,
              useAsComputeNode: !!node._useAsComputeNode,
              // Assign ip address based on ip family.
              ip: ($scope.shared.ipFamily === 2) ? node._ipv6 : node._ipv4 ,
            };
          }));
      } else if (!$scope.expandClusterData ||
        !$scope.expandClusterData.nodes.length) {
        $state.go('cluster.nodes');
      }
    }

    $scope.FORMATS = FORMATS;

    $scope.text = $rootScope.text.addVips;
    $scope.errorText = $rootScope.text.addVips.errorText;

    $scope.VIPsValid = true;

    $scope.mode = 'new';

    $scope.changePartition = function changePartition(id) {
      if (id) {
        $scope.getPartitionDetails(id);
        $scope.expandClusterData.clusterPartitionId = id;
      } else {
        $scope.shared.selectedPartitionDetails = null;
        $scope.expandClusterData.clusterPartitionId = null;
        $scope.vips = [];
      }
    };

    /**
     * Validates a new VIP before adding it the ui-select taggin
     *
     * @param      {string}            newVip  The new vip
     * @param      {string}  value   The value
     * @return     {(boolean|string)}  returns newVip string if valid
     *                                 vip, other returns false
     */
    $scope.addVIP = function addVIP(newVip) {
      if ($scope.shared.selectedPartitionDetails.vips.includes(newVip)) {
        $scope.VIPsValid = false;
        return false;
      }
      if (newVip.match($scope.FORMATS.IPv4)) {
        $scope.VIPsValid = true;
        return newVip;
      } else {
        $scope.VIPsValid = false;
        return false;
      }
    };

    $scope.goBack = function goBack() {
      // go back to previous state
      StateManagementService.goToPreviousState();
    };

    activate();

  }

})(angular);
