import { OverlayContainer, OverlayModule } from '@angular/cdk/overlay';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AppOutletModule } from './app-outlet';
import { PluginOutletModule } from './plugin-outlet';
import { AppLoaderService, MicroFrontendHostOptions, PluginLoaderService, ScriptLoaderService } from './services';

@NgModule({
  imports: [
    AppOutletModule,
    HttpClientModule,
    OverlayModule,
    PluginOutletModule,
  ],
  exports: [
    AppOutletModule,
    PluginOutletModule,
  ],
  providers: [
    AppLoaderService,
    MicroFrontendHostOptions,
    PluginLoaderService,
    ScriptLoaderService,
  ],
})
export class MicroFrontendHostModule {
  constructor(private overlayContainer: OverlayContainer) {
    // Initilizing overlayContainer by adding empty container div in the body
    // this is essential for making nested dialogs to work if they are launched by
    // different apps eg. w/o argus dialog will come behind the iris dialog.
    this.overlayContainer.getContainerElement();
  }
}
