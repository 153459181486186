/**
 * Enum for Cloud Provider.
 */
export enum CloudProvider {
  kAws = 'aws',
  kAzure = 'azure',
  kGcp = 'gcp',
  kNas = 'nas',
  kOracle = 'oracle',
  kQStar = 'qstar',
  kS3C = 's3c',
  kOther = 'other',
}
