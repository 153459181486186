/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ViewBox } from '../models/view-box';
import { CreateViewBoxParams } from '../models/create-view-box-params';
@Injectable({
  providedIn: 'root',
})
class ViewBoxesService extends __BaseService {
  static readonly GetViewBoxesPath = '/public/viewBoxes';
  static readonly CreateViewBoxPath = '/public/viewBoxes';
  static readonly GetViewBoxByIdPath = '/public/viewBoxes/{id}';
  static readonly UpdateViewBoxPath = '/public/viewBoxes/{id}';
  static readonly DeleteViewBoxPath = '/public/viewBoxes/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List Domains (View Boxes) filtered by the specified parameters.
   *
   * If no parameters are specified, all Domains (View Boxes) currently on
   * the Cohesity Cluster are returned.
   * Specifying parameters filters the results that are returned.
   * @param params The `ViewBoxesService.GetViewBoxesParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `templateId`: Filter list of Storage Domain (View Box) by the properties of the template
   *   like dedup, compression.
   *   If empty, Storage Domains (View Boxes) are not filtered.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `names`: Filter by a list of Storage Domain (View Box) Names.
   *   If empty, Storage Domains (View Boxes) are not filtered by Name.
   *
   * - `ids`: Filter by a list of Storage Domain (View Box) ids.
   *   If empty, View Boxes are not filtered by id.
   *
   * - `fetchTimeSeriesSchema`: Specifies whether to get time series schema info of the view box.
   *
   * - `fetchStats`: Specifies whether to include usage and performance statistics.
   *
   * - `clusterPartitionIds`: Filter by a list of Cluster Partition Ids.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViewBoxesResponse(params: ViewBoxesService.GetViewBoxesParams): __Observable<__StrictHttpResponse<Array<ViewBox>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.templateId != null) __params = __params.set('templateId', params.templateId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.names || []).forEach(val => {if (val != null) __params = __params.append('names', val.toString())});
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.fetchTimeSeriesSchema != null) __params = __params.set('fetchTimeSeriesSchema', params.fetchTimeSeriesSchema.toString());
    if (params.fetchStats != null) __params = __params.set('fetchStats', params.fetchStats.toString());
    (params.clusterPartitionIds || []).forEach(val => {if (val != null) __params = __params.append('clusterPartitionIds', val.toString())});
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/viewBoxes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ViewBox>>;
      })
    );
  }
  /**
   * List Domains (View Boxes) filtered by the specified parameters.
   *
   * If no parameters are specified, all Domains (View Boxes) currently on
   * the Cohesity Cluster are returned.
   * Specifying parameters filters the results that are returned.
   * @param params The `ViewBoxesService.GetViewBoxesParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `templateId`: Filter list of Storage Domain (View Box) by the properties of the template
   *   like dedup, compression.
   *   If empty, Storage Domains (View Boxes) are not filtered.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `names`: Filter by a list of Storage Domain (View Box) Names.
   *   If empty, Storage Domains (View Boxes) are not filtered by Name.
   *
   * - `ids`: Filter by a list of Storage Domain (View Box) ids.
   *   If empty, View Boxes are not filtered by id.
   *
   * - `fetchTimeSeriesSchema`: Specifies whether to get time series schema info of the view box.
   *
   * - `fetchStats`: Specifies whether to include usage and performance statistics.
   *
   * - `clusterPartitionIds`: Filter by a list of Cluster Partition Ids.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViewBoxes(params: ViewBoxesService.GetViewBoxesParams): __Observable<Array<ViewBox>> {
    return this.GetViewBoxesResponse(params).pipe(
      __map(_r => _r.body as Array<ViewBox>)
    );
  }

  /**
   * Create a Domain (View Box).
   *
   * Returns the created Domain (View Box).
   * @param params The `ViewBoxesService.CreateViewBoxParams` containing the following parameters:
   *
   * - `body`: Request to create a Storage Domain (View Box) configuration.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateViewBoxResponse(params: ViewBoxesService.CreateViewBoxParams): __Observable<__StrictHttpResponse<ViewBox>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/viewBoxes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ViewBox>;
      })
    );
  }
  /**
   * Create a Domain (View Box).
   *
   * Returns the created Domain (View Box).
   * @param params The `ViewBoxesService.CreateViewBoxParams` containing the following parameters:
   *
   * - `body`: Request to create a Storage Domain (View Box) configuration.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateViewBox(params: ViewBoxesService.CreateViewBoxParams): __Observable<ViewBox> {
    return this.CreateViewBoxResponse(params).pipe(
      __map(_r => _r.body as ViewBox)
    );
  }

  /**
   * List details about a single Domain (View Box).
   *
   * Returns the Domain (View Box) corresponding to the specified Domain (View Box)
   * Id.
   * @param params The `ViewBoxesService.GetViewBoxByIdParams` containing the following parameters:
   *
   * - `id`: Id of the Storage Domain (View Box)
   *
   * - `regionId`: This field represents the region id.
   *
   * - `fetchStats`: Specifies whether to include usage and performance statistics.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViewBoxByIdResponse(params: ViewBoxesService.GetViewBoxByIdParams): __Observable<__StrictHttpResponse<ViewBox>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.fetchStats != null) __params = __params.set('fetchStats', params.fetchStats.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/viewBoxes/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ViewBox>;
      })
    );
  }
  /**
   * List details about a single Domain (View Box).
   *
   * Returns the Domain (View Box) corresponding to the specified Domain (View Box)
   * Id.
   * @param params The `ViewBoxesService.GetViewBoxByIdParams` containing the following parameters:
   *
   * - `id`: Id of the Storage Domain (View Box)
   *
   * - `regionId`: This field represents the region id.
   *
   * - `fetchStats`: Specifies whether to include usage and performance statistics.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViewBoxById(params: ViewBoxesService.GetViewBoxByIdParams): __Observable<ViewBox> {
    return this.GetViewBoxByIdResponse(params).pipe(
      __map(_r => _r.body as ViewBox)
    );
  }

  /**
   * Update a Domain (View Box).
   *
   * Returns the updated Domain (View Box).
   * @param params The `ViewBoxesService.UpdateViewBoxParams` containing the following parameters:
   *
   * - `id`: Id of the Storage Domain (View Box)
   *
   * - `body`: Request to update a Storage Domain (View Box) configuration.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateViewBoxResponse(params: ViewBoxesService.UpdateViewBoxParams): __Observable<__StrictHttpResponse<ViewBox>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/viewBoxes/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ViewBox>;
      })
    );
  }
  /**
   * Update a Domain (View Box).
   *
   * Returns the updated Domain (View Box).
   * @param params The `ViewBoxesService.UpdateViewBoxParams` containing the following parameters:
   *
   * - `id`: Id of the Storage Domain (View Box)
   *
   * - `body`: Request to update a Storage Domain (View Box) configuration.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateViewBox(params: ViewBoxesService.UpdateViewBoxParams): __Observable<ViewBox> {
    return this.UpdateViewBoxResponse(params).pipe(
      __map(_r => _r.body as ViewBox)
    );
  }

  /**
   * Delete a Domain (View Box).
   *
   * Returns delete status upon completion.
   * @param params The `ViewBoxesService.DeleteViewBoxParams` containing the following parameters:
   *
   * - `id`: Id of the Storage Domain (View Box)
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteViewBoxResponse(params: ViewBoxesService.DeleteViewBoxParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/viewBoxes/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a Domain (View Box).
   *
   * Returns delete status upon completion.
   * @param params The `ViewBoxesService.DeleteViewBoxParams` containing the following parameters:
   *
   * - `id`: Id of the Storage Domain (View Box)
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteViewBox(params: ViewBoxesService.DeleteViewBoxParams): __Observable<null> {
    return this.DeleteViewBoxResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ViewBoxesService {

  /**
   * Parameters for GetViewBoxes
   */
  export interface GetViewBoxesParams {

    /**
     * TenantIds contains ids of the tenants for which objects are to be
     * returned.
     */
    tenantIds?: Array<string>;

    /**
     * Filter list of Storage Domain (View Box) by the properties of the template
     * like dedup, compression.
     * If empty, Storage Domains (View Boxes) are not filtered.
     */
    templateId?: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Filter by a list of Storage Domain (View Box) Names.
     * If empty, Storage Domains (View Boxes) are not filtered by Name.
     */
    names?: Array<string>;

    /**
     * Filter by a list of Storage Domain (View Box) ids.
     * If empty, View Boxes are not filtered by id.
     */
    ids?: Array<number>;

    /**
     * Specifies whether to get time series schema info of the view box.
     */
    fetchTimeSeriesSchema?: boolean;

    /**
     * Specifies whether to include usage and performance statistics.
     */
    fetchStats?: boolean;

    /**
     * Filter by a list of Cluster Partition Ids.
     */
    clusterPartitionIds?: Array<number>;

    /**
     * AllUnderHierarchy specifies if objects of all the tenants under the
     * hierarchy of the logged in user's organization should be returned.
     */
    allUnderHierarchy?: boolean;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateViewBox
   */
  export interface CreateViewBoxParams {

    /**
     * Request to create a Storage Domain (View Box) configuration.
     */
    body: CreateViewBoxParams;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetViewBoxById
   */
  export interface GetViewBoxByIdParams {

    /**
     * Id of the Storage Domain (View Box)
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies whether to include usage and performance statistics.
     */
    fetchStats?: boolean;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateViewBox
   */
  export interface UpdateViewBoxParams {

    /**
     * Id of the Storage Domain (View Box)
     */
    id: number;

    /**
     * Request to update a Storage Domain (View Box) configuration.
     */
    body: CreateViewBoxParams;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteViewBox
   */
  export interface DeleteViewBoxParams {

    /**
     * Id of the Storage Domain (View Box)
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { ViewBoxesService }
