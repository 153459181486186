import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { isNil } from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { GroupAction, ProtectionGroup } from '../../models';
import { ProtectionGroupService } from '../../services';

/**
 * @description
 *
 * Confirmation dialog window when deleting Protection Group.
 */
@Component({
  selector: 'coh-protection-group-delete-dialog',
  templateUrl: './protection-group-delete-dialog.component.html',
  styleUrls: ['./protection-group-delete-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProtectionGroupDeleteDialogComponent {

  /**
   * Form action values.
   */
  groupDeleteForm: UntypedFormGroup;

  /**
   * Feature flag for storage array snapshot policy.
   */
  storageArraySnapshotPolicyEnabled = false;

  /**
   * Specifies whether to only allow snapshot deletion.
   * This could be either the protection group is already deleted or is ProtectOnce.
   * ProtectOnce group is internally deleted in Magneto after the first run.
   */
  isSnapshotDeletionOnly = false;

  /**
   * Returns true if protection group snapshots can be deleted.
   */
  readonly hasCloudVaultSnapshots$: Observable<boolean> = this.groupService
    .getPolicy(this.data?.protectionGroup?.policyId)
    .pipe(
      filter(policy => !isNil(policy)),
      map(policy => policy.remoteTargetPolicy?.rpaasTargets?.length > 0),
      shareReplay(1)
    );

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: { protectionGroup: ProtectionGroup },
    private fb: UntypedFormBuilder,
    private irisCtx: IrisContextService,
    public dialogRef: MatDialogRef<ProtectionGroupDeleteDialogComponent>,
    readonly groupService: ProtectionGroupService
  ) {
    this.storageArraySnapshotPolicyEnabled = flagEnabled(this.irisCtx.irisContext, 'storageArraySnapshotPolicyEnabled') ||
      flagEnabled(this.irisCtx.irisContext, 'ibmFlashSystemStorageArraySnapshotEnabled');

    this.isSnapshotDeletionOnly = !!this.data?.protectionGroup?.isDeleted ||
      // ProtectOnce group is internally deleted in Magneto; hence, only display option to delete snapshot.
      !!this.data?.protectionGroup?.isProtectOnce;
    this.groupDeleteForm = this.fb.group({
      confirmDelete: new UntypedFormControl(null, [Validators.required, Validators.pattern('^([yY][eE][sS])$')]),
      enableSnapshotDeletion: [this.isSnapshotDeletionOnly]
    });
  }

  /**
   * If a protection group is already deleted this will allow us to delete the snapshots.
   */
  deleteSnapshots() {
    if (this.groupDeleteForm.controls.confirmDelete.invalid) {
      this.groupDeleteForm.controls.confirmDelete.markAsTouched();
      return;
    }
    this.dialogRef.close(GroupAction.DeleteSnapshots);
  }

  /**
   * Gets the delete action wanted by the user
   *
   * @returns GroupAction.DeleteSnapshots if the slide-toggle is activated otherwise GroupAction.Delete
   */
  onDelete() {
    if (this.groupDeleteForm.invalid) {
      return;
    }
    this.dialogRef.close(
      this.groupDeleteForm.get('enableSnapshotDeletion').value ? GroupAction.DeleteSnapshots : GroupAction.Delete
    );
  }
}
