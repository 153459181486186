import { Injectable } from '@angular/core';
import { getNavList } from '@cohesity/data-govern/security-center';
import { NavItem, updateNavActiveStatus, updateNavBaseHref } from '@cohesity/helix';
import { appConfigMap, AppName, AppType, IrisContextService, PillarType, translateAppName } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';

import { ServiceNavProvider } from '../service-nav-provider';

/**
 * This Service configures the Security Center view navigation menu with iris.
 */
@Injectable({ providedIn: 'root' })
export class SecurityCenterNavService implements ServiceNavProvider {
  pillar: PillarType = 'security';

  app: AppType = 'dgaas';

  private appName: AppName = 'securityCenter';

  private appConfig = appConfigMap[this.appName];

  get serviceNavTitle(): string {
    return translateAppName(this.translate)(this.appName);
  }

  showNavBranding = true;

  serviceNavTitleIcon = this.appConfig.icon;

  serviceNavTitleClass = 'securityCenter';

  updateNavActiveStatus = updateNavActiveStatus;

  getNavItems(): NavItem[] {
    return updateNavBaseHref(getNavList(this.translate, this.irisContext.irisContext), this.appConfig.urlSegment);
  }

  constructor(
    private translate: TranslateService,
    private irisContext: IrisContextService
  ) {}
}
