// Component: Azure Managed Disks

;(function(angular) {
  'use strict';

  angular
    .module('C.azureManagedDisks', [])
    .controller('AzureManagedDisksCtrl', AzureManagedDisksCtrlFn)
    .component('azureManagedDisks', {
      bindings:{
        /**
         * The number of columns to span while display
         *
         * @type  {Number}  [colSpan=12]
         */
        colSpan: '<?',

        /**
         * The environment whose VM is being cloned/deployed to azure
         *
         * @type  {String}
         */
        parentEnv: '<?',
      },
      // Required outside things. These show up on `this.ngModel` in the
      // controller.
      require: {
        ngModel: 'ngModel',
      },
      templateUrl:
        'app/protection/common/azure-managed-disks/azure-managed-disks.html',
      controller: 'AzureManagedDisksCtrl',
    });

  /**
   * @ngdoc component
   * @name azureManagedDisks
   *
   * @description
   * This component provides a means of selecting disk types (SSD/HDD) when
   * trying to clone/spin a VM to an Azure target.
   *
     @example
      <azure-managed-disks
        id="azure-managed-disks"
        parent-env="'kVMware'"
        ng-model="myModel"
        col-span="6">
      </azure-managed-disks>
   */

  function AzureManagedDisksCtrlFn(_, FEATURE_FLAGS, ENV_GROUPS) {
    var $ctrl = this;

    /**
     * Initializes the default parameters for recovery.
     *
     * @method   initializeDefaultValues
     */
    $ctrl.$onInit = function onInit() {
      _.assign($ctrl, {
        colSpan: $ctrl.colSpan || 12,
        diskTypes: [{
          name: 'Premium SSD',
          value: 'kPremiumSSD',
        }, {
          name: 'Standard SSD',
          value: 'kStandardSSD',
        }, {
          name: 'Standard HDD',
          value: 'kStandardHDD',
        }],
        dataDiskType: 'kStandardSSD',
        osDiskType: 'kStandardSSD',
        ENV_GROUPS: ENV_GROUPS,
        FEATURE_FLAGS: FEATURE_FLAGS,

        //methods
        diskTypeSelected: diskTypeSelected,
        getSetAzureManagedDisks: getSetAzureManagedDisks,
      });
    }

    /**
     * Callback function whenever a disk type is selected
     *
     * @param   {Object}   diskTypeConf    Configuration for types of disks
     *                                     to be used
     */
    function diskTypeSelected(diskTypeConf) {
      var modelValue = $ctrl.ngModel.$modelValue || {};
      var azureManagedDiskParams = modelValue.azureManagedDiskParams || {};

      _.each(diskTypeConf, function eachDiskType(value, key) {
        azureManagedDiskParams[key] = value;
      });

      modelValue.azureManagedDiskParams = azureManagedDiskParams;

      $ctrl.ngModel.$setViewValue(modelValue);
    }

    /**
     * getter setter for setting 'use managed disks' on/off
     *
     * @method   getSetAzureManagedDisks
     * @param    {Boolean}  toSet  to set or get
     *
     * @return   {Number}   The state of toggle.
     */
    function getSetAzureManagedDisks(toSet) {
      var modelValue;

      // arguments list would be present if used as setter
      if (angular.isDefined(toSet)) {
        $ctrl.useAzureManagedDisks = toSet;
        modelValue = $ctrl.ngModel.$modelValue || {};

        // reset property in the parent model if toggled off
        if (!toSet) {
          modelValue.azureManagedDiskParams = undefined;
          $ctrl.ngModel.$setViewValue(modelValue);
        } else {
          // default selection
          diskTypeSelected({
            osDiskSKUType: $ctrl.osDiskType,
            dataDisksSKUType: $ctrl.dataDiskType,
          });
        }
      }

      return $ctrl.useAzureManagedDisks;
    }
  }
})(angular);