import { Injectable } from '@angular/core';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { Transition } from '@uirouter/core';

import { McmViewService } from '../../services';
import { GuardPriority, GuardResult, StateGuard } from '../state-guard';

/**
 * Recover landing page Guard redirects all state traffic to the NG recovery landing state if
 * the feature flag is enabled.
 */
@Injectable({
  providedIn: 'root',
})
export class RecoverGuard implements StateGuard {
  /**
   * This guard priority is set to App, which indicates it should run as part of the main application
   * after all bootstrapping and initializing is completed.
   */
  guardPriority = GuardPriority.App;

  /**
   * Match all navigation to recover landing page (classic ui state).
   */
  matchCriteria = {
    to: state => ['recover', 'recover-local', 'recover-cloud', 'recover-tape'].includes(state.name)
  };

  constructor(private irisCtx: IrisContextService,
    private mcmService: McmViewService) {}

  /**
   * Redirect to recovery landing page with the params.
   *
   * @param   transition  The ui router transition
   * @returns a redirect transition if the protection group feature flag is enabled.
   */
  onStart(transition: Transition): GuardResult {
    const params = transition.params();
    const mappedParams = { ...params };

    if (flagEnabled(this.irisCtx.irisContext, 'ngRecovery') && !params.force &&
      this.mcmService.canAccessState('recovery.list')) {
      const stateName = transition.targetState().name();

      if (stateName === 'recover-cloud') {
        mappedParams.targetType = 'Cloud';
      } else if (stateName === 'recover-tape') {
        mappedParams.targetType = 'Tape';
      }
      return transition.router.stateService.target('recovery.list', mappedParams);
    }
  }
}
