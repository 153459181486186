<cog-slicer>
  <mat-button-toggle-group #viewSwitch [value]="value" (change)="onChange($event)" [disabled]="disabled">
    <mat-button-toggle
      *ngFor="let item of items"
      id="mat-btn-{{ item.id }}"
      [cogDataId]="'view-switcher-button-' + (item.name || item.tooltip)"
      [trackingEvent]="{ properties: { value: item.name || item.tooltip } }"
      [matTooltip]="item.tooltip"
      matTooltipPosition="below"
      [value]="item.id"
      [disabled]="item.disabled">
      <mat-icon *ngIf="item.icon?.length">{{ item.icon }}</mat-icon>
      <span *ngIf="item.name?.length">
        {{ item.name }}
      </span>
    </mat-button-toggle>
  </mat-button-toggle-group>
</cog-slicer>
