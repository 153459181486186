import { DecommissionCloudRigelStatusResult, Metric, ProvisionCloudRigelStatusResult, RigelCloudInfraInfo, RigelClusterUpgradeInfo, RigelGroup } from '@cohesity/api/rms';
import { McmSource, McmSourceInfo, McmSourceRegistration, SourceRegistration } from '@cohesity/api/v2';
import { DmsConnector } from './dms-connector';
import { NavItem } from '@cohesity/helix';
import { decorateInterface } from '@cohesity/utils';
import { DmsConnectorGroup } from './dms-connector-group';
import { Environment } from '@cohesity/iris-shared-constants';

/**
 * Rigel metric and status values used as index.
 */
type MetricAndStatus = Metric | 'Utilization' | 'Connected' | 'NotConnected' | 'SetupInProgress' | 'NumConnectors';

/**
 * Rigel metric result.
 */
export type DmsConnectionMetric = {
  /** DMS Metric */
  [key in MetricAndStatus]?: number;
};

/**
 * Interface for sources for dms connections page.
 */
export interface DmsConnectionSources {
  /** The array of all sources */
  sources: McmSource[];

  /** Map of registration id and corresponding source details */
  registrationMap: Record<string, McmSourceRegistration>;
}

/**
 * Interface for sources on cluster connections page.
 */
export interface ClusterConnectionSources {
  /** The array of all sources on the cluster */
  sources: SourceRegistration[];
}

/**
 * Interface for provision/decommission status calls.
 */
export interface DmsConnectionStatus {
  /** Group id of the connector */
  groupId?: number;

  /** Request id of the connection */
  requestId?: string;
}

export interface ConnectionEvents {
  /** Progress status list */
  messages?: string[];

  /** Timestamp of the progress events */
  timestampSecs?: number;
}

/**
 * Data model for DMaaS connection.
 */
export class DmsConnection extends decorateInterface<RigelGroup>() {
  /** Throughput */
  throughput?: number;

  /** Connection Metric */
  stats?: DmsConnectionMetric;

  /** List of source IDs associated with the connection (for filtering purpose) */
  sources?: string[];

  /** List of source objects associated with the connection on DMS(for display purpose) */
  sourceObjects?: (McmSource & McmSourceInfo)[];

  /** List of source objects associated with the connection on Cluster(for display purpose) */
  clusterSourceObjects?: SourceRegistration[];

  /** List of connectors belongs to the connection */
  connectors?: DmsConnector[];

  /** List of connector groups belongs to the connection */
  connectorGroups?: DmsConnectorGroup[];

  /** Last connection time */
  lastConnectionTimeUsecs?: number;

  /** Actions for the connection */
  actions?: NavItem[];

  /** Number of connectors under all connector groups. */
  numConnectors?: number;

  /** Parent ID (connection ID). This implies this is a connector group although treated as connection */
  parentId?: number;

  /** Expected number of rigels to be deployed */
  expectedNumberOfRigels?: number;

  /** Status of a rigel provision or decommission */
  cloudStatus?: ProvisionCloudRigelStatusResult | DecommissionCloudRigelStatusResult;

  /** Progress steps list for an InProgress rigel provision or decommission*/
  auditMessages?: ConnectionEvents[];

  /** Specifies cloud infrastructure details. */
  rigelCloudInfraInfo?: RigelCloudInfraInfo;

  /** Icon for the status of provision/decommission. */
  progressIcon?: string;

  /** Checks if force delete is enabled on the connection */
  isForceDeleteEnabled?: boolean;

  /** Connection source */
  environment?: Environment;

  /** Checks if connection needs an upgrade */
  upgradeNeeded?: boolean;

  /** Status of a rigel upgrade */
  upgradeStatus?: string;

  /** Rigel Upgrade status response object */
  upgradeStatusObject?: RigelClusterUpgradeInfo;

  /**
   * Constructor.
   *
   * @param   reference   Return data structure of Rigel Group from API
   */
  constructor(rigelGroup: RigelGroup) {
    super(rigelGroup);
    if (this.connectorGroups?.length) {
      this.numConnectors = this.connectorGroups.reduce(
        (count, item) => count + (item.connectors?.length || 0),
        0,
      );
      this.connectorGroups = this.connectorGroups
        .map(group => new DmsConnectorGroup(group, this.groupId, this))
        .sort((a, b) =>
          a.isUngroup ? 1 : a.connectorGroupName?.localeCompare(b.connectorGroupName)
        );
      this.connectorGroups.forEach(connectorGroup => {
        if (!connectorGroup.connectors) {
          connectorGroup.connectors = [];
        }
        if (connectorGroup.connectors?.length) {
          connectorGroup.connectors = connectorGroup.connectors
            .map(connector => new DmsConnector(connector))
            .sort((a, b) => a.rigelIp.localeCompare(b.rigelIp));
        }
      });
    } else {
      this.numConnectors = 0;
    }
  }
}
