// Controller: Storage Growth & Variance

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.reports')
    .controller('reportsStorageGrowthController', reportsStorageGrowthControllerFn);

  function reportsStorageGrowthControllerFn($scope, $filter, $q,
    DateTimeService, ReportsService, SourceService, evalAJAX, cUtils,
    CHART_COLORS, cReportsControlsUtil, ReportsUtil) {

    $scope.storageGrowthConfig =
      ReportsUtil.getReportConfig('kSourceGrowthAndVarianceReport')();

    $scope.getData = getData;
    $scope.downloadReport = downloadReport;

    $scope.sources = [];

    /**
     * init function for initial report and listeners setup
     *
     * @return {Void}
     */
    function init() {
      var defaultFilters;

      defaultFilters = cReportsControlsUtil.getDefaultFilters({
        lastNDays: 7,
        registeredSourceIds: [],
      });

      getData(defaultFilters);
    }

    /**
     * builds a params object based on current filter settings
     *
     * @param      {object}    filters    filters from c-reports-controls
     *
     * @return     {Object}    params     object for API submission
     */
    function getParams(filters) {
      return {
        msecsBeforeEndTime: (filters.lastNDays * 86400000),
        sourceIds: filters.registeredSourceIds,
      };
    }

    /**
     * requests a csv download via ReportsService
     *
     * @return {Void}
     */
    function downloadReport() {
      var params = getParams(cReportsControlsUtil.filters);
      params.outputFormat = 'csv';

      ReportsService.getStorageBySources(params);
    }

    /**
     * gets and parses the report data from the API
     *
     * @param      {object}    filters    filters from c-reports-controls
     */
     function getData(filters) {
      $scope.sourcesGrowthChart.loading = true;
      $scope.dataReady = false;
      $scope.sources = [];
      $scope.sourcesGrowthChart.series = [];

      $scope.nDaysLabel = cReportsControlsUtil.filters.lastNDays;

      ReportsService.getStorageBySources(getParams(filters)).then(
        function getStorageBySourcesSuccess(r) {
          var lastDataPointIndex;

          $scope.sources = r.data.reduce(
            function reduceSources(sources, source, index) {
              // if this source has no dataPoints, skip it
              if (!angular.isArray(source.dataPoints) || !source.dataPoints.length) {
                return sources;
              }
              lastDataPointIndex = source.dataPoints.length - 1;
              source._name = SourceService.getEntityName(source.registeredSource);
              source._objectCountNDaysAgo =
                source.dataPoints.length <= $scope.nDaysLabel ?
                  undefined : source.dataPoints[0].objectCount;

              source._objectCount =
                source.dataPoints[lastDataPointIndex].objectCount || 0;

              source._logicalSizeBytes =
                source.dataPoints[lastDataPointIndex].logicalSizeBytes;

              source._primaryPhysicalSizeBytes =
                source.dataPoints[lastDataPointIndex].primaryPhysicalSizeBytes;

              source._primaryPhysicalSizeBytesNDaysAgo =
                source.dataPoints.length <= $scope.nDaysLabel ?
                  undefined : source.dataPoints[0].primaryPhysicalSizeBytes;

              if (source.varPhysicalOnPrimaryPct !== undefined &&
                source.varPhysicalOnPrimaryPct !== null) {
                source._variance =
                  cUtils.round(source.varPhysicalOnPrimaryPct, 2) + '%';
              }

              sources.push(source);

              return sources;
            }, []);

          buildSourcesGrowthChart($scope.sources);
        }, evalAJAX.errorMesssage)
      .finally(function getStorageBySourcesFinally() {
        $scope.sourcesGrowthChart.loading = false;
        $scope.dataReady = true;
      });
    }

    // Build Storage Data Arrays for Chart
    function buildSourcesGrowthChart(sources) {
      var seriesArray = [];
      // we should always have one more data point than nDays
      // as we are comparing today to past days in 24 hour intervals
      var numDataPoints = cReportsControlsUtil.filters.lastNDays + 1;
      var startPointMsecs;

      sources.forEach(function(src, index) {
        var maxLogicalDPValue;
        var physicalUsageArray = [];

        src.dataPoints.forEach(function(dataPoint, n) {
          if (n < numDataPoints) {
            physicalUsageArray.push(dataPoint.primaryPhysicalSizeBytes || null);
          }
        });

        // If we don't have enough values to plot the entire timeline
        // unshift physicalUsageArray with null values to compensate
        if (physicalUsageArray.length < numDataPoints) {
          // earliestMsecs = physicalUsageArray[0][0];
          while (physicalUsageArray.length < numDataPoints) {
            // i++;
            physicalUsageArray.unshift(null);
          }
        }

        // calculate our interval starting point by getting the last provided timestamp,
        // subtracting a days worth of msecs multiplied by number of days, and then
        // rounding it to the beginning of day
        startPointMsecs = (src.dataPoints[src.dataPoints.length - 1].snapshotTimeUsecs / 1000) - cReportsControlsUtil.filters.lastNDays * 86400000;
        startPointMsecs = DateTimeService.beginningOfDay(new Date(startPointMsecs));
        startPointMsecs = startPointMsecs.getTime();

        seriesArray.push({
          data: physicalUsageArray,
          name: SourceService.getEntityName(src.registeredSource),
          pointStart: startPointMsecs,
          pointInterval: 86400000
        });
      });

      // Update Chart with Data
      $scope.sourcesGrowthChart.series = seriesArray;
    }

    // Sources Growth Chart
    $scope.sourcesGrowthChart = {
      chartType: 'bytesLine',
      loading: true,
      series: [],
      legend: {
        layout: 'horizontal',
        verticalAlign: 'top',
        align: 'left',
        borderWidth: 0
      },
      chart: {
        height: 300,
        marginTop: 50,
        marginLeft: 1
      },
      xAxis: {
        type: 'datetime',
        minTickInterval: 24 * 3600 * 1000 // 1 day
      },
      yAxis: {
        title: {
          text: null
        },
        min: 0
      },
      tooltip: {
        formatter: function bytesLineTooltipFormatter() {
          var xLabel;
          xLabel = DateTimeService.msecsToFormattedDate(new Date(this.x).getTime(), DateTimeService.getPreferredDateFormat());

          return [ xLabel, '<br>', this.series.name, ': ', cUtils.bytesToSize(this.y).string].join('');
        }
      },
      colors: cUtils.arrayShuffle(CHART_COLORS.tenColorPalette())
    };

    // run setup function on load
    init();
  }
})(angular);
