// MODULE: Performance Controller

;(function(angular, undefined) {
  'use strict';

  angular.module('C.statistics')
    .controller('PerformanceController', PerformanceControllerFn);

  function PerformanceControllerFn($rootScope, $scope, $q, cUtils, evalAJAX,
    StatsService, DateTimeService, CHART_COLORS) {

    var $ctrl = this;

    angular.extend($scope.text, $rootScope.text.monitoringPerformancePerformance);

    // convenience function
    $scope.bytesToSize = cUtils.bytesToSize;

    // Throughput Chart
    $scope.throughputChart = {
      chartType: 'bytesLine',
      compact: true,
      loading: true,
      yAxisLabelAppend: $scope.text.perSec,
      chart: {
        zoomType: 'x',
        height: 150,
        resetZoomButton: {
          theme: {
            display: 'none'
          }
        }
      },
      series: [{
        name: $scope.text.read,
        type: 'line',
        data: []
      }, {
        name: $scope.text.write,
        type: 'line',
        data: []
      }],
      colors: CHART_COLORS.neutralPair4,
      xAxis: $scope.xAxisShared,
      yAxis: {
        min: 0,
        labels: {
          // to be updated based on calculations (GiB, TiB, etc)
          format: ''
        }
      },
      tooltip: {
        // to be updated based on calculations (GiB, TiB, etc)
        valueSuffix: ''
      }
    };

    // IOPS Chart
    $scope.iopsChart = {
      chartType: 'line',
      compact: true,
      loading: true,
      chart: {
        zoomType: 'x',
        height: 150,
        resetZoomButton: {
          theme: {
            display: 'none'
          }
        }
      },
      series: [{
        name: $scope.text.read,
        type: 'line',
        data: []
      }, {
        name: $scope.text.write,
        type: 'line',
        data: []
      }],
      colors: CHART_COLORS.neutralPair4,
      xAxis: $scope.xAxisShared,
      yAxis: {
        labels: {
          format: '{value} IOPS'
        },
        min: 0,
        showFirstLabel: false
      },
      tooltip: {
        valueSuffix: ' IOPS'
      }
    };

    // Latency Chart (Test And Dev)
    $scope.latencyChart = {
      chartType: 'line',
      compact: true,
      loading: true,
      chart: {
        zoomType: 'x',
        height: 150,
        resetZoomButton: {
          theme: {
            display: 'none'
          }
        }
      },
      series: [{
        name: $scope.text.read,
        type: 'line',
        data: []
      }, {
        name: $scope.text.write,
        type: 'line',
        data: []
      }],
      colors: CHART_COLORS.neutralPair4,
      xAxis: $scope.xAxisShared,
      yAxis: {
        labels: {
          format: '{value} ' + $scope.text.ms
        },
        min: 0,
        showFirstLabel: false
      },
      tooltip: {
        valueSuffix: ''
      }
    };

    // CPU & Memory Chart
    $scope.cpuMemoryChart = {
      chartType: 'line',
      compact: true,
      loading: true,
      chart: {
        zoomType: 'x',
        height: 150,
        resetZoomButton: {
          theme: {
            display: 'none'
          }
        }
      },
      series: [{
        name: $scope.text.cpuUsage,
        type: 'line',
        data: []
      }, {
        name: $scope.text.memoryUsage,
        type: 'line',
        data: []
      }],
      colors: CHART_COLORS.neutralPair1,
      xAxis: $scope.xAxisShared,
      yAxis: {
        labels: {
          format: '{value}%'
        },
        min: 0,
        max: 100,
        showFirstLabel: false
      },
      tooltip: {
        valueSuffix: '%'
      }
    };

    $scope.averageThroughputRead = null;
    $scope.averageThroughputWrite = null;
    $scope.averageIopsRead = null;
    $scope.averageIopsWrite = null;
    $scope.averageLatencyRead = null;
    $scope.averageLatencyWrite = null;
    $scope.averageCpu = null;
    $scope.averageMemory = null;

    /**
     * Init this controller.
     *
     * @method   $onInit
     */
    $ctrl.$onInit = function $onInit() {
      // Additional configuration for visual co-relation
      _configureChartsForMcmMode();
    };

    /**
     * Updates IOPS, Throughput, CPU charts with additional configuration
     */
    function _configureChartsForMcmMode() {
      // If mcm mode, additional properties are added for visual co-relation.
      if ($rootScope.basicClusterInfo.mcmMode) {
        // For synchronized charts, crosshair is enabled
        _.merge($scope.xAxisShared, { crosshair: true, });

        // Add tooltip formatter for throughput chart
        _.merge($scope.throughputChart, {
          tooltip: {
            shared: true,
            formatter: function bytesLineTooltipFormatter() {
              var xLabel = DateTimeService.msecsToFormattedDate(new Date(this.x).getTime());
              var yLabels = [];

              this.points.forEach(function addLabels(point) {
                yLabels.push('<br><span style="color:' +
                  point.series.color + '">\u25CF</span> ' +
                  point.series.name + ': ' + '<strong>' +
                  cUtils.bytesToSize(point.y).string + '</strong>');
              });

              return [xLabel, yLabels].join('');
            },
          }
        });

        // Shared tooltip is set for all charts
        _.merge($scope.iopsChart, { tooltip: { shared: true, }, });
        _.merge($scope.latencyChart, { tooltip: { shared: true, }, });
        _.merge($scope.cpuMemoryChart, { tooltip: { shared: true, }, });
      }
    }

    /**
     * sets up our parameters based on current values and
     * calls the various functions that handle chart setup
     * @return {void}
     */
    $scope.updateCharts = function updateCharts() {

      var timeParams = $scope.getTimeParams(true);

      if ($scope.currentEntity.throughput) {
        buildThroughputChart(timeParams);
      }

      if ($scope.currentEntity.iops) {
        buildIopsChart(timeParams);
      }

      if ($scope.currentEntity.latency) {
        buildLatencyChart(timeParams);
      }

      if ($scope.currentEntity.cpuMemory) {
        buildCpuMemoryChart(timeParams);
      }

      $scope.showResetButton = false;

      $scope.buildAlertsChart(timeParams);
    };

    // when the parent controller broadcasts updateCharts message
    // obey the broadcast and run the function
    $scope.$on('updateCharts', $scope.updateCharts);

    /**
     * Calls API and constructs data for throughput chart.
     * @param  {Object} timeParams the time parameters as returned from StatsService.getTimeRangeParams
     * @return {void}
     */
    function buildThroughputChart(timeParams) {

      var throughputWriteParams = {
        entityId: $scope.currentEntity.id,
        schemaName: $scope.currentEntity.throughput.schemaName,
        metricName: $scope.currentEntity.throughput.writeMetric,
        rollupFunction: $scope.currentEntity.throughput.rollupFn,
        range: $scope.dateInfo.currentRange,
        startTimeMsecs: timeParams.startTimeMsecs
      };

      var throughputReadParams = {
        entityId: $scope.currentEntity.id,
        schemaName: $scope.currentEntity.throughput.schemaName,
        metricName: $scope.currentEntity.throughput.readMetric,
        rollupFunction: $scope.currentEntity.throughput.rollupFn,
        range: $scope.dateInfo.currentRange,
        startTimeMsecs: timeParams.startTimeMsecs
      };

      var promiseArray = [];
      var readData = [];
      var writeData = [];
      var maxReadValue;
      var maxWriteValue;
      var tpUnit;

      $scope.averageThroughputRead = null;
      $scope.averageThroughputWrite = null;

      // set chart as loading
      $scope.throughputChart.loading = true;

      // Request throughput data
      promiseArray.push(
        StatsService.getTimeSeries(throughputWriteParams),
        StatsService.getTimeSeries(throughputReadParams)
      );

      $q.all(promiseArray).then(function(response) {
        if (response[0].data && response[0].data.dataPointVec) {
          writeData = StatsService.buildIntervalDataSet(response[0].data.dataPointVec, throughputWriteParams);
        }
        if (response[1].data && response[1].data.dataPointVec) {
          readData = StatsService.buildIntervalDataSet(response[1].data.dataPointVec, throughputReadParams);
        }
        processData();
      }, function(error) {
        // clear data since it won't be replaced
        // and set loading to false
        $scope.throughputChart.series[0].data = [];
        $scope.throughputChart.series[1].data = [];
        $scope.throughputChart.loading = false;
      });

      function processData() {
        if (readData.length !== writeData.length) {
          $log.error('throughput dataset length mismatch', readData, writeData);
        }

        if (cUtils.arrayFilteredAverage(readData) !== null) {
          $scope.averageThroughputRead = cUtils.arrayFilteredAverage(readData);
        }
        if (cUtils.arrayFilteredAverage(writeData) !== null) {
          $scope.averageThroughputWrite = cUtils.arrayFilteredAverage(writeData);
        }

        // update chartConfig object with new values
        $scope.throughputChart.series[0].data = readData;
        $scope.throughputChart.series[0].pointInterval = throughputReadParams.rollupIntervalSecs * 1000;
        $scope.throughputChart.series[0].pointStart = throughputReadParams.startTimeMsecs;

        $scope.throughputChart.series[1].data = writeData;
        $scope.throughputChart.series[1].pointInterval = throughputWriteParams.rollupIntervalSecs * 1000;
        $scope.throughputChart.series[1].pointStart = throughputWriteParams.startTimeMsecs;

        $scope.throughputChart.loading = false;
      }
    }

    /**
     * calls API and constructs data for IOPs chart
     * @param  {Object} timeParams the time parameters as returned from StatsService.getTimeRangeParams
     * @return {void}
     */
    function buildIopsChart(timeParams) {

      var iopsWriteParams = {
        entityId: $scope.currentEntity.id,
        schemaName: $scope.currentEntity.iops.schemaName,
        metricName: $scope.currentEntity.iops.writeMetric,
        rollupFunction: $scope.currentEntity.iops.rollupFn,
        range: $scope.dateInfo.currentRange,
        startTimeMsecs: timeParams.startTimeMsecs
      };

      var iopsReadParams = {
        entityId: $scope.currentEntity.id,
        schemaName: $scope.currentEntity.iops.schemaName,
        metricName: $scope.currentEntity.iops.readMetric,
        rollupFunction: $scope.currentEntity.iops.rollupFn,
        range: $scope.dateInfo.currentRange,
        startTimeMsecs: timeParams.startTimeMsecs
      };

      var promiseArray = [];
      var readData = [];
      var writeData = [];

      $scope.averageIopsRead = null;
      $scope.averageIopsWrite = null;

      // set chart as loading
      $scope.iopsChart.loading = true;

      // Request throughput data
      promiseArray.push(
        StatsService.getTimeSeries(iopsWriteParams),
        StatsService.getTimeSeries(iopsReadParams)
      );

      $q.all(promiseArray).then(function(response) {
        if (!evalAJAX.success(response[0]) || !evalAJAX.success(response[1])) {
          $scope.iopsChart.series[0].data = [];
          $scope.iopsChart.series[1].data = [];
          $scope.iopsChart.loading = false;
          return;
        }
        if (response[0].data && response[0].data.dataPointVec) {
          writeData = StatsService.buildIntervalDataSet(
            response[0].data.dataPointVec,
            iopsWriteParams
          );
        }
        if (response[1].data && response[1].data.dataPointVec) {
          readData = StatsService.buildIntervalDataSet(
            response[1].data.dataPointVec,
            iopsReadParams
          );
        }
        processData();
      }, function qAllError(error) {
        // clear data since it won't be replaced
        // and set loading to false
        $scope.iopsChart.series[0].data = [];
        $scope.iopsChart.series[1].data = [];
        $scope.iopsChart.loading = false;
      });

      function processData() {
        if (readData.length !== writeData.length) {
          $log.error('iops dataset length mismatch', readData, writeData);
        }

        if (cUtils.arrayFilteredAverage(readData, 0) !== null) {
          $scope.averageIopsRead = cUtils.arrayFilteredAverage(readData, 0);
        }
        if (cUtils.arrayFilteredAverage(writeData, 0) !== null) {
          $scope.averageIopsWrite = cUtils.arrayFilteredAverage(writeData, 0);
        }

        // update chartConfig object with new values
        $scope.iopsChart.series[0].data = readData;
        $scope.iopsChart.series[0].pointInterval = iopsReadParams.rollupIntervalSecs * 1000;
        $scope.iopsChart.series[0].pointStart = iopsReadParams.startTimeMsecs;

        $scope.iopsChart.series[1].data = writeData;
        $scope.iopsChart.series[1].pointInterval = iopsWriteParams.rollupIntervalSecs * 1000;
        $scope.iopsChart.series[1].pointStart = iopsWriteParams.startTimeMsecs;

        $scope.iopsChart.loading = false;
      }
    }


    /**
     * calls API and constructs data for latency chart (Test and Dev)
     * @param  {Object} timeParams the time parameters as returned from StatsService.getTimeRangeParams
     * @return {void}
     */
    function buildLatencyChart(timeParams) {
      var workloadType = 'TestAndDev';

      var latencyWriteParams = {
        entityId: [$scope.currentEntity.id, workloadType].join(':'),
        schemaName: $scope.currentEntity.latency.schemaName,
        metricName: $scope.currentEntity.latency.writeMetric,
        rollupFunction: $scope.currentEntity.latency.rollupFn,
        range: $scope.dateInfo.currentRange,
        startTimeMsecs: timeParams.startTimeMsecs
      };

      var latencyReadParams = {
        entityId: [$scope.currentEntity.id, workloadType].join(':'),
        schemaName: $scope.currentEntity.latency.schemaName,
        metricName: $scope.currentEntity.latency.readMetric,
        rollupFunction: $scope.currentEntity.latency.rollupFn,
        range: $scope.dateInfo.currentRange,
        startTimeMsecs: timeParams.startTimeMsecs
      };

      var promiseArray = [];
      var readData = [];
      var writeData = [];
      var maxReadValue;
      var maxWriteValue;
      var tpUnit;

      $scope.averageLatencyRead = null;
      $scope.averageLatencyWrite = null;

      // set chart as loading
      $scope.latencyChart.loading = true;

      // Request throughput data
      promiseArray.push(
        StatsService.getTimeSeries(latencyWriteParams),
        StatsService.getTimeSeries(latencyReadParams)
      );

      $q.all(promiseArray).then(function(response) {
        if (response[0].data && response[0].data.dataPointVec) {
          writeData = StatsService.buildIntervalDataSet(response[0].data.dataPointVec, latencyWriteParams);
        }
        if (response[1].data && response[1].data.dataPointVec) {
          readData = StatsService.buildIntervalDataSet(response[1].data.dataPointVec, latencyReadParams);
        }
        processData();
      }, function(error) {
        // clear data since it won't be replaced
        // and set loading to false
        $scope.latencyChart.series[0].data = [];
        $scope.latencyChart.series[1].data = [];
        $scope.latencyChart.loading = false;
      });

      function processData() {
        if (readData.length !== writeData.length) {
          $log.error('throughput dataset length mismatch', readData, writeData);
        }

        // convert usecs to msecs
        readData = readData.map(function(value) {
          if (value) {
            return value / 1000;
          }
          return value;
        });
        writeData = writeData.map(function(value) {
          if (value) {
            return value / 1000;
          }
          return value;
        });

        if (cUtils.arrayFilteredAverage(readData, 0) !== null) {
          $scope.averageLatencyRead = cUtils.arrayFilteredAverage(readData, 0);
        }
        if (cUtils.arrayFilteredAverage(writeData, 0) !== null) {
          $scope.averageLatencyWrite = cUtils.arrayFilteredAverage(writeData, 0);
        }

        // update chartConfig object with new values
        $scope.latencyChart.series[0].data = readData;
        $scope.latencyChart.series[0].pointInterval = latencyReadParams.rollupIntervalSecs * 1000;
        $scope.latencyChart.series[0].pointStart = latencyReadParams.startTimeMsecs;

        $scope.latencyChart.series[1].data = writeData;
        $scope.latencyChart.series[1].pointInterval = latencyWriteParams.rollupIntervalSecs * 1000;
        $scope.latencyChart.series[1].pointStart = latencyWriteParams.startTimeMsecs;

        $scope.latencyChart.tooltip.valueSuffix = ' ' + $scope.text.ms;
        $scope.latencyChart.yAxis.labels.format = '{value} ' + $scope.text.ms;
        $scope.latencyChart.loading = false;
      }
    }

    /**
     * calls API and constructs data for throughput chart
     * @param  {Object} timeParams the time parameters as returned from StatsService.getTimeRangeParams
     * @return {void}
     */
    function buildCpuMemoryChart(timeParams) {

      var cpuParams = {
        entityId: $scope.currentEntity.id,
        schemaName: $scope.currentEntity.cpuMemory.schemaName,
        metricName: $scope.currentEntity.cpuMemory.cpuMetric,
        rollupFunction: $scope.currentEntity.cpuMemory.rollupFn,
        range: $scope.dateInfo.currentRange,
        startTimeMsecs: timeParams.startTimeMsecs
      };

      var memoryParams = {
        entityId: $scope.currentEntity.id,
        schemaName: $scope.currentEntity.cpuMemory.schemaName,
        metricName: $scope.currentEntity.cpuMemory.memoryMetric,
        rollupFunction: $scope.currentEntity.cpuMemory.rollupFn,
        range: $scope.dateInfo.currentRange,
        startTimeMsecs: timeParams.startTimeMsecs
      };

      var promiseArray = [];
      var cpuData = [];
      var memoryData = [];

      $scope.averageCpu = null;
      $scope.averageMemory = null;

      // set chart as loading
      $scope.cpuMemoryChart.loading = true;

      // Request throughput data
      promiseArray.push(
        StatsService.getTimeSeries(cpuParams),
        StatsService.getTimeSeries(memoryParams)
      );

      $q.all(promiseArray).then(
        function qAllSuccess(responses) {
          if (responses[0].data && responses[0].data.dataPointVec) {
            cpuData = StatsService.buildIntervalDataSet(responses[0].data.dataPointVec, cpuParams);
          }
          if (responses[1].data && responses[1].data.dataPointVec) {
            memoryData = StatsService.buildIntervalDataSet(responses[1].data.dataPointVec, memoryParams);
          }
          processData();
        },
        function qAllFail(response) {
          evalAJAX.errorMessage(response);
          // clear data since it won't be replaced
          // and set loading to false
          $scope.cpuMemoryChart.series[0].data = [];
          $scope.cpuMemoryChart.series[1].data = [];
        }
      ).finally(
        function qAllFinally() {
          $scope.cpuMemoryChart.loading = false;
        }
      );

      function processData() {
        if (cpuData.length !== memoryData.length) {
          $log.error('cpu/memory dataset length mismatch', readData, writeData);
        }

        $scope.averageCpu = cUtils.arrayFilteredAverage(cpuData, 2);
        $scope.averageMemory = cUtils.arrayFilteredAverage(memoryData, 2);

        // update chartConfig object with new values
        $scope.cpuMemoryChart.series[0].data = cpuData;
        $scope.cpuMemoryChart.series[0].pointInterval = cpuParams.rollupIntervalSecs * 1000;
        $scope.cpuMemoryChart.series[0].pointStart = cpuParams.startTimeMsecs;

        $scope.cpuMemoryChart.series[1].data = memoryData;
        $scope.cpuMemoryChart.series[1].pointInterval = memoryParams.rollupIntervalSecs * 1000;
        $scope.cpuMemoryChart.series[1].pointStart = memoryParams.startTimeMsecs;

        $scope.cpuMemoryChart.loading = false;
      }
    }

  }


})(angular);
