// Component: VLANs list
import { ClusterType } from 'src/app/shared/constants';

;(function _iife(angular, undefined) {
  'use strict';

  var vipsConfig = {
    require: {
      /**
       * Optional provide ngModel. If present, allow VLAN selection and selected
       * VLANs are provided via ngModel binding.
       */
      ngModel: '?ngModel',

      /**
       * Optional require cModalHoc controller. If present, used to close or
       * dismiss the modal on VIP modification.
       */
      cModalHoc: '^^?cModalHoc',
    },
    bindings: {
      /**
       * Optional provide the default selected interface group name and when
       * present then disabling changing the selected interface group and all
       * VIP modification will happen only for the provided selected interface
       * group.
       */
      defaultIfaceGroupName: '=?',

      /**
       * Optional attribute sniffed value. If present, then view VLAN navigation
       * will happen in modal
       */
      // inModal: '?',

      /**
       * Optional attribute sniffed value. If present, then hide the filters and
       * vice-versa.
       */
      // noFilters: '?',
    },
    templateUrl: 'app/platform/vips/vip-list.html',
    controller: 'VipListCtrl'
  };

  angular
    .module('C.vips', [])
    .controller('VipListCtrl', vipListCtrlFn)
    .component('vipList', vipsConfig);

  function vipListCtrlFn(_, $q, $attrs, VlanService, evalAJAX, TenantService,
    NetworkService, $state, cUtils, FORMATS, $scope, $timeout, IP_FAMILY,
    NgClusterService) {

    var $ctrl = this;

    // Indicates whether selected interface group VIPs are modified by deleting
    // some and used to inModal view to output the modified interface group
    // when modal is closed or dismissed.
    var isVipModified = false;

    var defaultVlan = {
      addToClusterPartition: true,
      id: undefined,
      gateway: undefined,
      gatewayV6: undefined,
      subnet: {
        netmaskIp4: undefined,
        _CIDR: undefined,
      },
      ifaceGroupName: undefined,
      ips: [],
    };

    _.assign($ctrl, {
      selectedVips: [],
      vips: [],
      selectedInterfaceGroup: undefined,
      ifaceGroupName: '',
      isSubnetRequired: false,
      FORMATS: FORMATS,
      IP_FAMILY: IP_FAMILY,
      ipFamily: IP_FAMILY.IPv4,
      updatingVlan: false,
      deletingVip: false,
      noFilters: $attrs.hasOwnProperty('noFilters'),
      inModal: $attrs.hasOwnProperty('inModal'),
      internalDnsFormValid: false,
      dnsDelegationZones: [],
      isCeCluster: false,
      isIpV6Supported: NetworkService.isIpV6Supported(),

      addVip: addVip,
      deleteVip: deleteVip,
      filterVlans: filterVlans,
      getInterfaceGroupName: VlanService.getInterfaceGroupName,
      getVlans: getVlans,
      loadingVlan: loadingVlan,
      toggleSelection: toggleSelection,
      viewVlanModal: viewVlanModal,

      filter: {
        tenantIds: [],
      },

      $onInit: $onInit,
    });

    /**
     * Initializes component View.
     *
     * @method   $onInit
     */
    function $onInit() {
      // overriding the modal _onClose & cancel method to return selected value
      // when modal is closed or cancelled.
      _.assign($ctrl.cModalHoc, {
        onModalCancel: _onClose,
        onModalClose: _onClose,
      });

      // Get cluster type and check if its CE cluster.
      NgClusterService.getClusterType().toPromise().then(
        function getClusterTypeSuccess(clusterType) {
          $ctrl.isCeCluster = clusterType === ClusterType.CloudEdition;
        }
      );

      getVlans();
      _getInterfaces();
    }

    /**
     * Toggle VLAN selection
     *
     * @method   toggleSelection
     * @param    {Object}   vlan   The vlan to toggle selection
     */
    function toggleSelection(vlan) {
      var selectedVlansMap = $ctrl.ngModel.$modelValue;

      if (selectedVlansMap[vlan._uid]) {
        delete selectedVlansMap[vlan._uid];
      } else {
        selectedVlansMap[vlan._uid] = vlan;
      }

      $ctrl.ngModel.$setViewValue(selectedVlansMap);
    }

    /**
     * Gets the vlans then get interface groups.
     *
     * @method   getVlans
     */
    function getVlans() {
      var params = TenantService.extendWithTenantParams({
        _includeTenantInfo: true,
      }, $ctrl.filter.tenantIds);

      $ctrl.vipsReady = false;

      VlanService.getVlans(params).then(
        function getVlansSuccess(vlans) {
          $ctrl.vlans = vlans;
        }, evalAJAX.errorMessage)
        .finally(function afterGotResponse() {
          _expendVlanByVips($ctrl.vlans);
        });
    }

    /**
     * Filters out the vlan(interface group) on the basis of provided default
     * interface group name.
     *
     * @method   filterVlans
     * @param    {object}  vlan  The vlan (interface group).
     * @return   {Boolean}   Return True if show the provided interface group
     *                       else return false.
     */
    function filterVlans(vlan) {
      if ($ctrl.defaultIfaceGroupName) {
        return $ctrl.defaultIfaceGroupName === vlan.ifaceGroupName;
      }

      // show all vlans (interface groups) if there is not default interface
      // group provided.
      return true;
    }

    /**
     * Launch the view vlan modal when vip-list is configured for modal view.
     *
     * @method   addVlanModal
     * @param    {object}  vlan    The vlan (interface group).
     * @param    {object}  $event  The click event object.
     */
    function viewVlanModal(vlan, $event) {
      if ($ctrl.inModal) {
        // prevent default ui-sref="vlan({...})" link redirection.
        $event.preventDefault();
        VlanService.viewVlanModal(vlan);
      }
    }

    /**
     * Handle the vip-list modal close and dismiss action and used to provided
     * selected interface group value.
     *
     * @method   _onClose
     * @return   {Promise}   Promise resolved with selected interface group or
     *                       null when modal is closed or dismissed.
     */
    function _onClose() {
      return $q.resolve(isVipModified ? $ctrl.selectedInterfaceGroup : null);
    }

    /**
     * Return the default selected interface group object.
     *
     * @method    _getDefaultSelectedInterface
     * @return  {Object}   The selected Interface.
     */
    function _getDefaultSelectedInterface() {
      var out;

      // select the default interface group if found in the list of interfaces.
      if ($ctrl.defaultIfaceGroupName) {
        out = _.find(
          $ctrl.interfaces, ['ifaceGroup', $ctrl.defaultIfaceGroupName]);
      }

      // by default select the 1st interfaces group.
      return out || $ctrl.interfaces[0];
    }

    /**
     * Duplicate the vlan object if it has multiple ips.
     *
     * @param {Array of Objects} vlans
     */
    function _expendVlanByVips(vlans) {
      angular.forEach(vlans, function(vlan) {
        var vipToDnsZonesMap = {};

        // Populate vipToDnsZonesMap with key as vip and value as list of zones.
        if (vlan.dnsDelegationZones) {
          angular.forEach(vlan.dnsDelegationZones,
            function zoneIterator(dnsZone) {
              angular.forEach(dnsZone.dnsZoneVips, function vipsIterator(vip) {
                if (!vipToDnsZonesMap.hasOwnProperty(vip)) {
                  vipToDnsZonesMap[vip] = [];
                }
                vipToDnsZonesMap[vip].push(dnsZone.dnsZoneName);
              });
            });
        }
        if (vlan.ips) {
          angular.forEach(vlan.ips, function(ip) {
            var vlan_obj = angular.copy(vlan);
            vlan_obj._ip = ip;
            if (vipToDnsZonesMap.hasOwnProperty(ip)) {
              vlan_obj._dnsZones = vipToDnsZonesMap[ip];
            }
            $ctrl.vips.push(vlan_obj);
          });
        }
      });
      // set vipsReady to true when the expension is done.
      $ctrl.vipsReady = true;
    }

    /**
     * Delete the requested vlan.
     *
     * @method  deletePartition
     * @param   {object}  ifaceGroupName  Iterface group name
     * @param   {string}  ip              The deleted IP
     */
    function deleteVip(vlan, ip) {
      $ctrl.deletingVip = true;
      vlan.ips.splice(vlan.ips.indexOf(ip), 1);
      // Set netmaskIp4 to undefined since netmaskBits is used.
      if (vlan.subnet) {
        vlan.subnet.netmaskIp4 = undefined;
      }
      _updateVlan(vlan, true).finally(function vlanUpdated() {
        isVipModified = true;
        $ctrl.deletingVip = false;
      });
    }

    /**
     * Get all interfaces
     *
     * @method    getInterfaces
     */
    function _getInterfaces() {
      $ctrl.loadingInterfaces = true;
      NetworkService.getInterfaces(['bond', 'vlan'])
        .then(function gotInterfaces(interfaces) {
          var _interfaces = interfaces.filter(function eachInterface(item) {
            return _.get(item, 'ifaceGroup.length');
          });
          $ctrl.interfaces = _interfaces.sort(_sortInterfacesComparator);
        }, evalAJAX.errorMessage)
        .finally(function preselection() {
          var selectedInterface = _getDefaultSelectedInterface();
          loadingVlan(selectedInterface).then(function vlanLoaded() {
            $ctrl.ifaceGroupName = selectedInterface.ifaceGroup;
            $ctrl.isSubnetRequired = selectedInterface.ifaceRole !== 'primary';
            $ctrl.loadingInterfaces = false;
          });
        });
    }

    /**
     * If the inputs are valid, broadcast the addVIPRange function in c-vip.js.
     * Then wait till the next digest cycle to create and update VLAN.
     *
     * @method  addVip
     */
    function addVip() {
      if ($ctrl.frmVlan.$invalid) {
        return;
      }

      $ctrl.updatingVlan = true;
      $scope.$broadcast('selectedVipsUpdate');

      $timeout(updateVlan);
    }

    /**
     * Create and save VLAN
     *
     * @method  updateVlan
     */
    function updateVlan() {
      var ifaceInfo =
        _ifaceGroupValidation($ctrl.ifaceGroupName);
      var cidr;
      $ctrl.selectedInterfaceGroup.ifaceGroupName = ifaceInfo.ifaceName;
      $ctrl.selectedInterfaceGroup.ips = $ctrl.selectedVips;

      if ($ctrl.ipFamily === $ctrl.IP_FAMILY.IPv6) {
        // Remove empty gateway v4.
        if (_.isEmpty($ctrl.selectedInterfaceGroup.gateway)) {
          $ctrl.selectedInterfaceGroup.gateway = undefined;
        }
        if ($ctrl.selectedInterfaceGroup.subnetV6._CIDR){
          cidr = cUtils.cidrToIpBits($ctrl.selectedInterfaceGroup.subnetV6._CIDR);
          $ctrl.selectedInterfaceGroup.subnetV6.ip = cidr[0];
          $ctrl.selectedInterfaceGroup.subnetV6.netmaskBits = cidr[1];
        } else {
          $ctrl.selectedInterfaceGroup.subnetV6 = {}
        }
      } else {
        // Remove empty gateway v6.
        if (_.isEmpty($ctrl.selectedInterfaceGroup.gatewayV6)) {
          $ctrl.selectedInterfaceGroup.gatewayV6 = undefined;
        }
        if ($ctrl.selectedInterfaceGroup.subnet._CIDR){
          cidr = cUtils.cidrToIpBits($ctrl.selectedInterfaceGroup.subnet._CIDR);
          $ctrl.selectedInterfaceGroup.subnet.ip = cidr[0];
          $ctrl.selectedInterfaceGroup.subnet.netmaskBits = cidr[1];
        } else {
          $ctrl.selectedInterfaceGroup.subnet = {}
        }
      }

      if ($ctrl.dnsDelegationZones.length) {
        $ctrl.dnsDelegationZones = $ctrl.dnsDelegationZones
          .filter(function filterValidDnsZones(dnsZone) {
            return (dnsZone.dnsZoneName && dnsZone.dnsZoneName.length &&
              dnsZone.dnsZoneVips && dnsZone.dnsZoneVips.length);
        });
        $ctrl.selectedInterfaceGroup.dnsDelegationZones =
          $ctrl.dnsDelegationZones || [];
      } else {
        $ctrl.selectedInterfaceGroup.dnsDelegationZones = [];
      }

      // Remove netmaskIp4 if any.
      if ($ctrl.selectedInterfaceGroup.subnet) {
        $ctrl.selectedInterfaceGroup.subnet.netmaskIp4 = undefined;
      }

      _updateVlan($ctrl.selectedInterfaceGroup);
    }


    /**
     * Validate if the ifaceGroupName is appended by VLAN ID.
     * If yes, return iface name and VLAN ID;
     * else, return iface name and 0 as VLAN ID.
     *
     * @method  _ifaceGroupValidation
     * @param   {string}  ifaceGroupName
     * @return  {object} contain iface name and VLAN ID
     */
    function _ifaceGroupValidation(ifaceGroupName) {
      var ifaceInfo = {
        ifaceName: ifaceGroupName,
        vlanId: 0,
      };

      if (ifaceGroupName.includes('.')) {
        var segment = ifaceGroupName.split('.');
        ifaceInfo.vlanId = Number(segment[1]);
      } else {
        ifaceInfo.ifaceName = ifaceGroupName + '.0';
      }

      return ifaceInfo;
    }


    /**
     * Update the vlan and renew the vlan list
     *
     * @method  _updateVlan
     * @param   {object}    data              Data to send to api
     * @param   {Boolean}   skipRedirection   If present and true then skip
     * redirection to parent state on successful vlan(interface group)
     * modification.
     */
    function _updateVlan(data, skipRedirection) {
      return VlanService.updateVlan(data)
        .then(skipRedirection ? angular.noop : _updateVlanSuccess,
          evalAJAX.errorMessage)
        .catch(angular.noop)
        .finally(function updateVlanFinally() {
          // reset
          $ctrl.vips = [];
          $ctrl.selectedInterfaceGroup.ips = [];
          $ctrl.updatingVlan = false;
          $ctrl.selectedVips = [];
          $ctrl.selectedInterfaceGroup.dnsDelegationZones = [];
          $ctrl.frmVlan.$setPristine(true);
          getVlans();
          // Get all vips for defaultIface and update selectedVips.
          // Fix for: ENG-92393
          _getInterfaces();
        });
    }

    /**
     * Handles success respose for create/update vlan
     *
     * @param    {object}   response   http response
     */
    function _updateVlanSuccess(response) {
      // Creating vlan in modal view
      if ($ctrl.inModal) {
        $ctrl.cModalHoc.save(response);
        return;
      }

      $state.go('networking.vips');
    }

    /**
     * Check if the vlan exists,
     * if exists, set selectedInterfaceGroup to existing data;
     * otherwise give default values
     *
     * @param {object} selectedIfaceGroup
     */
    function loadingVlan(selectedIfaceGroup) {
      var ifaceInfo = _ifaceGroupValidation(selectedIfaceGroup.ifaceGroup);
      $ctrl.isSubnetRequired = selectedIfaceGroup.ifaceRole !== 'primary';
      $ctrl.selectedInterfaceGroup = _.clone(defaultVlan);
      $ctrl.selectedInterfaceGroup.ips = [];
      $ctrl.selectedInterfaceGroup.dnsDelegationZones = [];
      return VlanService.getVlan(ifaceInfo.ifaceName)
        .then(function vlanExist(vlan) {
          _.assign($ctrl.selectedInterfaceGroup, vlan);

          $ctrl.selectedInterfaceGroup.ifaceGroupName =
          VlanService.getInterfaceGroupName(
            $ctrl.selectedInterfaceGroup.ifaceGroupName);
            if (vlan.dnsDelegationZones) {
              $ctrl.dnsDelegationZones = Object.create(vlan.dnsDelegationZones);
            }
          $ctrl.selectedVips = $ctrl.selectedInterfaceGroup.ips || [];
        }, function vlanNotFound() {
          $ctrl.selectedInterfaceGroup.id = 0;
          $ctrl.selectedInterfaceGroup.ifaceGroupName =
            selectedIfaceGroup.ifaceGroup;
          $ctrl.selectedVips = [];
        });
    }

    /**
     * Comparator function used in sorting interfaces.
     *
     * @param interface1 First interface to be compared.
     * @param interface2 Second interface to be compared.
     * @return -1 If interface1 is before interface2 in sorted order.
     */
    function _sortInterfacesComparator(interface1, interface2) {
      var iface1Split = interface1.ifaceGroup.split('.');
      var iface2Split = interface2.ifaceGroup.split('.');
      var result = iface1Split[0] === iface2Split[0] ?
        (parseInt(iface1Split[1], 0) < parseInt(iface2Split[1], 0)) :
          (iface1Split[0] < iface2Split[0]);
      return result ? -1 : 1;
    }
  }
})(angular);
