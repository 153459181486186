import { Injectable } from '@angular/core';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { NavItem } from '@cohesity/helix';
import { Environment } from '@cohesity/iris-shared-constants';
import { StateService } from '@uirouter/core';
import { envTypes } from 'src/app/shared';

import { SimpleObjectInfo } from './object-menu-provider';
import { ProtectionGroupService } from '../protection-group-shared';
import { ObjectActionCreator } from './object-action-creator';
import { ProtectedObjectsService } from './protected-objects.service';

/**
 * Helper class for creating object action items.
 */
@Injectable({
  providedIn: 'root',
})
export class VmwareObjectActionCreator extends ObjectActionCreator {
  constructor(
    objectsService: ProtectedObjectsService,
    protectionGroupService: ProtectionGroupService,
    stateService: StateService) {
    super(objectsService, protectionGroupService, stateService);
  }

  /**
   * Creates and returns an action object for upgrading CDP IO Filter.
   *
   * @param action The custom action to perform.
   * @returns The NavItem for upgrading CDP IO Filter.
   */
  createCdpIoFilterUpgradeAction(action: () => void): NavItem {
    return {
      displayName: 'upgradeCdpIoFilter',
      icon: 'helix:filter-local-upgrade',
      action,
    };
  }

  /**
   * Creates and returns an action object for upgrading CDP IO Filter.
   *
   * @param action The custom action to perform.
   * @returns The NavItem for uninstalling CDP IO Filter.
   */
  createCdpIoFilterUninstallAction(action: () => void): NavItem {
    return {
      displayName: 'uninstallCdpIoFilter',
      icon: 'helix:filter-local-uninstall',
      action,
    };
  }

  /**
   * Creates an action to edit or delete an application's registration
   *
   * @param node The v1 node
   * @param action Edit or Unregister action
   * @returns The NavItem to manage the application
   */
  createManageApplicationAction(node: ProtectionSourceNode, action: 'edit' | 'unregister'): NavItem {
    const { username, environments } = node.registrationInfo;

    // Default to SQL if for some reason nothing is selected here.
    const appEnvs = (environments.length ? environments : [Environment.kSQL]) as Environment[];

    // Check to see if there arer any registered apps that are protected separately from the root env.
    // If so, then the unregister action should be disabled.
    const isAppProtected = appEnvs.some(
      appEnv => node.protectedSourcesSummary.find(summary => summary.environment === appEnv)?.leavesCount > 0
    );

    let displayName;
    let icon;
    let state;

    if (action === 'edit') {
      displayName = 'editApplicationsRegistration';
      icon = 'edit!outline';
      state = 'sql-modify';
    } else {
      displayName = 'unregisterApplications';
      icon = 'delete!outline';
      state = 'sql-unregister';
    }

    const host = !node?.protectionSource ? {} :
    {
      credentials: username ? { username: username } : undefined,
      ownerEntity: node.protectionSource,

      // This page still uses private api types
      appEnvVec: appEnvs.map(env => envTypes[env]),
      usesPersistentAgent: !username,
    };

    return {
      displayName,
      state,
      icon,
      disabled: action === 'unregister' && isAppProtected,
      stateParams: {
        entityId: node.protectionSource.id,
        host,
        username,
      },
    };
  }

  /**
   * Creates a register application action
   *
   * @param object The object info
   * @returns The NavItem to register an application
   */
  createRegisterApplicationAction(object: SimpleObjectInfo): NavItem {
    return {
      displayName: 'registerApplications',
      state: 'sql-register',
      icon: 'add',
      stateParams: {
        entityId: object.id,
      },
    };
  }
}
