import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { IrisContextService, isMcm } from '@cohesity/iris-core';
import { Observable } from 'rxjs';
import { AppServiceManagerService } from 'src/app/app-services';

/**
 * This HTTP interceptor is to add the service context to every request that gets
 * handled through Helios UI. This special header is used by IRIS in Helios and
 * Cluster to handle the audit log in a right way.
 */
@Injectable()
export class ServiceContextInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Since the interceptor relies on certain AJS dependencies to be loaded.
    // Certain services might not be initialized at this time such as feature-flags.
    if (['featureFlags'].includes(req.url)) {
      return next.handle(req);
    }
    try {
      // Try to get the AJS $injector which IrisContext depends upon. If the injector
      // is not available, silently move on with the code flow. else handle the
      // adding service context to the request.
      const irisCtx = this.injector.get(IrisContextService);
      const appServiceManagerService = this.injector.get(AppServiceManagerService);
      if (!isMcm(irisCtx.irisContext)) {
        return next.handle(req);
      }

      const headerScope = appServiceManagerService.getAuditTag();
      const modifiedReq = req.clone({
        headers: req.headers.set('X-Cohesity-Service-Context', headerScope),
      });

      return next.handle(modifiedReq);

    } catch (error) {
      return next.handle(req);
    }
  }
}
