import { DataRenderer, IconDataRendererComponent } from '@cohesity/helix';
import { targetTypeIconMap } from '@cohesity/iris-shared-constants';

/**
 * Params used for rendering an target icon in a table
 */
export interface TargetIconParams {
  /**
   * The row/data key containing the object's environment type
   */
  ownershipKey: string;
}

/**
 * Renders target icon based on the target type.
 */
export const targetTypeIconRenderer = {
  transform: (renderValues: DataRenderer<string, TargetIconParams, any>) => ({
    ...renderValues,
    value: renderValues.secondaryValue[renderValues.renderParams.ownershipKey] === 'FortKnox' ?
      'helix:vault' :
      (targetTypeIconMap[renderValues.value] || 'helix:object-generic'),
  }),
  component: IconDataRendererComponent,
};
