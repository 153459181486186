<ng-container *ngIf="!filterDefParams.quickFilter">
  <ng-container *ngTemplateOutlet="filterTemplate"></ng-container>
</ng-container>

<ng-container *ngIf="filterDefParams.quickFilter">
  <cog-quick-filter
    [cogDataId]="filter.label"
    [inheritDataId]="true"
    [label]="filter.label"
    [filterTags]="filter.tagValues$ | async"
    [lockedMessage]="filter.lockedMessage"
    (applied)="applyFilters()"
    (cleared)="clearFilters()"
    (menuClose)="handleMenuClose()"
    (opened)="handleMenuOpen()"
    [disableArrowsKeyMenu]="true"
    [formStyle]="formStyle"
    [preLabel]="preLabel"
    [noClear]="noClear"
    [xPosition]="filterDefParams.quickFilterPositionX"
    [disableApplyButton]="disableApplyButton"
    (customButtonClicked)="customButtonClicked.emit()">
    <ng-container *ngTemplateOutlet="filterTemplate"></ng-container>
  </cog-quick-filter>
</ng-container>

<ng-template #filterTemplate>
  <div class="helix-date-range-picker-component">
    <cog-date-range-picker
      [formControl]="dateRange"
      cogDataId="date-range-filter"
      [inheritDataId]="true"
      [useTimeframe]="useTimeframe"
      [timeframeOptions]="timeframeOptions"
      [noDateOptionLabel]="noDateOptionLabel"
      [enableGuessTimeframe]="enableGuessTimeframe"
      [enablePreciseDateRangeSelection]="enablePreciseDateRangeSelection"
      [maxRange]="maxRange"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [banner]="banner"
      (changes)="changes.emit($event)">
    </cog-date-range-picker>
    <cog-banner *ngIf="maxRange" status="info" class="range-banner">
      {{ intlSvc.dataFilters.maxRangeWarning(this.maxRange) }}
    </cog-banner>
  </div>
</ng-template>
