import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ViewDirectoryQuota, ViewServiceApi } from '@cohesity/api/v2';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';

import { ViewInfo } from '../../models';
import { ViewsService } from '../../services';

/**
 * Parameters used for deleting directory quota.
 */
export interface DeleteDirQuotaParams {
  view: ViewInfo;
  directoryQuota: ViewDirectoryQuota;
}

@Component({
  selector: 'coh-delete-directory-quota-dialog',
  templateUrl: './delete-directory-quota-dialog.component.html',
})
export class DeleteDirectoryQuotaDialogComponent extends AutoDestroyable {

  /**
   * Indicates whether a deleting directory quota operation is in progress.
   */
  deleting = false;

  constructor(
    public dialogRef: MatDialogRef<DeleteDirectoryQuotaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogParams: DeleteDirQuotaParams,
    private evalAjaxService: AjaxHandlerService,
    private viewsService: ViewsService,
  ) {
    super();
  }

  /**
   * Confirm to delete directory quota.
   */
  deleteQuota() {
    const param: ViewServiceApi.DeleteViewDirectoryQuotaParams = {
      id: this.dialogParams.view.viewId,
      // Strips trailing slashes but leaves intact root level '/'.
      directoryPath: this.dialogParams.directoryQuota.directoryPath.replace(/(?!^\/)\/*$/, ''),
    };

    this.viewsService.deleteViewDirectoryQuota(param)
      .pipe(this.untilDestroy())
      .subscribe(() => {
        this.dialogRef.close();
      }, this.evalAjaxService.errorMessage);

  }
}
