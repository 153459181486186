import { NgModule } from '@angular/core';
import { DatePipesModule, IconModule, StatlistModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';

import { SnapshotInfoComponent } from './snapshot-info.component';
import { ColoredTagModule } from '@cohesity/shared/tags';

/**
 * This module is shared between security and health module.
 */
@NgModule({
  imports: [
    ColoredTagModule,
    DatePipesModule,
    IconModule,
    StatlistModule,
    TranslateModule,
  ],
  exports: [
    SnapshotInfoComponent
  ],
  declarations: [
    SnapshotInfoComponent
  ],
})
export class SnapshotInfoModule {}
