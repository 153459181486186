import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'coh-include-protected-objects',
  templateUrl: './include-protected-objects-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncludeProtectedObjectsDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<IncludeProtectedObjectsDialogComponent>,
  ) {}

  /**
   * Close the dialog
   *
   * @param confirm Is the confirm button clicked
   */
  close(confirm = false) {
    this.dialogRef.close(confirm);
  }
}
