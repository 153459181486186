import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { CanSelectRowFn, KeyedSelectionModel } from '@cohesity/helix';
import { AutoDestroyable } from '@cohesity/utils';
import { BrowseResult } from './browse-result';

/**
 * Displays the results of any restore object search.
 */
@Component({
  selector: 'coh-browse-search-results',
  templateUrl: './browse-search-results.component.html',
  styleUrls: ['./browse-search-results.component.scss'],
})
export class BrowseSearchResultsComponent extends AutoDestroyable {

  /**
   * List of table columns.
   */
  readonly columnsToDisplay = ['path', 'size', 'lastModified'];

  /**
   * The browse results to display.
   */
  @Input() browseResults: BrowseResult[];

  /**
   * The selection model for the search results. This could be single or multi-select.
   * This uses a keyed selection model and persists the selection between table reloads,
   * so that the selection can be maintained as data is swapped out with each search.
   * This can be null to remove any checkboxes or radio buttons from the form.
   */
  @Input() selection: KeyedSelectionModel<BrowseResult>;

  /**
   * Whether the search table supports multiple selections.
   */
  @Input() isMultipleSelection = true;

  /**
   * Flag that when a search returned no results.
   */
  @Input() noData = false;

  /**
   * Determine whether a row should be enabled in the selection results. The main case where
   * a row would be disabled is if an object is included in an already-selected protection group
   * or if this component is marked disabled.
   */
  canSelectObject: CanSelectRowFn<BrowseResult> = () =>
    // Default case allows for selection.
    true;


  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  /**
   * Toggles the selection of an item.
   *
   * @param   object   The object to toggle.
   */
  toggleObjectSelection(object: BrowseResult) {
    if (this.selection && this.canSelectObject(object)) {
      this.selection.toggle(object);
    }
  }
}
