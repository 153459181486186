// Module: Remote Clusters Module Routes

;(function(angular, undefined) {
  'use strict';

  angular.module('C.remoteClusters', ['C.network', 'C.tableCols'])
    .config(remoteClustersConfigFn);

  function remoteClustersConfigFn($stateProvider) {
    $stateProvider
      .state('remote-clusters', {
        url: '^/protection/remote-clusters',
        title: 'Remote Clusters',
        canAccess: 'CLUSTER_REMOTE_VIEW',
        help: 'platform_replication',
        template: '<remote-clusters-manager />',
      })
      .state('remote-clusters-new', {
        url: '^/protection/remote-cluster/new',
        title: 'New Remote Cluster Setup',
        canAccess: 'CLUSTER_REMOTE_MODIFY',
        parentState: 'remote-clusters',
        help: 'platform_replication_new_connection',
        component: 'remoteClustersModify',
      })
      .state('remote-clusters-edit', {
        url: '^/protection/remote-cluster/edit/{clusterId}',
        params: {
          clusterId: { type: 'string', value: null },
          cluster: undefined,
        },
        title: 'Edit Remote Cluster Setup',
        canAccess: 'CLUSTER_REMOTE_MODIFY',
        help: 'platform_replication_new_connection',
        parentState: 'remote-clusters',
        component: 'remoteClustersModify',
        resolve: {
          cluster: function getCluster($transition$) {
            return $transition$.params().cluster;
          },
          clusterId: function getClusterId($transition$) {
            return $transition$.params().clusterId;
          },
        },
      })
      .state('remote-clusters-view', {
        url: '^/protection/remote-cluster/details/{clusterId}',
        params: {
          clusterId: { type: 'string', value: null },
          cluster: undefined,
        },
        title: 'View Remote Cluster Setup Details',
        canAccess: 'CLUSTER_REMOTE_VIEW',
        help: 'platform_replication_new_connection',
        parentState: 'remote-clusters',
        component: 'remoteClustersView',
        resolve: {
          cluster: function getCluster($transition$) {
            return $transition$.params().cluster;
          },
          clusterId: function getClusterId($transition$) {
            return $transition$.params().clusterId;
          },
        },
      });
  }

})(angular);
