<div [formGroup]="formGroup">
  <div class="d-flex margin-bottom">
    <div class="row" *ngIf="isDynamicConfigFeatureEnabled">
      <div class="col-xs-6">
        <coh-form-field-object-selector
          cogDataId="uda-host-os-type-dropdown"
          [control]="formGroupControls.hostOsType"
          [label]="'hostOsType' | translate"
          [optionTemplate]="udaHostOsTypeDropdownTemplate"
          [allowSearch]="false"
          [triggerTemplate]="udaHostOsTypeDropdownTemplate"
          [values]="udaSourceContextService.hostOsTypes$ | async"
          [readonly]="editMode">
        </coh-form-field-object-selector>
      </div>
    </div>
  </div>

  <div *ngIf="isDynamicConfigSupported && isDynamicConfigFeatureEnabled">
    <div *ngIf="formGroupValues.hostOsType">
      <coh-uda-register-primary-fields
        [config]="udaRegistrationConfig?.primaryFields"
        [editMode]="editMode"
        [formControl]="formGroupControls.primaryFieldParams">
      </coh-uda-register-primary-fields>
      <mat-card *ngFor="let panel of udaRegistrationConfig?.dynamicForm?.panels"
        class="uda-registration-panel">
        <cog-form-panel
          [cogDataId]="panel.id"
          class="form-panel"
          [title]="panel.title"
          [optional]="panel.optional"
          [defaultExpanded]="isPanelExpandedByDefault(panel)">
          <div *ngFor="let field of panel.fields">
            <cog-generic-form-field
              [cogDataId]="field.id"
              [label]="field.label"
              [type]="field.type"
              [config]="field.typeConfig"
              [control]="getControlByKey(field.key)"
              [formControlGetterFn]="getControlByKey"
              [validationErrorFn]="validationErrorFn">
            </cog-generic-form-field>
          </div>
        </cog-form-panel>
      </mat-card>
    </div>
  </div>

  <div *ngIf="(editMode && !isDynamicConfigSupported) || !isDynamicConfigFeatureEnabled">
    <coh-uda-register-primary-fields
      [editMode]="editMode"
      [formControl]="formGroupControls.primaryFieldParams">
    </coh-uda-register-primary-fields>
  </div>
</div>

<!-- Host os type template for Universal Data Adapter sources -->
<ng-template #udaHostOsTypeDropdownTemplate let-value>
  {{ getHostOSTypeLabelKey(value) | translate }}
</ng-template>
