import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ransomwareTagTranslationKeys, sensitivityTagTranslationKeys, threatTagTranslationKeys } from '../../constants';

/**
 * a map of tag names for which we need translated output
 */
const tagNameKeys: Record<string, string> = {
  ...ransomwareTagTranslationKeys,
  ...threatTagTranslationKeys,
  ...sensitivityTagTranslationKeys,
};

/**
 * A pipe to get translated tag name. This will try to get the translated tag name if available, if not then it will
 * act as a no-op.
 */
@Pipe({
  name: 'tagName',
})
export class TagNamePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  /**
   * Tries to translate the given input and returns the translated tags
   *
   * @param value tag name that need to be translated
   * @returns translated tag name if translations are configured, otherwise the original value
   */
  transform(value: string): string {
    return tagNameKeys[value] ? this.translate.instant(tagNameKeys[value]) : value;
  }
}
