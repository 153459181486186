<div class="search-property-filter-wrapper"
  [class.expanded]="alwaysExpand || expanded"
  (mouseenter)="hovered = true"
  (mouseleave)="hovered = false; null">
  <cog-icon [attr.aria-label]="intl.search" matSuffix shape="search"></cog-icon>
  <input type="text"
    cogDataId="search-property-filter-input"
    id="search-by-{{filter.key}}"
    name="{{filter.key}}"
    [attr.aria-label]="placeholder"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    (focus)="focused = true"
    (blur)="focused = false; null">
</div>
