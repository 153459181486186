import { SiteContinuityInfo } from '@cohesity/api/v1';
import { daysRemaining, getEntitlementInfo } from './common-utils';
import { IrisContext } from './iris-context.model';
import { EntitlementKeys } from '../app-configs';

/**
 * Returns true if the current user is a DRaaS (SiteContinuity) configured user.
 * Depending on arguments, will ignore if the subscription is expired.
 *
 * @param irisContext The current iris context.
 * @param includeExpired Whether to take into account if user has expired subscription.
 * @returns boolean True if user is DRaaS user.
 */
export function isDraasUser(irisContext: IrisContext, includeExpired = false): boolean {
  return hasDraasEntitlement(irisContext) ?
    Boolean(irisContext?.user?.salesforceAccount?.isDRaaSUser) && hasDraasSku(irisContext, includeExpired) :
    Boolean(irisContext?.user?.salesforceAccount?.isDRaaSUser);
}

/**
 * Returns true if there is extra entitlement information
 * returned from the crm service
 *
 * @param irisContext The current iris context.
 * @returns boolean True if entitlement information is present
 */
export function hasDraasEntitlement(irisContext: IrisContext): boolean {
  return !!getEntitlementInfo(irisContext, EntitlementKeys.SiteContinuityInfo);
}



/**
 * Inspects the subscription info and determines
 * if the user has an active subscription or if additional parameter supplied,
 * if user also has an expired subscription.
 *
 * @param irisContext The current iris context.
 * @param includeExpired Whether to take into account if user has expired subscription.
 * @returns boolean True if user subscription is active.
 */
export function hasDraasSku(irisContext: IrisContext, includeExpired = false): boolean {
  return includeExpired ? !!draasEntitlement(irisContext) : draasEntitlement(irisContext)?.isActive;
}

/**
 * Indicates if the DRaaS user is currently using a free trial.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if user is DRaaS user and using a free trial.
 */
export function isDraasFreeTrialUser(irisContext: IrisContext): boolean {
  const entitlement = draasEntitlement(irisContext);
  return !!entitlement?.isFreeTrial;
}

/**
 * Provides the details for SiteContinuity service subscription.
 *
 * @param irisContext The current iris context.
 * @returns The data protect subscription or null.
 */
export function draasEntitlement(irisContext: IrisContext): SiteContinuityInfo | null {
  return irisContext?.user?.subscriptionInfo?.siteContinuity;
}

/**
 * Returns true if the current user is in DRaaS scope.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if the user in DRaaS context.
 */
export function isDraasScope(irisContext: IrisContext, includeExpired = false): boolean {
  return Boolean(isDraasUser(irisContext, includeExpired) && irisContext?.selectedScope?._serviceType === 'draas');
}

/**
 * Indicates if the DRaaS user is using a free trial that has expired.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if user is DRaaS user and their free trial has expired.
 */
export function isDraasFreeTrialExpired(irisContext: IrisContext): boolean {
  const entitlement = draasEntitlement(irisContext);
  return entitlement?.isFreeTrial && !entitlement.isActive;
}

/**
 * Provides the number of days remaining in DRaaS users free trial or subscription.
 *
 * @param irisContext The current iris context.
 * @returns number of days remaining in free trial or subscription.
 */
export function draasDaysRemaining(irisContext: IrisContext): number | null {
  const entitlement = draasEntitlement(irisContext);

  return daysRemaining(entitlement?.endDate);
}

/**
 * Returns the tenant ID for a given DRaaS ID.
 *
 * @param  irisContext  The current iris context.
 * @return The tenant ID if a DRaaS user or null.
 */
export function draasTenantId(irisContext: IrisContext): null | string {
  if (!isDraasUser(irisContext)) {
    return undefined;
  }

  return irisContext.user.profiles?.[0].tenantId ?? null;
}

/**
 * Returns the tenant name for a given DRaaS ID.
 *
 * @param  irisContext  The current iris context.
 * @return The tenant Name if a DRaaS user or null.
 */
export function draasTenantName(irisContext: IrisContext): null | string {
  if (!isDraasUser(irisContext)) {
    return undefined;
  }

  return irisContext.user.profiles?.[0]?.tenantName ?? null;
}
