// Component: Office365 Manager - Register/Update

(function(angular, undefined) {
  'use strict';

  angular
    .module('C.office365Manager', [])
    .component('cOffice365Manager', {
      bindings: {
        // @type    {object}   Object containing the modal instance.
        modalInstance: '<?',

        // @type    {object}   Object specifying the O365 source page config.
        // Object currently holds the source details for Office 365.
        // pageConfig: {
        //  source: {object},
        // }
        pageConfig: '<?',
      },
      controller: office365ModificationCtrlFn,
      templateUrl:
        'app/protection/sources/office365-manager/office365-modify.html',
    });

  /**
   * @ngdoc component
   * @name C.office365Manager:cOffice365Manager
   * @function
   *
   * @description
   * Component for registering Office 365 resoiurce.
   *
   * @example
   * <c-office365-manager></c-office365-manager>
   */
  function office365ModificationCtrlFn(_, $state, evalAJAX, cMessage,
    PubSourceService, PUB_TO_PRIVATE_ENV_STRUCTURES, $translate) {
    var $ctrl = this;

    // Office 365 Exchange Web Serviuces endpoint which provides access to
    // Office 365 applications such as Outlook, OneDrive, Calendar etc.
    var o365Endpoint = 'https://outlook.office365.com/EWS/Exchange.asmx';

    var defaultMSGraphCredentials = {
      clientId: undefined,
      clientSecret: undefined,
      grantType: 'client_credentials',
      scope: 'https://graph.microsoft.com/.default',
    };

    _.assign($ctrl, {
      // State variables
      state: {
        isEditMode: false,
        o365Endpoints: [],
        office365Config: {
          endpoint: o365Endpoint,
          environment: 'kO365',
          username: undefined,
          password: undefined,
          office365Region: $translate.instant(
            'office365Regions.default') || '',
          office365RegionList: [],

          // Office365 entity type
          office365Type: 'kDomain',

          // Application specific details for MS Graph API.
          office365CredentialsList: [{
            // Specifies the application ID that the registration portal
            // (apps.dev.microsoft.com) assigned.
            clientId: undefined,

            // Specifies the application secret that was created in app
            // registration portal.
            clientSecret: undefined,

            // Specifies the application grant type. eg: For client
            // credentials flow, this is set to "client_credentials".
            grantType: 'client_credentials',

            // Specifies a space separated list of scopes/permissions for
            // user. Eg: Incase of MS Graph APIs for Office365, scope is set
            // to default: https://graph.microsoft.com/.default.
            scope: 'https://graph.microsoft.com/.default',
          }],
        },
        submitting: false,
      },

      // Methods exposed to template.
      addCredentials: addCredentials,
      cancel: cancel,
      handleSubmit: handleSubmit,
      setOffice365Endpoint: setOffice365Endpoint,

      // Lifecycle methods.
      $onInit: $onInit,
    });

    /**
     * Initializer function
     *
     * @method   $onInit
     */
    function $onInit() {
      // Check for Office 365 registration inside Modal.
      $ctrl.inModal = !!$ctrl.modalInstance;

      var _defaultOffice365RegionList = [
        'office365Regions.default',
        'office365Regions.china',
        'office365Regions.germany',
        'office365Regions.usdod',
        'office365Regions.usgcchigh',
      ];

      // Get the sanitized region name from the default Region list.
      _defaultOffice365RegionList.forEach(
        function addRegion(region) {
          $ctrl.state.office365Config.office365RegionList.push(
            $translate.instant(region) || '');
        }
      );

      // o365Endpoints maps translated region names to the respective endpoint
      // which should be used to access that region.
      $ctrl.state.o365Endpoints = new Map();
      $ctrl.state.o365Endpoints.set($translate.instant(
        'office365Regions.default'),
        'https://outlook.office365.com/EWS/Exchange.asmx');
      $ctrl.state.o365Endpoints.set($translate.instant(
        'office365Regions.china'),
        'https://partner.outlook.cn/EWS/Exchange.asmx');
      $ctrl.state.o365Endpoints.set($translate.instant(
        'office365Regions.germany'),
        'https://outlook.office.de/EWS/Exchange.asmx');
      $ctrl.state.o365Endpoints.set($translate.instant(
        'office365Regions.usdod'),
        'https://outlook.office365.us/EWS/Exchange.asmx');
      $ctrl.state.o365Endpoints.set($translate.instant(
        'office365Regions.usgcchigh'),
        'https://outlook.office365.us/EWS/Exchange.asmx');

      // Check whether the source is being edited.
      if ($ctrl.pageConfig && $ctrl.pageConfig.source) {
        $ctrl.state.isEditMode = true;
        $ctrl.state.office365Config = _.merge($ctrl.state.office365Config,
          $ctrl.pageConfig.source);

        _.assign($ctrl.state.office365Config, {
          // Set the username.
          username: $ctrl.pageConfig.source.registrationInfo.username,
          office365CredentialsList: _.assign(
            $ctrl.state.office365Config.office365CredentialsList,
            _.get($ctrl.pageConfig.source,
              'registrationInfo.office365CredentialsList', [])),
          // Set the OAuth behavior for Exchange Online.
          useOAuthForExchangeOnline: _.get($ctrl.pageConfig.source,
            'registrationInfo.useOAuthForExchangeOnline', false),
          endpoint: $ctrl.pageConfig.source.registrationInfo.accessInfo.
            endpoint,

          // Since the Update source still uses private API, these parameters
          // are required.
          protectionSource: {
            name: $ctrl.state.office365Config.rootNode.name,
            id: $ctrl.state.office365Config.rootNode.id,
            type: 'kDomain',
          },
        });

        // We have value of the endpoint, we can find the corresponding
        // office365Region using the map 'o365Endpoints' which we already
        // filled up.
        $ctrl.state.o365Endpoints.forEach(function(value, key) {
          if (value === $ctrl.state.office365Config.endpoint) {
            $ctrl.state.office365Config.office365Region = key;
          }
        });
        _.forEach($ctrl.state.office365Config.office365CredentialsList,
          function(office365Credentials) {
            _.defaults(office365Credentials, {
              grantType: 'client_credentials',
              scope: 'https://graph.microsoft.com/.default'
            });
        });
      }
    }

    /**
     * Redirects user to sources page or dismisses the modal.
     *
     * @method   cancel
     */
    function cancel() {
      if ($ctrl.inModal) {
        $ctrl.modalInstance.dismiss('cancel');
      } else {
        $state.go('sources-new');
      }
    }

    /**
     * Redirects the user to sources page or closes the modal.
     *
     * @method   _close
     * @param    {object}   source   Specifies the newly registered source.
     */
    function _close(source) {
      if ($ctrl.inModal) {
        $ctrl.modalInstance.close(source);
      } else {
        $state.go('sources-new');
      }
    }

    /**
     * Adds an additional MS graph credentials: App id and App secret key.
     *
     * @method   addCredential
     */
    function addCredentials() {
      if ($ctrl.state.office365Config.office365CredentialsList) {
        $ctrl.state.office365Config.office365CredentialsList.push(
          _.cloneDeep(defaultMSGraphCredentials)
        );
      }
    };

    /**
     * Sets the correct endpoint for office 365 based on the selected
     * region.
     *
     * @method   setOffice365Endpoint
     */
    function setOffice365Endpoint() {
      $ctrl.state.office365Config.endpoint = $ctrl.state.o365Endpoints.get(
        $ctrl.state.office365Config.office365Region);
    }

    /**
     * Handle form submission to register Office 365 source.
     *
     * @method handleSubmit
     */
    function handleSubmit() {
      var o365ActionFn;
      $ctrl.state.submitting = true;

      // Set the password for update.
      if ($ctrl.state.isEditMode) {
        // Retry registration internally sends PUT request for update the
        // source to /irisservices/api/v1/backupsources/:id
        // TODO(Tauseef): Move this to public API when available.
        o365ActionFn = PubSourceService.retrySourceRegistration;
      } else {
        o365ActionFn = PubSourceService.registerSource;
      }

      o365ActionFn($ctrl.state.office365Config).then(
        function onSuccessfulRegistration(response) {
          // TODO(Tauseef): Check for status code?
          cMessage.success({
            textKey: 'office365Sources.cmessages.successfulRegistration',
            timeout: 5000,
          });

          // Redirect.
          _close(response.data);
        }, evalAJAX.errorMessage
      ).finally(
        function registrationDoneFn() {
          $ctrl.state.submitting = false;
        }
      );
    }
  }

})(angular);
