<span *ngIf="!editMode" class="view">{{value}}</span>
<mat-form-field *ngIf="editMode">
  <input type="text" matInput
    [placeholder]="label"
    [formControl]="formControl"
    [matAutocomplete]="timezonePicker">
  <mat-autocomplete
    autoActiveFirstOption
    #timezonePicker="matAutocomplete"
    (optionSelected)="onValueChange($event)">
    <mat-option
      *ngFor="let option of filteredOptions | async"
      [value]="option.value">
      {{option.value}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
