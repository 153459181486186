import { NgModule } from '@angular/core';
import { FilerAppStatusPipeModule, RangePipeModule } from '@cohesity/data-govern/shared';
import { UtilPipesModule } from '@cohesity/utils';

import { MetricTypePipeModule } from './metric-type-pipe';
import { MetricValuePipeModule } from './metric-value-pipe';

const IMPORT_EXPORT_MODULES = [
  FilerAppStatusPipeModule,
  MetricTypePipeModule,
  MetricValuePipeModule,
  RangePipeModule,
  UtilPipesModule,
];

@NgModule({
  exports: [...IMPORT_EXPORT_MODULES],
})
export class SecuritySharedPipesModule {}
