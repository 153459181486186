import { Injectable } from '@angular/core';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { DefType, Transition } from '@uirouter/core';
import { get } from 'lodash';
import { AjsUpgradeService, TenantScopeService } from 'src/app/core/services';
import { GuardPriority, GuardResult, StateGuard, StaticRoutes } from 'src/app/core/state/state-guard';

/**
 * The SwitchAccount guard adds orgId to all routes,
 *  when SwitchAccount is active
 *
 * Also reads orgId from url and activates SwitchAccount,
 *  when SwitchAccount is not active
 */
@Injectable({
  providedIn: 'root',
})
export class SwitchAccountGuard implements StateGuard {

  /**
   * This guard should be run before gaurds with App priority
   */
  guardPriority = GuardPriority.ImpersonationOrSwitch;

  /**
   * Legacy TenantService AngularJS service.
   */
  private ajsTenantService: any;

  /**
   * Legacy UserService AngularJS service.
   */
  private ajsUserService: any;

  constructor(
    private ajsUpgrade: AjsUpgradeService,
    private tenantScopeService: TenantScopeService,
    private irisCtx: IrisContextService
  ) {
    this.ajsTenantService = ajsUpgrade.get('TenantService');
    this.ajsUserService = ajsUpgrade.get('UserService');
  }

  /**
   * This is run on the onStart ui router transition.
   */
  onStart(transition: Transition): GuardResult {
    if (StaticRoutes.includes(transition.$to().name)) {
      return;
    }

    const toState = transition.$to();
    const paramDefs = toState.params;
    const paramConfig = toState.self.params;
    const params = { ...transition.params() };
    const options = transition.options();

    const getNewRoute = () => transition.router.stateService.target(toState.name, params, options);

    const resetParams = () => {
      params.impersonatedOrgId = undefined;
      params.syncLocalImpersonationToUrl = false;
      return getNewRoute();
    };

    switch (true) {
      // Do not process if feature flag is diabled
      case !flagEnabled(this.irisCtx.irisContext, 'persistentSwitchAccount') ||
        !this.ajsTenantService.isTenantAccountSwitchable():
        // Remove the param from url, if it not being processed
        return params.orgId && resetParams();
    }

    const orgId = this.ajsTenantService.getSelectedAccountId();
    if (params.orgId !== orgId) {
      if (!params.orgId || params.syncLocalSwitchAccountToUrl) {
        params.orgId = orgId;

        // when switching accounts clear all existing filters if they are persisted in url
        if (params.syncLocalSwitchAccountToUrl) {
          Object.keys(paramDefs).forEach(key => {
            // Delete only if the param is not a path param
            if (paramDefs[key].location !== DefType.PATH && !get(paramConfig[key], '_persistOnImpersonation', false)) {
              delete params[key];
            }
          });
        }

        // This param is used to identify when orgId changes locally
        // this works based on the assumption that we do a reload on switch
        params.syncLocalSwitchAccountToUrl = true;
        return getNewRoute();
      }

      return this.ajsUserService.getOrgMemberships().then(orgMemberships => {
        const tenant = orgMemberships.find(({ tenantId }) => (
          tenantId === params.orgId));

        if (tenant) {
          this.ajsTenantService.setSwitchedAccount(tenant);
          this.tenantScopeService.updateUserContextAndValidateState(toState);
        } else {
          return resetParams();
        }
      }).catch(resetParams);
    }

    if (!params.syncLocalSwitchAccountToUrl) {
      // For the case when url orgId and local orgId are same on page load
      params.syncLocalSwitchAccountToUrl = true;
      return getNewRoute();
    }
  }
}
