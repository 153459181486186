/**
 * Instead of specifying each mount point for a file base backup, specifying
 * this string will protect all local mount points.
 */
export const AllLocalDrives = '$ALL_LOCAL_DRIVES';

/**
 * Physical backup types.
 */
export enum PhysicalBackupType {
  File = 'kFile',
  Volume = 'kVolume',
}

/**
 * Agent upgradablity defintion.
 */
export const AgentUpgradable = 'Upgradable';

/**
 * List of agent upgrade task status.
 */
export enum AgentUpgradeTaskStatus {
  Accepted = 'Accepted',
  Failed = 'Failed',
  Finished = 'Finished',
  Idle = 'Idle',
  Running = 'Running',
  Scheduled = 'Scheduled',
  Started = 'Started',
}

/**
 * Enum for Error types.
 */
export enum ErrorTypes {
  kEOF = 'kEOF',
  kNonExistent = 'kNonExistent'
}

/**
 * Allowed fs types during Physical File based protection.
 */
export const AllowedFsTypes = ['nfs', 'nfs4', 'autofs'];

/**
 * Specifies the Restore Entity type of Physical files restore workflow.
 */
export enum PhysicalRestoreEntityType {
  kACLOnly = 'kACLOnly',
  kRegular = 'kRegular',
}
