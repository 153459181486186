// Component: GlobalSearchInput

;(function(angular, undefined) {
  'use strict';

  var _typeAheadEntriesToDisplay = 8;

  angular.module('C.globalSearch')
    .controller('GlobalSearchInputCtrl', globalSearchInputFn)
    .component('globalSearchInput', {
      bindings: {
        // @type {Array}  The current results
        results: '=?',

        // @type {Object}   The params object, as constructed in
        // _global-search.js. Optional, as the nav input doesn't use params.
        params: '<?',
      },
      controller: 'GlobalSearchInputCtrl',
      template: require('raw-loader!./global-search-input.html'),
    });

  function globalSearchInputFn(
    _, $state, $timeout, GlobalSearchService, evalAJAX, FEATURE_FLAGS) {

    var $ctrl = this;
    var _paramsChangeTimeout;

    _.assign($ctrl, {
      // Lifecycle hooks
      $onInit: $onInit,
      $onChanges: $onChanges,

      getResults: getResults,
      selectResult: selectResult,

      query: '',
      loadingData: false,
    });

    /**
     * Initialization function.
     *
     * @method   $onInit
     */
    function $onInit() {
      _.assign($ctrl, {
        results: $ctrl.results || GlobalSearchService.getCurrentResults(),
        query: GlobalSearchService.getCurrentQuery(),
        isSearchState: $state.current.name === 'search',
      });

      // If there is a query already, fire getResults() to ensure the result
      // set matches up with the query and params. This is for url param
      // support as user might be loading a URL directly or using browser
      // history to return to a previous search (that is potentially not the
      // represented by the cached results in the service).
      if ($ctrl.query) {
        getResults();
      }
    }

    /**
     * Lifecycle hook function to handle changes to one-way bindings.
     *
     * @method   $onChanges
     * @param    {object}   changes   The AngularJS provided changes object.
     */
    function $onChanges(changes) {
      // If params are initialized via state params url support then this
      // isFirstChange() check might be problematic.
      if (changes.params && !changes.params.isFirstChange()) {
        if (_paramsChangeTimeout) {
          $timeout.cancel(_paramsChangeTimeout);
        }
        _paramsChangeTimeout = $timeout(getResults, 1000);
      }
    }

    /**
     * Handles result selection. TODO: how to handle non selection [ENTER]?
     *
     * @method   selectResult
     * @param    {object|string}   result   The selected result if an object, or
     *                                      the current search query if string
     */
    function selectResult(result) {
      var inputElem;

      // If the selected result was via typeahead selection, save the selected
      // result to the service so it can be initiated as selected on the search
      // results page.
      if (typeof result === 'object') {
        GlobalSearchService.setSelectedResult(result);
      }

      if (!$ctrl.isSearchState) {
        // Manually getting the input element to cache its value, as the
        // debounce isn't resolving the view in tiem for processing on ENTER.
        inputElem = angular.element('#global-search-typeahead-input')[0];
        GlobalSearchService.setCurrentQuery(inputElem.value);
        $state.go('search');
      }
    }


    /**
     * Loads the global search data based on the current user query.
     *
     * @method   getResults
     * @param    {string}   query   The query, if passed its the ngModel
     *                              $viewValue
     * @return   {Object}   A promise to resolve request for results.
     */
    function getResults(query) {
      var params = $ctrl.params || {};

      params.searchString = query || $ctrl.query;

      // Exit early if there is no search string. This is a required field.
      // Consider updating _getParams to fill-in "*" for search string, in which
      // case this early exit won't be needed.
      if (!params.searchString) {
        return;
      }

      $ctrl.loadingData = true;

      // If on the search state, call $state.go so url params get updated.
      if ($ctrl.isSearchState) {
        $state.go('.', $ctrl.params);
      }

      return GlobalSearchService.getResults(params).then(
        function getResultsSuccess(results) {
          // NOTE: Clearing the array and pushing new results into it, this
          // prevents any shared references to the array from being broken.
          $ctrl.results.length = 0;

          if (!FEATURE_FLAGS.sqlRestoreSystemDbs) {
            results = filterSystemDbs(results);
          }

          Array.prototype.push.apply($ctrl.results, results);
          return $ctrl.results.slice(0, _typeAheadEntriesToDisplay);
        },
        evalAJAX.errorMessage
      ).finally(
        function getResultsFinally() {
          $ctrl.loadingData = false;
        }
      );
    }

    /**
     * Filters SQL system database results.
     *
     * @param    {Array}  results   Search results.
     * @returns  Filtered search results.
     */
    function filterSystemDbs(results) {
      return (results || []).filter(function filterResult(result) {
        // SQL system databases should be removed from search results.
        return result._sourceEnv !== 'kSQL' || !result._isSystemDatabase;
      });
    }

  }

})(angular);
