import { ChangeDetectionStrategy, Component, Input, OnInit, Optional } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';

import { canAccessAppFnMap, getAccessibleAppConfigs } from '../../../app-configs/utils';
import {
  flagEnabled,
  IrisContextService,
  isAllClustersScope,
  isDiaasScope,
  isDmsScope,
  isDraasScope,
  isHeliosTenantUser,
  isPiaasScope,
  isRpaasScope,
  isSecurityCenterScope,
  isSmartFilesScope,
} from '../../../iris-context';
import { appPillarsAppUrl, appPillarsBaseUrl, AppState, AppType, pillars, PillarType } from '../../app-pillars.model';

/**
 * Application breadcrumbs indexed by app or pillar type.
 */
export const appBreadcrumbs: Record<string, AppState[]> = {};

// Populate the appBreadcrumbs data structure.
pillars.forEach(pillar => {
  appBreadcrumbs[pillar.type] = [
    {
      title: 'appPillars.title',
      name: 'app-pillars',
      params: { category: null },
      href: appPillarsBaseUrl,
    },
    {
      title: pillar.title,
      name: 'app-pillars',
      params: { category: pillar.type },
      href: appPillarsBaseUrl + pillar.type,
    },
  ];

  pillar.items.forEach(item => {
    appBreadcrumbs[item.app] = [
      {
        title: 'appPillars.title',
        name: 'app-pillars',
        params: { category: null },
        href: appPillarsBaseUrl,
      },
      {
        title: pillar.title,
        name: 'app-pillars',
        params: { category: pillar.type },
        href: appPillarsBaseUrl + pillar.type,
      },
      {
        title: item.title,
        name: item.state,
        compatibleStates: [
          item.state,
          ...(item.compatibleStates || []),
          'app-blank',
        ],
        href: appPillarsAppUrl + item.app,
      },
    ];
  });
});

/**
 * Breadcrumbs for applications.
 */
@Component({
  selector: 'coh-app-breadcrumbs',
  templateUrl: './app-breadcrumbs.component.html',
  styleUrls: ['./app-breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppBreadcrumbsComponent implements OnInit {
  /** Application, used to find out which breadcrumbs to use. */
  @Input() app: AppType | PillarType;

  /** True if it is home page. */
  @Input() isHome = false;

  /** Breadcrumbs. */
  breadcrumbs: AppState[];

  /** Show breadcrumbs if true */
  show = false;

  constructor(
    private irisContext: IrisContextService,
    @Optional() public uiRouterGlobals: UIRouterGlobals,
  ) {
  }

  ngOnInit() {
    if (!this.app) {
      this.app =
        isAllClustersScope(this.irisContext.irisContext) ? 'clusterManager' :
        isSecurityCenterScope(this.irisContext.irisContext) ? 'dgaas' :
        isDiaasScope(this.irisContext.irisContext) ? 'diaas' :
        isDmsScope(this.irisContext.irisContext) ? 'dms' :
        isDraasScope(this.irisContext.irisContext) ? 'draas' :
        isPiaasScope(this.irisContext.irisContext) ? 'piaas' :
        isRpaasScope(this.irisContext.irisContext) ? 'rpaas' :
        isSmartFilesScope(this.irisContext.irisContext) ? 'smartFiles' : null;
    }
    this.breadcrumbs = appBreadcrumbs[this.app];

    const mfAppTypes = [
      'dgaas',
      'draas',
      canAccessAppFnMap.dataInsights(this.irisContext.irisContext) ? null : 'diaas',
    ].filter(Boolean);

    // Update url for Site Continuity and Security Center.
    // They are different apps so redirect from the blank page is more expensive so using their
    // home page directly if user has access/subscription already.
    if (mfAppTypes.includes(this.app) && this.breadcrumbs?.length === 3) {
      const appConfig = getAccessibleAppConfigs(this.irisContext.irisContext)?.find(a => a.app === this.app);

      if (appConfig) {
        this.breadcrumbs[2].href = appConfig.urlSegment;
      }
    }
    this.show = flagEnabled(this.irisContext.irisContext, 'appPillarsEnabled') &&
      !isHeliosTenantUser(this.irisContext.irisContext);
  }

  /**
   * Returns true if the state is the same as the current state.
   *
   * @param state  State to be checked.
   * @param last  True if it is the last breadcrumb.
   * @returns True if the state is the same as the current state.
   */
  sameState(state: AppState, last: boolean): boolean {
    if (last && this.isHome) {
      return true;
    }
    if (this.uiRouterGlobals) {
      const stateName = this.uiRouterGlobals.current?.name;
      const category = this.uiRouterGlobals.params?.category;

      if (stateName === 'app-pillars' && state?.name === 'app-pillars') {
        return category === state?.params?.category;
      }
      return state?.name === stateName || state?.compatibleStates?.includes(stateName);
    }
    return false;
  }
}
