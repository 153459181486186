<cog-settings-list-item
  cogFormGroup
  [formGroup]="formGroup"
  [invalid]="formGroup.invalid"
  [label]="'rename' | translate">
  <cog-settings-list-item-value-display>
    <p>
      {{ formGroupValues.suffix ? ('objectsRenamed' | translate) : ('none' | translate) }}
    </p>
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor>
    <mat-radio-group class="row" #renameGroup [formControl]="formGroupControls.bulkRename">
      <mat-radio-button cogDataId="recovery-bulk-rename" class="margin-right margin-bottom"
        radioGroup="renameGroup" [value]="true">{{'renameBulk' | translate}}</mat-radio-button>
      <mat-radio-button cogDataId="recovery-object-rename" class="margin-right" radioGroup="renameGroup"
        [value]="false" [disabled]="!enableIndividualRename">
        {{'renameIndividual' | translate}}</mat-radio-button>
    </mat-radio-group>
    <div *ngIf="formGroupValues.bulkRename; then thenBlock else elseBlock"></div>
    <ng-template #thenBlock>
      <mat-form-field>
        <input
          matInput
          [formControl]="formGroupControls.suffix"
          [inheritDataId]="true"
          class="col-xs-3"
          [placeholder]="'suffix' | translate"
          cogDataId="suffix-input">
        <mat-error *ngIf="formGroupErrors?.suffix?.whitespace">
          {{ 'errors.patterns.noWhiteSpace' | translate }}
        </mat-error>
      </mat-form-field>
    </ng-template>
    <ng-template #elseBlock>
      <div class="option-container col-xs-12">
        <div class="option-head">
          <h4 translate class="rename-col1">{{ 'originalName' | translate }}</h4>
          <h4 translate class="rename-col2">{{ 'newName' | translate }}</h4>
        </div>
        <div class="option-body">
          <ng-container [formArrayName]="formControlNames.objectDetails"
            *ngFor="let control of formGroupControls.objectDetails.controls; let i=index">
            <coh-settings-list-object-rename-item [formControl]="control">
            </coh-settings-list-object-rename-item>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
