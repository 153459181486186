/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CreateBondResult } from '../models/create-bond-result';
import { CreateBondParameters } from '../models/create-bond-parameters';
import { HostEntry } from '../models/host-entry';
import { HostResult } from '../models/host-result';
import { EditHostsParameters } from '../models/edit-hosts-parameters';
import { AppendHostsParameters } from '../models/append-hosts-parameters';
@Injectable({
  providedIn: 'root',
})
class NetworkService extends __BaseService {
  static readonly CreateBondPath = '/public/network/bonds';
  static readonly DeleteBondPath = '/public/network/bonds/{name}';
  static readonly ListHostsPath = '/public/network/hosts';
  static readonly EditHostsPath = '/public/network/hosts';
  static readonly AppendHostsPath = '/public/network/hosts';
  static readonly DeleteHostsPath = '/public/network/hosts';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Create a new network bond.
   *
   * Sends a request to create a new network bond on the Cluster. This can only be
   * performed on a Node before it is part of a Cluster.
   * @param params The `NetworkService.CreateBondParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateBondResponse(params: NetworkService.CreateBondParams): __Observable<__StrictHttpResponse<CreateBondResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/network/bonds`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateBondResult>;
      })
    );
  }
  /**
   * Create a new network bond.
   *
   * Sends a request to create a new network bond on the Cluster. This can only be
   * performed on a Node before it is part of a Cluster.
   * @param params The `NetworkService.CreateBondParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateBond(params: NetworkService.CreateBondParams): __Observable<CreateBondResult> {
    return this.CreateBondResponse(params).pipe(
      __map(_r => _r.body as CreateBondResult)
    );
  }

  /**
   * Delete a network bond.
   *
   * Sends a request to delete a network bond from the Cluster. This can only be
   * performed on a Node before it is part of a Cluster.
   * @param params The `NetworkService.DeleteBondParams` containing the following parameters:
   *
   * - `name`: Specifies the name of the bond being deleted.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteBondResponse(params: NetworkService.DeleteBondParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/network/bonds/${encodeURIComponent(params.name)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a network bond.
   *
   * Sends a request to delete a network bond from the Cluster. This can only be
   * performed on a Node before it is part of a Cluster.
   * @param params The `NetworkService.DeleteBondParams` containing the following parameters:
   *
   * - `name`: Specifies the name of the bond being deleted.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteBond(params: NetworkService.DeleteBondParams): __Observable<null> {
    return this.DeleteBondResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get the current entries in the hosts file on the Cluster.
   *
   * Sends a request to get a list of the current entries in the hosts file
   * on the Cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  ListHostsResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<Array<HostEntry>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/network/hosts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<HostEntry>>;
      })
    );
  }
  /**
   * Get the current entries in the hosts file on the Cluster.
   *
   * Sends a request to get a list of the current entries in the hosts file
   * on the Cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  ListHosts(regionId?: string,
    accessClusterId?: number): __Observable<Array<HostEntry>> {
    return this.ListHostsResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as Array<HostEntry>)
    );
  }

  /**
   * Edit entries in the Cluster's /etc/hosts file.
   *
   * Sends a request to edit one or more entries in the Cluster's /etc/hosts file.
   * @param params The `NetworkService.EditHostsParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  EditHostsResponse(params: NetworkService.EditHostsParams): __Observable<__StrictHttpResponse<HostResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/network/hosts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HostResult>;
      })
    );
  }
  /**
   * Edit entries in the Cluster's /etc/hosts file.
   *
   * Sends a request to edit one or more entries in the Cluster's /etc/hosts file.
   * @param params The `NetworkService.EditHostsParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  EditHosts(params: NetworkService.EditHostsParams): __Observable<HostResult> {
    return this.EditHostsResponse(params).pipe(
      __map(_r => _r.body as HostResult)
    );
  }

  /**
   * Add new entries to the /etc/hosts file.
   *
   * Sends a request to add one or more new entries to the Cluster's /etc/hosts
   * file.
   * @param params The `NetworkService.AppendHostsParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  AppendHostsResponse(params: NetworkService.AppendHostsParams): __Observable<__StrictHttpResponse<HostResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/network/hosts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HostResult>;
      })
    );
  }
  /**
   * Add new entries to the /etc/hosts file.
   *
   * Sends a request to add one or more new entries to the Cluster's /etc/hosts
   * file.
   * @param params The `NetworkService.AppendHostsParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  AppendHosts(params: NetworkService.AppendHostsParams): __Observable<HostResult> {
    return this.AppendHostsResponse(params).pipe(
      __map(_r => _r.body as HostResult)
    );
  }

  /**
   * Remove entries from the /etc/hosts file.
   *
   * Sends a request to remove one or more entries from the Cluster's
   * etc/hosts file.
   * @param params The `NetworkService.DeleteHostsParams` containing the following parameters:
   *
   * - `ips`: Specifies a list of the IP addresses of entries to remove from the
   *   Cluster's /etc/hosts file.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  DeleteHostsResponse(params: NetworkService.DeleteHostsParams): __Observable<__StrictHttpResponse<HostResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ips || []).forEach(val => {if (val != null) __params = __params.append('ips', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/network/hosts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HostResult>;
      })
    );
  }
  /**
   * Remove entries from the /etc/hosts file.
   *
   * Sends a request to remove one or more entries from the Cluster's
   * etc/hosts file.
   * @param params The `NetworkService.DeleteHostsParams` containing the following parameters:
   *
   * - `ips`: Specifies a list of the IP addresses of entries to remove from the
   *   Cluster's /etc/hosts file.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  DeleteHosts(params: NetworkService.DeleteHostsParams): __Observable<HostResult> {
    return this.DeleteHostsResponse(params).pipe(
      __map(_r => _r.body as HostResult)
    );
  }
}

module NetworkService {

  /**
   * Parameters for CreateBond
   */
  export interface CreateBondParams {
    body: CreateBondParameters;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteBond
   */
  export interface DeleteBondParams {

    /**
     * Specifies the name of the bond being deleted.
     */
    name: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for EditHosts
   */
  export interface EditHostsParams {
    body: EditHostsParameters;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for AppendHosts
   */
  export interface AppendHostsParams {
    body: AppendHostsParameters;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteHosts
   */
  export interface DeleteHostsParams {

    /**
     * Specifies a list of the IP addresses of entries to remove from the
     * Cluster's /etc/hosts file.
     */
    ips: Array<string>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { NetworkService }
