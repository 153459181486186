import { AfterViewInit, ChangeDetectorRef, Component, Input, ViewEncapsulation } from '@angular/core';

/**
 * The type for thermometer chart data
 */
export interface ThermometerChartData {
  /**
   * The main label for the element.
   */
  mainLabel: string;

  /**
   * The sub label for the element.
   */
  subLabel: string;

  /**
   * The percent value of the element
   */
  percent: number;

  /**
   * Any custom class to apply to the element.
   * This will be used to provde colors to the chart elements.
   */
  class?: string;
}

/**
 * @description
 * A thermometer chart shows a vertical single tube with different
 * distributions for data arranged one on top of another. It can have
 * labels for distribution on either side of the tube.
 *
 *
 * @example
 *   <cog-thermometer-chart></cog-thermometer-chart>
 */
@Component({
  selector: 'cog-thermometer-chart',
  templateUrl: './thermometer-chart.component.html',
  styleUrls: ['./thermometer-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ThermometerChartComponent implements AfterViewInit {
  /**
   * Custom chart options.
   * This will extend default base configuration.
   */
  @Input() chartData: ThermometerChartData[] = [];

  /**
   * Will provide an animation to the component when ready
   */
  transition = false;

  constructor(readonly cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.transition = true;
    this.cdr.detectChanges();
  }
}
