<cog-settings-list-item [formGroup]="formGroup"
  [invalid]="formControl.invalid"
  [label]="'cloudMigration' | translate">
  <cog-settings-list-item-value-display>
    {{formControl.value | boolLabel}}
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor [formGroup]="formGroup"
    cogDataId="settings-cloud-migration">
    <mat-slide-toggle [formControlName]="name"
      [inheritDataId]="true"
      cogDataId="toggle">
      {{'cloudMigration' | translate}}
    </mat-slide-toggle>
    <mat-hint *ngIf="!formControl.value">
      {{'job.settings.cloudMigrationDisabled' | translate}}
    </mat-hint>
    <mat-hint (click)="openDownloadAgentsModal($event)"
      *ngIf="formControl.value"
      [innerHtml]="'cloudMigrationEnabledDescription' | translate">
    </mat-hint>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
