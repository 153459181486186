<div class="object-name">
  <cog-icon [shape]="node.protectionSource | objectIcon : { isProtected: isNodeProtected }">
  </cog-icon>
  <h4 class="title">{{node.protectionSource.name}}</h4>
</div>

<!-- General Information -->
<div class="source-meta-grouping object-info-list">
  <ul>
    <!-- location -->
    <li class="source-meta-line-item" *ngIf="azureSqlSource?.location">
      <span class="source-meta-label">{{'location' | translate}}</span>
      {{azureSqlSource.location}}
    </li>

    <!-- Database Size -->
    <li class="source-meta-line-item" *ngIf="node?.logicalSize">
      <span class="source-meta-label">{{'databaseSize' | translate}}</span>
      {{node.logicalSize | byteSize}}
    </li>

    <!-- Server Size -->
    <li class="source-meta-line-item" *ngIf="totalLogicalSize">
      <span class="source-meta-label">{{'serverSize' | translate}}</span>
      {{totalLogicalSize | byteSize}}
    </li>
  </ul>
</div>

<coh-protection-status-meta *ngIf="azureSqlSource.type === 'kSQLDatabase'"
  class="margin-top-lg"
  [node]="node"
  [protectionLabel]="'sourceTreePub.tooltips.protected' | translate">
</coh-protection-status-meta>
