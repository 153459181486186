import { isDGaaSUser } from './dgaas-utils';
import { isDmsOnlyUser } from './dms-utils';
import { flagEnabled } from './iris-context-util';
import { IrisContext } from './iris-context.model';

/**
 * Returns true if the current user is a Security Center configured user.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if user is Security Center user.
 */
export function isSecurityCenterUser(irisContext: IrisContext): boolean {
  return (flagEnabled(irisContext, 'securityCenterEnabled') || isDGaaSUser(irisContext)) &&
    (!isDmsOnlyUser(irisContext) || flagEnabled(irisContext, 'securityCenterBaasSupported'));
}

/**
 * Returns true if the current user is in Security Center scope.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if the user in Security Center context.
 */
export function isSecurityCenterScope(irisContext: IrisContext): boolean {
  return Boolean(isSecurityCenterUser(irisContext) && irisContext?.selectedScope?._serviceType === 'dgaas');
}
