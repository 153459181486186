// Component:  Select or Register Replication Target/Cluster

;(function(angular, undefined) {
  'use strict';

  var componentName = 'selectReplicationTarget';
  var config = {
    controller: 'SelectReplicationTargetCtrl',
    require: { ngModel: '^ngModel' },
    templateUrl:
      'app/global/select-replication-target/select-replication-target.html',
    bindings: {
      /**
       * Specify a default vault by this ID if found in the list.
       *
       * @type   {integer}
       */
      defaultClusterId: '@?',
      name: '@?',

      /**
       * If a custom label is needed, its ui.json key can be provided via the
       * label attribute. Additionally, passing a value of 'false' via this
       * attribute will hide the label.
       *
       * @type   {string}
       */
      label: '@?',

      /**
       * Optional Job Proto to orient results around.
       *
       * @type   {object}
       */
      job: '<?',

      /**
       * Optional target ids which are to be disabled from the selection. In
       * cases like replicate/archive now, we can provide multiple targets but
       * they should not be duplicated. So when a target is selected, it needs
       * to be disabled so that it is not selected again.
       *
       * @type   {array}
       */
      disabledTargets: '<?',
    },
  };


  /**
   * Service-like object for sharing information across instances of this
   * ngComponent.
   *
   * @type   {object}
   * @global
   */
  var SHARED = {
    /**
     * Loading status flag.
     *
     * When one ngComponent updates this value, all instances will reflect the
     * change and updates will apply to all instances.
     *
     * @type   {boolean}
     */
    loading: false,

    /**
     * Cached Remote Pairings.
     *
     * This var is accessible by all instances of this ngComponent, sort of like
     * a dumb service. Each ngComponent has a discreet instance of the
     * controller, but all of those controllers have access to this var. This
     * lets one instance make updates accessible to all other instances that are
     * active in the browser session.
     *
     * @type   {array}
     */
    CACHED_PAIRINGS: [],
  };

  angular
    .module('C.selectReplicationTarget', [])
    .controller(
      'SelectReplicationTargetCtrl', SelectReplicationTargetControllerFn)
    .component(componentName, config);

  /**
   * @ngdoc component
   * @name C.selectReplicationTarget:selectReplicationTarget
   * @function
   *
   * @description
   *   Reusable component for selecting or registering a new External Target
   *   (icebox vault) in a modal window. Handles fetching of existing vaults
   *   internally. Totally self contained. Exposes the selected/created vault
   *   on the ngModel of the component.
   *
   *   All ngModel related attribute directives should also work with this one.
   *
   * @example
       <select-replication-target
         name="myName"
         required
         ng-disabled="<expression>"
         default-cluster-id="1"
         ng-model="myTarget"></select-replication-target>
   */
  function SelectReplicationTargetControllerFn($scope, $attrs, $timeout, $q,
    $translate, evalAJAX, RemoteClusterService, ViewBoxService, PolicyService, $rootScope) {

    /**
     * Controller reference.
     *
     * @type   {object}
     * @instance
     */
    var $ctrl = this;

    angular.extend($ctrl, {
      SHARED: SHARED,
      $onInit: init,
      clusterGrouper: clusterGrouper,
      selectClusterOrRegisterNew: selectClusterOrRegisterNew,
    });


    /**
     * Initialize this component.
     *
     * @method   init
     * @instance
     */
    function init() {
      $ctrl.id = [componentName, $ctrl.name || Date.now() ].join('-');
      $ctrl.name = $ctrl.name || [ componentName, $ctrl.id ].join('-');
      $ctrl.disabledTargets = $ctrl.disabledTargets || [];

      SHARED.groupNames = [
        $translate.instant('replicationTargetsInThisJobPolicy'),
        $translate.instant('replicationTargetsNotInThisJobPolicy'),
      ];

      fetchDependencies();

      if ($ctrl.job && $ctrl.job.viewBoxId) {
        getViewBoxName($ctrl.job.viewBoxId);
      }
    }

    /**
     * Fetches dependencies.
     *
     * @method   fetchDependencies
     */
    function fetchDependencies() {
      var dependencyPromises = {
        clusters: getClusters(),
        policy: $ctrl.job && $ctrl.job.isActive &&
          getPolicy($ctrl.job.policyId),
      };

      SHARED.loading = true;

      $q.all(dependencyPromises).then(
        function dependenciesReceived(resp) {
          if (resp.policy) {
            updateThisJobsReplicaTargetsMap(resp.policy);
            $ctrl.policy = resp.policy;
          }

          updateSharedClusters(resp.clusters);

          if ($ctrl.defaultClusterId > 0) {
            /**
             * If a `defaultClusterId` attribute is defined, preselect the
             * Cluster with the same ID, if present. This only affects this
             * instance of the ngComponent.
             */
            $ctrl.selectedTarget =
              resp.clusters.find(function findTarget(cluster) {
                if (cluster && cluster.clusterId == $ctrl.defaultClusterId) {
                  selectClusterOrRegisterNew(cluster);

                  return true;
                }
              });
          }
        }
      )
      .finally(function finallyFn() { SHARED.loading = false; });
    }

    /**
     * Calls the api and gets view box info.
     *
     * @method     getViewBoxName
     * @param      {integer}   viewBoxId  The view box id
     * @return     {string}  View box name from the api response.
     */
    function getViewBoxName(viewBoxId) {
      return ViewBoxService.getViewBox(viewBoxId).then(
        function getViewSuccessFn(viewBox) {
          return $ctrl.viewBoxName = viewBox.name;
        }
      );
    }

    /**
     * Updates a map of archive targets for a configured Job's Policy.
     *
     * @method   updateThisJobsArchiveTargetsMap
     * @param    {object}   [policy]   This Job's Policy
     * @return   {object}   The map
     */
    function updateThisJobsReplicaTargetsMap(policy) {
      if (!policy || !Array.isArray(policy.snapshotReplicationCopyPolicies)) {
        return SHARED.jobTargetsMap = undefined;
      }

      return SHARED.jobTargetsMap = SHARED.jobTargetsMap ||
        policy.snapshotReplicationCopyPolicies.reduce(
          function reducer(map, target) {
            if (target.target && !map[target.target.clusterId]) {
              map[target.target.clusterId] = target.target;
            }

            return map;
          },
          {}
        );
    }

    /**
     * uiSelect Grouping Fn.
     *
     * @method   clusterGrouper
     * @param    {object}   item   The Cluster object.
     * @return   {string}   Group Display key (translated).
     */
    function clusterGrouper(item) {
      // Add new, don't group it.
      if (!item.clusterId || !SHARED.jobTargetsMap) {
        // In the template, uiSelects `group-filter` will treat this as
        // `undefined`.
        return '';
      }

      return SHARED.jobTargetsMap[item.clusterId] ?
        SHARED.groupNames[0] :
        SHARED.groupNames[1];
    }

    /**
     * Gets a policy by ID.
     *
     * @method   getPolicy
     * @param    {number}   id   The Policy ID.
     * @return   {object}   Promise to resolve with the Policy.
     */
    function getPolicy(id) {
      return SHARED.$getPolicyPromise = SHARED.$getPolicyPromise ||
        PolicyService.getPolicy(id).then(
          function policySuccess(policy) {
            return $ctrl.policy = policy;
          }
        );
    }

    /**
     * Fetches the external kArchival targets for user selection.
     *
     * This Fn is shared by all instances of this ngComponent. It self manages
     * when a fetch is already underway. This prevents infinite loop errors from
     * the the $watcher.
     *
     * It is defined within the controller, however, for tidy access to
     * injections without muddying up scope, ctrl, or this IIFE wrapper.
     *
     * @method   getClusters
     * @global
     * @return   {object}   Promise to resolve with the filtered list of vaults,
     *                      or the server's raw response if error.
     */
    function getClusters() {
      return SHARED.$getClustersPromise = SHARED.$getClustersPromise ||
        RemoteClusterService.getRemoteClusters()
          .catch(evalAJAX.errorMessage)
          .finally(function targetsFinally() {
            // When this promise is done, clear it out so additional instances
            // will fetch data anew without colliding with this one, and all
            // instances get the update.
            SHARED.$getClustersPromise = undefined;
          });
    }

    /**
     * If passed a valid object, this will set it in the request object,
     * otherwise it triggers the Register New External Target modal and uses
     * that newly registered target.
     *
     * @method   selectClusterOrRegisterNew
     * @global
     * @instance
     * @param    {object}   target   The selected External Target.
     */
    function selectClusterOrRegisterNew(cluster) {
      if (cluster && cluster.clusterId) {
        // Set the target with the one selected and exit.
        return $ctrl.ngModel.$setViewValue(cluster);
      }

      SHARED.loading = true;

      RemoteClusterService.registerRemoteSlider().then(
          function promiseResolved(newRemoteCluster) {
            // Insert this newly created target into the list.
            SHARED.CACHED_PAIRINGS.unshift(newRemoteCluster);

            // Set the selection to this new target.
            selectClusterOrRegisterNew(
              // This also sets this target as selected in the view.
              $ctrl.selectedTarget = newRemoteCluster
            );
          },
          function promiseCanceled() {
            // Clear the selected model only after cancel so that in the case
            // that the user has selected a known target, made additional query
            // settings changes, and decided to register a new target, those
            // other settings aren't lost unless the modal is canceled.
            $ctrl.ngModel.$setViewValue(
              $ctrl.selectedTarget = undefined
            );
          }
        )
        .finally(function registerFinally() {
          SHARED.loading = false;
        });
    }

    /**
     * $attrs.$observe handler for (ng)disabled attributes.
     *
     * @method   toggleDisabled
     * @instance
     * @param    {string|boolean}   [disabled]   The detected attribute's
     *                                           expression.
     */
    function toggleDisabled(disabled) {
      $ctrl.isDisabled =
        $attrs.hasOwnProperty('disabled') || $scope.$eval(disabled);
    }

    /**
     * getClusters response handler that replaces the list of targets with the
     * newly received list.
     *
     * @method   updateSharedClusters
     * @global
     * @param    {array}   targets   The list of External Targets
     */
    function updateSharedClusters(targets) {
      SHARED.CACHED_PAIRINGS = targets;

      // Group the targets according to policy
      if ($ctrl.job && $ctrl.policy) {
        SHARED.CACHED_PAIRINGS.forEach(function checkTargetInPolicy(target) {
          target._group = clusterGrouper(target);
        });
      }

      // Check for viewBox pairing and disable the targets which have no
      // pairing with the replicated cluster.
      if ($ctrl.job.viewBoxId) {
        SHARED.CACHED_PAIRINGS.forEach(function checkViewboxPairing(target) {
          if (target.viewBoxPairInfo) {
            target.viewBoxPairInfo.forEach(function findMatchingPair(viewBox) {
              if ($ctrl.job.viewBoxId === viewBox.localViewBoxId) {
                target._hasCompatibleViewBoxPairing = true;
              }
            });
          } else if (target.name === 'registerRemoteCluster') {
            target._hasCompatibleViewBoxPairing = true;
          }
        });
      }

      return targets;
    }

    /**
     * Gets the tooltip message.
     *
     * @method   getTooltipMessage
     * @param    {object}   cluster   The replication cluster object
     * @return   {string}   The tooltip message.
     */
    $scope.getTooltipMessage = function getTooltipMessage(cluster) {
      if(!cluster) {
        return;
      }
      switch (true) {
        case !cluster._hasCompatibleViewBoxPairing:
          return $translate.instant('warnings.incompatibleViewBoxPairing', { storageDomainName: $ctrl.viewBoxName });

        case $ctrl.disabledTargets.includes(cluster.clusterId):
          return $translate.instant('warnings.targetAlreadyReplicated');

        default:
          return '';

      }
    };

    /**
     * Determines if target should be disabled
     *
     * @method   isTargetDisabled
     * @param    {object}    cluster   The cluster
     * @return   {boolean}   True if target disabled, False otherwise.
     */
    $scope.isTargetDisabled = function isTargetDisabled(cluster) {
      return cluster &&
        ($ctrl.disabledTargets.includes(cluster.clusterId) ||
        ($ctrl.job.viewBoxId && !cluster._hasCompatibleViewBoxPairing));
    };

    $scope.$watch('$ctrl.selectedTarget', function(newValue, oldValue) {
      // Enable this target which is being replaced with new value
      if (oldValue) {
        $ctrl.disabledTargets
          .splice($ctrl.disabledTargets.indexOf(oldValue.clusterId), 1);
      }

      // Disable this target from future selection
      if (newValue) {
        $ctrl.disabledTargets.push(newValue.clusterId);
      }
    });

    // Because the (ng)disabled state is NOT a property of ctrl.ngModel, we're
    // $observing the attrs hash instead.
    $attrs.$observe(
      $attrs.hasOwnProperty('disabled') ? 'disabled' : 'ngDisabled',
      toggleDisabled
    );
  }

})(angular);
