<div cogTooltipChangeDetection [matTooltip]="tooltip" *ngIf="nodeContext">
  <!-- Checkbox is hidden for items which are under an auto selection tree-->
  <mat-radio-button
    cogDataId="select"
    [inheritDataId]="true"
    *ngIf="!showAutoSelectIcon && node.isSelectable && nodeContext.selection.isSingleSelect"
    color="accent"
    [checked]="nodeContext.selected || selection.descendantsAllSelectedUnfiltered(node)"
    [disabled]="isDisabled"
    (change)="handleUserClick()">
  </mat-radio-button>

  <mat-checkbox #menuTrigger="matMenuTrigger"
    cogDataId="select"
    [inheritDataId]="true"
    *ngIf="showCheckboxMenu"
    [checked]="nodeContext.selected || selection.descendantsAllSelectedUnfiltered(node)"
    [indeterminate]="selection.descendantsPartiallySelectedUnfiltered(node)"
    [disabled]="isDisabled"
    [matMenuTriggerFor]="menu"
    (keydown.space)="$event.preventDefault(); menuTrigger.openMenu()"
    (click)="$event.preventDefault()">
  </mat-checkbox>

  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngIf="node.canAutoSelect" (click)="handleAutoSelectClick()">
      {{autoSelectObjectLabel}}
    </button>
    <button mat-menu-item [disabled]="node.isPureProtectionGroup || node.isIbmFlashSystemProtectionGroup" (click)="handleUserClick()">
      {{selectChildrenLabel}}
    </button>
    <button
      mat-menu-item
      *ngIf="node.isTag && nodeContext.canExclude && !nodeContext.autoSelected"
      (click)="handleExclusionClick()">
      {{excludeObjectLabel}}
    </button>
  </mat-menu>

  <mat-checkbox
    cogDataId="select"
    [inheritDataId]="true"
    *ngIf="!showAutoSelectIcon && node.isSelectable && !nodeContext.selection.isSingleSelect && !showCheckboxMenu"
    [checked]="nodeContext.selected || selection.descendantsAllSelectedUnfiltered(node)"
    [indeterminate]="selection.descendantsPartiallySelectedUnfiltered(node)"
    [disabled]="isDisabled"
    (change)="handleUserClick()">
  </mat-checkbox>

  <!-- Alternate icon button to toggle auto select or exclude. This can be primary or warn color -->
  <button
    class="auto-selection-toggle"
    cogDataId="auto-select"
    [inheritDataId]="true"
    type="button"
    mat-icon-button
    *ngIf="showAutoSelectIcon && !node.isGloballyExcluded"
    [disabled]="isAncestorExcluded || ineligibleForAutoSelect"
    (click)="toggleExclude()">
    <cog-icon
      class="auto-icon"
      *ngIf="!isAncestorExcluded"
      [color]="!noModifierClass ? nodeContext.excluded ? 'warn' : 'primary' : ''"
      [shape]="nodeContext.excluded ? excludedIcon ?? autoSelectedIcon : autoSelectedIcon">
    </cog-icon>
    <cog-icon *ngIf="isAncestorExcluded || ineligibleForAutoSelect" [shape]="ancestorExcludedIcon"></cog-icon>
  </button>
</div>
