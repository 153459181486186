import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DataTreeDetailDirective, DataTreeNodeContext } from '../data-tree-detail.directive';
import { DataTreeControl } from '../shared/data-tree-control';
import { DataTreeSelectionModel } from '../shared/data-tree-selection-model';
import { DataTreeNode } from '../shared/data-tree.model';

/**
 * The data tree node is responsible for showing the expand/collapse buttons, selection, and
 * auto selection options for a node. Node details are projected via ng-content. This is used
 * internally by DataTreeComponent and is not exposed outside of this module.
 *
 * @example
 * <cog-data-tree-node *ngIf="data && treeSelection && treeControl.dataNodes"
 *   [node]="node"
 *   [treeControl]="treeControl">
 *   <ng-container *ngTemplateOutlet="treeDetail.template; context: {$implicit: node}"></ng-container>
 * </cog-data-tree-node>
 */
@Component({
  selector: 'cog-data-tree-node',
  templateUrl: './data-tree-node.component.html',
  styleUrls: ['./data-tree-node.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataTreeNodeComponent<T extends DataTreeNode<any>> implements OnChanges, OnDestroy {

  /**
   * Gets the detail context for a given node. This is included in the node detail context to simplify
   * node calculations.
   */
  nodeDetailContext: DataTreeNodeContext<T> = null;

  /**
   * Returns an array of the length of the current level which can be used to add spacing
   * to the tree row.
   */
  get paddingArray(): any[] {
    return Array(this.node ? this.node.level : 0);
  }

  /**
   * Returns true if the component has enough data to render.
   */
  get isInitialized() {
    return this.node && this.selection && this.treeDetails;
  }

  /**
   * Optional function used to decorate the node's context when it is generated.
   */
  @Input() nodeDecoratorFn: (ctx: DataTreeNodeContext<T>) => DataTreeNodeContext<T> = null;

  /**
   * The current node being rendered.
   */
  @Input() node: T;

  /**
   * The control for the current tree.
   */
  @Input() treeControl: DataTreeControl<T>;

  /**
   * The data tree selection model.
   */
  @Input() selection: DataTreeSelectionModel<T>;

  /**
   * This is an area of detail structural directives that can be used to render information about the
   * node. The first item will be grouped along with the spacer and expand buttons and subsequent
   * details will be shown next to them.
   */
  @Input() treeDetails: DataTreeDetailDirective[] = [];

  /**
   * Used to clean up subscriptions.
   */
  private destroy = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef) {

  }

  /**
   * Sets up subscriptions for the selection and expansion models to listen
   * for changes and udpate the node.
   *
   * @param changes input changes in the component
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.selection || changes.treeControl) {
      this.destroy.next();

      if (this.treeControl) {
        this.treeControl.expansionModel.changed.pipe(takeUntil(this.destroy)).subscribe(() => {
          // Do not update the node detail context when only the expansion model changes, this reduces
          // the code run in each child's change detectiion.
          this.cdr.detectChanges();
        });
      }
    }

    if (changes.selection) {
      this.destroy.next();

      if (this.selection) {
        this.selection.changes$.pipe(takeUntil(this.destroy)).subscribe(() => {
          this.nodeDetailContext = this.getNodeDetailContext();
          this.cdr.detectChanges();
        });
      }
    }
    this.nodeDetailContext = this.getNodeDetailContext();
  }

  /**
   * Cleans up subscriptions.
   */
  ngOnDestroy() {
    this.destroy.next();
  }

  /**
   * Gets the detail context for a given node. This is included in the node detail context to simplify
   * node calculations.
   */
  getNodeDetailContext(): DataTreeNodeContext<T> {
    if (!this.node || !this.selection || !this.treeControl) {
      return null;
    }
    let ctx =  {
      node: this.node,
      selection: this.selection,
      treeControl: this.treeControl,
      selected: this.selection.isSelected(this.node),
      autoSelected: this.selection.isAutoSelected(this.node),
      excluded: this.selection.isExcluded(this.node),
      ancestorAutoSelected: this.selection.isAncestorAutoSelected(this.node),
      ancestorExcluded: this.selection.isAncestorExcluded(this.node),
      canSelect: this.selection.canSelectNode(this.node),
      canAutoSelect: this.selection.canAutoSelectNode(this.node),
      canExclude: this.selection.canExcludeNode(this.node),
    };
    ctx = this.nodeDecoratorFn ? this.nodeDecoratorFn(ctx) : ctx;
    return ctx;
  }
}
