import { isRpaasUser, isSecurityCenterUser } from '@cohesity/iris-core';
import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const rpaasPolicyAccess = (ctx: StateAccessContext) =>
  ctx.clusterInfo?._serviceType === 'rpaas' ||
  (isSecurityCenterUser(ctx.irisContext) && isRpaasUser(ctx.irisContext) && ctx.FEATURE_FLAGS.rpaasEnabled);

const rpaasMetadataAccess = (ctx: StateAccessContext) =>
  ctx.clusterInfo?._serviceType === 'rpaas' && ctx.FEATURE_FLAGS.rpaasEnabled && ctx.FEATURE_FLAGS.rpaasCloudRetrieve;

export const RpaasPolicyConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'rpaas-protection.**',
      url: '/rpaas/protection',
      loadChildren: () => import('./rpaas-policy.module').then(m => m.RpaasPolicyModule),
    },
    {
      name: 'rpaas-protection-clusters.**',
      url: '/rpaas/clusters',
      loadChildren: () => import('./rpaas-policy.module').then(m => m.RpaasPolicyModule),
    },
  ],

  allClusterMap: {
    'rpaas-protection': true,
    'rpaas-protection.policy-build': true,
    'rpaas-protection.policy-details': true,
    'rpaas-protection.landing': true,
    'rpaas-protection.landing.policy-list': true,
    'rpaas-protection.landing.backup': {
      globalContext: true,
    },
    'rpaas-protection.add-protection': true,
    'rpaas-protection-clusters': true,
    'rpaas-protection-clusters.list': true,
    'rpaas-protection-clusters.metadata': true,
    'rpaas-protection-clusters.metadata.retrieved': true,
    'rpaas-protection-clusters.metadata.searches': true,
    'rpaas-protection-clusters.search-detail': true,
  },

  getStateAccessMap(): StateAccessMap {
    return {
      'rpaas-protection': rpaasPolicyAccess,
      'rpaas-protection.policy-build': rpaasPolicyAccess,
      'rpaas-protection.policy-details': rpaasPolicyAccess,
      'rpaas-protection.landing': rpaasPolicyAccess,
      'rpaas-protection.landing.policy-list': rpaasPolicyAccess,
      'rpaas-protection.landing.backup': rpaasPolicyAccess,
      'rpaas-protection.add-protection': rpaasPolicyAccess,
      'rpaas-protection-clusters': rpaasPolicyAccess,
      'rpaas-protection-clusters.list': rpaasPolicyAccess,
      'rpaas-protection-clusters.metadata': rpaasMetadataAccess,
      'rpaas-protection-clusters.metadata.retrieved': rpaasMetadataAccess,
      'rpaas-protection-clusters.metadata.searches': rpaasMetadataAccess,
      'rpaas-protection-clusters.search-detail': rpaasMetadataAccess,
    };
  },
};
