import { Injectable, inject } from '@angular/core';
import { SearchServiceApi } from '@cohesity/api/v2';
import { IrisContextService, flagEnabled } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AppliedFilter,
  FilterFieldConfiguration,
  FilterFieldOption,
  FilterableFields,
  IFieldFilterService,
  filterFieldLabelKey,
} from '../constants';
import { AnomaliesFilterService } from './field-filters/anomalies-filter.service';
import { ClassificationFilterService } from './field-filters/classification-filter.service';
import { ClusterFilterService } from './field-filters/cluster-filter.service';
import { ObjectTypeFilterService } from './field-filters/object-type-filter.service';
import { ProtectionFilterService } from './field-filters/protection-filter.service';
import { SourceFilterService } from './field-filters/source-filter.service';
import { TagsFilterService } from './field-filters/tags-filter.service';
import { ThreatsFilterService } from './field-filters/threats-filter.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryFiltersService {
  /**
   * List of filterable fields and possible options for that field
   */
  readonly filterFieldOptions$ = new BehaviorSubject<AppliedFilter[]>([]);

  /**
   * map of filter field specific services
   */
  private fieldFilterServices: Partial<Record<FilterableFields, IFieldFilterService>> = {
    [FilterableFields.anomalies]: flagEnabled(this.irisCtx.irisContext, 'securityCenterInventoryPhase2')
      ? inject(AnomaliesFilterService)
      : null,
    [FilterableFields.classification]: flagEnabled(this.irisCtx.irisContext, 'securityCenterInventoryPhase2')
      ? inject(ClassificationFilterService)
      : null,
    [FilterableFields.threats]: flagEnabled(this.irisCtx.irisContext, 'securityCenterInventoryPhase2')
      ? inject(ThreatsFilterService)
      : null,
    [FilterableFields.clusters]: inject(ClusterFilterService),
    [FilterableFields.sources]: inject(SourceFilterService),
    [FilterableFields.status]: inject(ProtectionFilterService),
    [FilterableFields.tags]: inject(TagsFilterService),
    [FilterableFields.type]: inject(ObjectTypeFilterService),
  };

  /**
   * subscription for the filter field option generation
   */
  private filterValueSubscription?: Subscription;

  constructor(
    private irisCtx: IrisContextService,
    private translateService: TranslateService
  ) { }

  /**
   * Generate API compatible filters
   *
   * @param key field specific key
   * @param selectedValues selected values for the field
   * @param appliedFilters currently computed filters based on which additional filter can be generated
   * @returns filters that API understands
   */
  getTransformedAPIFilters(
    key: string,
    selectedValues: string[],
    appliedFilters: SearchServiceApi.SearchObjectsParams,
  ): SearchServiceApi.SearchObjectsParams {
    const service: IFieldFilterService = this.fieldFilterServices[key];
    return service?.transformFilterValues(selectedValues, appliedFilters) || {};
  }

  /**
   * populates the filter option for all the possible filterable fields
   */
  populateFilterOptions() {
    this.filterValueSubscription?.unsubscribe();

    this.filterValueSubscription = this.generateFilterFieldOptions();
  }

  /**
   * Allows access to the field specific filter level configuration
   *
   * @param field field for which we need the configuration
   * @returns field specific filter configuration
   */
  private getFieldConfiguration(field: FilterableFields): FilterFieldConfiguration {
    return this.fieldFilterServices[field]?.getFilterConfiguration?.() || {
      options: {},
    };
  }

  /**
   * Generates and returns the filter field option for all the known filterable fields
   *
   * @returns filter field options for all the fields
   */
  private getFilterFieldOptions(field: FilterableFields): Observable<FilterFieldOption[]> {
    return this.fieldFilterServices[field]?.getFilterOptions() || of([]);
  }

  /**
   * Generates list of filterable fields along with the possible options for the fields
   */
  private generateFilterFieldOptions() {
    const fields = Object.keys(this.fieldFilterServices).filter(key => !!this.fieldFilterServices[key]);
    const observables = fields.map(field => this.getFilterFieldOptions(FilterableFields[field]));

    return forkJoin(observables).pipe(
      map((values) =>
        values.map((options, index) => {
          const field = fields[index];
          return {
            key: field,
            label: this.translateService.instant(filterFieldLabelKey[field]),
            value: [],
            options: options,
            config: this.getFieldConfiguration(FilterableFields[field]),
          };
        })
      )
    ).subscribe((values) => {
      this.filterFieldOptions$.next(values);
    });
  }
}
