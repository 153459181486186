import { ReportColumnConfigs } from '../common-report-items/custom-reports.service';
import { ReportSection } from '../iris-reporting.model';

/**
 * This is the layout config for the protection runs report.
 */
export const protectionRunsReport: ReportSection[] = [
  {
    title: 'reporting.summary',
    items: [
      {
        id: 'statlistItem',
        componentIds: ['601'],
        componentNames: ['ProtectionRunsSummary'],
        params: {
          items: [
            {
              title: 'reporting.successRate',
              translate: true,
              showChart: true,
              measurement: {
                valueKey: 'countRunId',
                dataType: 'percent',
                filters: [
                  {
                    filterKey: 'status',
                    value: 'kSuccess',
                  },
                  {
                    filterKey: 'status',
                    value: 'kWarning',
                  },
                ],
                aggregation: 'percent',
              },
            },
            {
              title: 'reporting.totalRuns',
              translate: true,
              measurement: {
                valueKey: 'countRunId',
                dataType: 'number',
              }
            },
            {
              title: 'reporting.totalSuccessful',
              translate: true,
              measurement: {
                valueKey: 'countRunId',
                dataType: 'number',
                filters: [
                  {
                    filterKey: 'status',
                    value: 'kSuccess',
                  },
                  {
                    filterKey: 'status',
                    value: 'kWarning',
                  },
                ],
              },
            },
            {
              title: 'reporting.success',
              translate: true,
              measurement: {
                valueKey: 'countRunId',
                dataType: 'number',
                filter: {
                  filterKey: 'status',
                  value: 'kSuccess',
                },
              },
            },
            {
              title: 'reporting.warning',
              translate: true,
              measurement: {
                valueKey: 'countRunId',
                dataType: 'number',
                filter: {
                  filterKey: 'status',
                  value: 'kWarning',
                },
              },
            },
            {
              title: 'reporting.failed',
              translate: true,
              measurement: {
                valueKey: 'countRunId',
                dataType: 'number',
                filter: {
                  filterKey: 'status',
                  value: 'kFailure',
                },
              },
            },
            {
              title: 'reporting.canceled',
              translate: true,
              measurement: {
                valueKey: 'countRunId',
                dataType: 'number',
                filter: {
                  filterKey: 'status',
                  value: 'kCanceled',
                },
              },
            },
            {
              title: 'reporting.skipped',
              translate: true,
              measurement: {
                valueKey: 'countRunId',
                dataType: 'number',
                filter: {
                  filterKey: 'status',
                  value: 'kSkipped',
                },
              },
            },
            {
              title: 'reporting.running',
              translate: true,
              measurement: {
                valueKey: 'countRunId',
                dataType: 'number',
                filter: {
                  filterKey: 'status',
                  value: 'kRunning',
                },
              },
            },
          ],
        },
      },
    ],
  },
  {
    items: [
      {
        title: 'reporting.runStatusByPolicy',
        id: 'chartItem',
        componentIds: ['602'],
        componentNames: ['RunStatusByPolicy'],
        params: {
          chartType: 'column',
          measurements: [
            {
              valueKey: 'countRunId',
              dataType: 'number'
            },
          ],
          dimensions: [
            {
              dimensionKey: 'maxPolicyName',
              maxValues: 3,
            },
            {
              dimensionKey: 'status',
              maxValues: 3,
            },
          ],
        },
      },
      {
        title: 'reporting.runStatusByObjectType',
        id: 'chartItem',
        componentIds: ['603'],
        componentNames: ['RunStatusByObjectType'],
        params: {
          chartType: 'column',
          measurements: [
            {
              valueKey: 'countRunId',
              dataType: 'number'
            },
          ],
          dimensions: [
            {
              dimensionKey: 'environment',
              maxValues: 3,
            },
            {
              dimensionKey: 'status',
              maxValues: 3,
            },
          ],
        },
      },
    ],
  },
  {
    items: [
      {
        id: 'chartItem',
        componentNames: ['ProtectionRunStatusTrend'],
        title: 'reporting.runStatusTrend',
        params: {
          chartType: 'stacked',
          measurements: [
            {
              valueKey: 'countStatus',
              dataType: 'number'
            },
          ],
          dimensions: [
            {
              dataType: 'date',
              dimensionKey: 'runStartTimeUsecs',
            },
            {
              dimensionKey: 'status',
            },
          ],
        },
      },
    ],
  },
  {
    title: 'reporting.data',
    isFullData: true,
    items: [
      {
        id: 'tableItem',
        componentIds: ['600'],
        componentNames: ['AllProtectionRuns'],
        params: {
          columns: [
            {
              title: 'objectName',
              translate: true,
              key: 'objectName',
              icon: {
                key: 'objectType',
                dataType: 'objectTypeIcon',
                renderParams: {
                  environmentKey: 'environment',
                },
              },
              primary: {
                key: 'objectName',
                dataType: 'text',
                renderParams: {
                  width: 'lg',
                },
              },
              subtitle: {
                key: 'environment',
                dataType: 'environmentName',
              },
            },
            {
              title: 'policy',
              enabled: true,
              translate: 'true',
              key: 'policyName',
              primary: {
                key: 'policyName',
                dataType: 'text',
              },
              subtitle: {
                key: 'policyRetentionDays',
                dataType: 'text',
                renderParams: {
                  translate: true,
                  translateValue: 'reporting.retainValueD'
                }
              },
            },
            {
              title: 'startTime',
              translate: true,
              key: 'runStartTimeUsecs',
              icon: {
                key: 'status',
                dataType: 'statusIcon',
              },
              primary: {
                key: 'runStartTimeUsecs',
                dataType: 'date',
                renderParams: {
                  usecs: true,
                },
              },
              subtitle: {
                key: 'backupType',
                dataType: 'backupType',
              },
            },
            {
              title: 'endTime',
              translate: true,
              key: 'endTimeUsecs',
              primary: {
                key: 'endTimeUsecs',
                dataType: 'date',
                renderParams: {
                  usecs: true,
                },
              },
            },
            {
              title: 'duration',
              translate: true,
              key: 'durationUsecs',
              sortStart: 'desc',
              primary: {
                key: 'durationUsecs',
                dataType: 'duration',
                renderParams: {
                  usecs: true,
                },
              },
            },
            {
              title: 'protectionGroup',
              enabled: false,
              translate: true,
              key: 'groupName',
              primary: {
                key: 'groupName',
                dataType: 'text',
              },
            },
            {
              title: 'source',
              enabled: true,
              translate: 'true',
              key: 'sourceName',
              primary: {
                key: 'sourceName',
                dataType: 'text',
              },
            },
            {
              title: 'organization',
              enabled: false,
              translate: true,
              key: 'tenantName',
              primary: {
                key: 'tenantName',
                dataType: 'text',
                renderParams: {
                  emptyKey: 'naNotAvailable',
                },
              },
              featureFlag: 'ngReportSPView',
            },
            {
              title: 'system',
              enabled: true,
              translate: 'true',
              key: 'system',
              primary: {
                key: 'system',
                dataType: 'text',
              },
            },
            {
              title: 'reporting.snapshotStatus.wrapped',
              enabled: true,
              translate: true,
              key: 'snapshotStatus',
              primary: {
                key: 'snapshotStatus',
                dataType: 'enumType',
              },
            },
            {
              title: 'logicalData',
              enabled: false,
              translate: true,
              key: 'logicalSize',
              primary: {
                key: 'logicalSize',
                dataType: 'bytes',
              },
            },
            {
              title: 'sla',
              enabled: false,
              translate: true,
              key: 'slaStatus',
              primary: {
                key: 'slaStatus',
                dataType: 'enumType',
                renderParams: {
                  nowrap: true,
                },
              },
            },
            {
              title: 'dataRead',
              enabled: false,
              translate: true,
              key: 'dataRead',
              primary: {
                key: 'dataRead',
                dataType: 'bytes',
              },
            },
            {
              title: 'dataWritten',
              enabled: false,
              translate: true,
              key: 'dataWritten',
              primary: {
                key: 'dataWritten',
                dataType: 'bytes',
              },
            },
            {
              title: 'code',
              enabled: false,
              translate: true,
              featureFlag: 'messageCode',
              key: 'messageCode',
              primary: {
                key: 'messageCode',
                dataType: 'text',
              },
            }
          ],
        },
      },
    ],
  },
];


/**
 * This is metadata needed to determine available chart customizations.
 */
export const protectionRunsColumns: ReportColumnConfigs = {
  status: {
    aggs: [],
    canGroup: true,
    dataType: 'text',
  },
  runStartTimeUsecs: {
    aggs: [],
    dataType: 'date'
  },
  backupType: {
    aggs: [],
    canGroup: true,
    dataType: 'text',
  },
  policyId: {
    aggs: ['count'],
    dataType: 'uuid'
  },
  maxPolicyName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text'
  },
  objectName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  groupName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  runId: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  environment: {
    aggs: ['count'],
    canGroup: true,
    maxGroupingValues: 3,
    dataType: 'text',
  },
  sourceName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  sourceUuid: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  system: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  systemId: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  snapshotStatus: {
    aggs: ['count'],
    canGroup: true,
    dataType: 'text',
  },
  logicalSize: {
    aggs: ['sum', 'min', 'max', 'avg'],
    dataType: 'bytes',
  },
  dataRead: {
    aggs: ['sum', 'min', 'max', 'avg'],
    dataType: 'bytes',
  },
  dataWritten: {
    aggs: ['sum', 'min', 'max', 'avg'],
    dataType: 'bytes',
  },
};
