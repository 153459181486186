<cog-filters [showFilterLabel]="true" (valueChange)="applyFilters($event)">
  <cog-value-property-filter
    *cogFilterDef="
      let params = params;
      let filter;
      quickFilter: true;
      key: 'softwareVersion';
      label: 'dg.clusterConfig.softwareVerison' | translate;
      filterType: 'valueProperty'
    "
    [filterDefParams]="params"
    [filter]="filter"
    [allowMultiple]="true"
    [filterValues]="softwareVersionFilterValues"
    [inheritDataId]="true"
    cogDataId="software-version-filter">
  </cog-value-property-filter>
  <cog-value-property-filter
    *cogFilterDef="
      let params = params;
      let filter;
      quickFilter: true;
      key: 'location';
      label: 'dg.clusterConfig.location' | translate;
      filterType: 'valueProperty'
    "
    [filterDefParams]="params"
    [filter]="filter"
    [allowMultiple]="true"
    [filterValues]="locationFilterValues"
    [inheritDataId]="true"
    cogDataId="location-filter">
  </cog-value-property-filter>

  <cog-value-property-filter
    *cogFilterDef="
      let params = params;
      let filter;
      quickFilter: true;
      key: 'connectionStatus';
      label: 'dg.clusterConfig.connectionStatus' | translate;
      filterType: 'valueProperty'
    "
    [filterDefParams]="params"
    [filter]="filter"
    [allowMultiple]="true"
    [filterValues]="connectionFilterValues"
    [inheritDataId]="true"
    cogDataId="connectionStatus-status-filter">
  </cog-value-property-filter>
  <cog-value-property-filter
    *cogFilterDef="
      let params = params;
      let filter;
      quickFilter: true;
      key: 'datahawkAppStatus';
      label: 'dg.clusterConfig.datahawkEngineAppStatus' | translate;
      filterType: 'valueProperty'
    "
    [filterDefParams]="params"
    [filter]="filter"
    [allowMultiple]="true"
    [filterValues]="appStatusFilterValues"
    [inheritDataId]="true"
    cogDataId="datahawk-app-status-filter">
  </cog-value-property-filter>
  <cog-search-property-filter
    cogDataId="cluster-list-table-filter-by-scan-name"
    *cogFilterDef="
      let filter;
      key: 'clusterName';
      label: 'name' | translate;
      quickFilter: true;
      filterType: 'searchProperty'
    "
    [filter]="filter"
    [placeholder]="'dg.clusterConfig.clusterName' | translate">
  </cog-search-property-filter>
</cog-filters>
