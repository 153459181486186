import { Injectable } from '@angular/core';

import { Dimension, Dimensions, Measurement, Measurements } from '../iris-reporting.model';
import { reportDataTypes } from './report-data-types';

/**
 * x Values can be string or numbers - numbers are used for date axes.
 */
export type XValueType = string | number;

/**
 * This is the output after parsing the chart config and data. It can be
 * converted directly to highcharts series by each specific component.
 */
export interface ChartDataValues {
  /**
   * A map of series data by name
   */
  seriesData: Map<
    /**
     * The series name. If 0 or 1 dimensions are specified, this will be the label associated with the
     * measurements 'valueKey'. If a grouping dimension is used, the label will be based on the values
     * read from the data from the grouping key.
     */
    string,
    {
      /**
       * If set, this is a specific class name that will be applied to the chart's series.
       */
      className?: string;

      /**
       * The data type - they will all be numbers, but we can use this property to specify things like
       * percent, bytes, etc... It will always default to 'number'.
       */
      dataType: string;

      /**
       * The actual chart data for the series. For now at least, this is a simple number array, rather
       * than a more complex 'point' type.
       */
      data: number[];

      /**
       * Original key value without prefix.
       */
      originalKey?: string;
    }
  >;

  /**
   * xValue labels will be specified if there is at least one chart dimension. The number of x values
   * match the number of items in the data array for each series.
   */
  xValues?: XValueType[];

  /**
   * Original xValues without prefix.
   */
  originalXValues?: XValueType[];
}

/**
 * This is used while parsing data to track total values as each row is being calculated.
 * This is helpful for sorting by x value, and grouping into 'other'. This is usually stored
 * as a map of x value to totals.
 *
 * @example if you group on 'protectionStatus', and have two rows:
 * { objectType: 'vm', value: 10, protectionStatus: 'protected' }
 * { objecttype: 'vm', value: 2, protectionStatus: 'unprotected' }
 *
 * It should be represented in this structure with:
 * {
 *   total: 12,
 *   dataType: 'number',
 *   seriesValue: {
 *     protected: 10,
 *     unprotected: 2
 *   }
 * }
 */
interface SeriesTotals {
  /**
   * The total number associated with the x value. If there is grouping, this would be the sum
   * of all the group values for that type.
   */
  total: number;

  /**
   * The data type for the values - ie, bytes, number, etc...
   */
  dataType: string;

  /**
   * This is used only when a second dimension is used for grouping. It will contain totals based on
   * the value of the grouped property.
   */
  seriesValue?: { [name: string]: number };
}

/**
 * This services converts chart config and data into usable chart data.
 */
@Injectable({
  providedIn: 'root',
})
export class ChartReportsService {
  constructor() {}

  /**
   * Converts data value from a report into chart data that can then be converted into highcharts values.
   * This will use different methods for extracting data based on the measurements and dimensions specified.
   *
   * @example
   * data: [
   *   {x: 'one', value: 1, protectionStatus: 'protected'}
   *   {x: 'one', value: 1, protectionStatus: 'unprotected'}
   *   {x: 'two', value: 1, protectionStatus: 'protected'}
   *   {x: 'two', value: 1, protectionStatus: 'unprotected'}
   *   {x: 'three', value: 1, protectionStatus: 'protected'}
   *   {x: 'three', value: 1, protectionStatus: 'unprotected'}
   * ]
   * measurement.valueKey = 'value'
   * With No dimensions:
   * {
   *   value: {
   *     dataType: 'number',
   *     data: [6] // The sum of all numbers
   *   }
   * }
   *
   * With [{dimensionKey: 'x'}]
   * {
   *   value: {
   *     dataType: number,
   *     dataType: [2, 2, 2] // The total of each row with that x value
   *   },
   *   xValues: ['one', 'two', 'three']
   * }
   *
   * With [{dimensionKey: 'x'}, {dimensionKey: 'protectionStatus'}]
   * {
   *   protected: {
   *     dataType: number,
   *     dataType: [1, 1, 1] // Each of the protected rows
   *   },
   *   unprotected: {
   *     dataType: number,
   *     dataType: [1, 1, 1] // Each of the unprotected rows
   *   },
   *   xValues: ['one', 'two', 'three']
   * }
   *
   * @param dataValues    The raw table results from the report. This is an array of any, since we do not know
   *                      the property names ahead of time.
   * @param measurements  The measurements that will be graphed - the valueKey should _always_ map to a number
   *                      value in the data. Each measurement maps to one (or more, if a grouping
   *                      dimension is used) chart series. When multiple measurements are used, the series they
   *                      produce will be merged into a single map.
   * @param dimensions    The chart dimensions specify how data will be plotted. There are 3 main cases:
   *                      - If no dimensions are specified, the result should be a series with a single data point.
   *                        This can be used to calculate a single value from a given set of data.
   *                      - The first dimension specified will be used as an X axis. The xValues array will
   *                        be filled in for the result and the number of data points should match the number
   *                        of x values.
   *                      - A second dimension, if present will be used for grouping or aggregating data. Each
   *                        the values from the data will be used as the series name (rather than the value key).
   * @returns Chart data, split by series.
   */
  getChartData(dataValues: any[], measurements: Measurements, dimensions?: Dimensions): ChartDataValues {
    if (!dataValues || !measurements) {
      return { seriesData: new Map() };
    }

    const allSeriesData = [];
    measurements.forEach(metric => {
      switch ((dimensions || []).length) {
        case 0:
          allSeriesData.push(this.getZeroDimensionData(dataValues, metric));
          break;
        case 1:
          allSeriesData.push(this.getOneDimensionData(
            dataValues,
            metric,
            dimensions[0],

            // pass order of xValues from first sorted series so that the remaining metric can follow the same order
            allSeriesData.length ? allSeriesData[0].originalXValues : null,
          ));
          break;
        case 2:
          allSeriesData.push(this.getTwoDimensionData(dataValues, metric, dimensions[0], dimensions[1]));
          break;
        default:
          throw new Error('unsupported chart configuration');
      }
    });

    const combined = allSeriesData.reduce((combinedData, chartSeriesData) => {
      if (!combinedData) {
        return chartSeriesData;
      }
      chartSeriesData.seriesData.forEach((value, key) => combinedData.seriesData.set(key, value));
      return combinedData;
    }, null);
    return this.updateChartDataLabels(combined);
  }

  /**
   * Gets data for a single measurement with no dimensions
   *
   * @example
   * data: [
   *   {x: 'one', value: 1, protectionStatus: 'protected'}
   *   {x: 'one', value: 1, protectionStatus: 'unprotected'}
   *   {x: 'two', value: 1, protectionStatus: 'protected'}
   *   {x: 'two', value: 1, protectionStatus: 'unprotected'}
   *   {x: 'three', value: 1, protectionStatus: 'protected'}
   *   {x: 'three', value: 1, protectionStatus: 'unprotected'}
   * ]
   * measurement.valueKey = 'value'
   * With No dimensions:
   * {
   *   value: {
   *     dataType: 'number',
   *     data: [6] // The sum of all numbers
   *   }
   * }
   * @param dataValues    The raw table results from the report. This is an array of any, since we do not know
   *                      the property names ahead of time.
   * @param measurement   The measurement describing the value that will be calculated - the valueKey should _always_
   *                      map to a number value in the data.
   * @returns A single series with one value.
   */
  private getZeroDimensionData(dataValues: any[], measurement: Measurement): ChartDataValues {
    const seriesName = this.getDataLabel(measurement.valueKey);
    const seriesData = new Map<string, { data: number[]; dataType: string }>();

    // Aggs includes a running total of previous values with enough information to calculate the next value.
    const aggs = {};
    const total = dataValues.reduce((sum, row) => this.getMeasurementValue(measurement, row, aggs), 0);
    seriesData.set(seriesName, { data: [total], dataType: measurement.dataType || 'number' });

    return {
      seriesData,
    };
  }

  /**
   * Gets data for a single measurement with one dimension (x,y) data
   *
   * @example
   * data: [
   *   {x: 'one', value: 1, protectionStatus: 'protected'}
   *   {x: 'one', value: 1, protectionStatus: 'unprotected'}
   *   {x: 'two', value: 1, protectionStatus: 'protected'}
   *   {x: 'two', value: 1, protectionStatus: 'unprotected'}
   *   {x: 'three', value: 1, protectionStatus: 'protected'}
   *   {x: 'three', value: 1, protectionStatus: 'unprotected'}
   * ]
   * measurement.valueKey = 'value'
   * With [{dimensionKey: 'x'}]
   * {
   *   value: {
   *     dataType: number,
   *     dataType: [2, 2, 2] // The total of each row with that x value
   *   },
   *   xValues: ['one', 'two', 'three']
   * }
   * @param dataValues    The raw table results from the report. This is an array of any, since we do not know
   *                      the property names ahead of time.
   * @param measurement   The measurement describing the value that will be calculated - the valueKey should _always_
   *                      map to a number value in the data.
   * @param xAxis         The x axis dimension. The xValues array will be filled in for the result and the number
   *                      of data points should match the number of x values.
   * @param xValueOrder   If exist, follow the order of xValues in this parameter instead when sorting.
   * @returns Chart data, with one series
   */
  private getOneDimensionData(
    dataValues: any[],
    measurement: Measurement,
    xAxis: Dimension,
    xValueOrder?: XValueType[],
  ): ChartDataValues {
    const valueKey = measurement.valueKey;
    const xTotals = new Map<XValueType, SeriesTotals>();

    // Create a map of categories and their total values
    dataValues.forEach(row => {
      const groupValue = this.getDimensionValue(xAxis, row);
      const value = this.getMeasurementValue(measurement, row, {});
      if (groupValue) {
        if (!xTotals.has(groupValue)) {
          xTotals.set(groupValue, { total: 0, dataType: measurement.dataType || 'number' });
        }
        xTotals.get(groupValue).total += value;
      }
    });

    const [sortedCategories, remove] = this.consolidateSeriesTotals(xTotals, xAxis.maxValues, xValueOrder);
    const chartData = this.getChartDataFromTotals(xTotals, [valueKey], sortedCategories, remove);
    return this.updateChartDataLabels(chartData);
  }
  /**
   * Gets data for a single measurement with two dimensions (x,y + grouping)
   *
   * @example
   * data: [
   *   {x: 'one', value: 1, protectionStatus: 'protected'}
   *   {x: 'one', value: 1, protectionStatus: 'unprotected'}
   *   {x: 'two', value: 1, protectionStatus: 'protected'}
   *   {x: 'two', value: 1, protectionStatus: 'unprotected'}
   *   {x: 'three', value: 1, protectionStatus: 'protected'}
   *   {x: 'three', value: 1, protectionStatus: 'unprotected'}
   * ]
   * measurement.valueKey = 'value'
   * With [{dimensionKey: 'x'}, {dimensionKey: 'protectionStatus'}]
   * {
   *   protected: {
   *     dataType: number,
   *     dataType: [1, 1, 1] // Each of the protected rows
   *   },
   *   unprotected: {
   *     dataType: number,
   *     dataType: [1, 1, 1] // Each of the unprotected rows
   *   },
   *   xValues: ['one', 'two', 'three']
   * }
   *
   * @param dataValues    The raw table results from the report. This is an array of any, since we do not know
   *                      the property names ahead of time.
   * @param measurement   The measurement describing the value that will be calculated - the valueKey should _always_
   *                      map to a number value in the data.
   * @param xAxis         The x axis dimension. The xValues array will be filled in for the result and the number
   *                      of data points should match the number of x values.
   * @param grouping      This will be used for grouping or aggregating data. Each of the values from the data will
   *                      be used as the series name (rather than the value key).
   * @returns Chart data, split by series.
   */
  getTwoDimensionData(
    dataValues: any[],
    measurement: Measurement,
    xAxis: Dimension,
    grouping: Dimension
  ): ChartDataValues {
    const groupKey = grouping.dimensionKey;

    const xTotals = new Map<XValueType, SeriesTotals>();
    const seriessNames = new Set<string>();

    // Create a map of categories and their total values
    dataValues.forEach(row => {
      const xValue = this.getDimensionValue(xAxis, row);
      const value = this.getMeasurementValue(measurement, row, {});
      if (xValue) {
        if (!xTotals.has(xValue)) {
          xTotals.set(xValue, { total: 0, seriesValue: {}, dataType: measurement.dataType || 'number' });
        }
        const xTotal = xTotals.get(xValue);
        xTotal.total += value;

        const seriesKey = row[groupKey]?.toString();
        seriessNames.add(seriesKey);
        xTotal.seriesValue[seriesKey] = (xTotal.seriesValue[seriesKey] || 0) + (value || 0);
      }
    });

    const [sortedCategories, remove] = this.consolidateSeriesTotals(xTotals, xAxis.maxValues);
    return this.getChartDataFromTotals(xTotals, [...seriessNames.values()], sortedCategories, remove);
  }

  /**
   * Gets the dimension value from a row. If this is a date type, it will automatically convert from usecs to msecs.
   *
   * @param dimension The dimension config.
   * @param row The curent row.
   * @returns The dimension value.
   */
  private getDimensionValue(dimension: Dimension, row: any): number | string {
    const value = row[dimension.dimensionKey] || 0;
    if (value && dimension.dataType === 'date') {
      return value / 1000;
    }
    return value;
  }

  /**
   * Gets the measurement value from a row, based on a specified aggregation and filter value if present.
   *
   * @param measurement The measurement config.
   * @param row The current row.
   * @param valueAggreggations A running total of past values. This will be modified on each api call.
   * @returns The new measurement value.
   */
  private getMeasurementValue(
    measurement: Measurement,
    row: any,
    valueAggreggations: any
  ): number {
    const value = row[measurement.valueKey];
    const aggregation = measurement.aggregation || 'sum';
    const singleFilter = !measurement.filter || row[measurement.filter.filterKey] === measurement.filter.value;
    const multipleFilters = !measurement.filters || measurement.filters.some(f => row[f.filterKey] === f.value);
    const inFilter = singleFilter && multipleFilters;

    if (aggregation === 'count') {
      if (!valueAggreggations.count) {
        valueAggreggations.count = new Set<string | number>();
      }
      const seen: Set<string | number> = valueAggreggations.count;
      if (inFilter) {
        seen.add(value);
      }
      return seen.size;
    } else if (aggregation === 'percent') {
      if (!valueAggreggations.percent) {
        valueAggreggations.percent = {
          inFilter: 0,
          total: 0,
        };
      }
      const percentAggs = valueAggreggations.percent;
      percentAggs.inFilter += inFilter ? Number(value) : 0;
      percentAggs.total += Number(value);
      return percentAggs.inFilter / percentAggs.total;
    }

    // Default to sum aggregation
    valueAggreggations.sum = (valueAggreggations.sum || 0) + (inFilter ? Number(value) : 0);

    return valueAggreggations.sum;
  }

  /**
   * This looks up a label for a value based on a koown list and returns a translation key that can be used
   * for rendering.
   *
   * @param value The label value to look up, this could be either the valueKey, or a specific value from the data.
   * @returns A key for translating the data.
   */
  getDataLabel(value: string): string {
    const valueConfig = reportDataTypes[value];
    return valueConfig?.label || value;
  }

  /**
   * This looks up a classname for a value based on a koown list and returns it if it exists.
   *
   * @param value The label value to look up, this could be either the valueKey, or a specific value from the data.
   * @returns A classname to use for the value.
   */
  getDataClassName(value: string): string {
    return reportDataTypes[value]?.className;
  }

  /**
   * Given a totals object and a maxValues input, this will sort the xValues (based on their total), and
   * consoldiate any values past the maxvValues list into an 'other' group. If maxValues is 0 or not set,
   * this will not return or sort any values.
   *
   * For a date series (or any time the x values are numeric) this will sort on the dates in ascending
   * order.
   *
   * @param totals        The totals map grouped by x values
   * @param maxValues     Maxiumum number of values to show before consolidating to 'other'
   * @param xValueOrder   If exist, follow the order of xValues in this parameter instead when sorting.
   * @returns An array of two items, where the first is the sorted x values (that may have 'other'), and the
   *          second is an array of values that were removed from the array.
   */
  private consolidateSeriesTotals(
    totals: Map<XValueType, SeriesTotals>,
    maxValues: number,
    xValueOrder?: XValueType[],
  ): [XValueType[], string[]] {
    const keyType = typeof(totals.keys().next().value);
    if (maxValues && keyType === 'string') {
      // Sort the list of categories in decending order
      const sortedTotals = [...totals.entries()].sort((a, b) => {
        if (xValueOrder?.length) {
          const positionA = xValueOrder.indexOf(a[0]);
          const positionB = xValueOrder.indexOf(b[0]);

          // Handle xValues grouped in 'other' case, and therefore it cannot be found and place it later in the list
          // to be grouped.
          if (positionA < 0) {
            return 1;
          }
          if (positionB < 0) {
            return -1;
          }
          return positionA - positionB;
        }
        return b[1].total - a[1].total;
      }).map(([xValue]) => xValue);
      if (!maxValues || sortedTotals.length <= maxValues) {
        return [sortedTotals, []];
      }
      const remove = [...sortedTotals.splice(maxValues, sortedTotals.length - maxValues, 'other')] as string[];
      return [sortedTotals, remove];
    } else if (keyType === 'number') {
      const sortedTotals = [...totals.keys()].sort();
      return [sortedTotals, []];
    }

    return [null, null];
  }

  /**
   * Parse data into the actual chart values
   *
   * @param totals The totals map grouped by x values
   * @param seriesNames The names of all of the chart series
   * @param sortedXValues Optional list of sorted x values, if not set it this will use the totals x values
   * @param remove  Optiona list of items that need to be removed and consolidated in the results.
   * @returns Chart data grouped by series
   */
  private getChartDataFromTotals(
    totals: Map<XValueType, SeriesTotals>,
    seriesNames: string[],
    sortedXValues?: XValueType[],
    remove?: string[]
  ): ChartDataValues {
    // If there are sorted values, we will use those, otherwise, we'll use the keys from the total, which will
    // be retrieved in insertion order.
    const seriesData = new Map<string, { data: number[]; dataType: string }>();
    seriesNames.forEach(name => seriesData.set(name, { data: [], dataType: null }));
    const xValues = sortedXValues || [...totals.keys()];
    xValues.forEach(xValue => {
      seriesData.forEach((series, name) => {
        if (xValue !== 'other') {
          const xTotal = totals.get(xValue);
          series.dataType = xTotal?.dataType;
          if (xTotal?.seriesValue) {
            series.data.push(xTotal.seriesValue[name] || 0);
          } else {
            series.data.push(xTotal?.total || 0);
          }
        } else if (remove?.length) {
          const sum = remove.reduce((runningTotal: number, replace) => {
            const xTotal = totals.get(replace);
            const value = xTotal.seriesValue ? xTotal.seriesValue[name] : xTotal.total;
            return runningTotal + (value || 0);
          }, 0);
          series.data.push(sum);
        }
      });
    });

    return {
      seriesData,
      xValues,
    };
  }

  /**
   * Updates the series names and x values by looking up data labels from known values
   *
   * @param chartData Chart Data
   * @returns Chart Data with Labels
   */
  private updateChartDataLabels(chartData: ChartDataValues) {
    const { seriesData, xValues, originalXValues } = chartData;

    [...seriesData.keys()].forEach(key => {
      const values = seriesData.get(key);
      const className = this.getDataClassName(key);
      if (className) {
        values.className = className;
      }
      values.originalKey = key;
      seriesData.delete(key);
      seriesData.set(this.getDataLabel(key), values);
    });
    return {
      seriesData,
      xValues: xValues?.map(xValue => typeof(xValue) === 'string' ? this.getDataLabel(xValue) : xValue),
      originalXValues: originalXValues || xValues,
    };
  }
}
