/**
 * Specifies entity groups for Protection jobs.
 */
export const JOB_GROUPS = {
  // Leaf entities supporting physical block or file based jobs.
  // Physical jobs need to count Physical Servers (kHost & kHostGroup),
  // Clusters (kWindowsCluster, kOracleAPCluster, kOracleRACCluster)
  // because these entities can be backed up.
  //
  // NOTE: For both Veritas Cluster Server(VCS) & Oracle Active Passive
  // Cluster, the entity type is kOracleAPCluster.
  leafEntitiesForPhysicalJob: [
    'kHost',
    'kHostGroup',
    'kOracleAPCluster',
    'kOracleRACCluster',
    'kWindowsCluster',
    'kUnixCluster'
  ]

  // TODO(tauseef): Move other env_groups here while carefully putting/reusing
  // entity types within ./env.constants.ts.
};
