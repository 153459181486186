import { Injectable } from '@angular/core';
import { PrincipalsServiceApi, SessionUser } from '@cohesity/api/v1';
import { MFAServiceApi } from '@cohesity/api/v2';
import { IrisContextService } from '@cohesity/iris-core';
import { AjaxHandlerService } from '@cohesity/utils';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';

import { MFAType, UserMfaToken } from '../mfa/interfaces/mfa-verification';

@Injectable()
export class MfaService {

  /**
   * Holds the observable for the user mfa setup.
   */
  userMfaAccountInfo$ = new BehaviorSubject<UserMfaToken>(null);

  /**
   * Holds the observable for the token verified or not.
   */
  isTokenVerified$ = new BehaviorSubject<boolean>(false);

  /**
   * Holds the observable for the token verified or not.
   */
  isEmailTokenVerified$ = new BehaviorSubject<boolean>(false);

  /**
   * The authenticated user info.
   */
  authenticatedUser: SessionUser;

  /**
   * Is Token only based authentication.
   */
  get isTokenAuthentication(): boolean {
    const { user = {} } = this.irisContextService?.irisContext ?? {};
    return (user.mfaMethods || []).includes('totp');
  }

  /**
   * Is token setup done before authentication.
   */
  get isTokenAuthNeeded(): boolean {
    const { user = {} } = this.irisContextService?.irisContext ?? {};
    return this.isTokenAuthentication && !user.mfaInfo?.isTotpSetupDone;
  }

  /**
   * Is email only based authentication.
   */
  get isEmailAuthentication(): boolean {
    const { user = {} } = this.irisContextService?.irisContext ?? {};
    return (user.mfaMethods || []).includes('email');
  }

  /**
   * Is email setup done before authentication.
   */
  get isEmailAuthNeeded(): boolean {
    const { user = {} } = this.irisContextService?.irisContext ?? {};
    return this.isEmailAuthentication && !user.mfaInfo?.isEmailOtpSetupDone;
  }

  /**
   * Is Token and email based authentication.
   */
  get isHybridAuthentication(): boolean {
    return this.isTokenAuthentication && this.isEmailAuthentication;
  }

  /**
   * Is email and TOTP needs to be done together.
   */
  get isHybridAuthNeeded(): boolean {
    return this.isTokenAuthNeeded && this.isEmailAuthNeeded;
  }

  constructor(
    private mfaApi: MFAServiceApi,
    private principalService: PrincipalsServiceApi,
    private irisContextService: IrisContextService,
    private ajaxHandler: AjaxHandlerService,
  ) {
    this.irisContextService.irisContext$.pipe(
      filter(result => !!result.user)
    ).subscribe(
      data => this.userMfaAccountInfo$.next({
        userId: data.user.username,
        email: data.user.emailAddress,
      })
    );
  }

  /**
   * Returns the MFA setup information for the logged in user.
   */
  getUserMfaInfo() {
    const { user = {} } = this.irisContextService?.irisContext ?? {};
    this.mfaApi.CreateTotpKey({
      body: {}
    })
    .subscribe(
      data => {
        this.userMfaAccountInfo$.next({
          userId: user.username,
          email: user.emailAddress,
          ...data,
        });
      },
      err => this.ajaxHandler.errorMessage(err),
    );
  }

  /**
   * Verifies whether the MFA setup was successful.
   *
   * @param token The secret token used for verification
   */
  verify(token: string, type: MFAType): Observable<SessionUser> {
    return this.principalService.VerifyOtpCode({
      otpCode: token,
      otpType: type,
    })
    .pipe(
      catchError(err => {
        this.ajaxHandler.errorMessage(err);
        this.isTokenVerified$.next(false);
        return of(null);
      }),
      filter(result => !!result),
      tap(user => {
        this.authenticatedUser = user;

        if (type === MFAType.totp) {
          this.isTokenVerified$.next(true);
        } else {
          this.isEmailTokenVerified$.next(true);
        }
      }),
    );
  }

  /**
   * Clears the state of any previous observables.
   */
  clearState() {
    this.isTokenVerified$.next(false);
    this.isEmailTokenVerified$.next(false);
  }
}
