import { ActionCallbackFn, BANNER_PRIORITY, BannerStatus } from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, map } from 'rxjs/operators';

import { EntitlementKeys } from '../../../app-configs';
import {
  CommonInfo,
  daysRemaining,
  flagEnabled,
  getCommonEntitlementInfos,
  IrisContext,
  IrisContextService,
} from '../../../iris-context';
import { BaseBannerConfig } from '../base-banner.config';
import {
  BannerConfigReturnProperties,
  BannerExpirationType,
  CrmSubscriptionEntitlementData,
  mapApiStatusToBannerStatus,
} from './crm-entitlement.model';

export abstract class BaseEntitlementBannerConfig extends BaseBannerConfig {

  /**
   * This id must map to the key within the subscriptionInfo
   * returned from the $irisContext observable.
   *
   * user.subscriptionInfo[key];
   */
  abstract key: EntitlementKeys;

  /**
   * The scope function that must pass before this entitlement banner
   * is enabled.  The user should be in the right scope in order to
   * see the banner.  For instance, dmaas banners should be displayed
   * only in isDmaas() scope.
   */
  abstract isScope: (ctx: IrisContext) => boolean;

  /**
   * data relevant to subscription
   */
  defaultData: CrmSubscriptionEntitlementData = {
    showBanner: false,
    bannerType: BannerExpirationType.PreExpiration,
    bannerSeverity: 'info',
    trialExpired: false,
    isFreeTrial: false,
    appName: null,
  };

  /**
   * provides banner config as observable
   */
  bannerInfo$ = this.irisCtx.irisContext$.pipe(
    debounceTime(500),
    map(ctx => {
      const entitlements = getCommonEntitlementInfos(ctx, this.key) || [{}];
      const entitlementsData = entitlements.map(entitlement => ({
        ...this.defaultData,
        ...this.toSubsExpiryData(entitlement),
      }));

      return entitlementsData.map((data, index) => this.getBannerConfigProps(ctx, data, index));
    }),
  );

  getId: (ctx: IrisContext, data: CrmSubscriptionEntitlementData, index: number) => string = () => this.id;

  protected getBannerConfigProps(ctx: IrisContext, data: CrmSubscriptionEntitlementData, index: number) {
    return {
      id: this.getId(ctx, data, index),
      priority: this.getPriority(data),
      isVisible: this.allowBanner(ctx, data, index),
      status: this.getStatus(data),
      allowClose: this.allowClose(data),
      text: this.getText(ctx, data, index),
      closeCallback: this.closeCallback,
      actionText: this.getActionText(),
      actionCallback: this.getActionCallback(),
    } as BannerConfigReturnProperties;
  }

  /**
   * get relevant subscription expiry data out of given arguments
   *
   * @param ctx iris context for extracting subscription info
   * @returns relevant expiry info for corresponding service
   */
  protected toSubsExpiryData(subsData: CommonInfo): CrmSubscriptionEntitlementData {
    const bannerData = subsData?.banner;
    return {
      showBanner: bannerData?.showBanner,
      bannerType: <BannerExpirationType>bannerData?.bannerType,
      bannerSeverity: <BannerStatus>bannerData?.bannerSeverity,
      isFreeTrial: subsData?.isFreeTrial,
      trialExpired: bannerData?.bannerType === BannerExpirationType.Expired ? true : false,
      daysRemaining: daysRemaining(subsData?.endDate),
      appName: subsData?.productDisplayName,
    };
  }

  constructor(protected irisCtx: IrisContextService, protected translateService: TranslateService) {
    super();
  }

  /**
   * Indicates whether to show this banner or not
   *
   * @returns boolean
   */
  showBanner(data: CrmSubscriptionEntitlementData) {
    return data.showBanner;
  }

  /**
   * Provides a status value for the free trial banner.
   */
  getStatus(data: CrmSubscriptionEntitlementData) {
    return mapApiStatusToBannerStatus(data.bannerSeverity);
  }

  /**
   * If trial is expired, then priority is high.  Otherwise it is default
   *
   * @returns banner priority
   */
  getPriority(data: CrmSubscriptionEntitlementData) {
    return data.trialExpired ? BANNER_PRIORITY.HIGH : BANNER_PRIORITY.LOW;
  }

  /**
   * Make the banner persistent if the trial is expired.
   */
  allowClose(data: CrmSubscriptionEntitlementData): boolean {
    return !data.trialExpired;
  }

  getActionText() {
    return null;
  }

  getActionCallback() {
    return (() => {}) as ActionCallbackFn;
  }

  /**
   * Gets the translated text based on context
   *
   * @returns translated text
   */
  getText(ctx: IrisContext, data: CrmSubscriptionEntitlementData, index: number): string {
    let translateKey: string;
    if (data.isFreeTrial) {
      translateKey = data.trialExpired ? 'subscriptionBanner.freeTrialExpired' : 'subscriptionBanner.freeTrialActive';
    } else {
      switch (data.bannerType) {
        case BannerExpirationType.GracePeriod:
          translateKey = 'subscriptionBanner.expiredGracePeriod';
          break;
        case BannerExpirationType.Expired:
          translateKey = 'subscriptionBanner.expired';
          break;
        default:
          translateKey = 'subscriptionBanner.active';
      }
    }
    return this.translateService.instant(translateKey, {
      n: data.daysRemaining,
      appName: this.getAppName(ctx, data, index),
    });
  }

  /**
   * Returns the app name based on feature flag inspection
   *
   * @returns appName
   */
  getAppName(ctx: IrisContext, data: CrmSubscriptionEntitlementData, _index: number) {
    return data.appName;
  }

  /**
   *
   * @param ctx iris context
   * @returns true/false based on context
   */
  allowBanner(ctx: IrisContext, data: CrmSubscriptionEntitlementData, index: number) {
    return !this.isBannerAcknowledged(this.getId(ctx, data, index)) &&
      this.showBanner(data) &&
      flagEnabled(ctx, 'bannerNotificationCrmService') &&
      this.isScope(ctx);
  }

}
