<coh-parent-source-selector [label]="'source' | translate"
  [formControl]="formGroupControls.source"
  [readOnly]="readOnly"
  [addNewEnable]="true"
  [environments]="environments"
  [allowedEnvTypes]="environments"
  [selectedSourceId]="sourceId"
  [autoOpen]="false">
</coh-parent-source-selector>

<cog-banner *ngIf="isGCP" class="margin-top" status="info">
  <span>{{'recovery.gcp.cohesityAgentRequiredInfo' | translate}}</span>
</cog-banner>

<ng-content></ng-content>

<mat-form-field [class.read-only-form]="readOnly">
  <mat-label>{{'target' | translate}}</mat-label>
  <cog-spinner *ngIf="targetService.loading$ | async"
    [inline]="true"
    matPrefix
    size="xs">
  </cog-spinner>
  <cog-data-tree-select cohSourceTreeSelect
    [canSelectFn]="canSelectFn"
    [environment]="currentEnvironment"
    [sourceNodes]="(sourceNodes | async) || []"
    [additionalFilters]="targetFilters"
    [detailTemplate]="detail"
    [triggerTemplate]="detail"
    [formControl]="formGroupControls.target"
    (dataInitialized)="onDataInitialized($event)">
  </cog-data-tree-select>

  <ng-template #detail
    let-node>
    <span>
      <span class="source-icon {{node.data | sourceIcon}}"></span>
      {{node.name}}
      <cog-status-indicator *ngIf="node.asConnectionState?.hasConnectionStateProblem" type="error" size="sm">
      </cog-status-indicator>
      <cog-status-indicator
        *ngIf="node.protectionSource?.gcpProtectionSource?.type === 'kVirtualMachine' && !node.protectionSource?.gcpProtectionSource?.physicalSourceId"
        type="error"
        title="{{'recovery.gcp.cohesityAgentNotInstalledTooltip' | translate}}"
        size="sm">
      </cog-status-indicator>
      <coh-source-meta [node]="node.data"></coh-source-meta>
    </span>
  </ng-template>
  <mat-error *ngIf="formGroupErrors?.target?.required">
    {{ 'errors.required' | translate }}
  </mat-error>
</mat-form-field>
