// HTTP interceptor for setting salesforce account.
;(function(angular, undefined) {
  'use strict';

  angular.module('C.salesforceAccountInterceptor', [])
    .service('salesforceAccountInterceptor', salesforceAccountInterceptorFn)
    .config(salesforceAccountInterceptorConfigFn);

  function salesforceAccountInterceptorConfigFn($httpProvider) {
    $httpProvider.interceptors.push('salesforceAccountInterceptor');
  }

  function salesforceAccountInterceptorFn($injector, _) {
    var $rootScope = $injector.get('$rootScope');
    var localStorageService = $injector.get('localStorageService');
    var featureFlag = $injector.get('FEATURE_FLAGS');

    return {
      request: function requestFn(request) {
        var isSalesUser = _.get($rootScope, 'user.salesforceAccount.isSalesUser');
        var isSupportUser = _.get($rootScope, 'user.salesforceAccount.isSupportUser');
        var selectedAccount = _.get($rootScope, 'user.salesforceAccount.selectedAccount');

        if (request.url !== '/v2/mcm/accounts' &&
          (isSalesUser || isSupportUser)) {
          if (!selectedAccount) {
            selectedAccount = localStorageService.get('salesforceAccount');
            _.set($rootScope, 'user.salesforceAccount.selectedAccount', selectedAccount);
          }
          if (selectedAccount && selectedAccount.id) {
            request.headers['X-IMPERSONATE-ACCOUNT-ID'] = selectedAccount.id;
          }
        }

        return request;
      }
    };
  }

})(angular);
