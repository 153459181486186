// Component: cEntityTreePub

/**
 * NOTE: The built-in search functionality here does not work for actual nested
 * trees, like those in SQL jobs. It has been fixed in cEntityTree (private API)
 * because it's still used in the Run Now modal.
 *
 * If that functionality is ever needed in this public API version, port it over
 * from there (see listFilter function in c-entity-tree.js).
 */

;(function(angular, undefined) {
  'use strict';

  var modName = 'C.entityTree';
  var componentName = 'cEntityTreePub';
  var options = {
    bindings: {

      /**
       * Required protection Job setting.
       *
       * @type   {Object}   job    protection job setting
       */
      job: '=?',

      /**
       * Required tree to show which is a subset of jobTree.tree.
       *
       * @type   {Object[]}   tree    list of sources
       */
      tree: '=',

      /**
       * Optional provide whole tree reference to update node's ancestors when
       * it is removed.
       *
       * @type   {Object}   jobTree   The whole tree reference.
       */
      jobTree: '=?',

      /**
       * Optional options setting.
       *
       * @type   {Object}   options    custom options for entity tree
       */
      options: '=?',
    },
    controller: 'EntityTreePubCtrl',
    templateUrl: 'app/global/c-entity-tree/c-entity-tree-pub.html',
  };

  /**
   * default entity tree options
   *
   * @type  {Object}   defaultOptions
   * @type  {boolean}  defaultOptions.enableCheckbox        show checkbox
   * @type  {boolean}  defaultOptions.hideExcludedNodes     hide excluded Nodes
   * @type  {boolean}  defaultOptions.hideMetaData          hide meta data
   * @type  {boolean}  defaultOptions.isExcludedBranch      is excluded branch
   * @type  {boolean}  defaultOptions.isRootNode            is root node
   * @type  {boolean}  defaultOptions.showActionIcons       show action icons
   * @type  {boolean}  defaultOptions.showEntityTreeHeader  show Tree Header
   */
  var defaultOptions = {
    enableCheckbox: false,
    hideExcludedNodes: false,
    hideMetaData: false,
    isExcludedBranch: false,
    isRootNode: true,
    showActionIcons: true,
    showEntityTreeHeader: false,
  };

  angular
    .module(modName)
    .controller('EntityTreePubCtrl', cEntityTreePubControllerFn)
    .component(componentName, options);

  /**
   * @ngdoc component
   * @name C.entityTree:cEntityTree
   * @function
   *
   * @description
   * Recursive component to display a tree of entities
   *
   * @example
     <c-entity-tree-pub
      tree="$ctrl.node"
      job="$ctrl.job"
      options="treeOpts"></c-entity-tree-pub>
   */
  function cEntityTreePubControllerFn() {
    var $ctrl = this;

    angular.extend($ctrl, {
      $onInit: $onInit,
      shouldDisplayNode: shouldDisplayNode,
      isRootWithLeavesOnly: isRootWithLeavesOnly,
    });

    /**
     * Initialiation function
     *
     * @method   $onInit
     */
    function $onInit() {
      $ctrl.job = $ctrl.job || {};
      $ctrl.options = angular.merge({}, defaultOptions, ($ctrl.options || {}));
      $ctrl.childOptions =
        angular.merge({}, $ctrl.options, { isRootNode: false });
    }

    /**
     * indicates if a particular node should be displayed based on an evaluation
     * of the provided options and the node properties
     *
     * @param      {object}   node    The node
     * @return     {boolean}  true if the node should be displayed, false if not
     */
    function shouldDisplayNode(node) {

      // Active Directory nodes should always be displayed in the tree.
      if (node._environment === 'kAD') {
        return true;
      }
      if ($ctrl.options.hideExcludedNodes) {
        return node._isSelected;
      }
      if ($ctrl.options.isExcludedBranch) {
        return !node._isSelected;
      }
      return true;
    }

    /**
     * Indicates if this is the root level and only leaves (or non-leaves with
     * no children) are present. This is leveraged to conditionally provide
     * padding for the node expand/collapse arrow.
     * TODO: Explore CSS-only solutions and try to get rid of the need for this
     * heavy JavaScript check that's only used for visual styling.
     *
     * @method   isRootWithLeavesOnly
     * @return   {boolean}   True if root with leaves only, False otherwise.
     */
    function isRootWithLeavesOnly() {
      return !!$ctrl.options.isRootNode &&
        !$ctrl.tree.some(function findNonLeaf(node) {
          // Active directory hosts are configured as leaf nodes, but they
          // still need to show their children in the entity tree. Returning
          // true for active directory hosts here ensures that the child
          // will be displayed and that the node padding will be properly
          // set.
          if (node._isActiveDirectoryHost) {
            return true;
          }
          // Only nodes that are to be displayed are relevant.
          return shouldDisplayNode(node) &&
            !node._isLeaf && !!(node.nodes || []).length;
        }
      );
    }

  }

})(angular);
