import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { VirtualDiskConfig } from '@cohesity/api/v1';
import { DataTreeNodeContext, DataTreeNodeDetail } from '@cohesity/helix';
import { AcropolisDiskFilterType } from 'src/app/shared/constants';

import { VmSourceDataNode } from '../vm-source-data-node';

@Component({
  selector: 'coh-acropolis-options',
  templateUrl: './acropolis-options.component.html',
  styleUrls: ['./acropolis-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcropolisOptionsComponent implements DataTreeNodeDetail<VmSourceDataNode>, OnInit {
  /**
   * The node context, including info about the node and it's selection status.
   */
  @Input() nodeContext: DataTreeNodeContext<VmSourceDataNode>;

  /**
   * Gets the node from the nodeContext.
   */
  get node(): VmSourceDataNode {
    return this.nodeContext.node;
  }

  /**
   * Gets the current options for the node. This either gets them from the selection options, or
   * the default params for the node.
   */
  get currentOptions() {
    return this.nodeContext.selection.getOptionsForNode(this.node.id) || {};
  }

  /**
   * Gets the list of excluded disks, if any, from the special parameters.
   */
  get excludeDisks() {
    return (this.currentOptions.vmSpecialParameters || {}).excludeDisks;
  }

  /**
   * Gets the list of included disks, if any, from the special parameters.
   */
  get includeDisks() {
    return (this.currentOptions.vmSpecialParameters || {}).includeDisks;
  }

  /**
   * Virtual disks that can be excluded.
   */
  virtualDisks: VirtualDiskConfig[];

  /**
   * The form to contain the options.
   */
  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    // Holds the available virtual disks that can be excluded or included.
    this.virtualDisks = ((this.node.virtualDisks as VirtualDiskConfig[] || [])
      .map((disk: VirtualDiskConfig) => ({...disk, deviceBus: disk.deviceBus?.toLowerCase()})));
  }

  /**
   * Updates the form based on the current options setting in preparation for displaying the form
   * dialog.
   */
  updateForm() {
    // Map the excluded/included disk list from the job selection to the actual disks options
    // that are available for the host.
    let filterType = AcropolisDiskFilterType.Include;

    if (this.excludeDisks?.length) {
      filterType = AcropolisDiskFilterType.Exclude;
    }

    const selectedDisks =
      filterType === AcropolisDiskFilterType.Include ? this.includeDisks : this.excludeDisks;

    const diskOptions = (selectedDisks || []).map(selectedDisk => {
      const {controllerType, unitNumber} = selectedDisk;

      return this.virtualDisks.find(disk =>
        disk.deviceBus === controllerType &&
        disk.deviceIndex === unitNumber);
      }).filter(Boolean);

    this.form = this.fb.group({
      enableDiskFilter: [!!diskOptions.length, Validators.required],
      type: [filterType, Validators.required],
      disks: [diskOptions],
    });
  }

  /**
   * Updates the selection options after the form has been saved and the dialog has been closed.
   */
  onSaved() {
    const { type, disks } = this.form.value;
    const options = {
      sourceId: this.node.id,
      vmSpecialParameters: {
        excludeDisks: type === AcropolisDiskFilterType.Exclude && disks.length ?
          disks.map(disk => ({
            controllerType: disk.deviceBus,
            unitNumber: disk.deviceIndex,
          })) :
          undefined,
        includeDisks: type === AcropolisDiskFilterType.Include && disks.length ?
          disks.map(disk => ({
            controllerType: disk.deviceBus,
            unitNumber: disk.deviceIndex,
          })) :
          undefined,
      },
    };
    this.nodeContext.selection.setOptionsForNode(this.node.id, options);
    this.cdr.detectChanges();
  }

  /**
   * Triggers on toggling diskInclusionExclusion.
   * If the toggle is set to off, reset excludeDisks.
   */
  toggleDisks() {
    if (!this.form.value.enableDiskFilter) {
      this.form.get('disks').setValue([]);
    }
  }

  /**
   * Triggers on toggling diskInclusionExclusion type.
   * Reset the include/exclude disk selection.
   */
  toggleType() {
    this.form.get('disks').setValue([]);
  }

  /**
   * Remove a certain disk from the selected disks.
   *
   * @params  diskToRemove  The disk to be removed.
   */
  removeDisk(diskToRemove: VirtualDiskConfig) {
    const disks = this.form.get('disks').value;
    this.form.get('disks').setValue(disks.filter(disk => disk !== diskToRemove));
  }
}
