<mat-card-title>{{'dataStored' | translate}}</mat-card-title>
<dg-sc-rpaas-storage-class-usage *ngIf="showWarmSubscription"
  [data]="warmStorageData$ | async"
  color="warm"
  (gotoReport)="gotoReport.emit()">
</dg-sc-rpaas-storage-class-usage>
<dg-sc-rpaas-storage-class-usage *ngIf="showColdSubscription"
  [data]="coldStorageData$ | async"
  color="cold"
  (gotoReport)="gotoReport.emit()">
</dg-sc-rpaas-storage-class-usage>
