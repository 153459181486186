// Component: Instance list

;(function(angular, undefined) {
  'use strict';

  angular.module('C.appsManagement')
    .component('instanceList', {
      templateUrl: 'app/apps-management/instance-list/instance-list.html',
      controller: instanceListCtrlFn,
      bindings: {
        // @type   {array}   List of instances.
        instances: '<',

        // @type   {boolean}   Whether to show loading animation.
        loading: '<',

        // @type   {boolean}   Show instances for single app.
        singleAppMode: '<',
      },
    });

  /**
   * @ngdoc component
   * @name C.appsManagement:instanceList
   * @function instanceListCtrlFn
   *
   * @description
   * Provides list of instances and related functionality.
   *
   * @example
     <instance-list
       instances="$ctrl.instances"
       loading="$ctrl.loading">
     </instance-list>
   */
  function instanceListCtrlFn(_) {
    var $ctrl = this;

    _.assign($ctrl, {
      filteredInstances: [],

      // Maintains the local state of the row such as isExpanded
      // data structure is {appInstanceId: {isExpanded: false}}
      rowState: {},

      filterOptions: {
        appNames: [],
        instanceTypes: ['activeInstances', 'terminatedInstances', 'allInstances'],
      },
      filter: {
        appName: 'allApps',
        instanceType: 'activeInstances'
      },

      filterInstances: filterInstances,
      toggleExpandedRow: toggleExpandedRow,
      getExpandedRow: getExpandedRow,

      // Component lifecycle methods
      $onChanges: $onChanges,
    });

    /**
     * Handle changes to instances
     *
     * @method    $onChanges
     * @param     {object}   changes   angularjs component change object
     */
    function $onChanges(changes) {
      if(changes.instances) {
        // Update filter options
        _setFilters(changes.instances.currentValue);

        // Filter
        filterInstances();
      }
    }

    /**
     * Filter list of instances.
     *
     * @method    filterInstances
     */
    function filterInstances() {
      var instances = [];

      // Filter by appName
      instances = _.filter($ctrl.instances, function appNameFilter(i) {
        return $ctrl.filter.appName === 'allApps' ||
          $ctrl.filter.appName === i.appName;
      });

      // Filter by instanceType
      instances = _.filter(instances, function instanceTypeFilter(instance) {
        switch($ctrl.filter.instanceType) {
          case 'allInstances':
            return true;
          case 'terminatedInstances':
            return instance.state === 'kTerminated';
          case 'activeInstances':
            return instance._isActive;
        }
        return false;
      });

      $ctrl.filteredInstances = instances;
    }

    /**
     * toggles current value of row expansion.
     *
     * @method    toggleExpandedRow
     * @param     {object}    appInstanceId   id of the instance
     */
    function toggleExpandedRow(appInstanceId) {
      var current = $ctrl.getExpandedRow(appInstanceId);
      _.set($ctrl.rowState, [appInstanceId, 'isExpanded'], !current);
    }

    /**
     * Get the current value of row expansion
     *
     * @method    getExpandedRow
     * @returns   {boolean}   True if expanded else false
     */
    function getExpandedRow(appInstanceId) {
      return _.get($ctrl.rowState, [appInstanceId, 'isExpanded'], false);
    }

    /**
     * Initiates app instance termination.
     *
     * @method    _setFilters
     * @param     {object}   instances   list of instances
     */
    function _setFilters(instances) {
      $ctrl.filterOptions.appNames = _.chain(instances)
        .map('appName').uniq().value();

      $ctrl.filterOptions.appNames.unshift('allApps');
    }
  }
})(angular);
