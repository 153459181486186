import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { ProtectionPolicyResponse, ProtectionPolicyResponseWithPagination } from '@cohesity/api/v2';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { ItemPickerFormControl } from '@cohesity/shared-forms';
import { AjaxHandlerService } from '@cohesity/utils';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, finalize, map, startWith } from 'rxjs/operators';
import { ClusterService, DialogService, UserService } from 'src/app/core/services';
import { PolicyUtils } from 'src/app/modules/policy-shared/protection-policy-utils';
import { CloudDeploySources, Environment } from 'src/app/shared/constants';
import { ProtectionPolicyService } from 'src/app/shared/policy';

/**
 * This component implements policy selector drop down. It fetches policies$ from
 * protection policies$ service api and emits policySelectChange event once a
 * policy is selected. It has create policy button which opens up policy builder
 * component in full page dialog. Upon closing the dialog, it auto-selects
 * the policy created by policy builder component and adds it to policies$ list.
 *
 * @example
 * <coh-policy-select defaultPolicyId="123456">
 * </coh-policy-select>
 */
@Component({
  selector: 'coh-policy-select',
  templateUrl: './policy-select.component.html',
  styleUrls: ['./policy-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PolicySelectComponent),
      multi: true,
    },
  ],
})
export class PolicySelectComponent extends ItemPickerFormControl<ProtectionPolicyResponse>
  implements OnInit, OnChanges {
  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private clusterInfo: ClusterService,
    private policyService: ProtectionPolicyService,
    private dialogService: DialogService,
    private irisCtx: IrisContextService,
    private userService: UserService
  ) {
    super();
  }

  /**
   * Whether an API call is pending right now.
   */
  loading = false;

  /**
   * Indicates if the policy can be edited.
   */
  get canEditPolicy(): boolean {
    return this.allowEdit && this.hasPolicyModifyPriv && !!this.value
      && !PolicyUtils.isCascadedReplicationPolicy(this.value);
  }

  /**
   * Indicates if the user can create a policy.
   */
  get canCreatePolicy(): boolean {
    return this.allowCreate && this.hasPolicyModifyPriv;
  }

  /**
   * Indicates if the current user has appropriate privs to modify policies$.
   */
  get hasPolicyModifyPriv(): boolean {
    return Boolean(this.userService.user && this.userService.user.privs.PROTECTION_POLICY_MODIFY);
  }

  /**
   * Return true if cluster is NGCE.
   */
  get cloudEditionEnabled(): boolean {
    return this.clusterInfo.isClusterNGCE;
  }

  /**
   * Specify a default policy id to select from policies$ list.
   */
  @Input() defaultPolicyId: string;

  /**
   * Indicates whether the user can create new policies.
   */
  @Input() allowCreate = true;

  /**
   * Indicates if the user should generally be allowed to edit the selected Policy.
   * NOTE: The option won't be displayed if user does not have appropriate privs.
   */
  @Input() allowEdit = false;

  /**
   * Indicates if the policy selection should be disabled.
   * Differs from 'allowEdit' above in the way that if allowEdit is false, the editing
   * of selected policy will not be possible. A different policy can however be selected
   * from the dropdown.
   * This, on the other hand, if set, will disable even the dropdown.
   */
  @Input() readOnly = false;

  /**
   * Float label passed on to determine mat-form-field behavior.
   */
  @Input() floatLabel = 'always';

  /**
   * Environment of source selected for protection with this policy.
   * This will only be available if policy builder is invoked via the protection
   * group flow.
   */
  @Input() environment: Environment;

  /**
   * Should an invalid policy be filtered out of the list
   */
  @Input() filterInvalidPolicy = false;

  /**
   * Optional. If enabled, policy selector will have searching.
   */
  @Input() allowSearch = true;

  /**
   * Optional. If enabled, policy selector have 'None' option.
   */
  @Input() allowUnselect = false;

  /**
   * If this is specified, the API calls are considered as pass-through to
   * this cluster. This is essentially useful in MCM mode.
   */
  @Input() accessClusterId: number;

  /**
   * Sub title text for policy.
   */
  @Input() subTitle: string;

  /**
   * Validation for policy select.
   */
  @Input() validationRequired = true;

  /**
   * Emits event when getPolicies completes.
   */
  @Output() policiesLoaded = new EventEmitter();

  /**
   * Emits event when policy is selected.
   */
  @Output() selectedPolicyData = new EventEmitter();

  /**
   * List of all filtered policies$
   */
  filteredPolicies$: Observable<ProtectionPolicyResponse[]>;

  /**
   * List of all policies$ fetched
   */
  policies$ = new BehaviorSubject<ProtectionPolicyResponse[]>([]);

  /**
   * Observable of filterInvalidPolicy input.
   */
  private filterInvalidPolicy$ = new BehaviorSubject<boolean>(false);

  /**
   * Observable of search string.
   */
  private search$: Observable<string>;

  /**
   * Internal FormControl for managing values and validity.
   */
  policyControl = new UntypedFormControl();

  /**
   * Form Control for searching the values.
   */
  searchCtrl = new UntypedFormControl();

  /**
   * Function callback to determine whether a policy should be disallowed for selection
   */
  @Input() isInvalidPolicy: (policy: ProtectionPolicyResponse) => boolean = () => false;

  /**
   * Init function.
   */
  ngOnInit() {
    // Update the validators for the controls.
    if (this.validationRequired) {
      this.policyControl.setValidators((Validators.required));
    } else {
      this.policyControl.clearValidators();
    }
    this.policyControl.updateValueAndValidity();

    this.getPolicies();
    this.search$ = this.searchCtrl ?
      this.searchCtrl.valueChanges.pipe(startWith('')) :
      of('');

    this.filteredPolicies$ = combineLatest([
      this.policies$.pipe(map(policies => this.sortPolicies(policies))),
      this.filterInvalidPolicy$,
      this.search$,
    ]).pipe(
      map(([allPolicies, filterInvalidPolicy, search]) => {
        const policies = filterInvalidPolicy ?
          allPolicies.filter(policy => !this.isPolicyInvalid(policy)) :
          allPolicies;

        if (!search) {
          return policies;
        }

        const searchStringRegex = new RegExp(search, 'i');

        return policies.filter(
          (value) => value.name.search(searchStringRegex) > -1
        );
      }));
  }

  /**
   * Update policy list on change.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.filterInvalidPolicy) {
      this.filterInvalidPolicy$.next(this.filterInvalidPolicy);

      // Reset policy control value if the currently selected policy is invalid
      // This will propagate to parent component.
      if (this.isPolicyInvalid(this.policyControl.value)) {
        this.policyControl.setValue(undefined);
      }
    }

    if (changes.defaultPolicyId && this.defaultPolicyId) {
      this.getPolicies();
    }

    if (this.readOnly && this.policyControl.enabled) {
      this.policyControl.disable();
    } else if (!this.readOnly && this.policyControl.disabled) {
      this.policyControl.enable();
    }
  }

  /**
   * Allows editing the currently selected policy via dialogService.
   */
  editPolicy() {
    const showDialogFn = PolicyUtils.isProtectOncePolicy(this.value) ?
      this.dialogService.showDialog('protect-once-policy-dialog', this.value) :
      this.dialogService.showDialog('policy-builder-dialog',
        { id: this.value.id },
        { minWidth: '100vw', height: '100vh' });

    showDialogFn.pipe(
      filter(Boolean),
      this.untilDestroy()
    ).subscribe((policyResult: ProtectionPolicyResponse) => {
      if (policyResult) {
        const newPolicies = [...this.policies$.value];
        const policyIndex = newPolicies.findIndex(policy => policy.id === policyResult.id);

        // Replace the policy in the list with the updated version, and set
        // the updated version as the selected policy.
        newPolicies.splice(policyIndex, 1, policyResult);
        this.policies$.next(newPolicies);
        this.value = policyResult;
        this.policyControl.setValue(policyResult);
      }
    });
  }

  /**
   * Gets the Policies and sets component's policies$ object
   *
   * @method   getPolicies
   */
  getPolicies() {
    // Subscribe to FormControl value changes and update the Value Accessor accordingly.
    this.policyControl.valueChanges.pipe(this.untilDestroy()).subscribe((policy: ProtectionPolicyResponse) => {
      if (!policy) {
        this.policyControl.reset(undefined, {emitEvent: false});
        this.selectedPolicyData.emit(null);
      } else {
        this.value = policy;
        this.selectedPolicyData.emit(policy);
      }
    });

    // Whether to show ProtectOnce policy.
    const showProtectOncePolicy = flagEnabled(this.irisCtx.irisContext, 'ngPolicyProtectOnce');

    this.loading = true;
    this.policyService.getPolicies(this.accessClusterId)
      .pipe(
        this.untilDestroy(),
        map((policiesResp: ProtectionPolicyResponseWithPagination) => {
          let policies = policiesResp?.policies ?? [];
          if (!showProtectOncePolicy) {
            policies = policies.filter(policy => !PolicyUtils.isProtectOncePolicy(policy));
          }
          return policies;
        }),
        finalize(() => {
          this.loading = false;
          this.policiesLoaded.next();
        }),
      )
      .subscribe((policies: ProtectionPolicyResponse[]) => {
        this.policies$.next(policies);

        if (this.defaultPolicyId) {
          // if a defaultPolicyId was provided, find it in the list of policies$
          // and assign it to the internal model
          const defaultPolicy = policies.find(policy => policy.id === this.defaultPolicyId);
          this.policyControl.setValue(defaultPolicy);
        } else if (this.autoOpen && !this.readOnly) {
          this.matSelect.open();
        }
      },
      error => this.ajaxHandlerService.handler(error));
  }

  /**
   * Handle adding new policy - opens dialog to create new policy,
   * gets policy object after closing and auto-selects the created policy
   *
   * @method   addNewPolicy
   */
  addNewPolicy() {
    this.dialogService.showDialog(
      'policy-builder-dialog',
      {hideCloudSpin: !CloudDeploySources.includes(this.environment)},
      {
        minWidth: '100vw',
        height: '100vh',
      }
    ).pipe(
      filter(value => Boolean(value)),
      this.untilDestroy()
    )
    .subscribe((policyResult: ProtectionPolicyResponse) => {
      if (policyResult && !this.isPolicyInvalid(policyResult)) {
        this.policies$.next([...this.policies$.value, policyResult]);

        this.value = policyResult;
        this.policyControl.setValue(this.value);
      }
    });
  }

  /**
   * Check whether the given policy is a valid policy
   *
   * @param   policy  The policy object to be chwcked
   * @returns True if policy is invalid. False otherwise
   */
  private isPolicyInvalid(policy: ProtectionPolicyResponse): boolean {
    return this.isInvalidPolicy(policy) ||

      // Invalidate a CAD policy for a non CAD supported environment
      (PolicyUtils.isCadPolicy(policy) && !PolicyUtils.cadPolicyEnvironments.includes(this.environment)
        && !this.cloudEditionEnabled);
  }

  /**
   * Sorts policies by name.
   *
   * @param    policies   List of unsorted policies.
   * @returns  List of policies sorted by policy name.
   */
  private sortPolicies(policies: ProtectionPolicyResponse[] = []): ProtectionPolicyResponse[] {
    return policies.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
  }

  /**
   * Determine if the policy has datalock settings.
   */
  isDataLockPolicy(policy) {
    return PolicyUtils.isDataLockPolicy(policy);
  }
}
