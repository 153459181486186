import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { McmClusterConnectionStatus } from '@cohesity/api/private';
import { KeyedSelectionModel } from '@cohesity/helix';

/**
 * Params to send to the cluster select dialog
 */
export interface ClusterSelectorComponentParams {
  /**
   * Main title for the dialog
   */
  title: string;

  /**
   * Optional subtitle for the dialog
   */
  subtitle?: string;

  /**
   * Available clusters to choose from - clusters that are not connected to helios will be show, but disabled.
   */
  availableClusters: McmClusterConnectionStatus[];
}

/**
 * This displays a list of clusters to select from when initiating a recovery flow from a non-cluster
 * context.
 */
@Component({
  selector: 'coh-cluster-selector',
  templateUrl: './cluster-selector.component.html',
  styleUrls: ['./cluster-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClusterSelectorComponent {

  /**
   * Selection model containing the selected cluster
   */
  selection = new KeyedSelectionModel<McmClusterConnectionStatus>(cluster => cluster.clusterId, false);

  /**
   * Flag indicating that there are no available clusters to choose from
   */
  noClustersAvailable = false;

  /**
   * Can select function to pass to cog table to determine whether a cluster can be selected
   */
  canSelect = (cluster => cluster.connectedToCluster);

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: ClusterSelectorComponentParams
  ) {
    const selectCluster = data.availableClusters?.find(this.canSelect);
    this.noClustersAvailable = !selectCluster;
    if (selectCluster) {
      this.selection.select(selectCluster);
    }
  }
}
