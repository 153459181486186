import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators, ValidatorFn } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { Environment, HostType } from 'src/app/shared/constants';
import { REGEX_FORMATS } from 'src/app/shared/constants/formats.constants';
import { convertToHostSpecificPath, convertToUnixPath } from 'src/app/util';

import { GlobalExclusions, ProtectionItemName } from '../../../models';
import { BaseProtectionBuilderComponent } from '../../base-protection-builder/base-protection-builder.component';
import { ModifyProtectionService } from '../../../services/modify-protection.service';

@Component({
  selector: 'coh-settings-global-exclude',
  templateUrl: './settings-list-global-exclude-paths.component.html',
})
export class SettingsListGlobalExcludePathsComponent
  extends BaseProtectionBuilderComponent<GlobalExclusions, any> implements OnInit {

  /**
   * Default value for exclude paths.
   */
  _value: GlobalExclusions = {
    paths: []
  };

  /**
   * Boolean to convert paths to windows if windows sources are selected.
   */
  showWindowsPath = false;

  /**
   * Global exclude form group to be attached to the settings list form group.
   */
  globalExcludeFormGroup = new UntypedFormGroup({
    paths: new UntypedFormArray([])
  });

  constructor(private modifyProtectionService: ModifyProtectionService) {
    super();
  }

  /**
   * Get the array of path form controls from within global exclude form group.
   *
   * @return   FormArray of path controls.
   */
  get globalExcludePathsControl(): UntypedFormArray {
    return this.globalExcludeFormGroup.get('paths') as UntypedFormArray;
  }

  /**
   * Return validators for exclude path.
   */
  get excludePathValidators(): ValidatorFn[] {
    if (this.showWindowsPath) {
      return [
        Validators.required,
        Validators.pattern(REGEX_FORMATS.windowsExcludeFilePathRegex)
      ];
    }

    return [
      Validators.required,
    ];
  }

  ngOnInit() {
    // Determine if there are any selected ndoes
    this.modifyProtectionService.onControlChange(ProtectionItemName.Objects).pipe(
      filter(value => !!value.sourceTree)
    ).subscribe(value => {
      const environment = value.sourceTree.environment;
      if (environment === Environment.kPhysicalFiles) {
        // Add the form control if its a windows host
        this.formGroup.addControl(this.name, this.globalExcludeFormGroup);
      } else {
        // Otherwise remove the form control
        this.formGroup.removeControl(this.name);
      }

      const oldShowWindowsPath = this.showWindowsPath;
      this.showWindowsPath = value.sourceTree.selectedHostType === HostType.kWindows;
      if (this.showWindowsPath !== oldShowWindowsPath) {
        this.repopulateForm();
      }
    });
  }

  /**
   * Called when host type is changed from windows to unix or vice-versa.
   * This function recreates formgroup with proper validators.
   */
  repopulateForm() {
    const form = this.globalExcludeFormGroup.value as GlobalExclusions;
    const newPaths: UntypedFormArray = new UntypedFormArray([]);

    for (const path of form.paths) {
      newPaths.push(
        new UntypedFormControl(
          this.showWindowsPath ?
            convertToHostSpecificPath(path, true) :
            convertToUnixPath(path, true), this.excludePathValidators
        )
      );
    }
    this.globalExcludeFormGroup.setControl('paths', newPaths);
  }

  /**
   * Function to add a new item to the include or exclude paths array.
   *
   * @param   value   Default value for the path
   */
  addPathFormControl(value = '') {
    (this.globalExcludeFormGroup.get('paths') as UntypedFormArray).push(
      new UntypedFormControl(value, this.excludePathValidators)
    );
  }

  /**
   * Function to remove a form control item from the exclude form
   * controls array.
   *
   * @param   index   Index of the item to be removed.
   */
  removePathFormControl(index) {
    (this.globalExcludeFormGroup.get('paths') as UntypedFormArray).removeAt(index);
  }

  /**
   * Add the globalExcludeFormGroup as control.
   */
  addFormControl() {
    this.formGroup.addControl(this.name, this.globalExcludeFormGroup);
  }

  /**
   * Form control init.
   */
  initFormControl() {
    if (!this.protectionGroup || !this.protectionGroup.globalExcludePaths) {
      return;
    }

    const excludePaths = new UntypedFormArray([]);
    this.protectionGroup.globalExcludePaths.forEach(excludePath => {
      excludePaths.push(new UntypedFormControl(
        convertToHostSpecificPath(excludePath, this.showWindowsPath),
        this.excludePathValidators));
    });

    this.globalExcludeFormGroup.setControl('paths', excludePaths);
  }
}
