// Service: PublicJobServiceFormatter utility service.
import { isEntityOwner } from '@cohesity/iris-core';

;(function(angular, undefined) {
  'use strict';

  // Cached array, populated on first call to get getIndexableEnvironments()
  var indexableEnvironments;

  angular
    .module('C.pubJobServiceFormatter', ['C.pubSourceServiceFormatter'])
    .service('PubJobServiceFormatter', PubJobServiceFormatterFn);

  function PubJobServiceFormatterFn($filter, _, ViewBoxCacheService,
    FEATURE_FLAGS, ENV_GROUPS, SOURCE_KEYS, PubSourceServiceFormatter,
    SOURCE_TYPE_DISPLAY_NAME, ENUM_ENTITY_ICON_CLASS, ENV_PARAMS_ENUM, $log,
    CONNECTION_STATE_DISPLAY_NAME, SOURCE_SPECIAL_PARAMETERS_KEYS, cUtils,
    SUPPORTED_NAS_FILESYSTEM, $rootScope, ENUM_ENV_TYPE, OFFICE365_GROUPS,
    JOB_GROUPS, EXCHANGE_GROUPS, NgOracleUtilityService, NgUserStoreService, $translate, $injector) {

    // This Service's API
    return {
      addNodeToTagBranch: addNodeToTagBranch,
      addSelectedSourcesToJob: addSelectedSourcesToJob,
      addTagBranch: addTagBranch,
      decorateRootNodes: decorateRootNodes,
      decorateSource: decorateSource,
      decorateSourceForAssignment: decorateSourceForAssignment,
      decorateSources: decorateSources,
      expandTagBranch: expandTagBranch,
      filterNodes: filterNodes,
      findAutoProtectedNodes: findAutoProtectedNodes,
      findNodes: findNodes,
      findNodesByNodeIds: findNodesByNodeIds,
      forEachNode: forEachNode,
      forEachNodeAsync: forEachNodeAsync,
      getIndexableEnvironments: getIndexableEnvironments,
      isAutoProtectExclusionSupported: isAutoProtectExclusionSupported,
      isEnvironmentIndexable: isEnvironmentIndexable,
      isTagMatch: isTagMatch,
      nodeMatchesTagBranch: nodeMatchesTagBranch,
      nodeProtectionStatusDecorator: nodeProtectionStatusDecorator,
      removeNodes: removeNodes,
      removeSourceSpecialParameters: removeSourceSpecialParameters,
      removeTagBranch: removeTagBranch,
      selectNode: selectNode,
      shouldSuppressRootNode: shouldSuppressRootNode,
      stubEnvironmentParams: stubEnvironmentParams,
      transformJob: transformJob,
      transformOracleJob: transformOracleJob,
      transformSqlJob: transformSqlJob,
      transformTree: transformTree,
      unselectNode: unselectNode,
      untransformActiveDirectoryJob: untransformActiveDirectoryJob,
      untransformJob: untransformJob,
      untransformOracleJob: untransformOracleJob,
      untransformSqlJob: untransformSqlJob,
      updateSourceSpecialParameters: updateSourceSpecialParameters,
      syncDuplicateSourceNodes: syncDuplicateSourceNodes,
      syncSourceSpecialParametersMapAndList: syncSourceSpecialParametersMapAndList,
      yieldToMainThread: yieldToMainThread,
    };

    /**
     * Finds the nodes in the jobTree and adds them as a decoration to the Job
     * via job._selectedSources[]. In the Job Flow this is handled by
     * cJobObjects via its ngModel handling. This is leveraged to emulate that
     * logic outside of the Job Flow context (i.e. global search).
     *
     * @param   {object}   job       The Job to decorate (by reference).
     * @param   {object}   jobTree   The tree information for the provided Job.
     * @returns {object}   The decorated job object (also updated by reference).
     */
    function addSelectedSourcesToJob(job, jobTree) {
      var theSources = findNodesByNodeIds(
        jobTree.tree,
        job.sourceIds.concat(job.vmTagIds)
      );

      // Sort the list, pushing auto protected nodes to the bottom.
      theSources = _.orderBy(theSources, ['_isAutoProtected'], ['asc']);

      job._selectedSources = theSources;

      return job;
    }

    /**
     * Builds and adds a tree branch to represent a tag or set of tags that
     * are part of a Job. Updates the provided tree by reference, pushing the
     * newly created tagBranch into it.
     *
     * @method   addTagBranch
     * @param    {Object[]}   tags             The tag nodes from which the
     *                                         branch should be derived.
     * @param    {boolean}    isSelected       Indicates if the tags are being
     *                                         "selected." True if the tags are
     *                                         being auto protected, false if
     *                                         they are being excluded.
     * @param    {Object[]}   leafNodes        The leaf nodes of the tree.
     *                                         Leaves having the provided tags
     *                                         will be added to
     *                                         tagBranch.nodes[].
     * @param    {Object[]}   tree             The full tree.
     * @param    {Object}     selectedCounts   The selected counts hash.
     * @return   {object}     The newly created/added tagBranch object
     */
    function addTagBranch(tags, isSelected, leafNodes, tree, selectedCounts) {
      var tagBranch = addDescendantDecorations({});

      isSelected = isSelected !== false;

      angular.extend(tagBranch, {

        // Convenience boolean for distinguishing tagBranches from standard
        // nodes.NOTE: `Array.isArray(node.protectionSource.id)` can also be
        // indicative of a tagBranch, but is a more expensive check.
        _isTagBranch: true,

        _isSelected: isSelected,
        _isAutoProtected: isSelected,
        _isExcluded: !isSelected,

        // to be populated while building standard hashedBranches, for nested
        // display via cEntityTree
        nodes: [],

        // easy lookup of child entity Ids to prevent dupes
        childrenIds: [],

        _rootEnvironment: 'kVMware',
        _environment: 'kVMware',
        _type: 'kTag',
        _isLeaf: false,
        _iconClass: ENUM_ENTITY_ICON_CLASS.kVMware.kTag,
        _sourceTypeNameKey: SOURCE_TYPE_DISPLAY_NAME.kVMware.kTag,

        // these properties to be incremented for each included VM via
        // addNodeToTagBranch()
        _numLeaves: 0,
        _logicalSize: 0,

        // Fake kVMware node for tree display.
        protectionSource: {
          id: tags.map(function mapEntity(tag) {
            return tag.protectionSource.id;
          }),
          name: tags.map(function mapNames(tag) {
            return tag.protectionSource.name;
          }).join(', '),
          environment: 'kVMware',
          vmWareProtectionSource: {
            type: 'kTag',
          },
        },
      });

      (leafNodes || []).forEach(function findTaggedNodes(leafNode) {
        var actionFn;

        /**
         * for all nodes matches the tag search criteria.
         *
         * if auto protection via tag (isSelected === true)
         * then select all un-protected nodes.
         *
         * if exclusion via tag (isSelected === false)
         * then exclude all those nodes who are not already protected either by
         * another auto protection rule or explicitly selected.
         */
        if (nodeMatchesTagBranch(leafNode, tagBranch)) {
          if (isSelected ? !leafNode._isSelected : leafNode._isSelected) {
            actionFn = isSelected ? selectNode : unselectNode;

            actionFn(leafNode, {
              // NOTE: while a bit decieving, both selectNode() and unselectNode()
              // expect this flag (tagAutoProtect) to be set to true when dealing
              // with a tag.
              tagAutoProtect: true,

              excluding: !isSelected,

              // Mark all descendent nodes auto protected because there parent tag
              // is auto protected.
              ancestorAutoProtect: true,
              selectedObjectsCounts: selectedCounts,
              tree: tree,
            });
          }

          // adding the found node matches the tag search criteria.
          addNodeToTagBranch(leafNode, tagBranch);
        }
      });

      tree.unshift(tagBranch);

      return tagBranch;

    }

    /**
     * Removes a pseudo tag branch from the job. Updates the provided tree by
     * reference, after searching and splicing the specified tagBranch from it.
     *
     * @method   removeTagBranch
     * @param    {Number[]}   tagsIds          The ids of tags associated with
     *                                         the given tagBranch.
     * @param    {Object[]}   tree             The full tree.
     */
    function removeTagBranch(tagIds, tree) {
      var tagBranchIndex;

      tagIds = tagIds.sort();
      tagBranchIndex = _.findIndex(tree, function findTagTree(node) {
        // tagIds is an array. Check for its equality.
        return _.isEqual(node.protectionSource.id.sort(), tagIds);
      });

      tree.splice(tagBranchIndex, 1);
    }

    /**
     * Adds the provided leaf node (always kVirtualMachine) to the provided
     * tagBranch.
     *
     * @method   addNodeToTagBranch
     * @param    {object}   node        The child node to add to tagBranch
     * @param    {object}   tagBranch   The branch to add the node to
     * @return   {object}   the updated tagBranch
     */
    function addNodeToTagBranch(node, tagBranch) {
      var nodeCopy = cUtils.simpleCopy(node);
      tagBranch.nodes.push(nodeCopy);
      tagBranch.childrenIds.push(nodeCopy.protectionSource.id);
      tagBranch._numLeaves++;
      tagBranch._logicalSize += getNodeLogicalSize(nodeCopy);

      node._isDuplicate = nodeCopy._isDuplicate = true;

      syncDuplicateNodeProperties(nodeCopy, node);

      decorateAncestors([tagBranch], nodeCopy);

      return tagBranch;
    }

    /**
     * Checks a node against a provided tagBranch and indicates if the node has
     * all of tags represented by the branch.
     *
     * @param    {object}    node        The node
     * @param    {object}    tagBranch   The tagBranch, as created in
     *                                   addTagBranch()
     * @return   {boolean}   true if the node contain all tags from the
     *                       tagBranch, false otherwise
     */
    function nodeMatchesTagBranch(node, tagBranch) {
      return tagBranch.protectionSource.id.every(function tagMatches(tagId) {
        return (node._envProtectionSource.tagAttributes || []).some(
          function checkTagAttrib(tagAttrib) {
            return tagAttrib.id === tagId;
          }
        );
      });
    }

    /**
     * recursively goto each node and filter out nodes with there parent and
     * descendent's for which callback fn return truthy value.
     *
     * @method   filterNodes
     * @param    {Object[]}   nodeList    The node list
     * @param    {Function}   callback    The callback fn used to determine does
     *                                    node belongs to result or not.
     * @param    {Array}      [path=[]]   If present then all nodes path
     * will be perpended by provided path else considered empty.
     * @return   {Object[]}   Pruned tree nodes for which callback fn results
     *                        truthy value.
     */
    function filterNodes(nodeList, callback, path) {
      // exit when nodeList or callback fn is not defined
      if (!nodeList || !callback) {
        return [];
      }

      path = path || [];

      // loop over and filter out nodes
      return nodeList.filter(function eachNode(node, index, list) {
        // filter out nodes for which truthyCallback returned true
        var isNodeIncluded = callback(node, index, list, path);

        // if a node is not included then filter out its descendent's
        if (!isNodeIncluded) {
          node.nodes = filterNodes(node.nodes, callback, path.concat(node));
        }

        // include the node when callback fn returned truthy value or has some
        // include children node.
        return isNodeIncluded || _.get(node, 'nodes.length');
      });
    }

    /**
     * Recursively goto each node and delete the specified node and its children
     * also will remove its ancestors nodes if removed nodes are the only
     * children.
     *
     * @method   removeNodes
     * @param    {Object[]}   nodeList     The node list
     * @param    {Function}   callback     The callback fn used to determine the
     * node needed to be removed when returned truthy value.
     * @param    {Array}        [path=[]]   If present then all nodes path
     * will be perpended by provided path else considered empty.
     * @return   {Object[]}   Pruned tree nodes for which callback fn results
     *                        truthy value.
     */
    function removeNodes(nodeList, callback, path) {
      // exit when nodeList or callback fn is not defined
      if (!nodeList || !callback) {
        return [];
      }

      path = path || [];

      // loop over and filter out nodes
      return nodeList.filter(function eachNode(node, index, list) {
        // do we need to remove current node
        var isNodeToRemove = callback(node, index, list, path);

        // filter out all descendent's nodes
        node.nodes = removeNodes(node.nodes, callback, path.concat(node));

        // remove node if:
        // 1. current node is marked for removal
        // 2. current node is not a leaf node & not having any children visible.
        //
        // don't remove node if current node is not marked and:
        // 1. current node is a leaf node
        // 2. current node is having some visible children.
        return isNodeToRemove ? false : (node._isLeaf || node.nodes.length);
      });
    }

    /**
     * recursively goto each nodes in nested nodes list
     *
     * @method   findNodes
     * @param    {Object[]}     nodeList          The node list
     * @param    {Function}     callbackFunction  The callback function to be
     *                                            called for each node
     * @param    {Array}        [path=[]]         If present then all nodes path
     *                                            will be perpended by provided
     *                                            path else considered empty.
     */
    function forEachNode(nodeList, callbackFunction, path) {
      if (!nodeList || !callbackFunction) {
        return;
      }

      path = path || [];

      nodeList.forEach(function eachNode(node, index, list) {
        // provide node & path to reach that node which will contain all parent
        // nodes
        callbackFunction(node, index, list, path);
        forEachNode(node.nodes, callbackFunction, path.concat(node));
      });
    }

    /**
     * recursively goto each nodes in nested nodes list where
     * callback is an async method
     *
     * @method   findNodes
     * @param    {Object[]}     nodeList          The node list
     * @param    {Function}     callbackFunction  The callback function to be
     *                                            called for each node
     * @param    {Array}        [path=[]]         If present then all nodes path
     *                                            will be perpended by provided
     *                                            path else considered empty.
     */
    async function forEachNodeAsync(nodeList, callbackFunction, path) {
      if (!nodeList || !callbackFunction) {
        return;
      }

      path = path || [];
      let start = performance.now();
      let deadline = start + 50;
      let chunkTime = 0;

      for (let index = 0; index < nodeList.length; index += 1) {
        // provide node & path to reach that node which will contain all parent
        // nodes
        const node = nodeList[index];
        const current = performance.now();
        chunkTime += (current - start);

        // break task every 100ms of chunk time
        // Optimal time to yield to main thread should be 50ms
        // but we stretch it to 100ms. It will lower the smothness of the UI.
        // Breaking it too frequently has overhead of switching too much
        const breakTask = (chunkTime > 100 || current >= deadline);
        // console.log('$$$$$$$$$$$$$ break task point', chunkTime, breakTask);
        if (navigator.scheduling?.isInputPending() || breakTask) {

          // Pending user input, or the performance
          // deadline has been reached. Break.
          await yieldToMainThread();

          // New deadline
          start = performance.now();
          chunkTime = 0;
          deadline = start + 50;
        }

        await callbackFunction(node, index, nodeList, path);
        path.push(node);
        await forEachNodeAsync(node.nodes, callbackFunction, path);
        path.pop();
      }
    }

    function yieldToMainThread() {
      return new Promise(resolve => {
        setTimeout(resolve, 50);
      });
    }

    /**
     * Recursively find nodes in nested nodes list, results deduped and alpha
     * sorted.
     *
     * @method   findNodes
     * @param    {Object[]}   nodeList              The node list
     * @param    {Function}   compareFunction       The compare function return
     *                                              true if you want a node else
     *                                              return false
     * @param    {Object}     [foundNodesHash={}]   Hash used to prevent
     *                                              duplicate nodes from being
     *                                              returned. This is primarly
     *                                              useful for kVMware trees, as
     *                                              nodes can be represented
     *                                              many times.
     * @return   {Object[]}   foundNodes
     */
    function findNodes(nodeList, compareFunction, foundNodesHash) {
      foundNodesHash = foundNodesHash || {};

      return (nodeList || []).reduce(function eachNode(acc, node) {
        if (!foundNodesHash[node.protectionSource.id] &&
          compareFunction(node)) {
          foundNodesHash[node.protectionSource.id] = true;
          acc = acc.concat(node);
        }

        acc = acc.concat(
          findNodes(node.nodes, compareFunction, foundNodesHash));

        // return ordered node list by node name
        return $filter('orderBy')(acc, 'protectionSource.name');
      }, []);

    }

    /**
     * Finds and returns nodes with nodeIds included in provided nodeList.
     *
     * @method   findNodesByNodeIds
     * @param    {Object[]}   nodeList   The node list
     * @param    {String[]}   nodeIds    nodeIds list to find in nodeList
     * @return   {Object[]}   foundNodes
     */
    function findNodesByNodeIds(nodeList, nodeIds) {
      return findNodes(nodeList, function forEachNode(node) {
        if (node._isTagBranch) {
          // This is a derived tagBranch and needs special handling, because it
          // has an array of ids representing the tag or tags intersection.
          // First, sort the array of ids before checking equality.
          node.protectionSource.id.sort();

          // Then look for a match within the list of nodeIds.
          return nodeIds.some(function checkNodeIds(nodeId) {
            // We know protectionSource.id is an Array, so if nodeId isn't an
            // array then this is not a match. If nodeId is an array, check for
            // ~equality (with items sorted) between the two.
            return Array.isArray(nodeId) &&
              angular.equals(node.protectionSource.id, nodeId.sort());
          });
        }

        // Node is not a tag branch. A simple check of the list will suffice.
        return nodeIds.includes(node.protectionSource.id);
      });
    }

    /**
     * Find all auto protected nodes in nodeList.
     *
     * @method   findAutoProtectedNodes
     * @param    {Object[]}   nodeList   The node list
     * @return   {Object[]}   foundNodes
     */
    function findAutoProtectedNodes(nodeList) {
      return findNodes(nodeList, function forEachNode(node) {
        return node._isAutoProtected;
      });
    }

    /**
     * Formats the remote pre/post script part of the job payload by removing
     * the unused script for which the script path is missing.
     *
     * @method   _formatRemotePrePostScript
     * @param    {Object}    job    The protection job object
     * @return   {Object}    job    The formatted protection job object
     */
    function _formatRemotePrePostScript(job) {
      if (!_.get(job.preBackupScript,
        'incrementalBackupScript.scriptPath')) {
        job.preBackupScript = undefined;
      }

      if (!_.get(job.postBackupScript,
        'incrementalBackupScript.scriptPath')) {
        job.postBackupScript = undefined;
      }

      return job;
    }

    /**
     * Undoes Job transformations to prep object for API submission.
     *
     * @method   untransformJob
     * @param    {object}   job   The Job
     * @return   {object}   an updated copy of the Job
     */
    function untransformJob(job) {
      var jobCopy = angular.copy(job);
      _unstubEnvironmentParams(jobCopy);

      // Clear any `remoteViewName` if feature was disabled.
      jobCopy.remoteViewName =
        jobCopy.createRemoteView ? jobCopy.remoteViewName : undefined;

      // Remove the unused remote pre/post script from the payload
      if (ENV_GROUPS.remotePrePostScriptAdapters.includes(jobCopy.environment) &&
        (jobCopy.preBackupScript || jobCopy.postBackupScript)) {
        jobCopy = _formatRemotePrePostScript(jobCopy);
      }

      switch (job.environment) {
        case 'kSQL':
          untransformSqlJob(jobCopy);
          break;

        case 'kOracle':
          untransformOracleJob(jobCopy);
          break;
        case 'kAD':
          untransformActiveDirectoryJob(jobCopy);
      }

      return jobCopy;
    }

    /**
     * provides normalization of job object and adds derived properties that are
     * useful throughout multiple states/pages
     *
     * @method   transformJob
     * @param    {Object}   job   object as provided by PubJobService functions
     * @return   {Object}   returns the provided object updated. However, the
     *                      object is modified directly and will be updated by
     *                      reference.
     */
    function transformJob(job) {
      var cachedViewBox;

      if (!job) { return job; }

      // A Job is active if the isActive property is missing
      job.isActive = angular.isDefined(job.isActive) ? job.isActive : true;
      job.isPaused = angular.isDefined(job.isPaused) ? job.isPaused : false;

      // Populate default QoS policy since there is no database backfill
      job.qosType = job.qosType || 'kBackupHDD';

      // Ensure job.sourceIds is an array. If a Job was failed over its possible
      // that job.sourceIds[] won't exist.
      job.sourceIds = job.sourceIds || [];
      job.excludeSourceIds = job.excludeSourceIds || [];
      job.alertingPolicy = job.alertingPolicy || [];
      job.alertingConfig = job.alertingConfig || { emailDeliveryTargets: [] };

      job.vmTagIds = job.vmTagIds || [];
      job.excludeVmTagIds = job.excludeVmTagIds || [];
      job._parentSource = {};
      job._supportsAutoProtectExclusion = isAutoProtectExclusionSupported(job);

      job.dedupDisabledSourceIds = job.dedupDisabledSourceIds || [];

      cachedViewBox = ViewBoxCacheService.viewBoxes[job.viewBoxId];
      job._viewBoxName = cachedViewBox ?
            cachedViewBox.name : undefined;

      job.sourceSpecialParameters = job.sourceSpecialParameters || [];
      job._sourceSpecialParametersMap = job.sourceSpecialParameters.reduce(
        function forEachParams(acc, specialParam) {
          acc[specialParam.sourceId] = specialParam;

          return acc;
        },
        {}
      );

      // TODO: make this work for public API structure
      // job._tagSets = buildTagSets(job.vmTagIds);
      // job._excludeTagSets = buildTagSets(job.excludeVmTagIds);

      job.isDeleted = !!job.isDeleted;

      stubEnvironmentParams(job);

      // Verify indexable environment
      job._supportsIndexing = isEnvironmentIndexable(job.environment);

      // Verify if the environment supports indexing customization rules.
      job._supportsIndexingRules = !ENV_GROUPS.indexableWithoutRules
        .includes(job.environment);

      // Initialize indexing policy
      job.indexingPolicy = job.indexingPolicy || {};

      job.indexingPolicy.allowPrefixes =
        job.indexingPolicy.allowPrefixes || [];

      job.indexingPolicy.denyPrefixes =
        job.indexingPolicy.denyPrefixes || [];

      job.indexingPolicy.disableIndexing =
        angular.isDefined(job.indexingPolicy.disableIndexing) ?
          job.indexingPolicy.disableIndexing : true;

      if (FEATURE_FLAGS.crashConsistentEnabled &&
        ENV_GROUPS.hypervisor.includes(job.environment)) {
        if (job.environment === 'kVMware') {
          // fallbackToCrashConsistent is a new property in 4.0 which is
          // undefined for existing VM jobs. If undefined, we need to set a
          // default value which will be the inverse of the 'quiesce' value
          // which is the App-Consistent Backup setting in order to not force
          // the feature on pre-existing app-consistent jobs
          //
          // For new jobs, the default fallbackToCrashConsistent = true.
          // For pre-4.0 jobs with undefined fallbackToCrashConsistent,
          // we populate it as follows:
          //   if App-Consistent is enabled (quiesce: true) then
          //     fallbackToCrashConsistent = false.
          //   if App-Consistent is disabled (quiesce: false) then
          //     fallbackToCrashConsistent = true.
          //
          // This only applies to VMWare. Other Virtual Server products were
          // added AFTER this release which means there are no non-VMWare legacy
          // jobs.
          job._envParams.fallbackToCrashConsistent =
            job._envParams.fallbackToCrashConsistent ||
            !job.quiesce;
        } else {
          // For non-VMWare VM jobs, Crash Consistent fallback is always on.
          job._envParams.fallbackToCrashConsistent = true;
        }
      }

      // NAS Jobs do not have a simple flag to indicate there are file path
      // inclusions/exclusions, add one.
      job._hasFilePathFilters = !!(ENV_GROUPS.nas.includes(job.environment) &&
        _.get(job, '_envParams.filePathFilters.protectFilters.length', false));

      if (job._hasFilePathFilters) {
        job._envParams.filePathFilters.excludeFilters =
          job._envParams.filePathFilters.excludeFilters || [];
      }

      // While editing a job, we need these values to keep the pre & post
      // scripts turned on. For a new job they will be undefined.
      job._hasPreScript = !!job.preBackupScript;
      job._hasPostScript = !!job.postBackupScript;

      if (ENV_GROUPS.remotePrePostScriptAdapters.includes(job.environment)) {
        job._hasRemotePrePostScripts = job._hasPreScript || job._hasPostScript;
      }

      switch (job.environment) {
        case 'kSQL':
          transformSqlJob(job);
          break;

        case 'kOracle':
          transformOracleJob(job);
          break;
      }

      // True if the user belongs to job owner's organization. Used
      // to prevent the user from making run-now, pause, delete etc requests.
      // Using $injector since injecting this normally causes circular dependency error
      const ctx = $injector.get('NgIrisContextService')?.irisContext;
      job._isJobOwner = isEntityOwner(ctx, job.tenantId);

      job._isDownTieringJob = !!job._envParams.dataMigrationJobParameters;
      job._isUpTieringJob = !!job._envParams.dataUptierJobParameters;
      job._isDataMigrationJob = job._isDownTieringJob || job._isUpTieringJob;

      return _.assign(job, {
        // If a `remoteViewName` exists, then Create Remote View.
        createRemoteView: !!job.remoteViewName,
        _isPhysicalBlockEnv: job.environment === 'kPhysical',
        _isPhysicalFileEnv: job.environment === 'kPhysicalFiles'
      });
    }

    /**
     * Indicates if a particular env type support indexing, taking FEATURE_FLAGS
     * into account.
     *
     * @method   isEnvironmentIndexable
     * @param    {string}    environment   The environment
     * @return   {boolean}   True if provided environment value is indexable,
     *                       False otherwise.
     */
    function isEnvironmentIndexable(environment) {
      return getIndexableEnvironments().includes(environment);
    }

    /**
     * Returns an array of the indexable environment types.
     *
     * @method   getIndexableEnvironments
     * @return   {array}   The indexable environments, kValues.
     */
    function getIndexableEnvironments() {
      // if this logic has already been run, return the cached list.
      if (indexableEnvironments) {
        return indexableEnvironments;
      }

      indexableEnvironments = cUtils.simpleCopy(ENV_GROUPS.indexableKvals);

      if (!FEATURE_FLAGS.indexingViewsEnabled) {
        _.pullAll(indexableEnvironments, ENV_GROUPS.spanFS);
      }

      if (!FEATURE_FLAGS.indexingNasEnabled) {
        _.pullAll(indexableEnvironments, ENV_GROUPS.nas);
      }

      if (!FEATURE_FLAGS.activeDirectorySearchObjects) {
        // Active Directory object search is not enabled.
        _.pull(indexableEnvironments, 'kAD');
      }

      return indexableEnvironments;
    }

    /**
     * Determines if the given Job supports Exclusion of Auto-protected objects.
     *
     * This is intended as a stop-gap for 6.0 until we can properly engineer the
     * SQL auto-protect UX, which differs from our "normal" auto-protection UX.
     *
     * @method   isAutoProtectExclusionSupported
     * @param    {object}    job   The job to feature detect against.
     * @return   {boolean}   True if Exclusion is supported. False otherwise.
     */
    function isAutoProtectExclusionSupported(job) {
      // If no job environment is yet defined (brand new job), then the default
      // is true. We can only know if this feature is not supported when we know
      // the environment which sometimes happens after a Source is selected.
      return !job.environment ||
        ENV_GROUPS.autoProtectSupported.includes(job.environment) &&
        !ENV_GROUPS.databaseSources.includes(job.environment);
    }

    /**
     * sync updates of source special parameters map to list.
     * sync updates is an internal function.
     *
     * @method   syncSourceSpecialParametersMapAndList
     * @param    {Object}   job   The job
     */
    function syncSourceSpecialParametersMapAndList(job) {
      job.sourceSpecialParameters =
        Object.keys(job._sourceSpecialParametersMap).map(
          function forEachParam(sourceId) {
            return job._sourceSpecialParametersMap[sourceId];
          }
        );
    }

    /**
     * remove node special parameters for the job
     *
     * @method   removeSourceSpecialParameters
     * @param    {Object}   job    The job
     * @param    {Object}   node   The node
     */
    function removeSourceSpecialParameters(job, node) {
      // remove from map and then update the special parameters array list.
      delete job._sourceSpecialParametersMap[node.protectionSource.id];
      syncSourceSpecialParametersMapAndList(job);
    }

    /**
     * update node special parameters for the job
     *
     * @method   updateSourceSpecialParameters
     * @param    {object}   job       The job
     * @param    {object}   node      The node
     * @param    {object}   updates   The updates
     */
    function updateSourceSpecialParameters(job, node, updates) {
      var sourceId = node.protectionSource.id;
      var sourceSpecialParameters = job._sourceSpecialParametersMap[sourceId];
      var sourceSpecialParametersKey =
        SOURCE_SPECIAL_PARAMETERS_KEYS[node._environment];

      // if 1st update then create the parameters structure.
      if (!sourceSpecialParameters) {

        sourceSpecialParameters =
          job._sourceSpecialParametersMap[sourceId] = {
            sourceId: sourceId
          };
      }

      // If the special parameters being modified don't exist yet
      // create them.
      if (!sourceSpecialParameters[sourceSpecialParametersKey]) {
        sourceSpecialParameters[sourceSpecialParametersKey] = {}
      }

      // apply source special parameters updates in the job.
      angular.extend(
        sourceSpecialParameters[sourceSpecialParametersKey], updates);

      syncSourceSpecialParametersMapAndList(job);
    }

    /**
     * Stub out the job-specific environmentParams for the provided job object.
     * This Fn directly modifies the passed in object and will effect the
     * reference in the context from which it was called.
     *
     * @method   stubEnvironmentParams
     * @param    {object}   job   The job object
     */
    function stubEnvironmentParams(job) {
      // Stubbed defaultParams
      var defaultParams = {
        environmentParameters: {},
      };

      // Add the key name of the job-specific backup params property
      job._envParamsKey = ENV_PARAMS_ENUM[job.environment];

      // Further stub defaultParams with job-specific backupParams and merge
      // into Job. This preserves the parameters object in the Job if it already
      // exists.
      defaultParams.environmentParameters[job._envParamsKey] = {};
      angular.merge(job, defaultParams);

      // Add a convenience property since each job type has a deeply nested
      // backupParams object with a different path.
      job._envParams = job.environmentParameters[job._envParamsKey];
    }

    /**
     * put environment parameters back in their correct place in the Job in prep
     * for API submissions. NOTE: updates the provided Job by reference.
     *
     * @method   _unstubEnvironmentParams
     * @param    {object}   job   The Job
     * @return   {object}   The updated Job
     */
    function _unstubEnvironmentParams(job) {
      var _envParamsKey = ENV_PARAMS_ENUM[job.environment];

      // clear the Job's environment params until proven that they should stay.
      job.environmentParameters = undefined;

      // if there is an envParamsKey and any param was provided, put it in the
      // correct location.
      if (_envParamsKey && !angular.equals({}, job._envParams)) {
        job.environmentParameters = {};
        job.environmentParameters[_envParamsKey] = job._envParams;
      }
      return job;
    }

    /**
     * Used to determines if root node should be suppressed or not.
     *
     * @method   shouldSuppressRootNode
     * @param    {Array}     node     The node.
     * @param    {Object}    opts     The options.
     * @return   {boolean}   Return true if node should be suppressed else false
     */
    function shouldSuppressRootNode(node, opts) {
      var suppressRootNode = (node || []).length === 1 && (
        _.get(node[0],
          'protectionSource.physicalProtectionSource.type') === 'kGroup' ||
        _.get(node[0],
          'protectionSource.pureProtectionSource.type') === 'kStorageArray' ||
        _.get(node[0],
          'protectionSource.sqlProtectionSource.type') === 'kRootContainer'
      );

      return suppressRootNode && !_.get(opts, 'preventRootNodeSuppression');
    }

    /**
     * Take Source tree and Job object and transform the Source tree into a Job
     * flow friendly tree structure.
     *
     * The options object takes two keys.
     *  - rootEnvironment            The kValue string for the root enviroment.
     *  - preventRootNodeSuppression True, if you want to suppress the rootNode
     *
     * @method   transformTree
     * @param    {array}    source   The source
     * @param    {object}   job      The job
     * @param    {object}   opts     The options inclusive of rootEnviroment
     *                               and preventRootNodeSuppression.
     * @return   {array}    the decorated source tree
     */
    function transformTree(source, job, opts) {
      var decoratorOptions;
      var leafNodes;

      job = job || {};
      opts = _.assign(
        {
          selectedCounts: {},
          totalCounts: {},
          isSourceIdMissingHash: {},
        },
        opts
      );

      decoratorOptions = {
        job: job,
        selectedCounts: opts.selectedCounts,
        totalCounts: opts.totalCounts,
        isSourceIdMissingHash: opts.isSourceIdMissingHash,
        rootEnvironment: opts.rootEnvironment,

        // Children are not loaded if we are fetching partial tree
        // This is only applicable to SQL trees for now.
        areChildrenLoaded: FEATURE_FLAGS.sqlAsyncMode &&
          _.get($rootScope.$stateParams, 'environment') === 'kSQL' ? false : undefined,
      };

      // For some source trees the root wrapper/source is not desirable.
      if (shouldSuppressRootNode(source, opts)) {
        source = source[0].nodes || [];
      }

      source.forEach(function loopRootNodes(sourceNode) {
        decorateJobTreeNode(sourceNode, decoratorOptions);
      });

      opts.missingSourceIds = opts.missingSourceIds || [];

      if (_.get(source, '[0].nodes[0]._isTagProtectionSupported') &&
        (_.get(job.vmTagIds, 'length') ||
          _.get(job.excludeVmTagIds, 'length'))) {
          leafNodes = findNodes(source, isLeafNode);

          ['vmTagIds', 'excludeVmTagIds'].forEach(
            function handleTagIds(tagIdKey) {
              var indexesForRemoval = [];

              job[tagIdKey].forEach(function handleTagIds(tagIds, tagIdsIndex) {
                var tags = findNodesByNodeIds(source, tagIds);
                var isSelected = tagIdKey === 'vmTagIds';

                // Check for tagIds.length to prevent empty tag set lists from
                // being maintained in the job configuration. RCA of such
                // configuration is known so adding this failsafe. FI-12177
                if (tagIds.length && tags.length === tagIds.length) {
                  // All the tags exist in the tree. Safe to proceed.
                  addTagBranch(tags, isSelected, leafNodes, source,
                    opts.selectedCounts);
                } else {
                  // All the tags weren't found in the tree, Track for removal
                  // from job configuration. Use unshift so we can remove items
                  // without impacting subsequent items index value.
                  indexesForRemoval.unshift(tagIdsIndex);

                  // Add the missing tag ids to the aggregate list of missing
                  // source ids so the appropriate warning message will be
                  // displayed in the job flow.
                  tagIds.forEach(function isMissingTagId(tagId) {
                    var isMissing = !tags.some(function(tagNode) {
                      return tagNode.protectionSource.id === tagId;
                    });

                    if (isMissing) {
                      opts.missingSourceIds.push(tagId);
                    }
                  });
                }
            });

            // Clean up the job's configuration by removing any tag sets that
            // have tag ids which are missisng from the tree.
            if (indexesForRemoval.length) {
              indexesForRemoval.forEach(function removeIndex(indexVal) {
                job[tagIdKey].splice(indexVal, 1);
              });
            }
          });
      }

      _.forEach(
        opts.isSourceIdMissingHash,
        function evaluateMissingFn(isMissing, sourceId) {
          if (isMissing) {
            opts.missingSourceIds.push(Number(sourceId));
          }
        }
      );

      return source;
    }

    /**
     * Decorate root source node with node specific helper properties.
     *
     * @method   decorateRootNodes
     * @param    {Object}   rootNodes   The root nodes
     * @param    {Object}   options     Node options.
     * @return   {Object}   Modified source node.
     */
    function decorateRootNodes(rootNodes, options) {
      options = options || {};

      return rootNodes.map(function eachRootNode(node) {
        return decorateSource(
          node,
          _.assign({}, options, {
            // full root nodes hierarchy not fetched so decorate node
            // accordingly and call getSource to fetch the hierarchy for a
            // root node.
            areChildrenLoaded: false,
          })
        );
      });
    }

    /**
     * Decorate sources node with node specific helper properties.
     *
     * @method   decorateSource
     * @param    {Array}    sources      The sources list.
     * @param    {Object}   options   Node options.
     * @return   {Object}   Decorate source node.
     */
    function decorateSources(sources, options) {
      options = options || {};
      var out = sources.map(function eachRootNode(node) {
        // For some source trees the root wrapper/source is not desirable.
        if (shouldSuppressRootNode(node, options)) {
          node = node[0].nodes || [];
        }

        return decorateSource(node, options);
      });

      // use client side filtering for excludeTypes filter if below flag is true
      // currently it is used by data-protection > sources > all-objects page
      // for the tenants users to get inherited entityPermissionInfo for the VMs
      // under resource pool which is assigned to the tenant and if server side
      // filtering is used we won't be able to get those inherited properties.
      if (options.reqParams &&
        options.reqParams._useClientSideExcludeTypesFilter) {
        const clientSideExcludeTypes =
          new Set(options.reqParams._clientSideExcludeTypes || []);

        // filtering out node and its descendants but preserve its ancestors.
        out = filterNodes(out, function nodeToRemote(node) {
          // NOTE: excludeTypes filter works only for VMware source.
          return !(node._hostEnvironment === 'kVMware' &&
            clientSideExcludeTypes.has(node._envProtectionSource.type));
        });
      }

      return out;
    }

    /**
     * Decorate source node with node specific helper properties.
     *
     * @method   decorateSource
     * @param    {Object}   node      The node
     * @param    {Object}   options   Node options.
     * @return   {Object}   Modified source node.
     */
    function decorateSource(node, options) {
      var nodeEnvironment;
      var sourceKey;
      var rootNode;
      var nodeSourceType;

      /**
       * For DB nodes, this will be the host object.
       *
       * @type   {object}   The found host ancestor.
       */
      var thisNodesParentHost;

      options = options || {};

      // default initialize the options
      options = _.assign({
        ancestors: node.ancestors || [],

        // Initialize the object & parent node hash which holds the duplicate
        // nodes & their respective parent node.
        objHash: options.objHash || {},
        objParentHash: options.objParentHash || {},
      }, options);

      // NOTE: this fallback serves PubSourceService.getRootNodes() which
      // returns a similar structure but with different naming. Creating
      // protectionSource as a reference to rootNode for such cases.
      // TODO (Sam): We need to move this to PubSourceServiceFormatter since it
      // serves to decorate the source rather than the job.
      node.protectionSource = node.protectionSource || node.rootNode;

      // Add node in the object hash used to detect duplicate nodes and
      // appropriately set node._isDuplicate property.
      _addNodeToObjectHash(node, options);

      rootNode = _.find(
        _.get(options, 'ancestors', []),
        ['protectionSource.id', node.protectionSource.parentId]
      ) || {};

      options.rootEnvironment =
        options.rootEnvironment || node.protectionSource.environment;
      options.jobsByEntityIdsMap = options.jobsByEntityIdsMap || {};

      nodeEnvironment = node.protectionSource.environment;
      sourceKey = SOURCE_KEYS[nodeEnvironment];
      nodeSourceType = node.protectionSource[sourceKey] &&
        node.protectionSource[sourceKey].type;

      // If this is a DB entity,
      if (ENV_GROUPS.databaseSources.includes(nodeEnvironment)) {
        // Find its parent Host in its ancestors list.
        thisNodesParentHost = _.find(
          _.get(options, 'ancestors', []),
          ['protectionSource.id', node.protectionSource[sourceKey].ownerId]
        ) || {};

        if (!_.isEmpty(thisNodesParentHost)) {
          // If available, fill parent host data.
          // This will be useful in case of oracle where we only get
          // the  nodes data at the parent host level.
          // But we want that nodes data at the db level for
          // multinode multi channel stuff.
          node._parentHostProtectionSource =
            thisNodesParentHost._envProtectionSource || {};

          // This data is required to know if the database is
          // db authenticated or os authenticated in
          node._isDbAuthenticated = _.get(thisNodesParentHost,
            'registrationInfo.isDbAuthenticated', false);
        }


        // Tag this node as a System DB if it is one.
        node._isSystemDb =
          PubSourceServiceFormatter.isSystemDatabase(node.protectionSource);

        node.protectionSource[sourceKey]._isFilestreamDb =
          PubSourceServiceFormatter.isFilestreamDatabase(
            node.protectionSource[sourceKey]);
      }

      _.assign(node, {
        // This fork prevents display of application hierarchies under hosts
        // when outside of a application (db, active directory, etc..) workflow.
        // Those are only useful to the User when they are working with
        // registered applications.
        nodes: ENV_GROUPS.applicationSources.includes(options.rootEnvironment) ?
          node.applicationNodes || node.nodes || [] :
          node.nodes || [],
        _isSelected: false,
        _isExpanded: false,

        // Indicates whether the node is a root node or not and for that looking
        // for rootNode property when nodes are fetched from getSourcesInfo()
        // service otherwise protectionSource.parentId property.
        _isRootNode:  !!node.rootNode || !node.protectionSource.parentId,

        // list of job protecting the node
        _protectingJobs:
          options.jobsByEntityIdsMap[node.protectionSource.id] || [],

        // consider tree children loaded until optionally overridden.
        _areChildrenLoaded: _.isBoolean(options.areChildrenLoaded) ?
          options.areChildrenLoaded : true,
        _nameLowerCase: node.protectionSource.name.toLowerCase(),
        _environment: nodeEnvironment,
        _rootEnvironment: options.rootEnvironment,
        _rootSourceType: _.get(
          rootNode,
          'protectionSource['+sourceKey+'].type',
          nodeSourceType
        ),

        // Get thisNodesParentHost environment. Defaults to this node's
        // environment. Generally mirrors node._environment, but will differ in
        // Database trees.
        _hostEnvironment: options.hostEnvironment || _.get(
          thisNodesParentHost,
          'protectionSource.environment',
          nodeEnvironment
        ),
        _isHypervisor:
          ENV_GROUPS.hypervisor.includes(nodeEnvironment),
        _sourceKey: sourceKey,
      });

      // add node owner info also keep it in with all descendant nodes for quick
      // lookup
      addNodeOwnerInfo(node);

      // Depends on properties from the 1st derivation.
      node._isLeaf = isLeafNode(node);

      addEnvProtectionSource(node);
      nodeProtectionStatusDecorator(node);

      // uses some properties from 1st derivation
      _.assign(node, {
        _type: node._envProtectionSource.type,
        _volumeType: _.get(node._envProtectionSource, 'volumeInfo.type'),
        _typeStr: ENUM_ENV_TYPE[node.protectionSource.environment],
        _isExchangeRegistered: isExchangeRegistered(node),
        _dataProtocols: getDataProtocols(node),
        _volumesLvm: filterVolumes(node, 'lvm'),
        _volumesNonLvm: filterVolumes(node, 'nonlvm'),
        _volumesShared: filterVolumes(node, 'shared'),

        // a physical agent can be installed in any environment on any VM this
        // check is not specific to the kPhysical env
        _isPhysicalAgentInstalled:
          !!(node.protectionSource[sourceKey] &&
            node.protectionSource[sourceKey].physicalSourceId),
      });

      // uses some properties from 2nd derivation
      _.assign(node, {
        _iconClass: $filter('sourceIcon')(node),
        _sourceTypeNameKey: $filter('sourceType')(node.protectionSource),
        _connectionStateKey: CONNECTION_STATE_DISPLAY_NAME[
          node._envProtectionSource.connectionState
        ],
        _hasConnectionStateProblem: entityHasConnectionStateProblem(node),
        _logicalSize: getNodeLogicalSize(node),

        // General bool property. Adapter specific properties are added
        // elsewhere.
        _isDatabaseEntity:
          ENV_GROUPS.databaseSources.includes(nodeEnvironment),
      });

      // NOTE: No longer concerned if this is a leaf or not.
      node = decorateNode(node, options);
      node._numLeaves = getLeavesCount(node);

      // node decoration done so sync selection, auto protection, _owner etc
      // sharable properties for duplicate node generally they are leaf VM nodes
      if (node._isDuplicate) {
        _syncDuplicateSourceProperties(node, options);
      }

      if (node._dataProtocols) {
        node._dataProtocolsString = node._dataProtocols.map(protocol => {
          return $translate.instant(`enum.filesystemProtocol.${node._environment}.${protocol}`);
        }).join(', ');
      }

      const beforeOwnerInfo = { ...(options.objHash[node.protectionSource.id] || [node])[0]._owner };

      // decorate descendants nodes
      decorateDescendants(decorateSource, node, options);

      if (node._isDuplicate && !_.isEqual(beforeOwnerInfo, node._owner)) {
        _syncDuplicateSourceProperties(node, options);
      }

      return node;
    }

    /**
     * Setup node & its decorator options to detect duplicate nodes present in
     * the tree for eg. a VM leaf node can be logically present under a host,
     * resource pool or vCenter folder.
     *
     * @method  _addNodeToObjectHash
     * @param   {Object}   node           The node.
     * @param   {Object}   [options={}]   The options used to decorate nodes. {
     *    ancestors:     {Array}   The list of ancestors nodes for provided node
     *    objHash:       {Object}  List of duplicates nodes found in the tree.
     *    objParentHash: {Object}  List of parent nodes for found duplicates
                                   nodes in the tree.
     * }
     * @param   {Number}   [index=0]   The index.
     * @return  {Object}  The list of ancestors nodes.
     */
    function _addNodeToObjectHash(node, options) {
      var nodeHash = options.objHash[node.protectionSource.id];

      // initialize obj hashes used to determine duplicate node and its parent.
      if (!_.isArray(nodeHash)) {
        // objHash contains list of duplicates nodes found in the tree.
        nodeHash = options.objHash[node.protectionSource.id] = [];

        // objParentHash contains list of parent nodes for found duplicates
        // nodes in the tree.
        options.objParentHash[node.protectionSource.id] = [];
      }

      // append current node in the hash.
      nodeHash.push(node);

      // Mark as duplicate if we have multiple similar nodes.
      node._isDuplicate = nodeHash.length > 1;

      if (nodeHash.length === 2) {
        // mark the previous node is duplicate.
        nodeHash[0]._isDuplicate = true;
      }

      // If this node has any ancestors, add its immediate parent to the parent
      // hash used later after syncing duplicate descendent to update ancestor's
      // derived properties.
      if (options.ancestors.length) {
        options.objParentHash[node.protectionSource.id].push(
          options.ancestors[options.ancestors.length - 1]
        );
      }
    }

    /**
     * Sync sharable properties b/w duplicate source nodes and update there
     * ancestors nodes which depended on those sharable properties.
     *
     * TODO(ENG-64902): replace old syncDuplicateNodeProperties fn which is
     * limited to sync props b/w 2 nodes with _syncDuplicateSourceProperties fn
     * which can sync properties b/w more than 2 nodes and refactoring will be
     * done in 6.4 and skipped in 6.3 because of right regression risk in
     * protection job workflow.
     *
     * @method  _syncDuplicateSourceProperties
     * @param   {Object}   node           The node.
     * @param   {Object}   [options={}]   The options used to decorate nodes. {
     *    objHash:       {Object}  List of duplicates nodes found in the tree.
     *    objParentHash: {Object}  List of parent nodes for found duplicates
     *                             nodes in the tree.
     * }
     * @param   {Boolean}   [isNodeMaster=false]  If true then provided node
     *   value will be used as resultant value while merging properties.
     */
    function _syncDuplicateSourceProperties(node, options, isNodeMaster) {
      // The map of properties to sync which contains fn to get union value for
      // that property.
      var propsToSync = {
        _isAncestorAutoProtected: _listHasTruePropertyValue,
        _isAncestorExcluded: _listHasTruePropertyValue,
        _isAutoProtected: _listHasTruePropertyValue,
        _isSelected: _listHasTruePropertyValue,
        _isTagAutoProtected: _listHasTruePropertyValue,
        _isTagExcluded: _listHasTruePropertyValue,
        _owner: _getUnionOfOwnerInfo,
        _selectedAncestor: _listHasTruePropertyValue,
      };
      var nodesToSync = options.objHash[node.protectionSource.id] || [];

      // nothing to sync if there are 1 or 0 duplicates nodes.
      if (nodesToSync.length <= 1) {
        return;
      }

      // loop over properties to sync there values.
      _.forEach(propsToSync, function (getUnionOfValueFn, prop) {
        // compute the value to sync.
        var mergedValue = isNodeMaster ? node[prop] : getUnionOfValueFn(nodesToSync, prop);

        // updating the node values.
        nodesToSync.forEach(function eachNode(node) {
          if (prop === '_owner') {
            // adding isInferred true indicating other duplicate nodes ownership is inferred from some other node.
            if (mergedValue.tenantId && !node[prop].tenantId) {
              node[prop].isInferred = true;
            }
            _.assign(node[prop], mergedValue);
          } else {
            node[prop] = mergedValue;
          }
        });
      });

      // sync node's ancestors to update dependent properties like
      // _descendantNodeOwnersMap which depends on descendent _owner.
      nodesToSync.forEach(function eachNode(node, index) {
        var ancestors = _getAncestorsFromObjHash(node, options, index);

        decorateAncestors(ancestors, node);
      });
    }

    /**
     * Determines whether requested property is true for some of the provided
     * nodes.
     *
     * @method  _listHasTruePropertyValue
     * @param   {Array}    nodes   The list of nodes.
     * @return  {Boolean}  True if some of the node is set else return false.
     */
    function _listHasTruePropertyValue(nodes, prop) {
      return nodes.some(function eachNode(node) {
        return !!node[prop];
      });
    }

    /**
     * Get the union of owner info from list of provided nodes and used to sync
     * owner info b/w duplicate nodes.
     *
     * 1. if a node is owned by tenant that means all the duplicate nodes would
     *    be owned by tenant.
     * 2. if a node is owned by a user that means all the duplicate nodes would
     *    also be owned by that user.
     * 3. node can not be owned by user & tenant in that case log.
     *
     * @method  _getUnionOfOwnerInfo
     * @param   {Array}    nodes   The list of nodes.
     * @return  {Object}   The union of owner info from list of provided nodes.
     */
    function _getUnionOfOwnerInfo(nodes) {
      var out = {};
      var tenantOwnedNodes = _.chain(nodes)
        .filter('_owner.tenantId').filter(_.identity).uniqBy('_owner.tenantId').value();
      var userOwnedNodes = _.chain(nodes)
        .map('_owner.user').flatten().filter(_.identity).uniqBy('sid').sortBy('sid').value();
      var groupOwnedNodes = _.chain(nodes)
        .map('_owner.groups').flatten().filter(_.identity).uniqBy('sid').sortBy('sid').value();

      if (userOwnedNodes.length) {
        _.assign(out, {
          user: userOwnedNodes,
          userNames: _.map(userOwnedNodes, 'userName'),
          userSids: _.map(userOwnedNodes, 'sid'),
          userTenantIds: _.map(userOwnedNodes, 'tenantId'),
        });
      }

      if (groupOwnedNodes.length) {
        _.assign(out, {
          groups: groupOwnedNodes,
          groupNames: _.map(groupOwnedNodes, 'groupName'),
          groupSids: _.map(groupOwnedNodes, 'sid'),
          groupTenantIds: _.flatten(_.map(groupOwnedNodes, 'tenantIds')),
        });
      }

      if (tenantOwnedNodes.length) {
        const ownerInfo = tenantOwnedNodes[0]._owner;
        // Using $injector since injecting this normally causes circular dependency error
        const ctx = $injector.get('NgIrisContextService')?.irisContext;

        _.assign(out, {
          tenant: ownerInfo.tenant,
          tenantId: ownerInfo.tenantId,
          tenantName: ownerInfo.tenantName,

          // re-calculating the source ownership because duplicate nodes are now
          // owned by the tenant.
          isSourceOwner: isEntityOwner(ctx, ownerInfo.tenantId),
        });
      }

      return out;
    }

    /**
     * Recursively compute the ancestors nodes for provided node by using
     * options.objParentHash map.
     *
     * NOTE: options.ancestors contains current node's ancestors but during
     *       syncing properties b/w duplicates nodes we need to know other nodes
     *       ancestors.
     *
     * @method   _getAncestorsFromObjHash
     * @param   {Object}   node           The node.
     * @param   {Object}   [options={}]   The options used to decorate nodes. {
     *    objParentHash: {Object}  Array of arrays.
     * }
     * @param   {Number}   [index=0]   The index.
     * @return  {Object}  The list of ancestors nodes.
     */
    function _getAncestorsFromObjHash(node, options, index) {
      var nodeParents;

      if (_.isEmpty(node)) {
        return [];
      }

      index = index || 0;
      nodeParents = _.get(
        options.objParentHash,
        '[' + node.protectionSource.id + '][' + index + ']'
      );

      return [].concat(
        _getAncestorsFromObjHash(nodeParents, options),
        nodeParents || []
      );
    }

    /**
     * Decorates a Tree root node and it's children with helpers & convenience
     * properties.
     *
     * @method   decorateJobTreeNode
     * @param   {object}   node           The Tree's root node on which to start
     *                                    walking.
     * @param   {object}   [options={}]   Options (TODO: Needs more info).
     * @param   {object}   [objHash={}]   The hash of objects (TODO: Needs more
     *                                    info).
     */
    function decorateJobTreeNode(node, options, objHash) {
      // If a node can be duplicated across root branches, defaulting this
      // rather than sharing a passed in hash will result in potential missed
      // duplicate nodes.
      objHash = objHash || {};

      // Provide default options in the event that options weren't provided.
      options = _.assign({
        ancestors: [],
        autoIncludeChildren: false,
        excludeChildren: false,
        job: {
          sourceIds: [],
          excludeSourceIds: [],
          vmTagIds: [],
          excludeVmTagIds: [],
        },
        selectedCounts: {},
        selectedNodes: {},
        totalCounts: {},
        isSourceIdMissingHash: {},
        rootEnvironment: node._environment,
      }, options);

      // decorate source properties in context of job
      _.assign(node, {
        _isAutoProtected: false,
        _isAncestorAutoProtected: false,
        _isTagAutoProtected: false,
        _isExcluded: false,
        _isAncestorExcluded: false,
        _isTagExcluded: false,
        _isAutoProtectedDescendant: false,
      });

      // decorate source specific properties
      decorateSource(node, options);

      // Determine if this node is a duplicate. Note: This approach to duplicate
      // tracking falls apart if node can be represented in the tree more than
      // twice. If such a case arises, objHash[id] should be made an array
      if (objHash[node.protectionSource.id]) {
        objHash[node.protectionSource.id]._isDuplicate =
          node._isDuplicate = true;
      } else {
        objHash[node.protectionSource.id] = node;
      }

      switch (true) {

        // node is a duplicate and its dupe has some type of exclusion. Exit
        // the switch early, as an exclusion always takes precedent.
        case node._isDuplicate &&
          !objHash[node.protectionSource.id]._isSelected &&
          (objHash[node.protectionSource.id]._isTagExcluded ||
          objHash[node.protectionSource.id]._isAncestorExcluded):
          break;

        // node is explicitly selected (included in sources)
        case (options.job.sourceIds || []).includes(node.protectionSource.id):
          node._isSelected = true;
          node._isAutoProtectedDescendant = true;
          node._inJob = true;
          options.isSourceIdMissingHash[node.protectionSource.id] = false;

          if (!node._isLeaf) {
            node._isAutoProtected = true;
            options.autoIncludeChildren = true;
            options.excludeChildren = false;
          }
          break;

        case (options.job.excludeSourceIds || []).includes(node.protectionSource.id):
          // Either a tag or ancestor was autoprotected, check for ancestor
          node._isAncestorAutoProtected = options.ancestors.some(
            function checkAncestorSelection(ancestor) {
              return ancestor._isSelected;
            }
          );
          node._isExcluded = true;
          node._isSelected = false;
          options.autoIncludeChildren = false;
          options.excludeChildren = true;
          break;

        // node has a parent that is excluded
        case options.excludeChildren:
          node._isAncestorAutoProtected = true;
          node._isAncestorExcluded = true;
          break;

        // node has a parent that is auto protected
        case options.autoIncludeChildren:
          node._isSelected = true;
          node._inJob = true;
          node._isAncestorAutoProtected = true;
          node._isAutoProtectedDescendant = true;
          break;
      }

      if (node._isSelected &&

        // node is not a duplicate, or its duplicate wasn't selected.
        (!node._isDuplicate || !objHash[node.protectionSource.id]._isSelected)) {
        options.selectedCounts[node._type] =
          ++options.selectedCounts[node._type] || 1;
      }

      if (!node._isDuplicate) {
        options.totalCounts[node._type] =
          ++options.totalCounts[node._type] || 1;
      }

      // now that node selection and counting is complete, if the node is a
      // duplicate it's necessary to ensure that the selection/auto protection
      // between duplicate nodes matches
      if (node._isDuplicate) {
        syncDuplicateNodeProperties(node, objHash[node.protectionSource.id]);
      }

      node._canAutoProtect = _canAutoProtect(node);

      // TODO: restore missing section from SourceService dealing with
      // aggregatedProtectedInfoVec

      // decorate descendants nodes
      decorateDescendants(decorateJobTreeNode, node, options, objHash);
    }

    /**
     * Determines if a given node can be Auto-protected.
     *
     * @method   _canAutoProtect
     * @param    {object}    node   The node to check.
     * @return   {boolean}   True if node can be Auto-protected.
     */
    function _canAutoProtect(node) {
      var canAutoProtect = !node._isLeaf && !node._isAncestorAutoProtected;
      return node.protectionSource.environment === 'kSQL' ?
        // No auto-protect for SQL entities on VMs, only hosts.
        canAutoProtect && node._hostEnvironment !== 'kVMware' :

        // Everything else.
        canAutoProtect;
    }

    /**
     * Decorate descendant nodes with provided callback like decorateSource or
     * decorateJobTreeNode.
     *
     * @method  decorateDescendants
     * @param   {function}   callback       The function used to decorate
     *                                      descendant nodes.
     * @param   {object}     node           The Tree's root node on which to
     *                                      start walking.
     * @param   {object}     [options={}]   Options (TODO: Needs more info).
     * @param   {object}     [objHash={}]   The hash of objects (TODO: Needs
     *                                      more info).
     */
    function decorateDescendants(callback, node, options, objHash) {
      options = options || {};
      objHash = objHash || {};

      // ancestors will be recursively passed to this very function as an array
      // of ancestors, and ids/strings will be pushed to these arrays.
      addDescendantDecorations(node);

      decorateAncestors(options.ancestors, node);

      if (node.nodes.length) {
        // Make our ancestors object a non-reference copy of itself: we don't
        // want to continue pushing ancestors to the same array by reference, as
        // we would then be pushing all nodes into the same array, and then we
        // would have an inaccurate list of ancestors as the tree gets
        // traversed.
        options.ancestors = options.ancestors.slice(0);

        // add the current node to our new ancestors lists.
        options.ancestors.push(node);

        node.nodes.forEach(function decorateDescendantNodes(children) {
          callback(children, options, objHash);
        });
      }
    }

    /**
     * Adds descendant decorations, placeholder arrays to be injected with
     * information about decescendants. NOTE: The node passed into this function
     * is updated by reference.
     *
     * @method   addDescendantDecorations
     * @param    {Object}   node   The node
     * @return   {Object}   The same node, updated by reference.
     */
    function addDescendantDecorations(node) {
      node._protectedDescendants = [];
      node._unprotectedDescendants = [];
      node._sqlHostDescendants = [];
      node._exchangeHostDescendants = [];
      node._inJobDescendants = [];
      node._quiesceDescendants = [];
      node._descendantNames = [];
      node._descendantTypes = [];
      node._descendantDataProtocols = [];
      node._descendantHostTypes = [];
      node._readWriteDescendants = [];
      node._dataProtectDescendants = [];
      node._agentInstalledDescendants = [];
      node._agentNotInstalledDescendants = [];
      /**
       * an array of arrays. this can be walked to determine descendant
       * protection/exclusion status
       */
      node._descendantTagSets = [];

      return node;
    }

    /**
     * Checks to see whether the node is registered as exchange application.
     *
     * @method   isExchangeRegistered
     * @param    {object}     node   The source node
     * @return   {boolean}    True if exchange registered, False otherwise.
     */
    function isExchangeRegistered(node) {
      return node._environment === 'kVMware' &&
        node.registrationInfo &&
        node.registrationInfo.environments &&
        node.registrationInfo.environments.includes('kExchange') &&
        node.registrationInfo.authenticationStatus === 'kFinished' &&
        !node.registrationInfo.authenticationErrorMessage;
    }

    /**
     * returns the supported data protocols list
     *
     * @method   getDataProtocols
     * @param    {onject}   node   The node
     * @return   {Object[]}        supported protocols list
     */
    function getDataProtocols(node) {
      var protocolList = [];

      switch(node._environment) {
        case 'kNetapp':
          // We need to test all three levels of the hierarchy because the
          // filesystem protocol is defined at all levels. Since most nodes
          // are leaves, we start there are work upwards
          if (node._envProtectionSource.volumeInfo) {
            protocolList = protocolList.concat(
              node._envProtectionSource.volumeInfo.dataProtocols
            );
          }
          if (node._envProtectionSource.vserverInfo) {
            protocolList = protocolList.concat(
              node._envProtectionSource.vserverInfo.dataProtocols
            );
          }
          if (node._envProtectionSource.clusterInfo) {
            protocolList = protocolList.concat(
              node._envProtectionSource.clusterInfo.dataProtocols
            );
          }
          break;
        case 'kGenericNas':
          protocolList =
            protocolList.concat(node._envProtectionSource.protocol);
          break;
        case 'kIsilon':
          // Protocol info only at mount point share level
          if (node._envProtectionSource.mountPoint) {
            protocolList = protocolList.concat(
              node._envProtectionSource.mountPoint.protocols
            );
          }
          break;
        case 'kFlashBlade':
          if (node._envProtectionSource.fileSystem) {
            protocolList = protocolList.concat(
              node._envProtectionSource.fileSystem.protocols
            );
          }
          break;
        case 'kGPFS':
          if (node._envProtectionSource.fileset) {
            protocolList = protocolList.concat(
              node._envProtectionSource.fileset.protocols
            );
          }
          break;
        case 'kElastifile':
          if (node._envProtectionSource.container) {
            protocolList = protocolList.concat(
              node._envProtectionSource.container.protocols
            );
          }
          break;
      }

      // protocolList list contains some protocol that are not yet supported by
      // us for backup thus we need filter out those protocols.
      protocolList = protocolList.filter(function eachProtocol(protocol) {
        return SUPPORTED_NAS_FILESYSTEM[node._environment].includes(protocol);
      });

      return protocolList;
    }

     /**
     * Add node owner info and keep a copy of that to each descendant nodes for
     * quick lookup.
     *
     * @method     addNodeOwnerInfo
     * @param      {object}   node    The node
     * @param      {object}   skipDescendants    If true then skip adding owner info for descendants.
     * @return     {boolean}  Node owner info object.
     */
    function addNodeOwnerInfo(node, skipDescendants) {
      const tenant = _.get(node.entityPermissionInfo, 'tenant', {});
      const isInferred = _.get(node.entityPermissionInfo, 'isInferred', false);

      // sorting the list of users by sid so they can be compared easily by
      // using _.isEqual fn.
      const users = _.chain(node.entityPermissionInfo).get('users', []).sortBy('sid').value();
      const groups = _.chain(node.entityPermissionInfo).get('groups', []).sortBy('sid').value();
      // Using $injector since injecting this normally causes circular dependency error
      const ctx = $injector.get('NgIrisContextService')?.irisContext;

      const nodeOwnerInfo = {
        tenant: tenant,
        tenantId: tenant.tenantId,
        tenantName: tenant.name,
        user: users,
        userSids: _.map(users, 'sid'),
        userNames: _.map(users, 'userName'),
        userTenantIds: _.map(users, 'tenantId'),
        groups: groups,
        groupSids: _.map(groups, 'sid'),
        groupNames: _.map(groups, 'groupName'),
        groupTenantIds: _.flatten(_.map(groups, 'tenantIds')),
        isSourceOwner: isEntityOwner(ctx, tenant.tenantId),
        isInferred: isInferred,
        hasAssignedAncestor: false,
        rootNodeOwnerTenantId: null,
      };

      // if node owner info is already there preserve that.
      node._owner = node._owner || nodeOwnerInfo;
      Object.assign(node._owner, _getUnionOfOwnerInfo([node, { _owner: nodeOwnerInfo }]));

      // keep the root node owner tenant used.
      if (node._isRootNode) {
        node._owner.rootNodeOwnerTenantId = tenant.tenantId;

        // this will be set true if source was originally registered by SP
        node._owner.isRegisteredBySp = _.get(node.entityPermissionInfo, 'isRegisteredBySp', undefined);
      }

      // if node is assigned to tenant or restricted user then keep a copy of
      // owner info for each descendant nodes for quick lookup.
      // also keep isRootNodeOwned property used for disabling root node
      // and there descendant nodes un-assignment used in c-source-group
      // canSelectNode function.
      // NOTE: keep the copy so that resultant object is serializable by
      //       JSON.stringify.
      if (!skipDescendants) {
        forEachNode(node.nodes, function eachDescendantNode(descendantNode) {
          if (!descendantNode._owner) {
            addNodeOwnerInfo(descendantNode, true);
          }

          // passing the root node tenantId to all its descendant.
          Object.assign(
            descendantNode._owner,
            _getUnionOfOwnerInfo([node, descendantNode]),
            {
              rootNodeOwnerTenantId: node._owner.rootNodeOwnerTenantId,
              isRegisteredBySp: node._owner.isRegisteredBySp
            },
          );
        });
      }
    }

    /**
     * Determines if node is a leaf.
     *
     * @method     isLeafNode
     * @param      {object}   node    The node
     * @return     {boolean}  True if leaf node, False otherwise.
     */
    function isLeafNode(node) {
      // WARNING: Logic changes to this Fn need to also be made in
      // SourceService.isLeafNode (private API) until that one is no longer in
      // use.
      var pSource = node.protectionSource;
      var environment = pSource.environment;
      var isHypervisor = ENV_GROUPS.hypervisor.includes(environment);
      var physicalEntitiesTest;
      var type;

      addEnvProtectionSource(node);

      type = node._envProtectionSource.type;

      // When a node is in a DB tree, Physical hosts & VMs are not considered to
      // be "leaf" entities because they have app entity children. In this
      // context, only the DB is considered a "leaf" entity.
      if (ENV_GROUPS.databaseSources.includes(node._rootEnvironment)) {
        return type === 'kDatabase';
      }

      // When this flag is enabled, we consider all kPhysical entities (except
      // the root kGroup) to be leaf nodes. Otherwise, only those in the
      // manifest are considered leaf nodes. This is ignored in the context of
      // DB _rootEnvironments (see preceding if block).
      physicalEntitiesTest = FEATURE_FLAGS.sqlCloneToSqlCluster ?
        type !== 'kGroup' :
          JOB_GROUPS.leafEntitiesForPhysicalJob.includes(type);

      return (isHypervisor && type === 'kVirtualMachine') ||
        (node._rootEnvironment === 'kAD' && type === 'kHost') ||
        (node._rootEnvironment === 'kExchange' &&
          EXCHANGE_GROUPS.exchangeLeafEntities.includes(type)) ||
        (environment === 'kPhysical' && physicalEntitiesTest) ||
        (environment === 'kPure' && type === 'kVolume') ||
        (environment === 'kNetapp' && type === 'kVolume') ||
        (environment === 'kFlashBlade' && type === 'kFileSystem') ||
        (environment === 'kGenericNas' && type === 'kHost') ||
        (environment === 'kIsilon' && type === 'kMountPoint') ||
        (environment === 'kAzure' && type === 'kVirtualMachine') ||
        (environment === 'kAWS' && type === 'kEC2Instance') ||
        (environment === 'kAWS' && type === 'kRDSInstance') ||
        (environment === 'kAWS' && type === 'kAuroraCluster') ||
        (environment === 'kGCP' && type === 'kVirtualMachine') ||
        (environment === 'kGPFS' && type === 'kFileset') ||
        (ENV_GROUPS.office365.includes(environment) &&
          _isOffice365LeafEntity(type, node)) ||
        (environment === 'kKubernetes' && type === 'kNamespace') ||
        (environment === 'kElastifile' && type === 'kContainer') ||
        (environment === 'kNimble' && type === 'kVolume') ||
        type === 'kTag';
    }

    /**
     * Determines if the given entity is an office 365 leaf entity.
     *
     * @method   _isOffice365LeafEntity
     * @param    {string}    type   Specifies the entity type
     * @param    {string}    node   Specifies the selected node
     * @return   {boolean}   True if the type is a leaf entity, false,
     *                       otherwise.
     */
    function _isOffice365LeafEntity(type, node) {
      // Office365 leaf entities include 'kSite' which can act as both leaf and
      // an internal node, hence an explicit check on the count of children is
      // needed.
      return (OFFICE365_GROUPS.office365Leaves.includes(type) &&
          !node.nodes.length) ||
        // To tackle scale issues, when the iris_exec thresholds for the
        // count of child entities supported is crossed, actual leaf entities,
        // namely, kMailbox(deprecated), kUser, kSite & kGroup are not
        // returned.
        // In above scenarios, their respective containers, namely,
        // kOutlook(deprecated), kUsers, kSites & kGroups will have empty
        // child nodes and hence are to be considered as leaves.
        //
        // Refer OFFICE365_GROUPS for EH details.
        //
        // iris_exec threshold flags:
        // kMailbox - iris_max_outlook_mailbox_supported_count (deprecated)
        // kUser    - iris_max_office365_users_supported_count
        // kGroup   - iris_max_office365_groups_supported_count
        // kSites   - iris_max_office365_sites_supported_count
        (OFFICE365_GROUPS.office365EntityContainers.includes(type) &&
          !node.nodes.length);
    }

    /**
     * Filter list of volumes for either LVM or non-LVM as specified.
     * volume guid will be present for LVM type only
     *
     * @method     filterVolumes
     * @param      {Object}     node    the node
     * @param      {string}     type    filter by 'lvm' or 'nonlvm' volume types
     * @return     {Object[]}   array of filtered volumes
     */
    function filterVolumes(node, type) {
      return (node._envProtectionSource.volumes || []).filter(
        function filterVolumesFn(volume) {
          switch (type) {
            case "shared":
              return volume.isSharedVolume;
            case "lvm":
              return !!volume.guid;
            default:
              return !volume.guid && !volume.isSharedVolume;
          }
        }
      );
    }

    /**
     * returns the logicalSize of a node. If not a leaf node, returns aggregate
     * logical size of ancestor nodes.
     *
     * @method   getNodeLogicalSize
     * @param    {object}   node   The node
     * @return   {number}   The node logical size.
     */
    function getNodeLogicalSize(node) {
      var logicalSize = 0;

      // applicationServers uses logicalSize and registrationInfo
      // uses logicalSizeBytes
      if (node.logicalSize || node.logicalSizeBytes) {
        logicalSize = node.logicalSize || node.logicalSizeBytes;
      } else if (node.stats) {
        // Check for stats object and use it to calculate logical size first.
        logicalSize +=
          _.get(node,'stats.protectedSize', 0) +
          _.get(node,'stats.unprotectedSize', 0);
      } else {
        // This may need adjustments to find the correct environment for
        // display. For instance, for a VM tree, it might be necessary to
        // itterate and find node.protectionSources[n].environment = 'kVMware',
        // as it might also have 'kSQL' environment. Yet to be determined.
        logicalSize +=
          _.get(node, 'protectedSourcesSummary[0].totalLogicalSize', 0) +
          _.get(node, 'unprotectedSourcesSummary[0].totalLogicalSize', 0);
      }

      return logicalSize;
    }

    /**
     * Decorates a node with convenience properties (fka decorateLeafNode).
     *
     * @method   decorateNode
     * @param    {object}   node   The node
     * @return   {object}   The decorated node (also updated by reference)
     */
    function decorateNode(node) {
      if (!node) { return node; }

      // Ensure convenience _envProtectionSource object is present
      addEnvProtectionSource(node);

      // by default allow all nodes to be selectable and if you want to restrict
      // then programmability set this to false, eg. used for AWS/Azure sources
      // to restrict selection if agent is not installed.
      node._isSelectable = true;
      node.registrationInfo = node.registrationInfo || {};
      node.protectedSourcesSummary = node.protectedSourcesSummary || [];
      node.unprotectedSourcesSummary = node.unprotectedSourcesSummary || [];

      // does the node(and source) support auto protection using tags
      node._isTagProtectionSupported =
           ENV_GROUPS.taggable.includes(node._rootEnvironment);

      if (node.registrationInfo) {
        node._isConnectionError = !!node.registrationInfo.refreshErrorMessage;

        // Backend defaults are not being propagated through Iris-Backend.
        // Usually doesn't matter, but in this case it does. The proper fix is
        // in Iris-backend but that is a heavy lift, so a front-end fix is the
        // immediate solution.
        node.registrationInfo.authenticationStatus =
          node.registrationInfo.authenticationStatus || 'kFinished';

        // Determine if registration has been initialized.
        node._isRegistering =
          node.registrationInfo.authenticationStatus !== 'kFinished';

        // Determine if registration is complete for the host
        node._isRegistered = !node._isRegistering;

        // Determine if there was an error in registration verification.
        node._isRegError = !!node.registrationInfo.authenticationErrorMessage;

        // Determine if there are any warning during vcs cluster registration.
        node._isVcsRegistrationError =
          _.get(node, 'protectionSource.environment') === 'kPhysical' &&
          _.get(
            node, 'protectionSource.physicalProtectionSource.type'
          ) === 'kOracleAPCluster' &&
          !!node.registrationInfo.warningMessages;


        node._failedHealthChecks =
          PubSourceServiceFormatter.hasNodeFailedHealthChecks(node);
      }

      // Identify and tag MS SQL Cluster.
      node._isSqlCluster =
        node._environment === 'kPhysical' && node._type === 'kWindowsCluster';

      // Identify as a SQL entity. Not all SQL entities are hosts, so we're
      // doing this check here instead wiht other SQL checks lower.
      node._isSqlEntity = node._environment === 'kSQL';

      // Identify Oracle entity.
      node._isOracleSource = node._environment === 'kOracle';

      // Specifies whether the database is a standby within DataGuard
      // configuration.
      node._isOracleDGStandby = _.get(node.protectionSource,
        'oracleProtectionSource.dataGuardInfo.role') === 'kStandby';

      // Specifies whether the database is a Container DB having Pluggable DBs.
      // The CDB container entity if present will always have exactly 1 seed
      // PDB(PDB$SEED) & 0 or more user created PDBs.
      // Hence the 'pluggableDatabaseInfoList' will always be an array of
      // length 1 or more.
      node._isOracleCDB = node._isOracleSource && _.get(
        node.protectionSource.oracleProtectionSource,
        'containerDatabaseInfo.pluggableDatabaseInfoList', []).length > 0;

      // Holds the string representation for the Oracle database configuration
      // type.
      node._oracleDBConfigTypeString = NgOracleUtilityService
        .getDatabaseConfigTypeString(node);

      // Holds the string representation for the database in case of DataGuard
      // standby.
      node._oracleDataGuardStandbyTypeString = NgOracleUtilityService
        .getDataGuardStandbyTypeString(node);

      // TODO(Tauseef): Move the property generator specific to any environment
      // to a decorator.
      // Identify O365 Sources.
      node._isO365Source = node._environment === 'kO365';
      node._oneDriveSize = _.get(node.protectionSource,
        'office365ProtectionSource.userInfo.oneDriveSize');
      node._mailboxSize = _.get(node.protectionSource,
        'office365ProtectionSource.userInfo.mailboxSize');

      // TODO(Tauseef): This may change in future.
      node._sharePointSiteSize = node.logicalSize;

      // Identify O365 Outlook Mailbox.
      node._isO365Mailbox = node._type === 'kMailbox';

      // Identify O365 SharePoint Site.
      node._isSharePointSite = node._type === 'kSite';

      // Identify O365 Group.
      node._isO365Group = node._type === 'kGroup';

      // Identify if the site is corresponding to a group.
      node._isGroupSite = _.get(node.protectionSource,
        'office365ProtectionSource.siteInfo.isGroupSite')

      // Identify if the site is corresponding to a team.
      node._isTeamSite = _.get(node.protectionSource,
        'office365ProtectionSource.siteInfo.isTeamSite')

      // Identify if the site is corresponding to a private channel of a team.
      node._isPrivateChannelSite = _.get(node.protectionSource,
        'office365ProtectionSource.siteInfo.isPrivateChannelSite')

      // Assign the O365 Outlook mailbox username and unique identifier name.
      if (node._isO365Mailbox) {
        var source = node._envProtectionSource;
        if (source.primarySMTPAddress) {
          // Specifies the Mailbox User name.
          node._o365MailboxUsername =
            $filter('o365Username')(source.primarySMTPAddress);

          // Specifies the Unique name for the mailbox for identifying it
          // correctly in case the mailbox username and mailbox name aren't
          // same.
          node._o365MailboxIdentifier =
            $filter('o365MailboxIdentifier')(source.name,
              node._o365MailboxUsername);
        }
      }

      if (node._isSharePointSite) {
        node._subSitesCount = node.nodes ? node.nodes.length : 0;
      }

      // Identify as an Application Host of any kind.
      node._isApplicationHost =
        !!(node.registrationInfo && node.registrationInfo.environments);

      // This node is registered as an App Host, set some status flags.
      if (node._isApplicationHost) {
        node._isSqlHost = node.registrationInfo.environments.includes('kSQL');
        node._isOracleHost =
          node.registrationInfo.environments.includes('kOracle');
        node._isExchangeHost = isExchangeRegistered(node);
        node._isActiveDirectoryHost =
          node.registrationInfo.environments.includes('kAD');

        /*
         * True if the intersection of these two lists has length. False
         * otherwise. This allows easy updating via the
         * ENV_GROUPS.databaseSources constant as new DB adapters are added.
         */
        node._isDatabaseHost =
          !!_.intersection(
            ENV_GROUPS.databaseSources,
            node.registrationInfo.environments
          ).length;

        // Determine if there was an error in SQL registration verification.
        node._isApplicationRegError =
          !!node.registrationInfo.authenticationErrorMessage;

        // Determine if a SQL/App registration has been initialized.
        if (angular.isDefined(node.registrationInfo.authenticationStatus)) {
          node._appAuthenticationStatus =
            node.registrationInfo.authenticationStatus;
          node._isApplicationRegistering =
            node.registrationInfo.authenticationStatus !== 'kFinished';
        }

        // Add SQL Host credentials if available and not configured with a
        // persistentAgent.
        // NOTE: perhaps connectorParams maps to accessInfo.. credentials is
        // no where to be found.
        node._credentials = !node.registrationInfo.usesPersistentAgent ?
          node.registrationInfo.connectorParams &&
          node.registrationInfo.connectorParams.credentials :
          undefined;

        if (FEATURE_FLAGS.protectSqlAag) {
          node._aags = Object.values(
            PubSourceServiceFormatter.extractAagInfo(node)
          );
        }
      }

      node._isQuiesceCompatible = isQuiesceCompatible(node);

      // For ENV_GROUPS supporting agents, add agent related decorations
      if (ENV_GROUPS.usesAgent.includes(node._environment)) {
        _decorateAgents(node);
      }

      // If Physical, decorate with additional properties.
      if (node._environment === 'kPhysical') {
        _decoratePhysicalLeafNode(node);
      }

      if (node._isSqlHost) {
        // Determine if a SQL/App registration has been initialized.
        var registeredAppInfo =
          _.get(node, 'registrationInfo.registeredAppsInfo');
        if (registeredAppInfo) {
          var sqlAppInfo = registeredAppInfo.find(function findSqlApp(app) {
            return app.environment === 'kSQL';
          });

          if (sqlAppInfo) {
            node._appAuthenticationStatus = sqlAppInfo.authenticationStatus;
            node._isApplicationRegistering =
              sqlAppInfo.authenticationStatus !== 'kFinished';
          }
        }

        node.applicationNodes =
          (node.applicationNodes || []).map(function eachAppNode(appNode) {
            return decorateSource(appNode, {
              // Inherit the parent's rootEnvironment, since we don't have the
              // broader `options` here.
              rootEnvironment: node._rootEnvironment,
            });
          });

        // QUESTION: What is this filter for?
        node._sqlApplicationNodes = $filter('filter')(
          node.applicationNodes,
          {
            protectionSource: {
              sqlProtectionSource: {
                name: '!!'
              }
            }
          }
        );
      }

      if (node._isLeaf && node._environment === 'kVMware') {
        node._isAgentInstalled = !!node.protectionSource.
          vmWareProtectionSource.hasPersistentAgent;

        node._isAgentNotInstalled = !node.protectionSource.
          vmWareProtectionSource.hasPersistentAgent;
      }

      node._isAnyError = node._isApplicationRegError || node._isConnectionError;

      return node;
    }

    /**
     * Decorates a node with it's same-level protection status convenience
     * properties.
     *
     * @method   nodeProtectionStatusDecorator
     * @param    {object}   node   The node
     * @return   {object}   Copy of the input with decorators added.
     */
    function nodeProtectionStatusDecorator(node) {
      /**
       * Caution: Changes made in this Function will potentially need to also be
       * made in nodeProtectionStatusDecorator_preFilestream until its removed
       * after Filesrteam goes GA.
       */
      // Feature is off? Bypass this and go to the older formatter Fn instead.
      if (!FEATURE_FLAGS.enableFilestream) {
        return nodeProtectionStatusDecorator_preFilestream(node);
      }

      var DB_SOURCES = ENV_GROUPS.databaseSources;

      /**
       * Map of protection jobs on this node.
       *
       * @type  {object}
       */
      var protectedHash = _generateAggregatedProtectedInfoHash(node);

      /**
       * This is the environment of the current node.
       *
       * @type  {string}
       */
      var nodeEnvironment = node.protectionSource.environment;

      /**
       * The environment of the single root node. This will be the container,
       * vcenter, hyperv, etc.
       *
       * @type  {string}
       */
      var nodeRootEnvironment = node._rootEnvironment;

      /**
       * The map entry from protectedHash for this specific node's root
       * Environment.
       *
       * @type  {object}
       */
      var thisTypeHash = protectedHash[nodeRootEnvironment] || {};
      var isVMProtected =
        !!_.get(protectedHash, 'kVMware.protectedLeavesCount');

      var isSqlProtected =
        !!_.get(protectedHash, 'kSQL.protectedLeavesCount');

      var isOracleProtected =
        !!_.get(protectedHash, 'kOracle.protectedLeavesCount');

      var isBlockProtected =
        !!_.get(protectedHash, 'kPhysical.protectedLeavesCount');

      var isPhysicalFileProtected =
        !!_.get(protectedHash, 'kPhysicalFiles.protectedLeavesCount');

      /**
       * Represents if this node is fully or partially protected. A partially
       * protected node example is a MS SQL Instance with some, but not all of
       * it's DB children protected.
       *
       * @type  {boolean}
       */
      var isPartiallyProtected = !!thisTypeHash.protectedLeavesCount &&
        thisTypeHash.protectedLeavesCount < thisTypeHash.leavesCount;

      /**
       * Is protected by any job of any kind?
       *
       * @type  {boolean}
       */
      var isProtected;

      /**
       * Is protected by any DB job type, SQL, Oracle, etc?
       *
       * @type  {boolean}
       */
      var isDbProtected;

      /**
       * Is this node protected by a SQL FCBT Job?
       *
       * @type  {boolean}
       */
      var isSqlFileProtected;

      /**
       * Is this node protected by a SQL CBT Job?
       *
       * @type  {boolean}
       */
      var isSqlVolumeProtected;

      // Is SQL protected, AND neither block, nor vm protected (both volume type
      // jobs).
      isSqlFileProtected = node._isSqlFileProtected ||
        (isSqlProtected && !(isBlockProtected || isVMProtected));

      // Is SQL protected, AND one of either phsycail block, or vm protected.
      // This is always a 2 part determination for SQL CBT jobs.
      isSqlVolumeProtected = node._isSqlVolumeProtected ||
        (isSqlProtected && (isBlockProtected || isVMProtected));

      // True if protected by any DB job
      isDbProtected = DB_SOURCES.some(
        function eachDBSourceType(type) {
          return !!_.get(protectedHash, type+'.protectedLeavesCount');
        }
      );

      isProtected = node._isProtected ||
        (DB_SOURCES.includes(nodeEnvironment) ?
          // If any DB source has a protection job, it's protected.
          isDbProtected :

          // Otherwise, true if entity has any protection jobs, but not DB jobs.
          _isProtectedByAnyJobType(node));

      isProtected = isProtected && !isPartiallyProtected;

      return _.assign(node, {
        _isProtected: isProtected,
        _isPartiallyProtected: isPartiallyProtected,
        _isDbProtected: isDbProtected,
        _isSqlProtected: isSqlProtected,
        _isSqlFileProtected: isSqlFileProtected,
        _isSqlVolumeProtected: isSqlVolumeProtected,
        _isOracleProtected: isOracleProtected,
        _isBlockProtected: isBlockProtected,
        _isPhysicalFileProtected: isPhysicalFileProtected,

        /*
         * This section is based on the matrix at https://goo.gl/JDm6L4
         */
        // TODO (spencer): Remove this first predicate once VM FCBT is
        // supported.
        _canSqlFileProtect: node._hostEnvironment === 'kPhysical' && (
          // Hosts: For a given SQL Server Host, you can create ... one or more
          // file-based jobs.
          (nodeRootEnvironment === 'kSQL' && nodeEnvironment === 'kPhysical') ||

          // App Entities: A given database still cannot be part of multiple
          // jobs, whether this is volume or file-based.
          (nodeEnvironment === 'kSQL' && !isProtected)
        ),

        // For a given SQL Server Host, you can create at most one volume-based
        // job ... and a given database still cannot be part of multiple jobs.
        _canSqlVolumeProtect: nodeEnvironment === 'kSQL' ?
          // App Entities
          !isProtected :

          // Hosts
          !isSqlVolumeProtected && nodeRootEnvironment === 'kSQL',

        // True if not already protected by an Oracle job and is a valid Oracle
        // source.
        _canProtectOracle: !isOracleProtected &&
          ENV_GROUPS.oracleSources.includes(nodeRootEnvironment),

        _isOlderOracle: nodeRootEnvironment === 'kOracle' &&
          _isOlderOracle(node),

        _canBlockProtect: !isBlockProtected,

        // `_canProtect` logic is far too complex for this decorator and depends
        // on unavailable external info. Must be done at controller level.
      });
    }

    /**
     * Decorates a node with it's same-level protection status convenience
     * properties.
     *
     * @method   nodeProtectionStatusDecorator_preFilestream
     * @param    {object}   node   The node
     * @return   {object}   Copy of the input with decorators added.
     */
    function nodeProtectionStatusDecorator_preFilestream(node) {
      var DB_SOURCES = ENV_GROUPS.databaseSources;
      var protectedHash = _generateAggregatedProtectedInfoHash(node);
      var nodeEnvironment = node.protectionSource.environment;
      var noderootEnvironment = node._rootEnvironment;
      var thisTypeHash = protectedHash[noderootEnvironment] || {};
      var isSqlProtected =
        !!_.get(protectedHash, 'kSQL.protectedLeavesCount');

      var isOracleProtected =
        !!_.get(protectedHash, 'kOracle.protectedLeavesCount');

      var isBlockProtected =
        !!_.get(protectedHash, 'kPhysical.protectedLeavesCount');

      var isPhysicalFileProtected =
        !!_.get(protectedHash, 'kPhysicalFiles.protectedLeavesCount');

      var isPartiallyProtected = !!thisTypeHash.protectedLeavesCount &&
        thisTypeHash.protectedLeavesCount < thisTypeHash.leavesCount;

      var isProtected;
      var isDbProtected;
      var isSqlFileProtected;

      isSqlFileProtected =
        noderootEnvironment === 'kSQL' &&
        (node._isSqlFileProtected ||
          (isSqlProtected && !isBlockProtected));

      // True if protected by any DB job
      isDbProtected = DB_SOURCES.some(
        function eachDBSourceType(type) {
          return !!_.get(protectedHash, type+'.protectedLeavesCount');
        }
      );

      isProtected = node._isProtected ||
        (DB_SOURCES.includes(nodeEnvironment) ?
          // If any DB source has a protection job, it's protected.
          isDbProtected :

          // Otherwise, true if entity has any protection jobs, but not DB jobs.
          _isProtectedByAnyJobType(node));

      isProtected = isProtected && !isPartiallyProtected;

      return _.assign(node, {
        _isProtected: isProtected,
        _isPartiallyProtected: isPartiallyProtected,
        _isDbProtected: isDbProtected,
        _isSqlProtected: isSqlProtected,
        _isSqlFileProtected: isSqlFileProtected,
        _isOracleProtected: isOracleProtected,
        _isBlockProtected: isBlockProtected,
        _isPhysicalFileProtected: isPhysicalFileProtected,

        /*
         * This section is based on the matrix at https://goo.gl/JDm6L4
         */
        _canSqlFileProtect: !isSqlProtected &&
          // Working env is SQL &&
          noderootEnvironment === 'kSQL' &&

          // nodeEnv is SQL or Physical
          ['kSQL', 'kPhysical'].includes(nodeEnvironment),

        _canSqlVolumeProtect:
          // If this node is physical
          nodeEnvironment === 'kPhysical' ?
            // True if it's not already protected by a SQL job nor block
            // (physical) job.
            (!isSqlProtected && !isBlockProtected) :

            // Otherwise, true If it's a SQL host (no meaningful restrictions)
            ENV_GROUPS.sqlHosts.includes(nodeEnvironment),

        // True if not already protected by an Oracle job and is a valid Oracle
        // source.
        _canProtectOracle: !isOracleProtected &&
          ENV_GROUPS.oracleSources.includes(noderootEnvironment),

        _isOlderOracle: noderootEnvironment === 'kOracle' &&
          _isOlderOracle(node),

        _canBlockProtect: !isBlockProtected,

        // `_canProtect` logic is far too complex for this decorator and depends
        // on unavailable external info. Must be done at controller level.
      });
    }

    /**
     * Indicates if the node is running an older oracle version (< 11.2.0.3)
     *
     * @method   _isOlderOracle
     * @return   {boolean}   True only if the node is running
     * older oracle version (< 11.2.0.3), False otherwise.
     */
    function _isOlderOracle(node) {
      if (!node._envProtectionSource.version) {
        return false;
      }
      var version = node._envProtectionSource.version;
      version = parseInt(version.split('.').join(''), 10);
      return (version < 11203);
    }


    /**
     * Helper to determine if a node is protected by any job type.
     *
     * @method   _isProtectedByAnyJobType
     * @param    {object}    node   The node to inspect.
     * @return   {boolean}   True if protected at all. False otherwise.
     */
    function _isProtectedByAnyJobType(node) {
      // For the public API response, use the statsByEnv instead of
      // prortectedSourcesSummary
      if (_.some(node.statsByEnv, 'protectedCount')) {
        return true;
      }

      return (node.protectedSourcesSummary || []).some(
        function seekProtection(sourceSummary) {
          return !!sourceSummary.leavesCount;
        }
      );
    }

    /**
     * Generates a lookup map by PJ type from the protectedSourcesSummary &
     * uprotectedSourcesSummary arrays on an protectionSOurce node.
     *
     * @example {
     *   <kEnv>: {
     *     environment: '<kEnv>',
     *     leavesCount: int,
     *     protectedLeavesCount: int,
     *     protectedLogicalSize: int,
     *     totalLogicalSize: int,
     *     unprotectedLeavesCount: int,
     *     unprotectedLogicalSize: int,
     *   },
     *   <kEnv2>: { ... }
     * }
     *
     * @method   _generateAggregatedProtectedInfoHash
     * @param    {object}   node   The node
     * @return   {object}   The hash map by PJ type
     */
    function _generateAggregatedProtectedInfoHash(node) {
      // For the public API response, we will use the statsByEnv instead of
      // publicSourceSummary. The statsByEnv will be transformed to represent
      // the protected hash object generated while parsing the private response.
      if (node.statsByEnv && node.statsByEnv.length) {
        return _.keyBy(node.statsByEnv.map(function getStatsByEnv(envType) {
            envType.protectedLeavesCount = envType.protectedCount;
            envType.protectedLogicalSize = envType.protectedSize;
            envType.unprotectedLeavesCount = envType.unprotectedCount;
            envType.unprotectedLogicalSize = envType.unprotectedSize;
            envType.leavesCount =
              envType.protectedCount + envType.unprotectedCount;
            return envType;
        }), 'environment');
      }

      var outputHash = (node.protectedSourcesSummary || []).reduce(
        function sourceSummaryReducer(protectedHash, sourceSummary) {
          protectedHash[sourceSummary.environment] = _.assign({
            leavesCount: 0,
            totalLogicalSize: 0,
            protectedLogicalSize: sourceSummary.totalLogicalSize || 0,
            protectedLeavesCount: sourceSummary.leavesCount || 0,
          }, sourceSummary);

          return protectedHash;
        },
        {}
      );

      return (node.unprotectedSourcesSummary || []).reduce(
        function sourceSummaryReducer2(unprotectedHash, sourceSummary) {
          var thisSummary =
            unprotectedHash[sourceSummary.environment] = _.assign(
              {
                unprotectedLogicalSize: sourceSummary.totalLogicalSize || 0,
                unprotectedLeavesCount: sourceSummary.leavesCount || 0,
              },
              unprotectedHash[sourceSummary.environment]
            );

          thisSummary.leavesCount += thisSummary.unprotectedLeavesCount;
          thisSummary.totalLogicalSize += thisSummary.unprotectedLogicalSize;

          return unprotectedHash;
        },
        outputHash
      );
    }

    /**
     * Decorates a node with Agent related information.
     *
     * @method   _decorateAgents
     * @param    {object}   node   The node
     * @return   {object}   The doecrated node (also updated by reference)
     */
    function _decorateAgents(node) {
      var agents;

      // Stub `agents` if missing.
      node._envProtectionSource.agents = node._envProtectionSource.agents || [];

      agents = node._envProtectionSource.agents;

      // Assign first child agent to _agent property. This is primarily for
      // Physical Servers, which have only a single agent.
      node._agent = agents[0] || {};

      agents.forEach(
        function updateStatus(agent) {
          // Set _isAnyError and _statusKey for each agent.
          agent._isAnyError = false;
          agent._statusKey = 'healthy';
          if (!!_.get(agent, 'refreshErrorMessage')) {
            agent._statusKey = 'refreshError';
            agent._isAnyError = true;
          } else if (!!agent.verificationError ||
            !!_.get(agent, 'registrationInfo.authenticationErrorMessage')) {
            agent._statusKey = 'registrationError';
            agent._isAnyError = true;
          }

          // Helper to determine if this agent is upgradable.
          // TODO: Once we support agent upgrades on VMs, remove this first
          // expression. No ETA for that, though.
          agent._isUpgradable = node._environment !== 'kVMware' &&
            agent.upgradability === 'kUpgradable';
        }
      );

      // Iterate through the agents and look for an agent in special
      // condition. If found, assign this agent as the _agent in order to
      // surface relevant messaging. This is primarily for clusters.
      agents.some(
        function checkAgentsForSpecialConditions(agent) {
          node._isUpgrading = false;
          if (['kAccepted', 'kStarted'].includes(node._agent.upgradeStatus)) {
            // Agent is upgrading
            // Overwrite _agent with the cluster node that is upgrading
            node._agent = agent;
            node._isUpgrading = true;
            return true;
          } else if (agent && agent.upgradeStatusMessage) {
            // Agent is in error
            // Overwrite _agent with the cluster node that is in error
            node._agent = agent;
            return true;
          }
        }
      );

      return node;
    }

    /**
     * Decorates a physical protectionSource.
     * NOTE: Updates the passed in object by reference.
     *
     * @method   _decoratePhysicalLeafNode
     * @param    {object}   node   The node
     * @return   {object}   The decorated node (also updated by reference)
     */
    function _decoratePhysicalLeafNode(node) {

      if (!node || node.protectionSource.environment !== 'kPhysical') {
        return node;
      }

      node._hostType = node._envProtectionSource.hostType;

      // Is Windows Host
      node._isWindows =
        node.protectionSource.physicalProtectionSource.hostType === 'kWindows';

      // Is Linux Host
      node._isLinux =
        node.protectionSource.physicalProtectionSource.hostType === 'kLinux';

      // Is AIX Host
      node._isAix =
        node.protectionSource.physicalProtectionSource.hostType === 'kAix';
      // Append Other hosts here.

      node._isVcsCluster = _.get(node,
        'protectionSource.physicalProtectionSource.type') === 'kOracleAPCluster'
        && !!_.get(node,
          'protectionSource.physicalProtectionSource.vcsVersion');

      return node;
    }

    /**
     * Determines if a given entity supports app aware backups.
     *
     * @method   isQuiesceCompatible
     * @param    {Object}    node   Node object from server
     * @return   {Boolean}   If this node is Quiesce compatible
     */
    function isQuiesceCompatible(node) {

      // Ensure _envProtectionSource is available.
      addEnvProtectionSource(node);

      // If we have a vmware tools property...
      return !!node._envProtectionSource.toolsRunningStatus &&

        // Check if vmware tools is running.
        node._envProtectionSource.toolsRunningStatus === 'kGuestToolsRunning';
    }

    /**
     * Returns the number of leaves for a non-leaf source node
     *
     * @method   getLeavesCount
     * @param    {object}   node   A source tree node
     * @return   {number}   The leaves count.
     */
    function getLeavesCount(node) {
      var aggregateLeavesCount = 0;

      if (node._isLeaf) { return aggregateLeavesCount; }

      // This may need adjustments to find the correct environment for
      // display. For instance, for a VM tree, it might be necessary to
      // iterate and find node.protectionSources[n].environment = 'kVMware',
      // as it might also have 'kSQL' environment. Yet to be determined.
      aggregateLeavesCount +=
        _.get(node, 'protectedSourcesSummary[0].leavesCount', 0) +
        _.get(node, 'unprotectedSourcesSummary[0].leavesCount', 0);

      return aggregateLeavesCount;
    }

    /**
     * Detects if an entity has connection state problems.
     *
     * @method   entityHasConnectionStateProblem
     * @param    {object}    node   The node to detect connection problems on.
     * @return   {boolean}   True if source has known connection state problem,
     *                       false otherwise.
     */
    function entityHasConnectionStateProblem(node) {

      addEnvProtectionSource(node);

      switch (node.protectionSource.environment) {

        case 'kVMware':
          // For VM's an explicit 'connectionState' value is provided. If
          // connectionState === kConnected then all is good. Any other value
          // indicates some type of connectionState problem.
          return node._envProtectionSource.connectionState !== 'kConnected' &&
            ['kHostSystem', 'kVirtualMachine'].includes(
              node._envProtectionSource.type);


        case 'kPhysical':
          // For physical servers there is not an explicit 'connectionState'
          // value provided. connectionState should be inferred from the
          // presence of a refereshErrorMessage.
          return node._envProtectionSource.type !== 'kHostGroup' &&
            !!node.registrationInfo &&
            !!node.registrationInfo.refreshErrorMessage;
      }

      return false;
    }

    /**
     * Adds an _envProtectionSource decoration to a node if its not already
     * present.
     *
     * @method   addEnvProtectionSource
     * @param    {object}   node   The node
     * @return   {object}   the node, decorated with _envProtectionSource.
     */
    function addEnvProtectionSource(node) {
      var sourceKey = SOURCE_KEYS[node.protectionSource.environment];

      // TODO (Saleh): Remove this block before 6.1 release and after
      // backend is fixed. Backend should be return hyperFlexProtectionSource
      // property which it is currently not doing.
      if (node.protectionSource.environment === 'kHyperFlex') {
        node._envProtectionSource = _.assign({}, {
          type: 'kHyperFlex',
        });

        return node;
      }

      node._envProtectionSource = node.protectionSource[sourceKey] ?
        node.protectionSource[sourceKey] : {};

      return node;
    }

    /**
     * Decorates ancestors[] entities/sources with information derived from the
     * current descendant node.
     *
     * @method   decorateAncestors
     * @param    {array}    ancestors   The ancestor nodes
     * @param    {object}   node        The node, a descendant of provided
     *                                  ancestors
     */
    function decorateAncestors(ancestors, node) {
      // update ancestors metadata if there are any
      if (!ancestors || !ancestors.length) { return; }

      var protectionStatusKey =
        (node._isProtected || node._isSqlProtected) ?
          '_protectedDescendants' : '_unprotectedDescendants';

      ancestors.forEach(function updateAncestorsFn(ancestor, index) {

        ancestor[protectionStatusKey].push(node.protectionSource.id);
        ancestor._descendantNames.push(node._nameLowerCase);
        ancestor._descendantTypes.push(node._type);

        if (node._isTagProtectionSupported &&
          Array.isArray(node._envProtectionSource.tagAttributes)) {
          ancestor._descendantTagSets.push(
            node._envProtectionSource.tagAttributes.map(
              function loopTags(tag) {
                return tag.id;
              }
            )
          );
        }

        if (node._hostType) {
          ancestor._descendantHostTypes =
            _.union(ancestor._descendantHostTypes, [node._hostType]);
        }

        if (node._dataProtocols.length) {
          ancestor._descendantDataProtocols =
            _.union(ancestor._descendantDataProtocols, node._dataProtocols);
        }

        if (node._inJob) {
          ancestor._inJobDescendants.push(node.protectionSource.id);
        }

        if (node._isSqlHost) {
          // update the ancestors to reflect the presence of a child SQL Server
          ancestor._sqlHostDescendants.push(node.protectionSource.id);
        }

        if (node._isExchangeHost) {
          // update the ancestors to reflect the presence of a child Exchange
          // Host
          ancestor._exchangeHostDescendants.push(node.protectionSource.id);
        }

        if (node._isQuiesceCompatible) {
          ancestor._quiesceDescendants.push(node.protectionSource.id);
        }

        // keep descendant nodes owner info with ancestor's nodes for quick
        // lookup later
        if (!ancestor._descendantNodeOwnersMap) {
          ancestor._descendantNodeOwnersMap = {};
        }
        ancestor._descendantNodeOwnersMap[node.protectionSource.id] = node._owner;

        if (ancestor.entityPermissionInfo) {
          const assignedAncestorOwnerInfo = _getUnionOfOwnerInfo([node, ancestor]);

          // node inheriting the ownerInfo from its assigned ancestor.
          Object.assign(node._owner, assignedAncestorOwnerInfo);
          if (node._isLeaf) {
            node._owner.hasAssignedAncestor = true;
          }

           // ancestors after the assigned one are inheriting ownerInfo from assigned ancestor.
           // ancestors will contains the nodes from top to bottom w/o the node which is currently being decorated.
          for (let innerIndex = index + 1; innerIndex < ancestors.length; innerIndex++) {
            Object.assign(ancestors[innerIndex]._owner, assignedAncestorOwnerInfo);
          }
        }

        // keep the list of job protecting descendant nodes and used to show
        // node unassignment warnings.
        if (!_.isEmpty(node._protectingJobs)) {
          ancestor._protectingJobs = _.uniqBy(
            _.union(ancestor._protectingJobs, node._protectingJobs),

            // TODO: do we need to use id or jobId is enough
            'jobId'
          );
        }

        if (node._volumeType === 'kReadWrite') {
          ancestor._readWriteDescendants.push(node.protectionSource.id);
        }

        if (node._volumeType === 'kDataProtection') {
          ancestor._dataProtectDescendants.push(node.protectionSource.id);
        }

        if (node._isAgentInstalled) {
          ancestor._agentInstalledDescendants.push(
            node.protectionSource.id);
        }

        if (node._isAgentNotInstalled) {
          ancestor._agentNotInstalledDescendants.push(
            node.protectionSource.id);
        }
      });
    }

    /**
     * Used to expand tree nodes which matches the applied tag search criteria.
     *
     * @method   expandTagBranch
     * @param    {Array}      activeTags    The list of tags.
     * @param    {object}     opts          options {
          expandedNodes  {Array}    The list of expanded nodes.
          tree:          {Object}   The tree.
      }
     */
    function expandTagBranch(activeTags, opts) {
      forEachNode(opts.tree, function eachNode(node) {
        var isMatch = isTagMatch(node, activeTags);

        // // Expand nodes branches found under applied VM tags.
        if (isMatch && !opts.expandedNodes.includes(node)) {
          opts.expandedNodes.push(node);
        }
      });
    }

    /**
     * Determine whether a node or its descendant is associated with the
     * provided tags.
     *
     * @method   isTagMatch
     * @param    {Object}    node          The node to test.
     * @param    {Array}     activeTags    The list of tags.
     * @return   {Boolean}   True if node is associated with the provided tags
     *                       else return false.
     */
    function isTagMatch(node, activeTags) {
      var tagFilterMatch = false;

      if (node._isLeaf) {
        tagFilterMatch = activeTags.every(function matchTags(tag) {
          return (node._envProtectionSource.tagAttributes || []).some(
            function findTag(tagAttr) {
              return tag.protectionSource.id === tagAttr.id;
            }
          );
        });
      } else {
        tagFilterMatch = node._descendantTagSets.some(function(tagSet) {
          return activeTags.every(function findTag(tag) {
            return tagSet.some(function checkTagFromSet(descendantTagId) {
              return descendantTagId === tag.protectionSource.id;
            });
          });
        });
      }

      return tagFilterMatch;
    }

    /**
     * Select the given node, and recursively select it's children.
     *
     * @method   selectNode
     * @param    {object}     node          to be selected.
     * @param    {object}     opts          options.
     * @param    {function}   [canSelect]   Optional external canSelect Fn:
     *                                      canSelect(node); returns boolean
     */
    function selectNode(node, opts, canSelect) {
      var wasUnselected = !node._isSelected;

      if (_.isFunction(canSelect) && !canSelect(node)) { return; }

      // TODO: Refactor this to use _.assign()
      opts = {
        autoProtect: !!opts.autoProtect,
        ancestorAutoProtect: !!opts.ancestorAutoProtect,

        /**
         * Determines if this method should cascade selection to descendent
         * nodes.
         *
         * @type   {boolean}
         */
        noCascadingSelection: !!opts.noCascadingSelection,
        ancestorExcluded: !!opts.ancestorExcluded,
        expandedNodes: opts.expandedNodes,
        selectedObjectsCounts: opts.selectedObjectsCounts || {},
        tagAutoProtect: !!opts.tagAutoProtect,
        tree: opts.tree || [],

        // if auto protecting a node then don't use the filter function as any
        // descendant node will be auto-protected regardless of the view or
        // applied filters. eg. for vCenter hierarchal view, folder nodes will
        // be hidden but will get protected via the ancestors auto protection.
        treeFilterExpressionFn:
          (!opts.autoProtect && opts.treeFilterExpressionFn) ||
          function() { return true; },

        // unexclude option used to explicitly unexclude a node
        unexclude: !!opts.unexclude,

        // For the sake of upgrade selection, need to distinguish between the
        // detail page and object selection
        detailedView: !!opts.detailedView,

        // All sources are selectable by default
        selectable: true,
      };

      // If the node is filtered out of view, then it is not selectable.
      if (!opts.treeFilterExpressionFn(node)) {
        return;
      }

      // In the source detail view, if a physical agent is not upgradable or is
      // in the process of upgrading, then it's not selectable and it's safe to
      // exit early
      if (opts.detailedView &&
        node._environment == 'kPhysical' &&
        // TODO: Update this biz
        (node._agent.upgradability !== 0 || node._isUpgrading)) {
        return;
      }

      // don't traverse through a vmware tag category branch, as they are hidden
      // from the user and only retained in the tree for informational purposes
      if (node._environment === 'kVMware' && node._type === 'kTagCategory') {
        return;
      }

      // prevent recursing through an auto protected/excluded branch from a
      // standard checkbox click
      if (!opts.tagAutoProtect && !opts.autoProtect &&
        (node._isTagAutoProtected ||
        node._isTagExcluded ||
        node._isAutoProtected)) {
        return;
      }

      switch (true) {

        // node based auto protection
        case (opts.autoProtect):

          // if a node was previously excluded and this is recursion via action
          // on an ancestor, we want to maintain the exclusion
          if (!opts.unexclude && node._isExcluded) {
            opts.ancestorExcluded = true;
          } else {
            node._isExcluded = false;
            node._isAutoProtected =
              !node._isTagAutoProtected && !opts.ancestorAutoProtect;
            node._isAncestorAutoProtected = opts.ancestorAutoProtect;
            node._isAncestorExcluded = opts.ancestorExcluded;
            node._isSelected = !opts.ancestorExcluded && !node._isTagExcluded;
          }

          node._isAutoProtectedDescendant = true;
          opts.ancestorAutoProtect = true;
          break;

        // tag auto protection
        case (opts.tagAutoProtect):
          node._isTagAutoProtected = true;

          // Node is auto protected because its ancestor tag is auto protected.
          node._isAncestorAutoProtected = opts.ancestorAutoProtect;

          // Node is not excluded as it is getting selected currently.
          node._isTagExcluded = false;

          if (opts.unexclude) {
            node._isSelected = true;
            node._isExcluded = false;
          } else {
            node._isSelected = !(node._isExcluded ||
              node._isAncestorExcluded ||
              node._isTagExcluded);
          }

          break;

        // standard selection
        default:
          node._isSelected = true;
          break;

      }

      // adjust object count map if node was previously unselected and is now
      // selected
      if (wasUnselected && node._isSelected) {
        opts.selectedObjectsCounts[node._type] =
            ++opts.selectedObjectsCounts[node._type] || 1;
      }

      if (!opts.noCascadingSelection && node.nodes && node.nodes.length) {

        // If node has children, mark them as selected too
        node.nodes.forEach(function loopChildren(child) {
          // only add the child if it is not filtered.
          // For autoprotect or exclusion, ignore filtering
          // TODO: should we do the same for unselecting/removing nodes?
          if (opts.ancestorAutoProtect ||
            opts.ancestorExcluded ||
            opts.treeFilterExpressionFn(child)) {
            selectNode(child, opts, canSelect);
          }
        });

        // expand the node (if its not already expanded) so the user can clearly
        // see that child nodes were automatically selected
        if (opts.expandedNodes && !opts.expandedNodes.includes(node)) {
          opts.expandedNodes.push(node);
        }

      }

      // Update auto-protectability state.
      node._canAutoProtect = _canAutoProtect(node);

      // if we're dealing with a duplicate, any other
      // instances need to be found and updated to match
      if (node._isLeaf && node._isDuplicate) {
        opts.tree.forEach(function loopBranches(branch) {
          updateNodeIfDuplicate(branch, node);
        });
      }
    }

    /**
     * unselect a node
     *
     * @param      {object}  node    object
     * @param      {object}  opts    options
     */
    function unselectNode(node, opts) {

      var wasSelected = node._isSelected;

      opts = {
        autoProtect: opts.autoProtect === true,
        ancestorExcluded: opts.ancestorExcluded === true,
        excluding: opts.excluding === true,
        expandedNodes: opts.expandedNodes || [],
        selectedObjectsCounts: opts.selectedObjectsCounts || {},
        tagAutoProtect: opts.tagAutoProtect === true,
        tree: opts.tree || [],
      };

      // don't traverse through a vmware tag category branch, as they are hidden
      // from the user and only retained in the tree for informational purposes
      if (node._environment === 'kVMware' && node._type === 'kTagCategory') {
        return;
      }

      // prevent recursing through an auto protected/excluded branch from a
      // standard checkbox click
      if (!opts.tagAutoProtect &&
        !opts.autoProtect &&
        [node._isTagAutoProtected, node._isTagExcluded, node._isAutoProtected]
          .includes(true)) {
        return;
      }

      // in most of the below cases, the node will no longer be 'selected' for
      // protection. With the exception of cascading autoprotection off, in
      // which case the node may still be selected based on tag protection.
      node._isSelected = false;

      // handle special cases
      switch (true) {
        // excluding this node explicitly
        case (opts.autoProtect && opts.excluding && !opts.ancestorExcluded):
          node._isExcluded = true;
          node._isAncestorExcluded = false;
          opts.ancestorExcluded = true;
          break;

        // excluded by inheritance
        case (opts.autoProtect && opts.excluding && opts.ancestorExcluded):
          node._isAncestorExcluded = true;
          break;

        // cascading autoProtect off
        case (opts.autoProtect && !opts.excluding):
          node._isAutoProtected = false;
          node._isAncestorAutoProtected = false;
          node._isAncestorExcluded = false;
          node._isExcluded = false;
          node._isSelected = node._isTagAutoProtected;
          break;

        // excluding via tag exclusion
        case (opts.tagAutoProtect && opts.excluding):
          node._isTagExcluded = true;
          break;

        // cascading autoProtect off
        case (opts.tagAutoProtect && !opts.excluding):
          node._isTagExcluded = false;
          node._isTagAutoProtected = false;
          node._isSelected = false;
          node._isAncestorAutoProtected = false;
          break;
      }

      // adjust object count map if node was previously selected and is no
      // longer selected
      if (wasSelected && !node._isSelected) {
        opts.selectedObjectsCounts[node._type] =
          --opts.selectedObjectsCounts[node._type] || 0;
      }

      if (node.nodes && node.nodes.length) {
        node.nodes.forEach(function loopChildren(child) {
          // TODO: check filtering, etc here as we do in selectNode()?
          unselectNode(child, opts);
        });
      }

      // Update auto-protectability state.
      node._canAutoProtect = _canAutoProtect(node);

      // if we're dealing with a duplicate, any other
      // instances need to be found and updated to match
      if (node._isLeaf && node._isDuplicate) {
        opts.tree.forEach(function loopBranches(branch) {
          updateNodeIfDuplicate(branch, node);
        });
      }
    }

    /**
     * Sync duplicate source nodes found in the tree.
     *
     * @method  syncDuplicateSourceNodes
     * @param   {Array}     nodes   nodes to check.
     * @param   {Array}     tree    The Tree containing the nodes.
     * @param   {Function}  [callback=noop]  If provided then callback will be
     * called with found duplicate node and path to reach that node from root,
     * use this callback to add additional logic for duplicates nodes if needed.
     * callback will have signature => (node, pathToRoot): void
     * @param   {Boolean}  [isMaster=false]  Set true to sync properties from
     * node to other nodes if found in the tree.
     */
    function syncDuplicateSourceNodes(nodes, tree, callback, isMaster) {
      if (!_.isFunction(callback)) {
        callback = Function.prototype;
      }

      const nodeMap = new Map();
      const nodesWithDuplicates = new Set();

      [].concat(nodes).forEach(node => {
        nodeMap.set(node.protectionSource.id, {
          node: node,
          duplicates: [],
        });
      });

      // object hash map used by _syncDuplicateSourceProperties method to
      // calculate ancestor node.
      const options = {
        objHash: {},
        objParentHash: {},
      };

      // Looking for each node in the tree to find duplicate nodes as they are
      // lying under different hierarchy.
      forEachNode(tree || [], function eachNode(source, index, list, path) {
        const sourceId = source.protectionSource.id;
        if (!_.isArray(options.objHash[sourceId])) {
          options.objHash[sourceId] = [];
          options.objParentHash[sourceId] = [];
        }

        options.objHash[sourceId].push(source);
        options.objParentHash[sourceId].push(path[0]);

        // Sync properties b/w duplicate nodes.
        if ((source._isDuplicate || source._isApplicationHost) && nodeMap.has(sourceId)) {
          const nodeObj = nodeMap.get(sourceId);
          nodesWithDuplicates.add(nodeObj);
          nodeObj.duplicates.push({ node: source, path });
        }
      });

      nodesWithDuplicates.forEach(nodeObj => {
        const { node, duplicates } = nodeObj;
        _syncDuplicateSourceProperties(node, options, isMaster);

        duplicates.forEach(({ node, path}) => {
          callback(node, path);
        });
      });
    }

    /**
     * recursively checks nodes in provided node/branch, finding any leaf level
     * entities that match provided duplicate, updating their selection values
     * when found
     *
     * @method   updateNodeIfDuplicate
     * @param    {object}   node            the current search node
     * @param    {object}   duplicateNode   The duplicate node being searched
     *                                      for
     */
    function updateNodeIfDuplicate(node, duplicateNode) {

      var isSameSource = PubSourceServiceFormatter.isSameSource(
        node.protectionSource,
        duplicateNode.protectionSource
      );

      if (isSameSource) {
        syncDuplicateNodeProperties(duplicateNode, node, true);
      }

      if (node.nodes.length) {
        node.nodes.forEach(function checkChildren(child) {
          updateNodeIfDuplicate(child, duplicateNode);
        });
      }

    }

    /**
     * syncs protection related properties for a pair of duplicate nodes.
     *
     * @param      {object}   node1            The node
     * @param      {object}   node2            The duplicate of the node
     * @param      {boolean}  [isNode1Master]  if true, values should be copied
     *                                         from node1 to node2. if false,
     *                                         values will be true if either
     *                                         node has true
     */
    function syncDuplicateNodeProperties(node1, node2, isNode1Master) {
      var propsToSync = [
        '_isAncestorAutoProtected',
        '_isAncestorExcluded',
        '_isAutoProtected',
        '_isAutoProtectedDescendant',
        '_isExcluded',
        '_isSelected',
        '_isTagAutoProtected',
        '_isTagExcluded',
        '_owner',
        '_selectedAncestor',
      ];

      propsToSync.forEach(function syncProp(prop) {
        node1[prop] = node2[prop] = !!isNode1Master ?
          node1[prop] : node1[prop] || node2[prop];
      });
    }

    /**
     * Untransform the UI-created Job into a Magneto-friendly BackupJobProto.
     *
     * This is necessary because the UI treats Oracle databases and other
     * appEntities as first-class citizens. But Magneto does not. So we need to
     * rearrange the User selected hosts into their appropriate proto locations
     * for acceptance.
     *
     * @method   untransformOracleJob
     * @param    {object}   job   The UI-created Job object.
     * @return   {object}   The untransformed Job object.
     */
    function untransformOracleJob(job) {
      var appsHashByHostId;

      // Sanity check. If this isn't an Oracle job.
      if (!job || job.environment !== 'kOracle') {
        return job;
      }

      appsHashByHostId = _getAppEntitiesHash(job);
      job._sourceSpecialParametersMap = {};

      // With each key in the hash, updateSourceSpecialParameters
      angular.forEach(appsHashByHostId, function eachHash(appsMap, hostId) {
        var appParamsList = [];

        // No apps were found, no need to continue.
        if (!_.get(appsMap, 'appIds.length')) { return; }


        // Fill Multi Node Channel data
        appsMap.appIds.forEach(function forEachAppDb(appId, index) {
          var dbNodesData = _.chain(job)
            .get('_oracleMnmc', {})
            .get(hostId, {})
            .get(appId, {})
            .value();

          // From 6.5.1, magneto expects host id instead of host name in
          // databaseNodeList. So, we replace the name with id here in this
          // formatter. This also works well with upgrade cases where user
          // edits the job and submits it, we replace name with id.
          var parentHost = _.chain(appsMap)
          .get('appSources.[' + index + ']')
          .get('_parentHostProtectionSource')
          .value();

          var hostAddressToAgentIdMap =
            NgOracleUtilityService.getHostAddressToAgentIdMap(parentHost);

          if (!_.isEmpty(dbNodesData)) {
            (dbNodesData.databaseNodeList || []).forEach(
              function _iterNodeList(nodeList) {
                if (isNaN(nodeList.node)) {
                  nodeList.node = hostAddressToAgentIdMap[nodeList.node];
                }
              }
            );

            appParamsList.push({
              databaseAppId: appId,
              nodeChannelList: [dbNodesData],
            });
          }
        });


        // Update the sourceSpecialParamters for this host
        updateSourceSpecialParameters(
          job,
          appsMap.hostSource,
          {
            applicationEntityIds: appsMap.appIds,
            appParamsList: appParamsList.length  ?  appParamsList : undefined,
          }
        );

        // If this host wasn't explicitly selected, but was instead derived from
        // one or more selected App objects, add it to the job.sourceIds
        if (!appsMap.hostIsSelected) {
          // Coercing hostId to an integer because it's the hash ID which is a
          // string.
          job.sourceIds.push(+hostId);
        }
      });

      syncSourceSpecialParametersMapAndList(job);

      return job;
    }
    /**
     * Untransform the UI-created Job into a Magneto-friendly BackupJobProto.
     *
     * This is used to add Active Directory-specific source special parameters
     * to the job.
     *
     * @method   untransformActiveDirectoryJob
     * @param    {object}   job   The UI-created Job object.
     * @return   {object}   The untransformed Job object.
     */
    function untransformActiveDirectoryJob(job) {

      // Sanity check. Return early if this isn't a kAD job.
      if (!job || job.environment !== 'kAD') {
        return job;
      }

      job._selectedSources.forEach(function eachSource(source) {
        // All node children are always selected for Active Directory
        var appIds = source.nodes.map(function toId(node) {
          return node.protectionSource.id;
        });
        source._environment = 'kAD';

        updateSourceSpecialParameters(
          job,
          source,
          { applicationEntityIds: appIds }
        )
      });

      syncSourceSpecialParametersMapAndList(job);

      // Remove the UI decorated property to trim the request
      job._selectedSources = undefined;

      return job;
    }

    /**
     * Untransform the UI-created Job into a Magneto-friendly BackupJobProto.
     *
     * This is necessary because the UI treats SQL databases and other
     * appEntities as first-class citizens. But Magneto does not. So we need to
     * rearrange the User selected hosts into their appropriate proto locations
     * for acceptance.
     *
     * @method   untransformSqlJob
     * @param    {object}   job   The UI-created Job object.
     * @return   {object}   The untransformed Job object.
     */
    function untransformSqlJob(job) {
      var backupType = _.get(job, '_envParams.backupType');
      var appsHashByHostId;
      var physicalServerOptionsByHostId;

      // Sanity check.
      if (!FEATURE_FLAGS.enableFilestream &&
        !['kSqlVSSFile', 'kSqlNative'].includes(backupType)) {
        return job;
      }

      appsHashByHostId = _getAppEntitiesHash(job);

      physicalServerOptionsByHostId = _getphysicalServerOptionsByHostId(job);

      // When the Job is File-based, clear this out to use the server default.
      // This setting also has no real meaning in the context of a file-based
      // Job. This can happen if the User toggles Volume-based & File-based
      // setting when creating the Job. Just a little pre-flight house keeping.
      if (backupType !== 'kSqlVSSVolume') {
        job._envParams.backupVolumesOnly = undefined;
      }

      // Clean out previous SQL params. It gets rebuilt below.
      job._sourceSpecialParametersMap = {};

      // With each key in the hash, rebuild the sourceSpecialParameters
      angular.forEach(appsHashByHostId, function eachHash(appsMap, hostId) {
        // No apps were found, no need to continue.
        if (!_.get(appsMap, 'appIds.length')) { return; }

        // Update the sourceSpecialParamters for this host
        updateSourceSpecialParameters(
          job,
          appsMap.hostSource,
          { applicationEntityIds: appsMap.appIds }
        );

        // If this host wasn't explicitly selected, but was instead derived from
        // one or more selected App objects, add it to the job.sourceIds
        if (!appsMap.hostIsSelected) {
          // Coercing hostId to an integer because it's the hash ID which is a
          // string.
          job.sourceIds.push(+hostId);
        }
      });

      // Add physical server options to job source special parameters
      Object.values(physicalServerOptionsByHostId).forEach(
        function forEachHash(physicalOptionsHash) {
          updateSourceSpecialParameters(
            job,
            physicalOptionsHash.hostSource,
            physicalOptionsHash.physicalSpecialParameters
          );
        }
      );

      syncSourceSpecialParametersMapAndList(job);

      // Remove the UI decorated property to trim the request
      job._selectedSources = undefined;

      return job;
    }

    /**
     * Transforms the Magneto Oracle file-based job to a UI friendly object.
     * This is necessary because, while the UI treats Oracle files as
     * first-class citizens, they are not so in Magneto. So we need to
     * transform the job.sources list from hosts to appEntities, and rework the
     * tree.
     *
     * @method   transformOracleJob
     * @param    {object}   job   The Oracle Job.
     * @return   {object}   The UI-friendly Oracle Job.
     */
    function transformOracleJob(job) {
      var appEntities;

      // Sanity check. If this isn't a Oracle job, not a File-absed SQL job, nor
      // has App Entitoes protected, exit early.
      if (!job || job.environment !== 'kOracle') {
        return job;
      }

      // Identify app entities from sourceSpecialParameters
      appEntities = _getSelectedAppEntitiesHash(job);


      // Transform and store multinode multichannel data for better readability.
      _transformOracleMultiNodeMultiChannelData(job);

      if (_.isEmpty(appEntities)) { return job; }

      // With the list of job.sources, rebuild it to substitute appHosts with
      // their appEntities, if they were selected.
      job.sourceIds = job.sourceIds.reduce(
        function replaceHosts(accumulator, hostId) {
          if (appEntities[hostId]) {
            // If we know about this host by its appEntityIdVec, push them all
            // in instead of the host.
            Array.prototype.push.apply(
              accumulator,
              _.map(appEntities[hostId], 'protectionSource.id')
            );
          } else {
            // Otherwise keep the host and proceed because it was selected by
            // the User.
            accumulator.push(hostId);
          }

          return accumulator;
        },
        []
      );

      return job;
    }

    /**
     * Set the oracle multi node multi channel channel data in a
     * variable to access it easily.
     *
     * @method   _transformOracleMultiNodeMultiChannelData
     */
    function _transformOracleMultiNodeMultiChannelData(job) {
      job._oracleMnmc = {};
      _.forEach(job.sourceSpecialParameters,
        function eachServer(server) {
          job._oracleMnmc[server.sourceId] = {};

          if (_.get(server, 'oracleSpecialParameters.appParamsList')) {
            // Fill the node and channel details for each db
            server.oracleSpecialParameters.appParamsList.forEach(
              function eachDb(db) {
                job._oracleMnmc[server.sourceId][db.databaseAppId] =
                  db.nodeChannelList[0];
              }
            );
          }
      });
    }

    /**
     * Transforms the Magneto SQL file-based job to a UI friendly object. This
     * is necessary because, while the UI treats SQL files as first-class
     * citizens, they are not so in Magneto. So we need to transform the
     * job.sources list from hosts to appEntities, and rework the tree.
     *
     * @method   transformSqlJob
     * @param    {object}   job   The SQL file-based Job.
     * @return   {object}   The ui-friendly SQL file-based Job.
     */
    function transformSqlJob(job) {
      var appEntities;

      if (job.environment === 'kSQL' &&
        !_.get(job.environmentParameters, 'sqlParameters.backupType')) {
        // Make sure environmentParameters.sqlParameters.backupType has value.
        // If undefined (a job created via private API) it is equivalent to
        // 'kSqlVSSVolume'.
        _.set(
          job,
          'environmentParameters.sqlParameters.backupType',
          'kSqlVSSVolume'
        );
      }

      // Sanity check.
      if (!FEATURE_FLAGS.enableFilestream &&
        !['kSqlVSSFile', 'kSqlNative']
          .includes(_.get(job, '_envParams.backupType'))) {
        return job;
      }

      // Identify app entities from sourceSpecialParameters
      appEntities = _getSelectedAppEntitiesHash(job);

      if (_.isEmpty(appEntities)) { return job; }


      // With the list of job.sources, rebuild it to substitute appHosts with
      // their appEntities, if they were selected.
      job.sourceIds = job.sourceIds.reduce(
        function replaceHosts(accumulator, hostId) {
          if (appEntities[hostId]) {
            // If we know about this host by its appEntityIdVec, push them all
            // in instead of the host.
            Array.prototype.push.apply(
              accumulator,
              _.map(appEntities[hostId], 'protectionSource.id')
            );
          } else {
            // Otherwise keep the host and proceed because it was selected by
            // the User.
            accumulator.push(hostId);
          }

          return accumulator;
        },
        []
      );

      return job;
    }

    /**
     * Generates a hash, indexed by hostId, of application sources found in the
     * job from their ID associated with said hostId.
     *
     * @method   _getSelectedAppEntitiesHash
     * @param    {object}   job   The job to extract the hash from.
     * @return   {object}   The hash.
     */
    function _getSelectedAppEntitiesHash(job) {
      var jobEnv = job.environment;
      var specialParamsKey = SOURCE_SPECIAL_PARAMETERS_KEYS[jobEnv];

      return (job.sourceSpecialParameters || []).reduce(
        function eachSpecialParam(accumulator, specialParams) {
          var hostId = specialParams.sourceId;
          var appIds = _.result(
            specialParams,
            specialParamsKey + '.applicationEntityIds',
            []
          );

          if (appIds.length) {
            accumulator[hostId] = appIds.map(
              function idToProtectionSourceMapper(appId) {
                var output = {
                  protectionSource: {
                    id: appId,
                    parentId: hostId,
                    environment: jobEnv,
                  },
                };

                return output;
              }
            );
          }

          return accumulator;
        },
        {}
      );
    }

    /**
     * Gets a hash of the User selected App objects, grouped by Host ID.
     *
     * This is because we can not submit app objects in a Protection Job to
     * Magneto, but only the host, with a supplimentary property defining the
     * appEntity IDs.
     *
     * Output Format = {
     *   'hostId': {
     *     hostisSelected: bool
     *     hostSource: {}
     *     appSources: [{}, ...]
     *     appIds: [int, ...]
     *   }
     * }
     *
     * @method   _getAppEntitiesHash
     * @param    {object}   job   The Job object.
     * @return   {object}   Hash of appEntities by appHost ID.
     */
    function _getAppEntitiesHash(job) {
      return job._selectedSources.reduce(
        function eachSelection(accumulator, source) {
          var parentId = source.protectionSource.parentId;
          var thisId = source.protectionSource.id;
          var selectedSourceIndex = job.sourceIds.indexOf(thisId);

          if (ENV_GROUPS.databaseSources.includes(source._environment)) {
            accumulator[parentId] = accumulator[parentId] || {
              hostSource: {
                _environment: source._environment,
                protectionSource: {
                  id: parentId,
                },
              },

              // Fully hydrated sources list.
              appSources: [],

              // Same list as appSources, but flattened to just their IDs.
              appIds: [],
            };

            // Add this source & ID to the respective arrays
            accumulator[parentId].appSources.push(source);
            accumulator[parentId].appIds.push(thisId);

            // Remove this appSource ID from the selection
            job.sourceIds.splice(selectedSourceIndex, 1);
          }

          // If this node ID matches a host already in the job, replace the
          // hash[hostId].hostSource with it. This accounts for Volumes selected
          // in a File-absed job.
          if (ENV_GROUPS.databaseHosts.includes(source._environment)) {
            accumulator[thisId] = {
              hostIsSelected: true,
              hostSource: source,
            };
          }

          return accumulator;
        },
        {}
      );
    }

    /**
     * Gets a hash of the Physical Source Special parameters, grouped by Host ID.
     *
     * This is needed to save the physical source special params of the servers
     * selected in the protection job which will be used to fill up job source
     * special parameters when job is modified.
     *
     * Output Format = {
     *   'hostId': {
     *     hostSource: {}
     *     physicalSpecialParameters: {}
     *   }
     * }
     *
     * @method   _getphysicalServerOptionsByHostId
     * @param    {object}   job   The Job object.
     * @return   {object}   Hash of physicalSpecialParams by host id.
     */
    function _getphysicalServerOptionsByHostId(job) {
      return job._selectedSources.reduce(
        function eachSelection(accumulator, source) {
          var sourceId = source.protectionSource.id;

          // If we have physical special params then do the processing
          if (job._sourceSpecialParametersMap[sourceId] &&
            job._sourceSpecialParametersMap[sourceId].physicalSpecialParameters) {
            accumulator[sourceId] = {
              physicalSpecialParameters:
                job._sourceSpecialParametersMap[sourceId].physicalSpecialParameters,
              hostSource: source
            };
          }
          return accumulator;
        },
        {}
      );
    }

    /**
     * Decorate the source node for assignment to tenants or restricted user/group.
     *
     * @method   decorateSourceForAssignment
     * @param    {Object}   node      The node
     * @param    {Object}   options   Node options.
     */
    function decorateSourceForAssignment(node, options) {
      // shalow cloning to not expose below derived options out.
      options = { ...(options || {}) };
      // adding derived options for souece assignments.
      _decorateDerivedOptionsForAssignment(node, options);

      // preparing node for restricted user & group.
      if (options.purpose === 'assignToUser') {
        _decorateSourceAssignmentForUsersAndGroups(node, options);
      }

      // preparing node for tenants.
      if (options.purpose === 'assignToTenant') {
        _decorateSourceAssignmentForTenants(node, options);
      }
    }

    /**
     * Decorate the derived options for soruce assignment to tenants or restricted user/group.
     *
     * @method   _decorateDerivedOptionsForAssignment
     * @param    {Object}   node      The node
     * @param    {Object}   options   Node options.
     */
    function _decorateDerivedOptionsForAssignment(node, options) {
      Object.assign(options, {
        cantSelectReasonKey: 'cSourceTree.tooltips.ancestorSelectionMode',
        removeReasonKey: 'cSourceTree.ancestorSelectionMode',
        descendantIsSourceOwnerSet: new Set(),
        descendantNodeTenantIdsSet: new Set(),
        descendantUserOrGroupMap: {},
        descendantUserOrGroupTenantIdsSet: new Set(),
        descendantUserOrGroupNames: [],
      });

      [].concat(node._owner.user || [], node._owner.groups || []).forEach(principal => {
        options.descendantUserOrGroupMap[principal.sid] = principal;
      });

      options.descendantNodeTenantIdsSet.add(node._owner.tenantId);

      _.chain(node._descendantNodeOwnersMap).forEach(function eachOwnerInfo(ownerInfo) {
        options.descendantIsSourceOwnerSet.add(ownerInfo.isSourceOwner);
        options.descendantNodeTenantIdsSet.add(ownerInfo.tenantId);
        [].concat(ownerInfo.user || [], ownerInfo.groups || []).forEach(principal => {
          options.descendantUserOrGroupMap[principal.sid] = principal;
        });
      }).value();

      _.forEach(options.descendantUserOrGroupMap, principal => {
        [].concat(principal.tenantId || principal.tenantIds).forEach(tenantId => {
          options.descendantUserOrGroupTenantIdsSet.add(tenantId);
        });

        if (!options.descendantUserOrGroupNames.includes(principal.userName || principal.groupName)) {
          options.descendantUserOrGroupNames.push(principal.userName || principal.groupName);
        }
      });
    }

    /**
     * Decorate the source node for assignment to users or groups.
     *
     * @method   _decorateSourceAssignmentForUsersAndGroups
     * @param    {Object}   node      The node
     * @param    {Object}   options   Node options.
     */
    function _decorateSourceAssignmentForUsersAndGroups(node, options) {
      const multiTenancyEnabled = $rootScope.clusterInfo.multiTenancyEnabled;

      node._canSelect = { result: true };

      // from restricted user node can be removed w/o checking anything.
      node._canRemoveForMiniView = node._canRemove = { result: true };

      if (!node._owner.isSourceOwner) {
        // case 1: can't assign the node which is not owned by logged in user.
        node._canSelect = {
          result: false,
          reason: $translate.instant(`${options.cantSelectReasonKey}.nodeDisabled`, { multiTenancyEnabled }),
        };
      } else if (options.descendantIsSourceOwnerSet.size > 1) {
        // case 2: can't assign the code if its descendant is not complelty owned by one tenant eg.
        // there are some descendant nodes owned by SP & tenants.
        node._canSelect = {
          result: false,
          reason: $translate.instant(`${options.cantSelectReasonKey}.nodeDisabled`, { multiTenancyEnabled }),
        };
      } else if (options.descendantIsSourceOwnerSet.size === 1 && options.descendantIsSourceOwnerSet.has(false)) {
        // case 3: can't assign the node if its descendant is owned by one tenant but logged in user dosen't belongs
        // to that tenant.
        node._canSelect = {
          result: false,
          reason: $translate.instant(`${options.cantSelectReasonKey}.nodeDisabled`, { multiTenancyEnabled }),
        };
      }
    }

    /**
     * Decorate the source node for assignment to tenants.
     *
     * @method   _decorateSourceAssignmentForTenants
     * @param    {Object}   node      The node
     * @param    {Object}   options   Node options.
     */
    function _decorateSourceAssignmentForTenants(node, options) {
      const multiTenancyEnabled = $rootScope.clusterInfo.multiTenancyEnabled;

      node._canSelect = { result: true };
      node._canRemoveForMiniView = node._canRemove = { result: true };

      const descendantNodeTenantIdsSetSize = options.descendantNodeTenantIdsSet.size;
      const descendantUserOrGroupTenantIdsSetSize = options.descendantUserOrGroupTenantIdsSet.size;
      if (descendantNodeTenantIdsSetSize > 2) {
        // case 1: can't assign the node if its descendant nodes are owned by more than two tenants.
        node._canSelect = {
          result: false,
          reason: $translate.instant(`${options.cantSelectReasonKey}.nodeDisabled`, { multiTenancyEnabled }),
        };
      } else if (descendantNodeTenantIdsSetSize === 2 &&
        options.descendantNodeTenantIdsSet.has(NgUserStoreService.getUserTenantId()) &&
        !options.descendantNodeTenantIdsSet.has(options.tenantId)) {
        // case 2: can't assign the node if its descendant nodes are NOT owned by the tenant being edited and owned
        // service provider admin.
        node._canSelect = {
          result: false,
          reason: $translate.instant(`${options.cantSelectReasonKey}.nodeDisabled`, { multiTenancyEnabled }),
        };
      } else if (descendantNodeTenantIdsSetSize === 2 &&
        options.descendantNodeTenantIdsSet.has(NgUserStoreService.getUserTenantId()) &&
        options.descendantNodeTenantIdsSet.has(options.tenantId) &&
        (descendantUserOrGroupTenantIdsSetSize > 2 || (
          descendantUserOrGroupTenantIdsSetSize === 1 &&
          !options.descendantUserOrGroupTenantIdsSet.has(options.tenantId)
        ))) {
        // case 3: can't assign the node if its descendant nodes are owned by the tenant being edited and service
        // provider admin but having some descendant user/group assigned to 3 or more tenants or descendant user/group
        // assigned to one tenant is not being edited.
        node._canSelect = {
          result: false,
          reason: $translate.instant(`${options.cantSelectReasonKey}.nodeDisabled`, { multiTenancyEnabled }),
        };
      } else if (descendantNodeTenantIdsSetSize === 1 &&
       !$rootScope.isTenantsAncestor(options.descendantNodeTenantIdsSet.values().next().value, options.tenantId)) {
        // case 4: can't assign the node if its descendant nodes are owned by one tenant and that tenant dosen't
        // belongs to edited tenant(options.tenantId) orginization.
        node._canSelect = {
          result: false,
          reason: $translate.instant(`${options.cantSelectReasonKey}.nodeDisabled`, { multiTenancyEnabled }),
        };
      } else if (node._owner.isSourceOwner && (
        descendantUserOrGroupTenantIdsSetSize > 1 || (
          descendantUserOrGroupTenantIdsSetSize === 1 &&
          !options.descendantUserOrGroupTenantIdsSet.has(options.tenantId)
        )
      )) {
        // case 5: can't assign the node if its some of the descendant nodes are assigned to restricted user/group.
        node._canSelect = {
          result: false,
          reason: $translate.instant(`${options.cantSelectReasonKey}.nodeDisabled`, { multiTenancyEnabled }),
        };
      } else if (!node._owner.isSourceOwner
        && !isEntityOwner(
          $injector.get('NgIrisContextService')?.irisContext,
          node._owner.rootNodeOwnerTenantId)) {
        // case 6: can't assign the node if its root node is assigned to tenants
        // and is ALSO registered by tenant

        // check if current user is a SP admin user and node orginal owner is SP admin
        if (FEATURE_FLAGS.mtSourceRootUnassignment) {
          // Activate this check only if FEATURE FLAG is turned on.
          if (!node._owner.isRegisteredBySp)
          node._canSelect = {
            result: false,
            reason: $translate.instant(
              `${options.cantSelectReasonKey}.${node._isRootNode ? 'disabledRootUnselect' : 'disabledOwnedRootUnselect'}`
            ),
          };
        } else {
          // this is the old code w/ issue that root node once assigned to tenant could not
          // be claimed back by SP directly.
          node._canSelect = {
            result: false,
            reason: $translate.instant(
              `${options.cantSelectReasonKey}.${node._isRootNode ? 'disabledRootUnselect' : 'disabledOwnedRootUnselect'}`
            ),
          };
        }
      } else if (node._owner.isInferred && !node._owner.hasAssignedAncestor) {
        // case 7: can't assign the node if it is isInferred and its dosen't contain any assigned ancestor.
        node._canSelect = {
          result: false,
          reason: $translate.instant(`${options.cantSelectReasonKey}.disabledInferredUnselect`),
        };
      }

      // node removal condtions.
      if (!node._canSelect.result) {
        // case 1: node can't be removed if can't be selected.
        node._canRemoveForMiniView = node._canRemove = node._canSelect;
      }

      // indicate whehther the node is protected direcnlty or indirectly via any protected ancestor node.
      let isAncestorProtected = _isNodeAncestorProtected(node);

      if (!isAncestorProtected) {
        const pathToRoot = (options.pathToRoot || []);
        const pathLength = pathToRoot.length;

        for (let nodeIndex = pathLength - 1; nodeIndex >=0; nodeIndex -= 1) {
          const parentNode = pathToRoot[nodeIndex];
          if (_isNodeAncestorProtected(parentNode)) {
            isAncestorProtected = true;
            break;
          }
        }
      }

      if (node._canSelect.result && (isAncestorProtected || descendantUserOrGroupTenantIdsSetSize)) {
        // case 2: can't remove the nodes wihch belongs to edited tenant(options.tenantId) orginization and its
        // protected by some backup job or assigned to the user/group.
        node._canRemoveForMiniView = {
          result: false,
          reason: [
            `${options.removeReasonKey}.unselectWarning`,
            !_.isEmpty(node._protectingJobs) ? `${options.removeReasonKey}.unselectWarningProtectionJobsV2` : '',
            (!_.isEmpty(node._protectingJobs) && options.descendantUserOrGroupTenantIdsSet.size) ? 'or' : '',
            options.descendantUserOrGroupTenantIdsSet.size ?
              `${options.removeReasonKey}.unselectWarningUsersOrGroupsV2` : '',
          ].filter(_.identity).map(key => $translate.instant(key, {
            jobs: node._protectingJobs.map(j => j.name).join(', '),
            entities: options.descendantUserOrGroupNames.join(', '),
          })).join(' '),
        };

        // using same node removal message when node is not owned by logged-in user.
        if (!node._owner.isSourceOwner) {
          node._canRemove = node._canRemoveForMiniView;
        }
      }
    }

    function _isNodeAncestorProtected(node) {
      return node._isProtected || (node._selectedAncestor && node._isPartiallyProtected);
    }
  }

})(angular);
