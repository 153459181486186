import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

/**
 * Delete policy confirmation dialog component.
 */
@Component({
  selector: 'coh-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public dialogParam: any) {}
}
