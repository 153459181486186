import { Component, Input } from '@angular/core';
import { HeliosStatsServiceApi, LastProtectionRunStats, ProtectedObjectsSummary, StatsServiceApi } from '@cohesity/api/v1';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AutoDestroyable } from '@cohesity/utils';
import { RemoteClusterService, ScopeSelectorService } from 'src/app/core/services';
import { DataByGroup } from 'src/app/shared';
import { reduceByGroup } from 'src/app/util';

import { TopologyEdge, TopologyElement, TopologyNode } from '../topology-graph/topology-graph';

/**
 * @description
 * This component displays the topology element (Node or Edge) information.
 * It is used by the graph to show it in tooltip when mouse over the element.
 *
 * @example
 *   <coh-topology-element [element]="element" [nodes]="nodes"></coh-topology-element>
 */
@Component({
  selector: 'coh-topology-element',
  templateUrl: './topology-element.component.html',
  styleUrls: ['./topology-element.component.scss']
})
export class TopologyElementComponent extends AutoDestroyable {
  /**
   * Nodes of graph. Used for display title for edge when nodes name are needed.
   */
  @Input() nodes: TopologyNode[];

  /**
   * Topology Element.
   */
  _element: TopologyElement;

  /**
   * Title of tooltip, only needed for edge.
   */
  title: string;

  /**
   * Number of total protected objects.
   */
  numObjects: number;

  /**
   * Number of failed runs from last run.
   */
  numRunsFailed: number;

  /**
   * Number of missed SLA runs from last run.
   */
  numRunsFailedSla: number;

  /**
   * Number of objects by Group.
   */
  statsByGroup: DataByGroup[];

  /**
   * Gets the date range end time
   */
  get endTime(): number {
    return this.element.dateRange?.[1];
  }

  /**
   * Gets the date range start time
   */
  get startTime(): number {
    return this.element.dateRange?.[0];
  }

  /**
   * Constructor.
   */
  constructor(
    private heliosStatsService: HeliosStatsServiceApi,
    private remoteClusterService: RemoteClusterService,
    private scopeSelectorService: ScopeSelectorService,
    private statsService: StatsServiceApi,
    private translateService: TranslateService
  ) {
    super();
  }

  /**
   * Sets the summary data from protection summary API call result
   *
   * @param     summary    Protection summary API call result.
   */
  setSummary(summary: Observable<ProtectedObjectsSummary>) {
    summary.pipe(
      this.untilDestroy()
    )
    .subscribe(({ numObjectsProtected, statsByEnv }) => {
      this.numObjects = numObjectsProtected;
      this.statsByGroup = reduceByGroup(statsByEnv, 'numObjectsProtected', undefined, 3);
    });
  }

  /**
   * Sets the runs data from last run stats API call result
   *
   * @param     stats    Last run stats API call result.
   */
  setStats(stats: Observable<LastProtectionRunStats>) {
    stats.pipe(
      this.untilDestroy()
    )
    .subscribe(({ statsByEnv }) => {
      if (statsByEnv) {
        this.numRunsFailed = statsByEnv.reduce((sum, stat) => sum + stat.numObjectsFailed, 0);
        this.numRunsFailedSla = statsByEnv.reduce((sum, stat) => sum + stat.numObjectsFailedSla, 0);
      }
    });
  }

  /**
   * Gets the node or edge data for display when node or edge is set
   *
   * @param     element    The node or edge object of interest
   */
  @Input()
  set element(element: TopologyElement) {
    if (element) {
      this._element = element;
      if ((<TopologyEdge>element).from) {
        this.title = this.translateService.instant('archivalFromSourceToTarget', {
          source: this.nodes.find(node => node.id === (<TopologyEdge>element).from).label,
          target: this.nodes.find(node => node.id === (<TopologyEdge>element).to).label
        });
        // TODO(ang): add tooltip content for edge when available from API
      } else {
        const { id, name, type, activities } = <TopologyNode>element;

        if (!activities) {
          const isMcm = this.scopeSelectorService.isMcm;

          this.title = name;
          if (type === 'cluster' || (isMcm && type === 'remote')) {
            if (isMcm) {
              const [ clusterId, clusterIncarnationId ] = id.split(':').map(Number);

              // Check if cluster is in the connected list before making API calls to get data.
              this.remoteClusterService.getRemoteClustersList().subscribe((clusters) => {
                if (clusters && clusters.find(
                  cluster => cluster.clusterId === clusterId && cluster.clusterIncarnationId === clusterIncarnationId
                )) {
                  this.setSummary(this.heliosStatsService.McmProtectionSummary({clusterIdentifiers: [ id ]}));
                  this.setStats(this.heliosStatsService.McmGetLastProtectionRunStats({clusterIdentifiers: [ id ]}));
                }
              });
            } else {
              this.setSummary(this.statsService.GetProtectedObjectsSummary({}));
              this.setStats(this.statsService.GetLastProtectionRunStats({}));
            }
          }
        }
      }
    }
  }

  /**
   * Gets topology element.
   */
  get element(): TopologyElement {
    return this._element;
  }
}
