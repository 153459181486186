// COMPONENT: cSearchService

(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.search';
  var moduleDeps = ['smart-table', 'ngSanitize', 'C.filters'];
  var searchTypes = [
    'activeDirectory',
    'adObjects',
    'vm',
    'file',
    'browsableEntities',
    'sql',
    'oracle',
    'mountPoint',
    'view',
  ];

  var module;

  try {
    module = angular
      .module(moduleName);
  } catch (e) {
    module = angular
      .module(moduleName, moduleDeps);
  }
  module
    .service('cSearchService', cSearchService);

  /**
   * @ngdoc service
   * @service
   * @name  C.search.cSearchService
   * @description
   *   This is the Glue that binds this suite of directives together, by
   *   searchId. Each directive is responsible for it's own behaviors, and
   *   syncs user-initiated changes through this service.
   *
   * @requires $http, $filter, DateTimeService, SearchService
   */
  function cSearchService(
    $http, $filter, $q, evalAJAX, DateTimeService, SearchService, FEATURE_FLAGS,
    ENV_GROUPS) {
    // Defines the Service's public API
    var searchService = {
      isRegistered: isRegistered,
      registerInstance: registerInstance,
      getLastRegisteredInstanceId: getLastRegisteredInstanceId,
      getResults: getResults,
      purgeResults: purgeResults,
      destroy: destroy,
      updateCollection: updateCollection,
      updateInstanceConfig: updateInstanceConfig,
      getInstanceConfig: getInstanceConfig,
      selectRows: selectRows,
      deselectRows: deselectRows,
      addFilter: addFilter,
      removeFilter: removeFilter,
      unlockFilters: unlockFilters,
      resetFilters: resetFilters,
      resetSearch: resetSearch,
      getSearchTypes: getSearchTypes,
      updateLocalFilters: updateLocalFilters,

      // helper & processing functions
      displayLabel: displayLabel,
      containsFilter: containsFilter,
      processForDisplay: processForDisplay,
      areFiltersSame: areFiltersSame,
    };
    // Internal hash of registered instance configs
    var instances = {};
    // The last registered instanceId
    var lastRegisteredInstance;
    // @type {String} - The Cohesity default date format
    var dateFormat = DateTimeService.getPreferredDateFormat();
    // @type {String} - The Cohesity date+time format
    var dateTimeFormat = DateTimeService.getPreferredFormat();

    /**
     * Internal method that determines if there's a registered cSearch instance
     *
     * @method     isRegistered
     * @param      {String}   instanceId  Instance ID to check
     * @return     {Boolean}  Whether or not the instance is registered
     */
    function isRegistered(instanceId) {
      return !!instances[instanceId];
    }

    /**
     * Registers a new instance of the cSearch. Will simply return any
     * already registered instance
     *
     * @method     registerInstance
     * @param      {String}  instanceId  Instance ID to register
     * @return     {Object}  The registered instance
     */
    function registerInstance(instanceId) {
      instanceId = instanceId || moduleName+Date.now();
      if (!isRegistered(instanceId)) {
        instances[instanceId] = {
          _collection: [],
          _filters: undefined,
          localFilters: {},
          filterOptions: {},
          collection: [],
          filters: [],
          activeFilters: [],
          selectedRows: [],
          endpoint: undefined
        };
        lastRegisteredInstance = instanceId;
      }
      return instances[instanceId];
    }

    /**
     * Returns the most recently registered Instance ID
     *
     * @method     getLastRegisteredInstanceId
     * @return     {String}  The most recently registered Instance ID
     */
    function getLastRegisteredInstanceId() {
      return lastRegisteredInstance;
    }

    /**
     * Updates the config for the given searchId.
     * TODO: Update this to overwrite and not extend.
     *
     * @method     updateInstanceConfig
     * @param      {String}  instanceId  Instance ID
     * @param      {Object}  opts        Config object
     * @return     {Object}  The updated config object
     */
    function updateInstanceConfig(instanceId, opts) {
      if (isRegistered(instanceId)) {
        instances[instanceId] = angular.extend(instances[instanceId], opts);
        // Make a backup of initial filters
        if (!instances[instanceId]._filters &&
          angular.isArray(opts.filters) &&
          opts.filters.length > 1) {
            instances[instanceId]._filters = angular.copy(opts.filters);
        }
      }
      return instances[instanceId] || undefined;
    }

    /**
     * Retrieves the config object for the given searchId
     *
     * @method     getInstanceConfig
     * @param      {String}  instanceId  The searchId
     * @return     {Object}  Config object for given searchId else undefined
     */
    function getInstanceConfig(instanceId) {
      return instances[instanceId] || {};
    }


    /**
     * Empties the search results and selected items list (like a reset)
     *
     * @method     purgeResults
     * @param      {String}  instanceId  The searchId
     * @return     {Object}  Updated config object for given searchId else undefined
     */
    function purgeResults(instanceId) {
      if (isRegistered(instanceId)) {
        instances[instanceId]._collection.length = 0;
        instances[instanceId].collection.length = 0;
        instances[instanceId].selectedRows.length = 0;
      }
      return instances[instanceId] || {};
    }

    /**
     * Destroy the given search instance
     *
     * @method     destroy
     * @param      {String}  instanceId  The searchId
     */
    function destroy(instanceId) {
      delete instances[instanceId];
      return {};
    }

    /**
     * Function to query the configured endpoint.
     * TODO: Make this *more* optional
     *
     * @method     getResults
     * @param      {String}  instanceId  The searchId
     * @param      {Object}  opts        $http service compatible object
     * @return     {Q}  Promise from server
     */
    function getResults(instanceId, opts, endpoint) {
      if (angular.equals({},instances)) {
        return $q.resolve();
      }

      var thisInstance = instances[instanceId];
      var preProcessor = thisInstance.preProcessor;
      var excludeJobs = thisInstance.excludeJobs;

      // Override endpoint if provided
      thisInstance.endpoint = endpoint ? endpoint : thisInstance.endpoint;
      opts = angular.extend({
        method: 'GET',
        url: isRegistered(instanceId) ? thisInstance.endpoint : null,
        params: {}
      }, opts);

      // Apply filter value transformations now
      thisInstance.activeFilters.forEach(
        function activeFilterTransformer(filter) {
          opts.params[filter.property] = !filter.transformFn ?
            filter.value : filter.transformFn(filter.value);
        }
      );

      // Provide a default set of entityTypes for browsableEntities and
      // mountPoint searches if specific filtering by entityType wasn't
      // specified. Other search types have entityTypes defined in
      // SearchService.getSearchUrl() but because these searches have filtering
      // options for entity type this special handling was added.
      if (!opts.params.entityTypes &&
        ['mountPoint', 'browsableEntities'].includes(thisInstance.searchType)) {
        opts.params.entityTypes = SearchService.getBrowsableEnvironments();
      }

      return $http(opts)
        .then(function dataReceived(resp) {
          resp = (!angular.isFunction(preProcessor)) ? resp :
            preProcessor(resp, excludeJobs);
          instances[instanceId]._collection = resp;
          updateCollection(instanceId, instances[instanceId]._collection);
          return resp;
        }, function dataError(resp) {
          evalAJAX.errorMessage(resp);
          purgeResults(instanceId);
          return resp;
        });
    }

    /**
     * Convenience method to overwrite the master collection for this instance
     *
     * @method     updateCollection
     * @param      {String}  instanceId  The searchId
     * @param      {Array}   data        The collection to update with
     * @return     {Void}
     */
    function updateCollection(instanceId, data) {
      if (isRegistered(instanceId)) {
        instances[instanceId].collection = applyLocalFilters(instanceId);
      }
    }

    /**
     * Function to generate the collection of rows the user selected
     *
     * @method     selectRows
     * @param      {String}   instanceId  The searchId
     * @param      {Array}    rows        Array of rows the user has selected
     * @return     {Void}
     */
    function selectRows(instanceId, rows) {
      var selectedIndex = -1;
      if (!rows) {
        return false;
      }
      rows = [].concat(rows);
      if (isRegistered(instanceId)) {
        rows.forEach(function eachRow(row, ii) {
          selectedIndex = instances[instanceId].selectedRows.indexOf(row);
          if (selectedIndex < 0) {
            instances[instanceId].selectedRows.push(row);
          }
        });
      }
    }

    /**
     * Does the opposite of selectRows. This function removes the passed in
     * collection from the selected rows collection
     *
     * @method     deselectRows
     * @param      {String}   instanceId  The searchId
     * @param      {Array}    rows        Collection of rows to remove from
     *                                    selected rows collection
     * @return     {Void}
     */
    function deselectRows(instanceId, rows) {
      var selectedIndex = -1;
      if (!rows) {
        return false;
      }
      rows = [].concat(rows);
      if (isRegistered(instanceId)) {
        rows.forEach(function eachRow(row, ii) {
          selectedIndex = instances[instanceId].selectedRows.indexOf(row);
          if (selectedIndex > -1) {
            instances[instanceId].selectedRows.splice(selectedIndex, 1);
          }
        });
      }
    }

    /**
     * Adds a filter to the list of active filters. Also removes the filter
     * from the list of available properties.
     *
     * @method     addFilter
     * @param      {String}  instanceId  The searchId
     * @param      {Object}  filter      Filter object to activate
     * @return     {Array}   The list of active filters in the current instance
     */
    function addFilter(instanceId, filter) {
      if (isRegistered(instanceId)) {
        instances[instanceId].filters.some(function FindFn(_filter) {
          if (_filter.property === filter.property) {
            // Add it to the activeFilters list if its not already present
            // else replace it.
            if (!containsFilter(instances[instanceId].activeFilters, filter)) {
              instances[instanceId].activeFilters.push(filter);
            } else {
              instances[instanceId].activeFilters.forEach(
                function replaceWithNew(activeFilter) {
                  if (activeFilter.property === filter.property) {
                    activeFilter = filter;
                  }
                }
              );
            }
            return true;
          }
          return false;
        });
      }
      return (instances[instanceId].activeFilters) ?
          instances[instanceId].activeFilters : [];
    }

    /**
     * Removes a filter from the list of active filters. Also restores the
     * sanitized filter back to the list of available filterable properties.
     *
     * @method     removeFilter
     * @param      {String}  instanceId  The searchId
     * @param      {Object}  filter      Filter object to deactivate
     * @return     {Array}   The list of active filters in the current instance
     */
    function removeFilter(instanceId, filter) {
      var activeFilters = instances[instanceId] ?
        instances[instanceId].activeFilters : [];

      var filterIndex = activeFilters.indexOf(filter);
      activeFilters.splice(filterIndex, 1);

      return activeFilters;
    }

    /**
     * Unlocks filters without un-applying them. Optional argument to also
     * un-apply them
     *
     * @method     unlockFilters
     * @param      {String}  instanceId  The searchId
     * @param      {Bool=}   remove      True to un-apply filters (reset)
     * @return     {Array}   The currently active Filters
     */
    function unlockFilters(instanceId, remove) {
      if (isRegistered(instanceId)) {
        if (remove) {
          resetFilters(instanceId, true);
        } else {
          instances[instanceId].activeFilters.forEach(function filterUnlockerFn(filter) {
            filter.locked = false;
          });
        }
      }
      return (instances[instanceId]) ? instances[instanceId].activeFilters : [];
    }

    /**
     * Resets the filters for the given search instance, ignoring any locked
     * filters. Optional param to also reset locked filters.
     *
     * @method     resetFilters
     * @param      {String}  instanceId  The searchId
     * @param      {Bool=}   force       Include locked filters
     * @return     {Array}   List of current filters
     */
    function resetFilters(instanceId, force) {
      if (isRegistered(instanceId)) {
        instances[instanceId].activeFilters = instances[instanceId]
          .activeFilters.filter(
            function resetActiveFiltersFn(_filter) {
              if ((force || !_filter.locked)) {
                _filter.locked = false;
                delete _filter.value;
                // Put the filter back in the filters list
                instances[instanceId].filters.push(_filter);
                return false;
              }
              return true;
            }
          );
      }
      return instances[instanceId] ? instances[instanceId].filters : [];
    }

    /**
     * Resets the search data keeping the pre-defined filters and endpoint
     *
     * @method     resetSearch
     * @param      {String}  instanceId  The Instance Id
     * @return     {Object}              The reset Instance
     */
    function resetSearch(instanceId) {
      if (isRegistered(instanceId)) {
        angular.extend(instances[instanceId], {
          _collection: [],
          collection: [],
          filters: angular.copy(instances[instanceId]._filters),
          activeFilters: [],
          localFilters: {},
          selectedRows: []
        });
        lastRegisteredInstance = instanceId;
      }
      return instances[instanceId] || {};
    }

    /**
     * Detects if the filter.display property is a function. if it is,
     * execute it and return it's returned value. Otherwise just use the
     * string as it is.
     *
     * @method     displayLabel
     * @param      {Object}  filter  Standard filter object
     * @return     {String}  The processed filter label string
     */
    function displayLabel(filter) {
      if (filter) {
        return angular.isFunction(filter.display) ?
          filter.display(filter) :
          filter.display || filter.property;
      }
    }

    /**
     * Determine if the filter is in the list of filters.
     *
     * @method     containsFilter
     * @param      {Array}   searchFilters  List of filters to serach
     * @param      {Object}   filter   filter to check for inclusion
     * @return     {boolean}  True if it's included in search list, false if not
     */
    function containsFilter(searchFilters, filter) {
      return searchFilters.some(function findActiveFilterFn(_filter) {
        return (_filter.property === filter.property);
      });
    }

    /**
     * Process a filter object value for display. This means transforming
     * weird date formats into human readable standard formats, joining
     * Arrays into pretty lists, and anything else needed for pretty
     * display.
     *
     * @method     processForDisplay
     * @param      {String|Array|?}  value   Filter value
     * @return     {String}          Transformed filter value
     */
    function processForDisplay(value) {
      var hasTime = false;
      switch (true) {
        case (angular.isUndefined(value)):
          return value;

        case (angular.isString(value.display)):
          return value.display;

        case angular.isArray(value):
          return value.join(', ');

        case angular.isDate(value):
          // Checks if the date object has time.
          hasTime = +$filter('date')(value, 'Hms') > 0;
          return DateTimeService.msecsToFormattedDate(value, hasTime ? dateTimeFormat : dateFormat);

        default:
          return value;
      }
    }

    /**
     * Convenience method to update local filters for this search instance.
     *
     * @method     updateLocalFilters
     * @param      {string}  instanceId  The instance identifier
     * @param      {object}  filters     The filters map
     * @return     {object}  The updated config object
     */
    function updateLocalFilters(instanceId, filters) {
      var thisInstance = {};
      if (isRegistered(instanceId)) {
        thisInstance = instances[instanceId];
        thisInstance.localFilters = filters;
        // Update the collection
        updateCollection(instanceId, thisInstance._collection);
      }
      return thisInstance;
    }


    /**
     * Apply the local filters
     *
     * @method     applyLocalFilters
     * @param      {string}  instanceId  The instance identifier
     * @return     {array}   The filtered collection
     */
    function applyLocalFilters(instanceId) {
      var thisInstance;
      if (isRegistered(instanceId)) {
        thisInstance = instances[instanceId];
        if (!Object.keys(thisInstance.localFilters).length) {
          // No filters! Bail now and show everything.
          return thisInstance._collection;
        }
        return thisInstance._collection.filter(function localFilterCollectionFn(entity, ii) {
          var match = true;
          var typedDoc = SearchService.getTypedDocument(entity);
          angular.forEach(thisInstance.localFilters, function eachFilterFn(value, type) {
            switch (type) {
              // This filter is an Array of 0+ integers representing entity types
              case 'entityTypes':
                match = match && value && typedDoc && typedDoc.objectId &&
                  // True if the filter is empty OR the entity
                  // type is in the `value` list
                  (!value.length || value.includes(typedDoc.objectId.entity.type));
                break;

              default:
                match = true;
            }
          });
          return match;
        });
      }
      return thisInstance;
    }

    /**
     * Get the search types.
     *
     * @method     getSearchTypes
     * @return     {array}  The list of supported search type strings
     */
    function getSearchTypes() {
      return angular.copy(searchTypes);
    }

    /**
     * Determines if 2 arrays of search fiters are the same, ignorant of their
     * ordering.
     *
     * @method    areFiltersSame
     * @param     {array}   array1   The first array (typically the new in a
     *                               $watch).
     * @param     {array}   array2   The second array (typically the old in a
     *                               $watch).
     * @returns   {boolean}   True if the filter lists are the same, false if
     *                        different.
     */
    function areFiltersSame(array1, array2) {
      // @type  {array}  - Holder for a copy of array1 input.
      var dwindlingArray1;
      // @type  {array}  - Holder for a copy of array2 input.
      var dwindlingArray2;

      // Quick checks: One is not an array, OR their lengths differ: return
      // false.
      if (!Array.isArray(array1) || !Array.isArray(array2) ||
        (array1.length !== array2.length)) {
        return false;
      }

      // Quick checks: They are equal objects by reference: return true.
      if (array1 === array2) {
        return true;
      }

      // Quick checks have all failed: make a shallow copy of both inputs so we
      // don't modify the originals. And sort them so they are as similarly
      // ordered as possible to reduce recursion in the next step (which is the
      // more expensive loop).
      dwindlingArray1 = array1.slice(0).sort();
      dwindlingArray2 = array2.slice(0).sort();

      /**
       * Recursive function that can handle arrays that are the same length, and
       * possibly have the same values, but in any order. Note: The more out of
       * order the inputs, the more iterations this takes.
       *
       * To minimize the iterations, for each true match we find, we remove
       * those items from each array to reduce the number of subsequent
       * recursion loops.
       *
       * @method    _areFiltersSame
       * @param     {array}   arr1   The 1st input array (is modified
       *                             internally).
       * @param     {array}   arr2   The 2nd input array (is modified
       *                             internally).
       * @returns   {boolean}   True if the arrays have the exact same values
       *                        within, false otherwise.
       */
      function _areFiltersSame(arr1, arr2) {
        return arr2.some(
          function findMatch(item2, ii) {
            if (angular.equals(arr1[0], item2)) {
              // We have a match, so splice out the matched elements from each
              // input array.
              arr1.splice(0, 1);
              arr2.splice(ii, 1);

              // Exit (true) if both sets are now empty (no more iterations),
              // otherwise check the next (reduced) set.
              return !(arr1.length && arr2.length) ||
                _areFiltersSame(arr1, arr2);
            }
          }
        );
      }

      return _areFiltersSame(dwindlingArray1, dwindlingArray2);
    }

    return searchService;
  }

})(angular);
