import { Injectable } from '@angular/core';
import { ClusterIdentifier, UniversalId } from '@cohesity/api/v1';
import { HeliosUser } from 'src/app/shared/models';

@Injectable({ providedIn: 'root' })
export class V1V2ApiUtilsService {
  /**
   * Converts a v1 jobUid into a v2 protectionGroupId.
   *
   * @param    jobUid   The v1 Job UID
   * @returns  The v2 protectionGroupId string.
   */
  generateV2protectionGroupId(jobUid: UniversalId): string {
    // Treat jobUid as any because `objectId` isn't a valid property of
    // UniversalId, even though Magneto defines it. This lets us account for
    // that.
    const {clusterIncarnationId, clusterId, id, objectId} = jobUid as any;
    const _id = objectId || id;

    if (isNaN(clusterIncarnationId) || isNaN(_id) || isNaN(clusterId)) {
      throw new Error(`Invalid jobUid <UniversalId>: ${jobUid}`);
    }

    return `${clusterId}:${clusterIncarnationId}:${_id}`;
  }

  /**
   * Splits a protection group id into it's V1 representation as a UniversalId
   *
   * @param   protectionGroupId: string
   * @returns The v1 uuid object.
   */
  splitV2ProtectionGroupId(protectionGroupId: string): UniversalId {
    const [clusterId, clusterIncarnationId, id] = protectionGroupId.split(':').map(token => +token);
    return {
      clusterId,
      clusterIncarnationId,
      id
    };
  }

  /**
   * Returns the cluster identifier as per V1 represnetation.
   *
   * @param   user   The Helios User.
   * @returns the array of cluster identifiers.
   */
  getClusterIdentifier(user: HeliosUser): ClusterIdentifier[] {
    return user.clusterIdentifiers || (user.clusters || []).map(cluster => {
      const clusterIdentifier = cluster.split(':').map(Number);
      return {
        clusterId: clusterIdentifier[0],
        clusterIncarnationId: clusterIdentifier[1],
      };
    });
  }

  /**
   * Get the cluster identifier as a combination of clusterId and clusterIncarnationId
   *
   * @param cluster The cluster object whose identifierId is to be found
   * @returns The cluster identifier
   */
  getClusterIdentifierString(clusterId: number, clusterIncarnationId: number): string {
    return `${clusterId}:${clusterIncarnationId}`;
  }
}
