import { Lexer, Parser, ParserRuleContext } from 'antlr4ts';

import { AdvancedSearchSuggester } from './advanced-search-suggester';
import { AdvancedSearchParams, CursorLocation, GetRawSuggestionFn } from './advanced-search.models';
import { GrammarOpts } from './grammar-opts';

/**
 * Input used for caching search query with cursor location.
 */
interface CacheParams {
  /** The Cursor location. */
  cursorLocation: CursorLocation;

  /** THe search query input by user. */
  searchQuery: string;

  /** The parse tree for the query. */
  parseTree: ParserRuleContext;
}

/**
 * This class provides a concrete implementation of AdvancedSearchSuggester
 * using the antlr4ts library for grammer, parsing and giving suggestions.
 * Suggestions are provided by visiting the tree context specific to antlr4ts library
 * which is constructed after parsing the search query using antlr generated parser.
 * This class is used by:
 * 1. Argus log search component.
 * (iris/apps/argus/src/app/modules/logs/components/logs-search/log-search.component.ts).
 * 2. Argus shield rule dialog component.
 * (iris/apps/argus/src/app/shared/shield-rule-dialog/shield-rule-dialog.component.ts).
 * 3. AWS EBS settings for volume exclusions component.
 * (iris-ui-angular/src/app/modules/protection/vm/components/settings-list-items/
 * settings-list-global-ebs-volume-exclusions/settings-list-global-ebs-volume-exclusions.component.ts).
 */
export class AntlrSuggester extends AdvancedSearchSuggester {
  /**
   * Stores the mapping of (search query) => (tree generated for the query).
   * This helps in caching the tree generated for search query as the user
   * may delete or add some character multiple times, leading to same query.
   */
  treeMap = new Map<string, ParserRuleContext>();

  /**
   * Stores the mapping of (search query with cursor location) => ({search query, suggestion}).
   * This helps in caching the suggestion for a particular search query and cursor location as
   * the user may change the cursor position many times to the previous location during typing.
   */
  suggestionMap = new Map<string, AdvancedSearchParams>();

  constructor(private grammarOpts: GrammarOpts<Lexer, Parser, ParserRuleContext>) {
    super();
  }

  /**
   * Used to return the suggestion to the user on the basis of search query and cursor location.
   *
   * @param searchQuery     Current input by user.
   * @param cursorLocation  Current cursor location.
   * @returns               An object containing the current input and suggestion.
   */
  getRawSuggestion: GetRawSuggestionFn = (searchQuery: string, cursorLocation: CursorLocation):
    AdvancedSearchParams => {
    this.searchQuery = searchQuery;
    this.cursorLocation = cursorLocation;

    // Cache the tree for search query.
    const { value: parseTree } = this.cacheInputStream(
      searchQuery,
      this.getKeyForSearchQuery,
      (query: string) => this.grammarOpts.getTree(query),
      this.treeMap
    );

    // Cache the suggestion for search query and cursor location.
    const { value: suggestionAndQuery } = this.cacheInputStream(
      { cursorLocation, searchQuery, parseTree },
      this.getKeyForSearchQueryAndLocation,
      (params: CacheParams) => {
        const { cursorLocation: caretLocation, searchQuery: query, parseTree: tree } = params;
        return {
          searchQuery: query,
          suggestion: this.grammarOpts.visitTree(
            query,
            caretLocation,
            tree
          ),
        };
      },
      this.suggestionMap
    );

    return suggestionAndQuery;
  };

  /**
   * Function to get the key for search string.
   *
   * @param   input The input used to get key.
   * @returns       The key.
   */
  getKeyForSearchQuery(input: string): string {
    return input;
  }

  /**
   * Function to get the key for search string and location.
   *
   * @param   params  Object containing searchQuery, CursorLocation, ParseTree.
   * @returns         The key.
   */
  getKeyForSearchQueryAndLocation(params: CacheParams): string {
    const cursorLocation = params.cursorLocation;
    const searchQuery = params.searchQuery;

    return [cursorLocation.start, cursorLocation.end, searchQuery].join(':');
  }

  /**
   * Cache for mapped values for a given input to same re-mapping computation.
   *
   * @param input       The input that will be used to get key and value.
   * @param getKeyFn    The method used to return the cache key for a given input.
   * @param getValueFn  The method used to return the mapped value for a given input.
   * @param cache       The cache that will be used for memoizing the key-value pair.
   * @returns           An object containing the input and the mapped value.
   */
  cacheInputStream<Key, Value, Input>(
    input: Input,
    getKeyFn: (input: Input) => Key,
    getValueFn: (input: Input) => Value,
    cache: Map<Key, Value>
  ) {
    // Get key from input.
    const key = getKeyFn(input);

    if (!cache.has(key)) {
      // Compute and add mapped value into the cache.
      cache.set(key, getValueFn(input));
    }

    // Return input and cached value.
    return { input, value: cache.get(key) };
  }
}
