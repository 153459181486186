/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ProtectionPolicyResponseWithPagination } from '../models/protection-policy-response-with-pagination';
import { ProtectionPolicyResponse } from '../models/protection-policy-response';
import { ProtectionPolicyRequest } from '../models/protection-policy-request';
import { PolicyTemplatesResponseWithPagination } from '../models/policy-templates-response-with-pagination';
import { PolicyTemplateResponse } from '../models/policy-template-response';
import { HeliosPoliciesResponseWithPagination } from '../models/helios-policies-response-with-pagination';
import { HeliosPolicyResponse } from '../models/helios-policy-response';
import { HeliosPolicyRequest } from '../models/helios-policy-request';
@Injectable({
  providedIn: 'root',
})
class PolicyService extends __BaseService {
  static readonly GetProtectionPoliciesPath = '/data-protect/policies';
  static readonly CreateProtectionPolicyPath = '/data-protect/policies';
  static readonly GetProtectionPolicyByIdPath = '/data-protect/policies/{id}';
  static readonly UpdateProtectionPolicyPath = '/data-protect/policies/{id}';
  static readonly DeleteProtectionPolicyPath = '/data-protect/policies/{id}';
  static readonly GetPolicyTemplatesPath = '/data-protect/policy-templates';
  static readonly GetPolicyTemplateByIdPath = '/data-protect/policy-templates/{id}';
  static readonly GetHeliosPoliciesPath = '/mcm/data-protect/policies';
  static readonly CreateHeliosPolicyPath = '/mcm/data-protect/policies';
  static readonly GetHeliosPolicyByIdPath = '/mcm/data-protect/policies/{id}';
  static readonly UpdateHeliosPolicyPath = '/mcm/data-protect/policies/{id}';
  static readonly DeleteHeliosPolicyPath = '/mcm/data-protect/policies/{id}';
  static readonly CreateInternalHeliosPolicyFromKeplerPath = '/mcm/kepler-internal/policies';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List Protection Policies based on provided filtering parameters.
   *
   * Lists protection policies based on filtering query parameters.
   * @param params The `PolicyService.GetProtectionPoliciesParams` containing the following parameters:
   *
   * - `vaultIds`: Filter by a list of Vault ids. Policies archiving to any of the specified vaults will be returned.
   *
   * - `types`: Types specifies the policy type of policies to be returned
   *
   * - `tenantIds`: TenantIds contains ids of the organizations for which objects are to be returned.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `policyNames`: Filter policies by a list of policy names.
   *
   * - `includeTenants`: IncludeTenantPolicies specifies if objects of all the organizations under the hierarchy of the logged in user's organization should be returned.
   *
   * - `includeStats`: If includeStats is set to true, then response will return number of protection groups and objects. By default, the protection stats are not included in the response.
   *
   * - `includeReplicatedPolicies`: If includeReplicatedPolicies is set to true, then response will also contain replicated policies. By default, replication policies are not included in the response.
   *
   * - `ids`: Filter policies by a list of policy ids.
   *
   * - `excludeLinkedPolicies`: If excludeLinkedPolicies is set to true then only local policies created on cluster will be returned. The result will exclude all linked policies created from policy templates.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionPoliciesResponse(params: PolicyService.GetProtectionPoliciesParams): __Observable<__StrictHttpResponse<ProtectionPolicyResponseWithPagination>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.vaultIds || []).forEach(val => {if (val != null) __params = __params.append('vaultIds', val.toString())});
    (params.types || []).forEach(val => {if (val != null) __params = __params.append('types', val.toString())});
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.requestInitiatorType != null) __headers = __headers.set('requestInitiatorType', params.requestInitiatorType.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.policyNames || []).forEach(val => {if (val != null) __params = __params.append('policyNames', val.toString())});
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.includeStats != null) __params = __params.set('includeStats', params.includeStats.toString());
    if (params.includeReplicatedPolicies != null) __params = __params.set('includeReplicatedPolicies', params.includeReplicatedPolicies.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.excludeLinkedPolicies != null) __params = __params.set('excludeLinkedPolicies', params.excludeLinkedPolicies.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/policies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionPolicyResponseWithPagination>;
      })
    );
  }
  /**
   * List Protection Policies based on provided filtering parameters.
   *
   * Lists protection policies based on filtering query parameters.
   * @param params The `PolicyService.GetProtectionPoliciesParams` containing the following parameters:
   *
   * - `vaultIds`: Filter by a list of Vault ids. Policies archiving to any of the specified vaults will be returned.
   *
   * - `types`: Types specifies the policy type of policies to be returned
   *
   * - `tenantIds`: TenantIds contains ids of the organizations for which objects are to be returned.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `policyNames`: Filter policies by a list of policy names.
   *
   * - `includeTenants`: IncludeTenantPolicies specifies if objects of all the organizations under the hierarchy of the logged in user's organization should be returned.
   *
   * - `includeStats`: If includeStats is set to true, then response will return number of protection groups and objects. By default, the protection stats are not included in the response.
   *
   * - `includeReplicatedPolicies`: If includeReplicatedPolicies is set to true, then response will also contain replicated policies. By default, replication policies are not included in the response.
   *
   * - `ids`: Filter policies by a list of policy ids.
   *
   * - `excludeLinkedPolicies`: If excludeLinkedPolicies is set to true then only local policies created on cluster will be returned. The result will exclude all linked policies created from policy templates.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionPolicies(params: PolicyService.GetProtectionPoliciesParams): __Observable<ProtectionPolicyResponseWithPagination> {
    return this.GetProtectionPoliciesResponse(params).pipe(
      __map(_r => _r.body as ProtectionPolicyResponseWithPagination)
    );
  }

  /**
   * Create a Protection Policy.
   *
   * Create the Protection Policy and returns the newly created policy object.
   * @param params The `PolicyService.CreateProtectionPolicyParams` containing the following parameters:
   *
   * - `body`: Request to create a Protection Policy.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateProtectionPolicyResponse(params: PolicyService.CreateProtectionPolicyParams): __Observable<__StrictHttpResponse<ProtectionPolicyResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/policies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionPolicyResponse>;
      })
    );
  }
  /**
   * Create a Protection Policy.
   *
   * Create the Protection Policy and returns the newly created policy object.
   * @param params The `PolicyService.CreateProtectionPolicyParams` containing the following parameters:
   *
   * - `body`: Request to create a Protection Policy.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateProtectionPolicy(params: PolicyService.CreateProtectionPolicyParams): __Observable<ProtectionPolicyResponse> {
    return this.CreateProtectionPolicyResponse(params).pipe(
      __map(_r => _r.body as ProtectionPolicyResponse)
    );
  }

  /**
   * List details about a single Protection Policy.
   *
   * Returns the Protection Policy details based on provided Policy Id.
   * @param params The `PolicyService.GetProtectionPolicyByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Policy to return.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionPolicyByIdResponse(params: PolicyService.GetProtectionPolicyByIdParams): __Observable<__StrictHttpResponse<ProtectionPolicyResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.requestInitiatorType != null) __headers = __headers.set('requestInitiatorType', params.requestInitiatorType.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/policies/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionPolicyResponse>;
      })
    );
  }
  /**
   * List details about a single Protection Policy.
   *
   * Returns the Protection Policy details based on provided Policy Id.
   * @param params The `PolicyService.GetProtectionPolicyByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Policy to return.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionPolicyById(params: PolicyService.GetProtectionPolicyByIdParams): __Observable<ProtectionPolicyResponse> {
    return this.GetProtectionPolicyByIdResponse(params).pipe(
      __map(_r => _r.body as ProtectionPolicyResponse)
    );
  }

  /**
   * Update a Protection Policy.
   *
   * Specifies the request to update the existing Protection Policy. On successful update, returns the updated policy object.
   * @param params The `PolicyService.UpdateProtectionPolicyParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Policy to update.
   *
   * - `body`: Request to update a Protection Policy.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateProtectionPolicyResponse(params: PolicyService.UpdateProtectionPolicyParams): __Observable<__StrictHttpResponse<ProtectionPolicyResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/data-protect/policies/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionPolicyResponse>;
      })
    );
  }
  /**
   * Update a Protection Policy.
   *
   * Specifies the request to update the existing Protection Policy. On successful update, returns the updated policy object.
   * @param params The `PolicyService.UpdateProtectionPolicyParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Policy to update.
   *
   * - `body`: Request to update a Protection Policy.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateProtectionPolicy(params: PolicyService.UpdateProtectionPolicyParams): __Observable<ProtectionPolicyResponse> {
    return this.UpdateProtectionPolicyResponse(params).pipe(
      __map(_r => _r.body as ProtectionPolicyResponse)
    );
  }

  /**
   * Delete a Protection Policy.
   *
   * Deletes a Protection Policy based on given policy id.
   * @param params The `PolicyService.DeleteProtectionPolicyParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Policy to delete.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteProtectionPolicyResponse(params: PolicyService.DeleteProtectionPolicyParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/data-protect/policies/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a Protection Policy.
   *
   * Deletes a Protection Policy based on given policy id.
   * @param params The `PolicyService.DeleteProtectionPolicyParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Policy to delete.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteProtectionPolicy(params: PolicyService.DeleteProtectionPolicyParams): __Observable<null> {
    return this.DeleteProtectionPolicyResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * List Policy Templates filtered by query parameters.
   *
   * Returns the policy templates based on the filtering parameters. If no parameters are specified, then all the policy templates are returned.
   * @param params The `PolicyService.GetPolicyTemplatesParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the organizations for which objects are to be returned.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `policyNames`: Filter policies by a list of policy names.
   *
   * - `includeTenants`: IncludeTenantPolicies specifies if objects of all the organizations under the hierarchy of the logged in user's organization should be returned.
   *
   * - `ids`: Filter policies by a list of policy template ids.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetPolicyTemplatesResponse(params: PolicyService.GetPolicyTemplatesParams): __Observable<__StrictHttpResponse<PolicyTemplatesResponseWithPagination>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.requestInitiatorType != null) __headers = __headers.set('requestInitiatorType', params.requestInitiatorType.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.policyNames || []).forEach(val => {if (val != null) __params = __params.append('policyNames', val.toString())});
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/policy-templates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PolicyTemplatesResponseWithPagination>;
      })
    );
  }
  /**
   * List Policy Templates filtered by query parameters.
   *
   * Returns the policy templates based on the filtering parameters. If no parameters are specified, then all the policy templates are returned.
   * @param params The `PolicyService.GetPolicyTemplatesParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the organizations for which objects are to be returned.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `policyNames`: Filter policies by a list of policy names.
   *
   * - `includeTenants`: IncludeTenantPolicies specifies if objects of all the organizations under the hierarchy of the logged in user's organization should be returned.
   *
   * - `ids`: Filter policies by a list of policy template ids.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetPolicyTemplates(params: PolicyService.GetPolicyTemplatesParams): __Observable<PolicyTemplatesResponseWithPagination> {
    return this.GetPolicyTemplatesResponse(params).pipe(
      __map(_r => _r.body as PolicyTemplatesResponseWithPagination)
    );
  }

  /**
   * List details about a single Policy Template.
   *
   * Returns the Policy Template corresponding to the specified Policy Id.
   * @param params The `PolicyService.GetPolicyTemplateByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Policy Template to return.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetPolicyTemplateByIdResponse(params: PolicyService.GetPolicyTemplateByIdParams): __Observable<__StrictHttpResponse<PolicyTemplateResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/policy-templates/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PolicyTemplateResponse>;
      })
    );
  }
  /**
   * List details about a single Policy Template.
   *
   * Returns the Policy Template corresponding to the specified Policy Id.
   * @param params The `PolicyService.GetPolicyTemplateByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Policy Template to return.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetPolicyTemplateById(params: PolicyService.GetPolicyTemplateByIdParams): __Observable<PolicyTemplateResponse> {
    return this.GetPolicyTemplateByIdResponse(params).pipe(
      __map(_r => _r.body as PolicyTemplateResponse)
    );
  }

  /**
   * List Policies based on provided filtering parameters.
   *
   * Lists policies based on filtering query parameters.
   * @param params The `PolicyService.GetHeliosPoliciesParams` containing the following parameters:
   *
   * - `types`: Type specifies the policy type of policies to be returned. If not specified, all types of policies are fetched.
   *
   * - `tenantIds`: List of Tenant Ids to filter from. This is applicable only for type OnPremPolicy.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `policyNames`: Filter policies by a list of policy names.
   *
   * - `ids`: Filter policies by a list of policy ids.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers.
   *   This is applicable only for type OnPremPolicy. The format is
   *   clusterId:clusterIncarnationId.
   *
   * @return Success
   */
  GetHeliosPoliciesResponse(params: PolicyService.GetHeliosPoliciesParams): __Observable<__StrictHttpResponse<HeliosPoliciesResponseWithPagination>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.types || []).forEach(val => {if (val != null) __params = __params.append('types', val.toString())});
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.policyNames || []).forEach(val => {if (val != null) __params = __params.append('policyNames', val.toString())});
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.clusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('clusterIdentifiers', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/data-protect/policies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HeliosPoliciesResponseWithPagination>;
      })
    );
  }
  /**
   * List Policies based on provided filtering parameters.
   *
   * Lists policies based on filtering query parameters.
   * @param params The `PolicyService.GetHeliosPoliciesParams` containing the following parameters:
   *
   * - `types`: Type specifies the policy type of policies to be returned. If not specified, all types of policies are fetched.
   *
   * - `tenantIds`: List of Tenant Ids to filter from. This is applicable only for type OnPremPolicy.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `policyNames`: Filter policies by a list of policy names.
   *
   * - `ids`: Filter policies by a list of policy ids.
   *
   * - `clusterIdentifiers`: Specifies the list of cluster identifiers.
   *   This is applicable only for type OnPremPolicy. The format is
   *   clusterId:clusterIncarnationId.
   *
   * @return Success
   */
  GetHeliosPolicies(params: PolicyService.GetHeliosPoliciesParams): __Observable<HeliosPoliciesResponseWithPagination> {
    return this.GetHeliosPoliciesResponse(params).pipe(
      __map(_r => _r.body as HeliosPoliciesResponseWithPagination)
    );
  }

  /**
   * Create a Policy.
   *
   * Create a Global policy or a DMaaS on Helios.
   * @param body Request to create a Helios Policy.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  CreateHeliosPolicyResponse(body: HeliosPolicyRequest,
    regionId?: string): __Observable<__StrictHttpResponse<HeliosPolicyResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/data-protect/policies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HeliosPolicyResponse>;
      })
    );
  }
  /**
   * Create a Policy.
   *
   * Create a Global policy or a DMaaS on Helios.
   * @param body Request to create a Helios Policy.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  CreateHeliosPolicy(body: HeliosPolicyRequest,
    regionId?: string): __Observable<HeliosPolicyResponse> {
    return this.CreateHeliosPolicyResponse(body, regionId).pipe(
      __map(_r => _r.body as HeliosPolicyResponse)
    );
  }

  /**
   * List details about a single Protection Policy.
   *
   * Returns the Protection Policy details based on provided Policy Id.
   * @param id Specifies a unique id of the Protection Policy to return.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetHeliosPolicyByIdResponse(id: string,
    regionId?: string): __Observable<__StrictHttpResponse<HeliosPolicyResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/data-protect/policies/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HeliosPolicyResponse>;
      })
    );
  }
  /**
   * List details about a single Protection Policy.
   *
   * Returns the Protection Policy details based on provided Policy Id.
   * @param id Specifies a unique id of the Protection Policy to return.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetHeliosPolicyById(id: string,
    regionId?: string): __Observable<HeliosPolicyResponse> {
    return this.GetHeliosPolicyByIdResponse(id, regionId).pipe(
      __map(_r => _r.body as HeliosPolicyResponse)
    );
  }

  /**
   * Update a Protection Policy.
   *
   * Specifies the request to update the existing Protection Policy. On successful update, returns the updated policy object.
   * @param params The `PolicyService.UpdateHeliosPolicyParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Policy to update.
   *
   * - `body`: Request to update a Protection Policy.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  UpdateHeliosPolicyResponse(params: PolicyService.UpdateHeliosPolicyParams): __Observable<__StrictHttpResponse<HeliosPolicyResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/mcm/data-protect/policies/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HeliosPolicyResponse>;
      })
    );
  }
  /**
   * Update a Protection Policy.
   *
   * Specifies the request to update the existing Protection Policy. On successful update, returns the updated policy object.
   * @param params The `PolicyService.UpdateHeliosPolicyParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Policy to update.
   *
   * - `body`: Request to update a Protection Policy.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  UpdateHeliosPolicy(params: PolicyService.UpdateHeliosPolicyParams): __Observable<HeliosPolicyResponse> {
    return this.UpdateHeliosPolicyResponse(params).pipe(
      __map(_r => _r.body as HeliosPolicyResponse)
    );
  }

  /**
   * Delete a Policy.
   *
   * Deletes a Policy based on given policy id.
   * @param id Specifies a unique id of the Policy to delete.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  DeleteHeliosPolicyResponse(id: string,
    regionId?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/mcm/data-protect/policies/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a Policy.
   *
   * Deletes a Policy based on given policy id.
   * @param id Specifies a unique id of the Policy to delete.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  DeleteHeliosPolicy(id: string,
    regionId?: string): __Observable<null> {
    return this.DeleteHeliosPolicyResponse(id, regionId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Create a Internal Policy.
   *
   * Allows creating a ODP policy internally from Kepler micorservice.
   * @param body Request to create a Helios Policy.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  CreateInternalHeliosPolicyFromKeplerResponse(body: ProtectionPolicyRequest,
    regionId?: string): __Observable<__StrictHttpResponse<ProtectionPolicyResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/kepler-internal/policies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionPolicyResponse>;
      })
    );
  }
  /**
   * Create a Internal Policy.
   *
   * Allows creating a ODP policy internally from Kepler micorservice.
   * @param body Request to create a Helios Policy.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  CreateInternalHeliosPolicyFromKepler(body: ProtectionPolicyRequest,
    regionId?: string): __Observable<ProtectionPolicyResponse> {
    return this.CreateInternalHeliosPolicyFromKeplerResponse(body, regionId).pipe(
      __map(_r => _r.body as ProtectionPolicyResponse)
    );
  }
}

module PolicyService {

  /**
   * Parameters for GetProtectionPolicies
   */
  export interface GetProtectionPoliciesParams {

    /**
     * Filter by a list of Vault ids. Policies archiving to any of the specified vaults will be returned.
     */
    vaultIds?: Array<number>;

    /**
     * Types specifies the policy type of policies to be returned
     */
    types?: Array<'Regular' | 'Internal'>;

    /**
     * TenantIds contains ids of the organizations for which objects are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
     */
    requestInitiatorType?: 'UIUser' | 'UIAuto' | 'Helios';

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Filter policies by a list of policy names.
     */
    policyNames?: Array<string>;

    /**
     * IncludeTenantPolicies specifies if objects of all the organizations under the hierarchy of the logged in user's organization should be returned.
     */
    includeTenants?: boolean;

    /**
     * If includeStats is set to true, then response will return number of protection groups and objects. By default, the protection stats are not included in the response.
     */
    includeStats?: boolean;

    /**
     * If includeReplicatedPolicies is set to true, then response will also contain replicated policies. By default, replication policies are not included in the response.
     */
    includeReplicatedPolicies?: boolean;

    /**
     * Filter policies by a list of policy ids.
     */
    ids?: Array<string>;

    /**
     * If excludeLinkedPolicies is set to true then only local policies created on cluster will be returned. The result will exclude all linked policies created from policy templates.
     */
    excludeLinkedPolicies?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateProtectionPolicy
   */
  export interface CreateProtectionPolicyParams {

    /**
     * Request to create a Protection Policy.
     */
    body: ProtectionPolicyRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetProtectionPolicyById
   */
  export interface GetProtectionPolicyByIdParams {

    /**
     * Specifies a unique id of the Protection Policy to return.
     */
    id: string;

    /**
     * Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
     */
    requestInitiatorType?: 'UIUser' | 'UIAuto' | 'Helios';

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateProtectionPolicy
   */
  export interface UpdateProtectionPolicyParams {

    /**
     * Specifies a unique id of the Protection Policy to update.
     */
    id: string;

    /**
     * Request to update a Protection Policy.
     */
    body: ProtectionPolicyRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteProtectionPolicy
   */
  export interface DeleteProtectionPolicyParams {

    /**
     * Specifies a unique id of the Protection Policy to delete.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetPolicyTemplates
   */
  export interface GetPolicyTemplatesParams {

    /**
     * TenantIds contains ids of the organizations for which objects are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
     */
    requestInitiatorType?: 'UIUser' | 'UIAuto' | 'Helios';

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Filter policies by a list of policy names.
     */
    policyNames?: Array<string>;

    /**
     * IncludeTenantPolicies specifies if objects of all the organizations under the hierarchy of the logged in user's organization should be returned.
     */
    includeTenants?: boolean;

    /**
     * Filter policies by a list of policy template ids.
     */
    ids?: Array<string>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetPolicyTemplateById
   */
  export interface GetPolicyTemplateByIdParams {

    /**
     * Specifies a unique id of the Policy Template to return.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetHeliosPolicies
   */
  export interface GetHeliosPoliciesParams {

    /**
     * Type specifies the policy type of policies to be returned. If not specified, all types of policies are fetched.
     */
    types?: null | Array<'GlobalPolicy' | 'DMaaSPolicy' | 'OnPremPolicy'>;

    /**
     * List of Tenant Ids to filter from. This is applicable only for type OnPremPolicy.
     */
    tenantIds?: Array<null | string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Filter policies by a list of policy names.
     */
    policyNames?: Array<string>;

    /**
     * Filter policies by a list of policy ids.
     */
    ids?: Array<string>;

    /**
     * Specifies the list of cluster identifiers.
     * This is applicable only for type OnPremPolicy. The format is
     * clusterId:clusterIncarnationId.
     */
    clusterIdentifiers?: Array<string>;
  }

  /**
   * Parameters for UpdateHeliosPolicy
   */
  export interface UpdateHeliosPolicyParams {

    /**
     * Specifies a unique id of the Protection Policy to update.
     */
    id: string;

    /**
     * Request to update a Protection Policy.
     */
    body: HeliosPolicyRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;
  }
}

export { PolicyService }
