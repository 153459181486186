import { Injectable } from '@angular/core';
import { Transition } from '@uirouter/core';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AjsUpgradeService, CustomizationService, UserService, WhitelabelingService } from 'src/app/core/services';
import { GuardPriority, GuardResult, StateGuard, StaticRoutes } from 'src/app/core/state/state-guard';

/**
 * The User guard returns an observable that will resolve after user info has been loaded
 *
 * When this completes, the UserService.user is guaranteed to be set.
 */
@Injectable({
  providedIn: 'root',
})
export class UserGuard implements StateGuard {

  /**
   * This guard should be run after onStart from app-ajs/app.js
   */
  guardPriority = GuardPriority.User;

  /**
   * Legacy UserService AngularJS service.
   */
  private ajsUserService: any;

  constructor(
    private ajsUpgrade: AjsUpgradeService,
    private userService: UserService,
    private customizationService: CustomizationService,
    private whitelabelingService: WhitelabelingService,
  ) {
    this.ajsUserService = ajsUpgrade.get('UserService');
  }

  /**
   * This is run on the onStart ui router transition.
   */
  onStart(transition: Transition): GuardResult {
    // Skip this check for routes that don't need user info
    if (!StaticRoutes.includes(transition.$to().name)) {

      // Make sure that there is valid user object.
      return combineLatest([
        this.userService.user$,
        this.customizationService.clusterCustomizations$,
        this.customizationService.userCustomizations$,
        this.whitelabelingService.whitelabelingCustomizations$,
      ]).pipe(
        // Return true when user info been loaded.
        filter(([
          user,
          clusterCustomizations,
          userCustomizations,
        ]) => !!user && !!clusterCustomizations && !!userCustomizations),
        map(() => true)
      );
    }
  }
}
