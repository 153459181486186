<!-- Recovery confirmation dialog template -->
<h2 mat-dialog-title>{{ 'instantRecovery' | translate }}</h2>
<mat-dialog-content class="margin-bottom" [innerHTML]="'anomalyRecovery.confirmation' | translate">
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ 'cancel' | translate }}</button>
  <button
    mat-flat-button
    cogDataId="anomalous-objects-confirm-recovery"
    cdkFocusInitial
    color="primary"
    [mat-dialog-close]="true">
    {{ 'recover' | translate }}
  </button>
</mat-dialog-actions>
