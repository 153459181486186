import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NisNetgroup } from '@cohesity/api/v2';
import { AutoDestroyable } from '@cohesity/utils';
import { DialogService } from 'src/app/core/services';

import {
  DeleteNetgroupWhitelistDialogComponent,
  DeleteNetgroupWhitelistDialogParams,
} from '../delete-netgroup-whitelist-dialog/delete-netgroup-whitelist-dialog.component';
import {
  ModifyNetgroupWhitelistDialogComponent,
  ModifyNetgroupWhitelistDialogParams,
} from '../modify-netgroup-whitelist-dialog/modify-netgroup-whitelist-dialog.component';

/**
 * The component used to add/display/edit netgroup whitelist.
 *
 * @example
 * <coh-netgroup-whitelist
 *  [nisNetgroups]="nisNetgroups">
 * </coh-netgroup-whitelist>
 */
@Component({
  selector: 'coh-netgroup-whitelist',
  templateUrl: './netgroup-whitelist.component.html',
})
export class NetgroupWhitelistComponent extends AutoDestroyable implements OnInit {
  /**
   * Set netgroup whitelist.
   */
  @Input() set nisNetgroups(val: NisNetgroup[]) {
    this._nisNetgroups = val;
  }

  /**
   * Return netgroup used for table display.
   */
  get nisNetgroups(): NisNetgroup[] {
    return this._nisNetgroups;
  }

  /**
   * Netgroup whitelist used for table display.
   */
  private _nisNetgroups: NisNetgroup[];

  /**
   * Sets the hide NFS state.
   */
  @Input() set hideNFS(val: boolean) {
    this._hideNFS = val;
    this.reConstructTableColumns();
  }

  /**
   * Returns the hide NFS state.
   */
  get hideNFS(): boolean {
    return this._hideNFS;
  }

  /**
   * Whether to hide nfs columns or not. Defaults to false.
   */
  private _hideNFS = false;

  /**
   * Whether user can modify/add/delete netgroup. Defaults to true;
   */
  @Input() enableAction = true;

  /**
   * Whether will modify global netgroup whitelist or not. Defaults to true;
   */
  @Input() modifyGlobalNetgroup = true;

  /**
   * Update netgroup event.
   */
  @Output() readonly updateNetgroup = new EventEmitter<NisNetgroup[]>();

  /**
   * List of table columns.
   */
  columns = ['name', 'domain'];

  constructor(private dialogService: DialogService) {
    super();
  }

  /**
   * Determines whether netgroup whitelists is ready or not.
   */
  @Input() isLoading = false;

  /**
   * Init component.
   */
  ngOnInit() {
    this.reConstructTableColumns();
  }

  /**
   * Helper function to re-construct table columns based on Input change.
   */
  reConstructTableColumns() {
    // reset columns.
    this.columns = ['name', 'domain'];

    // modify nfs columns
    if (!this.hideNFS) {
      this.columns = [...this.columns, 'nfsAccess', 'nfsSquash'];
    }

    // add or remove action column
    if (this.enableAction) {
      // add action column.
      if (!this.columns.includes('actions')) {
        this.columns.push('actions');
      }
    } else {
      // remove action column
      if (this.columns.includes('actions')) {
        this.columns.pop();
      }
    }
  }

  /**
   * Add new netgroup whitelist or edit existing netgroup whitelist.
   *
   * @param   isEditMode   Whether it's editing mode or creating mode.
   * @param   netgroupWhitelist   The netgroup whitelist to be modified.
   */
  modifyNetgroupWhitelist(isEditMode: boolean, netgroupWhitelist?: NisNetgroup) {
    if (isEditMode) {
      // edit existing
      const dialogParam: ModifyNetgroupWhitelistDialogParams = {
        isEditMode: true,
        modifyGlobalNetgroup: this.modifyGlobalNetgroup,
        netgroupWhitelist: netgroupWhitelist,
      };

      this.dialogService
        .showDialog(ModifyNetgroupWhitelistDialogComponent, dialogParam)
        .pipe(this.untilDestroy())
        .subscribe((updatedNetgroupWhitelist: NisNetgroup) => {
          if (updatedNetgroupWhitelist) {
            // find the index of the netgroup being modified.
            const netgroupIndex = this.nisNetgroups.findIndex(
              (item: NisNetgroup) => item.name === netgroupWhitelist.name);

            // update previous netgroup object with updated netgroup.
            this.nisNetgroups[netgroupIndex] = updatedNetgroupWhitelist;

            // update table source with update netgroup.
            this.nisNetgroups = [...this.nisNetgroups];

            // Emit updated netgroup whitelist.
            this.updateNetgroup.emit(this.nisNetgroups);
          }
        });
    } else {
      // add new netgroupWhitelist
      const dialogParam: ModifyNetgroupWhitelistDialogParams = {
        isEditMode: false,
        modifyGlobalNetgroup: this.modifyGlobalNetgroup,
      };
      this.dialogService
        .showDialog(ModifyNetgroupWhitelistDialogComponent, dialogParam)
        .pipe(this.untilDestroy())
        .subscribe((newNetgroupWhitelist: NisNetgroup) => {
          if (newNetgroupWhitelist) {
            // update table source with new netgroup whitelist added.
            this.nisNetgroups = [...(this.nisNetgroups || []), newNetgroupWhitelist];

            // Emit new added netgroup whitelist.
            this.updateNetgroup.emit(this.nisNetgroups);
          }
        });
    }
  }

  /**
   * Delete netgroup whitelist.
   *
   * @param   netgroupWhitelist   The selected netgroup.
   */
  deleteNetgroup(netgroupWhitelist: NisNetgroup) {
    const dialogParam: DeleteNetgroupWhitelistDialogParams = {
      netgroup: netgroupWhitelist,
      modifyGlobalNetgroup: this.modifyGlobalNetgroup,
    };

    this.dialogService
      .showDialog(DeleteNetgroupWhitelistDialogComponent, dialogParam)
      .pipe(this.untilDestroy())
      .subscribe((rst) => {
        if (rst) {
          // find the index of the netgroup being deleted.
          const netgroupIndex = this.nisNetgroups.findIndex(
            (item: NisNetgroup) => item.name === netgroupWhitelist.name);

          // remove netgroup from table source.
          this.nisNetgroups.splice(netgroupIndex, 1);

          // Emit updated netgroup whitelist.
          this.updateNetgroup.emit([...this.nisNetgroups]);
        }
      });
  }
}
