import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { DataTreeSelectionModel } from '../shared/data-tree-selection-model';
import { DataTreeSource } from '../shared/data-tree-source';
import { DataTreeNode } from '../shared/data-tree.model';

/**
 * DataTreeSelectAllCheckboxComponent can be used to select/deselect all nodes in the source tree.
 *
 * @example
 * <cog-data-tree-select-all-checkbox [dataTreeSource]="dataTreeSource"
 *  [treeSelection]="dataTreeSelection">
 * </cog-data-tree-select-all-checkbox>
 */
@Component({
  selector: 'cog-data-tree-select-all-checkbox',
  templateUrl: './data-tree-select-all-checkbox.component.html',
  styleUrls: ['./data-tree-select-all-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTreeSelectAllCheckboxComponent<T extends DataTreeNode<any>> implements OnInit, OnDestroy {
  /**
   * The tree selection model
   */
  @Input() treeSelection: DataTreeSelectionModel<T>;

  /**
   * The data tree source - need to update the selection state whenever the current view changes
   */
  @Input() dataTreeSource: DataTreeSource<T>;

  /**
   * Subscription for the selection and source changes.
   */
  private selectionSub: Subscription;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.selectionSub = combineLatest([this.treeSelection.changes$, this.dataTreeSource.currentView$])
      .pipe(debounceTime(5))
      .subscribe(() => {
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    if (this.selectionSub?.unsubscribe) {
      this.selectionSub.unsubscribe();
    }
  }
}
