<cog-settings-list-item [formGroup]="formGroup"
  [invalid]="formGroup.invalid"
  [label]="'concurrency' | translate">
  <cog-settings-list-item-value-display>
    <ng-container [ngSwitch]="formGroupValues?.value">
      {{ formGroupValues.value }}
      <ng-container *ngSwitchCase="1">{{ 'jobSettingsList.concurrencyReadonly.singular' | translate:{jobType: jobType} }}</ng-container>
      <ng-container *ngSwitchDefault>{{ 'jobSettingsList.concurrencyReadonly.plural' | translate:{jobType: jobType} }}</ng-container>
    </ng-container>
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor>
    <mat-form-field class="margin-right-lg">
      <input
        matInput
        [formControlName]="formControlNames.value"
        [inheritDataId]="true"
        [placeholder]="'concurrency' | translate"
        cogDataId="concurrency-input"
        type="number"
        min="0">
    </mat-form-field>
    <span [ngSwitch]="formGroupValues?.value">
      <ng-container *ngSwitchCase="1">{{ 'jobSettingsList.concurrencyEditable.singular' | translate:{jobType: jobType} }}</ng-container>
      <ng-container *ngSwitchDefault>{{ 'jobSettingsList.concurrencyEditable.plural' | translate:{jobType: jobType} }}</ng-container>
    </span>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
