import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const heliosDashboardEnabled = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.smartFilesHeliosV2Dashboard && !ctx.isHeliosOnPrem;

export const HeliosSmartfilesDashboardConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'helios-smartfiles-dashboard.**',
      url: '^/dashboard',
      loadChildren: () => import('./helios-smartfiles-dashboard.module').then(m => m.HeliosSmartfilesDashboardModule)
    },
  ],
  allClusterMap: {
    'helios-smartfiles-dashboard': {
      heliosOnly: true,
      allClustersState: 'helios-smartfiles-dashboard',
    },
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'helios-smartfiles-dashboard': heliosDashboardEnabled,
    };
  }
};
