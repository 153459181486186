import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * This pipe used to get the formatted tenant id for display purpose.
 *
 * @example
 *   {{::'coke/hr/department_1/' | tenantId}}     @coke/hr/department_1
 *   {{null | tenantId}}                           -
 *   {{null | tenantId : true}}                    '' // empty string
 */
@Pipe({
  name: 'tenantId',
})
export class TenantIdFormatterPipe implements PipeTransform {
  /**
   * Initializes the pipe
   *
   * @param   userService   The user service containing privilege info
   */
  constructor(private translate: TranslateService) {}

  /**
   * Implements the pipe
   *
   * @param   privilege   The permission value to check.
   * @return  True if the user has the permission.
   */
  transform(tenantId: string, noNullState = false): any {
    if (!tenantId) {
      return noNullState ? '' : this.translate.instant('naNotAvailable');
    }

    return '@' + tenantId.slice(0, -1);
  }
}
