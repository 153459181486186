/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FailoverRunsResponse } from '../models/failover-runs-response';
import { GetTrackingViewIdResponse } from '../models/get-tracking-view-id-response';
import { GetViewFailoverResponseBody } from '../models/get-view-failover-response-body';
import { Failover } from '../models/failover';
import { CreateViewFailoverRequest } from '../models/create-view-failover-request';
import { GetFailoverOpsResponse } from '../models/get-failover-ops-response';
import { InitFailoverResponse } from '../models/init-failover-response';
import { InitFailoverRequest } from '../models/init-failover-request';
import { ReplicationBackupActivationResult } from '../models/replication-backup-activation-result';
import { ReplicationBackupActivation } from '../models/replication-backup-activation';
import { SourceBackupDeactivation } from '../models/source-backup-deactivation';
import { ObjectLinkingRequest } from '../models/object-linking-request';
import { FailoverCreateRunResponse } from '../models/failover-create-run-response';
import { FailoverRunConfiguration } from '../models/failover-run-configuration';
@Injectable({
  providedIn: 'root',
})
class FailoverService extends __BaseService {
  static readonly PollPlannedRunsPath = '/data-protect/failover/planned-runs';
  static readonly GetTrackingViewIdPath = '/data-protect/failover/views/trackingViewId/{id}';
  static readonly GetViewFailoverPath = '/data-protect/failover/views/{id}';
  static readonly CreateViewFailoverPath = '/data-protect/failover/views/{id}';
  static readonly CancelViewFailoverPath = '/data-protect/failover/views/{id}/cancel';
  static readonly GetFailoverOpsPath = '/data-protect/failover/views/{id}/operations';
  static readonly InitFailoverPath = '/data-protect/failover/{id}';
  static readonly ReplicationBackupActivationPath = '/data-protect/failover/{id}/backup-activation';
  static readonly SourceBackupDeactivationPath = '/data-protect/failover/{id}/backup-deactivation';
  static readonly InternalApiReplicationBackupActivationPath = '/data-protect/failover/{id}/backupActivation';
  static readonly CancelFailoverPath = '/data-protect/failover/{id}/cancel';
  static readonly ObjectLinkagePath = '/data-protect/failover/{id}/object-linkage';
  static readonly CreatePlannedRunPath = '/data-protect/failover/{id}/planned-run';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get the list of failover planned runs.
   *
   * Poll to see whether planned run has been scheduled or not.
   * @param params The `FailoverService.PollPlannedRunsParams` containing the following parameters:
   *
   * - `failoverIds`: Get runs for specific failover workflows.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: If true, the response will include Protection Groups which were created by all tenants which the current user has permission to see. If false, then only Protection Groups created by the current user will be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  PollPlannedRunsResponse(params: FailoverService.PollPlannedRunsParams): __Observable<__StrictHttpResponse<FailoverRunsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.failoverIds || []).forEach(val => {if (val != null) __params = __params.append('failoverIds', val.toString())});
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/failover/planned-runs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FailoverRunsResponse>;
      })
    );
  }
  /**
   * Get the list of failover planned runs.
   *
   * Poll to see whether planned run has been scheduled or not.
   * @param params The `FailoverService.PollPlannedRunsParams` containing the following parameters:
   *
   * - `failoverIds`: Get runs for specific failover workflows.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: If true, the response will include Protection Groups which were created by all tenants which the current user has permission to see. If false, then only Protection Groups created by the current user will be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  PollPlannedRuns(params: FailoverService.PollPlannedRunsParams): __Observable<FailoverRunsResponse> {
    return this.PollPlannedRunsResponse(params).pipe(
      __map(_r => _r.body as FailoverRunsResponse)
    );
  }

  /**
   * Get tracking View Id
   *
   * Get tracking View Id
   * @param params The `FailoverService.GetTrackingViewIdParams` containing the following parameters:
   *
   * - `id`: Specifies the view_uid of the source view.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `isForwarded`: Indicates whether the request is forwarded
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetTrackingViewIdResponse(params: FailoverService.GetTrackingViewIdParams): __Observable<__StrictHttpResponse<GetTrackingViewIdResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.isForwarded != null) __params = __params.set('isForwarded', params.isForwarded.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/failover/views/trackingViewId/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetTrackingViewIdResponse>;
      })
    );
  }
  /**
   * Get tracking View Id
   *
   * Get tracking View Id
   * @param params The `FailoverService.GetTrackingViewIdParams` containing the following parameters:
   *
   * - `id`: Specifies the view_uid of the source view.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `isForwarded`: Indicates whether the request is forwarded
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetTrackingViewId(params: FailoverService.GetTrackingViewIdParams): __Observable<GetTrackingViewIdResponse> {
    return this.GetTrackingViewIdResponse(params).pipe(
      __map(_r => _r.body as GetTrackingViewIdResponse)
    );
  }

  /**
   * Get View Failover.
   *
   * Get failover tasks of a View.
   * @param params The `FailoverService.GetViewFailoverParams` containing the following parameters:
   *
   * - `id`: Specifies a view id to create an failover task.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViewFailoverResponse(params: FailoverService.GetViewFailoverParams): __Observable<__StrictHttpResponse<GetViewFailoverResponseBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/failover/views/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetViewFailoverResponseBody>;
      })
    );
  }
  /**
   * Get View Failover.
   *
   * Get failover tasks of a View.
   * @param params The `FailoverService.GetViewFailoverParams` containing the following parameters:
   *
   * - `id`: Specifies a view id to create an failover task.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViewFailover(params: FailoverService.GetViewFailoverParams): __Observable<GetViewFailoverResponseBody> {
    return this.GetViewFailoverResponse(params).pipe(
      __map(_r => _r.body as GetViewFailoverResponseBody)
    );
  }

  /**
   * Create View Failover Task.
   *
   * Create a view failover task.
   * @param params The `FailoverService.CreateViewFailoverParams` containing the following parameters:
   *
   * - `id`: Specifies a view id to create an failover task.
   *
   * - `body`: Specifies the request body to create failover task.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateViewFailoverResponse(params: FailoverService.CreateViewFailoverParams): __Observable<__StrictHttpResponse<Failover>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/failover/views/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Failover>;
      })
    );
  }
  /**
   * Create View Failover Task.
   *
   * Create a view failover task.
   * @param params The `FailoverService.CreateViewFailoverParams` containing the following parameters:
   *
   * - `id`: Specifies a view id to create an failover task.
   *
   * - `body`: Specifies the request body to create failover task.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateViewFailover(params: FailoverService.CreateViewFailoverParams): __Observable<Failover> {
    return this.CreateViewFailoverResponse(params).pipe(
      __map(_r => _r.body as Failover)
    );
  }

  /**
   * Cancel View Failover Task.
   *
   * Cancel an in progress view failover task.
   * @param params The `FailoverService.CancelViewFailoverParams` containing the following parameters:
   *
   * - `id`: Specifies a view id to cancel it's failover.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  CancelViewFailoverResponse(params: FailoverService.CancelViewFailoverParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/failover/views/${encodeURIComponent(params.id)}/cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Cancel View Failover Task.
   *
   * Cancel an in progress view failover task.
   * @param params The `FailoverService.CancelViewFailoverParams` containing the following parameters:
   *
   * - `id`: Specifies a view id to cancel it's failover.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  CancelViewFailover(params: FailoverService.CancelViewFailoverParams): __Observable<null> {
    return this.CancelViewFailoverResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Gets all the failover operations which can be performed on this view.
   *
   * Gets all the failover operations which can be performed on this view.
   * @param params The `FailoverService.GetFailoverOpsParams` containing the following parameters:
   *
   * - `id`: Specifies the view id.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetFailoverOpsResponse(params: FailoverService.GetFailoverOpsParams): __Observable<__StrictHttpResponse<GetFailoverOpsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/failover/views/${encodeURIComponent(params.id)}/operations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetFailoverOpsResponse>;
      })
    );
  }
  /**
   * Gets all the failover operations which can be performed on this view.
   *
   * Gets all the failover operations which can be performed on this view.
   * @param params The `FailoverService.GetFailoverOpsParams` containing the following parameters:
   *
   * - `id`: Specifies the view id.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetFailoverOps(params: FailoverService.GetFailoverOpsParams): __Observable<GetFailoverOpsResponse> {
    return this.GetFailoverOpsResponse(params).pipe(
      __map(_r => _r.body as GetFailoverOpsResponse)
    );
  }

  /**
   * Initiate a failover request.
   *
   * Initiate a failover request.
   * @param params The `FailoverService.InitFailoverParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the failover workflow.
   *
   * - `body`: Specifies the parameters to initiate a failover. This failover request should be intiaited from replication cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  InitFailoverResponse(params: FailoverService.InitFailoverParams): __Observable<__StrictHttpResponse<InitFailoverResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/failover/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<InitFailoverResponse>;
      })
    );
  }
  /**
   * Initiate a failover request.
   *
   * Initiate a failover request.
   * @param params The `FailoverService.InitFailoverParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the failover workflow.
   *
   * - `body`: Specifies the parameters to initiate a failover. This failover request should be intiaited from replication cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  InitFailover(params: FailoverService.InitFailoverParams): __Observable<InitFailoverResponse> {
    return this.InitFailoverResponse(params).pipe(
      __map(_r => _r.body as InitFailoverResponse)
    );
  }

  /**
   * Activate failover entity backup on replication clsuter.
   *
   * Specifies the configuration required for activating backup for failover objects on replication cluster. Here orchastrator can call this API multiple times as long as full set of object are non-overlapping. They can also use the existing job if its compatible to backup failover objects.
   * @param params The `FailoverService.ReplicationBackupActivationParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the failover workflow.
   *
   * - `body`: Specifies the paramteres to activate the backup of failover entities.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ReplicationBackupActivationResponse(params: FailoverService.ReplicationBackupActivationParams): __Observable<__StrictHttpResponse<ReplicationBackupActivationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/failover/${encodeURIComponent(params.id)}/backup-activation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReplicationBackupActivationResult>;
      })
    );
  }
  /**
   * Activate failover entity backup on replication clsuter.
   *
   * Specifies the configuration required for activating backup for failover objects on replication cluster. Here orchastrator can call this API multiple times as long as full set of object are non-overlapping. They can also use the existing job if its compatible to backup failover objects.
   * @param params The `FailoverService.ReplicationBackupActivationParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the failover workflow.
   *
   * - `body`: Specifies the paramteres to activate the backup of failover entities.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ReplicationBackupActivation(params: FailoverService.ReplicationBackupActivationParams): __Observable<ReplicationBackupActivationResult> {
    return this.ReplicationBackupActivationResponse(params).pipe(
      __map(_r => _r.body as ReplicationBackupActivationResult)
    );
  }

  /**
   * Deactivate failover entity backup on source clsuter.
   *
   * Specifies the configuration required for deactivating backup for failover entities on source cluster.
   * @param params The `FailoverService.SourceBackupDeactivationParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the failover workflow.
   *
   * - `body`: Specifies the paramteres to deactivate the backup of failover entities.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  SourceBackupDeactivationResponse(params: FailoverService.SourceBackupDeactivationParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/failover/${encodeURIComponent(params.id)}/backup-deactivation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deactivate failover entity backup on source clsuter.
   *
   * Specifies the configuration required for deactivating backup for failover entities on source cluster.
   * @param params The `FailoverService.SourceBackupDeactivationParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the failover workflow.
   *
   * - `body`: Specifies the paramteres to deactivate the backup of failover entities.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  SourceBackupDeactivation(params: FailoverService.SourceBackupDeactivationParams): __Observable<null> {
    return this.SourceBackupDeactivationResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Activate failover entity backup on replication clsuter.
   *
   * Specifies the configuration required for activating backup for failover objects on replication cluster. Here orchastrator can call this API multiple times as long as full set of object are non-overlapping. They can also use the existing job if its compatible to backup failover objects.
   * @param params The `FailoverService.InternalApiReplicationBackupActivationParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the failover workflow.
   *
   * - `body`: Specifies the paramteres to activate the backup of failover entities.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  InternalApiReplicationBackupActivationResponse(params: FailoverService.InternalApiReplicationBackupActivationParams): __Observable<__StrictHttpResponse<ReplicationBackupActivationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/failover/${encodeURIComponent(params.id)}/backupActivation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReplicationBackupActivationResult>;
      })
    );
  }
  /**
   * Activate failover entity backup on replication clsuter.
   *
   * Specifies the configuration required for activating backup for failover objects on replication cluster. Here orchastrator can call this API multiple times as long as full set of object are non-overlapping. They can also use the existing job if its compatible to backup failover objects.
   * @param params The `FailoverService.InternalApiReplicationBackupActivationParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the failover workflow.
   *
   * - `body`: Specifies the paramteres to activate the backup of failover entities.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  InternalApiReplicationBackupActivation(params: FailoverService.InternalApiReplicationBackupActivationParams): __Observable<ReplicationBackupActivationResult> {
    return this.InternalApiReplicationBackupActivationResponse(params).pipe(
      __map(_r => _r.body as ReplicationBackupActivationResult)
    );
  }

  /**
   * Cancel failover workflow.
   *
   * Specifies the request to cancel failover workflow. The cancellation request should not be made if '/backupActivation' or '/backupDeactivaetion' are already called on replication or source cluster respectively.
   * @param params The `FailoverService.CancelFailoverParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the failover workflow.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  CancelFailoverResponse(params: FailoverService.CancelFailoverParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/failover/${encodeURIComponent(params.id)}/cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Cancel failover workflow.
   *
   * Specifies the request to cancel failover workflow. The cancellation request should not be made if '/backupActivation' or '/backupDeactivaetion' are already called on replication or source cluster respectively.
   * @param params The `FailoverService.CancelFailoverParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the failover workflow.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  CancelFailover(params: FailoverService.CancelFailoverParams): __Observable<null> {
    return this.CancelFailoverResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Linking between replicated objects and failover objects
   *
   * Specifies the request to link failover objects on replication cluster to the replicated entity from source cluster. This linking need to be done after perforing recoveries for failed entities on replication cluster. This linkage will be useful when merging snapshots of object across replications and failovers.
   * @param params The `FailoverService.ObjectLinkageParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the failover workflow.
   *
   * - `body`: Specifies the paramteres to create links between replicated objects and failover objects.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  ObjectLinkageResponse(params: FailoverService.ObjectLinkageParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/failover/${encodeURIComponent(params.id)}/object-linkage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Linking between replicated objects and failover objects
   *
   * Specifies the request to link failover objects on replication cluster to the replicated entity from source cluster. This linking need to be done after perforing recoveries for failed entities on replication cluster. This linkage will be useful when merging snapshots of object across replications and failovers.
   * @param params The `FailoverService.ObjectLinkageParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the failover workflow.
   *
   * - `body`: Specifies the paramteres to create links between replicated objects and failover objects.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  ObjectLinkage(params: FailoverService.ObjectLinkageParams): __Observable<null> {
    return this.ObjectLinkageResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Create a planned run for backup and replication.
   *
   * Specifies the configuration required for executing a special run as a part of failover workflow. This special run is triggered during palnned failover to sync the source cluster to replication cluster with minimum possible delta.
   * @param params The `FailoverService.CreatePlannedRunParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the failover workflow.
   *
   * - `body`: Specifies the paramteres to create a planned run while failover workflow.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreatePlannedRunResponse(params: FailoverService.CreatePlannedRunParams): __Observable<__StrictHttpResponse<FailoverCreateRunResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/failover/${encodeURIComponent(params.id)}/planned-run`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FailoverCreateRunResponse>;
      })
    );
  }
  /**
   * Create a planned run for backup and replication.
   *
   * Specifies the configuration required for executing a special run as a part of failover workflow. This special run is triggered during palnned failover to sync the source cluster to replication cluster with minimum possible delta.
   * @param params The `FailoverService.CreatePlannedRunParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the failover workflow.
   *
   * - `body`: Specifies the paramteres to create a planned run while failover workflow.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreatePlannedRun(params: FailoverService.CreatePlannedRunParams): __Observable<FailoverCreateRunResponse> {
    return this.CreatePlannedRunResponse(params).pipe(
      __map(_r => _r.body as FailoverCreateRunResponse)
    );
  }
}

module FailoverService {

  /**
   * Parameters for PollPlannedRuns
   */
  export interface PollPlannedRunsParams {

    /**
     * Get runs for specific failover workflows.
     */
    failoverIds: Array<string>;

    /**
     * TenantIds contains ids of the tenants for which objects are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * If true, the response will include Protection Groups which were created by all tenants which the current user has permission to see. If false, then only Protection Groups created by the current user will be returned.
     */
    includeTenants?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetTrackingViewId
   */
  export interface GetTrackingViewIdParams {

    /**
     * Specifies the view_uid of the source view.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Indicates whether the request is forwarded
     */
    isForwarded?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetViewFailover
   */
  export interface GetViewFailoverParams {

    /**
     * Specifies a view id to create an failover task.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateViewFailover
   */
  export interface CreateViewFailoverParams {

    /**
     * Specifies a view id to create an failover task.
     */
    id: number;

    /**
     * Specifies the request body to create failover task.
     */
    body: CreateViewFailoverRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CancelViewFailover
   */
  export interface CancelViewFailoverParams {

    /**
     * Specifies a view id to cancel it's failover.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetFailoverOps
   */
  export interface GetFailoverOpsParams {

    /**
     * Specifies the view id.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for InitFailover
   */
  export interface InitFailoverParams {

    /**
     * Specifies the id of the failover workflow.
     */
    id: string;

    /**
     * Specifies the parameters to initiate a failover. This failover request should be intiaited from replication cluster.
     */
    body: InitFailoverRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ReplicationBackupActivation
   */
  export interface ReplicationBackupActivationParams {

    /**
     * Specifies the id of the failover workflow.
     */
    id: string;

    /**
     * Specifies the paramteres to activate the backup of failover entities.
     */
    body: ReplicationBackupActivation;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for SourceBackupDeactivation
   */
  export interface SourceBackupDeactivationParams {

    /**
     * Specifies the id of the failover workflow.
     */
    id: string;

    /**
     * Specifies the paramteres to deactivate the backup of failover entities.
     */
    body: SourceBackupDeactivation;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for InternalApiReplicationBackupActivation
   */
  export interface InternalApiReplicationBackupActivationParams {

    /**
     * Specifies the id of the failover workflow.
     */
    id: string;

    /**
     * Specifies the paramteres to activate the backup of failover entities.
     */
    body: ReplicationBackupActivation;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CancelFailover
   */
  export interface CancelFailoverParams {

    /**
     * Specifies the id of the failover workflow.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ObjectLinkage
   */
  export interface ObjectLinkageParams {

    /**
     * Specifies the id of the failover workflow.
     */
    id: string;

    /**
     * Specifies the paramteres to create links between replicated objects and failover objects.
     */
    body: ObjectLinkingRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreatePlannedRun
   */
  export interface CreatePlannedRunParams {

    /**
     * Specifies the id of the failover workflow.
     */
    id: string;

    /**
     * Specifies the paramteres to create a planned run while failover workflow.
     */
    body: FailoverRunConfiguration;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { FailoverService }
