import { Injectable } from '@angular/core';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { StateDeclaration, StateObject, Transition } from '@uirouter/core';

import { StateManagementService } from '../../services';
import { GuardPriority, GuardResult, StateGuard } from '../state-guard';

/**
 * Antivirus Guard redirects all state traffic to the new antivirus page based on the
 * Feature flag ngAntivirus.
 */
@Injectable({
  providedIn: 'root',
})
export class AntivirusGuard implements StateGuard {
  /**
   * This guard priority is set to App, which indicates it should run as part of the main application
   * after all bootstrapping and initializing is completed.
   */
  guardPriority = GuardPriority.App;

  /**
   * Match old state to ng-antivirus.
   */
  matchCriteria = {
    to: (state: StateObject) => (
      this.stateMapping.has(state.name) || Array.from(this.stateMapping.values()).includes(state.name)
    ),
  };

  /**
   * Specifies the old to new state mapping for antivirus pages.
   */
  private readonly stateMapping = new Map<string, string>([
    ['antivirus.providers', 'ng-antivirus.providers'],
    ['antivirus.threats', 'ng-antivirus.threats'],
    ['antivirus.secured-views', 'ng-antivirus.secured-views'],
    ['view-av-provider', 'view-av-provider-details'],

    // redirect to providers list as new process flow utilizes modal
    ['add-av-provider', 'ng-antivirus.providers'],
    ['edit-av-provider', 'ng-antivirus.providers'],
  ]);

  /**
   * Constructor.
   */
  constructor(readonly stateManagementService: StateManagementService,
      private irisCtx: IrisContextService) {}

  /**
   * Returns the desired target state name for the provided state.
   *
   * @param toState The target state.
   * @returns  The target state name.
   */
  private getState(toState: StateDeclaration): string {
    return this.stateMapping.get(toState.name) || toState.name;
  }

  /**
   * Handles the redirection to the ng antivirus page based on feature flag.
   * This will help with deep linked states with the old and new antivirus page.
   *
   * @param    transition   The UI Router Transition.
   * @returns  A redirect transition if new antivirus page is enabled.
   */
  onStart(transition: Transition): GuardResult {

    const params = transition.params();
    const toState = transition.to();
    const stateName = this.getState(toState);
    if(!flagEnabled(this.irisCtx.irisContext, 'ngAntivirus')) {
      return;
    }

    if (stateName !== toState.name &&
      this.stateManagementService.canUserAccessState(stateName, params, true)) {
      return transition.router.stateService.target(stateName, params, transition.options());
    }
  }
}
