import { Injectable } from '@angular/core';
import {
  ArchivalTargetResult,
  BackupRun,
  BackupRunSummary,
  ObjectRunResult,
  ProtectionGroupRun,
  ProtectionGroupServiceApi,
  SnapshotInfo,
  ObjectServiceApi,
} from '@cohesity/api/v2';
import { BehaviorSubject } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { PassthroughOptionsService } from 'src/app/core/services';
import { Observable } from 'rxjs';

/**
 * Service used for searching for protection group runs. This may need to be updated as the
 * backend apis are written to better determine what snapshots are available for a given run.
 */
@Injectable()
export class ProtectionGroupSearchService {

  /**
   * Cache details for a given run id to avoid hitting the server too frequently.
   * This cache is valid throughout the lifetime of the modal.
   */
  cachedRunDetails: {
    [runId: string]: ProtectionGroupRun;
  } = {};

  /**
   * Runs list behavior subject.
   */
  private runsList = new BehaviorSubject<ProtectionGroupRun[]>([]);

  /**
   * The list of runs available for the selected protection group.
   */
  runsList$ = this.runsList.asObservable();

  /**
   * Loading runs flag behavior subject.
   */
  private loadingRuns = new BehaviorSubject<boolean>(false);

  /**
   * Returns true if the runs list is being loaded.
   */
  loadingRuns$ = this.loadingRuns.asObservable();

  /**
   * Run details behavior subject.
   */
  private runDetails = new BehaviorSubject<ProtectionGroupRun>(null);

  /**
   * The run details with objects for the currently selected group run.
   */
  runDetails$ = this.runDetails.asObservable();

  /**
   * Loading details behavior subject.
   */
  private loadingDetails = new BehaviorSubject<boolean>(false);

  /**
   * Returns true if the run details are being Loaded
   */
  loadingDetails$ = this.loadingDetails.asObservable();

  constructor(private groupService: ProtectionGroupServiceApi,
    private objectsService: ObjectServiceApi,
    private passthroughOptionsService: PassthroughOptionsService) {}

  /**
   * Fetches the list of recoverable runs for a group id. This will update the runList$
   * observable when it completes.
   *
   * @param   protectionGroupId   The group id to lookup details for.
   */
  getRecoverableRuns(protectionGroupId: string) {

    this.loadingRuns.next(true);
    this.runsList.next([]);
    this.groupService
      .GetProtectionGroupRuns({
        id: protectionGroupId,
        includeObjectDetails: false,
        excludeNonRestorableRuns: true,
      })
      .pipe(
        map(results => results.runs),
        map(runs => runs.filter(run => run.isCloudArchivalDirect
          ? this.getArchiveInfo(run)?.successfulObjectsCount > 0
          : this.getRunBackupInfo(run)?.successfulObjectsCount > 0)),
        finalize(() => this.loadingRuns.next(false))
      )
      .subscribe(runs => {
        this.runsList.next(runs);
      });
  }

  /**
   * Gets the run details for a specific run. This will include the full object list.
   *
   * @param   protectionGroupId   The protection group id.
   * @param   runId               The selected run id.
   */
  getRunDetails(protectionGroupId: string, runId: string) {
    if (this.cachedRunDetails[runId]) {
      this.runDetails.next(this.cachedRunDetails[runId]);
      return;
    }
    this.loadingDetails.next(true);
    this.runDetails.next(null);
    this.groupService
      .GetProtectionGroupRun({
        id: protectionGroupId,
        runId: runId,
        includeObjectDetails: true,
      })
      .pipe(
        tap(run => (this.cachedRunDetails[runId] = run)),
        finalize(() => this.loadingDetails.next(false))
      )
      .subscribe(runs => {
        this.runDetails.next(runs);
      });
  }

  /**
   * Gets the correct snapshot summary from an object, local backup info if it is set,
   * or original backup info for a cloud retrieve job.
   *
   * @param   run   The protection group run
   * @returns The backup info.
   */
  getObjectBackupInfo(object: ObjectRunResult): BackupRun {
    if (!object) {
      return null;
    }
    return object.localSnapshotInfo || object.originalBackupInfo;
  }

  /**
   * Gets the correct run summary from a job, local backup info if it is set,
   * or original backup info for a cloud retrieve job.
   *
   * @param   run   The protection group run
   * @returns The backup info.
   */
  getRunBackupInfo(run: ProtectionGroupRun): BackupRunSummary {
    if (!run) {
      return null;
    }
    return run.localBackupInfo || run.originalBackupInfo;
  }

  /**
   * Gets the archive info from ProtectionGroupRun.
   *
   * @param run   The protection group run or object run result.
   * @returns     Archive info.
   */
  getArchiveInfo(run: ObjectRunResult | ProtectionGroupRun): ArchivalTargetResult {
    if (!run) {
      return null;
    }
    return run.archivalInfo?.archivalTargetResults?.[0];
  }

  /**
   * Gets snapshot info from object backup info for regular run
   * or archive info for cloudArchiveDirect run.
   *
   * @param     object                Object run result.
   * @param     isCloudArchivalDirect  True if it is cloud archival direct run.
   * @returns   Snapshot info from local snapshot or archive snapshot.
   */
  getSnapshotInfo(object: ObjectRunResult, isCloudArchivalDirect = false): SnapshotInfo | ArchivalTargetResult {
    return isCloudArchivalDirect
      ? this.getArchiveInfo(object)
      : this.getObjectBackupInfo(object)?.snapshotInfo;
  }

  /**
   * Get Snapshot id for object id having runTime as snapshot time
   * and verifying it with object name provided
   *
   * @param   objectId            The id of the object being recovered.
   * @param   runTime             The snapshot time for object.
   * @param   objname               The object name whose snapshot id is fetched.
   * @returns Snapshot id from local snapshot or archive snapshot. This will return only the local snapshot ID
   */
  getSnapshotId(objectId: number, runTime: number, objname: string): Observable<string | null>  {
    const runtime = Number(runTime);
    const id = Number(objectId);
    const snapshotInfo$ = this.objectsService
      .GetObjectSnapshots({
        id: id,
        toTimeUsecs: runtime,
        fromTimeUsecs: runtime,
      })
      .pipe(
        map(response => response.snapshots),
        map(snapshots => {
          const matchingSnapshot = snapshots.find(snapshot =>
            snapshot.snapshotTimestampUsecs === runTime &&
            snapshot.objectName === objname && snapshot.snapshotTargetType !== 'Archival'
          );
          return matchingSnapshot ? matchingSnapshot.id : null;
        })

        );
    return snapshotInfo$;
  }
}
