import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const agentStatesAccess = (ctx: StateAccessContext) => ctx.MANAGE_BULK_AGENT_INSTALL_APP &&
  ctx.FEATURE_FLAGS.agentManagementEnabled && !ctx.isTenantUser;

export const AgentConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'agent.**',
      url: '/agent',
      loadChildren: () => import('./agent.module').then(m => m.AgentModule),
    },
  ],
  allClusterMap: {
    agent: false,
    'agent.appRegistration': false,
  },
  getStateAccessMap(): StateAccessMap {
    return {
      agent: agentStatesAccess,
      'agent.registration': agentStatesAccess,
      'agent.deploy': agentStatesAccess,
    };
  },
};
