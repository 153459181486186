/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ProtectionRunInstance } from '../models/protection-run-instance';
import { UpdateProtectionJobRunsParam } from '../models/update-protection-job-runs-param';
import { CancelProtectionJobRunParam } from '../models/cancel-protection-job-run-param';
import { ProtectionRunErrors } from '../models/protection-run-errors';
@Injectable({
  providedIn: 'root',
})
class ProtectionRunsService extends __BaseService {
  static readonly GetProtectionRunsPath = '/public/protectionRuns';
  static readonly UpdateProtectionRunsPath = '/public/protectionRuns';
  static readonly CancelProtectionJobRunPath = '/public/protectionRuns/cancel/{id}';
  static readonly GetProtectionRunErrorsPath = '/public/protectionRuns/errors';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List Protection Job Runs filtered by the specified parameters.
   *
   * If no parameters are specified, Job Runs currently
   * on the Cohesity Cluster are returned. Both running and completed Job Runs
   * are reported.
   * Specifying parameters filters the results that are returned.
   * @param params The `ProtectionRunsService.GetProtectionRunsParams` containing the following parameters:
   *
   * - `startedTimeUsecs`: Return a specific Job Run by specifying a time and a jobId.
   *   Specify the time when the Job Run started as a
   *   Unix epoch Timestamp (in microseconds).
   *   If this field is specified, jobId must also be
   *   specified.
   *
   * - `startTimeUsecs`: Filter by a start time. Only Job Runs that started after the specified
   *   time are returned.
   *   Specify the start time as a Unix epoch Timestamp (in microseconds).
   *
   * - `sourceId`: Filter by source id. Only Job Runs protecting the specified source
   *   (such as a VM or View) are returned. The source id
   *   is assigned by the Cohesity Cluster.
   *
   * - `runTypes`: Filter by run type such as 'kFull', 'kRegular' or 'kLog'.
   *   If not specified, Job Runs of all types are returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `numRuns`: Specify the number of Job Runs to return.
   *   The newest Job Runs are returned.
   *
   * - `jobId`: Filter by a Protection Job that is specified by id.
   *   If not specified, all Job Runs for all Protection Jobs are returned.
   *
   * - `includeRpoSnapshots`: If true, then the snapshots for Protection Sources protected by Rpo
   *   policies will also be returned.
   *
   * - `excludeTasks`: If true, the individual backup status for all the objects protected by
   *   the Job Run are not populated in the response. For example in a VMware
   *   environment, the status of backing up each VM associated with a Job
   *   is not returned.
   *
   * - `excludeNonRestoreableRuns`: Filter out jobs runs that cannot be restored by setting this field to
   *   'true'. If not set or set to 'false', Runs without any successful
   *   object will be returned. The default value is false.
   *
   * - `excludeErrorRuns`: Filter out Jobs Runs with errors by setting this field to 'true'.
   *   If not set or set to 'false', Job Runs with errors are returned.
   *
   * - `endTimeUsecs`: Filter by a end time specified as a Unix epoch Timestamp
   *   (in microseconds). Only Job Runs that completed before the specified
   *   end time are returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionRunsResponse(params: ProtectionRunsService.GetProtectionRunsParams): __Observable<__StrictHttpResponse<Array<ProtectionRunInstance>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startedTimeUsecs != null) __params = __params.set('startedTimeUsecs', params.startedTimeUsecs.toString());
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    if (params.sourceId != null) __params = __params.set('sourceId', params.sourceId.toString());
    (params.runTypes || []).forEach(val => {if (val != null) __params = __params.append('runTypes', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.numRuns != null) __params = __params.set('numRuns', params.numRuns.toString());
    if (params.jobId != null) __params = __params.set('jobId', params.jobId.toString());
    if (params.includeRpoSnapshots != null) __params = __params.set('includeRpoSnapshots', params.includeRpoSnapshots.toString());
    if (params.excludeTasks != null) __params = __params.set('excludeTasks', params.excludeTasks.toString());
    if (params.excludeNonRestoreableRuns != null) __params = __params.set('excludeNonRestoreableRuns', params.excludeNonRestoreableRuns.toString());
    if (params.excludeErrorRuns != null) __params = __params.set('excludeErrorRuns', params.excludeErrorRuns.toString());
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/protectionRuns`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProtectionRunInstance>>;
      })
    );
  }
  /**
   * List Protection Job Runs filtered by the specified parameters.
   *
   * If no parameters are specified, Job Runs currently
   * on the Cohesity Cluster are returned. Both running and completed Job Runs
   * are reported.
   * Specifying parameters filters the results that are returned.
   * @param params The `ProtectionRunsService.GetProtectionRunsParams` containing the following parameters:
   *
   * - `startedTimeUsecs`: Return a specific Job Run by specifying a time and a jobId.
   *   Specify the time when the Job Run started as a
   *   Unix epoch Timestamp (in microseconds).
   *   If this field is specified, jobId must also be
   *   specified.
   *
   * - `startTimeUsecs`: Filter by a start time. Only Job Runs that started after the specified
   *   time are returned.
   *   Specify the start time as a Unix epoch Timestamp (in microseconds).
   *
   * - `sourceId`: Filter by source id. Only Job Runs protecting the specified source
   *   (such as a VM or View) are returned. The source id
   *   is assigned by the Cohesity Cluster.
   *
   * - `runTypes`: Filter by run type such as 'kFull', 'kRegular' or 'kLog'.
   *   If not specified, Job Runs of all types are returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `numRuns`: Specify the number of Job Runs to return.
   *   The newest Job Runs are returned.
   *
   * - `jobId`: Filter by a Protection Job that is specified by id.
   *   If not specified, all Job Runs for all Protection Jobs are returned.
   *
   * - `includeRpoSnapshots`: If true, then the snapshots for Protection Sources protected by Rpo
   *   policies will also be returned.
   *
   * - `excludeTasks`: If true, the individual backup status for all the objects protected by
   *   the Job Run are not populated in the response. For example in a VMware
   *   environment, the status of backing up each VM associated with a Job
   *   is not returned.
   *
   * - `excludeNonRestoreableRuns`: Filter out jobs runs that cannot be restored by setting this field to
   *   'true'. If not set or set to 'false', Runs without any successful
   *   object will be returned. The default value is false.
   *
   * - `excludeErrorRuns`: Filter out Jobs Runs with errors by setting this field to 'true'.
   *   If not set or set to 'false', Job Runs with errors are returned.
   *
   * - `endTimeUsecs`: Filter by a end time specified as a Unix epoch Timestamp
   *   (in microseconds). Only Job Runs that completed before the specified
   *   end time are returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionRuns(params: ProtectionRunsService.GetProtectionRunsParams): __Observable<Array<ProtectionRunInstance>> {
    return this.GetProtectionRunsResponse(params).pipe(
      __map(_r => _r.body as Array<ProtectionRunInstance>)
    );
  }

  /**
   * Update how long Protection Job Runs and their snapshots are retained
   * on the Cohesity Cluster.
   *
   * Update the expiration date (retention period) for the specified Protection
   * Job Runs and their snapshots.
   * After an expiration time is reached, the Job Run and its snapshots are deleted.
   * If an expiration time of 0 is specified, a Job Run and its snapshots
   * are immediately deleted.
   * @param params The `ProtectionRunsService.UpdateProtectionRunsParams` containing the following parameters:
   *
   * - `body`: Request to update the expiration time of Protection Job Runs.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  UpdateProtectionRunsResponse(params: ProtectionRunsService.UpdateProtectionRunsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/protectionRuns`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update how long Protection Job Runs and their snapshots are retained
   * on the Cohesity Cluster.
   *
   * Update the expiration date (retention period) for the specified Protection
   * Job Runs and their snapshots.
   * After an expiration time is reached, the Job Run and its snapshots are deleted.
   * If an expiration time of 0 is specified, a Job Run and its snapshots
   * are immediately deleted.
   * @param params The `ProtectionRunsService.UpdateProtectionRunsParams` containing the following parameters:
   *
   * - `body`: Request to update the expiration time of Protection Job Runs.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  UpdateProtectionRuns(params: ProtectionRunsService.UpdateProtectionRunsParams): __Observable<null> {
    return this.UpdateProtectionRunsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Cancel a Protection Job run.
   * @param params The `ProtectionRunsService.CancelProtectionJobRunParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Job.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  CancelProtectionJobRunResponse(params: ProtectionRunsService.CancelProtectionJobRunParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/protectionRuns/cancel/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Cancel a Protection Job run.
   * @param params The `ProtectionRunsService.CancelProtectionJobRunParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Protection Job.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  CancelProtectionJobRun(params: ProtectionRunsService.CancelProtectionJobRunParams): __Observable<null> {
    return this.CancelProtectionJobRunResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * List Protection Job Run Errors filtered by the specified parameters.
   *
   * jobId, startTimeUsecs and taskId have to be provided to get the a list of
   * errors for a job run task.
   * @param params The `ProtectionRunsService.GetProtectionRunErrorsParams` containing the following parameters:
   *
   * - `taskId`: Specifies the id of the Protection Run task for which errors are to be
   *   returned. This field is required to get the errors list.
   *
   * - `startTimeUsecs`: Specifies the time when the Job Run started as a
   *   Unix epoch Timestamp (in microseconds).
   *   This field is required
   *
   * - `jobId`: Specifies the id of the Protection Job whose runs are to be returned.
   *   This field is required.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `paginationCookie`: Specifies the cookie for next set of results.
   *
   * - `limitNumberOfErrors`: Specifies the number of the results expected.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionRunErrorsResponse(params: ProtectionRunsService.GetProtectionRunErrorsParams): __Observable<__StrictHttpResponse<ProtectionRunErrors>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.taskId != null) __params = __params.set('taskId', params.taskId.toString());
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    if (params.jobId != null) __params = __params.set('jobId', params.jobId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.paginationCookie != null) __params = __params.set('paginationCookie', params.paginationCookie.toString());
    if (params.limitNumberOfErrors != null) __params = __params.set('limitNumberOfErrors', params.limitNumberOfErrors.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/protectionRuns/errors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectionRunErrors>;
      })
    );
  }
  /**
   * List Protection Job Run Errors filtered by the specified parameters.
   *
   * jobId, startTimeUsecs and taskId have to be provided to get the a list of
   * errors for a job run task.
   * @param params The `ProtectionRunsService.GetProtectionRunErrorsParams` containing the following parameters:
   *
   * - `taskId`: Specifies the id of the Protection Run task for which errors are to be
   *   returned. This field is required to get the errors list.
   *
   * - `startTimeUsecs`: Specifies the time when the Job Run started as a
   *   Unix epoch Timestamp (in microseconds).
   *   This field is required
   *
   * - `jobId`: Specifies the id of the Protection Job whose runs are to be returned.
   *   This field is required.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `paginationCookie`: Specifies the cookie for next set of results.
   *
   * - `limitNumberOfErrors`: Specifies the number of the results expected.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionRunErrors(params: ProtectionRunsService.GetProtectionRunErrorsParams): __Observable<ProtectionRunErrors> {
    return this.GetProtectionRunErrorsResponse(params).pipe(
      __map(_r => _r.body as ProtectionRunErrors)
    );
  }
}

module ProtectionRunsService {

  /**
   * Parameters for GetProtectionRuns
   */
  export interface GetProtectionRunsParams {

    /**
     * Return a specific Job Run by specifying a time and a jobId.
     * Specify the time when the Job Run started as a
     * Unix epoch Timestamp (in microseconds).
     * If this field is specified, jobId must also be
     * specified.
     */
    startedTimeUsecs?: number;

    /**
     * Filter by a start time. Only Job Runs that started after the specified
     * time are returned.
     * Specify the start time as a Unix epoch Timestamp (in microseconds).
     */
    startTimeUsecs?: number;

    /**
     * Filter by source id. Only Job Runs protecting the specified source
     * (such as a VM or View) are returned. The source id
     * is assigned by the Cohesity Cluster.
     */
    sourceId?: number;

    /**
     * Filter by run type such as 'kFull', 'kRegular' or 'kLog'.
     * If not specified, Job Runs of all types are returned.
     */
    runTypes?: Array<string>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specify the number of Job Runs to return.
     * The newest Job Runs are returned.
     */
    numRuns?: number;

    /**
     * Filter by a Protection Job that is specified by id.
     * If not specified, all Job Runs for all Protection Jobs are returned.
     */
    jobId?: number;

    /**
     * If true, then the snapshots for Protection Sources protected by Rpo
     * policies will also be returned.
     */
    includeRpoSnapshots?: boolean;

    /**
     * If true, the individual backup status for all the objects protected by
     * the Job Run are not populated in the response. For example in a VMware
     * environment, the status of backing up each VM associated with a Job
     * is not returned.
     */
    excludeTasks?: boolean;

    /**
     * Filter out jobs runs that cannot be restored by setting this field to
     * 'true'. If not set or set to 'false', Runs without any successful
     * object will be returned. The default value is false.
     */
    excludeNonRestoreableRuns?: boolean;

    /**
     * Filter out Jobs Runs with errors by setting this field to 'true'.
     * If not set or set to 'false', Job Runs with errors are returned.
     */
    excludeErrorRuns?: boolean;

    /**
     * Filter by a end time specified as a Unix epoch Timestamp
     * (in microseconds). Only Job Runs that completed before the specified
     * end time are returned.
     */
    endTimeUsecs?: number;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateProtectionRuns
   */
  export interface UpdateProtectionRunsParams {

    /**
     * Request to update the expiration time of Protection Job Runs.
     */
    body: UpdateProtectionJobRunsParam;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CancelProtectionJobRun
   */
  export interface CancelProtectionJobRunParams {

    /**
     * Specifies a unique id of the Protection Job.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;
    body?: CancelProtectionJobRunParam;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetProtectionRunErrors
   */
  export interface GetProtectionRunErrorsParams {

    /**
     * Specifies the id of the Protection Run task for which errors are to be
     * returned. This field is required to get the errors list.
     */
    taskId: number;

    /**
     * Specifies the time when the Job Run started as a
     * Unix epoch Timestamp (in microseconds).
     * This field is required
     */
    startTimeUsecs: number;

    /**
     * Specifies the id of the Protection Job whose runs are to be returned.
     * This field is required.
     */
    jobId: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the cookie for next set of results.
     */
    paginationCookie?: string;

    /**
     * Specifies the number of the results expected.
     */
    limitNumberOfErrors?: number;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { ProtectionRunsService }
