/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { NisNetgroups } from '../models/nis-netgroups';
import { NisNetgroup } from '../models/nis-netgroup';
import { NisProviders } from '../models/nis-providers';
import { NisProvider } from '../models/nis-provider';
@Injectable({
  providedIn: 'root',
})
class NetworkInformationServiceService extends __BaseService {
  static readonly GetNisNetgroupsPath = '/nis-netgroups';
  static readonly CreateNisNetgroupPath = '/nis-netgroups';
  static readonly GetNisNetgroupByNamePath = '/nis-netgroups/{name}';
  static readonly UpdateNisNetgroupByNamePath = '/nis-netgroups/{name}';
  static readonly DeleteNisNetgroupByNamePath = '/nis-netgroups/{name}';
  static readonly GetNisProvidersPath = '/nis-providers';
  static readonly CreateNisProviderPath = '/nis-providers';
  static readonly GetNisProviderByDomainNamePath = '/nis-providers/{domain}';
  static readonly UpdateNisProviderByDomainNamePath = '/nis-providers/{domain}';
  static readonly DeleteNisProviderByDomainNamePath = '/nis-providers/{domain}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a list of NIS netgroups.
   *
   * Get a list of NIS netgroups.
   * @param params The `NetworkInformationServiceService.GetNisNetgroupsParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `netgroupNames`: Filter by a list of NIS netgroup names.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetNisNetgroupsResponse(params: NetworkInformationServiceService.GetNisNetgroupsParams): __Observable<__StrictHttpResponse<NisNetgroups>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.netgroupNames || []).forEach(val => {if (val != null) __params = __params.append('netgroupNames', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/nis-netgroups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NisNetgroups>;
      })
    );
  }
  /**
   * Get a list of NIS netgroups.
   *
   * Get a list of NIS netgroups.
   * @param params The `NetworkInformationServiceService.GetNisNetgroupsParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `netgroupNames`: Filter by a list of NIS netgroup names.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetNisNetgroups(params: NetworkInformationServiceService.GetNisNetgroupsParams): __Observable<NisNetgroups> {
    return this.GetNisNetgroupsResponse(params).pipe(
      __map(_r => _r.body as NisNetgroups)
    );
  }

  /**
   * Create an NIS netgroup.
   *
   * Create an NIS netgroup.
   * @param params The `NetworkInformationServiceService.CreateNisNetgroupParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create an NIS netgroup.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateNisNetgroupResponse(params: NetworkInformationServiceService.CreateNisNetgroupParams): __Observable<__StrictHttpResponse<NisNetgroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/nis-netgroups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NisNetgroup>;
      })
    );
  }
  /**
   * Create an NIS netgroup.
   *
   * Create an NIS netgroup.
   * @param params The `NetworkInformationServiceService.CreateNisNetgroupParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create an NIS netgroup.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateNisNetgroup(params: NetworkInformationServiceService.CreateNisNetgroupParams): __Observable<NisNetgroup> {
    return this.CreateNisNetgroupResponse(params).pipe(
      __map(_r => _r.body as NisNetgroup)
    );
  }

  /**
   * Get an NIS netgroup by name.
   *
   * Get an NIS netgroup by name.
   * @param params The `NetworkInformationServiceService.GetNisNetgroupByNameParams` containing the following parameters:
   *
   * - `name`: Specifies name of the NIS netgroup.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetNisNetgroupByNameResponse(params: NetworkInformationServiceService.GetNisNetgroupByNameParams): __Observable<__StrictHttpResponse<NisNetgroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/nis-netgroups/${encodeURIComponent(params.name)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NisNetgroup>;
      })
    );
  }
  /**
   * Get an NIS netgroup by name.
   *
   * Get an NIS netgroup by name.
   * @param params The `NetworkInformationServiceService.GetNisNetgroupByNameParams` containing the following parameters:
   *
   * - `name`: Specifies name of the NIS netgroup.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetNisNetgroupByName(params: NetworkInformationServiceService.GetNisNetgroupByNameParams): __Observable<NisNetgroup> {
    return this.GetNisNetgroupByNameResponse(params).pipe(
      __map(_r => _r.body as NisNetgroup)
    );
  }

  /**
   * Update an NIS netgroup by name.
   *
   * Update an NIS netgroup by name.
   * @param params The `NetworkInformationServiceService.UpdateNisNetgroupByNameParams` containing the following parameters:
   *
   * - `name`: Specifies name of the NIS netgroup.
   *
   * - `body`: Request to update the NIS netgroup.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateNisNetgroupByNameResponse(params: NetworkInformationServiceService.UpdateNisNetgroupByNameParams): __Observable<__StrictHttpResponse<NisNetgroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/nis-netgroups/${encodeURIComponent(params.name)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NisNetgroup>;
      })
    );
  }
  /**
   * Update an NIS netgroup by name.
   *
   * Update an NIS netgroup by name.
   * @param params The `NetworkInformationServiceService.UpdateNisNetgroupByNameParams` containing the following parameters:
   *
   * - `name`: Specifies name of the NIS netgroup.
   *
   * - `body`: Request to update the NIS netgroup.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateNisNetgroupByName(params: NetworkInformationServiceService.UpdateNisNetgroupByNameParams): __Observable<NisNetgroup> {
    return this.UpdateNisNetgroupByNameResponse(params).pipe(
      __map(_r => _r.body as NisNetgroup)
    );
  }

  /**
   * Delete an NIS netgroup by name.
   *
   * Delete an NIS netgroup by name.
   * @param params The `NetworkInformationServiceService.DeleteNisNetgroupByNameParams` containing the following parameters:
   *
   * - `name`: Specifies name of the NIS netgroup.
   *
   * - `body`: Request to delete the NIS netgroup.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteNisNetgroupByNameResponse(params: NetworkInformationServiceService.DeleteNisNetgroupByNameParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/nis-netgroups/${encodeURIComponent(params.name)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete an NIS netgroup by name.
   *
   * Delete an NIS netgroup by name.
   * @param params The `NetworkInformationServiceService.DeleteNisNetgroupByNameParams` containing the following parameters:
   *
   * - `name`: Specifies name of the NIS netgroup.
   *
   * - `body`: Request to delete the NIS netgroup.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteNisNetgroupByName(params: NetworkInformationServiceService.DeleteNisNetgroupByNameParams): __Observable<null> {
    return this.DeleteNisNetgroupByNameResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get a list of NIS Providers.
   *
   * Get a list of NIS Providers.
   * @param params The `NetworkInformationServiceService.GetNisProvidersParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `domainNames`: Filter by a list of NIS domain names.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetNisProvidersResponse(params: NetworkInformationServiceService.GetNisProvidersParams): __Observable<__StrictHttpResponse<NisProviders>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.domainNames || []).forEach(val => {if (val != null) __params = __params.append('domainNames', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/nis-providers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NisProviders>;
      })
    );
  }
  /**
   * Get a list of NIS Providers.
   *
   * Get a list of NIS Providers.
   * @param params The `NetworkInformationServiceService.GetNisProvidersParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `domainNames`: Filter by a list of NIS domain names.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetNisProviders(params: NetworkInformationServiceService.GetNisProvidersParams): __Observable<NisProviders> {
    return this.GetNisProvidersResponse(params).pipe(
      __map(_r => _r.body as NisProviders)
    );
  }

  /**
   * Create an NIS Provider.
   *
   * Create an NIS Provider.
   * @param params The `NetworkInformationServiceService.CreateNisProviderParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create an NIS provider entry.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateNisProviderResponse(params: NetworkInformationServiceService.CreateNisProviderParams): __Observable<__StrictHttpResponse<NisProvider>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/nis-providers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NisProvider>;
      })
    );
  }
  /**
   * Create an NIS Provider.
   *
   * Create an NIS Provider.
   * @param params The `NetworkInformationServiceService.CreateNisProviderParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create an NIS provider entry.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateNisProvider(params: NetworkInformationServiceService.CreateNisProviderParams): __Observable<NisProvider> {
    return this.CreateNisProviderResponse(params).pipe(
      __map(_r => _r.body as NisProvider)
    );
  }

  /**
   * Get an NIS Provider by domain name.
   *
   * Get an NIS Provider by domain name.
   * @param params The `NetworkInformationServiceService.GetNisProviderByDomainNameParams` containing the following parameters:
   *
   * - `domain`: Specifies domain of an NIS Provider.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetNisProviderByDomainNameResponse(params: NetworkInformationServiceService.GetNisProviderByDomainNameParams): __Observable<__StrictHttpResponse<NisProvider>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/nis-providers/${encodeURIComponent(params.domain)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NisProvider>;
      })
    );
  }
  /**
   * Get an NIS Provider by domain name.
   *
   * Get an NIS Provider by domain name.
   * @param params The `NetworkInformationServiceService.GetNisProviderByDomainNameParams` containing the following parameters:
   *
   * - `domain`: Specifies domain of an NIS Provider.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetNisProviderByDomainName(params: NetworkInformationServiceService.GetNisProviderByDomainNameParams): __Observable<NisProvider> {
    return this.GetNisProviderByDomainNameResponse(params).pipe(
      __map(_r => _r.body as NisProvider)
    );
  }

  /**
   * Update an NIS Provider by domain name.
   *
   * Update an NIS Provider by domain name.
   * @param params The `NetworkInformationServiceService.UpdateNisProviderByDomainNameParams` containing the following parameters:
   *
   * - `domain`: Specifies domain name of an NIS Provider.
   *
   * - `body`: Request to update an NIS Provider.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateNisProviderByDomainNameResponse(params: NetworkInformationServiceService.UpdateNisProviderByDomainNameParams): __Observable<__StrictHttpResponse<NisProvider>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/nis-providers/${encodeURIComponent(params.domain)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NisProvider>;
      })
    );
  }
  /**
   * Update an NIS Provider by domain name.
   *
   * Update an NIS Provider by domain name.
   * @param params The `NetworkInformationServiceService.UpdateNisProviderByDomainNameParams` containing the following parameters:
   *
   * - `domain`: Specifies domain name of an NIS Provider.
   *
   * - `body`: Request to update an NIS Provider.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateNisProviderByDomainName(params: NetworkInformationServiceService.UpdateNisProviderByDomainNameParams): __Observable<NisProvider> {
    return this.UpdateNisProviderByDomainNameResponse(params).pipe(
      __map(_r => _r.body as NisProvider)
    );
  }

  /**
   * Delete an NIS Provider by domain name.
   *
   * Delete an NIS Provider by domain name.
   * @param params The `NetworkInformationServiceService.DeleteNisProviderByDomainNameParams` containing the following parameters:
   *
   * - `domain`: Specifies domain name of an NIS Provider.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteNisProviderByDomainNameResponse(params: NetworkInformationServiceService.DeleteNisProviderByDomainNameParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/nis-providers/${encodeURIComponent(params.domain)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete an NIS Provider by domain name.
   *
   * Delete an NIS Provider by domain name.
   * @param params The `NetworkInformationServiceService.DeleteNisProviderByDomainNameParams` containing the following parameters:
   *
   * - `domain`: Specifies domain name of an NIS Provider.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteNisProviderByDomainName(params: NetworkInformationServiceService.DeleteNisProviderByDomainNameParams): __Observable<null> {
    return this.DeleteNisProviderByDomainNameResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module NetworkInformationServiceService {

  /**
   * Parameters for GetNisNetgroups
   */
  export interface GetNisNetgroupsParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Filter by a list of NIS netgroup names.
     */
    netgroupNames?: Array<string>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateNisNetgroup
   */
  export interface CreateNisNetgroupParams {

    /**
     * Specifies the parameters to create an NIS netgroup.
     */
    body: NisNetgroup;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetNisNetgroupByName
   */
  export interface GetNisNetgroupByNameParams {

    /**
     * Specifies name of the NIS netgroup.
     */
    name: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateNisNetgroupByName
   */
  export interface UpdateNisNetgroupByNameParams {

    /**
     * Specifies name of the NIS netgroup.
     */
    name: string;

    /**
     * Request to update the NIS netgroup.
     */
    body: NisNetgroup;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteNisNetgroupByName
   */
  export interface DeleteNisNetgroupByNameParams {

    /**
     * Specifies name of the NIS netgroup.
     */
    name: string;

    /**
     * Request to delete the NIS netgroup.
     */
    body: NisNetgroup;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetNisProviders
   */
  export interface GetNisProvidersParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Filter by a list of NIS domain names.
     */
    domainNames?: Array<string>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateNisProvider
   */
  export interface CreateNisProviderParams {

    /**
     * Specifies the parameters to create an NIS provider entry.
     */
    body: NisProvider;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetNisProviderByDomainName
   */
  export interface GetNisProviderByDomainNameParams {

    /**
     * Specifies domain of an NIS Provider.
     */
    domain: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateNisProviderByDomainName
   */
  export interface UpdateNisProviderByDomainNameParams {

    /**
     * Specifies domain name of an NIS Provider.
     */
    domain: string;

    /**
     * Request to update an NIS Provider.
     */
    body: NisProvider;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteNisProviderByDomainName
   */
  export interface DeleteNisProviderByDomainNameParams {

    /**
     * Specifies domain name of an NIS Provider.
     */
    domain: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { NetworkInformationServiceService }
