import { Component, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FileSelectorService } from '@cohesity/shared/core';
import { AjaxHandlerService } from '@cohesity/utils';
import { takeUntilDestroyed } from 'ngx-sub-form';

import { BaseProtectionBuilderComponent } from '../../base-protection-builder/base-protection-builder.component';

interface InclusionFileUpload {
  enable: boolean;
  inclusionList: string[];
  overwriteInclusionList: boolean;
  exclusionList: string[];
  overwriteExclusionList: boolean;
}

@Component({
  selector: 'coh-settings-list-inclusion-file-upload',
  templateUrl: './settings-list-inclusion-file-upload.component.html',
  styleUrls: ['./settings-list-inclusion-file-upload.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SettingsListInclusionFileUploadComponent
  extends BaseProtectionBuilderComponent<InclusionFileUpload, any> {
  /**
   * Default value.
   */
  _value: InclusionFileUpload = {
    enable: false,
    inclusionList: null,
    exclusionList: null,
    overwriteExclusionList: false,
    overwriteInclusionList: false
  };

  /** Holds the file name of the uploaded file for inclusion. */
  inclusionFileName: string;

  /** Holds the Inclusion list entries. */
  inclusionEntries: string[];

  /** Holds the file name of the uploaded file for exclusion. */
  exclusionFileName: string;

  /** Holds the Exclusion list entries. */
  exclusionEntries: string[];

  /** Holds the type of file. */
  fileTypes = ['include', 'exclude'];

  /**
   * Form group.
   */
  inclusionFormGroup: UntypedFormGroup = new UntypedFormGroup({
    enable: new UntypedFormControl(this.value.enable, [Validators.required]),
    inclusionList: new UntypedFormControl(this.value.inclusionList),
    overwriteInclusionList: new UntypedFormControl(this.value.overwriteInclusionList),
    exclusionList: new UntypedFormControl(this.value.exclusionList),
    overwriteExclusionList: new UntypedFormControl(this.value.overwriteExclusionList),
  });

  constructor(
    private fileSelectorService: FileSelectorService,
    private ajaxHandlerService: AjaxHandlerService,) {
    super();
  }

  /**
   * Override of addFormControl from BaseProtectionBuilderComponent so this component's FormControl isn't required.
   */
  addFormControl() {
    this.formGroup.addControl(this.name, this.inclusionFormGroup);
  }

  /**
   * Handler for click of remove link to remove uploaded file.
   */
  removeFile(listType: string) {
    if (listType === 'include') {
      this.inclusionFileName = '';
      this.inclusionEntries = [];
      this.inclusionFormGroup.controls.inclusionList.setValue([]);
    } else {
      this.exclusionFileName = '';
      this.exclusionEntries = [];
      this.inclusionFormGroup.controls.exclusionList.setValue([]);
    }
    this.inclusionFormGroup.updateValueAndValidity();
  }

  /**
   * Method to parse the uploaded CSV data.
   *
   * @param csv Text content read from the csv file.
   * @returns An array of string
   */
  private processData(csv: string): string[] {
    // Parse a text file with FQN seperated by new line and Remove trailing comma and space
    return csv.split('\n')
      .map((entity) => entity.replace(/(\s*,?\s*)*$/, ''))
      .filter(entity => entity);
  }

  /**
   * Read objectList.
   */
  readObjectList(listType: string) {
    let inputFileName = '#select-include-list-file';
    let listControl = this.inclusionFormGroup.controls.inclusionList;

    if (listType === 'exclude') {
      inputFileName = '#select-exclude-list-file';
      listControl = this.inclusionFormGroup.controls.exclusionList;
    }

    this.fileSelectorService.showFileInput(document.querySelector(inputFileName), {
      allowMultiple: false,
      extensions: ['txt'],
    }).pipe(takeUntilDestroyed(this))
      .subscribe(results => {
        const file = results[0];
        const allEntries = this.processData(file.fileContent);
        if (listType === 'exclude') {
          this.exclusionFileName = file.name;
          this.exclusionEntries = this.processData(file.fileContent);
        } else {
          this.inclusionFileName = file.name;
          this.inclusionEntries = this.processData(file.fileContent);
        }

        // Set form values.
        listControl.setValue(allEntries.length ? allEntries : []);
        this.inclusionFormGroup.updateValueAndValidity();
      }, error => this.ajaxHandlerService.handler(error));
  }

  /**
   * Returns the file name.
   */
  fileName(type: string): string {
    return type === 'include' ? this.inclusionFileName : this.exclusionFileName;
  }

  /**
   * Returns the object entries count .
   */
  objectCount(type: string): number {
    return type === 'include' ? this.inclusionEntries.length : this.exclusionEntries.length;
  }

  /**
   * Returns the form control for overwrite checkbox.
   */
  overwriteFormControl(type: string) {
    return type === 'include' ? this.inclusionFormGroup.controls.overwriteInclusionList :
      this.inclusionFormGroup.controls.overwriteExclusionList;
  }

  /**
   * Returns state of right app layout column.
   */
  showFileName(type: string): boolean {
    let showFileName = false;
    if (type === 'include') {
      showFileName = !!this.inclusionFileName;
    } else {
      showFileName = !!this.exclusionFileName;
    }
    return showFileName;
  }
}
