<div
  class="endpoint-row"
  [formGroup]="privateEndpointField"
>
  <div class="sas-url-select-field">
    <mat-form-field>
      <mat-label>{{'region' | translate}}</mat-label>
      <mat-select cogDataId="region" formControlName="region">
        <mat-option
          *ngFor="let region of regions"
          [value]="region.protectionSource.azureProtectionSource.location">
          {{ region.protectionSource.azureProtectionSource.location }}
        </mat-option>

      </mat-select>
    </mat-form-field>
  </div>

  <div class="sas-url-select-field">
    <mat-form-field>
      <mat-label>{{'virtualNetwork' | translate}}</mat-label>
      <mat-select cogDataId="virtual-network" formControlName="virtualNetwork">
        <mat-option
          *ngFor="let virtualNetwork of virtualNetworks$ | async"
          [value]="virtualNetwork.protectionSource.id">
          {{ virtualNetwork.protectionSource.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="sas-url-select-field" >
    <mat-form-field>
      <mat-label>{{'subnet' | translate}}</mat-label>
      <mat-select cogDataId="subnet" formControlName="subnet">
        <mat-option
          *ngFor="let subnet of subnets$ | async"
          [value]="subnet.protectionSource.id">
          {{ subnet.protectionSource.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
