/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { NodeGroupResponse } from '../models/node-group-response';
import { NodeGroupRequest } from '../models/node-group-request';
@Injectable({
  providedIn: 'root',
})
class NodeGroupService extends __BaseService {
  static readonly GetNodeGroupsPath = '/node-groups';
  static readonly CreateNodeGroupPath = '/node-groups';
  static readonly GetNodeGroupByNamePath = '/node-groups/{groupName}';
  static readonly UpdateNodeGroupPath = '/node-groups/{groupName}';
  static readonly DeleteNodeGroupPath = '/node-groups/{groupName}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List Node Groups based on provided filtering parameters.
   *
   * List node groups.
   * @param params The `NodeGroupService.GetNodeGroupsParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `groupType`: Filter node groups by a node group type.
   *
   * - `groupNames`: Filter node groups by a list of node group names.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetNodeGroupsResponse(params: NodeGroupService.GetNodeGroupsParams): __Observable<__StrictHttpResponse<NodeGroupResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.groupType != null) __params = __params.set('groupType', params.groupType.toString());
    (params.groupNames || []).forEach(val => {if (val != null) __params = __params.append('groupNames', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/node-groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NodeGroupResponse>;
      })
    );
  }
  /**
   * List Node Groups based on provided filtering parameters.
   *
   * List node groups.
   * @param params The `NodeGroupService.GetNodeGroupsParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `groupType`: Filter node groups by a node group type.
   *
   * - `groupNames`: Filter node groups by a list of node group names.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetNodeGroups(params: NodeGroupService.GetNodeGroupsParams): __Observable<NodeGroupResponse> {
    return this.GetNodeGroupsResponse(params).pipe(
      __map(_r => _r.body as NodeGroupResponse)
    );
  }

  /**
   * Create a Node Group.
   *
   * Create the Node Group and returns the newly created node group object.
   * @param params The `NodeGroupService.CreateNodeGroupParams` containing the following parameters:
   *
   * - `body`: Request to create a Node Group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateNodeGroupResponse(params: NodeGroupService.CreateNodeGroupParams): __Observable<__StrictHttpResponse<NodeGroupResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/node-groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NodeGroupResponse>;
      })
    );
  }
  /**
   * Create a Node Group.
   *
   * Create the Node Group and returns the newly created node group object.
   * @param params The `NodeGroupService.CreateNodeGroupParams` containing the following parameters:
   *
   * - `body`: Request to create a Node Group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateNodeGroup(params: NodeGroupService.CreateNodeGroupParams): __Observable<NodeGroupResponse> {
    return this.CreateNodeGroupResponse(params).pipe(
      __map(_r => _r.body as NodeGroupResponse)
    );
  }

  /**
   * List Node Groups for a given Group Name.
   *
   * Returns Node Group for given Group Name.
   * @param params The `NodeGroupService.GetNodeGroupByNameParams` containing the following parameters:
   *
   * - `groupName`: Specifies a unique id of Node Group to return.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetNodeGroupByNameResponse(params: NodeGroupService.GetNodeGroupByNameParams): __Observable<__StrictHttpResponse<NodeGroupResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/node-groups/${encodeURIComponent(params.groupName)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NodeGroupResponse>;
      })
    );
  }
  /**
   * List Node Groups for a given Group Name.
   *
   * Returns Node Group for given Group Name.
   * @param params The `NodeGroupService.GetNodeGroupByNameParams` containing the following parameters:
   *
   * - `groupName`: Specifies a unique id of Node Group to return.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetNodeGroupByName(params: NodeGroupService.GetNodeGroupByNameParams): __Observable<NodeGroupResponse> {
    return this.GetNodeGroupByNameResponse(params).pipe(
      __map(_r => _r.body as NodeGroupResponse)
    );
  }

  /**
   * Update a Node Group.
   *
   * Specifies the request to update the existing Node Group. On successful update, returns the updated node group object.
   * @param params The `NodeGroupService.UpdateNodeGroupParams` containing the following parameters:
   *
   * - `groupName`: Specifies a unique name of the Node Group to update.
   *
   * - `body`: Request to update a Node Group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateNodeGroupResponse(params: NodeGroupService.UpdateNodeGroupParams): __Observable<__StrictHttpResponse<NodeGroupResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/node-groups/${encodeURIComponent(params.groupName)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NodeGroupResponse>;
      })
    );
  }
  /**
   * Update a Node Group.
   *
   * Specifies the request to update the existing Node Group. On successful update, returns the updated node group object.
   * @param params The `NodeGroupService.UpdateNodeGroupParams` containing the following parameters:
   *
   * - `groupName`: Specifies a unique name of the Node Group to update.
   *
   * - `body`: Request to update a Node Group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateNodeGroup(params: NodeGroupService.UpdateNodeGroupParams): __Observable<NodeGroupResponse> {
    return this.UpdateNodeGroupResponse(params).pipe(
      __map(_r => _r.body as NodeGroupResponse)
    );
  }

  /**
   * Delete a Node Group.
   *
   * Deletes a Node Group based on given node group name.
   * @param params The `NodeGroupService.DeleteNodeGroupParams` containing the following parameters:
   *
   * - `groupName`: Specifies a unique name of the Node Group to delete.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteNodeGroupResponse(params: NodeGroupService.DeleteNodeGroupParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/node-groups/${encodeURIComponent(params.groupName)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a Node Group.
   *
   * Deletes a Node Group based on given node group name.
   * @param params The `NodeGroupService.DeleteNodeGroupParams` containing the following parameters:
   *
   * - `groupName`: Specifies a unique name of the Node Group to delete.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteNodeGroup(params: NodeGroupService.DeleteNodeGroupParams): __Observable<null> {
    return this.DeleteNodeGroupResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module NodeGroupService {

  /**
   * Parameters for GetNodeGroups
   */
  export interface GetNodeGroupsParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Filter node groups by a node group type.
     */
    groupType?: number;

    /**
     * Filter node groups by a list of node group names.
     */
    groupNames?: Array<string>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateNodeGroup
   */
  export interface CreateNodeGroupParams {

    /**
     * Request to create a Node Group.
     */
    body: NodeGroupRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetNodeGroupByName
   */
  export interface GetNodeGroupByNameParams {

    /**
     * Specifies a unique id of Node Group to return.
     */
    groupName: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateNodeGroup
   */
  export interface UpdateNodeGroupParams {

    /**
     * Specifies a unique name of the Node Group to update.
     */
    groupName: string;

    /**
     * Request to update a Node Group.
     */
    body: NodeGroupRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteNodeGroup
   */
  export interface DeleteNodeGroupParams {

    /**
     * Specifies a unique name of the Node Group to delete.
     */
    groupName: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { NodeGroupService }
