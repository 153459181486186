<h4 class="cog-h4">
  {{ 'gcpMultiSubnet.fleetSettings' | translate }}
  <h6 class="cog-h6">
    {{ 'optional' | translate }}
  </h6>
</h4>
<div class="flex-row">
  <cog-banner status="info" class="info-wrapper">
    <p class="cog-body" [innerHtml]="'gcpMultiSubnet.fleetInformation' | translate"></p>
  </cog-banner>
</div>
<div [formGroup]="fleetControl" cogDataId="fleet-location">
  <div
    class="flex-row"
    formArrayName="locations"
    *ngFor="let location of locationGetter.controls; let i = index"
    cogDataId="{{ i }}"
    [inheritDataId]="true">
    <div class="flex-column">
      <mat-form-field>
        <mat-label *ngIf="i === 0" class="mat-h5"> {{ 'gcpMultiSubnet.primaryFleetInstanceLocation' | translate }}
        </mat-label>

        <mat-label *ngIf="i === 1" class="mat-h5"> {{ 'gcpMultiSubnet.secondaryFleetInstanceLocation' | translate }}
        </mat-label>

        <mat-label *ngIf="i === 2" class="mat-h5"> {{ 'gcpMultiSubnet.tertiaryFleetInstanceLocation' | translate }}
        </mat-label>

        <mat-select
          cogDataId="select"
          [inheritDataId]="true"
          formControlName="{{ i }}"
          panelClass="cog-mat-select-panel-lg fleet-location-select"
          [disableOptionCentering]="true">
          <mat-select-trigger *ngIf="viewValue(i)">
            {{ viewValue(i) }}
          </mat-select-trigger>
          <mat-option
            cogDataId="{{ j }}"
            [inheritDataId]="true"
            *ngFor="let location of fleetLocations; let j = index"
            [disabled]="isOptionDisabled(i, location.value)"
            [value]="location.value"
            multiLineOption>
            <div>{{ location.viewValue }}</div>
            <div class="cog-chart">{{ location.helperText }}</div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="flex-column">
      <coh-form-array-action-control
        cogDataId="actions"
        [inheritDataId]="true"
        [formArrayLength]="locationGetter.controls.length"
        [index]="i"
        [addActionDisabled]="isAddLocationDisabled(i)"
        (controlAdded)="addLocation(i)"
        (controlRemoved)="removeLocation(i)">
      </coh-form-array-action-control>
    </div>
  </div>

  <!-- The custom subnet priority order -->
  <coh-gcp-fleet-subnet-settings *ngIf="showSubnetSettings"
    formControlName="customSubnets"
    [availableSubnetConfigs]="hypervisor.availableSubnetConfigs">
  </coh-gcp-fleet-subnet-settings>
</div>
