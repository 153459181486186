import { ProtectionSourceNode } from '@cohesity/api/v1';
import { DataTreeSelection } from '@cohesity/helix';
import { Environment } from 'src/app/shared/constants';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';

import { ProtectionSourceDataNode } from '../shared/protection-source-data-node';
import { KubernetesProtectionTypes } from './kubernetes.constants';
/**
 * Extract tags from a vmware ProtectionSourceNode. This is outside the class since it is
 * called from the constructor before the class has been completely initialized.
 *
 * @param   data   A protection source node
 * @returns Any applied tags ids or an empty list.
 */
function findTags(data: ProtectionSourceNode): number[] {
  const envSource = data.protectionSource.kubernetesProtectionSource;

  return (envSource.labelAttributes || []).map(label => label.id);
}


export type KubernetesSelection = DataTreeSelection<KubernetesSourceDataNode>;

/**
 * Represents kubernetes node and job tree selection behavior.
 */
export class KubernetesSourceDataNode extends ProtectionSourceDataNode {
  constructor(data: ProtectionSourceNode,
    private irisCtx: IrisContextService,
    readonly level: number) {
    super(Environment.kKubernetes, data, level, findTags(data));
  }

  /**
   * Whether the node is a leaf which can be directly selected or not.
   */
  get isLeaf() {
    return this.type === KubernetesProtectionTypes.kNamespace;
  }

  /**
   * Whether the current node is a tag or not. This is determined by the node type.
   * Override isTag of protection-source-data-node to use kLabel instead of kTag.
   */
  get isTag(): boolean {
    return this.type === KubernetesProtectionTypes.kLabel;
  }

  /**
   * whether node should be expanded or not, namespace at level 1 are expandable in
   * hierarcy view and have children and namespace are not expandable and label are
   * always expandable
   */
  get expandable(): boolean {
    if (flagEnabled(this.irisCtx.irisContext, 'includeExcludePvcKubernetes')) {
      return !(this.type === KubernetesProtectionTypes.kNamespace);
    }
    return super.expandable;
  }
}
