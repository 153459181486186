import { ChangeDetectionStrategy, Component, ElementRef, OnInit } from '@angular/core';

import { GraphComponentService } from '../../shared/graph-component.service';

/**
 * This is a low-level yfiles graph component. It renders a div and then uses the yfiles library to replace the content
 * with a yfiles GraphComponent object.
 */
@Component({
  selector: 'hyf-graph-component',
  styleUrls: ['./graph-component.component.scss'],
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraphComponentComponent implements OnInit {
  constructor(private elementRef: ElementRef, private graphComponentService: GraphComponentService) {}

  ngOnInit(): void {
    // add the GraphComponent to the div of this component
    const graphComponent = this.graphComponentService.getGraphComponent();
    const div = graphComponent.div;
    div.style.height = '100%';
    this.elementRef.nativeElement.appendChild(div);
  }
}
