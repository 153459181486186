import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { ChangeTypeCount, FileChangeType } from '@cohesity/api/argus';

import { computeAffectedFileStats, sortChangeTypeCallbackFn } from './change-type-stats.model';

/** The Set of hidden file change type. */
const hiddenFileChangeTypes = new Set([FileChangeType.KAny]);

/**
 * This component lists all file stats of an anomaly
 *
 * @example
 * <dg-ar-change-type-stats
 *   [changeTypeStats]="changeTypeStats">
 * </dg-ar-change-type-stats>
 */
@Component({
  selector: 'dg-ar-change-type-stats',
  templateUrl: './change-type-stats.component.html',
  styleUrls: ['./change-type-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ChangeTypeStatsComponent {
  /**
   * Holds the sorted stats
   */
  private _changeTypeStats: ChangeTypeCount[] = [];

  /**
   * Indicates that to show pattern matched stats
   */
  @Input() showPatternsMatched = false;

  /**
   * Patterns matched stats
   */
  @Input() patternsMatched = 0;

  /**
   * Get input as affected files stats and sorts it
   */
  @Input() set changeTypeStats(changeTypeStats: ChangeTypeCount[]) {
    this._changeTypeStats = computeAffectedFileStats(changeTypeStats || [])
      .sort(sortChangeTypeCallbackFn)
      .filter(({ changeType }) => !hiddenFileChangeTypes.has(changeType));
  }

  /**
   * Gets change type count stats from private member
   */
  get changeTypeStats(): ChangeTypeCount[] {
    return this._changeTypeStats;
  }
}
