import { Injectable } from '@angular/core';
import { ProtectedObject } from '@cohesity/api/v2';
import { NavItem } from '@cohesity/helix';
import { StateService } from '@uirouter/core';
import { PassthroughOptions, RecoveryAction } from 'src/app/shared';

import { ProtectionGroupService } from '../protection-group-shared';
import { RestorePointSelection } from '../restore/restore-shared';
import { ObjectActionCreator } from './object-action-creator';
import { ProtectedObjectsService } from './protected-objects.service';

@Injectable({
  providedIn: 'root',
})
export class SfdcObjectActionCreator extends ObjectActionCreator {
  constructor(
    objectsService: ProtectedObjectsService,
    protectionGroupService: ProtectionGroupService,
    stateService: StateService) {
    super(objectsService, protectionGroupService, stateService);
  }

  /**
   * Creates and returns Sfdc recovery action.
   *
   * @param object The protected object.
   * @returns The recovery NavItem object.
   */
  createSfdcRecoverAction(object: ProtectedObject,
    type: RecoveryAction,
    restorePointSelection?: RestorePointSelection,
    objectOptions?: PassthroughOptions): NavItem {
    if (!object?.latestSnapshotsInfo) {
      return;
    }

    const restorePoints = super.getRestorePointSelection([object], restorePointSelection);

    if (!restorePoints) {
      return;
    }

    let displayName = 'Recover';
    let icon = 'restore';

    if (type === RecoveryAction.RecoverSfdcObjects) {
      displayName = 'recoverObject';
    } else if (type === RecoveryAction.RecoverSfdcRecords) {
      displayName = 'recoverRecords';
      icon = 'note!left';
    } else if (type === RecoveryAction.RecoverSfdcOrg) {
      displayName = 'recoverSfdcOrg';
    }

    return {
      displayName: displayName,
      icon: icon,
      state: 'recover-sfdc-ng',
      stateParams: {
        restorePoints: restorePoints,
        objectId: object.id,
        objectName: object.name,
        recoveryAction: type,
        cid: objectOptions.accessClusterId,
        regionId: objectOptions.regionId,
      }
    };
  }
}
