import { UntypedFormControl, ValidatorFn } from '@angular/forms';
import { DateRange, Timeframe } from '@cohesity/helix';

/**
 * Function to return an error object if a form control's value invalid
 *
 * @param control The form control.
 * @return null if valid, otherwise error object.
 */
export const DateRangePickerValidator: ValidatorFn = <D>(control: UntypedFormControl) => {
  const dateRange = control.value as DateRange<D>;

  if (!dateRange) {
    return { dateRangePicker: true };
  }

  const { start, end, timeframe } = dateRange;

  if (timeframe === Timeframe.Forever || (start && end)) {
    return null;
  }
  return { dateRangePicker: true };
};
