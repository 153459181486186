<ng-container>
  <div class="margin-top-lg">{{ label }}</div>
  <div class="row">
    <div class="col-xs-3">
      <mat-form-field>
        <mat-label>{{'username' | translate}}</mat-label>
        <input matInput
          cogDataId="recover-to-credentials-username"
          type="text"
          [formControl]="usernameControl">
      </mat-form-field>
      <mat-error *ngIf="usernameControl.touched && usernameControl.errors?.required">
        {{'errors.required' | translate}}
      </mat-error>
    </div>
    <div class="col-xs-3">
      <mat-form-field>
        <mat-label>{{'password' | translate}}</mat-label>
        <input matInput
          cogDataId="recover-to-credentials-password"
          type="password"
          [formControl]="passwordControl">
      </mat-form-field>
      <mat-error *ngIf="passwordControl.touched && passwordControl.errors?.required">
        {{'errors.required' | translate}}
      </mat-error>
    </div>
  </div>
</ng-container>
