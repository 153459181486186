// Component: App Details

;(function(angular) {
  'use strict';

  angular.module('C.appsManagement')
    .config(configFn)
    .controller('appDetailsCtrl', AppDetailsCtrlFn)
    .component('appDetails', {
      templateUrl: 'app/apps-management/app-store/app-details/app-details.html',
      controller: 'appDetailsCtrl',
      bindings: {
        /**
         * Page Config
         *
         * @param  pageConfig    routeParams and any other params required for
         *                       component init
         *                       {
         *                         appId: '',
         *                         detailType: '',
         *                       }
         * @type   {object}
         */
        pageConfig: '<',
      },
    });

  function configFn($stateProvider) {
    var viewAccess = 'CLUSTER_VIEW && FEATURE_FLAGS.appsManagementEnabled';
    $stateProvider
    .state('app-details', {
      title: 'App Details',
      url: '^/apps-management/apps/{id}/details/{type}?{getApp}',
      canAccess: viewAccess,
      parentState: 'apps-management.apps',
      allClustersSupport: {
        allClustersState: 'app-details',
        globalContext: true,
      },
      params: {
        id: { type: 'cNum' },

        // Set this param to true in order to open app specific eula modal
        // as soon as When the user is logged in to helios to purchase an
        // application from appstore netizen website.
        getApp: {
          type: 'cBool',
          value: false,
          dynamic: true,
          squash: true,
          _persistOnImpersonation: true,
        },
        type: { type: 'string', dynamic: true },
      },
      component: 'appDetails',
      reloadOnSearch: false,
      resolve: {
        // Transition change won't happen if this view is opened in a modal.
        // Set the params via pageConfig manually in that case.
        pageConfig: function setPageConfig($transition$) {
          var routeParams = $transition$.params();
          return {
            appId: +routeParams.id || undefined,
            detailType: routeParams.type || undefined,
          };
        }
      }
    });
  }

  function AppDetailsCtrlFn($state, AppStoreService, $transitions,
    FEATURE_FLAGS, evalAJAX, cMessage, $window, APPS_CONST) {
    var $ctrl = this;

    _.assign($ctrl, {
      app: {},
      clusterVersions: [],
      devVersionList: undefined,
      isLoading: false,
      selectedClusterVersion: undefined,
      tabConfig: [],

      // Methods
      downloadAppPackage: downloadAppPackage,
      fetchAppDevVersions: fetchAppDevVersions,
      getAppActionText: getAppActionText,
      launchInstallAppModal: launchInstallAppModal,

      // Component life-cycle methods
      $onInit: $onInit,
    });

    /**
     * Initializes the controller and gets details of the specified application.
     *
     * @method   $onInit
     */
    function $onInit() {
      _getAppDetails();
    }

    /**
     * Provides details of the specified application.
     *
     * @method   _getAppDetails
     */
    function _getAppDetails() {
      $ctrl.isLoading = true;
      AppStoreService.getAppDetails($ctrl.pageConfig.appId)
      .then(function gotDetailsSuccess(response) {
        $ctrl.app = response;

        if ($state.params.getApp && !$ctrl.app._isUnavailable) {
          // Set this property to true in order to prevent modal dismiss on state
          // change within modal.
          $ctrl.app._persistOnStateChanges = true;

          // Setting getApp property as null in order to avoid opening the modal
          // on state reload
          $state.go('.',
            {
              id: $ctrl.pageConfig.appId,
              type: $ctrl.pageConfig.detailType,
              getApp: null
            }
          );
          launchInstallAppModal();
        }
        $ctrl.tabConfig = initTabListConfig();

        AppStoreService.getAppSpecificClusterVersions($ctrl.app.appId).then(
          function getClusterVersions(response) {
            $ctrl.clusterVersions = response;
          }
        );
      })
      .finally(function finallyAfterResponse() {
        $ctrl.isLoading = false;
      });
    }

    /**
     * Provides the tab list for configuration of tabs.
     *
     * @method   initTabListConfig
     * @return   {Array}             returns tabs config list
     */
    function initTabListConfig() {
      var tabList = [
        'overview',
        'specification',
        'pricing',
        'licensing',
        'whatsNew',
        'installation',
        'documentation',
        'contactSupport',
        'termsAndConditions',
        'version',
        'reviews',
        'download'
      ];
      var tabConfig = [];

      tabList.map(function getTabConfig(type) {
        tabConfig.push({
          headingKey: type,
          route: 'app-details',
          params: {
            id: $state.params.id,
            type: type
          },
          hidden: isTabHidden(type),
        });
      });
      return tabConfig;
    }

    /**
     * Download latest app package.
     *
     * @method downloadAppPackage
     * @param {Interger} appId App id.
     */
    function downloadAppPackage(versionId) {
      /*
      * ENG-247800
      * Disable download for Reporting app
      * */
      if($ctrl.app.appName === 'Reporting'){
        cMessage.info({
          titleKey: 'apps.downloadDisabled.title',
          textKey: 'apps.contactCohesityReporting',
        });
        return;
      }

      var appInfo = {
        appId: $ctrl.app.appId,
        versionId: versionId,
        appState: $ctrl.app.appState,
      }
      return AppStoreService.downloadAppPackage(appInfo).then(
        function downloadSuccess() {
          cMessage.success({
            titleKey: 'apps.downloadAppPackageSuccess.title',
          });
        }, evalAJAX.errorMessage
      );
    }

    /**
     * Provides information to show show or hide tab based on app detail type
     *
     * @method   isTabHidden
     * @param    {string} type   provides app detail type information
     * @return   {boolean}       returns boolean based on detail type whether to
     *                           hide or show tab
     */
    function isTabHidden(type) {
      switch(type) {
        case 'reviews':
          return !(FEATURE_FLAGS.appsManagementReviewRatingsEnabled &&
            $ctrl.app.appState === 'kInstalled');

        case 'whatsNew':
          return !($ctrl.app && $ctrl.app.applicationDetails.whatsNew);

        case 'documentation':
          return !($ctrl.app && $ctrl.app.applicationDetails.documentationUrl);

        case 'download':
          return $ctrl.app._isUnavailable || ($ctrl.app.applicationDetails.appType === 'integration') ;

        default:
          return false;
      }
    }

    // Update app detail type based on state change, without reloading the
    // entire controller
    $transitions.onStart({}, function startTransitionFn(trans) {
      var routeParams = trans.params();
      $ctrl.pageConfig = {
        appId: +routeParams.id || undefined,
        detailType: routeParams.type || undefined,
      };
    })

    /**
     * launches helios user agreement modal to get app
     *
     * @method   launchInstallAppModal
     */
    function launchInstallAppModal() {
      if(APPS_CONST.secCenterIntegratedAppNames.includes($ctrl.app.appName)) {
        $window.open(`/datahawk/security/integration/${$ctrl.app.appId}/details/overview`, '_blank').focus();
      } else {
        _.get($ctrl.app, 'applicationDetails.appType') === 'integration' ?
          $window.open($ctrl.app.applicationDetails.appUrl, '_blank').focus() :
          AppStoreService.launchInstallAppModal($ctrl.app);
      }
    }

    /**
     * Fetches list of app dev versions.
     */
    function fetchAppDevVersions() {
      var params = {
        appId: $ctrl.app.appId,
        clusterVersion: $ctrl.selectedClusterVersion,
      };

      AppStoreService.getAppDevVersion(params).then(
        function getDevVersions(response) {
          $ctrl.devVersionList = _.sortBy(response, 'versionId');
        }
      );
    }

    /**
     * get app button text
     *
     * @method   getAppActionText
     * @return   {string}       returns 'integration' or appstate
     *                            for container apps
     */
    function getAppActionText() {
      return AppStoreService.getAppActionText($ctrl.app);
    }
  }

})(angular);
