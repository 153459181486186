<button
  #organizationPopOver
  cogDataId="select-organization"
  class="organization-switch padding-right-sm"
  mat-button
  [cogPopover]="popup"
  #cogPopover="cogPopover">
  <div class="tenant-switch-btn margin-left-sm">
    <cog-icon size="sm"
      [shape]="selectedOrganization?.id === 0 ? 'all_inclusive' : 'helix:object-region'">
    </cog-icon>
    <span class="margin-left-sm ellipsis">{{ selectedOrganization?.name }}</span>
    <cog-icon class="margin-left" size="md" shape="arrow_drop_down"></cog-icon>
  </div>
</button>

<ng-template #popup>
  <div class="switcher-wrap">
    <h3 class="title no-margin-bottom">{{ 'switchToOrganization' | translate }}</h3>
    <p class="cog-caption margin-top-sm">
      {{ 'helios.tenants.organizationsYouHaveAccessTo' | translate }}
    </p>

    <!--Search Field-->
    <div class="search-input-wrap"
      [class.expanded]="expanded"
      (mouseenter)="hovered = true"
      (mouseleave)="hovered = false; null">

      <div class="input-search-field">
        <mat-icon [attr.aria-label]="search" matSuffix>search</mat-icon>
        <mat-form-field>
          <input
            matInput
            type="text"
            cogDataId="input-search-term"
            [formControl]="orgSearchCtrl"
            placeholder="{{ 'placeholderSearch' | translate }}"
            (focus)="focused = true"
            (blur)="focused = false; null">
        </mat-form-field>
      </div>
    </div>

    <!--Organization List-->
    <mat-list cogDataId="select-organizations" *ngIf="!loading; else spinner">
      <mat-list-item cogDataId="all-organizations" (click)="onAllOrganizationSelect()">
        <cog-icon matListIcon [shape]="'all_inclusive'"> </cog-icon>
        <div class="margin-left-sm">
          <div matLine>{{ allOrganizations.name }}</div>
          <ul class="c-ul-inline meta-data synopsis">
            <li> {{ 'as' | translate }} {{ allOrganizations.role }}</li>
          </ul>
        </div>
        <mat-divider></mat-divider>
      </mat-list-item>

      <mat-list-item
        [cogDataId]="organization?.name"
        *ngFor="let organization of filteredOrganizations"
        (click)="onOrganizationSelect(organization)">
        <cog-icon mat-list-icon [shape]="'helix:object-helios-organization'"> </cog-icon>
        <div class="margin-left-sm">
          <div matLine>
            <div class="ellipsis"
              matTooltip="{{ organization.name }}">{{ organization.name }}</div>
          </div>
          <ul class="c-ul-inline meta-data synopsis">
            <li> {{ 'as' | translate }} {{ organization.roleLabel}}</li>
          </ul>
        </div>
        <mat-divider *ngIf="!last"></mat-divider>
      </mat-list-item>
    </mat-list>
  </div>
  <ng-template #spinner>
    <cog-spinner class="margin-top-lg"></cog-spinner>
  </ng-template>
</ng-template>
