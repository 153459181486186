import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeWhile, tap } from 'rxjs/operators';

import { TenantAccess, TenantAccessResult, TenantServiceApi, UserServiceApi } from '@cohesity/api/v2';
import {
  flagEnabled,
  IrisContext,
  IrisContextService,
  isDmsOnlyUser,
  isHeliosTenantUser,
  isImpersonated,
  isLoggedIn,
  isMcm
} from '@cohesity/iris-core';
import { AjaxHandlerService } from '@cohesity/utils';

/**
 * Organizations service for getting organizations info.
 */
@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {
  /**
   * Behavior Subject for checking enabled state of organization feature
   */
  private _isOrganizationEnabledSubject = new BehaviorSubject<boolean>(false);

  /**
   * Behaviour Subject for tenant access organizations
   */
  private organizations = new BehaviorSubject<TenantAccessResult>({});

  /**
   * Observable to consume the isOrganizationEnbaled subject from outside
   */
  isOrganizationEnabled$ = this._isOrganizationEnabledSubject.asObservable();

  /**
   * Provides quick acccess to the current value for isOrganizationEnabled so
   * the subscription can be skipped when not needed.
   */
  get isOrganizationsEnabled(): boolean {
    return this._isOrganizationEnabledSubject.value;
  }

  /**
   * Observable to consume the organizations subject from outside
   */
  organizations$ = this.organizations.asObservable();

  private irisCtx: IrisContextService;

  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private heliosUsersService: UserServiceApi,
    private organizationsService: TenantServiceApi,
  ) {
  }

  /**
   * Initialize the organization service with an instance of irisContextService. We need the context to determine
   * whether to make the calls to check organization status, but if we inject it in the constructor it will create
   * a circular dependency error. Instead, let irisContextService itself call this method.
   *
   * @param irisCtx The IrisContextService.
   */
  init(irisCtx: IrisContextService) {
    this.irisCtx = irisCtx;
    this.initAppOrgState();
  }

  /**
   * Exclusively set the isOrganizationEnabled staten
   *
   * @param state The state to be set
   */
  setOrganizationsEnabledState(state: boolean) {
    this._isOrganizationEnabledSubject.next(state);
  }

  /**
   * Fetch tenant access belonging to current user. Subscribe to
   * {@link organizations} for the values
   */
  updateLoggedInUserTenantAccesses() {
    this.fetchOrganizationsAccess().subscribe();
  }

  /**
   * Initialize the application state w.r.t organization.
   * This creates a stream to observe application events and interacts
   * with backend to fetch the latest org enabled status. Subscribe to
   * {@link organization$} for latest status and {@link organizations} for
   * latest tenant acess values.
   *
   * This ideally will be needed to initiated once during bootstrap.
   * This does not follow the general standard of "Never Subscribe" in services.
   */
  initAppOrgState() {
    this.irisCtx.irisContext$.pipe(
      // Add observer on flag enabled field, this will be effective when
      // ff is turned on/off. This wont be effective in production though.
      filter<IrisContext>(ctx => flagEnabled(ctx, 'heliosMTEnabled')),

      // The next 2 operators ensure that we're emitting values further into the stream
      // only when the loggedIn event for the user has changed from false to true and the current
      // loggedIn status is true.
      // This was put up as opposed to earlier implementation where the stream was taking first
      // satisfying value because of Helios_OnPrem use case. User does not get 'Server Redirect' post login
      // as opposed to Helios SaaS.
      distinctUntilChanged((prevLoggedIn, currLoggedIn) => prevLoggedIn === currLoggedIn, isLoggedIn),
      filter(ctx => isLoggedIn(ctx)),

      // This is minor optimization for Non MCM use cases. This could
      // have been a static check in {@link init} method but referencing to IrisContext
      // values in init thread of OrgService will cause CIRCULAR DEPENDENCY error.
      // Also, this check cannot be the first level of defence in pipe because
      // feature flag loads first then basicClusterInfo, so subscription will always
      // complete before its logical lifecycle. By this time, basicClusterInfo is definitely
      // loaded.
      takeWhile(ctx => isMcm(ctx)),

      // Ensure that irrelevant user contexts are filtered out
      filter(ctx => (!isHeliosTenantUser(ctx) || isImpersonated(ctx))  && !isDmsOnlyUser(ctx)),
      switchMap(
        () => this.organizationsService.GetAccountTenantConfig().pipe(
          map((tenantConfig) => tenantConfig.organizationsEnabled)
        )
      ),
      tap((isOrgEnabled) => this.setOrganizationsEnabledState(isOrgEnabled)),
      filter(isOrgEnabled => isOrgEnabled),
      switchMap(() => this.fetchOrganizationsAccess())
    ).subscribe();
  }

  /**
   * Function to fetch Tenant Access Organizations
   */
  fetchOrganizationsAccess(): Observable<TenantAccessResult> {
    const params: UserServiceApi.GetTenantAccessParams = {
      tenantIds: []
    };

    return this.heliosUsersService.GetTenantAccess(params).pipe(
      this.ajaxHandlerService.catchAndHandleError({ tenantAccesses: [] }),
      tap((response) => this.organizations.next(response))
    );
  }

  /**
   * This method finds access info of the logged in user in a particular cluster (clsuterId)
   * assigned to a tenant or organization (orgId). If the current user does not have
   * access to the cluster within the org, this method will return null.
   *
   * @param orgId tenant to look into
   * @param clusterId cluster within tenant (orgId) to check access
   * @returns tenant access information if user has access to the cluster
   * (clusterId) within the org (orgId).
   */
  findAccessInfo(orgId: string, clusterId: number): TenantAccess {
    const orgTenantAccessInfo = this.organizations.getValue() || {};

    const tenantAccesses = orgTenantAccessInfo.tenantAccesses || [];

    return tenantAccesses.find(
      (tenantAccessInfo) => {
        const accessTenantId = tenantAccessInfo?.tenantId;

        // match tenant id
        if (orgId !== accessTenantId) {
          return false;
        }

        const accessClusters = tenantAccessInfo?.clusters ?? [];
        // find cluster in the matched tenant id
        return accessClusters?.findIndex(
          (accessCluster) => accessCluster.clusterId === clusterId
        ) !== -1;
      }
    );
  }
}
