<div class="details-container">
  <div class="vips-details flex-row flex-row-sm flex-equal-division flex-direction-column@xs">
    <div class="flex-column">
      <span class="zone-name auto-truncate" matTooltip="{{ model.dnsZoneName }}">
        {{ (model.dnsZoneName ? model.dnsZoneName : 'naNotApplicable') | translate }}
      </span>
      <label class="mat-caption">{{ 'zoneName' | translate }}</label>
    </div>
    <div class="flex-column">
      <div [ngSwitch]="model.dnsZoneVips.length" class="detail">
        <span *ngSwitchCase="0">{{ 'naNotApplicable' | translate }}</span>
        <span *ngSwitchCase="1">{{ model.dnsZoneVips[0] }}</span>
        <span *ngSwitchDefault>
          {{ model.dnsZoneVips[0] }}
          <span class="more-icon" [cogPopover]="zoneServerVips">
            +{{ (model.dnsZoneVips.length - 1) }}
          </span>
        </span>
      </div>
      <label class="mat-caption">{{ 'zoneServerVips' | translate }}</label>
    </div>
    <div class="flex-column">
      <div [ngSwitch]="model?.dnsZoneResolvedVips?.length || 0" class="detail">
        <span *ngSwitchCase="0">{{ 'naNotApplicable' | translate }}</span>
        <span *ngSwitchCase="1">{{ model.dnsZoneResolvedVips[0] }}</span>
        <span *ngSwitchDefault>
          {{ model.dnsZoneResolvedVips[0] }}
          <span class="more-icon" [cogPopover]="resolvedVips">
            +{{ (model.dnsZoneResolvedVips.length - 1) }}
          </span>
        </span>
      </div>
      <label class="mat-caption">{{ 'resolvedVips' | translate }}</label>
    </div>
    <div class="flex-row actions">
      <button mat-icon-button
        type="button"
        (click)="edit.emit(index)"
        [matTooltip]="'edit' | translate">
        <cog-icon shape="edit!outline"></cog-icon>
      </button>
      <button mat-icon-button
        type="button"
        (click)="remove.emit(index)"
        [matTooltip]="'delete' | translate">
        <cog-icon shape="delete_outline"></cog-icon>
      </button>
    </div>
  </div>
</div>
<mat-divider></mat-divider>

<ng-template #zoneServerVips>
  <div class="flex-column">
    <h5>{{ 'zoneServerVips' | translate }}</h5>
    <div *ngFor="let vip of model.dnsZoneVips">
      <span>{{ vip }}</span>
    </div>
  </div>
</ng-template>

<ng-template #resolvedVips>
  <div class="flex-column">
    <h5>{{ 'resolvedVips' | translate }}</h5>
    <div *ngFor="let vip of model.dnsZoneResolvedVips">
      <span>{{ vip }}</span>
    </div>
  </div>
</ng-template>
