import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { RegisteredSource } from '@cohesity/api/private';
import { DataIdModule } from '@cohesity/helix';
import { CohesitySharedFormsModule } from '@cohesity/shared-forms';
import { allUnicodeWordCharacters, AutoDestroyable } from '@cohesity/utils';
import { TranslateModule } from '@ngx-translate/core';
import { AddEditStepComponent } from '../../interfaces/add-edit-step';
import { CleanRoomConfigurationService } from '../../services/clean-room-configuration/clean-room-configuration.service';

/**
 * RegExp to validate the tag name
 */
const cleanRoomNameRegExp = new RegExp('^[' + allUnicodeWordCharacters + '\\w-.:\\s]*$');

/**
 * source selection modes
 */
enum SourceSelectionMode {
  newSource = 'new-source',
  existingSource = 'existing-source',
}

@Component({
  selector: 'dg-sc-clean-room-source-selection-form',
  standalone: true,
  imports: [
    CohesitySharedFormsModule,
    CommonModule,
    DataIdModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatLegacyRadioModule,
    MatLegacySelectModule,
    TranslateModule,
  ],
  templateUrl: './source-selection-form.component.html',
  styleUrls: ['./source-selection-form.component.scss'],
})
export class SourceSelectionFormComponent extends AutoDestroyable implements AddEditStepComponent {
  /**
   * form group for inputs.
   */
  readonly formGroup = this.formBuilder.group({
    sourceModeCtrl: new FormControl({
      value: SourceSelectionMode.existingSource,
      disabled: false,
    }, [
      Validators.required,
    ]),
    cleanRoomNameCtrl: new FormControl({
      value: '',
      disabled: false,
    }, [
      Validators.required,
      Validators.maxLength(32),
      Validators.pattern(cleanRoomNameRegExp),
    ]),
    sourceCtrl: new FormControl<RegisteredSource>({
      value: undefined,
      disabled: false,
    }, [
      Validators.required,
    ]),
  });

  /**
   * selection modes for template access
   */
  readonly selectionModes = SourceSelectionMode;

  /**
   * List of existing available sources
   */
  readonly sources$ = this.cleanRoomConfigurationService.availableSources$;

  constructor(
    private cleanRoomConfigurationService: CleanRoomConfigurationService,
    private formBuilder: FormBuilder,
  ) {
    super();

    this.formGroup.valueChanges.pipe(
      this.untilDestroy(),
    ).subscribe((value) => {
      this.cleanRoomConfigurationService.updateData({
        cleanRoomSourceUUID: value.sourceCtrl?.uuid,
        name: value.cleanRoomNameCtrl,
        mappedClusterIds: value.sourceCtrl?.registeredSourceInfoList?.map(
          (info) => `${info.clusterId}:${info.clusterIncarnationId}`
        ) ?? []
      });
    });
  }
}
