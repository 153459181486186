<span class="icon-container" *ngIf="status?.length === 1">
  <button type="button"
    mat-icon-button
    *ngIf="status[0].state"
    [uiSref]="status[0].state"
    [uiParams]="status[0].stateParams"
    [attr.aria-label]="getAriaLabel(status[0])"
    [matTooltip]="getDisplayText(status[0])"
    matTooltipClass="status-icon-multi-line-tooltip"
    matTooltipPosition="above">
    <cog-icon [shape]="status[0] | statusIconMap" [size]="size"></cog-icon>
  </button>
  <ng-container *ngIf="!status[0].state">
    <cog-icon
      [shape]="status[0] | statusIconMap"
      [size]="size"
      [attr.aria-label]="getAriaLabel(status[0])"
      [matTooltip]="getDisplayText(status[0])"
      matTooltipClass="status-icon-multi-line-tooltip"
      matTooltipPosition="above">
    </cog-icon>
    <span class="visuallyhidden">{{getAriaLabel(status[0])}}</span>
  </ng-container>

</span>
<ng-container *ngIf="status?.length > 1">
  <button mat-icon-button
    class="icon-summary-container mat-display-1"
    [matMenuTriggerFor]="menu">
    <cog-icon [shape]="status | statusIconReduce" [size]="size"></cog-icon>
    <div class="icon-summary-plus">+{{ status.length - 1 }}</div>
  </button>
  <mat-menu #menu="matMenu" class="icon-menu-container">
    <button mat-menu-item disabled>
      <span>{{ status.length }} {{ 'icon.type.' + status[0].type | translate }}</span>
    </button>
    <button mat-menu-item
      *ngFor="let item of status"
      [disabled]="!item.state"
      [uiSref]="item.state"
      [uiParams]="item.stateParams"
      [matTooltip]="item.status | translate"
      matTooltipPosition="above">
      <cog-icon [shape]="item | statusIconMap"></cog-icon>
      <span class="icon-menu-item-text">{{ item.name }}</span>
    </button>
  </mat-menu>
</ng-container>
