import { Component, HostListener, Input, ViewEncapsulation } from '@angular/core';

/**
 * Component to show items as a list with "x more" suffix which expands when clicked.
 *
 * @example
 * <cog-item-list items="items"></cog-item-list>
 */
@Component({
  selector: 'cog-item-list',
  styleUrls: ['./item-list.component.scss'],
  templateUrl: './item-list.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ItemListComponent {
  /**
   * List of items to be displayed
   */
  @Input() items: string[];

  /**
   * What kind of item is it - display key like "externalTargets" which will be
   * translated.
   */
  @Input() itemType: string;

  /**
   * Suffix to display after the item list. Ex: 5 'more'
   */
  @Input() suffix: string;

  /**
   * Prevents triggering outside mouse events when this component is clicked.
   */
  @HostListener('click', ['$event']) stopEventPropagation = (e: MouseEvent) => e.stopPropagation();
}
