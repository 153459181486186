;(function(angular, undefined) {
  'use strict';

  angular.module('C.reportsControlsConstants', [])

  .constant('REPORT_TYPES', {
    kAgentDeploymentReport: 'reportsControls.names.agentStatusSummary',
    kAvailableLocalSnapshotsReport: 'reportsControls.names.localSnapshots',
    kBackupSummaryReport: 'reportsControls.names.backupJobsSummary',
    kDataTransferredToExternalTargetsReport:
      'reportsControls.names.dataTransferredToExternalTargets',
    kFailedObjectsReport: 'reportsControls.names.failedObjects',
    kGdprReport: 'reportsControls.names.gdprObjectsSummary',
    kObjectsByMultipleProtectionGroupsReport: 'reportsControls.names.vmsInMultipleJobs',
    kProtectedObjectsTrendsReport:
      'reportsControls.names.protectedObjectsHeatmap',
    kProtectionDetailsPerObjectReport:
      'reportsControls.names.protectionDetails',
    kProtectionJobsInventoryAndScheduleReport:
      'reportsControls.names.jobInventory',
    kProtectionRunsSummaryReport: 'reportsControls.names.protectionRunsSummary',
    kProtectionSummaryByObjectTypeReport:
      'reportsControls.names.protectionSummary',
    kSourceGrowthAndVarianceReport: 'reportsControls.names.storageGrowthReport',
    kStorageConsumedByBackupReport: 'reportsControls.names.storageJobReport',
    kStorageConsumedByFileCategoriesReport:
      'reportsControls.names.filesCategoryReport',
    kStorageConsumedByServersReport: 'reportsControls.names.storageVmReport',
    kStorageConsumedByTenantsReport:
      'reportsControls.names.storageTenantsReport',
    kStorageConsumedByTenantPerViewBoxReport:
      'reportsControls.names.storageTenantsReport',
    kStorageConsumedByViewBoxReport:
      'reportsControls.names.storageViewboxesReport',
    kUnprotectedVMsReport: 'reportsControls.names.vmsByProtection',
    kUserQuotasReport: 'reportsControls.names.userQuotas',
  })

  .constant('REPORTS_SELECTOR_OPTIONS', [
    {
      value: 'reports.jobs',
      name: 'reportsControls.names.backupJobsSummary',
      type: 'kBackupSummaryReport',
    }, {
      value: 'reports.top-jobs',
      name: 'reportsControls.names.topJobsReport',
    }, {
      value: 'reports.restore',
      name: 'reportsControls.names.restoreJobsSummary',
      type: 'kRecoverSummaryReport',
    }, {
      value: 'reports.sources',
      name: 'reportsControls.names.sourcesProtectedReport',
    }, {
      value: 'reports.vms-by-protection',
      name: 'reportsControls.names.vmsByProtection',
      type: 'kUnprotectedVMsReport',
    }, {
      value: 'reports.storage',
      name: 'reportsControls.names.storageClusterReport',
      isClusterSpecific: true,
    }, {
      value: 'reports.storage-job',
      name: 'reportsControls.names.storageJobReport',
      type: 'kStorageConsumedByBackupReport',
    }, {
      value: 'reports.storage-vm',
      name: 'reportsControls.names.storageVmReport',
      type: 'kStorageConsumedByServersReport',
    }, {
      value: 'reports.storage-viewboxes',
      name: 'reportsControls.names.storageViewboxesReport',
      type: 'kStorageConsumedByViewBoxReport',
    }, {
      value: 'reports.storage-growth',
      name: 'reportsControls.names.storageGrowthReport',
      type: 'kSourceGrowthAndVarianceReport',
    }, {
      value: 'reports.files-category',
      name: 'reportsControls.names.filesCategoryReport',
      type: 'kStorageConsumedByFileCategoriesReport',
    }, {
      value: 'reports.jobs-vms',
      name: 'reportsControls.names.vmsInMultipleJobs',
      type: 'kObjectsProtectedByMultipleGroupsReport',
    }, {
      value: 'reports.jobs-inventory',
      name: 'reportsControls.names.jobInventory',
      type: 'kProtectionJobsInventoryAndScheduleReport',
    }, {
      value: 'reports.available-local-snapshots',
      name: 'reportsControls.names.localSnapshots',
      type: 'kAvailableLocalSnapshotsReport',
    }, {
      value: 'reports.protection-details',
      name: 'reportsControls.names.protectionDetails',
      type: 'kProtectionDetailsPerObjectReport',
    }, {
      value: 'reports.failed-objects',
      name: 'reportsControls.names.failedObjects',
      type: 'kFailedObjectsReport',
    }, {
      value: 'reports.protection-summary',
      name: 'reportsControls.names.protectionSummary',
      type: 'kProtectionSummaryByObjectTypeReport',
    }, {
      value: 'reports.data-transferred-to-external-targets',
      name: 'reportsControls.names.dataTransferredToExternalTargets',
      type: 'kDataTransferredToExternalTargetsReport',
      isClusterSpecific: true,
    }, {
      value: 'reports.archival-summary',
      name: 'reportsControls.names.archivalSummary',
      type: 'kArchivalSummaryReport',
    }
  ]);

})(angular);
