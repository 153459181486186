// Reports: Data Transferred to External Targets

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.reports')
    .controller('reportsDataTransferredToExternalTargetsController',
      reportsDataTransferredToExternalTargetsControllerFn);

  function reportsDataTransferredToExternalTargetsControllerFn($q, $scope,
    ReportsService, DataTransferredToTargetsUtil, evalAJAX,
    cReportsControlsUtil, ReportsUtil, moment, ExternalTargetService,
    PolicyService) {

    var dataTransferredVm = this;
    var targetsPromise = ExternalTargetService.getTargets();
    var targets;
    var policiesPromise = PolicyService.getNGPolicies();
    var policies;

    dataTransferredVm.getExternalTargetsObjects = getExternalTargetsObjects;
    dataTransferredVm.downloadDataTransferredToExternalTargetsReport =
      downloadDataTransferredToExternalTargetsReport;

    dataTransferredVm.dataTransferredToExternalTargetsControlsConfig =
      ReportsUtil.getReportConfig('kDataTransferredToExternalTargetsReport')();

    dataTransferredVm.externalTargetsObjects = [];
    dataTransferredVm.goToProtectionJob = goToProtectionJob;

    /**
     * Loads necessary data for controller
     */
    function activate() {
      var vaultIds = [];
      var defaultFilters;

      dataTransferredVm.dataLoading = true;

      $q.all(dataTransferredVm.dataTransferredToExternalTargetsControlsConfig.datasetPromiseObject)
        .then(function getDataset(dataset) {
          dataTransferredVm.dataset = dataset;

          vaultIds = dataset.externalTargets.map(function mapTargets(target) {
            return target.id;
          });

          defaultFilters = cReportsControlsUtil.getDefaultFilters({
            timeObject: {
              from: moment().subtract(1, 'months'),
              until: moment().endOf('day'),
            },
            vaultIds: vaultIds,
          });

          getExternalTargetsObjects(defaultFilters);
        }, evalAJAX.errorMessage)
      .finally(function getTargetsFinally() {
        dataTransferredVm.reportsControlsDataReady = true;
      });

      targetsPromise.then((resp) => targets = resp);
      policiesPromise.then((resp) => policies = resp);
    }

    /**
     * returns params object for api call
     *
     * @param      {object}    filters    filters from c-reports-controls
     *
     * @return    {object}               filter values to be passed to API
     */
    function getParams(filters) {
      return {
        vaultIds: filters.vaultIds,
        // convert timestamps to milliseconds
        startTimeMsecs: filters.timeObject.from.valueOf(),
        endTimeMsecs: filters.timeObject.until.valueOf(),
      };
    }

    /**
     * gets data needed for controller and template calls getSummaryStats()
     * to aggregate data
     *
     * @param    {object}    filters    values to filter report data
     */
    function getExternalTargetsObjects(filters) {
      dataTransferredVm.dataLoading = true;
      dataTransferredVm.externalTargetsObjects.length = 0;

      // Expose filters on scope for ui.json string consumption.
      dataTransferredVm.filters = filters;

      ReportsService.getDataTransferredToVaults(getParams(filters))
        .then(function getExternalObjects(data) {
          dataTransferredVm.externalTargetsObjects = data;

          getSummaryStats(data);
        }, evalAJAX.errorMessage)
        .finally(function getExternalObjectsFinally() {
          dataTransferredVm.dataLoading = false;
        });
    }

    /**
     * calls util methods that will aggregate data to be displayed in
     * the template
     *
     * @param    {array}    targets    array of externalTargetsObjects that
     *                                 contains data to be aggregated
     */
    function getSummaryStats(targets) {
      dataTransferredVm.summaryStats =
        DataTransferredToTargetsUtil.calculateSummaryStats(targets);
    }

    /**
     * this methods fakes a hyperlink functionality between both tabs in the
     * report. using the vault id we find the external target set it as the
     * current filter and call getExternalTargetsObjects to get data associated
     * with that vault. then we set the protection job tab as the active tab
     *
     * @param    {number}    vaultId    the id of the vault selected
     */
    function goToProtectionJob(vaultId) {
      var foundTarget =
        dataTransferredVm.dataset.externalTargets.find(
          function findTarget(target) {
            return vaultId === target.id;
        });

      cReportsControlsUtil.updateFilters({
        vaultIds: foundTarget.id,
        selectedTargetNames: foundTarget,
      });

      getExternalTargetsObjects(cReportsControlsUtil.filters);

      dataTransferredVm.activeTabIndex = 1;
    }

    /**
     * Initiates CSV download
     */
    function downloadDataTransferredToExternalTargetsReport() {
      var params = getParams(cReportsControlsUtil.filters);
      params.outputFormat = 'csv';

      // Pass the groupBy param to download the CSV for the active tab, i.e.
      // By Target or By Protection Job.
      params.groupBy = dataTransferredVm.activeTabIndex === 0 ? 'kTarget'
        : 'kProtectionJob';

      ReportsService.getDataTransferredToVaults(params);
    }

    /**
     * Get the type of an external target based on its id
     *
     * @param {number} targetId The external Target id
     */
    $scope.getTargetType = function getTargetType(targetId) {
      if (targets) {
        return targets.find(target => target.id === targetId)._name;
      }
    }

    /**
     * Check if target is associated with CAv2/CADv2 policy or not.
     *
     * @param {number} targetId The external Target id
     */
    $scope.isTargetInCav2Cadv2Policy = (targetId) => {
      // For CAv2/CADv2 policy, associated target must have isForeverIncrementalEnabled
      // set to true, else policy will not be called as CAv2/CADv2 policy.
      const target = targets.find(target => target.id === targetId);
      const isForeverIncrementalEnabled = target?.isForeverIncrementalArchiveEnabled

      if (isForeverIncrementalEnabled) {
        // Check if target is present in any CADv2 polcies.
        const isCADTarget = policies.some(policy =>
          policy?.backupPolicy?.regular?.primaryBackupTarget?.archivalTargetSettings?.
            targetId === targetId
        )
        // Check if target is present in any CAv2 policies.
        const isCATarget = policies.some(policy => policy?.remoteTargetPolicy?.
          archivalTargets.some(archivalTarget => archivalTarget.targetId === targetId))

        return isCADTarget || isCATarget ;
      }
      return false;
    }

    activate();
  }
})(angular);
