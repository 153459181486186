// COMPONENT: Recover NAS: Search step

;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.restoreNas';

  angular
    .module(moduleName)
    .controller('nasRestoreSearchController', nasRestoreSearchControllerFn);

  function nasRestoreSearchControllerFn($rootScope,
    $scope, DateTimeService, SearchService, RestoreService,
    evalAJAX) {

    /**
     * Default list of Filters for use in the cSearchFilters.
     *
     * @example
      {
        property: {String},
        display: {String|Fn=},
        primary: {Bool=},
        locked: {Bool=},
        transformFn: {Fn=},
        value: {Array|Integer|String|Bool=}
      }
     * @type       {Array}
     */
    var defaultFilterOptions = [];

    angular.extend($scope, {
      // GENERAL SCOPE VARS
      contextActions: {},

      // SCOPE METHODS
      preProcessSearchResults: preProcessSearchResults,
      selectSnapshotIndex: selectSnapshotIndex,
    });

    defaultFilterOptions = [{
      property: 'registeredSourceIds',
      display: 'sourceName',
      transformFn: sourceIdFromName,
      locked: false,
    }, {
      property: 'viewBoxIds',
      display: 'viewBox',
      transformFn: viewBoxIdFromName,
      locked: false,
    }, {
      property: 'entityIds',
      display: 'volumes',
      transformFn: entityIdFromName,
      locked: false,
    }, {
      property: 'jobIds',
      display: 'protectionJob',
      transformFn: jobIdFromName,
      locked: false,
    }, {
      property: 'fromTimeUsecs',
      display: 'startDate',
      transformFn: DateTimeService.dateToUsecs,
      locked: false,
    }, {
      property: 'toTimeUsecs',
      display: 'endDate',
      transformFn: DateTimeService.dateToUsecs,
      locked: false,
    }, {
      property: 'vmName',
      primary: true,
    }];


    // WATCHERS
    // Update the contextActions object for the new paged set
    $scope.$watchCollection('shared.pagedResults', buildContextActions);


    // METHODS
    /**
     * Activate this controller
     *
     * @method   activate
     */
    function activate() {
      initSearchConfig();
    }

    /**
     * Builds context actions with the given entities.
     *
     * @method     buildContextActions
     * @param      {Array|object}  entities  One or more Entities
     * @return     {Object}        The updated context actions.
     */
    function buildContextActions(entities) {
      entities = [].concat(entities || []);
      entities.forEach(function eachEntityFn(entity) {
        $scope.contextActions[entity._id] = [
          {
            display: 'selectServer',
            action: function selectEntityAction() {
              $scope.addToCart(entity);
            }
          }
        ];
      });
      return $scope.contextActions;
    }

    /**
     * Pre-process the search results. Uses the SearchService's transform
     * method under the hood + a little more.
     *
     * @method     preProcessSearchResults
     * @param      {object}  results  The server's raw response object
     * @return     {array}   The results of the SearchService's transformer
     */
    function preProcessSearchResults(results) {
      var resp = SearchService.transformNasResults(results);
      if (!resp.length) {
        return [{
          isEmpty: true
        }];
      }
      return resp;
    }

    /**
     * Transform a single, or an array of Source names to Source IDs
     *
     * @method     sourceIdFromName
     * @param      {object|array}  names   Array Source names
     * @return     {array}         Array of Source IDs
     */
    function sourceIdFromName(names) {
      var out = [];
      if (names) {
        names = [].concat(names);
        return $scope.shared.filterLookups.registeredSourceIds
          .reduce(function matchSources(sources, source) {
            if (names.includes(source.entity.vmwareEntity.name)) {
              sources.push(source.entity.id);
            }
            return sources;
          }, []);
      }
      return out;
    }

    /**
     * Transforms an entityType filter object to its filter key
     *
     * @method     transformEntityTypeFilter
     * @param      {Object}  selections  selected Entity type filters
     * @return     {String}  The filter key
     */
    function transformEntityTypeFilter(selections) {
      var out = [];
      if (selections) {
        selections = [].concat(selections);
        selections.forEach(function getEntityTypeKeys(entityTypeFilter) {
          out.push(entityTypeFilter.value);
        });
      }
      return out;
    }

    /**
     * TransformFn for viewBox search filter
     *
     * @method     viewBoxIdFromName
     * @param      {object|array}  viewBoxes  The viewBox names to get the
     *                                        IDs for
     * @return     {array}         The viewBox ids
     */
    function viewBoxIdFromName(viewBoxes) {
      var out = [];
      if (viewBoxes) {
        viewBoxes = [].concat(viewBoxes);
        return $scope.shared.filterLookups.viewBoxIds
          .reduce(function matchViewboxes(boxes, vb) {
            if (!!~viewBoxes.indexOf(vb.name)) {
              boxes.push(vb.id);
            }
            return boxes;
          }, []);
      }
      return out;
    }

    /**
     * Transforms a list of entity names to a list of ids for matching entities.
     *
     * @method   entityIdFromName
     * @param    {array|object}   names   Single, or array of entity names
     * @return   {array}                  The list of ids, if any match
     */
    function entityIdFromName(names) {
      names = (!!names) ? [].concat(names) : [];
      return $scope.shared.filterLookups.entityIds
        .reduce(function matchEntities(ids, entity) {
          if (names.includes(entity[entity._entityKey].name)) {
            ids.push(entity.id);
          }
          return ids;
        }, []);
    }

    /**
     * Transform an array of Job names to Job IDs
     *
     * @method     jobIdFromName
     * @param      {Array}  names   Array of Job names
     * @return     {Array}          Array of Job IDs
     */
    function jobIdFromName(names) {
      var out = [];
      if (names) {
        names = [].concat(names);
        return $scope.shared.filterLookups.jobIds
          .reduce(function matchJobs(jobs, job) {
            if (!!~names.indexOf(job.jobName)) {
              jobs.push(job.jobId);
            }
            return jobs;
          }, []);
      }
      return out;
    }

    /**
     * Find the index of the selected snapshot and set it on the
     * row._snapshotIndex
     *
     * @method     selectSnapshotIndex
     * @param      {Object}   row        Row entity
     * @param      {Integer}  timestamp  Usecs timestamp
     */
    function selectSnapshotIndex(row, timestamp) {
      // Since we don't have access to the index of the selected item,
      // we'll find it and set row._snapshotIndex here.
      row._versions.some(function findSnapshotFn(ver, ii) {
        if (timestamp === ver.instanceId.jobStartTimeUsecs) {
          // Set the index
          row._snapshotIndex = ii;
          return true;
        }
        return false;
      });
      // Clear the tmp var
      $scope.tmpSnapshotUsecs = undefined;
    }

    /**
     * Init some search properties.
     *
     * @method     initSearchConfig
     */
    function initSearchConfig() {
      $scope.shared.filters = angular.copy(defaultFilterOptions);
    }


    activate();
  }

})(angular);
