/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityKeplerApiConfiguration as __Configuration } from '../cohesity-kepler-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DataPoolsResponse } from '../models/data-pools-response';
import { DataPool } from '../models/data-pool';
import { CreateDataPoolRequest } from '../models/create-data-pool-request';
import { UpdateDataPoolRequest } from '../models/update-data-pool-request';
@Injectable({
  providedIn: 'root',
})
class DataPoolsService extends __BaseService {
  static readonly GetDataPoolsPath = '/site-continuity/data-pools';
  static readonly CreateDataPoolPath = '/site-continuity/data-pools';
  static readonly GetDataPoolByIdPath = '/site-continuity/data-pools/{id}';
  static readonly UpdateDataPoolByIdPath = '/site-continuity/data-pools/{id}';
  static readonly DeleteDataPoolByIdPath = '/site-continuity/data-pools/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a list of data pools
   *
   * Returns a list of data pools based on the defined filter criteria.
   * @param params The `DataPoolsService.GetDataPoolsParams` containing the following parameters:
   *
   * - `siteIds`: Filter Data pools by site ids.
   *
   * - `returnSummaryOnly`: If true, list of data pools will not be returned. Only the summary information will be returned.
   *
   * - `includeDrInfo`: If this parameter is sent as true, the response will also include DR related information. This applies to the DR related information at the individual data pools level, as well as at the summary level.
   *
   * - `ids`: Filter Data pools by Id.
   *
   * - `dataTypes`:
   *
   * @return Specifies the Response of a successful get data pools operation.
   */
  GetDataPoolsResponse(params: DataPoolsService.GetDataPoolsParams): __Observable<__StrictHttpResponse<DataPoolsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.siteIds || []).forEach(val => {if (val != null) __params = __params.append('siteIds', val.toString())});
    if (params.returnSummaryOnly != null) __params = __params.set('returnSummaryOnly', params.returnSummaryOnly.toString());
    if (params.includeDrInfo != null) __params = __params.set('includeDrInfo', params.includeDrInfo.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.dataTypes || []).forEach(val => {if (val != null) __params = __params.append('dataTypes', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/site-continuity/data-pools`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DataPoolsResponse>;
      })
    );
  }
  /**
   * Get a list of data pools
   *
   * Returns a list of data pools based on the defined filter criteria.
   * @param params The `DataPoolsService.GetDataPoolsParams` containing the following parameters:
   *
   * - `siteIds`: Filter Data pools by site ids.
   *
   * - `returnSummaryOnly`: If true, list of data pools will not be returned. Only the summary information will be returned.
   *
   * - `includeDrInfo`: If this parameter is sent as true, the response will also include DR related information. This applies to the DR related information at the individual data pools level, as well as at the summary level.
   *
   * - `ids`: Filter Data pools by Id.
   *
   * - `dataTypes`:
   *
   * @return Specifies the Response of a successful get data pools operation.
   */
  GetDataPools(params: DataPoolsService.GetDataPoolsParams): __Observable<DataPoolsResponse> {
    return this.GetDataPoolsResponse(params).pipe(
      __map(_r => _r.body as DataPoolsResponse)
    );
  }

  /**
   * Create a Data pool
   *
   * Creates a new Data pool using the specified data sets.
   * @param body Specifies the parameters to create a data pool.
   * @return Specifies the response of a successful data pool creation.
   */
  CreateDataPoolResponse(body: CreateDataPoolRequest): __Observable<__StrictHttpResponse<DataPool>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/site-continuity/data-pools`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DataPool>;
      })
    );
  }
  /**
   * Create a Data pool
   *
   * Creates a new Data pool using the specified data sets.
   * @param body Specifies the parameters to create a data pool.
   * @return Specifies the response of a successful data pool creation.
   */
  CreateDataPool(body: CreateDataPoolRequest): __Observable<DataPool> {
    return this.CreateDataPoolResponse(body).pipe(
      __map(_r => _r.body as DataPool)
    );
  }

  /**
   * Get a data pool by ID
   *
   * Gets a data pool's information using it's ID.
   * @param params The `DataPoolsService.GetDataPoolByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the data pool.
   *
   * - `includeDrInfo`: The response will include DR related information for the data pool only if this parameter is sent as true.
   *
   * @return Response of a successful get data pool operation.
   */
  GetDataPoolByIdResponse(params: DataPoolsService.GetDataPoolByIdParams): __Observable<__StrictHttpResponse<DataPool>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.includeDrInfo != null) __params = __params.set('includeDrInfo', params.includeDrInfo.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/site-continuity/data-pools/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DataPool>;
      })
    );
  }
  /**
   * Get a data pool by ID
   *
   * Gets a data pool's information using it's ID.
   * @param params The `DataPoolsService.GetDataPoolByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the data pool.
   *
   * - `includeDrInfo`: The response will include DR related information for the data pool only if this parameter is sent as true.
   *
   * @return Response of a successful get data pool operation.
   */
  GetDataPoolById(params: DataPoolsService.GetDataPoolByIdParams): __Observable<DataPool> {
    return this.GetDataPoolByIdResponse(params).pipe(
      __map(_r => _r.body as DataPool)
    );
  }

  /**
   * Update a data pool by ID
   *
   * Updates a data pool's information using it's ID.
   * @param params The `DataPoolsService.UpdateDataPoolByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the data pool.
   *
   * - `body`: The request parameters to update the data pool's information.
   *
   * @return Response of a successful data pool update.
   */
  UpdateDataPoolByIdResponse(params: DataPoolsService.UpdateDataPoolByIdParams): __Observable<__StrictHttpResponse<DataPool>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/site-continuity/data-pools/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DataPool>;
      })
    );
  }
  /**
   * Update a data pool by ID
   *
   * Updates a data pool's information using it's ID.
   * @param params The `DataPoolsService.UpdateDataPoolByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the data pool.
   *
   * - `body`: The request parameters to update the data pool's information.
   *
   * @return Response of a successful data pool update.
   */
  UpdateDataPoolById(params: DataPoolsService.UpdateDataPoolByIdParams): __Observable<DataPool> {
    return this.UpdateDataPoolByIdResponse(params).pipe(
      __map(_r => _r.body as DataPool)
    );
  }

  /**
   * Delete a data pool by ID
   *
   * Deletes a data pool's information using it's ID.
   * @param id Specifies the ID of the data pool.
   */
  DeleteDataPoolByIdResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/site-continuity/data-pools/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a data pool by ID
   *
   * Deletes a data pool's information using it's ID.
   * @param id Specifies the ID of the data pool.
   */
  DeleteDataPoolById(id: number): __Observable<null> {
    return this.DeleteDataPoolByIdResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module DataPoolsService {

  /**
   * Parameters for GetDataPools
   */
  export interface GetDataPoolsParams {

    /**
     * Filter Data pools by site ids.
     */
    siteIds?: Array<string>;

    /**
     * If true, list of data pools will not be returned. Only the summary information will be returned.
     */
    returnSummaryOnly?: boolean;

    /**
     * If this parameter is sent as true, the response will also include DR related information. This applies to the DR related information at the individual data pools level, as well as at the summary level.
     */
    includeDrInfo?: boolean;

    /**
     * Filter Data pools by Id.
     */
    ids?: Array<number>;
    dataTypes?: Array<'vm' | 'view'>;
  }

  /**
   * Parameters for GetDataPoolById
   */
  export interface GetDataPoolByIdParams {

    /**
     * Specifies the ID of the data pool.
     */
    id: number;

    /**
     * The response will include DR related information for the data pool only if this parameter is sent as true.
     */
    includeDrInfo?: boolean;
  }

  /**
   * Parameters for UpdateDataPoolById
   */
  export interface UpdateDataPoolByIdParams {

    /**
     * Specifies the ID of the data pool.
     */
    id: number;

    /**
     * The request parameters to update the data pool's information.
     */
    body: UpdateDataPoolRequest;
  }
}

export { DataPoolsService }
