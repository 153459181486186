/* tslint:disable */

/**
 * Describes the health status of the Data Source.
 */
export enum ScanHealthStatus {
  Accepted = 'Accepted',
  Running = 'Running',
  Canceled = 'Canceled',
  Canceling = 'Canceling',
  Failed = 'Failed',
  Succeeded = 'Succeeded',
  SucceededWithWarning = 'SucceededWithWarning',
  Skipped = 'Skipped'
}
