import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const configureAccess = (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.rpaasEnabled;

export const ActivityConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'rpaasActivity.**',
      url: '/rpaas/activity',
      loadChildren: () => import('./activity.module').then(m => m.ActivityModule),
    },
    {
      name: 'rpaasDetails.**',
      url: '/rpaas/activity-details',
      loadChildren: () => import('./activity.module').then(m => m.ActivityModule),
    },
    {
      name: 'rpaasRecovery.**',
      url: '/rpaas/recovery',
      loadChildren: () => import('./activity.module').then(m => m.ActivityModule),
    },
    {
      name: 'rpaasClone.**',
      url: '/rpaas/clone',
      loadChildren: () => import('./activity.module').then(m => m.ActivityModule),
    },
  ],
  allClusterMap: {
    'rpaasActivity': true,
    'rpaasActivity.recoveries': true,
    'rpaasDetails': true,
    'rpaasDetails.archive': true,
    'rpaasDetails.backup': true,
    'rpaasDetails.cloudvault': true,
    'rpaasRecovery': true,
    'rpaasClone': true,
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'rpaasActivity': configureAccess,
      'rpaasActivity.recoveries': configureAccess,
      'rpaasDetails': configureAccess,
      'rpaasDetails.archive': configureAccess,
      'rpaasDetails.backup': configureAccess,
      'rpaasDetails.cloudvault': configureAccess,
      'rpaasRecovery': configureAccess,
      'rpaasClone': configureAccess,
    };
  },
};
