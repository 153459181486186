<div class="flex-end dialog-header">
  <h2 mat-dialog-title>{{ 'certificateAuthorityDialog.title' | translate }}</h2>
</div>

<mat-dialog-content class="margin-bottom">
  <coh-certificate-authority-interface (selectedCertificates)="selectedCertificates($event)">
  </coh-certificate-authority-interface>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <!-- Cancel button -->
  <button
    mat-button
    mat-dialog-close
    cogCancelButton
    cdkFocusInitial
  >
    {{ 'cancel' | translate }}
  </button>

  <!-- Save button -->
  <button
    mat-button
    cogSubmitButton
    (click)="onSave()"
  >
    {{ 'save' | translate }}
  </button>
</mat-dialog-actions>
