/**
 * Highchart's series colors.
 */
export const HIGHCHART_SERIES_COLOR = {
  red: '#F41A2E',
  primaryBlue: '#1570C8',
  shadedBlue: '#EDF4FB',
  legendColor: '#677075'
};

/**
 * One GB's byte values.
 */
export const ONE_GiB_BYTE_VALUE = 1073741824;
