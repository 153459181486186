<div *ngIf="domainPrincipalsHash">
  <div class="flex-row-md vertical-align"
    *ngFor="let sid of superUserSids; index as i; last as isLast">
    <coh-user-at-domain-picker
      cogDataId="super-user-{{i}}"
      [sid]="sid"
      [principalsHash]="domainPrincipalsHash"
      [domains]="trustedDomains"
      [removeValidator]="removeValidator"
      (sidChange)="onChangeSuperUserSid(i, $event)">
    </coh-user-at-domain-picker>

    <div class="super-users-button-group">
      <button mat-icon-button matSuffix
        type="button"
        cogDataId="remove-super-user-{{i}}"
        (click)="removeSuperUser(i)">
        <cog-icon shape="remove_circle_outline"></cog-icon>
      </button>
      <button mat-icon-button matSuffix
        type="button"
        cogDataId="add-super-user"
        *ngIf="isLast && !!sid && sid !== ''"
        (click)="addSuperUser()">
        <cog-icon shape="add_circle_outline"></cog-icon>
      </button>
    </div>
  </div>
</div>
