import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SearchObjectPerSource } from '../../scan.models';

@Component({
  selector: 'dg-scan-selection-summary',
  templateUrl: './selection-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectionSummaryComponent {
  // List of selected protected objects
  @Input() selectedObjects: SearchObjectPerSource[];
}
