<div class="byte-size-selector">
  <mat-label *ngIf="label">{{label}}</mat-label>
  <div class="bytes-container">
    <!-- Bytes size input -->
    <mat-form-field class="bytes-selector" [class.readonly]="readonly">
      <mat-label *ngIf="useInlineLabel && !label">{{ 'value' | translate }}</mat-label>
      <input
        type="number"
        [formControl]="scaledValue"
        cogDataId="{{id}}-byte-size-input"
        [step]="allowDecimal ? decimalStepValue : stepValue"
        (change)="updateScaledValue($event.target.value)"
        matInput>
    </mat-form-field>

    <!-- Byte size selector -->
    <mat-form-field class="bytes-selector" [class.readonly]="readonly">
      <mat-label *ngIf="useInlineLabel && !label">{{ 'unit' | translate }}</mat-label>
      <mat-select
        [formControl]="scaleFactor"
        cogDataId="{{id}}-unit-selector"
        (selectionChange)="updateScaledValue(scaledValue.value)">
        <mat-option *ngFor="let item of scaleFactors"
          [inheritDataId]="true"
          cogDataId="option-{{item.unit}}"
          [value]="item">
          {{item.label | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="control.invalid">
    <mat-error *ngIf="control.errors?.required">
      {{'errors.required' | translate}}
    </mat-error>
    <mat-error *ngIf="control.errors?.pattern">
      {{'errors.positiveNumber' | translate}}
    </mat-error>
    <mat-error *ngIf="control.errors?.min">
      {{ 'errors.min' | translate: {value: (min | byteSize)} }}
    </mat-error>
    <mat-error *ngIf="control.errors?.max">
      {{ 'errors.maxValue' | translate: {maxValue: (max | byteSize)} }}
    </mat-error>
  </div>
</div>
