<ng-container *ngIf="!element?.activities">
  <cog-statlist [title]="title">
    <cog-statlist-item [label]="'objects' | translate" *ngIf="numObjects !== undefined">
      {{ numObjects | number }}
    </cog-statlist-item>
    <cog-statlist-item [label]="'errors' | translate" labelLegend="critical" *ngIf="numRunsFailed !== undefined">
      {{ numRunsFailed | number }}
    </cog-statlist-item>
    <cog-statlist-item [label]="'missedSlas' | translate" labelLegend="warn" *ngIf="numRunsFailedSla !== undefined">
      {{ numRunsFailedSla | number }}
    </cog-statlist-item>
  </cog-statlist>
  <mat-divider [inset]="true" id="mat-divider-tall" *ngIf="statsByGroup?.length"></mat-divider>
  <cog-statlist *ngIf="statsByGroup?.length">
    <cog-statlist-item *ngFor="let stat of statsByGroup" [label]="stat.id | translate">
      {{ stat.value | number }}
    </cog-statlist-item>
  </cog-statlist>
</ng-container>

<ng-container *ngIf="element?.activities && element.style !== 'disconnected'">
  <h2 class="title margin-bottom-sm user-data-md">{{element.label}}</h2>
  <h4 class="title margin-bottom-sm">{{'activity' | translate}}</h4>
  <cog-statlist valueSize="xs" itemSpacing="grid">
    <a cogStatlistItem
       *ngFor="let stat of element.activities"
       cogDataId="activity-link-{{stat.label}}"
       label="{{ stat.label | translate }}"
       [iconName]="stat.icon"
       iconSize="xs"
       uiSref="rpaas-protection.landing.backup"
       [uiParams]="{
        status: [stat.status],
        vault: element.type === 'vault' ? [element.id] : null,
        cluster: element.type === 'cluster' ? [element.id] : null,
        startTime: startTime,
        endTime: endTime
      }">
      {{ stat.value || 0 }}
    </a>
  </cog-statlist>
</ng-container>

<ng-container *ngIf="element?.activities && element.style === 'disconnected'">
  <h2 class="title margin-bottom-sm user-data-md">{{element.label}}</h2>
  <h4 class="title margin-bottom-sm">{{'disconnected' | translate}}</h4>
  <a cogDataId="cluster-connectivity-anchor"
    class="help-link margin-md"
    href="https://helios-help.cohesity.com/en/articles/5688130-troubleshoot-cloudvault-connectivity"
    target="_blank"
    rel="noopener noreferrer">
    {{'learnMore' | translate}}
  </a>

  <button mat-flat-button
    cogDataId="view-alert-details"
    class="margin-top-sm"
    color="primary"
    type="button"
    uiSref="health.alerts"
    [uiParams]="{clusterIdentifiers: [element.id]}">
    {{'viewAlerts' | translate}}
  </button>
</ng-container>
