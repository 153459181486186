import { Component, Input } from '@angular/core';

/**
 * Data Structure for each treemap legend item
 */
export interface TreemapLegendItem {
  index: number;
  title: string;
  description: string;
}

/**
 * @description
 * This component displays the Treemap Chart Legend.
 *
 * @example
 *   <cog-treemap-legend [data]="legend" [title]="legendTitle"></cog-treemap-legend>
 */
@Component({
  selector: 'cog-treemap-legend',
  templateUrl: './treemap-legend.component.html',
  styleUrls: ['./treemap-legend.component.scss']
})
export class TreemapLegendComponent {
  /**
   * The Treemap legend data for display.
   */
  @Input() data: TreemapLegendItem[];

  /**
   * The Treemap legend title.
   */
  @Input() title: string;
}
