import { DataTreeControl, DataTreeFilter, DataTreeFilterUtils } from '@cohesity/helix';
import { FilterGroup, FilterOption, SourceTreeFilters } from '@cohesity/iris-source-tree';

import { ProtectionSourceDataNode } from './protection-source-data-node';

/**
 * Protection Source Filter Options defines the default filter options for protection source nodes.
 * This includes all objects, protected objects, and unprotected objects.
 */
export class ProtectionSourceFilterOptions<T extends ProtectionSourceDataNode> {
  /**
   * These are the default filter options.
   */
  objectFilterGroup: FilterGroup;

  constructor(private filters: SourceTreeFilters<T>, private treeControl: DataTreeControl<T>) {
    this.objectFilterGroup = {
      label: 'protectionStatus',
      id: 'objectFilter',
      alwaysShow: true,
      options: [
        {
          id: 'unprotected-objects',
          label: 'unprotected',
          filter: this.unprotectedFilter,
        },
        {
          id: 'protected-objects',
          label: 'protectedByCohesity',
          filter: this.protectedFilter,
        },
      ],
    };
    this.filters.addFilterGroup(this.objectFilterGroup);
  }

  /**
   * @param extraFilterOptions Additional filter options to append to default ones
   *
   * Appends extra filter options to default ones and sets them
   */
  addFilterOptions(extraFilterOptions: FilterOption[]) {
    extraFilterOptions.forEach(option => this.filters.addToFilterGroup('objectFilter', option));
  }

  /**
   * Filters a list of nodes for protected items.
   *
   * @param    nodes         The list of nodes to filter.
   * @return   The filtered list.
   */
  protectedFilter: DataTreeFilter<T> = (nodes: any[]) =>
    DataTreeFilterUtils.searchFilter(nodes, this.treeControl, (node: T) => node.protected, false, true);

  /**
   * Filters a list of nodes for unprotected items.
   *
   * @param    nodes         The list of nodes to filter.
   * @return   The filtered list.
   */
  unprotectedFilter: DataTreeFilter<T> = (nodes: any[]) =>
    DataTreeFilterUtils.searchFilter(nodes, this.treeControl, (node: T) => !node.protected, false, true);
}
