// Component: db details

;(function(angular, undefined) {
  'user strict';

  var componentConfig = {
    controller: 'DbDetailsController',
    templateUrl: 'app/dashboard/sql/detail/summary/db-details.html',
    bindings: {
      data: '<',
      loaded: '<',
    },
  };

  angular.module('C.dbDetails')
    .controller('DbDetailsController', DbDetailsCtrlFn)
    .component('dbDetails', componentConfig);

  /**
   * @ngdoc component
   * @name C.dbDetails.dbDetails
   *
   * @param   {Object}    data     SqlProtectionSource object for the database
   * @param   {boolean}   loaded   True if data is finished loading
   *
   * @description
   * Database Details, id, owner, filegroups, etc...
   *
   * @example
   * <db-details data="$ctrl.data" loaded="$ctrl.loaded"></db-details>
   */
  function DbDetailsCtrlFn(_) {
    var $ctrl = this;

    _.assign($ctrl, {
      getFilename: getFilename,
      getFilePath: getFilePath,
    });

    /**
     * Gets the filename from a full path
     *
     * @method   getFilename
     * @param    {string}   fullPath   full path of a windows file
     * @return   {string}   the name of the file without the path
     */
    function getFilename(fullPath) {
      return _.last(fullPath.split('\\'));
    }

    /**
     * Gets the path from a full path without the file name
     *
     * @method   getFilePath
     * @param    {string}   fullPath  full path of a windows file
     * @return   {string}   the path without the file name
     */
    function getFilePath(fullPath) {
      return _.dropRight(fullPath.split('\\'),1).join('\\') + '\\';
    }
  }
})(angular);
