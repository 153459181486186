import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ArchivalTargetResult, BackupRunSummary, ObjectRunResult, ObjectSummary, ProtectionGroupRun } from '@cohesity/api/v2';
import { AutoDestroyable } from '@cohesity/utils';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { filter, finalize, map, take } from 'rxjs/operators';
import { ScopeSelectorService } from 'src/app/core/services';
import { SnapshotSelectorUtilsService } from 'src/app/modules/restore/restore-shared/snapshot-selector/services/snapshot-selector-utils.service';
import { AvailableExtensionPoints } from 'src/app/plugin-shared';
import { Environment, JobRunTypeUIKey } from 'src/app/shared';

import { ClusterService } from 'src/app/core/services/cluster.service';
import { ProtectionGroupSearchService } from '../../services/protection-group-search.service';
import { SnapshotMetadataService } from '../../snapshot-selector/services/snapshot-metadata.service';
import { SnapshotMetadata } from '../../snapshot-selector/models/snapshot-metadata';

/**
 * These are the necessary params to pass to the run selector dialog.
 */
export interface RunSelectorParams {

  /**
   * The run object environment.
   */
  environment: Environment;

  /**
   * The group icon to show.
   */
  groupIcon: string;

  /**
   * The icon to show for object types.
   */
  objectIcon: string;

  /**
   * The current protection group name.
   */
  protectionGroupName: string;

  /**
   * The protection group id.
   */
  protectionGroupId: string;

  /**
   * Optional run id to initialize the modal selection to.
   */
  runId?: string;

  /**
   * Optional archive target id to initialize the modal selection to.
   */
  archiveTargetId?: number;
}

/**
 * The response from the modal is the protection group run (with objects), and
 * the selected archive target id, if any.
 */
export interface RunSelectorResponse {
  /**
   * The selected run.
   */
  run: ProtectionGroupRun;

  /**
   * The selected archive target id.
   */
  archiveTargetId?: number;
}

/**
 * Table-specific data model for protection group info. The main advantage of this
 * is that it brings the name to a top-level object, which makes it easier to filter by.
 */
export interface ProtectionGroupTableModel {
  /**
   * Display name for the object.
   */
  name: string;

  /**
   * Size of the object snapshot.
   */
  logicalSizeBytes: number;

  /**
   * latest snapshot information
   */
  latestSnapshot?: {
    /**
     * snapshot id
     */
    snapshotId?: string;
  };
}

/**
 * This dialog is used to select a protection run to recovery a group to.
 */
@Component({
  selector: 'coh-protection-group-run-selector-modal',
  templateUrl: './protection-group-run-selector-modal.component.html',
  styleUrls: ['./protection-group-run-selector-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProtectionGroupSearchService],
})
export class ProtectionGroupRunSelectorModalComponent extends AutoDestroyable implements OnInit {
  /**
   * The selected run date in the dropdown.
   */
  runControl = new UntypedFormControl(null, Validators.required);

  /**
   * The selected target type, either local or archive.
   */
  selectedTarget: BackupRunSummary | ArchivalTargetResult;

  /**
   * The currently selected run.
   */
  selectedRun: ProtectionGroupRun;

  /**
   * A reference to the restore snapshot selection detail extension point definition.
   * This is used by plugins (like vulscanner) to add information about a selected snapshot.
   */
  snapshotExtensionPoint = AvailableExtensionPoints.restoreSnapshotSelectionDetailColumn;

  /**
   * The list of objects for the currently selected run.
   */
  runObjects: ProtectionGroupTableModel[] = [];

  /**
   * Objects that are children of the run objects.
   */
  childObjects: ObjectSummary[] = [];

  /**
   * Snapshot metadata containing tags and recoverability information for the run objects
   */
  objectSnaphotMetadata?: SnapshotMetadata;

  /**
   * Expose Environment to temlate.
   */
  readonly Environment = Environment;

  /**
   * isSan indicates it is pure or ibm
   */
  isSan = false;

  /**
   * true, if the selected run can be used for the recovery
   */
  canUseForRecovery = true;

  /**
   * A subject which yields a value to indicate whether the content for current recovery point is loading.
   */
  recoverabilityInfoLoading$ = new BehaviorSubject<boolean>(false);

  /**
   * Has a local snapshot if there is a selected run and that has not
   * had its local snapshots deleted and is not CloudArchiveDirect.
   */
  get hasLocalSnapshot(): boolean {
    return this.selectedRun
      && this.selectedRun.hasLocalSnapshot
      && !this.selectedRun.isLocalSnapshotsDeleted
      && !this.selectedRun.isCloudArchivalDirect;
  }

  /**
   * Has a local snapshot with runType Storage array snapshot type
   */
  get hasStorageArraySnapshot(): boolean {
    return this.hasLocalSnapshot && this.selectedRun?.localBackupInfo?.runType === 'kStorageArraySnapshot';
  }

  /**
   * A list of archive targets for the run. This will be an empty list if there are
   * no archive targets.
   */
  get archivalTargets(): ArchivalTargetResult[] {
    if (!this.selectedRun || !this.selectedRun.archivalInfo || !this.selectedRun.archivalInfo.archivalTargetResults) {
      return [];
    }
    return this.selectedRun.archivalInfo.archivalTargetResults.filter(result =>
      ['Succeeded', 'SucceededWithWarning'].includes(result.status)
    );
  }

  /**
   * True if the list of runs is being loaded. This will show a spinner for the dialog dialog content.
   */
  get loadingRuns$(): Observable<boolean> {
    return this.service.loadingRuns$;
  }

  /**
   * True if loading run details.
   */
  get loadingDetails$(): Observable<boolean> {
    return combineLatest([
      this.service.loadingDetails$,
      this.recoverabilityInfoLoading$,
    ]).pipe(
      map(([loadingDetails, recoverabilityInfoLoading]) => loadingDetails || recoverabilityInfoLoading)
    );
  }

  /**
   * The list of available runs to choose form.
   */
  get runDates$(): Observable<ProtectionGroupRun[]> {
    return this.service.runsList$;
  }

  /**
   * Return true if cluster is NGCE.
   */
  get cloudEditionEnabled(): boolean {
    return this.clusterInfo.isClusterNGCE;
  }

  /**
   * list of columns for the table
   */
  get tableColumns(): string[] {
    const columns = ['name', 'size'];

    if (this.snapshotMetadataService.isCleanRoomRecoveryPhase1Enabled) {
      columns.push('tags');
    }

    return columns;
  }

  /**
   * Callback to pass data about a snapshot to the extension point.
   *
   * @param   row   The current row
   * @returns Info about the snapshot object.
   */
  getExtensionRowData = (row: ProtectionGroupRun) => ({
    clusterId: this.scopeSelectorService.clusterId,
    object: row
  });

  /**
   * Gets startTime from protection group run info.
   *
   * @param   run  Protection group run info.
   * @returns The startTimeUsecs.
   */
  getStartTime(run: ProtectionGroupRun): number {
    if (run.isCloudArchivalDirect) {
      return this.service.getArchiveInfo(run)?.startTimeUsecs;
    }
    return this.service.getRunBackupInfo(run)?.startTimeUsecs;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly params: RunSelectorParams,
    readonly service: ProtectionGroupSearchService,
    readonly snapshotSelectorUtilsService: SnapshotSelectorUtilsService,
    private dialogRef: MatDialogRef<RunSelectorParams, RunSelectorResponse>,
    private cdr: ChangeDetectorRef,
    private snapshotMetadataService: SnapshotMetadataService,
    private scopeSelectorService: ScopeSelectorService,
    private clusterInfo: ClusterService,
  ) {
    super();
    if (!this.params || !this.params.protectionGroupId) {
      throw new Error('Must specify a protection group id and initial run id');
    }
    if ([Environment.kPure, Environment.kIbmFlashSystem].includes(this.params.environment)) {
      this.isSan = true;
    }
  }

  /**
   * Closes the dialog with the selected run and archive target.
   */
  submit() {
    this.dialogRef.close({
      run: this.selectedRun,
      archiveTargetId:
        this.selectedTarget === this.service.getRunBackupInfo(this.selectedRun)
          ? undefined
          : (this.selectedTarget as ArchivalTargetResult).targetId,
    });
  }

  ngOnInit() {
    const { protectionGroupId, runId } = this.params;
    this.service.getRecoverableRuns(protectionGroupId);

    // Wait for the list to load, then find the selected run and initialize it to the
    // input value.
    this.service.runsList$
      .pipe(
        filter(list => !!list.length),
        take(1),
        this.untilDestroy(),
        map(list => list.find(run => run.id === runId) || list[0])
      )
      .subscribe(run => this.runControl.setValue(run));

    // Fetch details for the run id whenever it changes.
    this.runControl.valueChanges
      .pipe(
        filter(value => !!value),
        this.untilDestroy()
      )
      .subscribe(run => {
        this.service.getRunDetails(protectionGroupId, run.id);
      });

    this.service.runDetails$.pipe(this.untilDestroy()).subscribe(run => {
      this.selectedRun = run;
      if (run) {
        const isCloudArchivalDirect = run.isCloudArchivalDirect;
        const filteredObjects = (run.objects || []).filter(
          object =>
            ['kSuccessful', 'Succeeded'].includes(
              this.service.getSnapshotInfo(object, isCloudArchivalDirect)?.status) &&
            object.object.environment === this.params.environment
        );

        // TODO(ang): Confirm again if vms is really needed
        const vms = filteredObjects.map(row => ({
          id: row.object.id,
          name: row.object.name,
          parentId: row.object.sourceId
        }));

        this.childObjects = [];
        if (filteredObjects?.length) {
          for (const filteredObject of filteredObjects) {
            if (filteredObject.object?.childObjects) {
              if (this.isSan && this.params.protectionGroupName === filteredObject.object?.name) {
                // if this is San (ibm,pure) and a case of reattempt
                if (
                  filteredObject?.localSnapshotInfo?.failedAttempts?.length ||
                  filteredObject?.originalBackupInfo?.failedAttempts?.length
                ) {
                  // fetch the object run time from localsnapshot info from selected object
                  const objectRuntime = this.service.getSnapshotInfo(filteredObject).startTimeUsecs;
                  // fetch correct snapshot id of object based on successful snapshot time passed
                  this.service
                    .getSnapshotId(filteredObject.object.id, objectRuntime, filteredObject.object?.name)
                    .subscribe(result => {
                      // Update the snapshotId of selected object only for case of reattempt
                      // for reattempt object snapshot time differs,
                      // from run start time (which is same for backups without reattempt)
                       if (result) {
                         if (filteredObject?.localSnapshotInfo) {
                           filteredObject.localSnapshotInfo.snapshotInfo.snapshotId = result;
                         } else {
                           filteredObject.originalBackupInfo.snapshotInfo.snapshotId = result;
                         }
                       }
                    });
                }

                // for san, filter child objects by protection group
                this.childObjects.push(...filteredObject.object.childObjects.map(child => ({ ...child })));
              } else if (!this.isSan) {
                this.childObjects.push(
                  ...filteredObject.object.childObjects.map(child => ({ ...child, group: filteredObject.object.name }))
                );
              }
            }
          }
        }

        // We only care about objects that were successfully backed up.
        this.runObjects = [];
        this.runObjects = filteredObjects
          .map(row => ({
            name: row.object.name,
            logicalSizeBytes: this.service.getSnapshotInfo(row, isCloudArchivalDirect).stats.logicalSizeBytes,
            id: row.object.id,
            sourceId: row.object.sourceId,
            latestSnapshot: {
              protectionGroupId: run.protectionGroupId,
              runInstanceId: run.protectionGroupInstanceId,
              protectionRunStartTimeUsecs: this.service.getSnapshotInfo(row, isCloudArchivalDirect).startTimeUsecs,
              snapshotId: this.service.getSnapshotInfo(row, isCloudArchivalDirect).snapshotId
            },
            vms
          }));

        if (this.params.archiveTargetId) {
          // If the input includes a target id, select it automatically,
          // then clear it so that it doesn't affect subsequent selection.
          this.selectedTarget = this.archivalTargets.find(target => target.targetId === this.params.archiveTargetId);
          this.params.archiveTargetId = null;
        } else if (this.hasLocalSnapshot) {
          // Select local snapshot by default
          this.selectedTarget = this.service.getRunBackupInfo(this.selectedRun);
        } else if (this.archivalTargets.length) {
          // And finally, the first archive snapshot.
          this.selectedTarget = this.archivalTargets[0];
        }

        // load the snapshot tagging information for the run objects
        if (this.snapshotMetadataService.isCleanRoomRecoveryPhase1Enabled) {
          this.loadObjectSnapshotMetadata(filteredObjects, isCloudArchivalDirect);
        }

        this.cdr.detectChanges();
      }
    });
  }

  /**
   * Returns whether the currently selected snapshot is a tape snapshot.
   *
   * @returns `true` if the selected snapshot is a tape snapshot, `false` otherwise.
   */
  isTapeSnapshot(): boolean {
    return this.selectedTarget !== this.service.getRunBackupInfo(this.selectedRun) &&
      (this.selectedTarget as ArchivalTargetResult)?.targetType === 'Tape';
  }

  /**
   * Returns UI string for job run type.
   */
  getRunTypeUIKey(run: ProtectionGroupRun): string {
    const runType = run?.localBackupInfo?.runType || run?.originalBackupInfo?.runType;
    return JobRunTypeUIKey[runType];
  }

  /**
   * Fetches the snapshot metadata information to show the tags associated with object for the selected protection run
   *
   * @param objects list of objects for the currently selected protection run
   * @param isCloudArchivalDirect True, if it is cloud archival direct run.
   */
  loadObjectSnapshotMetadata(objects: ObjectRunResult[], isCloudArchivalDirect: boolean) {
    const snapshotIds = objects.map((object) => {
      const snapshotInfo = this.service.getSnapshotInfo(object, isCloudArchivalDirect);
      return snapshotInfo.snapshotId;
    }).filter(Boolean);

    this.recoverabilityInfoLoading$.next(true);
    this.snapshotMetadataService.fetchSnapshotMetadata(snapshotIds)
      .pipe(
        this.untilDestroy(),
        finalize(() => this.recoverabilityInfoLoading$.next(false))
      )
      .subscribe((snapshotMetadata) => {
        this.objectSnaphotMetadata = snapshotMetadata;
        this.canUseForRecovery = !Object.values(snapshotMetadata.recoverability).some(value => value === false);
        this.cdr.detectChanges();
      });
  }

  /**
   * Retrieves the tags associated with given object from the snapshot metadata information
   *
   * @param object object for which the tag list is required
   * @returns list of tags associated with the given object
   */
  getSnapshotTags(object: ProtectionGroupTableModel) {
    const snapshotId = object.latestSnapshot.snapshotId;
    const tags = this.objectSnaphotMetadata?.tags?.[snapshotId];
    return tags ?? [];
  }
}
