/**
 * Connection State of a VM
 */
export enum ConnectionState {
  kConnected = 'enum.connectionState.kConnected',
  kDisconnected = 'enum.connectionState.kDisconnected',
  kInaccessible = 'enum.connectionState.kInaccessible',
  kInvalid = 'enum.connectionState.kInvalid',
  kNotResponding = 'enum.connectionState.kNotResponding',
  kOrphaned = 'enum.connectionState.kOrphaned',
}
