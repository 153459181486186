import { ChangeDetectionStrategy, Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { BubbleMapComponent } from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';
import { SeriesMapbubbleDataOptions } from 'highcharts';
import { isEmpty } from 'lodash';

import { AnomalyStats } from '../../security-shared.models';

/**
 * Anomaly card component
 * This card contains Bubble map and aggregated stats view.
 *
 * @example
 * <dg-ar-anomaly-card
 *  [loading]="isLoading"
 *  [bubbleSeriesData]="locations"
 *  [statsData]="aggregatedStats">
 * </dg-ar-anomaly-card>
 *
 */
@Component({
  selector: 'dg-ar-anomaly-card',
  templateUrl: './anomaly-card.component.html',
  styleUrls: ['./anomaly-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AnomalyCardComponent {
  /**
   * Sets series data containing location information
   */
  @Input() set bubbleSeriesData(bubbleSeriesData: SeriesMapbubbleDataOptions[]) {
    this._bubbleSeriesData = bubbleSeriesData;
    this.refreshChartData();
  }

  /**
   * Returns series data
   */
  get bubbleSeriesData(): SeriesMapbubbleDataOptions[] {
    return this._bubbleSeriesData;
  }

  /**
   * Aggregated stats information
   */
  @Input() statsData: AnomalyStats;

  /**
   * Aggregated stats information
   */
  @Input() loading: boolean;

  /**
   * To show stats section on world map
   */
  @Input() showStats = true;

  /**
   * A reference to the line chart
   */
  @ViewChild(BubbleMapComponent) bubbleMapChart: BubbleMapComponent;

  /**
   * Lodash isEmpty function
   */
  isEmpty = isEmpty;

  /**
   * Referance variable for series data
   */
  private _bubbleSeriesData = [];

  /**
   * Customized options for bubble map.
   */
  readonly customMapOptions = {
    tooltip: {
      useHTML: true,
      formatter: (self =>
        function getFormatterTooltip() {
          return self.translateService.instant('chart.affectedObjectsPointFormat', {
            systemName: this.point.clusterName,
            objectsCount: this.point.z,
          });
        })(this),
    },
  };

  /**
   * Anomaly object details
   */
  anomaly = {
    seriesData: [
      {
        name: this.translateService.instant('anomalies'),
        type: 'mapbubble',
        data: [],
        maxSize: '5%',
      },
    ],
    colorSetClass: 'anomaly-bubble-map-theme',
  };

  constructor(private translateService: TranslateService) {}

  /**
   * Updates data in vulScan object, which refreshes graph
   */
  refreshChartData() {
    this.anomaly.seriesData[0].data = this._bubbleSeriesData;
    if (this.bubbleMapChart?.chart) {
      this.bubbleMapChart.chart.reflow();
    }
  }
}
