import { Pipe, PipeTransform } from '@angular/core';

import { Environment, iconSettings } from '../constants';

/**
 * Returns the icon for a sources that are grouped under a parent source.
 * The returned value is detemined based on existing helix icons.
 *
 * @param   environment  The parent source entity
 * @return  The icon for the parent source entity.
 */
export function sourceGroupIconTransform(environment: string): string {
  const iconName = iconSettings.environment.mapping[environment];

  if (environment === Environment.kHyperFlex) {
    return `helix:object-hyperflex`;
  } else if (environment === Environment.kAWS) {
    return 'helix:group-aws';
  } else if (environment === Environment.kAzure) {
    return 'helix:group-azure';
  }

  return iconName ? `helix:group-${iconName}` : '';
}

/**
 * @ngDoc Filter
 * @description
 *   Returns the icon for a sources that are grouped under a parent source.
 *   Example: Sources such as VCenter, Hypervisor, GCP< AWS etc. are grouped
 *   under parent entity VMWare.
 *
 * @example
 *   {{ source.environment | sourceGroupIcon }} => helix:group-virtual-machine.
 */
@Pipe({
  name: 'sourceGroupIcon'
})
export class SourceGroupIconPipe implements PipeTransform {

  /**
   * Returns the icon for a sources that are grouped under a parent source.
   * The returned value is detemined based on existing helix icons.
   *
   * @param   environment  The parent source entity
   * @return  The icon for the parent source entity.
   */
  transform(environment: string): string {
    return sourceGroupIconTransform(environment);
  }

}
