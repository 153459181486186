import { Component } from '@angular/core';
import { AppPanelService, SnackBarService } from '@cohesity/helix';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { UserService } from 'src/app/core/services';

import { TemplateSelectorComponent } from '../../components';
import { ViewsService } from '../../services';

/**
 * View creation source definition.
 */
interface ViewCreationSource {
  /**
   * This represent the HTML id for the corresponding menu item.
   */
  id: string;

  /**
   * This is the menu label for the view creation source.
   */
  translateKey: string;

  /**
   * Determines the icon name to be displayed.
   */
  icon?: string;

  /**
   * Determines whether the menu item has to be disabled or not.
   */
  disabled?: boolean;

  /**
   * Determines whether to show or hide the menu option.
   */
  hidden?: boolean;

  /**
   * This represents whether an action needs to be performed on click of the button.
   */
  action?: () => void;
}

/**
 * Create view button for views creation.
 *
 * @example
 *   <coh-create-view-button></coh-create-view-button>
 */
@Component({
  selector: 'coh-create-view-button',
  templateUrl: './create-view-button.component.html',
  styleUrls: ['./create-view-button.component.scss'],
})
export class CreateViewButtonComponent {
  /**
   * Sources for view creation template selection.
   */
  sources: ViewCreationSource[] = [
    {
      id: 'create-view',
      translateKey: 'view',
      action: () => this.openDialog(),
    },
    {
      id: 'create-view-from-from-template',
      translateKey: 'viewFromTemplate',
      action: this.openPanel.bind(this),
      hidden: !flagEnabled(this.irisCtx.irisContext, 'ngViewTemplates'),
    },
  ];

  /**
   * Method called to get specific privilege information.
   */
  get hasPermissionToCreateView(): boolean {
    if (!this.userService.user) {
      return false;
    }
    return (!!this.userService.user.privs.CLUSTER_MODIFY ||
      !!this.userService.user.privs.STORAGE_MODIFY);
  }

  /**
   * Check if intent based views are enabled or not.
   */
  get createViewFromTemplateOnlyEnabled() {
    return flagEnabled(this.irisCtx.irisContext, 'createViewFromTemplateOnly');
  }

  constructor(
    private irisCtx: IrisContextService,
    private snackbar: SnackBarService,
    private stateService: StateService,
    private translate: TranslateService,
    private userService: UserService,
    private viewsService: ViewsService,
    public appPanelService: AppPanelService,
  ) { }

  /**
   * Method called to open template panel component.
   */
  openPanel() {
    this.appPanelService.open(TemplateSelectorComponent, {
      autoFocus: false,
      panelClass: 'coh-template-selector-panel',
    });
  }

  /**
   * Opens the modal to create view.
   */
  openDialog() {
    this.viewsService.openCreateViewDialog()
      .subscribe(view => {
        this.snackbar.open(this.translate.instant('views.createViewSuccess', { name: view.name }));
        this.stateService.go('ng-views.views.all-views', {}, { reload: true });
      });
  }
}
