import { Injectable } from '@angular/core';
import { NavItem } from '@cohesity/helix';
import { IrisContextService } from '@cohesity/iris-core';
import { StateService } from '@uirouter/core';
import { Observable } from 'rxjs';
import { DialogService, StateManagementService } from 'src/app/core/services';
import { RestoreConfigService } from 'src/app/modules/restore/restore-shared';

import { ObjectActionCreator } from './object-action-creator';
import { ObjectActionProvider } from './object-action-provider';
import { ObjectInfoService } from './object-info.service';
import { SimpleObjectInfo } from './object-menu-provider';

/**
 * This is a simple implementation for construction of the object menu actions.
 * It has some simple logic around auto protection, and can be configured to
 * restrict items to only be protected by a single job.
 */
@Injectable()
export class SapHanaObjectActionProvider extends ObjectActionProvider {

  /**
   * The provider for this service is manually set up in the
   * object-actions-menu.service, which must provide the list of providers as
   * an array in the correct order. In order to maintain some kind of sanity,
   * the providers are listed here, they should match the order of the
   * constructor args.
   */
  static sapHanaObjectActionProviderDependencies = [
    ObjectInfoService,
    RestoreConfigService,
    DialogService,
    StateManagementService,
    StateService,
    ObjectActionCreator,
    IrisContextService,
  ];

  exclusiveProtection = false;

  constructor(
    objectStore: ObjectInfoService,
    restoreConfig: RestoreConfigService,
    private dialog: DialogService,
    stateManagementService: StateManagementService,
    stateService: StateService,
    readonly actionCreator: ObjectActionCreator,
    irisContextService: IrisContextService,
  ) {
    super(objectStore, restoreConfig, stateManagementService, stateService, actionCreator, irisContextService);
  }

  getObjectActions(object: SimpleObjectInfo): Observable<NavItem[]> {
    return super.getObjectActions(object);
  }
}
