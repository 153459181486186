<cog-settings-list-item [formGroup]="formGroup"
  [invalid]="formControl.invalid"
  [label]="'Exclusions' | translate">
  <cog-settings-list-item-value-display>
    {{diskExclusionFormGroup.get('rawQuery').value ?
      (diskExclusionFormGroup.get('rawQuery').value) :
      ('none' | translate)}}
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor [formGroup]="diskExclusionFormGroup"
    cogDataId="settings-gcp-disk-exclusion">
    <mat-slide-toggle
      cogDataId="exclude-disk-toggle"
      formControlName="enableExcludeDisk">
      {{'excludeDisks' | translate}}
    </mat-slide-toggle>
    <div *ngIf="diskExclusionFormGroup.get('enableExcludeDisk').value">
      <p>{{'job.settings.gcpDiskExclusion.description' | translate}}</p>
      <cog-advanced-search
        cogDataId="gcp-exclude-advance-search"
        [placeholder]="'job.settings.gcpDiskExclusion.tagsPlaceholder' | translate"
        [typingPlaceholder]="'job.settings.gcpDiskExclusion.typingPlaceholder' | translate"
        [advancedSearchSuggester]="antlrSuggester"
        [getSuggestions]="getSuggestions"
        [suggestedValuesLabel]="'suggestedValues' | translate"
        [valueFormatsLabel]="'valueFormats' | translate"
        [formControl]="rawQueryControl"
        [searchView]="false"
        [useTextarea]="true"
        [translationResult]="translationResult"
        [isEmptyStringValid]="true">
      </cog-advanced-search>
    </div>
    <mat-slide-toggle
      cogDataId="exclude-vm-with-no-disk-toggle"
      formControlName="enableExcludeVmWithNoDisk">
      {{'excludeVmsWithNoDisk' | translate}}
    </mat-slide-toggle>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
