<h4 *ngIf="label" class="margin-top-sm">{{ label }}</h4>
<mat-radio-group [formControl]="option" class="certificate-file-options">
  <mat-radio-button
    cogDataId="certificate-file-option-upload"
    [inheritDataId]="true"
    [value]="'upload'"
    class="margin-right-xl">{{ 'upload' | translate }}</mat-radio-button>
  <mat-radio-button
    cogDataId="certificate-file-option-edit"
    [inheritDataId]="true"
    [value]="'edit'">{{ 'edit' | translate }}</mat-radio-button>
</mat-radio-group>

<label for="certificate-file-upload-btn" *ngIf="option.value === 'upload'" class="margin-top-lg">
  <input
    type="file"
    required
    class="visuallyhidden"
    id="certificate-file-upload-btn"
    cogDataId="certificate-file-input"
    [inheritDataId]="true"
    (change)="readFile($event)"
    accept="{{acceptableFileExt.join(',')}}">
  <a
    mat-raised-button
    color="primary"
    cogDataId="certificate-select-file"
    [inheritDataId]="true"
    (click)="control.markAsTouched()">
    {{'selectFile' | translate}}
  </a>
</label>
<mat-chip class="certificate-file-name margin-left-lg" *ngIf="option.value === 'upload' && fileName?.length">
  {{fileName}}
  <mat-icon
    cogDataId="certificate-file-chip-remove"
    [inheritDataId]="true"
    (click)="clearCertificate()"
    matChipRemove>cancel</mat-icon>
</mat-chip>

<textarea
  *ngIf="option.value === 'edit'"
  cogDataId="certificate-text-input"
  class="certificate-text-input margin-top-lg"
  [inheritDataId]="true"
  [formControl]="control"
  required>
</textarea>

<mat-error class="mat-small margin-top-xs">
  <span *ngIf="option.value === 'upload' && control.touched">
    <ng-container *ngIf="readError || !fileName?.length">
      {{ (readError ? 'sso.error.readFile' : 'errors.required') | translate }}
    </ng-container>
  </span>
  <span *ngIf="option.value === 'edit' && control.touched && control.errors?.required">
    {{'errors.required' | translate}}
  </span>
  <span *ngIf="control.touched && control.errors?.pattern">
    {{'errors.patterns.invalidCertificate' | translate}}
  </span>
</mat-error>
