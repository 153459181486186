<ng-container *ngIf="displayMode === 'default'">
  <span *ngFor="let item of items">
    {{ item.label | translate }}: {{ (countByResourceType$ | async)?.[item.type] || 0}}
  </span>
</ng-container>

<cog-card-section-glancebar [class.invisible]="false" *ngIf="displayMode === 'statList'">
  <cog-statlist valueSize="md" itemSpacing="md">
    <cog-statlist-item *ngFor="let item of items" [label]="item.label | translate">
      {{ (countByResourceType$ | async)?.[item.type] || 0}}
    </cog-statlist-item>
  </cog-statlist>
</cog-card-section-glancebar>
