import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NisNetgroup } from '@cohesity/api/v2';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { finalize } from 'rxjs/operators';
import { NISService } from 'src/app/modules/access-management/on-prem-access-management/nis/nis.service';

/**
 * The parameters passed in DeleteNetgroupWhitelist Dialog.
 */
export interface DeleteNetgroupWhitelistDialogParams {
  /**
   * netgroup.
   */
  netgroup: NisNetgroup;

  /**
   * Whether modify the global netgroup whitelist or not.
   */
  modifyGlobalNetgroup?: boolean;
}

/**
 * The dialog component used to delete netgroup whitelist.
 */
@Component({
  selector: 'coh-delete-netgroup-whitelist-dialog',
  templateUrl: './delete-netgroup-whitelist-dialog.component.html',
})
export class DeleteNetgroupWhitelistDialogComponent extends AutoDestroyable {

  /**
   * The form group for the dialog
   */
  form: UntypedFormGroup = new UntypedFormGroup({
    confirmDelete: new UntypedFormControl(null, [Validators.required, Validators.pattern('^([yY][eE][sS])$')]),
  });

  /**
   * Indicates whether a deleting netgroup operation is in progress.
   */
  deleting = false;

  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private dialogRef: MatDialogRef<DeleteNetgroupWhitelistDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogParams: DeleteNetgroupWhitelistDialogParams,
    private nisService: NISService
  ) {
    super();
  }

  /**
   * Delete the current Netgroup.
   */
  deleteNetgroup() {
    // If don't want to modify global netgroup whitelist, then just close the dialog.
    if (!this.dialogParams.modifyGlobalNetgroup) {
      this.dialogRef.close('success');
      return;
    }

    if (this.form.get('confirmDelete').value.toUpperCase() === 'YES') {
      this.deleting = true;

      this.nisService.deleteNetgroup(this.dialogParams.netgroup)
        .pipe(
          this.untilDestroy(),
          finalize(() => this.deleting = false))
        .subscribe(() => this.dialogRef.close('success'), error => this.ajaxHandlerService.handler(error));
    }
  }

}
