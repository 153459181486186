import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyListItem as MatListItem } from '@angular/material/legacy-list';

import { HelixIntlService } from '../../helix-intl.service';
import { NavItem } from './nav-item.model';

/**
 * Application primary navigation item component.
 */
@Component({
  selector: 'cog-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavItemComponent {
  /**
   * The mat list item for this nav item.
   */
  @ViewChild(MatListItem) matListItem: MatListItem;

  /**
   * The navigation item to render
   */
  @Input() item: NavItem;

  /**
   * Optional. Set to true if the nav item is a child. This will set a style
   * that adds padding to the content.
   */
  @Input() isSubItem = false;

  /**
   * Handles keypress events for the MatListItem.
   *
   * @param event   The Keypress event
   */
  onKeypress(_event: Event) {
    if (this.item.subNavList && this.item.subNavList.length) {
      this.item.isOpen = !this.item.isOpen;
    } else {
      // Pretend this was a mouse click so it bubbles up.
      this.matListItem._getHostElement().click();
    }
  }

  /**
   * Handles a click action. This only tracks whether to open or close a parent
   * nav item. In order to use a link, a routerLink or usSref directive should
   * be added to the host element.
   *
   * @param   event   The Angular click event.
   */
  onClick(event: MouseEvent) {
    if (this.item.subNavList && this.item.subNavList.length) {
      // stop propogation so this is a simple toggle of show/hide subNavList.
      event.stopPropagation();
      this.item.isOpen = !this.item.isOpen;
    }
  }

  constructor(public intl: HelixIntlService) {}
}
