import { CdkPortalOutletAttachedRef, ComponentPortal, ComponentType } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { isEqual } from 'lodash';

import { DataTreeNodeContext } from '../data-tree-detail.directive';
import { DataTreeNode } from '../shared/data-tree.model';

/**
 * Components that get rendered as node details in the tree should implement
 * this interface so that the detail outlet can set the nodeContet object as
 * inputs on them.
 */
export interface DataTreeNodeDetail<T extends DataTreeNode<any> = any> {
  /**
   * nodeContext contains information about the current state of the node.
   */
  nodeContext: DataTreeNodeContext<T>;

  /**
   * A component can set this class to have the detail object apply it to its own
   * property. This is sometimes needed to adjust the component's width in the tree.
   */
  readonly nodeClass?: string;
}

/**
 * This component can be used to dynamically load a component and display it in a data tree. It will
 * it will inject an instance of the node into the newly created node. This can be used by a data tree
 * implementation to easily provide different component implementations based on different tree types.
 *
 * @example
 * <cog-data-tree-node>
 *   <ng-container *cogDataTreeDetail="let ctx">
 *     <cog-data-tree-detail-outlet [component]="nodeDetailComponent" [nodeContext]="ctx">
 *     </cog-data-tree-detail-outlet>
 *   </ng-conainer>
 * </cog-data-tree-node>
 *
 * ...
 * class NodeDetailComponent implements DataTreeNodeDetail<any>{
 *   nodeContext: DataTreeNodeContext<any>;
 * }
 */
@Component({
  selector: 'cog-data-tree-detail-outlet',
  template: '<ng-template [cdkPortalOutlet]="portal" (attached)="onAttached($event)"></ng-template>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTreeDetailOutletComponent<T extends DataTreeNode<any>> implements OnChanges {
  /**
   * The current node being rendered.
   */
  @Input() nodeContext: DataTreeNodeContext<T>;

  /**
   * A reference to the component to render for this node.
   */
  @Input() component: ComponentType<DataTreeNodeDetail>;

  /**
   * The component's instantiated CDK portal.
   */
  portal: ComponentPortal<DataTreeNodeDetail>;

  /**
   * A reference to the created component
   */
  private componentRef: ComponentRef<DataTreeNodeDetail>;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {

  }

  /**
   * Handle the component being attacked.
   *
   * @param   componentRef   A reference to the newly created component
   */
  onAttached(componentRef: CdkPortalOutletAttachedRef) {
    this.componentRef = componentRef as ComponentRef<any>;
    this.updateComponentContext();
  }

  /**
   * Update the node context on the data.
   */
  private updateComponentContext() {
    if (!this.componentRef) {
      return;
    }
    const { instance } = this.componentRef;
    if (instance.nodeClass) {
      this.renderer.addClass(this.elementRef.nativeElement, instance.nodeClass);
    }
    if (isEqual(instance.nodeContext, this.nodeContext)) {
      return;
    }

    // It turns out that componentRef.changeDetectorRef is a difference detector from
    // the one you would get by calling the component's injector. This method actually works
    // to detect and update the component.
    instance.nodeContext = this.nodeContext;
    this.componentRef.injector.get(ChangeDetectorRef).detectChanges();
  }

  /**
   * Recreate the portal whenever the node or portal changes
   *
   * @param   changes   A map of changed input properties.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.nodeContext) {
      this.updateComponentContext();
    }

    if (changes.component && changes.component.previousValue !== changes.component.currentValue) {
      this.portal = this.component ? new ComponentPortal(this.component) : undefined;
    }
  }
}
