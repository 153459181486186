// Module: Remote Clusters Modify Component

;(function(angular, undefined) {
  'use strict';

  var configOptions = {
    bindings: {
      /**
       * Optional provide the remote cluster clusterId to edit remote connection
       * else allow create a new remote connection.
       *
       * @type  {String}
       */
      clusterId: '<?',

      /**
       * Optional provide the cluster info to save fetching remote connection
       * info again.
       *
       * @type  {Object}
       */
      cluster: '<?',

      /**
       * Optional provide the modalInstance if rendering remote connection in a
       * modal and hence onSuccess & onCancel will hide the modal.
       *
       * @type  {Object}
       */
      modalInstance: '=?',
    },
    controller: 'RemoteClustersModifyCtrl',
    templateUrl: 'app/remote-clusters/modify/remote-clusters-modify.html',
  };

  var viewConfigOptions = _.assign({}, configOptions, {
    templateUrl: 'app/remote-clusters/modify/remote-clusters-view.html',
  });

  angular.module('C.remoteClusters')
    .controller('RemoteClustersModifyCtrl', remoteClustersModifyCtrlFn)
    .component('remoteClustersView', viewConfigOptions)
    .component('remoteClustersModify', configOptions);

  function remoteClustersModifyCtrlFn(_, $state, FEATURE_FLAGS, cMessage,
    evalAJAX, RemoteClusterService) {
    var $ctrl = this;
    var targetViewState = 'remote-clusters-view';

    // declare component methods
    _.assign($ctrl, {
      clipboardMessage: clipboardMessage,
      FEATURE_FLAGS: FEATURE_FLAGS,
      onCancel: onCancel,
      onRemoteAccessToggle: onRemoteAccessToggle,
      onSaveSuccess: onSaveSuccess,
      removeRemoteCluster: removeRemoteCluster,
      saveRemoteCluster: saveRemoteCluster,
      showReplicationSetup: showReplicationSetup,
      isRemoteAccessOptionAvaiable: isRemoteAccessOptionAvaiable,

      // component life cycle methods
      $onInit: $onInit,
    });

    /**
     * initialize component context
     *
     * @method   initializeCtrl
     */
    function initializeCtrl() {
      _.assign($ctrl, {
        // UI states
        state: {
          loading: false,
          submitting: false,
          inEditMode: !!$ctrl.clusterId,
          isViewMode: $state.current.name === targetViewState,
          inModalMode: !!$ctrl.modalInstance,
        },

        // component properties used in the view
        cluster: _.assign({
          autoRegisterTarget: true,
          compressionEnabled: true,
          purposeRemoteAccess: false,

          // If SPoG is deprecated then replication is the only point of a
          // remote cluster. Therefore we'll enable this by default and hide
          // the toggle switch in the UI.
          purposeReplication: FEATURE_FLAGS.spogDeprecated,
          remoteIps: [],
          _cache: {},
          _validConnection: false,
          _viewBoxSearchNeeded: true,
        }, $ctrl.cluster),
      });
    }

    /**
     * initialize the component
     *
     * @method   $onInit
     */
    function $onInit() {
      initializeCtrl();

      if ($ctrl.state.inEditMode) {
        getRemoteCluster($ctrl.clusterId);
      }
    }

    /**
     * Removes remote access dependent keys from cluster.
     *
     * @method   onRemoteAccessToggle
     */
    function onRemoteAccessToggle() {
      if (!$ctrl.cluster.purposeRemoteAccess) {
        $ctrl.cluster.remoteAccessCredentials = undefined;
      }
    }

    /**
     * Indicates if replication settings should be displayed.
     *
     * @method    showReplicationSetup
     * @returns   {boolean}   true if settings should be displayed,
     *                        false otherwise.
     */
    function showReplicationSetup() {
      return $ctrl.FEATURE_FLAGS.spogDeprecated
        ? $ctrl.cluster._validConnection
        : $ctrl.cluster.purposeReplication;
    }

    /**
     * Indicates if remote access toggle is available.
     *
     * @method    isRemoteAccessOptionAvaiable
     * @returns   {boolean}   true if remote access toggle should be displayed,
     *                        false otherwise.
     */
    function isRemoteAccessOptionAvaiable() {
      return $ctrl.state.inEditMode
        ? ($ctrl.cluster.purposeRemoteAccess || !$ctrl.FEATURE_FLAGS.spogDeprecated)
        : !$ctrl.FEATURE_FLAGS.spogDeprecated;
    }

    /**
     * Gets remote cluster config details
     *
     * @method   getRemoteCluster
     */
    function getRemoteCluster(clusterId) {
      $ctrl.state.loading = true;
      RemoteClusterService.getRemoteCluster(clusterId).then(
        function remoteClusterSuccess(cluster) {
          _.assign($ctrl.cluster, cluster);

          if ($ctrl.cluster.autoRegistration) {
            $ctrl.cluster._viewBoxSearchNeeded = false;
          }

          if ($ctrl.state.isViewMode) {
            if (!$ctrl.cluster.purposeReplication) {
              $ctrl.cluster._validConnection = true;
            }
            canEditRemoteCluster(cluster);
            return;
          }

          // validating the connection to remote cluster to check whether the
          // saved username/password are working or source cluster is able to
          // reach out to remote cluster.
          return RemoteClusterService.validateConnection($ctrl.cluster).then(
            function validationSuccess() {
              $ctrl.cluster._validConnection = true;
              canEditRemoteCluster(cluster);
            },
            function validationFailed(errorResponse) {
              // if connection is invalid caching the existing remote access or
              // replication settings used later to restore once the connection
              // issue is resolved by providing new password or restoring the
              // connection from source to remote cluster.
              _.assign($ctrl.cluster._cache, {
                purposeRemoteAccess: $ctrl.cluster.purposeRemoteAccess,
                purposeReplication: $ctrl.cluster.purposeReplication,
              });
              _.assign($ctrl.cluster, {
                _validConnection: false,
                purposeRemoteAccess: false,
                purposeReplication: false,
              });

              // show the error message received.
              evalAJAX.errorMessage(errorResponse);
              canEditRemoteCluster(cluster);
            }
          );
        }
      ).finally(function remoteClusterFinally() {
        $ctrl.state.loading = false;
      });
    }

    /**
     * Perform a test whether the logged in user belongs to the organization
     * which is the owner of the editing remote cluster if yes then allow
     * editing else navigate away to the view remote cluster page.
     *
     * @method   canEditRemoteCluster
     * @param    {Object}    The remote Cluster info to edit
     */
    function canEditRemoteCluster(cluster) {
      if (!_.get(cluster, '_canViewClusterDetails')) {
        $state.go($ctrl.state.isViewMode ?
          targetViewState : $state.current.parentState, {
          clusterId: $ctrl.clusterId,
          cluster: $ctrl.cluster,
        });
      }
    }

    /**
     * Determines whether modify remote cluster Form is invalid or not.
     *
     * @method   _isFormInvalid
     * @param    {Object}    remoteClusterForm   The remote cluster Form.
     * @return   {boolean}   True if from invalid, False otherwise.
     */
    function _isFormInvalid(remoteClusterForm) {
      var isInvalid = remoteClusterForm.$invalid;
      var clusterConnectionForm;
      var remoteAccessForm;
      var overrides;

      // no isolated Form so use parent Form validation state.
      if (!remoteClusterForm._isolatedFormHash) {
        return isInvalid;
      }

      // check whether isolated Forms are invalid.
      clusterConnectionForm =
        remoteClusterForm._isolatedFormHash['$ctrl.clusterConnectionForm'];
      remoteAccessForm =
        remoteClusterForm._isolatedFormHash['$ctrl.remoteAccessForm'];

      if (clusterConnectionForm ? clusterConnectionForm.$invalid : false) {
        clusterConnectionForm.$setSubmitted();
        isInvalid = true;
      }

      if (remoteAccessForm ? remoteAccessForm.$invalid : false) {
        remoteAccessForm.$setSubmitted();
        isInvalid = true;
      }

      // check whether the blacklist form is valid or not.
      // Check overrides settings is filled.
      overrides = _.get($ctrl.cluster.bandwidthLimit,
        'bandwidthLimitOverrides');
      if (overrides && !overrides.length) {
        cMessage.warn({
          textKey: 'addButtonBlackoutWindow',
        });
        isInvalid = true;
      }

      return isInvalid;
    }

    /**
     * create or update the remote cluster config.
     *
     * @method   saveRemoteCluster
     */
    function saveRemoteCluster() {
      var actionFn;

      // early exit if form is invalid
      if (_isFormInvalid($ctrl.state.remoteClusterForm)) {
        return;
      }

      $ctrl.state.submitting = true;

      actionFn = $ctrl.cluster.clusterId ?
        RemoteClusterService.updateRemoteCluster :
        RemoteClusterService.newRemoteCluster;

      actionFn($ctrl.cluster).then(
        $ctrl.onSaveSuccess,
        evalAJAX.errorMessage
      ).finally(function saveFinally() {
        $ctrl.state.submitting = false;
      });
    }

    /**
     * displays a c-message via ng-click when path is copied to clipboard
     *
     * @method     clipboardMessage
     * @param      {string}  clipboardValue  The clipboard value
     */
    function clipboardMessage(clipboardValue) {
      cMessage.success({
        textKey: 'copiedToClipboard',
        textKeyContext: { item: clipboardValue },
      });
    }

    /**
     * Removes a connection between local and remote clusters.
     *
     * @method   removeRemoteCluster
     * @param    {Object}   cluster   The cluster to remove
     */
    function removeRemoteCluster(cluster) {
      RemoteClusterService.deleteRemoteCluster(cluster).then(onSaveSuccess);
    }

    /**
     * handles on save action.
     * can be overriden by child controller to controler the exit behaviour
     * so keep & use this fn from $ctrl/this.
     *
     * @method   onSaveSuccess
     */
    /**
     * handles on save action.
     *
     * @method   onSaveSuccess
     * @param    {Object}   newRemoteCluster   The new remote cluster config
     */
    function onSaveSuccess(newRemoteCluster) {
      if ($ctrl.state.inModalMode) {
        return $ctrl.modalInstance.close(newRemoteCluster);
      }

      $state.go('remote-clusters');
    }

    /**
     * handles on cancel action for modal
     *
     * @method   onCancel
     */
    function onCancel() {
      if ($ctrl.state.inModalMode) {
        return $ctrl.modalInstance.dismiss();
      }

      $state.go('remote-clusters');
    }

  }

})(angular);
