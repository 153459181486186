// COMPONENT: cSourceViews
// provides a listing of Views with option to select view(s)

;(function(angular, undefined) {
  'use strict';

  var modName = 'C.sourceViews';
  var modDeps = [];
  var componentName = 'cSourceViews';
  var options = {
    bindings: {
      // {
      //   // provide this option to expose checkboxes for selection
      //   canSelect: true
      //   // provide this option if a user can only select one node
      //   singleSelect: true
      //   // provide this option if singleSelect is enabled, it will
      //   // be populated by the ng-model of the selected node's
      //   // radio button
      //   singleSelectNode: undefined
      // }
      options: '=?',
      selectedObjectsCounts: '=?',
      views: '=',
    },
    controller: cSourceViewsCtrlFn,
    templateUrl: 'app/global/c-source-views/c-source-views.html',
  };

  angular
    .module(modName, modDeps)
    .component(componentName, options);


  /**
   * @ngdoc component
   * @name C.views:cViews
   * @function
   *
   * @description
   * component to display paginated listing of views and provide a means of
   * selecting view(s)
   *
   * @example
     <c-source-views
       views="views"
       selected-object-counts="selectedObjectsCounts"
       options="customOptions"></c-source-views>
  */
  cSourceViewsCtrlFn.$inject = ['$rootScope', 'ViewBoxService', 'ViewService', 'evalAJAX'];
  function cSourceViewsCtrlFn($rootScope, ViewBoxService, ViewService, evalAJAX) {

    var ctrl = this;

    ctrl.text = $rootScope.text.cSourceViews;

    /**
     * select a view
     *
     * @method   selectView
     * @param    {Object}       view   View to be selected.
     */
    function selectView(view, permission) {

      // only adjust object count map if this view was previously unselected.
      if (!view._isSelected && ctrl.selectedObjectsCounts) {
        ctrl.selectedObjectsCounts.kView =
          ++ctrl.selectedObjectsCounts.kView || 1;
      }

      view._isSelected = true;

      // Select default permission if permission selection is enabled
      if (ctrl.options.permissionSelect) {
        switch(permission) {
          case 'read':
          case 'write':
            view._permission = permission;
            break;
          default:
            view._permission = 'read';
        }
      }
    }

    /**
     * unselect a view
     *
     * @method   unselectView
     * @param    {Object}         view   View to be unselected.
     */
    function unselectView(view) {

      // only adjust object count map if this view was previously selected.
      if (view._isSelected && ctrl.selectedObjectsCounts) {
        ctrl.selectedObjectsCounts.kView--;
      }

      view._isSelected = false;

      // Reset if permission selection is enabled
      if (ctrl.options.permissionSelect) {
        view._permission = '';
      }
    }

    /**
     * click checkbox
     *
     * @method   clickCheckbox
     * @param    {Object}        view    Clicked view
     */
    ctrl.clickCheckbox = function clickCheckbox(view) {
      if (view._isSelected) {
        unselectView(view);
      } else {
        selectView(view);
      }
    };

    /**
     * Select read/write permission
     *
     * @method   selectPermission
     *
     * @param    {Object}   view         Selected view
     * @param    {string}   permission   Selected permission
     */
    ctrl.selectPermission = function selectPermission(view, permission) {
      selectView(view, permission);
    };

    /**
     * handles toggling of select/unselect all
     */
    ctrl.selectUnselectAll = function selectUnselectAll() {

      ctrl.views.forEach(ctrl.selectAll ? selectView : unselectView);

    };

    /**
     * loads View Boxes from the ViewBoxService
     *
     * @return     {promise}  Promise to resolve request for View Boxes
     */
    function getViewBoxes() {

      var vbParams = {
        maxCount: 1000,
      };

      return ViewBoxService.getOwnViewBoxes(vbParams).then(
        function getViewBoxesSuccess(viewBoxes) {
          ctrl.viewBoxes = viewBoxes;

          // add "All Viewboxes" option
          ctrl.viewBoxes.unshift({
            all: true,
            name: ctrl.text.allViewBoxes,
          });
        },
        evalAJAX.errorMessage
      );
    }

    /**
     * loads Views from the Service
     *
     * @return     {promise}  promise to resolve with the list of views
     */
    function getViews() {

      var viewParams = {
        maxCount: 1000,
        viewBoxIds:
          ctrl.selectedViewBox ? [ctrl.selectedViewBox.id] : undefined,
        matchPartialNames: !!ctrl.nameFilter,
        viewNames: ctrl.nameFilter ? [ctrl.nameFilter] : undefined,
      };

      ctrl.loadingViews = true;

      // reset selections, otherwise selection counts would be inaccurate upon
      // replacement of $ctrl.views[]
      resetSelections();

      return ViewService.getOwnViews(viewParams).then(
        function getViewsSuccess(views) {
          // Using properties and methods of ctrl.views[] to reset/replace it
          // rather than assigning views directly so as to maintain the
          // reference of the external version bound to the ctrl
          views.forEach(function (view) {view._permission = '';});
          ctrl.views.length = 0;
          ctrl.views.push.apply(ctrl.views, views);
        },
        evalAJAX.errorMessage
      ).finally(
        function getViewsFinally() {
          ctrl.loadingViews = false;
        }
      );
    }

    /**
     * resets selections (unselects all things)
     */
    function resetSelections() {
      ctrl.options.singleSelectNode = undefined;
      ctrl.views.forEach(unselectView);
    }

    /**
     * handles View Box filter value change
     */
    ctrl.viewBoxSelected = function viewBoxSelected() {

      // clear View Box filter if user selected the "All View Boxes" option
      ctrl.selectedViewBox = ctrl.selectedViewBox.all ?
        undefined : ctrl.selectedViewBox;

      getViews();

    };

    /**
     * handles changes to the name filter
     */
    ctrl.nameFilterChanged = getViews;

    ctrl.$onInit = function onInit() {

      // TODO: add logic for pre-provided list of Views, based on evaluation
      // of ctrl.views.length. In which Views should be displayed as-is, and
      // API based filters should not be displayed to the user

      getViewBoxes();
      getViews();

    };

  }


})(angular);
