import { Component, Input } from '@angular/core';
import { Incidence } from '@cohesity/api/argus';
import { DcAdapterAccessService } from '@cohesity/data-govern/data-classification';
import { AppStatus, getHighestSensitivityCount } from '@cohesity/data-govern/shared';
import { emptyLinkFn, emptyLinkParamsFn, SkuType } from '@cohesity/iris-core';

@Component({
  selector: 'dg-ar-dc-scan-run-stats',
  templateUrl: './dc-scan-run-stats.component.html',
  styleUrls: ['./dc-scan-run-stats.component.scss']
})
export class DcScanRunStatsComponent {

  /** The incidence details */
  @Input() incidence: Incidence;

  /** The app installation status */
  @Input() appStatus: AppStatus;

  /**
   * The state to use for install app.
   */
  @Input() getInstallAppLinkFn = emptyLinkFn;

  /**
   * Return the install app link params.
   */
  @Input() getInstallAppLinkParamsFn = emptyLinkParamsFn;

  /** Sku type enum */
  readonly SkuTypeEnum = SkuType;


  /** The last sensitivity run details */
  get lastSensitivityRunDetails() {
    return this.incidence?.antiRansomwareDetails?.lastSensitivityRunDetails;
  }

  /** The highest sensitivity count details */
  get highestSensitivityCount() {
    return getHighestSensitivityCount(this.lastSensitivityRunDetails?.patternSensitivityCount || []);
  }

  constructor(public dcAdapterAccessService: DcAdapterAccessService) {}
}
