/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { KmsConfigurations } from '../models/kms-configurations';
import { KmsConfiguration } from '../models/kms-configuration';
import { KmsConfigurationCreateParams } from '../models/kms-configuration-create-params';
import { KmsConfigurationUpdateParams } from '../models/kms-configuration-update-params';
@Injectable({
  providedIn: 'root',
})
class KeyManagementSystemService extends __BaseService {
  static readonly GetKmsConfigurationsPath = '/kms';
  static readonly AddKmsConfigurationPath = '/kms';
  static readonly UpdateKmsConfigurationPath = '/kms/{id}';
  static readonly DeleteKmsConfigPath = '/kms/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get KMS
   *
   * Get key management systems(KMS) configured on the cluster.
   * @param params The `KeyManagementSystemService.GetKmsConfigurationsParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeRpaasKms`: If true, returns KMS that are configured by FortKnox.
   *
   * - `ids`: Ids of KMS configured on the cluster.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetKmsConfigurationsResponse(params: KeyManagementSystemService.GetKmsConfigurationsParams): __Observable<__StrictHttpResponse<KmsConfigurations>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeRpaasKms != null) __params = __params.set('includeRpaasKms', params.includeRpaasKms.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/kms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<KmsConfigurations>;
      })
    );
  }
  /**
   * Get KMS
   *
   * Get key management systems(KMS) configured on the cluster.
   * @param params The `KeyManagementSystemService.GetKmsConfigurationsParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeRpaasKms`: If true, returns KMS that are configured by FortKnox.
   *
   * - `ids`: Ids of KMS configured on the cluster.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetKmsConfigurations(params: KeyManagementSystemService.GetKmsConfigurationsParams): __Observable<KmsConfigurations> {
    return this.GetKmsConfigurationsResponse(params).pipe(
      __map(_r => _r.body as KmsConfigurations)
    );
  }

  /**
   * Add KMS
   *
   * Add a key management system(KMS) to the cluster.
   * @param params The `KeyManagementSystemService.AddKmsConfigurationParams` containing the following parameters:
   *
   * - `body`: Parameters to add KMS on the cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  AddKmsConfigurationResponse(params: KeyManagementSystemService.AddKmsConfigurationParams): __Observable<__StrictHttpResponse<KmsConfiguration>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/kms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<KmsConfiguration>;
      })
    );
  }
  /**
   * Add KMS
   *
   * Add a key management system(KMS) to the cluster.
   * @param params The `KeyManagementSystemService.AddKmsConfigurationParams` containing the following parameters:
   *
   * - `body`: Parameters to add KMS on the cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  AddKmsConfiguration(params: KeyManagementSystemService.AddKmsConfigurationParams): __Observable<KmsConfiguration> {
    return this.AddKmsConfigurationResponse(params).pipe(
      __map(_r => _r.body as KmsConfiguration)
    );
  }

  /**
   * Update KMS
   *
   * Update KMS on the cluster.
   * @param params The `KeyManagementSystemService.UpdateKmsConfigurationParams` containing the following parameters:
   *
   * - `id`: ID of the KMS configured on the cluster.
   *
   * - `body`: Parameters to update KMS on the cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateKmsConfigurationResponse(params: KeyManagementSystemService.UpdateKmsConfigurationParams): __Observable<__StrictHttpResponse<KmsConfiguration>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/kms/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<KmsConfiguration>;
      })
    );
  }
  /**
   * Update KMS
   *
   * Update KMS on the cluster.
   * @param params The `KeyManagementSystemService.UpdateKmsConfigurationParams` containing the following parameters:
   *
   * - `id`: ID of the KMS configured on the cluster.
   *
   * - `body`: Parameters to update KMS on the cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateKmsConfiguration(params: KeyManagementSystemService.UpdateKmsConfigurationParams): __Observable<KmsConfiguration> {
    return this.UpdateKmsConfigurationResponse(params).pipe(
      __map(_r => _r.body as KmsConfiguration)
    );
  }

  /**
   * Delete KMS
   *
   * Delete KMS configued on the cluster.
   * @param params The `KeyManagementSystemService.DeleteKmsConfigParams` containing the following parameters:
   *
   * - `id`: ID of the KMS configured on the cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteKmsConfigResponse(params: KeyManagementSystemService.DeleteKmsConfigParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/kms/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete KMS
   *
   * Delete KMS configued on the cluster.
   * @param params The `KeyManagementSystemService.DeleteKmsConfigParams` containing the following parameters:
   *
   * - `id`: ID of the KMS configured on the cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteKmsConfig(params: KeyManagementSystemService.DeleteKmsConfigParams): __Observable<null> {
    return this.DeleteKmsConfigResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module KeyManagementSystemService {

  /**
   * Parameters for GetKmsConfigurations
   */
  export interface GetKmsConfigurationsParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * If true, returns KMS that are configured by FortKnox.
     */
    includeRpaasKms?: null | boolean;

    /**
     * Ids of KMS configured on the cluster.
     */
    ids?: Array<number>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for AddKmsConfiguration
   */
  export interface AddKmsConfigurationParams {

    /**
     * Parameters to add KMS on the cluster.
     */
    body: KmsConfigurationCreateParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateKmsConfiguration
   */
  export interface UpdateKmsConfigurationParams {

    /**
     * ID of the KMS configured on the cluster.
     */
    id: number;

    /**
     * Parameters to update KMS on the cluster.
     */
    body: KmsConfigurationUpdateParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteKmsConfig
   */
  export interface DeleteKmsConfigParams {

    /**
     * ID of the KMS configured on the cluster.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { KeyManagementSystemService }
