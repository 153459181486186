// Module: Cluster Summary Page
import { getConfigByKey } from '@cohesity/iris-core';

;(function(angular, undefined) {
  'use strict';

  angular.module('C.cluster', ['C.kms'])
    .config(clusterConfigFn)
    .controller('clusterController', clusterControllerFn);

  function clusterConfigFn($stateProvider) {

    $stateProvider
      .state('cluster', {
        url: '^/platform',
        help: 'admin_cluster',
        title: 'Cluster Summary',
        canAccess: getAccessExpressionForTabs(),
        templateUrl: 'app/admin/cluster/cluster.html',
        controller: 'clusterController',
      })
      .state('cluster.summary', {
        url: '^/platform/cluster',
        help: 'admin_cluster_summary',
        title: 'Cluster Summary',
        canAccess: 'CLUSTER_VIEW',
        parentState: 'cluster',
        views: {
          'cluster-content@cluster': {
            templateUrl: 'app/admin/cluster/summary/summary.html',
            controller: 'clusterSummaryController',
          }
        },
        allClustersSupport: {
          singleClusterState: 'cluster.summary',
          allClustersState: 'cluster-upgrades',
        },
      });
  }

  /**
   * tab config
   * heading is translated into respective text by tabs directive.
   * route is the state name
   *
   * @type    {Array}
   */
  var tabs = [
    {
      headingKey: 'summary',
      route: 'cluster.summary',
      default: true,
    },
    {
      headingKey: 'partitions',
      route: 'cluster.partitions',
    },
    {
      headingKey: 'viewBoxes',
      route: 'cluster.viewboxes',
    },
    {
      headingKey: 'nodes',
      route: 'cluster.nodes',
    },
    {
      headingKey: 'certificates',
      route: 'cluster.certificates',
    },
    {
      headingKey: 'keyManagementSystem',
      route: 'kms',
    },
    {
      headingKey: 'syslog',
      route: 'cluster-new.syslog',
    }
  ];

  /**
   * Returns an expression which will indicate if the currently logged in user
   * can access any one of the tabs included in the set. if user can't access
   * any of the tabs, the entire tabbed page should/will be hidden.
   *
   * @method   getAccessExpressionForTabs
   * @return   {String}   The canAccess expression for tabs.
   */
  function getAccessExpressionForTabs() {
    return 'canUserAccessAnyTab(' + JSON.stringify(tabs) + ')';
  }

  function clusterControllerFn($scope, ClusterService, evalAJAX, $state,
    NgStateManagementService, FEATURE_FLAGS, NgIrisContextService) {
    $scope.tabs = tabs;
    $scope.showUpgradePulse = getConfigByKey(NgIrisContextService.irisContext, 'cluster.upgradePulse', true);

    _resetTabs();

    /**
     * Controller initalizer.
     *
     * @method   activate
     */
    function activate() {
      var defaultTab;
      if ($state.current.name === 'cluster') {
        defaultTab = NgStateManagementService.getDefaultTab($scope.tabs);
        $state.go(defaultTab.route);
      }
      getClusterStats();
    }

    /**
     * Gets the cluster statistics.
     *
     * @method     getClusterStats
     */
    function getClusterStats() {
      var params = {
        fetchStats: true
      };

      ClusterService.getClusterInfo(params).catch(evalAJAX.errorMessage);
    }

    /**
     * Helper function to reset tab list based on feature flag conditions.
     */
    function _resetTabs() {
      // Remote Disks feature is enabled if the feature flag is true OR
      var remoteDisksEnabled = FEATURE_FLAGS.remoteDisksModule ||
        // Cluster uses Disaggregated Storage.
        ClusterService.clusterInfo._isDisaggregatedStorage;

      if (!remoteDisksEnabled) {
        var remoteDisksIndex = _.findIndex($scope.tabs, ['headingKey', 'remoteStorage']);
        if (remoteDisksIndex > -1) {
          $scope.tabs.splice(remoteDisksIndex, 1);
        }
      }

      if (!getConfigByKey(NgIrisContextService.irisContext, 'cluster.keyManagementSystem', true)) {
        var idx = _.findIndex($scope.tabs, ['headingKey', 'keyManagementSystem']);
        if (idx > -1) {
          $scope.tabs.splice(idx, 1);
        }
      }

      if (!getConfigByKey(NgIrisContextService.irisContext, 'cluster.storageDomain', true)) {
        var idx = _.findIndex($scope.tabs, ['headingKey', 'viewBoxes']);
        if (idx > -1) {
          $scope.tabs.splice(idx, 1);
        }
      }
    }

    // Initialize the controller.
    activate();
  }

})(angular);
