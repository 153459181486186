import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { AngularMaterialModule, CohesityHelixModule } from '@cohesity/helix';
import {
  PageDialogComponent,
  PageDialogActionsComponent,
  PageDialogContentComponent,
  PageDialogNavComponent
} from './page-dialog.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    PageDialogComponent,
    PageDialogActionsComponent,
    PageDialogNavComponent,
    PageDialogContentComponent,
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    CohesityHelixModule,
    TranslateModule.forChild(),
  ],
  exports: [
    PageDialogComponent,
    PageDialogActionsComponent,
    PageDialogNavComponent,
    PageDialogContentComponent,
  ],
})
export class PageDialogModule { }
