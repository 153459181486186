import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { RangePipe } from './range.pipe';

@NgModule({
  imports: [TranslateModule.forChild()],
  providers: [RangePipe],
  exports: [RangePipe],
  declarations: [RangePipe],
})
export class RangePipeModule {}
