import { ProtectionSourceNode } from '@cohesity/api/v1';
import {
  DataTreeControl,
  DataTreeFilter,
  DataTreeFilterUtils,
  DataTreeNode,
  DataTreeSource,
  DataTreeTransformer,
} from '@cohesity/helix';
import {
  FilterGroup,
  SourceTreeFilters,
  TagAttribute,
  ViewFilterOption,
  ViewFilterType,
} from '@cohesity/iris-source-tree';
import { map } from 'rxjs/operators';
import { Environment, SourceKeys, VMwareEntities } from 'src/app/shared/constants';

import { NgtEnableStatus, NgtInstallStatus } from '../../acropolis-ngt-models';
import { VmSourceDataNode } from './vm-source-data-node';

/**
 * Filter types which are one of the below strings as each of these items are
 * tags.
 */
export const TagTypes = ['kTagCategory', 'kTag', 'kCustomProperty'];

/**
 * Types to expand on for data centers
 */
const dataCenterExpandType = [
  'kVCenter',
  'kStandaloneHost',
  'kStandaloneCluster',
  'kvCloudDirector',
  'kOVirtManager',
  'kSCVMMServer',
];

/**
 * Folder types to expand to for data centers
 */
const dataCenterExpandFolderType = ['kRootFolder'];

/**
 * Types to expand on for clusters
 */
const clusterExpandType = [...dataCenterExpandType, 'kDatacenter', 'kHostGroup'];

/**
 * Folder types to expand to for clusters
 */
const clusterExpandFolderType = [...dataCenterExpandFolderType, 'kHostFolder'];

/**
 * Types to expand on for hosts
 */
const hostsExpandType = [...clusterExpandType, 'kClusterComputeResource', 'kComputeResource', 'kHostCluster'];

/**
 * Folder types to expand to for hosts
 */
const hostsExpandFolderType = [...clusterExpandFolderType, 'kVMFolder'];

/**
 * Types to expand on for vms
 */
const vmExpandType = [...hostsExpandType, 'kHostSystem', 'kFolder', 'kHypervHost', 'kCluster'];

/**
 * Folder types to expand to for vms
 */
const vmExpandFolderType = [...hostsExpandFolderType];

/**
 * Provide view filters for vm sources. This includes options to filter for folder,
 * physical, or flat hierarchies as well as by tags.
 */
export class VmViewFilters {
  /**
   * These are the default view filters.
   */
  defaultViewFilters: ViewFilterOption[];

  /**
   * Default host filters.
   */
  hostFilterGroup: FilterGroup;

  constructor(
    private environment: Environment,
    private filters: SourceTreeFilters<VmSourceDataNode>,
    private treeControl: DataTreeControl<VmSourceDataNode>,
    private dataTransformer: DataTreeTransformer<ProtectionSourceNode>,
    private dataTreeSource: DataTreeSource<ProtectionSourceNode>,
    readonly useSimpleTags: boolean
  ) {
    const vmEnvSupportsTags = ![Environment.kAcropolis, Environment.kKVM].includes(environment);
    this.defaultViewFilters = [
      {
        id: ViewFilterType.Physical,
        tooltip: 'sourceTreePub.tooltips.physicalView',
        filter: this.filterPhysicalChildren,
        icon: 'helix:hierarchy-physical',
        expandToOptions: [
          [Environment.kKVM, Environment.kVMware].includes(environment) && {
            label: 'dataCenter',
            expandNodeFilter: node =>
              dataCenterExpandType.includes(node.type) || dataCenterExpandFolderType.includes(node.folderType),
          },
          [Environment.kVMware, Environment.kHyperV].includes(environment) && {
            label: 'cluster',
            expandNodeFilter: node =>
              clusterExpandType.includes(node.type) || clusterExpandFolderType.includes(node.folderType),
          },
          {
            label: Environment.kVMware === environment ? 'esxiHosts' : 'hosts',
            expandNodeFilter: node =>
              hostsExpandType.includes(node.type) || hostsExpandFolderType.includes(node.folderType),
          },
          {
            label: 'vms',
            expandNodeFilter: node => vmExpandType.includes(node.type) || vmExpandFolderType.includes(node.folderType),
          },
        ].filter(Boolean),
      },
      // Only VMware VM supports folder view.
      this.environment === Environment.kVMware && {
        id: ViewFilterType.Folder,
        tooltip: 'sourceTreePub.tooltips.folderView',
        filter: this.filterFolderChildren,
        icon: 'helix:hierarchy-folder',
      },
      {
        id: ViewFilterType.Flat,
        tooltip: 'sourceTreePub.tooltips.flatVmView',
        filter: this.filterVmNodes,
        icon: 'helix:hierarchy-flat',
      },
      useSimpleTags && vmEnvSupportsTags && {
        id: ViewFilterType.SimpleTag,
        tooltip: 'tags',
        icon: 'helix:hierarchy-tag',
        filter: this.simpleTagFilter,
        isTag: true,
      },
      !useSimpleTags && vmEnvSupportsTags && {
        // The filter for tags is an observable based on the selected tags observable
        // in source tree filters.
        id: ViewFilterType.Tag,
        filter: this.filters.selectedTags$.pipe(map((tags: TagAttribute[]) => this.getTagFilter(tags))),
        tooltip: 'tags',
        icon: 'helix:hierarchy-tag',
        isTag: true,
      },
    ].filter(Boolean);

    this.filters.setViewFilters(this.defaultViewFilters);
  }

  /**
   * Checks if the source is VCD source or not
   *
   * @returns Boolean
   */
  isVCDSource() {
    return this?.dataTreeSource.data[0].protectionSource.vmWareProtectionSource.type === VMwareEntities.kvCloudDirector;
  }

  /**
   * Filter callback function to show the logical/folder tree hierachy of a vcenter.
   */
  filterFolderChildren: DataTreeFilter<any> = (nodes: VmSourceDataNode[]) => {
    const hasCloudDirector = nodes.length && nodes[0].type === 'kvCloudDirector';

    return DataTreeFilterUtils.hierarchyExcludeFilter(
      nodes,
      node =>
        (node.type === 'kFolder' && node.folderType === 'kHostFolder') ||
        // If the root node type is vCloud Director, hide all "kRootFolder" underneath it.
        (hasCloudDirector && node.folderType === 'kRootFolder') ||
        TagTypes.includes(node.type)
    );
  };

  /**
   * Filter callback function to show the phsyical tree hierarchy of a vcenter.
   */
  filterPhysicalChildren: DataTreeFilter<any> = (nodes: VmSourceDataNode[]) => {
    const hasCloudDirector = nodes.length && nodes[0].type === 'kvCloudDirector';

    return DataTreeFilterUtils.hierarchyExcludeFilter(
      nodes,
      node =>
        (node.type === 'kFolder' && node.folderType === 'kVMFolder') ||
        // If the root node type is vCloud Director, hide all "kRootFolder" underneath it.
        (hasCloudDirector && node.folderType === 'kRootFolder') ||
        TagTypes.includes(node.type)
    );
  };

  /**
   * Filter callback to show a flat list of vms from a vcenter.
   */
  filterVMChildren: DataTreeFilter<any> = (nodes: VmSourceDataNode[]) => {
    const seenNodes = new Set<number | string>();
    return nodes
      .filter(node => {
        const matched = (node.envSource as any).type === 'kVirtualMachine';
        if (!matched || seenNodes.has(node.id)) {
          return false;
        }
        seenNodes.add(node.id);
        return true;
      })
      .map(node => this.dataTransformer.transformData(node.data, 0))
      .sort((a: VmSourceDataNode, b: VmSourceDataNode) => a.name.localeCompare(b.name));
  };

  filterVmNodes = (allNodes: VmSourceDataNode[]) => {
    if (!this.isVCDSource()) {
      // this executes for Vcenter sources
      return this.filterVMChildren(allNodes);
    }
    // For VCD filter the leaves within the organization
    const leafNodes = allNodes.filter(node => node.type === 'kOrganization');
    let i = 0;
    while (i < leafNodes.length) {
      if (!leafNodes[i].isLeaf) {
        const leafIds = allNodes.filter(node => node.id === leafNodes[i].id)[0].childIds.flat();
        leafNodes.splice(i, 1);
        allNodes.filter(node => leafIds.includes(Number(node.id))).forEach(node => leafNodes.push(node));
      } else {
        i++;
      }
    }
    return this.filterVMChildren(leafNodes);
  };

  /**
   * This is a simpler version of the tag filter, which does not support combined tag protection.
   * It should output every tag at level 0, with all of the matching virtual machines at level 1.
   *
   * @param   nodes  All nodes in the vm hierarchy.
   * @returns A Tag view of the nodes.
   */
  simpleTagFilter: DataTreeFilter<any> = (nodes: VmSourceDataNode[]) => {
    // Map of tag names to array of nodes
    // Array of All Tags
    // Assumption is that tags are applied _only_ to leaf nodes
    const taggedNodes = new Map<number, VmSourceDataNode[]>();

    // Some source trees have duplicate children in physical and folder hiearchies. Use a set to
    // make sure that we only add them to the child list once.
    const seenChildren = new Map<number, Set<number>>();
    const tagNodes = [];

    // Leaf nodes and tags are each listed  separately in the list. Each VM has a list of tag
    // ids that are associated with it. This loop makes one pass through the tree, finds all
    // of the tag nodes, and uses vm's tagIds to build a map of tag ids to children.
    nodes.forEach(node => {
      if (node.isTag) {
        tagNodes.push(node);
      } else if (node.tagIds) {
        node.tagIds.forEach(tagId => {
          if (!taggedNodes.has(tagId)) {
            taggedNodes.set(tagId, []);
            seenChildren.set(tagId, new Set());
          }
          if (!seenChildren.get(tagId).has(node.protectionSource.id)) {
            taggedNodes.get(tagId).push(node);
            seenChildren.get(tagId).add(node.protectionSource.id);
          }
        });
      }
    });

    // Now that we have the tags and their children, convert them into new data tree nodes at the appropriate
    // levels and sort the children by name.
    const filteredView = [];
    tagNodes.sort((a: VmSourceDataNode, b: VmSourceDataNode) => a.name.localeCompare(b.name));
    tagNodes.forEach(tagNode => {
      const children = taggedNodes.get(tagNode.id) || [];

      filteredView.push(this.dataTransformer.transformData(
        {
          ...tagNode.data,
          protectionSource: {
            ...tagNode.data.protectionSource,

            // Tag ids are stored as strings in the source tree because of the need to combine them
            // on the fly. This filter doesn't allow for that, but we still need this to keep everything
            // consistent with the rest of the tree.
            id: tagNode.id.toString(),
          },
          nodes: children.map(child => child.data)
        },
        0
      ));
      children.sort((a: VmSourceDataNode, b: VmSourceDataNode) => a.name.localeCompare(b.name));
      children.forEach(child => filteredView.push(this.dataTransformer.transformData(child.data, 1)));
    });
    return filteredView;
  };

  /**
   * Create a data tree filter from a set of selected tags
   *
   * @param   tags   The tags to filter by
   * @returs  A filter function that will select the desired tags.
   */
  getTagFilter(tags: TagAttribute[]): DataTreeFilter<any> {
    // If there are no tags specified yet, show a flat list of all vms
    if (!tags || !tags.length) {
      return this.filterVMChildren;
    }
    return (nodes: VmSourceDataNode[]) => {
      const tagIds = tags.map(tag => Number(tag.id));
      const tagNames = tags.map(tag => tag.name);

      const tagNode = this.createTagNode(tagIds, nodes, tagNames);
      const taggedNodes: DataTreeNode<any>[] = [tagNode];
      taggedNodes.push(...tagNode.data.nodes.map(node => this.dataTransformer.transformData(node, 1)));
      this.treeControl.expand(tagNode);

      return taggedNodes;
    };
  }

  /**
   * Create a dummy node to add to the root of the tree. This should have a level of 0 and all
   * of it's children will be at level 1.
   *
   * @param   tagIds    Array of all the tag ids.
   * @param   allNodes  The unfiltered list of tree nodes.
   * @param   tagNames  Array of tag names.
   * @returns A new tag node that can be added to the tree.
   */
  createTagNode(tagIds: number[], allNodes: VmSourceDataNode[], tagNames: string[] = []): VmSourceDataNode {
    if (!tagNames || !tagNames.length && tagIds.length) {
      tagNames = tagIds.map(tagId => allNodes.find(node => node.isTag && node.id === tagId))
        .map(node => node && node.name)
        .filter(name => !!name);
    }

    // Find all of the matching tagged nodes
    const taggedNodes = this.filterTaggedNodes(allNodes, tagIds);

    return this.dataTransformer.transformData(
      {
        protectionSource: {
          // The tag id  may contain ids for multiple tags
          id: tagIds.join('_') as any,

          // Include all of the tags in the name
          name: tagNames.join(', '),
          environment: this.environment as any,
          [SourceKeys[this.environment]]: {
            type: 'kTag',
          },
        },
        nodes: taggedNodes.map(node => node.data),
      },
      0
    ) as VmSourceDataNode;
  }

  /**
   * Filter a list of nodes for all nodes that match all of the tag ids.
   *
   * @param   nodes  Any nodes that are matched by this one.
   * @param   tagIds         One more tags represented by the node.
   * @returns A new tag node that can be added to the tree.
   */
  private filterTaggedNodes(nodes: VmSourceDataNode[], tagIds: number[]): VmSourceDataNode[] {
    const seenNodes = new Set<number | string>();
    return nodes.filter(node => {
      // Make sure that we don't return duplicates.
      if (!node.tagIds || seenNodes.has(node.id)) {
        return false;
      }
      seenNodes.add(node.id);

      // Must match all of the requested tags.
      return tagIds.every(searchTag => node.tagIds.find(tagId => tagId === searchTag));
    });
  }

  /**
   * Filter a list of nodes for all nodes that have installed and enabled NGT(Nutanix Guest Tool).
   *
   * @param   nodes  Any nodes that are matched by this one.
   * @returns A list of nodes that have installed and enabled NGT.
   */
  filterByNgt: DataTreeFilter<any> = (nodes: VmSourceDataNode[]) => nodes.filter(node =>
    node.protectionSource.acropolisProtectionSource.ngtEnableStatus === NgtEnableStatus.kEnabled &&
    node.protectionSource.acropolisProtectionSource.ngtInstallStatus === NgtInstallStatus.kInstalled)
    .map(node => this.dataTransformer.transformData(node.data, 0))
    .sort((a: VmSourceDataNode, b: VmSourceDataNode) => a.name.localeCompare(b.name));
}
