import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { GenerateCertificateSigninModel } from '../generate-certificate-signin-request-interface/generate-certificate-signin-request-interface-model.component';

@Component({
  selector: 'coh-generate-certificate-signin-request-dialog',
  templateUrl: './generate-certificate-signin-request-dialog.component.html',
})
export class GenerateCertificateSigninRequestDialogComponent {

  /**
   * List of possible type to select
   */
  interfaceData: GenerateCertificateSigninModel | null = null;

  /**
   * Constructor
   *
   * @param dialogRef
   */
  constructor(
    private dialogRef: MatDialogRef<GenerateCertificateSigninRequestDialogComponent>,
  ) {}

  /**
   * Set the data of generate certificate signin request interface
   *
   * @param interfaceDataParam
   */
  getGenerateCertificateSigninInterfaceData(interfaceDataParam: GenerateCertificateSigninModel | null) {
    this.interfaceData = interfaceDataParam;
  }

  /**
   * Close dialog and send the form data
   */
  onGenerate() {
    this.dialogRef.close(this.interfaceData);
  }
}
