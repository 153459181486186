<h1 mat-dialog-title>{{ 'deleteNetgroup' | translate }}</h1>

<form cogDataId="delete-netgroup-form" [formGroup]="form">
  <mat-dialog-content class="margin-bottom">

    <div class="padding-bottom"
      [innerHTML]="'deleteNetgroupconfirmation' | translate: {netgroupName: dialogParams.netgroup?.name}">
    </div>

    <mat-form-field>
      <input cogDataId="comfirm-delete-netgroup-input"
        required
        matInput
        placeholder="{{'typeYesToConfirm' | translate}}"
        formControlName="confirmDelete">
        <mat-error id="confirm--required-invalid" *ngIf="form.controls['confirmDelete'].errors?.required">
          {{'errors.required' | translate}}
        </mat-error>
        <mat-error id="confirm--pattern-invalid" *ngIf="form.controls['confirmDelete'].errors?.pattern">
          {{'mustInputYes' | translate}}
        </mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button
      cogCancelButton
      cogDataId="cancel-delete-netgroup-btn"
      mat-dialog-close
      type="button"
      class="margin-right"
      [disabled]="deleting">
      {{ 'cancel' | translate }}
    </button>
    <button mat-flat-button
      cogSubmitButton
      cogDataId="submit-delete-netgroup-btn"
      type="submit"
      color="warn"
      [loading]="deleting"
      [disabled]="deleting || !form.valid"
      (click)="deleteNetgroup()">
      {{ 'delete' | translate }}
    </button>
  </mat-dialog-actions>
</form>
