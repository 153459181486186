<ng-container [ngSwitch]="usage || 'tableCell'">
  <ng-container *ngSwitchCase="'banner'">
    <ng-container *ngTemplateOutlet="banner"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'button'">
    <ng-container *ngTemplateOutlet="button"></ng-container>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ng-container *ngTemplateOutlet="tableCell"></ng-container>
  </ng-container>
</ng-container>

<ng-template #tableCell>
  <div class="flex-row vertical-align"
    *ngIf="scanSupportedEnvs.has(environment);
      else notSupported">
    <ng-container [ngSwitch]="scanStatus || 'notStarted'">
      <ng-container *ngSwitchCase="'scanning'">
        <cog-icon class="margin-right-sm status-running" size="sm" shape="helix:status-in-progress-alt">
        </cog-icon>
        {{ 'running' | translate }}
      </ng-container>
      <ng-container *ngSwitchCase="'notStarted'">
        <span *ngIf="notStartedStatus.has(appStatus)">
          <ng-container *ngIf="hasSku; else noSkuCell">
            {{ 'dg.noScan' | translate }}
          </ng-container>
        </span>
        <a *ngIf="!notStartedStatus.has(appStatus)"
          [irisRouterLink]="getInstallAppLinkFn?.()"
          [params]="getInstallAppLinkParamsFn?.()">
          {{ 'argus.installConnector' | translate }}
        </a>
      </ng-container>
      <ng-container *ngSwitchCase="'error'">
        <cog-icon class="margin-right-sm" size="md" shape="helix:status-error!critical">
        </cog-icon>
        {{ message | naLabel}}
      </ng-container>
      <ng-container *ngSwitchDefault>
       <ng-content></ng-content>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #noSkuCell>
  <a (click)="upgradePlan()">{{ 'upgradeNow' | translate }}</a>
</ng-template>

<ng-template #notSupported>
  {{ 'notSupported' | translate }}
</ng-template>

<ng-template #notSupportedBanner>
  <cog-banner status="info">
    {{ 'notSupported' | translate }}
  </cog-banner>
</ng-template>

<ng-template #banner>
  <div [ngSwitch]="scanStatus || 'notStarted'"  *ngIf="scanSupportedEnvs.has(environment); else notSupportedBanner">
    <cog-banner status="info" *ngSwitchCase="'scanning'">
      {{ 'dg.dc.enum.sensitivityScan.status.scanning' | translate }}
    </cog-banner>
    <cog-banner status="info" *ngSwitchCase="'notStarted'">
      {{ 'dg.dc.enum.sensitivityScan.status.notStarted' | translate }}
    </cog-banner>
    <cog-banner status="critical" *ngSwitchCase="'error'">
      {{ message }}
    </cog-banner>
    <cog-banner status="info" *ngSwitchDefault>
      {{ 'dg.dc.enum.sensitivityScan.status.completed' | translate }}
    </cog-banner>
  </div>
</ng-template>

<ng-template #button>
  <div [ngSwitch]="scanStatus || 'notStarted'">
    <ng-container *ngSwitchCase="'notStarted'">
      <span *ngIf="notStartedStatus.has(appStatus)">
        {{ 'notStarted' | translate }}
      </span>
      <a *ngIf="!notStartedStatus.has(appStatus)"
        [irisRouterLink]="getInstallAppLinkFn?.()"
        [params]="getInstallAppLinkParamsFn?.()">
        {{ 'argus.installConnector' | translate }}
      </a>
    </ng-container>
  </div>
</ng-template>
