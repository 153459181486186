import { CloudProvider, DmaasRegion, TenantRegionInfo } from '@cohesity/api/dms';
import { DataProtectUsage, McmSource } from '@cohesity/api/v2';

export class Region {

  /**
   * Returns whether the current region be deleted.
   */
  get canDelete(): boolean {
    return this.provisioningInProgress || this.isProvisioningFailed || this.noOfSources === 0;
  }

  /**
   * Returns the amount of data processed by cluster from source.
   */
  get dataStored(): number {
    let count = 0;
    this.sources?.forEach(source => {
      const sourceInRegion = source.sourceInfoList.find(infoList =>
        infoList.regionId === this.configuredRegion.regionId);
      sourceInRegion?.protectionStats?.forEach(stats => {
        count += stats.protectedSizeBytes;
      });
    });
    return count;
  }

  /**
   * Returns the encryption type of the region.
   */
  get encryptionType(): string {
    return this.configuredRegion.kmsKeyInfo?.kmsKeyType;
  }

  /**
   * Returns the encryption type of the region.
   */
  get encryptionKey(): string {
    return this.configuredRegion.kmsKeyInfo?.controlPlaneAwsRoleJson;
  }

  /**
   * Returns the name of the region.
   */
  get name(): string {
    return this.region?.name;
  }

  /**
   * Returns the ID of the region.
   */
  get id(): string {
    return this.region?.id;
  }

  /**
   * Returns the number of sources configured in the region.
   */
  get noOfSources(): number {
    let count = 0;
    this.sources?.forEach(source => {
      const sourceInRegion = source.sourceInfoList.find(infoList =>
        infoList.regionId === this.configuredRegion.regionId);
      if (sourceInRegion) {
        count += 1;
      }
    });
    return count;
  }

  /**
   * Returns true if the region is being provisioned.
   */
  get provisioningInProgress(): boolean {
    return ['Waiting', 'InProgress'].includes(this.configuredRegion.provisionStatus?.status);
  }

  /**
   * Returns true if the region provisioning failed.
   */
  get isProvisioningFailed(): boolean {
    return this.configuredRegion.provisionStatus?.status === 'Failed';
  }

  /**
   * Returns true if KMS key validation in progress.
   */
  get isKmsKeyValidationInProgress(): boolean {
    return this.configuredRegion.provisionStatus?.status === 'KmsKeyValidationInProgress';
  }

  /**
   * Returns true if KMS key validation failed.
   */
  get isKmsKeyValidationFailed(): boolean {
    return this.configuredRegion.provisionStatus?.status === 'KmsKeyValidationFailed';
  }

  /**
   * Returns true if the region is marked for deletion.
   */
  get isMarkedForDeletion(): boolean {
    return this.configuredRegion.provisionStatus?.status === 'MarkedForDeletion';
  }

  /**
   * True if region has valid encryption key.
   */
  get hasValidEncryptionKey(): boolean {
    return this.encryptionKey && !this.isMarkedForDeletion && !this.isProvisioningFailed;
  }

  /**
   * Returns true if provision or KMS key validation is in progress.
   */
  get isInProgress(): boolean {
    return this.provisioningInProgress || this.isKmsKeyValidationInProgress;
  }

  /**
   * Returns true if provision or KMS key validation failed.
   */
  get isFailed(): boolean {
    return this.isProvisioningFailed || this.isKmsKeyValidationFailed;
  }

  /**
   * Returns the region configured for the user.
   */
  get region(): DmaasRegion {
    return this.regions.find(r => r.id === this.configuredRegion.regionId);
  }

  /**
   * Returns the status of the provisioning.
   */
  get status(): string {
    return this.configuredRegion.provisionStatus?.status;
  }

  /**
   * Return the current status of the provisioning.
   */
  get statusTooltip(): string {
    return this.configuredRegion.provisionStatus?.message;
  }

  /**
   * Returns the used raw size consumed.
   */
  get usedRawSize(): number {
    return this.usedSize;
  }

  /**
   * Returns the raw backend usage in BETB.
   */
  get backendRawSize(): number {
    return this.backendUsedSize;
  }

  /**
   * Cloud provider type.
   */
  get cloudProvider(): CloudProvider {
    return this.region?.type;
  }

  /**
   * Update usage info for all regions.
   *
   * @param regions  All regions.
   * @param dataProtectUsage  Data Protect usage size.
   */
  static updateUsage(regions: Region[], dataProtectUsage: DataProtectUsage) {
    if (dataProtectUsage) {
      const regionUsage = {};

      for  (const usage of (dataProtectUsage?.frontendUsage?.usage || [])) {
        regionUsage[usage.regionId] = usage.currentUsage;
      }

      regions?.forEach(region =>
        region.usedSize = regionUsage[region?.configuredRegion?.regionId] || 0
      );
    }
  }

  constructor(
    readonly configuredRegion: TenantRegionInfo,
    readonly regions: DmaasRegion[],
    public sources: McmSource[],
    public usedSize = 0,
    readonly backendUsedSize = 0,
  ) {
  }
}
