// Component: licenseUsageWidget

(function(angular, undefined) {
  angular
    .module('C.licenseServer')
    .component('licenseUsageWidget',
      {
        bindings: {
          // widget data
          data: '=',
        },
        templateUrl: 'app/license/license-usage-widget.html',
        controller: licenseUsageWidgetCtrl,
      });

  function licenseUsageWidgetCtrl(_, FEATURE_FLAGS) {
    var $ctrl = this;

    _.assign($ctrl, {
      // component life cycle methods
      $onInit: $onInit,
    });

    /**
     * initialize the component
     *
     * @method   $onInit
     */
    function $onInit() {
      $ctrl.consumptionBasedLicense = FEATURE_FLAGS.consumptionBasedLicensing &&
        !$ctrl.data.licenseTypeCapacity.Term

      // to change considerVm for clouspin based on capacity
      if ($ctrl.data.featureName.toLowerCase() === 'cloudspin'
           && $ctrl.data.capacity === 0) {
          $ctrl.data.considerVm = true;
      }

      // to control color of the usage bar
      $ctrl.noLicense = $ctrl.data.capacity === 0;
      $ctrl.overUsed = $ctrl.data.usage > $ctrl.data.capacity;

      // percentage used round of to 1 decimal place.
      $ctrl.usedPercentage = Math.round(
        ($ctrl.data.usage / $ctrl.data.capacity) * 1000) / 10;

      if ($ctrl.overUsed) {

        // $ctrl.remaining will detarmine the height of red part
        // of usage bar in case of overusage
        $ctrl.remaining =
          (Math.abs($ctrl.usedPercentage - 100) / $ctrl.usedPercentage)
            * 100 + '%';

        // $ctrl.height will determine the position of the usage percentage
        // placed on right side of the usage bar.
        // TODO: Manthan - remove all these hardcoded values
        $ctrl.height =
          $ctrl.usedPercentage < 300 ? ($ctrl.usedPercentage - 200) +
            '%' : 100 + '%';
      } else {
        // $ctrl.remaining will detarmine the height of grey part
        // of usage bar in not overusage case
        $ctrl.remaining = (100 - $ctrl.usedPercentage) + '%';
      }

      if ($ctrl.noLicense || $ctrl.usedPercentage === NaN){
        $ctrl.remaining = '100%';
      }
    }
  }
})(angular);
