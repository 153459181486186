/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Keystones } from '../models/keystones';
import { Keystone } from '../models/keystone';
import { CreateKeystoneRequest } from '../models/create-keystone-request';
import { UpdateKeystoneRequest } from '../models/update-keystone-request';
@Injectable({
  providedIn: 'root',
})
class KeystoneService extends __BaseService {
  static readonly GetKeystonesPath = '/keystones';
  static readonly CreateKeystonePath = '/keystones';
  static readonly GetKeystonesByIdPath = '/keystones/{id}';
  static readonly UpdateKeystonePath = '/keystones/{id}';
  static readonly DeleteKeystonePath = '/keystones/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get Keystones.
   *
   * Get Keystones.
   * @param params The `KeystoneService.GetKeystonesParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `names`: Specifies a list of Keystone names.
   *
   * - `includeTenants`: If true, the response will include Keystones which were created by all tenants which the current user has permission to see. If false, then only Keystones created by the current user will be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetKeystonesResponse(params: KeystoneService.GetKeystonesParams): __Observable<__StrictHttpResponse<Keystones>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.names || []).forEach(val => {if (val != null) __params = __params.append('names', val.toString())});
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/keystones`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Keystones>;
      })
    );
  }
  /**
   * Get Keystones.
   *
   * Get Keystones.
   * @param params The `KeystoneService.GetKeystonesParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `names`: Specifies a list of Keystone names.
   *
   * - `includeTenants`: If true, the response will include Keystones which were created by all tenants which the current user has permission to see. If false, then only Keystones created by the current user will be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetKeystones(params: KeystoneService.GetKeystonesParams): __Observable<Keystones> {
    return this.GetKeystonesResponse(params).pipe(
      __map(_r => _r.body as Keystones)
    );
  }

  /**
   * Create a Keystone configuration.
   *
   * Create a Keystone configuration.
   * @param params The `KeystoneService.CreateKeystoneParams` containing the following parameters:
   *
   * - `body`: Specifies the paremters to create a Keystone configuration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateKeystoneResponse(params: KeystoneService.CreateKeystoneParams): __Observable<__StrictHttpResponse<Keystone>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/keystones`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Keystone>;
      })
    );
  }
  /**
   * Create a Keystone configuration.
   *
   * Create a Keystone configuration.
   * @param params The `KeystoneService.CreateKeystoneParams` containing the following parameters:
   *
   * - `body`: Specifies the paremters to create a Keystone configuration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateKeystone(params: KeystoneService.CreateKeystoneParams): __Observable<Keystone> {
    return this.CreateKeystoneResponse(params).pipe(
      __map(_r => _r.body as Keystone)
    );
  }

  /**
   * Get a Keystone by its id.
   *
   * Get a Keystone by its id.
   * @param params The `KeystoneService.GetKeystonesByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the Keystone id.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetKeystonesByIdResponse(params: KeystoneService.GetKeystonesByIdParams): __Observable<__StrictHttpResponse<Keystone>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/keystones/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Keystone>;
      })
    );
  }
  /**
   * Get a Keystone by its id.
   *
   * Get a Keystone by its id.
   * @param params The `KeystoneService.GetKeystonesByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the Keystone id.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetKeystonesById(params: KeystoneService.GetKeystonesByIdParams): __Observable<Keystone> {
    return this.GetKeystonesByIdResponse(params).pipe(
      __map(_r => _r.body as Keystone)
    );
  }

  /**
   * Update a Keystone configuration.
   *
   * Update a Keystone configuration.
   * @param params The `KeystoneService.UpdateKeystoneParams` containing the following parameters:
   *
   * - `id`: Specifies the Keystone id.
   *
   * - `body`: Specifies the paremters to update a Keystone configuration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateKeystoneResponse(params: KeystoneService.UpdateKeystoneParams): __Observable<__StrictHttpResponse<Keystone>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/keystones/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Keystone>;
      })
    );
  }
  /**
   * Update a Keystone configuration.
   *
   * Update a Keystone configuration.
   * @param params The `KeystoneService.UpdateKeystoneParams` containing the following parameters:
   *
   * - `id`: Specifies the Keystone id.
   *
   * - `body`: Specifies the paremters to update a Keystone configuration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateKeystone(params: KeystoneService.UpdateKeystoneParams): __Observable<Keystone> {
    return this.UpdateKeystoneResponse(params).pipe(
      __map(_r => _r.body as Keystone)
    );
  }

  /**
   * Delete a Keystone configuration.
   *
   * Delete a Keystone configuration.
   * @param params The `KeystoneService.DeleteKeystoneParams` containing the following parameters:
   *
   * - `id`: Specifies the Keystone id.
   *
   * - `adminPassword`: Specifies the password of Keystone administrator.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteKeystoneResponse(params: KeystoneService.DeleteKeystoneParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.adminPassword != null) __headers = __headers.set('adminPassword', params.adminPassword.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/keystones/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a Keystone configuration.
   *
   * Delete a Keystone configuration.
   * @param params The `KeystoneService.DeleteKeystoneParams` containing the following parameters:
   *
   * - `id`: Specifies the Keystone id.
   *
   * - `adminPassword`: Specifies the password of Keystone administrator.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteKeystone(params: KeystoneService.DeleteKeystoneParams): __Observable<null> {
    return this.DeleteKeystoneResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module KeystoneService {

  /**
   * Parameters for GetKeystones
   */
  export interface GetKeystonesParams {

    /**
     * TenantIds contains ids of the tenants for which objects are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies a list of Keystone names.
     */
    names?: Array<string>;

    /**
     * If true, the response will include Keystones which were created by all tenants which the current user has permission to see. If false, then only Keystones created by the current user will be returned.
     */
    includeTenants?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateKeystone
   */
  export interface CreateKeystoneParams {

    /**
     * Specifies the paremters to create a Keystone configuration.
     */
    body: CreateKeystoneRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetKeystonesById
   */
  export interface GetKeystonesByIdParams {

    /**
     * Specifies the Keystone id.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateKeystone
   */
  export interface UpdateKeystoneParams {

    /**
     * Specifies the Keystone id.
     */
    id: number;

    /**
     * Specifies the paremters to update a Keystone configuration.
     */
    body: UpdateKeystoneRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteKeystone
   */
  export interface DeleteKeystoneParams {

    /**
     * Specifies the Keystone id.
     */
    id: number;

    /**
     * Specifies the password of Keystone administrator.
     */
    adminPassword: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { KeystoneService }
