<cog-card-section>
  <cog-statlist horizontal="true" valueSize="md" itemSpacing="sm">
    <cog-statlist-item [label]="'rules' | translate">
      {{ securityRulesData?.plans?.length }}
    </cog-statlist-item>
    <cog-statlist-item class="security-rules" [label]="'protectedClusters' | translate">
      {{ securityRulesData?.totalClusters }}
    </cog-statlist-item>
    <cog-pie-chart
      class="piechart-container"
      [percentage]="{
        value: securityRulesData?.outOfRuleClustersPercentage,
        status: 'critical'}">
    </cog-pie-chart>
    <cog-statlist-item [label]="'outOfRuleClusters' | translate">
      {{ securityRulesData?.outOfRuleClustersPercentage }}
    </cog-statlist-item>
  </cog-statlist>

  <cog-table name="rules-table"
    class="rules-table margin-top"
    *ngIf="securityRulesData?.plans"
    [source]="securityRulesData?.plans">
    <table mat-table matSort matSortActive="score" matSortDirection="asc">
      <!-- name -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef cogDataId="rule-name-header">
          {{ 'name' | translate }}
        </th>
        <td mat-cell *matCellDef="let row;index as i" cogDataId="rule-name-{{i}}">
          {{ row.name }}
        </td>
      </ng-container>

      <!-- strength -->
      <ng-container matColumnDef="strength">
        <th mat-header-cell *matHeaderCellDef cogDataId="rule-strength">
          {{ 'strength' | translate }}
        </th>
        <td mat-cell *matCellDef="let row;index as i" cogDataId="rule-strength-{{i}}">
          <div *ngIf="row.strength || row.totalSecurityCriteriaLength !== null; else noStrengthTemplate">
            <cog-pie-chart
              [percentage]="{
                value: ((row.strength / row.totalSecurityCriteriaLength) * 100),
                status: 'info'}">
            </cog-pie-chart>
            <div class="piechart-text">
            {{ row.strength }}/{{ row.totalSecurityCriteriaLength }}
            </div>
        </div>

          <ng-template #noStrengthTemplate>
            <span>{{'naNotAvailable' | translate}}</span>
          </ng-template>
        </td>
      </ng-container>

      <!-- Protected Cluster -->
      <ng-container matColumnDef="protectedCluster">
        <th mat-header-cell *matHeaderCellDef cogDataId="rule-protected-clusters">
          {{ 'protectedClusters' | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.protectedClusters }}
        </td>
      </ng-container>

      <ng-container matColumnDef="outOfPlanCluster">
        <th mat-header-cell *matHeaderCellDef cogDataId="out-of-rule-clusters">
          {{ 'outOfRuleClusters' | translate }}
        </th>
        <td mat-cell *matCellDef="let row;index as i">
          <div *ngIf="row.outOfPlanClusters || row.clusters !== null; else noOutOfPlanClusterTemplate">
            <cog-pie-chart
              [percentage]="{
                value: ((row?.outOfPlanClusters / row?.clusters?.length) * 100),
                status: 'critical'}">
            </cog-pie-chart>
            <div class="piechart-text" cogDataId="outofplan-clusters-{{i}}">
              {{ row?.outOfPlanClusters }}/{{ row?.clusters?.length }}
            </div>
        </div>
          <ng-template #noOutOfPlanClusterTemplate>
            <span>{{'naNotAvailable' | translate}}</span>
          </ng-template>
        </td>

      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef="let row;index as i">
          <dg-pa-action-menu [menuItems]="actions"
            (menuItemClick)="rowActionsHandler($event, row)">
          </dg-pa-action-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row
        *matRowDef="let row; columns: columns"
        class="row-click"
        (click)="ruleDetailClick.emit(row.id)">
      </tr>

      <!-- Loading Footer -->
      <ng-container matColumnDef="loading">
        <td [attr.colspan]="columns.length" mat-footer-cell *matFooterCellDef>
          <cog-spinner></cog-spinner>
        </td>
      </ng-container>
    </table>
    <mat-paginator></mat-paginator>
  </cog-table>
</cog-card-section>
