/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Role } from '../models/role';
import { RoleCreateParameters } from '../models/role-create-parameters';
import { RoleDeleteParameters } from '../models/role-delete-parameters';
import { RoleUpdateParameters } from '../models/role-update-parameters';
@Injectable({
  providedIn: 'root',
})
class RolesService extends __BaseService {
  static readonly GetRolesPath = '/public/roles';
  static readonly CreateRolePath = '/public/roles';
  static readonly DeleteRolesPath = '/public/roles';
  static readonly UpdateRolePath = '/public/roles/{name}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List the roles defined on the Cohesity Cluster.
   *
   * If the 'name' parameter is not specified, all roles defined on the
   * Cohesity Cluster are returned. In addition, information about each role
   * is returned such as the name, description, assigned privileges, etc.
   * If an exact role name (such as COHESITY_VIEWER) is specified in the
   * 'name' parameter, only information about that single role is returned.
   * @param params The `RolesService.GetRolesParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `name`: Specifies the name of the role.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRolesResponse(params: RolesService.GetRolesParams): __Observable<__StrictHttpResponse<Array<Role>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Role>>;
      })
    );
  }
  /**
   * List the roles defined on the Cohesity Cluster.
   *
   * If the 'name' parameter is not specified, all roles defined on the
   * Cohesity Cluster are returned. In addition, information about each role
   * is returned such as the name, description, assigned privileges, etc.
   * If an exact role name (such as COHESITY_VIEWER) is specified in the
   * 'name' parameter, only information about that single role is returned.
   * @param params The `RolesService.GetRolesParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `name`: Specifies the name of the role.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRoles(params: RolesService.GetRolesParams): __Observable<Array<Role>> {
    return this.GetRolesResponse(params).pipe(
      __map(_r => _r.body as Array<Role>)
    );
  }

  /**
   * Create a new custom role.
   *
   * Returns the new custom role that was created.
   * A custom role is a user-defined role that is created using the REST API,
   * the Cohesity Cluster or the CLI.
   * @param params The `RolesService.CreateRoleParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to create a new custom Role.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateRoleResponse(params: RolesService.CreateRoleParams): __Observable<__StrictHttpResponse<Role>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Role>;
      })
    );
  }
  /**
   * Create a new custom role.
   *
   * Returns the new custom role that was created.
   * A custom role is a user-defined role that is created using the REST API,
   * the Cohesity Cluster or the CLI.
   * @param params The `RolesService.CreateRoleParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to create a new custom Role.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateRole(params: RolesService.CreateRoleParams): __Observable<Role> {
    return this.CreateRoleResponse(params).pipe(
      __map(_r => _r.body as Role)
    );
  }

  /**
   * Delete one or more custom Roles.
   *
   * Returns Success if all the specified Roles are deleted.
   * @param params The `RolesService.DeleteRolesParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to delete one or more Roles.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteRolesResponse(params: RolesService.DeleteRolesParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete one or more custom Roles.
   *
   * Returns Success if all the specified Roles are deleted.
   * @param params The `RolesService.DeleteRolesParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to delete one or more Roles.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteRoles(params: RolesService.DeleteRolesParams): __Observable<null> {
    return this.DeleteRolesResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update a user-defined custom role.
   *
   * For example, you could update the privileges assigned to a Role.
   * Returns the updated role.
   * @param params The `RolesService.UpdateRoleParams` containing the following parameters:
   *
   * - `name`: Specifies the name of the role to update.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update a custom role.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateRoleResponse(params: RolesService.UpdateRoleParams): __Observable<__StrictHttpResponse<Role>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/roles/${encodeURIComponent(params.name)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Role>;
      })
    );
  }
  /**
   * Update a user-defined custom role.
   *
   * For example, you could update the privileges assigned to a Role.
   * Returns the updated role.
   * @param params The `RolesService.UpdateRoleParams` containing the following parameters:
   *
   * - `name`: Specifies the name of the role to update.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update a custom role.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateRole(params: RolesService.UpdateRoleParams): __Observable<Role> {
    return this.UpdateRoleResponse(params).pipe(
      __map(_r => _r.body as Role)
    );
  }
}

module RolesService {

  /**
   * Parameters for GetRoles
   */
  export interface GetRolesParams {

    /**
     * TenantIds contains ids of the tenants for which objects are to be
     * returned.
     */
    tenantIds?: Array<string>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the name of the role.
     */
    name?: string;

    /**
     * AllUnderHierarchy specifies if objects of all the tenants under the
     * hierarchy of the logged in user's organization should be returned.
     */
    allUnderHierarchy?: boolean;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateRole
   */
  export interface CreateRoleParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to create a new custom Role.
     */
    body?: RoleCreateParameters;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteRoles
   */
  export interface DeleteRolesParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to delete one or more Roles.
     */
    body?: RoleDeleteParameters;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateRole
   */
  export interface UpdateRoleParams {

    /**
     * Specifies the name of the role to update.
     */
    name: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to update a custom role.
     */
    body?: RoleUpdateParameters;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { RolesService }
