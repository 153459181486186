import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { AngularMaterialModule } from '@cohesity/helix';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { PaginatorIntlService } from './paginator-intl.service';
import { PaginatorComponent } from './paginator/paginator.component';
import { TableComponent } from './table.component';

@NgModule({
  imports: [CommonModule, FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }), AngularMaterialModule, TranslateModule],
  declarations: [
    PaginatorComponent,
    TableComponent,
  ],
  exports: [
    AngularMaterialModule,
    PaginatorComponent,
    TableComponent,
  ],
  providers: [{
    provide: MatPaginatorIntl, useClass: PaginatorIntlService, deps: [TranslateService]
  }]
})
export class TableModule {}
