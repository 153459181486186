;(function(angular) {
  'use strict';

  angular
    .module('C.licenseServer')
    .component('clusterWiseDetail', {
      bindings: {
        /**
         * @param {object}
         *
         * {
         *  usage:    {integer}  indicates the amount of feature used,
         *  capacity: {integer}  indicates the amount of feature capacity,
         *  clusters: {Array}    a list of clusters ,
         *  lastUpdateTime: {integer} indicates the last time data was synced
         * }
         */

        data: '=?',
      },
      templateUrl: 'app/license/cluster-wise-detail.html',
      controller: ClusteWiseDetailCtrl,
  });

  function ClusteWiseDetailCtrl(_, CHART_COLORS, cUtils, $translate,
    FEATURE_FLAGS) {
    var $ctrl = this;

    _.assign($ctrl, {
      $onInit: $onInit,
    });

    /**
     * initialize the component
     *
     * @method   $onInit
     */
    function $onInit() {
      var clusterData = [];
      $ctrl.data.consumptionBasedLicense =
        FEATURE_FLAGS.consumptionBasedLicensing &&
          !$ctrl.data.licenseTypeCapacity.Term

      $ctrl.data.overUsed = $ctrl.data.usage > $ctrl.data.capacity;
      $ctrl.clusterWiseDetailChart = {
        chartType: 'pie',
        chart: { height: 360, width: 370 },
        colors: Object.values(CHART_COLORS),
        loading: true,
        legend: { enabled: false },
        plotOptions: {
          pie: {
            center: [null, null],
            cursor: null,
            dataLabels: { enabled: false },
            innerSize: [((280 - 30) / 280) * 100, '%'].join(''),
            size: 280,
          },
        },
        series: [{
          data: [],
          name: 'clusters',
          states: { hover: false },
          type: 'pie',
        }],
        title: {
          align: 'center',
          floating: true,
          text: null,
          verticalAlign: 'middle',
          style: {
            fontSize: '15px',
            color: CHART_COLORS.textGrey,
            textAlign: 'center',
          },
          widthAdjust: 0,
          y: 4,
        },
        tooltip: { enabled: true },
      };
      $ctrl.data.clusters.map(function mapClusters(cluster) {
        clusterData.push([cluster.displayValue, cluster.currentUsageGiB]);
      });

      _.merge($ctrl.clusterWiseDetailChart, {
        loading: false,
        title: {
          text: [
            $translate.instant('license.clusterUsageDistribution'), '<b>',

            !$ctrl.data.considerVm ? cUtils.bytesToSize(
              Math.round($ctrl.data.usage) * Math.pow(2, 30)).string :
              $ctrl.data.usage + "&nbsp"+  $translate.instant('vm'), '</b>'
          ].join(''),
        },
        series: [{
          data: clusterData,
        }],
      });
    }
  }
})(angular);
