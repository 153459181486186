// Component: Upgrade App

;(function(angular) {
  'use strict';

  angular.module('C.appsManagement')
    .controller('UpgradeAppCtrl', UpgradeAppCtrlFn)
    .component('upgradeApp', {
      templateUrl: 'app/apps-management/app-upgrade/app-upgrade.html',
      controller: 'UpgradeAppCtrl',
      bindings: {
        /**
         * App information that will be installed on the selected clusters
         * from this component.
         *
         * @type {object}
         */
        app: '=',
      },
      require: {
        cModalHoc: '^^?cModalHoc',
      }
    });

  function UpgradeAppCtrlFn(_, HeliosService, evalAJAX) {
    var $ctrl = this;

    _.assign($ctrl, {
      isLoading: false,
      appUpgradeUrl: '',

      // Component life-cycle methods
      $onInit: $onInit,
    });

    /**
     * Initializes the controller
     *
     * @method   $onInit
     */
    function $onInit() {
      $ctrl.isLoading = true;

      // Redirecting user to helios app upgrade modal to upgrade
      // app to latest version.
      HeliosService.getHeliosConfiguration().then(
        function getHeliosConfigSuccess(response) {
          $ctrl.appUpgradeUrl = [response.heliosEndpoint,
            `/apps-management/apps?id=${$ctrl.app.appId}&upgradeApp=true`].join('');
        }, evalAJAX.errorMessage)
        .finally(function appUpgradeDone() {
          $ctrl.isLoading = false;
        });
    }

  }
})(angular);
