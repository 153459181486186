<!-- Default recovery settings dialog template -->
<h2 mat-dialog-title class="header-bottom-margin">{{ 'defaultObjectRecoverySettings' | translate }}</h2>
<mat-dialog-content>
  <div *ngFor="let setting of defaultRecoverySettings" class="header-bottom-margin">
    <h3 class="header-bottom-margin">{{ setting.environment | environmentType }}</h3>
    <div class="settings-container" *ngFor="let recoverySetting of setting.recoverySettings">
      <span class="label-column">{{ recoverySetting.label }}</span>
      <span>{{ recoverySetting.value }}</span>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button type="button" color="primary" cogDataId="default-settings-close-button" mat-flat-button mat-dialog-close>
    {{ 'close' | translate }}
  </button>
</mat-dialog-actions>
