import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FilterDefDirective, FiltersComponent, ValueFilterSelection } from '@cohesity/helix';
import { IrisContextService } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';

import { FilterGroup } from '../source-tree-filters';

/**
 * This takes a source tree filter group and converts it to a value property filter that that
 * can work with the standard filters component.
 */
@Component({
  selector: 'coh-source-tree-filter',
  templateUrl: './source-tree-filter.component.html',
})
export class SourceTreeFilterComponent implements OnInit, OnDestroy {
  /**
   * Filter group property
   */
  private _filterGroup: FilterGroup;

  /**
   * Set the filter group, and filter out any options that should be hidden.
   */
  @Input() set filterGroup(group: FilterGroup) {
    this._filterGroup = group;

    this.filterValues = (group?.options || [])
      .filter(option => !option.canAccess || option.canAccess(this.irisContext.irisContext))
      .map(option => ({
        label: this.translate.instant(option.label),
        value: option.filter,
      }));
  }

  /**
   * Get the filter group.
   */
  get filterGroup(): FilterGroup {
    return this._filterGroup;
  }

  /**
   * Set to true to prevent a filter from being removed, but without locking it. Use this to force a specific
   * selection.
   */
  @Input() disableClear = false;

  /**
   * Input to hide the filter.
   */
  @Input() hideFilter = false;

  /**
   * The filter values derived from the filter options which can be consumed by the value
   * filter component.
   */
  filterValues: ValueFilterSelection[];

  /**
   * The filter def directive for the filter
   */
  @ViewChild(FilterDefDirective, { static: true }) filterDef: FilterDefDirective;

  constructor(
    protected irisContext: IrisContextService,
    protected filters: FiltersComponent,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    if (this.filters && this.filterDef) {
      this.filters.addFilterDef(this.filterDef);
    }
  }

  ngOnDestroy() {
    if (this.filters && this.filterDef) {
      this.filters.removeFilterDef(this.filterDef);
    }
  }
}
