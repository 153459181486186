import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ColumnConfiguration } from '../list-configuration.model';

/**
 * Individual display of column of List Item Component.
 */
@Component({
  selector: 'coh-list-item-column-layout',
  templateUrl: './list-item-column-layout.component.html',
  styleUrls: ['./list-item-column-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListItemColumnLayoutComponent {
  /** List item row data. Entire row is needed if template is used. */
  @Input() row: any;

  /** List item column value data. */
  @Input() columnValue: any;

  /** List item column configuration. */
  @Input() config: ColumnConfiguration;

  /** Parameters for URL. */
  @Input() params: any;

  /** Icon string. */
  @Input() icon: string;

  /** Data for popover. */
  @Input() popoverData: any;
}
