// Component:  Select or Register External Target (Icebox Vault)

;(function(angular, undefined) {
  'use strict';

  var componentName = 'selectExternalTarget';
  var config = {
    controller: 'SelectExternalTargetCtrl',
    require: { ngModel: '^ngModel' },
    templateUrl: 'app/global/external-target-select/external-target-select.html',
    bindings: {
      /**
       * Specify a default vault by this ID if found in the list.
       *
       * @type   {integer}
       */
      defaultVaultId: '@?',
      name: '@?',

      /**
       * If a custom label is needed, it's ui.json key can be provided via the
       * label attribute. Additionally, passing a value of 'false' via this
       * attribute will hide the label.
       *
       * @type   {string}
       */
      label: '@?',

      /**
       * Optional: Job to orient display around (grouping of targets).
       *
       * @type   {object}
       */
      job: '<?',

      /**
       * Optional: kValue of Target type which is allowed.
       *
       * @type   {string}
       */
      restrictToType: '@?',

      /**
       * Optional: kValue of Target type which is allowed.
       *
       * @type   {string}
       */
      vaultOwnership: '@?',

      /**
       * Optional target ids which are to be disabled from the selection. In
       * cases like replicate/archive now, we can provide multiple targets but
       * they should not be duplicated. So when a target is selected, it needs
       * to be disabled so that it is not selected again.
       *
       * @type   {array}
       */
      disabledTargets: '<?',

      /**
       * Optional: should deleted vaults be hidden in the dropdown
       *
       * @type   {boolean}
       */
      hideDeletedTargets: '<?',
    },
  };

  /**
   * Service-like object for sharing information across instances of this
   * ngComponent.
   *
   * @type   {object}
   * @global
   */
  var SHARED = {
    /**
     * Loading status flag.
     *
     * When one ngComponent updates this value, all instances will reflect the
     * change and updates will apply to all instances.
     *
     * @type   {boolean}
     */
    loading: false,

    /**
     * Cached External Targets.
     *
     * This var is accessible by all instances of this ngComponent, sort of like
     * a dumb service. Each ngComponent has a discreet instance of the
     * controller, but all of those controllers have access to this var. This
     * lets one instance make updates accessible to all other instances that are
     * active in the browser session.
     *
     * @type   {array}
     */
    CACHED_TARGETS: [],
  };

  angular
    .module('C.selectExternalTarget', [])
    .controller('SelectExternalTargetCtrl', SelectExternalTargetControllerFn)
    .component(componentName, config);

  /**
   * @ngdoc component
   * @name C.selectExternalTarget:selectExternalTarget
   * @function
   *
   * @description
   *   Reusable component for selecting or registering a new External Target
   *   (icebox vault) in a modal window. Handles fetching of existing vaults
   *   internally. Totally self contained. Exposes the selected/created vault
   *   on the ngModel of the component.
   *
   *   All ngModel related attribute directives should also work with this one.
   *
   * @example
       <select-external-target
         name="myName"
         required
         ng-disabled="<expression>"
         default-vault-id="1"
         ng-model="myTarget"
         restrict-to-type="kArchival">
       </select-external-target>
   */
  function SelectExternalTargetControllerFn($scope, $attrs, $q,
    $translate, NgExternalTargetServiceApi, ExternalTargetService,
    NgExternalTargetSelectorService, PolicyService) {

    /**
     * Controller reference.
     *
     * @type   {object}
     * @instance
     */
    var $ctrl = this;

    angular.extend($ctrl, {
      SHARED: SHARED,
      targets: SHARED.CACHED_TARGETS,
      $onInit: $onInit,
      grouper: grouper,
      selectTargetOrRegisterNew: selectTargetOrRegisterNew,
    });


    /**
     * Initialize this component.
     *
     * @method   init
     * @instance
     */
    function $onInit() {
      $ctrl.name = $ctrl.name || componentName;
      $ctrl.id = [ componentName, $ctrl.name || Date.now() ].join('-');
      $ctrl.disabledTargets = $ctrl.disabledTargets || [];

      // binding $attrs for disabled state pass through
      $ctrl.$attrs = $attrs;

      SHARED.groupNames = [
        $translate.instant('archivalTargetsInThisJobPolicy'),
        $translate.instant('archivalTargetsNotInThisJobPolicy'),
      ];

      fetchDependencies();

      // When external ng-model changes, update the local model.
      $ctrl.ngModel.$render = function() {
        $ctrl.selectedTarget = $ctrl.ngModel.$viewValue;
      }
    }

    /**
     * Fetches dependencies.
     *
     * @method   fetchDependencies
     */
    function fetchDependencies() {
      var dependeciesPromise = {
        vaults: NgExternalTargetServiceApi.GetExternalTargets({}).toPromise().then(
          res => $q.resolve(res?.externalTargets?.filter(
            target => !$ctrl.restrictToType && !$ctrl.vaultOwnership ||
              ($ctrl.restrictToType && target.purposeType === $ctrl.restrictToType) ||
              ($ctrl.vaultOwnership && target.ownershipContext === $ctrl.vaultOwnership)))),
        policy: $ctrl.job && $ctrl.job.isActive &&
          getPolicy($ctrl.job.policyId),
      };

      SHARED.loading = true;

      $q.all(dependeciesPromise).then(function targetsReceived(resp) {
        if (resp.policy) {
          updateThisJobsArchiveTargetsMap(resp.policy);
          $ctrl.policy = resp.policy;
        }
        updateSharedTargets(resp.vaults);

        /**
         * If a `defaultVaultId` attribute is defined, preselect the vault with
         * the same ID, if present. This only affects this instance of the
         * ngComponent.
         */
        if ($ctrl.defaultVaultId > 0) {
          $ctrl.selectedTarget = resp.vaults.find(function findTarget(target) {
            if (target.id == $ctrl.defaultVaultId) {
              selectTargetOrRegisterNew(target);

              return true;
            }
          });
        }
      })
      .finally(function depsDone() {
        SHARED.loading = false;
      });
    }

    /**
     * Updates a map of archive targets for a configured Job's Policy.
     *
     * @method   updateThisJobsArchiveTargetsMap
     * @param    {object}   [policy]   This Job's Policy
     * @return   {object}   The map
     */
    function updateThisJobsArchiveTargetsMap(policy) {
      if (!policy || !Array.isArray(policy.snapshotArchivalCopyPolicies)) {
        return SHARED.jobTargetsMap = undefined;
      }

      return SHARED.jobTargetsMap = SHARED.jobTargetsMap ||
        policy.snapshotArchivalCopyPolicies.reduce(
          function reducer(map, target) {
            if (!map[target.target.vaultId]) {
              map[target.target.vaultId] = target.target;
            }

            return map;
          },
          {}
        );
    }

    /**
     * If passed a valid object, this will set it in the request object,
     * otherwise it triggers the Register New External Target modal and uses
     * that newly register target.
     *
     * @method   selectTargetOrRegisterNew
     * @global
     * @instance
     * @param    {object}   target   The selected External Target.
     */
    function selectTargetOrRegisterNew(target) {
      if (target && target.id) {
        // Now set the target with the one selected and exit.
        return $ctrl.ngModel.$setViewValue(target);
      }

      SHARED.loading = true;

      // First argument is policyId which is undefined for 'new' case. The
      // second argument indicates which kValue type should be allowed.
      NgExternalTargetSelectorService.registerNew($ctrl.restrictToType, undefined)
        .toPromise().then(
          function promiseResolved(target) {
            // Insert this newly created target into the list.
            $ctrl.targets.unshift(target);

            // Set the selection to this new target.
            selectTargetOrRegisterNew(
              // This also sets this target as selected in the view.
              $ctrl.selectedTarget = target
            );
          },
          function promiseCanceled() {
            // Clear the selected model only after cancel so that in the case
            // that the user has selected a known target, made additional query
            // settings changes, and decided to register a new target, those
            // other settings aren't lost unless the modal is canceled.
            $ctrl.ngModel.$setViewValue(
              $ctrl.selectedTarget = undefined
            );
          }
        )
        .finally(function registerFinally() {
          SHARED.loading = false;
        });
    }

    /**
     * Gets a policy by ID.
     *
     * @method   getPolicy
     * @param    {number}   id   The Policy ID.
     * @return   {object}   Promise to resolve with the Policy.
     */
    function getPolicy(id) {
      return SHARED.$getPolicyPromise = SHARED.$getPolicyPromise ||
        PolicyService.getPolicy(id).then(
          function policySuccess(policy) {
            return $ctrl.policy = policy;
          }
        );
    }

    /**
     * uiSelect Grouping Fn.
     *
     * @method   grouper
     * @param    {object}   vault   The vault to group.
     * @return   {string}   The group name.
     */
    function grouper(vault) {
      if (!vault.id || !SHARED.jobTargetsMap) {
        // In the template, uiSelects `group-filter` will treat this as
        // `undefined`.
        return '';
      }

      return SHARED.jobTargetsMap[vault.id] ?
        SHARED.groupNames[0] :
        SHARED.groupNames[1];
    }

    /**
     * getTargets response handler that replaces the list of targets with the
     * newly received list.
     *
     * @method   updateSharedTargets
     * @global
     * @param    {array}   targets   The list of External Targets
     */
    function updateSharedTargets(targets) {
      if ($ctrl.vaultOwnership) {
        $ctrl.targets = targets;
      } else {
        SHARED.CACHED_TARGETS = targets;
        $ctrl.targets = SHARED.CACHED_TARGETS;
      }

      // Group the targets according to policy
      // TODO: Restrict for Cloud Tier targets if there is a use case for that.
      if ($ctrl.job && $ctrl.policy) {
        $ctrl.targets.forEach(function checkTargetInPolicy(target) {
          target._group = grouper(target);
        });
      }

      return targets;
    }

    $scope.$watch('$ctrl.selectedTarget',
      function evaluateTargets(newValue, oldValue) {
      // Enable this target which is being replaced with new value
      if (oldValue) {
        $ctrl.disabledTargets
          .splice($ctrl.disabledTargets.indexOf(oldValue.id), 1);
      }

      // Disable this target from future selection
      if (newValue) {
        $ctrl.disabledTargets.push(newValue.id);
      }
    });
  }
})(angular);
