import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Api } from '../api.constants';
import { convertToHttpParams } from '../api.utils';
import {
  GetClusterInfoParams,
  McmClusterConnectionStatus,
  McmClusterLoc,
  McmUpgradeInfo,
  UpdateClustersLocationData
} from '../private-models';

/**
 * Mcm cluster service.
 */
@Injectable({
  providedIn: 'root',
})
export class McmClusterServiceApi {
  constructor(private http: HttpClient) {}

  /**
   * Returns all the Helios Cluster registered to the logged in users account.
   *
   * @returns A promise with list of cluster or error.
   */
  getClusters() {
    return this.http.get<McmClusterConnectionStatus[]>(Api.mcm('clusters'));
  }

  /**
   * Get the cluster's connection status with helios.
   *
   * @return     A McmClusterConnectionStatus observable of the result.
   */
  getClustersConnectionStatus(): Observable<McmClusterConnectionStatus[]> {
    return this.http.get<McmClusterConnectionStatus[]>(Api.mcm('clusters/connectionStatus'));
  }

  /**
   * Gets the registered cluster locations.
   *
   * @return     The list of cluster locations as a stream.
   */
  getClusterLocations(): Observable<McmClusterLoc[]> {
    return this.http.get<McmClusterLoc[]>(Api.mcm('clusters/locations'));
  }

  /**
   * Ger upgrade info for mcm mode.
   *
   * @return     The upgrade info result.
   */
  getUpgradeInfo(params: GetClusterInfoParams = {}): Observable<McmUpgradeInfo> {
    return this.http.get<McmUpgradeInfo>(Api.mcm('upgradeInfo'), { params: convertToHttpParams(params) });
  }

  /**
   * Updates the location information for a list of clusters.
   *
   * @param requestBody Cluster identification with location to update
   * @returns True if update was successful
   */
  updateClusterLocations(requestBody?: UpdateClustersLocationData): Observable<boolean> {
    return this.http.put(Api.mcm('clusters/locations'), requestBody).pipe(
      map(() => true),
    );
  }
}
