<cog-settings-list-item [formGroup]="formGroup"
  [invalid]="formControl.invalid || !!advancedSearch?.suggestion?.errorMessage"
  [label]="'Volume Exclusion Settings' | translate">
  <cog-settings-list-item-value-display>
    <p>{{(rawQueryControl.value || 'none') | translate}}</p>
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor [formGroup]="volExclusionFormGroup"
    cogDataId="settings-global-ebs-volume">
    <p>{{'job.settings.excludeEbsVolumes.tags' | translate}}</p>
      <!-- Search bar -->
    <cog-advanced-search
      #advancedSearch
      [placeholder]="'job.settings.excludeEbsVolumes.tagsPlaceholder' | translate"
      [typingPlaceholder]="'job.settings.excludeEbsVolumes.typingPlaceholder' | translate"
      [advancedSearchSuggester]="antlrSuggester"
      [getSuggestions]="getSuggestions"
      [translationResult]="translationResult"
      [suggestedValuesLabel]="'suggestedValues' | translate"
      [valueFormatsLabel]="'valueFormats' | translate"
      [formControl]="rawQueryControl"
      [searchView]="false"
      [isEmptyStringValid]="true">
    </cog-advanced-search>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
