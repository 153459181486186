import { ArgusAppConfig } from './argus-app.module';
import { GaiaAppConfig } from './gaia-app.module';
import { SiteContinuityAppConfig } from './site-continuity-app.module';
import { SizerAppConfig } from './sizer-app.module';

export * from './argus-app.module';
export * from './gaia-app.module';
export * from './site-continuity-app.module';
export * from './sizer-app.module';

export const appConfigs = [
  ArgusAppConfig,
  GaiaAppConfig,
  SiteContinuityAppConfig,
  SizerAppConfig,
];
