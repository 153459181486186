import { ReportSection } from '../iris-reporting.model';

/**
 * This is the layout config for the DR plan activity report.
 */
export const drPlanActivityReport: ReportSection[] = [
  {
    title: 'reporting.details',
    items: [
      {
        id: 'statlistItem',
        componentNames: ['DrPlanDetails'],
        params: {
          items: [
            {
              title: 'reporting.drPlanName',
              translate: true,
              measurement: { valueKey: 'drPlanName', dataType: 'text' },
            },
            {
              title: 'status',
              translate: true,
              measurement: { valueKey: 'status', dataType: 'text' },
            },
            {
              title: 'reporting.failureReason',
              translate: true,
              measurement: { valueKey: 'failureReason', dataType: 'text' },
            },
            {
              title: 'reporting.applicationName',
              translate: true,
              measurement: { valueKey: 'applicationName', dataType: 'text' },
            },
            {
              title: 'reporting.activityId',
              translate: true,
              measurement: { valueKey: 'activityId', dataType: 'text' },
            },
          ],
        },
      },
    ],
  },
  {
    title: 'reporting.resourceProfileDetails',
    items: [
      {
        id: 'statlistItem',
        componentNames: ['ResourceProfileDetails'],
        params: {
          items: [
            {
              title: 'reporting.resourcePool',
              translate: true,
              measurement: { valueKey: 'resourcePool', dataType: 'text' },
            },
            {
              title: 'reporting.datacenter',
              translate: true,
              measurement: { valueKey: 'datacenter', dataType: 'text' },
            },
            {
              title: 'reporting.cluster',
              translate: true,
              measurement: { valueKey: 'cluster', dataType: 'text' },
            },
            {
              title: 'reporting.ipConfiguration',
              translate: true,
              measurement: { valueKey: 'ipConfiguration', dataType: 'text' },
            },
          ],
        },
      },
    ],
  },
  {
    title: 'reporting.vmLevelDetails',
    isFullData: true,
    items: [
      {
        id: 'tableItem',
        componentNames: ['VmLevelDetails'],
        params: {
          columns: [
            {
              title: 'reporting.vmName',
              translate: true,
              key: 'vmName',
              primary: {
                key: 'vmName',
                dataType: 'text',
              },
            },
            {
              title: 'reporting.network',
              translate: true,
              key: 'network',
              primary: {
                key: 'network',
                dataType: 'text',
              },
            },
            {
              title: 'reporting.dataStore',
              translate: true,
              key: 'dataStore',
              primary: {
                key: 'dataStore',
                dataType: 'text',
              },
            },
            {
              title: 'reporting.ipAddress',
              translate: true,
              key: 'ipAddress',
              primary: {
                key: 'ipAddress',
                dataType: 'text',
              },
            },
            {
              title: 'reporting.subnet',
              translate: true,
              key: 'subnet',
              primary: {
                key: 'subnet',
                dataType: 'text',
              },
            },
            {
              title: 'reporting.gateway',
              translate: true,
              key: 'gateway',
              primary: {
                key: 'gateway',
                dataType: 'text',
              },
            },
            {
              title: 'reporting.dns',
              translate: true,
              key: 'dns',
              primary: {
                key: 'dns',
                dataType: 'text',
              },
            },
          ],
        },
      },
    ],
  },
  {
    title: 'reporting.activitySteps',
    isFullData: true,
    items: [
      {
        id: 'tableItem',
        componentNames: ['DrPlanActivitySteps'],
        params: {
          columns: [
            {
              title: '#',
              translate: true,
              key: 'serialNumber',
              primary: {
                key: 'serialNumber',
                dataType: 'text',
              },
            },
            {
              title: 'reporting.steps',
              translate: true,
              key: 'steps',
              primary: {
                key: 'steps',
                dataType: 'text',
              },
            },
            {
              title: 'status',
              translate: true,
              key: 'status',
              primary: {
                key: 'status',
                dataType: 'text',
              },
            },
            {
              title: 'startTime',
              translate: true,
              key: 'startTimeUsecs',
              primary: {
                key: 'startTimeUsecs',
                dataType: 'date',
                renderParams: {
                  usecs: true,
                },
              },
            },
            {
              title: 'reporting.executionTime',
              translate: true,
              key: 'duration',
              primary: {
                key: 'duration',
                dataType: 'duration',
                renderParams: {
                  usecs: true,
                },
              },
            },
          ],
        },
      },
    ],
  },
];
