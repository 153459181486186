import { Pipe, PipeTransform } from '@angular/core';
import { AjsUpgradeService } from 'src/app/core/services/ajs-upgrade.service';
import { UpgradePipe } from './upgrade-pipe';

// TODO(maulik): Port the implementation to Angular
@Pipe({
  name: 'sourceType',
  pure: true,
})
export class SourceTypePipe extends UpgradePipe implements PipeTransform {
  constructor(upgradeService: AjsUpgradeService) {
    super(upgradeService, 'sourceType');
  }
}
