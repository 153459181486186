import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { HelixIntlService } from '../../../helix-intl.service';

/**
 * Shows the application branding. It is often intended to link back to the home
 * page of the application. It can optionally display a logo and a title, or it
 * can display whatever is passed via ng content. It can be created as an
 * element selector or as an attribute selector.
 *
 * @example
 * <cog-app-branding>...Custom Logo and Text...</cog-app-branding>
 *
 * @example
 * <a cogAppBranding routerLink="/" [logo]="true" title="Helix"></a>
 */
@Component({
  selector: 'cog-app-branding, [cogAppBranding]',
  templateUrl: './app-branding.component.html',
  styleUrls: ['./app-branding.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppBrandingComponent {
  /**
   * Adds a class to the host so it doesn't inherit global anchor styling.
   */
  @HostBinding('class.cog-app-branding') hostClass = true;

  /**
   * If true, this will display the cohesity logo as part of the branding. In
   * order for this to work, the application must be configured to host the
   * helix assets under '/helix'.
   */
  @Input() logo = false;

  /**
   * If set, display the application title.
   */
  @Input() title = '';

  constructor(public intl: HelixIntlService) { }
}
