import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { LinuxSupportUserBashShellAccessResult } from '@cohesity/api/v1';
import { SnackBarService } from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';
import { filter, finalize } from 'rxjs/operators';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { DialogService } from 'src/app/core/services';

import { LinuxUserService, SUPPORT_USER } from '../../linux-user.service';
import { GetSupportUserAccessInfoResp } from '../../models';
import { UpdateLinuxUserPasswordDialogComponent } from '../update-password-dialog/update-password-dialog.component';

/**
 * Support user management component to change password and get ssh access token.
 *
 * @example
 *   <coh-support-user-management></coh-support-user-management>
 */
@Component({
  selector: 'coh-support-user-management',
  templateUrl: './support-user.component.html',
})
export class SupportUserComponent extends AutoDestroyable {

  constructor(
    private dialogService: DialogService,
    private evalAjaxService: AjaxHandlerService,
    private linuxUserService: LinuxUserService,
    private snackBarService: SnackBarService,
    private translate: TranslateService) {
    super();
    this.getSupportUserInfo();
  }

  /**
   *  Username of support user.
   */
  readonly supportUserName = SUPPORT_USER;

  /**
   * Ssh token for support user.
   */
  sshToken: string;

  /**
   * True if support password is already set.
   */
  isPasswordSet: boolean;

  /**
   * True if support user info is loading.
   */
  isSupportUserInfoLoading: boolean;

  getSupportUserInfo() {
    this.isSupportUserInfoLoading = true;
    this.linuxUserService.getSupportUserAccessInfo()
      .pipe(this.untilDestroy(),
      finalize(() => {
        this.isSupportUserInfoLoading = false;
      }))
      .subscribe((result: GetSupportUserAccessInfoResp) => {
        this.isPasswordSet = result.isPasswordSet;
      }, this.evalAjaxService.errorMessage);
  }

  /**
   * Open update password dialog.
   */
  openUpdatePassword() {
    this.dialogService.showDialog(UpdateLinuxUserPasswordDialogComponent,
      {
        username: this.supportUserName,
        isPasswordSet: this.isPasswordSet
      }
    ).pipe(this.untilDestroy(), filter(result => !!result)).subscribe(
      (result) => {
        if (result === 'success') {
          this.isPasswordSet = true;
          this.snackBarService.open(
            this.translate.instant('passwordChangedSuccessfully'), 'success');
        }
      }, this.evalAjaxService.errorMessage
    );
  }

  /**
   * Generate private key with default expiry time of 24 hours.
   */
  generateDefaultExpiryPrivateKey() {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    this.linuxUserService.generateBashAccessSshKey()
      .pipe(this.untilDestroy())
      .subscribe((resp: LinuxSupportUserBashShellAccessResult) => {
        this.sshToken = resp.supportUserToken;
      }, (err: HttpErrorResponse) => this.snackBarService.open(err.error.message, 'error'));
  }
}
