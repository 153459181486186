import { Type } from '@angular/core';

import { BaseIntegrationConfig } from './base-integration-config';
import { ZscalerDataProtectionConfig } from './dlp';
import { CyeraConfig, DaseraConfig, NormalyzeIntegrationConfig, WizIntegrationConfig } from './dspm';
import { CrowdStrikeLogScaleIntegrationConfig } from './siem/crowdstrike-logscale-config';
import {
  CiscoSecureXConfig,
  MicrosoftSentinelConfig,
  PaloAltoNetworksCortexConfig,
  ServiceNowSecurityOrchestratorConfig,
} from './soar';
import { TiCrowdStrikeIntegrationConfig } from './ti';

/**
 * Holds all the integration config service
 */
export const IntegrationConfigs: Type<BaseIntegrationConfig>[] = [
  CiscoSecureXConfig,
  CrowdStrikeLogScaleIntegrationConfig,
  CyeraConfig,
  DaseraConfig,
  MicrosoftSentinelConfig,
  NormalyzeIntegrationConfig,
  PaloAltoNetworksCortexConfig,
  ServiceNowSecurityOrchestratorConfig,
  TiCrowdStrikeIntegrationConfig,
  WizIntegrationConfig,
  ZscalerDataProtectionConfig,
];
