import { ProtectionSourceNode } from '@cohesity/api/v1';
import {
  DataTreeFilter,
  DataTreeTransformer,
} from '@cohesity/helix';
import {
  SourceTreeFilters,
  ViewFilterOption,
  ViewFilterType,
} from '@cohesity/iris-source-tree';

import { PureSourceDataNode } from './pure-source-data-node';
import { PureProtectionTypes } from './pure.constants';

/**
 * Provide view filters for pure sources. This includes options to filter for
 * physical, or flat hierarchies.
 */
export class PureViewFilters {
  /**
   * These are the default view filters.
   */
  defaultViewFilters: ViewFilterOption[];

  constructor(
    private filters: SourceTreeFilters<PureSourceDataNode>,
    private dataTransformer: DataTreeTransformer<ProtectionSourceNode>,
  ) {
    this.defaultViewFilters = [
      {
        id: ViewFilterType.Physical,
        tooltip: 'sourceTreePub.tooltips.ppgView',
        filter: this.filterPureProtectionGroups,
        icon: 'helix:hierarchy-physical',
      },
      {
        id: ViewFilterType.Flat,
        tooltip: 'volumeView',
        filter: this.filterIndependentVolumes,
        icon: 'helix:hierarchy-flat',
      },
    ];

    this.filters.setViewFilters(this.defaultViewFilters);
  }

  /**
   * Filter callback function to show the physical tree hierarchy of a pure cluster.
   */
  filterPureProtectionGroups: DataTreeFilter<any> = (nodes: PureSourceDataNode[]) => {
    if (!nodes?.length) {
      return [];
    }
    const ppgChildNodeIDs = new Set<number | string>();
    const ppgNodes = [];

    // get the id of all the children of all the ppgNodes.
    // maintain a list of ppgNodes.
    nodes.forEach(node => {
      if ((node.envSource as any).type === PureProtectionTypes.kPureProtectionGroup &&
        Boolean(node.childIds.length)) {
        for (const childId of node.childIds) {
          ppgChildNodeIDs.add(childId);
        }
        ppgNodes.push(node);
      }
    });

    const ppgChildNodesMap = new Map<string | number, PureSourceDataNode>();

    // create a mapping of a child (volume) id and itself.
    nodes.forEach(node => {
      if (ppgChildNodeIDs.has(node.id)) {
        ppgChildNodesMap.set(node.id, node);
      }
    });

    // push the storage array node.
    const filteredView = [this.dataTransformer.transformData(nodes[0]?.data, 0)];

    // Filtered view expects all nodes to be run through the transformData function and that all
    // children of a ppgNode, must immediately succeed it in the list.
    ppgNodes.forEach(ppgNode => {
      filteredView.push(this.dataTransformer.transformData(ppgNode?.data, 1));
      for (const childId of ppgNode.childIds) {
        if (ppgChildNodesMap.get(childId)) {
          filteredView.push(this.dataTransformer.transformData(
            ppgChildNodesMap.get(childId)?.data, 2));
        }
      }
    });

    return filteredView;
  };

  /**
   * Filter callback to show a flat list of volumes in a pure cluster
   */
  filterIndependentVolumes: DataTreeFilter<any> = (nodes: PureSourceDataNode[]) => {
    const seenNodes = new Set<number | string>();
    return nodes
      .filter(node => {
        const matched = (node.envSource as any).type === PureProtectionTypes.kVolume;
        if (!matched || seenNodes.has(node.id)) {
          return false;
        }
        seenNodes.add(node.id);
        return true;
      })
      .map(node => this.dataTransformer.transformData(node.data, 0))
      .sort((a: PureSourceDataNode, b: PureSourceDataNode) => a.name.localeCompare(b.name));
  };
}
