/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DownloadAgentRequestParams } from '../models/download-agent-request-params';
import { AgentUpgradeTaskStates } from '../models/agent-upgrade-task-states';
import { AgentUpgradeTaskState } from '../models/agent-upgrade-task-state';
import { CreateUpgradeTaskRequest } from '../models/create-upgrade-task-request';
import { AgentUpgradeTaskActionObject } from '../models/agent-upgrade-task-action-object';
import { AgentUpgradeTaskActionRequest } from '../models/agent-upgrade-task-action-request';
import { McmAgentImagesResponse } from '../models/mcm-agent-images-response';
@Injectable({
  providedIn: 'root',
})
class AgentService extends __BaseService {
  static readonly DownloadAgentPath = '/data-protect/agents/download';
  static readonly GetUpgradeTasksPath = '/data-protect/agents/upgrade-tasks';
  static readonly CreateUpgradeTaskPath = '/data-protect/agents/upgrade-tasks';
  static readonly PerformActionOnAgentUpgradeTaskPath = '/data-protect/agents/upgrade-tasks/actions';
  static readonly McmGetAgentImageDetailsPath = '/mcm/data-protect/agents/images';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Download agent
   *
   * Download agent for different hosts.
   * @param params The `AgentService.DownloadAgentParams` containing the following parameters:
   *
   * - `body`: Specifies agent details.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  DownloadAgentResponse(params: AgentService.DownloadAgentParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/agents/download`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Download agent
   *
   * Download agent for different hosts.
   * @param params The `AgentService.DownloadAgentParams` containing the following parameters:
   *
   * - `body`: Specifies agent details.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  DownloadAgent(params: AgentService.DownloadAgentParams): __Observable<Blob> {
    return this.DownloadAgentResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * Get upgrade tasks
   *
   * Get the list of agent upgrade tasks.
   * @param params The `AgentService.GetUpgradeTasksParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: If true, the response will include upgrade tasks which were created by all tenants which the current user has permission to see. If false, then only upgrade tasks created by the current user will be returned.
   *
   * - `ids`: Specifies IDs of tasks to be fetched.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetUpgradeTasksResponse(params: AgentService.GetUpgradeTasksParams): __Observable<__StrictHttpResponse<AgentUpgradeTaskStates>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/agents/upgrade-tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AgentUpgradeTaskStates>;
      })
    );
  }
  /**
   * Get upgrade tasks
   *
   * Get the list of agent upgrade tasks.
   * @param params The `AgentService.GetUpgradeTasksParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: If true, the response will include upgrade tasks which were created by all tenants which the current user has permission to see. If false, then only upgrade tasks created by the current user will be returned.
   *
   * - `ids`: Specifies IDs of tasks to be fetched.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetUpgradeTasks(params: AgentService.GetUpgradeTasksParams): __Observable<AgentUpgradeTaskStates> {
    return this.GetUpgradeTasksResponse(params).pipe(
      __map(_r => _r.body as AgentUpgradeTaskStates)
    );
  }

  /**
   * Create an upgrade task
   *
   * Create a schedule based agent upgrade task.
   * @param params The `AgentService.CreateUpgradeTaskParams` containing the following parameters:
   *
   * - `body`: Specifies parameters to create a schedule based agent upgrade task.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateUpgradeTaskResponse(params: AgentService.CreateUpgradeTaskParams): __Observable<__StrictHttpResponse<AgentUpgradeTaskState>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/agents/upgrade-tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AgentUpgradeTaskState>;
      })
    );
  }
  /**
   * Create an upgrade task
   *
   * Create a schedule based agent upgrade task.
   * @param params The `AgentService.CreateUpgradeTaskParams` containing the following parameters:
   *
   * - `body`: Specifies parameters to create a schedule based agent upgrade task.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateUpgradeTask(params: AgentService.CreateUpgradeTaskParams): __Observable<AgentUpgradeTaskState> {
    return this.CreateUpgradeTaskResponse(params).pipe(
      __map(_r => _r.body as AgentUpgradeTaskState)
    );
  }

  /**
   * Perform action on an upgrade task.
   *
   * Perform actions on an agent upgrade task.
   * @param params The `AgentService.PerformActionOnAgentUpgradeTaskParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to perform an action on an agent upgrade task.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  PerformActionOnAgentUpgradeTaskResponse(params: AgentService.PerformActionOnAgentUpgradeTaskParams): __Observable<__StrictHttpResponse<AgentUpgradeTaskActionObject>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/agents/upgrade-tasks/actions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AgentUpgradeTaskActionObject>;
      })
    );
  }
  /**
   * Perform action on an upgrade task.
   *
   * Perform actions on an agent upgrade task.
   * @param params The `AgentService.PerformActionOnAgentUpgradeTaskParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to perform an action on an agent upgrade task.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  PerformActionOnAgentUpgradeTask(params: AgentService.PerformActionOnAgentUpgradeTaskParams): __Observable<AgentUpgradeTaskActionObject> {
    return this.PerformActionOnAgentUpgradeTaskResponse(params).pipe(
      __map(_r => _r.body as AgentUpgradeTaskActionObject)
    );
  }

  /**
   * Get agent images details.
   *
   * Get agent information on Helios.
   * @param params The `AgentService.McmGetAgentImageDetailsParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `platform`: Specifies a platform for which agent information need to be fetched.
   *
   * - `packageType`: Specifies a package type for which agent information need to be fetched.
   *
   * @return Success
   */
  McmGetAgentImageDetailsResponse(params: AgentService.McmGetAgentImageDetailsParams): __Observable<__StrictHttpResponse<McmAgentImagesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.platform != null) __params = __params.set('platform', params.platform.toString());
    if (params.packageType != null) __params = __params.set('packageType', params.packageType.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/data-protect/agents/images`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmAgentImagesResponse>;
      })
    );
  }
  /**
   * Get agent images details.
   *
   * Get agent information on Helios.
   * @param params The `AgentService.McmGetAgentImageDetailsParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `platform`: Specifies a platform for which agent information need to be fetched.
   *
   * - `packageType`: Specifies a package type for which agent information need to be fetched.
   *
   * @return Success
   */
  McmGetAgentImageDetails(params: AgentService.McmGetAgentImageDetailsParams): __Observable<McmAgentImagesResponse> {
    return this.McmGetAgentImageDetailsResponse(params).pipe(
      __map(_r => _r.body as McmAgentImagesResponse)
    );
  }
}

module AgentService {

  /**
   * Parameters for DownloadAgent
   */
  export interface DownloadAgentParams {

    /**
     * Specifies agent details.
     */
    body: DownloadAgentRequestParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetUpgradeTasks
   */
  export interface GetUpgradeTasksParams {

    /**
     * TenantIds contains ids of the tenants for which objects are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * If true, the response will include upgrade tasks which were created by all tenants which the current user has permission to see. If false, then only upgrade tasks created by the current user will be returned.
     */
    includeTenants?: boolean;

    /**
     * Specifies IDs of tasks to be fetched.
     */
    ids?: Array<number>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateUpgradeTask
   */
  export interface CreateUpgradeTaskParams {

    /**
     * Specifies parameters to create a schedule based agent upgrade task.
     */
    body: CreateUpgradeTaskRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for PerformActionOnAgentUpgradeTask
   */
  export interface PerformActionOnAgentUpgradeTaskParams {

    /**
     * Specifies the parameters to perform an action on an agent upgrade task.
     */
    body: AgentUpgradeTaskActionRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for McmGetAgentImageDetails
   */
  export interface McmGetAgentImageDetailsParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies a platform for which agent information need to be fetched.
     */
    platform?: 'Windows' | 'Linux' | 'Solaris' | 'HPUX' | 'VOS';

    /**
     * Specifies a package type for which agent information need to be fetched.
     */
    packageType?: 'Script' | 'RPM' | 'SuseRPM' | 'DEB';
  }
}

export { AgentService }
