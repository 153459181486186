import { isHeliosTenantUser, isMcm } from './common-utils';
import { isDGaaSUser } from './dgaas-utils';
import { isDmsUser } from './dms-utils';
import { isDraasUser } from './draas-utils';
import { isGaiaUser } from './gaia-utils';
import { IrisContext } from './iris-context.model';
import { isRpaasUser } from './rpaas-utils';

/**
 * Determines if the user is currently impersonating a tenant.
 *
 * @param irisContext The current iris context.
 * @returns true is user is impersonated via different tenant.
 */
export function isImpersonated(irisContext: IrisContext): boolean {
  return Boolean(irisContext.impersonatedTenant?.tenantId);
}

/**
 * Determines if viewing as tenant user either by impersonating a tenant
 * user or tenant user is logged in.
 *
 * @param irisContext The current iris context.
 * @returns  True if tenant user, False otherwise.
 */
export function isTenantUser(irisContext: IrisContext): boolean {
  return Boolean(irisContext.user && irisContext.user.tenantId);
}

/**
 * Determines if logged in user is restricted user or not.
 *
 * @param irisContext The current iris context.
 * @returns  True if restricted user, False otherwise.
 */
export function isRestrictedUser(irisContext: IrisContext): boolean {
  return Boolean(irisContext.user && irisContext.user.restricted);
}

/**
 * Returns if current user is local admin.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if user is local admin.
 */
export function isLocalAdminUser(irisContext: IrisContext): boolean {
  return irisContext.user && irisContext.user.username === 'admin' && irisContext.user.domain === 'LOCAL';
}

/**
 * Returns whether the user is a SaaS service user.
 *
 * @param irisContext The current iris context.
 * @returns boolean value indicating whether the user is a SaaS service user.
 */
export function isSaasServiceUser(irisContext: IrisContext): boolean {
  return isDmsUser(irisContext) ||
    isDraasUser(irisContext) ||
    isRpaasUser(irisContext) ||
    isDGaaSUser(irisContext) ||
    isGaiaUser(irisContext);
}

/**
 * Indicates if the current user has both DMaaS and Helios managed clusters.
 * This is not applicable to DRaaS users, since they will always have access
 * to clusters, and hence always be "hybrid".
 *
 * @param irisContext The current iris context.
 * @returns true if user is dmaas and helios user, false otherwise.
 */
export function isHybridUser(irisContext: IrisContext): boolean {
  return isDraasUser(irisContext) || (isDmsUser(irisContext) && Boolean(irisContext.user.clusterIdentifiers?.length));
}

/**
 * Indicates if the user has any cluster related scopes regardless of current scope.
 *
 * @param irisContext The current iris context.
 * @returns true if user has any cluster scopes, false otherwise.
 */
export function hasClusters(irisContext: IrisContext): boolean {
  let clusterIndentifiers = irisContext.user?.clusterIdentifiers || [];

  // Check for helios tenant user and reassign clusterIndentifiers from profiles
  if (isHeliosTenantUser(irisContext)) {
    const { profiles } = irisContext.user || {};
    clusterIndentifiers = profiles?.length ? profiles[0].clusterIdentifiers : [];
  }
  return Boolean((clusterIndentifiers || []).length);
}

/**
 * Determines whether the user is Floating user.
 * Floating user is an SSO user without role and s/he is not
 * allowed to save state in the backend.
 *
 * @returns  True if user is floating user
 */
export function isFloatingUser(irisContext: IrisContext) {
  return irisContext.user?.idpUserInfo?.isFloatingUser || false;
}

/**
 * Returns true/false based on whether the user context is fully
 * authenticated by the backend.
 *
 * @param irisContext The current iris context.
 * @returns True if user has privilege
 */
export function isAuthenticatedUser(irisContext: IrisContext): boolean {
  return Boolean(irisContext?.user?.privilegeIds?.length);
}

/**
 * Determines user login state
 *
 * @param ctx Iris context
 * @returns true if user logged in
 */
export function isLoggedIn(ctx: IrisContext): boolean {
  // For cluster, to check whether user have proper access
  const hasUserAccess = Boolean(ctx?.user?.roles?.length);

  // In mcm, if the two-step verification is not complete. A temporary
  // user session is created without any privileges.
  return isMcm(ctx) ? isAuthenticatedUser(ctx) : hasUserAccess;
}

/**
 * Determines if user account is locked
 *
 * @param ctx Iris context
 * @returns true if user's account on locked state
 */
export function isAccountLocked(ctx: IrisContext): boolean {
  return Boolean(ctx?.user?.isAccountLocked);
}

/**
 * Determines if user is on password change page
 *
 * @param ctx Iris context
 * @returns true if user is on password change page
 */
export function userOnForcePasswordChange(ctx: IrisContext): boolean {
  return Boolean(ctx?.user?.forcePasswordChange);
}

/**
 * Determines if user is fully authenticated and redirected from
 * partially authenticated screens.
 *
 * @param ctx Iris context
 * @returns true if user is fully authenticated
 */
export function isFullyAuthenticated(ctx: IrisContext): boolean {
  return isLoggedIn(ctx) && !userOnForcePasswordChange(ctx) && !isAccountLocked(ctx);
}

/**
 * Use hasPrivilege instead. This was meant to be used by cohIrisAccess only.
 * Returns function to check user privilege.
 *
 * @param priv user privilege.
 * @returns IrisContextAccessFn function to check specified privilege.
 */
export const getUserPrivilegeCheck = (priv: string) => (ctx: IrisContext) => !!ctx.privs?.[priv];

/**
 * Returns true if user has the privilege.
 *
 * @param ctx   Iris context.
 * @param priv  User privilege.
 * @returns True if user has the privilege.
 */
export const hasPrivilege = (ctx: IrisContext, priv: string) => !!ctx.privs?.[priv];
