import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionUser } from '@cohesity/api/v1';
import { Observable } from 'rxjs';

import { Api } from '../api.constants';
import { McmUserUpdatePassword } from '../private-models';

/**
 * Mcm principals service.
 */
@Injectable({
  providedIn: 'root',
})
export class McmPrincipalsServiceApi {
  constructor(private http: HttpClient) {}

  /**
   * Get the information of the logged in mcm user.
   *
   * @returns An observable stream of the result.
   */
  GetSessionUser(): Observable<SessionUser> {
    return this.http.get<SessionUser>(Api.private('mcm/userInfo'));
  }

  /**
   * Update MCM (Helios_OnPrem) local user password
   *
   * @param userSid user sid
   * @param passwordDetails current and new password values
   * @returns observable for response
   */
  UpdateUserPassword(userSid: string, passwordDetails: McmUserUpdatePassword): Observable<void> {
    return this.http.put<void>(Api.mcm(`users/${userSid}/updatePassword`), passwordDetails);
  }
}
