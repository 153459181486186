import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ScanHealth } from '@cohesity/api/argus';
import { isFailedState } from '../utils';

@Component({
  selector: 'dg-scan-impact-status',
  templateUrl: './scan-impact-status.component.html',
  styleUrls: ['./scan-impact-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScanImpactStatusComponent {

  /** Scan Health Status */
  @Input() health: ScanHealth;

  readonly isFailedState = isFailedState;
}
