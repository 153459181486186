/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DataSourceConnectorList } from '../models/data-source-connector-list';
import { DataSourceConnector } from '../models/data-source-connector';
import { UpdateDataSourceConnectorRequest } from '../models/update-data-source-connector-request';
@Injectable({
  providedIn: 'root',
})
class DataSourceConnectorService extends __BaseService {
  static readonly GetDataSourceConnectorPath = '/data-source-connectors';
  static readonly UpdateDataSourceConnectorPath = '/data-source-connectors/{connectorId}';
  static readonly DeleteDataSourceConnectorPath = '/data-source-connectors/{connectorId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get data-source connectors
   *
   * Gets all specified data-source connectors.
   * @param params The `DataSourceConnectorService.GetDataSourceConnectorParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the ID of the tenant for which the connectors are to be fetched.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `connectorNames`: Specifies the names of the connectors which are to be fetched.
   *
   * - `connectorIds`: Specifies the unique IDs of the connectors which are to be fetched.
   *
   * - `connectionId`: Specifies the ID of the connection, connectors belonging to which are to be fetched.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetDataSourceConnectorResponse(params: DataSourceConnectorService.GetDataSourceConnectorParams): __Observable<__StrictHttpResponse<DataSourceConnectorList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.connectorNames || []).forEach(val => {if (val != null) __params = __params.append('connectorNames', val.toString())});
    (params.connectorIds || []).forEach(val => {if (val != null) __params = __params.append('connectorIds', val.toString())});
    if (params.connectionId != null) __params = __params.set('connectionId', params.connectionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-source-connectors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DataSourceConnectorList>;
      })
    );
  }
  /**
   * Get data-source connectors
   *
   * Gets all specified data-source connectors.
   * @param params The `DataSourceConnectorService.GetDataSourceConnectorParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the ID of the tenant for which the connectors are to be fetched.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `connectorNames`: Specifies the names of the connectors which are to be fetched.
   *
   * - `connectorIds`: Specifies the unique IDs of the connectors which are to be fetched.
   *
   * - `connectionId`: Specifies the ID of the connection, connectors belonging to which are to be fetched.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetDataSourceConnector(params: DataSourceConnectorService.GetDataSourceConnectorParams): __Observable<DataSourceConnectorList> {
    return this.GetDataSourceConnectorResponse(params).pipe(
      __map(_r => _r.body as DataSourceConnectorList)
    );
  }

  /**
   * Update a data-source connector using its ID
   *
   * Update the data-source connector specified by the ID in the request path.
   * @param params The `DataSourceConnectorService.UpdateDataSourceConnectorParams` containing the following parameters:
   *
   * - `connectorId`: Specifies the unique ID of the connector which is to be updated.
   *
   * - `body`: Specifies the complete connector resource with any updated properties.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateDataSourceConnectorResponse(params: DataSourceConnectorService.UpdateDataSourceConnectorParams): __Observable<__StrictHttpResponse<DataSourceConnector>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/data-source-connectors/${encodeURIComponent(params.connectorId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DataSourceConnector>;
      })
    );
  }
  /**
   * Update a data-source connector using its ID
   *
   * Update the data-source connector specified by the ID in the request path.
   * @param params The `DataSourceConnectorService.UpdateDataSourceConnectorParams` containing the following parameters:
   *
   * - `connectorId`: Specifies the unique ID of the connector which is to be updated.
   *
   * - `body`: Specifies the complete connector resource with any updated properties.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateDataSourceConnector(params: DataSourceConnectorService.UpdateDataSourceConnectorParams): __Observable<DataSourceConnector> {
    return this.UpdateDataSourceConnectorResponse(params).pipe(
      __map(_r => _r.body as DataSourceConnector)
    );
  }

  /**
   * Delete a data-source connector using its ID
   *
   * Delete the data-source connector specified by the ID in the request path.
   * @param params The `DataSourceConnectorService.DeleteDataSourceConnectorParams` containing the following parameters:
   *
   * - `connectorId`: Specifies the unique ID of the connector which is to be deleted.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteDataSourceConnectorResponse(params: DataSourceConnectorService.DeleteDataSourceConnectorParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/data-source-connectors/${encodeURIComponent(params.connectorId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a data-source connector using its ID
   *
   * Delete the data-source connector specified by the ID in the request path.
   * @param params The `DataSourceConnectorService.DeleteDataSourceConnectorParams` containing the following parameters:
   *
   * - `connectorId`: Specifies the unique ID of the connector which is to be deleted.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteDataSourceConnector(params: DataSourceConnectorService.DeleteDataSourceConnectorParams): __Observable<null> {
    return this.DeleteDataSourceConnectorResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module DataSourceConnectorService {

  /**
   * Parameters for GetDataSourceConnector
   */
  export interface GetDataSourceConnectorParams {

    /**
     * Specifies the ID of the tenant for which the connectors are to be fetched.
     */
    tenantId?: null | string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the names of the connectors which are to be fetched.
     */
    connectorNames?: null | Array<string>;

    /**
     * Specifies the unique IDs of the connectors which are to be fetched.
     */
    connectorIds?: null | Array<string>;

    /**
     * Specifies the ID of the connection, connectors belonging to which are to be fetched.
     */
    connectionId?: null | string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateDataSourceConnector
   */
  export interface UpdateDataSourceConnectorParams {

    /**
     * Specifies the unique ID of the connector which is to be updated.
     */
    connectorId: null | string;

    /**
     * Specifies the complete connector resource with any updated properties.
     */
    body: UpdateDataSourceConnectorRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteDataSourceConnector
   */
  export interface DeleteDataSourceConnectorParams {

    /**
     * Specifies the unique ID of the connector which is to be deleted.
     */
    connectorId: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { DataSourceConnectorService }
