// Module: Alerts Content Module

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.alerts')
    .controller('alertsContentCtrl', alertsContentControllerFn);

  function alertsContentControllerFn(
    $rootScope, $scope, $state, FEATURE_FLAGS) {

    /** @type {Date} for restricting datepicker */
    $scope.maxDate = new Date();

    $scope.FEATURE_FLAGS = FEATURE_FLAGS;

    // set a default tab if user isn't deep linked into a tab state
    if ($state.current.name === 'alerts' ||
      $state.current.name === 'alerts.content') {
      $scope.currentTab = 'alerts.alerts';
    } else {
      $scope.currentTab = $state.current.name;
    }

    // setup our tabs
    $scope.cTabConfig = [
      FEATURE_FLAGS.ngHealth ? {
        name: 'monitoringAlerts',
        value: 'health.alerts',
      } : {
      nameKey: 'monitoring.alertsPage.alertsSummary',
      value: 'alerts.alerts'
    }];

    // Add Analytics when ngHealth is disabled
    if(!FEATURE_FLAGS.ngHealth || $rootScope.clusterInfo._allClusters) {
      $scope.cTabConfig.push({
        name:  'analytics',
        value: 'alerts.analytics'
      });
    }

    // Add resolutions, setting tab only if the view is for single cluster
    if(!$rootScope.clusterInfo._allClusters) {
      $scope.cTabConfig.push({
          name: 'monitoring.alertsPage.resolutionsSummary',
          value: 'alerts.resolutions'
        },
        {
          name: 'settings',
          value: 'alerts.settings'
        }
      );
    }

    // when the current tab value changes, change to the appropriate state
    $scope.$watch('currentTab', function(newValue, oldValue) {
      if (newValue !== oldValue) {
        $state.go(newValue);
      }
    });
  }
})(angular);
