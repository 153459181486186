import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeFormatPickerComponent } from './time-format-picker.component';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { AngularMaterialModule } from '@cohesity/helix';

@NgModule({
  declarations: [TimeFormatPickerComponent],
  imports: [
    CommonModule,
    AngularMaterialModule
  ],
  exports: [TimeFormatPickerComponent]
})
export class TimeFormatPickerModule { }
