<!-- Binding the list issues table -->
<mat-dialog-content>
  <dg-pa-list-issues-table
    [loading]="loading"
    [issueData]="issueData">
  </dg-pa-list-issues-table>
</mat-dialog-content>

 <!-- Cancel button -->
 <mat-dialog-actions align="end">
  <button
    type="button"
    mat-button
    mat-dialog-close
    cogCancelButton
    cogDataId="cancel-issues-dialog">
    {{'cancel' | translate}}
  </button>
 </mat-dialog-actions>
