import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';

import { DataIdModule } from '../data-id/data-id.module';
import { RangeSliderComponent } from './range-slider/range-slider.component';
import { SliderComponent } from './slider.component';


const components = [
  RangeSliderComponent,
  SliderComponent,
];

/**
 * Slider Module.
 */
@NgModule({
  imports: [
    CommonModule,
    DataIdModule,
    MatSliderModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
  ],
  declarations: components,
  exports: components,
})
export class SliderModule { }
