//  Widget: Date Ranger Panel, Panel Toggle, and Service

;(function(angular, undefined) {
  'use strict';

  /**
   * Standard named ranges of days.
   *
   * NOTE: These are offset by -1 because when applying moment().startOf('day')
   * it picks up the missing day.
   *
   * @type   {object}
   */
  var NUM_DAYS_PER_RANGE = {
    day: 0,
    week: 6,
    month: 29,
    quarter: 90,
  };

  angular
    .module('C.dateRanger', [])
    .service('DateRangerService', DateRangerServiceFn)
    .directive('cDateRanger', cDateRanger)
    .directive('cDateRangerToggle', cDateRangerToggle);

  /**
   * @ngdoc  service
   * @name   C.dateRanger.service:DateRangerService
   * @description
   *   This service provides a means of persisting date range changes between
   *   application state changes.
   */
  function DateRangerServiceFn(DateTimeService) {

    var DateRangerService = {
      getDateRangerValues: getDateRangerValues,
      generateRangeObject: generateRangeObject,
      updateDateRangerValues: updateDateRangerValues,
    };

    /**
     * Used to persist date ranger selection through state changes. startDate
     * and endDate will be undefined and computed on get when snapToDate is
     * true. For custom ranges and ranges not snapped to today, the Date values
     * will be stored on this object and returned via get.
     *
     * @type   {object}
     */
    var dateRangerValues = generateRangeObject();

    /**
     * provides a copy of the current cDateRanger date configuration
     *
     * @method   getDateRangerValues
     * @param    {string}   range   range value to be updated
     * @return   {object}   The date ranger values.
     */
    function getDateRangerValues(range) {
      var now = moment();

      // If a supported range value was provided use it, otherwise use the most
      // recent dateRanger range value.
      dateRangerValues.range = (range && NUM_DAYS_PER_RANGE[range]) ?
        range : dateRangerValues.range;

      // if snapToToday is true/active, derive the startDate and endDate based
      // on the range provided
      if (dateRangerValues.snapToToday) {
        dateRangerValues.endDate = now.endOf('day').toDate();
        dateRangerValues.startDate = now
          .subtract(NUM_DAYS_PER_RANGE[dateRangerValues.range], 'days')
          .startOf('day')
          .toDate();
      }

      return angular.copy(dateRangerValues);
    }

    /**
     * Updates the values for the cDateRanger date configuration. Called from
     * the cDateRanger directive when user saves changes to the date range
     *
     * @method   updateDateRangerValues
     * @param    {object}   dates   updated dateRangerValues object
     */
    function updateDateRangerValues(dates) {
      dates.includesToday = DateTimeService.isToday(dates.endDate);
      dates.snapToToday = snapToToday(dates);

      dateRangerValues = angular.copy(dates);

      // if snapToToday is true, uncache the dates as getDateRangerValues() will
      // compute the dates based on range.
      if (dates.snapToToday) {
        dateRangerValues.startDate = undefined;
        dateRangerValues.endDate = undefined;
      }
    }

    /**
     * Gnerates a cDateRanger range object.
     *
     * @method   generateRangeObject
     * @param    {number|date|string}   [start=today]      Start Date
     * @param    {number|date|string}   [end=1-week-ago]   End Date
     * @return   {object}               The generated range object.
     */
    function generateRangeObject(start, end) {
      var endDate;
      var startDate;

      // Grab the specified end, the specified start, or now.
      endDate = end || start || Date.now();

      // Convert the detected date value to milliseconds if it's not already
      endDate = DateTimeService.isDateMicroseconds(endDate) ?
        endDate / 1000 : endDate;

      // Adjust end date to the start of the day
      endDate = moment(endDate).endOf('day');

      // Grab the specified start, or 1 week before endDate.
      startDate = start ||
        angular.copy(endDate)
          .subtract(NUM_DAYS_PER_RANGE['week'], 'days')
          .valueOf();

      // Convert the detected date value to milliseconds if it's not already
      startDate = DateTimeService.isDateMicroseconds(startDate) ?
        startDate / 1000 : startDate;

      // Adjust start date to the start of the day
      startDate = moment(startDate).startOf('day');

      return {
        endDate: endDate.toDate(),
        includesToday: DateTimeService.isToday(endDate),
        range: getRangeString(startDate, endDate),
        snapToToday: snapToToday({startDate: startDate, endDate: endDate}),
        startDate: startDate.toDate(),
      };
    }

    /**
     * Determines the appropriate range string based on input dates.
     *
     * See http://momentjs.com/docs/#/displaying/difference/ for possible arg
     * types.
     *
     * @method   getRangeString
     * @param    {*}        start   date
     * @param    {*}        end     date
     * @return   {string}   The range string.
     */
    function getRangeString(start, end) {
      var output = 'custom';

      /**
       * Absolute value of the difference in days between 2 dates rounded to
       * nearest whole number.
       *
       * @type   {integer}
       */
      var diff = Math.abs(moment(start).diff(end, 'days'));

      // Iterate over each range in the hash looking for a matching range. Stop
      // if found.
      Object.keys(NUM_DAYS_PER_RANGE)
        .some(function testEachRange(range) {
          // If the hashed range numDays is less than our range difference.
          if (diff === NUM_DAYS_PER_RANGE[range]) {
            return output = range;
          }
        });

      return output;
    }

    /**
     * Determines if range should snap to Today.
     *
     * @method   snapToToday
     * @param    {object}    snapToToday   cDateRanger dates object.
     * @return   {boolean}   True if should snap to Today.
     */
    function snapToToday(rangerObject) {
      return DateTimeService.isToday(rangerObject.endDate) &&
        getRangeString(rangerObject.startDate, rangerObject.endDate) !== 'custom';
    }

    return DateRangerService;
  }

  /**
   * @ngdoc directive
   * @name C.dateRanger.directive:cDateRanger
   * @description
   *   This directive displays the input panel for selecting a range between
   *   1 and 2 dates.
   *
   * @restrict 'E'
   * @scope
   * @example
      <example module="C.dateRanger" animation="false">
          <!-- The toggle control -->
          <c-date-ranger-toggle dates="dates"
              show-date-ranger="showDateRanger"></c-date-ranger-toggle>

          <!-- The input controls panel -->
          <c-date-ranger
              show-date-ranger="showDateRanger"
              on-save="updateDateParams()"
              dates="dates"></c-date-ranger>
      </example>
   */
  function cDateRanger($timeout, DateTimeService, DateRangerService, ENUM_DAY) {
    return {
      restrict: 'E',
      scope: {
        // dates object as provided by DateRangerService.getDateRangerValues():
        // {
        //   startDate: Date,
        //   endDate: Date,
        //   range: 'year',
        // }
        dates: '=',

        // boolean to hide range selection, defaults to false if not provided.
        hideRanges: '=?',

        // @type  {function}  - Callback to execute when dates are changed real-time.
        onChange: '&?',

        // callback function to call on save, gets no return value.
        onSave: '&?',

        // callback function to call on cancel. gets no return value.
        onCancel: '&?',

        // boolean that toggles display of c-date-ranger
        showDateRanger: '='
      },
      templateUrl: 'app/global/c-date-ranger/c-date-ranger.html',
      link: function linkFn(scope, elem, attrs) {

        /** @type {Object} key lookup for shifting time when user changes the range  */
        var timeShift = {
          day: 86400000,
          week: 604800000,

          // 30 days
          month: 2592000000,
          quarter: 604800000 * 13,
        };

        // convenience functions and variables
        scope.datepickerFormat = scope.format || DateTimeService.getDatePickerFormat();
        scope.ENUM_DAY = ENUM_DAY;
        scope.today = new Date();

        if (scope.dates.range === 'auto') {
          // auto select the date range based on provided start & end date.
          scope.dates.range = getDateRange(scope.dates.startDate, scope.dates.endDate);
        } else {
          // default our range to week if a default range wasn't provided
          scope.dates.range = scope.dates.range || 'week';
        }

        scope.tmpRange = scope.dates.range;

        // default hideRanges to false
        scope.hideRanges = !!scope.hideRanges;

        // setup temporary start and end dates, as new date range
        // isn't applied until user hits save button
        scope.tmpStartDate = angular.copy(scope.dates.startDate);
        scope.tmpEndDate = angular.copy(scope.dates.endDate);

        var verboseDateFormat = DateTimeService.getLongDateFormat();

        // if onChange is define, setup a watcher on the dates+range and save
        // them out in real-time.
        if (scope.onChange) {
          scope.$watchGroup(['tmpStartDate', 'tmpEndDate', 'tmpRange'], onChange);
        }

        /**
         * Get the deduced date range from provided start & end date.
         *
         * @method   getDateRange
         * @param    {date}    startDate  javascript date object
         * @param    {date}    endDate    javascript date object
         * @return   {string}  The deduced time range.
         */
        function getDateRange(startDate, endDate) {
          var diff = Math.abs(endDate - startDate);

          switch(true) {
            case diff === timeShift.day:
              return 'day';

            case diff === timeShift.week:
              return 'week';

            case diff === timeShift.month:
              return 'month';

            case diff === timeShift.quarter:
              return 'quarter';

            default:
              return 'custom';
          }
        }

        /**
         * Gets the verbose date string for display below the calendar
         *
         * @param      {date}    dateObj  javascript date object
         * @return     {string}  The verbose date string for display
         *                       purposes
         */
        scope.getVersboseDateString = function getVerboseDateString(dateObj) {
          return DateTimeService.formatDate(dateObj, verboseDateFormat);
        };

        /**
         * Apply a special class to particular days within the current range.
         * @param  {Object} data as provided by uib-datepicker:
         *                       {
         *                         date: {Object} of day to be evaluated
         *                         mode: {String} represents the current mode of datepicker (day|month|year)
         *                       }
         * @return {String}      class(es) to be applied for the given day
         */
        scope.inRange = function inRange(data) {
          var date = data.date;
          var mode = data.mode;
          var classStrings = [];
          var dayToCheck;
          var timeToCheck;
          var rangeStart;
          var rangeEnd;
          var startDateEnd;
          var endDateStart;

          if (mode === 'day') {

            dayToCheck = new Date(date);
            timeToCheck = dayToCheck.getTime();
            rangeStart = scope.tmpStartDate.getTime();
            rangeEnd = scope.tmpEndDate.getTime();

            if (timeToCheck >= rangeStart && timeToCheck <= rangeEnd) {
              classStrings.push('c-datepicker-in-range');

              // we're inside the range, determine if we're also
              // on the first or last day of the range.
              startDateEnd = rangeStart + timeShift.day - 1;
              endDateStart = rangeEnd - timeShift.day + 1;
              if (timeToCheck <= startDateEnd) {
                classStrings.push('c-datepicker-range-start');
              }
              if (timeToCheck >= endDateStart) {
                classStrings.push('c-datepicker-range-end');
              }

            }
          }
          return classStrings.join(' ');
        };

        /**
         * updates directive when called from ng-change on start date datepicker element
         */
        scope.startDateChanged = function startDateChanged() {
          if (scope.tmpRange === 'custom') {
            // ensure the end date is greater than or equal to start date
            if (scope.tmpEndDate.getTime() < scope.tmpStartDate.getTime()) {
              scope.tmpEndDate = new Date(scope.tmpStartDate.getTime() + timeShift.day - 1);
            }
            // fake an update so datepicker range styling gets updated
            scope.tmpEndDate = new Date(scope.tmpEndDate);
            // no need to enforce a range on the dates for custom range.
            return;
          }
          scope.tmpEndDate = new Date(scope.tmpStartDate.getTime() + timeShift[scope.tmpRange] - 1);
        };

        /**
         * updates directive when called from ng-change on end date datepicker element
         */
        scope.endDateChanged = function endDateChanged() {
          if (scope.tmpRange === 'custom') {
            // ensure the end date is greater than or equal to start date
            if (scope.tmpEndDate.getTime() < scope.tmpStartDate.getTime()) {
              scope.tmpStartDate = new Date(scope.tmpEndDate.getTime() - timeShift.day + 1);
            }
            // fake an update so datepicker range styling gets updated
            scope.tmpStartDate = new Date(scope.tmpStartDate);
            // no need to enforce a range on the dates for custom range.
            return;
          }
          scope.tmpStartDate = new Date(scope.tmpEndDate.getTime() - timeShift[scope.tmpRange] + 1);
        };

        /**
         * resets temp data and calls the onCancel callback function if provided
         */
        scope.cancel = function cancel() {
          // copy the original dates to our tmp dates
          // in case the directive persists on the page
          scope.tmpStartDate = angular.copy(scope.dates.startDate);
          scope.tmpEndDate = angular.copy(scope.dates.endDate);
          // reset the range
          scope.tmpRange = scope.dates.range;
          if (typeof scope.onCancel === 'function') {
            scope.onCancel();
          }
          scope.showDateRanger = false;
        };

        /**
         * updates the bound date values with the tmp updated dates and calls
         * the onSave callback function if provided
         */
        scope.saveDates = function saveDates() {
          scope.dates.startDate = angular.copy(scope.tmpStartDate);
          scope.dates.endDate = angular.copy(scope.tmpEndDate);
          scope.dates.range = scope.tmpRange;
          if (typeof scope.onSave === 'function') {
            // call onSave function in a timeout so model changes will propagate
            // in the event that the directive is destroyed
            $timeout(scope.onSave);
          }
          DateRangerService.updateDateRangerValues(scope.dates);
          scope.showDateRanger = false;
        };

        /**
         * updates start and end dates based on a change to range
         */
        scope.changeRange = function changeRange() {
          if (scope.tmpRange !== 'custom') {
            // if not custom range, enforce the range by updating the startDate
            // accordingly
            scope.tmpStartDate =
              new Date(scope.tmpEndDate - timeShift[scope.tmpRange] + 1);

            // fake an end date update so the model will change and the
            // datepicker will re-render, so our inRange dates will get updates
            scope.tmpEndDate = new Date(scope.tmpEndDate);
          }
        };

        /**
         * Internal $watchGroup handler for changes to date (only called when
         * scope.onChange is defined as a fn).
         *
         * @method   onChange
         * @param    {array}   values   The $watchGroup newValues array.
         */
        function onChange(values) {
          DateRangerService.updateDateRangerValues(
            angular.extend(
              scope.dates,
              {
                startDate: angular.copy(values[0]),
                endDate: angular.copy(values[1]),
                range: values[2],
              }
            )
          );

          if (angular.isFunction(scope.onChange)) {
            // Call onChange function in a timeout so model changes will
            // propagate in the event that the directive is destroyed.
            $timeout(scope.onChange);
          }
        }

        scope.datePickerOptions = {
          maxDate: scope.today,
          customClass: scope.inRange
        };

      }
    };
  }

  /**
   * @ngdoc directive
   * @name C.dateRanger.directive:cDateRangerToggle
   * @description
   *   This directive displays the selected (or default) date range as
   *   configured, and toggles the display of the input panel cDateRanger
   *   directive.
   *
   * @restrict 'E'
   * @scope
   * @example
      <example module="C.dateRanger" animation="false">
          <!-- The toggle control -->
          <c-date-ranger-toggle dates="dates"
              show-date-ranger="showDateRanger"></c-date-ranger-toggle>

          <!-- The input controls panel -->
          <c-date-ranger show-date-ranger="showDateRanger"
              on-save="updateDateParams()"
              dates="dates"></c-date-ranger>
      </example>
   */
  function cDateRangerToggle(DateTimeService) {
    return {
      restrict: 'E',
      scope: {
        // object as returned from DateRangerService.getDateRangerValues()
        dates: '=',
        // boolean that toggles display of c-date-ranger
        showDateRanger: '=',
        // Optional disable boolean
        disabled: '=?'
      },
      templateUrl: 'app/global/c-date-ranger/c-date-ranger-toggle.html',
      link: function linkFn(scope, elem, attrs) {

        var dtFormat = DateTimeService.getPreferredDateFormat();

        /**
         * formats a date for display in the view
         * @param  {Object} dt date object or milliseconds representing a date
         * @return {String}    prettified date for display
         */
        scope.dateFormat = function dateFormat(dt) {
          return DateTimeService.formatDate(dt, dtFormat);
        };
      }
    };
  }

})(angular);
