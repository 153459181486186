import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { isEqual, uniqBy } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, Subject, of as observableOf } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, startWith, takeUntil, tap } from 'rxjs/operators';
import { EventTrackingService } from '../../../../event-tracking.service';
import { HelixIntlService } from '../../../../helix-intl.service';
import { KeyedSelectionModel } from '../../../../util/keyed-selection-model';
import { DataFilter, ValueFilterSelection } from '../../comparators';
import { FilterDefParams } from '../../filters/filter-def.directive';
import { QuickFilterComponent } from '../../filters/quick-filter/quick-filter.component';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';

/** Maintains counter for unique id generation. */
let nextId = 0;

/**
 * N-ary Tree node to represent the given data set.
 */
export class TreeNode {
  /**
   * children array
   */
  children: TreeNode[];
  /**
   * corresponding filter
   */
  filter: ValueFilterSelection;
}

@Component({
  selector: 'cog-tree-select-value-property-filter',
  templateUrl: './tree-select-value-property-filter.component.html',
  styleUrls: ['./tree-select-value-property-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TreeSelectValuePropertyFilterComponent implements OnInit, OnDestroy, OnChanges {

  /**
   * tree control for mat-tree
   */
  nestedTreeControl: NestedTreeControl<TreeNode>;

  /**
   *  data source for mat-tree
   */
  nestedDataSource: MatTreeNestedDataSource<TreeNode>;

  /**
   * The quick filter component.
   */
  @ViewChild(QuickFilterComponent) quickFilter: QuickFilterComponent;

  /**
   * The filter associated with this component
   */
  @Input() filter: DataFilter<ValueFilterSelection[]>;

  /**
   * Params from `FilterDefDirective` instance.
   */
  @Input() filterDefParams: FilterDefParams;

  /**
   * Whether to allow multiple or single selection for the component.
   * The component will use either radio buttons or checkboxes based on this
   * value.
   */
  @Input() allowMultiple = true;

  /**
   * Preselected filters to be applied at time of page landing
   */
  @Input() preSelectedFilters: ValueFilterSelection[] = [];

  /**
   * Whether to show clear button.
   */
  @Input() noClear = false;

  /**
   * Whether to show expand/collapse in filters having subfilters.
   */
  @Input() showExpandCollapse = false;

  /**
   * Whether the filter should have at least one value present.
   */
  @Input() isRequired = false;

  /**
   * Automatically apply values without the user having to click apply
   */
  @Input() autoApply = false;

  /**
   * Provides a mechanism to allow for opting out of alpha sorting filter options.
   */
  @Input() alphaSort = true;

  /**
   * Returns current filter values as provided externally.
   */
  @Input() get filterValues(): ValueFilterSelection[] {
    return this.filterValues$.value;
  }

  /**
   * Sets filter values.
   */
  set filterValues(values: ValueFilterSelection[]) {
    if (this.alphaSort && values?.length) {
      values.sort((a, b) => a.label.localeCompare(b.label));
    }
    this.filterValues$.next(values);
  }

  /**
   * Unique id of the filter, defaults to internally generated id.
   */
  @Input() id = `value-property-filter-${nextId++}`;

  /**
   * Whether to make api call when search input change. Defaults to false.
   */
  @Input() asyncSearch = false;

  /**
   * Do not emit a tracking event if track filters is set to false.
   */
  @Input() trackFilters = false;

  /**
   * Whether its form style filter i.e without borders
   */
  @Input() formStyle = false;

  /**
   * Static label to preface the dynamic chip label.
   */
  @Input() preLabel = '';

  /**
   * EventEmitter to broadcast search filed update to parent component.
   */
  @Output() readonly filterSearchInputChange = new EventEmitter<string>();

  /**
   * Indicates if the filter should close automatically or not.
   */
  get autoClose(): boolean {
    return this.autoApply && !this.allowMultiple;
  }

  /**
   * Behavior subject for updating filter values.
   */
  private readonly filterValues$ = new BehaviorSubject<ValueFilterSelection[]>([]);

  /*
   * Determines when searching should be displayed for the given filter. Once there are more filter values than this
   * number the string search will be available for the filter.
   */
  filterValuesSearchThreshold = 10;

  /**
   * A search form control for filtering the list based on string input.
   */
  readonly stringSearchControl = new UntypedFormControl('');

  /**
   * Observable stream of filter values, filtered based on the stringSearchControl FormControl value.
   */
  displayedValues$: Observable<ValueFilterSelection[]>;

  /**
   * Array of ValueFilterSelection to get data from displayedValues$ Observable
   * to transform into tree friendly data easily
   */
  filtersInputForTreeDataChange: ValueFilterSelection[] = [];

  /**
   * Array of TreeNode to get data from filtersInputForTreeDataChange$ array
   * to transform into tree friendly data easily
   */
  treeInputForTreeDataChange: TreeNode[] = [];

  /**
   * A selection model to track items which are selected.
   */
  selectionModel: SelectionModel<ValueFilterSelection>;

  /**
   * The currently applied filters.
   */
  appliedFilters: ValueFilterSelection[];

  /**
   * Clean up observable subscriptions when component is destroyed.
   */
  private readonly destroy$ = new Subject();

  constructor(public intlSvc: HelixIntlService, private eventTrackingService: EventTrackingService) { }

  /**
   * Syncs the selection model with the filter
   */
  ngOnInit() {
    this.selectionModel = this.createSelectionModel();

    // Watch for filter values
    this.filter.currentValue$.pipe(
      takeUntil(this.destroy$),

      // clear selection model
      // NOTE: if the next value is undefined, it will still
      // filter the selection model
      tap(() => this.selectionModel.clear()),

      map((updatedFilters) => updatedFilters?.value ?? []),

      map((updatedFilterValues) => {

        if (!this.asyncSearch) {
          // Find all the matching items based on current filter values
          return this.mapToFilterOptions(updatedFilterValues);
        }

        // for async search items may or may not be present in current set
        // of filter options, pass them as is.
        return updatedFilterValues;
      }),
    ).subscribe(selectOptions => {
      // update selection model based on current value
      this.selectionModel.select(...selectOptions);
      this.appliedFilters = selectOptions;
    });

    this.filter.clear$.pipe(takeUntil(this.destroy$)).subscribe(this.clearFilters);
    this.filter.apply$.pipe(takeUntil(this.destroy$)).subscribe(this.applyFilters);

    const searchInputValue$ = this.stringSearchControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(300),
        distinctUntilChanged(),
        shareReplay(1),
        takeUntil(this.destroy$)
      );

    this.displayedValues$ = combineLatest([this.filterValues$, searchInputValue$]).pipe(map(([values, search]) => {
      if (!this.asyncSearch) {
        // If doing client-side filtering, just filter values to match search string.
        return values.filter(value => value.label.toLowerCase().includes(search.toLowerCase()));
      } else {
        // If doing server-side filtering, return values, which is passed into component from API response.
        return values;
      }
    }));

    // Subscribe to the search input and emit filterSearchInputChange event only when asyncSearch is true.
    if (this.asyncSearch) {
      searchInputValue$
        .pipe(takeUntil(this.destroy$))
        .subscribe((searchString: string) => this.filterSearchInputChange.emit(searchString));
    }

    this.nestedTreeControl = new NestedTreeControl<TreeNode>(this._getChildren);
    this.nestedDataSource = new MatTreeNestedDataSource();

    this.displayedValues$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(data => {
      this.filtersInputForTreeDataChange = data;
      this.treeInputForTreeDataChange = this.makeTree(this.filtersInputForTreeDataChange);
      this.nestedDataSource.data = this.treeInputForTreeDataChange;
      this.applyPreSelectedFilters(this.preSelectedFilters);
    });
  }

  /**
   * @method ApplyPreSelectedFilters function to apply preselected filters sent by user
   * @param values array of preselected filters
   */
  applyPreSelectedFilters(values: ValueFilterSelection[]) {
    values.forEach(
      filter => {
        const toggleNode = this.treeInputForTreeDataChange.find(node => node.filter.label === filter.label);
        if (toggleNode) {
          this.selectionToggle(toggleNode);
          const { selected } = this.selectionModel;
          this.filter.setValue(selected);
          this.selectionModel.clear();
          this.selectionModel.select(...selected);
        }
      }
    );
  }

  /**
   * @method makeTree function to create TreeNode[] from given ValueFilterSelection[]
   * @param values array of ValueFilterSelection elements
   * @returns array of TreeNode for implementaion of mat-tree
   */
  makeTree (values: ValueFilterSelection[]): TreeNode[] {
    const resp: TreeNode[] = values
      .map(filter => filter.subItems?.length ? ({
        filter: filter,
        children: this.makeTree(filter.subItems),
      }) :
      ({
        filter: filter,
        children: [],
      }));

    return resp;
  }

  /**
   * method to check for children
   */
  hasNestedChild = (_: number, nodeData: TreeNode) => nodeData.filter.subItems?.length;

  /**
   * method to get children
   */
  private _getChildren = (node: TreeNode) => observableOf(node.children);

  /**
   * Update the selectionModel whenever allowMultipleChanges or filterValuesChanges.
   *
   * @param changes simple changes object
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.allowMultiple) {
      this.selectionModel = this.createSelectionModel();
    }

    // If filterValues changed, maintain matched selection and update selectionModel.
    if (changes.filterValues &&
      !this.asyncSearch &&
      this.selectionModel?.selected?.length &&
      this.selectionModel?.selected[0] !== undefined) {
      // get current value of filter selection.
      const currentValue = this.selectionModel.selected;

      // Clear selection.
      this.selectionModel.clear();

      // Find the matching values from filterValues.
      const newSelection = this.filterValues.filter(newValue =>
        currentValue.find(current => current === newValue || current?.label === newValue?.label)
      );

      // Update selection model.
      this.selectionModel.select(...newSelection);

      // Apply new selection.
      this.applyFilters();
    }

    if (changes.preSelectedFilters) {
      this.applyPreSelectedFilters(changes.preSelectedFilters?.currentValue ?? []);
    }
  }

  /**
   * Clean up the filter subscription on destroy.
   */
  ngOnDestroy() {
    this.destroy$.next();
  }

  /**
   * Creates a new selection model and returns it.
   *
   * @returns Newly created selection model.
   */
  createSelectionModel(): SelectionModel<ValueFilterSelection> {
    if (this.asyncSearch) {
      return new KeyedSelectionModel<ValueFilterSelection>(
        (selection) => selection.value,
        this.allowMultiple
      );
    }
    return new SelectionModel<ValueFilterSelection>(this.allowMultiple);
  }

  /**
   * Check if the item can be selected or not. Should have atleast one filter
   * value selected
   *
   * @param item  The item to check
   * @returns True, if the selection is disabled.
   */
  isSelectionDisabled(item: ValueFilterSelection): boolean {
    if (this.isRequired && this.selectionModel.isSelected(item)) {
      return this.selectionModel.selected.length === 1;
    }
    return false;
  }

  /**
   * Applies all filters at once.
   */
  applyFilters = () => {
    const { selected } = this.selectionModel;
    const { value: currentValue } = this.filter.currentValue$;

    // Cache the applied filters.
    this.appliedFilters = selected;

    // If there is no currentValue and has selectedValue
    // OR if there is currentValue and its different from selected value
    // prevent setting filter value if nothing has changed
    if ((!currentValue && selected.length > 0) ||
      (currentValue && !isEqual(currentValue.value, selected))) {
      this.filter.setValue(selected);
    }

    if (this.autoClose) {
      this.quickFilter.dismiss();
    }
  };

  /**
   * Handle function for when quick filter menu is closed.
   */
  handleMenuClose() {
    // When the menu is closed, clear selection which were never applied.
    this.selectionModel.clear();
    this.selectionModel.select(...(this.appliedFilters || []));
  }

  /**
   * Clears all selected filters.
   */
  clearFilters = () => {
    this.selectionModel.clear();
    if (this.autoApply) {
      this.applyFilters();
    }
  };

  /**
   * Returns sanitized id for given value
   *
   * @param   value   unique value.
   * @param   label   the value's label
   * @return   Id string.
   */
  sanitizeId(value: any, label: string) {
    const itemId = ['object', 'function'].includes(typeof value) ? label : value;
    return `${this.id}-${itemId}`.replace(/\s/g, '');
  }

  /**
   * @method descendantsAllSelected function to check if all descendants are selected for a node
   * @param node the current node for which descendants are to be checked
   * @returns true or false depending on whether all descendants are selected or not
   */
  descendantsAllSelected(node: TreeNode): boolean {
    const descendants = this.nestedTreeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 && descendants.every(child => this.selectionModel.isSelected(child.filter));
    return descAllSelected;
  }

  /**
   * @method descendantsPartiallySelected function to check if descendants are selected partially for a node
   * @param node the current node for which descendants are to be checked
   * @returns true or false depending on whether descendants are selected partially or not
   */
  descendantsPartiallySelected(node: TreeNode): boolean {
    const descendants = this.nestedTreeControl.getDescendants(node);
    const result = descendants.some(child => this.selectionModel.isSelected(child.filter));
    return result && !this.descendantsAllSelected(node);
  }

  /**
   *
   * @method selectionToggle Toggles the current node (filter and all the corresponding subfilters)
   * @param node the current node which is to be toggled
   */
  selectionToggle(node: TreeNode): void {
    const isNodeCurrentlySelected = this.selectionModel.isSelected(node.filter) || this.descendantsAllSelected(node);

    if (isNodeCurrentlySelected) {
      this.deselectANode(node);
    } else {
      this.selectANode(node);
    }
    const { quickFilter, filterGroup } = this.filterDefParams;
    if (!quickFilter && !filterGroup || this.autoApply) {
      this.applyFilters();
    }
  }

  /**
   * @method leafItemSelectionToggle Toggles the current leaf node (sub-filter)
   * @param node the current leaf node which is to be toggled
   */
  leafItemSelectionToggle(node: TreeNode): void {
    this.selectionModel.toggle(node.filter);
    this.checkAllParentsSelection(node);
  }

  /**
   * @method checkAllParentsSelection Checks all the parents when a leaf node is selected/unselected
   * @param node the node which is to be toggled
   */
  checkAllParentsSelection(node: TreeNode): void {
    let parent: TreeNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /**
   * @method checkRootNodeSelection Check root node checked state and change it accordingly
   * @param node the node which is changed
   */
  checkRootNodeSelection(node: TreeNode): void {
    const nodeSelected = this.selectionModel.isSelected(node.filter);
    const descendants = this.nestedTreeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 && descendants.every(child => this.selectionModel.isSelected(child.filter));
    if (nodeSelected && !descAllSelected) {
      this.selectionModel.deselect(node.filter);
    } else if (!nodeSelected && descAllSelected) {
      this.selectionModel.select(node.filter);
    }
  }

  /**
   * @method getParentNode Get the parent node of a node
   * @param node the current (child) node
   */
  getParentNode(node: TreeNode): TreeNode | null {
    this.nestedTreeControl.dataNodes?.
      forEach( element => {
        if (element.children?.find(value => value === node)) {
          return element;
        }
      });
    return null;
  }

  /**
   * @method getLabel helper function to create testing-friendly cogDataId
   * @param label unsanitized label
   */
  getLabel(label: string, type: string): string {
    // replacing spaces in labels with '-'
    const sanitizedLabel = label.replace(/\s/g, '-');
    return `${sanitizedLabel}-${type}`;
  }

  selectANode(node: TreeNode) {
    this.selectionModel.select(node.filter);
    const descendants = this.nestedTreeControl.getDescendants(node);
    this.selectionModel.select(...descendants.map(value => value.filter));

    // Force update for the parent
    descendants?.forEach(child => this.selectionModel.isSelected(child.filter));
    this.checkAllParentsSelection(node);
  }

  deselectANode(node: TreeNode) {
    this.selectionModel.deselect(node.filter);
    const descendants = this.nestedTreeControl.getDescendants(node);
    this.selectionModel.deselect(...descendants.map(value => value.filter));

    // Force update for the parent
    descendants?.forEach(child => this.selectionModel.isSelected(child.filter));
    this.checkAllParentsSelection(node);
  }

  /**
   * Find a filter option from list of options including
   * sub options.
   *
   * @param node filter option node to search
   * @param options list of options
   * @returns found filter option if any
   */
  findFilterOption(
    node: ValueFilterSelection,
    options: ValueFilterSelection[]
  ): ValueFilterSelection {

    for (const filterOption of options) {
      // Check for object equality and then fall back to matching on the labels before
      // updating the selection model.
      const matches = (
        filterOption === node ||
        filterOption.label === node.label
      );

      if (matches) {
        return filterOption;
      }

      if (filterOption?.subItems?.length) {
        const matchingSubOption = this.findFilterOption(node, filterOption.subItems);

        if (matchingSubOption) {
          return matchingSubOption;
        }
      }
    }

    return null;
  }

  /**
   * Find and map nodes values to filter options
   *
   * @param nodes data for selection
   * @returns list of filter options correspoding to nodes
   */
  mapToFilterOptions(
    nodes: ValueFilterSelection[]
  ): ValueFilterSelection[] {

    const matchingItems: ValueFilterSelection[] = [];
    // map new values to filter options
    nodes.forEach((nodeValue) => {
      const filterOption = this.findFilterOption(nodeValue, this.filterValues);

      if (filterOption) {
        matchingItems.push(filterOption);
        // if a parent is selected, child has to be selected too.
        if (nodeValue?.subItems) {
          matchingItems.push(...filterOption.subItems);
        }
      }
    });

    return uniqBy(matchingItems, 'label');
  }
}
