/**
 * Constant for Exchange on-prem group.
 */
export const ExchangeGroups = {
  /**
   * Specifies the Exchange leaf entities - standalone or DAG databases.
   */
  exchangeLeafEntities: [
    'kExchangeDAGDatabase',
    'kExchangeDAGDatabaseCopy',
    'kExchangeStandaloneDatabase',
  ],

  /**
   * Specifies the Exchange container entities.
   */
  exchangeContainerEntities: [
    'kExchangeDAG',
    'kExchangeNode',
  ],

  /**
   * Specifies the statuses of registered exchange nodes.
   */
  registeredHostStatuses: [
    'kHealthy',
    'kUnHealthy',
  ],
};
