/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ConfigCommands } from '../models/config-commands';
import { Environment } from '../models/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationCommandsApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getConfigCommands
   */
  static readonly GetConfigCommandsPath = '/config-commands/{environment}';

  /**
   * Get the Data Source registration configuration commands.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConfigCommands()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConfigCommands$Response(params: {

    /**
     * Specifies the Data Source environment type.
     */
    environment: Environment;

  }): Observable<StrictHttpResponse<ConfigCommands>> {

    const rb = new RequestBuilder(this.rootUrl, ConfigurationCommandsApiService.GetConfigCommandsPath, 'get');
    if (params) {

      rb.path('environment', params.environment, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConfigCommands>;
      })
    );
  }

  /**
   * Get the Data Source registration configuration commands.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getConfigCommands$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConfigCommands(params: {

    /**
     * Specifies the Data Source environment type.
     */
    environment: Environment;

  }): Observable<ConfigCommands> {

    return this.getConfigCommands$Response(params).pipe(
      map((r: StrictHttpResponse<ConfigCommands>) => r.body as ConfigCommands)
    );
  }

}
