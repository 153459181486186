import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Api } from '../api.constants';

/**
 * Private params for executing linux command
 */
export interface ExecuteLinuxCommandParams {
  /**
   * command is the linux command to execute
   */
  command: string;

  /**
   * allNodes indicates whether to execute command on all nodes in the cluster
   */
  allNodes?: boolean;
}

/**
 * Result after executing linux command
 */
export interface ExecuteLinuxCommandNode {
  nodeId?: number;
  nodeIp?: string;
  success?: boolean;
  failedReason?: string;
  output?: string;
}

/**
 * Result after executing linux command
 */
export interface ExecuteLinuxCommandResponse {
  body?: ExecuteLinuxCommandNode[];
}

@Injectable({
  providedIn: 'root',
})
export class LinuxCommandServiceApi {
  constructor(private http: HttpClient) { }

  /**
   * Uses the private API to execute linux command
   *
   * @param    data  The execute linux command params.
   * @returns  An observable stream of command response data
   */
  public ExecuteLinuxCommand(data: ExecuteLinuxCommandParams): Observable<ExecuteLinuxCommandResponse> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post<ExecuteLinuxCommandResponse>(Api.private('linuxCommand/execute'),
      data,
      { headers });
  }
}
