// Component: cSourceName

;(function(angular, undefined) {
  'use strict';

  /**
   * @ngdoc component
   * @name C.reports:cSourceName
   * @function
   *
   * @description
   * Reusable component to display a job's source name
   * with appropriate link
   *
   * @example
     <c-source-name job="job"></c-source-name>
   */

  var options = {
    // These are bound to the controller, not the scope
    bindings: {

      // @type {Obect}  The job to display the source name of. If this is not
      //                provided implementation should provide a source binding.
      job: '<?',

      // @type {Object}   The Source to display a link for
      source: '<?',
    },

    // The controller
    controller: 'sourceNameCtrl',

    // URL to the template
    templateUrl: 'app/reports/components/c-source-name/c-source-name.html'
  };

  angular
    .module('C.reports')
    .controller('sourceNameCtrl', SourceNameCtrlFn)
    .component('cSourceName', options);

  function SourceNameCtrlFn($rootScope, $scope, $state, ENV_TYPE_CONVERSION,
    ENV_GROUPS) {

    var $ctrl = this;

    $scope.text = $rootScope.text.reportsSourceName;

    /**
     * Component initialization function
     *
     * @method   $onInit
     */
    $ctrl.$onInit = function onInit() {
      // Assuming the source exists unless told otherwise. TODO: consider
      // internalizing this logic. As-is, some controllers are checking for
      // the existence of the source locally and setting this flag.
      $ctrl.sourceExists = true;

      if ($ctrl.job) {
        $ctrl.job = $ctrl.job.backupJobSummary ?
          $ctrl.job.backupJobSummary.jobDescription : $ctrl.job.backupJob;

        $ctrl.source = $ctrl.job.parentSource;

        if (angular.isDefined($ctrl.job._sourceAvailable)) {
          $ctrl.sourceExists = $ctrl.job._sourceAvailable;
        }
      }
    };

    /**
     * Handles state change logic for directing user to the appropriate
     * page for a Source.
     *
     * @method   goToSourceDetail
     */
    $ctrl.goToSourceDetail = function goToSourceDetail() {
      var params = {
        id: $ctrl.source.id,
        environment:
          ENV_TYPE_CONVERSION[$ctrl.source.type],
      };

      if (ENV_GROUPS.cohesityGroups.includes($ctrl.source.type)) {
        // Sending user to sources landing page as the grouped sources are
        // listed here and don't have a grouped detail page.
        $state.go('sources-new', {
          scrollToEnvironment: params.environment,
        });
      } else {
        $state.go('source-details.protected', params);
      }
    };

  }

})(angular);
