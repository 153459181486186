import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { createElement } from '../../util/elements';
import { HelixAssetsModule } from '../helix-assets';
import { ButtonIconDecoratorDirective } from './button-icon-decorator.directive';
import { IconComponent } from './icon.component';
import { IconService } from './icon.service';

@NgModule({
  imports: [
    CommonModule,
    HelixAssetsModule,
    MatIconModule,
    IconComponent, ButtonIconDecoratorDirective
  ],
  exports: [IconComponent, ButtonIconDecoratorDirective]
})
export class IconModule {
  constructor(iconService: IconService, injector: Injector) {
    iconService.initializeIcons();
    createElement(IconComponent, 'icon', injector);
  }
}
