<cog-settings-list-item
  [invalid]="formControl.invalid"
  [label]="'ignorableErrors' | translate">

  <cog-settings-list-item-value-display>
    <span>
      {{ formControl.value?.length
        ? (getFormValue() | simpleList: {translate: true})
        : ('none' | translate) }}
    </span>
  </cog-settings-list-item-value-display>

  <cog-settings-list-item-value-editor>
    <mat-checkbox cogDataId="ignorable-error-{{option}}"
      class="padding-right"
      *ngFor="let option of errorOptions"
      [checked]="formControl.value.includes(option)"
      (change)="handleErrorSelection($event, option)">
      {{'enum.error.' + option | translate}}
    </mat-checkbox>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
