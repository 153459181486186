/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CompatibleClusters } from '../models/compatible-clusters';
import { ClusterDetails } from '../models/cluster-details';
import { SPClusterInfo } from '../models/spcluster-info';
import { UpdateSPClusterRequest } from '../models/update-spcluster-request';
import { UpgradesDetail } from '../models/upgrades-detail';
import { UpgradesResponse } from '../models/upgrades-response';
import { Upgrades } from '../models/upgrades';
import { UpgradesCancelResponse } from '../models/upgrades-cancel-response';
import { UpgradesInfo } from '../models/upgrades-info';
@Injectable({
  providedIn: 'root',
})
class ClusterManagementService extends __BaseService {
  static readonly CompatibleClustersForReleasePath = '/mcm/cluster-mgmt/compatible-clusters';
  static readonly GetClustersInfoPath = '/mcm/cluster-mgmt/info';
  static readonly UpdateSpClusterPath = '/mcm/cluster-mgmt/sp-clusters/{clusterId}';
  static readonly FetchClustersUpgradesPath = '/mcm/cluster-mgmt/upgrades';
  static readonly UpdateClustersUpgradesPath = '/mcm/cluster-mgmt/upgrades';
  static readonly CreateClustersUpgradesPath = '/mcm/cluster-mgmt/upgrades';
  static readonly DeleteClustersUpgradesPath = '/mcm/cluster-mgmt/upgrades';
  static readonly ClustersUpgradesInfoPath = '/mcm/cluster-mgmt/upgrades/info';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Fetch compatible clusters for release version.
   *
   * Get list of clusters that are compatible for an upgrade to the specified release version.
   * @param releaseVersion undefined
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  CompatibleClustersForReleaseResponse(releaseVersion?: string,
    regionId?: string): __Observable<__StrictHttpResponse<CompatibleClusters>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (releaseVersion != null) __params = __params.set('releaseVersion', releaseVersion.toString());
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/cluster-mgmt/compatible-clusters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompatibleClusters>;
      })
    );
  }
  /**
   * Fetch compatible clusters for release version.
   *
   * Get list of clusters that are compatible for an upgrade to the specified release version.
   * @param releaseVersion undefined
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  CompatibleClustersForRelease(releaseVersion?: string,
    regionId?: string): __Observable<CompatibleClusters> {
    return this.CompatibleClustersForReleaseResponse(releaseVersion, regionId).pipe(
      __map(_r => _r.body as CompatibleClusters)
    );
  }

  /**
   * Clusters information with upgrade details.
   *
   * Get clusters information and their upgrade details.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetClustersInfoResponse(regionId?: string): __Observable<__StrictHttpResponse<ClusterDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/cluster-mgmt/info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterDetails>;
      })
    );
  }
  /**
   * Clusters information with upgrade details.
   *
   * Get clusters information and their upgrade details.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetClustersInfo(regionId?: string): __Observable<ClusterDetails> {
    return this.GetClustersInfoResponse(regionId).pipe(
      __map(_r => _r.body as ClusterDetails)
    );
  }

  /**
   * Update SP Cluster Details.
   *
   * Internal API to update the Storage Protect cluster details.
   * @param params The `ClusterManagementService.UpdateSpClusterParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the id of the SP cluster.
   *
   * - `body`: Specifies the parameters to update the SP cluster details.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  UpdateSpClusterResponse(params: ClusterManagementService.UpdateSpClusterParams): __Observable<__StrictHttpResponse<SPClusterInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/mcm/cluster-mgmt/sp-clusters/${encodeURIComponent(params.clusterId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SPClusterInfo>;
      })
    );
  }
  /**
   * Update SP Cluster Details.
   *
   * Internal API to update the Storage Protect cluster details.
   * @param params The `ClusterManagementService.UpdateSpClusterParams` containing the following parameters:
   *
   * - `clusterId`: Specifies the id of the SP cluster.
   *
   * - `body`: Specifies the parameters to update the SP cluster details.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  UpdateSpCluster(params: ClusterManagementService.UpdateSpClusterParams): __Observable<SPClusterInfo> {
    return this.UpdateSpClusterResponse(params).pipe(
      __map(_r => _r.body as SPClusterInfo)
    );
  }

  /**
   * Fetch the cluster upgrade details.
   *
   * Fetch the cluster upgrade details.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param clusterIds undefined
   * @return Success
   */
  FetchClustersUpgradesResponse(regionId?: string,
    clusterIds?: null | Array<string>): __Observable<__StrictHttpResponse<UpgradesDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    (clusterIds || []).forEach(val => {if (val != null) __params = __params.append('clusterIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/cluster-mgmt/upgrades`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpgradesDetail>;
      })
    );
  }
  /**
   * Fetch the cluster upgrade details.
   *
   * Fetch the cluster upgrade details.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param clusterIds undefined
   * @return Success
   */
  FetchClustersUpgrades(regionId?: string,
    clusterIds?: null | Array<string>): __Observable<UpgradesDetail> {
    return this.FetchClustersUpgradesResponse(regionId, clusterIds).pipe(
      __map(_r => _r.body as UpgradesDetail)
    );
  }

  /**
   * Updates scheduled cluster upgrades.
   *
   * Updates scheduled cluster upgrades.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param body Request body params in order to start an upgrade
   * @return Success
   */
  UpdateClustersUpgradesResponse(regionId?: string,
    body?: Upgrades): __Observable<__StrictHttpResponse<UpgradesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/mcm/cluster-mgmt/upgrades`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpgradesResponse>;
      })
    );
  }
  /**
   * Updates scheduled cluster upgrades.
   *
   * Updates scheduled cluster upgrades.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param body Request body params in order to start an upgrade
   * @return Success
   */
  UpdateClustersUpgrades(regionId?: string,
    body?: Upgrades): __Observable<UpgradesResponse> {
    return this.UpdateClustersUpgradesResponse(regionId, body).pipe(
      __map(_r => _r.body as UpgradesResponse)
    );
  }

  /**
   * Initiates instant and scheduled cluster upgrade.
   *
   * Initiates instant and scheduled cluster upgrade.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param body Request body params in order to start an upgrade
   * @return Success
   */
  CreateClustersUpgradesResponse(regionId?: string,
    body?: Upgrades): __Observable<__StrictHttpResponse<UpgradesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/cluster-mgmt/upgrades`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpgradesResponse>;
      })
    );
  }
  /**
   * Initiates instant and scheduled cluster upgrade.
   *
   * Initiates instant and scheduled cluster upgrade.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param body Request body params in order to start an upgrade
   * @return Success
   */
  CreateClustersUpgrades(regionId?: string,
    body?: Upgrades): __Observable<UpgradesResponse> {
    return this.CreateClustersUpgradesResponse(regionId, body).pipe(
      __map(_r => _r.body as UpgradesResponse)
    );
  }

  /**
   * Cancels scheduled cluster upgrades.
   *
   * Cancels scheduled cluster upgrades.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param clusterIdentifiers Specifies the list of cluster identifiers. The format is clusterId:clusterIncarnationId.
   * @return Success
   */
  DeleteClustersUpgradesResponse(regionId?: string,
    clusterIdentifiers?: null | Array<string>): __Observable<__StrictHttpResponse<UpgradesCancelResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    (clusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('clusterIdentifiers', val.toString())});
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/mcm/cluster-mgmt/upgrades`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpgradesCancelResponse>;
      })
    );
  }
  /**
   * Cancels scheduled cluster upgrades.
   *
   * Cancels scheduled cluster upgrades.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param clusterIdentifiers Specifies the list of cluster identifiers. The format is clusterId:clusterIncarnationId.
   * @return Success
   */
  DeleteClustersUpgrades(regionId?: string,
    clusterIdentifiers?: null | Array<string>): __Observable<UpgradesCancelResponse> {
    return this.DeleteClustersUpgradesResponse(regionId, clusterIdentifiers).pipe(
      __map(_r => _r.body as UpgradesCancelResponse)
    );
  }

  /**
   * Fetch upgrade info.
   *
   * Get progress details and logs for a cluster upgrade. Logs will in json string format.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param clusterIdentifiers Fetch upgrade progress details for a list of cluster identifiers in format clusterId:clusterIncarnationId.
   * @return Success
   */
  ClustersUpgradesInfoResponse(regionId?: string,
    clusterIdentifiers?: Array<string>): __Observable<__StrictHttpResponse<UpgradesInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    (clusterIdentifiers || []).forEach(val => {if (val != null) __params = __params.append('clusterIdentifiers', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/cluster-mgmt/upgrades/info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpgradesInfo>;
      })
    );
  }
  /**
   * Fetch upgrade info.
   *
   * Get progress details and logs for a cluster upgrade. Logs will in json string format.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param clusterIdentifiers Fetch upgrade progress details for a list of cluster identifiers in format clusterId:clusterIncarnationId.
   * @return Success
   */
  ClustersUpgradesInfo(regionId?: string,
    clusterIdentifiers?: Array<string>): __Observable<UpgradesInfo> {
    return this.ClustersUpgradesInfoResponse(regionId, clusterIdentifiers).pipe(
      __map(_r => _r.body as UpgradesInfo)
    );
  }
}

module ClusterManagementService {

  /**
   * Parameters for UpdateSpCluster
   */
  export interface UpdateSpClusterParams {

    /**
     * Specifies the id of the SP cluster.
     */
    clusterId: number;

    /**
     * Specifies the parameters to update the SP cluster details.
     */
    body: UpdateSPClusterRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;
  }
}

export { ClusterManagementService }
