import { Pipe, PipeTransform } from '@angular/core';
import { ObjectSummary } from '@cohesity/api/v2';
import { Environment, ObjectTypeToIconMap, OsVariantIconMap } from '@cohesity/iris-shared-constants';

/**
 * Pipe to provide icon to object type
 *
 * Eg. {{ Environment.kVMware | objectTypeIcon: ObjectType.kVirtualMachine }}
 */
@Pipe({ name: 'objectTypeIcon' })
export class ObjectTypeIconPipe implements PipeTransform {
  /**
   * Gets the icon for a object type
   *
   * @param environment source environment type
   * @returns returns the icon
   */
  transform(
    environment: Environment | ObjectSummary['environment'] | string,
    objectType: ObjectSummary['objectType'],
    osType?: ObjectSummary['osType'],
  ): string {
    const objIcon = this.getObjectIconByEnv(environment, objectType);

    if (!osType) {
      return objIcon;
    }

    return OsVariantIconMap?.[objIcon]?.[osType] ?? objIcon;
  }

  getObjectIconByEnv(
    environment: Environment | ObjectSummary['environment'] | string,
    objectType: ObjectSummary['objectType'],
  ): string {
    return ObjectTypeToIconMap?.[environment]?.[objectType] ?? 'helix:object-generic';
  }
}
