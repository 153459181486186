import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';
import { TaskNotification } from '@cohesity/api/v1';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { AjsUpgradeService } from 'src/app/core/services/ajs-upgrade.service';

@Component({
  selector: 'coh-notifications-item',
  templateUrl: './notifications-item.component.html',
  styleUrls: ['./notifications-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsItemComponent {
  // Upgrade AngularJS NotificationService
  ajsNotifService: any;

  // The notification item being displayed.
  @Input() item: TaskNotification;

  // Indicates if a call is in progress to update the notification.
  updating = false;

  /**
   * Constructor
   */
  constructor(
    ajsUpgrade: AjsUpgradeService,
    private cdr: ChangeDetectorRef,
    private irisCtx: IrisContextService,
    private translate: TranslateService
  ) {
    // NOTE: Using upgrade AngularJS service because swagger documentation for
    // notification update endpoints was not written.
    this.ajsNotifService = ajsUpgrade.get('NotificationService');
  }

  // If appropriate, add unread/unvisited class to the host for styling.
  @HostBinding('class.unread') get isUnreadFn() {
    return !this.item.visited;
  }

  /**
   * Provides the appropriate matIcon icon name for a given notification.
   */
  get iconName(): string {
    if (this.item.taskType === 'fieldMessage') {
      return 'info';
    }
    if (this.wasSuccessful) {
      return 'check_circle';
    }
    if (this.wasUnsuccessful) {
      return 'error';
    }
  }

  /**
   * Provides the appropriate matIcon theme color palette for a given notification.
   */
  get iconThemePalette(): string {
    if (this.item.taskType === 'fieldMessage') {
      return 'primary';
    }
    if (this.wasSuccessful) {
      return 'primary';
    }
    if (this.wasUnsuccessful) {
      return 'warn';
    }
  }

  /**
   * Indicates if the task represented by the notification was successful.
   *
   * @returns  True if the task was successful, false otherwise.
   */
  get wasSuccessful(): boolean {
    return this.item.status === 'kSuccess';
  }

  /**
   * Indicates if the task represented by the notification was unsuccessful.
   *
   * @returns  True if the task was unsuccessful, false otherwise.
   */
  get wasUnsuccessful(): boolean {
    return this.item.status === 'kError';
  }

  /**
   * Returns the translation key for the notification description.
   */
  get stringKey(): string {
    return ['notification', this.item.taskType, this.item.status].join('.');
  }

  /**
   * Provides the ui-sref configuration to the detail page for the notifcation.
   *
   * @returns   String representation of the ui-sref for the detail page.
   */
  get uiSref(): string {
    switch (this.item.taskType) {
      case 'restore':
        // deepLinking primitive for recovery task uses 'recover-detail-local'
        // state for local and 'recover-detail-archive' for cloud and tape.
        return 'recover-detail-' + this.item.recoveryTask.type;

      case 'clone':
        return 'clone-detail';

      case 'backupNow':
        return 'job-run-details.protection';

      case 'tiering':
        return 'job-run-details.file-stubbing-runs';

      case 'analysis':
        return 'data-tiering';

      case 'fieldMessage':
        return 'field-messages';
    }

    if (this.isAgentManagementNotificationItem || this.isAgentUpgradeNotificationItem) {
      return 'sources-ng.sources';
    }

  }

  /**
   * Returns the appropraite state params for the notifcation.
   *
   * @returns   state params for the notifcation.
   */
  get uiParams(): object {
    switch (this.item.taskType) {
      case 'restore':
        return { id: this.item.recoveryTask.taskId };

      case 'clone':
        return { id: this.item.cloneTask.taskId };

      case 'backupNow':
        return {
          id: this.item.backupTask.taskId,
          instanceId: this.item.backupTask.instanceId,
          startTimeUsecs: this.item.backupTask.startTimeUsecs
        };

      case 'tiering':
        return {
          id: this.item.tieringTask.taskId,
          instanceId: this.item.tieringTask.instanceId,
          startTimeUsecs: this.item.tieringTask.startTimeUsecs
        };

      case 'fieldMessage':
        return { id: this.item.fieldMessageTask.taskId };
    }

    if (this.isAgentManagementNotificationItem || this.isAgentUpgradeNotificationItem) {
      return {
        agentJobProgressId: this.item.bulkInstallAppTask?.jobId,
        agentUpgradeTaskProgressId: this.item.agentUpgradeTask?.taskId,
      };
    }

  }

  /**
   * Mark the notication as visited.
   */
  markVisited() {
    this.ajsNotifService.markVisited([this.item.id])
      .finally(() => this.cdr.markForCheck());
  }

  /**
   * Dismiss the notifcation.
   *
   * @param   $event   Click event producing this function call.
   */
  dismissNotification($event: Event) {
    $event.stopPropagation();
    this.updating = true;
    this.ajsNotifService.markDismissed([this.item.id])
      .finally(() => {
        this.updating = false;
        this.cdr.markForCheck();
      });
  }

  /**
   * Returns the notification content to show for an item. This method can be
   * used to generate custom content based on arbitrary logic. If this method
   * returns a falsy value, then the translation will be delegated to the
   * `stringKey` method.
   *
   * @returns The final translated string for the notification item.
   */
  getNotificationContent(): string {
    if (this.isAgentManagementNotificationItem) {
      return this.getAgentTaskNotifContent();
    }

    return null;
  }

  /**
   * Returns the notification content for an agent management notification item.
   *
   * @returns The translated notification content.
   */
  getAgentTaskNotifContent(): string {
    const task = this.item.bulkInstallAppTask;
    const machineCount = this.wasSuccessful ? task.numMachinesTotal : task.numMachinesFailed;
    const translationKey = ['agentDeployment', 'notification'];
    translationKey.push(task.registeringApp ? 'registration' : 'deployment');

    if (this.wasUnsuccessful) {
      translationKey.push('failed');
    } else if (this.wasSuccessful) {
      switch (task.state) {
        case 'started':
          translationKey.push('started');
          break;
        case 'completed':
          translationKey.push('completed');
          break;
      }
    }

    return this.translate.instant(translationKey.join('.'), {
      app: task.registeringApp ? this.translate.instant(task.registeringApp) : null,
      count: machineCount,
    });
  }

  /**
   * Returns whether the current notification item is of agent management.
   */
  private get isAgentManagementNotificationItem(): boolean {
    return flagEnabled(this.irisCtx.irisContext, 'agentManagementEnabled') && this.item.taskType === 'bulkInstallApp';
  }

  /**
   * Returns whether the current notification item is of agent upgrade task.
   */
  private get isAgentUpgradeNotificationItem(): boolean {
    return flagEnabled(this.irisCtx.irisContext, 'agentUpgradeEnabled') && this.item.taskType === 'agentUpgrade';
  }
}
