import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavItem } from '@cohesity/helix';
import { AutoDestroyable } from '@cohesity/utils';
import { UIRouterGlobals } from '@uirouter/core';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { NavService } from 'src/app/core/app-layout/nav.service';
import { CustomizationService } from 'src/app/core/services';

/**
 * A select/title dropdown for choosing which dashboard to view.
 *
 * @example
 *   <coh-board-select></coh-board-select>
 */
@Component({
  selector: 'coh-board-select',
  templateUrl: './board-select.component.html',
  styleUrls: ['./board-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BoardSelectComponent extends AutoDestroyable implements OnInit {
  /**
   * List of dashboards to be displayed.
   */
  boardList: NavItem[] = [];

  /**
   * The board the user is currently on. This component assumes it exists on a dashboard.
   */
  currentBoard: NavItem;

  constructor(
    private navService: NavService,
    private routerGlobals: UIRouterGlobals,
    private customizationService: CustomizationService,
  ) {
    super();
  }

  /**
   * Init function.
   */
  ngOnInit() {
    combineLatest([
      this.navService.dashboardsList$,
      this.customizationService.hiddenDashboards$,
    ]).pipe(
      this.untilDestroy(),

      // Take the dashboard list a single time, as it won't change during the components lifecycle.
      take(1),
    ).subscribe(([list, hiddenBoards]) => {
      this.currentBoard = list.find(boardItem => this.routerGlobals.current.name.includes(boardItem.state));
      this.boardList = list.filter(board => !hiddenBoards.includes(board.state));
    });
  }
}
