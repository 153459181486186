import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { McmPrincipalsServiceApi } from '@cohesity/api/private';
import { User } from '@cohesity/api/v1';
import { BehaviorSubject } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

/**
 * Contains info and useful method related to logged in user.
 */
@Injectable()
export abstract class UserService {
  /**
   * Logged in user data as observable
   */
  user$ = new BehaviorSubject<User>(null);

  /**
   * Logged in user data as observable
   */
  privs$ = new BehaviorSubject<Record<string, boolean>>(null);

  constructor(
    private http: HttpClient,
    private mcmPrincipalsServiceApi: McmPrincipalsServiceApi,
  ) {}

  /**
   * Get logged in user details.
   *
   * @returns The logged in user details.
   */
  getSessionUser() {
    return this.mcmPrincipalsServiceApi.GetSessionUser().pipe(tap(({ user, privileges }) => {
      // creating a true map of privileges.
      const privs = (privileges || []).reduce((out, priv) => {
        out[priv] = true;
        return out;
      }, {});

      this.user$.next(this.extendUser(user));
      this.privs$.next(privs);
    }));
  }

  /**
   * Extends the user with additional details.
   *
   * @param user The user details.
   * @returns The modified user details with additional details.
   */
  extendUser = (user: User) => user;

  /**
   * logout the user.
   */
  logout = () => {
    this.http.post('/logout', null).pipe(finalize(this.goHome)).subscribe();
  };

  /**
   * Take your to home page or login page (dev mode)
   */
  abstract goHome: () => void;
}
