// Directives used to add custom table columns like tenant or cluster column.

;(function(angular, undefined) {
  'use strict';

  angular.module('C.tableCols', [], defineTableColDirective)
    .run(addTemplates);

  function defineTableColDirective($compileProvider) {
    ['cTenantCol', 'cClusterCol'].forEach(function eachColumn(colName) {
      $compileProvider.directive(colName, ['$templateCache',
        getTableColDirectiveDefinitionFn(colName)]);
    });
  }

  /**
   * Returns directive definition function for provided column, for example
   * cTenantCol will be used like
   *
   * c-tenant-col="<loop_control_variable>.<cell_variable>"
   * c-tenant-col-pos="<column_position>"
   * c-tenant-col-if="<show_tenant_column_when>"
   *
   * 1. Provide loop_control_variable from ng-repeat and cell_variable property
   *    which will be used to access data for each row.
   * 2. Column will be added at specified column_position ranging from 0 to n-1
   *    and zero is considered as default position.
   * 3. You can not use expression to generate c-tenant-col and position value.
   * 4. If multiple such columns are used on the same element then they will be
   *    processed in order they are written in html and column_position would
   *    be considered after adding all previous columns in the list.
   * 5. Optionally provide if condition when to show this tenant column.
   *
   * @example
      <table
        st-table
        c-tenant-col="cluster.tenant"
        c-tenant-col-pos="1"
        c-tenant-col-row-span="2"
        c-cluster-col="cluster.name"
        c-cluster-col-pos="2">
        <thead>
          <tr>
            <th>First column<th>
            <!-- Dynamically added column by c-tenant-col -->
            <th>...<th>
            <!-- Dynamically added column by c-cluster-col -->
            <th>...<th>
          </tr>
        </thead>
        <tbody>
          <tr ng-repeat="cluster in clusterList">
            <td>First column data<td>
            <!-- Dynamically added column by c-tenant-col -->
            <td>...<td>
            <!-- Dynamically added column by c-cluster-col -->
            <td>...<td>
          </tr>
        </tbody>
      </table>
   *
   * @method   getTableColDirectiveDefinitionFn
   * @param    {object}    colName   The column name.
   * @return   {function}  directive definition function for provided column.
   */
  function getTableColDirectiveDefinitionFn(colName) {
    /**
     * @ngdoc directive
     * @name C.tableCols.directiveDefinition
     *
     * @description
     *   Used to dynamically add table columns which shares same template for
     *   example tenant name and cluster name column.
     *
     * @restrict 'A'
     * @priority 9999
    */
    return function directiveDefinitionFn($templateCache) {
      var directiveDefinitionObject = {
        // setting higher priority to ensure custom table col runs before any
        // other directive on the same element.
        priority: 9999,
        restrict: 'A',
        compile: addTableColFn,
      };

      /**
       * Adding a column at specified position in the table.
       *
       * @method  addTableColFn
       * @param   {Object}   tElement   The element object.
       * @param   {Object}   tAttrs     The element attributes object.
       */
      function addTableColFn(tElement, tAttrs) {
        // parse attributes and extra out direction options.
        var options = getOptions(tAttrs);

        // the position after which the column will be added.
        var insertAfter = options.position - 1;

        // get column header and cell template.
        var headerTemplate = getTemplate('header', options);
        var cellTemplate = getTemplate('cell', options);

        // find table header cells
        var headerEls = tElement.find('thead').find('tr').first().find('th');

        // find table body row
        var rowEls = tElement.find('tbody').find('tr');

        // adding tenant header template.
        insertNode(headerEls, insertAfter, headerTemplate);

        // adding tenant cell template for rows getting repeated by (ng-repeat).
        angular.forEach(rowEls, function eachRow(row) {
          if (row.attributes['ng-repeat'] ||
            row.attributes['ng-repeat-start']) {
            var cellEls = angular.element(row).find('td');

            insertNode(cellEls, insertAfter, cellTemplate);
          }
        });

        return { pre: angular.noop, post: angular.noop };
      }

      /**
       * Returns options by parsing element attributes.
       *
       * @example
          <table
            c-tenant-col="cluster.tenant"
            c-tenant-col-pos="3">...</table>

          would result
          {
            cellVar:        'cluster.tenant',
            sortingVar:     'tenant',
            loopControlVar: 'cluster',
            position:        3,
            rowSpan:         null,
          }
       *
       * @method  getOptions
       * @param   {Object}   tAttrs     The element attributes object.
       * @return  {Object}   Returns options by parsing element attributes.
       */
      function getOptions(tAttrs) {
        var cellVar = tAttrs[colName];
        var properties = cellVar.split('.');
        var loopControlVar = properties[0];
        var sortingVar = properties.splice(1).join('.');

        return {
          cellVar: cellVar,
          sortingVar: sortingVar,
          loopControlVar: loopControlVar,
          position: (+tAttrs[colName + 'Pos'] || 0),
          ifCondition: (tAttrs[colName + 'If'] || true),
          sortDefault: tAttrs.hasOwnProperty(colName + 'SortDefault'),
          rowSpan: (+tAttrs[colName + 'RowSpan'] || null),
        };
      }

      /**
       * Insert the provided Node template after provided index.
       *
       * @method  insertNode
       * @param   {Array}    list          The list of elements.
       * @param   {Number}   insertAfter   The position after which template
       *                                   will be addded.
       * @param   {String}   template      The template html string.
       */
      function insertNode(list, insertAfter, template) {
        var targetEl = angular.element(list[insertAfter]);

        if (targetEl.length) {
          targetEl.after(template);
        } else {
          // adding template at 1st place by prepend template from list parent
          // element because angular jQuery lite doesn't support jQuery before
          // function.
          list.parent().prepend(template);
        }
      }

      /**
       * Return the template string for header or cell.
       *
       * @method  getTemplate
       * @param   {String}   type       The template type header or cell.
       * @param   {Object}   options    The config options.
       * @return  {String}   Return the header template string.
       */
      function getTemplate(type, options) {
        return $templateCache.get(colName + '-' + type)
          .replace(/\${COLUMN_NAME}/g, colName)
          .replace(/\${CELL_VAR}/g, options.cellVar)
          .replace(/\${ROW_SPAN}/g, options.rowSpan)
          .replace(/\${SORTING_VAR}/g, options.sortingVar)
          .replace(/\${IF_CONDITION}/g, options.ifCondition)
          .replace(/\${SORT_DEFAULT}/g, options.sortDefault)
          .replace(/\${LOOP_CONTROL_VAR}/g, options.loopControlVar);
      }

      return directiveDefinitionObject;
    };
  }

  /**
   * Adding custom table columns header and cell templates.
   *
   * @method  addTemplates
   * @param   {Object}   $templateCache     Injected $templateCache service used
   *                                        to put custom table columns header
   *                                        and cell template.
   */
  /* @ngInject */
  function addTemplates($templateCache) {
    /**
     * ${COLUMN_NAME} ${SORTING_VAR} and ${CELL_VAR} should be replaced with
     * appropriate value before using the template.
     */
    var templateNote = '<!-- Dynamically added column by ${COLUMN_NAME} -->';

    // c-tenant-col header and cell template.
    $templateCache.put('cTenantCol-header', [
      templateNote,
      '<th st-sort="${SORTING_VAR}.name" st-sort-default="${SORT_DEFAULT}"',
        'rowspan="${ROW_SPAN}"',
        'id="sort-by-tenant-name"',
        'ng-if="::$root.user.privs.ORGANIZATION_VIEW && ${IF_CONDITION}">',
        '{{::\'organizationName\' | translate}}',
      '</th>'
    ].join(' '));
    $templateCache.put('cTenantCol-cell', [
      templateNote,
      '<td ng-if="::$root.user.privs.ORGANIZATION_VIEW && ${IF_CONDITION}"',

        // create list of tenant to show and filter out holes.
        'ng-init="cTenantColList = ([].concat(${CELL_VAR}) | filter: {});',

          // max 2 tenants are visible by default and if more they will be seen
          // by toggling view-more-button.
          'cTenantColLimitTo = 2;">',
        '<div ng-class="{\'margin-bottom-xs\': cTenantColList.length > 1}"',
          'ng-repeat="tenant in cTenantColList | limitTo: cTenantColLimitTo',
            'as cTenantColVisibleTenants">',

          // link to view-tenants details page will not be there for deleted
          // tenants.
          '<a ng-if="tenant.tenantId && tenant.name && !tenant.deleted"',
            'class="user-data-md auto-truncate"',
            'uib-tooltip="{{::tenant.name | naFilter}}"',
            'ui-sref="view-tenant({id: tenant.tenantId})">',
            '{{::tenant.name | naFilter}}',
          '</a>',

          '<span ng-if="',
            // Special case where tenantId is present but not tenant name,
            // which can be a case for protection job replication
            '(tenant.tenantId && (tenant.deleted || !tenant.name)) ||',
            // Special case where tenantId is not present but tenant name is,
            // which is the case for Service Provider.
            '(!tenant.tenantId && tenant.name)"',
            'class="user-data-md auto-truncate"',
            'uib-tooltip="{{::(tenant.name || tenant.tenantId) | naFilter}}">',
            '{{::(tenant.name || tenant.tenantId) | naFilter}}',
          '</span>',

          `<i class="icn-info icn-xs margin-left-xs"
            ng-if="::tenant.deletionFinished"
            uib-tooltip-html="'cleanupObjectAfterTenantDeletion' |
              translate: tenant"></i>`,

          `<i class="icn-info icn-xs margin-left-xs"
            ng-if="::tenant.tenantId && !tenant.name"
            uib-tooltip-html="'tenantDoesNotExist' |
              translate: tenant"></i>`,
        '</div>',
        '<view-more-button total="cTenantColList.length"',
          'limit-to="cTenantColLimitTo"></view-more-button>',

        // display na text if no tenants to show.
        '<span ng-if="cTenantColVisibleTenants.length === 0">',
          '{{\'naNotAvailable\' | translate}}',
        '</span>',
      '</td>'
    ].join(' '));

    // c-cluster-col header and cell template.
    $templateCache.put('cClusterCol-header', [
      templateNote,
      '<th st-sort="${SORTING_VAR}.name"',
        'id="sort-by-cluster-name">',
        '{{::\'clusterName\' | translate}}',
      '</th>'
    ].join(' '));
    $templateCache.put('cClusterCol-cell', [
      templateNote,
      '<td>',
        '<a id="view-remote-cluster-details-{{$index}}"',
          'ui-sref="remote-clusters-view({',
            'clusterId: ${CELL_VAR}.clusterId,',
            'cluster: ${CELL_VAR},',
          '})"',
          'uib-tooltip="{{::${CELL_VAR}.name}}">',
          '{{::${CELL_VAR}.name}}',
        '</a>',
      '</td>'
    ].join(' '));
  }

}(angular));
