// Module: View Active Directory

;(function(angular) {
  'use strict';

  var configOptions = {
    bindings: {
      /**
       * Required attribute. The domain name of the AD that is to be viewed.
       * Works both for modal and page view.
       */
      adDomainName: '<',

      /**
       * Optional attribute. If present then active directory
       * edit/delete is not allowed else those options will be shown.
       */
      disableModifications: '<?',

      /**
       * Optional attribute. If present, then this component is opened inside a
       * modal.
       */
      inModal: '<?',
    },
    controller: 'viewAdController',
    templateUrl: 'app/admin/access-management/active-directory/view.html',
  };

  angular
    .module('C.activeDirectory')
    .controller('viewAdController', ViewAdControllerFn)
    .component('activeDirectoryView', configOptions);

  /**
   * Controller for the Details view
   */
  function ViewAdControllerFn(_, $state, $uibModal, $q, $log, $timeout,
    evalAJAX, cModal, ViewBoxService, ActiveDirectoryService, LdapService,
    ENUM_AD_USER_MAPPINGS, ViewBoxCacheService, UserService) {

    var $ctrl = this;

    // Default config object use to stub out model when editing.
    var defaultAdConfig = {
      domainName: undefined,
      ldapProviderId: undefined,
      machineAccounts: [],
      password: undefined,
      userIdMapping: {},
      userName: undefined,
    };

    // Default config object for fixed mapping.
    var defaultFixedMappingConfig = {
      uid: 0,
      gid: 0,
    };

    _.assign($ctrl, {
      ad: {},
      adDomains: [],
      centrifyZones: [],
      domainControllersHash: {},
      domainPrincipalsHash: {},
      fallbackMappingTypes: {
        kFixed: {
          type: 'kFixed',
          nameKey: ENUM_AD_USER_MAPPINGS['kFixed'],
        },
        kRid: {
          type: 'kRid',
          nameKey: ENUM_AD_USER_MAPPINGS['kRid'],
        },
      },
      forms: {
        editMachineAccounts: {},
        editUserMapping: {},
        editPreferredDomainControllers: {},
        editTrustedDomains: {},
      },
      gates: {
        dataReady: false,
        isEditModeLdapProvider: false,
        isEditModeMachineAccounts: false,
        principalsFetchError: false,
        savingMachineAccounts: false,
        unresolvedSid: false,
      },
      invalidMachineNames: [],
      mappingTypes: {
        kCentrify: {
          type: 'kCentrify',
          nameKey: ENUM_AD_USER_MAPPINGS['kCentrify'],
          requireFallback: true,
        },
        /* TODO: Not supported in 6.0. Re-enable ~6.2.
        kCustomAttributes: {
          type: 'kCustomAttributes',
          nameKey: ENUM_AD_USER_MAPPINGS['kCustomAttributes'],
          requireFallback: true,
        },
        */
        kFixed: {
          type: 'kFixed',
          nameKey: ENUM_AD_USER_MAPPINGS['kFixed'],
          requireFallback: false,
        },
        kLdapProvider: {
          type: 'kLdapProvider',
          nameKey: ENUM_AD_USER_MAPPINGS['kLdapProvider'],
          requireFallback: true,
        },
        kRid: {
          type: 'kRid',
          nameKey: ENUM_AD_USER_MAPPINGS['kRid'],
          requireFallback: false,
        },
        kRfc2307: {
          type: 'kRfc2307',
          nameKey: ENUM_AD_USER_MAPPINGS['kRfc2307'],
          requireFallback: true,
        },
        kSfu30: {
          type: 'kSfu30',
          nameKey: ENUM_AD_USER_MAPPINGS['kSfu30'],
          requireFallback: true,
        },
      },
      newAdConfig: angular.copy(defaultAdConfig),
      storageDomainsHashByLdap: {},


      // Methods
      $onInit: $onInit,
      addLdapProviderModal: addLdapProviderModal,
      deleteIdMappingInfo: deleteIdMappingInfo,
      editLdapProvider: editLdapProvider,
      editMachineAccounts: editMachineAccounts,
      editPreferredDomainControllers: editPreferredDomainControllers,
      editTrustedDomains: editTrustedDomains,
      setTrustedDomainsState: setTrustedDomainsState,
      fetchCentrifyZones: fetchCentrifyZones,
      filterDomains: filterDomains,
      getAffectedStorageDomains: getAffectedStorageDomains,
      getDisabledLdapChoiceTooltip: getDisabledLdapChoiceTooltip,
      getLdapProvider: LdapService.getLdapProvider,
      getPrincipal: getPrincipal,
      hasPartialConfig: hasPartialConfig,
      isAddPreferredButtonDisabled: isAddPreferredButtonDisabled,
      isLdapChoiceDisabled: isLdapChoiceDisabled,
      leaveDomain: leaveDomain,
      machineNamesHandler: machineNamesHandler,
      onSelectFallbackMappingType: onSelectFallbackMappingType,
      onSelectMappingType: onSelectMappingType,
      saveIdMappingInfo: saveIdMappingInfo,
      saveIgnoredTrustedDomains: saveIgnoredTrustedDomains,
      saveLdapProvider: saveLdapProvider,
      saveMachineAccounts: saveMachineAccounts,
      savePreferredDomainControllers: savePreferredDomainControllers,
      showAffectedStorageDomainsMessage: showAffectedStorageDomainsMessage,
      toggleIdMappingInfo: toggleIdMappingInfo,
      viewboxes: ViewBoxCacheService.viewBoxes,
    });

    /**
     * Initializes the controller.
     *
     * @method   $onInit
     */
    function $onInit() {
      var domainName = $ctrl.adDomainName;
      var promises = {
        ldap: LdapService.getLdapProviders(),
        storageDomain: ViewBoxService.getViewBoxes(),
      };

      if (!domainName) {
        return $state.go('access-management.active-directory');
      }

      $q.all(promises).then(
        function getDependenciesSuccess(responses) {

          // LDAP Providers
          // Concat the list of LDAP Providers with a None option.
          $ctrl.ldapProviders = [{name: 'none'}].concat(responses.ldap);

          // Stub the hash of ViewBoxes by LDAP id. We stub it separately
          // (instead of with the populate iterator, below) because we need to
          // ensure every LDAP has an entry, even if an empty array.
          $ctrl.storageDomainsHashByLdap[undefined] = [];
          responses.ldap.forEach(function stubStorageDomainsHash(ldap) {
            $ctrl.storageDomainsHashByLdap[ldap.id] = [];
          });

          // Populate the hash of ViewBoxes by LDAP id.
          responses.storageDomain.forEach(
            function hashViewBoxes(storageDomain) {
              if (storageDomain.ldapProviderId) {
                $ctrl.storageDomainsHashByLdap[storageDomain.ldapProviderId]
                  .push(storageDomain);
              }
            }
          );
        }
      );

      _getActiveDirectory(domainName);

      UserService.getAllUsers({domain: 'LOCAL'}).then(
        function gotLocalUsers(users) {
          users.forEach(ActiveDirectoryService.addPrincipalToCache);
        },
        _.noop
      );
    }

    /**
     * Gets Active Directory for specified Domain name.
     *
     * @method   _getActiveDirectory
     * @param    {String}   domainName    Domain name.
     */
    function _getActiveDirectory(domainName) {
      ActiveDirectoryService.getActiveDirectoryByDomainName(domainName)
        .then(function getActiveDirectorySuccess(ad) {
          $ctrl.ad = ad;

          // If no match, then leave.
          if (!$ctrl.ad) {
            return $state.go('access-management.active-directory');
          }

          // Disable modifications(delete) if the user is not an owner of the
          // AD.
          if (!$ctrl.ad._isAdOwner) {
            $ctrl.disableModifications = true;
          }

          // Cache long property chains for cleaner template.
          $ctrl.mappingInfo = $ctrl.ad.userIdMappingInfo;
          $ctrl.fallbackInfo = $ctrl.ad.fallbackUserIdMappingInfo;

          $ctrl.visibleDomains =
            [].concat($ctrl.ad.domainName, $ctrl.ad.trustedDomains);

          // LOCAL is a valid domain for user id mapping
          $ctrl.visibleMappingDomains = ['LOCAL'].concat($ctrl.visibleDomains);

          $ctrl.allDomains =
            $ctrl.visibleDomains.concat($ctrl.ad.ignoredTrustedDomains);

          _setupTrustedDomains($ctrl.ad);
          _setupDomainControllers($ctrl.visibleDomains);
          _setupAdPrincipals($ctrl.ad);
        },
        evalAJAX.errorMessage
      ).finally(function getActiveDirectoriesFinally() {
        $ctrl.gates.dataReady = true;
      });
    }

    /**
     * Sets up model and preloads any Principals already associated with the
     * Active Directory.
     *
     * @method     _setupAdPrincipals
     * @param      {Object}  domain  The active directory object
     */
    function _setupAdPrincipals(activeDirectory) {
      var sids = [];

      // For each domain, stub out the domain Principals hash.
      $ctrl.visibleDomains.forEach(function stubDomainHash(domain) {
        if (!$ctrl.domainPrincipalsHash[domain]) {
          $ctrl.domainPrincipalsHash[domain] = {};
        }
      });

      if (!activeDirectory.unixRootSid) {
        return;
      }

      $ctrl.gates.fetchingPrincipals = true;
      $ctrl.gates.principalFetchError = false;

      ActiveDirectoryService.getPrincipals([activeDirectory.unixRootSid]).then(
        function getPrincipalsSuccess(principalsList) {
          ActiveDirectoryService.addPrincipalsToHash(
            principalsList,
            $ctrl.domainPrincipalsHash
          );
        },
        function getPrincipalsFailure(response) {
          $ctrl.gates.principalsFetchError = true;
          evalAJAX.errorMessage(response);
        }
      ).finally(function getPrincipalsFinally() {
        $ctrl.gates.fetchingPrincipals = false;
      });
    }

    /**
     * Gets the Principal corresponding to provided sid.
     *
     * @method     getPrincipal
     * @param      {String}  sid     The sid
     * @return     {Object}  The principal.
     */
    function getPrincipal(sid) {
      var principal = ActiveDirectoryService.globalPrincipalsCache[sid] || sid;

      $ctrl.gates.unresolvedSid = typeof principal === 'string' ? true : false;

      return principal;
    }

    /**
     * Removes the given AD domain config. Pops a challenge modal to confirm the
     * action.
     *
     * @method     leaveDomain
     * @param      {Object}  config  Domain config object
     * @return     {Object}  $uibModal instance
     */
    function leaveDomain(config) {
      var modalConfig = {
        templateUrl: 'app/admin/access-management/active-directory/delete.html',
        controller: 'leaveAdController',
        controllerAs: '$ctrl',
        backdrop: true,
        keyboard: true,
        modalFade: true,
        resolve: {
          activeDirectory: function() {
            return angular.copy($ctrl.ad);
          },
        },
      };

      return $uibModal.open(modalConfig).result.then(
        function deleteSuccessCloseModal(response) {
          // Need a slight pause before the next state issues a new GET call
          // because the Leave Domain API response comes before the backend is
          // done updating cluster config.
          return $timeout($state.go('access-management.active-directory'), 100);
        }
      );
    }

    /**
     * Toggles edit mode for ID Mapping.
     *
     * @method     toggleIdMappingInfo
     * @param      {Boolean}  isEditMode  Indicates if edit mode
     */
    function toggleIdMappingInfo(isEditMode) {
      $ctrl.gates.isEditModeForUserMapping = isEditMode;
      if (isEditMode) {
        $ctrl.newAdConfig = angular.copy($ctrl.ad);

        // Stub missing default values.
        $ctrl.newAdConfig.userIdMappingInfo =
          $ctrl.newAdConfig.userIdMappingInfo || { type: 'kRid' };
        $ctrl.newAdConfig.fallbackUserIdMappingInfo =
          $ctrl.newAdConfig.fallbackUserIdMappingInfo || {
            type: 'kFixed',
            fixedMapping: defaultFixedMappingConfig,
          };

        // Reset the flag for fetching Centrify Zones.
        $ctrl.gates.firstZonesFetched = false;

        // Trigger initial type selection
        $ctrl.onSelectMappingType($ctrl.newAdConfig.userIdMappingInfo);
      } else {
        $ctrl.newAdConfig = {};
      }
    }

    /**
     * Based on selected mapping type, applies special handling for certain
     * mapping types
     *
     * @method     onSelectMappingType
     * @param      {Object}  selectedMapping  The ui-select selected item
     */
    function onSelectMappingType(selectedMapping) {
      var userIdMappingInfo = $ctrl.newAdConfig.userIdMappingInfo;

      if (selectedMapping.type === 'kCentrify') {
        // If no domain yet selected, then pre-select first domain in list.
        userIdMappingInfo.adDomain =
          (userIdMappingInfo.centrifyZoneMapping &&
          userIdMappingInfo.centrifyZoneMapping.zoneDomain) ||
          $ctrl.visibleDomains[0];

        // Pre-fetch Centrify Zones for the pre-selected domain.
        $ctrl.fetchCentrifyZones(userIdMappingInfo.adDomain);
      }
    }

    /**
     * Based on selected fallback mapping type, applies special handling for
     * certain mapping types
     *
     * @method     onSelectFallbackMappingType
     * @param      {Object}  selectedMapping  The ui-select selected item
     */
    function onSelectFallbackMappingType(selectedMapping) {
      var fallbackUserIdMappingInfo =
        $ctrl.newAdConfig.fallbackUserIdMappingInfo;

      if (selectedMapping.type === 'kFixed') {
        // Set default values for fixed mapping, if none.
        fallbackUserIdMappingInfo.fixedMapping =
          fallbackUserIdMappingInfo.fixedMapping || defaultFixedMappingConfig;
      }
    }

    /**
     * Fetches Centrify Zones for provided domain.
     *
     * @method     fetchCentrifyZones
     * @param      {String}  domain  The domain name
     */
    function fetchCentrifyZones(domain) {
      var mappingInfo = $ctrl.newAdConfig.userIdMappingInfo;

      $ctrl.gates.fetchingZones = true;

      // Empty the list of Centrify Zones for the ui-select.
      $ctrl.centrifyZones.length = 0;

      if ($ctrl.gates.firstZonesFetched) {
        // Empty the model for centrifyZoneMapping if this is not the first time
        // fetching Centrify Zones.
        mappingInfo.centrifyZoneMapping = undefined;
      } else {
        // Set the flag `true` so subsequent iterations are caught, above.
        $ctrl.gates.firstZonesFetched = true;
      }

      ActiveDirectoryService.getCentrifyZonesV2({domainName: domain}).then(
        function fetchCentrifyZonesSuccess(zones) {
          $ctrl.centrifyZones = zones;

          // Pre-select first Zone in the list if no existing value.
          mappingInfo.centrifyZoneMapping =
            mappingInfo.centrifyZoneMapping || zones[0];
        }
      ).finally(function fetchCentrifyZonesSuccess() {
        $ctrl.gates.fetchingZones = false;
      });
    }

    /**
     * Saves the User ID Mapping information.
     *
     * @method     saveIdMappingInfo
     * @param      {String}  [newAdConfig]  The new config to send to API
     */
    function saveIdMappingInfo(newAdConfig) {
      newAdConfig = newAdConfig || $ctrl.newAdConfig;

      if ($ctrl.forms.editUserMapping.$invalid) {
        return;
      }

      // Clean the model before issuing service call.
      // TODO: Remove this after 'kCustomAttributes' is supported, ~6.2.
      if (newAdConfig.userIdMappingInfo &&
        newAdConfig.type !== 'kCustomAttributes') {
        newAdConfig.userIdMappingInfo.customAttributesMapping = undefined;
      }

      $ctrl.gates.updatingDomain = true;

      ActiveDirectoryService.updateUserIdMapping(newAdConfig).then(
        function updateUserIdMappingSuccess(updatedActiveDirectory) {
          _setupAdPrincipals(updatedActiveDirectory);

          // Update local model.
          $ctrl.ad = updatedActiveDirectory;

          // Re-cache long property chains for cleaner template.
          $ctrl.mappingInfo = $ctrl.ad.userIdMappingInfo;
          $ctrl.fallbackInfo = $ctrl.ad.fallbackUserIdMappingInfo;

          $ctrl.gates.isEditModeForUserMapping = false;
        },
        evalAJAX.errorMessage
      ).finally(function updateUserIdMappingFinally(){
        $ctrl.gates.updatingDomain = false;
      });
    }

    /**
     * Displays confirmation modal. If confirmed, sends a request to
     * saveIdMappingInfo() with cleared ID Mapping Info.
     *
     * @method     deleteIdMappingInfo
     * @return     {Object}  standard modal instance
     */
    function deleteIdMappingInfo() {
      var options = {
        contentKey: 'ad.removeMapping.confirmation',
        actionButtonKey: 'remove',
        closeButtonKey: 'cancel',
      };

      return cModal.standardModal({}, options).then(
        function confirmModal() {
          // Copy the model so we don't blank out the model values, in the event
          // the user cancels the modal and expects the fields to still be
          // populated.
          saveIdMappingInfo(_.assign({}, $ctrl.newAdConfig, {
            unixRootSid: undefined,
            userIdMappingInfo: undefined,
            fallbackUserIdMappingInfo: undefined,
          }));
        }
      );
    }

    /**
     * Determines if there is any partial configuration for Multi Protocol
     * Network Access.
     *
     * @method     hasPartialConfig
     * @return     {Boolean}  True if partial configuration, False otherwise.
     */
    function hasPartialConfig() {
      return $ctrl.ad.unixRootSid ||
        $ctrl.ad.userIdMappingInfo ||
        $ctrl.ad.fallbackUserIdMappingInfo;
    }

    /**
     * Sets up edit mode for Machine Accounts.
     *
     * @method     editMachineAccounts
     */
    function editMachineAccounts() {
      $ctrl.newAdConfig = angular.copy($ctrl.ad);
      $ctrl.gates.isEditModeMachineAccounts = true;
    }

    /**
     * Saves the edited machine accounts.
     *
     * @method     saveMachineAccounts
     */
    function saveMachineAccounts() {
      var requestObj = {
        domainName: $ctrl.newAdConfig.domainName,
        userName: $ctrl.newAdConfig.userName,
        password: $ctrl.newAdConfig.password,
        machineAccounts: $ctrl.newAdConfig.machineAccounts,
        overwriteExistingAccounts: $ctrl.newAdConfig.overwriteExistingAccounts,
        dnsHostname: $ctrl.ad.dnsHostname,
        encryption: $ctrl.ad.encryption,
      };

      $ctrl.gates.savingMachineAccounts = true;

      ActiveDirectoryService.saveMachineAccounts(requestObj).then(
        function saveMachineAccountsSuccess(adConfig) {
          $ctrl.ad = adConfig;
          $ctrl.gates.isEditModeMachineAccounts = false;
        },
        evalAJAX.errorMessage
      ).finally(
        function saveMachineAccountsFinally() {
          $ctrl.gates.savingMachineAccounts = false;
        }
      );
    }

    /**
     * Passes through to a service method which handles validation of entered
     * tags.
     *
     * @method     machineNamesHandler
     * @param      {String}   value     The entered string value
     * @return     {Boolean}  returns false to notify ui-select not to add the
     *                        name
     */
    function machineNamesHandler(value) {
      return ActiveDirectoryService.machineNamesHandler(
        value,
        $ctrl.newAdConfig.machineAccounts,
        $ctrl.invalidMachineNames
      );
    }

    /**
     * Determines whether the selected LDAP ui-select option is selectable.
     *  1. 'None' is disabled if LDAP is the User ID Mapping type.
     *  2. The selected LDAP is disabled if it is already mapped to another
     *     Active Directory.
     *
     * @method     isLdapChoiceDisabled
     * @param      {Object}     ldap    The LDAP provider
     */
    function isLdapChoiceDisabled(ldap) {
      return _isLdapTheUserMappingType(ldap) || _isLdapMappedToAnotherAd(ldap);
    }

    /**
     * Returns tooltip translation key for disabled LDAP option.
     *
     * @method     getDisabledLdapChoiceTooltip
     * @param      {Object}     ldap    The LDAP provider
     * @returns    {String}    tooltip translation key
     */
    function getDisabledLdapChoiceTooltip(ldap) {
      var key = '';

      if (_isLdapTheUserMappingType(ldap)) {
        key = 'ad.ldapIsUserIdMapping';
      } else if (_isLdapMappedToAnotherAd(ldap)) {
        key = 'ad.alreadyMappedTooltip';
      }

      return key;
    }

    /**
     * Determines whether LDAP Provider is the User ID Mapping type.
     *
     * @method     _isLdapTheUserMappingType
     * @param      {Object}     ldap    an LDAP Provider
     * @returns    {Boolean}    true if is the selected User ID Mapping type
     */
    function _isLdapTheUserMappingType(ldap) {
      return ldap.name === 'none' && $ctrl.ad.unixRootSid &&
        $ctrl.newAdConfig.userIdMappingInfo.type === 'kLdapProvider';
    }
    /**
     * Determines whether LDAP Provider is already mapped to another AD.
     *
     * @method     _isLdapMappedToAnotherAd
     * @param      {Object}     ldap    an LDAP Provider
     * @returns    {Boolean}    true if already mapped to another AD
     */
    function _isLdapMappedToAnotherAd(ldap) {
      return ldap.adDomainName && ldap.adDomainName !== $ctrl.ad.domainName;
    }

    /**
     * Sets up edit mode for LDAP Provider.
     *
     * @method     editLdapProvider
     */
    function editLdapProvider() {
      $ctrl.newAdConfig = angular.copy($ctrl.ad);
      $ctrl.gates.isEditModeLdapProvider = true;
    }

    /**
     * Saves the edited LDAP Provider.
     *
     * @method     saveLdapProvider
     */
    function saveLdapProvider() {
      var requestObj = {
        domainName: $ctrl.newAdConfig.domainName,
        ldapProviderId: $ctrl.newAdConfig.ldapProviderId,
      };

      var authData = {};

      if (!!$ctrl.ad.ldapProviderId && !$ctrl.newAdConfig.ldapProviderId &&
        $ctrl.storageDomainsHashByLdap[$ctrl.ad.ldapProviderId][0]) {
        // Trying to break the link between AD and LDAP. User must specify which
        // one should remain associated with the Storage Domains. Open modal to
        // prompt user. On Cancel, exit function. Otherwise continue.

        _.assign(authData, {
          adDomainName: $ctrl.ad.domainName,
          ldapProviderId: $ctrl.ad.ldapProviderId,

          // Identify Storage Domains which had been mapped to this AD + LDAP.
          storageDomains:
            $ctrl.storageDomainsHashByLdap[$ctrl.ad.ldapProviderId],
        });

        // Open the modal which asks user to select preferred auth provider for
        // the affected Storage Domains.
        LdapService.openBreakAuthMappingModal(authData).then(
          function modalConfirmed(response) {
            _updateLdapProvider(requestObj, response);
          }
        );
      } else {
        // When selecting a different LDAP Provider, it is straightfoward.
        _updateLdapProvider(requestObj);
      }
    }

    /**
     * Issues the update LDAP API.
     *
     * @method   isMappedToAnother
     * @param    {Object}   requestObj   AD Config request object.
     * @param    {Object}   [authData]   Optional info for updating storage
     *                                   domains after breaking link between
     *                                   LDAP and AD
     */
    function _updateLdapProvider(requestObj, authData) {
      $ctrl.gates.savingLdapProvider = true;

      ActiveDirectoryService.saveLdapProvider(requestObj).then(
        function saveLdapProviderSuccess(adConfig) {
          $ctrl.ad = adConfig;
          $ctrl.gates.isEditModeLdapProvider = false;

          if (authData) {
            // User broke the mapping between LDAP and AD. And this will reset
            // both values in the Storage Domain. Now we need to go update all
            // the affected Storage Domains with the user's preferred auth
            // provider.
            _updateStorageDomains(authData);
          }
        },
        evalAJAX.errorMessage
      ).finally(
        function saveLdapProviderFinally() {
          $ctrl.gates.savingLdapProvider = false;
        }
      );
    }

    /**
     * Issues the update Storage Domains API with each preferred auth provider.
     *
     * @method   _updateStorageDomains
     * @param    {Object}   authdata   object containing auth provider info
     *                                 and affected Storage Domains
     */
    function _updateStorageDomains(authdata) {
      ViewBoxService.updateStorageDomains(authdata,
        $ctrl.gates.updatingStorageDomains);
    }

    /**
     * Compiles a deduped list of the Storage Domains affected by a change to
     * the LDAP mapping.
     */
    function getAffectedStorageDomains() {
      return _.uniqBy($ctrl.storageDomainsHashByLdap[$ctrl.ad.ldapProviderId]
        .concat($ctrl.storageDomainsHashByLdap[$ctrl.newAdConfig.ldapProviderId]
      ), 'id');
    }

    /**
     * Determines whether to show the message about affected Storage Domains. We
     * want to show this message for existing Storage Domains mapped to either
     * the existing LDAP or the selected LDAP.
     */
    function showAffectedStorageDomainsMessage() {
      // Not the current selection AND
      return $ctrl.newAdConfig.ldapProviderId !== $ctrl.ad.ldapProviderId &&

        // The current LDAP is mapped to existing Storage Domains OR
        ($ctrl.storageDomainsHashByLdap[$ctrl.ad.ldapProviderId][0] ||

        // The selected LDAP is mapped to existing Storage Domains.
        $ctrl.storageDomainsHashByLdap[$ctrl.newAdConfig.ldapProviderId][0]);
    }

    /**
     * Opens a modal to create new LDAP Provider and then sets the model with
     * the newly created one.
     *
     * @method   addLdapProviderModal
     */
    function addLdapProviderModal() {
      $ctrl.loading = true;

      LdapService.newLdapProviderModal().then(
        function modalResolved(newLdapProvider) {
          /**
           * An LDAP POST response does not have an `id` because iris sends out
           * two concurrent requests to bridge. The other one updates cluster
           * config and only that request contains the unique ID. Therefore, we
           * must now GET a new list and take the last item in the list which is
           * always the most recently added.
           *
           * TODO(David): When API is fixed ~6.2, delete the following three
           * lines and simply consume the `newLdapProvider` response from the
           * modal.
           */
          LdapService.getLdapProviders().then(
            function getUpdatedLdapListSuccess(ldapList) {
              var newLdapProvider = ldapList[ldapList.length - 1];

              if (!newLdapProvider) {
                // Empty list of LDAP Providers, so exit early.
                return;
              }

              // Add this new LDAP Provider to the ui-select list.
              $ctrl.ldapProviders.push(newLdapProvider);

              // Stub new bucket in the Storage Domain hash.
              $ctrl.storageDomainsHashByLdap[newLdapProvider.id] = [];

              // Select this LDAP Provider in the ui-select list.
              $ctrl.newAdConfig.ldapProviderId = newLdapProvider.id;
            }
          );
        }
      )
      .finally(function modalCloseFinally() {
        $ctrl.loading = false;
      });
    }

    /**
     * Sets up the Preferred Domain Controllers edit mode.
     *
     * @method     editPreferredDomainControllers
     */
    function editPreferredDomainControllers() {
      $ctrl.gates.isEditModeForPreferredDomain = true;

      // Close Trusted Domains panel because we don't want both sections edited
      // simultaneously.
      $ctrl.gates.isEditModeForTrustedDomains = false;

      $ctrl.unsavedControllers =
        _.clone($ctrl.ad.preferredDomainControllers) || [];

      // If the list is empty, stub with the first row to edit.
      if (!$ctrl.unsavedControllers[0]) {
        $ctrl.unsavedControllers.push({});
      }
    }

    /**
     * Save changes to the list of Preferred Domain Controllers.
     *
     * @method    savePreferredDomainControllers
     * @param     {Object[]}   controllers    The list of controllers
     * @returns   {Object}    Promise to update the list of preferred domain
     *                        controllers.
     */
    function savePreferredDomainControllers(controllers) {
      var data = {
        domainName: $ctrl.ad.domainName,
        preferredDomainControllers: controllers,
      };

      $ctrl.gates.updatingPreferredDomains = true;

      return ActiveDirectoryService.updatePreferredDomainControllers(data).then(
        function updatePreferredDomainControllersSuccess(response) {
          $ctrl.ad.preferredDomainControllers =
            response.preferredDomainControllers;

          return $ctrl.gates.isEditModeForPreferredDomain = false;
        },
        evalAJAX.errorMessage
      ).finally(function updatePreferredDomainControllersFinally() {
        $ctrl.gates.updatingPreferredDomains = false;
      });
    }

    /**
     * Sets up model and fetches Domain Controllers for the list of AD and
     * trusted domains.
     *
     * @method     _setupDomainControllers
     * @param      {String[]}  allDomains  List of AD and trusted domain names
     */
    function _setupDomainControllers(allDomains) {
      // For each domain, stub out the domain controllers hash and get the
      // domain controllers.
      allDomains.forEach(function forEachDomain(domain) {
        if (!$ctrl.domainControllersHash[domain]) {
          $ctrl.domainControllersHash[domain] = [];
        }
        ActiveDirectoryService.fetchDomainControllers({domainName: domain})
          .then(function fetchDomainControllersSuccess(controllers) {
            $ctrl.domainControllersHash[domain] = controllers;
          })
          .catch(function fetchDomainControllersFailure() {
            $log.error('No domain controllers found for domain: ' + domain);
          });
      });
    }

    /**
     * Filters out specified domain if it is blacklisted or already has
     * specified Preferred Domain Controllers.
     *
     * @method      filterDomains
     * @param       {String}     domain     domain name to test
     * @returns     {Boolean}    false if found it and want to exclude
     */
    function filterDomains(domain) {
      var includeDomain = true;

      $ctrl.unsavedControllers.some(function(row) {
        if ((row.domainControllers && row.domainName === domain) ||
          ($ctrl.ad.ignoredTrustedDomains &&
          $ctrl.ad.ignoredTrustedDomains.includes(domain))) {
          includeDomain = false;
          return true;
        }
      });

      return includeDomain;
    }

    /**
     * Determines whether to disable the Add button for Preferred Domain
     * Controllers. We enable the button if there is at least one domain which
     * is not blacklisted and does not yet have specified Preferred Domain
     * Controllers.
     *
     * @method      isAddPreferredButtonDisabled
     * @returns     {Boolean}     true if button should be disabled
     */
    function isAddPreferredButtonDisabled() {
      return !_.difference(
        $ctrl.visibleDomains,
        _.map($ctrl.unsavedControllers, 'domainName')
      ).length;
    }

    /**
     * Sets up the Trusted Domains local model.
     *
     * @method     _setupTrustedDomains
     */
    function _setupTrustedDomains(adConfig) {
      var _domains = $ctrl._domains = [];

      // Push all trusted and blacklisted domains into a single array of objects
      // for the edit blacklist component.
      _.forEach(adConfig.trustedDomains, function siftTrusted(domain) {
        _domains.push({
          domainName: domain,
          blacklisted: false,
        });
      });
      _.forEach(adConfig.ignoredTrustedDomains,
        function siftBlacklisted(domain) {
          _domains.push({
            domainName: domain,
            blacklisted: true,
          });
        });

      // Sort list so the order is consistent irrespective of which ones are
      // blacklisted. This is necessary because we list them separately but edit
      // them together.
      _domains = _.sortBy(_domains, 'domainName');

      $ctrl.gates.discoveringTrustedDomains = false;
    }

    /**
     * Saves changes to the list of Ignored Trusted Domains.
     *
     * @method     saveIgnoredTrustedDomains
     * @returns   {Object}      Promise to update the list of trusted domains.
     */
    function saveIgnoredTrustedDomains() {
      var data = {
        domainName: $ctrl.ad.domainName,
        trustedDomains: [],
        ignoredTrustedDomains: [],
      };

      // Separate the domains into their respective buckets.
      $ctrl._domains.forEach(function siftDomains(domain) {
        if (domain.blacklisted) {
          data.ignoredTrustedDomains.push(domain.domainName);
        } else {
          data.trustedDomains.push(domain.domainName);
        }
      });

      $ctrl.gates.updatingTrustedDomains = true;

      return ActiveDirectoryService.updateIgnoredTrustedDomains(data).then(
        function updateIgnoredTrustedDomainsSuccess(response) {
          // TODO(David)~6.2: The following line should be removed when(if) the
          // AD APIs return a truly updated version of the PUT. As it is, the
          // PUT response is sent back before it gets updated by backend. This
          // issue exists for all AD/LDAP APIs. Therefore we have to overwrite
          // the out-of-date list of trusted domains based on what the user just
          // did.
          response.trustedDomains = data.trustedDomains;

          $ctrl.ad.trustedDomains = response.trustedDomains;
          $ctrl.ad.ignoredTrustedDomains = response.ignoredTrustedDomains;

          // Re-align the visibleDomains with changes to blacklist.
          $ctrl.visibleDomains =
            [].concat(response.domainName, response.trustedDomains);

          _setupDomainControllers($ctrl.visibleDomains);

          // LOCAL is a valid domain for user id mapping
          $ctrl.visibleMappingDomains = ['LOCAL'].concat($ctrl.visibleDomains);

          $ctrl.gates.isEditModeForTrustedDomains = false;
        },
        evalAJAX.errorMessage
      ).finally(function updateIgnoredTrustedDomainsFinally() {
        $ctrl.gates.updatingTrustedDomains = false;
      });
    }

    /**
     * Sets up the Trusted Domains edit mode.
     *
     * @method     editTrustedDomains
     */
    function editTrustedDomains() {
      var gates = $ctrl.gates;
      gates.isEditModeForTrustedDomains = true;

      // Close Preferred Domain Controllers panel because we don't want both
      // sections edited simultaneously.
      gates.isEditModeForPreferredDomain = false;
    }

    /**
     * Sets the state of Trusted Domains Discovery.
     *
     * @method   setTrustedDomainsState
     * @param    {Boolean}   trustedDomainsEnabled
     */
    function setTrustedDomainsState(trustedDomainsEnabled) {
      var params = {
        domainName: $ctrl.ad.domainName,
        trustedDomainsEnabled: !!trustedDomainsEnabled,
      };

      // When disabling, clear the list of Trusted Domains.
      if (!trustedDomainsEnabled) {
        $ctrl.ad.trustedDomains.length = 0;
      }

      $ctrl.gates.isEditModeForTrustedDomains = false;
      $ctrl.gates.updatingTrustedDomains = true;

      ActiveDirectoryService.setTrustedDomainsState(params).then(
        function setTrustedDomainStateSuccess() {
          // After enabling discovery of Trusted Domains, wait two seconds and
          // fetch AD again to ensure Trusted Domains have been discovered.
          if (trustedDomainsEnabled) {
            $ctrl.gates.discoveringTrustedDomains = true;
            _.delay(function waitAndRefreshAd() {
              _getActiveDirectory(params.domainName);
            }, 2000);
          }
        },
        evalAJAX.errorMessage
      ).finally(function setTrustedDomainStateFinally() {
        $ctrl.gates.updatingTrustedDomains = false;
      });
    }

  }

})(angular);
