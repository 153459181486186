<div class="flex-column full-width">
  <h3 translate class="title">inboundDnsOptional</h3>
  <p translate>inboundDnsDescription</p>
  <button mat-icon-button type="button" class="add-button" (click)="addEditRow()" cohStopPropagation>
    <cog-icon shape="add"></cog-icon>
    {{ 'add' | translate }}
  </button>
</div>
<div class="vertical-align margin-bottom"
  *ngFor="let delegationZoneModel of model; let i = index">
  <coh-dns-vips-details
    [model]="delegationZoneModel"
    [index]="i"
    (remove)="removeRow($event)"
    (edit)="addEditRow($event)">
  </coh-dns-vips-details>
</div>
