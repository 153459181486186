import { ProtectionSourceNode } from '@cohesity/api/v1';
import { DataTreeSelection } from '@cohesity/helix';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { Environment } from 'src/app/shared/constants';

import { ProtectionSourceDataNode } from '../shared/protection-source-data-node';
import { SourceNodeMetadata } from '../shared/protection-source-metadata/source-node-metadata';
import { IbmProtectionTypes } from './ibmflashsystem.constants';

export type IbmFlashSystemSelection = DataTreeSelection<IbmFlashSystemSourceDataNode>;

/**
 * Represents Ibm FlashSystem volume source node and job tree selection behavior.
 */
export class IbmFlashSystemSourceDataNode extends ProtectionSourceDataNode {
  /**
   * Metadata for Ibm FlashSystem node.
   */
  readonly metadata: SourceNodeMetadata;

  /**
   * level of the node in the tree.
   */
  level: number;

  constructor(
    data: ProtectionSourceNode,
    private irisCtx: IrisContextService,
    readonly nodeLevel: number) {
    super(Environment.kIbmFlashSystem, data, nodeLevel);
    const volume = this.protectionSource.ibmFlashSystemProtectionSource.volume || {};
    this.metadata = {
      provisionedBytes: volume.sizeBytes,
      logicalSize: volume.usedBytes,
      leafCount: this.expandable ? this.leafCount : undefined,
      serialNumber: volume.serialNumber,
    };
    this.level = nodeLevel;
  }

  /**
   * Used as a callback function to determine if a node can be auto protected.
   */
  canAutoSelect(currentSelection: IbmFlashSystemSelection = {}): boolean {
    if (flagEnabled(this.irisCtx.irisContext, 'ibmFlashSystemEnabled')) {
      return this.isIbmFlashSystemProtectionGroup;
    }
    return super.canAutoSelect(currentSelection);
  }

  /**
   * Whether a node could be selected in the tree. If this is false, the checkbox will be hidden
   * in the tree. This is different from canSelect, which can be used to disable a checkbox.
   */
  get isSelectable(): boolean {
    if (flagEnabled(this.irisCtx.irisContext, 'ibmFlashSystemEnabled')) {
      return (this.isIbmFlashSystemProtectionGroup && this.level === 1) ||
        (this.isLeaf && this.level === 0);
    }
    return super.isSelectable;
  }

  /**
   * volumes in volume listing view and storage array in PPG view
   * are not eligible for auto select in kPure.
   */
  get ineligibleForAutoSelect(): boolean {
    return this.level === 0;
  }

  /**
   * Is this node a leaf node in the physical hierarchy view.
   */
  get isLeaf(): boolean {
    return this.type === IbmProtectionTypes.kVolume;
  }

  /**
   * Is this node a Ibm FlashSystem protection group.
   */
  get isIbmFlashSystemProtectionGroup(): boolean {
    return this.type === IbmProtectionTypes.kVolumeGroup;
  }
 }
