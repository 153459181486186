import { Pipe, PipeTransform } from '@angular/core';
import { Environment, enumGroupMap, RecoveryOsType, RecoveryAction } from 'src/app/shared/constants';
import { PureProtectionTypes } from '../source-tree/protection-source/pure/pure.constants';
import { IbmProtectionTypes } from '../source-tree/protection-source/ibmflashsystem/ibmflashsystem.constants';

/**
 * Converts a recovery object environment to an icon name
 *
 * @example
 *   <cog-icon [shape]="object.objectType | recoveryObjectIcon: object.environment : object.osType"></cog-icon>
 */
@Pipe({
  name: 'recoveryObjectIcon'
})
export class RecoveryObjectIconPipe implements PipeTransform {
  /**
   * Converts a recovery object environment to an icon name.
   *
   * @param   objectType  Recovery object type
   * @param   environment  Recovery object environment
   * @param   osType  Recovery object OS type
   * @param   recoveryAction  Recovery action
   * @returns The full icon name used in cog-icon
   */
  transform(objectType: string, environment: string, osType: string, recoveryAction: string): string {
    const envGroup = enumGroupMap[environment];
    let iconName = 'generic';

    switch (envGroup) {
      case 'vms':
        switch (environment) {
          case Environment.kAWS:
            iconName = recoveryAction === RecoveryAction.RecoverRDS ? 'db' : 'aws';
            break;

          case Environment.kAzure:
            iconName = 'azure';
            break;

          case Environment.kGCP:
            iconName = 'gcp';
            break;

          case Environment.kHyperV:
            iconName = 'vm-hyperv';
            break;

          default:
            switch (osType) {
              case RecoveryOsType.kAix:
                iconName = 'vm-aix';
                break;

              case RecoveryOsType.kLinux:
                iconName = 'vm-linux';
                break;

              case RecoveryOsType.kSolaris:
                iconName = 'vm-solaris';
                break;

              case RecoveryOsType.kWindows:
                iconName = 'vm-windows';
                break;

              default:
                iconName = recoveryAction === RecoveryAction.RecoverVApps ? 'vcloud-vapp' : 'vm';
                break;
            }
            break;
        }
        break;

      // TODO(ang): Need to check objectType for each environment
      case 'applications':
        switch (environment) {
          case Environment.kAD:
            iconName = 'ad';
            break;

          // kO365Outlook is deprecated.
          case Environment.kO365Outlook:
            iconName = 'email';
            break;

          case Environment.kExchange:
            iconName = 'db';
            break;

          default:
            iconName = 'vm-app';
            break;
        }
        break;

      case 'office365':
        switch (objectType) {
          case 'kUser':
            iconName = 'user';
            break;

          case 'kGroup':
            iconName = 'o365-msgroup';
            break;

          case 'kSite':
            iconName = 'o365-sites';
            break;

          case 'kPublicFolder':
            iconName = 'o365-publicfolder';
            break;

          case 'kTeam':
            iconName = 'o365-msteam';
            break;

          default:
            iconName = 'o365-onedrive';
            break;
        }
        break;

      case 'physical':
        switch (osType) {
          case RecoveryOsType.kAix:
            iconName = 'aix';
            break;

          case RecoveryOsType.kLinux:
            iconName = 'linux';
            break;

          case RecoveryOsType.kSolaris:
            iconName = 'solaris';
            break;

          case RecoveryOsType.kWindows:
            iconName = 'windows';
            break;

          // TODO(ang): Check with UX on what default icon to use.
          default:
            iconName = 'server';
            break;
        }
        break;

      case 'nas':
        if (environment === Environment.kIsilon) {
          iconName = 'isilon';
        } else {
          iconName = 'volume';
        }
        break;

      case 'san':
        switch (objectType) {
          case PureProtectionTypes.kPureProtectionGroup:
            iconName = 'pure-protection-group';
            break;
          case IbmProtectionTypes.kVolumeGroup:
            iconName = 'ibmflashsystem-protection-group';
            break;
          case PureProtectionTypes.kVolume:
            iconName = 'volume';
            break;
        }
        break;

      case 'kubernetes':
        iconName = 'kubernetes';
        break;

      case 'dbs':
        iconName = 'db';
        break;

      case 'views':
        iconName = 'view';
        break;
    }

    return `helix:object-${iconName}`;
  }
}
