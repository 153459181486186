import { DataRenderer, TextDataRendererComponent } from '@cohesity/helix';

/**
 * Renders a backup type value, prepending it with a prefix and calling translate on it.
 */
export const backupTypeTextRenderer = {
  transform: (renderValues: DataRenderer<string, undefined, undefined>) => ({
    ...renderValues,
    value: (renderValues.value || renderValues.value !== '') ?
      renderValues.translate(`enum.jobRunType.${renderValues.value}`) :
      '',
  }),
  component: TextDataRendererComponent,
};
