import { ReportColumnConfigs } from '../common-report-items/custom-reports.service';

/**
 * This is the layout config for the object growth report.
 */
export const commonGrowthReportConfigItems = {
  glanceBar: [
    {
      title: 'reporting.totalSourceData',
      translate: true,
      measurement: {
        valueKey: 'sumSrcDataBytes',
        dataType: 'bytes',
      },
    },
    {
      title: 'sourceDataGrowth',
      translate: true,
      measurement: {
        valueKey: 'sumSrcDataBytesGrowth',
        dataType: 'bytes',
      }
    },
    {
      title: 'dataRead',
      translate: true,
      measurement: {
        valueKey: 'sumDataIngestedBytes',
        dataType: 'bytes',
      }
    },
  ],
  table: [
    {
      title: 'system',
      enabled: false,
      translate: 'true',
      key: 'system',
      primary: {
        key: 'system',
        dataType: 'text',
      },
    },
    {
      title: 'organization',
      enabled: false,
      translate: true,
      key: 'tenantName',
      primary: {
        key: 'tenantName',
        dataType: 'text',
        renderParams: {
          emptyKey: 'naNotAvailable',
        },
      },
      featureFlag: 'ngReportSPView',
    },
    {
      title: 'sourceData',
      enabled: false,
      translate: true,
      hasSeparator: true,
      key: 'srcDataBytes',
      primary: {
        key: 'srcDataBytes',
        dataType: 'bytes',
      },
    },
    {
      title: 'sourceDataGrowth.table',
      translate: true,
      key: 'srcDataBytesGrowth',
      primary: {
        key: 'srcDataBytesGrowth',
        dataType: 'bytes',
      },
    },
    {
      title: 'sourceDataDailyGrowthRate',
      translate: true,
      key: 'srcDataDailyGrowthRate',
      primary: {
        key: 'srcDataDailyGrowthRate',
        dataType: 'bytes',
      },
    },
    {
      title: 'sourceDataDailyGrowthPercentage',
      translate: true,
      key: 'srcDataDailyGrowthPercent',
      primary: {
        key: 'srcDataDailyGrowthPercent',
        dataType: 'percent',
      },
    },
    {
      title: 'dataRead',
      translate: true,
      key: 'dataIngestedBytes',
      primary: {
        key: 'dataIngestedBytes',
        dataType: 'bytes',
      },
    },
  ],
};

/**
 * This is metadata needed to determine available chart customizations.
 */
export const commonGrowthColumns: ReportColumnConfigs = {
  environment: {
    aggs: ['count'],
    canGroup: true,
    maxGroupingValues: 3,
    dataType: 'text',
  },
  sourceName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  sourceUuid: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  system: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  systemId: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  srcDataBytes: {
    aggs: ['sum', 'min', 'max', 'avg'],
    dataType: 'bytes',
  },
  srcDataBytesGrowth: {
    aggs: ['sum', 'min', 'max', 'avg'],
    dataType: 'bytes',
  },
  srcDataDailyGrowthRate: {
    aggs: ['min', 'max', 'avg'],
    dataType: 'bytes',
  },
  srcDataDailyGrowthPercent: {
    aggs: ['min', 'max', 'avg'],
    dataType: 'bytes',
  },
  dataIngestedBytes: {
    aggs: ['sum', 'min', 'max', 'avg'],
    dataType: 'bytes',
  },
};
