import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatLegacySlideToggle as MatSlideToggle } from '@angular/material/legacy-slide-toggle';
import { OPEN_CLOSE_ANIMATION } from '../../../animations/index';

/**
 * Renders a generic form panel.
 *
 * The panel is simply a container that be used to hold any arbitrary content
 * inside it. The panel can be given a title and can also be made optional, in
 * which case it puts the content behind a toggle slider on the UI.
 *
 * @example
 * <cog-form-panel [title]="title" [optional] = "true"></cog-form-panel>
 */
@Component({
  selector: 'cog-form-panel',
  templateUrl: './form-panel.component.html',
  styleUrls: ['./form-panel.component.scss'],
  animations: [OPEN_CLOSE_ANIMATION],

  // Disabling encapsulation so that consumers can style the title depending
  // on styles of the workflow the panel is being embedded in.
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormPanelComponent implements AfterViewInit, OnInit {
  /**
   * Title to be displayed on the panel
   */
  @Input() title: string;

  /**
   * Whether to render an optional panel with a default initial expanded state or not.
   * (Only works with optional panels.)
   */
  @Input() defaultExpanded = false;

  /**
   * Specifies whether the panel is optional and to be put behind a toggle slider.
   */
  @Input() optional = false;

  /**
   * Handle to the slide toggle used to expand/collapse the form panel.
   */
  @ViewChild(MatSlideToggle, { static: false }) toggle: MatSlideToggle;

  ngOnInit(): void {
    if (!this.title && this.optional) {
      throw new Error('Title needs to be specified for optional panels');
    }
  }

  ngAfterViewInit() {
    // Only for an optional panel, if a default expanded value has been provided, use that
    // to show the default state for the form panel.
    if (this.optional) {
      this.toggle.checked = this.defaultExpanded ?? false;

      // Detect changes since the bindings would have been modified.
      this.cdr.detectChanges();
    }
  }

  constructor(private cdr: ChangeDetectorRef) {}
}
