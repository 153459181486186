<button mat-flat-button
  *ngIf="!isFab && ('PROTECTION_MODIFY' | userPrivileges)"
  [ngClass]="buttonStyle"
  id="show-protect-sources"
  cogDataId="protect-button"
  color="primary"
  [matMenuTriggerFor]="menu">
  <ng-container *ngIf="!customButtonLabel; else customLabel">
    {{ 'protect' | translate }}
  </ng-container>
  <ng-template #customLabel>{{ customButtonLabel }}</ng-template>
  <cog-icon shape="expand_more!outline"></cog-icon>
</button>
<button *ngIf="isFab && ('PROTECTION_MODIFY' | userPrivileges)"
  [matMenuTriggerFor]="menu"
  cogDataId="protect-button"
  id="protect-button"
  mat-mini-fab>
  <cog-icon shape="add"></cog-icon>
</button>
<mat-menu #menu="matMenu" class="{{ isFab ? 'cog-primary' : '' }}">
  <ng-container *ngFor="let source of sources">
    <!-- Environments supporting no sub menu. -->
    <button mat-menu-item
      id="{{source.id}}"
      uiSref="{{source.state}}"
      [cogDataId]="source.dataId"
      [uiParams]="getSourceStateParams(source)"
      *ngIf="!source.subStates">
      {{ source.translateKey | translate }}
    </button>

    <!-- Environment supporting sub menu states. -->
    <button mat-menu-item
      id="{{source.id}}"
      [cogDataId]="source.dataId"
      *ngIf="source.subStates?.length"
      [matMenuTriggerFor]="secondLevel">
      {{ source.translateKey | translate }}
    </button>

    <!-- Sub Menu states-->
    <mat-menu #secondLevel="matMenu" xPosition="before"
      class="{{ isFab ? 'cog-primary' : '' }}">
      <ng-container *ngFor="let state of source.subStates">
        <button mat-menu-item
          id="{{state.id}}"
          uiSref="{{state.state}}"
          [cogDataId]="state.dataId"
          [uiParams]="getSourceStateParams(state)">
          {{ state.translateKey | translate }}
        </button>
      </ng-container>
    </mat-menu>
  </ng-container>

</mat-menu>
