import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { nasEnvironments } from '@cohesity/iris-shared-constants';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';

import { QuietTimesConfig } from '../../../models';
import { BaseProtectionBuilderComponent } from '../../base-protection-builder/base-protection-builder.component';

/**
 * Default value for quiet times setting.
 */
export const DefaultValue = {
  enabled: false
};

@Component({
  selector: 'coh-settings-list-quiet-times',
  templateUrl: './settings-list-quiet-times.component.html',
  styleUrls: ['./settings-list-quiet-times.component.scss'],
})
export class SettingsListQuietTimesComponent
  extends BaseProtectionBuilderComponent<QuietTimesConfig, any> {

  /**
   * Default Quiet times value.
   */
  _value = DefaultValue;

  /**
   * Quiet Times form group
   */
  quietTimesFormGroup: UntypedFormGroup = new UntypedFormGroup({
    enabled: new UntypedFormControl(this.value.enabled, Validators.required),
    action: new UntypedFormControl(this.value.action)
  });

  constructor(private irisCtx: IrisContextService) {
    super();
  }

  /**
   * Gets the enabled control within quietTimes form group.
   *
   * @return   FormControl of the enabled toggle.
   */
  get quietTimesEnabledControl(): UntypedFormControl {
    return this.quietTimesFormGroup.get('enabled') as UntypedFormControl;
  }

  /**
   * Gets the enabled control within quietTimes form group.
   *
   * @return   FormControl of the enabled toggle.
   */
  get quietTimesActionControl(): UntypedFormControl {
    return this.quietTimesFormGroup.get('action') as UntypedFormControl;
  }

  /**
   * Called when the setting toggle is changed. The input control is added or
   * removed according to the toggle value.
   */
  toggleQuietTimes() {
    // If toggle is enabled, set default value for action
    if (this.quietTimesEnabledControl.value) {
      // If control exists, patch the value otherwise add control with default value
      if (this.quietTimesActionControl) {
        this.quietTimesFormGroup.patchValue({action: 'pause'});
      } else  {
        this.quietTimesFormGroup.addControl('action',
          new UntypedFormControl('pause', [Validators.required]));
      }
    } else {
      // If toggle is turned off, remove the action control
      this.quietTimesFormGroup.removeControl('action');
    }
  }

  /**
   * Add the Continuous Snapshots form group as control.
   */
  addFormControl() {
    this.formGroup.addControl(this.name, this.quietTimesFormGroup);
  }

  /**
   * Form control init.
   */
  initFormControl() {
    // we want env in both creation and edit flow.
    const env = this.formGroup.parent?.value?.source?._environment || this.protectionGroup?.environment;
    if (
      flagEnabled(this.irisCtx.irisContext, 'pauseRunsEnabled')
      && nasEnvironments.includes(env)
    ) {
      this.formGroup.addControl(this.name, new UntypedFormControl(this.value));
    } else {
      // Otherwise remove the form control
      this.formGroup.removeControl(this.name);
    }

    if (this.protectionGroup?.quietTimes) {
      this.value = this.protectionGroup.quietTimes;
      this.formControl.setValue(this.value);
    }
  }
}
