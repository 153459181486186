// Copy Task Status Icon Container
//
;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.statusIconContainer', [])
    .directive('statusIconContainer', statusIconContainerDirectiveFn);

  /**
   * @description
   * This directive will truncate a number of status icons for display within a
   * table cell
   *
   * @example
   * <status-icon-container icons="icons" max="2"></status-icon-container>
   */
  function statusIconContainerDirectiveFn() {
    var statusIconDirective = {
      restrict: 'E',
      templateUrl:
        'app/global/status-icon-container/status-icon-container.html',
      link: statusIconContainerLinkFn,
      scope: {

        // Array of icons config objects
        // {
        //      classNameSuffix: {String} 'canceled', 'error', 'success',
        //      'running'
        //      taskType: {String} 'tape', 'cloud', 'remote'
        //      tooltip: {String} 'whatevz'
        // }
        icons: '=',

        // Maximum number of icons to display
        // this county includes the '+ X more' icon
        // default value is 3
        max: '@?',
      },
    };

    return statusIconDirective;

    function statusIconContainerLinkFn(scope, elem, attrs) {
      scope.max = scope.max || 3;

      scope.showMoreIcon = false;

      if (scope.icons.length && scope.icons.length > scope.max) {
        scope.showMoreIcon = true;

        scope.hiddenCount = scope.icons.length - scope.max;
      }

      // Mapping to translate taskType strings into appropriate state names.
      scope.taskTypeToUiSref = {
        'cloud': 'job-run-details.archive',
        'tape': 'job-run-details.tape',
        'nas': 'job-run-details.nas',
        'remote': 'job-run-details.replication',
        'cloud-vm': 'job-run-details.clouddeploy'
      };

    }
  }
})(angular);
