import { ChangeDetectorRef, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Notifications, TaskNotification } from '@cohesity/api/v1';
import { IrisContextService } from '@cohesity/iris-core';
import { from } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AjsUpgradeService } from 'src/app/core/services/index';

// NOTE: Using ajs service as swagger documentation for notification PATCH
// endpoints is missing params information, making generated services unusable.
@Component({
  selector: 'coh-notifications-panel',
  templateUrl: './notifications-panel.component.html',
  styleUrls: ['./notifications-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsPanelComponent implements OnInit {
  // Upgraded AngularJS NotificationService
  ajsNotifService: any;

  // Notification info, as returned from API/service
  notificationsInfo: Notifications;

  // Indicates if data is currently being loaded from API/service.
  loading = true;

  /**
   * Returns a filtered list of "event" (non field message) notifications.
   */
  get eventTasks(): TaskNotification[] {
    return !this.notificationsInfo ? [] :
      this.notificationsInfo.notificationList.filter(this.isEventTask);
  }

  /**
   * Returns a filtered list with only field message notifications.
   */
  get fieldMessageTasks(): TaskNotification[] {
    return !this.notificationsInfo ? [] :
      this.notificationsInfo.notificationList.filter(this.isFieldMessageTask);
  }

  /**
   * Returns cluster basic info.
   */
  get basicClusterInfo(): any {
    return this.irisContext.irisContext.basicClusterInfo;
  }

  /**
   * Indicates if the provided task is an event (non field message) or not.
   *
   * @param   task   the notification to evaluate
   */
  isEventTask(task: TaskNotification): boolean {
    return task.taskType !== 'fieldMessage';
  }

  /**
   * Indicates if the provided task is a field message.
   *
   * @param   task   the notification to evaluate
   */
  isFieldMessageTask(task: TaskNotification): boolean {
    return task.taskType === 'fieldMessage';
  }

  /**
   * Dismisses all Notifications.
   *
   * @method    dismissAll
   */
  dismissAll() {
    // Get the ids of all notifications
    const ids = this.notificationsInfo.notificationList.map(notif => notif.id);

    // Make the request to dismiss all, updating the notifications list afterwards.
    from(this.ajsNotifService.markDismissed(ids))
      .pipe(finalize(() => this.loadNotifications()))
      .subscribe();
  }

  /**
   * Loads the current list of notifications and updates the controller accordingly.
   *
   * @method   loadNotifications
   */
  loadNotifications() {
    // Get the spinner loading and ensure the view gets updated.
    this.loading = true;
    this.cdr.markForCheck();

    from(this.ajsNotifService.getNotifications(false))
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        notifInfo => {
          this.notificationsInfo = notifInfo;
          this.cdr.markForCheck();
        }
      );
  }

  /**
   * Constructor
   */
  constructor(
    private cdr: ChangeDetectorRef,
    private ajsUpgrade: AjsUpgradeService,
    private irisContext: IrisContextService,
  ) {
    this.ajsNotifService = ajsUpgrade.get('NotificationService');
  }

  /**
   * Initialization function
   */
  ngOnInit() {
    this.loadNotifications();
  }

}
