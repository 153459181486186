import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { isDGaasScope, IrisContextService, IrisContext } from '../../../../iris-context';
import { BaseEntitlementBannerConfig } from '../base-entitlement-banner.config';
import { EntitlementKeys } from '../../../../app-configs';

@Injectable({
  providedIn: 'root',
})
export class DataHawkThreatProtectionEntitlementBanner extends BaseEntitlementBannerConfig {

  /**
   * The unique id for this entitlement banner
   */
  id = 'datahawk-threatprotection-entitlement-banner';

  /**
   * The key that identifieds the entitlement object
   * within the subscription data returned by the api
   */
  key = EntitlementKeys.ThreatProtection;

  /**
   * the scope that this banner should be available in
   */
  isScope = (ctx: IrisContext) => isDGaasScope(ctx, true);

  constructor(protected irisCtx: IrisContextService, protected translateService: TranslateService) {
    super(irisCtx, translateService);
  }
}
