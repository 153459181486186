import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';

import { PopoverContent, PopoverRef } from './popover-ref';
import {
  defaultBackdropClass,
  defaultPopoverOffsetX,
  defaultPopoverOffsetY,
  defaultPreferredPostions,
  PopoverConfig,
  PopoverService,
} from './popover.service';

/**
 * Attribute directive to decorate any html elements to open popover on click
 *
 * Example:
 * <button [cogPopover]="customTemplate">
 *  Button
 * </button>
 * <ng-template #customTemplate>
 * </ng-template>
 */
@Directive({
  selector: '[cogPopover]',
  exportAs: 'cogPopover',
  providers: [PopoverService]
})
export class PopoverDirective implements OnDestroy {
  /**
   * Popover content to be displayed inside popover
   */
  @Input('cogPopover') popoverInnerContent: PopoverContent;

  /**
   * Popover position
   */
  @Input() popoverPosition: 'left' | 'right' | 'bottom' | 'top';

  /**
   * Popover data which is passed to Inner component
   */
  @Input() popoverData: any;

  /**
   * Whether the popover is enabled or not. True by default.
   */
  @Input() popoverEnabled = true;

  /**
   * Whether the popover needs a backdrop or not
   */
  @Input() hasBackdrop = true;

  /**
   * Whether the popover should be shown on hover or click. If this is set to true and `hasBackdrop` is set to `true`,
   * it would need an explicite click to close the popover. To close the popover automatically, the `hasBackdrop` need
   * to falsy.
   */
  @Input() showOnHover = false;

  /**
   * horzontal offset for popover in pixels.
   */
  @Input() popoverOffsetX = defaultPopoverOffsetX;

  /**
   * vertical offset for popover in pixels.
   */
  @Input() popoverOffsetY = defaultPopoverOffsetY;

  /** Whether to style the popover as tooltip */
  @Input() tooltipStyling = false;

  /**
   * reference to the popover that is opened.
   */
  private popOverRef: PopoverRef;

  constructor(private elementRef: ElementRef, private popoverService: PopoverService) {}

  /**
   * Opens popover on click
   */
  @HostListener('click') onClick() {
    this.openPopover();
  }

  /**
   * Opens popover on hover
   */
  @HostListener('mouseenter') onMouseEnter() {
    if (this.showOnHover) {
      this.openPopover();
    }
  }

  /**
   * Closes popover on mouse leave, this will close the popover when there is no backdrop.
   */
  @HostListener('mouseleave') onMouseLeave() {
    // close the popover only when there is no backdrop, otherwise it will keep opening and closing the
    // popup.
    if (this.showOnHover && !this.hasBackdrop) {
      this.closePopover();
    }
  }

  ngOnDestroy(): void {
    this.closePopover();
  }

  /**
   * Internal function to call popoverService open(). Close the popover if it already
   * has atttached content
   */
  openPopover() {
    if (this.popOverRef && this.popOverRef.overlay.hasAttached()) {
      this.popOverRef.close();
    }

    const popoverConfig = this.computePopoverConfig();

    this.popOverRef = this.popoverEnabled &&
      this.popoverService.open(
        this.elementRef,
        this.popoverInnerContent,
        this.popoverData,
        popoverConfig,
        this.hasBackdrop
      );
  }

  closePopover() {
    if (this.popOverRef && this.popOverRef.overlay.hasAttached()) {
      this.popOverRef.close();
    }
  }

  /**
   * Compute configuration for popover config, the focus being on its positions
   *
   * @return popover configuration
   */
  computePopoverConfig(): Partial<PopoverConfig> {
    let preferredPositions: ConnectionPositionPair[];
    let out: Partial<PopoverConfig> = {
      panelClass: [
        this.tooltipStyling ? 'cog-popover-tooltip-styling' : null,
      ].filter(Boolean),
    };

    switch (this.popoverPosition) {
      case 'left':
        preferredPositions = [defaultPreferredPostions[1], defaultPreferredPostions[2], defaultPreferredPostions[3]];
        break;
      case 'top':
        preferredPositions = [defaultPreferredPostions[3], defaultPreferredPostions[2]];
        break;
      case 'bottom':
        preferredPositions = [defaultPreferredPostions[2], defaultPreferredPostions[3]];
        break;
    }

    if (preferredPositions) {
      out = {
        ...out,
        preferredPositions,
        popoverOffsetX: this.popoverOffsetX,
        popoverOffsetY: this.popoverOffsetY,
        backdropClass: defaultBackdropClass,
      };
    }

    return out;
  }
}
