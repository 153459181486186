import { Component, HostBinding, Input } from '@angular/core';
import { slideTransition } from '../carousel.animation';
import { CarouselService } from '../carousel.service';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
  selector: 'cog-carousel-item',
  templateUrl: './carousel-item.component.html',
  styleUrls: ['./carousel-item.component.scss'],
  animations: [slideTransition],
  standalone: true,
  imports: [NgIf, AsyncPipe]
})
export class CarouselItemComponent {

  /**
   * a flag to track active carousel item
   */
  @Input()
  active = false;

  @HostBinding('class')
  get hostClasses(): any {
    return {
      active: this.active,
      'carousel-item': true,
    };
  }

  constructor(readonly carouselService: CarouselService) {}
}
