<ul class="c-ul password-rules">
  <span class="margin-bottom-sm" translate>passwordRules.NewPasswordMustColon</span>
  <li>
    <span translate>passwordRules.MinimumCharacters</span>
  </li>
  <li>
    <span translate>passwordRules.AllCharacterClass</span>
  </li>
  <span class="margin-bottom-sm" translate>passwordRules.NewPasswordMustNotColon</span>
  <li>
    <span translate>passwordRules.MaximiumTwoConsecutiveSameCharacter</span>
  </li>
  <li *ngIf="!popoverRef?.data?.isNewPassword">
    <span translate>passwordRules.MaximumCharactersFromCurrentPassword</span>
  </li>
  <li>
    <span translate>passwordRules.MaximumFourCharactersFromSameClass</span>
  </li>
  <li>
    <span translate>passwordRules.SpecialCharacters</span>
  </li>
</ul>
