import { Injectable } from '@angular/core';
import { ProtectionSourceNode, ProtectionSourcesServiceApi } from '@cohesity/api/v1';
import { Environment } from '@cohesity/iris-shared-constants';
import { SourceTreeDataProvider } from '@cohesity/iris-source-tree';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PassthroughOptionsService } from 'src/app/core/services';

@Injectable()
export class AppProtectionSourceDataProvider implements SourceTreeDataProvider<ProtectionSourceNode> {
  constructor(
    private sourceService: ProtectionSourcesServiceApi,
    private passthroughOptionsService: PassthroughOptionsService
  ) {}
  /**
   * Load the entire source tree.
   *
   * @param sourceId            The parent id to load the tree for.
   * @param environment         The environment to loadd the tree for.
   * @param workloadType        The current workflow to use within the tree.
   * @param allUnderHierarchy   Whether to include all objects all nodes a user has access to
   * @returns An observable with the entire tree data
   */
  fetchTree(
    sourceId: number,
    environment: Environment,
  ): Observable<ProtectionSourceNode[]> {
    return this.sourceService
      .ListApplicationServers({
        protectionSourcesRootNodeId: sourceId,
        application: environment as any,
        protectionSourceId: sourceId,
        ...this.passthroughOptionsService.requestParams,
      })
      .pipe(map(response => [response[0].applicationServer]));
  }
}
