import { ObjectProtectionInfo, SearchObject } from '@cohesity/api/v2';

/**
 * Check if the given search object is protected or not.
 *
 * @param object search object that need to be evaluated
 * @returns true, if the object is protected. Otherwise, false.
 */
export function isObjectProtected(object: SearchObject): boolean {
  const hasProtection = object.objectProtectionInfos
    ?.some(info => hasGroupProtection(info) || hasObjectProtection(info));

  return hasProtection;
}

/**
 * Returns a list of object id's built from from protection info format as (clusterId:clusterIdentifier:ObjectId)
 *
 * @param protectionInfo Object details protection info.
 * @returns list of object ids extracted out of object details protection info.
 */
export function getObjectIdsFromObjectDetailsProtectionInfo(protectionInfo: ObjectProtectionInfo[]) {
  if (!protectionInfo) {
    return [];
  }

  return protectionInfo.map((pgInfo) => `${pgInfo.clusterId}:${pgInfo.clusterIncarnationId}:${pgInfo.objectId}`);
}

/**
 * Checks if the object has object protection or not
 *
 * @param protectionInfo protection information for the object
 * @returns true, if the object has the object protection. Otherwise, false.
 */
export function hasObjectProtection(protectionInfo: ObjectProtectionInfo) {
  return protectionInfo?.objectBackupConfiguration?.length > 0;
}

/**
 * Checks if the object has group protection or not
 *
 * @param protectionInfo protection information for an object
 * @returns true, if the object has the group protection. Otherwise, false.
 */
export function hasGroupProtection(protectionInfo: ObjectProtectionInfo) {
  return protectionInfo?.protectionGroups?.length > 0;
}

/**
 * Decodes the snapshots id which is a base64 encoded string to
 * build cluster identifier and return it.
 *
 * @param snapshotId snapshot id.
 */
export function getClusterIdentifierFromSnapshotId(snapshotId: string) {
  const parsedInfo = JSON.parse(atob(snapshotId)),
    clusterId = parsedInfo.a_clusterId,
    clusterIncarnationId = parsedInfo.b_clusterIncarnationId;

  return `${clusterId}:${clusterIncarnationId}`;
};

/**
 * Decodes the snapshots id which is a base64 encoded string to
 * build run start time.
 *
 * @param snapshotId snapshot id.
 */
export function getRunStartTimeFromSnapshotId(snapshotId: string) {
  const parsedInfo = JSON.parse(atob(snapshotId));

  return parsedInfo.f_runStartTimeUsecs;
};
