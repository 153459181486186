import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TagList } from '@cohesity/api/helios-metadata';

import { AnomalyChartSeries, ChartPoint } from '../../security-shared.models';


/**
 * A component that shows basic snapshot information
 */
@Component({
  selector: 'dg-ar-snapshot-info',
  templateUrl: './snapshot-info.component.html',
  styleUrls: ['./snapshot-info.component.scss'],
})
export class SnapshotInfoComponent implements OnChanges {
  /**
   * Chart data
   */
  @Input() selectedSnapshot: ChartPoint;

  /**
   * Chart data
   */
  @Input() seriesData: AnomalyChartSeries;

  /**
   * tags associated with the selected recovery point
   */
  tags: TagList = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedSnapshot || changes.seriesData) {
      this.tags = this.getSnapshotTags();
    }
  }

  /**
   * Extracts tags for the selected recovery point from the supplied series data
   *
   * @returns tags associated with the selected recovery point
   */
  getSnapshotTags(): TagList {
    try {
      const dataPoints = this.seriesData?.timestampDataPoints[this.selectedSnapshot?.x * 1000];
      return dataPoints[0].hmsTags ?? [];
    } catch (error) {
      return [];
    }
  }
}
