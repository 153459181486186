import { Pipe, PipeTransform } from '@angular/core';
import { BackupRunSummary } from '@cohesity/api/v2';
import { ProtectionRun } from 'src/app/modules/protection-group-shared';

const backupRunDetails: { [key in NonNullable<BackupRunSummary['status']>]: {
    title: 'info' | 'warning' | 'error';
    messageClass: 'info' | 'warn' | 'error';
} } = {
    'Accepted': {
        title: 'info',
        messageClass: 'info'
    },
    'Running': {
        title: 'info',
        messageClass: 'info'
    },
    'Succeeded': {
        title: 'info',
        messageClass: 'info'
    },
    'Finalizing': {
        title: 'info',
        messageClass: 'info'
    },
    'Paused': {
        title: 'info',
        messageClass: 'info'
    },
    'Canceled': {
        title: 'warning',
        messageClass: 'warn'
    },
    'Canceling': {
        title: 'warning',
        messageClass: 'warn'
    },
    'SucceededWithWarning': {
        title: 'warning',
        messageClass: 'warn'
    },
    'OnHold': {
        title: 'warning',
        messageClass: 'warn'
    },
    'Failed': {
        title: 'error',
        messageClass: 'error'
    },
    'Missed': {
        title: 'error',
        messageClass: 'error'
    },
    'Skipped': {
        title: 'error',
        messageClass: 'error'
    },
};

/**
 * Return className and title based on key for backup run status.
 *
 * @example
 * // This will return <div [class]="c-message-inline icon-message error">
 * <div [class]="`c-message-inline icon-message ${'Failed' | backupRunStatus: 'messageClass'}`">
 *    // This will return  <h6 translate>error</h6>
 *    <h6 translate>{{'Failed' | backupRunStatus: 'title'}}</h6>
 * </div>
 */
@Pipe({
    name: 'backupRunStatus',
    standalone: true,
})
export class BackupRunStatusPipe implements PipeTransform {
    transform(backupRunData: ProtectionRun, key: 'messageClass' | 'title'): string {
        const status = backupRunData?.backupStatus?.status;

        const defaultBackupRunDetails = {
            title: 'info',
            messageClass: 'info',
        };

        return backupRunDetails?.[status]?.[key] ?? defaultBackupRunDetails[key];
    }

}
