<ng-container [ngSwitch]="type">
  <cog-string-form-field *ngSwitchCase="'string'"
    cogDataId="string-form-field"
    [inheritDataId]="true"
    [label]="label"
    [config]="config?.stringConfig"
    [control]="control"
    [validationErrorFn]="validationErrorFn">
  </cog-string-form-field>
  <cog-password-form-field *ngSwitchCase="'password'"
    cogDataId="password-form-field"
    [inheritDataId]="true"
    [label]="label"
    [config]="config?.passwordConfig"
    [control]="control"
    [validationErrorFn]="validationErrorFn">
  </cog-password-form-field>
  <cog-number-form-field *ngSwitchCase="'number'"
    cogDataId="number-form-field"
    [inheritDataId]="true"
    [label]="label"
    [config]="config?.numberConfig"
    [control]="control"
    [validationErrorFn]="validationErrorFn">
  </cog-number-form-field>
  <cog-boolean-form-field *ngSwitchCase="'boolean'"
    cogDataId="boolean-form-field"
    [inheritDataId]="true"
    [label]="label"
    [config]="config?.booleanConfig"
    [control]="control"
    [validationErrorFn]="validationErrorFn">
  </cog-boolean-form-field>
  <cog-radio-group-form-field *ngSwitchCase="'radioGroup'"
    cogDataId="radio-group-form-field"
    [inheritDataId]="true"
    [label]="label"
    [config]="config?.radioGroupConfig"
    [control]="control"
    [validationErrorFn]="validationErrorFn"
    [formControlGetterFn]="formControlGetterFn">
  </cog-radio-group-form-field>
</ng-container>
