// Component: Accessibility Module

; (function (angular, undefined) {
  'use strict';

  angular
    .module('C.accessibility', ['ui.select'])
    .config(accessibilityConfig);

  function accessibilityConfig($provide) {
    $provide.decorator('uiSelectDirective', addAriaAttribute);
    $provide.decorator('uibTypeaheadDirective', removeAriaAttribute);
    $provide.decorator('requiredDirective', addAriaRequiredAttribute);
    $provide.decorator('ngRequiredDirective', addAriaRequiredAttribute);
  }

  /**
   * @ngdoc decorator
   * @name        C.accessibility
   * @method      addAriaAttribute
   *
   * @description
   * This function will add the missing aria attribute for ui-select directive
   * and return the modified element.
   */
  function addAriaAttribute($delegate) {
    var directive = $delegate[0];
    var orgCompileFn = directive.compile;

    directive.compile = function addAriaAttributeCompiler(element, attrs) {
      var uiSelectSearchEl = element.querySelectorAll('.ui-select-search');

      if (attrs.hasOwnProperty('multiple')) {
        // Elements with ARIA roles must ensure required owned elements
        // are present.
        // Details can be found here:
        // https://github.com/GoogleChrome/accessibility-developer-tools/wiki/Audit-Rules#ax_aria_08
        uiSelectSearchEl.attr('aria-expanded', 'false');
      } else {
        // Removing ARIA attributes which refer to other elements by ID
        // should refer to elements which exist in the DOM.
        // Details can be found here:
        // https://github.com/GoogleChrome/accessibility-developer-tools/wiki/Audit-Rules#ax_aria_02
        uiSelectSearchEl.removeAttr('aria-expanded');
        uiSelectSearchEl.removeAttr('aria-owns');
      }

      return orgCompileFn.apply(this, arguments);
    };

    return $delegate;
  }

  /**
   * @ngdoc decorator
   * @name        C.accessibility
   * @method      removeAriaAttribute
   *
   * @description
   * This function will add and remove the necessary aria attribute for
   * global search component and return the modified element.
   */
  function removeAriaAttribute($delegate) {
    var directive = $delegate[0];
    var orgCompileFn = directive.compile;

    directive.compile = function overrideUibTypeaheadCompiler() {
      var orgLinkFn = orgCompileFn.apply(this, arguments);

      return function postLink(scope, iElement) {
        // Removing the unsupported ARIA attribute from the DOM element.
        // Details can be found here:
        // https://github.com/GoogleChrome/accessibility-developer-tools/wiki/Audit-Rules#ax_aria_10
        iElement.removeAttr('aria-expanded');
        iElement.removeAttr('aria-owns');
        iElement.attr('aria-label', 'Input a search keyword');

        // Execute the original linkFn now.
        return orgLinkFn.apply(this, arguments);
      };
    };

    return $delegate;
  }

  /**
   * @ngdoc decorator
   * @name        C.accessibility
   * @method      addAriaRequiredAttribute
   *
   * @description
   * This function will add the missing aria attribute for every input element
   * which is mandatory and return the modified element.
   */

  function addAriaRequiredAttribute($delegate) {
    var directive = $delegate[0];
    var orgCompileFn = directive.compile;

    directive.compile = function ariaRequiredCompiler() {
      var orgLinkFn = orgCompileFn.apply(this, arguments);

      return function postLink(scope, iElement) {
        // Add the required ARIA attribute.
        iElement.attr('aria-required', true);

        // Execute the original linkFn now.
        return orgLinkFn.apply(this, arguments);
      };
    };

    return $delegate;
  }
})(angular);
