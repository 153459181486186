import { UntypedFormArray, UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Validate if the new name clause contains set newname syntax.
 */
export const SetNewNameSyntaxValidator: ValidatorFn = (control: UntypedFormControl) => {
  if (!control.value) {
    return;
  }

  const errors: ValidationErrors = {};
  const value = control.value?.trim();
  const commandList = value.split(';').filter(val => !!val);

  if (commandList?.length) {
    commandList.forEach(command => {

      // Check whether the command has 'SET NEWNAME' syntax at first position.
      // for eg: SET NEWNAME FOR DATAFILE 1 TO ’u1/home/test_1.dbf’;
      if (command?.toUpperCase().trim().indexOf('SET NEWNAME') !== 0) {
        errors.invalidSetNewNameSyntax = true;
      }
    });

    // Check if duplicate entries are found.
    if (!isUnique(commandList)) {
      errors.duplicateEntry = true;
    }

    // Checking the occurrence of semicolon whether it exists at the end of every command.
    if ((value.match(/;/g) || []).length !== commandList.length) {
      errors.invalidCommand = true;
    }
  }

  return Object.keys(errors)?.length ? errors : null;
};

/**
 * Validate if the new name clause commands contains duplicate commands.
 */
export const duplicateCommandsValidator: ValidatorFn = (formArray: UntypedFormArray) => {
  if (!formArray.value) {
    return;
  }

  const errors: ValidationErrors = {};
  const commandList = formArray.value.map(value => value.command);

  // Check if duplicate entries are found.
  if (!isUnique(commandList)) {
    errors.duplicateEntry = true;
  }

  return Object.keys(errors)?.length ? errors : null;
};

/**
 * Method called to validate if duplicate entries are found or not.
 *
 * @param    valueList  the list of values.
 * @returns  boolean to determine whether duplicate entries are found or not.
 */
export function isUnique(valueList: string[] = []): boolean {
  return new Set<string>(valueList.map(val => val?.toUpperCase())).size === valueList.length;
}
