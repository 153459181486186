import { Component } from '@angular/core';
import { isNumber } from 'lodash';
import { BandwidthThrottling, JobType } from 'src/app/shared';
import { BaseProtectionBuilderComponent } from '../../base-protection-builder/base-protection-builder.component';

/**
 * Specify concurrency per stream in settings for a protection group.
 */
@Component({
  selector: 'coh-settings-list-stream-bandwith-throttling',
  template: `
    <coh-settings-list-bandwidth-throttling [formControl]="formControl" [jobType]="jobType">
    </coh-settings-list-bandwidth-throttling>
  `
})
export class SettingsListStreamBandwidthThrottlingComponent extends
  BaseProtectionBuilderComponent<BandwidthThrottling, any> {

  /**
   * Specifies the protection group Job type.
   */
  jobType: JobType = 'Backup';

  /**
   * Form control init.
   */
  initFormControl() {
    if (this.protectionGroup && isNumber(this.protectionGroup.bandwidthThrottling)) {
      this.formControl.setValue({
        value: this.protectionGroup.bandwidthThrottling,
        enabled: true
      });
    }
  }
}
