// MODULE: NAS (NetApp) Registration/Edit
// NOTE: cSlideModal support currently only works for registering a new Source
import { Workflow } from 'src/app/app-module.config';

;(function(angular, undefined) {
  'use strict';

angular.module('C.sources')
  .controller('nasModifyController', nasModifyControllerFn);

  function nasModifyControllerFn(_, $scope, $state, $q, evalAJAX, SourceService,
    cMessage, $uibModalInstance, envType, envTypes, ENV_GROUPS, ENTITY_KEYS, FORMATS,
    PUB_TO_PRIVATE_ENV_STRUCTURES, NAS_SOURCE_NAMES, cUtils, NavStateService,
    ActiveDirectoryService, AdaptorAccessService, ngDialogService, StateManagementService, connectionId, UserService,
    NON_BIFROST_AWARE_NAS_ENVS) {

    const workflow = $state.params.fileStubbing ? Workflow.dataMigration : Workflow.backupAndRecovery;

    angular.extend($scope, {
      ENV_GROUPS: ENV_GROUPS,
      FORMATS: FORMATS,
      shared: {
        filterIpsEnabled: false,
        backupSmbVolumes: false,
        nasTypes: NavStateService.getNasSourceTypes(workflow).map(({ environment }) => {
          return {
            name: NAS_SOURCE_NAMES[environment],
            value: PUB_TO_PRIVATE_ENV_STRUCTURES[environment].envType
          }
        }),
        accessibleNetappEntityMap: AdaptorAccessService.filterByAccessibleEnvItems(
          [
            { environment: 'kNetapp', entity: 'kCluster' },
            { environment: 'kNetapp', entity: 'kVserver' }
          ],
          workflow
        ).reduce((out, envItem) => {
          out[envItem.entity] = envItem.entity;
          return out;
        }, {}),
      },

      // Methods
      cancel: cancel,
      cohesityManagedPasswordToggled: cohesityManagedPasswordToggled,
      getSetEntityType: getSetEntityType,
      getSetMountPath: getSetMountPath,
      onChangeSmbUsername: onChangeSmbUsername,
      onSelectDomain: onSelectDomain,
      submitForm: submitForm,
      toggleSmbVolumes: toggleSmbVolumes,
      toggleFilterIps: toggleFilterIps,
      toggleFilterType: toggleFilterType,
      toggleSourceThrottling: toggleSourceThrottling,
    });

    // Populate both entity types in the default config because we do not know
    // which type the user will register until they actually click submit. We
    // will delete the extraneous type before posting.
    var defaultSourceConfig = {
      entity: {},
      entityInfo: {
        endpoint: undefined,
        credentials: {
          username: undefined,
          password: undefined,
          token: undefined,
          nasMountCredentials: {},
        },
      },
      registeredEntityParams: {},
    };

    var nasMountCredentials;

    /**
     * filtering out the types of Nas source type allowed
     * based on given envTypes if envTypes are given.
     */
    if (envTypes) {
      $scope.shared.nasTypes = $scope.shared.nasTypes.filter(
        function checkTypesAllowed (entityType) {
          return envTypes.includes(entityType.value);
        }
      );
    }

    /**
     * Activate the controller
     *
     * @method     activate
     */
    function activate() {

      $scope.inModal = typeof $uibModalInstance === 'object';

      getServer().then(
        function getServerSuccess(source) {
          $scope.nasSource = source;
          nasMountCredentials = $scope.nasSource.entityInfo.credentials.nasMountCredentials;
          initSmbSettings();

          if ($state.params.nasType || envType) {
            $scope.getSetEntityType($state.params.nasType || envType);
          }
        },
        evalAJAX.errorMessage
      ).finally(
        function getServerFinally() {
          $scope.nasReady = true;
        }
      );
    }

    /**
     * handles redirecting user to the proper place on cancel or submit
     */
    function redirect() {
      StateManagementService.goToPreviousState('sources-new');
    }

    /**
     * Exit back to list view
     *
     * @method     cancel
     */
    function cancel() {

      if ($scope.inModal) {
        $uibModalInstance.dismiss('user.cancel');
        return;
      }

      redirect();
    };

    /**
     * handle form submission
     *
     * @method     submitForm
     * @param      {object}  form    The form
     */
    function submitForm(form) {

      var actionFn = $state.params.id ?
        SourceService.updateSource : SourceService.createSource;

      var nasSource = $scope.nasSource;
      var entity = nasSource.entity;
      var entityParams = nasSource.registeredEntityParams;

      if (form.$invalid) {
        return;
      }

      $scope.submitting = true;

      if ($scope.shared.selectedDomain) {
        nasMountCredentials.domainName = $scope.shared.selectedDomain;
        nasMountCredentials.cohesityManagedPassword = $scope.shared.cohesityManagedPassword;
        nasMountCredentials.domainController = $scope.shared.selectedDomainController;
        nasMountCredentials.username = $scope.shared.smbUsername;
      }

      if ($scope.shared.filterIpsEnabled) {
        entityParams.blacklistedIpAddrs = $scope.shared.filterType === 'deny' ?
          $scope.shared.filterIpAddresses : undefined;
        entityParams.whitelistedIpAddrs = $scope.shared.filterType === 'allow' ?
          $scope.shared.filterIpAddresses : undefined;
      } else {
        entityParams.blacklistedIpAddrs = undefined;
        entityParams.whitelistedIpAddrs = undefined;
      }

      if ($scope.shared.throttlingEnabled) {
        _.set(entityParams,
          'throttlingPolicy.nasThrottlingParams',
          $scope.shared.throttlingParams
        );
      } else {
        entityParams.throttlingPolicy = undefined;
      }

      // If not SMB, remove the SMB credentials from request obj.
      if (ENV_GROUPS.nasAdapters.includes(nasSource.entity.type) &&
        !$scope.shared.backupSmbVolumes) {
        nasSource.entityInfo.credentials.nasMountCredentials = undefined;
      }

      _resetDefaultConfig(entity.type);

      // Generic NAS conditions
      if (entity.type === 11) {
        // Delete management credentials in case of Generic NAS
        if ($scope.nasSource.entityInfo.credentials) {
          $scope.nasSource.entityInfo.credentials.username =
          $scope.nasSource.entityInfo.credentials.password = undefined;
        }

        // Delete SMB credentials if generic NAS NFS
        if (entity.genericNasEntity.protocol !== 2) {
          $scope.nasSource.entityInfo.credentials = undefined;
        }

        // Populate username and protocol information if SMB protocol is selected
        if (entity.genericNasEntity.protocol === 2) {
          _.assign($scope.nasSource.entityInfo.credentials.nasMountCredentials, {
            protocol: 2,
            domainName: nasMountCredentials.domainName,
            username: nasMountCredentials.username,
          });
        }
      }

      // add bifrost connection id
      _assignConnectionId(nasSource);

      actionFn(nasSource).then(
        function actionFnSuccess(source) {

          if ($scope.inModal) {
            return $uibModalInstance.close(source);
          }

          cMessage.success({
            textKey: $state.params.id ?
              'sources.nas.updated' : 'sources.nas.registered',
          });

          redirect();

        },
        evalAJAX.errorMessage
      ).finally(
        function actionFnFinally() {
          $scope.submitting = false;
        }
      );
    };

    /**
     * When user toggles 'Backup SMB Volumes' toggle, we check if the
     * nas mount credentials are missing, we assign the default values to get
     * the protocol information inside the credentials.
     *
     * @method     toggleSmbVolumes
     */
    function toggleSmbVolumes() {
      if ($scope.shared.backupSmbVolumes &&
        !_.get($scope.nasSource.entityInfo.credentials.nasMountCredentials, 'protocol')) {
        nasMountCredentials =
          $scope.nasSource.entityInfo.credentials.nasMountCredentials = {
            protocol: 2,
            username: undefined,
            password: undefined,
            domainName: undefined,
            cohesityManagedPassword: undefined,
            domainController: undefined,
          };
      }
    }

    /**
     * Fetches all registered active directory domains of the cluster
     *
     * @method   fetchDomains
     */
    function fetchDomains() {
      if ($scope.shared.domains) { return; }

      return ActiveDirectoryService.getDomainNames()
        .then(function getDomainsSuccess(domains) {
          $scope.shared.domains = domains;
        }, evalAJAX.errorMessage);
    }

    /**
     * When user selects an active directory domain, we reset Domain Controller
     *
     * @method   onSelectDomain
     */
    function onSelectDomain() {
      // Reset the domain controller selection as domain has changed
      $scope.shared.selectedDomainController = undefined;

      if ($scope.shared.selectedDomain) {
        fetchDomainControllers($scope.shared.selectedDomain);
      }
    }

    /**
     * If user selects an active directory domain and sets the cohesity managed
     * password to true, we show the domain controller selection and get the
     * list of all domain controllers for the selected domain name.
     *
     * @method   cohesityManagedPasswordToggled
     * @param   toggleValue   true if Cohesity managed password is turned on,
     *                        false otherwise
     */
    function cohesityManagedPasswordToggled(toggleValue) {
      if (toggleValue) {
        fetchDomains();
      } else {
        // If user wants to manage the password, username and password fields
        // are enabled again
        $scope.smbUsernamePasswordDisabled = false;
        $scope.shared.selectedDomain = undefined;
        $scope.shared.selectedDomainController = undefined;
      }
    }

    /**
     * Fetches all the domain controllers for selected domain name.
     *
     * @method   fetchDomainControllers
     * @param   domainName   Selected active directory domain name
     */
    function fetchDomainControllers(domainName) {
      const params = {domainName: domainName};

      if (nasMountCredentials.domainController) {
        return;
      }

      return ActiveDirectoryService.fetchDomainControllers(params)
        .then(function getDomainControllersSuccess(controllers) {
          $scope.shared.domainControllers = controllers;
        }, evalAJAX.errorMessage);
    }


    /**
     * When user changes SMB Username, split the domain from username and assign
     * each part to its respective model, but only if it is NetApp. Otherwise
     * assign the whole thing to username property
     *
     * @method     onChangeSmbUsername
     */
    function onChangeSmbUsername() {
      var smbParts;

      if ($scope.shared.sourceForm.smbUsername.$valid) {
        smbParts = $scope.shared.smbUsername.split('\\');

        if (smbParts.length > 1) {
          // Assign Domain and Username parts separately
          nasMountCredentials.domainName = smbParts[0];
          nasMountCredentials.username = smbParts[1];
        } else {
          // Assign whole thing to username property
          nasMountCredentials.domainName = undefined;
          nasMountCredentials.username = $scope.shared.smbUsername;
        }
      }
    }

    /**
     * Determines if filer IP config is applied or not. If the config is turned
     * on, default values are set and the config is set to undefined if toggle
     * is turned off.
     */
    function toggleFilterIps() {
      const enabled = $scope.shared.filterIpsEnabled;
      $scope.shared.filterType = enabled ? 'allow' : undefined;
      $scope.shared.filterIpAddresses = enabled ? [] : undefined;
    }

    /**
     * Determines if source throttling is enabled or not. If the config is
     * enabled, default values are set and the config is set to undefined if
     * the toggle is turned off.
     */
    function toggleSourceThrottling() {
      if ($scope.shared.throttlingEnabled) {
        $scope.shared.throttlingParams = {
          maxParallelMetadataFetchFullPercentage: 100,
          maxParallelMetadataFetchIncrementalPercentage: 100,
          maxParallelIoFullPercentage: 100,
          maxParallelIoIncrementalPercentage: 100
        };
      } else {
        $scope.shared.throttlingParams = undefined;
      }
    }

    /**
     * When filter type is switched and ip list is configured, user gets a
     * confirmation dialog to confirm filter change. The filter is changed only on
     * user confirmation.
     *
     * @param   filterType   Filter type selected
     */
    function toggleFilterType(filterType) {
      // If there are no ips configured, no need for confirmation dialog
      if (!$scope.shared.filterIpAddresses[0]) {
        return;
      }

      const dialogData = {
        title: 'protectionGroups.filterIps.modalTitle',
        copy: 'protectionGroups.filterIps.modalCopy',
        confirmButtonLabel: 'switch',
        declineButtonLabel: 'cancel'
      };

      ngDialogService.simpleDialog(null, dialogData, { width: '25rem', autoFocus: false })
        .subscribe((switched) => {
          if (switched) {
            return;
          }

          // If user did not click on switch, revert the values
          $scope.shared.filterType = filterType === 'allow' ? 'deny' : 'allow';
        });
    }

    /**
     * Set initial state of SMB settings
     *
     * @method     initSmbSettings
     */
    function initSmbSettings() {
      // If SMB username property exists, then setup model for form field.
      if (nasMountCredentials.username) {
        // If domainName property exists, then also concat with username.
        $scope.shared.smbUsername = nasMountCredentials.domainName ?
          [nasMountCredentials.domainName, nasMountCredentials.username]
          .join('\\') : nasMountCredentials.username;
        $scope.shared.backupSmbVolumes = true;
      }


      if (nasMountCredentials.cohesityManagedPassword) {
        $scope.shared.selectedDomain = nasMountCredentials.domainName;
        $scope.shared.cohesityManagedPassword = nasMountCredentials.cohesityManagedPassword;
        $scope.shared.selectedDomainController = nasMountCredentials.domainController;
        $scope.shared.smbUsername = nasMountCredentials.username;

        // If Cohesity Managed Password is selected and user is in edit flow,
        // disable smb credential fields as the password is being managed by
        // Cohesity and unknown to user.
        $scope.smbUsernamePasswordDisabled = !!$state.params.id;
      }
    }

    /**
     * When the view is changed, update two related values in model.
     *
     * @method     getSetEntityType
     * @param      {number}  newValue  The new value of entity type
     * @return     {number}  The set entity type
     */
    function getSetEntityType(newValue) {
      var nasSource = $scope.nasSource;

      if (newValue) {
        nasSource.entity.type =
          nasSource.entityInfo.type = newValue;

        _resetDefaultConfig(newValue);

        // Reset AD credential settings
        $scope.shared.selectedDomain = undefined;
        $scope.shared.cohesityManagedPassword = false;
        $scope.shared.selectedDomainController = undefined;
      }

      return nasSource.entityInfo.type;
    };

    /**
     * When the mount path is changed, update two related values in model.
     *
     * @method     getSetMountPath
     * @param      {string}  newValue  The new value of mount path
     * @return     {string}  The set mount path
     */
    function getSetMountPath(newValue) {
      var nasSource = $scope.nasSource;

      if (arguments.length) {
        nasSource.entity.genericNasEntity.path =
          nasSource.entityInfo.endpoint = newValue;
      }

      return nasSource.entityInfo.endpoint;
    };

    /**
     * Gets the NAS source object, either via API call or copying
     * the default object for new registration
     *
     * @method     getServer
     * @return     {Promise}  resolves with source object, or rejects with raw
     *                        server response
     */
    function getServer() {
      var deferred = $q.defer();
      var params;
      var nasSource = $scope.nasSource = angular.copy(defaultSourceConfig);

      if ($state.params.id) {
        // this is edit mode

        params = {
          onlyReturnOneLevel: true,
          entityId: [$state.params.id]
        };

        SourceService.getSources(params).then(
          function getSourceSuccess(response) {
            var blacklistedIpAddrs = _.get(response, 'entityHierarchy.registeredEntityInfo.registeredEntityParams.blacklistedIpAddrs') ||
              _.get(nasSource, 'registeredEntityParams.blacklistedIpAddrs');
            var whitelistedIpAddrs = _.get(response, 'entityHierarchy.registeredEntityInfo.registeredEntityParams.whitelistedIpAddrs') ||
              _.get(nasSource, 'registeredEntityParams.whitelistedIpAddrs');
            var throttlingParams = _.get(nasSource.registeredEntityParams,
              'throttlingPolicy.nasThrottlingParams');

            // If response is missing anything, reject and return
            if (!response || !response.entityHierarchy) {
              deferred.reject({ data: { message: $translate.instant('sources.nas.errorLoadingNAS') }});
              return;
            }

            // it is necessary to rebuild/rearrange the source object,
            // as the backend response structure does not match up properly
            // with the necessary create/update object structure.
            angular.merge(nasSource, {
              entity: response.entityHierarchy.entity,
              entityInfo:
                response.entityHierarchy.registeredEntityInfo.connectorParams,
              registeredEntityParams: response.entityHierarchy
                .registeredEntityInfo.registeredEntityParams || {},
            });

            // Turn on the blacklist IPs toggle if we have the value
            if (blacklistedIpAddrs || whitelistedIpAddrs) {
              $scope.shared.filterIpsEnabled = true;
              $scope.shared.filterType = blacklistedIpAddrs ? 'deny' : 'allow';
              $scope.shared.filterIpAddresses = blacklistedIpAddrs || whitelistedIpAddrs;
            }

            if (throttlingParams) {
              $scope.shared.throttlingEnabled = true;
              $scope.shared.throttlingParams = throttlingParams;
            }

            // For Pre 6.1.1 we need second part of OR condition. That is the
            // old magneto property used for backward compatibilty
            if (nasSource.entity.genericNasEntity) {
              nasSource.registeredEntityParams.description =
                nasSource.registeredEntityParams.description ||
                  nasSource.entity.genericNasEntity.description;
            }

            deferred.resolve(nasSource);
          },
          deferred.reject
        );

      } else {
        // this is a new registration
        let defaultEnvType = PUB_TO_PRIVATE_ENV_STRUCTURES.kNetapp.envType;
        const isDefaultDefaultEnvExist = _.find($scope.shared.nasTypes, ['value', defaultEnvType]);

        if (!isDefaultDefaultEnvExist && $scope.shared.nasTypes.length) {
          defaultEnvType = $scope.shared.nasTypes[0].value;
        }

        $scope.getSetEntityType(defaultEnvType);
        deferred.resolve(nasSource);
      }

      return deferred.promise;
    }

    /**
     * Reset the entity with default config and delete the extraneous entity
     * type
     *
     * @method     _resetDefaultConfig
     */
    function _resetDefaultConfig(entityType) {
      var entity = $scope.nasSource.entity;

      // Reset all entities except the selected one
      cUtils.onlyNumbers(ENV_GROUPS.nas).forEach(
        function clearEntities(nasType) {
          if (nasType !== entityType) {
            entity[ENTITY_KEYS[nasType]] = undefined;
          }
        });

      switch(entityType) {
        //Netapp Entity
        case 9:
          entity.netappEntity = entity.netappEntity || {
            isTopLevelEntity: true,

            // kCluster (0), kVserver (1), kVolume (2)
            type: PUB_TO_PRIVATE_ENV_STRUCTURES.kNetapp.entityTypes[
              $scope.shared.accessibleNetappEntityMap.kCluster || $scope.shared.accessibleNetappEntityMap.kVserver
            ],
          };
          break;

        // Generic NAS Entity
        case 11:
          // Close the SMB toggle switch
          $scope.shared.backupSmbVolumes = false;
          entity.genericNasEntity = entity.genericNasEntity || {
            // kNfs3 (1), kCifs1 (2)
            protocol: 1,

            // kGroup (0), kHost (1), kDfsGroup (2), kDfsTopDir (3)
            type: 1,
            path: undefined,
            description: undefined,
          };

          // Trigger MountPath gettersetter to syncronize the model in case
          // the user entered a value while in NetApp mode. This is because
          // NetApp has a single model value while Generic NAS needs that
          // value replicated to a second place in the object.
          $scope.getSetMountPath($scope.nasSource.entityInfo.endpoint);
          break;

        // All other NAS types
        default:
          _.set(entity, '['+ENTITY_KEYS[entityType]+'].type', 0);
          break;
      }
    }

    /**
     * Adds bifrost connection to nas source request object
     *
     * @param {object} sourceDetails request object containing nas source details
     * @returns augment request object with connection id
     */
    function _assignConnectionId(sourceDetails) {
      const sourceType = $scope.getSetEntityType();

      // change env string enums to env type values
      const nonBifrostAwareEnvTypes = NON_BIFROST_AWARE_NAS_ENVS.map(
        (env) => PUB_TO_PRIVATE_ENV_STRUCTURES[env].envType);

      const isBifrostAwareSource = nonBifrostAwareEnvTypes.indexOf(sourceType) === -1;

      // source type selected is not bifrost aware; exit
      if (!isBifrostAwareSource) {
        return ;
      }

      // edit case
      if ($state.params.id && $scope.nasSource) {
        return ;
      }

      const defaultConnectionId = UserService.getDefaultBifrostConnectionId();
      const assignConnectionId = connectionId ? connectionId : defaultConnectionId;
      // create case and if connection id is present
      if (assignConnectionId) {
        sourceDetails.connectionId = assignConnectionId;
      }
    }

    activate();
  }

}(angular));
