import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  LegacyDateAdapter as DateAdapter,
  MAT_LEGACY_DATE_FORMATS as MAT_DATE_FORMATS,
  MAT_LEGACY_DATE_LOCALE as MAT_DATE_LOCALE,
} from '@angular/material/legacy-core';
import { flagEnabled } from '@cohesity/iris-core';
import { subformComponentProviders } from 'ngx-sub-form';
import { Environment } from 'src/app/shared';
import { NoSqlSearchResultGroup } from '../model/nosql-search-result-group';

import { SnapshotSelectorComponent } from '../snapshot-selector/containers/snapshot-selector/snapshot-selector.component';
import { SnapshotSelectorService } from '../snapshot-selector/services/snapshot-selector.service';

@Component({
  selector: 'coh-nosql-snapshot-selector',
  templateUrl: '../snapshot-selector/containers/snapshot-selector/snapshot-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    SnapshotSelectorService,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: { ...MAT_MOMENT_DATE_FORMATS.parse, dateInput: 'MMM D, YYYY' },
        display: { ...MAT_MOMENT_DATE_FORMATS.display, dateInput: 'MMM D, YYYY' },
      },
    },
    subformComponentProviders(NoSqlSnapshotSelectorComponent),
  ],
})
export class NoSqlSnapshotSelectorComponent extends SnapshotSelectorComponent {

  getProtectionSourceIdForIndexedObject(): number {
    if (flagEnabled(this.irisCtx.irisContext, 'nosqlEntityLevelRbacSupport')) {
      return (this.object.objectInfo as NoSqlSearchResultGroup).objectId as number;
    }
    return super.getProtectionSourceIdForIndexedObject();
  }

  getTimeRangesIdForIndexedObject(): number {
    // Returns the entity id for MongoDB environment.
    if (this.environment === Environment.kMongoDB) {
      return (this.object.objectInfo as NoSqlSearchResultGroup).objectId as number;
    }
    // Uses default implementation (source id) for other environments (Cassandra).
    const object = this.object;
    return (this.useSourceId ? object.objectIds[0] : object.objectInfo.id) as number;
  }
}
