import { SelectionModel } from '@angular/cdk/collections';

/**
 * This class extends the CDK selection model to use a map to track items instead of a set.
 * The class should be created with a callback function to retrieve a unique id from an object.
 * Any object with a matching id will be considered to be part of the selection.
 */
export class KeyedSelectionModel<T> extends SelectionModel<T> {
  /**
   * This replaces _selection in the underlying SelectionModel
   */
  private mappedSelection = new Map<any, T>();

  /**
   * Some of the properties that this class overrides are marked private by SelectionModel. This
   * is a convenience method to access 'this' with the type definition stripped so that the private
   * methods can be accessed.
   */
  private get parent() {
    return this as any;
  }

  constructor(
    private getKey: (val: T) => any = val => val,
    multiple = false,
    initiallySelectedValues?: T[],
    private emitChanges = true
  ) {
    super(multiple, undefined, emitChanges);
    this.parent._markSelected = this.markSelectedOverride;
    this.parent._unmarkSelected = this.unmarkSelectedOverride;
    this.parent._unmarkAll = this.unmarkAllOverride;

    if (initiallySelectedValues && initiallySelectedValues.length) {
      if (multiple) {
        initiallySelectedValues.forEach(value => this.markSelectedOverride(value));
      } else {
        this.markSelectedOverride(initiallySelectedValues[0]);
      }

      // Clear the array in order to avoid firing the change event for preselected values.
      this.parent._selectedToEmit.length = 0;
    }
  }

  /**
   * Overrides the selected property to retrieve values from mappedSelection.
   */
  get selected(): T[] {
    if (!this.parent._selected) {
      this.parent._selected = Array.from(this.mappedSelection.values());
    }

    return this.parent._selected;
  }

  /**
   * Overrides the parent method to use mappedSelection to determine whether a value is selected.
   *
   * @param   value   The value to check.
   * @return   True if the values is selected.
   */
  isSelected(value: T): boolean {
    return this.mappedSelection.has(this.getKey(value));
  }

  /**
   * Overrides the parent method to determine whether the model does not have a value.
   *
   * @return   True if the model has no values.
   */
  isEmpty(): boolean {
    return this.mappedSelection.size === 0;
  }

  /**
   * Override _markSelected to use mappedSelection. This is defined as a property to automatically bind 'this'.
   *
   * @param   value   The value to select.
   */
  private markSelectedOverride = (value: T) => {
    if (!this.isSelected(value)) {
      if (!this.parent._multiple) {
        this.unmarkAllOverride();
      }

      this.mappedSelection.set(this.getKey(value), value);

      if (this.emitChanges) {
        this.parent._selectedToEmit.push(value);
      }
    }
  };

  /**
   * Override _unmarkSelected to use mappedSelection. This is defined as a property to automatically bind 'this'.
   *
   * @param   value   The value to deselect.
   */
  private unmarkSelectedOverride = (value: T) => {
    if (this.isSelected(value)) {
      this.mappedSelection.delete(this.getKey(value));

      if (this.emitChanges) {
        this.parent._deselectedToEmit.push(value);
      }
    }
  };

  /**
   * Override _unmarkAll to use mappedSelection. This is defined as a property to automatically bind 'this'.
   */
  private unmarkAllOverride = () => {
    if (!this.isEmpty()) {
      this.mappedSelection.forEach(value => this.unmarkSelectedOverride(value));
    }
  };
}
