import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { RestorePointSelection } from '../../model/restore-point-selection';
import { TranslateService } from '@ngx-translate/core';

import { ProtectionGroupSearchResult } from '../../model/protection-group-search-result';

// Interface for selected recovery vm object.
export interface SelectedRecoveryVmObject {

  // Name of the object.
  name: string;

  // Name of protection group.
  protectionGroupName: string;

  // Time when it is backedup last time.
  lastBackupUsecs: number;

  // Name of source.
  sourceName: string;

  // Size of object.
  objectSize: number;
}

/**
 * @description
 *
 * Dialog to show selected objects.
 */
@Component({
  selector: 'coh-view-selected-objects-dialog',
  templateUrl: './view-selected-objects-dialog.component.html',
  styleUrls: ['./view-selected-objects-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewSelectedObjectsDialogComponent implements OnInit {

  // Objects selected for recovery.
  selectedObjects: SelectedRecoveryVmObject[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: RestorePointSelection[],
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<ViewSelectedObjectsDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.data.forEach(object => {
      if (object.objectInfo.resultType === ProtectionGroupSearchResult.protectionGroupResultType) {
        (object.objectInfo as ProtectionGroupSearchResult).objectsProtected?.forEach(objectProtected => {
          if (object?.objectIds.includes(objectProtected.id)) {
            this.selectedObjects.push({
              name: objectProtected?.name,
              protectionGroupName: objectProtected?.protectionGroupName,
              lastBackupUsecs: object?.timestampUsecs,
              sourceName: objectProtected?.sourceInfo?.name,
              objectSize: objectProtected?.objectSizeBytes
            });
          }
        });
      } else {
        this.selectedObjects.push({
          name: object.objectInfo?.name,
          protectionGroupName: object.objectInfo?.protectionGroupName,
          lastBackupUsecs: object?.timestampUsecs,
          sourceName: object.objectInfo?.sourceInfo?.name ?? object.objectInfo?.parentSourceName,
          objectSize: object.objectInfo?.objectSizeBytes,
        });
      }
    });
  }
}
