import { Component, Input, ViewChild, TemplateRef, ViewEncapsulation } from '@angular/core';

import { NavItem } from '../../nav-item/nav-item.model';

/**
 * Application primary navigation side drawer component.
 */
@Component({
  selector: 'cog-app-nav',
  templateUrl: './app-nav.component.html',
  styleUrls: ['./app-nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppNavComponent {
  /**
   * List of menu items.
   */
  @Input() navList: NavItem[];

  /**
   * Template to override the nav item display. It should wrap a nav item
   * component and use directives for router links and state. See the default
   * item template for an example
   */
  @Input() itemTemplate: TemplateRef<any>;

  /**
   * Template to show the image required in nav menu
   */
  @Input() bannerTemplate?: TemplateRef<any>;

  @ViewChild('defaultItemTemplate', {static: false})
  private defaultItemTemplate: TemplateRef<any>;

  /**
   * Returns the item template to use for navigation, either a custom template
   * or the default template
   */
  get menuItemTemplate(): TemplateRef<any> {
    return this.itemTemplate || this.defaultItemTemplate;
  }

}
