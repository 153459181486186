<ng-container class="user-at-domain-selector" *ngIf="userAtDomainForm" [formGroup]="userAtDomainForm">
  <mat-form-field class="select-domain margin-right-sm">
    <mat-label>{{'adDomain' | translate}}</mat-label>
    <mat-select [inheritDataId]="true" cogDataId="domain-selector" formControlName="domain" [disabled]="readOnly">
      <mat-optgroup *ngFor="let domainGroup of domainGroups;" label="{{domainGroup.name | translate}}">
        <mat-option *ngFor="let domain of domainGroup.value"
          [value]="domain"
          [inheritDataId]="true"
          [cogDataId]="'option-' + domain">
          {{domain | translate}}
        </mat-option>
      </mat-optgroup>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="principle-user-input" *ngIf="!isUnixUserIdSelected">
    <input type="text"
      matInput
      placeholder="{{'principal' | translate}}"
      [matAutocomplete]="auto"
      formControlName="principal"
      [disabled]="readOnly"
      [inheritDataId]="true"
      cogDataId="principal">
    <mat-autocomplete #auto="matAutocomplete"
      (optionSelected)="onSelectPrincipal($event.option.value)" [displayWith]="getPrincipalName">
      <mat-option *ngIf="isLoading"><cog-spinner size="xs"></cog-spinner></mat-option>
      <ng-container *ngIf="!isLoading && userAtDomainForm.value.domain !== 'LOCAL'">
          <mat-option *ngFor="let principal of filteredPrincipals; let i = index;"
            [value]="principal"
            [inheritDataId]="true"
            [matTooltip]="principal.fullName + (principal.principalName ? ' ' + principal.principalName : '')"
            [cogDataId]="'principal-option-' + principal.principalName">
            <span class="user-at-domain-option">
              <cog-icon shape="{{principal.objectClass | principalIcon}}"></cog-icon>
              {{ principal.fullName }}
            </span>
            <span *ngIf="principal.principalName"> ({{ principal.principalName }})</span>
          </mat-option>
      </ng-container>
      <ng-container *ngIf="!isLoading && userAtDomainForm.value.domain === 'LOCAL'">
        <mat-optgroup *ngIf="filteredUsers?.length > 0" label="{{'users' | translate}}">
          <mat-option *ngFor="let principal of filteredUsers; let i = index;"
            [value]="principal"
            [inheritDataId]="true"
            [matTooltip]="principal.username"
            [matTooltipPosition]="'right'"
            [cogDataId]="'principal-option-' + principal.username">{{principal.username}}</mat-option>
        </mat-optgroup>
        <mat-optgroup *ngIf="filteredGroups?.length > 0" label="{{'groups' | translate}}">
          <mat-option *ngFor="let principal of filteredGroups; let i = index;"
            [value]="principal"
            [inheritDataId]="true"
            [matTooltip]="principal.name"
            [matTooltipPosition]="'right'"
            [cogDataId]="'principal-option-' + principal.name">
            {{principal.name}}
          </mat-option>
        </mat-optgroup>
      </ng-container>
    </mat-autocomplete>
    <mat-error *ngIf="userAtDomainForm.controls['principal'].hasError('required')"
      cogDataId="user-at-domain-picker-name-required-error">
      {{ 'errors.required' | translate }}
    </mat-error>
    <mat-error *ngIf="usersOnly && !filteredUsers?.length"
      cogDataId="user-at-domain-picker-no-principal-available">
      {{ 'noUsersFound' | translate }}
    </mat-error>
    <mat-error
      *ngIf="!usersOnly && !filteredUsers?.length && !filteredGroups?.length"
      cogDataId="user-at-domain-picker-no-principal-available">
      {{ 'noUsersOrGroupsFound' | translate }}
    </mat-error>
    <mat-hint *ngIf="constrainLocalFileAccess && userAtDomainForm.value.domain === 'LOCAL'">
      {{'enableLocalGroupReminder' | translate}}
    </mat-hint>
  </mat-form-field>

  <mat-form-field class="unix-user-input" *ngIf="isUnixUserIdSelected">
    <input [inheritDataId]="true"
      cogDataId="unix-user-input"
      matInput
      type="number"
      formControlName="unixUserId"
      placeholder="{{'placeholderUnixId' | translate}}"
      [disabled]="readOnly"
      min="0">
      <mat-error *ngIf="userAtDomainForm.controls['unixUserId'].hasError('required')"
        cogDataId="unix-user-id-required-error">
        {{ 'errors.required' | translate }}
      </mat-error>
  </mat-form-field>
</ng-container>
