/* eslint-disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for CohesityApiRms services
 */
@Injectable({
  providedIn: 'root',
})
export class CohesityApiRmsConfiguration {
  rootUrl: string = '/v2/mcm';
}

export interface CohesityApiRmsConfigurationInterface {
  rootUrl?: string;
}
