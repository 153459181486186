import { SubscriptionInfo, ThreatProtection } from '@cohesity/api/v1';
import dayjs from 'dayjs/esm';

import { EntitlementKeys } from '../app-configs';
import { IrisContext } from './iris-context.model';

/**
 * Provides the number of days remaining in users free trial or subscription.
 *
 * @param timestamp timestamp string.
 * @returns number of days remaining in free trial or subscription.
 */
export function daysRemaining(timestamp: string | number): number | null {
  if (!timestamp) {
    return null;
  }

  const today = dayjs();
  const endDate = dayjs(timestamp);

  // Add one day to the diff to include the end day itself.
  const daysLeft = endDate.diff(today, 'days') + 1;

  return Math.max(daysLeft, 0);
}

/**
 * Determines whether the user is a Helios Tenant User.
 *
 * @param irisContext The current iris context.
 * @returns  True if Helios tenant user, False otherwise.
 */
export function isHeliosTenantUser(irisContext: IrisContext): boolean {
  const { profiles } = irisContext.user || {};

  // Currently the tenant type 'Mcm' is not available in v1 YAML.
  const tenantType = profiles?.length ? (profiles[0].tenantType as any) : null;
  return tenantType === 'Mcm';
}

/**
 * Return whether the user is a privileged user.
 *
 * @param    irisContext The current iris context.
 * @param    userType Check only specific user type which is 'sales' or 'support'.
 * @returns  true if user is a privileged user.
 */
export function isPrivilegedUser(irisContext: IrisContext, userType?: 'support' | 'sales'): boolean {
  const isSupportUser = irisContext.user?.salesforceAccount?.isSupportUser || false;
  const isSalesUser = irisContext.user?.salesforceAccount?.isSalesUser || false;

  if (userType) {
    return userType === 'sales' ? isSalesUser : isSupportUser;
  }

  return isSalesUser || isSupportUser;
}

/**
 * Returns true if iris is in MCM mode.
 *
 * @param irisContext The current iris context.
 * @returns true if iris is in MCM mode.
 */
export function isMcm(irisContext: IrisContext): boolean {
  if (!irisContext.basicClusterInfo) {
    return false;
  }

  return irisContext.basicClusterInfo.mcmMode;
}

/**
 * Returns true if Dodin mode is enabled.
 *
 * @param irisContext The current iris context.
 * @returns true if iris is in Dodin mode.
 */
export function isDodin(irisContext: IrisContext): boolean {
  if (!irisContext.basicClusterInfo) {
    return false;
  }

  return irisContext.basicClusterInfo.dodinModeEnabled;
}

/**
 * Provides the subscription information from irisContext.
 *
 * @param irisContext The current iris context.
 * @returns The subscription info or null.
 */
export function getSubscriptionInfo(irisContext: IrisContext): SubscriptionInfo | null {
  return irisContext?.user?.subscriptionInfo;
}

/**
 * Provides the entitlement info found in the subscription.
 *
 * @param irisContext
 * @param entitlementKey
 * @returns the set of entitlements.
 */
export function getEntitlementInfo(irisContext: IrisContext, entitlementKey: EntitlementKeys) {
  return getSubscriptionInfo(irisContext)?.[entitlementKey];
}

export type CommonInfo = Pick<
  ThreatProtection,
  'banner' |
  'endDate' |
  'isActive' |
  'isFreeTrial' |
  'productDisplayName' |
  'startDate'
>;

export function getCommonEntitlementInfos(irisContext: IrisContext, entitlementKey: EntitlementKeys): CommonInfo[] {
  const subInfo = getSubscriptionInfo(irisContext);

  if (entitlementKey === EntitlementKeys.Llms) {
    return subInfo?.llms?.llms?.length ? subInfo?.llms?.llms : null;
  }

  if (entitlementKey === EntitlementKeys.GaiaIndexStorages) {
    return subInfo?.gaiaIndexStorages?.indexStorages?.length ? subInfo?.gaiaIndexStorages?.indexStorages : null;
  }

  return subInfo?.[entitlementKey] ? [subInfo?.[entitlementKey]] : null;
}
