<h2 mat-dialog-title>{{ 'recoveredItems' | translate }}</h2>

<mat-dialog-content>
  <ng-container *ngIf="(loading$ | async) === false; else spinner">
    <cog-table [source]="objects$ | async" name="recoveryItemsTable">
      <table mat-table>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>{{ 'name' | translate }}</th>
          <td mat-cell *matCellDef="let object">
            <div class="cog-table-object">
              <cog-icon [shape]="object | cohM365RecoveredObjectIcon"></cog-icon>
              <span class="object-name auto-truncate">{{ object.name }}</span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="size">
          <th mat-header-cell *matHeaderCellDef>{{ 'size' | translate }}</th>
          <td mat-cell *matCellDef="let object">{{ object.size | byteSize }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns"></tr>
      </table>
    </cog-table>
  </ng-container>

  <ng-template #spinner><cog-spinner></cog-spinner></ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button type="button" mat-flat-button color="primary" mat-dialog-close>{{ 'close' | translate }}</button>
</mat-dialog-actions>
