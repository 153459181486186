import { Observable, ReplaySubject, Subject, Subscriber } from 'rxjs';
import AES from 'crypto-js/aes';
import encUTF8 from 'crypto-js/enc-utf8';
/**
 * The type for records stored in the database.
 */
interface DBRecord {
  /**
   * The primary key in the object store.
   */
  key: string;

  /**
   * The actual value.
   */
  value: string;
}

/**
 * This class acts as an wrapper around a subset of indexed db apis to provide rxjs support.
 * It simplifies reading and writing to a database so that it can be easily done with observables.
 */
export class IndexedDBAdapter<RecordType = unknown> {
  /**
   * A replay subject of the databaase itself. This is opened when the object is created.
   */
  private db: Subject<IDBDatabase> = new ReplaySubject<IDBDatabase>(1);

  /**
   * Constructs a new database wrapper instance.
   *
   * @param name          The name of the database.
   * @param version       The version of the database.
   * @param store         The name of the object store to use. This currently supports only one object store and will
   *                      automatically set the id under the 'key' property.
   * @param supressErrors By default the wrapper will not emit errors if there is a problem reading or writing to the db
   *                      since this is used primarily for caching, a failure is not critical and should be fine to
   *                      ignore.
   */
  constructor(
    name: string,
    version: number,
    private store: string,
    private encryptionKey?: string,
    private supressErrors = true
  ) {
    const idb = window.indexedDB.open(name, version);

    // Create the object store if needed
    idb.onupgradeneeded = event => {
      const db = idb.result;
      if (event.oldVersion < version || !db.objectStoreNames.contains(store)) {
        db.createObjectStore(store, { keyPath: 'key' });
      }
    };

    idb.onerror = () => {
      if (!this.supressErrors) {
        this.db.error(new Error('unable to open db'));
      }
      this.db.complete();
    };

    idb.onsuccess = () => {
      this.db.next(idb.result);
      this.db.complete();
    };
  }

  /**
   * Get an item from the object store. This will resolve to null if it is not present.
   *
   * @param key The key to look up.
   * @returns The object if it exists, otherwise null.
   */
  get(key: string): Observable<RecordType> {
    return new Observable<RecordType>(subscriber => {
      this.db.subscribe(db => {
        try {
          const request = db.transaction([this.store], 'readonly').objectStore(this.store).get(key);

          request.onsuccess = () => {
            const result: DBRecord = request.result;
            subscriber.next(result ? this.deserialize(result.value) : null);
            subscriber.complete();
          };

          request.onerror = error => this.handleError(subscriber, error);
        } catch (error) {
          this.handleError(subscriber, error);
        }
      });
    });
  }

  /**
   * Adds an item to the object store.
   *
   * @param key The object's key.
   * @param object The object to save.
   * @returns An observable that resolves when the write completes successfully.
   */
  put(key: string, object: RecordType): Observable<void> {
    return new Observable<void>(subscriber => {
      this.db.subscribe(db => {
        try {
          const request = db
            .transaction([this.store], 'readwrite')
            .objectStore(this.store)
            .put({
              value: this.serialize(object),
              key,
            } as DBRecord);

          request.onsuccess = () => {
            subscriber.next();
            subscriber.complete();
          };
          request.onerror = error => this.handleError(subscriber, error);
        } catch (error) {
          this.handleError(subscriber, error);
        }
      });
    });
  }
  /**
   * Convert an object to a string before writing it to storage. If encryption is enabled, it will serialize and
   * encrypt the value.
   *
   * @param object The object to serialize
   * @returns A string representation of the object
   */
  serialize(object: RecordType): string {
    if (!object) {
      return null;
    }
    if (!this.encryptionKey) {
      console.error('Writing to IndexedDB with no Encryption. Do not do this unless you are debugging something');
      return JSON.stringify(object);
    }
    return AES.encrypt(JSON.stringify(object), this.encryptionKey).toString();
  }

  /**
   * Parse a string value from storage and return it as an object. If encryption is enabled, this will decrypt the
   * string first.
   *
   * @param serialized The string to parse
   * @returns The parsed object.
   */
  deserialize(serialized: string): RecordType {
    if (!serialized) {
      return null;
    }
    if (!this.encryptionKey) {
      console.error('Reading to IndexedDB with no Encryption. Do not do this unless you are debugging something');
      return JSON.parse(serialized);
    }
    try {
      return JSON.parse(AES.decrypt(serialized, this.encryptionKey).toString(encUTF8));
    } catch (e) {
      return null;
    }
  }

  /**
   * Apply the same logic for handling errors.
   *
   * @param subscriber The subscribe to emit an error on.
   * @param error The error that occurred.
   */
  private handleError(subscriber: Subscriber<any>, error: any) {
    if (!this.supressErrors) {
      subscriber.error(error);
    }
    subscriber.complete();
  }
}
