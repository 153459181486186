/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityReportingApiConfiguration as __Configuration } from '../cohesity-reporting-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class ArtifactsService extends __BaseService {
  static readonly GetArtifactForTaskPath = '/public/artifacts/{taskId}/{format}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Fetch report artifact for task
   *
   * Get the report artifact associated with task.
   * @param params The `ArtifactsService.GetArtifactForTaskParams` containing the following parameters:
   *
   * - `taskId`: Specifies the task for which report must be fetched.
   *
   * - `format`: Specifies the format in which the report is requested.
   *
   * @return The file pased on the artifact type.
   */
  GetArtifactForTaskResponse(params: ArtifactsService.GetArtifactForTaskParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/artifacts/${encodeURIComponent(params.taskId)}/${encodeURIComponent(params.format)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * Fetch report artifact for task
   *
   * Get the report artifact associated with task.
   * @param params The `ArtifactsService.GetArtifactForTaskParams` containing the following parameters:
   *
   * - `taskId`: Specifies the task for which report must be fetched.
   *
   * - `format`: Specifies the format in which the report is requested.
   *
   * @return The file pased on the artifact type.
   */
  GetArtifactForTask(params: ArtifactsService.GetArtifactForTaskParams): __Observable<Blob> {
    return this.GetArtifactForTaskResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }
}

module ArtifactsService {

  /**
   * Parameters for GetArtifactForTask
   */
  export interface GetArtifactForTaskParams {

    /**
     * Specifies the task for which report must be fetched.
     */
    taskId: string;

    /**
     * Specifies the format in which the report is requested.
     */
    format: 'PDF' | 'XLS' | 'CSV' | 'RawData';
  }
}

export { ArtifactsService }
