import { Injectable } from '@angular/core';
import {
  AagAndDatabases,
  ProtectionSource,
  ProtectionSourcesServiceApi as SourcesApi,
  SqlAagHostAndDatabases,
} from '@cohesity/api/v1';
import { transform, uniqBy } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UIRouterGlobals } from '@uirouter/core';


/**
 * Hybrid type AAG host object, depending on if the AAG host is known to this
 * cluster or not.
 */
export interface AagHost extends ProtectionSource, SqlAagHostAndDatabases {}

/**
 * Aag data as transformed from server response.
 */
export interface AagData extends ProtectionSource {
  hosts: AagHost[];
}

/**
 * HashMap of AagData objects keyed by the AAG name string.
 */
export interface AagDataMap {
  [key: string]: AagData;
}

@Injectable({
  providedIn: 'root'
})
export class SqlUtilityService {
  /**
   * Optional region id to get sources from. This is set from the
   * region id state param if present.
   */
  get regionId(): string {
    return this.uiRouterGlobals?.params?.regionId;
  }

  constructor(
    private sourceService: SourcesApi,
    private uiRouterGlobals: UIRouterGlobals,
  ) {}

  /**
   * Cache of AagDataMap Observables.
   */
  private aagDataCache$ = new BehaviorSubject<{[key: number]: AagDataMap}>({});

  /**
   * Gets AAG data from cache.
   *
   * @param     nodeId   The node ID to get data for.
   * @returns   Observable aag data
   */
  getAagData(nodeId: number): Observable<AagDataMap> {
    return this.aagDataCache$.pipe(map(cache => cache[nodeId]));
  }

  /**
   * Fetchs AAG data.
   *
   * @param   nodeId   The node ID to get data for.
   */
  fetchAagData(nodeId: number) {
    this.sourceService
      .ListSqlAagHostsAndDatabases({ sqlProtectionSourceIds: [nodeId], regionId: this.regionId })
      .pipe(map(this.transformAagResponse))
      .subscribe(result => this.aagDataCache$.next({...this.aagDataCache$.value, [nodeId]: result}));
  }

  /**
   * Transforms an AAG response.
   *
   * @param   resp   The Server's response.
   * @returns the transformed data.
   */
  private transformAagResponse(resp: SqlAagHostAndDatabases[]): AagDataMap {
    return (resp || []).reduce((output: AagDataMap, aagObject: SqlAagHostAndDatabases) => {
      (aagObject.aagDatabases || []).forEach((aagDatabase: AagAndDatabases) => {
        let thisAag = output[aagDatabase.aag.name];

        if (!thisAag) {
          output[aagDatabase.aag.name] = thisAag = { ...aagDatabase.aag, hosts: [] };
        }

        thisAag.hosts.push(
          aagObject.applicationNode
            ? aagObject.applicationNode.protectionSource
            : aagObject
        );
      });

      return output;
    }, {});
  }

  /**
   * Gets hosts from aag data.
   *
   * @param    data   The data from the transformed API response.
   * @returns         Hosts from aag data.
   */
  getHostsFromAagData(data: AagDataMap): AagHost[] {
    const hosts = transform(data, (hostsAccumulator: any[], aagObject: any) => {
      hostsAccumulator.push(...aagObject.hosts.filter(host => host.id));
      return hostsAccumulator;
    }, []);

    return uniqBy(hosts, 'id');
  }
}
