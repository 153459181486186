<div class="legends"
  *ngIf="data$ | async as data">
  <div *ngFor="let sub of data?.subscriptions"
    class="legend-container"
    [ngClass]="sub.storageClass">
    <div class="subscription">{{ sub.label }}</div>
    <div class="usage-container">
      <a class="usage-bytes"
        (click)="gotoReport.emit()">
        {{ sub.usedBytes | byteSize }}
      </a>
      <mat-progress-bar mode="determinate"
        [value]="getUsageBytes(sub.usedBytes, sub.totalBytes)">
      </mat-progress-bar>
    </div>
  </div>
</div>
<div class="chart-container">
  <ng-container *ngIf="chartData$ | async as chartData">
    <cog-line-chart *ngIf="chartData?.series && chartData?.options"
      [chartOptions]="chartData?.options"
      [seriesData]="chartData?.series"
      [height]="104">
    </cog-line-chart>
  </ng-container>
</div>
