<cog-settings-list-item [formGroup]="formGroup"
  [label]="'protectionGroups.prePostScripts' | translate">
  <cog-settings-list-item-value-display>
    <ng-container *ngIf="(internalFormGroup.value.preBackupScript ||
      internalFormGroup.value.postSnapshotScript ||
      internalFormGroup.value.postBackupScript); else none">
      <p>
        {{ 'preScript' | label }}
        {{ internalFormGroup.value.preBackupScript | boolLabel: 'enabled' : 'disabled' }}
      </p>
      <p>
        {{ 'postSnapshotScript' | label }}
        {{ internalFormGroup.value.postSnapshotScript | boolLabel: 'enabled' : 'disabled' }}
      </p>
      <p>
        {{ 'postScript' | label }}
        {{ internalFormGroup.value.postBackupScript | boolLabel: 'enabled' : 'disabled' }}
      </p>
    </ng-container>
  </cog-settings-list-item-value-display>

  <cog-settings-list-item-value-editor [formGroup]="internalFormGroup">
    <coh-pre-post-script-details-form label="preScript" formControlName="preBackupScript">
    </coh-pre-post-script-details-form>

    <coh-pre-post-script-details-form label="postSnapshotScript" formControlName="postSnapshotScript">
    </coh-pre-post-script-details-form>

    <coh-pre-post-script-details-form label="postScript" formControlName="postBackupScript">
    </coh-pre-post-script-details-form>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>

<ng-template #none>
  <p>{{ 'none' | translate }}</p>
</ng-template>
