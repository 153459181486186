import { InjectionToken } from '@angular/core';

/**
 * Injection token that is used to figure out if the UI is currently running
 * in IBM aqua context
 */
export const IS_IBM_AQUA_ENV = new InjectionToken<boolean>('Determines whether the UI is running in IBM Aqua mode');

/**
 * Injection token for configuring configuration files path
 */
export const PLATFORM_CONFIG_PATHS = new InjectionToken<string[]>(
  'Path to configuration files in order of INCREASING priority.'
);
