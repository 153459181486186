import { Pipe, PipeTransform } from '@angular/core';
import { File } from '@cohesity/api/v2';

/**
 * Default file icon if the object didn't match an existing icon type.
 */
const DefaultFileIcon = 'helix:object-file-resting';

/**
 * Map of different file types and their icons.
 */
const FileIconMap = {
  File: 'helix:object-file-resting',
  Directory: 'helix:object-folder',
  Symlink: 'helix:sym-link',
  Volume: 'helix:volume',
};

/**
 * Type for different file types. Volume is not added here, since this type is used in other places where Volume is
 * not a valid value.
 */
export type FileType = Exclude<File['type'], null>;

/**
 * Converts a file type into an icon name.
 *
 * @example
 * <cog-icon [shape]="fileType | fileIcon"></cog-icon>
 */
@Pipe({
  name: 'fileIcon'
})
export class FileIconPipe implements PipeTransform {
  transform(value: FileType | 'Volume'): string {
    return FileIconMap[value] || DefaultFileIcon;
  }
}
