<ng-content *ngIf="!deleted"></ng-content>
<ng-container *ngIf="deleted">
  <cog-icon *ngIf="useIcon; else label"
    shape="helix:snapshot-deleted"
    [size]="iconSize"
    [matTooltip]="'snapshotDeleted' | translate">
  </cog-icon>
</ng-container>
<ng-template #label>
  <span class="snapshot-deleted">
    {{ 'snapshotDeleted' | translate }}
  </span>
</ng-template>
