import { CommonRecoverFileAndFolderInfo } from '@cohesity/api/v2';
import { RecoveryOsType } from 'src/app/shared/constants';

/**
 * Copied from AJS _c-utils.js.
 * Transforms a windows path which is stored as a unix path in magneto
 * to windows style path with backslashes and : for display purpose
 *
 * @param    path  Original path string
 * @returns  Windows path
 *
 * @example   transformToWindowsPath('/C/Users') returns 'C:\Users'
 */
export function transformToWindowsPath(path: string): string {
  if (!path) {
    return path;
  }

  // Handle the case when path is something like '/C', we want to return 'C:/'
  if (path.length === 2 && path.charAt(0) === '/') {
    path = path + '/';
  }

  let result = path.replace(/\//g, '\\');

  if (result.charAt(0) === '\\' && result.charAt(2) === '\\') {
    result = result.replace(/\\/, '');
    result = result.slice(0, 1) + ':' + result.slice(1);
  }
  return result;
}

/**
 * Data model for Recovery Detail page file object table.
 */
export class RecoveryFileObject {
  /**
   * Absolute path of file/folder.
   */
  absolutePath: string;

  /**
   * Destination directory where the file/directory was copied.
   */
  destinationDir: string;

  /**
   * path of file/folder.
   */
  path: string;

  /**
   * name of file/folder.
   */
  name: string;

  /**
   * Whether the object is folder.
   */
  isDirectory: boolean;

  /**
   * Status.
   */
  status: string;

  /**
   * Messages.
   */
  messages: string[];

  /**
   * Constructor.
   *
   * @param   reference   Return data structure of Common Recovery File Object from API
   * @param   osType      Used to check whether path needs to be transformed
   */
  constructor(public reference: CommonRecoverFileAndFolderInfo, osType?: string) {
    const {
      absolutePath,
      destinationDir,
      isDirectory,
      status
    }: CommonRecoverFileAndFolderInfo = reference;
    if (absolutePath) {
      const pathNames = absolutePath.split('/');
      // Copy the logic from AJS restore-service.js getRestoreFileOrFolderNameAndPath() to set name/path.
      this.name = pathNames.pop();
      this.path = pathNames.join('/') || '/';
    }

    this.absolutePath = absolutePath;
    this.destinationDir = destinationDir;
    this.isDirectory = isDirectory;
    this.status = status;
    this.messages = (reference as any).messages;

    if (osType as RecoveryOsType === RecoveryOsType.kWindows) {
      this.path = transformToWindowsPath(this.path);
    }
  }
}
