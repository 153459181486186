<cog-page class="cog-takeover-theme">
  <cog-page-header>
    <cog-page-toolbar title="{{ pageDialogTitle }}"
      (back)="cancel()"
      [backTooltip]="'cancel' | translate"
      color="accent">
      <cog-page-actions>
        <ng-content select="coh-page-dialog-actions"
          *ngIf="pageDialogActionsComponent"></ng-content>
      </cog-page-actions>
      <cog-page-nav *ngIf="pageDialogNavComponent">
        <ng-content select="coh-page-dialog-nav"></ng-content>
      </cog-page-nav>
    </cog-page-toolbar>
  </cog-page-header>
  <div class="page-dialog-content">
    <ng-content></ng-content>
  </div>
</cog-page>
