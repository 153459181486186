import { isDmsScope, isDraasScope, isGlobalScope, isIbmBaaSEnabled, isTenantUser } from '@cohesity/iris-core';
import { AppModuleConfig, CanAccess, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';
import { generalCdpAccess } from 'src/app/modules/cdp/cdp.config';

const hasDmsAccess = (ctx: StateAccessContext) => isDmsScope(ctx.irisContext);
const inGlobalContext = (ctx: StateAccessContext) => isGlobalScope(ctx.irisContext);
const editBoardAccess: CanAccess = (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.customDashboardEnabled;

/**
 * Common/shared dashboard access checks for dasshboards serving on-prem or
 * Helios based cluster related dashboards.
 */
const sharedAccessChecks = (ctx: StateAccessContext) =>
  !isGlobalScope(ctx.irisContext) &&
  !ctx.isTenantUser &&
  !ctx.isHeliosTenantUser &&
  !ctx.isRestrictedUser;

/**
 * Disaster Recovery dashboard access control.
 */
const hasDrAccess = (ctx: StateAccessContext) =>
  (isDraasScope(ctx.irisContext) ||
    (ctx.FEATURE_FLAGS.keplerEnabled && ctx.FEATURE_FLAGS.keplerDashboardEnabled)) &&
  !ctx.isTenantUser &&
  !ctx.isRestrictedUser;

export const DashboardsConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'dashboards.**',
      url: '/dashboards',
      loadChildren: () => import('./dashboards.module').then(m => m.DashboardsModule),
    },
  ],
  allClusterMap: {
    'dashboards': {
      singleClusterState: 'dashboards',
      allClustersState: 'dashboards',
      globalContext: true,
    },
    'dashboards.summary': true,
    'dashboards.data-protection': true,
    'dashboards.cloud': true,
    'dashboards.ibm-baas': true,
    'dashboards.file-services': false,
    'dashboards.dms': true,
    'dashboards.global': {
      allClustersState: 'dashboards.global',
      globalContext: true,
      singleClusterState: 'dashboards.global',
    },
    'dashboards.disaster-recovery': true,
    'dashboards.organization': {
      heliosOnly: true,
      allClustersState: 'dashboards.organization',
      singleClusterState: 'dashboards.organization',
    },
    'dashboards.edit': {
      allClustersState: 'dashboards.edit',
      singleClusterState: 'dashboards.edit',
      globalContext: true,
    },
    'dashboards.add': {
      allClustersState: 'dashboards.add',
      singleClusterState: 'dashboards.add',
      globalContext: true,
    },
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'dashboards': () => true,
      'dashboards.summary': sharedAccessChecks,
      'dashboards.data-protection': (ctx: StateAccessContext) => sharedAccessChecks(ctx),
      'dashboards.cloud': (ctx: StateAccessContext) =>
        ctx.FEATURE_FLAGS.ngCloudDashboard &&
        sharedAccessChecks(ctx),
      'dashboards.ibm-baas': (ctx: StateAccessContext) =>
        isIbmBaaSEnabled(ctx.irisContext) &&
        isTenantUser(ctx.irisContext),
      'dashboards.file-services': (ctx: StateAccessContext) => sharedAccessChecks(ctx),
      'dashboards.dms': hasDmsAccess,
      'dashboards.global': inGlobalContext,
      'dashboards.cdp': generalCdpAccess,
      'dashboards.disaster-recovery': hasDrAccess,
      'dashboards.organization': (ctx: StateAccessContext) => ctx.isHeliosTenantUser,
      'dashboards.edit': editBoardAccess,
      'dashboards.add': editBoardAccess,
    };
  },
};
