import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const ldapViewAccess = (ctx: StateAccessContext) => ctx.AD_LDAP_VIEW && ctx.FEATURE_FLAGS.ngLdapProvider;
const ldapModifyAccess = (ctx: StateAccessContext) => ctx.AD_LDAP_MODIFY  && ctx.FEATURE_FLAGS.ngLdapProvider;

export const LdapConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'ldap-details.**',
      url: '/access/ldap/{id}',
      loadChildren: () => import('./ldap.module').then(m => m.LdapModule),
    },
    {
      name: 'modify-ldap.**',
      url: '^/access/ldap/create',
      loadChildren: () => import('./ldap.module').then(m => m.LdapModule),
    },
    {
      name: 'create-ldap.**',
      url: '^/access/ldap/edit/{id}',
      loadChildren: () => import('./ldap.module').then(m => m.LdapModule),
    }
  ],

  getStateAccessMap(): StateAccessMap {
    return {
      'ldap-details': ldapViewAccess,
      'modify-ldap': ldapModifyAccess,
      'create-ldap': ldapModifyAccess,
      'list-ldap-providers': ldapViewAccess,
    };
  },
};
