import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';

import { DataIdModule } from '../data-id/data-id.module';
import { BooleanFormFieldComponent } from './components/boolean-form-field/boolean-form-field.component';
import { GenericFormFieldComponent } from './components/generic-form-field/generic-form-field.component';
import { NumberFormFieldComponent } from './components/number-form-field/number-form-field.component';
import { PasswordFormFieldComponent } from './components/password-form-field/password-form-field.component';
import { RadioGroupFormFieldComponent } from './components/radio-group-form-field/radio-group-form-field.component';
import { StringFormFieldComponent } from './components/string-form-field/string-form-field.component';

@NgModule({
  declarations: [
    BooleanFormFieldComponent,
    GenericFormFieldComponent,
    NumberFormFieldComponent,
    PasswordFormFieldComponent,
    RadioGroupFormFieldComponent,
    StringFormFieldComponent,
  ],
  imports: [
    CommonModule,
    DataIdModule,
    MatFormFieldModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSlideToggleModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
  ],
  exports: [
    GenericFormFieldComponent,
  ],
})
export class GenericFormFieldModule { }
