import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { FilePathParameters } from '@cohesity/api/v1';
import { DataTreeNodeContext, DataTreeNodeDetail } from '@cohesity/helix';
import { IrisContextService, flagEnabled, isDmsScope } from '@cohesity/iris-core';
import { AllLocalDrives, AllowedFsTypes, PhysicalEntityType, REGEX_FORMATS } from 'src/app/shared/constants';
import { convertToHostSpecificPath, convertToUnixPath } from 'src/app/util';

import { PhysicalFilesSourceDataNode } from '../physical-files-source-data-node';
import { flatten } from 'lodash';

@Component({
  selector: 'coh-physical-files-host-options',
  templateUrl: './physical-files-host-options.component.html',
  styleUrls: ['./physical-files-host-options.component.scss'],

  // Disabling encapsulation as some content that needs to be themed lives in modal context.
  encapsulation: ViewEncapsulation.None,

})
export class PhysicalFilesHostOptionsComponent implements DataTreeNodeDetail<PhysicalFilesSourceDataNode> {

  /**
   * Mat chip list's separator key codes.
   */
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  /**
   * The node context, including info about the node and it's selection status.
   */
  @Input() nodeContext: DataTreeNodeContext<PhysicalFilesSourceDataNode>;

  /**
   * MountPoint input ref.
   */
  @ViewChild('mountPointInput') mountPointInput: ElementRef<HTMLInputElement>;

  /**
   * VSS Writer input ref.
   */
  @ViewChild('vssWriterInput') vssWriterInput: ElementRef<HTMLInputElement>;

  /**
   * Gets the node from the nodeContext.
   */
  get node(): PhysicalFilesSourceDataNode {
    return this.nodeContext.node;
  }

  /**
   * Gets the current options for the node. This either gets them from the selection options, or
   * the default params for the node.
   */
  get currentOptions() {
    return this.nodeContext.selection.getOptionsForNode(this.node.id) || {};
  }

  /**
   * Gets the list of filepaths, if any, from the special parameters.
   */
  get filePaths() {
    return (this.currentOptions.physicalSpecialParameters || {}).filePaths;
  }

  /**
   * Gets the list of nested mount point types to skip, if any, from the special parameters.
   */
  get skipNestedVolumesVec() {
    return (this.currentOptions.physicalSpecialParameters || {}).skipNestedVolumesVec;
  }

  /**
   * Gets follow nas symlink target, if any, from the special parameters.
   */
  get followNasSymlinkTarget() {
    return !!(this.currentOptions.physicalSpecialParameters || {}).followNasSymlinkTarget;
  }

  /**
   * Gets shouldProtectAllVolumes, based on the filePaths array.
   */
  get shouldProtectAllVolumes() {
    return flagEnabled(this.contextService.irisContext, 'enablePhysicalFileAllDriveBackup') &&
      (this.filePaths || []).length && this.filePaths[0].backupFilePath === AllLocalDrives &&
      !this.isDmsScope;
  }

  /**
   * Gets isMetadataFileBackup, if metadataFilePath is set in special parameters.
   */
  get isMetadataFileBackup() {
    return flagEnabled(this.contextService.irisContext, 'enablePhysicalFileMetaFileBackup') &&
      !!(this.currentOptions.physicalSpecialParameters || {}).metadataFilePath;
  }

  /**
   * Gets the type of protection
   */
  get protectionType(): string {
    if (this.shouldProtectAllVolumes) {
      return 'allVolumes';
    }

    if (this.isMetadataFileBackup) {
      return 'metadataFile';
    }

    return 'specifyDirectories';
  }

  /**
   * Return validators for exclude path.
   */
  get excludePathValidators() {
    return [
      this.node.isWindows && Validators.pattern(REGEX_FORMATS.windowsExcludeFilePathRegex)
    ].filter(Boolean);
  }

  /**
   * Return validators for include path.
   */
  get includePathValidators() {
    if (!this.isDmsScope) {
      return [
        this.node.isWindows && Validators.pattern(REGEX_FORMATS.windowsFilePathRegex)
      ].filter(Boolean);
    }
  }

  /**
   * Decides whether we should use path or object level skip nested volume settings.
   */
  get showSkipNestedDropdown() {
    return !this.node.isWindows &&
      !(this.currentOptions.physicalSpecialParameters || {}).usesPathLevelSkipNestedVolumeSetting;
  }

  /**
   * Indicates if the user is operating in DMaaS scope or not.
   */
  get isDmsScope(): boolean {
    return isDmsScope(this.contextService.irisContext);
  }

  /**
   * Returns true if the feature to allow skipping FS
   * even when not mounted in the system is enabled.
   */
  get showSkipUnmountedFS(): boolean {
    return flagEnabled(this.contextService.irisContext, 'physicalSkipUnmountedFS');
  }

  /**
   * Returns options for include path selection
   */
  get includePathOptions(): string[] {
    if (!flagEnabled(this.contextService.irisContext, 'unixClusterBackupEnabled')) {
      return [];
    }

    const volumes = (
      this.node?.protectionSource?.physicalProtectionSource?.volumes || []).filter(v => v?.isSharedVolume
      );
    return flatten(volumes.map(v => v.mountPoints));
  }

  /**
   * The form to contain the options.
   */
  form: UntypedFormGroup;

  /**
   * Exclude file paths form group.
   */
  excludeFilePathForm = this.fb.group({
    path: new UntypedFormControl('')
  });

  constructor(
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private contextService: IrisContextService) { }

  /**
   * Helper function for feature flag checks that is easier to expose to the html templae
   *
   * @param flag The flag name
   * @returns true if the flag is enabled
   */
  flagEnabled(flag: string): boolean {
    return flagEnabled(this.contextService.irisContext, flag);
  }

  /**
   * Updates the form based on the current options setting in preparation for displaying the form
   * dialog.
   */
  updateForm() {
    let protectionType = this.protectionType;

    if (this.filePaths && this.filePaths.length) {
      this.form = this.fb.group({
        filePaths: this.fb.array([]),
        skipNestedVolumesVec: new UntypedFormControl(this.skipNestedVolumesVec || []),
        protectNetworkMountPoints: new UntypedFormControl(!(this.skipNestedVolumesVec?.length > 0)),
        followNasSymlinkTarget: new UntypedFormControl(this.followNasSymlinkTarget),
      });
      this.setFilePaths();
    } else {
      // Selecting protect all volumes as default for windows os or windows file server role.
      if ((this.node?.type === PhysicalEntityType.kWindowsCluster && this.node?.clusterSourceType === 'kRole')
        || (this.node.isWindows && !this.isMetadataFileBackup)) {
        protectionType = 'allVolumes';
      }

      const skipNestedVolumesVec = (this.node?.volumeMountTypes || []).filter(type =>
        AllowedFsTypes.includes(type.toLowerCase()));
      this.form = this.fb.group({
        filePaths: this.fb.array([
          this.fb.group({
            path: new UntypedFormControl((this.node.isWindows ? (this.isDmsScope ? AllLocalDrives : 'C:\\') : '/'),
              this.includePathValidators),
            excludePaths: this.shouldProtectAllVolumes ? this.fb.array([]) : this.setExcludedPaths(),
            skipNestedVolumes: new UntypedFormControl(true),
          })
        ]),
        skipNestedVolumesVec: new UntypedFormControl(skipNestedVolumesVec),
        protectNetworkMountPoints: new UntypedFormControl(!(skipNestedVolumesVec?.length > 0)),
        followNasSymlinkTarget: new UntypedFormControl(this.followNasSymlinkTarget),
      });
    }

    this.form.addControl('protectionType', new UntypedFormControl(protectionType));
    this.form.addControl('protectAllPathInfo', this.fb.group({
      path: new UntypedFormControl(AllLocalDrives),
      excludePaths: this.shouldProtectAllVolumes ?
        this.setExcludedPaths(this.filePaths[0]) : this.fb.array([]),
    }));

    const metadataPathInfo = this.isMetadataFileBackup ?
      (this.currentOptions.physicalSpecialParameters || {}).metadataFilePath : '';
    this.form.addControl('metadataPathInfo', new UntypedFormControl(
      convertToHostSpecificPath(metadataPathInfo, this.node.isWindows), this.includePathValidators));
    this.cdr.detectChanges();
  }

  /**
   * Method called to update mount point selection.
   *
   * @param mountType    Selected mount type
   * @param isRemovable  True if allowing mount point to be removed from the list.
   *                     Otherwise, do nothing if the mountType already exists in the list.
   */
  updateNestedMountPointsSelection(mountType: string, isRemovable = true) {
    let skipNestedVolumesVec = [];

    if (this.form.value?.skipNestedVolumesVec) {
      skipNestedVolumesVec = [...this.form.value.skipNestedVolumesVec];
    }
    const index = skipNestedVolumesVec.indexOf(mountType);

    if (index > -1) {
      if (isRemovable) {
        skipNestedVolumesVec.splice(index, 1);
      }
    } else {
      skipNestedVolumesVec.push(mountType);
    }
    this.form.controls.skipNestedVolumesVec.setValue(skipNestedVolumesVec);
  }

  /**
   * Method called to determine whether the specified mount type is selected or not.
   *
   * @param   mountType   mount type
   * @returns True if the mount type is selected.
   */
  isMountTypeSelected(mountType: string): boolean {
    return (this.form.controls.skipNestedVolumesVec.value || []).indexOf(mountType) > -1;
  }

  /**
   * Method called to select all nested mount points.
   *
   * @param change selection change
   */
  selectAllMountTypes(change: boolean) {
    // Filter out Allowed Mount Types from all Volume Mount Types
    const allowedMountTypes = (this.node?.volumeMountTypes || []).filter(type =>
      AllowedFsTypes.includes(type.toLowerCase()));
    this.form.controls.skipNestedVolumesVec.setValue(change ? [] : allowedMountTypes);

    // Set 'skipNestedVolumes' based on checkbox selection.
    // This is needed because 'nestedVolumeTypesToSkip' is not being reffered in backend.
    // And the fix is needed for this FI-40296
    // TODO: Remove once 'nestedVolumeTypesToSkip' bug is fixed in backend
    const filePathsControl = this.form.controls.filePaths as UntypedFormArray;
    const filePathsVal = filePathsControl.value.map(path => {
      path.skipNestedVolumes = !change;
      return path;
    });
    filePathsControl.setValue(filePathsVal);
    filePathsControl.updateValueAndValidity();
    this.cdr.detectChanges();
  }

  /**
   * Method called to select nested mount points for a file path.
   *
   * @param change selection change
   * @param filePath File Path form
   */
  selectNestedMountTypes(change: boolean, filePathForm: UntypedFormGroup) {
    // Filter out Allowed Mount Types from all Volume Mount Types
    const allowedMountTypes = (this.node?.volumeMountTypes || []).filter(type =>
      AllowedFsTypes.includes(type.toLowerCase()));
    this.form.controls.skipNestedVolumesVec.setValue(change ? [] : allowedMountTypes);

    filePathForm?.controls?.skipNestedVolumes.setValue(!change);
    filePathForm?.controls?.skipNestedVolumes.updateValueAndValidity();
    this.cdr.detectChanges();
  }

  /**
   * Handle protection type change
   */
  handleProtectionTypeChange(type: string) {
    const excludePaths = type === 'specifyDirectories' ? this.setExcludedPaths() : this.fb.array([]);
    // Clear file path on toggle
    const filePaths = this.form.controls.filePaths as UntypedFormArray;
    filePaths.clear();
    filePaths.push(this.fb.group({
      path: new UntypedFormControl((this.node.isWindows ? 'C:\\' : '/'), this.includePathValidators),
      excludePaths: excludePaths,
      skipNestedVolumes: new UntypedFormControl(true),
    }));
    const metadataPathInfoControl = this.form.get('metadataPathInfo');
    metadataPathInfoControl.setValidators(
      type === 'metadataFile' ? [Validators.required, ...this.includePathValidators] : null
    );
    metadataPathInfoControl.updateValueAndValidity();
    this.form.updateValueAndValidity();
    this.cdr.detectChanges();
  }

  /**
   * Updates the selection options after the form has been saved and the dialog has been closed.
   */
  onSaved() {
    const formValue = this.form.value;
    let filePaths = [];
    if (formValue.protectionType === 'allVolumes') {
      filePaths = [formValue.protectAllPathInfo];
    } else if (formValue.protectionType === 'metadataFile') {
      filePaths = [];
    } else {
      filePaths = formValue.filePaths;
    }

    const options = {
      sourceId: this.node.id,
      physicalSpecialParameters: {
        filePaths: filePaths.length ? filePaths.map(filePath => {
          const unixPath = {
            backupFilePath: convertToUnixPath(filePath.path, this.node.isWindows),
            excludedFilePaths: filePath.excludePaths
              .filter(excludePath => excludePath !== '')
              .map(path => convertToUnixPath(path, this.node.isWindows)),
            skipNestedVolumes: filePath.skipNestedVolumes,
          };
          return unixPath;
        }) : undefined,
        usesPathLevelSkipNestedVolumeSetting: !this.showSkipNestedDropdown,
        skipNestedVolumesVec: formValue.skipNestedVolumesVec,
        followNasSymlinkTarget: formValue.followNasSymlinkTarget,
        metadataFilePath: formValue.protectionType === 'metadataFile'
          ? convertToUnixPath(formValue.metadataPathInfo, this.node.isWindows)
          : undefined,
      },
    };
    this.nodeContext.selection.setOptionsForNode(this.node.id, options);
    this.cdr.detectChanges();
  }

  /**
   * Adds an inclusion path for a physical server
   *
   * @method   addPath
   */
  addPath() {
    const control = this.form.controls.filePaths as UntypedFormArray;
    const excludePaths = new UntypedFormArray([]);
    excludePaths.push(new UntypedFormControl('', this.excludePathValidators));
    control.push(
      this.fb.group({
        path: new UntypedFormControl('', this.includePathValidators),
        excludePaths: excludePaths,
        skipNestedVolumes: new UntypedFormControl(true),
      })
    );
  }

  /**
   * Removes an inclusion path for a physical server
   *
   * @method   removePath
   * @param    index   The path index
   */
  removePath(index: number) {
    const control = this.form.controls.filePaths as UntypedFormArray;
    control.removeAt(index);
  }

  /**
   * Adds an exclude path for a particular include path in a physical server
   *
   * @method   addExcludePath
   * @param    control      The include path form control
   * @param    excludePath  exclude path value
   */
  addExcludePath(control: UntypedFormArray, excludePath?: string) {
    control.push(new UntypedFormControl(excludePath ?? '', this.excludePathValidators));

    // Adding new entry if exclude path is present.
    if (excludePath?.trim()?.length > 0) {
      control.push(new UntypedFormControl('', this.excludePathValidators));
    }
  }

  /**
   * Removes an exclude path for a particular include path in a physical server
   *
   * @method   removeExcludePath
   * @param    control   The include path form control
   * @param    index     The exclude path index
   */
  removeExcludePath(control: UntypedFormArray, index: number) {
    control.removeAt(index);
  }

  /**
   * Sets the file paths for an edit options scenario when the file paths are
   * set previously.
   *
   * @method   setFilePaths
   */
  setFilePaths() {
    const control = this.form.controls.filePaths as UntypedFormArray;
    if (this.shouldProtectAllVolumes) {
      control.push(this.fb.group({
        path: new UntypedFormControl((this.node.isWindows ? 'C:\\' : '/'), this.includePathValidators),
        excludePaths: this.fb.array([]),
        skipNestedVolumes: new UntypedFormControl(true),
      }));
    } else {
      this.filePaths.forEach(filePath => {
        control.push(this.fb.group({
          path: new UntypedFormControl(
            convertToHostSpecificPath(filePath.backupFilePath, this.node.isWindows),
            this.includePathValidators),
          excludePaths: this.setExcludedPaths(filePath),
          // DMS has Protect nested mount which is reverse of skip nested mount points
          skipNestedVolumes: filePath.skipNestedVolumes,
        }));
      });
    }
  }

  /**
   * Sets the excluded paths for an edit options scenario
   *
   * @method   setExcludedPaths
   * @param    filePath   Include path object
   * @return   List of all exclude paths for the include path
   */
  setExcludedPaths(filePath?: FilePathParameters): UntypedFormArray {
    const excludePaths = new UntypedFormArray([]);
    if (!filePath?.excludedFilePaths || filePath?.excludedFilePaths.length === 0) {
      // As of now UX is different for VMROBO and BAAS
      // In case of On Prem, we display add exclusion button while for BAAS
      // we display inline add/minus icon
      // TODO: Create a ticket for a parity.
      if (this.isDmsScope) {
        excludePaths.push(new UntypedFormControl('', this.excludePathValidators));
      }
    } else {
      filePath.excludedFilePaths.forEach(excludePath => {
        excludePaths.push(new UntypedFormControl(
          convertToHostSpecificPath(excludePath, this.node.isWindows),
          this.excludePathValidators));
      });
    }
    return excludePaths;
  }

  /**
   * Triggers on adding new mount point for skipping nested mount point types.
   *
   * @param   event   MatChipInputEvent.
   */
  addMountPoint(event: MatChipInputEvent) {
    const mountPoint = (event.value || '').trim();

    if (mountPoint) {
      this.updateNestedMountPointsSelection(mountPoint, false);
    }

    // Reset input value after adding
    this.mountPointInput.nativeElement.value = '';
  }

  /**
   * Triggers on selecting the mount point from the dropdown, add it to the list.
   *
   * @param   event   MatAutocompleteSelectedEvent.
   */
  selectMountPoint(event: MatAutocompleteSelectedEvent) {
    this.updateNestedMountPointsSelection(event.option.value, false);

    // Reset input value after adding
    this.mountPointInput.nativeElement.value = '';
  }

  /**
   * Triggers on removing mount point from the nested mount point types.
   *
   * @param  mountPoint   The mount point type to be removed.
   */
  removeMountPoint(mountPoint: string) {
    this.updateNestedMountPointsSelection(mountPoint);
  }
}
