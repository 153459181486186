<div class="app-pillar-title margin-bottom">{{ ('appPillars.description.app.' + app.app) | translate}}</div>
<ul class="c-ul">
  <li *ngFor="let item of app.descriptions">{{ item | translate }}</li>
</ul>
<button
  class="margin-top-lg"
  color="primary"
  mat-flat-button
  cogDataId="upgrade-app-button"
  (click)="visit()">
  {{ 'upgradeNow' | translate }}
</button>
