import { Component, inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CommonRecoverFileAndFolderInfo, ObjectMailboxParam, ObjectOneDriveParam, RecoveryServiceApi } from '@cohesity/api/v2';
import { AjaxHandlerService, ClearSubscriptions } from '@cohesity/utils';
import { BehaviorSubject } from 'rxjs';
import { filter, finalize, map } from 'rxjs/operators';

import { RecoveredObject, RecoveryItemDetailsDialogInput } from '../../models';

@Component({
  templateUrl: './recovery-item-details-dialog.component.html',
  styleUrls: ['./recovery-item-details-dialog.component.scss'],
})
export class RecoveryItemDetailsDialogComponent extends ClearSubscriptions implements OnInit {
  /**
   * The inputs passed to the dialog.
   */
  readonly data: RecoveryItemDetailsDialogInput = inject(MAT_DIALOG_DATA);

  /**
   * Columns of the table.
   */
  readonly columns = ['name', 'size'];

  /**
   * Indicates whether the data is loading.
   */
  readonly loading$ = new BehaviorSubject<boolean>(true);

  /**
   * The list of objects.
   */
  readonly objects$ = new BehaviorSubject<RecoveredObject[]>([]);

  constructor(
    private recoveryApi: RecoveryServiceApi,
    private ajaxHandler: AjaxHandlerService) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.recoveryApi
        .GetRecoveryById(
          { id: this.data.recoveryId, regionId: this.data.regionId, accessClusterId: this.data.clusterId })
        .pipe(
          finalize(() => this.loading$.next(false)),
          filter(resp => resp?.snapshotEnvironment === 'kO365'),
          map(resp => {
            const o365Params = resp.office365Params;

            if (['RecoverMailbox', 'ConvertToPst'].includes(o365Params.recoveryAction) ) {
              return this.mapMailboxRecoveryObjects(o365Params.recoverMailboxParams.objects);
            } else if ('RecoverOneDrive' === o365Params.recoveryAction) {
              return this.mapOneDriveRecoveryObjects(o365Params.recoverOneDriveParams.objects);
            } else if ('DownloadFilesAndFolders' === o365Params.recoveryAction) {
              return this.mapDownloadedObjects(o365Params.downloadFileAndFolderParams.filesAndFolders);
            } else {
              throw new Error(`Unsupported recovery action ${o365Params.recoveryAction}`);
            }
          })
        )
        .subscribe(
          objects => this.objects$.next(objects),
          err => this.ajaxHandler.errorMessage(err)
        )
    );
  }

  /**
   * Maps the list of Mailbox objects to the objects consumed by the dialog.
   *
   * @param objects The list of Mailbox objects.
   * @returns The list of dialog consumable objects.
   */
  private mapMailboxRecoveryObjects(objects: ObjectMailboxParam[]): RecoveredObject[] {
    const result: RecoveredObject[] = [];

    for (const obj of objects) {
      for (const folder of obj.mailboxParams.recoverFolders) {
        if (folder.recoverEntireFolder) {
          result.push({ id: folder.key.toString(), name: folder.key.toString(), type: 'folder', size: 0 });
        } else {
          result.push(
            ...folder.itemIds.map(
              id => ({ id: id.toString(), name: id.toString(), type: 'folder', size: 0 } as RecoveredObject)
            )
          );
        }
      }
    }

    return result;
  }

  /**
   * Maps the list of OneDrive objects to the objects consumed by the dialog.
   *
   * @param objects The list of OneDrive objects.
   * @returns The list of dialog consumable objects.
   */
  private mapOneDriveRecoveryObjects(objects: ObjectOneDriveParam[]): RecoveredObject[] {
    const result: RecoveredObject[] = [];

    for (const obj of objects) {
      for (const param of obj.oneDriveParams) {
        result.push(
          ...param.recoverItems.map(
            item => ({ id: item.id, name: item.id, size: 0, type: item.isFile ? 'file' : 'folder' } as RecoveredObject)
          )
        );
      }
    }

    return result;
  }

  /**
   * Maps the list of files & folders within the Download task consumed by the
   * dialog.
   *
   * @param objects Specifies the list of files & folders part of the download
   *                task
   * @returns Array of objects consumable by the Dialog.
   */
  private mapDownloadedObjects(objects: CommonRecoverFileAndFolderInfo[]): RecoveredObject[] {
    const result: RecoveredObject[] = [];
    objects.forEach(obj => {
      result.push({
        id: obj.absolutePath,
        name: obj.absolutePath,
        type: obj.isDirectory ? 'file' : 'folder',
        size: 0
      });
    });

    return result;
  }
}
