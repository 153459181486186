import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { SeriesSunburstOptions } from 'highcharts';

import { HighchartsComponent } from '../core';

/**
 * @description
 * Sunburst chart aka radial Treemap is used to visualize a hierarchical dataset.
 * It shows hierarchy through a series of concentric rings, where each ring corresponds to a level in the hierarchy.
 * Each ring is segmented proportionally to represent its constituent details.
 *
 * @example
 *    <cog-sunburst-chart
 *      [title]="'myAwesomeChart' | translate"
 *      [seriesData]="seriesData"
 *      [customChartOptions]="chartOptions"
 *      colorSetClass="custom-theme">
 *    </cog-sunburst-chart>
 *
 * Sample series data is as follows; check https://api.highcharts.com/highcharts/series.sunburst.data for more info.
 * seriesData = seriesData: SeriesSunburstOptions[] = [
 *   {
 *     type: 'sunburst',
 *     data: [
 *       // root node
 *       { id: '0',  parent: '',  name: 'RootNode' },
 *
 *       // level 1
 *       { id: '1',  parent: '0', name: 'L1 C1' }, // 'RootNode' children
 *       { id: '2',  parent: '0', name: 'L1 C2' }, // 'RootNode' children
 *
 *       // level 2
 *       { id: '3',  parent: '1', name: 'L2 C1' }, // 'L1 C1' children
 *       { id: '4',  parent: '1', name: 'L2 C2' }, // 'L1 C1' children
 *       { id: '5',  parent: '1', name: 'L2 C3' }, // 'L1 C1' children
 *
 *       { id: '6',  parent: '2', name: 'L2 C4' }, // 'L1 C2' children
 *       { id: '7',  parent: '2', name: 'L2 C5' }, // 'L1 C2' children
 *
 *       // level 3
 *       { id: '8',  parent: '3', name: 'L3 C1', value: 8765 }, // 'L2 C1' children
 *       { id: '9',  parent: '4', name: 'L3 C2', value: 8652 }, // 'L2 C2' children
 *       { id: '10', parent: '5', name: 'L3 C3', value: 5262 }, // 'L2 C3' children
 *       { id: '11', parent: '6', name: 'L3 C4', value: 1263 }, // 'L2 C4' children
 *       { id: '12', parent: '7', name: 'L3 C5', value: 7563 }, // 'L2 C5' children
 *     ]
 *   },
 *  ];
 */
@Component({
  selector: 'cog-sunburst-chart',
  templateUrl: './sunburst-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SunburstChartComponent extends HighchartsComponent<SeriesSunburstOptions> {
  /**
   * Color scheme for chart If not, uses default colors
   */
  @Input() colorSetClass: string;

  constructor() {
    super({
      chart: {
        height: '100%',
        styledMode: true,
        backgroundColor: 'transparent',
      },
      legend: { enabled: false },
      credits: { enabled: false },
      title: null,
      xAxis: { visible: false },
      plotOptions: {
        sunburst: {
          allowTraversingTree: true,
        },
      },
      series: [],
    } as Highcharts.Options);
  }
}
