// Module & Controller:  Job Create/Edit: Parent Controller
import { Workflow } from 'src/app/app-module.config';
import { nasSources } from 'src/app/shared/constants';

(function _iife(angular, undefined) {
  'use strict';

  var moduleName = 'C.jobModify';
  var moduleDependencies = [
    'smoothScroll',
    'C.constants',
    'C.pubJobService',
    'C.policyService',
    'C.selectPolicy',
    'C.selectView',
    'C.selectViewBox',
    'C.jobObjects',
    'C.formShutters',
    'C.quiesceConflictModal',
    'C.pubSourceTree',
    'C.selectParentSource',
    'C.viewListing',
    'C.clusterPubSshKey',
    'C.clipboardCta',
    'C.focus',
    'C.pubSourceService',
    'C.dedupSourceExclusion',
    'C.fileStubbingSettings',
    'C.oracleNodeSettings',
  ];
  var DEFAULT_NUM_STREAMS = 3;

  angular.module(moduleName, moduleDependencies)
    .config(stateConfigFn)
    .controller('JobModController', jobModControllerFn);

  function stateConfigFn($stateProvider, ENV_GROUPS) {
    $stateProvider
      .state('job-modify', {
        // TODO: nest this URL properly after deleting existing job create/edit
        url: '^/protection/jobedit/{id}',
        params: {
          id: { type: 'string', value: '' },

          // If v2 protection groups are enabled, the UID is required to handle
          // the redirect to the new state correctly.
          uid: { type: 'string', value: ''},

          // Optional force param to prevent redirect to v2 api.
          force: undefined,

          // Optional array of environment kValues for for a new Job. If not
          // provided, hypervisor kValues will be default.
          environments: ENV_GROUPS.hypervisor,

          // if protectView is provided (view obj) the provided View will be
          // preselected and View selection step will be removed from the flow
          protectView: undefined,

          // Optional ID of parent source to be selected by default.
          parentSourceId: undefined,

          // Optional environment of parent source to be selected by default.
          // This should be leveraged when protectSources[] is known to belong
          // to one ENV_GROUPS.cohesityGroups[]. This mechamism allows easy
          // protection without having to lookup the artifical parent wrapper.
          parentSourceEnvironment: undefined,

          // {array}   protectionSources to be pre added to the Job for
          // protection.
          protectSources: undefined,

          // Optional jobType for cloud environments.
          // eg: 'kAWS' sourceType can have 'kAWS', 'kAWSNative',
          // 'kAWSSnapshotManager', 'kRDSSnapshotManager' as jobTypes
          cloudJobType: {type: 'string', value: '' },

          // Optional parameter for opening quick protect dialog for new NG
          // wrapper.
          quickProtect: {type: 'bool', value: false},
        },
        title: 'Protection Job',
        canAccess: ctx => {
          return ctx.PROTECTION_MODIFY && ctx.canAccessSomeEnv(ctx.stateParams.environments || []);
        },
        parentState: 'jobs',
        controller: 'JobModController as $ctrl',
        templateUrl: 'app/protection/jobs/modify2/modify.html',

        // help value will be determined dynamically based on Job environment.
        help: '',
      })
      .state('file-stubbing-modify', {
        url: '^/data-migration/modify/{id}',
        params: {
          id: { type: 'string', value: '' },

          // Optional array of environment kValues for for a new Job. If not
          // provided, hypervisor kValues will be default.
          environments: ENV_GROUPS.nas,

          // if protectView is provided (view obj) the provided View will be
          // preselected and View selection step will be removed from the flow
          protectView: undefined,

          // Optional ID of parent source to be selected by default.
          parentSourceId: undefined,

          // Optional environment of parent source to be selected by default.
          // This should be leveraged when protectSources[] is known to belong
          // to one ENV_GROUPS.cohesityGroups[]. This mechamism allows easy
          // protection without having to lookup the artifical parent wrapper.
          parentSourceEnvironment: undefined,

          // {array}   protectionSources to be pre added to the Job for
          // protection.
          protectSources: undefined,

          // modify file stubbing job
          fileStubbing: true,
        },
        title: 'Data Tiering Job',
        canAccess: (ctx) => {
          return ctx.PROTECTION_MODIFY && ctx.canAccessSomeEnvItems(nasSources, Workflow.dataMigration);
        },
        parentState: 'dataMigration',
        controller: 'JobModController as $ctrl',
        templateUrl: 'app/protection/jobs/modify2/modify.html',
        help: 'platform_datamigration',
     });
  }

  function jobModControllerFn(_, $rootScope, $q, $state, $timeout, moment,
    JobFlowService, PubJobService, PubSourceService, PolicyService, cMessage,
    SlideModalService, RemoteClusterService,  PubJobServiceFormatter,
    SourceService, evalAJAX, cUtils, cFocus, ENV_GROUPS, NAS_FILESYSTEM_MAP,
    StateManagementService, ViewBoxService, PartitionService, FEATURE_FLAGS) {

    var $ctrl = this;
    $ctrl.openDownloadAgentsModal = SourceService.downloadAgentsModal;
    $ctrl.ENV_GROUPS = ENV_GROUPS;

    /**
     * Used to cache visibility booleans. This mechanism allows for the
     * visibility of sections to be cached and then not retracted once displayed
     * to the user.
     *
     * @type   {object}
     */
    var isVisibleApi = {
      cache: {
        // Objects grouping/section.
        parentSource: false,
        objects: false,
        remoteAdapterScriptSettings: false,
        dataMigration: false,

        // Settings grouping/section.
        // Items below `settingsSection` are its children.
        settingsSection: false,
        policy: false,
        viewBox: false,
        snapshotLabel: false,
        settingsShutters: false,
      },
      getters: {
        // Objects grouping section.
        parentSource: _showParentSource,
        objects: _showJobObjects,
        remoteAdapterScriptSettings: _showScriptSettings,
        dataMigration: _shouldShowDataMigration,

        // Settings grouping/section.
        // Items below `settingsSection` exist within it.
        settingsSection: _showSettingsSection,
        policy: _showPolicySelection,
        viewBox: _showViewBoxSelection,
        snapshotLabel: _showSnapshotLabelSelection,
        settingsShutters: _showSettingsShutters,
      },
    };

    var scheduleKeys = PolicyService.getScheduleKeys();
    var scheduleKeysToScriptMapping =
      PubJobService.getScheduleKeysToScriptMapping();

    var remoteClusterHash;

    _.assign($ctrl, {
      // lifecycle hooks
      $onInit: $onInit,
      FEATURE_FLAGS: FEATURE_FLAGS,

      // properties
      viewBoxPromise: ViewBoxService.getOwnViewBoxes(),

      // controller methods
      captureNasCredentials: captureNasCredentials,
      cloudArchiveDirectToggled: cloudArchiveDirectToggled,
      getHeaderContext: getHeaderContext,
      isSubmitDisabled: isSubmitDisabled,
      isVbRxCompatible: isVbRxCompatible,
      isVisible: isVisible,
      jobTypeSelected: jobTypeSelected,
      onSelectedSourcesChange: onSelectedSourcesChange,
      snapshotLocationChanged: snapshotLocationChanged,
      selectObjects: selectObjects,
      sourceSelected: sourceSelected,
      submitJobForm: submitJobForm,
      updateJobView: updateJobView,
      updatePolicy: updatePolicy,
      updateViewBox: updateViewBox,
      goBack: goBack,
      goToSources: goToSources,
    });

    /**
     * Controller initialization function
     *
     * @method   $onInit
     */
    function $onInit() {
      var promises;

      $ctrl.isEditMode = !!$state.params.id;
      $ctrl.isNewMode = !$ctrl.isEditMode;
      $ctrl.jobTree = {};
      $ctrl.parentSourceEnvironment = $state.params.parentSourceEnvironment;
      $ctrl.isFileStubbingJob = !!$state.params.fileStubbing;
      $ctrl.cloudProtectFromSourcePage = ENV_GROUPS.cloudSources.includes(
        _.get($state, 'params.environments[0]'));

      promises = {
        policies: PolicyService.getPolicies(),
        remoteClusterHash: $rootScope.user.privs.CLUSTER_REMOTE_VIEW ?
          RemoteClusterService.getRemoteClusterHash() : $q.resolve(),
        job: _getJob(),
      };

      $q.all(promises).then(
        function allPromisesSuccess(responses) {

          remoteClusterHash = responses.remoteClusterHash;

          $ctrl.policies = responses.policies;
          $ctrl.job = responses.job;
          $ctrl.environments = _getEnvironments();
          $ctrl.job._fileStubbing = $ctrl.isFileStubbingJob;
          $ctrl.isRDS = ($ctrl.isEditMode &&
            $ctrl.job.environment === 'kRDSSnapshotManager') ||
            _.get($state.params, 'cloudJobType') === 'kRDSSnapshotManager';

          _setSnapshotLabel();
          _setMissingEntitiesSelection();

          if ($ctrl.isNewMode) {
            // For new jobs, auto focus the Job name field.
            cFocus('job-name-input');

            /*
             * kPhysicalFiles Jobs leverage kPhysical sources, but the Job
             * environment will always be kPhysicalFiles
             */
            if ($ctrl.environments.includes('kPhysicalFiles')) {
              $ctrl.job.environment = 'kPhysicalFiles';
            }

            /*
             * A SQL Job can have a kPhysical or kVMware parentSource, but its
             * environment will always be kSQL.
             */
            if ($ctrl.environments.includes('kSQL')) {
              $ctrl.job.environment = 'kSQL';
            }

            /**
             * An Oracle Job can have a kPhysical parentSource, but its
             * environment will always be kOracle.
             */
            if ($ctrl.environments.includes('kOracle')) {
              $ctrl.job.environment = 'kOracle';
            }

            /*
             * If there is only a single environment provided for the flow, it
             * can safely be assigned to the Job.
             */
            if ($ctrl.environments.length === 1) {
              $ctrl.job.environment = $ctrl.environments[0];
            }

            /**
             * Incase of O365, environment is [kO365] || [O365, kO365Outlook].
             * In both the cases the job environment should be kO365Outlook.
             */
            if ($ctrl.environments.includes('kO365')) {
              $ctrl.job.environment = 'kO365Outlook';
            }

            /**
             * Setting up a temporary environment as 'kNetapp' as it will help
             * stub nasParameters which is the enviroment key in any type of
             * nas job. Later on when source is selected, right environment will
             * be set on source selection.
             */
            if ($ctrl.environments.includes('kNetapp')) {
              $ctrl.job.environment = 'kNetapp';
            }

            /*
             * for new View Jobs, its not necessary to wait for any source
             * loading, and selectObjects() can safely be initiated.
             */
            if ($ctrl.job.environment === 'kView') {
              // If a View was provided for protection, use it.
              if ($state.params.protectView) {
                JobFlowService.addViewToJob(
                  $state.params.protectView, $ctrl.job);
              }
            }
            PubJobServiceFormatter.transformJob($ctrl.job);
          }

          if ($ctrl.isEditMode &&
            ENV_GROUPS.spanFS.includes($ctrl.job.environment)) {
            _getJobView().then(function getViewSuccess(viewSource) {
              $ctrl.job._viewSource = viewSource;
            });
          }

          // If the job is owned by a tenant and is opened in edit mode, the SP
          // admin shouldn't be able to access this state, so redirect to the
          // job details page.
          if ($ctrl.isEditMode && !$ctrl.job._isJobOwner) {
            $state.go($ctrl.job._isDataMigrationJob ?
              'file-stubbing-modify' :
              'job-details.job', { id: $state.params.id });
          }
          $state.current.help = _getHelpValue();
        },
        evalAJAX.errorMessage
      ).finally(function allPromisesFinally() {
        $ctrl.loaded = true;
      });
    }

    /**
     * return a new copy of default job config based on selected job
     * environments types
     *
     * @method   _getDefaultJobConfig
     * @return   {Object}   The default job configuration.
     */
    function _getDefaultJobConfig() {
      var jobConfig = {
        name: '',
        _isFileStubbing: undefined,
        environment: undefined,
        _envParams: {},
        viewBoxId: undefined,
        parentSourceId: $state.params.parentSourceId || undefined,
        sourceIds: [],
        dedupDisabledSourceIds: [],
        excludeSourceIds: [],
        vmTagIds: [],
        excludeVmTagIds: [],
        _selectedSources: [],
        policyId: undefined,
        priority: 'kMedium',
        alertingPolicy: [
          'kFailure',
        ],
        alertingConfig: {
          // list of emails who will receive the email alerts for the job.
          emailDeliveryTargets: [],
        },
        timezone: moment.tz.guess(),
        incrementalProtectionSlaTimeMins: 60,
        fullProtectionSlaTimeMins: 120,
        qosType: 'kBackupHDD',
        _sourceSpecialParametersMap: {},

      };

      // get environments from current state so that we can initialize job
      // correctly based on selected environments.
      var jobEnvironments = _getEnvironments();


      // add kNetapp environment specific default job config
      if (jobEnvironments.includes('kNetapp')) {
        angular.merge(jobConfig, {
          environmentParameters: {
            nasParameters: { nasProtocol: 'kNfs3' },
          }
        });
      }

      if (jobEnvironments.includes('kSQL')) {
        _.assign(jobConfig, {
          environmentParameters: {
            sqlParameters: {
              backupType: 'kSqlVSSFile',
              numStreams: DEFAULT_NUM_STREAMS,
            },
          },
        });
      }

      // Add kO365Outlook specific default job config.
      if (jobEnvironments.includes('kO365Outlook')) {
        _.assign(jobConfig, {
          environmentParameters: {
            office365Parameters: {
              outlookParameters: {
                filePathFilter: {
                  excludeFilters: [],
                },
                shouldBackupMailbox: true,
              },

              // Parameters specific to OneDrive.
              onedriveParameters: {
                // NOTE: OneDrive backups may also be enabled by default in
                // future.
                shouldBackupOnedrive: false,
              }
            }
          },

          // Disable custom folder exclusion by default.
          customFolderExclusionEnabled: false,
        });
      }

      return jobConfig;
    }

    /**
     * Provides a list of environment types compatible with the current Job
     * configuration.
     *
     * @method   _getEnvironments
     * @return   {array}   The appropriate environment kValues
     */
    function _getEnvironments() {
      // If editing a job, restrict to the Job's environment type. otherwise use
      // provided default allowable job environments to hypervisor if none
      // provided.
      var environments = ($ctrl.job && $ctrl.job.environment) ?
        [$ctrl.job.environment] :
        [].concat($state.params.environments);

      // Adding physical and vmware jobs while editing the job so that old jobs
      // which have physical and vmware sources in sql job can he handled
      // gracefully.
      if ($ctrl.job && $ctrl.job.environment === 'kSQL') {
        environments = ['kSQL', 'kPhysical', 'kVMware'];
      }

      // Environment is 'kHyperV' for both 'kHyperVVSS' and 'kHyperV' job
      if ($ctrl.job && $ctrl.job.environment === 'kHyperVVSS') {
        environments = ['kHyperV'];
      }

      // Environment is 'kAWS' for 'kAWSNative', 'kAWSSnapshotManager',
      // 'kRDSSnapshotManager' and 'kAWS' jobs
      if ($ctrl.job &&
        (['kAWSNative', 'kAWSSnapshotManager', 'kRDSSnapshotManager']
          .includes($ctrl.job.environment))) {

        environments = ['kAWS'];
      }

      // Environment is 'kAzure' for both 'kAzureNative' and 'kAzure' job
       if ($ctrl.job &&
        (['kAzureNative', 'kAzureSnapshotManager']
          .includes($ctrl.job.environment))) {
        environments = ['kAzure'];
      }

      // Environment is 'kGCP' for both 'kGCPNative' and 'kGCP' job
      if ($ctrl.job && $ctrl.job.environment === 'kGCPNative') {
        environments = ['kGCP'];
      }

      // Source environment should also include 'kO365' if the job type is
      // 'kO365Outlook'.
      if ($ctrl.job && $ctrl.job.environment === 'kO365Outlook') {
        environments = ['kO365', 'kO365Outlook'];
      }

      // Environment is 'kGenericNas' for nas file stubbing job type
      if ($ctrl.isFileStubbingJob) {
        environments = ["kGenericNas"];
      }

      return cUtils.onlyStrings(environments);
    }

    /**
     * Constructs a context object to be used with ui.json key 'job.headerText'
     *
     * @method   getHeaderContext
     * @return   {object}   The header ui.json context object
     */
    function getHeaderContext() {
      var mode = $ctrl.isNewMode ? 'new' : 'edit';
      var flowType;

      switch (true) {
        case $ctrl.isRDS:
          flowType = 'rds';
          break;

        case $ctrl.isFileStubbingJob:
          flowType = 'dataMigration';
          break;

        case $ctrl.job.environment === 'kSQL':
          flowType = 'sql';
          break;

        case $ctrl.job.environment === 'kOracle':
          flowType = 'oracle';
          break;

        case $ctrl.job.environment === 'kAD':
          flowType = 'ad';
          break;

        // 'vm' case comes after sql, as sql can theoretically include a
        // hypervisor source type.
        case ENV_GROUPS.hypervisor.includes($ctrl.job.environment) ||
          _.difference(
            cUtils.onlyStrings($ctrl.environments),
            cUtils.onlyStrings(ENV_GROUPS.hypervisor)
          ).length === 0:
          flowType = 'vm';
          break;

        // 'physical' case comes after sql, as sql can theoretically include a
        // physical source type.
        case ENV_GROUPS.physical.includes($ctrl.job.environment):
          flowType = $ctrl.job.environment === 'kPhysicalFiles' ?
            'physicalFiles' : 'physical';
          break;

        case $ctrl.job.environment === 'kPuppeteer':
          flowType = 'puppeteer';
          break;

        case $ctrl.job.environment === 'kView':
          flowType = 'view';
          break;

        case $ctrl.job.environment === 'kPure':
          flowType = 'pure';
          break;

        case ENV_GROUPS.nas.includes($ctrl.job.environment) ||
          _.difference(
            cUtils.onlyStrings($ctrl.environments),
            cUtils.onlyStrings(ENV_GROUPS.nas)
          ).length === 0:
          flowType = 'nas';
          break;

        case $ctrl.job.environment === 'kO365':
        case $ctrl.job.environment === 'kO365Outlook':
          flowType = 'office365';
          break;

        case $ctrl.job.environment === 'kKubernetes':
          flowType = 'kubernetes';
          break;

        case $ctrl.job.environment === 'kNimble':
          flowType = 'nimble';
          break;

        default:
          flowType = 'generic';
          break;
      }

      return {
        jobHeaderTypeKey: 'job.headerType.' + flowType,
        newOrEdit: mode,
      };
    }

    /**
     * Gets the job from the API or if not an edit, creates the job object based
     * on default job object.
     *
     * @method   _getJob
     * @return   {object}   promise to provide the job object
     */
    function _getJob() {
      var params = {onlyReturnDataMigrationJobs : $ctrl.isFileStubbingJob};
      var config;
      if ($ctrl.isNewMode) {
        // make a copy of the default config so it will remain unchanged and we
        // can reset parts of it as needed
        config = _getDefaultJobConfig();

        // Check if the protected source is AAG and if so, show a modal asking
        // the user if he or she would like to protect other AAG's. This check
        // will only be made when a single source has been selected. If the user
        // wishes to protect the additional AAG's their ID's will be added to
        // to the sourceIds list. After the tree has loaded, the list will be
        // validated to remove any sources that are already being protected.
        // The removal is done silently, the same way that it is when selected
        // an AAG host in the modal.
        if (_.get($state, 'params.protectSources.length') === 1 &&
          _.get($state, 'params.protectSources[0]._aags.length')) {
          return PubSourceService.showAagNodeSelectDialog(
            $state.params.protectSources[0]).then(
              function accept(selection) {
                var node = $state.params.protectSources[0];
                var aagHostIds = [];
                if (selection === 'aagAll') {
                  PubSourceService.getUniqueAagHosts(node).forEach(
                    function eachHost(host) {
                      aagHostIds.push(host.id);
                    });
                  config.sourceIds = aagHostIds;
                }
                return config;
              },
              function reject() {
                return config;
              }
            );
        } else {
          return $q.resolve(config);
        }
      }

      return PubJobService.getJob($state.params.id, undefined, params);
    }

    /**
     * Requests the View for an existing View or Remote Adapter Job.
     *
     * @method   _getJobView
     * @return   {object}   Promise to resolve the request for source object
     */
    function _getJobView() {
      return PubSourceService.getObject($ctrl.job.sourceIds[0])
        .catch(evalAJAX.errorMessage);
    }

    /**
     * Indicates if a particular section should be visible to the user or not.
     *
     * @method   isVisible
     * @param    {string}   sectionKey   The section key to lookup on isVisible.
     */
    function isVisible(sectionKey) {
      return isVisibleApi.cache[sectionKey] =
        // Nothing is visible until the controller is boostrapped.
        $ctrl.loaded &&

        /**
         * If the requested section is already visible, it will always be
         * visible, otherwise lookup the current visibilty boolean.
         */
        !!(isVisibleApi.cache[sectionKey] ||
            isVisibleApi.getters[sectionKey]());
    }

    /**
     * Indicates if the selectParentSource component should be visible.
     *
     * @method   _showParentSource
     * @return   {boolean}   True if selectParentSource should be visible, false
     *                       otherwise.
     */
    function _showParentSource() {
      return !ENV_GROUPS.spanFS.includes($ctrl.job.environment);
    }

    /**
     * Indicates if the jobObjects component should be visible.
     *
     * @method   _showJobObjects
     * @return   {boolean}   True if jobObjects should be visible, false
     *                       otherwise.
     */
    function _showJobObjects() {
      return !!$ctrl.job.parentSourceId &&
        // For cloud sources, object selection should appear once job type
        // has been selected
        (!$ctrl.cloudProtectFromSourcePage || $ctrl.jobType) &&
          !ENV_GROUPS.spanFS.includes($ctrl.job.environment);
    }

    /**
     * Indicates if the Policy dropdown/selection should be visible.
     *
     * @method   _showPolicySelection
     * @return   {boolean}   True if policy dropdown should be visible, false
     *                       otherwise.
     */
    function _showPolicySelection() {
      switch ($ctrl.job.environment) {
        case 'kPuppeteer':
          // TODO: why doesn't this form validation check
          // return $ctrl.jobForm.remoteHostAddress.$valid &&
          //   $ctrl.jobForm.remoteHostUsername.$valid;
          return !!$ctrl.job.remoteScript &&
            $ctrl.job.remoteScript.username &&
            $ctrl.job.remoteScript.remoteHost &&
            $ctrl.job.remoteScript.remoteHost.address;

        case 'kView':
          /**
           * `viewName` property is used for new jobs, as the backend hasn't
           * necessarily created a "source" id for the View. For edit scenarios
           * the View will have a sourceId and it will be the only element in
           * `$ctrl.job.sourceIds[]`
           */
          return !!$ctrl.job.viewName || !!$ctrl.job.sourceIds.length;

        default:
          /**
           * If any objects/sources have been added to the Job, the user is
           * ready to select a Policy. This includes VM tags (currently a
           * kVMware exclusive feature).
           */
          var value = !$ctrl.isFileStubbingJob &&
            (!!$ctrl.job.sourceIds.length || !!$ctrl.job.vmTagIds.length);

          if (value && $ctrl.isNewMode) {

            // Without this the "auto-open-if-empty" on <select-policy> does not
            // work. With modal close, the focus gets changed and the policy
            // select never opens. So prevent showing the select policy dropdown
            // initially until modal related changes have happened.
            $timeout(function setShowPolicyDropdown() {
              isVisibleApi.cache.policy = true;
            });

            return false;
          }

          return value;
      }
    }

    /**
     * Indicates if the snapshot label setting should be visible.
     *
     * @method   _showPolicySelection
     * @return   {boolean}   True if snapshot label should be visible, false
     *                       otherwise.
     */
    function _showSnapshotLabelSelection() {
      if (!!$ctrl.job.policyId && !!$ctrl.job.viewBoxId) {
        $ctrl.enableSnapshotLabel = true;
        return true;
      }
    }

    /**
     * Sets the value of the snapshot label toggle when form is initialized
     *
     * @method   _setSnapshotLabel
     */
    function _setSnapshotLabel() {
      if ($ctrl.job.environment !== 'kNetapp') { return; }
      if ($ctrl.isNewMode) {
        $ctrl.enableSnapshotLabel = true;
      } else {
        $ctrl.enableSnapshotLabel = !!$ctrl.job._envParams.snapshotLabel;
      }
    }

    /**
     * Sets the value of the Missing Entity selection when form is initialized
     *
     * @method   _setMissingEntitiesSelection
     */
    function _setMissingEntitiesSelection() {
      if ($ctrl.job.missingEntities.length) {
        var selectedMissingIds = _.map(
          $ctrl.job.missingEntities,
          function eachEntity(entity) {
            entity.isSelected = true;
            return entity;
          }
        );
        $ctrl.job.missingEntities = selectedMissingIds;
      }
    }

    /**
     * Indicates if the View Box uiSelect/dropdown should be visible.
     *
     * @method   _showViewBoxSelection
     * @return   {boolean}   True if viewBox dropdown should be visible, false
     *                       otherwise.
     */
    function _showViewBoxSelection() {
      // AWS RDS doesn't use any local snapshots so we dont need to shpw
      // this field.
      // For CSM on the other hand, the user may still choose to use local
      // snapshots instead of remote. So ENV_GROUP.cloudJobsWithoutLocalSnapshot
      // is not used here.
      if ($ctrl.isRDS) {
        return false;
      }

      // For 'Nas Direct Archive', special hidden ViewBox is used.
      // It is not exposed to the end user.
      if ($ctrl.job.isDirectArchiveEnabled && !$ctrl.isFileStubbingJob) {
        return false;
      }

      return ($ctrl.isFileStubbingJob && !!$ctrl.job._selectedSources &&
        $ctrl.job._selectedSources.length > 0) ||
          (!!$ctrl.job.policyId &&
            !ENV_GROUPS.spanFS.includes($ctrl.job.environment));
    }


    /**
     * Indicates if the Remote Adapter script settings should be visible.
     *
     * @method   _showScriptSettings
     * @return   {boolean}   True if script settings should be visible, false
     *                       otherwise.
     */
    function _showScriptSettings() {
      return $ctrl.job.environment === 'kPuppeteer' &&
        !!$ctrl.job.policyId;
    }

    /**
     * Indicates if data migration sections should be visible.
     *
     * @method   _shouldShowDataMigration
     * @return   {boolean}  True if job type is data migration and viewBox is
     *                      selected.
    */
    function _shouldShowDataMigration() {
      return $ctrl.isFileStubbingJob && $ctrl.viewBox;
    }

    /**
     * Indicates if the Job Settings section should be visible.
     *
     * @method   _showSettingsShutters
     * @return   {boolean}   True if job settings should be visible, false
     *                       otherwise.
     */
    function _showSettingsShutters() {
      if ($ctrl.isFileStubbingJob) {
        return !!$ctrl.job.dataMigrationPolicy;
      }

      if ($ctrl.areSnapshotsManagedRemotely) {
        return !!$ctrl.job.policyId;
      }

      if ($ctrl.job.isDirectArchiveEnabled) {
        return !!$ctrl.job.policyId;
      }

      switch ($ctrl.job.environment) {
        case 'kPuppeteer':
          return !!($ctrl.job.viewName || $ctrl.isEditMode);

        case 'kView':
        case 'kRDSSnapshotManager':
          return !!$ctrl.job.policyId;

        default:
          // In some cases(eg: cloud), we make use of default viewBox id which
          // gets set at initialization. But settings shutters should not show
          // up at initialization
          if (ENV_GROUPS.cloudSources
            .includes(_.get($ctrl, 'source._environment'))) {

            return !!$ctrl.job.policyId && !!$ctrl.job.viewBoxId;
          } else {
            return !!$ctrl.job.viewBoxId;
          }
      }
    }

    /**
     * Indicates if the "Settings" section should be visible.
     *
     * @method   _showSettingsSection
     * @return   {boolean}   True if settings section should be visible, false
     *                       otherwise.
     */
    function _showSettingsSection() {
      // If any of the contained sub-sections are visible, show the section.
      return $ctrl.isVisible('policy') ||
        $ctrl.isVisible('viewBox') ||
        $ctrl.isVisible('settingsShutters') ||
        $ctrl.isVisible('remoteAdapterScriptSettings');
    }

    /**
     * Apply a selected Policy to the Job.
     *
     * @method   updatePolicy
     */
    function updatePolicy() {
      $ctrl.job.policyId = $ctrl.policy.id;

      // If remote targets are changed on editing policy
      if ($rootScope.user.privs.CLUSTER_REMOTE_VIEW) {
        RemoteClusterService.getRemoteClusterHash().then((hash) => {
          remoteClusterHash = hash;
        });
      }

      // For Remote Adapater Jobs, set the scriptKeys necessary for the Job
      // based on the selected policy.
      if ($ctrl.job.environment === 'kPuppeteer') {
        $ctrl.scriptKeys = scheduleKeys.reduce(
          function loopScriptKeys(scriptKeys, scheduleKey) {
            if ($ctrl.policy[scheduleKey]) {
              scriptKeys.push(scheduleKeysToScriptMapping[scheduleKey]);
            }
            return scriptKeys;
          },
          []
        );
      }
    }

    /**
     * Updates the Job's viewBoxId based on selectViewBox ngChange.
     *
     * @method   updateViewBox
     */
    function updateViewBox() {
      $ctrl.job._viewBoxName = $ctrl.viewBox.name;

      // for new kView Jobs, the Job should be submitted without a viewBoxId.
      $ctrl.job.viewBoxId =
        ($ctrl.isNewMode && $ctrl.job.environment === 'kView') ?
          undefined : $ctrl.viewBox.id;
    }

    /**
     * Updates the View associated with the kView Job.
     *
     * @method   updateJobView
     */
    function updateJobView() {
      /**
       * Per API specification, when creating a enew View Job parentSourceId,
       * sourceIds, and excludeSourceIds should not be provided to the API.
       */
      $ctrl.job.parentSourceId =
        $ctrl.job.sourceIds =
        $ctrl.job.excludeSourceIds = undefined;

      $ctrl.job.viewName =
        $ctrl.view ? $ctrl.view.name : undefined;
    }

    /**
     * Determine the next course of action on selection of a source based
     * on the type of selected source
     *
     * @method   sourceSelected
     * @param    {object}   source   The source
     */
    function sourceSelected(source) {
      var env;
      var isRDS = $ctrl.isRDS;

      $ctrl.needsJobType =
        $ctrl.isCSMEnabled =
          $ctrl.jobType = undefined;

      if (ENV_GROUPS.cloudSourcesWithNativeSupport
        .includes(source._environment)) {
          switch (source._environment) {
            case 'kAWS':
              // Job Type dropdown is not needed if user has specifically
              // selected RDS
              $ctrl.needsJobType = !isRDS;

              if (isRDS) {
                $ctrl.jobType = $ctrl.job.environment = 'kRDSSnapshotManager';

              // User has come to protection form Sources Page, or this is edit
              // mode.
              // In this case, all supported aws options need to be shown
              } else if ($ctrl.cloudProtectFromSourcePage ||
                $ctrl.isEditMode) {
                $ctrl.jobTypes = _mapCloudJobTypes(
                  _.without(ENV_GROUPS.awsTypes, 'kAWSSnapshotManager'));

              // User has come to protection by clicking on 'protect virtual
              // server'. In this case, RDS should not be shown.
              } else {
                $ctrl.jobTypes = _mapCloudJobTypes(
                  _.difference(ENV_GROUPS.awsTypes,
                    ENV_GROUPS.cloudJobsWithoutLocalSnapshot));
              }

              break;

            case 'kAzure':
              $ctrl.needsJobType = FEATURE_FLAGS.azureNativeSnapshots;
              $ctrl.jobTypes = _mapCloudJobTypes(
                _.without(ENV_GROUPS.azureTypes, 'kAzureSnapshotManager')
              );

              break;

            case 'kGCP':
              $ctrl.job.environment = 'kGCPNative';
              break;
          }

          if ($ctrl.isEditMode || !$ctrl.needsJobType) {
            // Source tree will change env back from kAWSNative to kAWS.
            // So cache it and reapply it after source tree is loaded
            env = $ctrl.job.environment;
            _applySourceAndLoadTree(source);
            $ctrl.jobType = env;
            $ctrl.job.environment = env;

            if ($ctrl.jobType === 'kAWSSnapshotManager') {
              $ctrl.jobType = 'kAWSNative';
              $ctrl.areSnapshotsManagedRemotely = true;
              $ctrl.jobTypeSelected();
            }
            if ($ctrl.jobType === 'kAzureSnapshotManager') {
              $ctrl.jobType = 'kAzureNative';
              $ctrl.areSnapshotsManagedRemotely = true;
              $ctrl.jobTypeSelected();
            }
          }
        } else {
          $ctrl.needsJobType = false;
          _applySourceAndLoadTree(source);
        }

      $ctrl.areSnapshotsManagedRemotely = _areSnapshotsManagedRemotely();
      _setDefaultViewBox(isRDS);
    }

    /**
     * For cloud sources with support for native snapshots, create a ui-select
     * friendly dropdown map.
     *
     * @method   _mapCloudJobTypes
     * @param    {Object[]}  jobTypes   The job types to map.
     * @return   {Object}    The mapped job types.
     */
    function _mapCloudJobTypes(jobTypes) {
      return jobTypes.map(function mapType(type) {
        return {
          // if native snapshot
          key: ENV_GROUPS.nativeSnapshotTypes.includes(type) ?
            'nativeSnapshot' :
            // standard cloud or RDS
            (ENV_GROUPS.cloudSources.includes(type) ?
              'cohesityProtectionService' : 'amazonRDS'),
          value: type,
        };
      });
    }

    /**
     * On selection of a job type, load the source tree and update the job's
     * environment
     *
     * @method   jobTypeSelected
     */
    function jobTypeSelected() {
      _applySourceAndLoadTree($ctrl.source);

      $ctrl.job.environment = $ctrl.jobType;
      $ctrl.isCSMEnabled = _isCSMEnabled();

      // reset the visibility of viewbox selection
      isVisibleApi.cache['viewBox'] = false;

      $ctrl.isRDS = ($ctrl.job.environment === 'kRDSSnapshotManager');

      if (FEATURE_FLAGS.awsSnapshotManager) {
        if ($ctrl.job.environment === 'kAWSNative') {
          $ctrl.areSnapshotsManagedRemotely = true;
          snapshotLocationChanged('aws');
        } else {
          $ctrl.areSnapshotsManagedRemotely = _areSnapshotsManagedRemotely();
        }
      }

      if (FEATURE_FLAGS.azureSnapshotManager) {
        if ($ctrl.job.environment === 'kAzureNative') {
          $ctrl.areSnapshotsManagedRemotely = true;
          snapshotLocationChanged('azure');
        } else {
          $ctrl.areSnapshotsManagedRemotely = _areSnapshotsManagedRemotely();
        }
      }
    }

    /**
     * check whether the snapshots created by the job are managed remotely or
     * on premise.
     *
     * @method  _areSnapshotsManagedRemotely
     * @return  {Boolean}  True if snapshots are managed remotely
     */
    function _areSnapshotsManagedRemotely() {
      return ENV_GROUPS
        .remoteSnapshotManagementSupported.includes($ctrl.job.environment);
    }

    /**
     * Apply a selected Source to the Job and load the source tree
     *
     * @method   _applySourceAndLoadTree
     * @param    {object}   source   The source
     */
    function _applySourceAndLoadTree(source) {
      /*
       * If selectParentSource clears the model, update accordingly. This will
       * happen if the user selects the "addNew" option and cancels out of the
       * modal
       */
      if (!source) {
        return $ctrl.job.parentSourceId =
          $ctrl.job._parentSource = undefined;
      }

      // These Jobs environments should not have their environment changed
      // to match the selected parentSource.
      if ($ctrl.isNewMode &&
        !['kPhysicalFiles', 'kSQL', 'kO365Outlook'].includes(
          $ctrl.job.environment)) {
        $ctrl.job.environment = _getNewJobEnvironment(source);
      }

      $ctrl.job._supportsAutoProtectExclusion =
        PubJobServiceFormatter.isAutoProtectExclusionSupported($ctrl.job);

      $ctrl.job.parentSourceId = source.protectionSource.id;
      $ctrl.job._parentSource = source;

      // Clear the Job's selected sources when the parent source changes, but
      // not during job edit as user isn't allowed to change an existing Job's
      // parent source but this function will fire on initial flow load. Also,
      // don't clear sourceIds if abbreviated flow params were provided.
      $ctrl.job.sourceIds =
        ($ctrl.isEditMode ||
        $state.params.parentSourceId ||
        $state.params.parentSourceEnvironment) ?
          $ctrl.job.sourceIds : [];

      _loadTree().finally(
        function loadTreeFinally() {
          if ($ctrl.isNewMode) {
            if (!$state.params.protectSources ||
              !$state.params.protectSources.length) {
              $ctrl.selectObjects();
            } else {
              JobFlowService.addParamSourcesToJob(
                $state.params.protectSources, $ctrl.job, $ctrl.jobTree);
              // Under no circumstances is it desirable to do this more than
              // once per Job flow. Clear the param to prevent such a scenario.
              $state.params.protectSources = undefined;
            }
          }

          // The first time the tree is loaded when a new job is being created
          // Check the list of protected source ids and remove any that are
          // already protected. Normally this shouldn't happen because a source
          // must already be unprotected to initiate the abbreviated flow.
          // If a user has opted to protect additional AAG hosts though, we need
          // to check and validate them here.
          if ($ctrl.isNewMode && $state.params.protectSources) {
            $ctrl.job.sourceIds = _.intersectionWith(
              $ctrl.job.sourceIds,
              $ctrl.jobTree.tree,
              function compare(id,source) {
                return source.protectionSource.id === id &&
                  !source._isProtected &&
                  !source._isPartiallyProtected;
              });
          }

          /*
           * Clear the $state.params for abbrievated flow. They have served
           * their purpose and additional changes to source selection should now
           * behave normally.
           */
          $state.params.protectSources =
            $state.params.parentSourceId =
            $state.params.parentSourceEnvironment = undefined;
        }
      );
    }

    /**
     * Indicates if the provided ViewBoxId is compatible with the replication
     * targets in provided policy.
     *
     * @method   isViewBoxRxCompatible
     * @return   {boolean}   True if view box compatible, False otherwise.
     */
    function isVbRxCompatible() {
      if (!$ctrl.policy || !$ctrl.job.viewBoxId) { return true; }

      if ($ctrl.job.isDirectArchiveEnabled) { return true; }

      return ($ctrl.policy.snapshotReplicationCopyPolicies || []).every(
        function hasMatchingVbPairing(rxCopyPolicy) {
          var remoteCluster = remoteClusterHash[
            _.get(rxCopyPolicy, 'target.clusterId')
          ];

          var remoteCloudTarget = _.get(rxCopyPolicy, 'cloudTarget.id');

          switch (true) {

            // No valid replication target
            case !remoteCluster && !remoteCloudTarget:

            // CSM is disabled and no replication cluster is found
            case !FEATURE_FLAGS.awsSnapshotManager &&
              !FEATURE_FLAGS.azureSnapshotManager && !remoteCluster:

              return false;

            // CSM Snapshots are stored remotely.
            // So viewbox check is not required.
            case $ctrl.areSnapshotsManagedRemotely:

            // CSM is enabled and local storage domain is selected.
            // But policy has only cloud replication target.
            case !$ctrl.areSnapshotsManagedRemotely && !!remoteCloudTarget &&
                !remoteCluster:

              return true;

            // All other cases
            default:
              return (remoteCluster.viewBoxPairInfo || []).some(
                function findPair(vb) {
                  return vb.localViewBoxId === $ctrl.job.viewBoxId;
                }
              );
          }
        }
      );
    }

    /**
     * Navigates away from job-edit state.
     */
    function goBack() {
      StateManagementService.goToPreviousState($ctrl.isFileStubbingJob ?
        'dataMigration' : 'jobs');
    }

    /**
     * Validates the relationships between various File DataLock values. This is
     * handled manually because we are comparing derived fields across multiple
     * instances of the c-retention component. The ng-model value is not what we
     * compare.
     *
     * @method     _validateFileDataLock
     * @return     {Boolean}   true if valid. False if invalid.
     */
    function _validateFileDataLock() {
      var fileLockConfig = $ctrl.job._envParams.fileLockConfig;
      var settingsForm = $ctrl.jobForm.jobSettingsForm;
      var validity = true;
      var defaultMsecs;
      var minMsecs;
      var maxMsecs;

      if (!FEATURE_FLAGS.nasFileDataLock || !fileLockConfig) {
        // File DataLock is not enabled.
        return true;
      }

      defaultMsecs = fileLockConfig.defaultFileRetentionDurationMsecs;
      minMsecs = fileLockConfig.minRetentionDurationMsecs;
      maxMsecs = fileLockConfig.maxRetentionDurationMsecs;

      // Reset validation for these inputs, but first check to see if they
      // exist, since they can be conditionally hidden.
      if (settingsForm.defaultRetention) {
        settingsForm.defaultRetention.$invalid =
        settingsForm.defaultRetention.$error.min =
          settingsForm.defaultRetention.$error.max = false;
      }
      if (settingsForm.maxRetention) {
        settingsForm.maxRetention.$invalid =
          settingsForm.maxRetention.$error.min = false;
      }

      // Values must fit this inequality: min < default < max
      if (defaultMsecs > 0) {
        if (minMsecs > 0 && defaultMsecs < minMsecs) {
          // Default is less than minimum.
          settingsForm.defaultRetention.$invalid =
            settingsForm.defaultRetention.$error.min = true;
          validity = false;
        }
        if (maxMsecs > 0 && defaultMsecs > maxMsecs) {
          // Default is greater than maximum.
          settingsForm.defaultRetention.$invalid =
            settingsForm.defaultRetention.$error.max = true;
          validity = false;
        }
      }

      if (minMsecs > 0) {
        if (maxMsecs > 0 && minMsecs > maxMsecs) {
          // Maximum is less than than minimum.
          settingsForm.maxRetention.$invalid =
            settingsForm.maxRetention.$error.min = true;
          validity = false;
        }
      }

      settingsForm.$submitted = true;

      return validity;
    }

    /**
     * Cleans `view.fileLockConfig` before submitting request. This is because
     * the properties have complex values. Forever = -1; None = undefined; and
     * anything else is a number of milliseconds.
     *
     * @method     _preSubmitFileDataLock
     */
    function _preSubmitFileDataLock() {
      var fileLockConfig = $ctrl.job._envParams.fileLockConfig;
      var localConfig = $ctrl.job._fileLockConfig;

      if (!FEATURE_FLAGS.nasFileDataLock || !localConfig || !fileLockConfig) {
        return;
      }

      // If not enabled, then clear the whole config.
      if (!localConfig.isEnabled) {
        $ctrl.job._envParams.fileLockConfig = undefined;
        return;
      }

      // Autolock
      if (localConfig.autolock === 'kNo') {
        fileLockConfig.autoLockAfterDurationIdle = undefined;
      }

      // Manual Lock
      if (fileLockConfig.minRetentionDurationMsecs === -1) {
        fileLockConfig.maxRetentionDurationMsecs = undefined;
      }

      // Override until Date
      if (localConfig.override === 'kOff') {
        fileLockConfig.expiryTimestampMsecs = undefined;
      }
    }

    /**
     * Manages form submit handling for the Job create/edit form.
     *
     * @method   submitJobForm
     */
    function submitJobForm() {
      // Check if form is invalid and validate the dependent relationships of
      // the File DataLock settings.
      if ($ctrl.jobForm.$invalid || !_validateFileDataLock()) {
        return;
      }

      // Clean up the File DataLock model prior to issuing service call.
      _preSubmitFileDataLock();

      // Add any missing sourceIds from the Job configuration based on the
      // selection.
      JobFlowService.addMissingSourceIds($ctrl.job);

      // In case of oracle multi node multi channel
      // user needs to save  the db credentials before making
      // the job call.
      var submitPromise = $ctrl.job._dbCredentials ?
        SourceService.updateAppOwner($ctrl.job._dbCredentials) :
        $q.resolve();

      // For NAS Jobs, check for presence of a directArchiveEnabled
      // viewbox
      var directArchivePromise = $ctrl.job.isDirectArchiveEnabled ?
        _getDirectArchiveViewBox() : $q.resolve();

      $q.all([submitPromise, directArchivePromise])
        .then(createOrUpdateJob, evalAJAX.errorMessage);
    }

    /**
     * create or update the job
     *
     * @method   createOrUpdateJob
     */
    function createOrUpdateJob() {
      var apiJob = $ctrl.job.environment === 'kSQL'
        ? _preflightSqlJob($ctrl.job)
        : cUtils.simpleCopy($ctrl.job);
      var actionFn = $ctrl.isNewMode ?
        PubJobService.createJob : PubJobService.updateJob;

      $ctrl.submitting = true;

      actionFn(apiJob).then(
        function actionSuccess(job) {
          cMessage.success({
            textKey: $ctrl.isEditMode ?
              'job.protectionJob.updated' : 'job.protectionJob.created',
            textKeyContext: job
          });
          goBack();
        },
        evalAJAX.errorMessage
      ).finally(
        function actionFinally() {
          $ctrl.submitting = false;
        }
      );
    }

    /**
     * Processes a SQL job just prior to submission to backend for various
     * settings cleanup. Does not mutate the input.
     *
     * @function   _preflightSqlJob
     * @param      {Object}   job     The job being submitted.
     * @returns    {Object}   A potentially-modified copy of the input job.
     */
    function _preflightSqlJob(job) {
      if (!job || job.environment !== 'kSQL') { return job; }

      const updatedJob = _.merge({}, job);

      // When ths SQL Job is volume-based, remove this setting because it only
      // applies to file-based SQL jobs.
      if (updatedJob._envParams.backupType === 'kSqlVSSVolume') {
        updatedJob.performSourceSideDedup = undefined;
      }

      // When this setting is falsey, empty the selected exclusions array since
      // they don't apply.
      if (!updatedJob.performSourceSideDedup) {
        updatedJob.dedupDisabledSourceIds.length = 0;
      }

      if (updatedJob._envParams.backupType !== 'kSqlNative') {
        // Empty this value if submitted with any other backupType except
        // kSqlNative.
        updatedJob._envParams.numStreams =
          updatedJob._envParams.withClause = undefined;
      }

      return updatedJob;
    }

    /**
     * Launches the object selection slide modal via SlideModalService
     * and process the results upon resolution.
     */
    function selectObjects() {
      var modalOpts = {
        templateUrl: 'app/protection/jobs/modify2/source-tree/source-tree.html',
        controller: 'jobModSourceTreeController',
        size: 'xl',
        resolve: {
          opts: {
            job: $ctrl.job,
            jobTree: $ctrl.jobTree,
            policy: $ctrl.policy,
            isEditMode: $ctrl.isEditMode,

            // Optional leafType for cloudSources.
            // AWS can have various jobTypes which treat different aws entities
            // as leaves. This tells the source tree which entity should be
            // treated as leaf.
            // This property will have no effect on non cloud adapters.
            cloudLeafType: ($ctrl.isRDS ?
              {kAWS: ['kRDSInstance']} : {kAWS: ['kEC2Instance']}),
          }
        },
      };

      SlideModalService.newModal(modalOpts).then(
        function slideModalClosed(resp) {
          $ctrl.job = resp.job;
          $ctrl.jobTree = resp.jobTree;
        }
      );
    }

    /**
     * Load the tree for the provided job.
     *
     * @method   _loadTree
     * @return   {object}   promise as returned from service call
     */
    function _loadTree() {
      $ctrl.treeLoaded = false;
      $ctrl.jobTree = {};

      // TODO(Tauseef): Lazy load the objects if its in edit mode using the
      // API protectedObjects to show job-objects.
      //
      // Incase of job creation mode, all leaf entities are to be excluded
      // from being fetched if the environment supports pagination. eg: kO365.
      return PubJobService.getJobTree($ctrl.job, undefined,
        !$ctrl.isEditMode).then(
        function getTreeSuccess(jobTree) {
          $ctrl.jobTree = jobTree;

          // Remove any missing sourceIds from the Job configuration.
          JobFlowService.removeMissingSourceIds(jobTree, $ctrl.job);
        },
        evalAJAX.errorMessage
      ).finally(
        function getTreeFinally() {
          $ctrl.treeLoaded = true;
        }
      );
    }

    /**
     * Gets the new job environment.
     *
     * @method   _getNewJobEnvironment
     * @param    {object}   sourceEntity   The source entity
     * @return   {string}   The new job environment, kValue
     */
    function _getNewJobEnvironment(source) {
      // hypervEntity
      if (source.protectionSource.environment === 'kHyperV') {
        // As per Magneto, entity type would be same for HyperV and HyperVVSS
        // but job type should be different for HyperV and HyperVVSS. Identify
        // HyperVVSS entity using backupType property. backupType for HyperVVSS
        // is 'kVssBackup'.
        return source._envProtectionSource.backupType === 'kVssBackup' ?
          'kHyperVVSS':
          'kHyperV';
      }
      return source.protectionSource.environment;
    }

    /**
     * Returns the appropriate help value for the state based on analysis of the
     * Job and/or environments.
     *
     * @method   _getHelpValue
     */
    function _getHelpValue() {
      var jobEnv = $ctrl.job.environment;

      // Some situtations apply for both new and edit scenarios. Start there.
      switch (jobEnv) {
        case 'kSQL':
          return 'protection_job_modify_sql';
        case 'kPuppeteer':
          return 'protection_job_modify_remote_adapter';
        case 'kPure':
          return 'protection_job_modify_pure';
        case 'kView':
          return 'protection_job_modify_view';
        case 'kOracle':
          return 'protection_job_modify_oracle';
        case 'kAD':
          return 'protection_job_ad';
      }

      // If the Job is already set with an environment that is included in a
      // particular ENV_GROUPS array or if the intersection of available
      // environments and the ENV_GROUPS array has a length, return the
      // appropriate help key for that ENV_GROUPS array
      if (ENV_GROUPS.physical.includes(jobEnv) ||
        _.intersection(ENV_GROUPS.physical, $ctrl.environments).length) {
        return 'protection_job_modify_physical';
      }

      if (ENV_GROUPS.hypervisor.includes(jobEnv) ||
        _.intersection(ENV_GROUPS.hypervisor, $ctrl.environments).length) {
        return 'protection_job_modify_virtual';
      }

      if (ENV_GROUPS.nas.includes(jobEnv) ||
        _.intersection(ENV_GROUPS.nas, $ctrl.environments).length) {
        return 'protection_job_modify_nas';
      }
    }

    /**
     * called when selected sources got changed i.e. addition/removal of source
     *
     * @method   onSelectedSourcesChange
     */
    function onSelectedSourcesChange() {
      var job = $ctrl.job;
      var jobEnv = job.environment;

      $ctrl.job._isLinuxSourceSelected =
        _.get($ctrl.job, '_selectedSources[0]._hostType') === 'kLinux';

      $ctrl.job._isWindowsSourceSelected =
        _.get($ctrl.job, '_selectedSources[0]._hostType') === 'kWindows';

      // check if $ctrl.job._selectedSource actually changed or not, as this
      // method gets fired for change in any $ctrl.job property
      if (selectedSourceTypeChanged(job._selectedSources)) {
        // recompute any property that depends on selectedsource
        recomputeSourceDedependentJobProperties();
      }

      // if job is in edit mode and performSourceSideDedup hasn't been set,
      // (because job was upgraded to 6.2), set it to it's default value here
      if ($ctrl.isEditMode && $ctrl.job.performSourceSideDedup === undefined
        && ($ctrl.job._isLinuxSourceSelected ||
          $ctrl.job._isWindowsSourceSelected)) {
        setPerformSourceSideDedup();
      }

      if (ENV_GROUPS.nas.includes(jobEnv)) {
        $ctrl.captureNasCredentials();

        if (FEATURE_FLAGS.cloudArchiveDirect) {
          $ctrl.job.isNativeFormat = true;
          $ctrl.job.indexingPolicy.disableIndexing = false;
        }
      }

      if (ENV_GROUPS.databaseSources.includes(jobEnv) && job.sourceIds[0]) {
        job._jobHostEnvironment = job._selectedSources[0]._hostEnvironment;
      }
    }

    /**
     * returns the NAS environment credentials state like
     * do we need to capture SMB credentials and prefered NAS protocol
     *
     * @method   captureNasCredentials
     */
    function captureNasCredentials() {
      var smbCredentialRequired = false;
      var smbCredentialRecommended = false;
      var hasSmbVolume = false;
      var hasError = false;
      var missingSmbCredentials = false;

      // mixedMode defines source support both NFS & SMB & only possible with
      // kNetapp.
      var mixedModeSettingRequired;

      // early exit when tree is empty OR no selected sources
      if ($ctrl.jobTree.tree.length === 0 ||
        $ctrl.job._selectedSources.length === 0) {
        $ctrl.nasState = undefined;
        return false;
      }

      /*
       * for kNetapp
       * missingSmbCredentials is true when rootNode has missing NAS mount
       * credentials else false.
       *
       * for other than kNetapp
       * missingSmbCredentials is true when tree has at least one SMB node
       * with missing NAS mount credentials else false.
       */
      if ($ctrl.job.environment === 'kNetapp') {
        // checking does rootNode has missing NAS mount credentials.
        missingSmbCredentials = !(
          $ctrl.job._parentSource.registrationInfo.accessInfo &&
          $ctrl.job._parentSource.registrationInfo.nasMountCredentials
        );
      } else {
        // Checking if any of the tree nodes is missing NAS mount credentials.
        missingSmbCredentials =
          $ctrl.jobTree.tree.some(function eachRootNode(node) {
            var nasEnvFSMap = NAS_FILESYSTEM_MAP[node._environment];
            var supportSMB = cUtils.findIntersection(
              node._dataProtocols,
              nasEnvFSMap.smb
            ).length;

            // does node have missing NAS mount credentials.
            var hasNasMountCredentials = !!(
              node.registrationInfo.accessInfo &&
              node.registrationInfo.nasMountCredentials
            );

            return supportSMB && !hasNasMountCredentials;
          });
      }

      $ctrl.job._selectedSources.forEach(function eachSelectedNode(node) {
        var nasEnvFSMap = NAS_FILESYSTEM_MAP[node._environment];
        var supportNFS =
          _.intersection(node._dataProtocols, nasEnvFSMap.nfs).length;
        var supportSMB =
          _.intersection(node._dataProtocols, nasEnvFSMap.smb).length;

        /*
         * mixedModeSettingRequired is true when at least one mode in selected
         * job node is either of kNetapp vServer type OR node support
         * both NFS and SMB.
         */
        mixedModeSettingRequired = mixedModeSettingRequired ||
          ((node._environment === 'kNetapp' &&
            node._envProtectionSource.vserverInfo) ||
            (supportNFS && supportSMB)
          );

        /*
         * hasSmbVolume is true when selected job node has at least one node
         * which support SMB but not NFS.
         */
        hasSmbVolume = hasSmbVolume || (supportSMB && !supportNFS);

        /*
         * smbCredentialRecommended is true when there are
         * missingSmbCredentials AND selected job node has at least one node
         * which is type kNetapp vServer type AND support NFS but not SMB.
         */
        smbCredentialRecommended = smbCredentialRecommended ||
          (missingSmbCredentials &&
            node._environment === 'kNetapp' &&
            node._envProtectionSource.vserverInfo &&
            supportNFS &&
            !supportSMB
          );
      });

      // nasProtocol is looked up from NasProtocol in magneto/base/enums.proto
      smbCredentialRequired = missingSmbCredentials &&
        (hasSmbVolume || (mixedModeSettingRequired &&
          $ctrl.job._envParams.nasProtocol === 'kCifs1')
        );

      hasError = mixedModeSettingRequired ||
        (smbCredentialRequired || smbCredentialRecommended);

      // update UI NAS state
      $ctrl.nasState = {
        mixedModeSettingRequired: mixedModeSettingRequired,
        smbCredentialRequired: smbCredentialRequired,
        smbCredentialRecommended: smbCredentialRecommended,
        hasSmbVolume: hasSmbVolume,
        hasError: hasError,
      };
    }

    /**
     * checks if type of selectedSources has changed
     *
     * @method   selectedSourceTypeChanged
     * @param    {object}   selectedSource   The selectedsource entity
     * @return   {boolean}   true if type of selectedsource has changed
     */
    function selectedSourceTypeChanged(newSelectedSources) {
      var _oldSourceType;
      var _newSourceType;

      if (!$ctrl.oldSelectedSource) {
        $ctrl.oldSelectedSource = newSelectedSources;
        return $ctrl.isNewMode;
      }

      if ($ctrl.oldSelectedSource.length > 0) {
        _oldSourceType = $ctrl.oldSelectedSource[0]._hostType;
      }

      if (newSelectedSources.length > 0) {
        _newSourceType = newSelectedSources[0]._hostType;
      }

      $ctrl.oldSelectedSource = newSelectedSources;
      return _oldSourceType !== _newSourceType;
    }

    /**
     * set performSourceSideDedup value based on selected sources
     *
     * @method setPerformSourceSideDedup
     */
    function setPerformSourceSideDedup() {
      // Source side dedup conditions:
      // -> Physical - linux(block) -> true by default(user can toggle)
      // -> Physical - linux(file)/windows(block/file) -> false by default(user can toggle)
      // -> Physical - for other OS types - cannot enable/disable
      switch (true) {
        case $ctrl.job._isLinuxSourceSelected && $ctrl.job._isPhysicalBlockEnv:
          $ctrl.job.performSourceSideDedup = true;
          break;

        case $ctrl.job._isLinuxSourceSelected && $ctrl.job._isPhysicalFileEnv:
          $ctrl.job.performSourceSideDedup = false;
          break;

        case $ctrl.job._isWindowsSourceSelected:
          $ctrl.job.performSourceSideDedup = false;
          break;

        default:
          $ctrl.job.performSourceSideDedup = undefined;
      }
    }

    /**
     * recomputes job property that depends on type of selectedsource
     *
     * @method   recomputeSourceDedependentJobProperties
     */
    function recomputeSourceDedependentJobProperties() {
      // calculate source dependent properties here. Currently only
      // performSourceSideDedup depends on type of selectedSources.
      setPerformSourceSideDedup();
    }

    /**
     * Indicates if the Job submit button should be disabled or not.
     *
     * @method   isSubmitDisabled
     * @return   {boolean} True if submit should be disabled, False otherwise.
     */
    function isSubmitDisabled() {
      // Button is never enabled until the settingsShutters are visible.
      if (!$ctrl.isVisible('settingsShutters')) {
        return true;
      }

      // Direct Archive Cases
      if ($ctrl.job.isDirectArchiveEnabled) {
        switch (true) {
          // More than one selected volume is not allowed
          case $ctrl.job._selectedSources.length > 1:

          // Replication is not allowed
          case _.get($ctrl, 'policy.snapshotReplicationCopyPolicies.length'):

          // More than one archival targets not allowed
          case _.get($ctrl, 'policy.snapshotArchivalCopyPolicies.length') > 1:

          // Tape as archival target is not allowed
          case _.get($ctrl, 'policy._targets.tape', false):

          // Archival policy must archive after every run
          case _.get($ctrl, 'policy.snapshotArchivalCopyPolicies[0]') &&
            _.get($ctrl, 'policy.snapshotArchivalCopyPolicies[0].periodicity')
              !== 'kEvery':

            return true;
        }
      }

      // For these special cases, don't disable the button and allow form
      // validation to prevent erroneous submissions.
      if (['kView', 'kPuppeteer'].includes($ctrl.job.environment)) {
        return false;
      }

      // For all other cases, ensure that some sources were selected.
      return !($ctrl.job.sourceIds || []).length &&
        !($ctrl.job.vmTagIds || []).length;
    }

    /**
     * Callback when a remote location is selected for storing snapshots
     *
     * @method  snapshotLocationChanged
     * @param   {String}  type  The type of remote storage (aws/azure etc)
     */
    function snapshotLocationChanged(type) {
      switch(type) {
        case 'aws':
          $ctrl.job.environment = $ctrl.areSnapshotsManagedRemotely ?
            'kAWSSnapshotManager' : 'kAWSNative';

          _setDefaultViewBox(true);

          break;

        case 'azure':
          $ctrl.job.environment = $ctrl.areSnapshotsManagedRemotely ?
            'kAzureSnapshotManager' : 'kAzureNative';

          _setDefaultViewBox(true);

          break;

        default:
          $ctrl.job.environment = $ctrl.jobType;
      }
    }

    /**
     * Sets the default viewBox value if there is only one value which can be
     * selected
     *
     * @method  _setDefaultViewBox
     * @param   {Boolean}  isVBHidden  True if viewbox selection option is
     *                                 hidden in UI.
     */
    function _setDefaultViewBox(isVBHidden) {
      $ctrl.viewBoxPromise.then(
        function thenHandler(viewBoxes) {
          // Select Default viewbox if only one viewbox is available.
          // For special cases like 'kRDSSnapshotManager' where viewbox
          // selection is hidden in the UI, select the first viewbox, even if
          // there are more that one viewboxes available.
          if (viewBoxes && (viewBoxes.length === 1 || isVBHidden)) {
            $ctrl.job._viewBoxName = viewBoxes[0].name;
            $ctrl.job.viewBoxId = viewBoxes[0].id;
          }
        }, evalAJAX.errorMessage);
    }

    /**
     * Get the special 'Direct Archive Enabled' Viewbox for NAS Direct Archive
     * jobs. If it is not avaialble, create one.
     *
     * @method  _getDirectArchiveViewBox
     * @return  {Object}  Promise to be resolved with directArchiveEnabled
     *                    ViewBox
     */
    function _getDirectArchiveViewBox() {
      var deferred = $q.defer();
      var vbPromise;
      var viewBox;

      ViewBoxService.getViewBoxes({_includeDirectArchiveEnabled: true}).then(
        function thenHandler(viewBoxes) {
          viewBox = _.find(viewBoxes || [], function eachViewBox(vb) {
            return vb.directArchiveEnabled;
          });

          if (!viewBox) {
            vbPromise = _createDirectArchiveViewbox();
          } else {
            vbPromise = $q.resolve(viewBox);
          }

          vbPromise.then(function foundViewBox(viewBox) {
            $ctrl.job._viewBoxName = viewBox.name;
            $ctrl.job.viewBoxId = viewBox.id;

            deferred.resolve();
          }, evalAJAX.errorMessage);

        }, evalAJAX.errorMessage);

      return deferred.promise;
    }

    /**
     * Go back to the sources landing page.
     *
     * @method  goToSources
     */
    function goToSources() {
      $state.go('sources-new');
    }

    /**
     * Callback for 'Cloud Archive Direct' setting when toggled on/off.
     * Set some metadata when it changes.
     *
     * @method  cloudArchiveDirectToggled
     * @param   {Boolean}  state  The current state of the toggle
     */
    function cloudArchiveDirectToggled(state) {
      $ctrl.job.isNativeFormat = state;

      // Indexing Should be enabled by default for DirectArchive
      $ctrl.job.indexingPolicy.disableIndexing = !state;

      // reset the visibility of viewbox selection
      isVisibleApi.cache['viewBox'] = false;
    }

    /**
     * Create a hidden "directArchiveViewbox" for NAS Direct archive support.
     * This is created only if there's no other directArchiveViewbox already
     * available. This viewbox is only meant for internal use and is hidden
     * from end user.
     *
     * @method _createDirectArchiveViewbox
     * @return  {Object}  Promise to be resolved with created viewbox object
     */
    function _createDirectArchiveViewbox() {
      var deferred = $q.defer();

      PartitionService.getPartitions().then(
        function getPartitionsSuccess(partitions) {
          ViewBoxService.createViewBox({
            name: 'Direct_Archive_Viewbox',
            clusterPartitionId: partitions[0].id,
            directArchiveEnabled: true,
            storagePolicy: {
              deduplicationEnabled: false,
              inlineDeduplicate: true,
              compressionPolicy: 'kCompressionLow',
              inlineCompress: true,
              encryptionPolicy: 'kEncryptionNone',
              erasureCodingInfo: {
                erasureCodingEnabled: false,
              },
              numFailuresTolerated: 1,
              numNodeFailuresTolerated: 1,
            },
            cloudDownWaterfallThresholdSecs: 86400 * 60,
          }).then(function createdVieBox(viewBox) {
            deferred.resolve(viewBox);
          }, function failedViewBoxCreation(resp) {
            deferred.reject(resp);
          });
        }, function failedPartitionGet(resp) {
          deferred.reject(resp);
        });

      return deferred.promise;
    }

    /**
     * Is Cloud Snapshot Manager Enabled
     *
     * @method  _isCSMEnabled
     * @return  {Boolean} True if CSM is enabled. False otherwise
     */
    function _isCSMEnabled() {
      return ((FEATURE_FLAGS.awsSnapshotManager &&
        $ctrl.jobType === 'kAWSNative') ||
          (FEATURE_FLAGS.azureSnapshotManager &&
            $ctrl.jobType === 'kAzureNative'));
    }
  }

}(angular));
