import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';

import { OnboardingToolbarComponent } from './onboarding-toolbar/onboarding-toolbar.component';

@NgModule({
  imports: [CohesityHelixModule, CommonModule],
  declarations: [OnboardingToolbarComponent],
  exports: [OnboardingToolbarComponent],
})
export class OnboardingWizardModule {}
