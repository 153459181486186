import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { Environment as EnvironmentTypes } from 'src/app/shared';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { ProtectedObjectInfo } from '@cohesity/api/v2';
import { PopoverRef } from '@cohesity/helix';
import { IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { StoreEntry } from '@cohesity/utils';
import { TransitionService } from '@uirouter/core';
import { Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { PassthroughOptionsService } from 'src/app/core/services';
import { ObjectInfoService } from 'src/app/modules/object-details-shared/object-info.service';

/**
 * This component displays protection info inside of a source meta overlay, using the object info service if it is
 * available. It will look up the object's details to show what groups or object is protecting the item. If the object
 * info service is not available, it will fall back to a simple protected/unprotected label.
 */
@Component({
  selector: 'coh-protection-status-meta',
  templateUrl: './protection-status-meta.component.html',
  styleUrls: ['./protection-status-meta.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProtectionStatusMetaComponent implements OnInit, OnDestroy {
  /**
   * Whether node is protected or not
   */
  isNodeProtected: boolean;

  /**
   * The (already translated) label to use to indicate protection if group details are not available.
   */
  @Input() protectionLabel: string;

  /**
   * The current (v1) node
   */
  @Input() node: ProtectionSourceNode;

  /**
   * Optional protection group specific environment.
   * If this is set, use the protection summary of this specific environment
   * instead of the default first environment in the summary list.
   */
  @Input() groupEnv: string;

  /**
   * Protected object info with protection and compliance info. We only use this to lookup whether there is group
   * protection applied to an object.
   */
  objectInfo$: Observable<StoreEntry<ProtectedObjectInfo>>;

  /**
   * Protection info. Note that the value here is typed as any since this component is defined in another module
   * and we don't want to bring in the import here
   */
  protectionInfo$: Observable<{ loading: boolean; info: any }>;

  /**
   * Gets the protected parent's info if the node is autoo protected by a parent's object protection configuration.
   */
  get protectedParent$(): Observable<StoreEntry<ProtectedObjectInfo>> {
    const parentId = this.node.objectProtectionInfo?.autoProtectParentId;
    if (!this.node.objectProtectionInfo?.hasActiveObjectProtectionSpec ||
      !parentId ||
      !this.infoService ||
      !this.isNodeProtected) {
      return of(null);
    }
    return this.infoService.getObjectInfo(parentId, this.passthroughOptionsService.requestParams);
  }

  /**
   * Whether the user is in DMaaS scope.
   */
  isDmsScope = false;

  /**
   * Environment constant to access in template
   */
  readonly environment = EnvironmentTypes;

  /**
   * Reference to the transition hook that can be cleaned up when the component is destroyed.
   */
  private transitionHook: Function;

  constructor(
    @Optional() readonly infoService: ObjectInfoService,
    @Optional() readonly popoverRef: PopoverRef,
    readonly passthroughOptionsService: PassthroughOptionsService,
    private irisCtx: IrisContextService,
    private transitionService: TransitionService
  ) {}

  ngOnInit(): void {
    this.isDmsScope = isDmsScope(this.irisCtx.irisContext);
    let protectedSourcesSummary = this.node.protectedSourcesSummary?.[0];

    if (this.groupEnv) {
      protectedSourcesSummary = this.node.protectedSourcesSummary
        .find(summary => summary.environment === this.groupEnv);
    }

    this.isNodeProtected = protectedSourcesSummary?.leavesCount > 0;

    const id = this.node.protectionSource.id;

    if (!this.isNodeProtected) {
      // Don't make any further lookups if the object is not protected.
      this.objectInfo$ = of({ loading: false, item: null, id, error: false });
      this.protectionInfo$ = of({
        loading: false,
        info: {
          node: this.node,
          isProtected: false,
        },
      });
    } else if (this.infoService) {
      this.objectInfo$ = this.infoService
        .getObjectInfo(this.node.protectionSource.id, this.passthroughOptionsService.requestParams)
        .pipe(
          // Make sure that the overlay updates when data is resolved, since it will adjust the height of the overlay.
          tap(() => this.popoverRef.overlay.updatePosition())
        );
      if (this.infoService.getLastRunActivity) {
        // Use helios api to manage the lookup
        this.protectionInfo$ = this.infoService
          .getLastRunActivity(id, this.passthroughOptionsService.requestParams)
          .pipe(
            filter(entry => !!entry),
            map(entry => ({
              loading: entry.loading,
              info: {
                lastRunActivity: entry.item as any,
                node: this.node,
                loadingError: entry.error,
                isProtected: true,
              },
            })),
            tap(() => this.popoverRef.overlay.updatePosition())
          );
      } else {
        // Use Magneto for the lookup
        this.protectionInfo$ = this.objectInfo$.pipe(
          filter(entry => !!entry),
          map(entry => ({
            loading: entry.loading,
            info: {
              object: entry.item,
              node: this.node,
              loadingError: entry.error,
              isProtected: true,
            },
          })),
        );
      }
    }

    // Make sure that the popover is closed if the user navigates to a new state while it is open.
    this.transitionHook = this.transitionService.onStart({}, () => this.popoverRef?.close());
  }

  ngOnDestroy() {
    if (this.transitionHook) {
      this.transitionHook();
      this.transitionHook = null;
    }
  }
}
