import { ransomwareTagNames } from '@cohesity/shared/tags';
import { CellColorStates } from './cell-colors.constants';

/**
 * An enum for all possible anomaly statuses that could be displayed in the anomalies column
 */
export enum AnomaliesStatus {
  high = 'high',
  medium = 'medium',
  not_found = 'not_found',
  unknown = 'unknown',
}

/**
 * A map of anomaly status to the corresponding display label key
 */
export const anomalyStatusLabelKeys: Record<AnomaliesStatus, string> = {
  [AnomaliesStatus.high]: 'critical',
  [AnomaliesStatus.medium]: 'warning',
  [AnomaliesStatus.not_found]: 'kNone',
  [AnomaliesStatus.unknown]: '-',
};

/**
 * A map of anomaly status to the background cell color
 */
export const anomalyStatusColors: Record<AnomaliesStatus, CellColorStates> = {
  [AnomaliesStatus.high]: CellColorStates.high,
  [AnomaliesStatus.medium]: CellColorStates.medium,
  [AnomaliesStatus.not_found]: CellColorStates.ok,
  [AnomaliesStatus.unknown]: CellColorStates.unknown,
};

/**
 * Mapped tag names for the anomalies statuses
 */
export const anomaliesTagNames: Record<AnomaliesStatus, string> = {
  [AnomaliesStatus.high]: ransomwareTagNames.critical,
  [AnomaliesStatus.medium]: ransomwareTagNames.warning,
  [AnomaliesStatus.not_found]: ransomwareTagNames.none,
  [AnomaliesStatus.unknown]: ''
};
