/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BulkUpdateShieldsRequest } from '../models/bulk-update-shields-request';
import { BulkUpdateShieldsResponse } from '../models/bulk-update-shields-response';
import { DataPoolId } from '../models/data-pool-id';
import { DataSourceId } from '../models/data-source-id';
import { FileChangeType } from '../models/file-change-type';
import { GetAffectedFilesResponse } from '../models/get-affected-files-response';
import { GetFilesResponse } from '../models/get-files-response';
import { GetIncidenceDetailsResponse } from '../models/get-incidence-details-response';
import { GetIncidencesResponse } from '../models/get-incidences-response';
import { GetSensitivityResultsResponse } from '../models/get-sensitivity-results-response';
import { GetShieldsResponse } from '../models/get-shields-response';
import { IncidenceId } from '../models/incidence-id';
import { ObjectId } from '../models/object-id';
import { OutputFormat } from '../models/output-format';
import { PaginationToken } from '../models/pagination-token';
import { PatternId } from '../models/pattern-id';
import { Range } from '../models/range';
import { RuleId } from '../models/rule-id';
import { Shield } from '../models/shield';
import { ShieldType } from '../models/shield-type';
import { TimestampMsecs } from '../models/timestamp-msecs';

@Injectable({
  providedIn: 'root',
})
export class ShieldsApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getShields
   */
  static readonly GetShieldsPath = '/shields';

  /**
   * Get the applicable and associated shields for the provided Data Pools.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShields()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShields$Response(params?: {

    /**
     * Filter by a list of Data Pools ids.
     */
    dataPoolIds?: null | Array<DataPoolId>;

  }): Observable<StrictHttpResponse<GetShieldsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ShieldsApiService.GetShieldsPath, 'get');
    if (params) {

      rb.query('dataPoolIds', params.dataPoolIds, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetShieldsResponse>;
      })
    );
  }

  /**
   * Get the applicable and associated shields for the provided Data Pools.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getShields$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShields(params?: {

    /**
     * Filter by a list of Data Pools ids.
     */
    dataPoolIds?: null | Array<DataPoolId>;

  }): Observable<GetShieldsResponse> {

    return this.getShields$Response(params).pipe(
      map((r: StrictHttpResponse<GetShieldsResponse>) => r.body as GetShieldsResponse)
    );
  }

  /**
   * Path part for operation bulkUpdateShields
   */
  static readonly BulkUpdateShieldsPath = '/shields';

  /**
   * Bulk updates the provided shields by assigning and unassigning data pools.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkUpdateShields()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkUpdateShields$Response(params: {
      body: BulkUpdateShieldsRequest
  }): Observable<StrictHttpResponse<BulkUpdateShieldsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ShieldsApiService.BulkUpdateShieldsPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BulkUpdateShieldsResponse>;
      })
    );
  }

  /**
   * Bulk updates the provided shields by assigning and unassigning data pools.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bulkUpdateShields$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkUpdateShields(params: {
      body: BulkUpdateShieldsRequest
  }): Observable<BulkUpdateShieldsResponse> {

    return this.bulkUpdateShields$Response(params).pipe(
      map((r: StrictHttpResponse<BulkUpdateShieldsResponse>) => r.body as BulkUpdateShieldsResponse)
    );
  }

  /**
   * Path part for operation getShieldByType
   */
  static readonly GetShieldByTypePath = '/shields/{type}';

  /**
   * Get the shield by type.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShieldByType()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShieldByType$Response(params: {

    /**
     * Specifies the shield type.
     */
    type: ShieldType;

  }): Observable<StrictHttpResponse<Shield>> {

    const rb = new RequestBuilder(this.rootUrl, ShieldsApiService.GetShieldByTypePath, 'get');
    if (params) {

      rb.path('type', params.type, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shield>;
      })
    );
  }

  /**
   * Get the shield by type.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getShieldByType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShieldByType(params: {

    /**
     * Specifies the shield type.
     */
    type: ShieldType;

  }): Observable<Shield> {

    return this.getShieldByType$Response(params).pipe(
      map((r: StrictHttpResponse<Shield>) => r.body as Shield)
    );
  }

  /**
   * Path part for operation updateShield
   */
  static readonly UpdateShieldPath = '/shields/{type}';

  /**
   * Update a shield by assigning and unassigning data pools.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateShield()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateShield$Response(params: {

    /**
     * Specifies the shield type.
     */
    type: ShieldType;
      body: Shield
  }): Observable<StrictHttpResponse<Shield>> {

    const rb = new RequestBuilder(this.rootUrl, ShieldsApiService.UpdateShieldPath, 'put');
    if (params) {

      rb.path('type', params.type, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Shield>;
      })
    );
  }

  /**
   * Update a shield by assigning and unassigning data pools.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateShield$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateShield(params: {

    /**
     * Specifies the shield type.
     */
    type: ShieldType;
      body: Shield
  }): Observable<Shield> {

    return this.updateShield$Response(params).pipe(
      map((r: StrictHttpResponse<Shield>) => r.body as Shield)
    );
  }

  /**
   * Path part for operation getShieldIncidences
   */
  static readonly GetShieldIncidencesPath = '/shields/{shieldType}/incidences';

  /**
   * Get the list of detected incidences for a shield.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShieldIncidences()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShieldIncidences$Response(params: {

    /**
     * Specifies the shield type.
     */
    shieldType: ShieldType;

    /**
     * Start time in milli seconds to fetch events.
     */
    startTimeMsecs?: TimestampMsecs;

    /**
     * End time in milli seconds to fetch events.
     */
    endTimeMsecs?: TimestampMsecs;

    /**
     * List of rules to filter events.
     */
    ruleIds?: Array<RuleId>;

    /**
     * Specifies filter list of data source ids.
     */
    dataSourceIds?: Array<DataSourceId>;

    /**
     * Specifies filter list of data pool ids.
     */
    dataPoolIds?: null | Array<DataPoolId>;

    /**
     * Specifies the sensitivity of events to fetch.
     */
    sensitivity?: Array<Range>;

    /**
     * Specifies the risk level of events to fetch.
     */
    riskLevel?: Array<Range>;

    /**
     * Return the cause of anomaly(By default false).
     */
    includeAnomalyCause?: boolean;

    /**
     * List of objects to filter events.
     */
    objectIds?: Array<ObjectId>;

  }): Observable<StrictHttpResponse<GetIncidencesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ShieldsApiService.GetShieldIncidencesPath, 'get');
    if (params) {

      rb.path('shieldType', params.shieldType, {});
      rb.query('startTimeMsecs', params.startTimeMsecs, {});
      rb.query('endTimeMsecs', params.endTimeMsecs, {});
      rb.query('ruleIds', params.ruleIds, {});
      rb.query('dataSourceIds', params.dataSourceIds, {});
      rb.query('dataPoolIds', params.dataPoolIds, {});
      rb.query('sensitivity', params.sensitivity, {});
      rb.query('riskLevel', params.riskLevel, {});
      rb.query('includeAnomalyCause', params.includeAnomalyCause, {});
      rb.query('objectIds', params.objectIds, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetIncidencesResponse>;
      })
    );
  }

  /**
   * Get the list of detected incidences for a shield.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getShieldIncidences$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShieldIncidences(params: {

    /**
     * Specifies the shield type.
     */
    shieldType: ShieldType;

    /**
     * Start time in milli seconds to fetch events.
     */
    startTimeMsecs?: TimestampMsecs;

    /**
     * End time in milli seconds to fetch events.
     */
    endTimeMsecs?: TimestampMsecs;

    /**
     * List of rules to filter events.
     */
    ruleIds?: Array<RuleId>;

    /**
     * Specifies filter list of data source ids.
     */
    dataSourceIds?: Array<DataSourceId>;

    /**
     * Specifies filter list of data pool ids.
     */
    dataPoolIds?: null | Array<DataPoolId>;

    /**
     * Specifies the sensitivity of events to fetch.
     */
    sensitivity?: Array<Range>;

    /**
     * Specifies the risk level of events to fetch.
     */
    riskLevel?: Array<Range>;

    /**
     * Return the cause of anomaly(By default false).
     */
    includeAnomalyCause?: boolean;

    /**
     * List of objects to filter events.
     */
    objectIds?: Array<ObjectId>;

  }): Observable<GetIncidencesResponse> {

    return this.getShieldIncidences$Response(params).pipe(
      map((r: StrictHttpResponse<GetIncidencesResponse>) => r.body as GetIncidencesResponse)
    );
  }

  /**
   * Path part for operation downloadShieldIncidencesReport
   */
  static readonly DownloadShieldIncidencesReportPath = '/shields/{shieldType}/incidences/download-reports';

  /**
   * Get the list of detected incidences for a shield.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadShieldIncidencesReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadShieldIncidencesReport$Response(params: {

    /**
     * Specifies the shield type.
     */
    shieldType: ShieldType;

    /**
     * Start time in milli seconds to fetch incidences.
     */
    startTimeMsecs?: TimestampMsecs;

    /**
     * End time in milli seconds to fetch incidences.
     */
    endTimeMsecs?: TimestampMsecs;

    /**
     * list of incidence Ids to filter incidences.
     */
    incidenceIds?: Array<IncidenceId>;

    /**
     * list of rules to filter incidences.
     */
    ruleIds?: Array<RuleId>;

    /**
     * Specifies filter list of data source ids.
     */
    dataSourceIds?: Array<DataSourceId>;

    /**
     * Specifies filter list of data pool ids.
     */
    dataPoolIds?: null | Array<DataPoolId>;

    /**
     * Specifies the sensitivity of events to fetch.
     */
    sensitivity?: Array<Range>;

    /**
     * Specifies the risk level of events to fetch.
     */
    riskLevel?: Array<Range>;

  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ShieldsApiService.DownloadShieldIncidencesReportPath, 'get');
    if (params) {

      rb.path('shieldType', params.shieldType, {});
      rb.query('startTimeMsecs', params.startTimeMsecs, {});
      rb.query('endTimeMsecs', params.endTimeMsecs, {});
      rb.query('incidenceIds', params.incidenceIds, {});
      rb.query('ruleIds', params.ruleIds, {});
      rb.query('dataSourceIds', params.dataSourceIds, {});
      rb.query('dataPoolIds', params.dataPoolIds, {});
      rb.query('sensitivity', params.sensitivity, {});
      rb.query('riskLevel', params.riskLevel, {});

    }
    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/csv'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Get the list of detected incidences for a shield.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadShieldIncidencesReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadShieldIncidencesReport(params: {

    /**
     * Specifies the shield type.
     */
    shieldType: ShieldType;

    /**
     * Start time in milli seconds to fetch incidences.
     */
    startTimeMsecs?: TimestampMsecs;

    /**
     * End time in milli seconds to fetch incidences.
     */
    endTimeMsecs?: TimestampMsecs;

    /**
     * list of incidence Ids to filter incidences.
     */
    incidenceIds?: Array<IncidenceId>;

    /**
     * list of rules to filter incidences.
     */
    ruleIds?: Array<RuleId>;

    /**
     * Specifies filter list of data source ids.
     */
    dataSourceIds?: Array<DataSourceId>;

    /**
     * Specifies filter list of data pool ids.
     */
    dataPoolIds?: null | Array<DataPoolId>;

    /**
     * Specifies the sensitivity of events to fetch.
     */
    sensitivity?: Array<Range>;

    /**
     * Specifies the risk level of events to fetch.
     */
    riskLevel?: Array<Range>;

  }): Observable<string> {

    return this.downloadShieldIncidencesReport$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getIncidenceDetails
   */
  static readonly GetIncidenceDetailsPath = '/shields/{shieldType}/incidences/{id}/details';

  /**
   * Get the impacted data pools for a given shield incidence.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIncidenceDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIncidenceDetails$Response(params: {

    /**
     * Specifies the shield type.
     */
    shieldType: ShieldType;

    /**
     * Specifies the incidence id.
     */
    id: IncidenceId;

  }): Observable<StrictHttpResponse<GetIncidenceDetailsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ShieldsApiService.GetIncidenceDetailsPath, 'get');
    if (params) {

      rb.path('shieldType', params.shieldType, {});
      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetIncidenceDetailsResponse>;
      })
    );
  }

  /**
   * Get the impacted data pools for a given shield incidence.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getIncidenceDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIncidenceDetails(params: {

    /**
     * Specifies the shield type.
     */
    shieldType: ShieldType;

    /**
     * Specifies the incidence id.
     */
    id: IncidenceId;

  }): Observable<GetIncidenceDetailsResponse> {

    return this.getIncidenceDetails$Response(params).pipe(
      map((r: StrictHttpResponse<GetIncidenceDetailsResponse>) => r.body as GetIncidenceDetailsResponse)
    );
  }

  /**
   * Path part for operation getImpactedFiles
   */
  static readonly GetImpactedFilesPath = '/shields/{shieldType}/incidences/{id}/data-pools/{dataPoolId}/files';

  /**
   * Get the impacted files within a given data pool for a given shield incidence.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImpactedFiles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImpactedFiles$Response(params: {

    /**
     * Specifies the shield type.
     */
    shieldType: ShieldType;

    /**
     * Specifies the incidence id.
     */
    id: IncidenceId;

    /**
     * Specifies the Data Pool id.
     */
    dataPoolId: DataPoolId;

  }): Observable<StrictHttpResponse<GetFilesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ShieldsApiService.GetImpactedFilesPath, 'get');
    if (params) {

      rb.path('shieldType', params.shieldType, {});
      rb.path('id', params.id, {});
      rb.path('dataPoolId', params.dataPoolId, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetFilesResponse>;
      })
    );
  }

  /**
   * Get the impacted files within a given data pool for a given shield incidence.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getImpactedFiles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImpactedFiles(params: {

    /**
     * Specifies the shield type.
     */
    shieldType: ShieldType;

    /**
     * Specifies the incidence id.
     */
    id: IncidenceId;

    /**
     * Specifies the Data Pool id.
     */
    dataPoolId: DataPoolId;

  }): Observable<GetFilesResponse> {

    return this.getImpactedFiles$Response(params).pipe(
      map((r: StrictHttpResponse<GetFilesResponse>) => r.body as GetFilesResponse)
    );
  }

  /**
   * Path part for operation getAffectedFiles
   */
  static readonly GetAffectedFilesPath = '/shields/{shieldType}/incidences/{id}/affected-files';

  /**
   * Get the affected files reported for given shield incidence.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAffectedFiles$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAffectedFiles$Json$Response(params: {

    /**
     * Specifies the shield type.
     */
    shieldType: ShieldType;

    /**
     * Specifies the incidence id.
     */
    id: IncidenceId;

    /**
     * Specifies the classified patternIds to filter files.
     */
    patternIds?: Array<PatternId>;

    /**
     * Specifies the file change types to filter results.
     */
    changeTypes?: Array<FileChangeType>;

    /**
     * Specifies the pattern sensitivity of files to filter.
     */
    sensitivities?: Array<Range>;

    /**
     * Specifies the format of the output such as csv or json(default).
     */
    outputFormat?: OutputFormat;

    /**
     * Specifies whether we want to populate sensitivity info for files or not.
     */
    includeSensitivityDetails?: boolean;

    /**
     * Specifies the maximum number of file paths returned per response and it should be less that 1000.
     */
    pageSize?: null | number;

    /**
     * Specifies the pagination token used to get next page.
     */
    paginationToken?: PaginationToken;

  }): Observable<StrictHttpResponse<GetAffectedFilesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ShieldsApiService.GetAffectedFilesPath, 'get');
    if (params) {

      rb.path('shieldType', params.shieldType, {});
      rb.path('id', params.id, {});
      rb.query('patternIds', params.patternIds, {});
      rb.query('changeTypes', params.changeTypes, {});
      rb.query('sensitivities', params.sensitivities, {});
      rb.query('outputFormat', params.outputFormat, {});
      rb.query('includeSensitivityDetails', params.includeSensitivityDetails, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('paginationToken', params.paginationToken, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetAffectedFilesResponse>;
      })
    );
  }

  /**
   * Get the affected files reported for given shield incidence.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAffectedFiles$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAffectedFiles$Json(params: {

    /**
     * Specifies the shield type.
     */
    shieldType: ShieldType;

    /**
     * Specifies the incidence id.
     */
    id: IncidenceId;

    /**
     * Specifies the classified patternIds to filter files.
     */
    patternIds?: Array<PatternId>;

    /**
     * Specifies the file change types to filter results.
     */
    changeTypes?: Array<FileChangeType>;

    /**
     * Specifies the pattern sensitivity of files to filter.
     */
    sensitivities?: Array<Range>;

    /**
     * Specifies the format of the output such as csv or json(default).
     */
    outputFormat?: OutputFormat;

    /**
     * Specifies whether we want to populate sensitivity info for files or not.
     */
    includeSensitivityDetails?: boolean;

    /**
     * Specifies the maximum number of file paths returned per response and it should be less that 1000.
     */
    pageSize?: null | number;

    /**
     * Specifies the pagination token used to get next page.
     */
    paginationToken?: PaginationToken;

  }): Observable<GetAffectedFilesResponse> {

    return this.getAffectedFiles$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetAffectedFilesResponse>) => r.body as GetAffectedFilesResponse)
    );
  }

  /**
   * Get the affected files reported for given shield incidence.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAffectedFiles$Csv()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAffectedFiles$Csv$Response(params: {

    /**
     * Specifies the shield type.
     */
    shieldType: ShieldType;

    /**
     * Specifies the incidence id.
     */
    id: IncidenceId;

    /**
     * Specifies the classified patternIds to filter files.
     */
    patternIds?: Array<PatternId>;

    /**
     * Specifies the file change types to filter results.
     */
    changeTypes?: Array<FileChangeType>;

    /**
     * Specifies the pattern sensitivity of files to filter.
     */
    sensitivities?: Array<Range>;

    /**
     * Specifies the format of the output such as csv or json(default).
     */
    outputFormat?: OutputFormat;

    /**
     * Specifies whether we want to populate sensitivity info for files or not.
     */
    includeSensitivityDetails?: boolean;

    /**
     * Specifies the maximum number of file paths returned per response and it should be less that 1000.
     */
    pageSize?: null | number;

    /**
     * Specifies the pagination token used to get next page.
     */
    paginationToken?: PaginationToken;

  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ShieldsApiService.GetAffectedFilesPath, 'get');
    if (params) {

      rb.path('shieldType', params.shieldType, {});
      rb.path('id', params.id, {});
      rb.query('patternIds', params.patternIds, {});
      rb.query('changeTypes', params.changeTypes, {});
      rb.query('sensitivities', params.sensitivities, {});
      rb.query('outputFormat', params.outputFormat, {});
      rb.query('includeSensitivityDetails', params.includeSensitivityDetails, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('paginationToken', params.paginationToken, {});

    }
    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/csv'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Get the affected files reported for given shield incidence.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAffectedFiles$Csv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAffectedFiles$Csv(params: {

    /**
     * Specifies the shield type.
     */
    shieldType: ShieldType;

    /**
     * Specifies the incidence id.
     */
    id: IncidenceId;

    /**
     * Specifies the classified patternIds to filter files.
     */
    patternIds?: Array<PatternId>;

    /**
     * Specifies the file change types to filter results.
     */
    changeTypes?: Array<FileChangeType>;

    /**
     * Specifies the pattern sensitivity of files to filter.
     */
    sensitivities?: Array<Range>;

    /**
     * Specifies the format of the output such as csv or json(default).
     */
    outputFormat?: OutputFormat;

    /**
     * Specifies whether we want to populate sensitivity info for files or not.
     */
    includeSensitivityDetails?: boolean;

    /**
     * Specifies the maximum number of file paths returned per response and it should be less that 1000.
     */
    pageSize?: null | number;

    /**
     * Specifies the pagination token used to get next page.
     */
    paginationToken?: PaginationToken;

  }): Observable<string> {

    return this.getAffectedFiles$Csv$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getSensitivitiyResults
   */
  static readonly GetSensitivitiyResultsPath = '/shields/{shieldType}/incidences/{id}/sensitivity-results';

  /**
   * Get sensitivity results for anomalous incident.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSensitivitiyResults()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSensitivitiyResults$Response(params: {

    /**
     * Specifies the shield type.
     */
    shieldType: ShieldType;

    /**
     * Specifies the incidence id.
     */
    id: IncidenceId;

    /**
     * Specifies classified patternIds to filter files.
     */
    patternIds?: Array<PatternId>;

    /**
     * Filter by the change type.
     */
    changeTypes?: Array<FileChangeType>;

  }): Observable<StrictHttpResponse<GetSensitivityResultsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ShieldsApiService.GetSensitivitiyResultsPath, 'get');
    if (params) {

      rb.path('shieldType', params.shieldType, {});
      rb.path('id', params.id, {});
      rb.query('patternIds', params.patternIds, {});
      rb.query('changeTypes', params.changeTypes, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetSensitivityResultsResponse>;
      })
    );
  }

  /**
   * Get sensitivity results for anomalous incident.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSensitivitiyResults$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSensitivitiyResults(params: {

    /**
     * Specifies the shield type.
     */
    shieldType: ShieldType;

    /**
     * Specifies the incidence id.
     */
    id: IncidenceId;

    /**
     * Specifies classified patternIds to filter files.
     */
    patternIds?: Array<PatternId>;

    /**
     * Filter by the change type.
     */
    changeTypes?: Array<FileChangeType>;

  }): Observable<GetSensitivityResultsResponse> {

    return this.getSensitivitiyResults$Response(params).pipe(
      map((r: StrictHttpResponse<GetSensitivityResultsResponse>) => r.body as GetSensitivityResultsResponse)
    );
  }

  /**
   * Path part for operation getIncidences
   */
  static readonly GetIncidencesPath = '/incidences';

  /**
   * Get the list of detected incidences across shields.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIncidences()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIncidences$Response(params?: {

    /**
     * List of incidences to filter events.
     */
    incidenceIds?: Array<IncidenceId>;

    /**
     * Specifies the shield types to filter.
     */
    shieldTypes?: Array<ShieldType>;

    /**
     * Start time in milli seconds to fetch events.
     */
    startTimeMsecs?: TimestampMsecs;

    /**
     * End time in milli seconds to fetch events.
     */
    endTimeMsecs?: TimestampMsecs;

    /**
     * List of rules to filter events.
     */
    ruleIds?: Array<RuleId>;

    /**
     * Specifies filter list of data source ids.
     */
    dataSourceIds?: Array<DataSourceId>;

    /**
     * Specifies filter list of data pool ids.
     */
    dataPoolIds?: null | Array<DataPoolId>;

    /**
     * Specifies the sensitivity of events to fetch.
     */
    sensitivity?: Array<Range>;

    /**
     * Specifies the risk levels of events to fetch.
     */
    riskLevel?: Array<Range>;

    /**
     * Specifies whether to group incidences by rules.
     */
    groupByRule?: boolean;

    /**
     * Return the cause of anomaly.
     */
    includeAnomalyCause?: boolean;

    /**
     * List of objects to filter events.
     */
    objectIds?: Array<ObjectId>;

    /**
     * Specifies the number of incidences for a page.
     */
    pageSize?: number;

    /**
     * Specifies the pagination token used to get next set of Incidences.
     */
    paginationToken?: PaginationToken;

  }): Observable<StrictHttpResponse<GetIncidencesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ShieldsApiService.GetIncidencesPath, 'get');
    if (params) {

      rb.query('incidenceIds', params.incidenceIds, {});
      rb.query('shieldTypes', params.shieldTypes, {});
      rb.query('startTimeMsecs', params.startTimeMsecs, {});
      rb.query('endTimeMsecs', params.endTimeMsecs, {});
      rb.query('ruleIds', params.ruleIds, {});
      rb.query('dataSourceIds', params.dataSourceIds, {});
      rb.query('dataPoolIds', params.dataPoolIds, {});
      rb.query('sensitivity', params.sensitivity, {});
      rb.query('riskLevel', params.riskLevel, {});
      rb.query('groupByRule', params.groupByRule, {});
      rb.query('includeAnomalyCause', params.includeAnomalyCause, {});
      rb.query('objectIds', params.objectIds, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('paginationToken', params.paginationToken, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetIncidencesResponse>;
      })
    );
  }

  /**
   * Get the list of detected incidences across shields.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getIncidences$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIncidences(params?: {

    /**
     * List of incidences to filter events.
     */
    incidenceIds?: Array<IncidenceId>;

    /**
     * Specifies the shield types to filter.
     */
    shieldTypes?: Array<ShieldType>;

    /**
     * Start time in milli seconds to fetch events.
     */
    startTimeMsecs?: TimestampMsecs;

    /**
     * End time in milli seconds to fetch events.
     */
    endTimeMsecs?: TimestampMsecs;

    /**
     * List of rules to filter events.
     */
    ruleIds?: Array<RuleId>;

    /**
     * Specifies filter list of data source ids.
     */
    dataSourceIds?: Array<DataSourceId>;

    /**
     * Specifies filter list of data pool ids.
     */
    dataPoolIds?: null | Array<DataPoolId>;

    /**
     * Specifies the sensitivity of events to fetch.
     */
    sensitivity?: Array<Range>;

    /**
     * Specifies the risk levels of events to fetch.
     */
    riskLevel?: Array<Range>;

    /**
     * Specifies whether to group incidences by rules.
     */
    groupByRule?: boolean;

    /**
     * Return the cause of anomaly.
     */
    includeAnomalyCause?: boolean;

    /**
     * List of objects to filter events.
     */
    objectIds?: Array<ObjectId>;

    /**
     * Specifies the number of incidences for a page.
     */
    pageSize?: number;

    /**
     * Specifies the pagination token used to get next set of Incidences.
     */
    paginationToken?: PaginationToken;

  }): Observable<GetIncidencesResponse> {

    return this.getIncidences$Response(params).pipe(
      map((r: StrictHttpResponse<GetIncidencesResponse>) => r.body as GetIncidencesResponse)
    );
  }

}
