<mat-form-field>
  <mat-label>{{ label }}</mat-label>
  <input matInput
    [formControl]="control"
    [placeholder]="config?.placeholder ? config?.placeholder : ''">
  <mat-hint *ngIf="config?.description">
    {{ config?.description }}
  </mat-hint>
  <mat-error *ngIf="control.hasError('required')">
    {{ validationErrorFn( 'required', 'string', label, { stringConfig: config } ) }}
  </mat-error>
</mat-form-field>
