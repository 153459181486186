import { Injectable } from '@angular/core';
import { QuorumGroup, QuorumServiceApi } from '@cohesity/api/quorum';
import { IrisContextService, isRpaasUser } from '@cohesity/iris-core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { EnhancedQuorumServiceApiService } from 'src/app/core/services/enhanced-quorum-service-api.service';

/**
 * These are the operation ids used within fortknox
 */
export enum RpaasQouromOperation {
  recover = 'RecoverFromVault',
  clone = 'CloneFromFortKnoxVault',
  recoverMetadata = 'RecoverMetadataFromVault',
}

/**
 * All quorum operations as an array.
 */
const allRpaasQuorumOperations = [
  RpaasQouromOperation.recover,
  RpaasQouromOperation.recoverMetadata,
  RpaasQouromOperation.clone,
];
/**
 * Each quorum operation has an associated categoory. This maps the operaations to their categories.
 */
const operationCategoeries = {
  [RpaasQouromOperation.recover]: 'Recovery',
  [RpaasQouromOperation.recoverMetadata]: 'Recovery',
  [RpaasQouromOperation.clone]: 'Clone',
};

/**
 * State params to pass to the quorum group page to preselect operations.
 */
export const quorumGroupStateParams = {
  operationCategories: [
    operationCategoeries[RpaasQouromOperation.recover],
    operationCategoeries[RpaasQouromOperation.clone],
  ],
  operationIds: allRpaasQuorumOperations,
};

@Injectable({
  providedIn: 'root',
})
export class RpaasQuorumService {
  /**
   * Observable to return relevant rpaas quorum groups.
   */
  rpaasQuorumGroups$: Observable<QuorumGroup[]> = this.enhancedQuorumServiceApiService.reload$.pipe(
    switchMap(() => this.findRpaasQuorumGroups()),
    shareReplay(1)
  );

  /**
   * Observable for whether there are rpaas quorum groups.
   */
  hasRpaasQuorumGroups$: Observable<boolean> = this.hasRpaasQuorumOperations(allRpaasQuorumOperations);

  /**
   * Observable for whether there are at least some groups with FortKnox operations, but we are still missing requried
   * operations.
   */
  hasPartiallyConfiguredGroup$: Observable<boolean> = combineLatest(
    allRpaasQuorumOperations.map(op => this.hasRpaasQuorumOperations([op]))
  ).pipe(map(results => !results.every(op => op) && results.some(op => op)));

  constructor(
    private quorumServiceApi: QuorumServiceApi,
    private enhancedQuorumServiceApiService: EnhancedQuorumServiceApiService,
    private irisContextService: IrisContextService
  ) {}

  /**
   * Check for quorum operations in the rpaas groups
   *
   * @param operations The operations to check
   * @returns Resolves to true if the operation is present.
   */
  hasRpaasQuorumOperations(operations: RpaasQouromOperation[]): Observable<boolean> {
    return this.rpaasQuorumGroups$.pipe(
      map(quorumGroups =>
        operations.every(operation =>
          quorumGroups?.some(group => group.isEnabled && this.hasOperationId(group, operation))
        )
      )
    );
  }

  /**
   * Gets a list of quorum groups that use rpaas recovery operations
   *
   * @returns A list of quorum group detils
   */
  private findRpaasQuorumGroups(): Observable<QuorumGroup[]> {
    return (
      isRpaasUser(this.irisContextService.irisContext)
        ? this.quorumServiceApi.GetQuorumGroups({
          operationIds: allRpaasQuorumOperations,
          includeOperationsDetails: true,
        }) : of({ quorumGroups: [] as QuorumGroup[] })
    ).pipe(
      map(groups =>
        (groups?.quorumGroups || []).filter(
          // Test for the operations separately as they could be handled in separate groups
          group => allRpaasQuorumOperations.some(op => this.hasOperationId(group, op))
        )
      )
    );
  }

  /**
   * Checks a quorum group to determine if is uses a specific operation
   *
   * @param group A quorum group detail
   * @returns True if the group includes the operation
   */
  private hasOperationId(group: QuorumGroup, operationId: RpaasQouromOperation): boolean {
    const category = operationCategoeries[operationId];
    const opGroup = group.operations.find(operationGroup => operationGroup.operationCategory === category);
    return !!opGroup?.operations.find(op => op.id === operationId);
  }
}
