import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@cohesity/api/v1';
import { WindowRef } from '@cohesity/helix';

import { LoginCredentials, AuthType } from '../models';

// Local storage key for storing login data
const loginLocalStorageKey = 'C.loginData';

/**
 * User data from login API.
 */
export type LoginUser = User & {
  forcePasswordChange?: boolean;
};

/**
 * Data returned by Login API.
 */
export interface LoginData {
  /**
   * The group Sids that are assigned to the source.
   */
  groupSids: null;

  /**
   * Privileges of the user's role
   */
  privileges: string[];

  /**
   * User model
   */
  user: LoginUser;
}

/**
 * @description
 * App authentication service.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private windowRef: WindowRef) { }

  /**
   * Returns login data for currently authenticated user.
   */
  get sessionData(): LoginData {
    const userData = localStorage.getItem(loginLocalStorageKey);
    return userData ? JSON.parse(userData) : null;
  }

  /**
   * Returns true if login session data is present.
   */
  isAuthenticated(): boolean {
    return Boolean(localStorage.getItem(loginLocalStorageKey));
  }

  /**
   * This will authenticate the user via SSO mechanism.
   * The SSO provider needs to be configured in the app.
   *
   * Since this method actually redirects the browser location,
   * the Promise it returns will only be rejected if an error
   * is thrown while building the url.
   *
   * @param credentials - The login credentials of the user.
   * @param authType - The sso types are accepted here.
   * @returns Promise
   */
  authenticateSso(credentials: LoginCredentials, authType: Omit<AuthType, 'local' | 'ad'>, redirectUrl: string) {
    return new Promise((_resolve, reject) => {
      try {
        const username = credentials.username;
        const params = [
          username ? 'username=' + encodeURIComponent(username) : '',
          'type=' + (authType === 'sso' ? 'kSso' : 'kSalesforce'),
          redirectUrl ? 'redirectTo=' + redirectUrl : '',
        ].filter(function filterParams(param) {
          return param.length;
        });

        const location = '/mcm/login?' + params.join('&');
        this.windowRef.nativeWindow.location.href = location;
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * Saves provided login data to session storage.
   *
   * @param data Login data to save to session storage.
   */
  private updateLocalStorage(data: LoginData) {
    localStorage.setItem(loginLocalStorageKey, JSON.stringify(data));
  }

  /**
   * Clears login session.
   */
  logout() {
    localStorage.removeItem(loginLocalStorageKey);
    this.http.post('/logout', null).subscribe();
    this.router.navigate(['login']);
  }
}
