/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccessScope } from '../models/access-scope';
import { AccessScopeCreateOrUpdateRequest } from '../models/access-scope-create-or-update-request';
import { AccessScopeId } from '../models/access-scope-id';
import { AccessScopes } from '../models/access-scopes';

@Injectable({
  providedIn: 'root',
})
export class AccessScopesApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAccessScopes
   */
  static readonly GetAccessScopesPath = '/access-scopes';

  /**
   * Gets the list of Access Scopes.
   *
   * This API is used for fetching details of access scopes. Each access scope defines the limited permission model for group of resources and members.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccessScopes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessScopes$Response(params: {

    /**
     * Specifies the unique account identifier of the user&#x27;s Salesforce account.
     */
    account_id: string;

    /**
     * Specifies the unique identifier for the user&#x27;s tenant ID. This field is mandatory if access scopes are fetched for BaaS entities.
     */
    tenant_id?: string;

    /**
     * Specifies comma separated list of identifiers for which the access scopes need to be fetched.
     */
    accessScopeIds?: null | Array<AccessScopeId>;

    /**
     * Specifies the unique member Id of the user for which access scopes need to be returned.
     */
    memberId?: string;

  }): Observable<StrictHttpResponse<AccessScopes>> {

    const rb = new RequestBuilder(this.rootUrl, AccessScopesApiService.GetAccessScopesPath, 'get');
    if (params) {

      rb.header('account_id', params.account_id, {});
      rb.header('tenant_id', params.tenant_id, {});
      rb.query('accessScopeIds', params.accessScopeIds, {});
      rb.query('memberId', params.memberId, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccessScopes>;
      })
    );
  }

  /**
   * Gets the list of Access Scopes.
   *
   * This API is used for fetching details of access scopes. Each access scope defines the limited permission model for group of resources and members.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAccessScopes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessScopes(params: {

    /**
     * Specifies the unique account identifier of the user&#x27;s Salesforce account.
     */
    account_id: string;

    /**
     * Specifies the unique identifier for the user&#x27;s tenant ID. This field is mandatory if access scopes are fetched for BaaS entities.
     */
    tenant_id?: string;

    /**
     * Specifies comma separated list of identifiers for which the access scopes need to be fetched.
     */
    accessScopeIds?: null | Array<AccessScopeId>;

    /**
     * Specifies the unique member Id of the user for which access scopes need to be returned.
     */
    memberId?: string;

  }): Observable<AccessScopes> {

    return this.getAccessScopes$Response(params).pipe(
      map((r: StrictHttpResponse<AccessScopes>) => r.body as AccessScopes)
    );
  }

  /**
   * Path part for operation createAccessScope
   */
  static readonly CreateAccessScopePath = '/access-scopes';

  /**
   * Creates a new access scope.
   *
   * This API creates an access scope for limiting access to fix set of users and resources. The access scope is a logical binding of users (Local users and SSO groups), resources and permissions into a single group which is reusable and extendable.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAccessScope()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAccessScope$Response(params: {

    /**
     * Specifies the unique account identifier of the user&#x27;s Salesforce account.
     */
    account_id: string;

    /**
     * Specifies the unique identifier for the user&#x27;s tenant ID. This field is mandatory if access scope creation involves BaaS entities.
     */
    tenant_id?: string;
      body: AccessScopeCreateOrUpdateRequest
  }): Observable<StrictHttpResponse<AccessScope>> {

    const rb = new RequestBuilder(this.rootUrl, AccessScopesApiService.CreateAccessScopePath, 'post');
    if (params) {

      rb.header('account_id', params.account_id, {});
      rb.header('tenant_id', params.tenant_id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccessScope>;
      })
    );
  }

  /**
   * Creates a new access scope.
   *
   * This API creates an access scope for limiting access to fix set of users and resources. The access scope is a logical binding of users (Local users and SSO groups), resources and permissions into a single group which is reusable and extendable.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createAccessScope$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAccessScope(params: {

    /**
     * Specifies the unique account identifier of the user&#x27;s Salesforce account.
     */
    account_id: string;

    /**
     * Specifies the unique identifier for the user&#x27;s tenant ID. This field is mandatory if access scope creation involves BaaS entities.
     */
    tenant_id?: string;
      body: AccessScopeCreateOrUpdateRequest
  }): Observable<AccessScope> {

    return this.createAccessScope$Response(params).pipe(
      map((r: StrictHttpResponse<AccessScope>) => r.body as AccessScope)
    );
  }

  /**
   * Path part for operation getAccessScopeById
   */
  static readonly GetAccessScopeByIdPath = '/access-scopes/{id}';

  /**
   * Get Access Scope by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccessScopeById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessScopeById$Response(params: {

    /**
     * Specifies the unique account identifier of the user&#x27;s Salesforce account.
     */
    account_id: string;

    /**
     * Specifies the unique identifier for the user&#x27;s tenant ID. This field is mandatory if access scopes are fetched for BaaS entities.
     */
    tenant_id?: string;

    /**
     * The Access Scope Id.
     */
    id: string;

  }): Observable<StrictHttpResponse<AccessScope>> {

    const rb = new RequestBuilder(this.rootUrl, AccessScopesApiService.GetAccessScopeByIdPath, 'get');
    if (params) {

      rb.header('account_id', params.account_id, {});
      rb.header('tenant_id', params.tenant_id, {});
      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccessScope>;
      })
    );
  }

  /**
   * Get Access Scope by ID.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAccessScopeById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessScopeById(params: {

    /**
     * Specifies the unique account identifier of the user&#x27;s Salesforce account.
     */
    account_id: string;

    /**
     * Specifies the unique identifier for the user&#x27;s tenant ID. This field is mandatory if access scopes are fetched for BaaS entities.
     */
    tenant_id?: string;

    /**
     * The Access Scope Id.
     */
    id: string;

  }): Observable<AccessScope> {

    return this.getAccessScopeById$Response(params).pipe(
      map((r: StrictHttpResponse<AccessScope>) => r.body as AccessScope)
    );
  }

  /**
   * Path part for operation updateAccessScopeById
   */
  static readonly UpdateAccessScopeByIdPath = '/access-scopes/{id}';

  /**
   * Update Access Scope using an Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAccessScopeById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAccessScopeById$Response(params: {

    /**
     * Specifies the unique account identifier of the user&#x27;s Salesforce account.
     */
    account_id: string;

    /**
     * Specifies the unique identifier for the user&#x27;s tenant ID. This field is mandatory if access scopes are fetched for BaaS entities.
     */
    tenant_id?: string;

    /**
     * The Access Scope Id.
     */
    id: string;
      body: AccessScopeCreateOrUpdateRequest
  }): Observable<StrictHttpResponse<AccessScope>> {

    const rb = new RequestBuilder(this.rootUrl, AccessScopesApiService.UpdateAccessScopeByIdPath, 'put');
    if (params) {

      rb.header('account_id', params.account_id, {});
      rb.header('tenant_id', params.tenant_id, {});
      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccessScope>;
      })
    );
  }

  /**
   * Update Access Scope using an Id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateAccessScopeById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAccessScopeById(params: {

    /**
     * Specifies the unique account identifier of the user&#x27;s Salesforce account.
     */
    account_id: string;

    /**
     * Specifies the unique identifier for the user&#x27;s tenant ID. This field is mandatory if access scopes are fetched for BaaS entities.
     */
    tenant_id?: string;

    /**
     * The Access Scope Id.
     */
    id: string;
      body: AccessScopeCreateOrUpdateRequest
  }): Observable<AccessScope> {

    return this.updateAccessScopeById$Response(params).pipe(
      map((r: StrictHttpResponse<AccessScope>) => r.body as AccessScope)
    );
  }

  /**
   * Path part for operation deleteAccessScopeById
   */
  static readonly DeleteAccessScopeByIdPath = '/access-scopes/{id}';

  /**
   * Deletes an access scope.
   *
   * This API is used for deleting an access scope. If given access scope has members assigned then it cannot be deleted. All members must be removed before deleting an access scope.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAccessScopeById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAccessScopeById$Response(params: {

    /**
     * Specifies the unique account identifier of the user&#x27;s Salesforce account.
     */
    account_id: string;

    /**
     * Specifies the unique identifier for the user&#x27;s tenant ID. This field is mandatory if access scopes are fetched for BaaS entities.
     */
    tenant_id?: string;

    /**
     * The Access Scope Id.
     */
    id: string;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccessScopesApiService.DeleteAccessScopeByIdPath, 'delete');
    if (params) {

      rb.header('account_id', params.account_id, {});
      rb.header('tenant_id', params.tenant_id, {});
      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deletes an access scope.
   *
   * This API is used for deleting an access scope. If given access scope has members assigned then it cannot be deleted. All members must be removed before deleting an access scope.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAccessScopeById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAccessScopeById(params: {

    /**
     * Specifies the unique account identifier of the user&#x27;s Salesforce account.
     */
    account_id: string;

    /**
     * Specifies the unique identifier for the user&#x27;s tenant ID. This field is mandatory if access scopes are fetched for BaaS entities.
     */
    tenant_id?: string;

    /**
     * The Access Scope Id.
     */
    id: string;

  }): Observable<void> {

    return this.deleteAccessScopeById$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
