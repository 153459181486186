/**
 * Target type to helix icon name mappings.
 * This is used in external targets report.
 */
export const targetTypeIconMap: {[key: string]: string} = {
  Amazon: 'helix:object-aws',
  AmazonC2S: 'helix:object-aws',
  AmazonS3StandardIA: 'helix:object-aws',
  AWSGovCloud: 'helix:object-aws',
  Azure: 'helix:object-azure',
  AzureArchive: 'helix:object-azure',
  AzureGovCloud: 'helix:object-azure',
  AzureStandard: 'helix:object-azure',
  Coldline: 'helix:object-gcp',
  Glacier: 'helix:object-aws',
  Google: 'helix:object-gcp',
  GoogleDRA: 'helix:object-gcp',
  GoogleStandard: 'helix:object-gcp',
  NAS: 'helix:nas',
  Nearline: 'helix:object-gcp',
  Oracle: 'helix:object-oracle',
  OracleTierArchive: 'helix:object-oracle',
  OracleTierStandard: 'helix:object-oracle',
  QStarTape: 'helix:tape',
  S3: 'helix:object-aws',
  S3Compatible: 'helix:object-aws',
};
