import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UdaConnectParams } from '@cohesity/api/v1';
import { KeyValuePair } from '@cohesity/api/v2';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from 'ngx-sub-form';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomArgDisplayEntry, getCustomArgumentsListForDisplay } from 'src/app/shared';
import { UdaConnectorConfigService } from 'src/app/shared/uda/services';

/**
 * UDA source details settings components. Used for displaying the registered
 * source's settings for a managed UDA source. A managed source is driven via
 * UDA dynamic config.
 */
@Component({
  selector: 'coh-uda-managed-source-details-settings',
  templateUrl: './managed-source-details-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UdaManagedSourceDetailsSettingsComponent extends AutoDestroyable implements OnInit {

  /**
   * Label to display for the UDA source type.
   */
  sourceTypeLabel: string;

  /**
   * List of custom arguments for display. Each entry contains a pre-translated
   * label for the argument and a value for it.
   */
  customArguments$: Observable<CustomArgDisplayEntry[]>;

  /**
   * Whether UDA Dynamic Config feature flag is enabled or not.
   */
  isDynamicConfigFeatureEnabled = flagEnabled(this.contextService.irisContext, 'udaDynamicConfig');

  disableSourceArgs$: Observable<boolean>;
  /**
   * Getter for retrieving udaParams from source input.
   */
  @Input() udaParams: UdaConnectParams;

  constructor(
    private udaConfigService: UdaConnectorConfigService,
    private contextService: IrisContextService,
    private translate: TranslateService,
  ) {
    super();
  }

  ngOnInit() {

    // If 'udaDynamicConfig' feature flag is enabled, fetch the source type label
    // from the UDA config service, else use the legacy hard coded labels.
    if (this.isDynamicConfigFeatureEnabled) {
      this.udaConfigService.udaSourceTypeToLabel$
        .pipe(takeUntilDestroyed(this))
        .subscribe((sourceTypeToLabelMap) => {
          this.sourceTypeLabel = sourceTypeToLabelMap.get(this.udaParams?.sourceType);
        });
    } else {
      this.sourceTypeLabel = this.translate.instant(`enum.sourceName.${this.udaParams.sourceType}`);
    }
    if (this.isDynamicConfigFeatureEnabled && (this.udaParams.capabilities?.dynamicConfig ?? false)) {
      const dynamicConfig$ = this.udaConfigService.udaSourceHostOsTypeToConfigs$.pipe(
        map((configMap) => {
          const sourceType = this.udaParams.sourceType;
          const hostOsType = this.udaParams.hostType;
          // Fetch the dynamic registration form configuration for the source
          // type + host OS type combination.
          return configMap.get(sourceType + hostOsType);
        })
      );
      this.disableSourceArgs$ = dynamicConfig$.pipe(
        map((config) => {
          const primaryFields = config?.registrationWorkflow?.primaryFields;
          const disableSourceArgs = (primaryFields?.sourceRegistrationArgs?.hidden ?? false);
          return disableSourceArgs;
        })
      );
      this.customArguments$ = dynamicConfig$.pipe(
        map((config) => {
          const dynamicForm = config?.registrationWorkflow?.dynamicForm;
          const customArgs = getCustomArgumentsListForDisplay(
            dynamicForm,
            this.udaParams?.sourceRegistrationArguments as KeyValuePair[]
          );
          return customArgs;
        })
      );
    } else {
      this.disableSourceArgs$ = of(false);
      this.customArguments$ = of([]);
    }
  }
}
