// Component: Add New CE Group

;(function(angular) {
  'use strict';

  angular.module('C.cloudEditionManager')
    .component('cloudEditionNewGroup', {
      require: {
        cModalHoc: '^^?cModalHoc',
      },
      bindings: {
        /**
         * @type  {String}  The placeholder string for input
         */
        placeholder: '<',

        /**
         * @type  {String}  The label for input
         */
        label: '<',

        /**
         * @type  {String}  The type of cloud (Aws/Azure)
         */
        cloud: '<',

        /**
         * @type  {String}  The type of group to be created
         */
        group: '<',

        /**
         * @type  {String[]} The list of groups already available
         */
        availableGroups: '<'
      },
      controller: 'cloudEditionNewGroupCtrl',
      templateUrl:
        'app/platform/cloud-edition-manager/new-group.html',
    })
    .controller('cloudEditionNewGroupCtrl',
      cloudEditionNewGroupCtrlFn);

  /**
   * @ngdoc component
   * @name C.cloudEditionManager:cloudEditionNewGroup
   * @function
   *
   * @description
   * Add a new CE Group
   */
  function cloudEditionNewGroupCtrlFn(_) {
    var $ctrl = this;

    _.assign($ctrl, {
      submitButtonText: 'yes',
      confirmed: false,

      // methods
      cancel: cancel,
      isGroupNameValid: isGroupNameValid,
      processForm: processForm,
    });

    /**
     * Add new Security Group
     *
     * @method  processForm
     * @param   {object}  form   The form object to process
     */
    function processForm(form) {
      if (!form.$valid) {
        return false;
      }

      $ctrl.cModalHoc.save($ctrl.groupName);
    }

    /**
     * Cancel form submission and close the modal
     *
     * @method   cancel
     */
    function cancel() {
      $ctrl.cModalHoc.close();
    }

    /**
     * Is the entered group name valid
     *
     * @method  isGroupNameValid
     * @param   {String}  groupName   The groupName just added
     * @return  {Boolean} True if groupName is valid. False otherwise.
     */
    function isGroupNameValid(groupName) {
      return !(_.find($ctrl.availableGroups || [],
        (group) => group === groupName));
    }
  }

})(angular);