<h3>{{ 'heliosClaim.title' | translate }}</h3>
<form *ngIf="heliosStatus$ | async as heliosStatus; else spinner"
  class="form"
  [formGroup]="formGroup"
  (ngSubmit)="onSubmit()">
  <ng-container *ngIf="heliosStatus?._isNotRegistered; else connected">
    <span>{{ 'heliosClaim.message' | translate }}</span>
    <mat-form-field class="token-form-field">
      <mat-label>{{ 'heliosClaim.tokenInput' | translate }}</mat-label>
      <input type="text"
        matInput
        cogDataId="claim-token-input"
        formControlName="tokenInput">
      <mat-hint>
        {{'heliosClaim.tokenHint' | translate}}
      </mat-hint>
    </mat-form-field>
  </ng-container>
  <mat-divider></mat-divider>
  <h4>{{ 'heliosClaim.heliosLicenseTitle' | translate }}</h4>
  <mat-radio-group formControlName="heliosPermission"
    class="helios-permissions-radio-group">
    <mat-radio-button [value]="true"
      cogDataId="full-permissions-option">
      <div>{{ 'full' | translate }}</div>
      <mat-hint class="radio-hint">
        {{ 'heliosClaim.fullPermissionHint' | translate }}
      </mat-hint>
    </mat-radio-button>
    <mat-radio-button [value]="false"
      cogDataId="view-only-permissions-option">
      <div>{{ 'viewOnly' | translate }}</div>
      <mat-hint class="radio-hint">
        {{ 'heliosClaim.viewOnlyHint' | translate }}
      </mat-hint>
    </mat-radio-button>
  </mat-radio-group>
  <mat-divider></mat-divider>
  <div>
    <mat-slide-toggle formControlName="clusterPermission"
      cogDataId="view-only-permissions-toggle">
      {{ 'heliosClaim.viewOnlyPermissions' | translate }}
    </mat-slide-toggle>
    <mat-hint>
      {{'heliosClaim.viewOnlyPermissionsHint' | translate}}
    </mat-hint>
  </div>
  <mat-divider></mat-divider>
  <button *ngIf="heliosStatus?._isNotRegistered"
    type="submit"
    cogDataId="save-button"
    mat-flat-button
    color="primary"
    [disabled]="submitting$ | async">
    {{ 'connect' | translate }}
  </button>
</form>

<ng-template #spinner>
  <cog-spinner></cog-spinner>
</ng-template>

<ng-template #connected>
  <ng-container *ngIf="heliosStatus$ | async as heliosStatus">
    <div class="connection-status" [ngSwitch]="heliosStatus.registrationStatus">
      <ng-container *ngSwitchCase="'kRegistrationNotDone'">
        <cog-icon shape="helix:status-error!critical">
        </cog-icon>
        {{ 'disconnected' | translate }}
      </ng-container>
      <ng-container *ngSwitchCase="'kRegistrationCompleted'">
        <cog-icon shape="helix:status-success!success">
        </cog-icon>
        {{ 'connected' | translate }}
      </ng-container>
      <ng-container *ngSwitchCase="'kRegistrationInProgress'">
        <cog-icon shape="helix:status-in-progress">
        </cog-icon>
        {{ 'connectingToHelios' | translate }}
      </ng-container>
    </div>
  </ng-container>
</ng-template>
