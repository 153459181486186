import { Component, Input } from '@angular/core';
import { NavState, NavStateService } from './nav-state.service';

/**
 * Protect action button for adding protection on dashboard or landing page.
 *
 * @example
 *   <coh-protect-button></coh-protect-button>
 */
@Component({
  selector: 'coh-protect-button',
  templateUrl: './protect-button.component.html',
})
export class ProtectButtonComponent {
  /**
   * Specifies the protect button style. Default to mat-raised-button.
   */
  @Input() buttonStyle = 'mat-flat-button';

  /**
   * Specifies the custom button label. If custom label is not provided,
   * use standard button label.
   */
  @Input() customButtonLabel: string;

  /**
   * Indicates if the button should be displayed as a fab or standard button.
   */
  @Input() isFab = false;

  /**
   * The region id to use for data protection.
   */
  @Input() regionId: string;

  /**
   * List of all sources which can be protected.
   */
  sources: NavState[];

  /**
   * Constructor.
   */
  constructor(
    private navStateService: NavStateService
  ) {
    this.sources = this.navStateService.getProtectStates();
  }

  /**
   * Return state params for a source from a source state. This function adds
   * regionId state param for the given source.
   *
   * @param sourceState The source state.
   * @returns The state params for the source.
   */
  getSourceStateParams(sourceState: NavState) {
    return {
      ...sourceState.stateParams,
      regionId: this.regionId,
    };
  }
}
