import { FortKnoxColdInfo, RansomwareInfo } from '@cohesity/api/v1';

import { IrisContext } from './iris-context.model';

/**
 * Returns the RPaaS warm storage subscription quantity.
 *
 * @param irisContext The current iris context.
 * @returns the total quantity purchased for the subscription
 */
export function rpaasWarmStorageQuantity(irisContext: IrisContext): null | number {
  const subscription = awsWarmStorageEntitlement(irisContext);

  // Quantity does not currently exist in our yaml, but the API returns it.
  return (subscription as any)?.quantity;
}

/**
 * Returns the RPaaS cold storage subscription quantity.
 *
 * @param irisContext The current iris context.
 * @returns the total quantity purchased for the subscription
 */
export function rpaasColdStorageQuantity(irisContext: IrisContext): number {
  return awsColdStorageEntitlement(irisContext)?.quantity;
}

/**
 * Returns the tenant ID for a given RpaaS ID.
 *
 * @param  irisContext  The current iris context.
 * @return The tenant ID if a RpaaS user or null.
 */
export function rpaasTenantId(irisContext: IrisContext): null | string {
  if (!isRpaasUser(irisContext)) {
    return undefined;
  }

  return irisContext.user.profiles?.[0].tenantId ?? null;
}

/**
 * Returns true if the current user is in RPaaS scope.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if the user in RPaaS context.
 */
export function isRpaasScope(irisContext: IrisContext): boolean {
  return Boolean(isRpaasUser(irisContext) && irisContext?.selectedScope?._serviceType === 'rpaas');
}

/**
 * Whether the cluster is a rpaas preview cluster.
 *
 * @returns Whether the cluster is a fortknox preview cluster.
 */
export function isRpaasPreviewCluster(irisContext: IrisContext): boolean {
  return Boolean(irisContext?.clusterInfo?.clusterSoftwareVersion?.includes('fortknox_preview'));
}

/**
 * Indicates if the DMaaS user is signed up via AWS subscription.
 *
 * @param irisContext The current iris context.
 * @returns True if DMaaS user is subscribed via AWS.
 */
export function isRpaasAwsSubscription(irisContext: IrisContext): boolean {
  // Casting to any as yaml does not yet have full entitlements populated.
  const entitlement: any = awsWarmStorageEntitlement(irisContext);

  return !!entitlement?.isAwsSubscription;
}

/**
 * Indicates if the DMaaS user is signed up via Cohesity Purchase Order.
 *
 * @param irisContext The current iris context.
 * @returns True if DMaaS user is signed up via Cohesity PO.
 */
export function isRpaasCohesitySubscription(irisContext: IrisContext): boolean {
  // Casting to any as yaml does not yet have full entitlements populated.
  const entitlement: any = awsWarmStorageEntitlement(irisContext);

  return entitlement?.isCohesitySubscription;
}

/**
 * Indicates if the RPaaS user has AWS Warm storage free trial.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if user is RPaaS user and using a free trial.
 */
export function isAwsWarmFreeTrialUser(irisContext: IrisContext): boolean {
  const entitlement = awsWarmStorageEntitlement(irisContext);
  return entitlement?.isActive && !!entitlement?.isFreeTrial;
}

/**
 * Indicates if the RPaaS user has AWS Cold storage free trial.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if user is RPaaS user and using a free trial.
 */
export function isAwsColdFreeTrialUser(irisContext: IrisContext): boolean {
  const entitlement = awsColdStorageEntitlement(irisContext);
  return entitlement?.isActive && !!entitlement?.isFreeTrial;
}

/**
 * Indicates if the RPaaS user has Azure Hot storage free trial.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if user is RPaaS user and using a free trial.
 */
export function isAzureHotFreeTrialUser(irisContext: IrisContext): boolean {
  const entitlement = azureHotStorageEntitlement(irisContext);
  return entitlement?.isActive && !!entitlement?.isFreeTrial;
}

/**
 * Indicates if the RPaaS user has Azure Cold storage free trial.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if user is RPaaS user and using a free trial.
 */
export function isAzureColdFreeTrialUser(irisContext: IrisContext): boolean {
  const entitlement = azureColdStorageEntitlement(irisContext);
  return entitlement?.isActive && !!entitlement?.isFreeTrial;
}

/**
 * Indicates if the RPaaS user is currently using a free trial.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if user is RPaaS user and using a free trial.
 */
export function isRpaasFreeTrialUser(irisContext: IrisContext): boolean {
  return isAwsWarmFreeTrialUser(irisContext) || isAwsColdFreeTrialUser(irisContext) ||
    isAzureColdFreeTrialUser(irisContext) || isAzureHotFreeTrialUser(irisContext);
}

/**
 * Returns true if the current user is a RPaas (Ransomware Protection) configured user.
 *
 * @param irisContext The current iris context.
 * @returns boolean True if user is RPaaS user.
 */
export function isRpaasUser(irisContext: IrisContext): boolean {
  return Boolean(irisContext?.user?.salesforceAccount?.isRPaaSUser);
}

/**
 * Provides details for FortKnox AWS warm storage subscription.
 *
 * @param irisContext The current iris context.
 * @returns AWS Warm subscription details.
 */
export function awsWarmStorageEntitlement(irisContext: IrisContext): RansomwareInfo | null {
  return irisContext?.user?.subscriptionInfo?.ransomware;
}

/**
 * Provides details for FortKnox AWS cold storage subscription.
 *
 * @param irisContext The current iris context.
 * @returns AWS Cold Storage details.
 */
export function awsColdStorageEntitlement(irisContext: IrisContext): FortKnoxColdInfo {
  return irisContext?.user?.subscriptionInfo?.fortKnoxCold;
}

/**
 * Provides details for Azure cold storage subscription.
 *
 * @param irisContext The current iris context.
 * @returns Azure Cold Storage details.
 */
export function azureColdStorageEntitlement(irisContext: IrisContext): FortKnoxColdInfo {
  return irisContext?.user?.subscriptionInfo?.fortKnoxAzureCool;
}

/**
 * Provides details for Azure hot storage subscription.
 *
 * @param irisContext The current iris context.
 * @returns Azure Hot Storage details.
 */
export function azureHotStorageEntitlement(irisContext: IrisContext): FortKnoxColdInfo {
  return irisContext?.user?.subscriptionInfo?.fortKnoxAzureHot;
}

/**
 * Function to return whether the logged-in user is a paid rpaas user.
 *
 * @param irisContext The iris context.
 * @return True if paid rpaas user.
 */
export function isPaidRpaasUser(irisContext: IrisContext): boolean {
  return isRpaasUser(irisContext) && !isRpaasFreeTrialUser(irisContext);
}
