import { NgModule } from '@angular/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import {
  LegacyDateAdapter as DateAdapter,
  MAT_LEGACY_DATE_FORMATS as MAT_DATE_FORMATS,
  MAT_LEGACY_DATE_LOCALE as MAT_DATE_LOCALE,
} from '@angular/material/legacy-core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { DialogService } from 'src/app/core/services';
import { CalendarModule, SharedModule, SourceTreeModule } from 'src/app/shared';
import { DatePipeWrapper, HumanizeDurationPipe } from 'src/app/shared/pipes';
import {
  GroupActionModalComponent,
  GroupActionTargetInfoComponent,
  GroupArchiveFormComponent,
  GroupBackupFormComponent,
  GroupCloudVaultFormComponent,
  GroupReplicationFormComponent,
  ObjectsSelectDialogComponent,
  ObjectsSelectTreeComponent,
  ProtectionGroupDeleteDialogComponent,
  ProtectionRunDeleteDialogComponent,
  ProtectionRunsComponent,
  RunProgressComponent,
  RunsLimitBannerComponent,
  RunsListComponent,
  RunsListItemComponent,
  RunsMenuComponent,
  RunsTableComponent,
  RunStatusComponent,
  SnapshotDeletedComponent,
  StatsContainerComponent
} from './components';

const components = [
  GroupActionModalComponent,
  GroupActionTargetInfoComponent,
  GroupArchiveFormComponent,
  GroupBackupFormComponent,
  GroupCloudVaultFormComponent,
  GroupReplicationFormComponent,
  ObjectsSelectDialogComponent,
  ObjectsSelectTreeComponent,
  ProtectionGroupDeleteDialogComponent,
  ProtectionRunDeleteDialogComponent,
  ProtectionRunsComponent,
  RunProgressComponent,
  RunsLimitBannerComponent,
  RunsListComponent,
  RunsListItemComponent,
  RunsMenuComponent,
  RunsTableComponent,
  RunStatusComponent,
  SnapshotDeletedComponent,
  StatsContainerComponent
];

@NgModule({
  declarations: components,
  exports: components,
  providers: [
    DatePipeWrapper,
    DialogService,
    HumanizeDurationPipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [
        MAT_DATE_LOCALE,
        MAT_MOMENT_DATE_ADAPTER_OPTIONS
      ],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS,
    },
  ],
  imports: [
    CalendarModule,
    CohesityHelixModule,
    SharedModule,
    SourceTreeModule,
  ],
})
export class ProtectionGroupSharedModule {}
