// Service: IDP Service
import { isEntityOwner } from '@cohesity/iris-core';

;(function(angular) {
  'use strict';

  angular
    .module('C')
    .service('IdpService', IdpServiceFn);

  function IdpServiceFn(_, $http, API, cModal, cMessage, evalAJAX,
    ClusterService, IdpServiceFormatter, UserService, NgIrisContextService, cUtils) {
    return {
      createExternalIdp: createExternalIdp,
      deleteExternalIdp: deleteExternalIdpModal,
      getAllExternalIdp: getAllExternalIdp,
      getOpenId: getOpenId,
      addPrincipals: addPrincipals,
      getExternalIdp: getExternalIdp,
      updateEnableStatusForIdp: updateEnableStatusForIdp,
      updateExternalIdp: updateExternalIdp,
    };

    /**
     * Retrieves all the external IDP from the system
     *
     * @method   getExternalIdp
     * @param    {Object}   params   The API Parameters
     * @return   {Object}   Promise that returns list of IDP's or an error
     */
    function getAllExternalIdp(params) {
      params = params || {};
      cUtils.selfOrDefault(params, 'allUnderHierarchy', true);
      return $http({
        method: 'get',
        url: API.public('idps'),
        params: params,
      }).then(function gotExternalIdp(resp) {
        return _.map(resp.data, _transformIdp);
      }, evalAJAX.errorMessage);
    }

    /**
     * Retrieves all OpenIds.
     *
     * @method   getOpenId
     * @return   {Object}   Promise that returns list of OpendIDs or an error
     */
    function getOpenId() {
      return $http({
        method: 'get',
        url: API.publicV2('identity-providers'),
        params: {},
      }).then(function gotOpenIds(resp) {
        return _.map(resp.data.idps || [], _transformIdp);
      }, evalAJAX.errorMessage);
    }

    /**
     * Add IDP principals. This is a consolidated service which handles the
     * individual createUser and createGroup services on the backend.
     *
     * @method     addPrincipals
     * @param      {Array}    principals  to be added
     * @return     {Object}  promise to resolve the request. resolves with
     *                        list of added principals, rejects with raw
     *                        server response
     */
    function addPrincipals(principals) {
      return $http({
        method: 'post',
        url: API.public('idps/principals'),
        data: principals,
      }).then(function addPrincipalsSuccess(resp) {
        return Array.isArray(resp.data) ? resp.data : [];
      });
    }

    /**
     * Retrieves a external IDP based on the id
     *
     * @method   getExternalIdp
     * @param    {Object}    [params]    Parameters for IDP Configuration
     * @return   {Object}    Promise that returns the matching IDP or an error
     */
    function getExternalIdp(params) {
      return $http({
        method: 'get',
        url: API.public('idps'),
        params: params,
      }).then(function gotExternalIdp(resp) {
        return _transformIdp(resp.data[0] || {});
      }, evalAJAX.errorMessage);
    }

    /**
     * Create an External Identity Provider
     *
     * @method   createExternalIdp
     * @param    {Object}    config   The IDP Configuration
     * @return   {Object}    Promise that returns the newly created IDP or error
     */
    function createExternalIdp(config) {
      return $http({
        method: 'post',
        url: API.public('idps'),
        data: IdpServiceFormatter.untransformIdpConfig(config),
      }).then(_updateBasicClusterInfo).then(function createIdpSuccess(response) {
        return response.data || {};
      });
    }

    /**
     * Deletes an External Identity Provider
     *
     * @method   deleteExternalIdpModal
     * @param    {Object}    idp   The IDP to be deleted
     * @return   {Object}    Promise that returns the deleted IDP or error
     */
    function deleteExternalIdpModal(idp) {
      var options = {
        titleKey: 'sso.deleteTitle',
        contentKey: 'sso.deleteContent',
        actionButtonKey: 'delete',
        closeButtonKey: 'cancel',
      };

      return cModal.standardModal({}, options).then(function deleteIdp() {
        return $http({
          method: 'delete',
          url: API.public('idps', idp.id),
        }).then(_updateBasicClusterInfo).then(
          function successfullyDeleted(resp) {
            cMessage.success({
              textKey: 'sso.deletedContent',
              textKeyContext: {
                sso: idp.name,
                tenant: idp._tenantName,
              },
            });
            return resp.data;
          }, evalAJAX.errorMessage);
      });
    }

    /**
     * Updates an External Identity Provider
     *
     * @method   updateExternalIdp
     * @param    {Object}    config          The IDP Configuration
     * @param    {boolean}   skipTransform   Skip transform of config object
     * @return   {Object}    Promise that returns the newly created IDP or error
     */
    function updateExternalIdp(config, skipTransform) {
      return $http({
        method: 'put',
        url: API.public('idps', config.id),
        data: IdpServiceFormatter.untransformIdpConfig(config),
      }).then(function updateIdpSuccess(response) {
        return response.data || {};
      }, evalAJAX.errorMessage);
    }

    /**
     * Updates the enable status of the SSO
     *
     * @method   updateEnableStatusForIdp
     * @param    {Object}    config          The IDP Configuration
     * @return   {Object}    Promise that returns the newly created IDP or error
     */
    function updateEnableStatusForIdp(config) {
      return $http({
        method: 'put',
        url: API.public('idps', config.id),
        data: config,
      }).then(function updateIdpSuccess(response) {
        return response.data || {};
      }, evalAJAX.errorMessage);
    }

    /**
     * Transforms the IDP by adding extra keys to it.
     *
     * @method   _transformIdp
     * @param    {Object}   idp   The IDP to transform.
     * @return   {Object}   Transformed IDP.
     */
    function _transformIdp(idp) {
      return _.assign(idp, {
        // add the key _isIdpOwner if the IDP is owned by the logged in user.
        _isIdpOwner: isEntityOwner(NgIrisContextService.irisContext, idp.tenantId),
      });
    }

    /**
     * Pass through function for IDP update calls which updates
     * basicClusterInfo, as it caches the flag "idpConfigured", which is used to
     * determine if an SSO Principal can be added
     *
     * @param      {object}  response    The server response from API call
     * @return     {object}  same server response
     */
    function _updateBasicClusterInfo(response) {
      ClusterService.getBasicClusterInfo(true);
      return response;
    }
  }
})(angular);
