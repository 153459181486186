import { Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { BannerStatus } from './banner.model';
import { IconModule } from '../icon/icon.module';
import { NgIf } from '@angular/common';

export const statusToIconMap = {
  info: 'helix:status-info!info',
  success: 'helix:status-success!success',
  warn: 'helix:status-warning!warning',
  critical: 'helix:status-error!critical',
};

export const statusToClassNameMap = {
  info: 'cog-icon-info',
  success: 'cog-icon-success',
  warn: 'cog-icon-warning',
  critical: 'cog-icon-critical',
};

@Component({
  selector: 'cog-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf, IconModule]
})
export class BannerComponent {
  /**
   * The desired styling/coloring of the banner.
   */
  @Input() status: BannerStatus = 'info';

  /**
   * Applies the appopriate class for the requested style.
   */
  @HostBinding('class')
  get classString(): string {
    return this.status;
  }

  /**
   * Applies class for hiding the banner icon if configured.
   */
  @HostBinding('class.hide-icon')
  @Input() hideIcon = false;

  /**
   * Flag for close icon
   */
  @Input() hideCloseIcon = true;

  /**
   * Provides a shape string for use with cogIconComponent.
   */
  get iconShape(): string {
    return statusToIconMap[this.status];
  }

  /**
   * Optional title.
   * NOTE: UX mocks don't show a title. Consider eliminating this before it gets used.
   */
  @Input() title: string;

  /**
   * Event Emitter for whenever the close icon is clicked
   */
  @Output() bannerClosed = new EventEmitter<void>();
}
