<mat-form-field class="{{className}}" floatLabel="{{floatLabel}}">
  <mat-label>{{'nisProvider' | translate}}</mat-label>
  <mat-select placeholder="{{'nisProvider' | translate}}" [formControl]="control" cogDataId="select-nis-provider">
    <mat-option *ngFor="let nisProvider of allNisProviders"
      [disabled]="editDisabled"
      [inheritDataId]="true"
      [cogDataId]="nisProvider.domain"
      [value]="nisProvider.domain">
      {{nisProvider.domain}}
    </mat-option>
    <mat-option *ngIf="!editDisabled" cogDataId="add-nis-provider" [value]="control.value" (click)="addNISProvider()">
      <coh-add-item-option>
        {{ 'addNisProvider' | translate }}
      </coh-add-item-option>
    </mat-option>
  </mat-select>
</mat-form-field>
