<div [formGroup]="formGroup">
  <!-- Current Password -->
  <mat-form-field *ngIf="confirmCurrentPassword" cogDataId="current-password-field">
    <mat-label translate>currentPassword</mat-label>
    <input cogDataId="current-password" [inheritDataId]="true"
      matInput type="password" [formControlName]="formControlNames.currentPassword">
    <mat-error *ngIf="formGroupErrors?.currentPassword?.required">
      {{'errors.required' | translate}}
    </mat-error>
  </mat-form-field>

  <!-- New Password -->
  <mat-form-field class="margin-top-lg" cogDataId="new-password-field">
    <mat-label translate>newPassword</mat-label>
    <input cogDataId="new-password" [inheritDataId]="true" matInput type="password"
      [formControlName]="formControlNames.newPassword">
    <button *ngIf="passwordRulesPopover" matSuffix mat-icon-button
      type="button"
      cogDataId="password-rule-popover-button"
      [cogPopover]="passwordRulesPopover"
      [popoverData]="popoverData">
      <mat-icon color="primary">info</mat-icon>
    </button>

    <mat-error *ngIf="formGroupErrors?.newPassword?.required">
      {{'errors.required' | translate}}
    </mat-error>
    <mat-error *ngIf="formGroupErrors?.newPassword?.minlength"
      cogDataId="new-password-error-length"
      [inheritDataId]="true">
      <!-- If minPasswordLength is provided, it is used to pass to get translation string
        else, default password error is thrown. -->
      <span *ngIf="minPasswordLength">
        {{ 'passwordMinNum' | translate: { minPassword: minPasswordLength }  }}
      </span>
      <span *ngIf="!minPasswordLength" translate="accessEdit.error.passwordMin"></span>
    </mat-error>
  </mat-form-field>

  <!-- Confirm Password -->
  <mat-form-field class="margin-top-lg" cogDataId="confirm-password-field">
    <mat-label translate>confirmPassword</mat-label>
    <input cogDataId="confirm-password" [inheritDataId]="true"
      matInput type="password" [formControlName]="formControlNames.confirmPassword" [errorStateMatcher]="confirmPasswordMatcher">
    <mat-error *ngIf="formGroupErrors?.formGroup?.passwordsMustMatch"
      cogDataId="confirm-password-invalid" [inheritDataId]="true">
      {{ 'errors.password.confirmMismatch' | translate }}
    </mat-error>
  </mat-form-field>
</div>
