// Component: App review and rating section

; (function (angular, undefined) {
  'use strict';

  angular.module('C.appsManagement')
  .controller('AppReviewRatingCtrl', AppReviewRatingCtrlFn)
  .component('reviewRatingModal', {
    templateUrl: 'app/apps-management/app-store/review-modal/review-modal.html',
    controller: 'AppReviewRatingCtrl',
    bindings: {
      /**
       * App information required to post review.
       *
       * @type {object}
       */
      app: '=',
    },
    require: {
      cModalHoc: '^^?cModalHoc',
    }
  });

  /**
   * @ngdoc component
   * @name C.appsManagement:reviewRatingModal
   * @function AppReviewRatingCtrlFn
   *
   * @description
   * Provides review and rating functionality to an application.
   *
   * @example
     <review-rating-modal
       data="$ctrl.appDetails">
     </review-rating-modal>
   */
  function AppReviewRatingCtrlFn(_, AppStoreService, cMessage, evalAJAX) {
    var $ctrl = this;

    _.assign($ctrl, {
      data: {},
      isLoading: false,

      // Component life cycle methods
      $onInit: $onInit,
    });

    /**
     * Method called on submitting app review
     *
     * @method   submitAppFeedback
     * @return   {promise}   returns a promise resolved with app review
     *                       submission success else rejected with error
     */
    function submitAppFeedback() {
      $ctrl.isLoading = true;

      return AppStoreService.postAppReview($ctrl.app, $ctrl.data).then(
        function submitAppFeedbackSuccess(response) {
          cMessage.success({
            titleKey: 'apps.reviewSuccessTitle',
            textKey: 'apps.reviewSuccessMessage',
          });
        }, evalAJAX.errorMessage)
        .finally(function submitAppFeedbackDone() {
          $ctrl.isLoading = false;
        }
      );
    }

    /**
     * Initializes the controller
     *
     * @method   $onInit
     */
    function $onInit() {
      if ($ctrl.cModalHoc) {
        $ctrl.cModalHoc.onModalSave = submitAppFeedback;
      }
    }

  }
})(angular);
