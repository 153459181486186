// Module & Component: seeMore

;(function(angular, undefined) {
  'use strict';

  var modName = 'C.seeMore';
  var modDeps = [];
  var componentName = 'seeMore';
  var options = {
    bindings: {
      /**
       * Unbound attribute. When this attribute is defined, seeMore will attempt
       * to center itself in the grid. Works best with even gridCol values.
       */
      // center

      /**
       * ui.json string key to be used for the "collapse" label.
       *
       * Default: 'hideAdvancedSettings'
       *
       * @type   {string}
       */
      collapseTextKey: '@?',

      /**
       * ui.json string key to be used for the "expand" label.
       *
       * Default: 'showAdvancedSettings'
       *
       * @type   {string}
       */
      expandTextKey: '@?',

      /**
       * Optional cols (1-12) can restrict the width of the content via grid
       * system.
       *
       * Default: 12
       *
       * @type   {integer}
       */
      gridCols: '@?',

      /**
       * The open Boolean only needs to be provided if you want to start the
       * panel in the open position, or if you want to programatically open and
       * close the panel by a means other than the link provided by the
       * component.
       *
       * Default: false
       *
       * @type   {boolean}
       */
      isOpen: '<?show',

      /**
       * Optional size (sm, md, lg) to be applied to c-fade class.
       *
       * Default: 'md'
       *
       * @type   {string}
       */
      size: '@?',

      /**
       * If true, this will suppress the styling of the containing box. No value
       * required.
       *
       * Default: false
       *
       * @type       {boolean}
       */
      noStyling: '<?',

      /**
       * Unbound attribute. If present, the default trigger-labels will be
       * shown. No value required.
       *
       * This is ignored when any label key attribute is defined.
       */
      // showLabel: '@',

      /**
       * Optional id which gets inserted into the clickable ID
       *
       * Default: 'settings'
       *
       * @type   {string}
       */
      name: '@?',

      /**
       * If true, the children of <see-more> are kept in the DOM and
       * shown/hidden via ng-hide and not added/removed via ng-if. This is
       * needed when a child component within <see-more> is mutating the object
       * reference passed as an attribute on init and <see-more>'s parent
       * component relies on it.
       *
       * Default: false
       *
       * @type   {boolean}
       */
      keepChildren: '<?',
    },
    controller: 'SeeMoreCtrl',
    transclude: true,
    templateUrl: 'app/global/see-more/see-more.html',
  };


  angular
    .module(modName, modDeps)
    .controller('SeeMoreCtrl', seeMoreControllerFn)
    .component(componentName, options);

  /**
   * @ngdoc component
   * @name C.seeMore:seeMore
   * @function
   *
   * @description
   * Reusable component provides an easy means of toggling a panel of content.
   *
   * @example
     <see-more size="sm" grid-cols="8" center>
       <p>The panel content to be shown/hidden.</p>
     </see-more>

     or

     <see-more
       expand-text-key="showMore"
       collapse-text-key="showLess">
       <p>The panel content to be shown/hidden.</p>
     </see-more>
   */
  function seeMoreControllerFn($attrs) {
    var $ctrl = this;

    $ctrl.name = $ctrl.name || 'settings';

    /**
     * Initialize this Component.
     *
     * @method   $onInit
     */
    $ctrl.$onInit = function $onInit() {
      $ctrl.size = $ctrl.size || 'md';
      $ctrl.gridCols = $ctrl.gridCols || 12;

      // If the center attrib is present and the gridCols are < 12
      $ctrl.gridCenteringOffset = $attrs.hasOwnProperty('center') &&

        // (Total - defined) ÷ 2, rounded down to nearest whole number.
        Math.floor((12 - $ctrl.gridCols) / 2);

      $ctrl.showLabel = $attrs.hasOwnProperty('showLabel') ||
        !!($attrs.expandTextKey || $attrs.collapseTextKey);

      // When showing the trigger-label, ensure both keys have a value.
      if ($ctrl.showLabel) {
        $ctrl.expandTextKey = $ctrl.expandTextKey || 'showAdvancedSettings';
        $ctrl.collapseTextKey = $ctrl.collapseTextKey || 'hideAdvancedSettings';
      }
    };
  }

})(angular);
