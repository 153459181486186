import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '@cohesity/api/private';
import { Observable } from 'rxjs';

/**
 * API service for legacy clone tasks.
 */
@Injectable({
  providedIn: 'root',
})
export class CloneApiService {
  /**
   * Constructor.
   */
  constructor(private http: HttpClient) {
  }

  /**
   * Clone View API call.
   *
   * @param params  API call payload.
   * @returns API call response.
   */
  clone(params: any): Observable<any> {
    return this.http.post<any>(Api.private('clone'), params);
  }

  /**
   * Destroy clone by ID.
   *
   * @param id  Clone task ID.
   * @returns Destroyed clone task.
   */
  tearDown(id: number): Observable<any> {
    return this.http.post<any>(Api.private(`destroyclone/${id}`), {});
  }

  /**
   * Get Source Entity by ID.
   *
   * @param id Source Entity ID.
   * @returns Source Entity.
   */
  getSourceEntityById(id: number): Observable<any> {
    return this.http.get<any>(Api.private('backupEntities'), { params: { entityIds: [id] }});
  }
}
