import { ProtectionSourceNode } from '@cohesity/api/v1';
import { DataTreeControl } from '@cohesity/helix';
import { Memoize } from '@cohesity/utils';
import { Environment, ExchangeGroups } from 'src/app/shared/constants';

import { ProtectionSourceDataNode } from '../shared/protection-source-data-node';

/**
 * Represents an exchange source node and job tree selection behavior.
 */
export class ExchangeSourceDataNode extends ProtectionSourceDataNode {

  /**
   * Gets node's parent id.
   */
  get parentId() {
    return this.protectionSource.parentId;
  }

  /**
   * Specifies whether the node is part of the group currently being edited or viewed.
   */
  inCurrentGroup = false;

  constructor(
    data: ProtectionSourceNode,
    readonly level: number,
    readonly treeControl: DataTreeControl<ExchangeSourceDataNode>,
  ) {
    super(Environment.kExchange, data, level);
  }

  /**
   * Auto protect is applicable to container entities.
   */
  canAutoSelect(): boolean {
    return this.expandable && ExchangeGroups.exchangeContainerEntities.includes(this.type);
  }

  /**
   * A node can be selected if it is not protected.
   */
  canSelect = () => !this.protected || this.inCurrentGroup;

  /**
   * Whether the node is a leaf which can be directly selected or not.
   * For Exchange, databases are leaves.
   */
  get isLeaf() {
    return ExchangeGroups.exchangeLeafEntities.includes(this.type);
  }

  /**
   * Helper to query this node's children for all matches.
   *
   * @param   predicate   Predicate callback function to determine if each node
   *                      is a match.
   * @return  True if all descendants match.
   */
  @Memoize()
  checkAllDescendants(predicate: (child: ExchangeSourceDataNode) => boolean): boolean {
    return this.treeControl.checkAllDescendants(this, predicate);
  }
}
