import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AnomalyAlert, SecurityServiceApi } from '@cohesity/api/v2';
import { SnackBarService } from '@cohesity/helix';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'dg-ar-anti-ransomware-notifications',
  templateUrl: './anti-ransomware-notifications.component.html',
  styleUrls: ['./anti-ransomware-notifications.component.scss'],
})
export class AntiRansomwareNotificationsComponent extends AutoDestroyable implements OnInit {
  /**
   * Form controls
   */
  readonly threshold = new UntypedFormControl(1);

  /**
   * The form group for the dialog
   */
  form = new UntypedFormGroup({
    threshold: this.threshold,
  });

  /**
   * Indicates whether data is being fetched.
   */
  loading = false;

  constructor(
    readonly dialogRef: MatDialogRef<AntiRansomwareNotificationsComponent>,
    private ajaxService: AjaxHandlerService,
    private snackbar: SnackBarService,
    private heliosSecurityService: SecurityServiceApi,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.getCurrentNotificationSettings();
  }

  /**
   * Gets current threshold level for push notifications
   */
  getCurrentNotificationSettings() {
    this.loading = true;
    this.heliosSecurityService
      .GetAnomalyAlertNotifLevel()
      .pipe(
        this.untilDestroy(),
        finalize(() => (this.loading = false))
      )
      .subscribe(
        response => this.threshold.setValue(response.notificationInfo?.anomalyStrengthThreshold),
        error => this.ajaxService.handler(error)
      );
  }

  /**
   * Updates threshold value.
   */
  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    const { threshold } = this.form.value;
    this.loading = true;

    // Assign current form value
    const body: AnomalyAlert = {
      notificationInfo: {
        anomalyStrengthThreshold: threshold,
      },
    };

    // API call
    this.heliosSecurityService
      .UpdateAnomalyAlertNotifLevel(body)
      .pipe(
        this.untilDestroy(),
        finalize(() => (this.loading = false))
      )
      .subscribe(
        () => {
          this.snackbar.open(this.translateService.instant('pushNotificationsConfigured'));
          this.dialogRef.close(true);
        },
        error => this.ajaxService.handler(error)
      );
  }
}
