/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { NavOptions, NavParams } from './iris-router.models';
import { RouterLinkAdapter } from './router-link/router-link-adapter';

/**
 * This service provides an abstraction around router libraries and can be written to be
 * compatible with either ui-router the angular router.
 *
 * To use, write an implementation that extends this class configure it in your core angular module.
 *
 * @example
 * {
 *   provide: IrisNavigationService,
 *   useClass: AppNavigationService,
 * },
 */
@Injectable()
export abstract class IrisNavigationService {
  /**
   * Returns the current state or url location.
   */
  get currentStateName(): string {
    return undefined;
  }

  /**
   * Returns the current state or router params.
   */
  get currentStateParams(): NavParams {
    return {};
  }

  /**
   * Navigates to a state or url path.
   *
   * @param state The state or path to navigate to.
   * @param params Orl params for the new location.
   * @param options Options to pass to the underlying router implementation.
   */
  go(state: string, params: NavParams, options?: NavOptions) {}

  /**
   * Indicates if the currently logged in user has appropriate permissions to
   * access the provided state.
   *
   * @param   stateOrName          The state or state name.
   * @param   stateParams          The state params.
   * @param   noLogging            If true then dont log if state is not accessible.
   * @return                       True if user has access to navigate to given state else false.
   */
  canUserAccessState(state: string, params?: NavParams, noLogging = false): boolean {
    return false;
  }

  /**
   * Directs the user to the previous state. if no previous state exists,
   * sends the user to the parent state or the optionally provided default
   * state.
   *
   * @param   defaultGoBackState   If a state doesn't have a parent, this should
   *                               be used to provide a default go back state.
   * @param   defaultStateParams   If set, params to pass to a default state.
   */
  goToPreviousState(defaultGoBackState?: string, defaultStateParams?: NavParams) {}

  /**
   * Creates an adapter object that implements the methods needed to use the iris router link directive.
   * This generally return either the UiRouterLinkAdapter or the AngularRuterLinkAdapter.
   *
   * @param injector The angular injector.
   * @param tabIndexAttribute Optional tab index, which is used by the angular router link.
   * @returns An instance of the router link adapter.
   */
  getRouterLinkAdapter(injector: Injector, tabIndexAttribute?: string): RouterLinkAdapter {
    return null;
  }

  /**
   * Reload the current route.
   *
   * @returns Observable resolved with true on success else false.
   */
  abstract reload(): Observable<boolean>;
}
