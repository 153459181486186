import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'coh-settings-list-overwrite-existing-file',
  templateUrl: './settings-list-overwrite-existing-file.component.html',
})
export class SettingsListOverwriteExistingFileComponent {
  /**
   * The form control for the settings list item.
   */
  @Input() control: UntypedFormControl;


  /**
   * Will be read only if this is set to true.
   */
  @Input() lockControl: boolean;
}
