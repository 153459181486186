<cog-settings-list-item [formGroup]="formGroup"
  [invalid]="formControl.invalid"
  [label]="'backupType' | translate">
  <cog-settings-list-item-value-display>
    <p>{{'snapshot' | translate}}</p>
    <p *ngIf="backupTypeFormGroup.get('createAmi').value"
      [innerHTML]="'createAMIEveryNRuns' | translate: {n: backupTypeFormGroup.get('amiCreationFrequency').value}">
    </p>
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor [formGroup]="backupTypeFormGroup">
    <mat-slide-toggle formControlName="createAmi">
      {{'createAMI' | translate}}
    </mat-slide-toggle>
    <mat-form-field *ngIf="backupTypeFormGroup.get('createAmi').value">
      <span>{{'every' | translate}}</span>
      <span>
        <input
          matInput
          formControlName="amiCreationFrequency"
          min="0"
          type="number">
        </span>
      <span>{{'runs' | translate}}</span>
    </mat-form-field>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
