import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LegendIndicatorComponent } from './legend-indicator.component';

const COMPONENTS = [LegendIndicatorComponent];

@NgModule({
  imports: [CommonModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class LegendIndicatorModule { }
