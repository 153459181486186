/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserPreferences } from '../models/user-preferences';
import { UpdateUserPreferences } from '../models/update-user-preferences';
@Injectable({
  providedIn: 'root',
})
class UserPreferencesService extends __BaseService {
  static readonly GetUserPreferencesPath = '/mcm/users/preferences';
  static readonly UpdateUserPreferencesPath = '/mcm/users/preferences';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get User Preferences.
   *
   * Get Preferences for a User
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetUserPreferencesResponse(regionId?: string): __Observable<__StrictHttpResponse<UserPreferences>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/users/preferences`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserPreferences>;
      })
    );
  }
  /**
   * Get User Preferences.
   *
   * Get Preferences for a User
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetUserPreferences(regionId?: string): __Observable<UserPreferences> {
    return this.GetUserPreferencesResponse(regionId).pipe(
      __map(_r => _r.body as UserPreferences)
    );
  }

  /**
   * Update User Preferences.
   *
   * Update Preferences for a User
   * @param body undefined
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  UpdateUserPreferencesResponse(body: UpdateUserPreferences,
    regionId?: string): __Observable<__StrictHttpResponse<UserPreferences>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/mcm/users/preferences`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserPreferences>;
      })
    );
  }
  /**
   * Update User Preferences.
   *
   * Update Preferences for a User
   * @param body undefined
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  UpdateUserPreferences(body: UpdateUserPreferences,
    regionId?: string): __Observable<UserPreferences> {
    return this.UpdateUserPreferencesResponse(body, regionId).pipe(
      __map(_r => _r.body as UserPreferences)
    );
  }
}

module UserPreferencesService {
}

export { UserPreferencesService }
