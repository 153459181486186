import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

import { ClusterService } from '../services/cluster.service';

/**
 * This interceptor warns if an API call might be missing a region id or
 * cluster id header param for passthrough calls.
 */
@Injectable()
export class PassthroughWarningHttpInterceptor implements HttpInterceptor {
  /**
   * These urls, despite not having "mcm" in the path, do not need to be
   * passthrough API calls.
   */
  noPassthroughUrls = [
    '/heliosreporting',
    '/irisservices/api/v1/public/basicClusterInfo',
    '/irisservices/api/v1/public/cluster',
    '/irisservices/api/v1/vulscan/api/appstatus',
    '/v2/data-protect/search/objects',
    'featureFlags',
    'messages/',
  ];

  /**
   * Headers for a passthrough request. If one of these headers is present,
   * then the request is a passthrough request.
   */
  passthroughHeaders = [
    'accessClusterId',
    'cid',
    'clusterId',
    'regionId',
  ];

  constructor(
    private clusterService: ClusterService,
    private ngxLogger: NGXLogger,
  ) {}

  /**
   * Intercepts http requests and warns if an API request is missing passthrough
   * headers.
   *
   * @param request The http request. Request interceptors modify this.
   * @param next The http handler. Response interceptors listen for
   *             events on this.
   * @return An observable of HttpEvent based on next.handle()
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Only show this warning on helios for URLs which should have a regionId
    // or cid header param, but do not have one.
    if (this.clusterService.isMcm &&
      request.responseType === 'json' &&
      !request.url.includes('mcm') &&
      !this.noPassthroughUrls.some(url => request.url.startsWith(url)) &&
      this.passthroughHeaders.every(header => !request.headers.has(header))) {
      this.ngxLogger.error('Missing passthrough header param for request.', request);
    }

    return next.handle(request);
  }
}
