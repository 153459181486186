<mat-form-field>
  <mat-label *ngIf="label">{{label}}</mat-label>
  <mat-chip-list
    [formControl]="control"
    [selectable]="true"
    [multiple]="!singleOnly">
    <mat-chip
      color="accent"
      cogDataId="day-of-week-chip-{{day.key}}"
      [inheritDataId]="true"
      *ngFor="let day of dayOfWeekList | enumKeys"
      #chip="matChip"
      [selectable]="true"
      [removable]="false"
      [value]="day.key"
      (click)="chip.toggleSelected(true)">
      {{day.key | dayVal:true}}
    </mat-chip>
  </mat-chip-list>
  <mat-error *ngIf="control.errors?.required">{{'errors.required' | translate}}</mat-error>
</mat-form-field>
