import { convertDegToRad, convertRadToDeg } from './maths';

/**
 * Method to find mid point of two points given lattitude and longitude values.
 *
 * @param lat1 Lattitude of point 1.
 * @param lon1 Longitude of point 1.
 * @param lat2 Lattitude of point 2.
 * @param lon2 Longitude of point 2.
 */
export function geoMidPoint(lat1: number, lon1: number, lat2: number, lon2: number) {
  // Longitude difference
  const dLng = convertDegToRad(lon2 - lon1);

  // Convert to radians
  lat1 = convertDegToRad(lat1);
  lat2 = convertDegToRad(lat2);
  lon1 = convertDegToRad(lon1);

  const bX = Math.cos(lat2) * Math.cos(dLng);
  const bY = Math.cos(lat2) * Math.sin(dLng);
  const lat3 = Math.atan2(
    Math.sin(lat1) + Math.sin(lat2),
    Math.sqrt((Math.cos(lat1) + bX) * (Math.cos(lat1) + bX) + bY * bY)
  );
  const lng3 = lon1 + Math.atan2(bY, Math.cos(lat1) + bX);

  return [convertRadToDeg(lat3), convertRadToDeg(lng3)];
}
