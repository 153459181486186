/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DownloadBaseosPatchRequest } from '../models/download-baseos-patch-request';
import { ApplyBaseosPatchRequest } from '../models/apply-baseos-patch-request';
import { BaseosPatchLog } from '../models/baseos-patch-log';
import { BaseosPatchList } from '../models/baseos-patch-list';
import { RemoveBaseosPatchRequest } from '../models/remove-baseos-patch-request';
@Injectable({
  providedIn: 'root',
})
class BaseosPatchManagementService extends __BaseService {
  static readonly DownloadBaseosPatchPath = '/patch-management/baseos-patch-download';
  static readonly ApplyBaseosPatchPath = '/patch-management/baseos-patch-apply';
  static readonly GetBaseosPatchLogPath = '/patch-management/baseos-patch-log';
  static readonly GetBaseosPatchListPath = '/patch-management/baseos-patch-list';
  static readonly RemoveBaseosPatchPath = '/patch-management/baseos-patch-remove';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Downloads the given baseos patch.
   *
   * Downloads the given baseos patch.
   * @param body Request to download a new baseos patch.
   */
  DownloadBaseosPatchResponse(body: DownloadBaseosPatchRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/patch-management/baseos-patch-download`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Downloads the given baseos patch.
   *
   * Downloads the given baseos patch.
   * @param body Request to download a new baseos patch.
   */
  DownloadBaseosPatch(body: DownloadBaseosPatchRequest): __Observable<null> {
    return this.DownloadBaseosPatchResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Applies the given baseos patch.
   *
   * Applies the given baseos patch.
   * @param body Request to apply a baseos patch.
   */
  ApplyBaseosPatchResponse(body: ApplyBaseosPatchRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/patch-management/baseos-patch-apply`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Applies the given baseos patch.
   *
   * Applies the given baseos patch.
   * @param body Request to apply a baseos patch.
   */
  ApplyBaseosPatch(body: ApplyBaseosPatchRequest): __Observable<null> {
    return this.ApplyBaseosPatchResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get Baseos patch application log
   *
   * Returns the log and status of the mentioned patch.
   * @param patchName Name of the hotfix with security patch
   * @return Success
   */
  GetBaseosPatchLogResponse(patchName: string): __Observable<__StrictHttpResponse<BaseosPatchLog>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (patchName != null) __params = __params.set('patchName', patchName.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patch-management/baseos-patch-log`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseosPatchLog>;
      })
    );
  }
  /**
   * Get Baseos patch application log
   *
   * Returns the log and status of the mentioned patch.
   * @param patchName Name of the hotfix with security patch
   * @return Success
   */
  GetBaseosPatchLog(patchName: string): __Observable<BaseosPatchLog> {
    return this.GetBaseosPatchLogResponse(patchName).pipe(
      __map(_r => _r.body as BaseosPatchLog)
    );
  }

  /**
   * Get available baseos patches
   *
   * Returns the available baseos patches
   * @return Success
   */
  GetBaseosPatchListResponse(): __Observable<__StrictHttpResponse<BaseosPatchList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patch-management/baseos-patch-list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BaseosPatchList>;
      })
    );
  }
  /**
   * Get available baseos patches
   *
   * Returns the available baseos patches
   * @return Success
   */
  GetBaseosPatchList(): __Observable<BaseosPatchList> {
    return this.GetBaseosPatchListResponse().pipe(
      __map(_r => _r.body as BaseosPatchList)
    );
  }

  /**
   * Cleans up the given baseos patch files.
   *
   * Cleans up the given baseos patch files.
   * @param body Request to remove baseos patch files.
   */
  RemoveBaseosPatchResponse(body: RemoveBaseosPatchRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/patch-management/baseos-patch-remove`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Cleans up the given baseos patch files.
   *
   * Cleans up the given baseos patch files.
   * @param body Request to remove baseos patch files.
   */
  RemoveBaseosPatch(body: RemoveBaseosPatchRequest): __Observable<null> {
    return this.RemoveBaseosPatchResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module BaseosPatchManagementService {
}

export { BaseosPatchManagementService }
