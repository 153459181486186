<coh-source-special-parameters [form]="form"
  [nodeContext]="nodeContext"
  [label]="selectionLabel | translate"
  (dialogOpened)="updateForm()"
  (saved)="onSave()"
  (cancelled)="onCancel()">
  <coh-oracle-node-settings
    [form]="form"
    [nodeContext]="nodeContext">
  </coh-oracle-node-settings>
</coh-source-special-parameters>