import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isFinite } from 'lodash';
import moment from 'moment';

/**
 * Wrapper for moment.js to render a string that converts a duration period
 * to a readable string.
 *
 * @example
 *   {{durationUsecs | duration}} => '7 minutes'
 */
@Pipe({
  name: 'duration',
  pure: true,
})
export class DurationPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  /**
   * Wrapper for moment.js to render a string that indicates the duration of a
   * period of time.
   *
   * @param   duration   The value to transform
   * @param   unit       The units to transform
   * @return  The string description of how long its been since provided usecs
   */
  transform(duration: number, unit: moment.DurationInputArg2 = 'ms'): string {
    if (!duration || !isFinite(duration)) {
      return this.translate.instant('naNotAvailable');
    }
    return moment.duration(duration, unit).humanize();
  }
}
