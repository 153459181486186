import { NgModule } from '@angular/core';

import { BoolLabelModule } from './bool-label';
import { DayValModule } from './day-val';
import { EnumKeysModule } from './enum-keys';
import { EnvironmentTypeModule } from './environment-type';
import { NaLabelModule } from './na-label';
import { ShowMoreModule } from './show-more';
import { YesNoModule } from './yes-no';

const PIPES = [
  BoolLabelModule,
  DayValModule,
  EnumKeysModule,
  EnvironmentTypeModule,
  NaLabelModule,
  ShowMoreModule,
  YesNoModule,
];

/**
 * App Module. This is the root angular module.
 */
@NgModule({
  exports: [...PIPES],
})
export class UtilPipesModule {}
