import { Injectable } from '@angular/core';
import { ProtectionSourceNode, ProtectionSourcesServiceApi } from '@cohesity/api/v1';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { Environment } from '@cohesity/iris-shared-constants';
import { SourceTreeDataProvider } from '@cohesity/iris-source-tree';
import { Observable } from 'rxjs';
import { PassthroughOptionsService } from 'src/app/core/services';
import { AzureBackupType } from 'src/app/shared';

@Injectable()
export class AzureSourceDataProvider implements SourceTreeDataProvider<ProtectionSourceNode, AzureBackupType> {

  constructor(
    readonly sourceService: ProtectionSourcesServiceApi,
    readonly irisContext: IrisContextService,
    readonly passthroughOptionsService: PassthroughOptionsService
  ) {}

  fetchTree(
    sourceId: number, environment: Environment,
    workloadType?: AzureBackupType, allUnderHierarchy?: boolean): Observable<ProtectionSourceNode[]> {
    const excludeTypes: any = ['kResourcePool'];
    const excludeAzureTypes = [];

    if (flagEnabled(this.irisContext.irisContext, 'dmsAzureEntraIdWorkload') &&
      workloadType !== 'kAzureEntraID') {
      excludeAzureTypes.push('kEntraID');
    }

    // Determine node type to be excluded based on current workload context
    switch (workloadType) {
      case 'kSQLDatabase':
        excludeAzureTypes.push('kVirtualMachine');
        break;
      case 'kVirtualMachine':
        excludeAzureTypes.push(...['kSQLDatabase', 'kSQLServer', 'kSQLManagedInstance']);
        break;
      case 'kAzureEntraID':
        excludeAzureTypes.push(...['kSQLDatabase', 'kSQLServer', 'kSQLManagedInstance', 'kVirtualMachine']);
        break;
    }

    return this.sourceService.ListProtectionSources({
      id: sourceId,
      allUnderHierarchy: allUnderHierarchy || false,
      environment,
      excludeTypes,
      excludeAzureTypes,
      includeEntityPermissionInfo: true,
      includeVMFolders: true,
      includeSystemVApps: true,
      ...this.passthroughOptionsService.requestParams,
    });
  }

}
