import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Api } from '../api.constants';
import { BringUpStatus } from '../private-models/nexus-cluster';

// Base path for Nexus Cluster API
const basePath = 'nexus/cluster';

/**
 * @description
 * Service for Nexus cluster private API.
 */
@Injectable({
  providedIn: 'root',
})
export class NexusClusterApiService {
  constructor(private http: HttpClient) {}

  /**
   * Returns bring-up status for nexus cluster.
   */
  getBringUpStatus(): Observable<BringUpStatus> {
    return this.http.get<BringUpStatus>(Api.private(`${basePath}/bringup_status`));
  }
}
