import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Source tree context is used to provide information context information for the entire source tree.
 * It can be injected into any component used within the source tree.
 */
export const SOURCE_TREE_CONTEXT = new InjectionToken<SourceTreeContext>('sourceTreeContext');

/**
 * Provider configuration for the source tree. This should be provided in the main source tree component.
 */
export const SourceTreeContextProvider = {
  provide: SOURCE_TREE_CONTEXT,

  // This returns a new value for each instance of the source tree, so that they can be controlled
  // independently.
  useFactory: () => ({}),
};

/**
 * Global source tree context settings
 */
export interface SourceTreeContext {
  /**
   * This provides an observable of the current search query. Component can use this to highlight matching
   * striings.
   */
  searchQuery$?: Observable<string>;

  /**
   * This can be set to true to enable navigation links in the source tree. These are disabled
   * by default, since the tree is usually shown within the context of a job creation page or
   * dialog.
   */
  showNavigationLinks?: boolean;
}
