import { AfterViewInit, Component, ContentChild, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';

@Component({
  selector: 'cog-slicer',
  templateUrl: './slicer.component.html',
  styleUrls: ['./slicer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SlicerComponent implements AfterViewInit {
  /**
   * The button toggle group wrapped by this component.
   */
  @ContentChild(MatButtonToggleGroup) toggleGroup: MatButtonToggleGroup;

  /**
   * Compact versions are shown with a smaller height and usually mainlly for the switcher shows
   * only text. Non compact is used for icons.
   */
  @HostBinding('class.compact') @Input() compact = true;

  ngAfterViewInit() {
    if (!this.toggleGroup) {
      throw new Error('SlicerComponent requires a MatButtonToggleGroup');
    }

    // TODO: get fancy and make the active background slide/grow/shrink based on
    // the selected item. Using Boring CSS for now.
    // this.toggleGroup.valueChange
    //   .pipe(
    //     filter(val => typeof val !== 'undefined')
    //   )
    //   .subscribe(console.log);
  }
}
