/* eslint-disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for CohesityElixirApi services
 */
@Injectable({
  providedIn: 'root',
})
export class CohesityElixirApiConfiguration {
  rootUrl: string = '/elixirServices';
}

export interface CohesityElixirApiConfigurationInterface {
  rootUrl?: string;
}
