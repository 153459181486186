import {
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { BaseFormFieldDirective, FormPanel, RadioGroupConfig } from '../../models';

/**
 * Render a radio group field.
 *
 * @example
 *  <cog-radio-group-form-field [label]="label" ...></cog-radio-group-form-field>
 */
@Component({
  selector: 'cog-radio-group-form-field',
  templateUrl: './radio-group-form-field.component.html',
  styleUrls: ['./radio-group-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioGroupFormFieldComponent extends BaseFormFieldDirective<RadioGroupConfig> implements OnChanges {

  /**
   * Optional form panel linked to the selected radio button from the radio group.
   */
  formPanel$: Observable<FormPanel>;

  /**
   * Initialise the formPanel observable. This react to the change in the radio
   * group form control value.
   */
  bindFormPanelToControlValueChange() {

    // Based on the selected radio button, check if there is a linked form panel
    // to be displayed and pass it to the formPanel subject.
    this.formPanel$ = this.control.valueChanges.pipe(
      startWith(this.control.value),

      // Reset the state of the form panels upon radio button selection change.
      // This needs to be done for the panels linked to the un-selected radiio buttons.
      tap((selectedValue) => {
        (this.config.radioButtons || [])
          .filter((button) => button.value !== selectedValue)
          .forEach((button) => {
            const panelFields = button?.formPanel?.fields || [];

            panelFields.forEach((field) => {
              const fieldControl = this.formControlGetterFn(field.key);
              fieldControl.clearValidators();

              // Resetting clears out any nested form panels as well.
              fieldControl.reset();
              fieldControl.updateValueAndValidity();
            });
          });
      }),
      map((selectedValue) => {
        const selectedRadioButtonConfig = this.config.radioButtons.find(
          button => button.value === selectedValue
        );
        return selectedRadioButtonConfig?.formPanel;
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config || changes.control) {
      this.control.clearValidators();
      this.control.setValidators([
        this.config?.required ?
          Validators.required : Validators.nullValidator,
      ]);

      // This component could have been reset. Upon re-init, if the control has
      // no value assigned to it, and a default value exists in it's config,
      // assign that to control.
      if (!this.control.value && !!this.config?.defaultValue) {
        this.control.setValue(this.config?.defaultValue);
      }
      this.control.updateValueAndValidity();
    }

    // On control change, re-initialise form panel observable.
    if (changes.control) {
      this.bindFormPanelToControlValueChange();
    }
  }
}
