import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  CanSelectAnyRowsFn,
  CanSelectRowFn,
  DataFilterValue,
  EventTrackingService,
  FiltersComponent,
  SnackBarService,
  ValueFilterSelection
} from '@cohesity/helix';
import { IrisContextService, flagEnabled, getUserPrivilegeCheck, isRpaasScope } from '@cohesity/iris-core';
import { AjaxHandlerService, AutoDestroyable, DialogService } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, finalize, takeUntil } from 'rxjs/operators';

import { MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { HmsTaggingWrapperService, TagSelectorDialogData, TagSelectorDialogResponse } from '@cohesity/data-govern/shared';
import {
  AnomalyAlert,
  AnomalyChartSeries,
  DataPoint,
  FileStats,
  MetricType,
  PropertyMapObject,
  SnapshotActionConfirmationParams,
  SnapshotCopyInfo,
  SnapshotTagActionType,
  baasMetrics,
  hiddenMetrics
} from '../../security-shared.models';
import { SecurityService } from '../../services';

import { SecurityServiceApi, Snapshot, SnapshotTag } from '@cohesity/api/v2';
import { TagSelectorDialogComponent } from '@cohesity/data-govern/shared';
import { SecurityDialogService } from '../../dialogs/security-dialog.service';

/**
 * This component lists all snapshots for the alert by given metrics.
 * Operations such as apply / remove tag can be performed by selecting snapshots.
 * Work In Progress
 *
 * @example
 * <dg-ar-anti-ransomware-snapshot-list
 *   [anomalyAlert]="anomaly.data"
 *   [snapshotListTagsData]="anomaly.seriesData">
 *   [filesStats]="filesStats"
 *   (tagUpdated)="getSeriesData()">
 * </dg-ar-anti-ransomware-snapshot-list>
 */
@Component({
  selector: 'dg-ar-anti-ransomware-snapshot-list',
  templateUrl: './anti-ransomware-snapshot-list.component.html',
  styleUrls: ['./anti-ransomware-snapshot-list.component.scss'],
})
export class AntiRansomwareSnapshotListComponent extends AutoDestroyable implements OnInit, AfterViewInit {
  /**
   * fileStates object that includes details number files deleted, added and modifed.
   */
  @Input() filesStats: FileStats;

  /**
   * Snapshot list input
   * After getting data, process it and set other values.
   */
  @Input() set snapshotListTagsData(data: AnomalyChartSeries) {
    if (!Object.keys(data).length) {
      return;
    }
    this.processSnapshotList(data);
  }

  private _anomalyAlert: AnomalyAlert;

  get anomalyAlert(): AnomalyAlert {
    return this._anomalyAlert;
  }

  /**
   * Alert information.
   */
  @Input() set anomalyAlert(alert: AnomalyAlert) {
    if (!Object.keys(alert).length) {
      return;
    }
    this._anomalyAlert = alert;
    this.anomalyProperties = alert.properties;
    this.processTaggingCommons(alert);
  }

  /**
   * Emit an event to refresh table data.
   */
  @Output() tagUpdated = new EventEmitter<void>();

  /**
   * Template for table filters.
   */
  @ViewChild(FiltersComponent) private filtersComponent: FiltersComponent;

  /**
   * Used for instrumentation.
   */
  readonly manageTagsActionTrackingKey = 'anomalous_object_snapshots_tags_updated';

  /**
   * Used for instrumentation.
   */
  readonly trackingKeys: { [key in SnapshotTagActionType]: string } = {
    applyTag: 'anomalous_object_snapshots_tagged',
    removeTag: 'anomalous_object_snapshots_untagged',
  };

  /**
   * Snapshot list with tag information.
   */
  snapshotListDataSource: DataPoint[];

  /**
   * Filtered snapshots lists.
   */
  filteredSnapshotListDataSource: DataPoint[];

  /**
   * Anomalous timestamps for easier lookup.
   */
  anomalousTimestamps: number[] = [];

  /**
   * Latest clean snapshot timestamp.
   */
  latestCleanTimestamp = 0;

  /**
   * Columns to be displayed
   * This will be updated with response.
   */
  displayColumns = [];

  /**
   * Actions enum
   */
  action: SnapshotTagActionType;

  /**
   * List of all metrics.
   */
  allMetrics = Object.values(MetricType);

  /**
   * This object will contain all table selection items.
   */
  tableSelection = new SelectionModel<DataPoint>(true, []);

  /**
   * To show / hide spinner
   */
  loading = false;

  /**
   * anomaly properties for displaying strength, source and cluster details.
   */
  anomalyProperties: PropertyMapObject;

  /**
   * Tag type filters
   */
  tagTypeFilters: ValueFilterSelection[] = ['tagged', 'untagged'].map(entity => ({
    label: this.translateService.instant(entity),
    value: entity,
  }));

  /**
   * Snapshot type filters.
   */
  snapshotTypeFilters: ValueFilterSelection[] = ['anomalousSnapshot', 'cleanSnapshot'].map(entity => ({
    label: this.translateService.instant(entity),
    value: entity,
  }));

  /**
   * Save common information needed for apply / remove tag API calls
   */
  taggingCommons = {
    snapshot: <Snapshot>{},
    tags: <SnapshotTag[]>[],
  };

  /**
   * Returns true if any rendered snapshot objects in the table can be selected.
   */
  canSelectAnyRows: CanSelectAnyRowsFn = () => {
    if (this.isNewSnapshotTaggingEnabled) {
      return true;
    }

    return !!this.tableSelection.selected?.length;
  };

  /**
   * Determines whether a row can be selected or not.
   * This method is defined as a property so that the this value will be bound
   * properly when passed as an input to the table component.
   *
   * @return   whether a row can be selected or not.
   */
  canSelectRow: CanSelectRowFn<any> = (row: any) => {
    if (this.isNewSnapshotTaggingEnabled) {
      return true;
    }

    // Get the current table selection is empty;
    // Then all rows are available for selection
    if (this.tableSelection.isEmpty()) {
      return true;
    }

    const hasRowTag = Boolean(row.tags?.length);
    const hasTags = currentValue => !!currentValue?.tags?.length;

    if (this.tableSelection.selected.every(hasTags)) {
      // If current table selection has all tagged snapshots then
      // enable remove tag action & return row.tag (all tagged snapshots should ber enabled)
      this.action = 'removeTag';
      return hasRowTag;
    } else {
      // If current table selection has all untagged snapshots then
      // enable apply tag action & return !row.tag (all untagged snapshots should be enabled)
      this.action = 'applyTag';
      return !hasRowTag;
    }
  };

  /**
   * Check if the user has tagging permissions or not.
   */
  get hasTaggingPermissions() {
    return getUserPrivilegeCheck('MCM_MANAGE_TAGS')(this.irisCtx.irisContext);
  }

  /**
   * Flag for checking if new snapshot tagging is enabled or not.
   */
  get isNewSnapshotTaggingEnabled() {
    return flagEnabled(
      this.irisCtx.irisContext,
      'cleanRoomRecoveryPhase2'
    );
  }

  constructor(
    private ajaxService: AjaxHandlerService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private eventTrackingService: EventTrackingService,
    private heliosSecurityService: SecurityServiceApi,
    private hmsWrapperService: HmsTaggingWrapperService,
    private irisCtx: IrisContextService,
    private securityDialogService: SecurityDialogService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    readonly securityService: SecurityService,
  ) {
    super();
  }

  ngOnInit() {
    if (this.isNewSnapshotTaggingEnabled) {
      this.getSnapshotTags();
    } else {
      this.getCurrentSettings();
    }
  }

  /**
   * Gets settings for logged in user.
   */
  getCurrentSettings() {
    this.loading = true;
    this.cdr.detectChanges();

    this.heliosSecurityService
      .GetAnomalyAlertNotifLevel()
      .pipe(
        this.untilDestroy(),
        finalize(() => {
          this.loading = false;
          this.cdr.detectChanges();
        })
      )
      .subscribe(
        response => {
          const { tagName, tagId } = response?.taggingInfo || {};

          if (tagName && tagId) {
            this.taggingCommons.tags.push({
              id: tagId,
              label: tagName,
            });
          }
        },
        error => this.ajaxService.handler(error)
      );
  }

  getSnapshotTags() {
    if (!this.snapshotListDataSource) {
      return;
    }

    const snapshotIds = this.snapshotListDataSource.map(source => source.snapshotInfo.localSnapshot);

    this.loading = true;
    this.cdr.detectChanges();

    this.hmsWrapperService
      .listAssociatedTags({ snapshotIds })
      .pipe(
        takeUntil(this._destroy),
        finalize(() => {
          this.loading = false;
          this.cdr.detectChanges();
        })
      )
      .subscribe((resp) => {
        const allTags: ValueFilterSelection[] = [];
        const tagsBySnapshotId = new Map();

        resp.snapshotTags.forEach(snapshotTags => {
          snapshotTags.tags.forEach(tag => {
            const tagExist = allTags.some((tagFilterValue) => tagFilterValue.value === tag.uuid);

            if (!tagExist) {
              allTags.push({
                label: this.translateService.instant(tag.name),
                value: tag.uuid,
              });
            }
          });

          tagsBySnapshotId.set(snapshotTags.snapshotId, snapshotTags.tags);
        });

        this.tagTypeFilters = allTags;

        this.snapshotListDataSource.forEach(source => {
          const filteredSnapshotTags = tagsBySnapshotId.get(source.snapshotInfo.localSnapshot) || [];
          if (filteredSnapshotTags.length) {
            source.hmsTags = filteredSnapshotTags;
          }
        });
      }, this.ajaxService.handler);
  }

  /**
   * Initialize filter updates.
   */
  ngAfterViewInit() {
    this.initializeFilterUpdates();
  }

  /**
   * Extracts necessary data from response and updates local vars.
   *
   * @param snapshotList  Anomaly chart series data
   */
  processSnapshotList(snapshotList: AnomalyChartSeries) {
    const { anomalousDataPoints, latestCleanDataPoint, dataPointVec, metrics } = snapshotList;
    this.displayColumns = ['timestampUsecs'];
    this.anomalousTimestamps = anomalousDataPoints.map(dataPoint => dataPoint.x * 1000);
    this.latestCleanTimestamp = latestCleanDataPoint?.x * 1000;
    this.filteredSnapshotListDataSource = this.snapshotListDataSource = dataPointVec;
    this.updateDisplayColumns(metrics);
  }

  /**
   * Updates tagging commons from alert properties
   *
   * @param alert  Anomaly Alert
   */
  processTaggingCommons(alert: AnomalyAlert) {
    const { cid, clusterIncarnationId, jobId, entityId } = alert.properties;
    this.taggingCommons.snapshot = {
      clusterId: Number(cid),
      incarnationId: Number(clusterIncarnationId),
      protectionGroupId: Number(jobId),
      objectId: Number(entityId),
    };
  }

  /**
   * Updates display columns from metric list.
   *
   * @param metrics list of metrics with which anomaly is detected.
   */
  updateDisplayColumns(metrics: string[]) {
    const { incidence } = this.anomalyAlert;

    const isBaas = incidence?.antiRansomwareDetails ?? false;

    if (isBaas) {
      metrics = metrics.filter(metric => baasMetrics.includes(MetricType[metric]));
    }

    for (const metricId of metrics) {
      const metricType = MetricType[metricId];
      if (!hiddenMetrics.includes(metricType)) {
        this.displayColumns.push(metricType);
      }
    }

    if (isRpaasScope(this.irisCtx.irisContext)) {
      this.displayColumns.splice(1, 0, 'location');
    }
  }

  /**
   * Returns the location of the snapshot location.
   *
   * @param   snapshotInfo   The snapshot information
   * @returns A string with the location of the snapshots.
   */
  getSnapshotLocation(snapshotInfo: SnapshotCopyInfo): string {
    const location = [];
    if (snapshotInfo?.localSnapshot) {
      location.push(this.translateService.instant('local'));
    }

    if (snapshotInfo?.archivalSnapshot?.length) {
      const vaultName = [];
      const archives = snapshotInfo.archivalSnapshot.filter(snap =>
        isRpaasScope(this.irisCtx.irisContext) ? snap.isRPaas : true
      );
      archives.forEach(archive => vaultName.push(this.translateService.instant(archive.rpaasRegion)));
      location.push(vaultName.join(', '));
    }

    return location.join(', ');
  }

  /**
   * Emit when filter value changes.
   * Host will capture this and update service params to get new data.
   */
  initializeFilterUpdates() {
    this.filtersComponent?.filterValues$.pipe(debounceTime(100), this.untilDestroy()).subscribe(filters => {
      this.applyFilters(filters);
    });
  }

  /**
   * Apply the selected filter and call service to get new data.
   *
   * @param filters The applied filter value.
   */
  applyFilters(filters: DataFilterValue<string, ValueFilterSelection[]>[]) {
    // Parse the filters, create params and call service
    if (!this.snapshotListDataSource?.length) {
      return;
    }

    this.filteredSnapshotListDataSource = [...this.snapshotListDataSource];

    filters.forEach(({ key, value }) => {
      // skip null or empty value filters.
      if (!value || !value.length) {
        return;
      }

      switch (key) {
        case 'tagType': {
          if (this.isNewSnapshotTaggingEnabled) {
            const selectedTags = value.map(val => val.value);

            this.filteredSnapshotListDataSource = this.filteredSnapshotListDataSource.filter(snapshot =>
              snapshot.hmsTags?.some(snapshotTag => selectedTags.includes(snapshotTag.uuid))
            );
          } else {
            const selectedTagType = value.map(val => val.value)[0];
            const filterSnapshotsByTagType =
              selectedTagType === 'tagged' ? snapshot => snapshot?.tags?.length : snapshot => !snapshot?.tags?.length;
            this.filteredSnapshotListDataSource = this.filteredSnapshotListDataSource.filter(filterSnapshotsByTagType);
          }
          break;
        }
        case 'snapshotType': {
          const selectedSnapshotType = value.map(val => val.value)[0];
          const filterSnapshotsBySnapshotType =
            selectedSnapshotType === 'anomalousSnapshot'
              ? snapshot => this.anomalousTimestamps.includes(snapshot.timestampUsecs)
              : snapshot => !this.anomalousTimestamps.includes(snapshot.timestampUsecs);
          this.filteredSnapshotListDataSource =
            this.filteredSnapshotListDataSource.filter(filterSnapshotsBySnapshotType);
          break;
        }
      }
    });
    this.cdr.detectChanges();
  }

  /**
   * Once tagging operation is complete;
   * Clear the existing selections and emit an event to refresh table source.
   */
  refreshTableSource(action?: SnapshotTagActionType) {
    if (this.isNewSnapshotTaggingEnabled) {
      this.eventTrackingService.send({ key: this.manageTagsActionTrackingKey });
      this.snackBarService.open(this.translateService.instant(`dg.sc.inventory.updateTagsSuccess`));
    } else {
      this.eventTrackingService.send({ key: this.trackingKeys[action] });
      this.snackBarService.open(this.translateService.instant(`anomalySnapshotList.${action}.success`));
      this.loading = true;
    }

    this.tableSelection.clear();
    this.filtersComponent.setValue('tagType', []);
    this.filtersComponent.setValue('snapshotType', []);
    this.tagUpdated.emit();
    this.cdr.detectChanges();
  }

  /**
   * Opens manage snapshot dialog.
   */
  manageSnapshotTags() {
    const config: MatDialogConfig = {
      width: '40rem',
      panelClass: 'tag-selector-dialog',
      data: {
        snapshotIds: this.tableSelection.selected.map(snapshot => snapshot.snapshotInfo.localSnapshot),
      } as TagSelectorDialogData,
    };

    this.dialogService
      .open<TagSelectorDialogComponent, TagSelectorDialogResponse, TagSelectorDialogData>(
        TagSelectorDialogComponent,
        config
      )
      .pipe(this.untilDestroy())
      .subscribe(resp => {
        if (resp.action === 'complete') {
          this.refreshTableSource();
        }
      });
  }

  /**
   * This confirms selected action and calls action handler.
   *
   * @param action Apply / Remove tag
   */
  confirmAction(action: SnapshotTagActionType) {
    if (!this.taggingCommons.tags.length) {
      this.snackBarService.open(this.translateService.instant('anomalySnapshotList.actionCannotBeCompleted'), 'error');
      return;
    }

    const dialogData: SnapshotActionConfirmationParams = {
      action,
      taggingCommons: this.taggingCommons,
      selectedSnapshots: this.tableSelection?.selected,
    };

    this.securityDialogService
      .launchSnapshotActionDialog(dialogData)
      .pipe(this.untilDestroy())
      .subscribe(
        () => this.refreshTableSource(action),
        error => this.ajaxService.handler(error)
      );
  }
}
