// Factory: scheduled emails utility factory

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.scheduledEmailsUtil', [])
    .factory('ScheduledEmailsUtil', scheduledEmailsUtilFn);

  function scheduledEmailsUtilFn($state, ReportsUtil, JobService, SourceService,
    ViewBoxService, ExternalTargetService, ViewService, cUtils, ENV_GROUPS,
    ActiveDirectoryService) {

    var ScheduledEmailsUtil = {
      buildFiltersPromiseObject: buildFiltersPromiseObject,
      getFilterIds: getFilterIds,
    };

    /**
     * after aggregating all the ids we want to make the necessary API calls
     * so we can build hashes to use when mapping our ids to names, when
     * displaying filters on the template
     *
     * @param     {object}    filterIds    object that contains aggregated
     *                                     filter ids
     *
     * @return    {object}                 a promise object that contains
     *                                     necessary API calls
     */
    function buildFiltersPromiseObject(filterIds) {
      var promiseObject = {};

      if (filterIds.jobIds.length) {
        promiseObject.jobs = JobService.getJobs({ ids: filterIds.jobIds });
      }

      if (filterIds.objectIds.length) {
        promiseObject.objects =
          SourceService.getEntitiesById(filterIds.objectIds);
      }

      if (filterIds.viewBoxIds.length) {
        promiseObject.viewBoxes = ViewBoxService.getViewBoxes({
          ids: filterIds.viewBoxIds,
        });
      }

      if (filterIds.registeredSourceIds.length) {
        promiseObject.registeredSourceIds = SourceService.getSources({
          onlyReturnOneLevel: true,
          envTypes: cUtils.onlyNumbers(ENV_GROUPS.standard),
        }, true);
      }

      if (filterIds.vaultIds) {
        promiseObject.vaultIds = ExternalTargetService.getTargets();
      }

      if (filterIds.viewNames) {
        promiseObject.viewNames = filterIds.viewNames;
      }

      if (filterIds.unixUids) {
        promiseObject.unixUids = filterIds.unixUids;
      }

      if (filterIds.sids) {
        promiseObject.sids =
          ActiveDirectoryService.getPrincipals(filterIds.sids);
      }

      return promiseObject;
    }

    /**
     * after we get our schedules we need to get more data for the filters
     * across all schedules. this method will aggregate the ids for certain
     * filters
     *
     * @param     {array}    schedules    array of schedule objects that
     *                                    contains report filters
     *
     * @return    {object}                object that contains aggregated ids
     *                                    from all schedules
     */
    function getFilterIds(schedules) {
      var idsObject = {
        jobIds: [],
        objectIds: [],
        viewBoxIds: [],
        registeredSourceIds: [],
        viewNames: [],
        unixUids: [],
        sids: [],
      };

      schedules.forEach(function loopSchedules(schedule) {
        schedule.scheduleJobParameters.reportJobParameter.reports.forEach(
          function loopReports(report) {
            if (report.parameters.jobId) {
              idsObject.jobIds.push(report.parameters.jobId);
            }

            if (report.parameters.objectIds) {
              idsObject.objectIds =
                idsObject.objectIds.concat(report.parameters.objectIds);
            }

            if (report.parameters.viewBoxId) {
              idsObject.viewBoxIds.push(report.parameters.viewBoxId);
            }

            if (report.parameters.registeredSourceIds) {
              idsObject.registeredSourceIds =
                idsObject.registeredSourceIds.concat(
                  report.parameters.registeredSourceIds
                );
            }

            if (report.parameters.vaultIds) {
              idsObject.vaultIds = true;
            }

            if (report.parameters.viewName) {
              idsObject.viewNames =
                idsObject.viewNames.concat(report.parameters.viewNames);
            }

            if (report.parameters.unixUid) {
              idsObject.unixUids =
                idsObject.unixUids.concat(report.parameters.unixUid);
            }

            if (report.parameters.sid) {
              idsObject.sids =
                idsObject.sids.concat(report.parameters.sid);
            }
          });
      });

      return idsObject;
    }

    return ScheduledEmailsUtil;
  }
})(angular);
