// License Key Service

(function(angular, undefined) {

  angular.module('C').service('LicenseService', licenseServiceFn);

  function licenseServiceFn($rootScope, $q, $uibModal, ClusterService,
    evalAJAX, cMessage) {

    var modalConfig = {
      keyboard: true,
      modalFade: true,
      backdrop: 'static',
      templateUrl: 'app/global/c-license/c-license.html',
      controller: licenseModalController,
      windowClass: 'c-modal-soft',
      resolve: {}
    };

    /**
     * Instantiate the Modal if user has appropriate permissions.
     * Currently, the backend requires the key and eula acceptance info
     * to all be submitted at once. Until that requirement is changed,
     * EulaService resolves with a paritcular eulaConfig object, which
     * should then be passed here for completion.
     *
     * @param      {Object}   eulaConfig  partially completed eulaConfig
     * @return     {Promise}  uibModal
     */
    function showModal(eulaConfig) {
      // copy modalConfig so our eulaConfig
      // doesn't persist between instances
      var modalConfigCopy = angular.copy(modalConfig);
      var deferred;
      var modalPromise;

      modalConfigCopy.resolve.eulaConfig = eulaConfig;

      if ($rootScope.user.privs.CLUSTER_MODIFY) {
        modalPromise = $uibModal.open(modalConfigCopy).result;
      } else {
        // if user doesn't have appropriate permissions return a fake
        // promise thats pre-rejected.
        deferred = $q.defer();
        deferred.reject('permission-fail');
        modalPromise = deferred.promise;

        // inform the user they need an admin to submit the license
        cMessage.error({
          textKey: 'license.adminUserRequired',
          persist: true,
        });
      }

      return modalPromise;
    }

    /**
     * Controller Logic for License Key Modal
     *
     * @param      {Object}  $scope             The scope
     * @param      {Object}  $uibModalInstance  The uib modal instance
     */
    /* @ngInject */
    function licenseModalController($scope, $uibModalInstance, eulaConfig) {
      /**
       * Reject the License Key Modal
       */
      $scope.cancel = function cancel() {
        $uibModalInstance.dismiss('cancel');
      };

      /**
       * Submit the License Key (eulaConfig)
       */
      $scope.submit = function submit() {

        if ($scope.licenseForm.$invalid) {
          return;
        }

        $scope.submitting = true;

        eulaConfig.licenseKey = $scope.licenseKey;

        ClusterService.acceptLicense(eulaConfig).then(
          $uibModalInstance.close,
          evalAJAX.errorMessage
        ).finally(
          function stopSpinning() {
            $scope.submitting = false;
          }
        );
      };

    }

    return {
      showModal: showModal
    };
  }

}(angular));
