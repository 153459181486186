import { Component, Input } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { BannerStatus } from '@cohesity/helix';
import { Controls, NgxSubFormComponent, subformComponentProviders } from 'ngx-sub-form';
import { YesToConfirmValidator } from '../validators';
import { ConfirmationFormModel } from './textual-confirmation-input.model';

/**
 * Provides a textual confirmation for an action the user is about to perform.
 */
@Component({
  selector: 'coh-textual-confirmation-input',
  templateUrl: './textual-confirmation-input.component.html',
  providers: subformComponentProviders(TextualConfirmationInputComponent),
})
export class TextualConfirmationInputComponent extends NgxSubFormComponent<ConfirmationFormModel> {

  /**
   * The desired styling/color of the surrounding banner.
   */
  @Input() status: BannerStatus = 'info';

  /**
   * Mesage to be displayed explaining the context for confirmation.
   */
  @Input() message: string;

  getDefaultValues(): Partial<ConfirmationFormModel> {
    return {
      confirmActionInput: null,
    };
  }

  getFormControls(): Controls<ConfirmationFormModel> {
    return {
      confirmActionInput: new UntypedFormControl(null, [Validators.required, YesToConfirmValidator])
    };
  }
}
