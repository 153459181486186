// Module: External Identity Providers

;(function(angular) {
  'use strict';

  angular
    .module('C.idp', [])
    .config(configFn);

  function configFn($stateProvider) {
    var viewAccess = 'FEATURE_FLAGS.idpEnabled && PRINCIPAL_VIEW';
    var modifyAccess = 'FEATURE_FLAGS.idpEnabled && PRINCIPAL_MODIFY';

    $stateProvider
      .state('access-management.sso', {
        title: 'SSO',
        url: '^/admin/access/sso',
        help: 'platform_sso',
        canAccess: viewAccess,
        parentState: 'access-management',
        views: {
          'tab-view': {
            controller: 'idpListCtrl as $ctrl',
            templateUrl: 'app/admin/access-management/idp/list.html',
          },
        },
      })
      .state('add-sso', {
        title: 'Add SSO',
        url: '^/admin/access/sso/add',
        help: 'platform_sso',
        canAccess: modifyAccess,
        parentState: 'access-management.sso',
        controller: 'modifyIdpCtrl as $ctrl',
        templateUrl: 'app/admin/access-management/idp/modify.html'
      })
      .state('edit-sso', {
        title: 'Edit SSO',
        url: '^/admin/access/sso/edit/{id}',
        help: 'platform_sso',
        parentState: 'access-management.sso',
        canAccess: modifyAccess,
        params: {
          id: { type: 'string' },
        },
        controller: 'modifyIdpCtrl as $ctrl',
        templateUrl: 'app/admin/access-management/idp/modify.html',
      });
  }

})(angular);
