import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AttributeFilter, FiltersServiceApi } from '@cohesity/api/reporting';
import { FiltersComponent, ValueFilterSelection } from '@cohesity/helix';
import { enumGroupMap } from '@cohesity/iris-shared-constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseReportFilterComponent } from '../base-report-filter.component';
import { getEnvGroups } from '../env-groups';

/**
 * Environment Group filter using NestedValuePropertyFilter.
 * Top levels are environment groups or environments and environment group can contain environments.
 */
@Component({
  selector: 'iris-rpt-env-group-filter',
  templateUrl: './env-group-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnvGroupFilterComponent extends BaseReportFilterComponent {
  constructor(filters: FiltersComponent, private api: FiltersServiceApi) {
    super(filters, 'environment');
  }

  /**
   * Gets filter values based on reporting resources API
   *
   * @returns  Filter options.
   */
  getFilterValues(): Observable<ValueFilterSelection[]> {
    // Use the list of registered sources to determine what environments we should show in the filter.
    // Otherwise, we might show environments in dms that are not supported yet.
    return this.api.GetResources({ resourceType: 'RegisteredSources' }).pipe(
      // Get the environment from each source
      map(response => {
        const result = [];

        (response?.sources || []).forEach(
          source => (source.environments || []).forEach(
            environment => result.push(environment)
          )
        );
        return result;
      }),

      // Convert to a set and back to an array to remove duplicates environments
      map(environments => [...new Set(environments)].sort()),

      map((environments: any[]) => getEnvGroups(environments, this.translate)),
    );
  }

  /**
   * Maps filter values from UI to API parameters.
   *
   * @param    filterValues  UI filter values.
   * @returns  API parameters.
   */
  toApiValue(filterValues: ValueFilterSelection[]): AttributeFilter {
    if (!filterValues?.length) {
      return null;
    }

    if (filterValues[0].subItems) {
      filterValues = filterValues[0].subItems;
    }

    return {
      attribute: this.property,
      filterType: 'In',
      inFilterParams: {
        attributeDataType: 'String',
        stringFilterValues: filterValues.map(value => value.value as string),
        attributeLabels: filterValues.map(value => value.label),
      }
    };
  }

  /**
   * Maps API parameter values to filter selection for UI.
   *
   * @param    apiValue  API parameter values.
   * @returns  filter selections for UI.
   */
  fromApiValue(apiValue: AttributeFilter): ValueFilterSelection[] {
    const filterValues = apiValue?.inFilterParams.stringFilterValues;

    if (filterValues?.length === 1) {
      return [{
        label: apiValue.inFilterParams.attributeLabels[0],
        value: filterValues[0],
      }];

    // If multiple values, it implies env group is selected.
    } else if (filterValues?.length > 1) {
      const envGroup = enumGroupMap[filterValues[0]];

      if (envGroup) {
        return [{
          label: this.translate(`enum.envGroup.longName.${envGroup}`),
          value: envGroup,
        }];
      }
    }
    return [];
  }
}

/**
 * Environment group filter same as above but with different label.
 */
@Component({
  selector: 'iris-rpt-group-env-group-filter',
  templateUrl: './env-group-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupEnvGroupFilterComponent extends EnvGroupFilterComponent {
  label = 'groupType';
}

/**
 * Environment group filter same as above but with different label.
 */
@Component({
  selector: 'iris-rpt-source-env-group-filter',
  templateUrl: './env-group-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SourceEnvGroupFilterComponent extends EnvGroupFilterComponent {
  label = 'sourceType';
}
