import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AngularMaterialModule } from '../../angular-material.module';
import { DataIdModule } from '../data-id/data-id.module';
import { IconModule } from '../icon/icon.module';
import { SnackBarComponent } from './snack-bar.component';


const components = [
  SnackBarComponent
];

/**
 * Snack Bar Module.
 */
@NgModule({
  imports: [
    AngularMaterialModule,
    CommonModule,
    DataIdModule,
    IconModule,
  ],
  declarations: components,
  exports: components
})
export class SnackBarModule { }
