<span
  *ngIf="!node.icon"
  class="source-icon {{ node.data | sourceIcon }}"
  [class.protected]="node.protected"
  [class.protected-partial]="node.partialProtected"
>
</span>
<cog-icon class="node-icon" [shape]="node.icon" *ngIf="node.icon"></cog-icon>
<span [matTooltip]="getSourceLabelTooltip()"
  [ngClass]="{'detail-label': true, 'user-data-lg': !isPureEnv, 'auto-truncate': !isPureEnv}">
  <a uiSref="object-details.landing"
    [uiParams]="{
      id: node.id,
      cid: passthroughOptionsService.accessClusterId,
      regionId: passthroughOptionsService.regionId,
      workloadType: workloadType
    }"
    cogDataId="object-details-{{node.name}}"
    eventTrackingId="object-details"
    *ngIf="showDetailLink">
    <ng-container *ngTemplateOutlet="nameTemplate"></ng-container>
    <cog-icon class="margin-left-sm" *ngIf="!!isAgentUpgradable"
      [matTooltip]="'sources.upgradeAvailable' | translate"
      size="sm"
      shape="helix:status-info!outline">
    </cog-icon>
  </a>
  <ng-container *ngIf="!showDetailLink">
    <ng-container *ngTemplateOutlet="nameTemplate"></ng-container>
  </ng-container>
</span>
<cog-spinner class="margin-left-sm" size="xs"
  *ngIf="!!isAgentUpgrading"
  [matTooltip]="'upgradingAgent' | translate">
</cog-spinner>
<cog-icon class="margin-left-sm" *ngIf="upgradeStatus === 'kScheduled'"
  [matTooltip]="'upgradeScheduled' | translate"
  size="sm"
  shape="calendar_today">
</cog-icon>
<cog-icon class="margin-left-sm" *ngIf="upgradeError"
  [matTooltip]="'agentUpgrade.failed' | translate"
  size="sm"
  shape="error_outline!critical">
</cog-icon>
<cog-banner class="margin-left-sm vcenter-migration-banner" [hideIcon]=true *ngIf="isVcenterMigrated">
  {{'vcenterMigrated' | translate}}
</cog-banner>
<cog-icon class="margin-left-sm"
  *ngIf="node.moreInfoText"
  [matTooltip]="node.moreInfoText | translate"
  size="sm"
  shape="helix:status-info!info">
</cog-icon>
<cog-icon
  *ngIf="hasConnectionStateProblem"
  [matTooltip]="connectionState || invalidCloudVmStateMessage | translate"
  class="margin-left-sm"
  [inline]="true"
  shape="error_outline!critical"
></cog-icon>
<ng-content></ng-content>

<ng-template #nameTemplate>
  <span *ngIf="queryMatchIndex === -1">{{ node.name }}</span>
  <ng-container *ngIf="queryMatchIndex !== -1">
    <span>{{ node.name.slice(0, queryMatchIndex) }}</span>
    <span class="query-match">{{ node.name.slice(queryMatchIndex, queryMatchIndex + searchQuery.length) }}</span>
    <span>{{ node.name.slice(queryMatchIndex + searchQuery.length) }}</span>
  </ng-container>
  <!-- Showing parent host name in case of application entities since duplicate app entities name would be present
    within application level entities -->
  <span class="padding-left-xs" *ngIf="!nodeContext?.canSelect && node?.parentHostName && nodeContext.selected">
    ({{ node.parentHostName }})
  </span>
</ng-template>
