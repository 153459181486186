import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestInitiator } from '@cohesity/iris-shared-constants';

@Injectable()
export class RequestInitiatorTypeInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    // Default is UIUser
    let requestInitiatorType = RequestInitiator.UIUser;
    // If this is a refresh API then set requestInitiatorType to UIAuto, else
    // set it to UIUser
    const uiAutoRegexUrls = [
      /^data-protect\/runs\/\d+:\d+\/progress$/,
      /^public\/sessionUser\/notifications$/,
      /^v1\/progressMonitors$/
    ];

    for(const regexUrl of uiAutoRegexUrls) {
      // If URL matches any of the regex URLs in uiAutoRegexUrls, then
      // we set the type to UIAuto.
      if (regexUrl.test(req.url)) {
        requestInitiatorType = RequestInitiator.UIAuto;
        break;
      }
    }

    // Clone the request and replace the original headers with
    // cloned headers, updated with the requestInitiatorType.
    const uiReq = req.clone({
      headers: req.headers.append('requestInitiatorType', requestInitiatorType)
    });

    // send cloned request with header to the next handler.
    return next.handle(uiReq);
  }
}
