import { Component } from '@angular/core';
import { Vlan, VlanServiceApi } from '@cohesity/api/v1';
import { AjaxHandlerService } from '@cohesity/utils';
import { AjsUpgradeService } from 'src/app/core/services';

import { BaseProtectionBuilderComponent } from '../../base-protection-builder/base-protection-builder.component';

/**
 * Component for vlan target settings list item in protection.
 * TODO (Tung): update valueType when api model is defined.
 */
@Component({
  selector: 'coh-settings-list-vlan-target',
  template: `
    <coh-settings-list-cluster-interface
      [formControl]="formControl"
      cohIrisHide="isDmsScope">
    </coh-settings-list-cluster-interface>
  `,
})
export class SettingsListVlanTargetComponent extends BaseProtectionBuilderComponent<any, any> {

  /**
   * AJS VLAN Service.
   */
  vlanService: any;

  constructor(
    ajsUpgrade: AjsUpgradeService,
    private vlanServiceApi: VlanServiceApi,
    private ajaxHandlerService: AjaxHandlerService,
  ) {
    super();
    this.vlanService = ajsUpgrade.get('VlanService');
  }

  /**
   * Initialize form control.
   */
  initFormControl() {
    if (this.protectionGroup && this.protectionGroup.vlan) {
      const { vlanId } = this.protectionGroup.vlan;

      this.vlanServiceApi.GetVlans({vlanId}).pipe(
        this.untilDestroy()
      ).subscribe((vlans: Vlan[]) => {
        if (vlans?.length) {
          const transformedVlan = this.vlanService.transformVlan(vlans[0]);

          // Set settings list control value.
          this.formControl.setValue({
            auto: !transformedVlan,
            interfaceGroup: transformedVlan,
          });
        }
      },
      error => this.ajaxHandlerService.handler(error));
    }
  }
}
