import { Injectable } from '@angular/core';
import { ProtectedObject } from '@cohesity/api/v2';
import { NavItem } from '@cohesity/helix';
import { StateService } from '@uirouter/core';

import { ProtectionGroupService } from '../protection-group-shared';
import { ObjectActionCreator } from './object-action-creator';
import { ProtectedObjectsService } from './protected-objects.service';

@Injectable({
  providedIn: 'root',
})
export class AdObjectActionCreator extends ObjectActionCreator {
  constructor(
    objectsService: ProtectedObjectsService,
    protectionGroupService: ProtectionGroupService,
    stateService: StateService) {
    super(objectsService, protectionGroupService, stateService);
  }

  /**
   * Creates and returns AD recovery action.
   *
   * @param object The protected object.
   * @returns The recovery NavItem object.
   */
  createAdRecoverAction(object: ProtectedObject): NavItem {
    if (!object?.latestSnapshotsInfo) {
      return;
    }

    return {
      displayName: 'recover',
      icon: 'restore',
      state: 'active-directory-recovery-search',
      stateParams: {
        query: object.name,
      },
    };
  }
}
