import { Component, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomSubnetElem } from '@cohesity/api/private';
import { CreateForm, createFormProviders } from '@cohesity/shared-forms';

/**
 * The interface for the internal form.
 */
interface GCPSubnetSettingsForm {
  customSubnets: CustomSubnetElem[];
}

@Component({
  selector: 'coh-gcp-fleet-subnet-settings',
  templateUrl: './gcp-subnet-settings.component.html',
  styleUrls: ['./gcp-subnet-settings.component.scss'],
  providers: createFormProviders(GCPFleetSubnetSettingsComponent),
})
export class GCPFleetSubnetSettingsComponent {
  /**
   * Set the available subnet configs. Using the setter maintain the subnet
   * config map to populate the options.
   */
   @Input() set availableSubnetConfigs(subnetConfigs: CustomSubnetElem[]) {
    subnetConfigs.forEach(({ projectId, vpc, subnet }) => {
      const subnetConfig = this.subnetConfigMap[projectId] ?? {};

      this.subnetConfigMap[projectId] = {
        ...subnetConfig,
        [vpc]: new Set([...(subnetConfig[vpc] ?? []), subnet]),
      };
    });
  };

  /**
   * A map from ProjectId to it's VPC's and from VPC's to applicable subnet.
   */
  subnetConfigMap = {};

  /**
   * Gets the custom subnet form array.
   */
  get customSubnetsFormArray() {
    return this.form.formGroup.controls.customSubnets as UntypedFormArray;
  }

  /**
   * The CVA form.
   */
  form = new CreateForm<GCPSubnetSettingsForm['customSubnets'], GCPSubnetSettingsForm>(this, {
    formControls: {
      customSubnets: new UntypedFormArray([], this.uniqueSubnetValidator)
    },
    // When setting the form group up using initial values, add the FormControl
    // for each value into the formArray. Also handle null cases.
    transformToFormGroup: value => {
      const customSubnets = (value ?? [{}]).map(({ projectId = null, vpc = null, subnet = null }) => {
        const config = { projectId, vpc, subnet };
        this.addSubnetConfig(config);
        return config;
      });

      return { customSubnets };
    },
    transformFromFormGroup: ({ customSubnets }) =>
      customSubnets.filter(({ projectId, vpc, subnet }) => projectId && vpc && subnet)
  });

  /**
   * Gets the available project id options.
   *
   * @returns The project ID list.
   */
  getProjectIdList(): string[] {
    return Object.keys(this.subnetConfigMap);
  }

  /**
   * Gets the available VPC options according to the projectId selected.
   *
   * @param index The index for which the options are needed.
   * @returns The VPC options.
   */
  getVpcList(index: number): string[] {
    return Object.keys(this.subnetConfigMap[this.customSubnetsFormArray.at(index).value?.projectId] ?? {});
  }

  /**
   * Gets the available subnet options based on the projectId and VPC selected.
   *
   * @param index The index for which the options are needed.
   * @returns The Subnet List.
   */
  getSubnetList(index: number): string[] {
    const { projectId, vpc } = this.customSubnetsFormArray.at(index).value ?? {};

    return this.subnetConfigMap[projectId] ? this.subnetConfigMap[projectId][vpc] ?? [] : [];
  }

  /**
   * Add the subnet config
   *
   * @param config The subnet config value
   */
  addSubnetConfig(config?: CustomSubnetElem) {
    const formGroup = new UntypedFormGroup({
      projectId: new UntypedFormControl(config?.projectId, Validators.required),
      vpc: new UntypedFormControl(config?.vpc, Validators.required),
      subnet: new UntypedFormControl(config?.subnet, Validators.required),
    });

    this.customSubnetsFormArray.push(formGroup);
  }

  /**
   * Removes the subnet config.
   *
   * @param index The index to remove the config from.
   */
  removeSubnetConfig(index: number) {
    this.customSubnetsFormArray.removeAt(index);
  }

  /**
   * Whether the add configuration button is disabled. Disable the add button
   * when the form is invalid or has more than 20 subnets.
   *
   * @param index The index to check.
   */
  addActionDisabled(index: number): boolean {
    const { projectId, vpc, subnet } = this.customSubnetsFormArray.at(index).value;

    // Check if the formControl is invalid.
    const formControlInvalid = !(projectId && vpc && subnet);

    return formControlInvalid || index >= 19;
  }

  /**
   * Validator function to validate the FormArray values to be unique.
   *
   * @param formArray The formArray to validate
   * @returns The validation error.
   */
  uniqueSubnetValidator(formArray: UntypedFormArray) {
    const isValid = formArray.value.length === new Set([...formArray.value.map(JSON.stringify)]).size;

    return isValid ? null : { unique: true };
  };
}
