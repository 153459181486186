import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { AttributeFilter, Component } from '@cohesity/api/reporting';
import { TranslateFn } from '@cohesity/helix';

import { Dimensions, Measurements, ReportItemFilter, ReportItemRenderer } from '../../iris-reporting.model';

/**
 * Mapping of dimension key for setting point custom data.
 */
const keyMap = {
  sourceName: 'sourceUuid',
  systemName: 'systemId',
};

/**
 * A base implementation for writing report item renderers.
 */
@Directive()
export class BaseReportItemRendererComponent implements ReportItemRenderer {
  /**
   * Whether the user can edit the report.
   */
  allowEdit: boolean;

  /**
   * Date format to display in header.
   */
  dateFormat = '#MMM D';

  /**
   * Data structure for point under plotOptions for charts.
   * Pass parameters to parent when user click on a point.
   */
  chartPlotOptions = {
    cursor: 'pointer',
    point: {
      events: {
        click: (event) => {
          const parameters = (event.point as any).custom;

          if (parameters) {
            this.itemFilterChange.emit({ parameters });
          }
        },
      },
    },
  };

  /**
   *  an object of key value which can be used as filters key value when apply manually.
   */
  @Input() customFilterOptions: Record<string, string> = null;

  /**
   * Measurements values
   */
  @Input() measurements: Measurements = [];

  /**
   * Dimension values
   */
  @Input() dimensions: Dimensions = [];

  /**
   * This is all of the components that are used by this component, determined
   * by the componentNames array in the layout config. Usually there is only one.
   */
  @Input() components: Component[];

  /**
   * Whether to show the table pagination controls
   */
  @Input() isPrintMedia = false;

  /**
   * Perform server side sort, filter if true.
   */
  @Input() isAsyncData: boolean;

  /**
   * Report filters
   */
  @Input() reportFilters: AttributeFilter[];

  /**
   * An event is emitted whenever an item is edits
   */
  @Output() itemEdited = new EventEmitter<any>();

  /**
   * Emits event when filter, sort, pagination change.
   */
  @Output() itemFilterChange = new EventEmitter<ReportItemFilter>();

  /**
   * Translate function for displaying strings.
   */
  @Input() translate: TranslateFn = key => key;

  /**
   * Returns the data from the first component. Most report items can use this to access
   * data as needed.
   */
  get data(): any[] {
    return this.components?.length && this.components[0].data || [];
  }

  /**
   * Sets custom data by key, value pair.
   * Sometimes the dimension key is for display only but not for filter so
   * we check the key here and use the mapped key which is relevant for filter,
   * e.g., sourceName to sourceUuid.
   *
   * @param key     Attribute name used to set data.
   * @param value   Attribute value to be set.
   * @param custom  Object to be updated.
   */
  private updateCustomData(
    key: string,
    value: string | number,
    custom: Record<string, string | number>,
  ) {
    // avoid sending null or generated option (other) parameters
    if (key && value && value !== 'other') {
      if (Object.keys(keyMap).includes(key)) {
        const match = this.data.find(item => item[key] === value);

        if (match?.[keyMap[key]]) {
          custom[keyMap[key]] = match[keyMap[key]];
        }
      } else {
        custom[key] = value;
      }
    }
  }

  /**
   * Gets point custom data for emitting when user click on the point.
   *
   * @param key    Key data for first dimension.
   * @param value  Value data for second dimension.
   * @returns  Custom data for emitting for a point
   */
  getCustomData(key: string, value: string | number): Record<string, string | number> {
    let custom = {} as Record<string, string | number>;

    this.updateCustomData(this.dimensions[0]?.dimensionKey, value, custom);
    this.updateCustomData(this.dimensions[1]?.dimensionKey, key, custom);

    if (this.customFilterOptions) {
      custom = {
        ...this.customFilterOptions,
        ...custom
      };
    }

    return Object.keys(custom).length ? custom : null;
  }

  /**
   * Gets data for a component by name. This is used when an item is derived from multiple
   * component values (usually glancebars)
   *
   * @param componentName The name of the component to look up.
   * @returns The component's data.
   */
  getDataForComponent(componentName: string): any[] {
    return (this.components || []).find(comp => comp.name === componentName)?.data || [];
  }

  /**
   * Gets the date timestamp from time range filter.
   *
   * @param   isLowerBound  Returns lower bound if true. Otherwise, upper bound
   * @returns date range filter timestamp.
   */
  getFilterDate(isLowerBound = false): number {
    const dateFilter = this.reportFilters.find(f => f.filterType === 'TimeRange');

    if (dateFilter) {
      return dateFilter.timeRangeFilterParams[isLowerBound ? 'lowerBound' : 'upperBound'];
    }
    return undefined;
  }
}
