import { PatternSensitivityCount, Range } from '@cohesity/api/argus';

/**
 * Return Highest sensitivity present in run details in the order of high, medium and low
 *
 * @param patternSensitivityCountList pattern sensitivity count list
 * @returns pattern sensitivity count
 */
export const getHighestSensitivityCount = (
  patternSensitivityCountList: PatternSensitivityCount[]
): PatternSensitivityCount => {
  let patternSensitivityCount: PatternSensitivityCount;

  patternSensitivityCountList?.forEach(psc => {
    if (patternSensitivityCount?.sensitivity === Range.high) {
      return;
    }
    if (!patternSensitivityCount || psc.sensitivity === Range.high) {
      patternSensitivityCount = psc;
    } else {
      if (patternSensitivityCount.sensitivity === Range.low) {
        patternSensitivityCount = psc;
      }
    }
  });

  return patternSensitivityCount;
};
