//  Component: cCopy

;(function(angular, undefined) {
  'use strict';

  /**
   * @ngdoc directive
   * @name C.cCopy:cCopy
   * @function
   *
   * @description
   * This directive provides a means of copying the model value from a
   * ui-select (multiple/tags), input and textarea.
   *
   * This directive assumes clipboard.js is loaded and available on window
   * NOTE: Currently this only (gracefully) supports ngModel values that are
   * simple values (int or string) or arrays of simple values.
   *
   * @example
      <ui-select ng-model="remoteIps" c-copy>
        ...
      </ui-select>

      <input ng-model="encryptionKey" c-copy>
   */

  angular
    .module('C.cCopy', [])
    .directive('cCopy', directiveFn);

  function directiveFn($rootScope, $compile, cMessage) {

    return {
      link: cCopyLinkFn,
      restrict: 'A',
      require: 'ngModel',
    };

    // linking function
    function cCopyLinkFn(scope, elem, attrs, ngModelCtrl) {
      var cCopyScope = $rootScope.$new(true);
      var modelRenderFn;

      // initialize disabled state
      setResetDisabledState();

      // create icon to be injected into the DOM to recieve the users click
      var copyBtn = $compile(
        ['<div class="c-copy icn-md icn-copy" ',
        'ng-disabled="disabled" ',
        'tooltip-enable="!disabled" ',
        'uib-tooltip="{{::\'copyToClipboard\' | translate}}"></div>'].join('')
      )(cCopyScope);

      // add the icon to the DOM
      if (elem.hasClass('ui-select-container')) {
        elem.append(copyBtn);
      } else {
        elem.wrap('<div class="c-copy-wrapper"></div>');
        elem.parent().append(copyBtn);
      }

      // register a click handler on the icon
      copyBtn.on('click', clickCopyBtn);

      // watch for view changes
      ngModelCtrl.$viewChangeListeners.push(setResetDisabledState);

      // watch for model changes
      if (angular.isFunction(ngModelCtrl.$render)) {
        modelRenderFn = ngModelCtrl.$render;

        // proxy the current render fn to capture model change
        ngModelCtrl.$render = function cCopyRenderProxyFn() {
          setResetDisabledState();
          return modelRenderFn.apply(this, arguments);
        };
      } else {
        ngModelCtrl.$render = setResetDisabledState;
      }

      /**
       * Update internal disabled state based on model current value.
       *
       * @method   setResetDisabledState
       */
      function setResetDisabledState() {
        cCopyScope.disabled = ngModelCtrl.$isEmpty(ngModelCtrl.$modelValue);
      }

      /**
       * Copy current model value to clipboard.
       *
       * @method   clickCopyBtn
       */
      function clickCopyBtn() {
        var clipboard;
        var clipInput;

        // Convert model values to an array.
        var values = [].concat(ngModelCtrl.$modelValue);

        if (attrs.copyKey) {
          // If a copyKey is specified, use that to get the value to copy.
          values = values.map(function mapValue(value) {
            return value[attrs.copyKey];
          })
        }

        // Create the string to copy.
        var clipValue = values.join(', ');

        // Clipboard lib throws console error when value is empty or not defined
        if (clipValue === '' || clipValue === undefined || clipValue === null) {
          return;
        }

        clipInput = document.createElement('a');
        clipInput.setAttribute('data-clipboard-text', clipValue);

        clipboard = new ClipboardJS(clipInput);

        // handle success case of copying to clipboard
        clipboard.on('success', function clipboardSuccess() {
          scope.$apply(showMessage('success', clipValue));
          clipboard.destroy();
        });

        // handle error case of copying to clipboard
        clipboard.on('error', function clipboardFail() {
          scope.$apply(showMessage('error', clipValue));
          clipboard.destroy();
        });

        // fire a click on the input to trigger clipboardjs handling
        clipInput.click();
      }

      /**
       * Shows a success or error message for the copy to clipboard attempt.
       *
       * @param   {string}   [messageType='error']   The message type success or
       *                                             error.
       * @param   {string}   [content]               The content copied to the
       *                                             clipboard
       */
      function showMessage(messageType, content) {
        if (messageType === 'success') {
          cMessage.success({
            textKey: 'copiedToClipboard',
            textKeyContext: {
              item: content,
            },
          });
        } else {
          cMessage.error({
            textKey: 'copiedToClipboardError',
          });
        }
      }

      // cleanup on exit
      scope.$on('$destroy', function cleanUp() {
        copyBtn.off('click');
        cCopyScope.$destroy();
      });

    }

  }

})(angular);
