<h2 mat-dialog-title>{{dialogParam.title | translate}}</h2>
<mat-dialog-content class="margin-bottom"
  [innerHTML]="dialogParam.text | translate: dialogParam.textParams || {}">
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button
    id="cancel-confirm-btn"
    mat-dialog-close>
    {{'cancel' | translate}}
  </button>
  <button mat-flat-button
    id="delete-confirm-btn"
    [mat-dialog-close]="true"
    color="primary"
    cdkFocusInitial>
    {{'delete' | translate}}
  </button>
</mat-dialog-actions>
