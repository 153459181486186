<form class="verify-container container"
  [formGroup]="formGroup"
  cohValidateOnSubmit
  (validatedSubmit)="login()">
  <h2 translate>multiFactorAuthentication</h2>
  <coh-token-verification *ngIf="isTokenView"
    [formControlName]="formControlNames.securityToken"></coh-token-verification>
  <coh-email-verification *ngIf="isEmailView"
    [formControlName]="formControlNames.emailToken"></coh-email-verification>
  <div class="sign-in-btn-wrapper">
    <span class="back-sign-btn">
      <button type="button"
        cogDataId="email-code-btn"
        *ngIf="mfaService.isHybridAuthentication"
        mat-button
        color="primary"
        (click)="handleBackClick()">
        {{backBtnText | translate}}
      </button>
    </span>
    <button type="submit"
      cogDataId="sign-in-btn"
      mat-flat-button
      color="primary">
      {{'signIn' | translate}}
    </button>
  </div>
</form>
