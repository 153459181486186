/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PackageDetails } from '../models/package-details';
import { DownloadPackageResult } from '../models/download-package-result';
import { DownloadPackageParameters } from '../models/download-package-parameters';
@Injectable({
  providedIn: 'root',
})
class PackagesService extends __BaseService {
  static readonly ListPackagesPath = '/public/packages';
  static readonly DownloadPackagePath = '/public/packages/url';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List all currently installed packages on a Cohesity Cluster.
   *
   * Sends a request retrieve information about all packages which are currently
   * installed on the Cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  ListPackagesResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<Array<PackageDetails>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/packages`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PackageDetails>>;
      })
    );
  }
  /**
   * List all currently installed packages on a Cohesity Cluster.
   *
   * Sends a request retrieve information about all packages which are currently
   * installed on the Cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  ListPackages(regionId?: string,
    accessClusterId?: number): __Observable<Array<PackageDetails>> {
    return this.ListPackagesResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as Array<PackageDetails>)
    );
  }

  /**
   * Download a package to the Cluster by providing a URL where the package is
   * hosted.
   *
   * Sends a request to download a package from a URL to the Cluster.
   * @param params The `PackagesService.DownloadPackageParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  DownloadPackageResponse(params: PackagesService.DownloadPackageParams): __Observable<__StrictHttpResponse<DownloadPackageResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/packages/url`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DownloadPackageResult>;
      })
    );
  }
  /**
   * Download a package to the Cluster by providing a URL where the package is
   * hosted.
   *
   * Sends a request to download a package from a URL to the Cluster.
   * @param params The `PackagesService.DownloadPackageParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  DownloadPackage(params: PackagesService.DownloadPackageParams): __Observable<DownloadPackageResult> {
    return this.DownloadPackageResponse(params).pipe(
      __map(_r => _r.body as DownloadPackageResult)
    );
  }
}

module PackagesService {

  /**
   * Parameters for DownloadPackage
   */
  export interface DownloadPackageParams {
    body: DownloadPackageParameters;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { PackagesService }
