

<div class="flex-row vertical-align"
  *ngFor="let email of model; let i = index">
  <coh-localized-email [model]="email"
    [hideLabel]="hideLabel"
    [enableRecipientType]="enableRecipientType"
    [withoutLocale]="withoutLocale"></coh-localized-email>
  <a id="localized-email-remove-button-{{i}}"
    class="icn-xs icn-remove"
    (click)="model.splice(i, 1)">
  </a>
</div>
<div class="margin-top-xs">
  <a id="localized-email-add-button"
    class="icon-link"
    (click)="addMore()">
    <i class="icn-xs icn-add enclosed"></i>
    {{'add'| translate}}
  </a>
</div>
