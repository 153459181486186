<!-- Multi View Selection Note -->
<div *ngIf="!(loading$ | async) && multiSelect && !allowMultiStorageDomainView"
  class="c-message-inline icon-message info">
  <p cogDataId="multi-view-selection-title">{{ 'multiViewSelectionNote' | translate }}</p>
</div>

<!-- Filters -->
<cog-filters #filters>
  <!-- Organization filter -->
  <div *ngIf="(organizationFilterList$ | async) && multiTenancyEnabled">
    <cog-value-property-filter
      *cogFilterDef="let params = params;
        let filter;
        quickFilter true;
        key 'organization';
        label 'organization' | translate;
        filterType 'valueProperty'"
      [filterDefParams]="params"
      [filter]="filter"
      [allowMultiple]="false"
      [autoApply]="true"
      [filterValues]="(organizationFilterList$ | async) || []">
    </cog-value-property-filter>
  </div>

  <!-- Storage Domain filter -->
  <cog-value-property-filter
    *cogFilterDef="let params = params;
      let filter;
      quickFilter true;
      key 'storageDomain';
      label 'storageDomain' | translate;
      filterType 'valueProperty'"
    [filterDefParams]="params"
    [filter]="filter"
    [allowMultiple]="false"
    [autoApply]="true"
    [filterValues]="(storageDomainFilterList$ | async) || []">
    </cog-value-property-filter>

  <!-- View name filter -->
  <cog-search-property-filter
    class="pull-right"
    *cogFilterDef="let filter;
      key: 'name';
      label: 'viewName' | translate;
      quickFilter: true;
      filterType: 'searchProperty'"
    [filter]="filter">
  </cog-search-property-filter>
</cog-filters>

<cog-spinner *ngIf="loading$ | async"></cog-spinner>

<cog-table name="views-selection-table"
  [source]="views || []"
  [persistSelection]="true"
  (renderedDataChange)="renderSelection()"
  [selection]="selection"
  [isAllSelectedFn]="isAllSelected"
  [toggleSelectAllFn]="toggleAllSelection"
  #viewsTable>
  <table mat-table>
    <!-- View name column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>{{ 'viewName' | translate }}</th>
      <td mat-cell *matCellDef="let row;">
        <div class="cog-table-object">
          <cog-icon  shape="{{row.viewProtection ? 'helix:object-view-protected' : 'helix:object-view'}}">
          </cog-icon>
          <div class="cog-table-object-body">
            <div class="flex-truncate-wrapper">
              <span class="cog-table-object-name flex-truncate-text">{{row.name}}</span>
            </div>
            <span class="view-category">
              {{row.category ? ('enum.view.category.' + row.category | translate) : ''}}
            </span>
          </div>
        </div>
      </td>
    </ng-container>

    <!-- storage domain name column -->
    <ng-container matColumnDef="storageDomainName">
      <th mat-header-cell *matHeaderCellDef>{{ 'storageDomain' | translate }}</th>
      <td mat-cell *matCellDef="let row;">
        <span class="user-data-x">{{ row.storageDomainName }}</span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"></tr>
  </table>
  <mat-paginator [pageSize]="10"></mat-paginator>
</cog-table>

<!-- TODO: add empty state holder -->
