// Directive: Validate restore point in time

(function _validatePitDirective(angular) {
  angular.module('C.dbRestore')
    .directive('cValidateRestorePit', validateRestorePitFn);

  /**
   * @ngdoc directive
   * @name C.dbRestore:cValidateRestorePit
   *
   * @description
   * This directive facilitates the validation of a restore time by calling the
   * API and reporting the returned message back, if any.
   *
   * @example
   * <ANY ng-model="$ctrl.model"
   *   c-validate-restore-pit
   *   on-invalid-pit="$ctrl.callback(message)">
   * </ANY>
   *
   * @method   validateRestorePitFn
   *
   * @param    {Object}   _                App dependency.
   * @param    {Object}   cMessage         App dependency.
   * @param    {Object}   evalAJAX         App dependency.
   * @param    {Object}   RestoreService   App dependency.
   * @param    {Object}   $q               App dependency.
   * @return   {Object}   The directive definition object.
   */
  function validateRestorePitFn(_, cMessage, evalAJAX, RestoreService, $q) {
    return {
      restrict: 'A',
      require: 'ngModel',
      scope: false,
      link: linkFn,
    };

    /**
     * Angular Directive link function.
     *
     * @method linkFn
     *
     * @param   {Object}   scope       The directive scope.
     * @param   {Object}   elem        The directive element.
     * @param   {Object}   attrs       Collection of element attributes.
     * @param   {Object}   modelCtrl   The ngModel controller object.
     */
    function linkFn(scope, elem, attrs, modelCtrl) {
      // We need a leading debounce to return a promise soon after the validator
      // runs. Without this, AngularJS will throw an error.
      var debounceOptions = { leading: true };

      // Register the validator.
      modelCtrl.$asyncValidators.restoreTime = _.debounce(
        validateRestoreTime, 300, debounceOptions);

      /**
       * The debounced restore time validation function. It is called by
       * angular when the model value changes.
       *
       * @return   {Object}   Promise which gets resolved or rejected on
       *                      successful and unsuccessful validation
       *                      respectively.
       */
      function validateRestoreTime() {
        var params = getRestoreTask().restoreAppParams;

        // Call API to validate chosen restore point
        return RestoreService.validateDatabaseRestoreTime(params)
          .then(function timeChecked(response) {
            var selections = scope.$ctrl.selections || {};

            if (response.error) {
              cMessage.error({
                textKey: response.error.errorMsg ||
                  'recovery.database.pointInTimeUnavailable',
              });

              return $q.reject();
            } else if (!selections.pointInTime && response.userMessage) {
              scope.$eval(attrs.onInvalidPit, {
                message: response.userMessage,
              });

              return $q.reject();
            }

            return $q.resolve();
          })
          .catch(evalAJAX.errorMessage);
      }

      /**
       * Returns the restore task object, which is compatible as the payload
       * for restore time validation API.
       *
       * @return   {Object}   The restore task obejct.
       */
      function getRestoreTask() {
        var task = _.cloneDeep(scope.task);
        var snapshot = scope.$ctrl.snapshot;
        var restoreAppObject = task.restoreAppParams.restoreAppObjectVec[0];
        var restoreParams = restoreAppObject.restoreParams;

        _.unset(restoreAppObject,
          'restoreParams.sqlRestoreParams.secondaryDataFileDestinationVec');

        // Determine database restore params.
        var dbParams = scope.isOracle ?
          restoreParams.oracleRestoreParams :
          restoreParams.sqlRestoreParams;

        _.assign(task.restoreAppParams.ownerRestoreInfo.ownerObject, {
          jobInstanceId: snapshot.instanceId.jobInstanceId,
          startTimeUsecs: +snapshot.instanceId.jobStartTimeUsecs,
        });

        // Populate restore time.
        dbParams.restoreTimeSecs = scope.$ctrl.getRestoreTimeSecs();
        return task;
      }
    }
  }
})(angular);
