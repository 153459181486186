/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Tenant } from '../models/tenant';
import { TenantUpdate } from '../models/tenant-update';
import { TenantCreateParameters } from '../models/tenant-create-parameters';
import { TenantIdData } from '../models/tenant-id-data';
import { TenantActiveDirectoryUpdate } from '../models/tenant-active-directory-update';
import { TenantActiveDirectoryUpdateParameters } from '../models/tenant-active-directory-update-parameters';
import { TenantEntityUpdate } from '../models/tenant-entity-update';
import { TenantEntityUpdateParameters } from '../models/tenant-entity-update-parameters';
import { Group } from '../models/group';
import { TenantGroupUpdateParameters } from '../models/tenant-group-update-parameters';
import { TenantLdapProviderUpdate } from '../models/tenant-ldap-provider-update';
import { TenantLdapProviderUpdateParameters } from '../models/tenant-ldap-provider-update-parameters';
import { TenantProtectionPolicyUpdate } from '../models/tenant-protection-policy-update';
import { TenantProtectionPolicyUpdateParameters } from '../models/tenant-protection-policy-update-parameters';
import { TenantProtectionJobUpdate } from '../models/tenant-protection-job-update';
import { TenantProtectionJobUpdateParameters } from '../models/tenant-protection-job-update-parameters';
import { TenantProxy } from '../models/tenant-proxy';
import { User } from '../models/user';
import { TenantUserUpdateParameters } from '../models/tenant-user-update-parameters';
import { TenantViewUpdate } from '../models/tenant-view-update';
import { TenantViewUpdateParameters } from '../models/tenant-view-update-parameters';
import { TenantViewBoxUpdate } from '../models/tenant-view-box-update';
import { TenantViewBoxUpdateParameters } from '../models/tenant-view-box-update-parameters';
import { TenantVlanUpdate } from '../models/tenant-vlan-update';
import { TenantVlanUpdateParameters } from '../models/tenant-vlan-update-parameters';
@Injectable({
  providedIn: 'root',
})
class TenantService extends __BaseService {
  static readonly GetTenantsPath = '/public/tenants';
  static readonly UpdateTenantPath = '/public/tenants';
  static readonly CreateTenantPath = '/public/tenants';
  static readonly DeleteTenantPath = '/public/tenants';
  static readonly UpdateTenantActiveDirectoryPath = '/public/tenants/activeDirectory';
  static readonly UpdateTenantEntityPath = '/public/tenants/entity';
  static readonly UpdateTenantGroupsPath = '/public/tenants/groups';
  static readonly UpdateTenantLdapProviderPath = '/public/tenants/ldapProvider';
  static readonly UpdateTenantProtectionPolicyPath = '/public/tenants/policy';
  static readonly UpdateTenantProtectionJobPath = '/public/tenants/protectionJob';
  static readonly GetTenantsProxiesPath = '/public/tenants/proxies';
  static readonly GetTenantsProxyConfigPath = '/public/tenants/proxy/config';
  static readonly DownloadTenantsProxyPath = '/public/tenants/proxy/image';
  static readonly UpdateTenantUsersPath = '/public/tenants/users';
  static readonly UpdateTenantViewPath = '/public/tenants/view';
  static readonly UpdateTenantViewBoxPath = '/public/tenants/viewBox';
  static readonly UpdateTenantVlanPath = '/public/tenants/vlan';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List the tenants on the cohesity cluster filtered by tenant ID prefixed to list
   * tenants for the respective tenant admin.
   *
   * Returns the list of tenants.
   * @param params The `TenantService.GetTenantsParams` containing the following parameters:
   *
   * - `status`: Filter by tenant status.
   *
   * - `skipInvalidIds`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `properties`: 'ViewBox' indicates view box data for tenant.
   *   'Vlan' indicates vlan data for tenant.
   *   'ProtectionPolicy' indicates protection policy for tenant.
   *   'ProtectionJob' indicates protection job for tenant.
   *   'Entity' indicates entity data for tenant.
   *   'Views' indicates view data for tenant.
   *   'ActiveDirectory' indicates active directory for tenant.
   *   'LdapProvider' indicates ldap provider for tenant.
   *   'SwiftConfig' indicates Swift configuration for tenant.
   *
   * - `includeSelf`:
   *
   * - `ids`:
   *
   * - `hierarchy`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Get Tenants Response.
   */
  GetTenantsResponse(params: TenantService.GetTenantsParams): __Observable<__StrictHttpResponse<Array<Tenant>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.status || []).forEach(val => {if (val != null) __params = __params.append('status', val.toString())});
    if (params.skipInvalidIds != null) __params = __params.set('skipInvalidIds', params.skipInvalidIds.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.properties || []).forEach(val => {if (val != null) __params = __params.append('properties', val.toString())});
    if (params.includeSelf != null) __params = __params.set('includeSelf', params.includeSelf.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.hierarchy != null) __params = __params.set('hierarchy', params.hierarchy.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/tenants`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Tenant>>;
      })
    );
  }
  /**
   * List the tenants on the cohesity cluster filtered by tenant ID prefixed to list
   * tenants for the respective tenant admin.
   *
   * Returns the list of tenants.
   * @param params The `TenantService.GetTenantsParams` containing the following parameters:
   *
   * - `status`: Filter by tenant status.
   *
   * - `skipInvalidIds`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `properties`: 'ViewBox' indicates view box data for tenant.
   *   'Vlan' indicates vlan data for tenant.
   *   'ProtectionPolicy' indicates protection policy for tenant.
   *   'ProtectionJob' indicates protection job for tenant.
   *   'Entity' indicates entity data for tenant.
   *   'Views' indicates view data for tenant.
   *   'ActiveDirectory' indicates active directory for tenant.
   *   'LdapProvider' indicates ldap provider for tenant.
   *   'SwiftConfig' indicates Swift configuration for tenant.
   *
   * - `includeSelf`:
   *
   * - `ids`:
   *
   * - `hierarchy`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Get Tenants Response.
   */
  GetTenants(params: TenantService.GetTenantsParams): __Observable<Array<Tenant>> {
    return this.GetTenantsResponse(params).pipe(
      __map(_r => _r.body as Array<Tenant>)
    );
  }

  /**
   * Update an existing tenant on the Cohesity Cluster.
   *
   * Returns the tenant that was updated on the Cohesity Cluster.
   * @param params The `TenantService.UpdateTenantParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update existing tenant.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Update Tenants Response
   */
  UpdateTenantResponse(params: TenantService.UpdateTenantParams): __Observable<__StrictHttpResponse<Tenant>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/tenants`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Tenant>;
      })
    );
  }
  /**
   * Update an existing tenant on the Cohesity Cluster.
   *
   * Returns the tenant that was updated on the Cohesity Cluster.
   * @param params The `TenantService.UpdateTenantParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update existing tenant.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Update Tenants Response
   */
  UpdateTenant(params: TenantService.UpdateTenantParams): __Observable<Tenant> {
    return this.UpdateTenantResponse(params).pipe(
      __map(_r => _r.body as Tenant)
    );
  }

  /**
   * Create/add a new tenant to the Cohesity Cluster.
   *
   * A tenant is required to support MultiTenant architecture for service provider
   * (SP) to facilitate data and view segregations in the Cohesity Dashboard.
   *
   * Returns the created/added tenant.
   * @param params The `TenantService.CreateTenantParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to add or create a new tenant.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Create Tenants response
   */
  CreateTenantResponse(params: TenantService.CreateTenantParams): __Observable<__StrictHttpResponse<Tenant>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/tenants`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Tenant>;
      })
    );
  }
  /**
   * Create/add a new tenant to the Cohesity Cluster.
   *
   * A tenant is required to support MultiTenant architecture for service provider
   * (SP) to facilitate data and view segregations in the Cohesity Dashboard.
   *
   * Returns the created/added tenant.
   * @param params The `TenantService.CreateTenantParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to add or create a new tenant.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Create Tenants response
   */
  CreateTenant(params: TenantService.CreateTenantParams): __Observable<Tenant> {
    return this.CreateTenantResponse(params).pipe(
      __map(_r => _r.body as Tenant)
    );
  }

  /**
   * Delete an existing tenant on the Cohesity Cluster.
   *
   * Returns success if the specified tenant is deleted.
   * @param params The `TenantService.DeleteTenantParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Get Tenants Response.
   */
  DeleteTenantResponse(params: TenantService.DeleteTenantParams): __Observable<__StrictHttpResponse<Array<Tenant>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/tenants`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Tenant>>;
      })
    );
  }
  /**
   * Delete an existing tenant on the Cohesity Cluster.
   *
   * Returns success if the specified tenant is deleted.
   * @param params The `TenantService.DeleteTenantParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Get Tenants Response.
   */
  DeleteTenant(params: TenantService.DeleteTenantParams): __Observable<Array<Tenant>> {
    return this.DeleteTenantResponse(params).pipe(
      __map(_r => _r.body as Array<Tenant>)
    );
  }

  /**
   * Update Active Directory for an existing tenant on the Cohesity Cluster.
   *
   * Returns success if the update for Active Directory is successful for specified
   * tenant.
   * @param params The `TenantService.UpdateTenantActiveDirectoryParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update existing active directories.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Tenant Active Directory Mapping Response.
   */
  UpdateTenantActiveDirectoryResponse(params: TenantService.UpdateTenantActiveDirectoryParams): __Observable<__StrictHttpResponse<TenantActiveDirectoryUpdate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/tenants/activeDirectory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantActiveDirectoryUpdate>;
      })
    );
  }
  /**
   * Update Active Directory for an existing tenant on the Cohesity Cluster.
   *
   * Returns success if the update for Active Directory is successful for specified
   * tenant.
   * @param params The `TenantService.UpdateTenantActiveDirectoryParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update existing active directories.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Tenant Active Directory Mapping Response.
   */
  UpdateTenantActiveDirectory(params: TenantService.UpdateTenantActiveDirectoryParams): __Observable<TenantActiveDirectoryUpdate> {
    return this.UpdateTenantActiveDirectoryResponse(params).pipe(
      __map(_r => _r.body as TenantActiveDirectoryUpdate)
    );
  }

  /**
   * Update entity permission for an existing tenant on the Cohesity Cluster.
   *
   * Returns success if the update for entity permission data is successful for
   * specified tenant.
   * @param params The `TenantService.UpdateTenantEntityParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to associate entity for tenant.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Tenant Entity Update Response.
   */
  UpdateTenantEntityResponse(params: TenantService.UpdateTenantEntityParams): __Observable<__StrictHttpResponse<TenantEntityUpdate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/tenants/entity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantEntityUpdate>;
      })
    );
  }
  /**
   * Update entity permission for an existing tenant on the Cohesity Cluster.
   *
   * Returns success if the update for entity permission data is successful for
   * specified tenant.
   * @param params The `TenantService.UpdateTenantEntityParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to associate entity for tenant.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Tenant Entity Update Response.
   */
  UpdateTenantEntity(params: TenantService.UpdateTenantEntityParams): __Observable<TenantEntityUpdate> {
    return this.UpdateTenantEntityResponse(params).pipe(
      __map(_r => _r.body as TenantEntityUpdate)
    );
  }

  /**
   * Update existing groups to an existing tenant on the Cohesity Cluster.
   *
   * Returns success if the update for groups is successful for specified tenant.
   * @param params The `TenantService.UpdateTenantGroupsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update existing tenant groups.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Tenant Group Mapping Response.
   */
  UpdateTenantGroupsResponse(params: TenantService.UpdateTenantGroupsParams): __Observable<__StrictHttpResponse<Array<Group>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/tenants/groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Group>>;
      })
    );
  }
  /**
   * Update existing groups to an existing tenant on the Cohesity Cluster.
   *
   * Returns success if the update for groups is successful for specified tenant.
   * @param params The `TenantService.UpdateTenantGroupsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update existing tenant groups.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Tenant Group Mapping Response.
   */
  UpdateTenantGroups(params: TenantService.UpdateTenantGroupsParams): __Observable<Array<Group>> {
    return this.UpdateTenantGroupsResponse(params).pipe(
      __map(_r => _r.body as Array<Group>)
    );
  }

  /**
   * Update Ldap Providers for an existing tenant on the Cohesity Cluster.
   *
   * Returns success if the update for Ldap Providers is successful for specified
   * tenant.
   * @param params The `TenantService.UpdateTenantLdapProviderParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update existing ldap providers.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Tenant Ldap Provider Mapping Response.
   */
  UpdateTenantLdapProviderResponse(params: TenantService.UpdateTenantLdapProviderParams): __Observable<__StrictHttpResponse<TenantLdapProviderUpdate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/tenants/ldapProvider`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantLdapProviderUpdate>;
      })
    );
  }
  /**
   * Update Ldap Providers for an existing tenant on the Cohesity Cluster.
   *
   * Returns success if the update for Ldap Providers is successful for specified
   * tenant.
   * @param params The `TenantService.UpdateTenantLdapProviderParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update existing ldap providers.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Tenant Ldap Provider Mapping Response.
   */
  UpdateTenantLdapProvider(params: TenantService.UpdateTenantLdapProviderParams): __Observable<TenantLdapProviderUpdate> {
    return this.UpdateTenantLdapProviderResponse(params).pipe(
      __map(_r => _r.body as TenantLdapProviderUpdate)
    );
  }

  /**
   * Update protection policy permission for an existing tenant on the Cohesity
   * Cluster.
   *
   * Returns success if the update for protection policy permission data is
   * successful for the specified tenant.
   * @param params The `TenantService.UpdateTenantProtectionPolicyParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update existing protection policies.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Tenant Protection Policy Mapping Response.
   */
  UpdateTenantProtectionPolicyResponse(params: TenantService.UpdateTenantProtectionPolicyParams): __Observable<__StrictHttpResponse<TenantProtectionPolicyUpdate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/tenants/policy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantProtectionPolicyUpdate>;
      })
    );
  }
  /**
   * Update protection policy permission for an existing tenant on the Cohesity
   * Cluster.
   *
   * Returns success if the update for protection policy permission data is
   * successful for the specified tenant.
   * @param params The `TenantService.UpdateTenantProtectionPolicyParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update existing protection policies.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Tenant Protection Policy Mapping Response.
   */
  UpdateTenantProtectionPolicy(params: TenantService.UpdateTenantProtectionPolicyParams): __Observable<TenantProtectionPolicyUpdate> {
    return this.UpdateTenantProtectionPolicyResponse(params).pipe(
      __map(_r => _r.body as TenantProtectionPolicyUpdate)
    );
  }

  /**
   * Update protection job for an existing tenant on the Cohesity Cluster.
   *
   * Returns success if the update for protection job is successful for specified
   * tenant.
   * @param params The `TenantService.UpdateTenantProtectionJobParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update existing protection jobs.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Tenant Protection Job Mapping Response.
   */
  UpdateTenantProtectionJobResponse(params: TenantService.UpdateTenantProtectionJobParams): __Observable<__StrictHttpResponse<TenantProtectionJobUpdate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/tenants/protectionJob`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantProtectionJobUpdate>;
      })
    );
  }
  /**
   * Update protection job for an existing tenant on the Cohesity Cluster.
   *
   * Returns success if the update for protection job is successful for specified
   * tenant.
   * @param params The `TenantService.UpdateTenantProtectionJobParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update existing protection jobs.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Tenant Protection Job Mapping Response.
   */
  UpdateTenantProtectionJob(params: TenantService.UpdateTenantProtectionJobParams): __Observable<TenantProtectionJobUpdate> {
    return this.UpdateTenantProtectionJobResponse(params).pipe(
      __map(_r => _r.body as TenantProtectionJobUpdate)
    );
  }

  /**
   * List proxies for tenant which are running within tenant's environment.
   *
   * Returns the list of proxies.
   * @param params The `TenantService.GetTenantsProxiesParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `ids`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Get Tenants Proxies Response.
   */
  GetTenantsProxiesResponse(params: TenantService.GetTenantsProxiesParams): __Observable<__StrictHttpResponse<Array<TenantProxy>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/tenants/proxies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TenantProxy>>;
      })
    );
  }
  /**
   * List proxies for tenant which are running within tenant's environment.
   *
   * Returns the list of proxies.
   * @param params The `TenantService.GetTenantsProxiesParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `ids`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Get Tenants Proxies Response.
   */
  GetTenantsProxies(params: TenantService.GetTenantsProxiesParams): __Observable<Array<TenantProxy>> {
    return this.GetTenantsProxiesResponse(params).pipe(
      __map(_r => _r.body as Array<TenantProxy>)
    );
  }

  /**
   * Get proxy config for tenant.
   *
   * Returns the config for tenants proxy.
   * @param params The `TenantService.GetTenantsProxyConfigParams` containing the following parameters:
   *
   * - `validateOnly`: Specifies whether to only validate the config request.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `id`: Specifies the id of the tenant.
   *
   * - `connectionId`: Specifies the id of the connection.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Get Tenants Proxy Config Response.
   */
  GetTenantsProxyConfigResponse(params: TenantService.GetTenantsProxyConfigParams): __Observable<__StrictHttpResponse<Array<number>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.validateOnly != null) __params = __params.set('validateOnly', params.validateOnly.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.connectionId != null) __params = __params.set('connectionId', params.connectionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/tenants/proxy/config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<number>>;
      })
    );
  }
  /**
   * Get proxy config for tenant.
   *
   * Returns the config for tenants proxy.
   * @param params The `TenantService.GetTenantsProxyConfigParams` containing the following parameters:
   *
   * - `validateOnly`: Specifies whether to only validate the config request.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `id`: Specifies the id of the tenant.
   *
   * - `connectionId`: Specifies the id of the connection.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Get Tenants Proxy Config Response.
   */
  GetTenantsProxyConfig(params: TenantService.GetTenantsProxyConfigParams): __Observable<Array<number>> {
    return this.GetTenantsProxyConfigResponse(params).pipe(
      __map(_r => _r.body as Array<number>)
    );
  }

  /**
   * Downloads the tenants proxy.
   *
   * Returns the tenant proxy to be downloaded.
   * @param params The `TenantService.DownloadTenantsProxyParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `id`: Specifies the id of the tenant.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Tenants Proxy Download Response.
   */
  DownloadTenantsProxyResponse(params: TenantService.DownloadTenantsProxyParams): __Observable<__StrictHttpResponse<Array<number>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/tenants/proxy/image`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<number>>;
      })
    );
  }
  /**
   * Downloads the tenants proxy.
   *
   * Returns the tenant proxy to be downloaded.
   * @param params The `TenantService.DownloadTenantsProxyParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `id`: Specifies the id of the tenant.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Tenants Proxy Download Response.
   */
  DownloadTenantsProxy(params: TenantService.DownloadTenantsProxyParams): __Observable<Array<number>> {
    return this.DownloadTenantsProxyResponse(params).pipe(
      __map(_r => _r.body as Array<number>)
    );
  }

  /**
   * Update existing users to an existing tenant on the Cohesity Cluster.
   *
   * Returns success if the update for users data is successful for specified tenant.
   * @param params The `TenantService.UpdateTenantUsersParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update existing users.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Tenant Users Mapping Response.
   */
  UpdateTenantUsersResponse(params: TenantService.UpdateTenantUsersParams): __Observable<__StrictHttpResponse<Array<User>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/tenants/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<User>>;
      })
    );
  }
  /**
   * Update existing users to an existing tenant on the Cohesity Cluster.
   *
   * Returns success if the update for users data is successful for specified tenant.
   * @param params The `TenantService.UpdateTenantUsersParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update existing users.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Tenant Users Mapping Response.
   */
  UpdateTenantUsers(params: TenantService.UpdateTenantUsersParams): __Observable<Array<User>> {
    return this.UpdateTenantUsersResponse(params).pipe(
      __map(_r => _r.body as Array<User>)
    );
  }

  /**
   * Update views permission for an existing tenant on the Cohesity Cluster.
   *
   * Returns success if the update for views permission data is successful for
   * specified tenant.
   * @param params The `TenantService.UpdateTenantViewParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update existing views.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Tenant View Mapping Response.
   */
  UpdateTenantViewResponse(params: TenantService.UpdateTenantViewParams): __Observable<__StrictHttpResponse<TenantViewUpdate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/tenants/view`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantViewUpdate>;
      })
    );
  }
  /**
   * Update views permission for an existing tenant on the Cohesity Cluster.
   *
   * Returns success if the update for views permission data is successful for
   * specified tenant.
   * @param params The `TenantService.UpdateTenantViewParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update existing views.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Tenant View Mapping Response.
   */
  UpdateTenantView(params: TenantService.UpdateTenantViewParams): __Observable<TenantViewUpdate> {
    return this.UpdateTenantViewResponse(params).pipe(
      __map(_r => _r.body as TenantViewUpdate)
    );
  }

  /**
   * Update view box for an existing tenant on the Cohesity Cluster.
   *
   * Returns success if the update for view box data is successful for specified
   * tenant.
   * @param params The `TenantService.UpdateTenantViewBoxParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update existing tenant view box.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Tenant View Box Update Response.
   */
  UpdateTenantViewBoxResponse(params: TenantService.UpdateTenantViewBoxParams): __Observable<__StrictHttpResponse<TenantViewBoxUpdate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/tenants/viewBox`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantViewBoxUpdate>;
      })
    );
  }
  /**
   * Update view box for an existing tenant on the Cohesity Cluster.
   *
   * Returns success if the update for view box data is successful for specified
   * tenant.
   * @param params The `TenantService.UpdateTenantViewBoxParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update existing tenant view box.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Tenant View Box Update Response.
   */
  UpdateTenantViewBox(params: TenantService.UpdateTenantViewBoxParams): __Observable<TenantViewBoxUpdate> {
    return this.UpdateTenantViewBoxResponse(params).pipe(
      __map(_r => _r.body as TenantViewBoxUpdate)
    );
  }

  /**
   * Update vlan for an existing tenant on the Cohesity Cluster.
   *
   * Returns success if the update for vlan data is successful for specified tenant.
   * @param params The `TenantService.UpdateTenantVlanParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update existing tenant vlan.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Tenant Vlan Update Response.
   */
  UpdateTenantVlanResponse(params: TenantService.UpdateTenantVlanParams): __Observable<__StrictHttpResponse<TenantVlanUpdate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/tenants/vlan`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantVlanUpdate>;
      })
    );
  }
  /**
   * Update vlan for an existing tenant on the Cohesity Cluster.
   *
   * Returns success if the update for vlan data is successful for specified tenant.
   * @param params The `TenantService.UpdateTenantVlanParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update existing tenant vlan.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Tenant Vlan Update Response.
   */
  UpdateTenantVlan(params: TenantService.UpdateTenantVlanParams): __Observable<TenantVlanUpdate> {
    return this.UpdateTenantVlanResponse(params).pipe(
      __map(_r => _r.body as TenantVlanUpdate)
    );
  }
}

module TenantService {

  /**
   * Parameters for GetTenants
   */
  export interface GetTenantsParams {

    /**
     * Filter by tenant status.
     */
    status?: Array<'Active' | 'Deactivated' | 'Deleted'>;
    skipInvalidIds?: boolean;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * 'ViewBox' indicates view box data for tenant.
     * 'Vlan' indicates vlan data for tenant.
     * 'ProtectionPolicy' indicates protection policy for tenant.
     * 'ProtectionJob' indicates protection job for tenant.
     * 'Entity' indicates entity data for tenant.
     * 'Views' indicates view data for tenant.
     * 'ActiveDirectory' indicates active directory for tenant.
     * 'LdapProvider' indicates ldap provider for tenant.
     * 'SwiftConfig' indicates Swift configuration for tenant.
     */
    properties?: Array<'ViewBox' | 'Vlan' | 'ProtectionPolicy' | 'Entity' | 'ProtectionJob' | 'Views' | 'ActiveDirectory' | 'LdapProvider' | 'SwiftConfig'>;
    includeSelf?: boolean;
    ids?: Array<string>;
    hierarchy?: boolean;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateTenant
   */
  export interface UpdateTenantParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to update existing tenant.
     */
    body?: TenantUpdate;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateTenant
   */
  export interface CreateTenantParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to add or create a new tenant.
     */
    body?: TenantCreateParameters;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteTenant
   */
  export interface DeleteTenantParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;
    body?: TenantIdData;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateTenantActiveDirectory
   */
  export interface UpdateTenantActiveDirectoryParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to update existing active directories.
     */
    body?: TenantActiveDirectoryUpdateParameters;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateTenantEntity
   */
  export interface UpdateTenantEntityParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to associate entity for tenant.
     */
    body?: TenantEntityUpdateParameters;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateTenantGroups
   */
  export interface UpdateTenantGroupsParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to update existing tenant groups.
     */
    body?: TenantGroupUpdateParameters;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateTenantLdapProvider
   */
  export interface UpdateTenantLdapProviderParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to update existing ldap providers.
     */
    body?: TenantLdapProviderUpdateParameters;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateTenantProtectionPolicy
   */
  export interface UpdateTenantProtectionPolicyParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to update existing protection policies.
     */
    body?: TenantProtectionPolicyUpdateParameters;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateTenantProtectionJob
   */
  export interface UpdateTenantProtectionJobParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to update existing protection jobs.
     */
    body?: TenantProtectionJobUpdateParameters;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetTenantsProxies
   */
  export interface GetTenantsProxiesParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;
    ids?: Array<string>;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetTenantsProxyConfig
   */
  export interface GetTenantsProxyConfigParams {

    /**
     * Specifies whether to only validate the config request.
     */
    validateOnly?: boolean;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the id of the tenant.
     */
    id?: string;

    /**
     * Specifies the id of the connection.
     */
    connectionId?: number;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DownloadTenantsProxy
   */
  export interface DownloadTenantsProxyParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the id of the tenant.
     */
    id?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateTenantUsers
   */
  export interface UpdateTenantUsersParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to update existing users.
     */
    body?: TenantUserUpdateParameters;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateTenantView
   */
  export interface UpdateTenantViewParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to update existing views.
     */
    body?: TenantViewUpdateParameters;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateTenantViewBox
   */
  export interface UpdateTenantViewBoxParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to update existing tenant view box.
     */
    body?: TenantViewBoxUpdateParameters;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateTenantVlan
   */
  export interface UpdateTenantVlanParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to update existing tenant vlan.
     */
    body?: TenantVlanUpdateParameters;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { TenantService }
