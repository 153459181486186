import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { DataTreeSelection } from '@cohesity/helix';
import { SourceSelection } from '@cohesity/iris-source-tree';

import { BaseProtectionSourceService } from '../shared/base-protection-source.service';
import { AdPhysicalHostOptionsComponent } from './ad-physical-host-options/ad-physical-host-options.component';
import { AdSourceDataNode } from './ad-source-data-node';

/**
 * Tree service for active directory.
 */
@Injectable({
  providedIn: 'root',
})
export class AdSourceTreeService extends BaseProtectionSourceService<AdSourceDataNode> {
  constructor() {
    super();
  }

  /**
   * Gets a component to render for a source's special parameters. This does not apply to every node
   * and can be null for certain data types.
   */
  getSpecialParametersComponent(node: AdSourceDataNode): ComponentType<any> {
    if (node.type === 'kHost') {
      return AdPhysicalHostOptionsComponent;
    }
    return null;
  }

  /**
   * Transforms the node object from the api into a Ad Tree node to pass to the tree.
   *
   * @param   node   The original node.
   * @param   level  The level in the tree.
   * @return  An AdSourceDataNode that can be displayed in the tree.
   */
  transformData(node: ProtectionSourceNode, level: number): AdSourceDataNode {
    return new AdSourceDataNode(node, level);
  }

  /**
   * Convert the data tree selection model to the job selection model.
   *
   * @param   selection   The selection from the tree.
   * @return  The job selection info.
   */
  transformFromDataTreeSelection(selection: DataTreeSelection<AdSourceDataNode>): SourceSelection {
    const selectedHosts = selection.selected.filter(item => item.type === 'kHost');
    return {
      sourceIds: selectedHosts.map(item => Number(item.id)),

      // Active directory always has default special parameters so they need to be specified in the selection
      // even if there were not manually set up anywhere.
      sourceSpecialParameters: selectedHosts.map(
        host => selection.options && selection.options[host.id] || host.getDefaultSourceParameters()
      ),
    };
  }

  /**
   * Convert source selection to the data tree selection model.
   * Source selection ids should map to physical hosts, there are no duplicates in the tree and
   * auto select is not applicable.
   *
   * @param   allNodes         The unfiltered list of tree nodes.
   * @param   sourceSelection  The job selection.
   * @return  A data tree selection model.
   */
  transformToDataTreeSelection(
    allNodes: AdSourceDataNode[],
    sourceSelection: SourceSelection
  ): DataTreeSelection<AdSourceDataNode> {
    const selection: DataTreeSelection<AdSourceDataNode> = {
      autoSelected: [],
      excluded: [],
      selected: [],
      options: {},
    };

    if (!sourceSelection) {
      return selection;
    }
    if (sourceSelection.sourceIds.length) {
      allNodes.forEach(node => {
        // Source selection id can either belong to a host or a domain controller
        // Therefore, we need to check both the node id and it's children ids.
        const idsToCheck = [Number(node.id)];
        if (node.expandable) {
          idsToCheck.push(...node.childIds);
        }
        if (idsToCheck.some(nodeId => (sourceSelection.sourceIds || []).includes(Number(nodeId)))) {
          node.inCurrentJob = true;
          selection.selected.push(node);
        }
      });
    }
    if (sourceSelection.sourceSpecialParameters) {
      selection.options = sourceSelection.sourceSpecialParameters.reduce((options, params) => {
        options[params.sourceId] = params;
        return options;
      }, {});
    }

    return selection;
  }
}
