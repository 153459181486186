<ng-container>
  <ng-container>
    <span>
      {{ 'totalSizeColon' | translate }}
      <cog-spinner *ngIf="loading$ | async" inline="true" size="xs"></cog-spinner>
      {{ totalSizeBytes | byteSize }}
    </span>
  </ng-container>
  <ng-container>
    <span>
      {{ 'totalItemsColon' | translate }}
      <cog-spinner *ngIf="loading$ | async" inline="true" size="xs"></cog-spinner>
      {{ totalItems }}
    </span>
  </ng-container>
  <div class="row message-container">
    <cog-banner status="critical" *ngIf="hasError">
      {{ recoveryMessage | translate }}
    </cog-banner>
    <cog-banner class="push" status="info" *ngIf="hasDownloadLinkForPst">
      {{ 'm365.selfServe.pstAvailableUntil' | translate: {date: downloadFileExpiryTimeUsecs | cogDate} }}
      <span class="download-link">
        <a href="{{downloadLink}}" target="_self">
          {{ 'm365.selfServe.downloadPst' | translate }}
        </a>
      </span>
    </cog-banner>
    <cog-banner class="push" status="info" *ngIf="hasDownloadLinkForOneDrive">
      {{ 'm365.selfServe.oneDriveDocumentsAvailable' | translate: {date: downloadFileExpiryTimeUsecs | cogDate} }}
      <span class="download-link">
        <a href="{{downloadLink}}" target="_self">
          {{ 'downloadFile' | translate }}
        </a>
      </span>
    </cog-banner>
  </div>
</ng-container>