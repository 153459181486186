<cog-table class="standard-width"
  [source]="searchResults"
  [selection]="selection"
  [persistSelection]="true"
  [canSelectRowFn]="canSelectObject"
  name="object-search-results-table">
  <table mat-table>
    <ng-container matColumnDef="detail">
      <td mat-cell *matCellDef="let row; let index = index">
        <ng-container *ngTemplateOutlet="detailTemplate || defaultDetail; context: {$implicit: row}"></ng-container>
      </td>
    </ng-container>

    <!-- NoData Footer -->
    <ng-container matColumnDef="noData">
      <td mat-footer-cell *matFooterCellDef>
        {{ 'noSearchResults' | translate }}
      </td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: ['detail']" (click)="toggleObjectSelection(row)"></tr>
    <tr class="nodata-row" mat-footer-row [class.hidden]="!noData" *matFooterRowDef="['noData']"></tr>
  </table>
</cog-table>

<ng-template #defaultDetail let-row>
  <coh-object-search-item-detail *ngIf="row.resultType === 'object'" [object]="row"></coh-object-search-item-detail>
</ng-template>
