<coh-runs-list-item *ngFor="let run of runs; index as i;"
  id="group-runs-list-item-{{ i }}"
  [run]="run"
  [hasActions]="hasActions"
  [hasDataRead]="hasDataRead"
  [hasDataWritten]="hasDataWritten"
  [hasObjectCount]="hasObjectCount"
  [selected]="isSelected(run)"
  (click)="selectionHandler(run)">
</coh-runs-list-item>
<div class="no-runs-container" *ngIf="!runs?.length">
  <div class="no-runs-group">
    <div class="no-runs"></div>
    <h1 class="no-runs-header">{{ 'noRunsFound' | translate }}</h1>
  </div>
</div>
