import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Api } from '@cohesity/api/private';
import { CustomizeUIServiceApi, MastheadDetails, WhitelabelingSetting } from '@cohesity/api/v2';
import { ThemeService } from '@cohesity/helix';
import { flagEnabled, IrisContext, IrisContextService, isMcm } from '@cohesity/iris-core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

/**
 * The theming config for the UI header
 */
export interface HeaderThemeConfig {
  bgColor?: string;
  textColor?: string;
  logo?: string;
}

@Injectable({
  providedIn: 'root',
})
export class WhitelabelingService {

  /**
   * Behavior Subject to store whitelabeling customization settings
   */
  private whitelabelingCustomizations = new BehaviorSubject<WhitelabelingSetting>(null);

  /**
   * Observable to consume whitelabeling settings from outside
   */
  readonly whitelabelingCustomizations$ = this.whitelabelingCustomizations.asObservable();

  /**
   * Behavior Subject to store custom logo information
   */
  private customLogoUrl = new BehaviorSubject<WhitelabelingSetting>(null);

  /**
   * Observable to expose custom logo info
   */
  readonly customLogoUrl$ = this.customLogoUrl.asObservable();

  constructor(
    private customizeUiService: CustomizeUIServiceApi,
    private irisCtx: IrisContextService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private themeService: ThemeService,

  ) {
    this.init();
  }

  /**
   * Initialize the service.
   */
  init() {
    this.irisCtx.irisContext$.pipe(
      filter((ctx: IrisContext) => isMcm(ctx) && flagEnabled(ctx, 'whitelabeling')),
      take(1),
    ).subscribe(() => {
      // set deafult theming customizations
      this.setWhitelabelingCustomizations();

      // load any user specified theming customizations
      this.loadWhitelabelingCustomizations();
    });
  }

  /**
   * Set ui customization config if its already available.
   * Otherwise, fetch it from the server and then set it.
   *
   * @param config The whitelabeling settings config
   */
  setWhitelabelingCustomizations(config?: WhitelabelingSetting) {
    if (config) {
      this.whitelabelingCustomizations.next(config);
      return;
    }

    this.customizeUiService.GetWhitelabelingSettings().subscribe(
      (data: WhitelabelingSetting) => this.whitelabelingCustomizations.next(data));
  }

  /**
   * Load any UI customizations that user has configured
   */
  loadWhitelabelingCustomizations() {
    combineLatest([
      this.themeService.darkModeEnabled$,
      this.whitelabelingCustomizations$,
    ]).pipe(
      filter(([, data]) => !!data)
    ).subscribe(([isDarkModeEnabled, data]) => {
      this.themeService.updateMastheadTheme({...this.themeService.defaultMastheadTheme, ...data.mastheadDetails});
      if (data.mastheadDetails?.lightLogoSrc || data.mastheadDetails?.darkLogoSrc) {
        // fallback to the other theme logo if logo for applied theme is not available
        const logo = isDarkModeEnabled ? data.mastheadDetails['darkLogoSrc'] : data.mastheadDetails['lightLogoSrc'];
        this.customLogoUrl.next(logo ? this.sanitizer.bypassSecurityTrustUrl(`url('${logo}')`) : null);
      } else {
        this.customLogoUrl.next(null);
      }
    });
  }

  /**
   * Whitelabeling needs image upload which requires multipart form data.
   * So a direct API call from YAML generated models is not possible.
   *
   * @param whitelabelingSettings The settings for UI customization
   * @param isEdit Indicates if an existing config is being modified
   */
  saveWhitelabelingCustomizations(
    whitelabelingSettings: WhitelabelingSetting,
    logoFiles: {lightLogoFile?: Blob; darkLogoFile?: Blob},
    isEdit: boolean
  ): Observable<WhitelabelingSetting> {
    const mastheadDetails = whitelabelingSettings.mastheadDetails;
    const formData = new FormData();

    // Files must be sent as multipart formData
    if (logoFiles.darkLogoFile) {
      formData.append('darkLogoSrc', logoFiles.darkLogoFile, 'dark-logo.png');
      delete mastheadDetails.darkLogoSrc;
    }
    if (logoFiles.lightLogoFile) {
      formData.append('lightLogoSrc', logoFiles.lightLogoFile, 'light-logo.png');
      delete mastheadDetails.lightLogoSrc;
    }

    formData.append('data', JSON.stringify(whitelabelingSettings));

    const method = isEdit ? 'patch' : 'post';

    return this.http[method](Api.v2Root + CustomizeUIServiceApi.UpdateWhitelabelingSettingsPath, formData);
  }

  /**
   * Returns the whitelabeling data for the selected theme
   *
   * @param theme The cuurently selected theme for customization
   * @returns The whitelabeling data for selected theme
   */
  getThemedWhitelabelingData(data: MastheadDetails, theme: string): HeaderThemeConfig {
    return {
      bgColor: data[theme + 'BGColor'],
      textColor: data[theme + 'TextColor'],
      logo: data[theme + 'LogoSrc']
    };
  }
}
