import { Injectable } from '@angular/core';
import { Integration, SecurityIntegrationServiceApi } from '@cohesity/api/v2';
import { AjaxHandlerService } from '@cohesity/utils';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TiIntegrationService {
  /**
   * TH Integration apps info behavior subject
   */
  private _tiIntegrations$ = new BehaviorSubject<Integration[]>([]);

  /**
   * TH Integration apps info observable
   */
  readonly tiIntegrations$ = this._tiIntegrations$.asObservable();

  constructor(
    private ajaxService: AjaxHandlerService,
    private securityIntegration: SecurityIntegrationServiceApi
  ) { }

  /**
   * Fetch all TH integration apps
   */
  getTiIntegrations(): Observable<Integration[]> {
    return this.securityIntegration.ListSecurityIntegrations({
      integrationType: 'TI',
    }).pipe(
      map(({ integrations = [] }) => integrations),
      tap(integrations => {
        this._tiIntegrations$.next(integrations);
      }),
      catchError(err => {
        this.ajaxService.handler(err);
        return of(null);
      })
    );
  }
}
