import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { SwiftConfig } from '@cohesity/api/v2';
import { CreateForm, createFormProviders } from '@cohesity/shared-forms';
import { AutoDestroyable } from '@cohesity/utils';

@Component({
  selector: 'coh-view-swift-ownership',
  templateUrl: './swift-ownership.component.html',
  providers: createFormProviders(SwiftOwnershipComponent),
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwiftOwnershipComponent extends AutoDestroyable implements OnInit {
  /**
   * Initialize the form.
   */
  form = new CreateForm<SwiftConfig>(this, {
    formControls: {
      swiftProjectDomain: new UntypedFormControl(null),
      swiftProjectName: new UntypedFormControl(null),
      swiftUserDomain: new UntypedFormControl(null),
      swiftUsername: new UntypedFormControl(null),
    },
    transformToFormGroup: this.transformToFormGroup.bind(this),
  });

  constructor() {
    super();
  }

  ngOnInit() {
    // swiftUserDomain is required when swiftUsername is present.
    this.form.formGroup.controls.swiftUsername.valueChanges
      .pipe(this.untilDestroy())
      .subscribe(value => {
        this.form.formGroup.controls.swiftUserDomain.setValidators(value ? Validators.required : null);
        this.form.formGroup.updateValueAndValidity();
      });
  }

  /**
   * Transform the input form value.
   */
  transformToFormGroup(value: SwiftConfig): SwiftConfig {
    // swiftUserDomain is required when swiftUsername is present.
    if (value?.swiftUsername) {
      this.form.formGroup.controls.swiftUserDomain.setValidators(Validators.required);
    }

    return { ...this.form.formGroup.value, ...value };
  }
}
