<cog-date-range-filter
  cogDataId="time-range-filter"
  *cogFilterDef="
    let filter;
    let params = params;
    key: property;
    label: translate('dateRange');
    filterType: 'dateRange';
    quickFilter: true"
  [filter]="filter"
  [noClear]="true"
  [noCustomRange]="isScheduleFilter && !isOnDemand"
  [useTimeframe]="true"
  [timeframeOptions]="timeframeOptions"
  [filterDefParams]="params"
  [banner]="banner"
  [disableApplyButton]="disableApplyButton"
  (changes)="onDateRangeChange($event)"
  (customButtonClicked)="createReport.emit(longDateRange)">
</cog-date-range-filter>
