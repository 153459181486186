import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { DataIdModule } from '../data-id/data-id.module';
import { IconModule } from '../icon/icon.module';
import {
  SettingsListItemValueDisplayComponent,
} from './settings-list-item-value-display/settings-list-item-value-display.component';
import {
  SettingsListItemValueEditorComponent,
} from './settings-list-item-value-editor/settings-list-item-value-editor.component';
import { SettingsListItemComponent } from './settings-list-item/settings-list-item.component';
import { SettingsListComponent } from './settings-list.component';

const COMPONENTS = [
  SettingsListComponent,
  SettingsListItemComponent,
  SettingsListItemValueDisplayComponent,
  SettingsListItemValueEditorComponent,
];

@NgModule({
  declarations: COMPONENTS,
  exports: COMPONENTS,
  imports: [
    CommonModule,
    IconModule,
    MatButtonModule,
    MatTooltipModule,
    DataIdModule,
  ]
})
export class SettingsListModule { }
