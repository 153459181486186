import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AppCustomProps } from '../micro-frontend.models';

/**
 * The App context default initial value set during micro frontend build.
 */
export const APP_CONTEXT = new InjectionToken<AppCustomProps>('APP_CONTEXT');

/**
 * The micro frontend app context service.
 */
@Injectable({ providedIn: 'root' })
export class AppContextService {
  /** Internal subject to keep the micro frontend app context. */
  private appContextSubject = new BehaviorSubject<AppCustomProps>(this.context);

  /** An observable of the micro frontend app context state. */
  appContext$ = this.appContextSubject.pipe(filter(context => !!context));

  /** Gets the current micro frontend app context. */
  get appContext(): AppCustomProps {
    return this.appContextSubject.value;
  }

  /** Create the micro frontend app context object. */
  constructor(@Inject(APP_CONTEXT) @Optional() private context: AppCustomProps = null) {}
}
