/**
 * Specifies the status of VMware Tools for the guest OS on the VM.
 */
export enum VmToolsStatus {
  kUnknown = 'Unknown',
  kGuestToolsExecutingScripts = 'Executing',
  kGuestToolsNotRunning = 'Not Running',
  kGuestToolsRunning = 'Running',
}

export const VmwareToolsRunningStatus = {
  0: 'Unknown',
  1: 'Executing',
  2: 'Not Running',
  3: 'Running',
};
