<div class="snapshot-info">
  <div class="stats-container">
    <div class="stat">
      <label class="label">
        {{ 'time' | translate }}
      </label>
      <span class="value">{{ selectedSnapshot?.x | cogDate: 'mediumTime' }}</span>
    </div>
    <div class="stat">
      <label class="label">
        {{ 'location' | translate }}
      </label>
      <span class="value">
        <cog-icon shape="helix:cluster"></cog-icon>
        <span>{{'local' | translate}}</span>
      </span>
    </div>
  </div>
  <div class="tags">
    <coh-colored-tags [tags]="tags"></coh-colored-tags>
  </div>
</div>
