import { Injectable } from '@angular/core';
import { DataTreeTransformer } from '@cohesity/helix';

import { SqlNode, SqlNodeType } from '../models';

/**
 * Sql Node Transformer Service for data tree control.
 */
@Injectable()
export class SqlNodeTransformerService implements DataTreeTransformer<any> {
  /**
   * Gets selectable status of current node.
   */
  get isSelectable(): boolean {
    return true;
  }

  /**
   * Gets children of current node.
   */
  getChildren(structuredNode: any): SqlNode[] {
    const { servers, fciClusters } = structuredNode;
    return [
      ...(servers || []).map(server => ({...server, type: SqlNodeType.Server})),
      ...(fciClusters || []).map(fci => ({
        ...fci,
        type: SqlNodeType.Fci,

        // Manually transform for servers inside Fci cluster since these nodes
        // will not be managed by the data tree.
        servers: fci.servers.map(server => this.transformData(server, 1))
      })),
    ];
  }

  /**
   * Gets level of current node.
   */
  getLevel(flatNode: SqlNode): number {
    return flatNode.level;
  }

  /**
   * Gets expandable status of current node.
   */
  getExpandable(flatNode: SqlNode): boolean {
    return flatNode.expandable;
  }

  /**
   * Transforms raw data to data tree node.
   */
  transformData(node: any, level: number): SqlNode {
    return new SqlNode(node, level);
  }
}
