// Module: Cloud Edition Policy Page

;(function(angular) {
  'use strict';

  angular.module('C.cloudEditionManager')
    .component('cloudEditionPolicy', {
      controller: 'cloudEditionPolicyCtrl',
      templateUrl: 'app/platform/cloud-edition-manager/policy.html',
      bindings: {
        /**
         * @type  {String}  The type of cloud (aws/azure etc.)
         */
        cloud: '<'
      }
    })
    .controller('cloudEditionPolicyCtrl', cloudEditionPolicyCtrlFn);

  /**
   * @ngdoc component
   * @name C.cloudEditionManager:cloudEditionPolicy
   * @function
   *
   * @description
   * Show a Cloud Edition Policy
   */
  function cloudEditionPolicyCtrlFn(_) {

    var $ctrl = this;

    _.assign($ctrl, {
      awsPolicyJSON: {
        "Version": "2012-10-17",
        "Statement": [
          {
            "Sid": "VisualEditor0",
            "Effect": "Allow",
            "Action": [
              "iam:ListAttachedUserPolicies",
              "iam:GetPolicyVersion",
              "iam:GetPolicy",
              "iam:ListGroupsForUser",
              "iam:ListUsers",
              "iam:GetUser",
              "iam:RemoveRoleFromInstanceProfile",
              "iam:CreateRole",
              "iam:AttachRolePolicy",
              "iam:AddRoleToInstanceProfile",
              "iam:DetachRolePolicy",
              "iam:ListPolicies",
              "iam:GetRole",
              "iam:DeleteRole",
              "iam:CreateInstanceProfile",
              "iam:PassRole",
              "iam:DeleteInstanceProfile",
              "iam:GetInstanceProfile",
              "cloudformation:UpdateStack",
              "cloudformation:ListStackResources",
              "cloudformation:CreateStack",
              "cloudformation:GetTemplate",
              "cloudformation:DeleteStack",
              "cloudformation:DescribeStacks",
              "cloudformation:ValidateTemplate",
              "cloudformation:DescribeStackEvents",
              "ec2:DescribeInstances",
              "ec2:DeleteTags",
              "ec2:DescribeRegions",
              "ec2:DeleteVolume",
              "ec2:DescribeNetworkInterfaces",
              "ec2:StartInstances",
              "ec2:DescribeVolumes",
              "ec2:AttachVolume",
              "ec2:DescribeInstanceStatus",
              "ec2:DetachVolume",
              "ec2:DetachNetworkInterface",
              "ec2:TerminateInstances",
              "ec2:ModifyVolumeAttribute",
              "ec2:CreateTags",
              "ec2:ModifyNetworkInterfaceAttribute",
              "ec2:DeleteNetworkInterface",
              "ec2:RunInstances",
              "ec2:StopInstances",
              "ec2:CreateVolume",
              "ec2:CreateNetworkInterface",
              "ec2:AttachNetworkInterface",
              "ec2:DescribeSubnets",
              "ec2:AuthorizeSecurityGroupIngress",
              "ec2:RevokeSecurityGroupEgress",
              "ec2:RevokeSecurityGroupIngress",
              "ec2:DescribeAvailabilityZones",
              "ec2:CreateSecurityGroup",
              "ec2:AuthorizeSecurityGroupEgress",
              "ec2:DescribeSecurityGroups",
              "ec2:DescribeVpcs",
              "ec2:DeleteSecurityGroup",
              "ssm:SendCommand",
              "ssm:GetCommandInvocation",
              "s3:CreateBucket",
              "s3:GetObject",
              "s3:PutObject",
              "s3:DeleteObject",
              "s3:DeleteBucket"
            ],
            "Resource": "*"
          }
        ]
      },
      azurePolicyJSON: {
        "Name": "<Name of Role>",
        "Description": "<Description for role>",
        "IsCustom": true,
        "Actions": [
          "Microsoft.Authorization/locks/write",
          "Microsoft.Compute/disks/write",
          "Microsoft.Compute/locations/runCommands/read",
          "Microsoft.Compute/virtualMachines/deallocate/action",
          "Microsoft.Compute/virtualMachines/read",
          "Microsoft.Compute/virtualMachines/runCommand/action",
          "Microsoft.Compute/virtualMachines/start/action",
          "Microsoft.Compute/virtualMachines/write",
          "Microsoft.Network/networkInterfaces/join/action",
          "Microsoft.Network/networkInterfaces/read",
          "Microsoft.Network/networkInterfaces/write",
          "Microsoft.Network/networkSecurityGroups/join/action",
          "Microsoft.Network/networkSecurityGroups/read",
          "Microsoft.Network/networkSecurityGroups/write",
          "Microsoft.Network/virtualNetworks/read",
          "Microsoft.Network/virtualNetworks/subnets/join/action",
          "Microsoft.Resources/deployments/read",
          "Microsoft.Resources/deployments/validate/action",
          "Microsoft.Resources/deployments/write",
          "Microsoft.Resources/subscriptions/resourcegroups/delete",
          "Microsoft.Resources/subscriptions/resourcegroups/read",
          "Microsoft.Resources/subscriptions/resourcegroups/write",
          "Microsoft.Storage/storageAccounts/blobServices/containers/write",
          "Microsoft.Storage/storageAccounts/delete",
          "Microsoft.Storage/storageAccounts/listKeys/action",
          "Microsoft.Storage/storageAccounts/read",
          "Microsoft.Storage/storageAccounts/write"
        ],
        "DataActions": [],
        "NotDataActions": [],
        "AssignableScopes": [
          "/subscriptions/<subscriptionID>"
        ]
      },
      gcpPolicyYAML: [
        'title: Cohesity CE Creator',
        'description: Role created in order to deploy Cohesity Cloud Edition.',
        'stage: GA',
        'includedPermissions:',
        '- cloudfunctions.functions.call',
        '- cloudfunctions.functions.create',
        '- cloudfunctions.functions.delete',
        '- cloudfunctions.functions.get',
        '- cloudfunctions.functions.list',
        '- cloudfunctions.locations.list',
        '- compute.addresses.createInternal',
        '- compute.addresses.deleteInternal',
        '- compute.addresses.delete',
        '- compute.addresses.get',
        '- compute.addresses.useInternal',
        '- compute.disks.create',
        '- compute.disks.delete',
        '- compute.disks.get',
        '- compute.disks.list',
        '- compute.disks.use',
        '- compute.disks.useReadOnly',
        '- compute.images.get',
        '- compute.images.useReadOnly',
        '- compute.instances.attachDisk',
        '- compute.instances.create',
        '- compute.instances.delete',
        '- compute.instances.get',
        '- compute.instances.list',
        '- compute.instances.setDeletionProtection',
        '- compute.instances.setLabels',
        '- compute.instances.setMetadata',
        '- compute.instances.setTags',
        '- compute.instances.start',
        '- compute.instances.stop',
        '- compute.instances.update',
        '- compute.networks.get',
        '- compute.networks.list',
        '- compute.projects.get',
        '- compute.regions.get',
        '- compute.regions.list',
        '- compute.subnetworks.get',
        '- compute.subnetworks.list',
        '- compute.subnetworks.use',
        '- compute.subnetworks.useExternalIp',
        '- compute.zones.get',
        '- compute.zones.list',
        '- iam.serviceAccounts.actAs',
        '- resourcemanager.projects.get',
        '- resourcemanager.projects.getIamPolicy',
        '- vpcaccess.connectors.get',
        '- vpcaccess.connectors.list',
        '- vpcaccess.locations.list',
        '- vpcaccess.operations.get',
        '- vpcaccess.operations.list',
      ].join('\n'),

      // Lifecycle hooks
      $onInit: $onInit,
      downloadGcpYaml: downloadGcpYaml,
    });

    /**
     * Activate this controller
     *
     * @method  $onInit
     */
    function $onInit() {
      $ctrl.cloudType = $ctrl.cloud === 'aws-us-gov' ? 'aws' : $ctrl.cloud;
    }

    /**
     * Download the GCP Yaml as a text file
     *
     * @method downloadGcpYaml
     */
    function downloadGcpYaml() {
      var element = document.createElement('a');

      element.setAttribute('href', 'data:text/plain;charset=utf-8,' +
        encodeURIComponent($ctrl.gcpPolicyYAML));
      element.setAttribute('download', 'permissions.yaml');

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  }

})(angular);
