import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Controls, NgxSubFormComponent, subformComponentProviders } from 'ngx-sub-form';
import { NoWhitespaceValidator } from '@cohesity/shared-forms';

import { RenameObjectDetails } from '../settings-list-object-rename/recovery-rename.model';

@Component({
  selector: 'coh-settings-list-object-rename-item',
  templateUrl: './settings-list-object-rename-item.component.html',
  styleUrls: ['./settings-list-object-rename-item.component.scss'],
  providers: subformComponentProviders(SettingsListObjectRenameItemComponent),
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsListObjectRenameItemComponent extends NgxSubFormComponent<RenameObjectDetails> {
  protected getFormControls(): Controls<RenameObjectDetails> {
    return {
      name: new UntypedFormControl(),
      renameTo: new UntypedFormControl('', NoWhitespaceValidator),
      icon: new UntypedFormControl(),
    };
  }
}
