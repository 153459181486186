import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AutoDestroyable } from '@cohesity/utils';
import { finalize } from 'rxjs/operators';

import { PostureAdvisorService } from '../../services';


/**
 * Component to display security rule details.
 */
@Component({
  selector: 'dg-pa-rule-details-page',
  templateUrl: './rule-details-page.component.html',
  styleUrls: ['./rule-details-page.component.scss']
})
export class RuleDetailsPageComponent extends AutoDestroyable implements OnInit {
  /**
   * Emit an event when a tab is selected
   */
  @Output() readonly tabSelect = new EventEmitter<string>();

  /**
   * Inform parent 'edit rule' has been clicked
   */
  @Output() editRuleClick = new EventEmitter<string>();

  /**
   * Inform parent 'back button' has been clicked
   */
  @Output() backClick = new EventEmitter<string>();

  /**
   * Route to determine the selected tab
   */
  @Input() route = 'definitions';

  /**
   * Rule id of the rule.
   */
  @Input() ruleId: string;

  /**
   * Tab objects on the Security rule details page.
   */
  readonly tabs = [
    {name: 'definition', route: 'definitions'},
    {name: 'clusters', route: 'clusters'},
  ];

  /**
   * All possible routes
   */
  readonly routes = this.tabs.map(tab => tab.route);

  /**
   * Rule name of the rule.
   */
  ruleName: string;

  /**
   * True if api request is in progress.
   */
  loading = false;

  constructor(
    private securityAdvisorService: PostureAdvisorService ) {
    super();
  }

  /**
   * OnInit lifecycle hook. Initialize tabs and get rule details.
   */
  ngOnInit() {
    this.fetchRuleDetails();
  }

  /**
   * Fetch rule details.
   */
  fetchRuleDetails() {
    this.loading = true;
    this.securityAdvisorService.getSecurityRuleDetails(this.ruleId)
      .pipe(this.untilDestroy(), finalize(() => this.loading = false))
      .subscribe(res => this.ruleName = res.name);
  }
}
