<aside class="sql-exclude-dialog" cogDataId="sql-exclude-dialog">
  <header mat-dialog-title>
    <h1 translate>excludeFromAutoProtection</h1>
  </header>

  <form [formGroup]="sqlExcludeFiltersForm" (ngSubmit)="apply()">
    <mat-dialog-content>
      <p>{{ data?.excludeOptions?.exclusionDescription ||
        ('sqlExcludeDialog.description' | translate) }}</p>
      <h2 class="margin-top-lg" translate>exclude</h2>
      <div formArrayName="excludeFilters"
        *ngFor="let formControl of excludeFiltersControl.controls; let index = index; let last = last">
        <mat-form-field>
          <div [formGroupName]="index">
            <input formControlName="filterString"
              matInput
              required
              placeholder="{{'enterExclusion' | translate}}"
              [cogDataId]="'exclude-filter-input-' + index">
            <mat-checkbox formControlName="isRegularExpression"
              matSuffix
              class="pull-right"
              value=false
              [cogDataId]="'option-isRegex-' + index">
              <small>{{'regEx' | translate}}</small>
            </mat-checkbox>
          </div>
          <mat-error *ngIf="formControl.invalid">
            {{'errors.required' | translate}}
          </mat-error>
        </mat-form-field>
        <cog-icon shape="remove_circle_outline"
          class="margin-left-sm action-icons"
          (click)="removeFilter(index)"
          [cogDataId]="'remove-exclude-filter-' + index">
        </cog-icon>
        <cog-icon shape="add_circle_outline"
          class="margin-left-sm action-icons"
          (click)="addFilter()"
          *ngIf="last"
          [cogDataId]="'add-exclude-filter-' + index">
        </cog-icon>
      </div>
      <a *ngIf="excludeFiltersControl.controls.length === 0" (click)="addFilter()">
        <cog-icon shape="add_circle_outline" size="sm"
          [cogDataId]="add-exclude-filter"></cog-icon>
        <span class="addFilter margin-left-sm">{{'addFilter' | translate}}</span>
      </a>
      <mat-error *ngIf="errorMessage">
        {{errorMessage}}
      </mat-error>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button type="button"
        cogCancelButton
        cogDataId="cancel"
        [inheritDataId]="true"
        mat-button
        mat-dialog-close
        [disabled]="saving"
        (click)="cancel()">{{'cancel' | translate}}</button>
      <button type="button"
        cogSubmitButton
        cogDataId="apply"
        [inheritDataId]="true"
        mat-button
        mat-raised-button
        color="primary"
        [loading]="saving"
        [disabled]="saving || !sqlExcludeFiltersForm.valid">
        {{(excludeFiltersControl.controls.length === 0 ? 'save' : 'apply') | translate}}
      </button>
    </mat-dialog-actions>
  </form>
</aside>
