import { flagEnabled, IrisContext } from '@cohesity/iris-core';
import { envGroups, Environment } from '@cohesity/iris-shared-constants';

/**
 * Returns true is NG group actions (run now) modal is enabled based on context and environment specified.
 *
 * @param context Iris context.
 * @param environment Protection group environment.
 * @returns True if feature flag is enabled based on environment specified.
 */
export function ngGroupActionsEnabled(context: IrisContext, environment: Environment): boolean {
  switch (true) {
    case envGroups.vms.includes(environment):
      return flagEnabled(context, 'ngRunNowVm');
    case envGroups.nas.includes(environment):
      return flagEnabled(context, 'ngRunNowNas');
    case envGroups.office365.includes(environment):
      return flagEnabled(context, 'ngRunNowOffice365');
    case environment === Environment.kPure:
      return flagEnabled(context, 'ngRunNowPure');
    case environment === Environment.kIbmFlashSystem:
      return flagEnabled(context, 'ibmFlashSystemEnabled');
  }

  return false;
}
