import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DialogService } from 'src/app/core/services';
import { TimelineDialogComponent, TimelineDialogData } from 'src/app/shared';

import { MigrationRunObject } from '../../model/migration-run-object';

/**
 * NoSQL migration objects table.
 *
 * @example
 * <coh-nosql-migration-object-table [recoveryV1]="recoveryV1$"></coh-nosql-migration-object-table>
 */
@Component({
  selector: 'coh-nosql-migration-object-table',
  styleUrls: ['./nosql-migration-object-table.component.scss'],
  templateUrl: './nosql-migration-object-table.component.html',
  encapsulation: ViewEncapsulation.None
})
export class NosqlMigrationObjectTableComponent {

  /**
   * Async Old Recovery data model (restore task).
   */
  recoveryV1$: Observable<any>;

  /**
   * Setter for old Recovery data (restore task in old UI) and update object teardown data.
   */
  @Input() set recoveryV1(recoveryV1Observable: Observable<any>) {
    this.recoveryV1$ = recoveryV1Observable;
    this.recoveryV1$.subscribe(response => this.parseTaskInformation(response));
  }

  /**
   * Sub task details.
   */
  subTaskDetails: MigrationRunObject[] = [];

  /**
   * Sub task details.
   */
  subTaskDetails$ = new BehaviorSubject<MigrationRunObject[]>([]);

  /**
   * Recovery object table default column names (only single progress).
   */
  readonly nosqlMigrationColumns = [
    'startTime',
    'duration',
    'status',
  ];

  /**
   * Getter for recovery object table columns.
   */
  get columns(): string[] {
    return this.nosqlMigrationColumns;
  }

  constructor(
    private translate: TranslateService,
    private dialogService: DialogService,
  ) {}

  /**
   * A multistage restore task consists of subTasks containing one or more object restores.
   * This method parses the subTasks and maps the old v1 api params to the new v2 api params.
   *
   * @return   A flat list of object restores.
   */
  parseTaskInformation(recoveryV1: any) {
    const subTasks = recoveryV1?.restoreSubTaskWrapperProtoVec;
    const subTaskDetails: MigrationRunObject[] = [];

    // Walk through each subTask and update the recoveryObjects array
    subTasks?.forEach(task => {
      const baseParams = task.performRestoreTaskState.base;
      let status: string = baseParams?.publicStatus;
      const taskId: number = baseParams?.taskId;
      const progressTaskId = task.performRestoreTaskState?.progressMonitorTaskPath;
      const latestSnapshot = task.performRestoreTaskState?.multiStageRestoreTaskState?.syncTimeUsecs;
      const error: string = baseParams?.error?.errorMsg;
      const startTimeUsecs = Math.round(baseParams?.startTimeUsecs / 1000);
      const endTimeUsecs = Math.round(baseParams?.endTimeUsecs / 1000);
      const duration = Math.max(0, endTimeUsecs - startTimeUsecs);
      const name = baseParams?.name;
      // If status is kOnHold with an error, then new status Pending sync is shown.
      if (status === 'kOnHold' && !!error) {
        status = 'kOnHoldError';
      }
      // If there is no snapshot and no error in the response, it means the migration
      // has just started. Show appropriate status for this scenario.
      if (status === 'kOnHold' && !error && !latestSnapshot) {
        status = 'kRunning';
      }

      const runDetails: MigrationRunObject = {
        taskId: taskId,
        startTimeUsecs: startTimeUsecs,
        endTimeUsecs: endTimeUsecs,
        duration: duration,
        status: status,
        error: [error],
        progressTaskId: progressTaskId,
        name: name,
      };
      subTaskDetails.push(runDetails);
    });

    // Sort in Descending order (high to low)
    this.subTaskDetails = subTaskDetails.sort(
      (objA, objB) => objB.startTimeUsecs - objA.startTimeUsecs,
    );
    this.subTaskDetails$.next(this.subTaskDetails);
  }

  /**
   * Opens modal window with timeline for pulse logs.
   */
  openActivityModal(subTask: MigrationRunObject) {
    const progressTaskId = subTask?.progressTaskId;
    const name = subTask?.name;
    if (progressTaskId) {
      this.dialogService.showDialog(TimelineDialogComponent, {
        title: this.translate.instant('recoveryActivityFor', { name }),
        errorMessages: [],
        task: progressTaskId,
      } as TimelineDialogData, { disableClose: false });
    }
  }


  /**
   * Track by function for the data table
   *
   * @param    index  Item index.
   * @param    item   The item.
   * @returns  The item id.
   */
  trackById(index: number, item: MigrationRunObject): number {
    return item.taskId;
  }
}
