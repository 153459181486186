import { Injectable } from '@angular/core';
import { getEffectiveAppList, IrisContextService } from '@cohesity/iris-core';
import { Transition } from '@uirouter/core';

import { CustomizationService, StateManagementService } from '../../services';
import { GuardPriority, GuardResult, StateGuard } from '../state-guard';

/**
 * App pillars guard to redirect to the correct pillar page.
 */
@Injectable({
  providedIn: 'root',
})
export class AppPillarsGuard implements StateGuard {
  /**
   * This guard priority is set to App, which indicates it should run as part of the main application
   * after all bootstrapping and initializing is completed.
   */
  guardPriority = GuardPriority.App;

  /**
   * Match app pillars state when loaded the first time on login.
   */
  matchCriteria = {
    to: (state, transition) => state.name === 'app-pillars' && transition.params().loginRedirect,
  };

  constructor(
    private customizationService: CustomizationService,
    private irisContextService: IrisContextService,
    private stateManagementService: StateManagementService,
  ) {}

  /**
   * Handles the redirection to specific app pillar landing page if a non
   * default landing page is set.
   *
   * @param transition The UI Router Transition.
   * @returns The redirect transition.
   */
  onStart(transition: Transition): GuardResult {
    const landingApp = this.customizationService?.userCustomizations?.heliosPreferences?.heliosDefaultLandingApp;
    const effectiveAppList = getEffectiveAppList(this.irisContextService.irisContext);
    const defaultState = effectiveAppList?.find(app => app.app === landingApp)?.state;

    if (defaultState && this.stateManagementService.canUserAccessState(defaultState)) {
      return transition.router.stateService.target(defaultState, {serviceType: landingApp});
    }

    return transition.router.stateService.target('app-pillars', {}, {inherit: false});
  }
}
