// COMPONENT: cEntityTree

/**
 * This is the private API version of this component and is ultimately destined
 * for deprecation.
 *
 * WARNING: Before final removal, make sure to migrate the ctrl.listFilter stuff
 * to the cEntityTreePub version, or determine if it's still needed at all.
 *
 * This has not already been done because at the time of writing this
 * (7/19/2019), cEntityTreePub is only used in the Job modify flow to show
 * protected objects and its header (which contains the search) is disabled by
 * default.
 */

;(function(angular, undefined) {
  'use strict';

  var modName = 'C.entityTree';
  var componentName = 'cEntityTree';
  var options = {
    bindings: {
      job: '=?',
      jobObjCounts: '=?',
      jobTagSets: '=?',
      options: '=?',
      nameQuery: '<?',
      tree: '=',
    },
    controller: 'EntityTreeCtrl',
    templateUrl: 'app/global/c-entity-tree/c-entity-tree.html'
  };

  var defaultOptions = {
    enableCheckbox: false,
    hideExcludedNodes: false,
    hideMetaData: false,
    isExcludedBranch: false,
    isRootNode: true,
    showActionIcons: true,
    showEntityTreeHeader: false,
  };

  angular
    .module(modName)
    .controller('EntityTreeCtrl', cEntityTreeControllerFn)
    .component(componentName, options);

  /**
   * @ngdoc component
   * @name C.entityTree:cEntityTree
   * @function
   *
   * @description
   * Recursive component to display a tree of entities
   *
   * @example
     <c-entity-tree tree="$ctrl.node" job="$ctrl.job" options="treeOpts"></c-entity-tree>
   */
  function cEntityTreeControllerFn(_) {
    var ctrl = this;

    /**
     * Initialize the options and child options in $onInit so that the inputs
     * will have been evaluated.
     *
     * @method $onInit
     */
    ctrl.$onInit = function $onInit() {
      ctrl.options = angular.merge({}, defaultOptions, (ctrl.options || {}));
      ctrl.childOptions = angular.merge({}, ctrl.options, {
        isRootNode: false,
        showEntityTreeHeader: false,
      });
      ctrl.childOptions.enableCheckbox =
        // If the root of the tree is a VM, prevent selection of children.
        // Otherwise use the configured value.
        _.get(ctrl.tree, '[0].entity.vmwareEntity') ||

        // If this is an active directory job, do not allow selecting children
        // only the physical host should be protected.
        ctrl.job.type === 29 ?
          false : ctrl.childOptions.enableCheckbox;
    };

    /**
     * indicates if a particular node should be displayed based on an evaluation
     * of the provided options and the node properties
     *
     * @param      {object}   node    The node
     * @return     {boolean}  true if the node should be displayed, false if not
     */
    ctrl.shouldDisplayNode = function shouldDisplayNode(node) {
      if (ctrl.options.hideExcludedNodes) {
        return node._isSelected;
      }

      if (ctrl.options.isExcludedBranch) {
        return !node._isSelected;
      }

      return true;
    };

    /**
     * AJS $filter function to filter the tree by display name.
     *
     * @function   listFilter
     * @param      {Object}    node   The node being evaluated.
     * @returns    {Boolean}   True if node matches filter criteria.
     */
    ctrl.listFilter = function listFilter(node) {
      /**
       * Because cEntityTree was designed for flat lists, and to support a tree
       * we used nested cEntityTrees, we need a way to mark each node as
       * filter-matched or not so the hierarchy selection algorithm knows what
       * is and isn't matched to the User's query. So we mark it.
       *
       * I recognize this isn't ideal in a filter function, but short of
       * rewriting cEntityTree (which uses private APIs and is destined for
       * deprecation) to properly support legit tree, this was the workaround.
       */
      return node._matchesFilter = ctrl.matchNodes(ctrl.nameQuery, node);
    }

    /**
     * Memoized _matchNodes to reduce recursion on repeated searches.
     *
     * @method   matchNodes
     */
    ctrl.matchNodes = _.memoize(
      _matchNodes,

      // https://lodash.com/docs/4.17.14#memoize resolver. Generates the memo
      // cache storage key
      (query, node) => String(query) + node.entity.id
    );

    /**
     * Determines if this node, or one of it's children matches the User's
     * query.
     *
     * @function   _matchNodes
     * @param     {String|RegExp}    [query]   The User's query, or RegExp
     *                                         wrapped User query.
     * @param     {Object}           node      The node in the tree to inspect.
     * @returns   {Boolean}   True if this or a child node matches this query
     */
    function _matchNodes(query, node) {
      var children = node.children || [];
      var displayName = node._displayName;
      var rxQuery;

      if (!query) {
        return true;
      }

      // Special handling for SQL tree. When a host or instance is matched but
      // not the DBs inside, we would still show the descendant nodes as a match
      // so that user can search for host and still be able to make selections
      // of the descendant nodes.
      if (node._rootEnvironment === 3) {
        // When traversing node children, avoid the unselected ones from matching
        if (!node._isSelected) {
          return false;
        }

        // When matching for DBs, attach host and instance name with the DB, so
        // when host or instance is searched, the DBs inside should be a match.
        if (node._parentHostEntity) {
          displayName = node._parentHostEntity.name + '/' + node._normalizedEntity.name;
        }
      }

      // Cast query to a RegExp query if it isn't already.
      rxQuery = query.source ? query : new RegExp(query, 'i');

      // First, check if this node matches the query pattern (substring match).
      return rxQuery.test(displayName) ||
        // Otherwise, recursively look into the children for a match.
        children.some(_matchNodes.bind(null, rxQuery));
    }
  }

})(angular);
