// Component: cContextMenu

;(function(angular) {
  'use strict';

  /**
   * @ngdoc component
   * @name C.contextMenu:cContextMenu
   * @description
   *   This component sets up a context-menu-like menu with customizable nav
   *   items. Used primarily for additional actions on a page or row in a
   *   listing to reduce visual clutter.
   *
   * @restrict 'E'
   * @scope
   * @transclude
   * @example
    <example module="C" animation="false">
      <c-context-menu items="itemsArray"></c-context-menu>
      <c-context-menu>
        Whatever you want to display
      </c-context-menu>
    </example>
   */

  var options = {
    controller: 'ContextMenuCtrl',
    transclude: true,
    bindings: {
      /**
       * Array of nav item objects. Example:
       * [{
       *     action: optionalClickFunction,
       *     // Use this if you want to set text for ngclipboard directive
       *     clipboardText: "ASDFJ93452ASDFafs",
       *     // deprecated: to be used for displaying text and or html for a
       *     // given menu item. Try to use translateKey as much as possible
       *     // going forward.
       *     display: '<span class="icn-sm icn-edit"></span> Optional string for
       *       display.',
       *     icon: 'icn-name',
       *     note: 'String',
       *     state: 'state-name',
       *     disabled: true,
       *     disabledTooltipKey: 'disabledTooltipKey',
       *     stateOptions: { optionalStateOptionsObject },
       *     stateParams: {},
       *
       *     // translateKey represents the key for the text string to display
       *     // from ui.json
       *     translateKey: 'edit'
       *
       *     // context values for translateKey
       *     translateValues: { name: 'object' },
       * }]
       *
       * NOTE: item.action is fired even when item.state is defined. Watch out
       * for unexpected state transition conflicts. If you change states
       * programmatically in the actionFn and provide a state name, this can
       * lead to unexpected and mysterious results.
       */
      items: '=',

      /**
       * To display a button instead of the standard (...) icon, provide this
       * attribute with a ui.json translation key to be displayed on the button
       */
      button: '@?',

      /**
       * If using a button and a styling other than 'c-btn-primary' is desired,
       * provide the button class via this attribute.
       */
      buttonClass: '@?',

      /**
       * If using a button, add an icon to the button for display alongside the
       * button text.
       */
      buttonIcon: '@?',

      /**
       * If an icon other than the standard swivel is desired, provide the
       * icon class via this attribute.
       */
      iconClass: '@?',

      /**
       * Boolean value to disable the menu. False by default.
       */
      disabled: '<?',

      /**
       * Primary action for the Split Button, which is the default button
       * displayed in it. Should be provided if we need to use the Split Button.
       */
      primaryAction: '=?',

      /**
       * Align the menu with the left of the button instead of the right. Useful
       * if the menu is shown on the left side of the page and we do not want it
       * to be cut off.
       */
      alignLeft: '<',
    },
    templateUrl: 'app/global/c-context-menu/c-context-menu.html',
  };

  angular
    .module('C.contextMenu', [])
    .controller('ContextMenuCtrl', cContextMenuCtrlFn)
    .component('cContextMenu', options);

  function cContextMenuCtrlFn(_, $state, StateManagementService, $uibPosition,
    $timeout, $element, ngDialogService) {
    var $ctrl = this;

    // declare component methods
    _.assign($ctrl, {
      checkActionAccess: checkActionAccess,
      itemAction: itemAction,
      toggleNavPanel: toggleNavPanel,

      // component life cycle methods
      $onInit: $onInit,
      $doCheck: computeActionsVisibility,
    });

    /**
     * initialize the component
     *
     * @method   $onInit
     */
    function $onInit() {
      _.assign($ctrl, {
        hasVisibleParent: true,
        hasVisibleChildren: true,
        repositionMenu: false,
        showMenuAfterReposition: false,
        transcluding: !angular.isDefined($ctrl.items),
      });
    }

    /**
     * Proxy click function to do other stuff if we want it. Like stopping
     * propagation, etc.
     *
     * @method     itemAction
     * @param      {Object}  item    The nav item object
     * @return     {Varies}          Whatever the configured item action returns
     */
    function itemAction(item) {
      // Execute the action callback
      (item.action || angular.noop)();

      // Open the dialog if it is configured
      if (item.dialog) {
        return ngDialogService.showDialog(item.dialog, item.stateParams);
      }

      // Otherwise go to the state if it's configured
      if (item.state) {
        return $state.go(item.state, item.stateParams, item.stateOptions);
      }
    }

    /**
     * Manages opening/closing the navPanel, and edge detection
     *
     * @method     toggleNavPanel
     * @param      {Bool}    show    True=show it; False=hide it
     */
    function toggleNavPanel(show) {

      // If there are no items to show, ensure the panel is closed and exit.
      if (Array.isArray($ctrl.items) && !$ctrl.items.length) {
        $ctrl.showNavPanel = false;
        return;
      }

      $ctrl.showMenuAfterReposition = false;
      $ctrl.showNavPanel = !!show;

      $timeout(function checkReposition() {
        checkRepositionMenu();
        $ctrl.showMenuAfterReposition = true;
      }, 0);
    }

    /**
     * compute menu items visibility by checking access to action item target
     * state & used change the UI eg.
     * 1. for split button if children are hidden we need to hide the toggle
     *    dropdown.
     * 2. for normal/inline context menu button we need to hide everything if
     *    all the children are hidden.
     *
     * @method   computeActionsVisibility
     */
    function computeActionsVisibility() {
      if ($ctrl.transcluding) {
        return;
      }

      $ctrl.hasVisibleParent = true;
      $ctrl.hasVisibleChildren = false;

      if ($ctrl.primaryAction) {
        $ctrl.hasVisibleParent = checkActionAccess($ctrl.primaryAction);

        if (!$ctrl.hasVisibleParent) {
          $ctrl.hasVisibleChildren = false;
          return;
        }
      }

      $ctrl.hasVisibleChildren = ($ctrl.items || []).some(
        function eachItem(item) {
          var navigationAllowed = checkActionAccess(item);
          var isPrimaryAction = _.isEqual(item, $ctrl.primaryAction);

          return !isPrimaryAction && navigationAllowed;
        }
      );
    }

    /**
     * perform target state access test for menu action item and returns true
     * if state is not defined assuming item.action is being used.
     *
     * @method   checkActionAccess
     * @param    {Object}    actionItem   The action item
     * @return   {Boolean}   False if target action item has state and not
     *                       having the right access else true.
     */
    function checkActionAccess(actionItem) {
      return actionItem.state ?
        StateManagementService.canUserAccessState(
          actionItem.state, actionItem.stateParams) : true;
    }

    /**
     * Checks if reposition is required for the menu or not
     *
     * @method   checkRepositionMenu
     * @return   {bool}   returns true if reposition is required otherwise false
     */
    function checkRepositionMenu() {
      var element = $element.find('menu');
      var offset = {};

      // Height of the header
      var pageHeaderHeight = 56;

      // Height of the subHeader
      var pageSubHeaderHeight = 56;
      var parent = {};
      var parentOffset = {};

      // Considering header will always be present, need to avoid opening of
      // context menu in the header's space.
      var topPosition = pageHeaderHeight;
      var viewPortOffset = {};
      $ctrl.repositionMenu = false;

      if (element && element.length) {
        // Passing true so that viewPort will be the document element.
        offset = $uibPosition.offset(element[0]);
        parent = $element.find('nav');
        viewPortOffset = $uibPosition.viewportOffset(parent[0], true);

        /* 'has-c-page-header' class get added when page has c-page-header
         * component. 'has-page-toolbar' class get added when page has
         * cog-page-toolbar component.
         * Don't open the menu in top, when top of the menu button don't
         * have enough space, including the headers height as headers have
         * higher z-index then menu. */
        if((angular.element('body').hasClass('has-c-page-header') ||
          angular.element('body').hasClass('has-page-toolbar')) &&
            viewPortOffset.top > topPosition + pageSubHeaderHeight){
          topPosition += pageSubHeaderHeight;
        }

        $ctrl.repositionMenu = viewPortOffset.bottom < offset.height &&
          offset.height < viewPortOffset.top - topPosition;
      }
    }
  }

})(angular);
