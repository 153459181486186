// Component: Recover Pure: Options step

;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.restorePure';

  angular
    .module(moduleName)
    .controller('pureRestoreOptionsController', pureRestoreOptionsControllerFn);

  function pureRestoreOptionsControllerFn($rootScope, $scope, SearchService,
    JobRunsService, RestoreService, SourceService, $q, $translate, $stateParams,
    SlideModalService, evalAJAX, cMessage) {

    angular.extend($scope, {
      // General Scope Vars
      entity: $scope.shared.cart[0],

      // Scope Methods
      selectRestorePoint: selectRestorePoint,
    });

    /**
     * Initialize all the things!
     *
     * @method   activate
     */
    function activate() {
      if ($stateParams.jobId &&
        ($stateParams.entityId || $stateParams.jobInstanceId)) {
        // This is an abbreviated flow
        setupAbbreviatedFlow();
      } else if (!$scope.shared.cart.length) {
        // Nothing to recover.
        $scope.startFlowOver();
      }

      updateRestoreParentSource();
    }

    /**
     * Conditionally updates the parent source of the task
     *
     * @method   updateRestoreParentSource
     */
    function updateRestoreParentSource() {
      if (!$scope.shared.task.restoreParentSource &&
        !!$scope.shared.filterLookups.registeredSourceIds &&
        $scope.shared.filterLookups.registeredSourceIds.length &&
        $scope.shared.task.objects.length) {
        // Convenience to preselect the volume's parent SAN, if one hasn't
        // already been selected.
        $scope.shared.filterLookups.registeredSourceIds.some(
          function findParent(san) {
            if (san.entity.id === $scope.shared.task.objects[0].entity.parentId) {
              $scope.shared.task.restoreParentSource = san.entity;

              return true;
            }
          }
        );
      }
    }

    /**
     * Function to make some scope changes for Direct object selection.
     *
     * @method   setupAbbreviatedFlow
     */
    function setupAbbreviatedFlow() {
      var foundMatch = false;

      $scope.isLoading = $scope.isAbbreviated = true;

      // Fetch by entityId & jobId
      SearchService.pureSearch({
        entityIds: +$stateParams.entityId,
        jobIds: [+$stateParams.jobId],
        registeredSourceIds: (!!$stateParams.parentId) ?
          [+$stateParams.parentId] :
          undefined,
      })
      .then(function getPureVolumeSuccess(resp) {
        if (resp && resp.length) {
          // Parse the response for the matching entity and add it to the cart
          foundMatch = resp.some(
            function findMatchingEntity(entity, ii) {
              // Using == in here because stateParams are strings
              if ($stateParams.entityId == entity.vmDocument.objectId.entity.id &&
                $stateParams.jobId == entity.vmDocument.objectId.jobId) {
                // Preselect snapshot?
                if ($stateParams.jobInstanceId) {
                  entity._snapshot = entity.vmDocument.versions.find(
                    function findSnapshot(run) {
                      return $stateParams.jobInstanceId == run.instanceId.jobInstanceId;
                    }
                  );
                }

                // Add it to the cart now
                $scope.addToCart(entity, false);

                return true;
              }
            }
          );
        }

        // No matching entity was found, show an error
        if (!foundMatch) {
          cMessage.error({
            titleKey: 'noVolumeFound',
            textKey: 'recover.pure.options.noEntityFound',
          });
        }
      }, evalAJAX.errorMessage)
      .finally(function getPureVolumeFinally() {
        $scope.isLoading = false;
      });
    }

    /**
     * Open the snapshot selector to let the user choose a different restore
     * point.
     *
     * @method   selectRestorePoint
     * @param    {object}   row   The search result entity in question
     */
    function selectRestorePoint(row) {
      var modalOpts = {
        templateUrl: 'app/protection/recovery/common/snapshot-selector/common.snapshot-selector.html',
        controller: 'commonRestoreSnapshotModalController',
        size: 'lg',
        resolve: {
          task: angular.copy($scope.shared.task),
          entity: row,
        },
      };

      SlideModalService
        .newModal(modalOpts)
        .then(function snapshotSelectedFn(resp) {
          angular.extend(row, {
            _snapshot: resp.snapshot,
            _archiveTarget: resp.archiveTarget,
          });

          if (resp.tapeListParams) {
            $scope.shared.tapeListParams = resp.tapeListParams;
          }
        });
    }

    // Activate!
    activate();
  }

})(angular);
