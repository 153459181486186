<cog-settings-list-item
  [formGroup]="formGroup"
  [invalid]="formControl.invalid"
  [label]="'protectionType' | translate">
  <cog-settings-list-item-value-display>
    {{'enum.protectionGroup.protectionType.' + formControl.value | translate}}
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor [formGroup]="formGroup">
    <mat-form-field>
      <mat-label translate>protectionType</mat-label>
      <mat-select [formControlName]="name">
        <mat-option value="kAuto">
          {{'enum.protectionGroup.protectionType.kAuto' | translate}}
        </mat-option>
        <mat-option value="kRCT">
          {{'enum.protectionGroup.protectionType.kRCT' | translate}}
        </mat-option>
        <mat-option value="kVSS">
          {{'enum.protectionGroup.protectionType.kVSS' | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
