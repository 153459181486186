// Controller: Clone View: Options controller

;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.cloneView';

  angular
    .module(moduleName)
    .controller('cloneViewOptionsController', cloneViewOptionsControllerFn);

  /**
   * cloneViewOptions Controller
   **************************************************************************/
  function cloneViewOptionsControllerFn(
    $scope, $state, $stateParams, evalAJAX, DateTimeService) {

    var dataLockPrefix = 'DataLock-';
    var dataLockSuffix = '-DataLock';

    angular.merge($scope, $stateParams, {
      dataLockGetterSetter: dataLockGetterSetter,
      dataLockViewToggled: dataLockViewToggled,
    });

    // METHODS
    /**
     * Initialize all the things!
     *
     * @method     activate
     */
    function activate() {
      if (!$scope.shared.cart.length && !$stateParams.view) {
        $scope.redirectAlert('clone.view.redirectMessage');

        // We can't use this page yet, go back to the beginning.
        $state.go(
          $scope.stateNames[0],
          $stateParams,
          { location: 'replace' }
        );
        return;
      }

      // Empty the search results.
      $scope.shared.results.length = 0;
      $scope.shared.pagedResults.length = 0;

      $scope.setupAbbreviatedFlow($stateParams);
    }

    /**
     * Updates task and clone View name changes when DataLock option is toggled on
     * or off.
     */
    function dataLockViewToggled() {
      var task = $scope.shared.task;

      if ($scope.shared.dataLockView) {
        task.name = [dataLockPrefix, task.name].join('');
        task.viewName = [task.viewName, dataLockSuffix].join('');
        task.viewParams.dataLockExpiryUsecs =
          DateTimeService.dateToUsecs($scope.shared.dataLockViewMinimum);
      } else {
        task.name = task.name.replace(dataLockPrefix, '');
        task.viewName = task.viewName.replace(dataLockSuffix, '');
        task.viewParams.dataLockExpiryUsecs = undefined;
      }

    }

    /**
     * getter/setter function for dataLockExpiryUsecs property
     *
     * @param      {Date}     newDate  new JavaScript Date Object following
     *                                 change made via cDatePicker
     * @return     {Integer}  current value (msecs) to display in the date
     *                        picker
     */
    function dataLockGetterSetter(newDate) {
      var viewParams = $scope.shared.task.viewParams;

      // called as a setter
      if (arguments.length) {
        if (newDate) {
          newDate = DateTimeService.beginningOfDay(newDate);
          viewParams.dataLockExpiryUsecs = DateTimeService.dateToUsecs(newDate);
        } else {
          viewParams.dataLockExpiryUsecs = undefined;
        }
      }

      return viewParams.dataLockExpiryUsecs ?
        (viewParams.dataLockExpiryUsecs / 1000) : undefined;
    }

    activate();
  }

})(angular);
