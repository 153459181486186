import { AggregatedSubtreeInfo, OracleProtectionSource, ProtectionSourceNode } from '@cohesity/api/v1';
import { DataTreeControl } from '@cohesity/helix';
import { get } from 'lodash';
import { Memoize } from '@cohesity/utils';
import { Environment, OracleDatabaseType } from 'src/app/shared/constants';

import { ProtectionSourceDataNode, TooltipContext } from '../shared/protection-source-data-node';
import { SourceNodeMetadata } from '../shared/protection-source-metadata/source-node-metadata';

/**
 * Specifies the Oracle Protection Source Node.
 */
export class OracleSourceDataNode extends ProtectionSourceDataNode<OracleProtectionSource> {

  /**
   * Oracle node meta data.
   */
  readonly metadata: SourceNodeMetadata;

  /**
   * This indicates whether the node is part of the job currently being edited or viewed.
   */
  inCurrentJob = false;

  /**
   * This indicates whether viewing node under job page.
   */
  isJob = false;

  /**
   * Map of Environment to the count of protected leaves for this node.
   */
  private protectedLeavesCountMap = new Map<string, number>();

  /**
   * Constructor
   */
  constructor(
    data: ProtectionSourceNode,
    readonly level: number,
    readonly treeControl: DataTreeControl<OracleSourceDataNode>,
    readonly isDmsScope?: boolean,
    readonly parentSource?: OracleSourceDataNode) {
    super(Environment.kOracle, data, level);
    this.data?.protectedSourcesSummary?.forEach((summary: AggregatedSubtreeInfo) => {
      this.protectedLeavesCountMap.set(summary.environment, summary.leavesCount);
    });

    this.metadata = {
      logicalSize: this.logicalSize,
      leafCount: this.expandable ? this.leafCount : undefined,
    };
  }

  /**
   * Determines if the nodes can be selected.
   */
  canSelect(): boolean {
    const isArchiveLogEnabled = get(this.data,
      'protectionSource.oracleProtectionSource.archiveLogEnabled', false);

    if (!isArchiveLogEnabled && this.data.protectionSource.environment === Environment.kOracle) {
      return isArchiveLogEnabled;
    }

    // For level-0 data-tree source nodes disable checkbox if no databases are
    // available for selection.
    if (this.level === 0 && !this.isDatabasesAvailable) {
      return false;
    }

    if (this.isDmsScope && !this.isJob) {
      return true;
    }
    return this.inCurrentJob || !this.protected;
  }

  /**
   * NOTE: Autoprotect is currently not supported for Oracle environment.
   */
  canAutoSelect(): boolean {
    return false;
  }

  /**
   * Helper to determine if this node is protected by the given environment
   * kValue job type, ie. 'kOracle'.
   *
   * @param    environment  The environment to check protection status for.
   * @return   True if the node is protected by a Job for the given Environment.
   */
  @Memoize()
  isEnvironmentProtected(environment: Environment): boolean {
    return !!this.protectedLeavesCountMap.get(environment as string);
  }

  /**
   * Determines if the Node can be expanded.
   */
  @Memoize()
  get expandable(): boolean {
    const children = this.children;
    return Array.isArray(children) && children.length > 0;
  }

  /**
   * Determines if the node is present in other Oracle Protection group.
   */
  get isOracleProtected(): boolean {
    return this.isEnvironmentProtected(Environment.kOracle);
  }

  /**
   * Returns the ID of the parent of the current databse node.
   */
  get ownerId(): number {
    return this.envSource.ownerId;
  }

  /**
   * Whether the node is a leaf which can be directly selected or not.
   * For oracle, databases are leaves.
   */
  get isLeaf() {
    return this.type === 'kDatabase';
  }

  /**
   * Memoized helper to fetch the parent host of the node.
   */
  @Memoize()
  get parentHost(): OracleSourceDataNode {
    const ownerId = this.ownerId;
    let parentHost: OracleSourceDataNode;

    if (!this.treeControl && this.parentSource) {
      return this.parentSource;
    }

    this.treeControl.checkAnyAncestor(this, (parentNode: OracleSourceDataNode): boolean => {
      if (ownerId === parentNode.id) {
        parentHost = parentNode;
        return true;
      }
    });

    return parentHost;
  }

  /**
   * Determines whether the given node supports Multi node & Multi Channel feature.
   */
  @Memoize()
  get isMultiNodeMultiChannelFeatureSuppported(): boolean {
    const physicalAgentList = get(this.parentHost,
      'data.protectionSource.physicalProtectionSource.agents', []);
    return physicalAgentList.some((agent: any): boolean => !!agent.oracleMultiNodeChannelSupported);
  }

  /**
   * Gets Parent host type.
   */
  @Memoize()
  get parentHostType(): string {
    return get(this.parentHost, 'envSource.hostType');
  }

  /**
   * Determines whether source is db authenticated or not.
   */
  get isDbAuthenticated(): boolean {
    return get(this.parentHost, 'data.registrationInfo.isDbAuthenticated', false);
  }

  /**
   * Returns the Oracle Database type.
   * Refer iris/src/app/shared/constants/oracle.constants.ts for details.
   */
  get databaseType(): OracleDatabaseType {
    if (!get(this.data.protectionSource.oracleProtectionSource, 'dbType')) {
      return null;
    }

    switch (this.data.protectionSource.oracleProtectionSource.dbType) {
      case 'kSingleInstance':
        return OracleDatabaseType.kSingleInstance;
      case 'kRACDatabase':
        return OracleDatabaseType.kRACDatabase;
    }
  }

  /**
   * Returns the UUID of the database.
   */
  get databaseUuid(): string {
    return this.protectionSource?.oracleProtectionSource?.uuid;
  }

  /**
   * Returns the unique name of the database.
   */
  get databaseUniqueName(): string {
    return this.protectionSource?.oracleProtectionSource?.databaseUniqueName;
  }

  /**
   * Returns parent host name of the selected oracle database.
   *
   * @return parent host name.
   */
  get parentHostName(): string {
    return this.parentHost?.envSource?.name;
  }

  /**
   * Return boolean to determine whether databases are available for
   * node selection or not.
   *
   * @return boolean to determine whether databases are available or not.
   */
  get isDatabasesAvailable(): boolean {
    return !!this.data.applicationNodes?.length;
  }

  /**
   * Return tooltip TranslationContext for node.
   *
   * @return  string  tooltip text to be displayed on checkbox hover
   */
  getCheckBoxToolTip(): TooltipContext {
    const isArchiveLogEnabled = this.data?.protectionSource?.oracleProtectionSource?.archiveLogEnabled;
    if (!isArchiveLogEnabled && this.data.protectionSource.environment === Environment.kOracle) {
      return { translateKey: 'sourceTree.tooltip.archiveLogDisabled' };
    }

    if (this.level === 0 && !this.isDatabasesAvailable) {
      return { translateKey: 'noDatabasesFound' };
    }
  }
}
