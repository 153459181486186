import {
  BaseClass,
  GeneralPath,
  IArrow,
  IBoundsProvider,
  IEdge,
  IVisualCreator,
  Point,
  Rect,
  SvgVisual,
} from 'yfiles';

/**
 * A simple Arrow implementation that renders a triangle arrow that can be styled with svg
 */
export default class StyledArrowRenderer extends BaseClass(IArrow, IVisualCreator, IBoundsProvider) {
  /**
   * The path/drawing for the arrow
   */
  private arrowFigure: GeneralPath = null;

  /**
   * A width to height ratio. Smaller values mean skinnier arrows.
   */
  private arrowRatio = 1 / 3;

  /**
   * The anchor point for the arrow
   */
  anchor: Point = Point.ORIGIN;

  /**
   * The direction for the arrow
   */
  direction: Point = Point.ORIGIN;

  /**
   * Returns the length of the arrow, i.e. the distance from the arrow's tip to
   * the position where the visual representation of the edge's path should begin.
   */
  get length(): number {
    return 7;
  }

  /**
   * Gets the cropping length associated with this instance.
   */
  get cropLength(): number {
    return 0;
  }

  /**
   * Gets an { @link IVisualCreator } implementation that will create
   * the { @link IVisualCreator } for this arrow at the given location using the given direction for the given edge.
   *
   * @param edge the edge this arrow belongs to
   * @param atSource whether this will be the source arrow
   * @param anchor the anchor point for the tip of the arrow
   * @param direction the direction the arrow is pointing in
   * Itself as a flyweight.
   * @returns An IVisualCreator that can be used to create and update the arrow. This immplementation uses
   *          this class to handle the creation and positioning of the item.
   */
  getVisualCreator(edge: IEdge, atSource: boolean, anchor: Point, direction: Point): IVisualCreator {
    // Cache the position info for the arroww
    this.anchor = anchor;
    this.direction = direction;
    return this;
  }

  /**
   * Gets an { @link IBoundsProvider } implementation that can yield
   * this arrow's bounds if painted at the given location using the
   * given direction for the given edge.
   *
   * @param edge the edge this arrow belongs to
   * @param atSource whether this will be the source arrow
   * @param anchor the anchor point for the tip of the arrow
   * @param direction the direction the arrow is pointing in
   * an implementation of the { @link IBoundsProvider } interface that can
   * subsequently be used to query the bounds. Clients will always call
   * this method before using the implementation and may not cache the instance returned.
   * This allows for applying the flyweight design pattern to implementations.
   * @returns An IBoundsProvider that can be used to get bounds for the arrow.
   */
  getBoundsProvider(edge: IEdge, atSource: boolean, anchor: Point, direction: Point): IBoundsProvider {
    // Cache the position info for the arroww
    this.anchor = anchor;
    this.direction = direction;
    return this;
  }

  /**
   * Creates the visual for the arrow.
   *
   * @param context The context that contains the information needed to create the visual.
   * @returns The Arrow Visual
   */
  createVisual(): SvgVisual {
    // create a new path to draw the arrow
    if (!this.arrowFigure) {
      this.arrowFigure = new GeneralPath();
      this.arrowFigure.moveTo(new Point(this.length, this.length * this.arrowRatio * -1));
      this.arrowFigure.lineTo(new Point(0, 0));
      this.arrowFigure.lineTo(new Point(this.length, this.length * this.arrowRatio));
      this.arrowFigure.close();
    }

    const path = this.arrowFigure.createSvgPath();
    path.setAttribute('class', 'arrow');

    // rotate the arrow and move it to correct position
    path.setAttribute(
      'transform',
      `matrix(${-this.direction.x} ${-this.direction.y} ${this.direction.y} ${-this.direction.x} ${this.anchor.x} ${
        this.anchor.y
      })`
    );

    return new SvgVisual(path);
  }

  /**
   * This method updates or replaces a previously created { @link Visual }.
   *
   * @param context The context that contains the information needed to create the visual.
   * @param oldVisual The visual instance that had been returned the last time the
   * @returns The updated visual.
   */
  updateVisual(): SvgVisual {
    return this.createVisual();
  }

  /**
   * Returns the bounds of the arrow for the current flyweight configuration.
   *
   * @param context The context that contains the information needed to get the visual's bounds
   * @returns The visual's bounds
   */
  getBounds(): Rect {
    return new Rect(this.anchor.x, this.anchor.y, 10, 5);
  }
}
