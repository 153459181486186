import { DateFilterRange, getDateRangeFilter, timeRangeParameterMap, ValueFilterSelection } from '@cohesity/helix';
import { ParamDeclaration } from '@uirouter/core/lib/params/interface';
import moment, { Moment } from 'moment';

/**
 * Parameter Type. Used to determine what filter type to be used.
 */
export type ParameterType = 'value' | 'date' | 'search';

/**
 * URL parameters' declaration.
 */
export interface StateParams { [key: string]: ParamDeclaration | any };

/**
 * Parameter for list.
 */
export class ListParameter {
  /** Allow multiple values if true. */
  allowMultiple = false;

  constructor(
    public name: string,
    public labelKey?: string,
    public type: ParameterType = 'value',
    public defaultParamValue: string | number = null,
    public values: ValueFilterSelection[] = [],
  ) {
    if (!this.labelKey) {
      this.labelKey = this.name;
    }
  }
}

/**
 * Date Parameter class.
 */
export class DateParameter extends ListParameter {
  /*
   * Data model for cog-date-range-filter.
   */
  filterValue: DateFilterRange<Moment> = {
    end: undefined,
    start: undefined,
  };

  constructor(
    public name = 'dateRange',
    public defaultParamValue = 'past7days',
    public startTimeKey = 'fromTimeUsecs',
    public endTimeKey = 'toTimeUsecs',
  ) {
    super(name, 'dateRange', 'date', defaultParamValue);
  }

  /**
   * Set filter value from URL parameters.
   *
   * @param params  URL parameters.
   */
  setFilterValue(params?: any) {
    Object.assign(this.filterValue, this.toFilterValue(params));
  }

  /**
   * Convert URL parameter values to filter values.
   *
   * @param params  URL parameters.
   * @returns filter values.
   */
  toFilterValue(params?: any): DateFilterRange<Moment> {
    const dateRangeFilter = getDateRangeFilter(params?.[this.name]);

    if (dateRangeFilter.timeframe) {
      return dateRangeFilter;
    }
    if (params.startTime || params.endTime) {
      return {
        start: params.startTime ? moment(params.startTime) : undefined,
        end: params.endTime ? moment(params.endTime) : undefined,
      };
    }
  }

  /**
   * Convert filter values to URL Parameter values.
   *
   * @param filterValue  Filter values
   * @returns URL Parameters.
   */
  toParamValue(filterValue: DateFilterRange<Moment>): Record<string, string | number> {
    const { start, end, timeframe } = filterValue;

    if (timeframe && timeRangeParameterMap[timeframe]) {
      return { [this.name]: timeRangeParameterMap[timeframe]};
    }
    if (start || end) {
      return {
        startTime: start?.valueOf(),
        endTime: end?.valueOf(),
      };
    }
  }

  /**
   * Convert filter values to API Parameter values.
   *
   * @param filterValue  Filter values
   * @returns API Parameters.
   */
  toApiValue(filterValue: DateFilterRange<Moment | number>): Record<string, number> {
    const result: Record<string, number> = {};
    const { start, end } = filterValue;

    if (start && start !== -1) {
      result[this.startTimeKey] = start.valueOf() * 1000;
    }
    if (end && end !== -1) {
      result[this.endTimeKey] = end.valueOf() * 1000;
    }
    return result;
  }
}

/**
 * Search Parameter class.
 */
export class SearchParameter extends ListParameter {
  constructor(
    public name = 'search',
    public labelKey?: string,
    public columns: string[] = [ 'name' ],
  ) {
    super(name, labelKey || name, 'search');
  }
}

/**
 * Function to get state params.
 *
 * @param params  Parameters definition.
 * @param pagination Add pagination parameters if true.
 * @return state params.
 */
export function getStateParams(
  params: ListParameter[],
  pagination = false,
  idType: 'int' | 'string' | null = null,
): StateParams {
  const result: StateParams = {};
  const defaultValue: ParamDeclaration = {
    type: 'string',
    array: false,
    squash: true,
    dynamic: true,
    isOptional: true
  };

  params?.forEach(param => {
    if (param.type === 'date') {
      result[param.name] = { ... defaultValue };
      result.startTime = { ...defaultValue, type: 'int' };
      result.endTime = { ...defaultValue, type: 'int' };
    } else {
      result[param.name] = {
        ...defaultValue,
        array: param.allowMultiple,
      };
    }
  });
  if (pagination) {
    result.pageNumber = { ...defaultValue, type: 'int' };
    result.pageSize = { ...defaultValue, type: 'int' };
  }
  if (idType) {
    result.id = { ...defaultValue, type: idType };
  }
  return result;
}
