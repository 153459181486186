// SQL Dashboard Service

;(function(angular, undefined) {
  'use strict';

  angular.module('C.sqlDashboardService', ['C.simpleCacheService'])
    .service('SqlDashboardService', SqlDashboardServiceFn);

  /**
   * @ngdoc service
   * @name C.sqlDashboardService.SqlDashboardService
   *
   * @description
   * Returns sql status info for the sql dashboard. This is
   **/
  function SqlDashboardServiceFn(
    _, $q, ENV_TYPE_CONVERSION, AlertService,
    DateTimeService, JobRunsService, PolicyService, PubJobService,
    PubSourceService, PubSourceServiceFormatter, RestoreService,
    SearchService, SimpleCacheService) {

    return {
      getDashboard: getDashboard,
      getDatabases: getDatabases,
      getProtectionSources: getProtectionSources,
      getRestoreTasks: getRestoreTasks,
      getSqlAlerts: getSqlAlerts,
    };

    /**
     * Returns the dashboard widgets data for local/remote or all cluster.
     *
     * @method   getDashboardData
     * @return   {object}   Promise Resolved the sql dashboard data else
     *                      rejected with error
     */
    function getDashboard() {
      var promises = {
        jobsData: _getJobSummary(),
        activeSqlJobs: _getSqlJobs(false),
        sources: getProtectionSources(),
        databases: getDatabases(),
        restores: getRestoreTasks('kSQL'),
      };

      return $q.all(promises).then(
        function onSuccess(results) {
          return {
            jobRuns: _transformJobRuns(results.jobsData),
            status: _transformStatus(
              results.sources, results.activeSqlJobs, results.restores),
            dbStatus: _transformDBStatus(results.databases),
            restoreTasks: _filterRestoreTasks(results.restores),
          };
        });
    }

    /**
     * Gets a list of restore tasks for all successful sql clones and recoveries
     *
     * @method   getRestoreTasks
     * @param    {string}   environment   Environment to filter by
     * @return   {array}    A list of recovery and clone tasks filtered for sql
     */
    function getRestoreTasks(environment) {
      var options = {
        endTimeUsecs: DateTimeService.getCurrentUsecs(),
        startTimeUsecs: DateTimeService.getDayAgoMs() * 1000,
      };

      // Cache the results of restore tasks
      return SimpleCacheService.getCachedValue(
        'SqlDashboardServiceAllRestoreTasks',
        RestoreService.getAppRestoreTasks.bind(
          null, environment, null, options));
    }

    /**
     * Filters out unsuccessful tasks and restore tasks older than thirty days.
     * Ideally, we would just pass the filter parameters to the restore tasks
     * api, but since we need to retrieve all of the tasks for the overall stats
     * we can filter client side to avoid making another api call.
     * @param    {array}   tasks   Array of restore tasks
     * @return   {array}   Restore tasks that started more than 30 days ago.
     */
    function _filterRestoreTasks(tasks) {
      var startUsecs =
        moment(Date.clusterNow()).subtract(30,'d').valueOf() * 1000;
      return tasks.filter(function filter(task) {
        return task._startTimeUsecs > startUsecs &&
          task._status === 3;
      });
    }

    /**
     * Returns a list of protection sources for sql server
     *
     * @method   getProtectionSources
     * @param    {boolean}   [skipCache]   If true, forces a reload and does
     *                                     not allow cached values to be used.
     * @return   {array}     A decorated list of source nodes
     */
    function getProtectionSources(skipCache) {
      var params = {
        environments: ['kSQL'],
      };
      var options = {
        rootEnvironment: 'kSQL',
      };
      var promises;

      if (skipCache) {
        SimpleCacheService.clearCache();
      }

      promises = {
        jobs: _getSqlJobs(false).then(_getJobMap),
        sources: SimpleCacheService.getCachedValue(
          'SqlDashboardServiceAllProtectionSources',
          PubSourceService.getSourcesInfo.bind(null, params, 'kSQL', options)),
      };

      return $q.all(promises).then(function onSuccess(results) {
        results.sources.rootNodes.forEach(function decorate(node) {
          _decorateHostNode(node, results.jobs);
        });
        return results.sources.rootNodes;
      });
    }

    /**
     * Returns a list of sql databases
     *
     * @method   getDatabases
     * @param    {boolean}   [skipCache]   If true, forces a reload and does
     *                                     not allow cached values to be used.
     * @return   {array}      A list of databases nodes
     */
    function getDatabases(skipCache) {
      var promises;

      if (skipCache) {
        SimpleCacheService.clearCache();
      }

      promises = {
        sources: getProtectionSources(),
        sqlJobs: _getSqlJobs(true).then(_getJobMap),
        policies: _getPolicyMap(),
        snapshots: _findSnapshots(),
      };

      return $q.all(promises).then(
        function onSuccess(results) {
          var sources = results.sources || [];
          var dbList = sources.reduce(function getInstances(list, node) {
            var sql = _findSql(node.applications);
            _.get(sql, 'applicationTreeInfo', []).forEach(
              function processAppNode(appNode) {
                const appNodes = _.get(appNode, 'nodes', []);
                if (!appNodes.length) {
                  var sqlProtectionInfo = _findSql(appNode.protectedSourcesSummary);
                  var isAagDb = _.get(appNode,
                    'protectionSource.sqlProtectionSource.type') === 'kAAGDatabase';
                  appNode._isProtected = sqlProtectionInfo.leavesCount > 0;
                  appNode._isSystemDb = PubSourceServiceFormatter.isSystemDatabase(
                    appNode.protectionSource);
                  appNode._isAagDb = isAagDb;
                  appNode._hostProtectionSource = node.protectionSource;
                  appNode._appProtectionSource = appNode.protectionSource;
                  appNode._latestSnapshot =
                    results.snapshots[appNode.protectionSource.id];
                  list = list.concat([appNode]);
                } else {
                  appNode.nodes.forEach(function addInfo(db) {
                    var sqlProtection = _findSql(db.protectedSourcesSummary);
                    db._isProtected = sqlProtection.leavesCount > 0;
                    db._isSystemDb = PubSourceServiceFormatter.isSystemDatabase(
                      db.protectionSource);
                    db._isAagDb = _.has(db, 'protectionSource.sqlProtectionSource.dbAagEntityId');
                    db._hostProtectionSource = node.protectionSource;
                    db._appProtectionSource = appNode.protectionSource;
                    db._latestSnapshot =
                      results.snapshots[db.protectionSource.id];
                  });
                  list = list.concat(appNode.nodes);
                }
              });
            return list;
          }, []);

          // Find Jobs associated with each db
          dbList.forEach(function updateJobInfo(db) {

            // Check for job with the database id first, then the instance id,
            // then finally, the host id.
            var job = results.sqlJobs[db.protectionSource.id] ||
              results.sqlJobs[db._appProtectionSource.id] ||
              results.sqlJobs[db._hostProtectionSource.id];

            if (job) {
              db._job = job;
              if (!job.isDeleted) {
                db._protectionPolicy = results.policies[job.policyId];
              }
            }

            if (db._latestSnapshot &&
              results.sqlJobs[db._latestSnapshot._jobId]) {
              db._snapshotJob = results.sqlJobs[db._latestSnapshot._jobId];
            }

          });
          return dbList;
        });
    }

    /**
     * Gets a list of active sql jobs
     *
     * @param    {boolean}   includeAll   Whether to include inactive and
     *                                    deleted policies. Defaults to false
     * @return   {array}     List of Jobs
     */
    function _getSqlJobs(includeAll) {
      var params = {
        environments: ['kSQL'],
        onlyReturnBasicSummary: true,
      };

      if (!includeAll) {
        params.isActive = true;
        params.isDeleted = true;
      } else {
        params.includeLastRunAndStats = true;
      }

      var key = ['SqlDashboardServiceJobs',
        includeAll ? 'All' : 'Active'].join(':');
      return SimpleCacheService.getCachedValue(key,
        PubJobService.getJobs.bind(null, params));
    }

    /**
     * Get List of open, ciritical and warning alerts
     *
     * @method   getSqlAlerts
     * @return   {object}  List of alerts
     */
    function getSqlAlerts() {
      var params = {
        alertStateList: ['kOpen'],
        endDateUsecs: DateTimeService.getCurrentUsecs(),
        maxAlerts: 1000,
        propertyKey: 'adapter_type',
        propertyValue: 'sql',
        startDateUsecs: DateTimeService.getDayAgoMs() * 1000,
      };
      return SimpleCacheService.getCachedValue('SqlDashboardServiceAlerts',
        AlertService.getAlerts.bind(null, params))
        .then(function onSuccess(results) {
          return results.data;
        })
        .then(_transformAlerts);
    }

    /**
     * Find snapshots for all databases. Returns a map of entityIds to most
     * recent snapshot.
     *
     * @return   {object}   Object containing the latest snapshot and stats
     */
    function _findSnapshots() {
      return SimpleCacheService.getCachedValue('SqlDashboardServiceSnapshots',
        SearchService.dbSearch.bind(null, { }, 'SQL'))
        .then(function jobResultsReceivedFn(results) {
          results = results || [];
          return results.reduce(function mapSnapshotsToIds(entityMap, vm) {
            // Make sure the entity is a sql database and has a valid backup.
            if (vm.vmDocument.objectId.entity.type === 3 &&
              vm.vmDocument.versions.length) {
              var entityId = vm.vmDocument.objectId.entity.id;

              // Decorate the snapshot info with the job id.
              vm._snapshot._jobId = vm._jobId;

              // Track the snapshot info by entity id.
              // It's possible that there are two entities with the same id.
              // If so, we want the one with the most recent version
              if (!entityMap[entityId] ||
                entityMap[entityId].snapshotTimestampUsecs <
                vm._snapshot.snapshotTimestampUsecs) {
                entityMap[entityId] = vm._snapshot;
              }
            }
            return entityMap;
          }, {});
        });
    }

    /**
     * Get Job runs summary for the last 24 hours
     *
     * @method   _getJobSummary
     * @return   {object}   Promise with job runs stats:
     *                       {
     *                         lastDayNumJobRuns: number;
     *                         lastDayNumJobSlaViolations: number;
     *                         lastDayNumJobErrors: number;
     *                         numJobsRunning: number;
     *                       }
     */
    function _getJobSummary() {
      var params = {
        endTimeUsecs: DateTimeService.getCurrentUsecs(),
        excludeTasks: true,
        numRuns: 1000,
        startTimeUsecs: DateTimeService.getDayAgoMs() * 1000,
      };

      return SimpleCacheService.getCachedValue('SqlDashboardServiceJobRuns',
        JobRunsService.getJobRuns.bind(null, params));
    }

    /**
     * Returns a map of policy ids to policies filtered for kQSL
     *
     * @return   {object}   Map of policy ids to policies
     */
    function _getPolicyMap() {
      var params = {
        environments: ['kSQL'],
      };
      return SimpleCacheService.getCachedValue('SqlDashboardServicePolicyMap',
      function get() {
        return PolicyService.getPolicies(params).then(
          function toPolicyMap(policies) {
            policies = policies || [];
            return policies.reduce(function reducePoliciesw(policyMap, policy) {
              policyMap[policy.id] = policy;
              return policyMap;
            }, {});
          });
        });
    }

    /**
     * Returns a map of entity ids to job ids filtered for kSQL. Also add the
     * job id to the map for easy lookup.
     *
     * @param    {array}    An array of job objects
     * @return   {object}   Map of entity ids to jobs
     */
    function _getJobMap(jobs) {
      return jobs.reduce(function reduceJobs(jobMap, job) {
        var ids = job.sourceIds || [];
        ids.forEach(function mapId(id) {
          jobMap[id] = job;
        });
        jobMap[job.id] = job;
        return jobMap;
      }, {});
    }

    /**
     * Returns summary for alerts.
     *
     * @method   _transformAlerts
     * @param    {array}    alerts    Array of alert objects
     * @return   {object}   Alerts Summary Status
     *                      {
     *                        criticalCount: number;
     *                        warningCount: number;
     *                      }
     */
    function _transformAlerts(alerts) {
      var alertStatus = {
        criticalCount: 0,
        warningCount: 0,
      };

      if (!_.get(alerts, 'length')) {
        return alertStatus;
      }

      return alerts.reduce(function(status, alert) {
          switch (alert.severity) {
            case 'kCritical':
              status.criticalCount++;
              break;
            case 'kWarning':
              status.warningCount++;
              break;
          }
        return status;
      }, alertStatus);
    }

    /**
     * Tranform job runs list to stats
     *
     * @method   _transformJobRuns
     * @param    {array}    jobRuns   Array of job run objects
     * @return   {object}   Job Status stats:
     *                      {
     *                        lastDayNumJobRuns: number;
     *                        lastDayNumJobSlaViolations: number;
     *                        lastDayNumJobErrors: number;
     *                        numJobsRunning: number;
     *                      }
     */
    function _transformJobRuns(jobRuns) {
      var jobStatus = {
        lastDayNumJobRuns: 0,
        lastDayNumJobSlaViolations: 0,
        lastDayNumJobErrors: 0,
        numJobsRunning: 0,
      };
      var allRuns = [];

      if (!jobRuns) {
        return jobStatus;
      }

      allRuns = jobRuns.reduce(
        function getRuns(list, job) {
          // Filter for all sql jobs
          if (job.backupJobRuns.jobDescription.type ===
              ENV_TYPE_CONVERSION.kSQL) {
            return list.concat(job.backupJobRuns.protectionRuns);
          }
          return list;
        }, []);

      jobStatus = allRuns.reduce(
        function aggregateStatus(status, run) {
          status.lastDayNumJobRuns++;

          if (run.backupRun.base.slaViolated) {
            status.lastDayNumJobSlaViolations++;
          }

          switch (run.backupRun.base._status) {
            case 2.2:
              // 2.2 is completed with error
              status.lastDayNumJobErrors++;
              break;
            case 1:
              // 1 is still running
              status.numJobsRunning++;
              break;
          }
          return status;

        }, jobStatus);

      return jobStatus;
    }

    /**
     * Add _sqlSummary to each host node. This includes counts for instances and
     * protected databases as well as stats on which nodes are auto protected or
     * not.
     *
     * @param   {object}   hostNode the host node to decorate
     */
    function _decorateHostNode(hostNode, jobMap) {
      var summary = {
        instances: 0,
        hostAutoProtected: false,
        autoProtectedInstances: 0,
        dbs: 0,
        protectedDbs: 0,
        manuallyProtectedDbs : 0,
        unprotectedDbs : 0,
      };
      var sqlStats = _findSql(hostNode.statsByEnv);
      var sqlApps = _findSql(hostNode.applications);
      var sqlInstances = _.get(sqlApps, 'applicationTreeInfo', []);
      var protectedSql;
      var unprotectedSql;

      summary.protectedDbs = sqlStats.protectedCount || 0;
      summary.dbs = summary.protectedDbs + (sqlStats.unprotectedCount || 0);
      summary.instances = sqlInstances.length;

      summary.hostAutoProtected = !!jobMap[hostNode.protectionSource.id];

      // If the host is auto protected, don't count anything else
      if (!summary.hostAutoProtected) {
        // Count the autoprotected instances
        sqlInstances.forEach(function checkInstance(app) {
          if (jobMap[app.protectionSource.id]) {
            summary.autoProtectedInstances++;
          } else {
            // For each non-auto protected instances, count the unprotected
            // and protected databases
            protectedSql = _findSql(app.protectedSourcesSummary);
            unprotectedSql = _findSql(app.unprotectedSourcesSummary);
            summary.manuallyProtectedDbs += protectedSql.leavesCount || 0;
            summary.unprotectedDbs += unprotectedSql.leavesCount || 0;
          }
        });
      }

      hostNode._sqlSummary = summary;
    }

    /**
     * Transform source tree to status object
     *
     * @method   _transformStatus
     * @param    {array}    sources        list of source root nodes
     * @param    {array}    jobs           list of sql jobs
     * @param    {array}    restoreTasks   list or restore tasks info
     * @return   {object}   Job Status stats:
     *                      {
     *                        hostCount: number;
     *                        instanceCount: number;
     *                        dbCount: number;
     *                        protectedDbCount: number;
     *                        protectedLogicalSize: number;
     *                        jobCount: number;
     *                        aagCount: number;
     *                        protectedAagCount: number;
     *                        recoveryCount: number;
     *                        cloneCount: number;
     *                      }
     */
    function _transformStatus(sources, jobs, restoreTasks) {
      var status = {
        hostCount: 0,
        instanceCount: 0,
        dbCount: 0,
        protectedDbCount: 0,
        protectedLogicalSize: 0,
        jobCount: 0,
        recoveryCount: 0,
        cloneCount: 0,

        // AAG data not available initially
        aagCount: undefined,
        protectedAagCount: undefined,
      };
      sources = sources || [];
      jobs = jobs || [];
      status.hostCount = sources.length;
      sources.forEach(function aggregateStats(source) {
        var stats = _findSql(source.statsByEnv);
        if (source._sqlSummary) {
          status.instanceCount += source._sqlSummary.instances || 0;
        }
        if (stats) {
          status.dbCount += stats.leavesCount || 0;
          status.protectedDbCount += stats.protectedLeavesCount || 0;
          status.protectedLogicalSize += stats.protectedLogicalSize || 0;
        }
      });

      restoreTasks.forEach(function aggregateRestoreStats(task) {
        if (task._status === 3)  {
          switch (task._restoreType) {
            case 'kRecoverApp':
              status.recoveryCount++;
              break;
            case 'kCloneApp':
              status.cloneCount++;
              break;
          }
        }
      });

      status.jobCount = jobs.length;
      return status;
    }

    /**
     * Gets the db status from a list of database instances
     *
     * @param   {array}   databases   List of database instances
     * @return  {object}  db status info
     *                    {
     *                      count: number;
     *                      online: number;
     *                      offline: number;
     *                      restoring: number;
     *                      recovering: number;
     *                      pendingRecovery: number;
     *                      suspect: number;
     *                      emergency: number;
     *                    }
     */
    function _transformDBStatus(databases) {
      var status = {
        count: 0,
        online: 0,
        offline: 0,
        restoring: 0,
        recovering: 0,
        pendingRecovery: 0,
        suspect: 0,
        emergency: 0,
      };

      databases = databases || [];
      status.count = databases.length;
      databases.forEach(function countStats(database) {
        var dbState = _.get(database,
          'protectionSource.sqlProtectionSource.sqlServerDbState');
        switch (dbState) {
          case 'kOnline':
            status.online++;
            break;
          case 'kRestoring':
            status.restoring++;
            break;
          case 'kRecovering':
            status.recovering++;
            break;
          case 'kRecoveryPending':
            status.pendingRecovery++;
            break;
          case 'kSuspect':
            status.suspect++;
            break;
          case 'kEmergency':
            status.emergency++;
            break;
          case 'kOffline':
            status.offline++;
            break;
          case 'kCopying':
          case 'kOfflineSecondary':
            break;
        }
      });
      return status;
    }

    /**
     * Search method for sql environments
     *
     * @param   {array}   envList   an array of environment objects
     * @return  {object}   the sql env or an empty object if not found
     */
    function _findSql(envList) {
      envList = envList || [];
      return envList.find(function findSql(env) {
        return env.environment === 'kSQL';
      }) || {};
    }
  }
})(angular);
