import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, finalize, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { WindowRef } from '../../util/index';
import { AdvancedSearchSuggester } from './advanced-search-suggester';
import {
  AdvancedSearchParams,
  CursorLocation,
  GetSuggestionFn,
  Suggestion,
  SuggestionType,
  TranslationResultFn,
} from './advanced-search.models';
import { enrichSuggestion } from './advanced-search.utils';

/**
 * The advanced search component stickly footer.
 */
@Directive({
  selector: '[cogAdvancedSearchFooterOption]'
})
export class AdvancedSearchFooterOptionDirective {
  constructor(public templateRef: TemplateRef<unknown>) {}
}

/**
 * The advanced search component with suggestions & autocomplete support.
 *
 * @example
 * <cog-advanced-search
 *  [formControl]="dummySearchForm"
 *  [advancedSearchSuggester]="dummySearchSuggester"
 *  [translationResult]="dummyTranslationResult">
 * </cog-advanced-search>
 */
@Component({
  selector: 'cog-advanced-search',
  exportAs: 'advancedSearch',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AdvancedSearchComponent),
      multi: true,
    },
  ],
})
export class AdvancedSearchComponent implements ControlValueAccessor, OnInit, OnDestroy {
  /**
   * Advanced search suggester.
   */
  @Input() advancedSearchSuggester: AdvancedSearchSuggester;

  /**
   * Optional callback method used to get possible values for the current suggestion.
   */
  @Input() getSuggestions?: GetSuggestionFn;

  /**
   * Callback method used to get translation for result labels.
   */
  @Input() translationResult: TranslationResultFn;

  /**
   * Indicates whether to follow search box semantics or form field semantics.
   */
  @Input() searchView = true;

  /**
   * Indicates if empty string is to be considered valid or not in form
   * field semantics.
   */
  @Input() isEmptyStringValid = false;

  /**
   * Indicates whehter to use textarea over input HTML element.
   */
  @Input() useTextarea = false;

  /**
   * The search box placeholder value.
   */
  @Input() placeholder = '';

  /**
   * The placeholder value when user is typing.
   */
  @Input() typingPlaceholder = '';

  /**
   * The search label.
   */
  @Input() searchLabel = '';

  /**
   * Label for displaying the heading for suggested values.
   */
  @Input() suggestedValuesLabel: string;

  /**
   * Label for displaying the heading for value formats.
   */
  @Input() valueFormatsLabel: string;

  /**
   * The external form control.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('formControl') extFormControl: UntypedFormControl;

  /**
   * The search input ref.
   */
  @ViewChild('searchInput') searchInput: ElementRef;

  /**
   * The autocomplete trigger ref.
   */
  @ViewChild(MatAutocompleteTrigger) matAutocompleteTrigger: MatAutocompleteTrigger;

  /** The stickly footer option */
  @ContentChild(AdvancedSearchFooterOptionDirective) footerOption!: AdvancedSearchFooterOptionDirective;

  /**
   * Indicates whether input field is focused or not.
   */
  inputFocused = false;

  /**
   * The current cursor location.
   */
  cursorLocation$ = new BehaviorSubject<CursorLocation>({
    start: null,
    end: null,
  });

  /**
   * The search suggestions.
   */
  suggestion: Suggestion = null;

  /**
   * Indicates whether suggested values are being fetched or not.
   */
  isLoadingSuggestion = false;

  /**
   * Form control for the search field.
   */
  searchCtrl = new UntypedFormControl('');

  /**
   * The search query.
   */
  get searchQuery(): string {
    return this.searchCtrl.value;
  }

  /**
   * Indicates whether search query syntax is valid.
   */
  get isStatusSuccess(): boolean {
    if (this.searchView) {
      return this.searchCtrl.value && !(this.suggestion?.type === 'error');
    }

    return false;
  }

  /**
   * Indicates whether search query syntax is invalid.
   */
  get isStatusCritical(): boolean {
    if (this.searchView) {
      return this.searchCtrl.value && (this.suggestion?.type === 'error');
    }

    if (this.extFormControl.touched && this.extFormControl.dirty) {
      return this.searchCtrl.value ? this.suggestion?.type === 'error' : !this.isEmptyStringValid;
    }

    return false;
  }

  /**
   * Use to clean up subscription.
   */
  private _destroy = new Subject<void>();

  /** The target help link */
  @Input() helpLink: string;

  /** The help link tooltip text */
  @Input() helpLinkTooltip: string;

  /** The fn used called to naviate to help link */
  @Input() openHelpLink = () => this.windowRef.openExternalLink(this.helpLink);

  constructor(private windowRef: WindowRef) {}

  ngOnInit() {
    this.setupAutoSuggester();
  }

  /**
   * Cleanup on component destroy.
   */
  ngOnDestroy() {
    this._destroy.next();
  }

  /**
   * Setup auto suggester subscriptions.
   */
  setupAutoSuggester() {
    combineLatest([
      this.cursorLocation$.pipe(distinctUntilChanged()),
      (this.searchCtrl?.valueChanges as Observable<string>).pipe(
        map(searchQuery => searchQuery ?? '')
      ),
    ])
      .pipe(
        takeUntil(this._destroy),

        // Get suggestion for value formats from the Suggester.
        map(([cursorLocation, searchQuery]) => {
          const rawSuggestion = this.advancedSearchSuggester.getRawSuggestion(
            searchQuery,
            cursorLocation
          );
          return rawSuggestion;
        }),

        // Fetch suggestion values.
        switchMap((value: AdvancedSearchParams) => {
          if (this.getSuggestions && value.suggestion?.field) {
            this.isLoadingSuggestion = true;
            return this.getSuggestions(value.suggestion).pipe(
              finalize(() => (this.isLoadingSuggestion = false)),
              map((suggestion) => ({ ...value, suggestion })),
              catchError(() => of(value).pipe(take(1)))
            );
          }

          return of(value).pipe(take(1));
        }),

        // enrich suggestion.
        map(value => ({ ...value, suggestion: enrichSuggestion(value.searchQuery, value.suggestion) })),

        // updating suggestions.
        tap(({ suggestion }) => this.suggestion = suggestion),

        // updating external model for non search mode view only.
        tap(({ searchQuery }) => {
          if (!this.searchView) {
            this.onChange(searchQuery);
          }
        }),
      ).subscribe();
  }

  /**
   * Trigger the search.
   */
  triggerSearchOnEnter() {
    if (this.searchView && this.suggestion?.type === SuggestionType.success) {
      this.matAutocompleteTrigger.closePanel();
      this.onChange(this.searchQuery);
    }
  }

  /**
   * Clear the current search query.
   */
  clearSearch() {
    this.searchCtrl.setValue('');
    this.onChange(this.searchQuery);
  }

  /**
   * For a basic primer on ControlValueAccessor used for implementing custom form controls, refer:
   * https://www.tsmean.com/articles/angular/angular-control-value-accessor-example/
   */

  /**
   * The placeholder method populated by forms API registerOnChange method which
   * is used to update changes from view to model.
   */
  onChange = (_value: string) => {};

  /**
   * The placeholder method populated by forms API registerOnTouched method which
   * is used to mark a form field should be considered blurred or "touched".
   */
  onTouch = () => {};

  /**
   * Update the view on model changes is request programmatic via forms API.
   *
   * This method is called by the forms API to write to the view when programmatic changes from model to view are
   * requested.
   *
   * @param   value   The new date range value.
   */
  writeValue(value: string) {
    this.searchCtrl.setValue(value);
  }

  /**
   * This method is called by the forms API on initialization to update the form
   * model when values propagate from the view to the model.
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function is called by the forms API on initialization
   * to update the form model on blur.
   */
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  /**
   * Handle keydown event on search text input.
   *
   * @param event Keyboard event.
   */
  onInputKeyDown(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;

    switch (event.key) {
      case 'Escape':
        // Un-focus and un-highlight the search text input.
        target.blur();
        return;
      case 'Enter':
        // Trigger search
        this.triggerSearchOnEnter();
        return;
    }
  }

  /**
   * update the cursor location.
   */
  updateCursorLocation() {
    const cursorLocation = {
      start: this.searchInput.nativeElement.selectionEnd,
      end: this.searchInput.nativeElement.selectionEnd,
    };

    if (cursorLocation.start !== this.cursorLocation$.value.start) {
      this.cursorLocation$.next(cursorLocation);
    }
  }

  /**
   * Fix the cursor location after suggestion selection.
   */
  fixCursorLocation() {
    let newLocation = (this.searchCtrl.value || '').length;

    if (this.suggestion?.textLocation) {
      const prevLocation: CursorLocation = {
        start: this.suggestion.textLocation.start,
        end: this.suggestion.textLocation.start,
      };

      this.cursorLocation$.next(prevLocation);
      newLocation = prevLocation.start + this.suggestion.text.length + 1;
    }

    // bringing the focus back to search input box.
    this.searchInput.nativeElement.focus();

    // moving the cursor at the end where suggestion value is added.
    this.searchInput.nativeElement.setRangeText('', newLocation, newLocation, 'end');

    // updating the cursor location manually because it got changes programmatically.
    this.updateCursorLocation();

    // re-opening the autocomplete in the next JS tick.
    setTimeout(() => {
      this.matAutocompleteTrigger.openPanel();
    }, 0);
  }

  /**
   * Function to handle focus action on input.
   */
  handleFocus() {
    this.inputFocused = true;
    this.onTouch();
  }

  /**
   * Stop stickly footer event and close the autocomplete panel.
   *
   * @param $event The mouse click event.
   */
  handleFoolterOptionClick($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    setTimeout(() => {
      this.matAutocompleteTrigger.closePanel();
    });
  }
}
