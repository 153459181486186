import { Injectable } from '@angular/core';
import { SecurityAdvisorServiceApi } from '@cohesity/api/secops';
import { McmProtectionGroupActivity, ProtectionGroupServiceApi, SearchServiceApi } from '@cohesity/api/v2';
import { IrisContextService, isRpaasUser } from '@cohesity/iris-core';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { catchError, debounceTime, filter, finalize, map, switchMap, tap } from 'rxjs/operators';

import { HasCustomRBACPermissions } from '@cohesity/data-govern/shared';
import { AppliedFilter, ListFilters, PaginationConfig, PostureRisk } from '../constants';
import { InventoryObject } from '../models';
import { InventoryFiltersService } from './inventory-filters.service';

/**
 * page size options for the inventory table
 */
export const PAGINATION_OPTIONS: number[] = [25, 50, 75, 100];

/**
 * default selected page for the inventory table
 */
export const DEFAULT_PAGE_INDEX = 0;

/**
 * default selected page size
 */
export const DEFAULT_PAGE_SIZE = PAGINATION_OPTIONS[0];

/**
 * Inventory Posture risk object.
 */
export interface InventoryPostureRisk {
  /**
   * Cluster id of the object.
   */
  clusterId: number;

  /**
   * Posture score of the cluster.
   */
  score: number;

  /**
   * Security risk based on posture score.
   */
  securityRisk: string;
}

@Injectable({
  providedIn: 'root',
})
export class InventoryService extends AutoDestroyable {
  /**
   * a flag to track if the data is loading
   */
  private isLoading = new BehaviorSubject<boolean>(true);

  /**
   * Observable for the loading state
   */
  readonly isLoading$ = this.isLoading.asObservable();

  /**
   * Subject containing currently applied list filters
   */
  private listFilters = new BehaviorSubject<ListFilters>({});

  /**
   * Observable for the list filters
   */
  private listFilters$ = this.listFilters.asObservable();

  /**
   * Observable for the search text.
   */
  readonly searchText$: Observable<string> = this.listFilters$.pipe(map((filters) => filters.search || ''));

  /**
   * Observable for the applied filters.
   */
  readonly appliedFilters$: Observable<AppliedFilter[]> = this.listFilters$.pipe(
    switchMap((filters) => this.populateAppliedFilters(filters))
  );

  /**
   * Pagination config to store the current state of the pagination
   */
  readonly paginationConfig$: Observable<PaginationConfig> = this.listFilters$.pipe(
    map((filters) => this.getPaginationConfig(filters.page, filters.limit))
  );

  /**
   * Observable providing access to currently loaded inventory objects. This allows access to the data without
   * triggering additional API calls.
   */
  readonly loadedInventoryObjects$ = new BehaviorSubject<InventoryObject[]>([]);

  /**
   * Observable providing access to currently loaded posture risk scan results.
   */
  readonly inventoryPostureRiskObjects$ = new BehaviorSubject<InventoryPostureRisk[]>([]);

  /**
   * Observable providing access to currently loaded vaulting activity object.
   */
  readonly inventoryVaultingActivities$ = new BehaviorSubject<McmProtectionGroupActivity[]>([]);

  /**
   * Transformed Posture scans data used by inventory pages.
   **/
  readonly postureScanResults$ = this.canAccessPostureAdvisor ? this.securityAdvisorService
    .GetScanResultsByRegion({})
    .pipe(
      map((resp) => resp?.results ?? []),
      map((scans) => scans.flatMap((scan) => scan.results.map((result) => ({
        clusterId: result.clusterId,
        score: result.currentScore,
        securityRisk: this.getSecurityRisk(result.currentScore),
      } as InventoryPostureRisk)
      )))) : of(null);

  /**
   * Check if user has access to data classification.
   */
  get canAccessDataClassification() {
    return HasCustomRBACPermissions(['DGAAS_VIEW_DC_SCAN', 'DGAAS_VIEW'], this.irisCtxService.irisContext);
  }

  /**
   * Check if user has access to threat protection.
   */
  get canAccessThreatProtection() {
    return HasCustomRBACPermissions(['DGAAS_VIEW_THREAT_SCAN', 'DGAAS_VIEW'], this.irisCtxService.irisContext);
  }

  /**
   * Check if user has access to posture advisor.
   */
  get canAccessPostureAdvisor() {
    return HasCustomRBACPermissions(['SECOPS_VIEW_POSTURE_ADVISOR', 'DGAAS_VIEW'], this.irisCtxService.irisContext);
  }

  constructor(
    private ajaxHandler: AjaxHandlerService,
    private inventoryFiltersService: InventoryFiltersService,
    private irisCtxService: IrisContextService,
    private protectionGroupService: ProtectionGroupServiceApi,
    private searchServiceApi: SearchServiceApi,
    private securityAdvisorService: SecurityAdvisorServiceApi,
  ) {
    super();
  }

  /**
   * Updates all the list filters with the specified ones
   *
   * @param appliedFilters currently applied filters
   * @param updateFilterOptions a boolean flag to indicate if field value options need to be refreshed
   */
  initFilters(appliedFilters: ListFilters, updateFilterOptions: boolean = false) {
    this.listFilters.next(appliedFilters);

    if (updateFilterOptions) {
      this.inventoryFiltersService.populateFilterOptions();
    }
  }

  /**
   * Provides access to the applied filters stored by the behaviour subject. This merges the specified filters with
   * the currently applied ones.
   *
   * @param _searchText updated searched text
   * @param _paginationConfig updated pagination configuration
   * @param _filters updated attribute filters
   * @returns combined list filters
   */
  getAllAppliedFilters(
    _searchText?: string,
    _paginationConfig?: PaginationConfig,
    _filters?: AppliedFilter[]
  ): ListFilters {
    const { search = '', limit, page, ...restFilters } = this.listFilters.getValue();

    const searchedText = _searchText ?? search;
    const paginationConfig = _paginationConfig || this.getPaginationConfig(page, limit);
    const filters = (_filters || []).reduce((acc, filterEntry) => {
      if (filterEntry.value.length) {
        acc[filterEntry.key] = filterEntry.value.join();
      }
      return acc;
    }, {});

    const allFilters: ListFilters = {
      ...(_filters?.length ? filters : restFilters),
    };

    if (searchedText.length) {
      allFilters.search = searchedText;
    }

    // if filters are changed or searched text is changed, reset the pagination index as there may not be those many
    // records matching the filter criteria
    if (_filters?.length || _searchText?.length) {
      paginationConfig.pageIndex = DEFAULT_PAGE_INDEX;
    }

    // add page number only if it is not the default one
    if (paginationConfig.pageIndex !== DEFAULT_PAGE_INDEX) {
      allFilters.page = paginationConfig.pageIndex;
    }

    // add page size only if it is changed
    if (paginationConfig.pageSize !== DEFAULT_PAGE_SIZE) {
      allFilters.limit = paginationConfig.pageSize;
    }

    return allFilters;
  }

  /**
   * Reloads the data for any backend changes. This will preservce the currently applied filters and refetch the data
   */
  refreshData(updatedObjects: InventoryObject[] = []) {
    if (updatedObjects.length) {
      const updatedData = this.loadedInventoryObjects$.value.map((existingObject) => {
        const obj = updatedObjects.find((o) => o.objectData.globalId === existingObject.objectData.globalId);
        if (obj) {
          return new InventoryObject(obj.objectData);
        }

        return existingObject;
      });

      this.loadedInventoryObjects$.next(updatedData);
      return updatedData;
    }

    return this.loadedInventoryObjects$.value;
  }

  /**
   * Loads the inventory data as per the state of the filters. Subscribing to this will make an API call and load data
   * satifying the currently applied filters.
   *
   * @returns Subscription for the data loading
   */
  loadData() {
    return combineLatest([this.appliedFilters$, this.searchText$, this.paginationConfig$]).pipe(
      debounceTime(250),
      // Filters initialisation is async, and we need to fetch data with at least supported environment filters. Below
      // filter helps in preventing redundent API call when there are no filters.
      filter(([filters]) => filters.length > 0),
      switchMap(([filters, searchFilters, paginationConfig]) =>
        this.loadInventoryObjects(filters, searchFilters, paginationConfig)
      ),
      catchError((err) => {
        this.ajaxHandler.handler(err);
        return of({
          count: 0,
          objects: [],
        });
      })
    );
  }

  /**
   * Loads the inventory objects based on the specified filters
   *
   * @param filters currently applied attribute based filters
   * @param searchFilter currently searched term
   * @param paginationConfig pagination configuration for the table
   * @returns Observable with the objects data
   */
  private loadInventoryObjects(
    filters: AppliedFilter<string>[],
    searchFilter: string,
    paginationConfig: PaginationConfig
  ) {
    this.isLoading.next(true);
    const apiFilters = this.getAPIFilters(filters, searchFilter);
    const searchService$ = this.searchServiceApi
      .SearchObjects({
        count: paginationConfig.pageSize,
        includeHeliosTagInfoForObjects: true,
        includeTenants: true,
        paginationCookie: (paginationConfig.pageIndex * paginationConfig.pageSize).toString(),
        ...apiFilters,
      });

    return combineLatest([searchService$, this.postureScanResults$]).pipe(
      switchMap(([searchResults, postureScanResults]) => {
        this.inventoryPostureRiskObjects$.next(postureScanResults);
        const protectionGroupIdentifiers = searchResults.objects.flatMap((object) =>
          object.objectProtectionInfos
            .flatMap((protectionInfo) => protectionInfo.protectionGroups?.map((pg) => pg.id))
            .filter(Boolean)
        );

        if (!protectionGroupIdentifiers || !isRpaasUser(this.irisCtxService.irisContext)) {
          return combineLatest([of(searchResults), of(null)]);
        }

        const protectionGroupActivities$ = this.protectionGroupService
          .GetMcmProtectionGroupsActivity({
            body: {
              activityTypes: ['ArchivalRun'],
              archivalRunParams: {
                isRpaas: true,
              },
              protectionGroupIdentifiers,
            },
          })
          .pipe(map((resp) => resp?.activity ?? []));

        return combineLatest([of(searchResults), protectionGroupActivities$]);
      }),
      switchMap(([searchResults, activities]) => {
        this.inventoryVaultingActivities$.next(activities);
        return of(searchResults);
      }),
      map((response) => ({
        ...response,
        objects: response.objects?.map((object) => new InventoryObject(object)),
      })),
      tap((data) => this.loadedInventoryObjects$.next(data.objects)),
      finalize(() => this.isLoading.next(false)),
    );
  }

  /**
   * Generates a consolidated filter payload that API understands
   *
   * @param filters currently applied attribute based filters on the UI
   * @param searchFilter currently applied search filter
   * @returns consolidated filters payload that API understands
   */
  private getAPIFilters(filters: AppliedFilter<string>[], searchFilter: string): SearchServiceApi.SearchObjectsParams {
    const searchFilters = this.getSearchFilters(searchFilter);

    const attributeFilters = filters.reduce((acc, filterEntry) => {
      const selectedFilterValues = this.getFilterValues(filters, filterEntry.key);
      const transformedFilters = this.inventoryFiltersService.getTransformedAPIFilters(
        filterEntry.key, selectedFilterValues, acc);

      return {
        ...acc,
        ...transformedFilters,
      };
    }, {} as SearchServiceApi.SearchObjectsParams);

    return {
      ...searchFilters,
      ...attributeFilters,
    };
  }

  /**
   * Generates search filter in API compatible format
   *
   * @param searchFilter currently applied search filter
   * @returns search filter that API understands
   */
  private getSearchFilters(searchString: string): SearchServiceApi.SearchObjectsParams {
    return searchString.trim().length ? { searchString } : {};
  }

  /**
   * Returns the filters applied for the given attribute key
   *
   * @param filters currently applied attribute based filters
   * @param filterKey attribute key for which we need the applied filters
   * @returns applied filter for the given attribute key
   */
  private getFilterValues(filters: AppliedFilter[], filterKey: string) {
    return filters.find((appliedFilter) => appliedFilter.key === filterKey)?.value || [];
  }

  /**
   * Generates pagination configuration from the page and limit value
   *
   * @param page current page number
   * @param limit page size
   * @returns pagination configuration
   */
  private getPaginationConfig(page?: number, limit?: number): PaginationConfig {
    return {
      pageIndex: page || DEFAULT_PAGE_INDEX,
      pageSize: limit || DEFAULT_PAGE_SIZE,
    };
  }

  /**
   * Generates applied attribute filters from the specified list filters
   *
   * @param filters applied list filters
   * @returns applied attribute filters that UI components understand
   */
  private populateAppliedFilters(filters: ListFilters): Observable<AppliedFilter[]> {
    return this.inventoryFiltersService.filterFieldOptions$.pipe(
      map((fields) =>
        fields.map((field) => ({
          ...field,
          value: field.options
            .map((opt) => opt.key)
            .filter((key) => {
              const selectedValues = filters[field.key]?.toString()?.split(',');
              return selectedValues?.includes(key);
            }),
        }))
      )
    );
  }

  /**
   * Gets the security risk based on posture score.
   *
   * @param score
   */
  private getSecurityRisk(score: number): string {
    if (!score) {
      return PostureRisk.unknown;
    }

    if (score < 70) {
      return PostureRisk.highRisk;
    } else if (score <= 90) {
      return PostureRisk.mediumRisk;
    } else {
      return PostureRisk.lowRisk;
    }
  }
}
