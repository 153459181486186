import { ChangeDetectionStrategy, Component, OnChanges } from '@angular/core';
import { HighchartsDataConfigService } from '@cohesity/helix';
import { Options } from 'highcharts';

import { CustomChartInput } from '../../../iris-reporting.model';
import { ChartReportsService } from '../../chart-reports.service';
import { ColumnChartItemComponent } from '../column-chart-item/column-chart-item.component';

/**
 * A pie chart report item.
 */
@Component({
  selector: 'iris-rpt-stacked-column-chart-item',
  templateUrl: './stacked-column-chart-item.component.html',
  styleUrls: ['./stacked-column-chart-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StackedColumnChartItemComponent extends ColumnChartItemComponent implements OnChanges, CustomChartInput {
  /**
   * Custom options for the stacked column chart.
   */
  options: Options = {
    chart: {
      marginTop: 20,
    },
    legend: {
      enabled: true,
      align: 'right',
      verticalAlign: 'top',
      layout: 'vertical',
      itemMarginBottom: 4,
      itemStyle: {
        fontWeight: 'normal',
      },
    },
    xAxis: {
      visible: true,
      title: {
        enabled: false,
      } as any,
    },
    yAxis: {
      visible: true,
      title: {
        enabled: false,
      } as any,
    },
    plotOptions: {
      column: {
        borderRadius: 0,
        ...this.chartPlotOptions,
      },
    },
  };

  /**
   * Given a chart config, determine whether this chart can be used to render it or now.
   *
   * @param input The chart input
   * @returns True if this component can render it.
   */
  static canRender(input: CustomChartInput): boolean {
    return ColumnChartItemComponent.canRender(input);
  }

  constructor(chartService: ChartReportsService, highchartConfigService: HighchartsDataConfigService) {
    super(chartService, highchartConfigService);
  }

  ngOnChanges(): void {
    super.ngOnChanges();

    const xAxis = this.options.xAxis as Highcharts.XAxisOptions;

    // This is to fix alignment problem on daily chart
    if (xAxis.tickInterval === 86400000) {
      xAxis.labels = {
        x: 35,
      };
    }

    this.options.tooltip = {
      xDateFormat: '%b %e, %y',
      pointFormatter: function () {
        const y = Math.round(this.y * 100) / 100;
        const percent = Math.round(this.percentage * 100) / 100;
        const name = this.series.name;

        if (y) {
          return `${name}: ${y} (${percent}%)`;
        }
        return null;
      }
    };
  }
}
