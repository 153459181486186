import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { EventTrackingService } from '@cohesity/helix';
import { AsyncBehaviorSubject, IndexedDBAdapter } from '@cohesity/utils';
import WordArray from 'crypto-js/lib-typedarrays';
import { isEqual } from 'lodash';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { flagEnabled, IrisContextService } from '../iris-context';

/**
 * By default, the cache is valid for 6 hours. After that, the cache will not
 * return a value for the item.
 */
export const defaultCacheValidity = 1000 * 60 * 60 * 6;

/**
 * This is here to make to make it easier to override the time values during
 * unit testing.
 */
export const TIME_PROVIDER = new InjectionToken<() => number>('currentTimeProvider');

/**
 * A provider to create a unique cache id. This is generaally derived from iris context
 * but could be overridden to allow other methods for getting unique cache ids.
 */
export const CACHE_ID_PROVIDER = new InjectionToken<(string, any) => string>('cacheIdProvider');

/**
 * Options for caching data values.
 */
export interface LocalDataCacheOptions<ParamsType = unknown> {
  /**
   * A base id for the cached item. The cache will use this, the user and context info, and the params object
   * to create a unique cache id for the record.
   */
  cacheId: string;

  /**
   * Optional params to cache on. Be careful that these do not include dates, or they will generate new cacheIds
   * on every request.
   */
  params?: ParamsType;

  /**
   * The amount of time the item should be valid in the cache. This defaults to 6 hours.
   */
  validityPeriod?: number;

  /**
   * A version associated with the object. When breaking changes are made in the frontend, the version can
   * be incremented to ensure that we don't return old data.
   */
  version?: number;
}

/**
 * The type for records stored in the database.
 */
export interface CacheRecord<DataType = unknown, ParamsType = unknown>
  extends Pick<LocalDataCacheOptions<ParamsType>, 'params' | 'version'> {
  /**
   * The timestamp in ms after which the record will no longer be valid.
   */
  expires: number;

  /**
   * The actual cached value.
   */
  value: DataType;
}

/**
 * This service reads and writes objects to a cache that is valid for a given period of time.
 */
@Injectable({ providedIn: 'root' })
export class LocalDataCacheService {
  /**
   * Wrapper around indexed database methods to open and read from a local database.
   */
  private indexedDb = new IndexedDBAdapter<CacheRecord<unknown>>(
    'local-data-cache',
    1,
    'data-cache',
    WordArray.random(32).toString()
  );

  constructor(
    private irisCtx: IrisContextService,
    private eventTracking: EventTrackingService,
    @Optional() @Inject(TIME_PROVIDER) private getCurrentTime: () => number,
    @Optional() @Inject(CACHE_ID_PROVIDER) private getUserCacheId: (string, any) => string
  ) {
    if (!this.getCurrentTime) {
      this.getCurrentTime = () => new Date().getTime();
    }

    if (!this.getUserCacheId) {
      this.getUserCacheId = (userCacheId, params) => this.getDefaultUserCacheId(userCacheId, params);
    }
  }

  /**
   * This initializes the cache to work with an AsyncBehaviorSubject.
   * If the value is present in the cache, it will immediately emit that value on the subject.
   * It also adds a tap pipe that will update the cache whenever the subject emits.
   *
   * @param asyncSubject The AsyncBehaviorSubject to cache data for
   * @param options Caching Options for the object
   * @returns An observable that will update the cache as long as it is subscribed to.
   */
  cacheAsyncSubject<DataType, ParamsType>(
    asyncSubject: AsyncBehaviorSubject<DataType>,
    options: LocalDataCacheOptions<ParamsType>
  ): Observable<void> {
    let cachedValue = null;
    let startTime = 0;
    return this.readItem(options).pipe(
      tap((cached: DataType) => {
        startTime = Date.now();
        cachedValue = cached;
        if (cached !== null) {
          asyncSubject.next({
            ...asyncSubject.value,
            result: cached,
          });
        }
      }),
      switchMap(() => asyncSubject),
      switchMap(value => {
        if (!value.loading && value.success && value.result) {
          this.emitTrackingEvent(
            options.cacheId,
            cachedValue !== null,
            !isEqual(cachedValue, value),
            Date.now() - startTime
          );
          return this.saveItem(value.result, options);
        }
        return of(null);
      }),
      map(() => undefined)
    );
  }

  /**
   * This initializes the cache to work with a single rxjs subject.
   * If the value is present in the cache, it will immediately emit that value on the subject.
   * It also adds a tap pipe that will update the cache whenever the subject emits.
   *
   * @param subject The rxjs Subject to cache data for
   * @param options Caching Options for the object
   * @returns An observable that will update the cache as long as it is subscribed to.
   */
  cacheSubject<DataType, ParamsType>(
    subject: Subject<DataType>,
    options: LocalDataCacheOptions<ParamsType>
  ): Observable<void> {
    let cachedValue = null;
    let startTime = 0;

    return this.readItem(options).pipe(
      tap((cached: DataType) => {
        startTime = Date.now();
        cachedValue = cached;
        if (cached !== null) {
          subject.next(cached);
        }
      }),
      switchMap(() => subject),
      switchMap(value => {
        if (value) {
          this.emitTrackingEvent(
            options.cacheId,
            cachedValue !== null,
            !isEqual(cachedValue, value),
            Date.now() - startTime
          );
        }
        return this.saveItem(value, options);
      }),
      map(() => undefined)
    );
  }

  /**
   * This wraps an observable with a response. If the item exists in the cache, it will emit twice,
   * once for the cached version and once when the actual observable emits.
   * This is usually used for api calls, which emit once and then complete. By default, this will
   * emit (up to) twice, and then complete. If we need to keep the observable open, we can use the keepOpen
   * option.
   *
   * @param observable The observable to cache data for
   * @param options Caching Options for the object
   * @returns An observable that will update the cache as long as it is subscribed to.
   */
  cacheObservable<DataType, ParamsType>(
    observable: Observable<DataType>,
    options: LocalDataCacheOptions<ParamsType> & { keepOpen?: boolean }
  ): Observable<DataType> {
    const keepOpen = options.keepOpen || false;
    return new Observable<DataType>(subscriber => {
      let startTime = 0;

      this.readItem(options)
        .pipe(
          map((cached: DataType) => {
            startTime = Date.now();
            if (cached !== null) {
              subscriber.next(cached);
            }
            return cached;
          }),
          switchMap(cached =>
            observable.pipe(
              switchMap(value => {
                const itemChanged = !isEqual(cached, value);
                if (itemChanged) {
                  subscriber.next(value);
                }
                if (!keepOpen) {
                  subscriber.complete();
                }
                this.emitTrackingEvent(options.cacheId, cached !== null, itemChanged, Date.now() - startTime);
                return itemChanged ? this.saveItem(value, options) : of(cached);
              })
            )
          ),
          catchError(error => {
            subscriber.error(error);
            if (!keepOpen) {
              subscriber.complete();
            }
            return of(null);
          })
        )
        .subscribe();
    });
  }

  /**
   * Saves an item to the cache under the specified id
   *
   * @param value The value to store
   * @param options Caching Options for the object
   */
  saveItem<DataType, ParamsType>(
    value: DataType,
    { cacheId, params, validityPeriod = defaultCacheValidity, version = 1 }: LocalDataCacheOptions<ParamsType>
  ): Observable<unknown> {
    // Disable all reads and writes if the feature flag is off
    if (!flagEnabled(this.irisCtx.irisContext, 'indexedDbCachingEnabled')) {
      return of(undefined);
    }

    // console.log(`saving id: ${cacheId}, item: ${JSON.stringify(value)}`);
    return this.indexedDb.put(this.getUserCacheId(cacheId, params), {
      expires: this.getCurrentTime() + validityPeriod,
      value,
      params,
      version,
    } as CacheRecord<DataType>);
  }

  /**
   * Reads an item from the cache
   *
   * @param options Caching Options for the object
   * @returns The cached value if it exists and has not expired.
   */
  readItem<DataType, ParamsType>({
    cacheId,
    params,
    version = 1,
  }: LocalDataCacheOptions<ParamsType>): Observable<DataType> {
    // Disable all reads and writes if the feature flag is off
    if (!flagEnabled(this.irisCtx.irisContext, 'indexedDbCachingEnabled')) {
      return of(null);
    }
    return this.indexedDb.get(this.getUserCacheId(cacheId, params)).pipe(
      map((cacheItem: CacheRecord<DataType>) => {
        if (this.getCurrentTime() <= cacheItem?.expires && cacheItem?.version === version) {
          return cacheItem.value;
        }
        return null;
      })
    );
  }

  /**
   * Emit a tracking event whenever the cache is read or written to. Should be enough to track cache data performance
   * in mixpanel
   *
   * @param cacheId The id of the data being cached
   * @param readFromCache Whether we read the initial value from cache or not
   * @param itemChanged Whether the value changed between the initial cache load and the actual api response
   * @param loadingTimeMs The total amount of time it took to load the live data
   */
  emitTrackingEvent(cacheId: string, readFromCache: boolean, itemChanged: boolean, loadingTimeMs: number) {
    this.eventTracking.send({
      key: 'load_data_from_cache',
      properties: {
        cacheId,
        readFromCache,
        itemChanged,
        loadingTimeMs,
      },
    });
  }

  /**
   * Given the user_supplied cache id, this provides a unique cache id based on
   * the user name and currently selected scope.
   *
   * @param cacheId
   * @returns
   */
  getDefaultUserCacheId(cacheId: string, params?: any): string {
    const ctx = this.irisCtx.irisContext;
    const paramKey = params ? `:${btoa(JSON.stringify(params))}` : '';
    const scope = ctx.selectedScope._nonCluster ? ctx.selectedScope.name : ctx.selectedScope.clusterId;
    return `data-cache:${ctx.user?.sid}:${scope}:${cacheId}${paramKey}`;
  }
}
