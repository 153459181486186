import { DatePipe } from '@angular/common';
import { Inject, InjectionToken, LOCALE_ID, Optional, Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

import { HourFormat } from './date.models';
import { convertTo24HourFormat, match12HourFormatRegExp } from './utils';

export const DATE_PIPE_OPTIONS = new InjectionToken<string>('HelixDatePipeOptions');
export interface DatePipeOptions {
  /**
   * Specify a global timezone
   */
  timezone?: string;

  /**
   * Specify a global locale
   */
  locale?: string;

  /**
   * Specify a global time format
   */
  hourFormat?: HourFormat;
}

export type ValidDateInput = number | string | Date;
export type Units = 'msec' | 'sec' | 'usec';
enum MSEC_UNITS_CONVERSION {
  msec = 1,
  sec = 0.001,
  usec = 1000,
}

// NOTE: The unused constants below are retained for reference.
const USEC_DAY = 9999999999999;
const USEC_MSEC_MULTIPLIER = 0.001;
// const MSEC_USEC_MULTIPLIER = 1000;

const MSEC_DAY = USEC_DAY / 1000;
// const MSEC_MULTIPLIER = 1;

// const SEC_DAY = MSEC_DAY / 1000;
const SEC_MSEC_MULTIPLIER = 1000;
// const MSEC_SEC_MULTIPLIER = 0.001;

// Use this date pattern if no pattern is passed
// to the pipe.
// TODO: This should be retreived from LocaleService so that it can be changed
// in the locale files and served a different format depending on locale.
const DEFAULT_PATTERN = "MMM d, yyyy h:mmaaaaa'm'";

// Use this date pattern if date has to be formatted with timezone by default.
const DEFAULT_DATE_PATTERN_WITH_TIMEZONE = 'MMM D, YYYY h:mm A z';

// Defines enum for available default date patterns.
export enum BuildInDatePattern {
  dateWithTimezone = 'dateWithTimezone',
}

/**
 * @description
 * Converts the input date, regardless of it's unit scale, to an integer date of
 * the specified units. By default, it will output milliseconds.
 *
 * The primary use-case for this pipe is within other pipes and Functions.
 *
 * The input can be a number, 'number', or a
 * [MomentJS parsable string](https://momentjs.com/docs/#/parsing/).
 *
 * @example
 *   // TS
 *   import { ConvertDateUnitsPipe } from "pipes";
 *   ...
 *   this.dateConverter = new ConvertDateUnitsPipe();
 *   ...
 *   return this.dateConvertor.transform('2019-03-02 11:46:57', 'sec');
 *
 *   // Templates
 *   {{ '2019-03-02 11:46:57' | convertDateUnits }}  // ms when unspecified
 *   {{ piontInTimeSeconds | convertDateUnits:'usec' | humanizeDuration }}
 *   {{ usecStartDate | convertDateUnits:'msec' }}
 *
 * @export
 */
@Pipe({
  name: 'convertDateUnits', pure: true,
  standalone: true
})
export class ConvertDateUnitsPipe implements PipeTransform {
  /**
   * The PipeTransformer method that detects and converts the input.
   *
   * @param     value                 The date to convert.
   * @param     [outputUnit='msec']   The units to convert the input to.
   * @returns   The input date converted to the specified units.
   */
  transform(value: ValidDateInput, outputUnit: Units = 'msec'): number | null {
    if (!value) {
      return null;
    }
    let date: any = value;
    date = isNaN(date) ? moment(date).valueOf() : date * 1;

    // Detect unit & convert it to milliseconds
    switch (true) {
      // Date in microseconds
      case date > USEC_DAY:
        date *= USEC_MSEC_MULTIPLIER;
        break;

      // Date in seconds
      case date <= MSEC_DAY:
        date *= SEC_MSEC_MULTIPLIER;
        break;
    }

    return (date *= MSEC_UNITS_CONVERSION[outputUnit]);
  }
}

/**
 * @description
 * This wraps Angular's DatePipe and passes all arguments along to that after
 * normalizing the input to milliseconds.
 *
 * In addition to pre-converting dates for use by Angular's DatePipe, this
 * enhances that by adding the following functionality:
 *
 *   1. Adds 'iso' named pattern which uses MomentJS
 *   2. Accepts MomentJS patterns by prefixing the pattern with '#'
 *      https://momentjs.com/docs/#/displaying/format/
 *
 * @example
 *   // Default
 *   {{ pointInTimeSeconds | cogDate }}
 *
 *   // Angular named patterns
 *   {{ usecStartTime | cogDate:'longDate' }}
 *
 *   // Moment patterns
 *   {{ msecStartTime | cogDate:'#MMM D, Y' }}
 *
 *   // Default date paatern with timezone
 *   {{ msecs | cogDate: 'dateWithDefaultTimeZone' }}
 *
 * @export
 */
@Pipe({
  name: 'cogDate', pure: false,
  standalone: true
})
export class MomentDatePipe implements PipeTransform {
  private converter: ConvertDateUnitsPipe;
  private datePipe: DatePipe;

  constructor(
    @Inject(LOCALE_ID) private _locale: string,
    @Inject(DATE_PIPE_OPTIONS) @Optional() private globalOptions?: DatePipeOptions,
  ) {
    this.converter = new ConvertDateUnitsPipe();
    this.datePipe = new DatePipe(_locale);
  }

  /**
   * Same args as Angular's own DatePipe:
   * https://github.com/angular/angular/blob/7.2.7/packages/common/src/pipes/date_pipe.ts#L154
   *
   * @param     value    The date to format.
   * @param     [args]   Same arguments as Angular's DatePipe.transform,
   *                     starting with pattern.
   * @returns   The formatted date string.
   */
  // transform(value: ValidDateInput, ...args): string|null {
  transform(
    value: ValidDateInput,
    pattern: string = DEFAULT_PATTERN,
    timezone?: string,
    locale?: string
  ): string | null {
    // Don't show anything for timestamp less than or equal to zero.
    if (!value || (Number.isFinite(value) && value <= 0)) {
      return null;
    }

    // args = [pattern, timezone, locale]
    const date: number = this.converter.transform(value, 'msec');

    switch (true) {
      // ISO string output. Not supported by native Angular date pipe as a named
      // pattern. Primarily used for testing, but can be used for display as
      // well.
      case !!/^iso$/i.test(pattern):
        return moment(date).toISOString();

      // Trigger for using moment to format instead
      case !!/^#.*/.test(pattern): {
        const momentResult = moment(date)
          .locale(locale || this.globalOptions?.locale || this._locale);

        // Cannot use utcOffset. It cannot tell the timezone because
        // multiple timezone can have same offset.
        timezone = timezone || this.globalOptions?.timezone;
        if (timezone) {
          momentResult.tz(timezone);
        }
        return momentResult.format(pattern.replace(/^#/, ''));
      }

      // Show datetime value with default date pattern along with timezone info
      case pattern === BuildInDatePattern.dateWithTimezone: {
        const dateWithTimezone = moment(date).locale(locale || this.globalOptions?.locale || this._locale);
        timezone = timezone || this.globalOptions?.timezone || moment.tz.guess();

        return dateWithTimezone.tz(timezone).format(DEFAULT_DATE_PATTERN_WITH_TIMEZONE);
      }
    }

    let dateStr = this.datePipe.transform(
      date,
      pattern,
      timezone || this.globalOptions?.timezone,
      locale || this.globalOptions?.locale
    );

    if (this.globalOptions?.timezone) {
      const dateValue = moment(date).tz(this.globalOptions.timezone);
      dateStr = this.datePipe.transform(dateValue.valueOf(), pattern, dateValue.format('Z'), locale);
    }

    // If 24-hour format is selected, replace 12-hour formatted time inside
    // date string with 24-hour formatted value.
    if (this.globalOptions?.hourFormat === HourFormat.Hour24) {
      // check if date string has 12-hour formatted time value
      const match12HourFormat = dateStr?.match(match12HourFormatRegExp);
      if (match12HourFormat?.length === 1) {
        const [ hour12Str ] = match12HourFormat;
        return dateStr.replace(new RegExp(hour12Str, 'gi'), convertTo24HourFormat(hour12Str));
      }
    }

    // Return value passed through DatePipe
    return dateStr;
  }
}
