import { Inject, Injectable, Injector } from '@angular/core';
import { merge } from 'lodash';
import { Environment, RecoveryAction } from 'src/app/shared';

import { CreateRecoveryFormTransformer } from '../model/create-recovery-form-transformer';
import {
  RECOVERY_FORM_OPTIONS,
  RecoveryFormConfig,
  RecoveryFormOptions,
} from '../model/recovery-form-options-provider';
import { IrisContextService, isIbmBaaSEnabled } from '@cohesity/iris-core';

/**
 * This service simplifies working with the Recovery Form Options. It merges the results together into a
 * single object and provides methods for working with the object.
 *
 * In order for it to work, it must be the same set of providers that includes RestoreService.
 */
@Injectable()
export class RestoreConfigService {
  /**
   * This is a list of all of the registered form options for each adapter.
   */
  readonly recoveryFormOptions: RecoveryFormOptions = {};
  constructor(
    @Inject(RECOVERY_FORM_OPTIONS) private recoveryFormConfigs: RecoveryFormOptions[],
    private irisCtx: IrisContextService,
    private injector: Injector
  ) {
    // Merge all of the form options into a single object.
    merge(this.recoveryFormOptions, ...recoveryFormConfigs);
  }

  /**
   * Get the form configuration for the specified environment.
   *
   * @param   environment   The current environment.
   * @param   action        The recovery action.
   * @returns Configuration used to load the form component and transform the data model.
   */
  getRecoveryFormConfig(environment: string, action: RecoveryAction): RecoveryFormConfig {
    const config = this.recoveryFormOptions[environment] && this.recoveryFormOptions[environment][action];

    if (!config) {
      throw new Error('Missing Form Options Config');
    }
    return config;
  }

  /**
   * Use the injected recovery form configs to look up the environment params to query for.
   * The vm search will only include environments that have recover implementations written.
   *
   * @param   recoveryFormConfigs   The injected recovery form configs
   * @param   restoreTypes         The restore operation to look up
   * @returns An array of registered environments.
   */
  getSearchEnvironments(restoreTypes: RecoveryAction[]): Environment[] {
    const environments = [];
    if (!restoreTypes) {
      throw new Error('A restore operation type must be set in order to look up search environments.');
    }

    // eslint-disable-next-line
    let matchingEnvironments = Object.keys(this.recoveryFormOptions).filter(env => {
      return (
        this.recoveryFormOptions[env] && restoreTypes.find(restoreType => !!this.recoveryFormOptions[env][restoreType])
      );
    });

    // For IBM BaaS clusters, we currently support only SQL and Physical recoveries.
    // Filter out all other environments from the payload.
    if (isIbmBaaSEnabled(this.irisCtx.irisContext)) {
      matchingEnvironments = matchingEnvironments.filter((env: Environment) =>
        [Environment.kPhysical, Environment.kPhysicalFiles, Environment.kSQL].includes(env));
    }

    environments.push(...matchingEnvironments);

    return environments;
  }

  /**
   * Function to return the form transformer for the recovery adapter.
   *
   * @param environment Environment to return the form transformer of.
   * @param action The recovery action.
   * @return The form transformer.
   */
  getTransformer(environment, action: RecoveryAction): CreateRecoveryFormTransformer<any> {
    const transformType = this.getRecoveryFormConfig(environment, action).formTransformer;
    if (!transformType) {
      return null;
    }
    return this.injector.get(transformType);
  }

}
