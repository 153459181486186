import { DataRenderer, IconDataRendererComponent } from '@cohesity/helix';
import { enumGroupMap } from '@cohesity/iris-shared-constants';

/**
 * Currently known environment groups and their icons. This
 * still isn't ideal because it's based on the environment rather
 * than the objectType, but it's the best we can do until the object
 * type is saved in the reporting database.
 */
export const envGroupIconMap: {[key: string]: string} = {
  // adapter
  // applications:
  // hadoop
  kubernetes: 'helix:object-kubernetes',
  views: 'helix:object-view',
  dbs: 'helix:object-db',
  nas: 'helix:object-volume',
  office365: 'helix:object-o365',
  physical: 'helix:object-server',
  san: 'helix:object-volume',
  vms: 'helix:object-vm',
};

/**
 * Params used for rendering an object icon in a table
 */
export interface EnvGroupIconParams {
  /**
   * The row/data key containing the object's protection type. This is optional.
   */
  protectionStatusKey?: string;
}

/**
 * Returns the helix object icon
 *
 * @param   envGroup      The env group of the object being rendered.
 * @param   renderParams    Additional params needed to parse the extra info from the secondary value.
 * @param   secondaryValue  Secondary value containing environment, protection status, etc...
 * @returns A renderer config containing the helix icon as the value.
 */

function getEnvGroupIcon(environment: string, renderParams: EnvGroupIconParams, secondaryValue: any): string {
  const { protectionStatusKey } = renderParams || {};

  const isProtected = secondaryValue && protectionStatusKey && secondaryValue[protectionStatusKey] === 'Protected';

  const envGroup = enumGroupMap[environment];

  let groupIcon = envGroupIconMap[envGroup] || 'helix:object-generic';

  if (protectionStatusKey && isProtected) {
    groupIcon += '-protected';
  }

  return groupIcon;
}

/**
 * Renders an object icon based on the environmnt group and protection status.
 */
export const envGroupIconRenderer = {
  transform: (renderValues: DataRenderer<string, EnvGroupIconParams, any>) => ({
    ...renderValues,
    value: getEnvGroupIcon(renderValues.value, renderValues.renderParams, renderValues.secondaryValue),
  }),
  component: IconDataRendererComponent,
};
