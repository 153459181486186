// Component: Bifrost settings component

;(function(angular, undefined) {
  'use strict';

  angular.module('C.tenants')
    .controller('BifrostCtrl', BifrostCtrlFn)
    .component('bifrostSettings', {
      controller: 'BifrostCtrl',
      templateUrl:
        'app/admin/access-management/tenants/bifrost-proxy/bifrost.html',
    });

  function BifrostCtrlFn(_, evalAJAX, NgUserStoreService, TenantService, UserService, $q, FEATURE_FLAGS) {
    var $ctrl = this;

    var tenantId = NgUserStoreService.getUserTenantId();

    var instructions = [{
      icon: 'type-vm-h',
      contentHtml: 'app/admin/access-management/tenants/download-bifrost/download-bifrost.html',
      data: {
        tenantId: tenantId,
      }
    },
    {
      content: 'bifrostSetup.donwloadConfig',
      icon: 'config-file',
      action: {
        idKey: 'upload-config',
        textKey: 'download',
        callback: function uploadConfig() {
          if (!FEATURE_FLAGS.enableHyxRealmClusterCompatChanges) {
            TenantService.getTenantProxy('config', tenantId);
            return ;
          }

          (
            UserService.isBifrostTenantUser() ?
            // if its a bifrost tenant user then data would already be
            // there in local storage - fetch that data
            $q.resolve(UserService.getDefaultBifrostConnectionId()) :
            // fetch default connection id for this tenant
            TenantService.getDefaultBifrostConnection(tenantId).then(
              (defaultConnection) => !!defaultConnection ? defaultConnection.id : null
            ).catch(() => null)
          ).then((defaultConnectionId) => {
            TenantService.getTenantProxy('config', tenantId, defaultConnectionId);
          }).catch(() => TenantService.getTenantProxy('config', tenantId));
        }
      }
    },
    {
      content: 'bifrostSetup.uploadStep',
      icon: 'upload',
      contentContext: {
        uploadUrl: TenantService.getTenantConfigProxyUploadUrl(),
      },
    }];

    // Declare Component Methods.
    _.assign($ctrl, {
      isLoading: false,
      instructions: instructions,
      proxies: [],

      addBifrostProxyModal: TenantService.addBifrostProxyModal,
      downloadCerts: TenantService.downloadCerts,

      // component life cycle methods.
      $onInit: $onInit,
    });

    /**
     * initialize the component
     *
     * @method   $onInit
     */
    function $onInit() {
      getData();
    }

    /**
     * make a request to get tenants configured proxies.
     *
     * @method   getData
     * @return   {Object}   Promise resolved with configured tenants proxies
     *                      else rejected with error.
     */
    function getData() {
      $ctrl.isLoading = true;
      return TenantService.getTenantProxies().then(
        function gotProxies(proxies) {
          return $ctrl.proxies = proxies;
        }, evalAJAX.errorMessage).finally(function finallyGetTenantProxies() {
        $ctrl.isLoading = false;
      });
    }
  }

})(angular);
