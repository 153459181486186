import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'coh-smb-super-users',
  templateUrl: './smb-super-users.component.html',
})
export class SmbSuperUsersComponent implements OnInit {
  /**
   * Existing list of Super User SIDs.
   */
  @Input() superUserSids = [];

  /**
   * List of trusted domains.
   */
  @Input() trustedDomains = ['LOCAL'];

  /**
   * Holds a hash of principals in their domains.
   */
  @Input() domainPrincipalsHash: Record<string, string>;

  /**
   * Remove validator for domain selector or not.
   */
  @Input() removeValidator = false;

  /**
   * Event emitter to update permissions.
   */
  @Output() updatePermissions = new EventEmitter<string[]>();

  /**
   * Init method
   */
  ngOnInit() {
    this.superUserSids = this.superUserSids || [];
    if (!this.superUserSids.length) {
      this.addSuperUser();
    }
  }

  /**
   * Method called to add a new Super User row.
   */
  addSuperUser() {
    this.superUserSids.push('');
  }

  /**
   * Method called to remove selected Super User from the list.
   *
   * @param   index   index in array to remove.
   */
  removeSuperUser(index: number) {
    this.superUserSids.splice(index, 1);
    this.emitChanges(this.superUserSids);

    // If the only entry was removed, then stub a new empty one for the UI.
    if (!this.superUserSids.length) {
      this.addSuperUser();
    }
  }

  /**
   * Method called on updating a Super User.
   *
   * @param   index   index in array which was updated.
   * @param   sid     SID of updated Super User.
   */
  onChangeSuperUserSid(index: number, sid: string) {
    this.superUserSids[index] = sid;
    if (!sid) {
      return;
    }
    this.emitChanges(this.superUserSids);
  }

  /**
   * Emit changes to the output function.
   *
   * @param   superUserSids     List of Super User SIDs.
   */
  emitChanges(superUserSids: string[]) {
    this.updatePermissions.emit(superUserSids);
  }
}
