/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Group } from '../models/group';
import { GroupParameters } from '../models/group-parameters';
import { GroupDeleteParameters } from '../models/group-delete-parameters';
@Injectable({
  providedIn: 'root',
})
class GroupsService extends __BaseService {
  static readonly GetGroupsPath = '/public/groups';
  static readonly UpdateGroupPath = '/public/groups';
  static readonly CreateGroupPath = '/public/groups';
  static readonly DeleteGroupsPath = '/public/groups';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List the groups that match the filter criteria specified using parameters.
   *
   * If no parameters are specified, all groups currently on the Cohesity Cluster
   * are returned. Specifying parameters filters the results that are returned.
   * @param params The `GroupsService.GetGroupsParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `name`: Optionally specify a group name to filter by. All groups containing name
   *   will be returned.
   *
   * - `filterByOwnedDomains`: If FilterByOwnedDomains is set to true, then the groups are filtered
   *   to show only the ones that are in the domains owned by the current tenant
   *   or user.
   *
   * - `domain`: If no domain is specified, all groups on the Cohesity Cluster are searched.
   *   If a domain is specified, only groups on the Cohesity Cluster
   *   associated with that domain are searched.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetGroupsResponse(params: GroupsService.GetGroupsParams): __Observable<__StrictHttpResponse<Array<Group>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.filterByOwnedDomains != null) __params = __params.set('filterByOwnedDomains', params.filterByOwnedDomains.toString());
    if (params.domain != null) __params = __params.set('domain', params.domain.toString());
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Group>>;
      })
    );
  }
  /**
   * List the groups that match the filter criteria specified using parameters.
   *
   * If no parameters are specified, all groups currently on the Cohesity Cluster
   * are returned. Specifying parameters filters the results that are returned.
   * @param params The `GroupsService.GetGroupsParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `name`: Optionally specify a group name to filter by. All groups containing name
   *   will be returned.
   *
   * - `filterByOwnedDomains`: If FilterByOwnedDomains is set to true, then the groups are filtered
   *   to show only the ones that are in the domains owned by the current tenant
   *   or user.
   *
   * - `domain`: If no domain is specified, all groups on the Cohesity Cluster are searched.
   *   If a domain is specified, only groups on the Cohesity Cluster
   *   associated with that domain are searched.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetGroups(params: GroupsService.GetGroupsParams): __Observable<Array<Group>> {
    return this.GetGroupsResponse(params).pipe(
      __map(_r => _r.body as Array<Group>)
    );
  }

  /**
   * Update an existing group on the Cohesity Cluster. Only group settings
   * on the Cohesity Cluster are updated. No changes are made to the
   * referenced group principal on the Active Directory.
   *
   * Returns the group that was updated on the Cohesity Cluster.
   * @param params The `GroupsService.UpdateGroupParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update a group.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateGroupResponse(params: GroupsService.UpdateGroupParams): __Observable<__StrictHttpResponse<Group>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Group>;
      })
    );
  }
  /**
   * Update an existing group on the Cohesity Cluster. Only group settings
   * on the Cohesity Cluster are updated. No changes are made to the
   * referenced group principal on the Active Directory.
   *
   * Returns the group that was updated on the Cohesity Cluster.
   * @param params The `GroupsService.UpdateGroupParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update a group.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateGroup(params: GroupsService.UpdateGroupParams): __Observable<Group> {
    return this.UpdateGroupResponse(params).pipe(
      __map(_r => _r.body as Group)
    );
  }

  /**
   * Create or add a new group to the Cohesity Cluster.
   *
   * If an Active Directory domain is specified, a new group is added to the
   * Cohesity Cluster for the specified Active Directory group principal.
   * If the LOCAL domain is specified, a new group is created directly in
   * the default LOCAL domain on the Cohesity Cluster.
   *
   * Returns the created or added group.
   * @param params The `GroupsService.CreateGroupParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to add or create a Group.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateGroupResponse(params: GroupsService.CreateGroupParams): __Observable<__StrictHttpResponse<Group>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Group>;
      })
    );
  }
  /**
   * Create or add a new group to the Cohesity Cluster.
   *
   * If an Active Directory domain is specified, a new group is added to the
   * Cohesity Cluster for the specified Active Directory group principal.
   * If the LOCAL domain is specified, a new group is created directly in
   * the default LOCAL domain on the Cohesity Cluster.
   *
   * Returns the created or added group.
   * @param params The `GroupsService.CreateGroupParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to add or create a Group.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateGroup(params: GroupsService.CreateGroupParams): __Observable<Group> {
    return this.CreateGroupResponse(params).pipe(
      __map(_r => _r.body as Group)
    );
  }

  /**
   * Delete one or more groups on the Cohesity Cluster.
   *
   * If the group on the Cohesity Cluster was added for an Active Directory user,
   * the referenced principal group on the Active Directory domain is NOT deleted.
   * Only the group on the Cohesity Cluster is deleted.
   *
   * Returns Success if the specified groups are deleted.
   * @param params The `GroupsService.DeleteGroupsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to delete one or more groups on the Cohesity Cluster.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteGroupsResponse(params: GroupsService.DeleteGroupsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete one or more groups on the Cohesity Cluster.
   *
   * If the group on the Cohesity Cluster was added for an Active Directory user,
   * the referenced principal group on the Active Directory domain is NOT deleted.
   * Only the group on the Cohesity Cluster is deleted.
   *
   * Returns Success if the specified groups are deleted.
   * @param params The `GroupsService.DeleteGroupsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to delete one or more groups on the Cohesity Cluster.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteGroups(params: GroupsService.DeleteGroupsParams): __Observable<null> {
    return this.DeleteGroupsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module GroupsService {

  /**
   * Parameters for GetGroups
   */
  export interface GetGroupsParams {

    /**
     * TenantIds contains ids of the tenants for which objects are to be
     * returned.
     */
    tenantIds?: Array<string>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Optionally specify a group name to filter by. All groups containing name
     * will be returned.
     */
    name?: string;

    /**
     * If FilterByOwnedDomains is set to true, then the groups are filtered
     * to show only the ones that are in the domains owned by the current tenant
     * or user.
     */
    filterByOwnedDomains?: boolean;

    /**
     * If no domain is specified, all groups on the Cohesity Cluster are searched.
     * If a domain is specified, only groups on the Cohesity Cluster
     * associated with that domain are searched.
     */
    domain?: string;

    /**
     * AllUnderHierarchy specifies if objects of all the tenants under the
     * hierarchy of the logged in user's organization should be returned.
     */
    allUnderHierarchy?: boolean;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateGroup
   */
  export interface UpdateGroupParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to update a group.
     */
    body?: GroupParameters;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateGroup
   */
  export interface CreateGroupParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to add or create a Group.
     */
    body?: GroupParameters;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteGroups
   */
  export interface DeleteGroupsParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to delete one or more groups on the Cohesity Cluster.
     */
    body?: GroupDeleteParameters;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { GroupsService }
