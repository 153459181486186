// Decorator: uiSelect autoOpenIfEmpty

;(function(angular, undefined) {
  'use strict';

  angular.module('ui.select')
    .config(uiSelectAutoOpenConfig);

  function uiSelectAutoOpenConfig($provide) {
    $provide.decorator('uiSelectDirective', autoOpenDecorator);
  }

  /**
   * @ngdoc  decorator
   * @name        ui.select
   * @method      autoOpenIfEmpty
   *
   * @description
   * Decorate ui-select to optionally open automatically if the ngModel is not
   * populated by setting the `auto-open-if-empty` to true. Additionally, if an
   * async call is loading the ui-select-options and they haven't been loaded
   * yet, prevent the auto open from happening until items are loaded via the
   * `auto-open-async-loading`. This is to be leveraged by components that need
   * to look up their model from the retrieved list based on a provided id or
   * other property.
   *
   * Emulated bindings:
   *    {
   *      // Required
   *      // @type {boolean}
   *      autoOpenIfEmpty: '=',
   *
   *      // @type {boolean}
   *      autoOpenAsyncLoading: '=?',
   *    }
   *
   * @example
      <ui-select ng-model="$ctrl.theSelectedThing"
        auto-open-if-empty="true"
        auto-open-async-loading="$ctrl.loadingItems">
        ...
      </ui-select>
   */
  function autoOpenDecorator($delegate) {
    var uiSelect = $delegate[0];
    var compile = uiSelect.compile;

    /**
     * Proxy the compileFn so we can proxy the LinkFn it returns and inject our
     * custom linkFn.
     *
     * @method   compile
     * @return   {function}   Angular link Fn.
     */
    uiSelect.compile = function proxyCompileFn() {
      // Cache the existing linkFn to execute after our decorator.
      var linkFn = compile.apply(this, arguments);

      return function proxyLinkFn() {
        // arguments = [scope, element, attrs, ctrls, transcludeFn]
        var scope = arguments[0];
        var attribs = arguments[2];
        var $select = arguments[3][0];
        var ngModelCtrl = arguments[3][1];

        /**
         * cache the original $render function so it can be run and restored
         * after successfully opening the uiSelect
         */
        var originalRenderFn = ngModelCtrl.$render;

        if (attribs.hasOwnProperty('autoOpenIfEmpty') &&
          scope.$eval(attribs.autoOpenIfEmpty)) {
          // Replace the $render function with custom implementation,
          // as implementation is requesting auto open functionality.
          ngModelCtrl.$render = openIfModelIsEmpty;
        }

        /**
         * Evaluates the current state of the model and opens the
         *
         * @method   openIfModelIsEmpty
         */
        function openIfModelIsEmpty() {

          // Exit early if implementation is still awaiting an async load.
          if (attribs.hasOwnProperty('autoOpenAsyncLoading') &&
            scope.$eval(attribs.autoOpenAsyncLoading)) {
            return;
          }

          if (ngModelCtrl.$isEmpty(ngModelCtrl.$modelValue)) {
            $select.open = true;
          }

          /**
           * Run the original $render function, and also assign it back to
           * ngModelCtrl.$render so subsequent model changes will behave as
           * expected.
           */
          (originalRenderFn || angular.noop)();
          ngModelCtrl.$render = originalRenderFn || angular.noop;
        }

        return linkFn.apply(this, arguments);
      };
    };

    return $delegate;
  }


})(angular);
