import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Node, NodesServiceApi } from '@cohesity/api/v1';
import { Api } from '@cohesity/api/private';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AsyncBehaviorSubject, updateWithStatus } from 'src/app/util';

import { ClusterDataNode } from '../../shared/cluster-node-tree';
import { IpmiClusterNetworkingInfo, IpmiClusterNodesContext, IpmiUserClusterInfo } from './models';

/**
 * Used to get and update ipmi settings at node level and cluster level.
 * It also includes fetchClusterTreeNodes() which fetches cluster nodes and transforms them
 * to ClusterDataNodes. This can be moved to appropriate service in future based on usage.
 */
@Injectable({
  providedIn: 'root'
})
export class IpmiService {
  /**
   * AsyncBehavior subject to hold transformed nodes.
   */
  transformedNodes$ = new AsyncBehaviorSubject<IpmiClusterNodesContext>();

  /**
   * Constructor.
   */
  constructor(private http: HttpClient,
    private nodesServiceApi: NodesServiceApi) { }

  /**
   * Get cluster ipmi networking info.
   */
  getIpmiLanInfo(): Observable<IpmiClusterNetworkingInfo> {
    return this.http.get<any>(Api.private('nexus/ipmi/cluster_get_lan_info'));
  }

  /**
   * Get cluster ipmi users info.
   */
  getIpmiClusterUsers(): Observable<IpmiUserClusterInfo> {
    return this.http.get<any>(Api.private('nexus/ipmi/cluster_list_users'));
  }

  /**
   * Update ipmi users info.
   */
  updateIpmiUsersInfo(ipmiUserInfo: IpmiUserClusterInfo) {
    return this.http.put<any>(Api.private('nexus/ipmi/cluster_update_users'), ipmiUserInfo);
  }

  /**
   * Update ipmi networking info.
   */
  updateIpmiLanInfo(lanInfo: IpmiClusterNetworkingInfo) {
    return this.http.put<any>(Api.private('nexus/ipmi/cluster_update_lan_info'), lanInfo);
  }

  /**
   * Get cluster tree nodes and update transformedNodes$ subject.
   */
  fetchClusterTreeNodes() {
    this.nodesServiceApi.GetNodes({}).pipe(
      map(this.sortNodesBySlotNumber),
      map(this.transformNodes),
      updateWithStatus(this.transformedNodes$)
    ).subscribe();
  }

  /**
   * Transform nodes api response to cluster tree data nodes.
   *
   * @param    nodes List of nodes info fetched from api.
   * @returns  IpmiClusterNodesContext object which contains cluster tree nodes and nodes map.
   */
  private transformNodes(nodes: Node[]): IpmiClusterNodesContext {
    const nodesMap = new Map<string, ClusterDataNode>();
    const nodeIdToNodeIpMap = new Map<number, string>();
    for (const node of nodes) {
      nodeIdToNodeIpMap[node.id] = node.ip;
      const chassisInfo = node.chassisInfo;
      const chassisId = chassisInfo.chassisId.toString();
      if (!nodesMap.has(chassisId)) {
        // Parent node.
        const parentNode: ClusterDataNode = {
          id: chassisId,
          serialNumber: chassisId,
          data: {
            // Todo(Sudeep) Change this to product model once iris changes are done.
            chassisModel: chassisInfo.chassisName,
          },
          nodes: []
        };
        nodesMap.set(chassisId, parentNode);
      }

      // Leaf node.
      const leafNode: ClusterDataNode = {
        id: node.id.toString(),
        data:  {
          ip: node.ip,
          slotNumber: node.slotNumber,
        }
      };

      // Get from nodesMap and append node to child nodes.
      const chassisNode = nodesMap.get(chassisId);
      chassisNode.nodes.push(leafNode);
    }
    return {
      clusterDataNodes: Array.from(nodesMap.values()),
      nodeIdToNodeIpMap: nodeIdToNodeIpMap
    };
  }

  /**
   * Sort nodes in increasing order of slot number.
   *
   * @param    nodes nodes array to be sorted.
   * @returns  Node[] Sorted array of nodes.
   */
  private sortNodesBySlotNumber(nodes: Node[]): Node[] {
    nodes.sort((node1, node2) => (node1.slotNumber - node2.slotNumber));
    return nodes;
  }
}
