<div class="container">
  <button mat-icon-button
    class="arrow arrow-prev"
    aria-label="load previous"
    [disabled]="isPrevDisabled"
    cogDataId="carousel-btn-prev"
    (click)="loadPrev()" >
    <cog-icon shape="chevron_left"></cog-icon>
  </button>
  <button mat-icon-button
    class="arrow arrow-next"
    aria-label="load next"
    [disabled]="isNextDisabled"
    cogDataId="carousel-btn-next"
    (click)="loadNext()">
    <cog-icon shape="chevron_right"></cog-icon>
  </button>
  <div class="carousel-track">
    <ng-content></ng-content>
  </div>
  <cog-carousel-indicators class="indicators" [activeIndex]="activeIndex" [totalItems]="items.length"
    (indicatorSelected)="onIndicatorClicked($event)">
  </cog-carousel-indicators>
</div>
