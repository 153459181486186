import { GetVulScanResultParams, CreateVulScanTaskParams } from '@cohesity/api/private';

/**
 * Contains parameters required for both get scan result and start scan API calls.
 */
export interface VulScanParams {
  /**
   * Parameters for both get scan result API call.
   */
  getResult: GetVulScanResultParams;

  /**
   * Parameters for start scan API call.
   */
  scan: CreateVulScanTaskParams;
}

/**
 * Contains the mapping function from snapshot data structure to params required by service.
 * This is temporary and should be simplified and likely not needed in v2 API.
 */
export class SnapshotParams {
  /**
   * Mapping from snapshot data structure to params required by service.
   *
   * @param    snapshot  Snapshot data
   * @returns  VulScan parameters for APIs
   */
  static mapSnapshot(snapshot: any): VulScanParams {
    const {
      objectId,
      objectName,
      protectionGroupId,
      runInstanceId,
      snapshotTimestampUsecs,
      sourceId,
    } = snapshot;
    const [ clusterId, clusterIncarnationId, jobId ] = protectionGroupId?.split(':')?.map(Number) ?? [];

    const getResult: GetVulScanResultParams = {
      objectId: {
        jobId,
        jobUid: {
          clusterId,
          clusterIncarnationId,
          objectId: jobId
        },
        entity: {
          id: objectId,
          parentId: sourceId,
          displayName: objectName
        }
      },
      type: 'vm',
      versions: [{
        instanceId: {
          jobInstanceId: runInstanceId,
          jobStartTimeUsecs: snapshotTimestampUsecs
        }
      }]
    };
    const scan: CreateVulScanTaskParams = {
      objectId: getResult.objectId,
      type: getResult.type,
      snapshot: {
        instanceId: getResult.versions[0].instanceId
      }
    };

    return { getResult, scan };
  }

  /**
   * Mapping from protected object data structure to params required by service.
   *
   * @param    object  Protected Object
   * @returns  VulScan parameters for APIs
   */
  static mapProtectedObject(object: any): VulScanParams {
    const {
      id: objectId,
      name: objectName,
      sourceId,
      latestSnapshot: {
        protectionGroupId,
        runInstanceId,
        protectionRunStartTimeUsecs: snapshotTimestampUsecs,
      },
      vms,
    } = object;
    const [ clusterId, clusterIncarnationId, jobId ] = protectionGroupId.split(':').map(Number);

    const getResult: GetVulScanResultParams = {
      objectId: {
        jobId,
        jobUid: {
          clusterId,
          clusterIncarnationId,
          objectId: jobId
        },
        entity: {
          id: objectId,
          parentId: sourceId,
          displayName: objectName
        }
      },
      type: 'job',
      versions: [{
        instanceId: {
          jobInstanceId: runInstanceId,
          jobStartTimeUsecs: snapshotTimestampUsecs
        }
      }]
    };
    const scan: CreateVulScanTaskParams = {
      objectId: getResult.objectId,
      type: getResult.type,
      snapshot: {
        instanceId: getResult.versions[0].instanceId,
        vms
      }
    };
    scan.objectId.entity.type = 1;

    return { getResult, scan };
  }
}
