<h2 class="auto-truncate" mat-dialog-title>
  {{ data.title || ('editRecoveryPointFor' | translate: { name: name }) }}
</h2>

<mat-dialog-content [ngClass]="{'snapshot-selection-panel-row': data.showPdbSelection}">
  <coh-snapshot-selector-plugin
    class="margin-bottom"
    webComponentName="web-dg-td-snapshot-recovery-plugin"
    [data]="data"
    [restorePoint]="value"
    (snapshotChanged)="onSnapshotChanged($event)">
  </coh-snapshot-selector-plugin>

  <cog-banner class="margin-bottom" *ngIf="data.infoMessage" cogDataId="snapshot-selector-modal-banner">
    <p>{{data.infoMessage}}</p>
  </cog-banner>

  <coh-nosql-snapshot-selector
    *ngIf="isNosqlHadoopEnvironment"
    [defaultViewSwitcherView]="defaultViewSwitcherView"
    [hideViewSwitcher]="data.hideViewSwitcher"
    [hideIndexedSnapshotsToggle]="data.hideIndexedSnapshotsToggle"
    [object]="data.object"
    [objectActionKeys]="data.objectActionKeys"
    [useSourceId]="data.useSourceId"
    [indexedObjectName]="data.indexedObjectName"
    [protectionGroupId]="data.protectionGroupId"
    [environment]="data.environment"
    [pointInTimeSelectionSupported]="data.pointInTimeSupported !== undefined ? data.pointInTimeSupported : true"
    [snapshotLabels]="data.snapshotLabels"
    [fetchRunType]="data.fetchRunType"
    (snapshotChanged)="onSnapshotChanged($event)"
  ></coh-nosql-snapshot-selector>

  <coh-snapshot-selector
    *ngIf="!isNosqlHadoopEnvironment"
    [defaultViewSwitcherView]="defaultViewSwitcherView"
    [hideViewSwitcher]="data.hideViewSwitcher"
    [hideIndexedSnapshotsToggle]="data.hideIndexedSnapshotsToggle"
    [object]="data.object"
    [objectActionKeys]="data.objectActionKeys"
    [useSourceId]="data.useSourceId"
    [indexedObjectName]="data.indexedObjectName"
    [protectionGroupId]="data.protectionGroupId"
    [environment]="data.environment"
    [pointInTimeSelectionSupported]="data.pointInTimeSupported !== undefined ? data.pointInTimeSupported : true"
    [snapshotLabels]="data.snapshotLabels"
    [fetchRunType]="data.fetchRunType"
    (snapshotChanged)="onSnapshotChanged($event)"
    [snapshotActions]="data.snapshotActions"
  ></coh-snapshot-selector>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button cogCancelButton cogDataId="cancel-snapshot-selection" mat-dialog-close>
    {{ 'cancel' | translate }}
  </button>

  <button
    mat-button
    cogSubmitButton
    cogDataId="submit-snapshot-selection"
    [mat-dialog-close]="value"
    [disabled]="!value"
  >
    {{ (data?.confirmButtonLabel || 'selectRecoveryPoint') | translate }}
  </button>
</mat-dialog-actions>
