<ng-container *ngIf="securityRuleDetails$ | async;let securityRuleDetails">
  <h2 translate class="margin-bottom-lg">definition</h2>
  <cog-statlist horizontal="true" valueSize="md" class="margin-bottom-lg" itemSpacing="lg">
    <cog-statlist-item [label]="'secure' | translate">
      {{securityRuleDetails.percentageSecure / 100 | percent}}
    </cog-statlist-item>
    <cog-statlist-item [label]="'strength' | translate">
      {{securityRuleDetails.configurationEnabledCriteriaLength +
        securityRuleDetails.accessControlEnabledCriteriaLength +
        securityRuleDetails.smartFilesEnabledCriteriaLength}}
      <span class="mat-caption">
        / {{securityRuleDetails.configurationCriteriaLength +
          securityRuleDetails.accessControlCriteriaLength + securityRuleDetails.smartFilesCriteriaLength}}
      </span>
    </cog-statlist-item>
    <cog-statlist-item class="definition-bar" [label]="'configuration' | translate">
      {{securityRuleDetails.configurationEnabledCriteriaLength}}
      <span class="mat-caption"> / {{securityRuleDetails.configurationCriteriaLength}}</span>
    </cog-statlist-item>
    <cog-statlist-item [label]="'accessControl' | translate">
      {{securityRuleDetails.accessControlEnabledCriteriaLength}}
      <span class="mat-caption">/ {{securityRuleDetails.accessControlCriteriaLength}}</span>
    </cog-statlist-item>
    <cog-statlist-item [label]="'smartFiles' | translate">
      {{securityRuleDetails.smartFilesEnabledCriteriaLength}}
      <span class="mat-caption">/ {{securityRuleDetails.smartFilesCriteriaLength}}</span>
    </cog-statlist-item>
  </cog-statlist>
  <ng-container *ngFor="let securityCriteriaList of securityRuleDetails?.groupedSecurityCriteria">
    <ng-container *ngIf="securityCriteriaList.length">
      <dg-pa-security-criteria-list [securityCriteriaList]="securityCriteriaList"></dg-pa-security-criteria-list>
      <mat-divider></mat-divider>
    </ng-container>
  </ng-container>
</ng-container>
