// SERVICE: Job Runs Service

;(function(angular, undefined) {
  'use strict';

  angular.module('C.jobRunsService', ['C.jobRunsServiceFormatter'])
    .service('JobRunsService', JobRunsServiceFn);

  function JobRunsServiceFn($rootScope, $state, $translate, $http, $q, API,
    JobRunsServiceFormatter, JobService, SourceService, ExternalTargetService,
    ENUM_BACKUP_JOB_STATUS, cUtils, cModal, FEATURE_FLAGS, DateTimeService,
    ENUM_PUBLIC_STATUS, TenantService, ENUM_BACKUP_LOCAL_JOB_STATUS_ICON_NAME,
    ENV_GROUPS, ENUM_BACKUP_SNAPSHOT_MANAGER_JOB_STATUS_ICON_NAME,
    SNAPSHOT_TARGET_TYPE) {

    var service = {
      aggregateAttemptData: aggregateAttemptData,
      cancelJobRun: cancelJobRun,
      deleteJobRuns: deleteJobRuns,
      downloadTieringReports: downloadTieringReports,
      everyTaskHasErrors: everyTaskHasErrors,
      getArchivalTargets: getArchivalTargets,
      getCopyTasks: getCopyTasks,
      getCopyTaskType: getCopyTaskType,
      getDeleteObjectsModal: getDeleteObjectsModal,
      getJobAudit: getJobAudit,
      getJobHistory: getJobHistory,
      getJobRunAction: getJobRunAction,
      getJobRunObjectMap: getJobRunObjectMap,
      getJobRuns: getJobRuns,
      // getJobRunsIncremental: getJobRunsIncremental,
      getJobRunSnapshotTargets: getJobRunSnapshotTargets,
      getJobsHash: getJobsHash,
      getJobStatusIcon: getJobStatusIcon,
      getJobSummary: getJobSummary,
      getPolicyJobs: getPolicyJobs,
      getPublicJobRuns: getPublicJobRuns,
      getReplicationTargets: getReplicationTargets,
      hasActiveTasks: hasActiveTasks,
      isCopyRunFinished: isCopyRunFinished,
      isCopyTaskRunning: isCopyTaskRunning,
      isSnapshotMarkedForDeletion: isSnapshotMarkedForDeletion,
      isValidJobRunAction: isValidJobRunAction,
      processJobRun: processJobRun,
      removeTargetFromDisabledList: removeTargetFromDisabledList,
      setLegalHold: setLegalHold,
    };

    /**
     * Public Methods
     **************************************************************************/

    /**
     * Requests job runs from the API and returns them on success
     *
     * @method   getJobRuns
     * @param    {Object=}   params   API query params{
     *   id: get job runs for provided job ID.
     *   entityId: get job runs by the protection jobs protecting the provided
     *             entity ID
     * }
     * @return   {object}            Promise to resolve request for job
     *                                runs. On success, resolves with array
     *                                of jobs containing runs, on failure,
     *                                rejects with server response.
     */
    function getJobRuns(params) {
      var headers;

      params = params || {};
      cUtils.selfOrDefault(params,
        'allUnderHierarchy', !!params.id || !!params.entityId);

      if (params.regionId) {
        headers = { regionId: params.regionId };
      }

      return $http({
        method: 'get',
        url: API.private('backupjobruns'),
        params: params,
        headers: headers,
      }).then(function successGetJobRuns(response) {
        var jobs = response.data || [];

        jobs.map(function jobRunsMapper(job) {
          if (Array.isArray(job.backupJobRuns.protectionRuns)) {
            job.backupJobRuns.protectionRuns =
              job.backupJobRuns.protectionRuns.map(
                JobRunsServiceFormatter.transformProtectionRun
              );
          }
          JobService.transformJob(job.backupJobRuns.jobDescription);
          return job;
        });

        if (params._includeTenantInfo) {
          return TenantService.resolveTenantDetails(jobs,
            'backupJobRuns.jobDescription._tenantId');
        }
        return jobs;
      });
    }

    /**
     * TODO (maulik): Currently the method is disabled as its not used anymore,
     * however, it might be used if we still want to get all runs in incremental
     * fashion for some performance usage. Keeping it for some time until we are
     * sure we wont need this at all.
     */
    /**
     * Fetches job run history incrementally to avoid protobuff errors on large
     * history sets with many protected VMs (run tasks). In most cases, this
     * will be one request.
     *\
     * In a nutshell, this fetches $numFetchFirst runs initially, and if the
     * results are the same number as $numFetchFirst, it incrementally fetches
     * the next $numFetchMore runs starting with the oldest known endTimeUsecs-1
     * until we get a response has fewer results than $numFetchMore.
     *
     * Fetch quantities can be configured below at the top of the function.
     * If the number of VMs is too huge to load and cause payload capacity issue,
     * user can enable the lowFetchJobRun feature flag to avoid the problem.
     *
     * @method   getJobRunsIncremental
     * @param    {object}   params  API endpoint request params.
     *                              https://{node}/docs/restApiDocs/bootprintinternal/#operation--backupjobruns-get
     * @returns  {object}           Promise to resolve with a single job object
     *                              (fully detailed), or the raw server response
     *                              in case of error.
     */
    // function getJobRunsIncremental(params) {
    //   var deferred = $q.defer();
    //   var firstIncrementalHasResults = false;

    //   // @type  {integer} - Number of runs to fetch in the first request
    //   var numFetchFirst = FEATURE_FLAGS.lowNumberFetchJobRun ? 5 : 75;

    //   // @type  {integer} - Number of additional runs to get incrementally
    //   var numFetchMore = FEATURE_FLAGS.lowNumberFetchJobRun ? 5 : 75;

    //   // @type  {object} - $http config.
    //   var opts = {
    //     method: 'get',
    //     url: API.private('backupjobruns'),

    //     // https://10.2.32.95/docs/restApiDocs/bootprintinternal/#operation--backupjobruns-get
    //     params: angular.extend({
    //       // The whole point of incrementally building the jobRunsHistory is to
    //       // avoid protobuf overrun errors and still get the full details. So
    //       // we're forcing the include tasks.
    //       excludeTasks: false,
    //       numRuns: numFetchFirst,

    //       // Because SQL VM Jobs also have log run backups, we want to filter
    //       // them out by default (can be overridden by incoming params{}
    //       // argument).
    //       runTypes: ['kFull', 'kRegular'],
    //     }, params),
    //   };

    //   // @type  {object} - Cumulative Job object for returning when incremental
    //   //                   fetching is complete.
    //   var job;

    //   // No job Id restriction, bail because this will be sloooow.
    //   if (!params.id) {
    //     return reject();
    //   }

    //   /**
    //    * Helper function to reject this deferred promise.
    //    *
    //    * @method   reject
    //    * @param    {*}       [result={}]  Any output to resolve the promise with.
    //    * @returns  {object}  Promise to reject with the result
    //    */
    //   function reject(result) {
    //     deferred.reject(result || job);
    //     return deferred.promise;
    //   }

    //   /**
    //    * This is the method that does all the work.
    //    *
    //    * @method   handleIncrementalJobRuns
    //    */
    //   function handleIncrementalJobRuns() {
    //     // Make a request with the latest known config object.
    //     $http(opts).then(function runsReceived(resp) {
    //       var data = resp.data && resp.data[0];
    //       var oldestStartTime = 0;

    //       // No runs. Reject with an error.
    //       if (!data || !Array.isArray(data.backupJobRuns.protectionRuns)) {
    //         // If the first incremental fetch doesn't have data,
    //         // return reject function.
    //         return firstIncrementalHasResults ? deferred.resolve(job) : reject();
    //       }
    //       if (!job) {
    //         // Initial fetch, assign the cumulative job{}
    //         job = data;
    //         JobService.transformJob(job.backupJobRuns.jobDescription);
    //         // Rewrite the protection runs with the transformed versions of the
    //         // same
    //         job.backupJobRuns.protectionRuns = job.backupJobRuns.protectionRuns
    //           .map(JobRunsServiceFormatter.transformProtectionRun);
    //       } else {
    //         // Since the job{} already exists, push the transformed updates to
    //         // the existing run history (accumulate them). Assumption: this
    //         // array is in descending order by jobRunStartDate and each
    //         // additional fetch gets older history.
    //         Array.prototype.push.apply(
    //           job.backupJobRuns.protectionRuns,
    //           data.backupJobRuns.protectionRuns.map(
    //             JobRunsServiceFormatter.transformProtectionRun
    //           )
    //         );
    //       }

    //       // If the most recent fetch returned fewer results than we requested.
    //       // We can resolve the request now. We're done.
    //       if (opts.params.numRuns > data.backupJobRuns.protectionRuns.length) {
    //         deferred.resolve(job);
    //         return;
    //       } else {
    //         deferred.notify(job);
    //       }

    //       // Cache the oldest known run startTimeUsecs that we have
    //       oldestStartTime = job.backupJobRuns.protectionRuns.slice(-1)[0].backupRun.base.startTimeUsecs;

    //       // If we've gotten this far, we need to request the next set of runs.
    //       angular.extend(opts.params, {
    //         // Decrement the last oldestStartTime by 1 to ensure we don't get
    //         // that same result in the next set.
    //         endTimeUsecs: --oldestStartTime,
    //         // Change the number of requested runs after the first request
    //         // (typically fewer).
    //         numRuns: numFetchMore,
    //       });

    //       firstIncrementalHasResults = true;

    //       // Run this self method again.
    //       handleIncrementalJobRuns();
    //     }, reject);
    //   }

    //   handleIncrementalJobRuns();

    //   return deferred.promise;
    // }

    /**
     * deletes job runs
     *
     * @method deleteJobRuns
     * @param  {Object}  data options for delete
     * @return {object} promise to resolve the request
     */
    function deleteJobRuns(data) {
      return $http({
        method: 'put',
        url: API.public('protectionRuns'),
        data: data,
      }).then(function successDeleteJobRuns(response) {
        return response.data || [];
      });
    }

    /**
     * downloads tiering logs
     *
     * @param {Object} params
     * @returns
     */
    function downloadTieringReports(params) {
      const relativePath = API.publicV2(`data-tiering/tasks/${params.id}/runs/${params.runId}/download-report`);
      const url = new URL(window.location.origin + relativePath);
      url.searchParams.set('targetViewName', params.targetViewName);
      url.searchParams.set('filePath', params.filePath);
      window.open(url, '_blank');
    }

    /**
     * Adds or removes the legal hold on selected entities of a Job Run.
     *
     * @method   setLegalHold
     * @param    {object}   requestObject   object containing entities to
     *                                      add/remove legal hold
     * @return   {object}   Promise to resolve the request
     */
    function setLegalHold(requestObject) {
      return $http({
        method: 'put',
        url: API.public('protectionRuns'),
        data: requestObject,
      }).then(function successDeleteJobRuns(response) {
        return response.data || [];
      });
    }

    /**
     * Fetches the Protection Job runs.
     *
     * @method   getPublicJobRuns
     * @param    {object}     queryParams   Specifies the query parameters for
     *                                      fetching the job runs. Below are
     *                                      the accepted params.
     *
     *                                      JobId *int64
     *                                      StartedTimeUsecs *int64
     *                                      StartTimeUsecs *int64
     *                                      EndTimeUsecs *int64
     *                                      NumRuns *int64
     *                                      ExcludeTasks *bool
     *                                      SourceId *int64
     *                                      RunTypes []string
     *                                      ExcludeErrorRuns *bool
     *                                      ExcludeNonRestoreableRuns *bool
     *
     * @return   {object}     Returns the Promise Object to resolve with the
     *                        protection job runs.
     */
    function getPublicJobRuns(queryParams) {
      return $http({
        method: 'get',
        url: API.public('protectionRuns'),
        params: queryParams,
      }).then(function onSuccessfulResponse(response) {
        return JobRunsServiceFormatter
          .decoratePublicJobRuns(response.data);
      });
    }

    /**
     * Gets a Job's summary.
     *
     * @method   getJobSummary
     * @param    {object}    params   The parameters{
     *   ids: The jobs details for provided job Ids.
     *   policyIds: get Jobs details that as using provided policies.
     * }
     * @return   {object}            Promise to resolve with the Job
     *                                summary, or raw server response in
     *                                case of error
     */
    function getJobSummary(params) {
      params = params || {};

      // Add allUnderHierarchy when params doesn't have the key and params have
      // a list of job ids.
      cUtils.selfOrDefault(params, 'allUnderHierarchy',
        !!_.get(params, 'ids.length') || !!_.get(params, 'policyIds.length'));

      return $http({
        method: 'get',
        url: API.private('backupjobssummary'),
        params: params
      }).then(function successGetJobSummary(response) {
        // Amend run objects with appropriate copy task flags
        angular.forEach(response.data, function markRunsWithCopyTasks(job) {
          job.backupJobSummary.jobDescription =
            JobService.transformJob(job.backupJobSummary.jobDescription);

          if (job.backupJobSummary &&
            job.backupJobSummary.lastProtectionRun) {
            job.backupJobSummary.lastProtectionRun =
              JobRunsServiceFormatter.transformProtectionRun(
                job.backupJobSummary.lastProtectionRun);
          }

          // for data migration job, transform schedule obj to inline schedule
          if (job.backupJobSummary.jobDescription.stubbingPolicy &&
            job.backupJobSummary.jobDescription.startTime) {
            job.backupJobSummary.jobDescription._inlineSchedule =
              JobRunsServiceFormatter.transformScheduleContext(
                job.backupJobSummary.jobDescription.startTime,
                job.backupJobSummary.jobDescription.stubbingPolicy);
          }
        });

        // If _includeTenantInfo is true, add the tenant info in the job summary
        if(params._includeTenantInfo) {
          return TenantService.resolveTenantDetails(response.data,
            'backupJobSummary.jobDescription._tenantId')
            .then(function updatedJobSummary(jobs) {
              response.jobs = jobs;
              return response;
            });
        }

        return response;
      });
    }

    /**
     * makes a request to API (via getJobSummary) for the jobs
     * associated with a particular policy and returns results
     *
     * @method   getPolicyJobs
     * @param    {Integer}   policyId   The policy id to get jobs for.
     * @param    {[Object]}  params     Extra optional params for the request.
     * @return   {Object}    promise to resolve request, on success provides
     *                       array of jobs as returned from
     *                       JobRunsService.getJobRuns with transformations for
     *                       easy UI display.
     */
    function getPolicyJobs(policyId, params) {
      params = params || {};
      params = TenantService.extendWithTenantParams(_.assign({
        policyIds: [policyId],
        excludeTasks: true,
        isDeleted: false,
        includeJobsWithoutRun: true,

        // NOTE: This flag reduces the load on magneto by allowing it to only
        // look up the most recent run for the related Job's. Without, magneto
        // provides some statistically values (averages about the runs) for ALL
        // runs for the relevant Jobs.
        onlyReturnBasicSummary: true,
      }, params), params.tenantIds);

      return getJobSummary(params).then(
        function getJobRunsSuccess(response) {

          var jobs = [];

          if (Array.isArray(response.data)) {
            jobs = response.data.map(transformPolicyJob);
          }

          return jobs;

        }
      );
    }

    /**
     * Gets the Job history.
     *
     * @method     getJobHistory
     * @param      {string}  id      The job ID
     * @return     {object}  Promise to resolve with the server's response.
     */
    function getJobHistory(id) {
      return $http({
        method: 'get',
        url: API.private('backupjobshistory', id),
      });
    }

    /**
     * Gets the Job audit.
     *
     * @method     getJobAudit
     * @param      {string}  id      The Job id
     * @return     {object}  Promise to resolve with the server's response.
     */
    function getJobAudit(id) {
      return $http({
        method: 'get',
        url: API.private('backupjobaudit', id),
      });
    }

    /**
     * Cancel a Job Run
     *
     * @method     cancelJobRun
     * @param      {string}   id      The Job id
     * @param      {object=}  data    Optional payload
     * @return     {object}   Promise to resolve with the server's response.
     */
    function cancelJobRun(id, data) {
      return $http({
        method: 'post',
        url: API.public('protectionRuns/cancel', id),
        data: data
      });
    }

    /**
     * Gets the configuration object for a given Job Action
     *
     * @param      {String}    action    the job action, must match one of the
     *                                   below
     * @param      {Object}    job       as returned by backupjobsummary or
     *                                   backupjobruns
     *
     * @return     {Object}    job action configuration
     */
    function getJobRunAction(action, job, backupRun) {
      switch (action) {
        case 'edit':
          return {
            translateKey: 'editRun',
            icon: 'icn-edit',
            action: function editJobRunWrapper() {
              return editRun(job, backupRun);
            },
          };
      }
    }

    /**
     * Edit Run Action
     * returns a standard modal window with job run editing options
     *
     * @param      {Object}   job   Job object
     * @param      {Object}   jobRun   Job run object
     * @return     {Array} Contextual Menu Configuration Objects
     */
    function editRun(job, backupRun) {
      var modalConfig = {
        size: 'lg',
        templateUrl: 'app/protection/jobs/modals/job-edit-run.html',
        controller: 'JobEditRunController',
        resolve: {
          job: function() {
            return job;
          },
          run: function() {
            return backupRun;
          },
        },
      };

      var windowOptions = {
        actionButtonKey: false,
        closeButtonKey: false,
        titleKey: 'editRunModal.header',
        titleKeyContext: {
          jobName: job.name,
        }
      };

      return cModal.standardModal(modalConfig, windowOptions)
        .then(function editRunSuccess() {
          if (!FEATURE_FLAGS.ngProtectionGroup) {
            $state.go('job-details.job', $state.params, {reload: true});
          }
        });
    }

    /**
     * Get Copy Task Status returns an array of status object for copy tasks
     * associated with most recent copy run of this job
     *
     * TODO: Try to reduce the @params on this by inferring their values from
     * the run @param.
     *
     * @method    getCopyTasks
     * @param     {Object}    run           job.backupJobSummary.lastProtectionRun
     * @param     {Object}    jobUid        jobUid of JobDescription
     * @param     {Boolean}   isJobActive   indicates if the Job is active
     * @returns   {Array}     The list of copy tasks.
     */
    function getCopyTasks(run, jobUid, isJobActive) {
      var copyTasks = [];
      var allTasks;
      var tmpTask;

      if (!run || !run.copyRun) {
        // No Run defined, or Run has no Copy Tasks.
        return copyTasks;
      }

      allTasks = [].concat(
        // Run has Active Tasks
        run.copyRun.activeTasks || [],
        // Run has Finished Tasks
        run.copyRun.finishedTasks || []
      );

      // Let's dig a little deeper, shall we? Loop though all tasks
      allTasks.forEach(
        function buildTaskStatusObject(task) {
          tmpTask = angular.copy(task);
          tmpTask.taskType = getCopyTaskType(task, jobUid);
          tmpTask.classNameSuffix = getTaskStatusClassName(task);
          tmpTask.tooltip = buildTooltip(task);
          tmpTask.run = run;
          tmpTask.isJobActive = isJobActive;
          tmpTask.isTaskRemote = isTaskRemote(task, jobUid);

          if (angular.isDefined(tmpTask.taskType)) {
            // add task object to copyTasks array
            copyTasks.unshift(tmpTask);
          }
        }
      );

      return copyTasks;
    }

    /**
     * Determines if the job run's copyRun is completed.
     *
     * @method    isCopyRunFinished
     * @param     {object}   [backupRun]   Optional backupRun object.
     *                                     Default = the one on $scope.
     * @returns   {boolean|undefined}      True if it's completed, false if it's
     *                                     not, undefined if no copyRun found.
     */
    function isCopyRunFinished(backupRun) {
      // Based on ENUM_COPY_TASK_STATUS
      return backupRun && backupRun.copyRun &&
        [3, 4].includes(backupRun.copyRun.status);
    }

    /**
     * Returns a boolean based of the task.status value. We want to check if
     * the task is still in an active state.
     *
     * CopyBackupRunTaskStateProto_kStarted = 0
     * CopyBackupRunTaskStateProto_kAssigned = 1
     * CopyBackupRunTaskStateProto_kAccepted = 2
     * CopyBackupRunTaskStateProto_kFinished = 3
     * CopyBackupRunTaskStateProto_kCancelled = 4
     * CopyBackupRunTaskStateProto_kScheduled = 5
     * CopyBackupRunTaskStateProto_kFinishing = 6
     *
     * @method     isCopyTaskRunning
     * @param  {object}  task task object details
     * @return {Boolean}      is the task in a running state
     */
    function isCopyTaskRunning(task) {
      // Based on ENUM_COPY_TASK_STATUS
      return [0, 1, 2, 5, 6].includes(task.status) &&
        // if a cancelation was requested but status is not yet canceled (4),
        // task should still be treated as canceled / not running
        !task.cancellationRequested;
    }

    /**
     * Determines if any of the replication tasks are active.
     *
     * NOTE: May also work for any kind of run task, not just replication. If
     * so, reword this @jsdoc block to reflect that.
     *
     * @method    hasActiveTasks
     * @param     {array}     tasks   List of replication tasks
     * @returns   {boolean|false}   True if any active tasks are found
     */
    function hasActiveTasks(tasks) {
      return Array.isArray(tasks) &&
        tasks.some(function anyActiveRxTasks(task) {
          return ![3, 4].includes(task.status);
        });
    }

    /**
     * Determines if all given tasks are in error state.
     *
     * @method    everyTaskHasErrors
     * @param     {array}   tasks   List of backup run tasks.
     * @returns   {boolean}   True if all tasks in error state.
     */
    function everyTaskHasErrors(tasks) {
      return Array.isArray(tasks) &&
        tasks.every(function eachTask(task) {
          return task.error;
        });
    }

    /**
     * Returns a string corrosponding to a copy task type
     *
     * @method getCopyTaskType
     * @param  {Object} task Object
     * @param  {Object} jobUid - jobUid of JobDescription
     * @return {String} 'tape', 'cloud', 'remote'
     */
    function getCopyTaskType(task, jobUid) {
      var isRemote = isTaskRemote(task, jobUid);
      // TODO(spencer): Replace this with a switch() block.
      // evaluate whether or not a given copy tasks are scheduled/present

      if (!task.snapshotTarget) {
        return undefined;
      }

      switch (task.snapshotTarget.type) {
        case 1:
          if (isRemote) {
            return 'remote';
          }
          break;

        case 2:
        case 5:
          return 'remote';

        case 3:
          // Check if this task has an archival task
          // SnapshotTarget_Type should === 3
          if (task.snapshotTarget.archivalTarget &&
            task.snapshotTarget.archivalTarget.type === 1) {
            // Check if archivalTarget.type is set to 1 (Tape)
            return 'tape';
          }
          return 'cloud';

        case 4:
          return 'cloud-vm';
      }
    }

    /**
     * Returns whether the copy task is on rx cluster (i.e. remote cluster).
     *
     * @method isTaskRemote
     * @param  {Object} task Object
     * @param  {Object} jobUid - jobUid of JobDescription (and not of Job Run).
     * @return {bool}
     */
    function isTaskRemote(task, jobUid) {
      return task.jobUid.clusterId !== jobUid.clusterId;
    }

    /**
     * Returns a string corrosponding to a copy task status class name
     * to be used in conjunction with taskType to form a full class
     * TODO: update this function to return the entire class name
     *
     * @method getTaskStatusClassName
     * @param  {Object} task Object
     * @return {String} Class name suffix
     */
    function getTaskStatusClassName(task) {
      // if response has error object, return error icon
      // no matter what the state is
      if (task.error) {
        return 'error';
      }

      // cancelation was requested but is not yet completed
      if (task.cancellationRequested && task.status !== 4) {
        return 'canceled';
      }

      // snapshot is deleted and task status is success
      if (task.expiryTimeUsecs === 0 && task.status === 3) {
        return 'canceled';
      }

      switch (task.status) {
        case 3:
          return 'success';

        case 4:
          return 'canceled';

        default:
          // Task is scheduled, started, or running
          // In all cases we display task status in the UI as running
          return 'running';
      }

    }

    /**
     * Get Tooltip for a given job
     *
     * @method buildTooltip
     * @param  {Object}   job   Job object
     * @return {String}         The tooltip display string
     */
    function buildTooltip(task) {
      var subtasks;

      if (!task.snapshotTarget) {
        return '';
      }

      // Snapshot is deleted and task status is success
      if (task.expiryTimeUsecs === 0 && task.status === 3) {
        return $translate.instant('cloudArchivedSnapshotDeleted');
      }

      switch (task.snapshotTarget.type) {
        case 1:
         // Consider for rx cluster. For rx cluster, snapshot target will be
         // local but subtasks will have replicationInfo.
         subtasks = task.finishedCopySubTasks || task.activeCopySubTasks;
         if (subtasks && subtasks.length && subtasks[0].replicationInfo) {
            return $translate.instant('replicationFromClusterName', {
              clusterName: subtasks[0].replicationInfo.remoteClusterName,
            });
         }
         break;

        case 2:
          return $translate.instant(
            'replicationToClusterName', task.snapshotTarget.replicationTarget);

        case 3:
          return $translate.instant('archiveToTargetName', {
            targetName: ExternalTargetService.targetNameMapById[
              task.snapshotTarget.archivalTarget.vaultId],
          });

        case 4:
          return $translate.instant('cloudDeployToDisplayName',
            task.snapshotTarget.cloudDeployTarget.targetEntity);

        case 5:
          return $translate.instant('replicationToClusterName', {
            clusterName:
              task.snapshotTarget.cloudDeployTarget.targetEntity.displayName,
          });
      }

      return '';
    }

    /**
     * Returns a hash of the job runs and their history
     *
     * @method     getJobsHash
     * @return     {object}   $q promise with the modified response
     */
    function getJobsHash() {
      return service
        .getJobRuns()
        .then(function jobsSuccess(response) {
          return createJobRunsHash(response.data);
        });
    }

    /**
     * Format Job Run data to be consumed by Job Run Details page
     *
     * @method processJobRun
     * @param  {Object} jobRun - Original Job Run
     * @param  {Function} terminateFunction - Function that will terminate a poll
     * @return {Object} jobRun - Formatted Job Run
     */
    function processJobRun(jobRun, terminateFunction) {
      jobRun = processActiveAndFinishedTasks(jobRun);

      // Set Job Level Error Message if applicable
      // If this backup run was received via an incoming replication from another
      // cluster, and the replication failed with an error, this field will contain
      // the details of the replication error.
      if (jobRun.backupRun.rxReplicationError) {
        jobRun.backupRun._errorMsg = jobRun.backupRun.rxReplicationError.errorMsg;
      }

      // Set Worm Retention type if applicable
      jobRun.backupRun._wormLocked =
        (_.get(jobRun.backupRun, "wormRetention.policyType") ===
         'kCompliance');

      // This determines if a the tasks can be deleted or not
      jobRun.allTasks.forEach(function loopOverTasks(task) {
        task._isDeleteDisabled = _isDeleteObjectsDisabled(task, jobRun);
      });

      return jobRun;
    }

    /**
     * Builds an object mapping all objects (sources) to attempts
     *
     * @method getJobRunObjectMap
     * @param  {Object} jobRun - Original Job Run
     * @return {Object} jobRunMap - {sourceId: [attempts]}
     */
    function getJobRunObjectMap(jobRun) {
      var jobRunMap = {};
      var attemptTasks = [];

      /**
       * Maps a copy attempt task to an object in the JobRunMap map
       * @param  {Object} attemptTask - Copy Task object
       */
      function updateJobRunMap(attemptTask) {
        var sourceId = attemptTask.base.sources[0].source.id;
        attemptTask._name = SourceService.getEntityName(attemptTask.base.sources[0].source);
        attemptTask._errorMsg = attemptTask.base.error ?
          attemptTask.base.error.errorMsg : undefined;

        // Because backup run attempts can be canceled before replication begins,
        // each copy task may not appear in activeCopySubTasks, nor in finishedCopySubTasks
        // We will infer the task status based on the backupRunObjectProtectionStatus
        // Set each run attempt status to a sensible default,
        // This will be displayed if there is no matching backupTaskId
        switch (attemptTask.base.status) {
          case 3:
            // The attempt was canceled
            // set default task status to  5 (Canceled);
            attemptTask._status = 5;
            break;
        }

        // Regrardless of what we set above,
        // if there is an error, make sure this attempt is marked as finished (4)
        if (attemptTask.base.error) {
          attemptTask._status = 4;
        }

        if (!jobRunMap[sourceId]) {
          jobRunMap[sourceId] = [];
        }
        jobRunMap[sourceId].push(attemptTask);
      }

      if (jobRun.backupRun.activeAttempt) {
        if (jobRun.backupRun.activeAttempt.finishedTasks) {
          attemptTasks = jobRun.backupRun.activeAttempt.finishedTasks.concat(attemptTasks);
        }
        if (jobRun.backupRun.activeAttempt.activeTasks) {
          attemptTasks = jobRun.backupRun.activeAttempt.activeTasks.concat(attemptTasks);
        }
      }

      if (jobRun.backupRun.finishedAttempts) {
        jobRun.backupRun.finishedAttempts.forEach(
          function parseFinishedAttempt(finishedAttempt) {
            if (finishedAttempt.finishedTasks) {
              attemptTasks = finishedAttempt.finishedTasks.concat(attemptTasks);
            }
          }
        );
      }

      attemptTasks.forEach(updateJobRunMap);

      return jobRunMap;
    }

    /**
     * Aggregates Job Run Attempt data
     * to display Copy Task totals when multiple attempts are detected
     *
     * @method aggregateAttemptData
     * @param  {Array}   jobRunMap     Original jobRunMap
     * @param  {Object}  copyRunTask   CopyRunTask used for determining attempt
     *                                 status for cancelled tasks
     * @return {Array}                 Modified jobRunMap including aggregate
     *                                 Attempt task if applicable
     */
    function aggregateAttemptData(jobRunMap, copyRunTask) {
      var aggregateTask;
      var text = $rootScope.text.servicesJobRunsService;

      Object.keys(jobRunMap).forEach(
        function aggregateAttempt(key) {
          var i = 0;
          var len = jobRunMap[key].length;

          // if copyRunTask.status is canceled (4),
          // overwrite jobRunMap[key][i]._status as canceled (5)
          if (copyRunTask.status === 4) {
            jobRunMap[key][i]._status = 5;
          }

          // Set default values for aggregateTask
          aggregateTask = {
            // Flag used to differentiate aggregate task from system task in the UI
            _isAggregateTask: true,
            // Aggregate bytesTransferred will increment this value with each attempt
            _bytesTransferred: 0,
            // Aggregate name will never change,
            // just grab it from the first attempt instance
            _name: jobRunMap[key][0]._name,
            // Aggregate StartTimeUsecs,
            // will assume the first attempt was the earliest
            _startTimeUsecs: jobRunMap[key][0]._startTimeUsecs,
            // Aggregate Status
            // If copyRunTask Status is canceled (4),
            // then we'll set the default aggregate task status to canceled (5)
            // Otherwise we'll assume that the default aggregate task status value is pending (2)
            _status: copyRunTask.status === 4 ? 5 : 2,
            // Aggregate duration should match that of the last attempt
            _duration: jobRunMap[key][len - 1]._duration,
            // Used for toggling display in the UI
            taskId: jobRunMap[key][0].taskId
          };

          // If more than one attempt is found,
          // Loop though attempts to form an aggregate attempt
          if (len > 1) {
            for (i; i < len; i++) {

              // Aggregate bytesTransferred if it is defined
              if (angular.isDefined(jobRunMap[key][i]._bytesTransferred)) {
                aggregateTask._bytesTransferred = aggregateTask._bytesTransferred + jobRunMap[key][i]._bytesTransferred;
              }

              // Custom Aggregate Task Status Mapping
              // If aggregateTask status matches the default status 2 or 5, or finished status 4
              // then evaluate the attempt status to override if need be.
              if ([2, 4, 5].includes(aggregateTask._status)) {
                switch (jobRunMap[key][i]._status) {
                  case 0:
                    // Aggregate Task is Running
                    aggregateTask._status = 0;
                    break;
                  case 4:
                    // Aggregate Task is Finished
                    aggregateTask._status = 4;
                    // Check for error message
                    if (jobRunMap[key][i]._errorMsg) {
                      aggregateTask._errorMsg = text.partialSuccessMessage;
                    }
                    break;
                  case 5:
                    // Aggregate Task is Cancelled
                    aggregateTask._status = 5;
                    break;
                  default:
                    // Aggregate Task is Pending
                    // return as default
                    aggregateTask._status = 2;
                }
              }
            }
            // Add aggregateTask to jobRunMap
            jobRunMap[key].push(aggregateTask);
          }
        }
      );

      return jobRunMap;
    }

    /**
     * Creates a hash of jobRuns.
     *
     * @method   createJobRunsHash
     * @param    {array}  runs  List of runs from a job
     * @returns  {object}       The hash
     */
    function createJobRunsHash(runs) {
      var out = {};
      if (!runs) {
        return out;
      }
      runs = [].concat(runs);
      runs.forEach(function eachJobRun(job) {
        job = job.backupJobRuns;
        out[job.jobDescription.jobId] = job;
      });
      return out;
    }

    /**
     * Specialized object copier to generate a subset of a vmDocument object
     * with only needed keys. angular.copy() was destroying performance.
     *
     * @method     lightCopyVmDocument
     * @param      {Entity}  vmdoc   EntityProto.vmDocument to copy
     * @return     {Object}  Reduced vmDocument object
     */
    function lightCopyVmDocument(vmdoc) {
      var out = {};
      var skipKeys = ['versions', 'registeredSource', 'objectAliases'];
      angular.forEach(vmdoc, function copierFn(val, key) {
        if (!skipKeys.includes(key)) {
          out[key] = angular.copy(val);
        }
      });
      return out;
    }

    /**
     * transforms a job run as provided by JobRunsService.getJobRuns and
     * transforms it for easier UI display
     *
     * @method transformPolicyJob
     * @param  {Object} job   Job as returned in array from JobRunsService.getJobRuns
     * @return {Object}       Transformed job for easy UI display
     */
    function transformPolicyJob(job) {
      // remove the unnecessary root property "backupJobRuns"
      job = job.backupJobSummary;
      job.lastProtectionRun = job.lastProtectionRun || {};

      // add timezone offset string
      job._timezoneOffset = cUtils.getTimezoneOffset();

      return job;

    }

    /**
     * Decorates a given jobRun task with some common properties.
     *
     * @method   transformTask
     * @param    {object}   task   The task object to transform
     * @return   {object}          The transformed task object
     */
    function transformTask(task) {
      var taskBase = task.base;
      return angular.extend(task, {
        _sourceObject: taskBase.sources[0].source,
        _normalizedEntity: SourceService.normalizeEntity(taskBase.sources[0].source),
        _uid: SourceService.normalizeEntity(taskBase.sources[0].source).uid,
        _error: taskBase.error && taskBase.error.errorMsg,
        _status: JobService.getStatus(task),
        _statusText: ENUM_BACKUP_JOB_STATUS[JobService.getStatus(task)],
        _read: taskBase.totalBytesReadFromSource || 0,
        // Use admittedTimeUsecs instead of startTimeUsecs for task start time.
        // admittedTimeUsecs is the task start time and startTimeUsecs is the job start time.
        _startDate: taskBase.admittedTimeUsecs || 0,
        _startDateUsecs: taskBase.admittedTimeUsecs || 0,
        _userMessage: taskBase.userMessage,
        _progress: {},
        _attempts: []
      });
    }

    /**
     * Decorates a given app entity with some common properties
     *
     * @method   generateAppEntities
     * @param    {Object}   appEntityObj   The appEntity object
     * @return   {Object}   the transformed App Entity Object
     */
    function generateAppEntities(appEntityObj) {
      var appEntity = appEntityObj.appEntity;
      var displayName;
      var dbEntity;
      var dbType;

      // Append future application's properties here here.
      if (appEntity.sqlEntity) {
        // TODO(maulik): The displayName should be database name once we add
        // eye popup to this screen
        displayName = appEntity.displayName;
        dbEntity = appEntityObj.appEntity.sqlEntity;
        dbType = 'sql';
      } else if (appEntity.oracleEntity) {
        // Since displayName is actually set within Magneto which corresponds
        // to the field - 'oracleEntity.dbEntity.dbEntityInfo.dbUniqueName', it
        // is safe to be used here.
        displayName = appEntity.displayName || appEntity.oracleEntity.name;
        dbEntity = appEntityObj.appEntity.oracleEntity;
        dbType = 'oracle';
      }

      return {
        appId: appEntity.id,
        publicStatus: appEntityObj.publicStatus,
        statusText: ENUM_PUBLIC_STATUS[appEntityObj.publicStatus],
        displayName: displayName,
        dbEntity: dbEntity,
        dbType: dbType,
        startTimeUsecs: appEntityObj.startTimeUsecs,
        endTimeUsecs: appEntityObj.endTimeUsecs,
        totalLogicalSizeInBytes: appEntityObj.totalLogicalBytes,
        totalBytesReadFromSource: appEntityObj.totalBytesReadFromSource,
        error: appEntityObj.error,
        warnings: appEntityObj.warnings,
        progressMonitorTaskPath: appEntityObj.progressMonitorTaskPath,
      };
    }

    /**
     * Decorates the run task with appEntities
     *
     * @method   decorateTaskWithAppEntities
     * @param    {Object}   task   The run task
     */
    function decorateTaskWithAppEntities(task) {
      task._appEntities = [];
      if (task.appEntityStateVec) {
        Array.prototype.push.apply(
          task._appEntities,
          task.appEntityStateVec.map(generateAppEntities)
        );
      }
    }

    /**
     * Parse through latestFinishedTasks and activeAttempts set up progress
     * monitor data if need be
     * TODO(Tauseef) - Revisit this logic to populate the previous attempts only
     * when they are unsuccessful.
     *
     * @method   processActiveAndFinishedTasks
     * @param    {Object}   jobRun   Original Job Run.
     * @return   {Object}            Formatted Job Run.
     */
    function processActiveAndFinishedTasks(jobRun) {
      var activeAttemptTasks = [];
      var allAttemptTasks = [];

      // Specifies the list of entities within a job having failed attempts.
      jobRun._failedTaskAppIDs = [];
      // jobRun.allTasks: tasks for each object/source in the job
      // allAttemptTasks: all attempt tasks per object, flattened

      // Let's build our data set of run tasks for the GUI
      // If there is an active Attempt, let's use that as the reference
      // for the most recent data
      if (jobRun.backupRun.activeAttempt) {
        // Then check for activeTasks and add to allTasks
        Array.prototype.push.apply(
          activeAttemptTasks,
          jobRun.backupRun.activeAttempt.activeTasks || []
        );

        jobRun.allTasks = activeAttemptTasks.map(transformTask);

        // If this job has an active attempt, let's add its tasks
        // into allAttemptTasks for evaluation later below.
        Array.prototype.push.apply(
          allAttemptTasks,
          (jobRun.backupRun.activeAttempt.activeTasks || []).map(transformTask)
        );

        if (_.get(jobRun, 'backupRun.activeAttempt.activeTasks')) {
          jobRun.backupRun.activeAttempt.activeTasks
            .forEach(decorateTaskWithAppEntities);
        }

        Array.prototype.push.apply(
          allAttemptTasks,
          (jobRun.backupRun.activeAttempt.finishedTasks || []).map(transformTask)
        );

        if (_.get(jobRun, 'backupRun.activeAttempt.finishedTasks')) {
          jobRun.backupRun.activeAttempt.finishedTasks
            .forEach(decorateTaskWithAppEntities);
        }
      }

      // Then work with Latest Finished Tasks
      if (_.get(jobRun, 'backupRun.latestFinishedTasks')) {
        // we can safely assume latestFinishedTasks to be the most
        // recent data so let's loop through latestFinishedTasks and
        // add them to jobRun.allTasks
        Array.prototype.push.apply(
          jobRun.allTasks,
          jobRun.backupRun.latestFinishedTasks.map(transformTask)
        );

        jobRun.backupRun.latestFinishedTasks
          .forEach(decorateTaskWithAppEntities);
      }

      // If ths job has finishedAttempts, add finishedAttempts.finishedTasks
      // to allAttemptTasks.
      (jobRun.backupRun.finishedAttempts || []).forEach(
        function getTasksFromAttempts(attempt) {
          Array.prototype.push.apply(
            allAttemptTasks,
            (attempt.finishedTasks || []).map(transformTask)
          );
        }
      );

      // Next, loop through allAttemptTasks, comparing each to jobRun.allTasks.
      allAttemptTasks.forEach(function taskToJobRunAllTasks(attemptTask) {
        jobRun.allTasks.some(function eachRunTask(task) {
          // If the sourceObjects match, but their task ID is different,
          // push the attempt to the _attempts array for that task.
          if (SourceService.isSameEntity(
              attemptTask._sourceObject,
              task._sourceObject) &&
            attemptTask.taskId !== task.taskId) {
              // Check for sub tasks within the parent backup task.
              if((_.get(attemptTask, 'appEntityStateVec') || []).length) {
                // Populate failed ids to the shared data list.
                attemptTask.appEntityStateVec.forEach(
                  function populateFailedTaskAppId(appEntityState) {
                    if (appEntityState.publicStatus === 'kFailure') {
                      jobRun._failedTaskAppIDs
                        .push(appEntityState.appEntity.id);
                    }
                  }
                );
              }
              task._attempts.push(attemptTask);
              decorateTaskWithAppEntities(attemptTask);
              return true;
          }
        });
      });

      jobRun._progressMonitorTaskPath = getTaskPath(jobRun);

      return jobRun;
    }

    /**
     * Extract task monitoring data for use with pulse.
     *
     * @method getTaskPath
     * @param  {Object}  jobRun
     * @return {Object}  taskConfig
     */
    function getTaskPath(jobRun) {
      var url;
      var attempts = [];
      if (jobRun.backupRun.activeAttempt &&
        jobRun.backupRun.activeAttempt.base.progressMonitorTaskPath) {
        attempts.push(jobRun.backupRun.activeAttempt);
      }

      if (jobRun.backupRun.finishedAttempts) {
        jobRun.backupRun.finishedAttempts.forEach(
          function(attempt) {
            if (attempt.base.progressMonitorTaskPath) {
              attempts.push(attempt);
            }
          }
        );
      }

      // Set up the progress monitor task path
      if (attempts && attempts.length) {
        // If there are finished attempts,
        // let's build a request for all previous attempt data
        // as well as the current active attempt
        url = [
          'progressMonitors?',
          getAttemptParams(attempts),
          'includeFinishedTasks=true&excludeSubTasks=false'
        ].join('');
      }

      return url;
    }

    /**
     * Return the individual attempt params of a job for use with progress
     * monitor.
     *
     * @method getAttemptParams
     * @param  {Array} attempts
     * @return {String}   String representation of each attempt as URL params
     */
    function getAttemptParams(attempts) {
      var len = attempts.length;
      var a = 0;
      var attemptString = [];
      for (a; a < len; a++) {
        if (attempts[a].base && attempts[a].base.attemptNum &&
         attempts[a].base.attemptNum !== (len + 1) &&
         attempts[a].base.progressMonitorTaskPath) {
          attemptString.push(
           'taskPathVec=',
           attempts[a].base.progressMonitorTaskPath,
           '&'
         );
        }
      }
      return attemptString.join('');
    }

    /**
     * Gets a job run's snapshot targets (Archival Targets).
     * Excludes expired snapshot.
     *
     * TODO: This is very similar to `getCopyTasks` in this file.
     * Consider combining them.
     *
     * @method     getJobRunSnapshotTargets
     * @param      {object}          run          The run object.
     * @param      {integer|array=}  ignoreTypes  One or more of target
     *                                            types to ignore. Default:
     *                                            2.
     * @return     {array}           The filtered & sorted job run snapshot
     *                               targets.
     */
    function getJobRunSnapshotTargets(run, ignoreTypes) {
      var nowUsecs = Date.clusterNow() * 1000;
      var tasks = [];
      // This defaults to 2 (kReplica)
      ignoreTypes = [].concat(ignoreTypes || 2);

      if (run && run.copyRun) {
        // Create a combined list. If both evaluate as [], the combined list
        // is []
        tasks = [].concat(run.copyRun.activeTasks || [],
                run.copyRun.finishedTasks || []);

        // Reduce (filter + sort) that combined list
        tasks = tasks.reduce(function reduceTargetsFn(_targets, task, ii) {
          var target = {
            target: angular.copy(task.snapshotTarget)
          };
          var hh = ii - 1;

          // Filter: Skip if the type is in the ignored types list
          // or the snapshot is expired
          if (!ignoreTypes.includes(task.snapshotTarget.type) &&
            task.expiryTimeUsecs > nowUsecs) {
              // Sort: Quick sort these by type in ascending order
              if (_targets[hh] &&
                (task.snapshotTarget.type < _targets[hh].target.type)) {
                  // Insert before
                  _targets.splice(hh, 0, target);
              } else {
                // Insert after
                _targets.splice(ii, 0, target);
              }
          }
          return _targets;
        }, []);
      }

      return tasks;
    }

    /**
     * Determines if snapshot marked for deletion.
     *
     * @method   isSnapshotMarkedForDeletion
     * @param    {object}    copyRun    The copy run
     * @param    {integer}   sourceId   The source id for the task
     * @return   {boolean}   True if snapshot marked for deletion, False
     *                       otherwise.
     */
    function isSnapshotMarkedForDeletion(copyRun, sourceId) {

      var localTask = copyRun.finishedTasks.find(
        function getLocalTask(copyTask) {
          return copyTask.snapshotTarget && copyTask.snapshotTarget.type === 1;
        });

      if (!localTask || !localTask.entityExpiryTimeUsecsMap) {
        return false;
      }

      return localTask.entityExpiryTimeUsecsMap.some(function findTask(task) {
        return task.key === sourceId && task.value === 0;
      });
    }

    /**
     * Presents a confirmation modal before deleting objects in job run
     *
     * @param    {Array}    objectsToDelete    List of all objects to be deleted
     * @param    {Array}    archivalTargets    List of all archival targets
     * @param    {Array}    replicateTargets   List of all replication targets
     * @return   {object}   promise successfully resolved when objects are
     *                      deleted else rejected when modal dissmissed.
     */
    function getDeleteObjectsModal(objectsToDelete, archiveTargets,
      replicateTargets) {

      var modalConfig = {
        templateUrl: 'app/protection/jobs/details/run/delete-objects.html',
        controller: deleteObjectsModalController,
        resolve: {
          objectNames: function() {
            return objectsToDelete;
          },
          archivalTargets: function() {
            return archiveTargets;
          },
          replicationTargets: function() {
            return replicateTargets;
          },
        },
      };
      var options = {
        titleKey: 'jobActionService.deleteObjectsModal.title',
        closeButtonKey: 'cancel',
        actionButtonKey: 'delete',
      };

      // Return a confirmation modal
      return cModal.standardModal(modalConfig, options);
    }

    /**
     * Controller function for deleteObject Modal
     *
     * @method   deleteObjectsModalController
     * @param    {object}   $uibModalInstance    The uib modal instance
     * @param    {Array}    objectNames          List of object names
     * @param    {Array}    archivalTargets      The list of archival targets
     * @param    {Array}    replicationTargets   The list of replication targets
     */
     /* @ngInject */
    function deleteObjectsModalController($uibModalInstance, objectNames,
      archivalTargets, replicationTargets) {
      var $ctrl = this;
      $ctrl.archivalTargets = archivalTargets;
      $ctrl.replicationTargets = replicationTargets;
      $ctrl.objectNames = objectNames;
      $ctrl.allowArchiveRecovery = true;

      $ctrl.ok = function modalConfirmDeleteJob() {
        $uibModalInstance.close($ctrl.allowArchiveRecovery);
      };

    }

    /**
     * Evaluates Job object to determine if a given Job Action is valid
     *
     * @param    {String}    action      the job action, must match one of the
     *                                   below
     * @param    {Object}    backupRun   Protection Job run
     * @param    {Object}    job         Protection Job object
     * @param    {Object}    copyRun     Protection Job copy run object
     *
     * @return   {Boolean}   is valid action?
     */
    function isValidJobRunAction(action, backupRun, job, copyRun) {
      var isReplicatedJob = !!_.get(job, 'remoteJobUids');
      var rxTask = !!copyRun &&
        _.find(copyRun.finishedTasks, function findReplicationTask(task) {
          return task.snapshotTarget.type === SNAPSHOT_TARGET_TYPE.kLocal;
        });
      var remoteReplicationSuccessful = !!rxTask && !rxTask.error;
      var doesRunHaveRemoteTask = backupRun._copyTasks.some(task => (
        !task.error && task.snapshotTarget.type !== SNAPSHOT_TARGET_TYPE.kLocal
      ));

      switch (action) {
        case 'edit':
          return FEATURE_FLAGS.editRunEnabled &&
            $rootScope.user.privs.PROTECTION_MODIFY &&
            backupRun.base &&

            // Disable edit run for file stubbing job,
            // because mock up is not ready.
            // TODO (Lucy): Will remove it and add edit data migration run modal
            // when mock is ready.
            !job._isDataMigrationJob && (

              // If a local snapshot is not availble but an archived or replicated
              // snapshot is, still allow the run to be edited so the user is
              // able to delete the remote snapshots (if any) using the edit
              // modal, otherwise the remote snapshots become inaccessible.
              // The relevant controls are disabled in the edit modal if local
              // snapshot is not available.
              doesRunHaveRemoteTask || (

                // Allow edit run for partially successful backups
                !!(backupRun.numSuccessfulAppObjects ||
                backupRun.numSuccessfulTasks) &&

                // To check if run has been deleted. This condition is helpful when
                // a job is deleted with snapshots on replication site and the job
                // is again brought back by doing replication again. In such cases
                // the expiry time is not 0 but the snapshotsDeleted is set to true.
                !backupRun.snapshotsDeleted &&

                // Below condition handles both local snapshots that are expired or
                // deleted by the user.
                backupRun.base._expiryTimeUsecs >
                  DateTimeService.getCurrentUsecs()
              )
            ) &&

            // If its a replicated job and replication has failed, edit should
            // be disabled
            (!isReplicatedJob || remoteReplicationSuccessful);
        default:
          // unknown action
          return false;

      }

    }

    /**
     * Updates the disabled targets list.
     *
     * @method   removeTargetFromDisabledList
     * @param    {Array}    disabledList      The disabled list of targets
     * @param    {string}   type              The type: 'replicate', or
     *                                        'archive'.
     * @param    {object}   removedTarget   The target config to remove.
     */
    function removeTargetFromDisabledList(disabledList, type, removedTarget) {
      if (!removedTarget.target) {
        return;
      }

      // Getting target id dynamically depending on the target type
      var removedTargetId = (type === 'archive') ?
        removedTarget.target.id :
        removedTarget.target.clusterId;

      // Loop over the disabled target list until matching target id found
      // and remove it from disabled
      disabledList.some(function removeGivenTargetId(targetId, index) {
        if (removedTargetId === targetId) {
          disabledList.splice(index, 1);
        }
      });
    }

    /**
    * Determines if delete objects checkboxes should be disabled or not.
    *
    * @method   _isDeleteObjectsDisabled
    * @param    {object}    task     The task object
    * @param    {object}    jobRun   The job run
    * @return   {boolean}   True if delete objects is not allowed, False
    *                       otherwise.
    */
    function _isDeleteObjectsDisabled(task, jobRun) {
      return jobRun.backupRun._wormLocked || task.snapshotDeleted ||
        task._snapshotMarkedForDeletion || !jobRun._hasLocalSnapshot;
    }

    /**
     * Gets the replication targets from the copy run.
     *
     * @method   getReplicationTargets
     * @param    {object}   copyRun   The copy run
     * @return   {array}   The replication targets.
     */
    function getReplicationTargets(copyRun, omitExpiredSnapshots = false) {
      const clusterNowUsecs = Date.clusterNow() * 1000;

      return copyRun.finishedTasks.reduce(function getTargets(targets, task) {
        if (
          task.snapshotTarget.type === 2 &&
          task.publicStatus === 'kSuccess' &&
          (!omitExpiredSnapshots || task.expiryTimeUsecs > clusterNowUsecs)
        ) {
          targets.push(task.snapshotTarget.replicationTarget);
        }
        return targets;
      }, []);
    }

    /**
     * Gets the archival targets.
     *
     * @method   getArchivalTargets
     * @param    {object}   copyRun   The copy run
     * @return   {array}   The archival targets.
     */
    function getArchivalTargets(copyRun, omitExpiredSnapshots = false) {
      const clusterNowUsecs = Date.clusterNow() * 1000;

      return copyRun.finishedTasks.reduce(function getTargets(targets, task) {
        if (
          task.snapshotTarget.type === 3 &&
          task.publicStatus === 'kSuccess' &&
          (!omitExpiredSnapshots || task.expiryTimeUsecs > clusterNowUsecs)
        ) {
          targets.push(task.snapshotTarget.archivalTarget);
        }
        return targets;
      }, []);
    }

    /**
     * Return an icon for the current job based on its status and type
     *
     * @param   {Object}  type     The type of job
     * @param   {Object}  status   The status of job
     * @return  {String}  The icon for the current job status
     */
    function getJobStatusIcon(type, status) {
      var iconMap = ENV_GROUPS.cloudJobsWithoutLocalSnapshot
        .includes(type) ?  ENUM_BACKUP_SNAPSHOT_MANAGER_JOB_STATUS_ICON_NAME :
          ENUM_BACKUP_LOCAL_JOB_STATUS_ICON_NAME;

      return iconMap[status];
    }

    return service;

  }

})(angular);
