import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { ClusterConfig, ClusterConfigApiService } from '@cohesity/api/argus';
import { RbacModule } from '@cohesity/data-govern/shared';
import { BannerModule, BlankCardModule, DataIdModule, getDialogConfig, SpinnerModule } from '@cohesity/helix';
import { AjaxHandlerService, AutoDestroyable, DialogService } from '@cohesity/utils';
import { TranslateModule } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';

import { ClusterOrderListComponent } from '../cluster-order-list';

@Component({
  selector: 'dg-cr-view-cluster-preference-dialog',
  standalone: true,
  imports: [
    BannerModule,
    BlankCardModule,
    CommonModule,
    ClusterOrderListComponent,
    DataIdModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    RbacModule,
    SpinnerModule,
    TranslateModule,
  ],
  templateUrl: './view-cluster-preference-dialog.component.html',
  styleUrls: ['./view-cluster-preference-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewClusterPreferenceDialogComponent extends AutoDestroyable implements OnInit {
  /**
   * Indicates whether data is loading or not
   */
  isLoading = false;

  /**
   * List of ordered clusters
   */
  orderedClusterList: ClusterConfig[] = [];

  /**
   *
   * Is scan on replica enabled
   */
  scanOnReplicaEnabled = false;

  /**
   * Used to check if there are any clusters configured
   */
  get hasConfiguredClusters(): boolean {
    return this.isLoading || Boolean(this.orderedClusterList?.length);
  }

  constructor(
    private ajaxServiceHandler: AjaxHandlerService,
    private cdr: ChangeDetectorRef,
    private clusterConfigService: ClusterConfigApiService,
    private router: Router
  ) {
    super();
  }

  /**
   * Opens cluster preference list dialog box
   */
  static launchDialog(dialog: DialogService) {
    return dialog.open<ViewClusterPreferenceDialogComponent, any, any>(
      ViewClusterPreferenceDialogComponent,
      getDialogConfig('xs', {})
    );
  }

  ngOnInit() {
    this.getData();
  }

  /**
   * Loads all data
   */
  getData() {
    this.isLoading = true;
    this.cdr.detectChanges();

    this.clusterConfigService
      .getClusterConfig()
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.cdr.detectChanges();
        }),
        this.untilDestroy()
      )
      .subscribe(data => {
        const clusterConfig = data.config;
        this.scanOnReplicaEnabled = clusterConfig?.scanOnReplicaEnabled ?? false;
        this.orderedClusterList = clusterConfig?.orderedClusters ?? [];
      }, this.ajaxServiceHandler.handler);
  }

  /**
   * Redirect to cluster scanning preference
   */
  editScanOrder() {
    this.router.navigate(['cluster-scanning-preference/edit']);
  }
}
