<cog-page class="cog-takeover-theme" pageWidth="md">
  <cog-page-header>
    <cog-page-toolbar [title]="title" [tallHeader]="true" color="accent">
      <cog-page-actions>
        <button id="cancel-recover-button" type="button" mat-raised-button (click)="goToPreviousPage()">
          {{'done' | translate}}
        </button>
      </cog-page-actions>
      <cog-page-nav>
        <ng-content select="coh-recover-search-header"></ng-content>
      </cog-page-nav>
    </cog-page-toolbar>
  </cog-page-header>

  <mat-card class="page-content">
    <mat-card-content>
      <ng-content></ng-content>
    </mat-card-content>
  </mat-card>
</cog-page>
