import { AgentDeployment, Applications, Status, TargetGroupType } from '@cohesity/api/agent-deployment';
import { AgentInfoObject, AgentUpgradeTaskState, Error } from '@cohesity/api/v2';
import { TranslateService } from '@ngx-translate/core';

/**
 * The map of the task type and it's icon.
 */
const TaskEnvIconMap: Record<TargetGroupType, string> = {
  vmWare: 'helix:group-virtual-machine',
  physicalServer: 'helix:group-physical-server',
};

/**
 * Interface for agent object in the upgrade/deployment tasks.
 */
export interface AgentUpgradeInfo {
  /**
   * Specifies the name of the agent object.
   */
  name: string;

  /**
   * Specifies the status of the agent object.
   */
  status: null | AgentUpgradeTaskState['status'] | AgentInfoObject['status'] | Status;

  /**
   * Specifies the error during upgrade for an agent.
   */
  error?: Error;
}

/**
 * Decorates agent activity task list for UI presentation.
 */
export class AgentActivity {
  /**
   * Helix compatible icon of this task's environment.
   */
  readonly environmentIcon: string;

  /**
   * Time stamp for timeline display in UI.
   */
  readonly timestampSecs: number;

  /**
   * Description of each agent task for UI.
   */
  readonly description: string;

  /**
   * Specifies the name of the task.
   */
  readonly name: null | string;

  /**
   * Specifies the agent object state.
   */
  readonly objects: AgentUpgradeInfo[];

  /**
   * Specifes the type of the task.
   */
  get type(): TargetGroupType | null | 'Auto' | 'Manual' | 'Retry' {
    return this.task.type;
  }

  /**
   * Boolean value to identify the application registration
   */
  get registerApplications(): null | boolean {
    return (this.task as AgentDeployment).registerApplications;
  }

  /**
   * Boolean value to check if the task upgrade can be retried.
   */
  get isRetryable(): null | boolean {
    return (this.task as AgentUpgradeTaskState).isRetryable;
  }

  /**
   * Holds the status of the upgrade agent task.
   */
  get status(): null | AgentUpgradeTaskState['status'] | AgentInfoObject['status'] | Status {
    return this.task.status;
  }

  /**
   * Application information based on the registration
   */
  get applications(): Applications {
    return (this.task as AgentDeployment).applications;
  }

  /**
   * Holds the ID of the agent
   */
  get id(): null | string {
    return this.task.id.toString();
  }

  /**
   * Constructor
   *
   * @param task  Agent activity task obtained of AgentUpgradeTaskState or AgentDeployment type.
   * @param translate  tanslate service
   */
  constructor(private task: AgentUpgradeTaskState | AgentDeployment, private translate: TranslateService) {
    this.environmentIcon = TaskEnvIconMap[task.type];
    this.timestampSecs = this.getTimeStampSecs();
    this.description = this.getDescription();
    this.name = this.getName();
    this.objects = this.getAgentObjectList();
  }

  /**
   * Constructs and returns the agent object info based on agent type
   *
   * @returns Agent object info consisting of name and status for display in UI
   */
  getAgentObjectList(): AgentUpgradeInfo[] {
    if (this.task.type === 'physicalServer' || this.task.type === 'vmWare') {
      return (this.task as AgentDeployment).objects.map(agent => ({
        name: agent.displayName,
        status: agent.status,
      }));
    } else {
      return (this.task as AgentUpgradeTaskState).agents.map(agent => ({
        name: agent.info.name,
        status: agent.info.status,
        error: agent.info.error
      }));
    }
  }

  /**
   * Calculates and returns the start time in seconds based on the agent type
   *
   * @returns Time stamp for activity timeline based on type
   */
  getTimeStampSecs(): number {
    if (this.task.type === 'physicalServer' || this.task.type === 'vmWare') {
      return this.task.startTimeMsecs / 1000;
    } else {
      return (this.task as AgentUpgradeTaskState).startTimeUsecs
        ? (this.task as AgentUpgradeTaskState).startTimeUsecs / (1000 * 1000)
        : (this.task as AgentUpgradeTaskState).scheduleTimeUsecs / (1000 * 1000);
    }
  }

  /**
   * Constructs and returns the description of the agent task based on the object status
   *
   * @returns Description of the task based on status
   */
  getDescription(): string {
    if (this.task.type === 'physicalServer' || this.task.type === 'vmWare') {
      if (this.task.status === 'running') {
        return this.task.objects ?
          this.translate.instant('agentActivityList.description.running', {
            count: this.task.objects.length,
          }) : '';
      } else if (this.task.status === 'failed') {
        return this.translate.instant('agentActivityList.description.deployment.failed', {
          count: this.task.objects.filter(item => item.status === 'failed').length,
        });
      }
    } else {
      if (this.task.status === 'PartiallyFailed') {
        return this.translate.instant('agentActivityList.description.upgrade.failed', {
          count: this.task.agents.filter(item => item.info.status === 'Failed').length,
        });
      }
      return (this.task as AgentUpgradeTaskState).description;
    }
  }

  /**
   * Constructs, translates and returns the name of the agent task based on the agent type
   *
   * @returns Name of the task based on type
   */
  getName(): string {
    if (this.task.type === 'physicalServer' || this.task.type === 'vmWare') {
      if (this.task.registerApplications) {
        const translateKey = 'agentDeployment.jobProgress.appRegistration.title';

        if (this.task.applications.registerOracle) {
          return this.translate.instant(translateKey, { app: this.translate.instant('oracle') });
        } else if (this.task.applications.registerMsSql) {
          return this.translate.instant(translateKey, { app: this.translate.instant('msSql') });
        }
      } else {
        return this.translate.instant('agentDeployment.jobProgress.deployment.title');
      }
    } else {
      return this.translate.instant('upgrade');
    }
  }
}
