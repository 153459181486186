import { Environment } from '@cohesity/api/argus';

export type SourceTypeToIconMap = {
  [key in Environment]?: string
};

export const SourceTypeToIconMap: SourceTypeToIconMap = Object.freeze({
  kCohesity: 'argus:object-cohesity',
  kGSuite: 'helix:object-o365',
  kO365: 'helix:object-o365',
});
