// Service: Fetch

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C')
    .service('FetchService', FetchServiceFn);

  function FetchServiceFn($http, $q) {

    return {
      incrementalFetch: incrementalFetch,
    };

    ////////////////////////////////////////
    // PUBLIC METHODS
    ////////////////////////////////////////

    /**
     * Recursively calls the specified API and aggregates the result set. This
     * is to avoid protobuff errors on large sets. In most cases, this will be
     * only one request.
     *
     * This fetches `numFetchFirst` records initially, and if a pagination
     * `cookie` is returned, it includes the cookie in a new request in order to
     * incrementally fetch the next `numFetchMore` records. This continues
     * until we receive a null `cookie`.
     *
     * @method     fetchIncremental
     *
     * @param      {Object}  config  Configuration object, including API
     *                               endpoint request params.
     * @return     {Object}  Promise to resolve with an aggregated object or the
     *                       raw server response in case of error.
     *
     *
     * Example config object:
        var config = {
          // (Optional) This is an object containing the request params for the
          // API call.
          requestParams: params,

          // This is the endpoint for the API call which is to be called
          // recursively until all records are fetched.
          endpoint: API.public('viewUserQuotas'),

          // This is the property key for the array which will be aggregated.
          // This will be consumed as follows: response.data[arrayPropertyKey]
          arrayPropertyKey: 'usersQuotaAndUsage',

          // (Optional) HTTP method. Default 'get'.
          method: 'get',

          // (Optional) Initial max set of records to fetch. Default 500.
          numFetchFirst: 100,

          // (Optional) Number of records to fetch during subsequent calls.
          // Default 500.
          numFetchMore: 1000,

          // (Optional) Method with which to transform incoming data.
          // Default undefined.
          transformFn: ViewServiceFormatter.transformDirectoryQuota,
        };
     */
    function incrementalFetch(config) {
      var deferred = $q.defer();

      // @type  {Number} - Number of records to fetch in the first request
      var numFetchFirst = config.numFetchFirst || 500;

      // @type  {Number} - Number of additional records to get incrementally
      var numFetchMore = config.numFetchMore || 200;

      // @type  {String} - HTTP method
      var method = config.method || 'get';

      // @type  {Function} - HTTP method
      var transformFn = config.transformFn;

      // @type  {Object} - $http config.
      var opts = {
        method: method,
        url: config.endpoint,
        params: angular.extend({
          pageCount: numFetchFirst,
        }, config.requestParams),
      };

      // @type  {Object} - Cumulative object which will be returned after
      //                   incremental fetching is complete.
      var bucket;

      /**
       * Helper function to reject this deferred promise.
       *
       * @method   reject
       *
       * @param    {*}       [result]  Any output with which to resolve the
       *                               promise
       * @return   {Object}  Promise to reject with the result
       */
      function reject(result) {
        return deferred.reject(result || bucket);
      }

      /**
       * This is the method that does all the work.
       *
       * @method     issueIncrementalRequest
       *
       * @param      {Object}  bucket            An object containing the
       *                                         accumulator array, identified
       *                                         by the second argument.
       * @param      {String}  arrayPropertyKey  The property key of the array
       *                                         in which to accumulate.
       */
      function issueIncrementalRequest(bucket, arrayPropertyKey) {
        // Make a request with the latest known config object.
        $http(opts).then(function responseReceived(resp) {
          var data = resp.data;

          // No data.
          if (!data) {
            return deferred.reject();
          }

          // Transform the data if a function was provided.
          if (transformFn && data[arrayPropertyKey]) {
            data[arrayPropertyKey] = data[arrayPropertyKey].map(transformFn);
          }

          if (!bucket) {
            // Initial fetch, assign the initial response data.
            bucket = data;
          } else if (data[arrayPropertyKey]) {
            // Initialize bucket, in case where api did not send arrayPropertyKey in first response
            if (!bucket[arrayPropertyKey]) {
              bucket[arrayPropertyKey] = [];
            }
            // Since the bucket already exists, concat the new values.
            bucket[arrayPropertyKey] =
              bucket[arrayPropertyKey].concat(data[arrayPropertyKey]);
          }

          // If the most recent fetch did not return a pagination cookie, then
          // we're done. We can resolve the request now.
          if (!data.cookie) {
            return deferred.resolve(bucket);
          }

          // If we've gotten this far, we need to fetch the next set of records.
          angular.extend(opts.params, {
            // Reuse the pagination cookie.
            cookie: data.cookie,

            // Update the number of subsequently requested records.
            pageCount: numFetchMore,
          });

          // Run this self method again.
          issueIncrementalRequest(bucket, arrayPropertyKey);
        }, reject);
      }

      issueIncrementalRequest(bucket, config.arrayPropertyKey);

      return deferred.promise;
    }

  }


}(angular));
