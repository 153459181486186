import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { IrisContextService } from '@cohesity/iris-core';
import { Environment, JobRunType, RecoveryAction } from 'src/app/shared/constants';
import { isNoSqlHadoopSource } from 'src/app/util/env-utils';

import { RestorePointSelection } from '../../model/restore-point-selection';
import { SnapshotLabels } from '../../snapshot-selector/models/snapshot-labels';
import { SnapshotSelectorView } from '../../snapshot-selector/models/snapshot-selector-view';

/**
 * Interface for providing the dialog data.
 */
export interface DialogData {
  /**
   * Default View Switcher's view to render on initial dialog render.
   */
  defaultViewSwitcherView?: SnapshotSelectorView;

  /**
   * Indicates whether to hide the view switcher.
   */
  hideViewSwitcher?: boolean;

  /**
   * The object for which, the snapshot selector should initialize.
   */
  object: RestorePointSelection;

  /**
   * Specifies the backup type for the given object snapshot.
   */
  objectActionKeys?: Environment[];

  /**
   * The title to show on the dialog. This will default to "Edit recovery point
   * for <object-name>", if not specified.
   */
  title?: string;

  /*
   * In case of NoSQL adapters, sourceId is required to fetch snapshot for
   * indexed-objects.
   */
  useSourceId?: boolean;

  /**
   * If this is true, the modal will use the indexed object api.
   */
  indexedObjectName?: string;

  /**
   * Optional info message to display with the dialog.
   */
  infoMessage?: string;

  /**
   * Optional protection group id to filter the fetched snapshots on.
   */
  protectionGroupId?: string;

  /**
   * Some environments need special handling.
   * RDS for ex. used kRDSSnapshotManager in v1 but uses kAWS in v2.
   * This key will override the object.environment in object key above.
   */
  environment?: Environment;

  /**
   * Any custom snapshot labels
   */
  snapshotLabels?: SnapshotLabels;

  /**
   * If this value is given, we add a checkobx to only fetch given run type snapshots.
   */
  fetchRunType?: JobRunType;

  /**
   * Determines if timestamp values with in Point in time range is allowed for selection or not.
   */
  pointInTimeSupported?: boolean;

  /**
   * Determines if the indexed snapshots toggle should be hidden.
   */
  hideIndexedSnapshotsToggle?: boolean;

  /**
   * If true, shows list of pluggable db list based on selection.
   * Applicable to database adapters(eg: Oracle).
   */
  showPdbSelection?: boolean;

  /**
   * The selected recovery action type.
   */
  recoveryAction: RecoveryAction;

  /**
   * List of actions available for the snapshot
   */
  snapshotActions?: RecoveryAction[];

  /**
   * Boolean which specifies only if snapshot location view should be hidden or not.
   */
  hideSnapshotLocationPicker?: boolean;

  /**
   * Specifies the confirmation button label passed to the dialog.
   */
  confirmButtonLabel?: string;

  /**
   * Specifies if the selected point in time value should be inline with the min and max time range
   * value provided.
   */
  allowTimeRangeValidation?: boolean;

  /**
   * Minimum point in time value allowed for timeline selection.
   */
  min?: number;

  /**
   * Boolean which specifies if the pdb granular restore selection is made.
   */
  isGranularRestore?: boolean;
}

@Component({
  selector: 'coh-snapshot-selector-modal',
  templateUrl: './snapshot-selector-modal.component.html',
  styleUrls: ['./snapshot-selector-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnapshotSelectorModalComponent {
  /**
   * The snapshot value.
   */
  value: RestorePointSelection;

  /**
   * Return the default view for snapshot selector when opened.
   */
  get defaultViewSwitcherView(): SnapshotSelectorView {
    if (this.data.object?.isPointInTime) {
      // If the selected snapshot is point in time, always show the
      // timeline view as the list will jump to incremental snapshot otherwise.
      return 'timeline';
    }

    return this.data.defaultViewSwitcherView;
  }

  /**
   * The name of the current object.
   */
  get name(): string {
    return this.data.indexedObjectName ? this.data.indexedObjectName : this.data.object.objectInfo.name;
  }

  /**
   * Returns true if environment is NoSQL-Hadoop, false otherwise.
   */
  get isNosqlHadoopEnvironment(): boolean {
    return isNoSqlHadoopSource(this.data.environment);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: DialogData,
    private cdr: ChangeDetectorRef,
    readonly irisContextService: IrisContextService) {
  }

  onSnapshotChanged(value: RestorePointSelection) {
    this.value = value;
    this.cdr.detectChanges();
  }
}
