import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ResourceType } from '@cohesity/api/guardian';
import {
  DataIdModule,
  DataTreeControl,
  DataTreeModule,
  DataTreeNodeContext,
  DataTreeSelectionChangeEvent,
  DataTreeSelectionModel,
  DataTreeSource
} from '@cohesity/helix';
import { OnChange, OnTouched } from '@cohesity/shared-forms';
import { ClearSubscriptions } from '@cohesity/utils';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ObservableInput } from 'ngx-observable-input';
import { Observable } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

import { ResourceNode } from '../../models';
import { ResourceTreeNode, ResourceTreeTransformerService } from '../../services';
import { ResourcesTreeAutoAssignComponent } from '../resources-tree-auto-assign/resources-tree-auto-assign.component';

@Component({
  standalone: true,
  selector: 'coh-resources-tree-table',
  templateUrl: './resources-tree-table.component.html',
  styleUrls: ['./resources-tree-table.component.scss'],
  imports: [
    CommonModule,
    DataIdModule,
    DataTreeModule,
    MatTableModule,
    ReactiveFormsModule,
    ResourcesTreeAutoAssignComponent,
    TranslateModule,
    MatTooltipModule,
  ],
})
export class ResourceTreeTableComponent extends ClearSubscriptions implements OnInit, ControlValueAccessor {
  /**
   * Resources data to display.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput([]) @Input('data') data$: Observable<ResourceNode[]>;

  /**
   * Callback to pass the change event back to a form control. Defaults to a noop.
   */
  private onChange: OnChange<string[]>;

  /**
   * Callback to pass the onTouch event back to a form control. Defaults to a noop.
   */
  private onTouch: OnTouched;

  /**
   * The data tree selection class. This is required even for read-only.
   */
  dataTreeSelection: DataTreeSelectionModel<ResourceTreeNode>;

  /**
   * The data tree source class.
   */
  dataTreeSource: DataTreeSource<any>;

  /**
   * The data tree control class.
   */
  dataTreeControl: DataTreeControl<ResourceTreeNode>;

  /**
   * List of column names for mat-table.
   */
  columnDef = ['name', 'status'];

  /**
   * Types of resources
   */
  ResourceType = ResourceType;

  constructor(
    @Optional() @Self() public controlDirective: NgControl,
    private transformer: ResourceTreeTransformerService,
    private translate: TranslateService
  ) {
    super();
    // Set the value accessor directly.
    // This is equivalent to injecting NG_VALUE_ACCESSOR to the provider.
    if (this.controlDirective != null) {
      this.controlDirective.valueAccessor = this;
    }

    this.dataTreeControl = new DataTreeControl(this.transformer.getLevel, this.transformer.getExpandable);
    this.dataTreeSource = new DataTreeSource(this.transformer, this.dataTreeControl);
    this.dataTreeSelection = new DataTreeSelectionModel(this.dataTreeControl);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.data$.pipe(filter(result => !!result)).subscribe(data => {
        this.dataTreeSource.data = this.getServiceResources(data);
      })
    );

    this.subscriptions.push(
      this.dataTreeSource.flattenedData$.pipe(filter(result => !!result)).subscribe(allDataNodes => {
        this.dataTreeSelection.currentSelection = this.transformer.transformToDataTreeSelection(
          allDataNodes as ResourceTreeNode[],
          this.controlDirective.value
        );
      })
    );

    this.subscriptions.push(
      this.dataTreeSource.filteredData$.pipe(debounceTime(5)).subscribe(filteredNodes => {
        const expandNodes = filteredNodes.filter((node: ResourceTreeNode) =>
          this.transformer.shouldExpandNodeOnLoad(node)
        );
        this.dataTreeControl.expansionModel.select(...(expandNodes as ResourceTreeNode[]));
      })
    );
  }

  /**
   * Returns resource tree without root node.
   *
   * @param resourceTree Entire resource tree.
   * @returns service resource hierarchy.
   */
  getServiceResources(resourceTree: ResourceNode[]): ResourceNode[] {
    return resourceTree[0]?.children;
  }

  /**
   * Handles user-initiated selection changes.
   */
  handleUserSelectionChange() {
    const selection = this.transformer.transformFromDataTreeSelection(this.dataTreeSelection.currentSelection);
    this.onChange(selection);
  }

  /**
   * Handler for auto assign button click.
   *
   * @param ev selection event
   */
  handleAutoAssignSelectionChange(ev: DataTreeSelectionChangeEvent<ResourceTreeNode>) {
    this.transformer.selectionChangeHandler(ev.selection);
    this.handleUserSelectionChange();
  }

  /**
   * Function callback for getting tooltip text on hover of node auto assign icon
   *
   * @param   nodeContext   DataTreeNodeContext reference to generate text
   * @return  string        tooltip text to be displayed on autoselect hover
   */
  getAutoSelectTooltipText = (nodeContext: DataTreeNodeContext<ResourceTreeNode>): string =>
    nodeContext.autoSelected ? this.translate.instant('helios.accessManagement.accessScope.autoAssignOnTooltip') : '';

  // start: CVA overrides

  writeValue(value: string[]) {
    if (this.dataTreeControl.dataNodes) {
      this.dataTreeSelection.currentSelection = this.transformer.transformToDataTreeSelection(
        this.dataTreeControl.allDataNodes,
        value
      ) as any;
    }
  }

  registerOnChange(fn: OnChange<string[]>) {
    this.onChange = fn;
  }

  registerOnTouched(fn: OnTouched) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.dataTreeSelection = new DataTreeSelectionModel(this.dataTreeControl, isDisabled);
  }
  // end: CVA overrides
}
