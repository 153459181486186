<cog-settings-list-item
  [label]="'sasUrlTypeLabel' | translate">
  <cog-settings-list-item-value-display>
    {{(formGroup.get('endPointType').value === 'public' ?
      'publicEndpoint' : 'privateEndpoint') | translate}}
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor [formGroup]="formGroup">
    <p [innerHtml]="'sasUrlInfoText' | translate"></p>

    <div class="row">
      <div class="col-xs-6">
        <mat-radio-group
          class="margin-top flex-row"
          [formControlName]="formControlNames.endPointType">
          <mat-radio-button *ngFor="let type of endPointTypes"
            class="margin-right"
            [value]="type.value">
              {{type.key | translate}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="margin-top" *ngIf="isPrivateEndpoint && !isNewLocationRecovery">
      <mat-slide-toggle [formControlName]="formControlNames.sasUrlToggle">
        {{'sasUrlToggleTextForRecovery' | translate}}
      </mat-slide-toggle>
    </div>

    <ng-container *ngIf="!formGroup.get('sasUrlToggle').value && isPrivateEndpoint">
      <cog-banner *ngIf="!isNewLocationRecovery" class="margin-top" status="info">
        <span>{{'sasUrlRecoveryInfoBannerText' | translate}}</span>
      </cog-banner>
      <div class="rows-wrap margin-top" formArrayName="privateEndpointFields">
        <div class="rows-nowrap" *ngFor="let privateEndpointField of privateEndpointFields; let i = index;">
          <coh-app-private-endpoints-row-recover
            [privateEndpointField]="privateEndpointField"
            [protectionSource$]="recoverAzureService.selectedObjectSource$.asObservable()"
            [destinationSourceSelected$]="recoverAzureService.selectedDestinationSource$.asObservable()"
            [resourceGroupSelected$]="recoverAzureService.selectedResourceGroup$.asObservable()"
            [isNewLocationRecovery]="isNewLocationRecovery">
          </coh-app-private-endpoints-row-recover>

          <coh-form-array-action-control *ngIf="!isNewLocationRecovery"
            [formArrayLength]="privateEndpointFields.length"
            [index]="i"
            (controlAdded)="addPrivateEndpointFields()"
            (controlRemoved)="removePrivateEndPointField(i)">
          </coh-form-array-action-control>
        </div>

      </div>
    </ng-container>

  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
