<mat-divider></mat-divider>

<h4 class="margin-top-lg no-margin-bottom">{{ 'swiftOwnership' | translate }}</h4>

<div class="flex-row-md margin-top">
  <mat-form-field>
    <mat-label>{{ 'projectDomain' | translate }}</mat-label>
    <input cogDataId="swift-project-domain-name" [formControl]="form.formGroup.controls.swiftProjectDomain" matInput>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'projectName' | translate }}</mat-label>
    <input cogDataId="swift-project-name" [formControl]="form.formGroup.controls.swiftProjectName" matInput>
  </mat-form-field>
</div>

<div class="flex-row-md">
  <mat-form-field>
    <mat-label>{{ 'username' | translate }} ({{ 'optional' | translate }})</mat-label>
    <input cogDataId="swift-username" [formControl]="form.formGroup.controls.swiftUsername" matInput>
  </mat-form-field>

  <mat-form-field *ngIf="form.value?.swiftUsername">
    <mat-label>{{ 'usersDomainName' | translate }}</mat-label>
    <input cogDataId="swift-user-domain" [formControl]="form.formGroup.controls.swiftUserDomain" matInput>
    <mat-error *ngIf="form.formGroup.controls.swiftUserDomain.errors?.required">
      {{ 'errors.required' | translate }}
    </mat-error>
  </mat-form-field>
</div>
