import { AzureDiskTag, AzureDiskTagParams } from '@cohesity/api/v2';
import {
  AndOrExpContext,
  AttributesContext,
  AzureLexer,
  AzureVisitor,
  ComparisonOperatorContext,
  LogicalOperatorContext,
  MultiArgContext,
  MultiArgsExpContext,
  ParenExpContext,
  ParseContext,
  RangeOperatorContext,
  SingleArgContext,
  SingleArgExpContext,
} from '@cohesity/grammar/azure-volume-exclusions';
import { ParseTree } from 'antlr4ts/tree';
import { AbstractParseTreeVisitor } from 'antlr4ts/tree/AbstractParseTreeVisitor';
import { TerminalNode } from 'antlr4ts/tree/TerminalNode';

interface ProcessedNode {
  // Stores the actual text of a particular key/value.
  field?: string;

  // Stores the combined tagParams Array recieved till that node.
  tagParamsArray?: AzureDiskTagParams[];

  // Stores the type of operator for Operator Nodes: AND, OR, =, !=, IN, NOT IN.
  operatorType?: number;

  // Stores the array of values in case of range operators : IN, NOT IN.
  valueList?: string[];
}

/**
 * The Azure grammar processed query constructor.
 */
export class AzureTagParamsVisitor extends AbstractParseTreeVisitor<ProcessedNode>
  implements AzureVisitor<ProcessedNode> {
  constructor() {
    super();
  }

  /**
   * Return the default suggestion.
   */
  defaultResult(): ProcessedNode {
    return null;
  }

  /**
   * Return the aggregate suggestion.
   *
   * @param  aggregate   The current suggestion.
   * @param  nextResult  The next suggestion.
   * @return             The aggregate suggestion.
   */
  aggregateResult(aggregate: ProcessedNode, nextResult: ProcessedNode): ProcessedNode {
    return nextResult ?? aggregate;
  }

  /**
   * Visit the AST parse node.
   * parse : expr EOF.
   *
   * @param  ctx The parser AST node.
   * @return     The search suggestion.
   */

  visitParse(ctx: ParseContext): ProcessedNode {
    return ctx.expr() ? this.visit(ctx.expr()) : { tagParamsArray: [] };
  }

  /**
   * Visit a parse tree produced by the `andOrExpr`
   * labeled alternative in `AzureParser.expr`.
   * expr: expr logicalOperator expr.
   *
   * @param  ctx The parse tree.
   * @return     The visitor result.
   */
  visitAndOrExp(ctx: AndOrExpContext): ProcessedNode {
    const left = this.visit(ctx.expr(0));
    const { operatorType: operator } = this.visit(ctx.logicalOperator());
    const right = this.visit(ctx.expr(1));

    const processed = {} as ProcessedNode;
    processed.tagParamsArray = this.combineTagParamsArrays(
      left.tagParamsArray,
      right.tagParamsArray,
      operator
    );

    return processed;
  }

  /**
   * Visit a parse tree produced by the `singleArgExp`
   * labeled alternative in `AzureParser.expr`.
   * expr: attributes comparisionOperator singleArg.
   *
   * @param  ctx The parse tree.
   * @return     The visitor result.
   */
  visitSingleArgExp(ctx: SingleArgExpContext): ProcessedNode {
    const { field: key } = this.visit(ctx.attributes());
    const { operatorType: operator } = this.visit(ctx.comparisonOperator());
    const { field: value } = this.visit(ctx.singleArg());

    const tagParamsArray: AzureDiskTagParams[] = [];
    const tagParams = this.getTagParams(key, value, operator);
    tagParamsArray.push(tagParams);

    const processed = {} as ProcessedNode;
    processed.tagParamsArray = tagParamsArray;

    return processed;
  }

  /**
   * Visit a parse tree produced by the `multiArgsExp`
   * labeled alternative in `AzureParser.expr`.
   * expr: attributes rangeOperator OPEN_PAREN multiArg CLOSE_PAREN
   *
   * @param  ctx The parse tree.
   * @return     The visitor result.
   */
  visitMultiArgsExp(ctx: MultiArgsExpContext): ProcessedNode {
    const { field: key } = this.visit(ctx.attributes());
    const { operatorType: operator } = this.visit(ctx.rangeOperator());
    const { valueList: values } = this.visit(ctx.multiArg());

    const tagParamsArray: AzureDiskTagParams[] = [];

    values.forEach((value: string) => {
      const tagParams = this.getTagParams(key, value, operator);
      tagParamsArray.push(tagParams);
    });

    const processed = {} as ProcessedNode;
    processed.tagParamsArray = tagParamsArray;

    return processed;
  }

  /**
   * Visit a parse tree produced by the `parenExp`.
   * labeled alternative in `AzureParser.expr`.
   * expr: OPEN_PAREN expr CLOSE_PAREN.
   *
   * @param  ctx The parse tree.
   * @return     The visitor result.
   */
  visitParenExp(ctx: ParenExpContext): ProcessedNode {
    return this.visit(ctx.expr());
  }

  /**
   * Visit a parse tree produced by `AzureParser.attributes`.
   * attributes: IDENTIFIER.
   *
   * @param  ctx The parse tree.
   * @return     The visitor result.
   */
  visitAttributes(ctx: AttributesContext): ProcessedNode {
    return this.visit(ctx.IDENTIFIER());
  }

  /**
   * Visit a parse tree produced by `AzureParser.ComparisonOperator`.
   * comparisonOperator: EQ | NOT_EQ.
   *
   * @param  ctx The parse tree.
   * @return     The visitor result.
   */
  visitComparisonOperator(ctx: ComparisonOperatorContext): ProcessedNode {
    return this.visit(ctx.EQ() ?? ctx.NOT_EQ());
  }

  /**
   * Visit a parse tree produced by `AzureParser.rangeOperator`.
   * rangeOperator: K_IN | K_NOT K_IN.
   *
   * @param  ctx The parse tree.
   * @return     The visitor result.
   */
  visitRangeOperator(ctx: RangeOperatorContext): ProcessedNode {
    return this.visit(ctx.K_NOT() ?? ctx.K_IN());
  }

  /**
   * Visit a parse tree produced by `AzureParser.logicalOperator`.
   * logicalOperator: K_AND | K_OR.
   *
   * @param  ctx The parse tree.
   * @return     The visitor result.
   */
  visitLogicalOperator(ctx: LogicalOperatorContext): ProcessedNode {
    return this.visit(ctx.K_AND() ?? ctx.K_OR());
  }

  /**
   * Visit a parse tree produced by `AzureParser.singleArg`.
   * singleArg: IDENTIFIER.
   *
   * @param  ctx The parse tree.
   * @return     The visitor result.
   */
  visitSingleArg(ctx: SingleArgContext): ProcessedNode {
    return this.visit(ctx.IDENTIFIER());
  }

  /**
   * Visit a parse tree produced by `AzureParser.multiArg`.
   * multiArg: singleArg ( COMMA singleArg )*.
   *
   * @param  ctx The parse tree.
   * @return     The visitor result.
   */
  visitMultiArg(ctx: MultiArgContext): ProcessedNode {
    const values: string[] = [];

    ctx.children.forEach((child: ParseTree) => {
      const childProcessed = this.visit(child);
      if (child instanceof SingleArgContext) {
        values.push(childProcessed.field);
      }
    });

    const processed = {} as ProcessedNode;
    processed.valueList = values;

    return processed;
  }

  /**
   * Visit a terminal node, and return a user-defined result of the operation.
   *
   * @param  node The TerminalNode to visit.
   * @return      The result of visiting the node.
   */
  visitTerminal(node: TerminalNode): ProcessedNode {
    const processed = {} as ProcessedNode;

    switch (node.symbol.type) {
      case AzureLexer.IDENTIFIER: {
        // Strip the start and end quotes(when the string has a ' ' or '=').
        // These quotes may have been added if the key/value had a ' ' or '='.
        processed.field = node.text.replace(/^["'`](.*)["'`]$/, '$1');
        break;
      }
      case AzureLexer.K_OR:
      case AzureLexer.K_AND:
      case AzureLexer.K_NOT:
      case AzureLexer.K_IN:
      case AzureLexer.NOT_EQ:
      case AzureLexer.EQ: {
        processed.operatorType = node.symbol.type;
        break;
      }
      default:
        break;
    }

    return processed;
  }

  /**
   * Helper function to combine the arrays recieved from left and right subtrees for a node
   * containing a logical operator.
   *
   * @param  left     The tagparams array from the left.
   * @param  right    The tagparams array from the right.
   * @param  operator The logical operator(AND/OR).
   * @return          The combined tagparams array.
   */
  combineTagParamsArrays(left: AzureDiskTagParams[], right: AzureDiskTagParams[],
    operator: number): AzureDiskTagParams[] {
    switch (operator) {
      case AzureLexer.K_AND: {
        const combined: AzureDiskTagParams[] = [];

        left.forEach((lparams: AzureDiskTagParams) => {
          right.forEach((rparams: AzureDiskTagParams) => {
            const lrparams: AzureDiskTagParams = {
              inclusionTagArray: [],
              exclusionTagArray: [],
            };
            lparams.inclusionTagArray.forEach((tag: AzureDiskTag) => {
              lrparams.inclusionTagArray.push(tag);
            });
            lparams.exclusionTagArray.forEach((tag: AzureDiskTag) => {
              lrparams.exclusionTagArray.push(tag);
            });
            rparams.inclusionTagArray.forEach((tag: AzureDiskTag) => {
              lrparams.inclusionTagArray.push(tag);
            });
            rparams.exclusionTagArray.forEach((tag: AzureDiskTag) => {
              lrparams.exclusionTagArray.push(tag);
            });
            combined.push(lrparams);
          });
        });

        return combined;
      }
      case AzureLexer.K_OR: {
        right.forEach((rparams: AzureDiskTagParams) => {
          left.push(rparams);
        });

        return left;
      }
    }
  }

  /**
   * Helper function to get tag params given a key, comparision operator, and a value.
   * Eg. status = critical ==> <key> <comparisonOp> <Value>.
   *
   * @param  key      The tagparams array from the left.
   * @param  right    The tagparams array from the right.
   * @param  operator The logical operator(AND/OR).
   * @return          The combined tagparams array.
   */
  getTagParams(key: string, value: string, operator: number): AzureDiskTagParams {
    const adt: AzureDiskTag = { key, value };
    const tagParams: AzureDiskTagParams = {
      inclusionTagArray: [],
      exclusionTagArray: [],
    };

    switch (operator) {
      case AzureLexer.EQ:
      case AzureLexer.K_IN: {
        tagParams.exclusionTagArray.push(adt);
        break;
      }
      case AzureLexer.NOT_EQ:
      case AzureLexer.K_NOT: {
        tagParams.inclusionTagArray.push(adt);
        break;
      }
    }

    return tagParams;
  }
}
