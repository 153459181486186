import { FormControl } from '@angular/forms';

/**
 * The local storage key we use to store the ad
 * credentials to prefill those inputs.
 */
export const localStorageKey = 'rememberedAdCredentials';

/**
 * Model for Login Form.
 */
export interface LoginForm {
  /**
   * Usename input.
   */
  username?: FormControl<string>;

  /**
   * Password input.
   */
  password?: FormControl<string>;

  /**
   * Password input.
   */
  domain?: FormControl<string>;

  /**
   * Password input.
   */
  rememberme?: FormControl<boolean>;
}

/**
 * This model represents the credentials
 * passes to the auth function from the
 * component.
 */
export interface LoginCredentials {
  username: string;
  password?: string;
  domain?: string;
  rememberme?: boolean;
}

/**
 * This model represents the event that is emitted from the LoginComponent.
 * It is up to the caller to implement the actual authentication mechanism
 * based on these values.
 */
export interface LoginEvent {
  credentials: LoginCredentials;
  authType: AuthType;
}

/**
 * The types of login that this login component supports
 */
export type AuthType = 'local' | 'sso' | 'ad' | 'salesforce';

/**
 * This model represents the object that is stored inside
 * persist service about the AD credentials the user last entered.
 */
export interface RememberedAdCredentials {
  username: string;
  domain: string;
}
