import { Injectable } from '@angular/core';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';

import { HelixIntlService } from '../../helix-intl.service';

/**
 * The class overrides the mat paginator intl file to provide strings from our own strings file.
 * It also overrides the getRangeLabel method to to provide some additional functionality around
 * async table sources where the total number of entries is unknown.
 */
@Injectable({
  providedIn: 'root',
})
export class PaginatorIntlService extends MatPaginatorIntl {
  constructor(private intl: HelixIntlService) {
    super();
    this.firstPageLabel = intl.table.firstPageLabel;
    this.itemsPerPageLabel = intl.table.itemsPerPageLabel;
    this.lastPageLabel = intl.table.lastPageLabel;
    this.nextPageLabel = intl.table.nextPageLabel;
    this.previousPageLabel = intl.table.previousPageLabel;
  }

  /**
   * A label for the range of items within the current page and the length of the whole list.
   * This modifies the original behavior of the class to handle async data sets where the total
   * number of items is unknown. If the length passed to this method is Number.MAX_SAFE_INTEGER,
   * the returned label will have a + appended to the length to indicate that there are more
   * values in the dataset.
   *
   * @param   page       The current page of the table.
   * @param   pageSize   The number of items per page.
   * @param   length     The total size of the data set.
   *
   * @return  The translated label.
   */
  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return this.intl.table.rangeLabelNone(length);
    }
    length = Math.max(length, 0);

    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return Number.MAX_SAFE_INTEGER === length ?
      this.intl.table.rangeLabelMore(startIndex + 1, endIndex) :
      this.intl.table.rangeLabel(startIndex + 1, endIndex, length);
  };
}
