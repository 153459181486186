import { ChangeDetectionStrategy, Component, OnChanges } from '@angular/core';
import { customDateTimeLabelFormats, HighchartsDataConfigService } from '@cohesity/helix';
import { Options, PointOptionsObject, SeriesColumnOptions } from 'highcharts';

import { CustomChartInput } from '../../../iris-reporting.model';
import { ChartReportsService } from '../../chart-reports.service';
import { BaseReportItemRendererComponent } from '../base-report-item-renderer.component';

/**
 * Predefined order for displaying series data based on status.
 */
const statusOrder = [
  [ 'kSuccess', 'Success', 'reporting.success', 'reporting.protected',
    'reporting.hasBackups', 'enum.backupJob.kSuccess' ],
  [ 'kWarning', 'Warning',  'reporting.warning', 'enum.backupJob.kWarning' ],
  [ 'kFailure', 'Failed', 'reporting.failure', 'reporting.unprotected',
    'reporting.hasNoBackups', 'enum.backupJob.kFailure' ],
  [ 'kCanceled', 'Canceled', 'reporting.canceled', 'enum.backupJob.kCanceled' ],
  [ 'enum.backupJob.kCanceling' ],
  [ 'enum.backupJob.kMissed' ],
  [ 'kRunning', 'Running', 'reporting.running', 'enum.backupJob.kRunning' ],
  [ 'enum.backupJob.kAccepted' ],
  [ 'enum.backupJob.kOnHold' ],
  [ 'enum.number.3' ],
  [ 'enum.number.2' ],
  [ 'enum.number.1' ],
  [ 'enum.number.0' ],
];

/**
 * Sort the keys of series data so that data will be displayed based on the order in statusOrder
 *
 * @param    mapKeys  Keys of a Map for series data.
 * @returns  ordered keys.
 */
function sortStatus(mapKeys: IterableIterator<string>): string[] {
  if (!mapKeys) {
    return [];
  }

  const keys = Array.from(mapKeys);

  if (!keys?.length) {
    return [];
  }

  const result = [];

  statusOrder.forEach(statusList => {
    const index = keys.findIndex(key => statusList.includes(key));
    if (index !== -1) {
      result.push(...keys.splice(index, 1));
    }
  });
  result.push(...keys);
  return result;
}

/**
 * A column chart report item.
 */
@Component({
  selector: 'iris-rpt-column-chart-item',
  templateUrl: './column-chart-item.component.html',
  styleUrls: ['./column-chart-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnChartItemComponent extends BaseReportItemRendererComponent implements OnChanges, CustomChartInput {
  /**
   * Category names to use for the x axis.
   */
  categories: string[] = [];

  /**
   * The chart's series, transformed from the input.
   */
  seriesData: SeriesColumnOptions[] = [];

  /**
   * Additional options used to format the chart.
   */
  options: Options = {
    chart: {
      marginTop: 20,
    },
    legend: {
      enabled: true,
      align: 'right',
      verticalAlign: 'top',
      layout: 'vertical',
      itemMarginBottom: 4,
      itemStyle: {
        fontWeight: 'normal',
      },
      useHTML: true,
    },
    yAxis: {
      visible: true,
      title: {
        enabled: false,
      } as any,
    },
    plotOptions: {
      column: {
        pointWidth: null,
        borderRadius: 0,
        minPointLength: 3,
        ...this.chartPlotOptions,
      },
    },
  };
  /**
   * Given a chart config, determine whether this chart can be used to render it or now.
   *
   * @param input The chart input
   * @returns True if this component can render it.
   */
  static canRender(input: CustomChartInput): boolean {
    return input.dimensions?.length && !!input.measurements?.length;
  }

  constructor(private chartService: ChartReportsService, private highchartConfigService: HighchartsDataConfigService) {
    super();
  }

  ngOnChanges(): void {
    const xAxisIsDate = this.dimensions[0].dataType === 'date';

    if (this.isPrintMedia) {
      this.options.plotOptions.column.animation = false;
    }

    if (this.measurements && this.dimensions && this.data) {
      const {seriesData, xValues, originalXValues} =
        this.chartService.getChartData(this.data, this.measurements, this.dimensions);
      const dataConfig = this.highchartConfigService.getConfigForDataType(this.measurements[0].dataType);

      (this.options.yAxis as Highcharts.YAxisOptions).labels = {
        formatter: dataConfig.axisLabelFormatter,
      };
      (this.options.yAxis as Highcharts.YAxisOptions).tickPositioner = dataConfig.tickPositioner;

      this.options.tooltip = {
        pointFormatter: dataConfig.tooltipPointFormatter,
      };


      if (xAxisIsDate) {
        this.options.xAxis = {
          visible: true,
          type: 'datetime',
          dateTimeLabelFormats: customDateTimeLabelFormats,
          tickLength: 0,
          showFirstLabel: true,
          showLastLabel: true,
          tickInterval: xValues?.length > 1 ? (xValues[1] as number) - (xValues[0] as number) : undefined,
        };
        this.categories = null;
      } else {
        this.options.xAxis = {
          visible: true,
          type: 'category'
        };
        this.categories = xValues?.map(value => this.translate(value as string)) || [];
      }

      // This forces the chart to redraw with the updated options
      this.options = {...this.options};

      this.seriesData = sortStatus(seriesData.keys()).map(seriesKey => ({
        type: 'column',
        name: this.translate(seriesKey),
        className: seriesData.get(seriesKey)?.className,
        data: seriesData.get(seriesKey)?.data.map((value, index) => {
          const point: PointOptionsObject = {
            name: typeof (xValues[index]) === 'string' ? this.translate(xValues[index] as string) : xValues[index],
            y: value,
            custom: this.getCustomData(seriesData.get(seriesKey)?.originalKey, originalXValues[index]),
          };

          // name attribute is no longer used by Highchart to format time in x-axis so need to assign it to x.
          if (xAxisIsDate) {
            point.x = point.name as number;
          }
          return point;
        }),
      }));
    }
  }
}
