<h2 mat-dialog-title>{{ 'completeVmMigration' | translate }}</h2>
<form [formGroup]="completeVmMigrationForm">
  <mat-dialog-content class="mat-typography margin-top-xs margin-bottom-xs">
    <cog-banner status="info">
      <p>{{'vm.migration.migrationFinalizationInfo' | translate}}</p>
    </cog-banner>
    <div class="margin-top">
      <mat-slide-toggle
        formControlName="powerOnTarget"
        cogDataId="power-on-target-toggle">
        {{ 'vm.migration.powerOnTarget' | translate }}
      </mat-slide-toggle>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      cogDataId="cancel-migration"
      cogCancelButton
      mat-button
      [mat-dialog-close]="null">
      {{ 'cancel' | translate }}
    </button>

    <button
      cogDataId="complete-migration"
      color="primary"
      cogSubmitButton
      [mat-dialog-close]="completeVmMigrationForm.value">
      {{ 'completeVmMigration' | translate }}
    </button>
  </mat-dialog-actions>
</form>
