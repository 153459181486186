import { Injectable } from '@angular/core';
import { IrisContextService } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { Environment } from 'src/app/shared/constants';

import { VmSourceTreeService } from './vm-source-tree.service';

@Injectable({
  providedIn: 'root'
})
export class AcropolisSourceTreeService extends VmSourceTreeService {
  constructor(translate: TranslateService, irisContext: IrisContextService) {
    super(Environment.kAcropolis, translate, irisContext);
  }
}
