import { getUserPrivilegeCheck, IrisContext, isHeliosTenantUser, isOrganizationEnabled } from '@cohesity/iris-core';

/**
 * Return true of ransomware anomalies bulk selection for recovery or resolve anomaly is allowed or not.
 *
 * @param ctx The iris context.
 * @returns True if the user can select ransomware anomalies or not.
 */
export const canRecoverOrResolve = (ctx: IrisContext) => !(isOrganizationEnabled(ctx) && isHeliosTenantUser(ctx));

/**
 * Return true of ransomware anomaly can be ignored or not.
 *
 * @param ctx The iris context.
 * @returns True if the user can make ignored anomaly call else false.
 */
export const canIgnoreAnomaly = (ctx: IrisContext) => getUserPrivilegeCheck('SNAPSHOT_TAGS_MANAGE')(ctx);
