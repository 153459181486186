import { Injectable } from '@angular/core';
import { AgentDeploymentStatusResponse, ReportsServiceApi } from '@cohesity/api/v1';
import { BANNER_PRIORITY, BannerConfig } from '@cohesity/helix';
import { BaseBannerConfig, flagEnabled, IrisContext, IrisContextService, isMcm, isOneHeliosAppliance } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ClusterAgentCertBannerConfig extends BaseBannerConfig {
  /**
   * Unique banner representation
   */
  id = 'cluster-agent-cert-banner';

  /**
   * Number of days to start showing this banner for expiring agent certs
   */
  expiringSoonDays = 90;

  /**
   * Banner config as observable
   */
  bannerInfo$ = combineLatest([
    this.irisContextService.irisContext$,
    this.reportsServiceApi.GetAgentDeploymentReport({}),
  ]).pipe(
    map(([irisContext, agents]) => {
      if (!agents?.length) {
        return {irisContext};
      }

      // Create an array of agent expiry timestamps, with the closest expiry
      // timestamp as the first item.
      const timestamps = (agents as (AgentDeploymentStatusResponse & { certificateExpiryTimeUsecs: number })[]).map(
        (agent) => agent?.certificateExpiryTimeUsecs
      ).sort((a, b) => a - b);

      // Create date object for the closest expiry time
      const closestExpiryTime = new Date(timestamps[0] / 1000);

      // Create date object for showing warning banner
      const expiringSoonTime = new Date();
      expiringSoonTime.setDate(expiringSoonTime.getDate() + this.expiringSoonDays);

      // Variable to store banner config
      let value: Partial<BannerConfig>;

      if (new Date().getTime() > closestExpiryTime.getTime()) {
        // Certificate is already expired
        value = {
          status: 'critical',
          text: this.translateService.instant('agentCertBanner.expired'),
          tooltip: this.translateService.instant('agentCertBanner.expired'),
        };
      } else if (closestExpiryTime.getTime() < expiringSoonTime.getTime()) {
        // Certificate is about to expire
        // Get number of days left
        const daysLeft = Math.floor(
          (closestExpiryTime.getTime() - new Date().getTime()) / (24 * 60 * 60 * 1000)
        );

        value = {
          status: 'warn',
          text: this.translateService.instant('agentCertBanner.expiringSoon', {n: daysLeft}),
          tooltip: this.translateService.instant('agentCertBanner.expiringSoon', {n: daysLeft}),
        };
      }

      return {irisContext, value};
    }),
    map(({irisContext, value}) => ([{
      id: this.id,
      priority: BANNER_PRIORITY.HIGH,
      isVisible: value && this.allowBanner(irisContext),
      allowClose: false,
      actionText: this.translateService.instant('goToAgentStatusSummary'),
      actionCallback: () => this.stateService.go('reports.agent-status'),
      ...value,
    } as BannerConfig]))
  );

  constructor(
    private reportsServiceApi: ReportsServiceApi,
    private irisContextService: IrisContextService,
    private stateService: StateService,
    private translateService: TranslateService) {
    super();
  }

  /**
   * Allow banner based on context
   *
   * @param ctx iris context
   * @returns true/false based on context
   */
  allowBanner(ctx: IrisContext) {
    return (
      this.isLoggedIn(ctx) &&
      flagEnabled(ctx, 'clusterAgentCertBanner') &&
      !isMcm(ctx) && !isOneHeliosAppliance(ctx)
    );
  }
}
