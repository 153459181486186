<div class="selection-header">
  <h4 class="no-margin title user-data-xl margin-right">
    {{ 'nSelectedNg' | translate : {n: selection.selected.length} }}
  </h4>
  <a cogDataId="deselect-all"
  (click)="selection.clear()">{{'removeAll' | translate}}</a>
</div>

<mat-list>
  <mat-list-item *ngFor="let selected of selection.selected">
    <cog-icon matListIcon shape="{{selected.type | fileIcon}}"></cog-icon>
    <h5 matLine class="title selected-name user-data-md"
      [matTooltip]="selected.name">
      {{selected.name}}
    </h5>
    <div matLine *ngIf="selected.name !== selected.fullPath" class="metadata user-data-md"
      [matTooltip]="selected.fullPath">
      {{selected.sanitizedPath}}
    </div>
    <button mat-icon-button
      class="remove-item-button"
      type="button"
      (click)="selection.deselect(selected)"
      cogDataId="deselect-file-{{selected.name}}">
      <cog-icon size="sm" shape="close"></cog-icon>
    </button>
  </mat-list-item>
</mat-list>
