import { Pipe, PipeTransform } from '@angular/core';
import { ArchivalTargetSummaryInfo } from '@cohesity/api/v2';
import { IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Account for old, k-value v1 enums as well as v2-style enums.
 */
type KnownTargetTypes =
  'Archival' | 'archival' | 'Local' | 'local' |
  'Tape' | 'kTape' | 'Nas' | 'kNas' | 'Cloud' | 'kCloud' | 'StorageArraySnapshot';

/**
 * This pipe is used to get the display name for a backup target type
 * This can be any of the archival target types: 'kTape', 'kNas', 'kCloud' or 'local' for
 * local backups.
 */
@Pipe({
  name: 'backupTargetType',
  pure: true,
})
export class BackupTargetTypePipe implements PipeTransform {

  constructor(
    private irisContextService: IrisContextService,
    private translate: TranslateService) {
  }

  /**
   * Gets a display name to show for backup target types. Use 'Local' for local backup target types
   * 'Cloud' for external backup targets and 'Tape' for tape targets.
   *
   * @param   targetType   The snapshot target type
   * @param   archivalTargetType   The snapshot archival target type
   * @returns The display name.
   */
  transform(targetType: KnownTargetTypes, archivalTargetInfo: ArchivalTargetSummaryInfo ): string {

    if (archivalTargetInfo?.ownershipContext === 'FortKnox') {
      return this.translate.instant('cloudVault');
    }

    let archivalTargetType = archivalTargetInfo?.targetType;

    switch (targetType) {
      case 'local':
      case 'Local':
        return this.translate.instant('enum.snapshotTargetType.Local');
      case 'kTape':
      case 'Tape':
        return this.translate.instant('enum.snapshotTargetType.Tape');
      case 'kNas':
      case 'Nas':
      case 'kCloud':
      case 'Cloud':
        if (isDmsScope(this.irisContextService.irisContext)) {
          return this.translate.instant('helios');
        }

        return this.translate.instant('enum.snapshotTargetType.Cloud');
      case 'Archival':
      case 'archival':
        archivalTargetType = ['Cloud', 'Nas'].includes(archivalTargetType) ? 'Cloud' : 'Tape';

        if (isDmsScope(this.irisContextService.irisContext) && archivalTargetType === 'Cloud') {
          return this.translate.instant('helios');
        }

        return this.translate.instant(`enum.snapshotTargetType.${archivalTargetType}`);
      case 'StorageArraySnapshot':
        return this.translate.instant('enum.snapshotTargetType.StorageArraySnapshot');
      default:
        return this.translate.instant('naNotAvailable');
    }
  }
}
