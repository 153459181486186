<cog-settings-list-item
  [invalid]="formControl.invalid"
  [label]="'includeAndExclude' | translate"
  cogFormGroup>
  <cog-settings-list-item-value-display>
    {{formControl.value?.enable | boolLabel}}
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor [formGroup]="inclusionFormGroup"
    cogDataId="settings-inclusion-file-upload">
    <div>
      <mat-slide-toggle formControlName="enable"
        [inheritDataId]="true"
        cogDataId="toggle">
        {{'enablePartialProtection' | translate}}
      </mat-slide-toggle>
      <mat-hint>
        {{'enablePartialProtectionHint' | translate}}
      </mat-hint>
    </div>
    <div *ngIf="inclusionFormGroup?.value?.enable">
      <div class="file-list-container margin-top-lg margin-bottom-md">
        <div *ngFor="let type of fileTypes" class="{{ type }}-list-container">
          <h3>{{ type | translate }}</h3>
          <ng-container *ngIf="!showFileName(type)">
            <div class="margin-bottom">
              <!-- hidden native input -->
              <label for="select-{{ type }}-list-file">
                <input type="file"
                  required
                  class="visuallyhidden"
                  cogDataId="{{ type }}-list-input"
                  id="select-{{ type }}-list-file"
                  accept=".txt">
              </label>

              <!-- Select button -->
              <a mat-button
                (click)="readObjectList(type)"
                cogDataId="{{ type }}-object-list-anchor">
                {{'selectFile' | translate}}
              </a>
            </div>
          </ng-container>
          <ng-container *ngIf="showFileName(type)">
            <div class="file-details">
              <div>{{fileName(type)}}</div>
              <div class="margin-left-sm">{{ 'objectsCount' | translate: {count: objectCount(type) || 0} }}</div>
              <a (click)="removeFile(type)" cogDataId="{{ type }}-remove" class="remove-link">{{ 'remove' | translate}}</a>
            </div>
            <div class="margin-top-sm">
              <mat-checkbox id="{{ type }}-overwrite-check"
                [formControl]="overwriteFormControl(type)"
                [matTooltip]="'enablePartialOverwriteHint' | translate"
                matTooltipPosition="right">
                {{'overwrite' | translate}}
              </mat-checkbox>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
