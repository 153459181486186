// MODULE: Storage Array Network (SAN) Registration/Edit
// NOTE: cSlideModal support currently only works for registering a new Source

;(function(angular, undefined) {
  'use strict';

  angular.module('C.sources')
    .controller('sanModifyController', sanModifyControllerFn);

  function sanModifyControllerFn($rootScope, $scope, $state, $q, evalAJAX,
    SourceService, cMessage, $uibModalInstance, StateManagementService) {

    var defaultServerConfig = {
      entity: {
        // SAN:Pure
        type: 7,
        pureEntity: {
          // name will match endpoint
          name: undefined,
          // kStorageArray
          type: 0

        }
      },
      entityInfo: {
        endpoint: undefined,
        // SAN:Pure
        type: 7,
        credentials: {
          username: undefined,
          password: undefined
        }
      }
    };

    /**
     * activation function
     */
    function activate() {

      $scope.inModal = typeof $uibModalInstance === 'object';

      getServer().then(
        function getServerSuccess(server) {
          $scope.sanSource = server;
        },
        evalAJAX.errorMessage
      ).finally(
        function getServerFinally() {
          $scope.sanReady = true;
        }
      );

    }

    /**
     * handles redirecting user to the proper place on cancel or submit
     */
    function redirect() {
      StateManagementService.goToPreviousState('sources-new');
    }

    /**
     * This proto requires some properties to have duplicate values,
     * when the form exposed versions are updated by the user this
     * function will be called via ng-change, and all such properties
     * will be synced as appropriate
     */
    $scope.syncDuplicateProperties = function syncDuplicateProperties() {
      $scope.sanSource.entity.pureEntity.hostType =
        $scope.sanSource.entityInfo.hostType;
      $scope.sanSource.entity.pureEntity.name =
        $scope.sanSource.entityInfo.endpoint;
    };

    /**
     * Exit back to list view
     *
     * @method     cancel
     */
    $scope.cancel = function cancel() {

      if ($scope.inModal) {
        $uibModalInstance.dismiss('user.cancel');
        return;
      }

      redirect();
    };

    /**
     * handle form submission
     *
     * @param      {object}  form    the angular form object being submitted
     * @return     {object|undefined}  if in modal and form valid, returns
     *                                 $uibModalInstance.close otherwise
     *                                 undefined
     */
    $scope.submitForm = function submitForm(form) {

      var actionFn = $state.params.id ?
        SourceService.updateSource : SourceService.createSource;

      if (form.$invalid) {
        return;
      }

      $scope.submitting = true;

      // source side dedup is not currently supported for Windows servers.
      // remove the property for Windows servers
      if ($scope.sanSource.entityInfo.hostType === 1) {
        $scope.sanSource.entityInfo.sourceSideDedupEnabled = undefined;
      }

      actionFn($scope.sanSource).then(
        function actionFnSuccess(source) {

          if ($scope.inModal) {
            return $uibModalInstance.close(source);
          }

          cMessage.success({
            textKey: $state.params.id ?
              'san.success.update' : 'san.success.create',
          });

          redirect();

        },
        evalAJAX.errorMessage
      ).finally(
        function actionFnFinally() {
          $scope.submitting = false;
        }
      );

    };

    /**
     * Gets the Windows Physical Servers object, either via API call or copying
     * the default object for new registration... TODO: update this description
     * if its not accurate
     *
     * @return     {object}  promise. Resolves with vCenter object or rejects
     *                       with raw server response
     */
    function getServer() {
      var deferred = $q.defer();
      var params;

      if ($state.params.id) {
        // this is an edit, we need to get
        // the source form the API

        params = {
          onlyReturnOneLevel: true,
          entityId: [$state.params.id]
        };

        SourceService.getSources(params).then(
          function getSourceSuccess(resp) {

            var entityHierarchy = resp.entityHierarchy;

            // it is necessary to rebuild/rearrange the server object, as the
            // backend response structure does not match up properly with the
            // necessary create/update object structure.
            var theServer = {
              entity:
                entityHierarchy.entity,
              entityInfo:
                entityHierarchy.registeredEntityInfo.connectorParams,
              throttlingPolicy:
                entityHierarchy.registeredEntityInfo.throttlingPolicy || {},
            };

            theServer.entityInfo.sourceSideDedupEnabled =
              entityHierarchy.registeredEntityInfo.sourceSideDedupEnabled;

            deferred.resolve(theServer);
          },
          deferred.reject
        );

      } else {
        // this is a new registration
        deferred.resolve(angular.copy(defaultServerConfig));
      }

      return deferred.promise;
    }

    // kick things off
    activate();
  }

}(angular));
