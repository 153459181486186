<div class="object-name">
  <cog-icon [shape]="node.protectionSource | objectIcon : { isProtected: isNodeProtected }">
  </cog-icon>
  <h4 class="title">{{node.protectionSource.name}}</h4>
</div>

<ul class="object-info-list" *ngIf="isDatabase || isAagDatabase">
  <!-- Logical Size -->
  <li *ngIf="node.logicalSize" class="source-meta-line-item">
    <span class="source-meta-label">{{'databaseSize' | translate}}</span>
    {{node.logicalSize | byteSize}}
  </li>

  <!-- SQL Database State -->
  <li *ngIf="sqlSource.sqlServerDbState as dbState"
    class="source-meta-line-item">
    <span class="source-meta-label">{{'state' | translate}}</span>
    <span [ngClass]="{
      'status-critical': sqlSource.sqlServerDbState === 'kOffline',
      'status-ok': sqlSource.sqlServerDbState === 'kOnline'
      }"> {{'enum.sqlDatabaseState.' + dbState | translate}} </span>
  </li>

  <!-- Available for Backup -->
  <li class="source-meta-line-item">
    <span class="source-meta-label">{{'availableForBackup' | translate}}</span>
    {{sqlSource.IsAvailableForVssBackup | boolLabel}}
  </li>

  <!-- SQL Recovery model -->
  <li *ngIf="sqlSource.sqlServerDbState"
    class="source-meta-line-item">
    <span class="source-meta-label">{{'recoveryModel' | translate}}</span>
    {{(sqlSource.recoveryModel ?
      'enum.sqlRecoveryModel.' + sqlSource.recoveryModel :
      'enum.sqlRecoveryModel.kSimpleRecoveryModel') | translate}}
  </li>

  <!-- SQL FILESTREAM database -->
  <li class="source-meta-line-item">
    <span class="source-meta-label">{{'filestream' | translate}}</span>
    {{isFileStreamDb | boolLabel}}
  </li>

  <!-- AAG Name -->
  <li *ngIf="sqlSource.dbAagName" class="source-meta-line-item">
    <span class="source-meta-label">{{'aagName' | translate}}</span>
    {{sqlSource.dbAagName}}
  </li>

  <!-- SQL Creation Date -->
  <li *ngIf="sqlSource.createdTimestamp"
    class="source-meta-line-item">
    <span class="source-meta-label">{{'creationDate' | translate}}</span>
    {{sqlSource.createdTimestamp | cohDate}}
  </li>

  <!-- TDE encrypted -->
  <li class="source-meta-line-item">
    <span class="source-meta-label">{{'isEncrypted' | translate}}</span>
    {{sqlSource.isEncrypted | boolLabel}}
  </li>
</ul>

<coh-protection-status-meta class="margin-top-lg" [node]="node"></coh-protection-status-meta>