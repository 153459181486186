import { FilterFieldOption } from '../constants';
import { getCombinedUniqueString } from './object-search.utils';

/**
 * Generates list of excluded tag names for the field in question by comparing the currently selected filters. It takes
 * care of duplicate tags values.
 *
 * @param currExcludedTags current list of excluded tags
 * @param selectedValues list of selected filter values for the field in the question
 * @param filterOptions all available filter options for the field in the question
 * @param tagNameMap key to tagName mapping for the field
 * @returns consolidated list of tags that need to be passed to the API
 */
export function getExcludedTagNames<T extends string | number | symbol>(
  currExcludedTags: string[],
  selectedValues: string[],
  filterOptions: FilterFieldOption[],
  tagNameMap: Record<T, string>,
): string[] {
  // first remove all the excluded tags for the field
  const excludedTagNames = currExcludedTags.filter((tagName) => {
    const isFieldTag = filterOptions.some((opt) => tagNameMap[opt.key] === tagName);
    return !isFieldTag;
  });

  // generate list of tags that need to be excluded
  const excludedFieldTags = selectedValues.length > 0
    ? filterOptions
      .filter((opt) => !selectedValues.includes(opt.key))
      .map(opt => tagNameMap[opt.key])
    : [];

  // generate the combined excluded tag name list
  return excludedFieldTags.length ? [
    getCombinedUniqueString(
      excludedTagNames,
      ...excludedFieldTags
    ),
  ] : excludedTagNames;
}
