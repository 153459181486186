import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SearchServiceApi } from '@cohesity/api/v2';
import { FiltersComponent, ValueFilterSelection } from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseObjectFilterComponent } from './../base-object-filter';

/**
 * This is a reusable filter that can be used to filter for objects. It is asynchronous.
 */
@Component({
  selector: 'coh-object-filter',
  templateUrl: './object-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectFilterComponent extends BaseObjectFilterComponent<SearchServiceApi.SearchProtectedObjectsParams> {
  /**
   * The name of the property containing the source id.
   */
  @Input() property = 'object';

  /**
   * The search query is updated whenever the user enters a search value.
   */
  searchString: string;

  constructor(private searchService: SearchServiceApi, private translate: TranslateService, filters: FiltersComponent) {
    super(filters);
  }

  /**
   * Callback for when the user enters a search query. Set the search query and fetch matching objects
   *
   *
   * @param searchString  The search string.
   */
  search(searchString: string) {
    this.searchString = searchString;
    this.fetchObjects();
  }

  /**
   * Fetch protection sources and map to filter values.
   */
  getFilterValues(): Observable<ValueFilterSelection[]> {
    if (!this.searchString) {
      return of([]);
    }
    const params = {
      ...this.requestParams,
      searchString: this.searchString,
    };

    return this.searchService.SearchProtectedObjects(params).pipe(
      map(response => response.objects || []),
      map(response =>
        response.map(object => ({
          label: object.name,
          value: object.id,
        }))
      )
    );
  }
}
