<h2 mat-dialog-title class="no-margin">{{data.title | translate}}</h2>
<h3 *ngIf="data.subtitle">{{data.subtitle | translate}}</h3>
<mat-dialog-content>
  <cog-banner *ngIf="noClustersAvailable" status="critical" class="margin-bottom">
      {{ 'noClustersAvailable' | translate }}
  </cog-banner>
  <cog-filters #filters>
    <cog-search-property-filter
      cogDataId="cluster-name"
      *cogFilterDef="let filter;
        key: 'name';
        label: 'cluster' | translate;
        quickFilter: true;
        filterType: 'searchProperty'"
      [filter]="filter"
      [placeholder]="'cluster' | translate">
    </cog-search-property-filter>
  </cog-filters>
  <cog-table name="cluster-table"
    [source]="data.availableClusters"
    [selection]="selection"
    [persistSelection]="true"
    [canSelectRowFn]="canSelect"
    [filters]="filters.filterValues$ | async">
    <table mat-table>

      <ng-container matColumnDef="name" matSort>
        <th mat-header-cell mat-sort-header="name" *matHeaderCellDef>
          {{ 'cluster' | translate }}
        </th>
        <td mat-cell *matCellDef="let row;">
          <div class="cog-table-object">
            <cog-icon shape="helix:cluster" size="md"></cog-icon>
            <div class="cog-table-object-body">
              <div class="flex-truncate-wrapper">
                  {{row.name}}
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="connectedToCluster">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'heliosStatus' | translate }}
        </th>
        <td mat-cell *matCellDef="let row;">
          <div class="cog-table-object">
            <cog-icon [shape]="row.connectedToCluster ? 'helix:connected!success' : 'helix:disconnected!critical'"
              size="md">
            </cog-icon>
            <div class="cog-table-object-body">
              {{ (row.connectedToCluster ? 'connected' : 'disconnected') | translate }}
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['name', 'connectedToCluster']"></tr>
      <tr mat-row *matRowDef="let row; columns: ['name', 'connectedToCluster']"></tr>
    </table>
  </cog-table>
</mat-dialog-content>

<div mat-dialog-actions align="end">
  <button
    mat-button
    mat-dialog-close
    cogCancelButton>
    {{'cancel' | translate}}
  </button>

  <button mat-flat-button
    cogDataId="continue-btn"
    cdkFocusInitial
    cogSubmitButton
    [disabled]="noClustersAvailable"
    [mat-dialog-close]="selection.selected[0]?.clusterId">
    {{ 'select' | translate }}
  </button>
</div>
