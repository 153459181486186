;(function(angular, undefined) {
  'use strict';

  angular
    .module('C')
    .service('FailedObjectsUtil', failedObjectsUtilFn);

  function failedObjectsUtilFn($parse) {
    var FailedObjectsUtil = {
      calculateSummaryStats: calculateSummaryStats,
      filterSourceGroups: filterSourceGroups,
      groupByRegisteredSource: groupByRegisteredSource,
      groupObjects: groupObjects,
      objectTypeStats: objectTypeStats,
    };

    /**
     * Aggregates data from all environment types to be
     * displayed in summary container
     *
     * @param  {object} objectTypes contains data for each environment type
     *
     * @return {object}             aggregated data
     */
    function calculateSummaryStats(objectTypes) {
      var stats = {
        totalSources: 0,
        totalObjects: 0,
        totalFailedObjects: 0,
      };

      for (var item in objectTypes) {
        stats.totalSources += objectTypes[item].sourcesCount || 0;
        stats.totalObjects += objectTypes[item].totalObjects || 0;
        stats.totalFailedObjects += objectTypes[item].totalFailedObjects || 0;
      }

      return stats;
    }

    /**
     * Deletes a sourceGroup that does not contain any registeredSources
     * with a lastRunStatus of 'kError'
     *
     * @param  {object} sourceGroups objects grouped by enviroment type
     *
     * @return {object}              only objects that contain a source with
     *                               lastRunStatus of 'kError'
     */
    function filterSourceGroups(sourceGroups) {
      var sourceGroupsCopy = angular.extend({}, sourceGroups);
      var sourceKeys = Object.keys(sourceGroups);

      sourceKeys.forEach(function loopSourceKeys(source) {
        var hasError = sourceGroupsCopy[source].some(
          function findError(value) {
            return value.lastRunStatus === 'kError';
        });

        if (!hasError) {
          sourceGroupsCopy[source] = undefined;
        }
      });

      return sourceGroupsCopy;
    }

    /**
     * Creates registeredSource subgroups inside each environment type group.
     *
     * @param  {object} sourceGroups an object of sources grouped by
     *                               environment type
     *
     * @param  {string} prop         the key / property we want to group by
     *
     * @return {object}              an object grouped by environment with
     *                               registered source subgroups ex:
     *                               {
     *                                 kVMware: {
     *                                   sv4-eng-cohesity: {}
     *                                 }
     *                               }
     */
    function groupByRegisteredSource(sourceGroups, prop) {
      var sourceKeys = Object.keys(sourceGroups);
      var groups = {};

      sourceKeys.forEach(function loopSources(source) {
        if (sourceGroups[source]) {
          groups[source] = sourceGroups[source].reduce(
            function groupSources(accumulator, value) {
              var key = value[prop];

              if (!accumulator[key]) {
                accumulator[key] = [];
                accumulator[key].vmCount = 1;

                if (value.lastRunStatus === 'kError') {
                  accumulator[key].push(value);
                }
              } else {
                if (value.lastRunStatus === 'kError') {
                  accumulator[key].push(value);
                }

                accumulator[key].vmCount++;
              }

              return accumulator;
          }, {});
        }
      });

      return groups;
    }

    /**
     * group our 'Object Types' by environment
     *
     * @param  {array} objects  an array of 'Object Types' that need to be
     *                          grouped by environment.
     *
     * @param  {string} prop    key / property to group by
     *
     * @return {Object}         an object grouped by environment type ex:
     *                          {
     *                            kVMware: [],
     *                            kNetApp: [],
     *                          }
     */
    function groupObjects(objects, prop) {
      var getter = $parse(prop);

      return objects.reduce(function groupTypes(accumulator, item) {
        var key = getter(item);

        accumulator[key] = accumulator[key] || [];
        accumulator[key].push(item);

        return accumulator;
      }, {});
    }

    /**
     * aggregate stats for each 'Object Type'
     *
     * @param  {object} groupData an object grouped by environment type
     *
     * @return {object}           Object organized by environment type
     *                            containing 'Object Type' stats ex:
     *                            {
     *                              kVMware: {
     *                                totalFailedObjects: 0,
     *                                totalObjects: 0,
     *                                sourcesCount: 0
     *                              }
     *                            }
     */
    function objectTypeStats(groupData) {
      var groupKeys = Object.keys(groupData);
      var typeStats = {};

      groupKeys.forEach(function loopGroupKeys(key, index) {
        typeStats[key] = {};

        if (groupData[key].length) {
          var sourcesMap = {};
          typeStats[key].totalFailedObjects = 0;

          groupData[key].forEach(function loopGroupData(value, index) {
            // For the sake of stats, we want to treat each Physical as its own
            // object which means we do not want to use "Physical Servers" as
            // the hash key.
            var statsSource =
              value.protectionSource.environment === 'kPhysical' ?
              value.protectionSource.name : value.registeredSource;

            if (!sourcesMap[statsSource]) {
              sourcesMap[statsSource] = statsSource;
            }

            if (value.lastRunStatus === 'kError') {
              typeStats[key].totalFailedObjects++;
            }
          });

          typeStats[key].totalObjects = groupData[key].length;
          typeStats[key].sourcesCount = Object.keys(sourcesMap).length;
        }
      });

      return typeStats;
    }

    return FailedObjectsUtil;
  }

})(angular);
