/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { KmsConfigurationResponse } from '../models/kms-configuration-response';
import { KmsCreateRequestParameters } from '../models/kms-create-request-parameters';
@Injectable({
  providedIn: 'root',
})
class KmsConfigurationService extends __BaseService {
  static readonly CreateKmsConfigPath = '/public/kmsConfig';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Create a KMS config.
   *
   * Returns the created KMS config.
   * @param params The `KmsConfigurationService.CreateKmsConfigParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Response after KMS has been created.
   */
  CreateKmsConfigResponse(params: KmsConfigurationService.CreateKmsConfigParams): __Observable<__StrictHttpResponse<KmsConfigurationResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/kmsConfig`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<KmsConfigurationResponse>;
      })
    );
  }
  /**
   * Create a KMS config.
   *
   * Returns the created KMS config.
   * @param params The `KmsConfigurationService.CreateKmsConfigParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Response after KMS has been created.
   */
  CreateKmsConfig(params: KmsConfigurationService.CreateKmsConfigParams): __Observable<KmsConfigurationResponse> {
    return this.CreateKmsConfigResponse(params).pipe(
      __map(_r => _r.body as KmsConfigurationResponse)
    );
  }
}

module KmsConfigurationService {

  /**
   * Parameters for CreateKmsConfig
   */
  export interface CreateKmsConfigParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;
    body?: KmsCreateRequestParameters;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { KmsConfigurationService }
