import { Site } from '@cohesity/api/kepler';
import { decorateInterface } from '@cohesity/utils';

/**
 * Decorates a Site interface.
 */
export class DecoratedSite extends decorateInterface<Site>() {
  /**
   * An cog-icon shape for this site, based on its environment.
   */
  readonly environmentIcon: string;

  constructor(site: Site) {
    super(site);

    this.environmentIcon = this.getEnvironmentIcon(site);
  }

  /**
   * Returns the environment icon for the site.
   *
   * @param site The site object.
   * @returns The appropriate cog-icon compatible shape.
   */
  private getEnvironmentIcon(site: Site): string {
    switch (site.type) {
      case 'AWS':
        return 'helix:cohesity-cloud';
      case 'OnPrem':
        return 'helix:cluster';
      case 'Saas':
        return 'helix:saas-connection';
      default:
        return '';
    }
  }
}
