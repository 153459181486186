import { AutoTableColumnConfig } from '@cohesity/helix';
import { ReportColumnConfigs } from '../common-report-items/custom-reports.service';

/**
 * This is the layout config for the storage consumption by protection group report.
 */
export const commonReportConfigItems = {
  storageConsumption: {
    glanceBar: [
      {
        title: 'reporting.storageConsumedWithResiliency',
        translate: true,
        measurement: {
          valueKey: 'sumScResiliencyBytes',
          dataType: 'bytes',
        },
      },
      {
        title: 'reporting.growthOfStorageConsumedWithResiliency',
        translate: true,
        measurement: {
          valueKey: 'sumScResiliencyBytesGrowth',
          dataType: 'bytes',
        }
      },
    ],
    table: [
      {
        title: 'sourceDataRetainedEnd',
        translate: true,
        hasSeparator: true,
        key: 'dataIngestedRetainedBytes',
        primary: {
          key: 'dataIngestedRetainedBytes',
          dataType: 'bytes',
        },
      },
      {
        title: 'sourceDataRetainedGrowth',
        translate: true,
        key: 'dataIngestedRetainedBytesGrowth',
        primary: {
          key: 'dataIngestedRetainedBytesGrowth',
          dataType: 'bytes',
        },
      },
      {
        title: 'sourceDataRetainedDailyGrowthRate',
        translate: true,
        key: 'dataIngestedRetainedDailyGrowthRate',
        primary: {
          key: 'dataIngestedRetainedDailyGrowthRate',
          dataType: 'bytes',
        },
      },
      {
        title: 'sourceDataRetainedDailyGrowthPercentage',
        translate: true,
        key: 'dataIngestedRetainedDailyGrowthPercent',
        primary: {
          key: 'dataIngestedRetainedDailyGrowthPercent',
          dataType: 'percent',
        },
      },
      {
        title: 'dataReductionEnd',
        translate: true,
        key: 'dataReduction',
        primary: {
          key: 'dataReduction',
          dataType: 'number',
          renderParams: {
            suffix: 'x',
            format: '1.0-1',
          },
        },
      },
      {
        title: 'deduplicationRatioEnd',
        translate: true,
        key: 'dedupRatio',
        primary: {
          key: 'dedupRatio',
          dataType: 'number',
          renderParams: {
            suffix: 'x',
            format: '1.0-1',
          },
        },
      },
      {
        title: 'compressionRatioEnd',
        translate: true,
        key: 'compressionRatio',
        primary: {
          key: 'compressionRatio',
          dataType: 'number',
          renderParams: {
            suffix: 'x',
            format: '1.0-1',
          },
        },
      },
      {
        title: 'storageConsumedForRetainedDataEnd',
        translate: true,
        hasSeparator: true,
        key: 'scRetainedBytes',
        primary: {
          key: 'scRetainedBytes',
          dataType: 'bytes',
        },
      },
      {
        title: 'storageConsumedForRetainedDataGrowth',
        translate: true,
        key: 'scRetainedBytesGrowth',
        primary: {
          key: 'scRetainedBytesGrowth',
          dataType: 'bytes',
        },
      },
      {
        title: 'storageConsumedForRetainedDataDailyGrowthRate',
        translate: true,
        key: 'scRetainedDailyGrowthRate',
        primary: {
          key: 'scRetainedDailyGrowthRate',
          dataType: 'bytes',
        },
      },
      {
        title: 'storageConsumedForRetainedDataDailyGrowthPercentage',
        translate: true,
        key: 'scRetainedDailyGrowthPercent',
        primary: {
          key: 'scRetainedDailyGrowthPercent',
          dataType: 'percent',
        },
      },
      {
        title: 'resiliencyImpactEnd',
        translate: true,
        key: 'resiliencyImpact',
        primary: {
          key: 'resiliencyImpact',
          dataType: 'number',
          renderParams: {
            suffix: 'x',
            format: '1.0-1',
          },
        },
      },
      {
        title: 'storageConsumedWithResiliencyEnd',
        translate: true,
        hasSeparator: true,
        key: 'scResiliencyBytes',
        primary: {
          key: 'scResiliencyBytes',
          dataType: 'bytes',
        },
      },
      {
        title: 'storageConsumedWithResiliencyGrowth',
        translate: true,
        key: 'scResiliencyBytesGrowth',
        primary: {
          key: 'scResiliencyBytesGrowth',
          dataType: 'bytes',
        },
      },
      {
        title: 'storageConsumedWithResiliencyDailyGrowthRate',
        translate: true,
        key: 'scResiliencyDailyGrowthRate',
        primary: {
          key: 'scResiliencyDailyGrowthRate',
          dataType: 'bytes',
        },
      },
      {
        title: 'storageConsumedWithResiliencyDailyGrowthPercentage',
        translate: true,
        key: 'scResiliencyDailyGrowthPercent',
        primary: {
          key: 'scResiliencyDailyGrowthPercent',
          dataType: 'percent',
        },
      },
    ],
  }
};

/**
 * This is metadata needed to determine available chart customizations.
 */
export const storageConsumptionCommonColumns: ReportColumnConfigs = {
  dataIngestedRetainedBytes: {
    aggs: ['sum', 'min', 'max', 'avg'],
    dataType: 'bytes',
  },
  dataIngestedRetainedBytesGrowth: {
    aggs: ['sum', 'min', 'max', 'avg'],
    dataType: 'bytes',
  },
  dataIngestedRetainedDailyGrowthRate: {
    aggs: ['min', 'max', 'avg'],
    dataType: 'bytes',
  },
  dataIngestedRetainedDailyGrowthPercent: {
    aggs: ['min', 'max', 'avg'],
    dataType: 'bytes',
  },
  dataReduction: {
    aggs: ['min', 'max', 'avg'],
    dataType: 'number',
  },
  dedupRatio: {
    aggs: ['min', 'max', 'avg'],
    dataType: 'number',
  },
  compressionRatio: {
    aggs: ['min', 'max', 'avg'],
    dataType: 'number',
  },
  scRetainedBytes: {
    aggs: ['sum', 'min', 'max', 'avg'],
    dataType: 'bytes',
  },
  scRetainedBytesGrowth: {
    aggs: ['sum', 'min', 'max', 'avg'],
    dataType: 'bytes',
  },
  scRetainedDailyGrowthRate: {
    aggs: ['min', 'max', 'avg'],
    dataType: 'bytes',
  },
  scRetainedDailyGrowthPercent: {
    aggs: ['min', 'max', 'avg'],
    dataType: 'bytes',
  },
  resiliencyImpact: {
    aggs: ['min', 'max', 'avg'],
    dataType: 'number',
  },
  scResiliencyBytes: {
    aggs: ['sum', 'min', 'max', 'avg'],
    dataType: 'bytes',
  },
  scResiliencyBytesGrowth: {
    aggs: ['sum', 'min', 'max', 'avg'],
    dataType: 'bytes',
  },
  scResiliencyDailyGrowthRate: {
    aggs: ['min', 'max', 'avg'],
    dataType: 'bytes',
  },
  scResiliencyDailyGrowthPercent: {
    aggs: ['min', 'max', 'avg'],
    dataType: 'bytes',
  },
};

/**
 * Columns to display by default for each storage consumption report table.
 */
const storageConsumptionDisplayColumns = {
  AllStorageConsumptionByCluster:
    ['dataReduction', 'scResiliencyBytes', 'scResiliencyBytesGrowth', 'scResiliencyDailyGrowthRate'],
  AllStorageConsumptionByProtectionGroups:
    ['scResiliencyBytes', 'scResiliencyBytesGrowth', 'scResiliencyDailyGrowthRate'],
  AllStorageConsumptionByOrganizations:
    ['dataIngestedRetainedBytes', 'dataReduction', 'scRetainedBytes', 'scResiliencyBytes'],
  AllStorageConsumptionByStorageDomains:
    ['dataIngestedRetainedBytes', 'scResiliencyBytes', 'scResiliencyBytesGrowth', 'scResiliencyDailyGrowthRate'],
  AllStorageConsumptionByViews:
    ['dataIngestedRetainedBytes', 'scRetainedBytesGrowth', 'scRetainedDailyGrowthRate', 'scResiliencyBytes'],
};

/**
 * Gets the common table columns for storage consumption report table. Note that the
 * columns are the same but the default display can be different for different table.
 *
 * @param    componentName  Table component name.
 * @returns  table configuration columns.
 */
export function getStorageConsumptionColumns(componentName: string): AutoTableColumnConfig[] {
  return commonReportConfigItems.storageConsumption.table.map(column => ({
    ...column,
    enabled: storageConsumptionDisplayColumns[componentName].includes(column.key),
  }));
}
