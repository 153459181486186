import { Component, Input, OnInit } from '@angular/core';
import { ProtectionGroup, PolicyServiceApi, ProtectionPolicyResponseWithPagination } from '@cohesity/api/v2';
import { AutoDestroyable } from '@cohesity/utils';

@Component({
  selector: 'coh-sd-protection-groups-list',
  templateUrl: './protection-groups-list.component.html',
  styleUrls: ['../delete-storage-domain-stepper.scss'],
})
export class ProtectionGroupsListComponent extends AutoDestroyable implements OnInit {

  @Input() protectionGroupList: ProtectionGroup[] = [];

  /**
   * Map policy ID to policy name.
   */
  policyMap: {[index: string]: string } = {};

  constructor(
    private policyService: PolicyServiceApi) {
    super();
  }

  /**
   * Init the component.
   */
  ngOnInit(): void {
    this.getPolicies();
  }

  /**
   * Get all policies.
   */
  getPolicies() {
    this.policyService.GetProtectionPolicies({})
    .pipe(this.untilDestroy())
    .subscribe((rsp: ProtectionPolicyResponseWithPagination) => {
      if (rsp.policies) {
        rsp.policies.forEach(policy => this.policyMap[policy.id] = policy.name);
      }
    });
  }
}
