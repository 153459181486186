// Component: Contact Cohesity

;(function(angular, undefined) {
  'use strict';

  angular.module('C.appsManagement')
    .controller('contactCohesityCtrl', contactCohesityCtrlFn)
    .component('contactCohesity', {
      templateUrl:
        'app/apps-management/app-store/contact-cohesity/contact-cohesity.html',
      controller: 'contactCohesityCtrl'
    });


  function contactCohesityCtrlFn() {
    var $ctrl = this;
  }
})(angular);
