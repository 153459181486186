import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SnackBarService } from '@cohesity/helix';
import { Controls, NgxSubFormComponent, takeUntilDestroyed } from 'ngx-sub-form';
import { finalize } from 'rxjs/operators';

import { LinuxUserService } from '../../linux-user.service';
import { UpdatePasswordDialogData, UpdateSupportPasswordFormModel, PasswordRulesPopoverData } from '../../models';
import { PasswordRulesPopoverComponent } from '../password-rules-popover-component/password-rules-popover.component';

/**
 * Update linux user password dialog component.
 */
@Component({
  selector: 'coh-update-password',
  templateUrl: './update-password-dialog.component.html',
  styleUrls: ['./update-password-dialog.component.scss']
})
export class UpdateLinuxUserPasswordDialogComponent extends NgxSubFormComponent<UpdateSupportPasswordFormModel> {
  /**
   * Linux username of user to update credentials.
   */
  userName: string;

  /**
   * True if update/fetch is in progress.
   */
  loading = false;

  /**
   * Popover component which is passed to change password form component.
   */
  popoverComponent = PasswordRulesPopoverComponent;

  /**
   * Popover context data.
   */
  popoverData: PasswordRulesPopoverData;

  constructor(
    public dialogRef: MatDialogRef<UpdateLinuxUserPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: UpdatePasswordDialogData,
    private linuxUserService: LinuxUserService,
    private snackBarService: SnackBarService) {
    super();
    this.userName = this.dialogData.username;
    this.popoverData = {isNewPassword: !dialogData.isPasswordSet};

    // Disable current password form control if password is not set already.
    if (!dialogData.isPasswordSet) {
      this.formGroupControls.currentPassword.disable();
    }
  }

  /**
   * Make api call to update linux user password.
   * Open snackbar on error and close dialog on success.
   */
  updatePassword() {
    if (!this.formGroup.valid) {
      return;
    }
    this.loading = true;
    this.linuxUserService.updateLinuxUserCredentials(this.userName,
      this.formGroupValues.newPassword.confirmPassword,
      this.formGroupValues.currentPassword)
      .pipe(
        takeUntilDestroyed(this),
        finalize(() => this.loading = false))
      .subscribe(
        () => this.dialogRef.close('success'),
        (err: HttpErrorResponse) => this.snackBarService.open(err.error.message, 'error'));
  }

  /**
   * Defines the form controls for updating linux user password.
   *
   * @return Form controls for update password.
   */
  protected getFormControls(): Controls<UpdateSupportPasswordFormModel> {
    return {
      currentPassword: new UntypedFormControl(null, [Validators.required]),
      newPassword: new UntypedFormControl(null, Validators.required)
    };
  }

}
