import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';

/**
 * Object key type definiton.
 */
interface ObjectKeyPattern {
  name: string;
  value: string;
  descKey?: string;
}

/**
 * Object Key pattern selector Component.
 *
 * @example
 *   <coh-object-key-pattern-selector></coh-object-key-pattern-selector>
 */
@Component({
  selector: 'coh-object-key-pattern-selector',
  templateUrl: './object-key-pattern-selector.component.html',
  styleUrls: ['./object-key-pattern-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ObjectKeyPatternSelectorComponent implements OnInit {
  /**
   * Form control options.
   */
  @Input() control: UntypedFormControl;

  /**
   * Is Swift protocol selected.
   */
  @Input() isSwiftOnly: boolean;

  /**
   * Object key pattern value definition.
   */
  objectKeyPattern: UntypedFormGroup;

  /**
   * Object key types definiton.
   */
  objectKeyTypes: ObjectKeyPattern[] = [
    {
      name: 'objectId',
      descKey: 'views.s3KeyPattern.objectId.description',
      value: 'ObjectId',
    },
    {
      name: 'flat',
      descKey: 'views.s3KeyPattern.flat.description',
      value: 'Flat',
    },
    {
      name: 'hierarchical',
      descKey: 'views.s3KeyPattern.hierarchical.description',
      value: 'Hierarchical',
    }
  ];

  /**
   * Flat key types definiton.
   */
  flatKeyTypes: ObjectKeyPattern[] = [
    {
      name: 'random',
      descKey: 'views.s3KeyType.random.description',
      value: 'Random',
    },
    {
      name: 'structured',
      descKey: 'views.s3KeyType.structured.description',
      value: 'Structured',
    },
  ];

  /**
   * Structured key lengths definiton.
   */
  structuredKeyLengths: ObjectKeyPattern[] = [
    {
      name: 'short',
      descKey: 'views.s3KeyLength.short.description',
      value: 'Short',
    },
    {
      name: 'long',
      descKey: 'views.s3KeyLength.long.description',
      value: 'Long',
    }
  ];

  /**
   * Whether Object Snap Tree based S3 view creation is enabled
   * according to feature flag.
   *
   * @return True, if enableS3ObjectSnapTree feature flag is enabled.
   */
  get s3ObjectSnapTreeEnabled(): boolean {
    return flagEnabled(this.irisCtx.irisContext, 'enableS3ObjectSnapTree');
  }

  /**
   * Whether ObjectId option is allowed based on certain flag
   * and view protocol conditions.
   *
   * @return True, if objectId option is allowed.
   */
  get isObjectIdAllowed(): boolean {
    return this.s3ObjectSnapTreeEnabled && !this.isSwiftOnly;
  }

  constructor(private irisCtx: IrisContextService) {}

  /**
   * Init Component
   */
  ngOnInit() {
    // Form intialization
    this.objectKeyPattern = new UntypedFormGroup({
      keyType: new UntypedFormControl(null, Validators.required),
      flatType: new UntypedFormControl(null),
      keyLength: new UntypedFormControl(null),
    });
    this.untransformObjectKeyPattern();

    // Disable object key pattern selection during view edit mode.
    if (this.control.disabled) {
      this.objectKeyPattern.disable();
    } else if (!this.control?.value) {
      // In view create mode set the default value as 'ObjectId'.
      this.control.setValue('ObjectId');
    }
  }

  /**
   * Method called to transform object services mapping configuration.
   */
  transformObjectKeyPattern() {
    let value: string;
    const {keyType, flatType, keyLength} = this.objectKeyPattern.value;

    if (keyType === 'Hierarchical') {
      value = 'Hierarchical';
    } else if (keyType === 'ObjectId') {
      value = 'ObjectId';
    } else if (flatType === 'Random') {
      value = flatType;
    } else {
      value = keyLength;
    }

    // Updating objectServicesMappingConfig formcontrol value
    this.control.setValue(value);
  }

  /**
   * Method called to untransform object services mapping configuration.
   */
  untransformObjectKeyPattern() {
    const value = this.control && this.control.value || 'ObjectId';

    switch (value) {
      case !value:
      case 'Hierarchical':
        // If there is no mapping or it's Hierarchical, then we only have a
        // type property 'Hierarchical'.
        this.objectKeyPattern.patchValue({keyType: 'Hierarchical'});
        break;

      case 'ObjectId':
        // If it's ObjectId, then we only have a type property 'ObjectId'.
        this.objectKeyPattern.patchValue({keyType: 'ObjectId'});
        break;

      case 'Random':
        // Else if it's 'Random', then we have type:'Flat' and a flatType
        // property.
        this.objectKeyPattern.patchValue({
          keyType: 'Flat',
          flatType: 'Random',
        });
        break;

      default:
        // Else we have a type:Flat and flatType:Structured and a keyLength
        // property.
        this.objectKeyPattern.patchValue({
          keyType: 'Flat',
          flatType: 'Structured',
          keyLength: value,
        });
        break;
    }
  }
}
