import {
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { BaseFormFieldDirective, PasswordConfig } from '../../models/index';

/**
 * Render a password field on the UI screen.
 *
 * @example
 * <cog-password-form-field [label]="label" ...></cog-password-form-field>
 */
@Component({
  selector: 'cog-password-form-field',
  templateUrl: './password-form-field.component.html',
  styleUrls: ['./password-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordFormFieldComponent extends BaseFormFieldDirective<PasswordConfig> implements OnChanges {

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config || changes.control) {
      this.control.clearValidators();
      this.control.setValidators([
        this.config?.required ?
          Validators.required : Validators.nullValidator,
      ]);

      this.control.updateValueAndValidity();
    }
  }
}
