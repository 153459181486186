import { inject, Injectable } from '@angular/core';
import { AppName, ClusterConfig, IrisContextService, isIbmBaaSEnabled, isTenantUser } from '@cohesity/iris-core';
import { IS_IBM_AQUA_ENV } from '@cohesity/shared/core';
import { TranslateService } from '@ngx-translate/core';

import { AppServiceConfig, AppServiceType } from '../app-service.config';
import { ClusterManagerNavService } from './cluster-manager-nav.service';
import { IBMClusterManagerNavService } from './ibm-cluster-manager-nav.service';
import { IBMBaasClusterManagerNavService } from './ibm-baas-cluster-manager-nav.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * This service configures the dataprotect application service configuration within iris.
 */
@Injectable({ providedIn: 'root' })
export class ClusterManagerServiceConfig extends AppServiceConfig {
  serviceType: AppServiceType = 'clusterManager';

  appName: AppName = 'clusterManager';

  homeState = 'dashboards';

  get clusterConfigPartial() {
    return {
      name: this.serviceTitle,

      // If user is in this context, they are in "all clusters", or potentially
      // a specific cluster. Treating this as _allClusters for simpler scope
      // restoration and tracking.
      _allClusters: true,
      _cohesityService: true,
      _globalContext: false,
      _nonCluster: true,
      _serviceType: this.serviceType,
    } as Partial<ClusterConfig>;
  }

  // Conditionally suports global search based on logic in AppLayoutComponent.
  supportsGlobalSearch = true;

  // ibm aqua nav provider is determined at build time.
  navProvider = inject(IS_IBM_AQUA_ENV) ? this.ibmAquaNavService : this.navService;

  constructor(
    protected translate: TranslateService,
    private navService: ClusterManagerNavService,
    private irisContextService: IrisContextService,
    private ibmAquaNavService: IBMClusterManagerNavService,
    private ibmBaasNavService: IBMBaasClusterManagerNavService,
  ) {
    super(translate);

    const unsubscribe = new Subject();
    this.irisContextService.irisContext$.pipe(takeUntil(unsubscribe)).subscribe(ctx => {
      // ibmBaaS nav provider is determined at runtime.
      if (isIbmBaaSEnabled(ctx)) {
        this.navProvider = this.ibmBaasNavService;
        this.homeState = isIbmBaaSEnabled(ctx) && isTenantUser(ctx) ? 'dashboards.ibm-baas' : this.homeState;
      }
      // once we get softwareType we can kill the subscription.
      if (ctx.basicClusterInfo.softwareType) {
        unsubscribe.next(true);
      }
    });
  }
}
