import { Injectable } from '@angular/core';
import {
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { IrisContextService } from '@cohesity/iris-core';
import { ToFormGroup } from '@cohesity/shared-forms';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import {
  CreateViewBasicForm,
  CreateViewData,
  CreateViewPageForm,
  CreateViewSettingsForm,
  Workflow,
  WorkflowType,
  WorkflowTypeToFormMap,
} from '../models';
import { transformToCreateViewForm, userCanRenameView } from '../utils';
import { ViewsService } from './views.service';

/**
 * A contextual service for creating forms for the create view page in dialog,
 * template or full page mode.
 *
 * @example
 *  For example, if we are using the forms in the dialog mode, then define the parent form
 *  group like this:
 *
 *  // This will maintain the workflowType all across the descendants of this component.
 *  @Component({ ..., providers: [CreateViewFormService] })
 *
 *  constructor(
 *   @Host() private createViewFormService: CreateViewFormService,
 *  ) { }
 *  ...
 *  formGroup = createViewFormService.getCreateViewFormGroup(createViewData, 'dialog');
 *
 *  and the child form controls (with disabled states already set) would be
 *  directly available as:
 *
 *  createViewFormService.getCreateViewBasicFormControls()
 *  createViewFormService.getCreateViewSettingsFormControls()
 *
 *  Also we can access some publicly exposed utils in the child components like
 *
 *  createViewFormService.isTemplateMode
 *  createViewFormService.workflow
 *
 *  createViewFormService.createViewData - The create view data.
 *  createViewFormService.currentFormValue - The current form value.
 */
@Injectable()
export class CreateViewFormService {
  /**
   * The current workflow in which the form is being used.
   */
  private workflowType: WorkflowType;

  /**
   * The create view data which is used to initialize the form.
   */
  createViewData: CreateViewData;

  /**
   * The create view parent form.
   */
  private createViewFormGroup: FormGroup<ToFormGroup<WorkflowTypeToFormMap[WorkflowType]>>;

  /**
   * This includes creating a new template; creating a view form default template; creating a view directly by visinting
   * the url. Note: This doesn't include creating a view from a custom template.
   */
  isCreateMode: boolean;

  /**
   * The current form value.
   */
  get currentFormValue(): WorkflowTypeToFormMap[WorkflowType] {
    return this.createViewFormGroup.getRawValue();
  }

  /**
   * Check if the page is opened in template mode.
   */
  get isTemplateMode() {
    return this.workflowType === 'template';
  }

  /**
   * The current worflow in which the form is being used.
   */
  get workflow(): Workflow {
    if (!this.isTemplateMode) {
      // If the view is being created using a template. createViewData.id is the
      // template id.
      if (this.createViewData?.id) {
        return 'CreateViewFromTemplate';
      }

      // Otherwise, depending on if viewParams has viewId or not decide if we
      // are creating or editing view.
      return this.createViewData?.viewParams?.viewId ? 'EditView' : 'CreateView';
    }

    // If in template mode, depending on the template id, decide the workflow.
    return this.createViewData?.id ? 'EditTemplate' : 'CreateTemplate';
  }

  constructor(
    private viewsService: ViewsService,
    private irisContextService: IrisContextService,
  ) {}

  /**
   * Initializes the parent and the inner form controls and returns the parent
   * formGroup.
   *
   * @param createViewData The create view data.
   * @param workflowType The workflowType in which the form is being called.
   * @returns The create view form group (parent form group)
   */
  getCreateViewFormGroup<T extends WorkflowType>(
    createViewData: CreateViewData,
    workflowType: T
  ): FormGroup<ToFormGroup<WorkflowTypeToFormMap[T]>> {
    this.workflowType = workflowType;
    this.createViewData = createViewData;

    this.isCreateMode =
      // creating a custom template
      (this.workflow === 'CreateTemplate') ||
      // creating a view from pre-defined template
      (this.workflow === 'CreateViewFromTemplate' && createViewData.defaultTemplateName !== 'Unknown') ||
      // creating a view by directly visting url
      (this.workflowType === 'view' && this.workflow !== 'CreateViewFromTemplate' && createViewData.isEditMode === false);

    const { createViewBasicForm, createViewSettings } = transformToCreateViewForm(
      createViewData,
      this.isTemplateMode,
      this.isCreateMode,
      this.irisContextService.irisContext
    );

    return (this.createViewFormGroup = new UntypedFormGroup({
      createViewBasicForm: new UntypedFormControl(createViewBasicForm),

      // Skip the settings form if we are in dialog mode.
      ...(workflowType !== 'dialog' ? { createViewSettings: new UntypedFormControl(createViewSettings) } : {}),
    }));
  }

  /**
   * Updates the create view form parent group.
   *
   * @param createViewData The create view data.
   */
  updateCreateViewFormGroup(createViewData: CreateViewData) {
    this.createViewData = createViewData;
    const formValue = transformToCreateViewForm(
      createViewData,
      this.isTemplateMode,
      false,
      this.irisContextService.irisContext
    );
    this.createViewFormGroup.setValue(formValue);
  }

  /**
   * Returns the inner create view basic form controls. Also sets the disabled
   * states to the form controls depending on the current workflow.
   *
   * @see {CreateViewBasicFormComponent}
   * @param createViewBasicForm The default value for the form
   */
  getCreateViewBasicFormControls(): ToFormGroup<CreateViewBasicForm> {
    // Disable the name if in edit template mode or if in Edit View mode, then check for datalock conditions.
    const nameControlValue = {
      value: null,
      disabled: this.workflow === 'EditTemplate' ||
        this.workflow === 'EditView' && !userCanRenameView(this.createViewData.viewParams),
    };

    // Disable category when we are creating a view from a template.
    const categoryControlValue = {
      value: null,
      disabled: this.workflow === 'CreateViewFromTemplate',
    };

    // Disable when the data lock has expired. This field is only visible for
    // DSO role. Any other role cannot access this to edit a DataLock View.
    const dataLockExpiryUsecsValue = {
      value: null,
      disabled: this.workflow === 'EditView' && this.createViewData?.viewParams?._dataLockExpired,
    };

    // Disable the protocol if we are editing a view and category is
    // 'ObjectServices'.
    const protocolAccessControlValue = {
      value: null,
      disabled: this.workflow === 'EditView' && this.createViewData?.viewParams?.category === 'ObjectServices',
    };

    // Disable objectServicesMappingConfig if in edit view mode.
    const objectServicesMappingConfigControlValue = {
      value: null,
      disabled: this.workflow === 'EditView',
    };

    // If the view is being created from a Storage Domain or if we are in edit
    // view mode, disable the SD control.
    const storageDomainIdControlValue = {
      value: null,
      disabled: this.createViewData?.restrictViewBoxId || this.workflow === 'EditView',
    };
    const storageDomainIdControl = new UntypedFormControl(storageDomainIdControlValue, Validators.required);
    const protocolAccessControl = new UntypedFormControl(protocolAccessControlValue, Validators.required);

    // Initialize the inner form controls using values.
    return {
      name: new UntypedFormControl(nameControlValue, Validators.required),
      category: new UntypedFormControl(categoryControlValue, Validators.required),
      dataLockExpiryUsecs: new UntypedFormControl(dataLockExpiryUsecsValue),
      storageDomainId: storageDomainIdControl,
      protocolAccess: protocolAccessControl,
      objectServicesMappingConfig: new UntypedFormControl(objectServicesMappingConfigControlValue),
      swiftConfig: new UntypedFormControl(null),
      mostSecureSettings: new UntypedFormControl(null),
      isExternallyTriggeredBackupTarget: new UntypedFormControl(),
      intent: new UntypedFormControl()
    };
  }

  /**
   * Initialize the inner create view settings form controls. Also sets the disabled
   * states to the form controls depending on the current workflow.
   *
   * @see {CreateViewSettingsListComponent}
   */
  getCreateViewSettingsFormControls(): ToFormGroup<CreateViewSettingsForm> {
    const editViewWorkflow = this.workflow === 'EditView';

    // Disable caseInsensitiveNamesEnabled if in edit view/template mode.
    const caseInsensitiveNamesEnabledValue = {
      value: null,
      disabled: editViewWorkflow,
    };

    // Disable s3FolderSupportEnabled if in edit view mode.
    const s3FolderSupportEnabledValue = {
      value: null,
      disabled: editViewWorkflow,
    };

    // Disable the file lock config using the service.
    const fileLockConfigValue = {
      value: null,
      disabled: !this.createViewData?.viewParams?.fileLockConfig?.coexistingLockMode &&
        this.viewsService.isFileDataLockDisabled(this.createViewData.viewParams ?? {}, editViewWorkflow),
    };

    return {
      abacServerConfig: new UntypedFormControl(null),
      aclConfig: new UntypedFormControl(null),
      antivirusScanConfig: new UntypedFormControl(null),
      bucketPolicy: new UntypedFormControl(null),
      caseInsensitiveNamesEnabled: new UntypedFormControl(caseInsensitiveNamesEnabledValue),
      description: new UntypedFormControl(null),
      enableFilerAuditLogging: new UntypedFormControl(null),
      fileExtensionFilter: new UntypedFormControl(),
      fileLockConfig: new UntypedFormControl(fileLockConfigValue),
      filerLifecycleManagement: new UntypedFormControl(null),
      lifecycleManagement: new UntypedFormControl(null),
      logicalQuota: new UntypedFormControl(null),
      ownerInfo: new UntypedFormControl(null),
      qosPolicySettings: new UntypedFormGroup({
        enableAppAwarePrefetching: new UntypedFormControl(null),
        enableAppAwareUptiering: new UntypedFormControl(null),
        qos: new UntypedFormControl(null, Validators.required),
        viewPinningConfig: new UntypedFormControl(),
      }),
      s3FolderSupportEnabled: new UntypedFormControl(s3FolderSupportEnabledValue),
      securitySettings: new UntypedFormControl(null),
      selfServiceSnapshotConfig: new UntypedFormControl(null),
      smbOptions: new UntypedFormControl(null),
      storagePolicyOverride: new UntypedFormControl(null),
      versioning: new UntypedFormControl(null),
      viewProtectionConfig: new UntypedFormControl(null),
      nfsConfig: new UntypedFormControl(null),
    };
  }

  /**
   * Get the form control listener for each control of createViewBasicForm and
   * createViewSettings using the parent form.
   *
   * @param formControlName The formControlName
   * @returns An observable for value changes for the given form.
   */
  getFormControlValueChangeListener<T extends keyof (CreateViewBasicForm & CreateViewSettingsForm)>(formControlName: T):
    Observable<(CreateViewBasicForm & CreateViewSettingsForm)[T]> {
    return this.createViewFormGroup.valueChanges
      .pipe(
        // Check if the name even exists in the form.
        filter((formValue: CreateViewPageForm) =>
          formControlName in formValue.createViewBasicForm || formControlName in formValue.createViewSettings
        ),

        // Map the correct value.
        map(formValue =>
          formControlName in formValue.createViewBasicForm ?
            formValue.createViewBasicForm[formControlName as keyof CreateViewBasicForm] :
            formValue.createViewSettings[formControlName as keyof CreateViewSettingsForm]
        ),
      );
  }
}
