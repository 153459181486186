// Component: protection details

;(function(angular, undefined) {
  'user strict';

  var componentConfig = {
    controller: 'ProtectionDetailsController',
    templateUrl: 'app/dashboard/sql/detail/summary/protection-details.html',
    bindings: {
      job: '<',
      latestSnapshot: '<',
      loaded: '<',
      policy: '<',
      snapshotSummary: '<',
    },
  };

  angular.module('C.dbDetails')
    .controller('ProtectionDetailsController', ProtectionDetailsCtrlFn)
    .component('protectionDetails', componentConfig);

  /**
   * @ngdoc component
   * @name C.dbDetails.protectionDetails
   *
   * @param   {Object}    job               The job applied to the database.
   * @param   {Object}    latestSnapshot    Most recent snapshot.
   * @param   {boolean}   loaded            True if data is finished loading.
   * @param   {Object}    policy            The policy applied to the database.
   * @param   {Object}    snapshotSummary   Summary of snapshot counts by type.
   *
   * @description
   * Database Protection Details.
   *
   * @example
   * <protection-details
   *   job="$ctrl.job"
   *   policy="$ctrl.policy"
   *   snapshotSummary="$ctrl.snapshotSumary"
   *   latestSnapshot="$ctrl.latestSnapshot"
   *   loaded="$ctrl.loaded">
   * </d-details>
   */
  function ProtectionDetailsCtrlFn() {
    var $ctrl = this;
  }

})(angular);
