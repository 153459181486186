import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';
import { getHeliosMfaStatus, heliosMfaGracePeriodExpired } from 'src/app/util';

// Access to this page is only available on Helios SaaS.
const heliosMfaEnabled = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.mfaHeliosSaas
  && ctx.FEATURE_FLAGS.mfaHeliosSaasPrompt
  && ctx.isHeliosSaaS;

// Access to this page is only available upon login when the MFA value is not set or MFA grace period is expired.
const heliosMfaPromptEnabled = (ctx: StateAccessContext) =>
  heliosMfaEnabled(ctx) && ctx.MCM_MODIFY_MFA
  && (getHeliosMfaStatus(ctx.irisContext) === 'Unknown' || heliosMfaGracePeriodExpired(ctx.irisContext));

// Access to this page is only available upon login when the MFA grace period is expired.
const heliosMfaPromptExpiredEnabled = (ctx: StateAccessContext) =>
  heliosMfaEnabled(ctx) && !ctx.MCM_MODIFY_MFA && heliosMfaGracePeriodExpired(ctx.irisContext);

export const HeliosMfPromptConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'helios-mfa-prompt.**',
      url: '^/mfa-prompt',
      loadChildren: () => import('./helios-mfa-prompt.module').then(m => m.HeliosMfaPromptModule)
    },
  ],
  allClusterMap: {
    'helios-mfa-prompt': {
      heliosOnly: true,
      globalContext: true
    },
    'helios-mfa-prompt.manage': {
      heliosOnly: true,
      globalContext: true
    },
    'helios-mfa-prompt.expired': {
      heliosOnly: true,
      globalContext: true
    },
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'helios-mfa-prompt': heliosMfaEnabled,
      'helios-mfa-prompt.manage': heliosMfaPromptEnabled,
      'helios-mfa-prompt.expired': heliosMfaPromptExpiredEnabled,
    };
  }
};
