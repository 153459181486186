import { ReportColumnConfigs } from '../common-report-items/custom-reports.service';
import { ReportSection } from '../iris-reporting.model';

/**
 * This is the layout config for the data transferred and storage consumption by external targets report.
 */
export const externalTargetsReport: ReportSection[] = [
  {
    title: 'reporting.summary',
    items: [
      {
        id: 'statlistItem',
        componentIds: ['1602'],
        componentNames: ['DataTransferredToExternalTargetsSummary'],
        params: {
          items: [
            {
              title: 'reporting.targets',
              translate: true,
              measurement: {
                valueKey: 'countTargetId',
                dataType: 'number',
              },
            },
            {
              title: 'reporting.externalStorageConsumedForRetainedData',
              translate: true,
              measurement: {
                valueKey: 'sumScRetainedBytes',
                dataType: 'bytes',
              },
            },
            {
              title: 'reporting.growthOfExternalStorageConsumedForRetainedData',
              translate: true,
              measurement: {
                valueKey: 'sumScRetainedBytesGrowth',
                dataType: 'bytes',
              },
            },
          ],
        },
      },
    ],
  },
  {
    items: [
      {
        id: 'lineChartItem',
        componentIds: ['1601'],
        componentNames: ['ExternalTargetDataTransferTrend'],
        title: 'reporting.externalStorageConsumptionTrend',
        params: {
          measurements: [
            {
              valueKey: 'sumScRetainedBytes',
              dataType: 'bytes'
            },
          ],
          dimensions: [
            {
              dataType: 'date',
              dimensionKey: 'timestampUsecs',
            },
            {
              dimensionKey: 'targetType',
            },
          ],
        },
      },
    ],
  },
  {
    title: 'reporting.data',
    isFullData: true,
    items: [
      {
        id: 'tableItem',
        componentIds: ['1600'],
        componentNames: ['AllDataTransferredToExternalTargets'],
        params: {
          columns: [
            {
              title: 'targetName',
              translate: true,
              key: 'targetName',
              icon: {
                key: 'targetType',
                dataType: 'targetIcon',
                renderParams: {
                  ownershipKey: 'managedBy',
                },
              },
              primary: {
                key: 'targetName',
                dataType: 'text',
              },
              subtitle: {
                key: 'targetType',
                dataType: 'targetType',
              },
            },
            {
              title: 'system',
              translate: true,
              key: 'systemName',
              primary: {
                key: 'systemName',
                dataType: 'text',
                renderParams: {
                  emptyKey: 'naNotAvailable',
                },
              },
            },
            {
              title: 'dataTransferred',
              translate: true,
              key: 'dataTransferredBytes',
              primary: {
                key: 'dataTransferredBytes',
                dataType: 'bytes',
              },
            },
            {
              title: 'cumulativeDataTransferred',
              translate: true,
              key: 'cumulativeDataTransferredBytes',
              primary: {
                key: 'cumulativeDataTransferredBytes',
                dataType: 'bytes',
              },
            },
            {
              title: 'externalStorageConsumedForRetainedData',
              translate: true,
              key: 'scRetainedBytes',
              primary: {
                key: 'scRetainedBytes',
                dataType: 'bytes',
              },
            },
            {
              title: 'externalStorageConsumedForRetainedDataGrowth',
              translate: true,
              key: 'scRetainedBytesGrowth',
              primary: {
                key: 'scRetainedBytesGrowth',
                dataType: 'bytes',
              },
            },
            {
              title: 'externalStorageConsumedForRetainedDataDailyGrowthRate',
              translate: true,
              key: 'scRetainedDailyGrowthRate',
              primary: {
                key: 'scRetainedDailyGrowthRate',
                dataType: 'bytesChange',
                renderParams: {
                  absoluteValue: true,
                  percentageKey: 'scRetainedDailyGrowthPercent'
                }
              },
            },
          ],
        },
      },
    ],
  },
];

/**
 * This is metadata needed to determine available chart customizations.
 */
export const externalTargetsColumns: ReportColumnConfigs = {
  targetName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  targetId: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  systemId: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  dataTransferredBytes: {
    aggs: ['sum', 'min', 'max', 'avg'],
    dataType: 'bytes',
  },
  cumulativeDataTransferredBytes: {
    aggs: ['min', 'max', 'avg'],
    dataType: 'bytes',
  },
  scRetainedBytes: {
    aggs: ['sum', 'min', 'max', 'avg'],
    dataType: 'bytes',
  },
  scRetainedBytesGrowth: {
    aggs: ['min', 'max', 'avg'],
    dataType: 'bytes',
  },
  scRetainedDailyGrowthRate: {
    aggs: ['min', 'max', 'avg'],
    dataType: 'bytes',
  },
  scRetainedDailyGrowthPercent: {
    aggs: ['min', 'max', 'avg'],
    dataType: 'bytes',
  },
};
