import get from 'lodash/get';
import { IrisContext } from './iris-context.model';

/**
 * Get the remote access cluster id. This is only set if the current scope is differrent from the accessCluster.
 *
 * @param irisContext The current iris context.
 * @param key configuration key
 * @param defaults default value if there's no value in configuration
 *
 * @returns the id of the remote access cluster or undefined.
 */
export function getConfigByKey<T>(irisContext: IrisContext, key: string, defaults: T): T {
  const config = irisContext?.appConfiguration ?? {};
  return <T>get(config, key, defaults);
}
