<h1 mat-dialog-title *ngIf="data.selectedObjects.length === 1">
  {{ 'backupName' | translate: { name: data.selectedObjects[0].name } }}
</h1>
<h1 mat-dialog-title *ngIf="data.selectedObjects.length > 1">
  {{ 'backupSelectedObjectsCount' | translate: { count: data.selectedObjects.length } }}
</h1>
<div mat-dialog-content class="content" *ngIf="!(data.selectedObjects[0]?.environment === 'kAWS')">
  <ng-container *ngIf="data.runningObjects.length">
    {{ 'runNowAlreadyRunningCount' | translate : {
          runningCount: data.runningObjects.length,
          backupCount: data.selectedObjects.length
        }
    }}
  </ng-container>
  {{ 'runNowObjectDialogMessage' | translate: { count: data.selectedObjects.length } }}
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close color="primary" cogDataId="run-now-cancel">
    {{ 'cancel' | translate }}
  </button>
  <button mat-stroked-button [mat-dialog-close]="ObjectRunNowAction.FullBackup" color="primary"
    *ngIf="data.selectedObjects[0]?.environment !== 'kAWS'"
    cogDataId="run-now-full-backup">
    {{ 'fullBackup' | translate }}
  </button>
  <button mat-flat-button [mat-dialog-close]="ObjectRunNowAction.Incremental" color="primary" cdkFocusInitial
    *ngIf="data.selectedObjects[0]?.environment !== 'kView'"
    cogDataId="run-now-incremental">
    {{ (data.selectedObjects[0]?.environment === 'kAWS' ? 'backup' : 'incremental') | translate }}
  </button>
  <button mat-flat-button [mat-dialog-close]="ObjectRunNowAction.Log" color="primary" cdkFocusInitial
    *ngIf="allowLogBackup"
    cogDataId="run-now-log">
    {{ 'log' | translate }}
  </button>
</div>
