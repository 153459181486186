;(function(angular, undefined) {
  'use strict';

  angular
    .module('C')
    .service('DataTransferredToTargetsUtil', dataTransferredToTargetsUtilFn);

  function dataTransferredToTargetsUtilFn(DateTimeService, moment) {
    var DataTransferredToTargetsUtil = {
      calculateSummaryStats: calculateSummaryStats,
      setFromInitDate: setFromInitDate,
    };

    /**
     * aggregates data from all external target to be displayed in summary
     * container
     *
     * @param    {array}    targetObjects    array of external target objects
     *                                       that contains data transferred info
     *
     * @return   {object}                    object that contains aggregated
     *                                       stats data
     */
    function calculateSummaryStats(targetObjects) {
      var stats = {
        physicalData: 0,
        archivalData: 0,
        tieringData: 0,
        totalStorageConsumed: 0,
      };

      targetObjects.forEach(function aggregateStats(target) {
        stats.physicalData += target.numPhysicalBytesTransferred || 0;

        if (target.storageConsumedBytes > 0) {
          if (target.dataTransferPerProtectionJob &&
            target.dataTransferPerProtectionJob.length) {
            stats.archivalData += target.storageConsumedBytes || 0;
          } else {
            stats.tieringData += target.storageConsumedBytes || 0;
          }
        }
      });

      stats.totalStorageConsumed = stats.archivalData + stats.tieringData;

      return stats;
    }

    /**
     * we want the default range for this report for 1 month. this method
     * will call the DateTimeSerice to manipulate our fromInitDate date obj
     *
     * @param    {object}    dateObj    date object of the default fromInitDate
     */
    function setFromInitDate(dateObj) {
      return moment(dateObj).subtract(1, 'months').format();
    }

    return DataTransferredToTargetsUtil;
  }
})(angular);
