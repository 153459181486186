<form [formGroup]="filterIpConfigFormGroup">
  <div class="margin-bottom">
    <mat-radio-group formControlName="filterType" (change)="switchFilterType($event)">
      <mat-radio-button value="allow">{{'allowIps' | translate}}</mat-radio-button>
      <mat-radio-button value="deny" class="margin-left-lg">{{'denyIps' | translate}}</mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="row margin-bottom">
    <mat-form-field class="col-xs-6">
      <mat-chip-list formControlName="ipList" #ipAddressList>
        <mat-chip *ngFor="let ip of filterIpConfigFormGroup.get('ipList').value; index as i"
          cogDataId="ip-{{i}}"
          [selectable]="true"
          (removed)="removeIpAddress(i)">
          {{ ip }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input
          tabindex="2"
          placeholder="{{'ipAddresses' | translate}}"
          [matChipInputFor]="ipAddressList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="addIpAddress($event)"
          cogDataId="add-ip-address-input">
      </mat-chip-list>
      <mat-hint>{{ 'protectionGroups.filterIpsHint' | translate }}</mat-hint>
      <mat-error>
        {{ 'errors.required' | translate }}
      </mat-error>
    </mat-form-field>
  </div>
</form>
