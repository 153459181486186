import { Injectable } from '@angular/core';
import { NaLabelPipeService } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';

/**
 * Na label pipe service with default na label translate value.
 */
@Injectable({ providedIn: 'root' })
export class AppNaLabelPipeService extends NaLabelPipeService {
  constructor(private translate: TranslateService) {
    super();
  }

  /**
   * Return the default na label value.
   *
   * @returns The default na label value.
   */
  getDefaultNaLabel(): string {
    return this.translate.instant('naNotAvailable');
  }
}
