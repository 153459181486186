// Component: recovery points

;(function(angular, undefined) {
  'user strict';

  var componentConfig = {
    controller: 'RecoveryPointsController',
    templateUrl: 'app/dashboard/sql/detail/summary/recovery-points.html',
    bindings: {
      id: '<',
      job: '<',
      latestSnapshot: '<',
      loaded: '<',
    },
  };

  angular.module('C.dbDetails')
    .controller('RecoveryPointsController', RecoveryPointsCtrlFn)
    .component('recoveryPoints', componentConfig);

  /**
   * @ngdoc component
   * @name C.dbDetails.recoveryPoints
   *
   * @param   {number}    id               The database entity id.
   * @param   {Object}    job              The job applied to the database.
   * @param   {Object}    latestSnapshot   Most recent snapshot.
   * @param   {boolean}   loaded           True if data is finished loading.
   *
   * @description
   * Shows snapshot and calendar pickers for recovery points.
   *
   * @example
   * <recovery-points
   *   id="$ctrl.id"
   *   job="$ctrl.job"
   *   latestSnapshot="$ctrl.latestSnapshot"
   *   loaded="$ctrl.loaded">
   * </recovery-points>
   */
  function RecoveryPointsCtrlFn(RestoreService, FEATURE_FLAGS) {
    var $ctrl = this;

    _.assign($ctrl, {
      $onChanges: $onChanges,
    });

    /**
     * Update the snapshot date when the latest snapshot changes
     *
     * @method   $onChanges
     * @param    {changes}   changes   A map with changed properties.
     */
    function $onChanges(changes) {
      if (changes.latestSnapshot && $ctrl.latestSnapshot) {
        // This initializes the snapshot calendar to the correct date based on
        // the latest snapshot.
        if (FEATURE_FLAGS.sqlRestoreLatestPIT) {
          defaultToLatestPIT();
        } else {
          $ctrl.snapshotsDate =
            $ctrl.latestSnapshot.instanceId.jobStartTimeUsecs / 1000;
        }
      }
    }

    /**
     * Sets the restore/clone default snapshot to latest Point in Time  snapshot
     *
     * @method   defaultToLatestPIT
     */
    function defaultToLatestPIT() {
      var params = {
        jobUids: [$ctrl.job.uid],
        environment: 'kSQL',
        protectionSourceId: $ctrl.id,
        startTimeUsecs: $ctrl.latestSnapshot.snapshotTimestampUsecs,
        endTimeUsecs: Date.now() * 1000,
      }

      RestoreService.getRestorePointsForTimeRange(params).then(
        function getRanges(ranges) {
          // If there are no PIT ranges default to incremental/full snapshot
          if (!ranges.timeRanges[0]) {
            $ctrl.snapshotsDate =
              $ctrl.latestSnapshot.instanceId.jobStartTimeUsecs / 1000;
            return;
          }

          var pitUsecs = _.last(ranges.timeRanges).endTimeUsecs;
          var latestSnapshotUsecs = ranges.fullSnapshotInfo[0] ?
            _.last(ranges.fullSnapshotInfo).restoreInfo.startTimeUsecs : 0;

          // If PIT latest snapshot is newer than incremental snapshot default
          // to the Point in Time value
          if (pitUsecs > latestSnapshotUsecs) {
            $ctrl.snapshotsDate = pitUsecs / 1000;
          } else {
            $ctrl.snapshotsDate =
              $ctrl.latestSnapshot.instanceId.jobStartTimeUsecs / 1000;
          }
        }
      );
    }
  }
})(angular);
