import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TruncateDirective } from './truncate.directive';

@NgModule({
  declarations: [TruncateDirective],
  exports: [TruncateDirective],
  imports: [
    CommonModule
  ]
})
export class TruncateModule { }
