/**
 * Seeded roles in MCM environment. This may not cover all the list.
 */
export const MCM_ROLES = {
  /**
   * Super Admin user
   */
  COHESITY_MCM_SUPER_ADMIN: 'COHESITY_MCM_SUPER_ADMIN',
  /**
   * Admin user
   */
  COHESITY_ADMIN: 'COHESITY_ADMIN',
  /**
   * Support user
   */
  COHESITY_MCM_SUPPORT_USER: 'COHESITY_MCM_SUPPORT_USER',
  /**
   * Quorom support user
   */
  COHESITY_MCM_QUORUM_SUPPORT_USER: 'COHESITY_MCM_QUORUM_SUPPORT_USER'
};

/**
 * List of unassignable support roles
 */
export const UNASSIGNABLE_ROLES = [
  MCM_ROLES.COHESITY_MCM_QUORUM_SUPPORT_USER,
  MCM_ROLES.COHESITY_MCM_SUPPORT_USER
];
