<cog-table
  name="security-advisor-score-table"
  class="score-table margin-top"
  *ngIf="scoresData"
  [selection]="selection"
  [persistSelection]="true"
  [isAllSelectedFn]="isAllSelectedFn"
  [source]="scoresData">
  <table mat-table matSort matSortActive="score" matSortDirection="asc">

    <!-- Score data -->
    <ng-container matColumnDef="lowestScore">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cogDataId="security-score-header">
        {{ 'securityScore' | translate }}
      </th>
      <td mat-cell *matCellDef="let row;index as i" cogDataId="security-score-{{i}}">
        <div class="score-container">
          <dg-pa-icon [scoreValue]="row.lowestScore" size="md"></dg-pa-icon>
          <span class="icon-text">{{ row.lowestScore !== null ? getAverage(row) : '...' | naLabel }} </span>
        </div>
      </td>
    </ng-container>


    <!-- Security risk -->
    <ng-container matColumnDef="security-risk">
      <th mat-header-cell *matHeaderCellDef cogDataId="security-risk-score-header">
        {{ 'securityRisk' | translate }}
      </th>
      <td mat-cell *matCellDef="let row;index as i" cogDataId="security-risk-score-{{i}}">
        <div class="security-risk-container">
          <span class="icon-text">
            {{ row.lowestScore !== null ? getSecurityRisk(row.lowestScore) : '...' | naLabel }}
          </span>
        </div>
      </td>
    </ng-container>


    <!-- Regions -->
    <ng-container matColumnDef="regionName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cogDataId="region-header">
        {{ 'region' | translate }}
      </th>
      <td mat-cell *matCellDef="let row;index as i" cogDataId="region-{{i}}">
        {{ row.regionName | naLabel }}
      </td>
    </ng-container>

    <!-- Clusters -->
    <ng-container matColumnDef="clusterNames">
      <th mat-header-cell *matHeaderCellDef cogDataId="clusters-header">
        {{ 'clusters' | translate }}
      </th>
      <td mat-cell *matCellDef="let row;index as i" cogDataId="clusters-{{i}}">
        <cog-item-list [items]="row.clusterNames | naLabel"
          [itemType]="'clusters' | translate" [suffix]="'more' | translate"></cog-item-list>
      </td>
    </ng-container>

    <!-- Security rules -->
    <ng-container matColumnDef="planName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cogDataId="rule-header">
        {{ 'rule' | translate }}
      </th>
      <td mat-cell *matCellDef="let row;index as i" cogDataId="rule-{{i}}">
        {{ row.planName | naLabel }}
      </td>
    </ng-container>

    <!-- Last Scan time -->
    <ng-container matColumnDef="lastScanTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cogDataId="last-scan-header">
        {{ 'lastScan' | translate }}
      </th>
      <td mat-cell *matCellDef="let row;index as i" cogDataId="last-scan-{{i}}">
        {{ row.lastScanTime | cogDate | naLabel }}
      </td>
    </ng-container>

    <!-- Next Scheduled Scan times -->
    <ng-container matColumnDef="nextScheduledScanTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'nextScheduledScan' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container>
          {{ row.nextScheduledScanTime | cogDate | naLabel }}
        </ng-container>
      </td>
    </ng-container>

    <!-- Actions -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" cogDataId="table-row-actions">
        <ng-container *ngIf="!disableRowActions">
          <button
            mat-icon-button
            type="button"
            cogDataId="view-issues"
            (click)="handleViewIssues(row, $event)"
            [matTooltip]="'viewIssues' | translate"
            [matTooltipPosition]="'left'"
            [inheritDataId]="true">
            <cog-icon shape="helix:inspect-bug"></cog-icon>
          </button>
        </ng-container>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: columns"
      [ngClass]="{ 'clickable': isRowClickable }"
      (click)="rowClicked.emit(row)">
    </tr>

    <!-- Loading Footer -->
    <ng-container matColumnDef="loading">
      <td [attr.colspan]="columns.length" mat-footer-cell *matFooterCellDef>
        <cog-spinner></cog-spinner>
      </td>
    </ng-container>
    <tr class="no-results" mat-footer-row [class.hidden]="!loading" *matFooterRowDef="['loading']"></tr>

    <!-- NoData Footer -->
    <ng-container matColumnDef="noScore">
      <td [attr.colspan]="columns.length" mat-footer-cell *matFooterCellDef>
        {{ 'noResultsFound' | translate }}
      </td>
    </ng-container>
    <tr
      class="nodata-row"
      mat-footer-row
      [class.hidden]="loading || scoresData?.length"
      *matFooterRowDef="['noScore']">
    </tr>
  </table>
  <mat-paginator></mat-paginator>
</cog-table>
