import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule, DataIdModule } from '@cohesity/helix';
import { HelixYfilesModule } from '@cohesity/helix-yfiles';
import { TranslateModule } from '@ngx-translate/core';

import { RpaasTopologyNodeModule } from '../rpaas-topology-node';
import { RpaasTopologyCardComponent } from './rpaas-topology-card.component';

@NgModule({
  imports: [
    CohesityHelixModule,
    CommonModule,
    DataIdModule,
    HelixYfilesModule,
    RpaasTopologyNodeModule,
    TranslateModule,
  ],
  exports: [RpaasTopologyCardComponent],
  declarations: [RpaasTopologyCardComponent],
})
export class RpaasTopologyCardModule {}
