import { Injectable } from '@angular/core';
import { RegionClusterScanResult, SecurityAdvisorServiceApi } from '@cohesity/api/secops';
import { AjaxHandlerService, AsyncBehaviorSubject, updateWithStatus } from '@cohesity/utils';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { SecurityPlanModel, SecurityPlansModel } from '../models';

@Injectable({
  providedIn: 'root',
})
export class PostureAdvisorService  {
  constructor(
    private securityAdvisorService: SecurityAdvisorServiceApi,
    private ajaxService: AjaxHandlerService,
  ) {}

  private _securityRuleDetails$ = new AsyncBehaviorSubject<SecurityPlanModel>();

  /**
   * Holds an observable to the list of configured regions.
   */
  readonly securityRuleDetails$ = this._securityRuleDetails$.pipe(map(info => info?.result));

  /**
   * The cohesity documentation url. Used for helios SaaS.
   * Will be populated by caller app.
   */
  cohesityDocsUrl: string;

  /**
   * The documentation url. This overrides cohesity documentation page for
   * installations like ibm.
   * Will be populated by caller app.
   */
  docsUrl: string;

  /**
   * Will be used by the caller app to populate global router params
   */
  getRouterGlobalParams: () => any;

  /**
   * Get all security plans.
   *
   * @returns Observable of security plans model.
   */
  getSecurityPlans(): Observable<SecurityPlansModel> {
    return this.securityAdvisorService
      .GetSecurityPlans({})
      .pipe(
        map(result => {
          let totalClusters = 0;
          let outOfPlanClusters = 0;
          const { plans } = result;
          plans.forEach(plan => {
            totalClusters += plan.clusters?.length;
            outOfPlanClusters += plan.outOfPlanClusters;
          });
          const securityPlansModel: SecurityPlansModel = {
            outOfRuleClustersPercentage: totalClusters ? outOfPlanClusters / totalClusters : 0,
            totalClusters,
            plans,
          };
          return securityPlansModel;
        })
      );
  }

  /**
   * Fetch the scan results based on a given region
   *
   * @param callback A callback function to make use of the data returned by the call
   */
  getScanResultsByRegion(callback: (r: RegionClusterScanResult[]) => void) {
    const { country, state } = this.getRouterGlobalParams();
    this.securityAdvisorService
      .GetScanResultsByRegion({ country, state })
      .subscribe(
        res => {
          callback && callback(res.results);
        },
        err => this.ajaxService.errorMessage(err)
      );
  }

  /**
   * Get Security rule details.
   *
   * @returns Observable of security plans model.
   */
  getSecurityRuleDetails(ruleId: string, forceQuery = true): Observable<SecurityPlanModel> {
    if (forceQuery || (!this._securityRuleDetails$.value.result && !this._securityRuleDetails$.value.loading)) {
      this.securityAdvisorService.GetSecurityPlanDetails(ruleId)
        .pipe(
          map((result: SecurityPlanModel) => {
            const accessControlList = result.securityCriteriaList.filter(
              criteria => criteria.category === 'accessControl');
            const configurationList = result.securityCriteriaList.filter(
              criteria => criteria.category === 'configuration');
            const smartFilesList = result.securityCriteriaList.filter(
              criteria => criteria.category === 'smartFiles');
            const accessControlCriteriaLength = accessControlList.length;
            const accessControlEnabledCriteriaLength = accessControlList.filter(val => val.isEnabled).length;

            const configurationCriteriaLength = configurationList.length;
            const configurationEnabledCriteriaLength = configurationList.filter(val => val.isEnabled).length;

            const smartFilesCriteriaLength = smartFilesList.length;
            const smartFilesEnabledCriteriaLength = smartFilesList.filter(val => val.isEnabled).length;

            const outOfPlanClustersPercentage = result.clusters.length ?
              (result.outOfPlanClusters / result.clusters.length) * 100 : 0;

            let percentageSecure = 0;
            if (accessControlCriteriaLength + configurationCriteriaLength + smartFilesCriteriaLength !== 0) {
              percentageSecure =
                ((accessControlEnabledCriteriaLength +
                  configurationEnabledCriteriaLength +
                  smartFilesEnabledCriteriaLength) /
                  (accessControlCriteriaLength + configurationCriteriaLength + smartFilesCriteriaLength)) *
                100;
            }
            return {
              ...result,
              accessControlCriteriaLength,
              accessControlEnabledCriteriaLength,
              configurationCriteriaLength,
              configurationEnabledCriteriaLength,
              smartFilesCriteriaLength,
              smartFilesEnabledCriteriaLength,
              percentageSecure,
              outOfPlanClustersPercentage,
              groupedSecurityCriteria: [
                accessControlList,
                configurationList,
                smartFilesList,
              ],
            };
          }),
          updateWithStatus(this._securityRuleDetails$)).subscribe();
    }
    return this.securityRuleDetails$.pipe(filter(result => !!result), take(1));
  }
}
