import { Component, Input } from '@angular/core';

import { HelixIntlService } from '../../../helix-intl.service';
import { DataFilterItem } from '../comparators/data-filter';

/**
 * Given a list of DataFilterItems, this component show the list of currently applied
 * filters using mat-chip list. Each filter can be cleared by clearing the mat chip.
 */
@Component({
  selector: 'cog-applied-filters',
  templateUrl: './applied-filters.component.html',
  styleUrls: ['./applied-filters.component.scss'],
})
export class AppliedFiltersComponent {
  /**
   * The list of applied filter items.
   */
  @Input() filterTags: DataFilterItem[];

  constructor(public intl: HelixIntlService) { }

  /**
   * Clears all currently applied filters.
   */
  clearFilters() {
    this.filterTags.forEach(filter => filter.remove());
  }
}
