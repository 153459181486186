import { DataTreeFilter } from '@cohesity/helix';
import { FilterGroup, SourceTreeFilters } from '@cohesity/iris-source-tree';
import { HostType } from 'src/app/shared/constants/host-type-groups.constants';

import { PhysicalSourceDataNode } from './physical-source-data-node';

export class PhysicalFilters {
  /**
   * These are the default view filters.
   */
  hostFilter: FilterGroup;

  constructor(private filters: SourceTreeFilters<PhysicalSourceDataNode>, private allowedOSes: HostType[] = []) {
    this.hostFilter = {
      id: 'host-type-filter',
      label: 'hostType',
      options: [
        this.shouldAddFilter(allowedOSes, HostType.kWindows) && {
          id: 'windows',
          label: 'cSourceTree.filterText.windows',
          filter: this.getFilterForOSType({ isWindows: true }),
        },
        this.shouldAddFilter(allowedOSes, HostType.kLinux) && {
          id: 'linux',
          label: 'cSourceTree.filterText.linux',
          filter: this.getFilterForOSType({ isLinux: true }),
        },
        this.shouldAddFilter(allowedOSes, HostType.kAix) && {
          id: 'aix',
          label: 'cSourceTree.filterText.aix',
          filter: this.getFilterForOSType({ isAix: true }),
        },
        this.shouldAddFilter(allowedOSes, HostType.kSolaris) && {
          id: 'solaris',
          label: 'cSourceTree.filterText.solaris',
          filter: this.getFilterForOSType({ isSolaris: true }),
        },
        this.shouldAddFilter(allowedOSes, HostType.kHPUX) && {
          id: 'HPUX',
          label: 'cSourceTree.filterText.hpux',
          filter: this.getFilterForOSType({ isHPUX: true }),
        },
        this.shouldAddFilter(allowedOSes, HostType.kVOS) && {
          id: 'VOS',
          label: 'cSourceTree.filterText.vos',
          filter: this.getFilterForOSType({ isVOS: true }),
        },
      ].filter(value => !!value),
    };

    this.filters.addFilterGroup(this.hostFilter);
    this.allowedOSes = allowedOSes;
  }

  /**
   * Checks if a filter for a host type should be added based on allowedOSes.
   */
  shouldAddFilter(allowedOSes: HostType[], hostType: HostType): boolean {
    return !allowedOSes.length || allowedOSes.includes(hostType);
  }

  /**
   * Get filter to fiter out specific os.
   */
  getFilterForOSType: (filterConfig: any) => DataTreeFilter<any> = filterConfig => (nodes: PhysicalSourceDataNode[]) =>
    nodes.filter(
      node =>
        node.isRoot ||
        (filterConfig.isAll &&
          (!this.allowedOSes.length || this.allowedOSes.includes(node.nodeHostType as HostType))) ||
        (filterConfig.isLinux && node.isLinux) ||
        (filterConfig.isWindows && node.isWindows) ||
        (filterConfig.isAix && node.isAix) ||
        (filterConfig.isSolaris && node.isSolaris) ||
        (filterConfig.isHPUX && node.isHPUX) ||
        (filterConfig.isVOS && node.isVOS)
    );
}
