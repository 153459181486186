import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

/**
 * The class overrides the mat paginator intl file to provide strings from our own strings file.
 * It also overrides the getRangeLabel method to to provide some additional functionality around
 * async table sources where the total number of entries is unknown.
 */
@Injectable({
  providedIn: 'root',
})
export class PaginatorIntlService extends MatPaginatorIntl {
  constructor(private translate: TranslateService) {
    super();
    this.firstPageLabel = this.translate.instant('matPaginator.firstPageLabel');
    this.itemsPerPageLabel = this.translate.instant('matPaginator.itemsPerPageLabel');
    this.lastPageLabel = this.translate.instant('matPaginator.lastPageLabel');
    this.nextPageLabel = this.translate.instant('matPaginator.nextPageLabel');
    this.previousPageLabel = this.translate.instant('matPaginator.previousPageLabel');
  }

  /**
   * A label for the range of items within the current page and the length of the whole list.
   * This modifies the original behavior of the class to handle async data sets where the total
   * number of items is unknown. If the length passed to this method is Number.MAX_SAFE_INTEGER,
   * the returned label will have a + appended to the length to indicate that there are more
   * values in the dataset.
   *
   * @param   page       The current page of the table.
   * @param   pageSize   The number of items per page.
   * @param   length     The total size of the data set.
   *
   * @return  The translated label.
   */
  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return this.translate.instant('matPaginator.rangeLabelNone', { length: length });
    }
    length = Math.max(length, 0);

    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    const labelKey = Number.MAX_SAFE_INTEGER === length ? 'matPaginator.rangeLabelMore' : 'matPaginator.rangeLabel';
    return this.translate.instant(labelKey, { start: startIndex + 1, end: endIndex, length: length });
  };
}
