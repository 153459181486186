import { Component, OnInit } from '@angular/core';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { filter, startWith, tap } from 'rxjs/operators';
import { ClusterService } from 'src/app/core/services';
import { AjsClusterService } from 'src/app/shared';
import { Environment } from 'src/app/shared/constants';

import { ProtectionItemName, QosPolicy } from '../../../models';
import { BaseProtectionBuilderComponent } from '../../base-protection-builder/base-protection-builder.component';

/**
 * Default values for QoS policy.
 */
export const DefaultQosPolicy = 'kBackupHDD';

/**
 * Default values for QoS policy if the cluster is all flash or if
 * 'defaultProtectionQosSsd' flag is enabled.
 */
export const AllFlashDefaultQosPolicy = 'kBackupSSD';

/**
 * 'kBackupAll' Qos policy.
 */
export const BackupAllQosPolicy = 'kBackupAll';

/**
 * Component for selecting Quality of Service for a protection group. This
 * component allows the user to select one of HDD or SSD as the QoS value if
 * their environment (native virtual machines) supports this.
 */
@Component({
  selector: 'coh-settings-list-qos-policy',
  templateUrl: './settings-list-qos-policy.component.html',
  styleUrls: ['./settings-list-qos-policy.component.scss'],
})
export class SettingsListQosPolicyComponent
  extends BaseProtectionBuilderComponent<QosPolicy, any> implements OnInit {
  /**
   * Default QoS value.
   */
  _value: QosPolicy = DefaultQosPolicy;

  /**
   * True if cluster platform includes PXG1.
   */
  isDisAggregatedStorage = this.ajsClusterService.clusterInfo._isDisaggregatedStorage;

  /**
   * Specifies the array of environments which support 'kTestAndDevHigh' QoS policy within backups.
   */
  envSupportingTestAndDevQoSPolicy: Environment[] = [
    Environment.kOracle,
    Environment.kSQL,
  ];

  /**
   * Specifies the array of environments which support 'kBackupAll' QoS policy within backups.
   */
  envWithDefaultBackupAllQoSPolicy: Environment[] = [
    Environment.kOracle,
  ];

  /**
   * Variable to store if the cluster is an all flash cluster.
   */
  allFlashCluster = false;

  /**
   * Specifies whether the 'kTestAndDevHigh' backup QoS policy is supported.
   */
  canSupportTestAndDevQoSPolicy = false;

  /**
   * Specifies whether the 'kBackupAll' backup QoS policy is supported.
   */
  canSupportBackupAllQoSPolicy = false;

  /**
   * ctor
   */
  constructor(
    private ajsClusterService: AjsClusterService,
    private clusterService: ClusterService,
    private irisCtx: IrisContextService
  ) {
    super();
  }

  /**
   * Return whether "kBackupSSD" is the default value over "kBackupHDD".
   */
  get allFlashDefault(): boolean {
    return this.allFlashCluster || flagEnabled(this.irisCtx.irisContext, 'defaultProtectionQosSsd');
  }

  /**
   * Return environment based on selected protection source.
   *
   * @returns selected protection source environment.
   */
  get environment(): Environment {
    return this.formGroup.parent?.get(ProtectionItemName.Source)?.value?.protectionSource?.environment;
  }

  /**
   * Return whether "kBackupAll" is the default value over "kBackupHDD".
   *
   * @returns 'True' if kBackupAll is default Qos policy.
   */
  get backupAllDefault(): boolean {
    return this.canSupportBackupAllQoSPolicy && this.envWithDefaultBackupAllQoSPolicy.includes(this.environment);
  }

  /**
   * Return backup name translation key.
   */
  get backupName(): string {
    return this.isDisAggregatedStorage ? 'backup' :
      this.allFlashDefault || this.backupAllDefault ? 'backupHdd' : 'backupHddDefault';
  }

  /**
   * Component init.
   */
  ngOnInit() {
    this.clusterService.isAllFlashCluster().pipe(this.untilDestroy()).subscribe(value => {
      this.allFlashCluster = value;

      if (this.allFlashCluster || flagEnabled(this.irisCtx.irisContext, 'defaultProtectionQosSsd')) {
        this.value = AllFlashDefaultQosPolicy;
      }

      if (this.formControl && !((this.protectionGroup || {}).qosPolicy || this.properties?.standalone)) {
        // If new protection group, set the initial value as SSD instead of HDD.
        this.formControl.setValue(this.value);
      }
    });
  }

  /**
   * Returns the string key for the corresponding QoS backup policy.
   *
   * @param     qosValue   Specifies the QoS value
   * @returns   String key for the given QoS value.
   */
  getQoSSettingValue(qosValue) {
    if (!qosValue) {
      return '';
    }

    // QOS value for PXG1 platform is always backup.
    if (this.isDisAggregatedStorage) {
      return 'backup';
    }
    switch (qosValue) {
      case 'kBackupHDD':
        return 'backupHdd';
      case 'kBackupSSD':
        return 'backupSsd';
      case 'kTestAndDevHigh':
        return 'testAndDevHigh';
      case 'kBackupAll':
        return 'backupAll';
    }
  }

  /**
   * Method called to set kBackupAll as default Qos policy based on selected source.
   *
   * @param environment environment.
   */
  setDefaultBackupAllQosPolicy(environment: Environment) {
    if (this.envWithDefaultBackupAllQoSPolicy.includes(environment) &&
      this.canSupportBackupAllQoSPolicy) {
      this.value = BackupAllQosPolicy;

      // If new protection group, set the initial value as Backup All.
      if (this.formControl && !(this.protectionGroup || {}).qosPolicy) {
        this.formControl.setValue(this.value);
      }
    }
  }

  /**
   * Form control init.
   */
  initFormControl() {
    const sourceFormControl = this.formGroup.parent?.get(ProtectionItemName.Source);

    if (sourceFormControl) {
      sourceFormControl.valueChanges.pipe(
        this.untilDestroy(),
        startWith(sourceFormControl.value),
        filter(value => !!value),
      ).subscribe(value => {
        const environment = value.protectionSource.environment;

        if (this.envSupportingTestAndDevQoSPolicy.includes(environment)) {
          this.canSupportTestAndDevQoSPolicy = true;
        }
        this.setDefaultBackupAllQosPolicy(environment);
      });
    }

    // Determine whether all nodes on the cluster belong to C6k platform.
    this.clusterService.isC6kCluster().pipe(
      this.untilDestroy(),
      tap(isC6kCluster => {
        // If the feature flag is disabled, display BT-All QoS policy only
        // if all the nodes on the cluster belong to C6k platform.
        if (flagEnabled(this.irisCtx.irisContext, 'enableBtAllQoSForBackups') || isC6kCluster) {
          this.canSupportBackupAllQoSPolicy = true;
        }
        this.setDefaultBackupAllQosPolicy(this.environment);
      })
    ).subscribe();

    if (this.protectionGroup && this.protectionGroup.qosPolicy) {
      this.value = this.protectionGroup.qosPolicy;
      this.formControl.setValue(this.value);
    }
  }
}
