import { Injectable } from '@angular/core';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';

import { BaseProtectionSourceService } from '../shared/base-protection-source.service';
import { IbmFlashSystemSourceDataNode } from './ibmflashsystem-source-data-node';
import { IbmFlashSystemViewFilters } from './ibmflashsystem-view-filters';
import { IbmProtectionTypes } from './ibmflashsystem.constants';

/**
 * Tree service for ibmflashsystem.
 */
@Injectable({
  providedIn: 'root',
})
export class IbmFlashSystemSourceTreeService extends BaseProtectionSourceService<IbmFlashSystemSourceDataNode> {

  /**
   * A reference to ibm flash sytem view filters, to show a physical, flat list view.
   */
  ibmFlashSystemViewFilters: IbmFlashSystemViewFilters;

  constructor(
    private irisCtx: IrisContextService,
    readonly translate: TranslateService,
  ) {
    super();
    if (flagEnabled(this.irisCtx.irisContext, 'ibmFlashSystemEnabled')) {
      this.ibmFlashSystemViewFilters = new IbmFlashSystemViewFilters(
        this.filters,
        this.treeTransformer,
      );
    }
  }

  /**
   * Transforms the node object from the api into a Ad Tree node to pass to the tree.
   *
   * @param   node   The original node.
   * @param   level  The level in the tree.
   * @return  An AdSourceDataNode that can be displayed in the tree.
   */
  transformData(node: ProtectionSourceNode, level: number): IbmFlashSystemSourceDataNode {
    return new IbmFlashSystemSourceDataNode(node, this.irisCtx, level);
  }

  /**
   * Clears the current selection of objects.
   */
  clearSelection() {
    this.dataTreeSelection.currentSelection = {
      autoSelected: [],
      selected: [],
    };
  }

  /**
   * Only expand certain node types by default.
   *
   * @param   node   The treenode to check.
   * @return  True if the node should be expanded by default.
   */
  shouldExpandNodeOnLoad(node: IbmFlashSystemSourceDataNode): boolean {
    return node.type !== IbmProtectionTypes.kVolumeGroup;
  }
}
