import { Injectable, Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

const PREFIX = 'C._persist';

/**
 * This is a thin layer over localStorage
 */
@Injectable({
  providedIn: 'root',
})
export class PersistService {

  /**
   * Gets the prefixed cache key.
   *
   * @method   _getInternalCacheKey
   * @param    cacheKey   The unprefixed cache key.
   * @return   The prefixed cache key.
   */
  public static getInternalCacheKey(cacheKey: string): string {
    return [PREFIX, cacheKey].join('.');
  }

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {}

  /**
   * Wrapper for localStorage get with an enforced key prefix.
   *
   * @method   get
   * @param    cacheKey   The cache key to retrieve.
   * @return   Whatever is stored in cache, or undefined.
   */
  get(cacheKey: string): any {
    return this.storage.get(PersistService.getInternalCacheKey(cacheKey));
  }

  /**
   * Wrapper for localStorage set with an enforced key prefix.
   *
   * @method   set
   * @param    cacheKey       The cache key.
   * @param    [cacheValue]   The value to store in cache.
   * @return   Unmodified 'cacheValue'.
   */
  set(cacheKey: string, cacheValue: any) {
    this.storage.set(PersistService.getInternalCacheKey(cacheKey), cacheValue);
    return cacheValue;
  }

  /**
   * Removes an item from the cache.
   *
   * @param cacheKey The key of the item to remove.
   */
  remove(cacheKey: string) {
    this.storage.remove(PersistService.getInternalCacheKey(cacheKey));
  }
}
