<div [formGroup]="formGroup">
  <div class="d-flex margin-bottom">
    <div class="row">
      <div class="col-xs-6">
        <coh-form-field-object-selector
          cogDataId="uda-host-os-type-dropdown"
          [control]="formGroupControls.hostOsType"
          [label]="'hostOsType' | translate"
          [optionTemplate]="udaHostOsTypeDropdownTemplate"
          [allowSearch]="false"
          [triggerTemplate]="udaHostOsTypeDropdownTemplate"
          [values]="hostOsTypes"
          [readonly]="editMode">
        </coh-form-field-object-selector>
      </div>
    </div>
  </div>

  <coh-uda-register-primary-fields
    [editMode]="editMode"
    [formControl]="formGroupControls.primaryFieldParams">
  </coh-uda-register-primary-fields>
</div>

<!-- Host os type template for Universal Data Adapter sources -->
<ng-template #udaHostOsTypeDropdownTemplate let-value>
  {{ getHostOSTypeLabelKey(value) | translate }}
</ng-template>
