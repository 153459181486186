import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { UIRouterGlobals } from '@uirouter/core';
import {
  Controls,
  NgxSubFormComponent,
  subformComponentProviders,
  takeUntilDestroyed,
  TypedFormGroup
} from 'ngx-sub-form';
import { envGroups, Environment } from 'src/app/shared/constants';

import { RecoverCommonOptionsForm } from '../../model/recover-common-options-form';
import { RecoverToOptions } from '../../model/recover-to-options';

/**
 * ID of the setting list item
 */
type SettingId =
  | 'clusterInterface'
  | 'aclOnlyRestore'
  | 'overwriteExistingFile'
  | 'preserveCustomAttributes'
  | 'continueOnError'
  | 'saveSuccessFiles'
  | 'encryptionEnabled'
  | 'ipFilters'
  | 'taskName';

/**
 * This component provides common options for file recoveries that are used across all
 * adapters. The UI for the target section of the form should be provided via content-projection
 *
 * @example
 * <coh-recover-common-options-form [isReadOnly]="isReadOnly"
 *   [formControl]="formControl">
 *   <ng-container [formGroup]="optionsForm">
 *     <coh-recover-file-vm-target-options [availableSources]="vmFileService.vmTargetsData$ | async"
 *       [environment]="environment"
 *       [isReadOnly]="isReadOnly"
 *       [formControl]="targetControl">
 *     </coh-recover-file-vm-target-options>
 *   </ng-container>
 * </coh-recover-common-options-form>
 */
@Component({
  selector: 'coh-recover-common-options-form',
  templateUrl: './recover-common-options-form.component.html',
  providers: [...subformComponentProviders(RecoverCommonOptionsFormComponent)],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecoverCommonOptionsFormComponent
  extends NgxSubFormComponent<RecoverCommonOptionsForm<any>>
  implements OnInit {
  /**
   * If the component should be displayed as read-only.
   */
  @Input() isReadOnly: boolean;

  /**
   * Only show cluster interface option.
   */
  @Input() onlyClusterInterface = false;

  /**
   * Specifies the environment of the source to be recovered.
   */
  @Input() sourceEnvironment: Environment;

  /**
   * Specifies the environment of the alternative location for recovery.
   */
  @Input() targetEnvironment: Environment;

  /*
   * Show the encryption setting only if this is set.
   */
  @Input() showEncryptionControl = false;

  /*
   * Show the encryption setting only if this is set.
   */
  @Input() showAclOnlyRestoreSetting = false;

  /**
   * The parent form group
   */
  @Input() parent: TypedFormGroup<RecoverCommonOptionsForm<any>>;

  /**
   * Data template id for reference.
   */
  @Input() dataId = 'recover-vm-aws-settings';

  /**
   * The value is decided by a feature flag.
   */
  @Input() overwriteOriginalsByDefault = true;

  /**
   * If it is a snapmirror backup.
   */
  @Input() isSnapMirrorBackup = false;

  /**
   * Settings to remove based on recovery type, environment, etc
   */
  @Input() removeSettings: SettingId[] = [];

  /**
   * Gets the control for the target form.
   */
  get targetConfigControl(): AbstractControl {
    return this.formGroupControls && this.formGroupControls.targetConfig;
  }

  /**
   * Determines if the recovery is NAS recovery or not.
   */
  get nasRecovery(): boolean {
    return this.sourceEnvironment && envGroups.nas.includes(this.sourceEnvironment);
  }

  /**
   * Determines if the recovery is Physical recovery or not.
   */
  get physicalRecovery(): boolean {
    return this.sourceEnvironment && envGroups.physical.includes(this.sourceEnvironment);
  }

  /**
   * Determines if Filter IPs setting should be shown or not.
   */
  get showFilterIpsSetting(): boolean {
    return flagEnabled(this.irisCtx.irisContext, 'nasFilterIpsEnabled') && this.nasRecovery &&

      // Filter Ips are not applicable to Generic NAS mount points targets
      ((this.targetConfigControl.value?.type === RecoverToOptions.originalLocation &&
        this.sourceEnvironment !== Environment.kGenericNas) ||
        this.targetEnvironment !== Environment.kGenericNas);
  }

  /**
   * Determines if Save Success Files setting should be shown or not.
   */
  get showSaveSuccessFileSetting(): boolean {
    return this.physicalRecovery && flagEnabled(this.irisCtx.irisContext, 'saveSuccessFileEnabled');
  }

  /**
   * Determines if Preserve file attributes setting should be disabled or not.
   */
  get disablePreserveFileAttributesSetting(): boolean {
    return !flagEnabled(this.irisCtx.irisContext, 'preserveFileFolderAttribsEnabled');
  }

  /**
   * Returns the taskNameKey required for coh-settings-list-task-name.
   */
  getTaskNameKey(): string {
    let returnVal = 'recovery.defaultRecoverFilesTaskName';

    if (this.nasRecovery) {
      returnVal = this.uiRouterGlobals.params.recoveryType === 'recover-files' ?
        'recovery.defaultStorageVolumeRecoverFilesTaskName' :
        'recovery.defaultRecoverStorageVolumesTaskName';
    }

    return returnVal;
  }

  /**
   * Returns whether setting shoule be displayed
   *
   * @param id ID of the setting list item
   * @returns true if the setting should be displayed
   */
  shouldDisplay(id: SettingId): boolean {
    return !this.removeSettings.includes(id);
  }

  constructor(
    private irisCtx: IrisContextService,
    private uiRouterGlobals: UIRouterGlobals
    ) {
    super();
  }

  ngOnInit() {
    this.formGroupControls.aclOnlyRestore.valueChanges.pipe(
      takeUntilDestroyed(this)
    ).subscribe(value => {
      this.formGroupControls.overwriteOriginals.setValue(value);
    });
  }

  getDefaultValues(): Partial<RecoverCommonOptionsForm<any>> {
    return {
      overwriteOriginals: this.overwriteOriginalsByDefault,
      preserveAttributes: true,
      continueOnError: true,
      encryptionEnabled: false,
      aclOnlyRestore: false,
      saveSuccessFiles: this.showSaveSuccessFileSetting
    };
  }

  /**
   * Gets the form controls for configuring azure vms.
   *
   * @returns  The vmware vm options controls.
   */
  protected getFormControls(): Controls<RecoverCommonOptionsForm<any>> {
    return {
      // This form control is initialized as a form group and has its
      // own validation as a sub form.
      targetConfig: new UntypedFormControl(null),

      // These form controls are directly used.
      overwriteOriginals: new UntypedFormControl(null, Validators.required),
      preserveAttributes: new UntypedFormControl(null, Validators.required),
      continueOnError: new UntypedFormControl(null, Validators.required),
      saveSuccessFiles: new UntypedFormControl(),
      encryptionEnabled: new UntypedFormControl(null),
      aclOnlyRestore: new UntypedFormControl(null),
      filterIps: new UntypedFormControl(null),
      clusterInterface: new UntypedFormControl(null),
      taskName: new UntypedFormControl(null, Validators.required),
    };
  }
}
