<div class="object-search-container" [class.container-with-selection]="!selection.isEmpty()">
  <coh-object-search-field
    [placeholderText]="'recover.searchPlaceholder.vm' | translate"
    [searchPending]="searchService.searchPending$ | async"
    (queryChanged)="searchService.searchQuery = $event">
  </coh-object-search-field>

  <cog-filters>
    <coh-object-type-filter [requestParams]="environments"></coh-object-type-filter>
    <coh-source-filter
      [requestParams]="{ environments: searchEnvironments }"
      (optionsLoaded)="filterValues.source = $event">
    </coh-source-filter>
    <coh-protection-group-filter [requestParams]="{ environments: searchEnvironments }"></coh-protection-group-filter>
    <coh-storage-domain-filter (optionsLoaded)="filterValues.storageDomain = $event"></coh-storage-domain-filter>
    <ng-container *ngIf="type !== 'view'">
      <cog-date-range-filter
        *cogFilterDef="let filter; let params = params;
          key: 'dateRange';
          label: 'date' | translate;
          filterType: 'dateRange';
          quickFilter: true"
        [filter]="filter"
        [useTimeframe]="true"
        [filterDefParams]="params"
        [selectedRange]="dateRange">
      </cog-date-range-filter>
    </ng-container>
  </cog-filters>

  <coh-object-search-results
    [searchResults]="searchService.searchResults$ | async"
    [selection]="selection"
    [noData]="(
      searchService.searchQuery$ | async)?.length &&
      !(searchService.searchPending$ | async) &&
      !(searchService.searchResults$ | async)?.length"
    [detailTemplate]="rowDetail"
    class="margin-top">
  </coh-object-search-results>

  <div class="total-number-of-records mat-body-2" *ngIf="totalReturnedRecords > 0">
    <span>{{totalReturnedRecords}} {{ 'recordsReturned' | translate }}</span>
  </div>
</div>

<div class="selected-object-list-container" *ngIf="form?.value?.length">
  <h3>{{ 'selectedNItems' | translate: { items: form.value.length } }}</h3>
  <div class="selected-object-list">
    <div
      *ngFor="let snapshot of form?.value; index as i"
      class="selected-object-container padding-top padding-bottom">
      <coh-restore-point-selection-detail
        [icon]="getIcon(snapshot.objectInfo) ?? (snapshot.objectInfo | searchObjectIcon)"
        [restorePointSelection]="snapshot"
        (editRestorePoint)="editSnapshotAt(i)">
        <coh-component-outlet-extension
          [extensionPoint]="snapshotExtensionPoint"
          [extensionData]="{
          clusterId: scopeSelectorService.clusterId,
          snapshotId: snapshot.restorePointId
        }">
        </coh-component-outlet-extension>
      </coh-restore-point-selection-detail>
      <div class="selected-object-button-container">
        <button
          mat-icon-button
          class="remove-item-button"
          type="button"
          (click)="removeSnapshot(snapshot)"
          cogDataId="remove-object-{{snapshot.objectInfo.name}}"
          [attr.aria-label]="'remove' | translate">
          <cog-icon shape="close"></cog-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #rowDetail let-row>
  <div class="server-row">
    <coh-object-search-item-detail
      [objectIcon]="getIcon(row) ?? (row | searchObjectIcon)"
      [sourceType]="getFilterValue('source', row.sourceId)?.hintText"
      [source]="getFilterValue('source', row.sourceId)?.label"
      [storageDomain]="getFilterValue('storageDomain', row.storageDomainId)?.label"
      [object]="row">
    </coh-object-search-item-detail>
  </div>
</ng-template>
