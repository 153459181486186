import { Component, Input, OnInit } from '@angular/core';
import { AutoDestroyable } from '@cohesity/utils';
import { ObservableInput } from 'ngx-observable-input';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RecoveryComponentUtil, RestorePointSelection } from 'src/app/modules/restore/restore-shared';

import { SnapshotSelectorUtilsService } from '../../snapshot-selector/services/snapshot-selector-utils.service';

/**
 * This component can be added to a recovery flow to prevent selecting fort knox snapshots for recovery
 * in prem installs.
 */
@Component({
  selector: 'coh-rpaas-validation-banner',
  templateUrl: './rpaas-validation-banner.component.html',
})
export class RpaasValidationBannerComponent extends AutoDestroyable implements OnInit {
  /**
   * The currently selected objects in the recovery flow
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('objects') objects$: Observable<RestorePointSelection[]>;

  /**
   * The recovery context. When fort knox snapshots are selected, this component will make calls to the
   * recover context to disable the recocery.
   */
  @Input() context: RecoveryComponentUtil;

  /**
   * Resolves to true if the recovery needs to be blocked, this will cause the error banner to show.
   */
  blockRecovery$: Observable<boolean>;

  constructor(private snapshotSelectorUtilsService: SnapshotSelectorUtilsService) {
    super();
  }

  ngOnInit() {
    this.blockRecovery$ = this.objects$.pipe(
      map(objects =>
        objects?.some(object => this.snapshotSelectorUtilsService.getArchivalTargetIsDisabled(object.archiveTargetInfo))
      )
    );

    this.blockRecovery$.pipe(this.untilDestroy()).subscribe(blockRecovery => {
      this.context.blockContinueRecover = blockRecovery;
    });
  }
}
