<cog-settings-list-item [formGroup]="formGroup"
  [invalid]="formControl.invalid"
  [label]="'sla' | translate">
  <cog-settings-list-item-value-display>
    <p>
      {{'full' | label: (slaFormGroup.get('full').value * 60 * 1000 | humanizeDuration: null : true) }}
    </p>
    <p>
      {{'incremental' | label: (slaFormGroup.get('incremental').value * 60 * 1000 | humanizeDuration: null : true) }}
    </p>
    <p *ngIf="slaLogBackupEnabled">
      {{'log' | label: (slaFormGroup.get('log').value * 60 * 1000 | humanizeDuration: null : true) }}
    </p>
    <cog-banner>
      {{'protectionGroups.sla.title' | translate: {
        full: (slaFormGroup.get('full').value * 60 * 1000 | humanizeDuration: null : true),
        incremental: (slaFormGroup.get('incremental').value * 60 * 1000 | humanizeDuration: null : true)
      } }}
    </cog-banner>
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor [formGroup]="slaFormGroup"
    cogDataId="settings-sla">
    <div class="inline-minutes-input">
      <mat-form-field>
        <input formControlName="full"
          matInput
          [inheritDataId]="true"
          cogDataId="full-value"
          min="1"
          placeholder="{{'full' | translate}}"
          type="number">
        <mat-error *ngIf="slaFormGroup.get('full').errors; let error">
          {{ (error.min ? 'errors.minValue' :
            error.pattern ? 'errors.wholeNumber' :
            'errors.required') | translate: {minValue: 1} }}
        </mat-error>
      </mat-form-field>
      <div translate>minutes</div>
    </div>
    <div class="inline-minutes-input">
      <mat-form-field>
        <input formControlName="incremental"
          matInput
          [inheritDataId]="true"
          cogDataId="incremental-value"
          min="1"
          placeholder="{{'incremental' | translate}}"
          type="number">
        <mat-error *ngIf="slaFormGroup.get('incremental').errors; let error">
          {{ (error.min ? 'errors.minValue' :
            error.pattern ? 'errors.wholeNumber' :
            'errors.required') | translate: {minValue: 1} }}
        </mat-error>
      </mat-form-field>
      <div translate>minutes</div>
    </div>
    <div *ngIf="slaLogBackupEnabled" class="inline-minutes-input">
      <mat-form-field>
        <input formControlName="log"
          matInput
          [inheritDataId]="true"
          cogDataId="log-value"
          min="1"
          placeholder="{{'log' | translate}}"
          type="number">
        <mat-error *ngIf="slaFormGroup.get('log').invalid">
          {{ 'errors.minValue' | translate: {minValue: 1} }}
        </mat-error>
      </mat-form-field>
      <div translate>minutes</div>
    </div>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
