import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from '@cohesity/api/v1';

/**
 * Shows the information suitable for rendering inside the user panel.
 *
 * @example
 * <coh-user-panel [user]="userObject" (logout)="onLogout()">
 * </coh-user-panel>
 */
@Component({
  selector: 'coh-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPanelComponent {
  /**
   * Comprises the details of the logged-in user.
   */
  @Input() user: User;

  /**
   * Emits when the user clicks the logout item.
   */
  @Output() logout = new EventEmitter();
}
