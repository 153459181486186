import { Injectable } from '@angular/core';
import { TagCategory, TagSubCategory, TagType } from '@cohesity/api/helios-metadata';
import { SearchServiceApi } from '@cohesity/api/v2';
import { Observable, of } from 'rxjs';
import { AnomaliesStatus, FilterFieldOption, IFieldFilterService, anomaliesTagNames, anomalyStatusLabelKeys } from '../../constants';
import { getCombinedUniqueString, getExcludedTagNames } from '../../utils';

@Injectable({
  providedIn: 'root'
})
export class AnomaliesFilterService implements IFieldFilterService {
  /**
   * static list of anomalies filter options
   */
  private anomaliesStatusValueOptions: FilterFieldOption[] = Object.values(AnomaliesStatus)
    .filter((status) => status !== AnomaliesStatus.unknown)
    .map((status) => ({
      key: status,
      labelKey: anomalyStatusLabelKeys[status],
    }));

  /**
   * Anomalies status filter value generator.
   *
   * @returns observable with filter options
   */
  getFilterOptions(): Observable<FilterFieldOption[]> {
    return of(this.anomaliesStatusValueOptions);
  }

  /**
   * consolidates anomalies filters into a format that API understands
   *
   * @param selectedValues currently applied anomalies filters
   * @param appliedApiFilters currently applied api filters
   * @returns anomalies filter that API understands
   */
  transformFilterValues(
    selectedValues: string[],
    appliedApiFilters: SearchServiceApi.SearchObjectsParams
  ): SearchServiceApi.SearchObjectsParams {
    const apiFilters: SearchServiceApi.SearchObjectsParams = {};

    if (selectedValues.length) {
      apiFilters.tagTypes = [getCombinedUniqueString(appliedApiFilters.tagTypes, TagType.System)];
      apiFilters.tagCategories = [getCombinedUniqueString(appliedApiFilters.tagCategories, TagCategory.Security)];
      apiFilters.tagSubCategories = [
        getCombinedUniqueString(appliedApiFilters.tagSubCategories, TagSubCategory.Anomalies),
      ];
      apiFilters.anomalyTags = [
        getCombinedUniqueString(selectedValues.map((value) => anomaliesTagNames[value]).filter(Boolean))];

      const excludedTagNames = getExcludedTagNames<AnomaliesStatus>(
        appliedApiFilters.tagNamesExcluded ?? [], selectedValues, this.anomaliesStatusValueOptions, anomaliesTagNames);
      if (excludedTagNames.length > 0) {
        apiFilters.tagNamesExcluded = excludedTagNames;
      }
    }

    return apiFilters;
  }
}
