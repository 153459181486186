import { Directive, inject, TemplateRef, ViewContainerRef } from '@angular/core';

import { FormSectionComponent } from './form-section.component';

@Directive({
  selector: '[cohFormSectionEditMode]',
})
export class FormSectionEditModeDirective {
  constructor(
    public readonly templateRef: TemplateRef<unknown>,
    public viewContainerRef: ViewContainerRef,
  ) { }
}

@Directive({
  selector: '[cohFormSectionEditCta]',
})
export class FormSectionEditCtaDirective {
  constructor(
    public readonly templateRef: TemplateRef<unknown>,
    public viewContainerRef: ViewContainerRef,
  ) { }
}

@Directive({
  selector: '[cohFormSectionViewMode]',
})
export class FormSectionViewModeDirective {
  constructor(
    public readonly templateRef: TemplateRef<unknown>,
    public viewContainerRef: ViewContainerRef,
  ) { }
}

@Directive({
  selector: '[cohFormSectionViewCta]',
})
export class FormSectionViewCtaDirective {
  private parent = inject(FormSectionComponent<any, any, any>);

  constructor(
    public readonly templateRef: TemplateRef<unknown>,
    public viewContainerRef: ViewContainerRef,
  ) { }

  viewEditMode() {
    this.parent.mode = 'edit';
    this.parent.formControl.updateValueAndValidity();
  }
}

@Directive({
  selector: '[cohFormSectionNavigator]',
})
export class FormSectionNavigatorDirective {
  private parent = inject(FormSectionComponent<any, any, any>);

  constructor(
    public readonly templateRef: TemplateRef<unknown>,
    public viewContainerRef: ViewContainerRef,
  ) { }

  next() {
    this.parent.mode = 'view';
    this.parent.formControl.updateValueAndValidity();
  }
}

export const EXPORT_THINGS = [
  FormSectionEditCtaDirective,
  FormSectionEditModeDirective,
  FormSectionNavigatorDirective,
  FormSectionViewCtaDirective,
  FormSectionViewModeDirective,
];
