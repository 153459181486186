<h1 mat-dialog-title>{{ (data ? 'editSaasConnectorGroup' : 'newSaasConnectorGroup') | translate  }}</h1>

<div mat-dialog-content>
  <mat-form-field>
    <input
      matInput
      cdkFocusInitial
      cogDataId="input-edit-connector-group-dialog-name"
      [formControl]="groupNameControl"
      [placeholder]="'groupName' | translate"
      type="text"
      maxlength="60">
    <mat-error *ngIf="groupNameControl.invalid">
      {{ 'connectorGroupNameAlreadyExists' | translate }}
    </mat-error>
  </mat-form-field>
</div>

<div mat-dialog-actions align="end">
  <button
    cogDataId="btn-edit-connector-group-dialog-close"
    mat-button
    mat-dialog-close>
    {{ 'cancel' | translate }}
  </button>
  <button
    cogDataId="btn-edit-connector-group-dialog"
    mat-flat-button
    color="primary"
    [disabled]="!groupNameControl.value"
    (click)="submit()">
    {{ (data ? 'update' : 'create') | translate }}
  </button>
</div>
