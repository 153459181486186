import { Component, Input } from '@angular/core';

import { HelixIntlService } from '../../helix-intl.service';
import { NgClass, NgIf } from '@angular/common';
import { IconModule } from '../icon/icon.module';

/**
 * Text sizes
 * Recommended size in different contexts
 * sm: dashboard cards
 * md: page with no data
 * lg: onboarding screen
 */
export enum CardSize {
  'sm' = 'sm',
  'md' = 'md',
  'lg' = 'lg',
}

/**
 * Icon sizes
 */
export enum BlankCardIconSize {
  'xs' = 'xs',
  'sm' = 'sm',
  'md' = 'md',
  'lg' = 'lg',
}

/**
 * This component renders no data state for dashcard.
 *
 * @example
 * <cog-blank-card
 *   size="sm"
 *   message="title"
 *   subMessage="subtitle">
 * </cog-blank-card>
 */
@Component({
  selector: 'cog-blank-card',
  templateUrl: './blank-card.component.html',
  styleUrls: ['./blank-card.component.scss'],
  standalone: true,
  imports: [IconModule, NgIf, NgClass]
})
export class BlankCardComponent {
  /**
   * Specifies the background image.
   */
  @Input() type = 'helix:no-data-img-general';

  /**
   * Specifies the message below no data image. Default to "No Data Available".
   */
  @Input() message = this.intl.noDataAvailable;

  /** Specifies weather to show or hide the message. */
  @Input() showMessage = true;

  /**
   * Sub Message Description
   */
  @Input() subMessage?: string;

  /**
   * Specifies size of the message and subMessage.
   */
  @Input() size: CardSize = CardSize.md;

  /**
   * Specifies icon size
   */
  @Input() iconSize?: BlankCardIconSize;

  constructor(private intl: HelixIntlService) {}
}
