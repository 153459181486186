import { AngularMaterialModule } from './../../angular-material.module';
import { AppPanelComponent } from './app-panel.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

const components = [
  AppPanelComponent
];

@NgModule({
  imports: [
    AngularMaterialModule,
    CommonModule,
    ...components
  ],
  exports: components
})
export class AppPanelModule { }
