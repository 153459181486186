import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IrisAccessDirective } from './iris-access.directive';
import { IrisHideDirective } from './iris-hide.directive';

const Directives = [
  IrisAccessDirective,
  IrisHideDirective,
];

@NgModule({
  declarations: [
    ...Directives,
  ],
  exports: [
    ...Directives,
  ],
  imports: [
    CommonModule
  ]
})
export class IrisContextModule { }
