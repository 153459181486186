import {
  AfterViewInit,
  Component, EventEmitter, Output
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { CaModel, CertificateAuthorityResponseAPI, CertificateAuthorityType } from './certificate-authority-interface.model';

@Component({
  selector: 'coh-certificate-authority-interface',
  templateUrl: './certificate-authority-interface.component.html',
  styleUrls: ['./certificate-authority-interface.component.scss'],
})
export class CertificateAuthorityInterfaceComponent implements AfterViewInit {

  /**
   * Output to delivery selected certificate list to parent component
   */
  @Output()
  selectedCertificates:
    EventEmitter<CaModel['typeOfCa'][]> = new EventEmitter<CaModel['typeOfCa'][]>();

  /**
   * Cluster user interface option type
   */
  clusterUserInterface: CaModel['typeOfCa'] = 'Self-Signed';

  /**
   * Cluster certificate option type
   */
  clusterCertificate: CaModel['typeOfCa']  = 'Self-Signed';

  /**
   * Agents option type
   */
  agents: CaModel['typeOfCa']  = 'Self-Signed';

  /**
   * Ephemeral agent option type
   */
  ephemeralAgent: CaModel['typeOfCa'] = 'Self-Signed';

  /**
   * Reference to Cluster User Interface row data
   */
  clusterUserInterfaceData: CertificateAuthorityType = {
    type: 'clusterUserInterface',
    title: 'certificateAuthorityDialog.clusterUserInterface',
    subtitle: 'certificateAuthorityDialog.clusterUserInterfaceSubtitle'
  };

  /**
   * Reference to Cluster Certificate row data
   */
  clusterCertificateData: CertificateAuthorityType = {
    type: 'clusterCertificate',
    title: 'certificateAuthorityDialog.clusterCertificates',
    subtitle: 'certificateAuthorityDialog.clusterCertificatesSubtitle'
  };

  /**
   * Reference to Agents row data
   */
  agentsData: CertificateAuthorityType = {
    type: 'agents',
    title: 'certificateAuthorityDialog.agents',
    subtitle: 'certificateAuthorityDialog.agentsSubtitle'
  };

  /**
   * Reference to Ephemeral Agent row data
   */
  ephemeralAgentData: CertificateAuthorityType = {
    type: 'ephemeralAgent',
    title: 'certificateAuthorityDialog.ephemeralAgent',
    subtitle: 'certificateAuthorityDialog.ephemeralAgentSubtitle'
  };

  /**
   * Keep all row data to display in the data table
   */
  allInterfaceTypes: CertificateAuthorityType[] = [
    this.clusterUserInterfaceData,
    this.clusterCertificateData,
    this.agentsData,
    this.ephemeralAgentData
  ];

  /**
   * Displayed columns
   */
  displayedColumns: string[] = [
    'certificateAuthorityDialog.componentColumn',
    'certificateAuthorityDialog.securedCARecommendedColumn',
    'certificateAuthorityDialog.untrustedCertificateColumn'
  ];

  showNotSecuredData = true;

  /**
   * ngAfterViewInit lifecycle hook.
   */
  ngAfterViewInit () {

    this.getCurrentSavedCertificates().subscribe((res) => {
      if (res.every((item: CertificateAuthorityResponseAPI) => item.typeOfCa !== 'Self-Signed')) {
        this.updateColumns();
        this.showNotSecuredData = false;
      } else {
        this.showNotSecuredData = true;
      }

      // TODO - After API Integration maybe need a litle change to insert data
      res.forEach((item: CertificateAuthorityResponseAPI) => {
        this[item.type] = item.typeOfCa;
      });
    });
  }

  getCurrentSavedCertificates(): Observable<CertificateAuthorityResponseAPI[]> {
    // TODO - To be implemented with API Integration

    /**
     * To test hide not secured column from certificate management -> item menu -> Certificate Settings
     * set all typeOfCa below to 'External CA' or 'Cohesity CA'
     */
    const response: CertificateAuthorityResponseAPI[] = [
      {type: 'clusterUserInterface', typeOfCa: 'Self-Signed'},
      {type: 'clusterCertificate', typeOfCa: 'Cohesity CA'},
      {type: 'agents', typeOfCa: 'Cohesity CA'},
      {type: 'ephemeralAgent', typeOfCa: 'Cohesity CA'}
    ];

    return of(response);
  }

  updateColumns() {
    this.displayedColumns = [
      'certificateAuthorityDialog.componentColumn',
      'certificateAuthorityDialog.securedCAColumn'
    ];
  }

  /**
   * Send a certificate list of selected options to a parent component
   */
  updateSelectedCa() {
    this.selectedCertificates.emit(
      [this.clusterUserInterface, this.clusterCertificate, this.agents, this.ephemeralAgent]
    );
  }

  /**
   * Set into variable the selected option and send to a parent component
   *
   * @param interfaceName
   * @param selectedCa
   * @returns
   */
  selectCa (interfaceName: CaModel['interfaceName'], selectedCa: CaModel['typeOfCa']) {
    switch(interfaceName) {
      case 'clusterUserInterface' : {
        this.clusterUserInterface = selectedCa;
        this.updateSelectedCa();
        break;
      }
      case 'clusterCertificate' : {
        this.clusterCertificate = selectedCa;
        this.updateSelectedCa();
        break;
      }
      case 'agents' : {
        this.agents = selectedCa;
        this.updateSelectedCa();
        break;
      }
      case 'ephemeralAgent' : {
        this.ephemeralAgent = selectedCa;
        this.updateSelectedCa();
        break;
      }
    }
  }
}
