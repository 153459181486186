import { Pipe, PipeTransform } from '@angular/core';
import { IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';

import { ArchivalOwnershipContext } from '../constants';

/**
 * Array of location and target names which get replaced with "Helios" for Dms
 * users.
 */
const LocationNames = [
  'Archival',
  'S3CADTarget',
];

/**
 * Array of target types which get replaced with "Helios" for Dms users.
 */
const TargetTypes = [
  'Cloud',
  'kCloud',
];

@Pipe({
  name: 'snapshotLocation'
})
export class SnapshotLocationPipe implements PipeTransform {
  constructor(
    private irisContextService: IrisContextService,
    private translateService: TranslateService) {
  }

  /**
   * Function to return the snapshot location name to show in the UI based on
   * the passed name and an optional target type.
   *
   * @param value The snapshot location name.
   * @param targetType The snapshot target type.
   * @returns The snapshot location name to show in the UI.
   */
  transform(value: string, ownershipContext?: ArchivalOwnershipContext, targetType?: string): string {
    if (isDmsScope(this.irisContextService.irisContext) &&
      (LocationNames.includes(value) || TargetTypes.includes(targetType))) {
      // Always show the location name as "Helios" whenever it is one of the
      // predetermined internal location names or a cloud target.
      // S3 bucket names are not directly exposed in the UI.
      return this.translateService.instant('helios');
    }

    if (ownershipContext === 'FortKnox') {
      // If we are using ownershipContext, then we have a name for the vault, so we can use that for our location.
      // Show "RpaasArchival" location as "Cloud Vault".
      return value;
    }

    return value;
  }
}
