// Factory: ReportsServiceFormatter

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.reports')
    .factory('ReportsServiceFormatter', ReportsServiceFormatterFn);

  function ReportsServiceFormatterFn(_, JobRunsServiceFormatter,
    DAY_PERIODICITY, moment, SOURCE_KEYS, TenantService) {

    return {
      convertStatuses: convertStatuses,
      formatReportScheduleParams: formatReportScheduleParams,
      formatBackupJobRuns: formatBackupJobRuns,
      transformTrendObjects: transformTrendObjects,
    };

    function transformTrendObjects(trendObjects, params) {
      var rollup = params.rollup || 'day';
      var currentTrendUsecs = params.startTimeUsecs;
      var trendSlots = [];
      var _trendAggregates = {
        total: 0,
        successful: 0,
        failed: 0,
        cancelled: 0,
        warning: 0,
        running: 0,
      };

      while (currentTrendUsecs < params.endTimeUsecs) {
        trendSlots.push(currentTrendUsecs);
        currentTrendUsecs = moment(currentTrendUsecs/1000).tz(params.timezone)
          .add(1, rollup+'s').valueOf() * 1000;
      }

      trendObjects = (trendObjects || []).map(function decorateTrendFn(object) {
        var sourceKey = SOURCE_KEYS[object.environment];

        _trendAggregates = {
          total: _trendAggregates.total + (object.total || 0),
          successful: _trendAggregates.successful + (object.successful || 0),
          failed: _trendAggregates.failed + (object.failed || 0),
          cancelled: _trendAggregates.cancelled + (object.cancelled || 0),
          warning: _trendAggregates.warning + (object.warning || 0),
          running: _trendAggregates.running + (object.running || 0),
        };

        // NOTE: intentionally not marking this as a decorator so that it will
        // function with source related filters.
        object.protectionSource = {
          id: object.id,
          environment: object.environment,
          name: object.name,
        };
        object.protectionSource[sourceKey] = {
          type: object.type,
        };

        object._trendMap =
          _.keyBy(object.trends || [], function keyByUsecs(trendInfo) {
            return trendInfo.trendStartTimeUsecs;
          });

        return object;
      });

      _trendAggregates.objectCount = trendObjects.length;

      return {
        _trendAggregates: _trendAggregates,
        _trendSlots: trendSlots,
        _trendObjects: _.sortBy(trendObjects, function sortByFailPct(object) {
          return object.successful / object.total;
        }),
      };
    }

    /**
     * Transforms /backupjobruns data so that the array is a list of runs rather
     * than an array of jobs with nested run info.
     *
     * @method formatBackupJobRuns
     * @param   {object[]}   jobsWithRuns   response.data from /backupjobruns
     * @returns {object[]}   list of runs
     */
    function formatBackupJobRuns(jobsWithRuns) {
      return jobsWithRuns.reduce(function reduceToRuns(runs, jobWithRuns) {
        // Build a list of runs for this paticular job.
        var theJobsRuns = (jobWithRuns.backupJobRuns.protectionRuns || []).map(
          function mapProtectionRuns(protectionRun) {
            // combine active and finished tasks so we have a full working set.
            var copyTasks = [].concat(
              protectionRun.copyRun.activeTasks || [],
              protectionRun.copyRun.finishedTasks || []
            );

            // Run this through the standard formatter because the aggregated
            // _rxTransferInfo is needed.
            JobRunsServiceFormatter.transformProtectionRun(protectionRun);

            protectionRun.archivalTasks =
              _.filter(copyTasks, ['snapshotTarget.type', 3]);

            protectionRun.replicationTasks =
              _.filter(copyTasks, ['snapshotTarget.type', 2]);

            // Add the job to each run for reference.
            protectionRun.job = jobWithRuns.backupJobRuns.jobDescription;

            return protectionRun;
          }
        );

        // Then add them to the running list of runs.
        return runs.concat(theJobsRuns);
      }, []);
    }

    /**
     * builds API body object and calls necessary methods to populate fields
     * before it is sent to backend
     *
     * @param     {object}    reportFilters     object containing all filters
     *                                          selected in reports sidebar
     *
     * @param     {object}    scheduleFilters    object containing all values
     *                                           selected in scheduler modal
     *
     * @return    {object}                       formatted object ready to be
     *                                           submitted to API
     */
    function formatReportScheduleParams(reportFilters, scheduleFilters) {
      const isObjHeatmapReport =
        reportFilters.reportType === 'kProtectedObjectsTrendsReport';
      reportFilters.typeAhead = reportFilters.typeAhead || {};

      return {
        id: reportFilters.reportId,
        enableRecurringEmail: scheduleFilters.enableRecurringEmail,
        scheduleJobParameters: {
          reportJobParameter: {
            receiverEmails: scheduleFilters.emails || [],
            reports: [{
              parameters: extendWithTenantIdVecParams({
                excludeUsersWithinAlertThreshold: reportFilters.excludeUsersWithinAlertThreshold,
                groupBy: reportFilters.groupBy,
                jobId: reportFilters.jobId,
                jobName: reportFilters.jobName,
                lastNDays: Number(reportFilters.lastNDays),
                objectIds: formatObjectIds(reportFilters.objectId ?
                  reportFilters.objectId : reportFilters.protectedObjectIds),
                registeredSourceIds: reportFilters.registeredSourceIds,
                registeredSourceId: reportFilters.registeredSourceId,
                runStatus: reportFilters.runStatus &&
                  convertStatuses(reportFilters),
                consecutiveFailures: reportFilters.consecutiveFailures,
                viewBoxId: _.get(reportFilters.viewBox, 'viewBoxId'),
                vaultIds: reportFilters.vaultIds,
                vmName: reportFilters.vmName,

                // Must cast empty string as undefined
                viewName: reportFilters.viewName || reportFilters.typeAhead.name || undefined,
                unixUid: Number(reportFilters.unixUid),
                sid: reportFilters.sid,
                rollup: reportFilters.rollup,
                hostOsType: reportFilters.agentOsType ?
                  _.map(reportFilters.agentOsType, 'name') : undefined,
                healthStatus: reportFilters.agentHealthTypes ?
                  _.map(reportFilters.agentHealthTypes, 'name') : undefined,
                compactVersion: reportFilters.agentVersion,

                // NOTE: Most reports use objectType parameter, but not the
                // object heatmap report. It's a special snowflake.
                objectType: !isObjHeatmapReport && reportFilters.objectType ?
                  reportFilters.objectType.enum : undefined,
                environment: isObjHeatmapReport && reportFilters.objectType ?
                  reportFilters.objectType.enum : undefined,

                // send timezone value from applied timezone filter else pick
                // it from email schedule.
                timezone: reportFilters.timezone || scheduleFilters.timezone,
              }, reportFilters.tenantIds),
              type: reportFilters.reportType,
              outputFormat: scheduleFilters.format,
            }]
          },
        },
        schedules: buildScheduleParams(scheduleFilters),
        type: 'kSCHEDULER_JOB_REPORT',
      };
    }

    /**
     * sends the tenantIds under the json key 'tenantIdVec' instead of
     * 'tenantIds'
     *
     * @param    {object}   params      API filter parameters to extend.
     *
     * @param    {Array}    tenantIds   A list of tenant Ids.
     *
     * @return   {object}   Return modified params with tenants params.
     */
    function extendWithTenantIdVecParams(params, tenantIds){
      params = TenantService.extendWithTenantParams(params, tenantIds);
      if (params.tenantIds) {
        params.tenantIdVec = params.tenantIds;
        delete params.tenantIds;
      }

      return params;
    }

    /**
     * if objectIds filter is being used, this function will force them to
     * type number because they can sometimes be strings
     *
     * @param     {Array}    objectIds    array of objectIds
     *
     * @return    {Array}                 array of objectIds
     */
    function formatObjectIds(objectIds) {
      if (!objectIds) { return; }

      var ids = [].concat(objectIds);

      return ids.map(function formatIds(id) {
        return Number(id);
      });
    }

    /**
     * builds the schdule params array according to selected values in
     * scheduler modal
     *
     * @param     {object}    scheduleFilters    object that containes selected
     *                                           values from scheduler modal
     *
     * @return    {array}                        array of objects that contains
     *                                           selected day(s) and hour for
     *                                           the scheduled report
     */
    function buildScheduleParams(scheduleFilters) {
      if (!scheduleFilters.scheduleDays) {
        return;
      }

      return scheduleFilters.scheduleDays.map(function(day) {
        return {
          day: DAY_PERIODICITY.indexOf(day),
          hour: scheduleFilters.scheduleTime.hour,
          timezone: scheduleFilters.timezone,
        };
      });
    }

    /**
    * protectionSourcesJobsRun API call requires different filters
    * than our other reports. these statuses need to be normalized
    * on the backend, until then we shall use this method
    *
    * @param  {array} filters ReportFilters Object
    * @param  {string} key    key containing the statuses to be converted
    * @return {array} array of strings converted to correct values for API
    */
    function convertStatuses(filters, key = 'runStatus') {
      const statuses = filters[key];

      if (filters.reportType === 'kBackupSummaryReport')
        return statuses;

      const statusConversion = {
        // when sending to server
        kBackupJobSuccess: 'kSuccess',
        kBackupJobRunning: 'kRunning',
        kBackupJobFailure: 'kError',
        kBackupJobCanceled: 'kCancelled',

        // when transforming from server values
        kSuccess: 'kBackupJobSuccess',
        kRunning: 'kBackupJobRunning',
        kError: 'kBackupJobFailure',
        kCancelled: 'kBackupJobCanceled',
      };

      return statuses.map(
        function convertStatus(status) {
          return statusConversion[status];
      });
    }
  }
})(angular);
