/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SourcesForSid } from '../models/sources-for-sid';
import { UpdateSourcesForPrincipalsParams } from '../models/update-sources-for-principals-params';
import { Principal } from '../models/principal';
import { User } from '../models/user';
import { UserParameters } from '../models/user-parameters';
import { UserDeleteParameters } from '../models/user-delete-parameters';
import { UpdateLinuxPasswordResult } from '../models/update-linux-password-result';
import { UpdateLinuxPasswordReqParams } from '../models/update-linux-password-req-params';
import { LinuxSupportUserBashShellAccessResult } from '../models/linux-support-user-bash-shell-access-result';
import { LinuxSupportUserBashShellAccessReqParams } from '../models/linux-support-user-bash-shell-access-req-params';
import { LinuxSupportUserSudoAccessResult } from '../models/linux-support-user-sudo-access-result';
import { LinuxSupportUserSudoAccessReqParams } from '../models/linux-support-user-sudo-access-req-params';
import { NewS3SecretAccessKey } from '../models/new-s3secret-access-key';
import { ResetS3SecretKeyParameters } from '../models/reset-s3secret-key-parameters';
import { SessionUser } from '../models/session-user';
import { VerifyOtpCodeParams } from '../models/verify-otp-code-params';
@Injectable({
  providedIn: 'root',
})
class PrincipalsService extends __BaseService {
  static readonly ListSourcesForPrincipalsPath = '/public/principals/protectionSources';
  static readonly UpdateSourcesForPrincipalsPath = '/public/principals/protectionSources';
  static readonly SearchPrincipalsPath = '/public/principals/searchPrincipals';
  static readonly GetSessionUserPath = '/public/sessionUser';
  static readonly GetUsersPath = '/public/users';
  static readonly UpdateUserPath = '/public/users';
  static readonly CreateUserPath = '/public/users';
  static readonly DeleteUsersPath = '/public/users';
  static readonly UpdateLinuxCredentialsPath = '/public/users/linuxPassword';
  static readonly LinuxSupportUserBashShellAccessPath = '/public/users/linuxSupportUserBashShellAccess';
  static readonly LinuxSupportUserSudoAccessPath = '/public/users/linuxSupportUserSudoAccess';
  static readonly GetUserPrivilegesPath = '/public/users/privileges';
  static readonly ResetS3SecretKeyPath = '/public/users/s3SecretKey';
  static readonly VerifyOtpCodePath = '/public/verify-otp';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns the Protection Sources objects and View names that the principals
   * have permissions to access.
   *
   * From the passed in list principals (specified by SIDs),
   * return the list of Protection Sources objects and View names that each
   * principal has permission to access.
   * @param params The `PrincipalsService.ListSourcesForPrincipalsParams` containing the following parameters:
   *
   * - `sids`: Specifies a list of security identifiers (SIDs) that specify user or
   *   group principals.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListSourcesForPrincipalsResponse(params: PrincipalsService.ListSourcesForPrincipalsParams): __Observable<__StrictHttpResponse<Array<SourcesForSid>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.sids || []).forEach(val => {if (val != null) __params = __params.append('sids', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/principals/protectionSources`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SourcesForSid>>;
      })
    );
  }
  /**
   * Returns the Protection Sources objects and View names that the principals
   * have permissions to access.
   *
   * From the passed in list principals (specified by SIDs),
   * return the list of Protection Sources objects and View names that each
   * principal has permission to access.
   * @param params The `PrincipalsService.ListSourcesForPrincipalsParams` containing the following parameters:
   *
   * - `sids`: Specifies a list of security identifiers (SIDs) that specify user or
   *   group principals.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListSourcesForPrincipals(params: PrincipalsService.ListSourcesForPrincipalsParams): __Observable<Array<SourcesForSid>> {
    return this.ListSourcesForPrincipalsResponse(params).pipe(
      __map(_r => _r.body as Array<SourcesForSid>)
    );
  }

  /**
   * Set the Protection Sources and Views that the specified principal
   * has permissions to access.
   *
   * Specify the security identifier (SID) of the principal to grant access
   * permissions for.
   * @param params The `PrincipalsService.UpdateSourcesForPrincipalsParams` containing the following parameters:
   *
   * - `body`: Request to set access permissions to Protection Sources and Views
   *   for a principal.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  UpdateSourcesForPrincipalsResponse(params: PrincipalsService.UpdateSourcesForPrincipalsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/principals/protectionSources`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Set the Protection Sources and Views that the specified principal
   * has permissions to access.
   *
   * Specify the security identifier (SID) of the principal to grant access
   * permissions for.
   * @param params The `PrincipalsService.UpdateSourcesForPrincipalsParams` containing the following parameters:
   *
   * - `body`: Request to set access permissions to Protection Sources and Views
   *   for a principal.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  UpdateSourcesForPrincipals(params: PrincipalsService.UpdateSourcesForPrincipalsParams): __Observable<null> {
    return this.UpdateSourcesForPrincipalsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * List the user and group principals that match the filter criteria specified
   * using parameters.
   *
   * Optionally, limit the search results by specifying security identifiers (SIDs),
   * an object class (user or group) or a substring.
   * You can specify SIDs or a substring but not both.
   * @param params The `PrincipalsService.SearchPrincipalsParams` containing the following parameters:
   *
   * - `sids`: Optionally filter by a list of security identifiers (SIDs) found
   *   in the specified domain. Only principals matching
   *   the specified SIDs are returned.
   *   If specified, a 'search' parameter should not be specified.
   *
   * - `search`: Optionally filter by matching a substring. Only principals in
   *   the with a name or sAMAccountName that matches
   *   part or all of the specified substring are returned.
   *   If specified, a 'sids' parameter should not be specified.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `objectClass`: Optionally filter by a principal object class such as 'kGroup' or 'kUser'.
   *   If 'kGroup' is specified, only group principals are returned.
   *   If 'kUser' is specified, only user principals are returned.
   *   If not specified, both group and user principals are returned.
   *   'kUser' specifies a user object class.
   *   'kGroup' specifies a group object class.
   *   'kComputer' specifies a computer object class.
   *   'kWellKnownPrincipal' specifies a well known principal.
   *
   * - `includeComputers`: Specifies if Computer/GMSA accounts need to be included in this search.
   *
   * - `domain`: Specifies the domain name of the principals to search. If specified
   *   the principals in that domain are searched. Domain could be an
   *   Active Directory domain joined by the Cluster or any one of the
   *   trusted domains of the Active Directory domain or the LOCAL domain.
   *   If not specified, all the domains are searched.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  SearchPrincipalsResponse(params: PrincipalsService.SearchPrincipalsParams): __Observable<__StrictHttpResponse<Array<Principal>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.sids || []).forEach(val => {if (val != null) __params = __params.append('sids', val.toString())});
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.objectClass != null) __params = __params.set('objectClass', params.objectClass.toString());
    if (params.includeComputers != null) __params = __params.set('includeComputers', params.includeComputers.toString());
    if (params.domain != null) __params = __params.set('domain', params.domain.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/principals/searchPrincipals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Principal>>;
      })
    );
  }
  /**
   * List the user and group principals that match the filter criteria specified
   * using parameters.
   *
   * Optionally, limit the search results by specifying security identifiers (SIDs),
   * an object class (user or group) or a substring.
   * You can specify SIDs or a substring but not both.
   * @param params The `PrincipalsService.SearchPrincipalsParams` containing the following parameters:
   *
   * - `sids`: Optionally filter by a list of security identifiers (SIDs) found
   *   in the specified domain. Only principals matching
   *   the specified SIDs are returned.
   *   If specified, a 'search' parameter should not be specified.
   *
   * - `search`: Optionally filter by matching a substring. Only principals in
   *   the with a name or sAMAccountName that matches
   *   part or all of the specified substring are returned.
   *   If specified, a 'sids' parameter should not be specified.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `objectClass`: Optionally filter by a principal object class such as 'kGroup' or 'kUser'.
   *   If 'kGroup' is specified, only group principals are returned.
   *   If 'kUser' is specified, only user principals are returned.
   *   If not specified, both group and user principals are returned.
   *   'kUser' specifies a user object class.
   *   'kGroup' specifies a group object class.
   *   'kComputer' specifies a computer object class.
   *   'kWellKnownPrincipal' specifies a well known principal.
   *
   * - `includeComputers`: Specifies if Computer/GMSA accounts need to be included in this search.
   *
   * - `domain`: Specifies the domain name of the principals to search. If specified
   *   the principals in that domain are searched. Domain could be an
   *   Active Directory domain joined by the Cluster or any one of the
   *   trusted domains of the Active Directory domain or the LOCAL domain.
   *   If not specified, all the domains are searched.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  SearchPrincipals(params: PrincipalsService.SearchPrincipalsParams): __Observable<Array<Principal>> {
    return this.SearchPrincipalsResponse(params).pipe(
      __map(_r => _r.body as Array<Principal>)
    );
  }

  /**
   * Get the information of the logged in user.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetSessionUserResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/sessionUser`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * Get the information of the logged in user.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetSessionUser(regionId?: string,
    accessClusterId?: number): __Observable<User> {
    return this.GetSessionUserResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * List the users on the Cohesity Cluster that match the filter criteria
   * specified using parameters.
   *
   * If no parameters are specified, all users currently on the Cohesity Cluster
   * are returned. Specifying parameters filters the results that are returned.
   * @param params The `PrincipalsService.GetUsersParams` containing the following parameters:
   *
   * - `usernames`: Optionally specify a list of usernames to filter by. All users containing
   *   username will be returned.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `partialMatch`: Optionally specify whether to enable partial match. If set, all users
   *   with name containing Usernames will be returned. If set to false, only
   *   users with exact the same name as Usernames will be returned. By default
   *   this parameter is set to true.
   *
   * - `emailAddresses`: Optionally specify a list of email addresses to filter by.
   *
   * - `domain`: Optionally specify a domain to filter by.
   *   If no domain is specified, all users on the Cohesity Cluster are searched.
   *   If a domain is specified, only users on the Cohesity Cluster
   *   associated with that domain are searched.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetUsersResponse(params: PrincipalsService.GetUsersParams): __Observable<__StrictHttpResponse<Array<User>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.usernames || []).forEach(val => {if (val != null) __params = __params.append('usernames', val.toString())});
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.partialMatch != null) __params = __params.set('partialMatch', params.partialMatch.toString());
    (params.emailAddresses || []).forEach(val => {if (val != null) __params = __params.append('emailAddresses', val.toString())});
    if (params.domain != null) __params = __params.set('domain', params.domain.toString());
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<User>>;
      })
    );
  }
  /**
   * List the users on the Cohesity Cluster that match the filter criteria
   * specified using parameters.
   *
   * If no parameters are specified, all users currently on the Cohesity Cluster
   * are returned. Specifying parameters filters the results that are returned.
   * @param params The `PrincipalsService.GetUsersParams` containing the following parameters:
   *
   * - `usernames`: Optionally specify a list of usernames to filter by. All users containing
   *   username will be returned.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `partialMatch`: Optionally specify whether to enable partial match. If set, all users
   *   with name containing Usernames will be returned. If set to false, only
   *   users with exact the same name as Usernames will be returned. By default
   *   this parameter is set to true.
   *
   * - `emailAddresses`: Optionally specify a list of email addresses to filter by.
   *
   * - `domain`: Optionally specify a domain to filter by.
   *   If no domain is specified, all users on the Cohesity Cluster are searched.
   *   If a domain is specified, only users on the Cohesity Cluster
   *   associated with that domain are searched.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetUsers(params: PrincipalsService.GetUsersParams): __Observable<Array<User>> {
    return this.GetUsersResponse(params).pipe(
      __map(_r => _r.body as Array<User>)
    );
  }

  /**
   * Update an existing user on the Cohesity Cluster. Only user settings
   * on the Cohesity Cluster are updated. No changes are made to the
   * referenced user principal on the Active Directory.
   *
   * Returns the user that was updated on the Cohesity Cluster.
   * @param params The `PrincipalsService.UpdateUserParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update an existing user.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateUserResponse(params: PrincipalsService.UpdateUserParams): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * Update an existing user on the Cohesity Cluster. Only user settings
   * on the Cohesity Cluster are updated. No changes are made to the
   * referenced user principal on the Active Directory.
   *
   * Returns the user that was updated on the Cohesity Cluster.
   * @param params The `PrincipalsService.UpdateUserParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update an existing user.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateUser(params: PrincipalsService.UpdateUserParams): __Observable<User> {
    return this.UpdateUserResponse(params).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * Create or add a new user to the Cohesity Cluster.
   *
   * If an Active Directory domain is specified, a new user is added to the
   * Cohesity Cluster for the specified Active Directory user principal.
   * If the LOCAL domain is specified, a new user is created directly in
   * the default LOCAL domain on the Cohesity Cluster.
   *
   * Returns the created or added user.
   * @param params The `PrincipalsService.CreateUserParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to add or create a new user.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateUserResponse(params: PrincipalsService.CreateUserParams): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * Create or add a new user to the Cohesity Cluster.
   *
   * If an Active Directory domain is specified, a new user is added to the
   * Cohesity Cluster for the specified Active Directory user principal.
   * If the LOCAL domain is specified, a new user is created directly in
   * the default LOCAL domain on the Cohesity Cluster.
   *
   * Returns the created or added user.
   * @param params The `PrincipalsService.CreateUserParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to add or create a new user.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateUser(params: PrincipalsService.CreateUserParams): __Observable<User> {
    return this.CreateUserResponse(params).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * Delete one or more users on the Cohesity Cluster.
   *
   * Only users from the same domain can be deleted by a single request.
   * If the Cohesity user was created for an Active Directory user, the referenced
   * principal user on the Active Directory domain is NOT deleted.
   * Only the user on the Cohesity Cluster is deleted.
   * Returns Success if the specified users are deleted.
   * @param params The `PrincipalsService.DeleteUsersParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to delete one or more users on the Cohesity Cluster.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteUsersResponse(params: PrincipalsService.DeleteUsersParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete one or more users on the Cohesity Cluster.
   *
   * Only users from the same domain can be deleted by a single request.
   * If the Cohesity user was created for an Active Directory user, the referenced
   * principal user on the Active Directory domain is NOT deleted.
   * Only the user on the Cohesity Cluster is deleted.
   * Returns Success if the specified users are deleted.
   * @param params The `PrincipalsService.DeleteUsersParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to delete one or more users on the Cohesity Cluster.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteUsers(params: PrincipalsService.DeleteUsersParams): __Observable<null> {
    return this.DeleteUsersResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update linux user password.
   * @param params The `PrincipalsService.UpdateLinuxCredentialsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateLinuxCredentialsResponse(params: PrincipalsService.UpdateLinuxCredentialsParams): __Observable<__StrictHttpResponse<UpdateLinuxPasswordResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/users/linuxPassword`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateLinuxPasswordResult>;
      })
    );
  }
  /**
   * Update linux user password.
   * @param params The `PrincipalsService.UpdateLinuxCredentialsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateLinuxCredentials(params: PrincipalsService.UpdateLinuxCredentialsParams): __Observable<UpdateLinuxPasswordResult> {
    return this.UpdateLinuxCredentialsResponse(params).pipe(
      __map(_r => _r.body as UpdateLinuxPasswordResult)
    );
  }

  /**
   * Requests Linux 'support' user bash shell access.
   * @param params The `PrincipalsService.LinuxSupportUserBashShellAccessParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  LinuxSupportUserBashShellAccessResponse(params: PrincipalsService.LinuxSupportUserBashShellAccessParams): __Observable<__StrictHttpResponse<LinuxSupportUserBashShellAccessResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/users/linuxSupportUserBashShellAccess`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LinuxSupportUserBashShellAccessResult>;
      })
    );
  }
  /**
   * Requests Linux 'support' user bash shell access.
   * @param params The `PrincipalsService.LinuxSupportUserBashShellAccessParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  LinuxSupportUserBashShellAccess(params: PrincipalsService.LinuxSupportUserBashShellAccessParams): __Observable<LinuxSupportUserBashShellAccessResult> {
    return this.LinuxSupportUserBashShellAccessResponse(params).pipe(
      __map(_r => _r.body as LinuxSupportUserBashShellAccessResult)
    );
  }

  /**
   * Requests Linux 'support' user sudo access.
   * @param params The `PrincipalsService.LinuxSupportUserSudoAccessParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  LinuxSupportUserSudoAccessResponse(params: PrincipalsService.LinuxSupportUserSudoAccessParams): __Observable<__StrictHttpResponse<LinuxSupportUserSudoAccessResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/users/linuxSupportUserSudoAccess`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LinuxSupportUserSudoAccessResult>;
      })
    );
  }
  /**
   * Requests Linux 'support' user sudo access.
   * @param params The `PrincipalsService.LinuxSupportUserSudoAccessParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  LinuxSupportUserSudoAccess(params: PrincipalsService.LinuxSupportUserSudoAccessParams): __Observable<LinuxSupportUserSudoAccessResult> {
    return this.LinuxSupportUserSudoAccessResponse(params).pipe(
      __map(_r => _r.body as LinuxSupportUserSudoAccessResult)
    );
  }

  /**
   * List the privileges of the session user.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetUserPrivilegesResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/users/privileges`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * List the privileges of the session user.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetUserPrivileges(regionId?: string,
    accessClusterId?: number): __Observable<Array<string>> {
    return this.GetUserPrivilegesResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * Reset the S3 secret access key for the specified user on the Cohesity Cluster.
   *
   * Returns the new key that was generated.
   * @param params The `PrincipalsService.ResetS3SecretKeyParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to reset the S3 secret access key for the specified Cohesity user.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return New S3 Secret Access Key.
   */
  ResetS3SecretKeyResponse(params: PrincipalsService.ResetS3SecretKeyParams): __Observable<__StrictHttpResponse<NewS3SecretAccessKey>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/users/s3SecretKey`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NewS3SecretAccessKey>;
      })
    );
  }
  /**
   * Reset the S3 secret access key for the specified user on the Cohesity Cluster.
   *
   * Returns the new key that was generated.
   * @param params The `PrincipalsService.ResetS3SecretKeyParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to reset the S3 secret access key for the specified Cohesity user.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return New S3 Secret Access Key.
   */
  ResetS3SecretKey(params: PrincipalsService.ResetS3SecretKeyParams): __Observable<NewS3SecretAccessKey> {
    return this.ResetS3SecretKeyResponse(params).pipe(
      __map(_r => _r.body as NewS3SecretAccessKey)
    );
  }

  /**
   * Verify the OTP code .
   *
   * Returns the session user info if the verification is successful.
   * @param body Request to verify OTP code.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  VerifyOtpCodeResponse(body: VerifyOtpCodeParams,
    accessClusterId?: number): __Observable<__StrictHttpResponse<SessionUser>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/verify-otp`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SessionUser>;
      })
    );
  }
  /**
   * Verify the OTP code .
   *
   * Returns the session user info if the verification is successful.
   * @param body Request to verify OTP code.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  VerifyOtpCode(body: VerifyOtpCodeParams,
    accessClusterId?: number): __Observable<SessionUser> {
    return this.VerifyOtpCodeResponse(body, accessClusterId).pipe(
      __map(_r => _r.body as SessionUser)
    );
  }
}

module PrincipalsService {

  /**
   * Parameters for ListSourcesForPrincipals
   */
  export interface ListSourcesForPrincipalsParams {

    /**
     * Specifies a list of security identifiers (SIDs) that specify user or
     * group principals.
     */
    sids?: Array<string>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateSourcesForPrincipals
   */
  export interface UpdateSourcesForPrincipalsParams {

    /**
     * Request to set access permissions to Protection Sources and Views
     * for a principal.
     */
    body: UpdateSourcesForPrincipalsParams;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for SearchPrincipals
   */
  export interface SearchPrincipalsParams {

    /**
     * Optionally filter by a list of security identifiers (SIDs) found
     * in the specified domain. Only principals matching
     * the specified SIDs are returned.
     * If specified, a 'search' parameter should not be specified.
     */
    sids?: Array<string>;

    /**
     * Optionally filter by matching a substring. Only principals in
     * the with a name or sAMAccountName that matches
     * part or all of the specified substring are returned.
     * If specified, a 'sids' parameter should not be specified.
     */
    search?: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Optionally filter by a principal object class such as 'kGroup' or 'kUser'.
     * If 'kGroup' is specified, only group principals are returned.
     * If 'kUser' is specified, only user principals are returned.
     * If not specified, both group and user principals are returned.
     * 'kUser' specifies a user object class.
     * 'kGroup' specifies a group object class.
     * 'kComputer' specifies a computer object class.
     * 'kWellKnownPrincipal' specifies a well known principal.
     */
    objectClass?: 'kUser' | 'kGroup' | 'kComputer' | 'kWellKnownPrincipal';

    /**
     * Specifies if Computer/GMSA accounts need to be included in this search.
     */
    includeComputers?: boolean;

    /**
     * Specifies the domain name of the principals to search. If specified
     * the principals in that domain are searched. Domain could be an
     * Active Directory domain joined by the Cluster or any one of the
     * trusted domains of the Active Directory domain or the LOCAL domain.
     * If not specified, all the domains are searched.
     */
    domain?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetUsers
   */
  export interface GetUsersParams {

    /**
     * Optionally specify a list of usernames to filter by. All users containing
     * username will be returned.
     */
    usernames?: Array<string>;

    /**
     * TenantIds contains ids of the tenants for which objects are to be
     * returned.
     */
    tenantIds?: Array<string>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Optionally specify whether to enable partial match. If set, all users
     * with name containing Usernames will be returned. If set to false, only
     * users with exact the same name as Usernames will be returned. By default
     * this parameter is set to true.
     */
    partialMatch?: boolean;

    /**
     * Optionally specify a list of email addresses to filter by.
     */
    emailAddresses?: Array<string>;

    /**
     * Optionally specify a domain to filter by.
     * If no domain is specified, all users on the Cohesity Cluster are searched.
     * If a domain is specified, only users on the Cohesity Cluster
     * associated with that domain are searched.
     */
    domain?: string;

    /**
     * AllUnderHierarchy specifies if objects of all the tenants under the
     * hierarchy of the logged in user's organization should be returned.
     */
    allUnderHierarchy?: boolean;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateUser
   */
  export interface UpdateUserParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to update an existing user.
     */
    body?: User;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateUser
   */
  export interface CreateUserParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to add or create a new user.
     */
    body?: UserParameters;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteUsers
   */
  export interface DeleteUsersParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to delete one or more users on the Cohesity Cluster.
     */
    body?: UserDeleteParameters;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateLinuxCredentials
   */
  export interface UpdateLinuxCredentialsParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;
    body?: UpdateLinuxPasswordReqParams;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for LinuxSupportUserBashShellAccess
   */
  export interface LinuxSupportUserBashShellAccessParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;
    body?: LinuxSupportUserBashShellAccessReqParams;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for LinuxSupportUserSudoAccess
   */
  export interface LinuxSupportUserSudoAccessParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;
    body?: LinuxSupportUserSudoAccessReqParams;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ResetS3SecretKey
   */
  export interface ResetS3SecretKeyParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to reset the S3 secret access key for the specified Cohesity user.
     */
    body?: ResetS3SecretKeyParameters;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { PrincipalsService }
