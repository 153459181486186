import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { CohesitySharedFormsModule } from '@cohesity/shared-forms';
import { TranslateModule } from '@ngx-translate/core';

import {
  AnomalyResolveConfirmationComponent,
} from './anomaly-resolve-confirmation/anomaly-resolve-confirmation.component';
import { AntiRansomwareSettingsComponent } from './anti-ransomware-settings/anti-ransomware-settings.component';
import { DefaultRecoverySettingsModule } from './default-recovery-settings';
import { IgnoreConfirmationComponent } from './ignore-dialog/ignore-confirmation.component';
import { RecoveryConfirmationComponent } from './recovery-confirmation/recovery-confirmation.component';
import {
  RecoverySnapshotSelectorComponent,
} from './recovery-snapshot-selector-dialog/recovery-snapshot-selector.component';
import {
  SnapshotActionConfirmationComponent,
} from './snapshot-action-confirmation/snapshot-action-confirmation.component';
import { AntiRansomwareNotificationsComponent } from './anti-ransomware-notifications/anti-ransomware-notifications.component';

const COMPONENTS = [
  AnomalyResolveConfirmationComponent,
  AntiRansomwareNotificationsComponent,
  AntiRansomwareSettingsComponent,
  IgnoreConfirmationComponent,
  RecoveryConfirmationComponent,
  RecoverySnapshotSelectorComponent,
  SnapshotActionConfirmationComponent,
];

@NgModule({
  imports: [
    CohesityHelixModule,
    CohesitySharedFormsModule,
    CommonModule,
    DefaultRecoverySettingsModule,
    FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    MatSliderModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    TranslateModule,
  ],
  exports: [
    ...COMPONENTS,
    DefaultRecoverySettingsModule,
  ],
  declarations: [...COMPONENTS],
})
export class DialogModule {}
