import { ReportColumnConfigs } from '../common-report-items/custom-reports.service';
import { ReportSection } from '../iris-reporting.model';

/**
 * This is the layout config for the protected/unprotected objects (all objects) report.
 */
export const storageConsumptionObjectReport: ReportSection[] = [
  {
    title: 'reporting.summary',
    items: [
      {
        id: 'statlistItem',
        componentIds: ['301'],
        componentNames: ['StorageConsumptionGlanceBar'],
        params: {
          items: [
            {
              title: 'reporting.totalSources',
              translate: true,
              measurement: {
                valueKey: 'sourceUuid',
                aggregation: 'count',
                dataType: 'number',
              },
            },
            {
              title: 'reporting.totalObjects',
              translate: true,
              measurement: {
                valueKey: 'countDistinctObjectUuid',
                dataType: 'number',
              },
            },
            {
              title: 'reporting.totalDataRead',
              translate: true,
              measurement: {
                valueKey: 'totalSourceDeltaSizeBytes',
                dataType: 'bytes',
              },
            },
            {
              title: 'reporting.totalDataWritten',
              translate: true,
              measurement: {
                valueKey: 'totalDataWrittenSizeBytes',
                dataType: 'bytes',
              }
            },
          ],
        },
      },
    ],
  },
  {
    items: [
      {
        id: 'chartItem',
        componentIds: ['302'],
        componentNames: ['TotalConsumptionByObjectType'],
        title: 'reporting.totalConsumptionByObjectType',
        params: {
          chartType: 'column',
          measurements: [
            {
              valueKey: 'totalDataWrittenSizeBytes',
              dataType: 'bytes'
            },
            {
              valueKey: 'totalSourceDeltaSizeBytes',
              dataType: 'bytes'
            },
          ],
          dimensions: [
            {
              dimensionKey: 'environment',
              maxValues: 3,
            }
          ],
        },
      },
    ],
  },
  {
    title: 'reporting.data',
    isFullData: true,
    items: [
      {
        id: 'tableItem',
        componentIds: ['300'],
        componentNames: ['AllStorageConsumptionByObjects'],
        params: {
          columns: [
            {
              title: 'objectName',
              translate: true,
              key: 'objectName',
              icon: {
                key: 'objectType',
                dataType: 'objectTypeIcon',
                renderParams: {
                  environmentKey: 'environment',
                },
              },
              primary: {
                key: 'objectName',
                dataType: 'text',
              },
              subtitle: {
                key: 'environment',
                dataType: 'environmentName',
              },
            },
            {
              title: 'system',
              translate: 'true',
              key: 'system',
              primary: {
                key: 'system',
                dataType: 'text',
              },
            },
            {
              title: 'source',
              enabled: false,
              translate: 'true',
              key: 'sourceName',
              primary: {
                key: 'sourceName',
                dataType: 'text',
              },
            },
            {
              title: 'organization',
              enabled: false,
              translate: true,
              key: 'tenantName',
              primary: {
                key: 'tenantName',
                dataType: 'text',
                renderParams: {
                  emptyKey: 'naNotAvailable',
                },
              },
              featureFlag: 'ngReportSPView',
            },
            {
              title: 'snapshots',
              translate: 'true',
              key: 'snapshots',
              primary: {
                key: 'snapshots',
                dataType: 'number',
              },
            },
            {
              title: 'logicalData',
              translate: true,
              key: 'maxSourceLogicalSizeBytes',
              primary: {
                key: 'maxSourceLogicalSizeBytes',
                dataType: 'bytes',
              },
            },
            {
              title: 'dataRead',
              translate: true,
              key: 'sumSourceDeltaSizeBytes',
              primary: {
                key: 'sumSourceDeltaSizeBytes',
                dataType: 'bytes',
              },
            },
            {
              title: 'dataWritten',
              translate: true,
              key: 'sumDataWrittenSizeBytes',
              primary: {
                key: 'sumDataWrittenSizeBytes',
                dataType: 'bytes',
              },
            },
            {
              title: 'dailyChangeRate',
              translate: true,
              key: 'dailyChangeRate',
              primary: {
                key: 'dailyChangeRate',
                dataType: 'bytesChange',
                renderParams: {
                  absoluteValue: true,
                },
              },
            },
          ],
        },
      },
    ],
  },
];

/**
 * This is metadata needed to determine available chart customizations.
 */
export const storageConsumptionObjectColumns: ReportColumnConfigs = {
  objectName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  objectUuid: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  environment: {
    aggs: ['count'],
    canGroup: true,
    maxGroupingValues: 3,
    dataType: 'text',
  },
  sourceName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  sourceId: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  systemName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  systemId: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  snapshots: {
    aggs: ['sum', 'min', 'max', 'avg'],
    dataType: 'number',
  },
  maxSourceLogicalSizeBytes: {
    aggs: ['sum', 'min', 'max', 'avg'],
    dataType: 'bytes',
  },
  sumSourceDeltaSizeBytes: {
    aggs: ['sum', 'min', 'max', 'avg'],
    dataType: 'bytes',
  },
  sumDataWrittenSizeBytes: {
    aggs: ['sum', 'min', 'max', 'avg'],
    dataType: 'bytes',
  },
  dailyChangeRate: {
    aggs: ['sum', 'min', 'max', 'avg'],
    dataType: 'bytes',
  },
};
