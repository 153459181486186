import { Component, Input } from '@angular/core';

import { HelixIntlService } from '../../../../helix-intl.service';
import { DataFilter } from '../../comparators/data-filter';

/**
 * Search property filter uses a simple text input to interact with a data filter.
 */
@Component({
  selector: 'cog-search-property-filter',
  templateUrl: './search-property-filter.component.html',
  styleUrls: ['./search-property-filter.component.scss'],
})
export class SearchPropertyFilterComponent {
  /**
   * The filter associated with this component
   */
  @Input() filter: DataFilter<string>;

  /**
   * A placeholder value for the input
   */
  @Input() placeholder = '';

  /**
   * Always expand input if true.
   */
  @Input() alwaysExpand = false;

  /**
   * Cached value indicating if the input is currently focused.
   */
  private _isFocused = false;

  /**
   * Getter for value currently indicating if input is focused.
   */
  get focused() {
    return this._isFocused;
  }

  /**
   * Setter for value currently indicating if input is focused.
   */
  set focused(newVal: boolean) {
    this._isFocused = newVal;
  }

  /**
   * Cached value indicating if user is currently hovering the filter.
   */
  private _isHovered = false;

  /**
   * Getter for value indicating if user is currently hovering the filter.
   */
  get hovered() {
    return this._isHovered;
  }

  /**
   * Setter for value indicating if user is currently hovering the filter.
   */
  set hovered(newVal: boolean) {
    this._isHovered = newVal;
  }

  /**
   * Indicates if the filter should be expanded.
   */
  get expanded(): boolean {
    return this.focused || this.hovered || !!this.value;
  }

  /**
   * Sets the value
   *
   * @param   value   the filter value
   */
  @Input() set value(value: string) {
    this.filter.setValue(value);
  }

  /**
   * Gets the filter values
   *
   * @return   the filter value
   */
  get value(): string {
    const filterValue = this.filter.currentValue$.value;
    return filterValue ? filterValue.value : undefined;
  }

  constructor(public intl: HelixIntlService) {}
}
