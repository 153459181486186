import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'coh-settings-list-save-success-files',
  templateUrl: './settings-list-save-success-files.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsListSaveSuccessFilesComponent {
  /**
   * The form control for the settings list item.
   */
  @Input() control: UntypedFormControl;

  /**
   * Alternate Label to use for the control.
   */
  @Input() label: string;

}
