<ng-container *ngTemplateOutlet="bannerTemplate"></ng-container>

<!-- recursively render the nav items -->
<mat-nav-list>
  <ng-container *ngFor="let item of navList">
    <ng-container [ngSwitch]="item.type">

      <!-- sub header -->
      <ng-container *ngSwitchCase="'sub-header'">
        <div mat-subheader>{{item.displayName}}</div>
      </ng-container>

      <!-- separator -->
      <ng-container *ngSwitchCase="'separator'">
        <mat-divider></mat-divider>
      </ng-container>

      <!-- menu-item type -->
      <ng-container *ngSwitchDefault>
        <div [class.disabled]="item.disabled">
          <ng-container *ngTemplateOutlet="menuItemTemplate; context:
            {
              $implicit: {
                item: item,
                subItem: false
              }
            }">
          </ng-container>
        </div>
        <ng-container *ngIf="item.isOpen">
          <ng-container *ngFor="let subItem of item.subNavList">
            <div [class.disabled]="subItem.disabled">
              <ng-container *ngTemplateOutlet="menuItemTemplate; context:
                {
                  $implicit: {
                    item: subItem,
                    subItem: true
                  }
                }">
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</mat-nav-list>

<!-- This is a default item template for the drawer navigation. It does not
  Handle routing or link activation.
  let-ctx will holds the provided $implicit context to the template details can
  be found here https://angular.io/api/common/NgTemplateOutlet#ngTemplateOutletContext -->
<ng-template #defaultItemTemplate let-ctx>
  <cog-nav-item [isSubItem]="ctx.subItem" [item]="ctx.item"
    cogDataId="item" [inheritDataId]="true">
    {{ctx.item.displayName}}
  </cog-nav-item>
</ng-template>
