<div class="object-name">
  <cog-icon [shape]="node.protectionSource | objectIcon : { isProtected: isNodeProtected }">
  </cog-icon>
  <h4 class="title">{{node.protectionSource.name}}</h4>
</div>


<!-- General Information -->
<div class="source-meta-grouping object-info-list">

  <ul>
    <!-- Server Type -->
    <li class="source-meta-line-item" *ngIf="hypervisorSource.hostType">
      <span class="source-meta-label">{{'type' | translate}}</span>
      {{hypervisorSource.hostType }}
    </li>

    <!-- Server Size -->
    <li class="source-meta-line-item" *ngIf="node.logicalSize">
      <span class="source-meta-label">{{'size' | translate}}</span>
      {{node.logicalSize | byteSize}}
    </li>

    <!-- Version -->
    <li class="source-meta-line-item" *ngIf="hypervisorSource.version">
      <span class="source-meta-label">{{'version' | translate}}</span>
      {{hypervisorSource.version}}
    </li>

    <!-- UUID -->
    <li class="source-meta-line-item" *ngIf="uuid">
      <span class="source-meta-label">{{'uuid' | translate}}</span>
      {{uuid}}
    </li>

    <!-- SaaS Connection -->
    <li class="source-meta-line-item"  *ngIf="isSaasConnector && vmwareVMCFlag">
      <cog-icon shape="helix:saas-connection"></cog-icon>
      <span>{{ 'saasConnectorVm' | translate }}</span>
    </li>

    <!-- VMWare tools -->
    <li class="source-meta-line-item" *ngIf="hypervisorSource?.toolsRunningStatus">
      <cog-icon shape="helix:object-vmtools"></cog-icon>
      <span [ngClass]="{
        'status-ok': hypervisorSource.toolsRunningStatus === 'kGuestToolsRunning',
        'status-info': ['kUnknown', 'kGuestToolsExecutingScripts'].includes(hypervisorSource.toolsRunningStatus),
        'status-warning': hypervisorSource.toolsRunningStatus === 'kGuestToolsNotRunning'}">
        {{ ('enum.toolsRunningStatus.' + hypervisorSource.toolsRunningStatus) | translate }}
      </span>
    </li>
  </ul>
</div>

<!-- Server & App Information -->
<div class="source-meta-grouping margin-top-lg" *ngIf="isSqlHost || isCohesityAgentInstalled">
  <h5 class="title" [innerHTML]="'serverAndAppInfo' | translate"></h5>
  <ul>
    <!-- SQL Host -->
    <li class="source-meta-line-item" *ngIf="isSqlHost">
      <cog-icon shape="helix:group-database"></cog-icon>
      <span> {{'cSourceTree.sourceMeta.sqlServer' | translate}}</span>
    </li>

    <!-- Cohesity Agent Installation -->
    <li class="source-meta-line-item" *ngIf="isCohesityAgentInstalled">
      {{'cohesityAgentInstalled' | translate}}
    </li>
  </ul>
</div>

<!-- VM Migration Details -->
<div class="source-meta-grouping margin-top-lg" *ngIf="isVcenterMigrated">
  <h5 class="title" [innerHTML]="'vmMigrationInformation' | translate"></h5>
  <ul>
    <!-- Original vCenter -->
    <li class="source-meta-line-item">
      <span>
        {{'migratedFromVcenter' | translate: {vcenter: migratedVcenter} }}
      </span>
      <cog-spinner class="pull-right" size="xs" *ngIf="vcenterIsLoading"></cog-spinner>
    </li>

    <!-- Migration Date -->
    <li class="source-meta-line-item">
      {{'migrationDateColon' | translate: {migrationDate: vmMigrationUsecs / 1000 | date: 'medium'} }}
    </li>
  </ul>
</div>

<!-- HyperV Agent Information -->
<div class="source-meta-grouping margin-top-lg" *ngIf="agent">
  <h5 class="title">{{'agentInformation' | translate}}</h5>

  <ul>
    <!-- Status -->
    <li class="source-meta-line-item">
      <span class="source-meta-label">{{'status' | translate}}</span>
      <span class="{{registrationInfo.refreshErrorMessage ? 'status-critical' : 'status-ok'}}">
        {{ (registrationInfo.refreshErrorMessage ? 'refreshError' : 'healthy') | translate }}
      </span>
    </li>

    <!-- Version -->
    <li class="source-meta-line-item">
      <span class="source-meta-label">{{'version' | translate}}</span>
      {{agent.version}}
    </li>

    <!-- Registration Date -->
    <li class="source-meta-line-item">
      <span class="source-meta-label">{{'registrationDate' | translate}}</span>
      {{registrationInfo.registrationTimeUsecs | cohDate}}
    </li>

    <!-- Last Refresh -->
    <li class="source-meta-line-item" *ngIf="registrationInfo.refreshTimeUsecs">
      <span class="source-meta-label">{{'lastRefresh' | translate}}</span>
      {{registrationInfo.refreshTimeUsecs | cohDate}}
    </li>
  </ul>
</div>

<!-- VM Tags -->
<div class="source-meta-grouping margin-top-lg" *ngIf="tags?.length">
  <h5 class="title">{{'cSourceTree.sourceMeta.tags' | translate}}</h5>
  <div class="source-meta-line-item" *ngFor="let tag of tags">{{tag}}</div>
</div>

<!-- Applications -->
<div class="source-meta-grouping margin-top-lg" *ngIf="node.registrationInfo?.environments?.length">
  <h5 class="title">{{'applications' | translate}}</h5>
  <div class="source-meta-line-item" *ngFor="let env of node.registrationInfo.environments">
    {{('enum.environment.' + env) | translate}}
  </div>
</div>

<coh-protection-status-meta class="margin-top-lg"
  [node]="node"
  [protectionLabel]="'sourceTreePub.tooltips.protected' | translate">
</coh-protection-status-meta>
