import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import {
  DataIdModule,
  DataTreeNode,
  DataTreeNodeContext,
  DataTreeSelectionChangeEvent,
  DataTreeSelectionModel,
  IconModule,
} from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [CommonModule, DataIdModule, IconModule, MatFormFieldModule, TranslateModule],
  selector: 'coh-resources-tree-auto-assign',
  templateUrl: './resources-tree-auto-assign.component.html',
  styleUrls: ['./resources-tree-auto-assign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourcesTreeAutoAssignComponent<T extends DataTreeNode<any>> {
  /**
   * Returns true if the current node has been explicitly auto selected.
   */
  @HostBinding('class.auto-selected') get isAutoSelected(): boolean {
    return this.nodeContext && this.nodeContext.autoSelected;
  }

  /**
   * The current nodeDetailContext
   */
  @Input() nodeContext: DataTreeNodeContext<T>;

  /**
   * Optional function to handle user-initiated selection changes. Will emit the
   * current selection.
   */
  @Output() userSelectionChange = new EventEmitter<DataTreeSelectionChangeEvent<T>>();

  /**
   * Gets the current node.
   */
  get node(): T {
    return this.nodeContext.node;
  }

  /**
   * Gets the current selection.
   */
  get selection(): DataTreeSelectionModel<T> {
    return this.nodeContext.selection;
  }

  /**
   * Generates the object to emit in teh selection click handlers.
   */
  get emitSelection(): DataTreeSelectionChangeEvent<T> {
    return {
      type: null,
      node: this.node,
      selection: this.selection.currentSelection,
    };
  }

  /**
   * User-initiated auto-select click handler. Emits the current selection.
   */
  handleAutoSelectClick() {
    this.selection.toggleNodeAutoSelection(this.node);

    if (this.userSelectionChange.observers.length > 0) {
      this.userSelectionChange.emit({ ...this.emitSelection, type: 'autoSelect' });
    }
  }
}
