<h1 mat-dialog-title *ngIf="title">{{ title | translate }}</h1>

<mat-dialog-content>
  <cog-banner *ngIf="hasCogBanner" status="warn" class="margin-bottom-lg width-50">
    {{ cogBannerMessage |  translate }}
  </cog-banner>
  <p *ngIf="!isHtml">{{ message | translate }}</p>
  <div class="confirmation-dialog-content" *ngIf="isHtml" [innerHtml]="message"></div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button
    mat-dialog-close
    color="primary"
    *ngIf="!noCancel"
    [cogDataId]="cancelLabel + '-button'"
    [inheritDataId]="true">
    {{ cancelLabel | translate }}
  </button>
  <button mat-flat-button
    [mat-dialog-close]="true"
    [color]="confirmButtonType"
    cdkFocusInitial
    [cogDataId]="confirmLabel + '-button'"
    [inheritDataId]="true">
    {{ confirmLabel | translate }}
  </button>
</mat-dialog-actions>
