import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TagNamePipe } from './tag-name/tag-name.pipe';
import { TagTypeLabelPipe } from './tag-type-label/tag-type-label.pipe';

@NgModule({
  declarations: [TagTypeLabelPipe, TagNamePipe],
  exports: [TagTypeLabelPipe, TagNamePipe],
  imports: [
    CommonModule
  ],
  providers: [TagTypeLabelPipe, TagNamePipe]
})
export class TagPipesModule { }
