import { APP_INITIALIZER } from '@angular/core';
import { IbmAquaIconService, ThemeService } from '@cohesity/helix';
import { IS_IBM_AQUA_ENV } from '@cohesity/shared/core';

import { FeatureFlagsService } from './core/services';

/**
 * FeatureFlagsService factory for app initializer provider.
 */
const featureFlagsFactory = (provider: FeatureFlagsService) => () => provider.init();

/**
 * Sets the theme based on the environment.
 */
const initThemeFactory = (isIbmAquaEnv: boolean, themeService: ThemeService, iconService: IbmAquaIconService) => () => {
  if (isIbmAquaEnv) {
    themeService.setTheme('ibm-aqua', 'light');
    iconService.updateIcons();
  }
};

/**
 * Set of Application Initializers that have to be resolved before application initializes.
 */
export const appInitializers = [
  {
    provide: APP_INITIALIZER,
    useFactory: featureFlagsFactory,
    deps: [FeatureFlagsService],
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: initThemeFactory,
    deps: [IS_IBM_AQUA_ENV, ThemeService, IbmAquaIconService],
    multi: true,
  },
];
