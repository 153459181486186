import { Subject } from 'rxjs';
import { MatLegacyPaginator as MatPaginator, MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  OnInit,
  OnDestroy,
  DoCheck,
  Output,
} from '@angular/core';

/**
 * This component extends the material design paginator and implements a version
 * based on existing styling
 */
@Component({
  selector: 'coh-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent extends MatPaginator implements DoCheck, OnInit, OnDestroy {

  private _cachedPageIndex = -1;
  private _cachedPageCount = -1;
  private _maxButtonCount = 6;

  /**
   * An array of currently displayed pages
   */
  displayedPages: number[];

  /**
   * Number of items per page. Defaults to 15
   */
  @Input() set pageSize(size: number) {
    super.pageSize = size || 15;
  }

  get pageSize(): number {
    return super.pageSize;
  }

  /**
   * Whether pagination has been disabled or not
   */
  @Input() disabled = false;

  /**
   * Observable for changes to the disabled property
   */
  @Output() disabledChange = new Subject<boolean>();

  /**
   * The name to use for generated element ids
   */
  @Input() name = '';

  /**
   * Returns the current count of pages.
   *
   * @return total number o pages
   */
  public get pageCount() {
    return this._cachedPageCount;
  }

  /**
   * Creates an instance of PaginatorComponent.
   *
   * @param   _intl   Overrides to for translation strings in the mat paginator
   * @param   _changeDetector   change detector object
   */
  constructor(_intl: MatPaginatorIntl, public _changeDetector: ChangeDetectorRef) {
    super(_intl, _changeDetector);
  }

  /**
   * Update the displayedPages values whenever changes to the component are made
   */
  ngDoCheck() {
    // Since Docheck is called frequently, we want to minimize the number of times
    // we udpate the display page count. We should only need to adjust if if the page index
    // or total page count has changed.
    if (this.pageIndex !== this._cachedPageIndex || this.getNumberOfPages() !== this._cachedPageCount) {
      this._cachedPageIndex = this.pageIndex;
      this._cachedPageCount = this.getNumberOfPages();
      this.updatedDisplayedPages();
    }
  }

  /**
   * Given a total number of pages and the current pages, determine which page
   * buttons should be visible in the paginator.
   */
  updatedDisplayedPages() {
    // Start by settings the first and last page indices to either side of the
    // currently selected index, adjusted
    const initialAdjust = (this._maxButtonCount - 2) / 2;
    let firstPage = Math.max(this.pageIndex - initialAdjust, 0);
    let lastPage = Math.min(this.pageCount, this.pageIndex + initialAdjust);

    // diff the first and last indices to determine how many buttons will be
    // shown. If it's less than expected, then we either push the first or last
    // index back to keep the number of buttons consistent.
    const diff = lastPage - firstPage;
    const adjust = this._maxButtonCount - diff - 1;

    if (diff <= this._maxButtonCount - 2) {
      if (firstPage === 0) {
        lastPage = Math.min(this.pageCount, lastPage + adjust);
      } else if (lastPage === this.pageCount) {
        firstPage = Math.max(0, firstPage - adjust);
      }
    }

    // Build an array of displayed pages
    this.displayedPages = [];
    for (let i = firstPage; i < lastPage; i++) {
      this.displayedPages.push(i);
    }
  }

  /**
   * Selects a page
   *
   * @param   index   the page index
   */
  selectPage(index: number) {
    const previousPageIndex = this.pageIndex;
    this.pageIndex = index;
    this.emitPageEvent(previousPageIndex);
  }

  /**
   * Toggles pagination and dispatches a change event
   */
  togglePagination() {
    this.disabled = !this.disabled;
    this.disabledChange.next(this.disabled);
    this._changeDetector.markForCheck();
  }

  /**
   * Emits a page event when the current page has changed
   *
   * @param  previousPageIndex   previously selected page
   */
  private emitPageEvent(previousPageIndex: number) {
    this.page.emit({
      previousPageIndex,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      length: this.length,
    });
  }
}
