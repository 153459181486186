<div *ngFor="let type of ['include', 'exclude']"
  class="margin-top">
  <h2>{{ type | translate }}</h2>
  <h3 *ngIf="type === 'include' && includeLabel">{{ includeLabel }}</h3>
  <h3 *ngIf="type === 'exclude' && excludeLabel">{{ excludeLabel }}</h3>
  <!-- Loop through all include and exclude path form controls -->
  <div *ngFor="let formControl of getPathFormControls(type); let index = index;">
    <mat-form-field>
      <input [cogDataId]="type + '-rule-input-' + index"
        [formControl]="formControl" [placeholder]="pathPlaceholder" matInput required>
      <mat-icon *ngIf="showFolderIcon" [inline]="true" matSuffix>
        folder_open
      </mat-icon>
      <mat-error *ngIf="formControl.invalid">
        {{'errors.required' | translate}}
      </mat-error>
    </mat-form-field>
    <button [cogDataId]="'remove-' + type + '-rule-button-' + index"
      (click)="removePathFormControl(type, index)"
      *ngIf="canRemovePath(type)"
      mat-icon-button
      type="button">
      <cog-icon shape="clear" size="sm"></cog-icon>
    </button>
  </div>
  <button [cogDataId]="'add-' + type + '-rule-button'"
    class="margin-top-sm"
    (click)="addPathFormControl(type)"
    color="primary"
    mat-stroked-button
    type="button">
    <cog-icon shape="add"></cog-icon>
    {{'add' | translate}}
  </button>
</div>
