import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';

/**
 * This pipe is used to check user privileges. Pass the name of the permission to check.
 *
 * @example
 * <div *ngIf="'CLUSTER_MODIFY' | userPrivileges">...</div>
 */
@Pipe({
  name: 'userPrivileges',
})
export class UserPrivilegesPipe implements PipeTransform {
  /**
   * Initializes the pipe
   *
   * @param   userService   The user service containing privilege info
   */
  constructor(private userService: UserService) {}

  /**
   * Implements the pipe
   *
   * @param   privilege   The permission value to check.
   * @return  True if the user has the permission.
   */
  transform(privilege: string): any {
    const privileges = this.userService.loginData.privileges;

    return privileges.includes(privilege);
  }
}
