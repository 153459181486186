// Module: User & Groups

// NOTE: The name Principal is a generic reference to any user or group.

;(function(angular) {
  'use strict';

  angular
    .module('C.userGroups', ['C.tenantService', 'C.NgLocaleModules'])
    .config(configFn);

  function configFn($stateProvider) {
    var _sharedParamConfig = {
      dynamic: true,
      value: undefined,
      squash: true,
    };

    $stateProvider
      .state('access-management.user-groups', {
        title: 'User and Groups Management',
        url: '^/admin/access/principals',
        help: 'admin_access',
        canAccess: 'PRINCIPAL_VIEW',
        parentState: 'access-management',
        views: {
          'tab-view': {
            template: '<user-groups allow-modifications include-all-principals />',
          },
        },
        allClustersSupport: {
          singleClusterState: 'access-management.user-groups',
          allClustersState: 'helios-access-management.users'
        },
      })
      .state('new-principal', {
        title: 'Add Principal',
        url: '^/admin/access/principals/new/{realm}/{type}',
        help: 'admin_access_users',
        params: {
          allowedTypes: _sharedParamConfig,
          createMode: true,
          realm: _sharedParamConfig,
          type: _sharedParamConfig,
        },
        canAccess: 'PRINCIPAL_MODIFY',
        parentState: 'access-management.user-groups',
        controller: 'modifyPrincipalCtrl as $ctrl',
        templateUrl: 'app/admin/access-management/user-groups/modify.html',
        resolve: {
          pageConfig: function getPageConfig($stateParams) {
            return {
              allowedTypes: $stateParams.allowedTypes,
              createMode: $stateParams.createMode,
              realm: $stateParams.realm,
              type: $stateParams.type,
            };
          },

          // mocking modal instance while rendering in page view
          $uibModalInstance: angular.noop,
        },
      })
      .state('edit-principal', {
        title: 'Edit Principal',
        url: '^/admin/access/principals/edit/{domain}/{type}/{name}',
        help: 'admin_access_users',
        parentState: 'access-management.user-groups',
        params: {
          editMode: true,
          tenantId: '',
          domain: { type: 'string' },
          type: { type: 'string' },
          name: { type: 'string' },
        },

        // allow logged in user to edit its password
        canAccess: 'PRINCIPAL_VIEW',
        controller: 'modifyPrincipalCtrl as $ctrl',
        templateUrl: 'app/admin/access-management/user-groups/modify.html',
        resolve: {
          pageConfig: function getPageConfig($stateParams) {
            return {
              domain: $stateParams.domain,
              editMode: $stateParams.editMode,
              name: $stateParams.name,
              tenantId: $stateParams.tenantId,
              type: $stateParams.type,
            };
          },

          // mocking modal instance while rendering in page view
          $uibModalInstance: angular.noop,
        },
      })
      .state('view-principal', {
        title: 'View Principal',
        url: '^/admin/access/principals/view/{domain}/{type}/{name}',
        help: 'admin_access_users',
        params: {
          viewMode: true,
          sid: '',
          domain: { type: 'string' },
          type: { type: 'string' },
          name: { type: 'string' },
        },
        canAccess: 'PRINCIPAL_VIEW',
        parentState: 'access-management.user-groups',
        controller: 'viewPrincipalController as $ctrl',
        templateUrl: 'app/admin/access-management/user-groups/view.html',
        resolve: {
          pageConfig: function getPageConfig($stateParams) {
            return {
              domain: $stateParams.domain,
              name: $stateParams.name,
              sid: $stateParams.sid,
              type: $stateParams.type,
              viewMode: $stateParams.viewMode,
            };
          },

          // mocking modal instance while rendering in page view
          $uibModalInstance: angular.noop,
        },
      });
  }

})(angular);
