import { Component, Input, OnChanges, Type } from '@angular/core';
import { ProtectionSourceNode } from '@cohesity/api/v1';

import { envGroups, Environment } from '../constants';
import { AwsPopoverComponent } from './aws-popover/aws-popover.component';
import { AzureEntraIDPopoverComponent } from './azure-entra-id-popover/azure-entra-id-popover.component';
import { AzureSqlPopoverComponent } from './azure-sql-popover/azure-sql-popover.component';
import { AzureVMPopoverComponent } from './azure-vm-popover/azure-vm-popover.component';
import { ExchangePopoverComponent } from './exchange-popover/exchange-popover.component';
import { HypervisorPopoverComponent } from './hypervisor-popover/hypervisor-popover.component';
import { NasPopoverComponent } from './nas-popover/nas-popover.component';
import { Office365PopoverComponent } from './office365-popover/office365-popover.component';
import { OraclePopoverComponent } from './oracle-popover/oracle-popover.component';
import { PhysicalPopoverComponent } from './physical-popover/physical-popover.component';
import { SapHanaPopoverComponent } from './saphana-popover/saphana-popover.component';
import { SqlPopoverComponent } from './sql-popover/sql-popover.component';

/**
 * @description
 * SourceMetaComponent to render node specific popover content in source tree
 *
 * For adapter specific popover, add a component similar to nas-popover and add
 * static function hasPopover() to that. Import the new component here
 * After that, Add else-if block to getPopoverComponent() here.
 */
@Component({
  selector: 'coh-source-meta',
  templateUrl: './source-meta.component.html',
  styleUrls: ['./source-meta.component.scss'],
})
export class SourceMetaComponent implements OnChanges {
  /**
   * Input protection node to render node specific popover content
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('node') protectionNode: ProtectionSourceNode;

  /**
   * popover component to render based on the node environment
   */
  popoverComponent: Type<any>;

  constructor() { }

  /**
   * Get popover component if protection node is not null
   */
  ngOnChanges() {
    if (this.protectionNode) {
      this.popoverComponent = this.getPopoverComponent();
    }
  }

  /**
   * If node has popover, use node environment to assign adapter specific popover
   * component to instance variable popoverComponent which is passed to cogPopover
   *
   * Please create adapter specific component and add conditions under switch case
   * statement in getPopoverComponent() if you want to display
   * adapter specific popover content
   *
   * @returns Component type of adapter specific popover component
   */
  getPopoverComponent(): Type<any> {
    const environment = this.protectionNode.protectionSource.environment;
    switch (true) {
      case envGroups.nas.includes(environment as any) && NasPopoverComponent.hasPopover(this.protectionNode):
        return NasPopoverComponent;
      case environment === Environment.kExchange && ExchangePopoverComponent.hasPopover(this.protectionNode):
        return ExchangePopoverComponent;
      case environment === Environment.kSQL && SqlPopoverComponent.hasPopover(this.protectionNode):
        return SqlPopoverComponent;
      case environment === Environment.kOracle && OraclePopoverComponent.hasPopover(this.protectionNode):
        return OraclePopoverComponent;
      case environment === Environment.kPhysical && PhysicalPopoverComponent.hasPopover(this.protectionNode):
        return PhysicalPopoverComponent;
      case environment === Environment.kAWS && AwsPopoverComponent.hasPopover(this.protectionNode):
        return AwsPopoverComponent;
      case environment === Environment.kAzure && AzureVMPopoverComponent.hasPopover(this.protectionNode):
        return AzureVMPopoverComponent;
      case envGroups.vms.includes(environment as Environment) &&
        HypervisorPopoverComponent.hasPopover(this.protectionNode):
        return HypervisorPopoverComponent;
      case environment === Environment.kO365 && Office365PopoverComponent.hasPopover(this.protectionNode):
        return Office365PopoverComponent;
      case environment === Environment.kAzure &&
        AzureSqlPopoverComponent.hasPopover(this.protectionNode):
        return AzureSqlPopoverComponent;
      case environment === Environment.kAzure &&
        AzureEntraIDPopoverComponent.hasPopover(this.protectionNode):
        return AzureEntraIDPopoverComponent;
      case environment === Environment.kSAPHANA && SapHanaPopoverComponent.hasPopover(this.protectionNode):
        return SapHanaPopoverComponent;
    }
  }
}
