// VLAN Service contains VLAN's CRUD operation

;(function(angular, undefined) {

  angular
    .module('C.vlan')
    .service('VlanService', VlanServiceFn);

  function VlanServiceFn($http, $q, API, cUtils, cMessage, evalAJAX,
    cModal, SlideModalService, TenantService, NgPassthroughOptionsService) {

    return {
      addVlanModal: addVlanModal,
      createVlan: createVlan,
      updateVlan: updateVlan,
      deleteVlan: deleteVlan,
      deleteVlanModal: deleteVlanModal,
      editVlanModal: editVlanModal,
      getVlan: getVlan,
      getVlans: getVlans,
      getVips: getVips,
      viewVlanModal: viewVlanModal,
      transformVlan: transformVlan,
      getInterfaceGroupName: getInterfaceGroupName,
      getIfaceVlan: getIfaceVlan,
      getIfaceName: getIfaceName,
    };

    /**
     * Creates a vlan.
     *
     * @method   createVlan
     * @param    {object}   data   The data
     * @return   {object}   promise
     */
    function createVlan(data) {
      return $http({
        method: 'post',
        url: API.public('vlans'),
        data: data
      }).then(function createVlanSuccess(response) {
        return transformVlan(response.data);
      });
    }

    /**
     * Update a vlan.
     *
     * @method   updateVlan
     * @param    {object}   data   The data
     * @return   {object}   promise
     */
    function updateVlan(data) {
      return $http({
        method: 'put',
        url: API.public('vlans', data.id),
        data: data
      }).then(function updateVlanSuccess(response) {
        return transformVlan(response.data);
      });
    }

    /**
     * Delete a vlan
     *
     * @method   deleteVlan
     * @param    {string}   id   The identifier
     * @return   {object}   promise
     */
    function deleteVlan(vlan) {
      return $http({
        method: 'delete',
        data: {ifaceGroupName: vlan.ifaceGroupName},
        url: API.public('vlans', vlan.id),
      });
    }

    /**
     * Gets the vlan.
     *
     * @method   getVlan
     * @param    {string}   interfaceGroup  The interface group
     * @return   {object}                   Promise to return vlan.
     */
    function getVlan(interfaceGroup) {
      return $http({
        method: 'get',
        url: API.public('vlans', interfaceGroup),
      }).then(function getVlanSuccess(response) {
        return transformVlan(response.data);
      });
    }

    /**
     * Gets the vlans.
     *
     * @method   getVlans
     * @param    {Object}   params   The params for the request.
     * @return   {Object}   Promise which returns the list of the vlans.
     */
    function getVlans(params) {
      params = params || {};
      return $http({
        method: 'get',
        url: API.public('vlans'),
        params: params,
        headers: NgPassthroughOptionsService.requestHeaders
      }).then(function getVlansSuccess(response) {
        var vlans = _.map(response.data, transformVlan);

        if (params._includeTenantInfo) {
          return TenantService.resolveTenantDetails(vlans);
        }
        return vlans;
      });
    }

    /**
     * Transform vlan object by adding more properties
     *
     * @method   transformVlan
     * @param    {object}   vlan   vlan object from api
     * @return   {object}          transformed vlan object
     */
    function transformVlan(vlan) {

      // Create a cidr mask from netmask
      var cidrMaskV4 = _.get(vlan.subnet, 'netmaskBits') ||
        cUtils.netmaskToCIDRMask(_.get(vlan.subnet, 'netmaskIp4'));
      var cidrMaskV6 = _.get(vlan.subnetV6, 'netmaskBits');

      if (vlan.subnet) {
        vlan.subnet._CIDR = vlan.subnet.ip + '/' + cidrMaskV4;
      }
      if (vlan.subnetV6) {
        vlan.subnetV6._CIDR = vlan.subnetV6.ip + '/' + cidrMaskV6;
      }
      // Backwards compatibility
      // Keep using vlanId if intefaceName is not present
      if (vlan.ifaceGroupName) {
        vlan._uid = vlan.ifaceGroupName;
      } else if(vlan.interfaceName) {
        vlan._uid = vlan.interfaceName + '.' + vlan.id;
      } else {
        vlan._uid = vlan.id;
      }

      // Use vlan._uid if vlanName is not present
      vlan.vlanName = vlan.vlanName || vlan._uid.toString();

      return vlan;
    }

    /**
     * Adds a vlan through slider modal.
     *
     * @method   addVlanModal
     * @return   {object}   a promise to resolve
     */
    function addVlanModal() {
      return SlideModalService.newModal({
        templateUrl: 'app/platform/vlans/detail/detail.html',
        controller: 'vlanDetailCtrl as $ctrl',
        title: 'vlan.addVlan',
        size: 'xl',
        resolve: {
          pageConfig: function getPageConfig() {
            return {
              mode: 'new',
            };
          },
        },
      });
    }

    /**
     * edit VLAN details in modal modal.
     *
     * @method   editVlanModal
     * @return   {object}   a promise to resolve
     */
    function editVlanModal(vlan) {
      return SlideModalService.newModal({
        templateUrl: 'app/platform/vlans/detail/detail.html',
        controller: 'vlanDetailCtrl as $ctrl',
        title: 'vlan.editVlan',
        size: 'xl',
        resolve: {
          pageConfig: function getPageConfig() {
            return {
              mode: 'edit',
              id: vlan._uid,
            };
          },
        },
      });
    }

    /**
     * view VLAN details in modal modal.
     *
     * @method   viewVlanModal
     * @return   {object}   a promise to resolve
     */
    function viewVlanModal(vlan) {
      return SlideModalService.newModal({
        templateUrl: 'app/platform/vlans/detail/detail.html',
        controller: 'vlanDetailCtrl as $ctrl',
        title: 'vlan.viewVlan',
        size: 'xl',
        resolve: {
          pageConfig: function getPageConfig() {
            return {
              mode: 'view',
              id: vlan._uid,
            };
          },
        },
      });
    }

    /**
     * Launch the Delete VLANs modal.
     *
     * @method   deleteVlanModal
     * @param    {object}   vlan   vlan to delete
     */
    function deleteVlanModal(vlan) {
      var options = {
        actionButtonKey: 'delete',
        closeButtonKey: 'cancel',
        title: 'vlan.deleteVlanTitle',
        contentKey: 'vlan.deleteVlanText',
        contentKeyContext: vlan,
      };

      // Show the modal.
      return cModal.standardModal({}, options).then(function onAccpedted() {
        return deleteVlan(vlan).catch(evalAJAX.errorMessage);
      });
    }

    /**
     * Remove the suffix if the suffix is '.0'
     *
     * @param   {String}  name
     * @return  {String}  the name without suffix .0
     */
    function getInterfaceGroupName(name) {
      if (name.lastIndexOf('.0') === name.length - 2) {
        return name.slice(0, name.length - 2);
      }
      return name;
    }

    /**
     * used in vlan and vip listing smart table sort field st-sort
     * It returns first part of ifaceGroupName, which is interface name
     *
     * @method  getIfaceName
     * @param   {object}  row smart table row
     * @return  {String}  interface name
     */
    function getIfaceName(row) {
      return row.ifaceGroupName.split('.')[0];
    }

    /**
     * used in vlan and vip listing smart table sort field st-sort
     * It returns second part of ifaceGroupName, which is vlan field as integer
     *
     * @method  getIfaceVlan
     * @param   {object}  row smart table row
     * @return  {number}  vlan id
     */
    function getIfaceVlan(row) {
      return parseInt(row.ifaceGroupName.split('.')[1],0);
    }

    /**
     * Get list  of all vips in cluster.
     *
     * @param   {object}  params optional params to filter list of vips.
     * @return  List of vips.
     */
    function getVips(params) {
      params = params || {};
      return $http({
        method: 'get',
        url: API.public('vlans'),
        params: params,
      }).then(function getVlansSuccess(response) {
        var vips = [];
        vips = response.data.map(
          function getVipsSuccess(vlan) {
            return vlan.ips;
          });
        return Array.prototype.concat.apply([], vips);
      });
    }
  }

})(angular);
