import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ObjectStatsInfo, ProgressTaskEvent } from '@cohesity/api/v2';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { Poller } from '@cohesity/utils';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PollingService } from 'src/app/core/services/polling.service';
import { EventVec, ResultGroupVec } from 'src/app/shared/models';

/** Specifies dialog data */
export interface TimelineDialogData {
  /** Title of the dialog. */
  title: string;

  /** Error messages to be displayed. */
  errorMessages?: string[];

  /** Task id, will poll for task info if task id is given. */
  task?: string;

  /** Previous task attempts IDs */
  taskAttempts?: string[];

  /** Event Vector data if available. */
  eventVec?: EventVec[];

  /**
   * Optional progress task events to display instead of eventVec,
   * taskAttempts or task.
   */
  progressEvents$?: Observable<ProgressTaskEvent[]>;

  /**
   * Optional stats task events to display instead of eventVec,
   * taskAttempts or task.
   */
  stats$?: Observable<any[] | ObjectStatsInfo>;

  /**
   * Optional progress task events failed attempts.
   */
  taskAttempts$?: Observable<ProgressTaskEvent[][]>;

  /**
   * Url to download debug/error logs specific to task.
   */
  downloadLogsUrl?: string;

  /**
   * Url to download success logs specific to task.
   */
  downloadSuccessLogsUrl?: string;

  /**
   * Url to download inclusion-exclusion report specific to task.
   */
  downloadInclusionExclusionReportsUrl?: string;

  /**
   * Start time of protection Run.
   */
  startTimeUsecs?: number;

  /**
   * Run ID of protection Run.
   */
  runId?: string;

  /**
   * Path to the object task within the protection Run.
   */
  objectTaskPath?: string;

  /**
   * Path to the run task.
   */
  runTaskPath?: string;

  /**
   * Id of the object.
   */
  id?: number;

  /**
   * Environment pertaining to the protection run.
   */
  environment?: string;

  /**
   * Type of physical protection job (file/block based)
   */
  physicalProtectionType?: string;

}

export interface Errors {
  /**
   * Total number of errors.
   */
  Total?: number;

  /**
   * Number of errors pertaining to File backups.
   */
  File?: number;

  /**
   * Number of errors pertaining to Folder backups.
   */
  Folder?: number;

  /**
   * Number of persistent errors.
   */
  Persistent?: number;

  /**
   * Number of intermittent errors.
   */
  Intermittent?: number;

  /**
   * Number of errors pertaining to Discovery phase.
   */
  Discovery?: number;

  /**
   * Number of errors pertaining to Ingestion phase.
   */
  Ingestion?: number;
}

/**
 * A dialog component showing events like a timeline.
 *
 * It can be used in two ways. You can specify timeline directly in format specified
 * by Timeline interface or provide pulse task id.
 *
 * If task id is provided, component will start polling for the pulse data.
 *
 * @example
 *   const data = {
 *     title: 'Backup Task Activity',
 *     errorMessages: ['foo', 'bar'],
 *     timelineData: [],
 *   };
 */
@Component({
  selector: 'coh-timeline-dialog',
  templateUrl: './timeline-dialog.component.html',
  styleUrls: ['./timeline-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineDialogComponent implements OnInit, OnDestroy {
  /**
   * Events Poller observable instance.
   */
  private eventsPoller: Poller<ResultGroupVec[]>;

  /**
   * Task Attempts Poller observable instance.
   */
  private attemptsPoller: Poller<ResultGroupVec[]>;

  /**
   * The pulse events list.
   */
  events$: Observable<EventVec[]>;

  /**
   * Previous failed task attempts.
   */
  attempts$: Observable<EventVec[][]>;

  /**
   * stats for the object protection run.
   */
  stats$: Observable<ObjectStatsInfo>;

  /**
   * errors in the protection run.
   */
  errorClasses$: Observable<Errors>;

  /**
   * last updated time of the stats data..
   */
  lastUpdated$: Observable<number>;

  /**
   * Time elapsed since the protection run was triggered.
   */
  duration: number;

  /**
   * Property that checks if feature flag protectionRunStatistics is enabled.
   */
  isProtectionRunStatsEnabled: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TimelineDialogData,
    private pollingService: PollingService,
    private irisCtx: IrisContextService,
  ) {}

  ngOnInit() {
    this.isProtectionRunStatsEnabled = flagEnabled(
      this.irisCtx.irisContext, 'protectionRunStatistics'
    );
    this.poll();

    if (this.data.eventVec) {
      this.events$ = of(this.data.eventVec);
    } else if (this.data.progressEvents$) {
      this.events$ = this.data.progressEvents$.pipe(
        map(events => events?.map(event => ({
          eventMsg: event.message,
          timestampSecs: event.occuredAtUsecs / 1_000_000,
        })) || [])
      );
    }

    if (this.data.taskAttempts$) {
      this.attempts$ = this.data.taskAttempts$.pipe(
        map(attempts => attempts?.map(events => events.map(event => ({
          eventMsg: event.message,
          timestampSecs: event.occuredAtUsecs / 1_000_000,
        }))) || [])
      );
    }
  }

  ngOnDestroy() {
    if (this.eventsPoller) {
      this.eventsPoller.finish();
    }

    if (this.attemptsPoller) {
      this.attemptsPoller.finish();
    }
  }

  /**
   * Starts polling if task id is provided.
   */
  poll() {
    if (this.data.task) {
      this.eventsPoller = this.pollingService.pollTask(this.data.task, 30, true, true);
      this.events$ = this.eventsPoller.poller.pipe(
        catchError(() => of(null)),
        map(events => events?.[0]?.taskVec?.[0]?.progress?.eventVec || [])
      );
    } else {
      this.events$ = of([]);
    }

    if (this.data.taskAttempts?.length) {
      this.attemptsPoller = this.pollingService.pollTask(this.data.taskAttempts, 30, true, true);

      this.attempts$ = this.attemptsPoller.poller.pipe(
        catchError(() => of(null)),
        map(attempts => attempts?.map(attempt => attempt?.taskVec?.[0]?.progress?.eventVec) || [])
      );
    }
  }

  /**
   * This is a generic component, Download logs option will always
   * be shown by default for all the other adapters. It will be
   * hidden only for Block based physical jobs.
   */
  showDownloadLogsOption(): boolean {
    if (this.data.physicalProtectionType === 'kVolume') {
      return false;
    }
    return true;
  }

  /**
   * Download debug/error logs.
   */
  downloadDebugLogs() {
    window.open(this.data.downloadLogsUrl, '_blank', 'noopener noreferrer');
  }

  /**
   * Download success logs.
   */
  downloadSuccessLogs() {
    window.open(this.data.downloadSuccessLogsUrl, '_blank', 'noopener noreferrer');
  }

  /**
   * Download inclusion-exclusion report.
   */
  downloadInclusionExclusionReport() {
    window.open(this.data.downloadInclusionExclusionReportsUrl, '_blank', 'noopener noreferrer');
  }
}
