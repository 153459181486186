<cog-settings-list-item [invalid]="sasUrlFormGroup?.invalid" [label]="'sasUrlTypeLabel' | translate" cogFormGroup>
  <cog-settings-list-item-value-display>
    {{ ( sasUrlFormGroup.get('endPointType')?.value === 'private' ? 'privateEndpoint' : 'publicEndpoint') | translate }}
    <span *ngIf="isDmsUser && sasUrlFormGroup.get('endPointType')?.value === 'private' && privateEndpointFields.length">
      - {{('privateEndpointCount' | translate: { count: privateEndpointFields.length })}}
    </span>
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor [formGroup]="sasUrlFormGroup">
    <p [innerHtml]="'sasUrlInfoText' | translate"></p>

    <div class="row">
      <div class="col-xs-6">
        <mat-radio-group formControlName="endPointType" class="margin-top flex-row">
          <mat-radio-button
            *ngFor="let type of endPointTypes"
            class="margin-right"
            [value]="type.value"
            [cogDataId]="type.key"
            (change)="handleEndPointSelection($event)">
            {{ type.key | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <ng-container *ngIf="!isDmsUser">
      <cog-banner class="margin-top" status="info" *ngIf="selectedEndpointType !== 'public'">
        <span>{{ 'sasUrlInfoBannerText' | translate }}</span>
      </cog-banner>

      <div *ngIf="selectedEndpointType !== 'public'" class="rows-wrap margin-top">
        <ng-container formArrayName="privateEndpointFields">
          <ng-container *ngFor="let privateEndPointField of privateEndpointFields; let i = index">
            <div class="rows-nowrap">
              <coh-app-private-endpoints-row
                [privateEndpointField]="privateEndPointField"
                [protectionSource$]="protectionSource$.asObservable()"
                [sourceIds$]="sourceIds$.asObservable()">
              </coh-app-private-endpoints-row>

              <coh-form-array-action-control
                class="margin-left"
                [formArrayLength]="privateEndpointFields.length"
                [index]="i"
                (controlAdded)="addPrivateEndpointFields()"
                (controlRemoved)="removePrivateEndPointField(i)">
              </coh-form-array-action-control>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="selectedEndpointType !== 'public' && isDmsUser">
      <a class="endpoint-config margin-top" (click)="openDialog()" *ngIf="showLink">
        {{ ((sasUrlFormGroup?.valid && privateEndpointFields.length ? 'privateEndpointCount' : 'sasUrlInfoBannerTitle') | translate: { count: privateEndpointFields.length }) }}
      </a>
      <div *ngIf="sasUrlFormGroup.touched && sasUrlFormGroup.invalid" class="mat-error margin-top">
        {{ 'sasUrlInfoErrorMsg' | translate }}
      </div>
    </ng-container>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
