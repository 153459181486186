import { Injectable } from '@angular/core';
import { Notifications } from '@cohesity/api/v2';
import { BANNER_PRIORITY, BannerConfig } from '@cohesity/helix';
import { BaseBannerConfig, IrisContext, IrisContextService, isMcmOnPrem } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { HeliosNotificationService } from 'src/app/core/services';


@Injectable({
  providedIn: 'root',
})
export class ClusterCertificateExpiryBannerConfig extends BaseBannerConfig {
  /**
   * Unique banner representation
   */
  id = 'ng-helios-cluster-certificate-expiry-banner';

  /**
   * provides banner config as observable
   */
  bannerInfo$ = this.irisCtx.irisContext$.pipe(

    switchMap(() => combineLatest([
      this.irisCtx.irisContext$,
      this.notificationService.heliosNotifications$,
    ])),
    map(([ctx, notification]) => ([{
      id: this.id,
      priority: BANNER_PRIORITY.HIGH,
      isVisible: this.allowBanner(ctx, notification),
      status: 'warn',
      allowClose: true,
      text: this.translateService.instant(
        'mcm.clusterCertExpiry.banner.text',
        { num: notification?.mcmClustersCertExpiryNotification?.length }
      ),
      closeCallback: this.closeCallback,
    } as BannerConfig]))
  );

  constructor(
    private irisCtx: IrisContextService,
    private notificationService: HeliosNotificationService,
    private translateService: TranslateService,
  ) {
    super();
  }

  /**
   * Allow banner based on context
   *
   * @param ctx iris context
   * @param notification upgrade notification
   * @returns true/false based on context
   */
  allowBanner(ctx: IrisContext, notification: Notifications) {
    return (
      this.isLoggedIn(ctx) &&
      !this.isBannerAcknowledged(this.id) &&
      isMcmOnPrem(ctx) &&
      notification?.mcmClustersCertExpiryNotification?.length > 0
    );
  }
}
