import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

/**
 * Directive to update CSS fill url with absolute url instead of relative url
 * to display gradient color for highcharts area in statlist-multi-series-chart component.
 * This is needed to fix issue SVG attributes which use relative url
 * does not work consistently across different browsers.
 * More info:
 *   https://www.highcharts.com/docs/chart-design-and-style/gradients-shadows-and-patterns
 *   https://github.com/angular/angular.js/issues/8934
 */
@Directive({
  selector: '[cohChartGradient]',
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }]
})
export class ChartGradientDirective implements AfterViewInit {

  /**
   * Unique chart name to identify the chart.
   * This name needs to match the statlistMultiSeriesChart name.
   */
  @Input('cohChartGradient') chartName = '';

  constructor(private el: ElementRef, private location: Location) { }

  /**
   * AfterViewInit lifecycle hook.
   */
  ngAfterViewInit() {
    const absolutePath = this.location.path();
    // Retrieve all highcharts-area svg path in the chart.
    const chartAreaPaths = this.el.nativeElement.querySelectorAll('path.highcharts-area');

    // Loop through highcharts-area paths and update fill url with absolute path.
    chartAreaPaths.forEach((path: HTMLElement, i: number) =>
      path.style.fill = `url(${absolutePath}#${this.chartName}gradient-${i})`);
  }
}
