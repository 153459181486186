import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScanImpactStatusModule } from '@cohesity/data-govern/scans';
import { ScanHealthStatusIconModule } from '@cohesity/data-govern/shared';
import { IconModule } from '@cohesity/helix';
import { HumanizeFromNumbersPipeModule } from '@cohesity/utils';
import { TranslateModule } from '@ngx-translate/core';

import { ThreatMatchedStatusComponent } from './threat-matched-status.component';



@NgModule({
  declarations: [
    ThreatMatchedStatusComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    HumanizeFromNumbersPipeModule,
    ScanHealthStatusIconModule,
    ScanImpactStatusModule,
    TranslateModule
  ],
  exports: [
    ThreatMatchedStatusComponent,
  ]
})
export class ThreatMatchedStatusModule { }
