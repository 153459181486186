import { CdkPortalOutletAttachedRef, ComponentPortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  Inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { DATA_RENDERERS, DataRenderer, DataRendererProvider, DataRenderers } from '../data-renderer.model';
import { BaseRendererComponent } from '../renderers/base-renderer.component';

/**
 * This component is takes an input and data type and looks up an appropriate rendering
 * component to render the data.
 */
@Component({
  selector: 'cog-data-renderer',
  templateUrl: './data-renderer.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataRendererComponent extends BaseRendererComponent<any> implements OnChanges {
  /**
   * The data type to render
   */
  @Input() dataType: string;

  /**
   * The renderer portal
   */
  portal: ComponentPortal<DataRenderer<any, any, any>>;

  /**
   * The renderer's component ref
   */
  private componentRef: ComponentRef<DataRenderer<any, any, any>>;

  /**
   * The renderer provider.
   */
  renderer: DataRendererProvider;

  constructor(@Inject(DATA_RENDERERS) private dataRenderers: DataRenderers[]) {
    super();
  }

  /**
   * Set the input properties on the component properties whenever they are rendered.
   *
   * @param ref The portal ref
   */
  onAttached(ref: CdkPortalOutletAttachedRef) {
    this.componentRef = ref as ComponentRef<DataRenderer<any, any, any>>;
    this.updatePortalInputs();
  }

  /**
   * Update the inputs on the portal component
   */
  private updatePortalInputs() {
    if (!this.componentRef) {
      return;
    }
    let inputs = {
      value: this.value,
      secondaryValue: this.secondaryValue,
      renderParams: this.renderParams,
      translate: this.translate,
    };

    if ((this.renderer as any).transform) {
      inputs = (this.renderer as any).transform(inputs);
    }

    Object.assign(this.componentRef.instance, inputs);
    this.componentRef.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataType) {
      this.renderer = this.getRendererProvider();
      this.portal = this.createPortal();
    } else {
      this.updatePortalInputs();
    }
  }

  /**
   * Looks up the renderer provider
   *
   * @returns A renderer provider or throws an error.
   */
  private getRendererProvider(): DataRendererProvider {
    if (!this.dataType) {
      return null;
    }

    const provider = this.dataRenderers.find(entry => !!entry[this.dataType])?.[this.dataType];

    if (!provider) {
      throw new Error(`No data renderer provided for type: ${this.dataType}`);
    }

    return provider;
  }

  /**
   * Looks up the ComponentPortal based on the renderer type. Throws an error if
   * one has not be registered.
   *
   * @returns A Component Portal matching the data type
   */
  private createPortal(): ComponentPortal<DataRenderer<any, any, any>> {
    if (!this.renderer) {
      return null;
    }
    const componentType = (this.renderer as any).component || this.renderer;
    return new ComponentPortal(componentType);
  }
}
