import { EntityProto } from '@cohesity/api/private';

/**
 * Data mover service type options
 */
export enum DataMoverServiceTypeOptions {
  // Allocates a cluster-internal IP address for load-balancing to endpoints.
  kClusterIp = 0,

  // Builds on ClusterIP and allocates a port on every node which routes to the
  // same endpoints as the clusterIP.
  kNodePort = 1,

  // Builds on NodePort and creates an external load-balancer which routes to
  // the same endpoints as the clusterIP.
  kLoadBalancer = 2,
}

/**
 * Enum for exclude include labels
 */
export enum KubernetesLabelFilterType {
  include = 'include',
  exclude = 'exclude',
}

/**
 * Enum for logical type for labels
 */
export enum KubernetesLabelLogicalTypeOptions {
  or = 'OR',
  and = 'AND'
}

/**
 * Enum for quiesce mode.
 */
export enum KubernetesQuiesceMode {
  together = 'kQuiesceTogether',
  independently = 'kQuiesceIndependently',
}

/**
 * Interface resource pool which is used for namespace and pvcs.
 */
export interface ResourcePool {
  // Resource pool of the source.
  resourcePool: EntityProto;
}

// Prefix for kubernetes files which need to be attached
// while recovering.
export const kubernetesFilePrefix = '/cohesity-data';
