/* tslint:disable */

/**
 * Specifies the policy run status.
 */
export enum PolicyRunStatus {
  notStarted = 'notStarted',
  completed = 'completed',
  error = 'error',
  scanning = 'scanning'
}
