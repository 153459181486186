<mat-form-field>
  <mat-label>{{ 'day' | translate }}</mat-label>
  <mat-select *ngIf="!singleOnly" [formControl]="control" [placeholder]="'selectADate' | translate" multiple>
    <mat-option *ngFor="let item of dayOfMonthList" [value]="item">
      {{ item.label | translate }}
    </mat-option>
  </mat-select>
  <mat-select *ngIf="singleOnly" [formControl]="control" [placeholder]="'selectADate' | translate">
    <mat-option *ngFor="let item of dayOfMonthList" [value]="item">
      {{ item.label | translate }}
    </mat-option>
  </mat-select>
</mat-form-field>
