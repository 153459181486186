<div *ngIf="!snapshots.length && !timeRanges.length; else timeline">
  {{ 'noSnapshotsFound' | translate }}
</div>

<ng-template #timeline>
  <!-- The timeline chart -->
  <highcharts-chart
    class="cog-chart"
    [ngClass]="timelineClassName"
    [Highcharts]="Highcharts"
    [options]="chartConfig">
  </highcharts-chart>

  <!-- Summary section -->
  <mat-card class="mat-elevation-z2 summary-wrap">
    <!-- The timepicker -->
    <div class="flex-row-sm" [ngClass]="{'align-row': !pointInTimeSupported}">
      <section *ngIf="pointInTimeSupported" class="snapshot-time">
        <coh-time-field
          cogDataId="select-time"
          [formControl]="timeFieldControl"
          [isDisabled]="timeRanges?.length === 0"
          [label]="'time' | translate"
          [showSeconds]="true">
        </coh-time-field>
      </section>

      <!-- Currently selected snapshot type -->
      <section class="snapshot-type">
        <ng-container *ngIf="!value.pointInTimeUsecs">
          <ng-container *ngIf="value.snapshot?.runType === 'kStorageArraySnapshot'">
            {{ 'storageArraySnapshot' | translate }}
          </ng-container>

          <ng-container *ngIf="value.snapshot?.runType === 'kFull'">
            {{ snapshotLabels.full | translate }}
          </ng-container>

          <ng-container *ngIf="value.snapshot?.runType === 'kRegular'">
            {{ snapshotLabels.incremental | translate }}
          </ng-container>

          <ng-container *ngIf="value.snapshot?.runType === 'kSystem'">
            {{ snapshotLabels.system | translate }}
          </ng-container>
        </ng-container>

        <ng-container *ngIf="value.pointInTimeUsecs">
          {{ snapshotLabels.pointInTime | translate }}
        </ng-container>
      </section>
    </div>

    <div *ngIf="!hideSnapshotLocationPicker" class="flex-row-sm">
      <!-- Snapshot location picker -->
      <section class="snapshot-location">
        <ng-container *ngIf="(!value.pointInTimeUsecs || (hasCloudVaultSnapshot$ | async))
          && snapshotGroup$ | async as group">
          {{ 'locationColon' | translate }}

          <!-- Local snapshot -->
          <span *ngIf="group.localSnapshot as snapshot">
            <button
              (click)="onSnapshotLocationClick(snapshot)"
              mat-icon-button
              type="button"
              [color]="value.snapshot?.id === snapshot.id && 'primary'"
              [matTooltip]="getTargetTooltip(snapshot) | translate"
              cogDataId="select-local"
              [inheritDataId]="true">
              <cog-icon [shape]="getTargetIcon(snapshot) | backupTargetIcon"></cog-icon>
            </button>
          </span>

          <!-- Archival snapshots -->
          <span *ngFor="let snapshot of [group.archiveSnapshots, group.cloudVaultSnapshots].flat()"
            [matTooltip]="snapshotSelectorUtilsService.getSnapshotTooltip(snapshot)">
            <button
              (click)="onSnapshotLocationClick(snapshot)"
              mat-icon-button
              type="button"
              [color]="value.snapshot?.id === snapshot.id && 'primary'"
              [disabled]="snapshotSelectorUtilsService.getSnapshotIsDisabled(snapshot)"
              cogDataId="select-{{ snapshot.externalTargetInfo.targetName }}"
              [inheritDataId]="true">
              <cog-icon [shape]="snapshotSelectorUtilsService.getSnapshotIcon(snapshot)">
              </cog-icon>
            </button>
          </span>
        </ng-container>
      </section>
    </div>
    <div class="snapshot-tags" *ngIf="isCleanRoomRecoveryPhase1Enabled && snapshotGroup$ | async as group">
      <coh-colored-tags [tags]="getSnapshotTags(group)"></coh-colored-tags>
    </div>
  </mat-card>
</ng-template>
