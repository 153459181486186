/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityReportingApiConfiguration as __Configuration } from '../cohesity-reporting-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Components } from '../models/components';
import { Component } from '../models/component';
import { ComponentsPreview } from '../models/components-preview';
import { ComponentsPreviewParams } from '../models/components-preview-params';
import { ComponentPreview } from '../models/component-preview';
import { ComponentPreviewParams } from '../models/component-preview-params';
@Injectable({
  providedIn: 'root',
})
class ComponentsService extends __BaseService {
  static readonly GetComponentsPath = '/public/components';
  static readonly GetComponentByIdPath = '/public/components/{id}';
  static readonly GetComponentsPreviewPath = '/public/components/preview';
  static readonly GetComponentPreviewPath = '/public/components/{id}/preview';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List Report Components
   *
   * Fetches list of all report components accessible by logged in user.
   * @param ids Specifies the ids of the report component to fetch.
   * @return Success
   */
  GetComponentsResponse(ids?: null | Array<string>): __Observable<__StrictHttpResponse<Components>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/components`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Components>;
      })
    );
  }
  /**
   * List Report Components
   *
   * Fetches list of all report components accessible by logged in user.
   * @param ids Specifies the ids of the report component to fetch.
   * @return Success
   */
  GetComponents(ids?: null | Array<string>): __Observable<Components> {
    return this.GetComponentsResponse(ids).pipe(
      __map(_r => _r.body as Components)
    );
  }

  /**
   * Fetch a Report Component
   *
   * Get information about a Report component.
   * @param id Specifies the id of the report component.
   * @return Success
   */
  GetComponentByIdResponse(id: string): __Observable<__StrictHttpResponse<Component>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/components/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Component>;
      })
    );
  }
  /**
   * Fetch a Report Component
   *
   * Get information about a Report component.
   * @param id Specifies the id of the report component.
   * @return Success
   */
  GetComponentById(id: string): __Observable<Component> {
    return this.GetComponentByIdResponse(id).pipe(
      __map(_r => _r.body as Component)
    );
  }

  /**
   * Fetch Preview of components.
   *
   * Get preview of a list of components.
   * @param body Request body params in order to preview a given list of components.
   * @return Success
   */
  GetComponentsPreviewResponse(body: ComponentsPreviewParams): __Observable<__StrictHttpResponse<ComponentsPreview>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/components/preview`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ComponentsPreview>;
      })
    );
  }
  /**
   * Fetch Preview of components.
   *
   * Get preview of a list of components.
   * @param body Request body params in order to preview a given list of components.
   * @return Success
   */
  GetComponentsPreview(body: ComponentsPreviewParams): __Observable<ComponentsPreview> {
    return this.GetComponentsPreviewResponse(body).pipe(
      __map(_r => _r.body as ComponentsPreview)
    );
  }

  /**
   * Fetch Preview of a component.
   *
   * Get preview for a component specified by Id.
   * @param params The `ComponentsService.GetComponentPreviewParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the component.
   *
   * - `body`: Request body params in order to preview a given component.
   *
   * @return Success
   */
  GetComponentPreviewResponse(params: ComponentsService.GetComponentPreviewParams): __Observable<__StrictHttpResponse<ComponentPreview>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/components/${encodeURIComponent(params.id)}/preview`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ComponentPreview>;
      })
    );
  }
  /**
   * Fetch Preview of a component.
   *
   * Get preview for a component specified by Id.
   * @param params The `ComponentsService.GetComponentPreviewParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the component.
   *
   * - `body`: Request body params in order to preview a given component.
   *
   * @return Success
   */
  GetComponentPreview(params: ComponentsService.GetComponentPreviewParams): __Observable<ComponentPreview> {
    return this.GetComponentPreviewResponse(params).pipe(
      __map(_r => _r.body as ComponentPreview)
    );
  }
}

module ComponentsService {

  /**
   * Parameters for GetComponentPreview
   */
  export interface GetComponentPreviewParams {

    /**
     * Specifies the id of the component.
     */
    id: string;

    /**
     * Request body params in order to preview a given component.
     */
    body: ComponentPreviewParams;
  }
}

export { ComponentsService }
