<section class="router-tabs" *ngIf="showTabs">
  <nav mat-tab-nav-bar>
    <span class="router-tab"
      [class.hidden]="tab.isDisabled"
      [class.default-tab]="tab === defaultTab"
      mat-tab-link
      *ngFor="let tab of accessibleTabs"
      [id]="tab.id"
      [cogDataId]="tab.displayName + '-tab'"
      (click)="changeState(tab)"
      (keyup.enter)="changeState(tab)"
      [active]="isCurrentState(tab)">
      <mat-icon class="router-tab-icon {{tab.icon}}" *ngIf="tab.icon"></mat-icon>
      {{tab.displayName | translate}}
    </span>
  </nav>

  <!-- Target for state to render into -->
  <ui-view *ngIf="renderView" class="tab-content"></ui-view>
</section>