// Directive: Restore Task Options Summary
// TODO(spencer): Fully convert this to an ngComponent

(function (angular, undefined) {
  'use strict';

  angular
    .module('C.restoreTaskSummary', [])
    .directive('cRestoreOptionsSummary', RestoreTaskOptionsSummaryDirective);

  /**
   * @ngdoc directive
   * @name  C.restoreTaskSummary.directive:cRestoreOptionsSummary
   * @restrict 'AE'
   * @scope
   * @description
   *   Summarizes in plain language the config options for the given restore
   *   task
   *
   * @example
    <example>
      <div c-restore-options-summary="restoreTask"></div>
    </example>
   */
  function RestoreTaskOptionsSummaryDirective() {
    return {
      restrict: 'A',
      controller: controllerFn,
      controllerAs: '$ctrl',
      templateUrl:
        'app/global/c-restore-options-summary/c-restore-options-summary-directive.html',
      scope: { restoreTask: '=cRestoreOptionsSummary' },
    };

    /**
     * Directive controller.
     *
     * @method   controllerFn
     */
    function controllerFn(_, $scope, $q, $timeout, $rootScope, SourceService,
      ExternalTargetService, ViewService, ClusterService, RestoreService,
      PubJobService, ENUM_RESTORE_TYPE, IVM_ENV_PARAMS, ENV_TYPE_CONVERSION,
      AZURE_DISK_TYPES, ENV_GROUPS, FLR_RESTORE_METHOD) {

      var $ctrl = this;

      _.assign($ctrl, {
        isArray: _.isArray,
        isBoolean: _.isBoolean,
        isNumber: _.isNumber,
        isObject: _.isObject,
        isString: _.isString,
        FLR_RESTORE_METHOD: FLR_RESTORE_METHOD,
        restoreTask: $scope.restoreTask,
        getTargetName: ExternalTargetService.getTargetName,
        taskOptions: createTaskOptionsObject($scope.restoreTask),
        taskType: detectTaskType($scope.restoreTask),
        getVappName: getVappName,
        checkActiveStateBeforeNavigation: checkActiveStateBeforeNavigation,
        ENV_TYPE_CONVERSION: ENV_TYPE_CONVERSION,
        ENUM_RESTORE_TYPE: ENUM_RESTORE_TYPE,
        isOverwriteOriginalDatabase: isOverwriteOriginalDatabase,
        flrTextHash: {
          0: 'autoDeployCohesityAgent',
          1: 'useExistingCohesityAgent',
          2: 'useVmwareTools',
        },
      });

      $ctrl.oracleRestoreParams = _.get($ctrl, ['taskOptions',
        'restoreAppTaskState', 'restoreAppParams', 'restoreAppObjectVec[0]',
        'restoreParams', 'oracleRestoreParams'].join('.'), {});

      /**
       * Initialize this directive.
       *
       * @method   onInit
       */
      $ctrl.$onInit = function onInit() {
        var isClone = /^kClone/.test($ctrl.taskType);
        var promiseObject = {
          externalTargets: $rootScope.user.privs.CLUSTER_EXTERNAL_TARGET_VIEW ?
            ExternalTargetService.getTargets() : $q.resolve([]),
          vlans: $rootScope.user.privs.VLAN_VIEW ?
            ClusterService.getVlans() : $q.resolve([]),
        };

        var restoreParentSource =
          $scope.restoreTask.performRestoreTaskState.restoreParentSource;

        _.assign($ctrl, {
          isClone: isClone,
          isRecovery: !isClone,
          isHyperV: !!restoreParentSource && restoreParentSource.type === 2,
          includeVlanSettings: true,
        })

        _initCloudOptions();

        $ctrl.envParam = restoreParentSource &&
          _getEnvParam(restoreParentSource.type);

        $ctrl.parentSource = getTargetParentSource();

        if ($ctrl.parentSource) {
          $ctrl.parentSourceEntityKey =
            SourceService.getEntityKey($ctrl.parentSource.type);
        }

        // If this task has an archival target associated, we need to get the
        // vault's info to show.
        if (Array.isArray($ctrl.taskOptions.objects) &&
          $ctrl.taskOptions.objects[0] &&
          $ctrl.taskOptions.objects[0].archivalTarget) {

          promiseObject.externalTarget = ExternalTargetService.getTarget(
            $ctrl.taskOptions.objects[0].archivalTarget.vaultId
          );
        }

        // For NAS tasks, we need to get the view associated with it so we can
        // display the mount paths widget.
        if ($ctrl.taskType === 'kMountFileVolume' &&
          $ctrl.restoreTask.performRestoreTaskState.fullViewName) {

          promiseObject.view = ViewService.getView(
            $ctrl.restoreTask.performRestoreTaskState.fullViewName
          );
        }

        $ctrl.envTaskParams = $ctrl.restoreTask._envTaskParams;

        $q.all(promiseObject).then(function restoreOptionsSuccess(data) {
          $ctrl.extTargetSource = data.externalTarget;
          $ctrl.restoreTask._view = data.view;
          $ctrl.vlans = ClusterService.clusterInfo._vlans;
          $ctrl.externalTargets = ExternalTargetService.targetNameMapById;
        });

        _pollState();
      };

      /**
       * Initialize the cloud specific options to be displayed in UI
       *
       * @method  _initCloudOptions
       */
      function _initCloudOptions() {
        var cloudParams = _.get($ctrl.taskOptions,
          'deployVmsToCloudTaskState.deployVmsToCloudParams', {});
        // List of nested objects that needs to be flattened out.
        var nestedObjects = [];
        // Any params which need not be displayed
        //
        // 'pointInTime' is a value in ms which determines which snapshot
        // to use for recovery. The user doesn't need to see it in details.
        var ignoreParams = ['pointInTimeParams', 'customTagVec'];

        // These objects are nested but don't need to be flattened out since
        // they already have the desired display format.
        var validObjects = ['osDiskType', 'dataDiskType'];

        // cloudParams can have more than 1 key in case of fleet instances.
        $ctrl.cloudOptions = Object.keys(cloudParams).reduce(
          (acc, cur) => Object.assign(acc, cloudParams[cur]), {});

        // For cloud, hide VLAN initially and display based on native/agent
        // jobType
        if (!angular.equals({}, cloudParams)) {
          $ctrl.includeVlanSettings = false;
        }

        // flatten out any nested objects.
        // First find out all nested objects and cache them in an array.
        // Then iterate over the array and merge the nested objects with
        // parent.
        // Finally, delete the nested object to remove duplicates.
        if ($ctrl.cloudOptions) {
          _.each($ctrl.cloudOptions, function eachParam(value, key) {
            // Handle the case for fleet params.
            if (key === 'awsFleetParams') {
              _.assign($ctrl.cloudOptions, {
                fleetSubnetType: value.fleetSubnetType
              });
              if (value.fleetSubnetType === 'kCustom') {
                _.assign($ctrl.cloudOptions, {
                  fleetVpc: value.networkParams.vpc,
                  fleetSubnet: value.networkParams.subnet
                });
              }
              delete $ctrl.cloudOptions[key];
            }
            if (key === 'customTagVec') {
              $ctrl.customTags = [];
              $ctrl.showCustomTags = false;
              _.assign($ctrl.customTags, $ctrl.cloudOptions[key]);
            }
            // if a nested object has 'id' key, it means its an entity
            // object. Entities don't have to be flattened.
            // Likewise, valid objects need not be flattened.
            if (_.isObject(value) && !_.isArray(value) && !value.id &&
              !validObjects.includes(key)) {
              nestedObjects.push(key);
            }
          });
        }

        if (nestedObjects.length) {
          _.each(nestedObjects, function eachNested(key) {
            _.assign($ctrl.cloudOptions, $ctrl.cloudOptions[key]);
            delete $ctrl.cloudOptions[key];
          });
        }

        // Add any additional parameters, not available
        // in deployTaskTo<Cloud>Params
        if (_.get($ctrl.taskOptions,
          'objects[0].entity.awsEntity.dbEngineId')) {

          _.assign($ctrl.cloudOptions, {
            dbEngineId:
              $ctrl.taskOptions.objects[0].entity.awsEntity.dbEngineId,
          });
        }

        // Get rid of all params to be ignored
        _.each(ignoreParams, function eachIgnoreParam(key) {
          delete $ctrl.cloudOptions[key];
        });

        // Cloud restore tasks need to know if it was a native/agent job
        // That info is available in job object
        // Skip if job object is not available for adapters such as Exchange.
        if (_.get($scope.restoreTask, 'performRestoreTaskState.objects[0].jobId')) {
          PubJobService.getJob(
            $scope.restoreTask.performRestoreTaskState.objects[0].jobId)
            .then(function gotJob(job) {
              $ctrl.includeVlanSettings =
                !ENV_GROUPS.remoteSnapshotManagementSupported
                  .concat(ENV_GROUPS.nativeSnapshotTypes)
                  .includes(job.environment);
            });
        }
      }

      /**
       * Check if Overwrite Original Database.
       *
       * @method   isOverwriteOriginalDatabase
       * @return   {boolean}  Overwrite Original Database or not.
       */
      function isOverwriteOriginalDatabase() {
        var restoreEnv = _.get($ctrl, 'taskOptions.restoreInfo.type');
        var taskType = _.get($ctrl, 'taskOptions.base.type');

        return restoreEnv === $ctrl.ENV_TYPE_CONVERSION.kOracle &&
          !$ctrl.oracleRestoreParams.alternateLocationParams &&
          taskType !== ENUM_RESTORE_TYPE.kCloneAppView;
      }

      /**
       * Gets the target parent source, be it new or the original.
       *
       * @method   getTargetParentSource
       * @return   {object}   The target parent source Entity.
       */
      function getTargetParentSource() {
        var ownerRestoreInfo;
        var taskState;

        if (!$ctrl.restoreTask ||
          !$ctrl.restoreTask.performRestoreTaskState) {
          return;
        }

        taskState = $ctrl.restoreTask.performRestoreTaskState;

        switch (true) {
          // SQL restore
          case ([4, 7].includes(taskState.base.type) &&
            taskState.restoreAppTaskState.restoreAppParams.type === 3):

            // Restore to new target/source
            if (taskState.restoreAppTaskState.restoreAppParams.restoreAppObjectVec[0].restoreParams.targetHostParentSource) {
              return taskState.restoreAppTaskState.restoreAppParams.restoreAppObjectVec[0].restoreParams.targetHostParentSource;
            }

            ownerRestoreInfo = taskState.restoreAppTaskState.restoreAppParams.ownerRestoreInfo;

            // Restore to original source
            return ownerRestoreInfo.restoreParentSource ||
              ownerRestoreInfo.ownerObject.entity;

          // File Restore
          case (taskState.base.type === 3):
            return taskState.restoreFilesTaskState.restoreParams.targetEntityParentSource;

          // Restore to a new Parent Source
          default:
            return taskState.restoreParentSource;
        }

      }

      /**
       * Detects the task type for the given task
       *
       * @method   detectTaskType
       * @param    {Object}   taskOpts   The RestoreTaskObject /
       *                                 RetrieveArchiveTask
       * @return   {string}   the type detected
       */
      function detectTaskType(taskOpts) {

        var type = taskOpts.performRestoreTaskState ?
          taskOpts.performRestoreTaskState.base.type :
          taskOpts.base.type;

        return ENUM_RESTORE_TYPE[type] || ENUM_RESTORE_TYPE[1];
      }

      /**
       * Process the given RestoreTaskObject into a format we need.
       *
       * TODO: explore removing this function entirely, as it might be more
       * clear/transparent to access the object properties as they are returned
       * from the API.
       *
       * @method   createTaskOptionsObject
       * @param    {Object}   taskOpts   RestoreTaskObject to transform
       * @return   {Object}   Transformed RestoreTaskObject
       */
      function createTaskOptionsObject(taskOpts) {
        var opts = {};

        if (taskOpts) {
          if (taskOpts._refreshInfo) {
            // Show details of clone refresh, if there is any refresh
            // performed on the clone task.
            opts = _.last(taskOpts._refreshInfo._tasks);
          } else if (taskOpts.performRestoreTaskState) {
            // Show restore/clone task details.
            opts = taskOpts.performRestoreTaskState;
          } else {
            opts = taskOpts;
          }
        }

        return _getDisplayableOptions(opts);
      }

      /**
       * Get only the displayable options for an entity type.
       *
       * @method  _getDisplayableOptions
       * @param   (Object)  options  The options to filter
       * @return  The filtered options
       */
      function _getDisplayableOptions(options) {
        var azureParams = _.get(options, ['deployVmsToCloudTaskState',
          'deployVmsToCloudParams', 'deployVmsToAzureParams'].join('.'));

        switch (true) {
          // Azure
          case !!azureParams:
            var osDiskType =
              _.get(azureParams, 'azureManagedDiskParams.osDiskSkuType');
            var dataDiskType =
              _.get(azureParams, 'azureManagedDiskParams.dataDisksSkuType');

            if (angular.isDefined(osDiskType)) {
              azureParams.osDiskType = {
                displayName: AZURE_DISK_TYPES[osDiskType]
              };
            }
            if (angular.isDefined(dataDiskType)) {
              azureParams.dataDiskType = {
                displayName: AZURE_DISK_TYPES[dataDiskType]
              };
            }

            delete azureParams.azureManagedDiskParams;

            _.set(options, ['deployVmsToCloudTaskState',
              'deployVmsToCloudParams', 'deployVmsToAzureParams'], azureParams);

            return options;

          default:
            return options;
        }
      }

      /**
       * proto key for bringDisksOnline setting needs to be dynamic according
       * to targetParentSource.type. this method will return the proper key
       *
       * @return   {String}    proto key to sucessfully submit task
       */
      function _getEnvParam(parentSourceType) {
        switch (parentSourceType) {
          case ENV_TYPE_CONVERSION.kVMware:
            return IVM_ENV_PARAMS.kVMware;

          case ENV_TYPE_CONVERSION.kHyperV || ENV_TYPE_CONVERSION.kHyperVVSS:
            return IVM_ENV_PARAMS.kHyperV;

          default:
            return IVM_ENV_PARAMS.kVMware;
        }
      }

      /**
       * Check whether the newly created view is operational.
       * Block the navigation to view if it is not available yet
       * and display a warning message for the same.
       *
       * @method   checkActiveStateBeforeNavigation
       * @param    {Boolean}  isActive   Is the new view available
       */
      function checkActiveStateBeforeNavigation($event) {
        var isActive = ($scope.restoreTask
          .performRestoreTaskState.base.status === 3 &&
          !$scope.restoreTask.performRestoreTaskState.base.error);

        if (!isActive) {
          $event.preventDefault();
          $ctrl.errorMsg = true;

          $timeout(function toggleError() {
            $ctrl.errorMsg = false;
          }, 3000);
        }
      }

      /**
       * Poll server to check when the view becomes
       * available. Try a maximum of 3 times before
       * giving up.
       *
       * @method _pollState
       */
      function _pollState() {
        var taskId = $scope.restoreTask.performRestoreTaskState.base.taskId;
        var triesLeft = 3;
        var timeoutRef;

        $scope.$on('$destroy', function clearTimeoutRef() {
          $timeout.cancel(timeoutRef);
        });

        (function refreshState(restoreTask) {
          if ($scope.restoreTask.performRestoreTaskState.base.status !== 3 &&
            triesLeft > 0) {

            RestoreService.getTask(taskId).then(
              function refreshStateCB(restoreTasks) {
                $scope.restoreTask = restoreTasks[0];
              }
            );

            timeoutRef = $timeout(refreshState, 2000);

            triesLeft--;
          }
        })();
      }

      function getVappName() {
        return _.get($ctrl.envTaskParams, 'renameRestoredObjectParam.prefix', '')
          + $ctrl.envTaskParams.objects[0].entity.displayName
          + _.get($ctrl.envTaskParams, 'renameRestoredObjectParam.suffix', '');
      }
    }
  }

})(angular);
