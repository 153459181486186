import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { IconModule } from '../../icon/icon.module';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { NgClass } from '@angular/common';

/**
 * Specifies the acceptable values for the item's severity.
 */
export type ActivityItemSeverity = 'info' | 'success' | 'warn' | 'error' | 'failed' | 'running' | 'cancel';

/**
 * Renders an activity item.
 *
 * An activity item has an icon and supports arbitrary content rendering
 * inside it.
 *
 * @example
 * <cog-activity-item
 *   [icon]="icon"
 *   [iconTooltip]="tooltip"
 *   [severity]="severity"
 *   (click)="onItemClick()">
 * </cog-activity-item>
 */
@Component({
  selector: 'cog-activity-item',
  templateUrl: './activity-item.component.html',
  styleUrls: ['./activity-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgClass, MatLegacyTooltipModule, IconModule]
})
export class ActivityItemComponent {
  /**
   * The icon to render for the activity item.
   */
  @Input() icon: string;

  /**
   * Custom class for the activity item.
   */
  @Input() customClass: string;

  /**
   * Optional binding to show a tooltip when the icon is hovered.
   */
  @Input() iconTooltip: string;

  /**
   * Optional binding to show a tooltip when the icon is hovered.
   */
  @Input() multiLineTooltip = false;

  /**
   * The severity of this item. The severity value is used to render the icon
   * background of the item.
   */
  @Input() severity: ActivityItemSeverity;

  /**
   * Emits a value whenever the item is clicked.
   */
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() click = new EventEmitter();
}
