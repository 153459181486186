import { ValidatorFn, UntypedFormControl } from '@angular/forms';

/**
 * Function to return an error object if a form control's value has whitespaces.
 *
 * @param control The form control.
 * @return null if a valid string, otherwise error object.
 */
export const NoWhitespaceValidator: ValidatorFn = (control: UntypedFormControl) => {
  const hasWhitespace = (control.value || '').indexOf(' ') >= 0;
  return hasWhitespace ? { whitespace: true } : null;
};
