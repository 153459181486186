// Component: cRangeSlider widget

(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.rangeSlider';
  var moduleDeps = [];
  var componentName = 'cRangeSlider';
  var options = {
    // These are bound to the controller not the scope.
    bindings: {
      /**
       * Optional minimum value for the range slider.
       *
       * @type   {Number}
       */
      minValue: '=?min',

      /**
       * Optional maximum value for the range slider.
       *
       * @type   {Number}
       */
      maxValue: '=?max',

      /**
       * Optional range step value for the slider.
       *
       * @type   {Number}
       */
      step: '=?',

      /**
       * Optional label positioning boolean for the slider.
       * True if the labels have to be below slider.
       *
       * @type   {Boolean}
       */
      labelBelow: '=?',

      /**
       * Optional label for minimum value on the slider.
       *
       * @type   {String}
       */
      labelMin: '=?',

      /**
       * Optional label for maximum value on the slider.
       *
       * @type   {String}
       */
      labelMax: '=?',

      /**
       * Optional name attribute for the <input> range slider.
       *
       * @type   {String}
       */
      name: '@?',

      /**
       * Optional id attribute for the <input> range slider.
       *
       * @type   {String}
       */
      id: '@?',

      /**
       * Optional required attribute for the <input> range slider.
       *
       * @type   {Boolean}
       */
      required: '=?',

      /**
       * Optional disabled attribute for the <input> range slider.
       *
       * @type   {Boolean}
       */
      disabled: '=?',
    },
    controller: cRangeSliderControllerFn,
    require: { ngModel: 'ngModel' },
    templateUrl: 'app/global/c-range-slider/c-range-slider.html',
  };

  angular
    .module(moduleName, moduleDeps)
    .component(componentName, options);

  /**
   * @ngdoc component
   * @name C.rangeSlider:cRangeSlider
   * @function
   *
   * @description
   *   Enhanced HTML slider input.
   *
   * @example
     <c-range-slider
       required="true"
       min-value="1"
       max-value="10"
       step="0.5"
       ng-model="rangeModel">
     </c-range-slider>
  */
  function cRangeSliderControllerFn() {
    var $ctrl = this;

    // Default name || id
    $ctrl.name = $ctrl.name || [ componentName, Date.now() ].join('-');
    $ctrl.step = $ctrl.step || 1;
    $ctrl.getSetModel = getSetModel;

    // Methods

    // The init method
    $ctrl.$onInit = function componentInit() {
      getSetModel($ctrl.value || $ctrl.minValue);
    };

    /**
     * Sync the model in this component with the model in the calling
     * controller
     *
     * @method     getSetModel
     * @param      {integer}  value   The model value to set when used as a
     *                                setter
     * @return     {integer}  The current model value.
     */
    function getSetModel(value) {
      if (value) {
        $ctrl.ngModel.$setViewValue(+value);
      }

      return $ctrl.ngModel.$modelValue;
    }
  }

})(angular);
