import { ProtectionSourceNode, SapHanaObject } from '@cohesity/api/v1';
import { Environment } from 'src/app/shared/constants';
import { SapHanaObjectTypeIconMap } from 'src/app/shared/constants/saphana.constants';

import { ProtectionSourceDataNode } from '../shared/protection-source-data-node';
import { HasMetadata, SourceNodeMetadata } from '../shared/protection-source-metadata/source-node-metadata';

/**
 * Represents an active sap hana source node and job tree selection behavior.
 */
export class SapHanaSourceDataNode extends ProtectionSourceDataNode implements HasMetadata {
  /**
   * Stores the metadata for the source node.
   */
  readonly metadata: SourceNodeMetadata;

  /**
   * Stores the sap hana objectInfo.
   */
  readonly objectInfo: SapHanaObject;

  constructor(data: ProtectionSourceNode, readonly level: number) {
    super(Environment.kSAPHANA, data, level, []);
    const sapHanaProtectionSource = data.protectionSource?.sapHanaProtectionSource || null;
    this.objectInfo = sapHanaProtectionSource ? sapHanaProtectionSource.objectInfo : null;

    this.metadata = {
      logicalSize: this.logicalSize,
      leafCount: this.expandable ? this.leafCount : undefined,
      nodeIdentifierKey: Environment.kSAPHANA + '.' + this.envSource.type
    };
  }

  /**
   * Whether the node is a leaf which can be directly selected or not.
   */
  get isLeaf() {
    return this.objectInfo ? this.objectInfo.isLeaf : true;
  }

  /**
   * Get the helix icon name to use for rendering the icon next to the tree node.
   */
  get icon(): string {
    const objectType = this.protectionSource?.sapHanaProtectionSource?.objectInfo?.objectType || 'Database';
    // Defaults to database Icon
    const iconName = SapHanaObjectTypeIconMap[objectType] || 'helix:object-db';

    // Object nodes which are auto protected should show the auto protected icon too
    const suffix = (this.isAutoProtectedByObject || (!this.isLeaf && this.isObjectProtected)) ? '-auto' :
      this.protected ? '-protected' :
      this.partialProtected ? '-partial' : '';

    return iconName.concat(suffix);
  }
}
