// Controller: Physical Server: Options step

;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.physicalServerRecovery';

  angular
    .module(moduleName)
    .controller('physicalServerRecoveryOptionsController',
      physicalServerRecoveryOptionsControllerFn);

  function physicalServerRecoveryOptionsControllerFn($scope, $stateParams,
    PhysicalServerRecoveryService) {
    var $ctrl = this;

    angular.extend($ctrl, {
      // scope methods
      actionTypeOptions: PhysicalServerRecoveryService.getActionTypeOptions(),
      getTargetVolumeLabel: PhysicalServerRecoveryService.getTargetVolumeLabel,
      isTargetDisabled: PhysicalServerRecoveryService.isTargetDisabled,
      selectRestorePoint: PhysicalServerRecoveryService.selectRestorePoint,
      selectVolumes: PhysicalServerRecoveryService.selectVolumes,
      selectTarget: PhysicalServerRecoveryService.selectTarget,
      selectTargetVolume: PhysicalServerRecoveryService.selectTargetVolume,
      setAllVolumesSelected:
        PhysicalServerRecoveryService.setAllVolumesSelected,
      submitTask: PhysicalServerRecoveryService.submitTask,
      toggleAllVolumes: PhysicalServerRecoveryService.toggleAllVolumes,
      unsetTargetVolume: PhysicalServerRecoveryService.unsetTargetVolume,
    });

    $scope.entity = $scope.shared.cart[0];


    // METHODS
    /**
     * Initialize option step
     *
     * @method     onInit
     */
    $ctrl.$onInit = function onInit() {
      if ($stateParams.entityId && $stateParams.jobId) {
        // This is an abbreviated flow
        PhysicalServerRecoveryService.setupAbbreviatedFlow();
      } else if (!$scope.shared.cart.length) {
        // Nothing to recover.
        PhysicalServerRecoveryService.startFlowOver();
      }
    };

  }

})(angular);
