<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content>
  <p>{{ message }}</p>
  <mat-checkbox
    class="margin-bottom-lg"
    [inheritDataId]="true"
    cogDataId="navigation-warning-checkbox"
    (change)="toggleNavigationWarning($event.checked)"
  >
    {{ 'dontShowThisAgain' | translate }}
  </mat-checkbox>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button cogCancelButton cogDataId="navigation-warning-cancel-button" [mat-dialog-close]="false">
    {{ 'cancel' | translate }}
  </button>
  <button color="primary" mat-flat-button [mat-dialog-close]="true" cogDataId="navigation-warning-confirm-button">
    {{ 'navigation.interrupt.confirmButton' | translate }}
  </button>
</mat-dialog-actions>
