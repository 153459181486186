import { TranslateService } from '@ngx-translate/core';
import { HelixIntlStrings } from '@cohesity/helix';
import { Injectable } from '@angular/core';

@Injectable()
export class AppHelixIntlTranslations implements HelixIntlStrings  {
    constructor(private translate: TranslateService) {
    }

    banner = {
      dismiss: 'Dismiss Notice',
      next: 'Next Notice',
      previous: 'Previous Notice',
      title: 'System Notices'
    };
    get clear() {
      return this.translate.instant('clear');
    }
    get close() {
      return this.translate.instant('close');
    }
    get cohesity() {
      return this.translate.instant('cohesity');
    }
    get copyToClipboard() {
      return this.translate.instant('copyToClipboard');
    }
    get noResults() {
      return this.translate.instant('noResultsFound');
    }
    get darkModeDisable() {
      return this.translate.instant('darkModeDisable');
    }
    get darkModeEnable() {
      return this.translate.instant('darkModeEnable');
    }
    dataFilters = {
      apply: 'Apply',
      clearFilters: 'Clear',
      filtersColon: 'Filters:',
      search: 'Search',
      searchResult: (count = 0, total = 0) => `Showing ${count} of ${total} results`,
      zeroResult: '0 results',
      dateRange: {
        timeframe: {
          past1Hour: 'Past Hour',
          past12Hours: 'Past 12 Hours',
          past24Hours: 'Past 24 Hours',
          past7Days: 'Past 7 Days',
          past30Days: 'Past 30 Days',
          past60Days: 'Past 60 Days',
          past90Days: 'Past 90 Days',
          past180Days: 'Past 180 Days',
          past365Days: 'Past 365 Days',
          pastMonth: 'Past Month',
          past3Months: 'Past 3 Months',
          past6Months: 'Past 6 Months',
          pastYear: 'Past Year',
          currentMonth: 'Current Month',
          currentYear: 'Current Year',
          day: '1 Day',
          week: '7 Days',
          month: '30 Days',
          quarter: '13 Weeks',
          halfYear: '6 Months',
          year: '1 Year',
          custom: 'Custom',
          forever: 'Forever',
        },
      },
      moreFilters: (count = 0) => {
        let ret = 'More Filters';

        if (count > 0) {
          ret = `${count} ${ret}`;
        }

        return ret;
      },
      appliedFilters: (count = 0) => `${count} Filters Applied`,
      maxRangeWarning: (maxRange) => `Date range limited to ${maxRange} days`
    };
    dateRange = {
      startDate: 'Start Date',
      endDate: 'End Date',
      startTime: 'Start Time',
      endTime: 'End Time',
    };
    duration = {
      y: 'y',
      mo: 'mo',
      w: 'w',
      d: 'd',
      h: 'h',
      m: 'm',
      s: 's'
    };
    //
    get next() {
      return this.translate.instant('next');
    }
    naNotAvailable = '-';
    get noDataAvailable() {
      return this.translate.instant('noDataAvailable');
    };
    get open() {
      return this.translate.instant('open');
    }
    get previous() {
      return this.translate.instant('previous');
    }
    get search() {
      return this.translate.instant('search');
    }
    get selected() {
      return this.translate.instant('selected');
    }
    get selectRow() {
      return this.translate.instant('selectRow');
    }
    get settingsListDisabledTooltip() {
      return this.translate.instant('settingsListDisabledTooltip');
    }
    get skipToPrimaryContent() {
      return this.translate.instant('skipToPrimaryContent');
    }
    get onlyShowLinesWithDifferences() {
      return this.translate.instant('onlyShowLinesWithDifferences');
    }
    table = {
      checkBoxHeader: 'Selection',
      firstPageLabel: 'First Page',
      itemsPerPageLabel: 'Items per page',
      lastPageLabel: 'Last Page',
      nextPageLabel: 'Next Page',
      previousPageLabel: 'Previous Page',
      rangeLabel: (start: number, end: number, length: number) => `${start} - ${end} of ${length}`,
      rangeLabelMore: (start: number, end: number) => `${start} - ${end} of ${end}+`,
      rangeLabelNone: (length: number) => `0 of ${length}`,
      multiAction: {
        allItemsSelected: 'All Items Selected',
        selectTablePageItems: 'Select all on page',
        clearAll: 'Unselect all',
        selectAllItems: count => this.translate.instant('defaultSelectAllLabel',{items:count}),
        selectedItems: count => this.translate.instant('defaultSelectionLabel',{items:count})
      }
    };
    timeframe = {
      Past1Hour: 'Past Hour',
      Past12Hours: 'Past 12 Hours',
      Past24Hours: 'Past 24 Hours',
      Past7Days: 'Past 7 Days',
      Past30Days: 'Past 30 Days',
      Past60Days: 'Past 60 Days',
      Past90Days: 'Past 90 Days',
      Past180Days: 'Past 180 Days',
      Past365Days: 'Past 365 Days',
      PastMonth: 'Past Month',
      Past3Months: 'Past 3 Months',
      Past6Months: 'Past 6 Months',
      PastYear: 'Past Year',
      CurrentMonth: 'Current Month',
      CurrentYear: 'Current Year',
      Day: '1 Day',
      Week: '7 Days',
      Month: '30 Days',
      Quarter: '13 Weeks',
      HalfYear: '6 Months',
      Year: '1 Year',
      Custom: 'Custom',
      Forever: 'Forever',
    };
    get today() {
      return this.translate.instant('today');
    }
  }
