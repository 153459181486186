<header>
  <div>
    <h2 mat-dialog-title>
      {{ 'setPrimary' | translate }}
    </h2>
  </div>
</header>
<form cohValidateOnSubmit cogFormFocusedHighlight
  [useNativeDomQuery]="true" [formGroup]="confirmFormGroup" (validatedSubmit)="onSubmit()">
  <mat-dialog-content>
    <div [innerHtml]="'cassandra.setPrimary.info' | translate"></div>
    <mat-form-field class="margin-top">
      <input matInput type="text"
        placeholder="{{'confirmationMessage' | translate: {phrase: 'yes' | translate } }}"
        formControlName="confirmInput"
        cogDataId="set-primary-confirm-input" required/>
      <mat-error cogDataId="confirm-required-invalid" *ngIf="confirmFormGroup?.controls?.confirmInput.errors?.required">
        {{'errors.required' | translate}}
      </mat-error>
      <mat-error cogDataId="confirm--pattern-pattern" *ngIf="confirmFormGroup?.controls?.confirmInput.errors?.pattern">
        {{'mustInputYes' | translate}}
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      cogCancelButton
      mat-button
      mat-dialog-close
      color="primary"
      cogDataId="cancel-btn">
      {{ 'cancel' | translate }}
    </button>
    <button
      cogSubmitButton
      mat-flat-button
      type="submit"
      cdkFocusInitial
      [disabled]="!confirmFormGroup.valid"
      cogDataId="save-btn">
      {{ 'save' | translate }}
    </button>
  </mat-dialog-actions>
</form>
