import { McmClusterInfo } from '@cohesity/api/private';
import { ObjectProtectionGroupSummary, ObjectProtectionInfo, SearchObject } from '@cohesity/api/v2';
import { Environment } from '@cohesity/iris-shared-constants';

import { HeliosFile } from './global-search-file-item.model';
import { GlobalSearchResultType } from './global-search-result-type.model';

/**
 * Options for global search result.
 */
export interface GlobalSearchItemOptions {
  /*
   * Map of registered clusters and their details.
   */
  clusterDetailsMap: Record<number, McmClusterInfo>;

  /**
   * Map of registered locations and their names.
   */
  locationNameMap: Record<string, string>;

  /**
   * Map of available tenant ids and their names
   */
  tenantsNameMap?: Record<string, string>;
}

/**
 * Extended object protection group model to include cluster id of the
 * protection group.
 */
export interface ObjectProtectionGroupInfo extends ObjectProtectionGroupSummary {
  /**
   * The id of the cluster where the protection group.
   */
  clusterId?: number;

  /**
   * The name of the cluster where this protection group is.
   */
  clusterName?: string;

  /**
   * The region id of the protection group.
   */
  regionId?: string;
}

/**
 * Extended object protection info model to include region specific result info.
 */
export interface ObjectLocationInfo extends ObjectProtectionInfo {
  /**
   * The name of the location object is protected in. This is either a region
   * name or a cluster name.
   */
  locationName?: string;

  /**
   * The details of cluster the object belongs to.
   */
  clusterDetails?: McmClusterInfo;

  /**
   * Whether the object is protected as part of an object protection or not.
   */
  hasObjectProtection: boolean;

  /**
   * Whether the object is protected as part of a protection group or not.
   */
  hasGroupProtection: boolean;

  /**
   * Whether the object is protected.
   */
  isProtected: boolean;
}

/**
 * Interface for a global search result's deleted info.
 */
interface GlobalSearchDeletedInfo {
  /**
   * Whether the result is deleted.
   */
  value: boolean;

  /**
   * Whether the search result is deleted in all locations.
   */
  allLocations: boolean;

  /**
   * Whether the search result is deleted in some locations.
   */
  someLocations: boolean;
}

export abstract class GlobalSearchBaseItem {
  /**
   * Name of the result.
   */
  name: string;

  /**
   * The result's source name.
   */
  sourceName: string;

  /**
   * The reference to the API model.
   */
  reference: HeliosFile | SearchObject;

  /**
   * The type of the global search item.
   */
  type: HeliosFile['type'] | SearchObject['objectType'];

  /**
   * The result's global id.
   */
  globalId: string;

  /**
   * The result's environment.
   */
  environment: Environment;

  /**
   * The result's source id. In MCM mode, this should be fetched from
   * locationObjectMap.
   */
  sourceId: number;

  /**
   * The Protection Groups protecting this result.
   */
  protectionGroups: ObjectProtectionGroupInfo[];

  /**
   * Whether the result is protected as part of a protection group or not.
   */
  hasGroupProtection: boolean;

  /**
   * Whether the result is protected.
   */
  isProtected: boolean;

  /**
   * Id of tenant this result belongs to
   */
  tenantIds: string[];

  /**
   * Array of cluster ids the result belongs to.
   */
  clusterIds: number[];

  /**
   * Array of region ids the result belongs to.
   */
  regionIds: string[];

  /**
   * Map of location and the object's associated info in that location. The key
   * is either a region id or a cluster id.
   */
  locationObjectMap: Record<string, ObjectLocationInfo>;

  /**
   * Type of search results.
   */
  resultType: GlobalSearchResultType;

  /**
   * Whether the result has been deleted.
   */
  deletedInfo: GlobalSearchDeletedInfo;

  constructor(searchItem: any, _options: GlobalSearchItemOptions) {
    this.name = searchItem.name;
    this.reference = searchItem;

    // Source name remain the same across different regions and clusters.
    this.sourceName = searchItem.sourceInfo?.name;
  }
}
