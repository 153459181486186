// Controller: Launch Cloud Deploy Instance

;(function(angular, undefined) {
  'use strict';

  angular.module('C.jobRunDetails')
    .controller('launchCloudDeployInstanceController',
      launchCloudDeployInstanceControllerFn);

  function launchCloudDeployInstanceControllerFn(_, $state, opts, evalAJAX,
    PubSourceService, RestoreService, SourceService, cMessage,
    $uibModalInstance, ENV_TYPE_CONVERSION) {

    var $ctrl = this;

    _.assign($ctrl, {
      $onInit: init,
      cancel: cancel,
      filterAzureNodes: filterAzureNodes,
      filterAwsNodes: filterAwsNodes,
      instanceSourceLoading: true,
      azureManagedDiskParams: {},
      metadata: opts.getLaunchMetadata(),
      submit: submit,
      sourceLists: {},
      virtualNetworkSelected: virtualNetworkSelected,
    });

    /**
     * initialize the controller
     *
     * @method   $onInit
     */
    function init() {
      var metadata = $ctrl.metadata;

      $ctrl.targetObj = angular.copy(metadata.targetObj);

      // there can be multiple entities to be launched
      // (if "launch all instances" is clicked)
      $ctrl.entities = (metadata.tasks || []).map(function mapTasks(task) {
        return task.entity;
      });

      _populateInstance($ctrl.targetObj);
    }

    /**
     * Fetch the cloud deploy sources and populate the instance to be launched
     * with the relevant target data.
     *
     * @method   _populateInstance
     * @param    {Object}   targetObj   The launch target
     */
    function _populateInstance(targetObj) {
      var targetEntity = targetObj.targetEntity;
      var target = {
        id: targetEntity.id,
        name: targetEntity.displayName || targetEntity.name,
      };

      PubSourceService.getSource(targetEntity.id).then(
        function getSourceSuccess(sources) {
          var cloudNodes = _.get(sources, '[0].nodes');
          var allResourceGroups;

          switch (targetEntity.type) {
            case ENV_TYPE_CONVERSION.kAzure:
              allResourceGroups = cloudNodes.filter(function eachNode(node) {
                return node._type === 'kResourceGroup';
              });

              target.azureParams = angular
                .copy(targetObj.deployVmsToCloudParams.deployVmsToAzureParams);
              target.type = 'kAzure';

              // Resource Group, Storage Account and Storage Container are
              // already specified in policy. Find their corressponding
              // entities from the source-nodes list.
              // These will appear as read-only in UI.
              $ctrl.resourceGroup =
                allResourceGroups.find(function eachNode(node) {
                  return node.protectionSource.id ===
                    target.azureParams.resourceGroup.id;
                });

              $ctrl.storageResourceGroup =
                allResourceGroups.find(function eachNode(node) {
                  return node.protectionSource.id ===
                    _.get(target, 'azureParams.storageResourceGroup.id');
                });

              allResourceGroups.find(function forEachResourceGroup(rg) {
                $ctrl.storageAccount = rg.nodes.find(function eachNode(node) {
                  return node._type === 'kStorageAccount' &&
                    node.protectionSource.id ===
                      target.azureParams.storageAccount.id;
                });

                if ($ctrl.storageAccount) {
                  // in case of an older task (created prior to 6.2), the
                  // storageResourceGroup will not be avaialble. In that case
                  // fall back to the resourceGroup
                  if (!$ctrl.storageResourceGroup) {
                    $ctrl.storageResourceGroup = rg;
                  }

                  return true;
                }
              });

              $ctrl.storageContainer =
                $ctrl.storageAccount.nodes.find(function eachNode(node) {
                  return node._type === 'kStorageContainer' &&
                    node.protectionSource.id ===
                      target.azureParams.storageContainer.id;
                });

              // add the above to the targetEntity to be sent to backend
              _.assign(target.azureParams, {
                resourceGroup: $ctrl.resourceGroup.protectionSource,
                storageAccount: $ctrl.storageAccount.protectionSource,
                storageContainer: $ctrl.storageContainer.protectionSource,
                storageResourceGroup:
                  $ctrl.storageResourceGroup.protectionSource
              });

              // lists to be populated in the UI dropdowns
              // based on above selection
              $ctrl.sourceLists.computeOption =
                _filterNodes($ctrl.resourceGroup.nodes, 'kComputeOptions');

              $ctrl.sourceLists.virtualNetwork =
                _.flatten(
                  allResourceGroups.map(function forEachResourceGroup(src) {
                    // old backend may not have location key. In that case,
                    // allow all virtualNetworks.
                    if (!src._envProtectionSource.location ||
                      (src._envProtectionSource.location ===
                      target.azureParams.resourceGroup
                      .azureProtectionSource.location)) {

                      // Get all virtual networks from resourceGroup entity tree
                      var filteredNodes =
                        _filterNodes(src.nodes, 'kVirtualNetwork');

                      // further filter based on location of
                      // selected resource group
                      if (_.get(filteredNodes,
                        '[0]._envProtectionSource.location')) {

                        filteredNodes = _.filter(filteredNodes, [
                          '_envProtectionSource.location',
                          $ctrl.resourceGroup._envProtectionSource.location
                        ]);
                      }

                      filteredNodes.forEach(function eachNode(node) {
                        node._resourceGroup = src.protectionSource;
                        node._groupName = src.protectionSource.name;
                      });

                      return filteredNodes;
                    } else {
                      return [];
                    }
                  })
                );
              break;

            case ENV_TYPE_CONVERSION.kAWS:
              target.awsParams = angular
                .copy(targetObj.deployVmsToCloudParams.deployVmsToAwsParams);
              target.type = 'kAWS';

              // Region, VPC, Subnet and NSG is already specified in policy.
              // Find their corresponding entity from the source-nodes list.
              // This will appear as read-only in UI.
              $ctrl.region =
                cloudNodes.find(function eachNode(node) {
                  return node._type === 'kRegion' &&
                    node.protectionSource.id === target.awsParams.region.id;
                });

              if (target.awsParams.vpc) {
                $ctrl.vpc =
                  $ctrl.region.nodes.find(function eachNode(node) {
                    return node._type === 'kVPC' &&
                      node.protectionSource.id === target.awsParams.vpc.id;
                  });
              }

              if ($ctrl.vpc) {
                if (target.awsParams.subnet) {
                  $ctrl.subnet =
                    $ctrl.vpc.nodes.find(function eachNode(node) {
                      return node._type === 'kSubnet' &&
                        node.protectionSource.id === target.awsParams.subnet.id;
                    });
                }
              }

              // add the above 3 to the target entity to be sent to backend
              _.assign(target.awsParams, {
                region: $ctrl.region.protectionSource,
                vpc: _.get($ctrl, 'vpc.protectionSource'),
                subnet: _.get($ctrl, 'subnet.protectionSource'),
                networkSecurityGroups: ($ctrl.networkSecurityGroups || [])
                  .map(function mapNSG(nsg) {
                    return nsg.protectionSource;
                  }),
              });

              // lists to be populated in the UI dropdowns
              // based on above selection
              $ctrl.sourceLists.instanceType =
                _filterNodes($ctrl.region.nodes, 'kInstanceType');

              $ctrl.sourceLists.vpc =
                _filterNodes($ctrl.region.nodes, 'kVPC');

              $ctrl.sourceLists.subnet =
                _filterNodes(_.get($ctrl, 'vpc.nodes') || [], 'kSubnet');

              $ctrl.sourceLists.networkSecurityGroups = _filterNodes(
                  _.get($ctrl, 'vpc.nodes') || [], 'kNetworkSecurityGroup');
              break;
          }

          $ctrl.target = target;
        }, function getSourceError(resp) {
          cMessage.error({
            acknowledgeTextKey: 'ok',
            persist: true,
            textKey: 'cloudDeploy.launchInstance.sourceError',
            titleKey: 'error',
          }).then($uibModalInstance.dismiss);
        }
      ).finally(function getSourcesFinally() {
         $ctrl.instanceSourceLoading = false;
      });
    }

    /**
     * filter azure nodes in a tree based on node type
     *
     * @method   filterAzureNodes
     * @param    {Object[]}   nodeList   The node list
     * @param    {String}     type       The type of nodes to filter
     * @param    {String}     kType      The kConstant of type
     */
    function filterAzureNodes(nodeList, type, kType) {
      $ctrl.sourceLists[type] = _filterNodes(nodeList, kType);
      $ctrl.target.azureParams[type] = undefined;
    }

    /**
     * filter aws nodes in a tree based on node type
     *
     * @method   filterNodes
     * @param    {Object[]}   nodeList   The node list
     * @param    {String}     type       The type of nodes to filter
     * @param    {String}     kType      The kConstant of type
     */
    function filterAwsNodes(nodeList, type, kType) {
      $ctrl.sourceLists[type] = _filterNodes(nodeList, kType);
      $ctrl.target.awsParams[type] = undefined;
    }

    /**
     * filter nodes in a tree based on node type
     *
     * @method   filterNodes
     * @param    {Object[]}   nodeList   The node list
     * @param    {String}     kType      The kConstant of nodes to filter
     */
    function _filterNodes(nodeList, kType) {
      return nodeList.filter(
        function forEachNode(node) {
          return node._type === kType;
        }
      );
    }

    /**
     * Callback for virtual network selection dropdown
     *
     * @method virtualNetworkSelected
     * @param  {Object}  selectedVN   The selected virtual network object
     */
    function virtualNetworkSelected(selectedVN) {
      $ctrl.filterAzureNodes(selectedVN.nodes, 'subnet', 'kSubnet');
      $ctrl.target.azureParams.networkResourceGroup = selectedVN._resourceGroup;
    }

    /**
     * submit the form to the server
     *
     * @method   submit
     */
    function submit() {
      var stringMap = {
        kAWS: {
          deployString: 'deployVmsToAwsParams',
          paramsString: 'awsParams',
        },
        kAzure: {
          deployString: 'deployVmsToAzureParams',
          paramsString: 'azureParams',
        },
      };

      var metadata = $ctrl.metadata;
      var cloudDeployTarget;

      // the json that backend is expecting
      var deployConfig = {
        name: RestoreService.getDefaultTaskName('Deploy', 'VM'),

        // one object corresponding to each VM to be deployed
        objects: metadata.tasks.map(function eachTask(task) {
          var type;

          // cloud target to deploy VM
          cloudDeployTarget = task.snapshotTarget.cloudDeployTarget;

          // type -> aws or azure
          type = ENV_TYPE_CONVERSION[cloudDeployTarget.targetEntity.type];

          cloudDeployTarget.deployVmsToCloudParams[stringMap[type]
            .deployString] = _.assign(
              SourceService.publicEntitiesToPrivateEntities(
                $ctrl.target[stringMap[type].paramsString]
              ), $ctrl.azureManagedDiskParams);

          return {
            jobId: metadata.job.jobDescription.jobId,
            jobUid: metadata.job.jobDescription.jobUid,
            entity: task.entity,
            jobInstanceId: task.jobInstanceId,
            startTimeUsecs: task.runStartTimeUsecs,
            cloudDeployTarget: cloudDeployTarget,
          };
        }),

        // following 2 are redundant but backend needs them
        restoreParentSource: cloudDeployTarget.targetEntity,
        deployVmsToCloudParams: cloudDeployTarget.deployVmsToCloudParams,
      };

      $ctrl.launching = true;

      RestoreService.deployToCloud(deployConfig)
        .then(function deploySuccess(resp) {
          var opts = {
            acknowledgeTextKey: 'viewTask',
            dismissTextKey: 'close',
            persist: true,
            textKey: 'cloudDeploy.launchInstance.successMessage',
            titleKey: 'success',
          };

          cMessage.success(opts).then(function acknowledgedMessage() {
            $uibModalInstance.close();

            // navigate to deploy task detail
            $state.go('clone-detail', {
              id: resp.performRestoreTaskState.base.taskId,
            });
          }, $uibModalInstance.dismiss);
        }, evalAJAX)
        .finally(function deployFinally() {
          $ctrl.launching = false;
        });
    }

    /**
     * Cancel form submission and close the modal
     *
     * @method   cancel
     */
    function cancel() {
      $uibModalInstance.close();
    }
  }

})(angular);
