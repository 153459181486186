import { Directive, Input, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AutoDestroyable } from '@cohesity/utils';
import { ShowHideEditControlWrapperComponent } from 'src/app/shared/show-hide-edit-control';
import { ProtectionGroupObject, ProtectionItemName } from '../../models';

@Directive()
export abstract class BaseProtectionBuilderComponent<ValueType, PropertiesType> extends AutoDestroyable {
  /**
   * Name of the form control to be attached.
   */
  @Input() name: ProtectionItemName;

  /**
   * Reference to the quick protection wrapper. This is present only when this
   * component is used in a quick protect context.
   */
  @ViewChild(ShowHideEditControlWrapperComponent) protectionItemWrapper: ShowHideEditControlWrapperComponent;

  /**
   * Variable to hold the Protection Group object when edit state. Also holds
   * the partial state of Protection Group object during Group creation.
   */
  protectionGroup: ProtectionGroupObject;

  /**
   * Additional properties passed for this protection item.
   */
  properties: PropertiesType;

  /**
   * Value of the form control.
   */
  _value: ValueType;

  /**
   * Returns the current value of the form control.
   */
  get value(): ValueType {
    return this._value;
  }

  /**
   * Sets the new value for the form control.
   */
  set value(value: ValueType) {
    this._value = value;
  }

  constructor() {
    super();
  }

  /**
   * Form group to attach form control to.
   */
  _formGroup: UntypedFormGroup;

  /**
   * Get form group value in the base and child class.
   */
  get formGroup(): UntypedFormGroup {
    return this._formGroup;
  }

  /**
   * Set form group value from the consumer component.
   *
   * @param   formGroup   The form group.
   */
  @Input() set formGroup(formGroup: UntypedFormGroup) {
    this._formGroup = formGroup;

    // Add the form control whenever the passed group changes.
    this.addFormControl();

    if (this.initFormControl) {
      // If the child class has defined a custom form control initialization, call it.
      this.initFormControl();
    }
  }

  /**
   * Get the current form control.
   */
  get formControl(): AbstractControl {
    return this.formGroup?.get(this.name);
  }

  /**
   * Adds a FormControl for the specific builder component.
   */
  addFormControl() {
    // The this.value is the initial value for the form control which will be
    // set by the child components classes for edit state and as undefined for
    // new protection group.
    this.formGroup.addControl(this.name, new UntypedFormControl(this.value, [Validators.required]));
  }

  initFormControl?(): void;

  /**
   * In quick protect, only show the selector when the quick protect protection
   * item is in edit mode. This is because, when switching to edit mode, the
   * selector needs to be autofocused/autoopened, which can only happen during
   * the initial render, and not when showing/hiding the selector.
   */
  get showSelectorInQuickProtect(): boolean {
    if (!this.properties || !(this.properties as any).quickProtect) {
      // If not in quick protect mode, or no properties, then show the
      // component like regular.
      return true;
    }

    if (!this.protectionItemWrapper) {
      return false;
    }

    // Once the form control has been interacted with (formControl.dirty), keep
    // the selector displayed to not lose the value.
    return this.formControl.dirty || this.protectionItemWrapper.editControlVisible;
  }
}
