/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DataTieringAnalysisGroups } from '../models/data-tiering-analysis-groups';
import { DataTieringAnalysisGroup } from '../models/data-tiering-analysis-group';
import { CommonDataTieringAnalysisGroupParams } from '../models/common-data-tiering-analysis-group-params';
import { DataTieringTagConfig } from '../models/data-tiering-tag-config';
import { UpdateDataTieringState } from '../models/update-data-tiering-state';
import { UpdateDataTieringStateRequest } from '../models/update-data-tiering-state-request';
import { DataTieringAnalysisGroupRuns } from '../models/data-tiering-analysis-group-runs';
import { DataTieringAnalysisRunRequest } from '../models/data-tiering-analysis-run-request';
import { CapacityTrendAnalysis } from '../models/capacity-trend-analysis';
import { DataTieringTasks } from '../models/data-tiering-tasks';
import { DataTieringTask } from '../models/data-tiering-task';
import { CreateOrUpdateDataTieringTaskRequest } from '../models/create-or-update-data-tiering-task-request';
import { DataTieringTaskRunRequest } from '../models/data-tiering-task-run-request';
@Injectable({
  providedIn: 'root',
})
class DataTieringService extends __BaseService {
  static readonly GetDataTieringAnalysisGroupsPath = '/data-tiering/analysis-groups';
  static readonly CreateDataTieringAnalysisGroupPath = '/data-tiering/analysis-groups';
  static readonly GetDataTieringAnalysisGroupsDefaultConfigPath = '/data-tiering/analysis-groups/config';
  static readonly UpdateDataTieringAnalysisGroupsStatePath = '/data-tiering/analysis-groups/states';
  static readonly GetDataTieringAnalysisGroupByIdPath = '/data-tiering/analysis-groups/{id}';
  static readonly UpdateDataTieringAnalysisGroupPath = '/data-tiering/analysis-groups/{id}';
  static readonly DeleteDataTieringAnalysisGroupPath = '/data-tiering/analysis-groups/{id}';
  static readonly UpdateDataTieringAnalysisGroupTagsConfigPath = '/data-tiering/analysis-groups/{id}/config';
  static readonly GetDataTieringAnalysisGroupRunsPath = '/data-tiering/analysis-groups/{id}/runs';
  static readonly CreateDataTieringAnalysisGroupRunPath = '/data-tiering/analysis-groups/{id}/runs';
  static readonly CancelDataTieringAnalysisGroupRunPath = '/data-tiering/analysis-groups/{id}/runs/{runId}/cancel';
  static readonly GetCapacityTrendAnalysisPath = '/data-tiering/capacity-trend';
  static readonly GetDataTieringTasksPath = '/data-tiering/tasks';
  static readonly CreateDataTieringTaskPath = '/data-tiering/tasks';
  static readonly UpdateDataTieringTasksStatePath = '/data-tiering/tasks/states';
  static readonly GetDataTieringTaskByIdPath = '/data-tiering/tasks/{id}';
  static readonly UpdateDataTieringTaskPath = '/data-tiering/tasks/{id}';
  static readonly DeleteDataTieringTaskPath = '/data-tiering/tasks/{id}';
  static readonly CreateDataTieringTaskRunPath = '/data-tiering/tasks/{id}/runs';
  static readonly CancelDataTieringTaskRunPath = '/data-tiering/tasks/{id}/runs/{runId}/cancel';
  static readonly DownloadTieringReportsPath = '/data-tiering/tasks/{id}/runs/{runId}/download-report';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get the list of data tiering analysis groups.
   *
   * Get list of all data tiering analysis groups.
   * @param params The `DataTieringService.GetDataTieringAnalysisGroupsParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeLastRunStats`: If true, the response will include last run info. If it is false or not specified, the last run info won't be returned.
   *
   * - `ids`: Filter by a list of Analysis Group IDs.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetDataTieringAnalysisGroupsResponse(params: DataTieringService.GetDataTieringAnalysisGroupsParams): __Observable<__StrictHttpResponse<DataTieringAnalysisGroups>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeLastRunStats != null) __params = __params.set('includeLastRunStats', params.includeLastRunStats.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-tiering/analysis-groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DataTieringAnalysisGroups>;
      })
    );
  }
  /**
   * Get the list of data tiering analysis groups.
   *
   * Get list of all data tiering analysis groups.
   * @param params The `DataTieringService.GetDataTieringAnalysisGroupsParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeLastRunStats`: If true, the response will include last run info. If it is false or not specified, the last run info won't be returned.
   *
   * - `ids`: Filter by a list of Analysis Group IDs.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetDataTieringAnalysisGroups(params: DataTieringService.GetDataTieringAnalysisGroupsParams): __Observable<DataTieringAnalysisGroups> {
    return this.GetDataTieringAnalysisGroupsResponse(params).pipe(
      __map(_r => _r.body as DataTieringAnalysisGroups)
    );
  }

  /**
   * Create a data tiering analysis group.
   *
   * Create a data tiering analysis group.
   * @param params The `DataTieringService.CreateDataTieringAnalysisGroupParams` containing the following parameters:
   *
   * - `body`: Specifies the data tiering analysis group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateDataTieringAnalysisGroupResponse(params: DataTieringService.CreateDataTieringAnalysisGroupParams): __Observable<__StrictHttpResponse<DataTieringAnalysisGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-tiering/analysis-groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DataTieringAnalysisGroup>;
      })
    );
  }
  /**
   * Create a data tiering analysis group.
   *
   * Create a data tiering analysis group.
   * @param params The `DataTieringService.CreateDataTieringAnalysisGroupParams` containing the following parameters:
   *
   * - `body`: Specifies the data tiering analysis group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateDataTieringAnalysisGroup(params: DataTieringService.CreateDataTieringAnalysisGroupParams): __Observable<DataTieringAnalysisGroup> {
    return this.CreateDataTieringAnalysisGroupResponse(params).pipe(
      __map(_r => _r.body as DataTieringAnalysisGroup)
    );
  }

  /**
   * Get the default config of data tiering analysis groups.
   *
   * Get default grouping configuration for data tiering
   * analysis groups.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetDataTieringAnalysisGroupsDefaultConfigResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<DataTieringTagConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-tiering/analysis-groups/config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DataTieringTagConfig>;
      })
    );
  }
  /**
   * Get the default config of data tiering analysis groups.
   *
   * Get default grouping configuration for data tiering
   * analysis groups.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetDataTieringAnalysisGroupsDefaultConfig(regionId?: string,
    accessClusterId?: number): __Observable<DataTieringTagConfig> {
    return this.GetDataTieringAnalysisGroupsDefaultConfigResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as DataTieringTagConfig)
    );
  }

  /**
   * Update data tiering analysis groups state.
   *
   * Perform actions like pause or resume on the data tiering analysis
   * groups for the specified sources.
   * @param params The `DataTieringService.UpdateDataTieringAnalysisGroupsStateParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to perform an action of list of data tiering
   *   analysis groups.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateDataTieringAnalysisGroupsStateResponse(params: DataTieringService.UpdateDataTieringAnalysisGroupsStateParams): __Observable<__StrictHttpResponse<UpdateDataTieringState>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-tiering/analysis-groups/states`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateDataTieringState>;
      })
    );
  }
  /**
   * Update data tiering analysis groups state.
   *
   * Perform actions like pause or resume on the data tiering analysis
   * groups for the specified sources.
   * @param params The `DataTieringService.UpdateDataTieringAnalysisGroupsStateParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to perform an action of list of data tiering
   *   analysis groups.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateDataTieringAnalysisGroupsState(params: DataTieringService.UpdateDataTieringAnalysisGroupsStateParams): __Observable<UpdateDataTieringState> {
    return this.UpdateDataTieringAnalysisGroupsStateResponse(params).pipe(
      __map(_r => _r.body as UpdateDataTieringState)
    );
  }

  /**
   * Get data tiering analysis group by id.
   *
   * Get data tiering analysis group by id.
   * @param params The `DataTieringService.GetDataTieringAnalysisGroupByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the data tiering analysis group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetDataTieringAnalysisGroupByIdResponse(params: DataTieringService.GetDataTieringAnalysisGroupByIdParams): __Observable<__StrictHttpResponse<DataTieringAnalysisGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-tiering/analysis-groups/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DataTieringAnalysisGroup>;
      })
    );
  }
  /**
   * Get data tiering analysis group by id.
   *
   * Get data tiering analysis group by id.
   * @param params The `DataTieringService.GetDataTieringAnalysisGroupByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the data tiering analysis group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetDataTieringAnalysisGroupById(params: DataTieringService.GetDataTieringAnalysisGroupByIdParams): __Observable<DataTieringAnalysisGroup> {
    return this.GetDataTieringAnalysisGroupByIdResponse(params).pipe(
      __map(_r => _r.body as DataTieringAnalysisGroup)
    );
  }

  /**
   * Update a data tiering analysis group. Currently, it supports updating
   * sources and schedule only.
   *
   * Update a data tiering analysis group.
   * @param params The `DataTieringService.UpdateDataTieringAnalysisGroupParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the data tiering analysis group.
   *
   * - `body`: Specifies the data tiering analysis group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateDataTieringAnalysisGroupResponse(params: DataTieringService.UpdateDataTieringAnalysisGroupParams): __Observable<__StrictHttpResponse<DataTieringAnalysisGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/data-tiering/analysis-groups/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DataTieringAnalysisGroup>;
      })
    );
  }
  /**
   * Update a data tiering analysis group. Currently, it supports updating
   * sources and schedule only.
   *
   * Update a data tiering analysis group.
   * @param params The `DataTieringService.UpdateDataTieringAnalysisGroupParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the data tiering analysis group.
   *
   * - `body`: Specifies the data tiering analysis group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateDataTieringAnalysisGroup(params: DataTieringService.UpdateDataTieringAnalysisGroupParams): __Observable<DataTieringAnalysisGroup> {
    return this.UpdateDataTieringAnalysisGroupResponse(params).pipe(
      __map(_r => _r.body as DataTieringAnalysisGroup)
    );
  }

  /**
   * Delete data tiering analysis group.
   *
   * Returns NoContentResponse if the data tiering analysis group is
   * deleted.
   * @param params The `DataTieringService.DeleteDataTieringAnalysisGroupParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the data tiering analysis group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteDataTieringAnalysisGroupResponse(params: DataTieringService.DeleteDataTieringAnalysisGroupParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/data-tiering/analysis-groups/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete data tiering analysis group.
   *
   * Returns NoContentResponse if the data tiering analysis group is
   * deleted.
   * @param params The `DataTieringService.DeleteDataTieringAnalysisGroupParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the data tiering analysis group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteDataTieringAnalysisGroup(params: DataTieringService.DeleteDataTieringAnalysisGroupParams): __Observable<null> {
    return this.DeleteDataTieringAnalysisGroupResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update data tiering analysis group config.
   *
   * Update data tiering analysis group config.
   * @param params The `DataTieringService.UpdateDataTieringAnalysisGroupTagsConfigParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the data tiering analysis group.
   *
   * - `body`: Specifies the data tiering analysis Tags Config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateDataTieringAnalysisGroupTagsConfigResponse(params: DataTieringService.UpdateDataTieringAnalysisGroupTagsConfigParams): __Observable<__StrictHttpResponse<DataTieringTagConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/data-tiering/analysis-groups/${encodeURIComponent(params.id)}/config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DataTieringTagConfig>;
      })
    );
  }
  /**
   * Update data tiering analysis group config.
   *
   * Update data tiering analysis group config.
   * @param params The `DataTieringService.UpdateDataTieringAnalysisGroupTagsConfigParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the data tiering analysis group.
   *
   * - `body`: Specifies the data tiering analysis Tags Config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateDataTieringAnalysisGroupTagsConfig(params: DataTieringService.UpdateDataTieringAnalysisGroupTagsConfigParams): __Observable<DataTieringTagConfig> {
    return this.UpdateDataTieringAnalysisGroupTagsConfigResponse(params).pipe(
      __map(_r => _r.body as DataTieringTagConfig)
    );
  }

  /**
   * Get data tiering analysis group runs.
   *
   * Get data tiering analysis group runs for an analysis group.
   * @param params The `DataTieringService.GetDataTieringAnalysisGroupRunsParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the data tiering analysis group.
   *
   * - `runIds`: Filter by a list of analysis group run ids.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetDataTieringAnalysisGroupRunsResponse(params: DataTieringService.GetDataTieringAnalysisGroupRunsParams): __Observable<__StrictHttpResponse<DataTieringAnalysisGroupRuns>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    (params.runIds || []).forEach(val => {if (val != null) __params = __params.append('runIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-tiering/analysis-groups/${encodeURIComponent(params.id)}/runs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DataTieringAnalysisGroupRuns>;
      })
    );
  }
  /**
   * Get data tiering analysis group runs.
   *
   * Get data tiering analysis group runs for an analysis group.
   * @param params The `DataTieringService.GetDataTieringAnalysisGroupRunsParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the data tiering analysis group.
   *
   * - `runIds`: Filter by a list of analysis group run ids.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetDataTieringAnalysisGroupRuns(params: DataTieringService.GetDataTieringAnalysisGroupRunsParams): __Observable<DataTieringAnalysisGroupRuns> {
    return this.GetDataTieringAnalysisGroupRunsResponse(params).pipe(
      __map(_r => _r.body as DataTieringAnalysisGroupRuns)
    );
  }

  /**
   * Create a data tiering analysis group run.
   *
   * Create a data tiering analysis group run.
   * @param params The `DataTieringService.CreateDataTieringAnalysisGroupRunParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the data tiering analysis group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `body`: Specifies the request to run analysis group once.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  CreateDataTieringAnalysisGroupRunResponse(params: DataTieringService.CreateDataTieringAnalysisGroupRunParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-tiering/analysis-groups/${encodeURIComponent(params.id)}/runs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Create a data tiering analysis group run.
   *
   * Create a data tiering analysis group run.
   * @param params The `DataTieringService.CreateDataTieringAnalysisGroupRunParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the data tiering analysis group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `body`: Specifies the request to run analysis group once.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  CreateDataTieringAnalysisGroupRun(params: DataTieringService.CreateDataTieringAnalysisGroupRunParams): __Observable<null> {
    return this.CreateDataTieringAnalysisGroupRunResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Cancel data tiering analysis run.
   *
   * Cancel data tiering analysis run for given analysis group ID
   * and run ID
   * @param params The `DataTieringService.CancelDataTieringAnalysisGroupRunParams` containing the following parameters:
   *
   * - `runId`: Specifies a unique run id of data tiering group run.
   *
   * - `id`: Specifies a unique id of data tiering group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  CancelDataTieringAnalysisGroupRunResponse(params: DataTieringService.CancelDataTieringAnalysisGroupRunParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-tiering/analysis-groups/${encodeURIComponent(params.id)}/runs/${encodeURIComponent(params.runId)}/cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Cancel data tiering analysis run.
   *
   * Cancel data tiering analysis run for given analysis group ID
   * and run ID
   * @param params The `DataTieringService.CancelDataTieringAnalysisGroupRunParams` containing the following parameters:
   *
   * - `runId`: Specifies a unique run id of data tiering group run.
   *
   * - `id`: Specifies a unique id of data tiering group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  CancelDataTieringAnalysisGroupRun(params: DataTieringService.CancelDataTieringAnalysisGroupRunParams): __Observable<null> {
    return this.CancelDataTieringAnalysisGroupRunResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get capacity trend analysis for all sources or a specific source.
   *
   * Get capacity trend analysis for the given time range, and for the given
   * source or set of sources.
   * @param params The `DataTieringService.GetCapacityTrendAnalysisParams` containing the following parameters:
   *
   * - `startTimeUsecs`: Filter by a start time. Specify the start time as a Unix epoch Timestamp (in microseconds).
   *
   * - `sourceId`: Filter by source id. If specified, this will only return the capacity trend analysis of the specific source.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `endTimeUsecs`: Filter by a end time. Specify the end time as a Unix epoch Timestamp (in microseconds).
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetCapacityTrendAnalysisResponse(params: DataTieringService.GetCapacityTrendAnalysisParams): __Observable<__StrictHttpResponse<CapacityTrendAnalysis>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startTimeUsecs != null) __params = __params.set('startTimeUsecs', params.startTimeUsecs.toString());
    if (params.sourceId != null) __params = __params.set('sourceId', params.sourceId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.endTimeUsecs != null) __params = __params.set('endTimeUsecs', params.endTimeUsecs.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-tiering/capacity-trend`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CapacityTrendAnalysis>;
      })
    );
  }
  /**
   * Get capacity trend analysis for all sources or a specific source.
   *
   * Get capacity trend analysis for the given time range, and for the given
   * source or set of sources.
   * @param params The `DataTieringService.GetCapacityTrendAnalysisParams` containing the following parameters:
   *
   * - `startTimeUsecs`: Filter by a start time. Specify the start time as a Unix epoch Timestamp (in microseconds).
   *
   * - `sourceId`: Filter by source id. If specified, this will only return the capacity trend analysis of the specific source.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `endTimeUsecs`: Filter by a end time. Specify the end time as a Unix epoch Timestamp (in microseconds).
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetCapacityTrendAnalysis(params: DataTieringService.GetCapacityTrendAnalysisParams): __Observable<CapacityTrendAnalysis> {
    return this.GetCapacityTrendAnalysisResponse(params).pipe(
      __map(_r => _r.body as CapacityTrendAnalysis)
    );
  }

  /**
   * Get the list of data tiering tasks.
   *
   * Get the list of data tiering tasks.
   * @param params The `DataTieringService.GetDataTieringTasksParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeDowntieredDataLocation`: If true, it will also return a list of downtiered data locations
   *   for downtiered tasks.
   *
   * - `ids`: Filter by a list of data tiering task ids.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetDataTieringTasksResponse(params: DataTieringService.GetDataTieringTasksParams): __Observable<__StrictHttpResponse<DataTieringTasks>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeDowntieredDataLocation != null) __params = __params.set('includeDowntieredDataLocation', params.includeDowntieredDataLocation.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-tiering/tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DataTieringTasks>;
      })
    );
  }
  /**
   * Get the list of data tiering tasks.
   *
   * Get the list of data tiering tasks.
   * @param params The `DataTieringService.GetDataTieringTasksParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeDowntieredDataLocation`: If true, it will also return a list of downtiered data locations
   *   for downtiered tasks.
   *
   * - `ids`: Filter by a list of data tiering task ids.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetDataTieringTasks(params: DataTieringService.GetDataTieringTasksParams): __Observable<DataTieringTasks> {
    return this.GetDataTieringTasksResponse(params).pipe(
      __map(_r => _r.body as DataTieringTasks)
    );
  }

  /**
   * Create a data tiering task.
   *
   * Create a data tiering task.
   * @param params The `DataTieringService.CreateDataTieringTaskParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a data tiering task.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateDataTieringTaskResponse(params: DataTieringService.CreateDataTieringTaskParams): __Observable<__StrictHttpResponse<DataTieringTask>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-tiering/tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DataTieringTask>;
      })
    );
  }
  /**
   * Create a data tiering task.
   *
   * Create a data tiering task.
   * @param params The `DataTieringService.CreateDataTieringTaskParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a data tiering task.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateDataTieringTask(params: DataTieringService.CreateDataTieringTaskParams): __Observable<DataTieringTask> {
    return this.CreateDataTieringTaskResponse(params).pipe(
      __map(_r => _r.body as DataTieringTask)
    );
  }

  /**
   * Update data tiering source analysis tasks state.
   *
   * Perform actions like pause or resume on the data tiering tasks.
   * @param params The `DataTieringService.UpdateDataTieringTasksStateParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to perform an action of list of data tiering
   *   tasks.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateDataTieringTasksStateResponse(params: DataTieringService.UpdateDataTieringTasksStateParams): __Observable<__StrictHttpResponse<UpdateDataTieringState>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-tiering/tasks/states`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateDataTieringState>;
      })
    );
  }
  /**
   * Update data tiering source analysis tasks state.
   *
   * Perform actions like pause or resume on the data tiering tasks.
   * @param params The `DataTieringService.UpdateDataTieringTasksStateParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to perform an action of list of data tiering
   *   tasks.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateDataTieringTasksState(params: DataTieringService.UpdateDataTieringTasksStateParams): __Observable<UpdateDataTieringState> {
    return this.UpdateDataTieringTasksStateResponse(params).pipe(
      __map(_r => _r.body as UpdateDataTieringState)
    );
  }

  /**
   * Get data tiering task by id.
   *
   * Get data tiering task by id.
   * @param params The `DataTieringService.GetDataTieringTaskByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the data tiering task.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetDataTieringTaskByIdResponse(params: DataTieringService.GetDataTieringTaskByIdParams): __Observable<__StrictHttpResponse<DataTieringTask>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-tiering/tasks/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DataTieringTask>;
      })
    );
  }
  /**
   * Get data tiering task by id.
   *
   * Get data tiering task by id.
   * @param params The `DataTieringService.GetDataTieringTaskByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the data tiering task.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetDataTieringTaskById(params: DataTieringService.GetDataTieringTaskByIdParams): __Observable<DataTieringTask> {
    return this.GetDataTieringTaskByIdResponse(params).pipe(
      __map(_r => _r.body as DataTieringTask)
    );
  }

  /**
   * Update a data tiering task.
   *
   * Update a data tiering task.
   * @param params The `DataTieringService.UpdateDataTieringTaskParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the data tiering task.
   *
   * - `body`: Specifies the parameters to update a data tiering task.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateDataTieringTaskResponse(params: DataTieringService.UpdateDataTieringTaskParams): __Observable<__StrictHttpResponse<DataTieringTask>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/data-tiering/tasks/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DataTieringTask>;
      })
    );
  }
  /**
   * Update a data tiering task.
   *
   * Update a data tiering task.
   * @param params The `DataTieringService.UpdateDataTieringTaskParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the data tiering task.
   *
   * - `body`: Specifies the parameters to update a data tiering task.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateDataTieringTask(params: DataTieringService.UpdateDataTieringTaskParams): __Observable<DataTieringTask> {
    return this.UpdateDataTieringTaskResponse(params).pipe(
      __map(_r => _r.body as DataTieringTask)
    );
  }

  /**
   * delete the data tiering task.
   *
   * Returns Success if the data tiering task is deleted.
   * @param params The `DataTieringService.DeleteDataTieringTaskParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the data tiering task.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteDataTieringTaskResponse(params: DataTieringService.DeleteDataTieringTaskParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/data-tiering/tasks/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * delete the data tiering task.
   *
   * Returns Success if the data tiering task is deleted.
   * @param params The `DataTieringService.DeleteDataTieringTaskParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the data tiering task.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteDataTieringTask(params: DataTieringService.DeleteDataTieringTaskParams): __Observable<null> {
    return this.DeleteDataTieringTaskResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Create a data tiering tasks run.
   *
   * Create a data tiering tasks run.
   * @param params The `DataTieringService.CreateDataTieringTaskRunParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the data tiering tasks.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `body`: Specifies the request to run tiering task once.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  CreateDataTieringTaskRunResponse(params: DataTieringService.CreateDataTieringTaskRunParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-tiering/tasks/${encodeURIComponent(params.id)}/runs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Create a data tiering tasks run.
   *
   * Create a data tiering tasks run.
   * @param params The `DataTieringService.CreateDataTieringTaskRunParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the data tiering tasks.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `body`: Specifies the request to run tiering task once.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  CreateDataTieringTaskRun(params: DataTieringService.CreateDataTieringTaskRunParams): __Observable<null> {
    return this.CreateDataTieringTaskRunResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Cancel data tiering task.
   *
   * Cancel data tiering task run for given data tiering task id and run id.
   * @param params The `DataTieringService.CancelDataTieringTaskRunParams` containing the following parameters:
   *
   * - `runId`: Specifies a unique run id of data tiering task.
   *
   * - `id`: Specifies a unique id of data tiering task.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  CancelDataTieringTaskRunResponse(params: DataTieringService.CancelDataTieringTaskRunParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-tiering/tasks/${encodeURIComponent(params.id)}/runs/${encodeURIComponent(params.runId)}/cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Cancel data tiering task.
   *
   * Cancel data tiering task run for given data tiering task id and run id.
   * @param params The `DataTieringService.CancelDataTieringTaskRunParams` containing the following parameters:
   *
   * - `runId`: Specifies a unique run id of data tiering task.
   *
   * - `id`: Specifies a unique id of data tiering task.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  CancelDataTieringTaskRun(params: DataTieringService.CancelDataTieringTaskRunParams): __Observable<null> {
    return this.CancelDataTieringTaskRunResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Download Tiering reports.
   *
   * Specifies the API to download the tiering run report.
   * @param params The `DataTieringService.DownloadTieringReportsParams` containing the following parameters:
   *
   * - `targetViewName`: Specifies the View name from which the tiering job report
   *   file should be read from.
   *
   * - `runId`: Specifies a unique run id of data tiering task.
   *
   * - `id`: Specifies a unique id of data tiering task.
   *
   * - `filePath`: Specifies the file path in the targetView.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DownloadTieringReportsResponse(params: DataTieringService.DownloadTieringReportsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.targetViewName != null) __params = __params.set('targetViewName', params.targetViewName.toString());


    if (params.filePath != null) __params = __params.set('filePath', params.filePath.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-tiering/tasks/${encodeURIComponent(params.id)}/runs/${encodeURIComponent(params.runId)}/download-report`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Download Tiering reports.
   *
   * Specifies the API to download the tiering run report.
   * @param params The `DataTieringService.DownloadTieringReportsParams` containing the following parameters:
   *
   * - `targetViewName`: Specifies the View name from which the tiering job report
   *   file should be read from.
   *
   * - `runId`: Specifies a unique run id of data tiering task.
   *
   * - `id`: Specifies a unique id of data tiering task.
   *
   * - `filePath`: Specifies the file path in the targetView.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DownloadTieringReports(params: DataTieringService.DownloadTieringReportsParams): __Observable<null> {
    return this.DownloadTieringReportsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module DataTieringService {

  /**
   * Parameters for GetDataTieringAnalysisGroups
   */
  export interface GetDataTieringAnalysisGroupsParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * If true, the response will include last run info. If it is false or not specified, the last run info won't be returned.
     */
    includeLastRunStats?: boolean;

    /**
     * Filter by a list of Analysis Group IDs.
     */
    ids?: Array<string>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateDataTieringAnalysisGroup
   */
  export interface CreateDataTieringAnalysisGroupParams {

    /**
     * Specifies the data tiering analysis group.
     */
    body: CommonDataTieringAnalysisGroupParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateDataTieringAnalysisGroupsState
   */
  export interface UpdateDataTieringAnalysisGroupsStateParams {

    /**
     * Specifies the parameters to perform an action of list of data tiering
     * analysis groups.
     */
    body: UpdateDataTieringStateRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetDataTieringAnalysisGroupById
   */
  export interface GetDataTieringAnalysisGroupByIdParams {

    /**
     * Specifies a unique id of the data tiering analysis group.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateDataTieringAnalysisGroup
   */
  export interface UpdateDataTieringAnalysisGroupParams {

    /**
     * Specifies a unique id of the data tiering analysis group.
     */
    id: string;

    /**
     * Specifies the data tiering analysis group.
     */
    body: CommonDataTieringAnalysisGroupParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteDataTieringAnalysisGroup
   */
  export interface DeleteDataTieringAnalysisGroupParams {

    /**
     * Specifies a unique id of the data tiering analysis group.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateDataTieringAnalysisGroupTagsConfig
   */
  export interface UpdateDataTieringAnalysisGroupTagsConfigParams {

    /**
     * Specifies a unique id of the data tiering analysis group.
     */
    id: string;

    /**
     * Specifies the data tiering analysis Tags Config.
     */
    body: DataTieringTagConfig;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetDataTieringAnalysisGroupRuns
   */
  export interface GetDataTieringAnalysisGroupRunsParams {

    /**
     * Specifies a unique id of the data tiering analysis group.
     */
    id: string;

    /**
     * Filter by a list of analysis group run ids.
     */
    runIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateDataTieringAnalysisGroupRun
   */
  export interface CreateDataTieringAnalysisGroupRunParams {

    /**
     * Specifies the id of the data tiering analysis group.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the request to run analysis group once.
     */
    body?: DataTieringAnalysisRunRequest;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CancelDataTieringAnalysisGroupRun
   */
  export interface CancelDataTieringAnalysisGroupRunParams {

    /**
     * Specifies a unique run id of data tiering group run.
     */
    runId: string;

    /**
     * Specifies a unique id of data tiering group.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetCapacityTrendAnalysis
   */
  export interface GetCapacityTrendAnalysisParams {

    /**
     * Filter by a start time. Specify the start time as a Unix epoch Timestamp (in microseconds).
     */
    startTimeUsecs?: number;

    /**
     * Filter by source id. If specified, this will only return the capacity trend analysis of the specific source.
     */
    sourceId?: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Filter by a end time. Specify the end time as a Unix epoch Timestamp (in microseconds).
     */
    endTimeUsecs?: number;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetDataTieringTasks
   */
  export interface GetDataTieringTasksParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * If true, it will also return a list of downtiered data locations
     * for downtiered tasks.
     */
    includeDowntieredDataLocation?: boolean;

    /**
     * Filter by a list of data tiering task ids.
     */
    ids?: Array<string>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateDataTieringTask
   */
  export interface CreateDataTieringTaskParams {

    /**
     * Specifies the parameters to create a data tiering task.
     */
    body: CreateOrUpdateDataTieringTaskRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateDataTieringTasksState
   */
  export interface UpdateDataTieringTasksStateParams {

    /**
     * Specifies the parameters to perform an action of list of data tiering
     * tasks.
     */
    body: UpdateDataTieringStateRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetDataTieringTaskById
   */
  export interface GetDataTieringTaskByIdParams {

    /**
     * Specifies the id of the data tiering task.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateDataTieringTask
   */
  export interface UpdateDataTieringTaskParams {

    /**
     * Specifies the id of the data tiering task.
     */
    id: string;

    /**
     * Specifies the parameters to update a data tiering task.
     */
    body: CreateOrUpdateDataTieringTaskRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteDataTieringTask
   */
  export interface DeleteDataTieringTaskParams {

    /**
     * Specifies the id of the data tiering task.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateDataTieringTaskRun
   */
  export interface CreateDataTieringTaskRunParams {

    /**
     * Specifies the id of the data tiering tasks.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the request to run tiering task once.
     */
    body?: DataTieringTaskRunRequest;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CancelDataTieringTaskRun
   */
  export interface CancelDataTieringTaskRunParams {

    /**
     * Specifies a unique run id of data tiering task.
     */
    runId: string;

    /**
     * Specifies a unique id of data tiering task.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DownloadTieringReports
   */
  export interface DownloadTieringReportsParams {

    /**
     * Specifies the View name from which the tiering job report
     * file should be read from.
     */
    targetViewName: string;

    /**
     * Specifies a unique run id of data tiering task.
     */
    runId: string;

    /**
     * Specifies a unique id of data tiering task.
     */
    id: string;

    /**
     * Specifies the file path in the targetView.
     */
    filePath: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { DataTieringService }
