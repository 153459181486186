import { Injectable } from '@angular/core';
import { IrisContextService, isMcmSaaS, isRpaasUser } from '@cohesity/iris-core';
import { iif, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { RpaasQouromOperation, RpaasQuorumService } from 'src/app/app-services/rpaas/shared';
import { CreateRecoveryForm, Recovery } from 'src/app/modules/restore/restore-shared';

@Injectable({
  providedIn: 'root'
})
export class RpaasRecoveryUtilsService {
  constructor(
    private irisContextService: IrisContextService,
    private rpaasQuorumService: RpaasQuorumService,
  ) { }

  /**
   * Get whether the recovery form is an rpaas recovery.
   *
   * @param formValue The form value.
   * @return True, if rpaas recovery.
   */
  isRpaasRecoveryForm(formValue: CreateRecoveryForm<any>): boolean {
    return formValue.objects?.some(
      // archiveTargetInfo is guaranteed to be set, but object.snapshot might not be.
      object => object?.archiveTargetInfo?.ownershipContext === 'FortKnox'
    );
  }

  /**
   * Get whether the recovery class instance is an rpaas recovery.
   *
   * @param recovery The recovery class instance.
   * @return True, if rpaas recovery.
   */
  isRpaasRecoveryInstance(recovery: Recovery): boolean {
    return recovery?.objects?.some(object =>
      object?.archivalTargetInfo?.ownershipContext === 'FortKnox'
    );
  }

  /**
   * Whether to check for existence of quorum groups before
   * creating the recovery task.
   *
   * @param formValue The form value.
   * @return True, if to check for quorum groups.
   */
  checkForQuorumGroups(formValue: CreateRecoveryForm<any>): boolean {
    return this.isRpaasRecoveryForm(formValue)
      && isRpaasUser(this.irisContextService.irisContext);
  }

  /**
   * Whether to disable recovery resubmit action.
   *
   * @param recovery The recovery class instance.
   * @return True, if to disable recovery resubmit action.
   */
  disableRecoveryResubmit(recovery: Recovery): boolean {
    // Do not allow resubmitting the recovery task on OnPrem.
    return this.isRpaasRecoveryInstance(recovery)
      && !isMcmSaaS(this.irisContextService.irisContext);
  }

  /**
   * Whether quorum groups are needed for a recovery form.
   *
   * @param formValue The form value.
   * @return True, if quorum groups are needed.
   */
  quorumGroupsNeeded$(formValue: CreateRecoveryForm<any>): Observable<boolean> {
    return iif(
      () => this.checkForQuorumGroups(formValue),

      // For rpaas recovery, at least one quorum group is needed.
      this.rpaasQuorumService.hasRpaasQuorumOperations([RpaasQouromOperation.recover]).pipe(
        take(1),
        map(hasGroup => !hasGroup)
      ),

      // No quorum group needed for non rpaas recoveries.
      of(false),
    );
  }
}
