import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

/**
 * An enumeration of possible values for snapshot deletion.
 */
export enum SnapshotDeletion {
  Local = 'local',
  All = 'all',
}

/**
 * Confirmation dialog window when deleting Protection Group Run.
 */
@Component({
  selector: 'coh-protection-run-delete-dialog',
  templateUrl: './protection-run-delete-dialog.component.html',
  styleUrls: ['./protection-run-delete-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProtectionRunDeleteDialogComponent {

  /**
   * Form action values.
   */
  runDeleteForm: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: {},
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ProtectionRunDeleteDialogComponent>,
  ) {
    this.runDeleteForm = this.fb.group({
      /**
       * Specifies whether to only allow deletion of all snapshots. If set to false,
       * only local snapshots are deleted. If true, in addition to local snapshots,
       * replication and archival snapshots are deleted as well.
       */
      snapshotDeletion: this.fb.control(SnapshotDeletion.Local, [Validators.required]),
    });
  }

  /**
   * Returns the snapshot deletion value.
   */
  get snapshotDeletion() {
    return this.runDeleteForm.get('snapshotDeletion').value;
  }

  /**
   * Returns true if the delete local snapshots radio button is selected.
   */
  get isDeletionLocal() {
    return this.snapshotDeletion === SnapshotDeletion.Local;
  }

  /**
   * Returns true if the delete all snapshots radio button is selected.
   */
  get isDeletionAll() {
    return this.snapshotDeletion === SnapshotDeletion.All;
  }

  /**
   * Gets the delete action wanted by the user
   *
   * @returns SnapshotDeletion
   */
  onDelete() {
    if (this.runDeleteForm.invalid) {
      return;
    }
    this.dialogRef.close(this.snapshotDeletion);
  }
}
