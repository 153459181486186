import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ScanHealthStatusIconPipe } from './scan-health-status-icon.pipe';

@NgModule({
  imports: [TranslateModule.forChild()],
  providers: [ScanHealthStatusIconPipe],
  exports: [ScanHealthStatusIconPipe],
  declarations: [ScanHealthStatusIconPipe],
})
export class ScanHealthStatusIconModule {}
