/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Sources } from '../models/sources';
import { SourceAttributeFiltersResponseParams } from '../models/source-attribute-filters-response-params';
import { GenerateM365DeviceCodeResponseParams } from '../models/generate-m365device-code-response-params';
import { GenerateM365DeviceCodeRequestParams } from '../models/generate-m365device-code-request-params';
import { GenerateM365DeviceAccessTokenResponseParams } from '../models/generate-m365device-access-token-response-params';
import { GenerateM365DeviceAccessTokenRequestParams } from '../models/generate-m365device-access-token-request-params';
import { CreateAzureApplicationResponseParams } from '../models/create-azure-application-response-params';
import { CreateAzureApplicationRequestParams } from '../models/create-azure-application-request-params';
import { GetM365BackupControllerResponseParams } from '../models/get-m365backup-controller-response-params';
import { M365BackupControllerBillingResponseParams } from '../models/m365backup-controller-billing-response-params';
import { GetM365SourceRegionEndpointResponseParams } from '../models/get-m365source-region-endpoint-response-params';
import { GetM365SelfServiceConfigResponse } from '../models/get-m365self-service-config-response';
import { CreateM365SelfServiceConfigRequestParams } from '../models/create-m365self-service-config-request-params';
import { SourceRegistrations } from '../models/source-registrations';
import { SourceRegistration } from '../models/source-registration';
import { SourceRegistrationRequestParams } from '../models/source-registration-request-params';
import { SourceRegistrationUpdateRequestParams } from '../models/source-registration-update-request-params';
import { SourceRegistrationPatchRequestParams } from '../models/source-registration-patch-request-params';
import { SourceConnectionResponseParams } from '../models/source-connection-response-params';
import { SourceConnectionRequestParams } from '../models/source-connection-request-params';
import { VdcObject } from '../models/vdc-object';
import { Source } from '../models/source';
import { McmSources } from '../models/mcm-sources';
import { McmSourceRegistration } from '../models/mcm-source-registration';
import { McmSourceRegistrationRequestParams } from '../models/mcm-source-registration-request-params';
import { McmSourceRegistrationUpdateRequestParams } from '../models/mcm-source-registration-update-request-params';
import { McmTenantSourceRegistrations } from '../models/mcm-tenant-source-registrations';
import { McmTenantSourceRegistrationsParams } from '../models/mcm-tenant-source-registrations-params';
@Injectable({
  providedIn: 'root',
})
class SourceService extends __BaseService {
  static readonly GetProtectionSourcesPath = '/data-protect/sources';
  static readonly GetSourceAttributeFiltersPath = '/data-protect/sources/filters';
  static readonly GenerateM365DeviceCodePath = '/data-protect/sources/microsoft365/auth/device-code';
  static readonly GenerateM365DeviceAccessTokenPath = '/data-protect/sources/microsoft365/auth/token';
  static readonly CreateOrUpdateAzureApplicationsPath = '/data-protect/sources/microsoft365/azure-applications';
  static readonly CreateAzureApplicationsPath = '/data-protect/sources/microsoft365/azure-applications';
  static readonly GetM365BackupControllerPath = '/data-protect/sources/microsoft365/backup-controllers';
  static readonly RegisterM365BackupControllerPath = '/data-protect/sources/microsoft365/backup-controllers';
  static readonly EnableMBSBillingProfilePath = '/data-protect/sources/microsoft365/backup-controllers/billing';
  static readonly UnregisterM365BackupControllerPath = '/data-protect/sources/microsoft365/backup-controllers/{id}';
  static readonly UpdateM365BackupControllerPath = '/data-protect/sources/microsoft365/backup-controllers/{id}';
  static readonly GetM365SourceRegionEndpointPath = '/data-protect/sources/microsoft365/region-info';
  static readonly GetMicrosoft365SelfServiceConfigPath = '/data-protect/sources/microsoft365/self-service-config';
  static readonly UpdateM365SelfServiceConfigPath = '/data-protect/sources/microsoft365/self-service-config/{uuid}';
  static readonly DeleteM365SelfServiceConfigPath = '/data-protect/sources/microsoft365/self-service-config/{uuid}';
  static readonly GetSourceRegistrationsPath = '/data-protect/sources/registrations';
  static readonly RegisterProtectionSourcePath = '/data-protect/sources/registrations';
  static readonly GetProtectionSourceRegistrationPath = '/data-protect/sources/registrations/{id}';
  static readonly UpdateProtectionSourceRegistrationPath = '/data-protect/sources/registrations/{id}';
  static readonly DeleteProtectionSourceRegistrationPath = '/data-protect/sources/registrations/{id}';
  static readonly PatchProtectionSourceRegistrationPath = '/data-protect/sources/registrations/{id}';
  static readonly TestConnectionProtectionSourcePath = '/data-protect/sources/test-connection';
  static readonly GetVdcDetailsPath = '/data-protect/sources/virtual-datacenter/{id}';
  static readonly ProtectionSourceByIdPath = '/data-protect/sources/{id}';
  static readonly RefreshProtectionSourceByIdPath = '/data-protect/sources/{id}/refresh';
  static readonly McmGetProtectionSourcesPath = '/mcm/data-protect/sources';
  static readonly McmRegisterProtectionSourcePath = '/mcm/data-protect/sources/registrations';
  static readonly McmGetProtectionSourceRegistrationPath = '/mcm/data-protect/sources/registrations/{id}';
  static readonly UpdateProtectionSourceRegistrationMixin1Path = '/mcm/data-protect/sources/registrations/{id}';
  static readonly McmDeleteProtectionSourceRegistrationPath = '/mcm/data-protect/sources/registrations/{id}';
  static readonly McmTestSourceConnectionPath = '/mcm/data-protect/sources/test-connection';
  static readonly McmGetTenantSourceRegistrationsPath = '/mcm/tenants/source-registrations';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a List of Protection Sources.
   *
   * Get a List of Protection Sources.
   * @param params The `SourceService.GetProtectionSourcesParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which Sources are to be returned.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: If true, the response will include Sources which belong belong to all tenants which the current user has permission to see. If false, then only Sources for the current user will be returned.
   *
   * - `includeSourceCredentials`: If true, the encrypted crednetial for the registered sources will be included. Credential is first encrypted with internal key and then reencrypted with user supplied encryption key.
   *
   * - `encryptionKey`: Specifies the key to be used to encrypt the source credential. If includeSourceCredentials is set to true this key must be specified.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionSourcesResponse(params: SourceService.GetProtectionSourcesParams): __Observable<__StrictHttpResponse<Sources>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.requestInitiatorType != null) __headers = __headers.set('requestInitiatorType', params.requestInitiatorType.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.includeSourceCredentials != null) __params = __params.set('includeSourceCredentials', params.includeSourceCredentials.toString());
    if (params.encryptionKey != null) __params = __params.set('encryptionKey', params.encryptionKey.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/sources`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Sources>;
      })
    );
  }
  /**
   * Get a List of Protection Sources.
   *
   * Get a List of Protection Sources.
   * @param params The `SourceService.GetProtectionSourcesParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which Sources are to be returned.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: If true, the response will include Sources which belong belong to all tenants which the current user has permission to see. If false, then only Sources for the current user will be returned.
   *
   * - `includeSourceCredentials`: If true, the encrypted crednetial for the registered sources will be included. Credential is first encrypted with internal key and then reencrypted with user supplied encryption key.
   *
   * - `encryptionKey`: Specifies the key to be used to encrypt the source credential. If includeSourceCredentials is set to true this key must be specified.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionSources(params: SourceService.GetProtectionSourcesParams): __Observable<Sources> {
    return this.GetProtectionSourcesResponse(params).pipe(
      __map(_r => _r.body as Sources)
    );
  }

  /**
   * List attribute filters for a source.
   *
   * Get a List of attribute filters for leaf entities within a a source
   * @param params The `SourceService.GetSourceAttributeFiltersParams` containing the following parameters:
   *
   * - `sourceUuid`: Specifies the source UUID of the parent entity.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `environment`: Specifies the environment type of the Protection Source.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetSourceAttributeFiltersResponse(params: SourceService.GetSourceAttributeFiltersParams): __Observable<__StrictHttpResponse<SourceAttributeFiltersResponseParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sourceUuid != null) __params = __params.set('sourceUuid', params.sourceUuid.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.environment != null) __params = __params.set('environment', params.environment.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/sources/filters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SourceAttributeFiltersResponseParams>;
      })
    );
  }
  /**
   * List attribute filters for a source.
   *
   * Get a List of attribute filters for leaf entities within a a source
   * @param params The `SourceService.GetSourceAttributeFiltersParams` containing the following parameters:
   *
   * - `sourceUuid`: Specifies the source UUID of the parent entity.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `environment`: Specifies the environment type of the Protection Source.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetSourceAttributeFilters(params: SourceService.GetSourceAttributeFiltersParams): __Observable<SourceAttributeFiltersResponseParams> {
    return this.GetSourceAttributeFiltersResponse(params).pipe(
      __map(_r => _r.body as SourceAttributeFiltersResponseParams)
    );
  }

  /**
   * Generate device code for Microsoft365 Device Authorization Grant flow.
   *
   * Generates User and Device code for Microsoft365 Device Authorization Grant for a given domain.
   * @param params The `SourceService.GenerateM365DeviceCodeParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to generate the user and device code to initiate authentication with Microsoft365.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GenerateM365DeviceCodeResponse(params: SourceService.GenerateM365DeviceCodeParams): __Observable<__StrictHttpResponse<GenerateM365DeviceCodeResponseParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/sources/microsoft365/auth/device-code`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GenerateM365DeviceCodeResponseParams>;
      })
    );
  }
  /**
   * Generate device code for Microsoft365 Device Authorization Grant flow.
   *
   * Generates User and Device code for Microsoft365 Device Authorization Grant for a given domain.
   * @param params The `SourceService.GenerateM365DeviceCodeParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to generate the user and device code to initiate authentication with Microsoft365.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GenerateM365DeviceCode(params: SourceService.GenerateM365DeviceCodeParams): __Observable<GenerateM365DeviceCodeResponseParams> {
    return this.GenerateM365DeviceCodeResponse(params).pipe(
      __map(_r => _r.body as GenerateM365DeviceCodeResponseParams)
    );
  }

  /**
   * Generate access token for Microsoft365 Device Authorization Grant flow.
   *
   * Generates the access token if the device code has been granted authorization as part of device login flow.
   * @param params The `SourceService.GenerateM365DeviceAccessTokenParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to validate and generate access token for authorizing the client within Microsoft365.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GenerateM365DeviceAccessTokenResponse(params: SourceService.GenerateM365DeviceAccessTokenParams): __Observable<__StrictHttpResponse<GenerateM365DeviceAccessTokenResponseParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/sources/microsoft365/auth/token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GenerateM365DeviceAccessTokenResponseParams>;
      })
    );
  }
  /**
   * Generate access token for Microsoft365 Device Authorization Grant flow.
   *
   * Generates the access token if the device code has been granted authorization as part of device login flow.
   * @param params The `SourceService.GenerateM365DeviceAccessTokenParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to validate and generate access token for authorizing the client within Microsoft365.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GenerateM365DeviceAccessToken(params: SourceService.GenerateM365DeviceAccessTokenParams): __Observable<GenerateM365DeviceAccessTokenResponseParams> {
    return this.GenerateM365DeviceAccessTokenResponse(params).pipe(
      __map(_r => _r.body as GenerateM365DeviceAccessTokenResponseParams)
    );
  }

  /**
   * Create/Update Microsoft 365 Azure Applications for a given domain.
   *
   * Creates/Updates Microsoft 365 Azure Applications
   * @param params The `SourceService.CreateOrUpdateAzureApplicationsParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create/update Azure applications within a given Microsoft365 source.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateOrUpdateAzureApplicationsResponse(params: SourceService.CreateOrUpdateAzureApplicationsParams): __Observable<__StrictHttpResponse<CreateAzureApplicationResponseParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/data-protect/sources/microsoft365/azure-applications`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateAzureApplicationResponseParams>;
      })
    );
  }
  /**
   * Create/Update Microsoft 365 Azure Applications for a given domain.
   *
   * Creates/Updates Microsoft 365 Azure Applications
   * @param params The `SourceService.CreateOrUpdateAzureApplicationsParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create/update Azure applications within a given Microsoft365 source.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateOrUpdateAzureApplications(params: SourceService.CreateOrUpdateAzureApplicationsParams): __Observable<CreateAzureApplicationResponseParams> {
    return this.CreateOrUpdateAzureApplicationsResponse(params).pipe(
      __map(_r => _r.body as CreateAzureApplicationResponseParams)
    );
  }

  /**
   * Create Microsoft 365 Azure Applications for a given domain.
   *
   * Creates Microsoft 365 Azure Applications
   * @param params The `SourceService.CreateAzureApplicationsParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create Azure applications within a given Microsoft365 source.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateAzureApplicationsResponse(params: SourceService.CreateAzureApplicationsParams): __Observable<__StrictHttpResponse<CreateAzureApplicationResponseParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/sources/microsoft365/azure-applications`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateAzureApplicationResponseParams>;
      })
    );
  }
  /**
   * Create Microsoft 365 Azure Applications for a given domain.
   *
   * Creates Microsoft 365 Azure Applications
   * @param params The `SourceService.CreateAzureApplicationsParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create Azure applications within a given Microsoft365 source.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateAzureApplications(params: SourceService.CreateAzureApplicationsParams): __Observable<CreateAzureApplicationResponseParams> {
    return this.CreateAzureApplicationsResponse(params).pipe(
      __map(_r => _r.body as CreateAzureApplicationResponseParams)
    );
  }

  /**
   * Fetches the Microsoft 365 registered Backup Controller by the Cohesity App for the owner tenant
   *
   * Fetches the registered Backup Controller by the Cohesity App for the tenant id within the JWT specified within the header.
   * @param params The `SourceService.GetM365BackupControllerParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `azureToken`: Specifies the JWT obtained through user with the scope as BackupRestore-Control.ReadWrite.All
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetM365BackupControllerResponse(params: SourceService.GetM365BackupControllerParams): __Observable<__StrictHttpResponse<GetM365BackupControllerResponseParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.azureToken != null) __headers = __headers.set('azureToken', params.azureToken.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/sources/microsoft365/backup-controllers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetM365BackupControllerResponseParams>;
      })
    );
  }
  /**
   * Fetches the Microsoft 365 registered Backup Controller by the Cohesity App for the owner tenant
   *
   * Fetches the registered Backup Controller by the Cohesity App for the tenant id within the JWT specified within the header.
   * @param params The `SourceService.GetM365BackupControllerParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `azureToken`: Specifies the JWT obtained through user with the scope as BackupRestore-Control.ReadWrite.All
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetM365BackupController(params: SourceService.GetM365BackupControllerParams): __Observable<GetM365BackupControllerResponseParams> {
    return this.GetM365BackupControllerResponse(params).pipe(
      __map(_r => _r.body as GetM365BackupControllerResponseParams)
    );
  }

  /**
   * Registers the Cohesity App to be the Microsoft 365 Backup Controller
   *
   * Registers the Cohesity App to be the Microsoft365 Backup Controller
   * @param params The `SourceService.RegisterM365BackupControllerParams` containing the following parameters:
   *
   * - `azureToken`: Specifies the JWT obtained through user with the scope for BackupRestore-Control.ReadWrite.All
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RegisterM365BackupControllerResponse(params: SourceService.RegisterM365BackupControllerParams): __Observable<__StrictHttpResponse<GetM365BackupControllerResponseParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.azureToken != null) __headers = __headers.set('azureToken', params.azureToken.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/sources/microsoft365/backup-controllers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetM365BackupControllerResponseParams>;
      })
    );
  }
  /**
   * Registers the Cohesity App to be the Microsoft 365 Backup Controller
   *
   * Registers the Cohesity App to be the Microsoft365 Backup Controller
   * @param params The `SourceService.RegisterM365BackupControllerParams` containing the following parameters:
   *
   * - `azureToken`: Specifies the JWT obtained through user with the scope for BackupRestore-Control.ReadWrite.All
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RegisterM365BackupController(params: SourceService.RegisterM365BackupControllerParams): __Observable<GetM365BackupControllerResponseParams> {
    return this.RegisterM365BackupControllerResponse(params).pipe(
      __map(_r => _r.body as GetM365BackupControllerResponseParams)
    );
  }

  /**
   * Enables billing profile for the MBS service for the tenant.
   *
   * Enables the M365 Backup Storage(MBS) service for the tenant.
   * @param params The `SourceService.EnableMBSBillingProfileParams` containing the following parameters:
   *
   * - `azureToken`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  EnableMBSBillingProfileResponse(params: SourceService.EnableMBSBillingProfileParams): __Observable<__StrictHttpResponse<M365BackupControllerBillingResponseParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.azureToken != null) __headers = __headers.set('azureToken', params.azureToken.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/sources/microsoft365/backup-controllers/billing`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<M365BackupControllerBillingResponseParams>;
      })
    );
  }
  /**
   * Enables billing profile for the MBS service for the tenant.
   *
   * Enables the M365 Backup Storage(MBS) service for the tenant.
   * @param params The `SourceService.EnableMBSBillingProfileParams` containing the following parameters:
   *
   * - `azureToken`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  EnableMBSBillingProfile(params: SourceService.EnableMBSBillingProfileParams): __Observable<M365BackupControllerBillingResponseParams> {
    return this.EnableMBSBillingProfileResponse(params).pipe(
      __map(_r => _r.body as M365BackupControllerBillingResponseParams)
    );
  }

  /**
   * Unregisters the Cohesity App as the Microsoft 365 Backup Controller
   *
   * Unregisters the Cohesity App as the Microsoft 365 Backup Controller
   * @param params The `SourceService.UnregisterM365BackupControllerParams` containing the following parameters:
   *
   * - `id`: Specifies the Service App ID for the registered M365 Backup Controller when Cohesity App is the active Controller.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `azureToken`: Specifies the JWT obtained through user with the scope as BackupRestore-Control.ReadWrite.All
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  UnregisterM365BackupControllerResponse(params: SourceService.UnregisterM365BackupControllerParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.azureToken != null) __headers = __headers.set('azureToken', params.azureToken.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/data-protect/sources/microsoft365/backup-controllers/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Unregisters the Cohesity App as the Microsoft 365 Backup Controller
   *
   * Unregisters the Cohesity App as the Microsoft 365 Backup Controller
   * @param params The `SourceService.UnregisterM365BackupControllerParams` containing the following parameters:
   *
   * - `id`: Specifies the Service App ID for the registered M365 Backup Controller when Cohesity App is the active Controller.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `azureToken`: Specifies the JWT obtained through user with the scope as BackupRestore-Control.ReadWrite.All
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  UnregisterM365BackupController(params: SourceService.UnregisterM365BackupControllerParams): __Observable<null> {
    return this.UnregisterM365BackupControllerResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Updates the status of the registered M365 Backup Controller
   *
   * Updates the Backup Controller status of the registered M365 Backup Controller
   * @param params The `SourceService.UpdateM365BackupControllerParams` containing the following parameters:
   *
   * - `id`: Specifies the Service App ID for the registered M365 Backup Controller when Cohesity App is the active Controller.
   *
   * - `azureToken`: Specifies the JWT obtained through user with the scope as BackupRestore-Control.ReadWrite.All
   *
   * - `state`: Specifies the state of the Backup Controller. The state parameter can only be either set to Active/Inactive within the request. The other states like PendingInactive & PendingActive are not applicable.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateM365BackupControllerResponse(params: SourceService.UpdateM365BackupControllerParams): __Observable<__StrictHttpResponse<GetM365BackupControllerResponseParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.azureToken != null) __headers = __headers.set('azureToken', params.azureToken.toString());
    if (params.state != null) __params = __params.set('state', params.state.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/data-protect/sources/microsoft365/backup-controllers/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetM365BackupControllerResponseParams>;
      })
    );
  }
  /**
   * Updates the status of the registered M365 Backup Controller
   *
   * Updates the Backup Controller status of the registered M365 Backup Controller
   * @param params The `SourceService.UpdateM365BackupControllerParams` containing the following parameters:
   *
   * - `id`: Specifies the Service App ID for the registered M365 Backup Controller when Cohesity App is the active Controller.
   *
   * - `azureToken`: Specifies the JWT obtained through user with the scope as BackupRestore-Control.ReadWrite.All
   *
   * - `state`: Specifies the state of the Backup Controller. The state parameter can only be either set to Active/Inactive within the request. The other states like PendingInactive & PendingActive are not applicable.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateM365BackupController(params: SourceService.UpdateM365BackupControllerParams): __Observable<GetM365BackupControllerResponseParams> {
    return this.UpdateM365BackupControllerResponse(params).pipe(
      __map(_r => _r.body as GetM365BackupControllerResponseParams)
    );
  }

  /**
   * Generates the region and endpoint for the Microsoft365 source.
   *
   * Fetches the region and endpoint for the Microsoft365 source.
   * @param params The `SourceService.GetM365SourceRegionEndpointParams` containing the following parameters:
   *
   * - `domain`: Specifies the domain name of the source.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetM365SourceRegionEndpointResponse(params: SourceService.GetM365SourceRegionEndpointParams): __Observable<__StrictHttpResponse<GetM365SourceRegionEndpointResponseParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.domain != null) __params = __params.set('domain', params.domain.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/sources/microsoft365/region-info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetM365SourceRegionEndpointResponseParams>;
      })
    );
  }
  /**
   * Generates the region and endpoint for the Microsoft365 source.
   *
   * Fetches the region and endpoint for the Microsoft365 source.
   * @param params The `SourceService.GetM365SourceRegionEndpointParams` containing the following parameters:
   *
   * - `domain`: Specifies the domain name of the source.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetM365SourceRegionEndpoint(params: SourceService.GetM365SourceRegionEndpointParams): __Observable<GetM365SourceRegionEndpointResponseParams> {
    return this.GetM365SourceRegionEndpointResponse(params).pipe(
      __map(_r => _r.body as GetM365SourceRegionEndpointResponseParams)
    );
  }

  /**
   * Get the list of Microsoft365 Self-Service configurations
   *
   * Get the list of Self-Service configurations for all Microsoft365 sources for the given tenant ID.
   * @param params The `SourceService.GetMicrosoft365SelfServiceConfigParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the Cohesity Tenant ID for the source owner.
   *
   * - `workloadType`: Specifies the workload type as filter for fetching Self-Service configuration types.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetMicrosoft365SelfServiceConfigResponse(params: SourceService.GetMicrosoft365SelfServiceConfigParams): __Observable<__StrictHttpResponse<GetM365SelfServiceConfigResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.workloadType != null) __params = __params.set('workloadType', params.workloadType.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/sources/microsoft365/self-service-config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetM365SelfServiceConfigResponse>;
      })
    );
  }
  /**
   * Get the list of Microsoft365 Self-Service configurations
   *
   * Get the list of Self-Service configurations for all Microsoft365 sources for the given tenant ID.
   * @param params The `SourceService.GetMicrosoft365SelfServiceConfigParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the Cohesity Tenant ID for the source owner.
   *
   * - `workloadType`: Specifies the workload type as filter for fetching Self-Service configuration types.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetMicrosoft365SelfServiceConfig(params: SourceService.GetMicrosoft365SelfServiceConfigParams): __Observable<GetM365SelfServiceConfigResponse> {
    return this.GetMicrosoft365SelfServiceConfigResponse(params).pipe(
      __map(_r => _r.body as GetM365SelfServiceConfigResponse)
    );
  }

  /**
   * Create or Update the Self-Service configuration for a Microsoft365 source.
   *
   * Create or Update the configuration for enabling Self-Service for a Microsoft365 source through Security Groups. The configuration can be done for Mailbox & OneDrive workload only.
   * @param params The `SourceService.UpdateM365SelfServiceConfigParams` containing the following parameters:
   *
   * - `uuid`: Specifies the UUID of the Microsoft365 Source.
   *
   * - `body`: Specifies the parameters to enable Self-Service for a Microsoft365 source. This configuration will apply to all regions incase the same source is registered across regions.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success or Success
   */
  UpdateM365SelfServiceConfigResponse(params: SourceService.UpdateM365SelfServiceConfigParams): __Observable<__StrictHttpResponse<CreateM365SelfServiceConfigRequestParams | CreateM365SelfServiceConfigRequestParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/data-protect/sources/microsoft365/self-service-config/${encodeURIComponent(params.uuid)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateM365SelfServiceConfigRequestParams | CreateM365SelfServiceConfigRequestParams>;
      })
    );
  }
  /**
   * Create or Update the Self-Service configuration for a Microsoft365 source.
   *
   * Create or Update the configuration for enabling Self-Service for a Microsoft365 source through Security Groups. The configuration can be done for Mailbox & OneDrive workload only.
   * @param params The `SourceService.UpdateM365SelfServiceConfigParams` containing the following parameters:
   *
   * - `uuid`: Specifies the UUID of the Microsoft365 Source.
   *
   * - `body`: Specifies the parameters to enable Self-Service for a Microsoft365 source. This configuration will apply to all regions incase the same source is registered across regions.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success or Success
   */
  UpdateM365SelfServiceConfig(params: SourceService.UpdateM365SelfServiceConfigParams): __Observable<CreateM365SelfServiceConfigRequestParams | CreateM365SelfServiceConfigRequestParams> {
    return this.UpdateM365SelfServiceConfigResponse(params).pipe(
      __map(_r => _r.body as CreateM365SelfServiceConfigRequestParams | CreateM365SelfServiceConfigRequestParams)
    );
  }

  /**
   * Deletes the Self-Service configuration for a Microsoft365 source.
   *
   * Delete the configuration for Self-Service for a Microsoft365 source. This includes deletion of both Mailbox & OneDrive workload configuration.
   * @param params The `SourceService.DeleteM365SelfServiceConfigParams` containing the following parameters:
   *
   * - `uuid`: Specifies the UUID of the Microsoft365 Source.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteM365SelfServiceConfigResponse(params: SourceService.DeleteM365SelfServiceConfigParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/data-protect/sources/microsoft365/self-service-config/${encodeURIComponent(params.uuid)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes the Self-Service configuration for a Microsoft365 source.
   *
   * Delete the configuration for Self-Service for a Microsoft365 source. This includes deletion of both Mailbox & OneDrive workload configuration.
   * @param params The `SourceService.DeleteM365SelfServiceConfigParams` containing the following parameters:
   *
   * - `uuid`: Specifies the UUID of the Microsoft365 Source.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteM365SelfServiceConfig(params: SourceService.DeleteM365SelfServiceConfigParams): __Observable<null> {
    return this.DeleteM365SelfServiceConfigResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get the list of Protection Source registrations.
   *
   * Get the list of Protection Source registrations.
   * @param params The `SourceService.GetSourceRegistrationsParams` containing the following parameters:
   *
   * - `useCachedData`: Specifies whether we can serve the GET request from the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: If true, the response will include Registrations which were created by all tenants which the current user has permission to see. If false, then only Registrations created by the current user will be returned.
   *
   * - `includeSourceCredentials`: If true, the encrypted crednetial for the registered sources will be included. Credential is first encrypted with internal key and then reencrypted with user supplied encryption key.
   *
   * - `ids`: Ids specifies the list of source registration ids to return. If left empty, every source registration will be returned by default.
   *
   * - `encryptionKey`: Specifies the key to be used to encrypt the source credential. If includeSourceCredentials is set to true this key must be specified.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetSourceRegistrationsResponse(params: SourceService.GetSourceRegistrationsParams): __Observable<__StrictHttpResponse<SourceRegistrations>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.useCachedData != null) __params = __params.set('useCachedData', params.useCachedData.toString());
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.includeSourceCredentials != null) __params = __params.set('includeSourceCredentials', params.includeSourceCredentials.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.encryptionKey != null) __params = __params.set('encryptionKey', params.encryptionKey.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/sources/registrations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SourceRegistrations>;
      })
    );
  }
  /**
   * Get the list of Protection Source registrations.
   *
   * Get the list of Protection Source registrations.
   * @param params The `SourceService.GetSourceRegistrationsParams` containing the following parameters:
   *
   * - `useCachedData`: Specifies whether we can serve the GET request from the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: If true, the response will include Registrations which were created by all tenants which the current user has permission to see. If false, then only Registrations created by the current user will be returned.
   *
   * - `includeSourceCredentials`: If true, the encrypted crednetial for the registered sources will be included. Credential is first encrypted with internal key and then reencrypted with user supplied encryption key.
   *
   * - `ids`: Ids specifies the list of source registration ids to return. If left empty, every source registration will be returned by default.
   *
   * - `encryptionKey`: Specifies the key to be used to encrypt the source credential. If includeSourceCredentials is set to true this key must be specified.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetSourceRegistrations(params: SourceService.GetSourceRegistrationsParams): __Observable<SourceRegistrations> {
    return this.GetSourceRegistrationsResponse(params).pipe(
      __map(_r => _r.body as SourceRegistrations)
    );
  }

  /**
   * Register a Protection Source.
   *
   * Register a Protection Source.
   * @param params The `SourceService.RegisterProtectionSourceParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to register a Protection Source.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RegisterProtectionSourceResponse(params: SourceService.RegisterProtectionSourceParams): __Observable<__StrictHttpResponse<SourceRegistration>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/sources/registrations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SourceRegistration>;
      })
    );
  }
  /**
   * Register a Protection Source.
   *
   * Register a Protection Source.
   * @param params The `SourceService.RegisterProtectionSourceParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to register a Protection Source.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RegisterProtectionSource(params: SourceService.RegisterProtectionSourceParams): __Observable<SourceRegistration> {
    return this.RegisterProtectionSourceResponse(params).pipe(
      __map(_r => _r.body as SourceRegistration)
    );
  }

  /**
   * Get a Protection Source registration.
   *
   * Get a Protection Source registration.
   * @param params The `SourceService.GetProtectionSourceRegistrationParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Protection Source registration.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionSourceRegistrationResponse(params: SourceService.GetProtectionSourceRegistrationParams): __Observable<__StrictHttpResponse<SourceRegistration>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.requestInitiatorType != null) __headers = __headers.set('requestInitiatorType', params.requestInitiatorType.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/sources/registrations/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SourceRegistration>;
      })
    );
  }
  /**
   * Get a Protection Source registration.
   *
   * Get a Protection Source registration.
   * @param params The `SourceService.GetProtectionSourceRegistrationParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Protection Source registration.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetProtectionSourceRegistration(params: SourceService.GetProtectionSourceRegistrationParams): __Observable<SourceRegistration> {
    return this.GetProtectionSourceRegistrationResponse(params).pipe(
      __map(_r => _r.body as SourceRegistration)
    );
  }

  /**
   * Update Protection Source registration.
   *
   * Update Protection Source registration.
   * @param params The `SourceService.UpdateProtectionSourceRegistrationParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Protection Source registration.
   *
   * - `body`: Specifies the parameters to update the registration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateProtectionSourceRegistrationResponse(params: SourceService.UpdateProtectionSourceRegistrationParams): __Observable<__StrictHttpResponse<SourceRegistration>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/data-protect/sources/registrations/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SourceRegistration>;
      })
    );
  }
  /**
   * Update Protection Source registration.
   *
   * Update Protection Source registration.
   * @param params The `SourceService.UpdateProtectionSourceRegistrationParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Protection Source registration.
   *
   * - `body`: Specifies the parameters to update the registration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateProtectionSourceRegistration(params: SourceService.UpdateProtectionSourceRegistrationParams): __Observable<SourceRegistration> {
    return this.UpdateProtectionSourceRegistrationResponse(params).pipe(
      __map(_r => _r.body as SourceRegistration)
    );
  }

  /**
   * Delete Protection Source Registration.
   *
   * Delete Protection Source Registration.
   * @param params The `SourceService.DeleteProtectionSourceRegistrationParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the Protection Source Registration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteProtectionSourceRegistrationResponse(params: SourceService.DeleteProtectionSourceRegistrationParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/data-protect/sources/registrations/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete Protection Source Registration.
   *
   * Delete Protection Source Registration.
   * @param params The `SourceService.DeleteProtectionSourceRegistrationParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the Protection Source Registration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteProtectionSourceRegistration(params: SourceService.DeleteProtectionSourceRegistrationParams): __Observable<null> {
    return this.DeleteProtectionSourceRegistrationResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Perform Partial Update on Protection Source registration. Currently this API is supported only for Cassandra
   *
   * Patches a Protection Source.
   * @param params The `SourceService.PatchProtectionSourceRegistrationParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Protection Source registration.
   *
   * - `body`: Specifies the parameters to partially update the registration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  PatchProtectionSourceRegistrationResponse(params: SourceService.PatchProtectionSourceRegistrationParams): __Observable<__StrictHttpResponse<SourceRegistration>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/data-protect/sources/registrations/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SourceRegistration>;
      })
    );
  }
  /**
   * Perform Partial Update on Protection Source registration. Currently this API is supported only for Cassandra
   *
   * Patches a Protection Source.
   * @param params The `SourceService.PatchProtectionSourceRegistrationParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Protection Source registration.
   *
   * - `body`: Specifies the parameters to partially update the registration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  PatchProtectionSourceRegistration(params: SourceService.PatchProtectionSourceRegistrationParams): __Observable<SourceRegistration> {
    return this.PatchProtectionSourceRegistrationResponse(params).pipe(
      __map(_r => _r.body as SourceRegistration)
    );
  }

  /**
   * Test connection to a source.
   *
   * Test connection to a source.
   * @param params The `SourceService.TestConnectionProtectionSourceParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to test connectivity with a source.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  TestConnectionProtectionSourceResponse(params: SourceService.TestConnectionProtectionSourceParams): __Observable<__StrictHttpResponse<SourceConnectionResponseParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/sources/test-connection`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SourceConnectionResponseParams>;
      })
    );
  }
  /**
   * Test connection to a source.
   *
   * Test connection to a source.
   * @param params The `SourceService.TestConnectionProtectionSourceParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to test connectivity with a source.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  TestConnectionProtectionSource(params: SourceService.TestConnectionProtectionSourceParams): __Observable<SourceConnectionResponseParams> {
    return this.TestConnectionProtectionSourceResponse(params).pipe(
      __map(_r => _r.body as SourceConnectionResponseParams)
    );
  }

  /**
   * Get VDC Details.
   *
   * Get the details such as catelogs, Org networks associated with a VMware virtual datacenter (VDC).
   * @param params The `SourceService.GetVdcDetailsParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the VMware virtual datacenter.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetVdcDetailsResponse(params: SourceService.GetVdcDetailsParams): __Observable<__StrictHttpResponse<VdcObject>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/sources/virtual-datacenter/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VdcObject>;
      })
    );
  }
  /**
   * Get VDC Details.
   *
   * Get the details such as catelogs, Org networks associated with a VMware virtual datacenter (VDC).
   * @param params The `SourceService.GetVdcDetailsParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the VMware virtual datacenter.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetVdcDetails(params: SourceService.GetVdcDetailsParams): __Observable<VdcObject> {
    return this.GetVdcDetailsResponse(params).pipe(
      __map(_r => _r.body as VdcObject)
    );
  }

  /**
   * Get a Protection Sources.
   *
   * Get a Protection Source.
   * @param params The `SourceService.ProtectionSourceByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Protection Source.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ProtectionSourceByIdResponse(params: SourceService.ProtectionSourceByIdParams): __Observable<__StrictHttpResponse<Source>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-protect/sources/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Source>;
      })
    );
  }
  /**
   * Get a Protection Sources.
   *
   * Get a Protection Source.
   * @param params The `SourceService.ProtectionSourceByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Protection Source.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ProtectionSourceById(params: SourceService.ProtectionSourceByIdParams): __Observable<Source> {
    return this.ProtectionSourceByIdResponse(params).pipe(
      __map(_r => _r.body as Source)
    );
  }

  /**
   * Refresh a Protection Source.
   *
   * Refresh a Protection Source.
   * @param params The `SourceService.RefreshProtectionSourceByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Protection Source.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  RefreshProtectionSourceByIdResponse(params: SourceService.RefreshProtectionSourceByIdParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/sources/${encodeURIComponent(params.id)}/refresh`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Refresh a Protection Source.
   *
   * Refresh a Protection Source.
   * @param params The `SourceService.RefreshProtectionSourceByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Protection Source.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  RefreshProtectionSourceById(params: SourceService.RefreshProtectionSourceByIdParams): __Observable<null> {
    return this.RefreshProtectionSourceByIdResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get a List of Protection Sources.
   *
   * Get a List of Protection Sources.
   * @param params The `SourceService.McmGetProtectionSourcesParams` containing the following parameters:
   *
   * - `regionIds`: Filter by a list of region ids.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `ids`: Specifies the list of ids to filter Protection Sources.
   *
   * - `excludeProtectionStats`: Whether to exclude Protection Sources protection stats in response.
   *
   * - `environments`: Specifies the list of environment type of the Protection Source.
   *
   * - `clusterIds`: Filter by a list of cluster ids.
   *
   * @return Success
   */
  McmGetProtectionSourcesResponse(params: SourceService.McmGetProtectionSourcesParams): __Observable<__StrictHttpResponse<McmSources>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.regionIds || []).forEach(val => {if (val != null) __params = __params.append('regionIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.excludeProtectionStats != null) __params = __params.set('excludeProtectionStats', params.excludeProtectionStats.toString());
    (params.environments || []).forEach(val => {if (val != null) __params = __params.append('environments', val.toString())});
    (params.clusterIds || []).forEach(val => {if (val != null) __params = __params.append('clusterIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/data-protect/sources`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmSources>;
      })
    );
  }
  /**
   * Get a List of Protection Sources.
   *
   * Get a List of Protection Sources.
   * @param params The `SourceService.McmGetProtectionSourcesParams` containing the following parameters:
   *
   * - `regionIds`: Filter by a list of region ids.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `ids`: Specifies the list of ids to filter Protection Sources.
   *
   * - `excludeProtectionStats`: Whether to exclude Protection Sources protection stats in response.
   *
   * - `environments`: Specifies the list of environment type of the Protection Source.
   *
   * - `clusterIds`: Filter by a list of cluster ids.
   *
   * @return Success
   */
  McmGetProtectionSources(params: SourceService.McmGetProtectionSourcesParams): __Observable<McmSources> {
    return this.McmGetProtectionSourcesResponse(params).pipe(
      __map(_r => _r.body as McmSources)
    );
  }

  /**
   * Register a Protection Source.
   *
   * Register a Protection Source.
   * @param params The `SourceService.McmRegisterProtectionSourceParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to register a Protection Source.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: Specifies the destination cluster id on which this Source needs to be registered.
   *
   * @return Success
   */
  McmRegisterProtectionSourceResponse(params: SourceService.McmRegisterProtectionSourceParams): __Observable<__StrictHttpResponse<McmSourceRegistration>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/data-protect/sources/registrations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmSourceRegistration>;
      })
    );
  }
  /**
   * Register a Protection Source.
   *
   * Register a Protection Source.
   * @param params The `SourceService.McmRegisterProtectionSourceParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to register a Protection Source.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: Specifies the destination cluster id on which this Source needs to be registered.
   *
   * @return Success
   */
  McmRegisterProtectionSource(params: SourceService.McmRegisterProtectionSourceParams): __Observable<McmSourceRegistration> {
    return this.McmRegisterProtectionSourceResponse(params).pipe(
      __map(_r => _r.body as McmSourceRegistration)
    );
  }

  /**
   * Get a Protection Source registration.
   *
   * Get a Protection Source registration.
   * @param id Specifies the id of the Protection Source registration.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  McmGetProtectionSourceRegistrationResponse(id: string,
    regionId?: string): __Observable<__StrictHttpResponse<McmSourceRegistration>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/data-protect/sources/registrations/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmSourceRegistration>;
      })
    );
  }
  /**
   * Get a Protection Source registration.
   *
   * Get a Protection Source registration.
   * @param id Specifies the id of the Protection Source registration.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  McmGetProtectionSourceRegistration(id: string,
    regionId?: string): __Observable<McmSourceRegistration> {
    return this.McmGetProtectionSourceRegistrationResponse(id, regionId).pipe(
      __map(_r => _r.body as McmSourceRegistration)
    );
  }

  /**
   * Update Protection Source registration.
   *
   * Update Protection Source registration.
   * @param params The `SourceService.UpdateProtectionSourceRegistrationMixin1Params` containing the following parameters:
   *
   * - `id`: Specifies the id of the Protection Source registration.
   *
   * - `body`: Specifies the parameters to update the registration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  UpdateProtectionSourceRegistrationMixin1Response(params: SourceService.UpdateProtectionSourceRegistrationMixin1Params): __Observable<__StrictHttpResponse<McmSourceRegistration>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/mcm/data-protect/sources/registrations/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmSourceRegistration>;
      })
    );
  }
  /**
   * Update Protection Source registration.
   *
   * Update Protection Source registration.
   * @param params The `SourceService.UpdateProtectionSourceRegistrationMixin1Params` containing the following parameters:
   *
   * - `id`: Specifies the id of the Protection Source registration.
   *
   * - `body`: Specifies the parameters to update the registration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  UpdateProtectionSourceRegistrationMixin1(params: SourceService.UpdateProtectionSourceRegistrationMixin1Params): __Observable<McmSourceRegistration> {
    return this.UpdateProtectionSourceRegistrationMixin1Response(params).pipe(
      __map(_r => _r.body as McmSourceRegistration)
    );
  }

  /**
   * Delete Protection Source Registration.
   *
   * Delete Protection Source Registration.
   * @param id Specifies the ID of the Protection Source Registration.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  McmDeleteProtectionSourceRegistrationResponse(id: string,
    regionId?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/mcm/data-protect/sources/registrations/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete Protection Source Registration.
   *
   * Delete Protection Source Registration.
   * @param id Specifies the ID of the Protection Source Registration.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  McmDeleteProtectionSourceRegistration(id: string,
    regionId?: string): __Observable<null> {
    return this.McmDeleteProtectionSourceRegistrationResponse(id, regionId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Test connection to a source.
   *
   * Specifies the endpoint to test the source connectivity.
   * @param params The `SourceService.McmTestSourceConnectionParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to test connectivity of a Protection Source.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: Specifies the destination cluster id on which this Source needs to be registered.
   *
   * @return Success
   */
  McmTestSourceConnectionResponse(params: SourceService.McmTestSourceConnectionParams): __Observable<__StrictHttpResponse<SourceConnectionResponseParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/data-protect/sources/test-connection`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SourceConnectionResponseParams>;
      })
    );
  }
  /**
   * Test connection to a source.
   *
   * Specifies the endpoint to test the source connectivity.
   * @param params The `SourceService.McmTestSourceConnectionParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to test connectivity of a Protection Source.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: Specifies the destination cluster id on which this Source needs to be registered.
   *
   * @return Success
   */
  McmTestSourceConnection(params: SourceService.McmTestSourceConnectionParams): __Observable<SourceConnectionResponseParams> {
    return this.McmTestSourceConnectionResponse(params).pipe(
      __map(_r => _r.body as SourceConnectionResponseParams)
    );
  }

  /**
   * GetTenantSourceRegistrations
   *
   * Get the source registrations for a given DMaaS tenant.
   * @param body Specifies the parameters to fetch source registrations for a given tenant.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  McmGetTenantSourceRegistrationsResponse(body: McmTenantSourceRegistrationsParams,
    regionId?: string): __Observable<__StrictHttpResponse<McmTenantSourceRegistrations>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/tenants/source-registrations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmTenantSourceRegistrations>;
      })
    );
  }
  /**
   * GetTenantSourceRegistrations
   *
   * Get the source registrations for a given DMaaS tenant.
   * @param body Specifies the parameters to fetch source registrations for a given tenant.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  McmGetTenantSourceRegistrations(body: McmTenantSourceRegistrationsParams,
    regionId?: string): __Observable<McmTenantSourceRegistrations> {
    return this.McmGetTenantSourceRegistrationsResponse(body, regionId).pipe(
      __map(_r => _r.body as McmTenantSourceRegistrations)
    );
  }
}

module SourceService {

  /**
   * Parameters for GetProtectionSources
   */
  export interface GetProtectionSourcesParams {

    /**
     * TenantIds contains ids of the tenants for which Sources are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
     */
    requestInitiatorType?: 'UIUser' | 'UIAuto' | 'Helios';

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * If true, the response will include Sources which belong belong to all tenants which the current user has permission to see. If false, then only Sources for the current user will be returned.
     */
    includeTenants?: boolean;

    /**
     * If true, the encrypted crednetial for the registered sources will be included. Credential is first encrypted with internal key and then reencrypted with user supplied encryption key.
     */
    includeSourceCredentials?: boolean;

    /**
     * Specifies the key to be used to encrypt the source credential. If includeSourceCredentials is set to true this key must be specified.
     */
    encryptionKey?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetSourceAttributeFilters
   */
  export interface GetSourceAttributeFiltersParams {

    /**
     * Specifies the source UUID of the parent entity.
     */
    sourceUuid: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the environment type of the Protection Source.
     */
    environment?: null | 'kVMware' | 'kHyperV' | 'kAcropolis' | 'kKVM' | 'kAWS' | 'kGCP' | 'kAzure' | 'kPhysical' | 'kPure' | 'kIbmFlashSystem' | 'kNimble' | 'kNetapp' | 'kGenericNas' | 'kIsilon' | 'kFlashBlade' | 'kGPFS' | 'kElastifile' | 'kO365' | 'kHyperFlex' | 'kKubernetes' | 'kCassandra' | 'kMongoDB' | 'kCouchbase' | 'kHdfs' | 'kHive' | 'kHBase' | 'kUDA' | 'kSQL' | 'kOracle' | 'kSfdc' | 'kSAPHANA';

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GenerateM365DeviceCode
   */
  export interface GenerateM365DeviceCodeParams {

    /**
     * Specifies the parameters to generate the user and device code to initiate authentication with Microsoft365.
     */
    body: GenerateM365DeviceCodeRequestParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GenerateM365DeviceAccessToken
   */
  export interface GenerateM365DeviceAccessTokenParams {

    /**
     * Specifies the parameters to validate and generate access token for authorizing the client within Microsoft365.
     */
    body: GenerateM365DeviceAccessTokenRequestParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateOrUpdateAzureApplications
   */
  export interface CreateOrUpdateAzureApplicationsParams {

    /**
     * Specifies the parameters to create/update Azure applications within a given Microsoft365 source.
     */
    body: CreateAzureApplicationRequestParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateAzureApplications
   */
  export interface CreateAzureApplicationsParams {

    /**
     * Specifies the parameters to create Azure applications within a given Microsoft365 source.
     */
    body: CreateAzureApplicationRequestParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetM365BackupController
   */
  export interface GetM365BackupControllerParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the JWT obtained through user with the scope as BackupRestore-Control.ReadWrite.All
     */
    azureToken?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for RegisterM365BackupController
   */
  export interface RegisterM365BackupControllerParams {

    /**
     * Specifies the JWT obtained through user with the scope for BackupRestore-Control.ReadWrite.All
     */
    azureToken: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for EnableMBSBillingProfile
   */
  export interface EnableMBSBillingProfileParams {
    azureToken: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UnregisterM365BackupController
   */
  export interface UnregisterM365BackupControllerParams {

    /**
     * Specifies the Service App ID for the registered M365 Backup Controller when Cohesity App is the active Controller.
     */
    id: null | string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the JWT obtained through user with the scope as BackupRestore-Control.ReadWrite.All
     */
    azureToken?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateM365BackupController
   */
  export interface UpdateM365BackupControllerParams {

    /**
     * Specifies the Service App ID for the registered M365 Backup Controller when Cohesity App is the active Controller.
     */
    id: null | string;

    /**
     * Specifies the JWT obtained through user with the scope as BackupRestore-Control.ReadWrite.All
     */
    azureToken: string;

    /**
     * Specifies the state of the Backup Controller. The state parameter can only be either set to Active/Inactive within the request. The other states like PendingInactive & PendingActive are not applicable.
     */
    state?: null | 'Inactive' | 'PendingInactive' | 'PendingActive' | 'Active';

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetM365SourceRegionEndpoint
   */
  export interface GetM365SourceRegionEndpointParams {

    /**
     * Specifies the domain name of the source.
     */
    domain: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetMicrosoft365SelfServiceConfig
   */
  export interface GetMicrosoft365SelfServiceConfigParams {

    /**
     * Specifies the Cohesity Tenant ID for the source owner.
     */
    tenantId: null | string;

    /**
     * Specifies the workload type as filter for fetching Self-Service configuration types.
     */
    workloadType?: null | 'kO365Exchange' | 'kO365OneDrive';

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateM365SelfServiceConfig
   */
  export interface UpdateM365SelfServiceConfigParams {

    /**
     * Specifies the UUID of the Microsoft365 Source.
     */
    uuid: string;

    /**
     * Specifies the parameters to enable Self-Service for a Microsoft365 source. This configuration will apply to all regions incase the same source is registered across regions.
     */
    body: CreateM365SelfServiceConfigRequestParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteM365SelfServiceConfig
   */
  export interface DeleteM365SelfServiceConfigParams {

    /**
     * Specifies the UUID of the Microsoft365 Source.
     */
    uuid: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetSourceRegistrations
   */
  export interface GetSourceRegistrationsParams {

    /**
     * Specifies whether we can serve the GET request from the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
     */
    useCachedData?: boolean;

    /**
     * TenantIds contains ids of the tenants for which objects are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * If true, the response will include Registrations which were created by all tenants which the current user has permission to see. If false, then only Registrations created by the current user will be returned.
     */
    includeTenants?: boolean;

    /**
     * If true, the encrypted crednetial for the registered sources will be included. Credential is first encrypted with internal key and then reencrypted with user supplied encryption key.
     */
    includeSourceCredentials?: boolean;

    /**
     * Ids specifies the list of source registration ids to return. If left empty, every source registration will be returned by default.
     */
    ids?: Array<number>;

    /**
     * Specifies the key to be used to encrypt the source credential. If includeSourceCredentials is set to true this key must be specified.
     */
    encryptionKey?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for RegisterProtectionSource
   */
  export interface RegisterProtectionSourceParams {

    /**
     * Specifies the parameters to register a Protection Source.
     */
    body: SourceRegistrationRequestParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetProtectionSourceRegistration
   */
  export interface GetProtectionSourceRegistrationParams {

    /**
     * Specifies the id of the Protection Source registration.
     */
    id: number;

    /**
     * Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
     */
    requestInitiatorType?: 'UIUser' | 'UIAuto' | 'Helios';

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateProtectionSourceRegistration
   */
  export interface UpdateProtectionSourceRegistrationParams {

    /**
     * Specifies the id of the Protection Source registration.
     */
    id: number;

    /**
     * Specifies the parameters to update the registration.
     */
    body: SourceRegistrationUpdateRequestParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteProtectionSourceRegistration
   */
  export interface DeleteProtectionSourceRegistrationParams {

    /**
     * Specifies the ID of the Protection Source Registration.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for PatchProtectionSourceRegistration
   */
  export interface PatchProtectionSourceRegistrationParams {

    /**
     * Specifies the id of the Protection Source registration.
     */
    id: number;

    /**
     * Specifies the parameters to partially update the registration.
     */
    body: SourceRegistrationPatchRequestParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for TestConnectionProtectionSource
   */
  export interface TestConnectionProtectionSourceParams {

    /**
     * Specifies the parameters to test connectivity with a source.
     */
    body: SourceConnectionRequestParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetVdcDetails
   */
  export interface GetVdcDetailsParams {

    /**
     * Specifies the ID of the VMware virtual datacenter.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ProtectionSourceById
   */
  export interface ProtectionSourceByIdParams {

    /**
     * Specifies the id of the Protection Source.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for RefreshProtectionSourceById
   */
  export interface RefreshProtectionSourceByIdParams {

    /**
     * Specifies the id of the Protection Source.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for McmGetProtectionSources
   */
  export interface McmGetProtectionSourcesParams {

    /**
     * Filter by a list of region ids.
     */
    regionIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the list of ids to filter Protection Sources.
     */
    ids?: Array<string>;

    /**
     * Whether to exclude Protection Sources protection stats in response.
     */
    excludeProtectionStats?: boolean;

    /**
     * Specifies the list of environment type of the Protection Source.
     */
    environments?: Array<'kVMware' | 'kHyperV' | 'kAcropolis' | 'kKVM' | 'kAWS' | 'kGCP' | 'kAzure' | 'kPhysical' | 'kPure' | 'kIbmFlashSystem' | 'kNimble' | 'kNetapp' | 'kGenericNas' | 'kIsilon' | 'kFlashBlade' | 'kGPFS' | 'kElastifile' | 'kO365' | 'kHyperFlex' | 'kKubernetes' | 'kCassandra' | 'kMongoDB' | 'kCouchbase' | 'kHdfs' | 'kHive' | 'kHBase' | 'kUDA' | 'kSQL' | 'kOracle' | 'kSfdc' | 'kSAPHANA'>;

    /**
     * Filter by a list of cluster ids.
     */
    clusterIds?: Array<string>;
  }

  /**
   * Parameters for McmRegisterProtectionSource
   */
  export interface McmRegisterProtectionSourceParams {

    /**
     * Specifies the parameters to register a Protection Source.
     */
    body: McmSourceRegistrationRequestParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the destination cluster id on which this Source needs to be registered.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateProtectionSourceRegistrationMixin1
   */
  export interface UpdateProtectionSourceRegistrationMixin1Params {

    /**
     * Specifies the id of the Protection Source registration.
     */
    id: string;

    /**
     * Specifies the parameters to update the registration.
     */
    body: McmSourceRegistrationUpdateRequestParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;
  }

  /**
   * Parameters for McmTestSourceConnection
   */
  export interface McmTestSourceConnectionParams {

    /**
     * Specifies the parameters to test connectivity of a Protection Source.
     */
    body: SourceConnectionRequestParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the destination cluster id on which this Source needs to be registered.
     */
    accessClusterId?: number;
  }
}

export { SourceService }
