import { ProtectdObjectsActionRequest } from '@cohesity/api/v2';
import { Environment } from '@cohesity/iris-shared-constants';

/**
 * Type for snapshot backend types.
 */
export type SnapshotBackendTypes = ProtectdObjectsActionRequest['snapshotBackendTypes'];

/**
 * Enum of actions for object protection.
 * This is same as ProtectdObjectsActionRequest.action in the API except "CancelRun".
 */
export enum ObjectProtectAction {
  Pause = 'Pause',
  Resume = 'Resume',
  UnProtect = 'UnProtect',
  ProtectNow = 'ProtectNow',
  CancelRun = 'CancelRun',
}

/**
 * Enum of Object protection environment types.
 */
export enum ObjectProtectionEnvironmentType {
  kNative = 'kNative',
  kSnapshotManager = 'kSnapshotManager',
  kPhysical = 'kPhysical',
  kSQL = 'kSQL',
  kOracle = 'kOracle',
}

/**
 * Array of possible value for snapshot backend types. This is used for
 * objects which can have different types of protection.
 */
export const SnapshotBackendTypes: SnapshotBackendTypes = [
  Environment.kAWSNative,
  Environment.kAWSSnapshotManager,
  Environment.kPhysical,
  Environment.kSQL,
  Environment.kOracle,
  Environment.kRDSSnapshotManager,
  Environment.kAuroraSnapshotManager,
  Environment.kAwsS3,
];
