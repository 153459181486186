import { VERSION } from 'src/environments/version';

import { Locale } from '../../models';

/**
 * Type of locales available.
 */
export const Locales:  Locale[] = ['en-us', 'ja-jp'];

/**
 * The default locale of the app.
 */
export const DefaultLocale: Locale = 'en-us';

/**
 * Language dictionary filename. Will be fetched with locale slug from
 * IP/messages/{slug}/ui.json
 */
export const LangFile = ['/ui.json?build=', VERSION.hash].join('');

/**
 * Locale settings filename. Will be fetched with locale slug from
 * IP/messages/{slug}/locale_settings.json
 */
export const LocaleFile = ['/locale_settings.json?build=', VERSION.hash].join('');

export function LanguageOverrideFile(overrideParameter) {
  return [`/ui-${overrideParameter}.json?build=`, VERSION.hash].join('');
}
