/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityReportingApiConfiguration as __Configuration } from '../cohesity-reporting-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Resources } from '../models/resources';
import { GetResourcesRequest } from '../models/get-resources-request';
@Injectable({
  providedIn: 'root',
})
class FiltersService extends __BaseService {
  static readonly GetResourcesPath = '/public/resources';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get resources
   *
   * Get different kinds of resources available which are discovered on Helios. These values can be used for filtering options
   * @param body Request body params to get available resources.
   * @return Success
   */
  GetResourcesResponse(body: GetResourcesRequest): __Observable<__StrictHttpResponse<Resources>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/resources`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Resources>;
      })
    );
  }
  /**
   * Get resources
   *
   * Get different kinds of resources available which are discovered on Helios. These values can be used for filtering options
   * @param body Request body params to get available resources.
   * @return Success
   */
  GetResources(body: GetResourcesRequest): __Observable<Resources> {
    return this.GetResourcesResponse(body).pipe(
      __map(_r => _r.body as Resources)
    );
  }
}

module FiltersService {
}

export { FiltersService }
