import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SecurityCriteria } from '@cohesity/api/secops';


/**
 * Component to display expansion panel with list of security criteria items of a security rule.
 * Used in Security rule details component.
 */
@Component({
  selector: 'dg-pa-security-criteria-list',
  templateUrl: './security-criteria-list.component.html',
  styleUrls: ['security-criteria-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SecurityCriteriaListComponent {
  /** Panel open state */
  panelOpenState = false;

  /** Number of security criteria enabled. */
  enabledSecurityCriteriaLength = 0;

  /** Input variable to display list of security criteria */
  @Input() set securityCriteriaList(securityCriteriaList: SecurityCriteria[]) {
    this.enabledSecurityCriteriaLength = securityCriteriaList.filter(item => item.isEnabled).length;
    this._securityCriteriaList = securityCriteriaList;
  }

  /** Getter to get security criteria list. */
  get securityCriteriaList(): SecurityCriteria[] {
    return this._securityCriteriaList;
  }

  /** Internal variable to store  securityCriteriaList. */
  private _securityCriteriaList: SecurityCriteria[];
}
