import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { AngularMaterialModule, CohesityHelixModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';

import { PipesModule } from '../pipes';
import { ProtectButtonComponent } from './protect-button.component';
import { RecoverButtonComponent } from './recover-button.component';

const COMPONENTS = [
  ProtectButtonComponent,
  RecoverButtonComponent
];

/**
 * Module for page action buttons.
 */
@NgModule({
  imports: [
    AngularMaterialModule,
    CohesityHelixModule,
    CommonModule,
    PipesModule,
    TranslateModule.forChild(),
    UIRouterModule
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class ActionButtonsModule {}
