import { TranslateService } from '@ngx-translate/core';

/**
 * Data structure for table value filter.
 */
export interface FilterValue {
  label: string;
  value?: string;
  subItems?: FilterValue[];
}

/**
 * Interface for common properties shared between FilterCOnfig and NestedFilterConfig.
 */
interface BaseFilterConfig {
  /**
   * Name of filter, will be used as index to reference after FilterValuesList is created.
   * NOTE: this will be translated based on prefix of parent FilterConfig.
   */
  name: string;

  /**
   * Prefix string for translation of subValues. If not provide, FilterConfig prefix
   * value will be used instead.
   */
  prefix?: string;

  /**
   * Used to overwrite a key with value.
   */
  overrideTranslateValue?: Record<string, string>;
}

/**
 * Data structure for filter configuration used as parameter for FilterValuesList constructor.
 *
 * @example
 * {
 *    name: 'task',
 *    prefix: 'enum.protectionTask.',
 *    values: [
 *      'kActive',
 *      'kInactive',
 *      'kDeleted'
 *    ],
 * }
 */
export interface FilterConfig extends BaseFilterConfig {
  /**
   * List of values, used in both matching and translation (with prefix).
   * If nested values are needed, this should be omited and nestedValues[]
   * should be used instead.
   */
  values?: string[];

  /**
   * List of values, used in both matching and translation (with prefix).
   * If nested values aren't need, use the more straightforward values[].
   */
  nestedValues?: NestedFilterConfig[];
}

/**
 * Configuration to support nesting values for use with NestedValuePropertyFilter.
 */
export interface NestedFilterConfig extends BaseFilterConfig {
  /**
   * List of values, used in both matching and translation (with prefix).
   */
  subValues: string[];
}

/**
 * Data structure for table value filters generated from filter configuration.
 */
export class FilterValuesList {
  [index: string]: FilterValue[];

  /**
   * Constructor. Initialize filter values required by cog-value-property-filter.
   *
   * @param   configs           Filter configuration which is used to generate the filter data model
   * @param   translateService  Optional parameter used to translate the label
   */
  constructor(configs: FilterConfig[], translateService?: TranslateService) {
    // If no translate service is not provided, provide a stand-in.
    const translate = translateService ? translateService : {
      instant: (val) => val,
    };

    configs.forEach(({ name, prefix, values, nestedValues, overrideTranslateValue = {} }) => {

      // Handle simple, single-level filters, or...
      if (values && values.length) {
        this[name] = values.map((value: string) => {
          // If an override value was provided for the translation, use it instead of the
          // of the prefix + value.
          const translateKey = overrideTranslateValue && overrideTranslateValue[value]
            ? overrideTranslateValue[value]
            : (prefix + value);

          return {
            label: translate.instant(translateKey),
            value: value,
          };
        });
      }

      // ...handled more complex, nested level filters.
      if (nestedValues && nestedValues.length) {
        this[name] = nestedValues.map((nestedConfig: NestedFilterConfig) => {

          const filterValue = nestedConfig.name;

          // If an override value was provided for the translation, use it instead of the
          // of the prefix + value.
          const translateKey = overrideTranslateValue && overrideTranslateValue[filterValue]
            ? overrideTranslateValue[filterValue]
            : (prefix + filterValue);

          const filterConfig = {
            // Overwrite default value with value provided in overrideTranslateValue object.
            label: translate.instant(translateKey),
            value: filterValue,

            subItems: (nestedConfig.subValues || []).map(subVal => ({
              // Can use the same prefix as the parent, but if one is provided at
              // the nested level use it instead.
              label: translate.instant((nestedConfig.prefix || prefix) + subVal),
              value: subVal,
            })),
          };

          return filterConfig;
        });
      }
    });
  }
}
