import { UntypedFormGroup } from '@angular/forms';

/**
 * This class extends the Angular Reactive Form Group to add strict value type.
 * A custom class can extend this to automatically cast the FromGroup value to
 * a specific interface type. Note that this will only really work with a type
 * interface. Attempting to cast to a class will not work as the value returned
 * will be a simple object
 */
export class TypedFormGroup<T> extends UntypedFormGroup {

  /**
   * Overrides the parent value to add type info to it.
   */
  readonly value: T;
}
