import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScanSummaryDetails, SecurityAdvisorServiceApi } from '@cohesity/api/secops';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'dg-pa-scan-result-detail-page',
  templateUrl: './scan-result-detail-page.component.html',
})

/**
 * Result detail component
 */
export class ScanResultDetailPageComponent extends AutoDestroyable implements OnInit {
  /**
   * Inform parent 'back button' has been clicked
   */
  @Output() backClick = new EventEmitter<string>();

  /**
   * Id of scan for which details are to be seen
   */
  @Input() scanId: string;

  /**
   *  Holds the summary detail data
   */
  summaryDetail: ScanSummaryDetails;

  /**
   * Status of the table data.
   */
  loading = false;

  /**
   * Constructor
   */
  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private cdr: ChangeDetectorRef,
    private securityAdvisorService: SecurityAdvisorServiceApi,
  ) {
    super();
  }

  /**
   * Onint life cycle hook
   */
  ngOnInit() {
    this.loading = true;
    this.securityAdvisorService.GetScanDetails(this.scanId)
      .pipe(this.untilDestroy(), finalize(() => {
        this.loading = false;
        this.cdr.detectChanges();
      }))
      .subscribe(response => this.summaryDetail = response, this.ajaxHandlerService.handler);
  }
}
