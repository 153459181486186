/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DataSourceId } from '../models/data-source-id';
import { GetFilesDeletedStatsResponse } from '../models/get-files-deleted-stats-response';
import { GetFilesOpenedStatsResponse } from '../models/get-files-opened-stats-response';
import { GetLogsTopLocationsResponse } from '../models/get-logs-top-locations-response';
import { LogDetailResponse } from '../models/log-detail-response';
import { LogSearchParams } from '../models/log-search-params';
import { LogSearchResponse } from '../models/log-search-response';
import { QueryOpId } from '../models/query-op-id';
import { TimestampMsecs } from '../models/timestamp-msecs';

@Injectable({
  providedIn: 'root',
})
export class LogSearchApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getLogSearch
   */
  static readonly GetLogSearchPath = '/logs/search';

  /**
   * Search for logs with given query body.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLogSearch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getLogSearch$Response(params: {
      body: LogSearchParams
  }): Observable<StrictHttpResponse<LogSearchResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LogSearchApiService.GetLogSearchPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LogSearchResponse>;
      })
    );
  }

  /**
   * Search for logs with given query body.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLogSearch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getLogSearch(params: {
      body: LogSearchParams
  }): Observable<LogSearchResponse> {

    return this.getLogSearch$Response(params).pipe(
      map((r: StrictHttpResponse<LogSearchResponse>) => r.body as LogSearchResponse)
    );
  }

  /**
   * Path part for operation logDetailsById
   */
  static readonly LogDetailsByIdPath = '/logs/{id}/ds/{dataSourceId}/ts/{logTime}/detail';

  /**
   * Get log details for a given log ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logDetailsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  logDetailsById$Response(params: {

    /**
     * Log id.
     */
    id: null | string;

    /**
     * Source id of the log.
     */
    dataSourceId: DataSourceId;

    /**
     * Log time in milli seconds for the log.
     */
    logTime: TimestampMsecs;

    /**
     * Unique token to map retries requests in backend
     */
    queryOpId?: QueryOpId;

  }): Observable<StrictHttpResponse<LogDetailResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LogSearchApiService.LogDetailsByIdPath, 'get');
    if (params) {

      rb.path('id', params.id, {});
      rb.path('dataSourceId', params.dataSourceId, {});
      rb.path('logTime', params.logTime, {});
      rb.query('queryOpId', params.queryOpId, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LogDetailResponse>;
      })
    );
  }

  /**
   * Get log details for a given log ID.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `logDetailsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  logDetailsById(params: {

    /**
     * Log id.
     */
    id: null | string;

    /**
     * Source id of the log.
     */
    dataSourceId: DataSourceId;

    /**
     * Log time in milli seconds for the log.
     */
    logTime: TimestampMsecs;

    /**
     * Unique token to map retries requests in backend
     */
    queryOpId?: QueryOpId;

  }): Observable<LogDetailResponse> {

    return this.logDetailsById$Response(params).pipe(
      map((r: StrictHttpResponse<LogDetailResponse>) => r.body as LogDetailResponse)
    );
  }

  /**
   * Path part for operation getLogsTopLocations
   */
  static readonly GetLogsTopLocationsPath = '/logs/top-locations';

  /**
   * Get the top locations statistics from logs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLogsTopLocations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogsTopLocations$Response(params?: {

    /**
     * Start time in milli seconds to fetch top locations.
     */
    startTimeMsecs?: TimestampMsecs;

    /**
     * End time in milli seconds to fetch top locations.
     */
    endTimeMsecs?: TimestampMsecs;

  }): Observable<StrictHttpResponse<GetLogsTopLocationsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LogSearchApiService.GetLogsTopLocationsPath, 'get');
    if (params) {

      rb.query('startTimeMsecs', params.startTimeMsecs, {});
      rb.query('endTimeMsecs', params.endTimeMsecs, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetLogsTopLocationsResponse>;
      })
    );
  }

  /**
   * Get the top locations statistics from logs.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLogsTopLocations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLogsTopLocations(params?: {

    /**
     * Start time in milli seconds to fetch top locations.
     */
    startTimeMsecs?: TimestampMsecs;

    /**
     * End time in milli seconds to fetch top locations.
     */
    endTimeMsecs?: TimestampMsecs;

  }): Observable<GetLogsTopLocationsResponse> {

    return this.getLogsTopLocations$Response(params).pipe(
      map((r: StrictHttpResponse<GetLogsTopLocationsResponse>) => r.body as GetLogsTopLocationsResponse)
    );
  }

  /**
   * Path part for operation getFilesDeletedStats
   */
  static readonly GetFilesDeletedStatsPath = '/logs/files-deleted';

  /**
   * Get the files deleted by users stats.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFilesDeletedStats()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFilesDeletedStats$Response(params?: {

    /**
     * Start time in milli seconds to fetch data.
     */
    startTimeMsecs?: TimestampMsecs;

    /**
     * End time in milli seconds to fetch data.
     */
    endTimeMsecs?: TimestampMsecs;

  }): Observable<StrictHttpResponse<GetFilesDeletedStatsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LogSearchApiService.GetFilesDeletedStatsPath, 'get');
    if (params) {

      rb.query('startTimeMsecs', params.startTimeMsecs, {});
      rb.query('endTimeMsecs', params.endTimeMsecs, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetFilesDeletedStatsResponse>;
      })
    );
  }

  /**
   * Get the files deleted by users stats.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFilesDeletedStats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFilesDeletedStats(params?: {

    /**
     * Start time in milli seconds to fetch data.
     */
    startTimeMsecs?: TimestampMsecs;

    /**
     * End time in milli seconds to fetch data.
     */
    endTimeMsecs?: TimestampMsecs;

  }): Observable<GetFilesDeletedStatsResponse> {

    return this.getFilesDeletedStats$Response(params).pipe(
      map((r: StrictHttpResponse<GetFilesDeletedStatsResponse>) => r.body as GetFilesDeletedStatsResponse)
    );
  }

  /**
   * Path part for operation getFilesOpenedStats
   */
  static readonly GetFilesOpenedStatsPath = '/logs/files-opened';

  /**
   * Get the files opened by users stats.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFilesOpenedStats()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFilesOpenedStats$Response(params?: {

    /**
     * Start time in milli seconds to fetch data.
     */
    startTimeMsecs?: TimestampMsecs;

    /**
     * End time in milli seconds to fetch data.
     */
    endTimeMsecs?: TimestampMsecs;

  }): Observable<StrictHttpResponse<GetFilesOpenedStatsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LogSearchApiService.GetFilesOpenedStatsPath, 'get');
    if (params) {

      rb.query('startTimeMsecs', params.startTimeMsecs, {});
      rb.query('endTimeMsecs', params.endTimeMsecs, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetFilesOpenedStatsResponse>;
      })
    );
  }

  /**
   * Get the files opened by users stats.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFilesOpenedStats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFilesOpenedStats(params?: {

    /**
     * Start time in milli seconds to fetch data.
     */
    startTimeMsecs?: TimestampMsecs;

    /**
     * End time in milli seconds to fetch data.
     */
    endTimeMsecs?: TimestampMsecs;

  }): Observable<GetFilesOpenedStatsResponse> {

    return this.getFilesOpenedStats$Response(params).pipe(
      map((r: StrictHttpResponse<GetFilesOpenedStatsResponse>) => r.body as GetFilesOpenedStatsResponse)
    );
  }

}
