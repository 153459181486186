import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { SmbPermission, SmbPermissionsInfo } from '@cohesity/api/v2';
import { Controls, NgxSubFormComponent, subformComponentProviders } from 'ngx-sub-form';

@Component({
  selector: 'coh-ntfs-root-permissions',
  templateUrl: './ntfs-root-permissions.component.html',
  providers: subformComponentProviders(NtfsRootPermissionsComponent),
})
export class NtfsRootPermissionsComponent extends NgxSubFormComponent<SmbPermissionsInfo> implements OnInit, OnChanges {
  /**
   * List of trusted domains.
   */
  @Input() domains = ['LOCAL'];

  /**
   * Whether smb protocol is selected or not.
   */
  @Input() isSMBSelected = false;

  /**
   * Holds a hash of principals in their domains.
   */
  @Input() domainPrincipalsHash: any;

  /**
   * Whether ntfs ACLs are disabled or not.
   * It's true when disableEditSmbPermissions feature flag is on and in edit mode.
   */
  @Input() isDisabled;

  /**
   * Callback method called in order to update principal domain hash.
   */
  @Output() updatePrincipals = new EventEmitter<SmbPermission[]>();

  /**
   * Get the form controls needed for the sub form.
   *
   * @returns   The form controls object.
   */
  getFormControls(): Controls<SmbPermissionsInfo> {
    return {
      ownerSid: new UntypedFormControl(null),
      permissions: new UntypedFormControl(null),
    };
  }

  ngOnInit() {
    if (this.isSMBSelected) {
      this.formGroupControls.ownerSid.setValidators(Validators.required);
    }
  }

  ngOnChanges (changes: SimpleChanges) {
    if (changes.isSMBSelected ) {
      if (changes.isSMBSelected.currentValue === true) {
        this.formGroupControls.ownerSid.setValidators(Validators.required);
      } else {
        this.formGroupControls.ownerSid.clearValidators();
      }
    }
  }

  /**
   * Method called on updating smb permissions.
   *
   * @param   permissions   updated permissions.
   */
  onPermissionsChange(permissions: null | SmbPermission[]) {
    this.formGroupControls.permissions.setValue(permissions);
    this.updatePrincipals.emit(permissions);
  }
}
