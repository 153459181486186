<!-- mat calendar will be hidden always and used only to leverage usefully methods it exposes -->
<mat-calendar-header #matCalendarHeader></mat-calendar-header>
<div class="mat-calendar-header">
  <div class="mat-calendar-controls">
    <button mat-icon-button
      type="button"
      [style.visibility]="showPreviousButton ? 'visible' : 'hidden'"
      class="mat-calendar-previous-button"
      (click)="previousClicked()"
      [attr.aria-label]="matCalendarHeader.prevButtonLabel"
      cogDataId="prev-btn"
      [inheritDataId]="true">
    </button>

    <div class="mat-calendar-period">
      {{matCalendarHeader.periodButtonText}}
    </div>

    <button mat-icon-button
      type="button"
      class="mat-calendar-next-button"
      [style.visibility]="showNextButton ? 'visible' : 'hidden'"
      (click)="nextClicked()"
      [attr.aria-label]="matCalendarHeader.nextButtonLabel"
      cogDataId="next-btn"
      [inheritDataId]="true">
    </button>
  </div>
</div>
