import { NGXLogger } from 'ngx-logger';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NavItem } from '@cohesity/helix';

@Component({
  selector: 'coh-nav-item-button',
  templateUrl: './nav-item-button.component.html',
})
export class NavItemButtonComponent implements OnChanges {
  /**
   * Color scheme for the nav item button.
   */
  @Input() color: 'primary' | 'accent' | 'warn' = 'primary';

  /**
   * Optional classname to apply to the nav item button.
   */
  @Input() buttonClassName = '';

  /**
   * The nav item to show the clickable button of.
   */
  @Input() navItem: NavItem;

  /**
   * The type of button to show for the nav item.
   */
  @Input() navButtonType: 'flat' | 'icon' | 'menu' | 'flatIcon' = 'flat';

  constructor(private ngxLogger: NGXLogger) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.navItem && this.navItem && this.navItem.action && this.navItem.state) {
      this.ngxLogger.error('Both action and state cannot be set for the same navItem');

      // Ignore state in this case.
      this.navItem = {...this.navItem, state: null};
    }
  }
}
