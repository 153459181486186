import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

import { ItemListComponent } from './item-list.component';

@NgModule({
  imports: [CommonModule, MatMenuModule],
  exports: [ItemListComponent],
  declarations: [ItemListComponent],
})
export class ItemListModule {}
