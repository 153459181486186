<form [formGroup]="formGroup" class="create-form padding-md" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>
    {{ (isEditMode ? 'editStorageDomain' : 'createStorageDomain') | translate }}
  </h2>

  <div class="row">
    <mat-form-field class="col-xs-6">
      <mat-label>{{ 'viewBoxName' | translate }}</mat-label>
      <input matInput autocomplete="off" formControlName="storageDomainName">
    </mat-form-field>
  </div>

  <h3 class="form-control-container">{{ 'associatedExternalTarget' | translate }}</h3>

  <cog-banner
    class="margin-bottom"
    status="info">
    <p *ngIf="!isEditMode" [innerHTML]="'nextGenCreateViewboxNote' | translate"></p>
    <p *ngIf="isEditMode">
      {{ 'nextGenEditViewboxNote' | translate }}
    </p>
  </cog-banner>

  <div class="flex-controls col-xs-6" *ngIf="!isEditMode">
    <coh-external-target-selector
      formControlName="associatedExternalTarget"
      [excludedTierTypes]="excludedTierTypes"
      [label]="'associatedExternalTarget' | translate"
      [cloudEditionEnabled]="true"
      context="viewbox">
    </coh-external-target-selector>
  </div>

  <div class="row" *ngIf="isEditMode">
    <mat-form-field class="col-xs-6">
      <mat-label>{{ associatedExternalTarget | translate }}</mat-label>
      <input matInput autocomplete="off" formControlName="associatedExternalTarget">
    </mat-form-field>
  </div>

  <mat-dialog-actions align="end" class="margin-bottom">
    <button
      [disabled]="saving"
      [inheritDataId]="true"
      cogDataId="cancel"
      cogCancelButton
      mat-button
      mat-dialog-close>
      {{'cancel' | translate}}
    </button>
    <button
      [disabled]="saving"
      [inheritDataId]="true"
      [loading]="saving"
      class="margin-left"
      cogDataId="register"
      cogSubmitButton
      color="primary"
      mat-flat-button
      type="submit">
      {{ (isEditMode ? 'save' : 'create') | translate}}
    </button>
  </mat-dialog-actions>

</form>
