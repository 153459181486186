// Service: appstore Service

;(function(angular, undefined) {
  'use strict';
  angular
    .module('C.appsManagement')
    .service('AppStoreService', AppStoreServiceFn);

  function AppStoreServiceFn(_, $http, SlideModalService, API, DateTimeService,
    $translate, $window, UserService) {
    var appStoreBaseUrl = API.mcm('appstore');
    var unavailableAppIdList = [6]; // App Id list to disable app installation.

    return {
      checkUpgradability: checkUpgradability,
      downloadAppPackage: downloadAppPackage,
      getAppActionText: getAppActionText,
      getApps: getApps,
      getAppDetails: getAppDetails,
      getAppReviews: getAppReviews,
      getAppDevVersion: getAppDevVersion,
      getAppSpecificClusterVersions: getAppSpecificClusterVersions,
      getClusterCompatibleAppVersions: getClusterCompatibleAppVersions,
      launchInstallAppModal: launchInstallAppModal,
      launchAppReviewModal: launchAppReviewModal,
      manageApp: manageApp,
      postAppReview: postAppReview,
      purchaseApp: purchaseApp,
    };

    /**
     * get all the applications in appstore
     *
     * @method   getApps
     * @param    {Object} params   properties to get the list of apps
     * @return   {promise}         returns a promise resolved with list of apps
     *                             else rejected with error
     */
    function getApps(params) {
      return $http({
        method: 'get',
        url: API.mcm('appstore/app'),
        params: params,
      })
      .then(function gotDataSuccess(response) {
        var appList = response.data;

        // if there is no response, return empty
        // TODO: Will remove this type check condition once backend is sending
        //       response in proper format
        if (_.isEmpty(appList) || typeof appList !== 'object') {
          return [];
        }
        return transformAppList(appList);
      });
    }

    /**
     * provides get app action button text
     * integration for integration
     * app for container apps
     *
     * @method   getAppActionText
     * @param    {Object} app   app detail info from the api
     * @return   {string}       returns 'integration' or appstate
     *                            for container apps
     */
    function getAppActionText(app) {
      switch(true) {
        case app.applicationDetails.appType === 'integration':
          return 'integration';
        case app._isUnavailable:
          return 'comingSoon';
        default:
          return app.appState || 'kAvailable';
      }
    }

    /**
     * provides transformed list of apps
     *
     * @method    transformAppList
     * @param     {Object} appList   properties to get the list of apps
     * @return    {Array}          returns transformed list of apps
     */
    function transformAppList(appList) {
      var categories = [];
      var categoryOccuranceCount = _.countBy(
        appList,
        'applicationDetails.categoryInfo.id');

      appList.forEach(function eachApp(app, index) {
        appList[index] = transformApp(app);

        // TODO(Mythri): Currently fetching category info from developer portal
        // will remove this soon and will fetch only categories field.
        if (app.applicationDetails && app.applicationDetails.categoryInfo) {
          var categoryInfo = app.applicationDetails.categoryInfo;
          categoryInfo.numApps = categoryOccuranceCount[categoryInfo.id];
          categories.push(categoryInfo);
        }

        if (app.applicationDetails && app.applicationDetails.categories) {
          categories = categories.concat(app.applicationDetails.categories);
        }
      });

      var numAppsCategoryMap = _.countBy(categories, 'id');

      categories = categories.map(function eachCategory(category) {
        if (category.numApps === undefined) {
          category.numApps = numAppsCategoryMap[category.id];
        }

        return category;
      });

      return {
        appList: appList,
        _categories: _.sortBy(_.uniqBy(categories, 'id'), 'name')
      };
    }

    /**
     * get details of the specific application
     *
     * @method    getAppDetails
     * @param     {Number} appId   the id of the desired application
     * @return    {promise}        returns a promise with app details
     */
    function getAppDetails(id) {
      return $http({
        method: 'get',
        url: API.mcm('appstore/app/' + id),
      }).then(function gotDataSuccess(response) {
        return transformApp(response.data) || {};
      });
    }

    /**
     * get reviews of the specific application
     *
     * @method    getAppDetails
     * @param     {Number} appId   the id of the desired application
     * @return    {promise}         returns a promise with app review details
     */
    function getAppReviews(id) {
      return $http({
        method: 'get',
        url: API.mcm('appstore/app', id, 'review'),
      }).then(function gotDataSuccess(response) {
        var reviewList = response.data;

        if (_.isEmpty(reviewList)) {
          return [];
        }
        reviewList.forEach(function eachApp(review) {
          review._publishDate = DateTimeService.usecsToFormattedDate(
            review.publishTimeMsecs,
            DateTimeService.getLongDateFormat()
          );
        })
        return reviewList;
      });
    }

    /**
     * provides transformed image url for list of apps
     *
     * @method   transformApp
     * @param    {Object} app   app details
     * @return   {object}       return transformed app
     */
    function transformApp(app) {
      app._logoUrl = appStoreBaseUrl + app.logoUrl;
      app._isEulaAccepted = app.appState !== 'kAvailable';
      app._isUnavailable = unavailableAppIdList.includes(app.appId);

      if (app.applicationDetails.categoryInfo) {
        app._categoryInfo = app.applicationDetails.categoryInfo.name;
      }

      if (app.applicationDetails.categories &&
        app.applicationDetails.categories.length > 0) {
        app._categoryInfo = [];
        app.applicationDetails.categories.forEach(category => {
          app._categoryInfo.push(category.name);
        });
        app._categoryInfo = app._categoryInfo.join(', ');
      }

      if (app.applicationDetails && app.applicationDetails.imageUrls &&
        app.applicationDetails.imageUrls.length > 0) {
        var imageDetails = app.applicationDetails.imageUrls;

        imageDetails.forEach(function mapAppImageDetails(path, index) {
          imageDetails[index] = appStoreBaseUrl + path;
        });
        app.applicationDetails._imageUrlList = imageDetails;
      }
      return app;
    }

    /**
     * purchase an application on specified list of clusters
     *
     * @method   purchaseApp
     * @param    {object} params   input to purchase an app on specified
     *                             clusters
     * @return   {promise}         returns a promise resolved with app purchase
     *                             success else rejected with error
     */
    function purchaseApp(params) {
      return $http({
        method: 'post',
        url: API.mcm('appstore/apps', params.appId, 'states/purchased'),
      }).then(function gotDataSuccess(response) {
        return response;
      });
    }

    /**
     * Fetch list of cluster compatible app versions for specific appId.
     *
     * @method   getClusterCompatibleAppVersions
     * @param    {number} appId   App id.
     * @return   {promise}        returns a promise resolved with app versions
     *                            data else rejected with error
     */
    function getClusterCompatibleAppVersions(appId) {
      return $http({
        method: 'get',
        url: API.mcm('appstore/clusters/apps', appId, 'versions'),
      }).then(function gotAppVersionSuccess(response) {
        return response.data || [];
      });
    }

    /**
     * Fetch list of cluster versions for specific appId.
     *
     * @method   getAppClusterVersions
     * @param appId {Integer} app id information.
     * @return   {promise}        returns a promise resolved with app versions
     *                            data else rejected with error
     */
    function getAppSpecificClusterVersions(appId) {
      return $http({
        method: 'get',
        url: API.mcm('appstore/clusterversions', appId),
      }).then(function gotAppVersionSuccess(response) {
        return response.data.clusterVersions || [];
      });
    }

    /**
     * Fetch list of app dev vserions for specific cluster version.
     *
     * @method   getAppClusterVersions
     * @param {Object} params app and cluster version parameters.
     * @return {promise}      returns a promise resolved with app versions
     *                        data else rejected with error
     */
    function getAppDevVersion(params) {
      return $http({
        method: 'get',
        url: API.mcm('appstore/apps', params.appId,'clusterversions',
          params.clusterVersion, 'appversions')
      }).then(function getAppDevVersionSuccess(response) {
        return response.data || [];
      });
    }

    /**
     * install/upgrade/uninstall an application on specified list of clusters
     *
     * @method   manageApp
     * @param    {object} params    input to manage app on specified
     *                              clusters
     * @param    {object} payload   payload to perform app specific operation
     * @return   {promise}          returns a promise resolved with install/
     *                              upgrade/uninstall success else rejected with
     *                              error
     */
    function manageApp(params, payload) {
      return $http({
        method: params.requestType,
        url: API.mcm('appstore/apps', params.appId, 'states/installed'),
        data: payload,
      }).then(function gotDataSuccess(response) {
        return response;
      });
    }

    /**
     * launch select clusters modal for app installation
     *
     * @method   launchSelectClustersModal
     * @param    {object} app   app specific information
     * @return   {Object}       promise to resolve the cSlideModal
     */
    function launchSelectClustersModal(app) {
      var appName = app.appName ? app.appName : app.metadata.name;
      var modalConfig = {
        size: 'md',
        resolve: {
          innerComponent: 'installApp',
          actionButtonKey: 'install',
          closeButtonKey: 'installLater',
          customTitle: $translate.instant('apps.selectClustersToInstallApp',{ appName: appName }),
          bindings: {
            app: app
          },
          persistOnStateChanges: app._persistOnStateChanges,
        },
      };
      return SlideModalService.newModal(modalConfig);
    }

    /**
     * launches helios user agreement modal
     *
     * @method   launchInstallAppModal
     * @param    {object} app   app specific information
     * @return   {Object}  promise to resolve the cSlideModal
     */
    function launchInstallAppModal(app) {
      /*
      * ENG-247800
      * Disable get & install for Reporting app
      * */
      /*
      * app.appName for browse apps flow
      * app.metadata.name for My apps flow
      * */
      if(app.appName === 'Reporting' || (app.metadata && app.metadata.name === 'Reporting')){
        var modalConfig = {
          autoHeight: true,
          resolve: {
            innerComponent: 'contactCohesity',
          }
        };
        return SlideModalService.newModal(modalConfig);
      }

      app._operationType = 'install';
      if (app.appState === 'kAvailable') {
        var modalConfig = {
          size: 'md',
          resolve: {
            innerComponent: 'installApp',
            actionButtonKey: 'apps.acceptEula',
            titleKey: 'apps.eula.title',
            bindings: {
              app: app,
            },
            persistOnStateChanges: app._persistOnStateChanges,
          },
        };
        return SlideModalService.newModal(modalConfig);
      }
      launchSelectClustersModal(app);
    }

    /**
     * Method to check cluster version is >= 6.2.
     *
     * @method   checkUpgradability
     * @param    {string} clusterVersion   The cluster software version.
     * @return   {boolean}                 whether cluster needs to be upgraded
     *                                     or not.
     */
    function checkUpgradability(clusterVersion) {
      if (clusterVersion && clusterVersion !== undefined) {
        if (clusterVersion.includes('master')) {
          return true;
        }
        clusterVersion = parseFloat(clusterVersion.slice(0, 3));
        return (clusterVersion >= 6.2);
      }
      return false;
    }

    /**
     * launch app review and rating modal
     *
     * @method   launchAppReviewModal
     * @param    {object}   app specific information
     * @return   {Object}   promise to resolve the cSlideModal
     */
    function launchAppReviewModal(app) {
      var modalConfig = {
        size: 'md',
        resolve: {
          innerComponent: 'reviewRatingModal',
          actionButtonKey: 'submit',
          hideModalHeaderCloseBtn: true,
          bindings: {
            app: app
          }
        },
        autoHeight: true,
      };
      return SlideModalService.newModal(modalConfig);
    }

    /**
     * Method to post app review and ratings
     *
     * @method   postAppReview
     * @param    {object} params    app specific params needed to post app
     *                              review
     * @param    {object} payload   input to post app review
     * @return   {promise}          returns a promise resolved with app review
     *                              submission success else rejected with error
     */
    function postAppReview(params, payload) {
      return $http({
        method: 'post',
        url: API.mcm('app', params.appId, 'version', params.version, 'review'),
        data: payload,
      }).then(function postAppReviewDone(response) {
        return response;
      });
    }

    /**
     * download latest app package of the specific application.
     *
     * @method    downloadAppPackage
     * @param     {object} appId   app information.
     * @return    {promise}        returns a promise which downloads
     *                             app package
     */
    function downloadAppPackage(app) {
      return $http({
        method: 'get',
        url: API.mcm('appstore/app-package/apps', app.appId, 'version', app.versionId),
      }).then(function getAppPackage(response) {
        if (response.data.signed_url !== undefined) {
          UserService.getMcmUser().then(
            function getUserInfo(response) {
              var userDetails = response.user.salesforceAccount;
              var value = {
                sid: response.user.sid,
                userId: userDetails.userId,
                accountId: userDetails.accountId,
                appId: app.appId,
                appState: app.appState,
                appVersion: app.versionId
              };
            }
          );

          // Downloads app package file.
          return $window.open(response.data.signed_url, '_blank');
        }
        return response.data;
      });
    }

  }
})(angular);
