import { Observable } from 'rxjs';

/**
 * The user caret or cursor location.
 *
 * @example
 * cursor before/after some character.
 * "|ABCD"  -> { start: 0, end: 0 }
 * "A|BCD"  -> { start: 1, end: 1 }
 * "AB|CD"  -> { start: 2, end: 2 }
 * "ABC|D"  -> { start: 3, end: 3 }
 * "ABCD|"  -> { start: 4, end: 4 }
 *
 * multiple character are selected and cursor at `]` place currently.
 * "A[BC]D" -> { start: 1, end: 3 }
 */
export interface CursorLocation {
  /** The start index of cursor */
  start: number;
  /** The end index of cursor */
  end: number;
}

/**
 * The suggestion type which can error or success.
 */
export enum SuggestionType {
  error = 'error',
  success = 'success',
}

/**
 * The suggestion result.
 */
export interface Result {
  /** The translation key */
  label: string;

  /** The value if fetched it from the backend */
  value?: string;

  /** The resultant search query when the result is selected */
  modelValue?: string;

  /** The label containing the highlighted & the remaining text after user search  */
  labelParts?: { highlighted?: string; remaining?: string };
}

/**
 * The suggestion.
 */
export interface Suggestion {
  /** Type of the suggestion which can be error or success */
  type: SuggestionType;

  /** The currently selected text AST node test */
  text: string;

  /** Optional field indicates the attributer value the user is trying to fill. */
  field?: string;

  /**
   * The location of the text within the search query which is used to replace with new selected
   * values by the user from the autocomplete dropdown.
   */
  textLocation?: CursorLocation;

  /** The list of all possible formats for a given suggestion fetched from the grammar */
  results: Result[];

  /** The possible values fetched by making API calls using getSuggestions input */
  resultValues?: Result[];

  /** The error message produced by ANTLR DefaultErrorStrategy. */
  errorMessage?: string;
}

/**
 * The Advanced Search parameters used during setting up of auto-suggestions.
 * Used to enforce a common interface accross implementations of AdvancedSearchSuggester
 * abstract class.
 */
export interface AdvancedSearchParams {
  /** The suggestion. */
  suggestion: Suggestion;

  /** The current query input by user. */
  searchQuery: string;
}

/**
 * The get suggestion fn type used to fetch possible values for current suggested suggestion found from the grammar.
 */
export type GetSuggestionFn = (suggestion: Suggestion) => Observable<Suggestion>;

/**
 * The translation fn to get translations for results labels.
 */
export type TranslationResultFn = (result: Result) => string;

/**
 * The get raw guggestion function used to get suggestions for value formats.
 */
export type GetRawSuggestionFn = (searchQuery: string, cursorLocation: CursorLocation) => AdvancedSearchParams;
