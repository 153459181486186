import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { PillarItem } from '../../app-pillars.model';
import { IrisContextService, flagEnabled, getEntitlementInfo } from '../../../iris-context';
import { BannerExpirationType } from '../../../banner-configs/configs/crm-entitlement-banners/crm-entitlement.model';
import { appConfigMap } from '../../../app-configs';

/**
 * App entitlement banner component.
 */
@Component({
  selector: 'coh-app-entitlement-banner',
  templateUrl: './app-entitlement-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppEntitlementBannerComponent implements OnInit {
  /** App data. */
  @Input() app: PillarItem;

  /**
   * Used to conditionally display the notification
   */
  showEntitlementNotification = false;

  /**
   * Message is dynamic depending on expiration status
   */
  entitlementNotificationMessage: string;

  constructor(
    private irisContext: IrisContextService,
    protected translateService: TranslateService) {}

  ngOnInit(): void {
    const appConfig = Object.values(appConfigMap).find((value) => value.app === this.app.app);

    if (flagEnabled(this.irisContext.irisContext, 'bannerNotificationCrmService') && appConfig?.entitlements?.length) {
      const entitlementInfos = [];

      // each app can have multiple entitlement keys
      appConfig.entitlements.forEach(key => {
        const entitlementInfo = getEntitlementInfo(this.irisContext.irisContext, key);
        if (entitlementInfo) {
          entitlementInfos.push(entitlementInfo);
        }
      });

      // find the first expired one.  We do not show multiple at the same time.
      const anExpiredSubscription = entitlementInfos.find(info => !info.isActive);
      const bannerData = anExpiredSubscription.banner || {};

      if (bannerData.showBanner && bannerData.bannerType === BannerExpirationType.Expired) {
        this.showEntitlementNotification = true;
        this.entitlementNotificationMessage = this.translateService.instant(
          anExpiredSubscription.isFreeTrial ? 'subscriptionBanner.freeTrialExpired' : 'subscriptionBanner.expired',
          { appName: anExpiredSubscription.productDisplayName });
      }
    }
  }
}
