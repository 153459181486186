import { AttributeFilter, ComponentsServiceApi, InFilterParams, TimeRangeFilterParams } from '@cohesity/api/reporting';
import { ValueFilterSelection } from '@cohesity/helix';
import moment from 'moment-timezone';

import { ReportFilterType } from './filters-types';

/**
 * UI defined report filter parameter value.
 */
export type ReportParameterValue = (ValueFilterSelection | string)[] | TimeRangeFilterParams;

/**
 * UI defined report filter parameter.
 */
export type ReportParameters = { [key in ReportFilterType]?: ReportParameterValue };

/**
 * UI defined component name. Value is component ID used for API call.
 */
export enum ReportComponentName {
  protectedObjectsAll = '000',
  protectedObjectsByType = '001',
  protectedObjectsStatus = '002',
  protectedObjectsSummary = '003',
  serviceConsumptionAll = '500',
  serviceConsumptionSummary = '501',
  serviceConsumptionByEnvironment = '502',
  serviceConsumptionTopConsumers = '505',
  serviceConsumptionTopMovers = '506',
  externalTargetAll = '1600',
  externalTargetSummary = '1602',
  externalTargetTrend = '1601',
}

/**
 * Gets API filter parameter object for different filter types.
 *
 * @param    key    Filter key name.
 * @param    value  Filter values.
 * @returns  Filter parameter object.
 */
export function getApiFilterParam(key: string, value: ReportParameterValue): AttributeFilter {
  if (key === 'date') {
    if (!value) {
      return null;
    }
    return {
      attribute: key,
      filterType: 'TimeRange',
      timeRangeFilterParams: value as TimeRangeFilterParams,
    };
  }

  if (!(value as any[])?.length) {
    return null;
  }

  const inFilterParams: InFilterParams = {
    attributeDataType: 'String'
  };

  if ((value[0] as ValueFilterSelection)?.value) {
    inFilterParams.stringFilterValues = (value as ValueFilterSelection[]).map(v => v.value as string);
    inFilterParams.attributeLabels = (value as ValueFilterSelection[]).map(v => v.label);
  } else {
    inFilterParams.stringFilterValues = [...(value as string[])];
  }
  return {
    attribute: key,
    filterType: 'In',
    inFilterParams,
  };
}

/**
 * Gets report API parameters from component name and UI parameters.
 *
 * @param    name    Component name.
 * @param    params  UI parameters.
 * @returns  API request parameters.
 */
export function getApiParams(
  name: ReportComponentName,
  params: ReportParameters,
): ComponentsServiceApi.GetComponentPreviewParams {
  const filters = [];

  Object.keys(params).forEach(key => {
    const filterParam = getApiFilterParam(key, params[key]);

    if (filterParam) {
      filters.push(filterParam);
    }
  });
  return {
    id: name,
    body: {
      filters,
      sort: null,
      timezone: moment.tz.guess(),
      limit: {
        size: 10000
      }
    }
  };
}
