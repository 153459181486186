// Component: New Group (Security Group/ResourceGroup etc) selector dropdown

;(function(angular) {
  'use strict';

  angular.module('C.cloudEditionManager')
    .component('ceGroupSelector', {
      bindings: {
        /**
         * Config object with the following properties
         *
         * addNewKey             {String}   The string(key) to display for "Add
         *                                  New Group" button
         * addNewDisableTooltip  {String}   The tooltip(key) to display if "Add
         *                                  New Group" button is disabled
         * modelId               {String}   The key to set in the model with
         *                                  group value
         * modalLabel            {String}   The label(key) to display in "Add
         *                                  New Group" modal
         * cloud                 {String}   Cloud type (azure/aws etc.)
         * groupName             {String}   The name of Group to be displayed
         * groupId               {String}   The Id to be used while setting
         *                                  group id value on model
         * groupNameId           {String}   The Name to be used while setting
         *                                  group name value on model
         * suffix                {String}   Any additional note to be dispalyed
         *                                  below the dropdown
         * disable               {Boolean}  Disable the dropdown if True
         * addNewDisableTooltip  {String}   Tooltip to display if "Add New" is
         *                                  disabled
         * groups                {Object[]} The list to be displayed in the
         *                                  dropdown
         * selectGroup           {Function} Callback function on group selection
         */
        config: '=',

        /**
         * the deployment config for the CE
         *
         * @type {Object}
         */
        deploymentConfig: '=',
      },
      require: 'ngModel',
      templateUrl: 'app/platform/cloud-edition-manager/ce-group-selector.html',
      controller: 'ceGroupSelectorCtrl',
    })
    .controller('ceGroupSelectorCtrl', ceGroupSelectorCtrlFn);

  /**
   * @ngdoc component
   * @name C.cloudEditionManager:ceGroupSelector
   * @function
   *
   * @description
   * Select or Add a new Security/Resource Group for CE
   *
   * example
      <ce-group-selector
        name="computeResourceGroup"
        config="{
          addNewDisableTooltip: 'cloudEdition.azure.disableAddNewCrgTooltip',
          addNewKey: 'newComputeResourceGroup',
          cloud: 'azure',
          createPlaceholder: 'MyComputeResourceGroup',
          disable: !$ctrl.deployTarget.deploymentConfig.regionId,
          groupName: 'computeResourceGroup',
          groupId: 'resourceGroupId',
          groups: $ctrl.computeResourceGroups,
          modalLabel: 'computeResourceGroupName',
          modalTitle: 'createNewAzureComputeResourceGroup',
          modelId: 'computeResourceGroupName',
          selectGroup: $ctrl.selectComputeResourceGroup,
        }"
        deployment-config=
          "$ctrl.deployTarget.deploymentConfig"
        ></ce-group-selector>
   */
  function ceGroupSelectorCtrlFn(_, SlideModalService) {

    var $ctrl = this;

    _.assign($ctrl, {
      // methods
      addNewGroup: addNewGroup,
      isNewGroupSelected: isNewGroupSelected,
      selectGroup: selectGroup,
    });

    /**
     * Opens a modal to add a new security group to AWS
     *
     * @method  addNewGroup
     */
    function addNewGroup() {
      var modalConfig = {
        resolve: {
          innerComponent: 'cloudEditionNewGroup',
          actionButtonKey: false,
          closeButtonKey: false,
          titleKey: $ctrl.config.modalTitle,
          bindings: {
            placeholder: $ctrl.config.createPlaceholder,
            group: $ctrl.config.groupName,
            cloud: $ctrl.config.cloud,
            availableGroups: ($ctrl.config.groups || [])
              .map((group) => group._displayName),
          },
        },
        autoHeight: true,
      };

      SlideModalService.newModal(modalConfig)
        .then(function addedGroup(resp) {
          var obj = {
            tag: resp,
            _displayName: resp,
          };

          obj[$ctrl.config.groupId] = $ctrl.config.multiple ? null : resp;

          $ctrl.config.groups.push(obj);

          _newGroupSelected(obj);
        });
    }

    /**
     * If new Group is selected, invalidate all other security groups
     *
     * @method _newGroupSelected
     * @param {Object} name New Group Object
     * @para
     */
    function _newGroupSelected(group) {
      var name = group._displayName;

      if ($ctrl.config.multiple) {
        $ctrl.deploymentConfig[$ctrl.config.modelId] = [null];
        if ($ctrl.config.groupNameId) {
          $ctrl.deploymentConfig[$ctrl.config.groupNameId] = [name];
        } else {
          $ctrl.deploymentConfig[$ctrl.config.modelId] = [name];
        }
      } else {
        $ctrl.deploymentConfig[$ctrl.config.modelId] = null;
        if ($ctrl.config.groupNameId) {
          $ctrl.deploymentConfig[$ctrl.config.groupNameId] = name;
        } else {
          $ctrl.deploymentConfig[$ctrl.config.modelId] = name;
        }
      }

      if (_.isFunction($ctrl.config.selectGroup)) {
        $ctrl.config.selectGroup(group);
      }
    }

    /**
     * Determine whether a New  Group is selected from the dropdown
     *
     * @method  isNewGroupSelected
     * @retrun  {Boolean}  True if a new  group is selected
     */
    function isNewGroupSelected() {
      // for single select, it doesn;t matter if a new group is selected or not
      if (!$ctrl.config.multiple) {
        return;
      }

      var deploymentConfig = $ctrl.deploymentConfig;

      return deploymentConfig[$ctrl.config.modelId] === null ||
        (_.get(deploymentConfig, $ctrl.config.modelId + '[0]') === null &&
          !!_.get(deploymentConfig, $ctrl.config.groupNameId).length);
    }

    /**
     * Callback after Group selection
     *
     * @method  selectGroup
     * @param   {Object}  group  The selected Group Object
     */
    function selectGroup(group) {
      // If a Group with id = null exists, its a "New" Group
      var newGroup = _.find(group, [$ctrl.config.modelId, null]);

      if (newGroup) {
        _newGroupSelected(newGroup._displayName);
        return;
      }

      if ($ctrl.config.groupNameId) {
        $ctrl.deploymentConfig[$ctrl.config.groupNameId] =
          _.isArray(group) ? _.map(group, 'tag') : group.tag;
      }

      if (_.isFunction($ctrl.config.selectGroup)) {
        $ctrl.config.selectGroup(group);
      }
    }
  }

})(angular);