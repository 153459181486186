<cog-settings-list-item
  [formGroup]="formGroup"
  [invalid]="formControl.invalid"
  [label]="'appConsistentBackups' | translate">
  <cog-settings-list-item-value-display>
    <ng-container *ngTemplateOutlet="vmwareToolsWarning"></ng-container>
    <ng-container *ngTemplateOutlet="ngtWarning"></ng-container>
    <ng-container>
      <p>
        {{formControl.value.appConsistentBackups | boolLabel}}
      </p>
      <p *ngIf="showCrashConsistentBackup">
        <strong>{{'crashConsistentBackupsColon' | translate}} </strong>
        {{formControl.value.crashConsistentBackup | boolLabel}}
      </p>
      <p *ngIf="showVssBackupType">
        <strong>{{'vssBackUpTypeColon' | translate}} </strong>
        {{ (vssBackupTypeValue ? 'job.settings.hyperV.vssBackupTypeCopy' : 'job.settings.hyperV.vssBackupTypeFull') | translate }}
      </p>
    </ng-container>
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor [formGroup]="appConsistentBackupsFormGroup"
    cogDataId="settings-app-consistent">
    <ng-container *ngTemplateOutlet="vmwareToolsWarning"></ng-container>
    <ng-container *ngTemplateOutlet="ngtWarning"></ng-container>
    <mat-slide-toggle [inheritDataId]="true"
      cogDataId="app-consistent-toggle"
      formControlName="appConsistentBackups">
      {{'appConsistentBackups' | translate}}
    </mat-slide-toggle>
    <ng-container *ngIf="showCrashConsistentBackup">
      <mat-slide-toggle class="margin-top-sm margin-left"
        [inheritDataId]="true"
        cogDataId="crash-consistent-toggle"
        formControlName="crashConsistentBackup">
        {{'job.settings.crashConsistentFallback' | translate}}
      </mat-slide-toggle>
    </ng-container>
    <ng-container *ngIf="showVssBackupType">
      <mat-slide-toggle class="margin-top-sm margin-left"
        [inheritDataId]="true"
        cogDataId="vss-backup-type-toggle"
        formControlName="vssBackupType">
        {{ (vssBackupTypeValue ? 'job.settings.hyperV.vssBackupTypeCopy' : 'job.settings.hyperV.vssBackupTypeFull') | translate }}
      </mat-slide-toggle>
      <mat-hint class="margin-left">
        {{ (vssBackupTypeValue ? 'protectionGroups.vssBackupTypeFullHint' : 'protectionGroups.vssBackupTypeCopyHint') | translate }}
      </mat-hint>
    </ng-container>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>

<ng-template #vmwareToolsWarning>
  <!-- Show a list of all VM names where VMware tools is not installed -->
  <div *ngIf="appConsistentBackupsValue && missingVmwareToolsObjects.size"
    class="c-message-inline icon-message warn">
    <h6>{{'quiesceConflictModal.title' | translate}}</h6>
    <p>
      {{'quiesceConflictModal.text' | translate}}
    </p>
    <ul>
      <li *ngFor="let missingVmwareToolsObject of missingVmwareToolsObjects">
        {{missingVmwareToolsObject}}
      </li>
    </ul>
    <p class="margin-top-sm">
      <a (click)="enableCrashConsistent()"
        *ngIf="!crashConsistentBackupValue">
        {{'job.settings.crashConsistentFallback' | translate}}
      </a>
    </p>
  </div>
</ng-template>

<ng-template #ngtWarning>
  <!-- Show a list of all VM names where Nutanix Guest Tool is not installed -->
  <div *ngIf="appConsistentBackupsValue && missingNgtObjects.size"
    class="c-message-inline icon-message warn">
    <h6>{{'ngtNotFound.title' | translate}}</h6>
    <p>
      {{'ngtNotFound.text' | translate}}
    </p>
    <ul>
      <li *ngFor="let missingNgtObject of missingNgtObjects">
        {{missingNgtObject}}
      </li>
    </ul>
    <p class="margin-top-sm">
      <a (click)="enableCrashConsistent()"
        *ngIf="!crashConsistentBackupValue">
        {{'job.settings.crashConsistentFallback' | translate}}
      </a>
    </p>
  </div>
</ng-template>
