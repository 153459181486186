import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { takeUntil, startWith, filter } from 'rxjs/operators';
import { AutoDestroyable } from '@cohesity/utils';
import { LocalizedEmail, RecipientType } from 'src/app/models';

/**
 * This component lets the user enter an email with a corresponding locale for
 * localization of emails that are being sent.
 *
 * @example
 *   <coh-localized-email [(model)]="emailWithLocale"></coh-localized-email>
 */
@Component({
  selector: 'coh-localized-email',
  templateUrl: './localized-email.component.html'
})
export class LocalizedEmailComponent extends AutoDestroyable implements OnInit {

  constructor() {
    super();
  }

  /**
   * The validated email address model.
   */
  emailAddress = new UntypedFormControl('', [ Validators.required, Validators.email ]);

  /**
   * Recipient type form control.
   */
  recipientTypeFormControl = new UntypedFormControl('');

  /**
   * List of supported email recipient types.
   */
  readonly recipientTypes: RecipientType[] = ['kTo', 'kCc'];

  /**
   * The ngModel alias that is to be propagated.
   */
  @Input() model: LocalizedEmail;

  /**
   * If you need to hide the floating label, set this to true.
   */
  @Input() hideLabel?: boolean;


  /**
   * If you need the email without locale, set this to true.
   */
  @Input() withoutLocale?: boolean;

  /**
   * Whether send type dropdown(to/cc) should be enabled.
   */
  @Input() enableRecipientType?: boolean;

  /**
   * The change fn which gets called when the model is changed hence
   * propagating the changes.
   */
  @Output() modelChange = new EventEmitter<LocalizedEmail>();

  /**
   * Initialization function
   */
  ngOnInit() {
    this.intializeEmailAddress();
  }

  /**
   * Intitializes the email address and attaches a listener to update the
   * external value.
   */
  private intializeEmailAddress() {
    this.recipientTypeFormControl.setValue(this.model.recipientType || this.recipientTypes[0]);
    this.emailAddress.setValue(this.model.emailAddress);
    this.emailAddress.valueChanges
      .pipe(takeUntil(this._destroy),
        filter(() => this.emailAddress.valid))
      .subscribe(email => {
        this.model.emailAddress = email;
      });

    // Subscribe to send type selection changes if its enabled.
    if (this.enableRecipientType) {
      this.recipientTypeFormControl.valueChanges
        .pipe(takeUntil(this._destroy),
          startWith(this.recipientTypeFormControl.value))
        .subscribe(recipientType => {
          this.model.recipientType = recipientType;
        });
    }
  }
}
