// Defining custom ui-routers url parameter matchers.

;(function(angular, undefined) {

  angular.module('C.appConfigs')
    .config(cUiRouterMatchers);

  /**
   * Defining the custom url-matcher type used to parse the url parameters into
   * some data structure like int/bool/number/data json etc.
   *
   * Matcher Type         Definition
   * ----------------------------------------
   * string               string values
   * int                  Mandatory int value
   * cNum                 Optional number value
   * bool                 Mandatory boolean value
   * cBool                Optional boolean value
   * cBoolAsNum           Optional boolean value as number 0: falsy & 1: true
   * date                 Mandatory date value
   * json                 Mandatory json value
   * any                  default any type
   *
   * NOTE:- Mandatory types would not load the state at all if parameter type
   * validation failed to preserve an invalid parameter value in the url use
   * cNum, cBool or cBoolAsNum.
   *
   * @method   cUiRouterMatchers
   * @param    {Object}  $urlServiceProvider   The Url Service Provider
   */
   function cUiRouterMatchers($urlServiceProvider) {
    /**
     * Defining a custom cNum type used to get and set an number value of the
     * parameters in the url as JS Number type.
     *
     * @example
     * test value             ?{sla-violation:cNum}     ?{sla-violation:int}
     * ?sla-violation=12      12                        12
     * ?sla-violation=12abc   '12abc' (invalid)         12
     * ?sla-violation=abc     'abc' (invalid)           will not load the state
     */
    $urlServiceProvider.config.type('cNum', {
      decode: function(val) {
        var numberVal = parseFloat(val, 10);

        // keep the original value if decoded value is not a number or is a mix
        // of number followed by string.
        if (Number.isNaN(numberVal) || String(numberVal) !== val) {
          return val;
        }

        return numberVal;
      },
    });

    /**
     * Return the Boolean value decoder fn which return given truthyValue for
     * truthy values else falsyValue for falsy values.
     *
     * @method   getBooleanDecoder
     * @param    {Object}     truthyValue   The truthy value
     * @param    {Object}     falsyValue    The falsy value
     * @return   {Function}   The boolean decoder fn.
     */
    function getBooleanDecoder(truthyValue, falsyValue) {
      var truthyValRegex = /^(1|true)$/i;
      var falsyValRegex = /^(0|false)$/i;

      /**
       * Return the truthyValue if given value is truthy else return falsyValue.
       *
       * @method   getBooleanDecoder
       * @param    {String}     val   The val to parse.
       * @return   {Any}   Returns the truthyValue or falsyValue as per provided
       *                   value.
       */
      return function booleanDecoder(val) {
        var numberVal = parseFloat(val, 10);

        if (Number.isNaN(numberVal)) {
          if (truthyValRegex.test(val)) {
            return truthyValue;
          } else if (falsyValRegex.test(val)) {
            return falsyValue;
          }

          // return the original value to preserve invalid value in the url.
          return val;
        }

        return !!numberVal;
      };
    }

    /**
     * Defining a custom cBool type used to get and set an boolean value of the
     * parameters in the url as true or false.
     *
     * @example
     * url: '?{sla-violation:cBool}'
     *                        '$stateParams.sla-violation' value would be
     * ?sla-violation=0       false
     * ?sla-violation=1       true
     * ?sla-violation=12      true
     * ?sla-violation=true    true
     * ?sla-violation=false   false
     * ?sla-violation=abc     'abc' invalid value so keep the original.
     */
    $urlServiceProvider.config.type('cBool', {
      decode: getBooleanDecoder(true, false),
    });

    /**
     * Defining a custom cBoolAsNum type used to get and set an boolean value
     * of the parameters in the url as 0 or 1.
     *
     * @example
     * url: '?{sla-violation:cBoolAsNum}'
     *                        '$stateParams.sla-violation' value would be
     * ?sla-violation=0       0
     * ?sla-violation=1       1
     * ?sla-violation=12      1
     * ?sla-violation=true    1
     * ?sla-violation=false   0
     * ?sla-violation=abc     'abc' invalid value so keep the original.
     */
    $urlServiceProvider.config.type('cBoolAsNum', {
      decode: getBooleanDecoder(1, 0),
    });

  }
})(angular);
