import { Injectable } from '@angular/core';
import { VlanParameters } from '@cohesity/api/v1';
import { CommonRecoverObjectSnapshotParams, FilterIpConfig, RecoverProtectionGroupRunParams } from '@cohesity/api/v2';
import { FilterIps } from 'src/app/modules/protection/protection-builder/models';
import { ClusterInterface } from 'src/app/shared/models';

import { ObjectSearchResult } from '../model/object-search-result';
import { ProtectionGroupSearchResult } from '../model/protection-group-search-result';
import { RestorePointSelection } from '../model/restore-point-selection';

@Injectable({
  providedIn: 'root',
})
export class TransformerUtilsService {
  /**
   * Filters the selection list for groups and converts the results to a list of protection group params.
   *
   * @param   selection   The items selected in the form.
   * @returns Protection group params, or an empty list.
   */
  getGroupParams(selection: RestorePointSelection[]): RecoverProtectionGroupRunParams[] {
    return (selection || [])
      .filter(item => item.objectInfo.resultType === ProtectionGroupSearchResult.protectionGroupResultType)
      .map(group => ({
        protectionGroupRunId: group.restorePointId,
        protectionGroupInstanceId: group.runInstanceId,
        archivalTargetId: group.archiveTargetInfo && group.archiveTargetInfo.targetId,
        protectionGroupId: group.localProtectionGroupId,
      }));
  }

  /**
   * Filters the selection for objects and returns a list of snapshot ids for the selected objects.
   *
   * @param   selection   The items selected in the form.
   * @resturns  A list of object snapshot ids.
   */
  getObjectSnapshots(selection: RestorePointSelection[]): CommonRecoverObjectSnapshotParams[] {
    return (selection || [])
      .filter(item => item.objectInfo.resultType === ObjectSearchResult.objectResultType)
      .map(object =>
        Object.assign(
          { snapshotId: object.restorePointId },
          object.isPointInTime ? { pointInTimeUsecs: object.timestampUsecs } : null
        )
      );
  }

  /**
   * Function to return the vlan parameters for a restore task based on the
   * provided cluster interface. This function mimics the AJS implementation
   * for returning vlan config.
   *
   * @param clusterInterface Form value of cluster interface.
   * @return the vlan parameters.
   */
  getVlanConfig(clusterInterface: ClusterInterface): VlanParameters {
    // If there is an ID on the object add the vlanId param to the API object
    // else we need to check explicitly if the ID is null because its the ID
    // for DO NOT USE VLAN and a seperate property (disableVlan) must be
    // passed.
    let result;

    if (!clusterInterface) {
      return null;
    }

    if (!clusterInterface.auto && clusterInterface.interfaceGroup) {
      if (clusterInterface.interfaceGroup.id === null) {
        result = {
          disableVlan: true,
        };
      } else {
        result = {
          id: clusterInterface.interfaceGroup.id,
          interfaceName: clusterInterface.interfaceGroup.ifaceGroupName.split('.')[0],
        };
      }
    }

    return result;
  }

  /**
   * Transforms filterIps protection api config to form model.
   *
   * @param   apiConfig   filter ips api config
   */
  untransformFilterIps(apiConfig: FilterIpConfig): FilterIps {
    if (!apiConfig) {
      return;
    }
    const {allowedIpAddresses: allowedIps, deniedIpAddresses: deniedIps} = apiConfig;
    const formConfig: FilterIps = {
      enabled: Boolean(allowedIps || deniedIps)
    };

    if (allowedIps?.length > 0) {
      formConfig.filterIpConfig = {
        filterType: 'allow',
        ipList: allowedIps,
      };
    } else {
      formConfig.filterIpConfig = {
        filterType: 'deny',
        ipList: deniedIps,
      };
    }

    return formConfig;
  }

  /**
   * Transforms form model of filter ips to protection api config.
   *
   * @param   formConfig   Form moodel filter ips config
   */
  transformFilterIps(formConfig: FilterIps): FilterIpConfig {
    if (!formConfig || !formConfig.enabled) {
      return;
    }
    const apiConfig: any = {};
    const {filterType, ipList} = formConfig.filterIpConfig;

    if (filterType === 'allow') {
      apiConfig.allowedIpAddresses = ipList;
    } else if (filterType === 'deny')  {
      apiConfig.deniedIpAddresses = ipList;
    }

    return apiConfig;
  }
}
