import { Component, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AutoDestroyable } from '@cohesity/utils';
import { IpAddressFamily } from 'src/app/shared/constants';
import { DnsVipsEntryDialogComponent } from '../dns-vips-entry-dialog/dns-vips-entry-dialog.component';
import { DnsDelegationZone } from '../internal-dns.models';

/**
 * This component is to add/edit/remove internal dns server to vips entries mapping.
 *
 * @example
 *   <coh-dns-vips-entry-list [(model)]="emailWithLocaleArray">
 *   </coh-dns-vips-entry-list>
 */
@Component({
  selector: 'coh-dns-vips-entry-list',
  templateUrl: './dns-vips-entry-list.component.html',
  styleUrls: ['./dns-vips-entry-list.component.scss'],
})
export class DnsVipsEntryListComponent extends AutoDestroyable {
  /**
   * List of dns delegation zone entries.
   */
  @Input() model: DnsDelegationZone[];

  /**
   * IP address preference of the vip address entries.
   */
  @Input() ipPreference?: IpAddressFamily;

  constructor(
    private dialog: MatDialog
  ) {
    super();
  }

  /**
   * Remove model specified by index.
   *
   * @param   index  Index to remove model at.
   */
  removeRow(index: number) {
    this.model.splice(index, 1);
  }

  /**
   * Adds a new row with dns server and vips entries inputs or edit and existing one.
   */
  addEditRow(index: number = -1) {
    const data = {
      model: null,
      ipPreference: this.ipPreference,
      index
    };
    if (index > -1) {
      data.model = this.model[index];
      data.index = index;
    }
    this.dialog.open(DnsVipsEntryDialogComponent, {
      data,
      width: '40rem'
    }).afterClosed().pipe(this.untilDestroy()).subscribe(res => {
      if (res) {
        if (index > -1) {
          this.model[index] = res;
        } else {
          this.model.push(res);
        }
      }
    });
  }
}
