// Module: Remote Cluster View Box pairing

;(function(angular, undefined) {
  'use strict';

  var configOptions = {
    bindings: {
      cluster: '=',
      inViewMode: '=?',
    },
    controller: 'ViewBoxPairingCtrl',
    templateUrl: 'app/remote-clusters/modify/view-box-pairing.html',
  };

  angular.module('C.remoteClusters')
    .controller('ViewBoxPairingCtrl', viewBoxPairingCtrlFn)
    .component('viewBoxPairing', configOptions);

  function viewBoxPairingCtrlFn(_, $q, ViewBoxService) {
    var $ctrl = this;

    _.assign($ctrl, {
      // UI states
      isLoading: false,
      error: null,
      localViewBoxList: [],
      newPairingForm: undefined,
      remoteViewBoxList: [],
      newViewBoxPair: {
        local: undefined,
        remote: undefined,
      },

      // component methods used in the view
      updateViewBoxPair: updateViewBoxPair,
      addNewViewBoxPair: addNewViewBoxPair,
      deleteViewBoxPair: deleteViewBoxPair,
      isLocalViewBoxSelected: isViewBoxSelected('localViewBoxId'),
      isRemoteViewBoxSelected: isViewBoxSelected('remoteViewBoxId'),

      // component life cycle methods
      $onInit: $onInit,
    });

    // Watch for the changes in _validConnection
    // Update viewBox list when connection is refreshed
    (() => {
      let currentValidConnection;
      $ctrl.$doCheck = function $doCheck() {
        if (!$ctrl.inViewMode && !currentValidConnection &&
          $ctrl.cluster._validConnection) {
          getViewboxesList();
        }
        currentValidConnection = $ctrl.cluster._validConnection;
      };
    })();

    /**
     * initialize the component
     *
     * @method   $onInit
     */
    function $onInit() {
      if (!$ctrl.cluster.viewBoxPairInfo) {
        $ctrl.cluster.viewBoxPairInfo = [];
      }

      // We don't validate the connection for view mode, so call
      // getViewboxesList in onInit
      if ($ctrl.inViewMode) {
        getViewboxesList();
      }
    }

    /**
     * Adds a new viewbox pairing to the list.
     *
     * @method   addNewViewBoxPair
     */
    function addNewViewBoxPair() {
      $ctrl.cluster.viewBoxPairInfo.push({
        localViewBoxId: null,
        localViewBoxName: null,
        remoteViewBoxId: null,
        remoteViewBoxName: null,
      });
    }

    /**
     * deletes a View Box Pairing from the list.
     *
     * @method   deleteViewBoxPair
     * @param    {number}   indexToRemove   The index of View Box pair to remote
     */
    function deleteViewBoxPair(indexToRemove) {
      $ctrl.cluster.viewBoxPairInfo.splice(indexToRemove, 1);
    }

    /**
     * Update local and remote viewbox id and name from selected local and remote viewboxes.
     *
     * @method   updateViewBoxPair
     * @param    {Object}    viewBoxPair   The view box pair information.
     */
    function updateViewBoxPair(viewBoxPair) {
      if (viewBoxPair._localViewBox) {
        viewBoxPair.localViewBoxId = viewBoxPair._localViewBox.id;
        viewBoxPair.localViewBoxName = viewBoxPair._localViewBox.name;
      }

      if (viewBoxPair._remoteViewBox) {
        viewBoxPair.remoteViewBoxId = viewBoxPair._remoteViewBox.id;
        viewBoxPair.remoteViewBoxName = viewBoxPair._remoteViewBox.name;
      }
    }

    /**
     * Returns a function which does determines whether a View Box is already a
     * part of View Box Pair or not.
     * if key is 'localViewBoxId' the returned function will perform test for
     * local View Box presence and if 'remoteViewBoxId' then perform test for
     * remote View Box presence.
     *
     * @method   isViewBoxSelected
     * @param    {string}    key       The key to be looked into viewBoxPair, it
     *                                 can be localViewBoxId or remoteViewBoxId.
     * @return   {function}  A function which when called perform the test for
     *                       of View Box presence in View Box Pair.
     */
    function isViewBoxSelected(key) {
      /**
       * Determines whether a View Box is already a part of View Box Pair or not
       *
       * @method   isSelected
       * @param    {object}    viewBox   The View Box to test
       * @return   {boolean}   True if viewBox is selected otherwise False.
       */
      return function isSelected(viewBox) {
        return $ctrl.cluster.viewBoxPairInfo.find(
          function eachViewBox(viewBoxPair) {
            return viewBoxPair[key] === viewBox.id;
          }
        );
      };
    }

    /**
     * fetch list of local & remote cluster ViewBoxes list
     *
     * @method   getViewboxesList
     */
    function getViewboxesList() {
      var getViewBoxesPromises = {
        local: ViewBoxService.getViewBoxes(),
        remote: ViewBoxService.getRemoteViewBoxes(
          !$ctrl.cluster.password ? $ctrl.cluster.clusterId : $ctrl.cluster
        ),
      };

      $ctrl.isLoading = true;

      $q.allSettled(getViewBoxesPromises)
        .then(function viewBoxsFetched(responses) {
          $ctrl.localViewBoxList = responses.local.resp;
          if (responses.remote.$status === 'error') {
            $ctrl.error = responses.remote.resp;
            $ctrl.remoteViewBoxList = [];
            $ctrl.cluster._validConnection = false;
          } else {
            $ctrl.error = null;
            $ctrl.remoteViewBoxList = responses.remote.resp;
            $ctrl.cluster._validConnection = true;
          }

          var localViewBoxesByIds = _.keyBy($ctrl.localViewBoxList, 'id');
          var remoteViewBoxesByIds = _.keyBy($ctrl.remoteViewBoxList, 'id');

          ($ctrl.cluster.viewBoxPairInfo || []).forEach(
            function eachPair(viewBoxPair) {
              viewBoxPair._localViewBox = localViewBoxesByIds[viewBoxPair.localViewBoxId];
              viewBoxPair._remoteViewBox = remoteViewBoxesByIds[viewBoxPair.remoteViewBoxId];
            }
          );
        })
        .finally(function afterFetchingViewBoxs() {
          $ctrl.isLoading = false;
        });
    }
  }

})(angular);
