/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityAgentDeploymentApiConfiguration as __Configuration } from '../cohesity-agent-deployment-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AgentDeployments } from '../models/agent-deployments';
import { AgentDeployment } from '../models/agent-deployment';
import { HostObjects } from '../models/host-objects';
import { HostObject } from '../models/host-object';
import { SuccessResponseBody } from '../models/success-response-body';
import { Notifications } from '../models/notifications';
import { NotificationsFlags } from '../models/notifications-flags';
@Injectable({
  providedIn: 'root',
})
class AgentDeploymentsService extends __BaseService {
  static readonly GetAgentDeploymentsPath = '/deployments';
  static readonly CreateAgentDeploymentPath = '/deployments';
  static readonly GetAgentDeploymentByIdPath = '/deployments/{id}';
  static readonly GetAgentDeploymentObjectsPath = '/deployments/{id}/objects';
  static readonly GetAgentDeploymentObjectsByIdPath = '/deployments/{id}/objects/{objectId}';
  static readonly GetHostObjectsPath = '/objects';
  static readonly GetHostObjectsByIdPath = '/objects/{id}';
  static readonly HealthCheckPath = '/heartbeat';
  static readonly GetNotificationsPath = '/notifications';
  static readonly UpdateNotificationsFlagsPath = '/notifications/setFlags';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns a list of AgentDeployment objects
   *
   * Returns a list of AgentDeployment objects.
   * @param returnSummary ReturnSummary specifies if only basic properties
   * should be returned for Deployments instead of complete data such
   * as data about Runs.
   * @return List of AgentDeployments
   */
  GetAgentDeploymentsResponse(returnSummary?: boolean): __Observable<__StrictHttpResponse<AgentDeployments>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (returnSummary != null) __params = __params.set('returnSummary', returnSummary.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/deployments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AgentDeployments>;
      })
    );
  }
  /**
   * Returns a list of AgentDeployment objects
   *
   * Returns a list of AgentDeployment objects.
   * @param returnSummary ReturnSummary specifies if only basic properties
   * should be returned for Deployments instead of complete data such
   * as data about Runs.
   * @return List of AgentDeployments
   */
  GetAgentDeployments(returnSummary?: boolean): __Observable<AgentDeployments> {
    return this.GetAgentDeploymentsResponse(returnSummary).pipe(
      __map(_r => _r.body as AgentDeployments)
    );
  }

  /**
   * Creates a new Agent deployment task
   *
   * Creates a new Agent deployment task and returns a task id.
   * @param params The `AgentDeploymentsService.CreateAgentDeploymentParams` containing the following parameters:
   *
   * - `body`: Request body to create a agent deployment task.
   *
   * - `sid`: SID of the logged in user.
   *
   * @return Success
   */
  CreateAgentDeploymentResponse(params: AgentDeploymentsService.CreateAgentDeploymentParams): __Observable<__StrictHttpResponse<AgentDeployment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.sid != null) __headers = __headers.set('sid', params.sid.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/deployments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AgentDeployment>;
      })
    );
  }
  /**
   * Creates a new Agent deployment task
   *
   * Creates a new Agent deployment task and returns a task id.
   * @param params The `AgentDeploymentsService.CreateAgentDeploymentParams` containing the following parameters:
   *
   * - `body`: Request body to create a agent deployment task.
   *
   * - `sid`: SID of the logged in user.
   *
   * @return Success
   */
  CreateAgentDeployment(params: AgentDeploymentsService.CreateAgentDeploymentParams): __Observable<AgentDeployment> {
    return this.CreateAgentDeploymentResponse(params).pipe(
      __map(_r => _r.body as AgentDeployment)
    );
  }

  /**
   * Returns the AgentDeployment details corresponding to specified job id
   *
   * Returns the AgentDeployment details corresponding to specified job id.
   * @param params The `AgentDeploymentsService.GetAgentDeploymentByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Job.
   *
   * - `returnSummary`: ReturnSummary specifies if only basic properties
   *   should be returned for Deployments instead of complete data such
   *   as data about Runs.
   *
   * @return AgentDeployment as per provided Id
   */
  GetAgentDeploymentByIdResponse(params: AgentDeploymentsService.GetAgentDeploymentByIdParams): __Observable<__StrictHttpResponse<AgentDeployment>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.returnSummary != null) __params = __params.set('returnSummary', params.returnSummary.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/deployments/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AgentDeployment>;
      })
    );
  }
  /**
   * Returns the AgentDeployment details corresponding to specified job id
   *
   * Returns the AgentDeployment details corresponding to specified job id.
   * @param params The `AgentDeploymentsService.GetAgentDeploymentByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Job.
   *
   * - `returnSummary`: ReturnSummary specifies if only basic properties
   *   should be returned for Deployments instead of complete data such
   *   as data about Runs.
   *
   * @return AgentDeployment as per provided Id
   */
  GetAgentDeploymentById(params: AgentDeploymentsService.GetAgentDeploymentByIdParams): __Observable<AgentDeployment> {
    return this.GetAgentDeploymentByIdResponse(params).pipe(
      __map(_r => _r.body as AgentDeployment)
    );
  }

  /**
   * Returns the list of objects of AgentDeployment details
   * corresponding to specified job id.
   *
   * Returns the list of objects of AgentDeployment details
   * corresponding to specified job id.
   * @param params The `AgentDeploymentsService.GetAgentDeploymentObjectsParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Job.
   *
   * - `returnSummary`: ReturnSummary specifies if only basic properties
   *   should be returned for Deployments instead of complete data such
   *   as data about Runs.
   *
   * @return List of HostObjects
   */
  GetAgentDeploymentObjectsResponse(params: AgentDeploymentsService.GetAgentDeploymentObjectsParams): __Observable<__StrictHttpResponse<HostObjects>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.returnSummary != null) __params = __params.set('returnSummary', params.returnSummary.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/deployments/${encodeURIComponent(params.id)}/objects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HostObjects>;
      })
    );
  }
  /**
   * Returns the list of objects of AgentDeployment details
   * corresponding to specified job id.
   *
   * Returns the list of objects of AgentDeployment details
   * corresponding to specified job id.
   * @param params The `AgentDeploymentsService.GetAgentDeploymentObjectsParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Job.
   *
   * - `returnSummary`: ReturnSummary specifies if only basic properties
   *   should be returned for Deployments instead of complete data such
   *   as data about Runs.
   *
   * @return List of HostObjects
   */
  GetAgentDeploymentObjects(params: AgentDeploymentsService.GetAgentDeploymentObjectsParams): __Observable<HostObjects> {
    return this.GetAgentDeploymentObjectsResponse(params).pipe(
      __map(_r => _r.body as HostObjects)
    );
  }

  /**
   * Returns the specific objects of AgentDeployment details
   * corresponding to specified job id.
   *
   * Returns the specific objects of AgentDeployment details
   * corresponding to specified job id.
   * @param params The `AgentDeploymentsService.GetAgentDeploymentObjectsByIdParams` containing the following parameters:
   *
   * - `objectId`: Specifies a unique id of the object.
   *
   * - `id`: Specifies a unique id of the Job.
   *
   * - `returnSummary`: ReturnSummary specifies if only basic properties
   *   should be returned for Deployments instead of complete data such
   *   as data about Runs.
   *
   * @return Host Object By Id
   */
  GetAgentDeploymentObjectsByIdResponse(params: AgentDeploymentsService.GetAgentDeploymentObjectsByIdParams): __Observable<__StrictHttpResponse<HostObject>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.returnSummary != null) __params = __params.set('returnSummary', params.returnSummary.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/deployments/${encodeURIComponent(params.id)}/objects/${encodeURIComponent(params.objectId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HostObject>;
      })
    );
  }
  /**
   * Returns the specific objects of AgentDeployment details
   * corresponding to specified job id.
   *
   * Returns the specific objects of AgentDeployment details
   * corresponding to specified job id.
   * @param params The `AgentDeploymentsService.GetAgentDeploymentObjectsByIdParams` containing the following parameters:
   *
   * - `objectId`: Specifies a unique id of the object.
   *
   * - `id`: Specifies a unique id of the Job.
   *
   * - `returnSummary`: ReturnSummary specifies if only basic properties
   *   should be returned for Deployments instead of complete data such
   *   as data about Runs.
   *
   * @return Host Object By Id
   */
  GetAgentDeploymentObjectsById(params: AgentDeploymentsService.GetAgentDeploymentObjectsByIdParams): __Observable<HostObject> {
    return this.GetAgentDeploymentObjectsByIdResponse(params).pipe(
      __map(_r => _r.body as HostObject)
    );
  }

  /**
   * List of agent deployments filtered by query parameters.
   *
   * Returns a list of filtered Agent deployment status.
   * @param returnSummary ReturnSummary specifies if only basic properties
   * should be returned for Deployments instead of complete data such
   * as data about Runs.
   * @return List of HostObjects
   */
  GetHostObjectsResponse(returnSummary?: boolean): __Observable<__StrictHttpResponse<HostObjects>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (returnSummary != null) __params = __params.set('returnSummary', returnSummary.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/objects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HostObjects>;
      })
    );
  }
  /**
   * List of agent deployments filtered by query parameters.
   *
   * Returns a list of filtered Agent deployment status.
   * @param returnSummary ReturnSummary specifies if only basic properties
   * should be returned for Deployments instead of complete data such
   * as data about Runs.
   * @return List of HostObjects
   */
  GetHostObjects(returnSummary?: boolean): __Observable<HostObjects> {
    return this.GetHostObjectsResponse(returnSummary).pipe(
      __map(_r => _r.body as HostObjects)
    );
  }

  /**
   * Returns details of single job Deployment.
   *
   * Returns the agent deployment details corresponding to specified job id.
   * @param params The `AgentDeploymentsService.GetHostObjectsByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Job.
   *
   * - `returnSummary`: ReturnSummary specifies if only basic properties
   *   should be returned for Deployments instead of complete data such
   *   as data about Runs.
   *
   * @return Host Object By Id
   */
  GetHostObjectsByIdResponse(params: AgentDeploymentsService.GetHostObjectsByIdParams): __Observable<__StrictHttpResponse<HostObject>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.returnSummary != null) __params = __params.set('returnSummary', params.returnSummary.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/objects/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HostObject>;
      })
    );
  }
  /**
   * Returns details of single job Deployment.
   *
   * Returns the agent deployment details corresponding to specified job id.
   * @param params The `AgentDeploymentsService.GetHostObjectsByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Job.
   *
   * - `returnSummary`: ReturnSummary specifies if only basic properties
   *   should be returned for Deployments instead of complete data such
   *   as data about Runs.
   *
   * @return Host Object By Id
   */
  GetHostObjectsById(params: AgentDeploymentsService.GetHostObjectsByIdParams): __Observable<HostObject> {
    return this.GetHostObjectsByIdResponse(params).pipe(
      __map(_r => _r.body as HostObject)
    );
  }

  /**
   * Health check of Controller.
   *
   * Returns success response if Controller is alive.
   * @return Success
   */
  HealthCheckResponse(): __Observable<__StrictHttpResponse<SuccessResponseBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/heartbeat`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SuccessResponseBody>;
      })
    );
  }
  /**
   * Health check of Controller.
   *
   * Returns success response if Controller is alive.
   * @return Success
   */
  HealthCheck(): __Observable<SuccessResponseBody> {
    return this.HealthCheckResponse().pipe(
      __map(_r => _r.body as SuccessResponseBody)
    );
  }

  /**
   * List of notifications for the logged-in user.
   *
   * Returns a list of notifications for the logged-in user.
   * @param sid SID of the logged in user.
   * @return List of Notifications
   */
  GetNotificationsResponse(sid?: string): __Observable<__StrictHttpResponse<Notifications>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (sid != null) __headers = __headers.set('sid', sid.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/notifications`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Notifications>;
      })
    );
  }
  /**
   * List of notifications for the logged-in user.
   *
   * Returns a list of notifications for the logged-in user.
   * @param sid SID of the logged in user.
   * @return List of Notifications
   */
  GetNotifications(sid?: string): __Observable<Notifications> {
    return this.GetNotificationsResponse(sid).pipe(
      __map(_r => _r.body as Notifications)
    );
  }

  /**
   * Updates visited and dismissed flags of a notification.
   *
   * Updates visited and dismissed flags of a notification.
   * @param params The `AgentDeploymentsService.UpdateNotificationsFlagsParams` containing the following parameters:
   *
   * - `body`: Request body to update notification flags.
   *
   * - `sid`: SID of the logged in user.
   *
   * @return Success
   */
  UpdateNotificationsFlagsResponse(params: AgentDeploymentsService.UpdateNotificationsFlagsParams): __Observable<__StrictHttpResponse<SuccessResponseBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.sid != null) __headers = __headers.set('sid', params.sid.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/notifications/setFlags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SuccessResponseBody>;
      })
    );
  }
  /**
   * Updates visited and dismissed flags of a notification.
   *
   * Updates visited and dismissed flags of a notification.
   * @param params The `AgentDeploymentsService.UpdateNotificationsFlagsParams` containing the following parameters:
   *
   * - `body`: Request body to update notification flags.
   *
   * - `sid`: SID of the logged in user.
   *
   * @return Success
   */
  UpdateNotificationsFlags(params: AgentDeploymentsService.UpdateNotificationsFlagsParams): __Observable<SuccessResponseBody> {
    return this.UpdateNotificationsFlagsResponse(params).pipe(
      __map(_r => _r.body as SuccessResponseBody)
    );
  }
}

module AgentDeploymentsService {

  /**
   * Parameters for CreateAgentDeployment
   */
  export interface CreateAgentDeploymentParams {

    /**
     * Request body to create a agent deployment task.
     */
    body: AgentDeployment;

    /**
     * SID of the logged in user.
     */
    sid?: string;
  }

  /**
   * Parameters for GetAgentDeploymentById
   */
  export interface GetAgentDeploymentByIdParams {

    /**
     * Specifies a unique id of the Job.
     */
    id: string;

    /**
     * ReturnSummary specifies if only basic properties
     * should be returned for Deployments instead of complete data such
     * as data about Runs.
     */
    returnSummary?: boolean;
  }

  /**
   * Parameters for GetAgentDeploymentObjects
   */
  export interface GetAgentDeploymentObjectsParams {

    /**
     * Specifies a unique id of the Job.
     */
    id: string;

    /**
     * ReturnSummary specifies if only basic properties
     * should be returned for Deployments instead of complete data such
     * as data about Runs.
     */
    returnSummary?: boolean;
  }

  /**
   * Parameters for GetAgentDeploymentObjectsById
   */
  export interface GetAgentDeploymentObjectsByIdParams {

    /**
     * Specifies a unique id of the object.
     */
    objectId: string;

    /**
     * Specifies a unique id of the Job.
     */
    id: string;

    /**
     * ReturnSummary specifies if only basic properties
     * should be returned for Deployments instead of complete data such
     * as data about Runs.
     */
    returnSummary?: boolean;
  }

  /**
   * Parameters for GetHostObjectsById
   */
  export interface GetHostObjectsByIdParams {

    /**
     * Specifies a unique id of the Job.
     */
    id: string;

    /**
     * ReturnSummary specifies if only basic properties
     * should be returned for Deployments instead of complete data such
     * as data about Runs.
     */
    returnSummary?: boolean;
  }

  /**
   * Parameters for UpdateNotificationsFlags
   */
  export interface UpdateNotificationsFlagsParams {

    /**
     * Request body to update notification flags.
     */
    body: NotificationsFlags;

    /**
     * SID of the logged in user.
     */
    sid?: string;
  }
}

export { AgentDeploymentsService }
