import { Component } from '@angular/core';
import { Priority } from '../../../models';

import { BaseProtectionBuilderComponent } from '../../base-protection-builder/base-protection-builder.component';

/**
 * Default value for priority.
 */
export const DefaultPriority = 'kMedium';

@Component({
  selector: 'coh-settings-list-priority',
  templateUrl: './settings-list-priority.component.html',
  styleUrls: ['./settings-list-priority.component.scss'],
})
export class SettingsListPriorityComponent
  extends BaseProtectionBuilderComponent<Priority, any> {
  /**
   * Default description value.
   */
  _value: Priority = DefaultPriority;

  /**
   * List of possible priority values available for selection.
   */
  priorities: Priority[] = ['kLow', 'kMedium', 'kHigh'];

  /**
   * Form control init.
   */
  initFormControl() {
    if (this.protectionGroup && this.protectionGroup.priority) {
      this.value = this.protectionGroup.priority;
      this.formControl.setValue(this.value);
    }
  }
}
