import { File, Object } from '@cohesity/api/v2';
import { ENV_GROUPS, JobEnvParamsV2, RecoveryOsType } from 'src/app/shared';

import { RestoreSearchResult } from './restore-search-result';

/**
 * This class provides search results information for files.
 *
 * @example
 * const searchResult = new FileSearchResult(file);
 */
export class FileSearchResult implements RestoreSearchResult {
  /**
   * Static property for the result type. Which can be referenced by other classes.
   */
  static readonly fileResultType = 'file';

  /**
   * Gets a unique id for a file based on the path, name, source id and object id. This is only used internally.
   */
  get id(): string {
    return `${this.fullPath}:${this.sourceId}:${this.objectId}`;
  }

  /**
   * Returns true if this is a unix path. This _should_ be based on the api, but for now it will
   * attempt to guess based on the path name.
   */
  get isUnixPath(): boolean {
    if (ENV_GROUPS.nas.includes(this.environment)) {
      return true;
    }
    return this.recoveryOsType !== RecoveryOsType.kWindows;
  }

  /**
   * Returns os type system from which file was backed up.
   */
  get recoveryOsType(): RecoveryOsType {
    return this.file.sourceInfo.osType as RecoveryOsType;
  }

  get name(): string {
    // Use the path if the name hasn't been set on the api object. This means the the file is the volume root.
    return this.file.name || this.path;
  }

  /**
   * Gets the full path, minus the file name.
   */
  get path(): string {
    return this.file.path;
  }

  get environment(): string {
    return this.file.sourceInfo.environment;
  }

  /**
   * Gets the full path, including the file name.
   */
  get fullPath(): string {
    // If the name hasn't been set on the api object, we should return only the path.
    const fullPath = this.file.name ? `${this.path}/${this.name}` : this.path;

    // Replace multiple slashes in a path with just one.
    return fullPath.replace(/\/\/+/g, '/');
  }

  get sourceEnvironment(): string {
    return this.environment;
  }

  get sourceId(): number {
    return this.file.sourceInfo.sourceId;
  }

  get parentSourceName(): string {
    return null;
  }

  get objectType(): 'File' | 'Directory' | 'Symlink' {
    return this.file.type;
  }

  get objectId(): number {
    return this.file.sourceInfo.id;
  }

  get objectName(): string {
    return this.file.sourceInfo.name;
  }

  /**
   * Gets the group id from the latest snapshot info.
   */
  get protectionGroupId(): string {
    return this.file.protectionGroupId;
  }

  get protectionGroupName(): string {
    return this.file.protectionGroupName;
  }

  /**
   * By default, objects can be selected along with other object types.
   */
  requiresExclusiveSelection = false;

  get restoreTimestampUsecs(): number {
    return null;
  }

  readonly resultType = FileSearchResult.fileResultType;

  get storageDomainId(): number {
    return this.file.storageDomainId;
  }

  get supportedNasMountProtocols(): string[] {
    return this.file.sourceInfo[
      JobEnvParamsV2[this.sourceEnvironment]]?.supportedNasMountProtocols || [];
  }

  get sourceInfo(): Object {
    return this.file.sourceInfo;
  }

  constructor(readonly file: File) { }
}
