export enum HostType {
  kWindows = 'kWindows',
  kLinux = 'kLinux',
  kSolaris = 'kSolaris',
  kAix = 'kAix',
  kHPUX = 'kHPUX',
  kVOS = 'kVOS',
  kCdpVMwareIOFilter = 'kCdpVMwareIOFilter'
}

export const HOST_TYPE_GROUPS = {
  physicalBlockBased: ['kWindows', 'kLinux'],
  physicalFileBased: ['kLinux', 'kAix', 'kSolaris', 'kWindows', 'kHPUX', 'kVOS'],

  // This group has integer values because it is used in a flow where proto
  // values come from both public and private APIs (FLR)
  nixFilesystems: [0, 3, 4, 16, 17, 'kLinux', 'kAix', 'kSolaris', 'kHPUX', 'kVOS'],
};

export const ENUM_HOST_TYPE = {
  '-1': 'Unknown OS',
  0: 'Linux',
  1: 'Windows',
  2: 'Other',
  3: 'AIX',
  4: 'Solaris',
  16: 'HPUX',
  17: 'VOS',
  kLinux: 'Linux',
  kWindows: 'Windows',
  kAix: 'AIX',
  kSolaris: 'Solaris',
  kOther: 'Other',
  kHPUX:  'HPUX',
  kVOS: 'VOS'
};

/**
 * Host type equivalent enum conversion definition.
 */
export const ENUM_HOST_TYPE_CONVERSION = {
  0: 'kLinux',
  1: 'kWindows',
  2: 'kOther',
  3: 'kAix',
  4: 'kSolaris',
  16: 'kHPUX',
  17: 'kVOS',
  kLinux: 0,
  kWindows: 1,
  kOther: 2,
  kAix: 3,
  kSolaris: 4,
  kHPUX: 16,
  kVOS: 17
};

export type HostTypes = 'kLinux' | 'kWindows' | 'kAix' | 'kSolaris' | 'kSapHana' | 'kHPUX' | 'kVOS' | 'kOther';
