import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  LegacyDateAdapter as DateAdapter,
  MAT_LEGACY_DATE_FORMATS as MAT_DATE_FORMATS,
  MAT_LEGACY_DATE_LOCALE as MAT_DATE_LOCALE,
} from '@angular/material/legacy-core';
import { ObjectSnapshot } from '@cohesity/api/v2';
import { DataFilterValue } from '@cohesity/helix';
import { IrisContextService, flagEnabled } from '@cohesity/iris-core';
import { AjaxHandlerService } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import moment, { Moment } from 'moment';
import { Controls, NgxSubFormComponent, subformComponentProviders, takeUntilDestroyed } from 'ngx-sub-form';
import { BehaviorSubject, combineLatest, merge } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { SnapshotsService } from 'src/app/core/services';
import { Environment, JobRunType, JobRunTypeUIKey, RecoveryAction } from 'src/app/shared';

import { RestorePointSelection } from '../../../model/restore-point-selection';
import { SnapshotLabels } from '../../models/snapshot-labels';
import { SnapshotMetadata } from '../../models/snapshot-metadata';
import { SnapshotSelectorView } from '../../models/snapshot-selector-view';
import { SnapshotTimelineValue } from '../../models/snapshot-timeline-value';
import { SnapshotMetadataService } from '../../services/snapshot-metadata.service';
import { SnapshotSearchService } from '../../services/snapshot-search.service';
import { SnapshotSelectorService } from '../../services/snapshot-selector.service';

/**
 * Interface for the snapshot selector subform.
 */
interface SnapshotSelectorForm {
  /**
   * The control for the list view value.
   */
  listViewControl: ObjectSnapshot;

  /**
   * The control for the "Indexed Snapshots Only" toggle button.
   */
  indexedSnapshotsOnlyControl: boolean;

  /**
   * The control for the datepicker in timeline view.
   */
  currentDate: Moment;

  /**
   * The control for the "Show only ... snapshots" toggle button.
   */
  fetchOnlySnapshotRunType: boolean;
}

@Component({
  selector: 'coh-snapshot-selector',
  templateUrl: './snapshot-selector.component.html',
  styleUrls: ['./snapshot-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    SnapshotMetadataService,
    SnapshotSearchService,
    SnapshotSelectorService,
    SnapshotsService,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: { ...MAT_MOMENT_DATE_FORMATS.parse, dateInput: 'MMM D, YYYY' },
        display: { ...MAT_MOMENT_DATE_FORMATS.display, dateInput: 'MMM D, YYYY' },
      },
    },
    subformComponentProviders(SnapshotSelectorComponent),
  ],
})
export class SnapshotSelectorComponent extends NgxSubFormComponent<SnapshotSelectorForm> implements OnInit {
  /**
   * Default View Switcher's view to render on initial dialog render.
   */
  @Input() defaultViewSwitcherView: SnapshotSelectorView;

  /**
   * Indicates whether to hide the view switcher.
   */
  @Input() hideViewSwitcher: boolean;

  /**
   * The object for which, the snapshot selector should initialize.
   */
  @Input() object: RestorePointSelection;

  /**
   * Specifies the backup type(s) for the fetching object snapshot(s).
   */
  @Input() objectActionKeys: Environment[];

  /*
   * In case of NoSQL adapters, sourceId is required to fetch snapshot for
   * indexed-objects.
   */
  @Input() useSourceId: boolean;

  /*
   * If this is true, the modal will use the indexed object api.
   */
  @Input() indexedObjectName: string;

  /**
   * Optional protection group id to filter the fetched snapshots on.
   */
  @Input() protectionGroupId: string;

  /**
   * Some environments need special handling.
   * RDS for ex. used kRDSSnapshotManager in v1 but uses kAWS in v2.
   * This key will override the object.environment in object key above.
   */
  @Input() environment: Environment;

  /**
   * Any custom snapshot labels
   */
  @Input() snapshotLabels: SnapshotLabels;

  /**
   * If this value is given, we add a checkobx to only fetch given run type snapshots.
   */
  @Input() fetchRunType: JobRunType;

  /**
   * Determines if timestamp values with in Point in time range is allowed for selection or not.
   * Defaults to 'true'.
   */
  @Input() pointInTimeSelectionSupported = true;

  /**
   * If this is true, hide the indexed snapshots toggle.
   */
  @Input() hideIndexedSnapshotsToggle = false;

  /**
   * List of actions available for the snapshot
   */
  @Input() snapshotActions: RecoveryAction[];

  /**
   * This event is emitted every time the snapshot selection is changed.
   */
  @Output() snapshotChanged = new EventEmitter<RestorePointSelection>();

  /**
   * The current value of the view switcher.
   */
  viewSwitcherView: SnapshotSelectorView;

  /**
   * The ID of the snapshot to select in the list view.
   */
  listViewSnapshotId: string;

  /**
   * An observable to indicate whether the data (snapshots or point-in-time ranges or snapshot metadata) is being
   * loaded from API.
   */
  readonly dataLoading$ = combineLatest([
    this.snapshotSelectorService.dataLoading$,
    this.snapshotMetadataService.snapshotMetadataLoading$,
  ]).pipe(
    map(([dataLoading, snapshotMetadataLoading]) => dataLoading || snapshotMetadataLoading),
  );

  /**
   * An observable of the grouped snapshots.
   */
  snapshots$ = this.snapshotSelectorService.snapshots$.pipe(
    // Filter snapshots on protection id if it is supplied in the dialog data.
    // This needs to be done for the get snapshots API response since it does
    // not support support filtering based on protection group ids yet. This
    // will be removed once filtering is added in the API.
    map(snapshots => {
      if (this.protectionGroupId) {
        return (snapshots || []).filter(snapshot => snapshot.protectionGroupId === this.protectionGroupId);
      }
      return snapshots;
    })
  );

  /**
   * An observable with snapshot metadata like tag information for currently loaded snapshots
   */
  snapshotMetadata$ = new BehaviorSubject<SnapshotMetadata>(this.snapshotMetadataService.defaultMetadata);

  /**
   * Snapshots for List view for the selected date in the optimized PIT recovery.
   */
  listViewSnapshots$ = this.snapshotSelectorService.currentDateSnapshots$.pipe(
    // Filter snapshots on protection id if it is supplied in the dialog data.
    // This needs to be done for the get snapshots API response since it does
    // not support filtering based on protection group ids yet. This
    // will be removed once filtering is added in the API.
    map(snapshots => {
      if (this.protectionGroupId) {
        return (snapshots || []).filter(snapshot => snapshot.protectionGroupId === this.protectionGroupId);
      }
      return snapshots;
    })
  );

  /**
   * The snapshots for the timeline view. This is the list of snapshots filtered by the currently selected date.
   */
  timelineViewSnapshots$ = this.snapshotSelectorService.currentDateSnapshots$.pipe(
    map(snapshots => snapshots.sort((a, b) => a.snapshotTimestampUsecs - b.snapshotTimestampUsecs))
  );

  /**
   * A default snapshot to always pass to the timeline view component, so that it returns this snapshot when only
   * the time ranges are available.
   */
  defaultTimelineViewSnapshot$ = this.snapshots$.pipe(map(snapshots => snapshots[0]));

  /**
   * The current value of the timeline view.
   */
  timelineViewValue$ = new BehaviorSubject<SnapshotTimelineValue>(null);

  /**
   * The timestamp (in usecs from epoch) for the timeline view.
   */
  timelineViewTimestamp: number;

  /**
   * The current selected snapshot type.
   */
  snapshotType: string;

  /**
   * Calendar dates that have runs.
   */
  activeDates$ = new BehaviorSubject<moment.Moment[]>([]);

  /**
   * Calendar dates that have runs.
   */
  private markedDatesSubject = new BehaviorSubject<moment.Moment[]>([]);

  /**
   * Observable of marked dates.
   */
  markedDates$ = this.markedDatesSubject.asObservable();

  /**
   * Behaviour subject to track if the currently selected snapshot can be used for recovery
   */
  isSnapshotNonRecoverable$ = new BehaviorSubject<boolean>(false);

  /**
   * The previously selected date. Tracked for sake of comparison.
   */
  previousDate: moment.Moment;

  /**
   * Stores the pre-selected date for adaptors supporting API based time range
   * filtering.
   */
  selectedTimeRange: { fromTimeUsecs?: number; toTimeUsecs?: number };

  /**
   * The filter for the calendar to enable/disable dates.
   */
  activeDatesFilter$ = this.snapshotSelectorService.activeDates$.pipe(
    filter(activeDates => !!activeDates.size),
    map(activeDates => (date: Moment): boolean => activeDates.has(date?.valueOf()))
  );

  /**
   * Check if the default view is 'list' and the view switcher is hidden.
   */
  get isTimelineViewDisabled(): boolean {
    return this.viewSwitcherView === 'list' && this.hideViewSwitcher;
  }

  /**
   * Returns UI string for job run type.
   */
  get runTypeUIKey(): string {
    return this.translate.instant(JobRunTypeUIKey[this.fetchRunType]);
  }

  /**
   * Returns option to show/Hide size col in list view.
   */
  get hideSizeCol(): boolean {
    // In case of Hdfs in all NoSql adapters display size and modified column.
    if (this.useSourceId && this.object.objectInfo.environment === Environment.kHdfs) {
      return false;
    }
    return this.useSourceId;
  }

  /**
   * Specifies whether to show/hide backup type column in list view.
   */
  get hideBackupTypeCol(): boolean {
    return this.snapshotSelectorService.isM365CsmWorkflow;
  }

  /**
   * Specifies whether the time-range filter through API is supported.
   */
  get isTimeRangeFilterThroughApiSupported(): boolean {
    if (!this.snapshotActions?.length) {
      return false;
    }

    const snapshotActionSupportingTimeRangeApiFilter = new Set([
      RecoveryAction.RecoverMailboxCSM,
      RecoveryAction.RecoverOneDriveCSM,
      RecoveryAction.RecoverSharePointCSM
    ]);
    return this.snapshotActions.some(
      action => snapshotActionSupportingTimeRangeApiFilter.has(action));
  }

  constructor(
    protected ajaxHandler: AjaxHandlerService,
    protected cdr: ChangeDetectorRef,
    protected irisCtx: IrisContextService,
    protected translate: TranslateService,
    readonly snapshotMetadataService: SnapshotMetadataService,
    readonly snapshotSearchService: SnapshotSearchService,
    readonly snapshotSelectorService: SnapshotSelectorService,
    readonly snapshotsService: SnapshotsService,
  ) {
    super();
  }

  /**
   * Setter for marked dates.
   */
  setMarkedDates(dates: moment.Moment[]) {
    this.markedDatesSubject.next([...dates]);
  }

  ngOnInit() {
    if (!this.object.objectIds?.length) {
      throw new Error('Snapshot Selector is missing the object id in the params');
    }

    this.snapshotSearchService.restoreTypes = this.snapshotActions;

    this.viewSwitcherView = this.defaultViewSwitcherView || 'list';

    this.setupSubscriptions();

    this.listViewSnapshotId = this.object.restorePointId;
    this.timelineViewTimestamp = this.object.timestampUsecs;
    if (this.object.snapshot) {
      this.snapshotType = this.snapshotsService.getSnapshotType(this.object.snapshot);
    }

    // This ensures the 'GetRestorePointsForTimeRange' is not called unnecessarily
    // in the scenario where we just want to render the 'list' view.
    if (!this.isTimelineViewDisabled) {
      const sourceObject = this.object.objectInfo;

      this.snapshotSelectorService.setFields({
        protectionGroupId: sourceObject?.protectionGroupId,
        environment: this.environment || (sourceObject.environment as any),
        protectionSourceId: sourceObject.id as number,
        objectActionKeys: this.objectActionKeys,
      });

      this.formGroupControls.currentDate.setValue(moment(this.object.timestampUsecs / 1000));
    }

    this.snapshotSearchService.setSelectedDate(moment(this.object.timestampUsecs / 1000));
    this.fetchSnapshots(moment(this.object.timestampUsecs / 1000));
    this.setActiveDates();
  }

  getFormControls(): Controls<SnapshotSelectorForm> {
    return {
      currentDate: new UntypedFormControl(),
      fetchOnlySnapshotRunType: new UntypedFormControl(),
      indexedSnapshotsOnlyControl: new UntypedFormControl(),
      listViewControl: new UntypedFormControl(),
    };
  }

  getDefaultValues(): Partial<SnapshotSelectorForm> {
    return {
      indexedSnapshotsOnlyControl: true,
      fetchOnlySnapshotRunType: false,
    };
  }

  /**
   * Returns the protection source id for the call to fetch indexed snapshots.
   * This method can be overridden by derived classes to provide a custom id.
   *
   * @returns protection source id for fetching snapshots corresponding to
   * indexed object
   */
  getProtectionSourceIdForIndexedObject(): number {
    const object = this.object;
    return (this.useSourceId ? object.objectIds[0] : object.objectInfo.id) as number;
  }

  /**
   * Returns the id for the call to fetch time ranges for an indexed object. Defaults to
   * getProtectionSourceIdForIndexedObject. This method can be overridden by derived classes
   * to provide a custom id for the time ranges API.
   *
   * @returns id to use for fetching time ranges corresponding to indexed object
   */
  getTimeRangesIdForIndexedObject(): number {
    return this.getProtectionSourceIdForIndexedObject();
  }

  /**
   * Fetches the set of snapshots.
   *
   * @param day   a day occuring in the month for which snapshots should be retrieved
   */
  fetchSnapshots(day: moment.Moment) {
    const object = this.object;

    // Configure default time-range.
    const timeRangeArgs = this.getTimeRangeParams(day);

    // Set the pre-selected time range.
    this.selectedTimeRange = timeRangeArgs;
    if (this.indexedObjectName) {
      this.snapshotSelectorService.setFields({
        protectionSourceId: this.getProtectionSourceIdForIndexedObject(),
        timeRangesId: this.getTimeRangesIdForIndexedObject(),
        protectionGroupId: object.objectInfo.protectionGroupId,
        indexedObjectName: this.indexedObjectName,
        indexedSnapshotsOnly: this.formGroupValues.indexedSnapshotsOnlyControl,
        objectActionKeys: this.objectActionKeys,
        ...timeRangeArgs
      });
    } else {
      this.snapshotSelectorService.setFields({
        protectionSourceId: this.getProtectionSourceIdForIndexedObject(),
        timeRangesId: this.getTimeRangesIdForIndexedObject(),
        protectionGroupId: object.objectInfo.protectionGroupId,
        runTypes: this.formGroupValues.fetchOnlySnapshotRunType ? [this.fetchRunType] : null,
        objectActionKeys: this.objectActionKeys,
        ...timeRangeArgs
      });
    }
  }

  /**
   * Sets up various subscriptions.
   */
  setupSubscriptions() {
    // Listen to changes in current date and update the snapshot selector service.
    this.formGroupControls.currentDate.valueChanges
      .pipe(takeUntilDestroyed(this))
      .subscribe(date => (this.snapshotSelectorService.currentDate = moment(date)));

    // Listen to changes in indexed snapshots toggle button and snapshot run type toggle button, and fetch the
    // snapshots.
    merge(
      this.formGroupControls.indexedSnapshotsOnlyControl.valueChanges,
      this.formGroupControls.fetchOnlySnapshotRunType.valueChanges
    )
      .pipe(takeUntilDestroyed(this))
      .subscribe(() => this.fetchSnapshots(moment(this.object.timestampUsecs / 1000)));

    // load snapshot metadata information
    this.snapshotSearchService.searchResults$.pipe(
      takeUntilDestroyed(this),
      switchMap((snapshotData) => {
        const snapshotIds: string[] = snapshotData.map(snapshot => snapshot.id);
        return this.snapshotMetadataService.fetchSnapshotMetadata(snapshotIds);
      })
    ).subscribe((value) => this.snapshotMetadata$.next(value));

    // Listen to changes in timeline view value or list view value and emit the snapshot change event.
    merge(
      this.timelineViewValue$,
      this.formGroupControls.listViewControl.valueChanges,
      this.snapshotMetadata$,
    )
      .pipe(takeUntilDestroyed(this))
      .subscribe(() => this.emitSnapshotChangedEvent());
  }

  /**
   * This method serves as a callback for the `change` event in the snapshot
   * selector view component.
   *
   * @param view The update view of snapshot selector.
   */
  onViewChange(view: SnapshotSelectorView) {
    this.viewSwitcherView = view;
    // reset the recoverability status when view changes
    this.isSnapshotNonRecoverable$.next(false);
    if (!this.snapshotSelectorService.optimizedSnapshotSelector) {
      if (view === 'timeline') {
        const listViewTimestamp = (this.formGroupValues.listViewControl as ObjectSnapshot)?.snapshotTimestampUsecs;
        this.timelineViewTimestamp = listViewTimestamp;
        this.formGroupControls.currentDate.setValue(moment(listViewTimestamp / 1000));
        if (this.formGroupValues.listViewControl) {
          this.snapshotType = this.snapshotsService.getSnapshotType(this.formGroupValues.listViewControl);
        }
      } else if (view === 'list') {
        const value = this.timelineViewValue$.value;

        if (value.isValidSnapshot) {
          this.listViewSnapshotId = value.snapshot.id;
        }
      }
    }
  }

  /**
   * This method serves as a callback for the `change` event in the timeline
   * view component.
   *
   * @param value The new value of the timeline component.
   */
  onTimelineValueChange(value: SnapshotTimelineValue) {
    this.timelineViewValue$.next(value);
    this.cdr.detectChanges();
  }

  /**
   * Returns whether the currently selected value is a tape snapshot.
   *
   * @returns `true` if the selected value is a tape snapshot, `false` otherwise.
   */
  isTapeSnapshot(): boolean {
    let snapshot: ObjectSnapshot;

    switch (this.viewSwitcherView) {
      case 'list':
        snapshot = this.formGroupValues.listViewControl;
        return snapshot?.externalTargetInfo?.targetType === 'Tape';
      case 'timeline': {
        const value = this.timelineViewValue$.value;
        snapshot = value?.snapshot as ObjectSnapshot;
        return !value?.pointInTimeUsecs && snapshot?.externalTargetInfo?.targetType === 'Tape';
      }
    }
  }

  /**
   * Emits the snapshotChanged event with appropriate value based on the current view mode (list or timeline).
   */
  emitSnapshotChangedEvent() {
    let value: RestorePointSelection;
    let snapshot: ObjectSnapshot;

    if (this.viewSwitcherView === 'list') {
      snapshot = this.formGroupValues?.listViewControl;

      if (snapshot) {
        value = {
          ...this.object,
          snapshot,
          restorePointId: snapshot.id,
          timestampUsecs: snapshot.snapshotTimestampUsecs,
          isPointInTime: false,
          snapshotRunType: snapshot.runType,

          // Reset these fields so that these can be initialized again.
          archiveTargetInfo: null,
          indexedObjectSourceUuid: (snapshot as any).indexedObjectSourceUuid,
        };
      }
    } else if (this.viewSwitcherView === 'timeline') {
      const timelineValue = this.timelineViewValue$.value;

      if (timelineValue?.isValidSnapshot) {
        snapshot = timelineValue.snapshot;

        value = {
          ...this.object,
          snapshot,
          restorePointId: timelineValue.snapshot.id,
          timestampUsecs: Math.floor(timelineValue.pointInTimeUsecs || timelineValue.snapshot.snapshotTimestampUsecs),
          isPointInTime: Boolean(timelineValue.pointInTimeUsecs),
          snapshotRunType: timelineValue.pointInTimeUsecs ? 'kHydrateCDP' : snapshot.runType,

          // Reset these fields so that these can be initialized again.
          archiveTargetInfo: null,
          indexedObjectSourceUuid: (snapshot as any).indexedObjectSourceUuid,
        };
      }
    }

    if (snapshot?.snapshotTargetType === 'Archival') {
      value.archiveTargetInfo = {...snapshot.externalTargetInfo};
    }

    if (value) {
      const isSnapshotNonRecoverable = this.snapshotMetadata$.value.recoverability[value.restorePointId] === false;
      this.isSnapshotNonRecoverable$.next(isSnapshotNonRecoverable);
      this.snapshotChanged.emit(isSnapshotNonRecoverable ? null : value);
    }
  }

  /**
   * Handles calendar date change event.
   *
   * @param newDate   New date selected on the calendar.
   */
  dateChangeHandler(newDate: moment.Moment) {
    this.snapshotSearchService.selectedDate$.pipe(take(1)).subscribe(
      selectedDate => this.previousDate = selectedDate
    );
    this.snapshotSearchService.setSelectedDate(newDate);
    const newDateMoment = moment(newDate);
    this.formGroupControls.currentDate.setValue(newDateMoment);

    this.snapshotSelectorService.fetchDayTimeRanges();

    if (!newDateMoment.isSame(this.previousDate, 'month')) {
      // Not the same month. Determine if user is moving forward or backward through time
      // so that getMonthSnapshots can correctly mark the most relevant date as
      // selected.
      const adjustedDate = newDateMoment.isBefore(this.previousDate)
        ? newDateMoment.endOf('month')
        : newDateMoment.startOf('month');
      this.getMonthSnapshots(adjustedDate);
    }

    // TODO: load snapshot selector timeline component only on having a day selected?
    // matCalendar seems to require a day being selected, which means it won't fire
    // change event if user clicks on defaulted day.
  }

  /**
   * Gets snapshots for a particular month.
   *
   * @param day   a day occuring in the month for which snapshots should be retrieved
   */
  getMonthSnapshots(day: moment.Moment) {
    const theDay = moment(day);
    const object = this.object;

    this.snapshotSearchService.getSnapshots({
      objectId: this.getProtectionSourceIdForIndexedObject(),
      protectionGroupId: object.objectInfo.protectionGroupId,
      runTypes: this.formGroupValues.fetchOnlySnapshotRunType ? [this.fetchRunType] : null,
      fromTimeUsecs: theDay.startOf('month').valueOf() * 1000,
      toTimeUsecs: theDay.endOf('month').valueOf() * 1000,
    });

    this.setActiveDates();
  }

  /**
   * Sets all the active dates in the optimized calendar.
   *
   * @param day   a day occuring in the month
   */
  setActiveDates() {
    // Clear previous months list and empty its related activeDates$.
    // this.displayedMonthSnaphots$.next([]);
    this.activeDates$.next([]);
    this.setMarkedDates([]);

    combineLatest([
      this.snapshotSearchService.groupedSearchResults$,
      this.snapshotSearchService.searchPending$,
    ]).pipe(
      filter(([, pending]) => !pending),
      takeUntilDestroyed(this),
    ).subscribe(
      ([snapshots]) => {
        // this.displayedMonthSnaphots$.next(snapshots);

        // NOTE (dangerous assumption?): dates should be in descending chronological order
        // so reverse them to make them easier to work with.
        const snapshotDates = snapshots.map(
          snapshot => moment(snapshot.snapshotTimestampUsecs / 1000).startOf('day')
        ).reverse();

        const earliestDayInMonth = snapshotDates[0];
        const activeDates: moment.Moment[] = [];

        // Initial check ensures there are actually snapshots in the month.
        if (earliestDayInMonth) {
          const m = moment(snapshotDates[0]);
          while (m.diff(snapshotDates[snapshotDates.length - 1], 'days') <= 0) {
            activeDates.push(moment(m));
            m.add(1, 'days');
          }

          if (flagEnabled(this.irisCtx.irisContext, 'pointsForTimeRangeForWeek')) {
            for (let additionalDay = 0; additionalDay < 7; additionalDay++) {
              activeDates.push(
                moment.min(moment(activeDates[activeDates.length - 1]).add(1, 'days'), moment())
              );
            }
          } else {
            activeDates.push(
              moment.min(moment(activeDates[activeDates.length - 1]).add(1, 'days'), moment())
            );
          }
        }

        this.activeDates$.next([...activeDates]);
        this.setMarkedDates([...snapshotDates]);
      },
      (err) => this.ajaxHandler.errorMessage(err)
    );
  }

  /**
   * Generates the time range parameters for given epoch start & end time in
   * usecs acceptable by the GET snapshots API.
   *
   * @param day Specifies the initial timestamp
   * @returns Object containing time-range.
   */
  getTimeRangeParams(day?: moment.Moment): { fromTimeUsecs?: number; toTimeUsecs?: number } {
    // For workloads desiring PointInTime recovery.
    if (this.snapshotSelectorService.optimizedSnapshotSelector) {
      return {
        fromTimeUsecs: moment(day).startOf('month').valueOf() * 1000,
        toTimeUsecs: moment(day).endOf('month').valueOf() * 1000,
      };
    }

    // For workload specific to M365 Backup Storage APIs(CSM).
    if (this.snapshotSelectorService.isM365CsmWorkflow) {
      return {
        fromTimeUsecs: moment().subtract(1, 'hour').startOf('hour').valueOf() * 1000,
        toTimeUsecs: moment().valueOf() * 1000,
      };
    }
    return null;
  }

  /**
   * Handles updating the time range for fetching the list of snapshots.
   *
   * @param dateTimeRange Specifies the filter value returned by the children.
   */
  handleTimeRangeChange(dateTimeRange: DataFilterValue<any, any>) {
    if (!this.isTimeRangeFilterThroughApiSupported) {
      return;
    }

    const object = this.object;
    const start = dateTimeRange.value.start.toDate().getTime() * 1000;
    const end = dateTimeRange.value.end.toDate().getTime() * 1000;
    this.selectedTimeRange = {
      fromTimeUsecs: start,
      toTimeUsecs: end
    };
    this.snapshotSelectorService.setFields({
      protectionSourceId: this.getProtectionSourceIdForIndexedObject(),
      timeRangesId: this.getTimeRangesIdForIndexedObject(),
      protectionGroupId: object.objectInfo.protectionGroupId,
      runTypes: this.formGroupValues.fetchOnlySnapshotRunType ?
        [this.fetchRunType] : null,
      objectActionKeys: this.objectActionKeys,
      fromTimeUsecs: start,
      toTimeUsecs: end,
    });
  }
}
