<ng-container *ngIf="filterDefParams.quickFilter">
  <cog-quick-filter
    #menuTrigger="matMenuTrigger"
    [cogDataId]="filter.label"
    [hideApplyButton]="true"
    [hideButtons]="true"
    [inheritDataId]="true"
    [label]="filter.label"
    [filterTags]="filter.tagValues$ | async"
    [lockedMessage]="filter.lockedMessage"
    [noClear]="noClear"
    [selfManagedMenu]="true"
    (applied)="applyFilters()"
    (cleared)="clearFilters()"
    [matMenuTriggerFor]="filtersMenu"
    [isMenuOpen]="menuTrigger.menuOpen"
    [xPosition]="filterDefParams.quickFilterPositionX">
  </cog-quick-filter>
</ng-container>

<mat-menu #filtersMenu="matMenu" class="cog-quick-filters-menu">
  <!--
    NOTE: Not currently supporting search in this filter. Can model this after
    ValuePropertyFilterComponent if search is needed. The plumbing is in place
    but this field is always removed as-is.
   -->
  <mat-form-field *ngIf="false"
    class="cog-nested-value-property-filter-search">
    <input cogDataId="search"
      [attr.aria-label]="intlSvc.search"
      [inheritDataId]="true"
      type="text"
      [placeholder]="intlSvc.search"
      matInput
      [formControl]="stringSearchControl">
  </mat-form-field>

  <div class="cog-nested-value-property-filter-list">
    <mat-selection-list cogDataId="list"
      [inheritDataId]="true"
      [multiple]="false">
      <ng-container *ngFor="let item of displayedValues$ | async">
        <div mat-menu-item *ngIf="!item.subItems?.length">
          <mat-list-option class="cog-nested-value-property-filter-option"
            [cogDataId]="item.label"
            [inheritDataId]="true"
            (click)="clickHandler(item)"
            [selected]="isSelected(item)"
            [class.cog-property-filter-value-single-line]="!item.hintText"
            [value]="item">
            <cog-icon mat-list-icon [shape]="item.icon" *ngIf="item.icon"></cog-icon>
            <div mat-line>{{item.label}}</div>
            <div mat-line *ngIf="item.hintText">{{item.hintText}}</div>
            <cog-icon class="cog-nested-value-property-filter-check"
              *ngIf="isSelected(item)"
              shape="check">
            </cog-icon>
          </mat-list-option>
        </div>
        <div *ngIf="item.subItems?.length"
          mat-menu-item
          #menuTrigger="matMenuTrigger"
          [matMenuTriggerFor]="subFilters">
          <mat-list-option class="cog-nested-value-property-filter-option"
            aria-haspopup="true"
            [cogDataId]="item.label"
            [inheritDataId]="true"
            (click)="clickHandler(item)"
            [selected]="isSelected(item)"
            [class.cog-property-filter-value-single-line]="!item.hintText"
            [class.has-selected-child]="isChildSelected(item)"
            [value]="item">
            <cog-icon mat-list-icon [shape]="item.icon" *ngIf="item.icon"></cog-icon>
            <div mat-line>{{item.label}}</div>
            <div mat-line *ngIf="item.hintText">{{item.hintText}}</div>
            <cog-icon class="cog-nested-value-property-filter-check"
              *ngIf="isSelected(item) && !isChildSelected(item)"
              shape="check">
            </cog-icon>
          </mat-list-option>
        </div>
        <mat-menu #subFilters="matMenu" xPosition="after">
          <div class="cog-nested-value-property-filter-list">
            <mat-selection-list cogDataId="sublist"
              [multiple]="false">
              <div mat-menu-item *ngFor="let subItem of item.subItems">
                <mat-list-option
                  [cogDataId]="subItem.label"
                  [inheritDataId]="true"
                  class="cog-nested-value-property-filter-option"
                  (click)="clickHandler(subItem)"
                  [selected]="isSelected(subItem)"
                  [class.cog-property-filter-value-single-line]="!subItem.hintText"
                  [value]="subItem">
                  <cog-icon mat-list-icon [shape]="subItem.icon" *ngIf="subItem.icon"></cog-icon>
                  <div mat-line>{{subItem.label}}</div>
                  <div mat-line *ngIf="subItem.hintText">{{subItem.hintText}}</div>
                  <cog-icon class="cog-nested-value-property-filter-check"
                    *ngIf="isSelected(subItem)"
                    shape="check">
                  </cog-icon>
                </mat-list-option>
              </div>
            </mat-selection-list>
          </div>
        </mat-menu>
      </ng-container>
    </mat-selection-list>
  </div>
</mat-menu>
