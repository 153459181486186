import { ChangeDetectionStrategy, Component } from '@angular/core';

import { BaseRendererComponent } from '../base-renderer.component';

/**
 * Renders a simple icon value.
 */
@Component({
  selector: 'cog-icon-data-renderer',
  template: '<cog-icon *ngIf="value" [shape]="value"></cog-icon>',
  styleUrls: ['./icon-data-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconDataRendererComponent extends BaseRendererComponent<string> {}
