/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Roles } from '../models/roles';
import { Role } from '../models/role';
import { CreateRoleParameters } from '../models/create-role-parameters';
import { UpdateRoleParameters } from '../models/update-role-parameters';
@Injectable({
  providedIn: 'root',
})
class RoleService extends __BaseService {
  static readonly GetRolesPath = '/roles';
  static readonly CreateRolePath = '/roles';
  static readonly UpdateRolePath = '/roles/{name}';
  static readonly DeleteRolePath = '/roles/{name}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get Roles.
   *
   * Get Roles.
   * @param params The `RoleService.GetRolesParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which Roles are to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `names`: Specifies a list of Role names.
   *
   * - `includeTenants`: If true, the response will include Roles which were created by all tenants which the current user has permission to see. If false, then only Roles created by the current user will be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRolesResponse(params: RoleService.GetRolesParams): __Observable<__StrictHttpResponse<Roles>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.names || []).forEach(val => {if (val != null) __params = __params.append('names', val.toString())});
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Roles>;
      })
    );
  }
  /**
   * Get Roles.
   *
   * Get Roles.
   * @param params The `RoleService.GetRolesParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which Roles are to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `names`: Specifies a list of Role names.
   *
   * - `includeTenants`: If true, the response will include Roles which were created by all tenants which the current user has permission to see. If false, then only Roles created by the current user will be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRoles(params: RoleService.GetRolesParams): __Observable<Roles> {
    return this.GetRolesResponse(params).pipe(
      __map(_r => _r.body as Roles)
    );
  }

  /**
   * Create a Role.
   *
   * Create a Role.
   * @param params The `RoleService.CreateRoleParams` containing the following parameters:
   *
   * - `body`: Specifies the request body to create a Role.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateRoleResponse(params: RoleService.CreateRoleParams): __Observable<__StrictHttpResponse<Role>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Role>;
      })
    );
  }
  /**
   * Create a Role.
   *
   * Create a Role.
   * @param params The `RoleService.CreateRoleParams` containing the following parameters:
   *
   * - `body`: Specifies the request body to create a Role.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateRole(params: RoleService.CreateRoleParams): __Observable<Role> {
    return this.CreateRoleResponse(params).pipe(
      __map(_r => _r.body as Role)
    );
  }

  /**
   * Update a Role.
   *
   * Update a Role.
   * @param params The `RoleService.UpdateRoleParams` containing the following parameters:
   *
   * - `name`: Specifies the name of Role to update.
   *
   * - `body`: Specifies the request body to update a Role.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateRoleResponse(params: RoleService.UpdateRoleParams): __Observable<__StrictHttpResponse<Role>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/roles/${encodeURIComponent(params.name)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Role>;
      })
    );
  }
  /**
   * Update a Role.
   *
   * Update a Role.
   * @param params The `RoleService.UpdateRoleParams` containing the following parameters:
   *
   * - `name`: Specifies the name of Role to update.
   *
   * - `body`: Specifies the request body to update a Role.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateRole(params: RoleService.UpdateRoleParams): __Observable<Role> {
    return this.UpdateRoleResponse(params).pipe(
      __map(_r => _r.body as Role)
    );
  }

  /**
   * Delete a Role.
   *
   * Delete a Role.
   * @param params The `RoleService.DeleteRoleParams` containing the following parameters:
   *
   * - `name`: Specifies the name of Role to delete.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteRoleResponse(params: RoleService.DeleteRoleParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/roles/${encodeURIComponent(params.name)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a Role.
   *
   * Delete a Role.
   * @param params The `RoleService.DeleteRoleParams` containing the following parameters:
   *
   * - `name`: Specifies the name of Role to delete.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteRole(params: RoleService.DeleteRoleParams): __Observable<null> {
    return this.DeleteRoleResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module RoleService {

  /**
   * Parameters for GetRoles
   */
  export interface GetRolesParams {

    /**
     * TenantIds contains ids of the tenants for which Roles are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies a list of Role names.
     */
    names?: Array<string>;

    /**
     * If true, the response will include Roles which were created by all tenants which the current user has permission to see. If false, then only Roles created by the current user will be returned.
     */
    includeTenants?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateRole
   */
  export interface CreateRoleParams {

    /**
     * Specifies the request body to create a Role.
     */
    body: CreateRoleParameters;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateRole
   */
  export interface UpdateRoleParams {

    /**
     * Specifies the name of Role to update.
     */
    name: string;

    /**
     * Specifies the request body to update a Role.
     */
    body: UpdateRoleParameters;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteRole
   */
  export interface DeleteRoleParams {

    /**
     * Specifies the name of Role to delete.
     */
    name: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { RoleService }
