import { Component, Input } from '@angular/core';
import { DmsServiceApi } from '@cohesity/api/dms';
import { FiltersComponent, ValueFilterSelection } from '@cohesity/helix';
import { IrisContextService, isDmsUser } from '@cohesity/iris-core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DmsService } from 'src/app/core/services';

import { BaseObjectFilterComponent } from './../base-object-filter';

/**
 * This is a reusable filter that can be used to filter for regions.
 */
@Component({
  selector: 'coh-region-filter',
  templateUrl: './region-filter.component.html',
})
export class RegionFilterComponent extends BaseObjectFilterComponent<any> {
  /**
   * The name of the property containing the region id.
   */
  @Input() property = 'region';

  /**
   * Can be disabled to allow for quick select of only one region.
   */
  @Input() multiSelect = true;

  /**
   * Get whether the user is a Dms user.
   */
  get isDmsUser(): boolean {
    return isDmsUser(this.irisContext.irisContext);
  }

  constructor(
    filters: FiltersComponent,
    private dmsService: DmsServiceApi,
    private irisContext: IrisContextService,
    private dmsServiceUtil: DmsService,
  ) {
    super(filters);
  }

  /**
   * Fetch regions when the component initializes or the params change.
   */
  getFilterValues(): Observable<ValueFilterSelection[]> {
    if (!this.isDmsUser) {
      return of([]);
    }

    // Array of region ids which belong to the user.
    const userRegionIds = this.dmsServiceUtil.configuredRegionIds;

    return this.dmsService.GetRegions().pipe(
      // Filter out regions not provisioned to the user.
      map(result =>
        (result.regions || []).filter(region => userRegionIds.includes(region.id))
      ),

      // Map to ValueFilterSelection format.
      map(regions =>
        regions.map(region => ({
          label: region.name,
          value: region.id,
        }))
      )
    );
  }
}
