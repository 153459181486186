import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BaseRendererComponent } from '../base-renderer.component';

/**
 * Available params that can be specified for duration values
 */
export interface DurationDataRendererParams {
  /**
   * Whether the duration is in usecs
   */
  usecs?: boolean;
}

/**
 * Renders a simple duration value.
 */
@Component({
  selector: 'cog-duration-data-renderer',
  template: '{{ (renderParams?.usecs ? (value/1000) : value) | cogDuration }}',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DurationDataRendererComponent extends BaseRendererComponent<number, DurationDataRendererParams> {

}
