<!-- Only show this setting for VMWare VMs. -->
<cog-settings-list-item [formGroup]="formGroup"
  [invalid]="formControl.invalid"
  [label]="'leverageStorageSnapshotsForDataProtection' | translate">
  <cog-settings-list-item-value-display>
    <ng-container *ngIf="storageSnapshotFormGroup.value.enabled">
      {{('displayLeverageType' | translate: {
        name: (storageSnapshotFormGroup.value.snapshotsCarrier === 'hyperFlex' ?
          storageSnapshotFormGroup.value.snapshotsCarrier : 'storageArraySlashNas') | translate
      })}}
    </ng-container>
    <ng-container *ngIf="!storageSnapshotFormGroup.value.enabled">
      {{'no' | translate}}
    </ng-container>
  </cog-settings-list-item-value-display>

  <cog-settings-list-item-value-editor [formGroup]="storageSnapshotFormGroup">
    <mat-slide-toggle formControlName="enabled" class="leverage-storage-snapshots-toggle">
      {{'leverageStorageSnapshotsForDataProtection' | translate}}
    </mat-slide-toggle>
    <mat-icon class="margin-left-sm hint-icon" [cogPopover]="popoverTemplate" >info</mat-icon>
    <div>
      <mat-form-field *ngIf="storageSnapshotFormGroup.get('enabled').value" class="row">
        <mat-select cogDataId="select-storage-snapshots"
          formControlName="snapshotsCarrier">
          <mat-option cogDataId="hyperflex" [inheritDataId]="true" value="hyperFlex">
            {{'hyperFlex' | translate}}
          </mat-option>
          <mat-option cogDataId="storageArray" [inheritDataId]="true" value="storageArray">
            {{'storageArraySlashNas' | translate}}
          </mat-option>
          <mat-option *ngIf="leverageNutanixSnapshots" cogDataId="nutanix" [inheritDataId]="true" value="nutanix">
            {{'nutanix' | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </cog-settings-list-item-value-editor>

  <ng-template #popoverTemplate>
    <div class="sql-node-popover-container">
      {{ getSnapshotInfo() | translate
      }}
    </div>
  </ng-template>
</cog-settings-list-item>
