/**
 * Enum of type of objects in kubernetes source.
 */
 export enum KubernetesProtectionTypes {
  kCluster = 'kCluster',
  kNamespace = 'kNamespace',
  kPersistentVolumeClaim = 'kPersistentVolumeClaim',
  kLabel = 'kLabel',
}

/**
 * Enum for exclude include pvcs
 */
export const enum KubernetesPvcFilterType {
  Include = 'include',
  Exclude = 'exclude',
}

// The type of sections in each rule.
export enum TypeSectionRule {
  labels = 'labels',
  preScript = 'preScript',
  postScript = 'postScript',
};
