import { AppModuleConfig, CanAccess, StateAccessMap } from 'src/app/app-module.config';

const clusterConnectAccess: CanAccess = ctx =>
  ctx.FEATURE_FLAGS.licensingRevampEnabled && !ctx.isHeliosTenantUser;

export const ClusterConnectConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'connected-site-ng.**',
      url: '/connected-site-new',
      loadChildren: () => import('./cluster-connect.module').then(m => m.ClusterConnectModule)
    }
  ],
  allClusterMap: {
    'connected-site-ng': {
      singleClusterState: 'connected-site-ng',
      heliosOnly: false,
      allClustersState: 'connected-site-ng'
    },
    'connected-site-ng.saas': {
      singleClusterState: 'connected-site-ng.saas',
      heliosOnly: false,
      allClustersState: 'connected-site-ng.saas'
    },
    'connected-site-ng.select-mode': {
      singleClusterState: 'connected-site-ng.select-mode',
      heliosOnly: false,
      allClustersState: 'connected-site-ng.select-mode'
    },
    'connected-site-ng.helios-token-claim': {
      singleClusterState: 'connected-site-ng.helios-token-claim',
      heliosOnly: false,
      allClustersState: 'connected-site-ng.helios-token-claim'
    }
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'connected-site-ng': clusterConnectAccess,
      'connected-site-ng.saas': clusterConnectAccess,
      'connected-site-ng.select-mode': clusterConnectAccess,
      'connected-site-ng.helios-token-claim': clusterConnectAccess
    };
  }
};
