// Module and Component: viewListing

;(function(angular, undefined) {
  'use strict';

  var modName = 'C.timelineView';
  var moduleDependencies = [];
  var componentName = 'timelineView';
  var options = {
    controller: 'TimelineViewCtrl',
    templateUrl: 'app/global/timeline-view/timeline-view.html',
    bindings: {
      /**
       * List of titles to display
       *
       * @type   {array}   [title]    Titles to be shown
       */
      titles: '<?',

      /**
       * Available upgrade paths
       *
       * @type   {array}   [items]    Upgrade Information.
       */
      timeline: '<?',

      /**
       * Show or hide the sub header
       *
       * @type   {boolean}   show or hides the sub heading
       */
      hidesubheader: '<?',
    },
    require: {
      ngModel: '^ngModel',
    },
  };

  angular
    .module(modName, moduleDependencies)
    .controller('TimelineViewCtrl', timelineViewCtrlFn)
    .component(componentName, options);

  /**
   * @ngdoc component
   * @name C.timelineView:cTimelineView
   * @function
   *
   * @description
   * Generates a timeline view of the available upgrade.
   *
   * @example
   *
   * <timeline-view></timeline-view>
   */
  function timelineViewCtrlFn(_, DateTimeService) {
    var $ctrl = this;
    $ctrl.shortDateFormat = DateTimeService.getPreferredDateFormat();

    /**
     * Init the controller
     *
     * @method   $onInit
     */
    $ctrl.$onInit = function $onInit() {
      var selectedRelease;

      // Return when it is undefined as there is no more processing.
      if (!$ctrl.timeline) {
        return;
      }

      selectedRelease = _.find($ctrl.timeline, '_checked');
      if (selectedRelease) {
        $ctrl.selectedItem = selectedRelease;
        $ctrl.ngModel.$setViewValue($ctrl.selectedItem);
      }
    };

    /**
     * On Change Function which sets the value of the current selected version.
     *
     * @method   selectItem
     */
    $ctrl.selectItem = function selectItem() {
      $ctrl.ngModel.$setViewValue($ctrl.selectedItem);
    };
  }

})(angular);
