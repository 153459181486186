import { SelectionModel } from '@angular/cdk/collections';
import { inject, Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ProtectionSourcesServiceApi } from '@cohesity/api/v1';
import {
  File,
  FilesAndFoldersObject,
  IndexedObjectSnapshot,
  ObjectServiceApi,
  ProtectedObject,
  Recovery as RecoveryApi,
  RecoveryServiceApi,
  SearchIndexedObjectsRequest,
  SearchServiceApi,
} from '@cohesity/api/v2';
import { DataFilterValue, SnackBarService, ValueFilterSelection } from '@cohesity/helix';
import { getConfigByKey, flagEnabled, IrisContextService, isDmsScope, isMcm } from '@cohesity/iris-core';
import { ConfirmationDialogComponent, IS_IBM_AQUA_ENV } from '@cohesity/shared/core';
import { getSnapshotObject } from '@cohesity/iris-shared-models';
import { AjaxHandlerService } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { Observable, of } from 'rxjs';
import { filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import {
  ClusterService,
  FileDownloadService,
  PassthroughOptionsService,
  SnapshotsService,
  TenantService,
  UserService,
} from 'src/app/core/services';
import { cloudGroups, Document, Environment, JobEnvParamsV2, PassthroughOptions, RecoveryAction } from 'src/app/shared';

import { FileSearchResult } from '../model/file-search-result';
import { FileSearchResultGroup } from '../model/file-search-result-group';
import { Recovery } from '../model/recovery';
import { RestorePointSelection } from '../model/restore-point-selection';
import { RestoreSearchResult } from '../model/restore-search-result';
import { DecoratedIndexedObjectSnapshot, GroupedObjectSnapshot } from '../snapshot-selector';
import { SnapshotSelectorUtilsService } from '../snapshot-selector/services/snapshot-selector-utils.service';
import { ObjectSearchProvider } from './object-search-provider';
import { RestoreConfigService } from './restore-config.service';
import { RestoreService } from './restore.service';

/**
 * Convenience type to reference a data filter value for a property filter
 */
type PropertyDataFilterValue = DataFilterValue<ProtectedObject, ValueFilterSelection[]>;

/**
 * Maximum number of selections allowed in snap mirror recovery.
 */
const MaxAllowedFilesSnapMirror = 8;

/**
 * Files Service. This should handle making the API tasks necessary to search
 * for and recover files.
 *
 * This implements ObjectSearchProvider, which is used to provide a search implementation for
 * The object search service.
 *
 * This is a temporary implementation based
 * on the private implementation. This will be updated as soon as the V2 apis are ready.
 */
@Injectable()
export class FilesService implements ObjectSearchProvider {
  /**
   * This is the restore operation type this restore service is configured for. Each restore component is
   * responsible for setting this value. This is used to look up all of the environments that should be
   * included in search filters.
   */
  restoreTypes: RecoveryAction[];

  /**
   * The list of environments to use for search queries. This is determined by the
   * list of registered adapters registered for for RecoverVMs
   */
  get searchEnvironments(): Environment[] {
    return this.restoreConfig.getSearchEnvironments(this.restoreTypes);
  }

  /**
   * Number of records to return in an api call.
   */
  count = 100;

  /**
   * Determines whether the UI is running in IBM Aqua mode.
   */
  readonly isIBMAquaEnv = inject(IS_IBM_AQUA_ENV);

  constructor(
    private clusterInfo: ClusterService,
    private ajaxService: AjaxHandlerService,
    private dialog: MatDialog,
    private downloadService: FileDownloadService,
    private irisContextService: IrisContextService,
    private objectsService: ObjectServiceApi,
    private protectionSourcesServiceApi: ProtectionSourcesServiceApi,
    private recoveryService: RecoveryServiceApi,
    private restoreConfig: RestoreConfigService,
    private restoreService: RestoreService,
    private searchService: SearchServiceApi,
    private snackBarService: SnackBarService,
    private stateService: StateService,
    private tenantService: TenantService,
    private translate: TranslateService,
    private userService: UserService,
    private passthroughOptionsService: PassthroughOptionsService,
    private snapshotsService: SnapshotsService,
    private snapshotSelectorUtilsService: SnapshotSelectorUtilsService
  ) {}

  /**
   * Gets a default RestorePointSelection for a selected file. This uses the object snapshot api to look
   * up available snapshots and chooses the most recent one.
   *
   * @param   file   The selected file
   * @return  A restore point selection object with the latest snapshot info.
   */
  getDefaultFileRestorePointSelection(file: FileSearchResult): Observable<RestorePointSelection> {
    return this.objectsService
      .GetIndexedObjectSnapshots({
        protectionGroupId: file.protectionGroupId,
        objectId: file.objectId,
        indexedObjectName: file.fullPath,
        includeIndexedSnapshotsOnly: true,
      })
      .pipe(
        tap(response => {
          if (!response || !response.snapshots || !response.snapshots.length) {
            throw new Error(this.translate.instant('noSnapshotsFound'));
          }
        }),
        map(response => response.snapshots.map(snapshot => new DecoratedIndexedObjectSnapshot(snapshot))),
        map(snapshots => GroupedObjectSnapshot.groupSnapshots(snapshots)),
        map(groupedSnapshots => groupedSnapshots[0].localSnapshot || groupedSnapshots[0].snapshots[0]),
        map(snapshotInfo => {
          // The interface used here is File. Technically inodeId is not a part of file.
          // Yoda file results returns inodeId which needs to be sent with
          // file info to magneto. File interface acts as a transporter here.
          // Copy the inodeId from snapshot to file info.
          (file.file as any).inodeId = (snapshotInfo as  IndexedObjectSnapshot).inodeId;
          return {
            objectInfo: new FileSearchResultGroup([file]),
            objectIds: [file.id],
            restorePointId: snapshotInfo.id,
            timestampUsecs: snapshotInfo.snapshotTimestampUsecs,
            archiveTargetInfo: snapshotInfo.externalTargetInfo,
            snapshotRunType: snapshotInfo.runType,
          };
        })
      );
  }

  /**
   * Creates the restore point selections from an existing recovery. This is used for the
   * resubmit flow.
   *
   * @param   recoveryApi   The recovery object to initialize to
   * @returns Selection objects suitable for display in the UI.
   */
  getFileSelectionsFromRecovery(recoveryApi: RecoveryApi): RestorePointSelection[] {
    const recovery = new Recovery(recoveryApi);

    if (!recovery.objects || !recovery.objects.length) {
      return [];
    }
    const object = recovery.objects[0].reference;
    const params = recoveryApi[JobEnvParamsV2[recovery.environment]];
    const { objectInfo, snapshotId, snapshotCreationTimeUsecs, storageDomainId } = object;
    const { recoverFileAndFolderParams } = params;

    const files: FileSearchResult[] = recoverFileAndFolderParams.filesAndFolders.map(fileParam => {
      const name = fileParam.absolutePath.split('/').pop();
      const path = fileParam.absolutePath.substr(0, fileParam.absolutePath.length - name.length - 1);
      return new FileSearchResult({
        name,
        path,
        inodeId: fileParam.inodeId,
        type: fileParam.isDirectory ? 'Directory' : 'File',
        protectionGroupId: object.protectionGroupId,
        storageDomainId: storageDomainId,
        sourceInfo: objectInfo,
      } as any);
    });
    const fileSelection = new FileSearchResultGroup(files);

    return [
      {
        objectInfo: fileSelection,
        objectIds: fileSelection.fileIds,
        restorePointId: snapshotId,
        timestampUsecs: snapshotCreationTimeUsecs,
        archiveTargetInfo: object.archivalTargetInfo as any,
      },
    ];
  }

  /**
   * Makes the api call for object search and returns the raw results.
   *
   * @param   query   The search query
   * @param   filters The filters to use for the search.
   * @param   count The filter to use for the search to return total records in a single api call.
   * @returns The raw response of the search api result.
   */
  searchForFiles(query: string, filters: DataFilterValue<any>[] = []): Observable<File[]> {
    const params: SearchIndexedObjectsRequest = {
      fileParams: {
        searchString: query,
        sourceEnvironments: this.searchEnvironments as any,
      },
      objectType: 'Files',
      useCachedData: flagEnabled(this.irisContextService.irisContext, 'useMagnetoCachedData'),
      count: this.count,
    };

    const sourceFilter: PropertyDataFilterValue = filters.find(item => item.key === 'source');
    const fileorFolderFilter: PropertyDataFilterValue = filters.find(item => item.key === 'fileOrFolder');
    const environmentFilter: PropertyDataFilterValue = filters.find(item => item.key === 'environment');
    const objectFilter: PropertyDataFilterValue = filters.find(item => item.key === 'object');
    const protectionGroupFilter: PropertyDataFilterValue = filters.find(item => item.key === 'protectionGroup');
    const storageDomainFilter: PropertyDataFilterValue = filters.find(item => item.key === 'storageDomain');

    // TODO: Source and Object filters need to be udpated in the API.
    if (sourceFilter) {
      params.fileParams.sourceIds = sourceFilter.value.map((entry: ValueFilterSelection) => entry.value as number);
    }

    if (fileorFolderFilter) {
      params.fileParams.types = fileorFolderFilter.value.map((entry: ValueFilterSelection) => entry.value as any);
    }

    if (environmentFilter && environmentFilter.value.length) {
      params.fileParams.sourceEnvironments = environmentFilter.value.map(
        (entry: ValueFilterSelection) => entry.value as any
      );

      // In case of Physical Source both the environments needs to be passed here
      // otherwise v2 protected objects doesn't return the file based objects.
      if (params.fileParams.sourceEnvironments.includes(Environment.kPhysical) &&
        !params.fileParams.sourceEnvironments.includes(Environment.kPhysicalFiles)) {
        params.fileParams.sourceEnvironments.push(Environment.kPhysicalFiles);
      }
    }

    if (objectFilter) {
      (params.fileParams as any).objectIds = objectFilter.value.map(
        (entry: ValueFilterSelection) => entry.value as number
      );
    }

    if (protectionGroupFilter) {
      params.protectionGroupIds = protectionGroupFilter.value.map(
        (entry: ValueFilterSelection) => entry.value as string
      );
    }

    if (storageDomainFilter) {
      params.storageDomainIds = storageDomainFilter.value.map((entry: ValueFilterSelection) => entry?.value as number);
    }

    return this.searchService.SearchIndexedObjects({ body: params }).pipe(map(res => (res && res.files) || []));
  }

  /**
   * Runs an object search.
   *
   * @param   query   The search query
   * @param   filters The filters to use for the search.
   * @returns Observable of an array of matching objects.
   */
  doObjectSearch(query: string, filters: DataFilterValue<any>[] = []): Observable<RestoreSearchResult[]> {
    return this.searchForFiles(query, filters).pipe(map(files => files.map(file => new FileSearchResult(file))));
  }

  /**
   * Created recovery task to download specified file directories.
   *
   * @param    snapshotId  Recovery Snapshot ID.
   * @param    files       List of file directories to create recovery download task for.
   * @param    regionId    The region id for where to create the download task.
   * @returns  Observable for when file recovery task is created.
   */
  createFileDownloadRecoveryTask(
    snapshotId: string,
    files: FileSearchResult[],
  ): Observable<RecoveryApi> {
    const filesAndFolders = files.map(({ fullPath, objectType, environment }) => ({
      absolutePath: environment === Environment.kKubernetes ? '/cohesity-data'+ fullPath : fullPath,
      isDirectory: objectType === 'Directory',
    }));

    // Hotfix: `InternalApiCreateDownloadFilesAndFoldersRecovery` endpoint is deprecated.
    // Update DMaaS DP clusters to new endpoints then change it in UI.
    return this.recoveryService.InternalApiCreateDownloadFilesAndFoldersRecovery({
      body: {
        name: this.restoreService.getDefaultTaskName('recovery.files.defaultTaskName'),
        object: {
          snapshotId,
        },
        filesAndFolders,
      },
      ...this.passthroughOptionsService.requestParams,
    });
  }


  /**
   * Created recovery task to download database backpac file.
   *
   * @param    snapshotId  Recovery Snapshot ID.
   * @returns  Observable for when file recovery task is created.
   */
  createDownloadDatabaseFilesRecoveryTask(snapshotId: string) {
    if (!snapshotId) {
      return;
    }
    // Hotfix: `InternalApiCreateDownloadFilesAndFoldersRecovery` endpoint is deprecated.
    // Update DMaaS DP clusters to new endpoints then change it in UI.
    return this.recoveryService.InternalApiCreateDownloadFilesAndFoldersRecovery({
      body: {
        name: this.restoreService.getDefaultTaskName('recovery.files.defaultTaskName'),
        object: {
          snapshotId,
        },
      },
      ...this.passthroughOptionsService.requestParams,
    }).pipe(
      map((recoverTask) => {
        if (recoverTask) {
          this.showRecoveryTaskSuccessMessage(recoverTask.id);
        }
        return recoverTask;
      }),
      this.ajaxService.catchAndHandleError()
    );
  }

  /**
   * Shows important message if needed and downloads selected files.
   *
   * @param selection  The files to download
   * @returns An observable of: null if the download is initiated directly, and the Recovery if a task was created.
   */
  downloadFiles(selection: RestorePointSelection): Observable<RecoveryApi> {
    const { clusterId } = getSnapshotObject(selection?.restorePointId) || {};
    const skipDialog = this.isIBMAquaEnv || getConfigByKey(
      this.irisContextService.irisContext,
      'recovery.skipDownloadFilesMessage',
      false,
    );

    // Shows a dialog if download cluster files via Helios.
    if (isMcm(this.irisContextService.irisContext) && clusterId && clusterId !== -1 && !skipDialog) {
      const data = {
        title: 'important',
        message: 'downloadFilesMessage',
        confirmLabel: 'download',
      };

      return this.dialog.open(ConfirmationDialogComponent, {data}).afterClosed().pipe(
        filter((res) => !!res),
        switchMap(
          () => this.downloadFilesAction(selection)
        ),
      );
    }
    return this.downloadFilesAction(selection);
  }

  /**
   * Downloads selected files only.
   *
   * @param selection  The files to download
   * @returns An observable of: null if the download is initiated directly, and the Recovery if a task was created.
   */
  private downloadFilesAction(selection: RestorePointSelection): Observable<RecoveryApi> {
    const { restorePointId, objectInfo } = selection;
    const { files } = objectInfo as FileSearchResultGroup;

    // Need to store the passthroughOptionsService regionId here because by the time
    // "View Progress' is clicked, the passthroughOptionsService regionId is reset by recover-files.guard.ts.
    const passthroughOptions = {
      regionId: this.passthroughOptionsService.regionId,
      accessClusterId: this.passthroughOptionsService.accessClusterId,
    };

    // Download multiple files/folder as a task
    return this.createFileDownloadRecoveryTask(restorePointId, files).pipe(
      tap(recoverTask => {
        if ((recoverTask as any)?.quorumResponse?.id) {
          // Don't show a regular snackbar message if the recovery
          // task is a quorum request.
          return;
        }
        this.showRecoveryTaskSuccessMessage(recoverTask.id, passthroughOptions);
      })
    );
  }

  /**
   * Downloads selected O365 files.
   *
   * @param selection  The O365 files to download
   * @returns An observable of: null because the download is initiated directly.
   */
  downloadO365File(selection: RestorePointSelection): Observable<RecoveryApi> {
    const { restorePointId, objectInfo, snapshot } = selection;
    const { files } = objectInfo as FileSearchResultGroup;
    let [{ fullPath }] = files;
    const o365RocksDBPathSuffix = '/metadata/rocksdb';
    if (!fullPath.includes(o365RocksDBPathSuffix)) {
      // O365 Indexed browse as it doesn't contain metadata/rocksdb

      // Need to modify path in case of single file download to directly
      // fetch file from rocksdb and download single file as a get call
      // Onedrive sample path: '/OneDrives/OneDrive-<od-id>/metadata/rocksdb/
      // abc/file.png' from fullPath:'/abc/file.png'
      // Sharepoint sample path: '/OneDrives/Master Page Gallery/metadata/
      // rocksdb/oslo.master' from fullPath:'/Master Page Gallery/oslo.master'
      if (snapshot?.environment as Environment === Environment.kO365OneDrive) {
        this.getOneDriveId(files[0].objectId).subscribe((driveId: string) => {
          const dirPath = '/OneDrives/OneDrive-' + driveId;
          fullPath = dirPath + o365RocksDBPathSuffix + fullPath;
          this.downloadService.v2RecoveryFileDownload(restorePointId, fullPath);
        });
      } else if (snapshot?.environment as Environment === Environment.kO365Sharepoint) {
        const doclibName = '/' + fullPath.split('/')[1];
        const pathInsideDoclib = fullPath.slice(doclibName.length);
        fullPath = '/OneDrives' + doclibName + o365RocksDBPathSuffix + pathInsideDoclib;
        this.downloadService.v2RecoveryFileDownload(restorePointId, fullPath);
      }
    } else {
      // Single file download via O365 non-indexed browse
      this.downloadService.v2RecoveryFileDownload(restorePointId, fullPath);
    }
    return of(null);
  }

  /**
   * Fetches OneDrive ID.
   * TODO(Vipul): Remove this and make the API response that fetches object
   * results contain the onedrive ID and pass it on to restorePointSelection to
   * prevent an additional API call
   *
   * @param selection  The files to download
   * @returns oneDrive ID
   */
  getOneDriveId(objectId: number): Observable<string> {
    const params: ProtectionSourcesServiceApi.ListProtectionSourcesParams = {
      id: objectId,
      ...this.passthroughOptionsService.requestParams,
    };
    return this.protectionSourcesServiceApi.ListProtectionSources(params).pipe(
      map(sources => sources[0]?.protectionSource),
      // eslint-disable-next-line
      map((node) => {
        return node?.office365ProtectionSource?.userInfo?.oneDriveId;
      }),
      this.ajaxService.catchAndHandleError(),
    );
  }



  /**
   * Validate a file recovery restore point selection and return an error message if it
   * can not be recovered.
   *
   * @param   restorePoints   The current selection
   * @returns A translated error message, or null if there are no errors.
   */
  validateSelectionForRecover(restorePoints: RestorePointSelection[]): string | null {
    if (!restorePoints || !restorePoints.length) {
      return this.translate.instant('noSelection');
    }

    const unavailableRestore = restorePoints.find(point =>
      this.snapshotSelectorUtilsService.getArchivalTargetIsDisabled(point.archiveTargetInfo));

    if (unavailableRestore) {
      return this.translate.instant(
        this.snapshotSelectorUtilsService.getArchivalTargetTooltip(unavailableRestore.archiveTargetInfo));
    }

    const environment = restorePoints[0].objectInfo.environment as Environment;

    if (!this.restoreConfig.getSearchEnvironments([RecoveryAction.RecoverFiles]).includes(environment)) {
      return this.translate.instant('downloadOnly');
    }

    // Recovery of snapshots from Tape Target is not supported if feature flag is not enabled.
    if (restorePoints[0]?.archiveTargetInfo?.targetType === 'Tape' &&
      !flagEnabled(this.irisContextService.irisContext, 'enableFileFolderRecoveryForTape')) {
      return this.translate.instant('recovery.disableMessage.tapeRecoverUnsupported');
    }

    // Files from cloud VMs can't directly be recovered from archives with the exception of AWS EC2 which now supported
    // They can be recovered from local snapshots or can be downloaded.
    // In DMaaS, archivals are backups.
    // File recoveries for GCP VMs work from NGCE archives.
    if (cloudGroups.cloud.includes(environment) && restorePoints[0].archiveTargetInfo
      && !isDmsScope(this.irisContextService.irisContext)
      && !(environment === Environment.kAWS)
      && !(this.clusterInfo.isClusterNGCE && environment === Environment.kGCP)) {
      return this.translate.instant('recovery.files.cloudFilesFromArchiveRecoveryError');
    }

    // Recovery from Cloud archived snapshots is not allowed for Hyx/bifrost tenants.
    if (!isDmsScope(this.irisContextService.irisContext) && this.userService.privs.HYBRID_EXTENDER_VIEW &&
      !flagEnabled(this.irisContextService.irisContext, 'irisExecAllowBifrostTenantArchivalRecovery') &&
      restorePoints.some(restorePoint =>
        restorePoint.archiveTargetInfo && restorePoint.archiveTargetInfo.ownershipContext !== 'FortKnox')) {
      return this.translate.instant('recovery.disableMessage.cloudSnapshot');
    }

    // If restore point selection has files with inodeIds, they are snapdiff protected.
    // Recovering a single snapdiff protected folder is supported.
    // Recovering multiple snapdiff protected folders or symlinks is not supported.
    // Both files and directories for snapdiff recovery are not supported.
    // Also, a maximum of 8 files can be recovered in snapdiff recovery.
    const allSelectedFiles = restorePoints[0].objectInfo as FileSearchResultGroup;

    // If restore point selection has files with inodeIds, they are snapdiff protected.
    // Filter selected snapdiff files that are directories
    const selectedSnapDiffDirectories = allSelectedFiles.files.filter(fileSelection => !!(fileSelection.file as any).inodeId && fileSelection.file.type === 'Directory');

    // If restore point selection has files with inodeIds, they are snapdiff protected.
    // Filter selected snapdiff files that are only files, not directories.
    const selectedSnapDiffFiles = allSelectedFiles.files.filter(fileSelection => !!(fileSelection.file as any).inodeId && fileSelection.file.type === 'File');

    // Check if more than one directory is selected for snapdiff recovery.
    if (selectedSnapDiffDirectories.length > 1 ) {
      return this.translate.instant('recovery.disableMessage.snapDiffFolderRecoveryUnsupportedMoreThanOneDirectory');
    }

    // Check if both files and directories are selected for snapdiff recovery.
    if(selectedSnapDiffFiles.length > 0 && selectedSnapDiffDirectories.length > 0){
      return this.translate.instant('recovery.disableMessage.snapDiffFolderRecoveryUnsupportedFilesAndDirectoryTogether');
    }

    // Check if any symlinks are selected for snapdiff recovery.
    if (allSelectedFiles.files.some(fileSelection => !!(fileSelection.file as any).inodeId &&
      fileSelection.file.type === 'Symlink')) {
      return this.translate.instant('recovery.disableMessage.symlinkDownloadUnsupported');
    }

    // Check if the number of selected files exceeds the maximum allowed for snapdiff recovery.
    if (allSelectedFiles.files.some(fileSelection => !!(fileSelection.file as any).inodeId) &&
      allSelectedFiles.files.length > MaxAllowedFilesSnapMirror) {
      return this.translate.instant('recovery.disableMessage.snapDiffMaximumAllowedFileSelections');
    }

    const transformer = this.restoreConfig.getTransformer(
      restorePoints[0].objectInfo.environment,
      RecoveryAction.RecoverFiles
    );
    if (transformer && transformer.getSelectionError) {
      return transformer.getSelectionError(restorePoints);
    }
    return null;
  }

  /**
   * Validate a file recovery restore point selection and return an error message if it
   * can not be downloaded.
   *
   * @param   restorePoints   The current selection
   * @param   selection       File and folder selection during browse
   * @returns A translated error message, or null if there are no errors.
   */
  validateSelectionForDownload(
    restorePoints: RestorePointSelection[],
    selection?: SelectionModel<Document>
  ): string | null {
    if (!restorePoints || !restorePoints.length) {
      return this.translate.instant('noSelection');
    }

    const restorePoint = restorePoints[0];
    const environment = restorePoint.objectInfo.environment as Environment;

    if (this.snapshotSelectorUtilsService.getArchivalTargetIsDisabled(restorePoint.archiveTargetInfo)) {
      return this.translate.instant(
        this.snapshotSelectorUtilsService.getArchivalTargetTooltip(restorePoint.archiveTargetInfo));
    }

    // SP can't download tenant's files while impersonating.
    if (this.tenantService.impersonatedTenantId && !this.userService.privs.RESTORE_DOWNLOAD) {
      return this.translate.instant('recovery.disableMessage.onlyTenantDownloadSupported');
    }

    if (restorePoint.snapshotRunType === 'kHydrateCDP') {
      return this.translate.instant('recovery.files.cdpDownloadNotSupported');
    }

    if (!this.restoreConfig.getSearchEnvironments([RecoveryAction.DownloadFilesAndFolders]).includes(environment)) {
      return this.translate.instant('recovery.disableMessage.downloadNotSupported');
    }

    // Downloading files from Tape Target is not supported if feature flag is not enabled.
    if (restorePoint.archiveTargetInfo && restorePoint.archiveTargetInfo.targetType === 'Tape' &&
      !flagEnabled(this.irisContextService.irisContext, 'enableFileFolderRecoveryForTape')) {
      return this.translate.instant('recovery.disableMessage.tapeDownloadUnsupported');
    }

    // Check both selected files, and the selection model to ensure that files selected during
    // browsing are found.
    const selectedFiles = restorePoint.objectInfo as FileSearchResultGroup;
    if (selectedFiles.files.some(fileSelection => fileSelection.file.type === 'Symlink') ||
       selection?.selected.some(file => file.type === 'Symlink')) {
      return this.translate.instant('recovery.disableMessage.symlinkDownloadUnsupported');
    }

    // If restore point selection has files with inodeIds, they are snapdiff protected.
    // Downloading snapdiff protected files is not supported.
    if (selectedFiles.files.some(fileSelection => !!(fileSelection.file as any).inodeId)) {
      return this.translate.instant('recovery.disableMessage.snapDiffDownloadUnsupported');
    }

    // If the files are not saved yet but user has selected the files during browse,
    // and if files have inodeIds, download files is not supported.
    if (selectedFiles.files.length === 0 && selection  && selection.selected &&
      selection.selected.some(file => !!(file as any).inodeId)) {
      return this.translate.instant('recovery.disableMessage.snapDiffDownloadUnsupported');
    }

    const transformer = this.restoreConfig.getTransformer(environment, RecoveryAction.DownloadFilesAndFolders);
    if (transformer && transformer.getSelectionError) {
      return transformer.getSelectionError(restorePoints);
    }
    return null;
  }

  /**
   * Function to show a success message when a download task is successfully created.
   *
   * @param recoveryTaskId The id of the task for state params.
   * @param passthroughOptions Passthrough options for switching to different scope.
   */
  showRecoveryTaskSuccessMessage(recoveryTaskId: string, passthroughOptions?: PassthroughOptions) {
    this.snackBarService
      .openWithAction(
        this.translate.instant('recoveryDownloadTaskSuccessfullyCreated'),
        this.translate.instant('viewProgress')
      )
      .subscribe(() =>
        this.stateService.go('recovery.detail', {
          id: recoveryTaskId,

          // The finalize in createFilesAndFoldersDownloadTask would have cleared
          // the passthrough options values, so fallback to passed values.
          regionId: this.passthroughOptionsService.regionId || passthroughOptions?.regionId,
          cid: this.passthroughOptionsService.accessClusterId || passthroughOptions?.accessClusterId,
        })
      );
  }

  /**
   * Function to create a download task for a set of files based on the object id
   * and protection group id.
   *
   * @param objectId The id of the object where the file is.
   * @param filesAndFolders The array of files and folders which are being recovered.
   * @param protectionGroupId The id of the protection group protecting the objects.
   * @param passthroughOptions If the request is a passthrough request.
   */
  createFilesAndFoldersDownloadTask(
    objectId: number,
    filesAndFolders?: FilesAndFoldersObject[],
    protectionGroupId?: string,
    passthroughOptions?: PassthroughOptions) {
    if (passthroughOptions?.regionId) {
      this.passthroughOptionsService.regionId = passthroughOptions.regionId;
    }

    if (passthroughOptions?.accessClusterId) {
      this.passthroughOptionsService.accessClusterId = passthroughOptions.accessClusterId;
    }

    // File search result doesn't have any snapshot information, so fetch that
    // first.
    this.objectsService.GetObjectSnapshots({
      id: objectId,

      // Only one item in the array since the files need to be in the same
      // protection group as they need to be in the same snapshot.
      protectionGroupIds: [protectionGroupId],
      ...this.passthroughOptionsService.requestParams,
    }).pipe(
      // Map to the latest snapshot.
      map(response => {
        const snapshotsMap = this.snapshotsService.getLatestSnapshotByTarget(response?.snapshots);

        // Pick the latest local snapshot, otherwise pick the latest archival or rpaas snapshot.
        return snapshotsMap.Local || snapshotsMap.Archival || snapshotsMap.FortKnox || Object.values(snapshotsMap)?.[0];
      }),
      switchMap(latestSnapshot => {
        if (!latestSnapshot) {
          return of(null);
        }

        // Create the task.
        // Hotfix: `InternalApiCreateDownloadFilesAndFoldersRecovery` endpoint is deprecated.
        // Update DMaaS DP clusters to new endpoints then change it in UI.
        return this.recoveryService.InternalApiCreateDownloadFilesAndFoldersRecovery({
          body: {
            name: this.restoreService.getDefaultTaskName('recovery.files.defaultTaskName'),
            object: {
              snapshotId: latestSnapshot.id,
            },
            filesAndFolders,
          },
          ...this.passthroughOptionsService.requestParams,
        });
      }),
      finalize(() => this.passthroughOptionsService.accessClusterId =  null),
    ).subscribe(recoverTask => {
      if (!recoverTask) {
        this.translate.instant('noMatchingSnapshotFound');

        return;
      }

      this.showRecoveryTaskSuccessMessage(recoverTask.id, passthroughOptions);
    });
  }
}
