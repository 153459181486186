<h2>{{'recoverTo' | translate}}</h2>
<ng-container [formGroup]="formGroup">
  <coh-recover-to-radio-group
    [control]="formGroupControls.type"
    [formGroup]="formGroup">
  </coh-recover-to-radio-group>

  <ng-container *ngIf="formGroupValues.type === recoverToOptions.newLocation">
    <div class="flex-row">
      <coh-recover-to-source-selector *ngIf="(servers$ | async) as servers"
        [formControlName]="formControlNames.newTarget"
        [servers]="servers">
      </coh-recover-to-source-selector>
    </div>
    <div class="flex-row">
      <mat-slide-toggle
        class="margin-top-sm"
        [formControlName]="formControlNames.overwrite"
        [inheritDataId]="true"
        cogDataId="restore-overwrite-toggle">
        {{ 'recovery.object.overwriteExisting' | translate }}
      </mat-slide-toggle>
    </div>
  </ng-container>
  <div *ngIf="(formGroupValues.type !== recoverToOptions.newLocation) || formGroupValues.overwrite"
    class="margin-top">
    <coh-textual-confirmation-input
      status="warn"
      [message]="'recovery.overwrite.warning' | translate"
      [formControlName]="formControlNames.overwriteConfirmation">
    </coh-textual-confirmation-input>
  </div>
</ng-container>
