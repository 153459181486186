<cog-settings-list-item [invalid]="formGroupErrors"
  cogFormGroup
  [label]="this.label || ('rename' | translate)">
  <cog-settings-list-item-value-display>
    <!-- Show valid string value of prefix and/or suffix -->
    <ul class="c-ul-inline">
      <li *ngIf="formGroupValues.prefix">
        {{'prefixColon' | translate}}{{' ' + formGroupValues.prefix}}
      </li>
      <li *ngIf="formGroupValues.suffix">
        {{'suffixColon' | translate}}{{' ' + formGroupValues.suffix}}
      </li>
      <li *ngIf="!formGroupValues.prefix && !formGroupValues.suffix">
        {{'none' | translate}}
      </li>
    </ul>
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor [formGroup]="formGroup">
    <!-- Prefix input option -->
    <mat-form-field [class.mat-form-field-invalid]="!formGroup.valid"
      [class.custom-height]="!!customPrefixError">
      <mat-label>{{'addPrefix' | translate}}</mat-label>
      <input cogDataId="rename-prefix-input"
        [formControlName]="formControlNames.prefix"
        [maxLength]="getPrefixMaxLength()"
        matInput
        type="text">

      <!-- Error if prefix value is invalid -->
      <mat-error *ngIf="formGroupErrors?.prefix?.pattern">
        {{customPrefixError || 'errors.invalidPrefixValue' | translate}}
      </mat-error>
    </mat-form-field>

    <!-- Suffix input option -->
    <mat-form-field [class.mat-form-field-invalid]="!formGroup.valid"
      [class.custom-height]="!!customSuffixError"
      class="margin-left-lg">
      <mat-label>{{'addSuffix' | translate}}</mat-label>
      <input cogDataId="rename-suffix-input"
        [formControlName]="formControlNames.suffix"
        [maxLength]="getSuffixMaxLength()"
        matInput
        type="text">

      <!-- Error if suffix value is invalid -->
      <mat-error *ngIf="formGroupErrors?.suffix?.pattern">
        {{customSuffixError || 'errors.invalidSuffixValue' | translate}}
      </mat-error>
    </mat-form-field>

    <!-- Show styled error if either if both of the fields are empty -->
    <mat-error *ngIf="formGroup?.errors?.prefixOrSuffixRequired">
      {{'cloneRecover.prefixOrSuffixRequired' | translate}}
    </mat-error>

    <cog-banner status="info" *ngIf="shouldDisplayInfoBanner">
      {{ 'recovery.pure.options.prefixSuffix' | translate }}
    </cog-banner>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
