// Component: SQL Database List

;(function(angular, undefined) {

  /**
   * @ngdoc component
   * @name C.sqlDashboard:sqlDatabaseList
   *
   * @description
   * SQL Databases data grid
   *
   * @example
   * <sql-database-list></sql-database-list>
   */
  angular.module('C.sqlDashboard')
    .controller('SqlDatabaseListController', DatabaseListCtrLFn)
    .filter('customDbSearch', customDbSearch)
    .component('sqlDatabaseList', {
      templateUrl: 'app/dashboard/sql/db-list/db-list.html',
      controller: 'SqlDatabaseListController',
    });


  /**
   * Component controller
   */
  function DatabaseListCtrLFn(
    _, evalAJAX, DbActionsService,  SqlDashboardService) {
    var $ctrl = this;

    $ctrl.databaseFilter = '';
    $ctrl.warningStates = [
      'kRestoring',
      'kRecovering',
      'kRecoveryPending',
      'kSuspect',
      'kCopying',
    ];
    $ctrl.disabledStates = ['kOffline'];
    $ctrl.errorStates =['kEmergency'];

    _.assign($ctrl, {
      dataReady: false,
      aagDatabases: [],
      fciDatabases: [],
      displayedDatabase: undefined,
      filterSelection: 'allDatabases',

      $onInit: $onInit,
    });

    /**
     * On onInit, set up the polling for the data call
     */
    function $onInit() {
      _getData(true);
    }

    /**
     * Make the actual call to retreive data
     *
     * @method   _getData
     * @param    {boolean}   [allowCache]   If true, allows a cached value to be
     *                                      returned, otherwise forces a full
     *                                      refresh. We need to force a refresh
     *                                      When a user action triggers reaload.
     * @returns   {object}   Promise with db data
     */
    function _getData(allowCache) {
      $ctrl.dataReady = false;
      return SqlDashboardService.getDatabases(!allowCache).then(
        function onSuccess(databases) {
          $ctrl.databases = databases.map(_processDatabase);
          $ctrl.displayedDatabase = $ctrl.databases;
          _categorizeDatabases();
        },
        evalAJAX.errorMessage)
        .finally(function gotDbData() {
          $ctrl.dataReady = true;
        });
    }

    /**
     * Convert to data object appropriate for grid display
     *
     * @param    {object}   node   Database instance node
     * @return   {object}   Object appropriate for UI display
     */
    function _processDatabase(db) {
      var protectionSource = db.protectionSource.sqlProtectionSource;
      var instance = protectionSource.name;
      var host = db._hostProtectionSource.name;
      return {
        node: db,
        name: protectionSource.databaseName,
        instanceName: [host, instance].join('/'),
        size: db.logicalSize,
        policy: db._protectionPolicy,
        job: db._job,
        snapshot: db._latestSnapshot,
        lastRunSort: _.get(db,
          '_latestSnapshot.instanceId.jobStartTimeUsecs', 0),
        searchName: [host, instance, protectionSource.databaseName].join('/'),
        recoveryModel: protectionSource.recoveryModel,
        dbState: protectionSource.sqlServerDbState,
        contextMenu: DbActionsService.getDbActions(db, _getData),
      };
    }

    /**
     * Category databases for filters.
     */
    function _categorizeDatabases() {
      $ctrl.databases.forEach(database => {
        var sqlSource = database.node.protectionSource.sqlProtectionSource;
        var physicalHost = database.node._hostProtectionSource.physicalProtectionSource;

        if ((sqlSource.type === 'kDatabase' && !!sqlSource.dbAagEntityId) ||
          sqlSource.type === 'kAAGDatabase') {
          $ctrl.aagDatabases.push(database);
        } else if (physicalHost && physicalHost.type === 'kWindowsCluster') {
          $ctrl.fciDatabases.push(database);
        }
      });
    }
  }

  /**
   * Allow searching for host/instance/name in one query. Automatically replace
   * '\' with '/' so either will work.
   *
   * @method   customDbSearch
   * @param    {Object}   $filter   the $filter provider
   * @return   {Object}   Filter that filters certain properties.
   */
  function customDbSearch($filter) {
    return function searchFilter(input, predicate) {
      if (predicate.searchName) {
        predicate.searchName = predicate.searchName.replace('\\', '/');
      }
      return $filter('filter')(input, predicate);
    };
  }

})(angular);
