/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GetIocThreatsResponse } from '../models/get-ioc-threats-response';
import { PaginationToken } from '../models/pagination-token';
import { Threat } from '../models/threat';
import { ThreatId } from '../models/threat-id';
import { ThreatParams } from '../models/threat-params';

@Injectable({
  providedIn: 'root',
})
export class IoCsThreatsApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getThreats
   */
  static readonly GetThreatsPath = '/ioc/threats';

  /**
   * Get the list of threat hunting threats.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getThreats()` instead.
   *
   * This method doesn't expect any request body.
   */
  getThreats$Response(params?: {

    /**
     * Specifies the filter by threat name.
     */
    name?: null | string;

    /**
     * Specifies wheather to include deleted threats or not.
     */
    includeDeleted?: null | boolean;

    /**
     * Specifies the maximum number of threats and rules returned per response and it should be less that 1000.
     */
    pageSize?: null | number;

    /**
     * Specifies the pagination token used to get the next page.
     */
    paginationToken?: PaginationToken;

  }): Observable<StrictHttpResponse<GetIocThreatsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, IoCsThreatsApiService.GetThreatsPath, 'get');
    if (params) {

      rb.query('name', params.name, {});
      rb.query('includeDeleted', params.includeDeleted, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('paginationToken', params.paginationToken, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetIocThreatsResponse>;
      })
    );
  }

  /**
   * Get the list of threat hunting threats.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getThreats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getThreats(params?: {

    /**
     * Specifies the filter by threat name.
     */
    name?: null | string;

    /**
     * Specifies wheather to include deleted threats or not.
     */
    includeDeleted?: null | boolean;

    /**
     * Specifies the maximum number of threats and rules returned per response and it should be less that 1000.
     */
    pageSize?: null | number;

    /**
     * Specifies the pagination token used to get the next page.
     */
    paginationToken?: PaginationToken;

  }): Observable<GetIocThreatsResponse> {

    return this.getThreats$Response(params).pipe(
      map((r: StrictHttpResponse<GetIocThreatsResponse>) => r.body as GetIocThreatsResponse)
    );
  }

  /**
   * Path part for operation createThreat
   */
  static readonly CreateThreatPath = '/ioc/threats';

  /**
   * Validate and create threat hunting threat.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createThreat()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createThreat$Response(params: {
      body: ThreatParams
  }): Observable<StrictHttpResponse<Threat>> {

    const rb = new RequestBuilder(this.rootUrl, IoCsThreatsApiService.CreateThreatPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Threat>;
      })
    );
  }

  /**
   * Validate and create threat hunting threat.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createThreat$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createThreat(params: {
      body: ThreatParams
  }): Observable<Threat> {

    return this.createThreat$Response(params).pipe(
      map((r: StrictHttpResponse<Threat>) => r.body as Threat)
    );
  }

  /**
   * Path part for operation deleteThreat
   */
  static readonly DeleteThreatPath = '/ioc/threats/{id}';

  /**
   * Delete a Threat.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteThreat()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteThreat$Response(params: {

    /**
     * Specifies the id of the threat.
     */
    id: ThreatId;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, IoCsThreatsApiService.DeleteThreatPath, 'delete');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete a Threat.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteThreat$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteThreat(params: {

    /**
     * Specifies the id of the threat.
     */
    id: ThreatId;

  }): Observable<void> {

    return this.deleteThreat$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
