import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AppStatus } from './filer-app-status.model';

/**
 * This pipe provides translated string value representing Filer app status
 *
 * @example
 *   {{ cluster.status | filerAppStatus }}     Installed | Not Installed | Connected
 */
@Pipe({ name: 'filerAppStatus' })
export class FilerAppStatusPipe implements PipeTransform {
  constructor(private translateService: TranslateService) { }

  transform(status: AppStatus): string {
    return this.translateService.instant(status ? `argus.enum.appStatus.${status}` : 'naNotAvailableLetters');
  }
}
