import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataFilter, DataFilterItem, DataFilterValue } from './data-filter';

export class SearchPropertyFilter implements DataFilter<string> {
  /**
   * The property/column name of an object to be filtered by
   */
  key: string;

  /**
   * A display label for a filter. Used to show filter chips
   */
  get tagValues$(): Observable<DataFilterItem[]> {
    return this.currentValue$.pipe(
      map(filterValue => filterValue ? [
        {
          label: this.label,
          value: filterValue.value,
          remove: () => this.removeValue(),
        },
      ] : [])
    );
  }

  /**
   * A ui friendly label for the filter name.
   */
  label: string;

  /**
   * Message to show when a filter is locked. If this is not set, the filter is unlocked.
   */
  lockedMessage: string;

  /**
   * The current value of the filter.
   */
  currentValue$ = new BehaviorSubject<DataFilterValue<string>>(undefined);

  /**
   * A filter predicate to use for a filter.
   */
  predicate(item: any, filter: string, key?: any): boolean {
    const data: any = item[key];
    if (!data) {
      return false;
    }
    filter = filter
      .toString()
      .trim()
      .toLowerCase();
    return (
      item[key]
        .toString()
        .toLowerCase()
        .indexOf(filter) !== -1
    );
  }

  /**
   * Sets the filter's value.
   *
   * @param   value   The value to set.
   */
  setValue(value: string) {
    if (!value) {
      this.currentValue$.next(undefined);
    } else {
      this.currentValue$.next({
        key: this.key,
        value: value,
        predicate: this.predicate,
      });
    }
  }

  /**
   * Removes the requested value from the filter.
   *
   * @param    value   The value to remove.
   */
  removeValue() {
    this.currentValue$.next(undefined);
  }
}
