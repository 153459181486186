import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AllParameterEncodingCodec, Api } from '@cohesity/api/private';
import { WindowRef } from '@cohesity/helix';

import { PassthroughOptionsService } from './passthrough-options.service';
import { RemoteClusterService } from './remote-cluster.service';
import { TenantService } from './tenant.service';

/**
 * The downloadfiles api is is private and we do not have TS documentation for it. This allows passing
 * any serializable params to the api.
 */
export interface FileDownloadParams {
  [param: string]: string | readonly string[];
}

/**
 * Service to expose the private downloadfiles api. This is part of the core module because it requires
 * additional logic from the remote cluster service in order to configure the url correctly.
 */
@Injectable({
  providedIn: 'root',
})
export class FileDownloadService {
  constructor(private remoteClusterService: RemoteClusterService,
    private tenantService: TenantService,
    private windowRef: WindowRef,
    private passthroughOptionsService: PassthroughOptionsService) {
  }

  /**
   * This uses the private downloadfiles api to open a new window and start a file download
   *
   * @param   params   The params to append to the api url.
   */
  downloadFile(params: FileDownloadParams) {
    const httpParams = this.getFileDownloadParams(params);
    const path = 'downloadfiles?' + httpParams.toString();
    this.windowRef.nativeWindow.open(this.decorateApiPathForRemote(Api.private(path)));
  }

  /**
   * Opens new browser tab to download specified file.
   *
   * @param  snapshotsId  Recovery snapshot ID.
   * @param  filePath     Full file path.
   */
  v2RecoveryFileDownload(snapshotsId: string, filePath?: string, downloadParams?: object) {
    // Omit filePath if undefined.
    const httpParams = this.getFileDownloadParams(downloadParams ?? {...(filePath && { filePath })});

    const path = Api.publicV2(`data-protect/snapshots/${snapshotsId}/downloadFile`);

    // TODO(alex): implement mcm when available
    this.windowRef.nativeWindow.open(`${path}?${httpParams.toString()}`);
  }

  /**
   * Gets the file download params required to download the file. Additionally,
   * sets the impersonated tenant id to the http params if in impersonation mode.
   *
   * @param  httpParams  The http params for the request.
   * @returns The modified http params.
   */
  private getFileDownloadParams(params: object): HttpParams {
    let httpParams = new HttpParams({
      fromObject: params as any,
      encoder: new AllParameterEncodingCodec()
    });

    if (this.remoteClusterService.isAccessCluster(this.remoteClusterService.selectedScope)) {
      httpParams = httpParams.append('clusterId', String(this.remoteClusterService.selectedScope.clusterId));
    } else if (this.passthroughOptionsService.regionId) {
      httpParams = httpParams.append('regionId', this.passthroughOptionsService.regionId);
    } else if (this.passthroughOptionsService.accessClusterId) {
      httpParams = httpParams.append('clusterId', String(this.passthroughOptionsService.accessClusterId));
    } else if (this.remoteClusterService.isMcmPassthrough) {
      httpParams = httpParams.append('clusterId', String(this.remoteClusterService.selectedScope.clusterId));
    }

    if (this.tenantService.impersonatedTenantId) {
      httpParams = httpParams.append('impersonatedTenantId', this.tenantService.impersonatedTenantId);
    }
    return httpParams;
  }

  /**
   * Conditionally adds "cluster/[remoteAccessClusterId]/" to an api url and
   * returns updated url string. NOTE Because header clusterId value can't be
   * injected when using $window.open, etc, backend provides special URL
   * handling which allows the clusterId to be specified via the URL
   * construction.
   *
   * @example
   *  Downloading a file via recovery gets opened in a new window. For local
   *  cluster the standard API url will work fine:
   *  /irisservices/api/v1/downloadfiles?etc=1&etc2=2
   *  When using SPOG/altClusterSelector to switch cluster context, this
   *  request needs a clusterId added via the URL (normally added via header):
   *  /irisservices/api/v1/cluster/11111111/downloadfiles?etc=1&etc2=2
   *
   *  For a public API url, the cluster/id/ needs to come before public/
   *  /irisservices/api/v1/public/actionPath
   *  For a remote cluster, this Would become:
   *  /irisservices/api/v1/cluster/11111111/public/actionPath
   *
   * @param    path   The API path to conditionally modify
   * @returns  The (possibly) updated API path.
   */
  private decorateApiPathForRemote(path: string): string {
    if (this.remoteClusterService.isAccessCluster(this.remoteClusterService.selectedScope)) {
      return path;
    }

    const remoteClusterPathSegment = '/cluster/' + this.remoteClusterService.selectedScope.clusterId;
    return path.slice(0, Api.root.length) + remoteClusterPathSegment + path.slice(Api.root.length);
  }

  /**
   * Get download File URL string for a recovery by ID.
   *
   * @param    id  Recovery ID
   * @returns  Download File URL string
   */
  getRecoveryDownloadUrl(id: string): string {
    const params: HttpParams = this.getFileDownloadParams({}).append('includeTenants', 'true');
    return Api.publicV2(`data-protect/recoveries/${id}/downloadFiles?${params.toString()}`);
  }
}
