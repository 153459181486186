import { Directive, ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'ajs-cluster-pub-ssh-key',
})
export class ClusterPubSshKeyComponent extends UpgradeComponent {

  constructor(injector: Injector, elementRef: ElementRef) {
    super('clusterPubSshKey', elementRef, injector);
  }
}
