<cog-settings-list-item [invalid]="control?.invalid"
  cogFormGroup
  [label]="'saveSuccessFiles' | translate">
  <cog-settings-list-item-value-display>
    {{ control?.value | boolLabel}}
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor>
    <mat-slide-toggle [formControl]="control"
      [inheritDataId]="true"
      cogDataId="save-success-files">
      {{label || ('saveSuccessFiles' | translate)}}
    </mat-slide-toggle>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
