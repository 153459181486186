<!-- TODO: The clickable icons within the button need to be their own buttons for better accessibility -->
<button cogDataId="quick-filter-button"
  [inheritDataId]="true"
  class="cog-quick-filter-button"
  type="button"
  [attr.aria-expanded]="isMenuOpen"
  aria-haspopup="true"
  [attr.aria-controls]="matMenuTriggerFor ? matMenuTriggerFor.panelId : selfManagedMenuPanelId"
  [attr.aria-label]="ariaLabel"
  [ngClass]="{
    selected: isSelected,
    'cog-form-button': formStyle
  }"
  [disabled]="locked"
  mat-stroked-button
  [trackingEvent]="{properties: {name: label}}">
  <div class="quick-filter-content">
    <span>{{ label }}</span>
    <cog-icon *ngIf="!isSelected || noClear"
      [ariaLabel]="intl.open"
      cogDataId="clear-button"
      [inheritDataId]="true"
      (click)="clear($event)"
      shape="arrow_drop_down" size="sm">
    </cog-icon>
    <cog-icon *ngIf="isSelected && !noClear"
      [ariaLabel]="intl.clear"
      cogDataId="clear-button"
      [inheritDataId]="true"
      (click)="clear($event)"
      shape="close" size="sm">
    </cog-icon>
  </div>
</button>
