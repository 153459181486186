import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  ViewEncapsulation,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MaListModule } from '@angular/material/legacy-list';
import {
  AccessScope,
  AccessScopeCreateOrUpdateRequest,
  AccessScopesApiService,
  Permission,
  Resource,
} from '@cohesity/api/guardian';
import { DataIdModule, FormsModule, IconModule } from '@cohesity/helix';
import { getMcmAccountId, getUserTenantId, IrisContextService } from '@cohesity/iris-core';
import { CohesitySharedFormsModule, NoEmptyArrayValidator } from '@cohesity/shared-forms';
import { AjaxHandlerService, ClearSubscriptions, DialogService } from '@cohesity/utils';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, finalize } from 'rxjs/operators';

import { AccessScopeDetailsForm } from '../../models';
import { ResourceSelectionDetailsComponent } from '../resource-selection-details/resource-selection-details.component';
import {
  ResourcesDialogComponent,
  ResourcesDialogData,
  ResourcesDialogResponse,
} from '../resources-dialog/resources-dialog.component';

/**
 * The interface to pass request data to this dialog.
 */
export interface AccessScopeDialogData {
  /**
   * The mode of this dialog.
   */
  mode: 'add' | 'edit';

  /**
   * Access scope that is being modified
   */
  accessScope?: AccessScope;

  /**
   * List of resources
   */
  resources: Resource[];

  /**
   * List of selected resources
   */
  selectedResources: Resource[];

  /**
   * Map of resouces by ID
   */
  resourcesByIdMap: Map<string, Resource>;

  /**
   * Indicates whether dialog has to be opened in read only mode
   */
  readOnly?: boolean;
}

/**
 * The interface to return a response to the caller.
 */
export interface AccessScopeDialogResponse {
  /**
   * Access scope that was added / modified
   */
  accessScope?: AccessScope;
}

@Component({
  standalone: true,
  imports: [
    ResourceSelectionDetailsComponent,
    CohesitySharedFormsModule,
    CommonModule,
    DataIdModule,
    FormsModule,
    IconModule,
    MaListModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  selector: 'coh-add-update-access-scope-dialog',
  templateUrl: './add-update-access-scope-dialog.component.html',
  styleUrls: ['./add-update-access-scope-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AddUpdateAccessScopeDialogComponent extends ClearSubscriptions implements OnInit {
  /**
   * Data injected into the dialog by parent component.
   */
  readonly data: AccessScopeDialogData = inject(MAT_DIALOG_DATA);

  /**
   * Represents the form.
   */
  formGroup = new FormGroup({
    name: new FormControl<string>(this.data.accessScope?.name, [Validators.required, Validators.maxLength(64)]),
    description: new FormControl<string>(this.data.accessScope?.description, [
      Validators.required,
      Validators.maxLength(256),
    ]),
    resources: new FormControl<Resource[]>(this.data.selectedResources || [], NoEmptyArrayValidator),
  });

  /**
   * Indicates whether the data is submitting.
   */
  readonly submitting$ = new BehaviorSubject<boolean>(false);

  constructor(
    private ajaxHandler: AjaxHandlerService,
    private cdr: ChangeDetectorRef,
    private dialogRef: MatDialogRef<AccessScopeDialogData>,
    private dialogService: DialogService,
    private irisCtx: IrisContextService,
    private accessScopesApiService: AccessScopesApiService
  ) {
    super();
  }

  ngOnInit() {
    if (this.data.readOnly) {
      this.formGroup.disable();
    }
  }

  /**
   * Handler for the 'validatedSubmit' event on the form.
   */
  onSubmit(): void {
    this.submitting$.next(true);
    const formValue = this.formGroup.value as AccessScopeDetailsForm;
    let req: Observable<AccessScope>;
    const body: AccessScopeCreateOrUpdateRequest = {
      name: formValue.name,
      description: formValue.description,
      permissions: [Permission.Edit],
      resourceIds: formValue.resources.map(resource => resource.id),
      memberIds: this.data.accessScope?.memberIds || [],
    };

    const params = {
      account_id: getMcmAccountId(this.irisCtx.irisContext),
      tenant_id: getUserTenantId(this.irisCtx.irisContext),
    };

    switch (this.data.mode) {
      case 'add':
        req = this.accessScopesApiService.createAccessScope({
          ...params,
          body,
        });
        break;
      case 'edit':
        {
          req = this.accessScopesApiService.updateAccessScopeById({
            ...params,
            id: this.data.accessScope?.id,
            body,
          });
        }
        break;
    }

    this.subscriptions.push(
      req.pipe(finalize(() => this.submitting$.next(false))).subscribe(accessScope => {
        const resp: AccessScopeDialogResponse = { accessScope };
        this.dialogRef.close(resp);
      }, this.ajaxHandler.handler)
    );
  }

  /**
   * Click handler for accessible items list item
   */
  onAccessibleItemsClick() {
    this.subscriptions.push(
      this.dialogService
        .open<ResourcesDialogComponent, ResourcesDialogResponse, ResourcesDialogData>(ResourcesDialogComponent, {
          autoFocus: false,
          panelClass: 'resources-dialog-panel-class',
          data: {
            resourceIds: (this.formGroup.get('resources')?.value || []).map(resource => resource.id),
            resources: this.data.resources,
            resourcesByIdMap: this.data.resourcesByIdMap,
            readOnly: this.data.readOnly,
          },
        })
        .pipe(filter(result => !!result))
        .subscribe(({ resourceIds }: ResourcesDialogResponse) => {
          this.formGroup.controls.resources.setValue(resourceIds.map(id => this.data.resourcesByIdMap.get(id)));
          this.cdr.detectChanges();
        })
    );
  }
}
