import { Type } from '@angular/core';
import { AzureSourceDetailsGuard } from 'src/app/core/state/guards/azure-source-details.guard';
import { AppLoaderGuard } from 'src/app/modules/app-loader';

import { StateGuard } from '../state-guard';
import { AccessManagementGuard } from './access-management.guard';
import { AccountFeatureFlags } from './account-feature-flags.guard';
import { ActivityRedirectGuard } from './activity-redirect.guard';
import { AntivirusGuard } from './antivirus.guard';
import { AppPillarsGuard } from './app-pillars.guard';
import { ApplianceManagerClusterCreateGuard } from './appliance-manager-cluster-create.guard';
import { AuditLogsGuard } from './audit-logs.guard';
import { BannerGuard } from './banner.guard';
import { BootstrapGuard } from './bootstrap.guard';
import { ChangePasswordGuard } from './change-password.guard';
import { ClusterCreateGuard } from './cluster-create.guard';
import { ClusterGuard } from './cluster.guard';
import { ContextGuard } from './context.guard';
import { DashboardsGuard } from './dashboards.guard';
import { DataTieringGuard } from './data-tiering.guard';
import { DmsMigrationGuard } from './dms-migration.guard';
import { DmsSetupGuard } from './dms-setup.guard';
import { DraasOnboardingGuard } from './draas-onboarding.guard';
import { FlashRecoverOnboardingGuard } from './flashrecover.guard';
import { GlobalSearchGuard } from './global-search.guard';
import { HealthGuard } from './health.guard';
import { HeliosMfaPromptGuard } from './helios-mfa-prompt.guard';
import { ImpersonationGuard } from './impersonation.guard';
import { JobDetailsGuard } from './job-details.guard';
import { JobModifyGuard } from './job-modify.guard';
import { JobsGuard } from './jobs.guard';
import { LdapGuard } from './ldap.guard';
import { LoginGuard } from './login.guard';
import { McmRecoverGuard } from './mcm-recover.guard';
import { UsersAndGroupsGuard } from './on-prem-users.guard';
import { PerformanceGuard } from './performance.guard';
import { PolicyGuard } from './policy.guard';
import { RecoverDetailGuard } from './recover-detail.guard';
import { RecoverGuard } from './recover.guard';
import { RemoteClustersGuard } from './remote-clusters.guard';
import { ReportingGuard } from './reporting.guard';
import { RestoreGuard } from './restore.guard';
import { RolesGuard } from './roles.guard';
import { SafeTransitionGuard } from './safe-transition.guard';
import { ScopeGuard } from './scope.guard';
import { SmartTierGuard } from './smart-tier.guard';
import { SourceDetailsGuard } from './source-details.guard';
import { SourcesGuard } from './sources.guard';
import { SSOGuard } from './sso.guards';
import { StateAccessGuard } from './state-access.guard';
import { StorageGuard } from './storage.guard';
import { SwitchAccountGuard } from './switch-account.guard';
import { TenantRegionsGuard } from './tenant-regions.guard';
import { UserGuard } from './user.guard';
import { ViewsGuard } from './views.guard';

export const stateGuards: Type<StateGuard>[] = [
  AccessManagementGuard,
  AccountFeatureFlags,
  ActivityRedirectGuard,
  AntivirusGuard,
  AppLoaderGuard,
  AppPillarsGuard,
  AuditLogsGuard,
  AzureSourceDetailsGuard,
  BannerGuard,
  BootstrapGuard,
  ChangePasswordGuard,
  ClusterCreateGuard,
  ApplianceManagerClusterCreateGuard,
  ClusterGuard,
  ContextGuard,
  DashboardsGuard,
  DataTieringGuard,
  DmsMigrationGuard,
  DmsSetupGuard,
  DraasOnboardingGuard,
  FlashRecoverOnboardingGuard,
  GlobalSearchGuard,
  HealthGuard,
  HeliosMfaPromptGuard,
  ImpersonationGuard,
  JobDetailsGuard,
  JobModifyGuard,
  JobsGuard,
  LdapGuard,
  LoginGuard,
  McmRecoverGuard,
  PerformanceGuard,
  PolicyGuard,
  RecoverDetailGuard,
  RecoverGuard,
  RemoteClustersGuard,
  ReportingGuard,
  RestoreGuard,
  RolesGuard,
  SafeTransitionGuard,
  ScopeGuard,
  SmartTierGuard,
  SourceDetailsGuard,
  SourcesGuard,
  SSOGuard,
  StateAccessGuard,
  StorageGuard,
  SwitchAccountGuard,
  TenantRegionsGuard,
  UserGuard,
  UsersAndGroupsGuard,
  ViewsGuard,
];
