import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacyRadioChange as MatRadioChange, MatLegacyRadioModule } from '@angular/material/legacy-radio';
import { DataIdDirective } from '../../data-id/data-id.directive';
import { NgFor } from '@angular/common';

@Component({
  selector: 'cog-carousel-indicators',
  templateUrl: './carousel-indicators.component.html',
  styleUrls: ['./carousel-indicators.component.scss'],
  standalone: true,
  imports: [MatLegacyRadioModule, NgFor, DataIdDirective]
})
export class CarouselIndicatorsComponent {
  slides: number[] = [];

  /**
   * total number of items
   */
  @Input()
  set totalItems(count: number) {
    this.slides = Array(count);
  }

  /**
   * Current active item
   */
  @Input()
  activeIndex = 0;

  /**
   * Click handler.
   */
  @Output() indicatorSelected = new EventEmitter<number>();

  /**
   * onChange handler for radio group
   *
   * @param param0 radio group value change event
   */
  onChange({ value }: MatRadioChange) {
    this.indicatorSelected.emit(value);
  }
}
