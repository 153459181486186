import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  ArrayPropertyKey,
  ArrayPropertyValue,
  Controls,
  NgxFormWithArrayControls,
  NgxSubFormRemapComponent,
  subformComponentProviders,
} from 'ngx-sub-form';
import { Environment, MongoDBEntities } from 'src/app/shared/constants';

import { EntityTypeToIconMap } from '../../../model/nosql-common';
import { NoSqlObjectPropertyOption } from '../../../model/nosql-form-options';
import { NoSqlSearchResult } from '../../../model/nosql-search-results';
import { RestorePointSelection } from '../../../model/restore-point-selection';
import { NoSqlService } from '../../../services/nosql.services';
import { ObjectPropertyDetails, ObjectPropertyForm } from './recovery-property.model';

/**
 * Settings list item to display Object proprties.
 */
@Component({
  selector: 'coh-settings-list-object-property',
  templateUrl: './settings-list-object-property.component.html',
  styleUrls: ['./settings-list-object-property.component.scss'],
  providers: subformComponentProviders(SettingsListObjectPropertyComponent),
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsListObjectPropertyComponent extends NgxSubFormRemapComponent<NoSqlObjectPropertyOption,
  ObjectPropertyForm> implements NgxFormWithArrayControls<ObjectPropertyForm>, OnChanges {

  constructor(
    private translate: TranslateService,
    private noSqlService: NoSqlService,
  ) {
    super();
  }

  /**
   * The selected recovery points to show details for.
   */
  @Input() objects: RestorePointSelection[] = [];

  /**
   * Environment Type.
   */
  @Input() environment: Environment;

  /**
   * NoSql selected objects.
   */
  private selectedObjects: NoSqlSearchResult[];

  /**
   * Listen for changes to the inputs and outputs and update the component
   * bindings if necessary.
   *
   * @param   changes   sumary of changed properties on the component.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.objects?.currentValue) {
      this.selectedObjects = this.noSqlService.getNoSqlSelectedObjects(this.objects);
      if (this.selectedObjects.length) {
        this.initiateForm();
      }
    }
  }

  /**
   * Initialize objects rename option form.
   */
  initiateForm() {
    this.formGroupControls.objectDetails.clear();
    this.selectedObjects.filter(object =>

      // Filter out MongoDB object type 'kDatabase' from the object properties list.
      !((object.environment === Environment.kMongoDB) &&
        (object.objectType === MongoDBEntities.kDatabase))
    ).map(object => {
      this.formGroupControls.objectDetails.push(
        this.createFormArrayControl('objectDetails', {
          name: object.name,
          objectProperties: [{
            key : '',
            value: '',
          }],
          icon: EntityTypeToIconMap[object.environment][object.objectType],
          type: object.objectType
        }),
      );
    });
  }

  /**
   * Dispay value string based on form group value.
   *
   * @param   objectDetails Form value.
   * @returns string value if some properties were updated.
   */
  getValueDisplay(objectDetails: ObjectPropertyDetails[]): string {
    let count = 0;
    objectDetails?.forEach(object => {
      if (object.objectProperties && object.objectProperties.length > 1) {
        count++;
      } else if ((object.objectProperties && object.objectProperties.length === 1)
        && (object.objectProperties[0].key !== '' && object.objectProperties[0].value !== '')) {
        count++;
      }
    });
    return this.translate.instant(count > 0 ? 'objectsPropertiesAdded' : 'none');
  }

  protected transformToFormGroup(): ObjectPropertyForm {
    return {
      objectDetails: this.formGroupControls.objectDetails?.value?.map((item) => ({
        name: item.name,
        objectProperties: item.objectProperties,
        icon: item.icon,
        type: item.type
      }))
    };
  }

  protected transformFromFormGroup(form: ObjectPropertyForm): NoSqlObjectPropertyOption {
    return {
      objectDetails: form.objectDetails.map(object => ({
        name : object.name,
        objectProperties: object.objectProperties,
        icon: object.icon,
        type: object.type
      }))
    };
  }

  getDefaultValues(): Partial<ObjectPropertyForm> {
    return {
      objectDetails: []
    };
  }

  protected getFormControls(): Controls<ObjectPropertyForm> {
    return {
      objectDetails: new UntypedFormArray([]),
    };
  }

  public createFormArrayControl(
    key: ArrayPropertyKey<ObjectPropertyForm> | undefined,
    value: ArrayPropertyValue<ObjectPropertyForm>,
    ): UntypedFormControl {
    return new UntypedFormControl(value);
  }
}
