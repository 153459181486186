import { ChangeTypeCount, FileChangeType, PolicyRunResultList, PolicyRunResultType } from '@cohesity/api/argus';

/**
 * Change type constants mapper
 */
export const FileChangeTypeOrder = {
  [FileChangeType.KAny]: 5,
  [FileChangeType.KAffected]: 4,
  [FileChangeType.KUnaffected]: 3,
  [FileChangeType.KAddedOrModified]: 2,
  [FileChangeType.KDeleted]: 1,
};

/**
 * Sorts all the stats based on changeType priority
 *
 * @param a first value
 * @param b second value
 * @returns matching flag
 */
export const sortChangeTypeCallbackFn = (a: ChangeTypeCount, b: ChangeTypeCount) => {
  // ordered as per FileStatsDisplayOrder
  const aDisplayPosition = FileChangeTypeOrder[a.changeType];
  const bDisplayPosition = FileChangeTypeOrder[b.changeType];
  if (aDisplayPosition < bDisplayPosition) {
    return 1;
  }
  if (aDisplayPosition > bDisplayPosition) {
    return -1;
  }
  return 0;
};

/**
 * Finds the required change type and get us the count of it
 *
 * @param changeTypeStats Holds change types
 * @param fileChangeType destinated change type
 */
export const getFileChangeTypeCount = (changeTypeStats: ChangeTypeCount[], fileChangeType: FileChangeType): number =>
  changeTypeStats.find(changeTypeStat => changeTypeStat.changeType === fileChangeType)?.count;

/**
 * Computes affected file stats with files added, modified and deleted.
 * If there is no affected files stats it will add.
 *
 * @param changeTypeStats Holds change types
 */
export const computeAffectedFileStats = (changeTypeStats: ChangeTypeCount[]): ChangeTypeCount[] => {
  const addedModifiedCount = getFileChangeTypeCount(changeTypeStats, FileChangeType.KAddedOrModified) ?? 0;
  const deletedCount = getFileChangeTypeCount(changeTypeStats, FileChangeType.KDeleted) ?? 0;
  const affectedFilesCount = getFileChangeTypeCount(changeTypeStats, FileChangeType.KAffected);

  if (!affectedFilesCount) {
    changeTypeStats.push({
      changeType: FileChangeType.KAffected,
      count: addedModifiedCount + deletedCount,
    });
  }

  return changeTypeStats;
};

/**
 * Extracts unique patterns from policy results
 *
 * @param policyResults policy results
 * @returns unique pattern count
 */
export const getUniqPatternCount = (policyResults: PolicyRunResultList = []): number => {
  const patterns = new Set<string>();
  const queue = [...policyResults];
  while (queue.length) {
    let count = queue.length;
    if (count === 0) {
      break;
    }
    while (count > 0) {
      const node = queue.shift();
      if (node.type === PolicyRunResultType.pattern) {
        patterns.add(node.pattern.id);
      }
      node.results?.forEach(result => queue.push(result));
      count--;
    }
  }
  return patterns.size;
};
