import { AppModuleConfig, StateAccessContext } from 'src/app/app-module.config';

// General access for CDP items.
export const generalCdpAccess = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.cdpEnabled &&
  ctx.isClusterUser &&
  !ctx.isHeliosTenantUser &&
  !ctx.isRestrictedUser &&
  !ctx.isTenantUser;

export const CdpConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'cdp.**',
      url: '/cdp',
      loadChildren: () => import('./cdp.module').then(m => m.CdpModule),
    },
  ],
  allClusterMap: {},
  getStateAccessMap() {
    return {
      'cdp': generalCdpAccess,
      'cdp.object': generalCdpAccess,
      'cdp.object.summary': generalCdpAccess,
      'cdp.object.details': generalCdpAccess,
    };
  },
};
