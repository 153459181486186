import { Injectable } from '@angular/core';
import { getClusterConfigPartial } from '@cohesity/data-govern/security-center';
import { AppName, ClusterConfig } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';

import { AppServiceConfig, AppServiceType } from '../app-service.config';
import { SecurityCenterNavService } from './security-center-nav.service';

/**
 * This service configures the Security Center service configuration within iris.
 */
@Injectable({ providedIn: 'root' })
export class SecurityCenterServiceConfig extends AppServiceConfig {
  serviceType: AppServiceType = 'dgaas';

  appName: AppName = 'securityCenter';
  get homeState() {
    return 'argus';
  }

  get clusterConfigPartial() {
    return {
      name: this.serviceTitle,
      ...getClusterConfigPartial(),
    } as Partial<ClusterConfig>;
  }

  // Conditionally suports global search based on logic in AppLayoutComponent.
  supportsGlobalSearch = false;

  navProvider = this.navService;

  constructor(
    private navService: SecurityCenterNavService,
    translate: TranslateService,
  ) {
    super(translate);
  }
}
