import { Injectable } from '@angular/core';
import { AppConfig } from '@cohesity/iris-core';
import { addErrorHandler, registerApplication, start } from 'single-spa';

import { CustomProps } from '../../shared';
import { MicroFrontendHostOptions } from './micro-frontend-host.options';
import { ScriptLoaderService } from './script-loader.service';

@Injectable({
  providedIn: 'root',
})
export class AppLoaderService {
  /**
   * Indicates whether app are registered or not.
   */
  isStarted = false;

  constructor(
    private microFrontendHostOptions: MicroFrontendHostOptions,
    private scriptLoaderService: ScriptLoaderService,
  ) {}

  /**
   * Initialize & start the single-spa for the registered apps.
   */
  start(appConfigs: AppConfig[]) {
    this.registerApps(appConfigs || []);

    // start single-spa which will start watching route changes and will automatically mount/unmount
    // bases on app's registration.
    start();

    this.isStarted = true;

    // capture exceptions while loading apps.
    addErrorHandler(err => {
      // logging the error reason why we are not able to load the app.
      console.error('AppLoaderService: Error while loading', err.appOrParcelName, err);

      // taking user to home page instead of showing the black page.
      this.microFrontendHostOptions.options.goHome();
    });
  }

  /**
   * Register apps.
   *
   * @param The list of app configs.
   */
  registerApps(appConfigs: AppConfig[]) {
    appConfigs.forEach(app => {
      // skipping apps lacking main file because they are loaded manually by host iris app.
      // eg. @cohesity/cluster-manager refers existing helios/iris and its used only for switch back
      // to helios/iris context from app's context.
      if (!app.publicPath) {
        return;
      }

      registerApplication<CustomProps>({
        name: app.libName,

        // method to load the application.
        app: () => this.scriptLoaderService.loadScript(app.publicPath, app.libName),

        // method indicating when to activate the application.
        activeWhen: location => location.pathname.includes(app.urlSegment),

        // additional props to be passed to the application from IRIS app.
        customProps: (_appName, location) => this.microFrontendHostOptions.options.getCustomPropsFn(app, location),
      });
    });
  }
}
