// Component: Rating

; (function (angular, undefined) {
  'use strict';

  angular.module('C.appsManagement')
  .controller('AppRatingCtrl', AppRatingCtrlFn)
  .component('cRating', {
    templateUrl: 'app/apps-management/app-store/rating/rating.html',
    controller: 'AppRatingCtrl',
    bindings: {
      /**
       * Optional attribute provided either to enable rating component or not
       *
       * @type   {boolean}   Whether rating component to be enabled or readonly
       */
      readonly: '<?',

      /**
       * Optional attribute provided to specify the size of rating icon
       *
       * @type   {String}   determines icon size (ex: 'md' or 'lg') and icon
       *                    size is 'sm' by default
       */
      size: '&?',
    },

    // Required outside things. These show up on `this.ngModel` in the
    // controller.
    require: {
      ngModel: 'ngModel',
    },
  });

  /**
   * @ngdoc component
   * @name C.appsManagement:cRating
   * @function AppRatingCtrlFn
   *
   * @description
   * Provides rating and related functionality to an application.
   *
   * @example
     <c-rating
       ng-model="$ctrl.appDetails.avgRating"
       readonly="true">
     </c-rating>
   */
  function AppRatingCtrlFn(_) {
    var $ctrl = this;

    _.assign($ctrl, {
      max: 5,

      // Methods
      toggleSelected: toggleSelected,

      //component life cycle methods
      $onInit: $onInit,
    });

    /**
     * Update the star based on rating value
     *
     * @method   updateStars
     */
    function updateStars() {
      $ctrl.stars = [];
      for (var i = 0; i < $ctrl.max; i++) {
        $ctrl.stars.push({
          filled: i < $ctrl.ngModel.$viewValue,
        });
      }
    };

    /**
     * Changes state either as filled or unfilled
     *
     * @method   toggleSelected
     * @param    {number}   index   position of selected star
     */
    function toggleSelected(index) {
      if (!$ctrl.readonly) {
        $ctrl.ngModel.$setViewValue(index + 1);
        updateStars();
      }
    }

    /**
     * Initializes the controller
     *
     * @method   $onInit
     */
    function $onInit() {
      $ctrl.ngModel.$render = function $render() {
        updateStars();
      };
    }

  }
})(angular);
