import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const changePasswordAccess =
  (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.enableForcePasswordChange;

export const FlashRecoverOnboardingConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'flashrecover-onboarding.**',
      url: '/flashrecover',
      loadChildren: () => import('./flashrecover-onboarding.module').then(m => m.FlashRecoverOnboardingModule),
    },
  ],
  getStateAccessMap(): StateAccessMap {
    return {
      'flashrecover-onboarding': changePasswordAccess,
      'flashrecover-onboarding.landing': changePasswordAccess,
    };
  }
};
