import { Component, DoCheck, EventEmitter, HostBinding, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { NavItem } from '@cohesity/helix';
import { StateService } from '@uirouter/core';

/**
 * This is copied from apps/iris-ui-angular/src/app/shared/nav-item-menu but remove dependency of
 * StateManagementService.
 * We cannot simply move it because there are a lot of dependencies in iris-ui-angular which may or may not depend on
 * StateManagementService eventually.
 *
 * This component displays a material button which will trigger a material menu displaying a NavItem[] list.
 *
 * @example
 *   Simple Usage:
 *   <coh-nav-item-menu [navItems]="itemsList" [title]="listOfThings | translate" icon="things">
 *     Extra Content to be Displayed Above the Provided NavItems
 *   </coh-nav-item-menu>
 */
@Component({
  selector: 'coh-list-item-actions',
  templateUrl: './list-item-actions.component.html',
  styleUrls: ['./list-item-actions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListItemActionsComponent implements DoCheck {
  /** Id value to be passed through to button that initiates menu. */
  @Input() menuId?: string;

  /** List of NavItems to be displayed. */
  @Input() navItems: NavItem[] = [];

  /** Flag to indicate if the icon of the item should be displayed in dropdown. */
  @Input() showIconInDropdown = false;

  /** Title of the menu. */
  @Input() title = '';

  /** Tooltip of the menu. */
  @Input() tooltip;

  /** ARIA label for the menu. */
  @Input() ariaLabel;

  /** The icon to be display in the button. */
  @Input() icon = 'more_vert';

  /**
   * Dictates how many items to show as icons before rolling additional options into a flyout menu.
   */
  @Input() exposeIconCount = 0;

  /**
   * Dictates how many items to show as text before rolling additional options into a flyout menu.
   * NOTE: setting this to more than 1 will require some visual changes.
   */
  @Input() exposeButtonCount = 0;

  /**
   * Show larger menu item if size = 'lg'.
   */
  @Input() size: 'lg' | 'md';

  /** Output event when a nav item is clicked. */
  @Output() clickNavItem = new EventEmitter<NavItem>();

  /**
   * Adds a class to the component whenever the menu is opened.
   */
  @HostBinding('class.is-open') isOpen = false;

  /**
   * Allow control of menu open/close
   */
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  /** Populated with those NavItems that we show as buttons. */
  exposedNavItemButtons: NavItem[] = [];

  /** Populated with those NavItems that we show as icons. */
  exposedNavItemIcons: NavItem[] = [];

  /** Populated with those NavItems that we show don't expose. */
  unexposedNavItems: NavItem[] = [];

  constructor(
    private state: StateService,
  ) {}

  /** Handles NavItem clicked and emits event. */
  onClickNavItem(item: NavItem) {
    this.clickNavItem.emit(item);
    if (item.action) {
      item.action();
    }
  }

  ngDoCheck() {
    this.updateNavItemAccess(this.navItems);
    this.updateItemExposure(this.navItems);
  }

  /**
   * Recreate exposed/unexposed NavItem arrays whenever there is some change. The reason for this is
   * that the array might be modified in place.
   *
   * @params items The list of nav items.
   */
  updateItemExposure(items: NavItem[]) {
    this.exposedNavItemIcons = [];
    this.exposedNavItemButtons = [];
    this.unexposedNavItems = [];
    (items || []).forEach(item => {
      if (!item.subNavList?.length && item.icon && this.exposedNavItemIcons.length < this.exposeIconCount) {
        this.exposedNavItemIcons.push(item);
      } else if (!item.subNavList?.length && this.exposedNavItemButtons.length < this.exposeButtonCount) {
        this.exposedNavItemButtons.push(item);
      } else {
        this.unexposedNavItems.push(item);
      }
    });
  }

  /**
   * Manually update the hidden property on nav items based on the state and canAcess checks.
   * It would be better to save a copy and not modify in place, but there are too many places
   * that dynamically modify this array and we would not pick up changes otherwise. The only
   * way to solve this without risking regression is to call this from doCheck to make sure we
   * are always up to date
   *
   * @param items The list of nav items
   */
  updateNavItemAccess(items: NavItem[]) {
    (items || []).forEach(item => {
      this.updateNavItemAccess(item.subNavList);
      item.hidden = item.hidden ||
        (item.subNavList && item.subNavList.every(child => child.hidden));
    });
  }

  /**
   * Handle enter key pressed on menu item.
   *
   * @param item  Menu item.
   */
  onEnter(item: NavItem) {
    this.onClickNavItem(item);
    if (!item.subNavList?.length && !item.disabled && item.state) {
      this.state.go(item.state, item.stateParams, item.stateOpts);
    }
    if (this.trigger.menuOpen) {
      // Without setTimeout, menu closes and reopens
      window.setTimeout(() => {
        this.trigger.closeMenu();
      });
    }
  }
}
