import { Resource, ResourceType } from '@cohesity/api/guardian';

/**
 * Models related to custom rbac page under access management
 */

/**
 * Inputs for the add access dialog.
 */
export interface AddAccessDialogInput {
  /**
   * Mode of the dialog.
   */
  mode: 'add' | 'edit';
}

/**
 * Represents a row in the access table.
 */
export interface AccessTableRow {
  /**
   *
   */
  id: string;

  /**
   * access scope name
   */
  name: string;

  /**
   * access scope description
   */
  description: string;

  /**
   * Resources associated with the access scope
   */
  resources: Resource[];
}

/**
 * Represents the actions allowed on the access table row.
 */
export enum AccessTableRowActionId {
  edit,
  delete,
}

/**
 * Represents an action taken by the user on a row in the access table.
 */
export interface AccessTableRowAction {
  /**
   * The action which was taken.
   */
  action: AccessTableRowActionId;

  /**
   * The row object.
   */
  rowItem: AccessTableRow;
}

/**
 * Summary of selected resource by type
 */
export type ResourceSelectionSummary = Partial<Record<ResourceType, number>>;

/**
 * Params to fetch access scope
 */
export interface GetAccessScopeParams {
  /**
   * Account ID
   */
  account_id: string;

  /**
   * Tenant ID
   */
  tenant_id?: string;

  /**
   * Access scope ids to be fetched
   */
  accessScopeIds?: string[];

  /**
   * Member ID to filter access scopes
   */
  memberId?: string;
}
