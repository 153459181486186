import { isEmpty, keys, isObject } from 'lodash';

/**
 * Tests whether the provided value is an empty array|object.
 */
const isEmptyCollection = (object: any) => !['string', 'number'].includes(typeof object) && isEmpty(object);

/**
 * Prunes an object of all empty object values.
 */
export const pruneObject = (object: object) => {
  if (!isObject(object)) {
    return;
  }
  keys(object).forEach(function(key) {
    const localObj = object[key];
    if (isEmptyCollection(localObj)) {
      delete object[key];
      return;
    }
    // Is object, so call recursively
    if (isObject(localObj)) {
      pruneObject(localObj);
    }
    if (isEmptyCollection(localObj)) {
      delete object[key];
      return;
    }
  });
};

/**
 * Utility compare method for table sorting.
 *
 * @param    a      Number or string to compare.
 * @param    b      Number or string to compare.
 * @param    isAsc  Whether sorting direction is ascending.
 */
export function strCompare(a: number | string, b: number | string, isAsc: boolean = true): number {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
