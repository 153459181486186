import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { ProtectionSourceNode, SourceSpecialParameter } from '@cohesity/api/v1';
import { OracleDbChannel } from '@cohesity/api/v2';
import { DataTreeFilter, DataTreeFilterUtils, DataTreeSelection } from '@cohesity/helix';
import { IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { FilterOption, SourceSelection } from '@cohesity/iris-source-tree';
import { Environment, OracleBackupType } from 'src/app/shared/constants';

import { BaseProtectionSourceService } from '../shared/base-protection-source.service';
import { OracleChannelSettingsComponent } from './oracle-channel-settings/oracle-channel-settings';
import { OracleSourceDataNode } from './oracle-source-data-node';


/**
 * Source tree service for Oracle.
 */
@Injectable({
  providedIn: 'root',
})
export class OracleSourceTreeService extends BaseProtectionSourceService<OracleSourceDataNode> {

  /**
   * Oracle doesn't support auto protection.
   */
  usesAutoProtection = false;

  /**
   * Additional filter options related to oracle source tree
   */
  oracleFilterOptions: FilterOption[];

  /**
   * ctor
   */
  constructor(
    private contextService: IrisContextService) {
    super();
    this.oracleFilterOptions = [
      {
        id: 'selected-objects',
        label: 'selectedObjects',
        filter: this.selectedObjectsFilter,
      }
    ];
    this.filterOptions.addFilterOptions(this.oracleFilterOptions);
  }

  /**
   * Gets a component to render for a source's special parameters. This does not apply to every node
   * and can be null for certain data types.
   */
  getSpecialParametersComponent(node: OracleSourceDataNode): ComponentType<any> {
    if (node.type === 'kDatabase' &&
        node.environment === Environment.kOracle &&
        node.isMultiNodeMultiChannelFeatureSuppported) {
      return OracleChannelSettingsComponent;
    }
    return null;
  }

  /**
   * Filters a list of nodes which are selected.
   *
   * @param   nodes The list of nodes to filter.
   * @return  The filtered selected node list.
   */
  selectedObjectsFilter: DataTreeFilter<any> = (nodes: OracleSourceDataNode[]) =>
    DataTreeFilterUtils.searchFilter(nodes, this.treeControl,
      (node: OracleSourceDataNode) =>
      this.dataTreeSelection.isSelected(node) ||
      this.dataTreeSelection.isAutoSelected(node),
      true,
      true
    );

  /**
   * Returns whether a node is a leaf or not. This can be used to calculate selection totals.
   */
  isLeaf(treeNode: OracleSourceDataNode): boolean {
    return treeNode.isLeaf;
  }

  /**
   * Transforms the node object from the API struct as defined in 'ProtectionSourceNode' to its equivalent
   * 'OracleSourceDataNode' to be passed to the Data tree.
   *
   * @param    node    Specifies the Protection Source Node
   * @param    level   Specifies the level in the tree.
   * @return   Instance of OracleSourceDataNode.
   */
  transformData(node: ProtectionSourceNode, level: number): OracleSourceDataNode {
    return new OracleSourceDataNode(node, level, this.treeControl, isDmsScope(this.contextService.irisContext));
  }

  /**
   * Transforms the data tree selection model to the Job selection Model and returns an instance of SourceSelection
   * with the 'oracleSpecialParameters' poulated within the same.
   * Refer 'SourceSpecialParameter' for details.
   *
   * @param    selection   Specifies the currently selected data from the tree.
   * @return   Instance of SourceSelection.
   */
  transformFromDataTreeSelection(selection: DataTreeSelection<OracleSourceDataNode>): SourceSelection {
    // Container-Leaf relationship Map
    const oracleLeafContainerMap = new Map<number, number[]>();

    // Maps database id to its Database Nodes & Channels configuration.
    const oracleDatabaseSettingsMap = new Map<number, OracleDbChannel>();

    // Populate Container-Leaf relationship Map to optimize lookups.
    selection.selected.map((node: OracleSourceDataNode) => {
      // Push Only Host nodes(kPhysical) as keys of the Map.
      if (node.protectionSource.environment === 'kPhysical' && !oracleLeafContainerMap.has(Number(node.id))) {
        oracleLeafContainerMap.set(node.id as number, []);
      }

      // Add Databases.
      if (node.protectionSource.environment === 'kOracle') {
        if (oracleLeafContainerMap.has(node.protectionSource.parentId)) {
          // Append the container's app entities with the database Id and update the Map.
          const appEntities = oracleLeafContainerMap.get(node.protectionSource.parentId);
          appEntities.push(node.id as number);
          oracleLeafContainerMap.set(node.protectionSource.parentId, appEntities);
        } else {
          // Add a new container with the selected database entity.
          oracleLeafContainerMap.set(node.protectionSource.parentId, [node.id as number]);
        }
        // In case of DMaaS, db unique name, uuid, backuptype must be provided for Oracle.
        if (isDmsScope(this.contextService.irisContext)) {
          const dbChannel = [{
            databaseUniqueName: node.protectionSource.oracleProtectionSource.databaseUniqueName,
            databaseUuid: node.protectionSource.oracleProtectionSource.uuid,
            rmanBackupType: OracleBackupType.kSbt
          }] as any;
          oracleDatabaseSettingsMap.set(Number(node.id), dbChannel);
        }
      }
    });
    // Populate Database Multi node multi channel settings map to optimize lookups.
    // The field 'selection.options' is populated within OracleChannelSettingsComponent. Refer to the same for details.
    if (selection.options && Object.keys(selection.options)) {
      for (const databaseId in selection.options) {
        if (Object.prototype.hasOwnProperty.call(selection.options, databaseId)) {
          if (selection.options[databaseId]) {
            oracleDatabaseSettingsMap.set(Number(databaseId), selection.options[databaseId].nodeChannelList);
          }
        }
      }
    }

    if (!oracleLeafContainerMap.size) {
      return;
    }

    return {
      sourceIds: [...oracleLeafContainerMap.keys()].concat(selection.autoSelected.map(item => item.id as number)),
      sourceSpecialParameters: [...oracleLeafContainerMap.keys()].map((containerId: number) => ({
        sourceId: containerId,

        // Refer 'OracleSpecialParameters' for details.
        oracleSpecialParameters: {
          applicationEntityIds: oracleLeafContainerMap.get(containerId),

          // Refer 'OracleAppParams' for details.
          appParamsList: [...oracleDatabaseSettingsMap.keys()].map((databaseId: number) => ({
            databaseAppId: databaseId,

            // Refer 'OracleDatabaseNodeChannel' for details.
            nodeChannelList: oracleDatabaseSettingsMap.get(databaseId),
          }))
        },
      })),
    };
  }

  /**
   * Transforms the source selection model to the Data tree selection model.
   *
   * @param    allNodes          Specifies array of OracleSourceDataNode
   * @param    sourceSelection   Specifies the Job selection Model
   * @return   Data tree selection Model for OracleSourceDataNode
   */
  transformToDataTreeSelection(allNodes: OracleSourceDataNode[],
    sourceSelection: SourceSelection): DataTreeSelection<OracleSourceDataNode> {
    // Holds only leaf database IDs.
    const selectedDatabaseIdSet = new Set<number>();

    const selection: DataTreeSelection<OracleSourceDataNode> = {
      autoSelected: [],
      excluded: [],
      selected: [],
      options: {},
    };

    if (!sourceSelection) {
      return selection;
    }

    // Populate Databases selected.
    if (Array.isArray(sourceSelection.sourceSpecialParameters) && sourceSelection.sourceSpecialParameters.length) {
      sourceSelection.sourceSpecialParameters.forEach((sourceParam: SourceSpecialParameter) => {
        sourceParam.oracleSpecialParameters.applicationEntityIds.forEach(
          databaseId => selectedDatabaseIdSet.add(databaseId));

        // TODO(tauseef): Check the type of the OracleAppParams within YAML.
        sourceParam.oracleSpecialParameters.appParamsList.forEach((oracleAppParams: any) => {
          if (oracleAppParams.nodeChannelList && oracleAppParams.nodeChannelList.length) {
            selection.options[oracleAppParams.databaseAppId] = oracleAppParams;
          }
        });
      });
    }

    allNodes.forEach(node => {
      node.isJob = true;
      const nodeId = Number(node.id);
      if (selectedDatabaseIdSet.has(nodeId)) {
        node.inCurrentJob = true;
        selection.selected.push(node);
      }
    });

    return selection;
  }

}
