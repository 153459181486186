import { AppModuleConfig, StateAccessMap } from 'src/app/app-module.config';

export const HelpConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'help.**',
      url: '^/help',
      loadChildren: () => import('./help.module').then(m => m.HelpModule),
    },
  ],
  allClusterMap: {
    'help.center': {
      // singleClusterState is not actual relevant. User's won't be shown this option
      // in the help navigation.
      singleClusterState: 'help.center',
      allClustersState: 'help.center',
      globalContext: true,
    }
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'help.center': () => true,
    };
  }
};
