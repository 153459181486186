<div class="db-info-container">
  <div class="object-name">
    <cog-icon [shape]="node.protectionSource | objectIcon : { isProtected: isNodeProtected }">
    </cog-icon>
    <h4 class="title">{{node.protectionSource.name}}</h4>
  </div>

  <!-- Template: Oracle Entity Details Popover -->
  <div class="margin-left-sm">
    <ul *ngIf="isDatabase">
      <!-- Database size -->
      <li *ngIf="node.logicalSize" class="source-meta-line-item">
        <span class="source-meta-label">{{'databaseSize' | translate}}</span>
        {{node.logicalSize | byteSize}}
      </li>
      <!-- Database type -->
      <li class="source-meta-line-item">
        <span class="source-meta-label">{{'databaseType' | translate}}</span>
        {{dbConfigTypeString}}
      </li>
      <!-- Oracle DG Standby info -->
      <li *ngIf="isOracleDGStandby" class="source-meta-line-item">
        <span class="source-meta-label">{{'standbyType' | translate}}</span>
        {{standbyType}}
      </li>
      <!-- Oracle Rac instances info -->
      <li *ngIf="isRacDatabase" class="source-meta-line-item">
        <span class="source-meta-label">{{'oracleRACInstances' | translate}}</span>
        {{oracleSource?.hosts?.length}}
      </li>
      <!-- DB Creation Date -->
      <li *ngIf="oracleSource?.id?.createdDateMsecs" class="source-meta-line-item">
        <span class="source-meta-label">{{'createdDate' | translate}}</span>
        {{oracleSource.id.createdDateMsecs | date: 'short'}}
      </li>
      <!-- Oracle Home -->
      <li class="source-meta-line-item">
        <span class="source-meta-label">{{'oracleHome' | translate}}</span>
        {{oracleSource.hosts[0].sessions[0].location}}
      </li>
      <!-- BCT Enabled -->
      <li class="source-meta-line-item">
        <span class="source-meta-label">{{'bctEnabled' | translate}}</span>
        {{oracleSource.bctEnabled | yesNo | translate}}
      </li>
      <!-- TDE Enabled -->
      <li class="source-meta-line-item">
        <span class="source-meta-label">{{'tdeEnabled' | translate}}</span>
        {{(oracleSource?.tdeEncryptedTsCount > 0) | yesNo | translate}}
      </li>
      <!-- Archive Log Enabled -->
      <li class="source-meta-line-item">
        <span class="source-meta-label">{{'archiveLogEnabled' | translate}}</span>
        {{oracleSource.archiveLogEnabled | yesNo | translate}}
      </li>
      <!-- Oracle version -->
      <li class="source-meta-line-item">
        <span class="source-meta-label">{{'version' | translate}}</span>
        {{oracleSource.version}}
      </li>
      <!-- System Global Area Size -->
      <li class="source-meta-line-item">
        <span class="source-meta-label">{{'oracleSources.systemGlobalArea' | translate}}</span>
        {{oracleSource.sgaTargetSize}}
      </li>
      <!-- Shared Pool Size -->
      <li class="source-meta-line-item">
        <span class="source-meta-label">{{'sharedPoolSize' | translate}}</span>
        {{oracleSource.sharedPoolSize | naLabel}}
      </li>
      <!-- Temporary Files Count -->
      <li class="source-meta-line-item">
        <span class="source-meta-label">{{'tempFiles' | translate}}</span>
        {{oracleSource.tempFilesCount | naLabel}}
      </li>
      <!-- Fast Recovery Area Size -->
      <li class="source-meta-line-item">
        <span class="source-meta-label">{{'oracleRestore.fraSize' | translate}}</span>
        {{oracleSource.fraSize | byteSize | naLabel}}
      </li>
      <!-- System Identifier -->
      <li class="source-meta-line-item">
        <span class="source-meta-label">{{'sids' | translate}}</span>
        <span *ngFor="let host of oracleSource.hosts; let last = last;">
          {{host?.sessions[0]?.systemIdentifier}}
          <span *ngIf="!last">,</span>
        </span>
      </li>
      <!-- PDB information within CDB -->
      <li *ngIf="oracleSource?.containerDatabaseInfo?.pluggableDatabaseInfoList?.length">
        <span class="source-meta-label">
          {{'enum.oracleConfigurationType.pluggableDatabases' | translate}}
        </span>
        {{oracleSource.containerDatabaseInfo.pluggableDatabaseInfoList.length}}
        <div class="margin-top-sm" *ngFor="let pdb of oracleSource.containerDatabaseInfo.pluggableDatabaseInfoList">
          <ul class="pdb-list">
            <li>
              <h5 class="margin-bottom-xs">{{'name' | translate}}</h5>
              {{pdb.name}}
            </li>
            <li>
              <h5 class="margin-bottom-xs">{{'guid' | translate}}</h5>
              {{pdb.databaseId}}
            </li>
            <li>
              <h5 class="margin-bottom-xs">{{'openMode' | translate}}</h5>
              {{getDatabaseOpenMode(pdb.openMode)}}
            </li>
            <li>
              <h5 class="margin-bottom-xs">{{'size' | translate}}</h5>
              {{pdb.sizeBytes | byteSize | naLabel}}
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>

  <coh-protection-status-meta class="margin-top-lg" [node]="node"></coh-protection-status-meta>
</div>