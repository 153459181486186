import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AttributeFilter } from '@cohesity/api/reporting';
import { FiltersComponent, ValueFilterSelection } from '@cohesity/helix';
import { Observable, of } from 'rxjs';

import { BaseReportFilterComponent } from '../base-report-filter.component';

/**
 * Possible Snapshot Type Values.
 */
const snapshotType = [
  'Archival',
  'CloudVault',
  'Local',
];

@Component({
  selector: 'iris-rpt-snapshot-type-filter',
  templateUrl: './snapshot-type-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnapshotTypeFilterComponent extends BaseReportFilterComponent {
  constructor(filters: FiltersComponent) {
    super(filters, 'snapshotType');
  }

  getFilterValues(): Observable<ValueFilterSelection[]> {
    return of(
      snapshotType.map(value => ({
        value,
        label: this.translate(`enum.global.${value}`),
      }))
    );
  }

  toApiValue(filterValues: ValueFilterSelection[]): AttributeFilter {
    if (!filterValues?.length) {
      return null;
    }
    return {
      attribute: this.property,
      filterType: 'In',
      inFilterParams: {
        attributeDataType: 'String',
        stringFilterValues: filterValues.map(value => value.value as string),
        attributeLabels: filterValues.map(value => value.label),
      },
    };
  }

  fromApiValue(apiValue: AttributeFilter): ValueFilterSelection[] {
    return (apiValue?.inFilterParams.stringFilterValues || []).map((value, index) => ({
      label: apiValue.inFilterParams.attributeLabels[index],
      value: value,
    }));
  }
}
