<div>
  <div class="dl-row">
    <dt translate="primaryHost"></dt>
    <dd>{{ cassandraParams?.primaryHost }}</dd>
  </div>
  <div class="dl-row">
    <dt translate="source.cassandra.configurationDirectory"></dt>
    <dd>{{ cassandraParams?.configDirectory }}</dd>
  </div>
  <div class="dl-row">
    <dt translate="source.cassandra.cassandraDistribution"></dt>
    <dd>
      {{ cassandraParams?.dseConfigDirectory | boolLabel:'source.cassandra.dse':'source.cassandra.apacheCassandra' }}
    </dd>
  </div>
  <div class="dl-row" *ngIf="!cassandraParams?.dseConfigDirectory">
    <dt translate="source.cassandra.cassandraVersion"></dt>
    <dd>{{ cassandraParams?.cassandraVersion }}</dd>
  </div>
  <div class="dl-row" *ngIf="cassandraParams?.dseConfigDirectory">
    <dt translate="source.cassandra.dseVersion"></dt>
    <dd>{{ cassandraParams?.dseVersion }}</dd>
  </div>
  <div class="dl-row" *ngIf="cassandraParams?.dseConfigDirectory">
    <dt translate="source.cassandra.dseConfigurationDirectory"></dt>
    <dd>{{ cassandraParams?.dseConfigDirectory }}</dd>
  </div>
  <div class="dl-row" *ngIf="cassandraParams?.dseConfigDirectory">
    <dt translate="source.cassandra.dseTieredStorage"></dt>
    <dd>{{ isDseTieredStorage | boolLabel:'enabled':'disabled' }}</dd>
  </div>
  <div class="dl-row" *ngIf="cassandraParams?.dseConfigDirectory">
    <dt translate="source.cassandra.dseSolr"></dt>
    <dd>{{ isDseSolr | boolLabel:'enabled':'disabled' }}</dd>
  </div>
  <div class="dl-row" *ngIf="cassandraParams?.seeds">
    <dt translate="seeds"></dt>
    <dd>{{ cassandraParams?.seeds?.join(", ") }}</dd>
  </div>
  <div class="dl-row">
    <dt translate="customName"></dt>
    <dd>{{ sourceCustomName }}</dd>
  </div>
  <div class="dl-row">
    <dt translate="nativeTransportPort"></dt>
    <dd>{{ cassandraParams?.cassandraPortsInfo?.nativeTransportPort }}</dd>
  </div>
  <div class="dl-row">
    <dt translate="rpcPort"></dt>
    <dd>{{ cassandraParams?.cassandraPortsInfo?.rpcPort }}</dd>
  </div>
  <div class="dl-row">
    <dt translate="storagePort"></dt>
    <dd>{{ cassandraParams?.cassandraPortsInfo?.storagePort }}</dd>
  </div>
  <div class="dl-row">
    <dt translate="sslStoragePort"></dt>
    <dd>{{ cassandraParams?.cassandraPortsInfo?.sslStoragePort }}</dd>
  </div>
  <div class="dl-row">
    <dt translate="jmxPort"></dt>
    <dd>{{ cassandraParams?.cassandraPortsInfo?.jmxPort }}</dd>
  </div>
  <div class="dl-row" *ngIf="cassandraParams?.dataCenters">
    <dt translate="dataCenters"></dt>
    <dd>{{ cassandraParams?.dataCenters?.join(", ") }}</dd>
  </div>
  <div class="dl-row" *ngIf="cassandraParams?.commitLogBackupLocation">
    <dt translate="source.cassandra.commitLog"></dt>
    <dd>{{ cassandraParams?.commitLogBackupLocation }}</dd>
  </div>
</div>
