/**
 * Returns a string to display and a class to apply based on the riskScore
 *
 * @param translate Instance of translation service to return risk string
 * @param riskScore The riskScore of the system
 * @returns Object containing string to display risk score and a class to apply
 *          to widget based on risk score
 */
export const getRiskProperties = (translate, riskScore): {str: string; class: string} => {
  if (riskScore <= 70) {
    return {
      str: translate.instant('dg.sc.dashboard.highRisk'),
      class: 'high'
    };
  } else if (riskScore <= 90) {
    return {
      str: translate.instant('dg.sc.dashboard.mediumRisk'),
      class: 'medium'
    };
  } else {
    return {
      str: translate.instant('dg.sc.dashboard.lowRisk'),
      class: 'low'
    };
  }
};
