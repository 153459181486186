import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Label pipe can be leveraged to output a ui.json translated label, followed
 * by a semicolon (in English) and the provided value.
 *
 * Usage:
 *   uiJsonKey | label: object.value
 *
 * Example:
 *   {{ ('policy' | label: policy.name }}         // "Policy: Silver"
 *   {{ 'policy' | label }}                       // "Policy: "
 */
@Injectable({ providedIn: 'root' })
export class LabelPipeService implements PipeTransform {
  constructor(private translate: TranslateService) {}

  /**
   * Outputs the provided ui.json key with a colon and provided value.
   * See above example.
   *
   * @param labelKey       The input value.
   * @param labeledValue   The value to be displayed next to cooresponding label.
   */
  transform(labelKey: string, labeledValue: string | number): string {
    const labelStr = this.translate.instant(labelKey);

    if (typeof labeledValue === 'undefined') {
      return this.translate.instant('label', { label: labelStr });
    }

    return this.translate.instant('labelColonValue', { label: labelStr, value: labeledValue });
  }
}

@Pipe({ name: 'label' })
export class LabelPipe extends LabelPipeService implements PipeTransform { }
