import { CellColorStates } from './cell-colors.constants';

/**
 * An enum for all possible vaulting statuses.
 */
export enum VaultingStatus {
  notVaulted = 'notVaulted',
  vaulted = 'vaulted',
  unknown = 'unknown',
}

/**
 * A map of vaulting statuses to the corresponding display label key.
 */
export const VaultingStatusLabelKeys: Record<VaultingStatus, string> = {
  [VaultingStatus.notVaulted]: 'notVaulted',
  [VaultingStatus.vaulted]: 'vaulted',
  [VaultingStatus.unknown]: '-',
};

/**
 * A map of vaulting statuses to the background cell color.
 */
export const VaultingStatusColors: Record<VaultingStatus, CellColorStates> = {
  [VaultingStatus.notVaulted]: CellColorStates.high,
  [VaultingStatus.vaulted]: CellColorStates.ok,
  [VaultingStatus.unknown]: CellColorStates.unknown,
};

/**
 * Mapped tag names for the vaulting statuses.
 */
export const vaultingTagNames: Record<VaultingStatus, string> = {
  [VaultingStatus.notVaulted]: 'Object is not vaulted',
  [VaultingStatus.vaulted]: 'Object is vaulted',
  [VaultingStatus.unknown]: '',
};
