/**
 * Angular version of AJS SOURCE_TYPE_DISPLAY_NAME constant object
 * It has mapping from Environment, Source type to translation key string to
 * fetch the translated display string
 */
export const sourceTypesDisplayNames = {
  kVMware: {
    kVCenter: 'enum.sourceType.kVMware.kVCenter',
    kFolder: 'enum.sourceType.kVMware.kFolder',
    kDatacenter: 'enum.sourceType.kVMware.kDatacenter',
    kComputeResource: 'enum.sourceType.kVMware.kComputeResource',
    kClusterComputeResource:
      'enum.sourceType.kVMware.kClusterComputeResource',
    kResourcePool: 'enum.sourceType.kVMware.kResourcePool',
    kDatastore: 'enum.sourceType.kVMware.kDatastore',
    kHostSystem: 'enum.sourceType.kVMware.kHostSystem',
    kVirtualMachine: 'enum.sourceType.kVMware.kVirtualMachine',
    kVirtualApp: 'enum.sourceType.kVMware.kVirtualApp',
    kStandaloneHost: 'enum.sourceType.kVMware.kStandaloneHost',
    kStoragePod: 'enum.sourceType.kVMware.kStoragePod',
    kNetwork: 'enum.sourceType.kVMware.kNetwork',
    kDistributedVirtualPortgroup:
      'enum.sourceType.kVMware.kDistributedVirtualPortgroup',
    kTagCategory: 'enum.sourceType.kVMware.kTagCategory',
    kTag: 'enum.sourceType.kVMware.kTag',
    kvCloudDirector: 'enum.sourceType.kVMware.kvCloudDirector',
  },

  kHyperV: {
    kSCVMMServer: 'enum.sourceType.kHyperV.kSCVMMServer',
    kStandaloneHost: 'enum.sourceType.kHyperV.kStandaloneHost',
    kStandaloneCluster: 'enum.sourceType.kHyperV.kStandaloneCluster',
    kHostGroup: 'enum.sourceType.kHyperV.kHostGroup',
    kHypervHost: 'enum.sourceType.kHyperV.kHypervHost',
    kHostCluster: 'enum.sourceType.kHyperV.kHostCluster',
    kVirtualMachine: 'enum.sourceType.kHyperV.kVirtualMachine',
    kNetwork: 'enum.sourceType.kHyperV.kNetwork',
  },

  kSQL: {
    kInstance: 'enum.sourceType.kSQL.kInstance',
    kDatabase: 'enum.sourceType.kSQL.kDatabase',

    // This kType is fabricated in the UI. It's created for the UI to
    // distinguish a SQL AAG database from a non-AAG SQL database.
    kDatabaseAAG: 'enum.sourceType.kSQL.kDatabaseAAG',
    kAAG: 'enum.sourceType.kSQL.kAAG',
    kAAGRootContainer: 'enum.sourceType.kSQL.kAAGRootContainer',
    kAAGDatabase: 'enum.sourceType.kSQL.kDatabaseAAG',
    kRootContainer: 'enum.sourceType.kSQL.kRootContainer',

    // These are not officially members of the SQL hierarchy, but because of the
    // hybrid state of the SQL tree, we have these here because they appear in
    // the EH.
    kHost: 'enum.sourceType.kPhysical.kHost',
    kVirtualMachine: 'enum.sourceType.kVMware.kVirtualMachine',
  },

  kOracle: {
    kDatabase: 'enum.sourceType.kOracle.kDatabase',
    kRootContainer: 'enum.sourceType.kOracle.kRootContainer',
    // Append other Oracle entities enum here.
  },

  kMongoDB: {
    kCluster: 'enum.sourceType.kMongoDB.kCluster',
    kCollection: 'enum.sourceType.kMongoDB.kCollection',
    kDatabase: 'enum.sourceType.kMongoDB.kDatabase',
  },

  kView: {
    kViewBox: 'enum.sourceType.kView.kViewBox',
    kView: 'enum.sourceType.kView.kView',
  },

  kPhysical: {
    kGroup: 'enum.sourceType.kPhysical.kGroup',
    kHostGroup: 'enum.sourceType.kPhysical.kHostGroup',
    kHost: 'enum.sourceType.kPhysical.kHost',
    kOracleAPCluster: 'enum.sourceType.kPhysical.kHost',
    kOracleRACCluster: 'enum.sourceType.kPhysical.kHost',
    kRole: 'enum.sourceType.kPhysical.kWindowsCluster.kRole',
    kWindowsCluster: 'enum.sourceType.kPhysical.kWindowsCluster',
    kUnixCluster: 'enum.sourceType.kPhysical.kUnixCluster',
  },

  kPhysicalFiles: {
    kGroup: 'enum.sourceType.kPhysical.kGroup',
    kHost: 'enum.sourceType.kPhysical.kHost',
    kWindowsCluster: 'enum.sourceType.kPhysical.kWindowsCluster',
    kUnixCluster: 'enum.sourceType.kPhysical.kUnixCluster',
  },

  kPure: {
    kStorageArray: 'enum.sourceType.kPure.kStorageArray',
    kVolume: 'enum.sourceType.kPure.kVolume',
    kPureProtectionGroup: 'enum.sourceType.kPure.kPureProtectionGroup',
  },

  kAzure: {
    kTenant: 'enum.sourceType.kAzure.kTenant',
    kRegion: 'enum.sourceType.kAzure.kRegion',
    kSubscription: 'enum.sourceType.kAzure.kSubscription',
    kResourceGroup: 'enum.sourceType.kAzure.kResourceGroup',
    kComputeOptions: 'enum.sourceType.kAzure.kComputeOptions',
    kVirtualMachine: 'enum.sourceType.kAzure.kVirtualMachine',
    kStorageAccount: 'enum.sourceType.kAzure.kStorageAccount',
    kStorageKey: 'enum.sourceType.kAzure.kStorageKey',
    kStorageContainer: 'enum.sourceType.kAzure.kStorageContainer',
    kStorageBlob: 'enum.sourceType.kAzure.kStorageBlob',
    kNetworkSecurityGroup: 'enum.sourceType.kAzure.kNetworkSecurityGroup',
    kVirtualNetwork: 'enum.sourceType.kAzure.kVirtualNetwork',
    kSubnet: 'enum.sourceType.kAzure.kSubnet',
    kSQLServer: 'enum.sourceType.kAzure.kSQLServer',
    kSQLDatabase: 'enum.sourceType.kAzure.kSQLDatabase',
    kSQLManagedInstance: 'enum.sourceType.kAzure.kSQLManagedInstance',
    kTag: 'enum.sourceType.kAzure.kTag',
  },

  kNetapp: {
    kCluster: 'enum.sourceType.kNetapp.kCluster',
    kVserver: 'enum.sourceType.kNetapp.kVserver',
    kVolume: 'enum.sourceType.kNetapp.kVolume',
  },

  // kAgent: {}

  kFlashBlade: {
    kStorageArray: 'enum.sourceType.kFlashBlade.kStorageArray',
    kFileSystem: 'enum.sourceType.kFlashBlade.kFileSystem',
  },


  kGenericNas: {
    kGroup: 'enum.sourceType.kGenericNas.kGroup',
    kHost: 'enum.sourceType.kGenericNas.kHost',
  },

  kAcropolis: {
    kPrismCentral: 'enum.sourceType.kAcropolis.kPrismCentral',
    kStandaloneCluster: 'enum.sourceType.kAcropolis.kStandaloneCluster',
    kCluster: 'enum.sourceType.kAcropolis.kCluster',
    kHost: 'enum.sourceType.kAcropolis.kHost',
    kVirtualMachine: 'enum.sourceType.kAcropolis.kVirtualMachine',
    kNetwork: 'enum.sourceType.kAcropolis.kNetwork',
    kStorageContainer: 'enum.sourceType.kAcropolis.kStorageContainer',
    kOtherHypervisorCluster: 'enum.sourceType.kAcropolis.kOtherHypervisorCluster',
  },

  // kPhysicalFiles envType still uses kPhysical
  // 13: {}

  kAWS: {
    kIAMUser: 'enum.sourceType.kAWS.kIAMUser',
    kRegion: 'enum.sourceType.kAWS.kRegion',
    kAvailabilityZone: 'enum.sourceType.kAWS.kAvailabilityZone',
    kEC2Instance: 'enum.sourceType.kAWS.kEC2Instance',
    kInstanceType: 'enum.sourceType.kAWS.kInstanceType',
    kKeyPair: 'enum.sourceType.kAWS.kKeyPair',
    kSubnet: 'enum.sourceType.kAWS.kSubnet',
    kNetworkSecurityGroup: 'enum.sourceType.kAWS.kNetworkSecurityGroup',
    kVPC: 'enum.sourceType.kAWS.kVPC',
    kRDSInstance: 'enum.sourceType.kAWS.kRDS',
    kAuroraCluster: 'enum.sourceType.kAWS.kAuroraCluster',
  },

  kElastifile: {
    kCluster: 'enum.sourceType.kElastifile.kCluster',
    kContainer: 'enum.sourceType.kElastifile.kContainer',
  },

  kIbmFlashSystem: {
    kStorageArray: 'enum.sourceType.kIbmFlashSystem.kStorageArray',
    kVolume: 'enum.sourceType.kIbmFlashSystem.kVolume',
    kVolumeGroup: 'enum.sourceType.kIbmFlashSystem.kIbmFlashSystemProtectionGroup'
  },

  kIsilon: {
    kCluster: 'enum.sourceType.kIsilon.kCluster',
    kZone: 'enum.sourceType.kIsilon.kZone',
    kMountPoint: 'enum.sourceType.kIsilon.kMountPoint',
  },

  kGCP: {
    kIAMUser: 'enum.sourceType.kGCP.kIAMUser',
    kProject: 'enum.sourceType.kGCP.kProject',
    kRegion: 'enum.sourceType.kGCP.kRegion',
    kAvailabilityZone: 'enum.sourceType.kGCP.kAvailabilityZone',
    kVirtualMachine: 'enum.sourceType.kGCP.kVirtualMachine',
  },

  kHyperVVSS: {
    kSCVMMServer: 'enum.sourceType.kHyperVVSS.kSCVMMServer',
    kStandaloneHost: 'enum.sourceType.kHyperVVSS.kStandaloneHost',
    kStandaloneCluster: 'enum.sourceType.kHyperVVSS.kStandaloneCluster',
    kHostGroup: 'enum.sourceType.kHyperVVSS.kHostGroup',
    kHypervHost: 'enum.sourceType.kHyperVVSS.kHypervHost',
    kHostCluster: 'enum.sourceType.kHyperVVSS.kHostCluster',
    kVirtualMachine: 'enum.sourceType.kHyperVVSS.kVirtualMachine',
    kNetwork: 'enum.sourceType.kHyperVVSS.kNetwork',
  },

  kKVM: {
    kOVirtManager: 'enum.sourceType.kKVM.kOVirtManager',
    kStandaloneHost: 'enum.sourceType.kKVM.kStandaloneHost',
    kDatacenter: 'enum.sourceType.kKVM.kDatacenter',
    kCluster: 'enum.sourceType.kKVM.kCluster',
    kHost: 'enum.sourceType.kKVM.kHost',
    kVirtualMachine: 'enum.sourceType.kKVM.kVirtualMachine',
    kNetwork: 'enum.sourceType.kKVM.kNetwork',
    kStorageDomain: 'enum.sourceType.kKVM.kStorageDomain',
    kVNicProfile: 'enum.sourceType.kKVM.kVNicProfile',
  },

  kO365: {
    kApplication: 'enum.sourceType.kO365.kApplication',
    kDomain: 'enum.sourceType.kO365.kDomain',
    kGraphUser: 'enum.sourceType.kO365.kGraphUser',
    kGroup: 'enum.sourceType.kO365.kGroup',
    kGroups: 'enum.sourceType.kO365.kGroups',
    kMailbox: 'enum.sourceType.kO365.kMailbox',
    kO365Exchange: 'enum.sourceType.kO365.kO365Exchange',
    kO365OneDrive: 'enum.sourceType.kO365.kO365OneDrive',
    kO365Sharepoint: 'enum.sourceType.kO365.kO365Sharepoint',
    kOutlook: 'enum.sourceType.kO365.kOutlook',
    kPublicFolder: 'enum.sourceType.kO365.kPublicFolder',
    kPublicFolders: 'enum.sourceType.kO365.kPublicFolders',
    kRootPublicFolder: 'enum.sourceType.kO365.kRootPublicFolder',
    kSite: 'enum.sourceType.kO365.kSite',
    kSites: 'enum.sourceType.kO365.kSites',
    kTeam: 'enum.sourceType.kO365.kTeam',
    kTeams: 'enum.sourceType.kO365.kTeams',
    kUser: 'enum.sourceType.kO365.kUser',
    kUsers: 'enum.sourceType.kO365.kUsers',
  },

  kHyperFlex: {
    kHyperFlex: 'enum.sourceType.kHyperFlex.kHyperFlex',
  },

  kAD: {
    kDomainController: 'enum.sourceType.kAD.kDomainController',
  },

  kExchange: {
    kRootContainer: 'enum.sourceType.kExchange.kExchange',
    kExchangeDAG: 'enum.sourceType.kExchange.kExchange',
    kExchangeDAGDatabase: 'enum.sourceType.kExchange.kExchangeDAGDatabase',
    kExchangeDAGDatabaseCopy: 'enum.sourceType.kExchange.kExchangeDAGDatabase',
    kExchangeStandaloneDatabase: 'enum.sourceType.kExchange.kExchangeStandaloneDatabase',
  },

  kGPFS: {
    kCluster: 'enum.sourceType.kGPFS.kCluster',
    kFilesystem: 'enum.sourceType.kGPFS.kFilesystem',
    kFileset: 'enum.sourceType.kGPFS.kFileset',
  },

  kKubernetes: {
    kCluster: 'enum.sourceType.kKubernetes.kCluster',
    kNamespace: 'enum.sourceType.kKubernetes.kNamespace',
    kPersistentVolumeClaim: 'enum.sourceType.kKubernetes.kPersistentVolumeClaim',
    kService: 'enum.sourceType.kKubernetes.kService',
    kLabel: 'enum.sourceType.kKubernetes.kLabel'
  },

  kNimble: {
    kStorageArray: 'enum.sourceType.kNimble.kStorageArray',
    kVolume: 'enum.sourceType.kNimble.kVolume',
  },

  kCassandra: {
    kCluster: 'enum.sourceType.kCassandra.kCluster',
    kKeyspace: 'enum.sourceType.kCassandra.kKeyspace',
    kTable: 'enum.sourceType.kCassandra.kTable',
  },

  kHBase: {
    kCluster: 'enum.sourceType.kHBase.kCluster',
    kNamespace: 'enum.sourceType.kHBase.kNamespace',
    kTable: 'enum.sourceType.kHBase.kTable',
  },

  kHdfs: {
    kCluster: 'enum.sourceType.kHdfs.kCluster',
  },

  kHive: {
    kCluster: 'enum.sourceType.kHive.kCluster',
    kDatabase: 'enum.sourceType.kHive.kDatabase',
    kTable: 'enum.sourceType.kHive.kTable',
  },

  kCouchbase: {
    kCluster: 'enum.sourceType.kCouchbase.kCluster',
    kBucket: 'enum.sourceType.kCouchbase.kBucket'
  },

  kUDA: {
    kCluster: 'enum.sourceType.kUDA.kCluster',
  },

  kSfdc: {
    kOrg: 'enum.sourceType.kSfdc.kOrg',
    kObject: 'enum.sourceType.kSfdc.kObject',
  },

  kSAPHANA: {
    kCluster: 'enum.sourceType.kUDA.kCluster',
  },
};
