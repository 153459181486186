<coh-settings-list-toggle
  [control]="formControl"
  [label]="'cacheOptimization' | translate"
  [toggleLabel]="'cacheOptimization' | translate"
  [trueLabel]="'enabled' | translate"
  [falseLabel]="'disabled' | translate">
  <mat-hint>
    {{'protectionGroups.cacheOptimizationHint' | translate}}
  </mat-hint>
</coh-settings-list-toggle>
