import { Component, Input } from '@angular/core';
import { StorageDomain } from '@cohesity/api/v2';

@Component({
  selector: 'coh-storage-domain-synopsis',
  templateUrl: './storage-domain-synopsis.component.html',
})
export class StorageDomainSynopsisComponent {
  /**
   * Storage Domain details.
   */
  @Input() storageDomain: StorageDomain;

  /**
   * Whether to show storage domain name.
   */
  @Input() showName = false;
}
