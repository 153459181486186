import { ProtectionSourceNode } from '@cohesity/api/v1';
import { Environment } from 'src/app/shared/constants';
import { ProtectionSourceDataNode } from '../shared/protection-source-data-node';
import { HasMetadata, SourceNodeMetadata } from '../shared/protection-source-metadata/source-node-metadata';

/**
 * Represents an active HBase source node and job tree selection behavior.
 */
export class HBaseSourceDataNode extends ProtectionSourceDataNode implements HasMetadata {

  /**
   * Stores the metadata for the source node.
   */
  readonly metadata: SourceNodeMetadata;

  constructor(data: ProtectionSourceNode, readonly level: number) {
    super(Environment.kHBase, data, level, []);
    this.metadata = {
      logicalSize: this.logicalSize,
      leafCount: this.expandable ? this.children && this.children.length || 0 : undefined,
      nodeIdentifierKey: Environment.kHBase + '.' + this.envSource.type
    };
  }

  /**
   * Whether the node is a leaf which can be directly selected or not.
   */
  get isLeaf() {
    return this.type === 'kTable';
  }

}
