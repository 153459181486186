<button
  [attr.aria-label]="'notifications' | translate"
  id="notifications-indicator-button"
  mat-icon-button
  [matTooltip]="'notifications' | translate"
  [matBadge]="notificationsInfo?.unreadCount"
  matBadgeSize="medium"
  matBadgeColor="warn"
  matBadgePosition="right"
  [matBadgeHidden]="notificationsInfo?.unreadCount === 0">
  <cog-icon shape="notifications_none"></cog-icon>
</button>
