import { AppModuleConfig, CanAccess, StateAccessMap } from 'src/app/app-module.config';

const heliosSecurityAdvisorAccess: CanAccess = ctx =>
  ctx.FEATURE_FLAGS.heliosSecurityAdvisorEnabled && !ctx.isHeliosTenantUser;

// Todo (Sudeep) Add privs needed to access quorum page.
const heliosQuorumAccess: CanAccess = ctx =>
  // Allow helios quorum in rpaas always
   (
    (ctx.clusterInfo?._serviceType === 'rpaas' && ctx.FEATURE_FLAGS.rpaasEnabled) ||
    (ctx.FEATURE_FLAGS.heliosQuorumEnabled && !ctx.isHeliosTenantUser)
  );

const heliosQuorumCreateGroupAccess: CanAccess = ctx =>
  heliosQuorumAccess(ctx) && ctx.irisContext.user.privilegeIds.includes('kQuorumGroupCreate');

const heliosOnlySingleClusterMap = {
  heliosOnly: true,
  singleClusterState: 'dashboards',
};

export const HeliosSecurityConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'helios-security-quorum.**',
      url: '^/security/quorum',
      loadChildren: () => import('./quorum/quorum.module').then(m => m.QuorumModule),
    },
    {
      name: 'helios-security-advisor.**',
      url: '/security/security-advisor',
      loadChildren: () => import('./security-advisor/security-advisor.module').then(m => m.HeliosSecurityAdvisorModule),
    },

    // TODO: The below states names should be refactored so that they have proper parent states.
    // With a parent state there can be one future state entry which will load aall of the child states.
    ...[
      ['helios-security-quorum-to-dos-details.**', '/security/quorum/to-dos/{id}'],
      ['helios-security-quorum-all-requests-details.**', '/security/quorum/all-requests/{id}'],
      ['helios-security-quorum-my-requests-details.**', '/security/quorum/my-requests/{id}'],
      ['helios-security-quorum-groups-add.**', '/security/quorum/groups/add'],
      ['helios-security-quorum-groups-edit.**', '/security/quorum/groups/edit/{id}'],
      ['helios-security-quorum-groups-details.**', '/security/quorum/groups/{id}'],
    ].map(([name, url]) => ({
      name,
      url,
      loadChildren: () => import('./quorum/quorum.module').then(m => m.QuorumModule),
    })),
    ...[
      ['security-advisor-scores-drilldown.**', '/security/security-advisor/scores-drilldown'],
      ['helios-security-advisor-rules-modify.**', '/security/security-advisor/security-rules-modify'],
      ['helios-security-advisor-rule-details.**', '/security/security-advisor/rule-details'],
      ['security-advisor-scan-results-detail.**', '/security/security-advisor/scan-results-detail'],
    ].map(([name, url]) => ({
      name,
      url,
      loadChildren: () => import('./security-advisor/security-advisor.module').then(m => m.HeliosSecurityAdvisorModule),
    })),
  ],
  allClusterMap: {
    'helios-security-quorum': {
      ...heliosOnlySingleClusterMap,
      allClustersState: 'helios-security-quorum',
    },
    'helios-security-quorum.to-dos': {
      ...heliosOnlySingleClusterMap,
      allClustersState: 'helios-security-quorum.to-dos',
    },
    'helios-security-quorum-to-dos-details': {
      ...heliosOnlySingleClusterMap,
      allClustersState: 'helios-security-quorum-to-dos-details',
    },
    'helios-security-quorum.all-requests': {
      ...heliosOnlySingleClusterMap,
      allClustersState: 'helios-security-quorum.all-requests',
    },
    'helios-security-quorum-all-requests-details': {
      ...heliosOnlySingleClusterMap,
      allClustersState: 'helios-security-quorum-all-requests-details',
    },
    'helios-security-quorum.my-requests': {
      ...heliosOnlySingleClusterMap,
      allClustersState: 'helios-security-quorum.my-requests',
    },
    'helios-security-quorum-my-requests-details': {
      ...heliosOnlySingleClusterMap,
      allClustersState: 'helios-security-quorum-my-requests-details',
    },
    'helios-security-quorum.groups': {
      ...heliosOnlySingleClusterMap,
      allClustersState: 'helios-security-quorum.groups',
    },
    'helios-security-quorum-groups-add': {
      ...heliosOnlySingleClusterMap,
      allClustersState: 'helios-security-quorum-groups-add',
    },
    'helios-security-quorum-groups-edit': {
      ...heliosOnlySingleClusterMap,
      allClustersState: 'helios-security-quorum-groups-edit',
    },
    'helios-security-quorum-groups-details': {
      ...heliosOnlySingleClusterMap,
      allClustersState: 'helios-security-quorum-groups-details',
    },
    'helios-security-advisor': {
      ...heliosOnlySingleClusterMap,
      allClustersState: 'helios-security-advisor',
    },
    'helios-security-advisor.scores': {
      ...heliosOnlySingleClusterMap,
      globalContext: true,
      allClustersState: 'helios-security-advisor.scores',
    },
    'security-advisor-scores-drilldown': {
      ...heliosOnlySingleClusterMap,
      allClustersState: 'security-advisor-scores-drilldown',
    },
    'helios-security-advisor.allIssues': {
      ...heliosOnlySingleClusterMap,
      allClustersState: 'helios-security-advisor.allIssues',
    },
    'helios-security-advisor.rules': {
      ...heliosOnlySingleClusterMap,
      allClustersState: 'helios-security-advisor.rules',
    },
    'helios-security-advisor-rules-modify': {
      ...heliosOnlySingleClusterMap,
      allClustersState: 'helios-security-advisor-rules-modify',
    },
    'helios-security-advisor-rule-details': {
      ...heliosOnlySingleClusterMap,
      allClustersState: 'helios-security-advisor-rule-details',
    },
    'helios-security-advisor-rule-details.definitions': {
      ...heliosOnlySingleClusterMap,
      allClustersState: 'helios-security-advisor-rule-details.definitions',
    },
    'helios-security-advisor-rule-details.clusters': {
      ...heliosOnlySingleClusterMap,
      allClustersState: 'helios-security-advisor-rule-details.clusters',
    },
    'helios-security-advisor.results': {
      ...heliosOnlySingleClusterMap,
      allClustersState: 'helios-security-advisor.results',
    },
    'security-advisor-scan-results-detail': {
      ...heliosOnlySingleClusterMap,
      allClustersState: 'security-advisor-scan-results-detail',
    },
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'helios-security-advisor': heliosSecurityAdvisorAccess,
      'helios-security-advisor.scores': heliosSecurityAdvisorAccess,
      'security-advisor-scores-drilldown': heliosSecurityAdvisorAccess,
      'helios-security-advisor.allIssues': heliosSecurityAdvisorAccess,
      'helios-security-advisor.rules': heliosSecurityAdvisorAccess,
      'helios-security-advisor-rules-modify': heliosSecurityAdvisorAccess,
      'helios-security-advisor-rule-details': heliosSecurityAdvisorAccess,
      'helios-security-advisor-rule-details.definitions': heliosSecurityAdvisorAccess,
      'helios-security-advisor-rule-details.clusters': heliosSecurityAdvisorAccess,
      'helios-security-advisor.results': heliosSecurityAdvisorAccess,
      'security-advisor-scan-results-detail': heliosSecurityAdvisorAccess,
      'helios-security-quorum': heliosQuorumAccess,
      'helios-security-quorum.to-dos': heliosQuorumAccess,
      'helios-security-quorum-to-dos-details': heliosQuorumAccess,
      'helios-security-quorum.all-requests': heliosQuorumAccess,
      'helios-security-quorum-all-requests-details': heliosQuorumAccess,
      'helios-security-quorum.my-requests': heliosQuorumAccess,
      'helios-security-quorum-my-requests-details': heliosQuorumAccess,
      'helios-security-quorum.groups': heliosQuorumAccess,
      'helios-security-quorum-groups-add': heliosQuorumCreateGroupAccess,
      'helios-security-quorum-groups-edit': heliosQuorumAccess,
      'helios-security-quorum-groups-details': heliosQuorumAccess,
    };
  },
};
