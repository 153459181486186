// Module: Tenant management

;(function(angular) {
  'use strict';

  angular
    .module('C.tenants', ['C.tenantService', 'C.policies'])
    .config(tenantsConfigFn);

  function tenantsConfigFn($stateProvider) {
    $stateProvider
      .state('access-management.tenants', {
        title: 'Organizations',
        url: '^/admin/organizations',
        help: 'platform_organizations',
        component: 'listTenants',
        canAccess: 'irisContext.clusterInfo.multiTenancyEnabled && ORGANIZATION_VIEW',
      })
      .state('view-tenant', {
        title: 'View Organization',
        url: '^/admin/organizations/view/{id}',
        help: 'platform_organizations',
        params: {
          // raw true will disables url-encoding of parameter values so that
          // url like "/organization/view/cohesity/india/finance" will be parsed
          // with id: "/cohesity/india/finance"
          id: { type: 'string', raw: true },
        },
        parentState: 'access-management.tenants',
        canAccess: 'irisContext.clusterInfo.multiTenancyEnabled && ORGANIZATION_VIEW',
        component: 'modifyTenant',
        resolve: {
          tenantId: function getID($transition$) {
            return $transition$.params().id;
          },
          inViewMode: function isViewMode() {return true;}
        },
      })
      .state('add-tenant', {
        title: 'Create Organization',
        url: '^/admin/organizations/add',
        help: 'add_organization',
        parentState: 'access-management.tenants',
        canAccess: 'irisContext.clusterInfo.multiTenancyEnabled && ORGANIZATION_MODIFY',
        component: 'modifyTenant',
      })
      .state('edit-tenant', {
        title: 'Edit Organization',
        url: '^/admin/organizations/edit/{id}',
        params: {
          id: { type: 'string', raw: true },
        },
        help: 'platform_organizations',
        parentState: 'access-management.tenants',
        canAccess: 'irisContext.clusterInfo.multiTenancyEnabled && ORGANIZATION_MODIFY',
        component: 'modifyTenant',
        resolve: {
          tenantId: function getID($transition$) {
            return $transition$.params().id;
          },
          inEditMode: function isEditMode() {return true;},
        },
      })
      .state('access-management.bifrost-settings', {
        title: 'Hybrid Extender Settings',
        url: '^/admin/hybrid-extender/settings',
        help: 'admin_organizations_bifrost',

        // bifrost setting page is having its own tab under access-management for tenant users
        // that why having `access-management` as a parent state.
        parentState: 'access-management',
        canAccess: '$root.isTenantUser() && HYBRID_EXTENDER_VIEW && FEATURE_FLAGS.bifrostEnabled',
        component: 'bifrostSettings',
      });
  }

})(angular);
