import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

import { SpinnerComponent } from './spinner.component';

const spinnerComponents = [
  SpinnerComponent,
];

@NgModule({
  imports: [
    MatProgressSpinnerModule,
    CommonModule,
    ...spinnerComponents
  ],
  exports: spinnerComponents
})
export class SpinnerModule { }
