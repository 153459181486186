<!-- Push notification configuration dialog template -->
<div class="flex-row">
  <cog-icon class="margin-right margin-top-xs" shape="add_alert!outline" size="md"></cog-icon>
  <h2 mat-dialog-title>{{ 'configurePushNotifications' | translate }}</h2>
</div>
<form
  id="configure-push-notification-form"
  [formGroup]="form"
  *ngIf="!loading"
  cohValidateOnSubmit
  (validatedSubmit)="onSubmit()">
  <mat-dialog-content>
    <span class="margin-top">{{ 'anomalyNotificationSettings.title' | translate }}</span>
    <div class="margin-top-lg">
      <!-- Threshold slider -->
      <mat-label>{{ 'anomalyNotificationSettings.sliderInfo' | translate }}</mat-label>
      <div class="flex-row-xs vertical-align">
        <mat-slider
          cogDataId="anomaly-strength-slider"
          color="primary"
          class="flex-grow"
          [max]="100"
          [min]="1"
          formControlName="threshold">
        </mat-slider>
        <mat-form-field class="threshold-value">
          <input
            matInput
            cogDataId="threshold-slider-input"
            [inheritDataId]="true"
            [value]="form.get('threshold').value" />
          <span matSuffix>%</span>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cogCancelButton cogDataId="anomaly-strength-configuration-cancel">
      {{ 'cancel' | translate }}
    </button>
    <button mat-button cogSubmitButton cdkFocusInitial cogDataId="anomaly-strength-configuration-submit">
      {{ 'configure' | translate }}
    </button>
  </mat-dialog-actions>
</form>
<cog-spinner *ngIf="loading"></cog-spinner>
