<coh-source-special-parameters [form]="form" [nodeContext]="nodeContext"
  class="flex-row vertical-align"
  [label]="buttonLabel"
  (dialogOpened)="initializeSelection()"
  (saved)="onSave()"
  [dialogHeaderTemplate]="dialogHeaderTemplate">
  <div class="gcp-options-wrapper">
    <div class="c-tile">
      <div class="info margin-top">
        {{'job.settings.excludeEbsVolumes' | translate}}
      </div>
    </div>

    <cog-table [source]="gcpDisks"
      [canSelectRowFn]="canSelectRowFn"
      [canSelectAnyRowsFn]="canSelectAnyRows"
      [isAllSelectedFn]="isAllSelected"
      [toggleSelectAllFn]="toggleSelectAllFn"
      [selection]="selection"
      name="gcp-disk-exclusion-table">
      <table mat-table>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>{{ 'name' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            <div class="cog-table-object">
              <cog-icon shape="helix:volume"></cog-icon>
              <div class="cog-table-object-body">
                <div class="cog-table-object-name">
                  {{ row.name }}
                  <span class="cog-gb-unit-sm gcp-volume-size">{{ row.byteSize | byteSize }}</span>
                </div>
                <div class="cog-table-object-meta">
                  {{ row.deviceName | naLabel }}
                </div>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>{{ 'id' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.id | naLabel }}</td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>{{ 'type' | translate }}</th>
          <!-- TODO: Show the texts corresponding to the different types -->
          <td mat-cell *matCellDef="let row">{{ row.type }}</td>
        </ng-container>

        <!-- NoData Footer -->
        <ng-container matColumnDef="noData">
          <td [attr.colspan]="colDefinition.length" class="text-center" mat-footer-cell *matFooterCellDef>
            {{ 'noDataFound' | translate }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="colDefinition"></tr>
        <tr mat-row *matRowDef="let row; columns: colDefinition" (click)="toggleObjectSelection(row)"></tr>

        <tr class="nodata-row" mat-footer-row [class.hidden]="gcpDisks?.length" *matFooterRowDef="['noData']"></tr>
      </table>
    </cog-table>
  </div>
</coh-source-special-parameters>

<ng-template #dialogHeaderTemplate let-dialogRef="dialogRef">
  <div class="flex-row-sm vertical-align">
    <button class="gcp-options-back-button"
      (click)="dialogRef.close(false)"
      cogDataId="gcp-options-back-button"
      mat-icon-button
      type="button">
      <cog-icon shape="arrow_back"></cog-icon>
    </button>

    <h1 mat-dialog-title
      class="no-margin-bottom"
      matTooltip="{{'serverOptions.volumesFor' | translate: {name: nodeContext.node.name} }}"
      matTooltipPosition="below">
      {{'serverOptions.volumesFor' | translate: {name: nodeContext.node.name} }}
    </h1>
  </div>
</ng-template>