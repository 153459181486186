// Controller: review-policy-jobs-modal

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.policies')
    .controller('reviewPolicyJobsController', reviewPolicyJobsControllerFn);

  /**
   * reviewPolicyJobs Modal controller
   *
   * @method   reviewPolicyJobsControllerFn
   * @param    {object}   $q                  promises provider factory.
   * @param    {object}   evalAJAX            generic api error handler service.
   * @param    {object}   JobService          job's related action service.
   * @param    {object}   params              modal params
   * @param    {object}   $uibModalInstance   uib-modal Instance service.
   */
  function reviewPolicyJobsControllerFn($q, evalAJAX, JobService, params,
    $uibModalInstance) {

    var $ctrl = this;

    angular.extend($ctrl, {
      policyJobs: params.policyJobs || [],

      // UI state
      state: {
        requireStartTimes: params.requireStartTimes,
        jobsUpdateError: [],
        errorUpdatingJobs: false,
        updatedJobsMap: {},
      },

      // modal actions & state
      ok: updateJobs,
      submitting: false,
      okDisabled: false,
    });

    /**
     * updates jobs start times.
     *
     * @method   updateJobs
     * @param    {array}    policyJobs   The policy jobs list
     * @return   {object}   promise resolved to updatedJobsMap on success
     */
    function updateJobs() {
      var promisesList;

      $ctrl.submitting = true;

      // when policy changed from continous to non-continous then capture job
      // start times & update the job.
      if (!$ctrl.state.requireStartTimes) {
        $uibModalInstance.close($ctrl.state.updatedJobsMap);
        return;
      }

      promisesList = $ctrl.policyJobs.map(function eachJob(job) {
        return JobService.updateJob({
          backupJob: job.jobDescription
        }).then(
          function onSuccess(updatedJob) {
            $ctrl.state.updatedJobsMap[updatedJob.jobId] = updatedJob;
            return $q.resolve({ status: 'success', job: updatedJob });
          },
          function onFailure(err) {
            job._updateError = evalAJAX.errorMessage(err, {
              showMessage: false,
            });
            $ctrl.state.errorUpdatingJobs = true;
            $ctrl.okDisabled = true;
            $ctrl.state.jobsUpdateError.push(job);
            return $q.resolve({ status: 'failed', err: err });
          }
        );
      });

      $q.all(promisesList).then(
        function onUpdateSuccess() {
          $ctrl.submitting = false;

          // if no jobs update failed then only allow policy update.
          if (!$ctrl.state.errorUpdatingJobs) {
            $uibModalInstance.close($ctrl.state.updatedJobsMap);
          }
        }
      );
    }
  }

})(angular);
