import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { FieldType, TypeConfig } from '@cohesity/helix';
import { TypedFormGroup } from 'ngx-sub-form';
import { Observable } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';

/**
 * This is a map of an object's properties keys to boolean values for whether the object's
 * form control should be enabled or not.
 */
export type FormControlEnabledMap<D> = {
  [K in keyof D]?: boolean;
};

/**
 * This creates an obervable from a form control value change that starts with the control's
 * current value.
 *
 *
 * @param control     The form control
 * @param emitOnNull  Whether to emit an value when a form's control is null.
 * @returns An observable of the control's value.
 */
export function fromControlValueChange<D = any>(
  control: AbstractControl | TypedFormGroup<D>,
  emitOnNull: boolean = false
): Observable<D> {
  return (control.valueChanges as Observable<D>).pipe(
    startWith(control.value),

    // This should still emit if the value is false or 0
    filter(value => (value !== null && value !== undefined) || emitOnNull)
  );
}

/**
 * Given a formGroup and enabledMap, this enabled or disabled each form control based on it's
 * value in the map.
 *
 * @param   formGroup   The form group
 * @param   enabledMap  A map of form control names to their enabled status.
 */
export function updateFormControlStatus<D = any>(
  formGroup: UntypedFormGroup | TypedFormGroup<D>,
  enabledMap: FormControlEnabledMap<D>
) {
  Object.keys(enabledMap).forEach(controlName => {
    if (enabledMap[controlName] && formGroup.controls[controlName].disabled) {
      formGroup.controls[controlName].enable();
    } else if (!enabledMap[controlName] && formGroup.controls[controlName].enabled) {
      formGroup.controls[controlName].disable();
    }
  });
}

/**
 * Returns corresponding error message based on the formControl errors object.
 *
 * @param formControl The formControl to be checked for error.
 * @returns The error message.
 */
export function mapErrorMessage(formControl: AbstractControl): string {
  let errorMsg = '';
  const { errors } = formControl;

  if (!errors) {
    return;
  }

  /**
   * Get error code from  the formControl errors object.
   * There should be only one error code at a time.
   */
  const error = Object.keys(errors)[0] || null;

  switch (error) {
    case 'required':
      errorMsg = 'errors.required';
      break;
    case 'min':
      errorMsg = 'errors.minValue';
      break;
    case 'max':
      errorMsg = 'errors.maxValue';
      break;
    case 'pattern':
      errorMsg = 'errors.invalid';
  }

  return errorMsg;
}

/**
 * This function returns a callback function to show validation errors
 * for generic form field
 *
 * @param   translate   Translate function to convert the error messages to UI
 * specific string
 * @return callback function to show validation errors for generic form field
 */
export function genericFormFieldValidationErrorFn(
  translate: (key: string , param?: any) => string ,
): ((
  validationError: string,
  fieldType?: FieldType,
  fieldLabel?: string,
  fieldConfig?: TypeConfig) => string) {

  const validationErrFn = (
    validationError: string,
    fieldType?: FieldType,
    fieldLabel?: string,
    fieldConfig?: TypeConfig): string => {
    switch (validationError) {
      case 'required':
        return translate('errors.required');
      case 'min':
        return translate('errors.minValue',
          { minValue: (fieldConfig?.numberConfig?.minimumValue ?? null) });
      case 'max':
        return translate('errors.maxValue',
          { maxValue: (fieldConfig?.numberConfig?.maximumValue ?? null) });
      default:
        break;
    }
    return '';
  };
  return validationErrFn;
}
