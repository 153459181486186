import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const protectModify = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngProtectionGroupGlobalModify &&
  ctx.FEATURE_FLAGS.ngProtectionGroupNasModify &&
  ctx.PROTECTION_MODIFY;

const sourceModify = (ctx: StateAccessContext) =>
  ctx.PROTECTION_SOURCE_MODIFY;

export const NasConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'protection-builder-nas.**',
      url: '/protection/protect/nas',
      loadChildren: () => import('./nas.module').then(m => m.NasModule),
    },
    {
      name: 'isilon-configure.**',
      url: '/protection/sources/isilon/configure',
      loadChildren: () => import('./nas.module').then(m => m.NasModule)
    },
  ],
  allClusterMap: {
    'protection-builder-nas.create': true,
    'protection-builder-nas.edit': true,
    'protection-builder-nas.edit-object': true,
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'protection-builder-nas.create': protectModify,
      'protection-builder-nas.edit': protectModify,
      'protection-builder-nas.edit-object': protectModify,
      'isilon-configure': sourceModify,
    };
  },
};
