import { Component, Input } from '@angular/core';
import { FiltersComponent, ValueFilterSelection } from '@cohesity/helix';
import { Observable, of } from 'rxjs';

import { BaseObjectFilterComponent } from 'src/app/shared/common-filters/base-object-filter';

@Component({
  selector: 'coh-object-type-search-filter',
  templateUrl: './object-type-search-filter.component.html',
})
export class ObjectTypeSearchFilterComponent extends BaseObjectFilterComponent<ValueFilterSelection[]> {

  /**
   * The name of the filter property.
   */
  @Input() property = 'objectType';

  constructor(filters: FiltersComponent) {
    super(filters);
  }

  /**
   * Fetch filter values from the supplied params.
   */
  getFilterValues(): Observable<ValueFilterSelection[]> {
    return of(this.requestParams || []);
  }
}
