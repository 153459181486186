import { Injectable } from '@angular/core';
import {
  GetProtectionRunProgressBody,
  GetProtectionRunStatsBody,
  ObjectProgressInfo,
  ProtectionGroupServiceApi,
} from '@cohesity/api/v2';
import { Observable, of, timer } from 'rxjs';
import { catchError, filter, switchMap, takeWhile } from 'rxjs/operators';
import { PassthroughOptionsService } from './passthrough-options.service';

/**
 * Update interval for active run progress in milliseconds.
 */
export const RUN_PROGRESS_THROTTLE = 30_000;

/**
 * Increase poll interval after 10 * RUN_PROGRESS_THROTTLE (5 minutes)
 */
export const MAX_COUNT_DEFAULT_TIMEOUT = 10;

/**
 * Poll interval change to 4 * RUN_PROGRESS_THROTTLE when error or after 5 minutes.
 */
export const DELAY_MULTIPLIER = 4;


/**
 * Protection Run service for getting Protection Run Info and delete snapshots.
 */
@Injectable({
  providedIn: 'root',
})
export class RunProgressStatsPollerService {

  constructor(
    private passthroughOptionsService: PassthroughOptionsService,
    private groupsServiceV2: ProtectionGroupServiceApi,
  ) {
  }

  /**
   * Returns run progress data.
   *
   * @param  runId  Protection Run ID.
   * @param  params  Optional params to pass to run progress API.
   * @returns  Run progress data.
   */
  getRunProgress(
    runId: string,
    params: Partial<ProtectionGroupServiceApi.GetProtectionRunProgressParams> = {}
  ): Observable<GetProtectionRunProgressBody> {
    return this.groupsServiceV2.GetProtectionRunProgress({
      ...params,
      runId,
      ...this.passthroughOptionsService.requestParams,
      regionId: params.regionId || this.passthroughOptionsService.regionId,
    });
  }

  /**
   * Returns run stats data.
   *
   * @param  runId  Protection Run ID.
   * @param  params  Optional params to pass to run stats API.
   * @returns  Run stats data.
   */
  getRunStats(
    runId: string,
    params: Partial<ProtectionGroupServiceApi.GetProtectionRunProgressParams> = {}
  ): Observable<GetProtectionRunStatsBody> {
    return this.groupsServiceV2.GetProtectionRunStats({
      ...params,
      runId,
      ...this.passthroughOptionsService.requestParams,
      regionId: params.regionId || this.passthroughOptionsService.regionId,
    });
  }

  /**
   * Returns timer observable that will trigger at specified interval while run is in progress.
   *
   * @param  runId  Protection run ID.
   * @param  pollInterval  Optional rate in milliseconds timer will trigger.
   * @param  regionId  Optional region id to poll from.
   * @param  continueOnError Keep poll on error if True.
   * @returns  Observable that will keep emitting values while run is in progress.
   */
  pollRunProgress(
    runId: string,
    pollInterval = RUN_PROGRESS_THROTTLE,
    regionId?,
    continueOnError = false,
    runTaskPath?,
    objects?,
    objectTaskPaths?,
    accessClusterId?,
  ): Observable<GetProtectionRunProgressBody> {
    let pollError: boolean;

    return timer(0, pollInterval).pipe(
      // Increase poll interval by using filter to skip polls.
      // Don't skip if no error and initial 10 (MAX_COUNT_DEFAULT_TIMEOUT) polls
      // (5 minutes). After that, skip all except every 4th poll (same as poll
      // every 30s * 4 (DELAY_MULTIPLIER) = 2 minutes).
      filter(count =>
        (!pollError && count <= MAX_COUNT_DEFAULT_TIMEOUT) ||
        ((count - MAX_COUNT_DEFAULT_TIMEOUT) % DELAY_MULTIPLIER === 0)
      ),
      switchMap(() => {
        pollError = false;
        return this.getRunProgress(runId,
          { includeEventLogs: false, regionId, runTaskPath, objects, objectTaskPaths, accessClusterId }).pipe(
          catchError(() => {
            pollError = true;
            return of<GetProtectionRunProgressBody>({});
          }),
        );
      }),
      takeWhile((runProgress: GetProtectionRunProgressBody) => {
        switch (true) {
          case runProgress.localRun?.status === 'Active':
            return true;
          case !!runProgress.localRun?.objects?.find(({ status }) => status === 'Active'):
            return true;
          case !!runProgress.archivalRun?.find(run => run.status === 'Active'):
            return true;
          case !!runProgress.archivalRun?.find(run => !!run.objects?.find(({ status }) => status === 'Active')):
            return true;
          case !!runProgress.replicationRun?.find(run => run.status === 'Active'):
            return true;
          case !!runProgress.replicationRun?.find(run => !!run.objects?.find(({ status }) => status === 'Active')):
            return true;
          case runProgress.replicationRun === null && runProgress.archivalRun === null:
            return true;
          default:
            return pollError ? continueOnError : false;
        }
      }, true)
    );
  }


  /**
   * Returns timer observable that will trigger at specified interval while run is in progress.
   *
   * @param  runId  Protection run ID.
   * @param  pollInterval  Optional rate in milliseconds timer will trigger.
   * @param  regionId  Optional region id to poll from.
   * @param  continueOnError Keep poll on error if True.
   * @returns  Observable that will keep emitting stats while run is in progress.
   */
  pollRunStats(
    runId: string,
    pollInterval = RUN_PROGRESS_THROTTLE,
    regionId?,
    runTaskPath?,
    objects?,
    objectTaskPaths?,
    accessClusterId?,
  ): Observable<GetProtectionRunStatsBody> {
    let pollError: boolean;

    return timer(0, pollInterval).pipe(
      // Increase poll interval by using filter to skip polls.
      // Don't skip if no error and initial 10 (MAX_COUNT_DEFAULT_TIMEOUT) polls
      // (5 minutes). After that, skip all except every 4th poll (same as poll
      // every 30s * 4 (DELAY_MULTIPLIER) = 2 minutes).
      filter(count =>
        (!pollError && count <= MAX_COUNT_DEFAULT_TIMEOUT) ||
        ((count - MAX_COUNT_DEFAULT_TIMEOUT) % DELAY_MULTIPLIER === 0)
      ),
      switchMap(() => {
        pollError = false;
        return this.getRunStats(runId,
          { includeEventLogs: false, regionId, runTaskPath, objects, objectTaskPaths, accessClusterId }).pipe(
          catchError(() => {
            pollError = true;
            return of<GetProtectionRunStatsBody>({});
          }),
        );
      }),
    );
  }

  /**
   * Returns timer observable that will trigger at specified interval while object run is in progress.
   *
   * @param  runId  Protection run ID.
   * @param  objectId  Protected object ID.
   * @param  pollInterval  Optional rate in milliseconds timer will trigger.
   * @returns  Observable that will keep emitting values while object run is in progress.
   */
  pollObjectRunProgress(
    runId: string,
    objectId: number,
    pollInterval = RUN_PROGRESS_THROTTLE
  ): Observable<GetProtectionRunProgressBody> {
    return timer(0, pollInterval).pipe(
      switchMap(() => this.getRunProgress(runId, { includeEventLogs: false })),
      catchError(() => of<GetProtectionRunProgressBody>({})),
      takeWhile((runProgress: GetProtectionRunProgressBody) => {
        const localBackup = runProgress.localRun?.objects?.find(({ id }) => id === objectId);

        let objectArchival: ObjectProgressInfo;
        runProgress?.archivalRun?.find(
          archival => objectArchival = archival?.objects?.find(({ id }) => id === objectId)
        );

        let objectReplication: ObjectProgressInfo;
        runProgress?.replicationRun?.find(
          replication => objectReplication = replication?.objects?.find(({ id }) => id === objectId)
        );

        return !((!localBackup || localBackup?.status !== 'Active') &&
          (!objectArchival || objectArchival?.status !== 'Active') &&
          (!objectReplication || objectReplication?.status !== 'Active'));
      }, true)
    );
  }
}
