// Component: cPulse Event Logs listing

(function _iife(angular) {
  'use strict';

  var moduleName = 'C.PulseEventLogs';
  var componentName = 'cPulseEventsList';

  /**
   * @ngdoc component
   * @name C.PulseEventLogs.component:cPulseLogsList
   *
   * @param   {Object[]}   events              The list of events. This is
   *                                           taskVec.progress.eventVec.
   * @param   {string}     id                  Required if showButton is true.
   * @param   {Boolean}    [showButton=true]   Show the button or not. When
   *                                           false, the logs list is always
   *                                           visible.
   *
   * @description
   * This component simply displays the pulse event logs in a table. An optional
   * toggle button can be displayed (default) to expand/collapse the listing.
   *
   * @example
   *  <!-- Show the button -->
   *  <c-pulse-events-list
   *    id="recover-db-pulse"
   *    events="task.progress.eventVec">
   *  </c-pulse-events-list>
   *
   *  <!-- hide the button -->
   *  <c-pulse-events-list
   *    events="task.progress.eventVec"
   *    show-button="::false">
   *  </c-pulse-events-list>
   */
  angular.module(moduleName, ['smart-table'])
    .controller('cPulseEventLogsController', ComponentControllerFn)
    .component(componentName, {
      bindings: {
        events: '<',
        id: '@?',
        showButton: '<?',
        ignoreSort: '<?',
      },
      controller: 'cPulseEventLogsController',
      template: require('raw-loader!./c-pulse-logs-list.component.html').default,
    });

  function ComponentControllerFn(_, $log) {
    var $ctrl = this;

    _.assign($ctrl, {
      showButton: !_.isBoolean($ctrl.showButton) && $ctrl.showButton,
      $onInit: function $onInit() {
        $ctrl.showPulseLogs = !$ctrl.showButton;

        // Ignore sorting of events if specified.
        if (!$ctrl.ignoreSort) {
          $ctrl.events = _.orderBy($ctrl.events, ['timestampSecs', 'asc']);
        }

        if (!$ctrl.id && $ctrl.showButton) {
          $log.error(
            'The "'+componentName+'" component requires an id attribute when '+
            'showing the toggle button.'
          );
        }
      }
    });
  }

})(angular);
