<h3 *ngIf="showStats">{{ 'systemsWithAffectedObjects' | translate }}</h3>
<cog-spinner *ngIf="loading"></cog-spinner>
<div class="flex-row" *ngIf="!loading" cogDataId="anomaly-stats">
  <div class="bubble-map-container flex-grow" [inheritDataId]="true" cogDataId="map">
    <cog-bubble-map
      [seriesData]="anomaly.seriesData"
      [customChartOptions]="customMapOptions"
      [colorSetClass]="anomaly.colorSetClass"
      [useZoom]="true">
    </cog-bubble-map>
  </div>
  <div *ngIf="showStats" class="flex-row-lg">
    <cog-statlist [vertical]="true">
      <cog-statlist-item
        label="{{ 'affectedSources' | translate }}"
        [inheritDataId]="true"
        cogDataId="affected-sources">
        {{ statsData?.sources | number | naLabel }}
      </cog-statlist-item>
      <cog-statlist-item
        label="{{ 'affectedProtections' | translate }}"
        [inheritDataId]="true"
        cogDataId="affected-protections">
        {{ statsData?.jobs | number | naLabel }}
      </cog-statlist-item>
      <cog-statlist-item
        label="{{ 'affectedSystems' | translate }}"
        [inheritDataId]="true"
        cogDataId="affected-systems">
        {{ statsData?.clusters | number | naLabel }}
      </cog-statlist-item>
    </cog-statlist>
    <mat-divider class="divider-margin" [vertical]="true"></mat-divider>
    <cog-statlist [vertical]="true">
      <cog-statlist-item
        label="{{ 'anomalousSnapshots' | translate }}"
        [inheritDataId]="true"
        cogDataId="affected-snapshots">
        {{ statsData?.occurrences | number | naLabel }}
      </cog-statlist-item>
      <cog-statlist-item
        label="{{ 'firstAnomalousSnapshot' | translate }}"
        [inheritDataId]="true"
        cogDataId="first-detected-snapshot">
        {{ statsData?.firstOccurrenceTimeUsecs | cogDate | naLabel }}
      </cog-statlist-item>
      <cog-statlist-item
        label="{{ 'latestAnomalousSnapshot' | translate }}"
        [inheritDataId]="true"
        cogDataId="latest-detected-snapshot">
        {{ statsData?.lastOccurrenceTimeUsecs | cogDate | naLabel }}
      </cog-statlist-item>
    </cog-statlist>
  </div>
</div>
