/**
 * This file declares constants and enums related to cluster, hardware and networking.
 */
export enum IpAddressFamily {
  Ipv4,
  Ipv6,
}

/**
 * An enum of the property keys attached to clusterInfo for each failure domain.
 */
export enum FailureDomainPropKeys {
  kNode = 'nodeCount',
  kChassis = 'chassisCount',
  kRack = 'assignedRacksCount',
}

/**
 * Defines different cluster install types.
 */
export enum ClusterType {
  Physical = 0,
  VirtualEdition,
  VmRobo,
  PhysicalRobo,
  CloudEdition,
}
