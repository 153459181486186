import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { AnomalyObjectIconPipeModule } from '@cohesity/data-govern/shared';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { IrisRouterModule } from '@cohesity/iris-core';
import { UtilPipesModule } from '@cohesity/utils';
import { TranslateModule } from '@ngx-translate/core';

import { AnomalyChartModule } from '../anomaly-chart';
import { SnapshotInfoModule } from '../snapshot-info';
import { AnomalyRecoveryComponent } from './anomaly-recovery.component';

const COMPONENTS = [AnomalyRecoveryComponent];

/**
 * This module is shared between security and health module.
 * Health module needs some components to show DMaaS anomaly alert details information.
 */
@NgModule({
  imports: [
    AnomalyChartModule,
    AnomalyObjectIconPipeModule,
    CohesityHelixModule,
    CommonModule,
    IrisRouterModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSliderModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    SnapshotInfoModule,
    TranslateModule,
    UtilPipesModule,
  ],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
})
export class AnomalyRecoveryModule {}
