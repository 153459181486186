import { ActiveDirectory, DomainController } from '@cohesity/api/v2';

/**
 * Enum of Mapping Type names.
 */
export enum MappingTypesType {
  Centrify = 'Centrify',
  CustomAttributes = 'CustomAttributes',
  Fixed = 'Fixed',
  LdapProvider = 'LdapProvider',
  NisProvider = 'NisProvider',
  Rfc2307 = 'Rfc2307',
  Rid = 'Rid',
  Sfu30 = 'Sfu30'
}

/**
 * Hash of Mapping Type translation keys.
 */
export const MappingTypeStringKeys = {
  [MappingTypesType.Centrify]: 'ad.mappingType.centrify',
  [MappingTypesType.CustomAttributes]: 'ad.mappingType.customId',
  [MappingTypesType.Fixed]: 'ad.mappingType.fixed',
  [MappingTypesType.LdapProvider]: 'ad.mappingType.ldapProvider',
  [MappingTypesType.NisProvider]: 'ad.mappingType.nisProvider',
  [MappingTypesType.Rfc2307]: 'ad.mappingType.rfc2307',
  [MappingTypesType.Rid]: 'ad.mappingType.rid',
  [MappingTypesType.Sfu30]: 'ad.mappingType.sfu30',
};

/**
 * Hash of Mapping Type Param names.
 */
export const MappingTypeParamNames = {
  [MappingTypesType.Centrify]: 'centrifyTypeParams',
  [MappingTypesType.CustomAttributes]: 'customAttributesTypeParams',
  [MappingTypesType.Fixed]: 'fixedTypeParams',
  [MappingTypesType.LdapProvider]: 'ldapProviderTypeParams',
  [MappingTypesType.NisProvider]: 'nisProviderTypeParams',
  [MappingTypesType.Rfc2307]: 'rfc2307TypeParams',
  [MappingTypesType.Rid]: 'ridTypeParams',
  [MappingTypesType.Sfu30]: 'sfu30TypeParams',
};

/**
 * Enum for Primary Mapping types
 */
export const MappingTypes = {
  Rid: {
    type: MappingTypesType.Rid,
    nameKey: MappingTypeStringKeys.Rid,
    requireFallback: false,
  },
  Fixed: {
    type: MappingTypesType.Fixed,
    nameKey: MappingTypeStringKeys.Fixed,
    requireFallback: false,
  },
  Centrify: {
    type: MappingTypesType.Centrify,
    nameKey: MappingTypeStringKeys.Centrify,
    requireFallback: true,
  },
  LdapProvider: {
    type: MappingTypesType.LdapProvider,
    nameKey: MappingTypeStringKeys.LdapProvider,
    requireFallback: true,
  },
  Rfc2307: {
    type: MappingTypesType.Rfc2307,
    nameKey: MappingTypeStringKeys.Rfc2307,
    requireFallback: true,
  },
  Sfu30: {
    type: MappingTypesType.Sfu30,
    nameKey: MappingTypeStringKeys.Sfu30,
    requireFallback: true,
  },
  /* TODO: Enable ~6.5 when supported by backend.
  kCustomAttributes: {
    type: MappingTypesType.CustomAttributes,
    nameKey: MappingTypeStringKeys.CustomAttributes,
    requireFallback: true,
  },
  */
};

/**
 * Enum for Fallback Mapping types
 */
export const FallbackMappingTypes = {
  Rid: {
    type: MappingTypesType.Rid,
    nameKey: MappingTypeStringKeys.Rid,
  },
  Fixed: {
    type: MappingTypesType.Fixed,
    nameKey: MappingTypeStringKeys.Fixed,
  },
};

/**
 * Enum for Encryption Types.
 */
export type EncryptionType = null |
'DES-CBC-CRC' |
'DES-CBC-MD5' |
'RC4-HMAC' |
'AES128-CTS-HMAC-SHA1-96' |
'AES256-CTS-HMAC-SHA1-96';

/**
 * Master list of all encryption types.
 */
export const AllEncryptionTypes: EncryptionType[] = [
  'DES-CBC-CRC',
  'DES-CBC-MD5',
  'RC4-HMAC',
  'AES128-CTS-HMAC-SHA1-96',
  'AES256-CTS-HMAC-SHA1-96',
];

/**
 * Master list of all FIPS-Compliant encryption types.
 */
export const CompliantEncryptionTypes: EncryptionType[] = [
  'AES128-CTS-HMAC-SHA1-96',
  'AES256-CTS-HMAC-SHA1-96',
];

/**
 * The domain controller dialog params.
 */
export interface DomainControllersDialogParams {
  /**
   * Indicated whether make api call to update domain controller or not.
   * Deprecated by flag: trustedDomainsAllowlist ENG-172175
   */
  updateOnSave: boolean;

  /**
   * The current active directory
   */
  ad: ActiveDirectory;

  /**
   * Specific domain controller to update.
   */
  domainName?: string;

  /**
   * All available domain controllers.
   */
  domainControllers: DomainController[];

  /**
   * The selected controllers for specified domainName.
   */
  preferredDomainControllers: DomainController[];

  /**
   * The list of denied domain controllers.
   */
  deniedDomainControllers?: DomainController[];
}

/**
 * The dialog close response interface.
 */
export interface DomainControllersDialogResponse {
  allowedDomainControllers: DomainController[];
  deniedDomainControllers: DomainController[];
}
