import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FilterObjectsRequest, ObjectServiceApi } from '@cohesity/api/v2';
import { ExcludeOptions } from '@cohesity/iris-source-tree';
import { AutoDestroyable } from '@cohesity/utils';
import { UIRouterGlobals } from '@uirouter/core';
import { finalize } from 'rxjs/operators';

export interface DialogData {
  /**
   * Exclude options that are required to prefill the form
   */
  excludeOptions: ExcludeOptions;
}

// TODO: Make this a general exclude dialog and make the SQL specific use the
// general component.
/**
 * SQL Exclude Filters Dialog. When the filters are updated and applied, the
 * exclusions are applied to the SQL source tree.
 */
@Component({
  selector: 'coh-sql-exclude-dialog',
  templateUrl: './sql-exclude-dialog.component.html',
  styleUrls: ['./sql-exclude-dialog.component.scss']
})
export class SqlExcludeDialogComponent extends AutoDestroyable implements OnInit {

  /**
   * SQL Exclude Filters form.
   */
  sqlExcludeFiltersForm: UntypedFormGroup = this.fb.group({
    excludeFilters: this.fb.array([this.createFilter()])
  });

  /**
   * Indicates whether form is being saved.
   */
  saving = false;

  /**
   * Error message to be displayed if the filters are incorrect or incompatible
   */
  errorMessage: string;

  constructor(
    private dialogRef: MatDialogRef<SqlExcludeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: UntypedFormBuilder,
    private miscService: ObjectServiceApi,
    private uiRouterGlobals: UIRouterGlobals,
  ) {
    super();
  }

  /**
   * Optional region id to get sources from. This is set from the
   * region id state param if present.
   */
  get regionId(): string {
    return this.uiRouterGlobals?.params?.regionId;
  }

  /**
   * Get array of exclude filter controls in the sqlExcludeFiltersForm group
   *
   * @returns   FormArray of filter controls
   */
  get excludeFiltersControl(): UntypedFormArray {
    return this.sqlExcludeFiltersForm.get('excludeFilters') as UntypedFormArray;
  }

  /**
   * Get an array of autoprotect object ids which are autoprotected by user
   *
   * @returns   Array of autoprotected ids
   */
  get autoProtectedSourceIds(): number[] {
    return this.data && this.data.excludeOptions &&
      this.data.excludeOptions.autoSelectedSourceIds;
  }

  ngOnInit() {
    if (this.data && this.data.excludeOptions && this.data.excludeOptions.filters &&
      this.data.excludeOptions.filters[0]) {
      this.sqlExcludeFiltersForm = this.fb.group({
        excludeFilters: this.fb.array(
          this.data.excludeOptions.filters.map(filter => this.fb.group({
            filterString: filter.filterString,
            isRegularExpression: filter.isRegularExpression,
          })))
      });
    }
  }

  /**
   * Creates an empty filter form group and returns it
   *
   * @return   Exclude filter form group
   */
  createFilter(): UntypedFormGroup {
    return this.fb.group({
      filterString: ['', Validators.required],
      isRegularExpression: false
    });
  }

  /**
   * Adds an empty form group to the existing filters form array
   */
  addFilter() {
    this.excludeFiltersControl.push(this.createFilter());
  }

  /**
   * Removes a filter from the filters form array.
   *
   * @param   index   The index of the filter to be removed
   */
  removeFilter(index: number) {
    this.excludeFiltersControl.removeAt(index);
  }

  /**
   * Saves filters array and sends API request to get list of exclude object ids
   * from the list of filters provided by the user. Closes the dialog with the
   * result if the API succeeds, otherwise shows an error message.
   */
  apply() {
    if (this.excludeFiltersControl.controls.length === 0) {
      this.dialogRef.close({
        filters: [],
        filteredObjects: [],
      });
      return;
    }
    const params: FilterObjectsRequest = {
      filterType: 'exclude',
      filters: this.excludeFiltersControl.value,
      applicationEnvironment: 'kSQL',
      objectIds: this.autoProtectedSourceIds,
    };

    this.saving = true;
    this.miscService.FilterObjects({body: params, regionId: this.regionId } )
      .pipe(this.untilDestroy(),
      finalize(() => this.saving = false))
      .subscribe((result) => this.dialogRef.close({
        filters: this.excludeFiltersControl.value,
        filteredObjects: (result && result.filteredObjects) || [],
      }),
      (errorHandler) => {
        this.errorMessage = errorHandler.error.message;
      });
  }

  /**
   * Cancels sql exclude dialog.
   */
  cancel() {
    this.dialogRef.close();
  }
}
