<!-- Nfs Root Permissions Component -->
<div *ngIf="nfsRootPermissionsForm" class="margin-top margin-bottom" [formGroup]="nfsRootPermissionsForm">
  <h3 class="no-margin" translate>rootPermissions</h3>
  <div>
    <mat-form-field>
      <mat-label translate>userId</mat-label>
      <input
        type="number"
        matInput
        [disabled]="!editable"
        cogDataId="uid-text-field"
        [inheritDataId]="true"
        formControlName="uid">
    </mat-form-field>
    <mat-form-field class="padding-left">
      <mat-label translate>groupId</mat-label>
      <input
        type="number"
        matInput
        [disabled]="!editable"
        [inheritDataId]="true"
        cogDataId="gid-text-field"
        formControlName="gid">
    </mat-form-field>
  </div>

  <table class="mat-table">
    <tr class="mat-header-row">
      <th class="mat-header-cell"
        *ngFor="let item of ['entity', 'readR', 'writeW', 'executeX']; let first = first;"
        [class.padding-left]="!first">
        {{item | translate}}
      </th>
    </tr>

    <tr class="mat-row" *ngFor="let permission of nfsPermissionsData">
      <td class="mat-cell">{{permission.key | translate}}</td>
      <td class="mat-cell"
        *ngFor="let item of ['read', 'write', 'execute'];">
        <mat-checkbox
          cogDataId="{{permission.key}}-{{item}}-checkbox"
          [checked]="!!permissions[permission.key][item]"
          (change)="onChangePermissions($event, permission, item)"
          class="padding-left"
          [disabled]="nfsRootPermissionsForm.disabled || !editable">
          {{item.label | translate}}
        </mat-checkbox>
      </td>
    </tr>
  </table>
</div>
