<coh-source-special-parameters [form]="form" [nodeContext]="nodeContext"
  [label]="excludeDisks?.length ? ('cSourceTreeNode.customized' | translate) : ''"
  (dialogOpened)="updateForm()"
  (saved)="onSaved()">
  <ng-container *ngIf="form" [formGroup]="form">
    <mat-slide-toggle formControlName="showDisks" id="toggle-exclude-disks"
      (change)="toggleDisks()">
      {{'excludeDisks' | translate}}
    </mat-slide-toggle>

    <div class="c-tile" *ngIf="form.value.showDisks">
      <div class="info margin-top">
        {{'job.settings.excludeDisks.selectDisksOfVm' | translate}}
      </div>
      <mat-form-field class="mat-select-content">
        <mat-select id="exclude-disks-selector-multiple" placeholder="{{'selectDisks' | translate}}"
          formControlName="excludeDisks" multiple>
          <mat-select-trigger *ngIf="form.value.excludeDisks?.length">
            <mat-chip-list>
              <mat-chip class="chip" *ngFor="let disk of form.value.excludeDisks">
                {{disk.controllerType}}
                ({{disk.busNumber}}:{{disk.unitNumber}})
              </mat-chip>
            </mat-chip-list>
          </mat-select-trigger>
          <mat-option *ngFor="let disk of virtualDisks" [value]="disk">
            <div>
              {{disk.controllerType}}
              ({{disk.busNumber}}:{{disk.unitNumber}})
            </div>
            <ul class="c-ul-inline">
              <li class="user-data-xl metadata">{{disk.filename}}</li>
              <li class="user-data-xl metadata">{{disk.logicalSizeBytes | byteSize}}</li>
            </ul>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <ng-container *ngIf="isExchangeRegistered">
      <h2 class="margin-top">
        {{'serverOptions.applicationBasedOptions' | translate}}
      </h2>
      <mat-slide-toggle cogDataId="toggle-exchange-log-truncation"
        class="margin-bottom"
        formControlName="exchangeLogTruncationEnabled">
        {{'serverOptions.exchangeLogTruncation' | translate}}
      </mat-slide-toggle>
    </ng-container>
  </ng-container>
</coh-source-special-parameters>
