import { forwardRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ComponentInterface } from './create-form.types';

/**
 * Provide the CVA related providers to the component so as to make
 * the component work like a FormControl.
 *
 * @param component  The component that requires the providers.
 * @returns The CVA providers.
 */
export function createFormProviders(component: ComponentInterface) {
  return [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => component),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => component),
      multi: true,
    },
  ];
}
