import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Helper root class to use as a notification channel across modules. This
 * should be used to push a notification or message, such as reloading a page,
 * from a component in one module to another within the app.
 */
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  /**
   * Subject to trigger a protection groups reload.
   */
  private readonly reloadProtectionGroupsSubject$ = new Subject<null>();

  /**
   * Subject to force a notification metadata pull.
   */
  private readonly forceFetchNotificationsMeta$ = new Subject<null>();

  /**
   * Observable to subscribe to listen to reloadProtectionGroupsSubject$ updates.
   */
  readonly reloadProtectionGroups$ = this.reloadProtectionGroupsSubject$.asObservable();

  /**
   * Observable to subscribe to listen to forceFetchNotificationsMeta$ updates.
   */
  readonly forceFetchNotifMeta$ = this.forceFetchNotificationsMeta$.asObservable();

  /**
   * Function to trigger an update to reloadProtectionGroupsSubject$.
   */
  notifyProtectionGroupsUpdated() {
    this.reloadProtectionGroupsSubject$.next(null);
  }

  /**
   * Forces to fetch the notification metadata.
   */
  forceFetchNotificationMeta() {
    this.forceFetchNotificationsMeta$.next(null);
  }
}
