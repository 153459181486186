import { Injectable } from '@angular/core';
import { AppName, ClusterConfig } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';

import { AppServiceConfig, AppServiceType } from '../app-service.config';
import { ApplianceManagerNavService } from './appliance-manager-nav.service';

/**
 * This service configures the appliance manager application service configuration within iris.
 */
@Injectable({ providedIn: 'root' })
export class ApplianceManagerServiceConfig extends AppServiceConfig {
  serviceType: AppServiceType = 'applianceManager';

  appName: AppName = 'applianceManager';

  homeState = 'appliance-manager';

  get clusterConfigPartial() {
    return {
      name: this.serviceTitle,
      _allClusters: false,
      _cohesityService: true,
      _globalContext: false,
      _nonCluster: true,
      _serviceType: this.serviceType,
    } as Partial<ClusterConfig>;
  }

  supportsGlobalSearch = false;

  navProvider = this.navService;

  constructor(
    protected translate: TranslateService,
    private navService: ApplianceManagerNavService,
  ) {
    super(translate);
  }
}
