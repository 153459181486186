import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';

import { FormsModule } from '../forms/forms.module';
import { NgUpgradeModule } from '../ng-upgrade';
import { PipesModule } from '../pipes';
import {
  SettingsListBandwidthThrottlingComponent,
} from './settings-list-bandwidth-throttling/settings-list-bandwidth-throttling.component';
import {
  SettingsListClusterInterfaceComponent,
} from './settings-list-cluster-interface/settings-list-cluster-interface.component';
import { SettingsListConcurrencyComponent } from './settings-list-concurrency/settings-list-concurrency.component';
import { SettingsListMountsComponent } from './settings-list-mounts/settings-list-mounts.component';
import {
  SettingsListNetworkSelectorComponent,
} from './settings-list-network-selector/settings-list-network-selector.component';
import { SettingsListRadioGroupComponent } from './settings-list-radio-group/settings-list-radio-group.component';
import { SettingsListScriptsComponent } from './settings-list-scripts/settings-list-scripts.component';
import { SettingsListToggleComponent } from './settings-list-toggle/settings-list-toggle.component';

export * from './settings-list-radio-group/settings-list-radio-group.component';

// The list of exported components from this module.
const exportedComponents = [
  SettingsListBandwidthThrottlingComponent,
  SettingsListClusterInterfaceComponent,
  SettingsListConcurrencyComponent,
  SettingsListMountsComponent,
  SettingsListNetworkSelectorComponent,
  SettingsListRadioGroupComponent,
  SettingsListScriptsComponent,
  SettingsListToggleComponent,
];

@NgModule({
  imports: [
    CohesityHelixModule,
    CommonModule,
    FormsModule,
    NgUpgradeModule,
    PipesModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    TranslateModule,
  ],
  declarations: [...exportedComponents],
  exports: [...exportedComponents],
})
export class SettingsListItemsModule {}
