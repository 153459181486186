<cog-value-property-filter *cogFilterDef="let filter;
    let params = params;
    quickFilter true;
    key: property;
    label: translate(label);
    filterType: 'valueProperty'"
  [filterDefParams]="params"
  [filter]="filter"
  [allowMultiple]="false"
  [filterValues]="filterOptions$ | async">
</cog-value-property-filter>
