import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Controls, NgxSubFormComponent, subformComponentProviders, takeUntilDestroyed } from 'ngx-sub-form';

import { OverwriteOption } from './overwrite-option.model';

@Component({
  selector: 'coh-overwrite-option',
  templateUrl: './overwrite-option.component.html',
  styleUrls: ['./overwrite-option.component.scss'],
  providers: subformComponentProviders(OverwriteOptionComponent),
})
export class OverwriteOptionComponent extends NgxSubFormComponent<OverwriteOption> implements OnInit {

  /**
   * Holds whether to show warning.
   */
  showWarning = false;

  /**
   * Internal overwrite field form control.
   */
  readonly overwrite_ = new UntypedFormControl();

  constructor(private translate: TranslateService) {
    super();
  }

  ngOnInit() {
    this.overwrite_.valueChanges.pipe(
      takeUntilDestroyed(this)
    ).subscribe(val => {
      this.showWarning = val;

      // If toggled off, deactivate the public facing overwrite toggle as well
      // and reset the value of the confirmation text field.
      if (!val) {
        this.formGroupControls.value.setValue(false);
        this.formGroupControls.continue.setValue(null);
      }
    });
  }

  /**
   * Handles event when confirmed.
   */
  setConfirmValue() {
    const continueConfirmationText = this.formGroupValues.continue;
    if (continueConfirmationText?.toLocaleLowerCase() === this.translate.instant('yes').toLocaleLowerCase()) {
      this.formGroupControls.value.setValue(true);
      this.formGroupControls.continue.setValue(null);
      this.showWarning = false;
    } else {
      this.overwrite_.setValue(false);
    }
  }

  getDefaultValues(): Partial<OverwriteOption> {
    return {
      value: false,
    };
  }

  /**
   * Returns a collection of form controls for the subform template.
   */
  protected getFormControls(): Controls<OverwriteOption> {
    return {
      value: new UntypedFormControl(null, [Validators.required]),
      continue: new UntypedFormControl()
    };
  }
}
