<mat-card class="cog-popover">
  <ng-container [ngSwitch]="renderMethod">
    <ng-container *ngSwitchCase="'template'">
      <ng-container *ngTemplateOutlet="contentTemplateRef; context: { $implicit: popoverRef }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'component'">
      <ng-container *ngComponentOutlet="contentComponentRef"></ng-container>
    </ng-container>
  </ng-container>
</mat-card>
