// Controller: Physical Server: Search step

;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.physicalServerRecovery';

  angular
    .module(moduleName)
    .controller('physicalServerRecoverySearchController',
      physicalServerRecoverySearchControllerFn);

  function physicalServerRecoverySearchControllerFn($scope, SearchService,
    PhysicalServerRecoveryService) {
    var $ctrl = this;

    angular.extend($ctrl, {
      // General scope vars
      contextActions: {},

      // Scope methods
      preProcessSearchResults: preProcessSearchResults,
      addToCart: PhysicalServerRecoveryService.addToCart,
    });


    // watcher
    // Update the contextActions object for the new paged set
    $scope.$watchCollection('shared.pagedResults', buildContextActions);


    // METHODS
    /**
     * Initialize all the things!
     *
     * @method     onInit
     */
    $ctrl.$onInit = function onInit() {
      PhysicalServerRecoveryService.fetchDependencies();
      PhysicalServerRecoveryService.initSearchConfig();
    };

    /**
     * Builds context actions with the given entities.
     *
     * @method     buildContextActions
     * @param      {array|object}  entities  One or more Entities
     * @return     {object}        The updated context actions.
     */
    function buildContextActions(entities) {
      entities = [].concat(entities || []);
      entities.forEach(function eachEntityFn(entity) {
        $ctrl.contextActions[entity._id] = [
          {
            translateKey: 'selectServer',
            action: function selectEntityAction() {
              PhysicalServerRecoveryService.addToCart(entity);
            },
          },
        ];
      });
      return $ctrl.contextActions;
    }

    /**
     * Pre-process the search results. Uses the SearchService's transform
     * method under the hood + a little more.
     *
     * @method     preProcessSearchResults
     * @param      {object}  results  The server's raw response object
     * @return     {array}   The results of the SearchService's transformer
     */
    function preProcessSearchResults(results) {
      var resp = SearchService.transformServerResults(results);
      if (!resp.length) {
        return [{
          isEmpty: true
        }];
      }
      return resp;
    }

  }

})(angular);
