import { Injectable } from '@angular/core';
import { TenantServiceApi } from '@cohesity/api/v2';
import {
  flagEnabled,
  IrisContextService,
  isDmsScope,
  isMcm,
} from '@cohesity/iris-core';
import { DefType, Transition } from '@uirouter/core';
import { get } from 'lodash';
import { AjsUpgradeService, BroadcastChannelService, TenantScopeService, TenantService, UserService } from 'src/app/core/services';
import { GuardPriority, GuardResult, StateGuard, StaticRoutes } from 'src/app/core/state/state-guard';
import { environment } from 'src/environments/environment';
import { BroadcastMessageKeys } from 'src/app/shared/constants/boradcast-message-keys.constants';

/**
 * The impersonation guard adds impersonatedOrgId to all routes,
 *  when impersonation is active
 *
 * Also reads impersonatedOrgId from url and activates impersonation,
 *  when impersonation is not active
 */
@Injectable({
  providedIn: 'root',
})
export class ImpersonationGuard implements StateGuard {

  /**
   * This guard should be run before gaurds with App priority
   */
  guardPriority = GuardPriority.ImpersonationOrSwitch;

  /**
   * Legacy TenantService AngularJS service.
   */
  private ajsTenantService: any;

  constructor(
    private ajsUpgrade: AjsUpgradeService,
    private irisContextService: IrisContextService,
    private organizationsService: TenantServiceApi,
    private tenantService: TenantService,
    private tenantScopeService: TenantScopeService,
    private userService: UserService,
    private broadcastChannelService: BroadcastChannelService,
  ) {
    this.ajsTenantService = ajsUpgrade.get('TenantService');
  }

  /**
   * This is run on the onStart ui router transition.
   */
  onStart(transition: Transition): GuardResult {
    if (StaticRoutes.includes(transition.$to().name)) {
      return;
    }

    const toState = transition.$to();
    const paramConfig = toState.self.params;
    const paramDefs = toState.params;
    const params = { ...transition.params() };
    const fromParams = transition.params('from');
    const options = transition.options();
    const impersonatedOrgId = this.tenantService.impersonatedTenantId;

    const getNewRoute = () => transition.router.stateService.target(toState.name, params, options);

    const resetParams = () => {
      params.impersonatedOrgId = undefined;
      params.syncLocalImpersonationToUrl = false;
      return getNewRoute();
    };

    switch (true) {
      // Do not process if feature flag is diabled
      case !flagEnabled(this.irisContextService.irisContext, 'persistentImpersonation') ||
        // TODO: Remove this when impersonation and switch account are made independant
        // As of now as impersonation and switch account can't exist together
        this.ajsTenantService.isTenantAccountSwitchable() ||

        // Do not process if user doesn't have the required privilege,
        // if impersonation is already active, the priv might disappear
        // but as impersonation is active, we can consider the priv exists
        (!this.userService.privs.ORGANIZATION_IMPERSONATE && !impersonatedOrgId && !environment.heliosInFrame):
        // Remove the param from url, if it not being processed
        return params.impersonatedOrgId && resetParams();
    }

    if (params.impersonatedOrgId !== impersonatedOrgId) {
      if (impersonatedOrgId || (params.syncLocalImpersonationToUrl && !environment.heliosInFrame)) {
        params.impersonatedOrgId = impersonatedOrgId;

        // when moving from non impersonated state to impersonated state,
        // clear all existing filters if they are persisted in url
        if (params.impersonatedOrgId !== fromParams.impersonatedOrgId) {
          Object.keys(paramDefs).forEach(key => {
            // Delete only if the param is not a path param
            if (paramDefs[key].location !== DefType.PATH && !get(paramConfig[key], '_persistOnImpersonation', false)) {
              delete params[key];
            }
          });
        }

        // No need to sync local changes as heliosInFrame depends on parent container
        // app to provide apt state changes
        if (!environment.heliosInFrame) {
          // This param is used to identify when impersonatedOrgId changes locally
          // this works based on the assumption that we do a reload on impersonation
          params.syncLocalImpersonationToUrl = true;
        }
        return getNewRoute();
      }

      const isMcmContext = isMcm(this.irisContextService.irisContext) &&
        !isDmsScope(this.irisContextService.irisContext);

      if (isMcmContext && !environment.heliosInFrame) {
        this.organizationsService.GetHeliosTenants({tenantIds: [params.impersonatedOrgId]})
          .subscribe(orgs => {
            this.tenantService.impersonatedTenant$.next({tenantId: orgs.tenants[0].id, ...orgs.tenants[0]});
            this.tenantScopeService.updateUserContextAndValidateState(toState, params);
          });
        return false;
      }

      const impersonationId = isMcmContext ? params.impersonatedOrgId?.split(':')?.[1] : params.impersonatedOrgId;
      return this.ajsTenantService.getTenantById(impersonationId).then(tenant => {
        if (isMcmContext) {
          tenant.tenantId = params.impersonatedOrgId;
        }
        this.tenantService.impersonatedTenant = tenant;
        this.tenantScopeService.updateUserContextAndValidateState(toState, params);
      }).catch(() => {
          // Notify the parent app of impersonation failure if the tenant API call fails.
          this.broadcastChannelService.postMessage(BroadcastMessageKeys.IMPERSONATION_FAILED);
          resetParams();
      });
    }

    if (!params.syncLocalImpersonationToUrl) {
      // For the case when loading impersonation from url
      params.syncLocalImpersonationToUrl = true;
      return getNewRoute();
    }
  }
}
