import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AppOutletComponent } from './app-outlet.component';

@NgModule({
  imports: [CommonModule],
  declarations: [AppOutletComponent],
  exports: [AppOutletComponent],
})
export class AppOutletModule { }
