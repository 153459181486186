// MODULE: Job Details

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.jobDetails')
    .controller('jobDetailsProtectionSettingsController', jobDetailsProtectionSettingsControllerFn);

  function jobDetailsProtectionSettingsControllerFn($rootScope, $scope,
    $timeout, $state, $q, DateTimeService, PolicyService, ENV_GROUPS,
    SourceService, JobService, JobRunsService, evalAJAX, cUtils,
    ENUM_BACKUP_JOB_FIELD, JobDetailsService) {

    // convenience functions and variables
    $scope.JobService = JobService;

    /**
     * Text translations for Reports Filter module
     * @type {Object}
     */
    $scope.text = angular.extend($scope.text, $rootScope.text.protectionJobsDetailsJobSettings);


    /**
     * Gets Job
     * @return {Object} Job
     */
    function getSettingsData() {
      // No info is displayed For inactive Job. set flags to true and exit.
      if (!$scope.job.isActive) {
        $scope.treeReady = true;
        $scope.policyReady = true;
        return;
      }

      if ($scope.job.type === 5) {

        // If dealing with a Remote Adapter Job (type 5)
        // then there is no source to get, just set this flag
        // to true so the rest of the page will load
        $scope.treeReady = true;
      } else {
        $scope.tree = JobDetailsService.tree;
      }

      getPolicy().finally(
        function getPolicyFinally() {
          $scope.policyReady = true;
        }
      );
    }

    /**
     * Gets a Policy
     * @return {Object} Policy
     */
    function getPolicy() {
      var deferred = $q.defer();

      // Always fetch the latest policy info to avoid stale data when updating
      // policy using v2 api and getting policy info using v1 api.
      PolicyService.getPolicy($scope.job.policyId, true).then(
        function getPoliciesSuccess(policy) {
          $scope.job.policy = $scope.policy = policy;

          deferred.resolve();
        },
        function getAllPoliciesFail(response) {
          deferred.reject(response);
        }
      );

      return deferred.promise;
    }

    /**
     * builds an index map of selected nodes for use
     * with SourceService.processHierarchyBranch
     *
     * @return {Object} index map
     */
    function createSelectedMap() {
      var selectedMap = {};

      if (Array.isArray($scope.job.sources)) {
        $scope.job.sources.forEach(function(source) {
          selectedMap[source.entities[0].id] = true;
        });
      }

      return selectedMap;
    }

    /**
     * builds an index map of excluded nodes for use
     * with SourceService.processHierarchyBranch
     *
     * @return {Object} index map
     */
    function createExcludedMap() {
      var excludedMap = {};

      if (Array.isArray($scope.job.excludeSources)) {
        $scope.job.excludeSources.forEach(function(source) {
          excludedMap[source.entities[0].id] = true;
        });
      }

      return excludedMap;
    }

    // If scope.jobReady is true,
    // then job Data was loaded on a previous tab load
    // or the API has already returned a response on this tab load
    // we can go ahead and kick off our call to the remaining settings data
    if ($scope.jobReady) {
      getSettingsData();
    }

    // Listen for jobReady event, then getSettingsData
    $scope.$on('jobReady', getSettingsData);
  }
})(angular);
