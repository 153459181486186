// Service: cPersistConfigService

// This is a thin layer over localStorageService for now. May expand to
// accomodate Scribe data persistence. No ETA for that.

;(function(angular, undefined) {
  'use strict';

  angular.module('C.persistConfig', [])
    .service('cPersistConfigService', cPersistConfigServiceFn);

  function cPersistConfigServiceFn(localStorageService) {
    var prefix = '_persist';

    // This Service's API
    return {
      get: get,
      set: set,
    };

    /**
     * Gets the prefixed cache key.
     *
     * @method   _getInternalCacheKey
     * @param    {string}   cacheKey   The unprefixed cache key.
     * @return   {string}   The prefixed cache key.
     */
    function _getInternalCacheKey(cacheKey) {
      return [prefix, cacheKey].join('.');
    }

    /**
     * Wrapper for localStorage get with an enforced key prefix.
     *
     * @method   get
     * @param    {string}   cacheKey   The cache key to retrieve.
     * @return   {*}        Whatever is stored in cache, or undefined.
     */
    function get(cacheKey) {
      return localStorageService.get(_getInternalCacheKey(cacheKey));
    }

    /**
     * Wrapper for localStorage set with an enforced key prefix.
     *
     * @method   set
     * @param    {string}   cacheKey       The cache key.
     * @param    {*}        [cacheValue]   The value to store in cache.
     * @return   {*}        Unmodified 'cacheValue'.
     */
    function set(cacheKey, cacheValue) {
      localStorageService.set(_getInternalCacheKey(cacheKey), cacheValue);

      return cacheValue;
    }
  }

})(angular);
