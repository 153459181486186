<!-- search for the cluster -->
<mat-form-field class="input-search-term" *ngIf="searchEnabled">
  <input #scopeSearch
    matInput
    type="text"
    id="input-search-term"
    [formControl]="searchTerm"
    placeholder="{{'placeholderSearch' | translate}}">
  <button
    matSuffix
    mat-icon-button
    *ngIf="searchTerm.value"
    [attr.aria-label]="'clear' | translate"
    (click)="searchTerm.setValue('')">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>

<cog-spinner *ngIf="loadingClusters"></cog-spinner>

<ng-container *ngIf="!loadingClusters">
  <!-- registered clusters list -->
  <mat-selection-list dense [multiple]="false" cogDataId="scope-cluster-list">

    <!-- Global Scope select option -->
    <ng-container *ngIf="globalOption">
      <div mat-subheader>
        {{'global' | translate}}
      </div>
      <mat-list-item>
        <mat-list-option
          cogDataId="scope-cluster-radio-{{globalOption.name}}"
          id="scope-cluster-radio-global"
          [attr.name]="'scopeClusterRadioGlobal'"
          [value]="globalOption"
          [selected]="isSelected(globalOption)"
          (click)="handleScopeSelection($event, globalOption)"
          (keydown.enter)="handleScopeSelection($event, globalOption)">
          <div class="scope-item">
            <cog-icon [shape]="iconShape(globalOption)"></cog-icon>
            <div>
              <div class="scope-selector-option-name">
                {{
                  'nSystems' | translate: { n: numClusters + numServices }
                }}
              </div>
            </div>
          </div>
        </mat-list-option>
      </mat-list-item>
    </ng-container>

    <!-- Cohesity services (DRaaS and DMaaS) scopes select option -->
    <ng-container *ngIf="numServices">
      <div mat-subheader>
        <ng-container *ngIf="numServices === 1">
          {{'scopeSelector.managedByCohesity.singular' | translate}}
        </ng-container>

        <ng-container *ngIf="numServices > 1">
          {{'scopeSelector.managedByCohesity.plural' | translate: {n: numServices} }}
        </ng-container>
      </div>
      <mat-list-item *ngFor="let serviceOption of serviceOptions">
        <mat-list-option
          cogDataId="scope-cluster-radio-{{serviceOption.name}}"
          id="scope-cluster-radio-{{serviceOption._serviceType}}"
          [attr.name]="'scopeClusterRadio' + serviceOption._serviceType"
          [value]="serviceOption"
          [disabled]="isDisabled(serviceOption)"
          [selected]="isSelected(serviceOption)"
          (click)="handleScopeSelection($event, serviceOption)">
          <div class="scope-item">
            <cog-icon [shape]="iconShape(serviceOption)"></cog-icon>
            <div>
              <div class="scope-selector-option-name">{{serviceOption.name}}</div>
            </div>
          </div>
        </mat-list-option>
      </mat-list-item>
    </ng-container>

    <!-- list of visible clusters -->
    <div mat-subheader *ngIf="numClusters">
      <ng-container *ngIf="dmsOption; else clusters">
        {{
          'scopeSelector.managedByYou.' + (numClusters === 1 ? 'singular' : 'plural') | translate:
          { n: numClusters }
        }}
      </ng-container>
      <ng-template #clusters>
        {{'clusters' | translate}}
      </ng-template>
    </div>
    <ng-container *ngFor="let cluster of visibleClustersList$ | async; let index = index;">
      <mat-list-item>
        <mat-list-option
          cogDataId="scope-cluster-radio-{{cluster.name}}"
          id="scope-cluster-radio-{{index}}"
          [attr.name]="'scopeClusterRadio' + index"
          [value]="cluster"
          [disabled]="isDisabled(cluster)"
          [selected]="isSelected(cluster)"
          (click)="handleScopeSelection($event, cluster)"
          (keydown.enter)="handleScopeSelection($event, cluster)">
          <div class="scope-item" [class.access-cluster]="isAccessCluster(cluster)">
            <cog-icon [shape]="iconShape(cluster)"></cog-icon>
            <div>
              <div class="scope-selector-option-name">{{cluster.name}}</div>
              <small *ngIf="isAccessCluster(cluster)">{{'accessCluster' | translate}}</small>
            </div>
          </div>
        </mat-list-option>
      </mat-list-item>
    </ng-container>
  </mat-selection-list>
</ng-container>
