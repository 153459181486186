/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityKeplerApiConfiguration as __Configuration } from '../cohesity-kepler-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SiteContinuityUser } from '../models/site-continuity-user';
import { UpdateCurrentUserDetailsRequest } from '../models/update-current-user-details-request';
@Injectable({
  providedIn: 'root',
})
class UsersService extends __BaseService {
  static readonly GetCurrentUserDetailsPath = '/site-continuity/current-user';
  static readonly UpdateCurrentUserDetailsPath = '/site-continuity/current-user';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get current user's details
   *
   * Returns the details of the currently logged in user.
   * @return Response of a successful get current user operation.
   */
  GetCurrentUserDetailsResponse(): __Observable<__StrictHttpResponse<SiteContinuityUser>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/site-continuity/current-user`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SiteContinuityUser>;
      })
    );
  }
  /**
   * Get current user's details
   *
   * Returns the details of the currently logged in user.
   * @return Response of a successful get current user operation.
   */
  GetCurrentUserDetails(): __Observable<SiteContinuityUser> {
    return this.GetCurrentUserDetailsResponse().pipe(
      __map(_r => _r.body as SiteContinuityUser)
    );
  }

  /**
   * Update current user's details
   *
   * Updates the details of the currently logged in user.
   * @param body Specifies the parameters to update currently logged in user's details.
   * @return Response of a successful update current user operation.
   */
  UpdateCurrentUserDetailsResponse(body: UpdateCurrentUserDetailsRequest): __Observable<__StrictHttpResponse<SiteContinuityUser>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/site-continuity/current-user`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SiteContinuityUser>;
      })
    );
  }
  /**
   * Update current user's details
   *
   * Updates the details of the currently logged in user.
   * @param body Specifies the parameters to update currently logged in user's details.
   * @return Response of a successful update current user operation.
   */
  UpdateCurrentUserDetails(body: UpdateCurrentUserDetailsRequest): __Observable<SiteContinuityUser> {
    return this.UpdateCurrentUserDetailsResponse(body).pipe(
      __map(_r => _r.body as SiteContinuityUser)
    );
  }
}

module UsersService {
}

export { UsersService }
