import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

import { ViewSwitch } from './view-switcher.interface';

@Component({
  selector: 'cog-view-switcher',
  templateUrl: './view-switcher.component.html',
})
export class ViewSwitcherComponent {
  /**
   * The list of items for the button group.
   */
  @Input() items: ViewSwitch[];

  /**
   * The value of the button group.
   */
  @Input() value: string;

  /**
   * Indicates whether button group is disabled.
   */
  @Input() disabled = false;

  /**
   * Outputs the selected value.
   */
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: EventEmitter<string> = new EventEmitter();

  /**
   * Emits the selected value.
   *
   * @param  event  Button Toggle event.
   */
  onChange(event: MatButtonToggleChange) {
    this.change.emit(event.value);
  }
}
