import { DataSource } from '@angular/cdk/table';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { BehaviorSubject, combineLatest, merge, Observable, of, Subscription, throwError } from 'rxjs';
import { switchMap, tap, debounceTime, catchError } from 'rxjs/operators';

/**
 * An async table data source should provide a list of data as well as the total size.
 * If the totalSize is not present, the data source will assume there are more items
 *
 * @param   T   The datatype returned by the api
 */
export interface DataSearchResult<T> {
  /**
   * The total size of the dataset, if available
   */
  totalSize?: number;

  /**
   * An array of the data set items matching the current request.
   */
  data: T[];
}

/**
 * This datasource can be used with the coh table component seamlessly for server-size
 * pagination, filtering and sorting. To use it, extend the class and implement the getData
 * method, then, set the datasource as the input for cohTable
 *
 * @param   T   The datatype returned by the api
 * @param   F   The typed filter to use for the table. Defaults to any
 *
 * @example
 *  <coh-table name="adNodeTable" [dataSource]="data" [selection]="tableSelection">
 */
export abstract class AsyncTableDataSource<T, F = any> implements DataSource<T> {
  /**
   * Behavior subject for the currently rendered data. If needed, the currently
   * displayed data can be accessed with dataSubject.value.
   */
  protected dataSubject = new BehaviorSubject<T[]>([]);

  /**
   * The loading subject will emit true when the data set is loading and false
   * otherwise.
   */
  private readonly loadingSubject = new BehaviorSubject<boolean>(true);

  /**
   * The resetPaginationToken subject will emit true when there is change in filter and false
   * if the source is paginator.
   */
  private readonly resetPaginationTokenSubject = new BehaviorSubject<boolean>(true);

  /**
   * The filter subject emits whenever the filters are changed, triggering a data
   * reload.
   */
  private readonly filterSubject = new BehaviorSubject<F>(undefined);

  /**
   * This subscription fires any time the sort, page, or filter changes and initiates
   * a reload.
   */
  private renderChangesSubscription = Subscription.EMPTY;

  /**
   * The loading subject as a readonly observable
   */
  public readonly loading$ = this.loadingSubject.asObservable();

  /**
   * The resetPaginationToken subject as readonly observable
   */
  public readonly resetPaginationToken$ = this.resetPaginationTokenSubject.asObservable();

  /**
   * The MatSort currently applied to the table. This is optional and will automatically
   * be set by <coh-table>.
   *
   * @returns the mat sort
   */
  get sort(): MatSort {
    return this._sort;
  }

  /**
   * Sets the sort directive. This triggers a change to the update subscription.
   * This is optional and will automatically be set by <coh-table>.
   *
   * @param   sort   the mat sort directive that is applied to the mat-table.
   */
  set sort(sort: MatSort) {
    this._sort = sort;
    this.updateChangeSubscription();
  }

  /**
   * Internal reference to MatSort.
   */
  private _sort: MatSort | null;

  /**
   * The MatPaginator currently applied to the table. This is optional and will automatically
   * be set by <coh-table>. Note - this datasource is only intended to work with mat-paginator,
   * and not coh-paginator.
   */
  get paginator(): MatPaginator {
    return this._paginator;
  }

  /**
   * Sets the paginator component. This triggers a change to the update subscription.
   * This is optional and will automatically be set by <coh-table>.
   *
   * @param   paginator   the mat paginator component that is applied to the mat-table.
   */
  set paginator(paginator: MatPaginator) {
    this._paginator = paginator;
    this.updateChangeSubscription();
  }

  /**
   * Internal reference to MatPaginator.
   */
  private _paginator: MatPaginator | null;

  /**
   * Sets the filters for the data source.
   *
   * @returns   The current filter for the table.
   */
  get filter(): F {
    return this.filterSubject.value;
  }

  /**
   * Sets the filter and triggers a reload of the table
   *
   * @param   filter   The filter to apply.
   */
  set filter(filter: F) {
    this.filterSubject.next(filter);
  }

  constructor() {
    this.updateChangeSubscription();
  }

  /**
   * Classes should implement this method to make api calls to load data.
   *
   * @param   filter   The currently applied filter/
   * @param   page     The current page info.
   * @param   sort     The current sort info.
   * @return  An observable of the data search result.
   */
  public abstract getData(filter: F, page: PageEvent, sort: Sort | null | void): Observable<DataSearchResult<T>>;

  /**
   * Configures the change subscriptin to update whenver the sort, page, or filter
   * changes.
   */
  protected updateChangeSubscription() {
    const sortChange: Observable<Sort | null | void> = this._sort
      ? merge<Sort | void>(this._sort.sortChange, this._sort.initialized)
      : of(null);

    const pageChange: Observable<PageEvent | null | void> = this._paginator
      ? merge<PageEvent | void>(this._paginator.page, this._paginator.initialized)
      : of(null);

    const filterChange: Observable<F> = this.filterSubject.asObservable().pipe(
      tap(() => {
        // Whenever the filter changes, we need to reset the paginator
        if (this._paginator) {
          this._paginator.pageIndex = 0;
          this._paginator.length = Number.MAX_SAFE_INTEGER;

          // Since the event source is filter change, emit true
          this.resetPaginationTokenSubject.next(true);
        }
      })
    );

    this.renderChangesSubscription.unsubscribe();

    this.renderChangesSubscription = combineLatest([filterChange, sortChange, pageChange])
      .pipe(
        // Add a debounce to the pipe so that it doesn't fire multiple times when a component is
        // first initializing.
        debounceTime(10),
        switchMap(([filter, sort]) => {
          // Rebuild the page event from the current page status make sure that we catch changes
          // to the page that might have happened by resetting the filter
          const page: PageEvent = {
            pageIndex: this._paginator ? this._paginator.pageIndex : 0,
            pageSize: this._paginator ? this._paginator.pageSize : 25,
            length: this._paginator ? this._paginator.length : Number.MAX_SAFE_INTEGER,
          };

          this.loadingSubject.next(true);
          this.dataSubject.next([]);

          return this.getData(filter, page, sort);
        }),

        // Make sure the loading subject is reset if there's an error
        catchError(e => {
          this.loadingSubject.next(false);
          return throwError(e);
        })
      )
      .subscribe(result => {
        if (!result) {
          return;
        }

        // Emit false upon data reception
        this.resetPaginationTokenSubject.next(false);
        this.loadingSubject.next(false);
        if (!isNaN(result.totalSize) && this._paginator) {
          this._paginator.length = result.totalSize;
        }
        this.dataSubject.next(result.data);
      });
  }

  /**
   * This connects the dataSubject observable to the table.
   */
  connect(): Observable<any[]> {
    return this.dataSubject.asObservable();
  }

  /**
   * When the datasource is disconected from, reset its value to an empty array.
   * Do not complete the observable though, or the data source will not be able
   * to be reused.
   */
  disconnect(): void {
    // Remove filters else it persists the values and causes inconsistency upon next visit.
    this.filterSubject.next(null);
    this.dataSubject.next([]);
  }
}
