<cog-settings-list-item
  [formGroup]="formGroup"
  [invalid]="formGroup.invalid"
  [label]="'protectionGroups.prePostScripts' | translate">
  <cog-settings-list-item-value-display>
    <ng-container *ngIf="formGroupValues.preScriptEnabled || formGroupValues.postScriptEnabled ||
      formGroupValues.postBackupScriptEnabled || formGroupValues.postSnapshotScriptEnabled; else none">
      <p>
        {{ 'preScriptColon' | translate }}
        {{ formGroupValues.preScriptEnabled ? formGroupValues.preScriptName : ('none' | translate) }}
      </p>
      <p *ngIf="!isSan">
        {{ 'postScriptColon' | translate }}
        {{ formGroupValues.postScriptEnabled ? formGroupValues.postScriptName : ('none' | translate) }}
      </p>
      <p *ngIf="isSan">
        {{ 'postBackupScriptColon' | translate }}
        {{ formGroupValues.postBackupScriptEnabled ? formGroupValues.postBackupScriptName : ('none' | translate) }}
      </p>
      <p *ngIf="isSan">
        {{ 'postSnapshotScriptColon' | translate }}
        {{ formGroupValues.postSnapshotScriptEnabled ? formGroupValues.postSnapshotScriptName : ('none' | translate) }}
      </p>
    </ng-container>
  </cog-settings-list-item-value-display>

  <cog-settings-list-item-value-editor>
    <p>{{ title || ('job.settings.prePostScripts.headingText' | translate) }}</p>

    <!-- Remote Host -->
    <ng-container *ngIf="areRemoteScripts">
      <mat-slide-toggle cogDataId="remote-scripts-toggle" [formControlName]="formControlNames.isRemoteScript">
        {{ 'enable' | translate }}
      </mat-slide-toggle>

      <ng-container *ngIf="formGroupValues.isRemoteScript">
        <mat-form-field>
          <mat-label>{{ 'job.settings.remotePrePostScripts.hostDetails' | translate }}</mat-label>
          <input
            type="text"
            matInput
            cogDataId="remote-scripts-host-details"
            [formControlName]="formControlNames.remoteHostAddress"
            required>
          <mat-error>{{ 'errors.required' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'username' | translate }}</mat-label>
          <input
            type="text"
            matInput
            cogDataId="remote-scripts-host-username"
            [formControlName]="formControlNames.remoteHostUsername"
            required>
          <mat-error>{{ 'errors.required' | translate }}</mat-error>
        </mat-form-field>

        <div class="pub-ssh-key-container margin-bottom">
          <ajs-cluster-pub-ssh-key></ajs-cluster-pub-ssh-key>
        </div>
      </ng-container>
    </ng-container>

    <!-- Pre Script -->
    <mat-slide-toggle class="toggle-scripts"
      cogDataId="pre-script-toggle"
      [class.hidden]="areRemoteScripts && !formGroupValues.isRemoteScript"
      [formControlName]="formControlNames.preScriptEnabled">
      {{ 'preScript' | translate }}
    </mat-slide-toggle>

    <div
      class="margin-top margin-bottom"
      *ngIf="formGroupValues.preScriptEnabled"
      cogDataId="pre-script-section">
      <mat-form-field>
        <mat-label>{{ 'protectionJobsJobSummaryJobSummary.scriptPath' | translate }}</mat-label>
        <input
          type="text"
          matInput
          cogDataId="name-input"
          [inheritDataId]="true"
          [formControlName]="formControlNames.preScriptName"
          required>
        <mat-hint *ngIf="!areRemoteScripts">
          {{ 'job.settings.prePostScripts.scriptNameDescription' | translate }}
        </mat-hint>
        <mat-hint *ngIf="areRemoteScripts">
          {{ 'job.settings.prePostScripts.remotePathDescription' | translate }}
        </mat-hint>
        <mat-error>{{ 'errors.required' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'scriptParams' | translate }}</mat-label>
        <input
          type="text"
          matInput
          cogDataId="params-input"
          [inheritDataId]="true"
          [formControlName]="formControlNames.preScriptParams">
      </mat-form-field>

      <mat-form-field class="timeoutFormField">
        <mat-label>{{ 'timeoutMins' | translate }}</mat-label>
        <input
          type="number"
          matInput
          cogDataId="timeout-input"
          [inheritDataId]="true"
          [formControlName]="formControlNames.preScriptTimeout"
          [min]="timeoutLimits.min"
          [max]="timeoutLimits.max">
        <mat-error>{{ 'errors.invalid' | translate }}</mat-error>
      </mat-form-field>

      <mat-slide-toggle
        cogDataId="continue-backup-toggle"
        [inheritDataId]="true"
        [formControlName]="formControlNames.preScriptContinueOnError">
        {{ 'job.settings.prePostScripts.continueBackup' | translate }}
      </mat-slide-toggle>
    </div>

    <!-- Post Script -->
    <mat-slide-toggle class="toggle-scripts"
      *ngIf="!isSan"
      cogDataId="post-script-toggle"
      [class.hidden]="areRemoteScripts && !formGroupValues.isRemoteScript"
      [formControlName]="formControlNames.postScriptEnabled">
      {{ 'postScript' | translate }}
    </mat-slide-toggle>

    <div
      class="margin-top margin-bottom"
      *ngIf="formGroupValues.postScriptEnabled"
      cogDataId="post-script-section">
      <mat-form-field>
        <mat-label>{{ 'protectionJobsJobSummaryJobSummary.scriptPath' | translate }}</mat-label>
        <input
          type="text"
          matInput
          cogDataId="name-input"
          [inheritDataId]="true"
          [formControlName]="formControlNames.postScriptName"
          required>
        <mat-hint>
          {{ (areRemoteScripts ?
             'job.settings.prePostScripts.remotePathDescription' :
             'job.settings.prePostScripts.scriptNameDescription') | translate }}
        </mat-hint>
        <mat-error>{{ 'errors.required' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'scriptParams' | translate }}</mat-label>
        <input
          type="text"
          matInput
          cogDataId="params-input"
          [inheritDataId]="true"
          [formControlName]="formControlNames.postScriptParams">
      </mat-form-field>

      <mat-form-field class="timeoutFormField">
        <mat-label>{{ 'timeoutMins' | translate }}</mat-label>
        <input
          type="number"
          matInput
          cogDataId="timeout-input"
          [inheritDataId]="true"
          [formControlName]="formControlNames.postScriptTimeout"
          [min]="timeoutLimits.min"
          [max]="timeoutLimits.max">
        <mat-error>{{ 'errors.invalid' | translate }}</mat-error>
      </mat-form-field>
    </div>

    <!-- Post Backup Script -->
    <mat-slide-toggle class="toggle-scripts"
      *ngIf="isSan"
      cogDataId="post-backup-script-toggle"
      [class.hidden]="areRemoteScripts && !formGroupValues.isRemoteScript"
      [formControlName]="formControlNames.postBackupScriptEnabled">
      {{ 'postBackupScript' | translate }}
      </mat-slide-toggle>

    <div
      class="margin-top margin-bottom"
      *ngIf="formGroupValues.postBackupScriptEnabled"
      cogDataId="post-backup-script-section">
      <mat-form-field>
        <mat-label>{{ 'protectionJobsJobSummaryJobSummary.scriptPath' | translate }}</mat-label>
        <input
          type="text"
          matInput
          cogDataId="name-input"
          [inheritDataId]="true"
          [formControlName]="formControlNames.postBackupScriptName"
          required>
        <mat-hint>
          {{ (areRemoteScripts ?
             'job.settings.prePostScripts.remotePathDescription' :
             'job.settings.prePostScripts.scriptNameDescription') | translate }}
        </mat-hint>
        <mat-error>{{ 'errors.required' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'scriptParams' | translate }}</mat-label>
        <input
          type="text"
          matInput
          cogDataId="params-input"
          [inheritDataId]="true"
          [formControlName]="formControlNames.postBackupScriptParams">
      </mat-form-field>

      <mat-form-field class="timeoutFormField">
        <mat-label>{{ 'timeoutMins' | translate }}</mat-label>
        <input
          type="number"
          matInput
          cogDataId="timeout-input"
          [inheritDataId]="true"
          [formControlName]="formControlNames.postBackupScriptTimeout"
          [min]="timeoutLimits.min"
          [max]="timeoutLimits.max">
        <mat-error>{{ 'errors.invalid' | translate }}</mat-error>
      </mat-form-field>
    </div>

    <!-- Post Snapshot Script -->
    <mat-slide-toggle
      *ngIf="isSan"
      cogDataId="post-snapshot-script-toggle"
      [class.hidden]="areRemoteScripts && !formGroupValues.isRemoteScript"
      [formControlName]="formControlNames.postSnapshotScriptEnabled">
      {{ 'postSnapshotScript' | translate }}
    </mat-slide-toggle>

    <div
      class="margin-top margin-bottom"
      *ngIf="formGroupValues.postSnapshotScriptEnabled"
      cogDataId="post-snapshot-script-section">
      <cog-banner class="margin-bottom" status="info" *ngIf="isStorageSnapshotPolicy && postBackupScriptSgcFlag">
        {{ 'postBackupScriptInfoMessage' | translate}}
      </cog-banner>
      <mat-form-field>
        <mat-label>{{ 'protectionJobsJobSummaryJobSummary.scriptPath' | translate }}</mat-label>
        <input
          type="text"
          matInput
          cogDataId="name-input"
          [inheritDataId]="true"
          [formControlName]="formControlNames.postSnapshotScriptName"
          required>
        <mat-hint>
          {{ (areRemoteScripts ?
             'job.settings.prePostScripts.remotePathDescription' :
             'job.settings.prePostScripts.scriptNameDescription') | translate }}
        </mat-hint>
        <mat-error>{{ 'errors.required' | translate }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'scriptParams' | translate }}</mat-label>
        <input
          type="text"
          matInput
          cogDataId="params-input"
          [inheritDataId]="true"
          [formControlName]="formControlNames.postSnapshotScriptParams">
      </mat-form-field>

      <mat-form-field class="timeoutFormField">
        <mat-label>{{ 'timeoutMins' | translate }}</mat-label>
        <input
          type="number"
          matInput
          cogDataId="timeout-input"
          [inheritDataId]="true"
          [formControlName]="formControlNames.postSnapshotScriptTimeout"
          [min]="timeoutLimits.min"
          [max]="timeoutLimits.max">
        <mat-error>{{ 'errors.invalid' | translate }}</mat-error>
      </mat-form-field>
    </div>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>

<ng-template #none>
  <p>{{ 'none' | translate }}</p>
</ng-template>
