import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { StateService } from '@uirouter/core';
import { debounceTime, tap } from 'rxjs/operators';
import { AutoDestroyable } from '@cohesity/utils';
import { BroadcastChannelService, FrameOptions, HomeService, McmViewService } from 'src/app/core/services';
import { BroadcastMessageKeys } from 'src/app/shared/constants/boradcast-message-keys.constants';

/**
 * This component renders an iFrame to show older cluster UI.
 * It's a Helios only feature. To be used in Helios Context.
 *
 * Usage
 * <coh-mcm-view></coh-mcm-view>
 */
@Component({
  selector: 'coh-mcm-view',
  templateUrl: './mcm-view.component.html',
  styleUrls: ['./mcm-view.component.scss']
})
export class McmViewComponent extends AutoDestroyable implements OnInit, OnDestroy {

  // Holds whether to show the frame using CSS.
  showFrame = false;

  // The URL to be loaded.
  url: SafeResourceUrl;

  // The element reference to the iframe.
  @ViewChild('mcmFrame', {static: true}) iframe: ElementRef;

  // Holds the Timer object to be cleared.
  private timer: number;

  // Holds the unsubscription function for the broadcast channel subscription.
  broadCastSubscription: () => boolean;

  /**
   * constructor.
   */
  constructor(
    readonly mcmViewService: McmViewService,
    private stateService: StateService,
    private sanitizer: DomSanitizer,
    private broadcastChannelService: BroadcastChannelService,
    private homeService: HomeService,
    private zone: NgZone
  ) {
    super();
  }

  /**
   * OnInit Lifecycle event.
   */
  ngOnInit() {
    this.mcmViewService.iframe = this.iframe;
    // Determine to show the iframe or not.
    this.mcmViewService.updateFrame$.pipe(
      this.untilDestroy(),
      debounceTime(400),
      tap(options => {
        this.handleIFrameState(options);
        this.showFrame = options.show;
      })
    ).subscribe();
    this.subscribeToBroadcastChannel();
  }

  /**
   * Subscribes to the 'impersonationFailed' message from the BroadcastChannel.
   * Upon receiving the message, navigates to dashboard.
   */
  subscribeToBroadcastChannel(): void {
    this.broadCastSubscription = this.broadcastChannelService.subscribe(
      BroadcastMessageKeys.IMPERSONATION_FAILED, () => {
      this.zone.run(() => {
        this.homeService.goHome({ location: 'replace' });
      });
    });
  }

  /**
   * Handles the transition in the iFrame.
   *
   * @param   options   The frame options
   */
  handleIFrameState(options: FrameOptions) {
    // Do not process if the iframe is not to be shown.
    if (!options.show) {
      return;
    }

    // For certain state we need to handle the state transitions differently.
    // Ex. job-modify state params cannot be resolved entirely into the URL.
    // So when navigating from the global search. It's hard to load the iframe
    // with exact url state. So we will load the UI when the state is available
    // we will send the state and params to load the right state.

    // TODO(Sam): There is a better approach to this. Please investigate more.
    const cluster = options.cluster;
    let protectionUrl: string;

    switch (true) {
      case options.navigateTo.includes('protection-builder'):
      case options.navigateTo.includes('job-modify'):
        protectionUrl = cluster.heliosVersion + this.stateService.href(
          'jobs', {}, {absolute: false});
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(protectionUrl);
        this.moveToStateWhenReady(options.navigateTo, options.stateParams || {});
        break;

      case options.navigateTo.includes('ng-views'):
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(`${cluster.heliosVersion}/`);
        this.moveToStateWhenReady(options.navigateTo, options.stateParams || {}, {reload: true});
        break;

      case options.navigateTo.includes('threats'):
        this.setUrlAndFocus(cluster.heliosVersion, options.navigateTo, options.stateParams, true);
        break;

      default:
        this.setUrlAndFocus(cluster.heliosVersion, options.navigateTo, options.stateParams, false);
    }
  }

  /**
   * This method constructs a full URL using the provided `heliosVersion` and `navigateTo` path.
   * It sanitizes the URL to ensure safe use within the application, then sets it as the source
   * for an iframe. The method also optionally adds a trailing slash to the URL.
   *
   * @param heliosVersion - The helios version like he71 or so.
   * @param navigateTo - The path or state to navigate to within the application.
   * @param stateParams - Parameters for the state navigation, allowing dynamic URL construction.
   * @param addTrailingSlash - A boolean flag indicating whether to append a trailing slash to the URL.
   */
  setUrlAndFocus(heliosVersion: string, navigateTo: string, stateParams: any, addTrailingSlash: boolean): void {
    const stateUrl: string = this.stateService.href(
      navigateTo,
      stateParams,
      { absolute: false }
    );
    const sanitizedUrl: string = heliosVersion + (stateUrl || '').replace(/\/$/, '') + (addTrailingSlash ? '/' : '');
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(sanitizedUrl);
    window.setTimeout(this.keepFrameInFocus.bind(this), 2000);
  }

  /**
   * Passes the focuses from the parent to the iframe that is loaded.
   */
  private keepFrameInFocus() {

    // It seems there is a problem with the hybrid angular router where
    // instantiating a $state.go call to the new angular state. It does
    // not resolve the template completely until the iframe in which it is
    // called, comes into focus. BTW document.focus() does not seem to do
    // the trick.
    // TODO(Sam): Explore an alternative.
    const iFrameDoc = this.iframe.nativeElement.contentDocument;
    if (iFrameDoc) {
      iFrameDoc.documentElement.click();
    }

  }


  /**
   * Wait and load the state when $state is available.
   *
   * @param   name   the state name to transition to.
   * @param   params the necessary state params to load.
   */
  moveToStateWhenReady(name: string, params: any, options: any= {}) {
    window.clearTimeout(this.timer);
    const childState = this.iframe &&
      this.iframe.nativeElement.contentWindow &&
      this.iframe.nativeElement.contentWindow.angular &&
      this.iframe.nativeElement.contentWindow.angular.element('html').scope().$state;
    if (childState && !childState.current.abstract) {
      childState.go(name, params);
      window.setTimeout(this.keepFrameInFocus.bind(this), 2000);
    } else {
      this.timer = window.setTimeout(this.moveToStateWhenReady.bind(this, name, params, options), 1000);
    }
  }

  ngOnDestroy(): void {
    // Call the unsubscription function to safely unsubscribe from the broadcast channel
    this.broadCastSubscription();
  }
}
