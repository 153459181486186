import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ScanSummary, SecurityAdvisorServiceApi } from '@cohesity/api/secops';
import { DataFilterValue, DateFilterRange, FiltersComponent } from '@cohesity/helix';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import dayjs from 'dayjs/esm';
import moment, { Moment } from 'moment';
import { debounceTime, finalize } from 'rxjs/operators';

import { PostureAdvisorService } from '../../services';

/**
 * To hold state paramenters
 */
interface routerParams {[key: string]: string | number}

/**
 * Scan results component.
 */
@Component({
  selector: 'dg-pa-scan-results-page',
  templateUrl: './scan-results-page.component.html',
  styleUrls: ['./scan-results-page.component.scss'],
})
export class ScanResultsPageComponent extends AutoDestroyable implements OnInit, AfterViewInit {
  /**
   * Inform parent that 'scan result' has been clicked
   */
  @Output() scanResultClick = new EventEmitter<string>();

  /**
   * Inform parent that 'scan result' has been clicked
   */
  @Output() dateRangeChange = new EventEmitter<routerParams>();

  /**
   * Holds results table column names for standard presentation.
   */
  readonly columns = ['name', 'lowestScore', 'totalClusters', 'totalIssues', 'startTimeMSecs'];

  /**
   * Status of the table data.
   */
  loading = false;

  /**
   * Summary Data
   */
  summaryLists: ScanSummary[];

  /**
   * The global ui
   */
  routerParams: routerParams;

  /**
   * Template for table filters.
   */
  @ViewChild(FiltersComponent) filtersComponent: FiltersComponent;

  /**
   * Data model for cog-date-range-filter. Initialized with undefined value
   */
  filterDateList: DateFilterRange<Moment> = {
    start: undefined,
    end: undefined,
  };

  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private cdr: ChangeDetectorRef,
    private postureAdvisorService: PostureAdvisorService,
    private securityAdvisorService: SecurityAdvisorServiceApi,
  ) {
    super();
  }

  /**
   * Oninit life cycle hook
   */
  ngOnInit() {
    this.routerParams = this.postureAdvisorService.getRouterGlobalParams();
    this.initDateRangeParams();
  }

  /**
   * AfterViewInit life cycle hook
   */
  ngAfterViewInit() {
    this.updateFiltersFromParams();

    // Filter value changes triggered
    this.filtersComponent.filterValues$.pipe(this.untilDestroy(), debounceTime(100)).subscribe(filters => {
      this.onFilterChange(filters);
      this.updateUrlParams(filters);
    });
  }

  /**
   * Handles filters change and makes API call based on filter values.
   *
   * @param filters Date filters.
   */
  onFilterChange(filters: DataFilterValue<any>[]) {
    const params = this.mapSummaryParams(filters);
    this.loading = true;
    this.securityAdvisorService
      .GetScansSummary(params)
      .pipe(
        this.untilDestroy(),
        finalize(() => (this.loading = false))
      )
      .subscribe(response => {
        this.summaryLists = response.results;
        this.cdr.detectChanges();
      }, this.ajaxHandlerService.handler);
  }

  /**
   * Map filters to API call parameters.
   *
   * @param filters Filters applied to the table
   * @returns API call parameters
   */
  mapSummaryParams(filters: DataFilterValue<any>[]): SecurityAdvisorServiceApi.GetScansSummaryParams {
    const params: SecurityAdvisorServiceApi.GetScansSummaryParams = {};

    filters.forEach(appliedFilter => {
      const { key, value } = appliedFilter;
      if (!value || (key !== 'dateRange' && !value.length)) {
        return;
      }
      if (key === 'dateRange') {
        const { start, end } = value;

        if (start) {
          params.startTimeMSecs = start.valueOf();
        }
        if (end) {
          params.endTimeMSecs = end.valueOf();
        }
      }
    });
    return params;
  }

  /**
   * Set the table filters based on date values provided via state/url params.
   */
  updateFiltersFromParams() {
    const params = this.routerParams;

    if (params.startTime) {
      this.filterDateList.start = moment(Number(params.startTime));
    }
    if (params.endTime) {
      this.filterDateList.end = moment(Number(params.endTime));
    }
  }

  /**
   * Updating the URL state params
   *
   * @param filters filters list selected
   */
  private updateUrlParams(filters: DataFilterValue<any, any>[]) {
    const params = this.routerParams;

    // Reset date range
    params.startTime = undefined;
    params.endTime = undefined;

    /**
     * Update the dynamic state params so navigating away from and back to
     * this page will restore the params.
     */
    filters.forEach(filter => {
      if (filter.key === 'dateRange') {
        const { start, end } = filter.value;
        if (start) {
          params.startTime = start.valueOf();
        }
        if (end) {
          params.endTime = end.valueOf();
        }
      }
    });

    // Fire a state change so url params get updated.
    // in state config will prevent this from reloading the entire state.
    this.dateRangeChange.emit(params);
  }

  /**
   * Set default current date as Start and End date for filters
   */
  initDateRangeParams() {
    const params = this.routerParams;

    if (!params.startTime && !params.endTime) {
      const today = dayjs().endOf('day');

      // Set last one week as default date filter
      params.endTime = today.valueOf();
      params.startTime = today.subtract(6, 'd').startOf('day').valueOf();
    }
  }
}
