import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { IrisContext } from './iris-context.model';

@Injectable()
export abstract class IrisContextService {
  /**
   * Internal subject to keep the cluster context.
   */
  private irisContextSubject = new BehaviorSubject<IrisContext>(null);

  /**
   * An observable of the cluster context state.
   */
  irisContext$ = this.irisContextSubject.pipe(filter(context => !!context));

  /**
   * Gets the current cluster context. It's generally safe for shorter-lived
   * components to access this property directly. Longer-lived components (app frame, etc...)
   * or components that need to respond to changes in the cluster context should subscribe
   * to the observable.
   */
  get irisContext(): IrisContext {
    return this.irisContextSubject.value;
  }

  /**
   * Create the context service object.
   *
   * @param skipAutoInit Optional if true will skip auto initilization.
   */
  constructor(@Inject(Boolean) skipAutoInit = false) {
    if (!skipAutoInit) {
      // Automatically initalize the subscription after the constructor completes. If we do
      // it during the constructor, this 'this' properties won't be set up yet.
      setTimeout(() => this.init().subscribe());
    }
  }

  /**
   * Initialize the context service.
   *
   * @returns The iris context.
   */
  init = () => this.getIrisContext().pipe(map(context => {
    this.irisContextSubject.next(context);
    return context;
  }));

  /**
   * Subclasses must implement this to create the cluster context observable.
   */
  abstract getIrisContext(): Observable<IrisContext>;
}
