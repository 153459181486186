import { Component, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { RemoteCluster, ReplicationTarget } from '@cohesity/api/v1';
import { AutoDestroyable } from '@cohesity/utils';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, startWith, tap } from 'rxjs/operators';

import { ReplicationTargetSelectorService } from './replication-target-selector.service';

/**
 * @description
 * Replications Target Selector Component. This can be used when we want to
 * add replication targets in policy or while running a job.
 *
 * @example
 * <coh-replication-target-selector
 *   addNewEnable="true"
 *   viewBoxId="5">
 * </coh-replication-target-selector>
 */
@Component({
  selector: 'coh-replication-target-selector',
  templateUrl: 'replication-target-selector.component.html',
  styleUrls: ['./replication-target-selector.component.scss'],
})

// TODO (Tung) 6.4.2: switch to implement MatFormFieldControl for better error handling.
// https://material.angular.io/guide/creating-a-custom-form-field-control

export class ReplicationTargetSelectorComponent extends AutoDestroyable
  implements OnInit, OnDestroy, ControlValueAccessor {
  /**
   * The selected target
   */
  get selectedTarget(): RemoteCluster {
    return this._selectedTarget;
  }

  /**
   * Sets the selected target and propagates the change
   */
  @Input() set selectedTarget(selectedTarget) {
    this._selectedTarget = selectedTarget;
    this.onModelChange(selectedTarget);
  }
  /**
   * The selected replication target
   */
  private _selectedTarget: RemoteCluster;

  /**
   * Whether add new cluster button should be enabled
   */
  @Input() addNewEnable = false;

  /**
   * Storage Domain used in the RPO policy or job
   */
  @Input() viewBoxId?: number;

  /**
   * Specifies the unique id of the component.
   */
  @Input() id = '';

  /**
   * If enabled, source selector will have searching.
   */
  @Input() allowSearch = true;

  /**
   * If specified, search for replication targets on the given cluster.
   */
  @Input() clusterId: string;

  /**
   * If specified, search for replication targets for the given organizations.
   */
  @Input() organizationIds: string[];

  /**
   * Form Control for searching the values.
   */
  searchCtrl = new UntypedFormControl();

  /**
   * Observable of the list of clusters that can be selected.
   */
  clusters$: Observable<RemoteCluster[]>;

  /**
   * List of all filtered clusters.
   */
  filteredClusters$: Observable<RemoteCluster[]>;

  /**
   * Observable of search string.
   */
  private search$: Observable<string>;

  /**
   * Callback to pass the change back to a form control. Defaults to a noop
   */
  private onModelChange = (_value: any) => {};

  /**
   * Callback to register on touched.
   */
  private onTouched = () => {};

  /**
   * Creates a new component
   *
   * @param   ngControl  Instance of component's ngControl.
   * @param   _service   Replication Target Selector Service
   */
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private _service: ReplicationTargetSelectorService
  ) {
    super();

    // Set the value accessor directly.
    // This is equivalent to injecting NG_VALUE_ACCESSOR to the provider.
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  /**
   * Initialize and retrieve the shared clusters list
   */
  ngOnInit() {
    this.search$ = this.searchCtrl ?
      this.searchCtrl.valueChanges.pipe(startWith('')) :
      of('');
    this.getTargets();
  }

  /**
   * Update the view with a value passed from a form
   *
   * @param   cluster   the view box id
   */
  writeValue(cluster: ReplicationTarget) {
    this._selectedTarget = cluster;
  }

  /**
   * Registers a change event to use to propagate changes
   *
   * @param   fn   the callback function
   */
  registerOnChange(fn: (value: ReplicationTarget) => {}) {
    this.onModelChange = fn;
  }

  /**
   * Register on touched.
   */
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  /**
   * If passed a valid object, this will set it in the request object,
   * otherwise it triggers the Register New Replication Target modal and uses
   * that newly registered target.
   */
  registerNewCluster() {
    this._service.registerNew(this.viewBoxId, this.clusterId).pipe(this.untilDestroy())
      .subscribe((cluster: RemoteCluster) => {
        // Set the selection to this new target.
        this.selectedTarget = cluster;
      },
      // Do nothing if legacy ajs service's promise rejects.
      // This happens when user clicks cancel.
      () => {});
  }

  /**
   * Triggers when mat select panel is open/closed.
   * Manually reset selectedTarget if value is undefined/empty.
   * Once we switch to MatFormFieldControl, this should not be necessary.
   *
   * @param  isOpen  Whether mat-select panel is open.
   */
  openedChange(isOpen: boolean) {
    this.onTouched();
    if (!this.ngControl.value && !isOpen) {
      this.selectedTarget = undefined;
    }
  }

  /**
   * Clean up subscriptions
   */
  ngOnDestroy() {
    this.selectedTarget = undefined;
  }

  /**
   * Gets remote target clusters and update filteredCluster$.
   */
  private getTargets() {
    this._service.fetchData(this.viewBoxId, this.organizationIds);
    this.clusters$ = this._service.sharedClusters$?.pipe(
      filter(clusters => !!clusters[0]),
      tap(clusters => {
        if (this.selectedTarget) {
          const selectedCluster =
            (clusters as RemoteCluster[])?.find(cluster => cluster?.clusterId === this.selectedTarget?.clusterId);
          this.selectedTarget =
            selectedCluster ? (selectedCluster as any)._target : undefined;
        }
      })
    );
    this.updateFilteredClusters();
  }

  /**
   * Sets filteredClusters$ observable after cluster$ is set.
   */
  private updateFilteredClusters() {
    this.filteredClusters$ = combineLatest([
      this.clusters$,
      this.search$,
    ])?.pipe(map(([clusters, search]) => {
      if (!search) {
        return clusters;
      }

      const searchStringRegex = new RegExp(search, 'i');
      const filteredClusters = [];

      for (const cluster of clusters) {
        if (cluster.name.search(searchStringRegex) > -1) {
          filteredClusters.push(cluster);
        }
      }

      return filteredClusters;
    }));
  }
}
