import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NavItem } from '@cohesity/helix';
import { AutoDestroyable } from '@cohesity/utils';

/**
 * @description
 * Action menu for a row.
 *
 * @example
 *   <dg-pa-action-menu [menuItems]="menuItems" ></dg-pa-action-menu>
 */
@Component({
  selector: 'dg-pa-action-menu',
  templateUrl: './action-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionMenuComponent extends AutoDestroyable {
  /**
   * Inform parent that an action has been clicked
   */
  @Output() menuItemClick = new EventEmitter<string>();

  /**
   * The action menu items for the activity
   */
  @Input() menuItems: NavItem[] = [];

  constructor() {
    super();
  }
}
