import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';


/**
 * Grain defintion.
 */
export interface Grain {
  /**
   * Label for grain.
   */
  label: string;

  /**
   * Multiplier factor required for value convertion.
   */
  multiplier: number;

  /**
   * Grain unit defintion.
   */
  unit: string;
}

/**
 * RetentionValue value payload
 */
export interface RetentionValue {
  /**
   * Grain quantity
   */
  quantity: number;

  /**
   * Grain value
   */
  grain: Grain;
}

/**
 * Default grain quantity value is one second.
 */
const DEFAULT_GRAIN_QUANTITY = 1;

/**
 * Allows positive Arabic numerals only.
 */
const REGEX_POSITIVE_NUMBERS = /^\d+$/;

/**
 * @description
 * Customized Retention selection component which provides input to record grain
 * quantity along with grain unit selector.
 *
 * @example
 *  <coh-retention-selector
 *    [id]="templateId"
 *    [grains]="grainsList"
 *    [placeholder]="placeholderKey"
 *    [label]="fieldName"
 *    [omitGrains]="omitGrainList"
 *    [defaultGrain]="defaultGrainValue"
 *    [defaultGrainQuantity]="defaultValue"
 *    [multiplierFactor]="scalefactor"
 *    [isDisabled]="disabled"
 *    [control]="formControl"
 *    [min]="minValue"
 *    (valueChange)="valueChangeHandler($event)">
 *  </coh-retention-selector>
 */
@Component({
  selector: 'coh-retention-selector',
  templateUrl: './retention-selector.component.html',
  styleUrls: ['./retention-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetentionSelectorComponent implements OnInit {

  /**
   * Default value for array of grains. To build on top of these grains,
   * use 'RetentionSelectorComponent.defaultGrains' in the consuming components.
   */
  static readonly defaultGrains: Grain[] = [
    {
      label: 'forever',
      multiplier: -1,
      unit: 'Forever',
    },
    {
      label: 'none',
      multiplier: 0,
      unit: 'None',
    },
    {
      label: 'seconds',
      multiplier: 1,
      unit: 'Seconds',
    },
    {
      label: 'minutes',
      multiplier: 60,
      unit: 'Minutes',
    },
    {
      label: 'hours',
      multiplier: 3600,
      unit: 'Hours',
    },
    {
      label: 'days',
      multiplier: 3600 * 24,
      unit: 'Days',
    },
    {
      label: 'weeks',
      multiplier: 3600 * 24 * 7,
      unit: 'Weeks',
    },
    {
      label: 'months',
      multiplier: 3600 * 24 * 30,
      unit: 'Months',
    },
    {
      label: 'years',
      multiplier: 3600 * 24 * 365,
      unit: 'Years',
    },
  ];

  /**
   * Form control required for validations.
   */
  @Input() control: UntypedFormControl;

  /**
   * Label for form field.
   */
  @Input() label: string;

  /**
   * Value used for identifying unique field attributes.
   */
  @Input() id: string;

  /**
   * Array of grains to override the default specified here.
   */
  @Input() grains: Grain[];

  /**
   * Optional Array of grains to omit here.
   */
  @Input() omitGrains: string[];

  /**
   * Value of grain by default.
   */
  @Input() defaultGrain = 'Months';

  /**
   * Optional multiplier factor multiplied to get the initial value in milleseconds.
   * Default value is 0.001.
   */
  @Input() multiplierFactor = 0.001;

  /**
   * Specifies whether to disable fields or not. Default is false.
   */
  _isDisabled = false;

  /**
   * Optional value for disabling the fields.
   */
  @Input()
  set isDisabled(isDisabled: boolean) {
    this._isDisabled = isDisabled;

    // Disable form fields based on isDisabled flag provided.
    if (this._isDisabled) {
      this.grainCtrl.disable();
      this.grainQuantityCtrl.disable();
      return;
    }

    // Enabling fields if disabled condition returns false.
    this.grainCtrl.enable();
    this.grainQuantityCtrl.enable();
  }

  /**
   * Returns value of isDisabled.
   */
  get isDisabled(): boolean {
    return this._isDisabled;
  }

  /**
   * Optional translation key for disabled tooltip.
   */
  @Input() disabledTooltip: string;

  /**
   * Optional minimum value. Default is 1.
   */
  @Input() min = 1;

  /**
   * Default value for grain quantity.
   */
  @Input() defaultGrainQuantity: number;

  /**
   * Optional placeholder key for grain quantity input.
   */
  @Input() placeholder: string;

  /**
   * Base grain unit before rounding.
   */
  @Input() baseGrainUnit: string;

  /**
   * Whether disable grain rounding or not. Default to false.
   * When disableRounding is set to be true, then user selected grain won't rounded to bigger grain.
   * For example, if user type 12 months, we won't round it to 1 year.
   */
  @Input() disableRounding = false;

  /**
   * Whether to disable the required validator. Since in some form, retention is not a required field, so need to remove
   * the Validators.required.
   */
  @Input() disableRequired = false;

  /**
   * Optional translation key for an info tooltip.
   */
  @Input() infoTooltip?: string;

  /**
   * Updates the output scaled value.
   */
  @Output() valueChange = new EventEmitter<number>();

  /**
   * Updated full scaled value.
   */
  @Output() fullValueChange = new EventEmitter<RetentionValue>();

  /**
   * Input value for grain quantity.
   */
  grainQuantityCtrl = new UntypedFormControl(null);

  /**
   * Grain value control.
   */
  grainCtrl = new UntypedFormControl(null);

  /**
   * Determines whether to show the retention input or not.
   *
   * @return   True if value is not a unit of time.
   */
  get showInput(): boolean {
    const isVisible = !['Forever', 'None', undefined].includes(this.grainCtrl?.value && this.grainCtrl?.value.unit);

    // On updating the grain if the grainQuantity is invalid,
    // then resetting the form control.
    if (!isVisible && this.grainQuantityCtrl.invalid) {
      this.grainQuantityCtrl.reset();
    }
    return isVisible;
  }

  constructor() {}

  /**
   * Init Component.
   */
  ngOnInit() {
    this.grains = this.grains || RetentionSelectorComponent.defaultGrains;

    // Filtering the grains based on omit grains input.
    if (this.omitGrains && this.omitGrains.length) {
      this.grains = this.grains.filter(grain => !this.omitGrains.includes(grain.unit));
    }

    this.grainCtrl.setValue(this.getGrain(this.defaultGrain));

    // If there is only one grain then disabling the grain selector field.
    if (this.grains.length === 1) {
      this.grainCtrl.disable();
    }

    // Setting validators for grain input.
    this.grainQuantityCtrl.setValidators([
      Validators.required,
      Validators.min(this.min),
      Validators.pattern(REGEX_POSITIVE_NUMBERS),
    ]);

    if (this.disableRequired) {
      this.grainQuantityCtrl.removeValidators(Validators.required);
    }

    this.calculateGrainEntities(this.control?.value);

    // Set derived values.
    this.updateValue();

    // On control value changes updating the grain value and validators.
    this.control?.valueChanges.subscribe(value => {
      this.calculateGrainEntities(value);
    });
  }

  /**
   * Method called to get the grain based on updated value.
   *
   * @param   value   updated value
   * @returns         grain value
   */
  getGrain(value: string): Grain {
    if (this.grains && this.grains.length) {
      return this.grains.find(grain => grain.unit === value);
    }
  }

  /**
   * Updates the value with the latest selected grain and quantity.
   */
  updateValue() {
    switch (this.grainCtrl?.value.unit) {
      case 'Forever':
        this.valueChange.emit(-1);
        break;

      case 'None':
        this.valueChange.emit(undefined);
        break;

      default: {
        this.baseGrainUnit = this.grainCtrl?.value?.unit;

        const newValue = this.grainQuantityCtrl?.value >= 0 ? this.grainQuantityCtrl?.value
          : DEFAULT_GRAIN_QUANTITY;

        this.valueChange.emit(newValue * this.grainCtrl.value.multiplier / this.multiplierFactor);
        this.fullValueChange.emit({ quantity: newValue, grain: this.grainCtrl?.value });
      }
    }
  }

  /**
   * Calculates the nearest granularity (grain and grain quantity) to display,
   * eg. 3000 will display "3 Seconds" granularity, and 63072000 will display "2 Years".
   *
   * @param   retentionValue   updated retention value
   */
  calculateGrainEntities(retentionValue: number) {
    let initialValue: number;
    const defaultvalue = this.getGrain(this.defaultGrain);

    switch (retentionValue) {
      case -1:
        this.grainCtrl.setValue(this.getGrain('Forever') || defaultvalue);
        break;

      case undefined:
      case null:
        // Setting default grain if the value is omitted from grain list.
        this.grainCtrl.setValue(this.getGrain('None') || defaultvalue);
        break;

      default:
        initialValue = typeof retentionValue === 'number' ? parseInt((retentionValue *
          this.multiplierFactor).toString(), 10) : this.defaultGrainQuantity;
    }

    if (initialValue === undefined || initialValue === null || Number.isNaN(initialValue)) {
      return;
    }

    // if disableRounding is true then do not round the grain. Use the only the specified grain.
    if (this.disableRounding) {
      this.grainCtrl.setValue(this.getGrain(this.baseGrainUnit));
      this.grainQuantityCtrl.setValue(initialValue / this.grainCtrl.value.multiplier);
    } else {
      // Traverse the available grains to find the nearest grain for display.
      const nearestGrain = this.grains.slice(0).reverse().find((grain) => {
        const ratio = initialValue / grain.multiplier;

        // If the ratio is greater than 1, then we're in the right ballpark,
        // then if the ratio is also a whole number (grain value is evenly
        // divisble by the ratio).
        return ratio >= 1 && ratio === parseInt(ratio.toString(), 10);
      }) || this.grainCtrl?.value || this.getGrain('Months');

      this.grainCtrl.setValue(nearestGrain);
      this.grainQuantityCtrl.setValue(initialValue / this.grainCtrl.value.multiplier);
    }
  }
}
