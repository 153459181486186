import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { DataTreeNode, DataTreeSelectionModel, NavItem } from '@cohesity/helix';
import { Environment } from '@cohesity/iris-shared-constants';
import {
  SOURCE_TREE_CONTEXT,
  SOURCE_TREE_SERVICE_FACTORY,
  SourceTreeService,
  SourceTreeServiceFactory,
} from '@cohesity/iris-source-tree';
import { AutoDestroyable } from '@cohesity/utils';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { PassthroughOptionsService } from 'src/app/core/services';
import { ObjectMenuProvider } from 'src/app/modules/object-details-shared';

import { SourceTreeContext } from '../source-tree-context';

/**
 * This component uses cog-table-multi action to render action items for one or more objects in a source tree.
 */
@Component({
  selector: 'coh-source-tree-table-actions',
  templateUrl: './source-tree-table-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SourceTreeTableActionsComponent<T extends DataTreeNode<any>> extends AutoDestroyable implements OnInit {
  /**
   * The selection model
   */
  @Input() selection: DataTreeSelectionModel<T>;

  /**
   * Sets the environment used for this object. Use this to get an instance
   * of the source tree service.
   */
  @Input() set environment(environment: Environment) {
    this.treeService = this.transformerFactory.getSourceTreeService(environment);
  }

  /**
   * Set when we have any workload defined.
   */
  @Input() workloadType: string = undefined;

  /**
   * Whether to show or hide the action row.
   */
  hidden$: Observable<boolean>;

  /**
   * Gets the object menu provider, if any.
   */
  get actionProvider(): ObjectMenuProvider {
    return this.sourceTreeContext?.objectActionProvider;
  }

  /**
   * An observable of actions that should be displayed for the selection.
   */
  actions$: Observable<NavItem[]> = of([]);

  /**
   * Tracks whether the actions for a given selection are in the process of being loaded.
   */
  actionsLoading$ = new BehaviorSubject<boolean>(false);

  /**
   * Use the tree service to transform to the source selection
   */
  private treeService: SourceTreeService<any, any>;

  constructor(
    @Optional() @Inject(SOURCE_TREE_CONTEXT) private sourceTreeContext: SourceTreeContext,
    @Inject(SOURCE_TREE_SERVICE_FACTORY) private transformerFactory: SourceTreeServiceFactory,
    private cdr: ChangeDetectorRef,
    private passthroughOptionsService: PassthroughOptionsService,
  ) {
    super();
  }

  ngOnInit() {
    if (!this.actionProvider) {
      return;
    }
    this.hidden$ = this.selection.changes$.pipe(
      map(currentSelection => !currentSelection.selected?.length && !currentSelection.autoSelected?.length)
    );

    this.actions$ = this.selection.changes$.pipe(
      this.untilDestroy(),
      startWith(this.selection.currentSelection),
      tap(() => {
        if (!this.actionProvider) {
          throw new Error('No action provider configured');
        }
        if (!this.treeService) {
          throw new Error('No soure tree service available, specify an environment input');
        }
      }),
      tap(() => this.actionsLoading$.next(true)),
      switchMap(currentSelection => {
        const options = {
          ...this.passthroughOptionsService.requestParams,
          workloadType: this.workloadType
        };
        return this.actionProvider.getBulkTreeObjectActions(
          currentSelection,
          this.treeService.transformFromDataTreeSelection(currentSelection),
          options
        );
      }),
      tap(() => this.actionsLoading$.next(false))
    );
  }
}
