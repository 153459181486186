<ng-container>
  <mat-slider #sMin
    class="min-range-slider"
    [disabled]="disabled"
    [color]="color"
    [tabIndex]="tabIndex"
    [max]="max"
    [min]="min"
    [step]="step"
    [thumbLabel]="thumbLabel"
    [tickInterval]="tickInterval"
    [displayWith]="displayWith"
    [inheritDataId]="true"
    [value]="selectedRange.min"
    (change)="onRangeSelection($event, 'min')"
    cogDataId="min-slider">
  </mat-slider>

  <mat-slider #sMax
    class="max-range-slider"
    [disabled]="disabled"
    [color]="color"
    [tabIndex]="maxTabIndex"
    [max]="max"
    [min]="min"
    [step]="step"
    [thumbLabel]="thumbLabel"
    [tickInterval]="tickInterval"
    [displayWith]="displayWith"
    [value]="selectedRange.max"
    (change)="onRangeSelection($event, 'max')"
    [inheritDataId]="true"
    cogDataId="max-slider">
  </mat-slider>
</ng-container>
