import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocaleService } from 'src/app/core/services';
import { Locale, LocaleItem } from 'src/app/models';
import { AutoDestroyable } from '../../constants';


/**
 * This component is used to set locale. Can be used using a two way binded
 * model or a FormControl.
 *
 * @example
 *
 * 1. Using a model -
 *      this.locale: Locale;
 *
 *      <coh-select-locale [(selectedLocale)]="locale"
 *         [hideLabel]="true"></coh-select-locale>
 *
 * 2. Using a FormControl -
 *      <coh-select-locale formControlName="locale"></coh-select-locale>
 */
@Component({
  selector: 'coh-select-locale',
  templateUrl: './select-locale.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectLocaleComponent),
      multi: true,
    },
  ],
})
export class SelectLocaleComponent extends AutoDestroyable implements OnInit, ControlValueAccessor {

  /**
   * The list of available locales.
   */
  localesList: LocaleItem[] = [];

  /**
   * The selected locale that is to be propagated.
   */
  @Input() selectedLocale?: Locale;

  /**
   * If you need to hide the floating label, set this to true.
   */
  @Input() hideLabel?: boolean;

  /**
   * The change fn which gets called when the selectedLocale is changed hence
   * propagating the changes.
   */
  @Output() selectedLocaleChange = new EventEmitter<string>();

  constructor(private localeService: LocaleService) {
    super();
   }

  ngOnInit() {
    this.localesList = this.localeService.getLocalesList();
    this.setSelectedLocale();
  }

  /**
   * Sets the selected locale from the user preferences.
   */
  setSelectedLocale() {
    if (!this.selectedLocale) {
      this.localeService.getUserLocale()
        .pipe(this.untilDestroy())
        .subscribe(locale => {
          this.selectedLocale = locale;
          this.selectedLocaleChange.emit(this.selectedLocale);
        });
    }
  }

  /**
   * Emit the changes when the locale is changed.
   *
   * @param locale The changed locale.
   */
  selectedLocaleValueChange() {
    this.selectedLocaleChange.emit(this.selectedLocale);
    this.onChange(this.selectedLocale);
  }

  /**
   * Update the view on model changes is request programmatic via forms API.
   *
   * This method is called by the forms API to write to the view when programmatic changes from model to view are
   * requested.
   *
   * @param   selection   The object selection.
   */
  writeValue(selection: Locale) {
    if (selection) {
      // updating the internal form new values.
      this.selectedLocale = selection;

      // Cancel the subscription which gets the user locale and sets it inside
      // as it overrides this selection.
    }
  }

  /**
   * This method is called by the forms API on initialization to update the form
   * model when values propagate from the view to the model.
   */
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  /**
   * Registers a callback function is called by the forms API on initialization
   * to update the form model on blur.
   */
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  /**
   * The placeholder method populated by forms API registerOnChange method which
   * is used to update changes from view to modal.
   */
  onChange = (_selection: Locale) => {};

  /**
   * The placeholder method populated by forms API registerOnTouched method which
   * is used to mark a form field should be considered blurred or "touched".
   */
  onTouched = () => {};

  /**
   * Function that is called by the forms API when the control status changes to
   * or from 'DISABLED'. Depending on the status, it enables or disables the
   * appropriate DOM element.
   */
  setDisabledState(_isDisabled: boolean) {}
}
