<cog-settings-list-item
  cogFormGroup
  *ngIf="protectionGroup$ | async as protectionGroup"
  (editModeChanged)="setEditMode($event)"
  cogDataId="backup-form">
  <cog-settings-list-item-value-display>
    <ng-container *ngTemplateOutlet="infoLabel; context: {
        protectionGroup: protectionGroup$ | async,
        policy: policy$ | async,
        formGroup: formGroup
      }">
    </ng-container>
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor>
    <fieldset [formGroup]="formGroup">
      <div class="backup-inputs" formGroupName="backupForm">
        <mat-form-field>
          <mat-label translate>backup</mat-label>
          <mat-select formControlName="backupObjectsScope" cogDataId="backup-scope-select"
            [inheritDataId]="true">
            <mat-option *ngFor="let scope of backupScopes$ | async" cogDataId="{{ scope }}"
              [inheritDataId]="true" [value]="scope">
              {{ 'enum.groupAction.objectScope.' + scope | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label translate>backupType</mat-label>
          <mat-select formControlName="backupType" cogDataId="backup-type-select"
            [inheritDataId]="true">
            <mat-option *ngFor="let type of runTypes$ | async" cogDataId="{{ type }}" [inheritDataId]="true"
              [value]="type">
              {{ 'enum.jobRunType.' + type | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="backup-meta">
        <ng-container *ngTemplateOutlet="infoLabel; context: {
          protectionGroup: protectionGroup$ | async,
          policy: policy$ | async,
          formGroup: formGroup
        }">
        </ng-container>

        <button
          *ngIf="formEditMode && this.formGroup.backupFormScope.value === backupScope.Selected"
          (click)="editObjectSelectionDialog()"
          [inheritDataId]="true"
          class="edit-action"
          [cogDataId]="'edit-button'"
          mat-icon-button
          type="button">
          <cog-icon [shape]="'edit!outline'"></cog-icon>
        </button>
      </div>
    </fieldset>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>

<ng-template #infoLabel
  let-protectionGroup="protectionGroup"
  let-policy="policy"
  let-formGroup="formGroup">
  <coh-group-action-target-info type="backup" status="scheduled">
    <ul class="subtitle-list">
      <li *ngIf="protectionGroup.name">
        {{ protectionGroup.name }}
      </li>
      <li *ngIf="formGroup.backupFormObjects.value?.length" cogDataId="selected-objects-count">
        {{ 'nObjects' | translate: { n: formGroup.backupFormObjects.value?.length } }}
      </li>
      <li *ngIf="policy.backupPolicy.regular.retention.unit && policy.backupPolicy.regular.retention.duration">
        {{ 'policySynopsis.retention.' + policy.backupPolicy.regular.retention.unit | translate: {
          duration: policy.backupPolicy.regular.retention.duration
        } }}
      </li>
      <li *ngIf="policy.backupPolicy.regular.retention.dataLockConfig as dataLockConfig">
        {{ 'policySynopsis.datalock.' + dataLockConfig.unit | translate: {
          duration: dataLockConfig.duration
        } }}
      </li>
    </ul>
  </coh-group-action-target-info>
</ng-template>
