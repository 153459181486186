import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AngularMaterialModule } from '../../angular-material.module';
import { DatePipesModule } from '../date-pipe';
import { DurationProgressComponent } from './duration-progress.component';

const components = [DurationProgressComponent];

@NgModule({
  imports: [AngularMaterialModule, DatePipesModule, CommonModule],
  declarations: components,
  exports: components,
})
export class DurationProgressModule {}
