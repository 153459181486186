<a cogDashcard
  [title]="'protection' | translate"
  [subtitle]="'objectsProtected' | translate"
  uiSref="jobs"
  *ngIf="!allClusters">
  <ng-container *ngTemplateOutlet="protectionCardTempl"></ng-container>
</a>

<cog-dashcard *ngIf="allClusters"
  [title]="'protection' | translate"
  [subtitle]="'objectsProtected' | translate">
  <ng-container *ngTemplateOutlet="protectionCardTempl"></ng-container>
</cog-dashcard>

<ng-template #protectionCardTempl>
  <cog-dashcard-layout-standard *ngIf="protectionData && !isLoading">
    <cog-dashcard-chart>
      <cog-donut-chart colorSetClass="consumption"
        [isClickable]="true"
        [seriesData]="protectionSeriesData"
        [customChartOptions]="customChartOptions">
        <span>
          {{protectionPct}}<small>%</small>
        </span>
      </cog-donut-chart>
    </cog-dashcard-chart>
    <cog-dashcard-legend>
      <cog-statlist valueSize="sm" [vertical]="true">
        <a cogStatlistItem *ngFor="let series of protectionData.series; index as i;"
          (click)="goToReport()"
          [label]="series.data[0].y | number"
          [labelLegend]="'highcharts-series-' + i">
          {{series.name | translate}}
        </a>
      </cog-statlist>
    </cog-dashcard-legend>
    <cog-dashcard-footer>
      <coh-run-failures-status *ngIf="!allClusters"></coh-run-failures-status>
    </cog-dashcard-footer>
  </cog-dashcard-layout-standard>
  <cog-spinner *ngIf="isLoading"></cog-spinner>
  <coh-blank-card type="view" *ngIf="!protectionData && !isLoading"></coh-blank-card>
</ng-template>
