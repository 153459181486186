import { Component, Input } from '@angular/core';
import { ProtectionSourcesServiceApi } from '@cohesity/api/v1';
import { FiltersComponent, ValueFilterSelection } from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PassthroughOptionsService } from 'src/app/core/services';

import { SourceKeys, sourceTypesDisplayNames } from '../../constants';
import { BaseObjectFilterComponent } from './../base-object-filter';


/**
 * This is a reusable filter that can be used to filter for protection sources
 */
@Component({
  selector: 'coh-source-filter',
  templateUrl: './source-filter.component.html',
})
export class SourceFilterComponent extends BaseObjectFilterComponent<
ProtectionSourcesServiceApi.ListProtectionSourcesRootNodesParams
> {
  /**
   * The name of the property containing the source id.
   */
  @Input() property = 'source';

  constructor(
    private sourceService: ProtectionSourcesServiceApi,
    private translate: TranslateService,
    private passthroughOptionsService: PassthroughOptionsService,
    filters: FiltersComponent
  ) {
    super(filters);
  }

  /**
   * Fetch protection sources and map to filter values.
   */
  getFilterValues(): Observable<ValueFilterSelection[]> {
    const params = {
      ...this.requestParams,
      ...this.passthroughOptionsService.requestParams,
    };

    return this.sourceService.ListProtectionSourcesRootNodes(params).pipe(
      map(response => response.map(source => {
        const { protectionSource } = source;
        const { environment } = protectionSource;
        const type = protectionSource[SourceKeys[environment]].type;
        const typeKey = sourceTypesDisplayNames[environment][type];
        let label = protectionSource.name;
        if (protectionSource.customName) {
          label = protectionSource.customName;
        }
        return {
          label: label,
          value: protectionSource.id,
          hintText: this.translate.instant(typeKey),
        };
      })
    ));
  }
}
