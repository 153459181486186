import {
  AppModuleConfig,
  StateAccessContext,
  StateAccessMap
} from 'src/app/app-module.config';

const gflagRecipesEnabled = (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.gflagRecipes && ctx.GFLAG_MANAGEMENT;

export const GflagRecipesConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'gflag-recipes.**',
      url: '^/gflag-recipes',
      loadChildren: () => import('./gflag-recipes.module').then(
        m => m.GflagRecipesModule)
    },
  ],
  allClusterMap: {
    'gflag-recipes': {
      heliosOnly: true,
      allClustersState: 'gflag-recipes',
    },
    'gflag-recipes-activity': {
      heliosOnly: true,
      allClustersState: 'gflag-recipes-activity',
    },
    'gflag-recipes-view': {
      heliosOnly: true,
      allClustersState: 'gflag-recipes-view',
    },
    'gflag-recipes-details': {
      heliosOnly: true,
      allClustersState: 'gflag-recipes-details',
    },
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'gflag-recipes': gflagRecipesEnabled,
      'gflag-recipes-activity': gflagRecipesEnabled,
      'gflag-recipes-view': gflagRecipesEnabled,
      'gflag-recipes-details': gflagRecipesEnabled,
    };
  }
};
