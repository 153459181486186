// Service: Group Service Formatter

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C')
    .service('GroupServiceFormatter', GroupServiceFormatterFn);

  function GroupServiceFormatterFn(BUILT_IN_GROUPS) {

    // This Service's API
    return {
      transformGroup: transformGroup,
    };

    /**
     * Transforms a Group data object defaulting values and adding convenience
     * properties and returns the same object.
     *
     * @method     transformGroup
     * @param      {Object}  group    The Group config
     * @return     {Object}  The same object with added and transformed data
     */
    function transformGroup(group) {
      group = group || {};

      group.type = 'group';
      group.objectClass = 'kGroup';
      group.fullName = group.fullName || group.name;

      // Transform non LOCAL group domains to lowercase to remain consistent
      // with user domains
      group.domain = group.domain !== 'LOCAL' ?
        group.domain.toLowerCase() : 'LOCAL';

      group._isBuiltIn = BUILT_IN_GROUPS.includes(group.sid) &&
        group.domain === 'LOCAL';
      group._isDeletable = !group._isBuiltIn;

      group.roles = group.roles || [];

      return group;
    }
  }

})(angular);
