import { ReportColumnConfigs } from '../common-report-items/custom-reports.service';
import { ReportSection } from '../iris-reporting.model';

/**
 * This is the layout config for the usage report.
 */
export const serviceConsumptionReport: ReportSection[] = [
  {
    title: 'reporting.summary',
    items: [
      {
        id: 'statlistItem',
        componentIds: ['501'],
        componentNames: ['DataProtectSummary'],
        params: {
          items: [
            {
              title: 'reporting.currentUsage',
              translate: true,
              measurement: {
                valueKey: 'sumPeakFrontEndSizeBytes',
                dataType: 'bytes',
              },
            },
            {
              title: 'reporting.sources',
              translate: true,
              measurement: {
                valueKey: 'countDistinctSourceUuid',
                dataType: 'number',
              },
            },
            {
              title: 'reporting.protectedObjects',
              translate: true,
              measurement: {
                valueKey: 'countObjectUuid',
                dataType: 'number',
              },
            },
          ],
        },
      },
    ],
  },
  {
    items: [
      {
        id: 'chartItem',
        componentIds: ['502'],
        componentNames: ['UsageByEnvironment'],
        title: 'reporting.currentUsageByObjectType',
        params: {
          chartType: 'pie',
          measurements: [
            {
              valueKey: 'sumPeakFrontEndSizeBytes',
              dataType: 'bytes',
            },
          ],
          dimensions: [
            {
              dimensionKey: 'environment',
            },
          ],
        },
      },
      {
        id: 'chartItem',
        componentIds: ['503'],
        componentNames: ['MonthlyUsageByEnvironment'],
        title: 'reporting.monthlyPeakUsage',
        params: {
          chartType: 'column',
          measurements: [
            {
              valueKey: 'sumPeakFrontEndSizeBytes',
              dataType: 'bytes',
            },
          ],
          dimensions: [
            {
              dataType: 'date',
              dimensionKey: 'runStartTimeUsecs',
            },
          ],
        },
      },
    ],
  },
  {
    items: [
      {
        id: 'tableItem',
        title: 'reporting.top5Consumers',
        componentIds: ['505'],
        componentNames: ['TopConsumers'],
        params: {
          noHeader: true,
          columns: [
            // {
            //   title: '',
            //   key: 'index',
            //   primary: {
            //     key: 'index',
            //     dataType: 'number',
            //   },
            // },
            {
              title: '',
              translate: true,
              key: 'sourceName',
              icon: {
                key: 'objectType',
                dataType: 'objectTypeIcon',
                renderParams: {
                  environmentKey: 'sourceEnvironment',
                },
              },
              primary: {
                key: 'sourceName',
                dataType: 'text',
              },
            },
            {
              title: '',
              key: 'sumPeakFrontEndSizeBytes',
              primary: {
                key: 'sumPeakFrontEndSizeBytes',
                dataType: 'bytes',
              },
            },
          ],
        },
      },
      {
        id: 'tableItem',
        title: 'reporting.top5MoversByMonthlyChangeRate',
        componentIds: ['506'],
        componentNames: ['TopMovers'],
        params: {
          noHeader: true,
          columns: [
            // {
            //   title: '',
            //   key: 'index',
            //   primary: {
            //     key: 'index',
            //     dataType: 'number',
            //   },
            // },
            {
              title: '',
              translate: true,
              key: 'sourceName',
              icon: {
                key: 'objectType',
                dataType: 'objectTypeIcon',
                renderParams: {
                  environmentKey: 'sourceEnvironment',
                },
              },
              primary: {
                key: 'sourceName',
                dataType: 'text',
              },
            },
            {
              title: '',
              key: 'sumAvgDailyChangeRateBytes',
              primary: {
                key: 'sumAvgDailyChangeRateBytes',
                dataType: 'bytesChange',
                renderParams: {
                  absoluteValue: true,
                },
              },
            },
          ],
        },
      },
    ],
  },
  {
    title: 'reporting.data',
    isFullData: true,
    items: [
      {
        id: 'tableItem',
        componentIds: ['500'],
        componentNames: ['AllObjects'],
        params: {
          columns: [
            {
              title: 'objectName',
              translate: true,
              key: 'objectName',
              icon: {
                key: 'objectType',
                dataType: 'objectTypeIcon',
                renderParams: {
                  environmentKey: 'sourceEnvironment',
                },
              },
              primary: {
                key: 'objectName',
                dataType: 'text',
                renderParams: {
                  width: 'lg',
                },
              },
            },
            {
              title: 'objectType',
              translate: true,
              key: 'environment',
              primary: {
                key: 'environment',
                dataType: 'environmentName',
              },
            },
            {
              title: 'source',
              translate: true,
              key: 'sourceName',
              primary: {
                key: 'sourceName',
                dataType: 'text',
              },
            },
            {
              title: 'organization',
              enabled: false,
              translate: true,
              key: 'tenantName',
              primary: {
                key: 'tenantName',
                dataType: 'text',
                renderParams: {
                  emptyKey: 'naNotAvailable',
                },
              },
              featureFlag: 'ngReportSPView',
            },
            {
              title: 'system',
              enabled: true,
              translate: 'true',
              key: 'system',
              primary: {
                key: 'system',
                dataType: 'text',
              },
            },
            {
              title: 'averageUsage',
              translate: true,
              key: 'avgFrontEndSizeBytes',
              primary: {
                key: 'avgFrontEndSizeBytes',
                dataType: 'bytes',
              },
            },
            {
              title: 'peakUsage',
              translate: true,
              key: 'peakFrontEndSizeBytes',
              primary: {
                key: 'peakFrontEndSizeBytes',
                dataType: 'bytes',
              },
            },
            {
              title: 'dailyChangeRate',
              translate: true,
              key: 'avgDailyChangeRateBytes',
              primary: {
                key: 'avgDailyChangeRateBytes',
                dataType: 'bytesChange',
                renderParams: {
                  absoluteValue: true,
                },
              },
            },
          ],
        },
      },
    ],
  },
];

/**
 * This is metadata needed to determine available chart customizations.
 */
export const serviceConsumptionColumns: ReportColumnConfigs = {
  objectName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  objectUuid: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  environment: {
    aggs: ['count'],
    canGroup: true,
    maxGroupingValues: 3,
    dataType: 'text',
  },
  sourceName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  sourceId: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  systemName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  systemId: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  avgFrontEndSizeBytes: {
    aggs: ['sum', 'min', 'max', 'avg'],
    dataType: 'bytes',
  },
  peakFrontEndSizeBytes: {
    aggs: ['sum', 'min', 'max', 'avg'],
    dataType: 'bytes',
  },
  avgDailyChangeRateBytes: {
    aggs: ['sum', 'min', 'max', 'avg'],
    dataType: 'bytes',
  },
  runStartTimeUsecs: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 6,
    dataType: 'date',
  },
};
