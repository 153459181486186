import { NgModule } from '@angular/core';

import { HelixAssetUrlPipe } from './helix-asset-url/helix-asset-url.pipe';

@NgModule({
  imports: [HelixAssetUrlPipe],
  providers: [HelixAssetUrlPipe],
  exports: [HelixAssetUrlPipe]
})
export class HelixAssetsModule {}
