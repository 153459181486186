import { Component, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';

import { BaseProtectionBuilderComponent } from '../../base-protection-builder/base-protection-builder.component';

@Component({
  selector: 'coh-settings-list-parallel-runs',
  templateUrl: './settings-list-parallel-runs.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class SettingsListParallelRunsComponent
  extends BaseProtectionBuilderComponent<boolean, any> {
  /**
   * Default allow-parallel-runs value.
   */
  _value = false;

  /**
   * Override of addFormControl from BaseProtectionBuilderComponent so this component's FormControl isn't required.
   */
  addFormControl() {
    this.formGroup.addControl(this.name, new FormControl(this.value));
  }

  /**
   * Form control init.
   */
  initFormControl() {
    if (this.protectionGroup?.allowParallelRuns) {
      this.value = !!this.protectionGroup.allowParallelRuns;
      this.formControl.setValue(this.value);
    }
  }
}
