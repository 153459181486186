// Component: for adding bandwith limit overrides for external targets

;(function(angular, undefined) {
  'use strict';

  angular.module('C.throttleOverrides', [])
    .component('cThrottleOverrides', {
      bindings: {
        // @type   {Boolean}   [showTrafficOption=false]  Should upload/download
        //                                                button be visible
        showTrafficOption: '@?',
        // @type   {Number}    [minValue=0]               Min Throttle Value
        //
        minValue: '<?',
        // @type   {String}    The unique identifier for form controls.
        //                     (Used for automation)
        uid: '@',
      },
      controller: cThrottleOverridesCtrlFn,
      require: {
        ngModel: 'ngModel',
      },
      templateUrl: 'app/global/c-throttle-overrides/c-throttle-overrides.html',
    });

  /**
   * @ngdoc component
   * @name cThrottleOverrides
   *
   * @description
   * This component provides a means of configuring a throttle overrides for
   * for data transfer during which data transfer is restricted and also
   * to configure the bandwidth (data transfer limit) for upload/download
   *
     @example
      <c-throttle-overrides
        id="toggle-bandwidth-override"
        ng-model="myModel"
        show-traffic-option="true">
      </c-throttle-overrides>
   */

  function cThrottleOverridesCtrlFn(_, $scope, DateTimeService, FILESIZES) {
    var $ctrl = this;

    _.assign($ctrl, {
      $onInit: init,
      addThrottleOverrides: addThrottleOverrides,
      deleteThrottleOverrides: deleteThrottleOverrides,
      isTimeValid: true,
      syncModelValue: syncModelValue,
      trafficOptions: ['upload', 'download'],
      minValue: $ctrl.minValue || 0,
      validateTime: validateTime,
    });

    /**
     * initialize the component with default values
     *
     * @method   init
     */
    function init() {
      $ctrl.throttleData = {
        timezone: undefined,
        newThrottleData: _getDefaultData(),
      };

      // on model change from outside update the internal model
      $ctrl.ngModel.$render = syncModelValue;
    }

    /**
     * returns the default values for throttle overrides
     *
     * @method   _getDefaultData
     * @return   {Object}   The default throttle overrides.
     */
    function _getDefaultData() {
      return {
        timePeriods: {
          days: undefined,
          startTime: {
            hour: 9,
            minute: 0,
          },
          endTime: {
            hour: 17,
            minute: 0,
          },
        },
        bytesPerSecond: 100 * FILESIZES.megabyte,
      };
    }

    /**
     * Sync internal & external model values.
     *
     * @method   _syncModelValue
     * @param    {Object}   newModelValue   The new internal model value to set
     * @param    {Boolean}  isInternal      Is the model changed internally
     */
    function syncModelValue(newModelValue, isInternal) {
      if (isInternal) {
        var newValue = _.assign($ctrl.ngModel.$modelValue, {
          bandwidthLimitOverrides:
            $ctrl.throttleData.bandwidthLimitOverrides,
          timezone: $ctrl.throttleData.timezone,
        });
        $ctrl.ngModel.$setViewValue(newValue);
      } else {
        angular.extend($ctrl.throttleData, $ctrl.ngModel.$modelValue);
      }
    }

    /**
     * Adds a new throttle overrides config to list.
     *
     * @method   addThrottleOverrides
     */
    function addThrottleOverrides() {
      // don't add if new throttle overrides config if it is invalid
      if ($ctrl.throttleData.newThrottleDataForm.$invalid) {
        $ctrl.throttleData.newThrottleDataForm.$setSubmitted();
        return;
      }

      if (!$ctrl.throttleData.bandwidthLimitOverrides) {
        $ctrl.throttleData.bandwidthLimitOverrides = [];
      }

      // if all day checkbox is selected, modify the times
      if ($ctrl.throttleData.newThrottleData.timePeriods.isAllDay) {
        $ctrl.throttleData.newThrottleData.timePeriods.startTime = {
          hour: 0,
          minute: 0,
        };
        $ctrl.throttleData.newThrottleData.timePeriods.endTime = {
          hour: 23,
          minute: 59,
        };
      }

      $ctrl.throttleData.bandwidthLimitOverrides.push(
        angular.copy($ctrl.throttleData.newThrottleData)
      );

      $ctrl.throttleData.newThrottleData = _getDefaultData();
      $ctrl.throttleData.newThrottleDataForm.$setPristine();

      $ctrl.syncModelValue(null, true);
    }

    /**
     * Deletes a throttle overrides setting from the list.
     *
     * @method   deleteThrottleOverrides
     * @param    {number}   indexToRemove   The index of throttle overrides
     *                                      config to remove
     */
    function deleteThrottleOverrides(indexToRemove) {
      $ctrl.throttleData.bandwidthLimitOverrides.splice(
        indexToRemove,
        1
      );

      $ctrl.syncModelValue(null, true);
    }

    /**
     * Update isTimeValid flag everytime a change happens.
     *
     * @method   validateTime
     */
    function validateTime() {
      var startTime = _.get($ctrl.throttleData,
        'newThrottleData.timePeriods.startTime');
      var endTime = _.get($ctrl.throttleData,
        'newThrottleData.timePeriods.endTime');

      if (startTime && endTime) {

        startTime = DateTimeService.objectTimeToMsecs(startTime);
        endTime = DateTimeService.objectTimeToMsecs(endTime);

        if (startTime > endTime) {

          $ctrl.isTimeValid = undefined;
          return;
        }
        $ctrl.isTimeValid = true;
      }
    }
  }
}(angular));
