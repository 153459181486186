<div class="flex-row" *ngIf="!arrowNavigation">
  <cog-menu
    iconName="query_builder"
    [(value)]="value"
    [options]="selectorOptions">
  </cog-menu>
</div>
<!-- A littel different structure when arrowNavigation is true -->
<div class="flex-row flex-wrap@xs" *ngIf="arrowNavigation">
  <button mat-stroked-button
    cogDataId="shift-time-backward"
    [inheritDataId]="true"
    class="margin-right-sm margin-bottom-sm"
    color="primary"
    (click)="shiftTime.emit(false)">
    <cog-icon shape="keyboard_arrow_left"></cog-icon>
  </button>

  <button mat-stroked-button
    cogDataId="shift-time-forward"
    [inheritDataId]="true"
    class="margin-right-sm margin-bottom-sm"
    color="primary"
    (click)="shiftTime.emit(true)">
    <cog-icon shape="keyboard_arrow_right"></cog-icon>
  </button>

  <button mat-stroked-button
    cogDataId="change-time-range"
    [inheritDataId]="true"
    class="margin-bottom-sm"
    color="primary"
    [matMenuTriggerFor]="menu">
    {{ selectedOption.label }}
    <cog-icon shape="arrow_drop_down"></cog-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button *ngFor="let option of selectorOptions"
      mat-menu-item
      cogDataId="option-{{option.value}}"
      [inheritDataId]="true"
      (click)="value = option.value">
      {{ option.label | translate }}
    </button>
  </mat-menu>
</div>
