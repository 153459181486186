import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { RbacModule } from '@cohesity/data-govern/shared';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { IrisContextModule, IrisCoreModule } from '@cohesity/iris-core';
import { UtilPipesModule } from '@cohesity/utils';
import { TranslateModule } from '@ngx-translate/core';

import {
  ActionMenuComponent,
  AllIssuesComponent,
  ClustersComponent,
  IssuesComponent,
  IssuesDialogComponent,
  ListIssuesDialogComponent,
  ListIssuesTableComponent,
  PerformScanDialogComponent,
  PostureAdvisorIconModule,
  RuleDetailsDefinitionComponent,
  ScoresListTableComponent,
  SecurityCriteriaListComponent,
  SecurityCriteriaSelectComponent,
  SecurityCriteriaSelectListComponent,
} from './components';
import {
  PostureAdvisorPageComponent,
  RuleDetailsPageComponent,
  ScanResultDetailPageComponent,
  ScanResultsPageComponent,
  ScoresDrillDownPageComponent,
  ScoresPageComponent,
  SecurityRulesModifyPageComponent,
  SecurityRulesPageComponent,
} from './containers';

const components = [
  ActionMenuComponent,
  AllIssuesComponent,
  ClustersComponent,
  IssuesComponent,
  IssuesDialogComponent,
  ListIssuesDialogComponent,
  ListIssuesTableComponent,
  PerformScanDialogComponent,
  RuleDetailsDefinitionComponent,
  RuleDetailsPageComponent,
  PostureAdvisorPageComponent,
  ScanResultDetailPageComponent,
  ScanResultsPageComponent,
  ScoresDrillDownPageComponent,
  ScoresListTableComponent,
  ScoresPageComponent,
  SecurityCriteriaListComponent,
  SecurityCriteriaSelectComponent,
  SecurityCriteriaSelectListComponent,
  SecurityRulesModifyPageComponent,
  SecurityRulesPageComponent,
];

@NgModule({
  declarations: components,
  imports: [
    CohesityHelixModule,
    CommonModule,
    IrisContextModule,
    IrisCoreModule,
    MatCheckboxModule,
    MatExpansionModule,
    PostureAdvisorIconModule,
    RbacModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    TranslateModule,
    UtilPipesModule,
  ],
  exports: components,
})
export class PostureAdvisorModule {}
