import { Component } from '@angular/core';
import { AjsUpgradeService } from 'src/app/core/services/ajs-upgrade.service';

import { BaseProtectionBuilderComponent } from '../../../../protection-builder/components/base-protection-builder/base-protection-builder.component';

/**
 * Default value for cloud migration.
 */
export const DefaultCloudMigration = false;

@Component({
  selector: 'coh-settings-list-cloud-migration',
  templateUrl: './settings-list-cloud-migration.component.html',
})
export class SettingsListCloudMigrationComponent
  extends BaseProtectionBuilderComponent<boolean, any> {
  /**
   * Default cloud migration value.
   */
  _value = DefaultCloudMigration;

  /**
   * Legacy ajs source service. Used to show the download agent modal.
   */
  ajsSourceService: any;

  constructor(ajsUpgrade: AjsUpgradeService) {
    super();
    this.ajsSourceService = ajsUpgrade.get('SourceService');
  }

  /**
   * Open Download Agents Modals when "Download" anchor in the Cloud Migration
   * description is clicked.
   *
   * @param   event   The click event.
   */
  openDownloadAgentsModal(event: any) {
    if (event.target.nodeName === 'A') {
      // Only open the modal if the anchor tag was clicked.
      this.ajsSourceService.downloadAgentsModal();
    }
  }

  /**
   * Form control init.
   */
  initFormControl() {
    if (this.protectionGroup?.id) {
      this.value = Boolean(this.protectionGroup.cloudMigration);
      this.formControl.setValue(this.value);
    }
  }
}
