// Controller: Helios Custom Report Fields

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.heliosReports')
    .controller('ReportFieldsCtrl', ReportFieldsCtrlFn)
    .component('reportFields', {
      // Attribute bindings
      bindings: {
        /**
         * Usage type for report-fields it may be floor or ceil.
         *
         * @type   {String}
         */
        type: '@',

        /**
         * fields to be shown available options are
         * [
         *    'clusters', 'sources', 'viewBoxes', 'tenants', 'jobSearch',
         *    'dateField', 'runStatus', 'objectTypes', 'varianceOverDays'
         * ]
         *
         * @type   {Array}
         */
        fields: '<',

        /**
         * name of the field that should be used when 'dateField' is found in fields
         * dateRange || reportTimeSpan
         *
         * @type   {String}
         */
        dateFieldType: '<?',

        /**
         * Optional. If present then provided fields all choices will be
         * selected initially by default.
         *
         * applicable only for multi-select dropdown fields only such as
         * [
         *    'clusters', 'sources', 'viewBoxes', 'tenants',
         * ]
         *
         * @type   {Array}
         */
        defaultSelectAllFields: '<?',

        /**
         * Selected filter values.
         *
         * @type   {Object}
         */
        filter: '=',
      },
      controller: 'ReportFieldsCtrl',
      templateUrl: 'app/reports/helios/report-fields.html',
    });

  function ReportFieldsCtrlFn(_, McmJobService, HeliosReportsServiceFormatter) {
    var $ctrl = this;

    _.assign($ctrl, {
      jobsList: [],
      isLoadingJobs: false,
      enableSelectAllCheckbox: true,
      jobRunStatusList: HeliosReportsServiceFormatter.getJobRunStatus(),
      objectTypesList: HeliosReportsServiceFormatter.getObjectTypes(),
      taskTypesList: HeliosReportsServiceFormatter.getTaskTypes(),
      fetchTypeList: HeliosReportsServiceFormatter.getFetchType(),
      timespanTypeList: HeliosReportsServiceFormatter.getTimespanType(),

      $onInit: $onInit,
      getProtectionJobs: getProtectionJobs,
      selectAllObjectType: selectAllObjectType,
      selectAllTaskType: selectAllTaskType,
      selectAllRunStatus: selectAllRunStatus,
      onObjectTypesChange: onObjectTypesChange,
      onTaskTypesChange: onTaskTypesChange,
      onRunStatusChange: onRunStatusChange
    });

    /**
     * Initialize the component.
     *
     * @method   $onInit
     */
    function $onInit() {
      $ctrl.fields = $ctrl.fields || [];
      $ctrl.dateFieldType = $ctrl.dateFieldType || 'dateRange';
      $ctrl.defaultSelectAllFields = $ctrl.defaultSelectAllFields || [];
      $ctrl.today = new Date(Date.clusterNow());

      // by default select 1st job run status and object type.
      if ($ctrl.defaultSelectAllFields.indexOf('runStatus') > -1) {
        selectAllRunStatus(true);
      }
      if ($ctrl.defaultSelectAllFields.indexOf('objectTypes') > -1) {
        selectAllObjectType(true);
      }
      if ($ctrl.defaultSelectAllFields.indexOf('taskTypes') > -1) {
        selectAllTaskType(true);
      }
    }

    /**
     * Get the list of matching protection job for provided job name.
     *
     * @method   getProtectionJobs
     * @param    {String}   searchString    The search query.
     * @returns  {Object}   Promise resolved with matching protection job list
     *                      else rejected with error.
     */
    function getProtectionJobs(searchString) {
      $ctrl.isLoadingJobs = true;
      return McmJobService.getJobs({searchString: searchString}).then(
        function gotJobs(jobs) {
          $ctrl.jobsList = jobs;
        }).finally(function finallyGotJobs() {
          $ctrl.isLoadingJobs = false;
        });
    }

    /**
     * Select all objects types.
     *
     * @method   selectAllObjectType
     */
    function selectAllObjectType(
      status = !$ctrl.filter.isAllObjectTypesSelected) {
      $ctrl.filter.isAllObjectTypesSelected = status;
      $ctrl.filter.objectTypes = status ? $ctrl.objectTypesList : [];
    }

    /**
     * Select all task types.
     *
     * @method   selectAllTaskType
     */
    function selectAllTaskType(
      status = !$ctrl.filter.isAllTaskTypesSelected) {
      $ctrl.filter.isAllTaskTypesSelected = status;
      $ctrl.filter.taskTypes = status ? $ctrl.taskTypesList : [];
    }

    /**
     * Select all job runs status.
     *
     * @method   selectAllRunStatus
     */
    function selectAllRunStatus(
      status = !$ctrl.filter.isAllRunStatusSelected) {
      $ctrl.filter.isAllRunStatusSelected = status;
      $ctrl.filter.runStatus = status ? $ctrl.jobRunStatusList : [];
    }

    /**
     * handler to be executed on change of objectTypes filter
     * @method    onObjectTypesChange
     */
    function onObjectTypesChange() {
      $ctrl.filter.isAllObjectTypesSelected =
        $ctrl.filter.objectTypes.length === $ctrl.objectTypesList.length;
    }

    /**
     * handler to be executed on change of taskTypes filter
     * @method    onTaskTypesChange
     */
    function onTaskTypesChange() {
      $ctrl.filter.isAllTaskTypesSelected =
        $ctrl.filter.taskTypes.length === $ctrl.taskTypesList.length;
    }

    /**
     * handler to be executed on change of runStatus filter
     * @method    onRunStatusChange
     */
    function onRunStatusChange() {
      $ctrl.filter.isAllRunStatusSelected =
        $ctrl.filter.runStatus.length === $ctrl.jobRunStatusList.length;
    }
  }
})(angular);