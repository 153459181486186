//Controller: Storage by Jobs

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.reports')
    .controller('reportsStorageJobsController', reportsStorageJobsControllerFn);

  function reportsStorageJobsControllerFn($scope, $state, $stateParams, $filter,
    $q, ReportsService, evalAJAX, cUtils, CHART_COLORS, ViewBoxCacheService,
    ReportsUtil, cReportsControlsUtil) {

    $scope.storageJobsConfig =
      ReportsUtil.getReportConfig('kStorageConsumedByBackupReport')();

    $scope.getData = getData;
    $scope.downloadReport = downloadReport;
    $scope.viewBoxHash = ViewBoxCacheService.viewBoxes;

    $scope.dataReady = false;
    $scope.jobs = [];
    $scope.nDaysLabel = 7;

    /**
     * init function for initial report and listeners setup
     */
    function init() {
      var defaultFilters;

      defaultFilters = cReportsControlsUtil.getDefaultFilters({
        lastNDays: 7,
        viewBox: undefined,
        registeredSourceIds: [],
      });

      getData(defaultFilters);
    }

    /**
     * builds a params object based on current filter settings
     *
     * @param      {object}    filters    filters from c-reports-controls
     *
     * @return {Object} params object for API submission
     */
     function getParams(filters) {
      var params = {
        msecsBeforeEndTime: filters.lastNDays * 86400000,
        sourceIds: filters.registeredSourceIds || [],
      };

      if (filters.viewBox && filters.viewBox.viewBoxId) {
        params.viewBoxIds = [filters.viewBox.viewBoxId];
      }

      return ReportsService.extendWithTenantParams(params);
    }

    /**
     * requests a csv download via ReportsService
     *
     * @return {Void}
     */
     function downloadReport() {
      var params = getParams(cReportsControlsUtil.filters);
      params.outputFormat = 'csv';

      ReportsService.getStorageByJobs(params);
    }

    /**
     * gets and parses the report data from the API
     *
     * @param      {object}    filters    filters from c-reports-controls
     *
     * @return {Void}
     */
    function getData(filters) {
      $scope.jobs = [];
      $scope.dataReady = false;
      $scope.jobByLogicalChart.series[0].data = [];

      ReportsService.getStorageByJobs(getParams(cReportsControlsUtil.filters))
        .then(function getStorageByJobsSuccess(r) {
          $scope.jobs = r.data.map(function(job, index) {
            if (job.logicalSizeBytesVariancePct !== undefined && job.logicalSizeBytesVariancePct !== null) {
              job._logicalVariance = cUtils.round(job.logicalSizeBytesVariancePct, 2) + '%';
            }
            if (job.storageConsumedBytesPrev) {
              var storageGrowthRate = ((job.storageConsumedBytes || 0) - job.storageConsumedBytesPrev)
                * 100 / job.storageConsumedBytesPrev;
              job.dailyStorageGrowthRate = cUtils.round(storageGrowthRate / cReportsControlsUtil.filters.lastNDays, 2) + '%';
              job.storageGrowthRate = cUtils.round(storageGrowthRate, 2) + '%';
            }

            return job;
          });

          parseDataForCharts($scope.jobs);
        }, evalAJAX.errorMessage)
      .finally(function getStorageByJobsFinally() {
        $scope.jobByLogicalChart.loading = false;
        $scope.dataReady = true;
        $scope.nDaysLabel = cReportsControlsUtil.filters.lastNDays;
      });
    }


    /**
     * generates chart data based on jobs provided from API
     *
     * @param  {Array} jobs returned based on filter params
     * @return {Void}
     */
     function parseDataForCharts(jobs) {

      var i = 0;
      var numJobs = jobs.length || 0;
      var maxChartObjects = 10;
      var logicalDataArray = [];


      if (!numJobs) {
        // nothing to process
        return;
      }

      // Loop through all Jobs
      for (i; i < numJobs; i++) {
        var logicalWritten = (jobs[i].storageConsumedBytes ? jobs[i].storageConsumedBytes : null);
        if (logicalWritten !== null) {
          logicalDataArray.push({
            name: jobs[i].backupJobSummary.jobDescription.name,
            value: logicalWritten
          });
        }
      }

      // sort data arrays in descending order and limit to 10
      logicalDataArray = $filter('orderBy')(logicalDataArray, '-value');
      logicalDataArray = $filter('limitTo')(logicalDataArray, maxChartObjects);

      logicalDataArray = logicalDataArray.map(function mapLogicalData(job) {
        return [job.name, job.value];
      });

      // Add data to the charts
      $scope.jobByLogicalChart.series[0].data = logicalDataArray;
    }


    $scope.jobByLogicalChart = {
      chartType: 'storageDonut',
      loading: true,
      colors: CHART_COLORS.tenColorPalette(),
      series: [{
        type: 'pie',
        name: 'jobs',
        data: []
      }],
      chart: {
        height: 250
      },
      title: {
        text: null
      },
      legend: {
        y: 0,
        itemMarginBottom: 8
      }
    };

    $scope.getReductionString = function getReductionString(value) {
      return cUtils.round(value, 2) + 'x';
    };

      // run setup function on load
    init();
  }
})(angular);
