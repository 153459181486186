import { ChangeDetectionStrategy, Component, OnChanges } from '@angular/core';
import { BubbleOptionsType, HighchartsDataConfigService } from '@cohesity/helix';
import { Options } from 'highcharts';

import { CustomChartInput } from '../../../iris-reporting.model';
import { ChartReportsService } from '../../chart-reports.service';
import { BaseReportItemRendererComponent } from '../base-report-item-renderer.component';

/**
 * A bubble chart report item.
 */
@Component({
  selector: 'iris-rpt-bubble-chart-item',
  templateUrl: './bubble-chart-item.component.html',
  styleUrls: ['./bubble-chart-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BubbleChartItemComponent extends BaseReportItemRendererComponent implements OnChanges, CustomChartInput {
  /**
   * The chart's series, transformed from the input.
   */
  bubbleChartSeries: BubbleOptionsType[] = [];

  /**
   * Values to use for the legend. The index is used to map to the correct highcharts
   * color, and the datatype is passed to a data renderer to render the data type correctly.
   * The values are split into two separate arrays to be displayed as columns
   */
  legendValues: {
    name: string;
    value: number;
    index: number;
    dataType: string;
  }[][] = [];

  /**
   * Custom options for the bubble chart.
   */
  options: Options = {
    plotOptions: {
      packedbubble: {
        layoutAlgorithm: {
          splitSeries: false,
          gravitationalConstant: 0.02,
        },
        dataLabels: {
          enabled: false,
        },
        ...this.chartPlotOptions,
      },
    } as any,
  };

  /**
   * Given a chart config, determine whether this chart can be used to render it or now.
   *
   * @param input The chart input
   * @returns True if this component can render it.
   */
  static canRender(input: CustomChartInput): boolean {
    return input.dimensions?.length === 1 && input.measurements?.length === 1;
  }

  constructor(private chartService: ChartReportsService, private highchartConfigService: HighchartsDataConfigService) {
    super();
  }

  ngOnChanges() {
    const {seriesData, xValues, originalXValues} =
      this.chartService.getChartData(this.data, this.measurements, this.dimensions);
    this.options.plotOptions.packedbubble.animation = {
      duration: this.isPrintMedia ? 200 : 1000,
    };

    const dataConfig = this.highchartConfigService.getConfigForDataType(this.measurements[0].dataType);
    this.options.tooltip = {
      pointFormatter: dataConfig.tooltipPointFormatter,
    };

    // This forces the chart to redraw with the updated options
    this.options = { ...this.options };

    this.bubbleChartSeries = [];
    [...seriesData.entries()].forEach(([metricName, seriesValue]) => {
      seriesValue.data.forEach((value, index) => {
        this.bubbleChartSeries.push({
          type: 'packedbubble',
          minSize: '30%',
          maxSize: '150%',
          zMin: 0,
          zMax: 1000,
          name: xValues && this.translate(xValues[index] as string),
          data: [
            {
              name: this.translate(metricName),
              value: value,
              dataType: seriesValue.dataType,
              custom: this.getCustomData(seriesValue?.originalKey, originalXValues[index]),
            },
          ],
        } as any);
      });
    });

    const allValues: BubbleChartItemComponent['legendValues'][number] = [];
    this.bubbleChartSeries.forEach((series, index) => {
      const data: any = series.data && (series.data as any[])[0];
      allValues.push({
        name: series.name || '',
        value: data?.value || '',
        dataType: data?.dataType || '',
        index,
      });
    });
    this.legendValues = [[...allValues.slice(0, allValues.length / 2)], [...allValues.slice(allValues.length / 2)]];
  }
}
