// COMPONENT: Recover DB: DBs in Snapshot Modal

;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.dbRestore';

  angular
    .module(moduleName)
    .controller('dbSnapshotDBsModalController',
      dbSnapshotDBsModalControllerFn);

  function dbSnapshotDBsModalControllerFn($rootScope, $scope, sqlHost) {
    angular.extend($scope, {
      // GENERAL SCOPE VARS
      dbs: sqlHost._versions[sqlHost._snapshotIndex].sqlEntities || [],

      // TEXT STRINGS
      text: $rootScope.text.protectionRecoverySqlSqlModalSnapshotDbsText,
    });
  }

})(angular);
