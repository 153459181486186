import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DmaasRegion } from '@cohesity/api/dms';
import { RpaasRegionInfo } from '@cohesity/api/v2';
import { FortknoxStorageClass, RegionEncryptionType } from '@cohesity/data-govern/security-center';
import {
  awsColdStorageEntitlement,
  awsWarmStorageEntitlement,
  azureColdStorageEntitlement,
  azureHotStorageEntitlement,
  flagEnabled,
  IrisContextService,
} from '@cohesity/iris-core';
import { forEach, groupBy } from 'lodash';
import { ObservableInput } from 'ngx-observable-input';
import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { GroupRegionByAvailability } from 'src/app/modules/dms-shared';
import { AutoDestroyable, CloudProvider, TypedFormGroup } from 'src/app/shared';

/**
 * Region Interface to hold the configuration for DMaaS.
 */
export interface AddCloudVaultFormModel {
  /**
   * The selected region to be configured.
   */
  regionId: string;

  /**
   * The encryption type that is supported.
   */
  encryption: RegionEncryptionType;

  /**
   * The encryption key if the type is AWS.
   */
  encryptionKey?: string;

  /**
   * The encryption JSON returned by AWS.
   */
  encryptionJSON?: string;

  /**
   * Holds whether row needs to be focused or not.
   */
  isFocused?: boolean;

  /**
   * Vault name input.
   */
  vaultName: string;

  /**
   * Vault storage class selector.
   */
  storageClass: FortknoxStorageClass;
}

/**
 * Group regions list by cloud type. See example.
 *
 * @example
 *    AWS
 *     Asia Pacific
 *     Europe
 *     US West
 *    Azure
 *     Central
 */
export type RegionByCloudType = { [cloudType in CloudProvider]?: DmaasRegion[] };

@Component({
  selector: 'coh-add-cloud-vault',
  templateUrl: './add-cloud-vault.component.html',
  styleUrls: ['./add-cloud-vault.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddCloudVaultComponent extends AutoDestroyable implements OnInit {
  /**
   * All available vaults.
   */
  @Input() provisionedVaults: RpaasRegionInfo[];

  /**
   * Other regions currently being edited and not saved yet.
   */
  @Input() pendingRegions: TypedFormGroup<AddCloudVaultFormModel>[];

  /**
   * All available regions
   */
  @Input() allRegions: DmaasRegion[];

  /**
   * Holds the configured region for DMaaS.
   */
  @Input() region: TypedFormGroup<AddCloudVaultFormModel>;

  /**
   * Should we hide the remove configuration.
   */
  @Input() hideDelete = false;

  /**
   * If this is false, do not allow the user to edit encryption settings.
   */
  @Input() isEncryptionEditEnabled = true;

  /**
   * Holds whether we need to focus the form or not.
   */
  @Input() focused = false;

  /**
   * The list of regions available to be configured.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('regionList') regionList$: Observable<GroupRegionByAvailability>;

  /**
   * Outputs an event when a new configuration needs to be added.
   */
  @Output() readonly addItem = new EventEmitter();

  /**
   * Outputs an event when the current configuration needs to be removed.
   */
  @Output() readonly removeItem = new EventEmitter<AddCloudVaultFormModel>();

  /**
   * Outputs an event when the current configuration needs to be removed.
   */
  @Output() readonly editItem = new EventEmitter<AddCloudVaultFormModel>();

  /**
   * Outputs an event when the user needs to generate the encryption JSON key.
   */
  @Output() readonly getEncryptionJSON = new EventEmitter<TypedFormGroup<AddCloudVaultFormModel>>();

  /**
   * The list of supported encryption type.
   */
  encryptionTypes$: Observable<RegionEncryptionType[]>;

  /**
   * Check rpaasMultiVaultRegions feature flag to display vault name and allow provisioned regions to be selected.
   */
  readonly multiVaultRegion = flagEnabled(this.irisContext.irisContext, 'rpaasMultiVaultRegions');

  /**
   * Vault storage class options.
   */
  storageClassOptions$: Observable<FortknoxStorageClass[]>;

  /**
   * Show storage class feature.
   */
  readonly storageClassEnabled = flagEnabled(this.irisContext.irisContext, 'rpaasStorageClass');

  /**
   * Use vaults provision API.
   */
  readonly vaultsProvision = flagEnabled(this.irisContext.irisContext, 'rpaasVaultsProvisionApi');

  /**
   * Customer KMS JSON key control.
   */
  encryptionJsonControl: AbstractControl;

  /**
   * Vault name input control.
   */
  vaultNameControl: AbstractControl;

  /**
   * Grouped regions by cloud provider type.
   */
  regionsByCloudType$: Observable<RegionByCloudType>;

  /**
   * constructor
   */
  constructor(private irisContext: IrisContextService) {
    super();
  }

  ngOnInit() {
    const regionIdChanges$ = this.region?.get('regionId').valueChanges;

    this.encryptionJsonControl = this.region.get('encryptionJSON');

    if (this.multiVaultRegion) {
      this.vaultNameControl = this.region?.get('vaultName');
      this.vaultNameControl?.setValidators([
        Validators.required,
        this.validateVaultName()
      ]);

      this.regionsByCloudType$ = this.regionList$.pipe(
        filter(regionList => regionList?.availableRegions?.length > 0 || regionList?.unavailableRegions?.length > 0),
        map(regionList => {
          let regions: DmaasRegion[] = [];

          if (regionList.availableRegions?.length > 0) {
            regions = [...regionList.availableRegions];
          }

          // event if region is already claimed, it's still available for multivault setup
          if (regionList.unavailableRegions?.length > 0) {
            regions = [...regions, ...regionList.unavailableRegions];
          }


          return groupBy(regions, 'type');
        }),
        shareReplay(1)
      );
    }

    if (this.storageClassEnabled) {
      const storageClassControl = this.region?.get('storageClass');
      storageClassControl?.setValidators(Validators.required);

      const { irisContext } = this.irisContext;
      const awsColdActive = awsColdStorageEntitlement(irisContext)?.isActive;
      const awsWarmActive = awsWarmStorageEntitlement(irisContext)?.isActive;
      const azureColdActive = azureColdStorageEntitlement(irisContext)?.isActive;
      const azureHotActive = azureHotStorageEntitlement(irisContext)?.isActive;

      this.storageClassOptions$ = regionIdChanges$.pipe(
        this.untilDestroy(),
        map(region => {
          storageClassControl?.setValue('');

          const storageClassOptions: FortknoxStorageClass[] = [];
          const regionById = this.allRegions.find(r => r.id === region);

          if (regionById?.type === 'Aws' && awsWarmActive) {
            storageClassOptions.push(this.vaultsProvision ? 'AmazonS3StandardIA' : 'kAmazonS3StandardIA');
          } else if (regionById?.type === 'Azure' && azureHotActive) {
            storageClassOptions.push('AzureCoolBlob');
          }

          if (regionById?.type === 'Aws' && awsColdActive) {
            storageClassOptions.push(this.vaultsProvision ? 'AmazonS3Glacier' : 'kAmazonS3Glacier');
          } else if (regionById?.type === 'Azure' && azureColdActive) {
            storageClassOptions.push('AzureArchiveBlob');
          }
          return storageClassOptions;
        }),
        shareReplay(1)
      );
    }

    this.encryptionTypes$ = regionIdChanges$.pipe(
      map(region => {
        this.encryptionJsonControl.setValue('');

        const encryptionTypes: RegionEncryptionType[] = ['CohesityManaged'];
        const regionById = this.allRegions.find(r => r.id === region);

        if (regionById?.type === 'Aws') {
          encryptionTypes.push('CustomerManaged');
        }

        return encryptionTypes;
      })
    );
  }

  /**
   * Validates vault name control for duplicate names.
   */
  private validateVaultName(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      const vaultName = control.value.trim();
      const hasDuplicate = this.provisionedVaults?.some(vault => vault.vaultName === vaultName) ||
        this.pendingRegions?.filter(region => region !== this.region)
          .some(region => region.value?.vaultName === vaultName);

      if (hasDuplicate) {
        return {
          duplicateName: true
        };
      }

      return null;
    };
  }

  /**
   * Emits an event to add new region configuration.
   */
  addRegion() {
    if (this.region.invalid) {
      forEach(this.region.controls, control => control.markAsTouched());
      return;
    }

    this.addItem.emit();
  }

  /**
   * Emits an event to remove the current configuration.
   */
  removeRegion() {
    this.removeItem.emit(this.region.value);
  }

  /**
   * Emits an event to edit the current configuration.
   */
  editRegion() {
    this.editItem.emit(this.region.value);
  }

  /**
   * Updates the validity of the form inputs based on the encryption key.
   *
   * @param  type  The type of encryption key for the region.
   */
  setValidators(type: string) {
    const encryptionKeyControl = this.region.get('encryptionKey');

    if (type === 'CustomerManaged') {
      encryptionKeyControl.setValidators(Validators.required);
      this.encryptionJsonControl.setValidators(Validators.required);
    } else {
      encryptionKeyControl.clearValidators();
      this.encryptionJsonControl.clearValidators();
    }

    encryptionKeyControl.updateValueAndValidity();
    this.encryptionJsonControl.updateValueAndValidity();
  }

  /**
   * Creates a association between region and ARN Key.
   */
  getJSON() {
    const { encryptionKey, regionId } = this.region.value;
    if (!regionId || !encryptionKey) {
      this.region.controls['regionId'].markAsTouched();
      this.region.controls['encryptionKey'].markAsTouched();
      return;
    }

    this.getEncryptionJSON.emit(this.region);
  }

  /**
   * ARN Key is changed and need to clear the encryption JSON
   */
  encryptionKeyChange() {
    this.region.get('encryptionJSON').setValue('');
  }
}
