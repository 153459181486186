<h2 mat-dialog-title>{{ (isEditMode ? 'editNetgroupWhitelist' : 'addNetgroupWhitelist') | translate }}</h2>

<form cogDataId="edit-netgroup-whitelist-form"
  [formGroup]="formGroup"
  (ngSubmit)="modifyNetgroupWhitelist(isEditMode)">

    <mat-dialog-content class="margin-bottom">
      <div class="row">
        <!-- netgroup name field -->
        <mat-form-field class="col-xs-8">
          <input cogDataId="netgroup-name-input"
            matInput
            placeholder="{{'netgroupName' | translate}}"
            [formControlName]="formControlNames.name">
          <mat-error cogDataId="netgroup-name-invalid" *ngIf="formGroupControls.name.errors?.required">
            {{'errors.required' | translate}}
          </mat-error>
        </mat-form-field>

        <!-- nis provider field -->
        <coh-nis-provider-selector
          class="col-xs-8"
          [control]="formGroupControls.domain"
          [allNisProviders]="allNisProviders$ | async"
          [showBackArrow]="true"
          [editDisabled]="isEditMode"
          (nisProviderAdded)="getNisList()"
          (childDialogOpenStatus)="toggleDialogVisibility($event)">
        </coh-nis-provider-selector>

        <!-- NFS permisstion field -->
        <div class="col-xs-12 margin-top">
          <mat-label class="col-xs-3 margin-right pull-left">{{'nfsPermissions' | translate}}</mat-label>
          <mat-radio-group
            cogDataId="nfs-permission-select"
            [formControlName]="formControlNames.nfsAccess">
            <mat-radio-button cogDataId="nfs-permission-kReadWrite"
              class="col-xs-3" value="kReadWrite">{{'kReadWrite' | translate}}</mat-radio-button>
            <mat-radio-button cogDataId="nfs-permission-kReadOnly"
              class="col-xs-3" value="kReadOnly">{{'kReadOnly' | translate}}</mat-radio-button>
            <mat-radio-button cogDataId="nfs-permission-kDisabled"
              class="col-xs-2" value="kDisabled">{{'kDisabled' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>

        <!-- NFS squash field -->
        <div class="col-xs-12 margin-top">
          <mat-label class="col-xs-3 margin-right pull-left">{{'nfsSquash' | translate}}</mat-label>
          <mat-radio-group
            cogDataId="nfs-squash-select"
            [formControlName]="formControlNames.nfsSquash">
            <mat-radio-button cogDataId="nfs-squash-kNone"
              class="col-xs-3" value="kNone">{{'kNone' | translate}}</mat-radio-button>
            <mat-radio-button cogDataId="nfs-squash-kRootSquash"
              class="col-xs-3" value="kRootSquash">{{'kRootSquash' | translate}}</mat-radio-button>
            <mat-radio-button cogDataId="nfs-squash-kAllSquash"
              class="col-xs-2" value="kAllSquash">{{'kAllSquash' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions class="margin-top" align="end">
      <button mat-button
        cogCancelButton
        mat-dialog-close
        cogDataId="cancel-netgroup-whitelist-btn"
        type="button"
        class="margin-right"
        [disabled]="submiting">
        {{ 'cancel' | translate }}
      </button>
      <button mat-flat-button
        cogSubmitButton
        cogDataId="{{ (isEditMode ? 'save' : 'add') }}-netgroup-whitelist-btn"
        type="submit"
        color="primary"
        [loading]="submiting"
        [disabled]="submiting || !formGroup.valid">
        {{ (isEditMode ? 'save' : 'add') | translate }}
      </button>
    </mat-dialog-actions>
</form>
