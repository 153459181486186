import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const apolloSupportabilityAccess = (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.apolloSupportabilityPage;

export const ApolloSupportabilityConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'apollo-supportability.**',
      url: '/apollo-supportability',
      loadChildren: () => import('./apollo-supportability.module').then(m => m.ApolloSupportabilityModule),
    },
  ],
  allClusterMap: {
    'apollo-supportability': false,
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'apollo-supportability': apolloSupportabilityAccess,
    };
  },
};
