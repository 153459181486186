/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ClusterPartition } from '../models/cluster-partition';
@Injectable({
  providedIn: 'root',
})
class ClusterPartitionsService extends __BaseService {
  static readonly GetClusterPartitionsPath = '/public/clusterPartitions';
  static readonly GetClusterPartitionByIdPath = '/public/clusterPartitions/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List Cluster Partitions filtered by the specified parameters.
   *
   * If no parameters are specified, all Cluster Partitions currently on
   * the Cohesity Cluster are returned.
   * Specifying parameters filters the results that are returned.
   * @param params The `ClusterPartitionsService.GetClusterPartitionsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `names`: Array of Cluster Partition Names.
   *
   *   Filter by a list of Cluster Partition Names. If empty, the Cluster
   *   Partitions are not filtered by names.
   *
   * - `ids`: Array of Cluster Partition Ids.
   *
   *   Filter by a list of Cluster Partition ids. If empty, the Cluster Partitions
   *   are not filtered by id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetClusterPartitionsResponse(params: ClusterPartitionsService.GetClusterPartitionsParams): __Observable<__StrictHttpResponse<Array<ClusterPartition>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.names || []).forEach(val => {if (val != null) __params = __params.append('names', val.toString())});
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/clusterPartitions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ClusterPartition>>;
      })
    );
  }
  /**
   * List Cluster Partitions filtered by the specified parameters.
   *
   * If no parameters are specified, all Cluster Partitions currently on
   * the Cohesity Cluster are returned.
   * Specifying parameters filters the results that are returned.
   * @param params The `ClusterPartitionsService.GetClusterPartitionsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `names`: Array of Cluster Partition Names.
   *
   *   Filter by a list of Cluster Partition Names. If empty, the Cluster
   *   Partitions are not filtered by names.
   *
   * - `ids`: Array of Cluster Partition Ids.
   *
   *   Filter by a list of Cluster Partition ids. If empty, the Cluster Partitions
   *   are not filtered by id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetClusterPartitions(params: ClusterPartitionsService.GetClusterPartitionsParams): __Observable<Array<ClusterPartition>> {
    return this.GetClusterPartitionsResponse(params).pipe(
      __map(_r => _r.body as Array<ClusterPartition>)
    );
  }

  /**
   * List details about a single Cluster Partition.
   *
   * Returns the Cluster Partition corresponding to the specified Cluster Partition Id.
   * @param params The `ClusterPartitionsService.GetClusterPartitionByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Cluster Partition to return.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetClusterPartitionByIdResponse(params: ClusterPartitionsService.GetClusterPartitionByIdParams): __Observable<__StrictHttpResponse<ClusterPartition>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/clusterPartitions/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterPartition>;
      })
    );
  }
  /**
   * List details about a single Cluster Partition.
   *
   * Returns the Cluster Partition corresponding to the specified Cluster Partition Id.
   * @param params The `ClusterPartitionsService.GetClusterPartitionByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Cluster Partition to return.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetClusterPartitionById(params: ClusterPartitionsService.GetClusterPartitionByIdParams): __Observable<ClusterPartition> {
    return this.GetClusterPartitionByIdResponse(params).pipe(
      __map(_r => _r.body as ClusterPartition)
    );
  }
}

module ClusterPartitionsService {

  /**
   * Parameters for GetClusterPartitions
   */
  export interface GetClusterPartitionsParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Array of Cluster Partition Names.
     *
     * Filter by a list of Cluster Partition Names. If empty, the Cluster
     * Partitions are not filtered by names.
     */
    names?: Array<string>;

    /**
     * Array of Cluster Partition Ids.
     *
     * Filter by a list of Cluster Partition ids. If empty, the Cluster Partitions
     * are not filtered by id.
     */
    ids?: Array<number>;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetClusterPartitionById
   */
  export interface GetClusterPartitionByIdParams {

    /**
     * Specifies a unique id of the Cluster Partition to return.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { ClusterPartitionsService }
