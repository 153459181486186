import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Evaluates the provided expression and applies one of the given labels.
 * Would default to yes or no if no labels are provided
 */
@Pipe({
  name: 'boolLabel',
  pure: true,
})
export class BoolLabelPipe implements PipeTransform {
  /**
   * creates the pipe
   *
   * @param translate   The translate service
   */
  constructor(private translate: TranslateService) {}

  /**
   * Evaluates the expression and returns true or false label string
   *
   * @method   transform
   * @param    expr                 The conditional expression which is to be
   *                                evaluated
   * @param    [trueLabel='yes']    Label used  if expression is true
   * @param    [falseLabel='no']    Label used if the the expression is false
   * @return   String Label returned according to the expression evaluated
   */
  transform(expr: any, trueLabel: string = 'yes', falseLabel: string = 'no'): string {
    return expr ?
      this.translate.instant(trueLabel) :
      this.translate.instant(falseLabel);
  }
}
