import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '../icon/icon.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { StatusIndicatorComponent } from './status-indicator.component';

const COMPONENTS = [StatusIndicatorComponent];

@NgModule({
  imports: [CommonModule, IconModule, SpinnerModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class StatusIndicatorModule { }
