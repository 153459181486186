// Importable/reusable animation rules should be added here. Any related global
// styles needed should go in scss/animations.scss.

import { animate, state, style, transition, trigger } from '@angular/animations';

export const OPEN_CLOSE_ANIMATION = trigger('openClose', [
  state('*', style({
    opacity: 0,
    height: 0,
  })),
  state('open', style({
    height: '*',
    opacity: 1,
    overflow: 'visible',
  })),
  state('close', style({
    height: '0',
    opacity: 0,
    overflow: 'hidden',
  })),
  transition('close <=> open', [
    animate('.35s'),
  ]),
]);
