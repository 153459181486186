// Module: App Consistent Conflict Modal Service & Controller

;(function(angular, undefined) {
  'use strict';

  angular.module('C.quiesceConflictModal', [])
    .service('QuiesceConflictModalService', serviceFn)
    .controller('QuiesceConflictController', controllerFn);

  function serviceFn(cModal) {
    return {
      openModal: openModal,
    };

    function openModal(quiesceIncompatibleNodes) {
      var modalConfig = {
        templateUrl: 'app/protection/jobs/modify2/quiesce-conflict.html',
        controller: 'QuiesceConflictController',
        size: quiesceIncompatibleNodes.length > 6 ? 'lg' : 'md',
        resolve: {
          entitiesInConflict: function resolveEntitiesInConflict() {
            return quiesceIncompatibleNodes;
          },
        },
      };
      var windowOptions = {
        titleKey: 'quiesceConflictModal.title',
        actionButtonKey: 'quiesceConflictModal.deselectEntities',
        closeButtonKey: 'quiesceConflictModal.enableCrashConsistent',
      };

      return cModal.standardModal(modalConfig, windowOptions);
    }
  }

  function controllerFn(entitiesInConflict) {

    var $ctrl = this;

    /**
     * Initialization routine
     *
     * @method     init
     */
    $ctrl.$onInit = function $onInit() {
      $ctrl.denseList = entitiesInConflict.length > 6;
      $ctrl.entitiesInConflict = entitiesInConflict;
    };

  }

}(angular));
