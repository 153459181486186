<div class="verify-container container">
  <p translate>mfa.verify.description</p>

  <form class="mfa-form-verify" [formGroup]="formGroup"
    cohValidateOnSubmit (validatedSubmit)="verifyToken()">
    <mat-form-field floatLabel="always">
      <mat-label translate>securityCode</mat-label>
      <input cogDataId="mfa-verify-token" type="text" matInput
        [formControlName]="formControlNames.securityToken">
      <mat-icon matSuffix class="verify-icon error" *ngIf="!verified">
        error_outline
      </mat-icon>
      <mat-error *ngIf="formGroupControls.securityToken.invalid">
        {{'errors.required' | translate}}
      </mat-error>
    </mat-form-field>
    <cog-banner *ngIf="verified" class="margin-top" status="success">
      {{'mfa.code.verified' | translate}}
    </cog-banner>
    <div class="padding-top">
      <button class="prev-btn"
        type="button"
        cogDataId="previous-btn"
        mat-button
        color="primary"
        (click)="previous.emit(true)">
        {{'back' | translate}}
      </button>
      <button cogSubmitButton class="verify-token-btn"
        *ngIf="!verified"
        type="button"
        cogDataId="mfa-verify-btn"
        mat-flat-button
        color="primary">
        {{'verify' | translate}}
      </button>
      <button class="next-btn"
        *ngIf="verified"
        type="button"
        cogDataId="next-btn"
        mat-flat-button
        color="primary"
        (click)="moveAhead()">
        {{nextBtnText | translate}}
      </button>
    </div>
  </form>
</div>
