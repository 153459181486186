import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { getMomentDateRange, timeFormat, Timeframe, timeframeMap } from '../../date-utils/public_api';
import { DataFilter, DataFilterItem, DataFilterValue } from './data-filter';

/**
 * Type definition range object used in  `DateRangeFilter`.
 */
export interface DateFilterRange<D> {
  timeframe?: Timeframe;
  timeframeLabel?: string;
  start?: D;
  end?: D;
}

/**
 * Gets the DateRangeFilter object model for the filter by timeRange parameter.
 * Note that by setting only timeframe (without start, end) in the object will not work
 * because of how filter works in Helix. Initial value set by the component,
 * containing time range filter, will not be picked up by time range filter to update
 * start, end in the model.
 *
 * @param timeRange  Time range parameter value (must be within the index of timeframeMap.
 * @returns DateFilterRange object model for the filter.
 */
export function getDateRangeFilter(timeRange: string): DateFilterRange<moment.Moment> {
  const timeframe = timeframeMap[timeRange];
  return (timeRange && timeframe) ? getMomentDateRange(timeframe) : {};
}

/**
 * Date range filter instantiated by `FilterDefDirective`.
 */
export class DateRangeFilter<D> implements DataFilter<DateFilterRange<D>> {
  /**
   * The property/column name of an object to be filtered by
   */
  key: string;

  /**
   * A ui friendly label for the filter name.
   */
  label: string;

  /**
   * Message to show when a filter is locked. If this is not set, the filter is unlocked.
   */
  lockedMessage: string;

  /**
   * The current value of the filter.
   */
  currentValue$ = new BehaviorSubject<DataFilterValue<DateFilterRange<D>>>(undefined);

  /**
   * Returns observable of items for rendering filter chips.
   */
  get tagValues$(): Observable<DataFilterItem[]> {
    return this.currentValue$.pipe(map(this.mapFilterItems));
  }

  /**
   * Maps filter value to filter item used for rendering filter chip items.
   */
  private readonly mapFilterItems = (filterValue: DataFilterValue<DateFilterRange<D>>): DataFilterItem[] => {
    if (filterValue && filterValue.value) {
      const { value } = filterValue;
      const { timeframeLabel, start: startDate, end: endDate } = value;

      if (timeframeLabel) {
        return [{
          label: timeframeLabel,
          value,
          remove: () => this.removeValue(),
        }];
      }

      if (!startDate && !endDate) {
        return [];
      }

      let start = '';
      if (startDate) {
        start = moment(startDate).format(timeFormat);
      }

      let end = '';
      if (endDate) {
        end = moment(endDate).format(timeFormat);
      }

      return [{
        label: `${start} - ${end}`,
        value,
        remove: () => this.removeValue(),
      }];
    }

    return [];
  };

  /**
   * Date range filter predicate.
   *
   * @param    item       Data item that will be searched by `key`.
   * @param    dateRange  Filter date range with start and end dates.
   * @param    key        Property key to lookup date value in `item`.
   * @returns  True if `item[key]` is within `dateRange[0]` and `dateRange[1]`.
   */
  predicate(item: any, dateRange: DateFilterRange<D>, key?: any): boolean {
    if (dateRange) {
      const itemDate = moment(item[key || this.key]);
      const { start, end } = dateRange;
      const startDate = moment(start);
      const endDate = moment(end);

      if (start && end) {
        return itemDate.isAfter(startDate.startOf('day')) && itemDate.isBefore(endDate.endOf('day'));
      } else if (start) {
        return itemDate.isAfter(startDate.startOf('day'));
      } else if (end) {
        return itemDate.isBefore(endDate.endOf('day'));
      }
    }

    return true;
  }

  /**
   * Sets the filter's value.
   *
   * @param  value  The value to set.
   */
  setValue(value: DateFilterRange<D>) {
    this.currentValue$.next({
      value,
      key: this.key,
      predicate: this.predicate,
    });
  }

  /**
   * Removes the requested value from the filter.
   */
  removeValue() {
    this.currentValue$.next(undefined);
  }
}
