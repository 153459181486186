;(function(angular, undefined) {
  'use strict';

  angular
    .module('C')
    .service('LocalSnapshotsUtil', localSnapshotsUtilFn);

  function localSnapshotsUtilFn() {
    var LocalSnapshotsUtil = {
      getErrorCount: getErrorCount,
      getFirstSuccessfulRun: getFirstSuccessfulRun,
      getLatestSuccessfulRun: getLatestSuccessfulRun,
      getSnapshotsCount: getSnapshotsCount,
    };

    /**
     * Calculates the total number of snapshots
     *
     * @param  {array} snapshots array of objects that contains data
     *                           about each protection object
     *
     * @return {number} total number of snapshots
     */
    function getSnapshotsCount(snapshots) {
      return snapshots.reduce(
        function snapshotsCount(accumulator, current) {
          return accumulator + current.numSnapshots;
      }, 0);
    }

    /**
     * Calculates total number of errors in each protection
     * object
     *
     * @param  {array} snapshots array of objects that contains data
     *                           about each protection object
     *
     * @return {number} total number of errors
     */
    function getErrorCount(snapshots) {
      return snapshots.reduce(
        function errorCount(accumulator, current) {
          if (current.numErrors) {
            return accumulator + current.numErrors;
          }

          return accumulator;
      }, 0);
    }

    /**
     * Returns the lastSuccessfulRunTime to inform user when the last
     * successful object run occured after the data has been sorted
     * in descending order.
     *
     * @param  {array} snapshots array of objects that contains data
     *                           about each protection object
     *
     * @return {number} the lastSuccessRunTimeUsecs Value
     */
    function getLatestSuccessfulRun(snapshots) {
      var shallowSnapshots = snapshots.slice(0);

      shallowSnapshots.sort(function sortLastRunTime(a, b) {
        return b.lastSuccessfulRunTimeUsecs - a.lastSuccessfulRunTimeUsecs;
      });

      return shallowSnapshots[0].lastSuccessfulRunTimeUsecs;
    }

    /**
     * Returns the firstSuccessfulRunTime to inform the user when the first
     * successful object run occured after the data has been sorted in
     * ascending order.
     *
     * @param  {array} snapshots array of objects that contains data
     *                           about each protection object
     *
     * @return {number} the firstSuccessfulRunTimeUsecs Value
     */
    function getFirstSuccessfulRun(snapshots) {
      var shallowSnapshots = snapshots.slice(0);

      shallowSnapshots.sort(function sortFirstRunTime(a, b) {
        return a.firstSuccessfulRunTimeUsecs - b.firstSuccessfulRunTimeUsecs;
      });

      return shallowSnapshots[0].firstSuccessfulRunTimeUsecs;
    }

    return LocalSnapshotsUtil;
  }
})(angular);
