import { DoBootstrap, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { ByteSizeService, DATE_PIPE_OPTIONS, HelixAssetsOptions } from '@cohesity/helix';
import { IS_IBM_AQUA_ENV } from '@cohesity/shared/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { environment } from 'src/environments/environment';

import { VERSION } from '../environments/version';
import { manifests } from './app-dynamic-components';
import { appInitializers } from './app.initializers';
import { APP_STATES } from './app.states';
import { CoreModule } from './core';
import { DynamicComponentLoaderModule } from './dynamic-component-loader';
import { defineDowngradeModule } from './shared/ng-downgrade/downgrades';

/**
 * Ensure downgrade modules are defined before initializing the NG module.
 */
defineDowngradeModule();

@Injectable({ providedIn: 'root' })
export class AppHelixAssetsOptions extends HelixAssetsOptions {
  constructor() {
    super();
    this.options = {
      cacheBusterValue: VERSION.hash,
    };
  }
}

/**
 * App Module. This is the root angular module.
 */
@NgModule({
  imports: [
    BrowserModule,
    CoreModule,
    DynamicComponentLoaderModule.forRoot(manifests),
    UIRouterUpgradeModule.forRoot({ states: APP_STATES }),
    UpgradeModule,
  ],
  providers: [
    ...appInitializers,
    ByteSizeService,
    {
      provide: DATE_PIPE_OPTIONS,
      useValue: {},
    },
    {
      provide: IS_IBM_AQUA_ENV,
      useValue: environment.ibmAqua ?? false,
    },
    {
      provide: HelixAssetsOptions,
      useClass: AppHelixAssetsOptions,
    },
  ],
})
export class AppModule implements DoBootstrap {
  constructor(private upgradeModule: UpgradeModule) {}

  /**
   * Bootstraps the angularjs module to finish loading the application.
   */
  ngDoBootstrap() {
    this.upgradeModule.bootstrap(document.documentElement, ['C']);
  }
}
