// Component: Select or Register Source

;(function(angular, undefined) {
  'use strict';

  var componentName = 'selectSource';
  var configOptions = {
    controller: 'SelectSourceCtrl',
    templateUrl: 'app/global/select-source/select-source.html',

    // Attribute bindings
    bindings: {
      /**
       * Optional single value or array of kEnvironment strings or envType
       * integers for which to display in the dropdown.
       *
       * Note: undefined will show *all* registered Sources.
       *
       * Default: undefined
       *
       * @type   {string|integer|array}
       */
      envTypes: '<?',


      /**
       * Optional single value or array of kEntityType strings which
       * are to be excluded from the source dropdown list.
       *
       *
       * Default: undefined
       *
       * @type   {string|array}
       */
      excludeEntityTypes: '<?',

      /**
       * Optionally disable registering new sources within.
       *
       * Default: enabled.
       *
       * @type   {boolean}
       */
      addNew: '<?',

      /**
       * Optional label override. Set 'false' to hide the label.
       *
       * This property does not observe changes.
       *
       * Default: 'Source'
       *
       * @type   {string}
       */
      label: '@?',

      /**
       * Specify a default source by this ID if found in the list.
       *
       * @type   {number}
       */
      defaultId: '=?',

      /**
       * A function to filter the sources, if they need to be filtered on
       * a criteria other than envTypes
       * @type   {function}
       */
      filterSources: '<?',
    },

    // Required outside things. These show up on `this.ngModel` in the
    // controller.
    require: {
      ngModel: '^ngModel',
    },
  };

  angular.module('C.selectSource', [])
    .controller('SelectSourceCtrl', SelectSourceCtrl)
    .component(componentName, configOptions);

  /**
   * $ngdoc Component
   * @name C.selectSource:selectSource
   * @scope
   * @link
   *
   * @requires ngModel
   * @function
   * @description
   *   Widget to select a Source from a dropdown.
   *
   * @example
   *   <select-source
   *     id="selected-parent-source-dropdown"
   *     name="selectedParentSource"
   *     required
   *     ng-model="shared.task.restoreParentSource"
   *     env-types="[1, 'kSQL']"
   *     default-id="12">
   *   </select-source>
   */
  function SelectSourceCtrl($attrs, $scope, SourceService) {

    var $ctrl = this;

    var defaultBaseSourcesList = [
      {
        displayName: 'registerSource',
        icon: 'icn-add',
      },
    ];

    angular.extend($ctrl, {
      sources: [],
      $onInit: init,
      selectOrRegisterSource: selectOrRegisterSource,
    });

    /**
     * Initialize this component.
     *
     * @method     init
     */
    function init() {
      angular.extend($ctrl, {
        label: $ctrl.label || 'source',
        name: [componentName, $attrs.name, Date.now()].join('-'),
        id: ['id', $ctrl.name].join('-'),
      });

      // set the internal model if external model is set from outside
      if ($ctrl.ngModel) {
        $ctrl.ngModel.$render = function updateInternalModalOnExternalChange() {
          $ctrl.selectedSource = $ctrl.ngModel.$modelValue;
        };
      }
    }

    /**
     * Set the model with the given source, or if it's the addNew source, pop
     * the Register Source modal. When that's done, select the newly created
     * source.
     *
     * @method   selectOrRegisterSource
     * @param    {object}   source   The selected Source
     */
    function selectOrRegisterSource(source) {
      var envType;
      var envTypesArray = [];

      if (source && source.type) {
        return setSelection(source);
      }

      $ctrl.loading = true;

      if (Array.isArray($ctrl.envTypes)) {
        envTypesArray = $ctrl.envTypes.slice();
        envType = $ctrl.envTypes[0];
      }
      else {
        envTypesArray = [$ctrl.envTypes];
        envType = $ctrl.envTypes;
      }

      SourceService.registerSourceSlider(envType, envTypesArray).then(
        function modalResolved(source) {
          $ctrl.sources.splice(1, 0, source.entity);
          selectOrRegisterSource(source.entity);
        },
        function modalCanceled() {
          setSelection(void 0);
        }
      )
      .finally(function modalCloseFinally() {
        $ctrl.loading = false;
      });
    }

    /**
     * Sets the selection in this component and in ngModel simultaneously.
     *
     * @method   setSelection
     * @param    {object}   [source]   The source.
     * @return   {object}   The same source.
     */
    function setSelection(source) {
      return $ctrl.ngModel.$setViewValue(
        $ctrl.selectedSource = source
      );
    }

    /**
     * $attrs.$observe handler for (ng)disabled attributes.
     *
     * @method   toggleDisabled
     * @instance
     * @param    {string|boolean}   [disabled]   The detected attribute's
     *                                           expression.
     */
    function toggleDisabled(disabled) {
      $ctrl.isDisabled =
        $attrs.hasOwnProperty('disabled') || $scope.$eval(disabled);
    }

    /**
     * Gets the Source entities.
     *
     * @method   getData
     * @param    {string|integer|array}   [types]   The envTypes attrib value.
     * @return   {object}                 Promise to resolve with the requested
     *                                    data.
     */
    function getData(types) {
      var params = SourceService.buildSourceEntitiesOfTypeParams(
        types, $ctrl.excludeEntityTypes);

      return SourceService.getEntitiesOfType(params)
        .then(function thenHandler(sources) {
          if ($ctrl.filterSources) {
            sources = $ctrl.filterSources(sources);
          }

          var listBase = $ctrl.addNew === undefined || $ctrl.addNew ?
            defaultBaseSourcesList : [];

          $ctrl.sources = listBase.concat(sources);

          // if a defaultId was provided, find it in the list of sources and
          // assign it to the internal model
          if ($ctrl.defaultId) {
            $ctrl.sources.some(function findDefault(source) {
              if (source.id === +$ctrl.defaultId) {
                $ctrl.selectOrRegisterSource(source);

                return true;
              }
            });
          }

        });
    }

    /**
     * Simple getter for the configured envTypes.
     *
     * @method   getEnvSpecifiedEnvTypes
     * @return   {string|integer|array}   The specified environment types, or
     *                                    undefined.
     */
    function getEnvSpecifiedEnvTypes() {
      return $ctrl.envTypes;
    }

    /**
     * Watchers & Observers
     */
    // Watch changes to ctrl.envTypes and re-fetch sources accordingly
    $scope.$watch(getEnvSpecifiedEnvTypes, getData);

    // Because (ng)disabled state isn't exposed on $ctrl.ngModel, $observing the
    // attrs hash instead.
    $attrs.$observe(
      $attrs.hasOwnProperty('disabled') ? 'disabled' : 'ngDisabled',
      toggleDisabled
    );
  }
})(angular);
