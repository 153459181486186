import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DataFiltersModule, DataFilterValue, DataIdModule, ValueFilterSelection } from '@cohesity/helix';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dg-cr-cluster-config-filters',
  standalone: true,
  imports: [CommonModule, DataIdModule, DataFiltersModule, TranslateModule],
  templateUrl: './cluster-config-filters.component.html',
  styleUrls: ['./cluster-config-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClusterConfigFiltersComponent {
  /**
   * Cluster version filter values
   */
  @Input() softwareVersionFilterValues: ValueFilterSelection[] = [];

  /**
   * Cluster location filter values
   */
  @Input() locationFilterValues: ValueFilterSelection[] = [];

  /**
   * Cluster connection health filter values
   */
  @Input() connectionFilterValues: ValueFilterSelection[] = [
    {
      label: this.translateService.instant('connected'),
      value: true,
    },
    {
      label: this.translateService.instant('disconnected'),
      value: false,
    },
  ];

  /**
   * Cluster app health filter values
   */
  @Input() appStatusFilterValues: ValueFilterSelection[] = [
    {
      label: this.translateService.instant('healthy'),
      value: true,
    },
    {
      label: this.translateService.instant('dg.clusterReplication.status.unhealthy'),
      value: false,
    },
  ];

  /**
   * Filter changes callback via event emitter
   */
  @Output() valueChanges = new EventEmitter<DataFilterValue<any, any>[]>();

  constructor(private translateService: TranslateService) {}

  /**
   * Apply filters to event emitter output
   */
  applyFilters(filters: DataFilterValue<any, any>[]) {
    this.valueChanges.emit(filters);
  }
}
