import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SmbPermission as SmbPermissionV1 } from '@cohesity/api/v1';
import { SmbPermission as SmbPermissionV2 } from '@cohesity/api/v2';
import { DialogService } from 'src/app/core/services';

import { SMB_ACL_PERMISSIONS } from '../../models';
import { ViewsService } from '../../services';
import { PrincipalDialogComponent } from '../principal-dialog/principal-dialog.component';

@Component({
  selector: 'coh-acl-permissions',
  templateUrl: './acl-permissions.component.html',
})
export class AclPermissionsComponent implements OnInit {
  /**
   * Smb permission list information.
   */
  @Input() permissionList: (SmbPermissionV1 | SmbPermissionV2)[];

  /**
   * Holds a hash of principals in their domains.
   */
  @Input() domainPrincipalsHash: any;

  /**
   * Viewbox trusted ad domains.
   */
  @Input() trustedAdDomains: string[] = ['LOCAL'];

  /**
   * Determies whether the actions are readonly or not.
   */
  @Input() readOnly = false;

  /**
   * Boolean indicates whether this is for Shares because there are some
   * differences between ACL Permissions for Shares and Views.
   */
  @Input() shares = false;

  /**
   * Event emitter to update permissions.
   */
  @Output() updatedPermissions = new EventEmitter();

  /**
   * displayedColumns to be displayed.
   */
  displayedColumns: string[] = [
    'principal',
    'domain',
    'type',
    'appliesTo',
    'permissions',
    'actions',
  ];

  /**
   * SMB Permissions.
   */
  smbPermissions = SMB_ACL_PERMISSIONS;

  /**
   * Determines whether data is loaded or not.
   */
  get isLoading(): boolean {
    return !this.permissionList;
  }

  /**
   * Determines whether to show warning which displays a notice that changes will NOT be applied retroactively
   */
  @Input() showWarningWhenChangePermission = false;

  constructor(
    private dialog: DialogService,
    private viewsService: ViewsService,
  ) { }

  /**
   * Init Component.
   */
  ngOnInit() {
    if (this.shares) {
      this.displayedColumns = this.displayedColumns.filter(e => e !== 'appliesTo');
    }
  }

  /**
   * Returns the domain principal name using the given hash.
   *
   * @param item The permision item
   * @returns The prinicipal name.
   */
  getPrincipalName(item: SmbPermissionV1 | SmbPermissionV2): string {
    return this.domainPrincipalsHash[item.sid]?.fullName ??
      this.domainPrincipalsHash[item.sid]?.name ??
      this.domainPrincipalsHash[item.sid]?.username ??
      item.sid;
  }

  /**
   * Method called to add new permissions.
   */
  addPermission() {
    const data = {
      visibleDomains: this.trustedAdDomains,
      shares: this.shares,
      title: !this.shares ? 'smbPermissions.addNTFSRootPermissions' :
        'smbPermissions.addShareLevelPermissions',
      subTitle: this.showWarningWhenChangePermission ? 'viewModify.ntfsPermissions.warningOnChange' : null,
      domainPrincipalsHash: this.domainPrincipalsHash,
      permissionList: this.permissionList,
    };

    this.dialog.showDialog(PrincipalDialogComponent, data, { restoreFocus: false, width: '38rem' })
      .subscribe(result => {
        if (result) {
          if (this.permissionList !== null) {
            this.permissionList.push(result);
          } else {
            this.permissionList = [result];
          }
          this.permissionList = [...this.permissionList];
          this.updatedPermissions.emit(this.permissionList);
        }
      });
  }

  /**
   * Method called to remove SMB permission from the permission list.
   *
   * @param   permission   smb permission
   */
  removePermission(permission: SmbPermissionV1 | SmbPermissionV2) {

    const removeFn = () => {
      this.permissionList = this.permissionList.filter(perm => {
        if (permission.sid !== perm.sid) {
          return perm;
        }
      });
      this.updatedPermissions.emit(this.permissionList);
    };

    if (!this.showWarningWhenChangePermission) {
      removeFn();
    } else {
      this.viewsService.openChallengeDialog(removeFn, () => {}, 'viewModify.ntfsPermissions.warningOnChange');
    }
  }
}
