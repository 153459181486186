import {
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FilterDefDirective, FiltersComponent, ValueFilterSelection } from '@cohesity/helix';
import { AutoDestroyable } from '@cohesity/utils';
import { BehaviorSubject, Observable } from 'rxjs';

@Directive()
export abstract class BaseObjectFilterComponent<ParamsType> extends AutoDestroyable
  implements OnInit, OnChanges, OnDestroy {

  /**
   * Option to hide a filter. This hides the filter in the dom, without removing the filter def itself.
   */
  @Input() hideFilter = false;

  /**
   * Optional params to pass to the api. Changing params will trigger a new api call.
   */
  @Input() requestParams: ParamsType;

  /**
   * Event triggers when options are loaded.
   */
  @Output() optionsLoaded = new EventEmitter<ValueFilterSelection[]>();

  /**
   * Position of filter inside cog-filters.
   */
  @Input() position: number;

  /**
   * The storage domain options to show in the value filter.
   */
  filterOptions$ = new BehaviorSubject<ValueFilterSelection[]>([]);

  /**
   * The filter def directive for the filter
   */
  @ViewChild(FilterDefDirective, { static: true }) filterDef: FilterDefDirective;

  constructor(protected filters: FiltersComponent) {
    super();
  }

  /**
   * Fetch storage domains when the component initializes or the params change.
   *
   * @returns   The filter values to display in the value property filter.
   */
  abstract getFilterValues(): Observable<ValueFilterSelection[]>;

  /**
   * Fetch objects and update the filter options behavior subject with the result.
   */
  fetchObjects() {
    this.getFilterValues()
      .pipe(this.untilDestroy())
      .subscribe(filterValues => {
        this.filterOptions$.next(filterValues);
        this.optionsLoaded.emit(filterValues);
      });
  }

  ngOnInit() {
    if (this.filters && this.filterDef) {
      this.filters.addFilterDef(this.filterDef);
    }
    this.fetchObjects();
  }

  ngOnDestroy() {
    if (this.filters && this.filterDef) {
      this.filters.removeFilterDef(this.filterDef);
    }
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges) {
    // Ignore the first time this is called, since ng on init will pick it up
    if (changes.requestParams && !changes.requestParams.firstChange) {
      this.fetchObjects();
    }
  }
}
