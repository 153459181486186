import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared';
import { HeliosClaimComponent } from './helios-claim.component';

const components = [HeliosClaimComponent];

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: components,
  exports: components,
})
export class HeliosClaimModule {}
