/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AnalyseJarResult } from '../models/analyse-jar-result';
import { AnalyseJarArg } from '../models/analyse-jar-arg';
import { ApplicationsWrapper } from '../models/applications-wrapper';
import { MapReduceInfo } from '../models/map-reduce-info';
import { KillMapReduceInstanceResult } from '../models/kill-map-reduce-instance-result';
import { MappersWrapper } from '../models/mappers-wrapper';
import { MapperInfo } from '../models/mapper-info';
import { AppRunHistory } from '../models/app-run-history';
import { GetMapReduceAppRunsParams } from '../models/get-map-reduce-app-runs-params';
import { ExtractFileRangeResult } from '../models/extract-file-range-result';
import { MapReduceFileFormats } from '../models/map-reduce-file-formats';
import { ReducersWrapper } from '../models/reducers-wrapper';
import { ReducerInfo } from '../models/reducer-info';
import { RunMapReduceInstanceResult } from '../models/run-map-reduce-instance-result';
import { RunMapReduceParams } from '../models/run-map-reduce-params';
import { SupportedPattern } from '../models/supported-pattern';
import { PatternRequestBody } from '../models/pattern-request-body';
import { UploadMRJarViewPathWrapper } from '../models/upload-mrjar-view-path-wrapper';
import { GetMRJarUploadPathResult } from '../models/get-mrjar-upload-path-result';
@Injectable({
  providedIn: 'root',
})
class AnalyticsService extends __BaseService {
  static readonly AnalyzeJarPath = '/public/analytics/analyzeJar';
  static readonly GetApplicationsPath = '/public/analytics/apps';
  static readonly CreateApplicationPath = '/public/analytics/apps';
  static readonly GetApplicationByIdPath = '/public/analytics/apps/{id}';
  static readonly UpdateApplicationPath = '/public/analytics/apps/{id}';
  static readonly DeleteApplicationPath = '/public/analytics/apps/{id}';
  static readonly CancelMapReduceInstanceRunPath = '/public/analytics/cancelAppInstanceRun/{id}';
  static readonly GetMappersPath = '/public/analytics/mappers';
  static readonly CreateMapperPath = '/public/analytics/mappers';
  static readonly GetMapperByIdPath = '/public/analytics/mappers/{id}';
  static readonly UpdateMapperPath = '/public/analytics/mappers/{id}';
  static readonly DeleteMapperPath = '/public/analytics/mappers/{id}';
  static readonly DeleteMapReduceInstanceRunPath = '/public/analytics/mrAppRun/{id}';
  static readonly GetMapReduceAppRunsPath = '/public/analytics/mrAppRuns';
  static readonly DownloadMRBaseJarPath = '/public/analytics/mrBaseJar';
  static readonly GetMapReduceFileFormatsPath = '/public/analytics/mrFileFormats';
  static readonly DownloadMROutputFilesPath = '/public/analytics/mrOutputfiles';
  static readonly GetReducersPath = '/public/analytics/reducers';
  static readonly CreateReducerPath = '/public/analytics/reducers';
  static readonly GetReducerByIdPath = '/public/analytics/reducers/{id}';
  static readonly UpdateReducerPath = '/public/analytics/reducers/{id}';
  static readonly DeleteReducerPath = '/public/analytics/reducers/{id}';
  static readonly RunMapReduceAppInstancePath = '/public/analytics/runAppInstance';
  static readonly GetSupportedPatternsPath = '/public/analytics/supportedPatterns';
  static readonly SavePatternPath = '/public/analytics/supportedPatterns';
  static readonly UploadJarPath = '/public/analytics/uploadJar';
  static readonly DeleteUploadedJarPath = '/public/analytics/uploadJar';
  static readonly GetMRUploadJarPathPath = '/public/analytics/uploadJarPath';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Analyze the uploaded jar.
   *
   * Returns the result of the jar analysis.
   * @param params The `AnalyticsService.AnalyzeJarParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`:
   *
   * @return Success
   */
  AnalyzeJarResponse(params: AnalyticsService.AnalyzeJarParams): __Observable<__StrictHttpResponse<AnalyseJarResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    __body = params.Body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/analytics/analyzeJar`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AnalyseJarResult>;
      })
    );
  }
  /**
   * Analyze the uploaded jar.
   *
   * Returns the result of the jar analysis.
   * @param params The `AnalyticsService.AnalyzeJarParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`:
   *
   * @return Success
   */
  AnalyzeJar(params: AnalyticsService.AnalyzeJarParams): __Observable<AnalyseJarResult> {
    return this.AnalyzeJarResponse(params).pipe(
      __map(_r => _r.body as AnalyseJarResult)
    );
  }

  /**
   * List Applications filtered by the specified parameters.
   *
   * If no parameters are specified, all Applications currently on
   * the Cohesity Cluster are returned.
   * Specifying parameters filters the results that are returned.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetApplicationsResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<ApplicationsWrapper>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/analytics/apps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApplicationsWrapper>;
      })
    );
  }
  /**
   * List Applications filtered by the specified parameters.
   *
   * If no parameters are specified, all Applications currently on
   * the Cohesity Cluster are returned.
   * Specifying parameters filters the results that are returned.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetApplications(regionId?: string,
    accessClusterId?: number): __Observable<ApplicationsWrapper> {
    return this.GetApplicationsResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as ApplicationsWrapper)
    );
  }

  /**
   * Create an Application.
   *
   * Returns the created application.
   * @param params The `AnalyticsService.CreateApplicationParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`:
   *
   * @return Success
   */
  CreateApplicationResponse(params: AnalyticsService.CreateApplicationParams): __Observable<__StrictHttpResponse<MapReduceInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    __body = params.Body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/analytics/apps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MapReduceInfo>;
      })
    );
  }
  /**
   * Create an Application.
   *
   * Returns the created application.
   * @param params The `AnalyticsService.CreateApplicationParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`:
   *
   * @return Success
   */
  CreateApplication(params: AnalyticsService.CreateApplicationParams): __Observable<MapReduceInfo> {
    return this.CreateApplicationResponse(params).pipe(
      __map(_r => _r.body as MapReduceInfo)
    );
  }

  /**
   * List details about a single Application.
   *
   * Returns the Application corresponding to the specified Application Id.
   * @param params The `AnalyticsService.GetApplicationByIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetApplicationByIdResponse(params: AnalyticsService.GetApplicationByIdParams): __Observable<__StrictHttpResponse<MapReduceInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/analytics/apps/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MapReduceInfo>;
      })
    );
  }
  /**
   * List details about a single Application.
   *
   * Returns the Application corresponding to the specified Application Id.
   * @param params The `AnalyticsService.GetApplicationByIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetApplicationById(params: AnalyticsService.GetApplicationByIdParams): __Observable<MapReduceInfo> {
    return this.GetApplicationByIdResponse(params).pipe(
      __map(_r => _r.body as MapReduceInfo)
    );
  }

  /**
   * Update an Application.
   *
   * Returns the updated Application.
   * @param params The `AnalyticsService.UpdateApplicationParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`:
   *
   * @return Success
   */
  UpdateApplicationResponse(params: AnalyticsService.UpdateApplicationParams): __Observable<__StrictHttpResponse<MapReduceInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    __body = params.Body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/analytics/apps/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MapReduceInfo>;
      })
    );
  }
  /**
   * Update an Application.
   *
   * Returns the updated Application.
   * @param params The `AnalyticsService.UpdateApplicationParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`:
   *
   * @return Success
   */
  UpdateApplication(params: AnalyticsService.UpdateApplicationParams): __Observable<MapReduceInfo> {
    return this.UpdateApplicationResponse(params).pipe(
      __map(_r => _r.body as MapReduceInfo)
    );
  }

  /**
   * Delete an Application.
   *
   * Returns delete status upon completion.
   * @param params The `AnalyticsService.DeleteApplicationParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteApplicationResponse(params: AnalyticsService.DeleteApplicationParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/analytics/apps/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete an Application.
   *
   * Returns delete status upon completion.
   * @param params The `AnalyticsService.DeleteApplicationParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteApplication(params: AnalyticsService.DeleteApplicationParams): __Observable<null> {
    return this.DeleteApplicationResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Cancel a specific map reduce instance run.
   *
   * Returns the result.
   * @param params The `AnalyticsService.CancelMapReduceInstanceRunParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CancelMapReduceInstanceRunResponse(params: AnalyticsService.CancelMapReduceInstanceRunParams): __Observable<__StrictHttpResponse<KillMapReduceInstanceResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/analytics/cancelAppInstanceRun/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<KillMapReduceInstanceResult>;
      })
    );
  }
  /**
   * Cancel a specific map reduce instance run.
   *
   * Returns the result.
   * @param params The `AnalyticsService.CancelMapReduceInstanceRunParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CancelMapReduceInstanceRun(params: AnalyticsService.CancelMapReduceInstanceRunParams): __Observable<KillMapReduceInstanceResult> {
    return this.CancelMapReduceInstanceRunResponse(params).pipe(
      __map(_r => _r.body as KillMapReduceInstanceResult)
    );
  }

  /**
   * List Mappers filtered by the specified parameters.
   *
   * If no parameters are specified, all Mappers currently on
   * the Cohesity Cluster are returned.
   * Specifying parameters filters the results that are returned.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetMappersResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<MappersWrapper>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/analytics/mappers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MappersWrapper>;
      })
    );
  }
  /**
   * List Mappers filtered by the specified parameters.
   *
   * If no parameters are specified, all Mappers currently on
   * the Cohesity Cluster are returned.
   * Specifying parameters filters the results that are returned.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetMappers(regionId?: string,
    accessClusterId?: number): __Observable<MappersWrapper> {
    return this.GetMappersResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as MappersWrapper)
    );
  }

  /**
   * Create a Mapper.
   *
   * Returns the created mapper.
   * @param params The `AnalyticsService.CreateMapperParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`:
   *
   * @return Success
   */
  CreateMapperResponse(params: AnalyticsService.CreateMapperParams): __Observable<__StrictHttpResponse<MapperInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    __body = params.Body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/analytics/mappers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MapperInfo>;
      })
    );
  }
  /**
   * Create a Mapper.
   *
   * Returns the created mapper.
   * @param params The `AnalyticsService.CreateMapperParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`:
   *
   * @return Success
   */
  CreateMapper(params: AnalyticsService.CreateMapperParams): __Observable<MapperInfo> {
    return this.CreateMapperResponse(params).pipe(
      __map(_r => _r.body as MapperInfo)
    );
  }

  /**
   * List details about a single Mapper.
   *
   * Returns the Mapper corresponding to the specified Mapper Id.
   * @param params The `AnalyticsService.GetMapperByIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetMapperByIdResponse(params: AnalyticsService.GetMapperByIdParams): __Observable<__StrictHttpResponse<MapperInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/analytics/mappers/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MapperInfo>;
      })
    );
  }
  /**
   * List details about a single Mapper.
   *
   * Returns the Mapper corresponding to the specified Mapper Id.
   * @param params The `AnalyticsService.GetMapperByIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetMapperById(params: AnalyticsService.GetMapperByIdParams): __Observable<MapperInfo> {
    return this.GetMapperByIdResponse(params).pipe(
      __map(_r => _r.body as MapperInfo)
    );
  }

  /**
   * Update a Mapper.
   *
   * Returns the updated Mapper.
   * @param params The `AnalyticsService.UpdateMapperParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`:
   *
   * @return Success
   */
  UpdateMapperResponse(params: AnalyticsService.UpdateMapperParams): __Observable<__StrictHttpResponse<MapperInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    __body = params.Body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/analytics/mappers/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MapperInfo>;
      })
    );
  }
  /**
   * Update a Mapper.
   *
   * Returns the updated Mapper.
   * @param params The `AnalyticsService.UpdateMapperParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`:
   *
   * @return Success
   */
  UpdateMapper(params: AnalyticsService.UpdateMapperParams): __Observable<MapperInfo> {
    return this.UpdateMapperResponse(params).pipe(
      __map(_r => _r.body as MapperInfo)
    );
  }

  /**
   * Delete a Mapper.
   *
   * Returns delete status upon completion.
   * @param params The `AnalyticsService.DeleteMapperParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteMapperResponse(params: AnalyticsService.DeleteMapperParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/analytics/mappers/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a Mapper.
   *
   * Returns delete status upon completion.
   * @param params The `AnalyticsService.DeleteMapperParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteMapper(params: AnalyticsService.DeleteMapperParams): __Observable<null> {
    return this.DeleteMapperResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Delete a Map-Reduce Application Instance Run.
   *
   * Returns delete status upon completion.
   * @param params The `AnalyticsService.DeleteMapReduceInstanceRunParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteMapReduceInstanceRunResponse(params: AnalyticsService.DeleteMapReduceInstanceRunParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/analytics/mrAppRun/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a Map-Reduce Application Instance Run.
   *
   * Returns delete status upon completion.
   * @param params The `AnalyticsService.DeleteMapReduceInstanceRunParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteMapReduceInstanceRun(params: AnalyticsService.DeleteMapReduceInstanceRunParams): __Observable<null> {
    return this.DeleteMapReduceInstanceRunResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * List map reduce application runs filtered by the specified parameters.
   *
   * If no parameters are specified, all map reduce application instance runs
   * currently on the Cohesity Cluster are returned.
   * Specifying parameters filters the results that are returned.
   * @param params The `AnalyticsService.GetMapReduceAppRunsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`:
   *
   * @return Success
   */
  GetMapReduceAppRunsResponse(params: AnalyticsService.GetMapReduceAppRunsParams): __Observable<__StrictHttpResponse<Array<AppRunHistory>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    __body = params.Body;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/analytics/mrAppRuns`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AppRunHistory>>;
      })
    );
  }
  /**
   * List map reduce application runs filtered by the specified parameters.
   *
   * If no parameters are specified, all map reduce application instance runs
   * currently on the Cohesity Cluster are returned.
   * Specifying parameters filters the results that are returned.
   * @param params The `AnalyticsService.GetMapReduceAppRunsParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`:
   *
   * @return Success
   */
  GetMapReduceAppRuns(params: AnalyticsService.GetMapReduceAppRunsParams): __Observable<Array<AppRunHistory>> {
    return this.GetMapReduceAppRunsResponse(params).pipe(
      __map(_r => _r.body as Array<AppRunHistory>)
    );
  }

  /**
   * Downloads the map reduce base jar.
   *
   * Returns a struct containing the map reduce base jar from the cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  DownloadMRBaseJarResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<ExtractFileRangeResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/analytics/mrBaseJar`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExtractFileRangeResult>;
      })
    );
  }
  /**
   * Downloads the map reduce base jar.
   *
   * Returns a struct containing the map reduce base jar from the cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  DownloadMRBaseJar(regionId?: string,
    accessClusterId?: number): __Observable<ExtractFileRangeResult> {
    return this.DownloadMRBaseJarResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as ExtractFileRangeResult)
    );
  }

  /**
   * Used to retrieve supported output file formats from a map reduce instance.
   *
   * The Analytics workbench generates output files from map reduce instances run for
   * a particular application. This API returns the output file formats available to
   * the user for download.
   * @param params The `AnalyticsService.GetMapReduceFileFormatsParams` containing the following parameters:
   *
   * - `viewName`: Specifies the name of the view inside view box where map reduce instance.
   *   is run.
   *
   * - `storageDomainId`: Specifies the ID of the storage domain.
   *
   * - `resultFilePath`: Specifies the path where the map reduce instance has the result.
   *   file generated.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetMapReduceFileFormatsResponse(params: AnalyticsService.GetMapReduceFileFormatsParams): __Observable<__StrictHttpResponse<MapReduceFileFormats>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.viewName != null) __params = __params.set('viewName', params.viewName.toString());
    if (params.storageDomainId != null) __params = __params.set('storageDomainId', params.storageDomainId.toString());
    if (params.resultFilePath != null) __params = __params.set('resultFilePath', params.resultFilePath.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/analytics/mrFileFormats`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MapReduceFileFormats>;
      })
    );
  }
  /**
   * Used to retrieve supported output file formats from a map reduce instance.
   *
   * The Analytics workbench generates output files from map reduce instances run for
   * a particular application. This API returns the output file formats available to
   * the user for download.
   * @param params The `AnalyticsService.GetMapReduceFileFormatsParams` containing the following parameters:
   *
   * - `viewName`: Specifies the name of the view inside view box where map reduce instance.
   *   is run.
   *
   * - `storageDomainId`: Specifies the ID of the storage domain.
   *
   * - `resultFilePath`: Specifies the path where the map reduce instance has the result.
   *   file generated.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetMapReduceFileFormats(params: AnalyticsService.GetMapReduceFileFormatsParams): __Observable<MapReduceFileFormats> {
    return this.GetMapReduceFileFormatsResponse(params).pipe(
      __map(_r => _r.body as MapReduceFileFormats)
    );
  }

  /**
   * Download map reduce base instance run output files from Yoda.
   *
   * Returns a struct containing the map reduce instance run output files from Yoda.
   * @param params The `AnalyticsService.DownloadMROutputFilesParams` containing the following parameters:
   *
   * - `viewName`: View name for the file to be read. Required for nfs files only.
   *
   * - `viewBoxId`: View box id for the file to be read. Required for nfs files only.
   *
   * - `startOffset`: start offset from where bytes will be read.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `partitionId`: Cluster partition id for the file to be read.
   *
   * - `lengthBytes`: Number of bytes to be read from start_offset.
   *
   * - `isNfsFile`: If true, then extracts file from NFS, else from local file system.
   *
   * - `filePath`: filepath of the file on NFS.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  DownloadMROutputFilesResponse(params: AnalyticsService.DownloadMROutputFilesParams): __Observable<__StrictHttpResponse<ExtractFileRangeResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.viewName != null) __params = __params.set('viewName', params.viewName.toString());
    if (params.viewBoxId != null) __params = __params.set('viewBoxId', params.viewBoxId.toString());
    if (params.startOffset != null) __params = __params.set('startOffset', params.startOffset.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.partitionId != null) __params = __params.set('partitionId', params.partitionId.toString());
    if (params.lengthBytes != null) __params = __params.set('lengthBytes', params.lengthBytes.toString());
    if (params.isNfsFile != null) __params = __params.set('isNfsFile', params.isNfsFile.toString());
    if (params.filePath != null) __params = __params.set('filePath', params.filePath.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/analytics/mrOutputfiles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExtractFileRangeResult>;
      })
    );
  }
  /**
   * Download map reduce base instance run output files from Yoda.
   *
   * Returns a struct containing the map reduce instance run output files from Yoda.
   * @param params The `AnalyticsService.DownloadMROutputFilesParams` containing the following parameters:
   *
   * - `viewName`: View name for the file to be read. Required for nfs files only.
   *
   * - `viewBoxId`: View box id for the file to be read. Required for nfs files only.
   *
   * - `startOffset`: start offset from where bytes will be read.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `partitionId`: Cluster partition id for the file to be read.
   *
   * - `lengthBytes`: Number of bytes to be read from start_offset.
   *
   * - `isNfsFile`: If true, then extracts file from NFS, else from local file system.
   *
   * - `filePath`: filepath of the file on NFS.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  DownloadMROutputFiles(params: AnalyticsService.DownloadMROutputFilesParams): __Observable<ExtractFileRangeResult> {
    return this.DownloadMROutputFilesResponse(params).pipe(
      __map(_r => _r.body as ExtractFileRangeResult)
    );
  }

  /**
   * List Reducers filtered by the specified parameters.
   *
   * If no parameters are specified, all Reducers currently on
   * the Cohesity Cluster are returned.
   * Specifying parameters filters the results that are returned.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetReducersResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<ReducersWrapper>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/analytics/reducers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReducersWrapper>;
      })
    );
  }
  /**
   * List Reducers filtered by the specified parameters.
   *
   * If no parameters are specified, all Reducers currently on
   * the Cohesity Cluster are returned.
   * Specifying parameters filters the results that are returned.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetReducers(regionId?: string,
    accessClusterId?: number): __Observable<ReducersWrapper> {
    return this.GetReducersResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as ReducersWrapper)
    );
  }

  /**
   * Create a Reducer.
   *
   * Returns the created reducer.
   * @param params The `AnalyticsService.CreateReducerParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`:
   *
   * @return Success
   */
  CreateReducerResponse(params: AnalyticsService.CreateReducerParams): __Observable<__StrictHttpResponse<ReducerInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    __body = params.Body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/analytics/reducers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReducerInfo>;
      })
    );
  }
  /**
   * Create a Reducer.
   *
   * Returns the created reducer.
   * @param params The `AnalyticsService.CreateReducerParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`:
   *
   * @return Success
   */
  CreateReducer(params: AnalyticsService.CreateReducerParams): __Observable<ReducerInfo> {
    return this.CreateReducerResponse(params).pipe(
      __map(_r => _r.body as ReducerInfo)
    );
  }

  /**
   * List details about a single Reducer.
   *
   * Returns the Reducer corresponding to the specified Reducer Id.
   * @param params The `AnalyticsService.GetReducerByIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetReducerByIdResponse(params: AnalyticsService.GetReducerByIdParams): __Observable<__StrictHttpResponse<ReducerInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/analytics/reducers/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReducerInfo>;
      })
    );
  }
  /**
   * List details about a single Reducer.
   *
   * Returns the Reducer corresponding to the specified Reducer Id.
   * @param params The `AnalyticsService.GetReducerByIdParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetReducerById(params: AnalyticsService.GetReducerByIdParams): __Observable<ReducerInfo> {
    return this.GetReducerByIdResponse(params).pipe(
      __map(_r => _r.body as ReducerInfo)
    );
  }

  /**
   * Update a Reducer.
   *
   * Returns the updated reducer.
   * @param params The `AnalyticsService.UpdateReducerParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`:
   *
   * @return Success
   */
  UpdateReducerResponse(params: AnalyticsService.UpdateReducerParams): __Observable<__StrictHttpResponse<ReducerInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    __body = params.Body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/analytics/reducers/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReducerInfo>;
      })
    );
  }
  /**
   * Update a Reducer.
   *
   * Returns the updated reducer.
   * @param params The `AnalyticsService.UpdateReducerParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`:
   *
   * @return Success
   */
  UpdateReducer(params: AnalyticsService.UpdateReducerParams): __Observable<ReducerInfo> {
    return this.UpdateReducerResponse(params).pipe(
      __map(_r => _r.body as ReducerInfo)
    );
  }

  /**
   * Delete a Reducer.
   *
   * Returns delete status upon completion.
   * @param params The `AnalyticsService.DeleteReducerParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteReducerResponse(params: AnalyticsService.DeleteReducerParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/analytics/reducers/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a Reducer.
   *
   * Returns delete status upon completion.
   * @param params The `AnalyticsService.DeleteReducerParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteReducer(params: AnalyticsService.DeleteReducerParams): __Observable<null> {
    return this.DeleteReducerResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Run a map-reduce application instance.
   *
   * Returns the updated Application.
   * @param params The `AnalyticsService.RunMapReduceAppInstanceParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`:
   *
   * @return Success
   */
  RunMapReduceAppInstanceResponse(params: AnalyticsService.RunMapReduceAppInstanceParams): __Observable<__StrictHttpResponse<RunMapReduceInstanceResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    __body = params.Body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/analytics/runAppInstance`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RunMapReduceInstanceResult>;
      })
    );
  }
  /**
   * Run a map-reduce application instance.
   *
   * Returns the updated Application.
   * @param params The `AnalyticsService.RunMapReduceAppInstanceParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`:
   *
   * @return Success
   */
  RunMapReduceAppInstance(params: AnalyticsService.RunMapReduceAppInstanceParams): __Observable<RunMapReduceInstanceResult> {
    return this.RunMapReduceAppInstanceResponse(params).pipe(
      __map(_r => _r.body as RunMapReduceInstanceResult)
    );
  }

  /**
   * Used to retrieve supported patterns available for search in an application.
   *
   * The Analytics workbench has ability to search for patterns which can be system
   * defined or can be user defined. This API returns the patterns available for
   * search.
   * @param params The `AnalyticsService.GetSupportedPatternsParams` containing the following parameters:
   *
   * - `applicationId`: Specifies the application Id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `applicationDataType`: Specifies the data type for which supported patterns can be fetched.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetSupportedPatternsResponse(params: AnalyticsService.GetSupportedPatternsParams): __Observable<__StrictHttpResponse<Array<SupportedPattern>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.applicationId != null) __params = __params.set('applicationId', params.applicationId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.applicationDataType != null) __params = __params.set('applicationDataType', params.applicationDataType.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/analytics/supportedPatterns`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SupportedPattern>>;
      })
    );
  }
  /**
   * Used to retrieve supported patterns available for search in an application.
   *
   * The Analytics workbench has ability to search for patterns which can be system
   * defined or can be user defined. This API returns the patterns available for
   * search.
   * @param params The `AnalyticsService.GetSupportedPatternsParams` containing the following parameters:
   *
   * - `applicationId`: Specifies the application Id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `applicationDataType`: Specifies the data type for which supported patterns can be fetched.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetSupportedPatterns(params: AnalyticsService.GetSupportedPatternsParams): __Observable<Array<SupportedPattern>> {
    return this.GetSupportedPatternsResponse(params).pipe(
      __map(_r => _r.body as Array<SupportedPattern>)
    );
  }

  /**
   * Used to save user patterns for search in an application.
   *
   * The Analytics workbench has ability to search for patterns which can be system
   * defined or can be user defined. This API enables the user to save patterns for
   * searching.
   * @param params The `AnalyticsService.SavePatternParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`:
   */
  SavePatternResponse(params: AnalyticsService.SavePatternParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    __body = params.Body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/analytics/supportedPatterns`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Used to save user patterns for search in an application.
   *
   * The Analytics workbench has ability to search for patterns which can be system
   * defined or can be user defined. This API enables the user to save patterns for
   * searching.
   * @param params The `AnalyticsService.SavePatternParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`:
   */
  SavePattern(params: AnalyticsService.SavePatternParams): __Observable<null> {
    return this.SavePatternResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Upload a jar to pre-specified Yoda internal view.
   *
   * Returns a struct containing the jar name and local mount path where the jar
   * will be uploaded.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  UploadJarResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<UploadMRJarViewPathWrapper>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/analytics/uploadJar`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UploadMRJarViewPathWrapper>;
      })
    );
  }
  /**
   * Upload a jar to pre-specified Yoda internal view.
   *
   * Returns a struct containing the jar name and local mount path where the jar
   * will be uploaded.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  UploadJar(regionId?: string,
    accessClusterId?: number): __Observable<UploadMRJarViewPathWrapper> {
    return this.UploadJarResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as UploadMRJarViewPathWrapper)
    );
  }

  /**
   * Delete the uploaded jar from temporary location.
   *
   * Returns delete status upon completion.
   * @param params The `AnalyticsService.DeleteUploadedJarParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`:
   */
  DeleteUploadedJarResponse(params: AnalyticsService.DeleteUploadedJarParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    __body = params.Body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/analytics/uploadJar`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete the uploaded jar from temporary location.
   *
   * Returns delete status upon completion.
   * @param params The `AnalyticsService.DeleteUploadedJarParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`:
   */
  DeleteUploadedJar(params: AnalyticsService.DeleteUploadedJarParams): __Observable<null> {
    return this.DeleteUploadedJarResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get details about the mounted path to upload Jars.
   *
   * Returns the mounted path to upload Jars.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetMRUploadJarPathResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<GetMRJarUploadPathResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/analytics/uploadJarPath`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetMRJarUploadPathResult>;
      })
    );
  }
  /**
   * Get details about the mounted path to upload Jars.
   *
   * Returns the mounted path to upload Jars.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetMRUploadJarPath(regionId?: string,
    accessClusterId?: number): __Observable<GetMRJarUploadPathResult> {
    return this.GetMRUploadJarPathResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as GetMRJarUploadPathResult)
    );
  }
}

module AnalyticsService {

  /**
   * Parameters for AnalyzeJar
   */
  export interface AnalyzeJarParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
    Body?: AnalyseJarArg;
  }

  /**
   * Parameters for CreateApplication
   */
  export interface CreateApplicationParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
    Body?: MapReduceInfo;
  }

  /**
   * Parameters for GetApplicationById
   */
  export interface GetApplicationByIdParams {
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateApplication
   */
  export interface UpdateApplicationParams {
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
    Body?: MapReduceInfo;
  }

  /**
   * Parameters for DeleteApplication
   */
  export interface DeleteApplicationParams {
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CancelMapReduceInstanceRun
   */
  export interface CancelMapReduceInstanceRunParams {
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateMapper
   */
  export interface CreateMapperParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
    Body?: MapperInfo;
  }

  /**
   * Parameters for GetMapperById
   */
  export interface GetMapperByIdParams {
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateMapper
   */
  export interface UpdateMapperParams {
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
    Body?: MapperInfo;
  }

  /**
   * Parameters for DeleteMapper
   */
  export interface DeleteMapperParams {
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteMapReduceInstanceRun
   */
  export interface DeleteMapReduceInstanceRunParams {
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetMapReduceAppRuns
   */
  export interface GetMapReduceAppRunsParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
    Body?: GetMapReduceAppRunsParams;
  }

  /**
   * Parameters for GetMapReduceFileFormats
   */
  export interface GetMapReduceFileFormatsParams {

    /**
     * Specifies the name of the view inside view box where map reduce instance.
     * is run.
     */
    viewName: string;

    /**
     * Specifies the ID of the storage domain.
     */
    storageDomainId: number;

    /**
     * Specifies the path where the map reduce instance has the result.
     * file generated.
     */
    resultFilePath: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DownloadMROutputFiles
   */
  export interface DownloadMROutputFilesParams {

    /**
     * View name for the file to be read. Required for nfs files only.
     */
    viewName?: string;

    /**
     * View box id for the file to be read. Required for nfs files only.
     */
    viewBoxId?: number;

    /**
     * start offset from where bytes will be read.
     */
    startOffset?: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Cluster partition id for the file to be read.
     */
    partitionId?: number;

    /**
     * Number of bytes to be read from start_offset.
     */
    lengthBytes?: number;

    /**
     * If true, then extracts file from NFS, else from local file system.
     */
    isNfsFile?: boolean;

    /**
     * filepath of the file on NFS.
     */
    filePath?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateReducer
   */
  export interface CreateReducerParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
    Body?: ReducerInfo;
  }

  /**
   * Parameters for GetReducerById
   */
  export interface GetReducerByIdParams {
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateReducer
   */
  export interface UpdateReducerParams {
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
    Body?: ReducerInfo;
  }

  /**
   * Parameters for DeleteReducer
   */
  export interface DeleteReducerParams {
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for RunMapReduceAppInstance
   */
  export interface RunMapReduceAppInstanceParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
    Body?: RunMapReduceParams;
  }

  /**
   * Parameters for GetSupportedPatterns
   */
  export interface GetSupportedPatternsParams {

    /**
     * Specifies the application Id.
     */
    applicationId: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the data type for which supported patterns can be fetched.
     */
    applicationDataType?: number;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for SavePattern
   */
  export interface SavePatternParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
    Body?: PatternRequestBody;
  }

  /**
   * Parameters for DeleteUploadedJar
   */
  export interface DeleteUploadedJarParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
    Body?: UploadMRJarViewPathWrapper;
  }
}

export { AnalyticsService }
