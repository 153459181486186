import { Injectable } from '@angular/core';
import { BANNER_PRIORITY, BannerConfig, SnackBarService } from '@cohesity/helix';
import { BaseBannerConfig, IrisContext, IrisContextService, flagEnabled } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services';
import { CaModel } from 'src/app/shared/dialogs/certificate-authority/certificate-authority-interface/certificate-authority-interface.model';
import { buildSettingsRules } from 'src/app/shared/dialogs/certificate-authority/certificate-authority-settings-dialog/certificate-authority-settings-rules';
import { CertificateAuthoritySettingsDialogComponent } from 'src/app/shared/dialogs/index';

/**
 * Mock interface while we don't have any API specification
 */
export interface CertificateSettingsConfig {
  enabled: boolean;
}

/**
 * Mock response for while we don't have any API specification
 */
export const certificateSettingsMockData: CertificateSettingsConfig = {
  enabled: false,
};

@Injectable({
  providedIn: 'root',
})
export class CertificateSettingsBannerConfig extends BaseBannerConfig {
  /**
   * Unique banner representation
   */
  id = 'ng-helios-certificate-settings-banner';

  /**
   * provides banner config as observable
   */
  bannerInfo$ = this.irisCtx.irisContext$.pipe(
    switchMap(ctx => this.getCertificateSettings(ctx)),
    map((certificateSettingsConfig) => ([{
      id: this.id,
      priority: BANNER_PRIORITY.HIGH,
      isVisible: certificateSettingsConfig ? !certificateSettingsConfig.enabled : false,
      status: 'warn',
      allowClose: true,
      text: this.translateService.instant('certificateSettings.banner.text'),
      actionText: this.translateService.instant('certificateSettings.banner.goToConfig'),
      actionCallback: () => {
        this.dialogService
          .showDialog(CertificateAuthoritySettingsDialogComponent).subscribe((result: CaModel['typeOfCa'][]) => {
            buildSettingsRules(
              result,
              this.snackBarService,
              this.translateService,
              this.dialogService,
              this.stateService
            );
          });
      },
      closeCallback: this.closeCallback,
    } as BannerConfig]))
  );

  constructor(
    private irisCtx: IrisContextService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private snackBarService: SnackBarService,
    private stateService: StateService,
  ) {
    super();
  }

  /**
   * Allow banner based on context
   *
   * @param ctx Iris context
   * @param users users detail
   * @returns true/false based on context
   */
  allowBanner(ctx: IrisContext) {
    return (
      !this.isBannerAcknowledged(this.id) &&
      flagEnabled(ctx, 'pkiCertificateManagement') &&
      !!(Object.keys(ctx.selectedScope).length) &&
      !ctx.selectedScope._nonCluster &&
      !ctx.selectedScope._allClusters &&
      this.isLoggedIn(ctx) &&
      ctx.privs.CLUSTER_VIEW
    );
  }

  /**
   * Fetch Certificate Settings Config based on context
   *
   * @returns Certificate Settings Config
   */
  getCertificateSettings(ctx: IrisContext): Observable<CertificateSettingsConfig> {
    return this.allowBanner(ctx) ? of(certificateSettingsMockData) : of(null);
  }
}
