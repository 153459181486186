import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { ObjectSnapshot } from '@cohesity/api/v2';
import { DataFilterValue, FiltersComponent, OPEN_CLOSE_ANIMATION } from '@cohesity/helix';
import { ItemPickerFormControl } from '@cohesity/shared-forms';
import { TranslateService } from '@ngx-translate/core';
import { isEqual, isNil } from 'lodash';
import moment from 'moment';
import { ObservableInput } from 'ngx-observable-input';
import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, take } from 'rxjs/operators';
import { ScopeSelectorService } from 'src/app/core/services';
import { ClusterService } from 'src/app/core/services/cluster.service';
import { AvailableExtensionPoints } from 'src/app/plugin-shared';

import { GroupedObjectSnapshot } from '../../models/grouped-object-snapshot';
import { SnapshotMetadata } from '../../models/snapshot-metadata';
import { SnapshotMetadataService } from '../../services/snapshot-metadata.service';
import { SnapshotSelectorUtilsService } from '../../services/snapshot-selector-utils.service';
import { SnapshotSelectorService } from '../../services/snapshot-selector.service';

/**
 * This is a form component for displaying a list of snapshots in a table. The table
 * shows a radio button, which can be used to select a snapshot.
 */
@Component({
  selector: 'coh-snapshot-selector-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [OPEN_CLOSE_ANIMATION],
})
export class SnapshotSelectorListViewComponent extends ItemPickerFormControl<ObjectSnapshot>
  implements OnChanges, OnInit, AfterViewInit {
  /**
   * The columns to show in the table.
   */
  tableColumns: string[];

  /**
   * The selection model for the table. This is single select, and maps to the current grouped snapshot.
   * The form value will be one of the individual snapshot targets.
   */
  selection = new SelectionModel<GroupedObjectSnapshot>(false);

  /**
   * Determines if we need to show Optimized List view or not.
   */
  @Input() optimizedListView = false;

  /**
   * An initial snapshot to select. This will find the correct snapshot from the list of snapshots
   * provided to the component and select it.
   */
  @Input() snapshotId: string;

  /**
   * Indicates whether the control is using indexed snapshots.
   */
  @Input() usingIndexedSnapshots: boolean;

  /**
   * Indicates whether show/hide size and modified col.
   */
  @Input() hideSizeCol = false;

  /**
   * Indicates whether to show/hide backup type column.
   */
  @Input() hideBackupTypeCol = false;

  /**
   * The list of snapshots to render. These are already grouped by location (ie, local, cloud, nas, etc..).
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput(null) @Input('snapshots') snapshots$: Observable<GroupedObjectSnapshot[]>;

  /**
   * The snapshot metadata information for the currently loaded snapshots. This metadata includes information like
   * tags associated with snapshots.
   */
  @Input() snapshotMetadata: SnapshotMetadata;

  /**
   * Specifies whether GET /snapshots API supports time-range filtering.
   */
  @Input() isTimeRangeFilterThroughApiSupported = false;

  /**
   * Specifies any pre-selected time-range to be rendered.
   */
  @Input() selectedTimeRange: { fromTimeUsecs?: number; toTimeUsecs?: number };

  /**
   * Specifies whether the component is loading.
   */
  @Input() loading: boolean;

  /**
   * Handler for emitting date-range events.
   */
  @Output() dateRangeChange = new EventEmitter<DataFilterValue<any, any>>();

  /**
   * The filters component reference.
   */
  @ViewChild(FiltersComponent) filters: FiltersComponent;

  /**
   * The snapshot list with the date filter applied.
   */
  filteredSnapshots$: Observable<GroupedObjectSnapshot[]>;

  /**
   * A reference to the restore snapshot selection detail extension point definition.
   * This is used by plugins (like vulscanner) to add information about a selected snapshot.
   */
  snapshotExtensionPoint = AvailableExtensionPoints.restoreSnapshotSelectionDetailColumn;

  /**
   * Pending snapshot id is used while a snapshot selection is being changed.
   */
  private pendingSnapshotId: string;

  /**
   * Flag to check whether to show backuptype column or not
   */
  public hideBackupType: boolean;

  /**
   * Return true if cluster is NGCE.
   */
  get cloudEditionEnabled(): boolean {
    return this.clusterInfo.isClusterNGCE;
  }

  /**
   * Callback to pass data about a snapshot to the extension point.
   *
   * @param   row   The current row
   * @returns Info about the snapshot object.
   */
  getExtensionRowData = (row: GroupedObjectSnapshot) => ({
    clusterId: this.scopeSelectorService.clusterId,
    snapshot: row.localSnapshot,
  });

  /**
   * Callback to determine if a user can select a snapshot row
   *
   * @param row The current row
   * @returns false if we are in an on prerm context, and the only snapshots are from fortknox.
   */
  canSelectRow = (row: GroupedObjectSnapshot) => {
    if (this.snapshotMetadataService.isCleanRoomRecoveryPhase1Enabled
      && !this.snapshotMetadataService.isGroupRecoverable(row, this.snapshotMetadata)) {
      // if the group is non recoverable, exist early.
      return false;
    }
    return !this.snapshotSelectorUtilsService.getGroupedSnapshotIsDisabled(row);
  };

  /**
   * Callback to return the row level tooltip for selection status
   *
   * @param row the current row in question
   * @returns the tooltip for the row if selection is disabled
   */
  selectRowTooltip = (row: GroupedObjectSnapshot) => {
    if (this.snapshotMetadataService.isCleanRoomRecoveryPhase1Enabled
      && !this.snapshotMetadataService.isGroupRecoverable(row, this.snapshotMetadata)) {
      return this.translateService.instant('anomalyRecovery.snapshotRecoveryNotAllowed');
    }

    return null;
  };

  constructor(
    private scopeSelectorService: ScopeSelectorService,
    @Optional() @Self() public ngControl: NgControl,
    private clusterInfo: ClusterService,
    private snapshotMetadataService: SnapshotMetadataService,
    readonly snapshotSelectorService: SnapshotSelectorService,
    readonly snapshotSelectorUtilsService: SnapshotSelectorUtilsService,
    private translateService: TranslateService,
  ) {
    super();

    // Set the value accessor directly.
    // This is equivalent to injecting NG_VALUE_ACCESSOR to the provider.
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.snapshotId && this.snapshotId) {
      this.selectSnapshot(this.snapshotId);
    }

    if (!isNil(changes.usingIndexedSnapshots)) {
      const shouldShowSizeAndModified = this.usingIndexedSnapshots && !this.hideSizeCol;
      this.tableColumns = [
        'snapshotTimestampUsecs',
        shouldShowSizeAndModified && 'lastModifiedTimeUsecs',
        shouldShowSizeAndModified && 'sizeBytes',
        !this.hideBackupTypeCol && !shouldShowSizeAndModified && 'runType' ,
        'targets',
        this.snapshotMetadataService.isCleanRoomRecoveryPhase1Enabled && 'tags',
      ].filter(Boolean);;
    }
  }

  ngOnInit() {
    this.selection.changed.pipe(startWith({}), this.untilDestroy()).subscribe(() => this.onSelectionChange());
  }

  ngAfterViewInit() {
    // There can be 3 cases here:
    // Case 1. Optimized PIT recovery
    // Case 2. Adaptors supporting API based time range filtering.
    // Case 3. Adaptors supporting UI only time range filtering.
    if (this.optimizedListView ) {
      // Case 1. Optimized PIT recovery
      this.filteredSnapshots$ = this.snapshots$;
    } else if (this.isTimeRangeFilterThroughApiSupported) {
      // Case 2. Adaptors supporting API based time range filtering.
      // Set the receieved snapshots.
      this.filteredSnapshots$ = this.snapshots$;
      const listDateRange = {
        start: moment(this.selectedTimeRange?.fromTimeUsecs / 1000),
        end: moment(this.selectedTimeRange?.toTimeUsecs / 1000)
      };

      // Set the date range value preselected by the parent.
      this.filters?.setValue('dateRange', listDateRange);

      // Subscribe to changes to the dateRange filter to trigger
      this.filters?.filterValues$.pipe(
        this.untilDestroy(),
        distinctUntilChanged<DataFilterValue<any, any>[]>(isEqual)
      ).subscribe(filters => {
        const dateRange: DataFilterValue<any, any> = filters.find(
          filterValue => filterValue.key === 'dateRange');
        if (dateRange) {
          this.dateRangeChange.emit(dateRange);
        }
      });
    } else {
      // Case 3. Adaptors supporting UI only time range filtering.
      this.filteredSnapshots$ = combineLatest([this.snapshots$, this.filters?.filterValues$]).pipe(
        filter(([snapshots, filterValues]) => !!snapshots && !!filterValues),
        map(([snapshots, filterValues]) => {
          const dateRange = filterValues.find(filterValue => filterValue.key === 'dateRange');
          if (!dateRange) {
            return snapshots;
          }
          const start = dateRange.value.start.toDate().getTime() * 1000;
          const end = dateRange.value.end.toDate().getTime() * 1000;

          return snapshots.filter(
            snapshot => snapshot.snapshotTimestampUsecs >= start && snapshot.snapshotTimestampUsecs <= end
          );
        })
      );
    }

    // Filter for snapshots within last 30 days only for those workloads
    // which don't support Time-Range based filter.
    if (!this.isTimeRangeFilterThroughApiSupported) {
      this.snapshots$.pipe(
        filter(snapshots => !!snapshots?.length),
        this.untilDestroy()
      ).subscribe(snapshots => {
        // Make sure that the initial filter includes the most recent snapshot, but don't
        // go back more than 30 days.
        const mostRecent = snapshots[0].snapshotTimestampUsecs / 1000;
        const start = moment().subtract(30, 'days').toDate().getTime();
        const listDateRange = {
          start: moment(Math.min(mostRecent, start)).startOf('day'),
          end: moment().endOf('day'),
        };
        this.filters?.setValue('dateRange', listDateRange);
      });
    }
  }

  /**
   * Determines the info to be displayed for the row based on type.
   *
   * @param   row   local snapshot info of current row
   * @returns Tooltip info.
   */
  getTargetTooltipData(localSnapshot: ObjectSnapshot): string {
    if (this.cloudEditionEnabled) {
      return 'cloud';
    } else {
      return localSnapshot.runType === 'kStorageArraySnapshot' ? 'storageArraySnapshot' : 'local';
    }
  }

  /**
   * This triggers when a specific snapshot id is selected. It clears the selection, and looks up the correct
   * grouped snapshot to select. It uses a temporary, pendingSnapshotId to finish selecting the snapshot in the
   * selection change handler.
   *
   * @param   snapshotId   The snapshot id to select.
   */
  selectSnapshot(snapshotId: string) {
    if (this.snapshotMetadataService.isCleanRoomRecoveryPhase1Enabled
        && this.snapshotMetadata?.recoverability[snapshotId] === false) {
      return false;
    }

    this.selection.clear();
    this.pendingSnapshotId = snapshotId;
    this.snapshots$.pipe(take(1), this.untilDestroy()).subscribe(snapshots => {
      this.selection.select(snapshots.find(group => group.snapshots.find(snapshot => snapshot.id === snapshotId)));
    });
  }

  /**
   * We need to update the control value any time the selection changes. If there is no selection, the form value will
   * be undefined. If there is a pendingSnapshotId, either from an input change, or a button click, the form value
   * will be updated to that specific snapshot, otherwise, it will default to the local snapshot target or the first
   * archive target.
   */
  onSelectionChange() {
    const groupedSnapshot: GroupedObjectSnapshot = this.selection.selected.length && this.selection.selected[0];
    if (!groupedSnapshot) {
      this.value = undefined;
    } else if (this.pendingSnapshotId) {
      this.value = groupedSnapshot.snapshots.find(snapshot => snapshot.id === this.pendingSnapshotId);
    } else {
      this.value = groupedSnapshot.localSnapshot || groupedSnapshot.archiveSnapshots[0] ||
        groupedSnapshot.cloudVaultSnapshots[0];
    }
    this.pendingSnapshotId = null;
  }

  /**
   * Whether to show the tape message for the given row.
   *
   * @param   row   The table row.
   * @returns true if the tape target is selected for this row.
   */
  showTapeMessage(row: GroupedObjectSnapshot): boolean {
    return this.selection.isSelected(row) && this.value?.externalTargetInfo?.targetType === 'Tape';
  }

  /**
   * Retrieves the tag information for the snapshots present in the group
   *
   * @param group group for which the tag information is required
   * @returns list of tags associated with the snapshots in the group
   */
  getSnapshotTags(group: GroupedObjectSnapshot) {
    return this.snapshotMetadataService.getSnapshotTags(group, this.snapshotMetadata);
  }
}
