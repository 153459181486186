import Highcharts from 'highcharts';

/**
 * We have a few 'named' chart series in highcharts that use a specific className to
 * provide colors for a series rather using the series index. Unfortunately, HighCharts
 * does not apply the series classname to the tooltip, which causes the wrong tooltip
 * stroke to be used for the series.
 *
 * This plugin checks for the existence of a series classname, and sets it on the tooltip
 * if it exists, removing previously added classnames as well.
 */
(function (H) {
  H.setOptions({
    tooltip: {
      // The default point format also won't pick up the right color for the series.
      // This version sets highcharts-point on th span, which will let it pick up
      // our color.
      pointFormat:
        '<span class="highcharts-point">' +
        '\u25CF</span> {series.name}: <span class="highcharts-strong">' +
        '{point.y}</span><br/>',
    },
    lang: {
      thousandsSep: ','
    }
  });

  H.wrap(H.Tooltip.prototype, 'refresh', function (proceed, pointOrPoints) {
    // Call the original function
    // eslint-disable-next-line prefer-rest-params
    proceed.apply(this, [].slice.call(arguments, 1));

    const label = this.getLabel();
    const className = H.splat(pointOrPoints)[0].series.options.className;

    if (this.lastClassName) {
      label.removeClass(this.lastClassName);
    }

    // If there is a classname, add it to the label.
    if (className) {
      // save the classname so that we can remove it next time, when a different series is selected.
      this.lastClassName = className;
      label.removeClass(/highcharts-color-[\d]+/g).addClass(className);
    }
  });
})(Highcharts);
