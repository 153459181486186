import { Injectable } from '@angular/core';
import { ProtectionSourceNode, RegisteredSourceInfo } from '@cohesity/api/v1';
import { McmSourceRegistration } from '@cohesity/api/v2';
import { flagEnabled, IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { BehaviorSubject } from 'rxjs';
import { Office365BackupType, Office365ContainerNodeType } from 'src/app/shared/constants';
/**
 * Service for O365 Source details to manage the workload.
 */
@Injectable({
  providedIn: 'root'
})
export class Office365SourceDetailsService {
  /**
   * BehaviorSubject to store the selected O365 workload.
   */
  private workloadSubject = new BehaviorSubject<Office365BackupType>(null);

  /**
   * Observable of current workload.
   */
  readonly workload$ = this.workloadSubject.asObservable();

  /**
   * Behavior Subject to store the OnPrem RegisteredSourceInfo.
   * This holds an instance of v1 API's RegisteredSourceInfo.
   */
  private onPremRegistrationInfoSubject = new BehaviorSubject<RegisteredSourceInfo>(null);

  /**
   * Observable for the RegisteredSourceInfo instance.
   */
  readonly onPremRegistrationInfo$ = this.onPremRegistrationInfoSubject.asObservable();

  /**
   * Behavior Subject to store the Helios Registration Info.
   * This holds an instance of v2 API's McmSourceRegistration.
   */
  private heliosRegistrationInfoSubject = new BehaviorSubject<McmSourceRegistration>(null);

  /**
   * Observable for the McmSourceRegistration instance.
   */
  readonly heliosRegistrationInfo$ = this.heliosRegistrationInfoSubject.asObservable();

  /**
   * Stores the total count of entities within each workload for given source.
   */
  private sourceSummaryCountMap: Map<string, Map<Office365ContainerNodeType | 'total', number>>;

  /**
   * Stores the Domain Node as value corresponding to the Domain ID as the key.
   */
  domainIdToNodeMap: Map<number, ProtectionSourceNode> = new Map<number, ProtectionSourceNode>();

  constructor(
    private irisContextService: IrisContextService
  ) {}
  /**
   * Updates new workload.
   *
   * @param   workload  The newly selected O365 workload.
   */
  setWorkload(workload: Office365BackupType) {
    this.workloadSubject.next(workload);
  }

  /**
   * Gets the current selected O365 workload.
   *
   * @returns   The current workload.
   */
  getWorkload(): Office365BackupType {
    return this.workloadSubject.getValue();
  }

  /**
   * Updates the OnPrem Registration Info which holds the instance of v1 API's
   * RegisteredSourceInfo
   *
   * @param onPremRegistrationInfo Specifies the v1 API RegisteredSourceInfo
   */
  setOnPremRegistrationInfo(onPremRegistrationInfo: RegisteredSourceInfo) {
    this.onPremRegistrationInfoSubject.next(onPremRegistrationInfo);
  }

  /**
   * Gets the current RegisteredSourceInfo for the source which is in view.
   * This will only be set when the user navigates to the sources details for
   * a particular source.
   *
   * @returns The instance of v1 API RegisteredSourceInfo
   */
  getOnPremRegistrationInfo(): RegisteredSourceInfo {
    return this.onPremRegistrationInfoSubject.getValue();
  }

  /**
   * Updates the Helios Registration Info which holds the instance of v2 API's
   * McmSourceRegistration
   *
   * @param mcmSourceRegistration Specifies the v2 API's McmSourceRegistration
   */
  setHeliosRegistrationInfo(mcmSourceRegistration: McmSourceRegistration) {
    this.heliosRegistrationInfoSubject.next(mcmSourceRegistration);
  }

  /**
   * Gets the current McmSourceRegistration for the source which is in view.
   * This will only be set when the user navigates to the sources details for
   * a particular source.
   *
   * @returns The instance of v2 API McmSourceRegistration
   */
  getHeliosRegistrationInfo(): McmSourceRegistration {
    return this.heliosRegistrationInfoSubject.getValue();
  }

  /**
   * Checks the feature flags to determine whether the ability to show the
   * security groups is enabled or not.
   *
   * @returns True iff feature flag is enabled.
   */
  isSecurityGroupsViewEnabled(): boolean {
    if (isDmsScope(this.irisContextService.irisContext)) {
      return flagEnabled(
        this.irisContextService.irisContext, 'dmsOffice365SecurityGroupsViewEnabled');
    }
    return flagEnabled(
      this.irisContextService.irisContext, 'office365SecurityGroupsViewEnabled');
  }

  /**
   * Checks for the support of Security Group within the current workload
   * if the feature flag to support the same is enabled.
   *
   * @returns True iff the current workload supports the Security Group view.
   */
  isSecurityGroupsViewSupportedForCurrentWorkload(): boolean {
    return this.isSecurityGroupsViewEnabled() &&
      [
        Office365BackupType.kMailbox,
        Office365BackupType.kOneDrive
      ].includes(this.getWorkload());
  }

  /**
   * Updates the count of entities by updating the count map.
   *
   * @param countMap Specifies the new instance of the count map
   */
  setSummaryCountMap(countMap: Map<string, Map<Office365ContainerNodeType | 'total', number>>) {
    this.sourceSummaryCountMap = countMap;
  }

  /**
   * @returns The current state of count map.
   */
  getSummaryCountMap(): Map<string, Map<Office365ContainerNodeType | 'total', number>> {
    return this.sourceSummaryCountMap;
  }

  /**
   * Stores a deep copy of the domain node within an in-memory map.
   *
   * @param domainNode Specifies the domain node for M365.
   * @returns void
   */
  storeDomainNodeInCache(domainNode: ProtectionSourceNode) {
    if (!domainNode?.protectionSource?.id) {
      return;
    }

    const minimalDomainNode: ProtectionSourceNode = {
      protectionSource: domainNode.protectionSource,
      nodes: domainNode.nodes,
      registrationInfo: {
        authenticationStatus: domainNode.registrationInfo.authenticationStatus,
        o365Params: domainNode.registrationInfo.o365Params
      }
    };

    // Store a new ref for the object to avoid issues due to modifications
    // by the caller. This is not that costly since only root nodes are
    // stored with no leaves.
    this.domainIdToNodeMap.set(domainNode.protectionSource.id,
      structuredClone(minimalDomainNode));
  }

  /**
   * Deletes the domain node within the internal cache.
   *
   * @param domainId Specifies the domain ID for deletion.
   */
  deleteDomainNodeInCacheById(domainId: number) {
    this.domainIdToNodeMap.delete(domainId);
  }

  /**
   * Returns a deep copy of the domain node.
   *
   * @param domainId Specifies the domain id.
   * @returns root node with the container entities.
   */
  getDomainNodeInCacheById(domainId: number): ProtectionSourceNode {
    if (!domainId) {
      return null;
    }

    // Return a new ref for the object to avoid issues due to modifications
    // by the caller. This is not that costly since only root nodes are
    // stored with no leaves.
    return structuredClone(this.domainIdToNodeMap.get(domainId));
  }
}
