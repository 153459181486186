import { Environment, EnvItems } from '@cohesity/iris-shared-constants';

export const sqlApplicationSources: EnvItems[] = [
  {
    environment: Environment.kSQL,
    hostEnvironment: Environment.kVMware,
  },
  {
    environment: Environment.kSQL,
    hostEnvironment: Environment.kPhysical,
  },
  {
    environment: Environment.kSQL,
    hostEnvironment: Environment.kSQL,
  },
];
