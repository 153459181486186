import { AppConfig } from '../app-configs/models';

/** Base Url for app pillars pages. */
export const appPillarsBaseUrl = '/data-cloud/';

/** Base Url for application pages. */
export const appPillarsAppUrl = '/data-cloud-app/';

/**
 * The top 5 pillars. 'global' is not a pillar but is needed to distinguish from other pillars
 * in the global scope.
 */
export type PillarType = 'protection' | 'security' | 'mobility' | 'access' | 'insights' | 'global' | 'appliance-manager';

/**
 * The applications under the 5 pillars. Global is special case for page redirect
 */
export type AppType =
  | 'applianceManager'
  | 'clusterManager'
  | 'dgaas'
  | 'diaas'
  | 'dms'
  | 'draas'
  | 'global'
  | 'piaas'
  | 'rpaas'
  | 'smartFiles';

/**
 * Pillar type.
 */
export interface Pillar {
  type: PillarType;
  title: string;
  class: string;
  items: PillarItem[];
  /**
   * True if pillar is hidden, else false.
   */
  hidden?: boolean;
}

/**
 * Pillar item (application) type.
 */
export interface PillarItem {
  title: string;
  app: AppType;
  pillar?: PillarType;
  state: string;
  compatibleStates?: string[];
  bannerTitle?: string;
  subtitle?: string;
  descriptions?: string[];
  upgradeUrl?: string;
  disabled?: boolean;
  /**
   * True if pillar is hidden
   */
  hidden?: boolean;

  /**
   * Override key for description on landing page. If not provided, default description
   * will be taken as 'appPillars.description.$item' where $item will
   * be {@link PillarItem.app}.
   */
  titleDescription?: string;
}

/**
 * Application state type.
 */
export interface AppState {
  title: string;
  name?: string;
  params?: Record<string, string>;
  appConfig?: AppConfig;
  compatibleStates?: string[];
  href?: string;
}

/**
 * Pillars information for banners, breadcrumbs and page.
 */
export const pillars: Pillar[] = [
  {
    type: 'protection',
    title: 'protection',
    class: 'pillar-icon-protection',
    items: [
      {
        title: 'dataProtectNoSpace',
        app: 'clusterManager',
        state: 'dashboards.summary',
        compatibleStates: [
          'dashboards.data-protection',
          'dashboards.cloud',
          'security.anti-ransomware',
        ],
        descriptions: [
          'appPillars.description.app.clusterManager.1',
          'appPillars.description.app.clusterManager.2',
          'appPillars.description.app.clusterManager.3',
        ],
        upgradeUrl: 'https://www.cohesity.com/products/dataprotect/',
      },
      {
        title: 'dataProtectAsAService',
        subtitle: 'asAService',
        bannerTitle: 'dataProtectNoSpace',
        app: 'dms',
        state: 'dashboards.dms',
        descriptions: [
          'appPillars.description.app.dms.1',
          'appPillars.description.app.dms.2',
          'appPillars.description.app.dms.3',
        ],
        upgradeUrl: 'https://www.cohesity.com/products/dataprotect-delivered-as-a-service/',
      },
    ],
  },
  {
    type: 'security',
    title: 'security',
    class: 'pillar-icon-security',
    items: [
      {
        title: 'app.securityCenter',
        app: 'dgaas',
        state: 'argus',
        descriptions: [
          'appPillars.description.app.dgaas.1',
          'appPillars.description.app.dgaas.2',
          'appPillars.description.app.dgaas.3',
        ],
        upgradeUrl: 'https://www.cohesity.com/products/datahawk/',
      },
      {
        title: 'app.fortKnox',
        app: 'rpaas',
        state: 'rpaasDashboard.summary',
        descriptions: [
          'appPillars.description.app.rpaas.1',
          'appPillars.description.app.rpaas.2',
          'appPillars.description.app.rpaas.3',
        ],
        upgradeUrl: 'https://www.cohesity.com/products/fortknox/',
      },
    ],
  },
  {
    type: 'mobility',
    title: 'mobility',
    class: 'pillar-icon-mobility',
    items: [
      {
        title: 'app.siteContinuity',
        app: 'draas',
        state: 'dashboards.disaster-recovery',
        descriptions: [
          'appPillars.description.app.draas.1',
          'appPillars.description.app.draas.2',
        ],
        upgradeUrl: 'https://www.cohesity.com/products/sitecontinuity/',
      },
    ],
  },
  {
    type: 'access',
    title: 'access',
    class: 'pillar-icon-access',
    items: [
      {
        title: 'smartFiles',
        app: 'smartFiles',
        state: 'software-upgrade.cluster-list',
        descriptions: [
          'appPillars.description.app.smartFiles.1',
          'appPillars.description.app.smartFiles.2',
          'appPillars.description.app.smartFiles.3',
        ],
        upgradeUrl: 'https://www.cohesity.com/products/smartfiles/',
      },
    ],
  },
  {
    type: 'insights',
    title: 'insights',
    class: 'pillar-icon-insights',
    items: [
      {
        title: 'dataInsights',
        app: 'diaas',
        state: 'gaia',
        compatibleStates: [],
        descriptions: [],
        upgradeUrl: 'https://www.cohesity.com/products/data-cloud/',
      },
      {
        title: 'platformInsights',
        app: 'piaas',
        state: 'simulation.list',
        compatibleStates: [
          'simulation.list',
          'simulation.details',
          'simulation.build',
          'reporting.list',
          'reporting.schedule',
        ],
        descriptions: [
        ],
        upgradeUrl: 'https://www.cohesity.com/products/data-cloud/',
      },
    ],
  },
];

/**
 * Global context pillar item.
 */
export const GLOBAL_PILLAR_ITEM: PillarItem = {
  title: 'global',
  app: 'global',
  pillar: 'global',
  state: 'dashboards.global',
};

/**
 * Appliance Manager context pillar item.
 */
export const APPLIANCE_MANAGER_PILLAR_ITEM: PillarItem = {
  app: 'applianceManager',
  state: 'appliance-manager.dashboard',
  title: 'applianceManager.title',
  pillar: 'appliance-manager',
};

/**
 * App Blank Page and banner content data structure.
 */
export const appList: PillarItem[] = [
  // Global page will not be displayed, but its data is used in banner
  GLOBAL_PILLAR_ITEM,
  APPLIANCE_MANAGER_PILLAR_ITEM,
  ...pillars.map(pillar => pillar.items.map(item => ({
    ...item,
    pillar: pillar.type,
  }))).flat(),
];

/**
 * Type of all helios apps, including 5 pillar static landing pages.
 */
export type HeliosLandingPageApp = AppType | 'cohesityDataCloud';

/**
 * landing page app model.
 */
export interface HeliosLandingPageAppModel { title: string; app: HeliosLandingPageApp };
