// Reports: Failed Objects

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.reports')
    .controller('reportsFailedObjectsController',
      reportsFailedObjectsControllerFn);

  function reportsFailedObjectsControllerFn($scope, $translate, ReportsService, evalAJAX,
    FailedObjectsUtil, ENUM_ENV_TYPE, SOURCE_OBJECT_SCHEDULE_TYPE,
    cReportsControlsUtil, ReportsUtil) {

    $scope.filters = cReportsControlsUtil.filters;
    $scope.getJobSummaries = getJobSummaries;
    $scope.downloadFailedObjectsReport = downloadFailedObjectsReport;

    $scope.failedObjectsConfig =
      ReportsUtil.getReportConfig('kFailedObjectsReport')();

    $scope.ENUM_ENV_TYPE = ENUM_ENV_TYPE;
    $scope.SOURCE_OBJECT_SCHEDULE_TYPE = SOURCE_OBJECT_SCHEDULE_TYPE;

    $scope.objectTypes = [];

    /**
     * Loads Controller Data
     */
    function activate() {
      var defaultFilters = cReportsControlsUtil.getDefaultFilters({
        objectType: { name: 'All' },
        typeAhead: {},
        registeredSourceIds: [],
        jobId: undefined,
        runStatus: ['kBackupJobFailure'],
      });

      getJobSummaries(defaultFilters);
    }

    /**
     * returns params object for api call
     *
     * @param      {object}    filters    filters from c-reports-controls
     *
     * @return     {object}    filter     values to be passed to API
     */
    function getParams(filters) {
      // If a user selects a job we do not need to pass the objectType or
      // sourceId to the API becuase the backend can get these details, from
      // the selected Job. If we do pass these values, with a jobId API will
      // return an error
      if (filters.jobId) {
        filters.objectType.enum = undefined;
        filters.registeredSourceIds = undefined;
      }

      // API only accepts an environment or a registeredSourceId not both;
      // registeredSourceId will always take precedence
      if (filters.objectType.enum && filters.registeredSourceId) {
        filters.objectType.enum = undefined;
      }

      return ReportsService.extendWithTenantParams({
        reportType: $scope.failedObjectsConfig.reportType,
        jobIds: filters.jobId,
        environments: filters.objectType.enum,
        registeredSourceId: filters.registeredSourceId,
        startTimeUsecs: filters.timeObject.from.toUsecDate(),
        endTimeUsecs: filters.timeObject.until.toUsecDate(),
        statuses: filters.runStatus,
        consecutiveFailures: filters.consecutiveFailures,
      });
    }

    /**
     * Gets data needed for controller and calls getSummaryStats()
     * to aggregate data for template
     *
     * @param      {object}    filters    filters from c-reports-controls
     */
    function getJobSummaries(filters) {
      $scope.dataLoading = true;
      $scope.objectTypes.length = 0;

      ReportsService.getProtectionSourceObjects(getParams(filters))
        .then(function getSources(data) {

          $scope.objectTypes = data;

          getSummaryStats();

        }, evalAJAX.errorMessage)
        .finally(function getJobSummariesFinally() {
          $scope.dataLoading = false;
        });
    }

    /**
     * Calls util methods to aggregate data for template
     */
    function getSummaryStats() {
      // Step 1. Group our objects by environment
      var sourceGroups =
        FailedObjectsUtil.groupObjects($scope.objectTypes, 'protectionSource.environment');

      // Step 2. Aggregate stats at the environment level
      $scope.objectTypeStats = FailedObjectsUtil.objectTypeStats(sourceGroups);

      // Step 2a. Aggregate stats for all environments
      $scope.summaryStats =
        FailedObjectsUtil.calculateSummaryStats($scope.objectTypeStats);

      // Step 3. Filter out any sourceGroups that dont have a 'kError'
      // registered source
      var filteredSourceGroups =
        FailedObjectsUtil.filterSourceGroups(sourceGroups);

      // Step 4. Group environment level data by registeredSource
      $scope.registeredSourceGroups =
        FailedObjectsUtil.groupByRegisteredSource(filteredSourceGroups, 'registeredSource');
    }

    /**
     * Initiates CSV download
     */
    function downloadFailedObjectsReport() {
      var params = getParams(cReportsControlsUtil.filters);
      params.outputFormat = 'csv';
      ReportsService.getProtectionSourceObjects(params);
    }

    activate();
  }
})(angular);
