<ng-container *ngIf="validSelectionSummaryItems$ | async as selectionsCount; else noSelections">
  <cog-statlist valueSize="md" itemSpacing="xl">
    <ul *ngIf="inline" class="c-ul-inline no-margin meta-data synopsis">
      <li *ngIf="inlineSourceName">
        {{"Source: " + inlineSourceName}}
      </li>
      <li *ngIf="locationName">
        {{locationName}}
      </li>
      <ng-container *ngFor="let detail of summaryItems">
        <li *ngIf="selectionsCount[detail.property]?.length">
          {{'label' | translate: {label: detail.label | translate} }}
          {{selectionsCount[detail.property].length | number}}
        </li>
      </ng-container>
    </ul>
    <ng-container *ngIf="!inline">
      <ng-container *ngFor="let detail of summaryItems">
        <!-- Show regular non clickable stat items if details is disabled -->
        <cog-statlist-item
          *ngIf="disableDetails && selectionsCount[detail.property]?.length"
          [label]="detail.label | translate">
          {{selectionsCount[detail.property].length | number}}
        </cog-statlist-item>
        <!-- Otherwise show clickable stat list items -->
        <a *ngIf="!disableDetails && selectionsCount[detail.property]?.length"
          (click)="toggleDetailFocus(detail.property)"
          [class.selected-detail]="focusedDetail === detail.property"
          [label]="detail.label | translate"
          cogDataId="protection-group-summary-detail-{{detail.property}}"
          cogStatlistItem>
          {{selectionsCount[detail.property].length | number}}
        </a>
      </ng-container>
    </ng-container>
  </cog-statlist>
  <cog-banner status="info" *ngIf="selectionsCount.overwrite?.length" class="margin-top">
    <p>{{'overwriteProtectionInfo' | translate}}</p>
    <button mat-stroked-button
      color="primary"
      type="button"
      (click)="deselectObjects(selectionsCount.overwrite); $event.stopPropagation()"
      cogDataId="deselect-overwrites">
      {{'deselectProtectedObjects' | translate}}
    </button>
  </cog-banner>
</ng-container>
<div class="detail-container margin-top-lg" *ngIf="!disableDetails && (detailNodes$ | async)?.length">
  <cog-filters #filters>
    <cog-search-property-filter
      *cogFilterDef="let filter;
        key: 'name';
        label: 'name' | translate;
        quickFilter: true;
        filterType: 'searchProperty';"
      [filter]="filter"
      [placeholder]="'search' | translate">
    </cog-search-property-filter>
  </cog-filters>
  <cog-table class="margin-top"
    [source]="detailNodes$ | async"
    name="selection-summary-list"
    [filters]="filters.filterValues$ | async">
    <mat-table>
      <ng-container matColumnDef="detail">
        <mat-cell *matCellDef="let node">
          <ng-container *ngIf="getNodeDetailContext(node) as ctx">
            <div class="detail-wrapper">
              <cog-data-tree-detail-outlet *ngFor="let detail of treeService.detailComponents"
                [component]="detail"
                [nodeContext]="ctx">
              </cog-data-tree-detail-outlet>
            </div>
            <cog-data-tree-detail-outlet class="edit-column"
              *ngIf="!readOnly && treeService.getSpecialParametersComponent(ctx.node) &&
                (ctx.selected || ctx.autoSelected || ctx.ancestorAutoSelected) &&
                !ctx.excluded && !ctx.ancestorExcluded &&
                !isDmsAutoProtectSpecialParamsDisabled(ctx)"
              [component]="treeService.getSpecialParametersComponent(ctx.node)"
              [nodeContext]="ctx">
            </cog-data-tree-detail-outlet>
          </ng-container>
        </mat-cell>
      </ng-container>
      <mat-row *matRowDef="let row; columns: ['detail']"></mat-row>
    </mat-table>
    <mat-paginator></mat-paginator>
  </cog-table>
  <button mat-icon-button
    type="button"
    class="close-button"
    cogDataId="close-summary-detail"
    (click)="toggleDetailFocus(null)">
    <cog-icon shape="close"></cog-icon>
  </button>
</div>

<ng-template #noSelections>
  <cog-statlist *ngIf="showZeroSelections"
    itemSpacing="xl"
    valueSize="md">
    <cog-statlist-item [label]="selectedCountLabel | translate">
      {{ 0 | number }}
    </cog-statlist-item>
  </cog-statlist>
</ng-template>
