import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';
import {
  ShowHideEditControlEditComponent,
  ShowHideEditControlSummaryComponent,
  ShowHideEditControlWrapperComponent,
} from './components';

@NgModule({
  declarations: [
    ShowHideEditControlEditComponent,
    ShowHideEditControlSummaryComponent,
    ShowHideEditControlWrapperComponent,
  ],
  exports: [
    ShowHideEditControlEditComponent,
    ShowHideEditControlSummaryComponent,
    ShowHideEditControlWrapperComponent,
  ],
  imports: [
    CommonModule,
    CohesityHelixModule,
    TranslateModule.forChild(),
  ],
})
export class ShowHideEditControlModule {}
