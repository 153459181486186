import { Component, Input, ViewEncapsulation } from '@angular/core';
import { SeriesPieOptions } from 'highcharts';

import { HighchartsComponent } from '../core/index';

/**
 * Specific data structure used when the pie chart is used to show a percentage.
 * There are some synchronization problem when these data are provided separately.
 * So we put them under the same object.
 */
export interface Percentage {
  /** Percentage value */
  value: number;

  /** Status of pie. When provided, it will set the pie area color based on the status provided. */
  status?: 'success' | 'critical' | 'info-1' | 'info-2' | 'info-3' | 'info-4';
}

/**
 * Pie chart component - very small pie chart, drawn without labels.
 */
@Component({
  selector: 'cog-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PieChartComponent extends HighchartsComponent<SeriesPieOptions> {
  /**
   * If non-default chart colors are desired, provide a colorSetClass for an
   * existing colorSet or define a new set in the stylesheet.
   */
  @Input() colorSetClass: string;

  /**
   * Set pie series data and render chart.
   * If size is provided here, it will override the size above.
   */
  @Input() set percentage(percentage: Percentage) {
    if (percentage.value !== undefined) {
      const {
        value,
        status,
      } = percentage;

      this.clearSeries();
      this.chartOptions.series = [
        {
          type: 'pie',
          data: [
            {
              y: value,
              className: ['success', 'critical', 'warning', 'info-1', 'info-2', 'info-3'].includes(status)
                ? `pie-chart-zone-${status}`
                : undefined,
            },
            {
              y: 100 - value,
              className: 'pie-chart-zone-rest',
            },
          ],
        },
      ];
      this.render();
    }
  }

  constructor() {
    super({
      chart: {
        type: 'pie',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        styledMode: true,
        margin: [-10, -10, -10, -10],
        spacing: [0, 0, 0, 0],
      },

      title: null,

      credits: {
        enabled: false,
      },

      tooltip: {
        enabled: false,
      },

      plotOptions: {
        pie: {
          allowPointSelect: false,
          dataLabels: {
            enabled: false,
          },
          size: '100%',
          showInLegend: false,
        }
      },

      series: []
    });
  }
}
