// Two-Factor Authentication confirmation modal Controller

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.twoFactorAuth', [])
    .controller('TwoFactorAuthController', twoFactorAuthControllerFn);

  function twoFactorAuthControllerFn(_, $uibModalInstance, AuthType, evalAJAX,
    ClusterService) {

    var $ctrl = this;

    _.assign($ctrl, {
      saveAuthSetting: saveAuthSetting,
      cancel: cancel,
    });

    /**
     * update cluster setting
     */
    function saveAuthSetting() {
      ClusterService.updateClusterInfo({ authType: AuthType })
        .then(_restartIris, evalAJAX.errorMessage);
    }

    /**
     * iris must be restarted for updated auth setting to go into effect
     */
    function _restartIris() {
      ClusterService.restartService({
        services: ['iris'],
        clusterId: ClusterService.clusterInfo.id,
      }).then(function restartSuccess() {
        $uibModalInstance.close('save');
      });
    }

    /**
     * close modal
     */
    function cancel() {
      $uibModalInstance.dismiss(AuthType);
    }
  }
})(angular);