import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { SecurityService } from '../../services';
import { AnomalyAlert } from './../../security-shared.models';

/**
 * Component to show information before user proceeds with apply/remove snapshot tag
 */
@Component({
  templateUrl: './anomaly-resolve-confirmation.component.html',
})
export class AnomalyResolveConfirmationComponent extends AutoDestroyable {
  /**
   * Indicates whether we are loading data.
   */
  isSubmitting = false;

  constructor(
    private ajaxService: AjaxHandlerService,
    private dialogRef: MatDialogRef<AnomalyResolveConfirmationComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) private data: AnomalyAlert[],
    private securityService: SecurityService
  ) {
    super();
  }

  /**
   * Handles actions as per table selection
   */
  actionHandler() {
    this.isSubmitting = true;
    combineLatest(this.data.map(anomaly => this.securityService.updateAnomalyState(anomaly, 'Resolved')))
      .pipe(
        this.untilDestroy(),
        finalize(() => {
          this.isSubmitting = false;
        })
      )
      .subscribe(() => this.dialogRef.close(true), this.ajaxService.handler);
  }
}
