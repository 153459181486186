import { InjectionToken } from '@angular/core';

/**
 * Specifics whether the provided url is root route or not.
 */
export type IsRootRoute = (url: string) => boolean;

/**
 * The extra options used to customize the universal routing behavior.
 */
export interface ExtraOptions {
  /** The navigation strategy to use and by default pushState would be used */
  navigationStrategy?: 'assign' | 'pushState';

  /** Specifics whether the provided url is root route or not. */
  isRootRoute: IsRootRoute;
}

export const EXTRA_OPTIONS = new InjectionToken<ExtraOptions>('EXTRA_OPTIONS');
