// Controller: Helios Custom Reports Details

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.heliosReports')
    .component('heliosCustomReports', {
      controller: 'HeliosCustomReportsCtrl',
      templateUrl: 'app/reports/helios/custom-reports.html',
    })
    .controller('HeliosCustomReportsCtrl', HeliosCustomReportsCtrlFn);

  /**
   * Controller for Helios custom reports.
   */
  function HeliosCustomReportsCtrlFn() {

  }
})(angular);