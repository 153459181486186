<ng-container [formGroup]="form.formGroup">
  <mat-form-field class="no-margin-bottom">
    <mat-label> {{ (isTemplateMode ? 'templateName' : 'viewName') | translate }} </mat-label>
    <input [cogDataId]="isTemplateMode ? 'view-template-name-input' : 'view-name-input'"
      formControlName="name"
      matInput>
    <mat-error *ngIf="form.formGroup.invalid"
      [cogDataId]="isTemplateMode ? 'template-name-invalid-error' : 'view-name-invalid-error'">
      {{ 'errors.required' | translate }}
    </mat-error>
  </mat-form-field>

  <cog-banner status="warn" *ngIf="!isTemplateMode && viewNameWarnings?.length">
    <p *ngFor="let namekey of viewNameWarnings"
      cogDataId="view-name-warning-{{namekey}}"
      [innerHtml]="namekey | translate">
    </p>
  </cog-banner>
</ng-container>
