<div *ngIf="tableDataSource" cogDataId="affected-files">
  <h3 *ngIf="!hideSubTitle" cogDataId="header" [inheritDataId]="true">
    {{ (inlineFileDiffSupported ? 'listOfAffectedFiles' : 'sampleOfAffectedFiles') | translate }}
  </h3>

  <cog-filters class="margin-bottom" [showFilterLabel]="true" *ngIf="!hideFilters" (valueChange)="applyFilters($event)">
    <dg-ar-change-type-filter
      [selectedChangeTypes]="affectedFilesFilters?.changeTypes"
      [changeTypeFilters]="changeTypeFilters">
    </dg-ar-change-type-filter>
    <dg-ar-pattern-filter
      *ngIf="showPatternFilter"
      [selectedPatternIds]="affectedFilesFilters?.patternIds"
      (filterReady)="updatePatternFilterReady($event)">
    </dg-ar-pattern-filter>
  </cog-filters>

  <div *ngIf="!hideChangeTypeStats && tableDataSource.changeTypeCounts?.length > 0">
    <dg-ar-change-type-stats [changeTypeStats]="tableDataSource.changeTypeCounts"></dg-ar-change-type-stats>
  </div>

  <!-- Blank card if data is not present -->
  <ng-container *ngIf="
    (tableDataSource?.loadingData$ | async) === false &&
    (tableDataSource?.hasData$ | async) === false &&
    isFilterReady">
    <cog-blank-card></cog-blank-card>
    <p class="subtext" [innerHTML]="'argus.affectedFiles.indexingInProgress' | translate"></p>
  </ng-container>

  <cog-table
    name="filesTable"
    [dataSource]="tableDataSource"
    [hidden]="!isFilterReady || (tableDataSource?.hasData$ | async) === false">
    <table mat-table>
      <ng-container matColumnDef="filename">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'filePath' | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <span class="flex-row-xs vertical-align">
            <cog-icon size="md" shape="insert_drive_file"></cog-icon>
            <span>{{ row.filePath }}</span>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="operation">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'changeType' | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <div
            [ngClass]="'file-change-type-' + row.changeType"
            class="flex-row-xs vertical-align flex-justify-center padding-right-sm">
            <cog-icon
              size="sm"
              [inline]="true"
              [shape]="getChangeTypeIcon(row.iconName)"
              class="flex-row vertical-align flex-justify-center"></cog-icon>
            <span>
              {{ 'argus.enum.fileChangeType.' + row.changeType | translate }}
            </span>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="option">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row; index as i" class="show-on-hover-cell">
          <button
            mat-icon-button
            id="affected-files-action-{{ i }}"
            [dgHasPermissions]="['RESTORE_MODIFY', 'RESTORE_DOWNLOAD']"
            [permissionPredicate]="'hasAll'"
            [matMenuTriggerFor]="menu?.childMenu"
            cogDataId="button-{{ i }}"
            [inheritDataId]="true">
            <cog-icon shape="more_vert"></cog-icon>
          </button>
          <cog-actions-menu
            #menu
            [inheritDataId]="true"
            cogDataId="action-button"
            [itemTemplate]="itemTemplate"
            [navList]="getChangeTypeActions(row.changeType)">
            <ng-content></ng-content>
          </cog-actions-menu>
          <ng-template #itemTemplate let-item>
            <div cogDataId="action-button-{{ i }}-{{ item.displayName }}" (click)="download(item, row)">
              {{ item.displayName | translate }}
            </div>
          </ng-template>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
      <tr mat-row class="c-fade-sm animate-staggered" *matRowDef="let row; columns: displayColumns"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
  </cog-table>

  <cog-spinner *ngIf="!isFilterReady || (tableDataSource?.loadingData$ | async)"></cog-spinner>
</div>
