<div *ngIf="!viewList?.length" class="no-data-container">
  <coh-blank-card
    type="view"
    message="{{ 'deleteSdNoViews'| translate }}">
  </coh-blank-card>
</div>

<div *ngIf="viewList?.length" class="stepper-item-list margin-rop margin-bottom">
  <div *ngFor="let view of viewList" class="c-media c-media-align-center single-item margin-top padding-bottom-sm">
    <cog-icon class="c-media-object" shape="helix:object-view"></cog-icon>
    <div class="c-media-body">
      <div class="flex-truncate-wrapper">
        <div class="flex-truncate-text user-data-xl">
          {{view.name}}
        </div>
      </div>
    </div>
  </div>
</div>
