import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

/**
 * The service provides translation strings for ngx-translate and angualr-translate.
 * The actual logic for loading translations for different locales is handled by
 * LocaleService.
 */
@Injectable({
  providedIn: 'root'
})
export class TranslationLoaderService extends TranslateLoader {
  /**
   * This is the current set of translations. Locale service is responsible
   * for setting this and ensuring that it matches the current language.
   */
  translations: any;

  constructor() {
    super();
  }

  /**
   * Returns the currently configured translations.
   *
   * @returns An observable of the translation object.
   */
  getTranslation(): Observable<any> {
    return of(this.translations);
  }
}
