import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const dataPoolsAccess = (ctx: StateAccessContext) =>
  ctx.clusterInfo?._serviceType === 'draas' || ctx.FEATURE_FLAGS.keplerEnabled;

const dataPoolsDashboardAccess = (ctx: StateAccessContext) =>
  ctx.clusterInfo?._serviceType === 'draas' ||
  (ctx.FEATURE_FLAGS.keplerEnabled && ctx.FEATURE_FLAGS.keplerDashboardEnabled);

export const DataPoolsConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'data-pools.**',
      url: '/data-pools',
      loadChildren: () => import('./data-pools.module').then(m => m.DataPoolsModule),
    },
  ],
  allClusterMap: {
    'data-pools': true,
    'data-pools.list': true,
    'data-pools.build': true,
    'data-pools.drInfo': true,
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'data-pools': dataPoolsAccess,
      'data-pools.list': dataPoolsAccess,
      'data-pools.build': dataPoolsAccess,
      'data-pools.drInfo': dataPoolsDashboardAccess,
    };
  },
};
