<cog-page>
  <cog-page-header [noMargin]="true">
    <cog-page-toolbar
      [backText]="'heliosSecurityAdvisor.backToScanResults' | translate"
      (back)="backClick.emit()">
    </cog-page-toolbar>
  </cog-page-header>

  <cog-page-content *ngIf="!loading; else spinner">
    <cog-page-content-header [title]="summaryDetail?.name"> </cog-page-content-header>

    <!-- Header score detail -->
    <ng-container *ngIf="summaryDetail">
      <cog-statlist horizontal="true" valueSize="md" itemSpacing="sm">
        <cog-statlist-item>
          <dg-pa-icon [scoreValue]="summaryDetail.lowestScore" size="lg">
          </dg-pa-icon>
        </cog-statlist-item>
        <cog-statlist-item class="security-rules" [label]="'score' | translate">
          {{ summaryDetail.lowestScore | number }}
        </cog-statlist-item>
        <cog-statlist-item [label]="'totalClusters' | translate">
          {{ summaryDetail.clustersCount | number }}
        </cog-statlist-item>
        <cog-statlist-item [label]="'totalIssues' | translate">
          {{ summaryDetail.issuesCount | number }}
        </cog-statlist-item>
      </cog-statlist>

      <!-- Binding the issue table -->
      <div class="margin-top-lg">
        <dg-pa-list-issues-table
          [loading]="loading"
          [issueData]="summaryDetail?.issues">
        </dg-pa-list-issues-table>
      </div>
    </ng-container>
  </cog-page-content>

  <!-- Loading Template -->
  <ng-template #spinner>
    <cog-spinner></cog-spinner>
  </ng-template>
</cog-page>
