import { Injectable } from '@angular/core';
import { RestoreTasksServiceApi, TimeRangeSettings, UniversalId } from '@cohesity/api/v1';
import { IrisContextService, flagEnabled, isDmsScope } from '@cohesity/iris-core';
import { UIRouterGlobals } from '@uirouter/core';
import { clamp, flatMap } from 'lodash';
import moment, { Moment } from 'moment';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, map } from 'rxjs/operators';
import { Environment, JobRunType, Office365CSMBackupTypes } from 'src/app/shared';

import { SnapshotSearchService } from './snapshot-search.service';

/**
 * List of environment types whitelisted for PITR on DMaaS.
 */
const allowedDmsPITREnvironments = [
  Environment.kOracle,
  Environment.kSQL,
  Environment.kRDSSnapshotManager,
  Environment.kAuroraSnapshotManager,
  Environment.kUDA,
  Environment.kSAPHANA,
];

/**
 * An interface for bulk setting the service fields.
 */
export interface SnapshotSelectorFields {
  /**
   * The currently selected date.
   */
  currentDate?: Moment;

  /**
   * The currently selected Protection Group ID.
   */
  protectionGroupId?: string;

  /**
   * The currently selected Protection Source ID.
   */
  protectionSourceId?: number;

  /**
   * The ID for fetching time ranges of currently selected object.
   */
  timeRangesId?: number;

  /**
   * The currently selected Environment.
   */
  environment?: Environment;

  /**
   * Specifies the backup type(s) for fetching object snapshot(s).
   */
  objectActionKeys?: Environment[];

  /**
   * If specified, causes the indexed snapshot API to be used to fetch the list
   * of snapshots.
   */
  indexedObjectName?: string;

  /**
   * Whether only indexed snapshots are to be fetched for the indexed object.
   * This field only makes sense when indexedObjectName is set.
   */
  indexedSnapshotsOnly?: boolean;

  /**
   * If specified, then these will be passed to the API to get only specific
   * types of snapshots.
   */
  runTypes?: JobRunType[];

  /**
   * Fetch snapshots from this time.
   */
  fromTimeUsecs?: number;

  /**
   * Fetch snapshots till this time.
   */
  toTimeUsecs?: number;
}

/**
 * Helper service for snapshot selection. This service needs to be provided
 * inside the component to initialize the context and use the underlying logic.
 */
@Injectable()
export class SnapshotSelectorService {
  /**
   * Optional region id to get sources from. This is set from the
   * region id state param if present.
   */
  get regionId(): string {
    return this.uiRouterGlobals?.params?.regionId;
  }

  /**
   * An observable to indicate whether the full/incremental snapshots are
   * being loaded from API.
   */
  protected snapshotsLoading$ = this.snapshotService.searchPending$;

  /**
   * A subject which yields a value to indicate whether the point-in-time
   * ranges are being loaded from API.
   */
  protected timeRangesLoading$ = new BehaviorSubject<boolean>(false);

  /**
   * A subject which yields an array of all the available point-in-time ranges.
   */
  protected timeRangesSubject$ = new BehaviorSubject<TimeRangeSettings[]>([]);

  /**
   * A subject which yields the currently selected date. If this is set to a
   * valid value, currentDateSnapshots$ and currentDateTimeRanges$ yield
   * their values filtered by this date.
   */
  private currentDateSubject$ = new BehaviorSubject<Moment>(null);

  /**
   * A subject which yields the currently set Protection Group ID.
   */
  protected protectionGroupId$ = new BehaviorSubject<string>(null);

  /**
   * A subject which yields the currently set Protection Source ID.
   */
  protected protectionSourceId$ = new BehaviorSubject<number>(null);

  /**
   * A subject which yields the currently set ID for the object's time ranges.
   */
  protected timeRangesId$ = new BehaviorSubject<number>(null);

  /**
   * A subject which yields the currently set Environment.
   */
  protected environment$ = new BehaviorSubject<Environment>(null);

  /**
   * A subject which yields the backup type for the given object snapshot.
   */
  protected objectActionKeys$ = new BehaviorSubject<Environment[]>(null);

  /**
   * A subject which yields the name of the indexed object. This should only
   * be set when indexedSnapshotMode is true.
   */
  private indexedObjectName$ = new BehaviorSubject<string>(null);

  /**
   * A subject which yields a value to indicate whether to fetch only the
   * indexed snapshots for the specified indexed object.
   */
  private indexedSnapshotsOnly$ = new BehaviorSubject<boolean>(null);

  /**
   * A subject which yields a value to indicate whether some specific types
   * of runs to be fetched using the snapshot API. If this yields null or an
   * empty array, then the runTypes filter is not applied in the API call.
   */
  protected runTypes$ = new BehaviorSubject<JobRunType[]>(null);

  /**
   * A subject which yields a value to fetch snapshots from this time.
   */
  protected fromTimeUsecs$ = new BehaviorSubject<number>(null);

  /**
   * A subject which yields a value to fetch snapshots till this time.
   */
  protected toTimeUsecs$ = new BehaviorSubject<number>(null);

  /**
   * Specifies whether to use Optimized Snapshot selector view.
   */
  optimizedSnapshotSelector = false;

  /**
   * Specifies whether the current flow is CSM based for M365.
   */
  isM365CsmWorkflow = false;

  /**
   * An observable to indicate whether the data (snapshots or point-in-time
   * ranges) is being loaded from API.
   */
  readonly dataLoading$ = combineLatest([this.snapshotsLoading$, this.timeRangesLoading$]).pipe(
    map(([snapshotsLoading, timeRangesLoading]) => snapshotsLoading || timeRangesLoading),
    distinctUntilChanged()
  );

  /**
   * An observable of the list of all the available full/incremental snapshots.
   */
  readonly snapshots$ = this.snapshotService.groupedSearchResults$;

  /**
   * An observable of the list of all the available point-in-time ranges.
   */
  readonly timeRanges$ = this.timeRangesSubject$.asObservable();

  /**
   * An observable of a set of active dates derived using the list of
   * snapshots and time ranges. The value can directly be used with the
   * calendar component.
   */
  readonly activeDates$ = combineLatest([this.snapshots$, this.timeRanges$]).pipe(
    map(
      ([snapshots, ranges]) =>
        new Set(
          [
            ...snapshots.map(snapshot => moment(snapshot.snapshotTimestampUsecs / 1000)),
            ...flatMap(ranges, range => this.enumerateDaysInRange(range.startTimeUsecs, range.endTimeUsecs)),
          ].map(date => date.startOf('day').valueOf())
        )
    )
  );

  /**
   * An observable of the currently selected date.
   */
  readonly currentDate$ = this.currentDateSubject$.asObservable();

  /**
   * An observable of the list of the full/incremental snapshots belonging to
   * the currently selected date. If the current date is not specified, then
   * this returns the list of all the snapshots.
   */
  readonly currentDateSnapshots$ = combineLatest([this.currentDate$, this.snapshots$]).pipe(
    map(([currentDate, snapshots]) =>
      snapshots.filter(snapshot => {
        if (!currentDate) {
          return true;
        }

        return moment(snapshot.snapshotTimestampUsecs / 1000).isSame(currentDate, 'day');
      })
    )
  );

  /**
   * An observable of the list of the point-in-time ranges belonging to the
   * currently selected date. If the current date is not specified, then this
   * returns the list of all point-in-time ranges.
   */
  readonly currentDateTimeRanges$ = combineLatest([this.currentDate$, this.timeRanges$]).pipe(
    map(([currentDate, timeRanges]) => {
      if (!currentDate) {
        return timeRanges;
      }

      const dayStartUsecs = currentDate.clone().startOf('day').valueOf() * 1000;
      const dayEndUsecs = currentDate.clone().endOf('day').valueOf() * 1000;

      return (
        timeRanges
          // Filter out the time ranges which do not cover the chosen day.
          .filter(
            range =>
              currentDate.isSameOrAfter(moment(range.startTimeUsecs / 1000), 'day') ||
              currentDate.isSameOrBefore(moment(range.endTimeUsecs / 1000), 'day')
          )

          // Clamp the timestamps to the chosen day.
          .map(timeRange => ({
            ...timeRange,
            startTimeUsecs: clamp(timeRange.startTimeUsecs, dayStartUsecs, dayEndUsecs),
            endTimeUsecs: clamp(timeRange.endTimeUsecs, dayStartUsecs, dayEndUsecs),
          }))
      );
    })
  );

  constructor(
    protected snapshotService: SnapshotSearchService,
    protected restoreService: RestoreTasksServiceApi,
    protected irisCtx: IrisContextService,
    protected uiRouterGlobals: UIRouterGlobals,
  ) {
    this.optimizedSnapshotSelector = flagEnabled(this.irisCtx.irisContext, 'optimizedPITRecovery');
    this.isM365CsmWorkflow = Office365CSMBackupTypes.includes(
      this.uiRouterGlobals.params.office365WorkloadType);
    this.setupSubscriptions();
  }

  /**
   * Sets a new value of the collection of all the available point-in-time
   * ranges.
   */
  set timeRanges(timeRanges: TimeRangeSettings[]) {
    this.timeRangesSubject$.next(timeRanges);
  }

  /**
   * Sets a new value of the currently selected date.
   */
  set currentDate(date: Moment) {
    this.currentDateSubject$.next(date);
  }

  /**
   * Sets a new value of the current Protection Group ID.
   */
  set protectionGroupId(id: string) {
    this.protectionGroupId$.next(id);
  }

  /**
   * Sets a new value of the current Protection Source ID.
   */
  set protectionSourceId(id: number) {
    if (this.environment$.value === Environment.kOracle) {

      // Currently id is a derived unique entity wrt oracle hence adding
      // this conversion here.
      this.protectionSourceId$.next(Number(id.toString().split(':')[0]));
      return;
    }
    this.protectionSourceId$.next(id);
  }

  set timeRangesId(timeRangesId: number) {
    this.timeRangesId$.next(timeRangesId);
  }

  /**
   * Sets a new value of the current Environment.
   */
  set environment(environment: Environment) {
    this.environment$.next(environment);
  }

  /**
   * Sets a new value for the list of object action keys.
   */
  set objectActionKeys(objectActionKeys: Environment[]) {
    this.objectActionKeys$.next(objectActionKeys);
  }

  /**
   * Sets a new value of the Indexed Object Name.
   */
  set indexedObjectName(objectName: string) {
    this.indexedObjectName$.next(objectName);
  }

  /**
   * Sets a new value of the Indexed Snapshots only flag.
   */
  set indexedSnapshotsOnly(value: boolean) {
    this.indexedSnapshotsOnly$.next(value);
  }

  /**
   * Sets a new value of the Snapshot Run Types.
   */
  set runTypes(runTypes: JobRunType[]) {
    this.runTypes$.next(runTypes);
  }

  /**
   * Sets a new value for fromTimeUsecs.
   */
  set fromTimeUsecs(fromTimeUsecs: number) {
    this.fromTimeUsecs$.next(fromTimeUsecs);
  }

  /**
   * Sets a new value for toTimeUsecs.
   */
  set toTimeUsecs(toTimeUsecs: number) {
    this.toTimeUsecs$.next(toTimeUsecs);
  }

  /**
   * A convenience method to set a bunch of fields at once instead of calling
   * individual setters.
   */
  setFields(fields: SnapshotSelectorFields) {
    for (const [fieldName, value] of Object.entries(fields)) {
      Reflect.set(this, fieldName, value);
    }
  }

  /**
   * Sets up various observable subscriptions.
   */
  setupSubscriptions() {
    if (isDmsScope(this.irisCtx.irisContext)) {
      // Listen to the changes in protection source or
      // environment, and fetch all the time ranges for DMaaS.
      combineLatest([this.protectionSourceId$, this.environment$])
        .pipe(
          debounceTime(0),
          filter(
            ([protectionSourceId, environment]) =>
              !!protectionSourceId && !!environment
          )
        )
        .subscribe(([, environment]) => {
          if (allowedDmsPITREnvironments.includes(environment)) {
            this.dmsFetchTimeRanges();
          }
        });
    }

    // Listen to the changes in protection source, protection group or
    // environment, and fetch all the time ranges.
    combineLatest([this.protectionSourceId$, this.protectionGroupId$, this.environment$])
      .pipe(
        debounceTime(0),
        filter(
          ([protectionSourceId, protectionGroupId, environment]) =>
            !!protectionSourceId && !!protectionGroupId && !!environment
        )
      )
      .subscribe(() => this.fetchTimeRanges());

    // Listen to the changes in protection source, protection group, indexed
    // object name or indexed snapshots only flag, and fetch snapshots.
    combineLatest([
      this.protectionSourceId$,
      this.protectionGroupId$,
      this.indexedObjectName$,
      this.indexedSnapshotsOnly$,
    ])
      .pipe(
        debounceTime(0),

        // ProtctionGroupId is optional, and is never set for protected objects.
        // It should not be filtered here.
        filter(([protectionSourceId]) => !!protectionSourceId)
      )
      .subscribe(() => this.fetchSnapshots());
  }

  /**
   * Calls the method to fetch all the available point-in-time ranges for 24 hrs.
   */
  fetchDayTimeRanges() {
    this.fetchTimeRanges();
  }

  /**
   * Calls the API to fetch all the available point-in-time ranges, and updates
   * timeRanges$ with the data.
   */
  protected fetchTimeRanges() {
    const protectionGroupId = this.protectionGroupId$.value;
    const [clusterId, clusterIncarnationId, id] = protectionGroupId.split(':').map(Number);
    const jobUid: UniversalId = { clusterId, clusterIncarnationId, id };

    // For NoSQL adapters, timeRangesId is already equal to the id of an entity so the split on ":" is
    // not needed, but we do it all the same instead of adding adapter-specific logic, as it will be a NOP.
    const protectionSourceId = Number(String(this.timeRangesId$.value).split(':')[0]);

    const params: RestoreTasksServiceApi.GetRestorePointsForTimeRangeParams = {
      body: {
        jobUids: [jobUid],
        environment: this.environment$.value as any,
        protectionSourceId: protectionSourceId,
        ...this.snapshotService.getPointsForTimeRange(),
      },
    };

    this.timeRangesLoading$.next(true);

    this.restoreService
      .GetRestorePointsForTimeRange(params)
      .pipe(finalize(() => this.timeRangesLoading$.next(false)))
      .subscribe(
        response => (this.timeRanges = response.timeRanges || []),
        () => (this.timeRanges = [])
      );
  }

  /**
   * Triggers a call to the snapshot service to fetch the snapshots of the
   * specified protection source.
   */
  protected fetchSnapshots() {
    if (this.indexedObjectName$.value) {
      return this.fetchIndexedSnapshots();
    }

    const runTypes = this.runTypes$.value;

    this.snapshotService.getSnapshots({
      objectId: this.protectionSourceId$.value,
      protectionGroupId: this.protectionGroupId$.value,
      objectActionKeys: this.objectActionKeys$.value,
      fromTimeUsecs: this.fromTimeUsecs$.value,
      toTimeUsecs: this.toTimeUsecs$.value,
      ...(runTypes?.length ? { runTypes } : {}),
    });
  }

  /**
   * Triggers a call to the snapshot service to fetch the snapshots for the
   * indexed object.
   */
  private fetchIndexedSnapshots() {
    this.snapshotService.getIndexedSnapshots({
      objectId: this.protectionSourceId$.value,
      protectionGroupId: this.protectionGroupId$.value,
      indexedObjectName: this.indexedObjectName$.value,
      includeIndexedSnapshotsOnly: this.indexedSnapshotsOnly$.value,
      objectActionKeys: this.objectActionKeys$.value,
    });
  }

  /**
   * Enumerates days based on startTimeUsecs and endTimeUsecs for PIT range.
   *
   * @param    startTimeUsecs  Start time in usecs.
   * @param    endTimeUsecs    End time in usecs.
   * @returns  Array of days span from startTime to endTime in moment format.
   */
  private enumerateDaysInRange(startTimeUsecs: number, endTimeUsecs: number): Moment[] {
    const now = moment(startTimeUsecs / 1000).startOf('day').clone();
    const endDate = moment(endTimeUsecs / 1000).startOf('day');
    const days: Moment[] = [];

    while (now.isSameOrBefore(endDate)) {
      days.push(now.clone());
      now.add(1, 'days');
    }

    return days;
  }

  /**
   * Calls the API to fetch all the available point-in-time ranges, and updates
   * timeRanges$ with the data on DMaaS.
   */
  private dmsFetchTimeRanges() {
    const protectionSourceId = Number(String(this.timeRangesId$.value).split(':')[0]);

    const params: RestoreTasksServiceApi.GetRestorePointsForTimeRangeParams = {
      body: {
        jobUids: [{}],
        environment: this.environment$.value as any,
        protectionSourceId: protectionSourceId,
        ...this.snapshotService.getPointsForTimeRange(),
      },
      regionId: this.regionId,
    };

    this.timeRangesLoading$.next(true);

    this.restoreService
      .GetRestorePointsForTimeRange(params)
      .pipe(finalize(() => this.timeRangesLoading$.next(false)))
      .subscribe(
        response => (this.timeRanges = response.timeRanges || []),
        () => (this.timeRanges = [])
      );
  }
}
