import { forEach } from 'lodash';
import { ValueFilterSelection } from '@cohesity/helix';
import { enumGroupMap, Environment, envGroups } from '@cohesity/iris-shared-constants';

/**
 * Returns environment group structure based on a list of environments.
 * Use for filter options.
 *
 * @param    environments  list of environments.
 * @param    translateFn   Translate function.
 * @returns  Environment group structure.
 */
export function getEnvGroups(
  environments: Environment[],
  translateFn: (key: string, params?: any) => string,
): ValueFilterSelection[] {
  // hash for envGroup to store envGroup based on environment.
  const envGroupMap: any = {};

  (environments || []).forEach(environment => {
    const envGroup = enumGroupMap[environment];

    if (!envGroupMap[envGroup]) {
      envGroupMap[envGroup] = {} as any;
    }
    envGroupMap[envGroup][environment] = true;
  });

  const result: ValueFilterSelection[] = [];

  // Create data structure based on the order in envGroups so that
  // the order of envGroup and environment is always consistent.
  forEach(envGroups, (enums: string[], key: string) => {
    const envMap = envGroupMap[key];

    if (envMap) {
      const orderedEnvList = [] as any[];

      enums.forEach(val => {
        if (envMap[val]) {
          orderedEnvList.push(val);
        }
      });

      if (orderedEnvList.length === 1) {
        result.push({
          label: translateFn(`enum.environment.${orderedEnvList[0]}`),
          value: orderedEnvList[0],
        });
      } else {
        result.push({
          value: key,
          label: translateFn(`enum.envGroup.longName.${key}`),
          subItems: orderedEnvList.map(env => {
            let labelKey = `enum.environment.${env}`;

            // Need to use different label 'EC2' for filter.
            if (env === 'kAWS') {
              labelKey += '.filter';
            }
            return {
              label: translateFn(labelKey),
              value: env,
            };
          }),
        });
      }
    }
  });

  return result;
}
