import { HookMatchCriteria, HookResult, TargetState, Transition } from '@uirouter/core';
import { Observable } from 'rxjs';

/**
 * This enum defines the priority to run each guard at. Higher numbers execute before lower numbers.
 */
export enum GuardPriority {
  /**
   *
   */
  SafeTransition,

  /**
   * App guards should run at a lower level priority after anything related to the app bootstrap or
   * login processes have loaded.
   */
  App,

  /**
   * Context guard ensures that we have a valid iris context. Should run just before App guards
   */
  IrisContext,

  /**
   * Impersonation and SwithAccount guards
   */
  ImpersonationOrSwitch,

  /**
   * UserGuard waits until user information is loaded
   */
  User,

  /**
   * Checks if the requested state can be accessed
   */
  StateAccessGuard,

  /**
   * Authentication gurad needs to run before impersonation and user
   */
  ScopeSelection,

  /**
   * Allows loading the single SPA applications.
   */
  SingleSpaApp,

  /**
   * onStart app-ajs/app.js should run before user info guard
   */
  AjsApp,

  /**
   * Banner guard should make sure any banner needs to be shown to the user after bootstrapping.
   */
  Banner,

  /**
   * Bootstrap happens as the highest priority. It loads basic cluster info and locale settings.
   */
  Bootstrap,
}

/**
 * This extends hook result to include observable types. The State guard service will convert the result to a promise
 * if necessary.
 */
export type GuardResult = HookResult | Observable<boolean | TargetState>;

/**
 * A state guard interface.
 */
export interface StateGuard {
  /**
   * The priority to run the guard at.
   */
  guardPriority: GuardPriority;

  /**
   * Optional match criterial to apply the state guard to.
   */
  matchCriteria?: HookMatchCriteria;

  /**
   * This method runs on each guard transition and can control access to a state or ensure that data is loaded.
   *
   * @param   transition   The current state transition.
   * @returns The result of the state guard.
   */
  onStart(transition: Transition): GuardResult;
}

// Routes that don't need user info
export const StaticRoutes = [
  'login',
  'login-ng',
  'forbidden',
  'unauthorized',
  'logout',
  'logout-ng',
  'mfa.setup',
  'mfa.verify',
  'helios-onprem-mfa',
  'helios-onprem-mfa-verify',
  'change-password',
  'self-service-portal.auth',
  'self-service-portal.m365'
];
