import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

/**
 * Settings list item to display a toggle with custom label
 */
@Component({
  selector: 'coh-settings-list-toggle',
  templateUrl: './settings-list-toggle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsListToggleComponent implements OnInit {
  /**
   * The form control for the settings list item.
   */
  @Input() control: UntypedFormControl;

  /**
   * Label of the settings list item.
   */
  @Input() label: string;

  /**
   * Label of the toggle.
   */
  @Input() toggleLabel: string;

  /**
   * Label when value is true.
   */
  @Input() trueLabel = 'on';

  /**
   * Label when value is false.
   */
  @Input() falseLabel = 'off';

  /**
   * Indicates if the toggle should be in "read only" mode.
   */
  @Input() readOnly = false;

  /**
   * Component init.
   */
  ngOnInit() {
    if (!this.control.value) {
      this.control.setValue(false);
    }
  }
}
