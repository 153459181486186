<cog-table name="reovery-objects-table"
  [source]="(subTaskDetails$ | async) || []">

  <table mat-table
    [trackBy]="trackById">

    <!-- Start time -->
    <ng-container matColumnDef="startTime">
      <th cogDataId="object-creation-time-col" mat-header-cell *matHeaderCellDef>
        {{ 'startTime' | translate }}
      </th>
      <td mat-cell *matCellDef="let subTask">
        <div class="cog-table-object" (click)="openActivityModal(subTask)">
          {{ subTask.startTimeUsecs | cohDate }}
        </div>
      </td>
    </ng-container>

    <!-- Duration -->
    <ng-container matColumnDef="duration">
      <th cogDataId="object-duration-time-col" mat-header-cell *matHeaderCellDef>
        {{ 'duration' | translate }}
      </th>
      <td mat-cell *matCellDef="let subTask">
        <div class="cog-table-object">
          {{ subTask.duration | humanizeDuration }}
        </div>
      </td>
    </ng-container>

    <!-- Run Status -->
    <ng-container matColumnDef="status">
      <th cogDataId="object-status-col" mat-header-cell *matHeaderCellDef>
        {{ 'status' | translate }}
      </th>
      <td mat-cell *matCellDef="let subTask">
          <div>{{ 'enum.migrationRunStatus.' + subTask.status | translate }}</div>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"
      [cogDataId]="'object-anchor-' + (row.name | lowercase)"></tr>

    <!-- NoData Footer -->
    <ng-container matColumnDef="noData">
      <td [attr.colspan]="columns.length" mat-footer-cell *matFooterCellDef>
        {{ 'noObjectsFound' | translate }}
      </td>
    </ng-container>

    <tr class="nodata-row" mat-footer-row [class.hidden]="subTaskDetails?.length" *matFooterRowDef="['noData']"></tr>
  </table>
  <mat-paginator></mat-paginator>
</cog-table>
