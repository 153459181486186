import { AfterViewInit, Directive, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AttributeFilter } from '@cohesity/api/reporting';
import { FilterDefDirective, FiltersComponent, TranslateFn, ValueFilterSelection } from '@cohesity/helix';
import { AutoDestroyable } from '@cohesity/utils';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { filterLabelMap } from './filters-labels';

/**
 * Find matching filter option values from initial value.
 *
 * @param filterValues  All option values for the filter.
 * @param value         Option values to be matched.
 * @return Matched filter option values.
 */
function findFilterValues(
  filterValues: ValueFilterSelection[],
  value: string[],
): ValueFilterSelection[] {
  const result: ValueFilterSelection[] = [];
  for (let i = 0; i < (filterValues?.length); i++) {
    if (value?.includes(filterValues[i].value)) {
      result.push(filterValues[i]);
    }

    const subItems = findFilterValues(filterValues[i].subItems, value);
    if (subItems) {
      result.push(...subItems);
    }
  }
  if (result.length) {
    return result;
  }
  return null;
}

/**
 * Interface for report filter items to use.
 */
export interface ReportFilterComponent<FilterValueType = any> {
  /**
   * The property name for the filter. This is the param value that will be passed to the api.
   */
  property: string;

  /**
   * If the filter is set to readonly, it should not make _any_ api calls or allow
   * a user to change it's value. It should also hide itself if it does not have a value.
   */
  readOnly: boolean;

  /**
   * Translate function to use for report items.
   */
  translate: TranslateFn;

  /**
   * Supported user contexts
   */
  supportedContexts: string[];

  /**
   * True if filters are used for scheduling report.
   */
  isScheduleFilter: boolean;

  /**
   * True if filters are used for on demand report during schedule.
   */
  isOnDemand: boolean;

  /**
   * Initial values.
   */
  initValues?: string[];

  /**
   * Initialized with initValue if true.
   */
  initialized?: boolean;

  /**
   * Button is clicked to create report instead of setting filter options.
   */
  createReport?: EventEmitter<any>;

  /**
   * Convert the filter value to a property suitable for the api
   *
   * @param filterValues The current filter values.
   * @returns An api value.
   */
  toApiValue(filterValues: FilterValueType): AttributeFilter;

  /**
   * Construct the filter values from the api value
   *
   * @param apiValue The api value
   * @returns The filter values.
   */
  fromApiValue(apiValue: AttributeFilter): FilterValueType;
}

/**
 * Base implementation for report filter items that use value filters (vs, date, etc...)
 */
@Directive()
export abstract class BaseReportFilterComponent extends AutoDestroyable
  implements AfterViewInit, OnInit, OnDestroy, ReportFilterComponent<ValueFilterSelection[]> {

  /**
   * Label for the filter.
   */
  label: string;

  /**
   * Whether the user can edit the report.
   */
  @Input() allowEdit = false;

  /**
   * If the filter is set to readonly, it should not make _any_ api calls or allow
   * a user to change it's value. It should also hide itself if it does not have a value.
   */
  @Input() readOnly = false;

  /**
   * The available options to choose from in the value filter.
   */
  filterOptions$ = new BehaviorSubject<ValueFilterSelection[]>([]);

  /**
   * Translate function to use for report items.
   */
  @Input() translate: TranslateFn;

  /**
   * Initial value.
   */
  @Input() initValues: string[];

  /**
   * Filter options initialized if true.
   */
  initialized = false;

  /**
   * Supported user contexts
   */
  @Input() supportedContexts: string[];

  /**
   * True if filters are used for scheduling report.
   */
  @Input() isScheduleFilter: boolean;

  /**
   * True if filters are used for on demand report during scheduling report.
   */
  @Input() isOnDemand: boolean;

  /**
   * Button is clicked to create report instead of setting filter options.
   */
  @Output() createReport: EventEmitter<any>;

  /**
   * The filter def directive for the filter
   */
  @ViewChild(FilterDefDirective, { static: true }) filterDef: FilterDefDirective;

  constructor(
    protected filters: FiltersComponent,
    readonly property: string,
    readonly asyncSearch = false,
  ) {
    super();
    this.label = filterLabelMap[property] || '';
  }

  /**
   * Fetch the values for the report.
   *
   * @returns   The filter values to display in the value property filter.
   */
  abstract getFilterValues(): Observable<ValueFilterSelection[]>;

  /**
   * Convert the filter value to a property suitable for the api
   *
   * @param filterValues The current filter values.
   * @returns An api value.
   */
  abstract toApiValue(filterValues: ValueFilterSelection[]): AttributeFilter;

  /**
   * Construct the filter values from the api value
   *
   * @param apiValue The api value
   * @returns The filter values.
   */
  abstract fromApiValue(apiValue: AttributeFilter): ValueFilterSelection[];

  /**
   * Fetch objects and update the filter options behavior subject with the result.
   */
  fetchObjects() {
    this.getFilterValues()
      .pipe(this.untilDestroy())
      .subscribe(filterValues => {
        this.filterOptions$.next(filterValues);
        if (!this.initialized) {
          const matchedValues = findFilterValues(filterValues, this.initValues);

          if (matchedValues) {
            this.initialized = true;

            // Without setTimeout, filters with hardcoded options will not work.
            setTimeout(() => this.filterDef?.filter?.setValue(matchedValues));
          }
        }
      });
  }

  ngOnInit() {
    if (this.filters && this.filterDef) {
      this.filters.addFilterDef(this.filterDef);
    }
    if (this.translate && !this.readOnly && !this.asyncSearch) {
      this.fetchObjects();
    }
  }

  ngAfterViewInit() {
    if (this.filters && this.filterDef && !this.asyncSearch) {
      // Filters are hidden if there are no available options and there is not a value set for them.
      combineLatest([this.filterDef.filter.currentValue$.pipe(startWith(null as any)), this.filterOptions$]).pipe(
        map(([value, options]) => !value && !options?.length),
        this.untilDestroy(),
      ).subscribe(hide => {
        this.filterDef.hidden = hide;
      });
    }
  }

  ngOnDestroy() {
    if (this.filters && this.filterDef) {
      this.filters.removeFilterDef(this.filterDef);
    }
    super.ngOnDestroy();
  }


  /**
   * Returns true if MCM scope is included.
   */
  get includeMcmScope(): boolean {
    return this.supportedContexts?.includes('MCM');
  }

  /**
   * Returns true if DMaaS scope is included.
   */
  get includeDmsScope(): boolean {
    return this.supportedContexts?.includes('DMaaS');
  }

  /**
   * Returns label with system name if system name is defined.
   *
   * @param    label       Filter label.
   * @param    systemName  System Name.
   * @returns  Label with system name.
   */
  getLabelWithSystemName(label: string, systemName: string): string {
    if (systemName) {
      return `${label} (${systemName})`;
    }
    return label;
  }
}
