import { Pipe, PipeTransform } from '@angular/core';
import { find, isNumber } from 'lodash';

/**
 * @ngdoc Pipe
 * @description
 *   converts numbers to humanized string.
 *
 * @example
 *   {{500000 | humanizeFromNumbers}} => '500k'
 *   {{5500000 | humanizeFromNumbers}} => '5M'
 *   {{5500000 | humanizeFromNumbers:1}} => '5.5M'
 */

@Pipe({
  name: 'humanizeFromNumbers',
})
export class HumanizeFromNumbersPipe implements PipeTransform {
  // map containing prefix and length
  readonly prefixMap = [
    { prefix: 'T', length: 13 },
    { prefix: 'B', length: 10 },
    { prefix: 'M', length: 7 },
    { prefix: 'k', length: 4 },
  ];

  /**
   * Transforms input value to more redable format.
   * can range upto billion and also supports decimals.
   *
   * @param   input   value to transform
   * @param   decimals  decimal point
   * @return  The string description of input with prefix
   */
  transform(input: number, decimals: number = 0): string {

    if (!isNumber(input)) {
      return '-';
    }

    // sanitize input
    decimals = Math.max(decimals, 0);

    // support for sign
    const sign = input < 0 ? '-' : '';

    // get unsigned value of input
    const unsignedInput = Math.abs(input);
    const unsignedInputStr = String(unsignedInput);
    const inputLen = unsignedInputStr.length;

    // small numbers
    if (unsignedInput < 1000) {
      return `${sign}${unsignedInputStr}`;
    }

    // find nearest prefix
    const { prefix, length } = find(this.prefixMap, property => inputLen >= property.length);

    // number split index
    const decimalIndex = inputLen - length + 1;

    // convert number string to array
    const unsignedNumChars = unsignedInputStr.split('');

    // split whole part
    let wholePart = unsignedNumChars.slice(0, decimalIndex).join('');

    // Get decimal part only if required
    if (decimals > 0) {
      const decimalPart = unsignedNumChars.slice(decimalIndex, decimalIndex + decimals + 1).join('');
      if (decimalPart.charAt(0) !== '0') {
        wholePart = Number.parseFloat(`${wholePart}.${decimalPart.slice(0,decimals)}`).toString();
      }
    }

    return `${sign}${wholePart}${prefix}`;
  }
}
