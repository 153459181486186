/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RemoteCluster } from '../models/remote-cluster';
import { RegisterRemoteCluster } from '../models/register-remote-cluster';
import { ReplicationEncryptionKeyReponse } from '../models/replication-encryption-key-reponse';
@Injectable({
  providedIn: 'root',
})
class RemoteClusterService extends __BaseService {
  static readonly GetRemoteClustersPath = '/public/remoteClusters';
  static readonly CreateRemoteClusterPath = '/public/remoteClusters';
  static readonly GetRemoteClusterByIdPath = '/public/remoteClusters/{id}';
  static readonly UpdateRemoteClusterPath = '/public/remoteClusters/{id}';
  static readonly DeleteRemoteClusterPath = '/public/remoteClusters/{id}';
  static readonly GetReplicationEncryptionKeyPath = '/public/replicationEncryptionKey';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List the remote Cohesity Clusters that are registered on this local
   * Cohesity Cluster that match the filter criteria specified using parameters.
   *
   * Cohesity Clusters involved in replication, must be registered to each other.
   * For example, if Cluster A is replicating Snapshots to Cluster B, Cluster
   * B must be registered on Cluster A and Cluster B must be registered
   * on Cluster A.
   * @param params The `RemoteClusterService.GetRemoteClustersParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `purposeReplication`: Filter for purpose as Replication.
   *
   * - `purposeRemoteAccess`: Filter for purpose as Remote Access.
   *
   * - `clusterNames`: Filter by a list of Cluster names.
   *
   * - `clusterIds`: Filter by a list of Cluster ids.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRemoteClustersResponse(params: RemoteClusterService.GetRemoteClustersParams): __Observable<__StrictHttpResponse<Array<RemoteCluster>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.purposeReplication != null) __params = __params.set('purposeReplication', params.purposeReplication.toString());
    if (params.purposeRemoteAccess != null) __params = __params.set('purposeRemoteAccess', params.purposeRemoteAccess.toString());
    (params.clusterNames || []).forEach(val => {if (val != null) __params = __params.append('clusterNames', val.toString())});
    (params.clusterIds || []).forEach(val => {if (val != null) __params = __params.append('clusterIds', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/remoteClusters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RemoteCluster>>;
      })
    );
  }
  /**
   * List the remote Cohesity Clusters that are registered on this local
   * Cohesity Cluster that match the filter criteria specified using parameters.
   *
   * Cohesity Clusters involved in replication, must be registered to each other.
   * For example, if Cluster A is replicating Snapshots to Cluster B, Cluster
   * B must be registered on Cluster A and Cluster B must be registered
   * on Cluster A.
   * @param params The `RemoteClusterService.GetRemoteClustersParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `purposeReplication`: Filter for purpose as Replication.
   *
   * - `purposeRemoteAccess`: Filter for purpose as Remote Access.
   *
   * - `clusterNames`: Filter by a list of Cluster names.
   *
   * - `clusterIds`: Filter by a list of Cluster ids.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRemoteClusters(params: RemoteClusterService.GetRemoteClustersParams): __Observable<Array<RemoteCluster>> {
    return this.GetRemoteClustersResponse(params).pipe(
      __map(_r => _r.body as Array<RemoteCluster>)
    );
  }

  /**
   * Register a remote Cluster on this local Cluster for replication.
   *
   * For a Protection Job to replicate Snapshots from one Cluster
   * to another Cluster, the Clusters must be paired together by
   * registering each Cluster on the other Cluster.
   * For example, Cluster A must be registered on Cluster B
   * and Cluster B must be registered on Cluster A.
   * @param params The `RemoteClusterService.CreateRemoteClusterParams` containing the following parameters:
   *
   * - `body`: Request to register a remote Cluster.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateRemoteClusterResponse(params: RemoteClusterService.CreateRemoteClusterParams): __Observable<__StrictHttpResponse<RemoteCluster>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/remoteClusters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RemoteCluster>;
      })
    );
  }
  /**
   * Register a remote Cluster on this local Cluster for replication.
   *
   * For a Protection Job to replicate Snapshots from one Cluster
   * to another Cluster, the Clusters must be paired together by
   * registering each Cluster on the other Cluster.
   * For example, Cluster A must be registered on Cluster B
   * and Cluster B must be registered on Cluster A.
   * @param params The `RemoteClusterService.CreateRemoteClusterParams` containing the following parameters:
   *
   * - `body`: Request to register a remote Cluster.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateRemoteCluster(params: RemoteClusterService.CreateRemoteClusterParams): __Observable<RemoteCluster> {
    return this.CreateRemoteClusterResponse(params).pipe(
      __map(_r => _r.body as RemoteCluster)
    );
  }

  /**
   * List details about a single remote Cluster registered on this local Cluster.
   *
   * Returns the details about the remote Cluster with the specified Cluster id
   * that is registered on this local Cluster.
   * @param params The `RemoteClusterService.GetRemoteClusterByIdParams` containing the following parameters:
   *
   * - `id`: id of the remote Cluster
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRemoteClusterByIdResponse(params: RemoteClusterService.GetRemoteClusterByIdParams): __Observable<__StrictHttpResponse<Array<RemoteCluster>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/remoteClusters/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RemoteCluster>>;
      })
    );
  }
  /**
   * List details about a single remote Cluster registered on this local Cluster.
   *
   * Returns the details about the remote Cluster with the specified Cluster id
   * that is registered on this local Cluster.
   * @param params The `RemoteClusterService.GetRemoteClusterByIdParams` containing the following parameters:
   *
   * - `id`: id of the remote Cluster
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRemoteClusterById(params: RemoteClusterService.GetRemoteClusterByIdParams): __Observable<Array<RemoteCluster>> {
    return this.GetRemoteClusterByIdResponse(params).pipe(
      __map(_r => _r.body as Array<RemoteCluster>)
    );
  }

  /**
   * Update the connection settings of the registered remote Cluster.
   *
   * Update the connection settings of the specified remote Cluster that is
   * registered on this Cluster.
   * @param params The `RemoteClusterService.UpdateRemoteClusterParams` containing the following parameters:
   *
   * - `id`: id of the remote Cluster
   *
   * - `body`: Request to update a remote Cluster.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateRemoteClusterResponse(params: RemoteClusterService.UpdateRemoteClusterParams): __Observable<__StrictHttpResponse<RemoteCluster>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/remoteClusters/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RemoteCluster>;
      })
    );
  }
  /**
   * Update the connection settings of the registered remote Cluster.
   *
   * Update the connection settings of the specified remote Cluster that is
   * registered on this Cluster.
   * @param params The `RemoteClusterService.UpdateRemoteClusterParams` containing the following parameters:
   *
   * - `id`: id of the remote Cluster
   *
   * - `body`: Request to update a remote Cluster.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateRemoteCluster(params: RemoteClusterService.UpdateRemoteClusterParams): __Observable<RemoteCluster> {
    return this.UpdateRemoteClusterResponse(params).pipe(
      __map(_r => _r.body as RemoteCluster)
    );
  }

  /**
   * Delete a remote Cluster registration connection.
   *
   * Delete the specified remote Cluster registration connection
   * on this Cluster.
   * @param params The `RemoteClusterService.DeleteRemoteClusterParams` containing the following parameters:
   *
   * - `id`: id of the remote Cluster
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteRemoteClusterResponse(params: RemoteClusterService.DeleteRemoteClusterParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/remoteClusters/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a remote Cluster registration connection.
   *
   * Delete the specified remote Cluster registration connection
   * on this Cluster.
   * @param params The `RemoteClusterService.DeleteRemoteClusterParams` containing the following parameters:
   *
   * - `id`: id of the remote Cluster
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteRemoteCluster(params: RemoteClusterService.DeleteRemoteClusterParams): __Observable<null> {
    return this.DeleteRemoteClusterResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get the encryption key on this Cluster.
   *
   * Get the encryption key that is used for encrypting replication data
   * between this Cluster and a remote Cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetReplicationEncryptionKeyResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<ReplicationEncryptionKeyReponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/replicationEncryptionKey`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReplicationEncryptionKeyReponse>;
      })
    );
  }
  /**
   * Get the encryption key on this Cluster.
   *
   * Get the encryption key that is used for encrypting replication data
   * between this Cluster and a remote Cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetReplicationEncryptionKey(regionId?: string,
    accessClusterId?: number): __Observable<ReplicationEncryptionKeyReponse> {
    return this.GetReplicationEncryptionKeyResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as ReplicationEncryptionKeyReponse)
    );
  }
}

module RemoteClusterService {

  /**
   * Parameters for GetRemoteClusters
   */
  export interface GetRemoteClustersParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Filter for purpose as Replication.
     */
    purposeReplication?: boolean;

    /**
     * Filter for purpose as Remote Access.
     */
    purposeRemoteAccess?: boolean;

    /**
     * Filter by a list of Cluster names.
     */
    clusterNames?: Array<string>;

    /**
     * Filter by a list of Cluster ids.
     */
    clusterIds?: Array<number>;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateRemoteCluster
   */
  export interface CreateRemoteClusterParams {

    /**
     * Request to register a remote Cluster.
     */
    body: RegisterRemoteCluster;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetRemoteClusterById
   */
  export interface GetRemoteClusterByIdParams {

    /**
     * id of the remote Cluster
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateRemoteCluster
   */
  export interface UpdateRemoteClusterParams {

    /**
     * id of the remote Cluster
     */
    id: number;

    /**
     * Request to update a remote Cluster.
     */
    body: RegisterRemoteCluster;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteRemoteCluster
   */
  export interface DeleteRemoteClusterParams {

    /**
     * id of the remote Cluster
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { RemoteClusterService }
