import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { SnackBarService } from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';

/**
 * Clipboard CTA component displays the content to be copied
 * and allows users to copy the text to clipboard.
 *
 * @example
 *   <coh-clipboard-cta
 *     headerKey="headerText"
 *     clipboardContent="Content to be copied">
 *   </coh-clipboard-cta>
 */
@Component({
  selector: 'coh-clipboard-cta',
  templateUrl: './clipboard-cta.component.html',
  styleUrls: ['./clipboard-cta.component.scss']
})
export class ClipboardCtaComponent {

  /**
   * The content that get copied to the clipboard.
   */
  @Input() clipboardContent: string;

  /**
   * Header text key.
   */
  @Input() headerKey: string;

  /**
   * Success message text key to be displayed in a snack bar.
   */
  @Input() successMessageKey = 'copiedToClipboard';

  /**
   * Context object for passing data into successMessageKey.
   */
  @Input() successMessageKeyContext: object;

  /**
   * Data template id for reference.
   */
  @Input() dataId: string;

  /**
   * Tooltip text to be displayed on hover of copy to clipboard button.
   */
  @Input() tooltipText: string;

  /**
   * Whether to show the header and content inline.
   */
  @Input() inline = false;

  /**
   * Element reference for clipboard content.
   */
  @ViewChild('content') contentRef: ElementRef;

  constructor(
    private cdr: ChangeDetectorRef,
    private snackbarService: SnackBarService,
    private translate: TranslateService,
  ) {}

  /**
   * Triggers a snack bar to indicate copying to the clipboard was successful.
   */
  copy() {
    // DetectChanges is needed since content ref is not automatically updated inside *ngIf
    this.cdr.detectChanges();
    if (this.contentRef) {
      this.contentRef.nativeElement.select();
      document.execCommand('copy');

      const context = this.successMessageKeyContext || {item: this.clipboardContent};
      this.snackbarService.open(
        this.translate.instant(this.successMessageKey, context), 'success');
    }
  }
}
