import { FormControl, ValidatorFn } from '@angular/forms';

/**
 * A function to validate whether the form control value contains valid
 * location.
 *
 * @param control The form control.
 * @return null if the control is valid, error object otherwise.
 */
export const locationValidator: ValidatorFn = (control: FormControl) => {
  const isInvalid = control.value && !control.value.city;
  return isInvalid ? { invalid: true } : null;
};
