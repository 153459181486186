import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { TimeOfDay } from '@cohesity/api/v1';
import { AutoDestroyable } from '@cohesity/utils';
import moment from 'moment/moment';

// Format values to use for moment
const format12 = 'h:mm a';
const format24 = 'HH:mm';

/**
 * This component wraps a material time picker input and configures it to work
 * with the TimeOfDay proto from the api. Given a TimeOfDay input, it will set
 * the correct value on the time. When changes are made, it will set the value
 * to a TimeOfDay object
 */
@Component({
  selector: 'coh-time-picker',
  templateUrl: './time-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TimePickerComponent),
      multi: true,
    }
  ],
})
export class TimePickerComponent extends AutoDestroyable implements ControlValueAccessor, OnInit {

  /**
   * Label for the mat form field
   */
  @Input() label = '';

  /**
   * Control for the time field.
   */
  timeControl = new UntypedFormControl();

  constructor() {
    super();
  }

  ngOnInit() {
    this.timeControl.valueChanges.pipe(this.untilDestroy()).subscribe(value => this.updateTime(value));
  }

  /**
   * Respond to the change from the picker and update the value
   *
   * @param  time12   The time value in a 12 hour format with am/pm specified.
   */
  updateTime(time12: string) {
    const time = moment(time12, format12).format(format24).split(':');

    this.propagateChange({
      hour: parseInt(time[0], 10),
      minute: parseInt(time[1], 10)
    });
  }

  /**
   * Callback to pass the change back to a form control. Defaults to a noop
   */
  propagateChange = (_value: TimeOfDay) => {};

  /**
   * Update the view with a value passed from a form
   *
   * @param   time   the time of day
   */
  writeValue(time: TimeOfDay) {
    this.timeControl.setValue(time ? moment(time.hour + ':' + time.minute, format24) : null);
  }

  /**
   * Registers a change event to use to propogate changes
   *
   * @param   fn   the callback function
   */
  registerOnChange(fn: (value: TimeOfDay) => any) {
    this.propagateChange = fn;
  }

  /**
   * Register on touched. Not currently used
   */
  registerOnTouched() {}

  /**
   * sets errors on time control
   *
   * @returns errors from time control
   */
  validate(): ValidationErrors | null {
    return this.timeControl.errors;
  }
}
