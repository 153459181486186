import { Component } from '@angular/core';
import { Environment } from '@cohesity/api/v2';
import { filter, startWith } from 'rxjs/operators';
import { PrePostScriptsSettingsListProperties, ProtectionItemName, RemotePrePostScripts } from '../../../models';
import { BaseProtectionBuilderComponent } from '../../base-protection-builder/base-protection-builder.component';

/**
 * Renders a setting list item to specify pre/post script details and, optionally, remote host details.
 */
@Component({
  selector: 'coh-settings-list-pre-post-scripts',
  template: `
    <coh-settings-list-scripts
      [environment]="environment"
      [formControl]="formControl"
      [areRemoteScripts]="properties.remotePrePostScripts"
      [isStorageSnapshotPolicy]=isStorageSnapshotPolicy>
    </coh-settings-list-scripts>
  `,
})
export class SettingsListPrePostScriptsComponent
  extends BaseProtectionBuilderComponent<RemotePrePostScripts, PrePostScriptsSettingsListProperties> {

  /**
   * Type of Adapter.
   */
  environment: Environment;
  /**
   * Checks if Storage Snapshot is defined in policy
   */
  isStorageSnapshotPolicy = false;

  /**
   * Initialize form control.
   */
  initFormControl() {
    const sourceFormControl = this.formGroup.parent.get(ProtectionItemName.Source);
    if (sourceFormControl) {
      sourceFormControl.valueChanges.pipe(
        this.untilDestroy(),
        startWith(sourceFormControl.value),
        filter(value => !!value),
      ).subscribe(value => {
        this.environment = value.protectionSource.environment;
      });
    }
    const policy = this.formGroup.parent.get('policy');
    if (policy) {
      policy.valueChanges.pipe(
        this.untilDestroy(),
        startWith(policy.value),
        filter(value => !!value),
      ).subscribe(value => {
        this.isStorageSnapshotPolicy = Boolean(value?.backupPolicy?.storageArraySnapshot);
      });
    }

    if (this.protectionGroup?.prePostScripts) {
      this.formControl.patchValue(this.protectionGroup.prePostScripts);
    } else {
      this.formControl.setValue({isRemoteScript: false, preScriptEnabled: false, postScriptEnabled: false});
    }
  }
}
