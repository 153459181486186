/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Vlan } from '../models/vlan';
@Injectable({
  providedIn: 'root',
})
class VlanService extends __BaseService {
  static readonly GetVlansPath = '/public/vlans';
  static readonly CreateVlanPath = '/public/vlans';
  static readonly GetVlanByIdPath = '/public/vlans/{id}';
  static readonly UpdateVlanPath = '/public/vlans/{id}';
  static readonly RemoveVlanPath = '/public/vlans/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List the VLANs for the Cohesity Cluster.
   *
   * Returns the VLANs for the Cohesity Cluster.
   * @param params The `VlanService.GetVlansParams` containing the following parameters:
   *
   * - `vlanId`: Vlan Id to filter vlans.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `skipPrimaryAndBondIface`: SkipPrimaryAndBondIface is to filter interfaces entries which are primary
   *   interface or bond interfaces.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `compressIpsToRanges`: Compress Ips list to list of contigous ip ranges with(startIp, endIp)
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetVlansResponse(params: VlanService.GetVlansParams): __Observable<__StrictHttpResponse<Array<Vlan>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.vlanId != null) __params = __params.set('vlanId', params.vlanId.toString());
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.skipPrimaryAndBondIface != null) __params = __params.set('skipPrimaryAndBondIface', params.skipPrimaryAndBondIface.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.compressIpsToRanges != null) __params = __params.set('compressIpsToRanges', params.compressIpsToRanges.toString());
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/vlans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Vlan>>;
      })
    );
  }
  /**
   * List the VLANs for the Cohesity Cluster.
   *
   * Returns the VLANs for the Cohesity Cluster.
   * @param params The `VlanService.GetVlansParams` containing the following parameters:
   *
   * - `vlanId`: Vlan Id to filter vlans.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `skipPrimaryAndBondIface`: SkipPrimaryAndBondIface is to filter interfaces entries which are primary
   *   interface or bond interfaces.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `compressIpsToRanges`: Compress Ips list to list of contigous ip ranges with(startIp, endIp)
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetVlans(params: VlanService.GetVlansParams): __Observable<Array<Vlan>> {
    return this.GetVlansResponse(params).pipe(
      __map(_r => _r.body as Array<Vlan>)
    );
  }

  /**
   * Creates a VLAN of the Cohesity Cluster.
   *
   * Returns the created VLAN on the Cohesity Cluster.
   * @param params The `VlanService.CreateVlanParams` containing the following parameters:
   *
   * - `Body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateVlanResponse(params: VlanService.CreateVlanParams): __Observable<__StrictHttpResponse<Vlan>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.Body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/vlans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Vlan>;
      })
    );
  }
  /**
   * Creates a VLAN of the Cohesity Cluster.
   *
   * Returns the created VLAN on the Cohesity Cluster.
   * @param params The `VlanService.CreateVlanParams` containing the following parameters:
   *
   * - `Body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateVlan(params: VlanService.CreateVlanParams): __Observable<Vlan> {
    return this.CreateVlanResponse(params).pipe(
      __map(_r => _r.body as Vlan)
    );
  }

  /**
   * List the details about a single VLAN.
   *
   * Returns the VLAN corresponding to the specified VLAN ID or a specified
   * vlan interface group name.
   * Example: /public/vlans/intf_group1.20
   * @param params The `VlanService.GetVlanByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the VLAN.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetVlanByIdResponse(params: VlanService.GetVlanByIdParams): __Observable<__StrictHttpResponse<Vlan>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/vlans/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Vlan>;
      })
    );
  }
  /**
   * List the details about a single VLAN.
   *
   * Returns the VLAN corresponding to the specified VLAN ID or a specified
   * vlan interface group name.
   * Example: /public/vlans/intf_group1.20
   * @param params The `VlanService.GetVlanByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the VLAN.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetVlanById(params: VlanService.GetVlanByIdParams): __Observable<Vlan> {
    return this.GetVlanByIdResponse(params).pipe(
      __map(_r => _r.body as Vlan)
    );
  }

  /**
   * Creates or updates a VLAN of the Cohesity Cluster.
   *
   * Returns the created or updated VLAN on the Cohesity Cluster.
   * @param params The `VlanService.UpdateVlanParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the VLAN.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`:
   *
   * @return Success
   */
  UpdateVlanResponse(params: VlanService.UpdateVlanParams): __Observable<__StrictHttpResponse<Vlan>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    __body = params.Body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/vlans/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Vlan>;
      })
    );
  }
  /**
   * Creates or updates a VLAN of the Cohesity Cluster.
   *
   * Returns the created or updated VLAN on the Cohesity Cluster.
   * @param params The `VlanService.UpdateVlanParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the VLAN.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * - `Body`:
   *
   * @return Success
   */
  UpdateVlan(params: VlanService.UpdateVlanParams): __Observable<Vlan> {
    return this.UpdateVlanResponse(params).pipe(
      __map(_r => _r.body as Vlan)
    );
  }

  /**
   * Remove the specified VLAN from the Cohesity Cluster.
   *
   * Returns the delete status upon completion.
   * @param params The `VlanService.RemoveVlanParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the VLAN.
   *
   * - `Body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  RemoveVlanResponse(params: VlanService.RemoveVlanParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.Body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/vlans/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Remove the specified VLAN from the Cohesity Cluster.
   *
   * Returns the delete status upon completion.
   * @param params The `VlanService.RemoveVlanParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the VLAN.
   *
   * - `Body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  RemoveVlan(params: VlanService.RemoveVlanParams): __Observable<null> {
    return this.RemoveVlanResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module VlanService {

  /**
   * Parameters for GetVlans
   */
  export interface GetVlansParams {

    /**
     * Vlan Id to filter vlans.
     */
    vlanId?: number;

    /**
     * TenantIds contains ids of the tenants for which objects are to be
     * returned.
     */
    tenantIds?: Array<string>;

    /**
     * SkipPrimaryAndBondIface is to filter interfaces entries which are primary
     * interface or bond interfaces.
     */
    skipPrimaryAndBondIface?: boolean;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Compress Ips list to list of contigous ip ranges with(startIp, endIp)
     */
    compressIpsToRanges?: boolean;

    /**
     * AllUnderHierarchy specifies if objects of all the tenants under the
     * hierarchy of the logged in user's organization should be returned.
     */
    allUnderHierarchy?: boolean;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateVlan
   */
  export interface CreateVlanParams {
    Body: Vlan;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetVlanById
   */
  export interface GetVlanByIdParams {

    /**
     * Specifies the id of the VLAN.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateVlan
   */
  export interface UpdateVlanParams {

    /**
     * Specifies the id of the VLAN.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
    Body?: Vlan;
  }

  /**
   * Parameters for RemoveVlan
   */
  export interface RemoveVlanParams {

    /**
     * Specifies the id of the VLAN.
     */
    id: number;
    Body: Vlan;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { VlanService }
