import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { AutoDestroyable } from '@cohesity/utils';
import { map as rxjsMap } from 'rxjs/operators';
import { GenerateCohesityCertificateModel } from './generate-cohesity-certificate-interface.model';

@Component({
    selector: 'coh-generate-cohesity-certificate-interface',
    templateUrl: './generate-cohesity-certificate-interface.component.html',
    styleUrls: ['./generate-cohesity-certificate-interface.component.scss'],
})
export class GenerateCohesityCertificateInterfaceComponent extends AutoDestroyable implements AfterViewInit {
    /**
     * Output to return object filled with the inputs to parent component
     */
    @Output()
    interfaceData: EventEmitter<GenerateCohesityCertificateModel | null> =
        new EventEmitter<GenerateCohesityCertificateModel | null>();

    /**
     * Form group to keep all items validated
     */
    formGroup = new FormGroup({
        commonName: new FormControl('', Validators.required),
        subjectiveAlternativeName: new FormControl([''], Validators.required),
        validity: new FormControl(null, Validators.required),
        period: new FormControl('Years', Validators.required),
        privateKey: new FormControl(''),
        organization: new FormControl('', Validators.required),
        department: new FormControl('', Validators.required),
        city: new FormControl('', Validators.required),
        state: new FormControl('', Validators.required),
        country: new FormControl('', Validators.required),
    });

    /**
     * List of Subjective alternative name
     */
    subjectiveAlternativeNameValues: string[] = [];

    /**
     * Separator key codes which trigger addiiton of subjective alternative name to chip-list.
     */
    separatorKeysCodes: number[] = [ENTER, COMMA];

    /**
     * List of period options
     */
    periods = [
        'Years',
        'Months',
        'Days'
    ];

    /**
     * Constructor
     */
    constructor() {
        super();
    }

    /**
     * AfterViewInit lifecycle hook.
     */
    ngAfterViewInit () {
        this.resetValidityField();

        this.formGroup.valueChanges
            .pipe(
                rxjsMap((value: GenerateCohesityCertificateModel) => {
                    this.validateValidityFields(value);
                    this.updateInterfaceData();
                }),
                this.untilDestroy(),
            ).subscribe();
    }

    /**
     * Validate validity field
     *
     * @param value
     * @returns
     */
    validateValidityFields(value: GenerateCohesityCertificateModel) {
        switch (value.period) {
            case 'Years':
                /**
                 * Validate validity field in years
                 */
                if(value.validity > 3) {
                    this.formGroup.patchValue({validity: 3});
                }
                break;
            case 'Months':
                /**
                 * Validate validity field in months
                 */
                if(value.validity > 36) {
                    this.formGroup.patchValue({validity: 36});
                }
                break;
            default:
                /**
                 * Validate validity field in days
                 */
                if(value.validity > 1095) {
                    this.formGroup.patchValue({validity: 1095});
                }
                break;
        }
    }

    /**
     * Reset validity field
     */
    resetValidityField() {
        this.formGroup.get('period').valueChanges.subscribe(() => {
            this.formGroup.patchValue({validity: 0});
        });
    }

    /**
     * Update parent component with the current interface data
     */
    updateInterfaceData() {
        const {
            commonName,
            validity,
            period,
            privateKey,
            organization,
            department,
            city,
            state,
            country
        } = this.formGroup.value;

        if(this.formGroup.invalid) {
            this.interfaceData.emit(null);
        } else {
            this.interfaceData.emit({
                commonName,
                validity,
                period,
                privateKey,
                organization,
                department,
                city,
                state,
                country
            }
            );
        }
    }

    /**
     * Remove subjective at index in subjective list.
     *
     * @param   index index of subjective in subjective list.
     */
    removeSubjectiveAlternativeName(index: number) {
        this.subjectiveAlternativeNameValues.splice(index, 1);
        this.formGroup.patchValue({subjectiveAlternativeName: this.subjectiveAlternativeNameValues});
        this.formGroup.controls.subjectiveAlternativeName.markAsDirty();
    }

    /**
     * Add Subjective Alternative Name to list of subjective.
     *
     * @param   event MatChipInputEvent to get input value.
     */
    addSubjectiveAlternativeName(event: MatChipInputEvent) {
        const subjective = event.value.trim();
        event.input.value = '';

        if (!subjective) {
            return;
        }

        this.subjectiveAlternativeNameValues.push(subjective);
        this.formGroup.patchValue({subjectiveAlternativeName: this.subjectiveAlternativeNameValues});
        this.formGroup.controls.subjectiveAlternativeName.markAsDirty();
    }

    /**
     * Handler for paste event on subjective alternative name entries input.
     *
     * @param event Event of type ClipboardEvent.
     */
    pasteSubjectiveAlternativeNameInput(event: ClipboardEvent): void {
        const clipboardData = event.clipboardData;
        const clipboardText = clipboardData.getData('text');
        event.preventDefault();

        clipboardText.split(/;|,|\n/).forEach(subjective => {
            const subjectiveInput = subjective.trim();
            if (subjectiveInput) {
                this.subjectiveAlternativeNameValues.push(subjectiveInput);
                this.formGroup.patchValue({subjectiveAlternativeName: this.subjectiveAlternativeNameValues});
                this.formGroup.controls.subjectiveAlternativeName.markAsDirty();
            }
        });
    }

    /**
     * Validate if the subjective alternative name list is empty
     * and apply validators to control
     */
    validateSubjectiveAlternativeNameFormControl() {
        if (this.subjectiveAlternativeNameValues.length === 0) {
            this.formGroup.patchValue({subjectiveAlternativeName: []});
        }

        this.formGroup.controls.subjectiveAlternativeName.markAsDirty();
        this.formGroup.controls.subjectiveAlternativeName.updateValueAndValidity();
    }
}
