import { Injectable } from '@angular/core';
import { ExecuteRuleResp, HeliosRulesApiService, ListAssociatedTagsResp, RuleType, Tag } from '@cohesity/api/helios-metadata';
import { HmsTaggingWrapperService } from '@cohesity/data-govern/shared';
import { IrisContextService, flagEnabled, isMcm } from '@cohesity/iris-core';
import { BehaviorSubject, forkJoin, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { GroupedObjectSnapshot } from '../models/grouped-object-snapshot';
import { SnapshotMetadata, SnapshotRecoverabilityInfo, SnapshotTagInfo } from '../models/snapshot-metadata';

@Injectable({
  providedIn: 'root'
})
export class SnapshotMetadataService {
  /**
   * A subject which yields a value to indicate whether the snapshot metadata is being loaded from API.
   */
  readonly snapshotMetadataLoading$ = new BehaviorSubject<boolean>(false);

  /**
   * default snapshot metadata
   */
  readonly defaultMetadata: SnapshotMetadata = {
    tags: {},
    recoverability: {},
  };

  /**
   * Returns true if phase 1 for the clean room recovery is enabled.
   */
  get isCleanRoomRecoveryPhase1Enabled(): boolean {
    return flagEnabled(
      this.irisContextService.irisContext,
      'cleanRoomRecoveryPhase1'
    );
  }

  constructor(
    private heliosRulesApiService: HeliosRulesApiService,
    private hmsService: HmsTaggingWrapperService,
    private irisContextService: IrisContextService,
  ) {}

  /**
   * Fetches the snapshot metadata for the provides list of snapshot ids
   *
   * @param snapshotIdList snapshot ids for which tag information need to be fetched
   * @returns an observable with snapshot metadata information
   */
  fetchSnapshotMetadata(snapshotIdList: string[]) {
    // if there are no snapshot ids, or the clean room recovery feature is disabled, or its in non MCM env, exit early.
    const isMcmMode = isMcm(this.irisContextService.irisContext);
    if (snapshotIdList.length === 0 || !this.isCleanRoomRecoveryPhase1Enabled || !isMcmMode) {
      return of(this.defaultMetadata);
    }

    this.snapshotMetadataLoading$.next(true);
    return forkJoin([
      this.heliosRulesApiService.executeRuleOp({
        body: {
          ruleType: RuleType.BlockSnapshotRecovery,
          snapshotIds: snapshotIdList,
        }
      }),
      this.hmsService.listAssociatedTags({
        snapshotIds: snapshotIdList,
      }),
    ]).pipe(
      map(([recoverabilityStatusResponse, tagsResponse]) => {
        const metadata: SnapshotMetadata = {
          tags: this.generateSnapshotTagsMap(tagsResponse),
          recoverability: this.generateRecoverabilityMap(recoverabilityStatusResponse),
        };

        return metadata;
      }),
      finalize(() => this.snapshotMetadataLoading$.next(false)),
      catchError(err => {
        console.error(err);
        return of(this.defaultMetadata);
      }),
    );
  }

  /**
   * Generate a snapshot id to tags mapping for easier lookup
   *
   * @param tagsResponse API response for the tags associated with the supplied snapshot ids
   * @returns a map of snapshot id to corresponding tag list
   */
  generateSnapshotTagsMap(tagsResponse: ListAssociatedTagsResp): SnapshotTagInfo {
    const defaultSnapshotTags: SnapshotTagInfo = {};
    return (tagsResponse.snapshotTags || []).reduce((acc, entry) => {
      if (!!entry.snapshotId && !!entry.tags) {
        acc[entry.snapshotId] = entry.tags;
      }
      return acc;
    }, defaultSnapshotTags);
  }

  /**
   * Generates a snapshot id to its recoverability status mapping for easier look up
   *
   * @param recoverabilityStatusResponse Recoverability status for the snapshots
   * @returns a map of snapshot id to its recoverability status
   */
  generateRecoverabilityMap(recoverabilityStatusResponse: ExecuteRuleResp): SnapshotRecoverabilityInfo {
    const defaultRecoverabilityStatus: SnapshotRecoverabilityInfo = {};

    return recoverabilityStatusResponse.result
      ?.reduce((acc, entry) => {
        if (entry.snapshotId) {
          acc[entry.snapshotId] = !entry.shouldBlockRecovery;
        }
        return acc;
      }, defaultRecoverabilityStatus) || defaultRecoverabilityStatus;
  }

  /**
   * A utility function to extract tag information from the metadata for the snapshots present in the given grouped
   * snapshot object.
   *
   * @param group group for which all the snapshot tag information is required
   * @param metadata snapshot metadata
   * @returns list of tags associated with the snapshots in the provided group
   */
  getSnapshotTags(group: GroupedObjectSnapshot, metadata: SnapshotMetadata): Tag[] {
    const uniqueTags: Record<string, Tag> = {};

    // iterate over all the snapshots in the groups, and find the unique tags from the metadata
    group.snapshots.forEach((snapshot) => {
      const tags = (metadata?.tags || {})[snapshot.id] || [];
      tags.forEach(tag => {
        if (!uniqueTags[tag.uuid]) {
          uniqueTags[tag.uuid] = tag;
        }
      });
    });

    return Object.values(uniqueTags);
  }

  /**
   * A utility function to check the recoverability status for the snapshots present in the given grouped
   * snapshot object.
   *
   * @param group group for which all the snapshot tag information is required
   * @param metadata snapshot metadata
   * @returns true if all snapshots are recoverable, otherwise false.
   */
  isGroupRecoverable(group: GroupedObjectSnapshot, metadata: SnapshotMetadata): boolean {
    if (!metadata) {
      return false;
    }

    return !group.snapshots.some((snapshot) => metadata?.recoverability[snapshot.id] === false);
  }
}
