import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { ColoredTagModule } from '@cohesity/shared/tags';
import { TranslateModule } from '@ngx-translate/core';
import { PluginSharedModule } from 'src/app/plugin-shared';
import { CalendarModule, SharedModule } from 'src/app/shared';

import { SnapshotSelectorListViewComponent } from './components/list-view/list-view.component';
import { SnapshotSelectorTimelineViewComponent } from './components/timeline-view/timeline-view.component';
import { SnapshotSelectorViewSwitcherComponent } from './components/view-switcher/view-switcher.component';
import { SnapshotSelectorMenuComponent } from './containers/snapshot-selector-menu/snapshot-selector-menu.component';
import { SnapshotSelectorComponent } from './containers/snapshot-selector/snapshot-selector.component';
import { SnapshotSearchService } from './services/snapshot-search.service';
import { SnapshotSelectorService } from './services/snapshot-selector.service';

@NgModule({
  imports: [
    CalendarModule,
    CohesityHelixModule,
    PluginSharedModule,
    SharedModule,
    ColoredTagModule,
    TranslateModule.forChild(),
  ],
  declarations: [
    SnapshotSelectorComponent,
    SnapshotSelectorListViewComponent,
    SnapshotSelectorMenuComponent,
    SnapshotSelectorTimelineViewComponent,
    SnapshotSelectorViewSwitcherComponent,
  ],
  providers: [SnapshotSelectorService, SnapshotSearchService],
  exports: [
    SnapshotSelectorComponent,
    SnapshotSelectorListViewComponent,
    SnapshotSelectorMenuComponent,
    SnapshotSelectorTimelineViewComponent,
    SnapshotSelectorViewSwitcherComponent,
  ],
})
export class SnapshotSelectorModule {}
