import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RegionClusterScanResult } from '@cohesity/api/secops';
import { IrisContextService, isSecurityCenterUser } from '@cohesity/iris-core';

import { PerformScanDialogInputParams } from '../../components';
import { PostureAdvisorService } from '../../services';
import { PerformScanDialogService } from '../../services/perform-scan-dialog.service';

/**
 * @description
 * Posture Advisor Landing Page.
 *
 * @example
 *  <dg-pa-posture-advisor-page>
 *  </dg-pa-posture-advisor-page>
 */
@Component({
  selector: 'dg-pa-posture-advisor-page',
  templateUrl: './posture-advisor-page.component.html',
  styleUrls: ['./posture-advisor-page.component.scss'],
  providers: [PerformScanDialogService]
})
export class PostureAdvisorPageComponent implements OnInit {
  /**
   * Emit an event when a tab is selected
   */
  @Output() tabSelect = new EventEmitter<string>();

  /**
   * Emit an event when a row item is clicked for drilldown
   */
  @Output() scoreDrilldownClick = new EventEmitter<string>();

  /**
   * Inform parent 'edit rule' has been clicked
   */
  @Output() editRuleClick = new EventEmitter<string>();

  /**
   * Inform parent that 'create rule' has been clicked
   */
  @Output() createRuleClick = new EventEmitter<string>();

  /**
   * Inform parent that 'rule detail' has been clicked
   */
  @Output() ruleDetailClick = new EventEmitter<string>();

  /**
   * Inform parent that 'scan result' has been clicked
   */
  @Output() scanResultClick = new EventEmitter<string>();

  /**
   * Inform parent that 'scan result' has been clicked
   */
  @Output() dateRangeChange = new EventEmitter<{[key: string]: string | number}>();

  /**
   * Route to determine the selected tab
   */
  @Input() route = 'scores';

  /**
   * List of all tabs.
   */
  readonly tabs = [
    {name: 'scores', route: 'scores'},
    {name: 'allIssues', route: 'allIssues'},
    {name: 'securityRules', route: 'rules'},
    {name: 'scanResults', route: 'results'}
  ];

  /**
   * All possible routes
   */
  readonly routes = this.tabs.map(tab => tab.route);

  /**
   * if it is loading show the spinner.
   */
  loading = false;

  /**
   * Score Data to be displayed in the UI.
   */
  scoresData: RegionClusterScanResult[];

  /**
   * Is security center enabled
   */
  isSecurityCenterEnabled = isSecurityCenterUser(this.irisContextService.irisContext);

  constructor(
    // public layout: AppLayoutService,
    private performScanDialogService: PerformScanDialogService,
    private postureAdvisorService: PostureAdvisorService,
    private irisContextService: IrisContextService,
  ) {
  }

  ngOnInit() {
    if (!this.scoresData) {
      this.postureAdvisorService.getScanResultsByRegion((result) => {
        this.scoresData = result;
      });
    }
  }

  /**
   * Open perform scan dialog with all data.
   */
  openPerformScanDialog() {
    const dialogParams: PerformScanDialogInputParams = {
      scoresData: this.scoresData,
    };
    this.performScanDialogService.openPerformScanDialog(this.scoresData ? dialogParams : undefined);
  }
}
