import { Component, Input } from '@angular/core';
import { View } from '@cohesity/api/v2';
import { AutoDestroyable } from '@cohesity/utils';

@Component({
  selector: 'coh-sd-views-list',
  templateUrl: './views-list.component.html',
  styleUrls: ['../delete-storage-domain-stepper.scss'],
})
export class ViewsListComponent extends AutoDestroyable {
  @Input() viewList: View[];

  constructor() {
    super();
  }
}
