import { Injectable } from '@angular/core';
import { RestoreTasksApiService } from '@cohesity/api/private';
import { IrisContextService } from '@cohesity/iris-core';
import { of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map } from 'rxjs/operators';
import {
  AvailableExtensionPoints,
  componentExtension,
  PluginRegistryService,
  tableColumnExtension,
} from 'src/app/plugin-shared';

import { SnapshotInfoComponent } from './snapshot-info/snapshot-info.component';

const snapshotSelectionDetailId = 'vulscan.restore.snapshotSelectionDetail';
const snapshotSelectionColumnId = 'vulscan.restore.snapshotSelectionColumn';

/**
 * This plugin should determine when the vulscanner application is available for use
 * within iris and register its plugins as needed.
 */
@Injectable({
  providedIn: 'root',
})
export class VulscannerPluginService {
  constructor(
    private pluginRegistry: PluginRegistryService,
    private restoreTasksApi: RestoreTasksApiService,
    private irisContextService: IrisContextService,
  ) { }

  /**
   * Initialize the plugin, currently, it only listens for the feature flag changes.
   */
  init() {
    // Monitor iris context for username.
    // irisContext.user is populated only when user logs in and after that call vulscan status api.
    this.irisContextService.irisContext$.pipe(
      filter(irisContext => !!irisContext.user.username),
      map(irisContext => irisContext.featureFlags.pluginVulscannerSnapshot),
      distinctUntilChanged()
    ).subscribe(flagEnabled => {
      if (flagEnabled) {
        // Make API call only when feature flag is enabled
        this.restoreTasksApi.getVulScanAppStatus().pipe(
          catchError(() => of(false))
        ).subscribe(appStatus => {
          if (appStatus) {
            this.registerExtensions();
          } else {
            this.unregisterExtensions();
          }
        });
      } else {
        this.unregisterExtensions();
      }
    });
  }

  /**
   * Register extensions
   */
  registerExtensions() {
    this.pluginRegistry.addExtensionOutlet(AvailableExtensionPoints.restoreSnapshotSelectionDetailComponent, {
      id: snapshotSelectionDetailId,
      type: componentExtension,
      data: SnapshotInfoComponent,
    });

    this.pluginRegistry.addExtensionOutlet(AvailableExtensionPoints.restoreSnapshotSelectionDetailColumn, {
      id: snapshotSelectionColumnId,
      type: tableColumnExtension,
      label: 'Vulnerability Status',
      columnName: 'vulscannerStatus',
      cellComponent: SnapshotInfoComponent,
    });
  }

  /**
   * Unregister extensions
   */
  unregisterExtensions() {
    this.pluginRegistry.removeExtensionOutlet(
      AvailableExtensionPoints.restoreSnapshotSelectionDetailComponent,
      snapshotSelectionDetailId
    );
    this.pluginRegistry.removeExtensionOutlet(
      AvailableExtensionPoints.restoreSnapshotSelectionDetailComponent,
      snapshotSelectionColumnId
    );
  }
}
