import { Component, Input } from '@angular/core';
import { Recovery } from '@cohesity/api/v2';
import { AutoDestroyable } from '@cohesity/utils';

@Component({
  selector: 'coh-sd-recoveries-list',
  templateUrl: './recoveries-list.component.html',
  styleUrls: ['../delete-storage-domain-stepper.scss'],
})
export class RecoveriesListComponent extends AutoDestroyable {
  /**
   * Recovery list.
   */
  @Input() recoveryList: Recovery[];

  constructor() {
    super();
  }
}
