// COMPONENT: Recover DB: Search step

;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.dbRestore';

  angular
    .module(moduleName)
    .controller('restoreDBSearchController', restoreDBSearchControllerFn);

  function restoreDBSearchControllerFn($rootScope, $scope, $state,
    evalAJAX, DateTimeService, SearchService, cSearchService, SourceService) {

    /**
     * Default list of Filters for use in the cSearchFilters.
     *
     * @example
      {
        property: {String},
        display: {String|Fn=},
        primary: {Bool=},
        locked: {Bool=},
        transformFn: {Fn=},
        value: {Array|Integer|String|Bool=}
      }
     * @type       {Array}
     */
    var defaultFilterOptions = [];

    angular.extend($scope, {
      // GENERAL SCOPE VARS
      // TEXT STRINGS
      text: $rootScope.text.protectionRecoverySqlSqlSearchText,

      // DB level host entities.
      hostEntities: [],

      // ERRORS
      errorText: $rootScope.text.protectionRecoverySqlSqlSearch1ErrorText,

      // SCOPE METHODS
      onSelectSearchItem: onSelectSearchItem,
      preProcessSearchResults: preProcessSearchResults,
      selectSnapshotIndex: selectSnapshotIndex
    });

    defaultFilterOptions = [
      {
        display: $scope.text.viewBox,
        locked: false,
        property: 'viewBoxIds',
        transformFn: viewBoxIdFromName,
      },
      {
        display: $scope.text.serverName,
        locked: false,
        property: 'entityIds',
        transformFn: transformVMnamesToIds,
      },
      {
        display: $scope.text.protectionJob,
        locked: false,
        property: 'jobIds',
        transformFn: jobIdFromName,
      },
      {
        display: $scope.text.startDate,
        locked: false,
        property: 'fromTimeUsecs',
        transformFn: DateTimeService.dateToUsecs,
      },
      {
        display: $scope.text.endDate,
        locked: false,
        property: 'toTimeUsecs',
        transformFn: DateTimeService.dateToUsecs,
      },
      {
        primary: true,
        property: 'vmName',
      },
    ];


    // METHODS
    /**
     * Initialize all the things!
     *
     * @method     sqlSearchInit
     */
    function sqlSearchInit() {
      initSearchConfig();
      $scope.updateStep();
      // Clears the cached results.
      cSearchService.purgeResults($scope.shared.searchId);
    }

    /**
     * Pre-process the search results. Uses the SearchService's transform method
     * under the hood + a little more.
     *
     * @method   preProcessSearchResults
     * @param    {object}   results   The server's raw response object
     * @return   {array}    The results of the SearchService's transformer
     */
    function preProcessSearchResults(results) {
      var resp = $scope.isDbMigration ?
        SearchService.transformDbMigrateResults(results) :
        SearchService.transformDBResults(results, true, $scope.flowType,
          $scope.hostEntities);

      if (!resp.length) {
        return [{
          isEmpty: true
        }];
      }
      return resp;
    }

    /**
     * Fetch list of Oracle host entities.
     */
    function getOracleEntities() {
      var params = {
        appEnvType: 19,
      };

      SourceService.getAppEntities(params).then(resp => {
        $scope.hostEntities = resp;
      }, evalAJAX.errorMessage);
    }


    /**
     * Callback on selecting a search item
     *
     * @method   onSelectSearchItem
     * @param    {object}  row   searchItem
     */
    function onSelectSearchItem(row) {
      $scope.setParametersBasedOnDbType(row._type);
      $scope.addToCart(row);
    }

    /**
     * TransformFn for viewBox search filter
     *
     * @method     viewBoxIdFromName
     * @param      {object|array}  viewBoxes  The viewBox names to get the
     *                                        IDs for
     * @return     {array}         The viewBox ids
     */
    function viewBoxIdFromName(viewBoxes) {
      var out = [];
      if (viewBoxes) {
        viewBoxes = [].concat(viewBoxes);
        return $scope.shared.filterLookups.viewBoxIds
          .reduce(function matchViewboxes(boxes, vb) {
            if (!!~viewBoxes.indexOf(vb.name)) {
              boxes.push(vb.id);
            }
            return boxes;
          }, []);
      }
      return out;
    }

    /**
     * Transform an array of Job names to Job IDs
     *
     * @method     jobIdFromName
     * @param      {Array}  names   Array of Job names
     * @return     {Array}          Array of Job IDs
     */
    function jobIdFromName(names) {
      var out = [];
      if (names) {
        names = [].concat(names);
        return $scope.shared.filterLookups.jobIds
          .reduce(function matchJobs(jobs, job) {
            if (names.includes(job.jobName)) {
              jobs.push(job.jobId);
            }
            return jobs;
          }, []);
      }
      return out;
    }

    /**
     * Transforms a single or list of EntityProto(s) into a flat list of
     * Entity IDs
     *
     * @method     transformVMnamesToIds
     * @param      {Array|Object}  entities  Single or list of VM EntityProtos
     * @return     {Array}                   Array of IDs
     */
    function transformVMnamesToIds(entities) {
      var out = [];
      var typedEntity;

      if (entities) {
        entities = [].concat(entities);
      }
      // Optimize this lookup if there's only one item
      if (entities.length === 1) {
        $scope.shared.filterLookups.entityIds.some(function vmFinderFn(server) {
          if (entities[0] === server[server._entityKey].name) {
            out[0] = server.id;
            return true;
          }
          return false;
        });
      } else {
        out = $scope.shared.filterLookups.entityIds.reduce(
          function reduceToIdsFn(ids, server) {
          if (entities.includes(server[server._entityKey].name)) {
            ids.push(server.id);
          }
          return ids;
        }, []);
      }
      return out;
    }

    /**
     * Find the index of the selected snapshot and set it on the
     * row._snapshotIndex
     *
     * @method     selectSnapshotIndex
     * @param      {Object}   row        Row entity
     * @param      {Integer}  timestamp  Usecs timestamp
     */
    function selectSnapshotIndex(row, timestamp) {
      // Since we don't have access to the index of the selected item,
      // we'll find it and set row._snapshotIndex here.
      row._versions.some(function findSnapshotFn(ver, ii) {
        if (timestamp === ver.instanceId.jobStartTimeUsecs) {
          // Set the index
          row._snapshotIndex = ii;
          return true;
        }
        return false;
      });
      // Clear the tmp var
      $scope.tmpSnapshotUsecs = undefined;
    }

    /**
     * Init the shared scope object.
     *
     * @method     initSearchConfig
     */
    function initSearchConfig() {
      angular.extend($scope.shared, {
        searchId: [$scope.flowType, $scope.db, 'search'].join('-'),
        results: [],
        pagedResults: [],
        filters: angular.copy(defaultFilterOptions),
        selectedResults: [],
        endpoint:
          SearchService.getSearchUrl(
            $state.params.dbMigration ?
              'db-migration' :
              $scope.flowType + '-' + $scope.db,

            // Only get latest run initially.
            true
          ),
      });
    }

    // Get oracle host entities.
    getOracleEntities();

    // Initialize!
    sqlSearchInit();
  }

})(angular);
