<mat-card>
  <div class="dashcard-header">
    <mat-card-title *ngIf="title">
      <span>{{title}}</span>
      <cog-spinner class="internal-spinner" *ngIf="loading" [inline]="true" size="xs"></cog-spinner>
    </mat-card-title>
    <ng-content select="cog-dashcard-header-aux"></ng-content>
  </div>
  <mat-card-subtitle *ngIf="subtitle">{{subtitle}}</mat-card-subtitle>

  <ng-content></ng-content>
</mat-card>
