import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DayCountInMonth } from '../constants';

/**
 * Converts a DayCount enum to a string
 */
@Pipe({
  name: 'dayCount',
  pure: true,
})
export class DayCountPipe implements PipeTransform {
  private dayCounts = {
    kFirst: 'enum.dayCountInMonth.first',
    kSecond: 'enum.dayCountInMonth.second',
    kThird: 'enum.dayCountInMonth.third',
    kFourth: 'enum.dayCountInMonth.fourth',
    kLast: 'enum.dayCountInMonth.last',
  };

  /**
   * creates the pipe
   *
   * @param translate   The translate service
   */
  constructor(private translate: TranslateService) {}

  /**
   * converts from a value to a display label
   *
   * @param  day   The day count, 'kFirst', 'kSecond', etc...
   * @return The label for the day count value
   */
  transform(dayCount: DayCountInMonth): any {
    return this.translate.instant(this.dayCounts[dayCount]);
  }
}
