/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AlertCategoryName } from '../models/alert-category-name';
import { NotificationRule } from '../models/notification-rule';
import { AlertResolution } from '../models/alert-resolution';
import { AlertResolutionRequest } from '../models/alert-resolution-request';
import { UpdateResolutionParams } from '../models/update-resolution-params';
import { AlertMetadata } from '../models/alert-metadata';
import { GetAlertTypesParams } from '../models/get-alert-types-params';
import { Alert } from '../models/alert';
@Injectable({
  providedIn: 'root',
})
class AlertsService extends __BaseService {
  static readonly GetAlertCategoriesPath = '/public/alertCategories';
  static readonly GetNotificationRulesPath = '/public/alertNotificationRules';
  static readonly UpdateNotificationRulePath = '/public/alertNotificationRules';
  static readonly CreateNotificationRulePath = '/public/alertNotificationRules';
  static readonly DeleteNotificationRulePath = '/public/alertNotificationRules/{ruleId}';
  static readonly GetResolutionsPath = '/public/alertResolutions';
  static readonly CreateResolutionPath = '/public/alertResolutions';
  static readonly GetResolutionByIdPath = '/public/alertResolutions/{id}';
  static readonly UpdateResolutionPath = '/public/alertResolutions/{id}';
  static readonly GetAlertTypesPath = '/public/alertTypes';
  static readonly GetAlertsPath = '/public/alerts';
  static readonly GetAlertByIdPath = '/public/alerts/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get alert categories in the Cohesity cluster.
   *
   * Returns alert categories in Cohesity cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetAlertCategoriesResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<Array<AlertCategoryName>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/alertCategories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AlertCategoryName>>;
      })
    );
  }
  /**
   * Get alert categories in the Cohesity cluster.
   *
   * Returns alert categories in Cohesity cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetAlertCategories(regionId?: string,
    accessClusterId?: number): __Observable<Array<AlertCategoryName>> {
    return this.GetAlertCategoriesResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as Array<AlertCategoryName>)
    );
  }

  /**
   * Gets all alert notification rules.
   *
   * Gets all alert notification rules containing criteria to deliver notification
   * to delivery targets such as email addresses, invoking external apis etc.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetNotificationRulesResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<Array<NotificationRule>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/alertNotificationRules`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<NotificationRule>>;
      })
    );
  }
  /**
   * Gets all alert notification rules.
   *
   * Gets all alert notification rules containing criteria to deliver notification
   * to delivery targets such as email addresses, invoking external apis etc.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetNotificationRules(regionId?: string,
    accessClusterId?: number): __Observable<Array<NotificationRule>> {
    return this.GetNotificationRulesResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as Array<NotificationRule>)
    );
  }

  /**
   * Updates an existing alert notification rule.
   *
   * Updates delivery targets such as email addresses and external apis in an
   * existing notification rule.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  UpdateNotificationRuleResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<NotificationRule>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/alertNotificationRules`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NotificationRule>;
      })
    );
  }
  /**
   * Updates an existing alert notification rule.
   *
   * Updates delivery targets such as email addresses and external apis in an
   * existing notification rule.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  UpdateNotificationRule(regionId?: string,
    accessClusterId?: number): __Observable<NotificationRule> {
    return this.UpdateNotificationRuleResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as NotificationRule)
    );
  }

  /**
   * Creates a new alert notification rule.
   *
   * Creates a new notification rule with provided delivery targets such as email
   * addresses and external apis.
   * @param params The `AlertsService.CreateNotificationRuleParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Create Notification Rule argument.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateNotificationRuleResponse(params: AlertsService.CreateNotificationRuleParams): __Observable<__StrictHttpResponse<NotificationRule>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/alertNotificationRules`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NotificationRule>;
      })
    );
  }
  /**
   * Creates a new alert notification rule.
   *
   * Creates a new notification rule with provided delivery targets such as email
   * addresses and external apis.
   * @param params The `AlertsService.CreateNotificationRuleParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Create Notification Rule argument.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateNotificationRule(params: AlertsService.CreateNotificationRuleParams): __Observable<NotificationRule> {
    return this.CreateNotificationRuleResponse(params).pipe(
      __map(_r => _r.body as NotificationRule)
    );
  }

  /**
   * Deletes an alert notification rule.
   *
   * Deletes an existing alert notification rule matching the rule id.
   * @param params The `AlertsService.DeleteNotificationRuleParams` containing the following parameters:
   *
   * - `ruleId`: Specifies the rule id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteNotificationRuleResponse(params: AlertsService.DeleteNotificationRuleParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/alertNotificationRules/${encodeURIComponent(params.ruleId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes an alert notification rule.
   *
   * Deletes an existing alert notification rule matching the rule id.
   * @param params The `AlertsService.DeleteNotificationRuleParams` containing the following parameters:
   *
   * - `ruleId`: Specifies the rule id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteNotificationRule(params: AlertsService.DeleteNotificationRuleParams): __Observable<null> {
    return this.DeleteNotificationRuleResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * List the Alert Resolutions on the Cohesity Cluster.
   *
   * Returns all Alert Resolution objects found on the Cohesity Cluster
   * that match the filter criteria specified using parameters.
   * If no filter parameters are specified,
   * all Alert Resolution objects are returned.
   * Each object provides details about the Alert Resolution such as
   * the resolution summary and details.
   * @param params The `AlertsService.GetResolutionsParams` containing the following parameters:
   *
   * - `maxResolutions`: Specifies the number of returned Resolutions to be returned. The newest
   *   Resolutions are returned.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `startDateUsecs`: Specifies Start Time Unix epoch in microseconds to filter resolutions by.
   *
   * - `resolutionIdList`: Specifies list of Alert Resolution ids to filter resolutions by.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `endDateUsecs`: Specifies End Time Unix epoch in microseconds to filter resolutions by.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `alertIdList`: Specifies list of Alert Resolution ids to filter resolutions by.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetResolutionsResponse(params: AlertsService.GetResolutionsParams): __Observable<__StrictHttpResponse<Array<AlertResolution>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.maxResolutions != null) __params = __params.set('maxResolutions', params.maxResolutions.toString());
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.startDateUsecs != null) __params = __params.set('startDateUsecs', params.startDateUsecs.toString());
    (params.resolutionIdList || []).forEach(val => {if (val != null) __params = __params.append('resolutionIdList', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.endDateUsecs != null) __params = __params.set('endDateUsecs', params.endDateUsecs.toString());
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    (params.alertIdList || []).forEach(val => {if (val != null) __params = __params.append('alertIdList', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/alertResolutions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AlertResolution>>;
      })
    );
  }
  /**
   * List the Alert Resolutions on the Cohesity Cluster.
   *
   * Returns all Alert Resolution objects found on the Cohesity Cluster
   * that match the filter criteria specified using parameters.
   * If no filter parameters are specified,
   * all Alert Resolution objects are returned.
   * Each object provides details about the Alert Resolution such as
   * the resolution summary and details.
   * @param params The `AlertsService.GetResolutionsParams` containing the following parameters:
   *
   * - `maxResolutions`: Specifies the number of returned Resolutions to be returned. The newest
   *   Resolutions are returned.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `startDateUsecs`: Specifies Start Time Unix epoch in microseconds to filter resolutions by.
   *
   * - `resolutionIdList`: Specifies list of Alert Resolution ids to filter resolutions by.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `endDateUsecs`: Specifies End Time Unix epoch in microseconds to filter resolutions by.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `alertIdList`: Specifies list of Alert Resolution ids to filter resolutions by.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetResolutions(params: AlertsService.GetResolutionsParams): __Observable<Array<AlertResolution>> {
    return this.GetResolutionsResponse(params).pipe(
      __map(_r => _r.body as Array<AlertResolution>)
    );
  }

  /**
   * Create an Alert Resolution.
   *
   * Create an Alert Resolution and apply it to one or more Alerts.
   * Mark the Alerts as resolved.
   * @param params The `AlertsService.CreateResolutionParams` containing the following parameters:
   *
   * - `body`: Request to create an Alert Resolution and apply it to the specified Alerts.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateResolutionResponse(params: AlertsService.CreateResolutionParams): __Observable<__StrictHttpResponse<AlertResolution>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/alertResolutions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AlertResolution>;
      })
    );
  }
  /**
   * Create an Alert Resolution.
   *
   * Create an Alert Resolution and apply it to one or more Alerts.
   * Mark the Alerts as resolved.
   * @param params The `AlertsService.CreateResolutionParams` containing the following parameters:
   *
   * - `body`: Request to create an Alert Resolution and apply it to the specified Alerts.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateResolution(params: AlertsService.CreateResolutionParams): __Observable<AlertResolution> {
    return this.CreateResolutionResponse(params).pipe(
      __map(_r => _r.body as AlertResolution)
    );
  }

  /**
   * List details about a single Alert Resolution.
   *
   * Returns the Alert Resolution object corresponding to passed in Alert
   * Resolution Id.
   * @param params The `AlertsService.GetResolutionByIdParams` containing the following parameters:
   *
   * - `id`: Unique id of the Alert Resolution to return.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetResolutionByIdResponse(params: AlertsService.GetResolutionByIdParams): __Observable<__StrictHttpResponse<AlertResolution>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/alertResolutions/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AlertResolution>;
      })
    );
  }
  /**
   * List details about a single Alert Resolution.
   *
   * Returns the Alert Resolution object corresponding to passed in Alert
   * Resolution Id.
   * @param params The `AlertsService.GetResolutionByIdParams` containing the following parameters:
   *
   * - `id`: Unique id of the Alert Resolution to return.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetResolutionById(params: AlertsService.GetResolutionByIdParams): __Observable<AlertResolution> {
    return this.GetResolutionByIdResponse(params).pipe(
      __map(_r => _r.body as AlertResolution)
    );
  }

  /**
   * Apply an existing Alert Resolution to additional Alerts.
   *
   * Apply an existing Alert Resolution to one or more additional Alerts.
   * Mark those additional Alerts as resolved.
   * @param params The `AlertsService.UpdateResolutionParams` containing the following parameters:
   *
   * - `id`: Unique id of the Alert Resolution to return.
   *
   * - `body`: Request to apply an existing resolution to the specified Alerts.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateResolutionResponse(params: AlertsService.UpdateResolutionParams): __Observable<__StrictHttpResponse<AlertResolution>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/alertResolutions/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AlertResolution>;
      })
    );
  }
  /**
   * Apply an existing Alert Resolution to additional Alerts.
   *
   * Apply an existing Alert Resolution to one or more additional Alerts.
   * Mark those additional Alerts as resolved.
   * @param params The `AlertsService.UpdateResolutionParams` containing the following parameters:
   *
   * - `id`: Unique id of the Alert Resolution to return.
   *
   * - `body`: Request to apply an existing resolution to the specified Alerts.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateResolution(params: AlertsService.UpdateResolutionParams): __Observable<AlertResolution> {
    return this.UpdateResolutionResponse(params).pipe(
      __map(_r => _r.body as AlertResolution)
    );
  }

  /**
   * Get registered alerts in the Cohesity cluster.
   *
   * Returns registered alerts in the Cohesity cluster that match the filter
   * criteria specified using parameters. If no filter parameters are specified,
   * all registered alerts in the Cohesity cluster are returned.
   * @param params The `AlertsService.GetAlertTypesParams` containing the following parameters:
   *
   * - `body`: Get alert types params.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAlertTypesResponse(params: AlertsService.GetAlertTypesParams): __Observable<__StrictHttpResponse<Array<AlertMetadata>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/alertTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AlertMetadata>>;
      })
    );
  }
  /**
   * Get registered alerts in the Cohesity cluster.
   *
   * Returns registered alerts in the Cohesity cluster that match the filter
   * criteria specified using parameters. If no filter parameters are specified,
   * all registered alerts in the Cohesity cluster are returned.
   * @param params The `AlertsService.GetAlertTypesParams` containing the following parameters:
   *
   * - `body`: Get alert types params.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAlertTypes(params: AlertsService.GetAlertTypesParams): __Observable<Array<AlertMetadata>> {
    return this.GetAlertTypesResponse(params).pipe(
      __map(_r => _r.body as Array<AlertMetadata>)
    );
  }

  /**
   * List the Alerts on the Cohesity Cluster.
   *
   * Returns all Alert objects found on the Cohesity Cluster that
   * match the filter criteria specified using parameters.
   * The Cohesity Cluster creates an Alert when a potential problem
   * is found or when a threshold has been exceeded on the Cohesity Cluster.
   * If no filter parameters are specified, all Alert objects are returned.
   * Each object provides details about the Alert such as the Status and Severity.
   * @param params The `AlertsService.GetAlertsParams` containing the following parameters:
   *
   * - `maxAlerts`: Specifies the number of returned Alerts to be returned. The newest Alerts
   *   are returned.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `startDateUsecs`: Specifies start time Unix epoch time in microseconds to filter alerts by.
   *
   * - `resolutionIdList`: Specifies alert resolution ids to filter alerts by.
   *
   * - `propertyValue`: Specifies value of the property to filter alerts by.
   *
   * - `propertyKey`: Specifies name of the property to filter alerts by.
   *
   * - `endDateUsecs`: Specifies end time Unix epoch time in microseconds to filter alerts by.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `alertTypeList`: Specifies list of Alert Types to filter alerts by.
   *
   * - `alertTypeBucketList`: Specifies the list of Alert type bucket.
   *   Specifies the Alert type bucket.
   *   kHardware - Alerts related to hardware on which Cohesity software is running.
   *   kSoftware - Alerts which are related to software components.
   *   kDataService - Alerts related to data services.
   *   kMaintenance - Alerts relates to maintenance activities.
   *
   * - `alertStateList`: Specifies list of Alert States to filter alerts by.
   *
   * - `alertSeverityList`: Specifies list of Alert severity to filter alerts by.
   *
   * - `alertName`: Specifies Alert name to filter alerts by.
   *
   * - `alertIdList`: Specifies list of Alert ids to filter alerts by.
   *
   * - `alertCategoryList`: Specifies list of Alert Categories.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAlertsResponse(params: AlertsService.GetAlertsParams): __Observable<__StrictHttpResponse<Array<Alert>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.maxAlerts != null) __params = __params.set('maxAlerts', params.maxAlerts.toString());
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.startDateUsecs != null) __params = __params.set('startDateUsecs', params.startDateUsecs.toString());
    (params.resolutionIdList || []).forEach(val => {if (val != null) __params = __params.append('resolutionIdList', val.toString())});
    if (params.propertyValue != null) __params = __params.set('propertyValue', params.propertyValue.toString());
    if (params.propertyKey != null) __params = __params.set('propertyKey', params.propertyKey.toString());
    if (params.endDateUsecs != null) __params = __params.set('endDateUsecs', params.endDateUsecs.toString());
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    (params.alertTypeList || []).forEach(val => {if (val != null) __params = __params.append('alertTypeList', val.toString())});
    (params.alertTypeBucketList || []).forEach(val => {if (val != null) __params = __params.append('alertTypeBucketList', val.toString())});
    (params.alertStateList || []).forEach(val => {if (val != null) __params = __params.append('alertStateList', val.toString())});
    (params.alertSeverityList || []).forEach(val => {if (val != null) __params = __params.append('alertSeverityList', val.toString())});
    if (params.alertName != null) __params = __params.set('alertName', params.alertName.toString());
    (params.alertIdList || []).forEach(val => {if (val != null) __params = __params.append('alertIdList', val.toString())});
    (params.alertCategoryList || []).forEach(val => {if (val != null) __params = __params.append('alertCategoryList', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/alerts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Alert>>;
      })
    );
  }
  /**
   * List the Alerts on the Cohesity Cluster.
   *
   * Returns all Alert objects found on the Cohesity Cluster that
   * match the filter criteria specified using parameters.
   * The Cohesity Cluster creates an Alert when a potential problem
   * is found or when a threshold has been exceeded on the Cohesity Cluster.
   * If no filter parameters are specified, all Alert objects are returned.
   * Each object provides details about the Alert such as the Status and Severity.
   * @param params The `AlertsService.GetAlertsParams` containing the following parameters:
   *
   * - `maxAlerts`: Specifies the number of returned Alerts to be returned. The newest Alerts
   *   are returned.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `startDateUsecs`: Specifies start time Unix epoch time in microseconds to filter alerts by.
   *
   * - `resolutionIdList`: Specifies alert resolution ids to filter alerts by.
   *
   * - `propertyValue`: Specifies value of the property to filter alerts by.
   *
   * - `propertyKey`: Specifies name of the property to filter alerts by.
   *
   * - `endDateUsecs`: Specifies end time Unix epoch time in microseconds to filter alerts by.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `alertTypeList`: Specifies list of Alert Types to filter alerts by.
   *
   * - `alertTypeBucketList`: Specifies the list of Alert type bucket.
   *   Specifies the Alert type bucket.
   *   kHardware - Alerts related to hardware on which Cohesity software is running.
   *   kSoftware - Alerts which are related to software components.
   *   kDataService - Alerts related to data services.
   *   kMaintenance - Alerts relates to maintenance activities.
   *
   * - `alertStateList`: Specifies list of Alert States to filter alerts by.
   *
   * - `alertSeverityList`: Specifies list of Alert severity to filter alerts by.
   *
   * - `alertName`: Specifies Alert name to filter alerts by.
   *
   * - `alertIdList`: Specifies list of Alert ids to filter alerts by.
   *
   * - `alertCategoryList`: Specifies list of Alert Categories.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAlerts(params: AlertsService.GetAlertsParams): __Observable<Array<Alert>> {
    return this.GetAlertsResponse(params).pipe(
      __map(_r => _r.body as Array<Alert>)
    );
  }

  /**
   * List details about a single Alert.
   *
   * Returns the Alert object corresponding to the specified id.
   * @param params The `AlertsService.GetAlertByIdParams` containing the following parameters:
   *
   * - `id`: Unique id of the Alert to return.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAlertByIdResponse(params: AlertsService.GetAlertByIdParams): __Observable<__StrictHttpResponse<Alert>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/alerts/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Alert>;
      })
    );
  }
  /**
   * List details about a single Alert.
   *
   * Returns the Alert object corresponding to the specified id.
   * @param params The `AlertsService.GetAlertByIdParams` containing the following parameters:
   *
   * - `id`: Unique id of the Alert to return.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAlertById(params: AlertsService.GetAlertByIdParams): __Observable<Alert> {
    return this.GetAlertByIdResponse(params).pipe(
      __map(_r => _r.body as Alert)
    );
  }
}

module AlertsService {

  /**
   * Parameters for CreateNotificationRule
   */
  export interface CreateNotificationRuleParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Create Notification Rule argument.
     */
    body?: NotificationRule;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteNotificationRule
   */
  export interface DeleteNotificationRuleParams {

    /**
     * Specifies the rule id.
     */
    ruleId: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetResolutions
   */
  export interface GetResolutionsParams {

    /**
     * Specifies the number of returned Resolutions to be returned. The newest
     * Resolutions are returned.
     */
    maxResolutions: number;

    /**
     * TenantIds contains ids of the tenants for which objects are to be
     * returned.
     */
    tenantIds?: Array<string>;

    /**
     * Specifies Start Time Unix epoch in microseconds to filter resolutions by.
     */
    startDateUsecs?: number;

    /**
     * Specifies list of Alert Resolution ids to filter resolutions by.
     */
    resolutionIdList?: Array<number>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies End Time Unix epoch in microseconds to filter resolutions by.
     */
    endDateUsecs?: number;

    /**
     * AllUnderHierarchy specifies if objects of all the tenants under the
     * hierarchy of the logged in user's organization should be returned.
     */
    allUnderHierarchy?: boolean;

    /**
     * Specifies list of Alert Resolution ids to filter resolutions by.
     */
    alertIdList?: Array<string>;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateResolution
   */
  export interface CreateResolutionParams {

    /**
     * Request to create an Alert Resolution and apply it to the specified Alerts.
     */
    body: AlertResolutionRequest;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetResolutionById
   */
  export interface GetResolutionByIdParams {

    /**
     * Unique id of the Alert Resolution to return.
     */
    id: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateResolution
   */
  export interface UpdateResolutionParams {

    /**
     * Unique id of the Alert Resolution to return.
     */
    id: number;

    /**
     * Request to apply an existing resolution to the specified Alerts.
     */
    body: UpdateResolutionParams;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetAlertTypes
   */
  export interface GetAlertTypesParams {

    /**
     * Get alert types params.
     */
    body: GetAlertTypesParams;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetAlerts
   */
  export interface GetAlertsParams {

    /**
     * Specifies the number of returned Alerts to be returned. The newest Alerts
     * are returned.
     */
    maxAlerts: number;

    /**
     * TenantIds contains ids of the tenants for which objects are to be
     * returned.
     */
    tenantIds?: Array<string>;

    /**
     * Specifies start time Unix epoch time in microseconds to filter alerts by.
     */
    startDateUsecs?: number;

    /**
     * Specifies alert resolution ids to filter alerts by.
     */
    resolutionIdList?: Array<number>;

    /**
     * Specifies value of the property to filter alerts by.
     */
    propertyValue?: string;

    /**
     * Specifies name of the property to filter alerts by.
     */
    propertyKey?: string;

    /**
     * Specifies end time Unix epoch time in microseconds to filter alerts by.
     */
    endDateUsecs?: number;

    /**
     * AllUnderHierarchy specifies if objects of all the tenants under the
     * hierarchy of the logged in user's organization should be returned.
     */
    allUnderHierarchy?: boolean;

    /**
     * Specifies list of Alert Types to filter alerts by.
     */
    alertTypeList?: Array<number>;

    /**
     * Specifies the list of Alert type bucket.
     * Specifies the Alert type bucket.
     * kHardware - Alerts related to hardware on which Cohesity software is running.
     * kSoftware - Alerts which are related to software components.
     * kDataService - Alerts related to data services.
     * kMaintenance - Alerts relates to maintenance activities.
     */
    alertTypeBucketList?: Array<'kHardware' | 'kSoftware' | 'kDataService' | 'kMaintenance'>;

    /**
     * Specifies list of Alert States to filter alerts by.
     */
    alertStateList?: Array<'kOpen' | 'kResolved' | 'kAlertSuppressed'>;

    /**
     * Specifies list of Alert severity to filter alerts by.
     */
    alertSeverityList?: Array<'kCritical' | 'kWarning' | 'kInfo'>;

    /**
     * Specifies Alert name to filter alerts by.
     */
    alertName?: string;

    /**
     * Specifies list of Alert ids to filter alerts by.
     */
    alertIdList?: Array<string>;

    /**
     * Specifies list of Alert Categories.
     */
    alertCategoryList?: Array<'kDisk' | 'kNode' | 'kCluster' | 'kChassis' | 'kPowerSupply' | 'kCPU' | 'kMemory' | 'kTemperature' | 'kFan' | 'kNIC' | 'kFirmware' | 'kNodeHealth' | 'kOperatingSystem' | 'kDataPath' | 'kMetadata' | 'kIndexing' | 'kHelios' | 'kAppMarketPlace' | 'kLicense' | 'kSecurity' | 'kUpgrade' | 'kClusterManagement' | 'kAuditLog' | 'kNetworking' | 'kConfiguration' | 'kStorageUsage' | 'kFaultTolerance' | 'kBackupRestore' | 'kArchivalRestore' | 'kRemoteReplication' | 'kQuota' | 'kCDP' | 'kViewFailover' | 'kDisasterRecovery'>;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetAlertById
   */
  export interface GetAlertByIdParams {

    /**
     * Unique id of the Alert to return.
     */
    id: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { AlertsService }
