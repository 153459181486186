<cog-data-tree-table [data]="dataTreeSource" [treeControl]="dataTreeControl" [treeSelection]="dataTreeSelection">
  <mat-table>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef>
        {{ 'resources' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let ctx" [ngClass]="{ disabled: true }">
        <cog-data-tree-table-row-expand [cogDataId]="ctx.node.name" [context]="ctx"></cog-data-tree-table-row-expand>
        <cog-data-tree-node-checkbox
          [nodeContext]="ctx"
          cogDataId="data-tree-node-{{ ctx.node.name }}-checkbox"
          (userSelectionChange)="handleUserSelectionChange()"
          autoSelectedIcon="iris:union-border"
          [checkboxTooltipFn]="getAutoSelectTooltipText">
        </cog-data-tree-node-checkbox>
        <div class="flex-truncate-wrapper" [matTooltip]="ctx.node.name">
          <span class="flex-truncate-text">
            {{ ctx.node.type === ResourceType.Service ? (ctx.node.name | translate ) : ctx.node.name }}
          </span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef> </mat-header-cell>
      <mat-cell *matCellDef="let ctx">
        <coh-resources-tree-auto-assign
          [nodeContext]="ctx"
          (userSelectionChange)="handleAutoAssignSelectionChange($event)"></coh-resources-tree-auto-assign>
      </mat-cell>
    </ng-container>

    <!-- Table header row -->
    <mat-header-row *matHeaderRowDef="columnDef"></mat-header-row>

    <!-- Body row -->
    <mat-row *matRowDef="let row; columns: columnDef"></mat-row>
  </mat-table>
</cog-data-tree-table>
