// Reports: Protection Details Per Object

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.reports')
    .controller('reportsProtectionDetailsController', reportsProtectionDetailsControllerFn);

  function reportsProtectionDetailsControllerFn($scope, $state, $q,
    ReportsService, ReportsUtil, evalAJAX, ProtectionDetailsUtil, SourceService,
    cReportsControlsUtil, SOURCE_OBJECT_SCHEDULE_TYPE, ENUM_SNAPSHOT_STATUS,
    SOURCE_OBJECT_STATUS_LABEL_CLASSNAME) {

    $scope.filters = cReportsControlsUtil.filters;

    $scope.protectionDetailsConfig =
      ReportsUtil.getReportConfig('kProtectionDetailsPerObjectReport')();

    $scope.getJobRuns = getJobRuns;
    $scope.updateFiltersCb = updateFiltersCb;
    $scope.downloadProtectionDetailsObjectReport =
      downloadProtectionDetailsObjectReport;

    $scope.SOURCE_OBJECT_SCHEDULE_TYPE = SOURCE_OBJECT_SCHEDULE_TYPE;
    $scope.ENUM_SNAPSHOT_STATUS = ENUM_SNAPSHOT_STATUS;
    $scope.SOURCE_OBJECT_STATUS_LABEL_CLASSNAME = SOURCE_OBJECT_STATUS_LABEL_CLASSNAME;

    $scope.objects = [];

    /**
     * Loads Controller Data
     *
     * If we are deep linking we need to load data based of query params
     * in the URL
     *
     * Calls getEntities Method to populate data for Type Ahead
     *
     */
    function activate() {
      $scope.dataset = {};

      cReportsControlsUtil.getDefaultFilters();

      $q.all($scope.protectionDetailsConfig.datasetPromiseObject).then(
        function getDataset(dataset) {
          $scope.dataset = dataset;

          if ($state.params.objectId) {
            cReportsControlsUtil.updateFilters({
              objectId: $state.params.objectId,
              multiSelectTypeAhead: buildFilterObjects($state.params.objectId),
            });

            getJobRuns(cReportsControlsUtil.filters);
          }

          if ($state.params.objectName) {
            $scope.objectNames = $state.params.objectName;
          }
        }, evalAJAX.errorMessage)
      .finally(function getDatasetFinally() {
        $scope.reportsControlsDataReady = true;
      });
    }

    /**
     * if the user is deeplinking into this report, objects need to be rebuilt
     * to be properly displayed in the filters controls
     *
     * @param     {Array}    objectId   the id or ids we want to filter by
     *
     * @return    {Array}               array of objects containing the id
     *                                  and name
     */
    function buildFilterObjects(objectId) {
      var objectIds = [].concat(objectId);

      return objectIds.map(function buildObject(id) {
        return {
          id: id,
          name: _.get(SourceService.entitiesHash[id], 'displayName'),
        };
      });
    }

    /**
     * returns params object for API Call
     *
     * @param      {object}    filters    filters from c-reports-controls
     *
     * @return {object} sets values from filters used in API Call
     */
    function getParams(filters) {
      return {
        protectionSourceIds: filters.objectId,
        startTimeUsecs: filters.timeObject.from.toUsecDate(),
        endTimeUsecs: filters.timeObject.until.toUsecDate(),
        runStatus: filters.runStatus || [],
      };
    }

    /**
     * Refreshes data when applying new filters
     *
     * @param      {object}    filters    filters from c-reports-controls
     */
    function getJobRuns(filters) {
      $scope.objects.length = 0;
      $scope.objectGroups = {};

      ReportsService.getSourcesJobsRun(getParams(filters))
        .then(function getRuns(protectionSourceJobRuns) {
          $scope.objects = protectionSourceJobRuns;

          if ($scope.objects.length) {
            $scope.objectGroups =
              ProtectionDetailsUtil.groupObjects($scope.objects, 'protectionSource.name');

            calculateSummaryStats();
          }

          $scope.dataReady = true;
        }, evalAJAX.errorMessage);
    }

    /**
     * Call util methods to populate values for summary
     * stats row
     */
    function calculateSummaryStats() {
      var snapshots = ProtectionDetailsUtil.getSortedSnapshots($scope.objects);
      var lastRunSuccessStats =
        ProtectionDetailsUtil.getStatsSinceLastSuccessfulRun(snapshots);

      $scope.summaryStats = {
        snapshotsCount: snapshots.length,
        totalErrors: ProtectionDetailsUtil.getTotalErrorCount(snapshots),
        errorsSinceLastRun: lastRunSuccessStats.errorCount,
        lastRunSuccess: lastRunSuccessStats.lastRunSuccess,
        lastRunSuccessType: lastRunSuccessStats.runType
      };
    }

    /**
     * Initiates CSV download
     */
    function downloadProtectionDetailsObjectReport() {
      var params = getParams(cReportsControlsUtil.filters);
      params.outputFormat = 'csv';

      ReportsService.getSourcesJobsRun(params);
    }

    /**
     * When new filters are set update the url for context
     *
     * This is how you update the browser URL without reloading the
     * controller.
     *
     * Also we need to call getJobRuns after filters are updated to get new
     * data based off updated filters.
     *
     * @param      {object}    filters    filters from c-reports-controls
     */
    function updateFiltersCb(filters) {
      if (!filters.objectId || !filters.objectId.length) {
        resetReport();

        return;
      }

      $scope.objectNames =
        filters.multiSelectTypeAhead.map(function mapItems(item) {
          return item.name;
        });

      // These are dynamic params and will update without a state change.
      $state.go('.', {
        objectId: filters.objectId,
        objectName: $scope.objectNames,
      });

      getJobRuns(filters);
    }

    /**
     * if the user clears the objects filter this resets the scope values,
     * so the UI can go back to its initial state
     */
    function resetReport() {
      $scope.objects.length = 0;
      $scope.dataReady = false;
      $scope.objectGroups = {};

      // These are dynamic params and will update without a state change.
      $state.go('.', {
        objectId: null,
        objectName: null,
      });
    }

    activate();
  }
})(angular);
