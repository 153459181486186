import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';

import { AngularMaterialModule } from '../../angular-material.module';
import { HelixIntlService } from '../../helix-intl.service';
import { DataIdModule } from '../data-id/data-id.module';
import { IconModule } from '../icon/icon.module';
import { RowIdModule } from '../row-id/row-id.module';
import { PaginatorIntlService } from './paginator-intl.service';
import { TableActionsRowDirective } from './table-actions-row.directive';
import { TableConfigFormComponent } from './table-config-form/table-config-form.component';
import { TableMultiActionComponent } from './table-multi-action/table-multi-actions.component';
import { TableSettingsMenuComponent } from './table-settings-menu/table-settings-menu.component';
import { TableComponent } from './table.component';

@NgModule({
  imports: [
    AngularMaterialModule,
    CommonModule,
    DataIdModule,
    DragDropModule,
    FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    IconModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    RowIdModule,
  ],
  declarations: [
    TableActionsRowDirective,
    TableComponent,
    TableConfigFormComponent,
    TableMultiActionComponent,
    TableSettingsMenuComponent,
  ],
  exports: [
    AngularMaterialModule,
    TableActionsRowDirective,
    TableComponent,
    TableConfigFormComponent,
    TableMultiActionComponent,
    TableSettingsMenuComponent,
  ],
  providers: [{
    provide: MatPaginatorIntl, useClass: PaginatorIntlService, deps: [HelixIntlService]
  }]
})
export class TableModule {}
