import { AfterViewInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { ItemPickerFormControl } from '../item-picker-form-control';

interface Timezone {
  // Timezone String
  value: string;

  // Timezone String in LowerCase
  compareValue: string;
}

/**
 * Create a timezone object from timezone string
 *
 * @param timezone timezone string
 * @returns timezone object
 */
const makeTimeZoneObject = (timezone: string): Timezone => ({
  value: timezone,
  compareValue: timezone.toLowerCase()
});

/**
 * List of Timezone objects
 */
export const TIMEZONES: Timezone[] =
  moment.tz.names().map(makeTimeZoneObject);

/**
 * Timezone selector component.
 *
 * @example
 *  <coh-timezone-selector label="label" editMode?="true" formControlName="timeZone">
 *  </coh-timezone-selector>
 */
@Component({
  selector: 'coh-timezone-selector',
  templateUrl: './timezone-selector.component.html',
  styleUrls: ['./timezone-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TimezoneSelectorComponent,
      multi: true,
    },
  ]
})
export class TimezoneSelectorComponent extends ItemPickerFormControl<string> implements AfterViewInit {
  /**
   * FormControl instance to listen to input changes
   */
  formControl = new UntypedFormControl();

  /**
   * List of all possible timezones
   */
  private options: Timezone[] = TIMEZONES;

  /**
   * List of options filtered based on the input
   */
  filteredOptions: Observable<Timezone[]>;

  /**
   * Label to show above the input
   */
  @Input() label: string;

  /**
   * Boolean when true renders the picker in editMode
   */
  @Input() editMode = true;

  /**
   * On init life cycle method
   */
  ngAfterViewInit() {
    const initVal = this.value;
    this.formControl.setValue(initVal);
    this.filteredOptions = this.formControl.valueChanges
      .pipe(
        startWith(initVal),
        map(value => this.filterTimezones(value))
      );
  }

  /**
   * Filter options based on value
   *
   * @param value input value to filter the options
   * @returns Array of filtered options
   */
  private filterTimezones(inputValue: string): Timezone[] {
    const filterValue = inputValue.toLowerCase();

    return this.options.filter(({ compareValue }) =>
      compareValue.includes(filterValue));
  }

  /**
   * On value change event
   *
   * @param event: MatAutocompleteSelectedEvent object from selected option
   */
  onValueChange(event: MatAutocompleteSelectedEvent) {
    this.value = event.option.value;
    this.formControl.setValue(this.value);
  }
}
