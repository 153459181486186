<div class="margin-bottom">
  <h1 translate>supportUser</h1>
  <div class="row margin-bottom-lg align-items-center">
    <div class="col-xs-6">
      <h3 class="margin-bottom-xs" translate>password</h3>
      <p translate>supportUser.password.description</p>
    </div>
    <div class="col-xs-3">
      <button type="button" cogDataId="add-button" color="primary"
        (click)="openUpdatePassword()" mat-stroked-button
        [disabled]="isSupportUserInfoLoading">
        {{(isPasswordSet ? 'updatePassword': 'setPassword') | translate}}
      </button>
    </div>
  </div>
</div>
