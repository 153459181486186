<cog-settings-list-item [invalid]="formGroupErrors"
  cogFormGroup
  [label]="'job.settings.leverageSanTransportForCopyRecovery' | translate">
  <cog-settings-list-item-value-display>
    <ul class="c-ul-inline">
      <li>
          {{ formGroupValues.leverageSanTransport | boolLabel: 'on' : 'off' }}
      </li>
      <li *ngIf="formGroupValues.leverageSanTransport">
        {{'allowNbdsslTransportFallback' | translate}} : {{ formGroupValues.allowNBDSSLTransportFallback | boolLabel: 'on' : 'off' }}
      </li>
      <li *ngIf="formGroupValues.leverageSanTransport">
          {{'vmRecoveryDiskProvisioning' | translate}} : {{getSelectedDiskProvisioning | translate}}
      </li>
    </ul>
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor>
    <mat-slide-toggle [formControl]="formGroupControls.leverageSanTransport"
      [class.margin-bottom]="formGroupValues.leverageSanTransport"
      [inheritDataId]="true"
      cogDataId="leverage-san-transport-copy-recovery"
      class="toggle-control">
      {{'leverageSanTransport' | translate}}
    </mat-slide-toggle>
    <ng-container *ngIf="formGroupValues.leverageSanTransport">
      <mat-slide-toggle [formControl]="formGroupControls.allowNBDSSLTransportFallback"
        [inheritDataId]="true"
        cogDataId="allow-nbdssl-transport-copy-recovery"
        class="toggle-control margin-bottom">
        {{'allowNbdsslTransportFallback' | translate}}
      </mat-slide-toggle>
      <ng-container *ngIf="displayCustomizeDiskProvisioningOption">
          <mat-slide-toggle [formControl]="formGroupControls.customizeDiskProvisioning"
          [inheritDataId]="true"
          cogDataId="customize-disk-provisioning"
          class="toggle-control margin-bottom">
          {{'customizeDiskProvisioning' | translate}}
          </mat-slide-toggle>
          <ng-container *ngIf="formGroupValues.customizeDiskProvisioning">
              <mat-form-field>
                <mat-label translate>vmRecoveryDiskProvisioning</mat-label>
                <mat-select [formControl]="formGroupControls.diskProvisionType"
                  [inheritDataId]="true"
                  cogDataId="select">
                  <mat-option [inheritDataId]="true"
                    cogDataId="option-thick-lazy"
                    value="kThickLazyZeroed">
                    {{ 'vmRecoveryDiskProvisioningThickProvisionLazyZeroed'  | translate }}
                  </mat-option>

                  <mat-option [inheritDataId]="true"
                    cogDataId="option-thick-eager"
                    value="kThickEagerZero">
                    {{ 'vmRecoveryDiskProvisioningThickProvisionEagerlyZeroed'  | translate }}
                  </mat-option>

                  <mat-option [inheritDataId]="true"
                    cogDataId="option-thin"
                    value="kThin">
                    {{ 'vmRecoveryDiskProvisioningThinProvision'  | translate }}
                  </mat-option>

                  <mat-option [inheritDataId]="true"
                    cogDataId="original-backup-disk"
                    value="kBackedUpDiskType">
                    {{ 'vmRecoveryDiskProvisioningBackedupDisktype'  | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <p class="c-message-inline icon-message info no-margin-bottom">
                  {{'recovery.vm.customDiskProvisioningInfo' | translate}}
              </p>
          </ng-container>
      </ng-container>
      <p *ngIf="!displayCustomizeDiskProvisioningOption || !formGroupValues.customizeDiskProvisioning"
        class="c-message-inline icon-message info no-margin-bottom">
        {{'recovery.vm.diskProvisioningInfo' | translate}}
      </p>
    </ng-container>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
