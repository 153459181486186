import { Injectable } from '@angular/core';
import { Cluster, ClusterServiceApi, NetworkInterface, Vlan, VlanServiceApi } from '@cohesity/api/v1';
import { BehaviorSubject, Observable, forkJoin, from } from 'rxjs';
import { map, mergeMap, tap, toArray } from 'rxjs/operators';

import { AjsUpgradeService, ClusterService } from 'src/app/core/services';
import { AjsNetworkService } from 'src/app/models/ajs-network-service';
import { AppSettings } from '../models/app-settings';

/** Batch size while updating vlans */
const updateVlanBatchSize = 2;

@Injectable({
  providedIn: 'root',
})
export class AppsService {
  /** Publish updates to app settings */
  appSettings$: Observable<AppSettings>;

  /** Leagcy Network service */
  networkService: AjsNetworkService;

  /** Stores current value of the appSettings and publishes updates to it. */
  private appSettingsSubject: BehaviorSubject<AppSettings>;

  constructor(
    private clusterServiceApi: ClusterServiceApi,
    private vlanService: VlanServiceApi,
    private clusterService: ClusterService,
    private ajsUpgrade: AjsUpgradeService
  ) {
    this.appSettingsSubject = new BehaviorSubject<AppSettings>(new AppSettings({}));
    this.appSettings$ = this.appSettingsSubject.asObservable();
    this.networkService = this.ajsUpgrade.get('NetworkService');
  }

  /**
   * Initiates app setting fetch from server.
   *
   * App settings are divided into two apis, both of which are called and consolidated settings
   * returned.
   *
   * @returns   Obervable with consolidated app settings
   */
  fetchAppSettings(): Observable<AppSettings> {
    return forkJoin([
      this.clusterServiceApi.GetAppSettings(),
      this.vlanService.GetVlans({}),
      this.clusterService.getClusterInfo(),
      this.networkService.getInterfaces(['bond'], {})
    ]).pipe(
      map(([config, vlans, clusterInfo, vips]) => new AppSettings(
        config,
        vlans as Vlan[],
        clusterInfo as Cluster,
        vips as NetworkInterface[]
      )),
      tap(settings => this.appSettingsSubject.next(settings))
    );
  }

  /**
   * Saves all app settings.
   *
   * This includes vlan changes as well as app config.
   *
   * @param   settings   Settings to save.
   * @returns   Observable with appconfig
   */
  saveAppSettings(settings: AppSettings): Observable<AppSettings> {
    settings.vlans = this.transformVlans(settings.vlans);
    return from(settings.vlans).pipe(
      mergeMap((vlan: Vlan) => this.vlanService.UpdateVlan({ id: vlan.id, Body: vlan }), updateVlanBatchSize),
      toArray(),
      mergeMap(() =>
        this.clusterServiceApi.UpdateAppSettings({
          Body: settings.config,
        })
      ),
      map(config => new AppSettings(config))
    );
  }

  /**
   * Converts vlan objects into objects suitable for backend api.
   *
   * @param   vlans   List of vlans.
   * @returns   List of vlans
   */
  private transformVlans(vlans: Vlan[]): Vlan[] {
    return vlans.map(vlan => {
      // Api doesn't like setting netmaskBits and netmastIp4 both at same time.
      // However it returns both in get call.
      if (vlan.subnet && vlan.subnet.netmaskBits && vlan.subnet.netmaskIp4) {
        vlan.subnet.netmaskIp4 = undefined;
      }
      return vlan;
    });
  }
}
