import { Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from 'src/app/core/services/locale.service';

/**
 * Pipe to format a large number to a readable format specific to the active
 * locale.
 *
 * @example
 *   {{ 7148 | largeNumber }}  // '7.1K'
 *   {{ 4687524 | largeNumber }}  // '4.7M'
 *   {{ 100000000 | largeNumber }}  // '1.0B'
 *
 *    If the activeLocale is Japanese.
 *   {{ 4687524 | largeNumber }} //469万
 */
@Pipe({ name: 'largeNumber' })
export class LargeNumberPipe implements PipeTransform {

  constructor(private localeService: LocaleService) {}

  /**
   * Get the humanized format for large numbers.
   *
   * @param value The value to transform
   * @returns Formatted value
   */
  transform(value: number): string {
    const numberFormatter = Intl.NumberFormat(this.localeService.activeLocale, { notation: 'compact' });

    return numberFormatter.format(value || 0);
  }
}
