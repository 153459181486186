import { TranslateService } from '@ngx-translate/core';
import { hasClusters, getConfigByKey, IrisContext } from '../iris-context';
import {
  APPLIANCE_MANAGER_PILLAR_ITEM,
  AppType,
  GLOBAL_PILLAR_ITEM,
  HeliosLandingPageAppModel,
  Pillar,
  PillarItem,
  pillars,
  PillarType
} from './app-pillars.model';


/**
 * Gets all apps, including 5 pillar static landing pages.
 * Users can select one of these apps as the default landing page.
 *
 * @param irisContext iris context
 * @param translate translate service
 * @returns list of helios landing page apps.
 */
export function getAppLandingPages(
  irisContext: IrisContext,
  translate: TranslateService
): HeliosLandingPageAppModel[] {

  const effectiveAppList = getEffectiveAppList(irisContext);
  const removedApps: Set<AppType> = new Set<AppType>(
    effectiveAppList.filter(app => app.hidden).map(app => app.app)
  );
  return [
    {
      title: translate.instant('cohesityDataCloud'),
      app: 'cohesityDataCloud',
    },
    ...effectiveAppList.map(app => ({
      title: translate.instant('appKeyDashboard', {
        app: translate.instant(app.title),
      }),
      app: app.app,
    })).filter(app => !removedApps.has(app.app)),
  ];
}


/**
 * Merge Pillars with the override values deeply. It will override pillar properties
 * with the properties given in overrides. Each pillar is idenitifed by 'type' property
 * value. It will also override each of the pillar item properties with the properties given
 * in overrides. Each pillar item under a pillar is identified by 'app' property value.
 *
 * @param appPillars all pillars
 * @param overrides override values for pillars
 * @returns deep merged pillars
 */
export function mergePillarsOverrides(
  appPillars: Pillar[] = pillars,
  overrides: Partial<Pillar>[] = []
): Pillar[] {

  const overridesMap: Map<PillarType, Partial<Pillar>> = overrides.reduce(
    (mapping, pillar) => {
      mapping.set(pillar.type, pillar);
      return mapping;
    },
    new Map<PillarType, Partial<Pillar>>()
  );

  return appPillars.map(pillar => {
    if (!overridesMap.has(pillar.type)) {
      return pillar;
    }

    const { items, ...pillarInfo } = pillar;
    const {
      items: overridePillarItems = [],
      ...overridePillarInfo
    } = overridesMap.get(pillar.type);

    // Map of PillarItem identifier vs PillarItem object from the overrides.
    const pillarItemOverridesMap: Map<AppType, Partial<PillarItem>> = overridePillarItems.reduce(
      (mapping, pillarItem) => {
        mapping.set(pillarItem.app, pillarItem);
        return mapping;
      },
      new Map<AppType, Partial<PillarItem>>()
    );

    // Deep merge PillarItems.
    const mergedItems: PillarItem[] = items.map(item => {
      if (!pillarItemOverridesMap.has(item.app)) {
        return item;
      }

      return {
        ...item,
        ...pillarItemOverridesMap.get(item.app),
      };
    });

    // Deep merge the overall Pillar.
    return {
      ...pillarInfo,
      ...overridePillarInfo,
      items: mergedItems
    };
  });
}

/**
 * Determine if this app requires clusters and if the user has clusters claimed.
 *
 * @param app
 * @param ctx
 * @returns boolean if the user has met prerequisites for this app
 */
export function meetsClusterRequirmentsForApp(app: string, ctx: IrisContext) {
  const appRequiresClusters = [
    'clusterManager',
    'dgaas',
    'rpaas',
    'draas',
    'piaas',
    'smartFiles'
  ].includes(app);
  return (appRequiresClusters) ? hasClusters(ctx) : true;
}

/**
 * Get the effective list of pillar items after merging
 * the overrides with the defaults.
 *
 * @param irisContext current iris context
 * @returns final list of Pillar Items
 */
export function getEffectiveAppList(irisContext: IrisContext): PillarItem[] {
  // Pillar overrides from App Config.
  const pillarOverrides = getConfigByKey<Partial<Pillar>[]>(
    irisContext, 'appPillarsOverrides', []
  );
  const appPillars = mergePillarsOverrides(pillars, pillarOverrides);

  // get all pillar items with merged values.
  const appPillarItems = getAllPillarItems(appPillars);

  // for global, and appliance manager, merging has to be done explicitly.
  const globalPillarOverride = pillarOverrides.find(
    pillar => pillar.type === 'global'
  )?.items?.find(app => app.app === 'global');

  const appliancePillarOverride = pillarOverrides.find(
    pillar => pillar.type === 'appliance-manager'
  )?.items?.find(app => app.app === 'applianceManager');

  let globalPillarItem = GLOBAL_PILLAR_ITEM;
  let appliancePillarItem = APPLIANCE_MANAGER_PILLAR_ITEM;

  if (globalPillarOverride) {
    globalPillarItem = {
      ...GLOBAL_PILLAR_ITEM,
      ...globalPillarOverride
    };
  }

  if (appliancePillarOverride) {
    appliancePillarItem = {
      ...APPLIANCE_MANAGER_PILLAR_ITEM,
      ...appliancePillarOverride
    };
  }

  return [
    globalPillarItem,
    appliancePillarItem,
    ...appPillarItems
  ];
}

/**
 * Get all pillar items across given pillars.
 *
 * @param appPillars list of pillars
 * @returns list of all pillar items across given pillars
 */
function getAllPillarItems(appPillars: Partial<Pillar>[] = []): PillarItem[] {
  return appPillars.reduce(
    (allItems: PillarItem[], currentPillar: Partial<Pillar>) => {
      if (currentPillar.items) {
        return allItems.concat(
          currentPillar.items.map(app => ({ ...app, pillar: currentPillar.type }))
        );
      }
      return allItems;
    },
    []
  );
}
