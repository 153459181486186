import { Component, Input } from '@angular/core';

/**
 * Possible dashcard type values.
 */
type BlankCardType =
  | 'general'
  | 'cloud'
  | 'view'
  | 'cloud-dashboard'
  | 'file-services-dashboard'
  | 'data-protection-dashboard'
  | 'data-pool'
  | 'license-agreement'
  | 'data-site'
  | 'systems'
  | 'access'
  | 'backup-source-and-objects'
  | 'organization'
  | 'no-activity-found'
  | 'external-target-cloud'
  | 'gflag-recipes'
  | 'helios'
  | 'report-schedule'
  | 'shares'
  | 'no-antivirus-providers'
  | 'no-secured-views'
  | 'no-threats'
  | null;

/**
 * Possible container type values.
 */
type BlankCardContainerType = 'dashboard' | 'card' | null;

/**
 * This component renders no data state for dashcard.
 * TODO(Tung): move to helix cog-dashcard component?
 *
 */
@Component({
  selector: 'coh-blank-card',
  templateUrl: './blank-card.component.html',
  styleUrls: ['./blank-card.component.scss'],
})
export class BlankCardComponent {
  /**
   * Specifies the dashboard this card belongs to show corresponding no data image.
   * If type is set to 'custom', the parent component will set the custom data image.
   */
  @Input() type: BlankCardType = 'general';

  /**
   * Specifies if the blank card is contained in an individual card or a whole dashboard.
   * Default to card.
   */
  @Input() container: BlankCardContainerType = 'card';

  /**
   * Specifies the message below no data image. Default to "No Data Available".
   */
  @Input() message = 'noDataAvailable';
}
