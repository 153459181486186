import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { SeriesBarOptions, SeriesColumnOptions, XAxisOptions } from 'highcharts';
import { HighchartsComponent } from '../core';

@Component({
  selector: 'cog-stack-chart',
  templateUrl: './stacked-chart.component.html',
  styleUrls: ['./stacked-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class StackedChartComponent extends HighchartsComponent<SeriesBarOptions | SeriesColumnOptions> {
  /**
   * Color settings for stack chart. Use high chart default color if colorSetClass is not provided.
   */
  @Input() colorSetClass: string;

  /**
   * Optional list of categories
   */
  private _categories: string[] = [];

  /**
   * Sets stack chart categories.
   */
  @Input() set categories(categories: string[]) {
    this._categories = categories;
    this.render();
  }

  /**
   * Returns stack chart categories.
   */
  get categories(): string[] {
    return this._categories;
  }

  constructor() {
    super({
      chart: {
        type: 'column',
        styleMode: true,
      },

      title: null,

      legend: {
        enabled: false,
      },

      credits: {
        enabled: false,
      },

      xAxis: {
        visible: false,
      },

      yAxis: {
        visible: false,
      },

      plotOptions: {
        series: {
          stacking: 'normal'
        }
      },
    });
  }

  /**
   * Updates stack chart using current chart options.
   */
  render() {
    const { chartOptions } = this;

    const xAxis = chartOptions.xAxis as XAxisOptions;
    xAxis.categories = this._categories;

    super.render();
  }
}
