// Initial app set up
import { appTransitionHook } from './app-transition-hook.js';

(function(angular, undefined) {
  'use strict';

  var C = {};

  var cAppInjections = [
    // Angular Dependencies
    'ngAnimate',
    'ngRoute',
    'ngMessages',
    'ngSanitize',
    'ngFileUpload',
    'LocalStorageModule',
    'ui.router',
    'ui.router.upgrade',
    'ui.bootstrap',
    'ui.ace',
    'ui.select',
    'ui.mask',
    'smart-table',
    'treeControl',
    'multiStepForm',
    'ngclipboard',
    'finderTree',
    'smoothScroll',
    'pascalprecht.translate',
    'tmh.dynamicLocale',
    'puElasticInput',

    // Cohesity angular Dependencies
    'C.NgService',

    // Cohesity Dependencies
    // Global
    'C.appConfigs',
    'C.constants',
    'C.browseForTarget',
    'C.controlboard',
    'C.entityTree',
    'C.focus',
    'C.help',
    'C.contentEditable',
    'C.accessibility',
    'C.interpolate',
    'C.nav',
    'C.pageHeader',
    'C.rolePrivileges',
    'C.actionsGroup',
    'C.persistConfig',
    'C.restoreProgress',
    'C.sourceSANDetails',
    'C.sourceViews',
    'C.statusIconContainer',
    'C.stExtensions',
    'C.cCopy',
    'C.vip',
    'C.import',
    'C.vlanSelector',
    'C.VMBrowser',
    'C.stateManagement',

    // custom form elements
    'C.select',
    'C.rangeSlider',
    'C.multiselect',
    'C.search',
    'C.toggle',
    'C.stickyFooter',
    'C.selectDays',
    'C.selectExternalTarget',
    'C.selectSource',

    // Helios
    'C.helios',

    // Salesforce account interceptor (Helios only)
    'C.salesforceAccountInterceptor',

    // Data migration
    'C.dataMigration',

    // User
    'C.login',
    'C.snmp',
    'C.activeDirectory',
    'C.ldap',
    'C.idp',
    'C.accessManagement',
    'C.userGroups',
    'C.manageRoles',
    'C.tenants',
    'C.apiKeys',

    // Injections previously from dashboard module
    'C.widgets',
    'C.createJobButton',
    'C.createRecoverButton',
    'C.jobRunsService',
    'C.dashboardService',
    'C.sqlDashboard',

    // Cluster Management
    'C.cluster',
    'C.clusterSummary',
    'C.clusterSetup',
    'C.clusterDetails',
    'C.clusterUpgrade',
    'C.remoteClusters',
    'C.partitions',
    'C.nodes',
    'C.vlan',
    'C.addNodes',
    'C.viewBoxes',
    'C.events',
    'C.sources',
    'C.externalTargets',
    'C.audit',
    'C.auditCluster',
    'C.auditEditSettings',
    'C.networking',

    // Cloud Edition Manager
    'C.cloudEditionManager',

    // Services
    // TODO: Move these to be dependencies of the modules that need them, not
    // the entire app.
    'C.externalTargetService',
    'C.jobService',
    'C.restoreService',
    'C.searchService',
    'C.sourceService',

    // Restore
    'C.restore',
    'C.restoreTaskSummary',
    'C.restorePure',
    'C.restoreNas',
    'C.tapes',

    //License
    'C.licenseServer',
    'C.licenseServerService',

    // Recover
    'C.fileRecovery',
    'C.physicalServerRecovery',
    'C.recovery',
    'C.recoverAsMountPoint',
    'C.storageVolumeRecovery',
    'C.office365Recovery',
    'C.rdsRecovery',

    // Dev-Ops
    'C.devOps',
    'C.cloneView',

    // Cloud Retrieval
    'C.cloud-retrieval',

    // Protection
    'C.jobActions',
    'C.jobs',
    'C.jobModify',
    'C.jobDetails',
    'C.jobRunDetails',
    'C.policies',
    'C.sql-manager',
    'C.filePaths',
    'C.antivirus',

    // Locale interceptor
    'C.localeInterceptor',

    // Activity
    // Reports
    'C.reports',
    'C.outputFormatInterceptor',

    // Monitoring
    'C.statistics',
    'C.fieldMessages',

    // Alerts
    'C.alerts',

    // Cohesity Modals
    'C.modal',
    'C.nodeList',

    // Cohesity Utils
    'C.utils',
    'C.filters',

    // Cohesity apps
    'C.appsManagement',

    // Cohesity common directives
    // NOTE: This tabs component is deprecated. Remove once all implementations
    // of <c-tabs> are removed.
    'C.tabs',

    // Docs: https://github.com/rpocklin/ui-router-tabs/tree/v2.0.2
    'ui.router.tabs',
    'C.stepper',
    'C.spinner',
    'C.pulse',
    'C.pulseRadial',
    'C.contextMenu',
    'C.timePicker',
    'C.timezonePicker',
    'C.datePicker',
    'C.dateRanger',
    'C.slideModal',
    'C.charts',
    'C.jobName',
    'C.typeIcon',
    'C.bytes',
    'C.byteMeter',
    'C.iconProtected',
    'C.carousel',
    'C.lightbox',
  ];

  // Declare app level modules
  C = angular.module('C', cAppInjections);

  // Use the NgTranslationLoaderService to load translations. This translation
  // values will be set by LocaleService.
  C.factory('translationLoader', function loader(NgTranslationLoaderService) {
    return function load(options) {
      return NgTranslationLoaderService.getTranslation().toPromise();
    }
  });

  // Set up initial routing state
  C.config(cAppConfig);

  function cAppConfig(
    $urlRouterProvider, $urlServiceProvider, $locationProvider, $windowProvider, $httpProvider,
    $animateProvider, $provide, $uibTooltipProvider, $translateProvider,
    uibDatepickerConfig, Routes, localStorageServiceProvider,
    tmhDynamicLocaleProvider, uiSelectConfig) {

    var $window = $windowProvider.$get();
    var url = $window.location.href;

    // This tells the hybrid ui router to wait until all bootstrapping is
    // finished before finishing the initial URL synchronization.
    $urlServiceProvider.deferIntercept();

    if (url.includes('/docs/restApiDocs')) {
      $window.location = Routes.restApiDocRoot;
      return;
    } else if (url.includes('/docs/api')) {
      $window.location = Routes.apiDoc;
      return;
    }
    else if (url.includes('/docs')) {
      $window.location = [Routes.helpRoot.local, Routes.helpRoot.index].join('');
      return;
    }

    // A breaking change in AngularJS requires hashPrefix to be set to ''.
    // https://github.com/angular/angular.js/commit/aa077e81129c740041438688dff2e8d20c3d7b52
    // Alternatively, webserver.go could adjust to route to '/#!/{path}' instead
    // of '/#/{path}' but the only real upside is search engine indexability,
    // which is of no concern.
    $locationProvider.hashPrefix('');

    $locationProvider.html5Mode(true);

    /**
     * Register an additional rule to ensure correct home state is set when an app route
     * is loaded by passing a serviceType as a param. Correct home state belonging to required service is
     * also desired as a fallback in case of no matching state is found for the required route.
     * This needs to be configured at router level as for cross mf apps, URLs are the only source of truths.
     */
    $urlRouterProvider.rule(function ($injector, $location) {
      const searchParams = $location.search();

      // if service type doesn't exist, its a no-op.
      if (!!searchParams.serviceType) {
        const NgAppServiceManager = $injector.get('NgAppServiceManager');
        const HOME_STATE = $injector.get('HOME_STATE');
        const appService = NgAppServiceManager.getServiceByType(searchParams.serviceType);

        if (appService && appService?.homeState) {
          HOME_STATE.set(appService.homeState);
        }
      }
    });

    // Fallback state when an invalid state is encountered.
    $urlRouterProvider.otherwise(
      function urlOtherwise($injector, locationService) {
        var $state = $injector.get('$state');
        $state.goHome();
      }
    );

    localStorageServiceProvider.setPrefix('C');

    // Http Interceptor to check auth failures for xhr requests
    $httpProvider.interceptors.push('authHttpResponseInterceptor');

    // appending tooltip to body relieves some layout jumping issues
    $uibTooltipProvider.options({
      appendToBody: true,
      popupDelay: 300
    });

    uibDatepickerConfig.showWeeks = false;

    // Default typeahead refresh delay in msec for ui-select.
    uiSelectConfig.refreshDelay = 400;

    // have angular only animate elements with specific classes
    $animateProvider
      .classNameFilter(/(angular-animate|c-fade|error-msg|ui-select-)/);

    // angular-translate configuration
    // https://angular-translate.github.io/docs/#/guide
    $translateProvider.useLoader('translationLoader');

    // Provide a sanitization strategy for translation processing.
    // Sanitization strategy has been set to sanitizeParameters.
    // This sanitizes all the translate-values provided to the translate pipe.
    $translateProvider.useSanitizeValueStrategy('sanitizeParameters');

    /**
     * Configuring DynamicLocaleProvider; setting the default locale to en-us
     * and setting a custom path for DynamicLocalProvider where it should look
     * for i18n files https://github.com/lgalfaso/angular-dynamic-locale
     * Angular Locale files can be found at:
     * https://github.com/angular/angular.js/tree/master/src/ngLocale
     */
    tmhDynamicLocaleProvider.defaultLocale('en-us');
    tmhDynamicLocaleProvider.localeLocationPattern(
      'messages/{{locale}}/angular-locale.js'
    );

    // $templateCache decorator ensures that Cohesity templates
    // don't begin with a slash, which ensures precached templates
    // get used in production build.
    $provide.decorator('$templateCache', templateCacheDecoratorFn);

    /* @ngInject */
    function templateCacheDecoratorFn($delegate, $log) {
      var templateCacheGet = $delegate.get;
      $delegate.get = function newTemplateCacheGet(templateName) {
        // trim leading slashes from template requests to ensure requests get
        // served from our cache
        if (templateName.indexOf('/app/') === 0) {
          // warn the developer in the console so string can be normalized
          // during development
          $log.warn('Template referenced with leading slash:', templateName);

          // trim the slash
          templateName = templateName.slice(1);
        }
        return templateCacheGet(templateName);
      };
      return $delegate;
    }

    // $templateRequest lazy loads the html templates the first time they are used.
    $provide.decorator('$templateRequest', templateRequestDecoratorFn);

    /* @ngInject */
    function templateRequestDecoratorFn($delegate, $templateCache, $sce) {
      const decoratedTemplateRequest = (tpl) => {

        // https://github.com/angular/angular.js/issues/12127
        for (var key in $delegate) {
          decoratedTemplateRequest[key] = $delegate[key];
        }

        if ($templateCache.get(tpl)) {
          return Promise.resolve($templateCache.get(tpl));
        }

        // The dynamic import ensures that the templates will all be loaded in their own
        // webpack bundle.
        return import('../templates')
          // Load the actual templates.
          .then(templateLoader => templateLoader.loadAjsTemplates())

          // Add them to our cache.
          .then(templateMap => {
            Object.keys(templateMap).forEach(key => $templateCache.put(key, templateMap[key]))
          })

          // Return the one that was actually requested.
          .then(() => $templateCache.get(tpl));
      }
      return decoratedTemplateRequest;
    }

    // ngSubmitDirective decorator extends the standard ngSubmit directive's
    // compile function to add support for automatically scrolling to the first
    // error field on submit attempt
    $provide.decorator('ngSubmitDirective',
      ngSubmitDirectiveScrollToErrorDecoratorFn);

    /* @ngInject */
    function ngSubmitDirectiveScrollToErrorDecoratorFn(
      $delegate, $parse, smoothScroll) {

      var directive = $delegate[0];
      var origCompile = directive.compile;

      /**
       * new compile function for ngSubmitDirective
       *
       * @param      {Object}  elem    The element
       * @param      {Array}   attrs   The attributes
       * @return     {Function}
       */
      directive.compile = function directiveCompileFn(elem, attrs) {

        // capture original ngSubmit expression function
        var ngSubmitFn = $parse(attrs['ngSubmit']);

        // apply the original ngSubmitDirective compile function
        origCompile.apply(this, arguments);

        return function scrollToErrorFn(scope, elem, attrs) {

          elem.on('submit', function submitFn(event) {

            var scrollOptions = {
              // if we need to scroll a modal we will find and define the
              // containerId option containerId:

              // account for fixed header height when scrolling plus a bit of
              // breathing room
              offset: angular.element('header[main-nav]').height() + 40,
              duration: 500,
              easing: 'easeInOutQuad',
              callbackAfter: function focusElementFn(elem) {
                var invalidElements = angular.element(elem).find('.ng-invalid');
                if (invalidElements && invalidElements.length) {
                  invalidElements[0].focus();
                }
              }
            };

            var errorFieldsets;
            var cSlideModals;
            var theSlideModal;
            var cSlideModalId;
            var allModals;

            if (elem.hasClass('ng-invalid')) {
              errorFieldsets = elem.find('fieldset.error');
              if (errorFieldsets.length) {

                allModals = angular.element('.modal');

                // safely(?) assume if there's a cSlideModal open on the page that
                // the submitting form is included in it
                cSlideModals = angular.element('.modal.c-slide-modal');
                if (cSlideModals.length) {
                  theSlideModal = cSlideModals[0];
                  cSlideModalId = addHtmlId(theSlideModal);
                  scrollOptions.containerId = cSlideModalId;
                }

                // only initiate the scroll if there are no modals or if the only
                // modals on the page are cSlideModals
                if (!allModals.length ||
                  (cSlideModals.length &&
                  allModals.length === cSlideModals.length)) {
                  smoothScroll(errorFieldsets[0], scrollOptions);
                }
              }
            }

            // do the standard/normal ngSubmit form submission
            scope.$apply(function scopeApplyFn() {
              ngSubmitFn(scope, {
                $event: event
              });
            });

          });
        };

      };

      /**
       * adds an html id attribute to the provided element if it doesn't
       * already have one, returning the id of the element
       *
       * @param      {Object}  elem    The element
       * @return     {String}  the id (wether added or existing) of elem
       */
      function addHtmlId(elem) {
        var elemId;
        if (!elem.id) {
          elemId = ['scroll-id-', Math.floor(Math.random() * 10000)].join('');
          elem.setAttribute('id', elemId);
        } else {
          elemId = elem.getAttribute('id');
        }
        return elemId;
      }

      return $delegate;
    }

  }

  // Instantiate app controller
  C.controller('C.Controller', mainAppControllerFn);
  C.run(appTransitionHook)

  function mainAppControllerFn(
    _, $rootScope, $log, $state, $stateParams, FORMATS, FEATURE_FLAGS,
    localStorageService, LocaleService, NgClusterService, featureFlagsService) {

    var $ctrl = this;

    $rootScope.showClusterIframe = true;

    /**
     * Main app initialization function
     *
     * @method   $onInit
     */
    $ctrl.$onInit = function $onInit() {
      // Update basic cluster info on root scope whenever it changes
      NgClusterService.basicClusterInfo$.subscribe(function infoLoaded(info) {
        $rootScope.basicClusterInfo = info;
      });

      // Update $translate and $rootScope.text whenever the locale changes
      LocaleService.text$.subscribe(function textLoaded(text) {
        if (!text) {
          return;
        }
        $rootScope.text = text;

        // Use this update to mark that the app has been bootstrapped, since
        // basic cluster info and the locale settings have been loaded now.
        $rootScope.isAppBootstrapped = true;
      });

      featureFlagsService.featureFlags$.subscribe(featureFlags => {
        angular.merge(FEATURE_FLAGS, featureFlags)
      });
    };

    // add objects to rootScope for easy template access
    $rootScope.$state = $state;
    $rootScope.$stateParams = $stateParams;
    $rootScope.FORMATS = FORMATS;
    $rootScope.FEATURE_FLAGS = FEATURE_FLAGS;

    // isAppBootstrapped will be updated in ClusterService when language files,
    // etc get loaded
    $rootScope.isAppBootstrapped = false;
  }

})(angular);
