import { Component, ViewEncapsulation } from '@angular/core';

import { HeliosAnalyticsService } from 'src/app/core/services';

@Component({
  selector: 'coh-toggle-messenger',
  templateUrl: './toggle-messenger.component.html',
  styleUrls: ['./toggle-messenger.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ToggleMessengerComponent {
  constructor(readonly heliosAnalyticsService: HeliosAnalyticsService) {
  }

  /**
   * Toggle the visibility of the messenger.
   */
  toggleMessenger() {
    this.heliosAnalyticsService.toggleIntercomMessenger();
  }
}
