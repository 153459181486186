import { File, GlobalClusterIdentifier } from '@cohesity/api/v2';
import { Environment } from '@cohesity/iris-shared-constants';

import { GlobalSearchBaseItem, GlobalSearchItemOptions } from './global-search-base-item.model';
import { GlobalSearchResultType } from './global-search-result-type.model';

/**
 * Type of Helios file.
 */
export type HeliosFile = GlobalClusterIdentifier & File;

/**
 * Class for global search file items.
 */
export class GlobalSearchFileItem extends GlobalSearchBaseItem {
  /**
   * The path of the search result.
   */
  path: string;

  /**
   * The id of the containing object of the search result.
   */
  objectId: number;

  constructor(searchFile: HeliosFile, options: GlobalSearchItemOptions) {
    super(searchFile, options);

    const {locationNameMap, clusterDetailsMap} = options;

    // Generate a globalId on the UI side for the files. The id of a file is
    // typically its path, which is not unique by itself.
    this.globalId = [
      searchFile.clusterIdentifier,
      searchFile.sourceInfo.sourceId,
      searchFile.sourceInfo.id,
      searchFile.path,
      searchFile.name,
    ].join(':');
    this.environment = searchFile.sourceInfo.environment as Environment;
    this.hasGroupProtection = true;
    this.isProtected = true;

    const clusterId = Number(searchFile.clusterIdentifier.split(':')[0]);

    this.protectionGroups = [
      {
        clusterId,
        clusterName: options.locationNameMap[clusterId],
        id: searchFile.protectionGroupId,
        name: searchFile.protectionGroupName,
        policyId: searchFile.policyId,
        policyName: searchFile.policyName,
        storageDomainId: `${searchFile.clusterIdentifier}:${searchFile.storageDomainId}`,
      },
    ];

    this.type = searchFile.type;
    this.sourceId = searchFile.sourceInfo.sourceId;
    this.resultType = GlobalSearchResultType.File;
    this.regionIds = [];
    this.clusterIds = [clusterId];
    this.path = searchFile.path;
    this.objectId = searchFile.sourceInfo.id;

    // No deleted info available for files.
    this.deletedInfo = {
      value: false,
      allLocations: false,
      someLocations: false,
    };

    // A file search result is always only present in one location.
    this.locationObjectMap = {
      [clusterId]: {
        locationName: locationNameMap[clusterId],
        clusterDetails: clusterDetailsMap[clusterId],
        hasObjectProtection: false,
        hasGroupProtection: true,
        isProtected: true,
      },
    };
  }
}
