<cog-settings-list-item [invalid]="formGroupErrors"
  cogFormGroup
  [label]="'filterIps' | translate">
  <cog-settings-list-item-value-display>
    {{formGroupValues.enabled | boolLabel}}
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor>
    <mat-slide-toggle class="margin-bottom"
      [formControl]="formGroupControls.enabled"
      cogDataId="settings-ip-filters-enabled">
      {{formGroupValues.enabled | boolLabel}}
    </mat-slide-toggle>
    <p>{{'recover.filterIps.description' | translate}}</p>
    <coh-filter-ip-config
      *ngIf="formGroupValues.enabled"
      cogDataId="settings-ip-filters-config"
      [control]="formGroupControls.filterIpConfig">
    </coh-filter-ip-config>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>