<div class="progress-bar-container">
  <mat-progress-bar
    class="pulse-bar mat-progress-bar {{barClass}}"
    mode="determinate"
    color="primary"
    [value]="progress.percentFinished"></mat-progress-bar>
</div>
<span class="pulse-label"
  *ngIf="label && progress"
  [innerHTML]="label | translate: progress"></span>
