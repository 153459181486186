import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'dg-pa-scores-drilldown-page',
  templateUrl: './scores-drilldown-page.component.html',
})
export class ScoresDrillDownPageComponent {
  /**
   * Emit an event when a back button is clicked
   */
  @Output() readonly backClick = new EventEmitter<string>();

  /**
   * Emit an event when a row item is clicked for drilldown
   */
  @Output() readonly scoreDrilldownClick = new EventEmitter<string>();

  /**
   * The country selected for drilldown
   */
  @Input() country: string;

  /**
   * The state selected for drilldown
   */
  @Input() state: string;

  constructor() { }
}
