<h2 mat-dialog-title>
  <div class="flex-row-sm vertical-align">
    <cog-icon class="margin-right-sm" shape="helix:share!outline">
    </cog-icon>
    {{'mountPathsAndApiEndpoints' | translate}}
  </div>
</h2>

<div mat-dialog-content *ngIf="share">
  <!-- S3 Path -->
  <div class="margin-bottom-xl" *ngIf="share.s3AccessPath">
    <h4 class="no-margin-bottom">{{'s3AccessPath' | translate}}</h4>
    <coh-clipboard-cta *ngIf="share?.s3AccessPath"
      [dataId]="'share-s3-access-path'"
      [cogDataId]="'share-s3-access-path'"
      [clipboardContent]="share.s3AccessPath">
    </coh-clipboard-cta>
  </div>

  <!-- NFS Path -->
  <div class="margin-bottom-xl" *ngIf="share.nfsMountPaths?.length">
    <h4 class="no-margin-bottom">{{'nfsMountPaths' | translate}}</h4>
    <coh-clipboard-cta
      *ngFor="let nfsPath of share.nfsMountPaths; index as i;"
      [dataId]="'share-nfs-mount-path-index-' + i"
      [cogDataId]="'share-nfs-mount-path-index-' + i"
      [clipboardContent]="nfsPath">
    </coh-clipboard-cta>
  </div>

  <!-- SMB Paths -->
  <div *ngIf="share.smbMountPaths?.length">
    <div class="row no-padding-left no-margin-left smb-path-header">
      <h4 class="col-xs-5 no-padding-left no-margin-bottom">{{'smbMountPaths' | translate}}</h4>
      <div class="search-smb-path-wrapper col-xs-7" *ngIf="share.smbMountPaths.length >= 2">
        <mat-icon matSuffix>search</mat-icon>
        <input type="text"
          matInput
          cogDataId="search-smb-mount-path-input"
          placeholder="{{'search' | translate}}"
          (input)="smbSearchUpdate.next($event.target.value)">
      </div>
    </div>
    <coh-clipboard-cta *ngFor="let smbPath of filteredSmbMountPaths; index as i;"
      [dataId]="'share-smb-mount-path-index-' + i"
      [cogDataId]="'share-smb-mount-path-index-' + i"
      [clipboardContent]="smbPath">
    </coh-clipboard-cta>
  </div>
</div>

<mat-dialog-actions align="end">
  <button mat-flat-button
    cogDataId="mount-paths-close-btn"
    type="button"
    color="primary"
    mat-dialog-close>
    {{'close' | translate}}
  </button>
</mat-dialog-actions>
