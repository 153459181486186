import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CopyStats, CopyStatsServiceApi, GetCopyStatParams, GetCopyStatResponse } from '@cohesity/api/v2';
import { MomentDatePipe } from '@cohesity/helix';
import { AjaxHandlerService, AutoDestroyable, HumanizeFromNumbersPipe } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { SeriesBarOptions } from 'highcharts';
import { finalize } from 'rxjs/operators';

import { AnomalyAlert } from '../../security-shared.models';

/**
 * The file analysis chart details.
 */
interface AnalysisChartOptions {
  /** The bar chart categories */
  categories: string[];

  /** The chart series data */
  seriesData: SeriesBarOptions[];

  /** The chart options */
  customOptions: Highcharts.Options;
}

@Component({
  selector: 'dg-ar-file-analysis',
  styleUrls: ['./file-analysis.component.scss'],
  templateUrl: './file-analysis.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [HumanizeFromNumbersPipe],
})
export class FileAnalysisComponent extends AutoDestroyable implements OnInit {
  /**
   * The anomaly details.
   */
  @Input() anomalyAlert: AnomalyAlert;

  /**
   * Indicate whether we are fetching data.
   */
  isLoading = false;

  /**
   * Indicates whether we got any data or not.
   */
  get hasData() {
    return !!this.stats?.seriesData?.[0].data?.length;
  }

  /**
   * The file analysis stats chart details.
   */
  stats: AnalysisChartOptions = {
    categories: [],
    seriesData: [
      {
        type: 'bar',
        name: this.translateService.instant('anomalousSnapshot'),
        data: [],
        custom: { copyStats: null as CopyStats },
      },
      {
        type: 'bar',
        name: this.translateService.instant('latestCleanSnapshot'),
        data: [],
        custom: { copyStats: null as CopyStats },
      },
    ],
    customOptions: {
      chart: {
        type: 'bar',
        height: 200,
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        labels: {
          enabled: false,
        },
      },
      tooltip: {
        formatter: (ctrl =>
          function () {
            return `${this.series.name}: <strong>${ctrl.humanizeFromNumbersPipe.transform(this.y)}</strong>`;
          })(this),
      },
      plotOptions: {
        series: {
          dataLabels: [
            {
              enabled: true,
              inside: true,
              align: 'left',
              formatter: (ctrl =>
                function () {
                  const copyStats = this.point.series.options?.custom?.copyStats as CopyStats;
                  const dataStr = copyStats ? ctrl.datePipe.transform(copyStats.local.runStartTimeUsecs) : '';
                  return `<strong>${this.series.name}</strong> ${dataStr}`;
                })(this),
            },
            {
              enabled: true,
              formatter: (ctrl =>
                function () {
                  return `${ctrl.humanizeFromNumbersPipe.transform(this.y)} ${ctrl.translateService.instant('files')}`;
                })(this),
            },
          ],
        },
      },
    },
  };

  /**
   * The file analysis distribution chart details.
   */
  distribution: AnalysisChartOptions = {
    categories: [],
    seriesData: [
      {
        type: 'bar',
        name: this.translateService.instant('anomalousSnapshot'),
        data: [],
      },
      {
        type: 'bar',
        name: this.translateService.instant('latestCleanSnapshot'),
        data: [],
      },
    ],
    customOptions: {
      chart: {
        type: 'bar',
        height: 200,
      },
      legend: {
        enabled: true,
      },
      tooltip: {
        formatter: (ctrl =>
          function () {
            return ctrl.humanizeFromNumbersPipe.transform(this.y);
          })(this),
      },
      yAxis: {
        visible: true,
        labels: {
          formatter: (ctrl =>
            function () {
              return ctrl.humanizeFromNumbersPipe.transform(+this.value);
            })(this),
        },
      },
      xAxis: {
        labels: {
          align: 'left',
          reserveSpace: true,
          formatter: (() =>
            function () {
              const className = this.value?.toString()?.includes('*') ? 'known-extension' : '';
              return `<span class="${className}">${this.value}</span>`;
            })(),
        },
      },
      plotOptions: {
        series: {
          dataLabels: [
            {
              enabled: true,
              formatter: (ctrl =>
                function () {
                  return `${ctrl.humanizeFromNumbersPipe.transform(this.y)} ${ctrl.translateService.instant('files')}`;
                })(this),
            },
          ],
        },
      },
    },
  };

  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private copyStatsService: CopyStatsServiceApi,
    private datePipe: MomentDatePipe,
    private humanizeFromNumbersPipe: HumanizeFromNumbersPipe,
    private translateService: TranslateService
  ) {
    super();
  }

  /**
   * Initialize component
   */
  ngOnInit() {
    this.getFileAnalysisData();
  }

  /**
   * Get the data for file analysis.
   */
  getFileAnalysisData() {
    const { clusterId, latestTimestampUsecs, propertyList } = this.anomalyAlert;
    let lastCleanSnapshotTimestampUsecs: number;
    let clusterIncarnationId: number;
    let entityId: number;
    let protectionGroupIds: string;

    (propertyList || []).forEach(item => {
      switch (item.key) {
        case 'jobStartTimeUsecs':
          lastCleanSnapshotTimestampUsecs = +item.value;
          break;
        case 'clusterIncarnationId':
          clusterIncarnationId = +item.value;
          break;
        case 'entityId':
          entityId = +item.value;
          break;
        case 'jobId':
          protectionGroupIds = item.value;
          break;
      }
    });

    const params: GetCopyStatParams = {
      objectIds: [entityId],
      runInstanceIds: [latestTimestampUsecs, lastCleanSnapshotTimestampUsecs],
    };

    // BaaS may not have clusterId or clusterIncarnationId
    if (clusterId && clusterIncarnationId) {
      const clusterIdentifiers = `${clusterId}:${clusterIncarnationId}`;

      params.clusterIdentifiers = [clusterIdentifiers];
      params.protectionGroupIds = [`${clusterIdentifiers}:${protectionGroupIds}`];
    }

    this.isLoading = true;
    this.copyStatsService
      .GetCopyStats(params)
      .pipe(
        this.untilDestroy(),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(data => this.renderChart(data), this.ajaxHandlerService.handler);
  }

  /**
   * Render chart from copy stats response.
   *
   * @param data The copy stats response.
   */
  renderChart(data: GetCopyStatResponse) {
    const categories = new Map<boolean, Set<string>>();

    (data || []).forEach((copyStats, index) => {
      this.stats.seriesData[index].custom.copyStats = copyStats;
      this.stats.seriesData[index].data = [copyStats?.indexingStats?.totalFiles || 0];

      copyStats.fileExtensions.forEach(fileExtension => {
        const fileExtensions = categories.get(fileExtension.isMalicious) || new Set<string>();

        categories.set(
          fileExtension.isMalicious,
          fileExtensions.add(fileExtension.extensionName + (fileExtension.isMalicious ? ' *' : ''))
        );
        this.distribution.seriesData[index].data.push(fileExtension.count || 0);
      });
    });

    this.distribution.categories = [...(categories.get(true) || []), ...(categories.get(false) || [])];
  }
}
