import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { AutoDestroyable, Poller } from '@cohesity/utils';
import { clamp, get } from 'lodash';
import { PollingService } from 'src/app/core/services';

/**
 * Default progress in percentage label.
 */
const defaultLabel = 'percentageCompleteAlt2';

/**
 * Round the percent.
 *
 * @param   percent    Percent number with decimals.
 * @returns Percent number rounded.
 */
function roundPercent(percent: number): number {
  if (percent || percent === 0) {
    return Math.round(clamp(percent, 0, 100));
  }

  // Should not happen but saw cases when percent is undefined.
  // Return 0 to avoid "{{percentFinished}}%" showing in UI.
  return 0;
}

/**
 * @description
 * Linear progress bar with Materialized Cohesity style!
 *
 * @example
 *   <!-- Automatic polling -->
 *   <coh-pulse taskPath="restore/task_path" [(pulse)]="pulse"></coh-pulse>
 *
 *   <!-- Manual progress (no polling) -->
 *   <coh-pulse percent="{{Math.PI | number:2}}"></coh-pulse>
 *
 *   <!-- This one takes an entire pulse response, but doesn't poll. -->
 *   <coh-pulse [(pulse)]="pulse"></coh-pulse>
 */
@Component({
  selector: 'coh-pulse',
  templateUrl: './pulse.component.html',
  styleUrls: ['./pulse.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PulseComponent extends AutoDestroyable implements OnInit, OnChanges, OnDestroy {
  /**
   * Region from where to fetch the progress.
   */
  @Input() regionId: string;

  /**
   * Access cluster ID.
   */
  @Input() clusterId: number;

  /**
   * The taskPath to fetch pulse for.
   */
  @Input() taskPath: string;

  /**
   * Manually-specified percentage. If defined along with taskpath, this is
   * ignored.
   *
   * This value is clamped within 0..100
   */
  @Input() percent: number;

  /**
   * String label to show for the bar. This is translated against the pulse's
   * progress property.
   */
  @Input() label: string | boolean = defaultLabel;

  /**
   * Class to apply to the internal progress bar widget.
   */
  @Input() barClass: string;

  /**
   * The polling interval in minutes.
   */
  @Input() interval = 1;

  /**
   * Whole pulse object for use in static-pulse mode (no polling).
   */
  @Input() pulse: any;

  /**
   * Emits full data or not.
   */
  @Input() emitFullData = false;

  /**
   * Emits changes to this.pulse (two-way binding)
   */
  @Output() readonly pulseChange = new EventEmitter<any>();

  /**
   * Emits event taskFinish when perecentFinished is 100%
   */
  @Output() readonly taskFinish = new EventEmitter();

  /**
   * Internal cache of the pulse either provided or fetched by polling.
   */
  protected pulseData: Poller<any>;

  /**
   * The Poller instance for this pulse widget.
   */
  private taskPoll: Poller<any>;

  /**
   * The Pulse progress extracted from the pulse response.
   *
   * NOTE: There's no specific type for this because it's a private API.
   */
  progress = { percentFinished: 0 };

  /**
   * Creates an instance of this PulseComponent.
   *
   * @param   pollingService   The PollingService
   */
  constructor(private pollingService: PollingService) {
    super();
  }

  /**
   * Sets the pulse data and progress as provided by pulse polling results.
   *
   * @param   pulseData   Pulse data structure returned from API call
   * @param   skipEmit    Skip emitting data or not
   */
  updatePulseDataAndProgress(pulseData: any, skipEmit = false) {
    this.pulseData = pulseData;
    this.progress = get(pulseData, 'taskVec[0].progress', this.progress);

    if (!skipEmit) {
      if (this.emitFullData) {
        this.pulseChange.emit(pulseData);
      } else if (this.progress && this.progress.percentFinished === 100) {
        this.taskFinish.emit();
      }
    }

    // Round percent after the above 100% check in case percent is 99.5 or larger
    if (this.progress) {
      this.progress.percentFinished = roundPercent(this.progress.percentFinished);
    }
  }

  /**
   * OnInit hook.
   */
  ngOnInit() {
    if (this.taskPath) {
      // Do auto-polling setup
      this.taskPoll = this.pollingService.pollTask(this.taskPath, this.interval * 60,
        // includeSubTasks
        false,

        // includeFinishedTasks
        true,

        // includeEventLogs
        false,
        0,
        this.regionId,
        this.clusterId
      );
      this.taskPoll.poller
        // TODO (spencer): ETA 6.4 - Take up @dcoblentz's suggestion from
        // http://gerrit.eng.cohesity.com/c/iris-ui/+/30081/2/iris/src/app/shared/pulse/pulse.component.ts#115
        .subscribe(data => data?.length ? this.updatePulseDataAndProgress(data[0]) : undefined);
    }
  }

  /**
   * Handle changes to bindings.
   */
  ngOnChanges(changes: SimpleChanges) {
    // Do static progress stuff
    if (!this.taskPath && !changes.pulse) {
      if (changes.percent) {
        this.progress = {
          ...this.progress,
          percentFinished: roundPercent(this.percent),
        };
      }
    }

    // Handle incoming pulse changes from an outside poller.
    if (changes.pulse) {
      this.updatePulseDataAndProgress(this.pulse, true);
    }

    // React to changes applicable to both modes
    if (changes.label || !this.label) {
      if (['false', false].includes(this.label)) {
        this.label = false;
      } else {
        this.label = this.label || defaultLabel;
      }
    }
  }

  /**
   * OnDestroy hook.
   */
  ngOnDestroy() {
    if (this.taskPoll) {
      this.taskPoll.finish();
    }
  }
}
