/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DspmRunList } from '../models/dspm-run-list';
import { DspmScheduleAction } from '../models/dspm-schedule-action';
@Injectable({
  providedIn: 'root',
})
class DSPMService extends __BaseService {
  static readonly GetDspmRunsPath = '/mcm/dspm/runs';
  static readonly PerformDspmScheduleActionPath = '/mcm/dspm/schedule/actions';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get DSPM runs.
   *
   * Get a list of DSPM runs for an account.
   * @param params The `DSPMService.GetDspmRunsParams` containing the following parameters:
   *
   * - `toTimeUsecs`: Specifies the time in Unix timestamp epoch in microsecond which filters all the runs started before this value. Default value is the current time or 7 days after the fromTimeUsecs.
   *
   * - `runType`: Type of DSPM run. All type of runs are returned by default.
   *
   * - `runStatus`: Status of DSPM run. All run status of runs are returned by default.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `lastRunOnly`: Specifies if only returning the last run. Time filter will be ignored if lastRunOnly is set as true.
   *
   * - `integrationIds`: Integration ids that the runs use. If not specified, all runs for the logged in customer will be returned, including the runs for deleted integrations.
   *
   * - `fromTimeUsecs`: Specifies the time in Unix timestamp epoch in microsecond which filters all the runs started after this value. Default value is 7 days before the current time or the toTimeUsecs.
   *
   * @return Response of listing DSPM runs.
   */
  GetDspmRunsResponse(params: DSPMService.GetDspmRunsParams): __Observable<__StrictHttpResponse<DspmRunList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.toTimeUsecs != null) __params = __params.set('toTimeUsecs', params.toTimeUsecs.toString());
    if (params.runType != null) __params = __params.set('runType', params.runType.toString());
    if (params.runStatus != null) __params = __params.set('runStatus', params.runStatus.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.lastRunOnly != null) __params = __params.set('lastRunOnly', params.lastRunOnly.toString());
    (params.integrationIds || []).forEach(val => {if (val != null) __params = __params.append('integrationIds', val.toString())});
    if (params.fromTimeUsecs != null) __params = __params.set('fromTimeUsecs', params.fromTimeUsecs.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/dspm/runs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DspmRunList>;
      })
    );
  }
  /**
   * Get DSPM runs.
   *
   * Get a list of DSPM runs for an account.
   * @param params The `DSPMService.GetDspmRunsParams` containing the following parameters:
   *
   * - `toTimeUsecs`: Specifies the time in Unix timestamp epoch in microsecond which filters all the runs started before this value. Default value is the current time or 7 days after the fromTimeUsecs.
   *
   * - `runType`: Type of DSPM run. All type of runs are returned by default.
   *
   * - `runStatus`: Status of DSPM run. All run status of runs are returned by default.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `lastRunOnly`: Specifies if only returning the last run. Time filter will be ignored if lastRunOnly is set as true.
   *
   * - `integrationIds`: Integration ids that the runs use. If not specified, all runs for the logged in customer will be returned, including the runs for deleted integrations.
   *
   * - `fromTimeUsecs`: Specifies the time in Unix timestamp epoch in microsecond which filters all the runs started after this value. Default value is 7 days before the current time or the toTimeUsecs.
   *
   * @return Response of listing DSPM runs.
   */
  GetDspmRuns(params: DSPMService.GetDspmRunsParams): __Observable<DspmRunList> {
    return this.GetDspmRunsResponse(params).pipe(
      __map(_r => _r.body as DspmRunList)
    );
  }

  /**
   * Perform actions for a DSPM schedule.
   *
   * Perform actions for a DSPM schedule.
   * @param body Specifies the parameters to perform a DSPM schedule action.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  PerformDspmScheduleActionResponse(body: DspmScheduleAction,
    regionId?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/dspm/schedule/actions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Perform actions for a DSPM schedule.
   *
   * Perform actions for a DSPM schedule.
   * @param body Specifies the parameters to perform a DSPM schedule action.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  PerformDspmScheduleAction(body: DspmScheduleAction,
    regionId?: string): __Observable<null> {
    return this.PerformDspmScheduleActionResponse(body, regionId).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module DSPMService {

  /**
   * Parameters for GetDspmRuns
   */
  export interface GetDspmRunsParams {

    /**
     * Specifies the time in Unix timestamp epoch in microsecond which filters all the runs started before this value. Default value is the current time or 7 days after the fromTimeUsecs.
     */
    toTimeUsecs?: number;

    /**
     * Type of DSPM run. All type of runs are returned by default.
     */
    runType?: 'Ingestion' | 'Push';

    /**
     * Status of DSPM run. All run status of runs are returned by default.
     */
    runStatus?: 'InProgress' | 'Failed' | 'Succeeded';

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies if only returning the last run. Time filter will be ignored if lastRunOnly is set as true.
     */
    lastRunOnly?: boolean;

    /**
     * Integration ids that the runs use. If not specified, all runs for the logged in customer will be returned, including the runs for deleted integrations.
     */
    integrationIds?: Array<string>;

    /**
     * Specifies the time in Unix timestamp epoch in microsecond which filters all the runs started after this value. Default value is 7 days before the current time or the toTimeUsecs.
     */
    fromTimeUsecs?: number;
  }
}

export { DSPMService }
