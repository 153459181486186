import { Injectable } from '@angular/core';

/**
 * Cohesity common Math utilities methods.
 */
@Injectable({
  providedIn: 'root'
})
export class MathService {
  /**
   * Rounds and returns a floating point number to the provided number of
   * decimal places.
   *
   * @param  num  The input number to round.
   * @param  dec  The number of places to be kept when rounding.
   * @returns  The rounded number.
   */
  public toFixed(num?: number, dec?: number): number|string {
    if (!num) {
      // TODO: translate n/a letter once cohesity common is aware of translation
      return (num === 0) ? num : '-';
    }

    if (dec) {
      dec = Math.pow(10, dec);
      return Math.round(num * dec) / dec;
    } else {
      return Math.round(num);
    }
  }
}
