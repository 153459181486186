<!-- Blank card when there are no filtered objects -->
<cog-blank-card *ngIf="data?.length === 0"></cog-blank-card>

<!-- Table view -->
<cog-table
  name="anomalyTable"
  class="margin-top"
  cogDataId="anomaly-list"
  [hidden]="data?.length === 0"
  [source]="data ? data : []"
  [selection]="tableSelection"
  [persistSelection]="true"
  [canSelectAnyRowsFn]="canSelect"
  [canSelectRowFn]="canSelect">
  <cog-table-multi-action
    allSelectedLabel="{{ 'selectedAllObjects' | translate: { items: data.length } }}"
    clearLabel="{{ 'unselectAll' | translate }}"
    anySelectedLabel="{{ 'selectedObjectsAction' | translate: { items: tableSelection?.selected?.length } }}"
    selectAllLabel="{{ 'selectAllObjects' | translate: { items: data.length } }}">
    <button
      type="button"
      [dgHasPermissions]="['RESTORE_MODIFY']"
      cogDataId="anomaly-bulk-recovery"
      class="action-button"
      cog-button-small
      mat-stroked-button
      color="primary"
      [irisRouterLink]="getRecoveryLinkFn()"
      (click)="recoverAnomalies.emit(tableSelection.selected)">
      {{ 'recover' | translate }}
    </button>

    <button
      type="button"
      [dgHasPermissions]="['ALERT_MODIFY']"
      cogDataId="anomaly-bulk-resolve"
      class="action-button"
      cog-button-small
      mat-stroked-button
      color="primary"
      (click)="resolveAlerts()">
      {{ 'resolve' | translate }}
    </button>
  </cog-table-multi-action>
  <table
    mat-table
    matSort
    matSortActive="latestTimestampUsecs"
    matSortDirection="desc"
    (matSortChange)="sortData($event)"
    [class.loading]="isLoading">
    <!-- Loading Footer Row -->
    <ng-container matColumnDef="loading">
      <td colspan="5" mat-footer-cell *matFooterCellDef>
        <cog-spinner></cog-spinner>
      </td>
    </ng-container>

    <!-- Object -->
    <ng-container matColumnDef="object">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'object' | translate }}
      </th>
      <td mat-cell *matCellDef="let row; index as i">
        <span class="flex-row-xs vertical-align">
          <cog-icon
            *ngIf="row.properties.environment"
            size="md"
            [shape]="row.properties.environment | anomalyObjectIcon"
            [matTooltip]="row.properties.environment | environmentType">
          </cog-icon>
          <div class="flex-column">
            <a
              class="user-data-md auto-truncate cog-table-object-name"
              [inheritDataId]="true"
              [matTooltip]="row.properties.object"
              cogDataId="object-{{ i }}-title"
              id="show-anomaly-{{ i }}"
              [irisRouterLink]="getObjectDetailLinkFn(row.id, row.incidence?.id)"
              [params]="getObjectDetailLinkParamsFn(row.id)">
              {{ row.properties.object }}
            </a>
            <small [matTooltip]="row.properties.source | naLabel" class="item-label auto-truncate">
              {{'sourceColon' | translate}} {{ row.properties.source | naLabel }}
            </small>
          </div>
        </span>
      </td>
    </ng-container>

    <!-- Anomaly Strength -->
    <ng-container matColumnDef="strength">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'anomalyStrength' | translate }}
      </th>
      <td mat-cell *matCellDef="let row; index as i">
        <div
          *ngIf="row.properties.anomalyStrength; else noStrengthTemplate"
          class="flex-row vertical-align"
          cogDataId="row-{{ i }}-{{(anomalyStrengthThreshold$ | async) < +row.properties.anomalyStrength ?
            'critical' : 'warning' }}"
          [inheritDataId]="true">
          <cog-pie-chart
            [matTooltip]="((anomalyStrengthThreshold$ | async) < +row.properties.anomalyStrength ?
              'criticalAlert' : 'warningAlert') | translate"
            [percentage]="{
              value: +row.properties.anomalyStrength,
              status: (anomalyStrengthThreshold$ | async) < +row.properties.anomalyStrength ?
                'critical' : 'warning'
            }">
          </cog-pie-chart>
          <span [matTooltip]="'statisticalConfidence' | translate: { strength: row.properties.anomalyStrength }">
            {{ row.properties.anomalyStrength }}%
          </span>
        </div>
        <ng-template #noStrengthTemplate>
          <span>{{ 'naNotAvailable' | translate }}</span>
        </ng-template>
      </td>
    </ng-container>

    <!-- Organization -->
    <coh-tenant-column [internalResolve]="true" position="3"></coh-tenant-column>

    <dg-ar-ai-bot-column>
    </dg-ar-ai-bot-column>

    <!-- System -->
    <ng-container matColumnDef="system">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'system' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <span [matTooltip]="row.clusterName | naLabel" class="user-data-md auto-truncate">
          {{ row.incidence?.antiRansomwareDetails?.isBaas ?
            (row.incidence?.antiRansomwareDetails?.baasRegionId | translate) :
            (row.clusterName | naLabel) }}
        </span>
      </td>
    </ng-container>

    <!-- Time -->
    <ng-container matColumnDef="latestTimestampUsecs">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'anomalousSnapshot' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <div class="flex-row vertical-align">
          {{ row.latestTimestampUsecs | cogDate }}
          <div *ngIf="row.tags" class="margin-left-sm" matTooltip="{{ 'anomalyTag.tooltip' | translate }}">
            <dg-ar-anomaly-tags [tags]="row.tags"></dg-ar-anomaly-tags>
          </div>
        </div>
      </td>
    </ng-container>

    <!-- Threat Scan Status -->
    <ng-container matColumnDef="threatDetection">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'dg.threatDetection' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <dg-ar-th-scan-run-stats
          [incidence]="row.incidence"
          [getInstallAppLinkFn]="getInstallAppLinkFn"
          [getInstallAppLinkParamsFn]="getInstallAppLinkParamsFn"
          [appStatus]="appStatusByIncidenceId.get(row.incidence?.id)"
          cogDataId="incidence-{{ row.incidence.id }}-th-stats">
        </dg-ar-th-scan-run-stats>
      </td>
    </ng-container>

    <!-- Sensitive Patterns -->
    <ng-container matColumnDef="sensitivePatterns">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'sensitivePatterns' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <dg-ar-dc-scan-run-stats
          [incidence]="row.incidence"
          [getInstallAppLinkFn]="getInstallAppLinkFn"
          [getInstallAppLinkParamsFn]="getInstallAppLinkParamsFn"
          [appStatus]="appStatusByIncidenceId.get(row.incidence?.id)">
        </dg-ar-dc-scan-run-stats>
      </td>
    </ng-container>

    <!-- Header and row definition -->
    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
    <tr
      mat-row
      class="c-fade-sm animate-staggered"
      [hidden]="isLoading"
      *matRowDef="let row; columns: displayColumns"></tr>

    <!-- Loading row -->
    <tr class="loading-row" mat-footer-row [class.hidden]="!isLoading" *matFooterRowDef="['loading']"></tr>
  </table>
  <mat-paginator [length]="data?.length"></mat-paginator>
</cog-table>
