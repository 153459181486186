import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import worldMap from '@highcharts/map-collection/custom/world-continents.geo.json';
import * as Highcharts from 'highcharts/highmaps';
import proj4 from 'proj4';

import { WindowRef } from '../../../util';
import { HighchartsComponent } from '../core';

/**
 * @description
 * This component displays world map with bubbles as pointers.
 * {lat, lon} from input decides where the bubble will be.
 * {z} decides radius of the bubble.
 *
 * @example
 * <cog-bubble-map></cog-bubble-map>
 */

/**
 * Custom interface to handle series click events.
 */
export interface BubbleClickEventObject extends Highcharts.SeriesClickEventObject {
  chartX: number;
  chartY: number;
}

/**
 * Types of series that can be rendered.
 */
export type MapOptionsType = Highcharts.SeriesMapbubbleOptions | Highcharts.SeriesMappointOptions;

@Component({
  selector: 'cog-bubble-map',
  templateUrl: './bubble-map.component.html',
  styleUrls: ['./bubble-map.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BubbleMapComponent extends HighchartsComponent<MapOptionsType> implements OnChanges, OnInit, OnDestroy {
  /**
   * Point format for tooltip.
   */
  @Input() pointFormat = '';

  /**
   * Colorset definitions defined in stylesheet
   */
  @Input() colorSetClass: string;

  /**
   * Toggles map zooming.
   */
  private _useZoom = true;

  /**
   * Sets map zoom flag. Component attribute might pass value as string.
   */
  @Input() set useZoom(useZoom: boolean) {
    this._useZoom = useZoom;
  }

  /**
   * Returns if map zoom is enabled.
   */
  get useZoom(): boolean {
    return this._useZoom;
  }

  /**
   * Emits bubble map click events.
   */
  @Output() bubbleClick = new EventEmitter<BubbleClickEventObject>();

  /**
   * Emit an event when hovering over a point.
   */
  @Output() pointMouseOver = new EventEmitter<EventTarget>();

  /**
   * Emit an event when hovering out of a point.
   */
  @Output() pointMouseOut = new EventEmitter<EventTarget>();

  /**
   * Can't use superclass' Highcharts instance and have to include Highcharts again
   * because it uses map constructor that is part of highcharts-more.
   */
  readonly Highcharts: typeof Highcharts = Highcharts;

  /**
   * Type of Highcharts chart constructor.
   */
  readonly chartConstructor = 'mapChart';

  /**
   * Base series for map chart to render map outline.
   */
  readonly baseMapSeries: Highcharts.SeriesMapOptions = {
    type: 'map',
    mapData: worldMap as any,
  };

  constructor(private window: WindowRef) {
    super({
      chart: {
        styledMode: true,
      },

      title: null,

      legend: {
        enabled: false,
      },

      credits: {
        enabled: false,
      },

      mapNavigation: {
        enabled: true,
        enableDoubleClickZoom: true,
      },

      tooltip: {
        pointFormat: '',
        borderRadius: 16,
      },

      series: [],

      plotOptions: {
        series: {
          point: {
            events: {
              /**
               * Emit an event when hovering over a point.
               *
               * @param event The event.
               */
              mouseOver: (event: PointerEvent) => {
                this.pointMouseOver.emit(this.chart?.pointer.normalize(event).target);
              },

              /**
               * Emit an event when hovering out of a point.
               *
               * @param event The event.
               */
              mouseOut: (event: PointerEvent) => {
                this.pointMouseOut.emit(this.chart?.pointer.normalize(event).target);
              },
            },
          },
        },
        mapbubble: {
          events: {
            /**
             * This is callback function for click event on map bubbles.
             */
            click: (event: BubbleClickEventObject) => {
              if (this._useZoom) {
                const { options } = event.point as any;
                if (this._chart && options) {
                  const position = this._chart.fromLatLonToPoint({ lat: options.lat, lon: options.lon });

                  // Zoom map to clicked point
                  this._chart.mapZoom(0.5, position.x, position.y, event.chartX, event.chartY);
                }
              }

              // Emit event
              this.bubbleClick.emit(event);
            },
          },
        },
      },
    });
    /**
     * Proj4 is required to convert {lat, lon} to highmap coordinates.
     * Highmaps looks for proj4 in Window object.
     * Hence setting it here.
     */
    window.nativeWindow.proj4 = proj4;
  }

  /**
   * Lifecycle hook.
   * called when component is initialized.
   */
  ngOnInit() {
    this.chartOptions.tooltip.pointFormat = this.pointFormat;
    super.ngOnInit();
  }

  ngOnDestroy() {
    this.window.nativeWindow.proj4 = null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (!changes.useZoom && !changes.seriesData) {
      return;
    }

    const { chartOptions } = this;

    if (changes.useZoom) {
      chartOptions.mapNavigation = {
        enabled: this._useZoom,
        enableDoubleClickZoom: this._useZoom,
      };
    }
    if (changes.seriesData) {
      chartOptions.series = [this.baseMapSeries, ...this._seriesData];
    }
    this.render();
  }
}
