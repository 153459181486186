/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityElixirApiConfiguration as __Configuration } from '../cohesity-elixir-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Runbooks } from '../models/runbooks';
import { Runbook } from '../models/runbook';
import { RunbookOperationResult } from '../models/runbook-operation-result';
import { RunbookOperationRequest } from '../models/runbook-operation-request';
import { RunbookRun } from '../models/runbook-run';
import { RunbookStatusResponse } from '../models/runbook-status-response';
import { RunbookRunStatusResponse } from '../models/runbook-run-status-response';
import { RunbookRunProgressResponse } from '../models/runbook-run-progress-response';
import { VmSnapshotInfo } from '../models/vm-snapshot-info';
import { RunbookReportOperationResult } from '../models/runbook-report-operation-result';
import { RunbookReportOperationRequest } from '../models/runbook-report-operation-request';
import { SuccessResponseBody } from '../models/success-response-body';
@Injectable({
  providedIn: 'root',
})
class RunbooksService extends __BaseService {
  static readonly GetRunbooksPath = '/runbooks';
  static readonly CreateRunbookPath = '/runbooks';
  static readonly GetRunbookByIdPath = '/runbooks/{id}';
  static readonly UpdateRunbookPath = '/runbooks/{id}';
  static readonly DeleteRunbookPath = '/runbooks/{id}';
  static readonly RunbookOperationPath = '/runbooks/{id}/operations';
  static readonly GetRunbookRunsPath = '/runbooks/{id}/runs';
  static readonly GetRunbookStatusPath = '/runbooks/{id}/status';
  static readonly GetRunbookRunByIdPath = '/runbooks/{id}/runs/{runId}';
  static readonly RunbookRunOperationPath = '/runbooks/{id}/runs/{runId}/operations';
  static readonly GetRunbookRunStatusPath = '/runbooks/{id}/runs/{runId}/status';
  static readonly GetRunbookRunProgressPath = '/runbooks/{id}/runs/{runId}/progress';
  static readonly GetVMsSnapshotPath = '/snapshots';
  static readonly CreateReportPath = '/reports';
  static readonly HealthCheckPath = '/heartbeat';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List of Runbooks filtered by query parameters.
   *
   * Returns the list of filtered Runbooks.
   * @param params The `RunbooksService.GetRunbooksParams` containing the following parameters:
   *
   * - `tenantIds`: Ids of the tenants.
   *
   * - `runbookStatus`: Status of Runbook.
   *
   * - `returnSummary`: ReturnSummary specifies if only basic properties
   *   should be returned for Runbooks instead of complete data such
   *   as data about Runs.
   *
   * - `isDeleted`: If true, return only Runbooks that are marked for deletion.
   *   If false, return Runbooks that are not marked for deletion.
   *
   * - `ids`: List of Runbook ids.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * - `X-User-Info`:
   *
   * @return List of Runbooks
   */
  GetRunbooksResponse(params: RunbooksService.GetRunbooksParams): __Observable<__StrictHttpResponse<Runbooks>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.runbookStatus != null) __params = __params.set('runbookStatus', params.runbookStatus.toString());
    if (params.returnSummary != null) __params = __params.set('returnSummary', params.returnSummary.toString());
    if (params.isDeleted != null) __params = __params.set('isDeleted', params.isDeleted.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    if (params.XUserInfo != null) __headers = __headers.set('X-User-Info', params.XUserInfo.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/runbooks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Runbooks>;
      })
    );
  }
  /**
   * List of Runbooks filtered by query parameters.
   *
   * Returns the list of filtered Runbooks.
   * @param params The `RunbooksService.GetRunbooksParams` containing the following parameters:
   *
   * - `tenantIds`: Ids of the tenants.
   *
   * - `runbookStatus`: Status of Runbook.
   *
   * - `returnSummary`: ReturnSummary specifies if only basic properties
   *   should be returned for Runbooks instead of complete data such
   *   as data about Runs.
   *
   * - `isDeleted`: If true, return only Runbooks that are marked for deletion.
   *   If false, return Runbooks that are not marked for deletion.
   *
   * - `ids`: List of Runbook ids.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * - `X-User-Info`:
   *
   * @return List of Runbooks
   */
  GetRunbooks(params: RunbooksService.GetRunbooksParams): __Observable<Runbooks> {
    return this.GetRunbooksResponse(params).pipe(
      __map(_r => _r.body as Runbooks)
    );
  }

  /**
   * Create a Runbook.
   *
   * Creates a new Runbook and returns the created Runbook.
   * @param params The `RunbooksService.CreateRunbookParams` containing the following parameters:
   *
   * - `body`: Request body to create/update a Runbook.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateRunbookResponse(params: RunbooksService.CreateRunbookParams): __Observable<__StrictHttpResponse<Runbook>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/runbooks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Runbook>;
      })
    );
  }
  /**
   * Create a Runbook.
   *
   * Creates a new Runbook and returns the created Runbook.
   * @param params The `RunbooksService.CreateRunbookParams` containing the following parameters:
   *
   * - `body`: Request body to create/update a Runbook.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateRunbook(params: RunbooksService.CreateRunbookParams): __Observable<Runbook> {
    return this.CreateRunbookResponse(params).pipe(
      __map(_r => _r.body as Runbook)
    );
  }

  /**
   * Returns details of single Runbook.
   *
   * Returns the Runbook details corresponding to specified id.
   * @param params The `RunbooksService.GetRunbookByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Runbook.
   *
   * - `returnSummary`: ReturnSummary specifies if only basic properties
   *   should be returned for Runbooks instead of complete data such
   *   as data about Runs.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRunbookByIdResponse(params: RunbooksService.GetRunbookByIdParams): __Observable<__StrictHttpResponse<Runbook>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.returnSummary != null) __params = __params.set('returnSummary', params.returnSummary.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/runbooks/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Runbook>;
      })
    );
  }
  /**
   * Returns details of single Runbook.
   *
   * Returns the Runbook details corresponding to specified id.
   * @param params The `RunbooksService.GetRunbookByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Runbook.
   *
   * - `returnSummary`: ReturnSummary specifies if only basic properties
   *   should be returned for Runbooks instead of complete data such
   *   as data about Runs.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRunbookById(params: RunbooksService.GetRunbookByIdParams): __Observable<Runbook> {
    return this.GetRunbookByIdResponse(params).pipe(
      __map(_r => _r.body as Runbook)
    );
  }

  /**
   * Update a Runbook.
   *
   * Updates an existing Runbook and returns the updated Runbook.
   * @param params The `RunbooksService.UpdateRunbookParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Runbook.
   *
   * - `body`: Request body to create/update a Runbook.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateRunbookResponse(params: RunbooksService.UpdateRunbookParams): __Observable<__StrictHttpResponse<Runbook>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/runbooks/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Runbook>;
      })
    );
  }
  /**
   * Update a Runbook.
   *
   * Updates an existing Runbook and returns the updated Runbook.
   * @param params The `RunbooksService.UpdateRunbookParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Runbook.
   *
   * - `body`: Request body to create/update a Runbook.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateRunbook(params: RunbooksService.UpdateRunbookParams): __Observable<Runbook> {
    return this.UpdateRunbookResponse(params).pipe(
      __map(_r => _r.body as Runbook)
    );
  }

  /**
   * Delete a Runbook.
   *
   * Marks a Runbook for deletion and returns success if Runbook is
   * deleted.
   * @param params The `RunbooksService.DeleteRunbookParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Runbook.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteRunbookResponse(params: RunbooksService.DeleteRunbookParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/runbooks/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a Runbook.
   *
   * Marks a Runbook for deletion and returns success if Runbook is
   * deleted.
   * @param params The `RunbooksService.DeleteRunbookParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Runbook.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteRunbook(params: RunbooksService.DeleteRunbookParams): __Observable<null> {
    return this.DeleteRunbookResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Start operation on Runbook.
   *
   * Initiates or performs action based on passed operation Id.
   * Returns success if operation is started or completed.
   * In case the operation is long running, its status can be checked
   * using status API.
   * @param params The `RunbooksService.RunbookOperationParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Runbook.
   *
   * - `body`: Request body for a Runbook operation.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RunbookOperationResponse(params: RunbooksService.RunbookOperationParams): __Observable<__StrictHttpResponse<RunbookOperationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/runbooks/${encodeURIComponent(params.id)}/operations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RunbookOperationResult>;
      })
    );
  }
  /**
   * Start operation on Runbook.
   *
   * Initiates or performs action based on passed operation Id.
   * Returns success if operation is started or completed.
   * In case the operation is long running, its status can be checked
   * using status API.
   * @param params The `RunbooksService.RunbookOperationParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Runbook.
   *
   * - `body`: Request body for a Runbook operation.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RunbookOperation(params: RunbooksService.RunbookOperationParams): __Observable<RunbookOperationResult> {
    return this.RunbookOperationResponse(params).pipe(
      __map(_r => _r.body as RunbookOperationResult)
    );
  }

  /**
   * Returns list of filtered runs for a Runbook.
   *
   * Returns list of runs for a Runbook. Filters the list based on
   * parameter. Contains historical run details.
   * @param params The `RunbooksService.GetRunbookRunsParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Runbook.
   *
   * - `runbookRunStatus`: Status of Runbook run
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRunbookRunsResponse(params: RunbooksService.GetRunbookRunsParams): __Observable<__StrictHttpResponse<Array<RunbookRun>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.runbookRunStatus != null) __params = __params.set('runbookRunStatus', params.runbookRunStatus.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/runbooks/${encodeURIComponent(params.id)}/runs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RunbookRun>>;
      })
    );
  }
  /**
   * Returns list of filtered runs for a Runbook.
   *
   * Returns list of runs for a Runbook. Filters the list based on
   * parameter. Contains historical run details.
   * @param params The `RunbooksService.GetRunbookRunsParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Runbook.
   *
   * - `runbookRunStatus`: Status of Runbook run
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRunbookRuns(params: RunbooksService.GetRunbookRunsParams): __Observable<Array<RunbookRun>> {
    return this.GetRunbookRunsResponse(params).pipe(
      __map(_r => _r.body as Array<RunbookRun>)
    );
  }

  /**
   * Returns last status of the Runbook.
   *
   * Returns status information of a Runbook.
   * The status may be Created/Validated/Running/Failed etc.
   * @param params The `RunbooksService.GetRunbookStatusParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Runbook.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRunbookStatusResponse(params: RunbooksService.GetRunbookStatusParams): __Observable<__StrictHttpResponse<RunbookStatusResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/runbooks/${encodeURIComponent(params.id)}/status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RunbookStatusResponse>;
      })
    );
  }
  /**
   * Returns last status of the Runbook.
   *
   * Returns status information of a Runbook.
   * The status may be Created/Validated/Running/Failed etc.
   * @param params The `RunbooksService.GetRunbookStatusParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the Runbook.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRunbookStatus(params: RunbooksService.GetRunbookStatusParams): __Observable<RunbookStatusResponse> {
    return this.GetRunbookStatusResponse(params).pipe(
      __map(_r => _r.body as RunbookStatusResponse)
    );
  }

  /**
   * Returns the details of a Runbook run.
   *
   * Returns details of Runbook run. This includes detailed information
   * of objects that are part of the Runbook.
   * @param params The `RunbooksService.GetRunbookRunByIdParams` containing the following parameters:
   *
   * - `runId`: Specifies a unique id of the Runbook run.
   *
   * - `id`: Specifies a unique id of the Runbook.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRunbookRunByIdResponse(params: RunbooksService.GetRunbookRunByIdParams): __Observable<__StrictHttpResponse<RunbookRun>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/runbooks/${encodeURIComponent(params.id)}/runs/${encodeURIComponent(params.runId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RunbookRun>;
      })
    );
  }
  /**
   * Returns the details of a Runbook run.
   *
   * Returns details of Runbook run. This includes detailed information
   * of objects that are part of the Runbook.
   * @param params The `RunbooksService.GetRunbookRunByIdParams` containing the following parameters:
   *
   * - `runId`: Specifies a unique id of the Runbook run.
   *
   * - `id`: Specifies a unique id of the Runbook.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRunbookRunById(params: RunbooksService.GetRunbookRunByIdParams): __Observable<RunbookRun> {
    return this.GetRunbookRunByIdResponse(params).pipe(
      __map(_r => _r.body as RunbookRun)
    );
  }

  /**
   * Start operation on Runbook run.
   *
   * Initiates or performs action based on passed operation Id.
   * Returns success if operation is started or completed.
   * In case the operation is long running, its status can be checked
   * using status API.
   * @param params The `RunbooksService.RunbookRunOperationParams` containing the following parameters:
   *
   * - `runId`: Specifies a unique id of the Runbook run.
   *
   * - `id`: Specifies a unique id of the Runbook.
   *
   * - `body`: Request body for a Runbook operation.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RunbookRunOperationResponse(params: RunbooksService.RunbookRunOperationParams): __Observable<__StrictHttpResponse<RunbookOperationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/runbooks/${encodeURIComponent(params.id)}/runs/${encodeURIComponent(params.runId)}/operations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RunbookOperationResult>;
      })
    );
  }
  /**
   * Start operation on Runbook run.
   *
   * Initiates or performs action based on passed operation Id.
   * Returns success if operation is started or completed.
   * In case the operation is long running, its status can be checked
   * using status API.
   * @param params The `RunbooksService.RunbookRunOperationParams` containing the following parameters:
   *
   * - `runId`: Specifies a unique id of the Runbook run.
   *
   * - `id`: Specifies a unique id of the Runbook.
   *
   * - `body`: Request body for a Runbook operation.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RunbookRunOperation(params: RunbooksService.RunbookRunOperationParams): __Observable<RunbookOperationResult> {
    return this.RunbookRunOperationResponse(params).pipe(
      __map(_r => _r.body as RunbookOperationResult)
    );
  }

  /**
   * Get status of the Runbook run.
   *
   * Returns status information of a Runbook run. The status may be
   * Running/Failed/Success etc.
   * @param params The `RunbooksService.GetRunbookRunStatusParams` containing the following parameters:
   *
   * - `runId`: Specifies a unique id of the Runbook run.
   *
   * - `id`: Specifies a unique id of the Runbook.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRunbookRunStatusResponse(params: RunbooksService.GetRunbookRunStatusParams): __Observable<__StrictHttpResponse<RunbookRunStatusResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/runbooks/${encodeURIComponent(params.id)}/runs/${encodeURIComponent(params.runId)}/status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RunbookRunStatusResponse>;
      })
    );
  }
  /**
   * Get status of the Runbook run.
   *
   * Returns status information of a Runbook run. The status may be
   * Running/Failed/Success etc.
   * @param params The `RunbooksService.GetRunbookRunStatusParams` containing the following parameters:
   *
   * - `runId`: Specifies a unique id of the Runbook run.
   *
   * - `id`: Specifies a unique id of the Runbook.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRunbookRunStatus(params: RunbooksService.GetRunbookRunStatusParams): __Observable<RunbookRunStatusResponse> {
    return this.GetRunbookRunStatusResponse(params).pipe(
      __map(_r => _r.body as RunbookRunStatusResponse)
    );
  }

  /**
   * Get detailed progress status of the Runbook run.
   *
   * Returns Runbook run progress information. This includes detailed
   * information for each objects that are part of the Runbook.
   * The object status can be Created/Creating/Failed/Running/Success.
   * Optionally filters response based on object ids.
   * @param params The `RunbooksService.GetRunbookRunProgressParams` containing the following parameters:
   *
   * - `runId`: Specifies a unique id of the Runbook run.
   *
   * - `id`: Specifies a unique id of the Runbook.
   *
   * - `objectIds`: List of Runbook object ids.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRunbookRunProgressResponse(params: RunbooksService.GetRunbookRunProgressParams): __Observable<__StrictHttpResponse<RunbookRunProgressResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    (params.objectIds || []).forEach(val => {if (val != null) __params = __params.append('objectIds', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/runbooks/${encodeURIComponent(params.id)}/runs/${encodeURIComponent(params.runId)}/progress`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RunbookRunProgressResponse>;
      })
    );
  }
  /**
   * Get detailed progress status of the Runbook run.
   *
   * Returns Runbook run progress information. This includes detailed
   * information for each objects that are part of the Runbook.
   * The object status can be Created/Creating/Failed/Running/Success.
   * Optionally filters response based on object ids.
   * @param params The `RunbooksService.GetRunbookRunProgressParams` containing the following parameters:
   *
   * - `runId`: Specifies a unique id of the Runbook run.
   *
   * - `id`: Specifies a unique id of the Runbook.
   *
   * - `objectIds`: List of Runbook object ids.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRunbookRunProgress(params: RunbooksService.GetRunbookRunProgressParams): __Observable<RunbookRunProgressResponse> {
    return this.GetRunbookRunProgressResponse(params).pipe(
      __map(_r => _r.body as RunbookRunProgressResponse)
    );
  }

  /**
   * List of VM uids with snapshot details for each.
   *
   * Returns the list of snapshot details for VMs in runbook based on
   * target profile.
   * @param params The `RunbooksService.GetVMsSnapshotParams` containing the following parameters:
   *
   * - `vmSourceUid`: Source uid of VM in runbook for which snapshot are to be fetched.
   *
   * - `runbookId`: Runbook id for which snapshots are to be fetched.
   *
   * - `profileId`: Profile id of runbook for which snapshots are to be fetched.
   *
   * - `includeV2SnapshotDetails`: The v2 apis style response for snapshots is expected.
   *
   * - `includeCdpInfo`: Include CDP details for entities if they are protected via CDP
   *   policy/job.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetVMsSnapshotResponse(params: RunbooksService.GetVMsSnapshotParams): __Observable<__StrictHttpResponse<Array<VmSnapshotInfo>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.vmSourceUid != null) __params = __params.set('vmSourceUid', params.vmSourceUid.toString());
    if (params.runbookId != null) __params = __params.set('runbookId', params.runbookId.toString());
    if (params.profileId != null) __params = __params.set('profileId', params.profileId.toString());
    if (params.includeV2SnapshotDetails != null) __params = __params.set('includeV2SnapshotDetails', params.includeV2SnapshotDetails.toString());
    if (params.includeCdpInfo != null) __params = __params.set('includeCdpInfo', params.includeCdpInfo.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/snapshots`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<VmSnapshotInfo>>;
      })
    );
  }
  /**
   * List of VM uids with snapshot details for each.
   *
   * Returns the list of snapshot details for VMs in runbook based on
   * target profile.
   * @param params The `RunbooksService.GetVMsSnapshotParams` containing the following parameters:
   *
   * - `vmSourceUid`: Source uid of VM in runbook for which snapshot are to be fetched.
   *
   * - `runbookId`: Runbook id for which snapshots are to be fetched.
   *
   * - `profileId`: Profile id of runbook for which snapshots are to be fetched.
   *
   * - `includeV2SnapshotDetails`: The v2 apis style response for snapshots is expected.
   *
   * - `includeCdpInfo`: Include CDP details for entities if they are protected via CDP
   *   policy/job.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetVMsSnapshot(params: RunbooksService.GetVMsSnapshotParams): __Observable<Array<VmSnapshotInfo>> {
    return this.GetVMsSnapshotResponse(params).pipe(
      __map(_r => _r.body as Array<VmSnapshotInfo>)
    );
  }

  /**
   * Create Runbook Report.
   *
   * Creates a report for the runbook and emails it.
   * @param params The `RunbooksService.CreateReportParams` containing the following parameters:
   *
   * - `body`: Request body for a Runbook Report operation.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateReportResponse(params: RunbooksService.CreateReportParams): __Observable<__StrictHttpResponse<RunbookReportOperationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/reports`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RunbookReportOperationResult>;
      })
    );
  }
  /**
   * Create Runbook Report.
   *
   * Creates a report for the runbook and emails it.
   * @param params The `RunbooksService.CreateReportParams` containing the following parameters:
   *
   * - `body`: Request body for a Runbook Report operation.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateReport(params: RunbooksService.CreateReportParams): __Observable<RunbookReportOperationResult> {
    return this.CreateReportResponse(params).pipe(
      __map(_r => _r.body as RunbookReportOperationResult)
    );
  }

  /**
   * Health check of Controller.
   *
   * Returns success response if Controller is alive.
   * @return Success
   */
  HealthCheckResponse(): __Observable<__StrictHttpResponse<SuccessResponseBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/heartbeat`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SuccessResponseBody>;
      })
    );
  }
  /**
   * Health check of Controller.
   *
   * Returns success response if Controller is alive.
   * @return Success
   */
  HealthCheck(): __Observable<SuccessResponseBody> {
    return this.HealthCheckResponse().pipe(
      __map(_r => _r.body as SuccessResponseBody)
    );
  }
}

module RunbooksService {

  /**
   * Parameters for GetRunbooks
   */
  export interface GetRunbooksParams {

    /**
     * Ids of the tenants.
     */
    tenantIds?: Array<string>;

    /**
     * Status of Runbook.
     */
    runbookStatus?: 'kCreated' | 'kRunning' | 'kSuccess' | 'kFailed';

    /**
     * ReturnSummary specifies if only basic properties
     * should be returned for Runbooks instead of complete data such
     * as data about Runs.
     */
    returnSummary?: boolean;

    /**
     * If true, return only Runbooks that are marked for deletion.
     * If false, return Runbooks that are not marked for deletion.
     */
    isDeleted?: boolean;

    /**
     * List of Runbook ids.
     */
    ids?: Array<string>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
    XUserInfo?: string;
  }

  /**
   * Parameters for CreateRunbook
   */
  export interface CreateRunbookParams {

    /**
     * Request body to create/update a Runbook.
     */
    body: Runbook;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetRunbookById
   */
  export interface GetRunbookByIdParams {

    /**
     * Specifies a unique id of the Runbook.
     */
    id: string;

    /**
     * ReturnSummary specifies if only basic properties
     * should be returned for Runbooks instead of complete data such
     * as data about Runs.
     */
    returnSummary?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateRunbook
   */
  export interface UpdateRunbookParams {

    /**
     * Specifies a unique id of the Runbook.
     */
    id: string;

    /**
     * Request body to create/update a Runbook.
     */
    body: Runbook;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteRunbook
   */
  export interface DeleteRunbookParams {

    /**
     * Specifies a unique id of the Runbook.
     */
    id: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for RunbookOperation
   */
  export interface RunbookOperationParams {

    /**
     * Specifies a unique id of the Runbook.
     */
    id: string;

    /**
     * Request body for a Runbook operation.
     */
    body: RunbookOperationRequest;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetRunbookRuns
   */
  export interface GetRunbookRunsParams {

    /**
     * Specifies a unique id of the Runbook.
     */
    id: string;

    /**
     * Status of Runbook run
     */
    runbookRunStatus?: 'kInProgress' | 'kCompleted' | 'kFailed' | 'kCanceled' | 'kCanceling' | 'kDeleted' | 'kDeleting';

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetRunbookStatus
   */
  export interface GetRunbookStatusParams {

    /**
     * Specifies a unique id of the Runbook.
     */
    id: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetRunbookRunById
   */
  export interface GetRunbookRunByIdParams {

    /**
     * Specifies a unique id of the Runbook run.
     */
    runId: number;

    /**
     * Specifies a unique id of the Runbook.
     */
    id: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for RunbookRunOperation
   */
  export interface RunbookRunOperationParams {

    /**
     * Specifies a unique id of the Runbook run.
     */
    runId: number;

    /**
     * Specifies a unique id of the Runbook.
     */
    id: string;

    /**
     * Request body for a Runbook operation.
     */
    body: RunbookOperationRequest;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetRunbookRunStatus
   */
  export interface GetRunbookRunStatusParams {

    /**
     * Specifies a unique id of the Runbook run.
     */
    runId: number;

    /**
     * Specifies a unique id of the Runbook.
     */
    id: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetRunbookRunProgress
   */
  export interface GetRunbookRunProgressParams {

    /**
     * Specifies a unique id of the Runbook run.
     */
    runId: number;

    /**
     * Specifies a unique id of the Runbook.
     */
    id: string;

    /**
     * List of Runbook object ids.
     */
    objectIds?: Array<string>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetVMsSnapshot
   */
  export interface GetVMsSnapshotParams {

    /**
     * Source uid of VM in runbook for which snapshot are to be fetched.
     */
    vmSourceUid?: string;

    /**
     * Runbook id for which snapshots are to be fetched.
     */
    runbookId?: string;

    /**
     * Profile id of runbook for which snapshots are to be fetched.
     */
    profileId?: string;

    /**
     * The v2 apis style response for snapshots is expected.
     */
    includeV2SnapshotDetails?: boolean;

    /**
     * Include CDP details for entities if they are protected via CDP
     * policy/job.
     */
    includeCdpInfo?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateReport
   */
  export interface CreateReportParams {

    /**
     * Request body for a Runbook Report operation.
     */
    body: RunbookReportOperationRequest;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { RunbooksService }
