import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { BaseProtectionBuilderComponent } from '../../base-protection-builder/base-protection-builder.component';

@Component({
  selector: 'coh-settings-list-description',
  templateUrl: './settings-list-description.component.html',
  styleUrls: ['./settings-list-description.component.scss'],
})
export class SettingsListDescriptionComponent
  extends BaseProtectionBuilderComponent<string, any> {
  /**
   * Default description value.
   */
  _value = '';

  /**
   * Override of addFormControl from BaseProtectionBuilderComponent so this component's FormControl isn't required.
   */
  addFormControl() {
    this.formGroup.addControl(this.name, new UntypedFormControl(this.value));
  }

  /**
   * Form control init.
   */
  initFormControl() {
    if (this.protectionGroup && this.protectionGroup.description) {
      this.value = this.protectionGroup.description;
      this.formControl.setValue(this.value);
    }
  }
}
