/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { McmSignups } from '../models/mcm-signups';
import { McmSignup } from '../models/mcm-signup';
import { CreateMcmSignupRequest } from '../models/create-mcm-signup-request';
import { UpdateMcmSignupRequest } from '../models/update-mcm-signup-request';
@Injectable({
  providedIn: 'root',
})
class HeliosSignupService extends __BaseService {
  static readonly GetMcmSignupsPath = '/mcm/signup';
  static readonly CreateMcmSignupPath = '/mcm/signup';
  static readonly UpdateMcmSignupPath = '/mcm/signup/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List MCM signup requests.
   *
   * List MCM signup requests.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param emails Specifies a list of email ids to filter the signup requests.
   * @return Success
   */
  GetMcmSignupsResponse(regionId?: string,
    emails?: Array<string>): __Observable<__StrictHttpResponse<McmSignups>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    (emails || []).forEach(val => {if (val != null) __params = __params.append('emails', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/signup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmSignups>;
      })
    );
  }
  /**
   * List MCM signup requests.
   *
   * List MCM signup requests.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param emails Specifies a list of email ids to filter the signup requests.
   * @return Success
   */
  GetMcmSignups(regionId?: string,
    emails?: Array<string>): __Observable<McmSignups> {
    return this.GetMcmSignupsResponse(regionId, emails).pipe(
      __map(_r => _r.body as McmSignups)
    );
  }

  /**
   * Create a signup request for MCM.
   *
   * Create a signup request for MCM.
   * @param body Specifies the parameters to create a signup request for MCM.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  CreateMcmSignupResponse(body: CreateMcmSignupRequest,
    regionId?: string): __Observable<__StrictHttpResponse<McmSignup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/signup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmSignup>;
      })
    );
  }
  /**
   * Create a signup request for MCM.
   *
   * Create a signup request for MCM.
   * @param body Specifies the parameters to create a signup request for MCM.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  CreateMcmSignup(body: CreateMcmSignupRequest,
    regionId?: string): __Observable<McmSignup> {
    return this.CreateMcmSignupResponse(body, regionId).pipe(
      __map(_r => _r.body as McmSignup)
    );
  }

  /**
   * Update MCM signup request.
   *
   * Update MCM signup request.
   * @param params The `HeliosSignupService.UpdateMcmSignupParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the MCM signup request.
   *
   * - `body`: Specifies the parameters to update a signup request for MCM.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  UpdateMcmSignupResponse(params: HeliosSignupService.UpdateMcmSignupParams): __Observable<__StrictHttpResponse<McmSignup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/mcm/signup/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmSignup>;
      })
    );
  }
  /**
   * Update MCM signup request.
   *
   * Update MCM signup request.
   * @param params The `HeliosSignupService.UpdateMcmSignupParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the MCM signup request.
   *
   * - `body`: Specifies the parameters to update a signup request for MCM.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  UpdateMcmSignup(params: HeliosSignupService.UpdateMcmSignupParams): __Observable<McmSignup> {
    return this.UpdateMcmSignupResponse(params).pipe(
      __map(_r => _r.body as McmSignup)
    );
  }
}

module HeliosSignupService {

  /**
   * Parameters for UpdateMcmSignup
   */
  export interface UpdateMcmSignupParams {

    /**
     * Specifies the id of the MCM signup request.
     */
    id: number;

    /**
     * Specifies the parameters to update a signup request for MCM.
     */
    body: UpdateMcmSignupRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;
  }
}

export { HeliosSignupService }
