import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { AngularMaterialModule } from '@cohesity/helix';
import { UtilPipesModule } from '@cohesity/utils';
import { TranslateModule } from '@ngx-translate/core';

import { DefaultRecoverySettingsComponent } from './default-recovery-settings.component';

const COMPONENTS = [DefaultRecoverySettingsComponent];

@NgModule({
  imports: [
    AngularMaterialModule,
    CommonModule,
    TranslateModule.forChild(),
    UtilPipesModule,
  ],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
})
export class DefaultRecoverySettingsModule {}
