// Field Message Service

;(function(angular, undefined) {
  'use strict';

  angular.module('C').service('FieldMessageService', fieldMessageServiceFn);

  function fieldMessageServiceFn($http, API, $q, SlideModalService,
     $translate, evalAJAX) {

    // Boolean to check cache filters and translations.
    var _isFilterCached = false;

    var service = {
      getFieldMessageFilters: getFieldMessageFilters,
      getFieldMessages: getFieldMessages,
      openDetailModal: openDetailModal,
    };

    /**
     * List of types for field messages.
     */
    var _filterOptions = {
      types: {
        'Field Notice': 'fieldMessages.filters.types.field',
        'Product Release Note': 'fieldMessages.filters.types.product',
        'Tips': 'fieldMessages.filters.types.tips',
        'Maintenance Window': 'fieldMessages.filters.types.maintenance',
        'SaaS Release Notes': 'fieldMessages.filters.types.saas',
      }
    };

    /**
     * Static filters for Types along with translations
     *
     * @method    getFieldMessageFilters
     */
    function getFieldMessageFilters() {
      if (!_isFilterCached) {
        for (var filterKey in _filterOptions) {
          for (var optionKey in _filterOptions[filterKey]) {
            // Translation ideally should happen once, in the parent scope, but
            // the translate service is not ready at that point.
            _filterOptions[filterKey][optionKey] =
              $translate.instant(_filterOptions[filterKey][optionKey]);
          }
          _isFilterCached = true;
        }
      }
      return $q.when(_filterOptions);
    }

    /**
     * Service call to get all field messages.
     *
     * @method    getFieldMessages
     * @param     {object}  params
     * @returns   {Array}   List of field messages
     */
    function getFieldMessages(params) {
      return $http({
        method: 'get',
        url: API.mcm('fieldMessages'),
        params: params,
      }).then(function onSuccess(response) {
        return response.data || [];
      });
    }

    /**
     * Opens detail modal for field message.
     *
     * @method    openDetailModal
     * @param     {object}  fieldMessage
     */
    function openDetailModal(fieldMessage) {
      var modalConfig = {
        component: 'fieldMessageDetail',
        size: 'xl',
        resolve: {
          fieldMessage: function setFieldMessage() {
            return fieldMessage;
          },
        },
      };

      return SlideModalService.newModal(modalConfig);
    }

    return service;
  }

})(angular);
