// Component: Tenant Policies Modal Component

;(function(angular, undefined) {
  'use strict';

  var configOptions = {
    bindings: {
      /**
       * Resolved bindings provided via uib-modal
       *
       * @type  {Object}  [resolve=undefined]
       */
      resolve: '=',

      /**
       * uib-modal instance used to close or dismiss then modal
       *
       * @type  {Object}  [resolve=modalInstance]
       */
      modalInstance: '=',
    },
    controller: 'TenantPoliciesModalCtrl',
    templateUrl: 'app/admin/access-management/tenants/tenant-policies-modal/tenant-policies-modal.html',
  };

  angular.module('C.tenants')
    .controller('TenantPoliciesModalCtrl', tenantPoliciesModalCtrlFn)
    .component('tenantPoliciesModal', configOptions);

  function tenantPoliciesModalCtrlFn(_, evalAJAX, PolicyService, NgProtectionPolicyService,
    PubJobService, NgIrisContextService, FEATURE_FLAGS) {

    var $ctrl = this;

    // declare component methods
    _.assign($ctrl, {
      addPolicyModal: addPolicyModal,
      cancel: cancel,
      isSelectionDisabled: isSelectionDisabled,
      isJobSelected: isJobSelected,
      isSelected: isSelected,
      openEditPolicyModal: PolicyService.openEditPolicyModal,
      save: save,
      updateJobSelection: updateJobSelection,
      updatePolicySelection: updatePolicySelection,

      // Component life-cycle methods.
      $onInit: $onInit,
    });

    /**
     * initialize component context
     *
     * @method   initializeCtrl
     */
    function initializeCtrl() {
      _.assign($ctrl, {
        // UI States
        maxVisibleJobs: 2,
        policies: [],
        isPoliciesReady: false,
        selectedJobsMap: {},
        selectedPoliciesMap: {},
      });

      // initialize selected tenant jobs & policies
      if (_.isObject($ctrl.resolve.selectedJobsMap)) {
        $ctrl.selectedJobsMap = $ctrl.resolve.selectedJobsMap;
      }
      if (_.isObject($ctrl.resolve.selectedPoliciesMap)) {
        $ctrl.selectedPoliciesMap = $ctrl.resolve.selectedPoliciesMap;
      }
    }

    /**
     * Initializes the controller.
     *
     * @method     $onInit
     */
    function $onInit() {
      initializeCtrl();

      getData();
    }

    /**
     * Fetch data for all policies and their assignable jobs
     *
     * @method   getData
     */
    function getData() {
      $ctrl.isPoliciesReady = false;

      // get all policies
      getPolicies().then(
        function gotPolicies() {
          // get all assignable jobs
          return getAssignableJobs();
        }
      ).finally(function getPoliciesFinally() {
        $ctrl.isPoliciesReady = true;
      });
    }

    /**
     * interfaces PolicyService to get a list of policies, and
     * adds them to our scope object
     *
     * @method   getPolicies
     * @return   {promise}    returns a promise resolved with all policies or
     *                        rejected with error
     */
    function getPolicies() {
      return NgProtectionPolicyService.getPolicies(NgIrisContextService.irisContext.clusterInfo.id).toPromise().then(
        function getPoliciesSuccess(policiesResponse) {
          const policies = policiesResponse?.policies ?? [];
          // filter out policy templates and usable policies.
          $ctrl.policies = policies.filter(function eachPolicy(policy) {
            if (FEATURE_FLAGS.ngPolicyTemplate) {
              // All local policies and usable linked policies
              if (policy.parentPolicyId) {
                return policy.isUsable;
              }
              return true;
            } else {
              // Only local policies
              return !policy.parentPolicyId;
            }
          });

          $ctrl.policies.forEach(function forEachPolicy(policy) {
            _.assign(policy, $ctrl.selectedPoliciesMap[policy.id]);
          });
        },
        evalAJAX.errorMessage
      );
    }

    /**
     * Fetches list of assignable protection jobs by sources, policy and
     * viewboxes
     *
     * @method   getAssignableJobs
     * @return   {promise}    returns a promise resolved with all assignable
     *                        protection or rejected with error
     */
    function getAssignableJobs() {
      var policyIds = _.map($ctrl.policies, 'id');

      return PubJobService.getAssociatedJobs(
        $ctrl.resolve.sourceIds,
        policyIds,
        $ctrl.resolve.selectedViewboxesIds,
        $ctrl.resolve.selectedViewMagnetoIds,
      ).then(function eachJob(jobs) {
            var policyJobsMap = _.groupBy(jobs, 'policyId');

            // Assign the jobs according to the policy.
            _.forEach($ctrl.policies, function forEachPolicy(policy) {
              policy._jobs = policyJobsMap[policy.id] || [];
            });

            // order polices in descending order of associated job count
            $ctrl.policies = _.orderBy($ctrl.policies, '_assignedJobs.length');
          },
          evalAJAX.errorMessage
        );
    }

    /**
     * On save, tag selected users with the provided tenant.
     *
     * @method   save
     */
    function save() {
      // If any policy is updated inside the modal, then update the
      // selectedPoliciesMap too.
      $ctrl.policies.forEach(function forEachPolicy(policy) {
        if ($ctrl.selectedPoliciesMap[policy.id]) {
          _.merge($ctrl.selectedPoliciesMap[policy.id], policy);
        }
      });

      $ctrl.modalInstance.close({
        jobsMap: $ctrl.selectedJobsMap,
        policiesMap: $ctrl.selectedPoliciesMap,
      });
    }

    /**
     * handles on cancel action for modal
     *
     * @method   cancel
     */
    function cancel() {
      $ctrl.modalInstance.dismiss();
    }

    /**
     * Checks if the policy is selected.
     *
     * @method   isSelected
     * @param    {Object}    policy   The policy to check.
     * @return   {Boolean}   the policy if it's selected.
     */
    function isSelected(policy) {
      var selectedPolicy = $ctrl.selectedPoliciesMap[policy.id];

      return selectedPolicy && !selectedPolicy._removed;
    }

    /**
     * Checks if the policy can be unassigned.
     * NOTE - A policy can only be unsassigned if it has no assigned jobs.
     *
     * @method   isSelectionDisabled
     * @param    {Object}   policy   The policy to check.
     * @return   {Boolean}  True, if selection is disabled.
     */
    function isSelectionDisabled(policy) {
      return isSelected(policy) && _.get(policy._assignedJobs, 'length');
    }

    /**
     * Updates the selected policies & jobs with the given policy.
     *
     * @method   updatePolicySelection
     * @param    {Object}   policy   The policy to be selected.
     */
    function updatePolicySelection(policy) {
      if (isSelected(policy)) {
        // mark policy for removal when provided markPolicyForRemoval fn
        // returned true else remove that policy from the selected policies list
        if ($ctrl.resolve.markPolicyForRemoval &&
          $ctrl.resolve.markPolicyForRemoval(policy)) {
          $ctrl.selectedPoliciesMap[policy.id]._removed = true;
        } else {
          delete $ctrl.selectedPoliciesMap[policy.id];
        }

        policy._jobs.forEach(function forEachPolicy(job) {
          delete $ctrl.selectedJobsMap[job.id];
        });
      } else {
        $ctrl.selectedPoliciesMap[policy.id] = policy;
        $ctrl.selectedPoliciesMap[policy.id]._removed = false;

        // select all jobs when policy is selected
        _.assign($ctrl.selectedJobsMap, _.keyBy(policy._jobs, 'id'));

        // view all selected jobs when policy is selected
        policy._maxVisibleJobs = policy._jobs.length;
      }
    }

    /**
     * Checks if the job is selected.
     *
     * @method   isSelected
     * @param    {Object}   job   The job to check.
     * @return   {Object}   the job if it's selected.
     */
    function isJobSelected(job) {
      return $ctrl.selectedJobsMap[job.id];
    }

    /**
     * Updates the selected jobs with the given job.
     *
     * @method   updatePolicySelection
     * @param    {Object}   job      The job to be selected.
     * @param    {Object}   policy   The policy job which is getting selected.
     */
    function updateJobSelection(job, policy) {
      if (isJobSelected(job)) {
        delete $ctrl.selectedJobsMap[job.id];
      } else {
        $ctrl.selectedJobsMap[job.id] = job;

        // select the policy of the job which is being selected
        $ctrl.selectedPoliciesMap[policy.id] = policy;
      }
    }

    /**
     * Opens a modal for policy creation. After successful creation
     * updates the list and selects it by default.
     *
     * @method   addPolicyModal
     */
    function addPolicyModal() {
      PolicyService.modifyPolicyModal()
        .then(function policyCreated(modalResp) {
          $ctrl.policies.push(modalResp.policy);
          updatePolicySelection(modalResp.policy);
        });
    }
  }

})(angular);
