import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CloudBackupScriptParams } from '@cohesity/api/v2';
import { ToFormGroup } from '@cohesity/shared-forms';
import { BaseProtectionBuilderComponent } from 'src/app/modules/protection/protection-builder/components/base-protection-builder/base-protection-builder.component';

@Component({
  selector: 'coh-settings-list-pre-post-script',
  templateUrl: './settings-list-pre-post-script.component.html',
  styleUrls: ['./settings-list-pre-post-script.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsListPrePostScriptComponent extends BaseProtectionBuilderComponent<unknown, unknown>{
  /**
   * The internal form group.
   */
  internalFormGroup = new FormGroup<ToFormGroup<CloudBackupScriptParams>>({
    preBackupScript: new FormControl(),
    postSnapshotScript: new FormControl(),
    postBackupScript: new FormControl(),
  });

  /**
   * Add the Scripts form group as control.
   */
  addFormControl() {
    this.formGroup.addControl(this.name, this.internalFormGroup);
  }

  /**
   * Form control init.
   */
  initFormControl() {
    if (this.protectionGroup?.cloudPrePostScript) {
      this.formControl.patchValue(this.protectionGroup.cloudPrePostScript);
    }
  }
}
