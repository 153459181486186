<mat-card class="expansion-card" [ngClass]="size">
  <ng-content select="cog-expansion-card-icon"></ng-content>
  <div class="expansion-card-body">
    <ng-content select="cog-expansion-card-header"></ng-content>
    <ng-content select="cog-expansion-card-content" *ngIf="showContent"></ng-content>
  </div>
  <div class="expansion-card-toggle" *ngIf="showToggle">
    <button mat-icon-button (click)="toggle()" [attr.aria-label]="toggleLabel">
      <cog-icon [size]="size" [shape]="toggleIcon"></cog-icon>
    </button>
  </div>
</mat-card>
