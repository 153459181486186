import { Injectable } from '@angular/core';
import { IrisContextService } from '@cohesity/iris-core';
import { NGXLogger } from 'ngx-logger';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { FeatureFlagsService } from '../../services';
import { GuardPriority, GuardResult, StateGuard } from '../state-guard';

/**
 * Flag used to ensure the warning message for account based feature flags
 * being disabled in dev mode is only displayed once per application lifecycle.
 */
let accountfeatureFlagsWarningDisplayed = false;

@Injectable({
  providedIn: 'root'
})
export class AccountFeatureFlags implements StateGuard {
  /**
   * The guard priority for the State. Hacking it to 'User' priority for iframe
   * environments so that it is loaded after scope selection has been done.
   * (so that correct cluster id goes in /featureFlags xhr call)
   */
  guardPriority = environment.heliosInFrame ? GuardPriority.User : GuardPriority.Banner;

  /**
   * constructor
   *
   * @param irisCtx The Iris Context
   */
  constructor(
    private irisCtx: IrisContextService,
    private featureFlag: FeatureFlagsService,
    private logger: NGXLogger,
  ) {}

  /**
   * OnStart of UI Router State Transition
   */
  onStart(): GuardResult {

    // Do not load any account specific feature flag when you are in dev mode.
    // Currently account feature flags replaces all the feature flags from the
    // proxied system and overwrite any local feature-flag file.
    // Any addition to feature-flag.json will not be applicable.
    // So to circumvent the problem, the developer is responsible to enabling
    // and disabling the feature when running in development mode.
    if (!environment.production && !accountfeatureFlagsWarningDisplayed) {
      this.logger.info('Account specific feature flags are only loaded for production builds.');
      accountfeatureFlagsWarningDisplayed = true;
      return true;
    }

    // This guard is only applicable to Helios.
    if (!this.irisCtx.irisContext?.basicClusterInfo?.mcmMode) {
      return true;
    }

    // Since the below is an authenticated endpoint, we have to wait
    // until user is initialized.
    if (!Object.keys(this.irisCtx.irisContext?.user).length) {
      return true;
    }

    this.featureFlag.loadAccountSpecificFlags();

    return this.featureFlag.accountFeature$.pipe(
      filter(data => !!data),
      map(() => true),
    );
  }
}
