import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CreateViewRequest, StorageDomain, Template, View, ViewServiceApi } from '@cohesity/api/v2';
import { SnackBarService } from '@cohesity/helix';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { cloneDeep, get, isEmpty, isNil, isObject, merge, omitBy } from 'lodash';
import { finalize, take } from 'rxjs/operators';
import { ClusterService, CustomizationService } from 'src/app/core/services';

import { DEFAULT_VIEW_CONFIG, DefaultProtocolListByCategory, DefaultProtocolListByCategoryWithNFS4 } from '../../models';
import { ViewsService } from '../../services';

/**
 * View dialog component.
 *
 * @example
 *   <coh-view-dialog></coh-view-dialog>
 */
@Component({
  selector: 'coh-view-dialog',
  templateUrl: './view-dialog.component.html',
})
export class ViewDialogComponent extends AutoDestroyable implements AfterViewChecked, OnInit   {
  /**
   * Indicates whether cluster is running in Helios mode.
   */
  isHelios: boolean;

  /**
   * View details form group for create/edit view flow.
   */
  viewDetailsForm: UntypedFormGroup;

  /**
   * Selected viewbox information.
   */
  selectedViewBox: StorageDomain;

  /**
   * Determines if there are any warnings wrt view name.
   */
  nameWarningKeys: string[] = [];

  /**
   * Specifies whether data is loading or not.
   */
  isLoading = false;

  /**
   * Template name form control.
   */
  templateName = new UntypedFormControl(null, Validators.required);

  /**
   * Specifies if the routing logic should be disabled.
   * Set to true if the dialog is embedded in other pages outside of view.
   */
  isRoutingDisabled = false;

  /**
   * Specifies whether it is view/template creation mode.
   */
  get isViewTemplateMode(): boolean {
    return !!(this.data.isViewTemplate);
  }

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ViewDialogComponent>,
    public fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private irisCtx: IrisContextService,
    public stateService: StateService,
    public viewService: ViewServiceApi,
    public viewsService: ViewsService,
    public translate: TranslateService,
    public snackbar: SnackBarService,
    private ajaxHandler: AjaxHandlerService,
    private clusterService: ClusterService,
    private customizationService: CustomizationService) {
    super();

    this.isHelios = !!this.clusterService.isMcm;

    this.viewDetailsForm = this.fb.group({
      caseInsensitiveNamesEnabled: [null],
      category: ['FileServices', Validators.required],
      enableNfsViewDiscovery: [null],
      enableSmbAccessBasedEnumeration: [null],
      enableSmbLeases: [null],
      enableSmbOplock: [null],
      enableSmbViewDiscovery: [null],
      fileExtensionFilter: [null],
      isExternallyTriggeredBackupTarget: [null],
      name: [null, Validators.required],
      objectServicesMappingConfig: [null],
      overrideGlobalNetgroupWhitelist: [null],
      overrideGlobalSubnetWhitelist: [null],
      protocolAccess: [null, [Validators.required, this.viewsService.protocolAccessValidator]],

      // Default QoS policy name: 'TestAndDevHigh'.
      qos: { name: 'TestAndDevHigh' },
      s3FolderSupportEnabled: [null],
      securityMode: ['NativeMode'],
      selfServiceSnapshotConfig: [null],
      sharePermissions: new UntypedFormControl(null),
      smbPermissionsInfo: new UntypedFormControl(null),
      storageDomainId: [null, Validators.required],
      storageDomainName: [null],
      swiftProjectDomain: [null],
      swiftProjectName: [null],
      swiftUserDomain: [null],
      swiftUsername: [null],
      viewProtectionConfig: [null]
    });

    // Set the values coming from viewParams or template.
    const defaultValue = this.data;
    const viewCategory = get(defaultValue, 'viewParams.category', 'FileServices');
    if (Object.keys(this.data).length > 0) {
      defaultValue.protocolAccess = get(defaultValue, 'viewParams.protocolAccess',
        flagEnabled(this.irisCtx.irisContext, 'viewSettingsSecureByDefault') ?
          DefaultProtocolListByCategoryWithNFS4[viewCategory] :
          DefaultProtocolListByCategory[viewCategory]);
    }
    // Restrict the SD if View from SD is created or get the value from template
    const storageDomainId = data.restrictViewBoxId || get(defaultValue, 'viewParams.storageDomainId') || null;
    const defaultOverrides = {
      category: viewCategory,
      isExternallyTriggeredBackupTarget: defaultValue?.viewParams?.isExternallyTriggeredBackupTarget,
      objectServicesMappingConfig: get(defaultValue, 'viewParams.objectServicesMappingConfig', null),
      protocolAccess: defaultValue.protocolAccess?.length ? defaultValue.protocolAccess :
        flagEnabled(this.irisCtx.irisContext, 'viewSettingsSecureByDefault') ?
          DefaultProtocolListByCategoryWithNFS4[viewCategory] :
          DefaultProtocolListByCategory[viewCategory],

      // Default QoS policy name: 'TestAndDevHigh'
      qos: { name: get(defaultValue, 'viewParams.qos.name', 'TestAndDevHigh') },

      // SD is set to -1 for Standard Templates so need to reset it to null to
      // set the SD to DefaultStorageDomain.
      storageDomainId:  storageDomainId === -1 ? null : storageDomainId,
      viewProtectionConfig: get(defaultValue, 'viewParams.viewProtectionConfig'),
    } as any;

    // If custom template then we want null permissions to overwrite defaults.
    if (defaultValue.defaultTemplateName && !defaultValue.isDefault) {
      defaultOverrides.sharePermissions = get(defaultValue, 'viewParams.sharePermissions');
      defaultOverrides.smbPermissionsInfo = get(defaultValue, 'viewParams.smbPermissionsInfo');
    }

    // Merge the unique values with the DEFAULT_VIEW_CONFIG.
    this.viewDetailsForm.patchValue({...DEFAULT_VIEW_CONFIG, ...defaultOverrides});

    // Remove S3 if this is Helios.
    if (this.isHelios) {
      const protocolList = (this.viewDetailsForm.value.protocolAccess || []).filter(obj => obj.type !== 'S3');
      this.viewDetailsForm.controls.protocolAccess.setValue(protocolList);
    }

    // Update model values based on selected protocols.
    this.viewDetailsForm.controls.protocolAccess.valueChanges.subscribe((newValue) => {
      const selectedProtocols = (newValue || []).map(value => value.type);
      const formControls = this.viewDetailsForm.controls;

      // If SMB is included, then file names must be case insensitive.
      formControls.caseInsensitiveNamesEnabled.setValue(selectedProtocols.includes('SMB'));

      // If SMB is included, then populate default permissions.
      if (selectedProtocols.includes('SMB')) {
        formControls.smbPermissionsInfo.setValue(DEFAULT_VIEW_CONFIG.smbPermissionsInfo);
        formControls.sharePermissions.setValue(DEFAULT_VIEW_CONFIG.sharePermissions);
      }

      // Validate View name because each protocol has different requirements.
      this.validateViewName(this.viewDetailsForm.value.name, newValue);
    });

    // Disable category field on selecting an existing template for view creation.
    if (this.data.id) {
      this.viewDetailsForm.controls.category.disable();
    }

    this.viewDetailsForm.controls.name.valueChanges.subscribe((newValue) => {
      this.validateViewName(newValue);
    });

    // Have to remove the validations (required) from the view 'name' field when
    // in Template creation mode.
    if (this.isViewTemplateMode) {
      this.viewDetailsForm.controls.name.clearValidators();
      this.viewDetailsForm.controls.name.updateValueAndValidity();
    }
  }

  /**
   * Init Component.
   */
  ngOnInit() {
    this.isRoutingDisabled = this.data.disableRouting || false;

    if (!this.data.id) {
      // Setting default storage domain value.
      this.viewsService.getDefaultStorageDomain(this.data.restrictViewBoxId).pipe(
        this.untilDestroy())
        .subscribe(storageDomain => {
          this.viewDetailsForm.controls.storageDomainId.setValue(storageDomain.id);
          this.viewDetailsForm.controls.storageDomainName.setValue(storageDomain.name);
        }
      );
    }

    // Disable storage domain selection if restrict viewbox is present.
    if (this.data.restrictViewBoxId) {
      this.viewDetailsForm.controls.storageDomainId.disable();
    }

    // If simple workflow for view create is disabled, then always immediately
    // open the full view form as this dialog is opening.
    this.customizationService.disabledWorkflows$.pipe(
      this.untilDestroy(),
      take(1),
    ).subscribe(value => {
      if (value.includes('simpleView')) {
        this.modifyViewSettings();
      }
    });
  }

  /**
   * After view is checked for changes. This is needed because of implicit
   * changes made by child components.
   */
  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Method called to validate view name.
   */
  validateViewName(name: string, protocolList = this.viewDetailsForm.value.protocolAccess) {
    this.nameWarningKeys = this.viewsService.getViewNameWarnings(name, protocolList);
  }

  /**
   * Method called to visualise and update view settings.
   */
  modifyViewSettings() {
    if (this.isViewTemplateMode) {
      this.modifyViewTemplateSettings();
      return;
    }

    // Preventing validation trigger for view name field on clicking more options.
    if (!this.viewDetailsForm.controls.name.value) {
      this.viewDetailsForm.controls.name.markAsUntouched();
    }

    let stateParams = {
      actionType: 'create',
      viewObj: cloneDeep(this.viewDetailsForm.getRawValue()),
      togglePageView: true,
    };

    if (this.data) {
      if (this.data.id) {
        stateParams = Object.assign({templateInfo: this.data}, stateParams);
      }

      if (this.data.restrictViewBoxId) {
        stateParams = Object.assign({restrictViewBoxId: this.data.restrictViewBoxId}, stateParams);
      }
    }

    if (this.isRoutingDisabled) {
      stateParams = Object.assign({backToPreviousState: true}, stateParams);
    }

    // Redirecting user to page take-over theme where user can modify
    // view settings.
    this.stateService.go('ng-views.modify', stateParams);
  }

  /**
   * Returns true if the given property is invalid
   *
   * TODO: This is a temporary fix, should be fixed when the component is
   *       refactored.
   *
   * @param val Any value of property.
   * @param key String key name of property.
   * @returns True if property value is invalid.
   */
  omitEmptyProperties(val: any, key: string) {
    // The view params from the template should override other values which
    // are not present in the form dialog.
    const nonOverridableKeys = [
      'objectServicesMappingConfig',
      'protocolAccess',
      'sharePermissions',
      'smbPermissionsInfo',
      'storageDomainId',
    ];

    if (nonOverridableKeys.includes(key)) {
      return true;
    }

    return isNil(val) || (isObject(val) && isEmpty(val));
  }

  /**
   * Method called to emit create view event.
   */
  onCreate() {
    if (!this.viewDetailsForm.valid) {
      return;
    }

    // Strip out null values and empty objects/arrays from incoming data.
    const scrubbedViewParams = this.data.viewParams && JSON.parse(
      JSON.stringify(this.data.viewParams),
      (key, value) => {
        if (isEmpty(value)) {
          return undefined;
        }
        return value;
      }
    );

    // Merge incoming data with the form defaults.
    const data = merge(
      this.viewDetailsForm.getRawValue(),
      omitBy(scrubbedViewParams, this.omitEmptyProperties)
    );
    const payload: CreateViewRequest = this.viewsService.transformViewsPayload(data);

    if (this.isViewTemplateMode) {
      this.onCreateTemplate(payload);
      return;
    }

    // Add Intent when intent based views are enabled.
    if (this.data.id) {
      payload.intent = {
        templateId: this.data.id,
        templateName: this.data.name,
      };
    }

    this.isLoading = true;
    this.viewService.CreateView({ body: payload }).pipe(
      this.untilDestroy(), finalize(() => this.isLoading = false)
    ).subscribe(response => {

      // Close the dialog with the response.
      if (this.isRoutingDisabled) {
        this.dialogRef.close(response);
        return;
      }

      this.onCancel();

      if (this.data.restrictViewBoxId) {
        const config = {
          messageText: this.translate.instant('views.updateViewSuccess', { name: response.name }),
          actionText: this.translate.instant('allViews'),
        };

        this.snackbar.openWithAction(config.messageText, config.actionText).subscribe(
          () => {
            this.stateService.go('ng-views.views');
          }
        );
        return;
      }
      this.snackbar.open(this.translate.instant('views.createViewSuccess',
        { name: response.name }));
    }, this.ajaxHandler.handler);
  }

  /**
   * Method called to visualise and update view template settings.
   */
  modifyViewTemplateSettings() {
    // Preventing validation trigger for view name field on clicking more options.
    if (!this.viewDetailsForm.controls.name.value) {
      this.viewDetailsForm.controls.name.markAsUntouched();
    }

    // Redirecting user to page take-over theme where user can modify
    // view settings.
    this.stateService.go('ng-templates.modify', {
      actionType: 'create',
      templateObj: {
        name: this.templateName.value,
        viewParams: this.viewDetailsForm.value,
      },
      togglePageView: true,
    });
  }

  /**
   * Method called to emit create view event.
   */
  onCreateTemplate(viewParams: View) {
    // Triggering validation for formcontrol on submit.
    if (!this.templateName.value) {
      this.templateName.markAsTouched();
      return;
    }

    const payload: Template = {
      name: this.templateName.value,
      isDefault: false,
      viewParams: viewParams
    };

    this.isLoading = true;
    this.viewService.CreateViewTemplate({ body: payload }).pipe(
      this.untilDestroy(), finalize(() => this.isLoading = false)
    ).subscribe(() => {
      this.snackbar.open(this.translate.instant('views.createViewSuccess', { name: payload.name }));
      this.dialogRef.close(true);
    }, this.ajaxHandler.handler);
  }

  /**
   * Redirect user to all views page.
   */
  onCancel() {
    this.dialogRef.close();

    // Skip the routing logic for embedded dialog.
    if (this.isRoutingDisabled) {
      return;
    }

    // Redirect user to storage domains page on cretion of view from storage domain.
    if (this.data.restrictViewBoxId) {
      return this.stateService.go('cluster.viewboxes', {}, { reload: true });
    }

    this.stateService.go('ng-views.views.all-views',
      { showTemplates: (this.isViewTemplateMode || !!this.data.id) },
      { reload: true }
    );
  }

  /**
   * Method called on updating storage domain selection.
   *
   * @param value Updated storage domain value.
   */
  onViewBoxChange(value: StorageDomain) {
    if (value) {
      this.selectedViewBox = value;
      this.viewDetailsForm.controls.storageDomainId.setValue(value.id);
      this.viewDetailsForm.controls.storageDomainName.setValue(value.name);
    }
  }
}
