import { Injectable } from '@angular/core';
import { McmProtectionSourcesServiceApi } from '@cohesity/api/private';
import { SearchServiceApi } from '@cohesity/api/v2';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterFieldOption, IFieldFilterService } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class SourceFilterService implements IFieldFilterService {

  constructor(
    private mcmClustersApi: McmProtectionSourcesServiceApi,
  ) { }

  /**
   * Sources filter value generator. This queries all the registered sources and generates a list of sources that can be
   * shown in the UI for user selection.
   *
   * * @returns observable with filter options
   */
  getFilterOptions(): Observable<FilterFieldOption[]> {
    return this.mcmClustersApi.getRegisteredSources().pipe(
      map((sources) => sources.map(source => ({
        key: source.uuid,
        labelKey: source.name,
      }))),
      map((sources) => {
        sources.sort((a, b) => a.labelKey.localeCompare(b.labelKey));
        return sources;
      }),
    );
  }

  /**
   * consolidated source filters into a format that API understands
   *
   * @param selectedValues currently applied source filters
   * @returns source filter that API understands
   */
  transformFilterValues(selectedValues: string[]): SearchServiceApi.SearchObjectsParams {
    return {
      sourceUuids: selectedValues.length ? [selectedValues.join()] : [],
    };
  }
}
