import { Component, Input } from '@angular/core';

import { IconSize } from '../icon/icon.component';

/**
 * The default settings for each StatusIndicatorType.
 */
export const defaults = {
  none: { icon: '' },
  success: { icon: 'check_circle!success' },
  error: { icon: 'error!critical' },
  warning: { icon: 'warning!warning' },
  cancel: { icon: 'cancel!critical' },
  pending: { icon: '' },
};

/**
 * Enumerates the possible types of status indicator.
 */
export type StatusIndicatorType = 'none' | 'success' | 'error' | 'warning' | 'pending';

/**
 * @description
 * Shows a status indicator, and optionally, a label.
 *
 * @example
 *   <cog-status-indicator type="error"></cog-status-indicator>
 *   <cog-status-indicator type="error" icon="cancel"></cog-status-indicator>
 *   <cog-status-indicator type="error" size="sm"></cog-status-indicator>
 *   <cog-status-indicator type="pending" label="Downloading"></cog-status-indicator>
 */
@Component({
  selector: 'cog-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss']
})
export class StatusIndicatorComponent {

  /**
   * The name of the icon. This will be passed to the `shape` property of
   * `cog-icon`. Use this to override the default icon.
   */
  @Input() icon: string;

  /**
   * A label besides the status indicator. This binding is optional.
   */
  @Input() label: string;

  /**
   * The t-shirt size of the icon which will be passed to the cog-icon size
   * attribute. Default is 'md'.
   */
  @Input() size: IconSize = 'md';

  /**
   * The type of the status indicator. From this value is derived the default
   * icon and the color for the icon. Default type is `none`.
   */
  @Input() type: StatusIndicatorType;

  /**
   * Convenience method to return the status type.
   */
  get statusType(): StatusIndicatorType {
    return this.type || 'none';
  }

  /**
   * Convenience method to return the name of the icon.
   */
  get statusIcon(): string {
    return this.icon || defaults[this.statusType]?.icon;
  }

  /**
   * Indicates whether the component should render an icon.
   */
  showIcon(): boolean {
    return this.type !== 'pending' && Boolean(this.statusIcon);
  }

  /**
   * Indicates whether the component should render a spinner.
   */
  showSpinner(): boolean {
    return this.type === 'pending';
  }

}
