import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const dataSitesAccess = (ctx: StateAccessContext) =>
  ctx.clusterInfo?._serviceType === 'draas' || ctx.FEATURE_FLAGS.keplerEnabled;

const dataSiteDetailsAccess = (ctx: StateAccessContext) =>
  ctx.clusterInfo?._serviceType === 'draas' ||
  (ctx.FEATURE_FLAGS.keplerEnabled && ctx.FEATURE_FLAGS.keplerDashboardEnabled);

export const DataSitesConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'data-sites.**',
      url: '/data-sites',
      loadChildren: () => import('./data-sites.module').then(m => m.DataSitesModule),
    },
  ],
  allClusterMap: {
    'data-sites': true,
    'data-sites.list': true,
    'data-sites.details': true,
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'data-sites': dataSitesAccess,
      'data-sites.list': dataSitesAccess,
      'data-sites.details': dataSiteDetailsAccess,
    };
  },
};
