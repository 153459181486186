import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { RegionClusterScanResult, ScanClusterRequest, SecurityAdvisorServiceApi } from '@cohesity/api/secops';
import { SnackBarService } from '@cohesity/helix';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';

import { PostureAdvisorService } from '../../services/posture-advisor.service';

/**
 * Interface to pass as input to perform scan dialog.
 */
export interface PerformScanDialogInputParams {

/**
 * Scores data to be displayed in table.
 */
  scoresData: RegionClusterScanResult[];
}

/**
 * Perform scan dialog component.
 */
@Component({
  selector: 'dg-pa-perform-scan',
  templateUrl: './perform-scan-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerformScanDialogComponent extends AutoDestroyable implements AfterViewInit {
  /**
   * Stores the selected rows.
   */
  selection: SelectionModel<RegionClusterScanResult>;

  /**
   * True if save of perform scan request is in progress.
   */
  saving = false;

  /**
   * Scores data to be displayed in table if not passed to as dialogParams.
   */
  scoresData: RegionClusterScanResult[];
  /**
   * True if data fetching is in progress.
   */
  loading = false;
  /**
   * Determines if all of the selectable rows are selected or not.
   *
   * @return  True if all rows are selected
   */
  isAllSelected = () => true;

  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private cdr: ChangeDetectorRef,
    private dialogRef: MatDialogRef<PerformScanDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogParams: PerformScanDialogInputParams,
    private postureAdvisorService: PostureAdvisorService,
    private securityAdvisorServiceApi: SecurityAdvisorServiceApi,
    private snackBarService: SnackBarService,
    private translateService: TranslateService) {
    super();
  }

  /**
   * Submit request to perform scan on selected regions/clusters.
   */
  submitPerformScanRequest() {
    if (!this.selection.selected.length) {
      return;
    }

    const performScanRequest: ScanClusterRequest = {};
    this.saving = true;

    // Add cluster results to flat array.
    performScanRequest.clusterIdList = [].concat(...this.selection.selected.map(res => res.clusterIds));
    this.securityAdvisorServiceApi
      .PerformScanClusters(performScanRequest)
      .pipe(
        this.untilDestroy(),
        finalize(() => (this.saving = false))
      )
      .subscribe(response => {
        this.dialogRef.close(response);
        this.snackBarService.open(this.translateService.instant('performScanRequestSubmitted'));
      }, this.ajaxHandlerService.handler);
  }

  ngAfterViewInit(): void {
    if (!this.dialogParams?.scoresData && !this.scoresData) {
      this.loading = true;
      this.postureAdvisorService.getScanResultsByRegion((results) => {
        this.loading = false;
        this.scoresData = results;
        this.selection = new SelectionModel<RegionClusterScanResult>(true, this.scoresData);
      });
    } else {
      this.selection = new SelectionModel<RegionClusterScanResult>(true, this.dialogParams?.scoresData);
      this.cdr.detectChanges();
    }
  }
}
