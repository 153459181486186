/* tslint:disable */

/**
 * Specifies the range.
 */
export enum Range {
  low = 'low',
  medium = 'medium',
  high = 'high'
}
