import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpParameterCodec,
  HttpParams,
  HttpRequest
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { convertRequestParamsToObject } from '@cohesity/utils';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

/**
 * This Parameter Codec is just needed so as to not encode the already encoded
 * Http Params when populating the Http Params.
 */
class SimpleParameterCodec implements HttpParameterCodec {
  encodeKey(key: string): string {
    return key;
  }

  encodeValue(value: string): string {
    return value;
  }

  decodeKey(key: string): string {
    return key;
  }

  decodeValue(value: string): string {
    return value;
  }
}
const SIMPLE_PARAMETER_CODEC = new SimpleParameterCodec();

/**
 * This interceptor applies legacy angular js http interceptors to HttpClient
 * calls.
 */
@Injectable()
export class AjsHttpInterceptor implements HttpInterceptor {

  // These are the services names/injection tokens for the interceptors we are
  // interested in.
  private interceptorNames = [
    'authHttpResponseInterceptor',
    'outputFormatInterceptor',
    'Http202Interceptor',
    'Http404Interceptor',
    'RemoteClusterHttpRequestInterceptor',
    'AddTenantImpersonationHeader',
    'localeInterceptor',
    'salesforceAccountInterceptor',
  ];

  // This will contain all of the actual interceptors
  private interceptors: any[] = [];

  /**
   * Class constructor
   *
   * @param   injector   Main Angular injector. This gets instantiated along early
   *                     In the bootstrapping phase, so we'll use it to initialize the
   *                     interceptors later on.
   */
  constructor(private injector: Injector) {
  }

  /**
   * Intercepts http requests and applies the ajs interceptors to them.
   *
   * @param   request   The http request. Request interceptors modify this.
   * @param   next      The http handler. Response interceptors listen for
   *                    events on this.
   * @return   An observable of HttpEvent based on next.handle()
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Convert the request to a standard object that is easy to manipulate
    const simpleRequest = this.convertRequest(request);

    // This interceptor gets instantiated at the same time as the module, which
    // happens before angular js has finished bootstrapping. This means that the
    // AjsUpgradeService cannot be injected into the constructor. As a workaround,
    // we'll lazily instantiate the injector after the app has finished bootstrapping.
    // However, the feature flags call gets made very early in the bootstrap process.
    // in angular < 8, attempting to get $injector before it was bootstrapped would
    // thrown an exception. In ivy, it fails silently and then throws a cyclic
    // hierarchy error much later in the boostrapping process.
    // Just load all of the interceptors the first time the intercept call is made.
    if (!this.interceptors.length && !['featureFlags'].includes(request.url)) {
      const upgrade = this.injector.get<{[key: string]: Function}>('$injector' as any);
      this.interceptors = this.interceptorNames.map(name => upgrade.get(name));
    }

    // If the interceptor list is empty, this won't do anything.
    this.interceptors.forEach(interceptor => {
      // Only apply the interceptors that define a request method.
      // Each interceptor may
      if (interceptor.request) {
        interceptor.request(simpleRequest);
      }
    });

    // After bringing up an AJS modal, sometimes it will set clusterId to undefined
    // (in remote-cluster-service.js). This will cause error in http.js so removing it
    // to avoid the error.
    if (!simpleRequest.headers.clusterId || !simpleRequest.headers.clusterId.length) {
      delete simpleRequest.headers.clusterId;
    }

    // This is to address the request.clone duplicate param issue.
    // Refer to https://github.com/angular/angular/issues/18812#issuecomment-336301386.
    // TODO: remove this when some related issue/bug is fixed in angular
    //
    // The SIMPLE_PARAMETER_CODEC is used here to not encode the already
    // encoded Http Params.
    let newParams = new HttpParams({encoder: SIMPLE_PARAMETER_CODEC});
    if (simpleRequest.params) {
      Object.keys(simpleRequest.params).forEach(function (key) {
        newParams = newParams.append(key, simpleRequest.params[key]);
      });
    }

    // Once the request headers and params have been updated, convert it back to an HttpRequest.
    // NOTE: headers value should be string or string[] else they will be skipped by HttpHeaders internally.
    request = request.clone({
      headers: new HttpHeaders(simpleRequest.headers),
      params: newParams
    });

    // Use next.handle to pass on the request and then listen for responses.
    // The tap operator gives an opportunity to handle events without modifying
    // or triggering a subscription on the observable. The response interceptors
    // are applied here.
    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      // The only response interceptors we currently use are for handling errors
      if (event instanceof HttpErrorResponse) {
        this.interceptors.forEach(interceptor => {
          if (interceptor.responseError) {
            interceptor.responseError(event);
          }
        });
      }
    }, (error: HttpErrorResponse) => {
      // Handle the error after the request returns from the request.
      this.interceptors.forEach(interceptor => {
        if (interceptor.responseError) {
          interceptor.responseError(error);
        }
      });
    }));
  }

  /**
   * Converts an HttpRequest to a simple object, retaining only the url, params and
   * headers. This allows it to be used and modified by the legacy http request
   * interceptors.
   *
   * @param     request   The immutable HttpRequest object.
   * @returns   a request object with url, params, and headers.
   */
  convertRequest(request: HttpRequest<any>): any {
    const simpleRequest = {
      params: {},
      headers: {},
      url: request.url,
    };

    if (request.params) {
      // The toString() method within HttpRequest returns the encoded URL
      // params which internally uses encodeURIComponent(...).
      // eg: For HTTP params key1=val1&key1=val2&key2=/val3+
      // the toString() returns key1=val1&key1=val2&key2=%2Fval3%2B
      simpleRequest.params = convertRequestParamsToObject(request.params.toString());
    }

    if (request.headers) {
      request.headers.keys().forEach(key => {
        simpleRequest.headers[key] = request.headers.getAll(key);
      });
    }
    return simpleRequest;
  }
}
