import { Pipe, PipeTransform } from '@angular/core';
import { isFinite } from 'lodash';
import moment from 'moment';

import { HelixIntlService } from '../../helix-intl.service';

/**
 * @ngdoc Filter
 * @description
 *   Wrapper for moment.js to render a string that indicates how long ago a
 *   usec value occurred from the current cluster time.
 *
 *
 * @example
 *   {{ startTimeUsecs | humanizeFromUsecs }} => '7 days ago'
 */
@Pipe({
  name: 'humanizeFromUsecs',
  standalone: true
})
export class HumanizeFromUsecsPipe implements PipeTransform {
  constructor(public intl: HelixIntlService) { }

  /**
   * Wrapper for moment.js to render a string that indicates how long ago a
   * usecs value occurred from the current cluster time.
   *
   * @param   usecs   The microseconds value to transform
   * @return  The string description of how long its been since provided usecs
   */
  transform(usecs: number): string {
    if (!usecs || !isFinite(usecs)) {
      return this.intl.naNotAvailable;
    }

    // TODO(jeff): replace with Typescript friendly version of Date.clusterNow()
    // when available.
    return moment(usecs / 1000).from(Date.now());
  }

}
