import { DataRenderer, TextDataRendererComponent } from '@cohesity/helix';

/**
 * Renders an environmnt value running prepending it with a prefix and calling translate on it.
 */
export const environmentTextRenderer = {
  transform: (renderValues: DataRenderer<string, undefined, undefined>) => ({
    ...renderValues,
    value: renderValues.translate(`enum.environment.${renderValues.value}`),
  }),
  component: TextDataRendererComponent,
};
