<h1 mat-dialog-title translate>downloadCliModal.header</h1>
<mat-list>
  <mat-list-item *ngFor="let download of downloadList">
    <p matLine translate>{{ download.name }}</p>
    <a mat-icon-button href="{{ download.url }}" class="pull-right" target="_self">
      <mat-icon matListIcon>get_app</mat-icon>
    </a>
  </mat-list-item>
</mat-list>
<div mat-dialog-actions align="end">
  <button mat-flat-button color="primary" mat-dialog-close cdkFocusInitial>{{ 'close' | translate }}</button>
</div>
