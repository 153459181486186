// Directive: cClearable

;(function (angular, undefined) {
  'use strict';

  angular
    .module('C.clearable', [])
    .directive('cClearable', cClearableDirectiveFn);

  /**
   * @ngdoc directive
   * @name clearable:cClearable
   *
   * @restrict 'A'
   *
   * @description
   * This directive wraps input element and shows clear icon.
   * Clear icon is used to empty the value of the element on click event.
   * Directive will now show clear icon if input is disabled.
   * Please note that its currently only tested for input elements and may
   * not work well with c-copy
   *
   * @example
   *  <input type="text" ng-model="searchQuery" c-clearable/>
   **/
  function cClearableDirectiveFn($compile) {
    return {
      require: 'ngModel',
      link: cClearableLinkFn,
      restrict: 'A',
    };

    function cClearableLinkFn(scope, ele, attrs, ngModelCtrl) {

      // wrap element
      var clearIcon = $compile(
        ['<i class="icn-cancel" ',
          'uib-tooltip="{{::\'clear\' | translate}}"></i>'].join(''))(scope);

      ele.wrap('<div class="clearable-wrapper"></div>');
      ele.after(clearIcon);

      // clear value of the input on clear icon click
      clearIcon.on('click', function clearInput() {
        ngModelCtrl.$setViewValue(undefined);
        // Render is required as setViewValue won't trigger it
        ngModelCtrl.$render();
      });

      // Initial state
      reviseClearIconVisibility();

      // Add listeners
      ngModelCtrl.$viewChangeListeners.push(reviseClearIconVisibility);
      attrs.$observe('disabled', reviseClearIconVisibility);

      /**
       * Show hide close icon based on input's value
       *
       * @method   reviseClearIconVisibility
       * @param    {boolean}   disabled   Optional, Whether input is disabled
       *                                  Default: false
       */
      function reviseClearIconVisibility(disabled) {
        disabled = disabled || false;
        var hideClass = 'ng-hide';

        if (ngModelCtrl.$isEmpty(ngModelCtrl.$viewValue) || disabled) {
          clearIcon.addClass(hideClass);
        } else {
          clearIcon.removeClass(hideClass);
        }
      }
    }
  }
})(angular);
