import { InjectionToken } from '@angular/core';
import { Environment } from '@cohesity/iris-shared-constants';

import { SourceTreeDataProvider } from './source-tree-data-provider';
import { SourceTreeService } from './source-tree.service';

export const SOURCE_TREE_SERVICE_FACTORY = new InjectionToken<SourceTreeServiceFactory>('sourceTreeServiceFactory');

/**
 * Source tree factory - given an environment, this will return the correct tree service.
 */
export interface SourceTreeServiceFactory {
  /**
   * Gets the source tree service for the given environment.
   *
   * @param   environment  The current environment.
   * @return  The correct source tree service.
   */
  getSourceTreeService(environment: Environment): SourceTreeService<any, any>;

  /**
   * Gets the source tree data provider for the given environment.
   *
   * @param   environment  The current environment.
   * @return  The correct source tree service.
   */
  getSourceTreeDataProvider(environment: Environment): SourceTreeDataProvider<any>;
}
