/**
 * Specifies the status of registration(authentication of credentials & the
 * discovery of entities within the source) while registering the source within
 * Cohesity Cluster.
 */
export enum RegistrationStatusType {
  /**
   * Specifies that the authentication & discovery of entities are pending.
   */
  kPending = 'kPending',

  /**
   * Specifies that the authentication & discovery of entities is scheduled.
   */
  kScheduled = 'kScheduled',

  /**
   * Specifies that the authentication & discovery of entities is complete.
   */
  kFinished = 'kFinished',

  /**
   * Specifies that the refresh of the entity hierarchy is in progress.
   */
  kRefreshInProgress = 'kRefreshInProgress',
}
