import { ChangeDetectionStrategy, Component, Input, Type } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { LegacyErrorStateMatcher as ErrorStateMatcher } from '@angular/material/legacy-core';
import { Controls, FormGroupOptions, NgxSubFormComponent, subformComponentProviders } from 'ngx-sub-form';

/**
 * Custom ErrorStateMatcher which returns true when the parent is invalid and has been touched
 */
class PasswordErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null): boolean {
    return control.parent.invalid && control.touched;
  }
}

/**
 * Form interface used in change password form component.
 */
export interface ChangePasswordForm {
  /**
   * Current password.
   */
  currentPassword: string;

  /**
   * New password.
   */
  newPassword: string;

  /**
   * Field to confirm the new password that is already entered.
   */
  confirmPassword: string;
}

/**
 * Component for changing password which includes two inputs for changing password.
 * This component does validation for minimum length of password and equality check
 * on new password and confirm password inputs.
 *
 * @example
 * <coh-change-password-form formControlName="changePassword">
 * </coh-change-password-form>
 */
@Component({
  selector: 'coh-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss'],
  providers: subformComponentProviders(ChangePasswordFormComponent),
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangePasswordFormComponent extends NgxSubFormComponent<ChangePasswordForm> {
  /**
   * Error state matches for confirm password.
   */
  confirmPasswordMatcher = new PasswordErrorStateMatcher();

  /**
   * Indicates of user should be required to input current password.
   */
  @Input() confirmCurrentPassword = false;

  /**
   * Minimum password length to validate against. Default is set to 8 characters in validator.
   */
  @Input() set minPasswordLength(minLength: number) {
    this._minPasswordLength = minLength;
    this.formGroupControls.newPassword.setValidators([
      Validators.required,
      Validators.minLength(minLength)]);
  }

  /**
   * Getter for minimum password length.
   */
  get minPasswordLength(): number {
    return this._minPasswordLength;
  }

  /**
   * Popover component for password rules.
   */
  @Input() passwordRulesPopover: Type<any>;

  /**
   * Popover data context.
   */
  @Input() popoverData: any;

  /**
   * Private variable to store minimum password length.
   */
  private _minPasswordLength: number;

  /**
   * Function that returns form controls of this component which is required to be
   * implemented by NgxSubFormComponent class.
   *
   * @return Controls of type ChangePasswordForm.
   */
  getFormControls(): Controls<ChangePasswordForm> {
    return {
      currentPassword: new UntypedFormControl(null, this.confirmCurrentPassword ? Validators.required : null),
      newPassword: new UntypedFormControl(null, [Validators.required, Validators.minLength(8)]),
      confirmPassword: new UntypedFormControl(null, Validators.required),
    };
  }

  /**
   * Function to add custom validation to return error if new password and confirm password inputs don't match.
   *
   * @return FormGroupOptions<ChangePasswordForm> type which includes custom validator.
   */
  public getFormGroupControlOptions(): FormGroupOptions<ChangePasswordForm> {
    return {
      validators: [
        formGroup => {
          if (formGroup.value.newPassword !== formGroup.value.confirmPassword) {
            return { passwordsMustMatch: true };
          }
          return null;
        },
      ],
    };
  }
}
