import { Component, forwardRef, inject, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AutoDestroyable } from '@cohesity/utils';

import { FormBuilderComponent } from './form-builder.component';
import {
  FormSectionEditCtaDirective,
  FormSectionEditModeDirective,
  FormSectionNavigatorDirective,
  FormSectionViewCtaDirective,
  FormSectionViewModeDirective,
} from './form-builder.directives';
import { FormSectionMode } from './form-builder.model';

@Component({
  template: '',
  providers: [
    {
      provide: FormSectionComponent,
      useExisting: forwardRef(() => FormSectionComponent<any, any, any>),
    },
  ],
})
export class FormSectionComponent<FormValue, FormModel, DataModel> extends AutoDestroyable {
  formSectionName: keyof FormModel;

  mode: FormSectionMode = 'edit';

  formControl = new FormControl<FormValue>(null);

  builder = inject(FormBuilderComponent<FormModel, DataModel>);

  @ViewChild(forwardRef(() => FormSectionEditModeDirective)) formSectionEditMode?: FormSectionEditModeDirective;

  @ViewChild(forwardRef(() => FormSectionEditCtaDirective)) formSectionEditCta?: FormSectionEditCtaDirective;

  @ViewChild(forwardRef(() => FormSectionViewModeDirective)) formSectionViewMode?: FormSectionViewModeDirective;

  @ViewChild(forwardRef(() => FormSectionViewCtaDirective)) formSectionViewCta?: FormSectionViewCtaDirective;

  @ViewChild(forwardRef(() => FormSectionNavigatorDirective)) formSectionNavigator?: FormSectionNavigatorDirective;

  constructor() {
    super();
  }

  initFormSection() {}

  addFormSectionControl() {
    this.builder.formGroup.addControl(
      this.formSectionName as any,
      this.formControl as any
    );
  }

  toDataModel(): Partial<DataModel> {
    return null;
  }

  fromDataModel(_dataModel: DataModel): FormValue {
    return null;
  }

  next(newValue: any) {
    if (this.formSectionViewMode) {
      this.mode = 'view';
    }
    this.formControl.patchValue(newValue);
  }
}
