import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { UISref } from '@uirouter/angular';
import { AutoDestroyable } from '@cohesity/utils';
import { StateManagementService } from 'src/app/core/services/state-management.service';

/**
 * decorate ui-router's uiSref directive to disable links for which user is lacking required privilege.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[uiSref]',
  exportAs: 'uiSref',
})
export class CanAccessUiSrefDirective extends AutoDestroyable implements OnInit {
  /**
   * Indicate whether to skip addition of no access class or not if user is lacking the required privilege and
   * used when custom disabling reason tooltip is shown for the disabled links.
   */
  @Input() skipNoAccessClass = false;

  constructor(
    private elementRef: ElementRef,
    private originalUISref: UISref,
    private renderer: Renderer2,
    private stateManagementService: StateManagementService
  ) {
    super();
  }

  ngOnInit() {
    // check target state access when changed.
    this.originalUISref.targetState$.pipe(this.untilDestroy()).subscribe(targetState => {
      // getting the raw target state name which is provided as an @Input to the uiSref directive to perform access test
      // because targetState$ will contain the future state name for any navigation to its children state eg.
      // for uiSref="protection-group.groups" targetState$ will contain 'protection-group.**'
      const toStateName = targetState && this.originalUISref.state;

      if (toStateName) {
        const canAccess = this.stateManagementService.canUserAccessState(toStateName, targetState.params(), true);

        // toggle 'no-access' class based on state access and skip adding 'no-access' class if
        // skipNoAccessClass is true.
        this.renderer.removeClass(this.elementRef.nativeElement, 'no-access');
        if (!canAccess) {
          // clearing uiSref's internal target state to prevent navigation.
          this.originalUISref.state = '';

          // removing href if target state is not accessible.
          if (this.elementRef.nativeElement.hasAttribute('href')) {
            this.renderer.removeAttribute(this.elementRef.nativeElement, 'href');
          }

          // skip no-access class if @Input(skipNoAccessClass) is true.
          if (!this.skipNoAccessClass) {
            this.renderer.addClass(this.elementRef.nativeElement, 'no-access');
          }
        }
      }
    });
  }
}
