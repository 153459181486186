<div>
  <h3>
    {{ 'anomaly.chartHeader' | translate : {
        numProtectionRuns: seriesData.dataPointVec?.length,
        metricName: (metricTypeLabel | metricType) || ''
      }
    }}
  </h3>
  <div class="chart-container">
    <cog-line-chart
      #lineChart
      *ngIf="seriesData"
      [chartOptions]="chartOptions"
      [seriesData]="lineChartSeriesData"
      (pointSelect)="pointSelected($event)"
      colorSetClass="anomaly-chart-theme"
      [height]="300">
    </cog-line-chart>
  </div>
</div>
