import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FieldType, TypeConfig, BaseFormFieldDirective } from '../../models/index';

/**
 * Render a form field based on a field type specified. Depending on the type,
 * it can render string, number, boolean, password, radioGroup types of fields.
 * The behaviour of these fields can be controlled via the config input
 * property.
 *
 * @example
 * <cog-generic-form-field [label]="label" ...></cog-generic-form-field>
 */
@Component({
  selector: 'cog-generic-form-field',
  templateUrl: './generic-form-field.component.html',
  styleUrls: ['./generic-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericFormFieldComponent extends BaseFormFieldDirective<TypeConfig> {
  /**
   * Field type. eg: 'string','boolean','number'
   */
  @Input() type: FieldType;
}
