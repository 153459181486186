import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

/**
 * The general access to the DR services.
 */
const disasterRecoveryAccess = (ctx: StateAccessContext) =>
  ctx.clusterInfo?._serviceType === 'draas' || ctx.FEATURE_FLAGS.keplerEnabled;

/**
 * Access for the DR onboarding wizard.
 */
const drOnboardingAccess = (ctx: StateAccessContext) =>
  (ctx.clusterInfo?._serviceType === 'draas' || ctx.FEATURE_FLAGS.keplerEnabled) &&
  ctx.FEATURE_FLAGS.keplerOnboardingWizardEnabled;

export const DisasterRecoveryConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'disaster-recovery.**',
      url: '/disaster-recovery',
      loadChildren: () => import('./disaster-recovery.module').then(m => m.DisasterRecoveryModule),
    },
  ],
  allClusterMap: {
    'disaster-recovery': true,
    'disaster-recovery.activities': true,
    'disaster-recovery.activity-details': true,
    'disaster-recovery.onboarding': true,
    'disaster-recovery.build-plan': true,
    'disaster-recovery.list': true,
    'disaster-recovery.execution-details': true,
    'disaster-recovery.execution-entity-details': true,
    'disaster-recovery.list.plans': true,
    'disaster-recovery.plan-details': true,
    'disaster-recovery.readiness-details': true,
    'disaster-recovery.prepare-for-failback': true,
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'disaster-recovery': disasterRecoveryAccess,
      'disaster-recovery.activities': disasterRecoveryAccess,
      'disaster-recovery.activity-details': disasterRecoveryAccess,
      'disaster-recovery.onboarding': drOnboardingAccess,
      'disaster-recovery.build-plan': disasterRecoveryAccess,
      'disaster-recovery.execution-details': disasterRecoveryAccess,
      'disaster-recovery.execution-entity-details': disasterRecoveryAccess,
      'disaster-recovery.list': disasterRecoveryAccess,
      'disaster-recovery.list.plans': disasterRecoveryAccess,
      'disaster-recovery.plan-details': disasterRecoveryAccess,
      'disaster-recovery.readiness-details': disasterRecoveryAccess,
      'disaster-recovery.report-details': disasterRecoveryAccess,
      'disaster-recovery.prepare-for-failback': disasterRecoveryAccess,
    };
  },
};
