// Component: policyTargets

;(function(angular, undefined) {
  'use strict';

  /**
   * @ngdoc component
   * @name C.policies:policyTargets
   * @function
   *
   * @description
   * Provides a policy targets summary.
   *
   * @example
      <policy-targets policy="policy"></policy-targets>
   */
  angular.module('C.policies')
    .component('policyTargets', {
      bindings: {
        // policy for which target summary needed.
        policy: '<',
      },
      templateUrl: 'app/protection/policies/policy-targets/policy-targets.html',
  });

})(angular);
