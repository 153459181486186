import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MFAServiceApi } from '@cohesity/api/v2';
import { SnackBarService } from '@cohesity/helix';
import { AjaxHandlerService } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { Controls, NgxSubFormComponent, subformComponentProviders, takeUntilDestroyed } from 'ngx-sub-form';

import { MfaService } from '../../../services';
import { MfaEmailToken, MFAType, UserMfaToken } from '../../interfaces/mfa-verification';

@Component({
  selector: 'coh-mfa-alternate-method',
  templateUrl: './alternate-method.component.html',
  styleUrls: ['./alternate-method.component.scss'],
  providers: subformComponentProviders(AlternateMethodComponent),
})
export class AlternateMethodComponent extends NgxSubFormComponent<MfaEmailToken> {

  /**
   * The user information with the token credentials.
   */
  @Input() userMfaInfo: UserMfaToken;

  /**
   * Emits an event when the next button is clicked.
   */
  @Output() next = new EventEmitter();

  /**
   * Emits an event when the previous button is clicked.
   */
  @Output() previous = new EventEmitter();

  /**
   * Holds whether the token has been verified.
   */
  verified = false;

  getFormControls(): Controls<MfaEmailToken> {
    return {
      emailToken: new UntypedFormControl(null, Validators.required),
    };
  }

  constructor(
    private mfaService: MFAServiceApi,
    readonly mfa: MfaService,
    private snackbar: SnackBarService,
    private translate: TranslateService,
    private ajaxHandler: AjaxHandlerService,
  ) {
    super();

    this.mfa.isEmailTokenVerified$
      .pipe(takeUntilDestroyed(this))
      .subscribe(verified => this.verified = verified);
  }

  /**
   * Verifies whether the email token is valid or not.
   */
  verifyToken() {
    this.mfa.verify(this.formGroupValues.emailToken, MFAType.email)
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  /**
   * Triggers an email with the verification code.
   */
  sendEmailCode() {
    this.mfaService.SendEmailOtp()
      .pipe(takeUntilDestroyed(this))
      .subscribe(
        () => this.snackbar.open(this.translate.instant('mfa.email.success')),
        err => this.ajaxHandler.errorMessage(err),
      );
  }

}
