import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { isEqual } from 'lodash';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AutoTableFilter } from './auto-table.model';
import { AsyncTableDataSource, DataSearchResult } from '../table/async-table-data-source';

/**
 * Async data source model for auto table.
 */
export class AutoTableSource extends AsyncTableDataSource<any, any> {
  /**
   * Used to track filter change event including sort, filter, pagination.
   */
  filterChangeSubject = new Subject<AutoTableFilter>();

  /**
   * Async data to manage data update.
   */
  autoTableDataSubject = new BehaviorSubject<any[]>([]);

  /**
   * Use the default string filter function in MatTableDataSource to
   * perform client-side string search.
   */
  tableUtil = new MatTableDataSource();

  /**
   * Stores user typed search string.
   */
  searchString: string;

  set data(data: any[]) {
    this.autoTableDataSubject.next(data);
  }

  get data(): any[] {
    return this.autoTableDataSubject.value;
  }

  constructor(public autoTableFilter: AutoTableFilter) {
    super();
  }

  /**
   * Filter data based on search string.
   *
   * @param    data  Original data before filtering.
   * @returns  Filtered data.
   */
  filterData(data: any[]): any[] {
    if (data?.length && this.searchString?.length) {
      this.tableUtil.data = data;
      this.tableUtil.filter = this.searchString;
      return this.tableUtil.filteredData;
    }
    return data;
  }

  /**
   * Gets data from filter, page and sort change.
   *
   * @param tableFilter  Filter Object
   * @param page         Page Event Object
   * @param sort         Sort Event Object
   * @returns async data after filter, pagination and sorting.
   */
  getData(tableFilter: any, page: PageEvent, sort: Sort): Observable<DataSearchResult<any>> {
    const searchString = tableFilter?.length ? tableFilter.trim().toLowerCase() : tableFilter;

    if (this.searchString !== searchString) {
      this.searchString = searchString;
    }

    // getData is sent multiple times so do some checking to avoid multiple events.
    if (sort && !isEqual(this.autoTableFilter.sort, sort)) {
      this.filterChangeSubject.next({ sort });

      // set data to empty first and then wait for async data
      this.data = null;
    }

    // Pagination is currently done in client side so perform it here instead of
    // invoking filterChange.
    const pageStart = (page.pageIndex * page.pageSize) || 0;

    return this.autoTableDataSubject.pipe(
      filter(data => !!data),
      take(1),
      map(data => {
        const filteredData = this.filterData(data);

        return {
          data: filteredData.slice(pageStart, pageStart + page.pageSize),
          totalSize: filteredData.length,
        };
      })
    );
  }
}
