<button mat-icon-button
  [matMenuTriggerFor]="menu"
  matTooltip="{{label}}"
  [attr.aria-label]="label"
  cogDataId="switch-app-anchor">
  <cog-icon [ariaLabel]="label" shape="apps"></cog-icon>
</button>

<mat-menu #menu="matMenu" class="app-switcher">
  <div class="app-grid">
    <a *ngFor="let app of appConfigs"
      class="app-item"
      [class.active]="app.isActive"
      [href]="getAppHref(app)"
      (click)="goto(app, $event)"
      cogDataId="switch-to-{{ getAppDisplayName(app.name) }}-anchor">
      <cog-icon size="lg" [shape]="app.icon"></cog-icon>
      <div>
        {{ getAppDisplayName(app.name) }}
      </div>
    </a>
  </div>
</mat-menu>
