<div class="token-wrapper">
  <div class="content-data" [class.wide-content-data]="tokenTimeout">
    <span>{{value || (placeholder | translate)}}</span>
    <span *ngIf="tokenTimeout" class="token-timeout">
      <cog-icon shape="timer" size="xs"></cog-icon>
      <span>{{ tokenTimeout | duration }}</span>
    </span>
  </div>
  <div class="action-buttons">
    <cog-icon shape="content_copy"
      cogDataId="copy-content-btn"
      [inheritDataId]="true"
      size="md"
      [ariaLabel]="'copy' | translate"
      matTooltip="{{'copy' | translate}}"
      (click)="copy()">
    </cog-icon>
    <cog-icon shape="get_app"
      cogDataId="download-file-btn"
      *ngIf="!hideDownload"
      [inheritDataId]="true"
      size="md"
      [ariaLabel]="'download' | translate"
      matTooltip="{{'download' | translate}}"
      (click)="download()">
    </cog-icon>
  </div>
</div>
