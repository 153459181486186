// Controller: Storage by tenants report

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.reports')
    .controller('reportsStorageTenantsCtrl', reportsStorageTenantsCtrlFn);

  function reportsStorageTenantsCtrlFn(_, $q, $scope, $filter, ReportsService,
    evalAJAX, cUtils, CHART_COLORS, cReportsControlsUtil, ReportsUtil,
    $rootScope, TenantService, FEATURE_FLAGS, $state) {

    var tenantsMap = {};

    $scope.storageTenantsConfig =
      ReportsUtil.getReportConfig('kStorageConsumedByTenantsReport')();

    $scope.tenantStorageInfo = [];
    $scope.plottedBackupTenantsCount = ' ';
    $scope.plottedViewsTenantsCount = ' ';
    $scope.getData = getData;
    $scope.downloadReport = downloadReport;
    $scope.dataset = {};

    $scope.showLogicalDataColumns = FEATURE_FLAGS.tenantStorageConsumedByOrgColumnsEnabled;

    /**
     * Tab Configuration
     *
     * @type {Array}
     */
    $scope.cTabConfig = [
      FEATURE_FLAGS.tenantsPhysicalUsageStorageConsumptionReportEnabled ? {
        headingKey: 'physicalUsage',
        route: 'reports.storage-tenants',
        params: {
          type: 'physical-usage',
        },

      }: null,
      {
        headingKey: 'workload',
        route: 'reports.storage-tenants',
        params: {
          type: 'workload',
        },
      }
    ].filter(Boolean);

    /**
     * initialize the workload report.
     *
     * @method  initialize
     */
    function initialize() {
      if ($state.params.type !== 'workload') {
        return;
      }

      var defaultFilters = cReportsControlsUtil.getDefaultFilters({
        lastNDays: 7,
      });
      var getTenantsPromise = $rootScope.user.privs.ORGANIZATION_VIEW ?
        TenantService.getTenants() : $q.resolve([]);

      getTenantsPromise.then(function onSuccess(tenantsList) {
        tenantsMap = _.keyBy(tenantsList, 'tenantId');

        getData(defaultFilters);
      });
    }

    /**
     * builds a params object based on current filter settings
     *
     * @param  {Object} filter   The filter for the API request.
     * @return {Object} params object for API submission
     */
    function getParams(filters) {
      var params = _.assign({}, filters, {
        msecsBeforeEndTime: filters.lastNDays * 86400000
      });

      return ReportsService.extendWithTenantParams(params);
    }

    /**
     * requests a csv download via ReportsService
     *
     * @return {Void}
     */
    function downloadReport() {
      var params = getParams(cReportsControlsUtil.filters);

      params.outputFormat = 'csv';
      ReportsService.getStorageByTenants(params);
    }

    /**
     * gets and parses the report data from the API
     *
     * @param      {object}    filters    filters from c-reports-controls
     *
     * @return {Void}
     */
    function getData(filters) {
      $scope.dataReady = false;
      $scope.tenantsByBackupChart.loading = true;
      $scope.tenantsByViewsChart.loading = true;
      $scope.nDaysAgo = filters.lastNDays;

      ReportsService.getStorageByTenants(getParams(filters)).then(
        function onSuccess(response) {
          $scope.tenantStorageInfo =
            (response.data.tenantStorageInformation || []).map(
              transformTenantStorageInfo);

          parseDataForCharts($scope.tenantStorageInfo);
        },
        evalAJAX.errorMessage
      ).finally(
        function finallyGotData() {
          $scope.tenantsByBackupChart.loading = false;
          $scope.tenantsByViewsChart.loading = false;
          $scope.dataReady = true;
        }
      );
    }

    /**
     * transforms the tenant storage info
     *
     * @param   {Object}   tenantStorage   storage info to transform
     * @return  {Object}   transformed tenant storage info
     */
    function transformTenantStorageInfo(tenantStorage) {
      if (tenantStorage.backupLogicalavgGrowthPct !== undefined &&
        tenantStorage.backupLogicalavgGrowthPct !== null) {
          tenantStorage._backupLogicalavgGrowth =
          cUtils.round(tenantStorage.backupLogicalavgGrowthPct, 2) + '%';
      }

      // TODO(veetesh): UI should not resolve the tenant info for remote
      // clusters since backend is planing to do that in 6.1.1 hence this
      // ugly hack https://cohesity.atlassian.net/browse/ENG-42096
      tenantStorage._tenant = tenantsMap[tenantStorage.tenantId];

      return tenantStorage;
    }

    /**
     * generates chart data based for provided tenants storage info
     *
     * @param  {Array} tenantStorageInfo   The tenant Storage info
     */
     function parseDataForCharts(tenantStorageInfo) {
      var maxChartObjects = 10;
      var backupDataArray = [];
      var viewsDataArray = [];

      if (!tenantStorageInfo.length) {
        return;
      }

      $scope.dataReady = false;
      $scope.tenantsByBackupChart.series[0].data = [];
      $scope.tenantsByViewsChart.series[0].data = [];

      // Grouping the data based on tenant id so that the chart will show
      // aggregated tenant details.
      tenantStorageInfo = (tenantStorageInfo || []).reduce(
        function groupAndCombine(tenantStorageMap, item) {
        if (tenantStorageMap[item.tenant.tenantId]) {
          tenantStorageMap[item.tenant.tenantId] = _.assign(
            tenantStorageMap[item.tenant.tenantId], {
            tenant: item.tenant,
            backupLogicalSizeBytes: item.backupLogicalSizeBytes +
              tenantStorageMap[item.tenant.tenantId].backupLogicalSizeBytes,
            viewsLogicalSizeBytes: item.viewsLogicalSizeBytes +
              tenantStorageMap[item.tenant.tenantId].viewsLogicalSizeBytes,
          });
        } else {
          tenantStorageMap[item.tenant.tenantId] = {
            tenant: item.tenant,
            backupLogicalSizeBytes: item.backupLogicalSizeBytes,
            viewsLogicalSizeBytes: item.viewsLogicalSizeBytes,
          };
        }
        return tenantStorageMap;
      }, {});

      angular.forEach(tenantStorageInfo, function eachInfo(storage) {
        if (storage.backupLogicalSizeBytes) {
          backupDataArray.push({
            name: $filter('naFilter')(_.get(storage, 'tenant.name')),
            value: storage.backupLogicalSizeBytes,
          });
        }

        if (storage.viewsLogicalSizeBytes) {
          viewsDataArray.push({
            name: $filter('naFilter')(_.get(storage, 'tenant.name')),
            value: storage.viewsLogicalSizeBytes,
          });
        }
      });

      // sort data arrays in descending order and limit to 10
      backupDataArray = $filter('orderBy')(backupDataArray, '-value');
      viewsDataArray = $filter('orderBy')(viewsDataArray, '-value');
      backupDataArray = $filter('limitTo')(backupDataArray, maxChartObjects);
      viewsDataArray = $filter('limitTo')(viewsDataArray, maxChartObjects);

      // transform our data for use with highcharts
      backupDataArray = backupDataArray.map(function(tenantStorage) {
        return [tenantStorage.name, tenantStorage.value];
      });
      viewsDataArray = viewsDataArray.map(function(tenantStorage) {
        return [tenantStorage.name, tenantStorage.value];
      });

      $scope.plottedBackupTenantsCount = backupDataArray.length;
      $scope.plottedViewsTenantsCount = viewsDataArray.length;

      // Add data to the charts
      $scope.tenantsByBackupChart.series[0].data = backupDataArray;
      $scope.tenantsByViewsChart.series[0].data = viewsDataArray;
    }

    $scope.tenantsByBackupChart = {
      chartType: 'storageDonut',
      loading: true,
      colors: CHART_COLORS.tenColorPalette(),
      series: [{
        type: 'pie',
        name: 'tenants',
        data: []
      }],
      chart: {
        height: 225
      },
      title: {
        text: null
      },
      legend: {
        y: 0,
        itemMarginBottom: 8
      }
    };

    $scope.tenantsByViewsChart = {
      chartType: 'storageDonut',
      loading: true,
      colors: CHART_COLORS.tenColorPalette(),
      series: [{
        type: 'pie',
        name: 'tenants',
        data: []
      }],
      title: {
        text: null
      },
      legend: {
        y: 0,
        itemMarginBottom: 8
      }
    };

    initialize();
  }
})(angular);
