import {
  AcropolisEntities,
  AWSEntities,
  AzureEntities,
  Environment,
  EnvItems,
  GCPEntities,
  HyperVEntities,
  KVMEntities,
  VMwareEntities,
} from '@cohesity/iris-shared-constants';

export const hypervisorSources: EnvItems[] = [
  {
    environment: Environment.kVMware,
    entity: VMwareEntities.kVCenter,
  },
  {
    environment: Environment.kVMware,
    entity: VMwareEntities.kStandaloneHost,
  },
  {
    environment: Environment.kHyperV,
    entity: HyperVEntities.kSCVMMServer,
  },
  {
    environment: Environment.kHyperV,
    entity: HyperVEntities.kStandaloneHost,
  },
  {
    environment: Environment.kAcropolis,
    entity: AcropolisEntities.kStandaloneCluster,
  },
  {
    environment: Environment.kAcropolis,
    entity: AcropolisEntities.kOtherHypervisorCluster,
  },
  {
    environment: Environment.kGCP,
    entity: GCPEntities.kIAMUser,
  },
  {
    environment: Environment.kAWS,
    entity: AWSEntities.kIAMUser,
  },
  {
    environment: Environment.kAzure,
    entity: AzureEntities.kSubscription,
  },
  {
    environment: Environment.kKVM,
    entity: KVMEntities.kOVirtManager,
  },
  {
    environment: Environment.kVMware,
    entity: VMwareEntities.kvCloudDirector,
  },
  {
    environment: Environment.kHyperV,
    entity: HyperVEntities.kStandaloneCluster,
  }
];
