import { Injectable } from '@angular/core';
import { IrisContextService } from '@cohesity/iris-core';
import { Transition } from '@uirouter/core';

import { GuardPriority, GuardResult, StateGuard } from '../state-guard';

/**
 * Recovery detail page Guard redirects all state traffic to the ng detail state if
 * the feature flag is enabled.
 */
@Injectable({
  providedIn: 'root',
})
export class RecoverDetailGuard implements StateGuard {
  /**
   * This guard priority is set to App, which indicates it should run as part of the main application
   * after all bootstrapping and initializing is completed.
   */
  guardPriority = GuardPriority.App;

  /**
   * Match all navigation to jobs (classic ui state).
   */
  matchCriteria = {
    to: state =>
      [
        'recover-detail-local',
        'recover-detail-archive',
        'recover-detail-local.db-migration-details',
        'recover-detail-local.db-migration-settings',
      ].includes(state.name),
  };

  constructor(private irisCtx: IrisContextService) {}

  /**
   * Redirect to recovery detail page with the params.
   *
   * @param   transition  The ui router transition
   * @returns a redirect transition if the ngRecoveryDetail feature flag is enabled.
   */
  onStart(transition: Transition): GuardResult {
    const clusterInfo = this.irisCtx.irisContext.clusterInfo;
    if (!clusterInfo) {
      // If clusterInfo is not loaded, make the route pass through so that
      // app.js catches it and replays the route later when clusterInfo is
      // loaded.
      return true;
    }

    let params = transition.params();

    // Note that there is no info available to determine if it is RecoverVM, so we need
    // to update the state change code directly if we want to check ngRecoveryDetailVm
    // feature flag. So far most are in the recover flow and does not require changes.
    if (!params.force) {
      params = {
        ...params,
        id: [clusterInfo.id, clusterInfo.incarnationId, params.id].join(':'),
      };

      return transition.router.stateService.target('recovery.detail', params);
    }
  }
}
