import { Directive, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TypeConfig, FieldType, ErrorType } from './generic-form-field.model';

@Directive()
export class BaseFormFieldDirective<ConfigType> {
  /**
   * Label of the field.
   */
  @Input() label: string;

  /**
   * Configuration based on the field type.
   */
  @Input() config: ConfigType;

  /**
   * FormControl for this form field.
   */
  @Input() control: UntypedFormControl;

  /**
   * Getter for fetching form control objects for nested fields. This is currently
   * only supported for RadioButtonGroups which may have optional fields to render
   * based on the selected radio button value.
   */
  @Input() formControlGetterFn: (key: string) => UntypedFormControl = null;

  /**
   * Callback function to handle the validation error.
   */
  @Input() validationErrorFn: (
    errorType: ErrorType,
    fieldType?: FieldType,
    fieldLabel?: string,
    fieldConfig?: TypeConfig ) => string;
}
