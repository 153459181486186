import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NavItem } from '@cohesity/helix';

@Component({
  selector: 'coh-table-actions',
  templateUrl: './table-actions.component.html',
  styleUrls: ['./table-actions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TableActionsComponent {
  /**
   * Actions to show.
   */
  @Input() actions: NavItem[] = [];

  /**
   * Number of items to show as icon buttons. Remaining actions will be behind
   * a vertical ellipsis menu.
   */
  @Input() numItems = 3;
}
