// Diretive: cTimeZoneConverter

;(function(angular, undefined) {
  'use strict';

  angular.module('C.timeZoneConverter', [])
    .directive('cTimeZoneConverter', cTimeZoneConverterFn);

  /**
   * @ngdoc directive
   * @name C.timeZoneConverter:cTimeZoneConverter
   * @description
   *    This directive adds formatter and parser logic to an element capable of
   *    manipulating Date object in Javascript such as uib-timepicker
   *    <div uib-timepicker c-time-zone-converter></div>
   *
   * @restrict 'A'
   * @requires ngModel
   *
   * @example
     <div uib-timepicker
      c-time-zone-converter>
     </div>
   */
  function cTimeZoneConverterFn() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: cTimeZoneConverterLinkFn,
    };

    /**
     * Specifies the Link function responsible for registering DOM listeners as
     * well as updating the DOM.
     *
     * @method   cTimeZoneConverterLinkFn
     * @param    {Object}   scope       AngularJS scope object
     * @param    {Object}   element     jqLite-wrapped element matching this
     *                                  directive
     * @param    {Object}   attrs       hash object with key-value pairs of
     *                                  normalized attribute
     * @param    {Object}   ngModelCtrl directive's required controller instance
     */
    function cTimeZoneConverterLinkFn(scope, element, attrs, ngModelCtrl) {
      // Specifies array of functions executed whenever the bound ngModel
      // expression changes programmatically.
      ngModelCtrl.$formatters.push(_convertViewValue);

      // Specifies array of functions executed whenever the control updates the
      // ngModelController with new $viewValue, usually via user input.
      ngModelCtrl.$parsers.push(_convertModelValue);

      /**
       * Converts $viewValue to the desired timezone if specified.
       *
       * @method  _convertViewValue
       * @param   {Object}   viewValue   Specifies the current $viewValue with
       *                                 details of the time.
       * @return  {Object}   formatted date object in the desired time zone.
       */
      function _convertViewValue(viewValue) {
        var offset = 0;
        var date = new Date(Date.parse(viewValue));
        if (scope.timeZone) {
          offset = _calculateTimeZoneOffset(date);
        }
        date = new Date(date.getTime() + offset);
        return date;
      }

      /**
       * Converts $modelValue to the desired timezone if specified.
       *
       * @method   _convertModelValue
       * @param    {Object}   modelValue  Specifies the current $modelValue with
       *                                  details of the time.
       * @return   {Object}   formatted date object in the desired time zone.
       */
      function _convertModelValue(modelValue) {
        if (!modelValue) { return; }

        var offset = 0;
        var date;
        if (scope.timeZone) {
          offset = _calculateTimeZoneOffset(modelValue);
        }
        date = new Date(modelValue.getTime() - offset);
        return date;
      }

      /**
       * Calculates the offset required to convert the time in the browser's
       * timezone to the desired timezone.
       *
       * @param   {Date}   date   Specifies the date in the browser's time zone
       * @return  {Number} offset value in minutes
       */
      function _calculateTimeZoneOffset(date) {
        var browserOffset = date.getTimezoneOffset();
        var desiredTimezoneOffset = moment(date).tz(scope.timeZone).utcOffset();
        return 60000 * (browserOffset + desiredTimezoneOffset);
      }
    }
  }
})(angular);
