import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { RegionClusterScanResult, SecurityAdvisorServiceApi } from '@cohesity/api/secops';
import { DataFilterValue, FiltersComponent, ValueFilterSelection } from '@cohesity/helix';
import { IrisContextService, isSecurityCenterUser } from '@cohesity/iris-core';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';

import { IssuesDialogComponent } from '../../components/issues-dialog/issues-dialog.component';
import { ListIssuesDialogComponent } from '../../components/list-issues-dialog/list-issues-dialog.component';
import { PerformScanDialogInputParams } from '../../components/perform-scan-dialog/perform-scan-dialog.component';
import { ListIssuesDialogParams } from '../../models/list-issues-dialog-params';
import { PerformScanDialogService } from '../../services';

/**
 * Security score component.
 */
@Component({
  selector: 'dg-pa-scores-page',
  templateUrl: './scores-page.component.html',
  styleUrls: ['./scores-page.component.scss'],
  providers: [PerformScanDialogService]
})
export class ScoresPageComponent extends AutoDestroyable implements OnInit, AfterViewInit {
  /**
   * Emit an event when a row item is clicked for drilldown
   */
  @Output() readonly scoreDrilldownClick = new EventEmitter<{[key: string]: string}>();

  /**
   * Score Data to be displayed in the UI.
   */
  scoresData$ = new BehaviorSubject<RegionClusterScanResult[]>([]);

  /**
   * The filtered list of scores.
   */
  filteredScoresData$ = new BehaviorSubject<RegionClusterScanResult[]>([]);

  /**
   * If filterOption true, to display in the ui
   */
  @Input() showFilters = true;

  /**
   * Table filters.
   */
  @ViewChild(FiltersComponent) filtersComponent: FiltersComponent;

  /**
   * True if data fetching is in progress.
   */
  loading = false;

  /**
   * True if component is loaded in drill down mode.
   */
  isDrillDownMode = false;

  /**
   * Region name to be displayed on top for which table data is grouped by.
   */
  region: string;

  /**
   * Country name to be displayed on top for which table data is grouped by region.
   */
  @Input() country: string;

  /**
   * State name to be displayed on top for which table data is grouped by country.
   */
  @Input() state: string;

  /**
   * UI router state name of drilldown page.
   */
  private readonly scoresDrillDownRouterState = 'security-advisor-scores-drilldown';

  /**
   * The values for the scores range filter.
   */
  scoreRangeFilterValues: ValueFilterSelection[] = [];

  /**
   * The values for the cluster filter.
   */
  clusterFilterValues: ValueFilterSelection[] = [];

  /**
   * The values for the region filter.
   */
  regionFilterValues: ValueFilterSelection[] = [];

  /**
   * The value of the feature flag security center.
   */
  readonly isSecurityCenterEnabled = isSecurityCenterUser(this.irisCtx.irisContext);

  /**
   * Constructor
   */
  constructor(
    private translateService: TranslateService,
    private securityAdvisorService: SecurityAdvisorServiceApi,
    private ajaxService: AjaxHandlerService,
    private dialog: MatDialog,
    private performScanDialogService: PerformScanDialogService,
    private irisCtx: IrisContextService
  ) {
    super();
  }

  /**
   * OnInit lifecycle hook.
   */
  ngOnInit() {
    this.region = this.state || this.country;
    this.isDrillDownMode = !!this.region;
    this.getScanResultsByRegion();
  }

  /**
   * Initialize the filters after component is rendered.
   */
  ngAfterViewInit() {
    if (this.showFilters) {
      // Apply the filter on filter value changes.
      this.filtersComponent.filterValues$.pipe(this.untilDestroy(), debounceTime(300)).subscribe(filters => {
        this.applyFilters(filters);
      });
    }
  }

  /**
   * Drill down to more narrower region.
   *
   * @param rowData Region for which drill down is done.
   */
  drillDownRegion(rowData: RegionClusterScanResult) {
    const params = {state: this.state, country: this.country};

    // Return if state is already set. Nothing to do.
    switch (true) {
      case !!params.state:
      case !rowData.regionName:
        this.fixIssuesClickHandler(rowData);
        break;
      case this.isDrillDownMode:
        params.state = rowData.regionName;
        this.scoreDrilldownClick.emit(params);
        break;
      default:
        this.scoreDrilldownClick.emit({ country: rowData.regionName });
    }
  }

  /**
   * Open perform scan dialog.
   */
  openPerformScanDialog() {
    const dialogParams: PerformScanDialogInputParams = {
      scoresData: this.filteredScoresData$.getValue(),
    };
    this.performScanDialogService.openPerformScanDialog(dialogParams, result => {
      if (result) {
        this.getScanResultsByRegion();
      }
    });
  }

  /**
   * Get scan results by region. On success,
   *
   * 1. Update scoresData$ behavior subject.
   * 2. Initialize filter options.
   */
  private getScanResultsByRegion() {
    this.loading = true;

    this.securityAdvisorService
      .GetScanResultsByRegion({ country: this.country, state: this.state })
      .pipe(
        this.untilDestroy(),
        finalize(() => (this.loading = false))
      )
      .subscribe(
        res => {
          this.scoresData$.next(res.results);
          this.initFilterOptions(res.results);
          this.filteredScoresData$.next(res.results);
        },
        err => this.ajaxService.errorMessage(err)
      );
  }

  /**
   *  Initialize filter options with values from regionResults.
   *
   * @param regionResults  RegionClusterScanResult List of region results object.
   */
  private initFilterOptions(regionResults: RegionClusterScanResult[]) {
    // Populate region filter options.
    const distinctRegionNames = new Set<string>();
    regionResults.forEach(regionResult => {
      if (regionResult.regionName) {
        distinctRegionNames.add(regionResult.regionName);
      }
    });
    this.regionFilterValues = [...distinctRegionNames].map(result => ({
      label: result,
      value: result,
    }));

    const distinctClusterNames = new Set<string>();
    regionResults.forEach(regionResult =>
      regionResult.clusterNames.forEach(cluster => distinctClusterNames.add(cluster)));
    this.clusterFilterValues = [...distinctClusterNames].map(result => ({
      label: result,
      value: result,
    }));
    this.scoreRangeFilterValues = [
      {
        value: '<70',
        label: '<70',
        icon: 'helix:hexagon:critical',
        hintText: this.translateService.instant('highRisk'),
      },
      {
        value: '70-90',
        label: '70-90',
        icon: 'helix:hexagon:warning',
        hintText: this.translateService.instant('mediumRisk'),
      },
      {
        value: '>90',
        label: '>90',
        icon: 'helix:hexagon:success',
        hintText: this.translateService.instant('lowRisk'),
      },
    ];
  }

  /**
   * Get low and high values for the filter value selected.
   *
   * @param filterValue Value of the range filter.
   */
  private getRangeFilterValues(filterValue: string): [number, number] {
    let low = 0,
      high = 100;
    switch (filterValue) {
      case '<70':
        high = 69;
        break;
      case '70-90':
        low = 70;
        high = 90;
        break;
      case '>90':
        low = 91;
        break;
    }
    return [low, high];
  }

  /**
   * Apply the selected filter to safe data sources and update the view.
   *
   * Todo(Sudeep) Manually applying filters is not needed. Same can be done by passing custom comparator.
   *
   * @param filters The applied filter value .
   */
  private applyFilters(filters: DataFilterValue<any, any>[]) {
    // creating the shallow copy of the original scores data.
    let filteredValues = this.scoresData$.getValue();
    // applying the selected filter.
    filters.forEach(({ key, value }) => {
      // skip null or empty value filters.
      if (!value?.length) {
        return;
      }
      const selectedFilterValue = value[0].value;
      switch (key) {
        case 'scoresRange': {
          const [low, high] = this.getRangeFilterValues(selectedFilterValue);
          filteredValues = filteredValues.filter(result => result.lowestScore >= low && result.lowestScore <= high);
          break;
        }
        case 'cluster': {
          // Cluster filter allows multiple values.
          const selectedFilterValues: string[] = value.map(item => item.value);

          // Filter all the rows matching with any of selectedFilterValues
          filteredValues = filteredValues.filter(result =>
            selectedFilterValues.some(filterValue => result.clusterNames.includes(filterValue)));
          break;
        }
        case 'region':
          filteredValues = filteredValues.filter(result => result.regionName === selectedFilterValue);
          break;
      }
    });
    this.filteredScoresData$.next(filteredValues);
  }

  /**
   * Open action fix issues dialog based on country state and city names.
   *
   * @param rowData holds the country or state or city names.
   */
  fixIssuesClickHandler(rowData: RegionClusterScanResult) {
    const { regionName } = rowData;
    let issuesDialogParams: ListIssuesDialogParams = {country: this.country, state: this.state};
    if (!regionName || !!this.state) {
      issuesDialogParams = { clusterId: rowData.clusterIds[0] };
    } else if (!this.country) {
      issuesDialogParams.country = regionName;
    } else if (!this.state) {
      issuesDialogParams.state = regionName;
    } else {
      issuesDialogParams.city = regionName;
    }
    issuesDialogParams.clusterRegionName = regionName;
    this.openListIssuesDialog(issuesDialogParams);
  }

  /**
   * Open list issues dialog and pass ListIssuesDialogParams object.
   *
   * @param issuesDialogParams holds the country and state names
   */
  openListIssuesDialog(issuesDialogParams?: ListIssuesDialogParams) {
    // If dialog params is not provided, provide from component bindings.
    if (!issuesDialogParams) {
      issuesDialogParams = { country: this.country, state: this.state };
    }

    const dialogConfig: MatDialogConfig = {
      width: '60vw',
      height: '80vh'
    };

    if (this.isSecurityCenterEnabled) {
      this.dialog.open(IssuesDialogComponent, {data: issuesDialogParams, ...dialogConfig});
    } else {
      this.dialog.open(ListIssuesDialogComponent, {data: issuesDialogParams, ...dialogConfig});
    }
  }
}
