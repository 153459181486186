/* tslint:disable */

/**
 * Specifies the file change type in a diff.
 */
export enum FileChangeType {
  KAddedOrModified = 'kAddedOrModified',
  KAffected = 'kAffected',
  KDeleted = 'kDeleted',
  KUnaffected = 'kUnaffected',
  KAny = 'kAny'
}
