/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { App } from '../models/app';
import { PerformServiceActionParameters } from '../models/perform-service-action-parameters';
import { ImagesStatus } from '../models/images-status';
import { RecentEvent } from '../models/recent-event';
@Injectable({
  providedIn: 'root',
})
class AppService extends __BaseService {
  static readonly GetAppsPath = '/public/apps';
  static readonly UploadAppPath = '/public/apps';
  static readonly PerformServiceActionPath = '/public/apps/{appUid}/images/action';
  static readonly GetImagesStatusPath = '/public/apps/{appUid}/images/status';
  static readonly InstallAppPath = '/public/apps/{appUid}/versions/{version}';
  static readonly UninstallAppPath = '/public/apps/{appUid}/versions/{version}';
  static readonly GetRecentEventsPath = '/public/recentEvents';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Lists the apps.
   *
   * Api provides the list of the apps which are available for the user to install
   * or are already installed. App object provides basic app information along with
   * app metadata.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return GetAppsResponse specifies response for getting apps
   */
  GetAppsResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<Array<App>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/apps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<App>>;
      })
    );
  }
  /**
   * Lists the apps.
   *
   * Api provides the list of the apps which are available for the user to install
   * or are already installed. App object provides basic app information along with
   * app metadata.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return GetAppsResponse specifies response for getting apps
   */
  GetApps(regionId?: string,
    accessClusterId?: number): __Observable<Array<App>> {
    return this.GetAppsResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as Array<App>)
    );
  }

  /**
   * Upload and install an app from image.
   *
   * Api provides the list of the apps which are available for the user to install
   * or are already installed. App object provides basic app information along with
   * app metadata.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  UploadAppResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<App>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/apps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<App>;
      })
    );
  }
  /**
   * Upload and install an app from image.
   *
   * Api provides the list of the apps which are available for the user to install
   * or are already installed. App object provides basic app information along with
   * app metadata.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  UploadApp(regionId?: string,
    accessClusterId?: number): __Observable<App> {
    return this.UploadAppResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as App)
    );
  }

  /**
   * Enables or disables a service.
   * @param params The `AppService.PerformServiceActionParams` containing the following parameters:
   *
   * - `body`: Request to update app instance settings.
   *
   * - `appUid`: Specifies the app Id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  PerformServiceActionResponse(params: AppService.PerformServiceActionParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;

    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/apps/${encodeURIComponent(params.appUid)}/images/action`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Enables or disables a service.
   * @param params The `AppService.PerformServiceActionParams` containing the following parameters:
   *
   * - `body`: Request to update app instance settings.
   *
   * - `appUid`: Specifies the app Id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  PerformServiceAction(params: AppService.PerformServiceActionParams): __Observable<null> {
    return this.PerformServiceActionResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Api to get images information.
   * @param appUid Specifies the app Id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return GetImagesStatusResponse specifies response for getting image status
   */
  GetImagesStatusResponse(appUid: number,
    accessClusterId?: number): __Observable<__StrictHttpResponse<ImagesStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/apps/${encodeURIComponent(appUid)}/images/status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ImagesStatus>;
      })
    );
  }
  /**
   * Api to get images information.
   * @param appUid Specifies the app Id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return GetImagesStatusResponse specifies response for getting image status
   */
  GetImagesStatus(appUid: number,
    accessClusterId?: number): __Observable<ImagesStatus> {
    return this.GetImagesStatusResponse(appUid, accessClusterId).pipe(
      __map(_r => _r.body as ImagesStatus)
    );
  }

  /**
   * Starts the application installation on the cluster.
   *
   * Only purchased apps can be installed using this api.
   * @param params The `AppService.InstallAppParams` containing the following parameters:
   *
   * - `version`: Specifies the app version.
   *
   * - `appUid`: Specifies the app Id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  InstallAppResponse(params: AppService.InstallAppParams): __Observable<__StrictHttpResponse<App>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/apps/${encodeURIComponent(params.appUid)}/versions/${encodeURIComponent(params.version)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<App>;
      })
    );
  }
  /**
   * Starts the application installation on the cluster.
   *
   * Only purchased apps can be installed using this api.
   * @param params The `AppService.InstallAppParams` containing the following parameters:
   *
   * - `version`: Specifies the app version.
   *
   * - `appUid`: Specifies the app Id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  InstallApp(params: AppService.InstallAppParams): __Observable<App> {
    return this.InstallAppResponse(params).pipe(
      __map(_r => _r.body as App)
    );
  }

  /**
   * Starts the application uninstall from the cluster.
   *
   * App must already been installed for this api to work.
   * @param params The `AppService.UninstallAppParams` containing the following parameters:
   *
   * - `version`: Specifies the app version.
   *
   * - `appUid`: Specifies the app Id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  UninstallAppResponse(params: AppService.UninstallAppParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/apps/${encodeURIComponent(params.appUid)}/versions/${encodeURIComponent(params.version)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Starts the application uninstall from the cluster.
   *
   * App must already been installed for this api to work.
   * @param params The `AppService.UninstallAppParams` containing the following parameters:
   *
   * - `version`: Specifies the app version.
   *
   * - `appUid`: Specifies the app Id.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  UninstallApp(params: AppService.UninstallAppParams): __Observable<null> {
    return this.UninstallAppResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns a list of recent apps related events performed by the user.
   *
   * Returns a list of recent apps related events performed by the user.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return GetRecentEventsResponse specifies response for getting apps related recent events performed by the user.
   */
  GetRecentEventsResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<Array<RecentEvent>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/recentEvents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RecentEvent>>;
      })
    );
  }
  /**
   * Returns a list of recent apps related events performed by the user.
   *
   * Returns a list of recent apps related events performed by the user.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return GetRecentEventsResponse specifies response for getting apps related recent events performed by the user.
   */
  GetRecentEvents(regionId?: string,
    accessClusterId?: number): __Observable<Array<RecentEvent>> {
    return this.GetRecentEventsResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as Array<RecentEvent>)
    );
  }
}

module AppService {

  /**
   * Parameters for PerformServiceAction
   */
  export interface PerformServiceActionParams {

    /**
     * Request to update app instance settings.
     */
    body: PerformServiceActionParameters;

    /**
     * Specifies the app Id.
     */
    appUid: number;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for InstallApp
   */
  export interface InstallAppParams {

    /**
     * Specifies the app version.
     */
    version: number;

    /**
     * Specifies the app Id.
     */
    appUid: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UninstallApp
   */
  export interface UninstallAppParams {

    /**
     * Specifies the app version.
     */
    version: number;

    /**
     * Specifies the app Id.
     */
    appUid: number;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { AppService }
