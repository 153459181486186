import { Injectable } from '@angular/core';
import { Cluster, ClusterServiceApi, SessionUser } from '@cohesity/api/v1';
import { OneHeliosServiceApi, PlatformServiceApi } from '@cohesity/api/v2';
import { flagEnabled, IrisContextService, isOneHeliosAppliance, getConfigByKey } from '@cohesity/iris-core';
import { AjaxHandlerService } from '@cohesity/utils';
import { StateService } from '@uirouter/core';
import { combineLatest, forkJoin, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AjsUpgradeService, ClusterService, EulaService, LicenseService, UserService } from 'src/app/core/services';

// ClusterInfo returned as part of the angular JS service.
interface ClusterInfo {
  data: Cluster;
}

@Injectable()
export class LoginService {
  /**
   * Angular JS cluster service.
   */
  private ajsClusterService;

  /**
   * Switch between revamped cluster License Page
   */
  useRevampedClusterLicensePage: boolean = flagEnabled(this.irisCtx.irisContext, 'clusterLicensePageRevamp');

  constructor(
    private ajaxService: AjaxHandlerService,
    private ajsUpgrade: AjsUpgradeService,
    private clusterService: ClusterService,
    private eulaService: EulaService,
    private irisCtx: IrisContextService,
    private licenseService: LicenseService,
    private stateService: StateService,
    private userService: UserService,
    private oneHeliosService: OneHeliosServiceApi,
    private platformServiceApi: PlatformServiceApi
  ) {
    this.ajsClusterService = this.ajsUpgrade.get('ClusterService');
  }

  /**
   * Authenticates the user after successful login.
   *
   * @param   loginData   The logged in user information with privs.
   */
  authenticateUser(loginData: SessionUser) {
    this.userService
      .createSession(loginData)
      .pipe(
        switchMap(loggedInUser =>
          combineLatest([
            of(loggedInUser),
            isOneHeliosAppliance(this.irisCtx.irisContext) ? this.isOneHeliosSetupInProgress() : of(false),
          ]).pipe(
            switchMap(([user, isSetupInProgress]) => {
              if (!user.isNodeInCluster || isSetupInProgress) {
                return this.performClusterSetup();
              }
              return this.handlePostLogin();
            })
          )
        ),
        catchError(err => {
          this.ajaxService.errorMessage(err);
          return of(err);
        })
      )
      .subscribe();
  }

  /**
   * Gets the cluster info with updated locale.
   *
   * @param   [params]           Cluster params
   * @param   [clusterDefaults]  Default cluster properties
   * @returns Promise with Cluster Info
   */
  getClusterInfoWithLocale(
    params: ClusterServiceApi.GetClusterParams = {},
    clusterDefaults: ClusterServiceApi.GetClusterParams = {}
  ): Observable<ClusterInfo> {

    // There is processing such as cluster formatter being called.
    // We might need special effort to migrate this to angular.
    // Also there is a circular dependency with Locale Service, that's why
    // this is in this file.
    // There was a change with ENG-195569 that wrap cluster info to data.
    return from(this.ajsClusterService.getClusterInfoWithLocale(params, clusterDefaults)) as Observable<ClusterInfo>;
  }

  /**
   * Updates the rootScope with the licensing flow so that the app loads as
   * it should.
   */
  updateRootWithLicenseSuccess() {
    this.ajsClusterService.updateRootWithLicenseSuccess();
  }

  /**
   * Handles the logic to setting up the cluster for the first time
   *
   * @returns An observable with cluster create in Progress or not.
   */
  private performClusterSetup() {
    return this.clusterService.isClusterCreateInProgress()
      .pipe(
        tap(inProgress => {
          if (isOneHeliosAppliance(this.irisCtx.irisContext)) {
            this.stateService.go('appliance-manager-setup');
          } else {
            if (inProgress) {
              this.stateService.go('cluster-setup.confirm');
            } else if (flagEnabled(this.irisCtx.irisContext, 'ngClusterCreate')) {
              this.stateService.go('cluster-create');
            } else {
              this.stateService.go('cluster-setup');
            }
          }
          return;
        })
      );
  }

  /**
   * Handles the post authentication logic to let the user access workflows.
   *
   * @returns An observable with the current cluster info.
   */
  private handlePostLogin() {
    return this.getClusterInfoWithLocale()
      .pipe(
        map(resp => resp.data),
        tap(cluster => {
          // If we are here, then cluster has been loaded in rootScope
          // and we are ready to proceed to authenticated workflows.
          const ctx = this.irisCtx.irisContext;
          const isEulaRequired = getConfigByKey(ctx, 'cluster.eulaNeeded', true);
          const isLicenseAcceptanceRequired = getConfigByKey(ctx, 'cluster.licenseNeeded', true);

          if (isEulaRequired && this.eulaService.isEulaNeeded(cluster)) {
            this.stateService.go('eulaNew', { eulaType: 'cohesity' });
            return;
          } else if (isLicenseAcceptanceRequired &&
            this.clusterService.isNewCluster(cluster) &&
            this.licenseService.isLicenseAcceptanceNeeded(cluster)) {
            if (this.useRevampedClusterLicensePage) {
              this.stateService.go('connected-site-ng.select-mode');
            } else {
              this.stateService.go('connected-site', { registrationType: 'saas' });
            }
            return;
          }

          // TODO: License Warning and Expiry needs to be incorporated.
          this.updateRootWithLicenseSuccess();
          this.userService.loginSuccess();
          this.userService.redirectPostLogin();
        })
      );
  }

  /**
   * Determines if the setup for Helios and Kubernetes is still in progress.
   *
   * @returns An observable that emits true if the setup is still in progress, otherwise false.
   */
  isOneHeliosSetupInProgress(): Observable<boolean> {
    return forkJoin({
      heliosInstallationLogs: this.oneHeliosService.InstallLogs(),
      kubernetesHealthStatus: this.platformServiceApi.GetKubernetesInfraHealthStatus(),
    }).pipe(
      map(({ heliosInstallationLogs, kubernetesHealthStatus }) => {
        const isHeliosInstallationIncomplete = heliosInstallationLogs.heliosInstallStatus !== 'Success';
        const isKubernetesUnhealthy =
          (
            kubernetesHealthStatus.overallK8SState === 'Pending' ||
            kubernetesHealthStatus.overallK8SState === 'Initializing' ||
            kubernetesHealthStatus.overallK8SState === 'Unknown'
          ) || (
            !!kubernetesHealthStatus.overallK8SHealthStatus &&
            kubernetesHealthStatus.overallK8SHealthStatus !== 'Healthy'
          );
        return isHeliosInstallationIncomplete || isKubernetesUnhealthy;
      }),
      catchError(() => of(true)),
    );
  }
}
