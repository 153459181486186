import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { SourceSpecialParameter } from '@cohesity/api/v1';
import { DataTreeNodeContext, DataTreeNodeDetail } from '@cohesity/helix';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { PhysicalEntityType } from 'src/app/shared/constants';

import { PhysicalBlockSourceDataNode } from '../../physical/physical-block/physical-block-source-data-node';
import { SqlSourceDataNode } from '../../sql/sql-source-data-node';

export type ConfigurableNodeTypes = SqlSourceDataNode | PhysicalBlockSourceDataNode;

@Component({
  selector: 'coh-physical-block-host-options',
  templateUrl: './physical-block-host-options.component.html',
  styleUrls: ['./physical-block-host-options.component.scss']
})
export class PhysicalBlockHostOptionsComponent implements DataTreeNodeDetail<ConfigurableNodeTypes> {
  /**
   * The node context, including info about the node and it's selection status.
   */
  @Input() nodeContext: DataTreeNodeContext<ConfigurableNodeTypes>;

  /**
   * VSS Writer input ref.
   */
  @ViewChild('vssWriterInput') vssWriterInput: ElementRef<HTMLInputElement>;

  /**
   * Gets the node from the nodeContext.
   */
  get node(): ConfigurableNodeTypes {
    return this.nodeContext.node;
  }

  /**
   * Gets the current options for the node. This either gets them from the selection options, or
   * the default params for the node.
   */
  get currentOptions(): SourceSpecialParameter {
    return this.nodeContext.selection.getOptionsForNode(this.node.id) || {};
  }

  /**
   * Return true if the current source params have enabled system backup. This is used
   * to initialize the form value.
   */
  get systemBackupEnabled() {
    const physicalParams = this.currentOptions.physicalSpecialParameters;
    return physicalParams && physicalParams.enableSystemBackup;
  }

  /**
   * Return array of selected volume guids.
   */
  get selectedGuids() {
    const physicalParams = this.currentOptions.physicalSpecialParameters;
    return physicalParams ? physicalParams.volumeGuid || [] : [];
  }

  /**
   * Gets the volumes that are previously selected but now not present in
   * the source either they are removed or renamed.
   *
   * @returns missingVolumes
   */
  get missingVolumeGuids(): string[] {
    const currentGuids = (this.nodeContext.node.nodeVolumes || []).map(volume => volume.guid);
    return this.selectedGuids.filter(guid => !currentGuids.includes(guid));
  }

  /**
   * Return true if all volumes are protected.
   */
  get protectAllVolumesEnabled() {
    return this.selectedGuids.length === 0;
  }

  /**
   * Return true if the node is windows file server role cluster.
   */
  get isWindowsFileServer(): boolean {
    return this.node.type === PhysicalEntityType.kWindowsCluster &&
      (this.node as PhysicalBlockSourceDataNode).clusterSourceType === 'kRole';
  }

  /**
   * Returns writers which are excluded
   */
  get excludedVssWriters(): string[] {
    return flagEnabled(this.contextService.irisContext, 'vssWritersUIEnabled') ?
      ((this.currentOptions.physicalSpecialParameters || {}).excludedVssWriters || []) : [];
  }

  /**
   * Returns initial value of Exclude Writers toggle
   */
  get enabledExcludeVSSWriters(): boolean {
    return this.excludedVssWriters.length > 0;
  }


  /**
   * The form to contain the options.
   */
  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private contextService: IrisContextService
  ) { }

  /**
   * Helper function for feature flag checks that is easier to expose to the html templae
   *
   * @param flag The flag name
   * @returns true if the flag is enabled
   */
  flagEnabled(flag: string): boolean {
    return flagEnabled(this.contextService.irisContext, flag);
  }

  /**
   * Updates the form based on the current options setting in preparation for displaying the form
   * dialog.
   */
  updateForm() {
    this.form = this.fb.group({
      enableSystemBackup: new UntypedFormControl(this.systemBackupEnabled),
      volumes: this.fb.array((this.nodeContext.node.nodeVolumes || []).map(volume => this.fb.group(
        {
          guid: volume.guid,
          label: volume.label,
          mountPoints: [volume.mountPoints],
          logicalSizeBytes: volume.logicalSizeBytes,
          selected: new UntypedFormControl({
            value: this.selectedGuids.includes(volume.guid),
            disabled: !volume.guid
          })
        },
      ))),
      protectAllVolumes: new UntypedFormControl(this.protectAllVolumesEnabled),
      enabledExcludeVSSWriters: new UntypedFormControl(this.enabledExcludeVSSWriters),
      excludedVssWriters: this.fb.array(this.excludedVssWriters),
    });
  }

  /**
   * addVssWriterExclude adds a new vssWriter to selection
   *
   * @param vssWriter
   */
  addVssWriterExclude(vssWriter: string) {
    const control = (this.form.controls.excludedVssWriters as UntypedFormArray);
    const isNewVssWriter = this.form.get('excludedVssWriters').value.indexOf(vssWriter) === -1;

    if (vssWriter && isNewVssWriter) {
      control.push(new UntypedFormControl(vssWriter, Validators.required));
    }
  }

  /**
   * Triggers on selecting the vss writer from the dropdown, add it to the list.
   *
   * @param   event   MatAutocompleteSelectedEvent.
   */
  selectExcludeVssWriter(event: MatAutocompleteSelectedEvent) {
    const vssWriter = event.option.value;
    this.addVssWriterExclude(vssWriter);

    // Reset input value after adding
    this.vssWriterInput.nativeElement.value = '';
  }

  /**
   * Triggers on adding new vss writer from text input
   *
   * @param   event   MatChipInputEvent.
   */
  vssWriterInputChange(event: MatChipInputEvent) {
    const vssWriter = (event.value || '').trim();
    this.addVssWriterExclude(vssWriter);

    // Reset input value after adding
    this.vssWriterInput.nativeElement.value = '';
  }

  /**
   * removeVssWriter removes vss writer from exclusion
   *
   * @param string vssWriter
   * @param number index
   */
  removeVssWriter(index: number) {
    const vssWriters = (this.form.controls.excludedVssWriters as UntypedFormArray);
    vssWriters.removeAt(index);
  }

  /**
   * getSupportedVSSWriters returns list of vss writers supported in selected host
   *
   * @returns
   */
  getSupportedVSSWriters(): string[] {
    return (
      this.nodeContext?.node?.data?.protectionSource?.physicalProtectionSource?.vsswriters
      || []).map(w => w.writerName);
  }

  /**
   * Updates the selection options after the form has been saved and the dialog has been closed.
   */
  onSaved() {
    const options = {
      sourceId: this.node.id,
      physicalSpecialParameters: {
        enableSystemBackup: this.form.value.enableSystemBackup,
        volumeGuid: this.form.value.protectAllVolumes ?
          [] :
          this.form.value.volumes.map(volume => (
            volume.selected && volume.guid
          )).filter(Boolean),
        excludedVssWriters: this.form.value.enabledExcludeVSSWriters ?
          this.form.value.excludedVssWriters :
          []
      }
    };
    this.nodeContext.selection.setOptionsForNode(this.node.id, options);
    this.cdr.detectChanges();
  }
}
