// Component: Select or Register Source

;(function(angular, undefined) {
  'use strict';

  var componentName = 'selectSourcePub';
  var configOptions = {
    controller: 'SelectSourcePubCtrl',
    templateUrl: 'app/global/select-source-pub/select-source-pub.html',

    // Attribute bindings
    bindings: {
      /**
       * Optional single value or array of kEnvironment strings for which to
       * display in the dropdown.
       *
       * Note: undefined will show *all* registered Sources.
       *
       * Default: ENV_GROUPS.all
       *
       * @type   {array}
       */
      environments: '<?',

      /**
       * Optional attribute sniffed value, if attribute is present then list all
       * sources owned by the logged in user organization.
       */
      // only-own: '?'

      /**
       * Optional If prevent and true then use MCM APIs to get data else use
       * on-prem cluster APIs.
       */
      mcmMode: '@?',

      /**
       * Optional. If provided then ngModel will be set with the value of the
       * property looked up inside source info for example
       * if 'protectionSource.id' is provided then ngModel will contain
       * source id of selected source.
       *
       * @type   {String}
       */
      resultAs: '@?',

      /**
       * Optional. If provided then will be used to determine if a source can be
       * selected or not else by default all sources are selectable.
       *
       * @type   {Function}
       */
      isChoiceDisabled: '&?',

      /**
       * Optional. If provided then will be used to determine whether to select
       * all sources initialing when sources are fetched.
       *
       * @type   {Boolean}
       */
      defaultSelectAll: '<?',

      /**
       * Optional attribute sniffed value, if attribute is present then allow
       * multiple selection.
       */
      // multiple: '?'

      /**
       * Optional attribute sniffed value, if attribute is present then show
       * select all sources and would work only in multiple selection mode.
       */
      // showSelectAllOption: '?'

      /**
       * Optional attribute sniffed value, if attribute is present then show
       * source's meta info.
       */
      // showMetaInfo: '?'

      /**
       * Optional attribute sniffed value, if attribute is present the uiSelect
       * component will open automatically on initialization if the model is
       * empty.
       */
      // autoOpenIfEmpty: '?'

      /**
       * Optional attribute sniffed value, if attribute is present then show
       *  add new source option.
       */
      // addNewEnabled: '?'

      /**
       * Optional. If provided then will be used to
       * maintain the state of all selected.
       *
       * @type   {Boolean}
       */
      isAllSelected: '=?'
    },

    // Required outside things. These show up on `this.ngModel` in the
    // controller.
    require: {
      ngModel: 'ngModel',
    },
  };

  angular.module('C.selectSourcePub',
    ['ui.select', 'C.pubSourceService', 'C.mcmSourceService'])
    .controller('SelectSourcePubCtrl', SelectSourcePubCtrlFn)
    .component(componentName, configOptions);

  /**
   * $ngdoc Component
   * @name C.selectSourcePub:selectSourcePub
   * @scope
   * @link
   *
   * @requires ngModel
   * @function
   * @description
   *   Widget to select a Source from a dropdown using public API.
   *
   * @example
   *   <select-source-pub
   *     id="selected-source-dropdown"
   *     name="selectedParentSource"
   *     required
   *     result-as="protectionSource.id"
         ng-model="selectedProtectionSource"></select-source-pub>
   */
  function SelectSourcePubCtrlFn(_, $attrs, evalAJAX, PubSourceService,
    McmSourceService, cUtils, ENV_GROUPS) {

    var $ctrl = this;

    _.assign($ctrl, {
      // binding $attrs for disabled state pass through
      $attrs: $attrs,

      // component properties
      sourcesList: [],
      selectedSource: undefined,
      selectedSources: [],

      // component methods
      selectAll: selectAll,
      updateExternalModel: updateExternalModel,

      // lifecycle hooks
      $onInit: $onInit,
      $onChanges: $onChanges,
    });

    /**
     * Initialize this component.
     *
     * @method     $onInit
     */
    function $onInit() {
      _.assign($ctrl, {
        id: [($attrs.id || componentName), 'ui-select'].join('-'),
        showMetaInfo: $attrs.hasOwnProperty('showMetaInfo'),
        showSelectAllOption: $attrs.hasOwnProperty('showSelectAllOption'),
        multiple: $attrs.hasOwnProperty('multiple'),
        addNewSource:
          $attrs.hasOwnProperty('addNewEnabled') ? addNewSource : undefined,
        onlyOwn: $attrs.hasOwnProperty('onlyOwn'),
      });

      // fetch list of sources.
      getData();
    }

    /**
     * Handles changes to one-way bound properties.
     *
     * @method   $onChanges
     * @param    {object}   changesObj   The changes object
     */
    function $onChanges(changesObj) {
      if (changesObj.environments) {
        getData();
      }
    }

    /**
     * Fetches list of sources.
     *
     * @method     getData
     */
    function getData() {
      $ctrl.loading = true;

      var params = {
        environments: cUtils.onlyStrings($ctrl.environments || ENV_GROUPS.all),
        allUnderHierarchy: true,
        includeEntityPermissionInfo: true,
      };
      var serviceName = $ctrl.onlyOwn ? 'getOwnRootNodes' : 'getRootNodes';

      var getDatePromise = $ctrl.mcmMode ? McmSourceService.getRootNodes() :
        PubSourceService[serviceName](params);

      getDatePromise.then(function gotSources(sources) {
        $ctrl.sourcesList = sources;

        // selecting all sources.
        if ($ctrl.defaultSelectAll) {
          $ctrl.selectAll(true);
        }

        // setup external model update hook when select-sources is ready.
        // update internal model on external model changes.
        $ctrl.ngModel.$render = updateInternalModel;

        // manually update the internal model to initialize them.
        updateInternalModel();
      }, evalAJAX.errorMessage).finally(function finallyGotSources() {
        $ctrl.loading = false;
      });
    }

    /**
     * Update internal model with external model changes.
     *
     * @method   updateInternalModel
     */
    function updateInternalModel() {
      $ctrl.selectedSources = [];
      $ctrl.selectedSource = undefined;

      if ($ctrl.ngModel.$isEmpty($ctrl.ngModel.$viewValue)) {
        return;
      }

      if ($ctrl.multiple) {
        $ctrl.sourcesList.forEach(function eachSource(source) {
          if (_.find($ctrl.ngModel.$viewValue, getEqualSelectionFn(source))) {
            $ctrl.selectedSources.push(source);
          }
        });
      } else {
        $ctrl.sourcesList.some(function eachSource(source) {
          if (_.isEqual($ctrl.ngModel.$viewValue, getProperty(source))) {
            $ctrl.selectedSource = source;
            return true;
          }
          return false;
        });
      }
    }

    /**
     * Return a fn used to determine if selected item is equals to provided
     * source.
     *
     * @method   getEqualSelectionFn
     * @param    {Object}     source   The source info.
     * @return   {function}   The function used to determine if selected item
     *                        is equals to provided source.
     */
    function getEqualSelectionFn(source) {
      /**
       * Determines if selected item is equals to source.
       *
       * @method   isEqualSelection
       * @param    {Object}    item   The item to test.
       * @return   {boolean}   Return True if selected item equals to source.
       */
      return function isEqualSelection(item) {
        return _.isEqual(item, getProperty(source));
      };
    }

    /**
     * Update external model value on internal model changes.
     *
     * @method   updateExternalModel
     */
    function updateExternalModel() {
      var value;
      if ($ctrl.multiple) {
        value = _.map($ctrl.selectedSources, getProperty);
      } else {
        value = getProperty($ctrl.selectedSource);
      }

      $ctrl.isAllSelected =
        $ctrl.selectedSources.length === $ctrl.sourcesList.length;

      // update external mode
      $ctrl.ngModel.$setViewValue(value);
    }

    /**
     * Returns the source property that needed to be in the selected source info
     *
     * @method   getProperty
     * @param    {Object}    source   The source info.
     * @return   {any}       Returns the source property that needed to be in
     *                       the selected source info.
     */
    function getProperty(source) {
      return $ctrl.resultAs ? _.get(source, $ctrl.resultAs) : source;
    }

    /**
     * Select all sources.
     *
     * @method   selectAll
     * @param    status   True, if select all is selected, else false.
     */
    function selectAll(status) {
      $ctrl.isAllSelected = status;
      $ctrl.selectedSources = status ? $ctrl.sourcesList : [];
      updateExternalModel();
    }

    /**
     * Launch register new source workflow.
     *
     * @method   addNewSource
     */
    function addNewSource() {
      // TODO(veetesh): add a new source logic.
    }

  }
})(angular);
