// REPORT: Files content

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.reports')
    .controller('reportsFilesContentController', reportsFilesContentControllerFn);

  function reportsFilesContentControllerFn($rootScope, $scope, $state,
    $stateParams, $log, $filter, ReportsService, SourceService, evalAJAX,
    cUtils) {

    $scope.stateParams = $stateParams;

    $scope.text = {};
    angular.extend($scope.text, $rootScope.text.reportsFilesFiles);

    /**
     * Shared VM Name String
     * @type {string}
     */
     $scope.vmName = null;

    // // TODO: REPLACE THIS BLOCK AFTER MAGNETO SUPPORTS INDIVIDUAL VM LOOKUP BY ID
    if ($stateParams.vmName) {
      $scope.vmName = $stateParams.vmName;
    }

    // redirect to categories by default
    if ($state.current.name === 'reports.files') {
      $state.go('reports.files-category', $stateParams);
    }

    if ($state.current.name === 'reports.files-topfiles' && !$stateParams.jobId &&
      !$stateParams.objId) {

      $state.go('reports.files-category');
    } else if ($state.current.name === 'reports.files-category' ||
      $state.current.name === 'reports.files-topfiles') {

      $scope.currentTab = $state.current.name;
    }

    // setup our tabs
    $scope.cTabConfig = [{
      name: $scope.text.storage.file.tabCat,
      value: 'reports.files-category'
    }, {
      name: $scope.text.storage.file.tabSize,
      value: 'reports.files-topfiles'
    }];

    // when the current tab value changes, change to the appropriate state
    $scope.$watch('currentTab', function(newValue, oldValue) {
      if (newValue !== oldValue) {
        $state.go(newValue, $stateParams);
      }
    });
  }
})(angular);
