import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';
import { Controls, NgxSubFormRemapComponent, subformComponentProviders } from 'ngx-sub-form';
import { TextInputListItemForm } from './text-input-list.model';

/**
 * Component to capture variable number of textual inputs from user.
 */
@Component({
  selector: 'coh-text-input-list',
  templateUrl: './text-input-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: subformComponentProviders(TextInputListComponent),
})
export class TextInputListComponent extends NgxSubFormRemapComponent<string[], TextInputListItemForm> {

  /**
   * Label to show for every input field.
   */
  @Input() label: string;

  /**
   * Maximum number of inputs to capture.
   */
  @Input() limit: number;

  /**
   * Initialize the component with a single text input item.
   */
  initFormControl() {
    this.formGroupControls.listItems.clear();
    this.formGroupControls.listItems.push(this.getNewInputItemFormControl());
  }

  /**
   * Whether to allow addition of new input fields. We show the add button only
   * for the last list items provided that the number of list items hasn't exceeded
   * the specified limit.
   *
   * @param   index   Index of the text input list item.
   * @returns True if add button should appear next to the input list item or not.
   */
  allowAdd(index: number): boolean {
    const numberOfListItems = this.formGroupControls.listItems.length;
    if (index === (numberOfListItems - 1)) {
      return this.limit ? numberOfListItems < this.limit : true;
    }

    return false;
  }

  /**
   * Function to remove a form control item from the text input list.
   *
   * @param   index   Index of the item to be removed.
   */
  public removeListItem(index: number): void {
    if (this.formGroupControls.listItems.length > 1) {
      this.formGroupControls.listItems.removeAt(index);
    } else {
      this.initFormControl();
    }
  }

  /**
   * Function to add a new empty form control to the text input list.
   *
   * @param   index   Index where item should be added.
   */
  public addListItem(index: number): void {
    this.formGroupControls.listItems.insert(index, this.getNewInputItemFormControl());
  }

  protected transformToFormGroup(obj: string[] | null): TextInputListItemForm | null {

    // If the original list is empty, assign a list with a single item in it.
    return {
      listItems: !obj || !obj.length ? [{ value: '' }] : obj.map(o => ({ value: o }))
    };
  }

  /**
   * Get a form control object for an empty text input list item.
   *
   * @retruns New text input list item form control.
   */
  getNewInputItemFormControl() {
    return new UntypedFormControl({ value : '' }, [Validators.required]);
  }

  protected transformFromFormGroup(formValue: TextInputListItemForm): string[] | null {
    return formValue.listItems.map(o => o.value);
  }

  public getDefaultValues(): Partial<TextInputListItemForm> | null {
    return {
      listItems: [],
    };
  }

  protected getFormControls(): Controls<TextInputListItemForm> {
    return {
      listItems: new UntypedFormArray([]),
    };
  }
}
