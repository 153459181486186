// Service: Antivirus Service

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.antivirus')
    .service('AntivirusService', AntivirusServiceFn);

  function AntivirusServiceFn(_, $http, API, cModal, cMessage, evalAJAX,
    SlideModalService, AntivirusServiceFormatter, $q) {

    // These caches are not considered a source of truth, but rather a
    // convenience. They should only be used for lookups or initial renderings,
    // followed by a refresh.
    var cachedListOfSecuredViews = [];
    var cachedListOfAvProviders = [];
    var cachedListOfThreats = [];

    return {
      addAvProvider: addAvProvider,
      addSecuredView: addSecuredView,
      checkIcapConnectionStatus: checkIcapConnectionStatus,
      checkConnectionAndEnableAvProvider: checkConnectionAndEnableAvProvider,
      confirmDeleteAvProvider: confirmDeleteAvProvider,
      deleteAvProvider: deleteAvProvider,
      deleteThreats: deleteThreats,
      editSecuredView: editSecuredView,
      getCachedListOfAvProviders: getCachedListOfAvProviders,
      getCachedListOfSecuredViews: getCachedListOfSecuredViews,
      getCachedListOfThreats: getCachedListOfThreats,
      getAvProvider: getAvProvider,
      getAvProviderList: getAvProviderList,
      getScanTriggerText: getScanTriggerText,
      getSecuredViews: getSecuredViews,
      getThreats: getThreats,
      setAvProviderState: setAvProviderState,
      updateAvProvider: updateAvProvider,
      updateThreats: updateThreats,
    };

    /**
     * Fetches list of Antivirus Provider groups.
     *
     * @method    getAvProviderList
     * @returns   {object}   Promise to return list of providers or error
     */
    function getAvProviderList() {
      return $http({
        method: 'get',
        url: API.public('antivirusGroups'),
      }).then(function fetchedAvProviders(response) {
        return cachedListOfAvProviders = (response.data || [])
          .map(AntivirusServiceFormatter.transformAvProvider);
      });
    }

    /**
     * Returns cached list of AV Provider Groups.
     *
     * @method    getCachedListOfAvProviders
     * @returns   {Array}   List of AV Provider Groups.
     */
    function getCachedListOfAvProviders() {
      return cachedListOfAvProviders;
    }

    /**
     * Fetches a single Antivirus Provider groups.
     *
     * @method    getAvProvider
     * @param     {String}   id     ID of Provider group.
     * @returns   {object}   Promise to return list of providers or error
     */
    function getAvProvider(id) {
      // TODO (David): Remove the following API after Vishal adds support to GET
      // a single Provider group. ~6.3
      return $http({
        method: 'get',
        url: API.public('antivirusGroups'),
      }).then(function fetchedAvProvider(response) {
        return AntivirusServiceFormatter.transformAvProvider(
          _.find((response.data || []), ['id', parseInt(id, 10)]));
      });

      /* return $http({
        method: 'get',
        url: API.public('antivirusGroups', id),
      }).then(function fetchedAvProvider(response) {
        return AntivirusServiceFormatter
          .transformAvProvider(response.data || {});
      }); */
    }

    /**
     * Adds a new Antivirus Provider group
     *
     * @method    addAvProvider
     * @param     {object}   avProvider     new AV Provider group object.
     * @returns   {object}   Promise to return newly added AV Provider.
     */
    function addAvProvider(avProvider) {
      return $http({
        method: 'post',
        url: API.public('antivirusGroups'),
        data: avProvider,
      }).then(function addedAvProvider(response) {
        return AntivirusServiceFormatter
          .transformAvProvider(response.data || {});
      });
    }

    /**
     * Updates an Antivirus Provider group
     *
     * @method    updateAvProvider
     * @param     {object}   avProvider     AV Provider group object.
     * @returns   {object}   Promise to return updated AV Provider.
     */
    function updateAvProvider(avProvider) {
      return $http({
        method: 'put',
        url: API.public('antivirusGroups'),
        data: avProvider,
      }).then(function updatedAvProvider(response) {
        return AntivirusServiceFormatter
          .transformAvProvider(response.data || {});
      });
    }

    /**
     * Deletes a single Antivirus Provider group.
     *
     * @method    deleteAvProvider
     * @param     {String}   id     ID of AV Provider group to delete.
     * @returns   {Object}   Promise to delete AV Provider group
     */
    function deleteAvProvider(id) {
      return $http({
        method: 'delete',
        url: API.public('antivirusGroups', id),
      });
    }

    /**
     * Presents a challenge modal before allowing user to delete an Antivirus
     * Provider group.
     *
     * @method    confirmDeleteAvProvider
     * @param     {Object}   avProvider    AV Provider group to be deleted.
     */
    function confirmDeleteAvProvider(avProvider) {
      var modalConfig = {
        controller: 'DeleteAvProviderCtrl',
        resolve: {
          avProvider: function resolveAvProvider() { return avProvider; }
        },
      };

      var options = {
        titleKey: 'antivirus.delete.provider.title',
        contentKey: 'antivirus.delete.provider.text',
        contentKeyContext: {name: avProvider.name},
        actionButtonKey: 'delete',
        closeButtonKey: 'cancel',
      };

      return cModal.standardModal(modalConfig, options).catch(_.noop);
    };

    /**
     * Sets enabled/disabled state of a single Antivirus Provider group.
     *
     * @method    setAvProviderState
     * @param     {String}   data     request params with id and enable state.
     * @returns   {Object}   Promise to toggle state of AV Provider group.
     */
    function setAvProviderState(data) {
      return $http({
        method: 'put',
        data: data,
        url: API.public('antivirusGroups', 'states'),
      });
    }

    /**
     * Fetches a list of Secured Views
     *
     * @method     getSecuredViews
     * @return     {Object}  Promise to resolve the API request
     */
    function getSecuredViews() {
      var params = {
        // This param filters the result to only include Views with Antivirus
        // configured.
        includeViewsWithAntivirusEnabledOnly: true,
      };

      return $http({
        method: 'get',
        url: API.public('views'),
        params: params,
      }).then(function getSecuredViewsSuccess(response) {
        return cachedListOfSecuredViews = _.map(response.data.views,
          AntivirusServiceFormatter.transformSecuredView);
      });
    }

    /**
     * Returns cached list of Secured Views.
     *
     * @method    getCachedListOfSecuredViews
     * @returns   {Array}   List of secured views.
     */
    function getCachedListOfSecuredViews() {
      return cachedListOfSecuredViews;
    }

    /**
     * Opens a slide modal with "Add Secured View".
     *
     * @method     addSecuredView
     * @return     {Object}  promise to resolve the cSlideModal
     */
    function addSecuredView() {
     return SlideModalService.newModal({
       templateUrl: 'app/antivirus/secured-views/add/add.html',
       controller: 'AddSecuredViewCtrl as $ctrl',
       size: 'xl',
       keyboard: false,
       resolve: {
         // Shared controller for Create/Edit expects a `view` param. Mocking
         // here when instantiating create flow.
         view: undefined,
       }
     });
    }

    /**
     * Opens a slide modal with "Edit Secured View".
     *
     * @method     editSecuredView
     * @return     {Object}  promise to resolve the cSlideModal
     */
    function editSecuredView(view) {
     return SlideModalService.newModal({
       templateUrl: 'app/antivirus/secured-views/add/add.html',
       controller: 'AddSecuredViewCtrl as $ctrl',
       size: 'xl',
       keyboard: false,
       resolve: {
         view: view,
       }
     });
    }

    /**
     * Fetches a list of Virus Threats
     *
     * @method     getThreats
     * @param      {Object}  params  required and optional params
     * @return     {Object}  Promise to resolve the API request
     */
    function getThreats(params) {
      return $http({
        method: 'get',
        url: API.public('infectedFiles'),
        params: params
      }).then(function getThreatsSuccess(response) {
        return cachedListOfThreats = _.map(response.data.infectedFiles,
          AntivirusServiceFormatter.transformAvThreat);
      });
    }

    /**
     * Returns cached list of Infected Files.
     *
     * @method    getCachedListOfThreats
     * @returns   {Array}   List of Infected Files.
     */
    function getCachedListOfThreats() {
      return cachedListOfThreats;
    }

    /**
     * Deletes threatening files.
     *
     * @method    deleteThreat
     * @param     {String}   data     request object params with a list of ids.
     * @returns   {Object}   Promise to delete Threats.
     */
    function deleteThreats(data) {
      return $http({
        method: 'delete',
        data: data,
        url: API.public('infectedFiles'),
      }).then(function deleteThreats(response) {
        return response.data || {};
      });
    }

    /**
     * Updates state of threatening files.
     *
     * @method    updateThreat
     * @param     {object}   data     request object params with a list of ids
     *                                and a state to update.
     * @returns   {object}   Promise to return updated threats.
     */
    function updateThreats(data) {
      return $http({
        method: 'put',
        url: API.public('infectedFiles'),
        data: data,
      }).then(function updateThreats(response) {
        return response.data || {};
      });
    }

    /**
     * Returns the translation key for the corresponding scan trigger.
     *
     * @method    getScanTriggerText
     * @param     {Object}     view     View object.
     * @returns   {String}     translation key.
     */
    function getScanTriggerText(view) {
      var avConfig = view.antivirusScanConfig;

      switch (true) {
        case avConfig.scanOnAccess && avConfig.scanOnClose:
          return 'antivirus.scanOnOpenAndClose';

        case !avConfig.scanOnAccess && avConfig.scanOnClose:
          return 'antivirus.scanOnClose';

        case avConfig.scanOnAccess && !avConfig.scanOnClose:
          return 'antivirus.scanOnOpen';
      }
    }

    /**
     * Fetches connection status for the ICAP servers in an Antivirus Provider
     * group.
     *
     * @method     _getIcapServerStatus
     * @param      {Object}     params     API request object data.
     * @returns    {Object}     Promise with icap server status info.
     */
    function _getIcapServerStatus(params) {
      /* return $q.resolve(
        {
          succeededConnectionStatus: [
            'icap://10.2.52.177:64000/avscan'
          ],
          failedConnectionStatus: [
            'icap://10.2.37.33:596/Symantec2',
            'icap://10.2.37.33:596/Symantec3',
          ],
        }
      );*/
      return $http({
        method: 'get',
        url: API.public('icapConnectionStatus'),
        params: params
      }).then(function getIcapStatusSuccess(response) {
        return AntivirusServiceFormatter.transformIcapStatus(response.data);
      });
    }

    /**
     * Checks the connection status for all of the configured ICAP servers and
     * updates each icap server object in the AV Provider group config.
     *
     * @method    checkIcapConnectionStatus
     * @param     {Object}    providerGroup     config for an AV Provider group.
     * @returns   {Object}    Promise with ICAP server status info.
     */
    function checkIcapConnectionStatus(providerGroup) {
      var requestObj = {
        icapUris: _.reduce(providerGroup.antivirusServices,
          function reduceIcapUrl(acc, item) {
            return [].concat(acc, item.icapUri);
          }, []
        ),
      };

      return _getIcapServerStatus(requestObj).then(
        function checkIcapServerStatusSuccess(icapStatus) {
          // Parse the list of ICAP server results and update the _verified
          // property for each.
          providerGroup.antivirusServices.forEach(
            function forEachIcapServer(server) {
              server._verified =
                icapStatus.succeededConnectionStatus.includes(server.icapUri);
            }
          );

          // This property indicates that status has been fetched at least once.
          providerGroup._fetchedIcapStatus = true;

          // Returns true or false, indicating 100% success or not. This value
          // is consumed when attempting to enable the AN Provider group. That
          // action will proceed only if all ICAP servers have a verified
          // connection.
          return providerGroup._isIcapVerified =
            !(icapStatus.failedConnectionStatus || []).length;
        },
        evalAJAX.errorMessage
      );
    }

    /**
     * Checks the connection status of all ICAP servers, and if verified then
     * enables the Antivirus Provider.
     *
     * @method    checkConnectionAndEnableAvProvider
     * @param     {Object}     avProvider    AV Provider group to be toggled.
     * @param     {Function}   callback    Callback function after success.
     * @returns   {Object}     Promise to check ICAP server status.
     */
    function checkConnectionAndEnableAvProvider(avProvider, callback) {
      return checkIcapConnectionStatus(avProvider).then(
        function precheckIcapStatusSuccess(icapStatus) {
          if (!icapStatus) {
            cMessage.error({
              textKey: 'antivirus.failedToEnable',
            });
            return icapStatus;
          }
          if (_.isFunction(callback)) {
            callback(avProvider);
          }
        },
        evalAJAX.errorMessage
      );
    }

  }
})(angular);
