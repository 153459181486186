import { Injectable } from '@angular/core';
import { flagEnabled, IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { StateObject, Transition } from '@uirouter/core';
import { Environment } from 'src/app/shared/constants';

import { StateManagementService } from '../../services';
import { GuardPriority, GuardResult, StateGuard } from '../state-guard';

/**
 * Internal interface for representing a re-direct state
 */
interface RedirectStateObject {
  /**
   * The state name
   */
  state: string;

  /**
   * Specifies if the required feature flag(s) for the state is enabled if applicable
   * (default: true for non flag based states)
   */
  isFlagEnabled: boolean;

  /**
   * Applicable workload param for the state
   * (default: null for non-applicable states)
   */
  workload: string;
}

/**
 * Array of states to match on for transition check.
 */
const matchStates = [
  'source-details-ng.objects',
  'source-details-ng.connections',
  'source-details-ng.settings'
];
/**
 * Source details Guard redirects all state traffic to the relevant details page based on the
 * Feature flag state for Azure workloads dmsAzureVmWorkload, dmsAzureSqlWorkload and dmsAzureEntraIdWorkload.
 *
 * TODO: This guard should be deprecated at some point in future or removed once there's a confirmation of full
 *  feature availability (i.e flag set to true in all envs),
 *  with exceptions that the guard implementation will be extended
 */
@Injectable({
  providedIn: 'root'
})
export class AzureSourceDetailsGuard implements StateGuard {
  /**
   * This guard priority is set to App, which indicates it should run as part of the main application
   * after all bootstrapping and initializing is completed.
   */
  guardPriority = GuardPriority.App;

  /**
   * Match all navigation to source details
   */
  matchCriteria = {
    to: (state: StateObject, transition: Transition) => {
      const matchesState = matchStates.includes(transition.targetState().name());
      const params = transition.params();
      return matchesState && (params?.environment === Environment.kAzure);
    },
  };

  /**
   * Constructor.
   */
  constructor(private irisCtx: IrisContextService, private stateManagementService: StateManagementService) {}

  /**
   * Handles the re-direction to the new source details page based on feature flag.
   *
   * @param    transition   The UI Router Transition.
   * @returns  A redirect transition if new sources details page is enabled.
   */
  onStart(transition: Transition): GuardResult {
    if (!isDmsScope(this.irisCtx.irisContext) || transition.options()?.reload) {
      return;
    }

    const targetName = transition.targetState().name();
    const params = transition.params();
    if (this.stateManagementService.canUserAccessState(targetName, params)) {
      const redirectState = this.getAzureSourceRedirectState(targetName, params);

      // Get silent param changes due to notify transition option being set to false
      const workloadParam = (transition.paramsChanged()?.workload || params?.wokload) ?? null;
      const isExactState = (redirectState?.state === targetName) &&
        (workloadParam === redirectState.workload);

      // If target state is not the same, then redirect otherwise proceed with transition
      if(redirectState && !isExactState && this.stateManagementService.canUserAccessState(redirectState.state)) {
        return transition.router.stateService.target(redirectState.state, {
          ...params,
          workload: redirectState.workload
        }, {
          ...transition.options(),
          location: 'replace'
        });
      }
    }
    return;
  }

  /**
   * Returns the relevant state for redirection
   *
   * @param targetStateName The target state name
   * @param params Route params
   */
  private getAzureSourceRedirectState(targetStateName: string, params: any): RedirectStateObject {
    // In DMS Context,
    // the Azure source details has the following 4 tabs in this order: VM | SQL | Connections | Settings
    const tabsStates: RedirectStateObject[] = [
      { state: 'source-details-ng.objects',
        workload: 'kVirtualMachine',
        isFlagEnabled: flagEnabled(this.irisCtx.irisContext, 'dmsAzureVmWorkload')
      },
      { state: 'source-details-ng.objects',
        workload: 'kSQLDatabase',
        isFlagEnabled: flagEnabled(this.irisCtx.irisContext, 'dmsAzureSqlWorkload')
      },
      { state: 'source-details-ng.objects',
        workload: 'kAzureEntraID',
        isFlagEnabled: flagEnabled(this.irisCtx.irisContext, 'dmsAzureEntraIdWorkload')
      },
      { state: 'source-details-ng.connections', workload: null, isFlagEnabled: true },
      { state: 'source-details-ng.settings', workload: null, isFlagEnabled: true }
    ];

    // Check if currentState is navigable otherwise get a first navigable fallback state
    // and then navigate to that state
    let fallBackState = null;
    let stateMatch = null;
    while (!fallBackState || !stateMatch && tabsStates.length) {
      const tabState = tabsStates.shift();
      const { state, workload, isFlagEnabled} = tabState;
      const stateFound = targetStateName === state && (!params.workload || (params.workload === workload));
      if(stateFound && isFlagEnabled) {
        stateMatch = tabState;
        break;
      } else if(isFlagEnabled && !fallBackState) {
        // Assign the first fallback state matched
        fallBackState = tabState;
      }
    }
    return stateMatch ?? fallBackState;
  }
}
