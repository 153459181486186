<cog-tree-select-value-property-filter
  *cogFilterDef="let filter;
    let params = params;
    quickFilter: true;
    key: property;
    label: label;
    filterType: 'valueProperty';
    position: position"
  [preLabel]="preLabel"
  [filterDefParams]="params"
  [filterValues]="filterOptions$ | async"
  [filter]="filter"
  [trackFilters]="true"
  [autoApply]="true"
  [showExpandCollapse]="true">
</cog-tree-select-value-property-filter>
