import {
  AndExpContext,
  AndOperatorContext,
  AtomExprContext,
  GcpLexer,
  GcpParser,
  GcpVisitor,
  KeyContext,
  OperatorContext,
  OrExpContext,
  OrOperatorContext,
  ParenExpContext,
  ParseContext,
  ValueContext,
} from '@cohesity/grammar/gcp-volume-exclusion';
import {
  CursorLocation,
  getLeafNodes,
  getNodeLocation,
  getParent,
  getResults,
  getSelectedNode,
  GrammarOpts,
  Result,
  Suggestion,
  SuggestionType,
} from '@cohesity/helix';
import { CharStreams, CommonTokenStream, ParserRuleContext, Vocabulary } from 'antlr4ts';
import { AbstractParseTreeVisitor, ErrorNode, TerminalNode } from 'antlr4ts/tree';

/**
 * The map of precomputed search suggestion.
 */
export type SuggestionMap = Record<
'andOperator' |
'key' |
'orOperator' |
'operator' |
'value' ,
 Suggestion
>;

/**
 * The possible gcp key values.
 */
export enum GcpExclusionKeys {
  Type = 'Type',
  Name = 'Name',
  Label = 'Label',
}

/**
 * The possible value for open params.
 */
export const openParenResult: Result = { label: 'OPEN_PAREN', value: '(' };

 /**
  * The possible value for close params.
  */
export const closeParenResult: Result = { label: 'CLOSE_PAREN', value: ')' };


/**
 * The GCP grammar suggestion visitor.
 * All the example comments given follow the pattern below:
 * 1. '|' represents the cursor location in the current input.
 * 2. The string represnts the current input given by the user.
 * On the basis of the cursor position and given input, we return appropriate suggestions.
 *
 * Eg. 1
 * example "uniqu|eTag = 'Cohesity-1' "
 * This represents that the user has typed this string in the input: uniqueTag = 'Cohesity-1'.
 * And they have placed the cursor at position 6 => uniqu|eTag = 'Cohesity-1'.
 * So we return the suggestions for the keys.
 *
 * Eg. 2
 * example "uniqueTag =| 'Cohesity-1' AND name = 'GcpEcxlude'"
 * This represents that the user has typed this string in the input:
 * uniqueTag = 'Cohesity-1' AND name = 'GcpEcxlude'.
 * And they have placed the cursor at position 12 => uniqueTag =| 'Cohesity-1' AND name = 'GcpEcxlude'.
 * So we return the suggestions for the operator.
 */
export class GcpSuggestionVisitor extends AbstractParseTreeVisitor<Suggestion> implements GcpVisitor<Suggestion> {
  /**
   * Get the suggestion for ParenExpContext.
   *
   * @param type The suggestion type.
   * @param parenExp The ParenExpContext node.
   * @param selected The selected node within ParenExpContext node.
   * @returns The suggestion for ParenExpContext.
   */
   static getParenExp(type: SuggestionType, parenExp?: ParserRuleContext, selected?: ParserRuleContext): Suggestion {
    const selectedChildIndex = parenExp.children.findIndex(child => child === selected);
    // For empty search query, node will absent.

    // For all cases below, we return this suggestion.
    // example "|"
    // example "    |    "
    // example "        |"
    // example "|        "
    if (selectedChildIndex === 0) {
      return {
        results: [{ label: 'OPEN_PAREN', value: '(' }],
        type,
        text: selected?.text,
        textLocation: getNodeLocation(selected),
      };
    }

    return {
      results: [{ label: 'CLOSE_PAREN', value: ')' }],
      type,
      text: selected?.text,
      textLocation: getNodeLocation(selected),
    };
  }


  /**
   * Get the suggestion for LiteralKeyContext.
   *
   * @param type The suggestion type.
   * @param node The node.
   * @returns The suggestion for LiteralKeyContext.
   */
  static getKey(type: SuggestionType, node?: ParserRuleContext): Suggestion {
    // for empty search query node will absent.
    // example "(    type|"
    // example "(    ty|pe"
    // example "(    |type"
    // example "(  |  type"
    return {
      ...precomputedSuggestion.key,
      type,

      // the selected node text which is used to highlight & filter the options.
      text: node?.text || '',

      // when text location is empty then above text will be added at the end of the search query.
      textLocation: node ? getNodeLocation(node) : null,
      field: 'key',
    };
  }

  /**
   * Get the suggestion for OperatorContext.
   *
   * @param type The suggestion type.
   * @param node The node.
   * @returns The suggestion for OperatorContext.
   */
  static getOperator(type: SuggestionType, node: ParserRuleContext): Suggestion {
    // example "( type =| value"
    // example "( type |= value"
    // example "( type |    = value"
    // example "( type ~| value"
    // example "( type |~ value"
    // example "( type |    ~ value"
    return {
      ...precomputedSuggestion.operator,
      type,
      text: node?.text,
      textLocation: getNodeLocation(node),
    };
  }

  /**
   * Get the suggestion for ValueContext.
   *
   * @param type The suggestion type.
   * @param parent The parent node.
   * @param selected The selected node.
   * @returns The suggestion for ValueContext.
   */
  static getValue(type: SuggestionType, parent: ParserRuleContext, selected: ParserRuleContext): Suggestion {
    const atomExprCtx = getParent(parent, [AtomExprContext]);
    // example "( type = value|"
    // example "( type = valu|e"
    // example "( type = v|alue"
    // example "( type = |value"
    // example "( type = |         value"
    return {
      ...precomputedSuggestion.value,
      type,
      text: selected?.text,
      textLocation: getNodeLocation(selected),
      field: atomExprCtx.getChild(0).text,
    };
  }

  /**
   * Get the suggestion for OrOperatorContext.
   *
   * @param  type The suggestion type.
   * @param  node The node.
   * @return      The suggestion for OrOperatorContext.
   */
  static getOrOperator(type: SuggestionType, node?: ParserRuleContext): Suggestion {
    // example "( type = value ) O|R "
    // example "( type = value ) |OR "
    return {
      ...precomputedSuggestion.orOperator,
      type,
      text: node?.text || '',
      textLocation: node ? getNodeLocation(node) : null,
    };
  }

  /**
   * Get the suggestion for AndOperatorContext.
   *
   * @param  type The suggestion type.
   * @param  node The node.
   * @return      The suggestion for AndOperatorContext.
   */
  static getAndOperator(type: SuggestionType, node?: ParserRuleContext): Suggestion {
    // example "( type = value | "
    // example "( type = value |AND) "
    // example "( type = value A|ND) "
    // example "( type = value AN|D) "
    return {
      ...precomputedSuggestion.andOperator,
      type,
      text: node?.text || '',
      textLocation: node ? getNodeLocation(node) : null,
    };
  }

  constructor(private searchQuery: string, private cursorLocation: CursorLocation, private vocabulary: Vocabulary,
    private hasException: boolean) {
    super();
  }


  /**
   * Return the default suggestion.
   */
  defaultResult(): Suggestion {
    return null;
  }

  /**
   * Return the aggregate suggestion.
   *
   * @param aggregate The current suggestion.
   * @param nextResult The next suggestion.
   * @returns Return the aggregate suggestion.
   */
  aggregateResult(aggregate: Suggestion, nextResult: Suggestion): Suggestion {
    return nextResult ?? aggregate;
  }

  /**
   * Visit the AST parse node.
   *
   * @param ctx The parser AST node.
   * @returns The search suggestion.
   */
  visitParse(ctx: ParseContext): Suggestion {
    const isEmpty = !(this.searchQuery || '').trim().length;
    if (isEmpty) {
      // example "|"
      // example "    |    "
      // example "        |"
      // example "|        "
      return {
        results: [openParenResult],
        type: SuggestionType.success,
        text: '',
        textLocation: null,
      };
    }

    const leafNodes = getLeafNodes(ctx);
    const selected = getSelectedNode(leafNodes, this.cursorLocation);
    const parent = selected?.parent;
    const hasException = !!ctx.exception || this.hasException;
    const exceptionNode = ctx?.exception?.context as ParserRuleContext;
    const type = hasException ? SuggestionType.error : SuggestionType.success;
    const exprNode = getParent(selected, [
      OrExpContext, ParenExpContext]) as OrExpContext | ParenExpContext;
    const expNode = getParent(selected, [
      AndExpContext, AtomExprContext]) as AndExpContext | AtomExprContext;

    if (hasException) {
      switch (true as boolean) {
        case exprNode instanceof ParenExpContext && expNode instanceof AtomExprContext: {
          if (parent instanceof KeyContext) {
            // example "(   | "
            // example "(    type|"
            // example "(    ty|pe"
            // example "(    |type"
            // example "(  |  type"
            return GcpSuggestionVisitor.getKey(type, selected);
          } else if (selected instanceof OperatorContext) {
            // example "( type =| value"
            // example "( type |= value"
            // example "( type |    = value"
            // example "( type ~| value"
            // example "( type |~ value"
            // example "( type |    ~ value"
            return GcpSuggestionVisitor.getOperator(type, selected);
          } else if (selected instanceof ValueContext) {
            // example "( type = value|"
            // example "( type = valu|e"
            // example "( type = v|alue"
            // example "( type = |value"
            // example "( type = |         value"
            return GcpSuggestionVisitor.getValue(type, selected, selected);
          } else if (parent instanceof AtomExprContext && selected instanceof KeyContext) {
            // example "(   | "
            // example "(    type|"
            // example "(    ty|pe"
            // example "(    |type"
            // example "(  |  type"
            return GcpSuggestionVisitor.getKey(type, selected);
          }
          break;
        }

        // example "(type = value) OR |"
        // example "(type = value) OR   |"
        // example "(type = value) OR |("
        // example "|(type = value) OR  "
        case exprNode instanceof OrExpContext && exceptionNode instanceof ParenExpContext:
        case parent instanceof OrExpContext && selected instanceof ParenExpContext:
        case parent instanceof OrExpContext && selected instanceof TerminalNode:
        case parent instanceof ParenExpContext && exceptionNode instanceof ParenExpContext: {
          return {
            results: [openParenResult],
            type: SuggestionType.error,
            text: '',
            textLocation: null,
          };
        }

        case parent instanceof ParseContext && selected instanceof ErrorNode: {
          // This is to handle cases when something is not within parenthesis.
          // For handling  OR, AND, = , .... or any token value.
          // example "a |"
          // example "OR |"
          // example ", |"
          return {
            results: [],
            type: SuggestionType.error,
            text: '',
            textLocation: null,
          };
        }

        case parent instanceof ParseContext && selected instanceof TerminalNode: {
          // example "( type = value   |"
          // example "( type = value |"
          return {
            results: [
              ...precomputedSuggestion.andOperator.results,
              { label: 'CLOSE_PAREN', value: ')' },
            ],
            type,
            text: ' ',
            textLocation: null,
          };
        }

      }
    }

    switch (true as boolean) {

      case parent instanceof OperatorContext: {
        return GcpSuggestionVisitor.getOperator(type, selected);
      }

      case parent instanceof ValueContext: {
        return GcpSuggestionVisitor.getValue(type, parent, selected);
      }

      case parent instanceof AndOperatorContext: {
        return GcpSuggestionVisitor.getAndOperator(type, selected);
      }

      case parent instanceof ParenExpContext: {
        return GcpSuggestionVisitor.getParenExp(type, parent, selected);
      }

      case parent instanceof OrOperatorContext: {
        return GcpSuggestionVisitor.getOrOperator(type, selected);
      }

      case parent instanceof KeyContext: {
        return GcpSuggestionVisitor.getKey(type, selected);
      }

      case parent instanceof ParseContext: {
        // example "( Name = Gcp ) |"
        return GcpSuggestionVisitor.getOrOperator(type, selected);
      }
    }

    return this.visitChildren(ctx);
  }
}

/**
 * GCP grammar options for advance search.
 */
export class GcpGrammarOpts extends GrammarOpts<GcpLexer, GcpParser, ParseContext> {
  /**
   * The GCP grammar vocabulary.
   */
  vocabulary = GcpParser.VOCABULARY;

  /**
   * Get the GCP grammar lexer.
   *
   * @param  searchQuery The search query.
   * @return             The grammar lexer.
   */
  lexer(searchQuery: string): GcpLexer {
    const chars = CharStreams.fromString(searchQuery);
    return new GcpLexer(chars);
  }

  /**
   * Get the Gcp grammar parser.
   *
   * @param  lexer The grammar's lexer.
   * @return       The grammar parser.
   */
  parser(lexer: GcpLexer): GcpParser {
    const tokens = new CommonTokenStream(lexer);
    return new GcpParser(tokens);
  }

  /**
   * Get the parsed abstract syntax tree(AST) for GCP grammar.
   *
   * @param  parser The grammar's parser.
   * @return        The parsed abstract syntax tree(AST).
   */
  tree(parser: GcpParser): ParseContext {
    return parser.parse();
  }

  /**
   * Get suggestion by visiting the abstract syntax tree(AST).
   *
   * @param  searchQuery    The search query.
   * @param  cursorLocation The cursor location.
   * @param  tree           The parsed abstract syntax tree(AST).
   * @return                The suggestion.
   */
  visit(searchQuery: string, cursorLocation: CursorLocation, tree: ParseContext): Suggestion {
    // We need to check for this.getErrorMessage() in order to look for errors from lexer.
    const hasException = !!tree.exception || !!this.getErrorMessage();
    const visitor = new GcpSuggestionVisitor(searchQuery, cursorLocation, this.vocabulary, hasException);
    return visitor.visit(tree);
  }

  /**
   * Get the error message from report by ANTLR DefaultErrorStrategy.
   *
   * @return The error message.
   */
  getErrorMessage(): string {
    return this.errorListener.message;
  }
}

/**
 * Returns the map of pre computed suggestions (possible value formats).
 */
export const precomputedSuggestion: Partial<SuggestionMap> = (() => {
  const gcpGrammarOpts = new GcpGrammarOpts();
  const parser = gcpGrammarOpts.getParser('');
  const extractSuggestion = (node: ParserRuleContext): Suggestion => ({
    type: null,
    text: null,
    results: getResults(node.exception, gcpGrammarOpts.vocabulary),
  });

  const out: Partial<SuggestionMap> = {
    orOperator: (() => extractSuggestion(parser.orOperator()))(),
    andOperator: (() => extractSuggestion(parser.andOperator()))(),
    key: (() => extractSuggestion(parser.key()))(),
    value: (() => extractSuggestion(parser.value()))(),
    operator: (() => extractSuggestion(parser.operator()))(),
  };
  return Object.freeze(out);
})();
