import { PhysicalVolume } from '@cohesity/api/v1';

/**
 * Determines whether the user has permission to modify the source.
 */
export function hasSourceModifyPrivilege(privileges: object, sourceType: string): boolean {

  if (!privileges['PROTECTION_SOURCE_MODIFY']) {
    return false;
  }
  /**
   * Mapping of the individual source privileges to the parent source group.
   */
  const sourcePrivileges = {
    hypervisor: [
      'MANAGE_PROTECTION_SOURCE_ACROPOLIS_STANDALONECLUSTER',
      'MANAGE_PROTECTION_SOURCE_AWS_IAMUSER',
      'MANAGE_PROTECTION_SOURCE_AZURE_SUBSCRIPTION',
      'MANAGE_PROTECTION_SOURCE_GCP_IAMUSER',
      'MANAGE_PROTECTION_SOURCE_HYPERV_SCVMMSERVER',
      'MANAGE_PROTECTION_SOURCE_HYPERV_STANDALONECLUSTER',
      'MANAGE_PROTECTION_SOURCE_HYPERV_STANDALONEHOST',
      'MANAGE_PROTECTION_SOURCE_KVM_OVIRTMANAGER',
      'MANAGE_PROTECTION_SOURCE_VMWARE_STANDALONEHOST',
      'MANAGE_PROTECTION_SOURCE_VMWARE_VCENTER',
      'MANAGE_PROTECTION_SOURCE_VMWARE_VCLOUDDIRECTOR',
    ],
    physical: ['MANAGE_PROTECTION_SOURCE_PHYSICAL'],
    o365: ['MANAGE_PROTECTION_SOURCE_O365_DOMAIN'],
    sql: ['MANAGE_PROTECTION_SOURCE_SQL'],
    oracle: ['MANAGE_PROTECTION_SOURCE_ORACLE'],
    storageArray: [
      'MANAGE_PROTECTION_SOURCE_NIMBLE_STORAGEARRAY',
      'MANAGE_PROTECTION_SOURCE_PURE_STORAGEARRAY',
    ],
    storageSnapshot: ['MANAGE_PROTECTION_SOURCE_HYPERFLEX_SERVER'],
    nas: [
      'MANAGE_PROTECTION_SOURCE_ELASTIFILE_CLUSTER',
      'MANAGE_PROTECTION_SOURCE_FLASHBLADE_STORAGEARRAY',
      'MANAGE_PROTECTION_SOURCE_GENERICNAS_HOST',
      'MANAGE_PROTECTION_SOURCE_GPFS_CLUSTER',
      'MANAGE_PROTECTION_SOURCE_ISILON_CLUSTER',
      'MANAGE_PROTECTION_SOURCE_NETAPP_CLUSTER',
      'MANAGE_PROTECTION_SOURCE_NETAPP_VSERVER',
    ],
    ad: ['MANAGE_PROTECTION_SOURCE_AD']
  };

  const sourcePrivs = sourcePrivileges[sourceType];
  return privileges['MANAGE_PROTECTION_SOURCE_ALL'] || sourcePrivs.some(priv => privileges[priv]);
}

/**
 * Filters out volume based on a type.
 *
 * @param volumes List of all volumes
 * @param type Type of volumes that are to be returned
 */
export function filterVolumes(volumes: PhysicalVolume[], type: string): PhysicalVolume[] {
  return (volumes || []).filter(volume => {
    switch (type) {
      case 'shared':
        return volume.isSharedVolume;
      case 'lvm':
        return !!volume.guid;
      default:
        return !volume.guid && !volume.isSharedVolume;
    }
  });
}
