import { Injectable } from '@angular/core';
import { flagEnabled, IrisContextService, isMcm } from '@cohesity/iris-core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalSearchResultType } from '../models/global-search-result-type.model';

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchResultsTypeService {
  /**
   * Whether selecting different results type is enabled. Global Search
   * currently supports Objects search (on OnPrem and Helios) and Files search
   * (only Helios).
   */
  enableResultsType$ = this.irisContextService.irisContext$.pipe(
    map(value =>
      flagEnabled(value, isMcm(value) ? 'ngGlobalSearchHeliosFileSearch' : 'ngGlobalSearchSingleClusterFileSearch')
    ),
  );

  /**
   * Behavior subject for the currently selected type.
   */
  private _selectedType$ = new BehaviorSubject<GlobalSearchResultType>(GlobalSearchResultType.Object);

  /**
   * Observable for the currently selected type.
   */
  selectedType$: Observable<GlobalSearchResultType> = this._selectedType$.asObservable();

  /**
   * Function to get the type for search results.
   */
  get selectedType(): GlobalSearchResultType {
    return this._selectedType$.value;
  }

  /**
   * Function to set the type for search results.
   *
   * @param value The type.
   */
  set selectedType(value: GlobalSearchResultType) {
    if (!value || this._selectedType$.value === value) {
      return;
    }

    this._selectedType$.next(value);
  }

  /**
   * Helper utility to determine whether the active search is file search.
   */
  isFileSearch$: Observable<boolean> = this.selectedType$.pipe(map(value => value === GlobalSearchResultType.File));

  /**
   * Helper utility to determine whether the active search is object search.
   */
  isObjectSearch$: Observable<boolean> = this.selectedType$.pipe(map(value => value === GlobalSearchResultType.Object));

  constructor(private irisContextService: IrisContextService) {}
}
