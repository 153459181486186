import { Injectable } from '@angular/core';
import { RigelGroup } from '@cohesity/api/rms';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { ConnectionConfig, SourceServiceApi } from '@cohesity/api/v2';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { filterTreeHierarchy } from 'src/app/modules/restore/hypervisor-shared/azure/shared/recover-vm-azure.utils';
import { DmsConnectionService } from 'src/app/modules/sources/shared';
import { McmSourceWithRmsConnections } from '../azure-saas-connection-form.model';

@Injectable()
export class AzureSaasConnectionFormService {
  constructor(
    private dmsConnectionService: DmsConnectionService,
    private heliosSourceService: SourceServiceApi,
  ) {
  }

  /**
   * Fetches Azure source registration details with valid active connections
   *
   * @param sourceRegId The source registration Id
   * @param regionId The source regionId
   */
  fetchSourceRegistrationData(sourceRegId: string, regionId: string): Observable<McmSourceWithRmsConnections> {
    return this.heliosSourceService.McmGetProtectionSourceRegistration(sourceRegId, regionId).pipe(
      switchMap((response) => {
        if (response.connections?.length) {
          return this.dmsConnectionService.getConnections(null, response.connections)
            .pipe(map((conns) => {
              // Utilize only source connections available in response from the dmsConnections api
              const dmsConnectionIds = conns.reduce((setData, conn) => setData.add(conn.groupId), new Set());
              const connections = [];
              let unlinkedConnections = 0;
              response.connections.forEach(conn => {
                if(dmsConnectionIds.has(conn.connectionId)) {
                  connections.push(conn);
                } else {
                  unlinkedConnections++;
                }
              });
              return {
                ...response,
                connections,
                hasUnlinkedConnections: (unlinkedConnections > 0),
                rmsConnections: conns
              } as McmSourceWithRmsConnections;
            }));
        }
        return of(response as McmSourceWithRmsConnections);
      }),
    );
  }

  /**
   * Returns the connections belonging to a subscription from the source details
   *
   * @param subscription The subscription
   * @param sourceConnections The source connections
   * @param source The source protection node with descendant hierarchy nodes
   */
  extractSubscriptionConnections(subscription: string, sourceConnections: ConnectionConfig[],
    source: ProtectionSourceNode): ConnectionConfig[] {
    const entityConnectionMap = sourceConnections.reduce((val, connection) => {
      val.set(connection.entityId, connection);
      return val;
    }, new Map<number, ConnectionConfig>());

    const connections = [];
    const subRegionData = filterTreeHierarchy(source.nodes,
      ['kSubscription', 'kRegion', 'kVirtualNetwork'], null, true);

    const subscriptionNode = subRegionData.find(sub => sub.protectionSource.name === subscription);
    if (subscriptionNode) {
      subscriptionNode.nodes.forEach((regionNode: ProtectionSourceNode) => {
        // If the connection type is region add connection,
        // check the regions virtualt Networks if it belongs there as well for vnet connections
        if (entityConnectionMap.has(regionNode.protectionSource.id)) {
          connections.push(entityConnectionMap.get(regionNode.protectionSource.id));
        }
        if (regionNode.nodes?.length) { // Check region vnets if available
          regionNode.nodes.forEach((vnet: ProtectionSourceNode) => {
            if (entityConnectionMap.has(vnet.protectionSource.id)) {
              connections.push(entityConnectionMap.get(vnet.protectionSource.id));
            }
          });
        }
      });
    }
    return connections;
  }

  /**
   * Cleans up the connection array attribute response from the api before use in UI models
   *
   * @param connectionDetail The connection detail api model
   * @return A cleaned connection detail object
   */
  cleanConnectionResponse(connectionDetail: RigelGroup): RigelGroup {
    const cleanArray = (arr: string[]) => arr?.length ? arr.filter(Boolean) : [];
    if (connectionDetail.rigelCloudInfraInfo?.azureRigelInfraInfo) {
      const {
        ntpServers,
        dnsServers,
        applicationSecurityGroup,
        managedIdentity
      } = connectionDetail.rigelCloudInfraInfo.azureRigelInfraInfo;
      connectionDetail.rigelCloudInfraInfo.azureRigelInfraInfo = {
        ...connectionDetail.rigelCloudInfraInfo?.azureRigelInfraInfo,
        ...{
          ntpServers: cleanArray(ntpServers),
          dnsServers: cleanArray(dnsServers),
          applicationSecurityGroup: cleanArray(applicationSecurityGroup),
          managedIdentity: cleanArray(managedIdentity)
        }
      };
    }
    return connectionDetail;
  }
}
