/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { User } from '../models/user';
import { DeleteMcmUserParameters } from '../models/delete-mcm-user-parameters';
import { McmApiKeyInfoList } from '../models/mcm-api-key-info-list';
import { McmApiKeyParams } from '../models/mcm-api-key-params';
import { McmApiKeyInfo } from '../models/mcm-api-key-info';
@Injectable({
  providedIn: 'root',
})
class HeliosUsersService extends __BaseService {
  static readonly GetMcmUsersOpPath = '/public/mcm/users';
  static readonly GetMcmUsersByIdOpPath = '/public/mcm/users/{sid}';
  static readonly UpdateMcmUserPath = '/public/mcm/users/{sid}';
  static readonly DeleteMcmUserPath = '/public/mcm/users/{sid}';
  static readonly McmGetApiKeysPath = '/public/mcm/users/{sid}/apiKeys';
  static readonly McmAddApiKeyPath = '/public/mcm/users/{sid}/apiKeys';
  static readonly McmGetApiKeyPath = '/public/mcm/users/{sid}/apiKeys/{keyId}';
  static readonly McmDeleteApiKeyPath = '/public/mcm/users/{sid}/apiKeys/{keyId}';
  static readonly UserResetPasswordBySidPath = '/public/mcm/users/{sid}/resetPassword';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns the list of Helios users.
   * @param params The `HeliosUsersService.GetMcmUsersOpParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `objectClasses`: Filter list of users with given list of Object Classes.
   *
   * - `impersonatingTenantIds`: ImpersonatingTenantIds filters users to fetch those that have right to
   *   impersonate following tenantIds.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * @return Success
   */
  GetMcmUsersOpResponse(params: HeliosUsersService.GetMcmUsersOpParams): __Observable<__StrictHttpResponse<Array<User>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.objectClasses || []).forEach(val => {if (val != null) __params = __params.append('objectClasses', val.toString())});
    (params.impersonatingTenantIds || []).forEach(val => {if (val != null) __params = __params.append('impersonatingTenantIds', val.toString())});
    if (params.allUnderHierarchy != null) __params = __params.set('allUnderHierarchy', params.allUnderHierarchy.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/mcm/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<User>>;
      })
    );
  }
  /**
   * Returns the list of Helios users.
   * @param params The `HeliosUsersService.GetMcmUsersOpParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `objectClasses`: Filter list of users with given list of Object Classes.
   *
   * - `impersonatingTenantIds`: ImpersonatingTenantIds filters users to fetch those that have right to
   *   impersonate following tenantIds.
   *
   * - `allUnderHierarchy`: AllUnderHierarchy specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * @return Success
   */
  GetMcmUsersOp(params: HeliosUsersService.GetMcmUsersOpParams): __Observable<Array<User>> {
    return this.GetMcmUsersOpResponse(params).pipe(
      __map(_r => _r.body as Array<User>)
    );
  }

  /**
   * Returns the Helios user.
   * @param sid Specifies a unique id of the user.
   * @param regionId This field represents the region id.
   * @return Success
   */
  GetMcmUsersByIdOpResponse(sid: number,
    regionId?: string): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/mcm/users/${encodeURIComponent(sid)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * Returns the Helios user.
   * @param sid Specifies a unique id of the user.
   * @param regionId This field represents the region id.
   * @return Success
   */
  GetMcmUsersByIdOp(sid: number,
    regionId?: string): __Observable<User> {
    return this.GetMcmUsersByIdOpResponse(sid, regionId).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * Update an exisiting Helios User.
   *
   * Returns the updated user.
   * @param sid Specifies a unique id of the user.
   * @param body Request to create or update mcm user.
   * @return Success
   */
  UpdateMcmUserResponse(sid: number,
    body: User): __Observable<__StrictHttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/mcm/users/${encodeURIComponent(sid)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<User>;
      })
    );
  }
  /**
   * Update an exisiting Helios User.
   *
   * Returns the updated user.
   * @param sid Specifies a unique id of the user.
   * @param body Request to create or update mcm user.
   * @return Success
   */
  UpdateMcmUser(sid: number,
    body: User): __Observable<User> {
    return this.UpdateMcmUserResponse(sid, body).pipe(
      __map(_r => _r.body as User)
    );
  }

  /**
   * Delete a Helios User.
   * @param sid Specifies a unique id of the user.
   * @param Body undefined
   */
  DeleteMcmUserResponse(sid: string,
    Body: DeleteMcmUserParameters): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = Body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/mcm/users/${encodeURIComponent(sid)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a Helios User.
   * @param sid Specifies a unique id of the user.
   * @param Body undefined
   */
  DeleteMcmUser(sid: string,
    Body: DeleteMcmUserParameters): __Observable<null> {
    return this.DeleteMcmUserResponse(sid, Body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get API Keys for a Helios user.
   *
   * Get API Keys for a Helios user.
   * @param sid Name for the API Key.
   * @param regionId This field represents the region id.
   * @return Success
   */
  McmGetApiKeysResponse(sid: string,
    regionId?: string): __Observable<__StrictHttpResponse<McmApiKeyInfoList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/mcm/users/${encodeURIComponent(sid)}/apiKeys`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmApiKeyInfoList>;
      })
    );
  }
  /**
   * Get API Keys for a Helios user.
   *
   * Get API Keys for a Helios user.
   * @param sid Name for the API Key.
   * @param regionId This field represents the region id.
   * @return Success
   */
  McmGetApiKeys(sid: string,
    regionId?: string): __Observable<McmApiKeyInfoList> {
    return this.McmGetApiKeysResponse(sid, regionId).pipe(
      __map(_r => _r.body as McmApiKeyInfoList)
    );
  }

  /**
   * Add API Keys for a Helios user.
   *
   * Add API Keys for a Helios user.
   * @param params The `HeliosUsersService.McmAddApiKeyParams` containing the following parameters:
   *
   * - `sid`: Name for the API Key.
   *
   * - `Body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * @return Success
   */
  McmAddApiKeyResponse(params: HeliosUsersService.McmAddApiKeyParams): __Observable<__StrictHttpResponse<McmApiKeyInfoList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.Body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/mcm/users/${encodeURIComponent(params.sid)}/apiKeys`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmApiKeyInfoList>;
      })
    );
  }
  /**
   * Add API Keys for a Helios user.
   *
   * Add API Keys for a Helios user.
   * @param params The `HeliosUsersService.McmAddApiKeyParams` containing the following parameters:
   *
   * - `sid`: Name for the API Key.
   *
   * - `Body`:
   *
   * - `regionId`: This field represents the region id.
   *
   * @return Success
   */
  McmAddApiKey(params: HeliosUsersService.McmAddApiKeyParams): __Observable<McmApiKeyInfoList> {
    return this.McmAddApiKeyResponse(params).pipe(
      __map(_r => _r.body as McmApiKeyInfoList)
    );
  }

  /**
   * Get an API Key for a Helios user.
   *
   * Get an API key for a Helios user.
   * @param params The `HeliosUsersService.McmGetApiKeyParams` containing the following parameters:
   *
   * - `sid`: Name for the API Key.
   *
   * - `keyId`: Id for the API Key.
   *
   * - `regionId`: This field represents the region id.
   *
   * @return Success
   */
  McmGetApiKeyResponse(params: HeliosUsersService.McmGetApiKeyParams): __Observable<__StrictHttpResponse<McmApiKeyInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/mcm/users/${encodeURIComponent(params.sid)}/apiKeys/${encodeURIComponent(params.keyId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmApiKeyInfo>;
      })
    );
  }
  /**
   * Get an API Key for a Helios user.
   *
   * Get an API key for a Helios user.
   * @param params The `HeliosUsersService.McmGetApiKeyParams` containing the following parameters:
   *
   * - `sid`: Name for the API Key.
   *
   * - `keyId`: Id for the API Key.
   *
   * - `regionId`: This field represents the region id.
   *
   * @return Success
   */
  McmGetApiKey(params: HeliosUsersService.McmGetApiKeyParams): __Observable<McmApiKeyInfo> {
    return this.McmGetApiKeyResponse(params).pipe(
      __map(_r => _r.body as McmApiKeyInfo)
    );
  }

  /**
   * Delete an API key for a Helios user.
   *
   * Delete an API key for a Helios user.
   * @param params The `HeliosUsersService.McmDeleteApiKeyParams` containing the following parameters:
   *
   * - `sid`: Name for the API Key.
   *
   * - `keyId`: Identifier for the API Key.
   *
   * - `regionId`: This field represents the region id.
   */
  McmDeleteApiKeyResponse(params: HeliosUsersService.McmDeleteApiKeyParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/mcm/users/${encodeURIComponent(params.sid)}/apiKeys/${encodeURIComponent(params.keyId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete an API key for a Helios user.
   *
   * Delete an API key for a Helios user.
   * @param params The `HeliosUsersService.McmDeleteApiKeyParams` containing the following parameters:
   *
   * - `sid`: Name for the API Key.
   *
   * - `keyId`: Identifier for the API Key.
   *
   * - `regionId`: This field represents the region id.
   */
  McmDeleteApiKey(params: HeliosUsersService.McmDeleteApiKeyParams): __Observable<null> {
    return this.McmDeleteApiKeyResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Provides an ability to reset the user password by providing unique user sid on Helios.
   * @param sid Specifies a unique id of the user.
   * @param regionId This field represents the region id.
   */
  UserResetPasswordBySidResponse(sid: number,
    regionId?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/mcm/users/${encodeURIComponent(sid)}/resetPassword`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Provides an ability to reset the user password by providing unique user sid on Helios.
   * @param sid Specifies a unique id of the user.
   * @param regionId This field represents the region id.
   */
  UserResetPasswordBySid(sid: number,
    regionId?: string): __Observable<null> {
    return this.UserResetPasswordBySidResponse(sid, regionId).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module HeliosUsersService {

  /**
   * Parameters for GetMcmUsersOp
   */
  export interface GetMcmUsersOpParams {

    /**
     * TenantIds contains ids of the tenants for which objects are to be
     * returned.
     */
    tenantIds?: Array<string>;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Filter list of users with given list of Object Classes.
     */
    objectClasses?: Array<'User' | 'Group' | 'Computer' | 'WellKnownPrincipal'>;

    /**
     * ImpersonatingTenantIds filters users to fetch those that have right to
     * impersonate following tenantIds.
     */
    impersonatingTenantIds?: Array<string>;

    /**
     * AllUnderHierarchy specifies if objects of all the tenants under the
     * hierarchy of the logged in user's organization should be returned.
     */
    allUnderHierarchy?: boolean;
  }

  /**
   * Parameters for McmAddApiKey
   */
  export interface McmAddApiKeyParams {

    /**
     * Name for the API Key.
     */
    sid: string;
    Body: McmApiKeyParams;

    /**
     * This field represents the region id.
     */
    regionId?: string;
  }

  /**
   * Parameters for McmGetApiKey
   */
  export interface McmGetApiKeyParams {

    /**
     * Name for the API Key.
     */
    sid: string;

    /**
     * Id for the API Key.
     */
    keyId: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;
  }

  /**
   * Parameters for McmDeleteApiKey
   */
  export interface McmDeleteApiKeyParams {

    /**
     * Name for the API Key.
     */
    sid: string;

    /**
     * Identifier for the API Key.
     */
    keyId: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;
  }
}

export { HeliosUsersService }
