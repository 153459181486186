import { Injectable, Inject } from '@angular/core';

/**
 * Utility service for accessing for legacy AngularJS services
 */
@Injectable({
  providedIn: 'root'
})
export class AjsUpgradeService {

  constructor(@Inject('$injector') private ajsInjector: any) {}

  /**
   * Gets an angularjs service by name. This should never be executed from the
   * constructor of an ngModule. If it is, it will run before AngularJS is
   * bootstrapped and the call will fail.
   *
   * @param   name   Name of the service to get.
   * @returns        The legacy service.
   */
  public get(name: string): any {
    return this.ajsInjector.get(name);
  }
}
