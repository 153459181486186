import { Directive, HostBinding } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { MixinConstructor } from './mixin-constructor';

/**
 * Mixin that can be used for components that can be instantied as a route or as a dialog.
 * When instantiated as a dialog, the 'is-dialog' class will be added to the component,
 * and the dialogRef and isDialog properties will be set.
 *
 * @example
 * export class ComponentBase {
 *   constructor(...args: any[]) {}
 * }
 * export const _ComponentBase: HasFlexFlowCtor & typeof ComponentBase =
 * mixinFlexFlow(ComponentBase);
 *
 * export class Component extends _ComponentBase implements HasFlexFlow {
 *   constructor(@Optional() public dialogRef: MatDialogRef<RegisterComponent>) {
 *     super();
 *   }
 * }
 */
export interface HasFlexFlow {
  dialogRef: MatDialogRef<any>;
  readonly isDialog: boolean;
}

export type HasFlexFlowCtor = MixinConstructor<HasFlexFlow>;

export function mixinFlexFlow<T extends MixinConstructor<{}>>(base: T): HasFlexFlowCtor & T {
  @Directive()
  class MixinClass extends base {
    dialogRef: MatDialogRef<T>;

    @HostBinding('class.is-dialog') public get isDialog(): boolean {
      return !!this.dialogRef;
    }

    constructor(...args: any[]) {
      super(...args);
    }
  }
  return MixinClass;
}
