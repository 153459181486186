import { RestoreTask } from '@cohesity/api/v1';
import { decorateInterface } from '@cohesity/utils';

/**
 * Decorator class for RestoreTask which includes common logic and properties
 * that can be applied to all RestoreTask objects.
 */
export class CohRestoreTask extends decorateInterface<RestoreTask>() {
  /**
   * Not included in API yet so added here
   */
  subTasks: any[];

  /**
   * Not included in API yet so added here
   */
  destroyClonedTaskState: any;

  /**
   * True if the task has been destroyed or is destroying.
   */
  _destroyed: boolean;

  /**
   * UI label for the restore task status. This takes into account error and
   * destroy states.
   */
  _statusLabel: string;

  /**
   * Class label for restore task status. This takes into account error and
   * destroy states.
   */
  _statusClass: string;

  constructor(restoreTask: RestoreTask) {
    super(restoreTask);

    this.subTasks = this.subTasks || [];
    this._destroyed = Array.isArray(this.destroyClonedTaskState) && !!this.destroyClonedTaskState.length;
    this._statusLabel = this.calculateStatusLabel();
    this._statusClass = this.calculateStatusClass();
  }

  /**
   * Calculates the status label
   *
   * @return   the status label for the restore task.
   */
  private calculateStatusLabel() {
    if (this._destroyed) {
      // Mapping: RestoreTaskStatus_Type_name > CALCULATED_STATUS
      // if a delete has been attempted, delete status types take priority
      const lastItem = this.destroyClonedTaskState.slice(-1)[0];
      switch (true) {
        // TODO: How/when to handle destroy scheduled.
        case !lastItem.finished && !lastItem.startTimeUsecs:
          return 'destroyScheduled';
        case !lastItem.finished:
          return 'destroying';
        // TODO: How/when to handle destroy error.
        case lastItem.finished:
          return 'destroyed';
      }
    } else if (this.error) {
      return 'error';
    }
    return `enum.restoreTask.${this.status}`;
  }

  /**
   * Calculates the status class.
   *
   * @return   the stylesname to use for the status.
   */
  private calculateStatusClass() {
    switch (true) {
      // API doesn't indicate if there was an error destroying the task.
      case !!this.error:
        return 'status-critical';
        break;
      case this.status === 'kCancelled':
        return 'status-warning';
        break;
      default:
        return 'status-ok';
    }
  }
}
