/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Banner } from '../models/banner';
import { BannerIdData } from '../models/banner-id-data';
import { BannerUpdateParameters } from '../models/banner-update-parameters';
@Injectable({
  providedIn: 'root',
})
class BannerService extends __BaseService {
  static readonly GetBannerPath = '/public/banners';
  static readonly UpdateBannerPath = '/public/banners';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List the banner for a persona.
   *
   * Currently it returns a cluster specific banner for all requests. Later,
   * depending on who is requesting it (which can be inferred from the URL),
   * we would like to return most appropriate banner if set by the cluster
   * admin (or Service Provider).
   * @param params The `BannerService.GetBannerParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to get the banner.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return GetBannerResponse response.
   */
  GetBannerResponse(params: BannerService.GetBannerParams): __Observable<__StrictHttpResponse<Banner>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/banners`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Banner>;
      })
    );
  }
  /**
   * List the banner for a persona.
   *
   * Currently it returns a cluster specific banner for all requests. Later,
   * depending on who is requesting it (which can be inferred from the URL),
   * we would like to return most appropriate banner if set by the cluster
   * admin (or Service Provider).
   * @param params The `BannerService.GetBannerParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to get the banner.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return GetBannerResponse response.
   */
  GetBanner(params: BannerService.GetBannerParams): __Observable<Banner> {
    return this.GetBannerResponse(params).pipe(
      __map(_r => _r.body as Banner)
    );
  }

  /**
   * Update an existing banner on the Cohesity Cluster.
   *
   * Returns the banner that was updated on the Cohesity Cluster.
   * @param params The `BannerService.UpdateBannerParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update existing banner.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return UpdateBannerResponse response.
   */
  UpdateBannerResponse(params: BannerService.UpdateBannerParams): __Observable<__StrictHttpResponse<Banner>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/banners`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Banner>;
      })
    );
  }
  /**
   * Update an existing banner on the Cohesity Cluster.
   *
   * Returns the banner that was updated on the Cohesity Cluster.
   * @param params The `BannerService.UpdateBannerParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update existing banner.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return UpdateBannerResponse response.
   */
  UpdateBanner(params: BannerService.UpdateBannerParams): __Observable<Banner> {
    return this.UpdateBannerResponse(params).pipe(
      __map(_r => _r.body as Banner)
    );
  }
}

module BannerService {

  /**
   * Parameters for GetBanner
   */
  export interface GetBannerParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to get the banner.
     */
    body?: BannerIdData;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateBanner
   */
  export interface UpdateBannerParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to update existing banner.
     */
    body?: BannerUpdateParameters;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { BannerService }
