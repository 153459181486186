import { Injectable } from '@angular/core';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { Transition } from '@uirouter/core';

import { McmViewService } from '../../services';
import { GuardPriority, GuardResult, StateGuard } from '../state-guard';

/**
 * Mapping of old jobs landing page param values to ng groups landing page values.
 */
const statusMap = {
  kError: 'Failed',
  kCanceled: 'Canceled',
  kCanceling: 'Canceling',
  kSuccess: 'Succeeded',
  kWarning: 'SucceededWithWarning',
};

/**
 * Mapping of old jobs landing page param values to ng groups landing page values.
 */
const slaStatusMap = {
  kPass: 'kMet',
  kFail: 'kMissed',
};

/**
 * Jobs landing page Gaurd redirects all state traffic to the ng groups landing state if
 * the feature flag is eanbled.
 */
@Injectable({
  providedIn: 'root',
})
export class JobsGuard implements StateGuard {
  /**
   * This guard priority is set to App, which indicates it should run as part of the main application
   * after all bootstrapping and initializing is completed.
   */
  guardPriority = GuardPriority.App;

  /**
   * Match all navigation to jobs (classic ui state).
   */
  matchCriteria = {
    to: 'jobs',
  };

  constructor(private irisCtx: IrisContextService, private mcmView: McmViewService) {}

  /**
   * Redirect to groups landing page with the params.
   *
   * @param   transition  The ui router transition
   * @returns a redirect transtion if the protection group feature flag is enabled.
   */
  onStart(transition: Transition): GuardResult {
    const params = transition.params();
    const mappedParams = { ...params };

    if (flagEnabled(this.irisCtx.irisContext, 'ngProtectionGroup') && !params.force &&
      this.mcmView.canAccessState('protection-group.groups')) {

      if (params.lastRunStatus) {
        // Old UI only supported one status via param as string, convert it to array.
        // Lazy loading will cause a second pass of this state guard before redirecting
        // to the new state, ensure we don't blow away already mapped state params by
        // leaving it alone if its already an array.
        mappedParams.lastRunLocalBackupStatus = Array.isArray(params.lastRunLocalBackupStatus) ?
          params.lastRunLocalBackupStatus : [statusMap[params.lastRunLocalBackupStatus]];
      }

      mappedParams.lastRunSlaStatus = slaStatusMap[params.lastRunSlaStatus];

      return transition.router.stateService.target('protection-group.groups', mappedParams);
    }
  }
}
