import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subnet } from '@cohesity/api/v1';

/**
 * Whitelist table component.
 *
 * @example
 *   <coh-whitelist-table></coh-whitelist-table>
 */
@Component({
  selector: 'coh-whitelist-table',
  templateUrl: './whitelist-table.component.html',
})
export class WhitelistTableComponent implements OnInit {
  /**
   * Array of whitelists.
   */
  @Input() whitelists: Subnet[];

  /**
   * Whether to show the organization filter and column.
   */
  @Input() showOrgs = true;

  /**
   * Whether user can modify/add/delete whitelist.
   */
  @Input() enableAction = true;

  /**
   * If you want to hide the description from the table.
   */
  @Input() hideDescription = false;

  /**
   * Sets the Hide NFS state.
   */
  @Input() set hideNFS(val: boolean) {
    this._hideNFS = val;
    this.reConstructTableColumns();
  }

  /**
   * Returns the hide NFS state.
   */
  get hideNFS(): boolean {
    return this._hideNFS;
  }

  /**
   * Whether to hide nfs columns or not. Defaults to false.
   */
  private _hideNFS: boolean;

  /**
   * Sets the hide SMB state.
   */
  @Input() set hideSMB(val: boolean) {
    this._hideSMB = val;
    this.reConstructTableColumns();
  }

  /**
   * Returns the hide SMB state.
   */
  get hideSMB(): boolean {
    return this._hideSMB;
  }

  /**
   * Whether to hide smb columns or not. Defaults to false.
   */
  private _hideSMB: boolean;

  /**
   * Sets the hide S3 state.
   */
  @Input() set hideS3(val: boolean) {
    this._hideS3 = val;
    this.reConstructTableColumns();
  }

  /**
   * Returns the hide S3 state.
   *
   * @returns the hide S3 state.
   */
  get hideS3(): boolean {
    return this._hideS3;
  }

  /**
   * Whether to hide S3 columns or not. Defaults to false.
   */
  private _hideS3: boolean;

  /**
   * Sets the hide NFS Squash state.
   */
  @Input() set hideSquash(val: boolean) {
    this._hideSquash = val;
    this.reConstructTableColumns();
  }

  /**
   * Returns the hide NFS Squash state.
   */
  get hideSquash(): boolean {
    return this._hideSquash;
  }

  /**
   * Whether to hide NFS Squash column or not. Defaults to false.
   */
  private _hideSquash: boolean;

  /**
   * Event emitter to output edit whitelist changes.
   */
  @Output() editWhitelist = new EventEmitter<Subnet>();

  /**
   * Event emitter to output delete whitelist changes.
   */
  @Output() deleteWhitelist = new EventEmitter<Subnet>();

  /**
   * Columns shown in global whitelists table.
   */
  columns = ['_ip'];

  /**
   * Init component.
   */
  ngOnInit () {
    this.reConstructTableColumns();
  }

  /**
   * Helper function to re-contruct table columns based on Input change.
   */
  reConstructTableColumns() {
    this.columns = ['_ip'];

    if (!this.hideSMB) {
      this.columns.push('smbAccess');
    }

    if (!this.hideNFS) {
      this.columns.push('nfsAccess');
    }

    if (!this.hideS3) {
      this.columns.push('s3Access');
    }

    if (!this.hideSquash) {
      this.columns.push('nfsSquash');
    }

    if (!this.hideDescription) {
      this.columns.push('description');
    }

    if (this.enableAction) {
      this.columns.push('actions');
    }
  }

  /**
   * Returns value based on nfs squash selection
   *
   * @param subnet Whitelisted Subnet
   * @return Mapped nfs squash value.
   */
  getNfsSquashValue(subnet: Subnet) {
    let value = 'kNone';

    if (subnet.nfsAllSquash) {
      value = 'kAll';
    } else if (subnet.nfsRootSquash) {
      value = 'kRoot';
    }

    return value;
  }
}
