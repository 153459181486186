import { Component } from '@angular/core';
import { ProtectionSourceNode, RegisteredSourceInfo } from '@cohesity/api/v1';
import { PopoverRef } from '@cohesity/helix';
import { IrisContextService } from '@cohesity/iris-core';

@Component({
  selector: 'coh-saphana-popover',
  templateUrl: './saphana-popover.component.html',
  styleUrls: ['./saphana-popover.component.scss'],
})
export class SapHanaPopoverComponent {

  /**
   * Access to the current node property.
   */
  get node(): ProtectionSourceNode {
    return this.popoverRef.data;
  }

  /**
   * Access to the Sap Hana Protection Source object.
   */
  get sapHanaSource(): any {
    return this.node?.protectionSource?.sapHanaProtectionSource;
  }

  /**
   * Return the registerationInfo of the source.
   */
  get registrationInfo(): RegisteredSourceInfo {
    return this.node.registrationInfo;
  }

  /**
   * Whether node is protected or not
   */
  isNodeProtected: boolean;

  /**
   * Constructor.
   */
  constructor(
    private popoverRef: PopoverRef<ProtectionSourceNode>,
    readonly irisCtx: IrisContextService,
  ) {
    const protectionSummary = this.node.protectedSourcesSummary;
    this.isNodeProtected = !!(protectionSummary &&
      protectionSummary[0].leavesCount && protectionSummary[0].leavesCount > 0);

    if (!this.popoverRef || !this.popoverRef.data) {
      throw new Error('Popover data is not defined');
    }
  }

  /**
   * Returns whether Sap Hana node has popover or not based on node specific logic
   *
   * @param    node Protection source node
   * @returns  true/false whether node has popover or not
   */
  static hasPopover(sapHanaNode: ProtectionSourceNode): boolean {
    return !sapHanaNode.nodes;
  }
}
