import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { MetricTypePipe } from './metric-type.pipe';

const PIPES = [
  MetricTypePipe,
];

@NgModule({
  imports: [TranslateModule.forChild()],
  providers: [...PIPES],
  exports: [...PIPES],
  declarations: [...PIPES],
})
export class MetricTypePipeModule {}
