<coh-salesforce-login
  *ngIf="authModes.includes('salesforce')"
  [ngClass]="{ 'hidden' : currentAuthMode !== 'salesforce' }"
  ></coh-salesforce-login>
<form
  *ngIf="currentAuthMode !== 'salesforce'"
  class="login-form"
  cohValidateOnSubmit
  [formGroup]="loginForm"
  (ngSubmit)="onSubmit()">
  <mat-form-field class="username-field" *ngIf="loginForm.get('username')">
    <mat-label translate>username</mat-label>
    <input type="text"
      matInput
      autofocus
      [maxlength]="maxInputLength"
      autocomplete="off"
      placeholder="{{ 'username' | translate }}"
      formControlName="username"
      cogDataId="username-input">
  </mat-form-field>
  <mat-form-field *ngIf="loginForm.get('password')">
    <mat-label translate>password</mat-label>
    <input type="password"
      matInput
      [maxlength]="maxInputLength"
      autocomplete="off"
      placeholder="{{ 'password' | translate }}"
      formControlName="password"
      cogDataId="password-input"
      #passwordInput>
      <cog-password-visibility-button matSuffix [input]="passwordInput"></cog-password-visibility-button>
  </mat-form-field>
  <mat-form-field *ngIf="loginForm.get('domain')" class="domain">
    <mat-label translate>domain</mat-label>
    <mat-select *ngIf="domains?.length > 0; else domainInput" cogDataId="domain-input">
      <mat-option *ngFor="let domain of domains" [value]="domain">{{domain}}</mat-option>
    </mat-select>
    <ng-template #domainInput>
      <input type="text"
        matInput
        [maxlength]="maxInputLength"
        placeholder="{{ 'domain' | translate }}"
        formControlName="domain"
        cogDataId="domain-input">
    </ng-template>
  </mat-form-field>
  <mat-checkbox
    *ngIf="loginForm.get('rememberme')"
    formControlName="rememberme"
    cogDataId="rememberme-input">
      {{ 'login.rememberMe' | translate }}
  </mat-checkbox>
  <button mat-flat-button
    cohSubmitButton
    color="primary"
    type="submit"
    [disabled]="(busy$ | async)"
    cogDataId="login-btn">
    {{ 'signIn' | translate }}
  </button>
</form>

<coh-auth-mode-toggle
  [currentAuthMode]="currentAuthMode"
  [authModes]="authModes"
  (authModeChanged)="handleAuthModeChanged($event)"
  ></coh-auth-mode-toggle>
