import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FortknoxServiceApi, RpaasRegionInfo, TransferTimeConfigurationParams, TransferTimeParams, VaultTransferTimeConfig } from '@cohesity/api/v2';
import { PolicyScheduleUnit, ScheduleSelectorForm } from '@cohesity/shared-forms';
import { AjaxHandlerService, AutoDestroyable, DayVal } from '@cohesity/utils';
import moment from 'moment';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

/**
 * @description
 * Cloud vault backup window config dialog.
 */
@Component({
  selector: 'coh-backup-window',
  templateUrl: './backup-window.component.html',
  styleUrls: ['./backup-window.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackupWindowComponent extends AutoDestroyable implements OnInit {
  /**
   * Root backup window form.
   */
  readonly formGroup = new UntypedFormGroup({
    enabled: new UntypedFormControl(),
    schedule: new UntypedFormArray([]),
  });

  /**
   * Schedule subform array that contains date and time config controls.
   */
  get scheduleForm(): UntypedFormArray {
    return this.formGroup.get('schedule') as UntypedFormArray;
  }

  /**
   * Indicates form is loading APIs.
   */
  readonly busy$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: {
      backupWindow: TransferTimeParams[];
      vault: RpaasRegionInfo;
    },
    private ajaxHandler: AjaxHandlerService,
    private dialogRef: MatDialogRef<BackupWindowComponent>,
    private fortknoxService: FortknoxServiceApi
  ) {
    super();
  }

  ngOnInit() {
    if (this.data?.backupWindow?.length) {
      this.formGroup.get('enabled').setValue(true);
      this.data.backupWindow.forEach(params => this.addSchedule(params));
    }

    this.formGroup.valueChanges.pipe(this.untilDestroy()).subscribe(form => {
      if (!form.schedule.length && form.enabled) {
        this.addSchedule();
      } else if (!form.enabled) {
        this.scheduleForm.clear();
      }
    });
  }

  /**
   * Adds new schedule config form to schedule subform.
   *
   * @param config Optional config to populate control.
   */
  addSchedule(config?: TransferTimeParams) {
    const days = config?.days ?? ([DayVal[new Date().getDay()]] as any);
    const startTime = config?.startTime ?? {
      hour: 9,
      minute: 0,
    };

    const endTime = config?.endTime ?? {
      hour: 17,
      minute: 0,
    };

    this.scheduleForm.insert(
      this.scheduleForm.controls.length,
      new UntypedFormGroup({
        startTime: new UntypedFormControl(startTime, [Validators.required]),
        endTime: new UntypedFormControl(endTime, [Validators.required]),
        weekDays: new ScheduleSelectorForm({
          timePeriod: { value: 0, type: PolicyScheduleUnit.Weeks },
          days,
        }),
      }, {
        validators: (controls) => {
          const start = controls.get('startTime')?.value;
          const end = controls.get('endTime')?.value;

          if (start?.hour + start?.minute / 60 >= end?.hour + end?.minute / 60) {

            return {
              invalidTime: true
            };
          }

        }
      })
    );
  }

  /**
   * Removes controls from schedule subform.
   *
   * @param index Index of controls that will be removed.
   */
  removeScheduleAt(index) {
    this.scheduleForm.removeAt(index);
  }

  private getApiParams(): TransferTimeConfigurationParams[] {
    if (!this.formGroup.get('enabled').value) {
      return [];
    }

    return this.scheduleForm.controls.map(control => ({
      days: control.get('weekDays').get('days').value,
      startTime: control.get('startTime').value,
      endTime: control.get('endTime').value,
    }));
  }

  /**
   * Submits backup window form.
   */
  onSubmit() {
    this.busy$.next(true);

    const transferTimes = this.getApiParams();
    const transferTimeConfigParams: VaultTransferTimeConfig = {
      globalVaultId: this.data.vault.globalVaultId,
      transferTimeConfigParams: transferTimes.length ? {
        timezone: moment.tz.guess(),
        transferTimes,
      } : undefined,
    };

    this.fortknoxService
      .UpdateVaultTransferTimeConfig({
        globalVaultId: this.data.vault.globalVaultId,
        body: transferTimeConfigParams,
      })
      .pipe(
        this.untilDestroy(),
        catchError(e => {
          this.busy$.next(false);
          this.ajaxHandler.handler(e);
          return throwError(e);
        })
      )
      .subscribe(() => this.dialogRef.close());
  }
}
