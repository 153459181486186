/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesitySecopsApiConfiguration as __Configuration } from '../cohesity-secops-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SecurityPlans } from '../models/security-plans';
import { SecurityPlan } from '../models/security-plan';
import { PerformScanResult } from '../models/perform-scan-result';
import { ScanClusterRequest } from '../models/scan-cluster-request';
import { SecurityCriteriaList } from '../models/security-criteria-list';
import { ClusterScanResults } from '../models/cluster-scan-results';
import { RegionClusterScanResults } from '../models/region-cluster-scan-results';
import { ScanSummaryList } from '../models/scan-summary-list';
import { ScanSummaryDetails } from '../models/scan-summary-details';
import { SecurityCriteriaResultList } from '../models/security-criteria-result-list';
@Injectable({
  providedIn: 'root',
})
class SecurityAdvisorService extends __BaseService {
  static readonly GetSecurityPlansPath = '/secops/plans';
  static readonly CreateSecurityPlanPath = '/secops/plans';
  static readonly GetSecurityPlanDetailsPath = '/secops/plans/{id}';
  static readonly UpdateSecurityPlanPath = '/secops/plans/{id}';
  static readonly PerformScanClustersPath = '/secops/perform-scans';
  static readonly GetSecurityCriteriaListPath = '/secops/security-criteria';
  static readonly GetScanResultsPath = '/secops/get-scan-results';
  static readonly GetScanResultsByRegionPath = '/secops/get-scan-results-by-region';
  static readonly GetScansSummaryPath = '/secops/get-scans-summary';
  static readonly GetScanDetailsPath = '/secops/get-scans-summary/{scanId}';
  static readonly GetScanIssuesSummaryPath = '/secops/get-scan-issues';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get a list of security plans
   *
   * Returns a list of available sites based on the defined filter criteria.
   * @param params The `SecurityAdvisorService.GetSecurityPlansParams` containing the following parameters:
   *
   * - `planNames`: Filter the security plans given by list of plan names.
   *
   * - `ids`: Filter the security plans given by list of plan ids.
   *
   * @return Success
   */
  GetSecurityPlansResponse(params: SecurityAdvisorService.GetSecurityPlansParams): __Observable<__StrictHttpResponse<SecurityPlans>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.planNames || []).forEach(val => {if (val != null) __params = __params.append('planNames', val.toString())});
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/secops/plans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SecurityPlans>;
      })
    );
  }
  /**
   * Get a list of security plans
   *
   * Returns a list of available sites based on the defined filter criteria.
   * @param params The `SecurityAdvisorService.GetSecurityPlansParams` containing the following parameters:
   *
   * - `planNames`: Filter the security plans given by list of plan names.
   *
   * - `ids`: Filter the security plans given by list of plan ids.
   *
   * @return Success
   */
  GetSecurityPlans(params: SecurityAdvisorService.GetSecurityPlansParams): __Observable<SecurityPlans> {
    return this.GetSecurityPlansResponse(params).pipe(
      __map(_r => _r.body as SecurityPlans)
    );
  }

  /**
   * Create a security plan.
   *
   * Create a security plan with different security criteria.
   * @param body Specifies the parameters for resolving marketplace token.
   * @return Success
   */
  CreateSecurityPlanResponse(body: SecurityPlan): __Observable<__StrictHttpResponse<SecurityPlan>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/secops/plans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SecurityPlan>;
      })
    );
  }
  /**
   * Create a security plan.
   *
   * Create a security plan with different security criteria.
   * @param body Specifies the parameters for resolving marketplace token.
   * @return Success
   */
  CreateSecurityPlan(body: SecurityPlan): __Observable<SecurityPlan> {
    return this.CreateSecurityPlanResponse(body).pipe(
      __map(_r => _r.body as SecurityPlan)
    );
  }

  /**
   * Get details of a plan.
   *
   * Returns details of a security plan given by plan id.
   * @param id Filter the security plans given by a plan id.
   * @return Success
   */
  GetSecurityPlanDetailsResponse(id: string): __Observable<__StrictHttpResponse<SecurityPlan>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/secops/plans/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SecurityPlan>;
      })
    );
  }
  /**
   * Get details of a plan.
   *
   * Returns details of a security plan given by plan id.
   * @param id Filter the security plans given by a plan id.
   * @return Success
   */
  GetSecurityPlanDetails(id: string): __Observable<SecurityPlan> {
    return this.GetSecurityPlanDetailsResponse(id).pipe(
      __map(_r => _r.body as SecurityPlan)
    );
  }

  /**
   * Update a security plan.
   *
   * Update a security plan.
   * @param params The `SecurityAdvisorService.UpdateSecurityPlanParams` containing the following parameters:
   *
   * - `id`: Specifies the plan id that needs to be updated.
   *
   * - `body`: Specifies the parameters for resolving marketplace token.
   *
   * @return Success
   */
  UpdateSecurityPlanResponse(params: SecurityAdvisorService.UpdateSecurityPlanParams): __Observable<__StrictHttpResponse<SecurityPlan>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/secops/plans/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SecurityPlan>;
      })
    );
  }
  /**
   * Update a security plan.
   *
   * Update a security plan.
   * @param params The `SecurityAdvisorService.UpdateSecurityPlanParams` containing the following parameters:
   *
   * - `id`: Specifies the plan id that needs to be updated.
   *
   * - `body`: Specifies the parameters for resolving marketplace token.
   *
   * @return Success
   */
  UpdateSecurityPlan(params: SecurityAdvisorService.UpdateSecurityPlanParams): __Observable<SecurityPlan> {
    return this.UpdateSecurityPlanResponse(params).pipe(
      __map(_r => _r.body as SecurityPlan)
    );
  }

  /**
   * Perform scan on clusters.
   *
   * Perform scan on the list of clusters.
   * @param body Parameters to perform scan request.
   * @return Success
   */
  PerformScanClustersResponse(body?: ScanClusterRequest): __Observable<__StrictHttpResponse<PerformScanResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/secops/perform-scans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PerformScanResult>;
      })
    );
  }
  /**
   * Perform scan on clusters.
   *
   * Perform scan on the list of clusters.
   * @param body Parameters to perform scan request.
   * @return Success
   */
  PerformScanClusters(body?: ScanClusterRequest): __Observable<PerformScanResult> {
    return this.PerformScanClustersResponse(body).pipe(
      __map(_r => _r.body as PerformScanResult)
    );
  }

  /**
   * Get list of available security criteria that can be configured as part of security rule.
   *
   * Get available security criteria list.
   * @return Success
   */
  GetSecurityCriteriaListResponse(): __Observable<__StrictHttpResponse<SecurityCriteriaList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/secops/security-criteria`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SecurityCriteriaList>;
      })
    );
  }
  /**
   * Get list of available security criteria that can be configured as part of security rule.
   *
   * Get available security criteria list.
   * @return Success
   */
  GetSecurityCriteriaList(): __Observable<SecurityCriteriaList> {
    return this.GetSecurityCriteriaListResponse().pipe(
      __map(_r => _r.body as SecurityCriteriaList)
    );
  }

  /**
   * Get scan result for clusters.
   *
   * Get scan result for the list of clusters.
   * @param clusterIds List of cluster Ids.
   * @return Success
   */
  GetScanResultsResponse(clusterIds?: Array<number>): __Observable<__StrictHttpResponse<ClusterScanResults>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (clusterIds || []).forEach(val => {if (val != null) __params = __params.append('clusterIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/secops/get-scan-results`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterScanResults>;
      })
    );
  }
  /**
   * Get scan result for clusters.
   *
   * Get scan result for the list of clusters.
   * @param clusterIds List of cluster Ids.
   * @return Success
   */
  GetScanResults(clusterIds?: Array<number>): __Observable<ClusterScanResults> {
    return this.GetScanResultsResponse(clusterIds).pipe(
      __map(_r => _r.body as ClusterScanResults)
    );
  }

  /**
   * Get scan result for clusters and group by region.
   *
   * Get scan result on the list of clusters and group by region.
   * @param params The `SecurityAdvisorService.GetScanResultsByRegionParams` containing the following parameters:
   *
   * - `state`: Parameters for fetching scan results in a state.
   *
   * - `planIds`: Filter the security plans given by list of plan ids.
   *
   * - `includeIssues`: If true, includes issues as part of scan results response.
   *
   * - `country`: Parameters for fetching scan results in a country.
   *
   * - `city`: Parameters for fetching scan results in a city.
   *
   * @return Success
   */
  GetScanResultsByRegionResponse(params: SecurityAdvisorService.GetScanResultsByRegionParams): __Observable<__StrictHttpResponse<RegionClusterScanResults>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.state != null) __params = __params.set('state', params.state.toString());
    (params.planIds || []).forEach(val => {if (val != null) __params = __params.append('planIds', val.toString())});
    if (params.includeIssues != null) __params = __params.set('includeIssues', params.includeIssues.toString());
    if (params.country != null) __params = __params.set('country', params.country.toString());
    if (params.city != null) __params = __params.set('city', params.city.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/secops/get-scan-results-by-region`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RegionClusterScanResults>;
      })
    );
  }
  /**
   * Get scan result for clusters and group by region.
   *
   * Get scan result on the list of clusters and group by region.
   * @param params The `SecurityAdvisorService.GetScanResultsByRegionParams` containing the following parameters:
   *
   * - `state`: Parameters for fetching scan results in a state.
   *
   * - `planIds`: Filter the security plans given by list of plan ids.
   *
   * - `includeIssues`: If true, includes issues as part of scan results response.
   *
   * - `country`: Parameters for fetching scan results in a country.
   *
   * - `city`: Parameters for fetching scan results in a city.
   *
   * @return Success
   */
  GetScanResultsByRegion(params: SecurityAdvisorService.GetScanResultsByRegionParams): __Observable<RegionClusterScanResults> {
    return this.GetScanResultsByRegionResponse(params).pipe(
      __map(_r => _r.body as RegionClusterScanResults)
    );
  }

  /**
   * Get list of scans summary.
   *
   * Get scans summary.
   * @param params The `SecurityAdvisorService.GetScansSummaryParams` containing the following parameters:
   *
   * - `startTimeMSecs`: Specifies the start time Unix time epoch in milliseconds from which the scans summary should be listed.
   *
   * - `endTimeMSecs`: Specifies the end time Unix time epoch in milliseconds from which the scans summary should be listed.
   *
   * @return Success
   */
  GetScansSummaryResponse(params: SecurityAdvisorService.GetScansSummaryParams): __Observable<__StrictHttpResponse<ScanSummaryList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startTimeMSecs != null) __params = __params.set('startTimeMSecs', params.startTimeMSecs.toString());
    if (params.endTimeMSecs != null) __params = __params.set('endTimeMSecs', params.endTimeMSecs.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/secops/get-scans-summary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ScanSummaryList>;
      })
    );
  }
  /**
   * Get list of scans summary.
   *
   * Get scans summary.
   * @param params The `SecurityAdvisorService.GetScansSummaryParams` containing the following parameters:
   *
   * - `startTimeMSecs`: Specifies the start time Unix time epoch in milliseconds from which the scans summary should be listed.
   *
   * - `endTimeMSecs`: Specifies the end time Unix time epoch in milliseconds from which the scans summary should be listed.
   *
   * @return Success
   */
  GetScansSummary(params: SecurityAdvisorService.GetScansSummaryParams): __Observable<ScanSummaryList> {
    return this.GetScansSummaryResponse(params).pipe(
      __map(_r => _r.body as ScanSummaryList)
    );
  }

  /**
   * Get details of a scan.
   *
   * Get details of a scan.
   * @param scanId Specifies the id of the scan to get detailed summary.
   * @return Success
   */
  GetScanDetailsResponse(scanId: string): __Observable<__StrictHttpResponse<ScanSummaryDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/secops/get-scans-summary/${encodeURIComponent(scanId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ScanSummaryDetails>;
      })
    );
  }
  /**
   * Get details of a scan.
   *
   * Get details of a scan.
   * @param scanId Specifies the id of the scan to get detailed summary.
   * @return Success
   */
  GetScanDetails(scanId: string): __Observable<ScanSummaryDetails> {
    return this.GetScanDetailsResponse(scanId).pipe(
      __map(_r => _r.body as ScanSummaryDetails)
    );
  }

  /**
   * Get issues from scans. Return last scan issues by default.
   *
   * Get scan issues summary.
   * @param params The `SecurityAdvisorService.GetScanIssuesSummaryParams` containing the following parameters:
   *
   * - `state`: Parameters for fetching scan issues in a state.
   *
   * - `includeLastScanOnly`: Parameters to include only last scan issues.
   *
   * - `country`: Parameters for fetching scan issues in a country.
   *
   * - `clusterId`: Parameters for fetching scan issues matching cluster id.
   *
   * - `city`: Parameters for fetching scan issues matching city.
   *
   * @return Success
   */
  GetScanIssuesSummaryResponse(params: SecurityAdvisorService.GetScanIssuesSummaryParams): __Observable<__StrictHttpResponse<SecurityCriteriaResultList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.state != null) __params = __params.set('state', params.state.toString());
    if (params.includeLastScanOnly != null) __params = __params.set('includeLastScanOnly', params.includeLastScanOnly.toString());
    if (params.country != null) __params = __params.set('country', params.country.toString());
    if (params.clusterId != null) __params = __params.set('clusterId', params.clusterId.toString());
    if (params.city != null) __params = __params.set('city', params.city.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/secops/get-scan-issues`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SecurityCriteriaResultList>;
      })
    );
  }
  /**
   * Get issues from scans. Return last scan issues by default.
   *
   * Get scan issues summary.
   * @param params The `SecurityAdvisorService.GetScanIssuesSummaryParams` containing the following parameters:
   *
   * - `state`: Parameters for fetching scan issues in a state.
   *
   * - `includeLastScanOnly`: Parameters to include only last scan issues.
   *
   * - `country`: Parameters for fetching scan issues in a country.
   *
   * - `clusterId`: Parameters for fetching scan issues matching cluster id.
   *
   * - `city`: Parameters for fetching scan issues matching city.
   *
   * @return Success
   */
  GetScanIssuesSummary(params: SecurityAdvisorService.GetScanIssuesSummaryParams): __Observable<SecurityCriteriaResultList> {
    return this.GetScanIssuesSummaryResponse(params).pipe(
      __map(_r => _r.body as SecurityCriteriaResultList)
    );
  }
}

module SecurityAdvisorService {

  /**
   * Parameters for GetSecurityPlans
   */
  export interface GetSecurityPlansParams {

    /**
     * Filter the security plans given by list of plan names.
     */
    planNames?: Array<string>;

    /**
     * Filter the security plans given by list of plan ids.
     */
    ids?: Array<string>;
  }

  /**
   * Parameters for UpdateSecurityPlan
   */
  export interface UpdateSecurityPlanParams {

    /**
     * Specifies the plan id that needs to be updated.
     */
    id: string;

    /**
     * Specifies the parameters for resolving marketplace token.
     */
    body: SecurityPlan;
  }

  /**
   * Parameters for GetScanResultsByRegion
   */
  export interface GetScanResultsByRegionParams {

    /**
     * Parameters for fetching scan results in a state.
     */
    state?: string;

    /**
     * Filter the security plans given by list of plan ids.
     */
    planIds?: Array<string>;

    /**
     * If true, includes issues as part of scan results response.
     */
    includeIssues?: boolean;

    /**
     * Parameters for fetching scan results in a country.
     */
    country?: string;

    /**
     * Parameters for fetching scan results in a city.
     */
    city?: string;
  }

  /**
   * Parameters for GetScansSummary
   */
  export interface GetScansSummaryParams {

    /**
     * Specifies the start time Unix time epoch in milliseconds from which the scans summary should be listed.
     */
    startTimeMSecs?: null | number;

    /**
     * Specifies the end time Unix time epoch in milliseconds from which the scans summary should be listed.
     */
    endTimeMSecs?: null | number;
  }

  /**
   * Parameters for GetScanIssuesSummary
   */
  export interface GetScanIssuesSummaryParams {

    /**
     * Parameters for fetching scan issues in a state.
     */
    state?: string;

    /**
     * Parameters to include only last scan issues.
     */
    includeLastScanOnly?: boolean;

    /**
     * Parameters for fetching scan issues in a country.
     */
    country?: string;

    /**
     * Parameters for fetching scan issues matching cluster id.
     */
    clusterId?: number;

    /**
     * Parameters for fetching scan issues matching city.
     */
    city?: string;
  }
}

export { SecurityAdvisorService }
