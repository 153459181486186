import { Component, Input, OnInit } from '@angular/core';
import { QoS, QosPoliciesResult, ViewServiceApi } from '@cohesity/api/v2';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { TypedFormControl } from 'ngx-sub-form';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';


@Component({
  selector: 'coh-view-qos-policy-selector',
  styleUrls: ['./view-qos-policy-selector.component.scss'],
  templateUrl: './view-qos-policy-selector.component.html',
})
export class ViewQosPolicySelectorComponent
  extends AutoDestroyable implements OnInit {
  /**
   * Form control for QoS policy selector.
   */
  @Input() control: TypedFormControl<QoS>;

  /**
   * Whether an API call is in progress.
   */
  loading = false;

  /**
   * An observable stream of data centers.
   */
  qosPolicies$ = new BehaviorSubject<QoS[]>([]);

  /**
   * Initial value for the control.
   */
  initialValue: any;

  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private viewService: ViewServiceApi,
  ) {
    super();
  }

  ngOnInit() {
    this.initialValue = this.initialValue || this.control.value;
    this.control.disable();
    this.loading = true;

    this.viewService.GetQosPolicies().pipe(
      this.untilDestroy(),
      finalize(() => this.loading = false)
    ).subscribe((qosPolicies: QosPoliciesResult) => {
      if (qosPolicies?.qosPolicies) {
        this.control.enable();
        this.qosPolicies$.next(qosPolicies.qosPolicies);

        if (this.initialValue) {
          // Select a value by default if an initial value is provided
          const foundItem = qosPolicies.qosPolicies.find(item =>
            item.name === this.initialValue.name
          );

          if (foundItem) {
            this.control.setValue(foundItem);
          }
        }
      }
    }, error => this.ajaxHandlerService.errorMessage(error));
  }
}
