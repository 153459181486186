import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { Resource, ResourceType } from '@cohesity/api/guardian';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { ResourceSelectionSummary } from '../../models';
import { getResourceSelectionSummary } from '../../utils';
import { CardModule, StatlistModule } from '@cohesity/helix';

/**
 * Displays resource selection count by resource type.
 *
 * @example
 * <coh-resource-selection-details [resources]="resources"></coh-resource-selection-details>
 */
@Component({
  standalone: true,
  imports: [CardModule, CommonModule, StatlistModule, TranslateModule],
  selector: 'coh-resource-selection-details',
  templateUrl: './resource-selection-details.component.html',
  styleUrls: ['./resource-selection-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ResourceSelectionDetailsComponent implements OnChanges {
  /**
   * List of selected resources.
   */
  @Input() resources: Resource[] = [];

  /**
   * Display mode depending on usage in parent component.
   * Default: Table row summary
   * statList: Stat list summary
   */
  @Input() displayMode: 'default' | 'statList' = 'default';

  /**
   * Count of selected resources by type.
   */
  countByResourceType$ = new BehaviorSubject<ResourceSelectionSummary>({});

  /**
   * List of resource type details to be displayed.
   */
  items: { type: ResourceType; label: string }[] = [
    { type: ResourceType.Service, label: 'cloudServices' },
    { type: ResourceType.CloudProviderRegion, label: 'regions' },
    { type: ResourceType.Aggregator, label: 'sourceTypes' },
    { type: ResourceType.Source, label: 'sources' },
  ];

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.resources.currentValue) {
      this.countByResourceType$.next(getResourceSelectionSummary(this.resources));
      this.cdr.detectChanges();
    }
  }
}
