import { CdkPortalOutlet, ComponentPortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { isObservable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AutoDestroyable } from '@cohesity/utils';

import { PluginRegistryService } from '../plugin-registry.service';
import { componentExtension, ComponentExtensionImpl, ComponentExtensionPointConfig } from './component-extension-config';

/**
 * This component is used to render a series of components defined by plugin extension points.
 * It will look up any components extension in the plugin render with the given name, render them
 * an pass the extensionData values as an input to them.
 */
@Component({
  selector: 'coh-component-outlet-extension',
  templateUrl: './component-outlet-extension.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComponentOutletExtensionComponent extends AutoDestroyable implements OnChanges, OnInit, AfterViewInit {
  /**
   * The name of the extension to look up. If there are no extensions registered, this component will be empty.
   */
  @Input() extensionPoint: string;

  /**
   * The data to pass to the extension component.
   */
  @Input() extensionData: any;

  /**
   * This is a list of component portals corresponding to each registered type
   */
  portals: ComponentPortal<ComponentExtensionImpl>[];

  /**
   * This provides access to the rendered portal outlets and each component instance. It is used
   * to pass inputs to the components.
   */
  @ViewChildren(CdkPortalOutlet) portalOutlets: QueryList<CdkPortalOutlet>;

  constructor(private registry: PluginRegistryService) {
    super();
  }

  /**
   * Setup the component to sync the input data whenever the portal outlets change.
   */
  ngAfterViewInit() {
    this.portalOutlets.changes
      .pipe(
        this.untilDestroy(),
        startWith(this.portalOutlets)
      )
      .subscribe(() => this.syncExtensionData());
  }

  /**
   * Lookup available extensions in the registry and subscribe to changes
   */
  ngOnInit() {
    this.registry
      .getRegisteredExtensions(this.extensionPoint, componentExtension)
      .pipe(
        this.untilDestroy(),
        map((configs: ComponentExtensionPointConfig[]) => configs.map(config => new ComponentPortal(config.data)))
      )
      .subscribe(portals => (this.portals = portals));
  }

  /**
   * Sync extension data whenever the data changes.
   *
   * @param  changes   Properties on the component that have changed.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.extensionData) {
      this.syncExtensionData();
    }
  }

  /**
   * Updates the extension data on the component.
   */
  syncExtensionData() {
    (this.portalOutlets || []).forEach(outlet => {
      const ref = outlet.attachedRef as ComponentRef<ComponentExtensionImpl>;

      if (isObservable(this.extensionData)) {
        this.extensionData.subscribe(extensionData => {
          ref.instance.extensionData = extensionData;
          ref.changeDetectorRef.detectChanges();
        });
      } else {
        ref.instance.extensionData = this.extensionData;
        ref.changeDetectorRef.detectChanges();
      }
    });
  }
}
