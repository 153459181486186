import { Pipe, PipeTransform } from '@angular/core';
import { ProtectionSource } from '@cohesity/api/v1';
import { ProtectedObjectInfo, SearchObject } from '@cohesity/api/v2';
import { NGXLogger } from 'ngx-logger';

import {
  ApplicationEnvironment,
  ApplicationEnvironments,
  Environment,
  JobEnvParamsV2,
  ObjectTypeToIconMap,
  OsVariantIconMap,
  SourceKeys,
} from '../constants';

/**
 * If environment specific icons are not available, use these.
 */
const DefaultIcons = {
  kVirtualMachine: 'helix:object-vm',
  kVolume: 'helix:object-volume',
  kDatabase: 'helix:object-db',
  kView: 'helix:object-view',
  kHost: 'helix:object-hostsystem',
};

/**
 * Options for object icon pipe.
 */
interface ObjectIconOptions {
  /**
   * Whether the object is partially protected (some children nodes are
   * protected).
   */
  partiallyProtected?: boolean;

  /**
   * Whether the object is auto protected (that is implicitly protected because
   * of a parent).
   */
  autoProtected?: boolean;

  /**
   * Whether the object is protected.
   */
  isProtected?: boolean;

  /**
   * Specifies the workload type for the given object.
   * This is only used by O365 and AWS entities.
   */
  workloadType?: string;

  /**
   * Specifies the application type for the given object.
   * This is only used by Application environments like Oracle and SQL entities.
   */
  applicationType?: string;
}

/**
 * Converts an object into an icon name.
 *
 * @example
 * <cog-icon [shape]="object | objectIcon"></cog-icon>
 */
@Pipe({
  name: 'objectIcon'
})
export class ObjectIconPipe implements PipeTransform {
  constructor(private ngxLogger: NGXLogger) {
  }

  transform(value: SearchObject | ProtectionSource | ProtectedObjectInfo, options: ObjectIconOptions = {}): string {
    const {partiallyProtected, autoProtected, isProtected, workloadType, applicationType} = options;
    let environment;
    let objectType;
    let objectIcon;
    let hostType;

    // Specifies whether the object environment is application type on top of a physical host
    // SourceInfo is kPhysical in this case but we are only interested in the application type.
    const isApplicationOnPhysical =
      (value as SearchObject)?.sourceInfo?.environment === Environment.kPhysical &&
      ApplicationEnvironments.includes(value?.environment as ApplicationEnvironment);

    switch (true) {
      case !!(value as SearchObject)?.sourceInfo && !isApplicationOnPhysical:
        environment = (value as SearchObject).sourceInfo?.environment;
        objectType = (value as SearchObject).objectType;
        break;
      case !!(value as ProtectedObjectInfo)?.environment && !!(value as ProtectedObjectInfo)?.objectType:
        environment = (value as ProtectedObjectInfo).environment;
        objectType = (value as ProtectedObjectInfo).objectType;
        break;
      case !!(value as ProtectionSource)?.environment:
        environment = (value as ProtectionSource).environment;
        objectType = (value as ProtectionSource)[SourceKeys[environment]]?.type;
        hostType = (value as ProtectionSource)[SourceKeys[environment]]?.hostType;
        break;
    }

    // If application type is provided then assigning it as object environment.
    if (ApplicationEnvironments.includes(applicationType as ApplicationEnvironment)) {
      environment = applicationType;
    }

    const environmentGroup = ObjectTypeToIconMap[environment] || {};

    if (!environmentGroup[objectType]) {
      this.ngxLogger.warn('No compatible icon could be found for the passed object.');
    }

    if (value && value[JobEnvParamsV2[value.environment]]?.isTemplate) {
      // Use the template icon if the object is a template.
      objectIcon = 'helix:object-vm-template';
    } else {
      // Fallback to a default icon if there is no environment specific icon.
      objectIcon = environmentGroup[objectType] || DefaultIcons[objectType];
    }

    if (value?.environment === Environment.kO365) {
      // Differentiate between icons for normal kGroup entities and kGroup
      // entities with security enabled.
      if ((value as ProtectionSource)?.office365ProtectionSource?.groupInfo?.isSecurityEnabled) {
        objectIcon = environmentGroup['kSecurityGroup'];
      } else if (workloadType) {
        // Only overwrite if the workloadType is present. For cases within
        // GlobalSearch, this will not be defined.
        objectIcon = environmentGroup[workloadType];
      }
    }

    // Differentiate between AG & Non-AG SQL Databases and assign icons accrodingly.
    if (value?.environment === Environment.kSQL) {
      const sqlSource = (value as ProtectionSource)?.sqlProtectionSource;
      if ((sqlSource?.dbAagEntityId && sqlSource?.type === 'kDatabase') ||
        sqlSource?.type === 'kAAGDatabase') {
        objectIcon = environmentGroup['kAAGDatabase'];
      }
    }

    if (hostType && OsVariantIconMap[objectIcon]) {
      objectIcon =  OsVariantIconMap[objectIcon][hostType] || objectIcon;
    }

    if (!objectIcon) {
      // Fallback to a generic icon if everything else fails. This icon doesn't
      // support the auto, protected and partial variants as UI doesn't have it,
      // therefore return early.
      return 'helix:object-generic';
    }

    if (autoProtected) {
      // If the object is auto protected, always just show the auto protected
      // icon.
      return `${objectIcon}-auto`;
    }

    if (partiallyProtected) {
      // Otherwise show the partially protected icon if partial protected.
      return `${objectIcon}-partial`;
    }

    if (isProtected) {
      return `${objectIcon}-protected`;
    }

    return objectIcon;
  }
}
