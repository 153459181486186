// Helios Service

;(function(angular, undefined) {
  'use strict';

  var selectedAccountPath = 'user.salesforceAccount.selectedAccount';
  var selectedAccountKey = 'salesforceAccount';

  angular.module('C').service('HeliosService', heliosServiceFn);

  function heliosServiceFn(
    $http, $q, $rootScope, _, API, NgEulaService, localStorageService, $injector) {
    var service = {
      heliosStatus: {},

      decorateHeliosConfiguration: decorateHeliosConfiguration,
      generateUuid: generateUuid,
      getGoogleProjects: getGoogleProjects,
      getHeliosConfiguration: getHeliosConfiguration,
      getHeliosEulaStatus: getHeliosEulaStatus,
      getSalesforceAccount: getSalesforceAccount,
      getUserAccountId: getUserAccountId,
      getUserExternalUserId: getUserExternalUserId,
      getClustersStatus: getClustersStatus,
      isHeliosEulaNeeded: isHeliosEulaNeeded,
      getImpersonatedSalesforceAccountId: getImpersonatedSalesforceAccountId,
      isSalesforceUser: isSalesforceUser,
      registerClusterToApp: registerClusterToApp,
      sendProvisionToBaas: sendProvisionToBaas,
      setSalesforceAccount: setSalesforceAccount,
      syncState: syncState,
      unregisterClusterFromHelios: unregisterClusterFromHelios,
      unregisterMcmCluster: unregisterMcmCluster,
      updateHeliosConfiguration: updateHeliosConfiguration,
      updateHeliosEulaStatus: updateHeliosEulaStatus,
    };

    /**
     * Generate uuid for provided object which will be unique in mcm(Helios)
     * environment.
     *
     * resultant UUID format: <clusterId:clusterIncarnationId:identifier>
     * clusterId and clusterIncarnationId will be unique across clusters and
     * object identifier will be unique withing a cluster hence generated uuid
     * will be unique across all registered clusters in mcm(Helios) env.
     *
     * @example
        var job = {
          clusterId: 123456789,
          clusterIncarnationId: 987654321,
          id: 9865,
          ...
        };
        var jobUuid = generateUuid(job, job.id);

        jobUuid === '123456789:987654321:9865'
     *
     * @method   generateUuid
     * @param    {object}           obj           The job, source, policy etc
     *                                            object info for which UUID
     *                                            is generated.
     * @param    {String|number}    identifier    The object identifier value eg
     *                                            for job it will be job.id
     *                                            for policy it will be policyId
     * @return   {String}   Return generated uuid for provided object.
     */
    function generateUuid(obj, identifier) {
      return [
        obj.clusterId,
        obj.clusterIncarnationId,
        identifier
      ].join(':');
    }

    /**
     * Check if Helios EULA has been accepted
     *
     * @method   isHeliosEulaNeeded
     * @param    {object}    eula   The EULA Object
     * @return   {boolean}   True if eula should be displayed, False otherwise.
     */
    function isHeliosEulaNeeded(eula) {
      return _.isEmpty(eula) ||
        (eula.signedVersion && eula.signedVersion < NgEulaService.heliosVersion);
    }

    /**
     * Return the external account ID for the logged in user in Helios.
     *
     * @method   getUserAccountId
     * @return   {string}   Account ID associated with the external user.
     */
    function getUserAccountId() {
      return _.get($rootScope, 'user.googleAccount.accountId') ||
        _.get($rootScope, 'user.salesforceAccount.accountId');
    }

    /**
     * Return the external User ID for the logged in user in Helios.
     *
     * @method   getUserExternalUserId
     * @return   {string}   User ID associated with the external user.
     */
    function getUserExternalUserId() {
      return _.get($rootScope, 'user.googleAccount.userId') ||
        _.get($rootScope, 'user.salesforceAccount.userId');
    }

    /**
     * Return whether the user is sales user.
     *
     * @method   isSalesforceUser
     * @param    {string}   User type 'sales' or 'support'.
     * @return   {boolean}   True if user is a sales user.
     */
    function isSalesforceUser(userType) {
      const isSupportUser = _.get($rootScope, 'user.salesforceAccount.isSupportUser', false);
      const isSalesUser = _.get($rootScope, 'user.salesforceAccount.isSalesUser', false);

      if (userType) {
        return userType === 'sales' ? isSalesUser : isSupportUser;
      }

      return isSalesUser || isSupportUser;
    }

    /**
     * Return the Salesforce company sales user selected.
     *
     * @method   getSalesforceAccount
     * @return   {object}   returns Salesforce company object.
     */
    function getSalesforceAccount() {
      var selectedAccount;

      if (isSalesforceUser()) {
        selectedAccount = _.get($rootScope, selectedAccountPath);
        if (!selectedAccount) {
          selectedAccount = localStorageService.get(selectedAccountKey);
          _.set($rootScope, selectedAccountPath, selectedAccount);
        }
        return selectedAccount;
      }
    }

    /**
     * Set/Reset the Salesforce company.
     *
     * @method   setSalesforceAccount
     * @param   {object}   Salesforce company object.
     */
    function setSalesforceAccount(account) {
      if (isSalesforceUser()) {
        if (account) {
          localStorageService.set(selectedAccountKey, account);
          _.set($rootScope, selectedAccountPath, account);
        } else {
          localStorageService.remove(selectedAccountKey);
          if ($rootScope.user && $rootScope.user.salesforceAccount) {
            delete $rootScope.user.salesforceAccount.selectedAccount;
          }
        }
      }
    }

    /**
     * Returns Salesforce account ID if user is impersonated with a salesforce account.
     *
     * @method   getImpersonatedSalesforceAccountId
     * @returns  {string}  returns impersonated Salesforce account ID.
     */
    function getImpersonatedSalesforceAccountId() {
      if (isSalesforceUser()) {
        var account = localStorageService.get(selectedAccountKey);

        if (account && account.id) {
          return account.id;
        }
      }
      return null;
    }

    /**
     * Fetch the EULA status for the current logged in account
     *
     * @method   getHeliosEulaStatus
     * @param    {object}   params   Holds the accountId of the user
     * @return   {object}   returns the promise
     */
    function getHeliosEulaStatus(params) {
      return $http.get(API.mcm('accountInfo'), { params: params }).then(
        function heliosEulaStatus(response) {
          return response.data;
        }
      );
    }

    /**
     * Update whether Helios License is Agreed
     *
     * @method   updateHeliosEulaStatus
     * @param    {object}   params   Holds the signed EULA object
     * @return   {object}   returns the promise
     */
    function updateHeliosEulaStatus(params) {
      return $http.put(API.mcm('accountInfo'), params).then(
        function updateStatus(response) {
          return response.data;
        }
      );
    }

    /**
     * Returns all the Google Projects for the current logged in user.
     *
     * @method   getGoogleProjects
     * @return   {object}   return the promise with list of project or error.
     */
    function getGoogleProjects() {
      return $http.get(API.mcm('baas/projects')).then(
        function getProjects(res) {
          return _.get(res.data, 'projects', []);
        }
      );
    }

    /**
     * Retrieves the current Helios Configuration
     *
     * @method   getHeliosConfiguration
     * @return   {object}  Returns the promise or the data
     */
    function getHeliosConfiguration() {
      if ($rootScope.isTenantUser()) {
        return $q.reject();
      }

      const configPromise = $injector.get('NgIrisContextService')?.irisContext?.
        selectedScope?.clusterId !== 1 && $injector.get('NgIrisContextService')?.irisContext?.
        basicClusterInfo?.mcmMode ? $http({
            method: 'GET',
            url: API.private('mcm/config'),
            headers: {
              'clusterId': $injector.get('NgIrisContextService')?.irisContext?.
              selectedScope?.clusterId,
            },
          }) :
          $http({
            method: 'GET',
            url: API.private('mcm/config'),
          });

      return configPromise.then(
        function getHeliosStatus(res) {
          decorateHeliosConfiguration(res.data);
          service.heliosStatus = res.data;
          return res.data;
        }
      );
    }

    /**
     * Updates the current Helios Configuration
     *
     * @method   updateHeliosConfiguration
     * @param    {object}   param   Helios Configuration
     * @return   {object}   Returns the promise of the http request
     */
    function updateHeliosConfiguration(param) {
      return $http.put(API.private('mcm/config'), param).then(
        function updateHeliosStatus(res) {
          decorateHeliosConfiguration(res.data);
          return res.data;
        }
      );
    }

    /**
     * Send provision information to BaaS so that GCP instance can be allocated
     * based on the user specification.
     *
     * @method   sendProvisionToBaas
     * @param    {object}    param    The BaaS provisioning configuration
     * @return   {object}    A promise object of the http request
     */
    function sendProvisionToBaas(param) {
      return $http.post(API.mcm('baas/provision'), param);
    }

    /**
     * Unregisters the registered Clusters in Helios
     *
     * @method  unregisterMcmCluster
     * @param   {object}   clusters   List of the cluster UID
     * @return  {object}   a promise with the unregistered clusters or error.
     */
    function unregisterMcmCluster(clusters) {
      return $http({
        method: 'post',
        url: API.mcmPublic('clusters/registrations'),
        data: clusters,
      }).then(function returnResponse(res) {
        return res.data || {};
      });
    }

    /**
     * Decorate the config object
     *
     * @method   decorateHeliosConfiguration
     * @param    {object}   config   Helios Configuration
     */
    function decorateHeliosConfiguration(config) {
      // Registration Status
      config._isRegistered =
        config.registrationStatus === 'kRegistrationCompleted';
      config._isRegistering =
        config.registrationStatus === 'kRegistrationInProgress';
      config._isNotRegistered =
        config.registrationStatus === 'kRegistrationNotDone';

      // Connection Status
      config._isConnected = _.get(config, 'connectionStatus.connectedToMcm');
      config._isConnecting = !config._isConnected && config.enableMcm;
      config._connectionError = config.connectionStatus.error;
      config._hasConnectionError = !!config.connectionStatus.error;

      // Downloading Certificates
      config._hasStartedRegistering = !config.enableMcm &&
        !config._isConnected && config._isRegistering;

      if (config._hasStartedRegistering) {
        config.enableMcm = true;
      }

      // Registration Error
      config._hasRegistrationError = !!config.registrationError;

      // Icons to be shown for Helios Status
      // Default Icon
      config._icon = '';

      switch (true) {
        case config._isRegistered && config._isConnected && config.enableMcm:
          config._icon = 'helios-online';
          break;
        case config._isRegistered &&
          (!config._isConnected || !config.enableMcm):
          config._icon = 'helios-error';
          break;
        default:
          break;
      }
    }

    /**
     * Register cluster to the Helios App.
     *
     * @method  registerClusterToApp
     * @param   {object}   config   The helios registration object.
     * @return  {object}   A promise with Successful registration or error.
     */
    function registerClusterToApp(config) {
      return $http({
        method: 'post',
        url: API.mcm('cluster/claim'),
        data: config
      });
    }

    /**
     * Unregister cluster from Helios App.
     *
     * @method   unregisterClusterFromHelios
     * @param    {object}   data   The cluster params to be deleted.
     * @return   {object}   A promise with successful unregistration or error.
     */
    function unregisterClusterFromHelios(data) {
      return $http({
        method: 'delete',
        url: API.private('mcm/cluster/registration/helios'),
        data: data,
      });
    }

    /**
     * Returns the status of all the clusters registered to the user.
     *
     * @method   getClustersStatus
     * @param    {object}    param   The cluster status parameters.
     * @return   {object}    A promise with the list of cluster or error.
     */
    function getClustersStatus(param) {
      return $http({
        method: 'get',
        url: API.mcm('clusters/connectionStatus'),
        param: param,
      }).then(function getClusters(resp) {
        return resp.data || [];
      });
    }

    /**
     * Triggers a sync of magneto state between Helios and Cluster.
     *
     * @method   syncState
     * @param    {object}   param   The account and cluster ID to be resetted.
     */
    function syncState(param) {
      return $http({
        method: 'post',
        url: API.mcm('syncClusterData'),
        param: param,
      });
    }

    return service;
  }

})(angular);
