/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AWSClaimStatus } from '../models/awsclaim-status';
import { AWSClaimRequest } from '../models/awsclaim-request';
import { HeliosClaimRequest } from '../models/helios-claim-request';
import { HeliosRegConfig } from '../models/helios-reg-config';
import { RigelClaimLogs } from '../models/rigel-claim-logs';
@Injectable({
  providedIn: 'root',
})
class RegistrationService extends __BaseService {
  static readonly GetAWSClaimStatusPath = '/aws-registration';
  static readonly AwsClaimPath = '/aws-registration';
  static readonly HeliosClaimPath = '/helios-registration';
  static readonly GetHeliosRegConfigPath = '/helios-registration-config';
  static readonly GetRigelClaimLogsPath = '/rigel-claim-logs';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get the status of the appliance registration with AWS.
   *
   * Get the status of the AWS claim.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetAWSClaimStatusResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<AWSClaimStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/aws-registration`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AWSClaimStatus>;
      })
    );
  }
  /**
   * Get the status of the appliance registration with AWS.
   *
   * Get the status of the AWS claim.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetAWSClaimStatus(regionId?: string,
    accessClusterId?: number): __Observable<AWSClaimStatus> {
    return this.GetAWSClaimStatusResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as AWSClaimStatus)
    );
  }

  /**
   * Register the appliance to AWS
   *
   * Claim to AWS.
   * @param params The `RegistrationService.AwsClaimParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to register the appliance with AWS.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  AwsClaimResponse(params: RegistrationService.AwsClaimParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/aws-registration`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Register the appliance to AWS
   *
   * Claim to AWS.
   * @param params The `RegistrationService.AwsClaimParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to register the appliance with AWS.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  AwsClaim(params: RegistrationService.AwsClaimParams): __Observable<null> {
    return this.AwsClaimResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Register to Helios.
   *
   * Claim to Helios.
   * @param params The `RegistrationService.HeliosClaimParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to claim to Helios.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  HeliosClaimResponse(params: RegistrationService.HeliosClaimParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/helios-registration`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Register to Helios.
   *
   * Claim to Helios.
   * @param params The `RegistrationService.HeliosClaimParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to claim to Helios.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  HeliosClaim(params: RegistrationService.HeliosClaimParams): __Observable<null> {
    return this.HeliosClaimResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Lists the Helios Registration Config.
   *
   * Lists the Helios Registration Config.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetHeliosRegConfigResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<HeliosRegConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/helios-registration-config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HeliosRegConfig>;
      })
    );
  }
  /**
   * Lists the Helios Registration Config.
   *
   * Lists the Helios Registration Config.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetHeliosRegConfig(regionId?: string,
    accessClusterId?: number): __Observable<HeliosRegConfig> {
    return this.GetHeliosRegConfigResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as HeliosRegConfig)
    );
  }

  /**
   * Lists the claim logs for rigel.
   *
   * Lists the logs during rigel cluster creation and claim.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetRigelClaimLogsResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<RigelClaimLogs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/rigel-claim-logs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RigelClaimLogs>;
      })
    );
  }
  /**
   * Lists the claim logs for rigel.
   *
   * Lists the logs during rigel cluster creation and claim.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetRigelClaimLogs(regionId?: string,
    accessClusterId?: number): __Observable<RigelClaimLogs> {
    return this.GetRigelClaimLogsResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as RigelClaimLogs)
    );
  }
}

module RegistrationService {

  /**
   * Parameters for AwsClaim
   */
  export interface AwsClaimParams {

    /**
     * Specifies the parameters to register the appliance with AWS.
     */
    body: AWSClaimRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for HeliosClaim
   */
  export interface HeliosClaimParams {

    /**
     * Specifies the parameters to claim to Helios.
     */
    body: HeliosClaimRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { RegistrationService }
