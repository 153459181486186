import { isFlashRecover } from '@cohesity/iris-core';
import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const remoteDisksAccess = (ctx: StateAccessContext) =>
  // Remote Disks feature is enabled if the feature flag is true OR
  // Cluster uses Disaggregated Storage.
   ctx.FEATURE_FLAGS.remoteDisksModule || isFlashRecover(ctx.irisContext);

export const RemoteDisksConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'remote-disks.**',
      url: '^/remote-disks',
      loadChildren: () => import('./remote-disks.module').then(m => m.RemoteDisksModule),
    },
  ],

  getStateAccessMap(): StateAccessMap {
    return {
      'remote-disks': remoteDisksAccess,
      'remote-disks.list': remoteDisksAccess,
    };
  },
};
