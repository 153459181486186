/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CreateClientcsrResponseBody } from '../models/create-clientcsr-response-body';
import { CreateCsrRequest } from '../models/create-csr-request';
import { ImportCertificateByClientcsrResponseBody } from '../models/import-certificate-by-clientcsr-response-body';
import { ImportCertificateByClientcsrRequest } from '../models/import-certificate-by-clientcsr-request';
import { GetCsrListResponseBody } from '../models/get-csr-list-response-body';
import { CreateCsrResponseBody } from '../models/create-csr-response-body';
import { UpdateCertificateByCsrResponseBody } from '../models/update-certificate-by-csr-response-body';
import { UpdateCertificateByCsrRequest } from '../models/update-certificate-by-csr-request';
import { CommonCsrResponseParams } from '../models/common-csr-response-params';
import { AnomalyAlert } from '../models/anomaly-alert';
import { SecurityConfigResponse } from '../models/security-config-response';
import { UpdateSecurityConfigRequest } from '../models/update-security-config-request';
import { CiphersResp } from '../models/ciphers-resp';
import { ModifyCiphersRequestBody } from '../models/modify-ciphers-request-body';
import { ObjectStoreCiphersResp } from '../models/object-store-ciphers-resp';
import { ModifyObjectStoreCiphersRequestBody } from '../models/modify-object-store-ciphers-request-body';
import { ListTrustedCasResult } from '../models/list-trusted-cas-result';
import { RegisterTrustedCas } from '../models/register-trusted-cas';
import { TrustedCa } from '../models/trusted-ca';
@Injectable({
  providedIn: 'root',
})
class SecurityService extends __BaseService {
  static readonly CreateClientcsrPath = '/client-csr';
  static readonly ImportCertificateByClientcsrPath = '/client-csr/certificate';
  static readonly GetCsrListPath = '/csr';
  static readonly CreateCsrPath = '/csr';
  static readonly UpdateCertificateByCsrPath = '/csr/certificate';
  static readonly GetCsrByIdPath = '/csr/{id}';
  static readonly DeleteCsrPath = '/csr/{id}';
  static readonly GetAnomalyAlertNotifLevelPath = '/mcm/security/anomalies';
  static readonly UpdateAnomalyAlertNotifLevelPath = '/mcm/security/anomalies';
  static readonly GetSecurityConfigPath = '/security-config';
  static readonly UpdateSecurityConfigPath = '/security-config';
  static readonly GetCiphersPath = '/security/ciphers';
  static readonly ModifyCiphersPath = '/security/ciphers';
  static readonly GetObjectStoreCiphersPath = '/security/object-store-ciphers';
  static readonly ModifyObjectStoreCiphersPath = '/security/object-store-ciphers';
  static readonly ListTrustedCasPath = '/trusted-cas';
  static readonly RegisterTrustedCasPath = '/trusted-cas';
  static readonly ListTrustedCaByIdPath = '/trusted-cas/{id}';
  static readonly UnregisterTrustedCaPath = '/trusted-cas/{id}';
  static readonly ValidateTrustedCaByIdPath = '/trusted-cas/{id}/validate';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Create Certificate Signing Requests on the cluster.
   *
   * Create two Certificate Signing Request on the cluster with the given details one each for client and server. Each service can have at most one outstanding pair of CSR.
   * @param params The `SecurityService.CreateClientcsrParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create the Certificate Signing Requests.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateClientcsrResponse(params: SecurityService.CreateClientcsrParams): __Observable<__StrictHttpResponse<CreateClientcsrResponseBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/client-csr`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateClientcsrResponseBody>;
      })
    );
  }
  /**
   * Create Certificate Signing Requests on the cluster.
   *
   * Create two Certificate Signing Request on the cluster with the given details one each for client and server. Each service can have at most one outstanding pair of CSR.
   * @param params The `SecurityService.CreateClientcsrParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create the Certificate Signing Requests.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateClientcsr(params: SecurityService.CreateClientcsrParams): __Observable<CreateClientcsrResponseBody> {
    return this.CreateClientcsrResponse(params).pipe(
      __map(_r => _r.body as CreateClientcsrResponseBody)
    );
  }

  /**
   * Import the signed certificates on the cluster after the Certificate Signing Requests are created.
   *
   * Import the signed certificates on the cluster after the Certificate Signing Requests are created.
   * @param params The `SecurityService.ImportCertificateByClientcsrParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to import the certificate.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ImportCertificateByClientcsrResponse(params: SecurityService.ImportCertificateByClientcsrParams): __Observable<__StrictHttpResponse<ImportCertificateByClientcsrResponseBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/client-csr/certificate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ImportCertificateByClientcsrResponseBody>;
      })
    );
  }
  /**
   * Import the signed certificates on the cluster after the Certificate Signing Requests are created.
   *
   * Import the signed certificates on the cluster after the Certificate Signing Requests are created.
   * @param params The `SecurityService.ImportCertificateByClientcsrParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to import the certificate.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ImportCertificateByClientcsr(params: SecurityService.ImportCertificateByClientcsrParams): __Observable<ImportCertificateByClientcsrResponseBody> {
    return this.ImportCertificateByClientcsrResponse(params).pipe(
      __map(_r => _r.body as ImportCertificateByClientcsrResponseBody)
    );
  }

  /**
   * List Certificate Signing Requests on the cluster.
   *
   * List Certificate Signing Requests on the cluster with service name filtering.
   * @param params The `SecurityService.GetCsrListParams` containing the following parameters:
   *
   * - `serviceName`: Specifies the Cohesity service name for which the CSR is generated. If this is not specified, all the csrs on the cluster will be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `ids`: Specifies the ids of the csrs. If this is not specified, all the csrs on the cluster will be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetCsrListResponse(params: SecurityService.GetCsrListParams): __Observable<__StrictHttpResponse<GetCsrListResponseBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.serviceName != null) __params = __params.set('serviceName', params.serviceName.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/csr`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetCsrListResponseBody>;
      })
    );
  }
  /**
   * List Certificate Signing Requests on the cluster.
   *
   * List Certificate Signing Requests on the cluster with service name filtering.
   * @param params The `SecurityService.GetCsrListParams` containing the following parameters:
   *
   * - `serviceName`: Specifies the Cohesity service name for which the CSR is generated. If this is not specified, all the csrs on the cluster will be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `ids`: Specifies the ids of the csrs. If this is not specified, all the csrs on the cluster will be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetCsrList(params: SecurityService.GetCsrListParams): __Observable<GetCsrListResponseBody> {
    return this.GetCsrListResponse(params).pipe(
      __map(_r => _r.body as GetCsrListResponseBody)
    );
  }

  /**
   * Create a Certificate Signing Request on the cluster.
   *
   * Create a Certificate Signing Request on the cluster with the given details. Each service has at most one outstanding CSR.
   * @param params The `SecurityService.CreateCsrParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a Certificate Signing Request.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateCsrResponse(params: SecurityService.CreateCsrParams): __Observable<__StrictHttpResponse<CreateCsrResponseBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/csr`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateCsrResponseBody>;
      })
    );
  }
  /**
   * Create a Certificate Signing Request on the cluster.
   *
   * Create a Certificate Signing Request on the cluster with the given details. Each service has at most one outstanding CSR.
   * @param params The `SecurityService.CreateCsrParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a Certificate Signing Request.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateCsr(params: SecurityService.CreateCsrParams): __Observable<CreateCsrResponseBody> {
    return this.CreateCsrResponse(params).pipe(
      __map(_r => _r.body as CreateCsrResponseBody)
    );
  }

  /**
   * Update the signed certificate on the cluster after a Certificate Signing Request is created.
   *
   * Update the signed certificate on the cluster after a Certificate Signing Request is created.
   * @param params The `SecurityService.UpdateCertificateByCsrParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to update the certificate.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateCertificateByCsrResponse(params: SecurityService.UpdateCertificateByCsrParams): __Observable<__StrictHttpResponse<UpdateCertificateByCsrResponseBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/csr/certificate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateCertificateByCsrResponseBody>;
      })
    );
  }
  /**
   * Update the signed certificate on the cluster after a Certificate Signing Request is created.
   *
   * Update the signed certificate on the cluster after a Certificate Signing Request is created.
   * @param params The `SecurityService.UpdateCertificateByCsrParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to update the certificate.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateCertificateByCsr(params: SecurityService.UpdateCertificateByCsrParams): __Observable<UpdateCertificateByCsrResponseBody> {
    return this.UpdateCertificateByCsrResponse(params).pipe(
      __map(_r => _r.body as UpdateCertificateByCsrResponseBody)
    );
  }

  /**
   * List the specified Certificate Signing Request.
   *
   * List the specified Certificate Signing Request.
   * @param params The `SecurityService.GetCsrByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the csr.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetCsrByIdResponse(params: SecurityService.GetCsrByIdParams): __Observable<__StrictHttpResponse<CommonCsrResponseParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/csr/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CommonCsrResponseParams>;
      })
    );
  }
  /**
   * List the specified Certificate Signing Request.
   *
   * List the specified Certificate Signing Request.
   * @param params The `SecurityService.GetCsrByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the csr.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetCsrById(params: SecurityService.GetCsrByIdParams): __Observable<CommonCsrResponseParams> {
    return this.GetCsrByIdResponse(params).pipe(
      __map(_r => _r.body as CommonCsrResponseParams)
    );
  }

  /**
   * Delete a Certificate Signing Request on the cluster.
   *
   * Delete a Certificate Signing Request on the cluster.
   * @param params The `SecurityService.DeleteCsrParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the csr to be deleted.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteCsrResponse(params: SecurityService.DeleteCsrParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/csr/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a Certificate Signing Request on the cluster.
   *
   * Delete a Certificate Signing Request on the cluster.
   * @param params The `SecurityService.DeleteCsrParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the csr to be deleted.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteCsr(params: SecurityService.DeleteCsrParams): __Observable<null> {
    return this.DeleteCsrResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get the anomaly details.
   *
   * Get the anomaly details.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Returns the response of the notification operation.
   */
  GetAnomalyAlertNotifLevelResponse(regionId?: string): __Observable<__StrictHttpResponse<AnomalyAlert>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/security/anomalies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AnomalyAlert>;
      })
    );
  }
  /**
   * Get the anomaly details.
   *
   * Get the anomaly details.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Returns the response of the notification operation.
   */
  GetAnomalyAlertNotifLevel(regionId?: string): __Observable<AnomalyAlert> {
    return this.GetAnomalyAlertNotifLevelResponse(regionId).pipe(
      __map(_r => _r.body as AnomalyAlert)
    );
  }

  /**
   * Updates the anomaly notification threshold.
   *
   * Update the anomaly settings such as notification threshold.
   * @param body Specifies the parameters to update an account notification threshold
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  UpdateAnomalyAlertNotifLevelResponse(body: AnomalyAlert,
    regionId?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/mcm/security/anomalies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Updates the anomaly notification threshold.
   *
   * Update the anomaly settings such as notification threshold.
   * @param body Specifies the parameters to update an account notification threshold
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  UpdateAnomalyAlertNotifLevel(body: AnomalyAlert,
    regionId?: string): __Observable<null> {
    return this.UpdateAnomalyAlertNotifLevelResponse(body, regionId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get cluster security settings.
   *
   * Get cluster security settings.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetSecurityConfigResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<SecurityConfigResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/security-config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SecurityConfigResponse>;
      })
    );
  }
  /**
   * Get cluster security settings.
   *
   * Get cluster security settings.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetSecurityConfig(regionId?: string,
    accessClusterId?: number): __Observable<SecurityConfigResponse> {
    return this.GetSecurityConfigResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as SecurityConfigResponse)
    );
  }

  /**
   * Update cluster security settings.
   *
   * Update cluster security settings.
   * @param params The `SecurityService.UpdateSecurityConfigParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to update security config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateSecurityConfigResponse(params: SecurityService.UpdateSecurityConfigParams): __Observable<__StrictHttpResponse<SecurityConfigResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/security-config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SecurityConfigResponse>;
      })
    );
  }
  /**
   * Update cluster security settings.
   *
   * Update cluster security settings.
   * @param params The `SecurityService.UpdateSecurityConfigParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to update security config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateSecurityConfig(params: SecurityService.UpdateSecurityConfigParams): __Observable<SecurityConfigResponse> {
    return this.UpdateSecurityConfigResponse(params).pipe(
      __map(_r => _r.body as SecurityConfigResponse)
    );
  }

  /**
   * Gets the list of ciphers enabled on the cluster.
   *
   * Gets the list of ciphers enabled on the cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetCiphersResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<CiphersResp>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/security/ciphers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CiphersResp>;
      })
    );
  }
  /**
   * Gets the list of ciphers enabled on the cluster.
   *
   * Gets the list of ciphers enabled on the cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetCiphers(regionId?: string,
    accessClusterId?: number): __Observable<CiphersResp> {
    return this.GetCiphersResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as CiphersResp)
    );
  }

  /**
   * Enable/Disable a list of ciphers on the cluster. Iris must be restarted for the change to take effect.
   *
   * Enable/Disable a list of ciphers on the cluster.
   * @param params The `SecurityService.ModifyCiphersParams` containing the following parameters:
   *
   * - `body`: Enable/Disable ciphers.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ModifyCiphersResponse(params: SecurityService.ModifyCiphersParams): __Observable<__StrictHttpResponse<CiphersResp>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/security/ciphers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CiphersResp>;
      })
    );
  }
  /**
   * Enable/Disable a list of ciphers on the cluster. Iris must be restarted for the change to take effect.
   *
   * Enable/Disable a list of ciphers on the cluster.
   * @param params The `SecurityService.ModifyCiphersParams` containing the following parameters:
   *
   * - `body`: Enable/Disable ciphers.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ModifyCiphers(params: SecurityService.ModifyCiphersParams): __Observable<CiphersResp> {
    return this.ModifyCiphersResponse(params).pipe(
      __map(_r => _r.body as CiphersResp)
    );
  }

  /**
   * Gets the list of object store ciphers enabled on the cluster.
   *
   * Gets the list of object store ciphers enabled on the cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetObjectStoreCiphersResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<ObjectStoreCiphersResp>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/security/object-store-ciphers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ObjectStoreCiphersResp>;
      })
    );
  }
  /**
   * Gets the list of object store ciphers enabled on the cluster.
   *
   * Gets the list of object store ciphers enabled on the cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetObjectStoreCiphers(regionId?: string,
    accessClusterId?: number): __Observable<ObjectStoreCiphersResp> {
    return this.GetObjectStoreCiphersResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as ObjectStoreCiphersResp)
    );
  }

  /**
   * Enable/Disable a list of object store ciphers on the cluster. Bridge must be restarted for the change to take effect.
   *
   * Enable/Disable a list of object store ciphers on the cluster.
   * @param params The `SecurityService.ModifyObjectStoreCiphersParams` containing the following parameters:
   *
   * - `body`: Enable/Disable object store ciphers.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ModifyObjectStoreCiphersResponse(params: SecurityService.ModifyObjectStoreCiphersParams): __Observable<__StrictHttpResponse<ObjectStoreCiphersResp>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/security/object-store-ciphers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ObjectStoreCiphersResp>;
      })
    );
  }
  /**
   * Enable/Disable a list of object store ciphers on the cluster. Bridge must be restarted for the change to take effect.
   *
   * Enable/Disable a list of object store ciphers on the cluster.
   * @param params The `SecurityService.ModifyObjectStoreCiphersParams` containing the following parameters:
   *
   * - `body`: Enable/Disable object store ciphers.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ModifyObjectStoreCiphers(params: SecurityService.ModifyObjectStoreCiphersParams): __Observable<ObjectStoreCiphersResp> {
    return this.ModifyObjectStoreCiphersResponse(params).pipe(
      __map(_r => _r.body as ObjectStoreCiphersResp)
    );
  }

  /**
   * List all Certificates with cluster trust store.
   *
   * List all trusted certificates in cluster trust store.
   * @param params The `SecurityService.ListTrustedCasParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `names`: Specifies the names of the certificates to be returned.
   *
   * - `ids`: Specifies the ids of the certificates to be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListTrustedCasResponse(params: SecurityService.ListTrustedCasParams): __Observable<__StrictHttpResponse<ListTrustedCasResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.names || []).forEach(val => {if (val != null) __params = __params.append('names', val.toString())});
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/trusted-cas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListTrustedCasResult>;
      })
    );
  }
  /**
   * List all Certificates with cluster trust store.
   *
   * List all trusted certificates in cluster trust store.
   * @param params The `SecurityService.ListTrustedCasParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `names`: Specifies the names of the certificates to be returned.
   *
   * - `ids`: Specifies the ids of the certificates to be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListTrustedCas(params: SecurityService.ListTrustedCasParams): __Observable<ListTrustedCasResult> {
    return this.ListTrustedCasResponse(params).pipe(
      __map(_r => _r.body as ListTrustedCasResult)
    );
  }

  /**
   * Register CA Certificate to the cluster trust store.
   *
   * Register CA Certificate to the cluster trust store.
   * @param params The `SecurityService.RegisterTrustedCasParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to register a Certificate.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RegisterTrustedCasResponse(params: SecurityService.RegisterTrustedCasParams): __Observable<__StrictHttpResponse<ListTrustedCasResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/trusted-cas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListTrustedCasResult>;
      })
    );
  }
  /**
   * Register CA Certificate to the cluster trust store.
   *
   * Register CA Certificate to the cluster trust store.
   * @param params The `SecurityService.RegisterTrustedCasParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to register a Certificate.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RegisterTrustedCas(params: SecurityService.RegisterTrustedCasParams): __Observable<ListTrustedCasResult> {
    return this.RegisterTrustedCasResponse(params).pipe(
      __map(_r => _r.body as ListTrustedCasResult)
    );
  }

  /**
   * List the specified Certificate.
   *
   * List the specified Certificate.
   * @param params The `SecurityService.ListTrustedCaByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the certificate.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListTrustedCaByIdResponse(params: SecurityService.ListTrustedCaByIdParams): __Observable<__StrictHttpResponse<TrustedCa>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/trusted-cas/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TrustedCa>;
      })
    );
  }
  /**
   * List the specified Certificate.
   *
   * List the specified Certificate.
   * @param params The `SecurityService.ListTrustedCaByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the certificate.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListTrustedCaById(params: SecurityService.ListTrustedCaByIdParams): __Observable<TrustedCa> {
    return this.ListTrustedCaByIdResponse(params).pipe(
      __map(_r => _r.body as TrustedCa)
    );
  }

  /**
   * Unregister CA Certificate from the cluster trust store.
   *
   * Unregister CA Certificate from the cluster trust store.
   * @param params The `SecurityService.UnregisterTrustedCaParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the certificate to be unregistered.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  UnregisterTrustedCaResponse(params: SecurityService.UnregisterTrustedCaParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/trusted-cas/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Unregister CA Certificate from the cluster trust store.
   *
   * Unregister CA Certificate from the cluster trust store.
   * @param params The `SecurityService.UnregisterTrustedCaParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the certificate to be unregistered.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  UnregisterTrustedCa(params: SecurityService.UnregisterTrustedCaParams): __Observable<null> {
    return this.UnregisterTrustedCaResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Validate CA Certificate.
   *
   * Certificate will be checked for Expiration and Revocation.
   * @param params The `SecurityService.ValidateTrustedCaByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the certificate to be validated.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ValidateTrustedCaByIdResponse(params: SecurityService.ValidateTrustedCaByIdParams): __Observable<__StrictHttpResponse<TrustedCa>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/trusted-cas/${encodeURIComponent(params.id)}/validate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TrustedCa>;
      })
    );
  }
  /**
   * Validate CA Certificate.
   *
   * Certificate will be checked for Expiration and Revocation.
   * @param params The `SecurityService.ValidateTrustedCaByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the certificate to be validated.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ValidateTrustedCaById(params: SecurityService.ValidateTrustedCaByIdParams): __Observable<TrustedCa> {
    return this.ValidateTrustedCaByIdResponse(params).pipe(
      __map(_r => _r.body as TrustedCa)
    );
  }
}

module SecurityService {

  /**
   * Parameters for CreateClientcsr
   */
  export interface CreateClientcsrParams {

    /**
     * Specifies the parameters to create the Certificate Signing Requests.
     */
    body: CreateCsrRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ImportCertificateByClientcsr
   */
  export interface ImportCertificateByClientcsrParams {

    /**
     * Specifies the parameters to import the certificate.
     */
    body: ImportCertificateByClientcsrRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetCsrList
   */
  export interface GetCsrListParams {

    /**
     * Specifies the Cohesity service name for which the CSR is generated. If this is not specified, all the csrs on the cluster will be returned.
     */
    serviceName?: 'iris';

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the ids of the csrs. If this is not specified, all the csrs on the cluster will be returned.
     */
    ids?: Array<string>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateCsr
   */
  export interface CreateCsrParams {

    /**
     * Specifies the parameters to create a Certificate Signing Request.
     */
    body: CreateCsrRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateCertificateByCsr
   */
  export interface UpdateCertificateByCsrParams {

    /**
     * Specifies the parameters to update the certificate.
     */
    body: UpdateCertificateByCsrRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetCsrById
   */
  export interface GetCsrByIdParams {

    /**
     * Specifies the id of the csr.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteCsr
   */
  export interface DeleteCsrParams {

    /**
     * Specifies the id of the csr to be deleted.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateSecurityConfig
   */
  export interface UpdateSecurityConfigParams {

    /**
     * Specifies the parameters to update security config.
     */
    body: UpdateSecurityConfigRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ModifyCiphers
   */
  export interface ModifyCiphersParams {

    /**
     * Enable/Disable ciphers.
     */
    body: ModifyCiphersRequestBody;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ModifyObjectStoreCiphers
   */
  export interface ModifyObjectStoreCiphersParams {

    /**
     * Enable/Disable object store ciphers.
     */
    body: ModifyObjectStoreCiphersRequestBody;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ListTrustedCas
   */
  export interface ListTrustedCasParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the names of the certificates to be returned.
     */
    names?: Array<string>;

    /**
     * Specifies the ids of the certificates to be returned.
     */
    ids?: Array<string>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for RegisterTrustedCas
   */
  export interface RegisterTrustedCasParams {

    /**
     * Specifies the parameters to register a Certificate.
     */
    body: RegisterTrustedCas;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ListTrustedCaById
   */
  export interface ListTrustedCaByIdParams {

    /**
     * Specifies the id of the certificate.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UnregisterTrustedCa
   */
  export interface UnregisterTrustedCaParams {

    /**
     * Specifies the id of the certificate to be unregistered.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ValidateTrustedCaById
   */
  export interface ValidateTrustedCaByIdParams {

    /**
     * Specifies the id of the certificate to be validated.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { SecurityService }
