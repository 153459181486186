import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Controls, NgxSubFormComponent, subformComponentProviders } from 'ngx-sub-form';
import { Mounts } from './settings-list-mounts.model';

/**
 * Component for capturing number of mount points needed for a backup job.
 */
@Component({
  selector: 'coh-settings-list-mounts',
  templateUrl: './settings-list-mounts.component.html',
  providers: subformComponentProviders(SettingsListMountsComponent)
})
export class SettingsListMountsComponent extends NgxSubFormComponent<Mounts> {

  protected getFormControls(): Controls<Mounts> {
    return {
      value: new UntypedFormControl(null),
    };
  }
}
