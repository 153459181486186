import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { InfoBadgeComponent } from './info-badge.component';

@NgModule({
  imports: [
    MatTooltipModule,
    CommonModule,
  ],
  declarations: [InfoBadgeComponent],
  exports: [InfoBadgeComponent]
})
export class InfoBadgeModule { }
