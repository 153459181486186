<mat-select [formControl]="selectControl"
  cogDataId="select"
  [inheritDataId]="true"
  [multiple]="multiSelect"
  [required]="required"
  [placeholder]="placeholder"
  [required]="required"
  [compareWith]="compareWith"
  *ngIf="initialized"
  panelClass="{{'cog-data-tree-select-panel' + (size === 'large' ? ' cog-mat-select-panel-lg'  : '')}}">

  <mat-select-trigger *ngIf="selectControl.value">
    <!-- Show simple text for single select -->
    <ng-container *ngIf="!multiSelect && !triggerTemplate">
      {{ nameFn(selectControl.value) }}
    </ng-container>
    <ng-container *ngIf="!multiSelect && triggerTemplate">
      <ng-container *ngTemplateOutlet="triggerTemplate; context: { $implicit: selectControl.value}"></ng-container>
    </ng-container>

    <!-- Show chips for multi select -->
    <ng-container *ngIf="multiSelect">
      <mat-chip-list [disabled]="disabled">
        <mat-chip *ngFor="let node of selectControl.value; index as i"
          cogDataId="selected-item-{{ nameFn(node) }}"
          [inheritDataId]="true"
          [selectable]="true"
          [removable]="true"
          (removed)="removeSelection(i)">
          <ng-container *ngIf="!triggerTemplate">
            <span class="chip-label">{{ nameFn(node) }}</span>
            <!-- Mat Icon aligns better inside of mat-chip then cog-icon does -->
            <mat-icon cogDataId="selected-item-remove-{{ nameFn(node) }}"
              [inheritDataId]="true"
              matChipRemove>
              cancel
            </mat-icon>
          </ng-container>
          <ng-container *ngIf="triggerTemplate">
            <ng-container *ngTemplateOutlet="triggerTemplate; context: { $implicit: node}"></ng-container>
          </ng-container>
        </mat-chip>
      </mat-chip-list>
    </ng-container>
  </mat-select-trigger>

  <mat-option cogDataId="option-search" [inheritDataId]="true">
    <ngx-mat-select-search [formControl]="searchControl"
      [noEntriesFoundLabel]="noResultsLabel || intl.noResults"
      [placeholderLabel]="intl.search">
    </ngx-mat-select-search>
  </mat-option>

  <!-- This sets up the virtual scroll container. This needs to have it's height set manually
       in order to adjust when there are fewer nodes. It should be 3rem smaller than the max
       panel height to account for the search option above -->
  <cdk-virtual-scroll-viewport itemSize="48"
    minBufferPx="1000"
    maxBufferPx="4000"
    [style.height]="scrollerHeight">
    <!-- This uses the current view (filtered list + expanded nodes) to determine how large the
          scrollport should be. -->
    <ng-container *cdkVirtualFor="let item of data.currentView$ | async"></ng-container>

    <!-- allNodes is the current view, filtered by the current page in the scroller -->
    <ng-container *ngFor="let node of allNodes">
      <!-- Use opt-groups to show non-leaf nodes which cannot be selected -->
      <mat-optgroup *ngIf="treeControl.isExpandable(node) && !canSelectFn(node)"
        cogTooltipChangeDetection
        [matTooltip]="nameFn(node)"
        [matTooltipShowDelay]="1000"
        matTooltipPosition="below"
        cogDataId="option-group-{{nameFn(node)}}"
        [inheritDataId]="true">
        <!-- mat-optgroup's default ng-content places content inside of a label. This causes issues
             with button clicks. It places anything in ng-container outside of the label though.-->
        <ng-container>
          <!-- This creates two mat-optgroup-labels inside of the optgroup though. The css will hide the
               first (empty) one, and then allow this one to show. -->
          <div class="mat-optgroup-label"
            [style.padding-left]="getOptionPadding(node)">
            <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: node}"></ng-container>
          </div>
        </ng-container>
      </mat-optgroup>

      <!-- Use mat-option to show all selectable nodes, as well as unselectable leaf-nodes -->
      <mat-option *ngIf="canSelectFn(node) || !treeControl.isExpandable(node)"
        cogTooltipChangeDetection
        [matTooltip]="nameFn(node)"
        [style.padding-left]="getOptionPadding(node, true)"
        [matTooltipShowDelay]="1000"
        matTooltipPosition="below"
        [value]="node"
        [class.hide-checkbox]="multiSelect && !canSelectFn(node)"
        [disabled]="!canSelectFn(node)"
        cogDataId="option-{{nameFn(node)}}"
        id="option-{{node.id}}"
        [inheritDataId]="true">
        <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: node}"></ng-container>
      </mat-option>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</mat-select>

<!-- The render template for a node detail -->
<ng-template #optionTemplate let-node>
  <button type="button"
    mat-icon-button
    *ngIf="treeControl.isExpandable(node)"
    cogDataId="expand-{{nameFn(node)}}"
    [inheritDataId]="true"
    (click)="toggleNodeExpansion(node); $event.stopPropagation()">
    <cog-icon shape="{{ treeControl.isExpanded(this.node) ? 'expand_more' : 'chevron_right' }}"></cog-icon>
  </button>
  <ng-container *ngIf="size === 'medium'">
    <ng-container *ngTemplateOutlet="detailTemplate || defaultDetail; context: { $implicit: node}"></ng-container>
  </ng-container>
  <div *ngIf="size === 'large'" class="option-display">
    <ng-container *ngTemplateOutlet="detailTemplate || defaultDetail; context: { $implicit: node}"></ng-container>
  </div>
</ng-template>

<ng-template #defaultDetail let-node>
  <cog-icon class="node-icon" *ngIf="iconFn(node)" [shape]="iconFn(node)"></cog-icon>
  <span>{{ nameFn(node) }}</span>
</ng-template>
