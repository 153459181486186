<nav *ngIf="disabled || pageCount >= 2">
  <ul class="pagination angular-animate c-fade-sm" *ngIf="!disabled">
    <li class="pagination-endcap-left" *ngIf="displayedPages[0] !== 0">
      <a id="select-{{name}}-page-1-anchor"(click)="selectPage(0)">
        1
      </a>
    </li>

    <li *ngFor="let page of displayedPages" [class.active]="pageIndex===page">
      <a id="select-{{name}}-page-{{page}}-anchor" (click)="selectPage(page)">
        {{page + 1}}
      </a>
    </li>

    <li class="pagination-endcap-right"
      *ngIf="displayedPages[displayedPages.length-1] !== pageCount - 1">
      <a id="select-{{name}}-last-page-anchor"
        (click)="selectPage(pageCount -1)">
        {{pageCount}}
      </a>
    </li>
  </ul>

  <div class="btn-group">
    <button class="btn"
      id="{{name}}-pagination-toggle"
      type="button"
      (click)="togglePagination()"
      matTooltip="{{(disabled ? 'globalPaginationPagination.enablePagination'
        : 'globalPaginationPagination.disablePagination') | translate}}">
        <div class="icn-md"
          [ngClass]="{
            'icn-pagination-enable' : disabled,
            'icn-pagination-disable': !disabled
          }">
        </div>
      </button>
    </div>
</nav>
