import { FilterFieldOption } from './filter.types';

/**
 * list of fields which will have filters
 */
export enum FilterableFields {
  anomalies = 'anomalies',
  classification = 'classification',
  clusters = 'clusters',
  sources = 'sources',
  status = 'status',
  tags = 'tags',
  threats = 'threats',
  type = 'type',
}

/**
 * translation key mapping for all the filterable fields
 */
export const filterFieldLabelKey: Record<FilterableFields, string> = {
  [FilterableFields.anomalies]: 'anomalies',
  [FilterableFields.classification]: 'classification',
  [FilterableFields.clusters]: 'clusters',
  [FilterableFields.sources]: 'sources',
  [FilterableFields.status]: 'protectionStatus',
  [FilterableFields.tags]: 'tags',
  [FilterableFields.threats]: 'threats',
  [FilterableFields.type]: 'type',
};

/**
 * filterable field list
 */
export const filterableFieldList: FilterFieldOption[] = Object.values(FilterableFields).map((field) => ({
  key: field,
  labelKey: filterFieldLabelKey[field],
}));
