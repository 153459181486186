import { DecommissionCloudRigelStatusResult, ProvisionCloudRigelStatusResult, Rigel } from '@cohesity/api/rms';
import { NavItem } from '@cohesity/helix';
import { decorateInterface } from '@cohesity/utils';
import { DmsConnectionMetric } from './dms-connection';

/**
 * Data model for DMaaS connector.
 */
export class DmsConnector extends decorateInterface<Rigel>() {
  /** True if the connector is healthy */
  isHealthy?: boolean;

  /** Connection Metric */
  stats?: DmsConnectionMetric;

  /** Throughput */
  throughput?: number;

  /** Last Connection Time */
  lastConnectionTimeUsecs?: number;

  /** True if both data and control planes are connected */
  isConnected?: boolean;

  /** actions for connector. */
  actions?: NavItem[];

  /**
   * Release info for display purpose.
   */
  release?: string;

  /** Deletion status of a rigel. */
  deletionStatus?: string;

  /** Status of a rigel provision or decommission */
  cloudStatus?: ProvisionCloudRigelStatusResult | DecommissionCloudRigelStatusResult;

  /** Rigel upgrade error message if upgrade failed */
  upgradeErrorMsg?: string;

  constructor(rigel: Rigel) {
    super(rigel);
    this.isHealthy = Boolean(this.isConnectedToControlPlane && this.isConnectedToDataPlane);
    this.lastConnectionTimeUsecs = Math.min(this.lastControlPlaneConnTimeUsecs, this.lastDataplaneConnTimeUsecs);
    this.isConnected = this.isConnectedToControlPlane && this.isConnectedToDataPlane;

    if (this.softwareVersion) {
      const split = this.softwareVersion.split('_')?.[1]?.split('-');

      this.release = split?.[1] || this.softwareVersion;
    }
  }
}
