import { PolicyRunResult, Range } from '@cohesity/api/argus';

/**
 * Prioritizing sensitivity range
 */
export const SensitivityPriority = {
  [Range.high]: 0,
  [Range.medium]: 1,
  [Range.low]: 2,
};

/**
 * Policy sensitivity sorting callback
 *
 * @param a first policy result
 * @param b second policy result
 * @returns sorting constant
 */
export const sortSensitivityPolicy = (a: PolicyRunResult, b: PolicyRunResult, isAsc: boolean = true): number =>
  (SensitivityPriority[a.sensitivity] < SensitivityPriority[b.sensitivity] ? -1 : 1) * (isAsc ? 1 : -1);

/**
 * Sensitivity range sorting
 *
 * @param a first policy result
 * @param b second policy result
 * @returns sorting constant
 */
export const sortSensitivity = (a: Range, b: Range, isAsc: boolean = true): number =>
  (SensitivityPriority[a] < SensitivityPriority[b] ? -1 : 1) * (isAsc ? 1 : -1);
