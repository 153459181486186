import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const configureAccess = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.rpaasEnabled && ctx.clusterInfo?._serviceType === 'rpaas';

export const VaultsConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'rpaasVaults.**',
      url: '/rpaas/vaults',
      loadChildren: () => import('./vaults.module').then(m => m.VaultsModule),
    },
  ],
  allClusterMap: {
    'rpaasVaults.list': true,
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'rpaasVaults.list': configureAccess,
    };
  },
};
