import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const configureAccess = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.rpaasEnabled && ctx.clusterInfo?._serviceType === 'rpaas';

export const OnboardingConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'rpaasOnboarding.**',
      url: '/rpaas/onboarding',
      loadChildren: () => import('./onboarding.module').then(m => m.OnboardingModule),
    },
  ],
  allClusterMap: {
    'rpaasOnboarding': true,
    'rpaasOnboarding.landing': true,
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'rpaasOnboarding': configureAccess,
      'rpaasOnboarding.landing': configureAccess,
    };
  },
};
