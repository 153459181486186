import { ReportColumnConfigs } from '../common-report-items/custom-reports.service';
import { ReportSection } from '../iris-reporting.model';
import {
  commonReportConfigItems,
  getStorageConsumptionColumns,
  storageConsumptionCommonColumns,
} from './common-report-config-items';

/**
 * This is the layout config for the storage consumption by organization report.
 */
export const storageConsumptionOrganizationReport: ReportSection[] = [
  {
    title: 'reporting.summary',
    items: [
      {
        id: 'statlistItem',
        componentIds: ['1301'],
        componentNames: ['StorageConsumptionOrganizationsSummary'],
        params: {
          items: [
            {
              title: 'reporting.organizations',
              translate: true,
              measurement: {
                valueKey: 'countOrganizationId',
                dataType: 'number',
              },
            },
            ...commonReportConfigItems.storageConsumption.glanceBar,
          ],
        },
      },
    ],
  },
  {
    title: 'reporting.data',
    isFullData: true,
    items: [
      {
        id: 'tableItem',
        componentIds: ['1300'],
        componentNames: ['AllStorageConsumptionByOrganizations'],
        params: {
          columns: [
            {
              title: 'organization',
              translate: true,
              key: 'organizationName',
              primary: {
                key: 'organizationName',
                dataType: 'text',
              },
              subtitle: {
                key: 'systemNames',
                dataType: 'textList',
                renderParams: {
                  isArray: true,
                  translateSummary: 'reporting.countSystems',
                }
              },
            },
            ...getStorageConsumptionColumns('AllStorageConsumptionByOrganizations'),
            {
              title: 'uniqueStorage',
              translate: true,
              enabled: false,
              key: 'uniqueStorageBytes',
              primary: {
                key: 'uniqueStorageBytes',
                dataType: 'bytes',
              },
            },
          ],
        },
      },
    ],
  },
];

/**
 * This is metadata needed to determine available chart customizations.
 */
export const storageConsumptionOrganizationColumns: ReportColumnConfigs = {
  organizationName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  organizationId: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  ...storageConsumptionCommonColumns,
};
