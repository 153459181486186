// Module: Kuiper VM details tooltip

;(function(angular) {
  'use strict';

  angular
    .module('C.devOps')
    .controller('KuiperLaunchConsoleCtrl', KuiperLaunchConsoleCtrlFn)
    .component('cKuiperLaunchConsole',{
      templateUrl:
      'app/devops/hypervisor/hypervisor-console/hypervisor-console.html',
      controller: 'KuiperLaunchConsoleCtrl',
    });

  function KuiperLaunchConsoleCtrlFn(_, $state, HypervisorVmService, evalAJAX) {

    var $ctrl = this;

    // Declare component methods and variable.
    _.assign($ctrl, {
      // Component life cycle methods.
      $onInit: getConsoleUrl,

      // UI States
      url: null,
      isDataReady: false,
    });

    /**
     * Gets the console url of specified VM
     *
     * @method   getConsoleUrl
     * @return   {Void}
     */
    function getConsoleUrl() {
      var params = {
        query: $state.params.uuid,
      };
      HypervisorVmService.getHypervisorVMs(params).then(
        function getHypervisorVmSuccess(hypervisorVms) {
          if(hypervisorVms.length) {
            $ctrl.url = HypervisorVmService.launchVncConsole(hypervisorVms[0]);
          }
        },evalAJAX.errorMessage).finally(
        function getHypervisorVmFinally() {
          $ctrl.isDataReady = true;
      });
    }
  }
})(angular);