import { ComponentPortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Inject,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
  AfterViewInit,
} from '@angular/core';
import { MatLegacyColumnDef as MatColumnDef, MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { TableComponent } from '@cohesity/helix';
import { get } from 'lodash';
import { isObservable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AutoDestroyable } from '@cohesity/utils';

import { ComponentExtensionImpl } from '../component-outlet-extension/component-extension-config';
import { PluginRegistryService } from '../plugin-registry.service';
import { tableColumnExtension, TableColumnExtensionPointConfig } from './table-column-extension-config';

@Component({
  selector: 'coh-table-column-extension',
  templateUrl: './table-column-extension.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableColumnExtensionComponent extends AutoDestroyable implements AfterViewInit, OnInit {

  /**
   * The name of the extension to look up. If there are no extensions registered, this component will be empty.
   */
  @Input() extensionPoint: string;

  /**
   * Starting position to insert columns at. The default position is the second column in the table.
   */
  @Input() position = 1;

  /**
   * Returns true if the table uses a flex layout or not.
   */
  get isFlex() {
    return this.table.isFlex;
  }

  /**
   * A list of configs and the component portals used to display them.
   */
  extensionConfigs: {
    config: TableColumnExtensionPointConfig;
    portal: ComponentPortal<ComponentExtensionImpl>;
  }[] = [];

  /**
   * The template reference for the column definitions.
   */
  @ViewChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;

  /**
   * Gets the data for the row to pass to the extension cell component.
   */
  @Input() getRowDataFn: (row) => any = row => row;


  constructor(private registry: PluginRegistryService,
    @Inject(TableComponent) private table: TableComponent<any>,
    @Inject(MatTable) private matTable: MatTable<any>,
    private cdr: ChangeDetectorRef) {
    super();
  }

  /**
   * Sets the extension data on the cell instance whenever it is attached
   * using the getRowDataFn to achieve the data for the row.
   */
  attachCellPortal(portal: ComponentRef<ComponentExtensionImpl>, row) {
    if (portal.instance && row && this.getRowDataFn) {
      const result = this.getRowDataFn(row);
      if (isObservable(result)) {
        result.subscribe(rowData => {
          portal.instance.extensionData = rowData;
          portal.changeDetectorRef.detectChanges();
        });
      } else {
        portal.instance.extensionData = result;
        portal.changeDetectorRef.detectChanges();
      }
    }
  }

  /**
   * After the view initalizes, update the column defs whenever the column defs view children changes.
   */
  ngAfterViewInit() {
    this.columnDefs.changes.pipe(
      this.untilDestroy(),
      startWith(this.columnDefs),
    ).subscribe(() => this.updateColumnDefs());
  }

  /**
   * Lookup available extensions in the registry and subscribe to changes
   */
  ngOnInit() {
    this.registry
      .getRegisteredExtensions(this.extensionPoint, tableColumnExtension)
      .pipe(
        this.untilDestroy(),
        map((configs: TableColumnExtensionPointConfig[]) => configs.map(config => ({
          config,
          portal: new ComponentPortal(config.cellComponent),
        }))
      ))
      .subscribe(configs => (this.extensionConfigs = configs));
  }

  /**
   * Triggers after the view is checked.
   */
  updateColumnDefs() {
    this.columnDefs.forEach((colDef, colIndex) => {
      this.matTable.addColumnDef(colDef);

      const headerColumns = get(this.matTable, '_headerRowDefs[0].columns', []);
      const rowColumns = get(this.matTable, '_rowDefs[0].columns', []);

      // Do not add the column to the header if it already contains it.
      if (!headerColumns.includes(colDef.name)) {
        headerColumns.splice(this.position + colIndex, 0, colDef.name);
      }

      // If the tenant column is not updated in the column definition
      if (!rowColumns.includes(colDef.name)) {
        rowColumns.splice(this.position + colIndex, 0, colDef.name);
      }
    });
    this.cdr.detectChanges();
  }
}
