import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SourceSpecialParameter } from '@cohesity/api/v1';
import { DataTreeNodeContext, DataTreeNodeDetail } from '@cohesity/helix';

import { AdSourceDataNode } from '../ad-source-data-node';

/**
 * This component is used to set and update source special params for active directory nodes.
 * It is not created directly, but returned from a call to getSpecialParametersComponent
 * in AdSourceTreeService.
 */
@Component({
  selector: 'coh-ad-physical-host-options',
  templateUrl: './ad-physical-host-options.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdPhysicalHostOptionsComponent implements DataTreeNodeDetail<AdSourceDataNode> {
  /**
   * The node context, including info about the node and it's selection status.
   */
  @Input() nodeContext: DataTreeNodeContext<AdSourceDataNode>;

  /**
   * Gets the node from the nodeContext.
   */
  get node(): AdSourceDataNode {
    return this.nodeContext.node;
  }

  /**
   * Gets the current options for the node. This either gets them from the selection options, or
   * the default params for the node.
   */
  get currentOptions(): SourceSpecialParameter {
    return this.nodeContext.selection.getOptionsForNode(this.node.id) || this.node.getDefaultSourceParameters();
  }

  /**
   * Return true if the current source params have enabled system backup. This is used
   * to initialize the form value.
   */
  get systemBackupEnabled() {
    const physicalParams = this.currentOptions.physicalSpecialParameters;
    return physicalParams && physicalParams.enableSystemBackup;
  }

  /**
   * The form to contain the options.
   */
  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private cdr: ChangeDetectorRef) {}

  /**
   * Updates the form based on the current options setting in preparation for displaying the form
   * dialog.
   */
  updateForm() {
    this.form = this.fb.group({
      enableSystemBackup: [this.systemBackupEnabled],
    });
  }

  /**
   * Updates the selection options after the form has been saved and the dialog has been closed.
   */
  onSaved() {
    const options = this.form.value.enableSystemBackup ? {
      ...this.currentOptions,
      physicalSpecialParameters: {
        enableSystemBackup: this.form.value.enableSystemBackup,
      },
    } : this.node.getDefaultSourceParameters();

    this.nodeContext.selection.setOptionsForNode(this.node.id, options);
    this.cdr.detectChanges();
  }
}
