import { ReportColumnConfigs } from '../common-report-items/custom-reports.service';
import { ReportSection } from '../iris-reporting.model';

/**
 * This is the layout config for the recovery report.
 */
export const recoveryReport: ReportSection[] = [
  {
    title: 'reporting.summary',
    items: [
      {
        id: 'statlistItem',
        componentIds: ['702'],
        componentNames: ['RecoveryTasksCount'],
        params: {
          items: [
            {
              title: 'reporting.successRate',
              translate: true,
              showChart: true,
              measurement: {
                valueKey: 'countObjectUuid',
                dataType: 'percent',
                filter: {
                  filterKey: 'status',
                  value: 'kSuccess',
                },
                aggregation: 'percent',
              },
            },
            {
              title: 'reporting.totalRecoveries',
              translate: true,
              measurement: {
                valueKey: 'countObjectUuid',
                dataType: 'number',
              }
            },
            {
              title: 'reporting.successful',
              translate: true,
              measurement: {
                valueKey: 'countObjectUuid',
                dataType: 'number',
                filter: {
                  filterKey: 'status',
                  value: 'kSuccess',
                },
              },
            },
            {
              title: 'reporting.failed',
              translate: true,
              measurement: {
                valueKey: 'countObjectUuid',
                dataType: 'number',
                filter: {
                  filterKey: 'status',
                  value: 'kFailure',
                },
              },
            },
            {
              title: 'reporting.warning',
              translate: true,
              measurement: {
                valueKey: 'countObjectUuid',
                dataType: 'number',
                filter: {
                  filterKey: 'status',
                  value: 'kWarning',
                },
              },
            },
            {
              title: 'reporting.canceled',
              translate: true,
              measurement: {
                valueKey: 'countObjectUuid',
                dataType: 'number',
                filter: {
                  filterKey: 'status',
                  value: 'kCanceled',
                },
              },
            },
            {
              title: 'reporting.running',
              translate: true,
              measurement: {
                valueKey: 'countObjectUuid',
                dataType: 'number',
                filter: {
                  filterKey: 'status',
                  value: 'kRunning',
                },
              },
            },
          ],
        },
      },
    ],
  },
  {
    items: [
      {
        title: 'reporting.recoveryStatusByObjectType',
        id: 'chartItem',
        componentIds: ['701'],
        componentNames: ['RecoveryStatusByEnvironment'],
        params: {
          chartType: 'column',
          measurements: [
            {
              valueKey: 'countObjectUuid',
              dataType: 'number'
            },
          ],
          dimensions: [
            {
              dimensionKey: 'environment',
              maxValues: 5,
            },
            {
              dimensionKey: 'status',
              maxValues: 3,
            },
          ],
        },
      },
      // {
      //   title: 'reporting.recoveryDurationByObjectType',
      //   id: 'chartItem',
      //   componentNames: ['RecoveryDurationByEnvironment'],
      //   params: {
      //     chartType: 'column',
      //     measurements: [
      //       {
      //         valueKey: 'countObjectUuid',
      //         dataType: 'number'
      //       },
      //     ],
      //     dimensions: [
      //       {
      //         dimensionKey: 'environment',
      //         maxValues: 3,
      //       },
      //       {
      //         dimensionKey: 'duration',
      //         maxValues: 3,
      //       },
      //     ],
      //   },
      // },
    ],
  },
  {
    title: 'reporting.data',
    isFullData: true,
    items: [
      {
        id: 'tableItem',
        componentIds: ['700'],
        componentNames: ['AllRecoveryObjects'],
        params: {
          columns: [
            {
              title: 'startTime',
              translate: true,
              key: 'startTimeUsecs',
              icon: {
                key: 'status',
                dataType: 'statusIcon',
              },
              primary: {
                key: 'startTimeUsecs',
                dataType: 'date',
                renderParams: {
                  usecs: true,
                },
              },
            },
            {
              title: 'objectName',
              translate: true,
              key: 'objectName',
              icon: {
                key: 'objectType',
                dataType: 'objectTypeIcon',
                renderParams: {
                  environmentKey: 'environment',
                },
              },
              primary: {
                key: 'objectName',
                dataType: 'text',
                renderParams: {
                  width: 'lg',
                },
              },
              subtitle: {
                key: 'environment',
                dataType: 'environmentName',
              },
            },
            {
              title: 'source',
              enabled: true,
              translate: true,
              key: 'sourceName',
              primary: {
                key: 'sourceName',
                dataType: 'text',
              },
            },
            {
              title: 'organization',
              enabled: false,
              translate: true,
              key: 'tenantName',
              primary: {
                key: 'tenantName',
                dataType: 'text',
                renderParams: {
                  emptyKey: 'naNotAvailable',
                },
              },
              featureFlag: 'ngReportSPView',
            },
            {
              title: 'system',
              enabled: false,
              translate: true,
              key: 'system',
              primary: {
                key: 'system',
                dataType: 'text',
              },
            },
            {
              title: 'recoveryPoint',
              translate: true,
              key: 'recoveryPointUsecs',
              primary: {
                key: 'recoveryPointUsecs',
                dataType: 'date',
                renderParams: {
                  usecs: true,
                },
              },
            },
            {
              title: 'duration',
              translate: true,
              key: 'durationUsecs',
              sortStart: 'desc',
              primary: {
                key: 'durationUsecs',
                dataType: 'duration',
                renderParams: {
                  usecs: true,
                },
              },
            },
            // {
            //   title: 'dataTransferred',
            //   enabled: true,
            //   translate: true,
            //   key: 'dataTransferred',
            //   primary: {
            //     key: 'dataTransferred',
            //     dataType: 'bytes',
            //   },
            // },
            {
              title: 'taskName',
              translate: true,
              key: 'taskName',
              primary: {
                key: 'taskName',
                dataType: 'text',
              },
            },
            {
              title: 'username',
              enabled: false,
              translate: true,
              key: 'username',
              primary: {
                key: 'username',
                dataType: 'text',
              },
            },
            {
              title: 'targetName',
              enabled: false,
              translate: true,
              key: 'targetName',
              primary: {
                key: 'targetName',
                dataType: 'text',
              },
            },
            {
              title: 'snapshotType',
              enabled: false,
              translate: true,
              key: 'snapshotType',
              primary: {
                key: 'snapshotType',
                dataType: 'enumType',
              },
            },
          ],
        },
      },
    ],
  },
];

/**
 * This is metadata needed to determine available chart customizations.
 */
export const recoveryColumns: ReportColumnConfigs = {
  status: {
    aggs: [],
    canGroup: true,
    dataType: 'text',
  },
  startTimeUsecs: {
    aggs: [],
    dataType: 'date'
  },
  objectName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  objectUuid: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  environment: {
    aggs: ['count'],
    canGroup: true,
    maxGroupingValues: 3,
    dataType: 'text',
  },
  sourceName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  sourceUuid: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  system: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  systemId: {
    aggs: ['count'],
    dataType: 'uuid',
  },
};
