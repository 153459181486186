<cog-page>
  <cog-page-content>
    <cog-spinner *ngIf="loadingUserInfo$ | async" class="margin-top-xl"></cog-spinner>
    <article *ngIf="(loadingUserInfo$ | async) === false" class="dashboard-content">
      <h3>{{ 'm365.selfServe.dashboard.heading1' | translate: { username } }}</h3>
      <h2>{{ 'm365.selfServe.dashboard.heading2' | translate }}</h2>

      <section class="recovery-cards">
        <coh-m365-self-service-recovery-card type="{{selfServiceRecoveryType.kMailbox}}"
          (click)="recoveryCardClick.emit(selfServiceRecoveryType.kMailbox)"
          cogDataId="mailbox-card">
        </coh-m365-self-service-recovery-card>

        <coh-m365-self-service-recovery-card type="{{selfServiceRecoveryType.kOneDrive}}"
          (click)="recoveryCardClick.emit(selfServiceRecoveryType.kOneDrive)"
          cogDataId="one-drive-card">
        </coh-m365-self-service-recovery-card>
      </section>

      <cog-spinner *ngIf="loading$ | async" class="margin-top-xl"></cog-spinner>

      <section *ngIf="(recoveries$ | async).length" class="margin-top-xl">
        <h3>{{ 'recoveries' | translate }}</h3>

        <mat-card class="recovery-list margin-top-sm">
          <mat-list>
            <mat-list-item *ngFor="let row of recoveries$ | async; index as i; trackBy:recoveryTrackByFn"
              cogDataId="recovery-{{i}}">
              <div class="main-wrapper">
                <div class="main-data">
                  <cog-icon
                    [shape]="row | cohM365RecoveryListItemIcon: getTaskStatus(row.recoveryParams?.id)"
                    size="lg">
                  </cog-icon>

                  <span class="task-name" matLine>
                    {{
                      'm365.selfServe.recovery.title'
                        | translate: {
                          type: (row.recoveryObjectTypeStr | translate),
                          date: row.createdUsecs / 1000 | cogDate }
                    }}
                  </span>

                  <span class="push task-status">
                    {{ getTaskStatusWithProgress(row.recoveryParams?.id) }}
                  </span>

                  <span class="task-actions">
                    <button mat-icon-button [matMenuTriggerFor]="menu.childMenu">
                      <cog-icon shape="more_vert"></cog-icon>
                    </button>

                    <cog-actions-menu #menu
                      [navList]="recoveryRowActions"
                      [itemTemplate]="actionMenuItem">
                    </cog-actions-menu>

                    <ng-template #actionMenuItem let-item>
                      <div (click)="onRowItemActionClick(item, row)">
                        {{ item.displayName | translate }}
                      </div>
                    </ng-template>
                  </span>
                </div>
                <div class="meta-data">
                  <span matLine>
                    <coh-m365-recovery-item-metadata [recoveryItem]="row"
                      (progressUpdateEvent)="handleTaskProgressUpdate($event)"
                      (statusUpdateEvent)="handleTaskStatusUpdate($event)">
                    </coh-m365-recovery-item-metadata>
                  </span>
                </div>
              </div>

              <mat-progress-bar *ngIf="getTaskStatus(row.recoveryParams?.id) === 'InProgress'"
                mode="determinate"
                value="{{getTaskProgress(row.recoveryParams?.id)}}">
              </mat-progress-bar>
              <mat-progress-bar *ngIf="getTaskStatus(row.recoveryParams?.id) === 'InitiatingRecovery'"
                mode="indeterminate">
              </mat-progress-bar>
            </mat-list-item>
          </mat-list>
        </mat-card>
      </section>
    </article>
  </cog-page-content>
</cog-page>
