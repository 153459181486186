<coh-protection-source-detail
  [class.status-critical]="node.hasRegistrationError"
  [nodeContext]="nodeContext">
  <cog-icon shape="error_outline!critical"
    [inline]="true"
    [class.health-checks-indicator]="true"
    *ngIf="node.hasHealthCheckProblems"
    [matTooltip]="'healthChecksErrorTitle' | translate"
    [cogPopover]="healthChecksComponent"
    [popoverData]="node.healthChecks"></cog-icon>
</coh-protection-source-detail>

<cog-spinner size="xs"
  inline="true"
  [header]="'registering' | translate"
  *ngIf="node.hasRegistrationError"></cog-spinner>
