import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared';

import {
  ObjectRunNowDialogComponent,
  ObjectUnprotectDialogComponent,
  ObjectLegalHoldDialogComponent
} from './components';
import { PipesModule } from 'src/app/shared/pipes';

const components = [
  ObjectRunNowDialogComponent,
  ObjectUnprotectDialogComponent,
  ObjectLegalHoldDialogComponent,
];

@NgModule({
  declarations: components,
  imports: [
    CohesityHelixModule,
    CommonModule,
    PipesModule,
    SharedModule,
    TranslateModule,
  ],
})
export class ObjectDetailsSharedModule {}
