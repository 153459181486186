import { Injectable } from '@angular/core';
import { MatLegacySnackBarConfig as MatSnackBarConfig, MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';
import { SnackBarService } from '@cohesity/helix';
import { Observable, of, pipe, throwError, UnaryFunction } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AjaxHandlerConfigService } from './ajax-handler-config.service';

export const defaultErrorConfig: MatSnackBarConfig = {
  // 1 minute before auto-close.
  duration: 15000,

  // The horizontal position to place the snack bar.
  horizontalPosition: 'left',

  // The vertical position to place the snack bar.
  verticalPosition: 'bottom',

  // CSS class to be added to the snack bar container.
  panelClass: ['cog-snack-bar-container'],
};

/**
 * Ajax handler defines helper methods to handle API error in an standard way.
 */
@Injectable({ providedIn: 'root' })
export class AjaxHandlerService {
  constructor(private config: AjaxHandlerConfigService, private snackBarService: SnackBarService) {}

  /**
   * This operator can be added to an rxjs pipe to simplify handilng messages. Instead of using ajaxHandler.handler
   * in the subscribe, this can be added earlier in the stream to catch and show a snackbar error mesage.
   *
   * @example
   * service.doSomething().pipe(catchAndHandleError([]));
   *
   * @param defaultReturn   If defaultReturn is set, the operator will return an observable of that value.
   *                        If it is not set, it will show the snackbar and then throw the error that it received.
   * @param     [args]     Optional MatSnackbarConfig options
   * @returns   A pipe operator.
   */
  catchAndHandleError: <R>(
    defaultReturn?: R,
    args?: MatSnackBarConfig
  ) => UnaryFunction<Observable<any>, Observable<R>> = (defaultReturn, args) =>
    pipe(
      catchError((error: any) => {
        this.handler(error, args);
        return defaultReturn ? of(defaultReturn) : throwError(error);
      })
    );

  /**
   * Handler/orchestrator to automatically determine if the response is an error
   * or not and execute the appropriate ops.
   *
   * @example
   *   myObservable.subscribe(null, (resp) => this.ajaxHandler.handler(resp));
   *   myObservable.subscribe(null, this.ajaxHandler.handler);
   *
   * @param     response   The Http* response object.
   * @param     [args]     Optional MatSnackbarConfig options
   * @returns   The Snackbar reference.
   */
  handler = (response: any, args?: MatSnackBarConfig): MatSnackBarRef<any> => {
    // If this is a successful response, do nothing.
    if (response.status < 400) {
      return null;
    }

    // Otherwise, show an error message
    return this.errorMessage(response, args);
  };

  /**
   * Shows an error message for the given response.
   *
   * @param     response   The Http* response object.
   * @param     [args]     Optional MatSnackbarConfig options
   * @returns   The Snackbar reference.
   */
  errorMessage = (response: any, args: MatSnackBarConfig = defaultErrorConfig): MatSnackBarRef<any> => {
    const errorHandled = this.config.handleError(response, args);

    if (errorHandled) {
      // If error is handled through the injected config, don't do anything.
      return errorHandled;
    }

    const errorString = this.getResponseMessage(response) || this.config.getErrorConnectingToServer();

    return this.snackBarService.open(errorString, 'error', true, args);
  };

  /**
   * Gets response message from the given response object.
   *
   * @param     response   The Http response object.
   * @returns   The response message.
   */
  getResponseMessage(response: any): string {
    if (response.error) {
      return response.error.message || response.error.errorMessage;
    }

    if (response.data) {
      return response.data.message;
    }

    if (response.message) {
      return response.message;
    }

    if (response.errorMessage) {
      return response.errorMessage;
    }

    if (response.status && response.statusText) {
      return `${response.status}: ${response.statusText}`;
    }
    return null;
  }
}
