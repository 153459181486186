import { Injectable, PipeTransform } from '@angular/core';
import { isEmpty } from 'lodash';

/**
 * Na label pipe transform function definition.
 */
export type NaLabelPipeTransformFn = (value: any, customNaText?: string) => any;

/**
 * Converts an empty values to hyphen(-).
 *
 * NOTE: NaLabelPipeService is there to pass the below transform method to ajs codebase and currently used by
 *       c-filter naFilter only.
 *
 * Usage:
 *   value | naLabel
 *
 * Example:
 *   {{ (null || undefined) | naLabel }}               // "-"
 *   {{ ([] || {})          | naLabel }}               // "-"
 *   {{ [...]               | naLabel }}               // [...]
 *   {{ {a: 11, b: 22}      | naLabel }}               // {a: 11, b: 22}
 *   {{ 'hello world'       | naLabel }}               // "hello world"
 *
 *   {{ true    | naLabel }}                           // true
 *   {{ false   | naLabel }}                           // false
 *   {{ 'true'  | naLabel }}                           // 'false'
 *   {{ 'false' | naLabel }}                           // 'false'
 *
 *   {{ 0       | naLabel }}                           // 0
 *   {{ 1       | naLabel }}                           // 1
 *   {{ '0'     | naLabel }}                           // "0"
 *   {{ '1'     | naLabel }}                           // "1"
 *
 *   {{ (null || undefined) | naLabel: 'N/A' }}        // "N/A"
 *   {{ (null || undefined) | naLabel: ('naNotApplicableLetters' | translate) }}        // "N/A"
 */
@Injectable({ providedIn: 'root' })
export class NaLabelPipeService implements PipeTransform {
  /**
   * Transform the empty values inputs to hyphen(-) else keep the original input.
   *
   * @param value The input value.
   * @param customNaText The custom not available text and if present then it will be used in place of 'naNotAvailable'
   * translation.
   */
  transform: NaLabelPipeTransformFn = (value, customNaText) =>
    (typeof value === 'undefined' || typeof value === 'object') &&
      isEmpty(value) ? (customNaText || this.getDefaultNaLabel()) : value;

  /**
   * Return the default na label value.
   *
   * @returns The default na label value.
   */
  getDefaultNaLabel() {
    return 'naNotAvailable';
  }
}
