import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DlpApiService, PatternId } from '@cohesity/api/argus';
import { FilterDefDirective, FiltersComponent, ValueFilterSelection } from '@cohesity/helix';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'dg-ar-pattern-filter',
  templateUrl: './pattern-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatternFilterComponent extends AutoDestroyable implements OnInit, OnChanges, OnDestroy {
  /**
   * PatternIds used as patternFilters
   */
  patternFilterValues: ValueFilterSelection[];

  /**
   * list of pattern ids that should be selected automatically
   */
  @Input() selectedPatternIds: PatternId[];

  @Output() filterReady = new EventEmitter<boolean>();

  /**
   * name of the filter
   */
  readonly filterName = 'patternIds';

  /**
   * The filter def directive for the filter
   */
  @ViewChild(FilterDefDirective, { static: true }) filterDef: FilterDefDirective;

  constructor(
    private ajaxService: AjaxHandlerService,
    private filters: FiltersComponent,
    private patternService: DlpApiService
  ) {
    super();
  }

  ngOnInit() {
    this.getPatterns();
    if (this.filters && this.filterDef) {
      this.filters.addFilterDef(this.filterDef);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedPatternIds && this.patternFilterValues?.length) {
      this.initSelection();
    }
  }

  /**
   * Fetches patterns
   */
  getPatterns() {
    this.patternService
      .getPatterns()
      .pipe(
        this.untilDestroy(),
        map(patternsRes =>
          patternsRes.patterns.map(pattern => ({
            label: pattern.name,
            value: pattern.id,
          }))
        ),
        tap(patterns => (this.patternFilterValues = patterns))
      )
      .subscribe(() => {
        this.filterReady.emit(true);
        if (this.selectedPatternIds) {
          this.initSelection();
        }
      }, this.ajaxService.handler);
  }

  /**
   * selects all the patterns in selected patterns list
   */
  private initSelection() {
    const selectedPatterns =
      this.patternFilterValues?.filter(pattern => this.selectedPatternIds?.includes(pattern.value)) || [];
    this.filters.setValue(this.filterName, selectedPatterns);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.filters && this.filterDef) {
      this.filters.removeFilterDef(this.filterDef);
    }
  }
}
