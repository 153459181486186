// Component: Office365 OneDrives search component.

;(function (angular, undefined) {
  'use strict';

  angular
    .module('C.office365Recovery')
    .controller('Office365OneDrivesSearchCtrl', office365OneDrivesSearchCtrlFn)
    .component('office365OneDrivesSearch', {
      templateUrl:
        'app/protection/recovery/office365/search/one-drives/one-drives.html',
      controller: 'Office365OneDrivesSearchCtrl',
    });

  /**
   * @ngdoc component
   * @name C.officeRecoverySearch:office365OneDrivesSearch
   * @function
   * @param   {object}   _                       Lodash Library
   * @param   {object}   RecoveryStore           Cart service
   * @param   {object}   SearchService           Search service
   * @param   {object}   cSearchService          c-search-service
   * @param   {object}   OFFICE365_SEARCH_TYPE   search types available for
   *                                             Office365 recovery flow
   *
   * @description
   *  Component to provide search functionality for Office365 OneDrive search.
   *
   *  OneDrive object search is carried out by sending requests to
   *  Elastic Search(ES) through iris_exec to query under the index 'objindex'.
   *
   *  The OneDrive browse is again carried out by sending requests to Yoda
   *  for browsing the same as a View.
   *
   *  The OneDrive document search is carried out by sending requests to
   *  Librarian through iris_exec & iris_proxy_exec to query under the index
   *  'cfileindex'.
   */
  function office365OneDrivesSearchCtrlFn(_, RecoveryStore, SearchService,
    cSearchService, OFFICE365_SEARCH_TYPE, $stateParams) {
    var $ctrl = this;

    _.assign($ctrl, {
      // Lifecycle hooks.
      $onInit: $onInit,

      state: {
        // Specifies domain UUIDs to search for protected onedrives within ES
        // for 'objindex' and librarian for 'cfileindex'.
        selectedDomainUuids: [],
        selectedJobs: [],
        selectedMailboxes: [],
        selectedServers: [],
      },

      clearCartAndSearch: clearCartAndSearch,
      preProcessSearchResults: preProcessSearchResults,
      searchTypes: [],
      oneDriveSearchTypes: [
        OFFICE365_SEARCH_TYPE.kOneDriveSearch,
        OFFICE365_SEARCH_TYPE.kOneDriveBrowse,
        OFFICE365_SEARCH_TYPE.kOneDriveDocumentSearch,
      ],
      sharePointSearchTypes: [
        OFFICE365_SEARCH_TYPE.kSharePointSiteSearch,
        OFFICE365_SEARCH_TYPE.kSharePointSiteBrowse,
        OFFICE365_SEARCH_TYPE.kSharePointSiteDocumentSearch,
      ],
    });

    /**
     * @method   $onInit
     *
     * Initializes component.
     */
    function $onInit() {
      $ctrl.shared = RecoveryStore.get();
      $ctrl.searchTypes = !!$stateParams.isSharePointSearch ?
        $ctrl.sharePointSearchTypes : $ctrl.oneDriveSearchTypes;

      if ($stateParams.shouldPreserveCart) {
        return;
      }

      // For new recovery initialization, set the default search types.
      $ctrl.shared.searchItemType = !!$stateParams.isSharePointSearch ?
        OFFICE365_SEARCH_TYPE.kSharePointSiteSearch :
        OFFICE365_SEARCH_TYPE.kOneDriveSearch;
      clearCartAndSearch();
    }

    /**
     * Processes the search results for the Mailboxes.
     *
     * @method   preProcessSearchResults
     * @param    {object}   results   OneDrives containing search results.
     * @return   {object}   OneDrives containing decorated search results.
     */
    function preProcessSearchResults(results) {
      if ([OFFICE365_SEARCH_TYPE.kOneDriveDocumentSearch,
            OFFICE365_SEARCH_TYPE.kSharePointSiteDocumentSearch].includes(
            $ctrl.shared.searchItemType)
          ) {
        return SearchService.processPublicFileSearchResults(results);
      }
      return SearchService.transformPublicSearchResults(
        results, $ctrl.shared.searchItemType, true);
    }

    /**
     * Clears the recovery cart and the search results when the search type is
     * changed.
     * NOTE: Both iris and magneto support recovery of entire drives,
     * folders &  files in a single recovery job but currently this is just to
     * segregate the flow. This method may be removed in future.
     *
     * @method   clearCartAndSearch
     */
    function clearCartAndSearch() {
      $ctrl.shared.cart.removeAll();
      _resetOffice365OneDrivesSearch();
    }

    /**
     * Clears any old search result for Office365 object and resets the
     * c-search filters.
     *
     * @method   _resetOffice365OneDrivesSearch
     */
    function _resetOffice365OneDrivesSearch() {
      cSearchService.purgeResults($ctrl.shared.searchId);
      cSearchService.resetFilters($ctrl.shared.searchId, true);
      if ($ctrl.cSearchApi) {
        $ctrl.cSearchApi.resetSearch();
      }
    }
  }
})(angular);
