<h1 mat-dialog-title *ngIf="isOnLegalHold">
  {{ 'removeLegalHold' | translate }}
</h1>
<h1 mat-dialog-title *ngIf="!isOnLegalHold">
  {{ 'addLegalHold' | translate }}
</h1>
<div mat-dialog-content class="content">
  <ng-container>
    <div *ngIf="!isOnLegalHold">
      {{ 'editRunModal.legalholdWarning.helios' | translate }}
    </div>
    <div class="snapshot-data">
        <div class="legal-hold-metadata">
          <h2>
            {{ activity.timestampUsecs | cohDate }}
          </h2>
          <p> {{ 'snapshotDate' | translate }}</p>
        </div>
        <div class="legal-hold-metadata">
          <h2>
            {{ (activity.backupTypeNameKey && activity.backupTypeNameKey.length ?
              activity.backupTypeNameKey : 'incremental') | translate }} {{ activity.typeNameKey | translate}}
          </h2>
          <p> {{ 'protectionJobsDetailsRunRunProtection.backupType' | translate }}</p>
        </div>
    </div>
  </ng-container>
</div>
<div mat-dialog-actions align="end">
  <button cogCancelButton mat-button mat-dialog-close cogDataId="run-now-cancel">
    {{ 'cancel' | translate }}
  </button>
  <button cogSubmitButton mat-flat-button [mat-dialog-close]="!isOnLegalHold" color="primary"
    cogDataId="legal-hold-save">
    {{ 'save' | translate }}
  </button>
</div>
