import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatStepper } from '@angular/material/stepper';
import { Api } from '@cohesity/api/private';
import { RestoreTask, RestoreTasksServiceApi } from '@cohesity/api/v1';
import { StorageDomain } from '@cohesity/api/v2';
import {
  GetViewsResult,
  ProtectionGroup,
  ProtectionGroups,
  ProtectionGroupServiceApi,
  RecoveryServiceApi,
  StorageDomainServiceApi,
  View,
  ViewServiceApi,
} from '@cohesity/api/v2';
import { SnackBarService } from '@cohesity/helix';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { AjsUpgradeService } from 'src/app/core/services';
import { ProtectionGroupService } from 'src/app/modules/protection-group-shared';
import { RecoveryService } from 'src/app/modules/restore/recovery/shared';
import { Recovery } from 'src/app/modules/restore/restore-shared';

export interface DeleteStorageDomainDialogParams {
  storageDomain: StorageDomain;
}

@Component({
  selector: 'coh-delete-storage-domain-dialog',
  templateUrl: './delete-storage-domain-dialog.component.html',
  styleUrls: ['./delete-storage-domain-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeleteStorageDomainDialogComponent extends AutoDestroyable implements OnInit {
  /**
   * Current Index of stepper
   */
  stepperIndex = 0;

  /**
   * Legacy AJS RestoreService service.
   */
  private ajsRestoreService: any;

  /**
   * The form group for the dialog
   */
  deleteSDForm: UntypedFormGroup;

  /**
   * List of protection groups.
   */
  protectionGroups: ProtectionGroup[] = [];

  /**
   * List of recovery jobs.
   */
  recoveryList: Recovery[] = [];

  /**
   * List of views
   */
  viewList: View[] = [];

  /**
   * List of clones.
   * Since it's using private API, so no data type.
   */
  cloneList: any[] = [];

  /**
   * Indicates whether loading data.
   */
  isLoading$ = new BehaviorSubject<boolean>(false);

  /**
   * Flag to determine if SD can be deleted immediately
   */
  canDeleteImmediately = false;

  /**
   * Stepper instance
   */
  @ViewChild('stepper') stepper: MatStepper;

  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private dialogRef: MatDialogRef<DeleteStorageDomainDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogParams: DeleteStorageDomainDialogParams,
    private httpClient: HttpClient,
    private protectionGroupService: ProtectionGroupService,
    private recoveryService: RecoveryService,
    private restoreTasksService: RestoreTasksServiceApi,
    private snackbarService: SnackBarService,
    private storageDomainsService: StorageDomainServiceApi,
    private translate: TranslateService,
    private upgradeService: AjsUpgradeService,
    private viewsService: ViewServiceApi,
  ) {
    super();
    this.ajsRestoreService = this.upgradeService.get('RestoreService');
  }

  ngOnInit(): void {
    this.deleteSDForm = new UntypedFormGroup({
      'deleteAllProtectionJobsStatus': new UntypedFormControl(false, [Validators.required]),
      'deleteAllRecoveriesStatus': new UntypedFormControl(false, [Validators.required]),
      'deleteAllClonesStatus': new UntypedFormControl(false, [Validators.required]),
      'deleteAllViewsStatus': new UntypedFormControl(false, [Validators.required]),
      'confirmDeleteStorageDomain':
        new UntypedFormControl(null, [Validators.required, Validators.pattern('^([yY][eE][sS])$')]),
    });

    this.getData();
  }

  /**
   * Helper function to load all necessary data(includes list of protection groups, recoveries, clones and views).
   * If none of them exist, then directly jump to the last step.
   */
  private getData() {
    this.isLoading$.next(true);

    forkJoin([
      this.getProtectionGroups(),
      this.getRecoveries(),
      this.getClones(),
      this.getViews(),
    ]).pipe(this.untilDestroy(), finalize(() => this.isLoading$.next(false)))
      .subscribe(([protectionGroups, recoveryList, cloneList, viewList]:
        [ProtectionGroup[], Recovery[], RestoreTask[], View[]]) => {

        // Assign data which are displayed in stepper.
        this.protectionGroups = protectionGroups || [];
        this.recoveryList = recoveryList || [];
        this.cloneList = cloneList || [];
        this.viewList = viewList || [];

        // If all data are empty, then directly jump to last step.
        if (!this.protectionGroups.length &&
          !this.recoveryList.length &&
          !this.cloneList.length &&
          !this.viewList.length) {
          this.stepperIndex = 4;
          this.canDeleteImmediately = true;
        }
      }, e => {
        this.ajaxHandlerService.handler(e);
      });
  }

  /**
   * Delete the current storage domain.
   */
  deleteSD() {
    this.storageDomainsService.DeleteStorageDomain({ id: this.dialogParams.storageDomain.id })
      .pipe(this.untilDestroy())
      .subscribe(
        () => {
          const msg = this.translate.instant('deleteStorageDomainSuccess', {
            storageDomainName: this.dialogParams.storageDomain.name
          });
          this.snackbarService.open(msg, 'success');
          this.dialogRef.close();
        },
        e => this.ajaxHandlerService.handler(e)
      );
  }

  /**
   * Load list of protection groups.
   *
   * @return Observable of ProtectionGroup list.
   */
  private getProtectionGroups(): Observable<ProtectionGroup[]> {
    // Request params to get all protection groups of the current storage domain.
    const getProtectionGroupsParam: ProtectionGroupServiceApi.GetProtectionGroupsParams = {
      isDeleted: false,
      includeLastRunInfo: true,
      storageDomainId: this.dialogParams.storageDomain.id,
    };

    return this.protectionGroupService.getGroups(getProtectionGroupsParam)
      .pipe(this.untilDestroy(), map((resp: ProtectionGroups) => resp.protectionGroups));
  }

  /**
   * Load list of recoveries.
   *
   * @return Observable of Recovery List.
   */
  private getRecoveries(): Observable<Recovery[]> {
    const param: RecoveryServiceApi.GetRecoveriesParams = {
      status: ['Running'],
      storageDomainId: this.dialogParams.storageDomain.id,
    };

    return this.recoveryService.getRecoveries(param)
      .pipe(
        this.untilDestroy(),
        map(response => (response.recoveries || []).map(recovery => new Recovery(recovery)))
      );
  }

  /**
   * Load list of clones.
   *
   * @return Observable of get clones public http request.
   */
  private getClones(): Observable<Object> {
    // Set taskTypes parameter.
    let params = new HttpParams()
      .set('taskTypes', this.ajsRestoreService.getAllowedCloneTypes());

    // Set StorageDomainIds parameter.
    const ids = [this.dialogParams.storageDomain.id];
    ids.forEach(id => {
      params = params.append('storageDomainIds', id.toString());
    });

    return this.httpClient.get(Api.public('restore/tasks'), { params })
      .pipe(
        this.untilDestroy(),
      );
  }

  /**
   * Load list of views.
   *
   * @return Observable of View list.
   */
  private getViews(): Observable<View[]> {
    const param: ViewServiceApi.GetViewsParams = {
      maxCount: 1000,
      storageDomainIds: [this.dialogParams.storageDomain.id],
      includeStats: false,
      includeProtectionGroups: false,
    };
    return this.viewsService.GetViews(param)
      .pipe(
        this.untilDestroy(),
        map((viewsResult: GetViewsResult) => viewsResult.views),
      );
  }

}
