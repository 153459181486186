import { Injectable } from '@angular/core';
import { ProtectedObject } from '@cohesity/api/v2';
import { NavItem } from '@cohesity/helix';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { StateService } from '@uirouter/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { StateManagementService } from 'src/app/core/services';
import { RestoreConfigService } from 'src/app/modules/restore/restore-shared';
import { RecoveryAction } from 'src/app/shared';

import { ObjectActionCreator } from './object-action-creator';
import { ObjectActionProvider } from './object-action-provider';
import { checkObjectsForBulkAction } from './object-action-utils';
import { ObjectInfoService } from './object-info.service';
import { SimpleObjectInfo } from './object-menu-provider';
import { SfdcObjectActionCreator } from './sfdc-object-action-creator';

/**
 * This is a simple implementation for construction object menu actions for Salesforce.
 */
@Injectable()
export class SfdcObjectActionProvider extends ObjectActionProvider {
  /**
   * The provider for this service is manually set up in object-actions-menu.service, which must provide
   * the list of providers as an array in the correct order. In order to maintain some kind of sanity,
   * the providers are listed here, they should match the order of the constructor args.
   */
  static sfdcObjectActionProviderDependencies = [
    ObjectInfoService,
    RestoreConfigService,
    StateManagementService,
    StateService,
    ObjectActionCreator,
    IrisContextService,
    SfdcObjectActionCreator,
  ];

  exclusiveProtection = true;

  supportsBulkActions = false;

  constructor(
    readonly objectStore: ObjectInfoService,
    readonly restoreConfig: RestoreConfigService,
    readonly stateManagementService: StateManagementService,
    readonly stateService: StateService,
    readonly actionCreator: ObjectActionCreator,
    readonly irisContextService: IrisContextService,
    private sfdcActionCreator: SfdcObjectActionCreator,
  ) {
    super(objectStore, restoreConfig, stateManagementService, stateService, actionCreator, irisContextService);
  }

  /**
   * Returns whether we can protect the specified object.
   *
   * @param object The protected object.
   * @returns Whether it can be protected.
   */
  canProtect(object: SimpleObjectInfo): boolean {
    if (object.objectType !== 'kOrg') {
      return false;
    }

    return super.canProtect(object);
  }

  /**
   * Checks a protected object and recovery action to determine if the provided action is applicable.
   *
   * @param object The protected object
   * @param actionType A recovery action type
   * @returns True if the action can be applied to this object.
   */
  canRecover(actionType: RecoveryAction, object: ProtectedObject): boolean {
    return Boolean(object?.latestSnapshotsInfo) && (actionType === RecoveryAction.RecoverSfdcObjects ||
      actionType === RecoveryAction.RecoverSfdcOrg);
  }

  getObjectActions(object: SimpleObjectInfo): Observable<NavItem[]> {
    return combineLatest([super.getObjectActions(object), this.getSfdcRecoverAction([object])]).pipe(
      map(([baseActions, sfdcActions]) => [
        ...this.filterSfdcProtectAction(baseActions, object.objectType),
        ...sfdcActions,
      ]),
      map(actions => actions.filter(action => Boolean(action)))
    );

  }

  /**
   * Creates and returns the Sfdc action for the specified object.
   *
   * @param objects The protected objects.
   * @returns An observable, which yields the NavItem or null.
   */
  getSfdcRecoverAction(objects: SimpleObjectInfo[]): Observable<NavItem[]> {
    let recoveryAction = RecoveryAction.RecoverSfdcObjects;
    const objectType = objects[0].v1Object?.protectionSource?.sfdcProtectionSource?.type;

    if (objectType === 'kOrg') {
      recoveryAction = RecoveryAction.RecoverSfdcOrg;
    }

    if (!checkObjectsForBulkAction(objects, recoveryAction)) {
      return of(null);
    }

    if (!this.objectStore.getProtectedObject) {
      return of(null);
    }

    return super.getProtectedObjectSearch(objects).pipe(
      map(infos => infos.filter(info => info && this.canRecover(recoveryAction, info))),
      map(infos => (objectType === 'kOrg' ? (
        flagEnabled(this.irisContextService.irisContext, 'dmsSfdcOrgRecovery') ? [RecoveryAction.RecoverSfdcOrg] : [])
        : [RecoveryAction.RecoverSfdcObjects, RecoveryAction.RecoverSfdcRecords]).map(recoveryType =>
          this.sfdcActionCreator.createSfdcRecoverAction(infos[0], recoveryType, null, {
            accessClusterId: objects[0].accessClusterId,
            regionId: objects[0].regionId,
          })
        )
      )
    );
  }

  /**
   * Creates protect action only for the kOrg object.
   *
   * @param objects The protected objects.
   * @returns An observable, which yields the NavItem or null.
   */
  filterSfdcProtectAction(baseActions: NavItem[], objectType: string): NavItem[] {
    // remove Protect action from object type
    if (objectType === 'kObject') {
      baseActions = baseActions.filter(action => action.displayName !== 'protect');
    }
    return baseActions;
  }
}
