/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiRmsConfiguration as __Configuration } from '../cohesity-api-rms-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListRigelResult } from '../models/list-rigel-result';
import { DeleteRigelsResult } from '../models/delete-rigels-result';
import { UpdateRigelResult } from '../models/update-rigel-result';
import { UpdateRigelRequest } from '../models/update-rigel-request';
import { UpdateConnectionResult } from '../models/update-connection-result';
import { UpdateConnectionRequest } from '../models/update-connection-request';
import { ListRigelGroupResult } from '../models/list-rigel-group-result';
import { CreateRigelGroupResult } from '../models/create-rigel-group-result';
import { CreateRigelGroupRequest } from '../models/create-rigel-group-request';
import { RigelImage } from '../models/rigel-image';
import { ClaimRigelResult } from '../models/claim-rigel-result';
import { ClaimRigelRequest } from '../models/claim-rigel-request';
import { RenewRigelCertificateResult } from '../models/renew-rigel-certificate-result';
import { RenewRigelCertificateRequest } from '../models/renew-rigel-certificate-request';
import { RenewClaimTokenResult } from '../models/renew-claim-token-result';
import { RenewRigelCertificateDoneRequest } from '../models/renew-rigel-certificate-done-request';
import { SetRigelMaintenanceModeResult } from '../models/set-rigel-maintenance-mode-result';
import { SetRigelMaintenanceModeRequest } from '../models/set-rigel-maintenance-mode-request';
import { SetRigelGroupLockResult } from '../models/set-rigel-group-lock-result';
import { SetRigelGroupLockRequest } from '../models/set-rigel-group-lock-request';
import { GetStatsResult } from '../models/get-stats-result';
import { GetStatsRequest } from '../models/get-stats-request';
import { SaaSConnectionBandwidthLimits } from '../models/saa-sconnection-bandwidth-limits';
import { LockRigelGroupResult } from '../models/lock-rigel-group-result';
import { LockRigelGroupRequest } from '../models/lock-rigel-group-request';
import { UnlockRigelGroupResult } from '../models/unlock-rigel-group-result';
import { UnlockRigelGroupRequest } from '../models/unlock-rigel-group-request';
import { UpgradeRigelResult } from '../models/upgrade-rigel-result';
import { UpgradeRigelRequest } from '../models/upgrade-rigel-request';
import { ConnectorSupportChannelConfig } from '../models/connector-support-channel-config';
import { LockRigelClaimResult } from '../models/lock-rigel-claim-result';
import { LockRigelClaimRequest } from '../models/lock-rigel-claim-request';
import { UnlockRigelClaimRequest } from '../models/unlock-rigel-claim-request';
import { MigrateTenantRequest } from '../models/migrate-tenant-request';
import { RigelConnectivityEndpoints } from '../models/rigel-connectivity-endpoints';
import { RigelConnectivityInfos } from '../models/rigel-connectivity-infos';
import { ProvisionCloudRigelRequest } from '../models/provision-cloud-rigel-request';
import { ProvisionCloudRigelStatusResult } from '../models/provision-cloud-rigel-status-result';
import { DecommissionCloudRigelRequest } from '../models/decommission-cloud-rigel-request';
import { DecommissionCloudRigelStatusResult } from '../models/decommission-cloud-rigel-status-result';
import { ExtendCloudRigelLeaseRequest } from '../models/extend-cloud-rigel-lease-request';
import { PairOnPremClusterResult } from '../models/pair-on-prem-cluster-result';
import { PairOnPremClusterRequest } from '../models/pair-on-prem-cluster-request';
import { GetConnectorGroupUpdateStatusResult } from '../models/get-connector-group-update-status-result';
import { UpgradeRigelGroupRequest } from '../models/upgrade-rigel-group-request';
import { RigelClusterUpgradeInfo } from '../models/rigel-cluster-upgrade-info';
@Injectable({
  providedIn: 'root',
})
class RigelmgmtService extends __BaseService {
  static readonly GetRigelsPath = '/rigelmgmt/rigels';
  static readonly DeleteRigelsPath = '/rigelmgmt/rigels';
  static readonly UpdateRigelPath = '/rigelmgmt/rigels';
  static readonly DeleteRigelConnectionPath = '/rigelmgmt/connections';
  static readonly UpdateConnectionsPath = '/rigelmgmt/connections';
  static readonly GetRigelGroupsPath = '/rigelmgmt/rigel-groups';
  static readonly CreateRigelGroupPath = '/rigelmgmt/rigel-groups';
  static readonly UpdateConnectionPath = '/rigelmgmt/rigel-groups';
  static readonly GetRigelImagePath = '/rigelmgmt/regions/{region-id}/images';
  static readonly ClaimRigelPath = '/rigelmgmt/rigels/claim';
  static readonly RenewRigelCertificatePath = '/rigelmgmt/renew-saas-connector-certificate';
  static readonly RenewTokenPath = '/rigelmgmt/renew-token';
  static readonly RenewRigelCertificateDonePath = '/rigelmgmt/renew-saas-connector-certificate-done';
  static readonly SetRigelMaintenanceModePath = '/rigelmgmt/rigels/maintenance-mode';
  static readonly SetRigelGroupLockPath = '/rigelmgmt/rigel-groups/lock';
  static readonly GetConnectorStatsPath = '/rigelmgmt/connector-stats';
  static readonly GetConnectionStatsPath = '/rigelmgmt/connection-stats';
  static readonly GetConnectionBandwidthPath = '/rigelmgmt/connection-bandwidth';
  static readonly UpdateConnectionBandwidthPath = '/rigelmgmt/connection-bandwidth';
  static readonly LockRigelGroupPath = '/rigelmgmt/internal/rigel-groups/lock';
  static readonly UnlockRigelGroupPath = '/rigelmgmt/internal/rigel-groups/lock';
  static readonly GetInternalRigelsPath = '/rigelmgmt/internal/rigels';
  static readonly GetInternalRigelGroupsPath = '/rigelmgmt/internal/rigel-groups';
  static readonly UpgradeRigelPath = '/rigelmgmt/internal/rigel/upgrade';
  static readonly UpdateRigelSupportChannelPath = '/rigelmgmt/support-channel';
  static readonly LockRigelClaimPath = '/rigelmgmt/internal/rigel-claim/lock';
  static readonly UnlockRigelClaimPath = '/rigelmgmt/internal/rigel-claim/lock';
  static readonly MigrateTenantPath = '/rigelmgmt/internal/migrate-tenant';
  static readonly CleanUpTenantPath = '/rigelmgmt/internal/migrate-tenant';
  static readonly GetConnectivityEndpointsPath = '/rigelmgmt/connectivity-endpoints';
  static readonly GetConnectivityPath = '/rigelmgmt/connectivity';
  static readonly CheckConnectivityPath = '/rigelmgmt/connectivity';
  static readonly GetS3EndpointPath = '/rigelmgmt/s3-endpoint';
  static readonly ProvisionCloudRigelPath = '/rigelmgmt/internal/cloud-rigel/provision';
  static readonly ProvisionCloudRigelStatusPath = '/rigelmgmt/internal/cloud-rigel/provision-status';
  static readonly DecommissionCloudRigelPath = '/rigelmgmt/internal/cloud-rigel/decommission';
  static readonly DecommissionCloudRigelStatusPath = '/rigelmgmt/internal/cloud-rigel/decommission-status';
  static readonly ExtendCloudRigelLeasePath = '/rigelmgmt/internal/cloud-rigel/extend-cloud-rigel-lease';
  static readonly UpdateAWSConnectorPath = '/rigelmgmt/aws-connection/connectors';
  static readonly PairOnPremClusterPath = '/rigelmgmt/internal/pair-on-prem-cluster';
  static readonly GetConnectorGroupUpdateStatusPath = '/rigelmgmt/connector-group/status';
  static readonly BringUpConnectorPath = '/rigelmgmt/connector/bring-up';
  static readonly UpgradeRigelGroupPath = '/rigelmgmt/rigel-groups/upgrade';
  static readonly UpgradeRigelGroupStatusPath = '/rigelmgmt/rigel-groups/upgrade';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get the list of Rigel instance information.
   *
   * Get the list of Rigel instance information.
   * @param params The `RigelmgmtService.GetRigelsParams` containing the following parameters:
   *
   * - `tenantId`: Filter the results by tenant.
   *
   * - `skipConnectionStatus`: Flag to tell Rigel mgmt whether to skip to get latest connection status for Rigels in the response.
   *
   * - `rigelGuid`: Filter the results by Rigel guid.
   *
   * - `resumeToken`: Token returned by the last call which is used to resume the list operation.
   *
   * - `regionId`: Filter the results by region.
   *
   * - `maxRecordLimit`: Specify maximum number of records returned.
   *
   * - `includeCloudRigel`: Whether to include cloud Rigels in the response.
   *
   * - `groupId`: Filter the results by Rigel Group id.
   *
   * - `getSupportChannelConfig`: Flag to tell Rigel mgmt whether to fetch latest support channel config for Rigels in the response.
   *
   * @return Success
   */
  GetRigelsResponse(params: RigelmgmtService.GetRigelsParams): __Observable<__StrictHttpResponse<ListRigelResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.skipConnectionStatus != null) __params = __params.set('skipConnectionStatus', params.skipConnectionStatus.toString());
    if (params.rigelGuid != null) __params = __params.set('rigelGuid', params.rigelGuid.toString());
    if (params.resumeToken != null) __params = __params.set('resumeToken', params.resumeToken.toString());
    if (params.regionId != null) __params = __params.set('regionId', params.regionId.toString());
    if (params.maxRecordLimit != null) __params = __params.set('maxRecordLimit', params.maxRecordLimit.toString());
    if (params.includeCloudRigel != null) __params = __params.set('includeCloudRigel', params.includeCloudRigel.toString());
    if (params.groupId != null) __params = __params.set('groupId', params.groupId.toString());
    if (params.getSupportChannelConfig != null) __params = __params.set('getSupportChannelConfig', params.getSupportChannelConfig.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/rigelmgmt/rigels`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListRigelResult>;
      })
    );
  }
  /**
   * Get the list of Rigel instance information.
   *
   * Get the list of Rigel instance information.
   * @param params The `RigelmgmtService.GetRigelsParams` containing the following parameters:
   *
   * - `tenantId`: Filter the results by tenant.
   *
   * - `skipConnectionStatus`: Flag to tell Rigel mgmt whether to skip to get latest connection status for Rigels in the response.
   *
   * - `rigelGuid`: Filter the results by Rigel guid.
   *
   * - `resumeToken`: Token returned by the last call which is used to resume the list operation.
   *
   * - `regionId`: Filter the results by region.
   *
   * - `maxRecordLimit`: Specify maximum number of records returned.
   *
   * - `includeCloudRigel`: Whether to include cloud Rigels in the response.
   *
   * - `groupId`: Filter the results by Rigel Group id.
   *
   * - `getSupportChannelConfig`: Flag to tell Rigel mgmt whether to fetch latest support channel config for Rigels in the response.
   *
   * @return Success
   */
  GetRigels(params: RigelmgmtService.GetRigelsParams): __Observable<ListRigelResult> {
    return this.GetRigelsResponse(params).pipe(
      __map(_r => _r.body as ListRigelResult)
    );
  }

  /**
   * Delete Rigels for the given region and tenant.
   *
   * Delete Rigels, the input can be rigelGuid to delete single rigel, it can also be tenantId or tenantId/regionId pair to delete all rigels under the tenantId or tenantId/regionId.
   * @param params The `RigelmgmtService.DeleteRigelsParams` containing the following parameters:
   *
   * - `tenantId`: Delete the rigels by tenant.
   *
   * - `rigelGuid`: Delete the rigels by rigel GUID.
   *
   * - `regionId`: Delete the rigels by region.
   *
   * - `forceDelete`: To force delete rigels. AWS rigel only
   *
   * @return Success
   */
  DeleteRigelsResponse(params: RigelmgmtService.DeleteRigelsParams): __Observable<__StrictHttpResponse<DeleteRigelsResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.rigelGuid != null) __params = __params.set('rigelGuid', params.rigelGuid.toString());
    if (params.regionId != null) __params = __params.set('regionId', params.regionId.toString());
    if (params.forceDelete != null) __params = __params.set('forceDelete', params.forceDelete.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/rigelmgmt/rigels`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DeleteRigelsResult>;
      })
    );
  }
  /**
   * Delete Rigels for the given region and tenant.
   *
   * Delete Rigels, the input can be rigelGuid to delete single rigel, it can also be tenantId or tenantId/regionId pair to delete all rigels under the tenantId or tenantId/regionId.
   * @param params The `RigelmgmtService.DeleteRigelsParams` containing the following parameters:
   *
   * - `tenantId`: Delete the rigels by tenant.
   *
   * - `rigelGuid`: Delete the rigels by rigel GUID.
   *
   * - `regionId`: Delete the rigels by region.
   *
   * - `forceDelete`: To force delete rigels. AWS rigel only
   *
   * @return Success
   */
  DeleteRigels(params: RigelmgmtService.DeleteRigelsParams): __Observable<DeleteRigelsResult> {
    return this.DeleteRigelsResponse(params).pipe(
      __map(_r => _r.body as DeleteRigelsResult)
    );
  }

  /**
   * Update rigel's name, ip and software version.
   *
   * Update rigel.
   * @param body Specifies the parameters to update Rigel.
   * @return Success
   */
  UpdateRigelResponse(body: UpdateRigelRequest): __Observable<__StrictHttpResponse<UpdateRigelResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/rigelmgmt/rigels`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateRigelResult>;
      })
    );
  }
  /**
   * Update rigel's name, ip and software version.
   *
   * Update rigel.
   * @param body Specifies the parameters to update Rigel.
   * @return Success
   */
  UpdateRigel(body: UpdateRigelRequest): __Observable<UpdateRigelResult> {
    return this.UpdateRigelResponse(body).pipe(
      __map(_r => _r.body as UpdateRigelResult)
    );
  }

  /**
   * Delete rigel connection.
   *
   * Delete rigel connection.
   * @param params The `RigelmgmtService.DeleteRigelConnectionParams` containing the following parameters:
   *
   * - `tenantId`: Tenant id of the connection.
   *
   * - `forceDelete`: To force delete rigels. AWS rigel only
   *
   * - `connectionId`: Delete the connection by connectionId.
   */
  DeleteRigelConnectionResponse(params: RigelmgmtService.DeleteRigelConnectionParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.forceDelete != null) __params = __params.set('forceDelete', params.forceDelete.toString());
    if (params.connectionId != null) __params = __params.set('connectionId', params.connectionId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/rigelmgmt/connections`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete rigel connection.
   *
   * Delete rigel connection.
   * @param params The `RigelmgmtService.DeleteRigelConnectionParams` containing the following parameters:
   *
   * - `tenantId`: Tenant id of the connection.
   *
   * - `forceDelete`: To force delete rigels. AWS rigel only
   *
   * - `connectionId`: Delete the connection by connectionId.
   */
  DeleteRigelConnection(params: RigelmgmtService.DeleteRigelConnectionParams): __Observable<null> {
    return this.DeleteRigelConnectionResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update connection.
   *
   * Update connection.
   * @param body Specifies the parameters to update connection.
   * @return Success
   */
  UpdateConnectionsResponse(body: UpdateConnectionRequest): __Observable<__StrictHttpResponse<UpdateConnectionResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/rigelmgmt/connections`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateConnectionResult>;
      })
    );
  }
  /**
   * Update connection.
   *
   * Update connection.
   * @param body Specifies the parameters to update connection.
   * @return Success
   */
  UpdateConnections(body: UpdateConnectionRequest): __Observable<UpdateConnectionResult> {
    return this.UpdateConnectionsResponse(body).pipe(
      __map(_r => _r.body as UpdateConnectionResult)
    );
  }

  /**
   * Get the list of Rigel Group information.
   *
   * Get the list of Rigel Group information.
   * @param params The `RigelmgmtService.GetRigelGroupsParams` containing the following parameters:
   *
   * - `tenantId`: Filter the results by tenant.
   *
   * - `resumeToken`: Token returned by the last call which is used to resume the list operation.
   *
   * - `regionId`: Filter the results by region.
   *
   * - `maxRecordLimit`: Specify maximum number of records returned.
   *
   * - `groupId`: Filter the results by Rigel Group id.
   *
   * - `getConnectionStatus`: Flag to determine whether connection status needs to be fetched.
   *
   * - `fetchToken`: Flag to determine whether claim token needs to be fetched, groupId needs to be provided to make sure only one group is returned.
   *
   * - `fetchConnectorGroups`: Flag to determine whether response contains connector groups and rigels under the connector group.
   *
   * @return Success
   */
  GetRigelGroupsResponse(params: RigelmgmtService.GetRigelGroupsParams): __Observable<__StrictHttpResponse<ListRigelGroupResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.resumeToken != null) __params = __params.set('resumeToken', params.resumeToken.toString());
    if (params.regionId != null) __params = __params.set('regionId', params.regionId.toString());
    if (params.maxRecordLimit != null) __params = __params.set('maxRecordLimit', params.maxRecordLimit.toString());
    if (params.groupId != null) __params = __params.set('groupId', params.groupId.toString());
    if (params.getConnectionStatus != null) __params = __params.set('getConnectionStatus', params.getConnectionStatus.toString());
    if (params.fetchToken != null) __params = __params.set('fetchToken', params.fetchToken.toString());
    if (params.fetchConnectorGroups != null) __params = __params.set('fetchConnectorGroups', params.fetchConnectorGroups.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/rigelmgmt/rigel-groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListRigelGroupResult>;
      })
    );
  }
  /**
   * Get the list of Rigel Group information.
   *
   * Get the list of Rigel Group information.
   * @param params The `RigelmgmtService.GetRigelGroupsParams` containing the following parameters:
   *
   * - `tenantId`: Filter the results by tenant.
   *
   * - `resumeToken`: Token returned by the last call which is used to resume the list operation.
   *
   * - `regionId`: Filter the results by region.
   *
   * - `maxRecordLimit`: Specify maximum number of records returned.
   *
   * - `groupId`: Filter the results by Rigel Group id.
   *
   * - `getConnectionStatus`: Flag to determine whether connection status needs to be fetched.
   *
   * - `fetchToken`: Flag to determine whether claim token needs to be fetched, groupId needs to be provided to make sure only one group is returned.
   *
   * - `fetchConnectorGroups`: Flag to determine whether response contains connector groups and rigels under the connector group.
   *
   * @return Success
   */
  GetRigelGroups(params: RigelmgmtService.GetRigelGroupsParams): __Observable<ListRigelGroupResult> {
    return this.GetRigelGroupsResponse(params).pipe(
      __map(_r => _r.body as ListRigelGroupResult)
    );
  }

  /**
   * Create Rigel Group and associated claim token.
   *
   * Create Rigel Group and associated claim token.
   * @param body Specifies the parameters to create Rigel Group.
   * @return Success
   */
  CreateRigelGroupResponse(body: CreateRigelGroupRequest): __Observable<__StrictHttpResponse<CreateRigelGroupResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/rigelmgmt/rigel-groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateRigelGroupResult>;
      })
    );
  }
  /**
   * Create Rigel Group and associated claim token.
   *
   * Create Rigel Group and associated claim token.
   * @param body Specifies the parameters to create Rigel Group.
   * @return Success
   */
  CreateRigelGroup(body: CreateRigelGroupRequest): __Observable<CreateRigelGroupResult> {
    return this.CreateRigelGroupResponse(body).pipe(
      __map(_r => _r.body as CreateRigelGroupResult)
    );
  }

  /**
   * Update SaaS connection.
   *
   * Update SaaS connection.
   * @param body Specifies the parameters to update SaaS connection.
   * @return Success
   */
  UpdateConnectionResponse(body: UpdateConnectionRequest): __Observable<__StrictHttpResponse<UpdateConnectionResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/rigelmgmt/rigel-groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateConnectionResult>;
      })
    );
  }
  /**
   * Update SaaS connection.
   *
   * Update SaaS connection.
   * @param body Specifies the parameters to update SaaS connection.
   * @return Success
   */
  UpdateConnection(body: UpdateConnectionRequest): __Observable<UpdateConnectionResult> {
    return this.UpdateConnectionResponse(body).pipe(
      __map(_r => _r.body as UpdateConnectionResult)
    );
  }

  /**
   * Get the download link for Rigel image.
   *
   * Get the download link for Rigel image.
   * @param region-id Get image by region.
   * @return Success
   */
  GetRigelImageResponse(regionId: string): __Observable<__StrictHttpResponse<RigelImage>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/rigelmgmt/regions/${encodeURIComponent(regionId)}/images`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RigelImage>;
      })
    );
  }
  /**
   * Get the download link for Rigel image.
   *
   * Get the download link for Rigel image.
   * @param region-id Get image by region.
   * @return Success
   */
  GetRigelImage(regionId: string): __Observable<RigelImage> {
    return this.GetRigelImageResponse(regionId).pipe(
      __map(_r => _r.body as RigelImage)
    );
  }

  /**
   * Claim Rigel instance.
   *
   * Claim Rigel instance.
   * @param body Specifies the parameters to claim Rigel.
   * @return Success
   */
  ClaimRigelResponse(body: ClaimRigelRequest): __Observable<__StrictHttpResponse<ClaimRigelResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/rigelmgmt/rigels/claim`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClaimRigelResult>;
      })
    );
  }
  /**
   * Claim Rigel instance.
   *
   * Claim Rigel instance.
   * @param body Specifies the parameters to claim Rigel.
   * @return Success
   */
  ClaimRigel(body: ClaimRigelRequest): __Observable<ClaimRigelResult> {
    return this.ClaimRigelResponse(body).pipe(
      __map(_r => _r.body as ClaimRigelResult)
    );
  }

  /**
   * Renew the certificate (if needed) for this rigel.
   *
   * Renew the certificate (if needed) for this rigel.
   * @param body Specifies the parameters to renew Rigel certificate.
   * @return Success
   */
  RenewRigelCertificateResponse(body: RenewRigelCertificateRequest): __Observable<__StrictHttpResponse<RenewRigelCertificateResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/rigelmgmt/renew-saas-connector-certificate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RenewRigelCertificateResult>;
      })
    );
  }
  /**
   * Renew the certificate (if needed) for this rigel.
   *
   * Renew the certificate (if needed) for this rigel.
   * @param body Specifies the parameters to renew Rigel certificate.
   * @return Success
   */
  RenewRigelCertificate(body: RenewRigelCertificateRequest): __Observable<RenewRigelCertificateResult> {
    return this.RenewRigelCertificateResponse(body).pipe(
      __map(_r => _r.body as RenewRigelCertificateResult)
    );
  }

  /**
   * Renew a claim token with group id.
   *
   * Renew a claim token with group id.
   * @param groupId Renew claim token associated with the group id.
   * @return Success
   */
  RenewTokenResponse(groupId?: null | number): __Observable<__StrictHttpResponse<RenewClaimTokenResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (groupId != null) __params = __params.set('groupId', groupId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/rigelmgmt/renew-token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RenewClaimTokenResult>;
      })
    );
  }
  /**
   * Renew a claim token with group id.
   *
   * Renew a claim token with group id.
   * @param groupId Renew claim token associated with the group id.
   * @return Success
   */
  RenewToken(groupId?: null | number): __Observable<RenewClaimTokenResult> {
    return this.RenewTokenResponse(groupId).pipe(
      __map(_r => _r.body as RenewClaimTokenResult)
    );
  }

  /**
   * API called by saas connector when certificate renewal is done.
   *
   * API called by saas connector when certificate renewal is done.
   * @param body Specifies the parameters to notify cert renewal is done for saas connector.
   */
  RenewRigelCertificateDoneResponse(body: RenewRigelCertificateDoneRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/rigelmgmt/renew-saas-connector-certificate-done`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * API called by saas connector when certificate renewal is done.
   *
   * API called by saas connector when certificate renewal is done.
   * @param body Specifies the parameters to notify cert renewal is done for saas connector.
   */
  RenewRigelCertificateDone(body: RenewRigelCertificateDoneRequest): __Observable<null> {
    return this.RenewRigelCertificateDoneResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Set the maintenance mode for a Rigel.
   *
   * Set the maintenance mode for a Rigel.
   * @param body Specifies the parameters to set Rigel maintenance mode.
   * @return Success
   */
  SetRigelMaintenanceModeResponse(body: SetRigelMaintenanceModeRequest): __Observable<__StrictHttpResponse<SetRigelMaintenanceModeResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/rigelmgmt/rigels/maintenance-mode`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SetRigelMaintenanceModeResult>;
      })
    );
  }
  /**
   * Set the maintenance mode for a Rigel.
   *
   * Set the maintenance mode for a Rigel.
   * @param body Specifies the parameters to set Rigel maintenance mode.
   * @return Success
   */
  SetRigelMaintenanceMode(body: SetRigelMaintenanceModeRequest): __Observable<SetRigelMaintenanceModeResult> {
    return this.SetRigelMaintenanceModeResponse(body).pipe(
      __map(_r => _r.body as SetRigelMaintenanceModeResult)
    );
  }

  /**
   * Set the lock for a Rigel Group.
   *
   * Set the lock for a Rigel Group.
   * @param body Specifies the parameters to set Rigel Group lock.
   * @return Success
   */
  SetRigelGroupLockResponse(body: SetRigelGroupLockRequest): __Observable<__StrictHttpResponse<SetRigelGroupLockResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/rigelmgmt/rigel-groups/lock`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SetRigelGroupLockResult>;
      })
    );
  }
  /**
   * Set the lock for a Rigel Group.
   *
   * Set the lock for a Rigel Group.
   * @param body Specifies the parameters to set Rigel Group lock.
   * @return Success
   */
  SetRigelGroupLock(body: SetRigelGroupLockRequest): __Observable<SetRigelGroupLockResult> {
    return this.SetRigelGroupLockResponse(body).pipe(
      __map(_r => _r.body as SetRigelGroupLockResult)
    );
  }

  /**
   * Get the stats for one or more Connectors.
   *
   * Get the stats for one or more Connectors.
   * @param body Specifies the parameters to get one or more Connector stats.
   * @return Success
   */
  GetConnectorStatsResponse(body: GetStatsRequest): __Observable<__StrictHttpResponse<GetStatsResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/rigelmgmt/connector-stats`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetStatsResult>;
      })
    );
  }
  /**
   * Get the stats for one or more Connectors.
   *
   * Get the stats for one or more Connectors.
   * @param body Specifies the parameters to get one or more Connector stats.
   * @return Success
   */
  GetConnectorStats(body: GetStatsRequest): __Observable<GetStatsResult> {
    return this.GetConnectorStatsResponse(body).pipe(
      __map(_r => _r.body as GetStatsResult)
    );
  }

  /**
   * Get the stats for one or more Connections.
   *
   * Get the stats for one or more Connections. Stats are aggregated across the Connectors in the Connection. If TenantId is provided, this would give the stats for all the Connections for a Tenant.
   * @param body Specifies the parameters to get one or more Connection stats.
   * @return Success
   */
  GetConnectionStatsResponse(body: GetStatsRequest): __Observable<__StrictHttpResponse<GetStatsResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/rigelmgmt/connection-stats`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetStatsResult>;
      })
    );
  }
  /**
   * Get the stats for one or more Connections.
   *
   * Get the stats for one or more Connections. Stats are aggregated across the Connectors in the Connection. If TenantId is provided, this would give the stats for all the Connections for a Tenant.
   * @param body Specifies the parameters to get one or more Connection stats.
   * @return Success
   */
  GetConnectionStats(body: GetStatsRequest): __Observable<GetStatsResult> {
    return this.GetConnectionStatsResponse(body).pipe(
      __map(_r => _r.body as GetStatsResult)
    );
  }

  /**
   * List the upload and download bandwidth limits for a connection.
   *
   * Returns the upload and download bandwidth limits for a connection.
   * @param params The `RigelmgmtService.GetConnectionBandwidthParams` containing the following parameters:
   *
   * - `tenantId`: TenantId corresponding to the connection.
   *
   * - `connectionId`: ConnectionId for which bandwidth settings are to be returned. Pass connector group id as connectionId if connector group level bandwidth needs to be fetched.
   *
   * @return Response of connection bandwidth call.
   */
  GetConnectionBandwidthResponse(params: RigelmgmtService.GetConnectionBandwidthParams): __Observable<__StrictHttpResponse<SaaSConnectionBandwidthLimits>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.connectionId != null) __params = __params.set('connectionId', params.connectionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/rigelmgmt/connection-bandwidth`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SaaSConnectionBandwidthLimits>;
      })
    );
  }
  /**
   * List the upload and download bandwidth limits for a connection.
   *
   * Returns the upload and download bandwidth limits for a connection.
   * @param params The `RigelmgmtService.GetConnectionBandwidthParams` containing the following parameters:
   *
   * - `tenantId`: TenantId corresponding to the connection.
   *
   * - `connectionId`: ConnectionId for which bandwidth settings are to be returned. Pass connector group id as connectionId if connector group level bandwidth needs to be fetched.
   *
   * @return Response of connection bandwidth call.
   */
  GetConnectionBandwidth(params: RigelmgmtService.GetConnectionBandwidthParams): __Observable<SaaSConnectionBandwidthLimits> {
    return this.GetConnectionBandwidthResponse(params).pipe(
      __map(_r => _r.body as SaaSConnectionBandwidthLimits)
    );
  }

  /**
   * Updates bandwidth limits for a connection.
   *
   * Returns the updated connection bandwidth limits.
   * @param body Request to update connection bandwidth limits settings.
   * @return Response of connection bandwidth call.
   */
  UpdateConnectionBandwidthResponse(body: SaaSConnectionBandwidthLimits): __Observable<__StrictHttpResponse<SaaSConnectionBandwidthLimits>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/rigelmgmt/connection-bandwidth`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SaaSConnectionBandwidthLimits>;
      })
    );
  }
  /**
   * Updates bandwidth limits for a connection.
   *
   * Returns the updated connection bandwidth limits.
   * @param body Request to update connection bandwidth limits settings.
   * @return Response of connection bandwidth call.
   */
  UpdateConnectionBandwidth(body: SaaSConnectionBandwidthLimits): __Observable<SaaSConnectionBandwidthLimits> {
    return this.UpdateConnectionBandwidthResponse(body).pipe(
      __map(_r => _r.body as SaaSConnectionBandwidthLimits)
    );
  }

  /**
   * An endpoint to lock a rigel group.
   *
   * An endpoint to lock a rigel group. Input parameters are unique lock ID and the rigel group ID.
   * @param body Specifies the parameters to lock a rigel group.
   * @return Response of lock a Rigel Group call.
   */
  LockRigelGroupResponse(body: LockRigelGroupRequest): __Observable<__StrictHttpResponse<LockRigelGroupResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/rigelmgmt/internal/rigel-groups/lock`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LockRigelGroupResult>;
      })
    );
  }
  /**
   * An endpoint to lock a rigel group.
   *
   * An endpoint to lock a rigel group. Input parameters are unique lock ID and the rigel group ID.
   * @param body Specifies the parameters to lock a rigel group.
   * @return Response of lock a Rigel Group call.
   */
  LockRigelGroup(body: LockRigelGroupRequest): __Observable<LockRigelGroupResult> {
    return this.LockRigelGroupResponse(body).pipe(
      __map(_r => _r.body as LockRigelGroupResult)
    );
  }

  /**
   * An endpoint to unlock a rigel group.
   *
   * An endpoint to unlock a rigel group. Input parameters are unique lock ID and the rigel group ID.
   * @param body Specifies the parameters to lock a rigel group.
   * @return Response of unlock a Rigel Group call.
   */
  UnlockRigelGroupResponse(body: UnlockRigelGroupRequest): __Observable<__StrictHttpResponse<UnlockRigelGroupResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/rigelmgmt/internal/rigel-groups/lock`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UnlockRigelGroupResult>;
      })
    );
  }
  /**
   * An endpoint to unlock a rigel group.
   *
   * An endpoint to unlock a rigel group. Input parameters are unique lock ID and the rigel group ID.
   * @param body Specifies the parameters to lock a rigel group.
   * @return Response of unlock a Rigel Group call.
   */
  UnlockRigelGroup(body: UnlockRigelGroupRequest): __Observable<UnlockRigelGroupResult> {
    return this.UnlockRigelGroupResponse(body).pipe(
      __map(_r => _r.body as UnlockRigelGroupResult)
    );
  }

  /**
   * Get the list of Rigel instance information.
   *
   * Get the list of Rigel instance information.
   * @param params The `RigelmgmtService.GetInternalRigelsParams` containing the following parameters:
   *
   * - `tenantId`: Filter the results by tenant.
   *
   * - `skipConnectionStatus`: Flag to tell Rigel mgmt whether to skip to get latest connection status for Rigels in the response.
   *
   * - `resumeToken`: Token returned by the last call which is used to resume the list operation.
   *
   * - `regionId`: Filter the results by region.
   *
   * - `maxRecordLimit`: Specify maximum number of records returned.
   *
   * - `groupId`: Filter the results by Rigel Group id.
   *
   * @return Success
   */
  GetInternalRigelsResponse(params: RigelmgmtService.GetInternalRigelsParams): __Observable<__StrictHttpResponse<ListRigelResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.skipConnectionStatus != null) __params = __params.set('skipConnectionStatus', params.skipConnectionStatus.toString());
    if (params.resumeToken != null) __params = __params.set('resumeToken', params.resumeToken.toString());
    if (params.regionId != null) __params = __params.set('regionId', params.regionId.toString());
    if (params.maxRecordLimit != null) __params = __params.set('maxRecordLimit', params.maxRecordLimit.toString());
    if (params.groupId != null) __params = __params.set('groupId', params.groupId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/rigelmgmt/internal/rigels`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListRigelResult>;
      })
    );
  }
  /**
   * Get the list of Rigel instance information.
   *
   * Get the list of Rigel instance information.
   * @param params The `RigelmgmtService.GetInternalRigelsParams` containing the following parameters:
   *
   * - `tenantId`: Filter the results by tenant.
   *
   * - `skipConnectionStatus`: Flag to tell Rigel mgmt whether to skip to get latest connection status for Rigels in the response.
   *
   * - `resumeToken`: Token returned by the last call which is used to resume the list operation.
   *
   * - `regionId`: Filter the results by region.
   *
   * - `maxRecordLimit`: Specify maximum number of records returned.
   *
   * - `groupId`: Filter the results by Rigel Group id.
   *
   * @return Success
   */
  GetInternalRigels(params: RigelmgmtService.GetInternalRigelsParams): __Observable<ListRigelResult> {
    return this.GetInternalRigelsResponse(params).pipe(
      __map(_r => _r.body as ListRigelResult)
    );
  }

  /**
   * Get the list of Rigel Group information.
   *
   * Get the list of Rigel Group information.
   * @param params The `RigelmgmtService.GetInternalRigelGroupsParams` containing the following parameters:
   *
   * - `tenantId`: Filter the results by tenant.
   *
   * - `resumeToken`: Token returned by the last call which is used to resume the list operation.
   *
   * - `regionId`: Filter the results by region.
   *
   * - `maxRecordLimit`: Specify maximum number of records returned.
   *
   * - `groupId`: Filter the results by Rigel Group id.
   *
   * - `getConnectionStatus`: Flag to determine whether connection status needs to be fetched.
   *
   * - `fetchToken`: Flag to determine whether claim token needs to be fetched, groupId needs to be provided to make sure only one group is returned.
   *
   * @return Success
   */
  GetInternalRigelGroupsResponse(params: RigelmgmtService.GetInternalRigelGroupsParams): __Observable<__StrictHttpResponse<ListRigelGroupResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.resumeToken != null) __params = __params.set('resumeToken', params.resumeToken.toString());
    if (params.regionId != null) __params = __params.set('regionId', params.regionId.toString());
    if (params.maxRecordLimit != null) __params = __params.set('maxRecordLimit', params.maxRecordLimit.toString());
    if (params.groupId != null) __params = __params.set('groupId', params.groupId.toString());
    if (params.getConnectionStatus != null) __params = __params.set('getConnectionStatus', params.getConnectionStatus.toString());
    if (params.fetchToken != null) __params = __params.set('fetchToken', params.fetchToken.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/rigelmgmt/internal/rigel-groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListRigelGroupResult>;
      })
    );
  }
  /**
   * Get the list of Rigel Group information.
   *
   * Get the list of Rigel Group information.
   * @param params The `RigelmgmtService.GetInternalRigelGroupsParams` containing the following parameters:
   *
   * - `tenantId`: Filter the results by tenant.
   *
   * - `resumeToken`: Token returned by the last call which is used to resume the list operation.
   *
   * - `regionId`: Filter the results by region.
   *
   * - `maxRecordLimit`: Specify maximum number of records returned.
   *
   * - `groupId`: Filter the results by Rigel Group id.
   *
   * - `getConnectionStatus`: Flag to determine whether connection status needs to be fetched.
   *
   * - `fetchToken`: Flag to determine whether claim token needs to be fetched, groupId needs to be provided to make sure only one group is returned.
   *
   * @return Success
   */
  GetInternalRigelGroups(params: RigelmgmtService.GetInternalRigelGroupsParams): __Observable<ListRigelGroupResult> {
    return this.GetInternalRigelGroupsResponse(params).pipe(
      __map(_r => _r.body as ListRigelGroupResult)
    );
  }

  /**
   * Upgrade a given rigel.
   *
   * Upgrade a given rigel.
   * @param body Specifies the parameters to upgrade a rigel.
   * @return Response of upgrade rigel call.
   */
  UpgradeRigelResponse(body: UpgradeRigelRequest): __Observable<__StrictHttpResponse<UpgradeRigelResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/rigelmgmt/internal/rigel/upgrade`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpgradeRigelResult>;
      })
    );
  }
  /**
   * Upgrade a given rigel.
   *
   * Upgrade a given rigel.
   * @param body Specifies the parameters to upgrade a rigel.
   * @return Response of upgrade rigel call.
   */
  UpgradeRigel(body: UpgradeRigelRequest): __Observable<UpgradeRigelResult> {
    return this.UpgradeRigelResponse(body).pipe(
      __map(_r => _r.body as UpgradeRigelResult)
    );
  }

  /**
   * Update rigel's support channel access.
   *
   * Update rigel's support channel access.
   * @param body Specifies the parameters to update Rigel's support channel access.
   * @return Success
   */
  UpdateRigelSupportChannelResponse(body: ConnectorSupportChannelConfig): __Observable<__StrictHttpResponse<ConnectorSupportChannelConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/rigelmgmt/support-channel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ConnectorSupportChannelConfig>;
      })
    );
  }
  /**
   * Update rigel's support channel access.
   *
   * Update rigel's support channel access.
   * @param body Specifies the parameters to update Rigel's support channel access.
   * @return Success
   */
  UpdateRigelSupportChannel(body: ConnectorSupportChannelConfig): __Observable<ConnectorSupportChannelConfig> {
    return this.UpdateRigelSupportChannelResponse(body).pipe(
      __map(_r => _r.body as ConnectorSupportChannelConfig)
    );
  }

  /**
   * An endpoint to lock rigel claim for specified tenant.
   *
   * An endpoint to lock rigel claim. Input parameter is tenant ID.
   * @param body Specifies the parameters to lock rigel claim.
   * @return Response of lock Rigel claim call.
   */
  LockRigelClaimResponse(body: LockRigelClaimRequest): __Observable<__StrictHttpResponse<LockRigelClaimResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/rigelmgmt/internal/rigel-claim/lock`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LockRigelClaimResult>;
      })
    );
  }
  /**
   * An endpoint to lock rigel claim for specified tenant.
   *
   * An endpoint to lock rigel claim. Input parameter is tenant ID.
   * @param body Specifies the parameters to lock rigel claim.
   * @return Response of lock Rigel claim call.
   */
  LockRigelClaim(body: LockRigelClaimRequest): __Observable<LockRigelClaimResult> {
    return this.LockRigelClaimResponse(body).pipe(
      __map(_r => _r.body as LockRigelClaimResult)
    );
  }

  /**
   * An endpoint to unlock rigel claim for specified tenant.
   *
   * An endpoint to unlock rigel claim. Input parameter is tenant ID.
   * @param body Specifies the parameters to unlock rigel claim.
   */
  UnlockRigelClaimResponse(body: UnlockRigelClaimRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/rigelmgmt/internal/rigel-claim/lock`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * An endpoint to unlock rigel claim for specified tenant.
   *
   * An endpoint to unlock rigel claim. Input parameter is tenant ID.
   * @param body Specifies the parameters to unlock rigel claim.
   */
  UnlockRigelClaim(body: UnlockRigelClaimRequest): __Observable<null> {
    return this.UnlockRigelClaimResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Migrate a given tenant.
   *
   * Migrate a given tenant.
   * @param body Specifies the parameters to tenant migration request.
   */
  MigrateTenantResponse(body: MigrateTenantRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/rigelmgmt/internal/migrate-tenant`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Migrate a given tenant.
   *
   * Migrate a given tenant.
   * @param body Specifies the parameters to tenant migration request.
   */
  MigrateTenant(body: MigrateTenantRequest): __Observable<null> {
    return this.MigrateTenantResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Clean up tenant's connections/connectors on the old cluster.
   *
   * Clean up tenant's connections/connectors on the old cluster.
   * @param params The `RigelmgmtService.CleanUpTenantParams` containing the following parameters:
   *
   * - `tenantId`: Tenant id.
   *
   * - `regionId`: Region id.
   *
   * - `clusterId`: cluster ID for the old dataplane cluster.
   */
  CleanUpTenantResponse(params: RigelmgmtService.CleanUpTenantParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.regionId != null) __params = __params.set('regionId', params.regionId.toString());
    if (params.clusterId != null) __params = __params.set('clusterId', params.clusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/rigelmgmt/internal/migrate-tenant`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Clean up tenant's connections/connectors on the old cluster.
   *
   * Clean up tenant's connections/connectors on the old cluster.
   * @param params The `RigelmgmtService.CleanUpTenantParams` containing the following parameters:
   *
   * - `tenantId`: Tenant id.
   *
   * - `regionId`: Region id.
   *
   * - `clusterId`: cluster ID for the old dataplane cluster.
   */
  CleanUpTenant(params: RigelmgmtService.CleanUpTenantParams): __Observable<null> {
    return this.CleanUpTenantResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get rigel connectivity check endpoints.
   *
   * Get rigel connectivity check endpoints.
   * @return Response of get rigel connectivity check endpoints call.
   */
  GetConnectivityEndpointsResponse(): __Observable<__StrictHttpResponse<RigelConnectivityEndpoints>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/rigelmgmt/connectivity-endpoints`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RigelConnectivityEndpoints>;
      })
    );
  }
  /**
   * Get rigel connectivity check endpoints.
   *
   * Get rigel connectivity check endpoints.
   * @return Response of get rigel connectivity check endpoints call.
   */
  GetConnectivityEndpoints(): __Observable<RigelConnectivityEndpoints> {
    return this.GetConnectivityEndpointsResponse().pipe(
      __map(_r => _r.body as RigelConnectivityEndpoints)
    );
  }

  /**
   * Get connector connectivity information.
   *
   * Get connector connectivity check information.
   * @param params The `RigelmgmtService.GetConnectivityParams` containing the following parameters:
   *
   * - `getRealtimeStatus`: Whether to get real time connectivity info from SaaS connectors, it is false by fault.
   *
   * - `ConnectorIds`: connector ids separated with comma.
   *
   * @return Response of get rigel connectivity info call.
   */
  GetConnectivityResponse(params: RigelmgmtService.GetConnectivityParams): __Observable<__StrictHttpResponse<RigelConnectivityInfos>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.getRealtimeStatus != null) __params = __params.set('getRealtimeStatus', params.getRealtimeStatus.toString());
    if (params.ConnectorIds != null) __params = __params.set('ConnectorIds', params.ConnectorIds.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/rigelmgmt/connectivity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RigelConnectivityInfos>;
      })
    );
  }
  /**
   * Get connector connectivity information.
   *
   * Get connector connectivity check information.
   * @param params The `RigelmgmtService.GetConnectivityParams` containing the following parameters:
   *
   * - `getRealtimeStatus`: Whether to get real time connectivity info from SaaS connectors, it is false by fault.
   *
   * - `ConnectorIds`: connector ids separated with comma.
   *
   * @return Response of get rigel connectivity info call.
   */
  GetConnectivity(params: RigelmgmtService.GetConnectivityParams): __Observable<RigelConnectivityInfos> {
    return this.GetConnectivityResponse(params).pipe(
      __map(_r => _r.body as RigelConnectivityInfos)
    );
  }

  /**
   * Trigger connectivity check for SaaS connector.
   *
   * Trigger connectivity check for SaaS connector.
   * @param connectorIds Connector ids separated with comma.
   */
  CheckConnectivityResponse(connectorIds?: null | string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (connectorIds != null) __params = __params.set('connectorIds', connectorIds.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/rigelmgmt/connectivity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Trigger connectivity check for SaaS connector.
   *
   * Trigger connectivity check for SaaS connector.
   * @param connectorIds Connector ids separated with comma.
   */
  CheckConnectivity(connectorIds?: null | string): __Observable<null> {
    return this.CheckConnectivityResponse(connectorIds).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get s3 endpoint .
   *
   * Get s3 vault endpoint for the input connection id.
   * @param connectionId Connection id.
   * @return Response of get rigel connectivity check endpoints call.
   */
  GetS3EndpointResponse(connectionId?: number): __Observable<__StrictHttpResponse<RigelConnectivityEndpoints>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (connectionId != null) __params = __params.set('connectionId', connectionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/rigelmgmt/s3-endpoint`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RigelConnectivityEndpoints>;
      })
    );
  }
  /**
   * Get s3 endpoint .
   *
   * Get s3 vault endpoint for the input connection id.
   * @param connectionId Connection id.
   * @return Response of get rigel connectivity check endpoints call.
   */
  GetS3Endpoint(connectionId?: number): __Observable<RigelConnectivityEndpoints> {
    return this.GetS3EndpointResponse(connectionId).pipe(
      __map(_r => _r.body as RigelConnectivityEndpoints)
    );
  }

  /**
   * API to create and provision cloud rigel(s).
   *
   * API to create and provision cloud rigel(s).
   * @param body Specifies the parameters for provision cloud rigel request.
   */
  ProvisionCloudRigelResponse(body: ProvisionCloudRigelRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/rigelmgmt/internal/cloud-rigel/provision`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * API to create and provision cloud rigel(s).
   *
   * API to create and provision cloud rigel(s).
   * @param body Specifies the parameters for provision cloud rigel request.
   */
  ProvisionCloudRigel(body: ProvisionCloudRigelRequest): __Observable<null> {
    return this.ProvisionCloudRigelResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * API to get provisioning status of cloud rigel(s).
   *
   * API to get provisioning status of cloud rigel(s).
   * @param requestId Request id
   * @return Response of provision cloud rigel status call.
   */
  ProvisionCloudRigelStatusResponse(requestId: string): __Observable<__StrictHttpResponse<ProvisionCloudRigelStatusResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (requestId != null) __params = __params.set('requestId', requestId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/rigelmgmt/internal/cloud-rigel/provision-status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProvisionCloudRigelStatusResult>;
      })
    );
  }
  /**
   * API to get provisioning status of cloud rigel(s).
   *
   * API to get provisioning status of cloud rigel(s).
   * @param requestId Request id
   * @return Response of provision cloud rigel status call.
   */
  ProvisionCloudRigelStatus(requestId: string): __Observable<ProvisionCloudRigelStatusResult> {
    return this.ProvisionCloudRigelStatusResponse(requestId).pipe(
      __map(_r => _r.body as ProvisionCloudRigelStatusResult)
    );
  }

  /**
   * API to decommission cloud rigel(s).
   *
   * API to decommission cloud rigel(s).
   * @param body Specifies the parameters for decommission cloud rigel request.
   */
  DecommissionCloudRigelResponse(body: DecommissionCloudRigelRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/rigelmgmt/internal/cloud-rigel/decommission`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * API to decommission cloud rigel(s).
   *
   * API to decommission cloud rigel(s).
   * @param body Specifies the parameters for decommission cloud rigel request.
   */
  DecommissionCloudRigel(body: DecommissionCloudRigelRequest): __Observable<null> {
    return this.DecommissionCloudRigelResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * API to get decommissioning status of cloud rigel(s).
   *
   * API to get rigel decommissioning status of cloud rigel(s).
   * @param requestId Request id
   * @return Response of decommission cloud rigel status call.
   */
  DecommissionCloudRigelStatusResponse(requestId: string): __Observable<__StrictHttpResponse<DecommissionCloudRigelStatusResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (requestId != null) __params = __params.set('requestId', requestId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/rigelmgmt/internal/cloud-rigel/decommission-status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DecommissionCloudRigelStatusResult>;
      })
    );
  }
  /**
   * API to get decommissioning status of cloud rigel(s).
   *
   * API to get rigel decommissioning status of cloud rigel(s).
   * @param requestId Request id
   * @return Response of decommission cloud rigel status call.
   */
  DecommissionCloudRigelStatus(requestId: string): __Observable<DecommissionCloudRigelStatusResult> {
    return this.DecommissionCloudRigelStatusResponse(requestId).pipe(
      __map(_r => _r.body as DecommissionCloudRigelStatusResult)
    );
  }

  /**
   * API to extend lease of cloud rigel(s).
   *
   * API to extend lease of a cloud rigel.
   * @param body Specifies the parameters to extend lease.
   */
  ExtendCloudRigelLeaseResponse(body: ExtendCloudRigelLeaseRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/rigelmgmt/internal/cloud-rigel/extend-cloud-rigel-lease`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * API to extend lease of cloud rigel(s).
   *
   * API to extend lease of a cloud rigel.
   * @param body Specifies the parameters to extend lease.
   */
  ExtendCloudRigelLease(body: ExtendCloudRigelLeaseRequest): __Observable<null> {
    return this.ExtendCloudRigelLeaseResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * API to update the number of connectors in the AWS connection.
   *
   * API to update the number of connectors in the AWS connection.
   * @param params The `RigelmgmtService.UpdateAWSConnectorParams` containing the following parameters:
   *
   * - `numberOfConnectors`: Updated number of connectors.
   *
   * - `connectionId`: Connection id.
   */
  UpdateAWSConnectorResponse(params: RigelmgmtService.UpdateAWSConnectorParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.numberOfConnectors != null) __params = __params.set('numberOfConnectors', params.numberOfConnectors.toString());
    if (params.connectionId != null) __params = __params.set('connectionId', params.connectionId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/rigelmgmt/aws-connection/connectors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * API to update the number of connectors in the AWS connection.
   *
   * API to update the number of connectors in the AWS connection.
   * @param params The `RigelmgmtService.UpdateAWSConnectorParams` containing the following parameters:
   *
   * - `numberOfConnectors`: Updated number of connectors.
   *
   * - `connectionId`: Connection id.
   */
  UpdateAWSConnector(params: RigelmgmtService.UpdateAWSConnectorParams): __Observable<null> {
    return this.UpdateAWSConnectorResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * API to pair on prem cluster with ODP cluster.
   *
   * API to pair on prem cluster with ODP cluster.
   * @param body Specifies the parameters to pair on prem cluster.
   * @return Response of pair onprem cluster call.
   */
  PairOnPremClusterResponse(body: PairOnPremClusterRequest): __Observable<__StrictHttpResponse<PairOnPremClusterResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/rigelmgmt/internal/pair-on-prem-cluster`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PairOnPremClusterResult>;
      })
    );
  }
  /**
   * API to pair on prem cluster with ODP cluster.
   *
   * API to pair on prem cluster with ODP cluster.
   * @param body Specifies the parameters to pair on prem cluster.
   * @return Response of pair onprem cluster call.
   */
  PairOnPremCluster(body: PairOnPremClusterRequest): __Observable<PairOnPremClusterResult> {
    return this.PairOnPremClusterResponse(body).pipe(
      __map(_r => _r.body as PairOnPremClusterResult)
    );
  }

  /**
   * API to get get connector group update status.
   *
   * API to get connector group update status.
   * @param requestId Request id
   * @return Response of get update connectors status call.
   */
  GetConnectorGroupUpdateStatusResponse(requestId: string): __Observable<__StrictHttpResponse<GetConnectorGroupUpdateStatusResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (requestId != null) __params = __params.set('requestId', requestId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/rigelmgmt/connector-group/status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetConnectorGroupUpdateStatusResult>;
      })
    );
  }
  /**
   * API to get get connector group update status.
   *
   * API to get connector group update status.
   * @param requestId Request id
   * @return Response of get update connectors status call.
   */
  GetConnectorGroupUpdateStatus(requestId: string): __Observable<GetConnectorGroupUpdateStatusResult> {
    return this.GetConnectorGroupUpdateStatusResponse(requestId).pipe(
      __map(_r => _r.body as GetConnectorGroupUpdateStatusResult)
    );
  }

  /**
   * Bring up the first connector inside connection.
   *
   * Bring up the first connector inside connection.
   * @param params The `RigelmgmtService.BringUpConnectorParams` containing the following parameters:
   *
   * - `connectorGroupId`: Connector group id
   *
   * - `connectionId`: Connection id
   */
  BringUpConnectorResponse(params: RigelmgmtService.BringUpConnectorParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.connectorGroupId != null) __params = __params.set('connectorGroupId', params.connectorGroupId.toString());
    if (params.connectionId != null) __params = __params.set('connectionId', params.connectionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/rigelmgmt/connector/bring-up`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Bring up the first connector inside connection.
   *
   * Bring up the first connector inside connection.
   * @param params The `RigelmgmtService.BringUpConnectorParams` containing the following parameters:
   *
   * - `connectorGroupId`: Connector group id
   *
   * - `connectionId`: Connection id
   */
  BringUpConnector(params: RigelmgmtService.BringUpConnectorParams): __Observable<null> {
    return this.BringUpConnectorResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Upgrade rigel group.
   * @param body Specifies the parameters to upgrade a rigel group.
   */
  UpgradeRigelGroupResponse(body: UpgradeRigelGroupRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/rigelmgmt/rigel-groups/upgrade`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Upgrade rigel group.
   * @param body Specifies the parameters to upgrade a rigel group.
   */
  UpgradeRigelGroup(body: UpgradeRigelGroupRequest): __Observable<null> {
    return this.UpgradeRigelGroupResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Upgrade status of rigel group.
   *
   * Get the upgrade status of rigel group.
   * @param requestId Request ID to the upgrade request.
   * @return Response of upgrade rigel clusters of cluster group.
   */
  UpgradeRigelGroupStatusResponse(requestId: string): __Observable<__StrictHttpResponse<RigelClusterUpgradeInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (requestId != null) __params = __params.set('requestId', requestId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/rigelmgmt/rigel-groups/upgrade`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RigelClusterUpgradeInfo>;
      })
    );
  }
  /**
   * Upgrade status of rigel group.
   *
   * Get the upgrade status of rigel group.
   * @param requestId Request ID to the upgrade request.
   * @return Response of upgrade rigel clusters of cluster group.
   */
  UpgradeRigelGroupStatus(requestId: string): __Observable<RigelClusterUpgradeInfo> {
    return this.UpgradeRigelGroupStatusResponse(requestId).pipe(
      __map(_r => _r.body as RigelClusterUpgradeInfo)
    );
  }
}

module RigelmgmtService {

  /**
   * Parameters for GetRigels
   */
  export interface GetRigelsParams {

    /**
     * Filter the results by tenant.
     */
    tenantId: string;

    /**
     * Flag to tell Rigel mgmt whether to skip to get latest connection status for Rigels in the response.
     */
    skipConnectionStatus?: null | boolean;

    /**
     * Filter the results by Rigel guid.
     */
    rigelGuid?: null | number;

    /**
     * Token returned by the last call which is used to resume the list operation.
     */
    resumeToken?: null | string;

    /**
     * Filter the results by region.
     */
    regionId?: null | string;

    /**
     * Specify maximum number of records returned.
     */
    maxRecordLimit?: null | number;

    /**
     * Whether to include cloud Rigels in the response.
     */
    includeCloudRigel?: null | boolean;

    /**
     * Filter the results by Rigel Group id.
     */
    groupId?: null | number;

    /**
     * Flag to tell Rigel mgmt whether to fetch latest support channel config for Rigels in the response.
     */
    getSupportChannelConfig?: null | boolean;
  }

  /**
   * Parameters for DeleteRigels
   */
  export interface DeleteRigelsParams {

    /**
     * Delete the rigels by tenant.
     */
    tenantId?: null | string;

    /**
     * Delete the rigels by rigel GUID.
     */
    rigelGuid?: null | number;

    /**
     * Delete the rigels by region.
     */
    regionId?: null | string;

    /**
     * To force delete rigels. AWS rigel only
     */
    forceDelete?: null | boolean;
  }

  /**
   * Parameters for DeleteRigelConnection
   */
  export interface DeleteRigelConnectionParams {

    /**
     * Tenant id of the connection.
     */
    tenantId?: null | string;

    /**
     * To force delete rigels. AWS rigel only
     */
    forceDelete?: null | boolean;

    /**
     * Delete the connection by connectionId.
     */
    connectionId?: null | number;
  }

  /**
   * Parameters for GetRigelGroups
   */
  export interface GetRigelGroupsParams {

    /**
     * Filter the results by tenant.
     */
    tenantId: string;

    /**
     * Token returned by the last call which is used to resume the list operation.
     */
    resumeToken?: null | string;

    /**
     * Filter the results by region.
     */
    regionId?: null | string;

    /**
     * Specify maximum number of records returned.
     */
    maxRecordLimit?: null | number;

    /**
     * Filter the results by Rigel Group id.
     */
    groupId?: null | number;

    /**
     * Flag to determine whether connection status needs to be fetched.
     */
    getConnectionStatus?: null | boolean;

    /**
     * Flag to determine whether claim token needs to be fetched, groupId needs to be provided to make sure only one group is returned.
     */
    fetchToken?: null | boolean;

    /**
     * Flag to determine whether response contains connector groups and rigels under the connector group.
     */
    fetchConnectorGroups?: null | boolean;
  }

  /**
   * Parameters for GetConnectionBandwidth
   */
  export interface GetConnectionBandwidthParams {

    /**
     * TenantId corresponding to the connection.
     */
    tenantId?: string;

    /**
     * ConnectionId for which bandwidth settings are to be returned. Pass connector group id as connectionId if connector group level bandwidth needs to be fetched.
     */
    connectionId?: number;
  }

  /**
   * Parameters for GetInternalRigels
   */
  export interface GetInternalRigelsParams {

    /**
     * Filter the results by tenant.
     */
    tenantId?: string;

    /**
     * Flag to tell Rigel mgmt whether to skip to get latest connection status for Rigels in the response.
     */
    skipConnectionStatus?: boolean;

    /**
     * Token returned by the last call which is used to resume the list operation.
     */
    resumeToken?: string;

    /**
     * Filter the results by region.
     */
    regionId?: string;

    /**
     * Specify maximum number of records returned.
     */
    maxRecordLimit?: string;

    /**
     * Filter the results by Rigel Group id.
     */
    groupId?: number;
  }

  /**
   * Parameters for GetInternalRigelGroups
   */
  export interface GetInternalRigelGroupsParams {

    /**
     * Filter the results by tenant.
     */
    tenantId?: string;

    /**
     * Token returned by the last call which is used to resume the list operation.
     */
    resumeToken?: string;

    /**
     * Filter the results by region.
     */
    regionId?: string;

    /**
     * Specify maximum number of records returned.
     */
    maxRecordLimit?: string;

    /**
     * Filter the results by Rigel Group id.
     */
    groupId?: number;

    /**
     * Flag to determine whether connection status needs to be fetched.
     */
    getConnectionStatus?: boolean;

    /**
     * Flag to determine whether claim token needs to be fetched, groupId needs to be provided to make sure only one group is returned.
     */
    fetchToken?: boolean;
  }

  /**
   * Parameters for CleanUpTenant
   */
  export interface CleanUpTenantParams {

    /**
     * Tenant id.
     */
    tenantId?: string;

    /**
     * Region id.
     */
    regionId?: string;

    /**
     * cluster ID for the old dataplane cluster.
     */
    clusterId?: number;
  }

  /**
   * Parameters for GetConnectivity
   */
  export interface GetConnectivityParams {

    /**
     * Whether to get real time connectivity info from SaaS connectors, it is false by fault.
     */
    getRealtimeStatus?: null | boolean;

    /**
     * connector ids separated with comma.
     */
    ConnectorIds?: null | string;
  }

  /**
   * Parameters for UpdateAWSConnector
   */
  export interface UpdateAWSConnectorParams {

    /**
     * Updated number of connectors.
     */
    numberOfConnectors?: null | number;

    /**
     * Connection id.
     */
    connectionId?: null | number;
  }

  /**
   * Parameters for BringUpConnector
   */
  export interface BringUpConnectorParams {

    /**
     * Connector group id
     */
    connectorGroupId: number;

    /**
     * Connection id
     */
    connectionId: number;
  }
}

export { RigelmgmtService }
