/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BifrostConnections } from '../models/bifrost-connections';
import { BifrostConnection } from '../models/bifrost-connection';
import { CreateBifrostConnectionRequest } from '../models/create-bifrost-connection-request';
import { UpdateBifrostConnectionRequest } from '../models/update-bifrost-connection-request';
import { RigelConnections } from '../models/rigel-connections';
import { RigelConnection } from '../models/rigel-connection';
import { CreateRigelConnectionRequest } from '../models/create-rigel-connection-request';
import { UpdateRigelConnectionRequest } from '../models/update-rigel-connection-request';
import { GetConnectionBandwidthResponseBody } from '../models/get-connection-bandwidth-response-body';
import { ConnectionBandwidthLimits } from '../models/connection-bandwidth-limits';
@Injectable({
  providedIn: 'root',
})
class ExternalConnectionService extends __BaseService {
  static readonly GetBifrostConnectionPath = '/connection-bifrost';
  static readonly CreateBifrostConnectionPath = '/connection-bifrost';
  static readonly GetBifrostConnectionByIdPath = '/connection-bifrost/{id}';
  static readonly UpdateBifrostConnectionPath = '/connection-bifrost/{id}';
  static readonly DeleteBifrostConnectionPath = '/connection-bifrost/{id}';
  static readonly GetRigelConnectionPath = '/connection-rigel';
  static readonly CreateRigelConnectionPath = '/connection-rigel';
  static readonly GetRigelConnectionByIdPath = '/connection-rigel/{id}';
  static readonly UpdateRigelConnectionPath = '/connection-rigel/{id}';
  static readonly DeleteRigelConnectionPath = '/connection-rigel/{id}';
  static readonly GetConnectionBandwidthPath = '/connection-rigel/{id}/bandwidth';
  static readonly UpdateConnectionBandwidthPath = '/connection-rigel/{id}/bandwidth';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get connections of Bifrost on the cluster.
   *
   * Get connections of Bifrost on the cluster.
   * @param params The `ExternalConnectionService.GetBifrostConnectionParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the id of the tenant which the connection belongs to.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `names`: Specifies the name of the connections.
   *
   * - `ids`: Specifies the id of the connections.
   *
   * - `defaultConnectionOnly`: Only return the default Bifrost connections (exactly one default connection is internally created for each Bifrost enabled tenant).
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetBifrostConnectionResponse(params: ExternalConnectionService.GetBifrostConnectionParams): __Observable<__StrictHttpResponse<BifrostConnections>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.names || []).forEach(val => {if (val != null) __params = __params.append('names', val.toString())});
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.defaultConnectionOnly != null) __params = __params.set('defaultConnectionOnly', params.defaultConnectionOnly.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/connection-bifrost`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BifrostConnections>;
      })
    );
  }
  /**
   * Get connections of Bifrost on the cluster.
   *
   * Get connections of Bifrost on the cluster.
   * @param params The `ExternalConnectionService.GetBifrostConnectionParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the id of the tenant which the connection belongs to.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `names`: Specifies the name of the connections.
   *
   * - `ids`: Specifies the id of the connections.
   *
   * - `defaultConnectionOnly`: Only return the default Bifrost connections (exactly one default connection is internally created for each Bifrost enabled tenant).
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetBifrostConnection(params: ExternalConnectionService.GetBifrostConnectionParams): __Observable<BifrostConnections> {
    return this.GetBifrostConnectionResponse(params).pipe(
      __map(_r => _r.body as BifrostConnections)
    );
  }

  /**
   * Create a connection of Bifrost on the cluster.
   *
   * Create a connection of Bifrost on the cluster.
   * @param params The `ExternalConnectionService.CreateBifrostConnectionParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a connection.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateBifrostConnectionResponse(params: ExternalConnectionService.CreateBifrostConnectionParams): __Observable<__StrictHttpResponse<BifrostConnection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/connection-bifrost`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BifrostConnection>;
      })
    );
  }
  /**
   * Create a connection of Bifrost on the cluster.
   *
   * Create a connection of Bifrost on the cluster.
   * @param params The `ExternalConnectionService.CreateBifrostConnectionParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a connection.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateBifrostConnection(params: ExternalConnectionService.CreateBifrostConnectionParams): __Observable<BifrostConnection> {
    return this.CreateBifrostConnectionResponse(params).pipe(
      __map(_r => _r.body as BifrostConnection)
    );
  }

  /**
   * Get a connection of Bifrost by the id.
   *
   * Get a connection of Bifrost by the id.
   * @param params The `ExternalConnectionService.GetBifrostConnectionByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Bifrost connection.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetBifrostConnectionByIdResponse(params: ExternalConnectionService.GetBifrostConnectionByIdParams): __Observable<__StrictHttpResponse<BifrostConnection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/connection-bifrost/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BifrostConnection>;
      })
    );
  }
  /**
   * Get a connection of Bifrost by the id.
   *
   * Get a connection of Bifrost by the id.
   * @param params The `ExternalConnectionService.GetBifrostConnectionByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Bifrost connection.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetBifrostConnectionById(params: ExternalConnectionService.GetBifrostConnectionByIdParams): __Observable<BifrostConnection> {
    return this.GetBifrostConnectionByIdResponse(params).pipe(
      __map(_r => _r.body as BifrostConnection)
    );
  }

  /**
   * Update a connection of Bifrost.
   *
   * Update a connection of Bifrost.
   * @param params The `ExternalConnectionService.UpdateBifrostConnectionParams` containing the following parameters:
   *
   * - `id`: Specifies the id of a Bifrost connection.
   *
   * - `body`: Specifies the parameters to update a connection.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateBifrostConnectionResponse(params: ExternalConnectionService.UpdateBifrostConnectionParams): __Observable<__StrictHttpResponse<BifrostConnection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/connection-bifrost/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BifrostConnection>;
      })
    );
  }
  /**
   * Update a connection of Bifrost.
   *
   * Update a connection of Bifrost.
   * @param params The `ExternalConnectionService.UpdateBifrostConnectionParams` containing the following parameters:
   *
   * - `id`: Specifies the id of a Bifrost connection.
   *
   * - `body`: Specifies the parameters to update a connection.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateBifrostConnection(params: ExternalConnectionService.UpdateBifrostConnectionParams): __Observable<BifrostConnection> {
    return this.UpdateBifrostConnectionResponse(params).pipe(
      __map(_r => _r.body as BifrostConnection)
    );
  }

  /**
   * Delete a connection of Bifrost.
   *
   * Delete a connection of Bifrost.
   * @param params The `ExternalConnectionService.DeleteBifrostConnectionParams` containing the following parameters:
   *
   * - `id`: Specifies the id of a Bifrost connection.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteBifrostConnectionResponse(params: ExternalConnectionService.DeleteBifrostConnectionParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/connection-bifrost/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a connection of Bifrost.
   *
   * Delete a connection of Bifrost.
   * @param params The `ExternalConnectionService.DeleteBifrostConnectionParams` containing the following parameters:
   *
   * - `id`: Specifies the id of a Bifrost connection.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteBifrostConnection(params: ExternalConnectionService.DeleteBifrostConnectionParams): __Observable<null> {
    return this.DeleteBifrostConnectionResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get connections of Rigel on the cluster.
   *
   * Get connections of Rigel on the cluster.
   * @param params The `ExternalConnectionService.GetRigelConnectionParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the id of the tenant which the connection belongs to.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `names`: Specifies the name of the connection.
   *
   * - `ids`: Specifies the id of the connections.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRigelConnectionResponse(params: ExternalConnectionService.GetRigelConnectionParams): __Observable<__StrictHttpResponse<RigelConnections>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.names || []).forEach(val => {if (val != null) __params = __params.append('names', val.toString())});
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/connection-rigel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RigelConnections>;
      })
    );
  }
  /**
   * Get connections of Rigel on the cluster.
   *
   * Get connections of Rigel on the cluster.
   * @param params The `ExternalConnectionService.GetRigelConnectionParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the id of the tenant which the connection belongs to.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `names`: Specifies the name of the connection.
   *
   * - `ids`: Specifies the id of the connections.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRigelConnection(params: ExternalConnectionService.GetRigelConnectionParams): __Observable<RigelConnections> {
    return this.GetRigelConnectionResponse(params).pipe(
      __map(_r => _r.body as RigelConnections)
    );
  }

  /**
   * Create a connection of Rigel on the cluster.
   *
   * Create a connection of Rigel on the cluster.
   * @param params The `ExternalConnectionService.CreateRigelConnectionParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a connection.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateRigelConnectionResponse(params: ExternalConnectionService.CreateRigelConnectionParams): __Observable<__StrictHttpResponse<RigelConnection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/connection-rigel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RigelConnection>;
      })
    );
  }
  /**
   * Create a connection of Rigel on the cluster.
   *
   * Create a connection of Rigel on the cluster.
   * @param params The `ExternalConnectionService.CreateRigelConnectionParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a connection.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateRigelConnection(params: ExternalConnectionService.CreateRigelConnectionParams): __Observable<RigelConnection> {
    return this.CreateRigelConnectionResponse(params).pipe(
      __map(_r => _r.body as RigelConnection)
    );
  }

  /**
   * Get a connection of Rigel by the id.
   *
   * Get a connection of Rigel by the id.
   * @param params The `ExternalConnectionService.GetRigelConnectionByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Rigel connection.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRigelConnectionByIdResponse(params: ExternalConnectionService.GetRigelConnectionByIdParams): __Observable<__StrictHttpResponse<RigelConnection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/connection-rigel/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RigelConnection>;
      })
    );
  }
  /**
   * Get a connection of Rigel by the id.
   *
   * Get a connection of Rigel by the id.
   * @param params The `ExternalConnectionService.GetRigelConnectionByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Rigel connection.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRigelConnectionById(params: ExternalConnectionService.GetRigelConnectionByIdParams): __Observable<RigelConnection> {
    return this.GetRigelConnectionByIdResponse(params).pipe(
      __map(_r => _r.body as RigelConnection)
    );
  }

  /**
   * Update a connection of Rigel.
   *
   * Update a connection of Rigel.
   * @param params The `ExternalConnectionService.UpdateRigelConnectionParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Rigel connection.
   *
   * - `body`: Specifies the parameters to update the connection.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateRigelConnectionResponse(params: ExternalConnectionService.UpdateRigelConnectionParams): __Observable<__StrictHttpResponse<RigelConnection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/connection-rigel/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RigelConnection>;
      })
    );
  }
  /**
   * Update a connection of Rigel.
   *
   * Update a connection of Rigel.
   * @param params The `ExternalConnectionService.UpdateRigelConnectionParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Rigel connection.
   *
   * - `body`: Specifies the parameters to update the connection.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateRigelConnection(params: ExternalConnectionService.UpdateRigelConnectionParams): __Observable<RigelConnection> {
    return this.UpdateRigelConnectionResponse(params).pipe(
      __map(_r => _r.body as RigelConnection)
    );
  }

  /**
   * Delete a connection of Rigel.
   *
   * Delete a connection of Rigel.
   * @param params The `ExternalConnectionService.DeleteRigelConnectionParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Rigel connection.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteRigelConnectionResponse(params: ExternalConnectionService.DeleteRigelConnectionParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/connection-rigel/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a connection of Rigel.
   *
   * Delete a connection of Rigel.
   * @param params The `ExternalConnectionService.DeleteRigelConnectionParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Rigel connection.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteRigelConnection(params: ExternalConnectionService.DeleteRigelConnectionParams): __Observable<null> {
    return this.DeleteRigelConnectionResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * List the upload and download bandwidth limits for a connection.
   *
   * Returns the upload and download bandwidth limits for a connection.
   * @param params The `ExternalConnectionService.GetConnectionBandwidthParams` containing the following parameters:
   *
   * - `id`: Connection Id for which bandwidth settings are to be returned.
   *
   * - `tenantId`: TenantId corresponding to the connection.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetConnectionBandwidthResponse(params: ExternalConnectionService.GetConnectionBandwidthParams): __Observable<__StrictHttpResponse<GetConnectionBandwidthResponseBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/connection-rigel/${encodeURIComponent(params.id)}/bandwidth`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetConnectionBandwidthResponseBody>;
      })
    );
  }
  /**
   * List the upload and download bandwidth limits for a connection.
   *
   * Returns the upload and download bandwidth limits for a connection.
   * @param params The `ExternalConnectionService.GetConnectionBandwidthParams` containing the following parameters:
   *
   * - `id`: Connection Id for which bandwidth settings are to be returned.
   *
   * - `tenantId`: TenantId corresponding to the connection.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetConnectionBandwidth(params: ExternalConnectionService.GetConnectionBandwidthParams): __Observable<GetConnectionBandwidthResponseBody> {
    return this.GetConnectionBandwidthResponse(params).pipe(
      __map(_r => _r.body as GetConnectionBandwidthResponseBody)
    );
  }

  /**
   * Updates bandwidth limits for a connection.
   *
   * Returns the updated connection bandwidth limits.
   * @param params The `ExternalConnectionService.UpdateConnectionBandwidthParams` containing the following parameters:
   *
   * - `id`: Connection Id for which bandwidth settings are to be returned
   *
   * - `body`: Request to update connection bandwidth limits settings.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateConnectionBandwidthResponse(params: ExternalConnectionService.UpdateConnectionBandwidthParams): __Observable<__StrictHttpResponse<GetConnectionBandwidthResponseBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/connection-rigel/${encodeURIComponent(params.id)}/bandwidth`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetConnectionBandwidthResponseBody>;
      })
    );
  }
  /**
   * Updates bandwidth limits for a connection.
   *
   * Returns the updated connection bandwidth limits.
   * @param params The `ExternalConnectionService.UpdateConnectionBandwidthParams` containing the following parameters:
   *
   * - `id`: Connection Id for which bandwidth settings are to be returned
   *
   * - `body`: Request to update connection bandwidth limits settings.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateConnectionBandwidth(params: ExternalConnectionService.UpdateConnectionBandwidthParams): __Observable<GetConnectionBandwidthResponseBody> {
    return this.UpdateConnectionBandwidthResponse(params).pipe(
      __map(_r => _r.body as GetConnectionBandwidthResponseBody)
    );
  }
}

module ExternalConnectionService {

  /**
   * Parameters for GetBifrostConnection
   */
  export interface GetBifrostConnectionParams {

    /**
     * Specifies the id of the tenant which the connection belongs to.
     */
    tenantId?: null | string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the name of the connections.
     */
    names?: null | Array<string>;

    /**
     * Specifies the id of the connections.
     */
    ids?: null | Array<number>;

    /**
     * Only return the default Bifrost connections (exactly one default connection is internally created for each Bifrost enabled tenant).
     */
    defaultConnectionOnly?: null | boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateBifrostConnection
   */
  export interface CreateBifrostConnectionParams {

    /**
     * Specifies the parameters to create a connection.
     */
    body: CreateBifrostConnectionRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetBifrostConnectionById
   */
  export interface GetBifrostConnectionByIdParams {

    /**
     * Specifies the id of the Bifrost connection.
     */
    id: null | number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateBifrostConnection
   */
  export interface UpdateBifrostConnectionParams {

    /**
     * Specifies the id of a Bifrost connection.
     */
    id: null | number;

    /**
     * Specifies the parameters to update a connection.
     */
    body: UpdateBifrostConnectionRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteBifrostConnection
   */
  export interface DeleteBifrostConnectionParams {

    /**
     * Specifies the id of a Bifrost connection.
     */
    id: null | number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetRigelConnection
   */
  export interface GetRigelConnectionParams {

    /**
     * Specifies the id of the tenant which the connection belongs to.
     */
    tenantId?: null | string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the name of the connection.
     */
    names?: null | Array<string>;

    /**
     * Specifies the id of the connections.
     */
    ids?: null | Array<number>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateRigelConnection
   */
  export interface CreateRigelConnectionParams {

    /**
     * Specifies the parameters to create a connection.
     */
    body: CreateRigelConnectionRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetRigelConnectionById
   */
  export interface GetRigelConnectionByIdParams {

    /**
     * Specifies the id of the Rigel connection.
     */
    id: null | number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateRigelConnection
   */
  export interface UpdateRigelConnectionParams {

    /**
     * Specifies the id of the Rigel connection.
     */
    id: null | number;

    /**
     * Specifies the parameters to update the connection.
     */
    body: UpdateRigelConnectionRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteRigelConnection
   */
  export interface DeleteRigelConnectionParams {

    /**
     * Specifies the id of the Rigel connection.
     */
    id: null | number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetConnectionBandwidth
   */
  export interface GetConnectionBandwidthParams {

    /**
     * Connection Id for which bandwidth settings are to be returned.
     */
    id: null | number;

    /**
     * TenantId corresponding to the connection.
     */
    tenantId?: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateConnectionBandwidth
   */
  export interface UpdateConnectionBandwidthParams {

    /**
     * Connection Id for which bandwidth settings are to be returned
     */
    id: null | number;

    /**
     * Request to update connection bandwidth limits settings.
     */
    body: ConnectionBandwidthLimits;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { ExternalConnectionService }
