import { Component } from '@angular/core';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { PopoverRef } from '@cohesity/helix';
import { Environment } from '@cohesity/iris-shared-constants';

import { SourceKeys } from '../../constants';

@Component({
  templateUrl: './nas-popover.component.html',
  styleUrls: ['./nas-popover.component.scss'],
})
export class NasPopoverComponent {

  /**
   * Reference to ProtectionSourceNode which is passed as part of PopoverRef
   * once popover is opened.
   */
  node: ProtectionSourceNode;

  /**
   * Whether node is protected or not
   */
  nodeProtected: boolean;

  /**
   * Gets the node's environment.
   */
  get environment(): Environment {
    return this.node.protectionSource.environment as Environment;
  }

  /**
   * Gets the env source node based on the environment. This is typed to any, since it could be many different types.
   */
  get envSource(): any {
    const key = SourceKeys[this.environment];
    return this.node.protectionSource[key];
  }

  /**
   * Volume Type of the node. Valid values are kReadWrite (Read-Write Volumes)
   * and kDataProtection (Read-Only Volumes).
   *
   */
  get nodeVolumeType(): string {
    return this.environment === Environment.kNetapp &&
      this.node.protectionSource.netappProtectionSource.volumeInfo.type;
  }


  constructor(private popoverRef: PopoverRef<ProtectionSourceNode>) {
    this.node = this.popoverRef.data;
    const protectionSummary = this.node.protectedSourcesSummary;
    if (protectionSummary && protectionSummary[0].leavesCount && protectionSummary[0].leavesCount > 0) {
      this.nodeProtected = true;
    }
  }

  /**
   * Returns whether nas node has popover or not based on node specific logic
   *
   * @returns  true/false whether node has popover or not
   */
  static hasPopover(nasNode: ProtectionSourceNode) {
    if (!nasNode.nodes) {
      return true;
    }
    return false;
  }
}
