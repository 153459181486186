import { Injectable } from '@angular/core';
import { UserPreferences, UserPreferencesServiceApi } from '@cohesity/api/v2';
import { ThemeService } from '@cohesity/helix';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AutoDestroyable } from '@cohesity/utils';
import { IrisContextService, isLoggedIn, isMcm } from '../iris-context';

/**
 * TODO: This service is Helios specific at the moment.
 */
@Injectable({
  providedIn: 'root',
})
export class UserPreferenceService extends AutoDestroyable {
  /**
   * User Preferences.
   */
  get preferences(): any {
    return JSON.parse((this.userPreferencesSubject.value as any)?.preferences || null) || {};
  }

  /**
   * Behavior Subject of user preferences.
   */
  private userPreferencesSubject = new BehaviorSubject<UserPreferences>(null);

  /**
   * Async user preferences.
   */
  public userPreferences$ = this.userPreferencesSubject.asObservable();

  /**
   * Saving preferences if true. Used to avoid saving preferences twice because saving preferences will trigger theme
   * change which trigger saving preferences again.
   */
  public savingPreferences = false;

  constructor(
    private themeService: ThemeService,
    private userPreferencesService: UserPreferencesServiceApi,
    private irisContext: IrisContextService
  ) {
    super();
    // TODO: This constructor is only triggered once in OnPrem mode, but needs
    // to be triggered again after login. This is fine currently since
    // this service is only used in Helios.
    let skipSave = false;
    this.userPreferences$.pipe(
      filter(() => this.irisContext?.irisContext &&
        isMcm(this.irisContext.irisContext) &&
        isLoggedIn(this.irisContext.irisContext)
      ),
      take(1),
    ).subscribe(() => {
      if (Object.prototype.hasOwnProperty.call(this.preferences, 'darkTheme')) {
        this.themeService.setMode(this.preferences.darkTheme ? 'dark' : 'light');
      }
    });
    this.themeService.currentTheme$.pipe(
      this.untilDestroy(),
      filter(() => this.irisContext?.irisContext &&
        isMcm(this.irisContext.irisContext) &&
        isLoggedIn(this.irisContext.irisContext)
      ),
      tap(() => {
        if (this.savingPreferences) {
          skipSave = true;
        }
      }),
      distinctUntilChanged(),
      switchMap((theme) => {
        if (skipSave) {
          skipSave = false;
          return of(null);
        }
        return this.saveTheme(theme?.mode === 'dark');
      }),
    ).subscribe();
  }

  /**
   * Load user preferences. This will be called from CustomizationService automatically so
   * no need to call it from constructor.
   */
  load() {
    this.userPreferencesService.GetUserPreferences().subscribe(this.userPreferencesSubject);
  }

  /**
   * Save user preferences.
   *
   * @param userPreferences User preferences.
   * @returns Observable of user preferences.
   */
  save(userPreferences: UserPreferences): Observable<UserPreferences> {
    return this.userPreferencesService.UpdateUserPreferences(userPreferences).pipe(
      tap(result => this.userPreferencesSubject.next(result)),
    );
  }

  /**
   * Save user preference theme using API.
   *
   * @param darkTheme  True if dark theme.
   * @returns Observable of darkTheme.
   */
  saveTheme(darkTheme = false): Observable<boolean> {
    if (this.preferences.darkTheme !== darkTheme) {
      const preferences = { ...this.preferences };
      preferences.darkTheme = darkTheme;
      return this.save({
        ...this.userPreferencesSubject.value,
        preferences: JSON.stringify(preferences),
      } as UserPreferences).pipe(
        map(() => darkTheme),
      );
    }
    return of(darkTheme);
  }
}
