import { Pipe, PipeTransform } from '@angular/core';

/**
 * @description
 * Converts number value to string and adds plus when value is positive.
 *
 * @example
 *    {{ 123 |  valueChangeTrend }} will output "+123"
 */
@Pipe({
  name: 'valueChangeTrend'
})
export class ValueChangeTrendPipe implements PipeTransform {

  /**
   * Transforms positive or negative number into string with + or - symbol.
   */
  transform(number: number): string {
    return number > 0 ? `+${number}` : `${number}`;
  }

}
