import { ChangeDetectionStrategy, Component, OnChanges } from '@angular/core';
import { customDateTimeLabelFormats, HighchartsDataConfigService } from '@cohesity/helix';
import { Options, SeriesLineOptions } from 'highcharts';

import { CustomChartInput } from '../../../iris-reporting.model';
import { ChartReportsService } from '../../chart-reports.service';
import { BaseReportItemRendererComponent } from '../base-report-item-renderer.component';

/**
 * A line chart report item.
 */
@Component({
  selector: 'iris-rpt-line-chart-item',
  templateUrl: './line-chart-item.component.html',
  styleUrls: ['./line-chart-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineChartItemComponent extends BaseReportItemRendererComponent implements OnChanges, CustomChartInput {
  /**
   * The chart's series, transformed from the input.
   */
  seriesData: SeriesLineOptions[] = [];

  /**
   * Additional options used to format the chart.
   */
  options: Options = {
    chart: {
      animation: true,
    },
    plotOptions: {
      line: {
        marker: {
          enabled: true,
        },
        showInLegend: true,
        ...this.chartPlotOptions,
      },
      series: {
        pointStart: 0,
      },
    },
    legend: {
      enabled: true,
      align: 'right',
      verticalAlign: 'top',
      layout: 'vertical',
      itemMarginBottom: 4,
    },
    yAxis: {
      visible: true,
      title: {
        enabled: false,
      } as any,
    },
  };

  /**
   * Given a chart config, determine whether this chart can be used to render it or now.
   *
   * @param input The chart input
   * @returns True if this component can render it.
   */
  static canRender(input: CustomChartInput): boolean {
    return input.dimensions?.length && !!input.measurements?.length;
  }

  constructor(private chartService: ChartReportsService, private highchartConfigService: HighchartsDataConfigService) {
    super();
  }

  ngOnChanges(): void {
    if (this.isPrintMedia) {
      this.options.plotOptions.line.animation = false;
    }
    if (this.measurements && this.dimensions && this.data) {
      const chartData = this.chartService.getChartData(this.data, this.measurements, this.dimensions);
      if (!chartData) {
        this.seriesData = [];
        return;
      }
      const { seriesData, xValues, originalXValues } = chartData;

      const dataConfig = this.highchartConfigService.getConfigForDataType(this.measurements[0].dataType);

      const dateTimeLabelFormats = { ...customDateTimeLabelFormats };

      (this.options.yAxis as Highcharts.YAxisOptions).labels = {
        formatter: dataConfig.axisLabelFormatter,
      };
      (this.options.yAxis as Highcharts.YAxisOptions).tickPositioner = dataConfig.tickPositioner;

      this.options.tooltip = {
        pointFormatter: dataConfig.tooltipPointFormatter,
      };

      if (this.dimensions[0].dataType === 'date') {
        // Set default format based on dimensionKey if xValues has 0 or 1 value
        if (this.dimensions[0].dimensionKey.indexOf('day') !== -1 ||
          this.dimensions[0].dimensionKey.indexOf('week') !== -1) {
          dateTimeLabelFormats.millisecond = dateTimeLabelFormats.day;
        } else {
          dateTimeLabelFormats.millisecond = dateTimeLabelFormats.month;
        }
        this.options.xAxis = {
          type: 'datetime',
          dateTimeLabelFormats,
          tickLength: 0,
          showFirstLabel: true,
          showLastLabel: true,
          tickInterval: xValues?.length > 1 ? (xValues[1] as number) - (xValues[0] as number) : undefined,
        };
      } else {
        this.options.xAxis = {
          type: 'category'
        };
      }

      // This forces the chart to redraw with the updated options
      this.options = { ...this.options };

      this.seriesData = [...seriesData.keys()].map(seriesKey => ({
        type: 'line',
        name: this.translate(seriesKey),
        className: seriesData.get(seriesKey)?.className,
        data: seriesData.get(seriesKey)?.data.map((value, index) => ({
          name: typeof(xValues[index]) === 'string' ? this.translate(xValues[index] as string) : xValues[index],
          y: value,
          custom: this.getCustomData(seriesData.get(seriesKey)?.originalKey, originalXValues[index]),
        })),
      }));
    }
  }
}
