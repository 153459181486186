// Component: Periodicity - Retention Config widget

// Note: Zero is a valid value in many places. Be mindful of falsy checks which
// would misinterpet this value.

;(function(angular, undefined) {
  'use strict';

  var configOptions = {
    controller: 'RetentionSelectorCtrl',
    templateUrl: 'app/global/c-retention-selector/c-retention-selector.html',
    bindings: {
      // Number of milliseconds.
      retention: '=ngModel',

      // Value used for unique name and id attributes.
      name: '@',

      // Optional Array of grains to override the default specified here.
      grains: '<?',

      // Optional CSV String of grains to omit.
      omitGrains: '@?',

      // Optional String kValue for default grain.
      defaultGrain: '@?',

      // Optional number multiplied times the initial value to get milleseconds.
      msecsMultiplier: '<?',

      // Optional value for ng-disabled.
      ngDisabled: '=?',

      // Optional translation key for disabled tooltip.
      disabledTooltip: '@?',

      // Optional minimum value. Default is 1.
      min: '@?',

      // Optional custom-ng-pattern validation regex. Default is FORMATS.positiveIntegers.
      customPattern: '<?',
    },
    require: {
      ngModel: 'ngModel'
    },
  };

  angular.module('C.retention', [])
    .controller('RetentionSelectorCtrl', cRetentionSelectorCtrlFn)
    .component('cRetentionSelector', configOptions);

  /**
   * $ngdoc Component
   * @name C.retention:cRetentionSelector
   * @scope
   *
   * @requires ngModel
   * @function
   * @description
   *   Displays the retention days (weeks, months, etc) selection widget.
   *   Consumes a model value of milliseconds.
   *
   * @example
        <c-retention-selector
          name="defaultFileRetention"
          class="elastic"
          omit-grains="kSecond,kYear"
          ng-model="$ctrl.defaultFileRetentionDurationMsecs">
        </c-retention-selector>
   */
  function cRetentionSelectorCtrlFn($scope, $attrs, FORMATS) {
    var $ctrl = this;

    // Default value is one second, in milliseconds.
    var defaultGrainQuantity = 1000;
    var defaultGrains = [
      {
        kValue: 'kForever',
        multiplier: -1,
        nameKey: 'granularity.kForever',
      },
      {
        kValue: 'kNone',
        multiplier: 0,
        nameKey: 'granularity.kNone',
      },
      {
        kValue: 'kSecond',
        multiplier: 1,
        nameKey: 'granularity.kSecond',
      },
      {
        kValue: 'kMinute',
        multiplier: 60,
        nameKey: 'granularity.kMinute',
      },
      {
        kValue: 'kHour',
        multiplier: 3600,
        nameKey: 'granularity.kHour',
      },
      {
        kValue: 'kDay',
        multiplier: 3600 * 24,
        nameKey: 'granularity.kDay',
      },
      {
        kValue: 'kWeek',
        multiplier: 3600 * 24 * 7,
        nameKey: 'granularity.kWeek',
      },
      {
        kValue: 'kMonth',
        multiplier: 3600 * 24 * 30,
        nameKey: 'granularity.kMonth',
      },
      {
        kValue: 'kYear',
        multiplier: 3600 * 24 * 365,
        nameKey: 'granularity.kYear',
      },
    ];

    angular.extend($ctrl, {
      FORMATS: FORMATS,

      // Use the provided list if available. Otherwise use the default.
      grains: $ctrl.grains || defaultGrains,

      // Default grain is "None".
      selectedGrain: undefined,

      // Public Methods
      $onInit: $onInit,
      showInput: showInput,
      updateModel: updateModel,
    });

    /**
     * Initialize this component.
     *
     * @method     onInit
     */
    function $onInit() {
      parseAttribs();

      // Update internal models on external model change.
      $ctrl.ngModel.$render = function $render() {
        $ctrl.retention = $ctrl.ngModel.$modelValue;
        bootstrapViewValues();
      };
    }

    /**
     * Gets the grain from list, based on kValue.
     *
     * @method     getGrain
     *
     * @param      {String}  kValue  The kValue
     * @return     {Object}  The grain.
     */
    function getGrain(kValue) {
      return $ctrl.grains.find(function findGrain(grain) {
        return grain.kValue === kValue;
      });
    }

    /**
     * Process the bound Model to set up the correct display values in the UI.
     * This determines the nearest granularity (grain) to display, ie. 3000 will
     * display "3 Seconds" granularity, and 63072000 will display "2 Years".
     *
     * @method   bootstrapViewValues
     */
    function bootstrapViewValues() {
      var initialValue;
      var nearestGrain;

      switch ($ctrl.retention) {
        case -1:
          $ctrl.selectedGrain = getGrain('kForever');
          break;

        case undefined:
          $ctrl.selectedGrain = getGrain('kNone');
          break;

        default:
          initialValue = _.isNumber($ctrl.retention) ?
            parseInt($ctrl.retention * $ctrl.msecsMultiplier, 10) :
            defaultGrainQuantity;
      }

      if (_.isUndefined(initialValue)) {
        return;
      }

      // Traverse the available grains to find the nearest grain for display.
      nearestGrain = $ctrl.grains.slice(0).reverse().find(
        function eachGrain(grain) {
          var ratio = initialValue / grain.multiplier;

          /*
           * If the ratio is greater than 1, then we're in the right ballpark,
           * then if the ratio is also a whole number (model value is evenly
           * divisble by the ratio).
           */
          return ratio >= 1 && ratio === parseInt(ratio, 10);
        }
      ) || $ctrl.selectedGrain;

      $ctrl.selectedGrain = nearestGrain;
      $ctrl.grainQuantity = initialValue / nearestGrain.multiplier;
    }

    /**
     * Updates the model with the latest selected grain and quanitity.
     *
     * @method   updateModel
     */
    function updateModel() {
      var newGrainQuantity;

      switch ($ctrl.selectedGrain.kValue) {
        case 'kForever':
          $ctrl.ngModel.$setViewValue(-1);
          break;

        case 'kNone':
          $ctrl.ngModel.$setViewValue(undefined);
          break;

        default:
          newGrainQuantity = $ctrl.grainQuantity >= 0 ?
            $ctrl.grainQuantity : defaultGrainQuantity;

          $ctrl.ngModel.$setViewValue(newGrainQuantity *
            $ctrl.selectedGrain.multiplier / $ctrl.msecsMultiplier);
      }
    }

    /**
     * Parse this components attributes for values. This avoids digest loop
     * bindings and forces one-time binds of attributes.
     *
     * @method     parseAttribs
     */
    function parseAttribs() {
      $ctrl.isElastic = !!$attrs.class && /elastic/i.test($attrs.class);
      $ctrl.label = $attrs.label;
      $ctrl.name = $attrs.name;
      $ctrl.disabledTooltip = $attrs.disabledTooltip;
      $ctrl.required = $attrs.hasOwnProperty('required');
      $ctrl.min = $attrs.min || 1;

      /*
       * If a multiplier is provided, use that. Otherwise assume milliseconds
       * and we want to reduce to seconds which is our base. In this way, the
       * component can handle an external ng-model of days and an
       * msecsMultiplier of "86400" will be used for accurate input and output.
       */
      $ctrl.msecsMultiplier = $attrs.msecsMultiplier || 0.001;

      // Set default grain.
      $ctrl.selectedGrain = $attrs.defaultGrain ?
        getGrain($attrs.defaultGrain) : getGrain('kMonth');

      /*
       * The idea is that all grains ("grains") are available options by
       * default. Devs can disable specific grains by providing a CSV String of
       * kValues to omit.
       */
      $ctrl.omitGrains =
        ($attrs.omitGrains && $attrs.omitGrains.split(',')) || [];

      angular.forEach($ctrl.omitGrains, function eachAttrib(kEnum) {
        omitGrain(kEnum);
      });
    }

    /**
     * Optionally omit grain from the dropdown if configured.
     *
     * @method     omitGrain
     *
     * @param      {string}  kEnum   'kEnum' to omit.
     */
    function omitGrain(kEnum) {
      $ctrl.grains = $ctrl.grains.filter(
        function eachGrain(grain) {
          return kEnum !== grain.kValue;
        }
      );
    }

    /**
     * Determines whether to show the input element.
     *
     * @method     showInput
     *
     * @param      {String}   [kValue]  The kValue from the ui-select $selected
     * @return     {Boolean}  True if kValue is not a unit of time.
     */
    function showInput(kValue) {
      return !['kForever', 'kNone', undefined].includes(kValue);
    }
  }

})(angular);
