/**
 * Function to determine whether a given cluster version is newer than the
 * provided minimum cluster version.
 *
 * @param version The cluster version.
 * @param minVersion The minimum cluster version to compare to.
 * @return True if version is newer than minVersion.
 */
export function checkClusterVersion(version: string, minVersion: string) {
  // TODO: Update this to more generically accommodate all team branches
  if (version?.includes('master') || version?.includes('_team')) {
    // If the software version is master (internal), return true.
    return true;
  }

  // Get the numerical semver version of the cluster. For example,
  // "6.6.0", "6.4.1", etc.
  // eslint-disable-next-line
  const formattedVersion = version?.split('_')[0].replace(/[^0-9\.]+/g, '');
  // eslint-disable-next-line
  const formattedMinVersion = minVersion?.split('_')[0].replace(/[^0-9\.]+/g, '');

  if (formattedVersion === formattedMinVersion) {
    // If the version is same as minimum cluster version.
    return true;
  }

  const clusterVersionParts = formattedVersion?.split('.');
  const minVersionParts = formattedMinVersion?.split('.');

  // Return whether the cluster version is newer than the minimum cluster.
  // For example, "6.6" is newer than "6.5.1".
  for (let i = 0; i < clusterVersionParts?.length; i++) {
    const versionA = Number(clusterVersionParts[i] || 0);
    const versionB = Number(minVersionParts[i] || 0);

    if (versionA < versionB) {
      return false;
    }

    if (versionA > versionB) {
      return true;
    }
  }

  return false;
}
