/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CreateProtectedObjectsResponse } from '../models/create-protected-objects-response';
import { CreateProtectedObjectsRequest } from '../models/create-protected-objects-request';
import { ProtectedObjectActionResponse } from '../models/protected-object-action-response';
import { ProtectdObjectsActionRequest } from '../models/protectd-objects-action-request';
import { GetProtectedObjectResponse } from '../models/get-protected-object-response';
import { UpdateProtectedObjectsRequest } from '../models/update-protected-objects-request';
@Injectable({
  providedIn: 'root',
})
class ProtectedObjectService extends __BaseService {
  static readonly ProtectObjectsOfAnyTypePath = '/data-protect/protected-objects';
  static readonly PerformActionOnProtectObjectsPath = '/data-protect/protected-objects/actions';
  static readonly UpdateProtectedObjectsOfAnyTypePath = '/data-protect/protected-objects/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Create Object Backup.
   *
   * Create Protect Objects Backup.
   * @param params The `ProtectedObjectService.ProtectObjectsOfAnyTypeParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to protect objects.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ProtectObjectsOfAnyTypeResponse(params: ProtectedObjectService.ProtectObjectsOfAnyTypeParams): __Observable<__StrictHttpResponse<CreateProtectedObjectsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.requestInitiatorType != null) __headers = __headers.set('requestInitiatorType', params.requestInitiatorType.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/protected-objects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateProtectedObjectsResponse>;
      })
    );
  }
  /**
   * Create Object Backup.
   *
   * Create Protect Objects Backup.
   * @param params The `ProtectedObjectService.ProtectObjectsOfAnyTypeParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to protect objects.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ProtectObjectsOfAnyType(params: ProtectedObjectService.ProtectObjectsOfAnyTypeParams): __Observable<CreateProtectedObjectsResponse> {
    return this.ProtectObjectsOfAnyTypeResponse(params).pipe(
      __map(_r => _r.body as CreateProtectedObjectsResponse)
    );
  }

  /**
   * Perform Actions on Protect Objects.
   *
   * Perform actions on Protected Objects.
   * @param params The `ProtectedObjectService.PerformActionOnProtectObjectsParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to perform an action on an already protected object.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  PerformActionOnProtectObjectsResponse(params: ProtectedObjectService.PerformActionOnProtectObjectsParams): __Observable<__StrictHttpResponse<ProtectedObjectActionResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-protect/protected-objects/actions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProtectedObjectActionResponse>;
      })
    );
  }
  /**
   * Perform Actions on Protect Objects.
   *
   * Perform actions on Protected Objects.
   * @param params The `ProtectedObjectService.PerformActionOnProtectObjectsParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to perform an action on an already protected object.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  PerformActionOnProtectObjects(params: ProtectedObjectService.PerformActionOnProtectObjectsParams): __Observable<ProtectedObjectActionResponse> {
    return this.PerformActionOnProtectObjectsResponse(params).pipe(
      __map(_r => _r.body as ProtectedObjectActionResponse)
    );
  }

  /**
   * Update Object Backup.
   *
   * Update Protected object backup configuration given a object id.
   * @param params The `ProtectedObjectService.UpdateProtectedObjectsOfAnyTypeParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Protected Object.
   *
   * - `body`: Specifies the parameters to perform an update on protected objects.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateProtectedObjectsOfAnyTypeResponse(params: ProtectedObjectService.UpdateProtectedObjectsOfAnyTypeParams): __Observable<__StrictHttpResponse<GetProtectedObjectResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.requestInitiatorType != null) __headers = __headers.set('requestInitiatorType', params.requestInitiatorType.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/data-protect/protected-objects/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetProtectedObjectResponse>;
      })
    );
  }
  /**
   * Update Object Backup.
   *
   * Update Protected object backup configuration given a object id.
   * @param params The `ProtectedObjectService.UpdateProtectedObjectsOfAnyTypeParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the Protected Object.
   *
   * - `body`: Specifies the parameters to perform an update on protected objects.
   *
   * - `requestInitiatorType`: Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateProtectedObjectsOfAnyType(params: ProtectedObjectService.UpdateProtectedObjectsOfAnyTypeParams): __Observable<GetProtectedObjectResponse> {
    return this.UpdateProtectedObjectsOfAnyTypeResponse(params).pipe(
      __map(_r => _r.body as GetProtectedObjectResponse)
    );
  }
}

module ProtectedObjectService {

  /**
   * Parameters for ProtectObjectsOfAnyType
   */
  export interface ProtectObjectsOfAnyTypeParams {

    /**
     * Specifies the parameters to protect objects.
     */
    body: CreateProtectedObjectsRequest;

    /**
     * Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
     */
    requestInitiatorType?: 'UIUser' | 'UIAuto' | 'Helios';

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for PerformActionOnProtectObjects
   */
  export interface PerformActionOnProtectObjectsParams {

    /**
     * Specifies the parameters to perform an action on an already protected object.
     */
    body: ProtectdObjectsActionRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateProtectedObjectsOfAnyType
   */
  export interface UpdateProtectedObjectsOfAnyTypeParams {

    /**
     * Specifies the id of the Protected Object.
     */
    id: number;

    /**
     * Specifies the parameters to perform an update on protected objects.
     */
    body: UpdateProtectedObjectsRequest;

    /**
     * Specifies the type of request from UI, which is used for services like magneto to determine the priority of requests.
     */
    requestInitiatorType?: 'UIUser' | 'UIAuto' | 'Helios';

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { ProtectedObjectService }
