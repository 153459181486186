import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { TranslateModule } from '@ngx-translate/core';

import { PulseComponent } from './pulse.component';


/**
 *  The module for Cohesity Pulse bar
 */
@NgModule({
  declarations: [PulseComponent],
  exports: [PulseComponent],
  imports: [
    CommonModule,
    MatProgressBarModule,
    TranslateModule.forChild(),
  ],
})
export class PulseModule { }
