import { Subnet } from '@cohesity/api/v1';
import {
  CreateView,
  NfsSquash,
  QoS,
  S3LifecycleManagement,
  Share,
  SmbPermission,
  Template,
  UserQuota,
  View,
  ViewDirectoryQuota,
  ViewProtocol,
} from '@cohesity/api/v2';

/**
 * Share model definitions.
 */
export interface SharesInfo extends Share {
  /**
   * Indicates whether file auditing is enabled on this share.
   */
  auditingEnabled?: boolean;

  /**
   * Array of mount paths object such as Nfs mount path, smb mount path etc.
   */
  _mountPaths?: null | object[];

  /**
   * Determines whether the user belongs to view share owner's organization or not.
   */
  _isShareOwner?: boolean;

  /**
   * Alias Name of the share.
   */
  aliasName?: string;

  /**
   * View path of the share.
   */
  viewPath: string;

  /**
   * Indicates whether this is the default share.
   */
  isDefaultShare?: boolean;

  /**
   * Specifies the main path for mounting this Share as an SMB share.
   */
  smbMountPath?: string;

  /**
   * Specifies the path for mounting this Share as an NFS share.
   */
  nfsMountPath?: string;
}

/**
 * All Views result definition.
 */
export interface ViewsResult {
  /**
   * Array of Views.
   *
   * Specifies the list of Views returned in this response.
   * The list is sorted by decreasing View ids.
   */
  views?: null | ViewInfo[];

  /**
   * If false, more Views are available to return. If the number of
   * Views to return exceeds the number of Views specified in maxCount
   * (default of 1000) of the original GET /public/views request,
   * the first set of Views are returned and this field returns false.
   * To get the next set of Views, in the next GET /public/views request
   * send the last id from the previous viewList.
   */
  lastResult?: null | boolean;

  /**
   * Number of views returned. This will only be returned if ViewCountOnly is
   * set in arguments.
   * hidden: true
   */
  count?: null | number;
}

/**
 * View information definition.
 */
export interface ViewInfo extends View {
  /**
   * True if user quotas is not available in any S3 view or any other view
   * which is replicated from the cluster.
   */
  _allowUserQuotas?: null | boolean;

  /**
   * Determines whether datalock is expired or not.
   */
  _dataLockExpired?: null | boolean;

  /**
   * Determines whether there is an active File DataLock by looking for
   * non-zero milliseconds remaining.
   */
  _hasFileDataLock?: null | boolean;

  /**
   * Determines whether there is S3 Object Lock
   */
  _hasS3ObjectLock?: null | boolean;

  /**
   * Determines whether there is an active View DataLock by comparing the
   * expiry datetime with now.
   */
  _hasViewDataLock?: null | boolean;

  /**
   * Determines whether view is archived or not.
   */
  _isArchived?: null | boolean;

  /**
   * Determines whether the view is deletable or not.
   */
  _isDeletable?: null | boolean;

  /**
   * Determines whether the view is editable or not.
   */
  _isEditable?: null | boolean;

  /**
   * Determines whether view is inactive or not.
   */
  _isInactive?: null | boolean;

  /**
   * Determines whether the view is protected or not.
   */
  _isProtected?: null | boolean;

  /**
   * Determines whether view is replicated view or not.
   */
  _isReplicated?: null | boolean;

  /**
   * True if it is protected by at least one Remote Adapter job.
   */
  _isRemoteAdapterView?: null | boolean;

  /**
   * Determines whether view is a Tracking View or not.
   */
  _isTrackingView?: boolean;

  /**
   * True if user's belongs to view owner organization else false.
   */
  _isViewOwner?: null | boolean;

  /**
   * Logical usage bytes information.
   */
  _logicalUsageBytes?: number;

  /**
   * Storage consumption bytes information in bytes.
   */
  _storageConsumedBytes?: number;

  /**
   * Provides storage reduction ratio.
   */
  _storageReduction?: string;

  /**
   * View share list.
   */
  _sharesList?: Share[];

  /**
   * Determines whether view ever had a Datalock or not.
   */
  _hadViewDataLock?: boolean;

  /**
   * Determines whether view protection run is paused or not.
   */
  _isPaused?: boolean;

  /**
   * Determines whether to apply strictest security settings by default.
   */
  mostSecureSettings?: boolean;

  /**
   * Shim to match Share type.
   */
  allSmbMountPaths?: string[];
}

/**
 * View Settings list item definiton.
 */
export interface ViewSettingsListItem {
  /**
   * label defintion.
   */
  label: string;

  /**
   * Value definition.
   */
  value: boolean | string;
}

/**
 * View build form model definition.
 */
export interface ViewBuildFormModel {
  /**
   * Specifies the supported Protocols for the View.
   */
  protocolAccess: null | ViewProtocol[];

  /**
   * Specifies the protection config setting for the View.
   */
  viewProtectionConfig: null | any;

  /**
   * Specifies whether to support case insensitive file/folder names. This
   * parameter can only be set during create and cannot be changed.
   */
  caseInsensitiveNamesEnabled?: null | boolean;

  /**
   * Specifies the name of the Storage Domain (View Box) where the View is
   * stored.
   */
  storageDomainName?: null | string;

  /**
   * Specifies the Quality of Service (QoS) Policy for the View.
   */
  qos: QoS;

  /**
   * Specifies the category of the View.
   */
  category: null | 'BackupTarget' | 'FileServices' | 'ObjectServices';

  /**
   * Specifies the id of the Storage Domain (View Box) where the View is stored.
   */
  storageDomainId: null | number;

  /**
   * Specifies the name of the View.
   */
  name: null | string;

  /**
   * View id.
   */
  viewId: null | number;

  /**
   * Specifies whether to support s3 folder behaviour. This
   * parameter can only be set during create and cannot be changed.
   */
  s3FolderSupportEnabled?: null | boolean;

  /**
   * Specifies the versioning state of S3 bucket.
   */
  versioning?: null | s3VersioningType;

  /**
   * Enable app aware prefetching or not.
   * TODO (Lucy): Remove this after the API YAML changes have been committed.
   */
  enableAppAwarePrefetching?: null | boolean;

  /**
   * TODO (David): Remove this after the API YAML changes have been committed.
   */
  enableSmbLeases?: null | boolean;

  /**
   * TODO (David): Remove this after the API is committed.
   */
  viewPinningConfig?: {
    enabled?: boolean;
    pinnedTimeSecs?: null | number;
    lastUpdatedTimestampSecs?: null | number;
  };
}

/**
 * View category type definition.
 */
export type ViewCategory = CreateView['category'];

/**
 * Model for all view categories.
 */
export enum AllViewCategories {
  BackupTarget = 'backupTarget',
  FileServices = 'fileShares',
  ObjectServices = 'objectServices',
}

/**
 * All View Categories.
 */
export const ViewCategories: ViewCategory[] = [
  'FileServices',
  'BackupTarget',
  'ObjectServices',
];

/**
 * Default Qos policy mapper based on view category.
 */
export const DefaultQosPolicyNameByCategory = {
  BackupTarget: 'BackupTargetHigh',
  FileServices: 'TestAndDevHigh',
  ObjectServices: 'TestAndDevHigh',
};


/**
 * Default protocol access value based on view category.
 */
export const DefaultProtocolListByCategory: Record<string, ViewProtocol[]> = {
  BackupTarget: [
    {
      type: 'NFS',
      mode: 'ReadWrite',
    }
  ],
  FileServices: [
    {
      type: 'NFS',
      mode: 'ReadWrite',
    },
    {
      type: 'SMB',
      mode: 'ReadWrite',
    },
    {
      type: 'S3',
      mode: 'ReadOnly',
    },
  ],
  ObjectServices: [
    {
      type: 'S3',
      mode: 'ReadWrite',
    }
  ],
};

/**
 * Default protocol access value based on view category.
 * It will be used when viewSettingsSecureByDefault feature flag is on.
 */
export const DefaultProtocolListByCategoryWithNFS4: Record<string, ViewProtocol[]> = {
  BackupTarget: [
    {
      type: 'NFS',
      mode: 'ReadWrite',
    }
  ],
  FileServices: [
    {
      type: 'NFS4',
      mode: 'ReadWrite',
    },
    {
      type: 'SMB',
      mode: 'ReadWrite',
    },
    {
      type: 'S3',
      mode: 'ReadOnly',
    },
  ],
  ObjectServices: [
    {
      type: 'S3',
      mode: 'ReadWrite',
    }
  ],
};

/**
 * The types of QoS policy.
 *
 * TODO: Use the API's response instead.
 */
export const QOS_POLICY_TYPES = [
  'BackupTargetHigh',
  'BackupTargetLow',
  'TestAndDevHigh',
  'TestAndDevLow',
  'BackupTargetSSD',
  'BackupTargetCommvault',
  'JournaledSequentialDump',
  'BackupTargetAuto',
];

// QoS policy used for Snapdiff S3 Views
export const snapdiffS3ViewQos: QoS = {
  name: 'BackupTargetHigh',
};

/**
 * Qos Polices to exclude from PXG cluster
 */
export const EXCLUDED_PXG_QOS_POLICIES = ['BackupTargetHigh', 'BackupTargetLow', 'BackupTargetAuto'];

/**
 * Default Acl permissions.
 */
export const DEFAULT_ACL_PERMISSIONS: SmbPermission[] = [{
  // 'Everyone'
  sid: 'S-1-1-0',
  access: 'FullControl',
  mode: 'FolderSubFoldersAndFiles',
  type: 'Allow',
}];

/**
 * Default View settings.
 */
export const DEFAULT_VIEW_CONFIG = {
  antivirusScanConfig: {
    isEnabled: false,
    blockAccessOnScanFailure: false,
    maximumScanFileSize: 26214400,
    scanFilter: {
      isEnabled: false,
      mode: null,
      fileExtensionsList: [],
    },
    scanOnAccess: false,
    scanOnClose: true,
    scanTimeoutUsecs: 180000000, // 180 seconds
  },
  caseInsensitiveNamesEnabled: true,
  category: 'FileServices',
  enableNfsKerberosAuthentication: true,
  enableNfsKerberosIntegrity: true,
  enableNfsKerberosPrivacy: true,
  enableNfsUnixAuthentication: true,
  enableNfsViewDiscovery: false,
  enableNfsWcc: false,
  enableSmbLeases: true,
  enableSmbOplock: true,
  enableSmbViewDiscovery: true,
  fileExtensionFilter: {
    isEnabled: false,
    mode: 'Blacklist',
    fileExtensionsList: [],
  },
  fileLockConfig: {
    autoLockAfterDurationIdleMsecs: null,
    defaultRetentionDurationMsecs: null,
    defaultRetentionDurationYears: null,
    expiryTimestampMsecs: null,
    lockingProtocol: null,
    minRetentionDurationMsecs: null,
    maxRetentionDurationMsecs: null,
    mode: null,
    coexistingLockMode: null,
  },
  logicalQuota: {
    alertLimitBytes: null,
    hardLimitBytes: null,
    alertThresholdPercentage: null
  },
  netgroupWhitelist: { nisNetgroups: null },
  nfsAllSquash: {gid: 65534, uid: 65534},
  nfsRootSquash: {gid: 65534, uid: 65534},
  overrideGlobalNetgroupWhitelist:  true,
  overrideGlobalSubnetWhitelist:  true,
  protocolAccess: DefaultProtocolListByCategory['FileServices'],
  qos: {
    name: 'TestAndDevHigh',
  },
  versioning: 'UnVersioned',
  s3FolderSupportEnabled: false,
  securityMode: 'NativeMode',
  selfServiceSnapshotConfig: {
    // NFS Properties
    enabled: false,
    nfsAccessEnabled: true,
    snapshotDirectoryName: '.snapshot',

    // SMB Properties
    smbAccessEnabled: true,
    alternateSnapshotDirectoryName: '~snapshot',
    previousVersionsEnabled: true,
    allowAccessSids: ['S-1-1-0'],
    denyAccessSids: [],
  },
  sharePermissions: {
    permissions: [...DEFAULT_ACL_PERMISSIONS],
    superUserSids: null,
  },
  smbPermissionsInfo: {
    // 'Administrators Group'
    ownerSid: 'S-1-5-32-544',
    permissions: [...DEFAULT_ACL_PERMISSIONS],
  },
  subnetWhitelist: [],
  viewPinningConfig: {
    enabled: false,
    pinnedTimeSecs: -1,
    lastUpdatedTimestampSecs: null,
  },
  lifecycleManagement: {
    rules: [],
    versionId: 1,
  },
};

/**
 * SMB Permissions for file access. Used in Create/Edit View flows.
 */
export const SMB_ACL_PERMISSIONS = {
  types: {
    Allow: 'smbPermissions.types.allow',
    Deny: 'smbPermissions.types.deny',
    SpecialType: 'smbPermissions.types.specialType',
  },
  modes: {
    FolderSubFoldersAndFiles: 'smbPermissions.modes.folderSubFoldersAndFiles',
    FolderAndSubFolders: 'smbPermissions.modes.folderAndSubFolders',
    FolderAndFiles: 'smbPermissions.modes.folderAndFiles',
    FolderOnly: 'smbPermissions.modes.folderOnly',
    SubFoldersAndFilesOnly: 'smbPermissions.modes.subFoldersAndFilesOnly',
    SubFoldersOnly: 'smbPermissions.modes.subFoldersOnly',
    FilesOnly: 'smbPermissions.modes.filesOnly',
  },
  access: {
    FullControl: 'smbPermissions.access.fullControl',
    ReadWrite: 'smbPermissions.access.readWrite',
    Modify: 'smbPermissions.access.modify',
    ReadOnly: 'smbPermissions.access.readOnly',
    SpecialAccess: 'smbPermissions.access.specialAccess',
    SuperUser: 'smbPermissions.access.superUser',
  },
  accessOptions: {
    views: {
      FullControl: 'smbPermissions.access.fullControl',
      ReadWrite: 'smbPermissions.access.readWrite',
      Modify: 'smbPermissions.access.modify',
      ReadOnly: 'smbPermissions.access.readOnly',
    },
    shares: {
      FullControl: 'smbPermissions.access.fullControl',
      Modify: 'smbPermissions.access.modify',
      ReadOnly: 'smbPermissions.access.readOnly',
    },
  },
};

/**
 * Modified subnet response to accomodate owner info.
 */
export interface SubnetModel extends Subnet {
  /**
   * Specifies if the user is allowed to modify the subnet or not.
   */
  _modifyDisabled?: boolean;
}

/**
 * Modified directory quota policy object.
 */
export interface DirQuotaPolicyInfo extends ViewDirectoryQuota {
  _folderName: string;
  _quotaOverride: number;
  _alertThreshold: number;
  _usageBytes?: number;
  _quotaUsagePercentage?: number;
}

/**
 * Modified user quota object.
 */
export interface UserQuotaInfo extends UserQuota {
  _quotaOverride: number;
  _alertThreshold: number;
}

/**
 * View protocol types.
 */
export type ViewProtocols = 'NFS' | 'NFS4' | 'SMB' | 'S3' | 'Swift';

/**
 * View protocol mapping.
 */
export const ProtocolStringKeys = {
  NFS: 'nfsV3',
  NFS4: 'nfsV4',
  SMB: 'smb',
  S3: 's3',
  Swift: 'swift',
};

/**
 * View category label mapping.
 */
export const ViewCategoryMapping = {
  FileServices: 'fileShares',
  BackupTarget: 'backupTarget',
  ObjectServices: 'objectServices',
};

/**
 * The file lock config modes available.
 */
export enum FileLockConfigMode {
  compliance = 'Compliance',
  enterprise = 'Enterprise',
}

/**
 * View summary protection config
 */
export interface ViewsSummaryProtectionData {
  takingSnapshots: number;
  replicatingIn: number;
  replicatingOut: number;
}

/**
 * View summary consumption config.
 */
export interface ViewsSummaryConsumptionData {
  dataReduction: null | number;
  lastUpdated: string;
  logical: number;
  physical: number;
  resiliencyImpact: number;
  storageConsumed: number;
  storageReduction: null | number;
  numDirectories: null | number;
  numFiles: null | number;
}

/**
 * View summary consolidated data.
 */
export interface ViewsSummaryData {
  consumption: ViewsSummaryConsumptionData;
  protection: ViewsSummaryProtectionData;
  totalViews: number;
  viewEntityId: string;
}

export interface ViewsConsumptionGrowthSummaryData {
  logicalChangeBytes: number;
  logicalChangePct: number;
  physicalChangeBytes: number;
  physicalChangePct: number;
  storageConsumedChangeBytes: number;
  storageConsumedChangePct: number;
}

export type SnapShotsTargetType = 'All' | 'Cloud' | 'Local' | 'Tape';

export type s3VersioningType = 'UnVersioned' | 'Enabled' | 'Suspended';

/**
 * s3 lifecycle management setting form data model.
 */
export interface S3LifecycleManagementFormModel extends S3LifecycleManagement {
  isEnabled: boolean;
}

/**
 * Temporary model for state params for ViewBuilderComponent. Would update later.
 */
export interface ViewBuilderStateParams {
  actionType: string;
  viewId?: string;
  templateInfo?: Template;
  restrictViewBoxId?: number;
  togglePageView?: boolean;
  viewObj: CreateView;
  backToPreviousState?: boolean;
}
/**
 * Internal model to hold the squash config.
 */export interface SquashConfig {
  nfsAllSquash: NfsSquash;
  nfsRootSquash: NfsSquash;
}

/**
 * S3 acl grantee permission.
 */
 export type AclPermission = 'Read' | 'Write' | 'ReadACP' | 'WriteACP' | 'FullControl';
