import { Injectable } from '@angular/core';
import { IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { Transition } from '@uirouter/core';
import { filter, map } from 'rxjs/operators';

import { AppStateService, DmsService } from '../../services';
import { GuardPriority, GuardResult, StateGuard } from '../state-guard';

/**
 * The responsibility of this state guard is to make sure that the user
 * can't access any other state in DataProtect when tenant is being migrated.
 */
@Injectable({
  providedIn: 'root'
})
export class DmsMigrationGuard implements StateGuard {

  /**
   * The guard priority for the State.
   */
  guardPriority = GuardPriority.ImpersonationOrSwitch;

  /**
   * constructor
   */
  constructor(
    private irisCtx: IrisContextService,
    private dmsService: DmsService,
    private appStateService: AppStateService,
  ) {}

  /**
   * OnStart of UI Router State Transition
   */
  onStart(transition: Transition): GuardResult {

    // This guard is only applicable for DataProtect Scope.
    if (!isDmsScope(this.irisCtx.irisContext)) {
      return true;
    }

    // This guard is active only when the feature is available.
    if (!this.irisCtx.irisContext.featureFlags?.dmsTenantMigration) {
      return true;
    }

    this.dmsService.getTenantMigrationStatus();

    if (this.appStateService.selectedScope?._serviceType !== 'dms') {
      return true;
    }

    if (transition.to().name === 'dms.tenant-migration') {
      return true;
    }

    return this.dmsService.isTenantMigrating$.pipe(
      filter(status => !!status),
      map(status => {
        if (status.inProgress) {
          return transition.router.stateService.target('dms.tenant-migration');
        }
        return true;
      }),
    );
  }
}
