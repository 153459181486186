import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { shareReplay } from 'rxjs/operators';
import { AutoDestroyable } from '@cohesity/utils';
import { AagDataMap, SqlUtilityService } from 'src/app/shared/sql/services/sql-utility.service';

import { SqlSourceDataNode } from '../sql-source-data-node';


/**
 * The possible result values of this modal.
 */
export type AagChallengeChoices = 'all' | 'one' | null;

/**
 * The default result value.
 */
export const defaultAagSelectionValue: AagChallengeChoices = 'all';

/**
 * Fields required to render Dialog for AAG Selection Challenge Modal
 */
export interface DialogData {
  node: SqlSourceDataNode;
}

@Component({
  templateUrl: './sql-aag-selection-challenge-modal.component.html',
  styleUrls: ['./sql-aag-selection-challenge-modal.component.scss']
})
export class SqlAagSelectionChallengeModalComponent extends AutoDestroyable {
  /**
   * Determines if the AAG details panel is open or not.
   */
  panelOpen = false;

  /**
   * Map of AAG data keyed by AAG name string.
   */
  aagData: AagDataMap;

  /**
   * The User choice form.
   */
  aagSelectionForm = this.fb.group({
    selectionOption: [defaultAagSelectionValue, Validators.required],
  });

  /**
   * Gets the node.
   */
  get node(): SqlSourceDataNode {
    return this.data && this.data.node;
  }

  /**
   * Gets AAG host ids.
   */
  get aagHostIds(): number[] {
    return this.sqlUtilService.getHostsFromAagData(this.aagData).map(host => Number(host.id));
  }

  constructor(
    private dialogRef: MatDialogRef<SqlAagSelectionChallengeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: UntypedFormBuilder,
    private sqlUtilService: SqlUtilityService) {
    super();
    sqlUtilService.fetchAagData(this.node.id);
    sqlUtilService.getAagData(this.node.id)
      .pipe(this.untilDestroy(), shareReplay(1))
      .subscribe((aagData: AagDataMap) => this.aagData = aagData);
  }

  /**
   * Saves the User's choice.
   */
  saveChoice() {
    let result: number[] = [];

    switch (this.aagSelectionForm.get('selectionOption').value) {
      case 'all':
        result = this.aagHostIds;
        break;

      case 'one':
        result = [this.node.id];
        break;
    }

    this.dialogRef.close(result);
  }

  /**
   * Cancels sql aag selection challenge modal.
   */
  cancel() {
    this.dialogRef.close([]);
  }
}
