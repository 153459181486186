<mat-card-header class="card-header">
  <div mat-card-avatar *ngIf="headerIcon || icon">
    <cog-icon *ngIf="icon" [shape]="icon" size="lg"></cog-icon>
    <ng-container *ngIf="headerIcon">
      <ng-content select="cog-page-content-header-icon"></ng-content>
    </ng-container>
  </div>
  <div class="title">
    <h1 *ngIf="title" class="header-title" [class.has-controls]="headerControls">
      <span *ngIf="htmlTitle" [innerHtml]="title"></span>
      <ng-container *ngIf="!htmlTitle">{{ title }}</ng-container>
      <span *ngIf="headerTag" class="header-tag">
        <ng-content select="cog-page-content-header-tag"></ng-content>
      </span>
    </h1>
    <div class="mat-card-subtitle card-subtitle" *ngIf="headerSubtitle || subtitle">
      <ng-container *ngIf="subtitle">{{ subtitle }}</ng-container>
      <ng-container *ngIf="headerSubtitle">
        <ng-content select="cog-page-content-header-subtitle"></ng-content>
      </ng-container>
    </div>
  </div>
  <div class="header-controls-container" *ngIf="headerControls">
    <ng-content select="cog-page-content-header-controls"></ng-content>
  </div>
</mat-card-header>
<div class="page-header-navigation" *ngIf="headerNavigation">
  <ng-content select="cog-page-content-header-navigation"></ng-content>
</div>
