import { Injectable } from '@angular/core';
import { FilesApiService, GetVolumeInfoParams } from '@cohesity/api/private';
import { ObjectSnapshot } from '@cohesity/api/v2';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PassthroughOptionsService, V1V2ApiUtilsService } from 'src/app/core/services';
import {
  AjaxHandlerService,
  DocumentsResponse,
  DocumentStat,
  Environment,
  ENV_GROUPS,
  FileStatMap,
  FileTypeMap,
  FolderBrowserCookies,
  FolderBrowserProvider,
} from 'src/app/shared';

import { FileBrowserModalData } from './file-browser-modal.component';

/**
 * Picks only the necessary values needed for this api from the snapshot object.
 */
export type SnapshotParams = Pick<
  ObjectSnapshot,
  'protectionGroupId' | 'runInstanceId' | 'snapshotTimestampUsecs' | 'sourceGroupId' | 'runStartTimeUsecs' | 'id'
>;

interface BaseParams {
  objectId: number;
  snapshot: SnapshotParams;
  searchIndexed?: boolean;
  pitUsecs?: number;
}

/**
 * Provides the API calls needed for file browsing. These are based on the private api, and will return items adapted
 * to work with the file browser modal.
 */
@Injectable({ providedIn: 'root' })
export class FileListService implements FolderBrowserProvider {
  /**
   * Form control browsing on indexed data or not.
   */
  browseOnIndexedData = true;

  /**
   * The currently selected snapshot.
   */
  private currentSnapshot: ObjectSnapshot;

  /**
   * The point-in-time between the snapshots. If this is set, this value will be used in the underlying APIs to get
   * the details for a PIT, instead of the snapshot run start time.
   */
  private pointInTimeUsecs: number;

  /**
   * The data required to make the API call.
   */
  data: FileBrowserModalData;

  /**
   * Use Job type instead of source type for physical and hyperv sources to check whether
   * indexed entities are exposed as server.
   */
  get environmentForIndexing(): string {
    if (this.data.object.environment === Environment.kPhysical) {
      const { physicalParams: physicalSnapshotParams } = this.currentSnapshot;

      return physicalSnapshotParams && physicalSnapshotParams.protectionType === 'kFile'
        ? Environment.kPhysicalFiles
        : Environment.kPhysical;
    } else if (this.data.object.environment === Environment.kHyperV) {
      const { hypervParams } = this.currentSnapshot;
      return hypervParams && hypervParams.protectionType === 'kVSS' ? Environment.kHyperVVSS : Environment.kHyperV;
    }

    return this.data.object.environment;
  }

  /**
   * By default, the modal will attempt to browse on volumes. If the volume list throws an error though,
   * it will switch to browsing on directories, starting from the root.
   */
  get usesVolumes(): boolean {
    return ENV_GROUPS.indexableEntitiesExposedAsServers.concat(['kHyperV', 2]).includes(this.environmentForIndexing);
  }

  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private apiUtils: V1V2ApiUtilsService,
    private filesApi: FilesApiService,
    private passthroughOptionsService: PassthroughOptionsService
  ) {}

  /**
   * Sets the data.
   */
  initData(data: FileBrowserModalData) {
    this.data = data;
  }

  /**
   * Sets the current snapshot
   */
  setCurrentSnapShot(snapshot: ObjectSnapshot) {
    this.currentSnapshot = snapshot;
  }

  /**
   * Returns the current snapshot.
   */
  getCurrentSnapshot(): ObjectSnapshot {
    return this.currentSnapshot;
  }

  /**
   * Sets the current point in time value.
   */
  setPointInTime(pitUsecs: number) {
    this.pointInTimeUsecs = pitUsecs;
  }

  /**
   * Returns the current point in time value.
   */
  getPointInTime(): number {
    return this.pointInTimeUsecs;
  }

  /**
   * Look up stats for a file, to check if it exists and what kind of file it is.
   *
   * @param fullPath  The full path to check
   * @param splitPath Alternate entry with volume and path split as an array. The file browser requires this, other
   *                  browsing implementations may not need it.
   * @param cookies   Optional cookies object containing the volume cookie.
   * @returns  An observable with the DocumentStat response.
   */
  statFile(fullPath: string, splitPath: string[], cookies?: FolderBrowserCookies): Observable<DocumentStat> {
    const [volume, path] = splitPath;
    return this.filesApi
      .getFileStat({
        ...this.getBaseParams({
          objectId: this.data.object.id,
          snapshot: this.currentSnapshot,
          searchIndexed: this.browseOnIndexedData,
          ...(this.pointInTimeUsecs ? { pitUsecs: this.pointInTimeUsecs } : {}),
        }),
        filePath: path,
        volumeInfoCookie: this.usesVolumes && cookies.volumeCookie,
        volumeName: this.usesVolumes && cookies.volumeCookie && volume,
      }, this.passthroughOptionsService.requestHeaders)
      .pipe(
        map(({ fstatInfo }) => ({
          type: FileStatMap[fstatInfo.type],
          size: fstatInfo.size,
          lastModifiedUsecs: fstatInfo.mtimeUsecs,
        }))
      );
  }

  /**
   * Lists files for the specified volume or path, or attempts to load more. This will return
   * a cached value if it exists.
   *
   * @param   fullPath  The full path, including the volume.
   * @param   fetchMore Whether to load additional entries for a directory. This will throw an error
   *                    if there's no cookie set.
   * @param   splitPath The path split into volume and path.
   * @param   cookies   The cookies needed for making API calls.
   * @param   maxEntries The filter to use for the search to return total records in a single api call.
   *
   * @returns An observable of the list of files.
   */
  listFiles(
    fullPath: string,
    fetchMore: boolean = false,
    splitPath: string[],
    cookies?: FolderBrowserCookies,
    maxEntries?: number
  ): Observable<DocumentsResponse> {
    // Check for the cookie if we are loading more entries.
    const cookie = (fetchMore && cookies.directoryCookie) || null;
    const [volume, path] = splitPath;

    return this.filesApi
      .getDirectoryList({
        ...this.getBaseParams({
          objectId: this.data.object.id,
          snapshot: this.currentSnapshot,
          searchIndexed: this.browseOnIndexedData,
          ...(this.pointInTimeUsecs ? { pitUsecs: this.pointInTimeUsecs } : {}),
        }),
        dirPath: path,
        volumeInfoCookie: this.usesVolumes && cookies.volumeCookie,
        volumeName: this.usesVolumes && cookies.volumeCookie && volume,
        cookie: cookie,
        protectionSourceEnvironment: this.data.protectionSourceEnvironment,
        maxEntries: maxEntries,
      }, this.passthroughOptionsService.requestHeaders)
      .pipe(
        map(res => ({
          cookie: res.cookie,
          documents: res.entries.map(file => ({
            name: file.name,
            fullPath: file.fullPath,
            volume: cookies.volumeCookie && (volume || '/'),
            type: FileTypeMap[file.type],
            size: file.fstatInfo && file.fstatInfo.size,
            lastModifiedUsecs: file.fstatInfo && file.fstatInfo?.mtimeUsecs,
            inodeId: file.fstatInfo && file.fstatInfo?.backupSourceInodeId,
            dirEntry: file,
          })),
        })),
        catchError(err => {
          this.ajaxHandlerService.handler(err);
          return of(null);
        }),
      );
  }

  /**
   * Lists all of the available volumes, caching the result for future calls.
   *
   * @returns   An observable of the volumes.
   */
  listVolumes(): Observable<DocumentsResponse> {
    return this.filesApi
      .getVolumeInfo(
        this.getBaseParams({
          objectId: this.data.object.id,
          snapshot: this.currentSnapshot,
          ...(this.pointInTimeUsecs ? { pitUsecs: this.pointInTimeUsecs } : {}),
        }),
        this.passthroughOptionsService.requestHeaders
      )
      .pipe(
        map(res => ({
          cookie: res.volumeInfoCookie,
          documents: (res.volumeInfos || []).map(volume => ({
            name: volume.name,
            fullPath: `${volume.name}`,
            type: 'Volume',
            volume: volume.name,
          })),
        }))
      );
  }

  /**
   * Returns based params that are common to the volume and file apis
   *
   * @param   objectId      The id of the host containing the file.
   * @param   snapshot      The snapshot information needed to look up the object.
   * @param   searchIndexed  Whether to search on indexed data using librarian or not.
   * @param   pitUsecs       The point-in-time to fetch the data according to a timestamp between
   * @returns Common params object
   */
  private getBaseParams({ objectId, snapshot, searchIndexed = false, pitUsecs }: BaseParams): GetVolumeInfoParams {
    let clusterId;
    let clusterIncarnationId;
    let jobId;
    let sourceJobId;
    if (snapshot.protectionGroupId) {
      const jobUid = this.apiUtils.splitV2ProtectionGroupId(snapshot.protectionGroupId);
      const sourceUid = snapshot.sourceGroupId
        ? this.apiUtils.splitV2ProtectionGroupId(snapshot.sourceGroupId)
        : jobUid;
      clusterId = sourceUid.clusterId;
      clusterIncarnationId = sourceUid.clusterIncarnationId;
      jobId = jobUid.id;
      sourceJobId = sourceUid.id;
    } else {
      // If the protection group id is not set, this is object protection. The only way to get the necessary
      // information is going to be to parse the snapshot id, which is pretty ugly.
      const parsedInfo = JSON.parse(atob(snapshot.id));
      clusterId = parsedInfo.a_clusterId;
      clusterIncarnationId = parsedInfo.b_clusterIncarnationId;
      jobId = -1;
      sourceJobId = -1;
    }

    return {
      attemptNum: 1,
      clusterId: clusterId,
      clusterIncarnationId: clusterIncarnationId,
      entityId: objectId,
      jobId,
      jobInstanceId: snapshot.runInstanceId,
      jobUidObjectId: sourceJobId,
      useLibrarian: searchIndexed,
      statFileEntries: true,
      ...(pitUsecs
        ? { pointInTimeUsecs: pitUsecs }
        : { jobStartTimeUsecs: snapshot.runStartTimeUsecs || snapshot.snapshotTimestampUsecs }),
    };
  }
}
