<form [formGroup]="formGroup">
  <mat-accordion #myaccordion="matAccordion" multi="true">
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
      hideToggle="true"
      expanded="true">
      <mat-expansion-panel-header>
        <mat-panel-title class="flex-row-align-items-center">
          <span>
            {{ category | translate }}
          </span>
          <cog-icon shape="keyboard_arrow_{{panelOpenState ? 'up' : 'down'}}"></cog-icon>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <span>{{enabledSecurityCriteria}}/{{formGroupControls?.criteriaList?.length}} {{'selected' | translate}}</span>
      <div
        [formArrayName]="formControlNames.criteriaList"
        *ngFor="let control of formGroupControls.criteriaList.controls;">
        <dg-pa-security-criteria-select-component [formControl]="control"></dg-pa-security-criteria-select-component>
        <mat-divider></mat-divider>
      </div>
    </mat-expansion-panel>
    <mat-divider></mat-divider>
  </mat-accordion>
</form>
