// Component: Upload license modal component to give user option to upload
// license file or key
;(function(angular) {
  'use strict';

  angular
    .module('C.clusterClaim')
    .controller('UploadLicenseModalCtrl',
    UploadLicenseModalCtrlFn)
    .component('uploadLicenseModal', {
      templateUrl: 'app/cluster-connect/upload-license-modal.html',
      controller: 'UploadLicenseModalCtrl',
      require: {
        cModalHoc: '^^?cModalHoc',
      },
    });

    /**
     * This is the controller function for upload license modal
     *
     * @method   UploadLicenseModalCtrlFn
     */
    function UploadLicenseModalCtrlFn(_, LicenseServerService, ClusterService,
      API, $state, cMessage, evalAJAX, $translate) {
      var $ctrl = this;

      _.assign($ctrl, {
        //properties
        isInvalidLicense: false,
        processedLicenseKey: '',

        //methods
        $onInit: $onInit,
        cancelModal: cancelModal,
        toggleUploadErrorFlag: toggleUploadErrorFlag,
        onContinueClick: onContinueClick
      });

    /**
     * Activate the controller and set config for upload component
     *
     * @method   $onInit
     */
      function $onInit() {
        $ctrl.uploadConfig = {
          confirmBtnKey: 'continue',
          cancelBtnKey: 'cancel',
          confirmCallback: licenseUploadedSuccessfully,

          cancelCb: function cancelCb() {
            $ctrl.cancelModal();
          },
          url: API.private('nexus/license/upload'),
          icon: "icn-license-file",
        }
      }

      /**
       * Cancels this modal
       *
       * @method     cancelModal
       * @return     {Object}  promise object with the modal dismissal result
       */
      function cancelModal() {
        return $ctrl.cModalHoc.close();
      }

      /**
       * When continue button is pressed to upload license key
       * if invalid license key, show invalid key message
       *
       * @method     onContinueClick
       */
      function onContinueClick() {
        var formatedMessage;

        LicenseServerService.uploadKey($ctrl.licenseKey)
          .then(licenseUploadedSuccessfully,
            function toggleInvalidKey(response) {
              formatedMessage = {
                message: $translate.instant('clusterConnect.uploadError.' +
                  response.data.errorCode),
              };

              // Updating the response object with new message key
              angular.merge(response.data, formatedMessage);

              evalAJAX.errorMessage(response);
              $ctrl.isInvalidLicense = true;
            }
          );
      };

      /**
       * Function to update clusterInfo with licenseState upon successful
       * upload of license key/file
       *
       * @method     licenseUploadedSuccessfully
       */
      function  licenseUploadedSuccessfully(response) {
        if (response.status === 208) {
          cMessage.success({
            titleKey: 'licenseAlreadyActivated',
          });
        }
        ClusterService.updateLicenseState('claimed')
          .then(ClusterService.updateRootWithLicenseSuccess);

        cancelModal();

        $state.goHome();

        cMessage.success({
          titleKey: 'licenseActivated',
          textKey: 'clusterConnect.darkSite.licenseUploadSuccessMessage',
        })
      }

      /**
       * To reset the error of invalid license
       * is called whenevr radio button option is changed
       *
       * @method     toggleUploadErrorFlag
       */
      function toggleUploadErrorFlag() {
        $ctrl.isInvalidLicense = false;
      }
    }
})(angular);
