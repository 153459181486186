// Component: cOffice365Settings

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.jobModify')
    .controller('Office365SettingsCtrl', office365SettingsCtrlFn)
    .component('office365Settings', {
      bindings: {
        // @type   {Object}    Object containing current job details.
        job: '=',

        // @type   {boolean}   Specifies whether the job is in edit mode.
        isEditMode: '<?',
      },
      controller: 'Office365SettingsCtrl',
      templateUrl:
        'app/protection/jobs/modify2/office365-settings/office365-settings.html',
    });

  /**
   * @ngdoc component
   * @name C.jobModify:office365Settings
   * @function
   *
   * @description
   * This component provides the advanced job settings for Office365 apps such
   * as Outlook, OneDrive etc.
   *
   * @example
   * <office365-settings job="$ctrl.job"
       is-edit-mode="true">
     </office365-settings>
   */
  function office365SettingsCtrlFn(_, $translate, Cart) {
    var $ctrl = this;

    _.assign($ctrl, {
      // Cart objects.
      defaultFolderCart: Cart.newCart(_generateKey),
      customFolderCart: Cart.newCart(_generateKey),
      unionCart: Cart.newCart(_generateKey),

      // Methods exposed to the template.
      analyzeFolderExclusion: analyzeFolderExclusion,
      handleCustomFolderNameChange: handleCustomFolderNameChange,
      handleCustomFolderAddition: handleCustomFolderAddition,
      handleCustomFolderDeletion: handleCustomFolderDeletion,

      // Component lifecycle methods.
      $onInit: onInit,
    });

    /**
     * Component initialization method hook.
     *
     * @method    onInit
     */
    function onInit() {
      var _defaultExclusionList = [
        'job.settings.o365Outlook.deletedItems',
        'job.settings.o365Outlook.drafts',
        'job.settings.o365Outlook.infectedItems',
        'job.settings.o365Outlook.inPlaceArchive',
        'job.settings.o365Outlook.junkEmail',
        'job.settings.o365Outlook.outbox',
        'job.settings.o365Outlook.rssSubscriptions',
        'job.settings.o365Outlook.sentItems',
        'job.settings.o365Outlook.syncIssues',
        'job.settings.o365Outlook.recoverableItems',
      ];

      // Sanitize the folder name in the default folder list.
      _defaultExclusionList.forEach(
        function addItem(item) {
          var currentFolder = {
            sanitizedName: _sanitizeFolderName(item),
            isSelected: _isFolderExcludedByDefault(item),
            isDisabled: false,
          };
          $ctrl.defaultFolderCart.add(currentFolder);

          // Add the In Place Folder to the Exclusion cart by default if not in
          // Edit Mode.
          if (!$ctrl.isEditMode) {
            analyzeFolderExclusion();
          }
        }
      );

      // Pre check the default folders if in edit mode.
      if ($ctrl.isEditMode) {
        var prevExclusionList =
          _.get($ctrl.job.environmentParameters.office365Parameters,
            'outlookParameters.filePathFilter.excludeFilters') || [];

        prevExclusionList.forEach(
          function preSelectFolder(folderName) {
            // Since the folder names are only returned through API, hence
            // validations for presence have to be done directly on Cart Map.
            if ($ctrl.defaultFolderCart.cartMap.hasOwnProperty(folderName)) {
              var oldFolderState = $ctrl.defaultFolderCart.cartMap[folderName];

              oldFolderState.isSelected = true;

              // Replace the old state.
              $ctrl.defaultFolderCart.add(oldFolderState);
            } else {
              $ctrl.customFolderCart.add({
                sanitizedName: folderName,
                isSelected: true,

                // This is to optimize lookups while updating the custom folder
                // cart directly within @method handleCustomFolderNameChange.
                oldKey: folderName,
              });

              // Enable custom folder exclusion display.
              $ctrl.job.customFolderExclusionEnabled = true;
            }
          }
        );
      }
    }

    // Common Fn to generate key for the carts.
    function _generateKey(folder) {
      return folder.sanitizedName;
    }

    /**
     * Generates translated folder name.
     *
     * @method   _sanitizeFolderName
     * @param    {string}   folderKey   Specifies the key to be translated.
     *
     * @return   {string}   Translated folder name.
     */
    function _sanitizeFolderName(folderKey) {
      return $translate.instant(folderKey) || '';
    }

    /**
     * Returns whether the folder should be selected by default or not.
     *
     * @method   _defaultValue
     * @param    {string}   folderKey   Specifies the key to be translated.
     *
     * @return   {bool}     Whether folder should be selected or not.
     */
    function _isFolderExcludedByDefault(folderKey) {
      if (!$ctrl.isEditMode) {
        return folderKey ==='job.settings.o365Outlook.recoverableItems';
      }
      return false;
    }

    /**
     * Analyzes the default and custom folder cart, removes duplicates, if any,
     * and updates the job_envParams with the exclusion list.
     *
     * @method   analyzeFolderExclusion
     */
    function analyzeFolderExclusion() {
      $ctrl.unionCart = $ctrl.defaultFolderCart.union($ctrl.customFolderCart,
        function filterItem(item) {
          return item.isSelected && !item.isDuplicate;
        });

      // This can only be undefined if the protection job is triggered through
      // the sources page.
      if (!$ctrl.job._envParams.outlookParameters) {
        _.assign($ctrl.job._envParams, {
          outlookParameters: {
            filePathFilter: {
              excludeFilters: [],
            },
            shouldBackupMailbox: true,
          },

          // Parameters specific to OneDrive.
          onedriveParameters: {
            // NOTE: OneDrive backups may also be enabled by default in
            // future.
            shouldBackupOnedrive: false,
          }
        });
      }
      $ctrl.job._envParams.outlookParameters.filePathFilter.excludeFilters =
        _.map($ctrl.unionCart.cartList, 'sanitizedName');
    }

    /**
     * Validates the presence of the custom folder name within the union cart
     * and triggers analysis of the folder exclusion if a new folder name is
     * added.
     *
     * @method   handleCustomFolderNameChange
     */
    function handleCustomFolderNameChange(index, oldKey) {
      // The item in custom folder cart now has the sanitized name updated
      // but since the key in the cart map still is the previous value, lookup
      // is done on 'oldKey' and the cart Map is updated.
      var oldState = $ctrl.customFolderCart.cartList[index];
      if ($ctrl.unionCart.has(oldState)) {
        oldState.isDuplicate = true;
      } else {
        oldState.isDuplicate = false;

        // Remove the old key object from Cart Map.
        delete $ctrl.customFolderCart.cartMap[oldKey];
        oldState.oldKey = oldState.sanitizedName;
        $ctrl.customFolderCart.cartMap[oldState.sanitizedName] = oldState;

        // Add the object with the new key at the old position of the object
        // removed above.
        $ctrl.customFolderCart.update(oldState, index);

        $ctrl.analyzeFolderExclusion();
      }
    }

    /**
     * Handles the addition of custom excluded folders and triggers the
     * analysis of the exclusions again.
     *
     * @method   handleCustomFolderAddition
     */
    function handleCustomFolderAddition() {
      $ctrl.customFolderCart.add({
        sanitizedName: '',
        isSelected: true,
        oldKey: '',
      });
      $ctrl.analyzeFolderExclusion();
    }

    /**
     * Handles the deletion of the folder from the custom folder cart.
     * @method   handleCustomFolderDeletion
     * @param    {string}  folderDetails
     */
    function handleCustomFolderDeletion(folderDetails) {
      $ctrl.customFolderCart.removeByKey(folderDetails.oldKey);
      if (folderDetails.isDuplicate) {
        // In case of duplicate just clear the current field & trigger
        // analyzeFolderExclusion()
        $ctrl.customFolderCart.add({
          sanitizedName: '',
          isSelected: true,
          oldKey: '',
        });
      }
      $ctrl.analyzeFolderExclusion();
    }
  }

})(angular);