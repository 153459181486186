<span
  class="column-name"
  [cogPopover]="tooltipTemplate"
  [tooltipStyling]="true"
  [showOnHover]="true"
  [hasBackdrop]="false"
  popoverPosition="bottom">
  {{ column.title }}
</span>

<ng-template #tooltipTemplate class="margin-top-lg">
  <div class="heat-map-column-header-tooltip-content">
    <div class="message">{{ messageKey | translate }}</div>
    <div class="states">
      <div class="state" *ngFor="let state of possibleStates">
        <span class="color {{state.colorClass}}"></span>
        {{ state.labelKey | translate }}
      </div>
    </div>
  </div>
</ng-template>
