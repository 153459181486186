import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { AltClusterSelectorComponent } from './alt-cluster-selector.component';
import { CloneDetailComponent } from './clone-detail.component';
import { CloneSummaryComponent } from './clone-summary.component';
import { ClusterPubSshKeyComponent } from './cluster-pub-ssh-key.component';
import { ContextMenuComponent } from './context-menu.component';
import { GlobalSearchInputComponent } from './global-search-input/global-search-input.component';
import { HealthAnalyticsComponent } from './health-analytics.directive';
import { HealthSettingsComponent } from './health-settings.directive';
import { ArchivalTargetIconPipe } from './pipes/archival-target-icon.pipe';
import { SnapshotTargetIconPipe } from './pipes/snapshot-target-icon.pipe';
import { SourceIconPipe } from './pipes/source-icon.pipe';
import { SourceTypePipe } from './pipes/source-type.pipe';
import { UsecsToTimePipe } from './pipes/usecs-to-time.pipe';
import { ViewBoxCompressionPipe, ViewBoxDedupeStatusPipe, ViewBoxEncryptionPipe } from './pipes/view-box.pipes';
import { PulseLogsListComponent } from './pulse-logs-list.component';
import { RolePrivilegesComponent } from './role-privileges.component';
import { SearchComponent } from './search.component';

export const UPGRADE_COMPONENTS = [
  AltClusterSelectorComponent,
  ArchivalTargetIconPipe,
  CloneSummaryComponent,
  ContextMenuComponent,
  CloneDetailComponent,
  ClusterPubSshKeyComponent,
  GlobalSearchInputComponent,
  HealthAnalyticsComponent,
  HealthSettingsComponent,
  PulseLogsListComponent,
  RolePrivilegesComponent,
  SearchComponent,
  SnapshotTargetIconPipe,
  SourceIconPipe,
  SourceTypePipe,
  UsecsToTimePipe,
  ViewBoxCompressionPipe,
  ViewBoxDedupeStatusPipe,
  ViewBoxEncryptionPipe,
];

/**
 * Ng Upgrade Module.
 */
@NgModule({
  imports: [TranslateModule.forChild(), CommonModule],
  declarations: [UPGRADE_COMPONENTS],
  exports: [UPGRADE_COMPONENTS],
})
export class NgUpgradeModule {}
