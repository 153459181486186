import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';

import { PipesModule } from '../pipes';
import { ClipboardCtaComponent } from './clipboard-cta/clipboard-cta.component';
import { ClusterPubSshKeyComponent } from './cluster-pub-ssh-key/cluster-pub-ssh-key.component';
import { DmsTokenComponent } from './dms-token/dms-token.component';

@NgModule({
  declarations: [ClipboardCtaComponent, ClusterPubSshKeyComponent, DmsTokenComponent],
  imports: [
    ClipboardModule,
    CohesityHelixModule,
    CommonModule,
    PipesModule,
    TranslateModule.forChild(),
  ],
  exports: [ClipboardCtaComponent, ClusterPubSshKeyComponent, DmsTokenComponent],
})
export class ClipboardCtaModule { }
