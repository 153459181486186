import { DOCUMENT, NgFor } from '@angular/common';
import { Component, Inject, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger, MatLegacyMenuModule as MatMenuModule  } from '@angular/material/legacy-menu';
import { Params } from '@angular/router';
import { IconModule } from '../icon/icon.module';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { DataIdDirective } from '../data-id/data-id.directive';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

export interface AppSwitcherConfig<T> {
  /**
   * The app name key.
   */
  name: T;

  /**
   * The url segment where the app UI is activated.
   */
  urlSegment?: string;

  /**
   * The optional params passed when navigated to app.
   */
  params?: Params;

  /**
   * Function responsible for transitioning the user to the app.
   */
  go?: () => void;

  /**
   * The app icon.
   */
  icon?: string;

  /**
   * Indicates if the service/app is currently active.
   */
  isActive?: boolean;

  /**
   * Application type. For App Pillars.
   */
  app?: string;

  /**
   * Pillar type. For App Pillars.
   */
  pillar?: string;
}

/**
 * Get the app displat name.
 */
export type GetAppDisplayName = (appName: string) => string;

@Component({
  selector: 'cog-app-switcher',
  templateUrl: './app-switcher.component.html',
  styleUrls: ['./app-switcher.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatLegacyButtonModule, DataIdDirective, MatMenuModule, MatLegacyTooltipModule, IconModule, NgFor]
})
export class AppSwitcherComponent<T> {
  /**
   * Label text for app switcher button.
   */
  @Input() label: string = null;

  /**
   * Optional if present and true then reload the page on app selection.
   */
  @Input() reload = false;

  /**
   * App configurations list.
   */
  @Input() appConfigs: AppSwitcherConfig<T>[] = [];

  /**
   * The MatMenuTrigger from the view, used to close the menu.
   */
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

  /**
   * Document reference.
   */
  private document?: Document;

  /**
   * App name translation method
   */
  @Input() getAppDisplayName: GetAppDisplayName = appName => appName;

  constructor(@Inject(DOCUMENT) document: any) {
    // Typing document as any and then assigning as Document type to avoid Angular complaining about being unable to
    // resolve type Document. See:
    // https://github.com/angular/angular/issues/20351#issuecomment-446025223
    this.document = document as Document;
  }

  /**
   * Navigated to app by reloading the page if this.reload is true else do nothing.
   *
   * @param app The app config.
   * @param event The mouse click event.
   */
  goto(app: AppSwitcherConfig<T>, event: MouseEvent) {
    if (typeof app.go === 'function') {
      // Prevent the url href from activating.
      event.preventDefault();
      event.stopPropagation();
      app.go();
      this.menuTrigger.closeMenu();
      return;
    }

    if (this.reload) {
      event.preventDefault();
      event.stopPropagation();
      this.document.location.href = this.getAppHref(app);
    }
  }

  getAppHref = (app: AppSwitcherConfig<T>) => [
    app.urlSegment,
    new URLSearchParams([...Object.entries(app.params || {})]).toString(),
  ].filter(Boolean).join('?');
}
