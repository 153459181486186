<!-- Form Field -->
<mat-form-field [style.display]="'block'">
  <mat-label>{{label | translate}}</mat-label>
  <mat-select #role id="role-select"
    cogDataId="role-admin"
    formControlName="rolesFormControl"
    [required]="isRequired"
    multiple
    [value]="selectedRole"
    [disabled]="disableRole"
    (selectionChange)="handleRoleSelection($event.value)">

    <!-- The selected values -->
    <mat-select-trigger>
      <mat-chip-list>
        <mat-chip class="selected-chip" *ngFor="let r of selectedRolesLabel">
          {{r.label}}
        </mat-chip>
      </mat-chip-list>
    </mat-select-trigger>

    <!-- Role search control -->
    <mat-option *ngIf="showRoleSearch">
      <ngx-mat-select-search
        [formControl]="roleSearchCtrl"
        [noEntriesFoundLabel]="'noMatchingItems' | translate"
        [disableScrollToActiveOnOptionsChanged]="true"
        [placeholderLabel]="'search' | translate"></ngx-mat-select-search>
    </mat-option>

    <!-- Admin Options -->
    <mat-option class="mat-multiline-option"
      *ngFor="let adminRole of filteredOptions.adminRoleOptions; index as i"
      id="role-admin-option-{{adminRole.name}}"
      cogDataId="role-admin-option-{{adminRole.name}}"
      [disabled]="adminRole._disabled"
      [value]="adminRole.name">
      <div class="padding-top-xs padding-bottom-xs">
        <div>{{adminRole.label}}</div>
        <small class="metadata" [matTooltip]="adminRole.description">{{adminRole.description}}</small>
      </div>
    </mat-option>
    <mat-divider></mat-divider>

    <!-- Other Role Options -->
    <mat-option class="mat-multiline-option"
      *ngFor="let role of filteredOptions.otherRoleOptions; index as i"
      id="role-option-{{role.name}}"
      cogDataId="role-option-{{role.name}}"
      [disabled]="role._disabled"
      [value]="role.name">
      <div>{{role.label}}</div>
      <small class="metadata" [matTooltip]="role.description">{{role.description}}</small>
    </mat-option>

    <!-- Custom Role Options -->
    <mat-option class="mat-multiline-option"
      *ngFor="let role of filteredOptions.customRoleOptions; index as i"
      id="custom-role-option-{{role.name}}"
      cogDataId="custom-role-option-{{role.name}}"
      [disabled]="role._disabled"
      [value]="role.name">
      <div>{{role.label}}</div>
      <small class="metadata" [matTooltip]="role.description">{{role.description}}</small>
    </mat-option>
    <button id="role-save-selection-btn"
      class="margin-sm"
      color="primary"
      mat-flat-button
      (click)="role.close()">
      {{'save' | translate}}
    </button>
  </mat-select>
  <mat-error *ngIf="!!rolesFormControl.errors?.required">
    {{'errors.required' | translate}}
  </mat-error>
</mat-form-field>
