import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { GetViewsResult, View, ViewServiceApi } from '@cohesity/api/v2';
import { SnackBarService } from '@cohesity/helix';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { ItemPickerFormControl } from '@cohesity/shared-forms';
import { AjaxHandlerService } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { filter, map } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services';

@Component({
  selector: 'coh-view-selector',
  templateUrl: './view-selector.component.html',
  styleUrls: ['./view-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ViewSelectorComponent),
      multi: true,
    },
  ],
})
export class ViewSelectorComponent extends ItemPickerFormControl<View> {
  /**
   * MatSelect child component.
   */
  @ViewChild(MatSelect) storageDomainSelector: MatSelect;

  /**
   * Label for the dropdown.
   */
  @Input() label = 'view';

  /**
   * Whether add new should be enabled
   */
  @Input() addNewEnable = false;

  /**
   * Label for addNew button
   */
  @Input() addNewLabel = 'addView';

  /**
   * Whether the component should show in read only mode
   */
  @Input() readOnly = false;

  /**
   * Default view id to select.
   */
  @Input() defaultId: number;

  /**
   * Specifies if only Views with nfs mount paths will be displayed for selection.
   */
  @Input() nfsOnly = false;

  /**
   * Specifies if only Views under Object Services with S3 protocol will be
   * displayed for selection.
   */
  @Input() objectServicesOnly = false;

  /**
   * If this property is true, creating a new View will
   * have certainly Remote Adapter specific settings enforced.
   * For instance, the View will only be allowed to use 'nfs' or 'all' protocols.
   */
  @Input() remoteAdapterOnly = false;

  /**
   * Float label passed on to determine mat-form-field behavior.
   */
  @Input() floatLabel = 'always';

  /**
   * Indicates if the component should be opened on initialization.
   */
  @Input() autoOpen = false;

  /**
   * Whether to show view meta data.
   */
  @Input() showMetaData = false;

  /**
   * Storage domain id to fetch the view from.
   */
  private _storageDomainId: number;

  /**
   * Sets storage domain id and gets the views from the current storage domain.
   */
  @Input() set storageDomainId(id: number) {
    this._storageDomainId = id;
    this.getViews();
  }

  /**
   * Gets storage domain id.
   */
  get storageDomainId() {
    return this._storageDomainId;
  }

  /**
   * Stores all the views.
   */
  views: View[] = [];

  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private dialogService: DialogService,
    private irisCtx: IrisContextService,
    private snackbar: SnackBarService,
    private translate: TranslateService,
    private viewsService: ViewServiceApi,
  ) {
    super();
  }

  /**
   * Gets all the views from current storage domain.
   */
  getViews() {
    // Reset the current value
    this.value = null;

    this.viewsService.GetViews({
      includeTenants: false,
      storageDomainIds: [this.storageDomainId],
    }).pipe(
      map((viewResponse: GetViewsResult) => viewResponse.views || []),
      this.untilDestroy()
    ).subscribe((views: View[]) => {
      // Filter nfs view only if applicable.
      if (this.nfsOnly) {
        views = views.filter(view => !!view.nfsMountPaths?.length);
      } else if (this.objectServicesOnly) {
        views = views.filter(view => view.category === 'ObjectServices' &&
          view.protocolAccess && view.protocolAccess[0]?.type === 'S3');
      }
      this.views = views;

      if (this.defaultId) {
        this.value = views.find(view => view.viewId === this.defaultId);
      }
    },
    error => this.ajaxHandlerService.handler(error));
  }

  /**
   * Adds new View to the current storage domain.
   */
  addView() {
    // Can't use viewService.openCreateViewDialog() due to import errors.
    const dialogName = flagEnabled(this.irisCtx.irisContext, 'createViewRefactor') ?
      'create-view-dialog-new' : 'modify-view-dialog';

    this.dialogService.showDialog(dialogName, {
      restrictViewBoxId: this.storageDomainId,
      disableRouting: true,
      viewParams: this.objectServicesOnly ? {
        category: 'ObjectServices'
      } : undefined,
    }, {
      minWidth: '34rem',
    }).pipe(
      filter(view => !!view),
      this.untilDestroy()
    ).subscribe((newView: View) => {

      // If the component is NFS only then the new view added should have
      // NFS mount path to be displayed in the list.
      if ((!this.nfsOnly || !!newView.nfsMountPaths?.length) &&

        // If the component is Object Services only category then the category
        // of the new view should match and the protocol should be S3.
        (!this.objectServicesOnly || (newView.category === 'ObjectServices' &&
        newView.protocolAccess && newView.protocolAccess[0]?.type === 'S3'))) {
        // If the component is a general component with no specific needs, add
        // the view and show it.
        this.views.push(newView);
        this.value = newView;
      }

      this.snackbar.open(this.translate.instant('views.createViewSuccess', {name: newView.name}));
    });
  }
}
