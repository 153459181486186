import { Component, Input } from '@angular/core';

/**
 * @description
 * Shows a legend indicator by drawing circle as per specified helix color class
 * Set background-color using css matching the legend input property in the component in which its used
 * Refer @all-color-palette mixin to generate colors as in examples below
 * @example
 *   <cog-legend-indicator legend="primary-500"></cog-legend-indicator>
 *   <cog-legend-indicator legend="status-success"></cog-legend-indicator>
 *   <cog-legend-indicator legend="helix-base-brand"></cog-legend-indicator>
 *   <cog-legend-indicator legend="chart-info"></cog-legend-indicator>
 */
@Component({
  selector: 'cog-legend-indicator',
  templateUrl: './legend-indicator.component.html',
  styleUrls: ['./legend-indicator.component.scss']
})
export class LegendIndicatorComponent {
  /**
   * The css selector for the legend color to be shown
   */
  @Input() legend: string;
}
