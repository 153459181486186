// Component: Host Mapping

;(function _iife(angular, undefined) {
  'use strict';

  angular
    .module('C.hostMapping', [])
    .controller('hostMappingCtrl', hostMappingCtrlFn)
    .component('hostMapping', {
      templateUrl: 'app/platform/host-mapping/host-mapping.html',
      controller: 'hostMappingCtrl'
    });

  function hostMappingCtrlFn(_, $rootScope, PartitionService, evalAJAX, FORMATS, cMessage,
    FILESIZES, $translate, NgUploadHostsFileService) {
    var $ctrl = this;

    _.assign($ctrl, {
      // variables
      hosts: [],
      FORMATS: FORMATS,
      savingHostMapping: false,
      editMode: false,
      unsavedHostMapping: undefined,
      hostsFile: undefined,
      version: 0,
      userModifyAccess: $rootScope.user.privs.CLUSTER_MODIFY,

      // methods
      $onInit: $onInit,
      addNewHostMapping: addNewHostMapping,
      frmHostMappingSubmit: frmHostMappingSubmit,
      editHostMapping: editHostMapping,
      uploadHostsFile: uploadHostsFile,
      openUploadHostsFileDialog: openUploadHostsFileDialog,
      cancel: cancel,
    });

    /**
     * open upload host file dialog
     *
     * @method  openUploadHostsFileDialog
     */
     function openUploadHostsFileDialog() {
       if ($ctrl.hostsFile) {
         NgUploadHostsFileService.openUploadHostsFileDialog().subscribe(data => {
           if (data === 'yes') {
             return uploadHostsFile();
           }
         });
       }
     }

    /**
     * Initializes and fetches data.
     *
     * @method   $onInit
     */
    function $onInit() {
      getHostMapping();
    }

    /**
     * Update the Hosts Mapping Data with Persisting the existing data
     *
     * @method getUpdatedHostMapping
     */
    function getUpdatedHostMapping(existingHosts, incomingHosts) {
      const filteredIncomingHosts = incomingHosts.filter(
        incomingHost => !existingHosts.some(existingHost => existingHost.ip === incomingHost.ip)
      );
      return [...existingHosts, ...filteredIncomingHosts];
    }

    /**
     * Gets the hosts file information from the API via PartitionService
     *
     * @method     getHostMapping
     */
    function getHostMapping() {
      $ctrl.loading = true;
      PartitionService.getHostMapping().then(
        function getHostMappingSuccess(hosts) {
          if ($ctrl.hosts.length > 0) {
            $ctrl.hosts = getUpdatedHostMapping($ctrl.hosts, hosts.hosts) || [];
          } else {
            $ctrl.hosts = hosts.hosts || [];
          }
          $ctrl.version = hosts.version;
          $ctrl.loading = false;
          if ($ctrl.userModifyAccess) {
            frmHostMappingSubmit();
          }
        }, evalAJAX.errorMessage);
    }

    /**
     * Switch to edit mode and clone the initial host mapping data which is used
     * if the user clicks cancel button.
     *
     * @method  editHostMapping
     */
    function editHostMapping() {
      $ctrl.editMode = true;
      $ctrl.unsavedHostMapping = _.clone($ctrl.hosts) || [];
    }

    /**
     * Add a new entry to add host IP mapping
     *
     * @method     addNewHostMapping
     */
    function addNewHostMapping() {
      $ctrl.editMode = true;
      $ctrl.hosts.push({
        ip: '',
        domainName: [],
      });
    };

    /**
     * Cancel editing host mapping. Revert host mapping to the previos state.
     *
     * @method  cancel
     */
    function cancel() {
      $ctrl.editMode = false;
      $ctrl.hosts = _.clone($ctrl.unsavedHostMapping) || [];
    }

    /**
    /**
     * form submit routing based on new vs edit mode
     *
     * @method     frmHostMappingSubmit
     */
    function frmHostMappingSubmit() {
      if ($ctrl.frmHostMapping.$invalid) {
        return;
      }

      $ctrl.savingHostMapping = true;
      $ctrl.version = $ctrl.version + 1;
      PartitionService.updateHostMapping({hosts: $ctrl.hosts, validate: true,
        version: $ctrl.version}).then(
        function updatedHostMapping() {
          $ctrl.editMode = false;
          $ctrl.frmHostMapping.$setPristine();
        },
        function updatedHostMappingError(response) {
          evalAJAX.errorMessage(response);
          cancel();

          // Get host mappings again to get latest version of hosts entries.
          getHostMapping();
        }).finally(function updatedHostMappingFinally() {
          $ctrl.savingHostMapping = false;
        });
    }

    /**
     * Call partition service to upload hosts file. on success, get host mapping
     * on failure, displays error message
     *
     * @method  uploadHostsFile
     */
    function uploadHostsFile() {
      if (!$ctrl.hostsFile) {
        return;
      }
      if ($ctrl.hostsFile.size > 10 * FILESIZES.megabyte) {
        evalAJAX.errorMessage(
          {
          error: {
            message: $translate.instant('maximumHostsFileSize')
          }
        });
        return;
      }
      PartitionService.postHostsFile($ctrl.hostsFile).success(
        function successResp(data) {
          getHostMapping();
        }
      ).error(function errorHandler(err) {
        evalAJAX.errorMessage({data: err});
      });
    };
  }
})(angular);
