import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Tenant } from '@cohesity/api/v1';
import { ClusterConfig, IrisContext, IrisContextService, flagEnabled, isMcm } from '@cohesity/iris-core';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { NGXLogger } from 'ngx-logger';
import { iif, merge, of } from 'rxjs';
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  finalize,
  map,
  switchMap,
  takeWhile
} from 'rxjs/operators';
import { AppServiceManagerService } from 'src/app/app-services';
import { AppStateService, ClusterGflagService, RemoteClusterService, ScopeSelectorService } from 'src/app/core/services';
import { isSameScope } from 'src/app/util';

/**
 * This component is used to show the list of cluster scopes available
 * when the user in Cluster Manger in Helios.
 */
@Component({
  selector: 'coh-cluster-switcher',
  templateUrl: './cluster-switcher.component.html',
  styleUrls: ['./cluster-switcher.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ClusterSwitcherComponent extends AutoDestroyable implements OnInit {
  /**
   * List of available cluster options based on user's grants and
   * privileges. This does not take user search into considerations.
   */
  clusterConfigs: ClusterConfig[] = [];

  /**
   * List of options considering user search on the UI.
   */
  searchFilteredConfigs: ClusterConfig[] = [];

  /**
   * FormControl for managing the MatSelect.
   */
  clusterSelector = new FormControl<ClusterConfig>(null);

  /**
   * Search control for role list
   */
  clusterSearchCtrl = new FormControl<string>('');

  /**
   * Indicates if the visual treatment should look like a classic dropdown (underlined).
   */
  @HostBinding('class.classic-dropdown') @Input() classicDropdown = true;

  /**
   * flag to show if loading is in progress
   */
  loadingClusters = false;

  /**
   * True if appPillars is enabled.
   */
  appPillars: boolean;

  /**
   * Get the selected cluster.
   */
  get selectedScope(): ClusterConfig {
    return this.appStateService.selectedScope;
  }

  get isSelectDisabled(): boolean {
    return (
      // Definitely switching in progress
      this.appStateService.isSwitchingContext ||
      // either switching is not completed yet or some error
      // may require user to do a full refresh
      !isSameScope(this.clusterSelector.value, this.irisContextService.irisContext.selectedScope)
    );
  }

  constructor(
    private appStateService: AppStateService,
    private appServiceManagerService: AppServiceManagerService,
    private ajaxHandlerService: AjaxHandlerService,
    private irisContextService: IrisContextService,
    private logger: NGXLogger,
    private remoteClusterService: RemoteClusterService,
    private scopeService: ScopeSelectorService,
    private stateService: StateService,
    private uiRouterGlobals: UIRouterGlobals,
    private gflagService: ClusterGflagService,
  ) {
    super();
  }

  ngOnInit() {
    this.appPillars = flagEnabled(this.irisContextService.irisContext, 'appPillarsEnabled');
    this.observeAppScopeChanges();
    this.observeUserChangeEvents();
  }

  /**
   * Indicates if a given cluster should be disabled or not.
   *
   * @param config   ClusteConfig for evaluation.
   * @returns        true if it should be disabled, false otherwise.
   */
  isDisabled(config: ClusterConfig) {
    if (!isMcm(this.irisContextService.irisContext)) {
      return false;
    }

    return !config.connectedToCluster && !config._allClusters;
  }

  /**
   * Calls the service to switch scope.
   */
  async changeScope() {
    const cluster: ClusterConfig = {
      ...this.clusterSelector.value,

      // Persist the Helios service context with each cluster change.
      _serviceType: this.selectedScope._serviceType,
    };

    // Remove cid (clusterId) param as the scope will be again set correctly
    // in scope switch methods.
    const {cid, ...params} = this.uiRouterGlobals.params;

    // Changing state is a 2-step method. First replace current state w/o cid params
    // then move to switch scope.
    try {
      await this.stateService.go('.', {...params}, {inherit: false, location: 'replace'});
    } catch (err) {
      this.logger.error('Transition to remove cluster Id failed');
    }

    if (cluster.claimType === 'kIBMStorageProtect') {
      // Revert scope to previous selected scope and open IBM Storage Protect UI in new tab
      this.updateState(
        this.clusterConfigs, this.irisContextService.irisContext.selectedScope
      );

      if (cluster.dataplaneUiRedirectUrl) {
        window.open(cluster.dataplaneUiRedirectUrl);
      }
    } else if (cluster._nonCluster || this.appStateService.selectedScope._nonCluster) {
      // For straightforward cases, cutting out all the complex logic.
      this.scopeService.basicScopeSwitch(cluster).subscribe(
        () => {},
        // revert the scope back to original
        (err) => {
          this.updateState(
            this.clusterConfigs, this.irisContextService.irisContext.selectedScope
          );
          this.ajaxHandlerService.handler(err);
        }
      );
    } else {
      // Suppress reloading of the state, when the application is running in Helios
      // The mcm-view.service will determine whether to reload the state or not.
      const suppressReload = !!this.appStateService.selectedScope.mcmMode;

      this.scopeService.switchScope(cluster, suppressReload);
    }
  }

  /**
   * Remove irrelevant cohesity service scopes.
   *
   * @param allScopes All available scopes to current user
   * @returns list of cluster scopes only
   */
  filterClustersOnly(allScopes: ClusterConfig[] = []): ClusterConfig[] {
    return allScopes.filter(
      // Ensure we are only showing "All Clusters" and actual clusters.
      cluster => (cluster._allClusters || !cluster._nonCluster) && cluster._serviceType !== 'clusterManager'
    );
  }

  /**
   * Observe different app events like impersonation, scope switch events
   * and update the component state
   */
  observeAppScopeChanges() {
    // this stream emits irisContext changes after the scope has been set i.e.
    // scope guard has run
    const safeContextChanges$ = this.irisContextService.irisContext$.pipe(
      this.untilDestroy(),
      // filter all events until scope guard has run
      // or in case of service switch, selected scope has been
      // established as _allClusters i.e. cluster manager since cluster switcher
      // is used within this context.
      // IGNORE all other changes in irisContext$
      filter((ctx) => (
        Boolean(ctx.selectedScope?.clusterId || ctx.selectedScope?._allClusters) &&
        Boolean(this.appStateService.remoteClusterList?.length)
      )),
    );

    // this stream emits the list of latest available scopes based on tenant
    // impersonation events
    const tenantImpersonationChanges$ = safeContextChanges$.pipe(
      takeWhile(
        () => this.appServiceManagerService.getActiveService()?.serviceType === 'clusterManager'
      ),
      map<IrisContext, Tenant>((ctx) => ctx.impersonatedTenant),
      // only listen for change tenantId events
      distinctUntilKeyChanged<Tenant>('tenantId'),
      // load scopes for changed tenant
      switchMap((tenantInfo) => {
        // if there's an active tenant impersonation
        if (tenantInfo.tenantId) {
          this.loadingClusters = true;
          // Do not update app state service with the remote cluster list
          // to ensure compat with earlier behaviour
          return this.remoteClusterService.getRemoteClustersList().pipe(
            this.untilDestroy(),
            finalize(() => this.loadingClusters = false)
          );
        }
        // tenant impersonation removal case. load the list from app state service
        return of(this.appStateService.remoteClusterList || []);
      })
    );

    // this stream emits the list of scopes in cluster scope switch events
    const clusterScopeChanges$ = safeContextChanges$.pipe(
      distinctUntilChanged((prevCtx, currCtx) => isSameScope(prevCtx.selectedScope, currCtx.selectedScope)),
      switchMap(ctx => iif(
        () => Boolean(ctx.impersonatedTenant.tenantId),
        // if there's an impersonated tenant, the list of available
        // scope do not change on cluster scope change event. Therefore
        // current list of scopes holds good
        of(this.clusterConfigs),
        // If there's no impersonated tenant, the remoteClusterList property
        // holds the list of available scopes for logged in user. Use that list.
        of(this.appStateService.remoteClusterList || [])
      ))
    );

    // Merge impersonation events and cluster scope change events
    // to update the component's state based on list of available scopes
    // and selected scope value
    merge(tenantImpersonationChanges$, clusterScopeChanges$).pipe(
      this.untilDestroy(),
      map(clusters => this.filterClustersOnly(clusters))
    ).subscribe((clusters) => {
      const selectedScope = this.irisContextService.irisContext.selectedScope;
      this.updateState(clusters, selectedScope);
    });
  }

  /**
   * Stream for observing user search on UI
   */
  observeUserChangeEvents() {
    this.clusterSearchCtrl.valueChanges.pipe(
      this.untilDestroy()
    ).subscribe(val => {
      this.searchFilteredConfigs = this.clusterConfigs?.filter(
        config => config._allClusters || config?.name?.toLowerCase().includes(val.toLowerCase())
      );
    });
  }

  /**
   * Updates the state of the component with the incoming selection
   * and list of available scopes after validating the selection.
   *
   * @param options list of cluster scope options
   * @param selection selected cluster scope
   */
  updateState(options: ClusterConfig[] = [], selection: ClusterConfig) {

    // All cluster state is expected to be in the list
    const allClusterState = options.find(option => option._allClusters);

    let selected = allClusterState;
    const validSelection = options.find((option) => isSameScope(option, selection));

    if (validSelection) {
      selected = validSelection;
    }

    this.clusterConfigs = options;
    this.searchFilteredConfigs = options;
    this.clusterSelector.setValue(selected);
  }

  /**
   * Change scope and location to the default state for the current helios service.
   * Otherwise select All Clusters in the selector which is the original behavior
   * for Cluster Manager, renamed DataProtect.
   */
  async selectAllClusters() {
    let serviceName = '';

    switch (this.selectedScope._serviceType) {
      case 'smartFiles':
        serviceName = 'SmartFiles';
        break;
      default:
        // Leave `serviceName` undefined for default behavior.
    }

    if (serviceName) {
      const serviceConfig = this.appServiceManagerService.getServiceByClusterConfig({name: serviceName});
      this.scopeService.basicScopeSwitch(serviceConfig.clusterConfigPartial).subscribe();
    } else {
      this.clusterSelector.setValue(this.clusterConfigs.find(c => c._allClusters));
      this.clusterSelector.updateValueAndValidity();
      this.changeScope();
    }
  }
}
