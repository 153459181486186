<ng-container *ngIf="data?.length">
  <cog-donut-chart
    [customChartOptions]="options"
    [seriesData]="pieChartSeries"
    class="default-chart-theme">
  </cog-donut-chart>
  <div class="default-chart-theme cog-chart column" *ngFor="let column of legendValues">
    <cog-statlist [vertical]="true">
      <cog-statlist-item (click)="setLegendClickParameters(item)" *ngFor="let item; of column"
        [label]="item.label || item.name"
        [labelLegend]="item.className ? item.className : 'highcharts-color-'+item.index">
        <cog-data-renderer *ngIf="!isLegendClickable" [value]="item.value" [dataType]="item.dataType">
        </cog-data-renderer>
        <a class="alternate-text" *ngIf="isLegendClickable">
          <cog-data-renderer [value]="item.value" [dataType]="item.dataType"></cog-data-renderer>
          <div *ngIf="showPercentInLegend && totalValue > 0" class="d-flex percent">
            (<cog-data-renderer [value]="item.value/totalValue" [dataType]="'percent'"></cog-data-renderer>)
          </div>
        </a>
      </cog-statlist-item>
    </cog-statlist>
  </div>
</ng-container>
<div *ngIf="!data?.length">{{translate('reporting.noChartFilterDataAvailable')}}</div>
