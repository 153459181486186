import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { isDmsScope, isDmsUser } from './dms-utils';
import { isDraasScope, isDraasUser } from './draas-utils';

import { IrisContext } from './iris-context.model';
import { IrisContextService } from './iris-context.service';

/**
 * Map of possible options for cohIrisHide directive.
 */
const IrisHideValueMap = {
  isDmsScope,
  isDmsUser,
  isDraasScope,
  isDraasUser,
  '!isDmsScope': (ctx: IrisContext) => !isDmsScope(ctx),
  '!isDmsUser': (ctx: IrisContext) => !isDmsUser(ctx),
  '!isDraasScope': (ctx: IrisContext) => !isDraasScope(ctx),
  '!isDraasUser': (ctx: IrisContext) => !isDraasUser(ctx),
};

/**
 * Directive to hide components automatically when the dms scope is selected or
 * if the user is a dms user.
 */
@Directive({
  selector: '[cohIrisHide]',
})
export class IrisHideDirective implements OnInit, OnDestroy {
  /**
   * Input to specify whether to hide the component for dms scope or dms user.
   */
  @Input('cohIrisHide') irisHideValue: keyof typeof IrisHideValueMap = 'isDmsScope';

  /**
   * Subscription of the iris context service change.
   */
  subscription: Subscription;

  constructor(private elementRef: ElementRef, private irisContextService: IrisContextService) {}

  ngOnInit(): void {
    // Set initial visibility of the component
    this.setVisibility(IrisHideValueMap[this.irisHideValue](this.irisContextService.irisContext));

    this.subscription = this.irisContextService.irisContext$.subscribe(value => {
      // Update the visibility id the selected scope changes.
      this.setVisibility(IrisHideValueMap[this.irisHideValue](value));
    });
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * Set the visibility of the component based on the provided value.
   *
   * @param value The value.
   */
  setVisibility(value: boolean): void {
    if (!this.elementRef || !this.elementRef.nativeElement) {
      return;
    }

    if (value) {
      // Hide the component using an inline style attribute.
      this.elementRef.nativeElement.style.display = 'none';
    } else {
      // Remove the inline style attribute.
      if (this.elementRef.nativeElement.removeProperty) {
        this.elementRef.nativeElement.removeProperty('display');
      }
    }
  }
}
