import { DataRenderer, IconDataRendererComponent } from '@cohesity/helix';

function getStatusIcon(value: string): string {
  switch (value) {
    case 'Connected':
      return 'helix:status-success!success';
    case 'Disconnected':
      return 'helix:status-error!critical';
  }
  return '';
}

/**
 * Renders a connection status icon based on a connection status enum.
 */
export const connectionStatusIconRenderer = {
  transform: (renderValues: DataRenderer<string, undefined, undefined>) => ({
    ...renderValues,
    value: getStatusIcon(renderValues.value),
  }),
  component: IconDataRendererComponent,
};
