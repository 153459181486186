import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const sourceModify = (ctx: StateAccessContext) => ctx.PROTECTION_SOURCE_MODIFY;

export const SanConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'san.**',
      url: '/protection/source/san',
      loadChildren: () => import('./san.module').then(m => m.SANModule),
    }
  ],
  getStateAccessMap(): StateAccessMap {
    return {
      'san.new': sourceModify,
      'san.edit': sourceModify,
    };
  }
};
