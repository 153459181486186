import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

/**
 * Settings list item to read only mount toggle
 */
@Component({
  selector: 'coh-settings-list-read-only-mount',
  templateUrl: './settings-list-read-only-mount.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsListReadOnlyMountComponent {
  /**
   * The form control for the settings list item.
   */
  @Input() control: UntypedFormControl;
}
