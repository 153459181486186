import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { BaseRendererComponent } from '@cohesity/helix';

/**
 * Available params that can be specified for difference data
 */
export interface BytesChangeRendererParams {
  /**
   * Key for the percentage value
   */
  percentageKey?: string;

  /**
   * Whether the value is absolute value. If true, won't show icon and color
   */
  absoluteValue?: boolean;
}

/**
 * Renders a difference.
 */
@Component({
  selector: 'iris-rpt-bytes-change-renderer',
  templateUrl: './bytes-change-renderer.component.html',
  styleUrls: ['./bytes-change-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BytesChangeRendererComponent extends BaseRendererComponent<string, BytesChangeRendererParams> {
  /**
   * Gets the rendered percentage value.
   */
  get percentage() {
    if (!this.secondaryValue || !this.renderParams?.percentageKey) {
      return null;
    }
    return this.secondaryValue[this.renderParams?.percentageKey];
  }

  /**
   * Gets the container class.
   */
  get containerClass(): string {
    if (this.renderParams?.absoluteValue) {
      return null;
    }
    return `bytes-change-with-icon bytes-change-cell-${Number(this.value) > 0 ? 'error' : 'success'}`;
  }

  /**
   * Gets the icon shape.
   */
  get iconShape(): string {
    if (this.renderParams?.absoluteValue) {
      return null;
    }
    return Number(this.value) > 0 ? 'arrow_drop_up' : 'arrow_drop_down';
  }

  /**
   * Gets the numeric equivalent of string value
   */
  get numericValue(): number {
    return Number(this.value);
  }
}
