<mat-calendar-header>
  <button [attr.aria-label]="'previous' | translate" mat-icon-button (click)="previousClicked()" class="prev-button"
    [id]="uniqueId + 'prev-btn'">
    <mat-icon aria-hidden="true">navigate_before</mat-icon>
  </button>

  <div class="coh-calendar-head-date">
    {{ calendar.activeDate | cohDate: 'MMMM y' }}
  </div>

  <button [attr.aria-label]="'next' | translate" mat-icon-button (click)="nextClicked()" class="next-button"
    [id]="uniqueId + 'next-btn'">
    <mat-icon aria-hidden="true">navigate_next</mat-icon>
  </button>

  <div class="coh-calendar-head-spacer"></div>

  <button mat-stroked-button (click)="todayClicked()" class="today-button"
    [id]="uniqueId + 'today-btn'">
    {{ 'today' | translate }}
  </button>
</mat-calendar-header>
