import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { Observable } from 'rxjs';
import { PrivateEndpointDialogData } from './private-endpoints-dialog.interface';

@Component({
  selector: 'coh-app-private-endpoints-dialog',
  templateUrl: './private-endpoints-dialog.component.html',
})
export class PrivateEndpointsDialogComponent implements OnInit {
  /**
   * Local instance of private endpoint field formGroup.
   */
  privateEndPointFormGroup = new FormGroup({
    privateEndpointFields: new FormArray([]),
  });

  /**
   * Selected protection source node.
   */
  protectionSource$: Observable<ProtectionSourceNode>;

  /**
   * Get formArray
   */
  get privateEndpointFields() {
    return this.privateEndPointFormGroup.get('privateEndpointFields') as FormArray;
  }

  constructor(
    public dialogRef: MatDialogRef<PrivateEndpointsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PrivateEndpointDialogData
  ) {
    this.protectionSource$ = data.protectionSource$;
  }

  ngOnInit(): void {
    this.data.protectionFieldValue.forEach(element => {
      this.privateEndpointFields.push(this.addPrivateEndpointWithSubscription(element));
    });
  }

  /**
   * get private endpoint field.
   *
   * @param index
   * @returns
   */
  getPrivateEndpointField(index: number) {
    return (this.privateEndPointFormGroup.get('privateEndpointFields') as FormArray).controls[index] as FormGroup;
  }

  /**
   * Add private endpoint formGroup to form array.
   *
   * @param param0 Form Values for private endpoint fields.
   * @returns private endpoint formGroup.
   */
  addPrivateEndpointWithSubscription({ subscription, region, virtualNetwork, subnet }) {
    return new FormGroup({
      subscription: new FormControl(subscription),
      region: new FormControl(region),
      virtualNetwork: new FormControl(virtualNetwork, Validators.required),
      subnet: new FormControl(subnet, Validators.required),
    });
  }

  /**
   * Save click - validate,if valid emit values.
   */
  save() {
    this.privateEndPointFormGroup.markAllAsTouched();
    if (this.privateEndPointFormGroup.valid) {
      this.dialogRef.close(this.privateEndpointFields.value);
    }
  }

  /**
   * Cancel click- Rest fields.
   */
  cancel() {
    this.privateEndPointFormGroup.reset();
    this.dialogRef.close([]);
  }
}
