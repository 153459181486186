import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { HelixIntlService } from '../../helix-intl.service';
import { SMALL_WIDTH_BREAKPOINT } from '../../util';
import { AppNavComponent } from './app-nav/app-nav.component';

/**
 * App frame configures the main frame for the application and configures the
 * placement of the header and menu sidenav. It uses content projection to place
 * the an AppDrawerComponent (if it exists), the main page content, and
 * additonal components for the app bar.
 *
 * @export
 */
@Component({
  selector: 'cog-app-frame',
  templateUrl: './app-frame.component.html',
  styleUrls: ['./app-frame.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppFrameComponent implements AfterViewInit, OnInit, OnDestroy, OnChanges {
  /**
   * Emits whenever the component is destroyed.
   * Subscriptions can use 'takeUntil(this.destroy)' to automatically clean up whenever the component is destroyed.
   */
  private destroy = new Subject<void>();

  /**
   * Indicates if overlay menu should be shown
   * because of viewport size
   */
  private _shouldOverlayMenu = true;

  /**
   * Indicates if the primary nav should be displayed.
   */
  public isNavOpen = false;

  /**
   * Nav column selector.
   */
  @ViewChild('navColumn', {static: false}) navColumn: MatSidenav;

  /**
   * Content column selector.
   */
  @ViewChild('contentColumn', {static: false}) contentColumn: ElementRef;

  /**
   * App column selector. Used to determine if navigation was provided.
   */
  @ContentChild(AppNavComponent, {static: false}) appNav: AppNavComponent;

  /**
   * Optional. If true, this displays the brand content in the center of the
   * screen and hides header embedded controls.
   * This is used in the login page for instance.
   */
  @Input() hideControls = false;

  /**
   * Optional. Whether to collapse the menu by default and always show the
   * hamburger menu icon.
   */
  @Input() collapseMenu = false;

  /**
   * Optional. Whether to collapse the menu initially.
   */
  @Input() initialCollapseMenu = false;

  /**
   * Optional. Whether to hide sidebar nav and nav button.
   */
  @Input() hideNav = false;

  /**
   * Optional. Whether to show the close button instead of hamburger menu.
   */
  @Input() showClose = false;

  /**
   * Optional.  Whether to just keep the drawer open and not display the
   * hamburger menu so that it cannot be closed.
   */
  @Input() keepNavDrawerOpen = true; // set to false for jazz

  /**
   * Event emitter for sidebar nav being toggled.
   */
  @Output() navToggled = new EventEmitter<boolean>();

  /**
   * Optional. Event emitter for when close button is clicked.
   */
  @Output() closeButtonClick = new EventEmitter<MouseEvent>();

  /**
   * Indicates if nav column is visible on page.
   */
  navColumnContentVisible = false;

  /**
   * Subscription for the breakpoint observer.
   */
  breakpointSubscription: Subscription;

  /**
   * Applies a class to the appFrame component element if the user has scrolled down the page at all. This class be used
   * by other components (i.e. pageHeader) to style based on viewport position.
   * TODO: clean up this scrolling implementation when its no longer utilized in Iris App. This will be a breaking
   * change for Helix.
   */
  @HostBinding('class.has-page-y-offset') hasOffset = false;

  constructor(
    public intl: HelixIntlService,
    private breakpoints: BreakpointObserver,
    private cdr: ChangeDetectorRef
  ) {}

  /**
   * Add a subscription to track the screen size breakpoints
   */
  ngOnInit() {
    this.setMenuVisibility();
  }

  ngAfterViewInit() {
    this.contentColumn.nativeElement.addEventListener('scroll', e => {
      this.hasOffset = (e.currentTarget as any).scrollTop > 0;
    });
  }

  /**
   * Handling input changes and update the nav accordingly.
   *
   * @param  changes  simple changes object
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.hideControls) {
      this.updateMenuVisibility();
    }

    if (changes.collapseMenu) {
      this.setMenuVisibility();
    }
  }

  /**
   * Trigger destroy subject to clean up subscriptions.
   */
  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  /**
   * Function to set the visibility mode for the menu (overlay or push).
   *
   * This visibility mode is set based on either collapseMenu input, or by
   * predefined breakpoints. collapseMenu overrides breakpoints and always
   * hides the menu by default and shows the hamburger menu icon.
   */
  setMenuVisibility() {
    if (this.breakpointSubscription && !this.breakpointSubscription.closed) {
      this.breakpointSubscription.unsubscribe();
    }

    if (this.collapseMenu || this.initialCollapseMenu) {
      // If collapseMenu input is set to true, always hide the nav menu.
      this.isNavOpen = false;

      // Menu will not be opened when window resizes if collapseMenu is true.
      if (this.collapseMenu) {
        return;
      }
    }

    // Show or hide menu based on the browser resolution
    this.breakpointSubscription = this.breakpoints
      .observe([`(max-width: ${SMALL_WIDTH_BREAKPOINT})`])
      .pipe(takeUntil(this.destroy))
      .subscribe((state: BreakpointState) => {
        this._shouldOverlayMenu = state.matches;
        this.updateMenuVisibility();
        this.cdr.detectChanges();
      });
  }

  /**
   * Update the nav menu visibility based on current screen size (small/widescreen) & hide controls status.
   */
  updateMenuVisibility() {
    this.isNavOpen = false;
    if (this.hideControls) {
      // Hide the nav control if nav controls are hidden.
      this.isNavOpen = false;
    } else if (!this._shouldOverlayMenu) {
      // Don't open nav if initialCollapseMenu is true.
      if (this.initialCollapseMenu) {
        this.initialCollapseMenu = false;
      } else {
        // Always open side nav for larger screens.
        this.isNavOpen = true;
      }
    }
  }

  /**
   * Toggles the visibility of the app drawer
   */
  toggleMenu() {
    this.isNavOpen = !this.isNavOpen;
    this.navToggled.emit(this.isNavOpen);
  }

  /**
   * Focuses the primary content for keyboard users.
   */
  skipToPrimary() {
    this.contentColumn.nativeElement.focus();
  }

  /**
   * Returns true if the drawer will be displayed as a modal. The display
   * switches to a modal when the screen width is less than
   * SMALL_WIDTH_BREAKPOINT. When it is a modal, it is rendered on top of the
   * main app content, rather than beside it, with an overlay dimming the
   * content and blocking input until the drawer is closed.
   */
  get shouldOverlayMenu(): boolean {
    // If there's no nav present, it can't be a modal.
    return !!this.appNav && this._shouldOverlayMenu;
  }

  /**
   * Returns menu column mode of how it interacts with layout: "push" or "overlay" mode.
   */
  get menuMode(): MatDrawerMode {
    return this.shouldOverlayMenu ? 'over' : 'side';
  }

  /**
   * Returns whether to show the hamburgerMenu or not.
   *
   * as long as we have viewport room and keepNavDrawerOpen
   * is true, then we do not need to show the hamburgerMenu
   * because hosting app wants to display consistent left nav.
   */
  get disableClose(): boolean {
    return this.keepNavDrawerOpen && !this.shouldOverlayMenu;
  }
}

/**
 * Projected content for items on the left of the app bar. This will show to
 * the right of the branding.
 */
@Component({
  selector: 'cog-app-bar-left',
  template: '<ng-content></ng-content>'
})
export class AppBarLeftComponent { }

/**
 * Projected content for items on the right of the app bar.
 */
@Component({
  selector: 'cog-app-bar-right',
  template: '<ng-content></ng-content>'
})
export class AppBarRightComponent  {
  @HostBinding('style.display') display = 'flex';
  @HostBinding('style.align-items') align = 'center';
}

/**
 * Projected content for the center container.
 */
@Component({
  selector: 'cog-app-content',
  template: '<ng-content></ng-content>'
})
export class AppContentComponent  {}

/**
 * Projected content for items on the bottom of the left nav.
 */
@Component({
  selector: 'cog-app-side-nav-footer',
  template: '<ng-content></ng-content>'
})
export class AppSideNavFooterComponent {}
