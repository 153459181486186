<div
  class="table-column-toggles"
  cdkDropList
  [cdkDropListDisabled]="!enableReordering"
  (cdkDropListDropped)="reorderColumns($event)"
  (click)="$event.stopPropagation()">
  <mat-slide-toggle
    cdkDrag
    [cdkDragData]="column"
    *ngFor="let column of columns || []; first as isFirst"
    class="table-column-config-slide-toggle"
    cdkDragPreviewClass="table-column-config-slide-toggle-drag-preview"
    [class.table-column-config-with-separator]="column.hasSeparator"
    [class.hidden]="enableReordering ? false : (column.hideConfig || isFirst)"
    [disabled]="enableReordering ? column.hideConfig : (column.hideConfig || isFirst)"
    [checked]="column.enabled"
    [required]="true"
    (toggleChange)="toggleColumnVisibility(column)"
    [cogDataId]="'toggle-table-column-' + column.title">
    <div class="slide-toggle-content">
      <span [innerHTML]="getTitleTranslation(column.title)"></span>
      <cog-icon *ngIf="enableReordering" class="drag-handle" shape="drag_handle!outline"></cog-icon>
    </div>
  </mat-slide-toggle>
</div>
