import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ByteSizeComponent } from './byte-size.component';
import { ByteSizePipe } from './byte-size.pipe';

/**
 * Byte-size Module having byte-size component, pipe, service and its models.
 */
@NgModule({
  imports: [CommonModule, ByteSizeComponent, ByteSizePipe],
  exports: [ByteSizeComponent, ByteSizePipe]
})
export class ByteSizeModule { }
