<!-- Object key pattern selector Component -->
<div [formGroup]="objectKeyPattern">
  <!-- Object key type selection -->
  <h3 class="no-margin">{{'objectKeys' | translate}}</h3>
  <div class="object-keys-hint"
    [innerHtml]="'views.objectKeyPattern.hint' | translate">
  </div>

  <mat-form-field>
    <mat-label>{{'objectKeyPattern' | translate}}</mat-label>
    <mat-select
      panelClass="cog-mat-select-panel-lg"
      cogDataId="select-object-key-pattern"
      formControlName="keyType"
      (selectionChange)="transformObjectKeyPattern()">
      <mat-select-trigger *ngIf="objectKeyPattern.value?.keyType">
        {{objectKeyPattern.value.keyType | translate}}
      </mat-select-trigger>
      <ng-container *ngFor="let item of objectKeyTypes; index as i">
        <mat-option *ngIf="item.value !== 'ObjectId' || isObjectIdAllowed"
          cogDataId="option-{{item.name}}"
          inheritDataId="true" [value]="item.value">
          {{item.name | translate}}
          <ng-container *ngTemplateOutlet="metaTemplate; context:{$implicit: item}">
          </ng-container>
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>

  <!-- Flat key type selection -->
  <mat-form-field class="padding-left" *ngIf="objectKeyPattern.value.keyType === 'Flat'">
    <mat-label>{{'flatKeyType' | translate}}</mat-label>
    <mat-select
      panelClass="cog-mat-select-panel-lg"
      cogDataId="select-flat-key-type"
      formControlName="flatType"
      (selectionChange)="transformObjectKeyPattern()">
      <mat-select-trigger *ngIf="objectKeyPattern.value?.flatType">
        {{objectKeyPattern.value.flatType | translate}}
      </mat-select-trigger>
      <mat-option *ngFor="let item of flatKeyTypes; index as i"
        cogDataId="option-{{item.name}}"
        inheritDataId="true"
        [value]="item.value">
        {{item.name | translate}}
        <ng-container
          *ngTemplateOutlet="metaTemplate; context:{$implicit: item}">
        </ng-container>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Structured object key length selection section -->
  <mat-form-field class="padding-left"
    *ngIf="objectKeyPattern.value.keyType === 'Flat' && objectKeyPattern.value.flatType === 'Structured'">
    <mat-label>{{'structuredKeyLength' | translate}}</mat-label>
    <mat-select
      formControlName="keyLength"
      panelClass="cog-mat-select-panel-lg"
      cogDataId="select-flat-key-type"
      (selectionChange)="transformObjectKeyPattern()">
      <mat-select-trigger *ngIf="objectKeyPattern.value?.keyLength">
        {{objectKeyPattern.value.keyLength | translate}}
      </mat-select-trigger>
      <mat-option *ngFor="let item of structuredKeyLengths; index as i"
        cogDataId="option-{{item.name}}"
        inheritDataId="true"
        [value]="item.value">
        {{item.name | translate}}
        <ng-container
          *ngTemplateOutlet="metaTemplate; context:{$implicit: item}">
        </ng-container>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<ng-template #metaTemplate let-item>
  <ul class="no-margin">
    <li class="select-meta">
      {{item.descKey | translate}}
    </li>
  </ul>
</ng-template>
