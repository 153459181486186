// Component: Radial cPulse progress bar

;(function(angular, undefined) {
  'use strict';

  var componentName = 'cPulseRadial';
  var configOptions = {
    controller: 'cPulseRadialCtrl',
    // Attribute bindings
    bindings: {
      /**
       * Color string to force the progress bar color.
       *
       * Note: This supercedes class-based colors, like those applied with .ok,
       * .error, and .warn.
       *
       * @type   {string}
       */
      barColor: '@?',

      /**
       * Function used to determine when the internal polling task is completed.
       * Required if `attr.iterator` is defined.
       *
       * Polling terminates when the return value is true.
       *
       * @type   {function}
       */
      doneWhen: '&?',

      /**
       * Seconds frequency to poll. Default: 30.
       *
       * @type   {number}
       */
      interval: '@?',

      /**
       * Function to execute on each polling iteration. Shares no scope with
       * this component: executes in calling Controller's context.
       *
       * @type   {function}
       */
      iterator: '&?',

      /**
       * The label to display within the radius.
       *
       * @type   {string}
       */
      label: '@?',

      /**
       * Maximum number of retries allowed before terminating a failed poller.
       *
       * @type   {integer}
       */
      maxRetries: '@?',

      /**
       * Executed after each execution of iteratorFn, regardless of success or
       * error..
       *
       * Signature: onUpdate(resp);
       *
       * @type   {function}
       */
      onUpdate: '<?',

      /**
       * Bi-directional exposure point for percentage value. Can be between
       * 0-100, or undefined.
       *
       * @type   {number}
       */
      percentage: '=?',

      /**
       * Radius of the progress bar. Supercedes `attr.size`.
       *
       * @type   {number}
       */
      radius: '@?',

      /**
       * Predefined size of the progress bar. This is ignored when `attr.radius`
       * is a number. Can be one of [sm, md, lg]. Default: md.
       *
       * @type   {number}
       */
      size: '@?',
    },
  };

  /**
   * Default size string.
   *
   * @constant   {string}
   */
  var DEFAULT_SIZE = 'md';

  /**
   * Default radius when none is specified. Adjusted by a scale factor when used
   * in partnership with the size attributes. This is roughly equivalent to
   * pixel size, though unspecified, because <svg> treats it as px when
   * unitless.
   *
   * @constant   {integer}
   */
  var DEFAULT_RADIUS = 20;

  /**
   * Default polling interval.
   *
   * @constant   {number}
   */
  var DEFAULT_INTERVAL = 30;

  /**
   * Default maximum retries for polling before terminating.
   *
   * @constant   {number}
   */
  var DEFAULT_RETRIES = 3;

  /**
   * Reduce by the Golden Ratio.
   *
   * @constant   {number}
   */
  var DEFAULT_SCALE_FACTOR_SM = 0.618;

  /**
   * Increase by the Golden Ratio.
   *
   * @constant   {number}
   */
  var DEFAULT_SCALE_FACTOR_LG = 1.618;

  angular
    .module('C.pulseRadial', [])
    .controller('cPulseRadialCtrl', cPulseRadialCtrlFn)
    .component(componentName, configOptions);

  /**
   * $ngdoc Component
   * @name C.pulseRadial:cPulseRadial
   *
   * @propertyOf angular.Module
   * @description
   *   Radial progress monitor. Can be used manually, or by polling itself. See
   *   config for options.
   *
   * @example
       <!-- Complex config Medium progress -->
       <c-pulse-radial id="cpulse-md"
         percentage="$ctrl.percentage"
         data="$ctrl.data"
         interval="4"
         done-when="$ctrl.doneWhenFn"
         iterator="$ctrl.iteratorFn"
         ng-class="{
           'error': $ctrl.percentage < 30,
           'warn': $ctrl.percentage >= 30 && $ctrl.percentage < 50,
           'ok': $ctrl.percentage >= 90}">
       </c-pulse-radial>

       <!-- Moderate config Large progress -->
       <c-pulse-radial id="cpulse-lg"
         size="lg"
         reverse
         label="{{'anyLabel' | translate}}{{$ctrl.percentage}}"
         percentage="$ctrl.percentage"
         bar-color="purple">
       </c-pulse-radial>
   */
  function cPulseRadialCtrlFn($scope, $element, $compile, PollTaskStatus) {

    var ctrl = this;
    var poller;


    /**
     * Initialize this component.
     *
     * @method   init
     */
    ctrl.$onInit = function init() {
      initDefaults();
      initSVG();
    };

    /**
     * Init the SVG element of this component: pre-compile it, $compile it, and
     * insert it into the DOM.
     *
     * @method   initSVG
     */
    function initSVG() {
      $element.append(
        $compile(getRadialTemplate(ctrl))($scope)
      );
    }

    /**
     * Init default settings.
     *
     * @method   initDefaults
     */
    function initDefaults() {
      var scale = 1;

      // Set some defaults
      ctrl.size = ctrl.size || DEFAULT_SIZE;
      ctrl.onUpdate = angular.isFunction(ctrl.onUpdate) ?
        ctrl.onUpdate : angular.noop;

      // Detect and set the component's visual scale.
      switch (ctrl.size) {
        case 'sm':
          scale = DEFAULT_SCALE_FACTOR_SM;
          break;

        case 'lg':
          scale = DEFAULT_SCALE_FACTOR_LG;
          break;
      }

      ctrl.radius =
        (isNaN(ctrl.radius) ? scale * DEFAULT_RADIUS : +ctrl.radius);
      ctrl.diameter = ctrl.radius * 2;
      ctrl.circumference = 2 * Math.PI * ctrl.radius;
    }

    /**
     * $watch handler for the polling config properties.
     *
     * @method   pollConfigChangeHandler
     */
    function pollConfigChangeHandler() {
      // Exit early if no iterator Fn, nor doneWHen Fn is provided.
      if (!angular.isFunction(ctrl.iterator) ||
        !angular.isFunction(ctrl.doneWhen)) {
        return poller = undefined;
      }

      // Both are configured: Initialize the poller.
      initPoller();
    }

    /**
     * Initialize the poller.
     *
     * @method   initPoller
     */
    function initPoller() {
      var pollerOpts = {
        isDoneFn: ctrl.doneWhen,
        iteratorFn: proxyIterator,

        // This enables self-destruction along with $scope.
        scope: $scope,
      };

      // Only add these if they're defined, because angular.extend will take
      // these over the defaults
      if (ctrl.interval) {
        pollerOpts.interval = ctrl.interval;
      }
      if (ctrl.maxRetries) {
        pollerOpts.maxRetries = ctrl.maxRetries;
      }

      poller = PollTaskStatus.createPoller(pollerOpts);
    }

    /**
     * Proxy wrapper Fn for onUpdate to allow us to also execute some internal
     * routines.
     *
     * @method   proxyOnUpdate
     */
    function proxyOnUpdate() {
      var classMethod = ctrl.percentage === 100 ? 'addClass' : 'removeClass';
      $element[classMethod]('done');
      ctrl.onUpdate.apply(this, arguments);
    }

    /**
     * Wrapper Fn that exposes the data from the iterator Response to the
     * outside world.
     *
     * @method   proxyIterator
     * @return   {object}   Promise to resovle with the results of the iterator
     *                      function.
     */
    function proxyIterator() {
      return ctrl.iterator()
        .then(proxyOnUpdate, proxyOnUpdate);
    }

    // WATCHERS
    $scope.$watch('$ctrl.iterator', pollConfigChangeHandler);

  }

  /**
   * Gets the radial template.
   *
   * The reason this is not in a partial is because of several issues with
   * interpolating attributes in SVG elements:
   *
   * 1) The Browser is aggressive when evaluating some SVG attributes and barfs
   * before Angular can interpolate them. In some cases, using ngAttr solves the
   * issue, except for the camelCased attribute `svg[viewBox]`, so...
   *
   * 2) Angular provides a machanism in conjunction with ngAttr which _should_
   * handle this according to the official docs (using this exact scenario), but
   * it doesn't in fact work: ng-attr-view_Box should render camelCased
   * `viewBox`, in the output markup, but it doesn't and `viewbox` is invalid.
   *
   * Some pre-binding is performed here since they would be ::bound anyway to
   * save $digest cycles.
   *
   * Inspired by https://stackoverflow.com/a/27831234
   *
   * @method   getRadialTemplate
   * @param    {object}   data   The data to compile against ($ctrl).
   * @return   {string}   The radial template.
   */
  function getRadialTemplate(data) {
    var viewDiameter = data.diameter * 1.175;
    var viewRadius = viewDiameter / 2;
    var textVerticalOffsetMultiplier = data.size === 'sm' ? 1.17 : 1.14;

    return [
      '<div uib-tooltip="{{\'percentageComplete\' | translate:{percentFinished: $ctrl.percentage} }}"',
        'tooltip-enable="$ctrl.percentage">',
        '<svg xmlns="http://www.w3.org/2000/svg"',
          'xmlns:xlink="http://www.w3.org/1999/xlink"',
          'width="', viewDiameter, '"',
          'height="', viewDiameter, '"',
          'preserveAspectRatio="xMinYMin meet"',
          'viewBox="', [0, 0, viewDiameter, viewDiameter].join(' '), '"',
          'class="c-pulse-radial ', data.size, '">',

          // The track
          '<circle class="c-pulse-radial-track"',
            'cx="', viewRadius, '"',
            'cy="', viewRadius, '"',
            'r="', data.radius, '"/>',

          // The dynamic progress bar
          '<circle class="c-pulse-radial-progress percentage-{{$ctrl.percentage}}"',
            'cx="', viewRadius, '"',
            'cy="', viewRadius, '"',
            'r="', data.radius, '"',
            'style="stroke: {{$ctrl.barColor}};"',
            'stroke-dasharray="{{($ctrl.percentage/100) * ', data.circumference,
              '}} ', data.circumference, '"/>',

          // The label
          '<text x="', viewRadius, '" y="', viewRadius * textVerticalOffsetMultiplier, '"',
            'translate="{{$ctrl.label || $ctrl.percentage+\'%\'}}"></text>',
        '</svg>',
      '</div>',
    ].join('');
  }


})(angular);
