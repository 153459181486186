import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Share } from '@cohesity/api/v2';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'coh-view-mount-paths',
  templateUrl: './view-mount-paths.component.html',
  styleUrls: ['./view-mount-paths.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ViewMountPathsComponent {

  /**
   * SMB search event.
   */
  smbSearchUpdate = new Subject<string>();

  /**
   * Filtered list of SMB mount paths.
   */
  filteredSmbMountPaths: string[];

  constructor(
    public dialogRef: MatDialogRef<ViewMountPathsComponent>,
    @Inject(MAT_DIALOG_DATA) public share: Share,
  ) {
    if (this.share.smbMountPaths?.length) {
      this.filteredSmbMountPaths = [...this.share.smbMountPaths];
      this.smbSearchUpdate.pipe(
        debounceTime(400),
        distinctUntilChanged()
      ).subscribe(smbSearchValue => {
        smbSearchValue = smbSearchValue.trim();
        if (smbSearchValue) {
          this.filteredSmbMountPaths =
            this.share.smbMountPaths.filter(path => path.toLowerCase().includes(smbSearchValue.toLowerCase()));
        } else {
          this.filteredSmbMountPaths = [...this.share.smbMountPaths];
        }
      });
    }
  }
}
