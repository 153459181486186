import { ValidatorFn, UntypedFormControl } from '@angular/forms';

/**
 * Function to return an error object if a form control's value is not yes.
 *
 * @param control The form control.
 * @return null if a valid string, otherwise error object.
 */
export const YesToConfirmValidator: ValidatorFn = (control: UntypedFormControl) => {
  if (!control.value && control.value !== null && control.value !== '') {
    return null;
  }
  const isYesTyped = (control.value && control.value.toLowerCase() === 'yes');
  return isYesTyped ? null : {yesToConfirmError: true};
};
