// Component: c-reports-header

;(function(angular, undefined) {
  'use strict';

  /**
   * @ngdoc component
   * @name C.reportsHeader:cReportsHeader
   * @function
   *
   * @description
   * Reusable component that displays the header for each report
   *
   * @example
   * <c-reports-header
   *  reports-controls-config="dataTransferredToExternalTargetsControlsConfig"
   *  download-csv="downloadDataTransferredToExternalTargetsReport()"
   *  dataset="dataset">
   *  <h1>
   *    {{::'reportsControls.names.dataTransferredToExternalTargets' | translate}}
   *  </h1>
   * </c-reports-header>
   *
   */
  var options = {
    controller: 'ReportsHeaderCtrl',
    templateUrl: 'app/reports/components/c-reports-header/c-reports-header.html',
    bindings: {

      // callback function to download csv in report controller
      downloadCsv: '&?',

      // optional param some reports will pass in a custom dataset to the
      // email scheduler to be used in c-reports-controls
      dataset: '<?',

      // the reports controls config needed for the email scheduler modal
      reportsControlsConfig: '<?',
    },
    transclude: true,
  };

  angular
    .module('C.reportsHeader', [])
    .controller('ReportsHeaderCtrl', ReportsHeaderCtrlFn)
    .component('cReportsHeader', options);

  function ReportsHeaderCtrlFn(ReportsUtil, cReportsControlsUtil) {
    var $ctrl = this;

    $ctrl.openEmailScheduler = openEmailScheduler;
    $ctrl.downloadReportCsv = downloadReportCsv;

    /**
     * open the email scheduler modal
     */
    function openEmailScheduler() {
      ReportsUtil.openEmailScheduler(
        cReportsControlsUtil.filters,
        angular.copy($ctrl.reportsControlsConfig),
        $ctrl.dataset,

        // launch create mode.
        false
      );
    }

    /**
     * download csv
     */
    function downloadReportCsv() {
      $ctrl.downloadCsv();
    }
  }
})(angular);
