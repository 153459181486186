/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SslCertificateParams } from '../models/ssl-certificate-params';
@Injectable({
  providedIn: 'root',
})
class CertificateService extends __BaseService {
  static readonly GetHeliosSslCertificatePath = '/mcm/ssl-certificate';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get the Helios SSL Certificate.
   *
   * Get the Helios SSL certificate.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetHeliosSslCertificateResponse(regionId?: string): __Observable<__StrictHttpResponse<SslCertificateParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/ssl-certificate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SslCertificateParams>;
      })
    );
  }
  /**
   * Get the Helios SSL Certificate.
   *
   * Get the Helios SSL certificate.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetHeliosSslCertificate(regionId?: string): __Observable<SslCertificateParams> {
    return this.GetHeliosSslCertificateResponse(regionId).pipe(
      __map(_r => _r.body as SslCertificateParams)
    );
  }
}

module CertificateService {
}

export { CertificateService }
