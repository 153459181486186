import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';

import {
  ListItemActionsComponent,
  ListItemColumnComponent,
  ListItemColumnContentComponent,
  ListItemColumnLayoutComponent,
  ListWithFilterComponent,
} from './components';

const COMPONENTS = [
  ListItemActionsComponent,
  ListItemColumnComponent,
  ListItemColumnContentComponent,
  ListItemColumnLayoutComponent,
  ListWithFilterComponent,
];

@NgModule({
  imports: [
    CommonModule,
    CohesityHelixModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    TranslateModule.forChild(),
    UIRouterModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class SharedListModule {}
