/* tslint:disable */

/**
 * Specifies tag sub categories.
 */
export enum TagSubCategory {
  Anomalies = 'Anomalies',
  Threats = 'Threats',
  Classification = 'Classification',
  Dspm = 'Dspm'
}
