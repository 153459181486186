import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DatePipesModule } from '../date-pipe';
import { ActivityTimelineComponent } from './activity-timeline.component';

@NgModule({
  imports: [CommonModule, DatePipesModule],
  declarations: [ActivityTimelineComponent],
  exports: [ActivityTimelineComponent],
})
export class ActivityTimelineModule {}
