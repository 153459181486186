import { NgModule } from '@angular/core';

import { NaLabelPipe } from './na-label.pipe';

/**
 * App Module. This is the root angular module.
 */
@NgModule({
  declarations: [NaLabelPipe],
  exports: [NaLabelPipe],
  providers: [NaLabelPipe],
})
export class NaLabelModule { }
