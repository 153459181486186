<h2 mat-dialog-title>
  {{ data.title | translate }}
</h2>

<mat-dialog-content>
  <p>{{ data.message | translate }}</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button type="button" mat-button cogCancelButton cogDataId="cancel-confirm-action" (click)="onCancelClick()">
    {{ 'cancel' | translate }}
  </button>

  <button
    type="submit"
    cogDataId="confirm-action"
    form="confirm-action-form"
    mat-flat-button
    cogSubmitButton
    [disabled]="isSubmitting$ | async"
    [loading]="isSubmitting$ | async"
    (click)="onSubmit()">
    {{ (data.successButtonText ?? 'submit') | translate }}
  </button>
</mat-dialog-actions>
