// View Box Service

;(function(angular, undefined) {
  'use strict';

  angular.module('C')
    .service('ViewBoxCacheService', ViewBoxCacheServiceFn)
    .service('ViewBoxService', ViewBoxServiceFn);

  // holds the cached viewboxes hash map used for quick resolution of viewbox ID
  // to its details.
  var cachedViewBoxes = {};

  function ViewBoxCacheServiceFn() {
    var out = {};

    // Exposed hash of cached View Boxes (Storage Domains)
    Object.defineProperty(out, 'viewBoxes', {
      get: function getCacheProperty() {
        return cachedViewBoxes;
      },
      set: function setCacheProperty(newValue) {
        cachedViewBoxes = newValue;
        return cachedViewBoxes;
      },
    });

    return out;
  }

  function ViewBoxServiceFn($http, $q, API, cUtils, evalAJAX, SlideModalService,
    ViewBoxServiceFormatter, TenantService, ClusterService, FEATURE_FLAGS, ngDialogService, NgStorageDomainServiceApi) {

    var quotaPolicyDefaults = {
      // 18 GiB
      alertLimitBytes: 19327352832,

      // 20 GiB
      hardLimitBytes: 21474836480,
    };

    var service = {
      // Exposed methods
      createViewBox: createViewBox,
      deleteViewBox: deleteViewBox,
      editViewBoxModal: editViewBoxModal,
      getOwnViewBoxes: getOwnViewBoxes,
      getQuotaPolicyDefaults: getQuotaPolicyDefaults,
      getRemoteViewBoxes: getRemoteViewBoxes,
      getViewBox: getViewBox,
      getViewBoxes: getViewBoxes,
      getViewBoxesByPartitionId: getViewBoxesByPartitionId,
      newViewBoxModal: newViewBoxModal,
      updateStorageDomains: updateStorageDomains,
      updateViewBox: updateViewBox,
      transformViewBoxToStorageDomain: ViewBoxServiceFormatter.tranformV1ToV2,
    };

    ////////////////////////////////////////
    // PUBLIC METHODS
    ////////////////////////////////////////

    /**
     * Method to Create a new view box params: name, clusterPartionName,
     *
     * @param      {Object}   viewBox  the View Box
     * @return     {Promise}  to resolve request to create a View Box
     */
    function createViewBox(viewBox) {
      return NgStorageDomainServiceApi.CreateStorageDomain({
        body: ViewBoxServiceFormatter.untransformViewBox(viewBox)
      })
        .toPromise()
        .then(handleIndividualViewBoxResponse);
    }

    /**
     * Method to update an existing view box
     *
     * @param      {Object}   viewBox  the View Box
     * @return     {Promise}  to resolve request to update the View Box
     */
    function updateViewBox(viewBox) {
      return NgStorageDomainServiceApi.UpdateStorageDomain({
        id: viewBox.id,
        body: ViewBoxServiceFormatter.untransformViewBox(viewBox)
      })
        .toPromise()
        .then(handleIndividualViewBoxResponse);
    }

    /**
     * submits a request to the API to delete a View Box
     *
     * @param      {integer}  id      The View Box id
     * @return     {promise}  promise to resolve API submission to delete
     *                        the View Box
     */
    function deleteViewBox(id) { // DeleteStorageDomain
      return NgStorageDomainServiceApi.DeleteStorageDomain({
        id,
      })
    }

    /**
     * submits a request to the API for a particular View Box
     *
     * @param      {integer}  id      The View Box id
     * @param      {object} [params] optional query params
     * @return     {promise}  promise to resolve API request. Resolves with
     *                        the requested View Box. Rejects with raw
     *                        server response.
     */
    function getViewBox(id, params) {
      return NgStorageDomainServiceApi.GetStorageDomainById({
        ...(params || {}),
        id,
      })
        .toPromise()
        .then(handleIndividualViewBoxResponse);
    }

    /**
     * shared function for handling an API response for operations that are
     * known to return a single View Box.
     *
     * @param      {object}            resp    raw server response
     * @return     {object|undefined}  the View Box object or undefined
     */
    function handleIndividualViewBoxResponse(resp) {
      var viewBox = resp ?
        ViewBoxServiceFormatter.transformViewBox(resp) : undefined;

      // ensure the View Box is up-to-date in the hash
      angular.extend(cachedViewBoxes, createViewBoxHash(viewBox));

      return viewBox;
    }

    /**
     * Get list of viewboxes owned by logged in user organization and will
     * return all viewboxes when viewbox sharing is enabled with multi-tenancy
     * so that SP can also share the viewboxes used by multiple tenants to
     * achieve higher data de-duplication.
     *
     * @method     getOwnViewBoxes
     * @param      {Object}  params   The request params.
     * @return     {Object}  Promise resolved with list of viewboxes else
     *                       rejected with error.
     */
    function getOwnViewBoxes(params) {
      params = TenantService.extendWithEntityOwnerParams(params);

      // when viewbox sharing is enabled with multi-tenancy then SP can share
      // viewboxes owned by multiple tenants.
      params.includeTenants = ClusterService.clusterInfo.tenantViewboxSharingEnabled ?
        true : params.allUnderHierarchy;

      return getViewBoxes(params);
    }

    /**
     * intefaces the API to return a list of ViewBoxes based
     * on provided parameters (or all ViewBoxes if no params provided)
     * @param  {Object} opts for filtering viewboxes
     * @return {Promise}     promise to resolve request for viewboxes
     *                       on success, resolves with array of viewboxes
     *                       on failure, rejects with server response obj
     */
    function getViewBoxes(opts) {
      opts = opts || {};
      cUtils.selfOrDefault(opts, 'includeTenants', true);
      return NgStorageDomainServiceApi.GetStorageDomains(opts)
        .toPromise()
        .then(
        function cacheViewBoxes(resp) {
          var viewBoxes = (resp.storageDomains || []).map(ViewBoxServiceFormatter.transformViewBox);

          // Direct Archive Enabled viewBoxes are opaque to end user.
          // They are only meant for internal use.
          if (!opts._includeDirectArchiveEnabled) {
            viewBoxes = _excludeDirectArchiveViewboxes(viewBoxes);
          }

          // resolved tenantInfo will be found under viewbox._tenants list.
          // TODO(veetesh): remove traces of resolveTenantDetails when backend
          // start accepting includeTenantInfo param and send tenantInfo in
          // place of tenantId https://cohesity.atlassian.net/browse/ENG-49913
          if (opts._includeTenantInfo) {
            return TenantService.resolveTenantDetails(viewBoxes, 'tenantIds');
          }

          cachedViewBoxes = createViewBoxHash(viewBoxes);

          return viewBoxes;
        }
      );
    }

    /**
     * Exclude DirectArchive Viewboxes from list of given viewboxes
     * @method  _excludeDirectArchiveViewboxes
     *
     * @param   {Object[]}  viewBoxes Array of viewBox objects
     * @return  {Object[]}  Array of viewBox objects with directArchiveViewboxes
     *                      filtered out
     */
    function _excludeDirectArchiveViewboxes(viewBoxes) {
      return _.reject(viewBoxes, ['directArchiveEnabled', true]);
    }

    /**
     * convenience function for initiating the New View Box cSlideModal,
     * allowing implementation without needing to know the config details
     *
     * @param   {Object}  storagePolicy   viewBox default storagePolicy details in order to
     *                                    prepopulate during new viewbox creation and
     *                                    default value is null.
     * @return  {Promise}  to resolve the cSlideModal
     */
    function newViewBoxModal(storagePolicy = null) {
      return SlideModalService.newModal({
        templateUrl: 'app/platform/viewboxes/modify/modify.html',
        controller: 'viewBoxCreateModalController',
        size: 'xl',
        // prevent escape key from closing modal so confirmation
        // page escaping doesn't reject modal instance
        keyboard: false,
        // add 'c-slide-modal-override-stack-order' class for storage domain
        // creation dialog since this dialog should typically opened on top of
        // an existing NG modal
        windowClass: 'c-slide-modal-window c-slide-modal-override-stack-order',
        resolve: {
          storagePolicy: function storagePolicyResolve() {
            return storagePolicy;
          }
        }
      });
    }

    /**
     * convenience function for initiating the Edit View Box cSlideModal,
     * allowing implementation without needing to know the config details
     *
     * @param      {integer}  viewBoxId  of the View Box to edit
     * @return     {Promise}  to resolve the cSlideModal
     */
    function editViewBoxModal(viewBoxId) {
      return SlideModalService.newModal({
        templateUrl: 'app/platform/viewboxes/modify/modify.html',
        controller: 'viewBoxEditModalController',
        size: 'xl',
        // prevent escape key from closing modal so confirmation
        // page escaping doesn't reject modal instance
        keyboard: false,
        resolve: {
          viewBoxId: function viewBoxIdResolve() {
            return viewBoxId;
          }
        }
      });
    }

    /**
     * Gets the View Boxes inside a given partition.
     *
     * @method   getViewBoxesByPartitionId
     * @param    {Number}   clusterPartitionIds   The partition identifier
     * @return   {Object}   promise to resolve with View Box list else rejected.
     */
    function getViewBoxesByPartitionId(clusterPartitionIds) {
      return NgStorageDomainServiceApi.GetStorageDomains({ includeTenants: true, clusterPartitionIds })
        .toPromise()
        .then(function gotViewBoxesWithPartitionId(resp) {
          var viewBoxes = (resp.storageDomains || []).map(ViewBoxServiceFormatter.transformViewBox);
          return _excludeDirectArchiveViewboxes(viewBoxes);
      });
    }

    /**
     * Gets the remote View Boxes list.
     *
     * @method   getRemoteViewBoxes
     * @param    {Object|String}   cluster/clusterId   The new remote cluster or
     *                                                 remote cluster id.
     * @return   {Object}   promise to resolve with remote View Box list else
     *                      rejected with error.
     */
    function getRemoteViewBoxes(cluster) {
      var clusterId;
      var headers = {};
      var params = { includeTenants: true };

      if (angular.isObject(cluster)) {
        // cluster id Zero indicates backend to go and fetch remote cluster view
        // boxes list by using credentials given in header.
        clusterId = 0;
        angular.extend(headers, {
          hostname: cluster.remoteIps[0],
          username: cluster.userName,
          password: cluster.password,
        });
      } else {
        clusterId = cluster;
      }

      return $http({
        method: 'get',
        url: API.public('remoteClusterApi', clusterId, encodeURIComponent('/v2/storage-domains')),
        headers: headers,
        params: params,
      }).then(
        function getRemoteViewBoxesSuccess(resp) {
          var viewBoxes = (resp.storageDomains || []).map(ViewBoxServiceFormatter.transformViewBox);
          return _excludeDirectArchiveViewboxes(viewBoxes);
        }
      );
    }

    /**
     * provides a copy of quotaPolicyDefaults{}
     *
     * @return     {object}  The quotaPolicy defaults
     */
    function getQuotaPolicyDefaults() {
      return cUtils.simpleCopy(quotaPolicyDefaults);
    }

    ////////////////////////////////////////
    // PRIVATE METHODS
    ////////////////////////////////////////

    /**
     * Creates a view boxes hash from view boxes list with key as view box id.
     *
     * @method   createViewBoxHash
     * @param    {Array}    viewBoxes   The view boxes
     * @return   {Object}   The view boxes hash else empty object.
     */
    function createViewBoxHash(viewBoxes) {
      var out = {};
      if (!viewBoxes) {
        return out;
      }
      viewBoxes = [].concat(viewBoxes);
      viewBoxes.forEach(function eachViewBox(vb, ii) {
        out[vb.id] = vb;
      });
      return out;
    }

    /**
     * Issues multiple update Storage Domains API requests when updating the
     * auth provider for all at once.
     *
     * @method   updateStorageDomains
     * @param    {Object}   authdata    object containing auth provider info and
     *                                  affected Storage Domains
     * @param    {Boolean}  inProgress  boolean gate indicating whether the
     *                                  operation is in progress
     */
    function updateStorageDomains(authdata, inProgress) {
      var promises = [];

      inProgress = true;

      // Set the preferred auth provider for each Storage Domain
      authdata.storageDomains.forEach(function(storageDomain) {
        if (authdata.selection === 'ad') {
          storageDomain.ldapProviderId = undefined;
        } else {
          storageDomain.adDomainName = undefined;
        }

        promises.push(updateViewBox(storageDomain));
      });

      $q.all(promises).catch(evalAJAX.errorMessage).finally(
        function updateStorageDomainsFinally() {
          inProgress = false;
        },
        evalAJAX.errorMessage
      );
    }

    return service;
  }

})(angular);
