import { Component, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ArchivalExternalTarget, Vault } from '@cohesity/api/v1';
import { FortknoxStorageClass } from '@cohesity/data-govern/security-center';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { ItemPickerFormControl } from '@cohesity/shared-forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

import { ClusterTypeMap, keyAcccessorMapForTargets, StorageType } from '../../constants';
import { DecoratedExternalTargetSelector } from './external-target-selector.model';
import { ExternalTargetSelectorService, RestrictType } from './external-target-selector.service';

/**
 * Types for context.
 */
type contextTypes = 'archive' | 'viewbox';

/**
 * Component for selecting external targets for archival during policy creation.
 * All instances of this component share the same underlying service and selection
 * model. This saves http calls, and allows the component to automatically disabled
 * items that are selected by another instance of the component.
 *
 * @example
 * <coh-external-target-selector class="col-xs-6" formControlName="target"
 *  restrictToUsageType="kArchival">
 * </coh-external-target-selector>
 */
@Component({
  selector: 'coh-external-target-selector',
  templateUrl: './external-target-selector.component.html',
  styleUrls: ['./external-target-selector.component.scss'],
})
export class ExternalTargetSelectorComponent extends ItemPickerFormControl<ArchivalExternalTarget>
  implements OnInit, OnDestroy {

  /**
   * Specifies the unique id of component.
   */
  @Input() idPrefix = '';

  /**
   * Specifies the label key for the selector.
   * Default to External Target.
   */
  @Input() label = this.translate.instant('externalTarget');

  /**
   * kValue of Target usage type which is allowed.
   */
  @Input() restrictToUsageType: RestrictType;

  /**
   * Array of kValue of external target types which need to be excluded.
   */
  @Input() excludedTargetTypes = [];

  /**
   * Array of kValue of external target tier types which need to be excluded.
   */
  @Input() excludedTierTypes = [];

  /**
   * Custom target in addition to the external target list from API.
   */
  @Input() customTarget: Vault;

  /**
   * Show/Hide register external target option in the dropdown.
   */
  @Input() showExternalTargetOption = true;

  /**
   * Checks whether the cloud edition is enabled or not.
   */
  @Input() cloudEditionEnabled = false;

  /**
   * Specifies the context in which the selector is used for.
   */
  @Input() context: contextTypes;

  /**
   * Indicate whether edit is allowed or not.
   */
  @Input() readonly = false;

  /**
   * List of vault Ids associated with a storage domain on an NGCE cluster.
   */
  @Input() primaryVaultIds = [];

  /**
   * List of vault Ids associated with a storage domain on an NGCE cluster.
   */
  @Input() isPrimaryCopy = false;

  /**
   * A observable stream of available vaults that can be used for external targets.
   */
  public vaults$: Observable<any[]>;

  /**
   * Rpaas storage class API mapping to UI values.
   */
  readonly rpaasStorageClass: { [storageClass in FortknoxStorageClass]?: string } = {
    AmazonS3Glacier: 'externalTargets.storageClass.Rpaas.AmazonS3Glacier',
    AmazonS3StandardIA: 'externalTargets.storageClass.Rpaas.AmazonS3StandardIA',
    AzureArchiveBlob: 'externalTargets.storageClass.Rpaas.AzureArchiveBlob',
    AzureCoolBlob: 'externalTargets.storageClass.Rpaas.AzureCoolBlob',
  };

  /**
   * Show storage class label in policies list.
   */
  readonly showStorageClass = flagEnabled(this.irisContext.irisContext, 'rpaasStorageClass');

  /**
   * Initializes the component
   *
   * @param   ngControl       Instance of component's ngControl.
   * @param   targetService   The angularjs external target selector service
   */
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private targetService: ExternalTargetSelectorService,
    private translate: TranslateService,
    private irisContext: IrisContextService,
  ) {
    super();

    // Set the value accessor directly.
    // This is equivalent to injecting NG_VALUE_ACCESSOR to the provider.
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  /**
   *  Returns true if the cluster is NGCE and there is no context
   */
  get showNGCEVault(): boolean {
    return this.cloudEditionEnabled && !this.context;
  }

  /**
   *  Returns true if the cluster is NGCE and the context is archive
   */
  get showStandardVault(): boolean {
    return this.cloudEditionEnabled && this.context === 'archive';
  }

  /**
   *  Returns true if the cluster is NGCE and the context is viewbox
   */
  get showVaultForNGCEViewBox(): boolean {
    return this.cloudEditionEnabled && this.context === 'viewbox' &&
      flagEnabled(this.irisContext.irisContext, 'ngArchivalForNgceEnabled');
  }

  /**
   * fetch the storage domain associated with the vault to show in option label
   *
   * @param vault in context of select option
   * @returns storage domain name associated with the vault
   */
  vaultStorageDomainName(vault: any): string {
    if (this.targetService.isNGVaultsEnabled()) {
      // safe to assume there would be cloud domains in the vault
      // because all vaults with 0 cloud domains would have been filtered already
      // before showing in the select
      const item = vault as DecoratedExternalTargetSelector;
      return item?.cloudDomains[0]?.storageDomainName;
    }

    return vault?.cloudDomainList[0]?.viewBoxName;
  }

  /**
   * computes and translates the subtext to be shown in select option
   *
   * @param vault in context of select option
   * @param isNGCEOption viewing in NGCE cluster
   * @returns subtext to show in the selcet option
   */
  vaultSubText(vault: any, isNGCEOption: boolean = false): string {
    if (!isNGCEOption) {

      if (this.targetService.isNGVaultsEnabled()) {
        const storageClass = vault._name ? this.translate.instant(
          `enum.externalTargets.storageClass.${vault._name}`
        ) : '';
        const storageType = vault._vaultName;
        // template for NG External targets vault subtext -
        // <STORAGE_TYPE> <STORAGE_CLASS>
        return this.translate.instant('externalTarget.displayNameNg', {
          vaultName: storageType,
          name: storageClass,
          type: ''
        });
      }

      return this.translate.instant('externalTarget.displayNameNg', {
        vaultName: vault._vaultName,
        name: vault._name || '',
        type: vault._typeKey || ''
      });
    } else {
      // template for NG cloud edition -
      // <STORAGE_TYPE> <STORAGE_CLASS> <EXTERNAL_TARGET_NAME>
      return this.translate.instant('externalTarget.displayNameNgCe', {
        vaultName: vault._vaultName,
        name: this.translate.instant(vault._name || vault._vaultName || ''),
        targetName: vault.name || ''
      });
    }
  }

  /**
   * Update the service selection model whenever the component selection changes
   * This can track selections across multiple instances of the component.
   *
   * @param   item   The selected target.
   */
  onSelect(item: ArchivalExternalTarget) {
    if (!item) {
      return;
    }
    if (this.value) {
      this.targetService.setSelection(this.value.vaultId, false);
    }
    this.value = item;
    this.targetService.setSelection(item.vaultId, true);
  }

  /**
   * Handle the add new button. This calls the service to register a new target
   * and then updates the selection if done successfully. If canceled, this will
   * not do anything.
   */
  onAdd() {

    // Reset value before opening register new model.
    if (this.value) {
      this.targetService.setSelection(this.value.vaultId, false);
      this.value = undefined;
    }

    this.targetService.registerNew(this.restrictToUsageType, this.context).
      pipe(take(1)).subscribe(target => {
      if (target?._target) {
        this.onSelect(target._target);
      }
    });
  }

  /**
   * Checks if a given target is selected by this or another instance of the
   * component.
   *
   * @param   item   The value to check.
   * @return  Whether the item is selected or not.
   */
  isSelected(item: ArchivalExternalTarget): boolean {
    return this.targetService.isSelected(item.vaultId);
  }

  /**
   * Triggers when mat select panel is open/closed.
   * Manually reset value if value is undefined/empty.
   *
   * @param  isOpen  Whether mat-select panel is open.
   */
  openedChange(isOpen: boolean) {
    this.propagateOnTouch();
    if (!this.ngControl.value && !isOpen) {
      this.value = undefined;
    }
  }

  /**
   * Whenever the component is initialized, refresh the list of targets.
   */
  ngOnInit() {
    const { rpaasVaults$, vaults$ } = this.targetService;
    // Get the cluster type.
    const clusterType = this.irisContext.irisContext.basicClusterInfo.clusterType;

    // Listen for changes to the vaults and update the selected value if necessary
    this.vaults$ = (this.restrictToUsageType === 'Rpaas' ? rpaasVaults$ : vaults$).pipe(
      // Only emit when there is a valid value
      filter(vaults => !!vaults),

      map(vaults => {
        // Filter out types that aren't part of the selected filter
        let filteredVaults = vaults.filter(vault =>
          (!this.restrictToUsageType ||
            // this check is not required in case ngVaults is enabled as data is restricted via API itself
            (this.targetService.isNGVaultsEnabled() || vault.usageType === this.restrictToUsageType)
          ) &&
          !this.excludedTargetTypes.find(
            (vaultEl) => {
              // if ngVaults is enabled, check for presence of value of
              // aliasV2Key for proper check on external target type
              // for all other cases, rely on v1 keys
              if (this.targetService.isNGVaultsEnabled() && vaultEl.aliasV2Key) {
                return vaultEl.aliasV2Key === vault.externalTargetType;
              }
              return vaultEl.key === vault.externalTargetType;
            }
          ) &&
          !this.excludedTierTypes.find(
            (vaultEl) => {
              // if ngVaults is enabled, check for presence of value of
              // aliasV2Key for proper check on external target storage
              // class/tier value for all other cases, rely on v1 keys
              if (this.targetService.isNGVaultsEnabled() && vaultEl.aliasV2Key) {
                return vaultEl.aliasV2Key === vault._tierData?.tier;
              }
              return vaultEl.key === vault._tierData?.tier;
            }
          )
        );

        // utility to determine if there are storage domains associated with an external target
        const hasCloudDomainAssociated = (vault): boolean => {
          if (this.targetService.isNGVaultsEnabled()) {
            return vault?.cloudDomains?.length;
          }
          return vault?.cloudDomainList?.length;
        };
        // Filters specific to NGCE clusters
        if (this.showNGCEVault) {
          // Filter and get only the vaults that are linked with storage domain.
          // Target for primary copy should be local to the cluster.
          filteredVaults = filteredVaults.filter(vault => this.primaryVaultIds?.includes(vault.id));
        } else if (this.showStandardVault) {
          // Filter and get the vaults that are not primary vaults.
          filteredVaults = filteredVaults.filter(vault => !this.primaryVaultIds?.includes(vault.id));
        } else if (this.showVaultForNGCEViewBox) {
          // Filter out the vaults that are not linked with a storage domain and local to the cluster.
          // Exception for IBM cloud edition alone where wer do not restrict targets local to the cluster.
          filteredVaults = filteredVaults.filter(vault => clusterType === ClusterTypeMap.kIBMCloud ?
            !hasCloudDomainAssociated(vault) : !hasCloudDomainAssociated(vault) &&
              vault.externalTargetType === this.getLocalTargetForNGCE() && vault?.archivalParams?.
                [`${keyAcccessorMapForTargets[vault?.archivalParams?.storageType]}Params`]?.
                isForeverIncrementalArchivalEnabled);
        }

        // Remove Object Lock enabled targets from Primary copy list.
        if (this.isPrimaryCopy &&
          !flagEnabled(this.irisContext.irisContext, 'enableObjectLockForNgceCADv2')) {
          filteredVaults = filteredVaults.filter(vault => !vault?.enableObjectLock);
        }

        // If there is a custom target, add it to the list of vaults.
        if (this.customTarget) {
          const decoratedTarget = this.targetService.decorateVault(this.customTarget);
          if (!filteredVaults.find(vault => vault._target.vaultId === decoratedTarget._target.vaultId)) {
            filteredVaults.unshift(decoratedTarget);
          }
        }

        return filteredVaults;
      }),

      // Each time the list is updated, update the selected object to match it.
      tap(vaults => {
        if (this.value) {
          const selectedVault = vaults.find(target => target._target.vaultId === this.value.vaultId);
          this.value = selectedVault ? selectedVault._target : undefined;
          this.ngControl.control.markAsPristine();
        }
      })
    );

    this.targetService.refreshTargetList(this.restrictToUsageType);
  }

  /**
   * Function to get the local target for an NGCE cluster
   * based on cluster type.
   *
   * @returns Storage type as string.
   */
  getLocalTargetForNGCE(): StorageType {
    const clusterType = this.irisContext.irisContext.basicClusterInfo.clusterType;
    switch (clusterType) {
      case 'kAmazonCloud':
        return StorageType.AWS;
      case 'kGoogleCloud':
        return StorageType.GCP;
      case 'kMicrosoftCloud':
        return StorageType.AZURE;
    }
  }

  /**
   * When the component is destroyed, make sure to remove the value from the
   * selection model.
   */
  ngOnDestroy() {
    if (this.value) {
      this.targetService.setSelection(this.value.vaultId, false);
    }
  }
}
