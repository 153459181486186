import { Environment, EnvItems, NimbleEntities, PureEntities } from '@cohesity/iris-shared-constants';

export const storageArraySources: EnvItems[] = [
  {
    environment: Environment.kPure,
    entity: PureEntities.kStorageArray,
  },
  {
    environment: Environment.kNimble,
    entity: NimbleEntities.kStorageArray,
  },
];
