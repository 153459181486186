import { addQueryParams, joinUrl } from '../../util/urls';
import { NavItem } from './nav-item.model';

/**
 * The nav active status updating function.
 */
export type UpdateNavActiveStatus = (navList: NavItem[], appBasePath?: string) => NavItem[];

/**
 * Indicates whether the current nav is active or not.
 *
 * @param navUrl The list of the nav list items.
 * @param appBasePath app base href that will be prepended to the navURL.
 * @returns returns true when nav url is includes in the current browser URL.
 */
export const isNavActive = (navUrl: string, appBasePath: string) => {
  const pathSegment = `/${joinUrl([appBasePath, new URL(navUrl, window.location.origin).pathname])}`;
  return window.location.pathname.startsWith(pathSegment);
};

/**
 * It returns the navList with the updated values for isActive and isOpen property on the basis of current route
 *
 * @param navList last updated navItem list
 * @param appBasePath optional app base href that will be prepended to the navURL.
 * @returns returns the updated navItem list which is used in the sidebar
 */
export const updateNavActiveStatus: UpdateNavActiveStatus = (navList, appBasePath = '') => navList.map(navItem => {
  let hasActiveChild = false;
  const subNavList = (navItem.subNavList || []).map(childItem => {
    const isActiveChild = !childItem.disabled && isNavActive(childItem.href, appBasePath);

    if (isActiveChild) {
      hasActiveChild = true;
    }
    return { ...childItem, isActive: isActiveChild };
  });

  return {
    ...navItem,
    subNavList,
    isOpen: hasActiveChild,
    isActive: !navItem.disabled && navItem.href && isNavActive(navItem.href, appBasePath),
  };
});

/**
 * Update the nav list and its childrens by adding the app's base href and options.
 *
 * @param navList The list of nav list items.
 * @param baseHref The apps base href value.
 * @returns returns the updated navItem list.
 */
export const updateNavBaseHref = (navList: NavItem[], baseHref: string): NavItem[] => (navList || []).map(nav => {
  let newNav = { ...nav };

  if (newNav.href) {
    newNav = {
      ...newNav,
      href: isValidUrl(nav.href) ? nav.href : `/${addQueryParams([baseHref, nav.href], nav.stateParams ?? null)}`,
      options: { ...newNav.options, hrefTarget: newNav.options?.hrefTarget ?? '_self' },
    };
  }

  if (newNav.subNavList?.length) {
    newNav = {
      ...newNav,
      subNavList: updateNavBaseHref(nav.subNavList, baseHref),
    };
  }

  return newNav;
});

/**
 * Checks if the given URL is valid or not
 *
 * @param url The URL to validate
 * @returns True if url is valid. False otherwise
 */
export const isValidUrl = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
};
