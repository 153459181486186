<!-- Principal dialog Component -->
<div *ngIf="data" class="principal-dialog">
  <h1 mat-dialog-title>
    {{data?.title | translate}}
  </h1>
  <cog-banner status="info" class="margin-bottom" *ngIf="data?.subTitle">
    {{data?.subTitle | translate}}
  </cog-banner>

  <form [formGroup]="permissionForm" cohValidateOnSubmit (validatedSubmit)="onSubmit()">
    <mat-dialog-content>
      <coh-user-at-domain-picker class="flex-row margin-bottom-sm"
        cogDataId="smb-permissions"
        [principalsHash]="data?.domainPrincipalsHash"
        [domains]="data?.visibleDomains"
        (sidChange)="permissionForm.controls.sid.setValue($event)">
      </coh-user-at-domain-picker>
      <mat-error class="duplicate-entry" *ngIf="permissionForm.controls.sid.hasError('duplicate')">
        {{ 'errors.duplicateEntry' | translate }}
      </mat-error>
      <div class="margin-bottom">
        <mat-label>{{'type' | translate}}</mat-label>
        <mat-radio-group class="flex-row padding-top-sm" formControlName="type">
          <mat-radio-button *ngFor="let item of types; let first = first;"
            [value]="item.value"
            cogDataId="permission-type-{{item.value}}"
            [class.padding-left]="!first">
            {{item.label | translate}}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <mat-form-field *ngIf="!data.shares && !data.snapshots" class="mode-selection-field">
        <mat-label>{{'appliesTo' | translate}}</mat-label>
        <mat-select formControlName="mode"
          panelClass="cog-mat-select-panel-lg"
          cogDataId="select-permission-mode"
          (selectionChange)="validateNewEntry()">
          <mat-option *ngFor="let item of smbPermissions.modes; index as i"
            cogDataId="mode-{{item.value}}"
            [value]="item.value">
            {{item.label | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="view-form-field" *ngIf="!data.snapshots">
        <mat-label>{{'permission' | translate}}</mat-label>
        <mat-radio-group class="flex-row padding-top-sm" formControlName="access">
          <mat-radio-button *ngFor="let item of accessOptions; let first = first;"
            [value]="item.value"
            cogDataId="permission-{{item.value}}"
            [class.padding-left]="!first">
            {{item.label | translate}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button
        cogCancelButton
        type="button"
        mat-button
        color="primary"
        cogDataId="new-permission-cancel-btn"
        mat-dialog-close>
        {{'cancel' | translate}}
      </button>
      <button
        cogSubmitButton
        type="submit"
        mat-flat-button
        color="primary"
        cogDataId="new-permission-add-btn">
        {{'add' | translate}}
      </button>
    </mat-dialog-actions>
  </form>
</div>
