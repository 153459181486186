/**
 * UserType to be used while creating a new User
 */
export enum UserType {
  organizationUser = 'organizationUser',
  heliosUser = 'heliosUser',
}

/**
 * Domain for helios User
 */
export enum HeliosUserDomain {
  HeliosSaas = 'Helios',
}
