import { Injectable } from '@angular/core';
import { BaseEntitlementBannerConfig, EntitlementKeys, isDmsScope, IrisContextService } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class DataProtectEntitlementBanner extends BaseEntitlementBannerConfig {

  /**
   * The unique id for this entitlement banner
   */
  id = 'dataprotect-entitlement-banner';

  /**
   * The key that identifieds the entitlement object
   * within the subscription data returned by the api
   */
  key = EntitlementKeys.DataProtect;

  /**
   * the scope that this banner should be available in
   */
  isScope = isDmsScope;

  constructor(protected irisCtx: IrisContextService, protected translateService: TranslateService) {
    super(irisCtx, translateService);
  }
}
