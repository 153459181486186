import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const simulationAccess = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.simulationEnabled &&
  !ctx.isHeliosTenantUser &&
  ctx.isHelios;

/**
 * Summary dashboard is single cluster state.
 * This tab should only be visible in all cluster state.
 */
const singleClusterState = 'dashboards';

export const SimulationConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'simulation.**',
      url: '/simulations',
      loadChildren: () => import('./simulation.module').then(m => m.SimulationModule),
    },
  ],
  allClusterMap: {
    'simulation.list': {
      heliosOnly: true,
      allClustersState: 'simulation.list',
      singleClusterState,
    },
    'simulation.details': {
      heliosOnly: true,
      allClustersState: 'simulation.details',
      singleClusterState,
    },
    'simulation.build': {
      heliosOnly: true,
      allClustersState: 'simulation.build',
      singleClusterState,
    },
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'simulation.list': simulationAccess,
      'simulation.details': simulationAccess,
      'simulation.build': simulationAccess,
    };
  }
};
