import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { DataIdModule } from '../data-id/data-id.module';
import { SlicerModule } from '../slicer/slicer.module';
import { ViewSwitcherComponent } from './view-switcher.component';

@NgModule({
  declarations: [
    ViewSwitcherComponent,
  ],
  imports: [
    CommonModule,
    DataIdModule,
    MatButtonToggleModule,
    MatIconModule,
    MatTooltipModule,
    SlicerModule,
  ],
  exports: [
    ViewSwitcherComponent,
  ]
})
export class ViewSwitcherModule { }
