import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';

import { CohesitySharedFormsModule } from '../forms';
import { FormBuilderComponent, FormSectionHostDirective } from './form-builder.component';
import { EXPORT_THINGS } from './form-builder.directives';
import { FormSectionComponent } from './form-section.component';

const EXPORT_COMPONENTS = [
  ...EXPORT_THINGS,
  FormBuilderComponent,
  FormSectionComponent,
  FormSectionHostDirective,
];

const COMPONENTS = [
  ...EXPORT_COMPONENTS,
];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...EXPORT_COMPONENTS],
  imports: [
    CohesityHelixModule,
    CohesitySharedFormsModule,
    CommonModule,
    TranslateModule,
  ],
})
export class FormBuilderModule { }
