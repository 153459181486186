
import { ClusterConfig } from '@cohesity/iris-core';

/**
 * Compare two cluster config/scopes
 *
 * @param scope1 first scope to compare
 * @param scope2 second scope to compare
 * @returns true if both scope entities are same
 */
export function isSameScope(scope1: ClusterConfig, scope2: ClusterConfig): boolean {
  if (scope1 && scope2) {
    // if both are non clusters
    if (scope1._nonCluster && scope2._nonCluster) {
      // 1. compare services
      if (scope1._cohesityService && scope2._cohesityService) {
        return scope1._serviceType === scope2._serviceType;
      }

      // If given scopes are not cohesity services, then 2 more
      // special cases need to be handled -
      // 1. Global context
      // 2. All clusters mode
      if (scope1._globalContext && scope2._globalContext) {
        return true;
      }

      if (scope1._allClusters && scope2._allClusters) {
        return true;
      }

      // 3. rest all cases are false because one of the config is
      // a global config and other is a service
      return false;
    }

    // 4. if either is non cluster return false
    if (scope1._nonCluster || scope2._nonCluster) {
      return false;
    }

    // 5. both are actual clusters, compare cluster id
    return scope1.clusterId === scope2.clusterId;
  }
  return false;
}
