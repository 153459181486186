import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const protectModify = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngProtectionGroupGlobalModify && ctx.FEATURE_FLAGS.ngProtectionGroupAdModify
    && ctx.PROTECTION_MODIFY;

export const ExchangeConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'protection-builder-exchange.**',
      url: '/protection/protect/exchange',
      loadChildren: () => import('./exchange.module').then(m => m.ExchangeModule),
    },
  ],
  getStateAccessMap(): StateAccessMap {
    return {
      'protection-builder-exchange.create': protectModify,
      'protection-builder-exchange.edit': protectModify,
    };
  }
};
