// Module: LDAP create/modify

;(function(angular, undefined) {
  'use strict';

  angular.module('C.ldap')
    .controller('addLdapController', AddLdapCtrlFn)
    .controller('editLdapController', EditLdapCtrlFn);

  // If this flow is instantiated as a modal, this var will hold the parent
  // state's context help id so we can restore it when the modal is closed.
  var parentHelpId;

  /**
   * Controller for the Add state
   */
  function AddLdapCtrlFn($state, _, evalAJAX, FEATURE_FLAGS,
    ActiveDirectoryService, LdapService, $uibModalInstance) {

    var $ctrl = this;

    // Default config object used to stub out model when creating.
    var defaultLdapConfig = {
      _type: 'kPreferred',
      authType: 'kSimple',
    };

    $ctrl.inModal = angular.isObject($uibModalInstance);

    if ($ctrl.inModal) {
      // Store the parent's context help ID so we can restore it later.
      parentHelpId = $state.current.help;

      // Set the context help ID for this modal.
      $state.current.help = 'admin_ldap';
    }

    _.assign($ctrl, {
      adDomains: [{domainName: 'none'}],
      allowAddNewAd: !$uibModalInstance,
      FEATURE_FLAGS: FEATURE_FLAGS,
      ldapConfig: angular.copy(defaultLdapConfig),
      ldapForm: {},
      storageDomainsHashByAd: {},

      // Methods
      $onInit: $onInit,
      addAdModal: addAdModal,
      finish: finish,
      onSubmitForm: onSubmitForm,
    });

    /**
     * Initializes the controller.
     *
     * @method   $onInit
     */
    function $onInit() {
      _getActiveDirectories();
    }

    /**
     * Add new LDAP Provider
     *
     * @method     addLdapProvider
     * @param      {Object}  config  LDAP config object
     */
    function onSubmitForm(config) {
      if (!config || $ctrl.ldapForm.$invalid) {
        return;
      }

      $ctrl.updatingLdap = true;

      LdapService.addLdapProvider(config).then(
        function addLdapProviderSuccess(newLdapConfig) {
          $ctrl.ldapConfig = newLdapConfig;
          $ctrl.finish('complete');
        },
        evalAJAX.errorMessage
      ).finally(
        function addLdapProviderFinally(resp) {
          $ctrl.updatingLdap = false;
        }
      );
    }

    /**
     * Gets the active directories.
     *
     * @method     _getActiveDirectories
     * @return     {Object}  Promise to resolve request for activeDirectories
     */
    function _getActiveDirectories() {
      return ActiveDirectoryService.getActiveDirectories().then(
        function getActiveDirectoriesSuccess(adConfigs) {
          $ctrl.adDomains = $ctrl.adDomains.concat(adConfigs);

          // Set default value to be 'None'
          $ctrl.ldapConfig.adDomainName = $ctrl.adDomains[0].domainName;
        }
      ).finally(
        function getActiveDirectoriesFinally() {
          $ctrl.dataReady = true;
        }
      );
    }

    /**
     * Cancels the create mode and either returns to main list or dismisses the
     * modal.
     *
     * @method     finish
     * @param      {String}  [reason='cancel']  describes reason for ending
     *                                          flow, 'cancel' or 'complete'
     */
    function finish(reason) {
      reason = reason || 'cancel';

      if ($ctrl.inModal) {
        switch (reason) {
          case 'cancel':
            $uibModalInstance.dismiss('cancel');
            break;
          case 'complete':
            $uibModalInstance.close($ctrl.ldapConfig);
            break;
        }

        // After closing the Add LDAP modal, restore context help ID to the
        // original value of the parent page.
        $state.current.help = parentHelpId;
      } else {
        return $state.go('access-management.ldap');
      }
    }

    /**
     * Opens a modal to create new Active Directory and then sets the model with
     * the newly created one.
     *
     * @method   addAdModal
     */
    function addAdModal() {
      $ctrl.loading = true;

      ActiveDirectoryService.newAdModal().then(
        function modalResolved(newAdConfig) {
          if (!newAdConfig) {
            // Empty AD, so exit early.
            return;
          }

          // Stub new bucket in the Storage Domain hash.
          $ctrl.storageDomainsHashByAd[newAdConfig.domainName] = [];

          // Add this new AD to the ui-select list.
          $ctrl.adDomains.push(newAdConfig);

          // Select this AD in the ui-select list.
          $ctrl.ldapConfig.adDomainName = newAdConfig.domainName;
        }
      )
      .finally(function modalCloseFinally() {
        $ctrl.loading = false;
      });
    }
  }

  /**
   * Controller for the Edit state
   */
  function EditLdapCtrlFn(_, $state, $q, $log, $timeout, evalAJAX,
    LdapService, ViewBoxService, ActiveDirectoryService) {

    var $ctrl = this;

    var originalLdapConfig;

    _.assign($ctrl, {
      adDomains: [{domainName: 'none'}],
      isEditMode: !!$state.params.id,
      ldapConfig: {},
      ldapForm: {},
      storageDomainsHashByAd: {},
      storageDomainsHashByLdap: {},

      // Methods
      $onInit: $onInit,
      addAdModal: addAdModal,
      finish: finish,
      getAffectedStorageDomains: getAffectedStorageDomains,
      isLdapTheUserMappingType: isLdapTheUserMappingType,
      isMappedToAnother: isMappedToAnother,
      onSubmitForm: onSubmitForm,
      showAffectedStorageDomainsMessage: showAffectedStorageDomainsMessage,
    });

    /**
     * Initializes the controller.
     *
     * @method   $onInit
     */
    function $onInit() {
      var promises = {
        ldaps: LdapService.getLdapProviders(),
        ads: ActiveDirectoryService.getActiveDirectories(),
        storageDomains: ViewBoxService.getOwnViewBoxes(),
      };

      $q.all(promises).then(
        function allSuccess(response) {
          // Assign list of Active Directories.
          $ctrl.adDomains = $ctrl.adDomains.concat(response.ads);

          // Assign list of Storage Domains.
          $ctrl.viewBoxes = response.storageDomains;

          // Stub the hash of ViewBoxes by AD.
          $ctrl.storageDomainsHashByAd.none = [];
          response.ads.forEach(function stubstorageDomainsHash(ad) {
            $ctrl.storageDomainsHashByAd[ad.domainName] = [];
          });

          // Populate the hash of ViewBoxes by AD.
          response.storageDomains.forEach(
            function hashViewBoxes(storageDomain) {
              if (storageDomain.adDomainName) {
                $ctrl.storageDomainsHashByAd[storageDomain.adDomainName]
                  .push(storageDomain);
              }
            }
          );

          // Assign LDAP Provider data object.
          originalLdapConfig = response.ldaps.find(
            function findLdapProvider(ldap) {
              return +$state.params.id === ldap.id;
            }
          );

          // Find mapped AD
          originalLdapConfig._mappedAd = $ctrl.adDomains.find(
            function findMappedAd(ad) {
              return ad.domainName === originalLdapConfig.adDomainName;
            }
          );

          $ctrl.ldapConfig = angular.copy(originalLdapConfig);

          if (!$ctrl.ldapConfig) {
            $log.error('Could not find specified LDAP Provider with ID: ' +
              $state.params.id);
            return $state.go('access-management.ldap');
          }

          // Assign selected AD Domain name.
          $ctrl.originalAdDomainName =
            $ctrl.ldapConfig.adDomainName =
            $ctrl.ldapConfig.adDomainName || $ctrl.adDomains[0].domainName;
        },
        evalAJAX.errorMessage
      ).finally(function allFinally() {
        $ctrl.dataReady = true;
      });
    }

    /**
     * Update LDAP provider with the given configuration.
     *
     * @method     _updateLdapProvider
     * @param      {Object}  ldapConfig  LDAP config object
     * @param      {Object}  [authData]  optional object containing auth
     *                                   provider info and affected Storage
     *                                   Domains.
     */
    function _updateLdapProvider(ldapConfig, authData) {
      $ctrl.updatingLdap = true;

      LdapService.updateLdapProvider(ldapConfig).then(
        function updateLdapProviderSuccess() {
          if (authData) {
            // User broke the mapping between LDAP and AD. And this will reset
            // both values in the Storage Domain. Now we need to go update all
            // the affected Storage Domains with the user's preferred auth
            // provider.
            _updateStorageDomains(authData);
          }

          // Need a slight delay in order for LDAP update to happen before we
          // return to view page and do a GET. TODO(David): rip out the timeout
          // after the LDAP APIs are changed (~6.2) to be blocking until all
          // updates are done.
          return $timeout(finish, 500);
        },
        evalAJAX.errorMessage
      ).finally(
        function updateLdapProviderFinally(resp) {
          $ctrl.updatingLdap = false;
        }
      );
    }

    /**
     * Issues the update Storage Domains API with each preferred auth provider.
     *
     * @method   _updateStorageDomains
     * @param    {Object}   authdata   object containing auth provider info
     *                                 and affected Storage Domains
     */
    function _updateStorageDomains(authdata) {
      ViewBoxService.updateStorageDomains(authdata,
        $ctrl.updatingStorageDomains);
    }

    /**
     * Submits form after first checking to see if user broke AD-LDAP mapping.
     *
     * @method   onSubmitForm
     * @param    {Object}   newLdapConfig   ldap model config object
     */
    function onSubmitForm(newLdapConfig) {
      var authData = {};

      if (!newLdapConfig || $ctrl.ldapForm.$invalid) {
        return;
      }

      // Clear password from request object if not changing it.
      if (!$ctrl.showChangePassword) {
        newLdapConfig.userPassword = undefined;
      }

      if (!!originalLdapConfig.adDomainName &&
        newLdapConfig.adDomainName === 'none' &&
        $ctrl.storageDomainsHashByAd[originalLdapConfig.adDomainName][0]) {
        // User is trying to break the link between AD and LDAP, and now must
        // specify which one should remain associated with the Storage Domains.
        // Open modal to prompt user. On Cancel, exit function. Otherwise
        // continue.

        _.assign(authData, {
          adDomainName: originalLdapConfig.adDomainName,
          ldapProviderId: originalLdapConfig.id,

          // Identify Storage Domains which had been mapped to this AD + LDAP.
          storageDomains:
            $ctrl.storageDomainsHashByAd[originalLdapConfig.adDomainName],
        });

        // Open the modal which asks user to select preferred auth provider for
        // the affected Storage Domains.
        LdapService.openBreakAuthMappingModal(authData).then(
          function modalConfirmed(response) {
            _updateLdapProvider(newLdapConfig, response);
          }
        );
      } else {
        // When simply selecting a different AD Provider, it is straightfoward.
        _updateLdapProvider(newLdapConfig);
      }
    }

    /**
     * Determines whether the Active Directory is already mapped to another LDAP
     * Provider.
     *
     * @method   isMappedToAnother
     * @param    {Object}   adConfig The AD config object
     */
    function isMappedToAnother(adConfig) {
      return adConfig.ldapProviderId &&
        // Don't show disabled if it's actually mapped to this LDAP Provider.
        $ctrl.ldapConfig.id !== adConfig.ldapProviderId;
    }

    /**
     * Determines whether the current LDAP Provider is the User ID Mapping type
     * for its mapped AD.
     *
     * @method     isLdapTheUserMappingType
     * @returns    {Boolean}   true if is the User ID Mapping type for mapped AD
     */
    function isLdapTheUserMappingType() {
      return _.get(originalLdapConfig, '_mappedAd.userIdMappingInfo.type') ===
        'kLdapProvider';
    }

    /**
     * Compiles a deduped list of the Storage Domains affected by a change to
     * the AD mapping.
     *
     * @method   getAffectedStorageDomains
     * @return   {Array}  List of Storage Domains
     */
    function getAffectedStorageDomains() {
      return _.uniqBy($ctrl.storageDomainsHashByAd[$ctrl.originalAdDomainName]
        .concat($ctrl.storageDomainsHashByAd[$ctrl.ldapConfig.adDomainName]
      ), 'domainName');
    }

    /**
     * Determines whether to show the message about affected Storage Domains. We
     * want to show this message for existing Storage Domains mapped to either
     * the existing AD or the selected AD.
     *
     * @method   showAffectedStorageDomainsMessage
     * @return   {Boolean}  True to show an "already mapped" warning message
     */
    function showAffectedStorageDomainsMessage() {
      // Not the current selection AND
      return $ctrl.originalAdDomainName !== $ctrl.ldapConfig.adDomainName &&

        // The current AD is mapped to existing Storage Domains OR
        ($ctrl.storageDomainsHashByAd[$ctrl.originalAdDomainName][0] ||

        // The selected AD is mapped to existing Storage Domains.
        $ctrl.storageDomainsHashByAd[$ctrl.ldapConfig.adDomainName][0]);
    }

    /**
     * Cancels the edit mode and returns to details view.
     *
     * @method     finish
     */
    function finish() {
      return $state.go('view-ldap', { id: $ctrl.ldapConfig.id });
    }

    /**
     * Opens a modal to create new LDAP Provider and then sets the model with
     * the newly created one.
     *
     * @method   addAdModal
     */
    function addAdModal() {
      $ctrl.loading = true;

      ActiveDirectoryService.newAdModal().then(
        function modalResolved(newAdConfig) {
          if (!newAdConfig) {
            // Empty AD, so exit early.
            return;
          }

          // Stub new bucket in the Storage Domain hash.
          $ctrl.storageDomainsHashByAd[newAdConfig.domainName] = [];

          // Add this new AD to the ui-select list.
          $ctrl.adDomains.push(newAdConfig);

          // Select this AD in the ui-select list.
          $ctrl.ldapConfig.adDomainName = newAdConfig.domainName;
        }
      )
      .finally(function modalCloseFinally() {
        $ctrl.loading = false;
      });
    }

  }

}(angular));
