import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AngularMaterialModule } from '../../angular-material.module';
import { DataIdModule } from '../data-id/data-id.module';
import { IconModule } from '../icon/icon.module';
import { NavItemComponent } from './nav-item.component';

const COMPONENTS = [
  NavItemComponent,
];

/**
 * Module for all components related to the application frame. This includes
 * the top app bar, the drawer, and nav item components.
 */
@NgModule({
  imports: [
    AngularMaterialModule,
    CommonModule,
    DataIdModule,
    IconModule,
  ],
  declarations: COMPONENTS,
  exports: [...COMPONENTS, AngularMaterialModule]
})
export class NavItemModule { }
