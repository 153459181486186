import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { DataTreeNode } from '@cohesity/helix';
import { UIRouterGlobals } from '@uirouter/core';

import { BaseSourceTreeComponent } from '../shared/base-source-tree.component';
import { SourceTreeContextProvider } from '../shared/source-tree-context';
import { SOURCE_TREE_SERVICE_FACTORY, SourceTreeServiceFactory } from '../shared/source-tree-service.factory';

/**
 * Job source tree component. This renders a source tree and allows a user to make selections
 * to configure a job.
 *
 * @example
 * <coh-source-tree-view [data]="protectionSource" environment="kAD" [formControl]="selection">
 * </coh-source-tree-view>
 */
@Component({
  selector: 'coh-source-tree-view',
  templateUrl: './source-tree-view.component.html',
  styleUrls: ['./source-tree-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SourceTreeContextProvider],
})
export class SourceTreeViewComponent<
  NodeType,
  TreeNodeType extends DataTreeNode<NodeType>
> extends BaseSourceTreeComponent<NodeType, TreeNodeType> {
  constructor(
    cdr: ChangeDetectorRef,
    @Inject(SOURCE_TREE_SERVICE_FACTORY) transformerFactory: SourceTreeServiceFactory,
    uiRouterGlobals: UIRouterGlobals
  ) {
    super(cdr, transformerFactory, uiRouterGlobals);
  }
}
