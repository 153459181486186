import { Injectable } from '@angular/core';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { DataTreeSelection } from '@cohesity/helix';
import { IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { SourceSelection } from '@cohesity/iris-source-tree';
import { TranslateService } from '@ngx-translate/core';

import { BaseProtectionSourceService } from '../shared/base-protection-source.service';
import { NasSourceDataNode } from './nas-source-data-node';
import { NasViewFilters } from './nas-view-filters';

/**
 * Tree service for NAS.
 */
@Injectable({
  providedIn: 'root',
})
export class NasSourceTreeService extends BaseProtectionSourceService<NasSourceDataNode> {
  /**
   * NAS View Filters to filter the source tree on basis of protocol
   */
  nasViewFilters: NasViewFilters<NasSourceDataNode>;

  /**
   * Nas environments by default do not use auto protection.
   */
  get usesAutoProtection(): boolean {
    return false;
  }

  /**
   * Indicates if the current user is in DMaaS context.
   */
  private isDmsScope = false;

  constructor(
    private translate: TranslateService,
    private irisCtx: IrisContextService
  ) {
    super();
    this.nasViewFilters = new NasViewFilters(this.treeControl, this.filters, irisCtx);
    this.isDmsScope = isDmsScope(this.irisCtx.irisContext);
  }

  /**
   * Determine what nodes are leaf nodes for nas
   *
   * @param   node   The node to check.
   * @returns True if this is a  leaf node.
   */
  isLeaf(node: NasSourceDataNode): boolean {
    return node.isLeaf;
  }

  /**
   * Transforms the node object from the api into a Ad Tree node to pass to the tree.
   *
   * @param   node   The original node.
   * @param   level  The level in the tree.
   * @return  An AdSourceDataNode that can be displayed in the tree.
   */
  transformData(node: ProtectionSourceNode, level: number): NasSourceDataNode {
    return new NasSourceDataNode(
      node.protectionSource.environment as any,
      node,
      level,
      this.irisCtx,
      this.isDmsScope
    );
  }

  /**
   * Convert the data tree selection model to the job selection model.
   *
   * @param   selection   The selection from the tree.
   * @return  The job selection info.
   */
  transformFromDataTreeSelection(selection: DataTreeSelection<NasSourceDataNode>): SourceSelection {
    const selectedObjects =
      selection.selected.filter(item => !item.expandable).concat(selection.autoSelected);
    return {
      sourceIds: selectedObjects.map(item => Number(item.id)),
      excludeSourceIds: selection.excluded.map(item => Number(item.id)),
    };
  }

  /**
   * Convert source selection to the data tree selection model.
   * Source selection ids should map to physical hosts, there are no duplicates in the tree and
   * auto select is not applicable.
   *
   * @param   allNodes         The unfiltered list of tree nodes.
   * @param   sourceSelection  The job selection.
   * @return  A data tree selection model.
   */
  transformToDataTreeSelection(
    allNodes: NasSourceDataNode[],
    sourceSelection: SourceSelection
  ): DataTreeSelection<NasSourceDataNode> {
    const selection: DataTreeSelection<NasSourceDataNode> = {
      autoSelected: [],
      excluded: [],
      selected: [],
      options: {},
    };

    if (!sourceSelection) {
      return selection;
    }
    if (sourceSelection.sourceIds.length) {
      allNodes.forEach(node => {
        const nodeId = Number(node.id);
        if ((sourceSelection.sourceIds || []).includes(nodeId)) {
          // If node is expandable, node is not a child and the node is
          // selected for auto protect. If node is not expandable, node is a
          // child object and not auto protected.
          node.expandable ? selection.autoSelected.push(node) :
            selection.selected.push(node);
        }
        if ((sourceSelection.excludeSourceIds || []).includes(nodeId)) {
          selection.excluded.push(node);
        }
      });
    }

    return selection;
  }
}
