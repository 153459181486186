import { Component, HostBinding, Input } from '@angular/core';

/**
 * Data model for NavBanner component
 */
export interface NavBanner {
  logo?: string;
  title: string;
  subtitle?: string;
  showBranding?: boolean;
  modifierClass?: string;
}

@Component({
  selector: 'cog-nav-banner',
  templateUrl: './nav-banner.component.html',
  styleUrls: ['./nav-banner.component.scss'],
})
export class NavBannerComponent {
  /**
   * The logo (cog-icon shape) to be displayed in the nav banner.
   */
  @Input() logo: string;

  /**
   * Size of logo.
   */
  @Input() size: 'md' | 'lg' | 'xl' = 'xl';

  /**
   * The title to be displayed.
   */
  @Input() title: string;

  /**
   * Optional mechanism to hide logo and title branding, making component
   * only show transcluded content.
   */
  @Input() showBranding = true;

  /**
   * A class for custom color styling of the banner. Not applied if branding
   * is disabled.
   */
  @Input() modifierClass: string;

  /**
   * Applies the modifier class if appropriate.
   */
  @HostBinding('class') get addModifierClass() {
    if (this.showBranding) {
      return this.modifierClass;
    }
  }
}
