<div class="flex-end dialog-header">
  <h2 mat-dialog-title>{{ 'securityAdvisor.issuesDialog.viewIssues' | translate }}</h2>
</div>

<!-- Binding the main base screen list issues -->
<mat-dialog-content class="margin-bottom">
  <dg-pa-issues [clusterRegionName]="clusterRegionName" [clusterId]="clusterId">
  </dg-pa-issues>
</mat-dialog-content>

 <!-- Cancel button -->
<mat-dialog-actions align="end">
  <button
    type="button"
    mat-button
    mat-dialog-close
    cogCancelButton
    cogDataId="cancel-issues-dialog">
    {{'close' | translate}}
  </button>
</mat-dialog-actions>
