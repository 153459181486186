import {
  EsxiRegistrationParams,
  McmSourceRegistration,
  ScvmmRegistrationParams,
  StandaloneClusterRegistrationParams,
  StandaloneHostRegistrationParams,
  VcdRegistrationParams,
  VcenterRegistrationParams,
} from '@cohesity/api/v2';
import { JobEnvParamsV2 } from 'src/app/shared/constants/job.constants';
import { SourceEntityParams } from 'src/app/shared/constants/source-entity-params.constants';

/**
 * Combines all source entity params into one type.
 */
export type SourceEntityParams =
  EsxiRegistrationParams |
  ScvmmRegistrationParams |
  StandaloneClusterRegistrationParams |
  StandaloneHostRegistrationParams |
  VcdRegistrationParams |
  VcenterRegistrationParams;

/**
 * Gets the parameter object from source API response data.
 * For example, this can return data.vmwareParams.vCenterParams.
 *
 * @param   data         Source API response data.
 *
 * @returns Parameter object in API response.
 */
export function getSourceEntityParams(data: McmSourceRegistration): SourceEntityParams {
  if (!data) {
    return null;
  }

  const environment = data.environment;
  const environmentParams = data[JobEnvParamsV2[environment]];
  const entityParamMap = SourceEntityParams[environment];

  if (environmentParams && entityParamMap) {
    const entityType = environmentParams.type;
    const entityParamKey = entityParamMap[entityType];

    return environmentParams[entityParamKey];
  }
  return null;
}
