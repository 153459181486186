<button [inheritDataId]="true"
  [matMenuTriggerFor]="settingsMenu"
  class="table-settings-menu-button"
  cogDataId="table-settings-menu-button"
  mat-icon-button
  [disabled]="disabled"
  type="button">
  <cog-icon shape="settings!outline"></cog-icon>
</button>

<mat-menu #settingsMenu>
  <cog-table-config-form
    (columnsChange)="columnsChange.emit($event)"
    (translate)="translate"
    [enableReordering]="enableReordering"
    [columns]="columns">
  </cog-table-config-form>
</mat-menu>
