import {
  CassandraIndexedObject,
  CouchbaseIndexedObject,
  HbaseIndexedObject,
  HDFSIndexedObject,
  HiveIndexedObject,
  MongoIndexedObject,
} from '@cohesity/api/v2';
import { Environment, ObjectTypeToIconMap } from 'src/app/shared';

/**
 * Aggregated type for all NoSQL & Hadoop Indexed Object defintions.
 */
export type NoSqlIndexedObject =
  CassandraIndexedObject |
  CouchbaseIndexedObject |
  HbaseIndexedObject |
  HDFSIndexedObject |
  HiveIndexedObject |
  MongoIndexedObject;

/**
 * Aggregated type for all NoSQL & Hadoop recovery source object types.
 */
export type NoSqlSourceObjectType =
  'CassandraObjects' |
  'CouchbaseObjects' |
  'HbaseObjects' |
  'HDFSObjects' |
  'HiveObjects' |
  'MongoObjects';

/**
 * Cassandra recovery object types.
 */
export type CassandraObjectType = 'CassandraKeyspaces' | 'CassandraTables';

/**
 * Couchbase recovery object types.
 */
export type CouchbaseObjectType = 'CouchbaseBuckets';

/**
 * MongoDB search object types.
 */
export type MongoDBObjectType = 'MongoDatabases' | 'MongoCollections';

/**
 * HBase search object types.
 */
export type HBaseObjectType = 'HbaseNamespaces' | 'HbaseTables';

/**
 * Hive search object types.
 */
export type HiveObjectType = 'HiveDatabases' | 'HiveTables';

/**
 * Hdfs search object types.
 */
export type HdfsObjectType = 'HDFSFolders' | 'HDFSFiles';

/**
 * Aggregated type for all NoSQL & Hadoop recovery object types.
 */
export type NoSqlEntityObjectType =
  CassandraObjectType |
  CouchbaseObjectType |
  HBaseObjectType |
  HdfsObjectType |
  HiveObjectType |
  MongoDBObjectType;

/**
 * Object type filter param names map for V2 search Indexed Objects API request object.
 * These are used inside the object derived from the SearchRequestEnvParamsMap.
 */
export const SourceObjectTypeEnvParamsMap: { [key in Environment]?: string } = {
  kCassandra: 'cassandraObjectTypes',
  kCouchbase: 'couchbaseObjectTypes',
  kHBase: 'hbaseObjectTypes',
  kHdfs: 'hdfsTypes',
  kHive: 'hiveObjectTypes',
  kMongoDB: 'mongoDBObjectTypes',
};

/**
 * Object type filter values map for V2 search Indexed Objects API request object.
 * These values should come from the object filters in the recovery flow later, once these
 * filters are incorportated in the UI for Imanis connectors.
 */
export const EntityObjectTypesEnvMap: { [key in Environment]?: NoSqlEntityObjectType[] } = {
  kCassandra: ['CassandraKeyspaces', 'CassandraTables'],
  kCouchbase: ['CouchbaseBuckets'],
  kHBase: ['HbaseNamespaces', 'HbaseTables'],
  kHdfs: ['HDFSFolders', 'HDFSFiles'],
  kHive: ['HiveDatabases', 'HiveTables'],
  kMongoDB: ['MongoDatabases', 'MongoCollections'],
};

/**
 * Recovery source object types map for V2 search Indexed Objects API request object.
 */
export const SourceObjectTypeEnvMap: { [key in Environment]?: NoSqlSourceObjectType } = {
  kCassandra: 'CassandraObjects',
  kCouchbase: 'CouchbaseObjects',
  kHBase: 'HbaseObjects',
  kHdfs: 'HDFSObjects',
  kHive: 'HiveObjects',
  kMongoDB: 'MongoObjects',
};

/**
 * Objects list param names map for V2 search Indexed Objects API response.
 */
export const SearchResponseEnvParamsMap: { [key in Environment]?: string } = {
  kCassandra: 'cassandraObjects',
  kCouchbase: 'couchbaseObjects',
  kHBase: 'hbaseObjects',
  kHdfs: 'hdfsObjects',
  kHive: 'hiveObjects',
  kMongoDB: 'mongoObjects',
};

/**
 * Filter params model for V2 object search APIs.
 */
export interface FilterParams {

  /**
   * Source Ids to filter the results on.
   */
  sourceIds?: number[];

  /**
   * Protection group Ids to filter the results on.
   */
  protectionGroupIds?: string[];

  /**
   * Storage domain Ids to filter the results on.
   */
  storageDomainIds?: number[];

  /**
   * Lower limit for snapshot timestamps filter.
   */
  filterSnapshotFromUsecs?: number;

  /**
   * Upper limit for snapshot timestamps filter.
   */
  filterSnapshotToUsecs?: number;

  /**
   * Recovery object types to filter the results on.
   */
  objectTypes?: string[];
}

/**
 * Recovery object types to entity types map.
 * This is useful for cases where existing entity mappings can be leveraged
 * to map them to recovery object types as well. E.g.: icon mappings.
 */
export const ObjectTypeToEntityTypeMap: { [key1 in Environment]?: { [key2 in NoSqlEntityObjectType]?: string} } = {
  kCassandra: {
    CassandraKeyspaces : 'kKeyspace',
    CassandraTables : 'kTable'
  },
  kCouchbase: {
    CouchbaseBuckets : 'kBucket',
  },
  kHBase: {
    HbaseNamespaces: 'kNamespace',
    HbaseTables: 'kTable',
  },
  kHdfs: {
    // These two entity types hdfsFiles and hdfsFolders for HDFS are made up only in the UI
    // for the purpose of icon mappings and do not map to any actual entity types in backend.
    HDFSFiles: 'hdfsFiles',
    HDFSFolders: 'hdfsFolders',
  },
  kHive: {
    HiveDatabases: 'kDatabase',
    HiveTables: 'kTable',
  },
  kMongoDB: {
    MongoCollections: 'kCollection',
    MongoDatabases: 'kDatabase',
  }
};

/**
 * Recovery Entity types to helix icon map.
 */
export const EntityTypeToIconMap: { [key1 in Environment]?: { [key2 in string]?: string} } = {
  kCassandra: {
    ...ObjectTypeToIconMap.kCassandra,
    kCluster: 'helix:group-database',
    kSystemKeyspace: 'helix:object-system-keyspace',
  },
  kCouchbase: {
    ...ObjectTypeToIconMap.kCouchbase,
    kCluster: 'helix:group-database',
  },
  kHBase: {
    ...ObjectTypeToIconMap.kHBase,
    kCluster: 'helix:group-hadoop',
  },
  kHdfs: {
    kCluster: 'helix:group-hadoop',
    // TODO (atif) : Replace Icon for hdfsFiles once the icon is available.
    hdfsFiles: 'note!left',
    hdfsFolders: 'helix:object-folder',
  },
  kHive: {
    ...ObjectTypeToIconMap.kHive,
    kCluster: 'helix:group-hadoop',
  },
  kMongoDB: {
    ...ObjectTypeToIconMap.kMongoDB,
    kCluster: 'helix:group-database',
  }
};

/**
 * Cassadra allow all authorizer constant.
 */
export const CassadraNoAuthorizer = 'AllowAllAuthorizer';
