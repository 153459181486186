// Simple Cache Service

;(function(angular, undefined) {
  'use strict';

  angular.module('C.simpleCacheService', [])
    .service('SimpleCacheService', SimpleCacheServiceFn);

  /**
   * @ngdoc service
   * @name C.simpleCacheService.SimpleCacheService
   *
   * @description
   * This is a simple service for caching promise results. Results are cached
   * By unique id and are valid for one minute.
   **/
  function SimpleCacheServiceFn(_, $cacheFactory, $q, $rootScope, $timeout,
    NgRemoteClusterService) {

    /**
     * A cache for various async values.
     *
     * @type   {object}
     */
    var _cache = $cacheFactory('simple-cache');

    /**
     * Timout value for cached values.
     *
     * Default: 2 minutes.
     *
     * @type   {number}
     */
    var _timeoutMsecs = 120000;

    /**
     * Cluster id associated with the cache. Anytime the cluster id changes we
     * need to reset the cache
     *
     * @type   {number}
     */
    var _cacheClusterId;

    return {
      clearCache: clearCache,
      getCachedValue: getCachedValue,
    };

    /**
     * Clears all items in the cache and resets it
     *
     * @method clearCache
     */
    function clearCache() {
      _cache.removeAll();
    }

    /**
     * Retrieves an object from a cache, or makes an an async call and adds the
     * result to a cache. The cache is valid for one minute, after wich the
     * result will be cleared
     *
     * @method getCachedValue
     * @param   {string}     id               A unique id to track the value by.
     * @param   {function}   originalMethod   Function to invoke the original
     *                                        call, this should return a promise
     * @return  {promise}   Returns either the promise from the original method,
     *                      a cached promise of a call in progress, or a
     *                      resolved promise of the cached data.
     */
    function getCachedValue(id, originalMethod) {
      var clusterId = NgRemoteClusterService.selectedScope ?
        NgRemoteClusterService.selectedScope.clusterId :
        $rootScope.clusterInfo.id;
      var entry;

      // If the cluster has changed, clear the cache
      if (clusterId != _cacheClusterId) {
        clearCache();
      }
      _cacheClusterId = clusterId;

      entry = _cache.get(id);

      // Return values from the cache or fetch data.
      if (entry && entry.value) {
        return $q.resolve(entry.value);
      } else if (entry && entry.promise) {
        return entry.promise;
      }

      // Create the entry and add it to the cache with a pending promise
      entry = {
        promise: originalMethod(),
      };
      _cache.put(id, entry);

      entry.promise
        .then(function updateCache(value) {
          // Update the cache with the value
          _cache.put(id, { value: value });

          // Start the countdown to expire this cache
          $timeout(_cache.remove.bind(this, id), _timeoutMsecs);
        })
        .finally(_.partial(_removeFinishedPromise, id))
        .onAbort(_.partial(_removeFinishedPromise, id));

      return entry.promise;
    }

    /**
     * Ensures that the promise object is cleared from the cache so that there
     * are no stale promises left in the cache.
     *
     * @param    {any}    id   cache id to clearn
     */
    function _removeFinishedPromise(id) {
      var entity = _cache.get(id);
      entity.prmoise = undefined;
      _cache.put(id, entity);
    }
  }
})(angular);
