// $log decoration
// Disables logging for production build with an option to enable via
// FEATURE_FLAGS.productionLogging

;(function(angular, undefined) {
  'use strict';

  angular.module('C').config(function cConfigFn($provide) {
    $provide.decorator('$log', logDecoratorFn);
  });

  function logDecoratorFn($delegate, FEATURE_FLAGS) {

    // Saving the original behaviors
    var dollarLog = {
      log: $delegate.log,
      debug: $delegate.debug,
      info: $delegate.info,

      // NOTE: Not currently decorating warn() and error() as they are desired
      // in all situtations. Will need to decorate them to do fancier things
      // such as writing logs to a file.
      //
      // warn: $delegate.warn,
      // error: $delegate.error,
    };

    /**
     * Replacement function for $log.log()
     *
     * @method   log
     */
    $delegate.log = function logMsgFn() {
      if (FEATURE_FLAGS.productionLogging) {
        dollarLog.log.apply(this, arguments);
      }
    };

    /**
     * Replacement function for $log.info()
     *
     * @method   info
     */
    $delegate.info = function infoMsgFn() {
      if (FEATURE_FLAGS.productionLogging) {
        dollarLog.info.apply(this, arguments);
      }
    };

    /**
     * Replacement function for $log.debug()
     *
     * @method   debug
     */
    $delegate.debug = function debugMsgFn() {
      if (FEATURE_FLAGS.productionLogging) {
        dollarLog.debug.apply(this, arguments);
      }
    };

    return $delegate;
  }

})(angular);
