import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

/**
 * Generic credentials component for recover flow.
 *
 * @example
 *   <coh-recover-to-credentials
 *     [readOnly]="readOnly"
 *     [label]="'someLabel' | translate"
 *     [usernameControl]="formGroupControls.username"
 *     [passwordControl]="formGroupControls.password">
 *   </coh-recover-to-credentials>
 */
@Component({
  selector: 'coh-recover-to-credentials',
  templateUrl: './recover-to-credentials.component.html',
  styleUrls: ['./recover-to-credentials.component.scss']
})
export class RecoverToCredentialsComponent {
  /**
   * Credentials label.
   */
  @Input() label: string;

  /**
   * Form control for user name.
   */
  @Input() usernameControl: UntypedFormControl;

  /**
   * Form control for password.
   */
  @Input() passwordControl: UntypedFormControl;
}
