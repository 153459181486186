import { Injectable, Optional, SkipSelf } from '@angular/core';
import { ProtectionSourceNode, ProtectionSourcesServiceApi } from '@cohesity/api/v1';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap, finalize } from 'rxjs/operators';

@Injectable()
export class TargetSelectorFormService {
  /**
   * Cache the source nodes by source id
   */
  private sourceCache = new BehaviorSubject<{ [id: number]: ProtectionSourceNode[] }>({});

  /**
   * Loading Behavior Subject
   */
  private loading = new BehaviorSubject<boolean>(false);

  /**
   * This is set to true whenever the protection sources are being loaded
   */
  loading$ = this.loading.asObservable();

  constructor(private sourceService: ProtectionSourcesServiceApi) {}

  /**
   * Gets the source nodes for a given id, and caches the results.
   *
   * @param id      The source id
   * @param params  Default params
   * @returns       An observable of the source nodes.
   */
  getSourceNodes(
    id: number,
    params: ProtectionSourcesServiceApi.ListProtectionSourcesParams
  ): Observable<ProtectionSourceNode[]> {
    if (!id) {
      return of([]);
    }
    return this.sourceCache.pipe(
      map(cache => cache[id]),
      tap(nodes => {
        if (!nodes) {
          this.loading.next(true);
          this.sourceService
            .ListProtectionSources({
              id,
              ...params,
            })
            .pipe(finalize(() => this.loading.next(false)))
            .subscribe(updatedNodes =>
              this.sourceCache.next({
                ...this.sourceCache.value,
                [id]: updatedNodes,
              })
            );
        }
      })
    );
  }
}

/**
 * Provider factory for TargetSelectorFormService
 *
 * @param   existing   Use this instance if it exists
 * @param   api        Deps to create the target selector service
 * @returns The service.
 */
export function targetSelectorFormServiceFactory(
  existing: TargetSelectorFormService,
  api: ProtectionSourcesServiceApi
): TargetSelectorFormService {
  return existing || new TargetSelectorFormService(api);
}

/**
 * Provider config for TargetSelectorFormService. This method ensures that within an injection tree, only
 * a single instance of this service will be used. This is provided by default on the target selector form,
 * but if you use *ngIf on the component, the cache will be cleared every time the component is added to
 * dom.
 *
 * If you add this provider on the form instance, it will use the same instance of the service throughout the
 * lifecycle of the form and maintain its cache.
 */
export const targetSelectorFormServiceProvider = {
  provide: TargetSelectorFormService,
  useFactory: targetSelectorFormServiceFactory,
  deps: [[new Optional(), new SkipSelf(), TargetSelectorFormService], ProtectionSourcesServiceApi],
};
