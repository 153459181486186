<cog-table #table
  class="standard-width"
  [source]="documents$ | async"
  cdkScrollable
  name="directory-list-table"
  cogDataId="directory-list-table">

  <!-- New code - added spinner on the top. -->
  <div *ngIf="isLoading">
    <cog-spinner></cog-spinner>
  </div>
  <mat-table
    matSort
    matSortActive="name"
    [trackBy]="trackBy"
    [class.loading]="isLoading">

    <!-- This uses custom selection logic in order to disable select all and to make children of selected directories
    show as selected, but disabled -->
    <ng-container matColumnDef="selection">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">

        <!-- Hide if the option to select the files if onlySelectFolders is true -->
        <ng-container *ngIf="!onlySelectFolders || row.isFolder">
          <mat-checkbox *ngIf="selection.isMultipleSelection()"
            cogDataId="select-{{row.name}}"
            [inheritDataId]="true"
            (change)="selectionUpdate(row)"
            (click)="table.stopPropagation($event)"
            [checked]="selection.isSelected(row) || isParentDirectorySelected"
            [disabled]="isRowDisabled(row)">
          </mat-checkbox>
          <mat-radio-button cogDataId="select-{{row.name}}"
            *ngIf="!selection.isMultipleSelection()"
            [inheritDataId]="true"
            (change)="selection.toggle(row)"
            (click)="table.stopPropagation($event)"
            [checked]="selection.isSelected(row)">
          </mat-radio-button>
        </ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'name' | translate }}</mat-header-cell>

      <mat-cell *matCellDef="let row" class="directory-name">
        <ng-template #docNameTemplate>
          <cog-icon
            [shape]="rowIconProviderFn?.(row) ?? (row.type | fileIcon)"
            [matTooltip]="('enum.fileObjectType.' + row.type) | translate">
          </cog-icon>

          <div class="cog-table-object-body">{{ row.name }}</div>
        </ng-template>

        <a *ngIf="isRowNavigable(row); else document"
          class="cog-table-object"
          cogDataId="select-document-{{row.name}}"
          (click)="browseToPath.emit(row.fullPath); table.stopPropagation($event)">
          <ng-template [ngTemplateOutlet]="docNameTemplate"></ng-template>
        </a>

        <ng-template #document>
          <div class="cog-table-object">
            <ng-template [ngTemplateOutlet]="docNameTemplate"></ng-template>
          </div>
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="size">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{'size' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">

        <span *ngIf="row.size && row.type === 'File'">
          {{row.size | byteSize}}
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="lastModifiedUsecs">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        {{'lastModified' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <ng-container *ngIf="row.lastModifiedUsecs > 0">
          {{row.lastModifiedUsecs | cohDate}}
        </ng-container>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="['selection', 'name', 'size', 'lastModifiedUsecs']"></mat-header-row>
    <mat-row *matRowDef="let row; columns: ['selection', 'name', 'size', 'lastModifiedUsecs']"
      (click)="onRowClicked(row); table.stopPropagation($event)"
      [hidden]="isLoading && !isPartialListing">
    </mat-row>

    <!-- Old code - added spinner on the top. Loading Footer row -->
    <!-- <mat-footer-row class="loading-row" [class.hidden]="!isLoading" *matFooterRowDef="['loading']"></mat-footer-row>
    <ng-container matColumnDef="loading">
      <mat-footer-cell *matFooterCellDef>
        <cog-spinner></cog-spinner>
      </mat-footer-cell>
    </ng-container> -->

    <!-- NoData Footer -->
    <mat-footer-row class="nodata-row"
      [class.hidden]="isLoading || (documents$ | async).length"
      *matFooterRowDef="['noData']">
    </mat-footer-row>
    <ng-container matColumnDef="noData">
      <mat-footer-cell mat-footer-cell *matFooterCellDef>
        {{ 'noDataFound' | translate }}
      </mat-footer-cell>
    </ng-container>

  </mat-table>
</cog-table>
