import { isDmsScope } from '@cohesity/iris-core';

// COMPONENT: Recover Files to Source: Snapshot selector modal

;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.fileRecovery';
  var moduleDeps;

  angular
    .module(moduleName, moduleDeps)
    .controller('recoverFilesSnapshotModal', recoverFilesSnapshotModalFn);

  function recoverFilesSnapshotModalFn(_, $rootScope, $scope, $interpolate,
    $uibModalInstance, RestoreService, fileEntity, cMessage, FEATURE_FLAGS,
    ExternalTargetService, ENV_GROUPS, ENTITY_KEYS, UserService,
    NgTenantService, $injector) {

    // Used when no results are found
    // @type {Object}
    var defaultResult = { multiFile: false };

    angular.merge($scope, {
      // GENERAL SCOPE VARS
      File: angular.extend(
        angular.copy(fileEntity),
        { fromObjectSnapshotsOnly: false, }
      ),
      isPristine: angular.isString(fileEntity._snapshotIndex),

      text: $rootScope.text.recoverFileSnapshotModal,

      // SCOPE METHODS
      isAddMoreDisabled: isAddMoreDisabled,
      isBulkRetrievalSupported: false,
      isDownloadDisabled: isDownloadDisabled,
      isRecoverDisabled: isRecoverDisabled,
      isTargetSelected: isTargetSelected,
      isTypeDownloadOnly: isTypeDownloadOnly,
      selectSnapshot: selectSnapshot,
      showDownloadLink: showDownloadLink,
      getTargetName: ExternalTargetService.getTargetName,
      getTargetType: ExternalTargetService.getTargetType,
      processSnapshot: processSnapshot,
    });

    const irisContext = $injector.get('NgIrisContextService')?.irisContext;

    // METHODS

    /**
     * Initialize this controller
     *
     * @method     snapshotSelectorInit
     */
    function snapshotSelectorInit() {
      if (!(fileEntity._versions && fileEntity._versions.length)) {
        $scope.getVersions();
      }
      if ($scope.File._volumeType === 2 && fileEntity._versions) {
        setDataProtectSnapshotVersions($scope.File);
      }
    }

    /**
     * checks if the selected file is from a type currently restricted to
     * download only
     *
     * @return   {Boolean}   returns true if the file is from an entity that
     *                       currently only support downloa d only, false
     *                       otherwise
     */
    function isTypeDownloadOnly() {
      return ($scope.entityKey === ENTITY_KEYS[2] &&
        FEATURE_FLAGS.hypervIsDownloadOnly) ||
      ENV_GROUPS.downloadOnly.includes($scope.entityKey);
    }

    /**
     * Downloads the snapshot of the selected file or folder
     *
     * @method     downloadNow
     * @return     {Object}  $Q Promise to close the modal after download.
     */
    function downloadNow() {
      // If cloud download is selected, or a directory is being downloaded
      // create file download task.
      if (isRemoteTargetSelected() || $scope.File.isDirectory) {
        return $uibModalInstance.close({
          fileEntity: $scope.File,
          isFileDownloadTask: true,
        });
      }

      // Fire it off
      RestoreService.downloadFileFromSnapshot(
        $scope.File.fileDocument.filename,
        _.get($scope.File, '_server.vmDocument') || $scope.File.fileDocument,
        $scope.File._versions[$scope.File._snapshotIndex]
      );

      // Set cMessage data
      cMessage.success({
        textKey: $interpolate($scope.text.downloadSuccessText)($scope),
      });

      // Close this modal
      return $uibModalInstance.dismiss('destroy');
    }

    /**
     * Sets the snapshotIndex of the chosen snapshot on the entity itself.
     *
     * @method     selectSnapshot
     * @param      {object}   version        The selected snapshot object.
     * @param      {object}   [replicaVec]   Optional selected replicaVec.
     */
    function selectSnapshot(version, replicaVec) {
      $scope.File._versions.some(function findVersionIndexFn(_version, ii) {
        if (angular.equals(version, _version)) {
          $scope.File._snapshotIndex = ii;
          return true;
        }

        return false;
      });

      $scope.File._snapshot = version;
      $scope.File._archiveTarget = replicaVec;

      if ($scope.File._archiveTarget) {
        ExternalTargetService.isBulkRetrievalSupported(
          _.get($scope.File._archiveTarget, 'target.archivalTarget.vaultId'))
        .then(function supportCallSuccess(isSupported) {
          $scope.isBulkRetrievalSupported = isSupported;
        });
      }
    }

    /**
     * Fetch the versions of the file we're looking at
     *
     * @method     getVersions
     * @return     {Object}  Passes through the $Q promise
     */
    $scope.getVersions = function getVersions() {
      var params = _.assign({

        // jobDetailsId is needed to check whether the job is "directArchive" or
        // not. It should always be the 'local' jobId.
        jobDetailsId: $scope.File.fileDocument.objectId.jobId,
      }, RestoreService.fileVersionsArg($scope.File));

      $scope.loading = true;

      return RestoreService.getFileVersions(params)
        .then(function versionsReceivedFn(resp) {
          $scope.job = resp.jobPromise;

          // Attach the fetched Versions to the object we'll ultimately return
          // into the cart. Pre-filter them for restorable runs.
          $scope.File._versions = RestoreService.getRestorableVersions(
            resp.data.versions
          );

          // when asking for get file version from all snapshots then don't
          // ignore fromObjectSnapshots flag.
          if (!$scope.File.fromObjectSnapshotsOnly) {
            $scope.fromObjectSnapshots = !!resp.data.fromObjectSnapshots;
          }

          // Assign the selected snapshot to the one already selected, or to the
          // first in the runs list.
          $scope.File._snapshot =
            $scope.File._snapshot || $scope.File._versions[0];

          // Do the same for archivalTarget, from the selected snapshot.
          $scope.File._archiveTarget = $scope.File._archiveTarget ||
            $scope.File._snapshot &&
            $scope.File._snapshot.replicaInfo &&
            Array.isArray($scope.File._snapshot.replicaInfo.replicaVec) &&
            $scope.File._snapshot.replicaInfo.replicaVec[0];

          if ($scope.File._volumeType === 2) {
            setDataProtectSnapshotVersions($scope.File);
          }

          return $scope.File._versions;
        })
        .finally(function versionsDoneFn() {
          $scope.loading = false;
        });
    };

    /**
     * If file being recovered is a part of a data protect volume in netapp,
     * we decorate the snapshot versions with the source snapshot time.
     *
     * @param     {object}  file   file object
     */
    function setDataProtectSnapshotVersions(file) {
      const fileDoc = file._server || file;
      const versions = $scope.File._versions || fileEntity._versions;
      RestoreService.getDataProtectSnapshotVersions(
        versions,
        fileDoc._jobId,
        fileDoc._protectionSourceId,
      ).then((versions) => (versions = versions));
    }

    /**
     * Determines if the 'Add More' option should be disabled.
     * If the option is disabled, also update the scope with appropriate
     * tooltip message to be displayed.
     *
     * @method    isAddMoreDisabled
     * @returns   {boolean}     True if the option should be disabled.
     *                          False otherwise
     */
    function isAddMoreDisabled() {
      switch (true) {
        case isRecoverDisabledForHyx():
          $scope.addMoreDisabledMessage = 'recovery.disableMessage.cloudSnapshot';
          return true;

        // if recovery is already disabled
        case !FEATURE_FLAGS.downloadFilesAndFoldersEnabled &&
          isRecoverDisabled():

          $scope.addMoreDisabledMessage = $scope.recoveryDisabledMessage;
          return true;

        case $scope.getTargetType($scope.File._archiveTarget) === 'tape':
          $scope.addMoreDisabledMessage =
            'recovery.disableMessage.multipleTapeSelection';
          return true;
      }

      $scope.addMoreDisabledMessage = '';
      return false;
    }

    /**
     * Determines if the 'Download Now' option should be disabled.
     * If the option is disabled, also update the scope with appropriate
     * tooltip message to be displayed.
     *
     * @method    isDownloadDisabled
     * @returns   {boolean}     True if the option should be disabled.
     *                          False otherwise
     */
    function isDownloadDisabled() {
      var file = $scope.File;

      switch (true) {
        // If SP is impersonating as a tenant
        case !!NgTenantService.impersonatedTenant:
          $scope.disableDownloadMessage =
            'recovery.disableMessage.onlyTenantDownloadSupported';
          return true;

        case !FEATURE_FLAGS.downloadFilesAndFoldersEnabled &&
          file.isDirectory:
          $scope.disableDownloadMessage =
            'recovery.disableMessage.folderDownloadUnsupported';
          return true;

        case $scope.getTargetType(file._archiveTarget) === 'tape':
          $scope.disableDownloadMessage =
            'recovery.disableMessage.tapeDownloadUnsupported';
          return true;

        case file._type === 'symlink':
          $scope.disableDownloadMessage =
            'recovery.disableMessage.symlinkDownloadUnsupported';
          return true;
      }

      $scope.disableDownloadMessage = '';
      return false;
    }

    /**
     * Determines if the recovery for Hyx bases tenant is supported or not.
     *
     * @method    isRecoverDisabledForHyx
     * @returns   {boolean}     True if recovery is allowed.
     *                          False otherwise
     */
    function isRecoverDisabledForHyx() {
      // Recovery from Cloud archived snapshots is not allowed for Hyx/bifrost tenants.
      return !isDmsScope(irisContext) &&
        UserService.user.privs.HYBRID_EXTENDER_VIEW &&
        !FEATURE_FLAGS.irisExecAllowBifrostTenantArchivalRecovery &&
        !!_.get($scope.File, '_archiveTarget.target.archivalTarget');
    }

    /**
     * Determines if the 'Download Now' option should be shown.
     *
     * @method    showDownloadLink
     * @returns   {boolean}     True if the option should be shown.
     *                          False otherwise
     */
    function showDownloadLink() {
      return $rootScope.FEATURE_FLAGS.downloadFilesAndFoldersEnabled &&
        // User should have RESTORE_DOWNLOAD privilege
        ($scope.user.privs.RESTORE_DOWNLOAD ||
          // Or show when SP is impersonating as tenant,
          // but this case should be disabled
          NgTenantService.impersonatedTenant);
    }

    /**
     * Determines if the 'Recover to Server' option should be disabled.
     * If the option is disabled, also update the scope with appropriate
     * tooltip message to be displayed.
     *
     * @method    isRecoverDisabled
     * @returns   {boolean}     True if the option should be disabled.
     *                          False otherwise
     */
    function isRecoverDisabled() {
      var jobType = _.get($scope.File, '_jobType');

      switch (true) {
        case isRecoverDisabledForHyx():
          $scope.recoveryDisabledMessage = 'recovery.disableMessage.cloudSnapshot';
          return true;

        case RestoreService
          .isUnsupportedTarget(_.get($scope.File,
            '_archiveTarget.target.archivalTarget.vaultId'),
              $scope.File._vaults):

          $scope.recoveryDisabledMessage =
            'recovery.disableMessage.cloudSnapshot';

          return true;

        // Cloud Native snapshots should be feature flag protected
        case ENV_GROUPS.nativeSnapshotTypes.includes(jobType):

          if (RestoreService.isCloudFLRFeatureOff(jobType)) {
            $scope.recoveryDisabledMessage =
              'recovery.disableMessage.unsupportedCloudNativeSnapshot';
            return true;
          }
      }

      $scope.recoveryDisabledMessage = '';
      return false;
    }

    /**
     * Determines if the given replicaVec is the selected one or not.
     *
     * @method    isTargetSelected
     * @param     {object}   replicaVec   The replicaVec to check
     * @returns   {boolean}   True if the given replicaVec is the same as the
     *                        one selected.
     */
    function isTargetSelected(replicaVec) {
      return ExternalTargetService.isSameTarget(
        replicaVec,
        $scope.File._archiveTarget
      );
    }

    /**
     * Determines if the remote target selected.
     *
     * @method   isRemoteTargetSelected
     * @return   {boolean}   True if remote target selected, False otherwise.
     */
    function isRemoteTargetSelected() {
      return $scope.getTargetType($scope.File._archiveTarget) !== 'local';
    }

    /**
     * Closes the modal with the response for recovering to VM
     *
     * @method     recoverToVM
     * @return     {Object}  $Q Promise with the modal's response
     */
    function recoverToVM() {
      var out = angular.extend({}, defaultResult, {
        fileEntity: $scope.File,
      });
      return $uibModalInstance.close(out);
    }

    /**
     * Closes the modal with the response for adding more files (multiFile)
     *
     * @method     addMore
     * @return     {Object}  $Q Promise with the modal's response
     */
    function addMore() {
      var out = angular.extend({}, defaultResult, {
        multiFile: true,
        fileEntity: $scope.File,
      });
      return $uibModalInstance.close(out);
    }

    /**
     * Process snapshot will call appropiate action function to perform on
     * recover point.
     *
     * @method   processSnapshot
     * @param    {string}   action   Available actions for snapshot are
     *                               ['recoverToServer', 'save', 'addMore',
     *                               'downloadNow'].
     */
    function processSnapshot(action) {
      // Assign the archivalTarget if the one passed in is type 3 (archive) or
      // greater. Otherwise undefined (local). Backend specific requirement for
      // local target
      if ($scope.File._archiveTarget &&
        $scope.File._archiveTarget.target.type < 3) {
        $scope.File._archiveTarget = undefined;
      }

      switch (action) {
        // invoke recoverToVM method for 'Save' and 'Recover to Server' action
        case 'save':
        case 'recoverToServer':
          recoverToVM();
          break;

        case 'addMore':
          addMore();
          break;

        case 'downloadNow':
          downloadNow();
          break;
      }
    }

    // Initialize!
    snapshotSelectorInit();
  }

})(angular);
