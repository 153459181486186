<mat-list-item *ngIf="item"
  (click)="onClick($event)"
  (keyup.enter)="onKeypress($event)"
  [class.active]="item.isActive"
  [class.sub-item]="isSubItem"
  cogDataId="item-{{ item.displayName }}"
  [inheritDataId]="true"
  tabindex="0">

  <!-- nav icon -->
  <cog-icon class="nav-icon" *ngIf="item.icon" [shape]="item.icon" [ariaLabel]="item.displayName"></cog-icon>

  <!-- nav inner content -->
  <div class="nav-inner">
    <ng-content></ng-content>
  </div>

  <!-- suffix icon -->
  <cog-icon class="suffix-icon"
    [shape]="item.suffixIcon"
    *ngIf="(!item.subNavList || item.subNavList.length === 0) && item.suffixIcon">
  </cog-icon>

  <cog-icon class="expand-icon"
    [shape]="item.isOpen ? 'helix:nav-expanded' : 'helix:nav-collapsed'"
    *ngIf="item.subNavList && item.subNavList.length"
    [ariaLabel]="intl[item.isOpen ? 'close' : 'open']">
  </cog-icon>
</mat-list-item>
