<input
  type="time"
  matInput
  cogDataId="input-field"
  [inheritDataId]="true"
  #timeInput
  class="time-picker-input"
  [attr.aria-labelledby]="formField?.getLabelId()"
  [disabled]="disabled"
  [required]="required"
  [placeholder]="placeholder"
  step="{{ showSeconds ? 1 : 60 }}"
  [value]="selectedTime"
  (change)="selectTime($event.currentTarget.value)"
  (focusin)="onFocusIn()"
  (focusout)="onFocusOut()">
