import { Pipe, PipeTransform } from '@angular/core';
import { Environment } from '@cohesity/iris-shared-constants';
import { TranslateService } from '@ngx-translate/core';

/**
 * This pipe returns translated environment type.
 */
@Pipe({
  name: 'environmentType'
})
export class EnvironmentTypePipe implements PipeTransform {
  constructor(private translateService: TranslateService) { }

  /**
   * Return the translated environment type.
   *
   * @param environment object environment.
   * @returns The translated environment type.
   */
  transform(environment: Environment | string): string {
    return this.translateService.instant(`enum.environment.${environment}`);
  }
}
