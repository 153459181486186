import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AzureProtectionSource, ProtectionSourceNode } from '@cohesity/api/v1';
import { AutoDestroyable } from '@cohesity/utils';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'coh-app-private-endpoints-row',
  templateUrl: './private-endpoints-row.component.html',
  styleUrls: ['./private-endpoints-row.component.scss']
})
export class PrivateEndpointsRowComponent extends AutoDestroyable implements OnInit {
  /**
   * The form group for this component.
   */
  @Input()  privateEndpointField: UntypedFormGroup;

  /**
   * The source of the selected objects for recovery.
   */
  @Input() protectionSource$: Observable<ProtectionSourceNode>;

  /**
   * The list of selected objects' ids.
   */
  @Input() sourceIds$: Observable<number[]>;

  /**
   * List of regions available for selection.
   */
  regions: ProtectionSourceNode[];

  /**
   * List of virtual networks available for selection.
   */
  virtualNetworks$: Observable<ProtectionSourceNode[]>;

  /**
   * List of subnets available for selection.
   */
  subnets$: Observable<ProtectionSourceNode[]>;

  /**
   * The list of selected objects' ids.
   */
  sourceIds: number[] = [];

  constructor() {
    super();
  }

  ngOnInit() {
    // Get all applicable regions using the source and the list of selected objects.
    combineLatest([this.protectionSource$, this.sourceIds$]).pipe(
      this.untilDestroy(),
      filter(([source]) => !!source),
      map(([source, sourceIds]) => {
        this.sourceIds = sourceIds;
        return this.getNodesByType(source, 'kVirtualMachine');
      })).subscribe(nodes => {
        this.regions = this.getRegionNodesBySelection(nodes);
      });

    // Get the list of virtual networks that belong to the selected region.
    this.virtualNetworks$ =
      combineLatest([this.privateEndpointField.get('region').valueChanges, this.protectionSource$]).pipe(
      filter(([region, source]) => !!region &&  !!source),
      map(([region, source]) => this.getNodesByType(source, 'kVirtualNetwork', region))
    );

    // Get the list of subnets that belong to the selected virtual network.
    this.subnets$ =
      combineLatest([this.privateEndpointField.get('virtualNetwork').valueChanges, this.virtualNetworks$]).pipe(
      filter(([vid, vn]) => !!vid &&  !!vn),
      map(([vid, vn]) =>  this.getNodesByTypeOneLvl(vid, vn, 'kSubnet'))
    );
  }

  /**
   * Get the child nodes of a parent node given it's id, type of child nodes and the list of nodes.
   *
   * @param id    Id of the parent node.
   * @param nodes List of all nodes.
   * @param type  Type of the child nodes to filter.
   * @returns     The list of child nodes of given type.
   */
  getNodesByTypeOneLvl(
    id: number,
    nodes: ProtectionSourceNode[],
    type: AzureProtectionSource['type']): ProtectionSourceNode[] {
    const vnode  = nodes.find(vn => vn.protectionSource.id === id);
    const result =
      vnode.nodes?.filter(
        (node: ProtectionSourceNode) => node.protectionSource?.azureProtectionSource?.type === type
      ) || [];

    return result;
  }

  /**
   * Given a tree, get all the nodes of specific type and belong to given location.
   *
   * @param sourceNode  Root node of the tree.
   * @param type        Type of nodes to filter.
   * @param location    Location of the nodes.
   * @returns           The list of child nodes of given type and location.
   */
  getNodesByType(
    sourceNode: ProtectionSourceNode,
    type: AzureProtectionSource['type'],
    location?: string): ProtectionSourceNode[] {
    const result = [];

    sourceNode?.nodes?.forEach((nodeLvlOne: ProtectionSourceNode) => {
      nodeLvlOne.nodes?.forEach((nodeLvlTwo: ProtectionSourceNode) => {
        if (location) {
          if (nodeLvlTwo.protectionSource?.azureProtectionSource?.type === type
            && nodeLvlTwo.protectionSource?.azureProtectionSource?.location === location ) {
            result.push(nodeLvlTwo);
          }
        } else {
          if (nodeLvlTwo.protectionSource?.azureProtectionSource?.type === type) {
            result.push(nodeLvlTwo);
          }
        }
      });
    });

    // Activate VirtualNettwork Field - enforce valueChanges
    this.privateEndpointField.get('virtualNetwork').enable();
    return result;
  }


  /**
   * Get the list of regions to which the selected objects belong to.
   *
   * @param nodes List of nodes selected for protection.
   * @returns     List of region.
   */
  getRegionNodesBySelection(nodes: ProtectionSourceNode[]): ProtectionSourceNode[] {
    const result: ProtectionSourceNode[] = [];

    nodes?.forEach((node: ProtectionSourceNode) => {
      if (this.sourceIds?.includes(node?.protectionSource?.id)) {
        result.push(node);
      }
    });

    const uniqueResult =
      [...new Map(result.map(item => [item.protectionSource.azureProtectionSource.location, item])).values()];

    // Activate Region Field - Enforce value Changes
    this.privateEndpointField.get('region').enable();

    return uniqueResult;
  }
}
