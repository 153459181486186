import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { TagInput } from '../colored-tag.component';

/**
 * Maximum number of tags to show. Rest will be displayed as +<COUNT>
 */
const maxTags = 2;

/**
 * @description
 *
 * Inline list of tags
 */
@Component({
  selector: 'coh-colored-tags',
  templateUrl: './colored-tags.component.html',
  styleUrls: ['./colored-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColoredTagsComponent implements OnInit, OnChanges {
  /**
   * Tags list
   */
  @Input() tags: TagInput[];

  /**
   * The current tag input.
   */
  inputTags$ = new BehaviorSubject<TagInput[]>([]);

  /**
   * Truncated tags list
   */
  tags$ = this.inputTags$.pipe(
    map(tags => (tags.length > maxTags ? tags.slice(0, maxTags) : tags)),
  );

  /**
   * Number of tags truncated
   */
  hiddenCount$ = this.inputTags$.pipe(
    map(tags => (tags.length > maxTags ? tags.length - maxTags : 0)),
  );

  /**
   * Rest of tags to display in tooltip
   */
  restTags$ = this.inputTags$.pipe(
    map(tags =>
      tags.length > maxTags
        ? tags
          .slice(maxTags)
          .map(tag => tag.name)
          .join(', ')
        : ''
    ),
  );

  ngOnInit() {
    this.updateTags();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tags) {
      this.updateTags();
    }
  }

  /**
   * whenever tag input changes, it updates the internal subject so that the updated state is correctly
   * reflected.
   */
  updateTags() {
    this.inputTags$.next(this.tags);
  }
}
