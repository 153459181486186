/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GetSuggestionsResponse } from '../models/get-suggestions-response';
import { PaginationToken } from '../models/pagination-token';

@Injectable({
  providedIn: 'root',
})
export class SuggestionsApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getSuggestions
   */
  static readonly GetSuggestionsPath = '/suggestions';

  /**
   * Get the suggestions for provided search term.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSuggestions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSuggestions$Response(params?: {

    /**
     * Specifies the search term.
     */
    searchTerm?: string;

    /**
     * Specifies the search field.
     */
    searchField?: string;

    /**
     * Specifies the maximum number of suggestions returned per response and it should be less that 1000.
     */
    maxResults?: null | number;

    /**
     * Specifies the pagination token used to get next set of suggestions.
     */
    paginationToken?: PaginationToken;

  }): Observable<StrictHttpResponse<GetSuggestionsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SuggestionsApiService.GetSuggestionsPath, 'get');
    if (params) {

      rb.query('searchTerm', params.searchTerm, {});
      rb.query('searchField', params.searchField, {});
      rb.query('maxResults', params.maxResults, {});
      rb.query('paginationToken', params.paginationToken, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetSuggestionsResponse>;
      })
    );
  }

  /**
   * Get the suggestions for provided search term.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSuggestions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSuggestions(params?: {

    /**
     * Specifies the search term.
     */
    searchTerm?: string;

    /**
     * Specifies the search field.
     */
    searchField?: string;

    /**
     * Specifies the maximum number of suggestions returned per response and it should be less that 1000.
     */
    maxResults?: null | number;

    /**
     * Specifies the pagination token used to get next set of suggestions.
     */
    paginationToken?: PaginationToken;

  }): Observable<GetSuggestionsResponse> {

    return this.getSuggestions$Response(params).pipe(
      map((r: StrictHttpResponse<GetSuggestionsResponse>) => r.body as GetSuggestionsResponse)
    );
  }

}
