import { commonGrowthColumns, commonGrowthReportConfigItems } from './common-growth-report-config-items';
import { ReportColumnConfigs } from '../common-report-items/custom-reports.service';
import { ReportSection } from '../iris-reporting.model';

/**
 * This is the layout config for the source growth report.
 */
export const sourceGrowthReport: ReportSection[] = [
  {
    title: 'reporting.summary',
    items: [
      {
        id: 'statlistItem',
        componentIds: ['1801'],
        componentNames: ['SourceGrowthGlanceBar'],
        params: {
          items: [
            {
              title: 'sources',
              translate: true,
              measurement: {
                valueKey: 'countSourceUuid',
                dataType: 'number',
              },
            },
            ...commonGrowthReportConfigItems.glanceBar,
          ],
        },
      },
    ],
  },
  {
    items: [
      {
        id: 'chartItem',
        componentIds: ['1802'],
        componentNames: ['SourceGrowthByType'],
        title: 'reporting.sourceDataBySourceType',
        params: {
          chartType: 'pie',
          measurements: [
            {
              valueKey: 'sumSrcDataBytes',
              dataType: 'bytes',
            },
          ],
          dimensions: [
            {
              dimensionKey: 'environment',
              maxValues: 3,
            },
          ],
        },
      },
      {
        id: 'tableItem',
        componentIds: ['1803'],
        componentNames: ['SourceGrowthTopMovers'],
        title: 'reporting.topMoversBySourceData',
        params: {
          noHeader: true,
          columns: [
            {
              title: '',
              translate: true,
              key: 'sourceName',
              icon: {
                key: 'objectType',
                dataType: 'objectTypeIcon',
                renderParams: {
                  environmentKey: 'environment',
                },
              },
              primary: {
                key: 'sourceName',
                dataType: 'text',
                renderParams: {
                  width: 'lg',
                },
              },
              subtitle: {
                key: 'environment',
                dataType: 'environmentName',
              },
            },
            {
              title: '',
              key: 'srcDataDailyGrowthRate',
              primary: {
                key: 'srcDataDailyGrowthRate',
                dataType: 'bytes',
              },
            },
          ],
        },
      },
    ],
  },
  {
    title: 'reporting.data',
    isFullData: true,
    items: [
      {
        id: 'tableItem',
        componentIds: ['1800'],
        componentNames: ['AllSourcesGrowth'],
        params: {
          columns: [
            {
              title: 'sourceName',
              translate: true,
              key: 'sourceName',
              icon: {
                key: 'objectType',
                dataType: 'objectTypeIcon',
                renderParams: {
                  environmentKey: 'environment',
                },
              },
              primary: {
                key: 'sourceName',
                dataType: 'text',
                renderParams: {
                  width: 'lg',
                },
              },
              subtitle: {
                key: 'environment',
                dataType: 'environmentName',
              },
            },
            ...commonGrowthReportConfigItems.table,
          ],
        },
      },
    ],
  },
];

/**
 * This is metadata needed to determine available chart customizations.
 */
export const sourceGrowthColumns: ReportColumnConfigs = {
  ...commonGrowthColumns
};
