import { Inject, Injectable } from '@angular/core';
import { DataFilterValue } from '@cohesity/helix';
import { AjaxHandlerService } from '@cohesity/utils';
import { flatMap, groupBy, isEqual } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, map, takeUntil, tap } from 'rxjs/operators';

import { RestoreSearchResult } from '../model/restore-search-result';
import { ObjectSearchProvider, searchProviderToken } from './object-search-provider';


/**
 * Service used for searching for object snapshots
 */
@Injectable()
export class ObjectSearchService {
  /**
   * A map of all objects that have been seen by the search service.
   */
  allObjects: {
    [id: number]: RestoreSearchResult[];
  } = {};

  /**
   * A map containing previous search results.
   */
  cachedSearches: {
    [searchHash: string]: Set<number | string>;
  } = {};

  /**
   * The current search results.
   */
  searchResults$ = new BehaviorSubject<RestoreSearchResult[]>([]);

  /**
   * Whether a search is in progress or not.
   */
  searchPending$ = new BehaviorSubject<boolean>(false);

  /**
   * Currently applied filters.
   */
  private _filterValues = new BehaviorSubject<DataFilterValue<any>[]>([]);

  /**
   * Updates the filter values.
   */
  set filterValues(filterValues: DataFilterValue<any>[]) {
    this._filterValues.next(filterValues);
  }

  /**
   * Get the existing filters.
   * This is for using the combination of advanced and cog filters, instead
   * of overwriting one with another.
   */
  get filterValues() {
    return this._filterValues.getValue();
  }

  /**
   * The current search query
   */
  private _searchQuery = new Subject<string>();

  /**
   * Updates the search query
   */
  set searchQuery(searchQuery: string) {
    this._searchQuery.next(searchQuery);
  }

  /**
   * An observable of the search query.
   */
  searchQuery$ = this._searchQuery.asObservable();

  /**
   * Triggers when a new search is made so that the old one gets canceled.
   */
  private cancelSearch$ = new Subject<void>();

  constructor(
    @Inject(searchProviderToken) private searchProvider: ObjectSearchProvider,
    private ajaxHandler: AjaxHandlerService
  ) {
    // Trigger a new search when the search query changes.
    combineLatest([this._searchQuery, this._filterValues])
      // If multiple filters change at once, prevent them from triggering too many
      // api calls.
      .pipe(
        debounceTime(5),
        distinctUntilChanged(
          ([query1, filters1], [query2, filters2]) => query1 === query2 && isEqual(filters1, filters2)
        )
      )
      .subscribe(([query, filterValues]) => {
        this.updateSearchResults(query, filterValues);
      });
  }

  /**
   * Makes an API search for a given query. Objects are cached in a map, and searches are cached
   * as a hash of the search params and result ids.
   *
   * TODO: Add filter and pagination info here.
   *
   * @param   query   The Search query to lookup.
   */
  private updateSearchResults(query: string, filters: DataFilterValue<any>[]) {
    const searchHash = this.hashSearchParams(query, filters);
    const cachedSearch = this.cachedSearches[searchHash];
    if (cachedSearch) {
      this.searchResults$.next(flatMap([...cachedSearch], id => this.allObjects[id]));
      return;
    }

    this.doSearch(query, filters)
      .pipe(
        // Add all of the objects to our master map.
        tap(objects => {
          // Group objects by their IDs.
          const groups = groupBy(objects, object => object.id);

          // Overwrite the objects map with the ones we found in the search.
          for (const [k, v] of Object.entries(groups)) {
            this.allObjects[k] = v;
          }
        }),

        // Cache the search result in case it is used again later.
        tap(objects => (this.cachedSearches[searchHash] = new Set(objects.map(object => object.id))))
      )
      .subscribe(
        searchResults => this.searchResults$.next(searchResults),
        error => this.ajaxHandler.handler(error)
      );
  }

  /**
   * Makes the actual API call for a search.
   *
   * @param   query   The Search query to lookup.
   * @returns The search results as an array of ids.
   */
  private doSearch(query: string, filters: DataFilterValue<any>[]): Observable<RestoreSearchResult[]> {
    if (!query) {
      return of([]);
    }
    this.cancelSearch$.next();
    const search$ =  this.searchProvider.doObjectSearch(query, filters).pipe(
      takeUntil(this.cancelSearch$),
      map(results => results || []),
      finalize(() => this.searchPending$.next(false))
    );
    this.searchPending$.next(true);
    return search$;
  }

  /**
   * Returns a string hash of the search params.
   *
   * TODO: hash pagination and filter params as well.
   *
   * @param   query   The search query.
   * @returns A unique hash of the search params.
   */
  private hashSearchParams(query: string, filters: DataFilterValue<any>[]) {
    const filterHash = filters.map(filterValue => `${filterValue.key}_${JSON.stringify(filterValue.value)}`).join('|');
    return `${query}__${filterHash}`;
  }
}
