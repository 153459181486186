import { Component } from '@angular/core';
import { PopoverRef } from '@cohesity/helix';
import { IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { RecoveryObject } from 'src/app/modules/restore/restore-shared/model/recovery-object';

/**
 * Used by cogPopover to bring up recovery object popover by referencing to this class
 * (assign RecoveryObjectPopoverComponent to popover used by cogPopover).
 *
 * @example
 *   <span class="icn-view"
 *     [cogPopover]="popover"
 *     [popoverData]="object"
 *     (click)="$event.stopPropagation()">
 *   </span>
 */
@Component({
  selector: 'coh-recovery-object-popover',
  templateUrl: './recovery-object-popover.component.html',
  styleUrls: [ './recovery-object-popover.component.scss' ],
})
export class RecoveryObjectPopoverComponent {
  /**
   * Recovery object.
   */
  object: RecoveryObject;

  /**
   * The recovery target type.
   */
  targetType: string;

  /**
   * Constructor.
   */
  constructor(
    private irisContextService: IrisContextService,
    readonly popoverRef: PopoverRef,
  ) {
    this.object = popoverRef.data;
    if (this.object.snapshotTargetType === 'Local') {
      this.targetType = 'Local';
    } else if (this.object.archivalTargetInfo) {
      const targetType = this.object.archivalTargetInfo.targetType;

      if (targetType && ['Cloud', 'Nas', 'Tape'].includes(targetType)) {
        this.targetType = targetType;
      }
    }
  }

  /**
   * Checks if user is DMS user.
   *
   * @returns  true if user is DMS user
   */
  isDmsScope(): boolean {
    return isDmsScope(this.irisContextService.irisContext);
  }
}
