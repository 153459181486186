import { ComponentType } from '@angular/cdk/portal';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { DataTreeSelection } from '@cohesity/helix';
import { flagEnabled, IrisContextService, isClusterScope, isDmsScope } from '@cohesity/iris-core';
import { SourceSelection, TagCategory, ViewFilterType } from '@cohesity/iris-source-tree';
import { TranslateService } from '@ngx-translate/core';
import { Environment } from 'src/app/shared/constants';

import { BaseProtectionSourceService } from '../shared/base-protection-source.service';
import { AcropolisOptionsComponent } from './acropolis-options/acropolis-options.component';
import { HypervOptionsComponent } from './hyperv-options/hyperv-options.component';
import { VmHostOptionsComponent } from './vm-host-options/vm-host-options.component';
import { VmSourceDataNode } from './vm-source-data-node';
import { TagTypes, VmViewFilters } from './vm-view-filters';

/**
 * Tree service for vm
 */
export class VmSourceTreeService extends BaseProtectionSourceService<VmSourceDataNode> {
  /**
   * A reference to vm view filters, to show a physical, folder, flat, and tagged list view.
   */
  vmViewFilters: VmViewFilters;

  constructor(
    readonly environment: Environment,
    readonly translate: TranslateService,
    private irisContext: IrisContextService
  ) {
    super(flagEnabled(irisContext.irisContext, 'objectProtectionOverwrite'));
    this.vmViewFilters = new VmViewFilters(
      this.environment,
      this.filters,
      this.treeControl,
      this.treeTransformer,
      this.dataTreeSource,

      // This configures the simple tag view to only be available on DMS. For on-prem, we still need the full tag view
      // to be able to protecting multiple tags at once.
      isDmsScope(irisContext.irisContext)
    );
  }

  /**
   * Lookup the best filter type for nodes. Depending on the node type, show either the physical, folder, flat, or
   * tag type
   *
   * @param   node   The node to check. This is usually the root node for object protection.
   * @returns The best view type to filiter on.
   */
  getBestViewFilterForNode?(node: VmSourceDataNode): ViewFilterType | string {
    if (node.isTag) {
      return this.vmViewFilters.useSimpleTags ? ViewFilterType.SimpleTag : ViewFilterType.Tag;
    }
    if (node.envSourceVMware && node.type === 'kFolder' && node.folderType !== 'kHostFolder') {
      return ViewFilterType.Folder;
    }

    if (node.isLeaf) {
      return ViewFilterType.Flat;
    }

    return ViewFilterType.Physical;
  }

  /**
   * Gets a component to render for a source's special parameters. This does not apply to every node
   * and can be null for certain data types.
   */
  getSpecialParametersComponent(node: VmSourceDataNode): ComponentType<any> {
    if (node.type === 'kVirtualMachine') {
      if (node.environment === Environment.kVMware) {
        return VmHostOptionsComponent;
      } else if (node.environment === Environment.kAcropolis &&
        isClusterScope(this.irisContext.irisContext) &&
        flagEnabled(this.irisContext.irisContext, 'acropolisDiskFilter')) {
        return AcropolisOptionsComponent;
      } else if (node.environment === Environment.kHyperV &&
        isClusterScope(this.irisContext.irisContext) &&
        flagEnabled(this.irisContext.irisContext, 'hypervDiskFilter')) {
        return HypervOptionsComponent;
      }
    }
    return null;
  }

  /**
   * Parse a list of tree nodes to determine available tags that can be filtered by.
   *
   * @param   allNodes   The complete list of nodes.
   * @param   selection  The current selection.
   * @returns A list of tag categories that can be filtered by.
   */
  getTagCategories(allNodes: VmSourceDataNode[], selection: DataTreeSelection<VmSourceDataNode>): TagCategory[] {
    const categories = allNodes.filter(node => TagTypes.includes(node.type) && node.expandable);
    const tagMap: any = {};
    const tags: TagCategory[] = [];

    // All available tags, grouped by category
    categories.forEach(category => {
      tags.push({
        name: category.name,
        tagGroups: category.data.nodes.map((tagNode: ProtectionSourceNode) => {
          tagMap[tagNode.protectionSource.id] = tagNode.protectionSource.name;
          return [
            {
              id: tagNode.protectionSource.id,
              name: tagNode.protectionSource.name,
            },
          ];
        }),
      });
    });

    // Add a category for currently excluded tags
    const excludedTags = (selection.excluded || []).filter(node => node.type === 'kTag');
    if (excludedTags.length) {
      tags.unshift({
        name: this.translate.instant('excluded'),
        tagGroups: excludedTags.map(tagNode =>
          tagNode.ids.map(tagId => ({
            id: Number(tagId),
            name: tagMap[tagId],
          }))
        ),
      });
    }

    // Add a category for currently auto protected tags
    const autoProtectedTags = (selection.autoSelected || []).filter(node => node.type === 'kTag');
    if (autoProtectedTags.length) {
      tags.unshift({
        name: this.translate.instant('autoProtected'),
        tagGroups: autoProtectedTags.map(tagNode =>
          tagNode.ids.reduce((tagsUnderCategory, tagId) => {
            if (tagMap[tagId]) {
              tagsUnderCategory.push({
                id: Number(tagId),
                name: tagMap[tagId],
              });
            }
            return tagsUnderCategory;
          }, [])
        ),
      });
    }

    return tags;
  }

  /**
   * Returns whether a node is a leaf or not. This can be used to calculate selection totals.
   */
  isLeaf(treeNode: VmSourceDataNode): boolean {
    return treeNode.isLeaf;
  }

  /**
   * Only expand certain node types by default.
   *
   * @param   treeNode   The treenode to check.
   * @return  True if the node should be expanded by default.
   */
  shouldExpandNodeOnLoad(treeNode: VmSourceDataNode): boolean {
    return (
      ['kHostGroup', 'kDatacenter', 'kComputeResource', 'kStandaloneHost', 'kStandaloneCluster'].includes(
        treeNode.type
      ) ||
      (treeNode.type === 'kFolder' && treeNode.envSource['folderType'] !== 'kHostFolder')
    );
  }

  /**
   * Transforms the node object from the api into a Protection Source Tree node to pass to the tree.
   *
   * @param   node   The original node.
   * @param   level  The level in the tree.
   * @return  An VmSourceDataNode that can be displayed in the tree.
   */
  transformData(node: ProtectionSourceNode, level: number): VmSourceDataNode {
    return new VmSourceDataNode(this.environment, node, level, isDmsScope(this.irisContext.irisContext));
  }

  /**
   * Convert the data tree selection model to the job selection model.
   *
   * @param   selection   The selection from the tree.
   * @return  The job selection info.
   */
  transformFromDataTreeSelection(selection: DataTreeSelection<VmSourceDataNode>): SourceSelection {
    // Sources include explicitly selected leaf node, and auto selected items
    const autoSelected = selection.autoSelected.filter(item => !item.isTag);
    const sources = selection.selected.filter(item => item.isLeaf).concat(autoSelected);

    return {
      // non-tag excluded items
      excludeSourceIds: selection.excluded.filter(item => !item.isTag).map(item => Number(item.id)),

      // excluded tags. The ids for these need to be converted to number since the ids property splits
      // a string and leaves them as strings. This comes out to an array of arrays.
      excludeVmTagIds: selection.excluded.filter(item => item.isTag).map(item => item.ids.map(id => Number(id))),

      // non-tag source ids
      sourceIds: sources.map(item => Number(item.id)),

      // Special source params
      sourceSpecialParameters: Object.values(selection.options || {}).filter(option => {
        if (!option) {
          return false;
        }
        return Object.values(option.vmSpecialParameters || {}).some(param => !!param);
      }),

      // tag source ids, an array of arrays.
      vmTagIds: selection.autoSelected.filter(item => item.isTag).map(item => item.ids.map(id => Number(id))),
    };
  }

  /**
   * Convert source selection to the data tree selection model.
   * source ids can be either selected items or auto selected items, nodes with children are
   * assumed to be auto selected. Nodes can be in the tree multiple times, and should not be
   * duplicated in the selection.
   *
   * @param   allNodes         The unfiltered list of tree nodes.
   * @param   sourceSelection  The job selection.
   * @return  A data tree selection model.
   */
  transformToDataTreeSelection(
    allNodes: VmSourceDataNode[],
    sourceSelection: SourceSelection
  ): DataTreeSelection<VmSourceDataNode> {
    const treeSelection = super.transformToDataTreeSelection(allNodes, sourceSelection);

    if (sourceSelection.vmTagIds) {
      sourceSelection.vmTagIds.forEach(tagIds =>
        treeSelection.autoSelected.push(this.vmViewFilters.createTagNode(tagIds, allNodes))
      );

      // If a node is in the selection list but is protected by a tag, we shouldn't mark it as explicitly
      // selected.
      treeSelection.selected = treeSelection.selected.filter(
        selected => !this.treeControl.matchTagSelection(treeSelection.autoSelected, selected)
      );
    }

    if (sourceSelection.excludeVmTagIds) {
      sourceSelection.excludeVmTagIds.forEach(tagIds =>
        treeSelection.excluded.push(this.vmViewFilters.createTagNode(tagIds, allNodes))
      );
    }
    return treeSelection;
  }
}
