// COMPONENT: SQL Management

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.sql-manager', [])
    .config(configFn);

  configFn.$inject = ['$stateProvider', '$urlRouterProvider'];
  function configFn($stateProvider, $urlRouterProvider) {
    var modifyAccess = 'PROTECTION_SOURCE_MODIFY';

    // Configure the states & routes
    $stateProvider
      .state('sql-register', {
        help: 'protection_sources_sql_unregister',
        title: 'Register a SQL Server',
        canAccess: modifyAccess,
        parentState: 'sources-new',
        url: '^/protection/sources/sql/register/{entityId}',
        params: {
          entityId: { type: 'string' },
          entity: undefined,
          host: undefined,
          mode: 'new',
        },
        views: {
          '': {
            controller: 'modifySQLRegistrationController',
            templateUrl: 'app/views/page-layouts/ls.html',
          },
          'col-l@sql-register': {
            templateUrl: 'app/protection/sources/sql-manager/sql.modify.html',
          },
        },
      })
      .state('sql-unregister', {
        help: 'protection_sources_sql_unregister',
        title: 'Unregister SQL Server',
        url: '^/protection/sources/sql/unregister/{entityId}',
        canAccess: modifyAccess,
        parentState: 'sources-new',
        params: {
          entityId: { type: 'int' },
          host: undefined,
        },
        views: {
          '': {
            controller: 'unregisterSQLHostController',
            templateUrl: 'app/views/page-layouts/ls.html',
          },
          'col-l@sql-unregister': {
            templateUrl: 'app/protection/sources/sql-manager/sql.unregister.html',
          },
        },
      })
      .state('sql-modify', {
        help: 'protection_sources_sql_unregister',
        title: 'Edit SQL Server Registration',
        url: '^/protection/sources/sql/modify/{entityId}?{username}',
        canAccess: modifyAccess,
        parentState: 'sources-new',
        params: {
          entity: undefined,
          host: undefined,
          mode: 'edit',
          entityId: { type: 'string' },
          username: { type: 'string' },
        },
        views: {
          '': {
            controller: 'modifySQLRegistrationController',
            templateUrl: 'app/views/page-layouts/ls.html',
          },
          'col-l@sql-modify': {
            templateUrl: 'app/protection/sources/sql-manager/sql.modify.html',
          },
        },
      });
  }


})(angular);
