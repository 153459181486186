import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';

import { BannerModule } from '../banner';
import { CalendarModule } from '../calendar';
import { DataIdModule } from '../data-id/data-id.module';
import { TimePickerModule } from '../time-picker';
import { DateRangePickerComponent, DateRangePickerHeaderComponent } from './date-range-picker.component';

@NgModule({
  imports: [
    BannerModule,
    CalendarModule,
    CommonModule,
    DataIdModule,
    FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    TimePickerModule,
  ],
  declarations: [DateRangePickerComponent, DateRangePickerHeaderComponent],
  exports: [DateRangePickerComponent],
})
export class DateRangePickerModule { }
