<form [formGroup]="pauseRunsForm" cohValidateOnSubmit (validatedSubmit)="onConfirm()">
  <h1 mat-dialog-title cogDataId="pause-runs-dialog-title">
    {{ data.title | translate }}
  </h1>
  <mat-dialog-content>
    <ng-container *ngIf="data.subtitle">
      <div class="margin-bottom-sm" cogDataId="pause-runs-dialog-sub-title">
        {{data.subtitle | translate}}
      </div>
    </ng-container>

    <div class="flex-column-sm">
      <mat-checkbox formControlName="enableAddNote" cogDataId="add-note-checkbox">
        {{'protectionGroups.pauseFutureRunsJob.addNoteLabel' | translate}}
      </mat-checkbox>
      <mat-form-field>
        <mat-label>{{'note' | translate}}</mat-label>
        <input
          matInput
          [placeholder]="'protectionGroups.pauseFutureRunsJob.addNotePlaceholder' | translate"
          cogDataId="add-note-input"
          formControlName="note" />
        <mat-hint cogDataId="note-hint">
          {{ 'protectionGroups.pauseFutureRunsJob.optionalPauseNote' | translate: {
              value: noteMaxLength - (pauseRunsForm.controls.note?.value?.length ?? 0)
            }
          }}
        </mat-hint>
        <mat-error
          *ngIf="pauseRunsForm.controls.note.errors?.required"
          cogDataId="note-required-error">
          {{ 'errors.protectionGroupPauseNoteRequired' | translate}}
        </mat-error>
        <mat-error
          *ngIf="pauseRunsForm.controls.note.errors?.maxlength"
          cogDataId="note-max-length-error">
          {{ 'errors.protectionGroupPauseNoteMaxlength' | translate: { value: noteMaxLength } }}
        </mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close color="primary" cogDataId="cancel-pause-runs">
      {{ 'cancel' | translate }}
    </button>
    <button
      mat-flat-button
      type="submit"
      color="primary"
      cdkFocusInitial
      cogDataId="confirm-pause-runs">
      {{ 'confirm' | translate }}
    </button>
  </div>
</form>
