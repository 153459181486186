import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TapeMediaInformation, VaultsServiceApi } from '@cohesity/api/v1';
import { ConfirmationDialogComponent } from '@cohesity/shared-dialogs';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services';

import { RestorePointSelection } from '../../model/restore-point-selection';

@Component({
  selector: 'coh-tape-media-info',
  templateUrl: './tape-media-info.component.html',
  styleUrls: ['./tape-media-info.component.scss']
})
export class TapeMediaInfoComponent implements OnChanges {
  /**
   * Either pass the recovery selection. The "qstarArchiveJobUid" value will be
   * derived from the objects selectiom. "qstarArchiveJobUid" is the "upload"
   * id returned in protected objects result.
   */
  @Input() objects: RestorePointSelection[] = [];

  /**
   * Or directly pass the qstar restore task uid to show the tap info table of.
   * "qstarRestoreTaskUid" is the "download" id returned in recovery response.
   */
  @Input() qstarRestoreTaskUid: string;

  /**
   * Whether the component is loading.
   */
  loading = false;

  /**
   * If set, this warning message is displayed.
   */
  warningMessage: string;

  /**
   * Variable to store current selected object with tape archive snapshot.
   */
  tapeArchiveObject: RestorePointSelection;

  /**
   * API response of ArchiveMediaInfo call.
   */
  archiveMediaInfo$: Observable<TapeMediaInformation[]>;

  constructor(
    private dialogService: DialogService,
    private translate: TranslateService,
    private vaultService: VaultsServiceApi,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.objects || changes.qstarRestoreTaskUid) {
      this.setArchiveMediaInfo();
    }
  }

  /**
   * Function to validate recovery selection.
   *
   * All objects belonging to the recovery selection should belong to the same
   * archival task.
   *
   * @param qstarArchiveJobUid Qstar archive job uid.
   */
  validateRecoverySelection(qstarArchiveJobUid: string) {
    const invalidObjects = [];

    for (const object of this.objects) {
      // Magneto requires all backups to use the same snapshot for an archive
      // recovery. Populate an array of invalid objects and prompt users with
      // their names. The "archivalTaskId" is what the API calls
      // "qstarArchiveJobId" here.
      const archivalTaskId =
        object.archiveTargetInfo && object.archiveTargetInfo.archivalTaskId;

      if (qstarArchiveJobUid !== archivalTaskId) {
        invalidObjects.push(object);
      }
    }

    if (invalidObjects.length) {
      // TODO(pg-future): Improve messaging here and add more functionality.
      this.warningMessage = this.translate.instant(
        'recovery.vm.tapeRecoveryMessage',
        {vmNames: invalidObjects.map(object => object.objectInfo.name).join(', ')}
      );

      this.dialogService.showDialog(ConfirmationDialogComponent, {
        title: 'recovery.vm.tapeRecoveryTitle',
        message: this.warningMessage,
        noCancel: true,
      });
    } else {
      this.warningMessage = null;
    }
  }

  /**
   * Function to retrieve qstar archive job uid from the recovery selection.
   */
  getQstarArchiveJobUidFromRecoverySelection(): string {
    // Find the object which is getting recovered from the tape archive
    // snapshot.
    this.tapeArchiveObject = (this.objects || []).find(object =>
      object.archiveTargetInfo && object.archiveTargetInfo.targetType === 'Tape'
    );

    if (!this.tapeArchiveObject) {
      return;
    }

    // The "archivalTaskId" is what the API calls "qstarArchiveJobId" here.
    const archivalTaskId =
      this.tapeArchiveObject.archiveTargetInfo.archivalTaskId;

    // Validate the recovery objects and show any relevant errors.
    this.validateRecoverySelection(archivalTaskId);

    return archivalTaskId;
  }

  /**
   * Function to setup archiveMediaInfo$ observable to show the tape archive
   * table.
   */
  setArchiveMediaInfo() {
    this.archiveMediaInfo$ = null;

    // Either use the provided qstarRestoreTaskUid as is or derive
    // qstarArchiveJobId from recovery selection. API result's key for both of
    // these is called "archivalTaskId", but they return different things.
    const tapeArchivalTaskId =
      this.qstarRestoreTaskUid ||
      this.getQstarArchiveJobUidFromRecoverySelection();

    if (!tapeArchivalTaskId) {
      return;
    }

    const [
      clusterId,
      clusterIncarnationId,
      qstarTaskId,
    ] = tapeArchivalTaskId.split(':').map(Number);

    this.loading = true;

    this.archiveMediaInfo$ = this.vaultService.GetArchiveMediaInfo({
      clusterId,
      clusterIncarnationId,

      // For qstarRestoreTaskUid (archive "download" id returned in recovery
      // response), use "qstarRestoreTaskId", otherwise use "qstarArchiveJobId"
      // ("upload" id returned in protected objects result).
      [this.qstarRestoreTaskUid ? 'qstarRestoreTaskId' : 'qstarArchiveJobId']: qstarTaskId,
    } as any).pipe(finalize(() => this.loading = false));
  }
}
