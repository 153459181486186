import { Injectable } from '@angular/core';
import { MFAServiceApi, SupportMfaConfigInfo } from '@cohesity/api/v2';
import { BANNER_PRIORITY, BannerConfig } from '@cohesity/helix';
import { BaseBannerConfig, flagEnabled, IrisContext, IrisContextService, isMcmOnPrem, isOneHeliosAppliance } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SupportUserMfaBannerConfig extends BaseBannerConfig {
  /**
   * Unique banner representation
   */
  id = 'ng-helios-support-user-mfa-banner';

  /**
   * State to redirect to for configuring support user MFA
   */
  supportUserMfaState = 'access-management.support.list';

  /**
   * provides banner config as observable
   */
  bannerInfo$ = this.irisCtx.irisContext$.pipe(
    switchMap(ctx => this.getSupportMFAConfig(ctx)),
    map(supportMFAConfig => ([{
      id: this.id,
      priority: BANNER_PRIORITY.HIGH,
      isVisible: supportMFAConfig ? !supportMFAConfig.enabled : false,
      status: 'info',
      allowClose: true,
      text: this.translateService.instant('supportUser.banner.text'),
      closeCallback: this.closeCallback,
      actionText: this.translateService.instant('supportUser.banner.goToSetupMFA'),
      actionCallback: () => {
        this.stateService.go(this.supportUserMfaState);
      },
    } as BannerConfig]))
   );

  constructor(
    private irisCtx: IrisContextService,
    private stateService: StateService,
    private mfaService: MFAServiceApi,
    private translateService: TranslateService
  ) {
    super();
    if (flagEnabled(this.irisCtx.irisContext, 'ngUsersAndGroups') ||
      isOneHeliosAppliance(this.irisCtx.irisContext)) {
      this.supportUserMfaState = 'on-prem-access-management.support';
    }
  }

  /**
   * Allow banner based on context
   *
   * @param ctx iris context
   * @returns true/false based on context
   */
  allowBanner(ctx: IrisContext) {
    return (
      !this.isBannerAcknowledged(this.id) &&
      flagEnabled(ctx, 'supportUserMfaEnabled') &&
      !!(Object.keys(ctx.selectedScope).length) &&
      !ctx.selectedScope._nonCluster &&
      !ctx.selectedScope._allClusters &&
      !isMcmOnPrem(ctx) &&
      this.isLoggedIn(ctx) &&
      ctx.privs.CLUSTER_VIEW
    );
  }

  /**
   * Fetch Support MFA Config based on context
   *
   * @returns Support MFA Config
   */
  getSupportMFAConfig(ctx: IrisContext): Observable<SupportMfaConfigInfo> {
    return this.allowBanner(ctx) ? this.mfaService.GetSupportMFAConfig() : of(null);
  }
}
