// Directive: Job Summary Directive

;(function(angular) {
  'use strict';

  angular
    .module('C.jobs')
    .directive('jobSummary', jobSummaryFn);

  function jobSummaryFn(_, $rootScope, $filter, JobService, JobDetailsService,
    ENUM_HOST_TYPE, ENV_GROUPS, ENV_TYPE_CONVERSION, PubJobService, FEATURE_FLAGS) {

    var jobSummaryConfig = {
      restrict: 'AE',
      link: linkFn,
      scope: {
        job: '=',
        policy: '=',
        tree: '=?',
      },
      templateUrl: 'app/protection/jobs/job-summary/job-summary.html'
    };

    function linkFn(scope, elem, attrs) {

      scope.ENUM_HOST_TYPE = ENUM_HOST_TYPE;
      scope.ENV_GROUPS = ENV_GROUPS;
      scope.ENV_TYPE_CONVERSION = ENV_TYPE_CONVERSION;

      // @type {Object} text for template
      scope.text = $rootScope.text.protectionJobsJobSummaryJobSummary;

      // @type {Array} list of VMs currently protected by this job
      scope.objectsList = [];

      // @type {Array} List of exclude objects excluded in this job
      scope.excludedObjects = [];

      // @type {Array} list of entities missing from the job.
      scope.missingObjectList = [];

      /**
       * @type {Integer} represents number of schedules/scripts for a
       * Remote Adapter Job
       */
      scope.scriptsCount = 0;

      scope.scriptKeys = JobService.getScriptKeys();

      /**
       * init function for the directive
       *
       * @method     init
       */
      function activate() {
        var objs = JobDetailsService.getObjectsList(scope.tree);

        // Alpha sort the objects list by object name
        scope.objectsList = $filter('orderBy')(objs, function objSort(obj) {
          return obj.entity.displayName;
        });

        // Compute missing entities from EH in job.
        if (FEATURE_FLAGS.showMissingObjectsWithinJobSummary) {
          scope.job.sources.forEach(entity => {
            if (!entity._foundInTree) {
              scope.missingObjectList.push(entity.entities[0]);
            }
          });
        }

        if (scope.job.type === 3 && scope.job.sourceFilters &&
          scope.job.sourceFilters.excludeSourceFilterVec) {
          PubJobService.getExcludeSourceIds(scope.job).then(
            function getExcludeIds(excludedIds) {
              _buildExcludeObjectsList(scope.objectsList, excludedIds);

              // Filter the list to remove excluded objects from all objects.
              scope.objectsList = scope.objectsList.filter(obj => {
                return !excludedIds.includes(obj.entity.id);
              });
            }
          );

        // for Remote Adapter Jobs, count the number of scripts
        } else if (scope.job.type === 5) {
          scope.scriptKeys.forEach(function loopKeysFn(scriptKey) {
            if (scope.job.preScript.hasOwnProperty(scriptKey)) {
              scope.scriptsCount++;
            }
          });

        // for Exchange, filter object list from appEntityIdVec
        } else if (scope.job.type === 17) {
          scope.objectsList = scope.objectsList.filter(function filterObject(object) {
            var appEntityIdVec = _.get(scope.job, 'backupSourceParams[0].appEntityIdVec');
            if (!appEntityIdVec) {
              return true;
            }
            return appEntityIdVec.includes(object.entity.id);
          });
        } else if (scope.job.type === 22) {
          // Remove all RDS instances from "Show VMs" which can show up
          // id "AutoProtect" is used
          scope.objectsList = scope.objectsList.filter(obj => {
            return obj._normalizedEntity.type !== 12;
          });

        // for Universal Data Adapter
        } else if (scope.job.type === 46) {
          // If this is an EH enabled job, scope.objectsList is already populated with the correct
          // value, so do not read from the scope.job
          if (scope.objectsList == 0 )
          {
            var udaObjectsList = _.get(scope.job, 'envBackupParams.udaBackupJobParams.udaObjects', []);
            scope.objectsList = udaObjectsList.map(obj => ({
              entity: {
                type: 46,
                displayName: obj.name,
                udaEntity: {
                  type: 1
                }
              }
            }));
          }
        // in case of cassandra log backup job, we don't want to
        // to show any object as protected because it is at a source
        // level only, by itself this cannot be used to do a recovery,
        // it requires normal backup jobs to be there.
        // 38 here is envType enum for kCassandra
        // Ref: iris/apps/iris-ui-angular/src/app/shared/constants/env.constants.ts
        } else if (scope.job.type === 38) {
          var cassandraParams = _.get(scope.job, 'envBackupParams.nosqlBackupJobParams.cassandraBackupJobParams');
          if (cassandraParams.isOnlyLogBackupJob) {
            scope.objectsList = [];
          }
        }
      }

      /**
       * Traverse the entity tree and push all the excluded nodes to the
       * excludeObjects list to be displayed in settings tab
       *
       * @method    _buildExcludeObjectsList
       * @param     {object}   tree         Entity Hierarchy tree
       * @param     {array}    excludedIds  List of exclude ids to be excluded
       */
      function _buildExcludeObjectsList(tree, excludedIds) {
        // Traverse the tree and push excluded nodes
        tree.forEach(treeNode => {
          if (excludedIds.includes(treeNode.entity.id)) {
            scope.excludedObjects.push(treeNode);
          }
          if (treeNode.children) {
            _buildExcludeObjectsList(treeNode.children, excludedIds);
          }
        });
      }

      activate();
    }

    return jobSummaryConfig;
  }

}(angular));
