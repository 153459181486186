/* eslint-disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CohesityAgentDeploymentApiConfiguration, CohesityAgentDeploymentApiConfigurationInterface } from './cohesity-agent-deployment-api-configuration';

import { AgentDeploymentsService } from './services/agent-deployments.service';

/**
 * Provider for all CohesityAgentDeploymentApi services, plus CohesityAgentDeploymentApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    CohesityAgentDeploymentApiConfiguration,
    AgentDeploymentsService
  ],
})
export class CohesityAgentDeploymentApiModule {
  static forRoot(customParams: CohesityAgentDeploymentApiConfigurationInterface): ModuleWithProviders<CohesityAgentDeploymentApiModule> {
    return {
      ngModule: CohesityAgentDeploymentApiModule,
      providers: [
        {
          provide: CohesityAgentDeploymentApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
