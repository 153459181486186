import { Injectable } from '@angular/core';
import { flagEnabled, IrisContextService, isDraasScope } from '@cohesity/iris-core';
import { HookMatchCriteria, Transition } from '@uirouter/core';
import { NGXLogger } from 'ngx-logger';
import { map } from 'rxjs/operators';

import { DraasService } from '../../services/draas.service';
import { GuardPriority, GuardResult, StateGuard } from '../state-guard';

/**
 * A state guard to ensure that the DRaaS initial onboarding is completed by
 * the user.
 */
@Injectable({ providedIn: 'root' })
export class DraasOnboardingGuard implements StateGuard {
  /**
   * The state prefixes to guard for.
   */
  private readonly statePrefixes = ['dashboards.disaster-recovery', 'data-sites', 'data-pools', 'disaster-recovery'];

  guardPriority = GuardPriority.App;

  matchCriteria: HookMatchCriteria = {
    to: state => this.statePrefixes.some(prefix =>
      state.name.startsWith(prefix)) && state.name !== 'disaster-recovery.onboarding',
  };

  constructor(
    private irisContextService: IrisContextService,
    private draasService: DraasService,
    private logger: NGXLogger
  ) {}

  onStart(transition: Transition): GuardResult {
    const draasScope = isDraasScope(this.irisContextService.irisContext);
    const onboardingEnabled = flagEnabled(this.irisContextService.irisContext, 'keplerOnboardingWizardEnabled');

    this.logger.debug(`[DRaaS Guard]: Is DRaaS scope? ${draasScope}`);
    this.logger.debug(`[DRaaS Guard]: Onboarding enabled? ${onboardingEnabled}`);

    if (!draasScope || !onboardingEnabled) {
      return true;
    }

    this.logger.debug(`[DRaaS Guard]: Fetching user details`);

    return this.draasService.getCurrentUser().pipe(
      map(resp => {
        const onboardingDone = resp?.onboardingCompleted ?? false;
        this.logger.debug(`[DRaaS Guard]: User details: `, resp);

        if (onboardingDone) {
          this.logger.info(`[DRaaS Guard]: Onboarding already done`);
          return true;
        }

        this.logger.info(`[DRaaS Guard]: Launching onboarding workflow`);
        return transition.router.stateService.target('disaster-recovery.onboarding');
      })
    );
  }
}
