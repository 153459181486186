// Controller: Source Details - Protected tab controller

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.sources')
    .controller('sourceDetailsProtectedController', sourceDetailsProtectedControllerFn);

  function sourceDetailsProtectedControllerFn($state, $scope, PubSourceService,
    SourceInfo, SourcesUtil, evalAJAX, SOURCE_TYPE_DISPLAY_NAME, ENUM_ENV_TYPE,
    ENV_GROUPS, ENUM_HOST_TYPE, SOURCE_TYPE_GROUPS, $filter, FEATURE_FLAGS,
    SOURCE_KEYS, HADOOP_ENVIRONMENTS, NOSQL_ENVIRONMENTS, PubJobServiceFormatter,
    UserService, $q, $translate, NgUserStoreService) {

    var $ctrl = this;

    _.assign($ctrl, {
      $onInit: onInit,
      ENUM_ENV_TYPE: ENUM_ENV_TYPE,
      ENUM_HOST_TYPE: ENUM_HOST_TYPE,
      ENV_GROUPS: ENV_GROUPS,
      isProtectedSizeAvailable: PubSourceService.isProtectedSizeAvailable,
      getProtectedSize: getProtectedSize,
      source: SourceInfo,
      environment: $state.params.environment,
      getNames: getNames,
      decorateRpoJobs: decorateRpoJobs,
      goToProtectionJobFlow: goToProtectionJobFlow,
      getEntityName: getEntityName,
      ngtStatus: ngtStatus,
      SOURCE_TYPE_DISPLAY_NAME: SOURCE_TYPE_DISPLAY_NAME,
      SOURCE_TYPE_GROUPS: SOURCE_TYPE_GROUPS,
    });

    /**
     * calls necessary methods that get necessary data for display
     */
    function onInit() {
      _getProtectedSources($state.params.id);
    }

    function _getProtectedSources(id) {
      const promises = [
        PubSourceService.getProtectedSources({
          id: id,
          environment: $state.params.environment,
          includeRpoSnapshots: FEATURE_FLAGS.rpoPolicyEnabled,

          // Avoid overfetching when displaying protected objects.
          pruneProtectionJobMetadata: true,
        })
      ];

      if (NgUserStoreService.isRestrictedUser()) {
        promises.push(
          PubSourceService.getSource(id,
            {
              excludeTypes: 'kResourcePool',
              excludeKubernetesTypes: FEATURE_FLAGS.excludeKubernetesTypes ? ['kService'] : undefined,
            }
          )
        );
      }

      $q.all(promises)
      .then(function ([ protectedSources, fullSource ]) {
        $ctrl.protectedSources = [];
        const protectedSourceIds = new Set();

        if (protectedSources.length) {
          $ctrl.protectedSources = _formatProtectedSources(protectedSources);
          protectedSources.forEach(({ protectionSource }) => {
            protectedSourceIds.add(protectionSource.id);
          });
        }

        // Add missing protected sources for restricted users
        if (fullSource &&
          protectedSources.length !== SourceInfo.stats.protectedCount) {
          PubJobServiceFormatter.forEachNode(fullSource,
            function eachNode(node) {
              if (node._isLeaf && node._isProtected &&
                !protectedSourceIds.has(node.protectionSource.id)) {
                $ctrl.protectedSources.push(node);
              }
            });
        }

        $ctrl.noProtectedObjects = !$ctrl.protectedSources.length;
      }, evalAJAX.errorMessage)
      .finally(function protectedSourcesFinally() {
        $ctrl.objectsListLoaded = true;
      });
    }

    /**
     * redirects user to the correct protection job flow used when there are
     * no protected objects
     */
    function goToProtectionJobFlow(environment) {
      SourcesUtil.goToProtectionJobFlow(SourceInfo, [environment]);
    }

    /**
     * add private properties to source objects for easy consumption in
     * template
     *
     * @param     {Object[]}    sources    protected source objects
     * @return    {Object[]}               formatted sources
     */
    function _formatProtectedSources(sources) {
      return sources.map(function mapSource(source) {
        source._copyTasks = _getCopyTasks(source.protectionPolicies);
        source._iconClass = $filter('sourceIcon')(source);
        source.protectionJobs =
          decorateRpoJobs(source.protectionJobs, source.protectionPolicies);
        return source;
      });
    }

    /**
     * Looks for RPO Policies and decorates corresponding jobs with the RPO
     * policy attributes
     *
     * @method   decorateRpoJobs
     * @param    {Array}   jobs       The jobs of a protection source
     * @param    {Array}   policies   The policies of a protection source
     * @return   {Array}   Decorated list of jobs of the source
     */
    function decorateRpoJobs(jobs, policies) {
      policies.forEach(function loopOverPolicies(policy) {
        // If the policy is not RPO Policy, we do not decorate any job that is
        // related to this policy.
        if (policy.type !== 'kRPO') { return; }

        // For an RPO Policy, find the corresponding job and decorate it with
        // name and protected boolean.
        jobs.forEach(function decorateMatchingJob(job) {
          if (job.policyId === policy.id) {
            job._isRpoProtected = true;
            job._policyName = policy.name;
          }
        });
      });
      return jobs;
   }

    /**
     * formats copyTasks array according to protection policies of a specific
     * protected VM for status-icon-container component.
     *
     * @param    {Array}    protectionPolicies    array of protection policy
     *                                            objects
     * @return   {Array}                          array of formatted copy task
     *                                            objects
     */
    function _getCopyTasks(protectionPolicies) {
      var archivalPolicies = [];
      var replicationPolicies = [];

      return protectionPolicies.reduce(function mapPolicy(copyTasks, current) {
        // If there is a full or incremental policy set, then its a local snap.
        if (current.fullSchedulingPolicy ||
          current.incrementalSchedulingPolicy) {
          copyTasks.push({
            taskType: 'local',
            isTaskRemote: true,
            tooltip: 'localSnapshot',
            classNameSuffix: 'running',
          });
        }

        // If there is snapshotReplicationCopyPolicies, then its a remote repl.
        if (current.snapshotReplicationCopyPolicies) {
          replicationPolicies = current.snapshotReplicationCopyPolicies.map(
            function mapReplicationPolicy(item) {
              var tooltip = _.get(item, 'target.clusterName') ||
                _.get(item, 'cloudTarget.name');

              return {
                taskType: 'remote',
                isTaskRemote: true,
                tooltip: tooltip,
                classNameSuffix: 'running',
              };
          });

          copyTasks = copyTasks.concat(replicationPolicies);
        }

        // If there is a snapshotArchivalCopyPolicies, then it's cloud or tape.
        if (current.snapshotArchivalCopyPolicies) {
          archivalPolicies = current.snapshotArchivalCopyPolicies.map(
            function mapArchivalPolicy(item) {
              var taskType;

              // Determine the task type based on the vault where is it is
              // backed up.
              switch (item.target.vaultType) {
                case 'kCloud':
                  taskType = 'cloud';
                  break;
                case 'kNas':
                  taskType = 'nas';
                  break;
                default:
                  taskType = 'tape';
                  break;
              }

              return {
                taskType: taskType,
                isTaskRemote: true,
                tooltip: item.target.vaultName,
                classNameSuffix: 'running',
              };
          });

          copyTasks = copyTasks.concat(archivalPolicies);
        }

        return copyTasks;
      }, []);
    }

    /**
     * some objects may be associated to multiple protection jobs and policies,
     * group the names into an array so they can be displayed in the template
     *
     * @param     {Object[]}    data   job/policy objects
     * @return    {Object[]}           job/policy names
     */
    function getNames(data) {
      return data.map(function(item) {
        return item.name;
      });
    }

    /**
     * Check if the environment belongs to the NoSQL or Hadoop environments.
     *
     * @return   {boolean}   True if environment a is NoSQL or Hadoop environment,
     *                       false otherwise.
     */
    function isNoSqlHadoopEnvironment() {
      return NOSQL_ENVIRONMENTS.includes($ctrl.environment) ||
        HADOOP_ENVIRONMENTS.includes($ctrl.environment);
    }

    /**
     * For a protection source, return the name of the underlying entity.
     *
     * @param   {Object}   protectionSource   Protection source object for an entity
     * @return  {string}   Name of the entity
     */
    function getEntityName(protectionSource) {
      return isNoSqlHadoopEnvironment() ?
        protectionSource[SOURCE_KEYS[$ctrl.environment]].uuid : protectionSource.name;
    }

    /**
     * Get Protected size of the protected object.
     *
     * @method   getProtectedSize
     * @param    {object}    stats   The source stats
     * @return   {Number}   Protected size if available, -1 otherwise.
     */
    function getProtectedSize(stats) {
      return (stats && stats.protectedCount > 0) ? stats.protectedSize : -1;
    }

    /**
     * Return a translated string to indicate NGT (Nutanix Guest Tools) status.
     *
     * @method  ngtStatus
     * @param   {object} protectionSource
     */
    function ngtStatus(protectionSource) {
      const ngtStatus = [];
      if (protectionSource.ngtEnableStatus === 'kEnabled') {
        ngtStatus.push($translate.instant('enabled'));
        if (protectionSource.ngtInstallStatus === 'kInstalled') {
          ngtStatus.push($translate.instant('installed'));
          if (protectionSource.ngtReachable) {
            ngtStatus.push($translate.instant('reachable'));
          } else {
            ngtStatus.push($translate.instant('notReachable'));
          }
        } else {
          ngtStatus.push($translate.instant('notInstalled'));
        }
      } else {
        ngtStatus.push($translate.instant('disabled'));
      }
      return ngtStatus.join(', ');
    }

    /**
     * Show the spinner to notify user the source is being refreshed.
     */
    $scope.$on('source-refresh-started', function refreshStarted() {
      $ctrl.objectsListLoaded = false;
    });

    /**
     * When refresh is done call _getSourceTree to get updated tree.
     */
    $scope.$on('source-refresh-done', function refreshDone() {
      _getProtectedSources($state.params.id);
    });
  }
})(angular);
