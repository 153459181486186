// COMPONENT:  Shared parent controller for Pure (SAN) create restore task flow
import { recoveryGroup } from 'src/app/shared/constants';

;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.restorePure';
  var moduleDeps = ['C.restoreCommon', 'C.jobRunsService'];

  var flowTypes = ['recover', 'clone'];
  var flowType = flowTypes[0];
  var stateNameRoot = [flowType, 'pure'].join('-');
  var stateNames = [
    flowType,
    [stateNameRoot, 'search'].join('.'),
    [stateNameRoot, 'options'].join('.'),
  ];

  angular
    .module(moduleName, moduleDeps)
    .config(pureConfigFn)
    .controller('pureRestoreParentController', pureRestoreParentControllerFn);

  /**
   * Pure Config: States & Routing
   **************************************************************************/
  function pureConfigFn($stateProvider) {
    var restoreModifyAccess = ctx => {
      return ctx.RESTORE_MODIFY &&
        !ctx.FEATURE_FLAGS.restoreStorageVolume &&
        ctx.canAccessSomeEnv(recoveryGroup.pure);
    };

    $stateProvider
      .state('recover-pure', {
        url: '/protection/recovery/pure',
        title: 'Recover Pure Storage Volumes',
        canAccess: restoreModifyAccess,
        parentState: 'recover',
        params: {
          flowType: flowTypes[0]
        },
        views: {
          '': {
            controller: 'pureRestoreParentController',
            templateUrl: 'app/views/page-layouts/ls.html'
          },
          'col-l@recover-pure': {
            templateUrl: 'app/protection/recovery/pure/pure.parent.html'
          }
        },
        redirectTo: 'recover-pure.search'
      })
      .state('recover-pure.search', {
        url: '/protection/recovery/pure/search',
        help: 'protection_recovery_pure_search',
        title: 'Recover Pure Storage Volumes: Select Volume',
        canAccess: restoreModifyAccess,
        parentState: 'recover-pure',
        views: {
          'canvas@recover-pure': {
            controller: 'pureRestoreSearchController',
            templateUrl: 'app/protection/recovery/pure/pure.search.html'
          }
        }
      })
      .state('recover-pure.options', {
        url: '/protection/recovery/pure/options?{entityId}&{parentId}&{jobId}&{jobInstanceId}',
        help: 'protection_recovery_pure_options',
        title: 'Recover Pure Storage Volumes: Task Options',
        canAccess: restoreModifyAccess,
        parentState: 'recover-pure',
        params: {
          entityId: { type: 'string' },
          parentId: { type: 'string' },
          jobId: { type: 'string' },
          jobInstanceId: { type: 'string' },
        },
        views: {
          'canvas@recover-pure': {
            controller: 'pureRestoreOptionsController',
            templateUrl: 'app/protection/recovery/pure/pure.options.html'
          }
        }
      });
  }

  /**
   * pureRestoreParent Controller
   **************************************************************************/
  function pureRestoreParentControllerFn($rootScope, $translate, $scope, $state,
    $q, evalAJAX, ExternalTargetService, SearchService, cSearchService,
    SourceService, RestoreService, ViewBoxService, JobService, ClusterService) {

    /**
     * The default RecoverArg object. Require properties are uncommented.
     * @type   {RecoverArg}
     */
    var defaultTask = {
      name: '',
      restoreParentSource: undefined,
      // continueRestoreOnError: undefined,
      // kRecoverSanVolume
      action: 8,
      objects: [],
      renameRestoredObjectParam: {
        prefix: undefined,
        suffix: undefined
      }
    };

    angular.extend($scope, {
      // GENERAL SCOPE VARS
      shared: {
        cart: [],
        filters: [],
        filterLookups: {},
        pagedResults: [],
        results: [],
        searchId: 'pure-search',
        searchEndpoint: SearchService.getSearchUrl('pure'),
        task: angular.copy(defaultTask)
      },

      // SCOPE METHODS
      addToCart: addToCart,
      submitTask: submitTask,
      updateTaskWithObjects: RestoreService.generateRestoreTaskObjects,
      startFlowOver: startFlowOver,
      setupSteps: setupSteps,
      getViewBox: getViewBox,
    });


    // WATCHERS
    $scope.$watch('shared.cart', cartUpdateHandler, true);

    // METHODS
    /**
     * Activate this ngController!
     *
     * @method     activate
     */
    function activate() {

      // Pick up the flowType from the state params
      flowType = $scope.flowType = $state.params.flowType || flowTypes[0];
      // A couple convenience Bools for knowing which type of flow
      // we're in.
      $scope.isRecover = (flowType === flowTypes[0]);
      $scope.isClone = !$scope.isRecover;

      setupSteps();
      fetchDependencies();
      $scope.shared.defaultTaskName = RestoreService.getDefaultTaskName(flowType, 'pure');
      $scope.shared.task.name = $scope.shared.defaultTaskName;

    }

    /**
     * Get a viewbox object by id
     *
     * @method   getViewBox
     * @param    {integer}            id   A ViewBox id
     * @return   {object|undefined}   The matched ViewBox, or undefined if
     *                                not found.
     */
    function getViewBox(id) {
      var match;

      if (!$scope.shared.filterLookups ||
        !$scope.shared.filterLookups.length ||
        isNaN(id)) {
        return;
      }

      $scope.shared.filterLookups.viewBoxIds.some(
        function viewBoxFinder(viewbox) {
          if (id === viewbox.id) {
            match = viewbox;
            return true;
          }
        }
      );

      return match;
    }

    /**
     * Handles $watch events for the task cart.
     *
     * @method   cartUpdateHandler
     * @param    {array}   nv   The current cart value
     * @param    {array}   ov   The previous cart value
     */
    function cartUpdateHandler(nv, ov) {
      // Empty cart or same entity
      if (!nv || !nv.length || angular.equals(nv, ov)) {
        return;
      }

      $scope.shared.task.objects = RestoreService.generateRestoreTaskObjects(nv);
    }

    /**
     * Convenience to start the flow over (first step, which can change depending on how the flow is accessed).
     *
     * @method   startFlowOver
     */
    function startFlowOver() {
      $state.go(stateNames[1], undefined, {
        location: 'replace',
        reload: true
      });
    }

    /**
     * Constructs a default SAN volume recover name (rename).
     *
     * @method   getDefaultRenamedObject
     * @param    {string=}   name   Optional volume name
     * @return   {string}           The generated name string
     */
    function getDefaultRenamedObject(name) {
      var suffix = ('clone' === flowType) ?
        $translate.instant('cloned') :
        $translate.instant('recovered');
      name = name || $translate.instant('unknown');

      return [name, suffix].join('-');
    }

    /**
     * Determines if the given object is already in the task cart.
     *
     * @method   isInCart
     * @param    {object}   entity   A single search result object
     * @return   {boolean}           True if the object is already in the
     *                               cart
     */
    function isInCart(entity) {
      return $scope.shared.cart.some(function findInCart(cartEntity) {
        if (cartEntity._id === entity._id) {
          return true;
        }
      });
    }

    /**
     * Sets up this flow's steps.
     *
     * @method   setupSteps
     */
    function setupSteps() {

      stateNameRoot = [flowType, 'pure'].join('-');
      stateNames = [
        flowType,
        [stateNameRoot, 'search'].join('.'),
        [stateNameRoot, 'options'].join('.'),
      ];

    }

    /**
     * Adds an item to the cart and transitions to the next step.
     *
     * @method   addToCart
     * @param    {object}    item      The item to add to the cart
     * @param    {boolean}   proceed   Proceed to next step when true.
     *                                 Default: true
     */
    function addToCart(item, proceed) {
      proceed = angular.isDefined(proceed) ? proceed : true;

      // Validate that this is a useful result object.
      if (!item || !item.vmDocument || !item._snapshot) {
        return;
      }
      // Add _archiveTarget if no local snapshots exist.
      angular.extend(item, {
        _archiveTarget: (item._snapshot.replicaInfo &&
          Array.isArray(item._snapshot.replicaInfo.replicaVec) &&
          item._snapshot.replicaInfo.replicaVec[0] &&
          item._snapshot.replicaInfo.replicaVec[0].target &&
          (1 !== item._snapshot.replicaInfo.replicaVec[0].target.type)) ?
          // It's not local, use it. First replica will be local.
          item._snapshot.replicaInfo.replicaVec[0] :
          // It's local, don't set it.
          undefined
      });

      $scope.shared.cart = [item];

      if (proceed) {
        $state.go(stateNames[2]);
      }
    }

    /**
     * Fetch dependencies from the server. Mostly for filter lookups.
     *
     * @method     fetchDependencies
     */
    function fetchDependencies() {
      var depsPromises = [
        // viewBoxes
        ViewBoxService.getOwnViewBoxes(),
        // ParentSources
        SourceService.getSources({onlyReturnOneLevel: true, envTypes: [7]}),
        // All protected Pure Volumes
        SourceService.getEntitiesOfType({
          environmentTypes: 'kPure',
          pureEntityTypes: 'kVolume',
          isProtected: true
        }),
        // All Pure jobs
        JobService.getJobs({envTypes: [7]}),
        // Get the external targets

        $rootScope.user.privs.CLUSTER_EXTERNAL_TARGET_VIEW ?
          ExternalTargetService.getTargets() : $q.resolve([]),
      ];

      return $q.all(depsPromises).then(function allFetched(responses) {
        initFilterLookups();
        if (Array.isArray(responses[0])) {
          $scope.shared.filterLookups.viewBoxIds = responses[0];
        }
        if (responses[1] && responses[1].entityHierarchy &&
          responses[1].entityHierarchy.children) {
            $scope.shared.filterLookups.registeredSourceIds =
              responses[1].entityHierarchy.children;
        }
        if (Array.isArray(responses[2])) {
          $scope.shared.filterLookups.entityIds = responses[2];
        }
        if (Array.isArray(responses[3])) {
          $scope.shared.filterLookups.jobIds = responses[3].map(
            function augmentJobsFn(job) {
              return angular.merge(job, {
                jobName: job.name
              });
            }
          );
        }
        if (Array.isArray(responses[4])) {
          $scope.shared.filterLookups.externalTargets =
            angular.copy(ExternalTargetService.targetNameMapById);
        }
        $scope.shared.filterLookups.clusters =
          ClusterService.updateClusterHash(ClusterService.clusterInfo);
      });
    }

    /**
     * Sets up the lookups skeleton needed for this flow
     *
     * @method     initFilterLookups
     */
    function initFilterLookups() {
      $scope.shared.filterLookups = angular.extend({
        entityIds: [],
        viewBoxIds: [],
        registeredSourceIds: [],
        jobIds: []
      }, $scope.shared.filterLookups);
    }

    /**
     * Submit the task as configured.
     *
     * @method   submitTask
     * @param    {object}   form   The form object
     */
    function submitTask(form) {
      if (!form || form.$invalid) {
        return false;
      }

      $scope.isSubmitting = true;
      RestoreService.restoreVM($scope.shared.task)
        .then(
          function taskAcceptedSuccess(restoreTask) {
            var stateName = RestoreService.getRestoreTaskDetailStateName(
              'recover', $scope.targetType);

            var stateParams = {
              id: restoreTask.performRestoreTaskState.base.taskId,
            };

            $state.go(stateName, stateParams);
          },
          evalAJAX.errorMessage
        )
        .finally(function taskAcceptedFinally() {
          $scope.isSubmitting = false;
        });

    }

    // Activate!
    activate();

  }


})(angular);
