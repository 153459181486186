import { Injectable } from '@angular/core';
import { Cluster } from '@cohesity/api/v1';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {
  /**
   * Holds the isLicenseActivated object as an observable.
   *
   * @example
   *   // how to get current status of isLicenseActivated.
   *   const tenant = LicenseService.isLicenseActivated;
   *
   *   // how to update update isLicenseActivated status.
   *   LicenseService.isLicenseActivated = newisLicenseActivatedValue;
   *
   *   // how to subscribe for isLicenseActivated updates.
   *   // NOTE: don't forget to unsubscribe once done to prevent un-necessary update notifications.
   *   const subscription = LicenseService.isLicenseActivated$.subscribe({
   *     next: response => {
   *       // do something with updated isLicenseActivated.
   *     },
   *   });
   *   subscription.unsubscribe();
   */
  isLicenseActivated$ = new BehaviorSubject<boolean>(false);

  /**
   * Get the isLicenseActivated value.
   *
   * @returns The isLicenseActivated value.
   */
  get isLicenseActivated(): boolean {
    return this.isLicenseActivated$.getValue();
  }

  /**
   * Set the isLicenseActivated object.
   *
   * @param  tenant  The new value for isLicenseActivated.
   */
  set isLicenseActivated(isLicenseActivated: boolean) {
    this.isLicenseActivated$.next(isLicenseActivated);
  }

  /**
   * Determines whether license acceptance is needed.
   */
  isLicenseAcceptanceNeeded(clusterInfo: Cluster): boolean {
    const { licenseState } = clusterInfo;

    if (licenseState.state === 'kClaimed') {
      return false;
    } else if (licenseState.state === 'kSkipped') {
      // TODO: Manthan: integrate with licenseServer to check, when to show
      // user the license flow again
      return false;
    }
    return true;
  }
}
