<!-- Show primary actions as icon buttons -->
<div *ngFor="let action of actions | slice:0:numItems"
  [cogDataId]="'table-action-' +  action.displayName"
  [inheritDataId]="true"
  [matTooltip]="action.disabled ? action.disabledTooltip : action.displayName"
  class="action-item-button">
  <coh-nav-item-button [navItem]="action" navButtonType="flatIcon">
  </coh-nav-item-button>
</div>

<!-- Show secondary actions behind a vertical ellipsis menu -->
<button *ngIf="actions?.length > numItems"
  [cogDataId]="'table-action-more-actions'"
  [inheritDataId]="true"
  [matMenuTriggerFor]="moreOptions"
  class="more-actions"
  mat-icon-button
  type="button">
  <mat-icon>more_vert</mat-icon>
</button>

<!-- Non primary actions -->
<mat-menu #moreOptions="matMenu">
  <coh-nav-item-button *ngFor="let action of (actions | slice: numItems)"
    [cogDataId]="'table-action-' +  action.displayName"
    [matTooltip]="action.disabled ? action.disabledTooltip : ''"
    [navItem]="action"
    navButtonType="menu">
  </coh-nav-item-button>
</mat-menu>
