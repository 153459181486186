import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';

import { PopoverDirective } from './popover-directive';
import { PopoverComponent } from './popover.component';

const components = [
  PopoverComponent
];

const directives = [
  PopoverDirective
];

/**
 * Popover Module.
 */
@NgModule({
  imports: [
    OverlayModule,
    CommonModule,
    MatCardModule,
  ],
  declarations: [
    ...components,
    ...directives,
  ],
  exports: [...directives],
})
export class PopoverModule { }
