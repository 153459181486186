import { Injectable } from '@angular/core';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { StateObject, Transition } from '@uirouter/core';
import { GuardPriority, GuardResult, StateGuard } from 'src/app/core/state/state-guard';

/**
 * Smart Tier Guard redirects all state traffic to the new page based on the
 * Feature flag smartTier.
 */
@Injectable({
  providedIn: 'root'
})
export class SmartTierGuard implements StateGuard {
  /**
   * This guard priority is set to App, which indicates it should run as part of the main application
   * after all bootstrapping and initializing is completed.
   */
  guardPriority = GuardPriority.App;

  /**
   * Specifies the old to new state mapping
   */
  private readonly stateMapping = new Map<string, string>([
    ['data-tiering.analysis', 'data-tiering.analysis-new'],
  ]);

  /**
   * Match all navigation to data-tiering migrated pages
   */
   matchCriteria = {
    to: (state: StateObject, transition: Transition) => this.stateMapping.has(transition.targetState().name()),
  };

  /**
   * Constructor.
   */
  constructor(private irisCtx: IrisContextService) { }

  /**
   * Handles the redirection to the new tiering plan page based on feature flag.
   *
   * @param    transition   The UI Router Transition.
   * @returns  A redirect transition if new page is enabled.
   */
  onStart(transition: Transition): GuardResult {
    const params = transition.params();
    const options = transition.options();

    const targetState = transition.targetState().name();
    const newState = this.stateMapping.get(targetState);

    if (flagEnabled(this.irisCtx.irisContext, 'smartTier')) {
      return transition.router.stateService.target(newState, params, options);
    }
  }
}
