import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DmsServiceApi, TenantRegionInfo, TenantRegions } from '@cohesity/api/dms';
import { AutoDestroyable } from '@cohesity/utils';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConnectionSetupService } from 'src/app/modules/sources-shared/services';
import { getUserTenantId, IrisContextService } from '@cohesity/iris-core';

@Component({
  selector: 'coh-view-port-info',
  templateUrl: './view-port-info.component.html',
  styleUrls: ['./view-port-info.component.scss']
})
export class ViewPortInfoDialogComponent extends AutoDestroyable implements OnInit {
  /**
   * Region information for tenant.
   */
  region: TenantRegionInfo;

  /**
   * True if loading region information.
   */
  loading = false;

  constructor(
    @Optional() private dialogRef: MatDialogRef<ViewPortInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) readonly data: { name: string; id?: string; region?: TenantRegionInfo },
    readonly connectionService: ConnectionSetupService,
    readonly dmsService: DmsServiceApi,
    readonly irisContextService: IrisContextService,
  ) {
    super();
  }

  ngOnInit() {
    if (this.data.region) {
      this.region = this.data.region;
      this.connectionService.getPortInformation(this.region);
    } else if (this.data.id) {
      this.loading = true;
      this.dmsService.GetTenantRegions({
        tenantId: getUserTenantId(this.irisContextService.irisContext),
        regionIds: [ this.data.id ],
      }).pipe(
        this.untilDestroy(),
        catchError(() => of(null)),
      ).subscribe((result: TenantRegions) => {
        this.loading = false;
        if (result?.tenantRegionInfoList?.length) {
          this.region = result.tenantRegionInfoList[0];
          this.connectionService.getPortInformation(this.region);
        }
      });
    }
  }
}
