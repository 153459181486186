import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { DataFilterValue, FiltersComponent } from '@cohesity/helix';
import { Observable, of } from 'rxjs';

/**
 * Generic company account object.
 */
export interface CompanyAccount {
  /**
   * Used for Salesforce account impersonation.
   */
  id?: string;

  /**
   * Name of company.
   */
  name?: string;

  /**
   * Case ID (for support user only).
   */
  caseId?: string;
}

/**
 * This component displays a list of company accounts.
 */
@Component({
  selector: 'coh-company-account-table',
  styleUrls: ['./company-account-table.component.scss'],
  templateUrl: './company-account-table.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CompanyAccountTableComponent {
  /**
   * Template for table filter.
   */
  @ViewChild(FiltersComponent, { static: false }) filterComponent: FiltersComponent;

  /**
   * List of accounts for the table.
   */
  @Input() accounts: CompanyAccount[];

  /**
   * Stores the selected row.
   */
  @Input() selection: SelectionModel<CompanyAccount>;

  /**
   * Async table filter.
   */
  get tableFilters$(): Observable<DataFilterValue<any>[]> {
    return this.filterComponent ? this.filterComponent.filterValues$ : of(undefined);
  }

  /**
   * Account table column name.
   */
  readonly columns = [
    'name'
  ];

  /**
   * Track by function for the data table
   *
   * @param    index  Item index.
   * @param    item   The item.
   * @returns  The item id.
   */
  trackById(index: number, item): string {
    return item.id;
  }
}
