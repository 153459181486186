<button
  [dgHasPermissions]="['SECOPS_MANAGE_POSTURE_ADVISOR', 'DGAAS_MODIFY']"
  mat-icon-button
  [matMenuTriggerFor]="menu.childMenu"
  cogDataId="actions-menu"
  (click)="$event.stopPropagation()">
  <cog-icon shape="more_vert"></cog-icon>
</button>

<cog-actions-menu #menu [navList]="menuItems" [itemTemplate]="actionMenuItem"></cog-actions-menu>

<ng-template #actionMenuItem let-item>
  <div cogDataId="action-{{ item.displayName }}" (click)="menuItemClick.emit(item.id)">
    {{ item.displayName | translate }}
  </div>
</ng-template>
