<ng-container *ngIf="loading === false; else spinner">
  <mat-dialog-content>
    <div class="border-bottom padding-md margin-bottom">
      <input
        matInput
        placeholder="Search this list..."
        #tagsSearch
        [formControl]="searchFormControl"
        cogDataId="tag-selector-dialog-search-input">
    </div>
    <cog-banner status="info">
      <span *ngIf="snapshotIds?.length">
        {{ 'dg.sc.inventory.tagsDialog.onlyCustomTagsMsgForSnapshots' | translate }}
      </span>
      <span *ngIf="!snapshotIds?.length">
        {{ 'dg.sc.inventory.tagsDialog.onlyCustomTagsMsgForObjects' | translate }}
      </span>
    </cog-banner>
    <div class="scroll-list">
      <div class="selection-list">
        <div *ngIf="filteredTags?.length === 0" class="margin-top-xs">
          {{ 'noResultsFound' | translate }}
        </div>
        <div *ngFor="let tag of filteredTags; let index = index;" class="flex-row">
          <mat-checkbox
            (change)="onTagSelect(tag)"
            [checked]="tag.state.isSelected"
            [disabled]="!tag.state.isSelected && tag.type === 'System'"
            [indeterminate]="tag.state.isInDeterminate"
            cogDataId="tag-selector-dialog-tag-checkbox-{{index}}"
            [matTooltip]="tag.type === 'System' ? ('dg.sc.inventory.tagsDialog.onlyCustomTagsMsg' | translate) : null">
          </mat-checkbox>
          <coh-colored-tag [tag]="tag"></coh-colored-tag>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button
      type="button"
      mat-button
      cogCancelButton
      cogDataId="cancel-edit-tags"
      [disabled]="isSubmitting"
      (click)="closeDialog()">
      {{ 'cancel' | translate }}
    </button>

    <button
      type="button"
      cogDataId="update-tags"
      mat-flat-button
      cogSubmitButton
      [disabled]="isSubmitting"
      [loading]="isSubmitting"
      (click)="applyTags()">
      {{ 'apply' | translate }}
    </button>
  </mat-dialog-actions>
</ng-container>

<ng-template #spinner>
  <cog-spinner></cog-spinner>
</ng-template>
