import { Component, EventEmitter, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { User } from '@cohesity/api/v1';
import { IrisContextService } from '@cohesity/iris-core';
import { StateService } from '@uirouter/core';
import { Controls, DataInput, NgxRootFormComponent } from 'ngx-sub-form';

import { LoginService } from '../../services/login.service';
import { MfaService } from '../../services/mfa.service';
import { MfaVerification } from '../interfaces/mfa-verification';

@Component({
  selector: 'coh-mfa-setup',
  templateUrl: './mfa-setup.component.html',
  styleUrls: ['./mfa-setup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MfaSetupComponent extends NgxRootFormComponent<MfaVerification> {

  /**
   * The data input for the MFA Verification form.
   */
  @DataInput()
  dataInput: Required<MfaVerification>;

  /**
   * The data output for the MFA verification form.
   */
  dataOutput = new EventEmitter<MfaVerification>();

  /**
   * Indicates the number of steps in the wizard.
   */
  noOfSteps = 3;

  /**
   * Holds the reference to the stepper items.
   */
  @ViewChild(MatStepper) stepperRef: MatStepper;

  /**
   * Returns whether the back button is enabled or not.
   *
   * @returns  Whether back button is enabled.
   */
  get isBackBtnEnabled(): boolean {
    return !!this.stepperRef?.selectedIndex;
  }

  /**
   * Returns the next button text to be shown.
   *
   * @returns  The button text
   */
  get nextBtnText(): string {
    return this.stepperRef?.selectedIndex === (this.noOfSteps - 1) ? 'complete' : 'next';
  }

  /**
   * Returns whether to show the verify button.
   *
   * @returns To show the verify button
   */
  get showVerifyBtn(): boolean {
    if (this.mfaService.isHybridAuthNeeded) {
      switch (this.stepperRef?.selectedIndex) {
        case 1:
          return !this.mfaService.isTokenVerified$.value;
        case 2:
          return !this.mfaService.isEmailTokenVerified$.value;
      }
    } else if (this.mfaService.isTokenAuthNeeded) {
      return this.stepperRef?.selectedIndex === 1 && !this.mfaService.isTokenVerified$.value;
    } else if (this.mfaService.isEmailAuthNeeded) {
      return this.stepperRef?.selectedIndex === 2 && !this.mfaService.isEmailTokenVerified$.value;
    }

    return false;
  }

  /**
   * Shows the stepper only when there are multiple steps involved.
   */
  get showStepper(): boolean {
    return this.mfaService.isHybridAuthNeeded || this.mfaService.isTokenAuthNeeded;
  }

  /**
   * Returns the current user.
   */
  get user(): User {
    return this.irisContextService?.irisContext?.user;
  }

  getFormControls(): Controls<MfaVerification> {
    return {
      emailToken: new UntypedFormControl(),
      securityToken: new UntypedFormControl(),
    };
  }

  constructor(
    readonly mfaService: MfaService,
    private loginService: LoginService,
    private irisContextService: IrisContextService,
    private stateService: StateService,
  ) {
    super();

    if (!Object.keys(this.user).length) {

      // You need have a user session to setup MFA.
      this.stateService.go('login');
      return;
    }

    // If the user has been authenticated and assigned a role. Then setup is
    // not needed.
    if (this.user?.roles?.length) {
      this.stateService.go('dashboards.summary');
      return;
    }

    // Reset all states to initial before proceeding with setup.
    this.mfaService.clearState();

    switch (true) {
      case this.mfaService.isHybridAuthNeeded:
        this.noOfSteps = 3;
        this.mfaService.getUserMfaInfo();
        break;
      case this.mfaService.isTokenAuthNeeded:
        this.noOfSteps = 2;
        this.mfaService.getUserMfaInfo();
        break;
      case this.mfaService.isEmailAuthNeeded:
        this.noOfSteps = 1;
        break;
    }
  }

  /**
   * Handles whether to login the user or move next in the configuration
   *
   * @param type The state to move.
   */
  handleNext(type: string) {
    if (type === 'next') {
      this.stepperRef.next();
      return;
    }

    if (this.mfaService.isTokenVerified$.value) {
      this.loginService.authenticateUser(this.mfaService.authenticatedUser);
    }
  }

  /**
   * Handles the users completion of the MFA setup.
   */
  completeLogin() {
    if (this.mfaService.isEmailTokenVerified$.value) {
      this.loginService.authenticateUser(this.mfaService.authenticatedUser);
    }
  }

}
