import { Environment } from 'src/app/shared/constants';

/**
 * Deployment platform list
 */
export const deploymentPlatformList = [
  {
    title: 'deploymentPlatform.hyperV',
    value:  Environment.kHyperV,
  },
  {
    title: 'deploymentPlatform.vmware',
    value:  Environment.kVMware,
  },
];
