<div class="flex-end dialog-header">
  <h2 mat-dialog-title>{{ 'performScan' | translate }}</h2>
</div>

<!-- Binding the score list table -->
<mat-dialog-content class="margin-bottom">
  <cog-spinner *ngIf="loading" size="sm"></cog-spinner>
  <dg-pa-scores-list-table
    *ngIf="(dialogParams?.scoresData || scoresData) && !loading"
    [disableRowActions]="true"
    [scoresData]="dialogParams?.scoresData || scoresData"
    [selection]="selection"
    [isAllSelectedFn]="isAllSelected">
  </dg-pa-scores-list-table>
</mat-dialog-content>

<!-- Saving button -->
<mat-dialog-actions align="end">
  <button
    mat-button
    mat-dialog-close
    cogCancelButton
    cdkFocusInitial
    cogDataId="cancel-perform-scan"
    [disabled]="saving">
    {{ 'cancel' | translate }}
  </button>

  <!-- Cancel button -->
  <button
    mat-button
    cogSubmitButton
    cogDataId="submit-perform-scan"
    [loading]="saving"
    [disabled]="saving"
    (click)="submitPerformScanRequest()">
    {{ 'performScan' | translate }}
  </button>
</mat-dialog-actions>
