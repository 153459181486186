import { DataTreeControl, DataTreeFilter, DataTreeFilterUtils } from '@cohesity/helix';
import { flagEnabled, IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { Environment } from '@cohesity/iris-shared-constants';
import { SourceTreeFilters } from '@cohesity/iris-source-tree';

import { NasSourceDataNode } from './nas-source-data-node';

/**
 * Provide view filters for NAS sources.
 */
export class NasViewFilters<T extends NasSourceDataNode> {
  constructor(
    private treeControl: DataTreeControl<any>,
    private filters: SourceTreeFilters<any>,
    private irisCtx: IrisContextService,
    environment?: Environment,
  ) {
    const isNetapp = environment === Environment.kNetapp;
    const isIsilon = environment === Environment.kIsilon;
    const isGenericNas = environment === Environment.kGenericNas;

    this.filters.addFilterGroup({
      id: 'protocol-filter',
      alwaysShow: true,
      label: 'protocol',
      options: [
        {
          id: isNetapp || isGenericNas ? 'nfs3' : 'nfs',
          label: isNetapp || isGenericNas ? 'nfs3' : 'nfs',
          filter: isIsilon ? this.filterIsilonNfsNodes : this.filterNfsNodes,
        },
        (isNetapp || isGenericNas) &&
        flagEnabled(this.irisCtx.irisContext, 'nasNfsv41ProtectionEnabled') &&
        flagEnabled(this.irisCtx.irisContext, 'nfsVersionPreferenceEnabled') && {
          id: 'nfs4_1',
          label: 'nfs4_1',
          filter: this.filterNfs4Nodes,
        },
        {
          id: 'smb',
          label: 'smb',
          filter: isIsilon ? this.filterIsilonSmbNodes : this.filterSmbNodes,
        },
        isIsilon && {
          id: 'mixedMode',
          label: 'mixedMode',
          filter: this.filterMixedModeNodes,
        },
      ].filter(Boolean),
    });

    if (isNetapp) {
      this.filters.addFilterGroup({
        id: 'volume-filter',
        alwaysShow: true,
        label: 'volumeType',
        options: [
          {
            id: 'readWrite',
            label: 'readWrite',
            filter: this.filterReadWriteNodes,
          },
          !isDmsScope(this.irisCtx.irisContext) && {
            id: 'dataProtect',
            label: 'dataProtect',
            filter: this.filterDataProtectNodes,
          },
        ].filter(Boolean),
      });

      if (flagEnabled(this.irisCtx.irisContext, 'netappExtendedStyleFilteringEnabled')) {
        this.filters.addFilterGroup({
          id: 'flexgroup-filter',
          alwaysShow: true,
          label: 'extendedStyle',
          options: [
            {
              id: 'flexGroups',
              label: 'flexGroups',
              filter: this.filterFlexGroupsNodes,
            },
            {
              id: 'flexVols',
              label: 'flexVols',
              filter: this.filterFlexVolumeNodes,
            },
          ],
        });
      }
    }
  }

  /**
   * Filter callback function to show only NFS objects
   */
  filterNfsNodes: DataTreeFilter<any> = (nodes: any[]) =>
    // Filter the group entities and leaf nodes which support NFS
    DataTreeFilterUtils.searchFilter(nodes, this.treeControl, (node: T) => node.supportNfS, false, true);

  /**
   * Filter callback function to show only NFS v4.1 objects
   */
  filterNfs4Nodes: DataTreeFilter<any> = (nodes: any[]) =>
    // Filter the group entities and leaf nodes which support NFS
    DataTreeFilterUtils.searchFilter(nodes, this.treeControl, (node: T) => node.supportNfS4, false, true);

  /**
   * Filter callback function to show only SMB objects
   */
  filterSmbNodes: DataTreeFilter<any> = (nodes: any[]) =>
    // Filter the group entities and leaf nodes which support SMB
    DataTreeFilterUtils.searchFilter(nodes, this.treeControl, (node: T) => node.supportSMB, false, true);

  /**
   * Filter callback function to show only NFS objects
   */
  filterIsilonNfsNodes: DataTreeFilter<any> = (nodes: any[]) =>
    // Filter the group entities and leaf nodes which support NFS
    DataTreeFilterUtils.searchFilter(
      nodes,
      this.treeControl,
      (node: T) => node.supportNfS && !node.supportSMB,
      false,
      true
    );

  /**
   * Filter callback function to show only SMB objects
   */
  filterIsilonSmbNodes: DataTreeFilter<any> = (nodes: any[]) =>
    // Filter the group entities and leaf nodes which support SMB
    DataTreeFilterUtils.searchFilter(
      nodes,
      this.treeControl,
      (node: T) => node.supportSMB && !node.supportNfS,
      false,
      true
    );

  /**
   * Filter callback function to show NFS and SMB objects
   */
  filterMixedModeNodes: DataTreeFilter<any> = (nodes: any[]) =>
    // Filter the group entities and leaf nodes which support NFS
    DataTreeFilterUtils.searchFilter(
      nodes,
      this.treeControl,
      (node: T) => node.supportNfS && node.supportSMB,
      false,
      true
    );

  /**
   * Filter callback function to show only Read-Write Netapp Volumes
   */
  filterReadWriteNodes: DataTreeFilter<any> = (nodes: any[]) =>
    // Filter the group entities and leaf nodes which support NFS
    DataTreeFilterUtils.searchFilter(
      nodes,
      this.treeControl,
      (node: T) => node.volumeType === 'kReadWrite',
      false,
      true
    );

  /**
   * Filter callback function to show only Data-Protect Netapp Volumes
   */
  filterDataProtectNodes: DataTreeFilter<any> = (nodes: any[]) =>
    // Filter the group entities and leaf nodes which support NFS
    DataTreeFilterUtils.searchFilter(
      nodes,
      this.treeControl,
      (node: T) => node.volumeType === 'kDataProtection',
      false,
      true
    );

  /**
   * Filter callback function to show only FlexGroup Netapp Volumes
   */
  filterFlexGroupsNodes:  DataTreeFilter<any> = (nodes: any[]) =>
    DataTreeFilterUtils.searchFilter(
      nodes,
      this.treeControl,
      (node: T) => node.extendedStyle === 'kFlexGroup',
      false,
      true
    );

  /**
   * Filter callback function to show only FlexVol Netapp Volumes
   */
  filterFlexVolumeNodes:  DataTreeFilter<any> = (nodes: any[]) =>
    DataTreeFilterUtils.searchFilter(
      nodes,
      this.treeControl,
      (node: T) => node.extendedStyle === 'kFlexVol',
      false,
      true
    );
}
