<div class="multitask-controls-wrapper" [ngClass]="{selected: hasSelected}">
  <div class="multitask-checkbox-container">
    <label>
      <input
        #checkbox
        type="checkbox"
        name="selectCheckbox"
        [checked]="allVisibleSelected"
        (change)="checkboxChange()">
    </label>
  </div>
  <div class="multitask-controls" [ngClass]="{'visible': hasSelected}">
    <div class="multitask-controls-container">
      <ng-content></ng-content>
    </div>
    <div class="multitask-select-all">
      <div class="multitask-select-current">
        {{ itemSelectionLabel | translate: {items: selectedCount} }}
      </div>
      <div class="multitask-select-rest">
        <a *ngIf="!isAllSelected" (click)="selectAll()">
          {{ selectAllLabel | translate: {items: allItems.length} }}
        </a>
        <a *ngIf="isAllSelected" (click)="clearAll()">
          {{ 'clearAll' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
