import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Controls, NgxSubFormComponent, subformComponentProviders } from 'ngx-sub-form';

import { RestoreTarget, RestoreTargetInfo } from '../../model/recover-to-source';

@Component({
  selector: 'coh-recover-to-source-selector',
  templateUrl: './recover-to-source-selector.component.html',
  providers: subformComponentProviders(RecoverToSourceSelectorComponent),
})
export class RecoverToSourceSelectorComponent extends NgxSubFormComponent<RestoreTarget> {
  // TODO: add explicit constructor


  /**
   * List of sources.
   */
  @Input() servers: RestoreTargetInfo[] = [];

  /**
   * Returns a collection of form controls for the subform template.
   */
  protected getFormControls(): Controls<RestoreTarget> {
    return {
      value : new UntypedFormControl()
    };
  }
}
