import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import dayjs from 'dayjs/esm';
import calendar from 'dayjs/esm/plugin/calendar';
import relativeTime from 'dayjs/esm/plugin/relativeTime';
import timezone from 'dayjs/esm/plugin/timezone';
import utc from 'dayjs/esm/plugin/utc';
import { BehaviorSubject, Subscription } from 'rxjs';

import { DATE_PIPE_OPTIONS, DatePipeOptions } from '../date-pipe/moment-date.pipe';
import { TimelineEvent } from './timeline-event';

dayjs.extend(calendar);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

/** Convenience type for event groups. */
type EventGroups<T> = Map<number, Map<number, T[]>>;

/** Specifies number of seconds in a minute. */
const secsInAMinute = 60;

/**
 * Function for use with moment calendar to output fromNow value.
 */
function daysAgoFn() {
  return this.fromNow();
}

@Component({
  selector: 'cog-activity-timeline',
  templateUrl: './activity-timeline.component.html',
  styleUrls: ['./activity-timeline.component.scss'],
})
export class ActivityTimelineComponent<T extends TimelineEvent> implements OnInit, OnChanges, OnDestroy {
  /**
   * The list of events which need to be represented by the timeline.
   */
  @Input() events: T[];

  /**
   * The template for rendering a header above each timeline group. Will render
   * between the date and the first item.
   */
  @Input() headerTemplate: TemplateRef<any>;

  /**
   * The template for rendering timeline items for each timestamp.
   */
  @Input() itemsTemplate: TemplateRef<any>;

  /**
   * Indicates whether to sort the events in reverse-chronological order of
   * their occurrence.
   */
  @Input() reverseSort = false;

  /**
   * The groups of the timeline events according to their occurrence.
   */
  groups$ = new BehaviorSubject<EventGroups<T>>(new Map());

  /**
   * The timestamp to represent days.
   */
  days: number[] = [];

  /**
   * The mapping between a day, and it's minutes.
   */
  dayMinutes: { [day: number]: number[] } = {};

  /**
   * Maintain an array of current subscriptions to unsubscribe on component
   * destroy.
   */
  protected subscriptions: Subscription[] = [];

  constructor(
    @Inject(DATE_PIPE_OPTIONS) @Optional() private globalOptions?: DatePipeOptions,
  ) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this.groups$.subscribe(groups => {
        this.days = [...groups.keys()].sort((a, b) => this.compare(a, b));

        this.dayMinutes = this.days.reduce((acc, day) => {
          acc[day] = [...groups.get(day).keys()].sort((a, b) => this.compare(a, b));
          return acc;
        }, {});
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.events || changes.reverseSort) {
      this.groups$.next(this.generateEventGroups());
    }
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      if (!subscription.closed) {
        subscription.unsubscribe();
      }
    }
  }

  /**
   * Provides a humanized string of the provided date with custom
   * handlers for today, tomorrow, and yesterday so that granularity
   * doesn't go less than 1 day.
   *
   * @param day The day's timestamp in seconds
   */
  humanizeDaysAgo(day: number): string {
    return dayjs(day * 1000).calendar(null, {
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      lastDay: '[Yesterday]',
      nextWeek: daysAgoFn,
      lastWeek: daysAgoFn,
      sameElse: daysAgoFn,
    });
  }

  /**
   * Returns the hierarchical representation of the timeline events.
   *
   * @returns The event groups object.
   */
  generateEventGroups(): EventGroups<T> {
    if (!this.events) {
      return new Map();
    }

    // Need to get the timezone from globalOptions which has the timezone from user preference.
    // Otherwise, the date display of activity group could be off by one day, e.g.,
    // If browser timezone is pacific but user preference is honolulu, the date displayed will be a day earlier.
    const userTimezone = this.globalOptions?.timezone || dayjs.tz.guess();

    return (
      // Make a copy of the original events array.
      [...this.events]

        // Sort events.
        .sort((a, b) => this.compare(a.timestampSecs, b.timestampSecs))

        // Generate day > minute > items hierarchy
        .reduce((groups, event) => {
          const minute = event.timestampSecs - (event.timestampSecs % secsInAMinute);
          const day = dayjs(event.timestampSecs * 1000).tz(userTimezone).startOf('day').valueOf() / 1000;

          if (!groups.get(day)) {
            groups.set(day, new Map());
          }

          if (!groups.get(day).get(minute)) {
            groups.get(day).set(minute, []);
          }

          groups.get(day).get(minute).push(event);
          return groups;
        }, new Map())
    );
  }

  /**
   * This function is a comparator for the `sort` method used in this component.
   *
   * @param a The first number.
   * @param b The second number.
   * @returns A number as per JavaScript's sort comparator specifications.
   */
  private compare(a: number, b: number): number {
    // if two timestamps are exactly equal reverse the order
    // for reverse sort
    if (this.reverseSort) {
      return b === a ? -1 : b - a;
    }
    return a - b;
  }
}
