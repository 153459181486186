import { Component, Input } from '@angular/core';
import { ColumnConfiguration } from '../list-configuration.model';

/**
 * Individual value of column of List Item Component.
 */
@Component({
  selector: 'coh-list-item-column-content',
  templateUrl: './list-item-column-content.component.html',
})
export class ListItemColumnContentComponent {
  /** List item column value data. */
  @Input() columnValue: any;

  /** List item column configuration. */
  @Input() config: ColumnConfiguration;
}
