import { Pipe, PipeTransform } from '@angular/core';
import { AjsUpgradeService } from 'src/app/core/services/ajs-upgrade.service';

/**
 * Time object interface.
 */
interface TimeObject {
  hour: number;
  minute: number;
}

/**
 *  @description
 *  Filters an API time object into a formatted timestamp
 *
 *  @example
 *    {{myTimeObj | timeObjectToDate}}
 */
@Pipe({
  name: 'timeObjectToDate'
})
export class TimeObjectToDatePipe implements PipeTransform {
  /**
   * Injected Ajs service.
   */
  dateTimeService: any;

  constructor(
    ajsUpgradeService: AjsUpgradeService,
  ) {
    this.dateTimeService = ajsUpgradeService.get('DateTimeService');
  }

  transform(timeObj: TimeObject): string {
    if (!timeObj ||
      typeof timeObj.hour === 'undefined' ||
      typeof timeObj.minute === 'undefined') {
      return this.dateTimeService.getNotAvailableFormat();
    }

    const timeFormat = this.dateTimeService.getPreferredTimeFormat();
    const dt = this.dateTimeService.timeObjectToDate(timeObj);

    return this.dateTimeService.formatDate(dt, timeFormat);
  }

}
