<div class="statlist-item-container">
  <cog-statlist valueSize="md" [allowWrapping]="true" itemSpacing="flex-wrap">
    <ng-container *ngFor="let item of items">
      <cog-pie-chart
        *ngIf="item.showChart"
        [customChartOptions]="pieChartOptions"
        [percentage]="{
          value: item.value * 100,
          status: 'success'
        }">
      </cog-pie-chart>
      <cog-statlist-item [htmlLabel]="getItemLabel(item)">
        <cog-data-renderer [value]="item.value" [dataType]="item.measurement.dataType"> </cog-data-renderer>
      </cog-statlist-item>
    </ng-container>
  </cog-statlist>
</div>
