import { NgModule } from '@angular/core';

import { AnomalyObjectIconPipe } from './anomaly-object-icon.pipe';

const PIPES = [
  AnomalyObjectIconPipe,
];

@NgModule({
  imports: [],
  providers: [...PIPES],
  exports: [...PIPES],
  declarations: [...PIPES],
})
export class AnomalyObjectIconPipeModule {}
