import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatGridListModule } from '@angular/material/grid-list';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { HelixYfilesModule, yfilesDevLicense, yfilesProdLicense } from '@cohesity/helix-yfiles';
import { IrisCoreModule } from '@cohesity/iris-core';
import { TranslateModule } from '@ngx-translate/core';

import {
  RpaasActivitiesCardModule,
  RpaasDataCardModule,
  RpaasProtectionCardModule,
  RpaasTopologyCardModule,
} from '../../../components';
import { environment } from '../../../environment';
import { CyberVaultingDashboardComponent } from './cyber-vaulting-dashboard.component';

const yfilesLicense = environment.heliosProduction ? yfilesProdLicense : yfilesDevLicense;

/**
 * Module for handling Rpaas Summary Dashboard.
 */
@NgModule({
  declarations: [
    CyberVaultingDashboardComponent,
  ],
  imports: [
    CohesityHelixModule,
    CommonModule,
    IrisCoreModule,
    MatCardModule,
    MatGridListModule,
    RpaasActivitiesCardModule,
    RpaasDataCardModule,
    RpaasProtectionCardModule,
    RpaasTopologyCardModule,
    TranslateModule,
    HelixYfilesModule.withLicense(yfilesLicense),
  ],
  exports: [CyberVaultingDashboardComponent],
})
export class CyberVaultingDashboardModule {}
