<cog-table [source]="data$ | async">
  <table mat-table>
    <ng-container matColumnDef="roleName">
      <th mat-header-cell *matHeaderCellDef>{{ 'name' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div class="cog-table-object" cogDataId="access-scope-name-{{ row.name }}">{{ row.name }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>{{ 'description' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div class="cog-table-object">
          <div class="cog-table-object-body padding-top-sm">
            <div class="cog-table-object-name margin-bottom-sm">
              {{ row.description }}
            </div>
            <div class="cog-table-object-meta">
              <coh-resource-selection-details [resources]="row.resources"></coh-resource-selection-details>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <button mat-icon-button [matMenuTriggerFor]="menu.childMenu" cogDataId="scope-actions-menu-{{ row?.name }}">
          <cog-icon shape="more_vert"></cog-icon>
        </button>

        <cog-actions-menu
          #menu
          [navList]="visibleRowActions$ | async"
          [itemTemplate]="actionMenuItem"></cog-actions-menu>

        <ng-template #actionMenuItem let-action>
          <div
            (click)="rowAction.emit({ action: action.id, rowItem: row })"
            cogDataId="scope-action-{{ action.displayName }}">
            {{ action.displayName | translate }}
          </div>
        </ng-template>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"></tr>
  </table>
</cog-table>
