import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { IrisContextModule } from '@cohesity/iris-core';
import { SOURCE_TREE_SERVICE_FACTORY, SourceTreeModule as IrisSourceTreeModule } from '@cohesity/iris-source-tree';
import { TranslateModule } from '@ngx-translate/core';
import { DialogService } from 'src/app/core/services';
import { RecoverSfdcSearchService } from 'src/app/modules/restore/recover-sfdc/services/recover-sfdc-search.service';

import { NgUpgradeModule } from '../ng-upgrade';
import { PipesModule } from '../pipes';
import { SharedModule } from '../shared.module';
import { SourceMetaModule } from '../source-meta';
import { JobSourceTreeComponent } from './job-source-tree/job-source-tree.component';
import {
  AdPhysicalHostOptionsComponent,
  OracleChannelSettingsComponent,
  ProtectionSourceDetailComponent,
  SourceSpecialParametersComponent,
  VmHostOptionsComponent,
} from './protection-source';
import { AwsEc2OptionsComponent } from './protection-source/aws/aws-ec2-options/aws-ec2-options.component';
import {
  GCPDiskExclusionOptionsComponent,
  GCPFleetInstanceSettingsComponent,
  GCPFleetSubnetSettingsComponent,
} from './protection-source/gcp';
import {
  AzureDiskExclusionOptionsComponent
} from './protection-source/azure/disk-exclusion-options/azure-disk-exclusion-options.component';
import { KubernetesSourceOptionsComponent } from './protection-source/kubernetes/kubernetes-source-options/kubernetes-source-options.component';
import {
  PhysicalFilesHostOptionsComponent,
} from './protection-source/physical/physical-files/physical-files-host-options/physical-files-host-options.component';
import {
  protectionSourceProviders,
  ProtectionSourceTreeServiceFactory,
} from './protection-source/protection-source-tree-service.factory';
import { SfdcFieldsOptionsComponent } from './protection-source/sfdc/sfdc-fields-options/sfdc-fields-options.component';
import {
  PhysicalBlockHostOptionsComponent,
} from './protection-source/shared/physical-block-host-options/physical-block-host-options.component';
import {
  ProtectionSourceMetadataComponent,
} from './protection-source/shared/protection-source-metadata/protection-source-metadata.component';
import {
  ProtectionSourceNodeActionsComponent,
} from './protection-source/shared/protection-source-node-actions/protection-source-node-actions.component';
import {
  SqlAagSelectionChallengeModalComponent,
} from './protection-source/sql/sql-aag-selection-challenge-modal/sql-aag-selection-challenge-modal.component';
import { SqlSourceDetailsComponent } from './protection-source/sql/sql-source-details/sql-source-details.component';
import { SqlSourceMetadataComponent } from './protection-source/sql/sql-source-metadata/sql-source-metadata.component';
import { SqlSourceTreeService } from './protection-source/sql/sql-source-tree.service';
import { AcropolisOptionsComponent } from './protection-source/vm/acropolis-options/acropolis-options.component';
import { HypervOptionsComponent } from './protection-source/vm/hyperv-options/hyperv-options.component';
import { SourceTreeTableActionsComponent } from './source-tree-table-actions/source-tree-table-actions.component';
import { SqlExcludeDialogComponent } from './sql-exclude/sql-exclude-dialog/sql-exclude-dialog.component';
import { SqlExcludeComponent } from './sql-exclude/sql-exclude.component';
import {
  AzureProtectionSourceDetailComponent
} from './protection-source/azure/azure-protection-source-detail/azure-protection-source-detail.component';

@NgModule({
  declarations: [
    AcropolisOptionsComponent,
    AdPhysicalHostOptionsComponent,
    AwsEc2OptionsComponent,
    AzureDiskExclusionOptionsComponent,
    GCPDiskExclusionOptionsComponent,
    GCPFleetInstanceSettingsComponent,
    GCPFleetSubnetSettingsComponent,
    HypervOptionsComponent,
    KubernetesSourceOptionsComponent,
    JobSourceTreeComponent,
    OracleChannelSettingsComponent,
    PhysicalBlockHostOptionsComponent,
    PhysicalFilesHostOptionsComponent,
    ProtectionSourceDetailComponent,
    ProtectionSourceMetadataComponent,
    ProtectionSourceNodeActionsComponent,
    SfdcFieldsOptionsComponent,
    SourceSpecialParametersComponent,
    SourceTreeTableActionsComponent,
    SqlAagSelectionChallengeModalComponent,
    SqlExcludeComponent,
    SqlExcludeDialogComponent,
    SqlSourceDetailsComponent,
    SqlSourceMetadataComponent,
    VmHostOptionsComponent,
    AzureProtectionSourceDetailComponent,
  ],
  imports: [
    CohesityHelixModule,
    CommonModule,
    IrisContextModule,
    IrisSourceTreeModule,
    MatAutocompleteModule,
    NgUpgradeModule,
    PipesModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    SharedModule,
    SourceMetaModule,
    TranslateModule,
  ],
  exports: [
    GCPFleetInstanceSettingsComponent,
    IrisSourceTreeModule,
    JobSourceTreeComponent,
    OracleChannelSettingsComponent,
    ProtectionSourceMetadataComponent,
    ProtectionSourceNodeActionsComponent,
    SourceTreeTableActionsComponent,
    SqlExcludeComponent,
    SqlExcludeDialogComponent,
    AzureProtectionSourceDetailComponent,
  ],
  providers: [
    DialogService,
    SqlSourceTreeService,
    {
      provide: SOURCE_TREE_SERVICE_FACTORY,
      useClass: ProtectionSourceTreeServiceFactory,
    },
    ...protectionSourceProviders,
    RecoverSfdcSearchService,
  ],
})
export class SourceTreeModule {}
