/* tslint:disable */
export enum Environment {
  kCohesity = 'kCohesity',
  kGSuite = 'kGSuite',
  kAcropolis = 'kAcropolis',
  kAD = 'kAD',
  kAgent = 'kAgent',
  kAWS = 'kAWS',
  kAWSNative = 'kAWSNative',
  kAzure = 'kAzure',
  kAzureNative = 'kAzureNative',
  kCassandra = 'kCassandra',
  kCouchbase = 'kCouchbase',
  kElastifile = 'kElastifile',
  kExchange = 'kExchange',
  kFlashBlade = 'kFlashBlade',
  kGCP = 'kGCP',
  kGCPNative = 'kGCPNative',
  kGenericNas = 'kGenericNas',
  kGPFS = 'kGPFS',
  kHBase = 'kHBase',
  kHdfs = 'kHdfs',
  kHive = 'kHive',
  kHyperFlex = 'kHyperFlex',
  kHyperV = 'kHyperV',
  kHyperVVSS = 'kHyperVVSS',
  kIbmFlashSystem = 'kIbmFlashSystem',
  kIsilon = 'kIsilon',
  kKubernetes = 'kKubernetes',
  kKVM = 'kKVM',
  kMongoDB = 'kMongoDB',
  kNetapp = 'kNetapp',
  kNimble = 'kNimble',
  kO365 = 'kO365',
  kO365Outlook = 'kO365Outlook',
  kOracle = 'kOracle',
  kPhysical = 'kPhysical',
  kPhysicalFiles = 'kPhysicalFiles',
  kPuppeteer = 'kPuppeteer',
  kPure = 'kPure',
  kRDSSnapshotManager = 'kRDSSnapshotManager',
  kSQL = 'kSQL',
  kView = 'kView',
  kVMware = 'kVMware'
}
