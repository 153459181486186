import { Injectable } from '@angular/core';
import { ILayoutAlgorithm, PortAdjustmentPolicy, TimeSpanConvertible } from 'yfiles';

import { GraphComponentService } from './graph-component.service';

/**
 * This service allows allows injecting the layout settings for a graph so that any component can trigger a new
 * layout.
 */
@Injectable()
export class GraphLayoutService {

  /**
   * The current layout
   */
  layout: ILayoutAlgorithm;

  constructor(private graphComponentService: GraphComponentService) {}

  /**
   * Updates the layout on the graph
   *
   * @param   duration  The amount of time time spend animating the layout
   */
  async runLayout(duration: TimeSpanConvertible = '1s') {
    if (!this.layout) {
      throw new Error('No layout algorithm set');
    }
    await this.graphComponentService.getGraphComponent().morphLayout({
      layout: this.layout,
      morphDuration: duration,
      portAdjustmentPolicy: PortAdjustmentPolicy.ALWAYS
    });
  }
}
