import { CommonRecoverObjectSnapshotParams } from '@cohesity/api/v2';
import { NoSqlRecoverySnapshotParams } from 'src/app/shared';
import { RecoveryObject } from './recovery-object';

export class NoSQLRecoveryObject extends RecoveryObject {
  /**
   * Specifies the fully qualified name of the object to be restored.
   */
  objectName: string;

  /**
   * Specifies the new name to which the object should be renamed to after the recovery.
   */
  renameTo?: string;

  constructor(public reference: NoSqlRecoverySnapshotParams, object: any) {
    super(reference as CommonRecoverObjectSnapshotParams);
    const { objectName, renameTo }  = object;
    this.renameTo = renameTo;
    this.objectName = objectName;
  }
}
