import { Component, OnInit } from '@angular/core';
import { flagEnabled, getConfigByKey, IrisContextService } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';

@Component({
  selector: 'coh-m365-msft-login-redirect',
  templateUrl: './login-redirect.component.html',
  styleUrls: ['./login-redirect.component.scss']
})
export class LoginRedirectComponent implements OnInit {
  // FEATURE FLAG VARS.
  isSelfServiceEnabled: boolean;

  get selfServeRedirectMessage(): string {
    return this.translateService.instant('login.selfService.redirectMessage');
  }

  get selfServeRedirectLink(): string {
    return this.translateService.instant('login.selfService.redirectLink');
  }

  constructor(
    private irisCtx: IrisContextService,
    private state: StateService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.isSelfServiceEnabled =
      getConfigByKey(this.irisCtx.irisContext, 'login.office365SelfServiceRecovery', this.isSelfServiceEnabled) ||
      flagEnabled(this.irisCtx.irisContext, 'dmsOffice365SelfServiceRecoveryEnabled');
  }

  handleSelfServiceRedirect() {
    this.state.go('self-service-portal.auth');
  }
}
