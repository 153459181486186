import { ComponentType } from '@angular/cdk/portal';
import { InjectionToken, Type } from '@angular/core';
import { RecoveryAction } from 'src/app/shared';

import { CreateRecoveryFormTransformer } from './create-recovery-form-transformer';
import { DynamicFormComponent } from './dynamic-form-component';
import { SearchResultActions } from './search-result-actions';

/**
 * Recovery Form Config is used so specify a form component and transformer class to use
 * for a given environment and recovery action.
 * See below for example.
 */
export interface RecoveryFormConfig {
  /**
   * An optional provider that can create a list of action items for a given search result.
   */
  actionsProvider?: Type<SearchResultActions>;

  /**
   * Form component maps to a Component type that can placed inside of a form using a cdk component portal.
   */
  formComponent?: ComponentType<DynamicFormComponent<any>>;

  /**
   * Form transformer is used to convert to and from the api model to the form model.
   */
  formTransformer: Type<CreateRecoveryFormTransformer<any, any>>;

  /**
   * Object table component that can placed in details tab of recovery page using a cdk component portal.
   */
  objectsComponent?: ComponentType<any>;

  /**
   * Options component that can placed in options tab of recovery page using a cdk component portal.
   */
  optionsComponent?: ComponentType<any>;

  /**
   * Invalid control selector based ngx-sub-form based components for form error validation.
   * Distinct recovery workflows may adopt custom components that do not support focus/blur.
   *
   * e.g mat-form-field .ng-invalid>mat-select
   */
  invalidControlSelector?: string;
}

/**
 * This is used to specify a dynamic form component to load for a given environment and recovery action.
 * See below for example.
 */
export interface RecoveryFormOptions {
  [environment: string]: {
    [K in RecoveryAction | 'CloneDb']?: RecoveryFormConfig;
  };
}

/**
 * The injection token provides access to available recovery form options configs.
 *
 * @example
 * constructor(@Inject(RECOVERY_FORM_OPTIONS) recoveryFormConfigs: RecoveryFormOptions[])
 */
export const RECOVERY_FORM_OPTIONS: InjectionToken<RecoveryFormOptions[]> = new InjectionToken('recovery-form-options');

export const PORTAL_RECOVERY_PARAMS = new InjectionToken<{}>('portalRecoveryParams');

/**
 * Provides a recovery form option values.
 *
 * @example
 *   providers: [
 *   provideRecoveryFormOptions({
 *     kVMware: {
 *       RecoverVMs: {
 *         formComponent: RecoverVmVmwareFormOptionsContainerComponent,
 *         formTransformer: new RecoverVmVmwareFormTransformer(),
 *         objectsComponent: RecoverVmVmwareObjectsComponent,
 *         optionsComponent: RecoverVmVmwareOptionsComponent,
 *       }
 *     },
 *   }),
 * ],
 */
export function provideRecoveryFormOptions(options: RecoveryFormOptions) {
  return { provide: RECOVERY_FORM_OPTIONS, useValue: options, multi: true };
}
