/**
 * SSO Provider interface.
 */
export interface SsoProvider {
  name: string;
  url: string;
}

/**
 * List of SSO Providers.
 */
export const ssoProviders: SsoProvider[] = [
  {
    name: 'CyberArk Workforce Identity',
    url: 'https://docs.cyberark.com/Product-Doc/OnlineHelp/Idaptive/Latest/en/Content/Applications/AppsOvw/ConfigSSO.htm',
  },
  {
    name: 'Duo',
    url: 'https://duo.com/docs/sso',
  },
  {
    name: 'IBM Security Verify',
    url: 'https://docs.verify.ibm.com/verify',
  },
  {
    name: 'JumpCloud',
    url: 'https://support.jumpcloud.com/support/s/article/SSO-Application-Connector-Fields',
  },
  {
    name: 'ManageEngine ADSelfService Plus',
    url: 'https://www.manageengine.com/products/self-service-password/help/admin-guide/Configuration/Self-Service/SSO.html',
  },
  {
    name: 'Microsoft Azure Active Directory',
    url: 'https://docs.microsoft.com/en-us/azure/active-directory/manage-apps/add-application-portal-setup-sso',
  },
  {
    name: 'Okta',
    url: 'https://saml-doc.okta.com/SAML_Docs/How-to-Configure-SAML-2.0-General-Information.html',
  },
  {
    name: 'OneLogin',
    url: 'https://onelogin.service-now.com/kb_view_customer.do?sysparm_article=KB0010398',
  },
  {
    name: 'Ping Identity',
    url: 'https://docs.pingidentity.com/bundle/pingfederate-aquera-connector/page/czu1563995051368.html',
  },
  {
    name: 'RSA SecurID Access',
    url: 'https://community.securid.com/t5/securid-cloud-authentication/add-a-saml-application/ta-p/623027',
  },
  {
    name: 'Thales SafeNet Trusted Access',
    url: 'https://thalesdocs.com/sta/operator/applications/apps_saml/index.html',
  },
];
