import { Compiler, Inject, Injectable, NgModuleFactory, Type } from '@angular/core';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { from, of, timer, zip } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { manifests } from 'src/app/app-dynamic-components';
import { AppModuleConfig, NgFutureStateDeclaration } from 'src/app/app-module.config';
import { APP_MODULES } from 'src/app/app.states';

/**
 * This service automatically loads all of the lazy loaded states and components within the app that are registered
 * as future states or dynamic components. It can be run after the app starts up to pre-load all of our code so that
 * they don't need to be loaded later on.
 */
@Injectable({
  providedIn: 'root',
})
export class PreloadModulesService {
  constructor(
    @Inject(APP_MODULES) private appModules: AppModuleConfig[],
    private compiler: Compiler,
    private irisCtx: IrisContextService
  ) {}

  /**
   * Find all of the lazy loaded components and load them.
   */
  loadModules() {
    if (!flagEnabled(this.irisCtx.irisContext, 'preloadModules')) {
      return;
    }

    const flatten = data =>
      data.reduce((r, e) => ((r = r.concat(Array.isArray(e) ? flatten(e) : e)), r), []);
    const futureStates = flatten(this.appModules.map(appModule => appModule.futureStates));

    // All of the future state load children
    const loadMethods: (() => any)[] = futureStates.map((state: NgFutureStateDeclaration) => state.loadChildren);

    // Add all of the dynamic component load children methods.
    loadMethods.push(...manifests.map(m => m.loadChildren));

    // Wait a few seconds until after core module finished loading and then start loading modules. We want to wait
    // long enough that we don't interfere with the app's startup, and also to spread out the loading a little bit
    // so it doesn't overwhelm the app with processing. These numbers seem to work reasonable well.
    zip(from(loadMethods), timer(2000, 100))
      .pipe(
        // This invokes the loader and actually downloads js files. Each module module may trigger multiple js
        // files depending on how webpack splits them up.
        map(([loader]) => loader()),

        // If the modules been loaded before, we just get the moduleLoader here, otherwise we get a promise that will
        // resolve once the download is complete.
        switchMap(moduleLoader => (moduleLoader instanceof Promise ? from(moduleLoader) : of(moduleLoader))),

        // If we're in AOT mode, the module should already been compiled, so we don't need to do anything.
        // In dev mode, we need to go ahead and compile the module and its components so that it will load
        // quickly when needed.
        switchMap((moduleOrFactory: Type<any> | NgModuleFactory<any>) => {
          if (moduleOrFactory instanceof NgModuleFactory) {
            return of(moduleOrFactory);
          }
          return this.compiler.compileModuleAndAllComponentsAsync(moduleOrFactory);
        })
      )
      .subscribe();
  }
}
