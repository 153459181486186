<cog-banners
  (currentBannerIndex)="updateCurrentBannerIndex($event)"
  (closeButtonClick)="closeBanner($event)"
  [showCloseButton]="showBannerCloseBtn">
  <cog-banner
    *ngFor="let banner of banners"
    [cogDataId]="banner.id"
    [status]="banner.status"
    [title]="banner.title">
    <div class="banner-content" [class.banner-no-action-content]="!banner.actionText" [matTooltip]="banner.tooltip">
      <span class="banner-text">{{ banner.text }}</span>
      <button
        [cogDataId]="'button-' + banner.id"
        class="banner-action"
        *ngIf="banner.actionText"
        (click)="banner.actionCallback()"
        mat-stroked-button
        mat-button>
        {{ banner.actionText }}
      </button>
      <div *ngIf="banner.secondaryButtons" class="secondary-buttons-wrapper">
        <button
          *ngFor="let button of banner.secondaryButtons; let i=index"
          [cogDataId]="'secondary-button-' +(i+1)+'-'+ banner.id"
          [class]="'secondary-button secondary-button-'+ (i+1)"
          (click)="button.actionCallback()"
          mat-stroked-button
          mat-button>
          {{ button.actionText }}
        </button>
      </div>
    </div>
  </cog-banner>
</cog-banners>
