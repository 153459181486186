import { DataPoolConfig, DrPlan, EnvType, ProtectionConfig } from '@cohesity/api/kepler';
import { decorateInterface } from '@cohesity/utils';
import { DecoratedSite } from '@cohesity/iris/feat-data-sites';

import { DrExecutionErrorStatuses, DrExecutionInProgressStatuses } from '../constants';
import { DecoratedDrPlanRpo } from './decorated-dr-plan-rpo';

/**
 * The map of the DR plan environment and it's icon.
 */
const DrPlanEnvIconMap: Record<EnvType, string> = {
  vmware: 'helix:group-virtual-machine',
  view: 'helix:group-view',
  aws: 'helix:group-virtual-machine'
};

/**
 * Interface for DrPlan site connection.
 */
export interface DrPlanSiteConnection {
  /**
   * Name of primary site cluster
   */
  primarySite: DecoratedSite;

  /**
   * List of drSite clusters.
   */
  drSites: DecoratedSite[];
}

/**
 * Type of SLAs.
 */
export type SlaType = 'Met' | 'Missed';

/**
 * Decorates DR plan for UI presentation.
 */
export class DecoratedDrPlan extends decorateInterface<DrPlan>() {
  /**
   * RPO for the plan.
   */
  readonly rpo: DecoratedDrPlanRpo;

  /**
   * The data pool configuration of this DR plan.
   */
  readonly dataPoolConfig: DataPoolConfig;

  /**
   * The protection config of this DR plan.
   */
  readonly protectionConfig: ProtectionConfig;

  /**
   * The name of this plan's primary site.
   */
  readonly location: string;

  /**
   * The name of the data pool of this DR plan.
   */
  readonly dataPool: string;

  /**
   * The primary and DR site clusters details.
   */
  readonly sites: DrPlanSiteConnection;

  /**
   * The environment of this DR plan. The environment of the DR plan depends
   * on the type of objects inside it's data pool.
   */
  readonly environment: EnvType;

  /**
   * Whether the plan is of Cohesity View type.
   */
  readonly isCohesityViewPlan: boolean;

  /**
   * Helix compatible icon of this DR plan's environment.
   */
  readonly environmentIcon: string;

  /**
   * The plan's SLA status. Being calculated using the RPO values.
   */
  sla: SlaType;

  /**
   * Returns whether this plan is currently executing.
   */
  get isExecuting(): boolean {
    return DrExecutionInProgressStatuses.includes(this.status);
  }

  /**
   * Returns whether this plan is currently in error state.
   */
  get isInError(): boolean {
    return DrExecutionErrorStatuses.includes(this.status);
  }

  constructor(plan: DrPlan) {
    super(plan);

    this.dataPoolConfig = this.primarySite?.resources?.find(resource => resource.type === 'DataPool')?.dataPoolConfig;
    this.protectionConfig = this.primarySite?.protectionConfig;
    this.rpo = this.getRpo();
    this.location = this.dataPoolConfig?.siteClusterDetails?.name;
    this.dataPool = this.dataPoolConfig?.name;
    this.sites = this.getSitesInfo();
    this.environment = this.envTypes?.[0];
    this.environmentIcon = DrPlanEnvIconMap[this.environment];
    this.isCohesityViewPlan = this.environment === 'view';
  }

  /**
   * Constructs and returns the RPO details object of this DR plan.
   *
   * @returns The decorated DR plan RPO object.
   */
  private getRpo(): DecoratedDrPlanRpo {
    const { schedule } = this.protectionConfig?.rpo ?? {};

    const rpo: DecoratedDrPlanRpo = {
      rpoDiffUsecs: 0,
    };

    if (schedule) {
      const usecsInSec = 1_000_000;
      const secsInMinute = 60;
      const minutesInHour = 60;
      const hoursInDay = 24;

      if (schedule.unit === 'Minutes') {
        rpo.specifiedRpoUsecs = schedule.frequency * secsInMinute * usecsInSec;
      } else if (schedule.unit === 'Hours') {
        rpo.specifiedRpoUsecs = schedule.frequency * minutesInHour * secsInMinute * usecsInSec;
      } else if (schedule.unit === 'Days') {
        rpo.specifiedRpoUsecs = schedule.frequency * hoursInDay * minutesInHour * secsInMinute * usecsInSec;
      }
    }

    if (this.rpoUsecs) {
      rpo.actualRpoUsecs = this.rpoUsecs;

      if (rpo.specifiedRpoUsecs) {
        rpo.rpoDiffUsecs = Math.ceil(Math.max(rpo.actualRpoUsecs - rpo.specifiedRpoUsecs, 0));

        // Populating based on the current RPO and set RPO.
        this.sla = rpo.rpoDiffUsecs ? 'Missed' : 'Met';
      }
    }

    return rpo;
  }

  /**
   * Returns the names of the primary and DR sites of this plan.
   *
   * @returns An object which details the primary and DR sites' names.
   */
  private getSitesInfo(): DrPlanSiteConnection {
    const result: DrPlanSiteConnection = { primarySite: null, drSites: [] };

    if (this.dataPoolConfig?.siteClusterDetails) {
      result.primarySite = new DecoratedSite(this.dataPoolConfig?.siteClusterDetails);
    }

    if (this.drSites?.length) {
      result.drSites = this.drSites.map(drSite => new DecoratedSite(drSite.targetSiteDetails?.siteClusterDetails));
    }

    return result;
  }
}
