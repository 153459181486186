import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WorkflowAccessContext } from 'src/app/app-module.config';

/**
 * A service for WorkflowAccessContext management (no dependecies)
 */
@Injectable({
  providedIn: 'root'
})
export class WorkflowAccessContextService {
  /**
   * Holds WorkflowAccessContext for current user as Observable
   */
  private workflowAccessContext$ = new BehaviorSubject<WorkflowAccessContext>(null);

  /**
   * Gets the current workflowAccessContext.
   *
   * @returns  current workflowAccessContext.
   */
  get workflowAccessContext(): WorkflowAccessContext {
    return this.workflowAccessContext$.getValue();
  }

  /**
   * Sets the current workflowAccessContext.
   */
  set workflowAccessContext(ctx: WorkflowAccessContext) {
    this.workflowAccessContext$.next(ctx);
  }

  /**
   * Clear the cached workflow access context done on
   * login, logout,
   * impersonation, switch back,
   * cluster scope switch,
   * switch account(currently available only for SSO group users).
   */
  clearWorkflowAccessContext() {
    this.workflowAccessContext = null;
  }
}
