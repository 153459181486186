import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UdaConnectParams } from '@cohesity/api/v1';

/**
 * UDA generic source details settings component. This is used when the UDA
 * connector's source type is 'kOther'.
 */
@Component({
  selector: 'coh-uda-generic-source-details-settings',
  templateUrl: './generic-source-details-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UdaGenericSourceDetailsSettingsComponent {

  /**
   * Getter for retrieving udaParams from source input.
   */
  @Input() udaParams: UdaConnectParams;
}
