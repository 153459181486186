<div class="search-form" role="search" [class.search-focused]="searchWrapperFocused">
  <div [class.search-wrapper-focused]="searchWrapperFocused"
    class="search-wrapper"
    cogDataId="global-search-wrapper">

    <div class="search-wrapper-input-container">
      <cog-icon *ngIf="(globalSearchResultsTypeService.enableResultsType$ | async) ? !searchWrapperFocused : true"
        (click)="focusInput()"
        [ariaLabel]="'search' | translate"
        [inheritDataId]="true"
        class="search-icon margin-left-sm margin-right-sm"
        cogDataId="search-icon"
        shape="search">
      </cog-icon>

      <!-- If search result type is enabled (ability to search objects and files), show the result type dropdown -->
      <ng-container *ngIf="(globalSearchResultsTypeService.enableResultsType$ | async) && searchWrapperFocused">
        <button (menuClosed)="!currentStateSearch && focusInput()"
          mat-flat-button
          color="secondary"
          [inheritDataId]="true"
          [matMenuTriggerFor]="menu"
          class="search-results-type-button"
          cogDataId="global-search-result-type-button"
          type="button">
          <ng-container [ngSwitch]="selectedType">
            <ng-container *ngSwitchCase="globalSearchResultType.Object">
              {{'objects' | translate}}
            </ng-container>

            <ng-container *ngSwitchCase="globalSearchResultType.File">
              {{'files' | translate}}
            </ng-container>
          </ng-container>
        </button>

        <mat-menu #menu="matMenu">
          <button (click)="globalSearchResultsTypeService.selectedType = globalSearchResultType.Object"
            [inheritDataId]="true"
            cogDataId="object"
            mat-menu-item
            type="button">
            {{'objects' | translate}}
           </button>

           <button (click)="globalSearchResultsTypeService.selectedType = globalSearchResultType.File"
            [inheritDataId]="true"
            cogDataId="file"
            mat-menu-item
            type="button">
            {{'files' | translate}}
           </button>
        </mat-menu>
      </ng-container>

      <input #searchInput
        role="search"
        [attr.aria-label]="'globalSearch' | translate"
        (focus)="inputFocused = true; searchResultsEnabled = true"
        (keydown)="onInputKeyDown($event)"
        [formControl]="searchControl"
        [inheritDataId]="true"
        [placeholder]="(inputFocused ? focusedSearchTranslationKey : 'search') | translate"
        class="search-wrapper-input"
        cogDataId="input"
        type="text">

      <cog-spinner *ngIf="searchWrapperFocused && (globalSearchResultsService.loadingInputResults$ | async)"
        class="padding-right"
        size="xs">
      </cog-spinner>
    </div>

    <div (keydown)="onSearchResultsKeyDown($event)"
      *ngIf="showSearchResults &&
        (globalSearchResultsService.inputSearchResults$ | async) as objects"
      class="search-results"
      cogDataId="global-search-results">
      <mat-selection-list [attr.aria-label]="'allSearchResults' | translate"
        (selectionChange)="onSearchResultsSelectionChange($event)"
        [inheritDataId]="true"
        [multiple]="false"
        cogDataId="list">
        <div mat-subheader>
          {{'navigate' | translate}}
        </div>

        <mat-list-option *ngIf="!((globalSearchResultsService.filteredNavItems$ | async).length)" [disabled]="true">
          {{'noMatchingResults' | translate}}
        </mat-list-option>

        <mat-list-option
          *ngFor="let navItem of (globalSearchResultsService.filteredNavItems$ | async) | slice: 0:3"
          [cogDataId]="'nav-item-' + navItem.displayName"
          [inheritDataId]="true"
          [value]="{navItem: navItem}">
          <div class="search-results-option">
            <cog-icon [shape]="navItem.icon" class="margin-right-sm">
            </cog-icon>

            <div class="search-results-option-name-size text-wrap@xs flex-wrap@xs">
              <!-- Highlight the matching parts of the name -->
              <span [innerHtml]="navItem.displayName | highlightText: searchControl.value">
              </span>
            </div>
          </div>
        </mat-list-option>

        <div mat-subheader>
          {{'searchResults' | translate}}
        </div>

        <mat-list-option *ngIf="!objects.length" [disabled]="true">
          {{'noMatchingResults' | translate}}
        </mat-list-option>

        <mat-list-option
          *ngFor="let object of objects | slice: 0:3"
          [cogDataId]="'option-' + object.name"
          [inheritDataId]="true"
          [value]="{object: object}">

          <div class="search-results-option">
            <coh-global-search-item-icon [object]="object" class="margin-right-sm">
            </coh-global-search-item-icon>

            <div class="search-results-option-name-size text-wrap@xs flex-wrap@xs">
              <!-- Highlight the matching parts of the name -->
              <span [innerHtml]="object.name | highlightText: searchControl.value">
              </span>

              <span *ngIf="object.sizeInBytes" class="metadata">
                {{object.sizeInBytes | byteSize}}
              </span>
            </div>
          </div>
        </mat-list-option>

        <mat-divider></mat-divider>

        <mat-list-option [inheritDataId]="true"
          [value]="{navItem: {state: 'ng-search', stateParams: {q: searchControl.value}}}"
          cogDataId="option-see-all-results">
          <div class="search-results-option search-results-option-see-all">
            <div class="no-margin-top" mat-subheader>
              <!-- Although the <mat-option> is clickable, the anchor tag still
               needs a uiSref to allow CMD clicking and right click and then
               "Open Link in New Tab". -->
              <a [inheritDataId]="true"
                [uiParams]="{q: searchControl.value}"
                cogDataId="anchor"
                uiSref="ng-search">
                {{'seeAllResults' | translate}}
              </a>
            </div>

            {{'hitEscToClose' | translate}}
          </div>
        </mat-list-option>
      </mat-selection-list>
    </div>
  </div>

  <button
    (blur)="selectSearchStringBlur()"
    (click)="selectSearchString(searchControl.value)"
    [class.search-submit-hidden]="!searchWrapperFocused"
    [inheritDataId]="true"
    [color]="isIBMAquaEnv ? 'primary' : undefined"
    class="margin-left-sm search-submit"
    cogDataId="submit"
    mat-raised-button>
    {{'search' | translate}}
  </button>

  <button mat-icon-button
    type="button"
    class="search-expand-button"
    (click)="focusInput()">
    <cog-icon [matTooltip]="'search' | translate" shape="search"></cog-icon>
  </button>
</div>
