import { AzureProtectionSource, ProtectionSourceNode, AzureDiskInfo } from '@cohesity/api/v1';
import { AzureLeafTypes, AzureObjectTypes, CloudJobType, Environment } from 'src/app/shared/constants';
import { AzureDiskTag } from '@cohesity/api/v2';
import { InvalidCloudVmState, InvalidCloudVmStateMessage } from '../shared/behaviors/invalid-cloud-vm-state';
import { ProtectionSourceDataNode, TooltipContext } from '../shared/protection-source-data-node';
import { HasMetadata, SourceNodeMetadata } from '../shared/protection-source-metadata/source-node-metadata';

/**
 * Extract tags from an azure ProtectionSourceNode. This is outside the class since it is
 * called from the constructor before the class has been completely initialized.
 *
 * @param   data   A protection source node
 * @returns Any applied tags ids or an empty list.
 */
function findTags(data: ProtectionSourceNode): number[] {
  return (data.protectionSource.azureProtectionSource.tagAttributes || []).map(tag => tag.id);
}

/**
 * Represents an active Azure source node and job tree selection behavior.
 */
export class AzureSourceDataNode extends ProtectionSourceDataNode<AzureProtectionSource>
  implements InvalidCloudVmState, HasMetadata {

  readonly asInvalidCloudVmState = this as InvalidCloudVmState;

  /**
   * Node's metadata.
   */
  readonly metadata: SourceNodeMetadata;

  /**
   * The current selected jobType
   */
  private jobType: string;

  private _azureDiskTags: AzureDiskTag[] = [];

  /**
   * Node's workload type, needs to be either of kSQLServer/kSQLDatabase/kVirtualMachine/kSQLManagedInstance.
   */
  workloadType: 'kSQLServer' | 'kSQLDatabase' | 'kVirtualMachine' | 'kSQLManagedInstance';

  /**
   * Override to disable autoProtection for objects
   * TODO: To remove when all environments support auto-protection
   * */
  disableAutoProtection = false;

  constructor(data: ProtectionSourceNode, readonly level: number) {

    super(Environment.kAzure, data, level, findTags(data));

    if (this.isTag) {
      this.metadata = {
        leafCount: this.children && this.children.length || 0
      };
    } else {
      this.metadata = {
        logicalSize: this.logicalSize,
        leafCount: !AzureObjectTypes.includes(this.type) ? this.leafCount : undefined,
      };
    }
  }

  /**
   * Get lists of azure disks of a node if the node's type is kVirtualMachine.
   *
   * @return   Array of Azure Disk Info.
   */
    get diskInfoList(): AzureDiskInfo[] | null {
      return this.protectionSource.azureProtectionSource && this.protectionSource.azureProtectionSource.diskInfoList;
    }

  /**
   * Get lists of azure disk tags of a node if the node's type is
   * kVirtualMachine.
   *
   * @return   Array of ebs volumes.
   */
  get azureDiskTags(): AzureDiskTag[] {
    return this._azureDiskTags;
  }

  /**
   * Is this a node without a cohesity agent installed?
   * Needed when the jobType is 'kAgent'
   *
   * @return   True if this node doesn't have physical agent installed and jobType is 'kAgent'.
   */
  isNodeWithoutPhysicalAgent = (): boolean => this.jobType === CloudJobType.kAgent &&
    this.type === 'kVirtualMachine' && !this.protectionSource.azureProtectionSource.physicalSourceId;

  /**
   * Is this an Unmanaged Azure VM?
   * Needed when the jobType is 'kSnapshotManager'
   *
   * @return   True if this node doesn't have physical agent installed and jobType is 'kAgent'.
   */
  isNodeUnmanaged = (): boolean => this.jobType === CloudJobType.kSnapshotManager &&
    this.type === 'kVirtualMachine' && !this.protectionSource.azureProtectionSource.isManagedVm;

  /**
   * A node can't be selected if its an agent based job and node doesn't have agent installed.
   *
   * @return   True if this node can be selected.
   */
  canSelect(): boolean {
    return !this.isNodeWithoutPhysicalAgent() && !this.isNodeUnmanaged();
  }

  /**
   * Return tooltip TranslationContext for node.
   *
   * @return  string  tooltip text to be displayed on checkbox hover
   */
  getCheckBoxToolTip(): TooltipContext {
    if (this.isNodeWithoutPhysicalAgent()) {
      return { translateKey: 'noPhysicalAgentInstalledMessage' };
    } else if (this.isNodeUnmanaged()) {
      return { translateKey: 'unmanagedVmsNotSupportedMessage' };
    }
  }

  /**
   * Can this node be auto-protected?
   *
   * @return   True if this node can be autoSelected/auto-protected.
   */
  canAutoSelect(): boolean {
    return this.disableAutoProtection ? false :
      (this.expandable || this.isTag) &&
      CloudJobType.kAgent as string !== this.jobType;
  }

  /**
   * Will this node be excluded globally from protection.
   *
   * @return  True if this node is excluded from protection.
   */
  get isGloballyExcluded(): boolean {
    return !this.canSelect();
  }

  /**
   * Whether the node is a leaf which can be directly selected or not.
   */
  get isLeaf(): boolean {
    return AzureLeafTypes.includes(this.type);
  }

  /**
   * Use this to indicate "no physical agent installed" message
   *
   * @return   True if this node can't be selected'.
   */
  get isInvalidCloudVm(): boolean {
    return !this.canSelect() && !this.isObjectProtected;
  }

  /**
   * Check if the object is protected or not. For auto protected objects, some
   * of the children should be protected with the parent as the
   * auto-protected entity.
   *
   * This is for case when a subscription is protected under the VM tab and then the
   * same subscription appears as protected under SQL tab.
   */
  get isObjectProtected() {
    const isObjectProtected = super.isObjectProtected;

    // For entities that can be auto-protected and is protected, check if the
    // children are mapped to the correct auto-protect parent id.
    if (isObjectProtected && this.canAutoSelect()) {
      return (this.children || []).some(node => node.objectProtectionInfo?.autoProtectParentId === this.id);
    }

    return isObjectProtected;
  }

  /**
   * The message to show for invalid VMs
   *
   * @return   String indicating why node can't be selected
   */
  get invalidCloudVmStateMessage(): InvalidCloudVmStateMessage {
    if (this.isNodeWithoutPhysicalAgent()) {
      return 'agentNotInstalled';
    } else if (this.isNodeUnmanaged()) {
      return 'unmanagedVmsNotSupported';
    }
  }

  /**
   * Set the current jobType
   *
   * @param jobType The current jobType
   */
  setJobType(jobType: string) {
    this.jobType = jobType;
  }
}
