// AJAX response parser logic

;(function(angular, undefined) {

  'use strict';

  angular.module('C').service('evalAJAX', evalAJAXFn);

  function evalAJAXFn($q, cMessage, $log, NgQuorumErrorService, $injector) {
    var StateManagementService;
    return {
      success: successHandler,
      errorMessage: errorHandler,
    };

    /**
     * @description
     * evaluates the success or failuer AJAX call based on response object
     * returned from the call. By default also displays any error messages
     * @param  {object}  response                   HTTP response object
     * @param  {boolean} [showErrorMessage=true]
     * @param  {object}  [errorArgs = {}]           argument object to be passed to errorMessage()
     * @return {boolean}                            was the AJAX call completed successfully
     */
    function successHandler(response, showErrorMessage, errorArgs) {
      showErrorMessage = typeof showErrorMessage !== 'undefined' ?
        showErrorMessage : true;
      errorArgs = typeof errorArgs !== 'undefined' ? errorArgs : {};
      if (response.status && response.status >= 200 && response.status <= 299) {
        return true;
      } else {
        if (showErrorMessage) {
          this.errorMessage(response, errorArgs);
        }
        return false;
      }
    }

    /**
     * Formats AJAX response into human readable string
     * @param  {object}  response http response object (from AJAX request)
     * @param  {object}  [args={persist: false, timeout: false, showMessage: true}] argument Object
     * @return {string}             optionally returns the message string, if showMessage
     *                              is changed to false via the argument object
     */
    function errorHandler(response, args) {
      StateManagementService = StateManagementService || $injector.get('StateManagementService');

      args = typeof args !== 'undefined' ? args : {};

      // setup default arguments
      var theArgs = {
        persist: false,
        timeout: false,
        showMessage: true,
      };

      var errorString = '';

      // override default arguments with any passed in arguments
      angular.extend(theArgs, args);

      // Return early from "unauthorized" status without showing a cMessage
      // unless the user is attempting login. It will be apparent that the user
      // isn't logged in since they will end up on the login page.
      if (response.status === 401 &&
        response.config &&
        response.config.url !== '/login') {
        return;
      }

      if (response.name === 'QuorumError') {
        // If a quorum error, handle differently from regular API errors.
        return NgQuorumErrorService.handleQuorumError(response, args, StateManagementService);
      }

      if (response.data && response.data.message) {
        errorString = response.data.message;
      } else if (response.error) {
        // Handles error responses coming from ng HttpClient api calls.
        errorString = response.error.message;
      } else if (response.status && response.status > 0
          && response.statusText) {
        errorString = [response.status, ': ', response.statusText].join('');
      } else {
        // Handle the status 0 or -1 case when server could not be reached.
        // If this connection fails before the appropriate ui.json is loaded
        // this textKey will be displayed in the cMessage.
        errorString = 'errorConnectingToServer';
        $log.error(response);
      }

      if (theArgs.showMessage) {
        cMessage.error({
          textKey: errorString,
          persist: theArgs.persist,
          timeout: theArgs.timeout,
        });

        // Return a rejected promise so chaining will still work.
        return $q.reject(response);
      } else {
        return errorString;
      }

    }
  }

})(angular);
