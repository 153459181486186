import { Injectable } from '@angular/core';
import { GCPMultiSubnetValues, MultiSubnetFormValues, FleetSubnetPriorityType, CustomSubnetElem } from '@cohesity/api/private';

/**
 * Utility Service to handle Source registration and edit workflows for GCP
 * in case of Multi Subnet Feature.
 *
 */

@Injectable({
  providedIn: 'root',
})
export class GCPMultiSubnetService {
  /**
   * String Array to hold mapping from number to string.
   */
  numberToFleetSubnetPriority: FleetSubnetPriorityType[] = ['kPrimary', 'kSecondary', 'kTertiary'];

  /**
   * Convert the FormValues to appropriate API values.
   *
   * @param   formValues The FormValue that is expected.
   * @return  An object that represents gcpFleetParams in the API value.
   */
  convertMultiSubnetFormValuesToApiValues(formValues: MultiSubnetFormValues): GCPMultiSubnetValues {
    const apiValues: GCPMultiSubnetValues = {
      fleetNetworkParamsVec: [],
    };

    for (let i = 0; i < formValues.locations.length; i++) {
      apiValues.fleetNetworkParamsVec.push({
        fleetSubnetType: formValues.locations[i],
        fleetSubnetPriority: this.numberToFleetSubnetPriority[i],

        // If it's a custom subnet and there's at least one subnet config,
        // then add the subnet priority order.
        ...(formValues.locations[i] === 'kCustom' && formValues.customSubnets?.length ?
          { networkParamsVec: formValues.customSubnets } : {}),
      });
    }

    return apiValues;
  }

  /**
   * Computes and returns the available subnet configs.
   *
   * TODO: The models for entity children are not present. Update them when
   *       we have them.
   *
   * @param entityChildren The entity children list
   * @returns The available subnet configs
   */
  getAvailableSubnetConfigs(entityChildren: unknown[]): CustomSubnetElem[] {
    // A helper function to go through each child and add the subnet config if
    // the type is of subnet type ie gcpEntityType is 6.
    function helper(children) {
      // Maintain a Set for non deduped configs.
      let subnetConfig = new Set([]);

      children?.forEach(entityInfo => {
        const entity = entityInfo?.entity;

        // If type is of subnet type, then add the value as stringified JSON to
        // maintain a clean Set.
        if (entity?.gcpEntity?.type === 6) {
          subnetConfig.add(JSON.stringify({
            subnet: entity.displayName,
            projectId: entity?.gcpEntity?.projectId,
            vpc: entity?.gcpEntity?.vpcNetwork,
          }));
        }

        subnetConfig = new Set([...subnetConfig, ...helper(entityInfo?.children)]);
      });

      return [...subnetConfig];
    }

    // Return set using the helper and parse each JSON Stringified value.
    return helper(entityChildren).map(v => JSON.parse(v));
  }
}
