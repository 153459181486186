// Module: DB Object Details

;(function (angular, undefined) {
  'use strict';

  var detailInjections = [
    'C.simpleCacheService',
    'C.snapshotSelection',
    'C.statList'
  ];

  /**
   * @ngdoc overview
   * @name C.dbDetails
   *
   * @description
   * db details module for object landing page
   */
  angular
    .module('C.dbDetails', detailInjections)
    .config(dbDetailsConfigFn);

  function dbDetailsConfigFn($stateProvider) {
    var viewAccess = 'PROTECTION_VIEW && FEATURE_FLAGS.sqlDashboardEnabled';
    var urlParams = [
      '{jobId}',
      '{rootSourceId}',
      '{policyId}',
      '{env}'
    ].join('&');

    $stateProvider
      .state('db-details', {
        url: '/{sourceId}/dbs/{id}/?' + urlParams,
        title: 'Database Details',
        parentState: 'sql-dashboard.databases',
        canAccess: viewAccess,
        params: {
          sourceId: { type: 'cNum' },
          id: { type: 'cNum' },
          jobId: { type: 'cNum' },
          rootSourceId: { type: 'cNum' },
          policyId: { type: 'string' },
          env: { type: 'string' },
        },
        component: 'dbDetail',
        redirectTo: 'db-details.summary',
      })
      .state('db-details.summary', {
        url: 'summary',
        title: 'Database Details',
        parentState: 'sql-dashboard.databases',
        canAccess: viewAccess,
        component: 'dbSummary',
      });
  }

})(angular);
