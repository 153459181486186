import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * A pipe to truncate the list and show the first element and the remaining
 * as +n,
 *
 * @example
 *  - Basic usage
 *  list = ['first', 'second'];
 *  {{ list | showMore }} => first, +1
 *
 *  - Usage with a collection and key.
 *  list = [{ name: 'first' }, { name: 'second' }];
 *  {{ list | showMore: 'name' }} => first, +1
 */
@Pipe({
  name: 'showMore',
})
export class ShowMorePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  /**
   * The pipe transform function.
   *
   * @param list The list to truncate
   * @param key Optionally pass the key if array is a collection.
   * @param showQty The number of elements to show before truncating.
   * @returns The truncated +n text.
   */
  transform(list: unknown[], key?: string, showQty = 1) {
    // Early return if empty/null array.
    if (!list?.length) {
      return this.translate.instant('naNotAvailable');
    }

    // Create a simple list if object was provided.
    const simpleList = key ? list.map(item => item[key]) : [...list];

    // Split the list into the items to be enumerated and a count of remainder.
    const listToShow = simpleList.splice(0, showQty).join(', ');
    const remainderCount = simpleList.length;

    if (!remainderCount) {
      return listToShow;
    }

    return listToShow + ', +' + (remainderCount);
  }
}
