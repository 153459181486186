import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';

import { getMsecsToTimePeriodValue, TimePeriodValue } from '@cohesity/utils';
import { TypedFormGroup } from '../typed-form-group';


/**
 * The type for the form value.
 */
export type TimePeriodFormValue = TimePeriodValue | number;

/**
 * Generic interface to use for showing available time period options. If
 * hideValue is true, the component should hide the value from the form
 */
export interface TimePeriodOptions {
  value: any;
  label: string;
  labelPlural?: string;
  hideValue?: boolean;
}

/**
 * An interval with minimum and maximum that restrict the user inputs
 */
export interface LimitInterval {
  min?: number;
  max?: number;
  granularity?: string;
  readonlyMessage?: string;
}

/**
 * Default options to use for the mat-select
 */
export const defaultTimePeriodOptions: TimePeriodOptions[] = [
  {value: 'kDay', label: 'granularity.kDay'},
  {value: 'kWeek', label: 'granularity.kWeek'},
  {value: 'kMonth', label: 'granularity.kMonth'},
  {value: 'kYear', label: 'granularity.kYear'},
];

/**
 * Default value to initialize the form to
 */
const defaultValue = {
  value: 1,
  type: 'kDay'
};

/**
 * Default min and max limits of value control.
 */
const limit: LimitInterval = {
  min: 1,
  max: 365000,
};

/**
 * Regular expression to enforce numeric entry.
 */
const numbersOnly = '^[0-9]*$';

/**
 * Utility function to convert the value to TimePeriodValue.
 *
 * @param value The value to be converted.
 */
const getTimePeriodValue = (value: TimePeriodFormValue): TimePeriodValue =>
  typeof value !== 'number' ? value : getMsecsToTimePeriodValue(value);

/**
 * Generic form control for selecting time period values in a form. The form
 * should show a range of possible types and allow a user to specify a value.
 * The options should be shown under a single form label
 *
 * Note - The initial value can be provided of type 'TimePeriodValue' or in
 *        mSecs and the form would return the formValue in the type of
 *        'TimePeriodValue'. Can use getTimePeriodToMsecsValue() utility fn to
 *        convert it to msecs.
 *
 * @example
 *   const form = new TimePeriodSelectorForm({
 *      value: 10, type: 'kDay'
 *   }, defaultTimePeriodOptions, { min: 0, max: 1000});
 *
 *   const form = new TimePeriodSelectorForm(864000000, defaultTimePeriodOptions)
 */
export class TimePeriodSelectorForm extends TypedFormGroup<TimePeriodValue> {

  /**
   * Holds the list of excluded type values.
   */
  private _excludedTypes: string[] = [];

  /**
   * Gets the list of excluded type values
   *
   * @return   list of excluded types
   */
  public get excludedTypes() {
    return this._excludedTypes;
  }

  /**
   * Sets the type values that should be excluded from the options
   *
   * @param   excludedTypes   list of types to exclude
   */
  public set excludedTypes(excludedTypes) {
    this._excludedTypes = excludedTypes;
    this.updatedForExcludedType(this);
  }

  /**
   * Convenience method to get the type form control
   *
   * @return the type form control
   */
  get typeControl() {
    return this.get('type') as UntypedFormControl;
  }

  /**
   * Convenience method to get the value form control
   *
   * @return the value form control
   */
  get valueControl() {
    return this.get('value') as UntypedFormControl;
  }

  /**
   * Get the current selected type option.
   *
   * @return  selected time period option.
   */
  get selectedTypeOption(): TimePeriodOptions {
    return this.typeOptions.find(option => option.value === this.typeControl.value);
  }

  /**
   * Defaults callback to handle whenever the excluded types are updated.
   * If the currently selected values becomes invalid, this will automatically
   * select the first item in the list.
   */
  public updatedForExcludedType: (TimePeriodSelectorForm) => void =
    (form: TimePeriodSelectorForm) => {
      const excludes = form.excludedTypes;
      if (excludes.includes(form.value.type)) {
        const newOption = form.typeOptions
          .find(option => !excludes.includes(option.value));
        form.typeControl.setValue(newOption.value);
      }
    };

  /**
   * Initializes the form group.
   *
   * @param   initialValue   A value to initialize the form control to. If none
   *                         is specified, it will use a default value.
   * @param   typeOptions    Type options.
   * @param   valueLimit     Min/max value limit.
   */
  constructor(
    initialValue: TimePeriodFormValue = defaultValue,
    public typeOptions = defaultTimePeriodOptions,
    public valueLimit: LimitInterval = limit) {
    super({
      type: new UntypedFormControl(getTimePeriodValue(initialValue).type, Validators.required),
      value: new UntypedFormControl(getTimePeriodValue(initialValue).value, [
        Validators.required,
        Validators.min(valueLimit.min),
        Validators.max(valueLimit.max),
        Validators.pattern(numbersOnly),
      ]),
    });
  }

  /**
   * Overrides the parent method to enable or disable the value control as
   * needed
   *
   * @param   opts   Options that were passed to this method. They are just
   *                 passed through to the super class in this case.
   */
  updateValueAndValidity(opts: {onlySelf?: boolean; emitEvent?: boolean} = {}): void {
    // When this is called from the constructor, the type options aren't set yet
    if (!this.typeOptions || !this.value) {
      return super.updateValueAndValidity(opts);
    }

    // Look up the options based on the current value. Default to the
    // first option if nothing is found.
    const typeValue = this.typeControl.value;
    const typeOption = this.typeOptions
      .find((option) => option.value === typeValue) || this.typeOptions[0];
    const valueControl = this.get('value') as UntypedFormControl;
    if (!typeOption.hideValue) {
      valueControl.enable({onlySelf: true, emitEvent: false});
    } else {
      valueControl.disable({onlySelf: true, emitEvent: false});
    }
    super.updateValueAndValidity(opts);
  }

  /**
   * Updates validators for min/max.
   *
   * @param valueLimit the new min/max options.
   */
  updateValueControlLimits(valueLimit: LimitInterval) {
    this.valueControl.setValidators([
      Validators.min(valueLimit.min),
      Validators.max(valueLimit.max),
      Validators.pattern(numbersOnly),
    ]);

    this.updateValueAndValidity();
  }

  /**
   * Override the patchValue function to accomodate if time is provided in msecs.
   *
   * Note - The type is set to 'any' as it throws an error when using 'number'
   *  but technically it should be 'TimePeriodFormValue'
   *
   * @param value The value to be set.
   */
  patchValue(value: any) {
    super.patchValue(getTimePeriodValue(value));
  }

  /**
   * Override the setValue function to accomodate if time is provided in msecs.
   *
   * Note - The type is set to 'any' as it throws an error when using 'number'
   *  but technically it should be 'TimePeriodFormValue'
   *
   * @param value The value to be set.
   */
  setValue(value: any) {
    super.setValue(getTimePeriodValue(value));
  }

  /**
   * Maps error message based on the formControl errors object.
   *
   * @param   formControl The formControl to be checked for error.
   * @return  error message of the formControl.
   */
  getErrorMessage(formControl: AbstractControl): string {
    let errorMsg = '';
    const { errors } = formControl;

    /**
     * Get error code from  the formControl errors object.
     * There should be only one error code at a time.
     */
    const error = Object.keys(errors)[0] || null;
    switch (error) {
      case 'required':
        errorMsg = 'errors.required';
        break;
      case 'min':
        errorMsg = 'errors.minValue';
        break;
      case 'max':
        errorMsg = 'errors.maxValue';
        break;
      case 'pattern':
      case 'invalid':
        errorMsg = 'errors.invalid';
    }

    return errorMsg;
  }
}
