import { AppModuleConfig, CanAccess, StateAccessMap } from 'src/app/app-module.config';

const appPillarsAccess: CanAccess = () => true;

export const AppPillarsConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'app-pillars.**',
      url: '^/data-cloud',
      loadChildren: () => import('./app-pillars.module').then(m => m.AppPillarsModule)
    },
    {
      name: 'app-blank.**',
      url: '^/data-cloud-app',
      loadChildren: () => import('./app-pillars.module').then(m => m.AppPillarsModule)
    },
  ],

  allClusterMap: {
    'app-pillars-home': {
      globalContext: true,
    },
    'app-pillars': {
      globalContext: true,
    },
    'app-blank': {
      globalContext: true,
    },
  },

  getStateAccessMap(): StateAccessMap {
    return {
      'app-pillars-home': appPillarsAccess,
      'app-pillars': appPillarsAccess,
      'app-blank': appPillarsAccess,
    };
  }
};
