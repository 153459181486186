import { Component, Input } from '@angular/core';

/**
 * Button with visibility icon to toggle type of password input
 * Use matSuffix to incorporate the button inside the input.
 * Expects the password input to be passed as a prop to chang it's type on toggling visibility
 *
 * @example
 * <input matInput type="password" #passwordInput />
 * <cog-password-visibility-button
 *  matSuffix
 *  [input]="passwordInput">
 * </cog-password-visibility-button>`
 */
@Component({
  selector: 'cog-password-visibility-button',
  templateUrl: './password-visibility-button.component.html',
})
export class PasswordVisibilityButtonComponent {
  /**
   * Password input to toggle visibility.
   */
  @Input() input: HTMLInputElement;

  /**
   * Current state of password visibility.
   */
  showPassword = false;

  /**
   * Toggles visibility of password input on click
   */
  toggleVisibility(): void {
    this.showPassword = !this.showPassword;
    this.input.type = this.showPassword ? 'text' : 'password';
  }
}
