import { BifrostConnector } from '@cohesity/api/v2';
import { decorateInterface } from '@cohesity/utils';

/**
 * Augmented model for {@link BifrostConnector} to include UI specific properties.
 */
export class DecoratedBifrostConnector extends decorateInterface<BifrostConnector>() {

  /**
   * channel uptime represented in human readable format
   */
  readonly relativeUpTimeInMSecs?: number;

  /**
   * Icon to represent connection status
   */
  get connectionStatusIcon() {
    if (this.connectionStatus?.isActive) {
      return 'helix:status-success!success';
    }

    return 'helix:status-error!critical';
  }

  /**
   * Human readable connection status string
   */
  readonly intlConnectionStatus?: string;

  constructor(connector: BifrostConnector) {
    super(connector);

    // current timestamp in milli seconds
    const currentTimeStamp = new Date().getTime();

    const lastConnectedTimestampMsecs = connector.connectionStatus?.lastConnectedTimestampMsecs;

    if (!Number.isNaN(lastConnectedTimestampMsecs)) {
      // find difference in milliseconds.
      // `lastConnectedTimestampMsecs` is in milliseconds
      this.relativeUpTimeInMSecs = currentTimeStamp - Number(lastConnectedTimestampMsecs);
    }

    if (this.connectionStatus?.isActive) {
      this.intlConnectionStatus = 'dms.status.Connected';
    } else {
      this.intlConnectionStatus = 'dms.status.NotConnected';
    }
  }

}
