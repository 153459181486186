// Controller: Storage by File Category

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.reports')
    .controller('reportsFilesCategoryController', reportsFilesCategoryControllerFn);

  function reportsFilesCategoryControllerFn($rootScope, $scope, $state,
    $stateParams, $log, $filter, ReportsService, SourceService, evalAJAX,
    cUtils, CHART_COLORS, JobService, ReportsUtil, cReportsControlsUtil) {

    $scope.text = $rootScope.text.reportsFilesCategoryCategory;

    $scope.filesCategoryConfig =
      ReportsUtil.getReportConfig('kStorageConsumedByFileCategoriesReport')();

    $scope.getData = getData;
    $scope.downloadReport = downloadReport;
    $scope.dataReady = false;
    $scope.files = [];
    $scope.filesDisplay = [];
    $scope.nDaysLabel = 7;
    $scope.jobName = null;
    $scope.registeredSourceName = null;

    if ($stateParams.objId && $stateParams.jobId) {
      $state.current.help = 'reports_files_category_vm';
    } else {
      $state.current.help = 'reports_files_category_cluster';
    }

    /**
     * activate function
     */
    function activate() {
      var defaultFilters;

      if ($stateParams.jobId) {
        JobService.getJob($stateParams.jobId).then(function getJobSuccess(job) {
          $scope.jobName = job.name;
          $scope.registeredSourceName =
            SourceService.getEntityName(job.parentSource);
        }, evalAJAX.errorMessage);
      }

      defaultFilters = cReportsControlsUtil.getDefaultFilters({
        lastNDays: 7,
        viewBox: {},
      });

      getData(defaultFilters);
    }

    /**
     * builds the API query parameters based on set filters
     *
     * @param      {object}    filters    filters from c-reports-controls
     *
     * @return {Object} API query params
     */
     function getParams(filters) {
      var params = {
        msecsBeforeEndTime: filters.lastNDays * 86400000
      };

      if ($stateParams.jobId) {
        params.jobIds = [parseInt($stateParams.jobId, 10)];
      }

      if ($stateParams.objId) {
        params.objectIds = [$stateParams.objId];
      }

      if (filters.viewBox.viewBoxId) {
        params.viewBoxIds = [filters.viewBox.viewBoxId];
      }

      return params;
    }

    /**
     * requests a csv download via JobRunsService
     *
     * @return {Void}
     */
     function downloadReport() {
      var params = getParams(cReportsControlsUtil.filters);
      params.outputFormat = 'csv';

      ReportsService.getStorageByFileCategory(params);
    }

    /**
     * requests and process the data for the chart
     *
     * @param    {object}    filters  filters selected in reports controls
     *                                component
     */
     function getData(filters) {
      var params = getParams(filters);

      $scope.dataReady = false;
      $scope.files = [];
      $scope.nDaysLabel = filters.lastNDays;

      ReportsService.getStorageByFileCategory(params).then(
        function getFileCatSuccess(response) {
          $scope.files = response.data.map(function mapDataFn(file, index) {
            if (file.varianceLogicalPct !== undefined &&
              file.varianceLogicalPct !== null) {
              file._logicalVariance =
                cUtils.round(file.varianceLogicalPct, 2) + '%';
            }

            // used exclusively for sorting
            file._fileType =
              file.fileType ? file.fileType : $scope.text.storage.file.other;

            file._logicalSizeBytesNDaysAgo =
              file.dataPoints.length <= $scope.nDaysLabel ?
                undefined : file.dataPoints[0].logicalSizeBytes;

            file._numFiles =
              file.dataPoints[file.dataPoints.length - 1].objectCount ?
                file.dataPoints[file.dataPoints.length - 1].objectCount : 0;

            file._logicalSizeBytes =
              file.dataPoints && file.dataPoints.length ?
                file.dataPoints[file.dataPoints.length - 1].logicalSizeBytes :
                undefined;

            return file;
          });

          buildDataChart($scope.files);
        }, evalAJAX.errorMessage)
      .finally(function getFileCatFinally() {
        $scope.dataReady = true;
      });
    }

    /**
     * Build data objects for files by size chart
     * @param  {object} data
     * @return {Void}
     */
     function buildDataChart(data) {
      var bytesData = [];
      var bytesCategories = [];
      var sizeUnit;
      var chartMaxJobsToDisplay = 20;

      var processedData = $filter('filter')(data, function(value, index) {
        return value.dataPoints[value.dataPoints.length - 1].hasOwnProperty('logicalSizeBytes');
      });
      processedData = $filter('orderBy')(processedData, 'dataPoints[0].logicalSizeBytes', true);
      processedData = $filter('limitTo')(processedData, chartMaxJobsToDisplay);
      $scope.topCategoriesBySizeChart.categories = [];

      angular.forEach(processedData, function processData(object, index) {
        // find the units for the largest job and use it for our yAxis labels and conversions
        if (index === 0) {
          sizeUnit = cUtils.bytesToSize(object.dataPoints[object.dataPoints.length - 1].logicalSizeBytes).unit;
        }
        bytesData.push([
          object.fileType ? object.fileType : $scope.text.storage.file.other,
          cUtils.bytesToUnit(object.dataPoints[object.dataPoints.length - 1].logicalSizeBytes, sizeUnit)
        ]);
        bytesCategories.push(object.fileType ? object.fileType : $scope.text.storage.file.other);
      });

      // update chart labels to match scale/unit of largest item
      $scope.topCategoriesBySizeChart.yAxis.labels.format = '{value} ' + sizeUnit;
      $scope.topCategoriesBySizeChart.tooltip.pointFormat = '<b>{point.y:.1f} ' + sizeUnit + '</b>';

      $scope.topCategoriesBySizeChart.series[0].data = bytesData;
      $scope.topCategoriesBySizeChart.xAxis.categories = bytesCategories;
    }

    // Config object for topCategoriesBySizeChart
    $scope.topCategoriesBySizeChart = {
      chartType: 'basic',
      loading: false,
      chart: {
        marginTop: 5,
        height: 300
      },
      series: [{
        name: 'MB',
        data: []
      }],
      yAxis: {
        labels: {
          format: ''
        },
        allowDecimals: false,
        title: {
          text: null
        }
      },
      tooltip: {
        pointFormat: ''
      },
      xAxis: {
        categories: [],
        labels: {
          rotation: -45,
          style: {
            whiteSpace: 'nowrap'
          },
          formatter: function chartFormatter() {
            // do truncation here and return string
            if (this.value.length > 15) {
              return this.value.slice(0, 15) + '...';
            } else {
              return this.value;
            }
          }
        }
      }
    };

    // run setup function on first load
    activate();
  }
})(angular);
