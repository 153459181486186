import { Component } from '@angular/core';
import { ProtectionSourceNode, SqlProtectionSource } from '@cohesity/api/v1';
import { PopoverRef } from '@cohesity/helix';

@Component({
  selector: 'coh-sql-popover',
  templateUrl: './sql-popover.component.html',
  styleUrls: ['./sql-popover.component.scss']
})
export class SqlPopoverComponent {

  /**
   * Access to the current node property.
   */
  get node(): ProtectionSourceNode {
    return this.popoverRef && this.popoverRef.data;
  }

  /**
   * Access to the SQL Protection Source object.
   */
  get sqlSource(): SqlProtectionSource {
    return this.node.protectionSource && this.node.protectionSource.sqlProtectionSource;
  }

  /**
   * Determines if the node is a database.
   */
  get isDatabase(): boolean {
    return this.sqlSource.type === 'kDatabase';
  }

  /**
   * Determines if the node is an AAG Database.
   */
  get isAagDatabase(): boolean {
    return ['kAAGDatabase'].includes(this.sqlSource.type);
  }

  /**
   * Determines if node is a filestream database.
   */
  get isFileStreamDb(): boolean {
    return (this.sqlSource.dbFiles || []).some(file => file.fileType === 'kFileStream');
  }

  /**
   * Whether node is protected or not
   */
  isNodeProtected: boolean;

  constructor(private popoverRef: PopoverRef<ProtectionSourceNode>) {
    const protectionSummary = this.node.protectedSourcesSummary;
    this.isNodeProtected = !!(protectionSummary &&
      protectionSummary[0].leavesCount && protectionSummary[0].leavesCount > 0);
  }

  /**
   * Returns whether nas node has popover or not based on node specific logic
   *
   * @returns  true/false whether node has popover or not
   */
  static hasPopover(sqlNode: ProtectionSourceNode) {
    return !!(['kDatabase', 'kAAGDatabase'].includes(sqlNode.protectionSource.sqlProtectionSource.type));
  }

}
