import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Environment } from '@cohesity/iris-shared-constants';
import { Controls, NgxSubFormRemapComponent, subformComponentProviders, takeUntilDestroyed } from 'ngx-sub-form';
import { tap } from 'rxjs/operators';
import { RemotePrePostScripts } from 'src/app/modules/protection/protection-builder/models';
import { ScriptsForm } from './scripts-form.model';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';

// The default timeout (in minutes) for the scripts.
const defaultScriptTimeoutMins = 15;

// The default value for the host type in case a remote host is specified.
const defaultHostType = 'kLinux';

// The limits for the value of timeouts (in minutes).
const timeoutLimits = { min: 1, max: 525_600 /** 1 year */ };

/**
 * Renders a settings form to supply details of pre and post scripts along with an optional remote host.
 *
 * @example
 *   <coh-settings-list-scripts
 *     [formControl]="control"
 *     [areRemoteScripts]="true">
 *   </coh-settings-list-scripts>
 */
@Component({
  selector: 'coh-settings-list-scripts',
  templateUrl: './settings-list-scripts.component.html',
  styleUrls: ['./settings-list-scripts.component.scss'],
  providers: subformComponentProviders(SettingsListScriptsComponent),
})
export class SettingsListScriptsComponent extends NgxSubFormRemapComponent<RemotePrePostScripts, ScriptsForm>
  implements OnInit, OnChanges {
  /**
   * Specifies whether to show the remote scripts section in the component.
   */
  @Input() areRemoteScripts = false;

  /**
   * Optional binding to customize the title for scripts.
   */
  @Input() title: string;

  /**
   * Type of adapter.
   */
  @Input() environment: Environment;

  /**
   * Is storage snapshot defined in policy
   */
  @Input() isStorageSnapshotPolicy: boolean;

  /**
   * Post backup feature flag
   */
  postBackupScriptSgcFlag = flagEnabled(this.irisContextService.irisContext, 'postBackupScriptForSGC');

  /**
   * Make timeout limits available to template.
   */
  timeoutLimits = timeoutLimits;

  /** Make Environment available to template. */
  Environment = Environment;

  /**
   * identify pure and ibm flash system environment
   */
  isSan: boolean;

  constructor(
    private irisContextService: IrisContextService
  ) {
    super();
  }

  ngOnInit() {
    this.registerValueChangeSubscriptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.environment = changes.environment.currentValue;
    this.isSan = [Environment.kPure, Environment.kIbmFlashSystem].includes(this.environment);
  }

  getDefaultValues(): Partial<ScriptsForm> {
    return {
      isRemoteScript: false,
      remoteHostAddress: '',
      remoteHostType: defaultHostType,
      remoteHostUsername: '',

      preScriptEnabled: false,
      preScriptName: '',
      preScriptParams: '',
      preScriptTimeout: defaultScriptTimeoutMins,
      preScriptContinueOnError: true,

      postScriptEnabled: false,
      postScriptName: '',
      postScriptParams: '',
      postScriptTimeout: defaultScriptTimeoutMins,

      postBackupScriptEnabled: false,
      postBackupScriptName: '',
      postBackupScriptParams: '',
      postBackupScriptTimeout: defaultScriptTimeoutMins,

      postSnapshotScriptEnabled: false,
      postSnapshotScriptName: '',
      postSnapshotScriptParams: '',
      postSnapshotScriptTimeout: defaultScriptTimeoutMins,
    };
  }

  protected transformToFormGroup(apiModel: RemotePrePostScripts, defaults: Partial<ScriptsForm>): ScriptsForm {
    if (apiModel === null) {
      return defaults as ScriptsForm;
    }

    // Extract the toggle values of remote host, pre script and post script.
    const {
      remoteScriptsToggle: isRemoteScript = defaults.isRemoteScript,
      preScriptToggle: preScriptEnabled = defaults.preScriptEnabled,
      postScriptToggle: postScriptEnabled = defaults.postScriptEnabled,
      postBackupScriptToggle: postBackupScriptEnabled = defaults.postBackupScriptEnabled,
      postSnapshotScriptToggle: postSnapshotScriptEnabled = defaults.postSnapshotScriptEnabled,
    } = apiModel;

    // Extract remote host details.
    const {
      address: remoteHostAddress = defaults.remoteHostAddress,
      hostType: remoteHostType = defaults.remoteHostType,
      username: remoteHostUsername = defaults.remoteHostUsername,
    } = apiModel.remoteHost || {};

    // Extract pre script details.
    const {
      scriptName: preScriptName = defaults.preScriptName,
      scriptParams: preScriptParams = defaults.preScriptParams,
      timeout: preScriptTimeout = defaults.preScriptTimeout,
      continueEnabled: preScriptContinueOnError = defaults.preScriptContinueOnError,
    } = apiModel.preScript || {};

    // Extract post script details.
    const {
      scriptName: postScriptName = defaults.postScriptName,
      scriptParams: postScriptParams = defaults.postScriptParams,
      timeout: postScriptTimeout = defaults.postScriptTimeout,
    } = apiModel.postScript || {};

    // Extract post backup script details.
    const {
      scriptName: postBackupScriptName = defaults.postBackupScriptName,
      scriptParams: postBackupScriptParams = defaults.postBackupScriptParams,
      timeout: postBackupScriptTimeout = defaults.postBackupScriptTimeout,
    } = apiModel.postBackupScript || {};

    // Extract post snapshot script details.
    const {
      scriptName: postSnapshotScriptName = defaults.postSnapshotScriptName,
      scriptParams: postSnapshotScriptParams = defaults.postSnapshotScriptParams,
      timeout: postSnapshotScriptTimeout = defaults.postSnapshotScriptTimeout,
    } = apiModel.postSnapshotScript || {};

    return {
      isRemoteScript,
      remoteHostAddress,
      remoteHostType,
      remoteHostUsername,
      preScriptEnabled,
      preScriptName,
      preScriptParams,
      preScriptTimeout,
      preScriptContinueOnError,
      postScriptEnabled,
      postBackupScriptEnabled,
      postSnapshotScriptEnabled,
      postScriptName,
      postScriptParams,
      postScriptTimeout,
      postBackupScriptName,
      postBackupScriptParams,
      postBackupScriptTimeout,
      postSnapshotScriptName,
      postSnapshotScriptParams,
      postSnapshotScriptTimeout,
    };
  }

  protected transformFromFormGroup(form: ScriptsForm): RemotePrePostScripts {
    const apiModel: RemotePrePostScripts = {
      remoteScriptsToggle: form.isRemoteScript,
      preScriptToggle: form.preScriptEnabled,
      postScriptToggle: form.postScriptEnabled,
      postBackupScriptToggle: form.postBackupScriptEnabled,
      postSnapshotScriptToggle: form.postSnapshotScriptEnabled,
    };

    // Convert the remote host details.
    if (apiModel.remoteScriptsToggle) {
      const { remoteHostAddress: address, remoteHostType: hostType, remoteHostUsername: username } = form;
      apiModel.remoteHost = { address, hostType, username };
    }

    // Convert the pre script details.
    if (apiModel.preScriptToggle) {
      const {
        preScriptName: scriptName,
        preScriptParams: scriptParams,
        preScriptTimeout: timeout,
        preScriptContinueOnError: continueEnabled,
      } = form;

      apiModel.preScript = { scriptName, scriptParams, timeout, continueEnabled };
    }

    // Convert the post script details.
    if (apiModel.postScriptToggle) {
      const { postScriptName: scriptName, postScriptParams: scriptParams, postScriptTimeout: timeout } = form;
      apiModel.postScript = { scriptName, scriptParams, timeout };
    }

    // Convert the post backup script details.
    if (apiModel.postBackupScriptToggle) {
      const {
        postBackupScriptName: scriptName,
        postBackupScriptParams: scriptParams,
        postBackupScriptTimeout: timeout,
      } = form;
      apiModel.postBackupScript = { scriptName, scriptParams, timeout };
    }

    // Convert the post snapshot script details.
    if (apiModel.postSnapshotScriptToggle) {
      const {
        postSnapshotScriptName: scriptName,
        postSnapshotScriptParams: scriptParams,
        postSnapshotScriptTimeout: timeout
      } = form;
      apiModel.postSnapshotScript = { scriptName, scriptParams, timeout };
    }

    return apiModel;
  }

  /**
   * Returns a collection of form controls for the subform template.
   */
  protected getFormControls(): Controls<ScriptsForm> {
    const timeoutValidators = [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
      Validators.min(timeoutLimits.min),
      Validators.max(timeoutLimits.max),
    ];

    return {
      // Remote host details.
      isRemoteScript: new UntypedFormControl(null, Validators.required),
      remoteHostAddress: new UntypedFormControl(null),
      remoteHostUsername: new UntypedFormControl(null),
      remoteHostType: new UntypedFormControl(null),

      // Pre script details.
      preScriptEnabled: new UntypedFormControl(null, Validators.required),
      preScriptName: new UntypedFormControl(null),
      preScriptParams: new UntypedFormControl(null),
      preScriptTimeout: new UntypedFormControl(null, timeoutValidators),
      preScriptContinueOnError: new UntypedFormControl(null),

      // Post script details.
      postScriptEnabled: new UntypedFormControl(null, Validators.required),
      postScriptName: new UntypedFormControl(null),
      postScriptParams: new UntypedFormControl(null),
      postScriptTimeout: new UntypedFormControl(null, timeoutValidators),

      // Post backup script details.
      postBackupScriptEnabled: new UntypedFormControl(null, Validators.required),
      postBackupScriptName: new UntypedFormControl(null),
      postBackupScriptParams: new UntypedFormControl(null),
      postBackupScriptTimeout: new UntypedFormControl(null, timeoutValidators),

      // Post snapshot script details.
      postSnapshotScriptEnabled: new UntypedFormControl(null, Validators.required),
      postSnapshotScriptName: new UntypedFormControl(null),
      postSnapshotScriptParams: new UntypedFormControl(null),
      postSnapshotScriptTimeout: new UntypedFormControl(null, timeoutValidators),
    };
  }

  /**
   * Registers subscription for various form controls' value changes.
   */
  registerValueChangeSubscriptions() {
    const requiredValidator = [Validators.required];

    // Enable/disable validations on remote script controls.
    this.formGroupControls.isRemoteScript.valueChanges
      .pipe(
        takeUntilDestroyed(this),
        tap(value => {
          [this.formGroupControls.remoteHostAddress, this.formGroupControls.remoteHostUsername].forEach(control => {
            control.setValidators(value ? requiredValidator : null);
            control.updateValueAndValidity();
          });
        })
      )
      .subscribe();

    // Enable/disable validations on pre script name.
    this.formGroupControls.preScriptEnabled.valueChanges
      .pipe(
        takeUntilDestroyed(this),
        tap(value => {
          const control = this.formGroupControls.preScriptName;
          control.setValidators(value ? requiredValidator : null);
          control.updateValueAndValidity();
        })
      )
      .subscribe();

    // Enable/disable validations on post script name.
    this.formGroupControls.postScriptEnabled.valueChanges
      .pipe(
        takeUntilDestroyed(this),
        tap(value => {
          const control = this.formGroupControls.postScriptName;
          control.setValidators(value ? requiredValidator : null);
          control.updateValueAndValidity();
        })
      )
      .subscribe();

    // Enable/disable validations on post backup script name.
    this.formGroupControls.postBackupScriptEnabled.valueChanges
      .pipe(
        takeUntilDestroyed(this),
        tap(value => {
          const control = this.formGroupControls.postBackupScriptName;
          control.setValidators(value ? requiredValidator : null);
          control.updateValueAndValidity();
        })
      )
      .subscribe();

    // Enable/disable validations on post snapshot script name.
    this.formGroupControls.postSnapshotScriptEnabled.valueChanges
      .pipe(
        takeUntilDestroyed(this),
        tap(value => {
          const control = this.formGroupControls.postSnapshotScriptName;
          control.setValidators(value ? requiredValidator : null);
          control.updateValueAndValidity();
        })
      )
      .subscribe();
  }
}
