import { animate, state, style, transition, trigger } from '@angular/animations';

export const ROW_EXPAND_COLLAPSE_ANIMATION = trigger('expandCollapse', [
  state('collapsed', style({
    height: '0',
    visibility: 'hidden',
    minHeight: '0',
  })),
  state('expanded', style({
    height: '*',
    visibility: 'visible',
  })),
  transition('expanded <=> collapsed',
    animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
  ),
]);
