import { Injectable } from '@angular/core';
import { ArchivalTargetResult } from '@cohesity/api/v2';
import { IrisContextService, isMcmSaaS } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { BackupTargetIconPipe, SnapshotLocationPipe } from 'src/app/shared/pipes';

import { AnySnapshotObject, GroupedObjectSnapshot } from '../models/grouped-object-snapshot';


/**
 * Service for utils for snapshot selector
 */
@Injectable({
  providedIn: 'root'
})
export class SnapshotSelectorUtilsService {
  constructor(
    private backupTargetIconPipe: BackupTargetIconPipe,
    private irisContextService: IrisContextService,
    private snapshotLocationPipe: SnapshotLocationPipe,
    private translateService: TranslateService,
  ) {}

  /**
   * Function to return whether a grouped snapshot is disabled in snapshot selector.
   *
   * @param snapshot The snapshot, with each available target
   * @return True if snapshot cannot be selected.
   */
  getGroupedSnapshotIsDisabled(groupedSnapshot: GroupedObjectSnapshot): boolean {
    return groupedSnapshot.snapshots.every(snapshot => this.getSnapshotIsDisabled(snapshot));
  }

  /**
   * Function to return whether a snapshot is disabled in snapshot selector.
   *
   * @param snapshot The snapshot.
   * @return True if snapshot cannot be selected.
   */
  getSnapshotIsDisabled(snapshot: AnySnapshotObject): boolean {
    // RPaaS snapshots cannot be selected in OnPrem. The user has to use Helios
    // to recover using those.
    return !isMcmSaaS(this.irisContextService.irisContext)
      && (snapshot.ownershipContext === 'FortKnox' ||
        snapshot.externalTargetInfo?.ownershipContext === 'FortKnox');
  }

  /**
   * Function to return tooltip for a snapshot.
   *
   * @param snapshot The snapshot.
   * @return The tooltip for the snaphot.
   */
  getSnapshotTooltip(snapshot: AnySnapshotObject): string {
    if (this.getSnapshotIsDisabled(snapshot)) {
      // If the snapshot is disabled (RPaaS snapshot), show a tooltip.
      return this.translateService.instant('rpaas.snapshotNotAccessible');
    }

    return this.snapshotLocationPipe.transform(
      snapshot.externalTargetInfo.targetName,
      snapshot.externalTargetInfo.ownershipContext,
      snapshot.externalTargetInfo.targetType
    );
  }

  /**
   * Function to return the icon for a snapshot.
   *
   * @param snapshot The snapshot.
   * @return The icon.
   */
  getSnapshotIcon(snapshot: AnySnapshotObject): string {
    const {
      snapshotTargetType,
      externalTargetInfo: {targetType} = {},
    } = snapshot || {} as AnySnapshotObject;

    // In certain cases, the more correct snapshot icon representation is found
    // in the snapshot target type, and not within external target info type.
    // For Example: For RPaaS snapshots, the external target info returns
    // 'Archival', so to differentiate between RPaaS snapshots and external
    // target snapshots, show icon from snapshot target type.
    return this.backupTargetIconPipe.transform(
      ['Local', 'Archival'].includes(snapshotTargetType) ? (targetType || 'local') : snapshotTargetType,
      snapshot?.externalTargetInfo,
    );
  }

  /**
   * Function to return whether a protection run's archival target is disabled
   * in snapshot selector.
   *
   * @param archivalTarget The archival target.
   * @return True if archival target cannot be selected.
   */
  getArchivalTargetIsDisabled(archivalTarget: ArchivalTargetResult): boolean {
    // Run with RPaaS snapshots cannot be selected in OnPrem. The user has to
    // use Helios to recover using those.
    return !isMcmSaaS(this.irisContextService.irisContext)
      && archivalTarget?.ownershipContext === 'FortKnox';
  }

  /**
   * Function to return tooltip for a protection run's archival target.
   *
   * @param archivalTarget The archival target.
   * @param isProtectionRun If the archival target came from a protection run vs a snapshot.
   * @return The tooltip for the protection run's archival target.
   */
  getArchivalTargetTooltip(archivalTarget: ArchivalTargetResult, isProtectionRun = false): string {
    if (this.getArchivalTargetIsDisabled(archivalTarget)) {
      // If the run is disabled (RPaaS snapshot), show a tooltip.
      return this.translateService.instant(
        isProtectionRun ? 'rpaas.protectionRunNotAccessible' : 'rpaas.snapshotNotAccessible'
      );
    }

    return archivalTarget.targetName;
  }

  /**
   * Function to return the icon for a protection run's archival target.
   *
   * @param archivalTarget The archival target.
   * @return The icon.
   */
  getArchivalTargetIcon(archivalTarget: ArchivalTargetResult): string {
    return this.backupTargetIconPipe.transform(archivalTarget.targetType, archivalTarget);
  }
}
