import { HttpParameterCodec } from '@angular/common/http';
import { RecoveryTaskStatus } from '../models';

/**
 * Custom parameter codec to correctly handle the plus sign in parameter
 * values. See https://github.com/angular/angular/issues/18261
 */
class ParameterCodec implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
export const PARAMETER_CODEC = new ParameterCodec();

/**
 * Specifies the header key for sending OpenID connect ID token using which the
 * user will be authenticated against.
 */
export const OPENID_AUTH_TOKEN_HEADER_KEY = 'X-OPEN-ID-AUTHN-TOKEN';

/**
 * Specifies the parameter key for specifying the login type.
 */
export const HELIOS_AUTHN_TYPE_KEY = 'type';

/**
 * Specifies the parameter value for MSFT Self Serve based login.
 */
export const MSFT_AUTHN_TYPE_VAL = 'kMsftSelfService';

/**
 * Specifies the Helios login URL.
 */
export const HELIOS_MCM_LOGIN_URL = '/mcm/login';

/**
 * Specifies the header key for sending the Cohesity tenant ID.
 */
export const HELIOS_TENANT_ID_HEADER_KEY = 'tenantId';

/**
 * Converts the API status received for recovery task into public facing
 * status for self service tasks.
 *
 * @param apiStatus Specifies the status returned within API /recoveries.
 */
export function convertApiStatusToSelfServiceTaskStatus(apiStatus: string): RecoveryTaskStatus {
  if (!apiStatus) {
    return null;
  }

  switch(apiStatus) {
    case 'Accepted':
    case 'OnHold':
      return RecoveryTaskStatus.InitiatingRecovery;
    case 'Running':
    case 'Finalizing':
      return RecoveryTaskStatus.InProgress;
    case 'Canceled':
    case 'Canceling':
      return RecoveryTaskStatus.Canceled;
    case 'Failed':
    case 'Missed':
    case 'Skipped':
      return RecoveryTaskStatus.Failed;
    case 'Succeeded':
    case 'SucceededWithWarning':
      return RecoveryTaskStatus.Completed;
    default:
      return RecoveryTaskStatus.InProgress;
  }
}
