import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';
import { Environment, EnvItems, recoveryGroup } from 'src/app/shared/constants';

/**
 * Returns a function that will evaluate based on RESTORE_MODIFY permission and the specified feature flag.
 */
const restoreModify = (flag: string, environments?: Environment[]) => (ctx: StateAccessContext) => {
  if (flag === 'ngRecoverVm') {
    if (!ctx.FEATURE_FLAGS.enableMultipleDatastores) {
      // This feature is enabled by default via feature-flags.json,
      // if it is disabled, the  NG recovery flow shouldn't be displayed
      // (AngularJS recovery has if statements checks to work around
      // "enableMultipleDatastores", but NG does not).
      return false;
    }
  }
  if (flag === 'ngRecoverFiles') {
    if (ctx.FEATURE_FLAGS.nonBrowsableSupportEnabled) {
      // The new flow does not yet support not browsable volumes.
      return false;
    }
  }

  const canAccess = ctx.RESTORE_MODIFY && ctx.FEATURE_FLAGS[flag];
  if (flag === 'ngRecoverOffice365') {
    // Evaluate condition for kO365 based on O365WorkloadType.
    const { office365WorkloadType } = ctx.stateParams;
    if (office365WorkloadType) {
      return canAccess && ctx.canAccessSomeEnvItems(
        environments.reduce((out, env) => (
          out.concat([office365WorkloadType].map(workloadType =>
          ({ environment: env, entity: workloadType })))), []) as EnvItems[]);
    }
  }
  if (environments) {
    // If an optional environments array is provided, ensure that context has
    // access to those environments.
    return canAccess && ctx.canAccessSomeEnv(environments);
  }

  return canAccess;
};

/**
 * Returns a function that will evaluate based on RESTORE_VIEW permission and the specified feature flag.
 */
const restoreView = (flag: string) => (ctx: StateAccessContext) => ctx.RESTORE_VIEW && ctx.FEATURE_FLAGS[flag];

export const RestoreConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'recovery.**',
      url: '^/recovery/',
      loadChildren: () => import('./recovery/recovery.module').then(m => m.RecoveryModule),
    },
    {
      name: 'recover-exchange-ng.**',
      url: '/recover/exchange',
      loadChildren: () => import('./recover-exchange/recover-exchange-routing.module')
        .then(m => m.RecoverExchangeRoutingModule),
    },
    {
      name: 'recover-ms-sql.**',
      url: '/recover/ms-sql',
      loadChildren: () => import('./recover-ms-sql/recover-ms-sql-routing.module')
        .then(m => m.RecoverMsSqlRoutingModule),
    },
    {
      name: 'recover-rds-ng.**',
      url: '/recover/rds',
      loadChildren: () => import('./recover-rds/recover-rds.module').then(m => m.RecoverRdsModule),
    },
    {
      name: 'recover-aws-db-ng.**',
      url: '/recover/aws-db',
      loadChildren: () => import('./recover-aws-db/recover-aws-db.module').then(m => m.RecoverAwsDbModule),
    },
    {
      name: 'recover-s3-ng.**',
      url: '/recover/s3',
      loadChildren: () => import('./recover-s3/recover-s3.module').then(m => m.RecoverS3Module),
    },
    {
      name: 'recover-storage-volume-ng.**',
      url: '/recover/storage-volume',
      loadChildren: () => import('./recover-storage-volume/recover-storage-volume.module')
        .then(m => m.RecoverStorageVolumeModule),
    },
    {
      name: 'recover-vm-ng.**',
      url: '/recover/vm',
      loadChildren: () => import('./recover-vm/recover-vm.module').then(m => m.RecoverVmModule),
    },
    {
      name: 'recover-files-ng.**',
      url: '/recover/files',
      loadChildren: () => import('./recover-files/recover-files-routing.module').then(m => m.RecoverFilesRoutingModule),
    },
    {
      name: 'recover-cassandra-ng.**',
      url: '/recover/cassandra',
      loadChildren: () => import('./recover-cassandra/recover-cassandra.module').then(m => m.RecoverCassandraModule),
    },
    {
      name: 'recover-couchbase-ng.**',
      url: '/recover/couchbase',
      loadChildren: () => import('./recover-couchbase/recover-couchbase.module').then(m => m.RecoverCouchbaseModule),
    },
    {
      name: 'recover-mongodb-ng.**',
      url: '/recover/mongodb',
      loadChildren: () => import('./recover-mongodb/recover-mongodb.module').then(m => m.RecoverMongodbModule),
    },
    {
      name: 'recover-hbase-ng.**',
      url: '/recover/hbase',
      loadChildren: () => import('./recover-hbase/recover-hbase.module').then(m => m.RecoverHBaseModule),
    },
    {
      name: 'recover-hdfs-ng.**',
      url: '/recover/hdfs',
      loadChildren: () => import('./recover-hdfs/recover-hdfs.module').then(m => m.RecoverHdfsModule),
    },
    {
      name: 'recover-hive-ng.**',
      url: '/recover/hive',
      loadChildren: () => import('./recover-hive/recover-hive.module').then(m => m.RecoverHiveModule),
    },
    {
      name: 'recover-kubernetes-ng.**',
      url: '/recover/kubernetes',
      loadChildren: () => import('./recover-kubernetes/recover-kubernetes.module').then(m => m.RecoverKubernetesModule),
    },
    {
      name: 'recover-virtual-disk-ng.**',
      url: '/recover/virtual-disk',
      loadChildren: () => import('./recover-virtual-disk/recover-virtual-disk.module').then(
        m => m.RecoverVirtualDiskModule
      ),
    },
    {
      name: 'instant-volume-mount-ng.**',
      url: '/recover/instant-volume-mount',
      loadChildren: () => import('./instant-volume-mount/instant-volume-mount.module').then(
        m => m.InstantVolumeMountModule
      ),
    },
    {
      name: 'recover-physical-server-ng.**',
      url: '/recover/physical-server',
      loadChildren: () => import('./recover-physical-server/recover-physical-server.module').then(
        m => m.RecoverPhysicalServerModule
      ),
    },
    {
      name: 'recover-oracle.**',
      url: '/recover/oracle',
      loadChildren: () => import('./recover-oracle/recover-oracle-routing.module').then(
        m => m.RecoverOracleRoutingModule
      ),
    },
    {
      name: 'recover-office365-ng.**',
      url: '/recover/microsoft365',
      loadChildren: () => import('./recover-office365/recover-office365.module').then(
        m => m.RecoverOffice365Module
      ),
    },
    {
      name: 'recover-uda-ng.**',
      url: '/recover/uda',
      loadChildren: () => import('./recover-uda/recover-uda.module').then(m => m.RecoverUdaModule),
    },
    {
      name: 'recover-saphana.**',
      url: '/recover/saphana',
      loadChildren: () => import('./recover-saphana/recover-saphana.module').then(m => m.RecoverSapHanaModule),
    },
    {
      name: 'migrate-vm-ng.**',
      url: '/migrate/vm',
      loadChildren: () => import('./migrate-vm/migrate-vm.module').then(m => m.MigrateVmModule),
    },
    {
      name: 'recover-sfdc-ng.**',
      url: '/recover/sfdc',
      loadChildren: () => import('./recover-sfdc/recover-sfdc.module').then(m => m.RecoverSfdcModule),
    },
    {
      name: 'recover-azure-sql-ng.**',
      url: '/recover/azure-sql',
      loadChildren: () => import('./recover-azure-sql/recover-azure-sql.module').then(m => m.RecoverAzureSqlModule),
    },
    {
      name: 'recover-azure-ad-ng.**',
      url: '/recover/azure-entra-id',
      loadChildren: () => import('./recover-azure-ad/recover-azure-ad.module').then(m => m.RecoverAzureADModule),
    },
  ],

  // TODO(Sam): Revert when recover flow is integrated in DMaaS.
  allClusterMap: {
    'recovery.list': {
      singleClusterState: 'recovery.list',
      allClustersState: 'recovery.list',
      globalContext: true,
    },
    'recovery.detail': {
      singleClusterState: 'recovery.detail',
      allClustersState: 'recovery.detail',
      globalContext: true,
    },
    'recovery.runs': {
      singleClusterState: 'recovery.runs',
      allClustersState: 'recovery.runs',
      globalContext: true,
    },
    'recover-vm-ng': {
      singleClusterState: 'recover-vm-ng',
      allClustersState: 'recover-vm-ng',
      globalContext: true,
    },
    'recover-azure-sql-ng': {
      singleClusterState: 'recover-azure-sql-ng',
      allClustersState: 'recover-azure-sql-ng',
      globalContext: true,
    },
    'recover-rds-ng': {
      singleClusterState: 'recover-rds-ng',
      allClustersState: 'recover-rds-ng',
      globalContext: true,
    },
    'recover-s3-ng': {
      singleClusterState: 'recover-s3-ng',
      allClustersState: 'recover-s3-ng',
      globalContext: true,
    },
    'recover-files-ng': {
      singleClusterState: 'recover-files-ng',
      allClustersState: 'recover-files-ng',
      globalContext: true,
    },
    'recover-storage-volume-ng': {
      singleClusterState: 'recover-storage-volume-ng',
      allClustersState: 'recover-storage-volume-ng',
      globalContext: true,
    },
    'recover-ms-sql': {
      singleClusterState: 'recover-ms-sql',
      allClustersState: 'recover-ms-sql',
      globalContext: true,
    },
    'recover-office365-ng': {
      singleClusterState: 'recover-office365-ng',
      allClustersState: 'recover-office365-ng',
      globalContext: true,
    },
    'recover-sfdc-ng': {
      singleClusterState: 'recover-sfdc-ng',
      allClustersState: 'recover-sfdc-ng',
      globalContext: true,
    },
    'recover-oracle': {
      singleClusterState: 'recover-oracle',
      allClustersState: 'recover-oracle',
      globalContext: true,
    },
    'recover-uda-ng': {
      singleClusterState: 'recover-uda-ng',
      allClustersState: 'recover-uda-ng',
      globalContext: true,
    },
    'recover-saphana': {
      singleClusterState: 'recover-saphana',
      allClustersState: 'recover-saphana',
      globalContext: true,
    },
    'migrate-vm-ng': {
      singleClusterState: 'migrate-vm-ng',
      allClustersState: 'migrate-vm-ng',
      globalContext: true,
    },
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'recovery.list': restoreView('ngRecovery'),
      'recovery.detail': restoreView('ngRecovery'),
      'recovery.runs': restoreView('ngRecovery'),
      'recover-exchange-ng': restoreModify('ngRestoreExchange', [Environment.kExchange]),
      'recover-ms-sql': restoreModify('ngRestoreMsSql', [Environment.kSQL]),
      'recover-rds-ng': restoreModify('ngRecoverRds', [Environment.kAWS]),
      'recover-aws-db-ng': restoreModify('awsDynamoDbEnabled', [Environment.kAWS]),
      'recover-s3-ng': restoreModify('ngRecoverS3', [Environment.kAWS]),
      'recover-storage-volume-ng': restoreModify('ngRecoverStorageVolume', recoveryGroup.storageVolume),
      'recover-vm-ng': restoreModify('ngRecoverVm', recoveryGroup.vm),
      'recover-azure-sql-ng': restoreModify('ngRecoverAzureSql', [Environment.kAzure]),
      'recover-azure-ad-ng': restoreModify('ngRecoverAzureEntraId', [Environment.kEntraID]),
      'recover-files-ng': restoreModify('ngRecoverFiles'),
      'recover-mongodb-ng': restoreModify('ngRecoverMongoDB', [Environment.kMongoDB]),
      'recover-couchbase-ng': restoreModify('ngRecoverCouchbase', [Environment.kCouchbase]),
      'recover-cassandra-ng': restoreModify('ngRecoverCassandra', recoveryGroup.cassandra),
      'recover-hbase-ng': restoreModify('ngRecoverHBase', [Environment.kHBase]),
      'recover-hdfs-ng': restoreModify('ngRecoverHdfs', [Environment.kHdfs]),
      'recover-hive-ng': restoreModify('ngRecoverHive', [Environment.kHive]),
      'recover-kubernetes-ng': restoreModify('ngRecoverKubernetes', recoveryGroup.kubernetes),
      'recover-virtual-disk-ng': restoreModify('ngRecoverVirtualDisk', recoveryGroup.vmdk),
      'instant-volume-mount-ng': restoreModify('ngRecoverInstantVolumeMount', recoveryGroup.mountPoint),
      'recover-physical-server-ng': restoreModify('ngRecoverPhysicalServer', recoveryGroup.physicalServer),
      'recover-oracle': restoreModify('ngRecoverOracle', [Environment.kOracle]),
      'recover-office365-ng': restoreModify('ngRecoverOffice365', [Environment.kO365]),
      'recover-uda-ng': restoreModify('ngRecoverUda', [Environment.kUDA]),
      'recover-saphana': restoreModify('sapHanaEnabledDms', [Environment.kSAPHANA]),
      'recover-sfdc-ng': restoreModify('ngRecoverSfdc', [Environment.kSfdc]),
      'migrate-vm-ng': restoreModify('ngMigrateVm', [Environment.kVMware]),
    };
  },
};
