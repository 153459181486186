<div [formGroup]="form.formGroup"
  class="flex-row-md"
  cohStopPropagation
  [cogDataId]="id">
  <mat-form-field>
    <mat-label>{{ usernameLabel | translate }}</mat-label>
    <input matInput
      cdkFocusInitial
      formControlName="username"
      cogDataId="username-input"
      [inheritDataId]="true">
    <mat-error *ngIf="form.formGroup.get('username')?.invalid">
      {{ 'errors.required' | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field *ngIf="showPassword">
    <mat-label> {{ 'enterPassword' | translate }}</mat-label>
    <input matInput
      type="password"
      formControlName="password"
      cogDataId="password-input"
      [inheritDataId]="true"
      #passwordInput>
    <cog-password-visibility-button matSuffix [input]="passwordInput"></cog-password-visibility-button>
    <mat-error *ngIf="form.formGroup.get('password')?.invalid">
      {{ 'errors.required' | translate }}
    </mat-error>
  </mat-form-field>
</div>