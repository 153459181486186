import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';

import { LoginRedirectComponent, M365AuthenticatorModule, RecoveryCardComponent } from './components';
import { DashboardComponent, RecoveryItemDetailsDialogComponent, RecoveryItemMetadataComponent } from './containers';
import { RecoveredItemIconPipe, RecoveryListItemIconPipe } from './pipes';
import { AuthenticatorService } from './services/authenticator.service';
import { ProgressService } from './services/progress.service';

@NgModule({
  imports: [
    CommonModule,
    CohesityHelixModule,
    M365AuthenticatorModule,
    TranslateModule.forChild(),
  ],
  declarations: [
    DashboardComponent,
    LoginRedirectComponent,
    RecoveredItemIconPipe,
    RecoveryCardComponent,
    RecoveryItemMetadataComponent,
    RecoveryListItemIconPipe,
    RecoveryItemDetailsDialogComponent,
  ],
  exports: [
    DashboardComponent,
    LoginRedirectComponent,
    RecoveryItemMetadataComponent
  ],
  providers: [
    AuthenticatorService,
    ProgressService,
  ]
})
export class M365SelfServicePortalModule {}
