// MODULE: Statistics Parent Module
// Statistics covers performance, storage, and custom stats

;(function(angular, undefined) {
  'use strict';

  angular.module('C.statistics', ['C.jobRunsService', 'C.diskService'])
    .config(cStatsStateConfigFn)
    .controller('StatisticsController', StatisticsControllerFn);

  function cStatsStateConfigFn($stateProvider) {
    $stateProvider
      .state('performance-deprecated', {
        url: '/monitoring/performance',
        params: {
          viewBoxId: undefined,
          clusterId: undefined,
          nodeId: undefined
        },
        help: 'monitoring_performance',
        title: 'Performance',
        canAccess: 'CLUSTER_VIEW',
        parentState: 'performance',
        views: {
          '': {
            templateUrl: 'app/views/page-layouts/ls.html',
            controller: 'StatisticsController'
          },
          'col-l@performance-deprecated': {
            templateUrl: 'app/monitoring/performance/performance.html',
            controller: 'PerformanceController'
          }
        },
      })
      .state('storage', {
        url: '/monitoring/storage',
        params: {
          viewBoxId: undefined,
          clusterId: undefined,
          nodeId: undefined
        },
        help: 'monitoring_storage',
        title: 'Storage',
        canAccess: 'CLUSTER_VIEW',
        views: {
          '': {
            templateUrl: 'app/views/page-layouts/ls.html',
            controller: 'StatisticsController'
          },
          'col-l@storage': {
            templateUrl: 'app/monitoring/storage/storage.html',
            controller: 'StorageController'
          }
        }
      })
      .state('diagnostics', {
        url: '/monitoring/diagnostics?{internal}',
        help: 'monitoring_diagnostics',
        title: 'Advanced Diagnostics',
        canAccess: 'CLUSTER_VIEW',
        params: {
          internal: { type: 'string', _persistOnImpersonation: true, },
        },
        views: {
          '': {
            templateUrl: 'app/views/page-layouts/ls.html',
            controller: 'StatisticsController'
          },
          'col-l@diagnostics': {
            templateUrl: 'app/monitoring/diagnostics/diagnostics.html',
            controller: 'DiagnosticsController'
          }
        }
      });
  }

  function StatisticsControllerFn($rootScope, $scope, $state, $q,
    $timeout, $filter, DateTimeService, ClusterService, NodeService,
    ViewBoxService, AlertService, StatsService, evalAJAX, cUtils,
    CHART_COLORS, Highcharts, FEATURE_FLAGS) {

    var $ctrl = this;

    // For visual co-relation; the highlighted chart points are cached in this array.
    var cachedChartPoints = [];

    /**
     * key lookup for shifting time when user interacts
     * with arrows surrounding date
     * @type {Object}
     */
    var timeShift = {
      day: 86400000,
      week: 604800000,
      month: 2592000000, // 30 days
      twoMonths: 2592000000 * 2,
      quarter: 604800000 * 13
    };

    // convenience functions and vars
    $scope.msecsToFormattedDate = DateTimeService.msecsToFormattedDate;
    $scope.preferredDateFormat = DateTimeService.getPreferredDateFormat();

    $scope.text = $rootScope.text.monitoring_statistics;

    /** @type {Date} used for setting max value on datepicker */
    $scope.today = DateTimeService.endOfDay(new Date());

    /**
     * shared xAxis for chart configs that require shared zooming functionality.
     * used in performance/ and custom-stats/
     * @type {Object}
     */
    $scope.xAxisShared = {
      type: 'datetime',
      minRange: 3600000, // one hour
      events: {
        afterSetExtremes: function() {
          if ($scope.chartExtremes.minimum !== this.min || $scope.chartExtremes.maximum !== this.max) {
            updateExtremes(this.min, this.max);

            // call $scope.$apply as this function is running
            // in highchart's context and outside of angular's context.
            // do so in a timeout to avoid $digest collision
            $timeout(function() {
              $scope.$apply();
            });
          }
        }
      },
      minTickInterval: StatsService.getDefaultRollupInterval()
    };


    // used for managing zoom levels across charts
    $scope.chartExtremes = {
      minimum: null,
      maximum: null
    };

    /**
     * Init this controller.
     *
     * @method   $onInit
     */
    $ctrl.$onInit = function onInit() {
      // Set up alert chart if mcm mode
      _configureAlertChartForMcmMode();
    };

    /**
     * Configure alert chart for visual co-relation.
     */
    function _configureAlertChartForMcmMode() {
      // If mcm mode, add properties to alerts chart for visual co-relation
      if ($rootScope.basicClusterInfo.mcmMode) {
        _.merge($scope.alertsChart, {
          tooltip: {
            shared: true,
          },
          plotOptions: {
            series: {
              point: {
                events: {
                  mouseOver: function onMouseOver(e) {
                    // Capture current point and assign it to event.
                    e.chartX = this.plotX;
                    e.chartY = this.plotY;
                    _toggleChartPointVisibility(e);
                  },
                  mouseOut: function onMouseOut(e) {
                    _toggleChartPointVisibility(e);
                  },

                  // To override default click behavior in mcm mode.
                  click: _.noop,
                },
              },
            },
          },
        });
      }
    }

    /**
     * sets new minimum and maximum values for chart x-axis
     * when zooming/unzooming
     * @param  {Float} newMin
     * @param  {Float} newMax
     * @return {Void}
     */
    function updateExtremes(newMin, newMax) {
      $scope.chartExtremes = {
        minimum: newMin,
        maximum: newMax
      };
    }

    $scope.refreshExtremes = function refreshExtremes() {
      var min = $scope.chartExtremes.minimum;
      var max = $scope.chartExtremes.maximum;
      $scope.resetZoom();
      updateExtremes(min, max);
    };

    /**
     * resets the xAxis zoom levels
     * @return {Void}
     */
    $scope.resetZoom = function resetZoom() {
      $scope.chartExtremes = {
        minimum: null,
        maximum: null
      };
    };

    /**
     * Renders flag symbol for alerts chart.
     * This uses svg path options to draw flag.
     */
    Highcharts.SVGRenderer.prototype.symbols.flag = function (x, y, w, h) {
      return ['M', x, y, 'L', x, y + 5, 'L', x, y - h, 'L', x + h, y - 5, 'z'];
    };

    /**
     * adjust config option so highcharts won't place tick marks
     * on x-axis in between our intervals
     * for instance: marking 12:00's on a 7 day chart
     */
    // TODO: will this still work with variable charts?
    // not sure how to handle this now since each
    // metric could have a different rollupIntervalSecs
    // and rollup is no longer part of timeParams
    // function setMinTickIntervals(params) {
    //     var interval = params.rollup * 1000;
    //     $scope.xAxisShared.minTickInterval = interval;
    // };

    /**
     * alertsChart configuration, shared by performance and diagnostics
     * @type {Object}
     */
    $scope.alertsChart = {
      chartType: 'line',
      loading: true,
      chart: {
        zoomType: 'x',
        height: 150,
        resetZoomButton: {
          theme: {
            display: 'none'
          }
        },
        // 1 pixel margins on left and right prevent
        // border cutoff
        marginTop: 1,
        marginRight: 1,
        marginLeft: 1
      },
      series: [
        {
          name: 'Critical',
          type: 'scatter',

          // critical alerts are the most important
          zIndex: 3,
          color: CHART_COLORS.red,
          data: []
        },
        {
          name: 'Warning',
          type: 'scatter',

          // warning alerts are less important
          zIndex: 2,
          color: CHART_COLORS.gold,
          data: []
        },
        {
          name: 'Info',
          type: 'scatter',

          // Info alerts are less important
          zIndex: 2,
          color: CHART_COLORS.skyBlue,
          data: []
        },
        {
          name: 'Placeholder Series'
        },
      ],
      legend: {
        layout: 'horizontal',
        verticalAlign: 'top',
        align: 'right',
        borderWidth: 0,
        y: -9,
        labelFormatter: function() {
          if (this.name === 'Placeholder') {
            return '';
          }
          return this.name;
        }
      },
      xAxis: $scope.xAxisShared,
      yAxis: {
        alternateGridColor: 'transparent',
        title: {
          text: null
        },
        labels: {
          enabled: false
        },
        allowDecimals: false,
        gridLineColor: 'transparent'
      },
      tooltip: {
        // to be updated based on calculations (GiB, TiB, etc)
        formatter: function bytesLineTooltipFormatter() {
          if (_.get(this, 'point.alert')) {
            return ['<div><strong>',
              this.point.alert.alertDocument.alertName,
              '</strong></div>',
              DateTimeService.usecsToFormattedDate(this.point.alert.firstTimestampUsecs)].
              join('');
          }
        },
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          marker: {
            symbol: 'flag',
            lineWidth: 2,
            lineColor: null,
            states: {
              hover: {
                enabled: false,
              },
            },
          },
          point: {
            events: {
              click: function onClick(e) {
                // if no alert information,
                // this is just the placeholder series
                if (!this.alert) {
                  return false;
                }
                var url = $state.href('alerts.alert-detail', {
                  id: this.alert.id
                });
                window.open(url, '_blank');
              },
            },
          },
        },
      },
    };

    /**
     * Toggles chart point's visibility based on mouseOver/mouseOut event.
     *
     * @param    {Object}    event    mouseOut/mouseOver event.
     */
    function _toggleChartPointVisibility(event) {
      var points;
      var searchPointLoc;

      Highcharts.charts.forEach(function processChart(chart) {
        if (!chart) {
          return;
        }
        switch (event.type) {
          case 'mouseOver':
            points = [];
            chart.series.forEach(function getHoveredPoint(series) {
              if (!series.visible || !series.tooltipOptions.shared) {
                return;
              }
              series.options.kdNow = true;
              searchPointLoc = series.searchPoint(event, true);
              if (searchPointLoc) {
                // Cache searched point
                cachedChartPoints.push(searchPointLoc);
                points.push(searchPointLoc);
              }
            });

            if (points.length > 0) {
              // Show the tooltip & crosshair
              chart.tooltip.refresh(points);
              chart.xAxis[0].drawCrosshair(event, points[0]);
            }
            break;

          case 'mouseOut':
            while (cachedChartPoints.length) {
              searchPointLoc = cachedChartPoints.pop();

              // Remove highlight effect
              searchPointLoc.setState('');
            }

            // Hide tooltip & crosshair
            chart.tooltip.hide();
            chart.xAxis[0].hideCrosshair();
            break;
        }
      });
    }

    /**
     * calls API and constructs data for alerts chart
     *
     * @param      {Object}  timeParams  as returned from
     *                                   StatsService.getTimeRangeParams
     */
    $scope.buildAlertsChart = function buildAlertsChart(timeParams) {

      var alertsParams = {
        maxAlerts: 1000,
        startDateUsecs: timeParams.startTimeMsecs * 1000, // ms to usecs
        endDateUsecs: timeParams.endTimeMsecs * 1000, // ms to usecs
        AlertSeverityList: ['kCritical', 'kWarning', 'kInfo'],
      };

      var _mcmMode = $rootScope.basicClusterInfo.mcmMode;
      var criticalSeries = [];
      var warningSeries = [];
      var infoSeries = [];

      // reset chart data
      $scope.alertsChart.series.forEach(function initializeData(series) {
        series.data = [];
      });

      // If mcmMode, then add systemEvents param
      if (_mcmMode) {
        _.merge(alertsParams, {
          eventCategoryList : ['kSystemEvent'],
          eventTypeList : ['kAlert', 'kClusterSoftwareUpgrade'],
        });
      }

      $scope.alertsChart.loading = true;

      AlertService.getAlerts(alertsParams).then(
        function getAlertsSuccess(result) {
          // these yValues are used solely for positioning
          //  on the chart and have no real meaning
          var yValues = {
            kCritical: 1.0,
            kWarning: 0.6,
            kInfo: 0.2
          };

          // TODO: track timestamps for overlap and give points
          // a different y value to provide visibility.
          angular.forEach(result.data, function (alert, index) {
            var microseconds = DateTimeService.usecsToDate(alert.firstTimestampUsecs);
            microseconds = microseconds.getTime();
            var alertChartObject = {
              x: microseconds,
              y: yValues[alert.severity],
              alert: alert
            };

            switch (alert.severity) {
              case 'kCritical':
                criticalSeries.push(alertChartObject);
                break;

              case 'kWarning':
                warningSeries.push(alertChartObject);
                break;

              case 'kInfo':
                infoSeries.push(alertChartObject);
                break;
            }
          });

          criticalSeries = $filter('orderBy')(criticalSeries);
          warningSeries = $filter('orderBy')(warningSeries);
          infoSeries = $filter('orderBy')(infoSeries);

          // add a fake data set in order to use a 'datetime' xAxis
          // and always have the 'alerts' chart visible
          // and have our alerts chart line up nicely with the other charts,
          $scope.alertsChart.series[3] = StatsService.getFakeSeries(timeParams, 1.75);

          // update the real alerts data
          $scope.alertsChart.series[0].data = criticalSeries;
          $scope.alertsChart.series[1].data = warningSeries;
          $scope.alertsChart.series[2].data = infoSeries;

          // update numbers to display on chart
          $scope.criticalAlerts = criticalSeries.length;
          $scope.warningAlerts = warningSeries.length;
          $scope.infoAlerts = infoSeries.length;

          $scope.alertsChart.loading = false;
        },
        evalAJAX.errorMessage
      );
    };

    /**
     * the entity for which statistics will be displayed.
     * this entity will be set/changed via dropdown selection
     * @type {Object}
     */
    $scope.currentEntity = {};

    /**
     * list of entities that we can display statistics for
     * @type {Array} array of objects (cluster, nodes, viewboxes)
     */
    $scope.entitiesList = [];

    /**
     * will store the cluster stats here for easy access, as we'll need
     * them for calcuations when user wants to viewbox storage info
     * @type {Object}
     */
    $scope.clusterStats = {};

    /**
     * object for tracking date related values.
     * putting values in a combined object so child
     * states can manipulate their values
     * @type {Object}
     */
    $scope.dateInfo = {
      // current range selected for chart display
      currentRange: 'day',
      // represents the current start time for chart display
      // default to null and current date will be usecsToDate
      currentStartTime: null,
      // currentEndTime is our anchor point for moving between
      // ranges and shifting time
      currentEndTime: $scope.today.getTime(),
      // represents the datepicker model
      datepickerEndDate: angular.copy($scope.today)
    };

    /**
     * sets new currentStartTime value when user clicks data change arrows
     * and broadcasts a request for charts to be updated...
     * currentEndTime is updated by params request
     * @param  {[type]} future [description]
     * @return {[type]}        [description]
     */
    $scope.shiftTime = function shiftTime(future) {
      // TODO: don't allow this user to move past current date based on range?
      // currently will simply see a 'no data' message on charts
      if (future) {
        $scope.dateInfo.currentStartTime = $scope.dateInfo.currentStartTime + timeShift[$scope.dateInfo.currentRange];
      } else {
        $scope.dateInfo.currentStartTime = $scope.dateInfo.currentStartTime - timeShift[$scope.dateInfo.currentRange];
      }

      // update our dateInfo.datepickerStartDate to match
      $scope.dateInfo.datepickerEndDate = new Date($scope.dateInfo.currentStartTime + timeShift[$scope.dateInfo.currentRange] - 1);
    };

    /**
     * sets the date range for the charts
     * @param {String} range date range type ('day', 'week', etc)
     */
    $scope.setRange = function setRange(range) {
      $scope.resetZoom();
      $scope.dateInfo.currentRange = range;
      $scope.dateInfo.currentStartTime = $scope.dateInfo.currentEndTime - timeShift[range];
      $scope.$broadcast('updateCharts');
    };

    /**
     * Gets the time parameters from StatsService.getTimeRangeParams()
     * based on current c-date-picker and range selection
     *
     * @param      {Boolean}  updateDateInfo  indicates if dateInfo
     *                                        object values should be
     *                                        updated
     * @return     {Object}   The time parameters.
     */
    $scope.getTimeParams = function getTimeParams(updateDateInfo) {
      var timeParams = $scope.dateInfo.currentStartTime ?
        StatsService.getTimeRangeParams($scope.dateInfo.currentRange, $scope.dateInfo.currentStartTime) :
        StatsService.getTimeRangeParams($scope.dateInfo.currentRange, null);

      if (updateDateInfo) {
        // update currentEndTime
        $scope.dateInfo.currentEndTime = timeParams.endTimeMsecs;

        if (!$scope.dateInfo.currentStartTime) {
          $scope.dateInfo.currentStartTime = timeParams.startTimeMsecs;
          $scope.dateInfo.datepickerStartDate = new Date(timeParams.startTimeMsecs);
        }

        // TODO: Determine how to get a rollupIntervalSec value
        // to setMinTickIntervals() now that its not part of
        // this params object
        // setMinTickIntervals(timeParams);
      }

      return timeParams;
    };

    /**
     * watch for changes to datepicker value and update charts
     * if its not the same value represented by $scope.dateInfo.currentEndTime
     */
    $scope.$watch(
      'dateInfo.datepickerEndDate',
      function endDateWatcher(newVal, oldVal) {
        // filter out changes when the user clicks on the currently selected day
        // or if the user is using shiftTime and we are updating our dateInfo.datepickerStartDate
        if (newVal && typeof $scope.dateInfo.datepickerEndDate === 'object' && $scope.dateInfo.datepickerEndDate.getTime() !== $scope.dateInfo.currentEndTime) {
          $scope.dateInfo.currentStartTime = $scope.dateInfo.datepickerEndDate.getTime() + 1 - timeShift[$scope.dateInfo.currentRange];
          $scope.$broadcast('updateCharts');
        }
      }
    );

    /**
     * sets the current entity selected from c-multiselect
     * and initiates a chart update
     * @param {Object} entity object returned from c-multiselect on-update
     */
    $scope.setCurrentEntity = function setCurrentEntity(entity) {
      $scope.currentEntity = entity;
      $scope.$broadcast('updateCharts');
    };

    /**
     * to be used in custom-stats to lookup names of entities
     * based on API provided id's. These property names match up
     * with attributeVec[0].key to simplify the lookup process
     * @type {Object}
     */
    $scope.entityNameMap = {
      ClusterId: {},
      NodeId: {},
      ViewBoxId: {},
      DiskId: {},
      VaultId: {}
    };

    /**
     * builds our entity list (c-multiselect), and runs updateCharts
     * based on default selection (cluster)
     * this will only need to be run on initial load.
     * @return {void}
     */
    function buildEntitiesList() {

      var promiseArray = [];

      // using a temp object and then setting $scope.entitiesList a single
      // time rather than pushing items to it as they come, which fires
      // the callback on c-multiselect.
      var tmpEntitiesList = [];

      var noParamIds = false;
      var paramClusterId;
      var paramViewBoxId;
      var paramNodeId;

      if (cUtils.isNumeric(+$state.params.clusterId)) {
        paramClusterId = +$state.params.clusterId;
      }
      if (cUtils.isNumeric(+$state.params.viewBoxId)) {
        paramViewBoxId = +$state.params.viewBoxId;
      }
      if (cUtils.isNumeric(+$state.params.nodeId)) {
        paramNodeId = +$state.params.nodeId;
      }
      if (!paramClusterId && !paramViewBoxId && !paramNodeId) {
        noParamIds = true;
      }

      promiseArray = [
        ClusterService.getClusterInfo({
          fetchStats: true
        }),
        NodeService.getClusterNodes({
          includeMarkedForRemoval: true
        }),
        ViewBoxService.getViewBoxes({
          includeStats: $state.current.name !== 'diagnostics',
          includeMarkedForRemoval: true,
          includeHidden: $state.current.name === 'diagnostics'
        })
      ];

      $q.all(promiseArray).then(
        function promiseArraySuccess(responses) {

          var clusterName;

          // process Cluster response
          if (angular.isObject(responses[0].data)) {
            clusterName = responses[0].data.name ?
              [$scope.text.clusterLabel, responses[0].data.name].join('') :
              '';

            $scope.entityNameMap.ClusterId[responses[0].data.id] = clusterName;

            // save cluster stats for easy calculations
            // when showing viewbox storage details
            $scope.clusterStats = responses[0].data.stats || null;

            tmpEntitiesList.push({
              id: responses[0].data.id,
              // name is for dropdown purposes, as its an expected property.
              // displayName is a clean version of the name for all other uses
              name: clusterName,
              displayName: responses[0].data.name,
              stats: responses[0].data.stats,
              type: 'cluster',
              selected: paramClusterId === responses[0].data.id || noParamIds,
              throughput: {
                schemaName: 'kBridgeClusterLogicalStats',
                readMetric: 'kNumBytesRead',
                writeMetric: 'kNumBytesWritten',
                rollupFn: 'rate'
              },
              iops: {
                schemaName: 'kBridgeClusterLogicalStats',
                readMetric: 'kReadIos',
                writeMetric: 'kWriteIos',
                rollupFn: 'rate'
              },
              latency: {
                schemaName: 'kBridgeClusterWorkloadLogicalStats',
                readMetric: 'kReadLatencyUsecs',
                writeMetric: 'kWriteLatencyUsecs',
                rollupFn: 'average'
              },
              cpuMemory: {
                schemaName: 'kSentryClusterStats',
                cpuMetric: 'kCpuUsagePct',
                memoryMetric: 'kMemoryUsagePct',
                rollupFn: 'average'
              },
              dataRedux: {
                logical: {
                  schemaName: 'kBridgeClusterLogicalStats',
                  metric: 'kUnmorphedUsageBytes',
                  rollupFn: 'latest'
                },
                physical: {
                  schemaName: 'kBridgeClusterStats',
                  metric: 'kMorphedUsageBytes',
                  rollupFn: 'latest'
                }
              }
            });
          }

          // process Nodes response
          if (responses[1].data && responses[1].data.length) {
            responses[1].data.forEach(
              function nodesLoop(node, index) {
                var nodeName = [$scope.text.nodeLabel, node.id, ' (', node.ip, ')'].join('');
                $scope.entityNameMap.NodeId[node.id] = nodeName;

                // don't add nodes to tmpEntitiesList if user is on storage page
                // as we don't have storage related stats for nodes
                if ($state.current.name !== 'storage') {
                  tmpEntitiesList.push({
                    id: node.id,
                    name: nodeName,
                    displayName: node.id,
                    type: 'node',
                    selected: paramNodeId === node.id,
                    throughput: false,
                    iops: false,
                    latency: false,
                    cpuMemory: {
                      schemaName: 'kSentryNodeStats',
                      cpuMetric: 'kCpuUsagePct',
                      memoryMetric: 'kMemoryUsagePct',
                      rollupFn: 'average'
                    },
                    dataRedux: false
                  });
                }
              }
            );
          }

          // process View Boxes response
          responses[2].forEach(
            function viewBoxesLoop(viewbox, index) {
              var viewboxName = [$scope.text.viewboxLabel, viewbox.name].join('');
              $scope.entityNameMap.ViewBoxId[viewbox.id] = viewboxName;

              tmpEntitiesList.push({
                id: viewbox.id,
                name: viewboxName,
                displayName: viewbox.name,
                stats: viewbox.stats,
                storagePolicy: viewbox.storagePolicy,
                type: 'viewbox',
                selected: paramViewBoxId === viewbox.id,
                throughput: {
                  schemaName: 'kBridgeViewBoxLogicalStats',
                  readMetric: 'kNumBytesRead',
                  writeMetric: 'kNumBytesWritten',
                  rollupFn: 'rate'
                },
                iops: {
                  schemaName: 'kBridgeViewBoxLogicalStats',
                  readMetric: 'kReadIos',
                  writeMetric: 'kWriteIos',
                  rollupFn: 'rate'
                },
                latency: {
                  schemaName: 'kBridgeViewBoxWorkloadLogicalStats',
                  readMetric: 'kReadLatencyUsecs',
                  writeMetric: 'kWriteLatencyUsecs',
                  rollupFn: 'average'
                },
                cpuMemory: false,
                dataRedux: {
                  logical: {
                    schemaName: 'kBridgeViewBoxLogicalStats',
                    metric: 'kUnmorphedUsageBytes',
                    rollupFn: 'latest'
                  },
                  physical: {
                    schemaName: 'kBridgeViewBoxStats',
                    metric: 'kMorphedUsageBytes',
                    rollupFn: 'latest'
                  }
                }
              });
            }
          );

          $scope.entitiesList = tmpEntitiesList;
        },
        evalAJAX.errorMessage
      );

    }

    // load our list of entities, which will set the
    // initial entity and load charts!
    buildEntitiesList();

  }

})(angular);
