import { Component } from '@angular/core';

import { BaseProtectionBuilderComponent } from '../../../../protection-builder/components/base-protection-builder/base-protection-builder.component';
import { ProtectionType } from '../../../../protection-builder/models';

/**
 * Default value for protection type.
 */
export const DefaultProtectionType = 'kAuto';

@Component({
  selector: 'coh-settings-list-hyperv-protection-type',
  templateUrl: './settings-list-hyperv-protection-type.component.html',
  styleUrls: ['./settings-list-hyperv-protection-type.component.scss']
})
export class SettingsListHypervProtectionTypeComponent extends BaseProtectionBuilderComponent<ProtectionType, any> {
  /**
   * Default protection type value.
   */
  _value: ProtectionType = DefaultProtectionType;

  /**
   * Form control init.
   */
  initFormControl() {
    if (this.protectionGroup && this.protectionGroup.protectionType) {
      this.value = this.protectionGroup.protectionType;
      this.formControl.setValue(this.value);
    }
  }
}
