<!-- Anomaly information -->
<cog-statlist [vertical]="false" class="margin-bottom-lg" cogDataId="anomaly-stats">
  <cog-statlist-item label="{{ 'anomalyStrength' | translate }}">
    <div class="flex-row vertical-align" *ngIf="anomalyProperties.anomalyStrength; else noStrengthTemplate">
      <cog-pie-chart
        [percentage]="{
          value: +anomalyProperties.anomalyStrength,
          status: (securityService.anomalyStrengthThreshold$ | async) < +anomalyProperties.anomalyStrength ?
            'critical' : 'warning'
        }">
      </cog-pie-chart>
      <span>{{ anomalyProperties.anomalyStrength }}%</span>
    </div>
    <ng-template #noStrengthTemplate>
      <span>{{ 'naNotAvailable' | translate }}</span>
    </ng-template>
  </cog-statlist-item>
  <cog-statlist-item label="{{ 'affectedSystem' | translate }}">
    {{ anomalyAlert.incidence?.antiRansomwareDetails?.isBaas ?
      (anomalyAlert.incidence?.antiRansomwareDetails?.baasRegionId | translate) :
      anomalyProperties.cluster }}
  </cog-statlist-item>
  <cog-statlist-item label="{{ 'affectedSource' | translate }}">
    {{ anomalyProperties.source | naLabel }}
  </cog-statlist-item>
  <ng-container *ngIf="filesStats">
    <cog-statlist-item label="{{ 'kAddedModified' | translate }}">
      {{ filesStats?.modified | humanizeFromNumbers }} {{ 'files' | translate }}
    </cog-statlist-item>
    <cog-statlist-item label="{{ 'kDeleted' | translate }}">
      {{ filesStats?.deleted | humanizeFromNumbers }} {{ 'files' | translate }}
    </cog-statlist-item>
  </ng-container>
</cog-statlist>

<!-- AntiRansomware Snapshot list template -->
<!-- Filter template -->
<div class="flex-row vertical-align margin-top margin-bottom" cogDataId="snapshot-filter">
  <cog-filters>
    <cog-value-property-filter
      cogDataId="tag-type"
      [inheritDataId]="true"
      *cogFilterDef="
        let params = params;
        let filter;
        quickFilter: true;
        key: 'tagType';
        label: 'tag' | translate;
        filterType: 'valueProperty'
      "
      [filterDefParams]="params"
      [filter]="filter"
      [autoApply]="!isNewSnapshotTaggingEnabled"
      [allowMultiple]="isNewSnapshotTaggingEnabled"
      [filterValues]="tagTypeFilters || []">
    </cog-value-property-filter>

    <cog-value-property-filter
      cogDataId="snapshot-type"
      [inheritDataId]="true"
      *cogFilterDef="
        let params = params;
        let filter;
        quickFilter: true;
        key: 'snapshotType';
        label: 'type' | translate;
        filterType: 'valueProperty'
      "
      [filterDefParams]="params"
      [filter]="filter"
      [autoApply]="true"
      [allowMultiple]="false"
      [filterValues]="snapshotTypeFilters || []">
    </cog-value-property-filter>
  </cog-filters>
</div>

<div *ngIf="!loading; else spinner">
  <!-- Table view -->
  <!-- ngIf is not working with filters hence using hidden -->
  <cog-table
    name="snapshotListTable"
    cogDataId="snapshot-list"
    [hidden]="filteredSnapshotListDataSource?.length === 0"
    [source]="filteredSnapshotListDataSource || []"
    [selection]="hasTaggingPermissions ? tableSelection : undefined"
    [canSelectRowFn]="canSelectRow"
    [canSelectAnyRowsFn]="canSelectAnyRows"
    [persistSelection]="true">
    <cog-table-multi-action [displayStatus]="false">
      <button
        *ngIf="isNewSnapshotTaggingEnabled"
        [dgHasPermissions]="['MCM_MANAGE_TAGS']"
        mat-flat-button
        color="primary"
        (click)="manageSnapshotTags()"
        cogDataId="dg-ar-snapshot-list-manage-snapshots-button">
        {{ 'dg.sc.inventory.manageSnapshotTags' | translate }}
      </button>
      <button
        *ngIf="!isNewSnapshotTaggingEnabled"
        cogDataId="{{ action }}"
        [inheritDataId]="true"
        class="action-button"
        mat-button
        color="primary"
        (click)="confirmAction(action)">
        {{ action | translate }}
      </button>
    </cog-table-multi-action>
    <table mat-table matSort matSortActive="timestampUsecs" matSortDirection="desc">

      <ng-container *ngFor="let metric of allMetrics">
        <ng-container matColumnDef="{{ metric }}">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ metric | metricType }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{ metric | metricValue: row }}
          </td>
        </ng-container>
      </ng-container>

      <!-- Location of Snapshot -->
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'location' | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          {{ getSnapshotLocation(row.snapshotInfo) }}
        </td>
      </ng-container>

      <!-- Time -->
      <ng-container matColumnDef="timestampUsecs">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'snapshot' | translate }}
        </th>
        <td mat-cell *matCellDef="let row; let i = index" [ngClass]="{snapshots: isNewSnapshotTaggingEnabled}">
          <div class="flex-row vertical-align">
            <div class="margin-right">
              <dg-ar-snapshot-indicator
                [anomalousTimestamps]="anomalousTimestamps"
                [latestCleanTimestamp]="latestCleanTimestamp"
                [snapshotTimestampUsecs]="row.timestampUsecs">
              </dg-ar-snapshot-indicator>
            </div>
            {{ row.timestampUsecs | cogDate }}
            <div *ngIf="row.tags && !isNewSnapshotTaggingEnabled"
              class="margin-left-sm" cogDataId="tag-indicator-{{ i }}" [inheritDataId]="true">
              <dg-ar-anomaly-tags [tags]="row.tags"></dg-ar-anomaly-tags>
            </div>
          </div>
          <div cogDataId="dg-ar-snapshots-list-snapshot-tags-list"
            *ngIf="row.hmsTags && row.hmsTags.length && isNewSnapshotTaggingEnabled"
            class="margin-top">
            <ng-container *ngFor="let tag of row.hmsTags">
              <coh-colored-tag [tag]="tag" class="tags"></coh-colored-tag>
            </ng-container>
          </div>
        </td>
      </ng-container>

      <!-- Header and row definition -->
      <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
      <tr mat-row class="c-fade-sm animate-staggered" *matRowDef="let row; columns: displayColumns"></tr>
    </table>
    <mat-paginator [length]="filteredSnapshotListDataSource?.length"></mat-paginator>
  </cog-table>

  <!-- Blank card when there are no filtered objects -->
  <cog-blank-card *ngIf="filteredSnapshotListDataSource?.length === 0"></cog-blank-card>
</div>

<ng-template #spinner>
  <cog-spinner class="margin-top-lg"></cog-spinner>
</ng-template>
