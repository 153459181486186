import { Component, EventEmitter, Input, Output  } from '@angular/core';
import { LocalizedEmail } from 'src/app/models';

/**
 * This component lets the user add/remove multiple localized email fields.
 *
 * @example
 *   <coh-localized-email-list
 *     [(model)]="emailWithLocaleArray">
 *   </coh-localized-email-list>
 */
@Component({
  selector: 'coh-localized-email-list',
  templateUrl: './localized-email-list.component.html',
  styleUrls: ['./localized-email-list.component.scss']
})
export class LocalizedEmailListComponent {
  /**
   * The ngModel alias that is to be propagated. This is the array of the
   * localized email.
   */
  @Input() model: LocalizedEmail[];

  /**
   * If you need to hide the floating label, set this to true.
   */
  @Input() hideLabel?: boolean;

  /**
   * If you need the emails without locale, set this to true.
   */
  @Input() withoutLocale?: boolean;

  /**
   * Whether recipient type dropdown(to/cc) should be enabled.
   */
  @Input() enableRecipientType?: boolean;

  /**
   * The change fn which gets called when the model is changed hence
   * propagating the changes.
   */
  @Output() modelChange = new EventEmitter<LocalizedEmail[]>();

  /**
   * Function to add a new localized email and update the model.
   */
  addMore() {
    this.model.push({ emailAddress: '' });
  }
}
