<mat-card [class.card-container]="editing" cogFormGroup>
  <div mat-card-title *ngIf="title">{{title}}</div>

  <button
    mat-icon-button
    *ngIf="!editing"
    class="edit-form-section"
    type="button"
    cogDataId="edit-form-section"
    [inheritDataId]="true"
    (click)="setEditing(true)">
    <cog-icon shape="edit!outline"></cog-icon>
  </button>

  <div class="summary-section" [@openClose]="editing ? 'close' : 'open'">
    <ng-content select="[cohFormSectionSummary]"></ng-content>
  </div>
  <div class="edit-section" [@openClose]="editing ? 'open' : 'close'">
    <ng-content select="[cohFormSectionEdit]"></ng-content>
  </div>

  <button mat-flat-button
    *ngIf="editing && !actionsOutlet"
    [disabled]="!canSave"
    class="save-form-section"
    type="button"
    color="primary"
    [cogDataId]="canSave ? 'save-form-section' : 'save-form-section-disabled'"
    [inheritDataId]="true"
    (click)="setEditing(false)">
    {{saveLabel ? saveLabel : 'save' | translate}}
  </button>
  <ng-content *ngIf="editing && actionsOutlet" select="[cohFormSectionActions]"></ng-content>
  <ng-content *ngIf="infoOutlet" select="[cohFormSectionInfo]"></ng-content>
</mat-card>
