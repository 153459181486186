<div class="primary-column" *ngIf="node">
  <span *ngFor="let i of paddingArray" class="spacer"></span>
  <button
    type="button"
    mat-icon-button
    matTreeNodeToggle
    class="tree-node-toggle-button"
    *ngIf="treeControl.isExpandable(node)">
    <cog-icon class="mat-icon-rtl-mirror"
      shape="{{treeControl.isExpanded(this.node) ? 'expand_more' : 'chevron_right'}}">
    </cog-icon>
  </button>

  <!-- use a disabled button to provide padding for tree leaf -->
  <button mat-icon-button disabled *ngIf="!treeControl.isExpandable(node)"></button>
  <ng-container *ngIf="treeDetails.length">
    <ng-container *ngTemplateOutlet="treeDetails[0].template; context: {$implicit: nodeDetailContext}"></ng-container>
  </ng-container>
</div>
<ng-container *ngIf="isInitialized">
  <ng-container *ngFor="let detail of treeDetails.slice(1)">
    <ng-container *ngTemplateOutlet="detail.template; context: {$implicit: nodeDetailContext}"></ng-container>
  </ng-container>
</ng-container>
