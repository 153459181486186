import { Injectable } from '@angular/core';
import { RpaasOnboardInfo, RpaasServiceApi } from '@cohesity/api/v2';
import { flagEnabled, IrisContextService, isRpaasScope } from '@cohesity/iris-core';
import { HookMatchCriteria, Transition } from '@uirouter/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GuardPriority, GuardResult, StateGuard } from 'src/app/core/state/state-guard';

import { RpaasConfig } from './rpaas.config';

/**
 * List of states the user can visit before onboarding setup has been completed.
 */
const rpaasAllowStates = [
  'rpaasOnboarding',
  'rpaas-protection.add-protection'
];

/**
 * A state guard to ensure that the RPaaS initial onboarding is completed by
 * the user.
 */
@Injectable({ providedIn: 'root' })
export class RpaasOnboardingGuard implements StateGuard {
  /**
   * Run this at app priority
   */
  guardPriority = GuardPriority.App;

  /**
   * Match on all rpaas states except the onboarding ones
   */
  matchCriteria: HookMatchCriteria = {
    to: state => this.rpaasStates.has(state.name) &&
      !rpaasAllowStates.some(allowState => state.name.startsWith(allowState))
  };

  /**
   * This is a list of all rpaas states
   */
  private rpaasStates = new Set<string>(Object.keys(RpaasConfig.getStateAccessMap()));

  /**
   * Cache the last onboarding api value
   */
  private onboardResp: RpaasOnboardInfo;

  constructor(
    private irisContextService: IrisContextService,
    private rpaasService: RpaasServiceApi,
    private logger: NGXLogger
  ) {}

  /**
   * Gets the onboarding info to determine if onboarding has been completed
   *
   * @returns The onboarding info
   */
  getOnboardResp(): Observable<RpaasOnboardInfo> {
    if (this.onboardResp) {
      return of(this.onboardResp);
    }
    return this.rpaasService.GetRpaasOnboard().pipe(tap(resp => (this.onboardResp = resp)));
  }

  /**
   * Reset the guard so that the next time a state change occurs we will fetch the onboarding value again
   */
  reset() {
    this.onboardResp = null;
  }

  /**
   * Run the rpaas onboarding state guard
   *
   * @param transition ui router transition
   * @returns allow if onboarding is configured, otherwise redirect to onboarding state
   */
  onStart(transition: Transition): GuardResult {
    const rpaasScope = isRpaasScope(this.irisContextService.irisContext);

    this.logger.debug(`[RPaaS Guard]: Is RPaaS scope? ${rpaasScope}`);

    if (!rpaasScope || !flagEnabled(this.irisContextService.irisContext, 'rpaasOnboardingEnabled')) {
      return true;
    }

    return this.getOnboardResp().pipe(
      map(resp => {
        const onboardingDone = resp?.isComplete ?? false;
        this.logger.debug('[RPaaS Guard]: User details: ', resp);

        if (onboardingDone) {
          this.logger.info('[RPaaS Guard]: Onboarding already done');
          return true;
        }

        this.logger.info('[RPaaS Guard]: Launching onboarding workflow');
        return transition.router.stateService.target('rpaasOnboarding.landing');
      })
    );
  }
}
