<div class="object-row">
  <cog-icon class="display-icon" [shape]="icon" [matTooltip]="getIconTooltip(icon) | translate"></cog-icon>
  <div class="mat-body user-data-{{wideLayout ? 'xxl' : 'lg'}}">{{restorePointSelection.objectInfo.name}}</div>
</div>
<div *ngIf="!hideSnapshot" class="snapshot-row">
  <div class="mat-body snapshot-line">
    <cog-icon *ngIf="!wideLayout" class="display-icon" shape="history"></cog-icon>
    <div *ngIf="restorePointSelection.timestampUsecs !== -1">{{restorePointSelection.timestampUsecs | cohDate}}</div>
    <div *ngIf="restorePointSelection.timestampUsecs === -1">{{'recoverToLatest' | translate}}</div>
  </div>
  <div class="target-type snapshot-line mat-caption">
    <cog-icon class="display-icon" [shape]="typeIcon
      | backupTargetIcon: restorePointSelection.archiveTargetInfo" size="sm">
    </cog-icon>
    {{'fromTarget' |
      translate: {target:
        fromTarget | snapshotLocation: ownershipContext : targetType} }}
  </div>
  <ng-content></ng-content>
  <button mat-icon-button
    type="button"
    cogDataId="edit-restore-point-{{restorePointSelection.objectInfo.name}}"
    *ngIf="showEdit"
    (click)=editRestorePoint.emit()
    [attr.aria-label]="'edit' | translate">
    <cog-icon shape="edit!outline"></cog-icon>
  </button>
</div>
<ng-container *ngIf="isCleanRoomRecoveryPhase1Enabled && !hideSnapshot">
  <cog-spinner size="sm" [inline]="true" *ngIf="dataLoading$ | async; else recoveryStatus"></cog-spinner>
</ng-container>
<ng-template #recoveryStatus>
  <div class="snapshot-metadata" *ngIf="(tags$ | async) as tags">
    <coh-colored-tags [tags]="tags" cogDataId="taglist-{{restorePointSelection.objectInfo.name}}"></coh-colored-tags>
    <cog-banner class="snapshot-warning" status="warn" *ngIf="(canUseForRecovery$ | async) === false"
      cogDataId="recovery-warning-{{restorePointSelection.objectInfo.name}}">
      {{ (isProtectionGroup ? 'recoveryCannotUseProtectionRun' : 'recoveryCannotUseSnapshot') | translate }}
    </cog-banner>
  </div>
</ng-template>
