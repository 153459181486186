import { Pipe, PipeTransform } from '@angular/core';

/**
 * orgIdSplit pipe can be leveraged to split string based on ':' delimeter
 *
 * Example Usage
 * If user has tenantId as '000001def:testorg/'
 * <div>{{user.tenantId | orgIdSplit}}<div> // testorg/
 */
@Pipe({
  name: 'orgIdSplit'
})
export class OrgIdSplitPipe implements PipeTransform {

  transform(value: string): string {
    return value ? (value.split(':')[1] || value) : '';
  }

}
