import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

/**
 * This component allows user to input certificate by upload or type.
 */
@Component({
  selector: 'coh-certificate-input',
  styleUrls: ['./certificate-input.component.scss'],
  templateUrl: './certificate-input.component.html'
})
export class CertificateInputComponent implements OnInit {
  /**
   * File extensions to be allowed for file selection.
   */
  acceptableFileExt = ['application/pkix-cert', 'application/x-x509-ca-cert', '.crt', '.pem', '.cert'];

  /**
   * Specifies label for control.
   */
  @Input() label = this.translate.instant('sso.x509Certificate');

  /**
   * Form control for certificate.
   */
  @Input() control: UntypedFormControl;

  /**
   * Form control for input options.
   */
  option = new UntypedFormControl('upload');

  /**
   * File name of the selected certificate file.
   */
  fileName = '';

  /**
   * File read error if true.
   */
  readError = false;

  constructor(
    private translate: TranslateService,
  ) {}

  /**
   * Set to edit mode if certificate is pre-loaded
   */
  ngOnInit() {
    if (this.control.value) {
      this.option.setValue('edit');
    }
  }

  /**
   * Read the certificate file for the identity provider.
   *
   * @param   event   The file change event.
   */
  readFile(event: Event): void {
    const file: File = (event?.target as HTMLInputElement)?.files?.[0];

    /**
     * Initialize the file reader and read the contents of the file.
     */
    const reader = new FileReader();

    this.readError = false;
    this.control.markAsTouched();
    reader.onloadend = readerEvent => {
      const {result} = readerEvent.target as any;

      this.fileName = file.name;
      this.control.setValue(result);
      this.control.updateValueAndValidity();
    };

    // Handle the error in the callback.. Since Windows system MIME types are not set correctly.
    reader.onerror = () => {
      this.readError = true;
    };

    reader.readAsText(file);
  }

  /**
   * Clears the current selected certificates.
   */
  clearCertificate() {
    this.fileName = '';
    this.control.setValue('');
    this.control.updateValueAndValidity();
  }
}
