<cog-filters>
  <cog-search-property-filter cogDataId="accounts-table-filter-search"
    *cogFilterDef="let filter;
      key: 'name';
      label: 'name' | translate;
      quickFilter: true;
      filterType: 'searchProperty'"
    [filter]="filter"
    [placeholder]="'name' | translate">
  </cog-search-property-filter>
</cog-filters>

<cog-table name="company-accounts-table"
  *ngIf="accounts; else loading"
  [source]="accounts"
  [filters]="tableFilters$ | async"
  [selection]="selection">
  <table mat-table
    matSort
    matSortActive="name"
    matSortDirection="asc"
    [trackBy]="trackById">

    <ng-container matColumnDef="name">
      <th mat-header-cell cogDataId="account-name-col" *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let account">
        <div class="flex-truncate-wrapper">
          <div class="flex-truncate-text user-data-xl column-name-text">
            {{ account.name }}
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"></tr>

    <!-- NoData Footer -->
    <ng-container matColumnDef="noData">
      <td [attr.colspan]="columns.length" mat-footer-cell *matFooterCellDef>
        {{ 'noAccountsFound' | translate }}
      </td>
    </ng-container>

    <tr class="nodata-row" mat-footer-row
      [class.hidden]="accounts.length"
      *matFooterRowDef="['noData']">
    </tr>
  </table>
</cog-table>

<ng-template #loading>
  <cog-spinner class="margin-top-lg"></cog-spinner>
</ng-template>
