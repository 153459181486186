// Component: cBytes

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.bytes', ['ui.select'])
    .controller('CBytesCtrl', cBytesControllerFn)
    .component('cBytes', {
      bindings: {
        // @type   {number}   scaled minimum value for validation
        min: '<?',

        // @type   {number}   raw maximum value for validation
        max: '<?',

        //  @type   {Object[]}   list of bytes unit visible and unit selector
        //                       will be hidden if only one unit is visible
        units: '<?',

        //  @type   {string}   used to customize unit label, cBytes will pass
        //                     scale factor as values while evaluating the
        //                     template
        unitLabelKey: '@?',

        // @type   {boolean}  [type=false]   if units are not specified and
        //                                   this key is true, use all bit
        //                                   units for auto scaling
        useBitsAsBytes: '<?',

        // @type   {string} An id value to extend for template ids. */
        id: '@?',

        // @type   {boolean}   if elastic is set and then this key is true,
        //                     otherwise it's false.
        elastic: '<?',

        // @type   {boolean}   if true, then allow decimal numbers in the
        //                     inputs. Default is false.
        allowDecimal: '<?',
      },
      require: {
        ngModel: 'ngModel',
      },
      controller: 'CBytesCtrl',
      templateUrl: 'app/global/c-bytes/c-bytes.html',
    });

  /**
   * @ngdoc component
   * @name C.bytes:cBytes
   * @function
   *
   * @description
   * Reusable component provides a set of inputs for adjusting and scaling byte
   * values
   *
   * @example
     <c-bytes
      name="myBytesValue"
      required
      min="1"
      max="1000000"
      units="['BYTES', 'MB', 'TB']"
      unit-label-key="cBytes.unitPerSec"
      ng-model="viewBox.physicalQuota.hardLimitBytes">
    </c-bytes>
   */
  function cBytesControllerFn(_, $scope, $attrs, FILESIZES, FORMATS) {
    var $ctrl = this;

    var bitScaleFactors = [
      {
        labelKey: 'filesize.bits',
        factor: FILESIZES.bit,
        unit: 'Bits',
      },
      {
        labelKey: 'filesizeAbbrevations.kilobits',
        factor: FILESIZES.kilobit,
        unit: 'Kb',
      },
      {
        labelKey: 'filesizeAbbrevations.megabits',
        factor: FILESIZES.megabit,
        unit: 'Mb',
      },
      {
        labelKey: 'filesizeAbbrevations.gigabits',
        factor: FILESIZES.gigabit,
        unit: 'Gb',
      },
      {
        labelKey: 'filesizeAbbrevations.terabits',
        factor: FILESIZES.terabit,
        unit: 'Tb',
      },
      {
        labelKey: 'filesizeAbbrevations.petabits',
        factor: FILESIZES.petabit,
        unit: 'Pb',
      },
    ];

    var byteScaleFactors = [
      {
        labelKey: 'filesize.bytes',
        factor: FILESIZES.byte,
        unit: 'BYTES',
      },
      {
        labelKey: 'filesizeAbbrevations.kilobytes',
        factor: FILESIZES.kilobyte,
        unit: 'KB',
      },
      {
        labelKey: 'filesizeAbbrevations.megabytes',
        factor: FILESIZES.megabyte,
        unit: 'MB',
      },
      {
        labelKey: 'filesizeAbbrevations.gigabytes',
        factor: FILESIZES.gigabyte,
        unit: 'GB',
      },
      {
        labelKey: 'filesizeAbbrevations.terabytes',
        factor: FILESIZES.terabyte,
        unit: 'TB',
      },
      {
        labelKey: 'filesizeAbbrevations.petabytes',
        factor: FILESIZES.petabyte,
        unit: 'PB',
      },
    ];

    /**
     * Initialize the scale factors based on the units passed to the component
     *
     * @method   _initializeScaleFactors
     */
    function _initializeScaleFactors () {
      // selected byte scale factor
      $ctrl.scale = undefined;

      // binding $attrs for disabled state pass through
      $ctrl.$attrs = $attrs;

      // If units are passed, filter them out from all possible units.
      if ($ctrl.units && $ctrl.units.length) {
        $ctrl.scaleFactors = bitScaleFactors.concat(byteScaleFactors).filter(
          function eachScaleFactor(scale) {
            return $ctrl.units.includes(scale.unit);
          });
      } else {
        // If no units are passed, default to bytes if 'use-bits-as-bytes'
        // attribute is not used.
        $ctrl.scaleFactors =
          ($ctrl.useBitsAsBytes ? bitScaleFactors : byteScaleFactors);
      }

      // Default to GB/Gb or smallest.
      $ctrl.scale =
        _.find($ctrl.scaleFactors, ['factor', FILESIZES.gigabyte]) ||
          $ctrl.scaleFactors[0];
    }

    /**
     * Sync internal & external model values.
     *
     * @method   syncModelValue
     * @param    {Object}   newScaledValue   The new model value to set
     */
    $ctrl.syncModelValue = function syncModelValue(newScaledValue) {
      if (arguments.length) {
        // Let external model with new model value or set undefined to keep
        // required validation working as expected. Because users can enter 18.1
        // GB, this results in a decimal number of bytes, which is invalid. The
        // base bytes must be integer. We floor instead of round, because we do
        // not want to increase the user's specified value, only reduce to the
        // nearest valid value.
        $ctrl.ngModel.$setViewValue(newScaledValue >= 1 ?
          (Math.floor(newScaledValue * $ctrl.scale.factor)) : newScaledValue);

        return;
      }

      if (!$ctrl.ngModel.$isEmpty($ctrl.ngModel.$modelValue)) {
        // update internal model with external model value
        calculateScaledValue();
      }
    };

    /**
     * Component initialization function
     */
    $ctrl.$onInit = function onInit() {
      if (!_.isNil($ctrl.min) && !$ctrl.allowDecimal &&
        !_.isInteger($ctrl.min)) {
        throw new TypeError('"min" must be an integer');
      }

      if (!_.isNil($ctrl.max) && !$ctrl.allowDecimal &&
        !_.isInteger($ctrl.max)) {
        throw new TypeError('"max" must be an integer');
      }

      $ctrl.id = $ctrl.id || Date.now();
      setupValidators();

      // on model change from outside update the component
      $ctrl.ngModel.$render = $ctrl.syncModelValue;

      _initializeScaleFactors();
    };

    /**
     * Binds the change listener
     *
     * @method   $onChanges
     * @param    {object}   changesObj   The changes object
     */
    $ctrl.$onChanges = function $onChanges(changesObj) {
      var unitsChanged = haveUnitsChanged(changesObj.units);

      if (unitsChanged) {
        calculateScaledValue();
      }

      // Trigger validator function when $ctrl.max value changes.
      // If the max value is not set yet when user select bytes, then the
      // max validator will not work.
      // So adding this validator on onChange will track the max value and
      // resolve the problem above.
      setupMaxValidation();
    };

    /**
     * If the provided value has changed, then revalidate the ngModel. We need
     * this because the internal validation is based on $ctrl.min and $ctrl.max
     * which can change outside without this component knowing about it.
     *
     * @method    revalidate
     * @param     {Number}    newValue    new value
     * @param     {Number}    oldValue    old value
     */
    function revalidate(newValue, oldValue) {
      if (newValue !== oldValue) {
        $ctrl.ngModel.$validate();
      }
    }

    // Watch attributes for changes and revalidate if necessary.
    $scope.$watch('$ctrl.min', revalidate);
    $scope.$watch('$ctrl.max', revalidate);

    /**
     * Determines whether units got changed or not
     *
     * @method   haveUnitsChanged
     * @param    {Object}    unitsChanges   units changes object
     * @return   {boolean}   return true if units not changed else false
     */
    function haveUnitsChanged(unitsChanges) {
      var commonUnits;

      // early exit if no changes
      if (!unitsChanges) {
        return false;
      }

      // units changed when initialized or new and old units list length differ
      if (unitsChanges.isFirstChange() || unitsChanges.currentValue.length !==
        unitsChanges.previousValue.length) {
        return true;
      }

      // find common values b/w new and old values
      commonUnits = _.intersection(
        unitsChanges.currentValue,
        unitsChanges.previousValue
      );

      // units are not changed when common units is equal to old & new units
      return !(
        commonUnits.length === unitsChanges.previousValue.length &&
        commonUnits.length === unitsChanges.currentValue.length
      );
    }

    /**
     * Calculates the scaled value and rounds to nearest whole number because of
     * scenarios where we want a fractional amount of another value. For
     * example, we default quota alerts to be 90% of the quota.
     *
     * @method     calculateScaledValue
     */
    function calculateScaledValue() {
      if (!$ctrl.scaleFactors) {
        return;
      }

      var bytes = $ctrl.ngModel.$modelValue;

      // set smallest possible scale factor as default scale
      $ctrl.scale = $ctrl.scaleFactors[0];

      // Find the highest matching scale with a scaledValue greater than one (1)
      // and set it.
      $ctrl.scaleFactors.some(function findBestScale(scale) {
        var scaledValue = bytes / scale.factor;

        if (scaledValue >= 1) {
          $ctrl.scale = scale;
        } else {
          // Round scaledValue to a whole number and then convert back to bytes.
          // We round instead of floor in this case because we want to display
          // the most natural user-facing value.
          return bytes =
            (bytes / $ctrl.scale.factor).toFixed(2) * $ctrl.scale.factor;
        }
      });

      // Adjust for the set scale
      $ctrl.scaledValue = bytes / $ctrl.scale.factor;
    }

    /**
     * Sets up the validators on the control.
     *
     * @method   setupValidators
     */
    function setupValidators() {
      if ($ctrl.allowDecimal) {
        $ctrl.ngModel.$validators.positiveNumber =
          function positiveNumberCheck(modelVal) {
            // Consider an empty model to be valid.
            if ($ctrl.ngModel.$isEmpty(modelVal)) {
              return true;
            }

            // Validate the user-entered value (e.g. 5 or 1.1) rather than the
            // ngModel which is a derived value.
            return FORMATS.positiveNumbers.test($ctrl.scaledValue);
          };

          $ctrl.ngModel.$validators.atMostTwoDecimal =
            function atMostTwoDecimalCheck(modelVal) {
              if ($ctrl.ngModel.$isEmpty(modelVal)) {
                return true;
              }
              return FORMATS.atMostTwoDecimal.test($ctrl.scaledValue);
            }
      } else {
        $ctrl.ngModel.$validators.positiveInteger =
          function positiveIntegerCheck(modelVal) {
            // Consider an empty model to be valid.
            if ($ctrl.ngModel.$isEmpty(modelVal)) {
              return true;
            }

            // Validate the user-entered value (e.g. 5 or 1.1) rather than the
            // ngModel which is a derived value.
            return FORMATS.positiveIntegers.test($ctrl.scaledValue);
          };
      }

      // Add validator function for min value if the binding exists.
      if (angular.isDefined($ctrl.min)) {
        $ctrl.ngModel.$validators.min = function minCheck(modelVal) {
          // consider empty model to be valid
          if ($ctrl.ngModel.$isEmpty(modelVal)) {
            return true;
          }

          return modelVal >= $ctrl.min;
        };
      }
    }

    /**
     * Set up the max value validation.
     *
     * @method   setupMaxValidation
     * @returns  {boolean}   True if max model value is smaller than $ctrl.max.
     */
    function setupMaxValidation() {
      if (angular.isDefined($ctrl.max) && $ctrl.max > -1) {
        $ctrl.ngModel.$validators.max = function maxCheck(modelVal) {
          // consider empty model to be valid
          if ($ctrl.ngModel.$isEmpty(modelVal)) {
            return true;
          }

          return modelVal <= $ctrl.max;
        };
      }
    }

  }

})(angular);
