/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Role } from '../models/role';
@Injectable({
  providedIn: 'root',
})
class HeliosRolesService extends __BaseService {
  static readonly GetMcmRolesOpPath = '/public/mcm/roles';
  static readonly CreateMcmRoleOpPath = '/public/mcm/roles';
  static readonly DeleteMcmRoleOpPath = '/public/mcm/roles';
  static readonly GetMcmRoleByNameOpPath = '/public/mcm/roles/{name}';
  static readonly UpdateMcmRoleOpPath = '/public/mcm/roles/{name}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns the list of all roles configured in Helios.
   * @param regionId This field represents the region id.
   * @return Success
   */
  GetMcmRolesOpResponse(regionId?: string): __Observable<__StrictHttpResponse<Array<Role>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/mcm/roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Role>>;
      })
    );
  }
  /**
   * Returns the list of all roles configured in Helios.
   * @param regionId This field represents the region id.
   * @return Success
   */
  GetMcmRolesOp(regionId?: string): __Observable<Array<Role>> {
    return this.GetMcmRolesOpResponse(regionId).pipe(
      __map(_r => _r.body as Array<Role>)
    );
  }

  /**
   * Creates the new role on Helios and returns the newly created role
   * object.
   * @param regionId This field represents the region id.
   * @param body Request to create a role in mcm.
   * @return Success
   */
  CreateMcmRoleOpResponse(regionId?: string,
    body?: Role): __Observable<__StrictHttpResponse<Role>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/mcm/roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Role>;
      })
    );
  }
  /**
   * Creates the new role on Helios and returns the newly created role
   * object.
   * @param regionId This field represents the region id.
   * @param body Request to create a role in mcm.
   * @return Success
   */
  CreateMcmRoleOp(regionId?: string,
    body?: Role): __Observable<Role> {
    return this.CreateMcmRoleOpResponse(regionId, body).pipe(
      __map(_r => _r.body as Role)
    );
  }

  /**
   * Deletes the Helios role.
   * @param regionId This field represents the region id.
   */
  DeleteMcmRoleOpResponse(regionId?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/mcm/roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes the Helios role.
   * @param regionId This field represents the region id.
   */
  DeleteMcmRoleOp(regionId?: string): __Observable<null> {
    return this.DeleteMcmRoleOpResponse(regionId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Returns the role information based on given name.
   * @param name Name parameter to get Mcm roles.
   * @param regionId This field represents the region id.
   * @return Success
   */
  GetMcmRoleByNameOpResponse(name: string,
    regionId?: string): __Observable<__StrictHttpResponse<Role>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/mcm/roles/${encodeURIComponent(name)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Role>;
      })
    );
  }
  /**
   * Returns the role information based on given name.
   * @param name Name parameter to get Mcm roles.
   * @param regionId This field represents the region id.
   * @return Success
   */
  GetMcmRoleByNameOp(name: string,
    regionId?: string): __Observable<Role> {
    return this.GetMcmRoleByNameOpResponse(name, regionId).pipe(
      __map(_r => _r.body as Role)
    );
  }

  /**
   * Updates the Helios roles and returns the updated role object.
   * @param params The `HeliosRolesService.UpdateMcmRoleOpParams` containing the following parameters:
   *
   * - `name`: Name parameter to get Mcm roles.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update a role in mcm.
   *
   * @return Success
   */
  UpdateMcmRoleOpResponse(params: HeliosRolesService.UpdateMcmRoleOpParams): __Observable<__StrictHttpResponse<Role>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/mcm/roles/${encodeURIComponent(params.name)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Role>;
      })
    );
  }
  /**
   * Updates the Helios roles and returns the updated role object.
   * @param params The `HeliosRolesService.UpdateMcmRoleOpParams` containing the following parameters:
   *
   * - `name`: Name parameter to get Mcm roles.
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to update a role in mcm.
   *
   * @return Success
   */
  UpdateMcmRoleOp(params: HeliosRolesService.UpdateMcmRoleOpParams): __Observable<Role> {
    return this.UpdateMcmRoleOpResponse(params).pipe(
      __map(_r => _r.body as Role)
    );
  }
}

module HeliosRolesService {

  /**
   * Parameters for UpdateMcmRoleOp
   */
  export interface UpdateMcmRoleOpParams {

    /**
     * Name parameter to get Mcm roles.
     */
    name: string;

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to update a role in mcm.
     */
    body?: Role;
  }
}

export { HeliosRolesService }
