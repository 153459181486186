import { Component, Host, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CreateView, ViewServiceApi } from '@cohesity/api/v2';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { StateService } from '@uirouter/core';
import { finalize, take } from 'rxjs/operators';
import { CustomizationService } from 'src/app/core/services';
import { CreateViewData, snapdiffS3ViewQos, ViewBuilderStateParams } from '../../models';
import { CreateViewFormService, ViewsService } from '../../services';

@Component({
  selector: 'coh-create-view-dialog',
  templateUrl: './create-view-dialog.component.html',
  styleUrls: ['./create-view-dialog.component.scss'],
  providers: [CreateViewFormService],
})
export class CreateViewDialogComponent extends AutoDestroyable implements OnInit {

  /**
   * Flag to check if the form is being submitted.
   */
  isSubmitting = false;

  /**
   * Initialize the form group.
   */
  formGroup = this.createViewFormService.getCreateViewFormGroup(this.createViewData, 'dialog');

  constructor(
    private ajaxHandler: AjaxHandlerService,
    @Host() private createViewFormService: CreateViewFormService,
    private customizationService: CustomizationService,
    private dialogRef: MatDialogRef<CreateViewDialogComponent>,
    private stateService: StateService,
    private viewServiceApi: ViewServiceApi,
    private viewsService: ViewsService,
    @Inject(MAT_DIALOG_DATA) public createViewData: CreateViewData,
  ) {
    super();
  }

  ngOnInit() {
    // If simple workflow for view create is disabled, then always immediately
    // open the full view form as this dialog is opening.
    this.customizationService.disabledWorkflows$
      .pipe(this.untilDestroy(), take(1))
      .subscribe(value => {
        if (value.includes('simpleView')) {
          this.modifyViewSettings();
        }
      });
  }

  /**
   * Creates the payload using the form values and the dialog params passed.
   *
   * @returns The CreateView Payload
   */
  getCreateViewPayload(): CreateView {
    return this.viewsService.transformCreateViewDialogPayload(
      this.formGroup.getRawValue(),
      this.createViewData,
      this.createViewFormService.isCreateMode,
    );
  }

  /**
   * On Submit button click.
   *
   * TODO: CustomizationService.
   */
  onSubmit() {
    const payload = this.getCreateViewPayload();

    // For snapdiff s3 view, set qos from default snapdiff s3 view settings.
    if (this.createViewData?.isSnapdiffS3View) {
      payload.qos =  snapdiffS3ViewQos;
    }

    this.isSubmitting = true;

    this.viewServiceApi.CreateView({ body: payload })
      .pipe(
        this.untilDestroy(),
        finalize(() => this.isSubmitting = false)
      ).subscribe(view => this.dialogRef.close(view), this.ajaxHandler.handler);
  }

  /**
   * Redirects to the full page mode.
   */
  modifyViewSettings() {
    const stateParams: ViewBuilderStateParams = {
      actionType: 'create',
      viewObj: this.getCreateViewPayload(),
      togglePageView: true,
      ...(this.createViewData?.id || this.createViewData?.isSnapdiffS3View ?
        { templateInfo: this.createViewData } : {}),
      ...(this.createViewData?.restrictViewBoxId ? { restrictViewBoxId: this.createViewData.restrictViewBoxId } : {}),
    };

    this.stateService.go('ng-views.modify', stateParams);
  }
}
