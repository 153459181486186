<mat-radio-group
  name="{{ 'timeframe_' + uuid }}"
  *ngIf="useTimeframe"
  [value]="dateRange.timeframe"
  (change)="onTimeframeChange($event.value)">
  <mat-radio-button
    [value]="option"
    cogDataId="timeframe-{{timeRangeParameterMap[option]}}"
    [inheritDataId]="true"
    *ngFor="let option of timeframeOptions">
    {{ getLabel(option) }}
  </mat-radio-button>
</mat-radio-group>
<div class="calendars-wrapper" [class.hidden]="!showCalendarPickers">
  <cog-banner *ngIf="banner" [status]="banner.status" class="range-banner margin-bottom">
    {{ banner.message }}
  </cog-banner>
  <div class="date-pickers">
    <mat-calendar
      cogCalendar
      [minDate]="minDate"
      [maxDate]="maxDate"
      [startAt]="startAt"
      [skipUpdatingMatSelected]="true"
      (selectedChange)="selectDay($event)"
      [dateClass]="getDateCellClass"
      [headerComponent]="headerComponent"
      cogDataId="left-cal"
      [inheritDataId]="true">
    </mat-calendar>
    <mat-calendar
      cogCalendar
      [minDate]="minDate"
      [maxDate]="maxDate"
      [startAt]="nextToStartAt"
      [skipUpdatingMatSelected]="true"
      (selectedChange)="selectDay($event)"
      [dateClass]="getDateCellClass"
      [headerComponent]="headerComponent"
      cogDataId="right-cal"
      [inheritDataId]="true">
    </mat-calendar>
  </div>
  <div class="time-pickers" *ngIf="enablePreciseDateRangeSelection">
    <mat-form-field class="date-field">
      <mat-label>{{ intlSvc.dateRange.startDate }}</mat-label>
      <input
        matInput
        readonly
        cogDataId="start-date"
        [inheritDataId]="true"
        [value]="getDisplayDate(dateRange.start)">
    </mat-form-field>
    <mat-form-field class="time-field start-time-field">
      <mat-label>{{ intlSvc.dateRange.startTime }}</mat-label>
      <cog-time-picker
        cogDataId="start-time"
        [inheritDataId]="true"
        [value]="dateRange.start"
        (changes)="selectTime({ start: $event })">
      </cog-time-picker>
    </mat-form-field>

    <label>&ndash;</label>

    <mat-form-field class="date-field">
      <mat-label>{{ intlSvc.dateRange.endDate }}</mat-label>
      <input
        matInput
        readonly
        cogDataId="end-date"
        [inheritDataId]="true"
        [value]="getDisplayDate(dateRange.end)">
    </mat-form-field>
    <mat-form-field class="time-field">
      <mat-label>{{ intlSvc.dateRange.endTime }}</mat-label>
      <cog-time-picker
        cogDataId="end-time"
        [inheritDataId]="true"
        [value]="dateRange.end"
        (changes)="selectTime({ end: $event })">
      </cog-time-picker>
    </mat-form-field>
  </div>
</div>
