// Directive: actionsGroup

;(function(angular, undefined) {
  'use strict';

  /**
   * @ngdoc directive
   * @name C.directive:actionsGroup
   * @description
   *   This directive displays actions links based on a list of config objects.
   *   Consolidates display logic of one or many actions.
   *
   *   Accepts click function, or a set of state config to change states. See
   *   the `exampleAction` for details.
   *
   *   When used within a cPageHeader component and there's one action, display
   *   a button. When 2+ actions, display icons.
   *
   *   When used outside the cPageHeader component, render all actions as icons.
   *
   * @restrict 'A'
   * @scope
    <example>
      <page-actions actions-group="::$ctrl.pageActions"></page-actions>
      <div actions-group="$ctrl.pageActions"></div>
    </example>
   */
  var directiveName = 'actionsGroup';

  /**
   * Example action for documentation
   *
   * @type   {object}
   */
  var exampleAction = {
    // Only required if no clickHandler is defined.
    stateName: 'optional.state.name',
    stateConfig: { optional: true },
    stateParams: { optional: true },

    // Only required of no stateName is defined.
    clickHandler: function optionalClickFn() {},

    // Required properties.
    disabled: false,
    displayKey: 'requiredUiJsonKey',
    icon: 'icn-cloud required',
  };

  /**
   * Error message for invalid action configurations.
   *
   * @type   {string}
   */
  var clickErrorMsg = [
    'Each action in the "',
    directiveName,
    '" directive requires either a `stateName` or `clickHandler` function.\n',
    'Action provided:',
  ].join('');

  // Register this module & directive
  angular.module('C.actionsGroup', [])
    .directive('actionsGroup', cPageActionsDirective);

  /**
   * The directive Fn.
   */
  function cPageActionsDirective($state, $log) {
    return {
      link: linkFn,
      restrict: 'A',
      scope: { actions: '=' + directiveName },
      templateUrl: 'app/global/actions-group/actions-group.html',
    };

    /**
     * The Link Fn.
     */
    function linkFn(scope, elem) {
      // True if a parent of this instance is cPageHeader
      scope.isInPageHeader = !!elem.parents('c-page-header').length;

      /**
       * Trigger the appropriate action when the User clicks in the UI.
       *
       * @method   clickProxy
       * @param    {object}   action   The action config object.
       */
      scope.clickProxy = function clickProxy(action) {
        // If there's a clickHandler Fn, prioritize that and execute...
        if (angular.isFunction(action.clickHandler)) {
          return action.clickHandler(action);
        }

        // Otherwise, if there's a stateName string, go to that state with the
        // optional state params.
        if (angular.isString(action.stateName)) {
          return $state.go(
            action.stateName,
            action.stateConfig,
            action.stateParams
          );
        }

        // If neither clickHandler, nor stateName are configured, show a
        // documentation message (error).
        $log.error(clickErrorMsg, action);
        $log.warn('Expected action format:', exampleAction);
      };
    }
  }

})(angular);
