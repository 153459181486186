/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SnapshotTagsList } from '../models/snapshot-tags-list';
import { SnapshotsList } from '../models/snapshots-list';
@Injectable({
  providedIn: 'root',
})
class TaggingServiceService extends __BaseService {
  static readonly AddSnapshotsTagsPath = '/mcm/tags/snapshots';
  static readonly RemoveSnapshotsTagsPath = '/mcm/tags/snapshots';
  static readonly GetSnapshotsTagsPath = '/mcm/tags/snapshots/status';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Adds specified tags to snapshots.
   *
   * Adds the tag labels specified in the parameters, to the snapshots.
   * @param body SnapshotTagsList specifies list of snapshots and tags to add to each of those. Only the tags to be added are specified. Existing tags will remain added. Response  will contain all tags associated with snapshots after addition.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  AddSnapshotsTagsResponse(body: SnapshotTagsList,
    regionId?: string): __Observable<__StrictHttpResponse<SnapshotTagsList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/tags/snapshots`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SnapshotTagsList>;
      })
    );
  }
  /**
   * Adds specified tags to snapshots.
   *
   * Adds the tag labels specified in the parameters, to the snapshots.
   * @param body SnapshotTagsList specifies list of snapshots and tags to add to each of those. Only the tags to be added are specified. Existing tags will remain added. Response  will contain all tags associated with snapshots after addition.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  AddSnapshotsTags(body: SnapshotTagsList,
    regionId?: string): __Observable<SnapshotTagsList> {
    return this.AddSnapshotsTagsResponse(body, regionId).pipe(
      __map(_r => _r.body as SnapshotTagsList)
    );
  }

  /**
   * Removes specified tags of snapshots.
   *
   * Removes the tag labels specified in the parameters, from the snapshots.
   * @param body SnapshotTagsList specifies list of snapshots and associated tags to remove from each of those. Only the tags to be removed are specified. Other existing tags will remain added. Response will contain tags associated with snapshots after removing specified tags.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  RemoveSnapshotsTagsResponse(body: SnapshotTagsList,
    regionId?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/mcm/tags/snapshots`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Removes specified tags of snapshots.
   *
   * Removes the tag labels specified in the parameters, from the snapshots.
   * @param body SnapshotTagsList specifies list of snapshots and associated tags to remove from each of those. Only the tags to be removed are specified. Other existing tags will remain added. Response will contain tags associated with snapshots after removing specified tags.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  RemoveSnapshotsTags(body: SnapshotTagsList,
    regionId?: string): __Observable<null> {
    return this.RemoveSnapshotsTagsResponse(body, regionId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get the tags of snapshots.
   *
   * Gets tag labels (if tagged) of snapshots, specified by parameters. Else, just returns back snapshots info, without any labels.
   * @param body SnapshotsList represents list of snapshots identified by various parameters like clusterId, protectionGroupId, objectId etc.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetSnapshotsTagsResponse(body: SnapshotsList,
    regionId?: string): __Observable<__StrictHttpResponse<SnapshotTagsList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/tags/snapshots/status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SnapshotTagsList>;
      })
    );
  }
  /**
   * Get the tags of snapshots.
   *
   * Gets tag labels (if tagged) of snapshots, specified by parameters. Else, just returns back snapshots info, without any labels.
   * @param body SnapshotsList represents list of snapshots identified by various parameters like clusterId, protectionGroupId, objectId etc.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetSnapshotsTags(body: SnapshotsList,
    regionId?: string): __Observable<SnapshotTagsList> {
    return this.GetSnapshotsTagsResponse(body, regionId).pipe(
      __map(_r => _r.body as SnapshotTagsList)
    );
  }
}

module TaggingServiceService {
}

export { TaggingServiceService }
