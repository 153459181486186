import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScanImpactStatusComponent } from './scan-impact-status.component';
import { DataIdModule, IconModule } from '@cohesity/helix';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ScanHealthStatusIconModule } from '@cohesity/data-govern/shared';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    ScanImpactStatusComponent
  ],
  imports: [
    CommonModule,
    DataIdModule,
    IconModule,
    MatTooltipModule,
    ScanHealthStatusIconModule,
    TranslateModule,
  ],
  exports: [ScanImpactStatusComponent]
})
export class ScanImpactStatusModule { }
