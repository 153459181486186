import { UntypedFormControl, ValidatorFn } from '@angular/forms';

/**
 * A function to validate whether the form control value contains an empty
 * array.
 *
 * @param control The form control.
 * @return null if the control is valid, error object otherwise.
 */
export const NoEmptyArrayValidator: ValidatorFn = (control: UntypedFormControl) => {
  const isEmpty = Array.isArray(control.value) && control.value.length === 0;
  return isEmpty ? { required: true } : null;
};

/**
 * PatternArrayValidator a validator function for an array of values based on the provided regex pattern.
 *
 * @param pattern The regex pattern to validate each value against.
 * @return Validator function that returns null if all values match the pattern, otherwise an error object.
 */
export const PatternArrayValidator =
  (pattern: RegExp): ValidatorFn =>
    (control: UntypedFormControl) => {
      if (!Array.isArray(control.value)) {
        return { patternError: true };
      }

      const allValid = control.value.every((value: string) => pattern.test(value));
      return allValid ? null : { patternError: true };
    };
