import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const rolesModifyAccess = (ctx: StateAccessContext) => ctx.PRINCIPAL_MODIFY  && ctx.FEATURE_FLAGS.ngRoles;

export const RolesConfig: AppModuleConfig = {
  futureStates: [
  ],
  getStateAccessMap(): StateAccessMap {
    return {
      'edit-role-ng': rolesModifyAccess,
      'new-role-ng': rolesModifyAccess,
    };
  },
};
