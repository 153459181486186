import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';
import { recoveryGroup } from 'src/app/shared/constants';

const sourceModify = (ctx: StateAccessContext) =>
  ctx.PROTECTION_SOURCE_MODIFY &&
  ctx.canAccessSomeEnv(recoveryGroup.kubernetes);
const protectModify = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngProtectionGroupGlobalModify &&
  ctx.FEATURE_FLAGS.ngProtectionGroupKubernetesModify &&
  ctx.PROTECTION_MODIFY;
const restoreModify = (ctx: StateAccessContext) =>
  ctx.RESTORE_MODIFY && ctx.canAccessSomeEnv(recoveryGroup.kubernetes);

export const KubernetesConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'kubernetes.**',
      url: '/protection/source/kubernetes',
      loadChildren: () => import('./kubernetes.module').then(m => m.KubernetesModule),
    },
    {
      name: 'protection-builder-kubernetes.**',
      url: '/protection/protect/kubernetes',
      loadChildren: () => import('./kubernetes.module').then(m => m.KubernetesModule),
    },
    {
      name: 'kubernetes-recovery.**',
      url: '^/protection/recovery/kubernetes/recovery',
      loadChildren: () => import('./kubernetes.module').then(m => m.KubernetesModule),
    }
  ],
  getStateAccessMap(): StateAccessMap {
    return {
      'kubernetes.new': sourceModify,
      'kubernetes.edit': sourceModify,
      'protection-builder-kubernetes.create': protectModify,
      'protection-builder-kubernetes.edit': protectModify,
      'kubernetes-recovery.search': restoreModify,
      'kubernetes-recovery.search.show-cart': restoreModify,
      'kubernetes-recovery.restore': restoreModify,
    };
  }
};
