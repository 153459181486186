import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DataTransferInfo } from '@cohesity/api/v2';
import { Controls, NgxSubFormRemapComponent, subformComponentProviders } from 'ngx-sub-form';
import { RecoverAzureService } from 'src/app/modules/restore/hypervisor-shared/azure/shared/recover-azure.service';

@Component({
  selector: 'coh-settings-list-sas-url-recover',
  templateUrl: './settings-list-sas-url-recover.component.html',
  styleUrls: ['./settings-list-sas-url-recover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: subformComponentProviders(SettingsListSasUrlRecoverComponent)
})
export class SettingsListSasUrlRecoverComponent extends NgxSubFormRemapComponent<any, any>
  implements OnInit {

  /**
   * True if recovery is to a new location.
   */
  @Input() isNewLocationRecovery = false;

  /**
   * If this is set, the form should initialize its values based on its properties.
   */
  @Input() initialDataTransferInfo: DataTransferInfo;

  constructor(
    private recoverAzureService: RecoverAzureService)  {
    super();
  }

  /**
   * End point types list
   */
  endPointTypes = [
    {key: 'publicEndpoint', value: 'public'},
    {key: 'privateEndpoint', value: 'private'}
  ];

  ngOnInit() {
    this.formGroup.get('sasUrlToggle').valueChanges
      .subscribe(enable => {
        if (enable) {
          (this.formGroup.get('privateEndpointFields') as UntypedFormArray).clear();
        } else if (!enable && this.privateEndpointFields.length === 0) {
          this.addPrivateEndpointFields();
        }
      });

    if (this.initialDataTransferInfo) {
      this.formGroup.get('endPointType').setValue(
        this.initialDataTransferInfo?.isPrivateNetwork ? 'private' : 'public');

      if (this.initialDataTransferInfo?.isPrivateNetwork) {
        const privateEndPointFields = new UntypedFormGroup({
          region: new UntypedFormControl(
            this.initialDataTransferInfo?.privateNetworkInfoList?.[0]?.region?.id, [Validators.required]),
          virtualNetwork: new UntypedFormControl(
            this.initialDataTransferInfo?.privateNetworkInfoList?.[0]?.vpn?.id, [Validators.required]),
          subnet: new UntypedFormControl(
            this.initialDataTransferInfo?.privateNetworkInfoList?.[0]?.subnet?.id, [Validators.required]),
        });
        (this.formGroup.get('privateEndpointFields') as UntypedFormArray).push(privateEndPointFields);

        this.formGroup.get('sasUrlToggle').setValue(this.initialDataTransferInfo?.useProtectionJobInfo);
      }
    }

    // In case of new location recovery, we can't use info from backup.
    this.formGroup.get('endPointType').valueChanges
      .subscribe(endPointType => {
        if (endPointType === 'private' && this.isNewLocationRecovery) {
          this.formGroup.get('sasUrlToggle').setValue(false);

        } else if (endPointType === 'public') {
          (this.formGroup.get('privateEndpointFields') as UntypedFormArray).clear();
        }

        if (endPointType === 'private' && this.privateEndpointFields.length === 0) {
          this.addPrivateEndpointFields();
        }
      });
  }

  /**
   * Function to return all the form controls.
   *
   * @return The form controls object.
   */
  protected getFormControls(): Controls<any> {
    // sasUrlToggle represents whether to use same SAS URL details as protection.
    return {
      endPointType: new UntypedFormControl('private', [Validators.required]),
      sasUrlToggle: new UntypedFormControl(true),
      privateEndpointFields: new UntypedFormArray([]),
    };
  }

  /**
   * Converts from the api model to the internal form group data representation.
   *
   * @param   params Api object.
   * @returns The form value.
   */
  protected transformToFormGroup(params: any | null): any {
    const {endPointType = 'public', sasUrlToggle = true, privateEndpointFields = []} = params || {};
    return {
      endPointType,
      sasUrlToggle,
      privateEndpointFields
    };
  }

  /**
   * Transform the form group object to api request param. Called by ngx-subform library.
   *
   * @param formValue Form object of this component.
   * @returns Object
   */
  protected transformFromFormGroup(): any | null {
    const {endPointType = 'public', sasUrlToggle = true, privateEndpointFields = []} = this.formGroup.value || {};
    return {
      endPointType: endPointType || null,
      sasUrlToggle: sasUrlToggle === undefined ? null : sasUrlToggle,
      privateEndpointFields: privateEndpointFields || []
    };
  }

  /**
   * Function to add a new item to the privateEndpointFields list.
   */
  addPrivateEndpointFields() {
    const privateEndPointFields = new UntypedFormGroup({
      region: new UntypedFormControl('', [Validators.required]),
      virtualNetwork: new UntypedFormControl('', [Validators.required]),
      subnet: new UntypedFormControl('', [Validators.required]),
    });
    (this.formGroup.get('privateEndpointFields') as UntypedFormArray).push(privateEndPointFields);
  }

  /**
   * Returns FormControls[] from privateEndpointFields FormArray.
   */
  get privateEndpointFields(): AbstractControl[] {
    return (this.formGroup.get('privateEndpointFields') as UntypedFormArray).controls;
  }

  /**
   * Function to remove a form control item from the privateEndpointFields list.
   *
   * @param   index   Index of the item to be removed.
   */
  removePrivateEndPointField(index: number) {
    (this.formGroup.get('privateEndpointFields') as UntypedFormArray).removeAt(index);
  }

  /**
   * Returns whether the selected endpoint is of type private
   */
  get isPrivateEndpoint(): boolean {
    return this.formGroup.get('endPointType').value === 'private';
  }
}
