import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

/**
 * Data structure for the dialog component.
 */
interface DialogContext {
  /**
   * Title of dialog.
   */
  title?: string;

  /**
   * Message or body of dialog.
   */
  message: string;

  /**
   * Indicates whether message contains HTML.
   */
  isHtml?: boolean;

  /**
   * Indicate if the dialog must contains a cog banner.
   */
  hasCogBanner?: boolean;

  /**
   * Message of cog banner.
   */
  cogBannerMessage?: string;

  /**
   * Hide cancel button if true.
   */
  noCancel?: boolean;

  /**
   * Cancel button label.
   */
  cancelLabel?: string;

  /**
   * Confirmation button label.
   */
  confirmLabel?: string;

  /**
   * Confirmation button type.
   */
  confirmButtonType?: string;
}

/**
 * A generic confirmation dialog that allows user to customize message, title, and button labels.
 */
@Component({
  selector: 'coh-confirmation-dialog',
  styleUrls: ['./confirmation-dialog.component.scss'],
  templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent {
  /**
   * Title of dialog.
   */
  title: string;

  /**
   * Message/Body of dialog.  Default to a generic 'Are you sure?' message
   */
  message = 'areYouSure';

  /**
   * Indicates whether message contains HTML..
   */
  isHtml: boolean;

  /**
   * Indicate if the dialog must contains a cog banner.
   */
  hasCogBanner: boolean;

  /**
   * Message of cog banner.
   */
  cogBannerMessage = '';

  /**
   * Hide cancel button if true.
   */
  noCancel: boolean;

  /**
   * Cancel button label. Default to 'Cancel'.
   */
  cancelLabel = 'cancel';

  /**
   * Confirmation button label. Default to 'OK'.
   */
  confirmLabel = 'ok';

  /**
   * Confirmation button type. Default to 'primary'.
   */
  confirmButtonType = 'primary';

  /**
   * Constructor.
   */
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public context: DialogContext
  ) {
    if (context) {
      if (context.message) {
        this.message = context.message;
      }
      if (context.hasCogBanner) {
        this.hasCogBanner = context.hasCogBanner;
      }
      if (context.cogBannerMessage) {
        this.cogBannerMessage = context.cogBannerMessage;
      }
      if (context.confirmLabel) {
        this.confirmLabel = context.confirmLabel;
      }
      if (context.cancelLabel) {
        this.cancelLabel = context.cancelLabel;
      }
      if (context.confirmButtonType) {
        this.confirmButtonType = context.confirmButtonType;
      }
      this.title = context.title;
      this.isHtml = context.isHtml;
      this.noCancel = context.noCancel;
    }
  }
}
