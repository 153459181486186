// Component: c-source-group

;(function(angular, undefined) {
  'use strict';

  var modName = 'C.pubSourceTree';
  var componentName = 'cSourceGroup';
  var options = {
    bindings: {
      // @type  {Array}  List of nodes to be grouped based  node on environment
      // type
      tree: '=',

      /**
       * Optional attribute sniffed value, If present then the rendered source
       * tree editing & selection will not be allowed.
       */
      // viewMode: '?',

      /**
       * Optional attribute sniffed value, If present then the rendered source
       * tree editing(removal) is allowed but selection will be disabled.
       */
      // modifyMode: '?',

      /**
       * Optional attribute sniffed value, if attribute is present the filters
       * will not rendered, i.e. source name and source type
       */
      // noFilters: '?',

      /**
       * Optional attribute sniffed value, if attribute is present the
       * type filters will not rendered
       */
      // noTypeFilter: '?',

      /**
       * Optional options
       * @type  {object}  Properties {
       *   canSelectNode: function [undefined] If defined then used to
       *                                       Determines a node selection is
       *                                       allowed or not.
       *
       *   canRemoveNode: function [undefined] If defined then used to
       *                                       Determines if node can be removed
       *                                       or not from the tree.
       *
       *   noTypeFilter: Boolean [false]  Optional property and if present
       *   it will not render the type filter.
       *
       *   noFilters:    Boolean [true]   Optional property and if present and
       *   false then show source environment specific filters eg.
       *   for vCenter show folder, hierarchical & tree view filters.
       *   for physical show windows, linux, aix filters.
       *   for NAS show protected, un-protected VM filters.
       *   etc source environment specific filters will be shown.
       * }
       */
      options: '=?',

      /**
       * @type  {string}  If it exists in a modal, the type is 'modal'; otherwise
       * the type is 'page'.
       */
      type: '<?',
    },
    controller: 'CSourceGroupCtrl',
    templateUrl: 'app/global/c-source-tree/c-source-group.html',
  };

  angular
    .module(modName)
    .controller('CSourceGroupCtrl', cSourceGroupCtrlFn)
    .component(componentName, options);

  /**
   * @ngdoc component
   * @name C.pubSourceTree:cSourceGroup
   * @function
   *
   * @description
   * Displays provided source tree grouped by there root environment type and
   * allow searching and filtering of grouped tree.
   *
   * @example
      <c-source-group
        tree="$ctrl.rootNodes"></c-source-group>
   */
  function cSourceGroupCtrlFn(_,
    $attrs,
    SourcesUtil,
    ENV_GROUPS,
    PubJobServiceFormatter,
    FEATURE_FLAGS,
    PubSourceService) {

    var $ctrl = this;
    var oldTree;

    var allOptions = {
      selectMode: {
        asyncMode: false,
        canSelect: true,
        contextMenu: false,
        detailedView: true,
        noFilters: true,
        noTypeFilter: false,
        noExpandAllToggle: true,
        showFullCloudTree: true,
        autoExpandRootNodes: false,
        ancestorSelectionMode: true,
        disabledBulkAgentUpgradeCheckbox: true,

        // get sources full hierarchy w/o any exclusion e.g. for vCenter with
        // resource pool
        getSourcesParams: {
          includeEntityPermissionInfo: true,
        },
      },
      viewMode: {
        asyncMode: false,
        canSelect: false,
        contextMenu: false,
        detailedView: true,
        noFilters: true,
        noTypeFilter: false,
        noExpandAllToggle: true,
        autoExpandRootNodes: false,
        showFullCloudTree: true,
        disabledBulkAgentUpgradeCheckbox: true,
      },
      modifyMode: {
        asyncMode: false,
        canSelect: false,
        contextMenu: false,
        detailedView: true,
        noFilters: true,
        noTypeFilter: false,
        noExpandAllToggle: true,
        autoExpandRootNodes: false,
        showFullCloudTree: true,
        removeNode: removeSources,
        canRemoveNode: angular.noop,
        disabledBulkAgentUpgradeCheckbox: true,

        // get sources full hierarchy w/o any exclusion e.g. for vCenter with
        // resource pool
        getSourcesParams: {
          includeEntityPermissionInfo: true,
        },
      },
    };

    // declare component methods
    _.assign($ctrl, {
      // component life-cycle methods
      $onInit: $onInit,
      $doCheck: constructSourceGroups,

      setSourceTree: setSourceTree,
      assignObjectsV2: FEATURE_FLAGS.assignObjectsV2,
    });

    /**
     * Initializes the controller.
     *
     * @method     $onInit
     */
    function $onInit() {
      _.assign($ctrl, {
        options: $ctrl.options || {},
        sourceGroups: [],
        sourceTreeOpts: allOptions.selectMode,
        filter: {
          name: undefined,
          sourceType: 'all',
        },
        selectedSource: null,
        selectedSourceGroup: [],
        selectedSourceTree: [],
        modifyMode: $attrs.hasOwnProperty('modifyMode'),
        viewMode: $attrs.hasOwnProperty('viewMode'),
        noFilters: $attrs.hasOwnProperty('noFilters'),
        type: $ctrl.type === 'modal' ? 'modal' : 'page',
      });

      if ($ctrl.viewMode) {
        $ctrl.sourceTreeOpts = allOptions.viewMode;
      } else if ($ctrl.modifyMode) {
        $ctrl.sourceTreeOpts = allOptions.modifyMode;
      } else {
        $ctrl.selectMode = true;
        $ctrl.sourceTreeOpts.toggleNodeSelection = $ctrl.options.toggleNodeSelection;
      }

      if ($ctrl.options.hasOwnProperty('noFilters')) {
        $ctrl.sourceTreeOpts.noFilters = $ctrl.options.noFilters;
        $ctrl.noTypeFilter = $ctrl.options.noFilters;
      }

      if ($ctrl.options.hasOwnProperty('noTypeFilter')) {
        $ctrl.noTypeFilter = $ctrl.options.noTypeFilter;
      }

      if ($ctrl.options.hasOwnProperty('hasSingleSourceGroup')) {
        $ctrl.hasSingleSourceGroup = $ctrl.options.hasSingleSourceGroup;
      }

      constructSourceGroups();

      // render the first group and source by default.
      $ctrl.selectedSourceGroup = $ctrl.sourceGroups[0];

      if ($ctrl.selectedSourceGroup) {
        $ctrl.selectedSource = $ctrl.selectedSourceGroup.nodes[0];
      }

      if ($ctrl.hasSingleSourceGroup) {
        $ctrl.selectedSourceTree = $ctrl.sourceGroups[0];
      } else {
        setSourceTree();
      }
    }

    /**
     * Construct the source group from provided $ctrl.tree
     *
     * @method   constructSourceGroups
     */
    function constructSourceGroups() {
      // do nothing when tree reference not changed
      if ($ctrl.tree === oldTree) {
        return;
      }

      oldTree = $ctrl.tree;
      $ctrl.sourceGroups = SourcesUtil.groupSourcesTree($ctrl.tree || []);

      // added to make view all sources filter
      if ($ctrl.noFilters) {
        $ctrl.sourceGroups.unshift({
          protectionSource: {
            id: 0,
            name: undefined,
            // environment 'all' is UI constrict there is no such environment
            // exist in backend
            environment: 'all',
          },
          nodes: [],
        });
      }

      /**
       * For assignObjectsV2 we are loading one source at a time
       * therefore we don't have the entire source tree
       * since we don't have the entire souce tree we are unable to
       * compute the previously selected source which is shown in the selected
       * souces view.
       * Therefore we are computing allRootNodes stored at service level
       * when assignObjectsV2 is enabled.
       */
      if ($ctrl.hasSingleSourceGroup) {
        $ctrl.allSelectedSourceGroups = SourcesUtil
          .groupSourcesTree(PubSourceService.allRootNodes || []);
      } else {
        $ctrl.allSelectedSourceGroups = $ctrl.sourceGroups;
      }
    }

    /**
     * Removes provided source node from the tenant assigned sources list.
     *
     * @method   removeSources
     * @param    {Object}   node   The node to remove
     */
    function removeSources(nodeToRemove) {
      // show unassignment warning when node removal is not allowed.
      if (!nodeToRemove._canRemove.result) {
        return;
      }

      // Removing the node from the tree.
      $ctrl.tree = PubJobServiceFormatter.removeNodes(
        $ctrl.tree,
        function isNodeToRemove(node) {
          return node.protectionSource.id === nodeToRemove.protectionSource.id;
        }
      );

      constructSourceGroups();
    }

    /**
     * Returns the current source tree.
     *
     * @method getSourceTree
     */
    function setSourceTree() {
      if (!$ctrl.selectedSourceGroup || !$ctrl.selectedSource) {
        return;
      }

      if (_.get($ctrl, 'selectedSourceGroup.suppressRootNode')) {
        $ctrl.selectedSourceTree = $ctrl.selectedSourceGroup;
      } else {
        // we want to show root node info
        // for users to be able to assign the entire vcenter
        // so we just put the selected source inside the selected source group.
        // and display it.
        // otherwise it will list all objects in the source group.
        const thisSourceGroup = {...$ctrl.selectedSourceGroup};
        thisSourceGroup.nodes = [$ctrl.selectedSource];
        $ctrl.selectedSourceTree = thisSourceGroup;
      }
    }
  }
})(angular);
