import { Inject, Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { ConfigurationService } from './configuration.service';
import { FeatureFlagsService } from './feature-flags.service';
import { LocaleService } from './locale.service';
import { UserService } from './user.service';

export type Initializers = Observable<boolean>[];

/**
 * Injection token that holds the common observables to initialise your app.
 */
export const DEFAULT_INITIALIZER = new InjectionToken<Initializers>('Set of observables to initialise the app');

export const getDefaultInitializerProvider = () => [
  {
    provide: DEFAULT_INITIALIZER,
    useFactory: (
      featureFlagsService: FeatureFlagsService,
      localeService: LocaleService,
      userService: UserService,
      configurationService: ConfigurationService,
    ): Initializers => [
      localeService.init(),
      featureFlagsService.init().pipe(switchMap(() => userService.getSessionUser()), map(({ user }) => !!user)),
      configurationService.fetchConfiguration().pipe(map(config => Boolean(config)))
    ],
    deps: [
      FeatureFlagsService,
      LocaleService,
      UserService,
      ConfigurationService
    ],
  },
];

/**
 * App initializer service holding the logic to fetch locale & argus onboarding pages.
 */
@Injectable()
export class InitializerService {
  /**
   * Indicates whether app is being loaded or not.
   */
  private _isLoading$ = new BehaviorSubject<boolean>(true);

  /**
   * Steam of app loading status changes.
   */
  isLoading$ = this._isLoading$.asObservable();

  /**
   * Constructor for initialiser service
   */
  constructor(
    @Inject(DEFAULT_INITIALIZER) private common?: Observable<boolean>[]
  ) { }

  /**
   * Initialize the app and required context.
   *
   */
  init(custom?: Observable<boolean>[]) {
    this.startLoading();
    return combineLatest([...(this.common || []), ...(custom || [])]).pipe(
      map(([isLocaleLoaded]) => isLocaleLoaded),
      tap(this.endLoading),
    );
  }

  /**
   * Set state to mark start loading.
   */
  startLoading = () => {
    this._isLoading$.next(true);
  };

  /**
   * Set state to mark end loading.
   */
  endLoading = () => {
    this._isLoading$.next(false);
    this._isLoading$.complete();
  };
}
