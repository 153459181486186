import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const dmsOnboardingAccess = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.dmsOnboardingEnabled

  // The onboarding wizard requires modify privileges for multiple resource types,
  // for simplicity, only show the wizard to super admins until a privilege
  // for DMS admin exists.
  && ctx.irisContext.user.roles.includes('COHESITY_MCM_SUPER_ADMIN')
  && ctx.clusterInfo?._serviceType === 'dms';

export const DmsOnboardingConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'dmsOnboarding.**',
      url: '/dms/onboarding',
      loadChildren: () => import('./dms-onboarding.module').then(m => m.DmsOnboardingModule),
    }
  ],
  allClusterMap: {
    'dmsOnboarding': true,
    'dmsOnboarding.welcome': true,
    'dmsOnboarding.regions': true,
    'dmsOnboarding.users': true,
    'dmsOnboarding.saasConnections': true,
    'dmsOnboarding.sources': true,
    'dmsOnboarding.finish': true,
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'dmsOnboarding': dmsOnboardingAccess,
      'dmsOnboarding.welcome': dmsOnboardingAccess,
      'dmsOnboarding.regions': dmsOnboardingAccess,
      'dmsOnboarding.users': dmsOnboardingAccess,
      'dmsOnboarding.saasConnections': dmsOnboardingAccess,
      'dmsOnboarding.sources': dmsOnboardingAccess,
      'dmsOnboarding.finish': dmsOnboardingAccess,
    };
  }
};
