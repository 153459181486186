import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WebComponent } from './web.component';

@NgModule({
  imports: [CommonModule],
  declarations: [WebComponent],
  exports: [WebComponent],
})
export class WebComponentModule { }
