<ng-container *ngIf="(accessScopeListingService.loading$ | async) === false; else spinner">
  <cog-blank-card
    *ngIf="!(accessScopeListingService.tableData$ | async).length"
    size="sm"
    [showMessage]="false"
    [subMessage]="'helios.accessManagement.accessScope.emptyStateMessage' | translate">
    <button
      *ngIf="!accessScopeListingService.isReadOnlyUser"
      mat-flat-button
      type="button"
      color="primary"
      (click)="accessScopeListingService.onAddEditButtonClick()"
      cogDataId="add-access-button">
      <cog-icon shape="add"></cog-icon>
      {{ 'accessScope' | translate }}
    </button>
  </cog-blank-card>

  <coh-object-access-roles-table
    *ngIf="(accessScopeListingService.tableData$ | async).length"
    [isReadOnlyUser]="accessScopeListingService.isReadOnlyUser"
    [data]="accessScopeListingService.tableData$ | async"
    (rowAction)="onTableRowAction($event)"></coh-object-access-roles-table>
</ng-container>

<ng-template #spinner><cog-spinner></cog-spinner></ng-template>
