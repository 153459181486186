import { AbstractControl } from '@angular/forms';

/**
 * This function returns error messages for form controls corresponding to error keys
 * returned by validators.
 *
 * @param formControl Form control object to get error key from.
 */
export function GetIpValidationError(formControl: AbstractControl): string {
  let errorMsg = '';
  const { errors } = formControl;

  if (!errors || !Object.keys(errors).length) {
    return errorMsg;
  }
  /**
   * Get error code from  the formControl errors object.
   * There should be only one error code at a time.
   */
  const error = errors ? Object.keys(errors)[0] : null;

  // Return corresponding translation key
  switch (error) {
    case 'required':
      errorMsg = 'errors.required';
      break;
    case 'ipv4AddressError':
      errorMsg = 'errors.patterns.IPv4';
      break;
    case 'ipv6AddressError':
      errorMsg = 'errors.patterns.IPv6';
      break;
    case 'ipAddressError':
      errorMsg = 'errors.patterns.IP';
      break;
    case 'ipv4CidrError':
      errorMsg = 'errors.patterns.IPv4SubnetMask';
      break;
    case 'ipv6CidrError':
      errorMsg = 'errors.patterns.IPv6SubnetMask';
      break;
    case 'cidrAddressError':
      errorMsg = 'errors.patterns.subnetMask';
      break;
    case 'ipv4CidrSubnet16':
      errorMsg = 'errors.patterns.CIDRIPv416Bits';
      break;
    case 'ipv4CidrSubnetUpto22Bits':
      errorMsg = 'errors.patterns.CIDRIPv4Upto22Bits';
  }
  return errorMsg;
}
