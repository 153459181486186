import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const infrastructureAccess = (ctx: StateAccessContext) => ctx.CLUSTER_VIEW;

export const InfrastructureConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'infrastructure.**',
      url: '/infrastructure',
      loadChildren: () => import('./infrastructure.module').then(m => m.InfrastructureModule),
    },
  ],
  allClusterMap: {
    'infrastructure': {
      heliosOnly: true,
      allClustersState: 'infrastructure'
    }
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'infrastructure': infrastructureAccess,
    };
  },
};
