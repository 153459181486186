import { AfterContentInit, ChangeDetectorRef, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { CarouselItemComponent } from './../carousel-item/carousel-item.component';
import { CarouselService } from './../carousel.service';
import { CarouselIndicatorsComponent } from '../carousel-indicators/carousel-indicators.component';
import { IconModule } from '../../icon/icon.module';
import { DataIdDirective } from '../../data-id/data-id.directive';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

@Component({
  selector: 'cog-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  standalone: true,
  imports: [MatLegacyButtonModule, DataIdDirective, IconModule, CarouselIndicatorsComponent]
})
export class CarouselComponent implements AfterContentInit {
  /**
   * tracks the currently active carousel item
   */
  activeIndex = 0;

  /**
   * list of all the carousel item components
   */
  items: CarouselItemComponent[] = [];

  /**
   * If true, infinitely scrolls through all the carousel items.
   *
   * @default true
   */
  @Input()
  infinite = true;

  @ContentChildren(CarouselItemComponent) itemList?: QueryList<CarouselItemComponent>;

  /**
   * indicates if the next button should be disabled or not. For infinite carousel
   * the button will always be enabled.
   */
  get isNextDisabled(): boolean {
    return !this.infinite && this.activeIndex === this.items.length - 1;
  }

  /**
   * indicates if the previous button should be disabled or not. For infinite carousel
   * the button will always be enabled.
   */
  get isPrevDisabled(): boolean {
    return !this.infinite && this.activeIndex === 0;
  }

  constructor(private carouselService: CarouselService, private cdr: ChangeDetectorRef) {}

  ngAfterContentInit(): void {
    this.items = this.itemList?.toArray() ?? [];
    this.setActiveItem();

    this.itemList?.changes.subscribe(() => {
      this.items = this.itemList?.toArray() ?? [];
      this.setActiveItem();
    });
  }

  /**
   * marks the carousel item corresponding to current active index as actove.
   */
  setActiveItem() {
    setTimeout(() => {
      // wrapped with setTimeout for smooth animation while navigating back and forth
      // between the carousel items
      this.items.forEach((item, index) => {
        item.active = index === this.activeIndex;
      });

      this.cdr.markForCheck();
    });
  }

  /**
   * Click handler for next button to load the next carousel item
   */
  loadNext() {
    this.activeIndex = (this.activeIndex + 1) % this.items.length;
    this.carouselService.setState({direction: 'next'});
    this.setActiveItem();
  }

  /**
   * Click handler for prev button to load the previous carousel item
   */
  loadPrev() {
    this.activeIndex = (this.items.length + this.activeIndex - 1) % this.items.length;
    this.carouselService.setState({direction: 'prev'});
    this.setActiveItem();
  }

  /**
   * Indicator click handler for selecting the correct carousel item
   *
   * @param index index of the selected indicator
   */
  onIndicatorClicked(index: number) {
    this.carouselService.setState({direction: this.activeIndex < index ? 'next' : 'prev'});
    this.activeIndex = index;
    this.setActiveItem();
  }
}
