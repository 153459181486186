import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ViewPinningConfig } from '@cohesity/api/v2';
import { Controls, FormGroupOptions, NgxSubFormComponent, subformComponentProviders, takeUntilDestroyed } from 'ngx-sub-form';

@Component({
  selector: 'coh-pin-view-to-ssd',
  templateUrl: './pin-view-to-ssd.component.html',
  providers: subformComponentProviders(PinViewToSsdComponent),
})
export class PinViewToSsdComponent extends NgxSubFormComponent<ViewPinningConfig> implements OnInit {
  /**
   * Init Component.
   */
  ngOnInit() {
    this.formGroupControls.enabled.valueChanges
      .pipe(takeUntilDestroyed(this))
      .subscribe(enabled => {
        // When the toggle is enabled, set the default value as Forever, which
        // is -1.
        if (enabled) {
          this.onChangeRetention(this.formGroupValues.pinnedTimeSecs || -1);
        }
      });
  }

  /**
   * Get the form controls needed for the sub form.
   *
   * @returns   The form controls object.
   */
  getFormControls(): Controls<ViewPinningConfig> {
    return {
      enabled: new UntypedFormControl(null),
      pinnedTimeSecs: new UntypedFormControl(null),

      // This property is so far unused in the UI, but required for the model.
      lastUpdatedTimestampSecs: new UntypedFormControl(null),
    };
  }

  /**
   * Called when change retention.
   */
  onChangeRetention(retentionValue: number) {
    this.formGroupControls.pinnedTimeSecs.setValue(retentionValue);
  }

  /**
   * Function to add custom validation to set Required validation for dependent fields.
   *
   * @return Custom validator.
   */
  public getFormGroupControlOptions(): FormGroupOptions<ViewPinningConfig> {
    return {
      validators: [
        formGroup => {
          if (formGroup.value.enabled && !formGroup.value.pinnedTimeSecs) {
            return { pinnedTimeSecsRequired: true };
          }
          return null;
        },
      ],
    };
  }
}
