import { Component } from '@angular/core';
import { AutoDestroyable } from '@cohesity/utils';


/**
 * Perform scan dialog component.
 */
@Component({
  selector: 'dg-pa-all-issues',
  templateUrl: './all-issues.component.html',
})
export class AllIssuesComponent extends AutoDestroyable {
  constructor() {
    super();
  }
}
