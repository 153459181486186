/**
 * Enum for global search result type. Global search currently supports
 * searching either files or objects.
 */
export enum GlobalSearchResultType {
  /**
   * When doing file search. This is Helios only.
   */
  File = 'File',

  /**
   * When doing object (vms, dbs, etc.) search.
   */
  Object = 'Object',
}
