import { Pipe, PipeTransform } from '@angular/core';
import { RecoveryAction } from '@cohesity/iris-shared-constants';

import { enumGroupMap } from '../constants';

/**
 * Converts a recovery action to an icon name
 *
 * @example
 *   <cog-icon [shape]="recovery.action | recoveryActionIcon: recovery.environment"></cog-icon>
 */
@Pipe({
  name: 'recoveryActionIcon'
})
export class RecoveryActionIconPipe implements PipeTransform {
  /**
   * Converts a recovery action to an icon name.
   *
   * @param   action  Recovery action
   * @param   environment  Recovery environment
   * @returns The full icon name used in cog-icon
   */
  transform(action: string, environment: string): string {
    const envGroup = enumGroupMap[environment];
    let iconName = 'generic';

    switch (action) {
      case RecoveryAction.RecoverVMs:
      case RecoveryAction.RecoverVApps:
      case RecoveryAction.RecoverVmDisks:
        iconName = 'virtual-machine';
        break;

      case RecoveryAction.RecoverFiles:
      case RecoveryAction.DownloadFilesAndFolders:
        iconName = 'file';
        break;

      case RecoveryAction.RecoverPhysicalVolumes:
      case RecoveryAction.RecoverSystem:
        iconName = 'physical-server';
        break;

      case RecoveryAction.InstantVolumeMount:
        iconName = 'instant-volume-mount';
        break;

      case RecoveryAction.RecoverNasVolume:
        iconName = 'nas';
        break;

      case RecoveryAction.RecoverSanGroup:
      case RecoveryAction.RecoverSanVolumes:
        iconName = 'storage-array';
        break;

      case RecoveryAction.RecoverObjects:
      case RecoveryAction.RecoverRDS:
      case RecoveryAction.RecoverRDSPostgres:
      case RecoveryAction.RecoverAurora:
      case RecoveryAction.RecoverAzureSQL:
        iconName = 'database';
        break;

      case RecoveryAction.RecoverS3:
        iconName = 'bucket';
        break;

      case RecoveryAction.RecoverAzureEntraID:
        iconName = 'entra-id';
        break;

      default:
        if (envGroup === 'applications') {
          iconName = 'application';
        } else if (envGroup === 'office365') {
          iconName = 'application';
        } else if (envGroup === 'dbs') {
          iconName = 'database';
        } else if (envGroup === 'kubernetes') {
          iconName = 'kubernetes';
        }
        break;
    }

    return `helix:group-${iconName}`;
  }
}
