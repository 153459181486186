<div [formGroup]="formGroup" class="details-container">
  <div class="object-wrapper details-col1">
    <div class="object-icon">
      <cog-icon [shape]="formGroupValues.icon"></cog-icon>
    </div>
    <div class="object-detail">
      <span class="auto-truncate"
        cogDataId="object-detail"
        [matTooltip]="formGroupValues.name">
        {{ formGroupValues.name }}
      </span>
    </div>
  </div>
  <div class="properties-wrapper details-col2">
    <ng-container [formArrayName]="formControlNames.objectProperties"
      *ngFor="let control of formGroupControls.objectProperties.controls; let i=index">
      <coh-settings-list-object-property-item
        [formControl]="control"
        [propertyKeys]="getPropertyKeys(formGroupValues?.type, i)"
        (removeProp)="removeProp(i)"
        (addProp)="addProp()">
      </coh-settings-list-object-property-item>
    </ng-container>
  </div>
</div>
