import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared';
import { AddCloudVaultComponent, BackupWindowComponent, RpaasRecoverButtonComponent, UnpairedClusterPopoverComponent, VaultsTableComponent, VaultTableActionsComponent } from './components';

/**
 * Module for handling DMaaS workflows
 */
@NgModule({
  declarations: [
    AddCloudVaultComponent,
    VaultsTableComponent,
    UnpairedClusterPopoverComponent,
    BackupWindowComponent,
    VaultTableActionsComponent,
    RpaasRecoverButtonComponent,
  ],
  exports: [AddCloudVaultComponent, RpaasRecoverButtonComponent, VaultsTableComponent],
  imports: [
    CohesityHelixModule,
    CommonModule,
    SharedModule,
    TranslateModule.forChild()
  ],
})
export class RpaasSharedModule { }
