// COMPONENT: Timezone Picker

;(function(angular, undefined) {
  'use strict';

  /**
   * @ngdoc component
   * @name C.timezonePicker:cTimezonePicker
   * @function
   *
   * @description
   * Displays the current timezone and a picker to update it.
   *
   * @example
      <c-timezone-picker
        ngModel="shared.remoteClusterInfo.bandwidthLimit.timezone">
      </c-timezone-picker>
   */
  var modName = 'C.timezonePicker';
  var modDeps = [];
  var componentName = 'cTimezonePicker';
  var options = {
    bindings: {
      displayOnly: '<?',

      // Label to describe this instance.
      label: '@?',

      // the presence of this attribute will result in the toggle functionality
      // being suppressed in favor of simply showing the form element
      // editOnly: '?',
    },
    require: {
      ngModel: '^ngModel',
    },
    controller: 'TimeZonePickerCtrl',
    templateUrl: 'app/global/c-timezone-picker/c-timezone-picker.html'
  };

  angular
    .module(modName, modDeps)
    .controller('TimeZonePickerCtrl', cTimezonePickerFn)
    .component(componentName, options);

  function cTimezonePickerFn($timeout, $attrs, TIMEZONES) {

    var ctrl = this;

    // Set timezone ui-select options model.
    ctrl.timezones = TIMEZONES;

    ctrl.editOnly = $attrs.hasOwnProperty('editOnly');

    // show the 'change timezone' action if display only does not
    // equal true. display only means we only want to display the selected
    // timezone for the implementation point.
    ctrl.displayOnly = !ctrl.editOnly && ctrl.displayOnly !== true;

    ctrl.$onInit = function onInit() {
      ctrl.ngModel.$render = syncModelValue;
    };

    /**
     * Gets the timezone offset. Provided the timezone in string long format
     * (America/Los_Angeles) return the string offset (-07:00).
     *
     * @method     getTimezoneOffset
     * @param      {string}  timezone  The timezone
     * @return     {string}  The timezone offset.
     */
    function getTimezoneOffset(timezone) {
      return moment.tz(timezone).format('Z');
    }

    /**
     * Toggles display of timezone ui-select.
     *
     * @method     toggleTimeZoneInput
     */
    ctrl.toggleTimeZoneInput = function toggleTimeZoneInput() {
      if (!ctrl.model) {
        ctrl.model = moment.tz.guess();
        ctrl.onChangeTimezone(ctrl.model);
      }
      ctrl.showTimeZoneOption = !ctrl.showTimeZoneOption;
    };

    /**
     * Updates the timezone offset and hides the ui-select.
     *
     * @method     onChangeTimezone
     * @param      {string}  timezone  The timezone
     */
    ctrl.onChangeTimezone = function onChangeTimezone(timezone) {
      syncModelValue(timezone);
      ctrl.toggleTimeZoneInput();
    };

    /**
     * Sync internal & external model values.
     *
     * @method   syncModelValue
     * @param    {string}   newValue   The new model value to set
     */
    function syncModelValue(newValue) {
      if (arguments.length) {
        ctrl.ngModel.$setViewValue(newValue);
        setInternalModel(newValue);
        return;
      }

      if (ctrl.ngModel.$isEmpty(ctrl.ngModel.$modelValue)) {
        // initialize internal model
        setInternalModel(moment.tz.guess());

        // initialize external model in next digest cycle
        $timeout(function setExternalModel() {
          ctrl.ngModel.$setViewValue(ctrl.model);
        });
      } else {
        setInternalModel(ctrl.ngModel.$modelValue);
      }
    }

    /**
     * Sets the internal model values.
     *
     * @method   setInternalModel
     * @param    {string}   timezone   The new timezone
     */
    function setInternalModel(timezone) {
      ctrl.model = timezone;
      ctrl.timezoneOffset = getTimezoneOffset(ctrl.model);
    }
  }

})(angular);
