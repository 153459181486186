// Module: List Active Directories

;(function(angular) {
  'use strict';

  var configOptions = {
    bindings: {
      /**
       * Optional attribute. If present, then show the provided active
       * directories.
       */
      activeDirectories: '=?',

      /**
       * Optional attribute. If present then active directory delete is not
       * allowed else those options will be shown.
       */
      disableModifications: '<?',

      /**
       * Optional attribute. If present, then hide the organization column.
       */
      hideTenantCol: '<?',

      /**
       * Optional attribute. If present, it means the component is opened inside
       * a modal.
       */
      inModal: '<?',

      /**
       * Optional provide the inViewMode flag and if true then don't allow
       * viewing the Active Directory.
       *
       * @type  {Boolean}  [inViewMode=undefined]
       */
      inViewMode: '<?',

      /**
       * Optional attribute. If present, don't show the filter options.
       */
      noFilters: '<?',

      /**
       * Optional attribute. If present, then open the details of the AD inside
       * a modal. Use case when, viewing for the ADs assigned to a tenant, it
       * should open inside a modal.
       */
      viewDetailsInModal: '<?',

      /**
       * Optional function if provided this function will be used to determine
       * if a AD is selectable or not else by default all AD are considered selectable.
       */
      canSelect: '=?',

      /**
       * Optional function if provided this function will be used to get the AD
       * disabling reason else by default assuming no disabling reason.
       */
      getDisablingReason: '=?',
    },
    require: {
      /**
       * Optional provide ngModel. If present, allow AD selection and selected
       * ADs are provided via ngModel binding.
       */
      ngModel: '?ngModel',
    },
    controller: 'adController',
    templateUrl: 'app/admin/access-management/active-directory/list.html',
  };

  angular
    .module('C.activeDirectory')
    .controller('adController', AdControllerFn)
    .component('activeDirectoryList', configOptions);

  /**
   * Controller for the List view
   */
  function AdControllerFn($state, $q, $uibModal, $timeout, evalAJAX,
    ActiveDirectoryService, LdapService, Cart, TenantService, ngDialogService, featureFlagsService,) {

    var $ctrl = this;

    // Use cart service to enable AD selection.
    var selectedADs = Cart.newCart(
      function keyGeneratorFn(ad) {
        return ad.domainName;
      }
    );

    _.assign($ctrl, {
      // Component states.
      areAdsReady: false,
      haveRegisteredAds: false,

      // Filter for AD listing.
      filter: {
        tenantIds: [],
      },

      // Methods
      $onInit: $onInit,
      addAd: addAd,
      getData: getData,
      getLdapProvider: LdapService.getLdapProvider,
      isSelected: selectedADs.has,
      leaveDomain: leaveDomain,
      toggleSelection: toggleSelection,
      viewAd: viewAd,
    });

    /**
     * Initializes the controller and gets the list of Active Directories.
     *
     * @method   $onInit
     */
    function $onInit() {
      _.assign($ctrl, {
        canSelect: $ctrl.canSelect || _.constant(true),
        getDisablingReason: $ctrl.getDisablingReason || _.constant(''),
      });

      // If ngModel exists, then update the selected ADs using the given value.
      if ($ctrl.ngModel) {
        $ctrl.ngModel.$render = function updateInternalModalOnExternalChange() {
          selectedADs.addAll(_.map($ctrl.ngModel.$modelValue));
        };
      }

      if (!$ctrl.activeDirectories) {
        getData();
      } else {
        $ctrl.areAdsReady = true;
      }
    }

    /**
     * Gets the list of ADs.
     *
     * @method   getData
     */
    function getData() {
      var params = !$ctrl.hideTenantCol ? TenantService.extendWithTenantParams({
        _includeTenantInfo: true,
      }, $ctrl.filter.tenantIds) : {};

      $ctrl.areAdsReady = false;

      // Param to include the mapped Ldap Info.
      params._includeLdapInfo = true;

      // Add a slight delay
      // In order to leave enough time to complete AD creation
      $timeout(function() {
        ActiveDirectoryService.getActiveDirectories(params).then(
          function getAdSuccess(activeDirectories) {
            $ctrl.activeDirectories = activeDirectories;

            // Sets the variable for the first time, if ADs are registered.
            $ctrl.haveRegisteredAds = $ctrl.haveRegisteredAds ||
              !!activeDirectories.length;
          },
          evalAJAX.errorMessage
        ).finally(function getAdFinally() {
          $ctrl.areAdsReady = true;
        });
      }, 500);
    }
    /**
     * Toggle AD selection.
     *
     * @method   toggleSelection
     * @param    {Object}   ad   The ad to select.
     */
    function toggleSelection(ad) {
      selectedADs.toggle(ad);

      // Set the selected Map to the ngModel.
      $ctrl.ngModel.$setViewValue(selectedADs.cartMap);
    }

    /**
     * Opens the view AD details component inside a modal or a page depending
     * on the params.
     *
     * @method   viewAD
     * @param    {Object}   ad   The ad whose details are to be viewed.
     */
    function viewAd(ad) {
      if ($ctrl.inModal || $ctrl.viewDetailsInModal) {
        ActiveDirectoryService.viewADModal(ad);
      } else {
        $state.go('view-domain', {domain: ad.domainName});
      }
    }

    /**
     * Opens a modal to add an AD if inside a modal else go to the page view.
     *
     * @method   addAd
     */
    function addAd() {
      if ($ctrl.inModal || $ctrl.viewDetailsInModal) {
        if (featureFlagsService.enabled('ngActiveDirectory')) {
          return ngDialogService.showDialog('ng-modify-active-directory-dialog', {}, {
            minWidth: '100vw',
            height: '100vh',
          })
            .toPromise()
            .then(function modalResolved(newAdConfig) {
              if (newAdConfig) {
                $ctrl.activeDirectories.push(newAdConfig);

                // Select the newly added ad.
                toggleSelection(newAdConfig);
              }
            }, _.noop)
            .finally(function modalCloseFinally() {
              $ctrl.loading = false;
            });
        } else {
          ActiveDirectoryService.newAdModal()
            .then(function addAdSuccess(newAdConfig) {
              if (newAdConfig) {
                $ctrl.activeDirectories.push(newAdConfig);

                // Select the newly added ad.
                toggleSelection(newAdConfig);
              }
            });
        }
      } else {
        $state.go('add-ad');
      }
    }

    /**
     * Pops a challenge modal to confirm the intended action to remove the given
     * AD domain config.
     *
     * @method     leaveDomain
     * @param      {Object}  adConfig  AD config object
     * @return     {Object}  $uibModal instance
     */
    function leaveDomain(adConfig) {
      var modalConfig = {
        templateUrl: 'app/admin/access-management/active-directory/delete.html',
        controller: 'leaveAdController',
        controllerAs: '$ctrl',
        backdrop: true,
        keyboard: true,
        modalFade: true,
        resolve: {
          activeDirectory: function() {
            return angular.copy(adConfig);
          },
        },
      };

      return $uibModal.open(modalConfig).result.then(
        function leaveDomainSuccess() {
          // Need a slight pause before issuing a new GET call because the
          // response comes before the backend is done updating cluster config.
          $timeout(getData, 100);
        }
      );
    }
  }

})(angular);
