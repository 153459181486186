<cog-settings-list-item [formGroup]="formGroup" [invalid]="globalExcludeFSFormGroup.invalid"
  [label]="'globalExcludeFS' | translate">
  <cog-settings-list-item-value-display>
    <span>
      {{'protectionGroups.excluded' | translate: {
        excluded: globalExcludeFSFormGroup.get('fsTypes').value.length | number}
      }}
    </span>
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor [formGroup]="globalExcludeFSFormGroup" cogDataId="settings-global-exclude-fs">
    <h2>{{ 'exclude' | translate }}</h2>
    <div>
      <mat-form-field class="col-xs-8">
        <mat-label>
          {{'cFilePaths.skipNestedMountPointsPlaceholder' | translate}}
        </mat-label>
        <!-- Allow skip nested mount point types even when FS is not mounted -->
        <mat-chip-list #chipList>
          <mat-chip *ngFor="let skippedMountType of globalExcludeFSFormGroup.get('fsTypes').value"
            [cogDataId]="skippedGlobalFSType" [inheritDataId]="true" (removed)="removeFSFormControl(skippedMountType)">
            {{skippedMountType}}
            <cog-icon shape="cancel" matChipRemove></cog-icon>
          </mat-chip>
          <input #mountPointInput [placeholder]="'addNew' | translate" [matChipInputFor]="chipList" [matChipInputAddOnBlur]="true"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addFSFormControl($event)"
            cogDataId="global-skip-fs-type-input">
          </mat-chip-list>
      </mat-form-field>
    </div>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
