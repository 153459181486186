import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Environment } from 'src/app/shared/constants';
import { IrisContextService } from '@cohesity/iris-core';

import { NasSourceTreeService } from './nas-source-tree.service';
import { NasViewFilters } from './nas-view-filters';

/**
 * Tree service for GenericNas.
 */
@Injectable({
  providedIn: 'root',
})
export class GenericNasSourceTreeService extends NasSourceTreeService {
  constructor(translate: TranslateService, irisCtx: IrisContextService) {
    super(translate, irisCtx);

    // GenericNas also uses custom view filters.
    this.nasViewFilters = new NasViewFilters(this.treeControl, this.filters, irisCtx, Environment.kGenericNas);
  }

}
