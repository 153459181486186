import { Injectable } from '@angular/core';
import { HeliosTaggingApiService, Tag, TagCategory, TagList, TagsAsyncReqStatus, TagsAsyncStatusResp } from '@cohesity/api/helios-metadata';
import { SnackBarService } from '@cohesity/helix';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription, combineLatest, interval } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';

/**
 * polling interval for checking the status of the async operations on the tag
 */
const statusPollingInterval = 3000; // 3 seconds

/**
 * Model for async operation tracking
 */
interface StatusInfoTracker {
  /**
   * uuid of the tag for which the action is being tracked
   */
  tagUuid: string;

  /**
   * unique identifier of the async action
   */
  trackingId: string;
};

@Injectable({
  providedIn: 'root'
})
export class TagsService extends AutoDestroyable {
  /**
   * BehaviorSubject to store the loading state of the API response.
   */
  private _isLoading = new BehaviorSubject<boolean>(false);

  /**
   * Observable for the loading state
   */
  isLoading$ = this._isLoading.asObservable();

  /**
   * BehaviorSubject to store the tag list
   */
  private _tagList = new BehaviorSubject<TagList>(null);

  /**
   * Observable for the tag list
   */
  tagList$ = this._tagList.asObservable();

  /**
   * BehaviorSubject to store the tracking ids for which the async ops are going on.
   */
  private asyncActionTrackingIds = new BehaviorSubject<StatusInfoTracker[]>([]);

  /**
   * Observable yielding the current status of the async operations for the tags
   */
  private asyncStatuses$ = combineLatest([interval(statusPollingInterval), this.asyncActionTrackingIds]).pipe(
    this.untilDestroy(),
    switchMap(([_number, pendingTrackers]) => combineLatest(
      pendingTrackers.map((status) =>
        this.heliosTaggingService.tagsAsyncStatusOp({ trackingId: status.trackingId })),
    )),
    tap((values) => {
      // if status values exist, handle the current statuses of the operations. Otherwise, assume there no pending
      // operations and unsubscribe.
      if (values.length) {
        values.forEach((value, index) => {
          this.handleAsyncActionResponse(this.asyncActionTrackingIds.value[index], value);
        });
      } else if (this.asyncStatusesSubscription != null) {
        this.asyncStatusesSubscription.unsubscribe();
        this.asyncStatusesSubscription = null;
      }
    }),
  );

  private asyncStatusesSubscription: Subscription = null;

  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private heliosTaggingService: HeliosTaggingApiService,
    private snackbar: SnackBarService,
    private translate: TranslateService,
  ) {
    super();
  }

  /**
   * Loads tag data for the specified categories
   *
   * @param _categories list of categories for which tags need to be fetched
   */
  loadData(categories: TagCategory[]) {
    this._isLoading.next(true);

    return this.heliosTaggingService.listTagsOp({
      categories,
      pageSize: 1000,
    }).pipe(
      this.untilDestroy(),
      finalize(() => this._isLoading.next(false))
    ).subscribe(
      (value) => {
        // sort the tags in desc order as per the created timestamp
        value.tags?.sort((a, b) => b.createdTimeMsecs - a.createdTimeMsecs);
        this._tagList.next(value.tags);
      },
      this.ajaxHandlerService.handler
    );
  }

  /**
   * A helper function to update the in-memory copy of the tag.
   *
   * @param tag tag that need to be updated
   */
  updateTagInfoLocally(tag: Tag): void {
    this._tagList.next(
      this._tagList.value.map((existingTag: Tag) => {
        if (existingTag.uuid === tag.uuid) {
          return tag;
        }
        return existingTag;
      })
    );
  }

  /**
   * A helper function to mark local copy of tag as deleting and optionally to track the delete operation
   *
   * @param uuid uuid of the tag that need to be marked for deletion
   * @param trackingId optional tracking id to track the progress of the delete operation
   */
  markTagForDeletion(uuid: string, trackingId?: string) {
    const existingTag = this.getLocalTagInfo(uuid);
    if (existingTag) {
      this.updateTagInfoLocally({
        ...existingTag,
        isActive: false,
      });
    }

    // if tracking id is specified, track the delete operation.
    if (trackingId) {
      this.trackAsyncOperation(uuid, trackingId);
    }
  }

  /**
   * Schedules tracking of the async operation and handles the operation outcomes
   *
   * @param uuid tag uuid for which the async operation need to be tracked
   * @param trackingId tracking id of the async operation
   */
  private trackAsyncOperation(uuid: string, trackingId: string) {
    const tracker: StatusInfoTracker = {
      tagUuid: uuid,
      trackingId: trackingId,
    };

    if (!this.asyncStatusesSubscription) {
      this.asyncStatusesSubscription = this.asyncStatuses$.subscribe();
    }

    this.asyncActionTrackingIds.next([
      ...this.asyncActionTrackingIds.value,
      tracker,
    ]);
  }

  /**
   * Handles the responses of the status apis
   *
   * @param trackerInfo tracking operation for which the response need to be handled
   * @param value response of the tracking operation
   */
  private handleAsyncActionResponse(trackerInfo: StatusInfoTracker, value: TagsAsyncStatusResp) {
    if ([TagsAsyncReqStatus.Completed, TagsAsyncReqStatus.Failed].includes(value.status)) {
      this.handleOpCompletion(trackerInfo);
    }
  }

  /**
   * Handles the terminating statuses for the async workflow
   *
   * @param trackerInfo tracking operation for which the response need to be handled
   */
  private handleOpCompletion(trackerInfo: StatusInfoTracker) {
    const subscription: Subscription = this.heliosTaggingService.listTagsOp({uuids: [trackerInfo.tagUuid]})
      .subscribe((response) => {
        const updatedTag = response.tags?.find((tag) => tag.uuid === trackerInfo.tagUuid);
        if (updatedTag) {
          this.updateTagInfoLocally(updatedTag);
        } else {
          this.removeLocalTag(trackerInfo.tagUuid);
        }
        subscription.unsubscribe();
      }, this.ajaxHandlerService.handler);

    this.pruneTrackingInfo(trackerInfo);
  }

  /**
   * Helper function to prune the outdated tracker information
   *
   * @param trackerInfo tracking operation info that need to be pruned
   */
  private pruneTrackingInfo(trackerInfo: StatusInfoTracker) {
    const newTrackers = [];
    this.asyncActionTrackingIds.value.forEach(entry => {
      if (!(entry.tagUuid === trackerInfo.tagUuid && entry.trackingId === trackerInfo.trackingId)) {
        newTrackers.push(entry);
      }
    });
    this.asyncActionTrackingIds.next(newTrackers);
  }

  /**
   * Removes a tag from the local copy of the tags and displays the notification
   *
   * @param uuid tag id that need to be removed
   */
  private removeLocalTag(uuid: string) {
    const newTagList: TagList = [];
    this._tagList.value.forEach((tag) => {
      if (tag.uuid !== uuid) {
        newTagList.push(tag);
      }
    });
    this._tagList.next(newTagList);
    this.snackbar.open(this.translate.instant('tags.deleteSuccess'));
  }

  /**
   * Retrieves the tag having the given uuid
   *
   * @param uuid uuid of the tag that need to be retrieved
   * @returns a tag that matches the given uuid
   */
  private getLocalTagInfo(uuid: string) {
    return this._tagList.value.find((tag: Tag) => tag.uuid === uuid);
  }
}
