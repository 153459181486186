// Module: Report Top Jobs

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.reports')
    .controller('reportsTopJobsController', reportsTopJobsControllerFn);

  function reportsTopJobsControllerFn($scope, $state, $filter, $q, $translate,
    DateTimeService, JobRunsService, evalAJAX, SourceService, cUtils,
    cReportsControlsUtil, ReportsUtil) {

    var chartMaxJobsToDisplay = 5;

    $scope.topJobsControlsConfig = {
      showReportsSelector: true,
      showHeader: true,
      showDateRangeFilter: true,
      showRegisteredSourceFilter: true,
      showApplyFiltersBtn: true,
      showStatusFilter: true,
      enableTypeAhead: true,
      typeAheadLabel: 'reports.typeAheadLabel.jobs',
      typeAheadDatasetKey: 'jobId',
    };

    /**
     * Collection of jobs by data transferred
     * @type {array} = [name: 'string', value: int]
     */
    $scope.jobs = [];
    $scope.getJobSummary = getJobSummary;

    // number of items to show on paginated smart-table
    $scope.itemsPerPage = 10;

    /**
     * loads necessary data for controller and sets default filters
     */
    function activate() {
      var promiseObject = {};
      var defaultFilters;

      promiseObject.typeAheadData = ReportsUtil.getJobs();
      promiseObject.registeredSources = ReportsUtil.getSources();

      $q.all(promiseObject).then(function(data)  {
        $scope.dataset = data;
      }, evalAJAX.errorMessage).finally(function() {
        $scope.reportsControlsDataReady = true;
      });

      defaultFilters = cReportsControlsUtil.getDefaultFilters({
        registeredSourceIds: [],
        typeAhead: {},
      });

      getJobSummary(defaultFilters);
    }

    /**
     * returns params object populated by selected filters
     *
     * @param     {object}    filters    selected filters from reports controls
     *                                   component
     *
     * @return    {object}               params object
     */
    function getParams(filters) {
      return {
        ids: filters.jobId,
        startTimeUsecs: filters.timeObject.from.toUsecDate(),
        endTimeUsecs: filters.timeObject.until.toUsecDate(),
        onlyReturnJobDescription: false,
        statuses: filters.runStatus,
        sourceIds: filters.registeredSourceIds || [],
      };
    }

    /**
     * checks if source is available
     *
     * @param     {object}    source    object containing soure details
     * @return    {Boolean}
     */
    function isSourceAvailable(source) {
      return SourceService.parentSourceIds.includes(source.id);
    }

    /**
     * get report data
     *
     * @param    {object}    filters    filters selected from reports controls
     *                                  component
     */
    function getJobSummary(filters) {
      $scope.jobs = [];
      $scope.dataReady = false;
      $scope.bytesTransferredChart.loading = true;
      $scope.runtimeChart.loading = true;
      $scope.objectsChart.loading = true;
      $scope.SLAViolationsChart.loading = true;

      JobRunsService.getJobSummary(getParams(filters)).then(
        function getJobSummarySuccess(r) {
          //reset our jobs array
          if (r.data.length) {
            r.data.forEach(function forEachJobSummary(object, index) {
              object.backupJobSummary.dataReductionRatio =
                cUtils.getRatio(
                  object.backupJobSummary.totalLogicalBackupSizeBytes,
                  object.backupJobSummary.totalPhysicalBackupSizeBytes,
                  $translate.instant('naNotApplicable')
                );

              r.data[index].backupJobSummary.avgTransferredBytes =
                calculateAvgDataTransferred(
                  object.backupJobSummary.totalBytesReadFromSource,
                  object.backupJobSummary.numSuccessfulJobRuns,
                  object.backupJobSummary.numFailedJobRuns
                );

              object.backupJobSummary.avgTransferredBytes =
                calculateAvgDataTransferred(
                  object.backupJobSummary.totalBytesReadFromSource,
                  object.backupJobSummary.numSuccessfulJobRuns,
                  object.backupJobSummary.numFailedJobRuns
                );

              object.backupJobSummary.numSlaViolations =
                object.backupJobSummary.numSlaViolations || 0;
              object._normalizedParent =
                SourceService.normalizeEntity(
                  object.backupJobSummary.jobDescription.parentSource);

              object._sourceAvailable =
                isSourceAvailable(object._normalizedParent);

              $scope.jobs.push(object);
            });
          }
          buildBytesTransferredChart(r.data);
          buildRuntimeChart(r.data);
          buildObjectsChart(r.data);
          buildSLAChart(r.data);
        },
        evalAJAX.errorMessage
        ).finally(
        function getJobSummaryFinally() {
          $scope.bytesTransferredChart.loading = false;
          $scope.runtimeChart.loading = false;
          $scope.objectsChart.loading = false;
          $scope.SLAViolationsChart.loading = false;
          $scope.dataReady = true;
        });
      }

      /**
       * Build data objects for jobs by data transferred chart
       * @param  {object} data
       * @return {}
       */
      function buildBytesTransferredChart(data) {
        var bytesData = [];
        var bytesCategories = [];
        var sizeUnit;
        var processedData = $filter('filter')(data, function(value, index) {
          return value.backupJobSummary.hasOwnProperty('avgTransferredBytes');
        });
        processedData = $filter('orderBy')(processedData, 'backupJobSummary.avgTransferredBytes', true);
        processedData = $filter('limitTo')(processedData, chartMaxJobsToDisplay);
        $scope.bytesTransferredChart.categories = [];

        angular.forEach(processedData, function loopData(object, index) {
          // find the units for the largest job and use it for our yAxis labels and conversions
          if (index === 0) {
            sizeUnit = cUtils.bytesToSize(object.backupJobSummary.avgTransferredBytes).unit;
          }
          bytesData.push([
            object.backupJobSummary.jobDescription.name,
            cUtils.bytesToUnit(
              object.backupJobSummary.avgTransferredBytes, sizeUnit)
          ]);
          bytesCategories.push(object.backupJobSummary.jobDescription.name);
        });

        // update chart labels to match scale/unit of largest item
        $scope.bytesTransferredChart.yAxis.labels.format =
          '{value} ' + sizeUnit;
        $scope.bytesTransferredChart.yAxis.title.text = sizeUnit;
        $scope.bytesTransferredChart.tooltip.pointFormat =
          '<b>{point.y:.1f} ' + sizeUnit + '</b>';

        $scope.bytesTransferredChart.series[0].data = bytesData;
        $scope.bytesTransferredChart.xAxis.categories = bytesCategories;
        $scope.bytesTransferredChart.loading = false;
      }

        // Config object for bytes transferred chart
        $scope.bytesTransferredChart = {
          chartType: 'basic',
          loading: true,
          series: [{
            name: 'MB',
            data: []
          }],
          chart: {
            height: 250
          },
          yAxis: {
            labels: {
              format: '{value} MB'
            },
            allowDecimals: false,
            title: {
              text: 'MB'
            }
          },
          tooltip: {
            pointFormat: '<b>{point.y:.1f} MB</b>'
          },
          xAxis: {
            categories: [],
            labels: {
              autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90],
              style: {
                whiteSpace: "nowrap"
              },
              formatter: function() {
                // do truncation here and return string
                if (this.value.length > 25) {
                  return this.value.slice(0, 25) + '...';
                } else {
                  return this.value;
                }
              }
            }
          }
        };

      /**
       * Build data objects for jobs by runtime chart
       * @param  {object} data
       * @return {}
       */
       function buildRuntimeChart(data) {
        var runtimeData = [];
        var runtimeCategories = [];
        var processedData = $filter('filter')(data, function(value, index) {
          return value.backupJobSummary.hasOwnProperty('avgRunTimeUsecs');
        });
        processedData = $filter('orderBy')(processedData, 'backupJobSummary.avgRunTimeUsecs', true);
        processedData = $filter('limitTo')(processedData, chartMaxJobsToDisplay);
        $scope.runtimeChart.categories = [];

        angular.forEach(processedData, function loopData(object, index) {
          var time = DateTimeService.usecsToMinutes(
            object.backupJobSummary.avgRunTimeUsecs);
          runtimeData.push([object.backupJobSummary.jobDescription.name, time]);
          runtimeCategories.push(object.backupJobSummary.jobDescription.name);
        });

        $scope.runtimeChart.series[0].data = runtimeData;
        $scope.runtimeChart.xAxis.categories = runtimeCategories;
        $scope.runtimeChart.loading = false;
      }

      // Config object for runtime chart
      $scope.runtimeChart = {
        chartType: 'basic',
        loading: true,
        series: [{
          name: 'minutes',
          data: []
        }],
        chart: {
          height: 250
        },
        yAxis: {
          allowDecimals: false,
          title: {
            text: $translate.instant('minutes'),
          }
        },
        tooltip: {
          pointFormat: '<b>{point.y:.1f} minutes</b>'
        },
        xAxis: {
          categories: [],
          labels: {
            autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90],
            style: {
              whiteSpace: "nowrap"
            },
            formatter: function() {
              // do truncation here and return string
              if (this.value.length > 25) {
                return this.value.slice(0, 25) + '...';
              } else {
                return this.value;
              }
            }
          }
        }
      };

      /**
       * Build data objects for jobs by protected objects chart
       * @param  {object} data
       * @return {}
       */
      function buildObjectsChart(data) {
        var objectsData = [];
        var objectsCategories = [];
        var processedData = $filter('filter')(data, function(value, index) {
          return value.backupJobSummary.hasOwnProperty('numObjectsBackedUp');
        });
        processedData = $filter('orderBy')(processedData, 'backupJobSummary.numObjectsBackedUp || 0', true);
        processedData = $filter('limitTo')(processedData, chartMaxJobsToDisplay);
        $scope.objectsChart.categories = [];

        angular.forEach(processedData, function loopData(object, index) {
          objectsData.push([
            object.backupJobSummary.jobDescription.name,
            object.backupJobSummary.numObjectsBackedUp,
          ]);
          objectsCategories.push(object.backupJobSummary.jobDescription.name);
        });

        $scope.objectsChart.series[0].data = objectsData;
        $scope.objectsChart.xAxis.categories = objectsCategories;
        $scope.objectsChart.loading = false;
      }

      // Config object for objects chart
      $scope.objectsChart = {
        chartType: 'basic',
        loading: true,
        series: [{
          name: 'objects',
          data: []
        }],
        chart: {
          height: 250
        },
        yAxis: {
          allowDecimals: false,
          title: {
            text: $translate.instant('objects'),
          }
        },
        tooltip: {
          pointFormat: '<b>{point.y} objects</b>'
        },
        xAxis: {
          categories: [],
          labels: {
            autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90],
            style: {
              whiteSpace: "nowrap"
            },
            formatter: function() {
              // do truncation here and return string
              if (this.value.length > 25) {
                return this.value.slice(0, 25) + '...';
              } else {
                return this.value;
              }
            }
          }
        }
      };

    /**
     * Build data objects for jobs by SLA Violations chart
     * @param  {object} data
     * @return {}
     */
    function buildSLAChart(data) {
      var SLAViolationData = [];
      var SLAViolationCategories = [];

      var processedData =
        $filter('filter')(data, function filterData(value, index) {
          return value.backupJobSummary.hasOwnProperty('numObjectsBackedUp');
        });
      processedData = $filter('orderBy')(processedData, 'backupJobSummary.numSlaViolations || 0', true);
      processedData = $filter('limitTo')(processedData, chartMaxJobsToDisplay);
      $scope.SLAViolationsChart.categories = [];

      angular.forEach(processedData, function loopData(object, index) {
        SLAViolationData.push([
          object.backupJobSummary.jobDescription.name,
          object.backupJobSummary.numSlaViolations || 0,
        ]);
        if (object.backupJobSummary.numSlaViolations !== undefined) {
          $scope.noSLAViolations = false;
        }
        SLAViolationCategories.push(object.backupJobSummary.jobDescription.name);
      });

      $scope.SLAViolationsChart.series[0].data = SLAViolationData;
      $scope.SLAViolationsChart.xAxis.categories = SLAViolationCategories;
      $scope.SLAViolationsChart.loading = false;
    }

    // Config object for objects chart
    $scope.SLAViolationsChart = {
      chartType: 'basic',
      loading: true,
      series: [{
        name: 'violations',
        data: []
      }],
      chart: {
        height: 250
      },
      tooltip: {
        pointFormat: '<b>{point.y} violations</b>'
      },
      yAxis: {
        allowDecimals: false,
        title: {
          text: $translate.instant('violations'),
        }
      },
      xAxis: {
        categories: [],
        labels: {
          autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90],
          style: {
            whiteSpace: "nowrap"
          },
          formatter: function() {
            // do truncation here and return string
            if (this.value.length > 25) {
              return this.value.slice(0, 25) + '...';
            } else {
              return this.value;
            }
          }
        }
      }
    };

    function calculateAvgDataTransferred(totalBytesReadFromSource, numSuccessfulJobRuns, numFailedJobRuns) {
      var totalRuns;
      if (!totalBytesReadFromSource) {
        return 0;
      }
      if (!numSuccessfulJobRuns) {
        numSuccessfulJobRuns = 0;
      }
      if (!numFailedJobRuns) {
        numFailedJobRuns = 0;
      }
      totalRuns = numSuccessfulJobRuns + numFailedJobRuns;
      if (totalRuns === 0) {
        return 0;
      } else {
        return totalBytesReadFromSource / totalRuns;
      }
    }

    activate();
  }
})(angular);
