import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Api } from '@cohesity/api/private';
import { filter, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { PARAMETER_CODEC } from '../utils/utils';

@Injectable()
export class ProgressService {
  constructor(
    protected http: HttpClient
  ) { }

  /**
   * Determines whether the task is complete.
   *
   * @param progressInfo Specifies the progress info
   * @returns True, iff the task is finished. False, otherwise.
   */
  isTaskComplete(progressInfo: any): boolean {
    // kFinished === 1.
    return progressInfo?.resultGroupVec?.[0]?.taskVec?.[0]?.progress?.status?.type === 1;
  }

  /**
   * Fetches the progress info corresponding to the given taskPath within the
   * pulse tree in stats service.
   * This uses the v1 private API /progressMonitors
   *
   * @param taskPath Specifies the task path for the restore task
   * @param regionId Specifies the region ID for the DataPlane
   * @param clusterId Specifies the cluster ID for the DataPlane
   * @returns Observable containing the Progress Info.
   */
  getTaskProgess(taskPath: string,
    regionId?: string,
    clusterId?: number): Observable<any> {
    if (!taskPath || (!regionId && !clusterId)) {
      return null;
    }

    const taskUrl = Api.private('progressMonitors');

    // Prepare headers for passthrough call to DataPlane.
    let headers = new HttpHeaders();
    if (regionId) {
      headers = headers.set('regionId', regionId);
    }
    if (clusterId) {
      headers = headers.set('clusterId', String(clusterId));
    }

    // Prepare parameters.
    let params = new HttpParams({
      encoder: PARAMETER_CODEC
    });
    params = params.set('taskPathVec', taskPath);
    params = params.set('excludeSubTasks', true);
    params = params.set('includeFinishedTasks', false);
    params = params.set('includeEventLogs', false);

    const body: any = null;
    const req = new HttpRequest<any>(
      'GET',
      taskUrl,
      body,
      {
        headers: headers,
        params: params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(r => r instanceof HttpResponse),
      switchMap((r: HttpResponse<any>) => of(r.body)));
  }
}
