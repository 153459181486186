/* tslint:disable */

/**
 * Specifies the sync status of IOC integration.
 */
export enum IocIntegrationSyncStatus {
  NotStarted = 'notStarted',
  InProgress = 'inProgress',
  Success = 'success',
  Error = 'error'
}
