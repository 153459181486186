<!-- Snapshot selector for connectors supporting time-range filtering in API.
     Since the timeRange cog-filter needs to be kept rendered so as to not
     trigger an infinite call loop, this component is rendered separately.
     The 'loading' input param takes care of selectively rendering the
     restore points cog-table. -->
<ng-container *ngIf="isTimeRangeFilterThroughApiSupported">
  <coh-snapshot-selector-list-view
    [formControl]="formGroupControls.listViewControl"
    [snapshotId]="listViewSnapshotId"
    [snapshots]="snapshots$ | async"
    [snapshotMetadata]="snapshotMetadata$ | async"
    [usingIndexedSnapshots]="indexedObjectName"
    [hideSizeCol]="hideSizeCol"
    [hideBackupTypeCol]="hideBackupTypeCol"
    [isTimeRangeFilterThroughApiSupported]="true"
    [selectedTimeRange]="selectedTimeRange"
    [loading]="dataLoading$ | async"
    (dateRangeChange)="handleTimeRangeChange($event)">
  </coh-snapshot-selector-list-view>
</ng-container>

<!-- For all other connectors that don't support time-range based filter. -->
<ng-container *ngIf="!isTimeRangeFilterThroughApiSupported">
  <cog-spinner *ngIf="dataLoading$ | async; else selector"></cog-spinner>

  <ng-template #selector>
    <div class="c-message-inline" *ngIf="isTapeSnapshot()">
      <strong>{{ 'vmSnapshotSelectModal.tapeSelected.title' | translate }}</strong>
      {{ 'vmSnapshotSelectModal.tapeSelected.text' | translate }}
    </div>

    <cog-banner
      *ngIf="!formGroupValues.indexedSnapshotsOnlyControl"
      status="warn"
      class="margin-bottom-sm"
      cogDataId="non-indexed-snapshots-warning">
      {{ 'indexedSnapshotsOnly' | translate }}
    </cog-banner>

    <cog-banner
      *ngIf="isSnapshotNonRecoverable$ | async"
      status="critical"
      class="margin-bottom-sm"
      cogDataId="recovery-blocker-error">
      {{ 'recoveryBlocked' | translate }}
    </cog-banner>

    <div class="flex-row flex-justify-space-between margin-bottom">
      <div>
        <mat-slide-toggle
          *ngIf="indexedObjectName && !this.hideIndexedSnapshotsToggle"
          [formControl]="formGroupControls.indexedSnapshotsOnlyControl"
          cogDataId="show-indexed-snapshots-only"
        >
          {{ 'showIndexedSnapshotsOnly' | translate }}
        </mat-slide-toggle>
      </div>

      <div>
        <coh-snapshot-selector-view-switcher
          *ngIf="!this.hideViewSwitcher"
          [defaultViewSwitcherView]="defaultViewSwitcherView"
          [view]="viewSwitcherView"
          (change)="onViewChange($event)">
        </coh-snapshot-selector-view-switcher>
      </div>
    </div>

    <div *ngIf="fetchRunType" class="margin-top margin-bottom-sm">
      <mat-checkbox [formControl]="formGroupControls.fetchOnlySnapshotRunType" cogDataId="fetch-run-type">
        <span>{{ 'showRunTypeSnapshots' | translate: { runType: runTypeUIKey } }} </span>
      </mat-checkbox>
    </div>

    <ng-container *ngIf="!snapshotSelectorService.optimizedSnapshotSelector">
      <div *ngIf="viewSwitcherView === 'timeline'">
        <mat-form-field>
          <input
            matInput
            cogDataId="current-date"
            [formControl]="formGroupControls.currentDate"
            [matDatepicker]="picker"
            [matDatepickerFilter]="activeDatesFilter$ | async"
            [placeholder]="'chooseADate' | translate"
          />
          <mat-datepicker-toggle matSuffix cogDataId="current-date-picker-icon" [for]="picker">
            <cog-icon shape="keyboard_arrow_down" matDatepickerToggleIcon></cog-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <coh-snapshot-selector-timeline-view
        *ngIf="viewSwitcherView === 'timeline'"
        [defaultSnapshot]="defaultTimelineViewSnapshot$ | async"
        [pointInTimeSupported]="pointInTimeSelectionSupported"
        [snapshots]="timelineViewSnapshots$ | async"
        [snapshotMetadata]="snapshotMetadata$ | async"
        [timestampUsecs]="timelineViewTimestamp"
        [timeRanges]="snapshotSelectorService.currentDateTimeRanges$ | async"
        [snapshotLabels]="snapshotLabels"
        [snapshotType]="snapshotType"
        (change)="onTimelineValueChange($event)"
      >
      </coh-snapshot-selector-timeline-view>

      <coh-snapshot-selector-list-view
        *ngIf="viewSwitcherView === 'list'"
        [formControl]="formGroupControls.listViewControl"
        [snapshotId]="listViewSnapshotId"
        [snapshots]="snapshots$ | async"
        [snapshotMetadata]="snapshotMetadata$ | async"
        [usingIndexedSnapshots]="indexedObjectName"
        [hideSizeCol]="hideSizeCol"
        [hideBackupTypeCol]="hideBackupTypeCol">
      </coh-snapshot-selector-list-view>
    </ng-container>

    <ng-container *ngIf="snapshotSelectorService.optimizedSnapshotSelector">
      <div class="calendar-view-container flex-row">
        <div class="calendar-container">
          <coh-calendar
            cogDataId="cdp-pit-calendar"
            [markedDates]="markedDates$ | async"
            [activeDates]="activeDates$ | async"
            [selectedDate]="snapshotSearchService.selectedDate$ | async"
            (dateChanged)="dateChangeHandler($event)"
          >
          </coh-calendar>
        </div>

        <div class="view-container">
          <div class="pit-container" *ngIf="viewSwitcherView === 'timeline'">
            <coh-snapshot-selector-timeline-view
              [optimizedTimelineView]="snapshotSelectorService.optimizedSnapshotSelector"
              [defaultSnapshot]="defaultTimelineViewSnapshot$ | async"
              [pointInTimeSupported]="pointInTimeSelectionSupported"
              [snapshots]="timelineViewSnapshots$ | async"
              [snapshotMetadata]="snapshotMetadata$ | async"
              [timestampUsecs]="timelineViewTimestamp"
              [timeRanges]="snapshotSelectorService.currentDateTimeRanges$ | async"
              [snapshotLabels]="snapshotLabels"
              [snapshotType]="snapshotType"
              (change)="onTimelineValueChange($event)"
            >
            </coh-snapshot-selector-timeline-view>
          </div>

          <div class="list-container" *ngIf="viewSwitcherView === 'list'">
            <coh-snapshot-selector-list-view
              [optimizedListView]="snapshotSelectorService.optimizedSnapshotSelector"
              [formControl]="formGroupControls.listViewControl"
              [snapshotId]="listViewSnapshotId"
              [snapshots]="listViewSnapshots$ | async"
              [snapshotMetadata]="snapshotMetadata$ | async"
              [usingIndexedSnapshots]="indexedObjectName"
              [hideSizeCol]="hideSizeCol"
            >
            </coh-snapshot-selector-list-view>
          </div>
        </div>
      </div>
    </ng-container>

  </ng-template>

</ng-container>
