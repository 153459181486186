/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DataSourceConnectionList } from '../models/data-source-connection-list';
import { DataSourceConnection } from '../models/data-source-connection';
import { CreateDataSourceConnectionRequest } from '../models/create-data-source-connection-request';
import { PatchDataSourceConnectionRequest } from '../models/patch-data-source-connection-request';
@Injectable({
  providedIn: 'root',
})
class DataSourceConnectionService extends __BaseService {
  static readonly GetDataSourceConnectionPath = '/data-source-connections';
  static readonly CreateDataSourceConnectionPath = '/data-source-connections';
  static readonly DeleteDataSourceConnectionPath = '/data-source-connections/{connectionId}';
  static readonly PatchDataSourceConnectionPath = '/data-source-connections/{connectionId}';
  static readonly GetDataSourceConnectionRegistrationTokenPath = '/data-source-connections/{connectionId}/registrationToken';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get data-source connections
   *
   * Gets all specified data-source connections.
   * @param params The `DataSourceConnectionService.GetDataSourceConnectionParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the ID of the tenant for which the connections are to be fetched.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `connectionNames`: Specifies the names of the connections which are to be fetched.
   *
   * - `connectionIds`: Specifies the unique IDs of the connections which are to be fetched.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetDataSourceConnectionResponse(params: DataSourceConnectionService.GetDataSourceConnectionParams): __Observable<__StrictHttpResponse<DataSourceConnectionList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.connectionNames || []).forEach(val => {if (val != null) __params = __params.append('connectionNames', val.toString())});
    (params.connectionIds || []).forEach(val => {if (val != null) __params = __params.append('connectionIds', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/data-source-connections`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DataSourceConnectionList>;
      })
    );
  }
  /**
   * Get data-source connections
   *
   * Gets all specified data-source connections.
   * @param params The `DataSourceConnectionService.GetDataSourceConnectionParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the ID of the tenant for which the connections are to be fetched.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `connectionNames`: Specifies the names of the connections which are to be fetched.
   *
   * - `connectionIds`: Specifies the unique IDs of the connections which are to be fetched.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetDataSourceConnection(params: DataSourceConnectionService.GetDataSourceConnectionParams): __Observable<DataSourceConnectionList> {
    return this.GetDataSourceConnectionResponse(params).pipe(
      __map(_r => _r.body as DataSourceConnectionList)
    );
  }

  /**
   * Create a data-source connection
   *
   * Creates a data-source connection which can be used to register and protect sources, to access filer services, etc.
   * @param params The `DataSourceConnectionService.CreateDataSourceConnectionParams` containing the following parameters:
   *
   * - `body`: Specifies the request parameters to create a connection.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateDataSourceConnectionResponse(params: DataSourceConnectionService.CreateDataSourceConnectionParams): __Observable<__StrictHttpResponse<DataSourceConnection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-source-connections`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DataSourceConnection>;
      })
    );
  }
  /**
   * Create a data-source connection
   *
   * Creates a data-source connection which can be used to register and protect sources, to access filer services, etc.
   * @param params The `DataSourceConnectionService.CreateDataSourceConnectionParams` containing the following parameters:
   *
   * - `body`: Specifies the request parameters to create a connection.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateDataSourceConnection(params: DataSourceConnectionService.CreateDataSourceConnectionParams): __Observable<DataSourceConnection> {
    return this.CreateDataSourceConnectionResponse(params).pipe(
      __map(_r => _r.body as DataSourceConnection)
    );
  }

  /**
   * Delete a data-source connection using its ID. After deleting a connection, any connectors within it won't be able to connect to the cluster. A connection should only be deleted after ensuring that no sources are using it.
   *
   * Delete the data-source connection specified by the ID in the request path.
   * @param params The `DataSourceConnectionService.DeleteDataSourceConnectionParams` containing the following parameters:
   *
   * - `connectionId`: Specifies the unique ID of the connection which is to be deleted.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteDataSourceConnectionResponse(params: DataSourceConnectionService.DeleteDataSourceConnectionParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/data-source-connections/${encodeURIComponent(params.connectionId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a data-source connection using its ID. After deleting a connection, any connectors within it won't be able to connect to the cluster. A connection should only be deleted after ensuring that no sources are using it.
   *
   * Delete the data-source connection specified by the ID in the request path.
   * @param params The `DataSourceConnectionService.DeleteDataSourceConnectionParams` containing the following parameters:
   *
   * - `connectionId`: Specifies the unique ID of the connection which is to be deleted.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteDataSourceConnection(params: DataSourceConnectionService.DeleteDataSourceConnectionParams): __Observable<null> {
    return this.DeleteDataSourceConnectionResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Patch a data-source connection using its ID
   *
   * Patch the data-source connection specified by the ID in the request path.
   * @param params The `DataSourceConnectionService.PatchDataSourceConnectionParams` containing the following parameters:
   *
   * - `connectionId`: Specifies the unique ID of the connection which is to be patched.
   *
   * - `body`: Specifies the connection resource with the properties that can be patched.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  PatchDataSourceConnectionResponse(params: DataSourceConnectionService.PatchDataSourceConnectionParams): __Observable<__StrictHttpResponse<DataSourceConnection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/data-source-connections/${encodeURIComponent(params.connectionId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DataSourceConnection>;
      })
    );
  }
  /**
   * Patch a data-source connection using its ID
   *
   * Patch the data-source connection specified by the ID in the request path.
   * @param params The `DataSourceConnectionService.PatchDataSourceConnectionParams` containing the following parameters:
   *
   * - `connectionId`: Specifies the unique ID of the connection which is to be patched.
   *
   * - `body`: Specifies the connection resource with the properties that can be patched.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  PatchDataSourceConnection(params: DataSourceConnectionService.PatchDataSourceConnectionParams): __Observable<DataSourceConnection> {
    return this.PatchDataSourceConnectionResponse(params).pipe(
      __map(_r => _r.body as DataSourceConnection)
    );
  }

  /**
   * Get registration token for a data-source connection
   *
   * Get a token to register connectors against the data-source connection specified by the ID in the request path. The same token can be used to register multiple connectors as long as the token is valid. Once the token expires, typically in a day, this API can be hit again to generate another token.
   * @param params The `DataSourceConnectionService.GetDataSourceConnectionRegistrationTokenParams` containing the following parameters:
   *
   * - `connectionId`: Specifies the unique ID of the connection for which the registration token is to be fetched.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return The registration token.
   */
  GetDataSourceConnectionRegistrationTokenResponse(params: DataSourceConnectionService.GetDataSourceConnectionRegistrationTokenParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/data-source-connections/${encodeURIComponent(params.connectionId)}/registrationToken`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Get registration token for a data-source connection
   *
   * Get a token to register connectors against the data-source connection specified by the ID in the request path. The same token can be used to register multiple connectors as long as the token is valid. Once the token expires, typically in a day, this API can be hit again to generate another token.
   * @param params The `DataSourceConnectionService.GetDataSourceConnectionRegistrationTokenParams` containing the following parameters:
   *
   * - `connectionId`: Specifies the unique ID of the connection for which the registration token is to be fetched.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return The registration token.
   */
  GetDataSourceConnectionRegistrationToken(params: DataSourceConnectionService.GetDataSourceConnectionRegistrationTokenParams): __Observable<string> {
    return this.GetDataSourceConnectionRegistrationTokenResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module DataSourceConnectionService {

  /**
   * Parameters for GetDataSourceConnection
   */
  export interface GetDataSourceConnectionParams {

    /**
     * Specifies the ID of the tenant for which the connections are to be fetched.
     */
    tenantId?: null | string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the names of the connections which are to be fetched.
     */
    connectionNames?: null | Array<string>;

    /**
     * Specifies the unique IDs of the connections which are to be fetched.
     */
    connectionIds?: null | Array<string>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateDataSourceConnection
   */
  export interface CreateDataSourceConnectionParams {

    /**
     * Specifies the request parameters to create a connection.
     */
    body: CreateDataSourceConnectionRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteDataSourceConnection
   */
  export interface DeleteDataSourceConnectionParams {

    /**
     * Specifies the unique ID of the connection which is to be deleted.
     */
    connectionId: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for PatchDataSourceConnection
   */
  export interface PatchDataSourceConnectionParams {

    /**
     * Specifies the unique ID of the connection which is to be patched.
     */
    connectionId: string;

    /**
     * Specifies the connection resource with the properties that can be patched.
     */
    body: PatchDataSourceConnectionRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetDataSourceConnectionRegistrationToken
   */
  export interface GetDataSourceConnectionRegistrationTokenParams {

    /**
     * Specifies the unique ID of the connection for which the registration token is to be fetched.
     */
    connectionId: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { DataSourceConnectionService }
