import { Injectable } from '@angular/core';
import { SiteContinuityUser, UsersServiceApi } from '@cohesity/api/kepler';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

/**
 * Shared service for DRaaS workflows.
 */
@Injectable({ providedIn: 'root' })
export class DraasService {
  /**
   * Caches the currently logged in user's details.
   */
  private currentUser: SiteContinuityUser;

  constructor(private keplerUsersApi: UsersServiceApi) {}

  /**
   * Returns the currently logged in user's information.
   *
   * @param force If set to true, it ignores the cached info and forces API
   *   call.
   * @returns An observable of the DRaaS user object.
   */
  getCurrentUser(force = false): Observable<SiteContinuityUser> {
    if (!force && this.currentUser) {
      return of(this.currentUser);
    }

    return this.keplerUsersApi.GetCurrentUserDetails().pipe(tap(resp => (this.currentUser = resp)));
  }
}
