import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';
import { Environment } from 'src/app/shared/constants';
import { hasSourceModifyPrivilege } from 'src/app/shared/helper-utils';

const sourceModify = (ctx: StateAccessContext) =>
  ctx.PROTECTION_SOURCE_MODIFY &&
  hasSourceModifyPrivilege(ctx, 'ad') &&
  ctx.canAccessSomeEnv([Environment.kAD]);

const restoreModify = (ctx: StateAccessContext) =>
  ctx.RESTORE_MODIFY && ctx.canAccessSomeEnv([Environment.kAD]);

const protectModify = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngProtectionGroupGlobalModify &&
  ctx.FEATURE_FLAGS.ngProtectionGroupAdModify
  && ctx.PROTECTION_MODIFY;

export const ActiveDirectoryConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'active-directory-new.**',
      url: '/protection/sources/active-directory/new',
      loadChildren: () => import('./active-directory.module').then(m => m.ActiveDirectoryModule)
    },
    {
      name: 'active-directory-recovery-search.**',
      url: '/protection/recovery/active-directory/search',
      loadChildren: () => import('./active-directory.module').then(m => m.ActiveDirectoryModule)
    },
    {
      name: 'active-directory-restore-snapshot.**',
      url: '^/protection/recovery/active-directory/{id}',
      loadChildren: () => import('./active-directory.module').then(m => m.ActiveDirectoryModule),
    },
    {
      name: 'protection-builder-active-directory.**',
      url: '/protection/protect/active-directory',
      loadChildren: () => import('./active-directory.module').then(m => m.ActiveDirectoryModule),
    },
  ],
  getStateAccessMap(): StateAccessMap {
    return {
      'active-directory-new': sourceModify,
      'active-directory-recovery-search': restoreModify,
      'active-directory-restore-snapshot': restoreModify,
      'protection-builder-active-directory.create': protectModify,
      'protection-builder-active-directory.edit': protectModify,

    };
  }
};
