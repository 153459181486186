// Restore Summary Reports Controller

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.reports')
    .controller('reportsSourcesSummaryCtrl', reportsSourcesSummaryCtrlFn);

  function reportsSourcesSummaryCtrlFn($rootScope, $scope, $q, cUtils,
    evalAJAX, SourceService, CHART_COLORS, ENV_GROUPS, ENV_TYPE_CONVERSION) {

    $scope.text = $rootScope.text.reportsSourcesSummary;

    $scope.sourceListReady = false;
    $scope.sources = [];

    // disabling unprotected sources chart until the API
    // supports historical data on unprotected objects
    $scope.unprotectedSourcesChartEnabled = false;
    // Sources chart data
    $scope.sourcesChart = {
      chartType: 'donut',
      loading: true,
      titleText: 'totalSources',
      series: [{
        type: 'pie',
        name: 'jobs',
        data: []
      }],
      chart: {
        height: 225
      },
      colors: [CHART_COLORS.green, CHART_COLORS.red]
    };

    $scope.sourcesSummaryControlsConfig = {
      showReportsSelector: true,
    };

    $scope.updateData = updateData;

    // Unprotected Sources chart data
    // TODO: this chart is a placeholder until the API
    // supports historical data. Update this when data is available
    $scope.unprotectedChart = {
      chartType: 'basic',
      loading: true,
      series: [{
        name: 'Sources',
        data: [
          ['5 Jun', 51],
          ['6 Jun', 72],
          ['7 Jun', 80],
          ['8 Jun', 65],
          ['9 Jun', 25],
          ['10 Jun', 92],
          ['11 Jun', 67]
        ]
      }]
    };

    /**
     * activate controller
     */
    function activate() {
      // run setup function on first load
      updateData();
    }

    /**
     * Returns protected and unprotected count for different type of entity
     * heirarchy objects.
     *
     * @param   {object}    response    Entity heirarchy object.
     * @return  {object}    An object containing protectedCount and
     *                       unprotectedCount.
     */
    function getNumProtectedEnities(response) {
      var protectedCount = 0;
      var unprotectedCount = 0;
      if (response.entity.type === ENV_TYPE_CONVERSION.kPhysical) {
        // For physical, we need to go over each children and see if it is
        // protected or not, by checking numEntities of block/file base jobs.
        response.children.forEach(function (source) {
          var isSourceProtected = false;
          source.aggregatedProtectedInfoVec.forEach(
            function iterProtectedInfoVec(protectedObj) {
              if (protectedObj.type === ENV_TYPE_CONVERSION.kPhysical &&
                protectedObj.numEntities > 0 ||
                protectedObj.type === ENV_TYPE_CONVERSION.kPhysicalFiles &&
                protectedObj.numEntities > 0) {
                isSourceProtected = true;
              }
            });
          if (isSourceProtected) {
            protectedCount += 1;
          } else {
            unprotectedCount += 1;
          }
        });

        return {
          protectedCount: protectedCount,
          unprotectedCount: unprotectedCount,
        };
      }

      (response.aggregatedProtectedInfoVec || []).forEach(
        function iterProtectedInfoVec(protectedObj) {
          if (protectedObj.type === response.entity.type) {
            // If numEntities is undefined, assign 0.
            protectedCount = protectedObj.numEntities || 0;
          }
        });

      (response.aggregatedUnprotectedInfoVec || []).forEach(
        function iterUnprotectedInfoVec(protectedObj) {
          if (protectedObj.type === response.entity.type) {
            // If numEntities is undefined, assign 0.
            unprotectedCount = protectedObj.numEntities || 0;
          }
        });

      return {
        protectedCount: protectedCount,
        unprotectedCount: unprotectedCount,
      };
    }

    /**
     * gets the data for the API based on filters set in the sidebar,
     * and updates the chart and table data accordingly.
     */
    function updateData() {
      var apiParams = {
        envTypes: cUtils.onlyNumbers(ENV_GROUPS.all),
      };

      // reset sources list
      $scope.sourceListReady = false;
      $scope.sources = [];

      SourceService.getSources(apiParams)
        .then(function getChildrenData(sourceResult) {
          var children = sourceResult.entityHierarchy.children || [];

          if (children.length) {
            // query the API for Source Report information,
            // all thats needed from this call is is number of jobs.
            var sourceIds = children.map(function getSourceIds(source){
              return source.entity.id;
            });

            // Get the report for each sources and attach it to the
            // corresponding entities in the data structure.
            return SourceService.getSourceReport({
              sourceIds: sourceIds
            }).then(function onSuccess(resp){
              // A hashmap that points to the source report given the id.
              var reportMap = (resp.data || []).reduce(
                function addMapEntry(map, obj){
                map[obj.entityHierarchy.entity.id] = obj;
                return map;
              }, {});

              // Use the hashmap to decorate the children data struture with
              // the report data.
              return children.map(function getReport(source) {
                source._report = reportMap[source.entity.id];
                return source;
              });
            }, evalAJAX.errorMessage);
          }

          return [];
        },
        evalAJAX.errorMessage)
        .then(function processChildrenData(allResponses) {
          // total number of protected and unprotected objects,
          // used for doing math and updating our Source Protection chart
          var totalProtected = 0;
          var totalUnprotected = 0;

          allResponses.forEach(function (response) {
            var protectionCount = getNumProtectedEnities(response);
            response._normalizedEntity =
              SourceService.normalizeEntity(response.entity);
            response._numJobs = response._report.numJobs;
            response._protectedCount = protectionCount.protectedCount;
            response._unprotectedCount = protectionCount.unprotectedCount;
            totalProtected += response._protectedCount;
            totalUnprotected += response._unprotectedCount;
            $scope.sources.push(response);
          });

          // update our chart data
          $scope.sourcesChart.series[0].data = [
            [$scope.text.protected, totalProtected],
            [$scope.text.unprotected, totalUnprotected]
          ];

          // TODO: update $scope.unprotectedChart series data once
          // historical information is available via API
        })
        .finally(function () {
          $scope.sourceListReady = true;
          $scope.sourcesChart.loading = false;
        });
    }

    activate();
  }
})(angular);
