import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Tag, TagList } from '@cohesity/api/helios-metadata';
import { CdpObjectInfo } from '@cohesity/api/v2';
import { getIconTooltipKey } from '@cohesity/iris-shared-constants';
import { Memoize } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { ProtectionGroupSearchResult } from '../../model/protection-group-search-result';
import { RestorePointSelection } from '../../model/restore-point-selection';
import { SnapshotMetadataService } from '../../snapshot-selector/services/snapshot-metadata.service';

@Component({
  selector: 'coh-restore-point-selection-detail',
  templateUrl: './restore-point-selection-detail.component.html',
  styleUrls: ['./restore-point-selection-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestorePointSelectionDetailComponent implements AfterViewInit, OnChanges {
  /**
   * Event is emitted when the user chooses to edit the restore point. The edit button
   * will only be visible if an event handler is attached to the component.
   */
  @Output() editRestorePoint = new EventEmitter();

  /**
   * The object icon to show for the selection.
   */
  @Input() icon: string;

  /**
   * The selection to display
   */
  @Input() restorePointSelection: RestorePointSelection;

  /**
   * Indicates if snapshot selection will be shown or hidden
   */
  @Input() hideSnapshot = false;

  /**
   * The backup type of the selected snapshot. Used in resubmit context.
   */
  @Input() backupType: number;

  /**
   * Widelayout modifies the view so that all of the detail sections are laid
   * out in a single row.
   */
  @HostBinding('class.wide-layout') @Input() wideLayout = false;

  /**
   * A subject which yields a tags for the currently selected snapshots.
   */
  tags$ = new BehaviorSubject<TagList>(null);

  /**
   * A subject which yields a value to indicate whether the content for current recovery point is loading.
   */
  dataLoading$ = new BehaviorSubject<boolean>(false);

  /**
   * A subject which yields a value to indicate whether the current snapshot can be used for the recovery or not.
   */
  canUseForRecovery$ = new BehaviorSubject<boolean>(true);

  /**
   * Checks whethther the current restore point is a fort knox snapshot or run.
   */
  get isRpaasSelection(): boolean {
    // Snapshot will be present only if the user has manually chosen a snapshot. The default selection from search
    // or when redirected from another page will have archiveTargetInfo populated.
    return this.restorePointSelection.snapshot?.ownershipContext === 'FortKnox' ||
      this.restorePointSelection.archiveTargetInfo?.ownershipContext === 'FortKnox';
  }

  /**
   * Gets the icon to display for the selected snapshot target
   */
  get typeIcon(): string {
    if (this.restorePointSelection.archiveTargetInfo) {
      return this.restorePointSelection.archiveTargetInfo.targetType;
    }

    // If snapshot run type is 'kStorageArraySnapshot' return type icon accordingly
    if (
      this.restorePointSelection.snapshotRunType === 'kStorageArraySnapshot' ||
      this.restorePointSelection.snapshot?.runType === 'kStorageArraySnapshot' ||
      this.backupType === 6
    ) {
      return 'kStorageArraySnapshot';
    } else {
      return 'local';
    }
  }

  /**
   * Returns currentTierType of the snapshot
   */
  get currentTierType(): string {
    return this.restorePointSelection?.archiveTargetInfo?.tierSettings?.currentTierType;
  }

  /**
   * Whether to show the edit button or not.
   */
  get showEdit(): boolean {
    return !!this.editRestorePoint.observers.length;
  }

  /**
   * Get cdpObjectInfo of the VM if applicable.
   */
  get cdpInfo(): CdpObjectInfo {
    return this.restorePointSelection.objectInfo.cdpObjectInfo;
  }

  /**
   * Returns the target name for the selected snapshot.
   */
  get fromTarget(): string {
    if (this.isRpaasSelection) {
      return this.restorePointSelection.snapshot?.externalTargetInfo?.targetName ||
      this.restorePointSelection?.archiveTargetInfo.targetName;
    }

    return (
      this.restorePointSelection?.archiveTargetInfo?.targetName ||
      this.translateService.instant(
        this.restorePointSelection.snapshotRunType === 'kStorageArraySnapshot' ||
          this.restorePointSelection.snapshot?.runType === 'kStorageArraySnapshot' ||
          this.backupType === 6
          ? 'storageArraySnapshot'
          : 'local'
      )
    );
  }

  /**
   * Get the correct target type for the snapshot or run.
   */
  get targetType(): string {
    return this.restorePointSelection?.archiveTargetInfo?.targetType;
  }

  /**
   * Returns the ownership context for the snapshot or run.
   */
  get ownershipContext(): string {
    return this.restorePointSelection?.archiveTargetInfo?.ownershipContext;
  }

  /**
   * Returns true, if current selection is a protection groups
   */
  get isProtectionGroup(): boolean {
    return this.restorePointSelection.objectInfo.resultType === 'protectionGroup';
  }

  /**
   * Returns true if phase 1 for the clean room recovery is enabled.
   */
  get isCleanRoomRecoveryPhase1Enabled(): boolean {
    return this.snapshotMetadataService.isCleanRoomRecoveryPhase1Enabled;
  }

  constructor(
    private snapshotMetadataService: SnapshotMetadataService,
    private translateService: TranslateService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.restorePointSelection) {
      this.loadSnapshotMetadata();
    }
  }

  ngAfterViewInit(): void {
    this.loadSnapshotMetadata();
  }

  /**
   * Gets tooltip string for icon.
   *
   * @param   icon  Icon used in cog-icon.
   * @return  Tooltip string.
   */
  @Memoize()
  getIconTooltip(icon): string {
    return getIconTooltipKey(icon);
  }

  /**
   * Loads the tags information for the currently selected recovery point.
   *
   * @returns a subscription for tag load
   */
  loadSnapshotMetadata() {
    if (!this.isCleanRoomRecoveryPhase1Enabled) {
      return;
    }

    const snapshotIds = this.getSnapshotIds();
    if (!snapshotIds.length) {
      return;
    }

    this.dataLoading$.next(true);
    return this.snapshotMetadataService.fetchSnapshotMetadata(snapshotIds)
      .pipe(
        finalize(() => this.dataLoading$.next(false))
      )
      .subscribe((metadata) => {
        const allUniqueTags = Object.values(metadata.tags).reduce((acc, tagList) => {
          tagList.forEach((tag) => {
            acc[tag.uuid] = tag;
          });

          return acc;
        }, {} as Record<string, Tag>);

        const tags = Object.values(allUniqueTags);
        this.tags$.next(tags ?? null);

        const isNonRecoverable = snapshotIds.some((snapshotId) => metadata.recoverability[snapshotId] === false);
        this.canUseForRecovery$.next(!isNonRecoverable);
      });
  }

  /**
   * Generates list of snapshot ids based on the type of the selected entity
   *
   * @returns list of snapshot ids
   */
  getSnapshotIds() {
    const snapshotIds = [];
    switch (this.restorePointSelection.objectInfo.resultType) {
      case 'protectionGroup':
        snapshotIds.push(...this.getPGObjectSnapshotIds());
        break;
      default:
        snapshotIds.push(this.restorePointSelection.restorePointId);
        break;
    }

    return snapshotIds.filter(Boolean);
  }

  /**
   * Generates list of snapshot ids of all the objects protected by the protection group
   *
   * @returns list of snapshot ids of all the objects
   */
  getPGObjectSnapshotIds() {
    const pgInfo = this.restorePointSelection.objectInfo as ProtectionGroupSearchResult;

    return (pgInfo.objectsProtected ?? [])
      .map((object) => object.latestSnapshotInfo.localSnapshotInfo?.snapshotId)
      .filter(Boolean);
  }
}
