<dg-scan-impact-status [health]="health">
  <div class="flex-row-xs vertical-align">
    <cog-icon
      size="md"
      shape="{{ count ? 'argus:threats-found' : health?.status | scanHealthStatusIcon: true }}">
    </cog-icon>
    <span>
      {{ count
        ? ('dg.td.threatsFound' | translate: { count: (count | humanizeFromNumbers: 1) })
        : 'dg.td.noMatches' | translate
       }}
    </span>
  </div>
</dg-scan-impact-status>
