<h2>{{'unpairedClusterCount' | translate : {count: unpairedClusters.length} }}</h2>
<cog-banner *ngIf="hasVersionError" status="warn">
  {{'rpaas.softwareNotSuppportedMessage' | translate : { version: minVersion } }}
</cog-banner>

<cog-table [source]="unpairedClusters" class="standard-width">
  <table mat-table>
    <tr mat-header-row *matHeaderRowDef="['cluster', 'software', 'unpairedReason']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['cluster', 'software', 'unpairedReason'];"></tr>

    <ng-container matColumnDef="cluster">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'cluster' | translate }}
      </th>
      <td mat-cell *matCellDef="let row;">
        {{ row.clusterName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="software">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'software' | translate }}
      </th>
      <td mat-cell *matCellDef="let row;">
        {{ row.softwareVersion }}
      </td>
    </ng-container>

    <ng-container matColumnDef="unpairedReason">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'reason' | translate }}
      </th>
      <td mat-cell *matCellDef="let row;" class="message-col">
        <span  [matTooltip]="row.message">
          {{ ('rpaas.pairingStatus.' + row.unpairedReason) | translate}}
        </span>
        <span *ngIf="row.unpairedReason === 'Failed'">
          ({{ row.message }})
        </span>
      </td>
    </ng-container>
  </table>
</cog-table>

<button mat-stroked-button
  type="button"
  color="primary"
  class="retry-button"
  cogDataId="retry-cluster-pairing"
  *ngIf="allowRetry"
  (click)="retryPairing()">
  {{'rpaas.retryPairing' | translate }}
</button>
