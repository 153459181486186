<div class="dl-row" *ngIf="registrationInfo?.accessInfo?.endpoint as endpoint">
  <dt translate="kubernetesClusterEndpoint"></dt>
  <dd>{{endpoint}}</dd>
</div>

<ng-container *ngIf="kubernetesInfo">
  <div class="dl-row" *ngIf="kubernetesInfo.distribution as distribution">
    <dt translate="kubernetesDistribution"></dt>
    <dd>{{ 'enum.kubernetesDistribution.' + distribution | translate }}</dd>
  </div>
  <div class="dl-row" *ngIf="kubernetesInfo.datamoverImageLocation as datamoverImageLocation">
    <dt translate="dataMoverImage"></dt>
    <dd>{{ datamoverImageLocation }}</dd>
  </div>
  <div class="dl-row" *ngIf="kubernetesInfo.veleroOpenshiftPluginImageLocation as veleroOpenshiftPluginImageLocation">
    <dt translate="veleroOpenshiftPluginImage"></dt>
    <dd>{{ veleroOpenshiftPluginImageLocation }}</dd>
  </div>
  <div class="dl-row" *ngIf="kubernetesInfo.veleroImageLocation as veleroImageLocation">
    <dt translate="veleroImage"></dt>
    <dd>{{ veleroImageLocation }}</dd>
  </div>
  <div class="dl-row" *ngIf="kubernetesInfo.veleroAwsPluginImageLocation as veleroAwsPluginImageLocation">
    <dt translate="veleroAwsPluginImage"></dt>
    <dd>{{ veleroAwsPluginImageLocation }}</dd>
  </div>
  <div class="dl-row" *ngIf="kubernetesInfo.initContainerImageLocation as initContainerImageLocation">
    <dt translate="initContainerImage"></dt>
    <dd>{{ initContainerImageLocation }}</dd>
  </div>
</ng-container>
