<cog-card-section-filters *ngIf="config?.params?.length">
  <cog-filters class="margin-bottom" #filters>
    <ng-container *ngFor="let valueParameter of valueParameters">
      <cog-value-property-filter
        [cogDataId]="'value-' + valueParameter.name"
        [inheritDataId]="true"
        *cogFilterDef="
      let params = params;
      let filter;
      key: valueParameter.name;
      label: valueParameter.labelKey | translate;
      quickFilter: true;
      filterType: 'valueProperty'"
        [filterDefParams]="params"
        [filter]="filter"
        [allowMultiple]="valueParameter.allowMultiple"
        [autoApply]="!valueParameter.allowMultiple"
        [alphaSort]="false"
        [filterValues]="valueParameter.values">
      </cog-value-property-filter>
    </ng-container>

    <!-- Date Range Filter -->
    <ng-container *ngIf="dateRangeParameter">
      <cog-date-range-filter
        cogDataId="date-range"
        [inheritDataId]="true"
        *cogFilterDef="
        let filter;
        let params = params;
        key: dateRangeParameter.name;
        label: dateRangeParameter.labelKey | translate;
        filterType: 'dateRange';
        quickFilter: true;
        quickFilterPositionX: 'before';"
        [filterDefParams]="params"
        [filter]="filter"
        [useTimeframe]="true"
        [noClear]="true"
        [selectedRange]="dateRangeParameter.filterValue">
      </cog-date-range-filter>
    </ng-container>

    <!-- String Search Filter -->
    <ng-container *ngIf="searchParameter">
      <cog-search-property-filter
        cogDataId="string-search"
        [inheritDataId]="true"
        *cogFilterDef="let filter;
        key: searchParameter.name;
        label: searchParameter.labelKey | translate;
        quickFilter: true;
        filterType: 'searchProperty'"
        [filter]="filter"
        [placeholder]="searchParameter.labelKey | translate">
      </cog-search-property-filter>
    </ng-container>
  </cog-filters>
</cog-card-section-filters>

<cog-spinner *ngIf="loading$ | async"></cog-spinner>

<ng-container *ngIf="(loading$ | async) === false">
  <ng-container *ngIf="!config?.isTimeline">
    <cog-table
      name="{{ config.name }}-table"
      [source]="(listItems$ | async) || []"
      [selection]="config.bulkActions ? selection : null"
      [isAllSelectedFn]="isAllSelectedFn"
      [canSelectAnyRowsFn]="canSelectAnyRow"
      [canSelectRowFn]="canSelectRow">

      <!-- Table Multi action pane -->
      <cog-table-multi-action
        *ngIf="!!config.bulkActions"
        #tableActions
        allSelectedLabel="{{ 'nSelectedNg' | translate: { n: tableActions.selectedCount } }}"
        anySelectedLabel="{{ 'nSelectedNg' | translate: { n: tableActions.selectedCount } }}"
        selectAllLabel="{{ 'selectAll' | translate }}"
        clearLabel="{{ 'clearSelection' | translate }}">
        <button
          *ngFor="let bulkAction of config.bulkActions"
          mat-flat-button
          cogDataId="bulk-action-item-{{ bulkAction.labelKey }}"
          color="primary"
          (click)="bulkAction.action(selection)">
          {{bulkAction.labelKey | translate}}
        </button>
      </cog-table-multi-action>

      <!-- Table Content -->
      <table mat-table matSort matSortActive="name" matSortDirection="asc">
        <ng-container *ngFor="let column of config.columns" [matColumnDef]="column.name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="column.disableSort">
            {{ (column.labelKey || column.name) | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            <coh-list-item-column [config]="column" [row]="row"></coh-list-item-column>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>
          </th>
          <td
            mat-cell *matCellDef="let row; let i = index"
            class="mat-column-actions">
            <coh-list-item-actions
              *ngIf="row.actions?.length"
              cohStopPropagation
              cogDataId="list-item-actions-{{i}}"
              [inheritDataId]="true"
              [ariaLabel]="'actionsMenu' | translate"
              [navItems]="row.actions"
              [exposeIconCount]="config.exposedActionCount || 0">
            </coh-list-item-actions>
          </td>
        </ng-container>

        <!-- No Data Footer -->
        <ng-container matColumnDef="noData">
          <td [attr.colspan]="configColumns.length" mat-footer-cell *matFooterCellDef>
            {{'noDataAvailable' | translate}}
          </td>
        </ng-container>

        <tr
          mat-footer-row
          class="text-center"
          [class.hidden]="(listItems$ | async)?.length"
          *matFooterRowDef="['noData']">
        </tr>

        <tr mat-header-row *matHeaderRowDef="configColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: configColumns"
          [class.clickable-row]="config.rowAction"
          (click)="action(row)">
        </tr>
      </table>

      <mat-paginator
        *ngIf="(listItems$ | async)?.length > pageSize"
        [pageSize]="pageSize">
      </mat-paginator>
    </cog-table>
  </ng-container>

  <ng-container *ngIf="config?.isTimeline">
    <ng-container *ngIf="listItems$ | async; let listItems">
      <ng-container *ngIf="listItems?.length">
        <cog-activity-timeline
          [events]="listItems"
          [headerTemplate]="headerTemplate"
          [itemsTemplate]="itemsTemplate"
          [reverseSort]="true">
        </cog-activity-timeline>

        <mat-paginator
          [length]="total || listItems?.length || 0"
          [pageSize]="pageSize"
          [pageSizeOptions]="defaultPageSizeOptions"
          [pageIndex]="pageIndex"
          (page)="paginatorChange($event)">
        </mat-paginator>
      </ng-container>

      <div class="no-results" *ngIf="!listItems?.length">{{ 'noResultsFound' | translate }}</div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #headerTemplate>
  <div class="timeline-list-header">
    <ng-container *ngFor="let column of config.columns">
      <div class="timeline-list-header-col" [ngClass]="column.flex ? ('timeline-item-flex-' + column.flex) : null">
        {{ (column.labelKey || column.name) | translate }}
      </div>
    </ng-container>
    <div class="timeline-action-column"></div>
  </div>
</ng-template>

<ng-template #itemsTemplate let-items>
  <cog-activity-item
    *ngFor="let item of items; let i = index"
    [icon]="item.statusIcon"
    [iconTooltip]="'enum.taskStatus.' + item.status | translate"
    [severity]="item.severity">
    <ng-container *ngFor="let column of config.columns">
      <coh-list-item-column
        [config]="column"
        [row]="item"
        [ngClass]="column.flex ? ('timeline-item-flex-' + column.flex) : null">
      </coh-list-item-column>
    </ng-container>
    <div class="timeline-action-column">
      <coh-list-item-actions
        *ngIf="item.actions?.length"
        cohStopPropagation
        cogDataId="list-item-actions-{{i}}"
        [inheritDataId]="true"
        [ariaLabel]="'actionsMenu' | translate"
        [navItems]="item.actions"
        [exposeIconCount]="config.exposedActionCount || 0">
      </coh-list-item-actions>
    </div>
  </cog-activity-item>
</ng-template>
