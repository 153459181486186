// Module & Component Controller: cFormShutters

;(function(angular, undefined) {

  angular.module('C.formShutters', [])
    .component('cFormShutters', {
      bindings: {

        // Optionally provide a custom header string key. Default: 'Settings'
        listHeaderKey: '@?',

        // Optionally provide "edit all" string key. Default: "Edit All"
        editAllKey: '@?',

        // Optionally provide "edit" string key. Default: "Edit"
        editKey: '@?',

        // Optionally provide "close all" string key. Default: "Close All"
        closeAllKey: '@?',

        // Optionally provide "close" string key. Default: "Close"
        closeKey: '@?',

        // Optionally adjust UI for a nested set of shutters. Default: false
        nested: '<?',
      },
      require: {
        formCtrl: '^form',
      },
      transclude: true,
      controller: cFormShuttersFn,
      templateUrl: 'app/global/c-form-shutters/c-form-shutters.html',
    });


  /**
   * @ngdoc component
   * @name C.formShutters:cFormShutters
   * @function
   *
   * @description
   * Layout component that provides a means of displaying settings in a readable
   * format and allowing the user to toggle a section or sections of the
   * settings to make changes.
   *
   * @example
     <c-form-shutters id="give-your-shutter-an-id">
       <c-form-shutters-panel id="give-each-panel-an-id">
         <view>
           <!--
           This element houses the display/view state for a particular setting
           or group of settings.
           -->
           <strong>Is Enabled</strong> {{$ctrl.thing.isEnabled}}
         </view>
         <edit>
           <!--
           This element houses the form/edit state for a particular setting
           or group of settings.
           -->
           <label>
             <input type="checkbox"
               name="isEnabled"
               ng-model="$ctrl.thing.isEnabled">
             Enabled
           </label>
         </edit>
       </c-form-shutters-panel>
     </c-form-shutters>
   */
  function cFormShuttersFn($timeout) {
    var $ctrl = this;
    var panelCtrls = [];

    /**
     * Component initialization function
     *
     * @method   $onInit
     */
    $ctrl.$onInit = function $onInit() {

      $ctrl.listHeaderKey = $ctrl.listHeaderKey || 'settings';
      $ctrl.editKey = $ctrl.editKey || 'edit';
      $ctrl.closeKey = $ctrl.closeKey || 'close';
      $ctrl.editAllKey = $ctrl.editAllKey || 'editAll';
      $ctrl.closeAllKey = $ctrl.closeAllKey || 'closeAll';

      $ctrl.allPanelsOpen = false;
    };

    /**
     * Component $doCheck lifecycle hook. Runs with each digest cycle. Leveraged
     * here to trigger checkForErrors in panel controllers, and does nothing
     * if a form submit has not been attempted since our general approach is
     * to not surface form errors until first form submit is attempted.
     *
     * @method   $doCheck
     */
    $ctrl.$doCheck = function $doCheck() {
      if (!$ctrl.formCtrl.$submitted) { return; }

      panelCtrls.forEach(function loopPanels(panelCtrl) {
        panelCtrl.checkForErrors();
      });

    };

    /**
     * Toggles all panels/items open or close. Panels that are being forcedOpen
     * for validation reasons will be skipped.
     *
     * @method   toggleAll
     */
    $ctrl.toggleAll = function toggleAll() {
      $ctrl.allPanelsOpen = !$ctrl.allPanelsOpen;
      panelCtrls.forEach(function togglePanel(panelCtrl) {
        /**
         * Only toggle display boolean if the panel isn't currently forced open.
         * This prevents the panel from quickly closing on the user while she is
         * correcting form errors.
         */
        if (!panelCtrl.forcedOpen) {
          panelCtrl.toggle($ctrl.allPanelsOpen);
        }

      });
    };

    /**
     * Register a panel/item with this parent controller. This is called from
     * $onInit of each cVeListItem's controller
     *
     * @method   registerPanel
     * @param    {object}   panelCtrl   The cVeListItem instance controller
     */
    $ctrl.registerPanel = function registerPanel(panelCtrl) {
      panelCtrl.nested = $ctrl.nested;
      panelCtrls.push(panelCtrl);
    };


  }

})(angular);
