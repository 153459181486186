<cog-settings-list-item [formGroup]="formGroup"
  [invalid]="formControl.invalid"
  [label]="'pauseFutureRuns' | translate">
  <cog-settings-list-item-value-display>
    {{formControl.value.pausedFutureRunsEnabled | boolLabel}}
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor [formGroup]="pauseNoteFormGroup"
    cogDataId="settings-is-paused">
    <div class="flex-row-sm">
      <div>
        <mat-slide-toggle formControlName="pausedFutureRunsEnabled"
          [inheritDataId]="true"
          cogDataId="toggle">
          {{'pauseFutureRuns' | translate}}
        </mat-slide-toggle>
        <mat-hint>
          {{'protectionGroups.pauseJobSettingHint' | translate}}
        </mat-hint>
      </div>
      <div *ngIf="pauseNoteFormGroup.get('pausedFutureRunsEnabled')?.value &&
        flagEnabled('protectionRunPauseNoteEnabled')">
        <mat-checkbox class="add-note-checkbox"
          cogDataId="toggle-add-note"
          formControlName="addNoteEnabled">
          {{'protectionGroups.pauseFutureRunsJob.addNoteLabel' | translate}}
        </mat-checkbox>
      </div>
    </div>
    <mat-form-field
      *ngIf="pauseNoteFormGroup.get('pausedFutureRunsEnabled')?.value &&
        pauseNoteFormGroup.get('addNoteEnabled')?.value &&
        flagEnabled('protectionRunPauseNoteEnabled')">
      <mat-label>{{'note' | translate}}</mat-label>
      <input
        matInput
        [placeholder]="'protectionGroups.pauseFutureRunsJob.addNotePlaceholder' | translate"
        cogDataId="add-note-input"
        formControlName="pauseNote"
        />
        <mat-hint>
          {{ 'protectionGroups.pauseFutureRunsJob.optionalPauseNote' | translate: {
            value: noteMaxLength - (pauseNoteFormGroup.controls.pauseNote?.value?.length ?? 0)
          } }}
        </mat-hint>
      <mat-error *ngIf="pauseNoteFormGroup.controls.pauseNote.errors?.maxlength">
        {{ 'errors.maxlength' | translate: { value: noteMaxLength } }}
      </mat-error>
    </mat-form-field>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
