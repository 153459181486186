import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';

import { NgUpgradeModule } from '../ng-upgrade';
import { PipesModule } from '../pipes';
import { SqlAagDetailsPopoverComponent } from './components/sql-aag-details/sql-aag-details-popover.component';
import { SqlAagDetailsComponent } from './components/sql-aag-details/sql-aag-details.component';

const COMPONENTS = [
  SqlAagDetailsComponent,
  SqlAagDetailsPopoverComponent,
];

@NgModule({
  declarations: [ ...COMPONENTS ],
  exports: [ ...COMPONENTS ],
  imports: [
    CohesityHelixModule,
    CommonModule,
    NgUpgradeModule,
    PipesModule,
    TranslateModule,
  ],
})
export class SqlProtectionModule { }
