import { Injectable } from '@angular/core';
import { AppName, ClusterConfig } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';

import { AppServiceConfig, AuditServiceType, AppServiceType } from '../app-service.config';
import { GlobalNavService } from './global-nav.service';

/**
 * This service configures the global view configuration within iris.
 */
@Injectable({ providedIn: 'root' })
export class GlobalServiceConfig extends AppServiceConfig {
  serviceType: AppServiceType = 'global';

  appName: AppName = 'global';

  homeState = 'dashboards.global';

  get clusterConfigPartial() {
    return {
      name: this.serviceTitle,
      _allClusters: false,
      _cohesityService: false,
      _globalContext: true,
      _nonCluster: true,
    } as Partial<ClusterConfig>;
  }

  supportsGlobalSearch = true;

  navProvider = this.navService;

  auditLogTag: AuditServiceType = null;

  constructor(protected translate: TranslateService, private navService: GlobalNavService) {
    super(translate);
  }
}
