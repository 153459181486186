import {
  Attribute,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';

import { IrisNavigationService } from '../iris-navigation.service';
import { NavParams, NavOptions } from '../iris-router.models';
import { RouterLinkAdapter } from './router-link-adapter';

/**
 * This directive gets applied if the link is on an anchor tag.
 */
@Directive({
  selector: 'a[irisRouterLink]',
})
export class RouterLinkAnchorDirective {
  @HostBinding('attr.target') @Input() target: string;
}

/**
 * irisRouterLink is a drop in replacement for ui-router's uiSref that can be used in projects with angular router or
 * ui-router. It also uses IrisNavigationService to automatically remove links from elements whenever a user cannot
 * access them.
 */
@Directive({
  selector: '[irisRouterLink]',
  exportAs: 'irisRouterLink',
})
export class RouterLinkDirective implements OnInit, OnChanges, OnDestroy {
  /**
   * Indicate whether to skip addition of no access class or not if user is lacking the required privilege and
   * used when custom disabling reason tooltip is shown for the disabled links.
   */
  @Input() skipNoAccessClass = false;

  /**
   * The link, or state associated with the navigation.
   */
  @Input('irisRouterLink') link: string;

  /**
   * Url params to pass to the router.
   */
  @Input() params: NavParams;

  /**
   * Additional navigation options to pass to the router.
   */
  @Input() options: NavOptions;

  /**
   * The adapter with the router-specific implementation.
   */
  adapter: RouterLinkAdapter;

  /**
   * Cached result of IrisNavigationService.canUserAccessState for this directive.
   */
  canAccess = true;

  /**
   * Destroy subject for cleaning up subscriptions
   */
  private destroy = new Subject<void>();

  constructor(
    injector: Injector,
    @Attribute('tabindex') tabIndexAttribute: string | null | undefined,
    private navService: IrisNavigationService,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    this.adapter = navService.getRouterLinkAdapter(injector, tabIndexAttribute);
  }

  ngOnInit() {
    // check target state access when changed.
    this.adapter.targetState$
      .pipe(takeUntil(this.destroy), startWith({ state: this.link, params: this.params }))
      .subscribe(({ state, params }) => {
        // getting the raw target state name which is provided as an @Input to the uiSref directive to perform access
        // test because targetState$ will contain the future state name for any navigation to its children state eg.
        // for uiSref="protection-group.groups" targetState$ will contain 'protection-group.**'
        const toStateName = state && this.link;

        if (toStateName) {
          this.canAccess = this.navService.canUserAccessState(toStateName, params, true);

          // toggle 'no-access' class based on state access and skip adding 'no-access' class if
          // skipNoAccessClass is true.
          this.renderer.removeClass(this.elementRef.nativeElement, 'no-access');
          if (!this.canAccess) {
            const changes: SimpleChanges = {
              link: new SimpleChange(this.link, '', false),
            };
            // clearing uiSref's internal target state to prevent navigation.
            this.link = '';

            // removing href if target state is not accessible.
            if (this.elementRef.nativeElement.hasAttribute('href')) {
              this.renderer.removeAttribute(this.elementRef.nativeElement, 'href');
            }

            // skip no-access class if @Input(skipNoAccessClass) is true.
            if (!this.skipNoAccessClass) {
              this.renderer.addClass(this.elementRef.nativeElement, 'no-access');
            }
            this.ngOnChanges(changes);
          }
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.adapter.ngOnChanges(changes);
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.adapter.ngOnDestroy();
  }

  /**
   * Navigate to the state associated with the router link
   *
   * @param button Optional property from the click event.
   * @param ctrlKey Optional property from the click event.
   * @param shiftKey Optional property from the click event.
   * @param altKey Optional property from the click event.
   * @param metaKey Optional property from the click event.
   * @returns true if the navigation succeeds.
   */
  @HostListener('click', ['$event.button', '$event.ctrlKey', '$event.shiftKey', '$event.altKey', '$event.metaKey'])
  onClick(button: number, ctrlKey: boolean, shiftKey: boolean, altKey: boolean, metaKey: boolean): boolean {
    if (this.canAccess) {
      return this.adapter.go(button, ctrlKey, shiftKey, altKey, metaKey);
    }
    return false;
  }
}
