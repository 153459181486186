<cog-value-property-filter
  *cogFilterDef="
    let params = params;
    let filter;
    quickFilter: true;
    key: filterName;
    label: 'patterns' | translate;
    filterType: 'valueProperty'
  "
  [filterDefParams]="params"
  [filter]="filter"
  [allowMultiple]="true"
  [filterValues]="patternFilterValues || []"
  [inheritDataId]="true"
  [preLabel]="'patternsColon' | translate"
  cogDataId="pattern-filter">
</cog-value-property-filter>
