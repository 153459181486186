/**
 * Report version by report id, which is used for generating local storage key.
 */
export const reportLayoutVersion = {
  // Protection
  'cluster-connections': 1,
  'failures': 1,
  'm365-backup-storage-usage': 1,
  'm365-compliance-report': 1,
  'protected': 1,
  'protected-unprotected': 1,
  'protection-activity': 1,
  'protection-group-summary': 2,
  'protection-runs': 1,
  'system-protection': 1,
  'recovery': 1,
  'service-consumption': 1,

  // Storage
  'data-transferred-to-external-targets': 2,
  'object-growth': 1,
  'source-growth': 1,
  'storage-consumption-cluster': 2,
  'storage-consumption-group': 3,
  'storage-consumption-object': 1,
  'storage-consumption-organization': 3,
  'storage-consumption-storage-domain': 3,
  'storage-consumption-view': 3,
};

/**
 * Generate a local storage key for the report layout
 *
 * @param reportId  The report id
 * @param version   The report version
 * @returns         The local storage key
 */
export function getReportLayoutStorageKey(
  reportId: string,
  version = reportLayoutVersion[reportId]
): string {
  if (!version || version === 1) {
    version = '';
  }
  return `report_layout${version}_${reportId}`;
}

/**
 * Generate old local storage keys for the report layout for clean up purpose.
 * Whenever there is a new major revision of report data structure, we will
 * create a new key so that old cached report data structure is not used automatically.
 *
 * @param reportId  The report id
 * @returns         The list of local storage keys
 */
export function getOldReportLayoutStorageKeys(reportId: string): string[] {
  const version = reportLayoutVersion[reportId];
  const oldKeys = [];

  for (let i = 1; i < version; i++) {
    oldKeys.push(getReportLayoutStorageKey(reportId, i));
  }
  return oldKeys;
}
