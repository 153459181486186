import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule, dataRendererProvider } from '@cohesity/helix';

import { reportItemRendererProvider } from '../iris-reporting.model';
import { activityTypeTextRenderer } from './data-renderers/activity-type-text-renderer';
import { backupTypeTextRenderer } from './data-renderers/backup-type-text-renderer';
import { BytesChangeRendererComponent } from './data-renderers/bytes-change-renderer/bytes-change-renderer.component';
import { clusterIconRenderer } from './data-renderers/cluster-icon-renderer';
import { connectionStatusIconRenderer } from './data-renderers/connection-status-icon-renderer';
import { DelimitedTextRendererComponent } from './data-renderers/delimited-text-renderer/delimited-text-renderer.component';
import { drPlanTextRenderer } from './data-renderers/dr-plan-text-renderer';
import { enumTypeTextRenderer } from './data-renderers/enum-type-text-renderer';
import { envGroupIconRenderer } from './data-renderers/env-group-icon-renderer';
import { environmentTextRenderer } from './data-renderers/environment-text-renderer';
import { objectIconRenderer } from './data-renderers/object-icon-renderer';
import { statusIconRenderer } from './data-renderers/status-icon-renderer';
import { targetTypeIconRenderer } from './data-renderers/target-type-icon-renderer';
import { targetTypeTextRenderer } from './data-renderers/target-type-text-renderer';
import { textArrayRenderer } from './data-renderers/text-array-renderer';
import { viewIconRenderer } from './data-renderers/view-icon-renderer';
import { BubbleChartItemComponent } from './report-items/bubble-chart-item/bubble-chart-item.component';
import { ChartConfigFormComponent } from './report-items/chart-config-form/chart-config-form.component';
import { ChartItemComponent } from './report-items/chart-item/chart-item.component';
import { ColumnChartItemComponent } from './report-items/column-chart-item/column-chart-item.component';
import { DonutChartItemComponent } from './report-items/donut-chart-item/donut-chart-item.component';
import { LineChartItemComponent } from './report-items/line-chart-item/line-chart-item.component';
import { PieChartItemComponent } from './report-items/pie-chart-item/pie-chart-item.component';
import { SettingsListItemComponent } from './report-items/settings-list-item/settings-list-item.component';
import { StackedColumnChartItemComponent } from './report-items/stacked-column-chart-item/stacked-column-chart-item.component';
import { StatlistItemComponent } from './report-items/statlist-item/statlist-item.component';
import { TableItemComponent } from './report-items/table-item/table-item.component';

@NgModule({
  declarations: [
    BubbleChartItemComponent,
    BytesChangeRendererComponent,
    ColumnChartItemComponent,
    DelimitedTextRendererComponent,
    DonutChartItemComponent,
    LineChartItemComponent,
    PieChartItemComponent,
    StackedColumnChartItemComponent,
    StatlistItemComponent,
    SettingsListItemComponent,
    TableItemComponent,
    ChartItemComponent,
    ChartConfigFormComponent,
  ],
  imports: [CommonModule, CohesityHelixModule, ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' })],
  providers: [
    dataRendererProvider({
      activityType: activityTypeTextRenderer,
      backupType: backupTypeTextRenderer,
      bytesChange: BytesChangeRendererComponent,
      clusterIcon: clusterIconRenderer,
      connectionStatusIcon: connectionStatusIconRenderer,
      delimitedText: DelimitedTextRendererComponent,
      envGroupIcon: envGroupIconRenderer,
      environmentName: environmentTextRenderer,
      objectTypeIcon: objectIconRenderer,
      enumType: enumTypeTextRenderer,
      drPlanType: drPlanTextRenderer,
      statusIcon: statusIconRenderer,
      targetIcon: targetTypeIconRenderer,
      targetType: targetTypeTextRenderer,
      textArray: textArrayRenderer,
      textList: DelimitedTextRendererComponent,
      viewIcon: viewIconRenderer,
    }),
    reportItemRendererProvider({
      bubbleChartItem: {
        renderer: BubbleChartItemComponent,
        loadingHeight: '13rem',
      },
      columnChartItem: {
        renderer: ColumnChartItemComponent,
        loadingHeight: '13rem',
      },
      donutChartItem: {
        renderer: DonutChartItemComponent,
        loadingHeight: '13rem',
      },
      lineChartItem: {
        renderer: LineChartItemComponent,
        loadingHeight: '13rem',
      },
      pieChartItem: {
        renderer: PieChartItemComponent,
        loadingHeight: '13rem',
      },
      stackedColumnChartItem: {
        renderer: StackedColumnChartItemComponent,
        loadingHeight: '13rem',
      },
      statlistItem: {
        renderer: StatlistItemComponent,
        loadingHeight: '3.75rem',
      },
      settingsListItem: {
        renderer: SettingsListItemComponent,
        loadingHeight: '3.75rem',
      },
      tableItem: {
        renderer: TableItemComponent,
        loadingHeight: '13rem',
      },
      chartItem: {
        renderer: ChartItemComponent,
        loadingHeight: '13rem',
      },
    }),
  ],
})
export class CommonReportItemsModule {}
