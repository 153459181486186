import { Injectable, Injector } from '@angular/core';
import { MatLegacySnackBarConfig as MatSnackBarConfig, MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';
import { AjaxHandlerConfigService } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { QuorumErrorService } from './quorum-error.service';
import { StateManagementService } from './state-management.service';

/**
 * Ajax handler config service for customizing the default error message string.
 */
@Injectable({ providedIn: 'root' })
export class AppAjaxHandlerConfigService extends AjaxHandlerConfigService {
  stateManagementService: StateManagementService;

  constructor(
    private translate: TranslateService,
    private injector: Injector,
    private quorumErrorService: QuorumErrorService
  ) {
    super();
  }

  /**
   * Return the default error message text.
   *
   * @returns The error message text.
   */
  getErrorConnectingToServer(): string {
    return this.translate.instant('errorConnectingToServer');
  }


  /**
   * Function to handle error in the app specific config. Not returning
   * a snackbar ref here will result in the error handled in the default way.
   *
   * @param     response   The Http* response object.
   * @param     [args]     Optional MatSnackbarConfig options
   * @returns   The Snackbar reference.
   */
  handleError(response: any, args: MatSnackBarConfig): MatSnackBarRef<any> {
    if (response.name === 'QuorumError') {
      // If a quorum error, handle differently from regular API errors
      if (!this.stateManagementService) {
        this.stateManagementService = this.injector.get(StateManagementService);
      }
      return this.quorumErrorService.handleQuorumError(response, args, this.stateManagementService);
    }
  }
}
