import { Injectable } from '@angular/core';
import { IndexedObjectSnapshot, ObjectServiceApi, ObjectSnapshot } from '@cohesity/api/v2';
import { flagEnabled, IrisContextService, isDmsScope } from '@cohesity/iris-core';
import moment, { Moment } from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { PassthroughOptionsService } from 'src/app/core/services';
import { Environment, JobRunType, RecoveryAction } from 'src/app/shared/constants';

import { AnySnapshotObject, GroupedObjectSnapshot } from '../models/grouped-object-snapshot';
import { DecoratedIndexedObjectSnapshot } from '../models/indexed-object-snapshot.decorator';

/**
 * The arguments for the getSnapshots function.
 */
interface GetSnapshotsArgs {
  objectId: number;
  protectionGroupId?: string;
  fromTimeUsecs?: number;
  toTimeUsecs?: number;
  runTypes?: JobRunType[];
  objectActionKeys?: Environment[];
  allowCache?: boolean;
}

/**
 * The arguments for the MCM getSnapshots function.
 */
interface McmGetSnapshotsArgs {
  globalId: string;
  fromTimeUsecs?: number;
  toTimeUsecs?: number;
  allowCache?: boolean;
}

/**
 * The arguments for the getIndexedSnapshots function.
 */
interface GetIndexedSnapshotsArgs {
  objectId: number;
  protectionGroupId: string;
  indexedObjectName: string;
  includeIndexedSnapshotsOnly: boolean;
  objectActionKeys?: Environment[];
  allowCache?: boolean;
}

interface TimeRanges {
  startTimeUsecs: number;
  endTimeUsecs: number;
}

/**
 * Service used for searching for object snapshots
 */
@Injectable()
export class SnapshotSearchService {
  /**
   * A map containing previous search results.
   */
  cachedSearches: {
    [searchHash: string]: AnySnapshotObject[];
  } = {};

  /**
   * The current search results.
   */
  searchResults$ = new BehaviorSubject<AnySnapshotObject[]>([]);

  /**
   * Search results grouped by run id.
   */
  groupedSearchResults$ = this.searchResults$.pipe(
    map(snapshots => GroupedObjectSnapshot.groupSnapshots(snapshots || []))
  );

  /**
   * Whether a search is in progress or not.
   */
  searchPending$ = new BehaviorSubject<boolean>(false);

  /**
   * Behavior Subject of selected calendar date in calendar view.
   */
  selectedDateSubject = new BehaviorSubject<Moment>(undefined);

  /**
   * Observable of selected calendar date in calendar view.
   */
  selectedDate$ = this.selectedDateSubject.asObservable();

  /**
   * This is the restore operation type this restore service is configured for.
   * Each restore component is responsible for setting this value.
   * This is used to look up all of the environments that should be
   * included in snapshot search filters.
   */
  restoreTypes: RecoveryAction[];

  constructor(
    private irisContextService: IrisContextService,
    private objectsService: ObjectServiceApi,
    private passthroughOptionsService: PassthroughOptionsService,
  ) {}

  /**
   * Setter for Selected Date behavior subject.
   */
  setSelectedDate(date: Moment) {
    this.selectedDateSubject.next(date);
  }

  /**
   * Requests indexed snapshots for the specified object from the time range
   *
   * @param   objectId                     The object to get snapshots for.
   * @param   protectionGroupId            The group id that protected the object
   * @param   indexedObjectName            The name of the object to check.
   * @param   includeIndexedSnapshotsOnly  Whether to search only indexed snapshots.
   * @param   objectActionKeys    Specifies the object backup types.
   * @param   allowCache                   Whether to allow previously cached results.
   */
  getIndexedSnapshots({
    objectId,
    protectionGroupId,
    indexedObjectName,
    includeIndexedSnapshotsOnly = false,
    objectActionKeys,
    allowCache = true
  }: GetIndexedSnapshotsArgs) {
    const searchHash = this.hashSearchParams(
      objectId,
      0,
      0,
      protectionGroupId,
      indexedObjectName,
      includeIndexedSnapshotsOnly,
      [], // run types.
      objectActionKeys || [],
    );
    const cachedSearch = this.cachedSearches[searchHash];

    if (cachedSearch && allowCache) {
      this.searchResults$.next(cachedSearch);
      return;
    }

    this.doIndexedSearch(objectId, protectionGroupId, indexedObjectName, includeIndexedSnapshotsOnly, objectActionKeys)
      .pipe(
        // Cache the search result in case it is used again later.
        tap(objects => (this.cachedSearches[searchHash] = objects))
      )
      .subscribe(searchResults => this.searchResults$.next(searchResults));
  }

  /**
   * Requests snapshots for the specified object from the time range
   *
   * @param   objectId            The object to get snapshots for.
   * @param   protectionGroupId   The protection group ID of the snapshot.
   * @param   fromTimeUsecs       The start date to search from.
   * @param   toTimeUsecs         The end date to search to.
   * @param   runTypes            Specifies the job run types.
   * @param   objectActionKeys    Specifies the object backup types.
   * @param   allowCache          Whether to allow previously cached results.
   * @
   */
  getSnapshots({ objectId, protectionGroupId, fromTimeUsecs,
    toTimeUsecs, runTypes, objectActionKeys, allowCache = true }: GetSnapshotsArgs) {
    const searchHash = this.hashSearchParams(
      objectId,
      fromTimeUsecs,
      toTimeUsecs,
      protectionGroupId,

      // object name
      '',
      false,
      runTypes || [],

      objectActionKeys || [],
    );
    const cachedSearch = this.cachedSearches[searchHash];

    if (cachedSearch && allowCache) {
      this.searchResults$.next(cachedSearch);
      return;
    }

    this.doSearch(objectId, protectionGroupId, fromTimeUsecs, toTimeUsecs, runTypes, objectActionKeys)
      .pipe(
        // Cache the search result in case it is used again later.
        tap(objects => (this.cachedSearches[searchHash] = objects))
      )
      .subscribe(searchResults => this.searchResults$.next(searchResults));
  }

  /**
   * Requests snapshots for the specified object from the time range
   *
   * @param   globalId            The object to get snapshots for.
   * @param   fromTimeUsecs       The start date to search from.
   * @param   toTimeUsecs         The end date to search to.
   * @param   allowCache          Whether to allow previously cached results.
   */
  getMcmSnapshots({ globalId, fromTimeUsecs, toTimeUsecs, allowCache = true  }: McmGetSnapshotsArgs) {
    const searchHash = this.hashSearchParams(
      undefined,
      fromTimeUsecs,
      toTimeUsecs,
      undefined,
      undefined,
      false,
      [],
    );
    const cachedSearch = this.cachedSearches[searchHash];

    if (cachedSearch && allowCache) {
      this.searchResults$.next(cachedSearch);
      return;
    }

    this.doMcmSearch(globalId, fromTimeUsecs, toTimeUsecs)
      .pipe(
        // Cache the search result in case it is used again later.
        tap(objects => (this.cachedSearches[searchHash] = objects))
      )
      .subscribe(searchResults => this.searchResults$.next(searchResults));
  }

  /**
   * Makes the actual API call for the snapshots.
   *
   * @param   objectId                     The object to get snapshots for.
   * @param   protectionGroupId            The group id that protected the object
   * @param   indexedObjectName            The name of the object to check.
   * @param   includeIndexedSnapshotsOnly  Whether to search only indexed snapshots.
   * @param   objectActionKeys             Specifies the object backup types.
   * @returns An observable of indexed snapshots.
   */
  private doIndexedSearch(
    objectId: number,
    protectionGroupId: string,
    indexedObjectName: string,
    includeIndexedSnapshotsOnly: boolean = false,
    objectActionKeys?: Environment[]
  ): Observable<IndexedObjectSnapshot[]> {
    if (!objectId) {
      return of([]);
    }

    this.searchPending$.next(true);

    // Handle Object based snapshot fetching.
    if (isDmsScope(this.irisContextService.irisContext) || !protectionGroupId) {
      return this.objectsService.GetAllIndexedObjectSnapshots({
        objectId,
        indexedObjectName,
        includeIndexedSnapshotsOnly,
        objectActionKey: objectActionKeys?.length ?
          objectActionKeys[0] as ObjectServiceApi.GetAllIndexedObjectSnapshotsParams['objectActionKey'] : null,
        ...this.passthroughOptionsService.requestParams,
      }).pipe(
        map(result => (result.snapshots || []).map(snapshot => new DecoratedIndexedObjectSnapshot(snapshot))),
        finalize(() => this.searchPending$.next(false))
      );
    }

    // Handle Group based snapshot fetching.
    return this.objectsService
      .GetIndexedObjectSnapshots({
        protectionGroupId,
        objectId,
        indexedObjectName,
        includeIndexedSnapshotsOnly,
        ...this.passthroughOptionsService.requestParams,
      })
      .pipe(
        map(result => (result.snapshots || []).map(snapshot => new DecoratedIndexedObjectSnapshot(snapshot))),
        finalize(() => this.searchPending$.next(false))
      );
  }

  /**
   * Makes the actual API call for the snapshots.
   *
   * @param   objectId            The object to get snapshots for.
   * @param   protectionGroupId   The protection group ID of the snapshot.
   * @param   fromTimeUsecs       The start date to search from.
   * @param   toTimeUsecs         The end date to search to.
   * @param   objectActionKeys    The list of backup types for snapshots.
   *
   * @returns An observable of snapshots.
   */
  private doSearch(
    objectId: number,
    protectionGroupId?: string,
    fromTimeUsecs?: number,
    toTimeUsecs?: number,
    runTypes?: JobRunType[],
    objectActionKeys?: Environment[],
  ): Observable<ObjectSnapshot[]> {
    if (!objectId) {
      return of([]);
    }

    this.searchPending$.next(true);

    return this.objectsService
      .GetObjectSnapshots({
        id: objectId,
        fromTimeUsecs,
        toTimeUsecs,
        protectionGroupIds: [protectionGroupId],
        snapshotActions: (this.restoreTypes || []) as ObjectServiceApi.GetObjectSnapshotsParams['snapshotActions'],
        runTypes,
        objectActionKeys: objectActionKeys as ObjectServiceApi.GetObjectSnapshotsParams['objectActionKeys'],
        ...this.passthroughOptionsService.requestParams,
      })
      .pipe(
        map(result => result.snapshots),
        finalize(() => this.searchPending$.next(false))
      );
  }

  /**
   * Makes the actual API call for the snapshots.
   *
   * @param   globalId            The object to get snapshots for.
   * @param   fromTimeUsecs       The start date to search from.
   * @param   toTimeUsecs         The end date to search to.
   *
   * @returns An observable of snapshots.
   */
  private doMcmSearch(
    globalId: string,
    fromTimeUsecs?: number,
    toTimeUsecs?: number,
  ): Observable<ObjectSnapshot[]> {
    if (!globalId) {
      return of([]);
    }

    this.searchPending$.next(true);

    return this.objectsService
      .GetMcmObjectSnapshots({
        globalId,
        fromTimeUsecs,
        toTimeUsecs,
      })
      .pipe(
        // MCM Snapshots API is currently returning responses in an incorrect
        // format, this should be resolved with ENG-182599.
        map(result => (result?.snapshots || []).map(snapshot => ({
          ...snapshot,
          snapshotTimestampUsecs: snapshot.runStartTimeUsecs,
          externalTargetInfo: {},
        }))),
        finalize(() => this.searchPending$.next(false))
      );
  }

  /**
   * Returns a string hash of the search params.
   *
   * @param   objectId                     The object to get snapshots for.
   * @param   fromTimeUsecs The start date to search from.
   * @param   toTimeUsecs   The end date to search to.
   * @param   protectionGroupId            The group id that protected the object
   * @param   indexedObjectName            The name of the object to check.
   * @param   includeIndexedSnapshotsOnly  Whether to search only indexed snapshots.
   * @param   objectActionKeys    Specifies the object backup types.
   * @returns A hashed string of all of the params
   */
  private hashSearchParams(
    objectId: number,
    fromTimeUsecs: number = 0,
    toTimeUsecs: number = 0,
    protectionGroupId: string = '',
    indexedObjectName: string = '',
    includeIndexedSnapshotsOnly: boolean = false,
    runTypes: JobRunType[] = [],
    objectActionKeys: Environment[] = [],
  ): string {
    return [
      objectId,
      fromTimeUsecs,
      toTimeUsecs,
      protectionGroupId,
      indexedObjectName,
      includeIndexedSnapshotsOnly,
      runTypes.join(),
      objectActionKeys.join()
    ].join(':');
  }

  getPointsForTimeRange(): TimeRanges {
    const pointsForTimeRange = {
      startTimeUsecs: 0,
      endTimeUsecs: moment().endOf('day').valueOf() * 1000,
    };

    const optimizedPointsForTimeRange = {
      startTimeUsecs: flagEnabled(this.irisContextService.irisContext, 'pointsForTimeRangeForWeek') ?
        moment(this.selectedDateSubject.value).subtract(7, 'days').valueOf() * 1000 :
        moment(this.selectedDateSubject.value).startOf('day').valueOf() * 1000,
      endTimeUsecs: moment(this.selectedDateSubject.value).endOf('day').valueOf() * 1000,
    };

    return flagEnabled(this.irisContextService.irisContext, 'optimizedPITRecovery') ?
      optimizedPointsForTimeRange : pointsForTimeRange;
  }

  /**
   * Clears the cache.
   *
   * @param searchHash The search hash to clear the cache for.
   */
  clearCacheForSearch() {
    this.cachedSearches = {};
  }
}
