// Controller: Protection Runs Summary Report

;(function(angular, undefined) {
  'use strict';

  angular.module('C.reports')
    .controller('ReportRunsSummaryCtrl', reportRunsSummaryCtrlFn);

  function reportRunsSummaryCtrlFn(_, $state, ReportsService, evalAJAX,
    ReportsUtil, cReportsControlsUtil, ENUM_PUBLIC_STATUS,
    ENUM_PUBLIC_STATUS_LABEL_CLASSNAME) {

    // Replication and Archival columns hidden by feature enabler.
    const hiddenColumns = ReportsUtil.getConfigByKeyAsMap('reportsAjs.runsSummary.hiddenColumns');

    var $ctrl = this;

    _.assign($ctrl, {
      // Lifecycle hooks
      $onInit: $onInit,

      // Controller methods
      downloadCsv: downloadCsv,
      getData: getData,

      // Controller data
      ENUM_PUBLIC_STATUS: ENUM_PUBLIC_STATUS,
      ENUM_PUBLIC_STATUS_LABEL_CLASSNAME: ENUM_PUBLIC_STATUS_LABEL_CLASSNAME,
      loadingData: false,
      runs: [],
      hiddenColumns
    });

    /**
     * Controller initialization function.
     *
     * @method   $onInit
     */
    function $onInit() {
      $ctrl.runsSummaryConfig =
        ReportsUtil.getReportConfig('kProtectionRunsSummaryReport')();

      cReportsControlsUtil.getDefaultFilters();

      getData(cReportsControlsUtil.filters);
    }

    /**
     * Requests the report data via Service.
     *
     * @method   getData
     * @param    {object}    filters       The filters
     * @param    {boolean}   [formatCsv]   Indicates if report should be CSV
     * @return   {object}    Promise to resolve request for report data.
     */
    function getData(filters, formatCsv) {
      var params = _getParams(filters);
      // This field is set to -1 to force magneto to bypass the limiting of
      // the number of job runs returned.
      params.numRuns = -1;

      if (formatCsv) {
        params.outputFormat = 'csv';
      }

      $ctrl.runs.length = 0;
      $ctrl.loadingData = true;

      return ReportsService.getRunsSummary(params).then(
        function getReportSuccess(runs) {
          $ctrl.runs = runs;
        },
        evalAJAX.errorMessage
      ).finally(
        function getReportFinally() {
          $ctrl.loadingData = false;
        }
      );
    }

    /**
     * returns params object for api call
     *
     * @method   _getParams
     * @param     {object}    filters    filters from c-reports-controls
     * @return    {object}               filter values to be passed to API
     */
    function _getParams(filters) {
      var params = {
        startTimeUsecs: filters.timeObject.from.toUsecDate(),
        endTimeUsecs: filters.timeObject.until.toUsecDate(),

        // Don't get tasks/objects as this will will bloat API response and this
        // info isn't show in the UI.
        excludeTasks: true,
        _includeTenantInfo: true,
      };

      $state.go('.', params);

      return ReportsService.extendWithTenantParams(params);
    }

    /**
     * Request the report with in CVS format.
     *
     * @method   downloadCsv
     */
    function downloadCsv() {
      getData(cReportsControlsUtil.filters, true);
    }

  }

})(angular);
