import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

/**
 * List of day of month objects.
 */
export const dayOfMonthList = [...Array(31).keys()].map(day => ({
  label: String(day + 1),
  value: day + 1
}));

dayOfMonthList.push({
  label: 'Last Day',
  value: -1
});

/**
 * Sort function for day of month.
 */
export function dayOfMonthSort(a: number, b: number) {
  if (a === -1) {
    return 1;
  }
  if (b === -1) {
    return -1;
  }
  return a - b;
}

/**
 * This component allows user to input day of month.
 */
@Component({
  selector: 'coh-day-of-month-selector',
  styleUrls: ['./day-of-month-selector.component.scss'],
  templateUrl: './day-of-month-selector.component.html'
})
export class DayOfMonthSelectorComponent {
  /**
   * Day of month list.
   */
  dayOfMonthList = dayOfMonthList;

  /**
   * Form control for day of week selector.
   */
  @Input() control: UntypedFormControl;

  /**
   * Allow single selection only.
   */
  @Input() singleOnly = false;
}
