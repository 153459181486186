import { OverlayRef } from '@angular/cdk/overlay';
import { TemplateRef, Type } from '@angular/core';
import { Subject } from 'rxjs';


/**
 * PopoverContent is either TemplateRef or Component. It is used as content inside
 * popover.
 */
export type PopoverContent = TemplateRef<any> | Type<any>;

/**
 * PopoverRef object is returned in PopoverService open() which is used to
 * either close the popover or subscribe to close event.
 */
export class PopoverRef<T = any> {

  /**
   * Emits on close of popover
   */
  private afterClosed = new Subject<void>();

  /**
   * Observable on which components subscribe to handle close event
   */
  afterClosed$ = this.afterClosed.asObservable();

  constructor(
    public overlay: OverlayRef,
    public content: PopoverContent,
    public data: T
  ) {
    this.overlay.backdropClick().subscribe(() => {
      this.close();
    });
  }

  /**
   * Closes popover component. Emit afterclosed event
   */
  close() {
    this.overlay.dispose();
    this.afterClosed.next();
    this.afterClosed.complete();
  }
}
