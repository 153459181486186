<mat-form-field [floatLabel]="floatLabel">
  <mat-label>{{label | translate}}</mat-label>
  <mat-select
    panelClass="cog-mat-select-panel-lg"
    cogDataId="select-view"
    (valueChange)="value = $event"
    [value]="value"
    [disabled]="readOnly || !storageDomainId">
    <mat-select-trigger *ngIf="value">
      {{value.name}}
      <ng-container *ngTemplateOutlet="metaTemplate; context:{$implicit: value}">
      </ng-container>
    </mat-select-trigger>
    <mat-option *ngFor="let view of views"
      [cogDataId]="'option-' + view.name | lowercase"
      inheritDataId="true"
      [value]="view">
      {{view.name}}
      <ng-container *ngTemplateOutlet="metaTemplate; context:{$implicit: view}">
      </ng-container>
    </mat-option>
    <mat-option #add (click)="!add.disabled ? addView() : undefined"
      [disabled]="!('STORAGE_MODIFY' | userPrivileges)">
      <coh-add-item-option>
        {{addNewLabel | translate}}
      </coh-add-item-option>
    </mat-option>
  </mat-select>
</mat-form-field>

<ng-template #metaTemplate let-view>
  <ul *ngIf="showMetaData" class="c-ul-inline no-margin select-meta">
    <li *ngIf="view?.qos?.name as qosName">
      {{'qosPolicyColon' | translate}} {{("enum.qosPolicyName." + qosName) | translate}}
    </li>
  </ul>
</ng-template>
