/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClusterIdentifierList } from '../models/cluster-identifier-list';
import { GetActiveDirectoriesResponse } from '../models/get-active-directories-response';
import { UpdateActiveDirectoriesParamsRequest } from '../models/update-active-directories-params-request';
import { UpdateActiveDirectoriesParamsResponse } from '../models/update-active-directories-params-response';

@Injectable({
  providedIn: 'root',
})
export class ActiveDirectoriesApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getActiveDirectories
   */
  static readonly GetActiveDirectoriesPath = '/active-directories';

  /**
   * Get the list of on-prem Active Directories for an account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActiveDirectories()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveDirectories$Response(params?: {

    /**
     * A list of clusterIdentifierIds for which the associated ADs will be fetched.
     */
    clusterIdentifierIds?: ClusterIdentifierList;

  }): Observable<StrictHttpResponse<GetActiveDirectoriesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActiveDirectoriesApiService.GetActiveDirectoriesPath, 'get');
    if (params) {

      rb.query('clusterIdentifierIds', params.clusterIdentifierIds, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetActiveDirectoriesResponse>;
      })
    );
  }

  /**
   * Get the list of on-prem Active Directories for an account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getActiveDirectories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActiveDirectories(params?: {

    /**
     * A list of clusterIdentifierIds for which the associated ADs will be fetched.
     */
    clusterIdentifierIds?: ClusterIdentifierList;

  }): Observable<GetActiveDirectoriesResponse> {

    return this.getActiveDirectories$Response(params).pipe(
      map((r: StrictHttpResponse<GetActiveDirectoriesResponse>) => r.body as GetActiveDirectoriesResponse)
    );
  }

  /**
   * Path part for operation updateActiveDirectories
   */
  static readonly UpdateActiveDirectoriesPath = '/active-directories';

  /**
   * Authenticates and saves the given list of active directory credentials.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateActiveDirectories()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateActiveDirectories$Response(params: {
      body: UpdateActiveDirectoriesParamsRequest
  }): Observable<StrictHttpResponse<UpdateActiveDirectoriesParamsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ActiveDirectoriesApiService.UpdateActiveDirectoriesPath, 'patch');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdateActiveDirectoriesParamsResponse>;
      })
    );
  }

  /**
   * Authenticates and saves the given list of active directory credentials.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateActiveDirectories$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateActiveDirectories(params: {
      body: UpdateActiveDirectoriesParamsRequest
  }): Observable<UpdateActiveDirectoriesParamsResponse> {

    return this.updateActiveDirectories$Response(params).pipe(
      map((r: StrictHttpResponse<UpdateActiveDirectoriesParamsResponse>) => r.body as UpdateActiveDirectoriesParamsResponse)
    );
  }

}
