/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiQuorumConfiguration as __Configuration } from '../cohesity-api-quorum-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { QuorumGroupResponse } from '../models/quorum-group-response';
import { QuorumGroupDetails } from '../models/quorum-group-details';
import { QuorumGroupRequest } from '../models/quorum-group-request';
import { QuorumRequestsResponse } from '../models/quorum-requests-response';
import { QuorumRequestDetails } from '../models/quorum-request-details';
import { QuorumRequest } from '../models/quorum-request';
import { DecisionInfo } from '../models/decision-info';
import { OperationsResponse } from '../models/operations-response';
import { OperationCategories } from '../models/operation-categories';
import { GetOperationTemplatesResult } from '../models/get-operation-templates-result';
@Injectable({
  providedIn: 'root',
})
class QuorumService extends __BaseService {
  static readonly GetQuorumGroupsPath = '/groups';
  static readonly CreateQuorumGroupPath = '/groups';
  static readonly GetQuorumGroupsDetailsPath = '/groups/{id}';
  static readonly DeleteQuorumGroupPath = '/groups/{id}';
  static readonly UpdateQuorumGroupPath = '/groups/{id}';
  static readonly GetSentQuorumRequestsPath = '/sent-quorum-requests';
  static readonly DeleteQuorumRequestPath = '/sent-quorum-requests/{id}';
  static readonly GetSentQuorumRequestDetailsPath = '/sent-quorum-requests/{id}';
  static readonly GetReceivedQuorumRequestsPath = '/received-quorum-requests';
  static readonly MakeDecisionOnApplicationRequestPath = '/received-quorum-requests/{id}';
  static readonly GetReceivedQuorumRequestDetailsPath = '/received-quorum-requests/{id}';
  static readonly GetAllQuorumRequestsPath = '/all-quorum-requests';
  static readonly GetSupportedOperationsPath = '/operations';
  static readonly GetSupportedOperationsCategoriesPath = '/operations/categories';
  static readonly GetSupportedOperationTemplatesPath = '/operations/templates';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List Quorum Groups.
   *
   * Get list of quorum groups.
   * @param params The `QuorumService.GetQuorumGroupsParams` containing the following parameters:
   *
   * - `searchString`: Search string to filter the results by.
   *
   * - `operationIds`: Quorum operation ids list to filter the requests.
   *
   * - `memberSids`: Specifies list of member sids to filter the results by.
   *
   * - `isEnabled`: Quorum group status to filter the results by.
   *
   * - `includeOperationsDetails`: Include additional details about operations as part of the quorum groups.
   *
   * - `includeHeliosOnly`: If true, only helios requests/operations will be returned.
   *
   * - `clusterIdList`: Specifies list of cluster ids to filter the results by.
   *
   * - `categoryList`: Specifies list of categories to filter the results by.
   *
   * @return Success
   */
  GetQuorumGroupsResponse(params: QuorumService.GetQuorumGroupsParams): __Observable<__StrictHttpResponse<QuorumGroupResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchString != null) __params = __params.set('searchString', params.searchString.toString());
    (params.operationIds || []).forEach(val => {if (val != null) __params = __params.append('operationIds', val.toString())});
    (params.memberSids || []).forEach(val => {if (val != null) __params = __params.append('memberSids', val.toString())});
    if (params.isEnabled != null) __params = __params.set('isEnabled', params.isEnabled.toString());
    if (params.includeOperationsDetails != null) __params = __params.set('includeOperationsDetails', params.includeOperationsDetails.toString());
    if (params.includeHeliosOnly != null) __params = __params.set('includeHeliosOnly', params.includeHeliosOnly.toString());
    (params.clusterIdList || []).forEach(val => {if (val != null) __params = __params.append('clusterIdList', val.toString())});
    (params.categoryList || []).forEach(val => {if (val != null) __params = __params.append('categoryList', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QuorumGroupResponse>;
      })
    );
  }
  /**
   * List Quorum Groups.
   *
   * Get list of quorum groups.
   * @param params The `QuorumService.GetQuorumGroupsParams` containing the following parameters:
   *
   * - `searchString`: Search string to filter the results by.
   *
   * - `operationIds`: Quorum operation ids list to filter the requests.
   *
   * - `memberSids`: Specifies list of member sids to filter the results by.
   *
   * - `isEnabled`: Quorum group status to filter the results by.
   *
   * - `includeOperationsDetails`: Include additional details about operations as part of the quorum groups.
   *
   * - `includeHeliosOnly`: If true, only helios requests/operations will be returned.
   *
   * - `clusterIdList`: Specifies list of cluster ids to filter the results by.
   *
   * - `categoryList`: Specifies list of categories to filter the results by.
   *
   * @return Success
   */
  GetQuorumGroups(params: QuorumService.GetQuorumGroupsParams): __Observable<QuorumGroupResponse> {
    return this.GetQuorumGroupsResponse(params).pipe(
      __map(_r => _r.body as QuorumGroupResponse)
    );
  }

  /**
   * Create a Quorum Group.
   *
   * Create the quorum group and return the newly created quorum group object.
   * @param body Request to create a Quorum Group.
   * @return Success
   */
  CreateQuorumGroupResponse(body: QuorumGroupRequest): __Observable<__StrictHttpResponse<QuorumGroupDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QuorumGroupDetails>;
      })
    );
  }
  /**
   * Create a Quorum Group.
   *
   * Create the quorum group and return the newly created quorum group object.
   * @param body Request to create a Quorum Group.
   * @return Success
   */
  CreateQuorumGroup(body: QuorumGroupRequest): __Observable<QuorumGroupDetails> {
    return this.CreateQuorumGroupResponse(body).pipe(
      __map(_r => _r.body as QuorumGroupDetails)
    );
  }

  /**
   * List detailed info of a quorum group.
   *
   * Get detailed info of a quorum group
   * @param id Specifies a unique id of the quorum group to fetch details.
   * @return Success
   */
  GetQuorumGroupsDetailsResponse(id: string): __Observable<__StrictHttpResponse<QuorumGroupDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/groups/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QuorumGroupDetails>;
      })
    );
  }
  /**
   * List detailed info of a quorum group.
   *
   * Get detailed info of a quorum group
   * @param id Specifies a unique id of the quorum group to fetch details.
   * @return Success
   */
  GetQuorumGroupsDetails(id: string): __Observable<QuorumGroupDetails> {
    return this.GetQuorumGroupsDetailsResponse(id).pipe(
      __map(_r => _r.body as QuorumGroupDetails)
    );
  }

  /**
   * Delete a quorum group.
   *
   * Deletes a quorum group.
   * @param id Specifies a unique id of the quorum group to delete.
   */
  DeleteQuorumGroupResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/groups/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a quorum group.
   *
   * Deletes a quorum group.
   * @param id Specifies a unique id of the quorum group to delete.
   */
  DeleteQuorumGroup(id: string): __Observable<null> {
    return this.DeleteQuorumGroupResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update a Quorum Group.
   *
   * Update the quorum group and return the updated quorum group object.
   * @param params The `QuorumService.UpdateQuorumGroupParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the quorum group to update.
   *
   * - `body`: Request to update a Quorum Group.
   *
   * @return Success
   */
  UpdateQuorumGroupResponse(params: QuorumService.UpdateQuorumGroupParams): __Observable<__StrictHttpResponse<QuorumGroupDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/groups/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QuorumGroupDetails>;
      })
    );
  }
  /**
   * Update a Quorum Group.
   *
   * Update the quorum group and return the updated quorum group object.
   * @param params The `QuorumService.UpdateQuorumGroupParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the quorum group to update.
   *
   * - `body`: Request to update a Quorum Group.
   *
   * @return Success
   */
  UpdateQuorumGroup(params: QuorumService.UpdateQuorumGroupParams): __Observable<QuorumGroupDetails> {
    return this.UpdateQuorumGroupResponse(params).pipe(
      __map(_r => _r.body as QuorumGroupDetails)
    );
  }

  /**
   * List requests sent for quorum approval.
   *
   * Get list of requests sent by a user for quorum approval.
   * @param params The `QuorumService.GetSentQuorumRequestsParams` containing the following parameters:
   *
   * - `userDecision`: Filter results by the specified user decision field.
   *
   * - `searchString`: Search string to filter the results by.
   *
   * - `requestStartTimeMsecs`: Filter requests with initiated time greater than specified start timestamp in milliseconds since the epoch.
   *
   * - `requestEndTimeMsecs`: Filter requests with initiated time less than specified end timestamp in milliseconds since the epoch.
   *
   * - `maxResultsCount`: Maximum number of results to be returned.
   *
   * - `includeHeliosOnly`: If true, only helios requests/operations will be returned.
   *
   * - `executionStatus`: Filter by execution status of quorum request on cluster/helios once it is approved.
   *
   * - `clusterIdList`: Specifies list of cluster ids to filter the results by.
   *
   * - `categoryList`: Specifies list of categories to filter the results by.
   *
   * @return Success
   */
  GetSentQuorumRequestsResponse(params: QuorumService.GetSentQuorumRequestsParams): __Observable<__StrictHttpResponse<QuorumRequestsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userDecision != null) __params = __params.set('userDecision', params.userDecision.toString());
    if (params.searchString != null) __params = __params.set('searchString', params.searchString.toString());
    if (params.requestStartTimeMsecs != null) __params = __params.set('requestStartTimeMsecs', params.requestStartTimeMsecs.toString());
    if (params.requestEndTimeMsecs != null) __params = __params.set('requestEndTimeMsecs', params.requestEndTimeMsecs.toString());
    if (params.maxResultsCount != null) __params = __params.set('maxResultsCount', params.maxResultsCount.toString());
    if (params.includeHeliosOnly != null) __params = __params.set('includeHeliosOnly', params.includeHeliosOnly.toString());
    if (params.executionStatus != null) __params = __params.set('executionStatus', params.executionStatus.toString());
    (params.clusterIdList || []).forEach(val => {if (val != null) __params = __params.append('clusterIdList', val.toString())});
    (params.categoryList || []).forEach(val => {if (val != null) __params = __params.append('categoryList', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sent-quorum-requests`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QuorumRequestsResponse>;
      })
    );
  }
  /**
   * List requests sent for quorum approval.
   *
   * Get list of requests sent by a user for quorum approval.
   * @param params The `QuorumService.GetSentQuorumRequestsParams` containing the following parameters:
   *
   * - `userDecision`: Filter results by the specified user decision field.
   *
   * - `searchString`: Search string to filter the results by.
   *
   * - `requestStartTimeMsecs`: Filter requests with initiated time greater than specified start timestamp in milliseconds since the epoch.
   *
   * - `requestEndTimeMsecs`: Filter requests with initiated time less than specified end timestamp in milliseconds since the epoch.
   *
   * - `maxResultsCount`: Maximum number of results to be returned.
   *
   * - `includeHeliosOnly`: If true, only helios requests/operations will be returned.
   *
   * - `executionStatus`: Filter by execution status of quorum request on cluster/helios once it is approved.
   *
   * - `clusterIdList`: Specifies list of cluster ids to filter the results by.
   *
   * - `categoryList`: Specifies list of categories to filter the results by.
   *
   * @return Success
   */
  GetSentQuorumRequests(params: QuorumService.GetSentQuorumRequestsParams): __Observable<QuorumRequestsResponse> {
    return this.GetSentQuorumRequestsResponse(params).pipe(
      __map(_r => _r.body as QuorumRequestsResponse)
    );
  }

  /**
   * Cancel a request raised by the user.
   *
   * Deletes a quorum request that is sent by user.
   * @param id Specifies a unique id of the request to delete.
   */
  DeleteQuorumRequestResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/sent-quorum-requests/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Cancel a request raised by the user.
   *
   * Deletes a quorum request that is sent by user.
   * @param id Specifies a unique id of the request to delete.
   */
  DeleteQuorumRequest(id: string): __Observable<null> {
    return this.DeleteQuorumRequestResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get Sent Request Details.
   *
   * Get details of the quorum request sent by a user
   * @param id Specifies a unique id of the request to fetch details.
   * @return Success
   */
  GetSentQuorumRequestDetailsResponse(id: string): __Observable<__StrictHttpResponse<QuorumRequestDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/sent-quorum-requests/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QuorumRequestDetails>;
      })
    );
  }
  /**
   * Get Sent Request Details.
   *
   * Get details of the quorum request sent by a user
   * @param id Specifies a unique id of the request to fetch details.
   * @return Success
   */
  GetSentQuorumRequestDetails(id: string): __Observable<QuorumRequestDetails> {
    return this.GetSentQuorumRequestDetailsResponse(id).pipe(
      __map(_r => _r.body as QuorumRequestDetails)
    );
  }

  /**
   * List requests received for quorum approval.
   *
   * Get list of received requests awaiting user's quorum approval.
   * @param params The `QuorumService.GetReceivedQuorumRequestsParams` containing the following parameters:
   *
   * - `userDecision`: Filter results by the specified user decision field.
   *
   * - `searchString`: Search string to filter the results by.
   *
   * - `requestersList`: Specifies list of requester sids to filter the results by.
   *
   * - `requestStartTimeMsecs`: Filter requests with initiated time greater than specified start timestamp in milliseconds since the epoch.
   *
   * - `requestEndTimeMsecs`: Filter requests with initiated time less than specified end timestamp in milliseconds since the epoch.
   *
   * - `operationIds`: Quorum operation ids list to filter the requests.
   *
   * - `maxResultsCount`: Maximum number of results to be returned.
   *
   * - `includeHeliosOnly`: If true, only helios requests/operations will be returned.
   *
   * - `clusterIdList`: Specifies list of cluster ids to filter the results by.
   *
   * - `categoryList`: Specifies list of categories to filter the results by.
   *
   * @return Success
   */
  GetReceivedQuorumRequestsResponse(params: QuorumService.GetReceivedQuorumRequestsParams): __Observable<__StrictHttpResponse<QuorumRequestsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.userDecision != null) __params = __params.set('userDecision', params.userDecision.toString());
    if (params.searchString != null) __params = __params.set('searchString', params.searchString.toString());
    (params.requestersList || []).forEach(val => {if (val != null) __params = __params.append('requestersList', val.toString())});
    if (params.requestStartTimeMsecs != null) __params = __params.set('requestStartTimeMsecs', params.requestStartTimeMsecs.toString());
    if (params.requestEndTimeMsecs != null) __params = __params.set('requestEndTimeMsecs', params.requestEndTimeMsecs.toString());
    (params.operationIds || []).forEach(val => {if (val != null) __params = __params.append('operationIds', val.toString())});
    if (params.maxResultsCount != null) __params = __params.set('maxResultsCount', params.maxResultsCount.toString());
    if (params.includeHeliosOnly != null) __params = __params.set('includeHeliosOnly', params.includeHeliosOnly.toString());
    (params.clusterIdList || []).forEach(val => {if (val != null) __params = __params.append('clusterIdList', val.toString())});
    (params.categoryList || []).forEach(val => {if (val != null) __params = __params.append('categoryList', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/received-quorum-requests`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QuorumRequestsResponse>;
      })
    );
  }
  /**
   * List requests received for quorum approval.
   *
   * Get list of received requests awaiting user's quorum approval.
   * @param params The `QuorumService.GetReceivedQuorumRequestsParams` containing the following parameters:
   *
   * - `userDecision`: Filter results by the specified user decision field.
   *
   * - `searchString`: Search string to filter the results by.
   *
   * - `requestersList`: Specifies list of requester sids to filter the results by.
   *
   * - `requestStartTimeMsecs`: Filter requests with initiated time greater than specified start timestamp in milliseconds since the epoch.
   *
   * - `requestEndTimeMsecs`: Filter requests with initiated time less than specified end timestamp in milliseconds since the epoch.
   *
   * - `operationIds`: Quorum operation ids list to filter the requests.
   *
   * - `maxResultsCount`: Maximum number of results to be returned.
   *
   * - `includeHeliosOnly`: If true, only helios requests/operations will be returned.
   *
   * - `clusterIdList`: Specifies list of cluster ids to filter the results by.
   *
   * - `categoryList`: Specifies list of categories to filter the results by.
   *
   * @return Success
   */
  GetReceivedQuorumRequests(params: QuorumService.GetReceivedQuorumRequestsParams): __Observable<QuorumRequestsResponse> {
    return this.GetReceivedQuorumRequestsResponse(params).pipe(
      __map(_r => _r.body as QuorumRequestsResponse)
    );
  }

  /**
   * Make decision on a quorum request.
   *
   * Specifies the decision made to the existing request. On successful update, returns the updated request object.
   * @param params The `QuorumService.MakeDecisionOnApplicationRequestParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the request to update.
   *
   * - `body`: Request to make decision on a Quorum Request.
   *
   * @return Success
   */
  MakeDecisionOnApplicationRequestResponse(params: QuorumService.MakeDecisionOnApplicationRequestParams): __Observable<__StrictHttpResponse<QuorumRequest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/received-quorum-requests/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QuorumRequest>;
      })
    );
  }
  /**
   * Make decision on a quorum request.
   *
   * Specifies the decision made to the existing request. On successful update, returns the updated request object.
   * @param params The `QuorumService.MakeDecisionOnApplicationRequestParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the request to update.
   *
   * - `body`: Request to make decision on a Quorum Request.
   *
   * @return Success
   */
  MakeDecisionOnApplicationRequest(params: QuorumService.MakeDecisionOnApplicationRequestParams): __Observable<QuorumRequest> {
    return this.MakeDecisionOnApplicationRequestResponse(params).pipe(
      __map(_r => _r.body as QuorumRequest)
    );
  }

  /**
   * Get Received Request Details.
   *
   * Get details of received request for a user who is part of one or more quorum groups.
   * @param id Specifies a unique id of the request to fetch details.
   * @return Success
   */
  GetReceivedQuorumRequestDetailsResponse(id: string): __Observable<__StrictHttpResponse<QuorumRequestDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/received-quorum-requests/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QuorumRequestDetails>;
      })
    );
  }
  /**
   * Get Received Request Details.
   *
   * Get details of received request for a user who is part of one or more quorum groups.
   * @param id Specifies a unique id of the request to fetch details.
   * @return Success
   */
  GetReceivedQuorumRequestDetails(id: string): __Observable<QuorumRequestDetails> {
    return this.GetReceivedQuorumRequestDetailsResponse(id).pipe(
      __map(_r => _r.body as QuorumRequestDetails)
    );
  }

  /**
   * List all requests.
   *
   * Get list of all the requests sent and received by user. For Admin user, it returns all the requests raised by all the users.
   * @param params The `QuorumService.GetAllQuorumRequestsParams` containing the following parameters:
   *
   * - `searchString`: Search string to filter the results by.
   *
   * - `requestersList`: Specifies list of requester sids to filter the results by.
   *
   * - `requestStartTimeMsecs`: Filter requests with initiated time greater than specified start timestamp in milliseconds since the epoch.
   *
   * - `requestEndTimeMsecs`: Filter requests with initiated time less than specified end timestamp in milliseconds since the epoch.
   *
   * - `quorumDecision`: Filter results by the quorum decision field.
   *
   * - `maxResultsCount`: Maximum number of results to be returned.
   *
   * - `includeHeliosOnly`: If true, only helios requests/operations will be returned.
   *
   * - `executionStatus`: Filter by execution status of quorum request on cluster/helios once it is approved.
   *
   * - `clusterIdList`: Specifies list of cluster ids to filter the results by.
   *
   * - `categoryList`: Specifies list of categories to filter the results by.
   *
   * @return Success
   */
  GetAllQuorumRequestsResponse(params: QuorumService.GetAllQuorumRequestsParams): __Observable<__StrictHttpResponse<QuorumRequestsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.searchString != null) __params = __params.set('searchString', params.searchString.toString());
    (params.requestersList || []).forEach(val => {if (val != null) __params = __params.append('requestersList', val.toString())});
    if (params.requestStartTimeMsecs != null) __params = __params.set('requestStartTimeMsecs', params.requestStartTimeMsecs.toString());
    if (params.requestEndTimeMsecs != null) __params = __params.set('requestEndTimeMsecs', params.requestEndTimeMsecs.toString());
    if (params.quorumDecision != null) __params = __params.set('quorumDecision', params.quorumDecision.toString());
    if (params.maxResultsCount != null) __params = __params.set('maxResultsCount', params.maxResultsCount.toString());
    if (params.includeHeliosOnly != null) __params = __params.set('includeHeliosOnly', params.includeHeliosOnly.toString());
    if (params.executionStatus != null) __params = __params.set('executionStatus', params.executionStatus.toString());
    (params.clusterIdList || []).forEach(val => {if (val != null) __params = __params.append('clusterIdList', val.toString())});
    (params.categoryList || []).forEach(val => {if (val != null) __params = __params.append('categoryList', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/all-quorum-requests`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QuorumRequestsResponse>;
      })
    );
  }
  /**
   * List all requests.
   *
   * Get list of all the requests sent and received by user. For Admin user, it returns all the requests raised by all the users.
   * @param params The `QuorumService.GetAllQuorumRequestsParams` containing the following parameters:
   *
   * - `searchString`: Search string to filter the results by.
   *
   * - `requestersList`: Specifies list of requester sids to filter the results by.
   *
   * - `requestStartTimeMsecs`: Filter requests with initiated time greater than specified start timestamp in milliseconds since the epoch.
   *
   * - `requestEndTimeMsecs`: Filter requests with initiated time less than specified end timestamp in milliseconds since the epoch.
   *
   * - `quorumDecision`: Filter results by the quorum decision field.
   *
   * - `maxResultsCount`: Maximum number of results to be returned.
   *
   * - `includeHeliosOnly`: If true, only helios requests/operations will be returned.
   *
   * - `executionStatus`: Filter by execution status of quorum request on cluster/helios once it is approved.
   *
   * - `clusterIdList`: Specifies list of cluster ids to filter the results by.
   *
   * - `categoryList`: Specifies list of categories to filter the results by.
   *
   * @return Success
   */
  GetAllQuorumRequests(params: QuorumService.GetAllQuorumRequestsParams): __Observable<QuorumRequestsResponse> {
    return this.GetAllQuorumRequestsResponse(params).pipe(
      __map(_r => _r.body as QuorumRequestsResponse)
    );
  }

  /**
   * List supported quorum operations.
   *
   * Get supported operations by quorum service.
   * @param params The `QuorumService.GetSupportedOperationsParams` containing the following parameters:
   *
   * - `versionList`: Specifies list of api versions to filter the results by.
   *
   * - `templateList`: Specifies list of templates to filter the results by.
   *
   * - `searchString`: Search string to filter the results by.
   *
   * - `includeOnPremOperationsOnly`: If true, only cluster onprem requests/operations will be returned. If false, doesn't have any effect.
   *
   * - `includeHeliosOnly`: If true, only helios requests/operations will be returned.
   *
   * - `criticalityList`: Specifies list of criticalities to filter the results by.
   *
   * - `categoryList`: Specifies list of categories to filter the results by.
   *
   * @return Success
   */
  GetSupportedOperationsResponse(params: QuorumService.GetSupportedOperationsParams): __Observable<__StrictHttpResponse<OperationsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.versionList || []).forEach(val => {if (val != null) __params = __params.append('versionList', val.toString())});
    (params.templateList || []).forEach(val => {if (val != null) __params = __params.append('templateList', val.toString())});
    if (params.searchString != null) __params = __params.set('searchString', params.searchString.toString());
    if (params.includeOnPremOperationsOnly != null) __params = __params.set('includeOnPremOperationsOnly', params.includeOnPremOperationsOnly.toString());
    if (params.includeHeliosOnly != null) __params = __params.set('includeHeliosOnly', params.includeHeliosOnly.toString());
    (params.criticalityList || []).forEach(val => {if (val != null) __params = __params.append('criticalityList', val.toString())});
    (params.categoryList || []).forEach(val => {if (val != null) __params = __params.append('categoryList', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/operations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OperationsResponse>;
      })
    );
  }
  /**
   * List supported quorum operations.
   *
   * Get supported operations by quorum service.
   * @param params The `QuorumService.GetSupportedOperationsParams` containing the following parameters:
   *
   * - `versionList`: Specifies list of api versions to filter the results by.
   *
   * - `templateList`: Specifies list of templates to filter the results by.
   *
   * - `searchString`: Search string to filter the results by.
   *
   * - `includeOnPremOperationsOnly`: If true, only cluster onprem requests/operations will be returned. If false, doesn't have any effect.
   *
   * - `includeHeliosOnly`: If true, only helios requests/operations will be returned.
   *
   * - `criticalityList`: Specifies list of criticalities to filter the results by.
   *
   * - `categoryList`: Specifies list of categories to filter the results by.
   *
   * @return Success
   */
  GetSupportedOperations(params: QuorumService.GetSupportedOperationsParams): __Observable<OperationsResponse> {
    return this.GetSupportedOperationsResponse(params).pipe(
      __map(_r => _r.body as OperationsResponse)
    );
  }

  /**
   * List supported quorum operation categories.
   *
   * Get list of operation categories supported by quorum.
   * @return Success
   */
  GetSupportedOperationsCategoriesResponse(): __Observable<__StrictHttpResponse<OperationCategories>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/operations/categories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OperationCategories>;
      })
    );
  }
  /**
   * List supported quorum operation categories.
   *
   * Get list of operation categories supported by quorum.
   * @return Success
   */
  GetSupportedOperationsCategories(): __Observable<OperationCategories> {
    return this.GetSupportedOperationsCategoriesResponse().pipe(
      __map(_r => _r.body as OperationCategories)
    );
  }

  /**
   * List supported quorum operation templates.
   *
   * Get list of pre-defined operation templates suggested by cohesity.
   * @param params The `QuorumService.GetSupportedOperationTemplatesParams` containing the following parameters:
   *
   * - `includeOnPremOperationsOnly`: If true, only cluster onprem requests/operations will be returned. If false, doesn't have any effect.
   *
   * - `includeHeliosOnly`: If true, only helios requests/operations will be returned.
   *
   * @return success
   */
  GetSupportedOperationTemplatesResponse(params: QuorumService.GetSupportedOperationTemplatesParams): __Observable<__StrictHttpResponse<GetOperationTemplatesResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.includeOnPremOperationsOnly != null) __params = __params.set('includeOnPremOperationsOnly', params.includeOnPremOperationsOnly.toString());
    if (params.includeHeliosOnly != null) __params = __params.set('includeHeliosOnly', params.includeHeliosOnly.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/operations/templates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetOperationTemplatesResult>;
      })
    );
  }
  /**
   * List supported quorum operation templates.
   *
   * Get list of pre-defined operation templates suggested by cohesity.
   * @param params The `QuorumService.GetSupportedOperationTemplatesParams` containing the following parameters:
   *
   * - `includeOnPremOperationsOnly`: If true, only cluster onprem requests/operations will be returned. If false, doesn't have any effect.
   *
   * - `includeHeliosOnly`: If true, only helios requests/operations will be returned.
   *
   * @return success
   */
  GetSupportedOperationTemplates(params: QuorumService.GetSupportedOperationTemplatesParams): __Observable<GetOperationTemplatesResult> {
    return this.GetSupportedOperationTemplatesResponse(params).pipe(
      __map(_r => _r.body as GetOperationTemplatesResult)
    );
  }
}

module QuorumService {

  /**
   * Parameters for GetQuorumGroups
   */
  export interface GetQuorumGroupsParams {

    /**
     * Search string to filter the results by.
     */
    searchString?: string;

    /**
     * Quorum operation ids list to filter the requests.
     */
    operationIds?: Array<string>;

    /**
     * Specifies list of member sids to filter the results by.
     */
    memberSids?: Array<string>;

    /**
     * Quorum group status to filter the results by.
     */
    isEnabled?: 'Active' | 'Disabled';

    /**
     * Include additional details about operations as part of the quorum groups.
     */
    includeOperationsDetails?: boolean;

    /**
     * If true, only helios requests/operations will be returned.
     */
    includeHeliosOnly?: boolean;

    /**
     * Specifies list of cluster ids to filter the results by.
     */
    clusterIdList?: Array<number>;

    /**
     * Specifies list of categories to filter the results by.
     */
    categoryList?: Array<string>;
  }

  /**
   * Parameters for UpdateQuorumGroup
   */
  export interface UpdateQuorumGroupParams {

    /**
     * Specifies a unique id of the quorum group to update.
     */
    id: string;

    /**
     * Request to update a Quorum Group.
     */
    body: QuorumGroupRequest;
  }

  /**
   * Parameters for GetSentQuorumRequests
   */
  export interface GetSentQuorumRequestsParams {

    /**
     * Filter results by the specified user decision field.
     */
    userDecision?: 'Approved' | 'Declined' | 'Pending';

    /**
     * Search string to filter the results by.
     */
    searchString?: string;

    /**
     * Filter requests with initiated time greater than specified start timestamp in milliseconds since the epoch.
     */
    requestStartTimeMsecs?: number;

    /**
     * Filter requests with initiated time less than specified end timestamp in milliseconds since the epoch.
     */
    requestEndTimeMsecs?: number;

    /**
     * Maximum number of results to be returned.
     */
    maxResultsCount?: number;

    /**
     * If true, only helios requests/operations will be returned.
     */
    includeHeliosOnly?: boolean;

    /**
     * Filter by execution status of quorum request on cluster/helios once it is approved.
     */
    executionStatus?: 'Pending' | 'Success' | 'Failed';

    /**
     * Specifies list of cluster ids to filter the results by.
     */
    clusterIdList?: Array<number>;

    /**
     * Specifies list of categories to filter the results by.
     */
    categoryList?: Array<string>;
  }

  /**
   * Parameters for GetReceivedQuorumRequests
   */
  export interface GetReceivedQuorumRequestsParams {

    /**
     * Filter results by the specified user decision field.
     */
    userDecision?: 'Approved' | 'Declined' | 'Pending';

    /**
     * Search string to filter the results by.
     */
    searchString?: string;

    /**
     * Specifies list of requester sids to filter the results by.
     */
    requestersList?: Array<string>;

    /**
     * Filter requests with initiated time greater than specified start timestamp in milliseconds since the epoch.
     */
    requestStartTimeMsecs?: number;

    /**
     * Filter requests with initiated time less than specified end timestamp in milliseconds since the epoch.
     */
    requestEndTimeMsecs?: number;

    /**
     * Quorum operation ids list to filter the requests.
     */
    operationIds?: Array<string>;

    /**
     * Maximum number of results to be returned.
     */
    maxResultsCount?: number;

    /**
     * If true, only helios requests/operations will be returned.
     */
    includeHeliosOnly?: boolean;

    /**
     * Specifies list of cluster ids to filter the results by.
     */
    clusterIdList?: Array<number>;

    /**
     * Specifies list of categories to filter the results by.
     */
    categoryList?: Array<string>;
  }

  /**
   * Parameters for MakeDecisionOnApplicationRequest
   */
  export interface MakeDecisionOnApplicationRequestParams {

    /**
     * Specifies a unique id of the request to update.
     */
    id: string;

    /**
     * Request to make decision on a Quorum Request.
     */
    body: DecisionInfo;
  }

  /**
   * Parameters for GetAllQuorumRequests
   */
  export interface GetAllQuorumRequestsParams {

    /**
     * Search string to filter the results by.
     */
    searchString?: string;

    /**
     * Specifies list of requester sids to filter the results by.
     */
    requestersList?: Array<string>;

    /**
     * Filter requests with initiated time greater than specified start timestamp in milliseconds since the epoch.
     */
    requestStartTimeMsecs?: number;

    /**
     * Filter requests with initiated time less than specified end timestamp in milliseconds since the epoch.
     */
    requestEndTimeMsecs?: number;

    /**
     * Filter results by the quorum decision field.
     */
    quorumDecision?: 'Approved' | 'Declined' | 'Pending';

    /**
     * Maximum number of results to be returned.
     */
    maxResultsCount?: number;

    /**
     * If true, only helios requests/operations will be returned.
     */
    includeHeliosOnly?: boolean;

    /**
     * Filter by execution status of quorum request on cluster/helios once it is approved.
     */
    executionStatus?: 'Pending' | 'Success' | 'Failed';

    /**
     * Specifies list of cluster ids to filter the results by.
     */
    clusterIdList?: Array<number>;

    /**
     * Specifies list of categories to filter the results by.
     */
    categoryList?: Array<string>;
  }

  /**
   * Parameters for GetSupportedOperations
   */
  export interface GetSupportedOperationsParams {

    /**
     * Specifies list of api versions to filter the results by.
     */
    versionList?: Array<string>;

    /**
     * Specifies list of templates to filter the results by.
     */
    templateList?: Array<string>;

    /**
     * Search string to filter the results by.
     */
    searchString?: string;

    /**
     * If true, only cluster onprem requests/operations will be returned. If false, doesn't have any effect.
     */
    includeOnPremOperationsOnly?: boolean;

    /**
     * If true, only helios requests/operations will be returned.
     */
    includeHeliosOnly?: boolean;

    /**
     * Specifies list of criticalities to filter the results by.
     */
    criticalityList?: Array<string>;

    /**
     * Specifies list of categories to filter the results by.
     */
    categoryList?: Array<string>;
  }

  /**
   * Parameters for GetSupportedOperationTemplates
   */
  export interface GetSupportedOperationTemplatesParams {

    /**
     * If true, only cluster onprem requests/operations will be returned. If false, doesn't have any effect.
     */
    includeOnPremOperationsOnly?: boolean;

    /**
     * If true, only helios requests/operations will be returned.
     */
    includeHeliosOnly?: boolean;
  }
}

export { QuorumService }
