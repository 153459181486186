<form [formGroup]="formGroup"
  cohValidateOnSubmit (validatedSubmit)="onRetry()">
  <h1 *ngIf="title" class="retry-connection-title" mat-dialog-title>
    <span class="connector-icon">
      <cog-icon shape="helix:connector"></cog-icon>
    </span>
    <span>{{ title | translate }}</span>
  </h1>

  <mat-dialog-content>
    <div class="col-xs-12 connection-details">
      <div class="col-xs-6">
        {{ 'region' | label: data.rigelCloudInfraInfo?.awsRigelInfraInfo?.regionId | translate }}
      </div>
      <div class="col-xs-6">
        {{ 'subnet' | label: data.rigelCloudInfraInfo?.awsRigelInfraInfo?.subnetId  }}
      </div>
    </div>
    <div class="col-xs-12 padding-left connection-details">
      {{ 'securityGroup' | label: data.rigelCloudInfraInfo?.awsRigelInfraInfo?.securityGroupId  }}
    </div>
    <div class="col-xs-12 connection-details">
      <div *ngIf="formattedDns" class="col-xs-6" >
        {{ 'sources.aws.dns' | label: formattedDns }}
      </div>
      <div *ngIf="formattedNtp" class="col-xs-6">
        {{ 'ntpServers' | label: formattedNtp }}
      </div>
    </div>
    <div *ngIf="formattedTags" class="col-xs-12 padding-left connection-details">
      {{ 'tags' | label: formattedTags }}
    </div>
    <div class="col-xs-6 padding-left connection-details">
      <mat-form-field>
        <mat-label>{{'numberOfConnectors' | translate}}</mat-label>
        <input matInput
          type="number"
          max="15"
          min="1"
          formControlName="numConnectors"
          cogDataId="number-of-connectors">
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button
      cogCancelButton
      cogDataId="retry-confirmation-cancel-action"
      type="button"
      mat-button
      color="basic"
      mat-dialog-close>
      {{ cancelLabel | translate }}
    </button>
    <button cogSubmitButton
      cogDataId="retry-confirmation-click-action"
      color="primary"
      type="submit"
      mat-flat-button>
      {{ confirmLabel | translate }}
    </button>
  </mat-dialog-actions>
</form>
