<div>
  <h1 class="mat-dialog-title">{{ 'includeAlreadyProtectedObjects' | translate }}</h1>

  <div class="mat-dialog-content">
    <div class="simple-dialog-copy">
      {{ 'overwriteProtectionChallenge' | translate }}
    </div>
  </div>

  <div mat-dialog-actions align="end" class="mat-dialog-actions">
    <button mat-button
      cogDataId="button-simple-dialog-decline"
      color="primary"
      (click)="close()">
      {{ 'exclude' | translate }}
    </button>

    <button mat-flat-button
      cogDataId="button-simple-dialog-confirm"
      color="primary"
      (click)="close(true)">
      {{ 'include' | translate }}
    </button>
  </div>
</div>