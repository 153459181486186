// Component: Instance deployment details

(function (angular) {
  'use strict';

  angular.module('C.appsManagement').component('instanceDeploymentDetails', {
    templateUrl: 'app/apps-management/instance-deployment-details/instance-deployment-details.html',
    controller: instanceDeploymentDetailsCtrlFn,
    bindings: {
      // @type   {object}   App instance object
      instance: '<',
    },
  });

  /**
   * @ngdoc component
   * @name C.appsManagement:instanceDeploymentDetails
   * @function instanceDeploymentDetailsCtrlFn
   *
   * @description
   * Displays deployment details of application instance
   *
   * @example
     <instance-deployment-details
       instance="$ctrl.instance">
     </instance-deployment-details>
   */

  function instanceDeploymentDetailsCtrlFn(_, cMessage) {
    var $ctrl = this;

    _.assign($ctrl, {
      vmGroupToggleMap: {},

      // Methods
      clipboardMessage: clipboardMessage,
      toggleVmGroup: toggleVmGroup,
    });

    /**
     * Toggle vm group info.
     *
     * @param   {Object}   vmGroup   vmGroup object.
     */
    function toggleVmGroup(vmGroup) {
      $ctrl.vmGroupToggleMap[vmGroup.name]
        = !$ctrl.vmGroupToggleMap[vmGroup.name];
    }

    /**
     * Displays a success message to indicate that text was copied to
     * the clipboard successfully.
     *
     * @param   {string}   text   text for copying.
     */
    function clipboardMessage(text) {
      cMessage.success({
        textKey: 'copiedToClipboard',
        textKeyContext: { item: text },
      });
    }
  }
})(angular);
