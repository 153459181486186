// COMPONENT: $httpInterceptors for mocking data

import { getUserTenantId } from '@cohesity/iris-core';
import { environment } from 'src/environments/environment';

;(function(angular) {
  'use strict';

  angular
    .module('C.outputFormatInterceptor', [])
    .factory('outputFormatInterceptor', outputFormatInterceptorFn)
    .config(outputFormatInterceptorConfigFn);

  function outputFormatInterceptorConfigFn($httpProvider) {
    $httpProvider.interceptors.push('outputFormatInterceptor');
  }

  function outputFormatInterceptorFn(_, $window, $httpParamSerializer, $log,
    RemoteAccessClusterService, $injector) {
    var interceptor = {
      request: outputFormatCheck,
    };

    /**
     * Request Interceptor to modify the request when output format is defined.
     *
     * @method   outputFormatCheck
     * @param    {object}   config   HTTP Request Configuration Object
     * @return   {object}            Modified HTTP Request Object
     */
    function outputFormatCheck(config) {
      // Using injector as TenantService is causing a circular dependency
      // because $http needs -> outputFormatInterceptor needs -> TenantService needs -> $http
      // and to break that circular dependency we need to create a new service
      // which holds the shared things b/w outputFormatInterceptor &
      // TenantService to break the chain.
      var NgTenantService = $injector.get('NgTenantService');
      var TenantService = $injector.get('TenantService');
      var ngIrisContextService = $injector.get('NgIrisContextService');
      var csvUrl;

      // there are some APIs that support tenantId filter but most of them
      // support tenantIds filter because of API nature, performance limitation
      // or semantic API design eg.
      // below API support tenantId filter only.
      // 1. GET /auditLogs/cluster API
      // 2. GET /searchfiles API using Librarian
      // ...
      // below API support tenantIds filter only.
      // 1. GET /public/protectionJobs API
      // 2. GET /restoretasks API
      // ...
      var tenantIdParamName = _.get(config, 'params._useSingularTenantFilter') ?
        'tenantId' : 'tenantIds';

      // if the outputFormat param is defined, pop the request in a new tab
      // so the file will be downloaded by the user
      // For Helios, we will be forwarding the request to the Cluster.
      if (_.get(config, 'params.outputFormat') === 'csv') {
        // If the user is impersonating a tenant then add the tenantId param
        // to configure the required csvUrl.
        if (NgTenantService.impersonatedTenant) {
          if (environment.heliosInFrame) {
            config.params[tenantIdParamName] = getUserTenantId(ngIrisContextService.irisContext);
          } else {
            config.params[tenantIdParamName] = NgTenantService.impersonatedTenantId;
          }
        } else if (TenantService.isTenantAccountSwitchable()) {
          // If the tenant user is switching between tenant then add the header
          // key to the query param to make it work.
          config.params[TenantService.switchAccountHeader] =
            TenantService.getSelectedAccountId();
        }
        RemoteAccessClusterService.addClusterIdParam(config.params);

        csvUrl = [config.url, '?', $httpParamSerializer(config.params)].join('');

        $log.info('Download url for Remote cluster', csvUrl);
        $window.open(csvUrl, '_blank');

        // remove the outputFormat and continue the request as normal.
        // this will allow any promise resolutions to work as expected
        // just in case someone is expecting it.
        delete config.params.outputFormat;
      }

      return config;
    }

    return interceptor;
  }
})(angular);
