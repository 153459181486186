<div class="content-header">
  <coh-address-bar
    [currentPath]="fbContext.currentPath"
    [getMenuItemsFn]="fbContext.getMenuItems"
    (browseToPath)="fbContext.browseToPath($event)">
  </coh-address-bar>
</div>
<div class="content-listing">
  <coh-directory-list
    [documents]="fbContext.currentDirectory$ | async"
    [isPartialListing]="!!fbContext.directoryCookie"
    [selection]="selection"
    [isLoading]="fbContext.isLoading$ | async"
    [onlySelectFolders]="true"
    [canSelectRowFn]="canSelectItemInDirectoryListFn"
    [canNavigateRowFn]="canNavigateItemInDirectoryListFn"
    (browseToPath)="fbContext.browseToPath($event)"
    (loadMore)="fbContext.browseToPath(fbContext.currentPath, true)">
  </coh-directory-list>
</div>