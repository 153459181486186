/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ClusterClaimTokensMetadata } from '../models/cluster-claim-tokens-metadata';
import { ClusterClaimToken } from '../models/cluster-claim-token';
import { CreateMcmClaimTokenRequest } from '../models/create-mcm-claim-token-request';
@Injectable({
  providedIn: 'root',
})
class ClusterRegistrationService extends __BaseService {
  static readonly GetMcmClaimTokenPath = '/mcm/claim-tokens';
  static readonly CreateMcmClaimTokenPath = '/mcm/claim-tokens';
  static readonly DeleteMcmClaimTokenPath = '/mcm/claim-tokens/{uuid}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Fetch claim tokens for an account
   *
   * Specifies the request to get a list of claim tokens for a particular user account. This returns list of unexpired and unused tokens only.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Specifies the response for getting created cluster claim tokens.
   */
  GetMcmClaimTokenResponse(regionId?: string): __Observable<__StrictHttpResponse<ClusterClaimTokensMetadata>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/claim-tokens`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterClaimTokensMetadata>;
      })
    );
  }
  /**
   * Fetch claim tokens for an account
   *
   * Specifies the request to get a list of claim tokens for a particular user account. This returns list of unexpired and unused tokens only.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Specifies the response for getting created cluster claim tokens.
   */
  GetMcmClaimToken(regionId?: string): __Observable<ClusterClaimTokensMetadata> {
    return this.GetMcmClaimTokenResponse(regionId).pipe(
      __map(_r => _r.body as ClusterClaimTokensMetadata)
    );
  }

  /**
   * Generate a cluster claim token
   *
   * Specifies the request to generate a claim token that can be used to claim a cluster. The default expiry time of the token is 15 minutes. An account will be rate limited to 32 active tokens. Once the cluster is registered using the token, Helios will expire the token and cannot be used anymore.
   * @param body Specifies the request parameters to create a claim token.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Specifies the cluster claim token create response.
   */
  CreateMcmClaimTokenResponse(body: CreateMcmClaimTokenRequest,
    regionId?: string): __Observable<__StrictHttpResponse<ClusterClaimToken>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/claim-tokens`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterClaimToken>;
      })
    );
  }
  /**
   * Generate a cluster claim token
   *
   * Specifies the request to generate a claim token that can be used to claim a cluster. The default expiry time of the token is 15 minutes. An account will be rate limited to 32 active tokens. Once the cluster is registered using the token, Helios will expire the token and cannot be used anymore.
   * @param body Specifies the request parameters to create a claim token.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Specifies the cluster claim token create response.
   */
  CreateMcmClaimToken(body: CreateMcmClaimTokenRequest,
    regionId?: string): __Observable<ClusterClaimToken> {
    return this.CreateMcmClaimTokenResponse(body, regionId).pipe(
      __map(_r => _r.body as ClusterClaimToken)
    );
  }

  /**
   * Delete a cluster claim token by token UUID
   *
   * Specifies the request to delete a cluster claim token.
   * @param uuid Specifies the token UUID which needs to be deleted.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  DeleteMcmClaimTokenResponse(uuid: string,
    regionId?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/mcm/claim-tokens/${encodeURIComponent(uuid)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a cluster claim token by token UUID
   *
   * Specifies the request to delete a cluster claim token.
   * @param uuid Specifies the token UUID which needs to be deleted.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  DeleteMcmClaimToken(uuid: string,
    regionId?: string): __Observable<null> {
    return this.DeleteMcmClaimTokenResponse(uuid, regionId).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ClusterRegistrationService {
}

export { ClusterRegistrationService }
