/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { MultipleViewsUpdateSuccessFailureIds } from '../models/multiple-views-update-success-failure-ids';
import { MigrateS3Views } from '../models/migrate-s3views';
import { GetNlmLocksResult } from '../models/get-nlm-locks-result';
import { ClearNlmLockRequest } from '../models/clear-nlm-lock-request';
import { QosPoliciesResult } from '../models/qos-policies-result';
import { Shares } from '../models/shares';
import { Share } from '../models/share';
import { UpdateShareParam } from '../models/update-share-param';
import { SmbFileOpens } from '../models/smb-file-opens';
import { CloseSmbFileOpenParams } from '../models/close-smb-file-open-params';
import { ViewClients } from '../models/view-clients';
import { ViewClientsSummary } from '../models/view-clients-summary';
import { GetViewTemplatesResult } from '../models/get-view-templates-result';
import { Template } from '../models/template';
import { GetViewsResult } from '../models/get-views-result';
import { View } from '../models/view';
import { CreateViewRequest } from '../models/create-view-request';
import { ViewsSummary } from '../models/views-summary';
import { CloneViewDirectoryParams } from '../models/clone-view-directory-params';
import { CloneViewParams } from '../models/clone-view-params';
import { ViewDirectoryQuotas } from '../models/view-directory-quotas';
import { ViewDirectoryQuota } from '../models/view-directory-quota';
import { FileLockStatus } from '../models/file-lock-status';
import { LockFileParams } from '../models/lock-file-params';
import { OverwriteViewParams } from '../models/overwrite-view-params';
import { ViewUserQuotas } from '../models/view-user-quotas';
import { ViewUserQuotaSettings } from '../models/view-user-quota-settings';
import { UserQuotaOverrides } from '../models/user-quota-overrides';
import { UserQuotaDeleteParams } from '../models/user-quota-delete-params';
import { UserQuota } from '../models/user-quota';
import { QuotaPolicy } from '../models/quota-policy';
@Injectable({
  providedIn: 'root',
})
class ViewService extends __BaseService {
  static readonly MigrateS3ViewsPath = '/file-services/migrate-s3-views';
  static readonly GetNlmLocksPath = '/file-services/nlm-locks';
  static readonly ClearNlmLocksPath = '/file-services/nlm-locks';
  static readonly GetQosPoliciesPath = '/file-services/qos-policies';
  static readonly GetSharesPath = '/file-services/shares';
  static readonly CreateSharePath = '/file-services/shares';
  static readonly UpdateSharePath = '/file-services/shares/{name}';
  static readonly DeleteSharePath = '/file-services/shares/{name}';
  static readonly ListSmbFileOpensPath = '/file-services/smb-file-opens';
  static readonly CloseSmbFileOpenPath = '/file-services/smb-file-opens';
  static readonly GetViewClientsPath = '/file-services/view-clients';
  static readonly GetViewClientsSummaryPath = '/file-services/view-clients/summary';
  static readonly ReadViewTemplatesPath = '/file-services/view-template';
  static readonly CreateViewTemplatePath = '/file-services/view-template';
  static readonly ReadViewTemplateByIdPath = '/file-services/view-template/{id}';
  static readonly UpdateViewTemplatePath = '/file-services/view-template/{id}';
  static readonly DeleteViewTemplatePath = '/file-services/view-template/{id}';
  static readonly GetViewsPath = '/file-services/views';
  static readonly CreateViewPath = '/file-services/views';
  static readonly GetViewsSummaryPath = '/file-services/views-summary';
  static readonly CloneViewDirectoryPath = '/file-services/views/clone-directory';
  static readonly GetViewByIdPath = '/file-services/views/{id}';
  static readonly UpdateViewPath = '/file-services/views/{id}';
  static readonly DeleteViewPath = '/file-services/views/{id}';
  static readonly CloneViewPath = '/file-services/views/{id}/clone';
  static readonly GetViewDirectoryQuotasPath = '/file-services/views/{id}/directory-quotas';
  static readonly UpdateViewDirectoryQuotaPath = '/file-services/views/{id}/directory-quotas';
  static readonly DeleteViewDirectoryQuotaPath = '/file-services/views/{id}/directory-quotas';
  static readonly GetFileLockStatusPath = '/file-services/views/{id}/file-lock';
  static readonly LockFilePath = '/file-services/views/{id}/file-lock';
  static readonly OverwriteViewPath = '/file-services/views/{id}/overwrite';
  static readonly GetViewUserQuotasPath = '/file-services/views/{viewId}/user-quotas';
  static readonly UpdateViewUserQuotaSettingsPath = '/file-services/views/{viewId}/user-quotas';
  static readonly AddViewUserQuotaOverridesPath = '/file-services/views/{viewId}/user-quotas';
  static readonly DeleteViewUserQuotaOverridesPath = '/file-services/views/{viewId}/user-quotas';
  static readonly UpdateViewUserQuotaOverridePath = '/file-services/views/{viewId}/user-quotas/{userId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Migrate S3 Views.
   *
   * Migrate S3 Views from S3 1.0 to 2.0.
   * @param params The `ViewService.MigrateS3ViewsParams` containing the following parameters:
   *
   * - `body`: Specifies the request body to Migrate S3 Views.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  MigrateS3ViewsResponse(params: ViewService.MigrateS3ViewsParams): __Observable<__StrictHttpResponse<MultipleViewsUpdateSuccessFailureIds>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/file-services/migrate-s3-views`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MultipleViewsUpdateSuccessFailureIds>;
      })
    );
  }
  /**
   * Migrate S3 Views.
   *
   * Migrate S3 Views from S3 1.0 to 2.0.
   * @param params The `ViewService.MigrateS3ViewsParams` containing the following parameters:
   *
   * - `body`: Specifies the request body to Migrate S3 Views.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  MigrateS3Views(params: ViewService.MigrateS3ViewsParams): __Observable<MultipleViewsUpdateSuccessFailureIds> {
    return this.MigrateS3ViewsResponse(params).pipe(
      __map(_r => _r.body as MultipleViewsUpdateSuccessFailureIds)
    );
  }

  /**
   * Get NLM locks.
   *
   * Get the list of NLM locks in the views.
   * @param params The `ViewService.GetNlmLocksParams` containing the following parameters:
   *
   * - `viewName`: Specifies the name of the View in which to search.
   *   If a view name is not specified, all the views in the Cluster
   *   is searched. This field is mandatory if filePath field is specified.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `maxCount`: Specifies the maximum number of NLM locks to return in the response.
   *   By default, maxCount is set to 1000. At any given instance, maxCount
   *   value cannot be set to more than 1000.
   *
   * - `filePath`: Specifies the filepath in the view relative to the root filesystem.
   *   If this field is specified, viewName field must also be specified.
   *
   * - `cookie`: Specifies the pagination cookie. If this is set, next set of locks
   *   just after the previous response are returned. If this is
   *   not set, first set of NLM locks are returned."
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetNlmLocksResponse(params: ViewService.GetNlmLocksParams): __Observable<__StrictHttpResponse<GetNlmLocksResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.viewName != null) __params = __params.set('viewName', params.viewName.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.maxCount != null) __params = __params.set('maxCount', params.maxCount.toString());
    if (params.filePath != null) __params = __params.set('filePath', params.filePath.toString());
    if (params.cookie != null) __params = __params.set('cookie', params.cookie.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/file-services/nlm-locks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetNlmLocksResult>;
      })
    );
  }
  /**
   * Get NLM locks.
   *
   * Get the list of NLM locks in the views.
   * @param params The `ViewService.GetNlmLocksParams` containing the following parameters:
   *
   * - `viewName`: Specifies the name of the View in which to search.
   *   If a view name is not specified, all the views in the Cluster
   *   is searched. This field is mandatory if filePath field is specified.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `maxCount`: Specifies the maximum number of NLM locks to return in the response.
   *   By default, maxCount is set to 1000. At any given instance, maxCount
   *   value cannot be set to more than 1000.
   *
   * - `filePath`: Specifies the filepath in the view relative to the root filesystem.
   *   If this field is specified, viewName field must also be specified.
   *
   * - `cookie`: Specifies the pagination cookie. If this is set, next set of locks
   *   just after the previous response are returned. If this is
   *   not set, first set of NLM locks are returned."
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetNlmLocks(params: ViewService.GetNlmLocksParams): __Observable<GetNlmLocksResult> {
    return this.GetNlmLocksResponse(params).pipe(
      __map(_r => _r.body as GetNlmLocksResult)
    );
  }

  /**
   * Clear NLM locks.
   *
   * Clear NLM locks that match the filter criteria specified using parameters
   * @param params The `ViewService.ClearNlmLocksParams` containing the following parameters:
   *
   * - `body`: Request to clear NLM lock.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  ClearNlmLocksResponse(params: ViewService.ClearNlmLocksParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/file-services/nlm-locks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Clear NLM locks.
   *
   * Clear NLM locks that match the filter criteria specified using parameters
   * @param params The `ViewService.ClearNlmLocksParams` containing the following parameters:
   *
   * - `body`: Request to clear NLM lock.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  ClearNlmLocks(params: ViewService.ClearNlmLocksParams): __Observable<null> {
    return this.ClearNlmLocksResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get QoS Policies.
   *
   * Get the list of QoS policies on the Cohesity cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetQosPoliciesResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<QosPoliciesResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/file-services/qos-policies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QosPoliciesResult>;
      })
    );
  }
  /**
   * Get QoS Policies.
   *
   * Get the list of QoS policies on the Cohesity cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetQosPolicies(regionId?: string,
    accessClusterId?: number): __Observable<QosPoliciesResult> {
    return this.GetQosPoliciesResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as QosPoliciesResult)
    );
  }

  /**
   * Get Shares.
   *
   * If no parameters are specified, all shares on the Cohesity Cluster are returned. Specifying share name/prefix filters the results that are returned. NOTE: If maxCount is set and the number of Shares returned exceeds the maxCount, there are more Share to return. To get the next set of Views, send another request and specify the pagination cookie from the previous response. If maxCount is not specified, the first 2000 Shares.
   * @param params The `ViewService.GetSharesParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `name`: Specifies the Share name.
   *
   * - `maxCount`: Specifies a limit on the number of Shares returned. If maxCount is not specified, the first 2000 Shares.
   *
   * - `matchPartialName`: If true, the share name is matched by any partial rather than exactly matched.
   *
   * - `includeTenants`: IncludeTenants specifies if objects of all the tenants under the hierarchy of the logged in user's organization should be returned.
   *
   * - `cookie`: Specifies the pagination cookie. Expected to be empty in the first call to the API. To get the next set of results, set this value to the pagination cookie value returned in the response of the previous call.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetSharesResponse(params: ViewService.GetSharesParams): __Observable<__StrictHttpResponse<Shares>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.maxCount != null) __params = __params.set('maxCount', params.maxCount.toString());
    if (params.matchPartialName != null) __params = __params.set('matchPartialName', params.matchPartialName.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.cookie != null) __params = __params.set('cookie', params.cookie.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/file-services/shares`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Shares>;
      })
    );
  }
  /**
   * Get Shares.
   *
   * If no parameters are specified, all shares on the Cohesity Cluster are returned. Specifying share name/prefix filters the results that are returned. NOTE: If maxCount is set and the number of Shares returned exceeds the maxCount, there are more Share to return. To get the next set of Views, send another request and specify the pagination cookie from the previous response. If maxCount is not specified, the first 2000 Shares.
   * @param params The `ViewService.GetSharesParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `name`: Specifies the Share name.
   *
   * - `maxCount`: Specifies a limit on the number of Shares returned. If maxCount is not specified, the first 2000 Shares.
   *
   * - `matchPartialName`: If true, the share name is matched by any partial rather than exactly matched.
   *
   * - `includeTenants`: IncludeTenants specifies if objects of all the tenants under the hierarchy of the logged in user's organization should be returned.
   *
   * - `cookie`: Specifies the pagination cookie. Expected to be empty in the first call to the API. To get the next set of results, set this value to the pagination cookie value returned in the response of the previous call.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetShares(params: ViewService.GetSharesParams): __Observable<Shares> {
    return this.GetSharesResponse(params).pipe(
      __map(_r => _r.body as Shares)
    );
  }

  /**
   * Create a Share.
   *
   * Create a Share.
   * @param params The `ViewService.CreateShareParams` containing the following parameters:
   *
   * - `body`: Specifies the request to create a Share.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateShareResponse(params: ViewService.CreateShareParams): __Observable<__StrictHttpResponse<Share>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/file-services/shares`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Share>;
      })
    );
  }
  /**
   * Create a Share.
   *
   * Create a Share.
   * @param params The `ViewService.CreateShareParams` containing the following parameters:
   *
   * - `body`: Specifies the request to create a Share.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateShare(params: ViewService.CreateShareParams): __Observable<Share> {
    return this.CreateShareResponse(params).pipe(
      __map(_r => _r.body as Share)
    );
  }

  /**
   * Update a Share.
   *
   * Update a Share.
   * @param params The `ViewService.UpdateShareParams` containing the following parameters:
   *
   * - `name`: Specifies the Share name to update.
   *
   * - `body`: Specifies the request to update a Share.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateShareResponse(params: ViewService.UpdateShareParams): __Observable<__StrictHttpResponse<Share>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/file-services/shares/${encodeURIComponent(params.name)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Share>;
      })
    );
  }
  /**
   * Update a Share.
   *
   * Update a Share.
   * @param params The `ViewService.UpdateShareParams` containing the following parameters:
   *
   * - `name`: Specifies the Share name to update.
   *
   * - `body`: Specifies the request to update a Share.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateShare(params: ViewService.UpdateShareParams): __Observable<Share> {
    return this.UpdateShareResponse(params).pipe(
      __map(_r => _r.body as Share)
    );
  }

  /**
   * Delete a Share.
   *
   * Delete a Share.
   * @param params The `ViewService.DeleteShareParams` containing the following parameters:
   *
   * - `name`: Specifies the Share name to delete.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteShareResponse(params: ViewService.DeleteShareParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/file-services/shares/${encodeURIComponent(params.name)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a Share.
   *
   * Delete a Share.
   * @param params The `ViewService.DeleteShareParams` containing the following parameters:
   *
   * - `name`: Specifies the Share name to delete.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteShare(params: ViewService.DeleteShareParams): __Observable<null> {
    return this.DeleteShareResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get SMB File opens.
   *
   * Get SMB active file opens on a Cohesity View.
   * @param params The `ViewService.ListSmbFileOpensParams` containing the following parameters:
   *
   * - `viewName`: Specifies the name of the Cohesity View in which to search. If a view name is not specified, all the views in the Cluster are searched. This field is mandatory if filePath field is specified.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `maxCount`: Specifies the maximum number of active file opens to return in the response. This field cannot be set above 1000. If this is not set, maximum of 1000 entries are returned.
   *
   * - `filePath`: Specifies the filepath in the Cohesity View relative to the root filesystem. If this field is specified, viewName field must also be specified.
   *
   * - `cookie`: Specifies the Pagination Cookie returned in the previous response.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListSmbFileOpensResponse(params: ViewService.ListSmbFileOpensParams): __Observable<__StrictHttpResponse<SmbFileOpens>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.viewName != null) __params = __params.set('viewName', params.viewName.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.maxCount != null) __params = __params.set('maxCount', params.maxCount.toString());
    if (params.filePath != null) __params = __params.set('filePath', params.filePath.toString());
    if (params.cookie != null) __params = __params.set('cookie', params.cookie.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/file-services/smb-file-opens`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SmbFileOpens>;
      })
    );
  }
  /**
   * Get SMB File opens.
   *
   * Get SMB active file opens on a Cohesity View.
   * @param params The `ViewService.ListSmbFileOpensParams` containing the following parameters:
   *
   * - `viewName`: Specifies the name of the Cohesity View in which to search. If a view name is not specified, all the views in the Cluster are searched. This field is mandatory if filePath field is specified.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `maxCount`: Specifies the maximum number of active file opens to return in the response. This field cannot be set above 1000. If this is not set, maximum of 1000 entries are returned.
   *
   * - `filePath`: Specifies the filepath in the Cohesity View relative to the root filesystem. If this field is specified, viewName field must also be specified.
   *
   * - `cookie`: Specifies the Pagination Cookie returned in the previous response.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListSmbFileOpens(params: ViewService.ListSmbFileOpensParams): __Observable<SmbFileOpens> {
    return this.ListSmbFileOpensResponse(params).pipe(
      __map(_r => _r.body as SmbFileOpens)
    );
  }

  /**
   * Close SMB File open.
   *
   * Close an active SMB file open.
   * @param params The `ViewService.CloseSmbFileOpenParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `body`: Specifies parameters to close active  SMB file open.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  CloseSmbFileOpenResponse(params: ViewService.CloseSmbFileOpenParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/file-services/smb-file-opens`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Close SMB File open.
   *
   * Close an active SMB file open.
   * @param params The `ViewService.CloseSmbFileOpenParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `body`: Specifies parameters to close active  SMB file open.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  CloseSmbFileOpen(params: ViewService.CloseSmbFileOpenParams): __Observable<null> {
    return this.CloseSmbFileOpenResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get View Clients.
   *
   * Get View Clients.
   * @param params The `ViewService.GetViewClientsParams` containing the following parameters:
   *
   * - `viewIds`: Specifies a list of View ids. Only clients connected to these Views will be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `protocols`: Specifies a list of protocols to filter the clients.
   *
   * - `nodeIp`: Specifies a node ip. Only clients connected to this node will be returned.
   *
   * - `maxCount`: Specifies the maximum number of connections to return for SMB and NFS protocols respectively.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViewClientsResponse(params: ViewService.GetViewClientsParams): __Observable<__StrictHttpResponse<ViewClients>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.viewIds || []).forEach(val => {if (val != null) __params = __params.append('viewIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.protocols || []).forEach(val => {if (val != null) __params = __params.append('protocols', val.toString())});
    if (params.nodeIp != null) __params = __params.set('nodeIp', params.nodeIp.toString());
    if (params.maxCount != null) __params = __params.set('maxCount', params.maxCount.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/file-services/view-clients`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ViewClients>;
      })
    );
  }
  /**
   * Get View Clients.
   *
   * Get View Clients.
   * @param params The `ViewService.GetViewClientsParams` containing the following parameters:
   *
   * - `viewIds`: Specifies a list of View ids. Only clients connected to these Views will be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `protocols`: Specifies a list of protocols to filter the clients.
   *
   * - `nodeIp`: Specifies a node ip. Only clients connected to this node will be returned.
   *
   * - `maxCount`: Specifies the maximum number of connections to return for SMB and NFS protocols respectively.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViewClients(params: ViewService.GetViewClientsParams): __Observable<ViewClients> {
    return this.GetViewClientsResponse(params).pipe(
      __map(_r => _r.body as ViewClients)
    );
  }

  /**
   * Get View Clients Summary.
   *
   * Get View Clients Summary.
   * @param params The `ViewService.GetViewClientsSummaryParams` containing the following parameters:
   *
   * - `viewIds`: Specifies a list of View ids. Only clients connected to these Views will be included in the summary.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViewClientsSummaryResponse(params: ViewService.GetViewClientsSummaryParams): __Observable<__StrictHttpResponse<ViewClientsSummary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.viewIds || []).forEach(val => {if (val != null) __params = __params.append('viewIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/file-services/view-clients/summary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ViewClientsSummary>;
      })
    );
  }
  /**
   * Get View Clients Summary.
   *
   * Get View Clients Summary.
   * @param params The `ViewService.GetViewClientsSummaryParams` containing the following parameters:
   *
   * - `viewIds`: Specifies a list of View ids. Only clients connected to these Views will be included in the summary.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViewClientsSummary(params: ViewService.GetViewClientsSummaryParams): __Observable<ViewClientsSummary> {
    return this.GetViewClientsSummaryResponse(params).pipe(
      __map(_r => _r.body as ViewClientsSummary)
    );
  }

  /**
   * List View Templates
   *
   * All view templates on the Cohesity Cluster are returned.
   * Specifying parameters filters the results that are returned.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  ReadViewTemplatesResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<GetViewTemplatesResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/file-services/view-template`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetViewTemplatesResult>;
      })
    );
  }
  /**
   * List View Templates
   *
   * All view templates on the Cohesity Cluster are returned.
   * Specifying parameters filters the results that are returned.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  ReadViewTemplates(regionId?: string,
    accessClusterId?: number): __Observable<GetViewTemplatesResult> {
    return this.ReadViewTemplatesResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as GetViewTemplatesResult)
    );
  }

  /**
   * Create a View Template
   *
   * Creates a View Template.
   * @param params The `ViewService.CreateViewTemplateParams` containing the following parameters:
   *
   * - `body`: Request to create a view template.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateViewTemplateResponse(params: ViewService.CreateViewTemplateParams): __Observable<__StrictHttpResponse<Template>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/file-services/view-template`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Template>;
      })
    );
  }
  /**
   * Create a View Template
   *
   * Creates a View Template.
   * @param params The `ViewService.CreateViewTemplateParams` containing the following parameters:
   *
   * - `body`: Request to create a view template.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateViewTemplate(params: ViewService.CreateViewTemplateParams): __Observable<Template> {
    return this.CreateViewTemplateResponse(params).pipe(
      __map(_r => _r.body as Template)
    );
  }

  /**
   * Read a View Template by Id
   *
   * Reads a view template based on given template id.
   * @param params The `ViewService.ReadViewTemplateByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the view template.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ReadViewTemplateByIdResponse(params: ViewService.ReadViewTemplateByIdParams): __Observable<__StrictHttpResponse<Template>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/file-services/view-template/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Template>;
      })
    );
  }
  /**
   * Read a View Template by Id
   *
   * Reads a view template based on given template id.
   * @param params The `ViewService.ReadViewTemplateByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the view template.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ReadViewTemplateById(params: ViewService.ReadViewTemplateByIdParams): __Observable<Template> {
    return this.ReadViewTemplateByIdResponse(params).pipe(
      __map(_r => _r.body as Template)
    );
  }

  /**
   * Update a View Template
   *
   * Updates a View Template.
   * @param params The `ViewService.UpdateViewTemplateParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the view template.
   *
   * - `body`: Request to update a view template.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateViewTemplateResponse(params: ViewService.UpdateViewTemplateParams): __Observable<__StrictHttpResponse<Template>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/file-services/view-template/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Template>;
      })
    );
  }
  /**
   * Update a View Template
   *
   * Updates a View Template.
   * @param params The `ViewService.UpdateViewTemplateParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the view template.
   *
   * - `body`: Request to update a view template.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateViewTemplate(params: ViewService.UpdateViewTemplateParams): __Observable<Template> {
    return this.UpdateViewTemplateResponse(params).pipe(
      __map(_r => _r.body as Template)
    );
  }

  /**
   * Delete a View Template
   *
   * Deletes a view template based on given template id.
   * @param params The `ViewService.DeleteViewTemplateParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the view template to delete.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteViewTemplateResponse(params: ViewService.DeleteViewTemplateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/file-services/view-template/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a View Template
   *
   * Deletes a view template based on given template id.
   * @param params The `ViewService.DeleteViewTemplateParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the view template to delete.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteViewTemplate(params: ViewService.DeleteViewTemplateParams): __Observable<null> {
    return this.DeleteViewTemplateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * List Views
   *
   * If no parameters are specified, all Views on the Cohesity Cluster are returned.
   * Specifying parameters filters the results that are returned.
   * NOTE: If maxCount is set and the number of Views returned exceeds the maxCount,
   * there are more Views to return.
   * To get the next set of Views, send another request and specify the id of the
   * last View returned in viewList from the previous response.
   * @param params The `ViewService.GetViewsParams` containing the following parameters:
   *
   * - `viewProtectionTypes`: Filter by a list of View protection types. Supported types: [Local Archival ReplicationOut ReplicationIn UnProtected]. UnProtected is mutually exclusive from remaining types.
   *
   * - `viewProtectionGroupIds`: Filter by Protection Group ids. Return Views that are being protected by
   *   listed Groups, which are specified by ids.
   *
   * - `viewNames`: Filter by a list of View names.
   *
   * - `viewIds`: Filter by a list of View ids.
   *
   * - `viewCountOnly`: Whether to get just the total number of views with the given input
   *   filters. If the flag is true, we ignore the parameter 'maxViews' for the
   *   count. Also, if flag is true, list of views will not be returned.
   *
   * - `useCachedData`: Specifies whether we can serve the GET request to the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `summaryOnly`: Whether to get only view summary including 'name', 'viewId',
   *   'storageDomainName', 'storageDomainId' and 'tenantId'.
   *
   * - `storageDomainNames`: Filter by a list of View Box names.
   *
   * - `storageDomainIds`: Filter by a list of Storage Domains (View Boxes) specified by id.
   *
   * - `sortByLogicalUsage`: If set to true, the list is sorted descending by logical usage.
   *
   * - `s3MigrationState`: Filter the list of Views by S3 Migration Statuses.
   *   Supported filter values are
   *   [Enabled, UnderMigration, Paused, Completed, Eligible]."
   *   If `s3MigrationState` is specified then
   *   `includeS3MigrationOnly` param should also be set to true.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `qosPrincipalIds`: qosPrincipalIds contains ids of the QoS principal for which views are to be returned. This field is deprecated.
   *
   * - `qosPolicies`: Specifies a filter for Views based on the qosPolicies. This param will be prioritized if qosPrincipalIds is also specified.
   *
   * - `protocolAccesses`: Filter by a list of protocol accesses. Only views with
   *   protocol accesses in these specified accesses list will be returned.
   *
   * - `protectionGroupIds`: This field will be deprecated. Filter by Protection Group ids.
   *   Return Views that are being protected by listed Groups, which are specified by ids.
   *   If both protectionGroupIds and viewProtectionGroupIds are specified, only
   *   viewProtectionGroupIds will be used.
   *
   * - `maxViewId`: If the number of Views to return exceeds the maxCount specified in the
   *   original request, specify the id of the last View
   *   from the viewList in the previous response
   *   to get the next set of Views.
   *
   * - `maxCount`: Specifies a limit on the number of Views returned.
   *
   * - `matchPartialNames`: If true, the names in viewNames are matched by any partial rather than
   *   exactly matched.
   *
   * - `matchAliasNames`: If true, view aliases are also matched with the names in viewNames.
   *
   * - `lastRunReplicationStatuses`: Filter by last remote replication run status of the view.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Skipped' indicates that the run was skipped.
   *
   * - `lastRunLocalBackupStatuses`: Filter by last local backup run status of the view.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Skipped' indicates that the run was skipped.
   *
   * - `lastRunArchivalStatuses`: Filter by last cloud archival run status of the view.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Skipped' indicates that the run was skipped.
   *
   * - `lastRunAnyStatuses`: Filter by last any run status of the view.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Skipped' indicates that the run was skipped.
   *
   * - `isProtected`: Specifies the protection status of Views. If set to true, only protected Views will be returned. If set to false, only unprotected Views will be returned.
   *
   * - `internalAccessSids`: Sids of restricted principals who can access the view. This is an
   *   internal field and therefore does not have json tag.
   *
   * - `includeViewsWithDataLockEnabledOnly`: If set to true, the list will contain only the views for which either
   *   file level data lock is enabled or view level data lock is enabled.
   *
   * - `includeViewsWithAntivirusEnabledOnly`: If set to true, the list will contain only the views for which antivirus
   *   scan is enabled.
   *
   * - `includeTenants`: IncludeTenants specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `includeStats`: If set to true, stats of views will be returned. By default this parameter
   *   is set to false.
   *
   * - `includeS3MigrationOnly`: Specifies whether to return only views which have a
   *   s3 migration state.
   *
   * - `includeProtectionGroups`: Specifies if Protection Groups information needs to be returned along with
   *   view metadata. By default, if not set or set to true, Group information is
   *   returned.
   *
   * - `includeInternalViews`: Specifies if internal Views created by the Cohesity Cluster are
   *   also returned. In addition, regular Views are returned.
   *
   * - `includeInactive`: Specifies if inactive Views on this Remote Cluster (which have
   *   Snapshots copied by replication) should also be returned.
   *   Inactive Views are not counted towards the maxCount.
   *   By default, this field is set to false.
   *
   * - `includeFileCountBySize`: Whether to include View file count by size.
   *
   * - `includeDeletedProtectionGroups`: Specifies if deleted Protection Groups information needs to be returned along with
   *   view metadata. By default, deleted Protection Groups are not returned. This is only
   *   applied if used along with any view protection related parameter.
   *
   * - `filerAuditLogEnabled`: If set to true, only views with filer audit log enabled will be returned.
   *   If set to false, only views with filer audit log disabled will be returned.
   *
   * - `categories`: Filter by a list of View categories.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViewsResponse(params: ViewService.GetViewsParams): __Observable<__StrictHttpResponse<GetViewsResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.viewProtectionTypes || []).forEach(val => {if (val != null) __params = __params.append('viewProtectionTypes', val.toString())});
    (params.viewProtectionGroupIds || []).forEach(val => {if (val != null) __params = __params.append('viewProtectionGroupIds', val.toString())});
    (params.viewNames || []).forEach(val => {if (val != null) __params = __params.append('viewNames', val.toString())});
    (params.viewIds || []).forEach(val => {if (val != null) __params = __params.append('viewIds', val.toString())});
    if (params.viewCountOnly != null) __params = __params.set('viewCountOnly', params.viewCountOnly.toString());
    if (params.useCachedData != null) __params = __params.set('useCachedData', params.useCachedData.toString());
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.summaryOnly != null) __params = __params.set('summaryOnly', params.summaryOnly.toString());
    (params.storageDomainNames || []).forEach(val => {if (val != null) __params = __params.append('storageDomainNames', val.toString())});
    (params.storageDomainIds || []).forEach(val => {if (val != null) __params = __params.append('storageDomainIds', val.toString())});
    if (params.sortByLogicalUsage != null) __params = __params.set('sortByLogicalUsage', params.sortByLogicalUsage.toString());
    (params.s3MigrationState || []).forEach(val => {if (val != null) __params = __params.append('s3MigrationState', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.qosPrincipalIds || []).forEach(val => {if (val != null) __params = __params.append('qosPrincipalIds', val.toString())});
    (params.qosPolicies || []).forEach(val => {if (val != null) __params = __params.append('qosPolicies', val.toString())});
    (params.protocolAccesses || []).forEach(val => {if (val != null) __params = __params.append('protocolAccesses', val.toString())});
    (params.protectionGroupIds || []).forEach(val => {if (val != null) __params = __params.append('protectionGroupIds', val.toString())});
    if (params.maxViewId != null) __params = __params.set('maxViewId', params.maxViewId.toString());
    if (params.maxCount != null) __params = __params.set('maxCount', params.maxCount.toString());
    if (params.matchPartialNames != null) __params = __params.set('matchPartialNames', params.matchPartialNames.toString());
    if (params.matchAliasNames != null) __params = __params.set('matchAliasNames', params.matchAliasNames.toString());
    (params.lastRunReplicationStatuses || []).forEach(val => {if (val != null) __params = __params.append('lastRunReplicationStatuses', val.toString())});
    (params.lastRunLocalBackupStatuses || []).forEach(val => {if (val != null) __params = __params.append('lastRunLocalBackupStatuses', val.toString())});
    (params.lastRunArchivalStatuses || []).forEach(val => {if (val != null) __params = __params.append('lastRunArchivalStatuses', val.toString())});
    (params.lastRunAnyStatuses || []).forEach(val => {if (val != null) __params = __params.append('lastRunAnyStatuses', val.toString())});
    if (params.isProtected != null) __params = __params.set('isProtected', params.isProtected.toString());
    (params.internalAccessSids || []).forEach(val => {if (val != null) __params = __params.append('internalAccessSids', val.toString())});
    if (params.includeViewsWithDataLockEnabledOnly != null) __params = __params.set('includeViewsWithDataLockEnabledOnly', params.includeViewsWithDataLockEnabledOnly.toString());
    if (params.includeViewsWithAntivirusEnabledOnly != null) __params = __params.set('includeViewsWithAntivirusEnabledOnly', params.includeViewsWithAntivirusEnabledOnly.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.includeStats != null) __params = __params.set('includeStats', params.includeStats.toString());
    if (params.includeS3MigrationOnly != null) __params = __params.set('includeS3MigrationOnly', params.includeS3MigrationOnly.toString());
    if (params.includeProtectionGroups != null) __params = __params.set('includeProtectionGroups', params.includeProtectionGroups.toString());
    if (params.includeInternalViews != null) __params = __params.set('includeInternalViews', params.includeInternalViews.toString());
    if (params.includeInactive != null) __params = __params.set('includeInactive', params.includeInactive.toString());
    if (params.includeFileCountBySize != null) __params = __params.set('includeFileCountBySize', params.includeFileCountBySize.toString());
    if (params.includeDeletedProtectionGroups != null) __params = __params.set('includeDeletedProtectionGroups', params.includeDeletedProtectionGroups.toString());
    if (params.filerAuditLogEnabled != null) __params = __params.set('filerAuditLogEnabled', params.filerAuditLogEnabled.toString());
    (params.categories || []).forEach(val => {if (val != null) __params = __params.append('categories', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/file-services/views`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetViewsResult>;
      })
    );
  }
  /**
   * List Views
   *
   * If no parameters are specified, all Views on the Cohesity Cluster are returned.
   * Specifying parameters filters the results that are returned.
   * NOTE: If maxCount is set and the number of Views returned exceeds the maxCount,
   * there are more Views to return.
   * To get the next set of Views, send another request and specify the id of the
   * last View returned in viewList from the previous response.
   * @param params The `ViewService.GetViewsParams` containing the following parameters:
   *
   * - `viewProtectionTypes`: Filter by a list of View protection types. Supported types: [Local Archival ReplicationOut ReplicationIn UnProtected]. UnProtected is mutually exclusive from remaining types.
   *
   * - `viewProtectionGroupIds`: Filter by Protection Group ids. Return Views that are being protected by
   *   listed Groups, which are specified by ids.
   *
   * - `viewNames`: Filter by a list of View names.
   *
   * - `viewIds`: Filter by a list of View ids.
   *
   * - `viewCountOnly`: Whether to get just the total number of views with the given input
   *   filters. If the flag is true, we ignore the parameter 'maxViews' for the
   *   count. Also, if flag is true, list of views will not be returned.
   *
   * - `useCachedData`: Specifies whether we can serve the GET request to the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be
   *   returned.
   *
   * - `summaryOnly`: Whether to get only view summary including 'name', 'viewId',
   *   'storageDomainName', 'storageDomainId' and 'tenantId'.
   *
   * - `storageDomainNames`: Filter by a list of View Box names.
   *
   * - `storageDomainIds`: Filter by a list of Storage Domains (View Boxes) specified by id.
   *
   * - `sortByLogicalUsage`: If set to true, the list is sorted descending by logical usage.
   *
   * - `s3MigrationState`: Filter the list of Views by S3 Migration Statuses.
   *   Supported filter values are
   *   [Enabled, UnderMigration, Paused, Completed, Eligible]."
   *   If `s3MigrationState` is specified then
   *   `includeS3MigrationOnly` param should also be set to true.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `qosPrincipalIds`: qosPrincipalIds contains ids of the QoS principal for which views are to be returned. This field is deprecated.
   *
   * - `qosPolicies`: Specifies a filter for Views based on the qosPolicies. This param will be prioritized if qosPrincipalIds is also specified.
   *
   * - `protocolAccesses`: Filter by a list of protocol accesses. Only views with
   *   protocol accesses in these specified accesses list will be returned.
   *
   * - `protectionGroupIds`: This field will be deprecated. Filter by Protection Group ids.
   *   Return Views that are being protected by listed Groups, which are specified by ids.
   *   If both protectionGroupIds and viewProtectionGroupIds are specified, only
   *   viewProtectionGroupIds will be used.
   *
   * - `maxViewId`: If the number of Views to return exceeds the maxCount specified in the
   *   original request, specify the id of the last View
   *   from the viewList in the previous response
   *   to get the next set of Views.
   *
   * - `maxCount`: Specifies a limit on the number of Views returned.
   *
   * - `matchPartialNames`: If true, the names in viewNames are matched by any partial rather than
   *   exactly matched.
   *
   * - `matchAliasNames`: If true, view aliases are also matched with the names in viewNames.
   *
   * - `lastRunReplicationStatuses`: Filter by last remote replication run status of the view.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Skipped' indicates that the run was skipped.
   *
   * - `lastRunLocalBackupStatuses`: Filter by last local backup run status of the view.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Skipped' indicates that the run was skipped.
   *
   * - `lastRunArchivalStatuses`: Filter by last cloud archival run status of the view.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Skipped' indicates that the run was skipped.
   *
   * - `lastRunAnyStatuses`: Filter by last any run status of the view.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Skipped' indicates that the run was skipped.
   *
   * - `isProtected`: Specifies the protection status of Views. If set to true, only protected Views will be returned. If set to false, only unprotected Views will be returned.
   *
   * - `internalAccessSids`: Sids of restricted principals who can access the view. This is an
   *   internal field and therefore does not have json tag.
   *
   * - `includeViewsWithDataLockEnabledOnly`: If set to true, the list will contain only the views for which either
   *   file level data lock is enabled or view level data lock is enabled.
   *
   * - `includeViewsWithAntivirusEnabledOnly`: If set to true, the list will contain only the views for which antivirus
   *   scan is enabled.
   *
   * - `includeTenants`: IncludeTenants specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * - `includeStats`: If set to true, stats of views will be returned. By default this parameter
   *   is set to false.
   *
   * - `includeS3MigrationOnly`: Specifies whether to return only views which have a
   *   s3 migration state.
   *
   * - `includeProtectionGroups`: Specifies if Protection Groups information needs to be returned along with
   *   view metadata. By default, if not set or set to true, Group information is
   *   returned.
   *
   * - `includeInternalViews`: Specifies if internal Views created by the Cohesity Cluster are
   *   also returned. In addition, regular Views are returned.
   *
   * - `includeInactive`: Specifies if inactive Views on this Remote Cluster (which have
   *   Snapshots copied by replication) should also be returned.
   *   Inactive Views are not counted towards the maxCount.
   *   By default, this field is set to false.
   *
   * - `includeFileCountBySize`: Whether to include View file count by size.
   *
   * - `includeDeletedProtectionGroups`: Specifies if deleted Protection Groups information needs to be returned along with
   *   view metadata. By default, deleted Protection Groups are not returned. This is only
   *   applied if used along with any view protection related parameter.
   *
   * - `filerAuditLogEnabled`: If set to true, only views with filer audit log enabled will be returned.
   *   If set to false, only views with filer audit log disabled will be returned.
   *
   * - `categories`: Filter by a list of View categories.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViews(params: ViewService.GetViewsParams): __Observable<GetViewsResult> {
    return this.GetViewsResponse(params).pipe(
      __map(_r => _r.body as GetViewsResult)
    );
  }

  /**
   * Create a View
   *
   * Creates a View.
   * @param params The `ViewService.CreateViewParams` containing the following parameters:
   *
   * - `body`: Request to create a View.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateViewResponse(params: ViewService.CreateViewParams): __Observable<__StrictHttpResponse<View>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/file-services/views`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<View>;
      })
    );
  }
  /**
   * Create a View
   *
   * Creates a View.
   * @param params The `ViewService.CreateViewParams` containing the following parameters:
   *
   * - `body`: Request to create a View.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateView(params: ViewService.CreateViewParams): __Observable<View> {
    return this.CreateViewResponse(params).pipe(
      __map(_r => _r.body as View)
    );
  }

  /**
   * Get Views summary.
   *
   * Get Views summary.
   * @param params The `ViewService.GetViewsSummaryParams` containing the following parameters:
   *
   * - `useCachedData`: Specifies whether we can serve the GET request to the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `msecsBeforeCurrentTimeToCompare`: Specifies the time in msecs before current time to compare with.
   *
   * - `includeTenants`: IncludeTenants specifies if objects of all the tenants under the hierarchy of the logged in user's organization should be returned.
   *
   * - `includeInternalViews`: Specifies if internal Views created by the Cohesity Cluster are also returned. In addition, regular Views are returned.
   *
   * - `includeDeletedProtectionGroups`: Specifies if deleted Protection Groups information needs to be returned along with view metadata. By default, deleted Protection Groups are not returned. This is only applied if used along with any view protection related parameter.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViewsSummaryResponse(params: ViewService.GetViewsSummaryParams): __Observable<__StrictHttpResponse<ViewsSummary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.useCachedData != null) __params = __params.set('useCachedData', params.useCachedData.toString());
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.msecsBeforeCurrentTimeToCompare != null) __params = __params.set('msecsBeforeCurrentTimeToCompare', params.msecsBeforeCurrentTimeToCompare.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.includeInternalViews != null) __params = __params.set('includeInternalViews', params.includeInternalViews.toString());
    if (params.includeDeletedProtectionGroups != null) __params = __params.set('includeDeletedProtectionGroups', params.includeDeletedProtectionGroups.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/file-services/views-summary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ViewsSummary>;
      })
    );
  }
  /**
   * Get Views summary.
   *
   * Get Views summary.
   * @param params The `ViewService.GetViewsSummaryParams` containing the following parameters:
   *
   * - `useCachedData`: Specifies whether we can serve the GET request to the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which objects are to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `msecsBeforeCurrentTimeToCompare`: Specifies the time in msecs before current time to compare with.
   *
   * - `includeTenants`: IncludeTenants specifies if objects of all the tenants under the hierarchy of the logged in user's organization should be returned.
   *
   * - `includeInternalViews`: Specifies if internal Views created by the Cohesity Cluster are also returned. In addition, regular Views are returned.
   *
   * - `includeDeletedProtectionGroups`: Specifies if deleted Protection Groups information needs to be returned along with view metadata. By default, deleted Protection Groups are not returned. This is only applied if used along with any view protection related parameter.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViewsSummary(params: ViewService.GetViewsSummaryParams): __Observable<ViewsSummary> {
    return this.GetViewsSummaryResponse(params).pipe(
      __map(_r => _r.body as ViewsSummary)
    );
  }

  /**
   * Clone View Directory.
   *
   * Clone View Directory.
   * @param params The `ViewService.CloneViewDirectoryParams` containing the following parameters:
   *
   * - `body`: Specifies the request to clone View directory.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  CloneViewDirectoryResponse(params: ViewService.CloneViewDirectoryParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/file-services/views/clone-directory`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Clone View Directory.
   *
   * Clone View Directory.
   * @param params The `ViewService.CloneViewDirectoryParams` containing the following parameters:
   *
   * - `body`: Specifies the request to clone View directory.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  CloneViewDirectory(params: ViewService.CloneViewDirectoryParams): __Observable<null> {
    return this.CloneViewDirectoryResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get a View by Id
   *
   * Get a View based on given Id.
   * @param params The `ViewService.GetViewByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the View to fetch.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViewByIdResponse(params: ViewService.GetViewByIdParams): __Observable<__StrictHttpResponse<View>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/file-services/views/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<View>;
      })
    );
  }
  /**
   * Get a View by Id
   *
   * Get a View based on given Id.
   * @param params The `ViewService.GetViewByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the View to fetch.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViewById(params: ViewService.GetViewByIdParams): __Observable<View> {
    return this.GetViewByIdResponse(params).pipe(
      __map(_r => _r.body as View)
    );
  }

  /**
   * Update a View
   *
   * Updates a View based on given id.
   * @param params The `ViewService.UpdateViewParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the View to update.
   *
   * - `body`: Request to update a view.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateViewResponse(params: ViewService.UpdateViewParams): __Observable<__StrictHttpResponse<View>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/file-services/views/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<View>;
      })
    );
  }
  /**
   * Update a View
   *
   * Updates a View based on given id.
   * @param params The `ViewService.UpdateViewParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the View to update.
   *
   * - `body`: Request to update a view.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateView(params: ViewService.UpdateViewParams): __Observable<View> {
    return this.UpdateViewResponse(params).pipe(
      __map(_r => _r.body as View)
    );
  }

  /**
   * Delete a View
   *
   * Deletes a View based on given id.
   * @param params The `ViewService.DeleteViewParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the View to delete.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteViewResponse(params: ViewService.DeleteViewParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/file-services/views/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a View
   *
   * Deletes a View based on given id.
   * @param params The `ViewService.DeleteViewParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the View to delete.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteView(params: ViewService.DeleteViewParams): __Observable<null> {
    return this.DeleteViewResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Clone View.
   *
   * Clone View.
   * @param params The `ViewService.CloneViewParams` containing the following parameters:
   *
   * - `id`: Specifies the View id to clone.
   *
   * - `body`: Specifies the request to clone the View.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CloneViewResponse(params: ViewService.CloneViewParams): __Observable<__StrictHttpResponse<View>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/file-services/views/${encodeURIComponent(params.id)}/clone`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<View>;
      })
    );
  }
  /**
   * Clone View.
   *
   * Clone View.
   * @param params The `ViewService.CloneViewParams` containing the following parameters:
   *
   * - `id`: Specifies the View id to clone.
   *
   * - `body`: Specifies the request to clone the View.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CloneView(params: ViewService.CloneViewParams): __Observable<View> {
    return this.CloneViewResponse(params).pipe(
      __map(_r => _r.body as View)
    );
  }

  /**
   * Get directory quotas for the View.
   *
   * Get directory quotas for the View.
   * @param params The `ViewService.GetViewDirectoryQuotasParams` containing the following parameters:
   *
   * - `id`: Specifies the View id.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `maxCount`: Specifies a limit on the number of quotas returned.
   *
   * - `cookie`: Specifies the cookie.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViewDirectoryQuotasResponse(params: ViewService.GetViewDirectoryQuotasParams): __Observable<__StrictHttpResponse<ViewDirectoryQuotas>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.maxCount != null) __params = __params.set('maxCount', params.maxCount.toString());
    if (params.cookie != null) __params = __params.set('cookie', params.cookie.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/file-services/views/${encodeURIComponent(params.id)}/directory-quotas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ViewDirectoryQuotas>;
      })
    );
  }
  /**
   * Get directory quotas for the View.
   *
   * Get directory quotas for the View.
   * @param params The `ViewService.GetViewDirectoryQuotasParams` containing the following parameters:
   *
   * - `id`: Specifies the View id.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `maxCount`: Specifies a limit on the number of quotas returned.
   *
   * - `cookie`: Specifies the cookie.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViewDirectoryQuotas(params: ViewService.GetViewDirectoryQuotasParams): __Observable<ViewDirectoryQuotas> {
    return this.GetViewDirectoryQuotasResponse(params).pipe(
      __map(_r => _r.body as ViewDirectoryQuotas)
    );
  }

  /**
   * Update directory quota for the View.
   *
   * Update directory quota for the View.
   * @param params The `ViewService.UpdateViewDirectoryQuotaParams` containing the following parameters:
   *
   * - `id`: Specifies the View id.
   *
   * - `body`: Specifies the request to update directory quota.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateViewDirectoryQuotaResponse(params: ViewService.UpdateViewDirectoryQuotaParams): __Observable<__StrictHttpResponse<ViewDirectoryQuota>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/file-services/views/${encodeURIComponent(params.id)}/directory-quotas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ViewDirectoryQuota>;
      })
    );
  }
  /**
   * Update directory quota for the View.
   *
   * Update directory quota for the View.
   * @param params The `ViewService.UpdateViewDirectoryQuotaParams` containing the following parameters:
   *
   * - `id`: Specifies the View id.
   *
   * - `body`: Specifies the request to update directory quota.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateViewDirectoryQuota(params: ViewService.UpdateViewDirectoryQuotaParams): __Observable<ViewDirectoryQuota> {
    return this.UpdateViewDirectoryQuotaResponse(params).pipe(
      __map(_r => _r.body as ViewDirectoryQuota)
    );
  }

  /**
   * Delete directory quota for the View.
   *
   * Delete directory quota for the View.
   * @param params The `ViewService.DeleteViewDirectoryQuotaParams` containing the following parameters:
   *
   * - `id`: Specifies the View id.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `directoryPath`: Specifies the directory path to delete. Exactly one of 'directoryPath' and 'deleteAllDirectoryQuotas' should be provided.
   *
   * - `deleteAllDirectoryQuotas`: Specifies whether to delete all directory quotas for this view. Exactly one of 'directoryPath' and 'deleteAllDirectoryQuotas' should be provided.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteViewDirectoryQuotaResponse(params: ViewService.DeleteViewDirectoryQuotaParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.directoryPath != null) __params = __params.set('directoryPath', params.directoryPath.toString());
    if (params.deleteAllDirectoryQuotas != null) __params = __params.set('deleteAllDirectoryQuotas', params.deleteAllDirectoryQuotas.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/file-services/views/${encodeURIComponent(params.id)}/directory-quotas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete directory quota for the View.
   *
   * Delete directory quota for the View.
   * @param params The `ViewService.DeleteViewDirectoryQuotaParams` containing the following parameters:
   *
   * - `id`: Specifies the View id.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `directoryPath`: Specifies the directory path to delete. Exactly one of 'directoryPath' and 'deleteAllDirectoryQuotas' should be provided.
   *
   * - `deleteAllDirectoryQuotas`: Specifies whether to delete all directory quotas for this view. Exactly one of 'directoryPath' and 'deleteAllDirectoryQuotas' should be provided.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteViewDirectoryQuota(params: ViewService.DeleteViewDirectoryQuotaParams): __Observable<null> {
    return this.DeleteViewDirectoryQuotaResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get file lock status
   *
   * Get the lock status of a file in a view.
   * @param params The `ViewService.GetFileLockStatusParams` containing the following parameters:
   *
   * - `path`: Specifies the request file path in a view.
   *
   * - `id`: Specifies the id of a view.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetFileLockStatusResponse(params: ViewService.GetFileLockStatusParams): __Observable<__StrictHttpResponse<FileLockStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.path != null) __params = __params.set('path', params.path.toString());

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/file-services/views/${encodeURIComponent(params.id)}/file-lock`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FileLockStatus>;
      })
    );
  }
  /**
   * Get file lock status
   *
   * Get the lock status of a file in a view.
   * @param params The `ViewService.GetFileLockStatusParams` containing the following parameters:
   *
   * - `path`: Specifies the request file path in a view.
   *
   * - `id`: Specifies the id of a view.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetFileLockStatus(params: ViewService.GetFileLockStatusParams): __Observable<FileLockStatus> {
    return this.GetFileLockStatusResponse(params).pipe(
      __map(_r => _r.body as FileLockStatus)
    );
  }

  /**
   * Create a file-lock
   *
   * Locks a file in a view and returns the lock status of the file.
   * @param params The `ViewService.LockFileParams` containing the following parameters:
   *
   * - `id`: Specifies the id of a view.
   *
   * - `body`: Specifies the request params to lock a file
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  LockFileResponse(params: ViewService.LockFileParams): __Observable<__StrictHttpResponse<FileLockStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/file-services/views/${encodeURIComponent(params.id)}/file-lock`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FileLockStatus>;
      })
    );
  }
  /**
   * Create a file-lock
   *
   * Locks a file in a view and returns the lock status of the file.
   * @param params The `ViewService.LockFileParams` containing the following parameters:
   *
   * - `id`: Specifies the id of a view.
   *
   * - `body`: Specifies the request params to lock a file
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  LockFile(params: ViewService.LockFileParams): __Observable<FileLockStatus> {
    return this.LockFileResponse(params).pipe(
      __map(_r => _r.body as FileLockStatus)
    );
  }

  /**
   * Overwrite View.
   *
   * Overwrite View.
   * @param params The `ViewService.OverwriteViewParams` containing the following parameters:
   *
   * - `id`: Specifies the View id to be overwritten.
   *
   * - `body`: Specifies the request to overwrite the View.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  OverwriteViewResponse(params: ViewService.OverwriteViewParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/file-services/views/${encodeURIComponent(params.id)}/overwrite`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Overwrite View.
   *
   * Overwrite View.
   * @param params The `ViewService.OverwriteViewParams` containing the following parameters:
   *
   * - `id`: Specifies the View id to be overwritten.
   *
   * - `body`: Specifies the request to overwrite the View.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  OverwriteView(params: ViewService.OverwriteViewParams): __Observable<null> {
    return this.OverwriteViewResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get View user quotas.
   *
   * Get user quotas for the View.
   * @param params The `ViewService.GetViewUserQuotasParams` containing the following parameters:
   *
   * - `viewId`: Specifies the View id.
   *
   * - `unixUid`: Specifies the user identifier of an Unix user. If a valid unix-id to SID mappings are available (i.e., when mixed mode is enabled) the server will perform the necessary id mapping and return the correct usage irrespective of whether the unix id / SID is provided.
   *
   * - `topQuotas`: TopQuotas is the quotas sorted by quota usage in descending order. This parameter defines number of results to be returned. No pagination cookie is returned if this is set to true
   *
   * - `summaryOnly`: Specifies a flag to just return a summary. If set to true, and if ViewId is not nil, it returns the summary of users for a view. Otherwise if UserId not nil, and ViewId is nil then it fetches the summary for a user in his views. By default, it is set to false.
   *
   * - `sid`: Specifies the user identifier of a SMB user. If a valid unix-id to SID mappings are available (i.e., when mixed mode is enabled) the server will perform the necessary id mapping and return the correct usage irrespective of whether the unix id / SID is provided.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `outputFormat`: OutputFormat is the Output format for the output. If it is not specified, default is json.
   *
   * - `maxCount`: Specifies a limit on the number of quotas returned. If maxCount is not set, response will have a maximum of 100 results.
   *
   * - `includeUserWithQuotaOverridesOnly`: If set to true, the result will only contain user with user quota override enabled. By default, this field is set to false, and it's only in effect when 'SummaryOnly' is set to false and 'ViewId' is specified.
   *
   * - `includeUsage`: If set to true, the logical usage info is included only for users with quota overrides. By default, it is set to false
   *
   * - `excludeUsersWithinAlertThreshold`: This field can be set only when includeUsage is set to true. By default, all the users with logical usage > 0 will be returned in the result. If this field is set to true, only the list of users who has exceeded the alert threshold will be returned.
   *
   * - `cookie`: Specifies the cookie. If there are more results than maxCount, response will include a cookie with has to be set as part of the next GET request.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViewUserQuotasResponse(params: ViewService.GetViewUserQuotasParams): __Observable<__StrictHttpResponse<ViewUserQuotas>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.unixUid != null) __params = __params.set('unixUid', params.unixUid.toString());
    if (params.topQuotas != null) __params = __params.set('topQuotas', params.topQuotas.toString());
    if (params.summaryOnly != null) __params = __params.set('summaryOnly', params.summaryOnly.toString());
    if (params.sid != null) __params = __params.set('sid', params.sid.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.outputFormat != null) __params = __params.set('outputFormat', params.outputFormat.toString());
    if (params.maxCount != null) __params = __params.set('maxCount', params.maxCount.toString());
    if (params.includeUserWithQuotaOverridesOnly != null) __params = __params.set('includeUserWithQuotaOverridesOnly', params.includeUserWithQuotaOverridesOnly.toString());
    if (params.includeUsage != null) __params = __params.set('includeUsage', params.includeUsage.toString());
    if (params.excludeUsersWithinAlertThreshold != null) __params = __params.set('excludeUsersWithinAlertThreshold', params.excludeUsersWithinAlertThreshold.toString());
    if (params.cookie != null) __params = __params.set('cookie', params.cookie.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/file-services/views/${encodeURIComponent(params.viewId)}/user-quotas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ViewUserQuotas>;
      })
    );
  }
  /**
   * Get View user quotas.
   *
   * Get user quotas for the View.
   * @param params The `ViewService.GetViewUserQuotasParams` containing the following parameters:
   *
   * - `viewId`: Specifies the View id.
   *
   * - `unixUid`: Specifies the user identifier of an Unix user. If a valid unix-id to SID mappings are available (i.e., when mixed mode is enabled) the server will perform the necessary id mapping and return the correct usage irrespective of whether the unix id / SID is provided.
   *
   * - `topQuotas`: TopQuotas is the quotas sorted by quota usage in descending order. This parameter defines number of results to be returned. No pagination cookie is returned if this is set to true
   *
   * - `summaryOnly`: Specifies a flag to just return a summary. If set to true, and if ViewId is not nil, it returns the summary of users for a view. Otherwise if UserId not nil, and ViewId is nil then it fetches the summary for a user in his views. By default, it is set to false.
   *
   * - `sid`: Specifies the user identifier of a SMB user. If a valid unix-id to SID mappings are available (i.e., when mixed mode is enabled) the server will perform the necessary id mapping and return the correct usage irrespective of whether the unix id / SID is provided.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `outputFormat`: OutputFormat is the Output format for the output. If it is not specified, default is json.
   *
   * - `maxCount`: Specifies a limit on the number of quotas returned. If maxCount is not set, response will have a maximum of 100 results.
   *
   * - `includeUserWithQuotaOverridesOnly`: If set to true, the result will only contain user with user quota override enabled. By default, this field is set to false, and it's only in effect when 'SummaryOnly' is set to false and 'ViewId' is specified.
   *
   * - `includeUsage`: If set to true, the logical usage info is included only for users with quota overrides. By default, it is set to false
   *
   * - `excludeUsersWithinAlertThreshold`: This field can be set only when includeUsage is set to true. By default, all the users with logical usage > 0 will be returned in the result. If this field is set to true, only the list of users who has exceeded the alert threshold will be returned.
   *
   * - `cookie`: Specifies the cookie. If there are more results than maxCount, response will include a cookie with has to be set as part of the next GET request.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetViewUserQuotas(params: ViewService.GetViewUserQuotasParams): __Observable<ViewUserQuotas> {
    return this.GetViewUserQuotasResponse(params).pipe(
      __map(_r => _r.body as ViewUserQuotas)
    );
  }

  /**
   * Update View user quota settings.
   *
   * Specifies parameters to update View user quota settings.
   * @param params The `ViewService.UpdateViewUserQuotaSettingsParams` containing the following parameters:
   *
   * - `viewId`: Specifies the View id.
   *
   * - `body`: Specifies the parameters to enable/disable or update the default quota config on the view.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateViewUserQuotaSettingsResponse(params: ViewService.UpdateViewUserQuotaSettingsParams): __Observable<__StrictHttpResponse<ViewUserQuotas>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/file-services/views/${encodeURIComponent(params.viewId)}/user-quotas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ViewUserQuotas>;
      })
    );
  }
  /**
   * Update View user quota settings.
   *
   * Specifies parameters to update View user quota settings.
   * @param params The `ViewService.UpdateViewUserQuotaSettingsParams` containing the following parameters:
   *
   * - `viewId`: Specifies the View id.
   *
   * - `body`: Specifies the parameters to enable/disable or update the default quota config on the view.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateViewUserQuotaSettings(params: ViewService.UpdateViewUserQuotaSettingsParams): __Observable<ViewUserQuotas> {
    return this.UpdateViewUserQuotaSettingsResponse(params).pipe(
      __map(_r => _r.body as ViewUserQuotas)
    );
  }

  /**
   * Add User Quota overrides.
   *
   * Specifies the parameters to override the user quota on the view. User quota on the view should be enabled before setting a user override.
   * @param params The `ViewService.AddViewUserQuotaOverridesParams` containing the following parameters:
   *
   * - `viewId`: Specifies the id of a view.
   *
   * - `body`: Specifies the parameters to override the default user quota on the view.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  AddViewUserQuotaOverridesResponse(params: ViewService.AddViewUserQuotaOverridesParams): __Observable<__StrictHttpResponse<UserQuotaOverrides>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/file-services/views/${encodeURIComponent(params.viewId)}/user-quotas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserQuotaOverrides>;
      })
    );
  }
  /**
   * Add User Quota overrides.
   *
   * Specifies the parameters to override the user quota on the view. User quota on the view should be enabled before setting a user override.
   * @param params The `ViewService.AddViewUserQuotaOverridesParams` containing the following parameters:
   *
   * - `viewId`: Specifies the id of a view.
   *
   * - `body`: Specifies the parameters to override the default user quota on the view.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  AddViewUserQuotaOverrides(params: ViewService.AddViewUserQuotaOverridesParams): __Observable<UserQuotaOverrides> {
    return this.AddViewUserQuotaOverridesResponse(params).pipe(
      __map(_r => _r.body as UserQuotaOverrides)
    );
  }

  /**
   * Delete user quota overrides.
   *
   * Specifies the parameters to delete user quotas on the view.
   * @param params The `ViewService.DeleteViewUserQuotaOverridesParams` containing the following parameters:
   *
   * - `viewId`: Specifies the id of a view.
   *
   * - `body`: Specifies parameters to delete user quotas.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteViewUserQuotaOverridesResponse(params: ViewService.DeleteViewUserQuotaOverridesParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/file-services/views/${encodeURIComponent(params.viewId)}/user-quotas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete user quota overrides.
   *
   * Specifies the parameters to delete user quotas on the view.
   * @param params The `ViewService.DeleteViewUserQuotaOverridesParams` containing the following parameters:
   *
   * - `viewId`: Specifies the id of a view.
   *
   * - `body`: Specifies parameters to delete user quotas.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteViewUserQuotaOverrides(params: ViewService.DeleteViewUserQuotaOverridesParams): __Observable<null> {
    return this.DeleteViewUserQuotaOverridesResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update user quota override.
   *
   * Update user quota. To use this API, User quota settings should be enabled on the View and there should be a user quota override added for this user.
   * @param params The `ViewService.UpdateViewUserQuotaOverrideParams` containing the following parameters:
   *
   * - `viewId`: Specifies the View id.
   *
   * - `userId`: Specifies the unixUid or sid or an user.
   *
   * - `body`: Specifies the user quota policy of the user.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateViewUserQuotaOverrideResponse(params: ViewService.UpdateViewUserQuotaOverrideParams): __Observable<__StrictHttpResponse<UserQuota>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/file-services/views/${encodeURIComponent(params.viewId)}/user-quotas/${encodeURIComponent(params.userId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserQuota>;
      })
    );
  }
  /**
   * Update user quota override.
   *
   * Update user quota. To use this API, User quota settings should be enabled on the View and there should be a user quota override added for this user.
   * @param params The `ViewService.UpdateViewUserQuotaOverrideParams` containing the following parameters:
   *
   * - `viewId`: Specifies the View id.
   *
   * - `userId`: Specifies the unixUid or sid or an user.
   *
   * - `body`: Specifies the user quota policy of the user.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateViewUserQuotaOverride(params: ViewService.UpdateViewUserQuotaOverrideParams): __Observable<UserQuota> {
    return this.UpdateViewUserQuotaOverrideResponse(params).pipe(
      __map(_r => _r.body as UserQuota)
    );
  }
}

module ViewService {

  /**
   * Parameters for MigrateS3Views
   */
  export interface MigrateS3ViewsParams {

    /**
     * Specifies the request body to Migrate S3 Views.
     */
    body: MigrateS3Views;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetNlmLocks
   */
  export interface GetNlmLocksParams {

    /**
     * Specifies the name of the View in which to search.
     * If a view name is not specified, all the views in the Cluster
     * is searched. This field is mandatory if filePath field is specified.
     */
    viewName?: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the maximum number of NLM locks to return in the response.
     * By default, maxCount is set to 1000. At any given instance, maxCount
     * value cannot be set to more than 1000.
     */
    maxCount?: number;

    /**
     * Specifies the filepath in the view relative to the root filesystem.
     * If this field is specified, viewName field must also be specified.
     */
    filePath?: string;

    /**
     * Specifies the pagination cookie. If this is set, next set of locks
     * just after the previous response are returned. If this is
     * not set, first set of NLM locks are returned."
     */
    cookie?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ClearNlmLocks
   */
  export interface ClearNlmLocksParams {

    /**
     * Request to clear NLM lock.
     */
    body: ClearNlmLockRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetShares
   */
  export interface GetSharesParams {

    /**
     * TenantIds contains ids of the tenants for which objects are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the Share name.
     */
    name?: string;

    /**
     * Specifies a limit on the number of Shares returned. If maxCount is not specified, the first 2000 Shares.
     */
    maxCount?: number;

    /**
     * If true, the share name is matched by any partial rather than exactly matched.
     */
    matchPartialName?: boolean;

    /**
     * IncludeTenants specifies if objects of all the tenants under the hierarchy of the logged in user's organization should be returned.
     */
    includeTenants?: boolean;

    /**
     * Specifies the pagination cookie. Expected to be empty in the first call to the API. To get the next set of results, set this value to the pagination cookie value returned in the response of the previous call.
     */
    cookie?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateShare
   */
  export interface CreateShareParams {

    /**
     * Specifies the request to create a Share.
     */
    body: Share;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateShare
   */
  export interface UpdateShareParams {

    /**
     * Specifies the Share name to update.
     */
    name: string;

    /**
     * Specifies the request to update a Share.
     */
    body: UpdateShareParam;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteShare
   */
  export interface DeleteShareParams {

    /**
     * Specifies the Share name to delete.
     */
    name: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ListSmbFileOpens
   */
  export interface ListSmbFileOpensParams {

    /**
     * Specifies the name of the Cohesity View in which to search. If a view name is not specified, all the views in the Cluster are searched. This field is mandatory if filePath field is specified.
     */
    viewName?: null | string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the maximum number of active file opens to return in the response. This field cannot be set above 1000. If this is not set, maximum of 1000 entries are returned.
     */
    maxCount?: null | number;

    /**
     * Specifies the filepath in the Cohesity View relative to the root filesystem. If this field is specified, viewName field must also be specified.
     */
    filePath?: null | string;

    /**
     * Specifies the Pagination Cookie returned in the previous response.
     */
    cookie?: null | string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CloseSmbFileOpen
   */
  export interface CloseSmbFileOpenParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies parameters to close active  SMB file open.
     */
    body?: CloseSmbFileOpenParams;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetViewClients
   */
  export interface GetViewClientsParams {

    /**
     * Specifies a list of View ids. Only clients connected to these Views will be returned.
     */
    viewIds?: Array<number>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies a list of protocols to filter the clients.
     */
    protocols?: Array<'NFS' | 'SMB'>;

    /**
     * Specifies a node ip. Only clients connected to this node will be returned.
     */
    nodeIp?: string;

    /**
     * Specifies the maximum number of connections to return for SMB and NFS protocols respectively.
     */
    maxCount?: number;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetViewClientsSummary
   */
  export interface GetViewClientsSummaryParams {

    /**
     * Specifies a list of View ids. Only clients connected to these Views will be included in the summary.
     */
    viewIds?: Array<number>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateViewTemplate
   */
  export interface CreateViewTemplateParams {

    /**
     * Request to create a view template.
     */
    body: Template;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ReadViewTemplateById
   */
  export interface ReadViewTemplateByIdParams {

    /**
     * Specifies a unique id of the view template.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateViewTemplate
   */
  export interface UpdateViewTemplateParams {

    /**
     * Specifies a unique id of the view template.
     */
    id: number;

    /**
     * Request to update a view template.
     */
    body: Template;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteViewTemplate
   */
  export interface DeleteViewTemplateParams {

    /**
     * Specifies a unique id of the view template to delete.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetViews
   */
  export interface GetViewsParams {

    /**
     * Filter by a list of View protection types. Supported types: [Local Archival ReplicationOut ReplicationIn UnProtected]. UnProtected is mutually exclusive from remaining types.
     */
    viewProtectionTypes?: Array<'Local' | 'Archival' | 'ReplicationOut' | 'ReplicationIn' | 'UnProtected'>;

    /**
     * Filter by Protection Group ids. Return Views that are being protected by
     * listed Groups, which are specified by ids.
     */
    viewProtectionGroupIds?: Array<string>;

    /**
     * Filter by a list of View names.
     */
    viewNames?: Array<string>;

    /**
     * Filter by a list of View ids.
     */
    viewIds?: Array<number>;

    /**
     * Whether to get just the total number of views with the given input
     * filters. If the flag is true, we ignore the parameter 'maxViews' for the
     * count. Also, if flag is true, list of views will not be returned.
     */
    viewCountOnly?: boolean;

    /**
     * Specifies whether we can serve the GET request to the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
     */
    useCachedData?: boolean;

    /**
     * TenantIds contains ids of the tenants for which objects are to be
     * returned.
     */
    tenantIds?: Array<string>;

    /**
     * Whether to get only view summary including 'name', 'viewId',
     * 'storageDomainName', 'storageDomainId' and 'tenantId'.
     */
    summaryOnly?: boolean;

    /**
     * Filter by a list of View Box names.
     */
    storageDomainNames?: Array<string>;

    /**
     * Filter by a list of Storage Domains (View Boxes) specified by id.
     */
    storageDomainIds?: Array<number>;

    /**
     * If set to true, the list is sorted descending by logical usage.
     */
    sortByLogicalUsage?: boolean;

    /**
     * Filter the list of Views by S3 Migration Statuses.
     * Supported filter values are
     * [Enabled, UnderMigration, Paused, Completed, Eligible]."
     * If `s3MigrationState` is specified then
     * `includeS3MigrationOnly` param should also be set to true.
     */
    s3MigrationState?: Array<'Enabled' | 'UnderMigration' | 'Paused' | 'Completed' | 'Eligible'>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * qosPrincipalIds contains ids of the QoS principal for which views are to be returned. This field is deprecated.
     */
    qosPrincipalIds?: Array<number>;

    /**
     * Specifies a filter for Views based on the qosPolicies. This param will be prioritized if qosPrincipalIds is also specified.
     */
    qosPolicies?: Array<'BackupTargetHigh' | 'BackupTargetLow' | 'TestAndDevHigh' | 'TestAndDevLow' | 'BackupTargetSSD' | 'BackupTargetCommvault' | 'JournaledSequentialDump' | 'BackupTargetAuto'>;

    /**
     * Filter by a list of protocol accesses. Only views with
     * protocol accesses in these specified accesses list will be returned.
     */
    protocolAccesses?: Array<'NFS' | 'NFS4' | 'SMB' | 'S3' | 'Swift'>;

    /**
     * This field will be deprecated. Filter by Protection Group ids.
     * Return Views that are being protected by listed Groups, which are specified by ids.
     * If both protectionGroupIds and viewProtectionGroupIds are specified, only
     * viewProtectionGroupIds will be used.
     */
    protectionGroupIds?: Array<number>;

    /**
     * If the number of Views to return exceeds the maxCount specified in the
     * original request, specify the id of the last View
     * from the viewList in the previous response
     * to get the next set of Views.
     */
    maxViewId?: number;

    /**
     * Specifies a limit on the number of Views returned.
     */
    maxCount?: number;

    /**
     * If true, the names in viewNames are matched by any partial rather than
     * exactly matched.
     */
    matchPartialNames?: boolean;

    /**
     * If true, view aliases are also matched with the names in viewNames.
     */
    matchAliasNames?: boolean;

    /**
     * Filter by last remote replication run status of the view.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Skipped' indicates that the run was skipped.
     */
    lastRunReplicationStatuses?: Array<'Accepted' | 'Running' | 'Canceled' | 'Canceling' | 'Failed' | 'Missed' | 'Succeeded' | 'SucceededWithWarning' | 'OnHold' | 'Finalizing' | 'Skipped'>;

    /**
     * Filter by last local backup run status of the view.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Skipped' indicates that the run was skipped.
     */
    lastRunLocalBackupStatuses?: Array<'Accepted' | 'Running' | 'Canceled' | 'Canceling' | 'Failed' | 'Missed' | 'Succeeded' | 'SucceededWithWarning' | 'OnHold' | 'Finalizing' | 'Skipped'>;

    /**
     * Filter by last cloud archival run status of the view.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Skipped' indicates that the run was skipped.
     */
    lastRunArchivalStatuses?: Array<'Accepted' | 'Running' | 'Canceled' | 'Canceling' | 'Failed' | 'Missed' | 'Succeeded' | 'SucceededWithWarning' | 'OnHold' | 'Finalizing' | 'Skipped'>;

    /**
     * Filter by last any run status of the view.<br> 'Running' indicates that the run is still running.<br> 'Canceled' indicates that the run has been canceled.<br> 'Canceling' indicates that the run is in the process of being canceled.<br> 'Failed' indicates that the run has failed.<br> 'Missed' indicates that the run was unable to take place at the scheduled time because the previous run was still happening.<br> 'Succeeded' indicates that the run has finished successfully.<br> 'SucceededWithWarning' indicates that the run finished successfully, but there were some warning messages.<br> 'Skipped' indicates that the run was skipped.
     */
    lastRunAnyStatuses?: Array<'Accepted' | 'Running' | 'Canceled' | 'Canceling' | 'Failed' | 'Missed' | 'Succeeded' | 'SucceededWithWarning' | 'OnHold' | 'Finalizing' | 'Skipped'>;

    /**
     * Specifies the protection status of Views. If set to true, only protected Views will be returned. If set to false, only unprotected Views will be returned.
     */
    isProtected?: boolean;

    /**
     * Sids of restricted principals who can access the view. This is an
     * internal field and therefore does not have json tag.
     */
    internalAccessSids?: Array<string>;

    /**
     * If set to true, the list will contain only the views for which either
     * file level data lock is enabled or view level data lock is enabled.
     */
    includeViewsWithDataLockEnabledOnly?: boolean;

    /**
     * If set to true, the list will contain only the views for which antivirus
     * scan is enabled.
     */
    includeViewsWithAntivirusEnabledOnly?: boolean;

    /**
     * IncludeTenants specifies if objects of all the tenants under the
     * hierarchy of the logged in user's organization should be returned.
     */
    includeTenants?: boolean;

    /**
     * If set to true, stats of views will be returned. By default this parameter
     * is set to false.
     */
    includeStats?: boolean;

    /**
     * Specifies whether to return only views which have a
     * s3 migration state.
     */
    includeS3MigrationOnly?: boolean;

    /**
     * Specifies if Protection Groups information needs to be returned along with
     * view metadata. By default, if not set or set to true, Group information is
     * returned.
     */
    includeProtectionGroups?: boolean;

    /**
     * Specifies if internal Views created by the Cohesity Cluster are
     * also returned. In addition, regular Views are returned.
     */
    includeInternalViews?: boolean;

    /**
     * Specifies if inactive Views on this Remote Cluster (which have
     * Snapshots copied by replication) should also be returned.
     * Inactive Views are not counted towards the maxCount.
     * By default, this field is set to false.
     */
    includeInactive?: boolean;

    /**
     * Whether to include View file count by size.
     */
    includeFileCountBySize?: boolean;

    /**
     * Specifies if deleted Protection Groups information needs to be returned along with
     * view metadata. By default, deleted Protection Groups are not returned. This is only
     * applied if used along with any view protection related parameter.
     */
    includeDeletedProtectionGroups?: boolean;

    /**
     * If set to true, only views with filer audit log enabled will be returned.
     * If set to false, only views with filer audit log disabled will be returned.
     */
    filerAuditLogEnabled?: boolean;

    /**
     * Filter by a list of View categories.
     */
    categories?: Array<'BackupTarget' | 'FileServices' | 'ObjectServices'>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateView
   */
  export interface CreateViewParams {

    /**
     * Request to create a View.
     */
    body: CreateViewRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetViewsSummary
   */
  export interface GetViewsSummaryParams {

    /**
     * Specifies whether we can serve the GET request to the read replica cache. There is a lag of 15 seconds between the read replica and primary data source.
     */
    useCachedData?: boolean;

    /**
     * TenantIds contains ids of the tenants for which objects are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the time in msecs before current time to compare with.
     */
    msecsBeforeCurrentTimeToCompare?: number;

    /**
     * IncludeTenants specifies if objects of all the tenants under the hierarchy of the logged in user's organization should be returned.
     */
    includeTenants?: boolean;

    /**
     * Specifies if internal Views created by the Cohesity Cluster are also returned. In addition, regular Views are returned.
     */
    includeInternalViews?: boolean;

    /**
     * Specifies if deleted Protection Groups information needs to be returned along with view metadata. By default, deleted Protection Groups are not returned. This is only applied if used along with any view protection related parameter.
     */
    includeDeletedProtectionGroups?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CloneViewDirectory
   */
  export interface CloneViewDirectoryParams {

    /**
     * Specifies the request to clone View directory.
     */
    body: CloneViewDirectoryParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetViewById
   */
  export interface GetViewByIdParams {

    /**
     * Specifies a unique id of the View to fetch.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateView
   */
  export interface UpdateViewParams {

    /**
     * Specifies a unique id of the View to update.
     */
    id: number;

    /**
     * Request to update a view.
     */
    body: View;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteView
   */
  export interface DeleteViewParams {

    /**
     * Specifies a unique id of the View to delete.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CloneView
   */
  export interface CloneViewParams {

    /**
     * Specifies the View id to clone.
     */
    id: number;

    /**
     * Specifies the request to clone the View.
     */
    body: CloneViewParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetViewDirectoryQuotas
   */
  export interface GetViewDirectoryQuotasParams {

    /**
     * Specifies the View id.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies a limit on the number of quotas returned.
     */
    maxCount?: number;

    /**
     * Specifies the cookie.
     */
    cookie?: number;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateViewDirectoryQuota
   */
  export interface UpdateViewDirectoryQuotaParams {

    /**
     * Specifies the View id.
     */
    id: number;

    /**
     * Specifies the request to update directory quota.
     */
    body: ViewDirectoryQuota;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteViewDirectoryQuota
   */
  export interface DeleteViewDirectoryQuotaParams {

    /**
     * Specifies the View id.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the directory path to delete. Exactly one of 'directoryPath' and 'deleteAllDirectoryQuotas' should be provided.
     */
    directoryPath?: string;

    /**
     * Specifies whether to delete all directory quotas for this view. Exactly one of 'directoryPath' and 'deleteAllDirectoryQuotas' should be provided.
     */
    deleteAllDirectoryQuotas?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetFileLockStatus
   */
  export interface GetFileLockStatusParams {

    /**
     * Specifies the request file path in a view.
     */
    path: string;

    /**
     * Specifies the id of a view.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for LockFile
   */
  export interface LockFileParams {

    /**
     * Specifies the id of a view.
     */
    id: number;

    /**
     * Specifies the request params to lock a file
     */
    body: LockFileParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for OverwriteView
   */
  export interface OverwriteViewParams {

    /**
     * Specifies the View id to be overwritten.
     */
    id: number;

    /**
     * Specifies the request to overwrite the View.
     */
    body: OverwriteViewParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetViewUserQuotas
   */
  export interface GetViewUserQuotasParams {

    /**
     * Specifies the View id.
     */
    viewId: number;

    /**
     * Specifies the user identifier of an Unix user. If a valid unix-id to SID mappings are available (i.e., when mixed mode is enabled) the server will perform the necessary id mapping and return the correct usage irrespective of whether the unix id / SID is provided.
     */
    unixUid?: number;

    /**
     * TopQuotas is the quotas sorted by quota usage in descending order. This parameter defines number of results to be returned. No pagination cookie is returned if this is set to true
     */
    topQuotas?: number;

    /**
     * Specifies a flag to just return a summary. If set to true, and if ViewId is not nil, it returns the summary of users for a view. Otherwise if UserId not nil, and ViewId is nil then it fetches the summary for a user in his views. By default, it is set to false.
     */
    summaryOnly?: boolean;

    /**
     * Specifies the user identifier of a SMB user. If a valid unix-id to SID mappings are available (i.e., when mixed mode is enabled) the server will perform the necessary id mapping and return the correct usage irrespective of whether the unix id / SID is provided.
     */
    sid?: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * OutputFormat is the Output format for the output. If it is not specified, default is json.
     */
    outputFormat?: string;

    /**
     * Specifies a limit on the number of quotas returned. If maxCount is not set, response will have a maximum of 100 results.
     */
    maxCount?: number;

    /**
     * If set to true, the result will only contain user with user quota override enabled. By default, this field is set to false, and it's only in effect when 'SummaryOnly' is set to false and 'ViewId' is specified.
     */
    includeUserWithQuotaOverridesOnly?: boolean;

    /**
     * If set to true, the logical usage info is included only for users with quota overrides. By default, it is set to false
     */
    includeUsage?: boolean;

    /**
     * This field can be set only when includeUsage is set to true. By default, all the users with logical usage > 0 will be returned in the result. If this field is set to true, only the list of users who has exceeded the alert threshold will be returned.
     */
    excludeUsersWithinAlertThreshold?: boolean;

    /**
     * Specifies the cookie. If there are more results than maxCount, response will include a cookie with has to be set as part of the next GET request.
     */
    cookie?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateViewUserQuotaSettings
   */
  export interface UpdateViewUserQuotaSettingsParams {

    /**
     * Specifies the View id.
     */
    viewId: number;

    /**
     * Specifies the parameters to enable/disable or update the default quota config on the view.
     */
    body: ViewUserQuotaSettings;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for AddViewUserQuotaOverrides
   */
  export interface AddViewUserQuotaOverridesParams {

    /**
     * Specifies the id of a view.
     */
    viewId: number;

    /**
     * Specifies the parameters to override the default user quota on the view.
     */
    body: UserQuotaOverrides;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteViewUserQuotaOverrides
   */
  export interface DeleteViewUserQuotaOverridesParams {

    /**
     * Specifies the id of a view.
     */
    viewId: number;

    /**
     * Specifies parameters to delete user quotas.
     */
    body: UserQuotaDeleteParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateViewUserQuotaOverride
   */
  export interface UpdateViewUserQuotaOverrideParams {

    /**
     * Specifies the View id.
     */
    viewId: number;

    /**
     * Specifies the unixUid or sid or an user.
     */
    userId: string;

    /**
     * Specifies the user quota policy of the user.
     */
    body: QuotaPolicy;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { ViewService }
