// Controller: GDPR Object Summary Report

;(function(angular, undefined) {
  'use strict';

  angular.module('C.reports')
    .controller('gdprObjectsController', gdprObjectsControllerFn);

  function gdprObjectsControllerFn(_, $q, $state, ReportsService, evalAJAX,
    ReportsUtil, cReportsControlsUtil, TIME) {

    var $ctrl = this;
    var registeredSourcesHash = {};
    var entitiesHash;

    _.assign($ctrl, {
      // Lifecycle hooks
      $onInit: $onInit,

      // Controller methods
      getData: getData,
      downloadCsv: downloadCsv,

      // Controller data
      loadingData: false,
      gdprObjects: [],
    });

    /**
     * Controller initialization function.
     *
     * @method   $onInit
     */
    function $onInit() {
      $ctrl.gdprObjectsConfig =
        ReportsUtil.getReportConfig('kGdprReport')();

      entitiesHash = ReportsUtil.getEntitiesHash();

      cReportsControlsUtil.getDefaultFilters();

      $q.all($ctrl.gdprObjectsConfig.datasetPromiseObject).then(
        function qAllSuccess(dataset) {
          (dataset.registeredSources || []).forEach(
            function hashSource(source) {
              registeredSourcesHash[source.value.id] = source.value;
            }
          );

          // If any query params are present, then set the filters accordingly.
          if ($state.params.objectId || $state.params.startTimeUsecs ||
            $state.params.endTimeUsecs) {

            $state.params.objectId =
              [].concat($state.params.objectId ? $state.params.objectId : []);

            cReportsControlsUtil.updateFilters($state.params);
          }

          if ((cReportsControlsUtil.filters.objectId || []).length) {
            getData(cReportsControlsUtil.filters);
          }
        }
      );
    }

    /**
     * Requests the report data via Service.
     *
     * @method   getData
     * @param    {object}    filters         The filters
     * @param    {boolean}   [formatCsv]   Indicates if report should be CSV
     * @return   {object}    Promise to resolve request for report data.
     */
    function getData(filters, formatCsv) {
      var params = _getParams(filters);

      if (formatCsv) {
        params.outputFormat = 'csv';
      }

      $ctrl.gdprObjects.length = 0;
      $ctrl.loadingData = true;

      return ReportsService.getGdprReport(params).then(
        function getReportSuccess(gdprObjects) {

          gdprObjects.forEach(function decorateObject(obj) {
            obj._entity = entitiesHash[obj.sourceId];
            obj._parentSource = registeredSourcesHash[obj.rootNodeId];
            (obj.protectionInfo || []).forEach(
              function decorateProjInfo(projInfo) {
              if (projInfo.retentionPeriod && projInfo.startTimeUsecs) {
                projInfo._expirationUsecs = projInfo.startTimeUsecs +
                  (projInfo.retentionPeriod * TIME.usecsPerDay);
              }
            });
          });

          $ctrl.gdprObjects = gdprObjects;
        },
        evalAJAX.errorMessage
      ).finally(
        function getReportFinally() {
          $ctrl.loadingData = false;
        }
      );
    }

    /**
     * returns params object for api call
     *
     * @param      {object}    filters    filters from c-reports-controls
     *
     * @return    {object}               filter values to be passed to API
     */
    function _getParams(filters) {
      var params = {
        // duplicating this value into two properties so it can serve the API
        // call and then query params / filter settings.
        id: filters.objectId || [],
        objectId: filters.objectId || [],
        parentSourceId: filters.registeredSourceIds || [],
        startTimeUsecs: filters.timeObject.from.toUsecDate(),
        endTimeUsecs: filters.timeObject.until.toUsecDate(),
      };

      $state.go('.', params);

      return params;
    }

    /**
     * Request the report with in CVS format.
     *
     * @method   downloadCsv
     */
    function downloadCsv() {
      getData(cReportsControlsUtil.filters, true);
    }

  }

})(angular);
