import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Api } from '../api.constants';
import { convertToHttpParams } from '../api.utils';
import {
  PlacesSearchOutput,
  PlacesSearchInput,
  CityData
} from '../private-models';

/**
 * Mcm cluster service.
 */
@Injectable({
  providedIn: 'root',
})
export class McmLocationServiceApi {
  constructor(private http: HttpClient) {}

  /**
   * This method tries to fetch the location matching
   * the given search query in params.
   *
   * @param params request parameters for v1 `/mcm/places`
   * @returns an array of cities based on search query
   */
  fetchLocations(params: PlacesSearchInput = {}): Observable<CityData[]> {
    return this.http.get<PlacesSearchOutput>(Api.mcm('places'), { params: convertToHttpParams(params) }).pipe(
      map(response => response?.cities)
    );
  }
}
