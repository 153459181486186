import { Injectable } from '@angular/core';
import { ProtectionRunInstance } from '@cohesity/api/v1';
import { ObjectSnapshot, SnapshotsSummary } from '@cohesity/api/v2';
import { SnapshotTargetTypeRecord } from 'src/app/modules/restore/restore-shared';

@Injectable({
  providedIn: 'root',
})
export class SnapshotsService {

  /**
   * Generates and returns a unique snapshot uid. It uses the cluster id,
   * cluster incarnation id and the job instance id to generate unique id.
   *
   * @param    run   The protected object snapshot
   * @return   Unique id to identify the object snapshot
   */
  getSnapshotUid(run: ProtectionRunInstance): string {
    return `${run.jobUid.clusterId}_${run.jobUid
      .clusterIncarnationId}_${run.backupRun.jobRunId}`;
  }

  /**
   * Get the snapshot type. Append the target id if an archival snapshot.
   *
   * @param snapshot The current snapshot.
   * @returns The snapshot type, derived from ownership context and snapshot target type;
   */
  getSnapshotType(snapshot: ObjectSnapshot|SnapshotsSummary): string {
    if (!snapshot) {
      return;
    }

    let type = snapshot.ownershipContext === 'FortKnox'
      ? 'FortKnox'
      : snapshot.snapshotTargetType;

    if (snapshot.externalTargetInfo?.targetId) {
      // No Target means "Local".
      // Only one target for "Local", therefore no need to append target id.
      type += `:${snapshot.externalTargetInfo.targetId}`;
    }

    return type;
  }

  /**
   * Function to return the latest snapshot per target type from a v2 snapshots
   * API response.
   *
   * @param snapshots The snapshots array.
   * @return The latest snapshot where the key is a snapshot target type,
   *         and value is the corresponding latest snapshot of that target type.
   */
  getLatestSnapshotByTarget(snapshots: ObjectSnapshot[]): SnapshotTargetTypeRecord<ObjectSnapshot> {
    if (!snapshots?.length) {
      return {};
    }

    // Get all unique snapshot target types.
    const targetTypes = new Set(
      snapshots.map(snapshot => this.getSnapshotType(snapshot))
    );

    // Sort snapshots array based on timestamps.
    const snapshotsArr = snapshots.sort((snapshotA, snapshotB) =>
      snapshotB.snapshotTimestampUsecs - snapshotA.snapshotTimestampUsecs
    );

    const latestSnapshotMap: SnapshotTargetTypeRecord<ObjectSnapshot> = {};

    for (const targetType of targetTypes) {
      latestSnapshotMap[targetType] = snapshotsArr.find(snapshot => this.getSnapshotType(snapshot) === targetType);
    }

    return latestSnapshotMap;
  }
}
