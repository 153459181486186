import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { ShowHideEditControlWrapperComponent } from '../components';

/**
 * Provide this service to the wrapper component containing multiple
 * <coh-show-hide-edit-control-wrapper> instances. This service is internally
 * used by all <coh-show-hide-edit-control-wrapper> to ensure only one instance
 * of this component is in edit mode at a time.
 */
@Injectable()
export class ShowHideEditControlContextService {

  /**
   * Subject to trigger closing all other items when one of them is in edit
   * mode.
   */
  private readonly itemOpenedSubject$ = new Subject<any>();

  /**
   * Subject to trigger showing errors for items.
   */
  private readonly itemShowErrorsSubject$ = new BehaviorSubject<boolean>(false);

  /**
   * Observable to subscribe to listen to itemOpenedSubject$ updates.
   */
  readonly itemOpened$ = this.itemOpenedSubject$.asObservable();

  /**
   * Observable to subscribe to listen to itemShowErrorsSubject$ updates.
   */
  readonly itemShowErrors$ = this.itemShowErrorsSubject$.asObservable();

  /**
   * Function to trigger transitioning all but passed item from edit mode to
   * view mode.
   *
   * @param componentInstance The component instance which went into edit mode.
   */
  notifyItemOpened(componentInstance: ShowHideEditControlWrapperComponent) {
    this.itemOpenedSubject$.next(componentInstance);
  }

  /**
   * Function to trigger showing errors in the items.
   *
   * @param value Value for the subject.
   */
  notifyItemShowErrors(value: boolean) {
    this.itemShowErrorsSubject$.next(value);
  }
}
