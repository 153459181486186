<cog-page>
  <cog-page-header [noMargin]="true">
    <cog-page-toolbar
      [backText]="'backToSecurityRules' | translate"
      (back)="backClick.emit()">
    </cog-page-toolbar>
  </cog-page-header>

  <cog-page-content *ngIf="!loading">
    <cog-page-content-header
      [title]="ruleName">
      <cog-page-content-header-controls>
        <button mat-icon-button
          [dgHasPermissions]="['SECOPS_MANAGE_POSTURE_ADVISOR', 'DGAAS_MODIFY']"
          matTooltip="{{ 'editRule' | translate }}"
          cogDataId="edit-role-button"
          (click)="editRuleClick.emit(ruleId)">
          <mat-icon>edit</mat-icon>
        </button>
      </cog-page-content-header-controls>

      <cog-page-content-header-navigation>
        <div class="pa-tabs">
          <mat-tab-group
            [selectedIndex]="routes.indexOf(route)"
            (selectedIndexChange)="route = routes[$event]; tabSelect.emit(route)">
            <mat-tab *ngFor="let tab of tabs" [label]="tab.name | translate"></mat-tab>
          </mat-tab-group>
        </div>
      </cog-page-content-header-navigation>
    </cog-page-content-header>
  </cog-page-content>

  <cog-spinner *ngIf="loading"></cog-spinner>

  <ng-container>
    <dg-pa-rule-details-definitions
      *ngIf="!route || route === 'definitions'">
    </dg-pa-rule-details-definitions>
    <dg-pa-clusters *ngIf="route === 'clusters'"></dg-pa-clusters>
  </ng-container>
</cog-page>
