//  Decorator: c-ui-select-checkboxes

;(function(angular, undefined) {
  'use strict';

  angular
    .module('ui.select')
    .config(uiSelectCustomConfig);

  function uiSelectCustomConfig($provide) {
    $provide.decorator('uiSelectDirective', addIsSelected);
    $provide.decorator('uiSelectDirective', collapsibleHeader);
    $provide.decorator('uiSelectChoicesDirective', templateCustomizer);
    $provide.decorator('uiSelectChoicesDirective', addCheckboxes);
    $provide.decorator('uiSelectChoicesDirective', selectAllRowsCheckbox);
    $provide.decorator('uiSelectMatchDirective', doNotShowPills);
    $provide.decorator('uiSelectMultipleDirective', alwaysShowPlaceholder);
    $provide.decorator('uiSelectDirective', hideOverflow);
  }

  /**
   * @ngdoc decorator
   * @name        ui.select
   * @method      templateCustomizer
   *
   * @description
   * Replaces the uiSelectChoices templateUrl function so that our customized
   * template gets loaded.
   */
  function templateCustomizer($delegate) {
    $delegate[0].templateUrl = function uiSelectChoicesTemplateFn(tElement) {
      // Needed so the uiSelect can detect the transcluded content
      tElement.addClass('ui-select-choices');
      return 'app/global/c-ui-select/c-ui-select-choices.html';
    };

    return $delegate;
  }

  /**
   * @ngdoc decorator
   * @name        ui.select
   * @method      addIsSelected
   *
   * @description
   * Decorate uiSelectCtrl to add an isSelected() function to be utilized in
   * customized template (app/global/c-ui-select/c-ui-select-choices.html).
   * Implementation is automatic via the template override.
   */
  function addIsSelected($delegate, $controller) {
    var directive = $delegate[0];
    var ctrlName = directive.controller;

    directive.controller = uiSelectIsSelectedCtrl;

    return $delegate;

    /* @ngInject */
    function uiSelectIsSelectedCtrl($scope, $element) {
      var ctrl = $controller(ctrlName, {
        $scope: $scope,
        $element: $element,
      });

      /**
       * Indicates if provided itemScope represents an item that is selected.
       *
       * @method   isSelected
       * @param    {object}    itemScope   The item scope
       * @return   {boolean}   True if selected, False otherwise.
       */
      ctrl.isSelected = function isSelected(itemScope) {
        var item = itemScope[ctrl.itemProperty];

        return ctrl.open &&
          ctrl.selected &&

          // if ctrl.selected equals the item (single select)
          (angular.equals(item, ctrl.selected) ||

            // or the item is represented in the ctrl.selected mutliselect array
            (angular.isArray(ctrl.selected) &&
              ctrl.selected.find(function filterSelected(selection) {
                return angular.equals(selection, item);
              })
            )
          );
      };

      return ctrl;
    }
  }

  /**
   * @ngdoc decorator
   * @name        ui.select
   * @method      collapsibleHeader
   *
   * @description
   * Decorate uiSelectCtrl to add collapse/expand functionality to the
   * ui-select-choices group headers.
   */
  function collapsibleHeader($delegate, $controller) {
    var directive = $delegate[0];
    var ctrlName = directive.controller;

    directive.controller = uiSelectCollapsibleHeaderCtrl;

    return $delegate;

    /* @ngInject */
    function uiSelectCollapsibleHeaderCtrl($scope, $element, $attrs) {
      var ctrl = $controller(ctrlName, {
        $scope: $scope,
        $element: $element,
      });

      ctrl.collapsible = $scope.$eval($attrs.collapsibleHeader);

      /**
       * collpase/expand a group header on click
       *
       * @method   toggleGroupHeader
       * @param    {object}    group    The group
       */
      ctrl.toggleGroupHeader = function toggleGroupHeader(group) {

        // Don't do anything if collapsible-header attribute is not passed
        // or if its an anonymous group (group with no name)
        if (!ctrl.collapsible || !group.name) {
          return;
        }

        group.collapsed = !group.collapsed;
      };

      return ctrl;
    }
  }

  /**
   * @ngdoc decorator
   * @name        ui.select
   * @method      addCheckboxes
   *
   * @description
   * Decorate ui-select to display checkboxes with no disabled appearance and to
   * allow directly deselecting the menu item.
   *
   *  Required attributes:
   *    remove-selected="false" on <ui-select> // Built-in ui-select attribute
   *    checkboxes="true"       on <ui-select-choices>
   *
   * @example
   *
      <ui-select
        remove-selected="false">

        <ui-select-choices
          checkboxes="true">
          <div>
            // All DOM should be contained within a single element
          </div>
        </ui-select-choices>
      </ui-select>
   *
   * TODO (David): Figure out how to eliminate the requirement for the extra DOM
   * element by doing something like:
        element.querySelectorAll('.ui-select-choices-row-inner')
        .wrapInner('<div class="innerWrapper"></div>');
   *
   */
  function addCheckboxes($delegate) {
    var directive = $delegate[0];
    var compile = directive.compile;

    directive.compile = function uiSelectChoicesCompile(element, attrs) {

      if (attrs.checkboxes === 'true') {
        // Add an identifying class to ui-select-choices element for this
        // implementation.
        element.addClass('c-ui-select-checkboxes');

        // Overwrite the default ng-class directive which adds 'active' and
        // 'disabled' classes when the item is selected. But we don't want the
        // disabled appearance in this checkbox implementation.
        element.querySelectorAll('.ui-select-choices-row')
          .attr('ng-class','{active: $select.isActive(this)}');

        // Add an ng-click directive on each row that allows the row to be
        // deselected even when 'disabled'. Why? The default ui-select
        // behavior is that you can't interact with the disabled/selected
        // item: you must remove the item directly from the text input, but
        // that's not the implementation we want with checkboxes.
        // NOTE: calling $select.isDisabled 2 times one before and another after
        // removing the choice because $select.isDisabled internally updates
        // disabledItems list which is used to prevent addition of selected item
        // again hence to make things works in sync calling isDisabled again.
        // removing the choice only if item is selected otherwise allow click
        // event to propagate to its parent DOM which is handling item selection
        element.querySelectorAll('.ui-select-choices-row-inner')
          .attr('ng-click', '$select.isDisabled(this) && $select.isSelected(this) && $selectMultiple.removeChoice($select.selected.indexOf(this[$select.itemProperty])) && !$select.isDisabled(this) && $event.stopPropagation()')
          .prepend('<input type="checkbox" id="ui-select-choices-row-inner-{{::$index}}" ng-checked="$select.selected.includes(this[$select.itemProperty])">');
      }

      return compile.apply(this, arguments);
    };

    return $delegate;
  }

  /**
   * @ngdoc decorator
   * @name        ui.select
   * @method      doNotShowPills
   *
   * @description When 'hide-matches' is on the ui-select *root* element, do not
   *              display pills inside the input.
   */
  function doNotShowPills($delegate) {
    var directive = $delegate[0];
    var compile = directive.compile;

    directive.compile = function proxyCompileFn(element, attrs) {
      var parentEl =  element.parent();
      var hasHideMatchesAttr = parentEl.length &&
        parentEl[0].attributes.hasOwnProperty('hide-matches');

      element.find('span[ng-repeat]')
        .attr('ng-class','{hidden: $select.hideMatches}');

      // Cache the existing linkFn to execute after our decorator.
      var linkFn = compile.apply(this, arguments);

      return function proxyLinkFn(scope, elem, attrs, uiSelect) {
        // set ui-select controller hideMatches.
        uiSelect.hideMatches = hasHideMatchesAttr;

        // Execute the default linkFn now.
        return linkFn.apply(this, arguments);
      };
    };

    return $delegate;
  }

  /**
   * @ngdoc decorator
   * @name        ui.select
   * @method      alwaysShowPlaceholder
   *
   * @description When 'hide-matches' attribute is found on the ui-select root
   *              element, always show placeholder even when items have been
   *              selected.
   */
  function alwaysShowPlaceholder($delegate) {
    var directive = $delegate[0];

    var link = directive.link;
    directive.compile = function uiSelectMultipleCompile() {
      return function(scope, element, attrs, ctrls) {
        link.apply(this, arguments);

        // getting original get placeholder fn after linking.
        var getPlaceholder = scope.$selectMultiple.getPlaceholder;

        // Replace getPlaceholder function with a new version which always
        // returns the placeholder value.
        // Modified get placeholder fn to return placeholder when ui-select is
        // configured to hide matched choices.
        scope.$selectMultiple.getPlaceholder =
          function getPlaceholderDecorated(){
            if (scope.$select.hideMatches) {
              return scope.$select.placeholder;
            }

            return getPlaceholder.apply(this, arguments);
          };
      };
    };

    return $delegate;
  }

  /**
   * @ngdoc decorator
   * @name        ui.select
   * @method      selectAllRowsCheckbox
   *
   * @description Provide a primary custom checkbox for the ui-select elements.
   */
  function selectAllRowsCheckbox($delegate, $log) {
    var uiChoices = $delegate[0];
    var compile = uiChoices.compile;

    uiChoices.compile = function compileForUiSelectAllCheckboxFn() {
      // Cache the existing linkFn to execute after our decorator.
      var linkFn = compile.apply(this, arguments);

      return function uiSelectAllCheckboxLinkFn(scope, element, attrs, ctrl) {
        // holds the internal mutable state properties for
        // select-all-rows-checkbox

        // Exposing selectAll to both scope and controller scope
        ctrl.selectAll = scope.selectAll = {
          allRowsCheckbox: false,
          checkboxClickHandler: undefined,
          allRowsCheckboxState: undefined,
          allRowsCheckboxLabel: '',
          allRowsCheckboxFn: undefined,
        };

        scope.selectAll.allRowsCheckboxFn =
          scope.$eval(attrs.allRowsCheckboxFn);

        if (angular.isFunction(scope.selectAll.allRowsCheckboxFn) &&
            scope.$eval(attrs.allRowsCheckboxEnable)) {
          scope.selectAll.allRowsCheckbox = true;

          // Use the allRows label, or use the default.
          scope.selectAll.allRowsCheckboxLabel = attrs.allRowsCheckboxLabel;
        }

        /**
         * Add new allRows checkbox click handler.
         *
         * @method   checkboxClickHandler
         */
        scope.selectAll.checkboxClickHandler =
          function checkboxClickHandler(checked) {
          if (!scope.selectAll.allRowsCheckboxFn) {
            $log.error(
              'ui.select: allRowsCheckbox',
              'all-rows-checkbox-fn is required.'
            );
            return;
          }

          scope.selectAll.allRowsCheckboxFn(checked);
        };

        attrs.$observe('allRowsCheckboxState',
          function observeCheckState(value) {
          scope.selectAll.allRowsCheckboxState =
            (value === 'false' ? false : (value === 'true' ? true : undefined));
        });

        linkFn.apply(this, arguments);
      };
    };

    return $delegate;
  }

  /**
   * @ngdoc   decorator
   * @name    ui.select
   * @method  hideOverflow
   *
   * @description Prevents ui-select items wrapping and creating new line,
   *              all items will remain on one line and overflow count element
   *              will show how many items are hidden.
   *
   * @example
   *    <ui-select
   *      hide-overflow="true"
   *      multiple>
   *      <ui-select-match>
   *        {{$item}}
   *      </ui-select-match>
   *      <ui-select-choices
   *        checkboxes="true"
   *        ng-repeat="choice in choices">
   *        {{choice}}
   *      </<ui-select-choices
   *    </ui-select>
   */
  function hideOverflow($delegate, $compile, $timeout) {
    var directive = $delegate[0];
    var directiveCompile = directive.compile;

    directive.compile = function directiveCompileFn(tElement, tAttrs) {
      var link = directiveCompile.apply(this, arguments);
      return function directiveLinkFn(scope, elem, attrs, ctrls) {
        var hideOverflow = scope.$eval(attrs.hideOverflow);
        var ctrl = ctrls[0];
        var eclipseEl;
        var container;
        var children;
        var len = 0;
        var renderTimeoutPromise;
        var render = function render() {
          var overflowIndex = -1;
          var containerWidth = container.width();
          var tooltipItems = [];
          var childText = '';
          var i;
          var childrenWidth = 0;
          var childWidth = 0;
          var widthRemainder = 0;
          // Allocate min space for items to avoid text cutoff.
          var minWidth = 0;

          container.find('> .hidden').removeClass('hidden');
          container.removeClass('has-count');
          container.removeClass('overflow');

          children.each(function eachChild(index, child) {
            minWidth = index * 100;
            widthRemainder =
              containerWidth - Math.max(minWidth, childrenWidth);

            childWidth = child.offsetWidth;
            childrenWidth += childWidth;

            if (index > 0 && overflowIndex < 0 && childWidth > widthRemainder) {
              overflowIndex = index;
            }

            if (overflowIndex > -1) {
              childText =
                child
                  .querySelector('span[uis-transclude-append]')
                  .textContent.trim();
              tooltipItems.push(childText);
            }
          });

          if (overflowIndex > -1) {
            container.addClass('has-count');
            eclipseEl.text('+' + (len - overflowIndex));
            scope.overflowTooltip = tooltipItems.join(', ');
          }

          childrenWidth = 0;
          for (i = 0; i < children.length; i++) {
            if (overflowIndex > -1 && i >= overflowIndex) {
              children[i].classList.add('hidden');
            } else {
              childrenWidth += children[i].offsetWidth;
            }
          }

          containerWidth = container.width();
          if (childrenWidth > containerWidth) {
            container.addClass('overflow');
          }
        };

        /**
         * Tooltip for overflow count element.
         * @type {string}
         */
        scope.overflowTooltip = '';

        link.apply(this, arguments);

        if (hideOverflow && ctrl.multiple) {
          elem.addClass('c-ui-select-hide-overflow');

          eclipseEl = $compile(
            '<span class="ui-select-match-item overflow-count"' +
            ' uib-tooltip="{{overflowTooltip}}"></span>')(scope);

          container = elem.find('.ui-select-match');
          container.after(eclipseEl);

          scope.$watch('$select.selected', function uiSelectChange() {
            var childOneText = '';

            container = elem.find('.ui-select-match');
            children = container.children();
            len = children.length;

            if (len > 0) {
              $timeout.cancel(renderTimeoutPromise);

              childOneText = children[0]
                .querySelector('span[uis-transclude-append]')
                .textContent.trim();

              // Delay rendering until uis-transclude-append is done
              if (childOneText === '') {
                renderTimeoutPromise = $timeout(render, 100);
              } else {
                render();
              }
            } else {
              render();
            }
          });
        }
      };
    };

    return $delegate;
  }
})(angular);
