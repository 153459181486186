import {Component} from '@angular/core';

@Component({
  selector: 'coh-multitask-table',
  templateUrl: './multitask-table.component.html',
  styleUrls: ['./multitask-table.component.scss']
})
export class MultitaskTableComponent {

}
