import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { AngularMaterialModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';

import { SnapshotIndicatorComponent } from './snapshot-indicator.component';

const COMPONENTS = [SnapshotIndicatorComponent];

@NgModule({
  imports: [AngularMaterialModule, TranslateModule.forChild()],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
})
export class SnapshotIndicatorModule {}
