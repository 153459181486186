// Directive: cTabs

/**
 * NOTE: This directive is DEPRECATED. Please upgrade your implementation to use
 * ui-router-tabs or uibTabset instead. Examples:
 * https://{CLUSTER_IP}/examples/tabs
 */

;(function(angular, undefined) {
  'use strict';

  angular.module('C.tabs', [])
    .directive('cTabs', cTabsDirective);

  function cTabsDirective($state, $transitions) {
    return {
      restrict: 'AE',
      replace: true,
      require: 'ngModel',
      scope: {
        tabs: '=',
        id: '@?',
        ngModel: '=',
        activeTabIndex: '=?',
      },
      templateUrl: 'app/global/cTabs/cTabs.html',
      link: linkFn,
    };

    /**
     * ngDirective linkFn
     *
     * @method   linkFn
     * @param    {object}     scope         The scope
     * @param    {object}     elem          The element
     * @param    {object}     attrs         The attributes
     * @param    {function}   ngModelCtrl   The ng model controller
     */
    function linkFn(scope, elem, attrs, ngModelCtrl) {
      /**
       * Initial load to determine initial variable assignment.
       *
       * @method   init
       */
      function init() {
        var activeTabIndex = getActiveTabIndex();

        if (activeTabIndex > -1) {
          scope.activeTabIndex = activeTabIndex;
        }

        // Setup transition hook to ensure tabs are in sync with actual state
        // changes. Cleans up on scope destroy.
        scope.$on('$destroy',
          $transitions.onSuccess({}, updateTabOnStateChange));
      }

      /**
       * Updates the activeTabIndex if its out of sync with the actual tab being
       * displayed to the user. This is generally caused by use of browser back
       * button.
       *
       * @param {object}   trans   The transition object via uiRouter
       */
      function updateTabOnStateChange(trans) {
        var toStateName = trans.$to().self.name;
        var newTabIndex;

        if (scope.tabs[scope.activeTabIndex].value !== toStateName) {

          // The currently marked active tab does not reflect reality. Likely
          // the user hit "back" in the browser. Update the tabs to reflect the
          // new reality.
          newTabIndex = scope.tabs.findIndex(tab => tab.value === toStateName);

          if (newTabIndex > -1) {
            scope.activeTabIndex = newTabIndex;
          }
        }
      }

      /**
       * Gets the active tab index. Return the tab index for the tab which
       * matches current route state.
       *
       * @method   getActiveTabIndex
       * @return   {number}   The active tab index.
       */
      function getActiveTabIndex() {
        return scope.tabs.findIndex(function findActiveTabIndex(tab) {
          return $state.is(tab.value);
        });
      }

      /**
       * activate tab on click.
       *
       * @method   onTabClick
       * @param    {object}   tab   The tab
       */
      scope.onTabClick = function onTabClick(tab) {
        if (tab.value && !tab.noState) {
          $state.go(tab.value, tab.params);
        }

        ngModelCtrl.$setViewValue(tab.value);
      };

      init();

    }
  }

})(angular);
