import { NavItem } from '@cohesity/helix';
import { IrisContext, isHeliosTenantUser, isTenantUser } from '@cohesity/iris-core';
import { StateManagementService } from '../services';

/**
 * Indicates if the provided nav should be hidden or not.
 *
 * @param    navItem    the navItem to evaluate
 * @return   true if navItem should be hidden, false if not
 */
export function isNavItemHidden(
  navItem: NavItem,
  irisContext: IrisContext,
  stateManagentService: StateManagementService
): boolean {

  // hiding dashboard nav link for tenants.
  // Remove restriction for restricted access users, as they are able to visit
  // the sql dashboard at a minimum.
  if ((isTenantUser(irisContext) && !isHeliosTenantUser(irisContext)) &&
    ['dashboards', 'dashboard'].includes(navItem.state)) {
    return true;
  }

  // Never hide external links.
  if (navItem.href || !navItem.state) {
    return false;
  }

  // If the user isn't allowed access to the state, then the navItem should be hidden.
  return !stateManagentService.canUserAccessState(navItem.state);
}
