import { Injectable } from '@angular/core';
import { flagEnabled, IrisContextService, isMcmGovCloud } from '@cohesity/iris-core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

/**
 * The cookiebot keys are tied to a specific domains. This key is tied to the
 * domain helios-sbx.cohesity.com. Trying to load cookiebot on any other domain
 * with this key will gracefully fail.
 */
const CookiebotKey = '7a15c274-5c41-4982-aaa5-1b37d6516df9';

@Injectable({
  providedIn: 'root'
})
export class CookieNoticeService {
  /**
   * Whether the application is in mcm mode.
   */
  mcmMode = false;

  constructor(private irisContextService: IrisContextService) {
    // This is initialized earlier than initCookieNotice() being called in the
    // lifecycle, so is safe to initialize this way.
    this.irisContextService.irisContext$?.pipe(
      map(value => Boolean(value?.basicClusterInfo?.mcmMode)),
    ).subscribe(value => {
      this.mcmMode = value;
    });
  }

  /**
   * Function to initialize and show cookiebot notice.
   */
  initCookieNotice() {
    if (!environment.production || !this.mcmMode) {
      // Do not show cookie notice in development environment or for non
      // mcm users currently.
      return;
    }

    if (isMcmGovCloud(this.irisContextService.irisContext)
      && !flagEnabled(this.irisContextService.irisContext, 'cookieNoticeMcmGovCloudEnabled')) {
      // Don't load cookiebot in FedRAMP env unless feature flag is set to true
      return;
    }

    // Header script which loads the cookiebot js bundle. This must always be in
    // the <head> and the first thing which loads (we do not have anything else
    // which loads in <head>).
    const headerScript = document.createElement('script');
    headerScript.setAttribute('src', 'https://consent.cookiebot.com/uc.js');
    headerScript.setAttribute('id', 'Cookiebot');
    headerScript.setAttribute('data-cbid', CookiebotKey);

    window.document.head.appendChild(headerScript);
  }
}
