import { Injectable } from '@angular/core';

import { MicroFrontendHostConfig } from './../micro-frontend-host.models';

@Injectable()
export class MicroFrontendHostOptions {
  /**
   * The app config and other micro frontend host options.
   */
  readonly default: MicroFrontendHostConfig = Object.freeze({
    appConfigs: [],
    pluginConfigs: [],
    getCustomPropsFn: null,
    goHome: null,
  } as MicroFrontendHostConfig);

  private _options: MicroFrontendHostConfig = { ...this.default };

  get options(): MicroFrontendHostConfig {
    return this._options;
  }

  set options(newOptions: Partial<MicroFrontendHostConfig>) {
    this._options = {
      ...this.default,
      ...newOptions,
    };
  }
}
