// Component: cUpload

(function(angular) {
  'use strict';

  angular
    .module('C.upload', [])
    .controller('CUploadCtrl', CUploadCtrlFn)
    .component('cUpload', {
      bindings: {
        /*
        @type   {Object}
          {
            confirmBtnKey: {String},    Text to be displayed on confirm button

            cancelBtnKey: {String},     Text to be displayed on cancel button

            confirmCallback: {function}, Funtion to be called on succesfully
                                         uploading of file

            cancelCb: {Function},        Funtion to be called when user press
                                         Cancel button

            url: {String},               EndPoint where file to be uploaded

            icon: {String}               icon to displayed adjacent to progress
                                         bar
            onFileSelect: {Function}     Callback function for when a file is
                                         added for upload
          }
        */
        config: '='
      },
      controller: 'CUploadCtrl',
      templateUrl: 'app/global/c-upload/c-upload.html'
    });

  function CUploadCtrlFn(_, Upload, CHART_COLORS, evalAJAX) {
    var $ctrl = this;
    _.assign($ctrl, {
      // Component Life Cycle hook
      $onInit: onInit
    });

    /**
     * Activate the controller
     *
     * @method   onInit
     */
    function onInit() {

      // To get color to be used for Progress bar
      $ctrl.CHART_COLORS = CHART_COLORS;

      // Determines wheter file uploading is in Progress or
      // user has option to choose or change file
      $ctrl.uploading = false;
    }

    /**
     *  Function to start upload process
     *  @method   uploadFile
     */
    $ctrl.uploadFile = function uploadFile() {
      // Changes the display to show uploading screen.
      $ctrl.uploading = true;

      var uploadObject = {
        // Endpoint on which the post method will be called.
        url: $ctrl.config.url,

        // When user will select File, it will be saved in ctrl by ngf-upload.
        data: $ctrl.file
      }

      //check if component is passed some headers
      if ($ctrl.config.headers)
        uploadObject.headers = $ctrl.config.headers

      // $ctrl.upload will get promise object which can be used to track the
      // progress
      $ctrl.upload = Upload.http(uploadObject);
      $ctrl.upload
        .progress(function uploadFilePogress(evt) {
          $ctrl.uploadPercentageComplete =
            parseInt((100.0 * evt.loaded) / evt.total,10);
        })
        .success(function uploadFileSuccess(response) {
          $ctrl.config.confirmCallback(response);
        })
        .error(function showError(response) {
          evalAJAX.errorMessage({ data: response });
        });
    };

    /**
     * Function to cancel upload process
     *
     * @method  cancelUpload
     */
    $ctrl.cancelUpload = function cancelUpload() {

      // Cancels the  Api request.
      $ctrl.upload.abort();

      // Changes the display to choose file screen
      $ctrl.uploading = false;
    };

    /**
     * Callback for when a file is successfully selected/dropped
     *
     * @method  onFileSelect
     */
    $ctrl.onFileSelect = function onFileSelect() {
      if (_.isFunction($ctrl.config.onFileSelect)) {
        $ctrl.config.onFileSelect($ctrl.file);
      }
    };
  }
})(angular);
