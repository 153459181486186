import { Pipe, PipeTransform } from '@angular/core';
import { StorageDomain } from '@cohesity/api/v2';
import { TranslateService } from '@ngx-translate/core';

/**
 * This pipe provides a string value representing a storage domain's compression setting
 *
 * @example
 *   {{ viewbox | ngViewBoxCompression}}     Inline
 */
@Pipe({
  name: 'ngViewBoxCompression'
})
export class NgViewBoxCompressionPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  /**
   * Implements the pipe.
   *
   * @param   storageDomain   The storage domain object.
   * @return  storage domain compression status.
   */
  transform(storageDomain: StorageDomain): string {
    if (!storageDomain ||
      !storageDomain.storagePolicy) {
      return this.translate.instant('naNotAvailableLetters');
    }

    const {compressionParams} = storageDomain.storagePolicy;

    switch (true) {
      case !compressionParams:
        return this.translate.instant('no');
      case compressionParams && compressionParams.inlineEnabled:
        return this.translate.instant('inline');

      case compressionParams && !compressionParams.inlineEnabled:
        return this.translate.instant('post');

      // some other case, fallback for uncertainty
      default:
        return this.translate.instant('naNotAvailableLetters');
    }
  }

}
