/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FortKnoxVaultInfoList } from '../models/fort-knox-vault-info-list';
import { RpaasOnboardInfo } from '../models/rpaas-onboard-info';
import { RpaasRegionInfoList } from '../models/rpaas-region-info-list';
import { AddRpaasRegionsRequest } from '../models/add-rpaas-regions-request';
import { VerifyS3CredentialsInfo } from '../models/verify-s3credentials-info';
import { VerifyS3CredentialsRequest } from '../models/verify-s3credentials-request';
@Injectable({
  providedIn: 'root',
})
class RpaasService extends __BaseService {
  static readonly GetFortKnoxVaultsPath = '/mcm/fknox/vaults';
  static readonly GetRpaasOnboardPath = '/mcm/ransomware-shield/complete';
  static readonly CompleteRpaasOnboardPath = '/mcm/ransomware-shield/complete';
  static readonly GetRpaasRegionsPath = '/mcm/ransomware-shield/regions';
  static readonly AddRpaasRegionsPath = '/mcm/ransomware-shield/regions';
  static readonly VerifyS3CredentialsPath = '/mcm/ransomware-shield/verify-s3-credentials';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get FortKnox vaults.
   *
   * Get the list of FortKnox vaults for the logged in account.
   * @param regionIds List of region IDs to filter the response.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetFortKnoxVaultsResponse(regionIds?: null | Array<null | 'us-east-1' | 'us-east-2' | 'us-west-1' | 'us-west-2' | 'ca-central-1' | 'ap-northeast-1' | 'ap-south-1' | 'ap-southeast-1' | 'ap-southeast-2' | 'eu-central-1' | 'eu-west-2' | 'me-south-1' | 'eu-west-3'>,
    regionId?: string): __Observable<__StrictHttpResponse<FortKnoxVaultInfoList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (regionIds || []).forEach(val => {if (val != null) __params = __params.append('regionIds', val.toString())});
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/fknox/vaults`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FortKnoxVaultInfoList>;
      })
    );
  }
  /**
   * Get FortKnox vaults.
   *
   * Get the list of FortKnox vaults for the logged in account.
   * @param regionIds List of region IDs to filter the response.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetFortKnoxVaults(regionIds?: null | Array<null | 'us-east-1' | 'us-east-2' | 'us-west-1' | 'us-west-2' | 'ca-central-1' | 'ap-northeast-1' | 'ap-south-1' | 'ap-southeast-1' | 'ap-southeast-2' | 'eu-central-1' | 'eu-west-2' | 'me-south-1' | 'eu-west-3'>,
    regionId?: string): __Observable<FortKnoxVaultInfoList> {
    return this.GetFortKnoxVaultsResponse(regionIds, regionId).pipe(
      __map(_r => _r.body as FortKnoxVaultInfoList)
    );
  }

  /**
   * Get the RPaaS onboarding status.
   *
   * Get the onboarding status for RPaaS for the logged in user.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetRpaasOnboardResponse(regionId?: string): __Observable<__StrictHttpResponse<RpaasOnboardInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/ransomware-shield/complete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RpaasOnboardInfo>;
      })
    );
  }
  /**
   * Get the RPaaS onboarding status.
   *
   * Get the onboarding status for RPaaS for the logged in user.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetRpaasOnboard(regionId?: string): __Observable<RpaasOnboardInfo> {
    return this.GetRpaasOnboardResponse(regionId).pipe(
      __map(_r => _r.body as RpaasOnboardInfo)
    );
  }

  /**
   * Complete onboarding for RPaaS.
   *
   * Complete onboarding for RPaaS for the logged in user.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  CompleteRpaasOnboardResponse(regionId?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/ransomware-shield/complete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Complete onboarding for RPaaS.
   *
   * Complete onboarding for RPaaS for the logged in user.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  CompleteRpaasOnboard(regionId?: string): __Observable<null> {
    return this.CompleteRpaasOnboardResponse(regionId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get the Rpaas regions.
   *
   * Get the list of Rpaas regions enabled for the logged in account.
   * @param regionIds List of region IDs to filter the response.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetRpaasRegionsResponse(regionIds?: null | Array<null | 'us-east-1' | 'us-east-2' | 'us-west-1' | 'us-west-2' | 'ca-central-1' | 'ap-northeast-1' | 'ap-south-1' | 'ap-southeast-1' | 'ap-southeast-2' | 'eu-central-1' | 'eu-west-2' | 'me-south-1' | 'eu-west-3'>,
    regionId?: string): __Observable<__StrictHttpResponse<RpaasRegionInfoList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (regionIds || []).forEach(val => {if (val != null) __params = __params.append('regionIds', val.toString())});
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/ransomware-shield/regions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RpaasRegionInfoList>;
      })
    );
  }
  /**
   * Get the Rpaas regions.
   *
   * Get the list of Rpaas regions enabled for the logged in account.
   * @param regionIds List of region IDs to filter the response.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetRpaasRegions(regionIds?: null | Array<null | 'us-east-1' | 'us-east-2' | 'us-west-1' | 'us-west-2' | 'ca-central-1' | 'ap-northeast-1' | 'ap-south-1' | 'ap-southeast-1' | 'ap-southeast-2' | 'eu-central-1' | 'eu-west-2' | 'me-south-1' | 'eu-west-3'>,
    regionId?: string): __Observable<RpaasRegionInfoList> {
    return this.GetRpaasRegionsResponse(regionIds, regionId).pipe(
      __map(_r => _r.body as RpaasRegionInfoList)
    );
  }

  /**
   * Add rpaas regions for an account.
   *
   * Add Cohesity RPaaS service in a given set of regions for the logged in account.
   * @param body Specifies the parameters to add RPaas service in the regions.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  AddRpaasRegionsResponse(body: AddRpaasRegionsRequest,
    regionId?: string): __Observable<__StrictHttpResponse<RpaasRegionInfoList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/ransomware-shield/regions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RpaasRegionInfoList>;
      })
    );
  }
  /**
   * Add rpaas regions for an account.
   *
   * Add Cohesity RPaaS service in a given set of regions for the logged in account.
   * @param body Specifies the parameters to add RPaas service in the regions.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  AddRpaasRegions(body: AddRpaasRegionsRequest,
    regionId?: string): __Observable<RpaasRegionInfoList> {
    return this.AddRpaasRegionsResponse(body, regionId).pipe(
      __map(_r => _r.body as RpaasRegionInfoList)
    );
  }

  /**
   * Verify getting RPaaS S3 credentials.
   *
   * Verify getting RPaaS S3 credentials by checking restored objects and search jobs.
   * @param body Specifies the parameters to verify getting RPaaS S3 credentials.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  VerifyS3CredentialsResponse(body: VerifyS3CredentialsRequest,
    regionId?: string): __Observable<__StrictHttpResponse<VerifyS3CredentialsInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/ransomware-shield/verify-s3-credentials`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VerifyS3CredentialsInfo>;
      })
    );
  }
  /**
   * Verify getting RPaaS S3 credentials.
   *
   * Verify getting RPaaS S3 credentials by checking restored objects and search jobs.
   * @param body Specifies the parameters to verify getting RPaaS S3 credentials.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  VerifyS3Credentials(body: VerifyS3CredentialsRequest,
    regionId?: string): __Observable<VerifyS3CredentialsInfo> {
    return this.VerifyS3CredentialsResponse(body, regionId).pipe(
      __map(_r => _r.body as VerifyS3CredentialsInfo)
    );
  }
}

module RpaasService {
}

export { RpaasService }
