import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { takeUntil } from 'rxjs/operators';
import { AutoDestroyable } from '@cohesity/utils';
import { DialogService } from 'src/app/core/services';

@Component({
  selector: 'coh-filter-ip-config',
  templateUrl: './filter-ip-config.component.html'
})
export class FilterIpConfigComponent extends AutoDestroyable implements OnInit {
  /**
   * The input form control where data needs to be binded.
   */
  @Input() control: UntypedFormControl;

  /**
   * Material chip keyboard separators for adding the new chip.
   */
  readonly separatorKeysCodes = [ENTER, COMMA, SPACE];

  /**
   * Default filter config
   */
  defaultFilterIpConfig = {
    filterType: 'allow',
    ipList: [],
  };

  /**
   * Form Group for the component
   */
  filterIpConfigFormGroup: UntypedFormGroup = new UntypedFormGroup({
    filterType: new UntypedFormControl('', Validators.required),
    ipList: new UntypedFormControl([], Validators.required)
  });

  constructor(private dialogService: DialogService) {
    super();
  }

  /**
   * Getter for filterType Form Control.
   */
  get filterTypeFormControl(): UntypedFormControl {
    return this.filterIpConfigFormGroup.get('filterType') as UntypedFormControl;
  }

  /**
   * Getter for ipList FormControl.
   */
  get ipListFormControl(): UntypedFormControl {
    return this.filterIpConfigFormGroup.get('ipList') as UntypedFormControl;
  }

  /**
   * OnInit sets the form group value to input control or default value if
   * there is not input to the component.
   */
  ngOnInit() {
    this.filterIpConfigFormGroup.setValue(this.control?.value || this.defaultFilterIpConfig);
  }

  /**
   * When filter type is switched and ip list is configured, user gets a
   * confirmation dialog to confirm filter change. The filter is changed only on
   * user confirmation.
   */
  switchFilterType(event) {
    // If there are no ips configured, no need for confirmation dialog
    if (!this.ipListFormControl.value[0]) {
      return;
    }

    const dialogData = {
      title: 'protectionGroups.filterIps.modalTitle',
      copy: 'protectionGroups.filterIps.modalCopy',
      confirmButtonLabel: 'switch',
      declineButtonLabel: 'cancel'
    };

    this.dialogService.simpleDialog(null, dialogData, { width: '25rem', autoFocus: false })
      .pipe(takeUntil(this._destroy))
      .subscribe((switched) => {
        if (switched) {
          this.emitControl();
          return;
        }

        const previousValue = event.value === 'allow' ? 'deny' : 'allow';
        this.filterTypeFormControl.setValue(previousValue);
      });
  }

  /**
   * Adding an IP address to the chip list
   *
   * @param   event   MatChipInputEvent which has input and value
   */
  addIpAddress(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;
    const currentValues = this.ipListFormControl.value as string[] || [];
    if ((value || '').trim()) {
      if (currentValues.indexOf(value) === -1) {
        this.ipListFormControl.setValue([
          ...currentValues,
          value,
        ]);
      }
      input.value = '';
    }
    this.emitControl();
  }

  /**
   * Remove a ip address from input of chip list.
   *
   * @param   index   index of the ip mat chip that is being removed.
   */
  removeIpAddress(index: number) {
    const ipList = this.ipListFormControl.value;
    ipList.splice(index, 1);
    this.ipListFormControl.setValue(ipList);
    this.emitControl();
  }

  /**
   * Emits the control changes to the parent component.
   */
  emitControl() {
    this.control.setValue({
      filterType: this.filterTypeFormControl.value,
      ipList: this.ipListFormControl.value
    });
  }
}
