<div class="title-header">
  <div class="title-wrapper">
    <cog-icon [shape]="data.restorePoint.objectInfo | searchObjectIcon"></cog-icon>
    <h2 class="no-margin title user-data-xl">{{ data.object.name }}</h2>
  </div>

  <mat-slide-toggle cogDataId="browse-on-indexed-data"
    class="index-toggle"
    *ngIf="!data.hideIndexing"
    [checked]="browseOnIndexedData"
    [disabled]="disableIndexingToggle"
    (change)="indexedDataChange($event)"
    [matTooltip]="
      disableIndexingToggle ? ((indexingAvailable ? 'indexedDataOnly' : 'indexedDataIsNotAvailable') | translate) : null
    ">
    {{ 'browseOnIndexedData' | translate }}
  </mat-slide-toggle>

  <coh-snapshot-selector-menu
    [restorePoint]="restorePointSelection"
    (snapshotChanged)="onSnapshotChanged($event)"
    [snapshotActions]="snapshotActions"
    [objectActionKeys]="objectActionKeys"
    >
  </coh-snapshot-selector-menu>
</div>

<mat-dialog-content>
  <div class="content-pane">
    <div class="content-header">
      <coh-address-bar *ngIf="!fbContext.hasNoSupportedVolumes"
        [currentPath]="fbContext.currentPath"
        [getMenuItemsFn]="fbContext.getMenuItems"
        [allowUserInput]="true"
        [allowOnlyUserInput]="unbrowsable"
        (browseToPath)="fbContext.browseToPath($event)"
        [resetIcon]="data.restorePoint.objectInfo | searchObjectIcon"
        (browseToUserInputPath)="browseToUserInputPath($event)">
      </coh-address-bar>
    </div>
    <div class="content-listing">
      <coh-directory-list *ngIf="!fbContext.hasNoSupportedVolumes && !unbrowsable"
        [documents]="fbContext.currentDirectory$ | async"
        [isPartialListing]="!!fbContext.directoryCookie"
        [selection]="selection"
        [isLoading]="fbContext.isLoading$ | async"
        [canSelectRowFn]="canSelectItemInDirectoryListFn"
        [canNavigateRowFn]="canNavigateItemInDirectoryListFn"
        [rowIconProviderFn]="directoryListItemIconProviderFn"
        (browseToPath)="fbContext.browseToPath($event)"
        (loadMore)="fbContext.browseToPath(fbContext.currentPath, true)">
      </coh-directory-list>

      <div *ngIf="fbContext.hasNoSupportedVolumes" class="message-box">
        {{ 'cVmBrowser.noSupportedVolumes' | translate }}
      </div>
      <cog-banner class="banner" *ngIf="unbrowsable" status="info">
        {{ isO365Browse? 'unableToBrowseSnapshotO365' : 'unableToBrowseSnapshot' | translate }}
      </cog-banner>
    </div>
    <!-- Label to show total returned records message. -->
    <div class="total-number-of-records" *ngIf="totalReturnedRecords > 0"><span>{{totalReturnedRecords}} {{ 'recordsReturned' | translate }}</span></div>
  </div>
  <div class="selection-pane" *ngIf="selection.hasValue()">
    <coh-volume-selection-detail [selection]="selection"></coh-volume-selection-detail>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="mat-actions-div">
    <div class="mat-actions-div-page-count">
      <mat-icon class="mat-tooltip-icon" matTooltip="{{ 'recoverPaginationMessage' | translate }}">{{ 'infoIcon' | translate }}</mat-icon>
      <!-- Dropdown used to fetch number of records from directory list (/irisservices/api/v1/vm/directoryList) api endpoint. -->
      <mat-form-field class="mat-dropdown-page-count">
        <select (change)="loadRecordsOnPageChange($event)" matNativeControl panelClass="select-count" placeholder="{{ 'matPaginator.itemsPerPageLabel' | translate }}">
          <option *ngFor="let pageCount of paginationArr" [value]="pageCount">
            {{pageCount}}
          </option>
        </select>
      </mat-form-field>
      <button mat-button cohCancelButton cogDataId="next-browser-selection" *ngIf="!!fbContext.directoryCookie" (click)="loadNextPageRecords()">
        {{ 'nextPage' | translate }}
      </button>
    </div>
    <div>
      <button mat-button cohCancelButton cogDataId="cancel-browser-selection" mat-dialog-close>
        {{ 'cancel' | translate }}
      </button>
      <span *ngIf="data.allowDownload"
          [matTooltip]="getDownloadFilesError(selection)">
        <button
          type="button"
          mat-stroked-button
          color="primary"
          [disabled]="selection.isEmpty() || getDownloadFilesError(selection)"
          (click)="submit('download')"
          ngClass="margin-left margin-right"
          cogDataId="download-files">
          {{'downloadFiles' | translate}}
        </button>
      </span>
      <button mat-button
        cogSubmitButton
        cogDataId="submit-browser-selection"
        [disabled]="selection.isEmpty()"
        (click)="submit('select')"
        cdkFocusInitial>
        {{ 'next' | translate }}
      </button>
    </div>
  </div>
</mat-dialog-actions>
