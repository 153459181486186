<h2 mat-dialog-title *ngIf="title">{{ title | translate }}</h2>

<mat-dialog-content>
  <p *ngIf="!isHtml">{{ message | translate: messageData }}</p>
  <div class="confirmation-dialog-content" *ngIf="isHtml" [innerHtml]="message | translate: messageData"></div>
  <form *ngIf="confirmationInput" id="confirmation-dialog-form" cohValidateOnSubmit
    [formGroup]="formGroup"
    (validatedSubmit)="dialogRef.close(true)">
    <mat-form-field class="full-width">
      <input
        cogDataId="confirm-input"
        matInput
        placeholder="{{ 'typeYesToConfirm' | translate }}"
        formControlName="confirm"/>
      <mat-error *ngIf="formGroup?.controls?.confirm?.errors?.required">
        {{ 'errors.required' | translate }}
      </mat-error>
      <mat-error *ngIf="formGroup?.controls?.confirm?.errors?.pattern">
        {{ 'errors.pattern.mustInputYes' | translate }}
      </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-button
    [mat-dialog-close]="false"
    color="primary"
    *ngIf="!hideCancel"
    [cogDataId]="cancelLabel + '-button'"
    [inheritDataId]="true"
    cogCancelButton>
    {{ cancelLabel | translate }}
  </button>
  <button
    mat-flat-button
    [mat-dialog-close]="true"
    [color]="confirmButtonType"
    cdkFocusInitial
    [cogDataId]="confirmLabel + '-button'"
    [inheritDataId]="true"
    [disabled]="confirmationInput ? !formGroup?.valid : false"
    cogSubmitButton>
    {{ confirmLabel | translate }}
  </button>
</mat-dialog-actions>
