import { Injectable } from '@angular/core';
import { Notifications } from '@cohesity/api/v2';
import { BANNER_PRIORITY, BannerConfig, AppBannerService } from '@cohesity/helix';
import { BaseBannerConfig, flagEnabled, IrisContext, IrisContextService, isMcm, isMcmOnPrem } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { HeliosNotificationService } from 'src/app/core/services';


@Injectable({
  providedIn: 'root',
})
export class ClusterUpgradesBannerConfig extends BaseBannerConfig {
  /**
   * Unique banner representation
   */
  id = 'ng-helios-cluster-upgrade-banner';

  /**
   * provides banner config as observable
   */
  bannerInfo$ = this.irisCtx.irisContext$.pipe(
    switchMap(() => combineLatest([
      this.irisCtx.irisContext$,
      this.notificationService.heliosNotifications$,
    ])),
    map(([ctx, notification]) => ([{
      id: this.id,
      priority: BANNER_PRIORITY.HIGH,
      isVisible: this.allowBanner(ctx, notification),
      status: 'info',
      allowClose: true,
      text: this.translateService.instant(
        'softwareUpgrade.banner.text',
        { num: notification?.upgradeNotification?.numClustersUpgradeAvailable }
      ),
      closeCallback: this.closeCallback,
      actionText: this.translateService.instant('softwareUpgrade.banner.goToUpgrades'),
      actionCallback: () => {
        this.stateService.go('software-upgrade.cluster-list',
        {
          upgradeStatus: 'UpgradeAvailable',
          serviceType: 'clusterManager',
        },
        { reload: true });
        this.closeCallback(this.id);
        this.appBannerService.remove(this.id);
      },
    } as BannerConfig]))
  );

  constructor(
    private irisCtx: IrisContextService,
    private notificationService: HeliosNotificationService,
    private stateService: StateService,
    private translateService: TranslateService,
    private appBannerService: AppBannerService
  ) {
    super();
  }

  /**
   * Allow banner based on context
   *
   * @param ctx iris context
   * @param notification upgrade notification
   * @returns true/false based on context
   */
  allowBanner(ctx: IrisContext, notification: Notifications) {
    return (
      this.isLoggedIn(ctx) &&
      !this.isBannerAcknowledged(this.id) &&
      flagEnabled(ctx, 'ngHeliosClusterUpgradeBanner') &&
      !isMcmOnPrem(ctx) &&
      isMcm(ctx) &&
      notification?.upgradeNotification?.isUpgradeAvailable &&
      ctx?.user?.clusterIdentifiers.length > 0
    );
  }
}
