import { Injectable } from '@angular/core';
import { IrisContextService } from '@cohesity/iris-core';
import { RawParams } from '@uirouter/core';
import { NavService } from 'src/app/core/app-layout/nav.service';
import { AjsStateManagementService } from 'src/app/shared/ng-upgrade/services';

import { AjsUpgradeService } from './ajs-upgrade.service';
import { TenantService } from './tenant.service';
import { UserScopeService } from './user-scope.service';

/**
 * Tenant Scope Service is used to switch user context when impersonating
 */
@Injectable({
  providedIn: 'root'
})
export class TenantScopeService {
  /**
   * Legacy TenantService AngularJS service.
   */
  private ajsTenantService: any;

  constructor(
    ajsUpgrade: AjsUpgradeService,
    private ajsStateManagementService: AjsStateManagementService,
    private navService: NavService,
    private tenantService: TenantService,
    private userScopeService: UserScopeService,
    private irisContextService: IrisContextService,
  ) {
    this.ajsTenantService = ajsUpgrade.get('TenantService');
  }

  /**
   * Updates the UI states and the navbar after impersonation.
   *
   * @return Promise for resolving user context change.
   */
  updateUserContext(): Promise<any> {
    return this.userScopeService.changeUserContext().toPromise().then(
      () => this.navService.updateNavList(this.irisContextService.irisContext)
    );
  }

  /**
   * Update user context and go to effective fallback state
   *
   * @param  state   The state to check for.
   * @param  params   Params to be passed to transition
   * @return Promise for resolving user context change.
   */
  updateUserContextAndValidateState(
    state?: { name: string },
    params?: RawParams
  ): Promise<any> {
    return this.updateUserContext().then(() => {
      this.ajsStateManagementService.goToEffectiveFallbackState(state, params);
    });
  }

  /**
   * Opens the impersonation modal.
   *
   * @return Promise for opening impersonation modal.
   */
  openSwitchAccountModal(): Promise<any> {
    return this.ajsTenantService.openSwitchAccountModal()
      .then(() => this.updateUserContextAndValidateState());
  }

  /**
   * Switches back to the original user after impersonation.
   *
   * @return Promise for updating after impersonation is done.
   */
  quitImpersonation(): Promise<any> {
    this.tenantService.clearImpersonatedTenant();
    this.ajsTenantService.setSwitchedAccount();
    return this.updateUserContextAndValidateState();
  }
}
