import { Injectable } from '@angular/core';
import { flagEnabled, IrisContextService, mapNavProvider } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { AppServiceManagerService } from 'src/app/app-services';

/**
 * App Layout service is used to provide the current status of layout.
 */
@Injectable({
  providedIn: 'root'
})
export class AppLayoutService {
  /** Async flag indicates if sidebar nav is displayed. */
  private navDisplayedSubject = new BehaviorSubject<boolean>(true);

  /** Observable of flag indicates if sidebar nav is displayed. */
  navDisplayed$ = this.navDisplayedSubject.asObservable();

  /** Observable of navBanner data. */
  navBanner$ = combineLatest([
    this.navDisplayed$,
    this.irisContext.irisContext$,
  ]).pipe(
    map(([navDisplayed]) =>
      (navDisplayed || !flagEnabled(this.irisContext.irisContext, 'appPillarsEnabled')) ?
        null :
        mapNavProvider(this.appServiceManager.getActiveService()?.navProvider, this.translate)
    ),
    distinctUntilChanged(),
  );

  constructor(
    private appServiceManager: AppServiceManagerService,
    private irisContext: IrisContextService,
    private translate: TranslateService,
  ) {
  }

  /**
   * Set navDisplayed.
   *
   * @param navDisplayed  True if nav is displayed.
   */
  setNavDisplayed(navDisplayed: boolean) {
    this.navDisplayedSubject.next(navDisplayed);
  }
}
