/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AuthCheckRequest } from '../models/auth-check-request';
import { AuthCheckResult } from '../models/auth-check-result';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation checkAuth
   */
  static readonly CheckAuthPath = '/private/auth-check';

  /**
   * Performs an autorization check.
   *
   * This API is used for performing an authorization check for given member ID, resource ID and permission string.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkAuth()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkAuth$Response(params: {

    /**
     * Specifies the unique account identifier of the user&#x27;s Salesforce account.
     */
    account_id: string;

    /**
     * Specifies the unique identifier for the user&#x27;s tenant ID. This field is mandatory if permission check involves BaaS entities.
     */
    tenant_id?: string;
      body: AuthCheckRequest
  }): Observable<StrictHttpResponse<AuthCheckResult>> {

    const rb = new RequestBuilder(this.rootUrl, AuthorizationApiService.CheckAuthPath, 'post');
    if (params) {

      rb.header('account_id', params.account_id, {});
      rb.header('tenant_id', params.tenant_id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthCheckResult>;
      })
    );
  }

  /**
   * Performs an autorization check.
   *
   * This API is used for performing an authorization check for given member ID, resource ID and permission string.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkAuth$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkAuth(params: {

    /**
     * Specifies the unique account identifier of the user&#x27;s Salesforce account.
     */
    account_id: string;

    /**
     * Specifies the unique identifier for the user&#x27;s tenant ID. This field is mandatory if permission check involves BaaS entities.
     */
    tenant_id?: string;
      body: AuthCheckRequest
  }): Observable<AuthCheckResult> {

    return this.checkAuth$Response(params).pipe(
      map((r: StrictHttpResponse<AuthCheckResult>) => r.body as AuthCheckResult)
    );
  }

}
