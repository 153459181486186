<div class="form-wrapper" *ngIf="form" [formGroup]="form" [matTooltip]="currentValueLimit.readonlyMessage">
  <mat-form-field
    class="time-period-selector-input"
    [class.readonly]="internalReadonly"
    floatLabel="always"
    *ngIf="form.valueControl?.enabled && form.typeControl?.value !== 'Daily'">
    <mat-label id="{{id}}-label">
      {{label}}
      <span *ngIf="auxLabel">{{auxLabel}}</span>
    </mat-label>
    <input matInput
      type="number"
      id="{{id}}-value-input"
      cogDataId="value-input"
      [inheritDataId]="true"
      [attr.aria-label]="ariaLabelPrefix ? (ariaLabelPrefix + 'Frequency' | translate) : ('Frequency' | translate)"
      formControlName="value"
      (change)="updateValue()"
      [min]="form.valueLimit?.min"
      [max]="form.valueLimit?.max"
      [readonly]="internalReadonly">
    <mat-error *ngIf="form.valueControl?.invalid">
      <span [translate]="form.getErrorMessage(form.valueControl)"
        [translateParams]="{
          minValue: form.valueControl.errors.min?.min,
          maxValue: form.valueControl.errors.max?.max
        }"></span>
    </mat-error>
  </mat-form-field>

  <mat-form-field
    class="time-period-selector-select"
    [class.readonly]="internalReadonly"
    floatLabel="always"
    *ngIf="form.typeControl.enabled">
    <mat-label id="{{id}}-time-period-selector-label" *ngIf="!form.valueControl.enabled">
      {{label}}
      <span *ngIf="auxLabel">{{auxLabel}}</span>
    </mat-label>
    <mat-select id="{{id}}-select"
      cogDataId="select"
      [inheritDataId]="true"
      [aria-label]="ariaLabelPrefix ? (ariaLabelPrefix + 'Interval' | translate) : 'Interval' | translate"
      formControlName="type"
      (selectionChange)="updateValue()">
      <mat-option *ngFor="let type of typeOptions"
        id="{{id}}-option-{{type.value}}"
        cogDataId="option-{{type.value}}"
        [inheritDataId]="true"
        [value]="type.value">
        {{((form.valueControl?.value > 1 && type.labelPlural) ? type.labelPlural : type.label) | translate}}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="form.typeControl?.invalid">{{'errors.required' | translate}}</mat-error>
  </mat-form-field>
</div>
