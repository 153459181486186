import { Injectable } from '@angular/core';
import { BroadcastChannel } from 'broadcast-channel';

/**
 * This service provides a thin wrapper on top of BroadcastChannel.
 * BroadcastChannel is used for communication across NG and AJS and between
 * different instances of the app (different windows, tabs, etc.).
 */
@Injectable({
  providedIn: 'root'
})
export class BroadcastChannelService {
  /**
   * The broadcaster broadcast channel.
   */
  private broadcaster = new BroadcastChannel(window.location.hostname);

  /**
   * The receiver broadcast channel.
   */
  private receiver = new BroadcastChannel(window.location.hostname);

  /**
   * Map of all subscriptions currently being listened to.
   */
  private subscriptionsMap: Map<any, Set<Function>> = new Map;

  constructor() {
    this.receiver.onmessage = event => {
      // Whenever there's a new a message, fire the necessary subscriptions.
      if (this.subscriptionsMap.has(event)) {
        for (const fn of this.subscriptionsMap.get(event)) {
          fn();
        }
      }
    };
  }

  /**
   * Function to call to subscribe to a data variable.
   *
   * @param data The data variable to be subscribed to. This can be of any type
   *             and is serialized by the browser.
   * @param fn The function to fire when message the data variable is fired.
   * @return Reference to the function to unsubscribe which returns a boolean
   *         for unsubscription success.
   */
  subscribe(data: any, fn: Function): () => boolean {
    if (!this.subscriptionsMap.has(data)) {
      this.subscriptionsMap.set(data, new Set<Function>());
    }

    this.subscriptionsMap.get(data).add(fn);

    return this.unsubscribe(data, fn);
  }

  /**
   * Function to post a data variable.
   *
   * @param data The data variable to post. This can be of any type and is
   *             serialized by the browser.
   */
  postMessage(data: any) {
    this.broadcaster.postMessage(data);
  }

  /**
   * Private function to call to unsubscribe to a data variable.
   *
   * @param data The data variable to unsubscribe to.
   * @param fn The function reference to unsubscribe.
   * @return Function to call to do the unsubscription which returns a boolean
   *         for unsubscription success.
   */
  private unsubscribe(data: any, fn: Function): () => boolean {
    return () => {
      if (!this.subscriptionsMap.has(data)) {
        return false;
      }

      return this.subscriptionsMap.get(data).delete(fn);
    };
  }
}
