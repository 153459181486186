import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ObservableInput } from 'ngx-observable-input';
import { combineLatest, Observable, Subscription } from 'rxjs';

import { IrisContextAccessFn } from './iris-context-util';
import { IrisContextService } from './iris-context.service';

/**
 * This is a structural directive that evaluates a IrisContextAccessFn and hides
 * or shows a template, similar to using *ngIf
 *
 * @example
 * <div *cohIrisAccess="someProp.canAccess"></div>
 */
@Directive({
  selector: '[cohIrisAccess]',
})
export class IrisAccessDirective implements OnInit, OnDestroy {
  /**
   * The main input for the method is a iris context access function that will
   * be evaluated against the current iris context.
   */
  @ObservableInput(null) @Input('cohIrisAccess') accessFn$: Observable<IrisContextAccessFn>;

  /**
   * The context susbscription
   */
  private contextSub: Subscription;

  /**
   * Whether the view is currently being shown or not.
   */
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private vcr: ViewContainerRef,
    private contextService: IrisContextService
  ) {}

  ngOnInit() {
    this.contextSub = combineLatest([this.accessFn$, this.contextService.irisContext$]).subscribe(
      ([accessFn, context]) => {
        const allowed = !context || !accessFn || accessFn(context);
        if (allowed && !this.hasView) {
          this.vcr.createEmbeddedView(this.templateRef);
          this.hasView = true;
        } else if (!allowed && this.hasView) {
          this.vcr.clear();
          this.hasView = false;
        }
      }
    );
  }

  ngOnDestroy() {
    this.contextSub?.unsubscribe();
  }
}
