export enum ProtectionItemName {
  AwsPostgresProtectionType = 'awsPostgresProtectionType',
  AcropolisDiskFilter = 'acropolisDiskFilter',
  Actions = 'actions',
  AdditionalSettings = 'additionalSettings',
  CloudJobType = 'cloudJobType',
  DataCenters = 'dataCenters',
  DataMigrationSettings = 'dataMigrationSettings',
  DirectCloudArchive = 'directCloudArchive',
  Hosts = 'hosts',
  HypervDiskFilter = 'hypervDiskFilter',
  LogBackupSelection = 'LogBackupSelection',
  LogBackupSettings = 'logBackupSettings',
  MsSqlSettings = 'msSqlSettings',
  NasProtocol = 'nasProtocol',
  NfsPreference = 'nfsPreference',
  Objects = 'objects',
  Office365WorkloadType = 'workloadType',
  Policy = 'policy',
  CsmPolicy = 'csmPolicy',
  IngestPolicy = 'ingestPolicy',
  InventoryBucketLocation = 'inventoryBucketLocation',
  ProtectionGroup = 'protectionGroup',
  QuickProtectObjects = 'quickProtectObjects',
  Settings = 'settings',
  SnapMirrorBackupComponent = 'snapMirrorConfig',
  Source = 'source',
  CassandraSystemKeyspaceForm = 'cassandraSystemKeyspaceForm',
  UdaManagedForm = 'udaManagedForm',
  UdaManagedFormOptionsReadonly = 'udaManagedFormOptionsReadonly',
  UdaObjectNames = 'udaObjectNames',
  View = 'view',
}
