<cog-page>
  <cog-page-header [noMargin]="true">
    <cog-page-toolbar
      [backText]="'heliosSecurityAdvisor.backToScoresForAllCountries' | translate"
      [backTooltip]="'goBack' | translate"
      (back)="backClick.emit('')">
    </cog-page-toolbar>
  </cog-page-header>
  <dg-pa-scores-page
    (scoreDrilldownClick)="scoreDrilldownClick.emit($event)"
    [country]="country"
    [state]="state">
  </dg-pa-scores-page>
</cog-page>
