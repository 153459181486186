import { AppConfig, PluginName } from './models';

/**
 * The argus recovery plugin.
 */
const argusRecoveryPluginConfig: AppConfig = {
  name: 'argusRecoveryPluginConfig',
  libName: '@cohesity/argus-recovery-plugin',
  publicPath: '/datahawk/recovery-plugin',
  rootContainerClassName: 'argus-recovery-plugin-root',
};

/**
 * The ai-bot plugin.
 */
const aiBotPluginConfig: AppConfig = {
  name: 'aiBotPluginConfig',
  libName: '@cohesity/ai-bot-plugin',
  publicPath: '/datahawk/ai-bot-plugin',
  rootContainerClassName: 'ai-bot-plugin-root',
};

/**
 * The plugin config by plugin name.
 */
export const pluginConfigMap: Record<PluginName, AppConfig> = {
  aiBotPluginConfig: aiBotPluginConfig,
  argusRecoveryPluginConfig: argusRecoveryPluginConfig,
};
