import { Injectable } from '@angular/core';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { HookMatchCriteria, StateDeclaration, StateObject, Transition } from '@uirouter/core';
import { McmViewService } from 'src/app/core/services';
import { GuardPriority, GuardResult, StateGuard } from 'src/app/core/state/state-guard';

/**
 * View Guard redirects all state traffic to the new view page based on the
 * Feature flag ngView.
 */
@Injectable({
  providedIn: 'root'
})
export class ViewsGuard implements StateGuard {
  /**
   * This guard priority is set to App, which indicates it should run as part of the main application
   * after all bootstrapping and initializing is completed.
   */
  guardPriority = GuardPriority.App;

  /**
   * Specifies the old to new state mapping for views pages.
   */
  private readonly stateMapping = new Map<string, string>([
    ['view-new', 'ng-views.views'],
    ['view-edit', 'ng-views.views'],
    ['view', 'ng-views.view-detail'],
    ['share-details', 'ng-views.share-detail'],
    ['ng-views.modify', 'ng-views.modify-new'],
    ['ng-templates.modify', 'ng-templates.modify-new'],
  ]);

  private readonly stateMappingInverse = new Map<string, string>();

  /**
   * The ng views guard matching criteria.
   */
  matchCriteria: HookMatchCriteria = {
    to: (() => {
      // IIFE is used to create the closure of all states here used inside HookMatchCriterion.
      const allStates = new Set<string>();

      this.stateMapping.forEach((newState, oldState) => {
        this.stateMappingInverse.set(newState, oldState);

        // run this views guard for both old and new states.
        [oldState, newState].forEach(state => {
          allStates.add(state);

          // run this guard for future and modify pages for both old and new states.
          allStates.add(`${state}.**`);
          allStates.add(`${state}.create`);
          allStates.add(`${state}.modify`);
        });
      });

      // the hook matching criterion method for views.
      return (state: StateObject) => allStates.has(state.name);
    })(),
  };

  constructor(
    private irisCtx: IrisContextService,
    private mcmViewService: McmViewService,
  ) {}

  /**
   * Ensures the transition going new views page if flag is enabled else allow transition to old states.
   *
   * @param   transition  The ui routglober transition.
   * @returns Redirect to valid target state.
   */
  onStart(transition: Transition): GuardResult {
    const toState = transition.to();
    const params = transition.params();

    // do nothing if ng view is disabled.
    if (!flagEnabled(this.irisCtx.irisContext, 'ngView')) {
      return;
    }

    // compute the valid target state.
    const validStateName = this.getValidState(toState);


    // got to valid target state of current transition is going to invalid state.
    if (validStateName !== toState.name && this.mcmViewService.canAccessState(validStateName)) {
      if (this.mcmViewService.showFrame$.value && validStateName === 'ng-views.views.all-shares') {
        // In case of iframe, maintain old state.
        return true;
      }
      return transition.router.stateService.target(validStateName, params, transition.options());
    }
  }

  /**
   * Return the valid target state name for the provided state.
   *
   * @param toState The target state.
   * @returns  The valid target state name.
   */
  private getValidState(toState: StateDeclaration): string {
    const stateName = toState.name;
    let validState = '';

    // The state corresponding to the feature flag, if disabled. Then return
    // the default state and not the new mapped state.
    if (['view', 'share-details'].includes(stateName) &&
      !flagEnabled(this.irisCtx.irisContext, 'ngViewDetails')) {
      return stateName;
    }

    if (['ng-views.modify', 'ng-templates.modify'].includes(stateName) &&
      !flagEnabled(this.irisCtx.irisContext, 'createViewRefactor')) {
      return stateName;
    }

    if (this.stateMapping.has(stateName)) {
      validState = `${this.stateMapping.get(stateName)}`;
    } else if (this.stateMappingInverse.has(stateName)) {
      validState = toState.name;
    }

    return validState;
  }
}
