<cog-settings-list-item [formGroup]="formGroup"
  [invalid]="formControl.invalid"
  [label]="'priority' | translate">
  <cog-settings-list-item-value-display>
    {{formControl.value | translate}}
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor [formGroup]="formGroup"
    cogDataId="settings-priority">
    <mat-form-field>
      <mat-label translate>priority</mat-label>
      <mat-select [formControlName]="name"
        [inheritDataId]="true"
        cogDataId="select">
        <mat-option *ngFor="let option of priorities"
          [cogDataId]="'option-' + (option | translate)"
          [inheritDataId]="true"
          [value]="option">
          {{option | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
