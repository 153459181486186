import { getConfigByKey } from '@cohesity/iris-core';
import { environment } from 'src/environments/environment';
import { heliosMfaGracePeriodExpired } from 'src/app/util/iris-context-mfa-utils';
import { GuardPriority } from 'src/app/core/state/state-guard';

appTransitionHook.$inject = [
  '$rootScope', '$transitions', '$log', '$state', 'ClusterService',
  'UserService', 'RemoteClusterService', 'FEATURE_FLAGS', 'evalAJAX',
  'clusterState', 'localStorageService', 'HOME_STATE', 'EulaService',
  'HeliosService', 'StateManagementService', 'NgClusterService',
  'featureFlagsService', 'StaticRoutes', 'NG_IS_IBM_AQUA_ENV', 'NgIrisContextService',
  'NgFeatureFlagsService', 'NgMcmViewService', 'NgUserStoreService'
];
export function appTransitionHook($rootScope, $transitions, $log, $state, ClusterService,
  UserService, RemoteClusterService, FEATURE_FLAGS, evalAJAX,
  clusterState, localStorageService, HOME_STATE, EulaService,
  HeliosService, StateManagementService, NgClusterService,
  featureFlagsService, StaticRoutes, NG_IS_IBM_AQUA_ENV, NgIrisContextService,
  NgFeatureFlagsService, NgMcmViewService, NgUserStoreService) {

  let handleTransitionStart = (trans) => {
    var to = trans.$to();
    var toState = to.self;

    // Defaults to dashboard
    var stateName = toState.name || HOME_STATE.name;
    var stateParams = trans.params() || HOME_STATE.params;

    var loggedIn;

    // If a lockClusterId param is passed, the intention is to lock the app
    // API calls into that Cluster. Pass the param value on to the service
    // that manages it.
    if (stateParams.lockClusterId) {
      RemoteClusterService.lockClusterId(stateParams.lockClusterId);
    }

    // before allowing access to a requested state, check if a user is
    // logged in.
    loggedIn = UserService.isLoggedIn();

    /**
     * Going to the logout state should actually log you out.
     * This is necessary to support IBM Defender to logout
     * user from browser with logout link.
     */
    if (NG_IS_IBM_AQUA_ENV && stateName === 'logout') {
      if (loggedIn) {
        UserService.logout(stateParams);
        // return true here allows logout state to persist and render the logout page.
        return true;
      }
    }

    if (NgClusterService.isMcm) {
      // Workflow: User is redirected to helios on successful salesforce authentication
      if (stateParams.heliosAuthenticated) {
        if (loggedIn) {
          UserService.isSameAsRedirectUser().then(isSameUser => {
            // if RedirectUser (from session API) is different from user in local
            // storage, create session.
            if (!isSameUser) {
              onPostSalesforceAuthenticationRedirect(trans);
            }
          });
        } else {
          onPostSalesforceAuthenticationRedirect(trans);
          // early exit to prevent further processing.
          return false;
        }
      }

      if (loggedIn && isEligibleForHeliosEulaWorkflow()) {
        // Workflow: Get the EULA status if the user is logged in.
        if (!$rootScope.user.mcmEula) {
          fetchEulaForAccount(trans, stateName, stateParams);

          // early exit to prevent further processing.
          return false;
        }

        // Workflow: Trigger helios Eula acceptance workflow
        if (showHeliosEula()) {
          triggerHeliosEulaWorkflow(trans, stateName, stateParams)
          // early exit to prevent further processing.
          return false;
        }
      } else if (!environment.heliosInFrame) {
        // Do not move into this block for iframe as some states like MT
        // require prequisite data like clusterInfo before evaluating
        // `canAccess` checks
        gotoState(trans, true);
      }
    }

    // Workflow: Force validating the user session because backend had already authenticated the user
    // trying to login using SSO/IDP or remoteLogin APIs(currently used cohesity vmware VRA tool).
    if (stateParams.isAuthenticated) {
      return onPostSsoAuthenticationRedirect(trans)
    }

    if (!loggedIn && !StaticRoutes.includes(toState.name)) {
      // prevent the requested state change.
      // TODO: investigate $state.target('login') redirect instead.
      trans.abort();

      // capture the requested state and params in the UserService for
      // redirect after successful login
      UserService.capturePreLoginRequest(toState, trans.params());
      $state.go('login');

    } else if (loggedIn && !ClusterService.clusterInfo &&
      clusterState.found &&
      // Fetching cluster info is not needed for Self-Service user.
      !NgUserStoreService.isSelfServiceUser()) {
      return fetchClusterInfo(trans, toState);

    } else if (loggedIn && toState.name === 'login') {
      // user is logged in and trying to access login page. Prevent
      // requested state transition and send them to the dashboard.
      trans.abort();

      $state.goHome();
    } else {
      // else, if no trans.abort()'s were reached, the user's request
      // will be processed as normal.
      //
      // Block default route if the user is a Self Service user.
      if (NgUserStoreService.isSelfServiceUser() && toState.name === "dashboards.summary") {
        return UserService.validateSessionUser().then(user => {
          UserService.createSession(user);
          ClusterService.updateRootWithLicenseSuccess();
          trans.abort();
          $state.go('self-service-portal.m365');
        });
      }
    }
  };


  /**
   * Create user session after sucessful salesforce authentication.
   *
   * @method onPostSalesforceAuthenticationRedirect
   * @param    {Object}    trans                  The transition object
   */
  function onPostSalesforceAuthenticationRedirect(trans) {
    // prevent the requested state change.
    trans.abort();

    // Fetch the user information, this is an authorized URL which will
    // fail if the session context is not set by the backend.
    // Do not create session for BaaS Provision page. It is a
    // semi-authenticated page.
    UserService.createHeliosSession().then(
      function mcmUser() {
        if (NG_IS_IBM_AQUA_ENV) {
          // Don't show app pillars in IBM aqua.
          gotoState(trans);
          return;
        }

        // Disable appPillars for tenant user.
        if (!UserService.isHeliosTenantUser()) {
          // If a Helios account feature flag is set for appPillarsEnabled,
          // FEATURE_FLAGS.appPillarsEnabled still returns as false first,
          // and true later. This results in the user landing on the
          // wrong landing page. To fix that, after the user logs in,
          // listen to account feature flags value, and if appPillarsEnabled
          // is enabled, redirect to the correct state. This is a temporary
          // fix, and once appPillarsEnabled will be enabled in the
          // feature-flags.json file, this will be unnecessary.
          const subscription = NgFeatureFlagsService.accountFeature$.subscribe((value) => {
            if (value) {
              if (value.appPillarsEnabled && $state.current?.name !== 'app-pillars') {
                $state.go('app-pillars', {loginRedirect: true});
              }

              subscription.unsubscribe();
            }
          });
        }

        if (FEATURE_FLAGS.appPillarsEnabled && !UserService.isHeliosTenantUser()) {
          $state.go('app-pillars', { loginRedirect: true });
        } else {
          gotoState(trans);
        }
      }, evalAJAX.errorMessage
    );
  }

  /**
   * Fetch Eula for user account and update on rootscope.
   *
   * @method fetchEulaForAccount
   * @param    {Object}    trans                  The transition object
   * @param    {string}    stateName              Name of the traget state
   * @param    {Object}    stateParams            Params of target state
   */
  function fetchEulaForAccount(trans, stateName, stateParams) {
    // prevent the requested state change.
    trans.abort();

    // Get account ID from the User Details
    const accountId = HeliosService.getUserAccountId();

    // Construct helios EULA get params
    var getHeliosParam = {
      accountIds: [accountId]
    };

    // Get Helios EULA Status
    HeliosService.getHeliosEulaStatus(getHeliosParam).then(
      function getStatus(eulaStatus) {
        $rootScope.user.mcmEula = !_.isEmpty(eulaStatus) ?
          eulaStatus.eulaVec[0] : {};
        // Replacing this with state.go as gotoState does some priv
        // checks based on clusterInfo, which is not loaded on this
        // moment.
        $state.go(stateName, stateParams);
      },
      () => UserService.logout()
    );
  }

  /**
   * Display helios Eula dialog.
   * On accept, update eula status and navigate to target state.
   * On decline, logout the user
   *
   * @method triggerHeliosEulaWorkflow
   * @param    {Object}    trans                  The transition object
   * @param    {string}    stateName              Name of the traget state
   * @param    {Object}    stateParams            Params of target state
   */
  function triggerHeliosEulaWorkflow(trans, stateName, stateParams) {
    // prevent the requested state change.
    trans.abort();

    // Show the EULA modal, if the user agrees save the information
    // and continue processing or logout the user.
    EulaService.showModal('heliosEula').then(
      function getEulaStatus(signedEula) {
        const accountId = HeliosService.getUserAccountId();

        var eulaObj = _.assign({
          accountId: accountId,
        }, signedEula);

        HeliosService.updateHeliosEulaStatus(eulaObj).then(
          function updateSuccessful(eulaStatus) {
            // TODO(Sam): To be replaced with res.data.eulaVec[0],
            // currently backend is not returning this for update,
            // story is inprogress.
            $rootScope.user.mcmEula = eulaObj;
            // Replacing this with state.go as gotoState does some priv
            // checks based on clusterInfo, which is not loaded on this
            // moment.
            $state.go(stateName, stateParams);
          },
          () => UserService.logout()
        );
      },
      () => UserService.logout()
    );
  }

  /**
   * Returns whether Helios eula workflow needs to be triggered
   *
   * @method showHeliosEula
   */
  function showHeliosEula() {
    // Don't show Helios EULA if MFA grace period is expired.
    // This is because users will not be able to accept the EULA
    // since that API will be blocked.
    if (heliosMfaGracePeriodExpired(NgIrisContextService.irisContext) || NG_IS_IBM_AQUA_ENV) {
      return false;
    }

    return HeliosService.isHeliosEulaNeeded($rootScope.user?.mcmEula);
  }

  /**
   * Returns whether conditions are met for Helios eula workflow
   *
   * @method isEligibleForHeliosEulaWorkflow
   */
  function isEligibleForHeliosEulaWorkflow() {
    return (!NgUserStoreService.isSelfServiceUser() &&
      !(UserService.isHeliosTenantUser() ||
        window.location.href.includes('impersonatedOrgId') ||
        environment.heliosInFrame) ||
      // wait for account feature flags also to load
      (featureFlagsService.accountFeature$.value && !FEATURE_FLAGS.heliosMTEnabled))
  }

  /**
   * Validate user session after sucessful authentication via SSO,
   * IDP or remoteLogin APIs.
   *
   * @method onPostSsoAuthenticationRedirect
   * @param    {Object}    trans                  The transition object
   */
  function onPostSsoAuthenticationRedirect(trans) {
    // Since this is an authenticated call, if the user is not logged in
    // it will result in a error, but for SSO redirection, this will result
    // in a success call and will create a session for the user.
    return UserService.validateSessionUser().then(
      function successUser(user) {
        // Create the user session and direct him to the requested page.
        UserService.createSession(user);

        // Since the Self service user is already authenticated by iris
        // server through the OIDC workflow, proceed to the desired state.
        if (NgUserStoreService.isSelfServiceUser()) {
          // Accept License, since iris has already handled authorization
          // for the user against their Azure UUID and all backup flows
          // are anyway not accessible by the SelfServe user.
          ClusterService.updateRootWithLicenseSuccess();
          return gotoState(trans);
        }

        const postLoginRedirect = {
          ...(localStorageService.get('postSsoLoginRedirect') || HOME_STATE),

          // Resetting the value to prevent force re-validation again on successive navigation's.
          isAuthenticated: false,
        };

        return trans.router.stateService.target(
          postLoginRedirect.name, postLoginRedirect.params, trans.options());
      },
      function failedUser() {
        UserService.informAndLogOutUser('userCannotBeValidated');
        return false;
      }
    );
  }

  /**
   * Fetch cluster info and handle eula acceptance workflow for cluster
   *
   * @method fetchClusterInfo
   * @param    {Object}    trans                  The transition object
   * @param    {Object}    toState                The original state object
   * @returns  {Promise<boolean>}                 True, if transition can continue. Otherwise, false.
   */
  function fetchClusterInfo(trans, toState) {
    UserService.capturePreLoginRequest(toState, trans.params());

    const selectedCluster = localStorageService.get('selectedCluster');
    const defaultClusterParams = selectedCluster ? {
      _allClusters: !!selectedCluster._allClusters,
      _cohesityService: !!selectedCluster._cohesityService,
      _globalContext: !!selectedCluster._globalContext,
      _nonCluster: selectedCluster._nonCluster,
      _serviceType: selectedCluster._serviceType,
      id: selectedCluster.id,
    } : undefined;

    // getClusterInfo() sets up ClusterService.clusterInfo
    return ClusterService.getClusterInfoWithLocale(undefined, defaultClusterParams)
      .then(function getClusterInfoSuccess() {

        var shouldLogout = !NgClusterService.basicClusterInfo.mcmMode &&
          (ClusterService.isEulaNeeded() || (FEATURE_FLAGS.licensing ?
            ClusterService.isNewCluster() &&
            ClusterService.isUserAcceptanceNeeded() : false))

        $rootScope.clusterInfo = ClusterService.clusterInfo;

        // to check if licensing has been done or not and set on rootScope
        checkLicensingStatus();

        // clusterInfo loaded, resume the original request unless EULA
        // acceptance is required.
        // EULA acceptance for Helios is performed previously so it's
        // safe to assume and move on.
        if (shouldLogout) {
          // If this happens during the SSO Login flow.
          // Show the modal to accept the new EULA and update the version.
          if (toState.isAuthenticated) {
            // If an SSO user is authenticated, then show acceptance of the
            // EULA dialog.
            EulaService.showModal().then(

              // If the user accepts the EULA update the EULA in cluster
              // config.
              function acceptEula(eulaConfig) {
                ClusterService.updateEula(eulaConfig).then(

                  // If successful resume the transition
                  gotoState.bind(null, trans),

                  // If the update is not successful, destory the user
                  // session.
                  UserService.informAndLogOutUser.bind(null,
                    'userCannotBeValidated')
                );
              },

              // If the user cancel the dialog. Logout the user.
              () => UserService.logout(),
            );
          } else {
            // EULA acceptance is needed. Log the user out so they can go
            // through the appropriate EULA flow.
            UserService.logout();
          }

          // abort the current transition
          return false;
        } else {
          // user returned back with valid session cookie so to update
          // nav list broadcast login success message.
          UserService.loginSuccess();

          if (trans.targetState().name() === 'dashboards.summary') {
            // dashboards.summary is the default state set by HOME_SERVICE,
            // however this state is not accessible in all contexts.
            // HOME_SERVICE.name cannot be used here since as at this point,
            // the value may not have been set correctly.

            const cluster = $rootScope.clusterInfo;

            if (cluster._globalContext) {
              trans.abort();
              $state.go('dashboards.global');
            } else if (cluster._cohesityService && cluster._serviceType !== 'clusterManager') {
              trans.abort();

              const stateName = cluster._serviceType === 'dms'
                ? 'dashboards.dms'
                : 'dashboards.disaster-recovery';

              $state.go(stateName);
            } else {
              // proceed to original state request
              return true;
            }
          }

          // proceed to original state request
          return true;
        }

      },
        evalAJAX.errorMessage
      );
  }

  /**
   * Function to evaluate situation to update license state and licenseingDone
   * on RootScope
   *
   * @method checkLicensingStatus
   */
  function checkLicensingStatus() {
    switch (true) {
      // On helios, we dont need to restrict component rendering on
      // basis of "licenseingDone"
      case NgClusterService.basicClusterInfo.mcmMode:
        ClusterService.updateRootWithLicenseSuccess();
        break;

      // if eula needed, we will not update the nav bar
      case ClusterService.isEulaNeeded():
        break;

      case !FEATURE_FLAGS.licensing:
        ClusterService.updateRootWithLicenseSuccess();
        break;

      // if the user has tried connecting to helios and the connection
      // succeeded but the claim didn't finish,
      // update the license state accordingly
      case ['kInProgressNewCluster', 'kInProgressOldCluster']
        .includes(ClusterService.clusterInfo.licenseState.state):
        HeliosService.getHeliosConfiguration().then(
          function getConfiguration(config) {
            if ('kRegistrationCompleted' === config.registrationStatus) {
              ClusterService.updateLicenseState("claimed")
                .then(ClusterService.updateRootWithLicenseSuccess);
            } else {
              // if cluster claim fails, restore the license state value
              var restorePreviousState =
                ClusterService.clusterInfo.licenseState.state ===
                  'kInProgressNewCluster' ? "started" : undefined;

              ClusterService.updateLicenseState(restorePreviousState);

              // if it was an old cluster,update nav bar
              if (!restorePreviousState) {
                ClusterService.updateRootWithLicenseSuccess();
              }
            }
          }, evalAJAX.errorMessage)
        break;

      // if license state is already 'claimed' or 'skipped'
      case !ClusterService.isLicenseAcceptanceNeeded():
        ClusterService.updateRootWithLicenseSuccess();
        ClusterService.showLicenseExpiryMsg();

        break;

      // for old clusters, proceed as normal
      case !ClusterService.isNewCluster():
        ClusterService.updateRootWithLicenseSuccess();
        // on old cluster,if license not claimed or skipped
        // show user notification to deploy license
        if (ClusterService.isLicenseAcceptanceNeeded()) {
          ClusterService.showLicenseNotDeployedWarning();
        }
        break;
    }
  }

  /**
   * Allow navigation to provided state if logged in user is having the
   * required access else prevent state change and show the error message.
   *
   * define state.canAccess which will be evaluated by
   * StateManagementService.canUserAccessState and by default considered as
   * true.
   *
   * @method   gotoState
   * @param    {Object}    trans                  The transition object
   * @param    {Boolean}   allowOriginalRequest   If true then don't
   * navigate to provided state if user is having the required access used
   * when navigation is already in progress.
   */
  function gotoState(trans, allowOriginalRequest) {
    var toState = trans.$to();
    var toParams = trans.params();
    var fromState = trans.$from();
    var stateConfig = $state.get(toState.name);
    var canAccess = StateManagementService.canUserAccessState(stateConfig, toParams);

    // don't load the state if user is not having access to target state
    if (!canAccess) {
      trans.abort();

      // If this state can't be loaded go to the home state.
      $state.goHome();

      $log.info(
        'Logged-in user does not have required access for the state',
        '`' + toState.name + '`',
        '(Navigated from',
        '`' + fromState.name + '`)'
      );
    } else if (!allowOriginalRequest) {
      $state.go(toState.name, toParams);
    }
  }

  // This is to remove ui-view placeholder from the DOM completely in case
  // cluster iframe is activated. Since when cluster iframe is activated,
  // the same view (or controller) need not be computed in the native application.
  // Everything related to target transition state should happen inside iframe
  // only.
  $transitions.onBefore({}, function beforeTransitionStart() {
    if ($rootScope.showClusterIframe !== NgMcmViewService?.showFrame$?.value) {
      const targetState = NgMcmViewService?.showFrame$?.value;

      // If intent is to show iframe in upcoming transition, do the switch within
      // same digest cycle so that no network calls are made furthere related to that
      // transition. But if the intent is to hide iframe, wait for next digest cycle
      // because enabling it instantly will again cause ui router to compute
      // '$from' state transition which will lead to api calls which are irrelevant
      // to '$to' state.
      if (targetState) {
        $rootScope.showClusterIframe = targetState;
      } else {
        setTimeout(() => $rootScope.showClusterIframe = targetState, 0);
      }
    }
  }, { priority: GuardPriority.AjsApp })

  $transitions.onSuccess({}, function successfulTransitionFn(trans) {
    var from = trans.$from();
    var to = trans.$to();
    var toState = to.self;
    var fromState = from.self;
    const titleSuffix = NG_IS_IBM_AQUA_ENV ? 'Dashboard' : 'Cohesity Dashboard';

    // Set Page Title
    $rootScope.pageTitle = toState.title ? `${toState.title} - ${titleSuffix}` : titleSuffix;

    // Check if the user has accepted EULA before continuing.
    // Unless the target state is the new EULA acceptance page or the app is
    // running in Helios as EULA check happens during login flow. Logout the
    // user and clear the session. ENG-103293
    if (!NgClusterService.basicClusterInfo.mcmMode &&
      $rootScope.clusterInfo &&
      !$rootScope.clusterInfo.eulaConfig &&
      toState.name !== 'eulaNew' &&
      getConfigByKey(NgIrisContextService.irisContext, 'cluster.eulaNeeded', true)) {
      UserService.logout();
    }

    if (from.name !== to.name) {
      StateManagementService.addPreviousState(fromState, trans.params('from'));

      // Save the fromState if being redirected to login so that the user goes
      // back to the state.
      // Do not add the invalid states and redirect after login.
      // Do not add this is MCM, this is causing state transition while
      // navigating between single cluster selection => logout => login.
      if (from.name &&
        !['logout', 'logout-ng', 'forbidden'].includes(from.name) &&
        ( to.name === 'login' || to.name === 'login-ng' ) &&
        !NgClusterService.basicClusterInfo.mcmMode) {
        UserService.capturePreLoginRequest(fromState, trans.params('from'));
      }
    }
  });

  $transitions.onStart({}, handleTransitionStart, { priority: GuardPriority.AjsApp });
}
