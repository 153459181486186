
/**
 * These are the extension points currently defined in the application.
 * These should be named according to convention: applicationCategory.feature.extensionType
 */
export enum AvailableExtensionPoints {
  /**
   * This is used by the recovery flow to show additional information about a selected snapshot
   * or protection group
   */
  restoreSnapshotSelectionDetailComponent = 'restore.snapshotSelectionDetail.component',

  /**
   * This is used by the recovery flow to show a table column with additional information for
   * a protection group or snapshot object.
   */
  restoreSnapshotSelectionDetailColumn = 'restore.snapshotSelectionDetail.column'
}

/**
 * This is the model used for the restore snapshot selection extension points.
 */
export interface SnapshotSelectionDetailModel {
  /**
   * Protection group id or the id of specific object.
   */
  objectId?: string|number;

  /**
   * Snapshot id or protection group run id.
   */
  restorePointId?: string;

  /**
   * The type of selected object, either a group or an object.
   */
  type?: 'protectionGroup' | 'object';

  /**
   * Cluster ID of the current cluster, used for generating download URL.
   */
  clusterId?: number;

  /**
   * local snapshot ID For protected objects.
   */
  snapshotId?: string;

  /**
   * local snapshot For protected objects.
   */
  snapshot?: any;

  /**
   * Protected object.
   */
  object?: any;
}
