/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { IdentityConfigs } from '../models/identity-configs';
import { IdentityConfig } from '../models/identity-config';
import { IdentityAction } from '../models/identity-action';
import { IdentityProviderConfigurations } from '../models/identity-provider-configurations';
import { IdentityProviderConfiguration } from '../models/identity-provider-configuration';
import { CreateIdpRequestParams } from '../models/create-idp-request-params';
import { UpdateIdpRequestParams } from '../models/update-idp-request-params';
import { Idps } from '../models/idps';
import { Idp } from '../models/idp';
import { CreateOrUpdateIdpRequest } from '../models/create-or-update-idp-request';
import { IdpPrincipals } from '../models/idp-principals';
import { IdpPrincipal } from '../models/idp-principal';
@Injectable({
  providedIn: 'root',
})
class IdentityProviderService extends __BaseService {
  static readonly GetIdentitiesPath = '/identity-providers';
  static readonly CreateIdentityPath = '/identity-providers';
  static readonly PerformIdentityActionPath = '/identity-providers/actions';
  static readonly UpdateIdentityPath = '/identity-providers/{id}';
  static readonly DeleteIdentityPath = '/identity-providers/{id}';
  static readonly GetIdentityProvidersPath = '/idps';
  static readonly CreateIdentityProviderPath = '/idps';
  static readonly IdpsLoginPath = '/idps/login';
  static readonly UpdateIdentityProviderPath = '/idps/{id}';
  static readonly DeleteIdentityProviderPath = '/idps/{id}';
  static readonly AuthenticateIdpPath = '/mcm/idp/authenticate';
  static readonly GetIdpsPath = '/mcm/idps';
  static readonly CreateIdpPath = '/mcm/idps';
  static readonly ListIdpPrincipalsPath = '/mcm/idps/principals';
  static readonly CreateIdpPrincipalPath = '/mcm/idps/principals';
  static readonly GetIdpPrincipalBySidPath = '/mcm/idps/principals/{sid}';
  static readonly UpdateIdpPrincipalPath = '/mcm/idps/principals/{sid}';
  static readonly DeleteIdpPrincipalPath = '/mcm/idps/principals/{sid}';
  static readonly GetIdpByIdPath = '/mcm/idps/{id}';
  static readonly UpdateIdpPath = '/mcm/idps/{id}';
  static readonly DeleteIdpPath = '/mcm/idps/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get Identities
   *
   * Get Identity Providers configured on the cluster. Currently this API only supports Open ID based SSO providers, but it will be expanded in the future to support SAML SSO providers.
   * @param params The `IdentityProviderService.GetIdentitiesParams` containing the following parameters:
   *
   * - `tenantIds`: Specifies the tenant id's to get IDPs configured on tenants
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeAllTenants`: Specifies if IDP configurations on all the tenants under the hierarchy of the logged in user should be returned
   *
   * - `ids`: Specifies IDs of configured identity providers
   *
   * - `domains`: Specifies domains of the IDP configurations
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetIdentitiesResponse(params: IdentityProviderService.GetIdentitiesParams): __Observable<__StrictHttpResponse<IdentityConfigs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeAllTenants != null) __params = __params.set('includeAllTenants', params.includeAllTenants.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.domains || []).forEach(val => {if (val != null) __params = __params.append('domains', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/identity-providers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IdentityConfigs>;
      })
    );
  }
  /**
   * Get Identities
   *
   * Get Identity Providers configured on the cluster. Currently this API only supports Open ID based SSO providers, but it will be expanded in the future to support SAML SSO providers.
   * @param params The `IdentityProviderService.GetIdentitiesParams` containing the following parameters:
   *
   * - `tenantIds`: Specifies the tenant id's to get IDPs configured on tenants
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeAllTenants`: Specifies if IDP configurations on all the tenants under the hierarchy of the logged in user should be returned
   *
   * - `ids`: Specifies IDs of configured identity providers
   *
   * - `domains`: Specifies domains of the IDP configurations
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetIdentities(params: IdentityProviderService.GetIdentitiesParams): __Observable<IdentityConfigs> {
    return this.GetIdentitiesResponse(params).pipe(
      __map(_r => _r.body as IdentityConfigs)
    );
  }

  /**
   * Configure Identity Provider
   *
   * Configure Identity Provider on the cluster. Currently this API is only for Open ID providers, but will be expanded to include SAML providers in the future.
   * @param params The `IdentityProviderService.CreateIdentityParams` containing the following parameters:
   *
   * - `body`: Specifies parameters to configure Identity
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateIdentityResponse(params: IdentityProviderService.CreateIdentityParams): __Observable<__StrictHttpResponse<IdentityConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/identity-providers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IdentityConfig>;
      })
    );
  }
  /**
   * Configure Identity Provider
   *
   * Configure Identity Provider on the cluster. Currently this API is only for Open ID providers, but will be expanded to include SAML providers in the future.
   * @param params The `IdentityProviderService.CreateIdentityParams` containing the following parameters:
   *
   * - `body`: Specifies parameters to configure Identity
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateIdentity(params: IdentityProviderService.CreateIdentityParams): __Observable<IdentityConfig> {
    return this.CreateIdentityResponse(params).pipe(
      __map(_r => _r.body as IdentityConfig)
    );
  }

  /**
   * Perform Identity Action
   *
   * Perform an action on an Identity Provider. Currently this API only supports Open ID based SSO providers, but it will be expanded in the future to support SAML SSO providers.
   * @param params The `IdentityProviderService.PerformIdentityActionParams` containing the following parameters:
   *
   * - `body`: Specifies parameters perform an identity action.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  PerformIdentityActionResponse(params: IdentityProviderService.PerformIdentityActionParams): __Observable<__StrictHttpResponse<IdentityAction>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/identity-providers/actions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IdentityAction>;
      })
    );
  }
  /**
   * Perform Identity Action
   *
   * Perform an action on an Identity Provider. Currently this API only supports Open ID based SSO providers, but it will be expanded in the future to support SAML SSO providers.
   * @param params The `IdentityProviderService.PerformIdentityActionParams` containing the following parameters:
   *
   * - `body`: Specifies parameters perform an identity action.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  PerformIdentityAction(params: IdentityProviderService.PerformIdentityActionParams): __Observable<IdentityAction> {
    return this.PerformIdentityActionResponse(params).pipe(
      __map(_r => _r.body as IdentityAction)
    );
  }

  /**
   * Update Identity Provider
   *
   * Update Identity Provider on the cluster. Currently this API only supports Open ID based SSO providers, but it will be expanded in the future to support SAML SSO providers.
   * @param params The `IdentityProviderService.UpdateIdentityParams` containing the following parameters:
   *
   * - `id`: Specifies id of identity provider configuration
   *
   * - `body`: Specifies parameters to update identity provider configuration
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateIdentityResponse(params: IdentityProviderService.UpdateIdentityParams): __Observable<__StrictHttpResponse<IdentityConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/identity-providers/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IdentityConfig>;
      })
    );
  }
  /**
   * Update Identity Provider
   *
   * Update Identity Provider on the cluster. Currently this API only supports Open ID based SSO providers, but it will be expanded in the future to support SAML SSO providers.
   * @param params The `IdentityProviderService.UpdateIdentityParams` containing the following parameters:
   *
   * - `id`: Specifies id of identity provider configuration
   *
   * - `body`: Specifies parameters to update identity provider configuration
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateIdentity(params: IdentityProviderService.UpdateIdentityParams): __Observable<IdentityConfig> {
    return this.UpdateIdentityResponse(params).pipe(
      __map(_r => _r.body as IdentityConfig)
    );
  }

  /**
   * Delete Identity Provider
   *
   * Delete identity provider configuration on the cluster. Currently this API only supports Open ID based SSO providers, but it will be expanded in the future to support SAML SSO providers.
   * @param params The `IdentityProviderService.DeleteIdentityParams` containing the following parameters:
   *
   * - `id`: Specifies id of identity provider configuration
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteIdentityResponse(params: IdentityProviderService.DeleteIdentityParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/identity-providers/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete Identity Provider
   *
   * Delete identity provider configuration on the cluster. Currently this API only supports Open ID based SSO providers, but it will be expanded in the future to support SAML SSO providers.
   * @param params The `IdentityProviderService.DeleteIdentityParams` containing the following parameters:
   *
   * - `id`: Specifies id of identity provider configuration
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteIdentity(params: IdentityProviderService.DeleteIdentityParams): __Observable<null> {
    return this.DeleteIdentityResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get identity providers
   *
   * Get SAML based identity providers configured on the cluster
   * @param params The `IdentityProviderService.GetIdentityProvidersParams` containing the following parameters:
   *
   * - `tenantIds`: Specifies the tenant id's to get idps configured on tenants
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `names`: Specifies the names of the identity providers
   *
   * - `includeAllTenants`: Specifies if idp configurations on all the tenants under the hierarchy of the logged in user should be returned
   *
   * - `ids`: Specifies ids of configured identity providers
   *
   * - `domains`: Specifies domains of the idp configurations
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetIdentityProvidersResponse(params: IdentityProviderService.GetIdentityProvidersParams): __Observable<__StrictHttpResponse<IdentityProviderConfigurations>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.names || []).forEach(val => {if (val != null) __params = __params.append('names', val.toString())});
    if (params.includeAllTenants != null) __params = __params.set('includeAllTenants', params.includeAllTenants.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.domains || []).forEach(val => {if (val != null) __params = __params.append('domains', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/idps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IdentityProviderConfigurations>;
      })
    );
  }
  /**
   * Get identity providers
   *
   * Get SAML based identity providers configured on the cluster
   * @param params The `IdentityProviderService.GetIdentityProvidersParams` containing the following parameters:
   *
   * - `tenantIds`: Specifies the tenant id's to get idps configured on tenants
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `names`: Specifies the names of the identity providers
   *
   * - `includeAllTenants`: Specifies if idp configurations on all the tenants under the hierarchy of the logged in user should be returned
   *
   * - `ids`: Specifies ids of configured identity providers
   *
   * - `domains`: Specifies domains of the idp configurations
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetIdentityProviders(params: IdentityProviderService.GetIdentityProvidersParams): __Observable<IdentityProviderConfigurations> {
    return this.GetIdentityProvidersResponse(params).pipe(
      __map(_r => _r.body as IdentityProviderConfigurations)
    );
  }

  /**
   * Configure identity provider
   *
   * Configure SAML based identity provider on the cluster
   * @param params The `IdentityProviderService.CreateIdentityProviderParams` containing the following parameters:
   *
   * - `body`: Specifies parameters to configure identity provider
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateIdentityProviderResponse(params: IdentityProviderService.CreateIdentityProviderParams): __Observable<__StrictHttpResponse<IdentityProviderConfiguration>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/idps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IdentityProviderConfiguration>;
      })
    );
  }
  /**
   * Configure identity provider
   *
   * Configure SAML based identity provider on the cluster
   * @param params The `IdentityProviderService.CreateIdentityProviderParams` containing the following parameters:
   *
   * - `body`: Specifies parameters to configure identity provider
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateIdentityProvider(params: IdentityProviderService.CreateIdentityProviderParams): __Observable<IdentityProviderConfiguration> {
    return this.CreateIdentityProviderResponse(params).pipe(
      __map(_r => _r.body as IdentityProviderConfiguration)
    );
  }

  /**
   * Login to cluster using idp
   *
   * Redirects the client to the idp site with the URI to login
   * @param params The `IdentityProviderService.IdpsLoginParams` containing the following parameters:
   *
   * - `tenantId`: Specifies an optional tenantId for which the SSO login should be done. If this is not specified, cluster SSO login is done.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  IdpsLoginResponse(params: IdentityProviderService.IdpsLoginParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/idps/login`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Login to cluster using idp
   *
   * Redirects the client to the idp site with the URI to login
   * @param params The `IdentityProviderService.IdpsLoginParams` containing the following parameters:
   *
   * - `tenantId`: Specifies an optional tenantId for which the SSO login should be done. If this is not specified, cluster SSO login is done.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  IdpsLogin(params: IdentityProviderService.IdpsLoginParams): __Observable<null> {
    return this.IdpsLoginResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update identity provider
   *
   * Update SAML based identity provider configurartion on the cluster
   * @param params The `IdentityProviderService.UpdateIdentityProviderParams` containing the following parameters:
   *
   * - `id`: Specifies id of idp configuration
   *
   * - `body`: Specifies parameters to update identity provider configuration
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateIdentityProviderResponse(params: IdentityProviderService.UpdateIdentityProviderParams): __Observable<__StrictHttpResponse<IdentityProviderConfiguration>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/idps/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IdentityProviderConfiguration>;
      })
    );
  }
  /**
   * Update identity provider
   *
   * Update SAML based identity provider configurartion on the cluster
   * @param params The `IdentityProviderService.UpdateIdentityProviderParams` containing the following parameters:
   *
   * - `id`: Specifies id of idp configuration
   *
   * - `body`: Specifies parameters to update identity provider configuration
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateIdentityProvider(params: IdentityProviderService.UpdateIdentityProviderParams): __Observable<IdentityProviderConfiguration> {
    return this.UpdateIdentityProviderResponse(params).pipe(
      __map(_r => _r.body as IdentityProviderConfiguration)
    );
  }

  /**
   * Delete identity provider
   *
   * Delete SAML based identity provider configuration on the cluster
   * @param params The `IdentityProviderService.DeleteIdentityProviderParams` containing the following parameters:
   *
   * - `id`: Specifies id of idp configuration
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteIdentityProviderResponse(params: IdentityProviderService.DeleteIdentityProviderParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/idps/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete identity provider
   *
   * Delete SAML based identity provider configuration on the cluster
   * @param params The `IdentityProviderService.DeleteIdentityProviderParams` containing the following parameters:
   *
   * - `id`: Specifies id of idp configuration
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteIdentityProvider(params: IdentityProviderService.DeleteIdentityProviderParams): __Observable<null> {
    return this.DeleteIdentityProviderResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Create an Identity Provider Configuration.
   *
   * Authenticate Identity Provider (IDP)
   * @param SAMLResponse Specifies the parameters to authenticate an Identity Provider.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  AuthenticateIdpResponse(SAMLResponse: string,
    regionId?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (SAMLResponse != null) __params = __params.set('SAMLResponse', SAMLResponse.toString());
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/idp/authenticate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Create an Identity Provider Configuration.
   *
   * Authenticate Identity Provider (IDP)
   * @param SAMLResponse Specifies the parameters to authenticate an Identity Provider.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  AuthenticateIdp(SAMLResponse: string,
    regionId?: string): __Observable<null> {
    return this.AuthenticateIdpResponse(SAMLResponse, regionId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get the list of IDP configurations.
   *
   * Get the list of Identity Providers (IDP) configurations.
   * @param params The `IdentityProviderService.GetIdpsParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: IncludeTenants specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *   Default's to false.
   *
   * - `ids`: Filter by a list of IDP ids.
   *
   * @return Success
   */
  GetIdpsResponse(params: IdentityProviderService.GetIdpsParams): __Observable<__StrictHttpResponse<Idps>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/idps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Idps>;
      })
    );
  }
  /**
   * Get the list of IDP configurations.
   *
   * Get the list of Identity Providers (IDP) configurations.
   * @param params The `IdentityProviderService.GetIdpsParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: IncludeTenants specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *   Default's to false.
   *
   * - `ids`: Filter by a list of IDP ids.
   *
   * @return Success
   */
  GetIdps(params: IdentityProviderService.GetIdpsParams): __Observable<Idps> {
    return this.GetIdpsResponse(params).pipe(
      __map(_r => _r.body as Idps)
    );
  }

  /**
   * Create an Identity Provider Configuration.
   *
   * Create Identity Provider (IDP) Configuration.
   * @param body Specifies the parameters to create an Identity Provider.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  CreateIdpResponse(body: CreateOrUpdateIdpRequest,
    regionId?: string): __Observable<__StrictHttpResponse<Idp>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/idps`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Idp>;
      })
    );
  }
  /**
   * Create an Identity Provider Configuration.
   *
   * Create Identity Provider (IDP) Configuration.
   * @param body Specifies the parameters to create an Identity Provider.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  CreateIdp(body: CreateOrUpdateIdpRequest,
    regionId?: string): __Observable<Idp> {
    return this.CreateIdpResponse(body, regionId).pipe(
      __map(_r => _r.body as Idp)
    );
  }

  /**
   * List IDP Principals
   *
   * List the IDP Principals which have been created.
   * @param params The `IdentityProviderService.ListIdpPrincipalsParams` containing the following parameters:
   *
   * - `sids`: Specifies a list of principal SIDs.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: IncludeTenants specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * @return Success
   */
  ListIdpPrincipalsResponse(params: IdentityProviderService.ListIdpPrincipalsParams): __Observable<__StrictHttpResponse<IdpPrincipals>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.sids || []).forEach(val => {if (val != null) __params = __params.append('sids', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/idps/principals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IdpPrincipals>;
      })
    );
  }
  /**
   * List IDP Principals
   *
   * List the IDP Principals which have been created.
   * @param params The `IdentityProviderService.ListIdpPrincipalsParams` containing the following parameters:
   *
   * - `sids`: Specifies a list of principal SIDs.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTenants`: IncludeTenants specifies if objects of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *
   * @return Success
   */
  ListIdpPrincipals(params: IdentityProviderService.ListIdpPrincipalsParams): __Observable<IdpPrincipals> {
    return this.ListIdpPrincipalsResponse(params).pipe(
      __map(_r => _r.body as IdpPrincipals)
    );
  }

  /**
   * Create an Identity Provider Configuration.
   *
   * Create and IDP User or Group.
   * @param body Specifies the parameters to create an IDP Principal.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  CreateIdpPrincipalResponse(body: IdpPrincipal,
    regionId?: string): __Observable<__StrictHttpResponse<IdpPrincipal>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/idps/principals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IdpPrincipal>;
      })
    );
  }
  /**
   * Create an Identity Provider Configuration.
   *
   * Create and IDP User or Group.
   * @param body Specifies the parameters to create an IDP Principal.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  CreateIdpPrincipal(body: IdpPrincipal,
    regionId?: string): __Observable<IdpPrincipal> {
    return this.CreateIdpPrincipalResponse(body, regionId).pipe(
      __map(_r => _r.body as IdpPrincipal)
    );
  }

  /**
   * Get IDP Principal by SID
   *
   * List the IDP Principals which have been created.
   * @param sid Specifies the ID of the IDP Principal.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetIdpPrincipalBySidResponse(sid: string,
    regionId?: string): __Observable<__StrictHttpResponse<IdpPrincipal>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/idps/principals/${encodeURIComponent(sid)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IdpPrincipal>;
      })
    );
  }
  /**
   * Get IDP Principal by SID
   *
   * List the IDP Principals which have been created.
   * @param sid Specifies the ID of the IDP Principal.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetIdpPrincipalBySid(sid: string,
    regionId?: string): __Observable<IdpPrincipal> {
    return this.GetIdpPrincipalBySidResponse(sid, regionId).pipe(
      __map(_r => _r.body as IdpPrincipal)
    );
  }

  /**
   * Update IDP Principal.
   *
   * Update the specified IDP Principal
   * @param params The `IdentityProviderService.UpdateIdpPrincipalParams` containing the following parameters:
   *
   * - `sid`: Specifies the SID of the IDP Principal.
   *
   * - `body`: Specifies the parameters to update IDP Principal.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  UpdateIdpPrincipalResponse(params: IdentityProviderService.UpdateIdpPrincipalParams): __Observable<__StrictHttpResponse<IdpPrincipal>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/mcm/idps/principals/${encodeURIComponent(params.sid)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IdpPrincipal>;
      })
    );
  }
  /**
   * Update IDP Principal.
   *
   * Update the specified IDP Principal
   * @param params The `IdentityProviderService.UpdateIdpPrincipalParams` containing the following parameters:
   *
   * - `sid`: Specifies the SID of the IDP Principal.
   *
   * - `body`: Specifies the parameters to update IDP Principal.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  UpdateIdpPrincipal(params: IdentityProviderService.UpdateIdpPrincipalParams): __Observable<IdpPrincipal> {
    return this.UpdateIdpPrincipalResponse(params).pipe(
      __map(_r => _r.body as IdpPrincipal)
    );
  }

  /**
   * Delete an IDP Principal.
   *
   * Returns Success if the Identity Provider Principal is deleted.
   * @param sid Specifies a unique SID of the Principal.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  DeleteIdpPrincipalResponse(sid: string,
    regionId?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/mcm/idps/principals/${encodeURIComponent(sid)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete an IDP Principal.
   *
   * Returns Success if the Identity Provider Principal is deleted.
   * @param sid Specifies a unique SID of the Principal.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  DeleteIdpPrincipal(sid: string,
    regionId?: string): __Observable<null> {
    return this.DeleteIdpPrincipalResponse(sid, regionId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * List details about single Identity Provider configuration.
   *
   * Returns a specific Identity Provider configuration.
   * @param id Specifies a unique id of the IDP.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetIdpByIdResponse(id: number,
    regionId?: string): __Observable<__StrictHttpResponse<Idp>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/idps/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Idp>;
      })
    );
  }
  /**
   * List details about single Identity Provider configuration.
   *
   * Returns a specific Identity Provider configuration.
   * @param id Specifies a unique id of the IDP.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  GetIdpById(id: number,
    regionId?: string): __Observable<Idp> {
    return this.GetIdpByIdResponse(id, regionId).pipe(
      __map(_r => _r.body as Idp)
    );
  }

  /**
   * Update IDP Configuration.
   *
   * Update the specified Identity Provider Configuration.
   * @param params The `IdentityProviderService.UpdateIdpParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the IDP configuration.
   *
   * - `body`: Specifies the parameters to update IDP configuration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  UpdateIdpResponse(params: IdentityProviderService.UpdateIdpParams): __Observable<__StrictHttpResponse<Idp>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/mcm/idps/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Idp>;
      })
    );
  }
  /**
   * Update IDP Configuration.
   *
   * Update the specified Identity Provider Configuration.
   * @param params The `IdentityProviderService.UpdateIdpParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the IDP configuration.
   *
   * - `body`: Specifies the parameters to update IDP configuration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  UpdateIdp(params: IdentityProviderService.UpdateIdpParams): __Observable<Idp> {
    return this.UpdateIdpResponse(params).pipe(
      __map(_r => _r.body as Idp)
    );
  }

  /**
   * Delete a IDP configuration.
   *
   * Returns Success if the Identity Provider configuration is deleted.
   * @param id Specifies a unique id of the IDP.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  DeleteIdpResponse(id: number,
    regionId?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/mcm/idps/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a IDP configuration.
   *
   * Returns Success if the Identity Provider configuration is deleted.
   * @param id Specifies a unique id of the IDP.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  DeleteIdp(id: number,
    regionId?: string): __Observable<null> {
    return this.DeleteIdpResponse(id, regionId).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module IdentityProviderService {

  /**
   * Parameters for GetIdentities
   */
  export interface GetIdentitiesParams {

    /**
     * Specifies the tenant id's to get IDPs configured on tenants
     */
    tenantIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies if IDP configurations on all the tenants under the hierarchy of the logged in user should be returned
     */
    includeAllTenants?: boolean;

    /**
     * Specifies IDs of configured identity providers
     */
    ids?: Array<number>;

    /**
     * Specifies domains of the IDP configurations
     */
    domains?: Array<string>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateIdentity
   */
  export interface CreateIdentityParams {

    /**
     * Specifies parameters to configure Identity
     */
    body: IdentityConfig;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for PerformIdentityAction
   */
  export interface PerformIdentityActionParams {

    /**
     * Specifies parameters perform an identity action.
     */
    body: IdentityAction;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateIdentity
   */
  export interface UpdateIdentityParams {

    /**
     * Specifies id of identity provider configuration
     */
    id: number;

    /**
     * Specifies parameters to update identity provider configuration
     */
    body: IdentityConfig;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteIdentity
   */
  export interface DeleteIdentityParams {

    /**
     * Specifies id of identity provider configuration
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetIdentityProviders
   */
  export interface GetIdentityProvidersParams {

    /**
     * Specifies the tenant id's to get idps configured on tenants
     */
    tenantIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the names of the identity providers
     */
    names?: Array<string>;

    /**
     * Specifies if idp configurations on all the tenants under the hierarchy of the logged in user should be returned
     */
    includeAllTenants?: boolean;

    /**
     * Specifies ids of configured identity providers
     */
    ids?: Array<number>;

    /**
     * Specifies domains of the idp configurations
     */
    domains?: Array<string>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateIdentityProvider
   */
  export interface CreateIdentityProviderParams {

    /**
     * Specifies parameters to configure identity provider
     */
    body: CreateIdpRequestParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for IdpsLogin
   */
  export interface IdpsLoginParams {

    /**
     * Specifies an optional tenantId for which the SSO login should be done. If this is not specified, cluster SSO login is done.
     */
    tenantId?: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateIdentityProvider
   */
  export interface UpdateIdentityProviderParams {

    /**
     * Specifies id of idp configuration
     */
    id: number;

    /**
     * Specifies parameters to update identity provider configuration
     */
    body: UpdateIdpRequestParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteIdentityProvider
   */
  export interface DeleteIdentityProviderParams {

    /**
     * Specifies id of idp configuration
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetIdps
   */
  export interface GetIdpsParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * IncludeTenants specifies if objects of all the tenants under the
     * hierarchy of the logged in user's organization should be returned.
     * Default's to false.
     */
    includeTenants?: boolean;

    /**
     * Filter by a list of IDP ids.
     */
    ids?: Array<number>;
  }

  /**
   * Parameters for ListIdpPrincipals
   */
  export interface ListIdpPrincipalsParams {

    /**
     * Specifies a list of principal SIDs.
     */
    sids?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * IncludeTenants specifies if objects of all the tenants under the
     * hierarchy of the logged in user's organization should be returned.
     */
    includeTenants?: boolean;
  }

  /**
   * Parameters for UpdateIdpPrincipal
   */
  export interface UpdateIdpPrincipalParams {

    /**
     * Specifies the SID of the IDP Principal.
     */
    sid: string;

    /**
     * Specifies the parameters to update IDP Principal.
     */
    body: IdpPrincipal;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;
  }

  /**
   * Parameters for UpdateIdp
   */
  export interface UpdateIdpParams {

    /**
     * Specifies the id of the IDP configuration.
     */
    id: number;

    /**
     * Specifies the parameters to update IDP configuration.
     */
    body: CreateOrUpdateIdpRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;
  }
}

export { IdentityProviderService }
