import { Injectable } from '@angular/core';
import {
  PaginationParameters,
  ProtectionJob,
  ProtectionSourceNode,
  ProtectionSourcesServiceApi,
  RegisteredSourceInfo
} from '@cohesity/api/v1';
import { McmSourceRegistration } from '@cohesity/api/v2';
import { IrisContextService, flagEnabled } from '@cohesity/iris-core';
import { Environment } from '@cohesity/iris-shared-constants';
import { SourceTreeDataProvider } from '@cohesity/iris-source-tree';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { filter, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { PassthroughOptionsService } from 'src/app/core/services';
import {
  Office365SourceDetailsService,
} from 'src/app/modules/sources/office365/services/office365-source-details.service';
import {
  Office365BackupType,
  Office365ContainerNodeType,
  Office365DomainType,
  Office365HierarchyType,
  Office365ActionKeyProtectionTypeMap
} from 'src/app/shared/constants';
import { Office365UtilityService } from 'src/app/shared/office365/services/office365-utility.service';

@Injectable()
export class Office365SourceDataProvider implements SourceTreeDataProvider<ProtectionSourceNode, Office365BackupType> {
  /**
   * Page size to use for loading the source tree. We are still eagerly loading
   * the entire tree, so the page size should be as high as we can get away
   * with in order to avoid too many api calls.
   * This value may be larger than the max size allowed by iris_exec API,
   * FLAGS_MaxEntityHierarchyPageSize, if so, the iris_exec API flag will be
   * used. Pagination will still work as expected if there is a mismatch.
   *
   * NOTE: There is a size limit of 4MB for passthrough calls from ControlPlane
   * to DataPlane. In such cases, the EH call for 50k entities may fail to
   * load. For such scenarios FLAGS_MaxEntityHierarchyPageSize within iris_exec
   * should be tuned.
   */
  private pageSize = 50000;

  /**
   * Specifies a map of O365 source ID to a map of workload to its container
   * nodes. The attributes on the container entities including the root node
   * is responsible for showing the correct stats to the client about
   * aggregated protection statuses across workloads.
   * This is used for removing the extra API call to fetch the container
   * ID while workloads tabs are switched.
   */
  private workloadToContainerEntityMap: Map<number, Map<Office365BackupType, ProtectionSourceNode>>;

  /**
   * Specifies the current M365 Domain Node.
   * Refer office365.contants.ts for details on M365 EH.
   */
  private rootNode: ProtectionSourceNode;

  /**
   * Behavior Subject to store the set of autoprotected entity IDs.
   */
  autoProtectedEntities$: BehaviorSubject<Set<number>>;

  /**
   * Behavior Subject to store the boolean status whether children entities
   * are being fetched.
   */
  private loadingChildrenSubject = new BehaviorSubject(false);

  /**
   * Observable for the boolean flag loadingChildren.
   */
  readonly loadingChildren$ = this.loadingChildrenSubject.asObservable();

  /**
   * Indicates whether the lazy loading feature flag is enabled.
   */
  private lazyLoadEnabled = flagEnabled(this.irisContext.irisContext, 'office365SourceLazyLoadEnabled');

  /**
   * For lazy loading, this will return each page as we receive it so the UI can display.
   */
  private sourceTree = new Subject<ProtectionSourceNode[]>();

  /**
   * This is used to cancel fetching data when we no longer need more responses
   * (e.g. when the workload changes).
   */
  private interruptFetch = new Subject<void>();

  constructor(
    readonly irisContext: IrisContextService,
    readonly passthroughOptionsService: PassthroughOptionsService,
    readonly sourceService: ProtectionSourcesServiceApi,
    private o365SourceDetailsService: Office365SourceDetailsService,
    private office365Utils: Office365UtilityService
  ) {
    this.autoProtectedEntities$ = new BehaviorSubject<Set<number>>(new Set<number>([]));
    this.loadingChildren$ = new BehaviorSubject<boolean>(false);
    this.workloadToContainerEntityMap = new Map<number, Map<Office365BackupType, ProtectionSourceNode>>();
  }

  /**
   * Get value of loadingChildrenSubject
   */
  isLoadingChildren(): boolean {
    return this.loadingChildrenSubject?.getValue();
  }

  /**
   * Updates autoprotectedEntities$ behavior subject.
   *
   * @param   autoProtectedEntities  The newly selected O365 workload.
   */
  setAutoProtectedEntities(autoProtectedEntities: Set<number>) {
    this.autoProtectedEntities$.next(autoProtectedEntities);
  }

  /**
   * Checks whether we should do the registrationInfo API call
   * will be used in the source-details-page component
   *
   * @returns Whether registrationInfo API call is to be made or not
   */
  skipRegistrationInfoApiCall(): boolean {
    return flagEnabled(this.irisContext.irisContext, 'office365SkipRegistrationInfoFetchEnabled');
  }

  /**
   * Persists the v1 API's RegisteredSourceInfo within the service
   * Office365SourceDetailsService for access across components for
   * optimizing operations on the same.
   *
   * @param registeredSourceInfo Specifies the instance of RegisteredSourceInfo
   */
  setOnPremRegistrationInfo(registeredSourceInfo: RegisteredSourceInfo): void {
    this.o365SourceDetailsService.setOnPremRegistrationInfo(registeredSourceInfo);
  }

  /**
   * Persists the v2 API's McmSourceRegistration within the service
   * Office365SourceDetailsService for access across components for
   * optimizing operations on the same.
   *
   * @param mcmSourceRegistration Specifies the instance of McmSourceRegistration
   */
  setHeliosRegistrationInfo(mcmSourceRegistration: McmSourceRegistration): void {
    this.o365SourceDetailsService.setHeliosRegistrationInfo(mcmSourceRegistration);
  }

  /**
   * Clears the in-memory state of 'workloadToContainerEntityMap' which will
   * force the fetch of the container entities within M365. This also cancels
   * any ongoing paginated calls to fetch EH.
   *
   * Note: The fetching of container is a costly operation given the scale in
   * M365. It is the caller's responsibility to careful use the method and
   * in most scenarios the strategy should be to avoid the usage of this.
   * Refer 'workloadToContainerEntityMap' for more info.
   */
  clearInMemoryStores(): void {
    this.interruptFetch.next();
    this.workloadToContainerEntityMap.clear();
  }

  /**
   * Returns the Office365 source hierarchy for the given Domain Id & the
   * selected backup type.
   *
   * NOTE *********************************************************************
   *
   * The Office365 EH holds the following mapping of workloads to their
   * container entities.
   * kUsers -> kMailbox, kOneDrive
   * kSites -> kSharePoint
   * kTeams -> kTeams
   * kGroups -> kGroups, kMailbox, kOneDrive
   * kPublicFolders -> kPublicFolders
   *
   * The containers kUsers & kGroups are special since they dont have a 1:1
   * mapping from their types to their workload which are explained below:
   *
   * Case 1: kUsers
   *   kUsers holds a list of kUser entities which have a Mailbox or OneDrive
   * or both which can be backed up. The choice is then left on the client to
   * decide on the same.
   *
   * Case 2: kGroups
   *   kGroups holds a list of kGroup entities which are either Mail-enabled
   * or are Security-enabled or both.
   *   Mail-enabled Groups have their own dedicated mailbox which can be backed
   * up. Security-enabled Group entities are simply a way of tagging together
   * multiple users based on attributes that the M365 organization has chosen.
   *   Thus backing up Security-enabled Group is another way of saying backup
   * mailboxes or onedrives of a list of kUser entities. Such Groups are called
   * Security Groups.
   *   Within the UI in the workload tabs, Security Group is just a view for
   * list of security-enabled Group entities.
   *
   * **************************************************************************
   *
   * The decision to fetch either single subtree based on workload and view or
   * 2 subtrees incase of SecurityGroups view is first taken. Accordingly,
   * fetchLinkedWorkloadTrees(...) or fetchStandaloneWorkloadTree(...) are
   * executed.
   * In either of the 2 functions, the hierarchy is fetched in 2 steps:
   *
   * 1. Container entities are fetched as the entire EH will include not just
   * mailbox(Users) but Sites, Groups, Teams, etc. Container entities are then
   * modified based on current workload's autoprotection spec for entities
   * that support autoprotection.
   * As an optimization Step 1 may be skipped if the container entity is
   * already known to the service for the workload as part of the map
   * 'workloadToContainerEntityMap'.
   *
   * 2. Based on the workload selected(currently Mailbox), the sub tree within
   * the EH is fetched using loadAllChilldren.
   *
   * For details on Office365 EH, refer constants/office365.constants.ts.
   *
   * @param sourceId            The parent id to load the tree for.
   * @param environment         The environment to loadd the tree for.
   * @param workloadType        The current workflow to use within the tree.
   * @param allUnderHierarchy   Whether to include all objects all nodes a user has access to
   * @returns An observable with the entire tree data
   */
  fetchTree(
    sourceId: number,
    environment: Environment,
    workloadType?: Office365BackupType,
    allUnderHierarchy?: boolean,
    resolveEarlyWithContainer?: boolean
  ): Observable<ProtectionSourceNode[]> {

    if (this.lazyLoadEnabled) {
      // when starting a new fetch, cancel any pending API calls
      this.interruptFetch.next();
    }

    if (!workloadType) {
      workloadType = this.o365SourceDetailsService.getWorkload();
    }
    this.o365SourceDetailsService.setWorkload(workloadType);

    // Decide if more than 1 container is to be fetched.
    // This will only happen in case of kMailbox/kOneDrive.
    const isSecurityGroupsViewSupportedForCurrentWorkload =
      this.o365SourceDetailsService.isSecurityGroupsViewSupportedForCurrentWorkload();

    if (this.lazyLoadEnabled) {
      if (isSecurityGroupsViewSupportedForCurrentWorkload) {
        this.fetchLinkedWorkloadTrees(
          sourceId,
          environment,
          workloadType,
          allUnderHierarchy
        )
        .subscribe();
      } else {
        this.fetchStandaloneWorkloadTree(
          sourceId,
          environment,
          workloadType,
          allUnderHierarchy,
          resolveEarlyWithContainer
        )
        .subscribe();
      }

      return this.sourceTree.asObservable();
    } else {
      if (isSecurityGroupsViewSupportedForCurrentWorkload) {
        return this.fetchLinkedWorkloadTrees(
          sourceId,
          environment,
          workloadType,
          allUnderHierarchy
        );
      } else {
        return this.fetchStandaloneWorkloadTree(
          sourceId,
          environment,
          workloadType,
          allUnderHierarchy
        );
      }
    }
  }

  /**
   * Returns the Office365 source hierarchy subset with the fields derived
   * from the ids and manes in thenodes from the job
   *
   * @param job             The job from whose 'sources' the tree will be derived
   * @param environment     Environment source tree belong to.
   * @returns               An observable with a subset of tree data scoped to the job
   */
  fetchDerivedTreeFromJob(job: ProtectionJob, environment: Environment): Observable<ProtectionSourceNode[]> {
    const workloadType = Office365ActionKeyProtectionTypeMap[environment];
    const { sources, parentSourceId } = job;
    this.o365SourceDetailsService.setWorkload(workloadType);

    const { nodeType, leafNodeType } = this.office365Utils.getNodeTypeAndNameFromWorkLoad(workloadType);

    const containerNodeName = nodeType.substring(1);

    const nodes = sources.map(({id, name}) => (
      {
        protectionSource: {
          environment: Environment.kO365,
          id,
          parentId: parentSourceId,
          name,
          office365ProtectionSource: {
            type: leafNodeType,
            name
          }
        }
      }
    ));

    // Move this to a factory function once there are other variations
    return of([{
      nodes: [{
        nodes: nodes,
        protectionSource: {
          environment: Environment.kO365,
          parentId: parentSourceId,
          name: containerNodeName,
          office365ProtectionSource: {
            type: nodeType,
            uuid: containerNodeName,
            name: containerNodeName
          }
        }
      }],
      protectionSource: {
        environment: Environment.kO365,
        id: parentSourceId,
        office365ProtectionSource: {
          type: 'kDomain'
        }
      }
    }]);
  }

  /**
   * Fetch alternate containerNode that should be sent together with the children of current containerNode
   * and send children of both containerNode. Updating rootNode after each api call.
   *
   * @param containerNode The current container node.
   * @param workloadType  The current workflow to use within the tree.
   * @param children      Children of current contianer node.
   * @returns List of ProtectionSourceNode containing children of both current and alternate containerNode
   */
  getContainerNode(
    containerNode: ProtectionSourceNode,
    children: ProtectionSourceNode[]
  ): ProtectionSourceNode[] {
      if (!this.rootNode || !this.rootNode?.nodes?.length) {
        return null;
      }

      let altContainerNode: ProtectionSourceNode;
      let containerNodeIdx: number;
      if(containerNode?.protectionSource?.office365ProtectionSource?.type === Office365ContainerNodeType.kGroups) {
        altContainerNode = this.office365Utils.getContainerEntity(this.rootNode, Office365BackupType.kMailbox);
        containerNodeIdx = this.rootNode.nodes.findIndex((SourceNode: ProtectionSourceNode) =>
                SourceNode?.protectionSource?.office365ProtectionSource?.type === Office365ContainerNodeType.kGroups);
      } else {
        altContainerNode = this.office365Utils.getContainerEntity(this.rootNode, Office365BackupType.kGroups);
        containerNodeIdx = this.rootNode.nodes.findIndex((SourceNode: ProtectionSourceNode) =>
                SourceNode?.protectionSource?.office365ProtectionSource?.type === Office365ContainerNodeType.kUsers);
      }
      if(containerNodeIdx !== -1) {
        (this.rootNode.nodes[containerNodeIdx] as ProtectionSourceNode).nodes = children;
      }

      return [{
        ...this.rootNode,
        nodes: [{...altContainerNode}, {
          ...containerNode,
          nodes: children
        }]
      }];
  }

  /**
   * Prunes unnecessary properties on the protection source node. These will
   * include warning messages.
   *
   * @param protectionSource Specifies the instance of Protection Source node.
   * @returns light-weight protection source node.
   */
  pruneProtectionSource(protectionSource: ProtectionSourceNode): ProtectionSourceNode{
    return this.office365Utils.pruneProtectionSource(protectionSource);
  }

  /**
   * Recursively loads all of a node's children in chunks until there are none left
   *
   * @param containerNode       The container node to load children for
   * @param allUnderHierarchy   Whether to include all objects all nodes a user has access to
   * @param workloadType        The current workflow to use within the tree.
   * @param pageInfo            Pagination info from the previus load
   * @param loadedChildren      Already loaded children
   * @returns All of a node's direct children, using paginated apis
   */
  loadAllChildren(
    containerNode: ProtectionSourceNode,
    allUnderHierarchy: boolean,
    workloadType: Office365BackupType,
    pageInfo: PaginationParameters = null,
    loadedChildren: ProtectionSourceNode[] = []
  ): Observable<ProtectionSourceNode> {

    const isSecurityGroupsViewSupportedForCurrentWorkload =
      this.o365SourceDetailsService.isSecurityGroupsViewSupportedForCurrentWorkload();

    return this.sourceService
      .ListProtectionSources({
        id: containerNode.protectionSource.id,
        nodeId: containerNode.protectionSource.id,
        allUnderHierarchy: allUnderHierarchy || false,
        ...this.office365Utils.getEntityFilterParameters(workloadType),
        ...this.passthroughOptionsService.requestParams,
        pageSize: this.pageSize,
        afterCursorEntityId: pageInfo?.beforeCursorEntityId,
        pruneNonCriticalInfo: flagEnabled(this.irisContext.irisContext, 'pruneNonCriticalInfo'),
      })
      .pipe(
        takeUntil(this.interruptFetch),
        switchMap(([parent]) => {
          const children = [...loadedChildren, ...(parent.nodes || [])];

          // Determines whether the client(UI) should continue fetching more
          // entities.
          let loadMore = !!parent.entityPaginationParameters?.beforeCursorEntityId && !!parent.nodes;

          // TODO(tauseef): Remove #295 to #313 once ENG-347413 is resolved.
          //
          // Due to ENG-347413, magneto returns beforeCursorEntityId even if
          // the last page is being returned. An extra API call is triggerred
          // since client doesn't know if it has to stop fetching more. The
          // extra API call returns only the last entity and has same after &
          // before cursor.
          if (parent.entityPaginationParameters?.beforeCursorEntityId ===
            parent.entityPaginationParameters?.afterCursorEntityId) {
            loadMore = false;
          }
          // Due to ENG-347413, remove the last entity from the children array
          // since magneto will return the same in the next API call as the
          // first entity.
          if (loadMore) {
            children.pop();
          }

          this.loadingChildrenSubject.next(loadMore);

          // Need to send results of both kUsers and kGroups when loadChildren is called for both
          // flatViewContainerNode and securityGroupViewContainerNode in fetchLinkedWorkloadTrees.
          if(isSecurityGroupsViewSupportedForCurrentWorkload) {
            this.sourceTree.next(
              this.getContainerNode(containerNode,
              children));
          } else {
            this.sourceTree.next([{
              ...this.rootNode,
              nodes: [{
                ...containerNode,
                nodes: children
              }]
            }]);
          }

          if (loadMore) {
            return this.loadAllChildren(
              containerNode,
              allUnderHierarchy,
              workloadType,
              parent.entityPaginationParameters,
              children
            );
          } else {
            containerNode.nodes = children;
            return of(containerNode);
          }
        }),
      );
  }

  /**
   * Fetches the single subtree for the given source and the workload.
   * This is applicable to all workloads except kMailbox & kOneDrive iff
   * Security feature flags is enabled.
   *
   * @param sourceId            The parent id to load the tree for.
   * @param environment         The environment to loadd the tree for.
   * @param workloadType        The current workflow to use within the tree.
   * @param allUnderHierarchy   Whether to include all objects all nodes a user has access to
   * @returns An observable with the entire tree data
   */
  private fetchStandaloneWorkloadTree(
    sourceId: number,
    environment: Environment,
    workloadType?: Office365BackupType,
    allUnderHierarchy?: boolean,
    resolveEarlyWithContainer?: boolean
  ): Observable<ProtectionSourceNode[]> {
    let treeLoader: Observable<ProtectionSourceNode[]>;

    if (this.workloadToContainerEntityMap.has(sourceId) &&
        this.workloadToContainerEntityMap.get(sourceId)?.get(workloadType)) {
      treeLoader = this.autoProtectedEntities$.pipe(
        filter(response => !!response),
        first(),
        switchMap((autoProtectedEntitySet) => {
          let containerNode = this.workloadToContainerEntityMap.get(sourceId)?.get(workloadType);
          containerNode = this.setAutoProtectionInfoOnContainer(
            containerNode, autoProtectedEntitySet);
          return this.loadAllChildren(containerNode, allUnderHierarchy, workloadType);
        }),
        map(container => {
          this.rootNode.nodes = [container];
          return Array.of(this.rootNode);
        })
      );
    } else {
      treeLoader = forkJoin([
        this.sourceService.ListProtectionSources({
          id: sourceId,
          allUnderHierarchy: allUnderHierarchy || false,

          // Use NumLevels to optimize fetching of container entities.
          numLevels: 1,
          ...this.passthroughOptionsService.requestParams,
        }),
        this.autoProtectedEntities$.pipe(
          filter(response => !!response),
          first())
      ]).pipe(
          map(([[rootNode], autoProtectedEntitySet]) => {
            this.office365Utils.pruneProtectionSource(rootNode);
            this.rootNode = rootNode;
            this.maybePersistRootNodeInLocalCache(rootNode);
            this.o365SourceDetailsService.setSummaryCountMap(
              this.office365Utils.getAggregationSummayAcrossAllWorkload(rootNode));
            let containerNode = this.office365Utils.getContainerEntity(rootNode, workloadType);
            if (!containerNode) {
              return rootNode;
            }

            containerNode = this.setAutoProtectionInfoOnContainer(
              containerNode, autoProtectedEntitySet);

            // Save the container nodes to avoid API call while switching tabs.
            this.workloadToContainerEntityMap.set(rootNode.protectionSource.id,
              this.office365Utils.getWorkloadContainerMap(rootNode));
            return containerNode;
          }),
          switchMap((container: ProtectionSourceNode) => {
            if (container.protectionSource?.office365ProtectionSource?.type === Office365DomainType) {
              // Resolve early if only the container node has been discovered
              if (resolveEarlyWithContainer) {
                this.sourceTree.next([{
                  ...this.rootNode,
                  nodes: [{
                    ...container,
                    nodes: []
                  }]
                }]);
              }
              return of(container as any);
            }
            return this.loadAllChildren(container, allUnderHierarchy, workloadType);
          }),
          map(container => {
            this.rootNode.nodes = [container];
            return Array.of(this.rootNode);
          })
        );
    }

    if (this.lazyLoadEnabled) {
      treeLoader.subscribe();
      return this.sourceTree;
    } else {
      return treeLoader;
    }
  }

  /**
   * Fetches 2 subtrees(kUsers and kGroups) to support both kMailbox &
   * kOneDrive backups of either kUser or members of kGroup(Security enabled).
   *
   * @param sourceId            The parent id to load the tree for.
   * @param environment         The environment to loadd the tree for.
   * @param workloadType        The current workflow to use within the tree.
   * @returns An observable with the entire tree data
   */
  private fetchLinkedWorkloadTrees(
    sourceId: number,
    environment: Environment,
    workloadType?: Office365BackupType,
    allUnderHierarchy?: boolean
  ): Observable<ProtectionSourceNode[]> {
    let treeLoader: Observable<ProtectionSourceNode[]>;

    const defaultViewContainerType = this.office365Utils.getContainerWorkloadMapping(
      workloadType, Office365HierarchyType.Default);
    const securityGroupViewContainerType = this.office365Utils.getContainerWorkloadMapping(
      workloadType, Office365HierarchyType.SecurityGroup);

    return forkJoin([
      this.sourceService.ListProtectionSources({
        id: sourceId,
        allUnderHierarchy: allUnderHierarchy || false,

        // Use NumLevels to optimize fetching of container entities.
        numLevels: 1,
        ...this.passthroughOptionsService.requestParams,
      }),
      this.autoProtectedEntities$.pipe(
        filter(response => !!response),
        first())
    ]).pipe(
      map(([[rootNode], autoProtectedEntitySet]) => {
        this.office365Utils.pruneProtectionSource(rootNode);
        this.rootNode = rootNode;
        this.maybePersistRootNodeInLocalCache(rootNode);
        this.o365SourceDetailsService.setSummaryCountMap(
          this.office365Utils.getAggregationSummayAcrossAllWorkload(rootNode));

        let flatViewContainerNode = this.office365Utils.getContainerEntity(
          rootNode, defaultViewContainerType);
        const securityGroupViewContainerNode = this.office365Utils.getContainerEntity(
          rootNode, securityGroupViewContainerType);

        if (!flatViewContainerNode) {
          return [rootNode];
        }

        // Only update viewable container node.
        flatViewContainerNode = this.setAutoProtectionInfoOnContainer(
          flatViewContainerNode, autoProtectedEntitySet);

        // Save the container nodes to avoid API call while switching tabs.
        this.workloadToContainerEntityMap.set(rootNode.protectionSource.id,
          this.office365Utils.getWorkloadContainerMap(rootNode));

        return [flatViewContainerNode, securityGroupViewContainerNode];
      }),
      switchMap((containerNodes: ProtectionSourceNode[]) => {
        if (containerNodes[0].protectionSource?.office365ProtectionSource?.type === Office365DomainType) {
          return of(containerNodes);
        }
        // set the ids of the containers we're loading nodes for.
        treeLoader = forkJoin({
          securityGroupList: this.loadAllChildren(containerNodes[1],
            allUnderHierarchy,
            workloadType) as Observable<ProtectionSourceNode>,
          userList: this.loadAllChildren(containerNodes[0],
            allUnderHierarchy,
            workloadType) as Observable<ProtectionSourceNode>
        }).pipe(
          map(response => {
            this.rootNode.nodes =
              [response.securityGroupList, response.userList];
            return Array.of(this.rootNode);
          })
        );

        if (this.lazyLoadEnabled) {
          treeLoader.subscribe();
          return this.sourceTree;
        } else {
          return treeLoader;
        }
      }),
    );

  }

  /**
   * Updates the autoprotection info on the containers.
   *
   * @param containerNode Specifies the container node.
   * @param autoProtectedEntitySet Specifies the Set of entity IDs which are
   *                               autoprotected
   * @returns Updated container node which autoprotection info.
   */
  private setAutoProtectionInfoOnContainer(
    containerNode: ProtectionSourceNode,
    autoProtectedEntitySet: Set<number>): ProtectionSourceNode {
    // Modify autoprotection spec at the container based on
    // autoprotection info.
    if (containerNode.objectProtectionInfo &&
        !autoProtectedEntitySet.has(containerNode.protectionSource?.id)) {
      containerNode.objectProtectionInfo.hasActiveObjectProtectionSpec = false;
    }

    return containerNode;
  }

  /**
   * Persists the root node along with the container entities within the
   * Cache. It is the caller's responsibility to handle staleness.
   * Currently the UI client will always update the cache with the
   * latest EH from Magneto everytime the source details page is visited.
   *
   * TODO(tauseef): Maybe clear cache if the source is unregistered.
   *
   * @returns void
   */
  private maybePersistRootNodeInLocalCache(rootNode: ProtectionSourceNode) {
    if (!rootNode) {
      return;
    }
    this.o365SourceDetailsService.storeDomainNodeInCache(rootNode);
  }
}
