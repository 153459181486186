import { Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, mergeMap, tap } from 'rxjs/operators';

import { BatchedQueue } from './batched-queue';
import { ItemCache } from './item-cache';

/**
 * Id could be a string or a number.
 */
export type ItemId = string | number;

/**
 * An entry for an item in the store.
 */
export interface StoreEntry<T> {
  /**
   * The item's id
   */
  id: ItemId;

  /**
   * The item itself. If this is set to null and loading is false, then
   * the item does not exist.
   */
  item: T;

  /**
   * Whether the item is currently loading or not.
   */
  loading: boolean;

  /**
   * True if there was an error loading the object.
   */
  error: boolean;
}

/**
 * This class is used to manage a store of items, and to make api calls for items
 * as needed. The store requires a callback method to look up items, which can
 * be requested by id. It uses a batched queue so that it can take requests for many
 * items at once and then send requests in batches in order to not overwealm the api.
 */
export class LazyItemStore<T = any> {
  /**
   * A cache of items
   */
  cache: ItemCache<StoreEntry<T>>;

  /**
   * The queue of requests.
   */
  private requestQueue: BatchedQueue<ItemId>;

  /**
   * Returns a new instance of LazyItemStore.
   *
   * @param getId A callback to get the id from an item in the store.
   * @param fetchItems A callback to fetch items to add to the store.
   * @param maxItemSize Max items to allow in a single request.
   * @param debounceTimsMs Max amount of time to wait between api calls.
   */
  constructor(
    private getId: (val: T) => string | number,
    private fetchItems: (ids: ItemId[]) => Observable<T[]>,
    maxItemSize: number = 50,
    debounceTimeMs: number = 100
  ) {
    this.cache = new ItemCache(entry => entry.id);
    this.requestQueue = new BatchedQueue<ItemId>(maxItemSize, debounceTimeMs);
  }

  /**
   * This can be used to manually add items to the store.
   *
   * @param items A list of items to add to the store.
   */
  addItems(items: T[]) {
    this.cache.cacheItems(this.itemsToStoreEntries(items));
  }

  /**
   * Connects to the lazy item store.
   *
   * @returns an observable to subscribe to in order to run the store.
   */
  connect(): Observable<void> {
    return this.requestQueue.batchedQueue$.pipe(
      mergeMap(ids => {
        this.cache.cacheItems(this.idsToStoreEntries(ids, true));
        return this.fetchItems(ids).pipe(
          tap(items =>
            this.cache.cacheItems([...this.idsToStoreEntries(ids, false), ...this.itemsToStoreEntries(items)])
          ),
          catchError(error => {
            // Just log the error rather than showing a snapshoto because this call will be made a lot
            // and we don't want to show too many snackbars.
            console.error(error);
            this.cache.cacheItems([...this.idsToStoreEntries(ids, false, true)]);
            return of(undefined);
          })
        );
      }),
      // Don't return a result, we just need a mechanism to stop and start the store.
      map(() => undefined),
      distinctUntilChanged()
    );
  }

  /**
   * Gets an item from the store. If the item is not already in the store, or if
   * allow cache is false, it will trigger a new requst.
   *
   * @param   id          The id of the item to fetch.
   * @param   allowCache  Whether to allow a cached value.
   * @returns An observable of the item's entry.
   */
  getItem(id: ItemId, allowCache = true): Observable<StoreEntry<T>> {
    if (!allowCache || !this.cache.hasItem(id)) {
      this.requestQueue.push(id);

      // If the item is already in our cache, we need to reset it to a loading status
      if (this.cache.hasItem(id)) {
        this.cache.cacheItems(this.idsToStoreEntries([id], true));
      }
    }
    return this.cache.getItem(id);
  }

  /**
   * Gets items from the store. If the items are not already in the store, or if
   * allow cache is false, it will trigger a new requst for each item.
   *
   * @param   ids          The ids of the items to fetch.
   * @param   allowCache  Whether to allow a cached value.
   * @returns An observable of the item entries.
   */
  getItems(ids: ItemId[], allowCache = true): Observable<StoreEntry<T>[]> {
    (ids || []).forEach(id => this.getItem(id, allowCache));
    return this.cache.getItems(ids);
  }

  /**
   * Clears the entire store
   */
  reset() {
    this.cache.reset();
  }

  /**
   * Converts a set of ids to store entries to add to the cache. This is used either
   * to initialize an item in the store or to set entries where the items were null.
   *
   * @param   ids  ids to add
   * @return  Store entry objects
   */
  private idsToStoreEntries(ids: ItemId[], loading: boolean, error: boolean = false): StoreEntry<T>[] {
    return (ids || []).map(id => ({
      item: null,
      loading,
      id,
      error,
    }));
  }

  /**
   * Converts a set of items to store entries to add to the cache
   *
   * @param   items  Items to add
   * @return  Store entry objects
   */
  private itemsToStoreEntries(items: T[]): StoreEntry<T>[] {
    return (items || []).map(item => ({
      item,
      loading: false,
      id: this.getId(item),
      error: false,
    }));
  }
}
