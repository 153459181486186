import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { IrisContextService } from '@cohesity/iris-core';
import { HasCustomRBACPermissions } from './has-permission.utils';

/**
 * Type for permission perdicate.
 */
export type PermissionPredicate = 'hasAny' | 'hasAll';

/**
 * Directive to manage access control for elements based on RBAC permissions.
 * Hides the element if the required permission is not granted.
 *
 * Usage:
 * ```html
 * <button [dgHasPermissions]="['PERMISSION_1', 'PERMISSION_2']" predicate="hasAll"
 *  (click)="onScanClick()">Scan</button>
 * ```
 */
@Directive({
  selector: '[dgHasPermissions]'
})
export class HasPermissionDirective implements OnInit {
  /**
   * The required list of permission to enable the element.
   */
  @Input('dgHasPermissions') permissions: string[];

  /**
   * Optional - The permission check type (hasAny or hasAll).
   */
  @Input() permissionPredicate: PermissionPredicate = 'hasAny';

  /**
   * Creates an instance of HasPermissionDirective.
   *
   * @param el - The element this directive is applied to.
   * @param irisCtx - Service to provide the Iris context for RBAC permission checks.
   */
  constructor(
    private el: ElementRef,
    private irisCtx: IrisContextService,
  ) {}

  /**
   * OnInit lifecycle hook to perform the initial permission check.
   */
  ngOnInit(): void {
    this.updateElementState();
  }

  /**
   * Updates the element state based on the required permission.
   * Hides the element if the required permission is not granted.
   */
  private updateElementState(): void {
    const thisElement = this.el.nativeElement;
    const hasPermission = HasCustomRBACPermissions(this.permissions,
      this.irisCtx.irisContext, this.permissionPredicate);

    if (!hasPermission && thisElement.parentNode) {
      thisElement.parentNode.removeChild(thisElement);
    }
  }
}
