import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProtectionSourceNode } from '@cohesity/api/v1';

import { NodeAgentInformation } from '../physical-popover.component';

@Component({
  selector: 'coh-agent-information',
  templateUrl: './agent-information.component.html',
  styleUrls: ['../physical-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentInformationComponent {
  /**
   * Access to the current node property.
   */
  @Input() node: ProtectionSourceNode;

  /**
   * Access to the current agent of the node.
   */
  @Input() agents: NodeAgentInformation[];

  /**
   * Determines if there is any connection error while connecting to the node.
   */
  get isConnectionError(): boolean {
    return !!this.node?.registrationInfo?.refreshErrorMessage;
  }

  /**
   * Determines the status of file CBT driver associated with the agent.
   */
  get fileCbtStatus(): string {
    const fileCbtInfo = this.agents[0]?.fileCbtInfo;

    if (!fileCbtInfo || this.node.protectionSource.physicalProtectionSource.type !== 'kHost') {
      return null;
    }

    return !fileCbtInfo.isInstalled ? 'notInstalled' : fileCbtInfo.serviceState;
  }

  /**
   * Determines the status of Volume CBT driver associated with the agent.
   */
  get volCbtStatus(): string {
    const volCbtInfo = this.agents[0]?.volCbtInfo;

    if (!volCbtInfo || this.node.protectionSource.physicalProtectionSource.type !== 'kHost') {
      return null;
    }

    switch (true) {
      case !volCbtInfo.isInstalled:
        return 'notInstalled';
      case volCbtInfo.rebootStatus === 'kNeedsReboot':
        return 'kNeedsReboot';
      case volCbtInfo.rebootStatus === 'kInternalError':
        return 'rebooStatustFetchError';
      default:
        return volCbtInfo.serviceState;
    }
  }

  /**
   * Upgrade icon based on agent status.
   */
  readonly upgradabilityIcons: Record<NodeAgentInformation['upgradability'], string> = {
    kCurrent: 'status-ok',
    kUnknown: 'status-info',
    kNonUpgradableInvalidVersion: 'status-warning',
    kNonUpgradableAgentIsNewer: 'status-warning',
    kUpgradable: 'status-warning',
    kNonUpgradableAgentIsOld: 'status-warning',
  };
}
