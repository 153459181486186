/* eslint-disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for CohesityReportingApi services
 */
@Injectable({
  providedIn: 'root',
})
export class CohesityReportingApiConfiguration {
  rootUrl: string = '/heliosreporting/api/v1';
}

export interface CohesityReportingApiConfigurationInterface {
  rootUrl?: string;
}
