import { Component } from '@angular/core';
import { Tenant } from '@cohesity/api/v1';
import { TenantService } from 'src/app/core/services';

/**
 * Use this component to show impersonated tenant/organization name.
 */
@Component({
  selector: 'coh-impersonation-indicator',
  templateUrl: './impersonation-indicator.component.html',
})
export class ImpersonationIndicatorComponent {
  constructor(private tenantService: TenantService) {}

  /**
   * Get the impersonated tenant object.
   *
   * @returns The impersonated tenant object.
   */
  get impersonatedTenant(): Tenant {
    return this.tenantService.impersonatedTenant;
  }
}
