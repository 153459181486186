import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { EnvironmentTypePipe } from './environment-type.pipe';

@NgModule({
  declarations: [EnvironmentTypePipe],
  imports: [TranslateModule.forChild()],
  exports: [EnvironmentTypePipe],
  providers: [EnvironmentTypePipe]
})
export class EnvironmentTypeModule { }
