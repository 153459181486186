import { Pipe, PipeTransform } from '@angular/core';
import { AjsUpgradeService } from 'src/app/core/services/ajs-upgrade.service';
import { UpgradePipe } from './upgrade-pipe';

/**
 * Upgraded view box dedupe status pipe
 */
@Pipe({
  name: 'viewBoxDedupeStatus',
  pure: true,
})
export class ViewBoxDedupeStatusPipe extends UpgradePipe implements PipeTransform {
  constructor(upgradeService: AjsUpgradeService) {
    super(upgradeService, 'viewBoxDedupeStatus');
  }
}
/**
 * Upgraded view box dedupe encryption pipe
 */
@Pipe({
  name: 'viewBoxEncryption',
  pure: true,
})
export class ViewBoxEncryptionPipe extends UpgradePipe implements PipeTransform {
  constructor(upgradeService: AjsUpgradeService) {
    super(upgradeService, 'viewBoxEncryption');
  }
}
/**
 * Upgraded view box dedupe compression pipe
 */
@Pipe({
  name: 'viewBoxCompression',
  pure: true,
})
export class ViewBoxCompressionPipe extends UpgradePipe implements PipeTransform {
  constructor(upgradeService: AjsUpgradeService) {
    super(upgradeService, 'viewBoxCompression');
  }
}
