;(function(angular, undefined) {
  'use strict';

  angular
    .module('C')
    .service('ProtectionSummaryUtil', protectionSummaryUtilFn);

  function protectionSummaryUtilFn($parse) {
    var ProtectionSummaryUtil = {
      calculateSummaryStats: calculateSummaryStats,
      groupByRegisteredSource: groupByRegisteredSource,
      groupObjects: groupObjects,
      objectTypeStats: objectTypeStats,
    };

    /**
     * Creates registeredSource subgroups inside each environment type group,
     * also aggregates stats for each new registeredSource group. Each
     * registeredSource subgroups will contain a 'sourceSummary' object that
     * will contain aggregated data.
     *
     * @param  {object} sourceGroups  an object of sources grouped by environment
     *                                type
     *
     * @param  {string} prop          the key / property we want to group by
     *
     * @return {object}               an object grouped by environment with
     *                                registered source subgroups ex:
     *                                {
     *                                  kVMware: {
     *                                    sv4-eng-cohesity: {
     *                                      sourceSummary: {}
     *                                    }
     *                                  }
     *                                }
     */
    function groupByRegisteredSource(sourceGroups, prop) {
      var sourceKeys = Object.keys(sourceGroups);
      var groups = {};

      sourceKeys.forEach(function loopSources(source) {
        groups[source] = sourceGroups[source].reduce(
          function groupSources(accumulator, value) {
            var key = value[prop];

            if (!accumulator[key]) {
              accumulator[key] = [];

              accumulator[key].sourceSummary = {
                vmCount: 1,
                successCount: 0,
                errorCount: 0,
                runningCount: 0,
                dataRead: value.numDataReadBytes,
                logicalProtected: value.numLogicalBytesProtected,
              };

            } else {
              accumulator[key].sourceSummary.vmCount++;
              accumulator[key].sourceSummary.dataRead +=
                value.numDataReadBytes || 0;
              accumulator[key].sourceSummary.logicalProtected +=
                value.numLogicalBytesProtected || 0;
            }

            accumulator[key].push(value);
            lastRunStatsIncrementer(accumulator[key].sourceSummary, value.lastRunStatus);

            return accumulator;
        }, {});
      });

      return groups;
    }

    /**
     * Aggregates data from all enviroment types to be displayed in summary
     * container
     * @param  {object} objectTypes data grouped by environment type
     *
     * @return {object}             aggregated data
     */
    function calculateSummaryStats(objectTypes) {
      var stats = {
        totalSources: 0,
        totalObjects: 0,
        totalSuccessObjects: 0,
        totalErrorObjects: 0,
        totalRunningObjects: 0,
        totalDataRead: 0,
        totalLogicalProtected: 0,
      };

      for (var item in objectTypes) {
        stats.totalSources += objectTypes[item].sourcesCount || 0;
        stats.totalObjects += objectTypes[item].totalObjects || 0;
        stats.totalSuccessObjects += objectTypes[item].successCount || 0;
        stats.totalErrorObjects += objectTypes[item].errorCount || 0;
        stats.totalRunningObjects += objectTypes[item].runningCount || 0;
        stats.totalDataRead += objectTypes[item].dataRead || 0;
        stats.totalLogicalProtected += objectTypes[item].logicalProtected || 0;
      }

      return stats;
    }

    /**
     * group our 'Object Types' by environment
     *
     * @param  {array} objects  an array of 'Object Types' that need to be
     *                          grouped by environment.
     *
     * @param  {string} prop    key / property to group by
     *
     * @return {Object}         an object grouped by environment type ex:
     *                          {
     *                            kVMware: [],
     *                            kNetApp: [],
     *                          }
     */
    function groupObjects(objects, prop) {
      var getter = $parse(prop);

      return objects.reduce(function groupTypes(accumulator, item) {
        var key = getter(item);

        accumulator[key] = accumulator[key] || [];
        accumulator[key].push(item);

        return accumulator;
      }, {});
    }

    /**
     * Aggregate stats for each 'Object Type'. High level aggregation for
     * each object type.
     *
     * @param  {object} groupData an object group by environment type
     *
     * @return {object}           Object organized by environemt type containing
     *                            'Object Type' stats ex:
     *                            {
     *                              kVMware: {
     *                                errorCount: 0,
     *                                successCount: 0,
     *                                runningCount: 0
     *                                .......
     *                              }
     *                            }
     */
    function objectTypeStats(groupData) {
      var groupKeys = Object.keys(groupData);
      var typeStats = {};

      groupKeys.forEach(function loopGroupKeys(key, index) {
        typeStats[key] = {};

        if (groupData[key].length) {
          var sourcesMap = {};

          typeStats[key] = {
            errorCount: 0,
            successCount: 0,
            runningCount: 0,
            dataRead: 0,
            logicalProtected: 0,
          };

          groupData[key].forEach(function loopGroupData(value, index) {
            if (!sourcesMap[value.registeredSource]) {
              sourcesMap[value.registeredSource] = value.registeredSource;
            }

            lastRunStatsIncrementer(typeStats[key], value.lastRunStatus);

            typeStats[key].dataRead += value.numDataReadBytes || 0;
            typeStats[key].logicalProtected +=
              value.numLogicalBytesProtected || 0;
          });

          typeStats[key].totalObjects = groupData[key].length;
          typeStats[key].sourcesCount = Object.keys(sourcesMap).length;
        }
      });

      return typeStats;
    }

    /**
     * Increments lastRunStatus stats on a given object.
     *
     * @param  {object} statsObject a stats object with runStatus counters
     *                              that need to be incremented
     *
     * @param  {string} lastRunStatus the lastRunStatus value
     */
    function lastRunStatsIncrementer(statsObject, lastRunStatus) {
      switch(lastRunStatus) {
        case 'kError':
          statsObject.errorCount++;
          break;
        case 'kSuccess':
          statsObject.successCount++;
          break;
        case 'kRunning':
          statsObject.runningCount++;
      }
    }

    return ProtectionSummaryUtil;
  }
})(angular);
