import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';
import { FolderBrowserModule, SharedModule } from 'src/app/shared';
import { PipesModule } from 'src/app/shared/pipes';

import { SnapshotSelectorModule } from '../../snapshot-selector';
import { SearchObjectIconModule } from '../search-object-icon/search-object-icon.module';
import { FileBrowserModalComponent } from './file-browser-modal/file-browser-modal.component';
import { VolumeSelectionDetailComponent } from './volume-selection-detail/volume-selection-detail.component';

const components = [FileBrowserModalComponent, VolumeSelectionDetailComponent];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    CohesityHelixModule,
    FolderBrowserModule,
    MatBadgeModule,
    PipesModule,
    SearchObjectIconModule,
    SharedModule,
    SnapshotSelectorModule,
    TranslateModule,
  ],
})
export class VolumeBrowserModule {}
