import { Injectable } from '@angular/core';
import { StateObject, Transition } from '@uirouter/core';

import { McmViewService, StateManagementService } from '../../services';
import { GuardPriority, GuardResult, StateGuard } from '../state-guard';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';

/**
 * Redirect states for access management, based on feature flags and state names.
 * Although some tab contents are already in NG in the old state, they are under an AJS parent which
 * needs to be replaced with NG by using the new state below.
 */
const redirectMap = {
  'access-management': 'on-prem-access-management',
  'access-management.user-groups': 'on-prem-access-management.users',
  'access-management.support.list': 'on-prem-access-management.support',
  'access-management.roles': 'on-prem-access-management.roles',
  'access-management.active-directory': 'on-prem-access-management.active-directory',
  'access-management.secure-login': 'on-prem-access-management.mfa',
  'access-management.kerberos': 'on-prem-access-management.kerberos',
  'access-management.ldap': 'on-prem-access-management.ldap',
  'access-management.tenants': 'on-prem-access-management.organizations',
  'access-management.sso': 'on-prem-access-management.sso',
  'access-management.keystone': 'on-prem-access-management.keystone',
  'access-management.nis': 'on-prem-access-management.nis',
  'access-management.abac': 'on-prem-access-management.abac',
  'access-management.api-keys': 'on-prem-access-management.api-keys',
};

/**
 * Array of legacy states to match on for redirect.
 */
const matchStates = Object.keys(redirectMap);

/**
 * Access Management Guard redirects all state traffic to the ng restore states if
 * the feature flag ngUsersAndGroups is enabled.
 */
@Injectable({
  providedIn: 'root',
})
export class AccessManagementGuard implements StateGuard {
  /**
   * This guard priority is set to App, which indicates it should run as part of
   * the main application after all bootstrapping and initializing is completed.
   */
  guardPriority = GuardPriority.App;

  /**
   * Match all navigation to jobs (classic ui state).
   */
  matchCriteria = {
    to: (state: StateObject, transition: Transition) => matchStates.includes(transition.targetState().name()),
  };

  constructor(
    private irisCtx: IrisContextService,
    private stateManagementService: StateManagementService,
    private mcmViewService: McmViewService
  ) { }

  /**
   * Redirect to the correct access management page.
   *
   * @param   transition  The ui router transition
   * @returns a redirect transtion if the corresponding feature flag is enabled.
   */
  onStart(transition: Transition): GuardResult {
    if (this.mcmViewService.showFrame$.value) {
      // In case of iframe, maintain old state.
      return true;
    }
    const targetName = transition.targetState().name();
    const redirectState = redirectMap[targetName];

    if (flagEnabled(this.irisCtx.irisContext, 'ngUsersAndGroups') &&
      this.stateManagementService.canUserAccessState(redirectState)) {
      return transition.router.stateService.target(redirectState);
    }
  }
}
