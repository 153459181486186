/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { IntegrationList } from '../models/integration-list';
import { Integration } from '../models/integration';
import { IntegrationParams } from '../models/integration-params';
@Injectable({
  providedIn: 'root',
})
class SecurityIntegrationService extends __BaseService {
  static readonly ListSecurityIntegrationsPath = '/mcm/security-integration/integrations';
  static readonly AddSecurityIntegrationPath = '/mcm/security-integration/integrations';
  static readonly UpdateSecurityIntegrationPath = '/mcm/security-integration/integrations/{integrationId}';
  static readonly DeleteSecurityIntegrationPath = '/mcm/security-integration/integrations/{integrationId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List security integrations
   *
   * List all the security integrations for an account. This operation requires SECURITY_INTEGRATION_VIEW privilege.
   * @param params The `SecurityIntegrationService.ListSecurityIntegrationsParams` containing the following parameters:
   *
   * - `vendorName`: Name of the vendor.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `integrationType`: Type of security integration.<br> - 'DSPM': Data Security Posture Management.<br> - 'SIEM': Security Information and Event Management.<br> - 'SOAR': Security Orchestration, Automation, and Response.<br> - 'TI': Threat Intelligence.
   *
   * @return Response of a get request to list the integrations.
   */
  ListSecurityIntegrationsResponse(params: SecurityIntegrationService.ListSecurityIntegrationsParams): __Observable<__StrictHttpResponse<IntegrationList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.vendorName != null) __params = __params.set('vendorName', params.vendorName.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.integrationType != null) __params = __params.set('integrationType', params.integrationType.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/security-integration/integrations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IntegrationList>;
      })
    );
  }
  /**
   * List security integrations
   *
   * List all the security integrations for an account. This operation requires SECURITY_INTEGRATION_VIEW privilege.
   * @param params The `SecurityIntegrationService.ListSecurityIntegrationsParams` containing the following parameters:
   *
   * - `vendorName`: Name of the vendor.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `integrationType`: Type of security integration.<br> - 'DSPM': Data Security Posture Management.<br> - 'SIEM': Security Information and Event Management.<br> - 'SOAR': Security Orchestration, Automation, and Response.<br> - 'TI': Threat Intelligence.
   *
   * @return Response of a get request to list the integrations.
   */
  ListSecurityIntegrations(params: SecurityIntegrationService.ListSecurityIntegrationsParams): __Observable<IntegrationList> {
    return this.ListSecurityIntegrationsResponse(params).pipe(
      __map(_r => _r.body as IntegrationList)
    );
  }

  /**
   * Add security integration
   *
   * Add a new security integration for an account. This operation requires SECURITY_INTEGRATION_MODIFY privilege.
   * @param body Specifies the security integration details.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Response of a post request to add an integration.
   */
  AddSecurityIntegrationResponse(body: IntegrationParams,
    regionId?: string): __Observable<__StrictHttpResponse<Integration>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/security-integration/integrations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Integration>;
      })
    );
  }
  /**
   * Add security integration
   *
   * Add a new security integration for an account. This operation requires SECURITY_INTEGRATION_MODIFY privilege.
   * @param body Specifies the security integration details.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Response of a post request to add an integration.
   */
  AddSecurityIntegration(body: IntegrationParams,
    regionId?: string): __Observable<Integration> {
    return this.AddSecurityIntegrationResponse(body, regionId).pipe(
      __map(_r => _r.body as Integration)
    );
  }

  /**
   * Update security integration
   *
   * Update an existing security integration for an account. This operation requires SECURITY_INTEGRATION_MODIFY privilege.
   * @param params The `SecurityIntegrationService.UpdateSecurityIntegrationParams` containing the following parameters:
   *
   * - `integrationId`: Integration ID of the customer-vendor integration.
   *
   * - `body`: Specifies the security integration details.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Response of a put request to update an integration.
   */
  UpdateSecurityIntegrationResponse(params: SecurityIntegrationService.UpdateSecurityIntegrationParams): __Observable<__StrictHttpResponse<Integration>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/mcm/security-integration/integrations/${encodeURIComponent(params.integrationId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Integration>;
      })
    );
  }
  /**
   * Update security integration
   *
   * Update an existing security integration for an account. This operation requires SECURITY_INTEGRATION_MODIFY privilege.
   * @param params The `SecurityIntegrationService.UpdateSecurityIntegrationParams` containing the following parameters:
   *
   * - `integrationId`: Integration ID of the customer-vendor integration.
   *
   * - `body`: Specifies the security integration details.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Response of a put request to update an integration.
   */
  UpdateSecurityIntegration(params: SecurityIntegrationService.UpdateSecurityIntegrationParams): __Observable<Integration> {
    return this.UpdateSecurityIntegrationResponse(params).pipe(
      __map(_r => _r.body as Integration)
    );
  }

  /**
   * Delete security integration
   *
   * Delete an existing security integration for an account. This operation requires SECURITY_INTEGRATION_MODIFY privilege.
   * @param integrationId Integration ID of the customer-vendor integration.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  DeleteSecurityIntegrationResponse(integrationId: string,
    regionId?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/mcm/security-integration/integrations/${encodeURIComponent(integrationId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete security integration
   *
   * Delete an existing security integration for an account. This operation requires SECURITY_INTEGRATION_MODIFY privilege.
   * @param integrationId Integration ID of the customer-vendor integration.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   */
  DeleteSecurityIntegration(integrationId: string,
    regionId?: string): __Observable<null> {
    return this.DeleteSecurityIntegrationResponse(integrationId, regionId).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module SecurityIntegrationService {

  /**
   * Parameters for ListSecurityIntegrations
   */
  export interface ListSecurityIntegrationsParams {

    /**
     * Name of the vendor.
     */
    vendorName?: 'Normalyze' | 'Wiz' | 'CrowdStrike';

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Type of security integration.<br> - 'DSPM': Data Security Posture Management.<br> - 'SIEM': Security Information and Event Management.<br> - 'SOAR': Security Orchestration, Automation, and Response.<br> - 'TI': Threat Intelligence.
     */
    integrationType?: 'DSPM' | 'SIEM' | 'SOAR' | 'TI';
  }

  /**
   * Parameters for UpdateSecurityIntegration
   */
  export interface UpdateSecurityIntegrationParams {

    /**
     * Integration ID of the customer-vendor integration.
     */
    integrationId: string;

    /**
     * Specifies the security integration details.
     */
    body: IntegrationParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;
  }
}

export { SecurityIntegrationService }
