// MODULE:  Policy Detail Page

;(function(angular) {
  'use strict';

  angular.module('C.policies')
    .controller('policyController', policyControllerFn);

  function policyControllerFn($scope, $state, PolicyService, JobRunsService,
    DeletePolicyModalService, evalAJAX) {
    var $ctrl = this;

    /** @type   {Boolean} indicates if the policy has been loaded, for template
     * spinner display */
    $scope.policyReady = false;

    /** @type {Object} the policy to be displayed */
    $scope.policy = {};


    /** @type   {Boolean} indicates if the policy's jobs have been loaded from
     * the API */
    $scope.jobsReady = false;

    /** @type {Array} list of jobs that make use of this policy */
    $scope.policyJobs = [];

    $scope.filters = {
      tenantIds: [],
    };

    /**
     * presents modal responsible for handling confirmation and deletion of
     * policy. on successful delete, sends user to policy listing page
     *
     * @method   deletePolicy
     */
    $scope.deletePolicy = function deletePolicy() {
      DeletePolicyModalService.open($scope.policy).then(
        function deleteSuccess(deleted) {
          if (deleted) {
            // policy deleted, send user to policy listing
            $state.go('policies');
          }
        }
      );
    };

    /**
     * Load the protection jobs that use the Policy.
     *
     * @method   getPolicyJobs
     */
    function getPolicyJobs() {
      var params = {
        _includeTenantInfo: true,
        tenantIds: $scope.filters.tenantIds,
      };
      $scope.jobsReady = false;

      JobRunsService.getPolicyJobs($scope.policy.id, params).then(
        function getPolicyJobsSuccess(policyJobs) {
          $scope.policyJobs = policyJobs;
        },
        evalAJAX.errorMessage
      ).finally(
        function getPolicyJobsFinally() {
          $scope.jobsReady = true;
        }
      );
    }

    /**
     * Initialization/activation function.
     *
     * @method   $onInit
     */
    $ctrl.$onInit = function $onInit() {
      PolicyService.getPolicy($state.params.policyId).then(
        function getPolicySuccess(policy) {
          if (!policy) {
            // the policy no longer exists.
            // send user to the policy listing page
            $state.go('policies');
            return;
          }
          $scope.policy = policy;
          getPolicyJobs();
        },
        function getPolicyFail(resp) {
          evalAJAX.errorMessage(resp, { persist: true });

          // Redirect if policy doesn't exist.
          if (resp.data.errorCode === 'KEntityNotExistsError') {
            $state.go('policies');
          }
        }
      ).finally(
        function getPolicyFinally() {
          $scope.policyReady = true;
        }
      );
    };

    /**
     * Filters the policy jobs when the filter changes.
     *
     * @method   filterPolicyJobs
     */
    $scope.filterPolicyJobs = function filterPolicyJobs() {
      getPolicyJobs();
    };
  }

}(angular));
