import { Injectable } from '@angular/core';
import { BANNER_PRIORITY, BannerConfig } from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

import { flagEnabled, IrisContext, IrisContextService, isMcmSaaS } from '../../iris-context';
import { BaseBannerConfig } from './base-banner.config';

@Injectable({
  providedIn: 'root',
})
export class CohesityInfoBannerConfig extends BaseBannerConfig {
  /**
   * Unique banner representation
   */
  id = 'cohesity-info-banner';

  /**
   * provides banner config as observable
   */
  bannerInfo$ = this.irisCtx.irisContext$.pipe(
    map(ctx => ([{
      id: this.id,
      priority: BANNER_PRIORITY.HIGH,
      isVisible: this.allowBanner(ctx),
      status: 'info',
      allowClose: true,
      text: this.translateService.instant('banner.mycohesity.info'),
      tooltip: this.translateService.instant('banner.mycohesity.desc'),
      closeCallback: this.closeCallback,
    } as BannerConfig]))
  );

  constructor(private irisCtx: IrisContextService, private translateService: TranslateService) {
    super();
  }

  /**
   * Allow banner based on context
   *
   * @param ctx iris context
   * @returns true/false based on context
   */
  allowBanner(ctx: IrisContext) {
    return (
      this.isLoggedIn(ctx) &&
      !this.isBannerAcknowledged(this.id) &&
      flagEnabled(ctx, 'cohesityInfoBanner') &&
      isMcmSaaS(ctx)
    );
  }
}
