import { ClusterConfig } from '@cohesity/iris-core';

/**
 * Return the partial cluster config values for iris context selectedScope for data insights (gaia) scope.
 *
 * @returns The partial cluster config values.
 */
export const getClusterConfigPartial = (): Partial<ClusterConfig> => ({
  // If user is in this context, they are in "all clusters", or potentially
  // a specific cluster. Treating this as _allClusters for simpler scope
  // restoration and tracking.
  _allClusters: false,
  _cohesityService: true,
  _globalContext: false,
  _nonCluster: true,
  _serviceType: 'diaas',
});
