// Component: Select or Add Policy

;(function(angular, undefined) {
  'use strict';

  var componentName = 'selectPolicy';
  var configOptions = {
    controller: 'SelectPolicyCtrl',
    templateUrl: 'app/global/select-policy/select-policy.html',

    // Attribute bindings
    bindings: {

      /**
       * Optionally disable adding new policies within.
       *
       * Default: enabled.
       *
       * @type   {boolean}
       */
      addNew: '<?',

      /**
       * Optional label override. Set 'false' to hide the label.
       *
       * This property does not observe changes.
       *
       * Default: 'Policy'
       *
       * @type   {string}
       */
      label: '@?',

      /**
       * Specify a default policy by this ID if found in the list.
       *
       * @type   {number}
       */
      defaultId: '=?',

      /**
       * Optional emulated binding. if this attribute is present, an edit link
       * will be displayed when a policy is selected.
       *
       * @type {boolean}
       */
      // allowEdit: '<?',

      /**
       * Optional attribute sniffed value, if attribute is present the uiSelect
       * component will open automatically on initialization if the model is
       * empty.
       */
      // autoOpenIfEmpty: '?'
    },

    // Required outside things. These show up on `this.ngModel` in the
    // controller.
    require: {
      ngModel: '^ngModel',
    },
  };

  angular.module('C.selectPolicy', ['ui.select'])
    .controller('SelectPolicyCtrl', selectPolicyCtrlFn)
    .component(componentName, configOptions);

  /**
   * $ngdoc Component
   * @name C.selectPolicy:selectPolicy
   * @scope
   * @link
   *
   * @requires ngModel
   * @methodOf angular.Module|AUTO.
   * @function
   * @description
   *   Provides a dropdown component
   *
   * @example
       Example usage and possible output.
   */
  function selectPolicyCtrlFn($attrs, PolicyService, evalAJAX) {
    var $ctrl = this;
    var defaultBasePoliciesList = [];

    angular.extend($ctrl, {
      // binding $attrs for disabled state pass through
      $attrs: $attrs,
      policies: [],
      $onInit: $onInit,
      selectOrAddPolicy: selectOrAddPolicy,
      editPolicy: editPolicy,
    });


    /**
     * Initialize this component.
     *
     * @method     init
     */
    function $onInit() {
      angular.extend($ctrl, {
        label: $ctrl.label || 'policy',
        name: [componentName, $attrs.name, Date.now()].join('-'),
        id: [($attrs.id || componentName), 'ui-select'].join('-'),
        allowEdit: $attrs.hasOwnProperty('allowEdit'),
      });

      getPolicies();
    }

    /**
     * Set the model with the given policy, or if it's the addNew policy, pop
     * the Register Policy modal. When that's done, select the newly created
     * Policy.
     *
     * @method   selectOrAddPolicy
     * @param    {object}   policy   The selected Policy
     */
    function selectOrAddPolicy(policy) {
      var envType;

      if (policy && policy.id) {
        return setSelection(policy);
      }

      $ctrl.loading = true;

      PolicyService.modifyPolicyModal().then(
        function modalResolved(modalResp) {
          var newPolicy = modalResp.policy;
          $ctrl.policies.unshift(newPolicy);
          setSelection(newPolicy);
        },
        function modalCanceled() {
          setSelection(undefined);
        }
      )
      .finally(function modalCloseFinally() {
        $ctrl.loading = false;
      });
    }

    /**
     * Sets the selection in this component and in ngModel simultaneously.
     *
     * @method   setSelection
     * @param    {object}   [policy]   The Policy.
     * @return   {object}   The same Policy.
     */
    function setSelection(policy) {
      return $ctrl.ngModel.$setViewValue(
        $ctrl.selectedPolicy = policy
      );
    }

    /**
     * Gets the Policies
     *
     * @method   getPolicies
     * @return   {object}   Promise to resolve with the requested data.
     */
    function getPolicies() {
      $ctrl.loading = true;

      return PolicyService.getPolicies().then(
        function thenHandler(policies) {
          policies = policies.filter(function filterRPOPolicies(policy) {
            return policy.type !== 'kRPO';
          });
          var listBase = ($ctrl.addNew === undefined || $ctrl.addNew) ?
            defaultBasePoliciesList : [];
          $ctrl.policies = listBase.concat(policies);

          // if a defaultId was provided, find it in the list of policies and
          // assign it to the internal model
          if ($ctrl.defaultId) {
            $ctrl.policies.some(function findDefault(policy) {
              if (policy.id === $ctrl.defaultId) {
                $ctrl.selectOrAddPolicy(policy);
                return true;
              }
            });
          }
        },
        evalAJAX.errorMessage
      ).finally(function getPoliciesFinally() {
        $ctrl.loading = false;
      });
    }

    /**
     * Initiates Policy edit via modal and handles successful response.
     *
     * @method   editPolicy
     */
    function editPolicy() {
      PolicyService.modifyPolicyModal($ctrl.selectedPolicy.id).then(
        function policyEditSuccess(updateInfo) {
          var updatedPolicy = updateInfo.policy;
          var replaceIndex = $ctrl.policies.indexOf($ctrl.selectedPolicy);
          $ctrl.policies[replaceIndex] = updatedPolicy;
          setSelection(updatedPolicy);
        }
      );
    }

  }


})(angular);
