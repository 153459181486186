import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AttributeFilter, FiltersServiceApi } from '@cohesity/api/reporting';
import { FiltersComponent, ValueFilterSelection } from '@cohesity/helix';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseReportFilterComponent } from '../base-report-filter.component';

@Component({
  selector: 'iris-rpt-environment-filter',
  templateUrl: './environment-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnvironmentFilterComponent extends BaseReportFilterComponent {
  constructor(filters: FiltersComponent, private api: FiltersServiceApi) {
    super(filters, 'environment');
  }

  getFilterValues(): Observable<ValueFilterSelection[]> {
    // Use the list of registered sources to determine what environments we should show in the filter.
    // Otherwise, we might show environments in dms that are not supported yet.
    return this.api.GetResources({ resourceType: 'RegisteredSources' }).pipe(
      // Get the environment from each source
      map(response => {
        const result = [];

        (response?.sources || []).forEach(
          source => (source.environments || []).forEach(
            environment => result.push(environment)
          )
        );
        return result;
      }),

      // Convert to a set and back to an array to remove duplicates environments
      map(environments => [...new Set(environments)].sort()),

      // Convert to filter value
      map(environments => environments.map(environment => ({
        label: this.translate(`enum.environment.${environment}`),
        value: environment,
      })))
    );
  }

  toApiValue(filterValues: ValueFilterSelection[]): AttributeFilter {
    if (!filterValues?.length) {
      return null;
    }
    return {
      attribute: this.property,
      filterType: 'In',
      inFilterParams: {
        attributeDataType: 'String',
        stringFilterValues: filterValues.map(value => value.value as string),
        attributeLabels: filterValues.map(value => value.label),
      }
    };
  }

  fromApiValue(apiValue: AttributeFilter): ValueFilterSelection[] {
    return (apiValue?.inFilterParams.stringFilterValues || []).map((value, index) => ({
      label: apiValue.inFilterParams.attributeLabels[index],
      value: value
    }));
  }
}

@Component({
  selector: 'iris-rpt-environment-filter',
  templateUrl: './environment-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupEnvironmentFilterComponent extends EnvironmentFilterComponent {
  label = 'groupType';
}
