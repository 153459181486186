import {
  ProtectionSourceNode,
  VirtualDiskBasicInfo,
  VirtualDiskConfig,
  VirtualDiskInfo,
  VMwareCdpProtectionSourceInfo,
  VMwareProtectionSource,
} from '@cohesity/api/v1';
import { DataTreeSelection } from '@cohesity/helix';
import { ENUM_HOST_TYPE, Environment, SourceKeys } from 'src/app/shared/constants';

import { ConnectionState, HasConnectionState } from '../shared/behaviors/has-connection-state';
import { ProtectionSourceDataNode } from '../shared/protection-source-data-node';
import { HasMetadata, SourceNodeMetadata } from '../shared/protection-source-metadata/source-node-metadata';

/**
 * Extract tags from a vmware ProtectionSourceNode. This is outside the class since it is
 * called from the constructor before the class has been completely initialized.
 *
 * @param   data   A protection source node
 * @returns Any applied tags ids or an empty list.
 */
function findTags(data: ProtectionSourceNode): number[] {
  const sourceKey = SourceKeys[data.protectionSource.environment];
  const envSource = data.protectionSource[sourceKey];

  return (envSource.tagAttributes || []).map(tag => tag.id);
}

/**
 * Represents an active vm source node and job tree selection behavior.
 */
export class VmSourceDataNode extends ProtectionSourceDataNode
  implements HasConnectionState, HasMetadata {
  readonly asConnectionState = this as HasConnectionState;

  readonly metadata: SourceNodeMetadata;

  /**
   * Flag to disable special params settings at object level
   * when object is auto protected and is in Dms context.
   * TODO (Tung): remove when object level settings is properly fixed in auto-protect.
   */
  readonly disableDmsAutoProtectSpecialParams = false;

  constructor(
    public environment: Environment,
    data: ProtectionSourceNode,
    readonly level: number,
    exclusiveProtection: boolean = false
  ) {
    super(environment, data, level, findTags(data), exclusiveProtection);

    if (this.isTag) {
      this.metadata = {
        leafCount: this.children && this.children.length || 0
      };
    } else {
      this.metadata = {
        // Don't display logical size info for non leaf objects reporting 0 bytes.
        logicalSize: this.isLeaf
        ? this.logicalSize
        : (this.logicalSize > 0 ? this.logicalSize : undefined),
        leafCount: this.type !== 'kVirtualMachine' ? this.leafCount : undefined,
        hostType: this.type === 'kVirtualMachine' && this.envSourceVMware ?
          ENUM_HOST_TYPE[this.envSource.hostType] : undefined,
      };
    }
  }

  /**
   * Get the environment source object if the node environment is VMware.
   *
   * @return   Environment source of the node.
   */
  get envSourceVMware(): VMwareProtectionSource {
    // Do not return anything if not VMware environment
    return this.environment === Environment.kVMware && this.envSource;
  }

  /**
   * Whether the node is a leaf which can be directly selected or not.
   */
  get isLeaf() {
    return this.type === 'kVirtualMachine';
  }

  /**
   * For data-pool selection, a vm is allowed to be selected only in 1 data set.
   * Disable its selection in other data sets.
   *
   * @param    currentSelection Current Selection.
   * @return   True if this node can be selected.
   */
  canSelect = (currentSelection: DataTreeSelection<VmSourceDataNode>): boolean =>
    !currentSelection.options?.[this.data.protectionSource.vmWareProtectionSource?.id?.uuid]?.disable;

  /**
   * Get lists of virtual disks of a node if the node's source environment is
   * VMware/Acropolis/Hyperv.
   *
   * @return   Array of virtual disks.
   */
  get virtualDisks(): VirtualDiskInfo[] | VirtualDiskConfig[] | VirtualDiskBasicInfo[] {
    if (this.envSourceVMware) {
      // VMWare virtual disks
      return this.envSourceVMware.virtualDisks as VirtualDiskInfo[];
    } else if (this.environment === Environment.kAcropolis) {
      // Acropolis virtual disks
      return this.envSource.virtualDisks as VirtualDiskConfig[];
    } else if(this.environment === Environment.kHyperV) {
      // Hyperv virtual disks
      return this.envSource.vmInfo.virtualDisks as VirtualDiskBasicInfo[];
    }
  }

  get hasConnectionStateProblem(): boolean {
    // Only VMware supports showing erroneous connection states.
    return this.envSourceVMware && this.connectionState !== 'kConnected' &&
      ['kHostSystem', 'kVirtualMachine'].includes(this.type);
  }

  /**
   * Get folder type of node if the node's source environment is vmware.
   *
   * @return   String of the folder type
   */
  get folderType(): string {
    return this.envSourceVMware && this.envSourceVMware.folderType;
  }

  get connectionState(): ConnectionState {
    return this.envSourceVMware && this.envSourceVMware.connectionState;
  }

  /**
   * Returns the CDP information available to this node. CDP info is only available in VMware environment.
   *
   * @return The CDP info object, or `null` if it isn't available.
   */
  get cdpInfo(): VMwareCdpProtectionSourceInfo {
    if (!this.envSourceVMware) {
      return null;
    }

    return this.data.protectionSource.vmWareProtectionSource?.cdpInfo ?? null;
  }

  /**
   * Returns whether the CDP IO Filter upgrade is available (and applicable) to the current node.
   *
   * @returns `true` if the upgrade is available, `false` otherwise.
   */
  get cdpFilterUpgradable(): boolean {
    if (!this.cdpInfo) {
      return false;
    }

    const filterState = this.cdpInfo.ioFilterState;

    return filterState.upgradability === 'kUpgradable' &&
      (filterState.filterStatus === 'kFilterInstalled' || filterState.filterStatus === 'kUpgradeFilterFailed');
  }

  /**
   * Checks whether the node has SaaS Connector.
   */
  get isSaasConnector(): boolean{
    return !!(this.envSourceVMware as VMwareProtectionSource)?.isSaasConnector;
  }
}
