// Controller: Job Run Indexing Details

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.jobRunDetails')
    .controller('jobRunDetailsIndexingController', jobRunIndexingCtrlFn);

  function jobRunIndexingCtrlFn($scope, $q, evalAJAX, $state, PollTaskStatus,
    JobRunsService, SourceService, cMessage) {

    var $ctrl = this;
    var entityNamesHash = {};

    $scope.glanceBarStats = {
      canceled: 0,
      errors: 0,
      running: 0,
      success: 0,
    };
    $scope.expandedRows = {};

    // METHODS
    /**
     * Activate this ngController!
     *
     * @method   $onInit
     */
    $ctrl.$onInit = function $onInit() {
      // Get Job data and then get pulse
      // TODO: Consolidate getJobData into parent controller since it's
      // shared by all tabs.
      getJobData().then(getPulseData);
    };

    /**
     * Gets the Job data.
     *
     * @method   getJobData
     * @return   {object}   Promise to resolve with the results of the
     *                      getJobData Fn.
     */
    function getJobData() {

      var jobRunsParams = {
        id: +$state.params.id,
        exactMatchStartTimeUsecs: $state.params.startTimeUsecs || undefined,
      };

      return JobRunsService.getJobRuns(jobRunsParams).then(
        function getJobRunsSuccess(jobs) {

          if (!jobs.length) {
            // No run info was returned
            // show message and redirect user back to jobs landing
            cMessage.error({
              titleKey:
                'protectionJobsDetailsRunRunProtection.unknownJobRun.title',
              textKey:
                'protectionJobsDetailsRunRunProtection.unknownJobRun.text',
            });
            $state.go('jobs');
          }
          $scope.jobRun = jobs[0].backupJobRuns.protectionRuns[0];
          $scope.entityKey =
            SourceService.getEntityKey(
              jobs[0].backupJobRuns.jobDescription.parentSource.type
            );
          entityNamesHash =
            prepareEntityNamesHash($scope.jobRun.backupRun.latestFinishedTasks);
        });
    }

    /**
     * Gets the pulse data.
     *
     * @method   getPulseData
     * @return   {object}   Promise to resolve with the results of the
     *                      pollingIterator Fn.
     */
    function getPulseData() {
      return PollTaskStatus.createPoller({
        interval: 5,
        isDoneFn: isPollingDone,
        iteratorFn: pollingIterator,
        scope: $scope,
      });
    }

    /**
     * Gets the yoda progress monitor root path
     *
     * @method   getYodaProgressMonitorRootPath
     * @return   {string}   Yoda progress monitor root path string.
     */
    function getYodaProgressMonitorRootPath() {
      return $scope.jobRun.backupRun.yodaProgressMonitorRootPath;
    }

    /**
     * Determines if polling is done.
     *
     * @method   isPollingDone
     * @return   {boolean}   True if polling done, False otherwise.
     */
    function isPollingDone() {
      return ($scope.task && $scope.task.progress.endTimeSecs);
    }

    /**
     * Function executed at every iteration of the poller.
     *
     * @method   pollingIterator
     * @return   {object}   Promise to resolve with the poller response.
     */
    function pollingIterator() {
      // Using the yoda progress monitor root path, fetch their pulse data
      var pulseOptions = {
        taskPathVec: getYodaProgressMonitorRootPath(),
        includeFinishedTasks: true,
        excludeSubTasks: false,
      };

      return isPollingDone() ?

        // Resolve early with nothing if there are no active indexing subtasks.
        // This will prevent unnecessary polling.
        $q.resolve([]) :

        // Otherwise we will poll for the indexing subtasks
        PollTaskStatus
          .getProgress(pulseOptions)
            .then(function pulseReceived(resp) {
              if (resp.resultGroupVec[0].taskVec[0]) {
                $scope.task =  resp.resultGroupVec[0].taskVec[0];
                $scope.subTasks = resp.resultGroupVec[0].taskVec[0].subTaskVec;

                processSubTasks($scope.subTasks);
                resetGlanceStats($scope.subTasks);
              }
            },
            evalAJAX.errorMessage
          ).finally(
            function finallyHandler() {
              $scope.indexingDataReady = true;
            }
          );
    }

    /**
     * Function to reset the glance stats
     *
     * @method     resetGlanceStats
     * @param      {object} subTasks The subsTasks object
     */
    function resetGlanceStats(subTasks) {
      $scope.glanceBarStats = {
        canceled: 0,
        errors: 0,
        running: 0,
        success: 0,
      };

      subTasks.forEach(function incrementStats(subTask) {
        switch (subTask.progress.status.type) {
          case 0:
            $scope.glanceBarStats.running += 1;
            break;

          case 1:
            $scope.glanceBarStats.success += 1;
            break;

          case 2:
            $scope.glanceBarStats.errors += 1;
            break;

          case 4:
            $scope.glanceBarStats.canceled += 1;
            break;
        }
      });
    }

    /**
     * Prepares an entity hash with key as entity id and value as entity
     * displayname
     *
     * @method   prepareEntityNamesHash
     * @param    {Array}    tasks   The tasks
     * @return   {Object}   The entity hash
     */
    function prepareEntityNamesHash(tasks) {
      return tasks.reduce(function prepareHash(accumulator, task) {
        accumulator[task.base.sources[0].source.id] =
          task.base.sources[0].source.displayName;
        return accumulator;
      }, {});
    }
    /**
     * Function to set names for all subTasks
     *
     * @method     processSubTasks
     * @param      {object} subTasks The subsTasks object
     */
    function processSubTasks(subTasks) {
      subTasks.forEach(function setName(subTask, index) {
        subTask.entityId = subTask.taskPath.split('_')[1];
        subTask._displayName = entityNamesHash[subTask.entityId];
      });
    }

    /**
     * Toggles the taskId of expanded rows in $scope.expandedRows
     * @param {int}  taskId, taskId of row to be expanded/collapsed
     */
    $scope.toggleExpandedRow = function toggleExpandedRow(subTask) {
      $scope.expandedRows[subTask.entityId] =
        !$scope.expandedRows[subTask.entityId];
    };

  }

})(angular);
