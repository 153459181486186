/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiAlertServerConfiguration as __Configuration } from '../cohesity-api-alert-server-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SilenceRule } from '../models/silence-rule';

/**
 * Rules configured for silence certain alerts
 */
@Injectable({
  providedIn: 'root',
})
class AlertSilenceRulesService extends __BaseService {
  static readonly GetSilenceRulesPath = '/alerts/config/silenceRules';
  static readonly CreateSilenceRulePath = '/alerts/config/silenceRules';
  static readonly UpdateSilenceRulePath = '/alerts/config/silenceRules';
  static readonly DeleteSilenceRulePath = '/alerts/config/silenceRules/{ruleId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Gets all alert silence rules.
   *
   * Gets all alert silence rules containing criteria to stop sending the notification
   * to certain targets such as email addresses and webhook etc.
   * @param params The `AlertSilenceRulesService.GetSilenceRulesParams` containing the following parameters:
   *
   * - `accountId`: Current Account Id.
   *
   * - `tenantIds`: TenantId contains ids of the current tenant for which objects are to be returned.
   *
   * - `ruleName`: Specifies the unique rule name under the user account.
   *
   * - `ruleId`: Specifies the global unique rule id.
   *
   * - `clusterIds`: ClusterId contains ids of the current tenant for which objects are to be returned.
   *
   * - `alertTypeList`: Specifies list of Alert Type Ids to filter alerts.
   *
   * - `alertTypeBucketList`: Specifies list of Alert type bucket to filter alerts.
   *
   * - `alertSeverityList`: Specifies list of Alert severity to filter alerts.
   *
   * - `alertPropertyValueList`: Specifies list of the alert property value, multiple values for one key should be joined by '|'
   *
   * - `alertPropertyKeyList`: Specifies list of the alert property keys to be query.
   *
   * - `alertNameList`: Specifies list of Alert names to filter alerts.
   *
   * - `alertCategoryList`: Specifies list of Alert Categories.
   *
   * @return Success
   */
  GetSilenceRulesResponse(params: AlertSilenceRulesService.GetSilenceRulesParams): __Observable<__StrictHttpResponse<Array<SilenceRule>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.ruleName != null) __params = __params.set('ruleName', params.ruleName.toString());
    if (params.ruleId != null) __params = __params.set('ruleId', params.ruleId.toString());
    (params.clusterIds || []).forEach(val => {if (val != null) __params = __params.append('clusterIds', val.toString())});
    (params.alertTypeList || []).forEach(val => {if (val != null) __params = __params.append('alertTypeList', val.toString())});
    (params.alertTypeBucketList || []).forEach(val => {if (val != null) __params = __params.append('alertTypeBucketList', val.toString())});
    (params.alertSeverityList || []).forEach(val => {if (val != null) __params = __params.append('alertSeverityList', val.toString())});
    (params.alertPropertyValueList || []).forEach(val => {if (val != null) __params = __params.append('alertPropertyValueList', val.toString())});
    (params.alertPropertyKeyList || []).forEach(val => {if (val != null) __params = __params.append('alertPropertyKeyList', val.toString())});
    (params.alertNameList || []).forEach(val => {if (val != null) __params = __params.append('alertNameList', val.toString())});
    (params.alertCategoryList || []).forEach(val => {if (val != null) __params = __params.append('alertCategoryList', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/alerts/config/silenceRules`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SilenceRule>>;
      })
    );
  }
  /**
   * Gets all alert silence rules.
   *
   * Gets all alert silence rules containing criteria to stop sending the notification
   * to certain targets such as email addresses and webhook etc.
   * @param params The `AlertSilenceRulesService.GetSilenceRulesParams` containing the following parameters:
   *
   * - `accountId`: Current Account Id.
   *
   * - `tenantIds`: TenantId contains ids of the current tenant for which objects are to be returned.
   *
   * - `ruleName`: Specifies the unique rule name under the user account.
   *
   * - `ruleId`: Specifies the global unique rule id.
   *
   * - `clusterIds`: ClusterId contains ids of the current tenant for which objects are to be returned.
   *
   * - `alertTypeList`: Specifies list of Alert Type Ids to filter alerts.
   *
   * - `alertTypeBucketList`: Specifies list of Alert type bucket to filter alerts.
   *
   * - `alertSeverityList`: Specifies list of Alert severity to filter alerts.
   *
   * - `alertPropertyValueList`: Specifies list of the alert property value, multiple values for one key should be joined by '|'
   *
   * - `alertPropertyKeyList`: Specifies list of the alert property keys to be query.
   *
   * - `alertNameList`: Specifies list of Alert names to filter alerts.
   *
   * - `alertCategoryList`: Specifies list of Alert Categories.
   *
   * @return Success
   */
  GetSilenceRules(params: AlertSilenceRulesService.GetSilenceRulesParams): __Observable<Array<SilenceRule>> {
    return this.GetSilenceRulesResponse(params).pipe(
      __map(_r => _r.body as Array<SilenceRule>)
    );
  }

  /**
   * Creates a new silence rule.
   *
   * Creates a new silence rule with provided criteria to stop sending the notificaiont
   * to certain targets such as email ddresses and webhook, etc.
   * @param body Create Silence Rule argument.
   * @return Success
   */
  CreateSilenceRuleResponse(body?: SilenceRule): __Observable<__StrictHttpResponse<SilenceRule>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/alerts/config/silenceRules`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SilenceRule>;
      })
    );
  }
  /**
   * Creates a new silence rule.
   *
   * Creates a new silence rule with provided criteria to stop sending the notificaiont
   * to certain targets such as email ddresses and webhook, etc.
   * @param body Create Silence Rule argument.
   * @return Success
   */
  CreateSilenceRule(body?: SilenceRule): __Observable<SilenceRule> {
    return this.CreateSilenceRuleResponse(body).pipe(
      __map(_r => _r.body as SilenceRule)
    );
  }

  /**
   * Updates an existing silence rule.
   *
   * Update an existing silence rule, including the filter condition, delivery target, etc
   * @param body Update Silence Rule argument.
   * @return Success
   */
  UpdateSilenceRuleResponse(body?: SilenceRule): __Observable<__StrictHttpResponse<SilenceRule>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/alerts/config/silenceRules`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SilenceRule>;
      })
    );
  }
  /**
   * Updates an existing silence rule.
   *
   * Update an existing silence rule, including the filter condition, delivery target, etc
   * @param body Update Silence Rule argument.
   * @return Success
   */
  UpdateSilenceRule(body?: SilenceRule): __Observable<SilenceRule> {
    return this.UpdateSilenceRuleResponse(body).pipe(
      __map(_r => _r.body as SilenceRule)
    );
  }

  /**
   * Deletes an existing silence rule.
   *
   * Deletes an existing silence rule matching the rule name.
   * @param params The `AlertSilenceRulesService.DeleteSilenceRuleParams` containing the following parameters:
   *
   * - `ruleId`: Specifies the unique rule id.
   *
   * - `accountId`: Current Account Id.
   */
  DeleteSilenceRuleResponse(params: AlertSilenceRulesService.DeleteSilenceRuleParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/alerts/config/silenceRules/${encodeURIComponent(params.ruleId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes an existing silence rule.
   *
   * Deletes an existing silence rule matching the rule name.
   * @param params The `AlertSilenceRulesService.DeleteSilenceRuleParams` containing the following parameters:
   *
   * - `ruleId`: Specifies the unique rule id.
   *
   * - `accountId`: Current Account Id.
   */
  DeleteSilenceRule(params: AlertSilenceRulesService.DeleteSilenceRuleParams): __Observable<null> {
    return this.DeleteSilenceRuleResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module AlertSilenceRulesService {

  /**
   * Parameters for GetSilenceRules
   */
  export interface GetSilenceRulesParams {

    /**
     * Current Account Id.
     */
    accountId: string;

    /**
     * TenantId contains ids of the current tenant for which objects are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * Specifies the unique rule name under the user account.
     */
    ruleName?: string;

    /**
     * Specifies the global unique rule id.
     */
    ruleId?: string;

    /**
     * ClusterId contains ids of the current tenant for which objects are to be returned.
     */
    clusterIds?: Array<number>;

    /**
     * Specifies list of Alert Type Ids to filter alerts.
     */
    alertTypeList?: Array<number>;

    /**
     * Specifies list of Alert type bucket to filter alerts.
     */
    alertTypeBucketList?: Array<'DataService' | 'Maintenance' | 'Software' | 'Hardware'>;

    /**
     * Specifies list of Alert severity to filter alerts.
     */
    alertSeverityList?: Array<'Critical' | 'Warning' | 'Info'>;

    /**
     * Specifies list of the alert property value, multiple values for one key should be joined by '|'
     */
    alertPropertyValueList?: Array<string>;

    /**
     * Specifies list of the alert property keys to be query.
     */
    alertPropertyKeyList?: Array<string>;

    /**
     * Specifies list of Alert names to filter alerts.
     */
    alertNameList?: Array<string>;

    /**
     * Specifies list of Alert Categories.
     */
    alertCategoryList?: Array<string>;
  }

  /**
   * Parameters for DeleteSilenceRule
   */
  export interface DeleteSilenceRuleParams {

    /**
     * Specifies the unique rule id.
     */
    ruleId: string;

    /**
     * Current Account Id.
     */
    accountId: string;
  }
}

export { AlertSilenceRulesService }
