import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  isAppActiveFnMap,
  canAccessAppFnMap,
  ClusterConfig,
  IrisContextService,
  isAllClustersScope,
  isClusterScope,
  isMcm,
} from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';

import { AppServiceConfig, AuditServiceType } from './app-service.config';

export const APP_SERVICE_CONFIGS = new InjectionToken<AppServiceConfig[]>('appServices');
/**
 * App Service Manage provides information about available services (cluster contexts) that
 * can be activited in the application as well as utilities for looking up which service is
 * active at a given moment.
 */
// @Injectable({ providedIn: 'root' })
@Injectable()
export class AppServiceManagerService {
  /**
   * This is the list of all available application services
   */
  // appServices = [this.cmConfig, this.globalConfig, this.dmsConfig, this.draasConfig, this.rpaasConfig];

  constructor(
    @Inject(APP_SERVICE_CONFIGS) private appServiceConfigs: AppServiceConfig[],
    private irisContext: IrisContextService,
    private translate: TranslateService,
  ) {}

  /**
   * Look up which service, if any is currently active.
   *
   * @returns The active service, or none (if we are in a specific cluster or all clusters context).
   */
  getActiveService(): AppServiceConfig {
    return this.appServiceConfigs.find(service =>
      isAppActiveFnMap[service.appName](this.irisContext.irisContext)
    );
  }

  /**
   * Looks up a service by its cluster config.
   *
   * @param    cluster  The cluster config to look up
   * @returns  The service, if it is registered.
   */
  getServiceByClusterConfig(cluster: Partial<ClusterConfig>): AppServiceConfig {
    // Service titles are unique and sufficient for this lookup.
    return this.appServiceConfigs.find(service => service.serviceTitle === cluster.name);
  }

  /**
   * Looks up a service by its type. Service types are unique
   *
   * @param    serviceType  The service type to lookup.
   * @returns  The service, if it is registered.
   */
  getServiceByType(serviceType: string): AppServiceConfig {
    return this.appServiceConfigs.find(service => service.serviceType === serviceType);
  }

  /**
   * Gets a list of services that a user has access to.
   *
   * @returns A list of available services.
   */
  getAvailableServices(): AppServiceConfig[] {
    return this.appServiceConfigs.filter(service =>
      canAccessAppFnMap[service.appName](this.irisContext.irisContext)
    );
  }

  /**
   * Looks up the name for the active scope. cluster and allClusters do not have their own configs and are treated
   * separately here for now.
   *
   * @returns The name for the current scope
   */
  getScopeName(): string | 'allClusters' | 'cluster' {
    const activeService = this.getActiveService();
    switch (true) {
      case !!activeService:
        return activeService.serviceType;
      case isClusterScope(this.irisContext.irisContext):
        return 'cluster';
      case isAllClustersScope(this.irisContext.irisContext):
        return 'allClusters';
    }
  }

  /**
   * Fetches the active service and returns the audit tag for the service.
   *
   * @returns  The current active service audit tag.
   */
  getAuditTag(): null | AuditServiceType {
    if (!isMcm(this.irisContext.irisContext)) {
      return;
    }

    const activeService = this.getActiveService();
    if (activeService?.auditLogTag) {
      return activeService.auditLogTag;
    }

    return 'Mcm';
  }
}
