/**
 * Interface of a tracking event.
 */
export interface TrackingEvent {
  /**
   * The tracking event key.
   */
  key: string;

  /**
   * If the event should only be emitted if emitted from within a selector.
   */
  parentSelector?: string;

  /**
   * Whether to match this datq id on starts with over exact match.
   */
  startsWith?: true;

  /**
   * Additional properties attached to the event.
   */
  properties?: any;
}

/**
 * Type for the value of data id tracking event map.
 */
type DataIdTrackingEventMapValue = string | TrackingEvent;

/**
 * List of all tracking events which will be emitted from the UI.
 */
export const TrackingEventKeys: Record<string, string> = {
  'add_user_canceled': 'Add User Canceled',
  'add_user_submitted': 'Add User Submitted',
  'add_user_type_clicked': 'Add User Type Clicked',
  'anomalous_object_added_to_cart': 'Anomalous Object Added To Recovery Cart',
  'anomalous_object_removed_from_cart': 'Anomalous Object Removed From Recovery Cart',
  'anomalous_object_ignored': 'Anomalous Object Ignored',
  'anomalous_object_snapshots_tagged': 'Anomalous object snapshots tagged',
  'anomalous_object_snapshots_untagged': 'Anomalous object snapshots untagged',
  'anomalous_objects_instant_recovery_dialog_opened': 'Anomalous Objects Instant Recovery Dialog Opened',
  'anomalous_objects_instant_recovery': 'Anomalous Objects Instant Recovery Submitted',
  'anomalous_objects_push_notifications': 'Anomalous Objects Push Notifications Value Configured',
  'audit_logs_download_csv_btn': 'Download Audit logs',
  'bulk_upgrade_button_clicked': 'Bulk instant upgrade button clicked',
  'bulk_upgrade_scheduled': 'Bulk upgrade scheduled',
  'cancel_instant_upgrade_dialog': 'Instant upgrade dialog cancelled',
  'cluster_gflags_navigated': 'Navigated to view gflags recipe page for cluster',
  'cluster_location_update_submitted': 'Cluster location update submitted',
  'cluster_package_downloaded': 'Cluster package downloaded',
  'cluster_scheduled_upgrade_cancelled': 'Cluster scheduled upgrade cancelled',
  'cluster_update_location_cancelled': 'Cluster update location dialog cancelled',
  'cluster_upgrade_scheduled': 'Cluster upgrade scheduled',
  'configure_sso_dialog_canceled': 'Configure SSO Dialog Canceled',
  'configure_sso_dialog_opened': 'Configure SSO Dialog Opened',
  'configure_sso_dialog_submitted': 'Configure SSO Dialog Submitted',
  'create_policy_dialog_canceled': 'Create Policy Dialog Canceled',
  'create_policy_dialog_more_options_clicked': 'Create Policy Dialog More Options Clicked',
  'create_policy_dialog_opened': 'Create Policy Dialog Opened',
  'create_policy_dialog_submitted': 'Create Policy Dialog Submitted',
  'dark_mode_enabled': 'Dark Mode Enabled',
  'dms_token_copied': 'DMS Token Copied',
  'dms_token_downloaded': 'DMS Token Downloaded',
  'edit_recovery_point': 'Edit Recovery Point',
  'filter_applied': 'Filter Applied',
  'filter_cleared': 'Filter Cleared',
  'filter_opened': 'Filter Opened',
  'filter_value_toggled': 'Filter Value Toggled',
  'light_mode_enabled': 'Light Mode Enabled',
  'load_data_from_cache': 'Load Data From Local Cache',
  'manage_gflags_recipe_button_clicked': 'Manage gflags recipe button clicked',
  'messenger_toggled': 'Messenger Toggled',
  'new_protection_dialog_canceled': 'New Protection Dialog Canceled',
  'new_protection_dialog_more_options_clicked': 'New Protection Dialog More Options Clicked',
  'new_protection_dialog_opened': 'New Protection Dialog Opened',
  'new_protection_dialog_submitted': 'New Protection Dialog Submitted',
  'page_transition': 'Page Transition',
  'policy_builder_canceled': 'Policy Builder Canceled',
  'policy_builder_opened': 'Policy Builder Opened',
  'policy_builder_submitted': 'Policy Builder Submitted',
  'policy_option_added': 'Policy Option Added',
  'protection_builder_canceled': 'Protection Builder Canceled',
  'protection_builder_opened': 'Protection Builder Opened',
  'protection_builder_submitted': 'Protection Builder Submitted',
  'protection_group_type_clicked': 'Protection Group Type Clicked',
  'recover_to_type_clicked': 'Recover To Type Clicked',
  'recovery_options_clicked': 'Recovery Options Clicked',
  'recovery_page_canceled': 'Recovery Page Canceled',
  'recovery_page_submitted': 'Recovery Page Submitted',
  'recovery_point_location_click': 'Recovery Point Location Clicked',
  'recovery_point_value_canceled': 'Recovery Point Value Canceled',
  'recovery_point_value_selected': 'Recovery Point Value Selected',
  'release_selection_made': 'Release selection made on Downloads page',
  'reporting_download_report': 'Reporting v2 - Download Report',
  'reporting_download_artifact': 'Reporting v2 - Download Scheduled Report',
  'reporting_landing_page': 'Reporting v2 - Landing Page',
  'reporting_report_page': 'Reporting v2 - Report View',
  'reporting_scheduled_report_page': 'Reporting v2 - Scheduled Report View',
  'reporting_schedule_action': 'Reporting v2 - Schedule Action',
  'reporting_schedule_entry': 'Reporting v2 - Schedule Entry',
  'reporting_schedule_page': 'Reporting v2 - Schedule Page',
  'reporting_schedule_submit': 'Reporting v2 - Schedule Submit',
  'reporting_view_legacy_reports': 'Reporting v2 - View Classic',
  'search_filter_focused': 'Search Filter Focused',
  'settings_item_edit_toggled': 'Settings Item Edit Toggled',
  'settings_visibility_toggled': 'Settings Visibility Toggled',
  'setup_region_dialog_canceled': 'Setup Region Dialog Canceled',
  'setup_region_dialog_opened': 'Setup Region Dialog Opened',
  'setup_region_dialog_region_added': 'Setup Region Dialog Region Added',
  'setup_region_dialog_region_removed': 'Setup Region Dialog Region Removed',
  'setup_region_dialog_submitted': 'Setup Region Dialog Submitted',
  'simulation_builder_event_added': 'Simulation Builder Event Added',
  'simulation_builder_event_removed': 'Simulation Builder Event Removed',
  'simulation_builder_opened': 'Simulation Builder Opened',
  'simulation_builder_submitted': 'Simulation Builder Submitted',
  'simulation_builder_update_submitted': 'Simulation Builder Update Submitted',
  'simulation_delete_submitted': 'Simulation Delete Submitted',
  'source_connection_toggled': 'Source Connection Toggled',
  'source_registration_dialog_canceled': 'Source Registration Dialog Canceled',
  'source_registration_dialog_opened': 'Source Registration Dialog Opened',
  'source_registration_dialog_submitted': 'Source Registration Dialog Submitted',
  'source_tree_expand_to_clicked': 'Source Tree Expand To Clicked',
  'source_tree_filter_applied': 'Source Tree Filter Applied',
  'source_tree_refreshed': 'Source Tree Refreshed',
  'source_tree_view_filter_clicked': 'Source Tree View Filter Clicked',
  'submit_instant_upgrade_dialog': 'Instant cluster upgrade submitted',
  'user_logged_in': 'User Logged In',
  'user_logged_out': 'User Logged Out',
  'verify_connection_clicked': 'Verify Connection Clicked',
  'view_switched': 'View Switched',
};

/**
 * Map of data id values and their corresponding tracking events.
 */
export const DataIdTrackingEventMap: Record<string, DataIdTrackingEventMapValue | DataIdTrackingEventMapValue[]> = {
  'add-btn': {key: 'setup_region_dialog_region_added', parentSelector: 'coh-add-region'},
  'add-sso-btn': 'configure_sso_dialog_submitted',
  'add-to-recovery': {key: 'anomalous_object_added_to_cart', parentSelector: 'coh-anomaly-details'},
  'apply-filters-button': 'filter_applied',
  'anomaly-confirm-ignore': 'anomalous_object_ignored',
  'anomaly-strength-configuration-submit': 'anomalous_objects_push_notifications',
  'anomalous-objects-confirm-recovery': 'anomalous_objects_instant_recovery',
  'audit-logs-download-csv-btn': 'audit_logs_download_csv_btn',
  'build-simulation': 'simulation_builder_opened',
  'btn-legacy-reports': 'reporting_view_legacy_reports',
  'btn-report-schedule': 'reporting_schedule_entry',
  'cancel': {key: 'new_protection_dialog_canceled', parentSelector: 'coh-modify-quick-protect'},
  'cancel-btn': [
    {key: 'setup_region_dialog_canceled', parentSelector: 'coh-add-region'},
    {key: 'setup_region_dialog_canceled', parentSelector: 'coh-hypervisor-register'},
  ],
  'cancel-recovery': 'recovery_page_canceled',
  'cancel-run-selection': 'recovery_point_value_canceled',
  'cancel-sso-btn': 'configure_sso_dialog_canceled',
  'cancel-instant-upgrade': 'cancel_instant_upgrade_dialog',
  'cancel-schedule-upgrade': 'cluster_scheduled_upgrade_cancelled',
  'cancel-update-location': 'cluster_update_location_cancelled',
  'cancel-user-button': 'add_user_canceled',
  'clear-filters-button': 'filter_cleared',
  'cluster-gflags': 'cluster_gflags_navigated',
  'connection-setup-existing-button': {key: 'source_connection_toggled', properties: {value: 'existing'}},
  'connection-setup-new-button': {key: 'source_connection_toggled', properties: {value: 'new'}},
  'copy-content-btn': 'dms_token_copied',
  'download-cluster-package': 'cluster_package_downloaded',
  'download-file-btn': 'dms_token_downloaded',
  'edit-restore-point-': {key: 'edit_recovery_point', startsWith: true},
  'manage-gflags-recipes': 'manage_gflags_recipe_button_clicked',
  'more-options': {key: 'new_protection_dialog_more_options_clicked', parentSelector: 'coh-modify-quick-protect'},
  'policy-builder-cancel-button': 'policy_builder_canceled',
  'policy-builder-submit-button': 'policy_builder_submitted',
  'protect': {key: 'new_protection_dialog_submitted', parentSelector: 'coh-modify-quick-protect'},
  'protection-builder-cancel-button': 'protection_builder_canceled',
  'protection-builder-submit-button': 'protection_builder_submitted',
  'quick-filter-button': 'filter_opened',
  'quick-policy-cancel-button': 'create_policy_dialog_canceled',
  'quick-policy-create-button': 'create_policy_dialog_submitted',
  'quick-policy-more-options-button': 'create_policy_dialog_more_options_clicked',
  'radio-button-new-group': {key: 'protection_group_type_clicked', properties: {value: 'new'}},
  'radio-button-no-group': {key: 'protection_group_type_clicked', properties: {value: 'noGroup'}},
  'radio-group-existing-group': {key: 'protection_group_type_clicked', properties: {value: 'existing'}},
  'radio-group-sso': {key: 'add_user_type_clicked', properties: {value: 'sso'}},
  'radio-group-user': {key: 'add_user_type_clicked', properties: {value: 'local'}},
  'recover-to-new-cohesity-view': {key: 'recover_to_type_clicked', properties: {value: 'view'}},
  'recover-to-new-location-option': {key: 'recover_to_type_clicked', properties: {value: 'new'}},
  'recover-to-original-location-option': {key: 'recover_to_type_clicked', properties: {value: 'original'}},
  'release-package-select': {key :'release_selection_made', properties: {value: 'package'}},
  'release-series-select': {key :'release_selection_made', properties: {value: 'series'}},
  'release-type-select': {key :'release_selection_made', properties: {value: 'releaseType'}},
  'release-version-select': {key :'release_selection_made', properties: {value: 'version'}},
  'helios-release-deployment-type-select': {key :'release_selection_made', properties: {value: 'heliosDeploymentType'}},
  'helios-release-version-type-select': {key :'release_selection_made', properties: {value: 'heliosReleaseVersion'}},
  'helios-release-type-select': {key :'release_selection_made', properties: {value: 'heliosReleaseType'}},
  'helios-release-package-type-select': {key :'release_selection_made', properties: {value: 'heliosPackageType'}},
  'remove-btn': {key: 'setup_region_dialog_region_removed', parentSelector: 'coh-add-region'},
  'remove-from-recovery': {key: 'anomalous_object_removed_from_cart', parentSelector: 'coh-anomaly-details'},
  'save-btn': {key: 'setup_region_dialog_submitted', parentSelector: 'coh-add-region'},
  'save-form-section': {key: 'recovery_options_clicked', parentSelector: 'coh-recover-vm'},
  'save-sso-user-button': 'add_user_submitted',
  'save-user-button': 'add_user_submitted',
  'schedule-upgrade-selected': 'bulk_upgrade_scheduled',
  'search-property-filter-input': 'search_filter_focused',
  'select-target-local': {key: 'recovery_point_location_click', properties: {value: 'local'}},
  'settings-list-item-edit-toggle': 'settings_item_edit_toggled',
  'source-tree-refresh-button': 'source_tree_refreshed',
  'source-tree-search': 'search_filter_focused',
  'start-recovery': 'recovery_page_submitted',
  'submit-instant-upgrade': 'submit_instant_upgrade_dialog',
  'submit-run-selection': 'recovery_point_value_selected',
  'submit-schedule-upgrade': 'cluster_upgrade_scheduled',
  'submit-update-location': 'cluster_location_update_submitted',
  'theme-toggle': 'dark_mode_toggled',
  'toggle-visibility': 'settings_visibility_toggled',
  'upgrade-selected': 'bulk_upgrade_button_clicked',
  'value-property-list-item': 'filter_value_toggled',
  'verify-connection': 'verify_connection_clicked',
  'view-switcher-button-': {key: 'view_switched', startsWith: true},
};

/**
 * List of data ids which are tracked and will be matched on starts with
 * function.
 */
export const StartsWithDataIdsMap: Record<string, TrackingEvent[]> = {};

// Create a map of all events which need to be matched on startsWith.
for (const [key, value] of Object.entries(DataIdTrackingEventMap)) {
  const trackingEventValues = [].concat(value);

  for (const trackingEventValue of trackingEventValues) {
    if (typeof trackingEventValue === 'string') {
      continue;
    }

    if (trackingEventValue.startsWith) {
      StartsWithDataIdsMap[key] = StartsWithDataIdsMap[key] || [];
      StartsWithDataIdsMap[key].push(trackingEventValue);
    }
  }
}
