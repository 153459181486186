import { PipeTransform } from '@angular/core';
import { AjsUpgradeService } from 'src/app/core/services/ajs-upgrade.service';

/**
 * A base clase to simplify upgrade angular js filters to Angular pipes. Just
 * Specify the name of the filter in the constructor.
 */
export abstract class UpgradePipe implements PipeTransform {

  // The Angular JS Filter function
  private ajsFilter: () => any;

  /**
   * Inits the pipe
   *
   * @param   upgradeService   Provides simple access to the angular injector
   * @param   filterName       The name of the angular js filter
   */
  constructor(upgradeService: AjsUpgradeService, filterName: string) {
    this.ajsFilter = upgradeService.get('$filter')(filterName);
  }

  /**
   * Calls the original filter function, passing any arguments that were passed
   * to this method.
   */
  transform(...args): any {
    return this.ajsFilter.apply(null, args);
  }

}
