/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListCertResponse } from '../models/list-cert-response';
import { CertificateDetails } from '../models/certificate-details';
import { DeployCertParameters } from '../models/deploy-cert-parameters';
import { SslCertificateConfig } from '../models/ssl-certificate-config';
@Injectable({
  providedIn: 'root',
})
class CertificatesService extends __BaseService {
  static readonly GetCertificateListPath = '/public/certificates/global';
  static readonly DeployHostCertificatePath = '/public/certificates/global';
  static readonly GetHeliosWebServerCertificatePath = '/public/certificates/heliosWebServer';
  static readonly UpdateHeliosWebServerCertificatePath = '/public/certificates/heliosWebServer';
  static readonly DeleteHeliosWebServerCertificatePath = '/public/certificates/heliosWebServer';
  static readonly GetWebServerCertificatePath = '/public/certificates/webServer';
  static readonly UpdateWebServerCertificatePath = '/public/certificates/webServer';
  static readonly DeleteWebServerCertificatePath = '/public/certificates/webServer';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List the certificates generated and deployed on hosts.
   *
   * Returns the all certificate and their details generated from this cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return List Host Certificate Response.
   */
  GetCertificateListResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<ListCertResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/certificates/global`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListCertResponse>;
      })
    );
  }
  /**
   * List the certificates generated and deployed on hosts.
   *
   * Returns the all certificate and their details generated from this cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return List Host Certificate Response.
   */
  GetCertificateList(regionId?: string,
    accessClusterId?: number): __Observable<ListCertResponse> {
    return this.GetCertificateListResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as ListCertResponse)
    );
  }

  /**
   * Generate and deploy certificate for a single or multiple hosts.
   *
   * Returns the global certificate for a single or multiple hosts.
   * @param params The `CertificatesService.DeployHostCertificateParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to generate and deploy a new certificate.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Host Certificate Download Response.
   */
  DeployHostCertificateResponse(params: CertificatesService.DeployHostCertificateParams): __Observable<__StrictHttpResponse<CertificateDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/public/certificates/global`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CertificateDetails>;
      })
    );
  }
  /**
   * Generate and deploy certificate for a single or multiple hosts.
   *
   * Returns the global certificate for a single or multiple hosts.
   * @param params The `CertificatesService.DeployHostCertificateParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`: Request to generate and deploy a new certificate.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Host Certificate Download Response.
   */
  DeployHostCertificate(params: CertificatesService.DeployHostCertificateParams): __Observable<CertificateDetails> {
    return this.DeployHostCertificateResponse(params).pipe(
      __map(_r => _r.body as CertificateDetails)
    );
  }

  /**
   * Get the Web Server Certificate configured for the Helios.
   *
   * Returns the Server Certificate configured for the Helios.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetHeliosWebServerCertificateResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<SslCertificateConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/certificates/heliosWebServer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SslCertificateConfig>;
      })
    );
  }
  /**
   * Get the Web Server Certificate configured for the Helios.
   *
   * Returns the Server Certificate configured for the Helios.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetHeliosWebServerCertificate(regionId?: string,
    accessClusterId?: number): __Observable<SslCertificateConfig> {
    return this.GetHeliosWebServerCertificateResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as SslCertificateConfig)
    );
  }

  /**
   * Update the Web Server Certificate for the Helios Web Server.
   *
   * Returns the updated Web Server Certificate.
   * @param params The `CertificatesService.UpdateHeliosWebServerCertificateParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateHeliosWebServerCertificateResponse(params: CertificatesService.UpdateHeliosWebServerCertificateParams): __Observable<__StrictHttpResponse<SslCertificateConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/certificates/heliosWebServer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SslCertificateConfig>;
      })
    );
  }
  /**
   * Update the Web Server Certificate for the Helios Web Server.
   *
   * Returns the updated Web Server Certificate.
   * @param params The `CertificatesService.UpdateHeliosWebServerCertificateParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateHeliosWebServerCertificate(params: CertificatesService.UpdateHeliosWebServerCertificateParams): __Observable<SslCertificateConfig> {
    return this.UpdateHeliosWebServerCertificateResponse(params).pipe(
      __map(_r => _r.body as SslCertificateConfig)
    );
  }

  /**
   * Delete the Web Server SSL Certificate for Helios.
   *
   * Returns delete status upon completion.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteHeliosWebServerCertificateResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/certificates/heliosWebServer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete the Web Server SSL Certificate for Helios.
   *
   * Returns delete status upon completion.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteHeliosWebServerCertificate(regionId?: string,
    accessClusterId?: number): __Observable<null> {
    return this.DeleteHeliosWebServerCertificateResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get the Server Certificate configured on the Cluster.
   *
   * Returns the Server Certificate configured on the cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetWebServerCertificateResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<SslCertificateConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/certificates/webServer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SslCertificateConfig>;
      })
    );
  }
  /**
   * Get the Server Certificate configured on the Cluster.
   *
   * Returns the Server Certificate configured on the cluster.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetWebServerCertificate(regionId?: string,
    accessClusterId?: number): __Observable<SslCertificateConfig> {
    return this.GetWebServerCertificateResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as SslCertificateConfig)
    );
  }

  /**
   * Update the Web Server Certificate on the Cluster.
   *
   * Returns the updated Web Server Certificate on the cluster.
   * @param params The `CertificatesService.UpdateWebServerCertificateParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateWebServerCertificateResponse(params: CertificatesService.UpdateWebServerCertificateParams): __Observable<__StrictHttpResponse<SslCertificateConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/public/certificates/webServer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SslCertificateConfig>;
      })
    );
  }
  /**
   * Update the Web Server Certificate on the Cluster.
   *
   * Returns the updated Web Server Certificate on the cluster.
   * @param params The `CertificatesService.UpdateWebServerCertificateParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `body`:
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateWebServerCertificate(params: CertificatesService.UpdateWebServerCertificateParams): __Observable<SslCertificateConfig> {
    return this.UpdateWebServerCertificateResponse(params).pipe(
      __map(_r => _r.body as SslCertificateConfig)
    );
  }

  /**
   * Delete the SSL Certificate in the Cluster.
   *
   * Returns delete status upon completion.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteWebServerCertificateResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/public/certificates/webServer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete the SSL Certificate in the Cluster.
   *
   * Returns delete status upon completion.
   * @param regionId This field represents the region id.
   * @param accessClusterId This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   */
  DeleteWebServerCertificate(regionId?: string,
    accessClusterId?: number): __Observable<null> {
    return this.DeleteWebServerCertificateResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CertificatesService {

  /**
   * Parameters for DeployHostCertificate
   */
  export interface DeployHostCertificateParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Request to generate and deploy a new certificate.
     */
    body?: DeployCertParameters;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateHeliosWebServerCertificate
   */
  export interface UpdateHeliosWebServerCertificateParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;
    body?: SslCertificateConfig;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateWebServerCertificate
   */
  export interface UpdateWebServerCertificateParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;
    body?: SslCertificateConfig;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { CertificatesService }
