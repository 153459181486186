<div *ngIf="showBranding" class="nav-banner-branding">
  <cog-icon [shape]="logo" [size]="size"></cog-icon>
  <div>
    <div>{{ title }}</div>
    <ng-content select="[subtitle]"></ng-content>
  </div>
</div>

<!-- Arbitrary content/components can be added to the banner. -->
<ng-content></ng-content>
