import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const organizationsAccess = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.heliosMTEnabled && !ctx.isHeliosTenantUser;

const tenantOrganizationAccess = (ctx: StateAccessContext) => ctx.isHeliosTenantUser;

/**
 * Data Pools should only be visible in all cluster state.
 */
const singleClusterState = 'dashboards';

export const OrganizationsConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'organizations.**',
      url: '/organizations',
      loadChildren: () => import('./organizations.module').then(m => m.OrganizationsModule),
    },
  ],
  allClusterMap: {
    'organizations': {
      heliosOnly: true,
      allClustersState: 'organizations',
      singleClusterState
    },
    'organizations.status': {
      heliosOnly: true,
      allClustersState: 'organizations.status',
      singleClusterState
    },
    'organizations.list': {
      heliosOnly: true,
      allClustersState: 'organizations.list',
      singleClusterState
    },
    'organizations.create': {
      heliosOnly: true,
      allClustersState: 'organizations.create',
      singleClusterState
    },
    'organizations.view': {
      heliosOnly: true,
      allClustersState: 'organizations.view',
      singleClusterState
    },
    'organizations.connections': {
      heliosOnly: true,
      allClustersState: 'organizations.connections',
      singleClusterState
    },
    'organizations.self': {
      heliosOnly: true,
      allClustersState: 'organizations.self',
      singleClusterState
    },
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'organizations': organizationsAccess,
      'organizations.status': organizationsAccess,
      'organizations.list': organizationsAccess,
      'organizations.build': organizationsAccess,
      'organizations.view': organizationsAccess,
      'organizations.view.basics': organizationsAccess,
      'organizations.view.users': organizationsAccess,
      'organizations.connections': organizationsAccess,
      'organizations.self': tenantOrganizationAccess,
    };
  },
};
