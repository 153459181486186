import { Type } from '@angular/core';

import { BaseBannerConfig } from './base-banner.config';
import { CohesityInfoBannerConfig } from './cohesity-info-banner.config';
import { DataClassificationSubExpiryBannerConfig } from './deprecated/data-classification-sub-expiry-banner.config';
import { DmsSubscriptionBannerConfig } from './deprecated/dms-subscription-banner.config';
import { FreeTrialBannerConfig } from './deprecated/free-trial-banner.config';
import { ThreatProtectionSubExpiryBannerConfig } from './deprecated/threat-protection-sub-expiry-banner.config';
import { OfflineBannerConfig } from './offline-banner.config';

export * from './base-banner.config';
export * from './cohesity-info-banner.config';
export * from './crm-entitlement-banners';
export * from './deprecated/free-trial-banner.config';
export * from './deprecated/subs-expiry-banner.config';
export * from './floating-user-deprecate-banner.config';
export * from './offline-banner.config';
export * from './usage-banners';

export const defaultBannerConfigs: Type<BaseBannerConfig>[] = [
  CohesityInfoBannerConfig,
  DataClassificationSubExpiryBannerConfig,
  DmsSubscriptionBannerConfig,
  FreeTrialBannerConfig,
  OfflineBannerConfig,
  ThreatProtectionSubExpiryBannerConfig,
];
