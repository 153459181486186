// COMPONENT:  Storage Array Network (ie. Pure) details

;(function(angular, undefined) {
  'use strict';

  var options = {
    bindings: {
      volumes: '=',

      // @type {string} optional header to be displayed in the template
      header: '@?',

      // {
      //   // provide this option to expose checkboxes for selection
      //   canSelect: true
      //   // provide this option if a user can only select one node
      //   singleSelect: true
      //   // provide this option if singleSelect is enabled, it will
      //   // be populated with the ng-model of the radio buton
      //   singleSelectNode: undefined
      // }
      options: '=?',

      // if tracking/counting of selected objects is desired, this property
      // should be an entity type hashed map with the value representing the
      // number of selected objects of the type
      // {
      //   // 10 Volumes currently selected
      //   1: 10
      // }
      selectedObjectsCounts: '=?'
    },
    // contents are transcluded into the sticky-footer
    transclude: true,
    controller: componentControllerFn,
    templateUrl: 'app/global/c-san-details/c-san-details.html'
  };

  angular
    .module('C.sourceSANDetails')
    .component('cSanDetails', options);

  /**
   * @ngdoc component
   * @name C:cComponentName
   * @function
   *
   * @description
   * Description of this component
   *
   * @example
     <example module="C">
       <c-san-details
         ng-if="sourceTree.length"
         volumes="sourceTree"
         options="{ canSelect: true }"
         selected-objects-counts="selectedObjectsCounts">
         <!-- transcluded content goes into stickyFooter -->
         <button type="submit">Save</button>
       </c-san-details>
     </example>
   */
  function componentControllerFn($rootScope, $scope, $filter,
    ENUM_ENTITY_ICON_CLASS) {

    var ctrl = this;

    // all the filtered table results, not just the currently displayed page
    var filteredVolumes = [];

    angular.extend($scope, {
      ENUM_ENTITY_ICON_CLASS: ENUM_ENTITY_ICON_CLASS,
      // copy volumes so we don't lose original volume list when filtering is
      // applied
      volumes: angular.copy(ctrl.volumes),
    });

    ctrl.text = $rootScope.text.cSanDetails;

    $scope.sanVolumeFilters = [{
      // NOTE: If this dropdown adds additional filters against other
      // properties, this show all reset will break.
      name: ctrl.text.showAll,
      property: '_isProtected',
      value: null
    }, {
      name: ctrl.text.protectedByCohesity,
      icon: 'protected',
      property: '_isProtected',
      value: true
    }, {
      name: ctrl.text.unprotected,
      property: '_isProtected',
      value: false
    }];

    // init sanVolumes array (which will be smart tables filtered list of
    // volumes) so it will be scoped locally
    $scope.sanVolumes = [];

    /**
     * select a volume
     *
     * @method   selectVolume
     * @param    {Object}       volume   Volume to be selected.
     */
    function selectVolume(volume) {

      // only adjust object count map if this volume was previously unselected.
      if (!volume._isSelected && ctrl.selectedObjectsCounts) {
        ctrl.selectedObjectsCounts[volume._normalizedEntity.type]++;
      }

      volume._isSelected = true;
    }

    /**
     * unselect a volume
     *
     * @method   unselectVolume
     * @param    {Object}         volume   Volume to be unselected.
     */
    function unselectVolume(volume) {

      // only adjust object count map if this volume was previously selected.
      if (volume._isSelected && ctrl.selectedObjectsCounts) {
        ctrl.selectedObjectsCounts[volume._normalizedEntity.type]--;
      }

      volume._isSelected = false;
    }

    /**
     * click checkbox
     *
     * @method   clickCheckbox
     * @param    {Object}        volume    Clicked volume
     */
    ctrl.clickCheckbox = function clickCheckbox(volume) {
      if (volume._isSelected) {
        unselectVolume(volume);
      } else {
        selectVolume(volume);
      }
    };

    /**
     * handles select/unselect all on model change of ctrl.selectAll
     * checkbox. leveraging filteredVolumes so selections/deselections only
     * apply to filtered volumes, and all filtered volumes (not just
     * currently displayed page)
     *
     * @method   selectUnselectAll
     */
    ctrl.selectUnselectAll = function selectUnselectAll() {
      if (ctrl.selectAll) {
        filteredVolumes.forEach(selectVolume);
      } else {
        // unselect all
        filteredVolumes.forEach(unselectVolume);
      }
    };

    /**
     * filters, sorts, and splices the Volumes based on user input.
     *
     * @method     pipeTable
     * @param      {object}  tableState The smart table provided tableState,
     *                                  includes pagination, search and sort
     *                                  information
     * @param      {object}  tableCtrl  The smart table Controller
     */
    ctrl.pipeTable = function pipeTable(tableState, tableCtrl) {

      var pagination = tableState.pagination;

      // the starting index of the first volume to be displayed
      // in the table
      var startIndex = pagination.start || 0;

      // number of volumes to display per page
      var numPerPage = pagination.number || 10;

      // filter the volumes based on any user defined predicate
      filteredVolumes = tableState.search.predicateObject ?
        $filter('filter')(ctrl.volumes, tableState.search.predicateObject) :
        ctrl.volumes;

      // sort the filtered list if appropriate
      if (tableState.sort.predicate) {
        filteredVolumes = $filter('orderBy')(
          filteredVolumes,
          tableState.sort.predicate,
          tableState.sort.reverse
        );
      }

      // slice the filtered and sorted results into a single page
      // to be displayed to the user
      $scope.volumes = filteredVolumes.slice(startIndex, startIndex + numPerPage);

      // update total number of volumes
      pagination.totalItemCount = ctrl.volumes.length;

      // update the number of pages based on filtering
      tableState.pagination.numberOfPages =
        Math.ceil(filteredVolumes.length / numPerPage);

      // determine if all Volumes in filtered list are currently selected
      ctrl.selectAll = filteredVolumes.every(
        function checkSelection(volume) {
          return volume._isSelected;
        }
      );

    };

  }

})(angular);
