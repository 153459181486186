import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PolicyRunStatus, ScanHealthStatus } from '@cohesity/api/argus';
import { AppStatus, notStartedStatus } from '@cohesity/data-govern/shared';
import { WindowRef } from '@cohesity/helix';
import {
  AdaptorAccessService,
  dgProductPage,
  emptyLinkFn,
  emptyLinkParamsFn,
  hasSku,
  IrisContextService,
  SkuType,
} from '@cohesity/iris-core';
import { Environment } from '@cohesity/iris-shared-constants';

/** The scan run status usage type */
export type ScanRunStatusUsage = 'statlist' | 'banner' | 'button';

@Component({
  selector: 'dg-ar-scan-run-status',
  templateUrl: './scan-run-status.component.html',
  styleUrls: ['./scan-run-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScanRunStatusComponent {
  /** The scan run status usage type */
  @Input() usage: ScanRunStatusUsage = 'statlist';

  /** The Scan Status */
  @Input() scanStatus: PolicyRunStatus | ScanHealthStatus;

  /** The Scan Status Message */
  @Input() message: string;

  /** The Scan Environment */
  @Input() environment: Environment;

  /** required SKU */
  @Input() skuType: SkuType;

  /** The app installation status */
  @Input() appStatus: AppStatus;

  /**
   * The state to use for install app.
   */
  @Input() getInstallAppLinkFn = emptyLinkFn;

  /**
   * Return the install app link params.
   */
  @Input() getInstallAppLinkParamsFn = emptyLinkParamsFn;

  /**
   * Supported Environments
   */
  @Input() adapterAccessService: AdaptorAccessService;

  /** The app status for which anti ransomware dc/th might not be started. */
  readonly notStartedStatus = notStartedStatus;

  /** DC/TH supported list of environments */
  get scanSupportedEnvs(): Set<Environment> {
    return new Set<Environment>(this.adapterAccessService.getSupportedEnvironments());
  }

  /** Returns if the account has the sku or not */
  get hasSku(): boolean {
    return hasSku(this.irisContextService.irisContext, this.skuType);
  }

  constructor(private irisContextService: IrisContextService, private windowRef: WindowRef) {}

  /** Take user to datahawk product page */
  upgradePlan() {
    this.windowRef.openExternalLink(dgProductPage);
  }
}
