// Component: Generate License

(function(angular) {
  'use strict';

  angular
    .module('C.licenseServer')
    .controller('GenerateLicenseModalCtrl', GenerateLicenseModalCtrlFn)
    .component('generateLicenseModal', {
      templateUrl: 'app/license/generate-license-modal.html',
      controller: 'GenerateLicenseModalCtrl',
      require: {
        cModalHoc: '^^?cModalHoc',
      },
    });

  function GenerateLicenseModalCtrlFn(LicenseServerService, evalAJAX) {
    var $ctrl = this;

    _.assign($ctrl, {
      $onInit: $onInit,
      onSubmit: onSubmit,
      downloadLicenseKey: downloadLicenseKey
    });

    /**
     * initialize the component
     *
     * @method   $onInit
     */
    function $onInit() {
      LicenseServerService.checkClassifiedUser().then(
        /**
         * @method setClassifiedUser
         *
         * @param  response {object}
         * {
         *   isClassified: {boolean}
         * }
         */
        function setClassifiedUser(response) {
          $ctrl.isClassifiedUser = response.isClassified;
        }
      );
    }

    /**
     * request a license file or licence key generation based on the type of
     * user. A classified user will need a license key. Others need license
     * file.
     *
     * @method onSubmit
     */
    function onSubmit() {
      $ctrl.submitting = true;

      LicenseServerService
        .generateLicense($ctrl.clusterId, $ctrl.isClassifiedUser)
        .then(function onLicenseGeneration(response) {
          if ($ctrl.isClassifiedUser) {
            $ctrl.licenseKey = response.licenseKey;
          } else {
            $ctrl.license = response;
            $ctrl.license.downloadLicenseCb = function downloadLicenseCb() {
              LicenseServerService.downloadLicenseFile($ctrl.clusterId);
            };
          }
          $ctrl.clusterIdUploaded = true;
        }, evalAJAX.errorMessage)
        .finally(function afterLicenceKeyGeneration() {
          $ctrl.submitting = false;
        });
    }

    /**
     * function to download license key in text file
     *
     * @method downloadLicenseKey
     */
    function downloadLicenseKey() {
      LicenseServerService.downloadLicenseKeyFile($ctrl.clusterId,
        $ctrl.licenseKey);
    }
  }
})(angular);
