import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { RpaasTopologyGraphData } from '../../../models/';
import { RpaasTopologyNodeComponent } from '../rpaas-topology-node/rpaas-topology-node.component';

/**
 * Rpaas Summary Dashboard shows data over time, usage, status, and topology information
 *
 * @example
 * <dg-sc-rpaas-topology-card [graphData]="graphData"></dg-sc-rpaass-topology-card>
 */
@Component({
  selector: 'dg-sc-rpaas-topology-card',
  templateUrl: './rpaas-topology-card.component.html',
  styleUrls: ['./rpaas-topology-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class RpaasTopologyCardComponent {
  /**
   * All of the graph's edges and nodes
   */
  @Input() graphData: RpaasTopologyGraphData;

  /**
   * This is the component to use to render each node
   */
  nodeComponent = RpaasTopologyNodeComponent;
}
