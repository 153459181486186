import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { ProtectionSource } from '@cohesity/api/v1';
import { ProtectionGroup, ProtectionGroupServiceApi, View } from '@cohesity/api/v2';
import { DataTreeSelection } from '@cohesity/helix';
import { ItemPickerFormControl } from '@cohesity/shared-forms';
import { UIRouterGlobals } from '@uirouter/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { VmProtectionUtilsService } from 'src/app/modules/protection/vm/vm-protection-utils.service';
import {
  cloudGroups,
  CloudJobType,
  cohesityGroups,
  envGroups,
  EnvironmentType,
  JobEnvParamsV2,
  NoEmptyStringValidator,
} from 'src/app/shared';
import { Environment } from 'src/app/shared/constants';

/**
 * Protection Groups options enum specifying the types of Protection Groups
 * which can be used.
 */
export enum ProtectionGroupOptions {
  new,
  existing,
  object,
}

/**
 * Interface for Protection Group Form Control value.
 */
export interface ProtectionGroupValue {
  type: ProtectionGroupOptions;

  // Name will only exist if type is ProtectionGroupOptions.new
  name?: string;

  // existing will only exist if type is ProtectionGroupOptions.existing
  existingId?: ProtectionGroup;
}

/**
 * Interface for the SnapMirrorConfig that is received from SnapMirrorBackupComponent.
 */
export interface SnapMirrorConfig {
  /**
   * Specifies the incremental snapshot prefix value.
   */
  incrementalPrefix: null | string;
  /**
   * Specifies the Id of the S3 view where data need to be written.
   */
  view: View;
}

/**
 * Protection Group Selector Component allows user to decide which type of
 * protection group is being created or edited and also sets the group name of
 * the protection group. An existing group can also be selected for protection.
 */
@Component({
  selector: 'coh-protection-group-selector',
  templateUrl: './protection-group-selector.component.html',
  styleUrls: ['./protection-group-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProtectionGroupSelectorComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ProtectionGroupSelectorComponent),
      multi: true,
    },
  ],
})
export class ProtectionGroupSelectorComponent extends ItemPickerFormControl<ProtectionGroupValue>
  implements Validator, OnInit {
  /**
   * Emits whenever the registered source input changes.
   */
  registeredSource$ = new BehaviorSubject<ProtectionSource>(undefined);

  /**
   * Emits whenever the environment input changes.
   */
  environment$ = new BehaviorSubject<EnvironmentType>(undefined);

  /**
   * Emits whenever the cloud job type input changes.
   */
  cloudJobType$ = new BehaviorSubject<CloudJobType>(undefined);

  /**
   * Emits whenever the hideExistingGroupSelctor input changes.
   */
  hideExistingGroupSelector$ = new BehaviorSubject<boolean>(undefined);

  /**
   * Emits whenever the selectedObjects input changes.
   */
  selectedObjects$ = new BehaviorSubject<DataTreeSelection<any>>(undefined);

  /**
   * Emits whenever the snapMirrorConfig input changes.
   */
  snapMirrorConfig$ = new BehaviorSubject<SnapMirrorConfig>(undefined);

  /**
   * Parent Source which is being protected by the Protection Group
   */
  @Input() set registeredSource(registeredSource: ProtectionSource) {
    this.registeredSource$.next(registeredSource);
  }

  /**
   * Environment type of the protection job
   */
  @Input() protectionJobEnvironment: EnvironmentType;

  /**
   * Environment type of the Protection Source being protected
   */
  @Input() set environment(environment: EnvironmentType) {
    this.environment$.next(environment);
  }

  /**
   * Selected objects of the Protection Group being modified.
   */
  @Input() set selectedObjects(objects: DataTreeSelection<any>) {
    this.selectedObjects$.next(objects);
  }

  /**
   * Snapdiff toggle state for the current protection.
   */
  @Input() set snapMirrorConfig(snapMirrorConfig: SnapMirrorConfig) {
    this.snapMirrorConfig$.next(snapMirrorConfig);
  }

  /**
   * Determines if the Protection Group is being created or edited
   */
  @Input() editMode: boolean;

  /**
   * Default name of the protection group. This would be the pre-populated name
   * if in edit state.
   */
  @Input() defaultName: string;

  /**
   * Job type for a cloud adapter.
   */
  @Input() set cloudJobType(cloudJobType: CloudJobType) {
    this.cloudJobType$.next(cloudJobType);
  }

  /**
   * Direct Cloud Archive prop for a nas adapter.
   */
  @Input() set hideExistingGroupSelector(hideExistingGroupSelector: boolean) {
    this.hideExistingGroupSelector$.next(hideExistingGroupSelector);
  }

  /**
   * Optional input defining a custom filter for the list of existing groups.
   */
  @Input() filterBy?: Function;

  /**
   * Whether to not auto focus the name input by default.
   */
  @Input() disableAutoFocus = false;

  /**
   * Whether object protection should be enabled. This is shown as 'no group' in the selection.
   */
  @Input() enableObjectProtection = false;

  /**
   * Expose ProtectionGroupOptions enum for the view
   */
  protectionGroupOptions = ProtectionGroupOptions;

  /**
   * List of Protection Groups returned from the API. This is the list before it has been
   * filtered for source id and cloud job type.
   */
  protectionGroups$: Observable<ProtectionGroup[]>;

  /**
   * Protection Groups that can be applied to the current cloud job type and source.
   */
  filteredProtectionGroups$: Observable<ProtectionGroup[]>;

  /**
   * Form Group for the Protection Group values
   */
  formGroup = new UntypedFormGroup({
    type: new UntypedFormControl(ProtectionGroupOptions.new, [Validators.required]),
    name: new UntypedFormControl('', NoEmptyStringValidator),
    existingId: new UntypedFormControl(''),
  });

  /**
   * Constructs new Protection Group Selector Component
   *
   * @param   protectionUserServiceApi   Protection Jobs Service API
   * @param   vmProtectionUtilsService   VM Protection Utils Service
   */
  constructor(
    private protectionUserServiceApi: ProtectionGroupServiceApi,
    private uiRouterGlobals: UIRouterGlobals,
    private vmProtectionUtilsService: VmProtectionUtilsService
  ) {
    super();
  }

  /**
   * Component Init.
   */
  ngOnInit() {
    // Initialize the control to a default name, if set.
    if (this.defaultName) {
      this.formGroup.get('name').setValue(this.defaultName);
    }

    // Fetch a new set of groups whenever the environment changes.
    this.protectionGroups$ = this.environment$.pipe(
      filter(environment => !!environment),
      distinctUntilChanged(),
      switchMap(environment => this.getExistingGroups(environment))
    );

    // Update the group filter when the groups, source, or cloud job type changes
    this.filteredProtectionGroups$ = combineLatest([
      this.protectionGroups$,
      this.registeredSource$,
      this.cloudJobType$,
      this.hideExistingGroupSelector$,
      this.selectedObjects$,
      this.snapMirrorConfig$,
    ]).pipe(
      map(([groups, source, cloudJobType, hideExistingGroupSelector, selectedObjects, snapMirrorConfig]) =>
        this.filterExistingGroups(
          groups, source, cloudJobType, hideExistingGroupSelector, selectedObjects, snapMirrorConfig
        )),

      // By default the protection groups will be shown as oldest first, reverse it to show newest first.
      map(groups => groups.reverse()),
      tap(groups => {
        if (!groups.length) {
          // If there are no available groups, set the type to new.
          this.formGroup.get('type').setValue(ProtectionGroupOptions.new);
        }
      }),
      startWith([]),

      // This share replay ensures that each subscription in the template does not trigger a new
      // api call.
      shareReplay(1),
    );

    // Update the control value when the form changes.
    this.formGroup.valueChanges.pipe(this.untilDestroy()).subscribe(value => {
      this.value = value;
    });
  }

  /**
   * Custom validation function to validate Protection Group selector
   *
   * @param   control   Form Control of the Protection Group
   * @return   null if valid, otherwise anything else for invalid.
   */
  validate(control: AbstractControl): ValidationErrors | null {
    switch (control.value.type) {
      case ProtectionGroupOptions.new:
        return this.formGroup.get('name').errors;
      case ProtectionGroupOptions.existing:
        return control.value.existingId ? null : {error: true};
    }
  }

  /**
   * Fetches existing groups from the api
   *
   * @param   environment   The environment type to query for.
   * @returns A list of active protection groups matching the selected environment.
   */
  private getExistingGroups(_environment: EnvironmentType): Observable<ProtectionGroup[]> {
    return this.protectionUserServiceApi
      .GetProtectionGroups({
        environments: [this.protectionJobEnvironment] as any,
        isActive: true,
      })
      .pipe(map(groups => groups.protectionGroups));
  }

  /**
   * Filters a set of groups by matching source id and cloud job type.
   * Cohesity group environments always matchin since they share the same root source.
   *
   * @param     protectionGroups             The unfiltered list of groups, matching the current environment.
   * @param     source                       The selected protection source.
   * @param     cloudJobType                 The selected cloudJobType, if any.
   * @param     hideExistingGroupSelector    The flag to denote whether to hide the
   *                                        existing group selector radio button.
   * @param     selectedObjects              The objects selected in the source tree.
   * @param     snapMirrorConfig             The current configuration of SnapDiff subform.
   * @returns  The filtered list of groups.
   */
  private filterExistingGroups(
    protectionGroups: ProtectionGroup[],
    source: ProtectionSource,
    cloudJobType: CloudJobType,
    hideExistingGroupSelector: boolean,
    selectedObjects?: DataTreeSelection<any>,
    snapMirrorConfig?: SnapMirrorConfig,
  ): ProtectionGroup[] {
    if (hideExistingGroupSelector) {
      return [];
    }

    if (typeof this.filterBy === 'function') {
      if (envGroups.nas.includes(source.environment as Environment)) {
        return (protectionGroups || []).filter(group => this.filterBy(
          group, snapMirrorConfig?.view?.viewId, source.environment));
      }
      return (protectionGroups || []).filter(group => this.filterBy(group, selectedObjects));
    }

    return (protectionGroups || []).filter(group => {
      const adapterParams = group[JobEnvParamsV2[group.environment]];

      if (cloudGroups.cloud.includes(group.environment as any)) {
        if (group.environment === Environment.kAWS && !cloudJobType) {
          // RDS Job Type is set implicitly without using the jobType control.
          // So it doesn't trigger any jobType control change event.
          cloudJobType = CloudJobType.kRDSSnapshotManager;
        }

        // Cloud groups need to check the job type adapter params
        const jobTypeAdapterParams = this.vmProtectionUtilsService.getCloudParams(
          group[JobEnvParamsV2[group.environment]]
        );

        return adapterParams.protectionType === cloudJobType && jobTypeAdapterParams.sourceId === source.id;
      } else if (cohesityGroups.includes(group.environment as any)) {
        // Cohesity groups always match because there is only one source
        return true;
      } else if (group.environment === Environment.kO365) {
        return adapterParams.sourceId === source.id &&
          adapterParams.protectionTypes?.[0] === this.uiRouterGlobals.params.office365WorkloadType;
      }
      return adapterParams.sourceId === source.id;
    });
  }
}
