import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

/**
 * Parses the given HTTP params URI string and converts the same to a simple
 * object with keys and their array of values.
 *
 * @param     requestUri    Specifies the encoded HTTP params string
 * @returns   Object containing the param key and their array of values
 */
export function convertRequestParamsToObject(requestUri: string): Object {
  if (!requestUri) {
    return {};
  }

  const paramsObject = requestUri.split('&')
    .map(keyValPair => keyValPair.split('='))
    .reduce((accumulator, [key, val]) => {
      if (!accumulator[key]) {
        accumulator[key] = [val];
      } else {
        accumulator[key].push(val);
      }
      return accumulator;
    }, {});

  return paramsObject;
}

/**
 * Function to return a proxy for http client which fires an update to a
 * provided subject whenever a non GET API call is being made.
 *
 * @param httpClient The http client to proxy.
 * @param subject The subject to fire the update to.
 * @return The http client proxy.
 */
export function httpClientCacheProxy(httpClient: HttpClient, subject: Subject<any>|BehaviorSubject<any>) {
  return new Proxy(httpClient, {
    get: function(target, prop) {
      return function(...args) {
        // Reload if a non GET api call was made.
        const reload = args?.[0]?.method !== 'GET';

        return target[prop](...args)?.pipe(
          tap(() => {
            if (reload) {
              subject.next(null);
            }
          })
        );
      };
    }
  });
}

/**
 * Determines whether the given 'urlString' can form a valid URL.
 *
 * @param urlString Specifies the string to be validated
 * @returns True iff, the 'urlString' is a valid URL with HTTP/HTTPS based
 *          schemes.
 */
export function isValidHttpURL(urlString: string): boolean {
  let url;

  try {
    url = new URL(urlString);
  } catch (_) {
    return false;
  }

  /**
   * Return true for only those URLs which have schemes as HTTP & HTTPS as URL
   * has many schemes that are valid.
   * Refer https://www.iana.org/assignments/uri-schemes/uri-schemes.xhtml for
   * details on all valid schemes.
   */
  return url.protocol === 'http:' || url.protocol === 'https:';
}
