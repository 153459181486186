<div mat-dialog-title
  class="dialog-header">
  <cog-icon *ngIf="params.groupIcon"
    [shape]="params.groupIcon">
  </cog-icon>
  <div class="page-title">{{params.protectionGroupName}}</div>
</div>
<mat-dialog-content  *ngIf="loadingRuns$ | async">
  <cog-spinner></cog-spinner>
</mat-dialog-content>
<mat-dialog-content *ngIf="!(loadingRuns$ | async)">
  <div class="c-message-inline" *ngIf="isTapeSnapshot()">
    <strong>{{ 'vmSnapshotSelectModal.tapeSelected.title' | translate }}</strong>
    {{ 'vmSnapshotSelectModal.tapeSelected.text' | translate }}
  </div>

  <div>
    <coh-form-field-object-selector
      cogDataId="protection-group-run"
      [allowSearch]="false"
      [control]="runControl"
      [label]="'protectionRun' | translate"
      [values]="runDates$ | async"
      [optionTemplate]="dateTemplate"
      [triggerTemplate]="dateTemplate"
      [required]="true">
    </coh-form-field-object-selector>

    <span class="location-label">{{'locationColon' | translate}}</span>
    <span *ngIf="hasLocalSnapshot && !hasStorageArraySnapshot"
      class="margin-right-sm">
      <button mat-icon-button
        type="button"
        (click)="selectedTarget = service.getRunBackupInfo(selectedRun)"
        [color]="selectedTarget === service.getRunBackupInfo(selectedRun) && 'primary'"
        [matTooltip]="!cloudEditionEnabled ? ('local' | translate) : ('cloud' | translate)"
        cogDataId="select-target-local"
        [inheritDataId]="true">
        <cog-icon [shape]="'local' | backupTargetIcon"></cog-icon>
      </button>
    </span>
    <span *ngFor="let target of archivalTargets"
      [matTooltip]="snapshotSelectorUtilsService.getArchivalTargetTooltip(target, true)"
      class="margin-right-sm">
      <button mat-icon-button
        type="button"
        [trackingEvent]="{key: 'recovery_point_location_click', properties: {value: target.targetType}}"
        (click)="selectedTarget = target"
        [color]="selectedTarget === target && 'primary'"
        cogDataId="select-target-{{target.targetName}}"
        [disabled]="snapshotSelectorUtilsService.getArchivalTargetIsDisabled(target)"
        [inheritDataId]="true">
        <cog-icon [shape]="snapshotSelectorUtilsService.getArchivalTargetIcon(target)">
        </cog-icon>
      </button>
    </span>
    <span *ngIf="hasStorageArraySnapshot"
      class="margin-right-sm">
      <button mat-icon-button
        type="button"
        (click)="selectedTarget = service.getRunBackupInfo(selectedRun)"
        [color]="selectedTarget === service.getRunBackupInfo(selectedRun) && 'primary'"
        [matTooltip]="'storageArraySnapshot' | translate"
        cogDataId="select-target-storage-array-snapshot"
        [inheritDataId]="true">
        <cog-icon [shape]="'kStorageArraySnapshot' | backupTargetIcon"></cog-icon>
      </button>
    </span>
  </div>

  <cog-banner class="snapshot-warning" status="warn" *ngIf="!canUseForRecovery" cogDataId="recovery-blocker-error">
    {{ 'recoveryCannotUseProtectionRun' | translate }}
  </cog-banner>

  <cog-spinner *ngIf="loadingDetails$ | async"></cog-spinner>
  <cog-filters #filters class="margin-left">
    <cog-search-property-filter *cogFilterDef="
        let filter;
        key: 'name';
        label: 'name' | translate;
        quickFilter: true;
        filterType: 'searchProperty'"
      [filter]="filter"
      [placeholder]="'search' | translate">
    </cog-search-property-filter>
  </cog-filters>

  <cog-table name="group-run-objects-selector-table"
    *ngIf="!(loadingDetails$ | async) && !isSan"
    [filters]="filters.filterValues$ | async" [source]="runObjects">

    <table mat-table>
      <!-- TODO: Enable when backend is ready -->
      <!--
      <coh-table-column-extension [position]="1"
        [extensionPoint]="snapshotExtensionPoint"
        [getRowDataFn]="getExtensionRowData">
      </coh-table-column-extension>
      -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>{{'name' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <cog-icon class="margin-right"
            *ngIf="params.objectIcon"
            [shape]="params.objectIcon"></cog-icon>
          <span class="user-data-xl auto-truncate">{{row.name}}</span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="size">
        <mat-header-cell *matHeaderCellDef>{{'size' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{row.logicalSizeBytes | byteSize}}
        </mat-cell>
      </ng-container>
      <!-- Run type -->
      <ng-container matColumnDef="tags">
        <mat-header-cell *matHeaderCellDef>{{'tags' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let row; index as i">
          <coh-colored-tags [tags]="getSnapshotTags(row)" [cogDataId]="'row-' + i + '-taglist'"></coh-colored-tags>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>
    </table>
    <mat-paginator [length]="runObjects?.length"></mat-paginator>
  </cog-table>

  <cog-table [source]="childObjects"
    *ngIf="!(loadingDetails$ | async) && isSan"
    [filters]="filters.filterValues$ | async">
    <table mat-table>
      <ng-container matColumnDef="volumeName">
        <mat-header-cell *matHeaderCellDef>{{'volumeName' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let object">
          <cog-icon class="margin-right"
            *ngIf="params.objectIcon"
            [shape]="params.objectIcon"></cog-icon>
          <span class="user-data-xl auto-truncate">{{object.name}}</span>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="['volumeName']"></mat-header-row>
      <mat-row *matRowDef="let object; columns: ['volumeName']"></mat-row>
    </table>
    <mat-paginator [length]="childObjects?.length"></mat-paginator>
  </cog-table>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button
    cogCancelButton
    cogDataId='cancel-run-selection'
    mat-dialog-close>{{'cancel' | translate}}</button>
  <button mat-button
    cogSubmitButton
    cogDataId='submit-run-selection'
    [disabled]="!selectedRun || !canUseForRecovery"
    (click)="submit()"
    cdkFocusInitial>
    {{'selectRecoveryPoint' | translate}}
  </button>
</mat-dialog-actions>

<ng-template #dateTemplate
  let-value>
  {{getStartTime(value) | cohDate}}
  <ul class="c-ul-inline meta-data" *ngIf="isSan">
    <li> {{'backupType' | translate}} : {{getRunTypeUIKey(value) | translate}}</li>
  </ul>
</ng-template>
