import { trigger, state, style, transition, animate } from '@angular/animations';

/**
 * slide animation for the carousel items
 */
export const slideTransition = trigger('slideTransition', [
  state(
    '*',
    style({ transform: 'translateX(0)' })
  ),
  // prev >>>> next
  transition('void => next', [
    style({
      transform: 'translateX(100%)',
    }),
    animate('0.6s ease-in-out', style('*')),
  ]),
  transition('next => void', [
    animate(
      '0.6s ease-in-out',
      style({
        transform: 'translateX(-100%)',
      })
    ),
  ]),
  // next <<<< prev
  transition('void => prev', [
    style({
      transform: 'translateX(-100%)',
    }),
    animate('0.6s ease-in-out', style('*')),
  ]),
  transition('prev => void', [
    animate(
      '0.6s ease-in-out',
      style({
        transform: 'translateX(100%)',
      })
    ),
  ]),
]);
