import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { SecurityCriteriaResult, SecurityCriteriaResultList } from '@cohesity/api/secops';
import { FiltersComponent, ROW_EXPAND_COLLAPSE_ANIMATION } from '@cohesity/helix';
import { FilterConfig, FilterValuesList } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { SecurityCriteriaResultModel } from '../../models';
import { PostureAdvisorService } from '../../services';


/**
 * Parameters to help creating the data structure for cog-value-property-filter
 */
const filterSettings: FilterConfig[] = [
  {
    name: 'fixStatus',
    prefix: '',
    values: ['fixed', 'notFixed'],
  },
  {
    name: 'cluster',
    prefix: '',
    values: [],
  },
];

/**
 * List issues table component.
 */
@Component({
  selector: 'dg-pa-list-issues-table',
  templateUrl: './list-issues-table.component.html',
  styleUrls: ['./list-issues-table.component.scss'],
  animations: [ROW_EXPAND_COLLAPSE_ANIMATION],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListIssuesTableComponent {

  /**
   * Holds issue table column names for standard presentation.
   */
  readonly columns = ['icon', 'fixStatus', 'description', 'clusterName', 'suggestions'];

  /**
   * Issue Data to be displayed.
   */
  @Input() set issueData(issueData: SecurityCriteriaResult[]) {
    this._issuesData.next(
      issueData.map(issue => {
        // Replace fixed cohesity docs website URL with dynamic value from Routes
        issue.documentUrl = issue.documentUrl?.replace(
          this.postureAdvisorService.cohesityDocsUrl, this.postureAdvisorService.docsUrl);

        return {
          ...issue,
          isFixed: issue.scanResult === 'Passed' || issue.scanResult === 'Fixed',
          fixStatus: (issue.scanResult === 'Passed' || issue.scanResult === 'Fixed') ? 'fixed' : 'notFixed',
        };
      })
    );
    this.initFilterOptions(issueData);
  }

  /**
   * Getter for issues data.
   */
  get issueData(): SecurityCriteriaResult[] {
    return this._issuesData.value;
  }

  /**
   * Table filters.
   */
  @ViewChild(FiltersComponent) filtersComponent: FiltersComponent;

  /**
   * True if table has to be in loading state.
   */
  @Input() loading: boolean;

  /**
   * Filters data for cog-value-property-filter [filterValues]
   */
  filterValuesList: FilterValuesList = {};

  /**
   * Row that is expanded.
   */
  expandedRow: SecurityCriteriaResultList;

  /**
   * Internal variable to store issues data.
   */
  private _issuesData = new BehaviorSubject<SecurityCriteriaResultModel[]>([]);

  /**
   * Constructor
   */
  constructor(
    private translateService: TranslateService,
    readonly postureAdvisorService: PostureAdvisorService,
  ) {
    this.filterValuesList = new FilterValuesList(filterSettings, this.translateService);
  }

  /**
   * Initialize filter options with values from issueResults.
   *
   * @param issueResults SecurityCriteriaResult objects
   */
  initFilterOptions(issueResults: SecurityCriteriaResult[]) {
    // Populate clusters filter options.
    const distinctClusterNames = new Set<string>();
    issueResults.forEach(issueResult => distinctClusterNames.add(issueResult.clusterName));
    this.filterValuesList.cluster = [...distinctClusterNames].map(result => ({
      label: result,
      value: result,
    }));
  }
}
