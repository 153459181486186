<ng-container [formGroup]="form.formGroup">

  <!-- Lock until duration input -->
  <div class="create-view-form-field" *ngIf="form.formGroup.controls.dataLockExpiryUsecs.value">
    <coh-view-data-lock-expiry formControlName="dataLockExpiryUsecs">
    </coh-view-data-lock-expiry>
  </div>

  <!-- View Name/Template name -->
  <div class="create-view-form-field height-auto">
    <coh-create-view-name formControlName="name"
      [selectedProtocols]="form.formGroup.controls.protocolAccess.value">
    </coh-create-view-name>
  </div>

  <!-- View Category -->
  <div class="create-view-form-field">
    <coh-create-view-category formControlName="category">
    </coh-create-view-category>
  </div>

  <!-- Storage Domain Selector -->
  <div class="create-view-form-field height-auto">
    <!-- Default Storage Domain Selector -->
    <coh-storage-domain-selector
      *ngIf="!isRecommendedStorageDomain"
      cogDataId="view-storage-domain-id"
      [addNewEnable]="true"
      [hideSynopsis]="true"
      [readOnly]="form.formGroup.controls.storageDomainId.disabled"
      [defaultStorageDomainId]="form.formGroup.controls.storageDomainId.value"
      formControlName="storageDomainId"
      [autoSelectDefaultStorageDomain]="true"
      floatLabel="always">
    </coh-storage-domain-selector>

    <!-- Storage domain selector with recommended settings, shown for
    predefined templates -->
    <coh-recommended-storage-domain-selector
      *ngIf="isRecommendedStorageDomain"
      [templateId]="viewTemplateInfo.id"
      [template]="viewTemplateInfo"
      [defaultStorageDomainId]="form.formGroup.controls.storageDomainId.value"
      [addNewEnable]="true"
      cogDataId="view-storage-domain-id"
      formControlName="storageDomainId"
      floatLabel="always">
    </coh-recommended-storage-domain-selector>
  </div>

  <!-- Object key selector -->
  <div class="create-view-form-field height-auto"
    *ngIf="form.formGroup.controls.category.value === 'ObjectServices'">
    <coh-create-view-object-keys formControlName="objectServicesMappingConfig">
    </coh-create-view-object-keys>
  </div>

  <!-- Protocol selector -->
  <div class="create-view-form-field">
    <coh-create-view-protocol formControlName="protocolAccess"
      [category]="form.formGroup.controls.category.value">
    </coh-create-view-protocol>
  </div>

  <!-- Swift settings -->
  <div class="create-view-form-field height-auto" *ngIf="isSwiftProtocolSelected">
    <coh-view-swift-ownership formControlName="swiftConfig">
    </coh-view-swift-ownership>
  </div>

  <!-- Most secure settings -->
  <div *ngIf="showMostSecureSettingsToggle">
    <coh-most-secure-settings formControlName="mostSecureSettings"
    [selectedProtocols]="form.formGroup.controls.protocolAccess.value">
    </coh-most-secure-settings>
  </div>
</ng-container>
