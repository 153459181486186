import { Injectable, Inject } from '@angular/core';
import {
  ProtectionJob,
  ProtectionJobsServiceApi,
  ProtectionSourceNode,
  ProtectionSourcesServiceApi,
} from '@cohesity/api/v1';
import { SourceTreeServiceFactory, SOURCE_TREE_SERVICE_FACTORY } from '@cohesity/iris-source-tree';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PassthroughOptionsService } from 'src/app/core/services';
import { ENV_GROUPS, Environment, JobToSourceEnvironment, Office365ActionKeyProtectionTypeMap } from 'src/app/shared';

/**
 * @description
 *
 * Service for returning source tree information.
 */
@Injectable({
  providedIn: 'root',
})
export class ObjectsTreeService {
  constructor(
    private jobsApi: ProtectionJobsServiceApi,
    private passthroughOptionsService: PassthroughOptionsService,
    private sourceApi: ProtectionSourcesServiceApi,
    @Inject(SOURCE_TREE_SERVICE_FACTORY) private transformerFactory: SourceTreeServiceFactory,
  ) {}

  /**
   * Returns source tree nodes.
   *
   * @param parentSourceId Parent source ID.
   * @param environment Environment source tree belong to.
   */
  getSourceTree(parentSourceId: number, environment: Environment): Observable<ProtectionSourceNode[]> {
    const sourceEnv: Environment = JobToSourceEnvironment[environment] ?
      JobToSourceEnvironment[environment][0] : environment;
    let workloadType = null;

    const provider = this.transformerFactory.getSourceTreeDataProvider(sourceEnv);
    if (provider) {
      workloadType = Office365ActionKeyProtectionTypeMap[environment];
      return provider.fetchTree(parentSourceId, sourceEnv, workloadType);
    }
    if (ENV_GROUPS.applicationSources.includes(environment)) {
      return this.sourceApi
        .ListApplicationServers({
          protectionSourcesRootNodeId: parentSourceId,
          application: environment as any,
          protectionSourceId: parentSourceId,
          ...this.passthroughOptionsService.requestParams,
        })
        .pipe(map(response => [response[0].applicationServer]));
    }

    return this.sourceApi.ListProtectionSources({
      id: parentSourceId,
      allUnderHierarchy: false,
      environment,
      excludeTypes: ['kResourcePool'],
      includeEntityPermissionInfo: true,
      includeVMFolders: true,
      includeSystemVApps: true,
      ...this.passthroughOptionsService.requestParams,
    });
  }

  /**
   * Returns subset of source tree nodes including only the nodes
   * that are contained in the job
   *
   * @param job          Job whose 'source' will be used to create the tree
   * @param environment  Environment source tree belong to.
   * @returns
   */
  buildSourceTreeFromJob(job: ProtectionJob, environment: Environment): Observable<ProtectionSourceNode[]> {
    const sourceEnv: Environment = JobToSourceEnvironment[environment] ?
      JobToSourceEnvironment[environment][0] : environment;
    const provider = this.transformerFactory.getSourceTreeDataProvider(sourceEnv);
    if (provider) {
      return provider.fetchDerivedTreeFromJob(job, environment);
    }
    return of([]);
  }

  /**
   * Returns protection job.
   *
   * @param jobId Job id to return protection job data.
   * @param includeSourceNames Include sourcenames along with the ids
   */
  getJob(jobId: number, includeSourceNames = false): Observable<ProtectionJob> {
    return this.jobsApi
      .GetProtectionJobs({
        ids: [jobId],
        onlyReturnBasicSummary: true,
        includeSourceNames
      })
      .pipe(map(jobs => jobs[0]));
  }
}
