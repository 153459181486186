import { ComponentType } from '@angular/cdk/portal';
import { ExtensionPointConfig } from '../extension-point-config';

/**
 * Component extension type
 */
export const componentExtension = 'componentExtension';

/**
 * Configuration model for component extensions. These require a data property with the component type
 * that should be instantiated.
 */
export interface ComponentExtensionPointConfig<DataType = any> extends ExtensionPointConfig {
  /**
   * The type value must be 'componentExtension'
   */
  type: 'componentExtension';

  /**
   * The component to use for the outlet. The component must implement the ComponentExtensionImpl type which
   * defines a property for extension data input.
   */
  data: ComponentType<ComponentExtensionImpl<DataType>>;
}

/**
 * Interface for component extensions to implement.
 */
export interface ComponentExtensionImpl<DataType = any> {
  /**
   * The data passed to the extension.
   */
  extensionData: DataType;
}
