import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

/**
 * Upgrade component for the ajs clone detail page
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'ajs-clone-detail'
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class CloneDetailComponent extends UpgradeComponent {

  /**
   * If present, this is the default back state to navigate to if there is no history
   */
  @Input() backState: string;
  constructor(injector: Injector, elementRef: ElementRef) {
    super('cloneDetail', elementRef, injector);
  }
}
