<cog-stepper-header *ngIf="showStepper; else emailOnly" class="mfa-stepper"
  [matStepperRef]="stepper">
</cog-stepper-header>

<ng-template #emailOnly>
  <h3 class="mfa-email-only-title" translate>mfa.email.title</h3>
  <form [formGroup]="formGroup">
    <coh-mfa-alternate-method
      [userMfaInfo]="mfaService.userMfaAccountInfo$ | async"
      [formControlName]="formControlNames.emailToken"
      (next)="completeLogin()">
    </coh-mfa-alternate-method>
  </form>
</ng-template>

<mat-horizontal-stepper [class.visuallyhidden]="!showStepper" class="stepper-container" #stepper cogStepper>
  <form [formGroup]="formGroup">
    <mat-step *ngIf="mfaService.isTokenAuthNeeded"
      [label]="'authenticatorApp' | translate">
      <coh-mfa-auth-app [userMfaInfo]="mfaService.userMfaAccountInfo$ | async"
        (next)="stepperRef?.next()">
      </coh-mfa-auth-app>
    </mat-step>
    <mat-step *ngIf="mfaService.isTokenAuthNeeded"
      [label]="'verifySettings' | translate">
      <coh-mfa-verify-settings
        [formControlName]="formControlNames.securityToken"
        (previous)="stepperRef?.previous()"
        (next)="handleNext($event)">
      </coh-mfa-verify-settings>
    </mat-step>
    <mat-step *ngIf="mfaService.isEmailAuthNeeded"
      [label]="'alternativeVerificationMethod' | translate">
      <coh-mfa-alternate-method
        [userMfaInfo]="mfaService.userMfaAccountInfo$ | async"
        [formControlName]="formControlNames.emailToken"
        (previous)="stepperRef?.previous()"
        (next)="completeLogin()">
      </coh-mfa-alternate-method>
    </mat-step>
  </form>
</mat-horizontal-stepper>
