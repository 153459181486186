import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { WebComponent } from '@cohesity/helix';
import { ClusterConfig, IrisContextService } from '@cohesity/iris-core';
import { AutoDestroyable } from '@cohesity/utils';
import { tap } from 'rxjs/operators';

import { RestorePointSelection } from '../../model/restore-point-selection';
import { DialogData } from '../snapshot-selector-modal/snapshot-selector-modal.component';

@Component({
  selector: 'coh-snapshot-selector-plugin',
  templateUrl: './snapshot-selector-plugin.component.html',
  styleUrls: ['./snapshot-selector-plugin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnapshotSelectorPluginComponent extends AutoDestroyable implements OnDestroy, OnChanges, AfterViewInit {
  /** The web component name */
  @Input() webComponentName: string;

  /** The selected cluster scope */
  cluster: ClusterConfig;

  /** The Select Recovery Dialog input data */
  @Input() data: DialogData;

  /** The Restore point */
  @Input() restorePoint: RestorePointSelection;

  /** This event is emitted every time the snapshot selection is changed. */
  @Output() snapshotChanged = new EventEmitter<RestorePointSelection>();

  /** The custom element component instaince */
  @ViewChild(WebComponent) webComponent: WebComponent<SnapshotSelectorPluginComponent>;

  constructor(private irisContextService: IrisContextService) {
    super();

    // Getting selected cluster and pass that to rendered web component.
    this.irisContextService.irisContext$.pipe(
      this.untilDestroy(),
      tap(() => this.updateInput()),
    ).subscribe();
  }

  ngAfterViewInit() {
    this.updateInput();
    this.setupOutput();
  }

  ngOnChanges(_changes: SimpleChanges) {
    this.updateInput();
  }

  /**
   * Update the rendered web component inputs.
   */
  updateInput() {
    this.webComponent?.updateInput({
      cluster: this.irisContextService.irisContext?.selectedScope,
      data: this.data,
      restorePoint: this.restorePoint,
    });
  }

  setupOutput() {
    this.webComponent.setupOutput({
      snapshotChanged: this,
    });
  }
}
