/**
 * Data model for tenant object used in this column component.
 */
export class Tenant {
  /**
   * Tenant ID.
   */
  tenantId?: string;

  /**
   * Tenant Name.
   */
  name?: string;
}

/**
 * Data model for any class that contains tenant.
 */
export interface HasTenant {
  /**
   * List of tenants.
   */
  tenant: Tenant[];
}

/**
 * Used in the Tenanted mixin for any class to contain tenant.
 */
type Constructor<T = {}> = new (...args: any[]) => T;

/**
 * Mixin to provide accessor to organization name for sorting.
 * This could potentially be extended to provide other tenant functions.
 */
export function mixinTenant<TBase extends Constructor>(Base: TBase) {
  return class extends Base implements HasTenant {
    /**
     * List of tenants.
     */
    tenant: Tenant[];

    /**
     * Gets organization name base on tenant data.
     */
    get organizationName(): string {
      if (this.tenant && this.tenant[0]) {
        return this.tenant[0].name || '';
      }
      return '';
    }
  };
}
