<mat-form-field [class.loading]="loading" [floatLabel]="floatLabel">
  <mat-label>{{ labelKey | translate}}</mat-label>
  <cog-spinner *ngIf="loading"
    [inline]="true"
    matPrefix
    size="xs">
  </cog-spinner>
  <mat-select
    id="{{id}}select-storage-domain"
    [ngClass]="!hideSynopsis ? 'high-selector' : null"
    [formControl]="storageDomainControl"
    panelClass="cog-mat-select-panel-lg"
    cogDataId="{{id}}select-storage-domain"
    (valueChange)="value = $event"
    [value]="value"
    [disabled]="readOnly">
    <mat-select-trigger *ngIf="selectedView">
      {{selectedView.name}}
      <coh-storage-domain-synopsis *ngIf="!hideSynopsis" [storageDomain]="selectedView">
      </coh-storage-domain-synopsis>
    </mat-select-trigger>

    <!-- Show search filter if the parent component sets the allowSearch -->
    <mat-option *ngIf="allowSearch"
      [inheritDataId]="true"
      cogDataId="option-search">
      <ngx-mat-select-search
        [formControl]="searchCtrl"
        [noEntriesFoundLabel]="'noMatchingItems' | translate"
        [placeholderLabel]="'search' | translate">
      </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let domain of storageDomains$ | async; index as i"
      [cogDataId]="'option-' + domain.name | lowercase"
      [disabled]="isDisabled(domain)"
      [matTooltip]="isDisabled(domain) ? ('remoteClusters.storageDomain.remote.domainOptionDisabled' | translate) : '' "
      matTooltipPosition="below"
      inheritDataId="true"
      [value]="domain.id"
      (click)="selectStorageDomain(domain)"
      id="select-storage-domain-{{i}}">
      {{domain.name}}
      <coh-storage-domain-synopsis *ngIf="!hideSynopsis" [storageDomain]="domain">
      </coh-storage-domain-synopsis>
    </mat-option>
    <mat-option #add
      *ngIf="addNewEnable"
      cogDataId="add-view-box"
      class="sticky-option bottom"
      (click)="!add.disabled ? addStorage() : undefined"
      [disabled]="!('CLUSTER_MODIFY' | userPrivileges)">
      <coh-add-item-option>
        {{'addViewBox' | translate}}
      </coh-add-item-option>
    </mat-option>
  </mat-select>
  <mat-error> {{'errors.required' | translate}}</mat-error>
</mat-form-field>
