import { Injectable } from '@angular/core';
import { ProtectionSourceNode, ProtectionSourcesServiceApi } from '@cohesity/api/v1';
import { Environment } from '@cohesity/iris-shared-constants';
import { SourceTreeDataProvider } from '@cohesity/iris-source-tree';
import { Observable } from 'rxjs';
import { PassthroughOptionsService } from 'src/app/core/services';

@Injectable()
export class ProtectionSourceDataProvider implements SourceTreeDataProvider<ProtectionSourceNode> {
  constructor(
    readonly sourceService: ProtectionSourcesServiceApi,
    readonly passthroughOptionsService: PassthroughOptionsService
  ) {}

  /**
   * Load the entire source tree.
   *
   * @param sourceId            The parent id to load the tree for.
   * @param environment         The environment to loadd the tree for.
   * @param workloadType        The current workflow to use within the tree.
   * @param allUnderHierarchy   Whether to include all objects all nodes a user has access to
   * @returns An observable with the entire tree data
   */
  fetchTree(
    sourceId: number,
    environment: Environment,
    workloadType?: string,
    allUnderHierarchy?: boolean
  ): Observable<ProtectionSourceNode[]> {
    return this.sourceService.ListProtectionSources({
      id: sourceId,
      allUnderHierarchy: allUnderHierarchy || false,
      environment,
      excludeTypes: ['kResourcePool'],
      includeEntityPermissionInfo: true,
      includeVMFolders: true,
      includeSystemVApps: true,
      includeExternalMetadata: true,
      ...this.passthroughOptionsService.requestParams,
    });
  }
}
