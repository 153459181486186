// Component: (Bulk) Retrieval Options

;(function(angular) {
  'use strict';

  angular
    .module('C.retrievalOptions', [])
    .controller('RetrievalOptionsCtrl', RetrievalOptionsCtrlFn)
    .component('retrievalOptions', {
      bindings:{
        /**
         * the value of selected retrieval option
         *
         * @type  {Object}  [retrievalOption=undefined]
         */
        retrievalOption: '=',

        /**
         * The id of archive target from which retrieval is being done
         *
         * @type  {Number}  [archivalTargetId=undefined]
         */
        archivalTargetId: '<',

        /**
         * The number of columns to span while display
         *
         * @type  {Number}  [colSpan=3]
         */
        colSpan: '<',

        /**
         * should the label for dropdown be hidden
         *
         * @type  {Boolean}  [hideLabel=false]
         */
        hideLabel: '<',
      },
      templateUrl:
        'app/protection/common/retrieval-options/retrieval-options.html',
      controller: 'RetrievalOptionsCtrl',
    });

  /**
   * @ngdoc component
   * @name RetrievalOptions
   *
   * @description
   * This component provides a means of providing bulk or standard retrieval
   * options from AWS Glacier vaults.
   *
     @example
      <retrieval-options
        retrieval-option="model"
        archival-target-id="id">
      </retrieval-options>
   */

  function RetrievalOptionsCtrlFn(ExternalTargetService) {
    var $ctrl = this;

    /**
     * Initializes the default parameters for recovery.
     *
     * @method   initializeDefaultValues
     */
    $ctrl.$onInit = function onInit() {
      _.assign($ctrl, {
        retrievalOptions: [{
          name: 'standardDefault',
          value: 'kStandard',
        }, {
          name: 'bulk',
          value: 'kBulk',
        }],
        toShow: false,
        colSpan: $ctrl.colSpan || 3,
      });

      $ctrl.retrievalOption =
          $ctrl.retrievalOption || $ctrl.retrievalOptions[0].value;
    };

    $ctrl.$onChanges = function onChanges() {
      ExternalTargetService
        .isBulkRetrievalSupported($ctrl.archivalTargetId)
        .then(function supportCallSuccess(isSupported) {
          $ctrl.toShow = isSupported;
        });
    }
  }
})(angular);