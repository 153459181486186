<cog-settings-list-item [invalid]="formGroup.invalid"
  cogFormGroup
  [label]="_controlLabel">
  <cog-settings-list-item-value-display>
    <span cogDataId="input-resource-tag-summary">{{ readOnly ? readOnlyValues : tagLabel }}</span>
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor>
    <ng-container [formGroup]="formGroup">
      <ng-container formArrayName="tagList">
        <div class="flex-row vertical-align"
          *ngFor="let tag of tagListForm.controls; index as i">
          <ng-container [formGroupName]="i">
            <div class="col-xs-5">
              <mat-form-field class="no-margin-bottom w-100">
                <input matInput
                  type="text"
                  cogDataId="input-resource-tag-key-{{i}}"
                  formControlName="key"
                  placeholder="{{'tagKey' | translate}}">
                <mat-error *ngIf="tag?.get('key')?.hasError('tagName')">
                  {{ 'cloudEdition.duplicateKeyNotAllowed' | translate }}
                </mat-error>
                <mat-error *ngIf="tag?.get('key')?.hasError('internalTagName')">
                  {{ 'errors.invalid' | translate }}
                </mat-error>
                <mat-error *ngIf="tag?.get('key')?.hasError('required')">
                  {{ 'errors.required' | translate }}
                </mat-error>
                <mat-error *ngIf="tag?.get('key')?.hasError('maxlength')">
                  {{ 'policy.cloudSpin.errorKeyMaxLength' | translate }}
                </mat-error>
              </mat-form-field>

            </div>
            <div class="col-xs-5">
              <mat-form-field class="no-margin-bottom w-100">
                <input matInput
                  type="text"
                  cogDataId="input-resource-tag-value-{{i}}"
                  formControlName="value"
                  placeholder="{{'tagValue' | translate}}">
                <mat-error *ngIf="tag?.get('value')?.hasError('required')">
                  {{ 'errors.required' | translate }}
                </mat-error>
                <mat-error *ngIf="tag?.get('value')?.hasError('maxlength')">
                  {{ 'policy.cloudSpin.errorValueMaxLength' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-xs-2" *ngIf="!readOnly">
              <coh-form-array-action-control
                [index]="i"
                [addActionDisabled]="(!tag.value.key || !tag.value.value) || tag.invalid || ((i+1) === maximumTags)"
                [formArrayLength]="tagListForm.length"
                (controlAdded)="addTagControl()"
                (controlRemoved)="removeTagControl(i)">
              </coh-form-array-action-control>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
