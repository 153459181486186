import { CommonModule } from '@angular/common';
import { inject, NgModule } from '@angular/core';
import { MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';

import { AngularMaterialModule } from './angular-material.module';
import {
  ActionsMenuModule,
  ActivityItemModule,
  ActivityTimelineModule,
  AdvancedSearchModule,
  AppFrameModule,
  AppPanelModule,
  AppSwitcherModule,
  AutoTableModule,
  BannerModule,
  BlankCardModule,
  ByteSizeModule,
  CalendarModule,
  CardModule,
  CarouselModule,
  ChartsModule,
  ChipListInputModule,
  DarkModeToggleModule,
  DashcardModule,
  DataFiltersModule,
  DataIdModule,
  DataRenderersModule,
  DataTreeModule,
  DateAdapterUtils,
  DatePipesModule,
  DateRangeInputModule,
  DateRangePickerModule,
  DurationProgressModule,
  ExpansionCardModule,
  FormsModule,
  GenericFormFieldModule,
  HelixAssetsModule,
  IconModule,
  InfoBadgeModule,
  ItemListModule,
  LegendIndicatorModule,
  LoadingMaskModule,
  MenuModule,
  OnboardingWizardModule,
  PageModule,
  PopoverModule,
  ReflowModule,
  RowIdModule,
  SettingsListModule,
  SlicerModule,
  SliderModule,
  SnackBarModule,
  SpinnerModule,
  StatlistModule,
  StatusIndicatorModule,
  StepperHeaderModule,
  TableModule,
  TagModule,
  TextDiffComponent,
  ThemeService,
  TimeframePickerModule,
  TimePickerModule,
  TooltipChangeDetectionModule,
  TruncateModule,
  ViewSwitcherModule,
  WebComponentModule,
} from './components';

@NgModule({
  imports: [
    AngularMaterialModule,
    AppFrameModule,
    CommonModule,
    HelixAssetsModule,
    TextDiffComponent,
  ],
  exports: [
    ActionsMenuModule,
    ActivityItemModule,
    ActivityTimelineModule,
    AdvancedSearchModule,
    AngularMaterialModule,
    AppFrameModule,
    AppPanelModule,
    AppSwitcherModule,
    AutoTableModule,
    BannerModule,
    BlankCardModule,
    ByteSizeModule,
    CalendarModule,
    CardModule,
    CarouselModule,
    ChartsModule,
    ChipListInputModule,
    DarkModeToggleModule,
    DashcardModule,
    DataFiltersModule,
    DataIdModule,
    DataRenderersModule,
    DataTreeModule,
    DatePipesModule,
    DateRangeInputModule,
    DateRangePickerModule,
    DurationProgressModule,
    ExpansionCardModule,
    FormsModule,
    GenericFormFieldModule,
    HelixAssetsModule,
    IconModule,
    InfoBadgeModule,
    ItemListModule,
    LegendIndicatorModule,
    LoadingMaskModule,
    MenuModule,
    OnboardingWizardModule,
    PageModule,
    PopoverModule,
    ReflowModule,
    RowIdModule,
    SettingsListModule,
    SlicerModule,
    SliderModule,
    SnackBarModule,
    SpinnerModule,
    StatlistModule,
    StatusIndicatorModule,
    StepperHeaderModule,
    TableModule,
    TagModule,
    TextDiffComponent,
    TimeframePickerModule,
    TimePickerModule,
    TooltipChangeDetectionModule,
    TruncateModule,
    ViewSwitcherModule,
    WebComponentModule,
  ],
  providers: [
    DateAdapterUtils,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { hideRequiredMarker: true } },
  ],
})
export class CohesityHelixModule {
  // Need to create an instance of the service here to run the constructor, which sets the default theme
  // configuration.
  private themeService = inject(ThemeService);
}
