import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Api } from '../api.constants';
import { PassthroughOptions } from '../private-models';

/**
 * Private Params for Registering an application source
 */
export interface ApplicationSourceRegistrationParams {
  ownerEntity: {
    id: number;
  };
  appEnvVec: number[];
}


@Injectable({
  providedIn: 'root'
})
export class ApplicationSourceRegistrationServiceApi {
  constructor(private http: HttpClient) { }
  /**
   * Reusable method to register/modify an App owner. For the same
   * endpoint and configurable HTTP method.
   *
   * @param      data    Params for registring the app owner.
   * @param   headersParams Header parameters
   * @return     A boolean observable of the result.
   */
  public modifyAppOwner(
    data: ApplicationSourceRegistrationParams,
    headersParams?: PassthroughOptions
  ): Observable<boolean> {
    let headers = new HttpHeaders();
    if (headersParams?.accessClusterId) {
      headers = headers.append('accessClusterId', String(headersParams?.accessClusterId));
    }
    if (headersParams?.regionId) {
      headers = headers.append('regionId', String(headersParams?.regionId));
    }
    return this.http.post<boolean>(Api.private('applicationSourceRegistration'), data, { headers });
  }

  /**
   * Reusable method to update an App owner. For the same endpoint and
   * configurable HTTP method.
   *
   * @param      data    Params for registring the app owner.
   * @return     A observable of the result.
   */
  public updateAppOwner(data: ApplicationSourceRegistrationParams): Observable<any> {
    return this.http.put(Api.private('applicationSourceRegistration'), data);
  }

  /**
   * Reusable method to delete an App owner. For the same endpoint and
   * configurable HTTP method.
   *
   * @param      data    Params for registring the app owner.
   * @return     A observable of the result.
   */
  public unregisterAppOwner(data: ApplicationSourceRegistrationParams): Observable<any> {
    const request = new HttpRequest('DELETE', Api.private('applicationSourceRegistration'), data, {});
    return this.http.request(request);
  }
}
