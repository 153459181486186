// Controller: Helios Reports Email Schedules

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.heliosReports')
    .component('heliosReportsEmailSchedules', {
      controller: 'EmailSchedulesCtrl',
      templateUrl: 'app/reports/helios/email-schedules.html',
    })
    .controller('EmailSchedulesCtrl', EmailSchedulesCtrlFn);

  /**
   * Controller for helios reports email schedules
   */
  function EmailSchedulesCtrlFn(_, $translate, evalAJAX, cMessage, ReportsUtil,
    HeliosReportsService) {
    var $ctrl = this;

    _.assign($ctrl, {
      // Life-Cycle Hooks.
      $onInit: $onInit,

      // Methods exposed to the template.
      canShowScheduledReport: canShowScheduledReport,
      deleteSchedule: deleteSchedule,
      editSchedule: editSchedule,

      // Variables exposed to the template.
      isLoading: true,
      reportTypesMap: {},
      schedulesList: [],
      selectedReport: null,
      reports: [
        {
          labelKey: 'all',
          params: {
            reportType: 'all',
          },
        },
      ].concat(HeliosReportsService.getReportsConfig()),
    });

    /**
     * Initialize this controller.
     *
     * @method     $onInit
     */
    function $onInit() {
      $ctrl.reports = $ctrl.reports.map(report => (
        { ...report, reportName: $translate.instant(report.labelKey) }
      ));

      $ctrl.selectedReport = $ctrl.reports[0];
      $ctrl.reportTypesMap = _.keyBy($ctrl.reports, 'params.reportType');
      getData();
    }

    /**
     * Get list of scheduled emails reports.
     *
     * @method    getData
     */
    function getData() {
      $ctrl.isLoading = true;
      HeliosReportsService.getReportSchedules().then(
        function GetReportSchedulesSuccess(schedulesList) {
          $ctrl.schedulesList = schedulesList;
        },
        evalAJAX.errorMessage
      ).finally(function finallyGetReportSchedules() {
        $ctrl.isLoading = false;
      });
    }

    /**
     * Can show scheduled email report.
     *
     * @method    canShowScheduledReport
     * @param     {Object}    schedule   The schedule info.
     * @returns   {Boolean}   Return true if schedule can be shown else return
     *                        false.
     */
    function canShowScheduledReport(schedule) {
      if ($ctrl.selectedReport.params.reportType === 'all') {
        return true;
      }

      return $ctrl.selectedReport.params.reportType === schedule.reportType;
    }

    /**
     * Launch edit schedule modal.
     *
     * @method    editSchedule
     * @param    {Object}   schedule   The schedule info.
     */
    function editSchedule(schedule) {
      var report = $ctrl.reportTypesMap[schedule.reportType];
      var filter = _.assign(schedule.schedule, {
        dateRange: {
          start: schedule.filterParams.startDate,
          end: schedule.filterParams.endDate,
        },
        reportTimeSpan: schedule.filterParams.reportTimeSpan,
        reportTimeSpanType: schedule.filterParams.reportTimeSpanType,
        clusters: schedule.filterParams._clusters,
        sources: schedule.filterParams._rootNodes,
        runStatus: schedule.filterParams._runStatus,
        objectTypes: schedule.filterParams._objectType,
        fetchType: schedule.filterParams.fetchType,
        taskType: schedule.filterParams.taskType,
        scheduleHour: schedule.schedule.scheduleTime.hour,
      });

      ReportsUtil.openEmailScheduler(
        // selected filter values.
        filter,

        // reports config.
        {
          fields: report.filter.floor.concat(report.filter.ceil),
          selectedReport: report,
          showHeader:true,
        },

        // empty filters dataset.
        {},

        // launch edit mode.
        true,

        // show mcmMode email scheduler view.
        true
      ).then(getData);
    }

    /**
     * Remove the scheduled task.
     *
     * @method    deleteSchedule
     * @param    {Object}   taskId   The ID of the task to delete.
     */
    function deleteSchedule(taskId) {
      $ctrl.isLoading = true;
      HeliosReportsService.deleteReportSchedule(taskId)
        .then(function onSuccess() {
          cMessage.success({
            textKey: 'reports.scheduleDeleted',
          });
          getData();
        }, evalAJAX.errorMessage);
    }
  }
})(angular);