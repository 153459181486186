import { Output, ViewChild, Input, Directive } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Subject } from 'rxjs';
import { AutoDestroyable } from '@cohesity/utils';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';

/**
 * A base class that makes it easier to implement custom form controls based on mat-select.
 * T represents the type of value this control is based on and is defined by the
 * subclass.
 */
@Directive()
export abstract class ItemPickerFormControl<T> extends AutoDestroyable implements ControlValueAccessor {
  /**
   * MatSelect child component, leveraged in implementations to auto-open selects.
   */
  @ViewChild(MatSelect) matSelect: MatSelect;

  /**
   * Indicates if the component should be opened on initialization.
   */
  @Input() autoOpen = false;

  /**
   * There appear to be issues in the angular forms that prevent form.valueChanges from firing properly on
   * custom control value accessors. This will emit a change manually when the value is set.
   */
  @Output() valueChange = new Subject<T>();
  /**
   * The current form control value
   */
  private _value: T;
  /**
   * The current form control value
   *
   * @return   The current value of the form control
   */
  get value(): T {
    return this._value;
  }

  /**
   * Sets the form control value and propogates the change
   *
   * @param   value   The form control value to set
   */
  set value(value) {
    this._value = value;
    this.propagateChange(value);
    this.valueChange.next(value);
  }

  /**
   * Callback to pass the change event back to a form control. Defaults to a noop
   */
  propagateChange = (_value: T) => {};

  /**
   * Callback to pass the onTouch event back to a form control. Defaults to a noop
   */
  propagateOnTouch = () => {};

  /**
   * Update the view with a value passed from a form
   *
   * @param   value   the new form control value
   */
  writeValue(value: T) {
    this._value = value;
  }

  /**
   * Registers a change event handler to use to propogate changes
   *
   * @param   fn   the callback function
   */
  registerOnChange(fn: (value: T) => any) {
    this.propagateChange = fn;
  }

  /**
   * Register on touched event handler.
   */
  registerOnTouched(fn: () => any) {
    this.propagateOnTouch = fn;
  }

  constructor() {
    super();
  }
}
