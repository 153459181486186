import { Injectable } from '@angular/core';
import { BANNER_PRIORITY, BannerConfig } from '@cohesity/helix';
import { BaseBannerConfig, flagEnabled, IrisContext, IrisContextService, isMcmSaaS } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { map } from 'rxjs/operators';
import { getHeliosMfaGracePeriodDays, getHeliosMfaStatus } from 'src/app/util';

@Injectable({
  providedIn: 'root',
})
export class HeliosMfaBannerConfig extends BaseBannerConfig {
  /**
   * Unique banner representation
   */
  id = 'helios-mga-banner';

  /**
   * Banner config as observable
   */
  bannerInfo$ = this.irisContextService.irisContext$.pipe(
    map(ctx => ([{
      id: this.id,
      priority: BANNER_PRIORITY.HIGH,
      isVisible: this.allowBanner(ctx),
      status: 'warn',
      allowClose: false,
      text: this.getBannerText(ctx),
      tooltip: this.getBannerText(ctx),
      actionText: this.translateService.instant('manageMfaShort'),
      actionCallback: () => this.stateService.go('helios-access-management.mfa'),
    } as BannerConfig]))
  );

  constructor(
    private irisContextService: IrisContextService,
    private stateService: StateService,
    private translateService: TranslateService) {
    super();
  }

  /**
   * Get text for the Helios MFA banner.
   *
   * @param ctx iris context
   * @returns The banner text
   */
  getBannerText(ctx): string {
    const days = getHeliosMfaGracePeriodDays(ctx);

    if (!days) {
      return this.translateService.instant('mfa.banner.gracePeriodNoDays');
    }

    return this.translateService.instant('mfa.banner.gracePeriod', {days});
  }

  /**
   * Allow banner based on context
   *
   * @param ctx iris context
   * @returns true/false based on context
   */
  allowBanner(ctx: IrisContext) {
    return (
      this.isLoggedIn(ctx) &&
      flagEnabled(ctx, 'mfaHeliosSaas') &&
      flagEnabled(ctx, 'mfaHeliosSaasBanner') &&
      isMcmSaaS(ctx) &&
      getHeliosMfaStatus(ctx) === 'Pending' &&
      ctx?.privs?.MCM_MODIFY_MFA
    );
  }
}
