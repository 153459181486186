<cog-table name="recovery-nosql-detail-objects-table"
  [source]="recoveryObjects || []">
  <table mat-table
    matSort
    matSortActive="name"
    matSortDirection="desc"
    [trackBy]="trackById">

    <ng-container matColumnDef="name">
      <th mat-header-cell
        cogDataId="object-name-col"
        *matHeaderCellDef
        mat-sort-header>
        {{ 'object' | translate }}
      </th>
      <td mat-cell *matCellDef="let object">
        <div class="cog-table-object">
          <cog-icon
            [shape]="object.objectType | recoveryObjectIcon:
              object.environment:
              object.osType:
              recovery.action">
          </cog-icon>
          <div class="cog-table-object-body">
            <div class="flex-truncate-wrapper">
              <div class="cog-table-object-name flex-truncate-text">
                {{ object.fullName }}
                <span class="icn-view"
                  cohStopPropagation
                  [cogPopover]="popover"
                  [popoverData]="object">
                </span>
              </div>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="rename">
      <th cogDataId="object-rename-col"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ 'rename' | translate }}
      </th>
      <td mat-cell *matCellDef="let object">
        {{ object.renameTo }}
      </td>
    </ng-container>

    <ng-container matColumnDef="recoveryPoint">
      <th cogDataId="object-recoveryPoint-col"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        {{ 'recoveryPoint' | translate }}
      </th>
      <td mat-cell *matCellDef="let object">
        {{ formatRecoveryPointTime(object.recoveryPoint) }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns"
      [cogDataId]="'object-anchor-' + (row.name | lowercase)"
      [class.no-activity-row]="!row.progressTaskId?.length"
      (click)="openActivityModal(row)">
    </tr>

    <!-- NoData Footer -->
    <ng-container matColumnDef="noData">
      <td [attr.colspan]="tableColumns.length" mat-footer-cell *matFooterCellDef>
        {{ 'noObjectsFound' | translate }}
      </td>
    </ng-container>

    <tr class="nodata-row"
      mat-footer-row
      [class.hidden]="recoveryObjects?.length"
      *matFooterRowDef="['noData']">
    </tr>
  </table>
  <mat-paginator></mat-paginator>
</cog-table>
