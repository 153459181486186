import { CreateView, SmbPermission } from '@cohesity/api/v2';
import { IrisContext, flagEnabled } from '@cohesity/iris-core';
import { isEmpty } from 'lodash';

import {
  CreateViewBasicForm,
  CreateViewData,
  CreateViewPageForm,
  CreateViewSettings,
  DefaultCreateViewValue,
  DefaultProtocolListByCategory,
  DefaultProtocolListByCategoryWithNFS4,
  S3AbacServerSettings,
} from '../models';
import { isS3ProtocolSelectedOnly, isSwiftProtocolSelected } from './views.utils';

/**
 * Transforms the View Data to be handled in the create-view-page.
 *
 * @see {CreateViewPageComponent}
 *
 * @param createViewData The create view data
 * @param isTemplateMode Whether the view is being created from a template or not.
 * @param isCreateMode Whether to use default values or not. Default values should only be added to form when
 * creating a new template; creating a view from default template; directly creating a view from URL. Note: It shouldn't
 * be used when editing a view OR creating a view from a custom template.
 * @returns The transformed form value.
 */
export const transformToCreateViewForm = (
  createViewData: CreateViewData,
  isTemplateMode?: boolean,
  isCreateMode?: boolean,
  irisContext?: IrisContext
  ): CreateViewPageForm => ({
  createViewBasicForm: transformToCreateViewBasicForm(createViewData, isTemplateMode, irisContext),
  createViewSettings: transformToCreateViewSettingsForm(createViewData, isCreateMode),
});

/**
 * Transforms the view data to be used in the create-view-basic parent form.
 *
 * @param createViewData The create view data
 * @param isTemplateMode Whether the view is being created from a template or not.
 * @returns The transformed form value.
 */
export const transformToCreateViewBasicForm = (
    createViewData: CreateViewData,
    isTemplateMode?: boolean,
    irisContext?: IrisContext
  ): CreateViewBasicForm => {
  const {
    category = null,
    dataLockExpiryUsecs = null,
    mostSecureSettings = null,
    name = null,
    objectServicesMappingConfig = null,
    protocolAccess = null,
    storageDomainId = null,
    swiftProjectDomain = null,
    swiftProjectName = null,
    swiftUserDomain = null,
    swiftUsername = null,
    isExternallyTriggeredBackupTarget = null,
    intent = null,
  } = { ...DefaultCreateViewValue, ...(createViewData?.viewParams ?? {}) };

  // Use restrictViewBoxId if it exists.
  const storageDomainIdFormValue = createViewData?.restrictViewBoxId || storageDomainId;

  // If in template mode, use the template name instead.
  const nameValue = isTemplateMode ? createViewData.name : name;

  return {
    category,
    dataLockExpiryUsecs,
    name: nameValue ?? null,
    mostSecureSettings: mostSecureSettings ?? (flagEnabled(irisContext, 'viewSettingsSecureByDefault') && category === 'FileServices'),
    protocolAccess: protocolAccess?.length ? protocolAccess :
      flagEnabled(irisContext, 'viewSettingsSecureByDefault') ?
        DefaultProtocolListByCategoryWithNFS4[category] :
        DefaultProtocolListByCategory[category],
    objectServicesMappingConfig,

    // SD is set to -1 for Standard Templates so need to reset it to null to
    // set the SD to DefaultStorageDomain.
    storageDomainId: storageDomainIdFormValue === -1 ? null : storageDomainIdFormValue,

    // Set swift config if protocol is swift.
    swiftConfig: !isSwiftProtocolSelected(protocolAccess) ? null : {
      swiftProjectDomain,
      swiftProjectName,
      swiftUserDomain,
      swiftUsername,
    },

    // Set isExternallyTriggeredBackupTarget if available.
    isExternallyTriggeredBackupTarget,

    // Set view intent if template details are available.
    intent,
  };
};

/**
 * Transforms the view data to be used in the create-view-settings parent form.
 *
 * @param createViewData The create view data
 * @param isCreateMode Whether to use default values or not. Default values should only be added to form when
 * creating a new template; creating a view from default template; directly creating a view from URL. Note: It shouldn't
 * be used when editing a view OR creating a view from a custom template.
 * @returns The transformed form value.
 */
const transformToCreateViewSettingsForm = (createViewData: CreateViewData, isCreateMode: boolean):
  CreateViewSettings => {
  const {
    enableAbac = null,
    aclConfig = null,
    antivirusScanConfig = null,
    bucketPolicy = null,
    caseInsensitiveNamesEnabled = null,
    description = null,
    enableFastDurableHandle = null,
    enableFilerAuditLogging = null,
    enableNfsKerberosAuthentication = null,
    enableNfsKerberosIntegrity = null,
    enableNfsKerberosPrivacy = null,
    enableNfsWcc = null,
    enableNfsUnixAuthentication = null,
    enableNfsViewDiscovery = null,
    enableOfflineCaching = null,
    enableSmbAccessBasedEnumeration = null,
    enableSmbEncryption = null,
    enableSmbLeases = null,
    enableSmbOplock = null,
    enableSmbViewDiscovery = null,
    enforceSmbEncryption = null,
    fileExtensionFilter = null,
    fileLockConfig = null,
    filerLifecycleManagement = null,
    lifecycleManagement = null,
    logicalQuota = null,
    netgroupWhitelist,
    nfsAllSquash,
    nfsRootPermissions = null,
    nfsRootSquash,
    overrideGlobalNetgroupWhitelist,
    overrideGlobalSubnetWhitelist,
    ownerInfo = null,
    qos = null,
    s3FolderSupportEnabled = null,
    securityMode = null,
    selfServiceSnapshotConfig = null,
    sharePermissions = null,
    smbPermissionsInfo = null,
    storagePolicyOverride = null,
    subnetWhitelist = null,
    versioning = null,
    viewPinningConfig = null,
    viewProtectionConfig = null,
    viewProtection = null,
  } = {
    ...(isCreateMode ? DefaultCreateViewValue : {}),
    ...(createViewData?.viewParams ?? {})
  };

  // Set some of the missing properties in file lock config so as to update
  // the form controls correctly.
  let fileLockConfigValue = fileLockConfig;
  if (fileLockConfig) {
    // if file lock or s3 object lock contains year retention, then UI should convert it to msecs unit,
    // since coh-retention-selector component only accept msecs unit.
    if (fileLockConfig.defaultRetentionDurationYears) {
      fileLockConfig.defaultRetentionDurationMsecs =
        fileLockConfig.defaultRetentionDurationYears * 60 * 60 * 24 * 365 * 1000;
    }

    const {
      autoLockAfterDurationIdleMsecs = null,
      coexistingLockMode = null,
      defaultRetentionDurationMsecs = null,
      defaultRetentionDurationYears = null,
      expiryTimestampMsecs = null,
      lockingProtocol = null,
      maxRetentionDurationMsecs = null,
      minRetentionDurationMsecs = null,
      mode = null,
    } = fileLockConfig;

    fileLockConfigValue = {
      ...fileLockConfig,
      autoLockAfterDurationIdleMsecs,
      coexistingLockMode,
      defaultRetentionDurationMsecs,
      defaultRetentionDurationYears,
      expiryTimestampMsecs,
      lockingProtocol,
      maxRetentionDurationMsecs,
      minRetentionDurationMsecs,
      mode,
    };
  }

  // Set viewProtctionConfig value if viewProtection is available.
  // viewProtection is set after a view is created, use that info to populate
  // config.
  let viewProtectionConfigValue = viewProtectionConfig;
  if (!viewProtectionConfig && viewProtection) {
    viewProtectionConfigValue = {
      protectionGroupType: 'ExistingGroup',
      existingGroupParam: {
        id: viewProtection?.protectionGroups[0]?.protectionGroupId,
      }
    };
  }

  const logicalQuotaConfig = {
    hardLimitBytes: logicalQuota?.hardLimitBytes || null,
    alertLimitBytes: logicalQuota?.alertLimitBytes || null,
    alertThresholdPercentage: logicalQuota?.alertThresholdPercentage || null,
  };

  const isExternallyTriggeredBackupTarget = createViewData?.viewParams?.isExternallyTriggeredBackupTarget;

  const abacServerConfig: S3AbacServerSettings = {
    enableAbac: enableAbac,
    ownerInfo: enableAbac ? ownerInfo : null,
  };

  return {
    abacServerConfig,
    aclConfig,
    antivirusScanConfig,
    bucketPolicy,
    caseInsensitiveNamesEnabled,
    description,
    enableFastDurableHandle,
    enableFilerAuditLogging,
    enableOfflineCaching,
    enableSmbAccessBasedEnumeration,
    enableSmbEncryption,
    enableSmbLeases,
    enableSmbOplock,
    enableSmbViewDiscovery,
    enforceSmbEncryption,
    fileExtensionFilter,
    fileLockConfig: fileLockConfigValue,
    filerLifecycleManagement,
    lifecycleManagement: {
      rules: lifecycleManagement?.rules ?? [],
      versionId: 1,
    },

    // Sometimes logicalQuota comes as an empty object, need to check for it.
    logicalQuota: isEmpty(logicalQuota) ? null : logicalQuotaConfig,
    netgroupWhitelist,
    nfsAllSquash,
    nfsRootSquash,
    overrideGlobalNetgroupWhitelist,
    overrideGlobalSubnetWhitelist,
    ownerInfo: ownerInfo,
    qos,
    s3FolderSupportEnabled,
    securityMode,
    selfServiceSnapshotConfig,
    sharePermissions,

    // Don't modify SMB pemissions since this would create an immutable view for externally
    // triggered backup target.
    smbPermissionsInfo: isExternallyTriggeredBackupTarget ? null : smbPermissionsInfo,
    storagePolicyOverride,
    subnetWhitelist,
    versioning,
    viewPinningConfig: viewPinningConfig ?? DefaultCreateViewValue.viewPinningConfig,
    viewProtectionConfig: viewProtectionConfigValue,

    // TODO: Find out exact YAML details about these fields as they are absent
    //       from CreateView.
    enableAppAwarePrefetching: null,
    enableAppAwareUptiering: null,
    enableNfsViewDiscovery,
    enableNfsUnixAuthentication,
    enableNfsKerberosAuthentication,
    enableNfsKerberosIntegrity,
    enableNfsKerberosPrivacy,
    enableNfsWcc,

    // Don't modify nfs pemissions since this is will create an immutable view for externally
    // triggered backup target.
    nfsRootPermissions: isExternallyTriggeredBackupTarget ? null : nfsRootPermissions,
  };
};

/**
 * Parses the create view page form by stubbing some form fields and
 * transforming it to API friendly CreateView.
 *
 * @param createViewForm  The create view page form.
 * @returns The form value transformed into API friendly CreateView.
 */
export const transformFromCreateViewForm = (createViewForm: CreateViewPageForm): CreateView => ({
  ...transformFromCreateViewBasicForm(createViewForm.createViewBasicForm),
  ...transformFromCreateViewSettingsForm(createViewForm.createViewSettings, createViewForm.createViewBasicForm),
});

/**
 * Parses the create view basic form by stubbing some required form fields and
 * transforming it to API friendly CreateView.
 *
 * @param createViewBasicForm The create view basic form.
 * @returns The form value transformed into API friendly CreateView.
 */
export const transformFromCreateViewBasicForm = (createViewBasicForm: CreateViewBasicForm): CreateView => {
  const { swiftConfig, ...formValue } = createViewBasicForm;

  return  {
    ...formValue,

    // Remove objectServicesMappingConfig if category isn't ObjectServices.
    objectServicesMappingConfig: formValue.category === 'ObjectServices' ? formValue.objectServicesMappingConfig : null,

    // Add swift related fields if protocol is swift.
    ...(isSwiftProtocolSelected(formValue.protocolAccess) ? swiftConfig : {}),
  };
};


/**
 * Parses the create view settings form by stubbing some required form fields and
 * transforming it to API friendly CreateView.
 *
 * @param createViewSettingsForm The create view settings form.
 * @param createViewBasicForm The create view basic form to remove certain fields.
 *
 * @returns The form value transformed into API friendly CreateView.
 */
export const transformFromCreateViewSettingsForm = (createViewSettingsForm: CreateViewSettings,
  createViewBasicForm: CreateViewBasicForm): CreateView => {

  const { category, protocolAccess, objectServicesMappingConfig } = createViewBasicForm;
  const { fileLockConfig, lifecycleManagement, abacServerConfig } = createViewSettingsForm ?? {};

  // If abacServerConfig exist, need to delete it and transform it to API friendly properties.
  if (createViewSettingsForm?.abacServerConfig) {
    delete createViewSettingsForm.abacServerConfig;
  }

  if (fileLockConfig?.autoLockAfterDurationIdleMsecs === 0) {
    fileLockConfig.autoLockAfterDurationIdleMsecs = undefined;
  }

  return {
    ...(createViewSettingsForm || {}),

    // s3FolderSupportEnabled should be false when the category is not ObjectServices, protocol is not S3 only
    // and the objectServicesMappingConfig is not 'Hierarchical'.
    s3FolderSupportEnabled: category === 'ObjectServices' && isS3ProtocolSelectedOnly(protocolAccess) &&
      objectServicesMappingConfig === 'Hierarchical' && createViewSettingsForm?.s3FolderSupportEnabled,

    // Remove the file lock config if mode is not set.
    fileLockConfig: (fileLockConfig?.mode ||  fileLockConfig?.coexistingLockMode) ? fileLockConfig : null,

    // Delete all other properties in lifecycleManagement and only keep rules, if rules exist.
    lifecycleManagement: lifecycleManagement?.rules?.length ? { rules: lifecycleManagement.rules } : null,
    enableAbac: abacServerConfig?.enableAbac || null,
    ownerInfo: abacServerConfig?.enableAbac ? abacServerConfig?.ownerInfo : createViewSettingsForm?.ownerInfo,
  };
};

/**
 * Find index of the Everyone principal in a provided list of ACL permissions.
 *
 * @param permissionsList A list of ACL permissions.
 * @returns Index number.
 */
export const findEveryoneIndex = (permissionsList: SmbPermission[] = []) =>
  permissionsList.findIndex(perm => perm.sid === 'S-1-1-0');
