import { CdpObjectInfo, ObjectSnapshotsInfo, ProtectedObject } from '@cohesity/api/v2';
import { decorateInterface } from '@cohesity/utils';
import { RecoveryOsType, JobEnvParamsV2 } from 'src/app/shared/constants';

import { RestorePointSelection } from './restore-point-selection';
import { RestoreSearchResult } from './restore-search-result';

/**
 * This class is used to decorate the ProtectedObject api model to work with the restore search field and selection.
 *
 * @example
 * const searchResult = new ObjectSearchResult(protectObject);
 */
export class ObjectSearchResult extends decorateInterface<Required<ProtectedObject>>() implements RestoreSearchResult {
  /**
   * Static property for the result type. Which can be referenced by other classes.
   */
  static readonly objectResultType = 'object';

  get defaultRestorePointSelection(): RestorePointSelection {
    const selection: RestorePointSelection = {
      objectInfo: this,
      restorePointId: this.defaultSnapshotId,
      objectIds: [this.id],
      timestampUsecs: this.restoreTimestampUsecs,
      snapshotRunType: this.snapshotRunType,
      isPointInTime: this.snapshotRunType === 'kHydrateCDP'
    };

    if (this.defaultSnapshotTargetType === 'Archival') {
      const targetInfo = this.latestSnapshotInfo.archivalSnapshotsInfo[0] as any;
      selection.archiveTargetInfo = {...targetInfo};
    }

    return selection;
  }

  /**
   * Gets the default snapshot target type to use when this object is selected from the search results.
   */
  get defaultSnapshotTargetType(): 'Local' | 'Archival' {
    if (this.latestSnapshotInfo && this.latestSnapshotInfo.localSnapshotInfo) {
      return 'Local';
    } else if (this.latestSnapshotInfo.archivalSnapshotsInfo && this.latestSnapshotInfo.archivalSnapshotsInfo.length) {
      return 'Archival';
    }
    throw new Error('No local or Archive Snapshot Available');
  }

  /**
   * OS type of restore object.
   */
  get recoveryOsType(): RecoveryOsType {
    return this.osType as RecoveryOsType;
  }

  /**
   * Gets the group id from the latest snapshot info.
   */
  get protectionGroupId(): string {
    return this.latestSnapshotInfo.protectionGroupId;
  }

  get protectionGroupName(): string {
    return this.latestSnapshotInfo.protectionGroupName;
  }

  /**
   * Gets the group run id from the latest snapshot info.
   */
  get protectionRunId(): string {
    return this.latestSnapshotInfo.protectionRunId;
  }

  /**
   * Gets the object size from the most recent snapshot.
   */
  get objectSizeBytes(): number {
    return this.latestSnapshotInfo?.localSnapshotInfo?.logicalSizeBytes;
  }

  /**
   * By default, objects can be selected along with other objec types.
   */
  requiresExclusiveSelection = false;

  get restoreTimestampUsecs(): number {
    return this.latestSnapshotInfo.protectionRunStartTimeUsecs;
  }

  readonly resultType = ObjectSearchResult.objectResultType;

  get storageDomainId(): number {
    return this.latestSnapshotInfo.storageDomainId;
  }

  get sourceEnvironment(): string {
    return this.sourceInfo.environment || this.environment;
  }

  get parentSourceName(): string {
    return this.sourceInfo && this.sourceInfo.name;
  }

  /**
   * Get Continuous Protection Info from the object. It might not exist.
   */
  get cdpObjectInfo(): CdpObjectInfo {
    return this[JobEnvParamsV2[this.environment]]?.cdpInfo;
  }

  /**
   * Gets the default snapshot id to use when this object is selected from the search results.
   */
  private get defaultSnapshotId(): string {
    return this.defaultSnapshotTargetType === 'Local'
      ? this.latestSnapshotInfo.localSnapshotInfo.snapshotId
      : (this.latestSnapshotInfo.archivalSnapshotsInfo[0] as any).snapshotId;
  }

  /**
   * Convenience getter to return the first item in the last snapshots info array.
   */
  get latestSnapshotInfo(): ObjectSnapshotsInfo {
    return this.latestSnapshot ? this.latestSnapshot : this.latestSnapshotsInfo[0];
  }

  /**
   * Returns run type from latest snapshot.
   */
  private get snapshotRunType() {
    return this.latestSnapshotInfo.runType;
  }

  constructor(object: Required<ProtectedObject>, private latestSnapshot?: ObjectSnapshotsInfo) {
    super(object);
  }
}
