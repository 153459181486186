/**
 * model kValues for days
 */
export enum DayKVal {
  kSunday,
  kMonday,
  kTuesday,
  kWednesday,
  kThursday,
  kFriday,
  kSaturday,
}

/**
 * Day of week value. To replace DayKVal as we are removing kValues in v2 API.
 */
export enum DayVal {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

/**
 * Weekday list.
 */
export const WeekdayList = [
  'Friday',
  'Monday',
  'Thursday',
  'Tuesday',
  'Wednesday',
];

/**
 * Weekend list.
 */
export const WeekendList = [
  'Saturday',
  'Sunday',
];

/**
 * model kValues for day of the week.
 */
export enum DayOfWeek {
  kSunday = 'Sunday',
  kMonday = 'Monday',
  kTuesday = 'Tuesday',
  kWednesday = 'Wednesday',
  kThursday = 'Thursday',
  kFriday = 'Friday',
  kSaturday = 'Saturday',
}


/**
 * model inverse kValues for day of the week.
 */
export enum DayOfWeekInverse {
  Sunday = 'kSunday',
  Monday = 'kMonday',
  Tuesday = 'kTuesday',
  Wednesday = 'kWednesday',
  Thursday = 'kThursday',
  Friday = 'kFriday',
  Saturday = 'kSaturday',
}

/**
 * model kValues for day count in month
 */
export enum DayCountInMonth {
  kFirst = 'kFirst',
  kSecond = 'kSecond',
  kThird = 'kThird',
  kFourth = 'kFourth',
  kLast = 'kLast',
}

/**
 * model kValues for retention time
 */
export enum Granularity {
  kDays = 'Days',
  kHours = 'Hours',
  kMinutes = 'Minutes',
  kMonths = 'Months',
  kWeeks = 'Weeks',
  kYears = 'Years',
  /* eslint-disable @typescript-eslint/no-duplicate-enum-values */
  kDay = 'Days',
  kHour = 'Hours',
  kMinute = 'Minutes',
  kMonth = 'Months',
  kWeek = 'Weeks',
  kYear = 'Years',
  /* eslint-enable @typescript-eslint/no-duplicate-enum-values */
  kEvery = 'Every',
}

// Type definition for the timeframe that should be displayed on dashboards/tiles/etc.
export type Timeframe = 'kHour' | 'kDay' | 'kWeek' | 'kMonth' | 'kQuarter';

/**
 * Inverse map for Granularity.
 */
export enum GranularityInverse {
  Days = 'kDay',
  Hours = 'kHour',
  Minutes = 'kMinute',
  Months = 'kMonth',
  Weeks = 'kWeek',
  Years = 'kYear',
  Every = 'kEvery',
}

/**
 * DayToMsecs holds the mapping of time period kValues to there numerical representation milli secs.
 */
export enum DayToMsecs {
  kMinute = 60 * 1000,
  kHour = kMinute * 60,
  kDay = kHour * 24,
  kWeek = kDay * 7,
  kMonth = kDay * 30,
  kYear = kDay * 365,
}

/**
 * GranularityToDay holds the mapping of time period to their number of days equivalent.
 */
export enum GranularityToDays {
  kDay = 1,
  kWeek = kDay * 7,
  kMonth = kDay * 30,
  kYear = kDay * 365,
  kHour = kDay / 24,
  kMinute = kHour / 60,
}
