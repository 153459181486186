import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { Document } from 'src/app/shared';
import { SelectionModel } from '@angular/cdk/collections';
import { AutoDestroyable } from '@cohesity/utils';

/**
 * This displays the selection information for a volume browser.
 */
@Component({
  selector: 'coh-volume-selection-detail',
  templateUrl: './volume-selection-detail.component.html',
  styleUrls: ['./volume-selection-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VolumeSelectionDetailComponent extends AutoDestroyable implements OnInit {

  /**
   * The selection model containing the selected files.
   */
  @Input() selection: SelectionModel<Document>;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    // Need to run change detection whenever the selection changes.
    this.selection.changed.pipe(this.untilDestroy()).subscribe(() => this.cdr.detectChanges());
  }
}
