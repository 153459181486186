/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ConnectivityCheckResponseBody } from '../models/connectivity-check-response-body';
import { BifrostConnectors } from '../models/bifrost-connectors';
import { BifrostConnector } from '../models/bifrost-connector';
import { UpdateBifrostConnectorRequest } from '../models/update-bifrost-connector-request';
import { RigelConnectors } from '../models/rigel-connectors';
import { RigelConnector } from '../models/rigel-connector';
import { CreateRigelConnectorRequest } from '../models/create-rigel-connector-request';
import { UpdateRigelConnectorRequest } from '../models/update-rigel-connector-request';
import { DeleteRigelConnectorRequest } from '../models/delete-rigel-connector-request';
@Injectable({
  providedIn: 'root',
})
class ConnectorsService extends __BaseService {
  static readonly GetConnectivityCheckPath = '/connectivity-endpoints';
  static readonly PerformConnectivityCheckPath = '/connectivity-endpoints';
  static readonly GetBifrostConnectorPath = '/connector-hybrid-extender';
  static readonly GetBifrostConnectorByIdPath = '/connector-hybrid-extender/{id}';
  static readonly UpdateBifrostConnectorPath = '/connector-hybrid-extender/{id}';
  static readonly DeleteBifrostConnectorPath = '/connector-hybrid-extender/{id}';
  static readonly GetRigelConnectorPath = '/connector-rigel';
  static readonly CreateRigelConnectorPath = '/connector-rigel';
  static readonly GetRigelConnectorByIdPath = '/connector-rigel/{id}';
  static readonly UpdateRigelConnectorPath = '/connector-rigel/{id}';
  static readonly DeleteRigelConnectorPath = '/connector-rigel/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get connectivity check results.
   *
   * Get connectivity check results.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetConnectivityCheckResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<ConnectivityCheckResponseBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/connectivity-endpoints`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ConnectivityCheckResponseBody>;
      })
    );
  }
  /**
   * Get connectivity check results.
   *
   * Get connectivity check results.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetConnectivityCheck(regionId?: string,
    accessClusterId?: number): __Observable<ConnectivityCheckResponseBody> {
    return this.GetConnectivityCheckResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as ConnectivityCheckResponseBody)
    );
  }

  /**
   * Perform Connectivity Check.
   *
   * Perform connectivity checks on endpoints.
   * @param params The `ConnectorsService.PerformConnectivityCheckParams` containing the following parameters:
   *
   * - `cloudProvider`: Cloud provider - kAws or kAzure.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  PerformConnectivityCheckResponse(params: ConnectorsService.PerformConnectivityCheckParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.cloudProvider != null) __params = __params.set('cloudProvider', params.cloudProvider.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/connectivity-endpoints`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Perform Connectivity Check.
   *
   * Perform connectivity checks on endpoints.
   * @param params The `ConnectorsService.PerformConnectivityCheckParams` containing the following parameters:
   *
   * - `cloudProvider`: Cloud provider - kAws or kAzure.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  PerformConnectivityCheck(params: ConnectorsService.PerformConnectivityCheckParams): __Observable<null> {
    return this.PerformConnectivityCheckResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get Bifrost connectors on the cluster.
   *
   * Get Bifrost connectors on the cluster.
   * @param params The `ConnectorsService.GetBifrostConnectorParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the id of the tenant which the connector belongs to.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `names`: Specifies the name of the connectors.
   *
   * - `ids`: Specifies the id of the connectors.
   *
   * - `connectionId`: Specifies the Id of the connection which the connector belongs to.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetBifrostConnectorResponse(params: ConnectorsService.GetBifrostConnectorParams): __Observable<__StrictHttpResponse<BifrostConnectors>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.names || []).forEach(val => {if (val != null) __params = __params.append('names', val.toString())});
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.connectionId != null) __params = __params.set('connectionId', params.connectionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/connector-hybrid-extender`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BifrostConnectors>;
      })
    );
  }
  /**
   * Get Bifrost connectors on the cluster.
   *
   * Get Bifrost connectors on the cluster.
   * @param params The `ConnectorsService.GetBifrostConnectorParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the id of the tenant which the connector belongs to.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `names`: Specifies the name of the connectors.
   *
   * - `ids`: Specifies the id of the connectors.
   *
   * - `connectionId`: Specifies the Id of the connection which the connector belongs to.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetBifrostConnector(params: ConnectorsService.GetBifrostConnectorParams): __Observable<BifrostConnectors> {
    return this.GetBifrostConnectorResponse(params).pipe(
      __map(_r => _r.body as BifrostConnectors)
    );
  }

  /**
   * Get a Bifrost connector by the id.
   *
   * Get a Bifrost connector by the id.
   * @param params The `ConnectorsService.GetBifrostConnectorByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of connector.
   *
   * - `tenantId`: Specifies the id of the tenant which the connector belongs to.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetBifrostConnectorByIdResponse(params: ConnectorsService.GetBifrostConnectorByIdParams): __Observable<__StrictHttpResponse<BifrostConnector>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/connector-hybrid-extender/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BifrostConnector>;
      })
    );
  }
  /**
   * Get a Bifrost connector by the id.
   *
   * Get a Bifrost connector by the id.
   * @param params The `ConnectorsService.GetBifrostConnectorByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of connector.
   *
   * - `tenantId`: Specifies the id of the tenant which the connector belongs to.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetBifrostConnectorById(params: ConnectorsService.GetBifrostConnectorByIdParams): __Observable<BifrostConnector> {
    return this.GetBifrostConnectorByIdResponse(params).pipe(
      __map(_r => _r.body as BifrostConnector)
    );
  }

  /**
   * Update a Bifrost connector.
   *
   * Update a Bifrost connector.
   * @param params The `ConnectorsService.UpdateBifrostConnectorParams` containing the following parameters:
   *
   * - `id`: Specifies the id of connector.
   *
   * - `body`: Specifies the parameters to update a connector.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateBifrostConnectorResponse(params: ConnectorsService.UpdateBifrostConnectorParams): __Observable<__StrictHttpResponse<BifrostConnector>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/connector-hybrid-extender/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BifrostConnector>;
      })
    );
  }
  /**
   * Update a Bifrost connector.
   *
   * Update a Bifrost connector.
   * @param params The `ConnectorsService.UpdateBifrostConnectorParams` containing the following parameters:
   *
   * - `id`: Specifies the id of connector.
   *
   * - `body`: Specifies the parameters to update a connector.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateBifrostConnector(params: ConnectorsService.UpdateBifrostConnectorParams): __Observable<BifrostConnector> {
    return this.UpdateBifrostConnectorResponse(params).pipe(
      __map(_r => _r.body as BifrostConnector)
    );
  }

  /**
   * Delete a Bifrost connector.
   *
   * Delete a Bifrost connector.
   * @param params The `ConnectorsService.DeleteBifrostConnectorParams` containing the following parameters:
   *
   * - `id`: Specifies the id of connector.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteBifrostConnectorResponse(params: ConnectorsService.DeleteBifrostConnectorParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/connector-hybrid-extender/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a Bifrost connector.
   *
   * Delete a Bifrost connector.
   * @param params The `ConnectorsService.DeleteBifrostConnectorParams` containing the following parameters:
   *
   * - `id`: Specifies the id of connector.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteBifrostConnector(params: ConnectorsService.DeleteBifrostConnectorParams): __Observable<null> {
    return this.DeleteBifrostConnectorResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get Rigel connectors on the cluster.
   *
   * Get Rigel connectors on the cluster.
   * @param params The `ConnectorsService.GetRigelConnectorParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the id of the tenant which the connector belongs to.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `names`: Specifies the name of the connectors.
   *
   * - `ids`: Specifies the id of the connector.
   *
   * - `connectionId`: Specifies the Id of the connection which the connector belongs to.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRigelConnectorResponse(params: ConnectorsService.GetRigelConnectorParams): __Observable<__StrictHttpResponse<RigelConnectors>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.names || []).forEach(val => {if (val != null) __params = __params.append('names', val.toString())});
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.connectionId != null) __params = __params.set('connectionId', params.connectionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/connector-rigel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RigelConnectors>;
      })
    );
  }
  /**
   * Get Rigel connectors on the cluster.
   *
   * Get Rigel connectors on the cluster.
   * @param params The `ConnectorsService.GetRigelConnectorParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the id of the tenant which the connector belongs to.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `names`: Specifies the name of the connectors.
   *
   * - `ids`: Specifies the id of the connector.
   *
   * - `connectionId`: Specifies the Id of the connection which the connector belongs to.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRigelConnector(params: ConnectorsService.GetRigelConnectorParams): __Observable<RigelConnectors> {
    return this.GetRigelConnectorResponse(params).pipe(
      __map(_r => _r.body as RigelConnectors)
    );
  }

  /**
   * Create a Rigel connector on the cluster.
   *
   * Create a Rigel connector on the cluster.
   * @param params The `ConnectorsService.CreateRigelConnectorParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a connector.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateRigelConnectorResponse(params: ConnectorsService.CreateRigelConnectorParams): __Observable<__StrictHttpResponse<RigelConnector>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/connector-rigel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RigelConnector>;
      })
    );
  }
  /**
   * Create a Rigel connector on the cluster.
   *
   * Create a Rigel connector on the cluster.
   * @param params The `ConnectorsService.CreateRigelConnectorParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create a connector.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateRigelConnector(params: ConnectorsService.CreateRigelConnectorParams): __Observable<RigelConnector> {
    return this.CreateRigelConnectorResponse(params).pipe(
      __map(_r => _r.body as RigelConnector)
    );
  }

  /**
   * Get a Rigel connector by the id.
   *
   * Get a Rigel connector by the id.
   * @param params The `ConnectorsService.GetRigelConnectorByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of connector.
   *
   * - `tenantId`: Specifies the id of the tenant which the connector belongs to.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRigelConnectorByIdResponse(params: ConnectorsService.GetRigelConnectorByIdParams): __Observable<__StrictHttpResponse<RigelConnector>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/connector-rigel/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RigelConnector>;
      })
    );
  }
  /**
   * Get a Rigel connector by the id.
   *
   * Get a Rigel connector by the id.
   * @param params The `ConnectorsService.GetRigelConnectorByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of connector.
   *
   * - `tenantId`: Specifies the id of the tenant which the connector belongs to.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRigelConnectorById(params: ConnectorsService.GetRigelConnectorByIdParams): __Observable<RigelConnector> {
    return this.GetRigelConnectorByIdResponse(params).pipe(
      __map(_r => _r.body as RigelConnector)
    );
  }

  /**
   * Update a Rigel connector.
   *
   * Update a Rigel connector.
   * @param params The `ConnectorsService.UpdateRigelConnectorParams` containing the following parameters:
   *
   * - `id`: Specifies the id of connector.
   *
   * - `body`: Specifies the parameters to update a connector.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateRigelConnectorResponse(params: ConnectorsService.UpdateRigelConnectorParams): __Observable<__StrictHttpResponse<RigelConnector>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/connector-rigel/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RigelConnector>;
      })
    );
  }
  /**
   * Update a Rigel connector.
   *
   * Update a Rigel connector.
   * @param params The `ConnectorsService.UpdateRigelConnectorParams` containing the following parameters:
   *
   * - `id`: Specifies the id of connector.
   *
   * - `body`: Specifies the parameters to update a connector.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateRigelConnector(params: ConnectorsService.UpdateRigelConnectorParams): __Observable<RigelConnector> {
    return this.UpdateRigelConnectorResponse(params).pipe(
      __map(_r => _r.body as RigelConnector)
    );
  }

  /**
   * Delete a Rigel connector.
   *
   * Delete a Rigel connector.
   * @param params The `ConnectorsService.DeleteRigelConnectorParams` containing the following parameters:
   *
   * - `id`: Specifies the id of connector.
   *
   * - `body`: Specifies the parameters to delete a connector.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteRigelConnectorResponse(params: ConnectorsService.DeleteRigelConnectorParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/connector-rigel/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a Rigel connector.
   *
   * Delete a Rigel connector.
   * @param params The `ConnectorsService.DeleteRigelConnectorParams` containing the following parameters:
   *
   * - `id`: Specifies the id of connector.
   *
   * - `body`: Specifies the parameters to delete a connector.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteRigelConnector(params: ConnectorsService.DeleteRigelConnectorParams): __Observable<null> {
    return this.DeleteRigelConnectorResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ConnectorsService {

  /**
   * Parameters for PerformConnectivityCheck
   */
  export interface PerformConnectivityCheckParams {

    /**
     * Cloud provider - kAws or kAzure.
     */
    cloudProvider: 'kAws' | 'kAzure';

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetBifrostConnector
   */
  export interface GetBifrostConnectorParams {

    /**
     * Specifies the id of the tenant which the connector belongs to.
     */
    tenantId?: null | string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the name of the connectors.
     */
    names?: null | Array<string>;

    /**
     * Specifies the id of the connectors.
     */
    ids?: null | Array<string>;

    /**
     * Specifies the Id of the connection which the connector belongs to.
     */
    connectionId?: null | number;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetBifrostConnectorById
   */
  export interface GetBifrostConnectorByIdParams {

    /**
     * Specifies the id of connector.
     */
    id: null | string;

    /**
     * Specifies the id of the tenant which the connector belongs to.
     */
    tenantId?: null | string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateBifrostConnector
   */
  export interface UpdateBifrostConnectorParams {

    /**
     * Specifies the id of connector.
     */
    id: null | string;

    /**
     * Specifies the parameters to update a connector.
     */
    body: UpdateBifrostConnectorRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteBifrostConnector
   */
  export interface DeleteBifrostConnectorParams {

    /**
     * Specifies the id of connector.
     */
    id: null | string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetRigelConnector
   */
  export interface GetRigelConnectorParams {

    /**
     * Specifies the id of the tenant which the connector belongs to.
     */
    tenantId?: null | string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the name of the connectors.
     */
    names?: null | Array<string>;

    /**
     * Specifies the id of the connector.
     */
    ids?: null | Array<number>;

    /**
     * Specifies the Id of the connection which the connector belongs to.
     */
    connectionId?: null | number;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateRigelConnector
   */
  export interface CreateRigelConnectorParams {

    /**
     * Specifies the parameters to create a connector.
     */
    body: CreateRigelConnectorRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetRigelConnectorById
   */
  export interface GetRigelConnectorByIdParams {

    /**
     * Specifies the id of connector.
     */
    id: null | number;

    /**
     * Specifies the id of the tenant which the connector belongs to.
     */
    tenantId?: null | string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateRigelConnector
   */
  export interface UpdateRigelConnectorParams {

    /**
     * Specifies the id of connector.
     */
    id: null | number;

    /**
     * Specifies the parameters to update a connector.
     */
    body: UpdateRigelConnectorRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteRigelConnector
   */
  export interface DeleteRigelConnectorParams {

    /**
     * Specifies the id of connector.
     */
    id: null | number;

    /**
     * Specifies the parameters to delete a connector.
     */
    body: DeleteRigelConnectorRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { ConnectorsService }
