/**
 * Enum for recover to options for recovery details.
 */
export enum RecoverToOptions {
  /**
   * This option will put the recovered object(s) back to their original source.
   */
  originalLocation,

  /**
   * This option will put the recovered object(s) to a new source.
   */
  newLocation,

  /**
   * This option will put the recovered object(s) into a new view.
   * Currently only used by NAS.
   */
  newView,

  /**
   * Option only available to kView.
   */
  originalView,

  /**
   * This option will allow instant recovery.
   */
  multiStageRestore = 'multiStageRestore',

  /**
   * This option will convert the mailbox items to PST and will be available
   * download.
   */
  convertToPst = 'convertToPst',

  /**
   * This option will prepare and serve one drive files.
   */
  download = 'download',

  /**
   * This option indicates download private chats workflow.
   */
  downloadChats = 'downloadChats',
}

/**
 * These recovery options are common to most flows.
 */
export type CommonRecoverToOptions = RecoverToOptions.originalLocation | RecoverToOptions.newLocation;
