import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { customDateTimeLabelFormats, HighchartsDataConfigService } from '@cohesity/helix';
import {
  azureColdStorageEntitlement,
  azureHotStorageEntitlement,
  IrisContextService,
  rpaasColdStorageQuantity,
  rpaasWarmStorageQuantity,
} from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { Options, SeriesLineOptions } from 'highcharts';
import { ObservableInput } from 'ngx-observable-input';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { FortknoxStorageClass } from '../../../constants';
import { VaultUsageByStorageClass } from '../../../models';
import { StorageClassUsage } from '../rpaas-storage-class-usage/rpaas-storage-class-usage.component';


/**
 * This historical vault usage data in a dashboard card
 *
 * @example
 *  <dg-sc-rpaas-data-card [usageData]="usageData"></dg-sc-rpaas-data-card>
 */
@Component({
  selector: 'dg-sc-rpaas-data-card',
  templateUrl: './rpaas-data-card.component.html',
  styleUrls: ['./rpaas-data-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RpaasDataCardComponent implements OnInit {
  /**
   * The usage stats for the dashboard.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('usageData') usageData$: Observable<VaultUsageByStorageClass>;

  /**
   * Notify parent to visit External Targets report page.
   */
  @Output() readonly gotoReport = new EventEmitter<null>();

  /**
   * Show warm subscription information
   */
  @Input() showWarmSubscription = true;

  /**
   * Show cold subscription information
   */
  @Input() showColdSubscription = true;

  /**
   * Warm storage charts data and subscription info
   */
  warmStorageData$: Observable<StorageClassUsage>;

  /**
   * Cold storage charts data and subscription info
   */
  coldStorageData$: Observable<StorageClassUsage>;

  constructor(
    private highchartConfigService: HighchartsDataConfigService,
    private irisContext: IrisContextService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    const dataConfig = this.highchartConfigService.getConfigForDataType('bytes');
    const awsColdStorageCapacity = rpaasColdStorageQuantity(this.irisContext.irisContext) || 0;
    const azureColdStorageCapacity = azureColdStorageEntitlement(this.irisContext.irisContext)?.quantity || 0;
    const awsWarmStorageCapacity = rpaasWarmStorageQuantity(this.irisContext.irisContext) || 0;
    const azureWarmStorageCapacity = azureHotStorageEntitlement(this.irisContext.irisContext)?.quantity || 0;

    const options: Options = {
      chart: {
        spacing: [5, 0, 0, 0],
      },
      legend: {
        enabled: false,
      },
      yAxis: {
        visible: true,
        title: {
          enabled: false,
        } as any,
      },
      tooltip: {
        pointFormatter: dataConfig.tooltipPointFormatter,
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          ...customDateTimeLabelFormats,
          millisecond: customDateTimeLabelFormats.day,
        },
        tickLength: 0,
        showFirstLabel: true,
        showLastLabel: true,
      },
    };

    (options.yAxis as Highcharts.YAxisOptions).labels = {
      formatter: dataConfig.axisLabelFormatter,
    };

    (options.yAxis as Highcharts.YAxisOptions).tickPositioner = dataConfig.tickPositioner;

    this.warmStorageData$ = this.usageData$.pipe(
      map(usageData => {
        const storageData: StorageClassUsage = {
          subscriptions: [],
          options: { ...options },
        };

        const storageClasses: FortknoxStorageClass[] = [];

        if (awsWarmStorageCapacity) {
          storageData.subscriptions.push({
            label: this.translate.instant('currentPlanRpaas', {
              plan: this.translate.instant(`rpaas.dashboard.AmazonS3StandardIA`),
              size: awsWarmStorageCapacity,
            }),
            storageClass: 'AmazonS3StandardIA',
            totalBytes: awsWarmStorageCapacity * Math.pow(1024, 4),
            usedBytes: usageData?.AmazonS3StandardIA?.totalUsage || usageData?.kAmazonS3StandardIA?.totalUsage || 0,
          });

          storageClasses.push('AmazonS3StandardIA', 'kAmazonS3StandardIA');
        }

        if (azureWarmStorageCapacity) {
          storageData.subscriptions.push({
            label: this.translate.instant('currentPlanRpaas', {
              plan: this.translate.instant(`rpaas.dashboard.AzureCoolBlob`),
              size: azureWarmStorageCapacity,
            }),
            storageClass: 'AzureCoolBlob',
            totalBytes: azureWarmStorageCapacity * Math.pow(1024, 4),
            usedBytes: usageData?.AzureCoolBlob?.totalUsage || 0,
          });

          storageClasses.push('AzureCoolBlob');
        }

        storageData.series = storageClasses
          .filter(storageClass => Boolean(usageData?.[storageClass]))
          .map(storageClass => {
            const warmData = usageData[storageClass].usageData.map(point => [
              point.timestampUsecs / 1000,
              point.usageBytes,
            ]);

            const warmSeries: SeriesLineOptions = {
              className: `series-${storageClass}`,
              type: 'line',
              data: warmData,
            };

            // show single dot otherwise chart looks empty with single data point
            if (warmData.length === 1) {
              warmSeries.marker = {
                enabled: true,
                radius: 2,
              };
            }

            return warmSeries;
          });

        // show axis on empty if every series data is empty
        if (storageData.series.every(series => series.data.length === 0)) {
          const now = new Date().getTime();

          storageData.options.xAxis = {
            ...storageData.options.xAxis,
            showEmpty: true,
            min: now,
            max: now,
          };

          storageData.options.yAxis = {
            ...storageData.options.yAxis,
            showEmpty: true,
            min: 0,
            max: 1_1024_1024_1024,
          };

          storageData.series.push({
            type: 'line',
            data: [null],
          });
        }

        return storageData;
      }),
      shareReplay(1)
    );

    this.coldStorageData$ = this.usageData$.pipe(
      map(usageData => {
        const storageData: StorageClassUsage = {
          subscriptions: [],
          options: { ...options },
        };

        const storageClasses: FortknoxStorageClass[] = [];

        if (awsColdStorageCapacity) {
          storageData.subscriptions.push({
            label: this.translate.instant('currentPlanRpaas', {
              plan: this.translate.instant(`rpaas.dashboard.AmazonS3Glacier`),
              size: awsColdStorageCapacity,
            }),
            storageClass: 'AmazonS3Glacier',
            totalBytes: awsColdStorageCapacity * Math.pow(1024, 4),
            usedBytes: usageData?.AmazonS3Glacier?.totalUsage || usageData?.kAmazonS3Glacier?.totalUsage || 0,
          });

          storageClasses.push('AmazonS3Glacier', 'kAmazonS3StandardIA');
        }

        if (azureColdStorageCapacity) {
          storageData.subscriptions.push({
            label: this.translate.instant('currentPlanRpaas', {
              plan: this.translate.instant(`rpaas.dashboard.AzureArchiveBlob`),
              size: azureColdStorageCapacity,
            }),
            storageClass: 'AzureArchiveBlob',
            totalBytes: azureColdStorageCapacity * Math.pow(1024, 4),
            usedBytes: usageData?.AzureArchiveBlob?.totalUsage || 0,
          });

          storageClasses.push('AzureArchiveBlob');
        }

        storageData.series = storageClasses
          .filter(storageClass => Boolean(usageData?.[storageClass]))
          .map(storageClass => {
            const coldData = usageData[storageClass].usageData.map(point => [
              point.timestampUsecs / 1000,
              point.usageBytes,
            ]);

            const coldSeries: SeriesLineOptions = {
              className: `series-${storageClass}`,
              type: 'line',
              data: coldData,
            };

            // show single dot otherwise chart looks empty with single data point
            if (coldData.length === 1) {
              coldSeries.marker = {
                enabled: true,
                radius: 2,
              };
            }

            return coldSeries;
          });

        // show axis on empty chart if every series data is empty
        if (storageData.series.every(series => series.data.length === 0)) {
          const now = new Date().getTime();

          storageData.options.xAxis = {
            ...storageData.options.xAxis,
            showEmpty: true,
            min: now,
            max: now,
          };

          storageData.options.yAxis = {
            ...storageData.options.yAxis,
            showEmpty: true,
            min: 0,
            max: 1_1024_1024_1024,
          };

          storageData.series.push({
            type: 'line',
            data: [null],
          });
        }

        return storageData;
      }),
      shareReplay(1)
    );
  }
}
