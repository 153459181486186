import { Component, Input } from '@angular/core';
import { AjsStateManagementService } from 'src/app/shared';

/**
 * An optional header component to show tab info on a recovery search page
 *
 * @example
 * <coh-recover-search-page title="Recover stuff">
 *   <coh-recover-search-header>
 *     <nav mat-tab-nav-bar>
 *       ...
 *     </nav>
 *   </coh-recover-search-header>
 *   ...
 * </coh-recover-search-page>
 */
@Component({
  selector: 'coh-recover-search-header',
  template: '<ng-content></ng-content>',
})
export class RecoverSearchHeaderComponent {}
/**
 * This is a page layout for recovery search pages, it displays a cog-page with a takeover theme, the
 * page title, and provides support for navigating to the previous page.
 *
 * @example
 * <coh-recover-search-page title="Recover stuff">
 *   header content
 * </coh-recover-search-page>
 */
@Component({
  selector: 'coh-recover-search-page',
  templateUrl: './recover-search-page.component.html',
  styleUrls: ['./recover-search-page.component.scss'],
})
export class RecoverSearchPageComponent {
  /**
   * The page title to show.
   */
  @Input() title: string;

  constructor(private ajsStateManagementService: AjsStateManagementService) {}

  /**
   * Goes to the previous page
   */
  public goToPreviousPage() {
    this.ajsStateManagementService.goToPreviousState('recover');
  }
}
