// Module/Component: Cloud Edition Manager

;(function(angular) {
  'use strict';

  var inProgressStates =
    ['SCHEDULED', 'IN_PROGRESS', 'CANCELLING', 'DELETING'];

  angular.module('C.cloudEditionManager', ['C.cloudEditionManagerService'])
    .config(cloudEditionManagerConfigFn)
    .component('cloudEditionManager', {
      templateUrl: 'app/platform/cloud-edition-manager/manager.html',
      controller: 'cloudEditionManagerCtrl',
    })
    .controller('cloudEditionManagerCtrl', cloudEditionManagerCtrlFn);

  function cloudEditionManagerConfigFn($stateProvider) {
    var canAccessCondition = [
      'CLUSTER_UPGRADE',
      '$root.FEATURE_FLAGS.cloudEditionDeploy',
      '$root.basicClusterInfo.mcmMode',
      '!$root.basicClusterInfo.mcmOnPremMode',
      '!$root.isHeliosTenantUser()'
    ].join(' && ');

    $stateProvider
      .state('cloud-edition-manager', {
        allClustersSupport: {
          singleClusterState: 'cloud-edition-manager',
          allClustersState: 'cloud-edition-manager',
          globalContext: true,
        },
        canAccess: canAccessCondition,
        component: 'cloudEditionManager',
        help: 'cloud_edition_manager',
        title: 'Cloud Edition Manager',
        url: '^/platform/cloud-edition',
      })
      .state('cloud-edition-new', {
        allClustersSupport: {
          singleClusterState: 'cloud-edition-new',
          allClustersState: 'cloud-edition-new',
          globalContext: true,
        },
        canAccess: canAccessCondition,
        parentState: 'cloud-edition-manager',
        component: 'cloudEditionNew',
        help: 'cloud_edition_manager',
        params: {
          type: { type: 'string'},
        },
        resolve: {
          type: function getType($transition$) {
            return $transition$.params().type;
          },
        },
        title: 'Deploy Cloud Edition',
        url: '^/platform/deploy-cloud-edition/{type}',
      })
      .state('cloud-edition-view', {
        allClustersSupport: {
          singleClusterState: 'cloud-edition-view',
          allClustersState: 'cloud-edition-view',
          globalContext: true,
        },
        canAccess: canAccessCondition,
        parentState: 'cloud-edition-manager',
        component: 'cloudEditionView',
        help: 'cloud_edition_manager',
        resolve: {
          id: function getID($transition$) {
            return $transition$.params().id;
          },
        },
        title: 'View Cloud Edition',
        url: '^/platform/view-cloud-edition',
      })
      .state('cloud-edition-view.details', {
        allClustersSupport: {
          singleClusterState: 'cloud-edition-view.details',
          allClustersState: 'cloud-edition-view.details',
          globalContext: true,
        },
        canAccess: canAccessCondition,
        parentState: 'cloud-edition-view',
        component: 'cloudEditionViewDetails',
        help: 'cloud_edition_manager',
        params: {
          id: {type: 'string'},
          cloudEdition: null,
        },
        resolve: {
          id: function getID($transition$) {
            return $transition$.params().id;
          },
          cloudEdition: function getCloudEdition($transition$) {
            return $transition$.params().cloudEdition;
          },
        },
        title: 'View Cloud Edition',
        url: '^/platform/view-cloud-edition/{id}/details',
      })
      .state('cloud-edition-view.logs', {
        allClustersSupport: {
          singleClusterState: 'cloud-edition-view.logs',
          allClustersState: 'cloud-edition-view.logs',
          globalContext: true,
        },
        canAccess: canAccessCondition,
        parentState: 'cloud-edition-view',
        component: 'cloudEditionViewLogs',
        help: 'cloud_edition_manager',
        params: {
          id: {type: 'string'},
          cloudEdition: null,
        },
        resolve: {
          id: function getID($transition$) {
            return $transition$.params().id;
          },
          cloudEdition: function getCloudEdition($transition$) {
            return $transition$.params().cloudEdition;
          },
        },
        title: 'View Cloud Edition',
        url: '^/platform/view-cloud-edition/{id}/logs',
      });
  }

  /**
   * @ngdoc component
   * @name C.cloudEditionManager:cloudEditionManager
   * @function
   *
   * @description
   * Cloud Edition manager for Cohesity DataPlatform for cloud
   */
  function cloudEditionManagerCtrlFn(_, $q, evalAJAX, ENV_GROUPS, FEATURE_FLAGS,
    CloudEditionManagerService, SlideModalService, PollTaskStatus) {

    var $ctrl = this;
    var isDoneDeferred = $q.defer();

    _.assign($ctrl, {
      // properties
      initialized: false,
      inProgressJobs: [],
      isLoading: true,
      sourcesList:
        _.map(_.sortBy(_.union(ENV_GROUPS.cloudEditionSources, ['aws-us-gov'])),
          function eachSource(src) {
            var translateKey = src;
            var featureFlagKey = src;

            if (src === 'aws-us-gov') {
              translateKey = 'awsGovCloud';
              featureFlagKey = 'awsGov';
            }

            return {
              translateKey: translateKey,
              state: 'cloud-edition-new',
              stateParams: {
                type: src,
              },
              parentState: 'admin',
              disabled: !FEATURE_FLAGS[featureFlagKey + 'CloudEdition'],
            };
        }),

      // methods
      $onInit: $onInit,
      $onDestroy: $onDestroy,
      deleteCloudEdition: deleteCloudEdition,
      getCloudEditionIconClass:
        CloudEditionManagerService.getCloudEditionIconClass,
      getProgressPercent: getProgressPercent,
      showCloudEditionLogs: showCloudEditionLogs,
    });

    /**
     * Activate the controller
     *
     * @method   $onInit
     */
    function $onInit() {
      PollTaskStatus.createPoller({
        interval: 10,
        isDonePromise: isDoneDeferred.promise,
        iteratorFn: getData,
        maxRetries: 10,
      });
    }

    /**
     * fetch the cloudEditions data
     *
     * @method  getData()
     */
    function getData() {
      var promises = {};

      // initial call
      if (!$ctrl.initialized) {
        promises.all = CloudEditionManagerService.getAllCloudEditionJobs();
        $ctrl.initialized = true;
      // subsequent calls
      } else {
        _.each($ctrl.inProgressJobs, function eachJobKey(jobId) {
          promises[jobId] = CloudEditionManagerService.getCloudEditionJob(jobId);
        });
      }

      return $q.all(promises)
        .then(function gotAllCloudEditionJobs(resp) {

          // first call response
          if (resp.all) {
            _.each(resp.all, function eachJob(job) {

              // collect all in_progress jobs
              if (inProgressStates.includes(job.status)) {
                $ctrl.inProgressJobs.push(job.jobId);
              }
            });

            $ctrl.cloudEditions = resp.all;

            // subsequent calls response
          } else {
            _.each(resp, function eachJob(job, key) {
              var foundJobIndex =
                _.findIndex($ctrl.cloudEditions, ['jobId', key]);

              // assign new properties
              _.assign($ctrl.cloudEditions[foundJobIndex], job);

              if (!inProgressStates.includes(job.status)) {
                $ctrl.inProgressJobs =
                  _.without($ctrl.inProgressJobs, job.jobId);
              }
            });
          }
        }, evalAJAX.errorMessage)
        .finally(function getAllCloudEditionJobsFinally() {
          $ctrl.isLoading = false;
        });
    }

    /**
     *  Delete a deployed Cloud Edition
     *
     * @method  deleteCloudEdition
     * @param   {Object}  cloudEdition  The cloudEdition object
     */
    function deleteCloudEdition(cloudEdition) {
      var modalConfig = {
        resolve: {
          innerComponent: 'cloudEditionDelete',
          actionButtonKey: false,
          closeButtonKey: false,
          titleKey: cloudEdition.canDelete ?
            'cloudEdition.deleteCloudEditionTitle' :
              'cloudEdition.cancelCloudEditionTitle',
          bindings: {
            cloudEdition: cloudEdition,
          },
        },
        autoHeight: true,
      };

      SlideModalService.newModal(modalConfig).then(function onDelete(resp) {
        $ctrl.cloudEditions.find(function findCloudEdition(ce, i) {
          if (ce.jobId === resp.jobId) {
            $ctrl.cloudEditions[i].status = resp.status;

            if (inProgressStates.includes(resp.status)) {
              $ctrl.inProgressJobs.push(resp.jobId);
            }

            return true;
          }
        });
      });
    }

    /**
     * Show detailed logs of a Cloud Edition Deploy Job
     *
     * @method  showCloudEditionLogs
     * @param   {Object}  cloudEdition  The cloudEdition object
     */
    function showCloudEditionLogs(cloudEdition) {
      cloudEdition.expanded = !cloudEdition.expanded;

      if (cloudEdition.expanded && !cloudEdition.details) {
        cloudEdition.loadingDetails = true;

        CloudEditionManagerService.getCloudEditionJob(cloudEdition.jobId)
          .then(function gotCloudEditionJob(resp) {
            cloudEdition.details = resp.details;
          }, evalAJAX.errorMessage)
          .finally(function getCloudEditionJobFinally() {
            cloudEdition.loadingDetails = false;
          });
      }
    }

    /**
     * Calculate and return the progress percen of a cloudEdition Job
     *
     * @param   {Object}  ce  The cloudEdition object for which progress percent
     *                        is to be calculated
     * @return  {Number}  The progress percent
     */
    function getProgressPercent(ce) {
      return Math.round(((ce.currentStep - 1)/ce.totalSteps)*100 || 0);
    }

    /**
     * on component destroy end polling for data.
     *
     * @method   $onDestroy
     */
    function $onDestroy() {
      isDoneDeferred.resolve();
    }
  }

})(angular);
