import { DatePipe } from '@angular/common';
import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyMenuTrigger as MatMenuTrigger, LegacyMenuPositionX as MenuPositionX } from '@angular/material/legacy-menu';
import { TranslateService } from '@ngx-translate/core';

import { MaxDateOptions, MinDateOptions } from '../date-utils/date-options';
import { DateRange, getDateRange, Timeframe } from '../date-utils/date-range';
import { defaultTimeframeOptions } from '../timeframe-picker';

@Component({
  selector: 'cog-date-range-input',
  templateUrl: './date-range-input.component.html',
  styleUrls: ['./date-range-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangeInputComponent),
      multi: true,
    },
  ],
})
export class DateRangeInputComponent implements ControlValueAccessor, OnInit {
  /**
   * Reference to MatMenuTrigger instance.
   */
  @ViewChild(MatMenuTrigger, { static: false }) matTrigger: MatMenuTrigger;

  /**
   * Form control supplied to date range picker
   */
  rangeControl = new FormControl<DateRange<Date>>(getDateRange(Timeframe.Past7Days));

  /**
   * Controls if the input is disabled or not
   */
  @Input() disabled = false;

  /**
   * Used to reset input value when range selection changes are cancelled
   */
  appliedValue: DateRange<Date>;

  /**
   * Set value of the date range
   */
  @Input() set value(dateRange: DateRange<Date>) {
    if (dateRange) {
      this.appliedValue = { ...dateRange };
    }
  };

  /**
   * Indicates a matMenu xPosition value to be used if auto positioning
   * isn't working as desired/expected.
   */
  @Input() xPosition: MenuPositionX = 'after';

  /**
   * Displays timeframe selector.
   */
  @Input() useTimeframe = true;

  /**
   * Available timeframe options.
   */
  @Input() timeframeOptions = defaultTimeframeOptions;

  /**
   * If provided, user will be able to select an option with this label, which
   * will present an additional option having this label and providing no date
   * range in the filter value. NOTE: Requires @Input() useTimeframe to be true.
   */
  @Input() noDateOptionLabel = '';

  /**
   * does not allow guessing of time frame
   */
  @Input() enableGuessTimeframe = true;

  /**
   * If enabled then allow time selection for selected start and end date range.
   */
  @Input() enablePreciseDateRangeSelection = true;

  /**
   * Sets max number of days selectable by the user.
   */
  @Input() maxRange: number;

  /**
   * earliest date in the past which can be allowed
   */
  @Input() minDate: MinDateOptions = MinDateOptions.Forever;

  /**
   * if selection in the future is to be allowed (after the current moment)
   */
  @Input() maxDate: MaxDateOptions = MaxDateOptions.Today;

  /**
   * Hides the Custom time frame option.
   */
  @Input() noCustomRange = false;

  /**
   * emits value changes whenever apply button is clicked
   */
  @Output() private valueChange = new EventEmitter<DateRange<Date>>();


  /**
   * Stringified value of the range value.
   */
  get currentRange(): string {
    const start = this.datePipe.transform(this.appliedValue?.start, 'short') || this.translateService.instant('startDate');
    const end = this.datePipe.transform(this.appliedValue?.end, 'short') || this.translateService.instant('endDate');
    return `${start} - ${end}`;
  }

  constructor(
    private datePipe: DatePipe,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    // add "Custom" radio button option to timeframe selector
    if (!this.noCustomRange) {
      this.timeframeOptions = [
        ...this.timeframeOptions,
        Timeframe.Custom,
      ];
    }

    if (this.useTimeframe && this.noDateOptionLabel) {
      this.timeframeOptions = [
        ...this.timeframeOptions,
        Timeframe.NoDate,
      ];
    }
  }

  /**
   * Apply changes to the date range selection
   */
  apply() {
    this.appliedValue = { ...this.rangeControl.value };
    this.onChange(this.rangeControl.value);
    this.valueChange.emit(this.appliedValue);
    this.dismiss();
  }

  /**
   * Cancel changes to the date range selection
   */
  cancel() {
    this.dismiss();
  }

  /**
   * Close date range picker
   */
  private dismiss() {
    if (this.matTrigger) {
      this.matTrigger.closeMenu();
    }
  }

  /**
   * Open date range picker
   */
  openCalendar() {
    this.rangeControl.setValue({ ...this.appliedValue });
    if (this.matTrigger && !this.disabled) {
      this.matTrigger.openMenu();
    }
  }

  /**
   * Handles form click. Primary purpose is to prevent menu dialog from closing.
   */
  formClick(event: MouseEvent) {
    event.stopPropagation();
  }

  /**
   * Handles form keydown. Primary purpose is to prevent menu dialog from closing.
   */
  formKeyDown(event: KeyboardEvent) {
    if (['Tab', 'Enter', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.code)) {
      event.stopPropagation();
    }
  }

  /**
   * Update the view on model changes is request programmatic via forms API.
   *
   * This method is called by the forms API to write to the view when programmatic changes from model to view are
   * requested.
   *
   * @param   dateRange   The new date range value.
   */
  writeValue(dateRange: DateRange<Date>) {
    if (dateRange) {
      this.appliedValue = { ...dateRange };
      this.rangeControl.setValue(dateRange);
    }
  }

  /**
   * This method is called by the forms API on initialization to update the form
   * model when values propagate from the view to the model.
   */
  registerOnChange(fn: any) {
    const orgOnChange = this.onChange;

    this.onChange = (...args) => {
      // calling the original on changes callback defined below which will emit the changes output event.
      orgOnChange.call(this, ...args);

      // calling to on changes fn provided by FormControl.
      fn.call(this, ...args);
    };
  }

  /**
   * Registers a callback function is called by the forms API on initialization
   * to update the form model on blur.
   */
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  /**
   * The placeholder method populated by forms API registerOnChange method which
   * is used to update changes from view to modal.
   */
  onChange = (_dateRange: DateRange<Date>) => { };

  /**
   * The placeholder method populated by forms API registerOnTouched method which
   * is used to mark a form field should be considered blurred or "touched".
   */
  onTouched = () => { };

  /**
   * Function that is called by the forms API when the control status changes to
   * or from 'DISABLED'. Depending on the status, it enables or disables the
   * appropriate DOM element.
   */
  setDisabledState(_isDisabled: boolean) {
    this.disabled = _isDisabled;
  }
}
