// Generated from libs/grammar/azure-volume-exclusions/Azure.g4 by ANTLR 4.9.0-SNAPSHOT


import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { FailedPredicateException } from "antlr4ts/FailedPredicateException";
import { NotNull } from "antlr4ts/Decorators";
import { NoViableAltException } from "antlr4ts/NoViableAltException";
import { Override } from "antlr4ts/Decorators";
import { Parser } from "antlr4ts/Parser";
import { ParserRuleContext } from "antlr4ts/ParserRuleContext";
import { ParserATNSimulator } from "antlr4ts/atn/ParserATNSimulator";
import { ParseTreeListener } from "antlr4ts/tree/ParseTreeListener";
import { ParseTreeVisitor } from "antlr4ts/tree/ParseTreeVisitor";
import { RecognitionException } from "antlr4ts/RecognitionException";
import { RuleContext } from "antlr4ts/RuleContext";
//import { RuleVersion } from "antlr4ts/RuleVersion";
import { TerminalNode } from "antlr4ts/tree/TerminalNode";
import { Token } from "antlr4ts/Token";
import { TokenStream } from "antlr4ts/TokenStream";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";

import { AzureListener } from "./AzureListener";
import { AzureVisitor } from "./AzureVisitor";


export class AzureParser extends Parser {
	public static readonly COMMA = 1;
	public static readonly EQ = 2;
	public static readonly NOT_EQ = 3;
	public static readonly OPEN_PAREN = 4;
	public static readonly CLOSE_PAREN = 5;
	public static readonly K_AND = 6;
	public static readonly K_IN = 7;
	public static readonly K_NOT = 8;
	public static readonly K_OR = 9;
	public static readonly IDENTIFIER = 10;
	public static readonly SPACES = 11;
	public static readonly RULE_parse = 0;
	public static readonly RULE_expr = 1;
	public static readonly RULE_attributes = 2;
	public static readonly RULE_comparisonOperator = 3;
	public static readonly RULE_rangeOperator = 4;
	public static readonly RULE_logicalOperator = 5;
	public static readonly RULE_singleArg = 6;
	public static readonly RULE_multiArg = 7;
	// tslint:disable:no-trailing-whitespace
	public static readonly ruleNames: string[] = [
		"parse", "expr", "attributes", "comparisonOperator", "rangeOperator",
		"logicalOperator", "singleArg", "multiArg",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, "','", "'='", "'!='", "'('", "')'",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, "COMMA", "EQ", "NOT_EQ", "OPEN_PAREN", "CLOSE_PAREN", "K_AND",
		"K_IN", "K_NOT", "K_OR", "IDENTIFIER", "SPACES",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(AzureParser._LITERAL_NAMES, AzureParser._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return AzureParser.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace

	// @Override
	public get grammarFileName(): string { return "Azure.g4"; }

	// @Override
	public get ruleNames(): string[] { return AzureParser.ruleNames; }

	// @Override
	public get serializedATN(): string { return AzureParser._serializedATN; }

	protected createFailedPredicateException(predicate?: string, message?: string): FailedPredicateException {
		return new FailedPredicateException(this, predicate, message);
	}

	constructor(input: TokenStream) {
		super(input);
		this._interp = new ParserATNSimulator(AzureParser._ATN, this);
	}
	// @RuleVersion(0)
	public parse(): ParseContext {
		let _localctx: ParseContext = new ParseContext(this._ctx, this.state);
		this.enterRule(_localctx, 0, AzureParser.RULE_parse);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 17;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (_la === AzureParser.OPEN_PAREN || _la === AzureParser.IDENTIFIER) {
				{
				this.state = 16;
				this.expr(0);
				}
			}

			this.state = 19;
			this.match(AzureParser.EOF);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public expr(): ExprContext;
	public expr(_p: number): ExprContext;
	// @RuleVersion(0)
	public expr(_p?: number): ExprContext {
		if (_p === undefined) {
			_p = 0;
		}

		let _parentctx: ParserRuleContext = this._ctx;
		let _parentState: number = this.state;
		let _localctx: ExprContext = new ExprContext(this._ctx, _parentState);
		let _prevctx: ExprContext = _localctx;
		let _startState: number = 2;
		this.enterRecursionRule(_localctx, 2, AzureParser.RULE_expr, _p);
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 36;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 1, this._ctx) ) {
			case 1:
				{
				_localctx = new SingleArgExpContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;

				this.state = 22;
				(_localctx as SingleArgExpContext)._attr = this.attributes();
				this.state = 23;
				(_localctx as SingleArgExpContext)._op = this.comparisonOperator();
				this.state = 24;
				(_localctx as SingleArgExpContext)._arg = this.singleArg();
				}
				break;

			case 2:
				{
				_localctx = new MultiArgsExpContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 26;
				(_localctx as MultiArgsExpContext)._attr = this.attributes();
				this.state = 27;
				(_localctx as MultiArgsExpContext)._op = this.rangeOperator();
				this.state = 28;
				this.match(AzureParser.OPEN_PAREN);
				this.state = 29;
				(_localctx as MultiArgsExpContext)._arg = this.multiArg();
				this.state = 30;
				this.match(AzureParser.CLOSE_PAREN);
				}
				break;

			case 3:
				{
				_localctx = new ParenExpContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 32;
				this.match(AzureParser.OPEN_PAREN);
				this.state = 33;
				(_localctx as ParenExpContext)._exp = this.expr(0);
				this.state = 34;
				this.match(AzureParser.CLOSE_PAREN);
				}
				break;
			}
			this._ctx._stop = this._input.tryLT(-1);
			this.state = 44;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 2, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					if (this._parseListeners != null) {
						this.triggerExitRuleEvent();
					}
					_prevctx = _localctx;
					{
					{
					_localctx = new AndOrExpContext(new ExprContext(_parentctx, _parentState));
					(_localctx as AndOrExpContext)._left = _prevctx;
					this.pushNewRecursionContext(_localctx, _startState, AzureParser.RULE_expr);
					this.state = 38;
					if (!(this.precpred(this._ctx, 4))) {
						throw this.createFailedPredicateException("this.precpred(this._ctx, 4)");
					}
					this.state = 39;
					(_localctx as AndOrExpContext)._op = this.logicalOperator();
					this.state = 40;
					(_localctx as AndOrExpContext)._right = this.expr(5);
					}
					}
				}
				this.state = 46;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 2, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.unrollRecursionContexts(_parentctx);
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public attributes(): AttributesContext {
		let _localctx: AttributesContext = new AttributesContext(this._ctx, this.state);
		this.enterRule(_localctx, 4, AzureParser.RULE_attributes);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 47;
			this.match(AzureParser.IDENTIFIER);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public comparisonOperator(): ComparisonOperatorContext {
		let _localctx: ComparisonOperatorContext = new ComparisonOperatorContext(this._ctx, this.state);
		this.enterRule(_localctx, 6, AzureParser.RULE_comparisonOperator);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 49;
			_la = this._input.LA(1);
			if (!(_la === AzureParser.EQ || _la === AzureParser.NOT_EQ)) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public rangeOperator(): RangeOperatorContext {
		let _localctx: RangeOperatorContext = new RangeOperatorContext(this._ctx, this.state);
		this.enterRule(_localctx, 8, AzureParser.RULE_rangeOperator);
		try {
			this.state = 54;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case AzureParser.K_IN:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 51;
				this.match(AzureParser.K_IN);
				}
				break;
			case AzureParser.K_NOT:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 52;
				this.match(AzureParser.K_NOT);
				this.state = 53;
				this.match(AzureParser.K_IN);
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public logicalOperator(): LogicalOperatorContext {
		let _localctx: LogicalOperatorContext = new LogicalOperatorContext(this._ctx, this.state);
		this.enterRule(_localctx, 10, AzureParser.RULE_logicalOperator);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 56;
			_la = this._input.LA(1);
			if (!(_la === AzureParser.K_AND || _la === AzureParser.K_OR)) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public singleArg(): SingleArgContext {
		let _localctx: SingleArgContext = new SingleArgContext(this._ctx, this.state);
		this.enterRule(_localctx, 12, AzureParser.RULE_singleArg);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 58;
			this.match(AzureParser.IDENTIFIER);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public multiArg(): MultiArgContext {
		let _localctx: MultiArgContext = new MultiArgContext(this._ctx, this.state);
		this.enterRule(_localctx, 14, AzureParser.RULE_multiArg);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 60;
			this.singleArg();
			this.state = 65;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === AzureParser.COMMA) {
				{
				{
				this.state = 61;
				this.match(AzureParser.COMMA);
				this.state = 62;
				this.singleArg();
				}
				}
				this.state = 67;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public sempred(_localctx: RuleContext, ruleIndex: number, predIndex: number): boolean {
		switch (ruleIndex) {
		case 1:
			return this.expr_sempred(_localctx as ExprContext, predIndex);
		}
		return true;
	}
	private expr_sempred(_localctx: ExprContext, predIndex: number): boolean {
		switch (predIndex) {
		case 0:
			return this.precpred(this._ctx, 4);
		}
		return true;
	}

	public static readonly _serializedATN: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x03\rG\x04\x02\t" +
		"\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06\x04\x07\t" +
		"\x07\x04\b\t\b\x04\t\t\t\x03\x02\x05\x02\x14\n\x02\x03\x02\x03\x02\x03" +
		"\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03" +
		"\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x05\x03\'\n\x03\x03\x03\x03" +
		"\x03\x03\x03\x03\x03\x07\x03-\n\x03\f\x03\x0E\x030\v\x03\x03\x04\x03\x04" +
		"\x03\x05\x03\x05\x03\x06\x03\x06\x03\x06\x05\x069\n\x06\x03\x07\x03\x07" +
		"\x03\b\x03\b\x03\t\x03\t\x03\t\x07\tB\n\t\f\t\x0E\tE\v\t\x03\t\x02\x02" +
		"\x03\x04\n\x02\x02\x04\x02\x06\x02\b\x02\n\x02\f\x02\x0E\x02\x10\x02\x02" +
		"\x04\x03\x02\x04\x05\x04\x02\b\b\v\v\x02D\x02\x13\x03\x02\x02\x02\x04" +
		"&\x03\x02\x02\x02\x061\x03\x02\x02\x02\b3\x03\x02\x02\x02\n8\x03\x02\x02" +
		"\x02\f:\x03\x02\x02\x02\x0E<\x03\x02\x02\x02\x10>\x03\x02\x02\x02\x12" +
		"\x14\x05\x04\x03\x02\x13\x12\x03\x02\x02\x02\x13\x14\x03\x02\x02\x02\x14" +
		"\x15\x03\x02\x02\x02\x15\x16\x07\x02\x02\x03\x16\x03\x03\x02\x02\x02\x17" +
		"\x18\b\x03\x01\x02\x18\x19\x05\x06\x04\x02\x19\x1A\x05\b\x05\x02\x1A\x1B" +
		"\x05\x0E\b\x02\x1B\'\x03\x02\x02\x02\x1C\x1D\x05\x06\x04\x02\x1D\x1E\x05" +
		"\n\x06\x02\x1E\x1F\x07\x06\x02\x02\x1F \x05\x10\t\x02 !\x07\x07\x02\x02" +
		"!\'\x03\x02\x02\x02\"#\x07\x06\x02\x02#$\x05\x04\x03\x02$%\x07\x07\x02" +
		"\x02%\'\x03\x02\x02\x02&\x17\x03\x02\x02\x02&\x1C\x03\x02\x02\x02&\"\x03" +
		"\x02\x02\x02\'.\x03\x02\x02\x02()\f\x06\x02\x02)*\x05\f\x07\x02*+\x05" +
		"\x04\x03\x07+-\x03\x02\x02\x02,(\x03\x02\x02\x02-0\x03\x02\x02\x02.,\x03" +
		"\x02\x02\x02./\x03\x02\x02\x02/\x05\x03\x02\x02\x020.\x03\x02\x02\x02" +
		"12\x07\f\x02\x022\x07\x03\x02\x02\x0234\t\x02\x02\x024\t\x03\x02\x02\x02" +
		"59\x07\t\x02\x0267\x07\n\x02\x0279\x07\t\x02\x0285\x03\x02\x02\x0286\x03" +
		"\x02\x02\x029\v\x03\x02\x02\x02:;\t\x03\x02\x02;\r\x03\x02\x02\x02<=\x07" +
		"\f\x02\x02=\x0F\x03\x02\x02\x02>C\x05\x0E\b\x02?@\x07\x03\x02\x02@B\x05" +
		"\x0E\b\x02A?\x03\x02\x02\x02BE\x03\x02\x02\x02CA\x03\x02\x02\x02CD\x03" +
		"\x02\x02\x02D\x11\x03\x02\x02\x02EC\x03\x02\x02\x02\x07\x13&.8C";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!AzureParser.__ATN) {
			AzureParser.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(AzureParser._serializedATN));
		}

		return AzureParser.__ATN;
	}

}

export class ParseContext extends ParserRuleContext {
	public EOF(): TerminalNode { return this.getToken(AzureParser.EOF, 0); }
	public expr(): ExprContext | undefined {
		return this.tryGetRuleContext(0, ExprContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AzureParser.RULE_parse; }
	// @Override
	public enterRule(listener: AzureListener): void {
		if (listener.enterParse) {
			listener.enterParse(this);
		}
	}
	// @Override
	public exitRule(listener: AzureListener): void {
		if (listener.exitParse) {
			listener.exitParse(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AzureVisitor<Result>): Result {
		if (visitor.visitParse) {
			return visitor.visitParse(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ExprContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AzureParser.RULE_expr; }
	public copyFrom(ctx: ExprContext): void {
		super.copyFrom(ctx);
	}
}
export class AndOrExpContext extends ExprContext {
	public _left!: ExprContext;
	public _op!: LogicalOperatorContext;
	public _right!: ExprContext;
	public expr(): ExprContext[];
	public expr(i: number): ExprContext;
	public expr(i?: number): ExprContext | ExprContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExprContext);
		} else {
			return this.getRuleContext(i, ExprContext);
		}
	}
	public logicalOperator(): LogicalOperatorContext {
		return this.getRuleContext(0, LogicalOperatorContext);
	}
	constructor(ctx: ExprContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AzureListener): void {
		if (listener.enterAndOrExp) {
			listener.enterAndOrExp(this);
		}
	}
	// @Override
	public exitRule(listener: AzureListener): void {
		if (listener.exitAndOrExp) {
			listener.exitAndOrExp(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AzureVisitor<Result>): Result {
		if (visitor.visitAndOrExp) {
			return visitor.visitAndOrExp(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class SingleArgExpContext extends ExprContext {
	public _attr!: AttributesContext;
	public _op!: ComparisonOperatorContext;
	public _arg!: SingleArgContext;
	public attributes(): AttributesContext {
		return this.getRuleContext(0, AttributesContext);
	}
	public comparisonOperator(): ComparisonOperatorContext {
		return this.getRuleContext(0, ComparisonOperatorContext);
	}
	public singleArg(): SingleArgContext {
		return this.getRuleContext(0, SingleArgContext);
	}
	constructor(ctx: ExprContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AzureListener): void {
		if (listener.enterSingleArgExp) {
			listener.enterSingleArgExp(this);
		}
	}
	// @Override
	public exitRule(listener: AzureListener): void {
		if (listener.exitSingleArgExp) {
			listener.exitSingleArgExp(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AzureVisitor<Result>): Result {
		if (visitor.visitSingleArgExp) {
			return visitor.visitSingleArgExp(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class MultiArgsExpContext extends ExprContext {
	public _attr!: AttributesContext;
	public _op!: RangeOperatorContext;
	public _arg!: MultiArgContext;
	public OPEN_PAREN(): TerminalNode { return this.getToken(AzureParser.OPEN_PAREN, 0); }
	public CLOSE_PAREN(): TerminalNode { return this.getToken(AzureParser.CLOSE_PAREN, 0); }
	public attributes(): AttributesContext {
		return this.getRuleContext(0, AttributesContext);
	}
	public rangeOperator(): RangeOperatorContext {
		return this.getRuleContext(0, RangeOperatorContext);
	}
	public multiArg(): MultiArgContext {
		return this.getRuleContext(0, MultiArgContext);
	}
	constructor(ctx: ExprContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AzureListener): void {
		if (listener.enterMultiArgsExp) {
			listener.enterMultiArgsExp(this);
		}
	}
	// @Override
	public exitRule(listener: AzureListener): void {
		if (listener.exitMultiArgsExp) {
			listener.exitMultiArgsExp(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AzureVisitor<Result>): Result {
		if (visitor.visitMultiArgsExp) {
			return visitor.visitMultiArgsExp(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class ParenExpContext extends ExprContext {
	public _exp!: ExprContext;
	public OPEN_PAREN(): TerminalNode { return this.getToken(AzureParser.OPEN_PAREN, 0); }
	public CLOSE_PAREN(): TerminalNode { return this.getToken(AzureParser.CLOSE_PAREN, 0); }
	public expr(): ExprContext {
		return this.getRuleContext(0, ExprContext);
	}
	constructor(ctx: ExprContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: AzureListener): void {
		if (listener.enterParenExp) {
			listener.enterParenExp(this);
		}
	}
	// @Override
	public exitRule(listener: AzureListener): void {
		if (listener.exitParenExp) {
			listener.exitParenExp(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AzureVisitor<Result>): Result {
		if (visitor.visitParenExp) {
			return visitor.visitParenExp(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class AttributesContext extends ParserRuleContext {
	public IDENTIFIER(): TerminalNode { return this.getToken(AzureParser.IDENTIFIER, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AzureParser.RULE_attributes; }
	// @Override
	public enterRule(listener: AzureListener): void {
		if (listener.enterAttributes) {
			listener.enterAttributes(this);
		}
	}
	// @Override
	public exitRule(listener: AzureListener): void {
		if (listener.exitAttributes) {
			listener.exitAttributes(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AzureVisitor<Result>): Result {
		if (visitor.visitAttributes) {
			return visitor.visitAttributes(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ComparisonOperatorContext extends ParserRuleContext {
	public EQ(): TerminalNode | undefined { return this.tryGetToken(AzureParser.EQ, 0); }
	public NOT_EQ(): TerminalNode | undefined { return this.tryGetToken(AzureParser.NOT_EQ, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AzureParser.RULE_comparisonOperator; }
	// @Override
	public enterRule(listener: AzureListener): void {
		if (listener.enterComparisonOperator) {
			listener.enterComparisonOperator(this);
		}
	}
	// @Override
	public exitRule(listener: AzureListener): void {
		if (listener.exitComparisonOperator) {
			listener.exitComparisonOperator(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AzureVisitor<Result>): Result {
		if (visitor.visitComparisonOperator) {
			return visitor.visitComparisonOperator(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class RangeOperatorContext extends ParserRuleContext {
	public K_IN(): TerminalNode { return this.getToken(AzureParser.K_IN, 0); }
	public K_NOT(): TerminalNode | undefined { return this.tryGetToken(AzureParser.K_NOT, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AzureParser.RULE_rangeOperator; }
	// @Override
	public enterRule(listener: AzureListener): void {
		if (listener.enterRangeOperator) {
			listener.enterRangeOperator(this);
		}
	}
	// @Override
	public exitRule(listener: AzureListener): void {
		if (listener.exitRangeOperator) {
			listener.exitRangeOperator(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AzureVisitor<Result>): Result {
		if (visitor.visitRangeOperator) {
			return visitor.visitRangeOperator(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class LogicalOperatorContext extends ParserRuleContext {
	public K_AND(): TerminalNode | undefined { return this.tryGetToken(AzureParser.K_AND, 0); }
	public K_OR(): TerminalNode | undefined { return this.tryGetToken(AzureParser.K_OR, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AzureParser.RULE_logicalOperator; }
	// @Override
	public enterRule(listener: AzureListener): void {
		if (listener.enterLogicalOperator) {
			listener.enterLogicalOperator(this);
		}
	}
	// @Override
	public exitRule(listener: AzureListener): void {
		if (listener.exitLogicalOperator) {
			listener.exitLogicalOperator(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AzureVisitor<Result>): Result {
		if (visitor.visitLogicalOperator) {
			return visitor.visitLogicalOperator(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class SingleArgContext extends ParserRuleContext {
	public IDENTIFIER(): TerminalNode { return this.getToken(AzureParser.IDENTIFIER, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AzureParser.RULE_singleArg; }
	// @Override
	public enterRule(listener: AzureListener): void {
		if (listener.enterSingleArg) {
			listener.enterSingleArg(this);
		}
	}
	// @Override
	public exitRule(listener: AzureListener): void {
		if (listener.exitSingleArg) {
			listener.exitSingleArg(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AzureVisitor<Result>): Result {
		if (visitor.visitSingleArg) {
			return visitor.visitSingleArg(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class MultiArgContext extends ParserRuleContext {
	public singleArg(): SingleArgContext[];
	public singleArg(i: number): SingleArgContext;
	public singleArg(i?: number): SingleArgContext | SingleArgContext[] {
		if (i === undefined) {
			return this.getRuleContexts(SingleArgContext);
		} else {
			return this.getRuleContext(i, SingleArgContext);
		}
	}
	public COMMA(): TerminalNode[];
	public COMMA(i: number): TerminalNode;
	public COMMA(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(AzureParser.COMMA);
		} else {
			return this.getToken(AzureParser.COMMA, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return AzureParser.RULE_multiArg; }
	// @Override
	public enterRule(listener: AzureListener): void {
		if (listener.enterMultiArg) {
			listener.enterMultiArg(this);
		}
	}
	// @Override
	public exitRule(listener: AzureListener): void {
		if (listener.exitMultiArg) {
			listener.exitMultiArg(this);
		}
	}
	// @Override
	public accept<Result>(visitor: AzureVisitor<Result>): Result {
		if (visitor.visitMultiArg) {
			return visitor.visitMultiArg(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
