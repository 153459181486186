<cog-settings-list-item cogFormGroup *ngIf="protectionGroup$ | async as protectionGroup" cogDataId="cloudvault-form"
  [invalid]="formGroup.invalid">
  <cog-settings-list-item-value-display>
    <coh-group-action-target-info type="cloudVault" [scheduled]="formGroup.enabled">
      <ng-container *ngTemplateOutlet="subtitleInfo; context: { formGroup: formGroup }"></ng-container>
    </coh-group-action-target-info>
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor>
    <fieldset class="target-form" [formGroup]="formGroup">
      <coh-group-action-target-info type="cloudVault" [scheduled]="formGroup.enabled">
        <ng-container *ngTemplateOutlet="subtitleInfo; context: { formGroup: formGroup }"></ng-container>
      </coh-group-action-target-info>
      <mat-slide-toggle formControlName="enabled" cogDataId="enable-cloudvault">
        {{ 'runCloudVault' | translate }}
      </mat-slide-toggle>
      <div *ngIf="formGroup.enabled" class="indent-controls">
        <div class="flex-controls">
          <coh-external-target-selector
            cogDataId="rpaas-target"
            formControlName="target"
            [label]="'archiveTo' | translate"
            restrictToUsageType="Rpaas"
            [readonly]="true">
          </coh-external-target-selector>
          <coh-time-period-selector
            cogDataId="rpaas-retention"
            [label]="'retainFor' | translate"
            [form]="formGroup.retentionForm"
            [readonly]="true">
          </coh-time-period-selector>
        </div>
      </div>
    </fieldset>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>

<ng-template #subtitleInfo let-formGroup="formGroup">
  <ul class="subtitle-list">
    <ng-container [ngSwitch]="formGroup.enabled">
      <ng-container *ngSwitchCase="true">
        <li *ngIf="formGroup.targetName">
          {{ formGroup.targetName }}
        </li>
        <li *ngIf="formGroup.retentionUnit && formGroup.retentionDuration">
          {{ 'policySynopsis.retention.' + formGroup.retentionUnit | translate: {
            duration: formGroup.retentionDuration
          } }}
        </li>
      </ng-container>
      <ng-container *ngSwitchCase="false">
        <span translate>cloudVaultWillNotRun</span>
      </ng-container>
    </ng-container>
  </ul>
</ng-template>
