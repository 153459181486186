import { Injectable } from '@angular/core';
import {  AbstractIPNum, IPv4, IPv6, Validator as IpValidator } from 'ip-num';

type IncrementIpReturnType = [AbstractIPNum, boolean];
export type GenerateIpReturnType = [string[], boolean];


@Injectable({
  providedIn: 'root',
})
export class IpUtilsService {
  /**
   * Generate count number of ip addresses from startIp.
   *
   * @param startIp Start ip address of the list of ip addresses to be generated.
   * @param count Total number of ip addresses to be generated.
   * @return List of ip addresses with length of count and starting from start ip address
   *
   */
  generateIps(startIp: string, count: number): string[] {
    const result = [];
    let ipAddress: AbstractIPNum, currentCount = 1;

    if (IpValidator.isValidIPv4String(startIp)[0]) {
      ipAddress = new IPv4(startIp);
    } else if (IpValidator.isValidIPv6String(startIp)[0]) {
      ipAddress = new IPv6(startIp);
    }

    // Push first ip address to the result list.
    result.push(ipAddress.toString());

    // Iterate count number of times and push each incremented IP to result.
    for (; currentCount < count; currentCount++) {
      ipAddress = ipAddress.nextIPNumber();
      result.push(ipAddress.toString());
    }
    return result;
  }


  /**
   * Function to increment ip address by an increment. Returns error if it can't be incremented.
   * Example: 10.1.2.255, 5 - 10.1.3.5
   *
   * @param ipAddress IpAddress to be incremented.
   * @param increment number to be incremented.
   * @return IncrementIpReturnType list of two items. First is IPNumber object, Second one isError boolean.
   *
   */
  incrementIp(ipAddress: AbstractIPNum, increment: number): IncrementIpReturnType {
    let currentIndex = 0;
    let result = ipAddress;
    for (; currentIndex < increment; currentIndex++ ) {
      if (result.hasNext()) {
        result = result.nextIPNumber();
      } else {
        return [result, true];
      }
    }
    return [result, false];
  }

  /**
   * Generate count number of ip addresses from startIp at internval of increment.
   *
   * @param startIp Start ip address of the list of ip addresses to be generated.
   * @param increment Number to increment for the next ip address to be generated.
   * @param count Total number of ip addresses to be generated.
   * @return GenerateIpReturnType which is a list. First item is list of ip addresses, second item is isError boolean.
   *
   */
  generateIpsWithIncrement(startIp: string, increment: number, count: number): GenerateIpReturnType {
    const result = [];
    let ipAddress: AbstractIPNum, currentCount = 1;

    // Reject invalid startIp or if increment is greater than 10.
    if ((increment > 0 && increment > 10) ||
      !(IpValidator.isValidIPv4String(startIp)[0] || IpValidator.isValidIPv6String(startIp)[0])) {
      return [null, true];
    }
    if (IpValidator.isValidIPv4String(startIp)[0]) {
      ipAddress = new IPv4(startIp);
    } else if (IpValidator.isValidIPv6String(startIp)[0]) {
      ipAddress = new IPv6(startIp);
    }

    // Push first ip address to the result list.
    result.push(ipAddress.toString());

    // Iterate count number of times and push each incremented IP to result.
    for (; currentCount < count; currentCount++) {
      const incrementResult = this.incrementIp(ipAddress, increment);
      if (incrementResult[1]) {
        return [result, true];
      }
      ipAddress = incrementResult[0];
      result.push(ipAddress.toString());
    }
    return[result, false];
  }

  /**
   * Get ip cidr prefix value from subnet mask.
   *
   * @param   subnetmask subnet mask string ex: 255.255.240.0
   * @returns prefix number ex: 20
   */
  getPrefixFromSubnetMask(subnetmask: string): number {
    // eslint-disable-next-line no-bitwise
    return subnetmask.split('.').map(Number).map(part => (part >>> 0).toString(2))
      .join('').split('1').length - 1;
  }
}
