import { DOCUMENT } from '@angular/common';
import { inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { MastheadThemeConfig, ThemeConfig } from './theme.model';

// 7.0 and older clusters still rely on the 'helix.darkMode' local storage key.
// Persist the legacy keys as well so that older cluster iframe views appear
// to match the theme which is set in parent Helios UI.
export const legacyDarkModeKey = 'helix.darkMode';

// this used in theme service as cache of the previous selected theme
export const legacyLocalStorageThemeKey = 'helix.theme';

// this used in customization service as a cache of the preference before it is loaded
export const localStorageThemeModeKey = 'helix.theme.mode';

// this used to theme service as cache of the previous selected theme
export const localStorageThemeFamilyKey = 'helix.theme.family';

const mastheadThemeStyleKeys: MastheadThemeConfig = {
  darkBGColor: '--hlx-dark-component-masthead',
  darkTextColor: '--hlx-dark-component-masthead-contrast',
  lightBGColor: '--hlx-light-component-masthead',
  lightTextColor: '--hlx-light-component-masthead-contrast',
};

// All the defined themes for reference
export const jazzLightTheme: ThemeConfig = { family: 'jazz', name: 'jazzLight', mode: 'light', className: 'jazz-light-theme' };
export const jazzDarkTheme: ThemeConfig = { family: 'jazz', name: 'jazzDark', mode: 'dark', className: 'jazz-dark-theme' };
export const ibmAquaTheme: ThemeConfig = { family: 'ibm-aqua', name: 'ibmAquaLight', mode: 'light', className: 'ibm-aqua-light-theme' };

@Injectable({ providedIn: 'root' })
export class ThemeService implements OnDestroy {

  /**
   * An array of the currently supported themes in helix
   */
  public static allThemes: ThemeConfig[] = [
    jazzDarkTheme,
    jazzLightTheme,
    ibmAquaTheme,
  ];

  /**
   * The default theme if none is provided
   */
  public static defaultThemeFamily = 'jazz';

  /**
   * The default theme mode if user has no preference
   */
  public static defaultThemeMode = 'dark';

  /**
   * The currently applied theme.
   */
  private currentTheme = new BehaviorSubject<ThemeConfig>(null);

  /**
   * Observable which emits the currently applied theme config.
   */
  readonly currentTheme$ = this.currentTheme.pipe(
    filter(value => !!value),
    distinctUntilChanged(),
  );

  /**
   * Emits a value indicating whether the dark mode is enabled.
   */
  readonly darkModeEnabled$ = this.currentTheme$.pipe(map(config => config.mode === 'dark'));

  /**
   * Default masthead theme which is needed to revert any customization
   */
  defaultMastheadTheme: MastheadThemeConfig;

  /**
   * Document reference.
   */
  private document: Document = inject(DOCUMENT);

  // Listen to theme updates and update the body class and LS entry.
  private sub = this.currentTheme$.subscribe(config => {
    this.document.body.classList.remove(...ThemeService.allThemes.map(theme => theme.className));
    this.document.body.classList.add(config.className);

    // these keys are used for clusters > 7.1 and helios
    localStorage.setItem(localStorageThemeModeKey, config.mode);
    localStorage.setItem(localStorageThemeFamilyKey, config.family);

    // set the legacy theme key so iframe cluster manager shows appropriate theme.
    // this theme key is used for clusters 7.0, 7.1
    localStorage.setItem(legacyLocalStorageThemeKey, config.name);

    // cluster managers with 6.8 or earlier use this key
    if (config.mode === 'dark') {
      localStorage.setItem(legacyDarkModeKey, 'true');
    } else {
      localStorage.removeItem(legacyDarkModeKey);
    }
  });

  constructor() {

    // Apply the already set theme or default theme.
    const lsThemeFamily = localStorage.getItem(localStorageThemeFamilyKey) || ThemeService.defaultThemeFamily;
    const lsThemeMode = localStorage.getItem(localStorageThemeModeKey) || ThemeService.defaultThemeMode;
    this.setTheme(lsThemeFamily, lsThemeMode);

    // Set the default mashead theme on load. This will be used as fallback if
    // any customization is reset.
    this.defaultMastheadTheme = this.getMastheadTheme();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  /**
   * This function sets the theme
   * only if it is not the current theme.
   *
   * @param name - name of the theme
   */
  setTheme(family: string, mode?: string): void {
    let familyMembers = ThemeService.allThemes.filter(theme => theme.family === family);

    // Caller passed in theme family name that does not exist
    if (!familyMembers.length) {
      // Only set to default if no theme has already been set
      if (!this.currentTheme.value) {
        console.warn(`Theme ${family} not found.  Setting to default.`);
        familyMembers = ThemeService.allThemes.filter(theme => theme.family = ThemeService.defaultThemeFamily);
        mode = ThemeService.defaultThemeMode;
      } else {
        // use the current theme family
        familyMembers = ThemeService.allThemes.filter(theme => theme.family = this.currentTheme.value.family);
      }
    }

    // if we can find the requested mode, then set it.
    // Otherwise, theme does not support dark or light mode.
    this.currentTheme.next(familyMembers.find(theme => theme.mode === mode) || familyMembers[0]);
  }

  /**
   * This function looks up the current theme and attempts
   * to set it to either the light or dark version depending
   * on the current mode.
   */
  toggleMode(): void {
    const currentTheme = this.currentTheme.value;
    if (currentTheme) {
      this.setTheme(currentTheme.family, ( currentTheme.mode === 'dark' ? 'light' : 'dark' ));
    }
  }

  /**
   * Set the current theme to a specific light or dark mode
   *
   * @param mode - 'dark' or 'light'
   */
  setMode(mode: string) {
    this.setTheme(this.currentTheme.value?.family || ThemeService.defaultThemeFamily, mode);
  }

  /**
   * Get the theme colors for the masthead
   *
   * @returns Object containing the different theme colors for the masthead
   */
  getMastheadTheme(): MastheadThemeConfig {
    const styles = getComputedStyle(document.documentElement, null);

    return {
      darkBGColor: styles.getPropertyValue(mastheadThemeStyleKeys.darkBGColor).trim(),
      darkTextColor: styles.getPropertyValue(mastheadThemeStyleKeys.darkTextColor).trim(),
      lightBGColor: styles.getPropertyValue(mastheadThemeStyleKeys.lightBGColor).trim(),
      lightTextColor: styles.getPropertyValue(mastheadThemeStyleKeys.lightTextColor).trim(),
    };
  }

  /**
   * Apply any custom UI config set by the user
   */
  updateMastheadTheme(config: MastheadThemeConfig) {
    config = { ...this.getMastheadTheme(), ...config };

    document.documentElement.style.setProperty(mastheadThemeStyleKeys.darkBGColor, config.darkBGColor);
    document.documentElement.style.setProperty(mastheadThemeStyleKeys.darkTextColor, config.darkTextColor);
    document.documentElement.style.setProperty(mastheadThemeStyleKeys.lightBGColor, config.lightBGColor);
    document.documentElement.style.setProperty(mastheadThemeStyleKeys.lightTextColor, config.lightTextColor);
  }

  /**
   * This is a deprecated function that is no longer necessary
   * however, as several apps call it I'm just making it void
   *
   * @deprecated
   */
  updateThemeClass() {
    // do nothing since this is now handled in the constructor
  }
}
