<div>
  <cog-spinner
    *ngIf="updating"
    size="sm">
  </cog-spinner>
  <div *ngIf="!updating" class="align-center">
    <cog-icon
      [shape]="statusIcons[connectionStatus]"
      size="md">
    </cog-icon>
    <span *ngIf="message" class="padding-left-xs">{{ message | translate }}</span>
  </div>
</div>
