/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityReportingApiConfiguration as __Configuration } from '../cohesity-reporting-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Tasks } from '../models/tasks';
import { Task } from '../models/task';
import { TasksDao } from '../models/tasks-dao';
import { TaskDao } from '../models/task-dao';
@Injectable({
  providedIn: 'root',
})
class TasksService extends __BaseService {
  static readonly GetTasksPath = '/public/tasks';
  static readonly GetTaskByIdPath = '/public/tasks/{id}';
  static readonly GetTasksDaoPath = '/private/tasks';
  static readonly CreateTaskDaoPath = '/private/tasks';
  static readonly GetTaskDaoByIdPath = '/private/tasks/{id}';
  static readonly UpdateTaskDaoPath = '/private/tasks/{id}';
  static readonly DeleteTaskDaoPath = '/private/tasks/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Fetch Tasks
   *
   * Fetches list of tasks by query params.
   * @param params The `TasksService.GetTasksParams` containing the following parameters:
   *
   * - `toTimeUsecs`: If specified, the tasks created before this value are returned. Value is epoch timestamp in microseconds.
   *
   * - `statuses`: "Specifies the task statuses corresponding to which TasksDao must
   *   be fetched."
   *
   * - `scheduleIds`: Specifies the schedule ids corresponding to which tasks must be fetched.
   *
   * - `reportIds`: "Specifies the report ids corresponding to which TasksDao must be
   *   fetched."
   *
   * - `onDemand`: If true, return only on demand tasks.
   *
   * - `ids`: Specifies the ids of tasks to fetch.
   *
   * - `fromTimeUsecs`: If specified, the tasks created after this value are returned. Value is epoch timestamp in microseconds.
   *
   * @return Success
   */
  GetTasksResponse(params: TasksService.GetTasksParams): __Observable<__StrictHttpResponse<Tasks>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.toTimeUsecs != null) __params = __params.set('toTimeUsecs', params.toTimeUsecs.toString());
    (params.statuses || []).forEach(val => {if (val != null) __params = __params.append('statuses', val.toString())});
    (params.scheduleIds || []).forEach(val => {if (val != null) __params = __params.append('scheduleIds', val.toString())});
    (params.reportIds || []).forEach(val => {if (val != null) __params = __params.append('reportIds', val.toString())});
    if (params.onDemand != null) __params = __params.set('onDemand', params.onDemand.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.fromTimeUsecs != null) __params = __params.set('fromTimeUsecs', params.fromTimeUsecs.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Tasks>;
      })
    );
  }
  /**
   * Fetch Tasks
   *
   * Fetches list of tasks by query params.
   * @param params The `TasksService.GetTasksParams` containing the following parameters:
   *
   * - `toTimeUsecs`: If specified, the tasks created before this value are returned. Value is epoch timestamp in microseconds.
   *
   * - `statuses`: "Specifies the task statuses corresponding to which TasksDao must
   *   be fetched."
   *
   * - `scheduleIds`: Specifies the schedule ids corresponding to which tasks must be fetched.
   *
   * - `reportIds`: "Specifies the report ids corresponding to which TasksDao must be
   *   fetched."
   *
   * - `onDemand`: If true, return only on demand tasks.
   *
   * - `ids`: Specifies the ids of tasks to fetch.
   *
   * - `fromTimeUsecs`: If specified, the tasks created after this value are returned. Value is epoch timestamp in microseconds.
   *
   * @return Success
   */
  GetTasks(params: TasksService.GetTasksParams): __Observable<Tasks> {
    return this.GetTasksResponse(params).pipe(
      __map(_r => _r.body as Tasks)
    );
  }

  /**
   * Fetch a Task
   *
   * Get a tasks for a given id.
   * @param id Specifies the id of the task.
   * @return Success
   */
  GetTaskByIdResponse(id: string): __Observable<__StrictHttpResponse<Task>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/tasks/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Task>;
      })
    );
  }
  /**
   * Fetch a Task
   *
   * Get a tasks for a given id.
   * @param id Specifies the id of the task.
   * @return Success
   */
  GetTaskById(id: string): __Observable<Task> {
    return this.GetTaskByIdResponse(id).pipe(
      __map(_r => _r.body as Task)
    );
  }

  /**
   * Fetch TasksDao from reportingStore
   *
   * Fetches list of TasksDao based on filter params.
   * @param params The `TasksService.GetTasksDaoParams` containing the following parameters:
   *
   * - `userSids`: "Specifies the user sids ids corresponding to which TasksDao must
   *   be fetched."
   *
   * - `toTimeUsecs`: If specified, the tasks created before this value are returned. Value is epoch timestamp in microseconds.
   *
   * - `statuses`: "Specifies the task statuses corresponding to which TasksDao must
   *   be fetched."
   *
   * - `scheduleIds`: "Specifies the schedule ids corresponding to which TasksDao must be
   *   fetched."
   *
   * - `requestIds`: "Specifies the request ids corresponding to which TasksDao must be
   *   fetched."
   *
   * - `reportIds`: "Specifies the report ids corresponding to which TasksDao must be
   *   fetched."
   *
   * - `onDemand`: If true, return only on demand tasks.
   *
   * - `limit`: Only return number of tasks specified by this parameter.
   *
   * - `ids`: Specifies the ids of TasksDao to fetch.
   *
   * - `fromTimeUsecs`: If specified, the tasks created after this value are returned. Value is epoch timestamp in microseconds.
   *
   * - `accountIds`: "Specifies the account ids corresponding to which TasksDao must be
   *   fetched."
   *
   * @return Success
   */
  GetTasksDaoResponse(params: TasksService.GetTasksDaoParams): __Observable<__StrictHttpResponse<TasksDao>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.userSids || []).forEach(val => {if (val != null) __params = __params.append('userSids', val.toString())});
    if (params.toTimeUsecs != null) __params = __params.set('toTimeUsecs', params.toTimeUsecs.toString());
    (params.statuses || []).forEach(val => {if (val != null) __params = __params.append('statuses', val.toString())});
    (params.scheduleIds || []).forEach(val => {if (val != null) __params = __params.append('scheduleIds', val.toString())});
    (params.requestIds || []).forEach(val => {if (val != null) __params = __params.append('requestIds', val.toString())});
    (params.reportIds || []).forEach(val => {if (val != null) __params = __params.append('reportIds', val.toString())});
    if (params.onDemand != null) __params = __params.set('onDemand', params.onDemand.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.fromTimeUsecs != null) __params = __params.set('fromTimeUsecs', params.fromTimeUsecs.toString());
    (params.accountIds || []).forEach(val => {if (val != null) __params = __params.append('accountIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/private/tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TasksDao>;
      })
    );
  }
  /**
   * Fetch TasksDao from reportingStore
   *
   * Fetches list of TasksDao based on filter params.
   * @param params The `TasksService.GetTasksDaoParams` containing the following parameters:
   *
   * - `userSids`: "Specifies the user sids ids corresponding to which TasksDao must
   *   be fetched."
   *
   * - `toTimeUsecs`: If specified, the tasks created before this value are returned. Value is epoch timestamp in microseconds.
   *
   * - `statuses`: "Specifies the task statuses corresponding to which TasksDao must
   *   be fetched."
   *
   * - `scheduleIds`: "Specifies the schedule ids corresponding to which TasksDao must be
   *   fetched."
   *
   * - `requestIds`: "Specifies the request ids corresponding to which TasksDao must be
   *   fetched."
   *
   * - `reportIds`: "Specifies the report ids corresponding to which TasksDao must be
   *   fetched."
   *
   * - `onDemand`: If true, return only on demand tasks.
   *
   * - `limit`: Only return number of tasks specified by this parameter.
   *
   * - `ids`: Specifies the ids of TasksDao to fetch.
   *
   * - `fromTimeUsecs`: If specified, the tasks created after this value are returned. Value is epoch timestamp in microseconds.
   *
   * - `accountIds`: "Specifies the account ids corresponding to which TasksDao must be
   *   fetched."
   *
   * @return Success
   */
  GetTasksDao(params: TasksService.GetTasksDaoParams): __Observable<TasksDao> {
    return this.GetTasksDaoResponse(params).pipe(
      __map(_r => _r.body as TasksDao)
    );
  }

  /**
   * Create a TaskDao Entry in reportingStore
   *
   * Create a Task Entry in reportingStore.
   * @param body Request body params in order to create a Task.
   * @return Success
   */
  CreateTaskDaoResponse(body: TaskDao): __Observable<__StrictHttpResponse<TaskDao>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/private/tasks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TaskDao>;
      })
    );
  }
  /**
   * Create a TaskDao Entry in reportingStore
   *
   * Create a Task Entry in reportingStore.
   * @param body Request body params in order to create a Task.
   * @return Success
   */
  CreateTaskDao(body: TaskDao): __Observable<TaskDao> {
    return this.CreateTaskDaoResponse(body).pipe(
      __map(_r => _r.body as TaskDao)
    );
  }

  /**
   * Fetch a taskDao
   *
   * Fetch a taskDao for a given id.
   * @param id Specifies the id of the task.
   * @return Success
   */
  GetTaskDaoByIdResponse(id: string): __Observable<__StrictHttpResponse<TaskDao>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/private/tasks/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TaskDao>;
      })
    );
  }
  /**
   * Fetch a taskDao
   *
   * Fetch a taskDao for a given id.
   * @param id Specifies the id of the task.
   * @return Success
   */
  GetTaskDaoById(id: string): __Observable<TaskDao> {
    return this.GetTaskDaoByIdResponse(id).pipe(
      __map(_r => _r.body as TaskDao)
    );
  }

  /**
   * Update a taskDao
   *
   * Update taskDao for a given id.
   * @param params The `TasksService.UpdateTaskDaoParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the task.
   *
   * - `body`: Request body params in order to update a schedule.
   *
   * @return Success
   */
  UpdateTaskDaoResponse(params: TasksService.UpdateTaskDaoParams): __Observable<__StrictHttpResponse<TaskDao>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/private/tasks/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TaskDao>;
      })
    );
  }
  /**
   * Update a taskDao
   *
   * Update taskDao for a given id.
   * @param params The `TasksService.UpdateTaskDaoParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the task.
   *
   * - `body`: Request body params in order to update a schedule.
   *
   * @return Success
   */
  UpdateTaskDao(params: TasksService.UpdateTaskDaoParams): __Observable<TaskDao> {
    return this.UpdateTaskDaoResponse(params).pipe(
      __map(_r => _r.body as TaskDao)
    );
  }

  /**
   * Delete a taskDao
   *
   * Delete taskDao for a given id.
   * @param id Specifies the id of the task.
   */
  DeleteTaskDaoResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/private/tasks/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a taskDao
   *
   * Delete taskDao for a given id.
   * @param id Specifies the id of the task.
   */
  DeleteTaskDao(id: string): __Observable<null> {
    return this.DeleteTaskDaoResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module TasksService {

  /**
   * Parameters for GetTasks
   */
  export interface GetTasksParams {

    /**
     * If specified, the tasks created before this value are returned. Value is epoch timestamp in microseconds.
     */
    toTimeUsecs?: number;

    /**
     * "Specifies the task statuses corresponding to which TasksDao must
     * be fetched."
     */
    statuses?: null | Array<'Created' | 'InProgress' | 'Completed' | 'Error'>;

    /**
     * Specifies the schedule ids corresponding to which tasks must be fetched.
     */
    scheduleIds?: null | Array<string>;

    /**
     * "Specifies the report ids corresponding to which TasksDao must be
     * fetched."
     */
    reportIds?: null | Array<string>;

    /**
     * If true, return only on demand tasks.
     */
    onDemand?: null | boolean;

    /**
     * Specifies the ids of tasks to fetch.
     */
    ids?: null | Array<string>;

    /**
     * If specified, the tasks created after this value are returned. Value is epoch timestamp in microseconds.
     */
    fromTimeUsecs?: number;
  }

  /**
   * Parameters for GetTasksDao
   */
  export interface GetTasksDaoParams {

    /**
     * "Specifies the user sids ids corresponding to which TasksDao must
     * be fetched."
     */
    userSids?: null | Array<string>;

    /**
     * If specified, the tasks created before this value are returned. Value is epoch timestamp in microseconds.
     */
    toTimeUsecs?: number;

    /**
     * "Specifies the task statuses corresponding to which TasksDao must
     * be fetched."
     */
    statuses?: null | Array<'Created' | 'InProgress' | 'Completed' | 'Error'>;

    /**
     * "Specifies the schedule ids corresponding to which TasksDao must be
     * fetched."
     */
    scheduleIds?: null | Array<string>;

    /**
     * "Specifies the request ids corresponding to which TasksDao must be
     * fetched."
     */
    requestIds?: null | Array<string>;

    /**
     * "Specifies the report ids corresponding to which TasksDao must be
     * fetched."
     */
    reportIds?: null | Array<string>;

    /**
     * If true, return only on demand tasks.
     */
    onDemand?: null | boolean;

    /**
     * Only return number of tasks specified by this parameter.
     */
    limit?: null | number;

    /**
     * Specifies the ids of TasksDao to fetch.
     */
    ids?: null | Array<string>;

    /**
     * If specified, the tasks created after this value are returned. Value is epoch timestamp in microseconds.
     */
    fromTimeUsecs?: number;

    /**
     * "Specifies the account ids corresponding to which TasksDao must be
     * fetched."
     */
    accountIds?: null | Array<string>;
  }

  /**
   * Parameters for UpdateTaskDao
   */
  export interface UpdateTaskDaoParams {

    /**
     * Specifies the id of the task.
     */
    id: string;

    /**
     * Request body params in order to update a schedule.
     */
    body: TaskDao;
  }
}

export { TasksService }
