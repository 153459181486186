// Directives: cSpinner, cBtnSpinner, and cInputSpinner Loading Spinner

;(function(angular, undefined) {
    'use strict';

    // @type    {string}    Class to be toggled on/off when `spinning` is truthy
    var spinningClass = 'spinning';

    angular.module('C.spinner', [])
      .directive('cSpinner', cSpinnerFn)
      .directive('cBtnSpinner', cBtnSpinnerFn)
      .directive('cInputSpinner', cInputSpinnerFn);

    /**
     * cSpinner directive.
     * Displays a spinning indicator.
     *
     * EXAMPLES:
     *   <c-spinner size="sm"></c-spinner>
     *   <c-spinner header="Header String..."></c-spinner>
     */
    function cSpinnerFn() {
      return {
        scope: {
          // @type {string}  - Display header in spinner widget.
          header: '@?',

          // @type {boolean}  - Whether the header should be inline with spinner
          inline: '@?',

          // @type {boolean}  - Whether the spinner should be left aligned,
          //                    default it is center align
          leftAligned: '@?',

          /**
          * Rendered widget size
          * @type {string}  One of ['sm', 'md', 'lg'].
          *                 Default = 'md'
          */
          size: '@?'
        },
        restrict: 'AE',
        templateUrl: 'app/global/c-spinner/c-spinner.html',
      };
    }


    /**
     * cBtnSpinner Button Loading Spinner.
     * Includes relevant markup to display spinning loading balls within
     * a '.c-btn'.
     *
     * EXAMPLE:
     *   <button class="c-btn-primary" c-btn-spinner="expression"></button>
     */
    cSpinnerFn.$inject = ['$compile'];
    function cBtnSpinnerFn($compile) {
      return {
        scope: false,
        restrict: 'A',
        link: linkWrapperFn('cBtnSpinner', $compile),
      };
    }


    /**
     * cInputSpinner Loading Spinner.
     * Includes relevant markup to display spinning loading balls within
     * a form control/field.
     *
     * EXAMPLES:
     *   <ui-select type="text" name="myField"
     *     ng-model="modelThing"
     *     c-input-spinner="expression">
     *     ...
     *   </ui-select>
     *
     *   <input type="text" name="myField"
     *     ng-model="modelThing"
     *     c-input-spinner="expression"
     *     spinner-disables="false">
     */
    cSpinnerFn.$inject = ['$compile'];
    function cInputSpinnerFn($compile) {
      return {
        scope: false,
        restrict: 'A',
        link: linkWrapperFn('cInputSpinner', $compile),
      };
    }

    /**
     * Wrapper Fn for directive linking that accepts an attribute name to be
     * used within the actual linkFn.
     *
     * @method   linkWrapperFn
     * @param    {string}     spinnerAttrib   The attr[prop] to use for
     *                                        determining if spinner is on.
     * @param    {function}   $compile        Angular $compile function
     * @return   {function}   The actual link fn
     */
    function linkWrapperFn(spinnerAttrib, $compile) {
      /**
       * Common Angular Link Fn for the cWhateverSpinner directives
       *
       * @method   link
       * @param    {object}   scope   scope object
       * @param    {object}   elem    $element the directive is bound to
       * @param    {object}   attrs   $normalized attributes object
       */
      return function link(scope, elem, attrs) {
        // Compile a new ngSpinner (cogSpinner) for injection into the DOM.
        const size = spinnerAttrib === 'cBtnSpinner' ? 'sm' : 'xs';
        var spinnerLinkFn = $compile('<ng-spinner [size]="\'' + size + '\'"></ng-spinner>');
        var compiledCommonTemplate = spinnerLinkFn(scope);

        if (spinnerAttrib === 'cInputSpinner') {
          elem.wrap('<div class="c-input-spinner-wrapper"></div>');

          // Insert spinner after input
          elem.after(compiledCommonTemplate);
        } else {
          // Insert the spinner into the button
          elem.append(compiledCommonTemplate);
        }

        // Watch the specified attribute (set in directive-specific compuleFn)
        // for changes and toggle a class accordingly.
        scope.$watch(
          function attrGetter() {
            // Evaluate an expression on the spinnerAttrib specified for the
            // type of directive this is acting on.
            return scope.$eval(attrs[spinnerAttrib]);
          },
          function spinningObserver(isSpinning) {
            var isDisabled;
            var classMethod;

            if (isSpinning === undefined) { return; }

            isDisabled = scope.$eval(
              // Evaluate an expression on the ng-disabled attribute, or simply
              // check for the presence of native disabled (this is the correct
              // application to disable a native form control).
              attrs.ngDisabled || elem.prop('disabled') || attrs.hasOwnProperty('disabled')
            );

            // Which add/remove class method should we use?
            classMethod = isSpinning ? '$addClass' : '$removeClass';

            // Toggle the class on the element
            attrs[classMethod](spinningClass);

            // Toggle disabled on the element. Disabling is an opt-out feature.
            // Don't modify disabled value if isDisabled is true.
            if (attrs.spinnerDisables !== 'false') {
              // Toggle the disabled attribute & prop on the element.
              elem.prop('disabled', isSpinning);
              elem.attr('disabled', isSpinning || null);
            }
          }
        );
      };
    }

})(angular);
