<cog-settings-list-item [formGroup]="formGroup"
  [label]="'bandwidthThrottling' | translate">
  <cog-settings-list-item-value-display>
    <ng-container *ngIf="formGroupValues.enabled">
      {{ formGroupValues?.value }} {{ 'protectionGroups.mbpsPerBackupStream' | translate:{jobType: jobType} }}
    </ng-container>
    <ng-container *ngIf="!formGroupValues.enabled">
      {{ 'disabled' | translate }}
    </ng-container>
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor>
    <mat-slide-toggle [inheritDataId]="true"
      cogDataId="bandwidth-throttling-toggle"
      [formControlName]="formControlNames.enabled">
      {{ 'protectionGroups.bandwidthThrottling.toggleHint' | translate }}
    </mat-slide-toggle>
    <ng-container *ngIf="formGroupValues.enabled">
      <mat-form-field>
        <input
          matInput
          [formControlName]="formControlNames.value"
          [inheritDataId]="true"
          [placeholder]="'mbps' | translate"
          cogDataId="bandwidth-throttling-value-input"
          type="number"
          min="0">
      </mat-form-field>
      <span>{{ 'protectionGroups.mbpsPerBackupStream' | translate:{jobType: jobType} }}</span>
    </ng-container>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>