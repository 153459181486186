<ng-container [formGroup]="form.formGroup">
  <mat-form-field>
    <mat-label>{{ 'lockUntil' | translate }}</mat-label>
    <input cogDataId="override-datelock-expiry-value"
      [min]="minDataLockExpiryDate"
      formControlName="dataLockExpiryDate"
      [matDatepicker]="datalockExpiryDatePicker"
      matInput>
    <mat-datepicker-toggle matSuffix [for]="datalockExpiryDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #datalockExpiryDatePicker></mat-datepicker>
  </mat-form-field>
</ng-container>
