import { ScanHealthStatus } from '@cohesity/api/argus';

export const cancellableScanStatues = new Set([ScanHealthStatus.Accepted, ScanHealthStatus.Running]);

export const intermediateScanStatues = new Set([ScanHealthStatus.Running, ScanHealthStatus.Canceling]);

/**
 * Determines if status is failed.
 *
 * @param healthStatus scan health status
 * @returns true if status matched
 */
export const isFailedState = (status: ScanHealthStatus) => new Set([ScanHealthStatus.Failed]).has(status);

/**
 * Determines if status is running.
 *
 * @param healthStatus scan health status
 * @returns true if status matched
 */
export const isRunningState = (status: ScanHealthStatus) => new Set([ScanHealthStatus.Running]).has(status);

/**
 * Determines if status is terminal.
 *
 * @param healthStatus scan health status
 * @returns true if status matched
 */
export const isTerminalState = (status: ScanHealthStatus) => !isFailedState(status) && !isRunningState(status);

/**
 * The sorting order for AppStatus
 */
export const scanHealthStatusSortOrder: {[key in ScanHealthStatus]: number} = {
  [ScanHealthStatus.Succeeded]: 7,
  [ScanHealthStatus.SucceededWithWarning]: 6,
  [ScanHealthStatus.Failed]: 5,
  [ScanHealthStatus.Accepted]: 4,
  [ScanHealthStatus.Running]: 3,
  [ScanHealthStatus.Canceled]: 2,
  [ScanHealthStatus.Canceling]: 1,
  [ScanHealthStatus.Skipped]: 8,
};

/**
 * The list of sorted scan health status values
 *
 * NOTE: sorted as per scanHealthStatusSortOrder
 */
export const getSortedAppStatus = () => (Object.keys(ScanHealthStatus) as ScanHealthStatus[]).sort(
  (a: ScanHealthStatus, b: ScanHealthStatus) => {
    // ordered as per scanHealthStatusSortOrder
    if (scanHealthStatusSortOrder[a] < scanHealthStatusSortOrder[b]) {
      return 1;
    }
    if (scanHealthStatusSortOrder[a] > scanHealthStatusSortOrder[b]) {
      return -1;
    }
    return 0;
  }
);
