import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ClustersServiceApi } from '@cohesity/api/v1';
import { Subnet } from '@cohesity/api/v2';
import { SnackBarService } from '@cohesity/helix';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { REGEX_FORMATS } from 'src/app/shared/constants/formats.constants';

import { SquashConfig, SubnetModel } from '../../models';


// TODO: [Refactor] Move these models to models/whitelist.model.ts
/**
 * Permissions for Nfs and Smb access.
 */
export let PermissionList = ['kReadWrite', 'kReadOnly', 'kDisabled'];

/**
 * Nfs squash options.
 */
export const NfsSquashOptions = [
  'kNone',
  'kAllSquash',
  'kRootSquash',
];

/**
 * Subnet Whitelist defintition.
 */
export interface SubnetWhiteList extends Subnet {

  /**
   * IPV4/IPV6 address in slash notation.
   */
  _ip?: string;

  /**
   * Specifies whether all clients from this subnet can map view with
   * view_all_squash_uid/view_all_squash_gid configured in the view.
   *
   * NOTE - The Subnet Model is different for v1 and v2 API, hence adding these
   *        older keys.
   */
  nfsAllSquash?: null | boolean;
  /**
   * Specifies whether clients from this subnet can mount as root on NFS.
   *
   * NOTE - The Subnet Model is different for v1 and v2 API, hence adding these
   *        older keys.
   */
  nfsRootSquash?: null | boolean;
}

/**
 * Whitelist dialog data.
 */
export interface WhitelistDialogData {
  /**
   * Existing subnet value.
   */
  existingWhitelist?: SubnetWhiteList;

  /**
   * Array of Whitelists.
   */
  whitelists: SubnetWhiteList[];

  /**
   * Determines the action type whether add, edit or delete.
   */
  type: string;

  /**
   * Determines whether to update global whitelist model or not.
   */
  isGlobal?: boolean;

  /**
   * Determines whether to how NFS Squash form field ot not.
   */
  hideNfsSquash?: boolean;

  /**
   * Determines whether the NFS permission and smb permission is read-only or not.
   * If yes, then hide kReadWrite option.
   */
  readOnlyPermission?: boolean;

  /**
   * Squash configuration for uid/gid.
   */
  squashConfig?: SquashConfig;

  /**
   * Selected protocols.
   */
  viewProtocols?: string[];
}

/**
 * Whitelist dialog component.
 *
 * @example
 *   <coh-whitelist-dialog></coh-whitelist-dialog>
 */
@Component({
  selector: 'coh-whitelist-dialog',
  templateUrl: './whitelist-dialog.component.html',
  styleUrls: ['./whitelist-dialog.component.scss']
})
export class WhitelistDialogComponent extends AutoDestroyable implements OnInit {
  /**
   * Default whitelist value.
   */
  defaultWhitelistValue: SubnetWhiteList = {
    _ip: '',
    ip: '',
    netmaskIp4: '',
    nfsAccess: 'kReadWrite',
    smbAccess: 'kReadWrite',
    s3Access: 'kReadWrite',
    nfsSquash: 'kNone',
    description: '',
  };

  /**
   * Specifies whether the data is in loading state or not.
   */
  loading = false;

  /**
   * Nfs Permissions.
   */
  nfsPermissions = PermissionList;

  /**
   * Nfs squash options.
   */
  nfsSquashList = NfsSquashOptions;

  /**
   * Smb permissions.
   */
  smbPermissions = PermissionList;

  /**
   * S3 permissions.
   */
  s3Permissions = PermissionList;

  /**
   * Whitelist form group.
   */
  whitelistForm: UntypedFormGroup;

  /**
   * Form group to delete whitelist.
   */
  whitelistDeleteForm: UntypedFormGroup;

  /**
   * Returns error for a form field.
   */
  public hasError = (controlName: string, errorName: string) =>
    this.whitelistForm.controls[controlName].hasError(errorName);

  /**
   * Determines whether the action type is delete or not.
   */
  get isDeleteAction(): boolean {
    return this.data && this.data.type === 'delete';
  }

  constructor(
    private clustersService: ClustersServiceApi,
    public dialogRef: MatDialogRef<WhitelistDialogComponent>,
    private evalAjax: AjaxHandlerService,
    private fb: UntypedFormBuilder,
    private snackbar: SnackBarService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: WhitelistDialogData) {
    super();
  }

  /**
   * Init Component.
   */
  ngOnInit() {
    if (this.isDeleteAction) {
      this.whitelistDeleteForm = this.fb.group({
        confirmDelete: new UntypedFormControl(null, [Validators.required, Validators.pattern('^([yY][eE][sS])$')]),
      });
      return;
    }
    this.initializeForm();
  }

  /**
   * Validates whether duplicate ip exists or not.
   */
  duplicateIpValidator: ValidatorFn = (control: AbstractControl) => {
    let value = control.value;

    // Ignore if no values
    if (!value) {
      return null;
    }

    value = value.split('/')[0];
    const duplicateEntry = (this.data.whitelists || []).find(subnet =>
      subnet.ip.toLowerCase() === value.toLowerCase());

    // Ignore during editing existing whitelist.
    if (this.data.type === 'edit') {
      const existingValue = this.untransformSubnet(this.data.existingWhitelist);

      if (existingValue.ip === value) {
        return null;
      }
    }

    return duplicateEntry ? { duplicate: true } : null;
  };

  /**
   * Initialize for with default values.
   */
  initializeForm() {

    // Change default form value on condition.
    if (this.data.readOnlyPermission) {
      this.defaultWhitelistValue = {
        _ip: '',
        ip: '',
        netmaskIp4: '',
        nfsAccess: 'kReadOnly',
        smbAccess: 'kReadOnly',
        s3Access: 'kReadOnly',
        nfsSquash: 'kNone',
        description: '',
      };
    }

    const value = this.data.existingWhitelist || this.defaultWhitelistValue;

    if (value && value !== undefined) {
      // Mapping Nfs squash value.
      if (!value.nfsSquash) {
        switch (true) {
          case value.nfsAllSquash:
            value.nfsSquash = 'kAllSquash';
            break;

          case value.nfsRootSquash:
            value.nfsSquash = 'kRootSquash';
            break;

          default:
            value.nfsSquash = 'kNone';
            break;
        }
      }

      this.whitelistForm = this.fb.group({
        ip: [value._ip, [Validators.required, Validators.pattern(REGEX_FORMATS.ipSlashNotation),
          this.duplicateIpValidator]],
        netmaskIp4: [value.netmaskIp4],
        nfsAccess: [value.nfsAccess],
        smbAccess: [value.smbAccess],
        s3Access: [value.s3Access],
        nfsSquash: [value.nfsSquash],
        description: [value.description],
      });

      // Reset NFS Permission and SMB Permission form field on condition.
      if (this.data.readOnlyPermission) {
        PermissionList = ['kReadOnly', 'kDisabled'];
        this.nfsPermissions = PermissionList;
        this.smbPermissions = PermissionList;
      }
    }
  }

  /**
   * Method called on form submission to update whitelists.
   */
  onSubmit() {
    const formName = this.data.type === 'delete' ? 'whitelistDeleteForm' : 'whitelistForm';

    if (!this[formName].valid) {
      this[formName].markAllAsTouched();
      return;
    }

    let whitelists = this.data.whitelists || [];

    switch (this.data.type) {
      case 'add': {
        let newValue = this.transformNfsSquashValue(this.whitelistForm.value);
        newValue = this.untransformSubnet(newValue);
        whitelists = (this.data.whitelists || []).concat(newValue);
        break;
      }
      case 'edit': {
        const updatedValue = this.transformNfsSquashValue(this.whitelistForm.value);

        // Populating updated value based on selected whitelist info.
        whitelists.forEach((subnet, index) => {
          if (subnet?.ip === this.data.existingWhitelist?.ip) {
            whitelists[index] = this.untransformSubnet(updatedValue);
          }
        });
        break;
      }
      case 'delete':
        whitelists = this.data.whitelists.filter(value => value?.ip !== this.data.existingWhitelist?.ip);
        break;
    }

    this.updateWhitelistEntries(whitelists);
  }

  /**
   * Returns subnet with updated nfs squash information.
   *
   * @param subnet Sunet information
   * @return transformed subnet value.
   */
  transformNfsSquashValue(subnet: SubnetWhiteList) {
    switch (subnet.nfsSquash) {
      case 'kRootSquash':
        subnet.nfsRootSquash = true;
        break;

      case 'kAllSquash':
        subnet.nfsAllSquash = true;
        break;
    }
    return subnet;
  }

  /**
   * Updated whitelist entries.
   *
   * @param subnets Subnet list
   */
  updateWhitelistEntries(subnets: SubnetModel[]) {
    if (this.data.isGlobal) {
      this.loading = true;

      // Using this to converge the results into one as we don't have unique ids
      // to filter the lists later.
      const clientSubnets = [], otherSubnets = [];
      subnets.forEach(subnet => {
        if ((subnet !== null) && (subnet !== undefined) && !subnet._modifyDisabled) {
          clientSubnets.push(subnet);
        } else {
          otherSubnets.push(subnet);
        }
      });
      const params = {
        Body: { clientSubnets }
      };

      this.clustersService.UpdateExternalClientSubnets(params).pipe(
        this.untilDestroy(),
        finalize(() => this.loading = false)
      ).subscribe(response => {
        const title = `views.whitelist.${this.data.type}Ip.SuccessDetails`;
        this.data.whitelists = subnets;

        this.dialogRef.close([...(response.clientSubnets || []), ...otherSubnets]);
        this.snackbar.open(this.translate.instant(title));
      }, this.evalAjax.errorMessage);
      return;
    }

    this.dialogRef.close(subnets || []);
  }

  /**
   * Untransform ip/mask to individual params.
   *
   * @param   subnet    Subnet object.
   */
  untransformSubnet(subnet: Subnet) {
    if (!subnet || !subnet.ip) {
      return subnet;
    }

    const parts = subnet.ip.split('/');
    subnet.ip = parts[0];
    const mask: any = parts[1];

    if (mask && (mask.substr(':') > -1)) {
      subnet.netmaskIp4 = undefined;
      subnet.netmaskBits = +mask;
    } else {
      subnet.netmaskIp4 = mask;
      subnet.netmaskBits = undefined;
    }

    return subnet;
  }
}
