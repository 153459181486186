<cog-settings-list-item [invalid]="control.invalid"
  cogFormGroup
  [label]="'powerState' | translate">
  <cog-settings-list-item-value-display>
    {{ control.value | boolLabel: 'on' : 'off' }}
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor>
    <mat-slide-toggle [formControl]="control"
      [inheritDataId]="true"
      cogDataId="power-on-vms">
      {{'powerOn' | translate}}
    </mat-slide-toggle>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
