// Report: Protection Summary By Object Type

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.reports')
    .controller('reportsProtectionSummaryController',
      reportsProtectionSummaryControllerFn);

  function reportsProtectionSummaryControllerFn(
    $scope, $q, ReportsService, evalAJAX, ProtectionSummaryUtil, cUtils,
    ENUM_ENV_TYPE, SOURCE_OBJECT_SCHEDULE_TYPE, ENUM_SNAPSHOT_STATUS,
    ENV_GROUPS, cReportsControlsUtil, ReportsUtil) {

    $scope.filters = cReportsControlsUtil.filters;
    $scope.downloadProtectionSummaryReport = downloadProtectionSummaryReport;
    $scope.getProtectionSummaryObjects = getProtectionSummaryObjects;

    $scope.protectionSummaryConfig =
      ReportsUtil.getReportConfig('kProtectionSummaryByObjectTypeReport')();

    $scope.ENUM_ENV_TYPE = ENUM_ENV_TYPE;
    $scope.SOURCE_OBJECT_SCHEDULE_TYPE = SOURCE_OBJECT_SCHEDULE_TYPE;
    $scope.ENUM_SNAPSHOT_STATUS = ENUM_SNAPSHOT_STATUS;

    $scope.protectionSummaryObjects = [];

    /**
     * Loads Controller Data and resets filters
     */
    function activate() {
      var defaultFilters;

      defaultFilters = cReportsControlsUtil.getDefaultFilters({
        objectType: { name: 'All' },
        typeAhead: {},
      });

      getProtectionSummaryObjects(defaultFilters);
    }

    /**
     * returns params object for api call
     *
     * @param      {object}    filters    filters from c-reports-controls
     *
     * @return     {object}    filter     values to be passed to API
     */
    function getParams(filters) {
      // If a user selects a job we do not need to pass the objectType or
      // sourceId to the API becuase the backend can get these details, from
      // the selected Job. If we do pass these values, with a jobId API will
      // return an error
      if (filters.jobId) {
        filters.objectType.enum = undefined;
        filters.registeredSourceIds = undefined;
      }

      // API only accepts an environment or a registeredSourceId not both;
      // registeredSourceId will always take precedence
      if (filters.objectType.enum && filters.registeredSourceIds) {
        filters.objectType.enum = undefined;
      }

      return ReportsService.extendWithTenantParams({
        reportType: $scope.protectionSummaryConfig.reportType,
        jobIds: filters.jobId,
        environments: filters.objectType.enum,
        registeredSourceId: filters.registeredSourceIds,
        startTimeUsecs: filters.timeObject.from.toUsecDate(),
        endTimeUsecs: filters.timeObject.until.toUsecDate(),
      });
    }

    /**
     * Gets data needed for controller and calls getSummaryStats()
     * to aggregate data for template
     *
     * @param      {object}    filters    filters from c-reports-controls
     */
    function getProtectionSummaryObjects(filters) {
      $scope.dataLoading = true;
      $scope.protectionSummaryObjects.length = 0;

      ReportsService.getProtectionSourceObjects(getParams(filters))
        .then(function getSummaries(data) {

          $scope.protectionSummaryObjects = data;

          getSummaryStats();

        }, evalAJAX.errorMessage)
        .finally(function getSummaryObjectsFinally() {
          $scope.dataLoading = false;
        });
    }

    /**
     * Calls util methods to aggregate data for template
     */
    function getSummaryStats() {
      // Step 1. Group our objects by enviroment
      var sourceGroups =
        ProtectionSummaryUtil.groupObjects($scope.protectionSummaryObjects, 'protectionSource.environment');

      // Step 2. Aggregate Stats at the environment level
      $scope.objectTypeStats =
        ProtectionSummaryUtil.objectTypeStats(sourceGroups);

      // Step 3. Aggregates stats total for all environments
      $scope.summaryStats =
        ProtectionSummaryUtil.calculateSummaryStats($scope.objectTypeStats);

      // Step 4. Group enviroment level data by registered source
      $scope.registeredSourceGroups =
        ProtectionSummaryUtil.groupByRegisteredSource(sourceGroups, 'registeredSource');
    }

    /**
     * Initiates CSV downloads
     */
    function downloadProtectionSummaryReport() {
      var params = getParams(cReportsControlsUtil.filters);
      params.outputFormat = 'csv';

      ReportsService.getProtectionSourceObjects(params);
    }

    activate();
  }
})(angular);
