import { Component, Input } from '@angular/core';

/**
 * Recovery detail page volume mapping table (for IVM only).
 *
 * @example
 * <coh-recovery-volume-mapping-table [volumeMapping]="recovery.targetParams?.volumeMapping">
 * </coh-recovery-volume-mapping-table>
 */
@Component({
  selector: 'coh-recovery-volume-mapping-table',
  templateUrl: './recovery-volume-mapping-table.component.html'
})
export class RecoveryVolumeMappingTableComponent {
  /**
   * Recovery
   */
  @Input() volumeMapping: any[];

  /**
   * Recovery file object table column names.
   */
  readonly columns = [
    'originalVolume',
    'mountedVolume',
    'fileSystemType'
  ];

  /**
   * Track by function for the data table
   *
   * @param    index  Item index.
   * @param    item   The item.
   * @returns  The item's original volume.
   */
  trackByOriginalVolume(index: number, item: any): string {
    return item.originalVolume;
  }
}
