import { Pipe, PipeTransform } from '@angular/core';
import { ArchivalTargetSummaryInfo } from '@cohesity/api/v2';

import { ClusterService } from '../../core/services/cluster.service';

/**
 * Account for old, k-value v1 enums as well as v2-style enums.
 */
type KnownTargetTypes =
  | 'Archival'
  | 'archival'
  | 'Local'
  | 'local'
  | 'Tape'
  | 'kTape'
  | 'Nas'
  | 'kNas'
  | 'Cloud'
  | 'kCloud'
  | 'StorageArraySnapshot'
  | 'kStorageArraySnapshot'

  // TODO: Remove this once it is cleaned from the api
  | 'RpaasArchival';



/**
 * This pipe is used to get the icon for a backup target type this can be
 * any of the archival target types: 'kTape', 'kNas', 'kCloud' or 'local' for
 * local backups.
 */
@Pipe({
  name: 'backupTargetIcon',
  pure: true,
})
export class BackupTargetIconPipe implements PipeTransform {

  constructor(private clusterInfo: ClusterService) {

  }
  /**
   * Gets an icon to show for backup target types. Use 'local' for local backup target types
   * and 'kTape' 'kNas' or 'kCloud' for external backup targets.
   *
   * @param   targetType   The snapshot target type
   * @param   archivalTargetType   The snapshot archival target type
   * @param   currentTierType The snapshot current tier type
   * @returns The correct helix icon.
   */
  transform(targetType: KnownTargetTypes, archivalTarget: ArchivalTargetSummaryInfo): string {

    const currentTierType = archivalTarget?.tierSettings?.currentTierType;
    const archivalTargetType = archivalTarget?.targetType;
    const ownershipContext = archivalTarget?.ownershipContext;

    if (['kAmazonS3Glacier', 'kAmazonS3GlacierDeepArchive'].includes(currentTierType)) {
      return 'helix:snowflake';
    }

    if (ownershipContext === 'FortKnox') {
      return 'helix:vault';
    }

    switch (targetType) {
      case 'local':
      case 'Local':
        if (this.clusterInfo.isClusterNGCE) {
          return 'helix:cloud';
        }
        return 'helix:cluster';
      case 'kTape':
      case 'Tape':
        return 'helix:tape';
      case 'StorageArraySnapshot':
      case 'kStorageArraySnapshot':
        return 'helix:storage-array-snapshot';
      case 'Archival':
      case 'archival':
        return ['Cloud', 'Nas'].includes(archivalTargetType) ? 'helix:cloud' : 'helix:tape';
      case 'kNas':
      case 'Nas':
      case 'kCloud':
      case 'Cloud':
      default:
        return 'helix:cloud';
    }
  }
}
