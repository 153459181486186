import { Injectable } from '@angular/core';
import { NetworkInformationServiceServiceApi, NisNetgroup, NisNetgroups, NisProviders } from '@cohesity/api/v2';
import { Observable } from 'rxjs';

/**
 * The service contains functions related to netgroup whitelist scope.
 */
@Injectable({
  providedIn: 'root'
})
export class NetgroupWhitelistService {

  constructor(private netgroupWhitelistService: NetworkInformationServiceServiceApi) {}

  /**
   * Get all netgroup whitelist.
   *
   * @returns   Observable of all nisNetgroups
   */
  getNetgroupWhitelist(): Observable<NisNetgroups> {
    return this.netgroupWhitelistService.GetNisNetgroups({});
  }

  /**
   * Update the current netgroup whitelist.
   *
   * @returns   Observable of update netgroup whitelist
   */
  editNetgroupWhitelist(netgroup: NisNetgroup): Observable<NisNetgroup> {
    const updateParam: NetworkInformationServiceServiceApi.UpdateNisNetgroupByNameParams = {
      name: netgroup.name,
      body: netgroup,
    };
    return this.netgroupWhitelistService.UpdateNisNetgroupByName(updateParam);
  }

  /**
   * Delete curent netgroup whitelist.
   *
   * @returns   Observable of null.
   */
  deleteNetgtoupWhitelist(netgroup: NisNetgroup): Observable<null> {
    return this.netgroupWhitelistService.DeleteNisNetgroupByName({
      name: netgroup.name,
      body: netgroup,
    });
  }

  /**
   * Get all NIS Providers.
   *
   * @returns   Observable of all NIS Providers.
   */
  getNisProviders(): Observable<NisProviders> {
    return this.netgroupWhitelistService.GetNisProviders({});
  }

}
