// Component: c-schedule-info

;(function(angular, undefined) {
  'use strict';

  /**
   * @ngdoc component
   * @name C.scheduleInfo:cScheduleInfo
   * @function
   *
   * @description
   * Reusable component that displays the details of each report schedule,
   * allows the schedule to be deleted or edited
   *
   * @example
   * <c-schedule-info
   *   ng-repeat="schedule in scheduledEmailCtrl.schedules"
   *   schedule="schedule"
   *   on-delete="scheduledEmailCtrl.onDelete(id)"
   *   on-edit="scheduledEmailCtrl.onEdit(filters, reportType)">
   * </c-schedule-info>
   *
   */
  var options = {
    controller: 'scheduleInfoCtrl',
    templateUrl: 'app/reports/components/c-schedule-info/c-schedule-info.html',
    bindings: {

      // the report schedule object
      schedule: '<',

      // delete function defined in scheduled-emails controller
      onDelete: '&',

      // edit function defined in scheduled-emails controller
      onEdit: '&',
    },
  };

  angular
    .module('C.reports')
    .controller('scheduleInfoCtrl', ScheduleInfoCtrlFn)
    .component('cScheduleInfo', options);

  function ScheduleInfoCtrlFn(_, REPORT_TYPES, moment, cScheduleInfoService) {

    var $ctrl = this;

    /**
     * Initializes $ctrl values to be used on template
     */
    $ctrl.$onInit = function onInit() {
      _.assign($ctrl, {
        deleteSchedule: deleteSchedule,
        editSchedule: editSchedule,
        getScheduleDaysString: getScheduleDaysString,
        getScheduleHourString: getScheduleHourString,
        getScheduleTimezoneString: getScheduleTimezoneString,
        shared: {
          inProgress: false,
          fetchingModalData: false,
        },

        // Caching value to avoid this long object path everywhere.
        reportJobParameter:
          $ctrl.schedule.scheduleJobParameters.reportJobParameter,
      });

      $ctrl.reportType = REPORT_TYPES[$ctrl.reportJobParameter.reports[0].type];

      $ctrl.outputFormat = $ctrl.reportJobParameter.reports[0].outputFormat;

      $ctrl.reportFilters = $ctrl.reportJobParameter.reports[0].parameters;
    };

    /**
     * maps through the schedules array and converts integers to day strings
     * using moment
     *
     * @return    {string}    string of days ex: (Monday, Tuesday, Thursday)
     */
    function getScheduleDaysString() {
      return $ctrl.schedule.schedules.map(function mapSchedules(schedule) {
        return moment().weekday(schedule.day).format('dddd');
      }).join(', ');
    }

    /**
     * converts hour integer into human readable time format
     *
     * @return    {string}    string of schedule time ex: 12pm
     */
    function getScheduleHourString() {
      return moment().hour($ctrl.schedule.schedules[0].hour).format('hA');
    }

    /**
     * Get the scheduled timezone.
     *
     * @method   getScheduleTimezoneString
     * @return   {string}    string of schedule timezone.
     */
    function getScheduleTimezoneString() {
      return $ctrl.schedule.schedules[0].timezone;
    }

    /**
     * calls delete method in scheduled-emails controller to delete a report
     */
    function deleteSchedule() {
      $ctrl.shared.inProgress = true;
      $ctrl.onDelete({ id: $ctrl.schedule.id })
        .finally(function() {
          $ctrl.shared.inProgress = false;
        });
    }

    /**
     * pass the reportId and reportFilters to edit function in scheduled-emails
     * controller
     */
    function editSchedule() {
      $ctrl.shared.inProgress = true;
      $ctrl.shared.fetchingModalData = true;
      $ctrl.onEdit({
        filters: cScheduleInfoService.rebuildFilterValues($ctrl.reportFilters,
          $ctrl.schedule, $ctrl.reportJobParameter),
        clearSpinnerFn: clearFetchingDataSpinnerFn
      }).finally(function editScheduleFinally() {
        $ctrl.shared.inProgress = false;
      });
    }

    /**
     * Clears the `fetchingModalData` spinner flag.
     */
    function clearFetchingDataSpinnerFn() {
      $ctrl.shared.fetchingModalData = false;
    }
  }
})(angular);
