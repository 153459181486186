import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataFilter, DataFilterItem, DataFilterValue } from './data-filter';

/**
 * Possible options for number comparison.
 */
export type NumberComparator = '===' | '==' | '>=' | '<=' | '>' | '<';

/**
 * A numberFilter value. This includes the current comparator and number necessary for use in the predicate function.
 */
export interface NumberFilter {
  number: number;
  comparator: NumberComparator;
}

/**
 * Provides a mechanism for filtering a data set based on a specific property's value compared to a numerical value
 * using the defined comparator. Should generally be used in conjunction with NumberPropertyFilterComponent.
 */
export class NumberPropertyFilter implements DataFilter<NumberFilter> {
  /**
   * The property/column name of an object to be filtered by
   */
  key: string;

  /**
   * A display label for a filter. Used to show filter chips
   */
  get tagValues$(): Observable<DataFilterItem[]> {
    return this.currentValue$.pipe(
      map(filterValue => {
        if (filterValue) {
          const { value } = filterValue;
          const { comparator, number } = value;

          // If the comparator is an ~exact match, don't include it in the tag/label/chip value, otherwise do as its
          // pertinent information.
          const val = ['==', '==='].includes(value.comparator) ? number : `${comparator} ${number}`;

          return [{
            label: this.label,
            value: val,
            remove: () => this.removeValue(),
          }];
        }

        return [];
      })
    );
  }

  /**
   * A ui friendly label for the filter name.
   */
  label: string;

  /**
   * Message to show when a filter is locked. If this is not set, the filter is unlocked.
   */
  lockedMessage: string;

  /**
   * The current value of the filter.
   */
  currentValue$ = new BehaviorSubject<DataFilterValue<NumberFilter>>(undefined);

  /**
   * A filter predicate to use for a filter.
   */
  predicate(item: any, filter: any, key?: any): boolean {
    const data: any = item[key];

    if (!data) {
      return false;
    }

    switch (filter.comparator) {
      case '===':
        return data === filter.number;
      case '==':
        // eslint-disable-next-line eqeqeq
        return data == filter.number;
      case '>=':
        return data >= filter.number;
      case '<=':
        return data <= filter.number;
      case '>':
        return data > filter.number;
      case '<':
        return data < filter.number;
    }
  }

  /**
   * Sets the filter's value.
   *
   * @param   value   The value to set.
   */
  setValue(value: NumberFilter) {
    this.currentValue$.next({
      key: this.key,
      value: value,
      predicate: this.predicate,
    });
  }

  /**
   * Removes the requested value from the filter.
   */
  removeValue() {
    this.currentValue$.next(undefined);
  }
}
