<a id="skip-nav" class="skip-to-primary-content" (click)="skipToPrimary()" href="javascript:;">
  {{intl.skipToPrimaryContent}}
</a>
<cog-app-banner></cog-app-banner>
<mat-toolbar class="cohesity-app-bar" [class.hide-controls]="hideControls">
  <div class="cohesity-app-bar-content">
    <span class="app-bar-content-branding">
      <!-- hide the main side nav toggle during login or for large screen -->
      <button
        *ngIf="!showClose && !hideControls && !disableClose"
        [class.app-frame-visually-hidden]="hideNav"
        cogDataId="app-bar-menu-button"
        id="menuButton"
        mat-icon-button
        (click)="toggleMenu()">
        <cog-icon shape="menu"></cog-icon>
      </button>
      <!-- show the close button if configured -->
      <button
        *ngIf="showClose"
        [class.app-frame-visually-hidden]="hideNav"
        cogDataId="app-bar-close-button"
        id="closeButton"
        class="app-bar-close-button"
        mat-icon-button
        (click)="closeButtonClick.emit($event)">
        <mat-icon>close</mat-icon>
      </button>
      <div class="brand-wrapper">
        <ng-content select="cog-app-branding"></ng-content>
        <ng-content select="[cogAppBranding]"></ng-content>
      </div>
    </span>
    <span class="app-bar-content-left">
      <ng-content select="cog-app-bar-left"></ng-content>
    </span>
    <span class="app-bar-content-right">
      <ng-content select="cog-app-bar-right"></ng-content>
    </span>
  </div>
</mat-toolbar>
<mat-sidenav-container class="drawer-container">
  <mat-sidenav role="navigation"
    #navColumn
    class="drawer-column nav-column"
    *ngIf="appNav && !hideNav"
    [(opened)]="isNavOpen"
    [disableClose]="disableClose"
    [mode]="menuMode"
    position="start">
    <ng-content select="cog-app-nav"></ng-content>
    <ng-content select="cog-app-side-nav-footer">
    </ng-content>
  </mat-sidenav>
  <div role="main" id="primary-content" tabindex="0"
    class="app-frame-content-wrapper"  cdkScrollable #contentColumn
    [ngClass]="{'menu-open': isNavOpen, 'menu-closed': !isNavOpen, 'menu-modal': shouldOverlayMenu, 'nav-hidden': hideControls }">
    <ng-content select="cog-app-content"></ng-content>
  </div>
</mat-sidenav-container>
