import { Injectable } from '@angular/core';
import { NavItem } from '@cohesity/helix';
import { IrisContext, isTenantUser } from '@cohesity/iris-core';
import { IBMClusterManagerNavService } from './ibm-cluster-manager-nav.service';
import { StateManagementService } from 'src/app/core/services';

/**
 * This Service overrides the configurations of ClusterManagerNavService for the Ibm BaaS project.
 */
@Injectable({ providedIn: 'root' })
export class IBMBaasClusterManagerNavService extends IBMClusterManagerNavService {

  /**
   * Tenants do not see the runbooks.
   */
  get shouldShowRunbooks(): boolean {
    return !isTenantUser(this.irisContextService.irisContext);
  }

  /**
   * Get the main nav items for this service
   *
   * @param ctx The current cluster context.
   * @param stateManagementService state management context.
   * @returns An array of nav items.
   */
  override getNavItems(ctx: IrisContext, stateManagementService: StateManagementService): NavItem[] {
    const navList: NavItem[] = this.getStandardNavList(ctx, stateManagementService);
    return navList;
  }

  /**
   * Returns a copy of navList for main navigation.
   *
   * @returns The list of nav items.
   */
  override getStandardNavList(irisContext: IrisContext, stateManagementService: StateManagementService): NavItem[] {
    const navList: NavItem[] = [
      this.getDashboardNavs(irisContext, stateManagementService),
      this.getStandardDataProtectionNavOptions(irisContext),
      ...this.getInfrastructureNavItems(irisContext),
      this.getStandardMarketplaceNavOptions(irisContext),
      this.getSystemNavsItems(irisContext),
      this.getReportingNavItem(),
      this.getSettingsNavItems(irisContext),
    ];
    return this.getValidNavItems(navList);
  }

  /**
   * Get the appropriate nav item based on the current scope.
   * For IBM, this goes directly to the cluster reporting and bypasses
   * the speedbump page that mentions it is deprecated.
   *
   * @param scope The current cluster scope
   * @returns The reporting nav item.
   */
  protected getReportingNavItem(): NavItem {
    return {
      displayName: 'reports',
      state: 'reports',
      icon: 'assessment!outline',
      activeStates: ['reporting.detail', 'reporting.schedule'],
    };
  }

  /**
   * Returns a fresh copy of navItems[] for dashboard states that
   * are specific to IBMBaaS and Tenant requirements.
   *
   * @returns The list of dashboard items.
   */
  override getDashboardItems(irisContext: IrisContext): NavItem[] {
    let dashboardItems: NavItem[] = [];
    if (isTenantUser(irisContext)) {
      dashboardItems.push({
        displayName: 'summary',
        state: 'dashboards.ibm-baas',
        icon: 'dashboard',
      });
    } else {
      // Dashboard items not applicable to IBM context
      // matched by display name.
      const excludedItems = ['smartFiles'];
      dashboardItems = super.getDashboardItems(irisContext);
      // Filter out falsy values.
      dashboardItems.filter(Boolean).filter(item => !excludedItems.includes(item.displayName));
    }
    return dashboardItems;
  }

  /**
   * Ibm does not show infrastructure to tenants.
   * This method returns an empty array if user is a tenant.
   *
   * @param irisContext
   * @returns
   */
  getInfrastructureNavItems(irisContext) {
    return isTenantUser(irisContext) ? [] : this.getInfrastructureNavs(irisContext);
  }

  /**
   * This method adds the saas connector nav item for ibm.
   *
   * @param irisContext
   * @returns
   */
  getSystemNavsItems(irisContext) {
    const systemNav = this.getStandardSystemNavOptions(irisContext);
    systemNav.subNavList.unshift(
      {
        displayName: 'saasConnections',
        state:'dms-connections',
      }
    );
    return systemNav;
  }

  /**
   * Ibm does not show settings to tenants.
   * This method returns an empty array if user is a tenant.
   *
   * @param irisContext
   * @returns
   */
  getSettingsNavItems(irisContext) {
    return isTenantUser(irisContext) ? undefined : this.getStandardSettingsNavOptions(irisContext);
  }
}
