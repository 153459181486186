<cog-settings-list-item [invalid]="formGroupErrors"
  cogFormGroup
  [label]="'clusterInterface' | translate">
  <cog-settings-list-item-value-display>
    <ng-container *ngIf="formGroupValues.interfaceGroup">
      {{formGroupValues.interfaceGroup.ifaceGroupName}}
    </ng-container>
    <ng-container *ngIf="!formGroupValues.interfaceGroup">
      {{'autoSelect' | translate}}
    </ng-container>
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor>
    <mat-slide-toggle [formControl]="formGroupControls.auto"
      [inheritDataId]="true"
      class="margin-bottom attach-toggle"
      cogDataId="cluster-interface-auto-select-toggle">
      {{'autoSelect' | translate}}
    </mat-slide-toggle>

    <!-- Select an interface group if interface selection is not set to automatic -->
    <coh-cluster-interface-selector *ngIf="!formGroupValues.auto"
      [control]="formGroupControls.interfaceGroup">
    </coh-cluster-interface-selector>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
