import { Injectable } from '@angular/core';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';

import { BaseProtectionSourceService } from '../shared/base-protection-source.service';
import { PureSourceDataNode } from './pure-source-data-node';
import { PureViewFilters } from './pure-view-filters';
import { PureProtectionTypes } from './pure.constants';

/**
 * Tree service for pure.
 */
@Injectable({
  providedIn: 'root',
})
export class PureSourceTreeService extends BaseProtectionSourceService<PureSourceDataNode> {

  /**
   * A reference to pure view filters, to show a physical, flat list view.
   */
  pureViewFilters: PureViewFilters;

  constructor(
    private irisCtx: IrisContextService,
    readonly translate: TranslateService,
  ) {
    super();
    if (flagEnabled(this.irisCtx.irisContext, 'pureProtectionGroupViewEnabled')) {
      this.pureViewFilters = new PureViewFilters(
        this.filters,
        this.treeTransformer,
      );
    }
  }

  /**
   * Transforms the node object from the api into a Ad Tree node to pass to the tree.
   *
   * @param   node   The original node.
   * @param   level  The level in the tree.
   * @return  An AdSourceDataNode that can be displayed in the tree.
   */
  transformData(node: ProtectionSourceNode, level: number): PureSourceDataNode {
    return new PureSourceDataNode(node, this.irisCtx, level);
  }

  /**
   * Clears the current selection of objects.
   */
  clearSelection() {
    this.dataTreeSelection.currentSelection = {
      autoSelected: [],
      selected: [],
    };
  }

  /**
   * Only expand certain node types by default.
   *
   * @param   node   The treenode to check.
   * @return  True if the node should be expanded by default.
   */
  shouldExpandNodeOnLoad(node: PureSourceDataNode): boolean {
    return node.type !== PureProtectionTypes.kPureProtectionGroup;
  }
}
