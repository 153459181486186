import { Injectable } from '@angular/core';
import { getClusterConfigPartial } from '@cohesity/data-insights/nav';
import { AppName, ClusterConfig } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { StateGuardService } from 'src/app/core/state/state-guard.service';

import { AppServiceConfig, AppServiceType } from '../app-service.config';
import { DataInsightsNavService } from './data-insights-nav.service';
import { DataInsightsGuard } from './data-insights.guard';

/**
 * This service configures the data insights (gaia) service configuration within iris.
 */
@Injectable({ providedIn: 'root' })
export class DataInsightsServiceConfig extends AppServiceConfig {
  serviceType: AppServiceType = 'diaas';

  appName: AppName = 'dataInsights';

  get homeState() {
    return 'gaia';
  }

  get clusterConfigPartial() {
    return {
      name: this.serviceTitle,
      ...getClusterConfigPartial(),
    } as Partial<ClusterConfig>;
  }

  // Conditionally suports global search based on logic in AppLayoutComponent.
  supportsGlobalSearch = true;

  navProvider = this.navService;

  constructor(
    private navService: DataInsightsNavService,
    stateGuardService: StateGuardService,
    translate: TranslateService,
  ) {
    super(translate);
    stateGuardService.registerStateGuards([DataInsightsGuard]);
  }
}
