/* eslint-disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for CohesityApi services
 */
@Injectable({
  providedIn: 'root',
})
export class CohesityApiConfiguration {
  rootUrl: string = '/irisservices/api/v1';
}

export interface CohesityApiConfigurationInterface {
  rootUrl?: string;
}
