/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { StorageDomain } from '../models/storage-domain';
import { CreateCadStorageDomainParam } from '../models/create-cad-storage-domain-param';
import { StorageDomains } from '../models/storage-domains';
import { CreateStorageDomainParam } from '../models/create-storage-domain-param';
import { UpdateStorageDomainParam } from '../models/update-storage-domain-param';
@Injectable({
  providedIn: 'root',
})
class StorageDomainService extends __BaseService {
  static readonly CreateCadStorageDomainsPath = '/cad-storage-domains';
  static readonly GetStorageDomainsPath = '/storage-domains';
  static readonly CreateStorageDomainPath = '/storage-domains';
  static readonly GetStorageDomainByIdPath = '/storage-domains/{id}';
  static readonly UpdateStorageDomainPath = '/storage-domains/{id}';
  static readonly DeleteStorageDomainPath = '/storage-domains/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Create CAD Storage Domains.
   *
   * Create CAD Storage Domains. Instead of returning error, This API will return the CAD Storage Domain mapped to the external target if there is one.
   * @param params The `StorageDomainService.CreateCadStorageDomainsParams` containing the following parameters:
   *
   * - `body`: Specified the request to create a CAD Storage Domain.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateCadStorageDomainsResponse(params: StorageDomainService.CreateCadStorageDomainsParams): __Observable<__StrictHttpResponse<StorageDomain>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/cad-storage-domains`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StorageDomain>;
      })
    );
  }
  /**
   * Create CAD Storage Domains.
   *
   * Create CAD Storage Domains. Instead of returning error, This API will return the CAD Storage Domain mapped to the external target if there is one.
   * @param params The `StorageDomainService.CreateCadStorageDomainsParams` containing the following parameters:
   *
   * - `body`: Specified the request to create a CAD Storage Domain.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateCadStorageDomains(params: StorageDomainService.CreateCadStorageDomainsParams): __Observable<StorageDomain> {
    return this.CreateCadStorageDomainsResponse(params).pipe(
      __map(_r => _r.body as StorageDomain)
    );
  }

  /**
   * Get Storage Domains.
   *
   * Get Storage Domains.
   * @param params The `StorageDomainService.GetStorageDomainsParams` containing the following parameters:
   *
   * - `viewTemplateId`: Specifies a view template id for Storage Domain. Storage Domains with same deduplication and compression settings will be recommended.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which Storage Domains are to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `names`: Filter by a list of Storage Domain names.
   *
   * - `matchPartialNames`: If true, the names in viewNames are matched by any partial rather than exactly matched.
   *
   * - `includeTimeSeriesSchema`: Whether to include Storage Domain time series schema in response.
   *
   * - `includeTenants`: IncludeTenants specifies if Storage Domains of all the tenants under the hierarchy of the logged in user's organization should be returned.
   *
   * - `includeStats`: Whether to include Storage Domain stats in response.
   *
   * - `includeFileCountBySize`: Whether to include Storage Domain file count by size.
   *
   * - `ids`: Filter by a list of Storage Domain ids.
   *
   * - `clusterPartitionIds`: Filter by a list of cluster partition ids.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetStorageDomainsResponse(params: StorageDomainService.GetStorageDomainsParams): __Observable<__StrictHttpResponse<StorageDomains>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.viewTemplateId != null) __params = __params.set('viewTemplateId', params.viewTemplateId.toString());
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.names || []).forEach(val => {if (val != null) __params = __params.append('names', val.toString())});
    if (params.matchPartialNames != null) __params = __params.set('matchPartialNames', params.matchPartialNames.toString());
    if (params.includeTimeSeriesSchema != null) __params = __params.set('includeTimeSeriesSchema', params.includeTimeSeriesSchema.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.includeStats != null) __params = __params.set('includeStats', params.includeStats.toString());
    if (params.includeFileCountBySize != null) __params = __params.set('includeFileCountBySize', params.includeFileCountBySize.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    (params.clusterPartitionIds || []).forEach(val => {if (val != null) __params = __params.append('clusterPartitionIds', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storage-domains`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StorageDomains>;
      })
    );
  }
  /**
   * Get Storage Domains.
   *
   * Get Storage Domains.
   * @param params The `StorageDomainService.GetStorageDomainsParams` containing the following parameters:
   *
   * - `viewTemplateId`: Specifies a view template id for Storage Domain. Storage Domains with same deduplication and compression settings will be recommended.
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which Storage Domains are to be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `names`: Filter by a list of Storage Domain names.
   *
   * - `matchPartialNames`: If true, the names in viewNames are matched by any partial rather than exactly matched.
   *
   * - `includeTimeSeriesSchema`: Whether to include Storage Domain time series schema in response.
   *
   * - `includeTenants`: IncludeTenants specifies if Storage Domains of all the tenants under the hierarchy of the logged in user's organization should be returned.
   *
   * - `includeStats`: Whether to include Storage Domain stats in response.
   *
   * - `includeFileCountBySize`: Whether to include Storage Domain file count by size.
   *
   * - `ids`: Filter by a list of Storage Domain ids.
   *
   * - `clusterPartitionIds`: Filter by a list of cluster partition ids.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetStorageDomains(params: StorageDomainService.GetStorageDomainsParams): __Observable<StorageDomains> {
    return this.GetStorageDomainsResponse(params).pipe(
      __map(_r => _r.body as StorageDomains)
    );
  }

  /**
   * Create a Storage Domain.
   *
   * Create a Storage Domain.
   * @param params The `StorageDomainService.CreateStorageDomainParams` containing the following parameters:
   *
   * - `body`: Specified the request to create a Storage Domain.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateStorageDomainResponse(params: StorageDomainService.CreateStorageDomainParams): __Observable<__StrictHttpResponse<StorageDomain>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storage-domains`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StorageDomain>;
      })
    );
  }
  /**
   * Create a Storage Domain.
   *
   * Create a Storage Domain.
   * @param params The `StorageDomainService.CreateStorageDomainParams` containing the following parameters:
   *
   * - `body`: Specified the request to create a Storage Domain.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateStorageDomain(params: StorageDomainService.CreateStorageDomainParams): __Observable<StorageDomain> {
    return this.CreateStorageDomainResponse(params).pipe(
      __map(_r => _r.body as StorageDomain)
    );
  }

  /**
   * Get a Storage Domain by id.
   *
   * Get a Storage Domain by id.
   * @param params The `StorageDomainService.GetStorageDomainByIdParams` containing the following parameters:
   *
   * - `id`: Specified the Storage Domain id to fetch.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTimeSeriesSchema`: Whether to include Storage Domain time series schema in response.
   *
   * - `includeTenants`: Whether to include Storage Domains that belong to Tenants. This param is only effective when the User has privilege to view Storage Domain details of a tenant.
   *
   * - `includeStats`: Whether to include Storage Domain stats in response.
   *
   * - `includeFileCountBySize`: Whether to include Storage Domain file count by size.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetStorageDomainByIdResponse(params: StorageDomainService.GetStorageDomainByIdParams): __Observable<__StrictHttpResponse<StorageDomain>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeTimeSeriesSchema != null) __params = __params.set('includeTimeSeriesSchema', params.includeTimeSeriesSchema.toString());
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.includeStats != null) __params = __params.set('includeStats', params.includeStats.toString());
    if (params.includeFileCountBySize != null) __params = __params.set('includeFileCountBySize', params.includeFileCountBySize.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/storage-domains/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StorageDomain>;
      })
    );
  }
  /**
   * Get a Storage Domain by id.
   *
   * Get a Storage Domain by id.
   * @param params The `StorageDomainService.GetStorageDomainByIdParams` containing the following parameters:
   *
   * - `id`: Specified the Storage Domain id to fetch.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeTimeSeriesSchema`: Whether to include Storage Domain time series schema in response.
   *
   * - `includeTenants`: Whether to include Storage Domains that belong to Tenants. This param is only effective when the User has privilege to view Storage Domain details of a tenant.
   *
   * - `includeStats`: Whether to include Storage Domain stats in response.
   *
   * - `includeFileCountBySize`: Whether to include Storage Domain file count by size.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetStorageDomainById(params: StorageDomainService.GetStorageDomainByIdParams): __Observable<StorageDomain> {
    return this.GetStorageDomainByIdResponse(params).pipe(
      __map(_r => _r.body as StorageDomain)
    );
  }

  /**
   * Update a Storage Domain.
   *
   * Update a Storage Domain.
   * @param params The `StorageDomainService.UpdateStorageDomainParams` containing the following parameters:
   *
   * - `id`: Specified the Storage Domain id to update.
   *
   * - `body`: Specified the request to update a Storage Domain.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateStorageDomainResponse(params: StorageDomainService.UpdateStorageDomainParams): __Observable<__StrictHttpResponse<StorageDomain>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/storage-domains/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StorageDomain>;
      })
    );
  }
  /**
   * Update a Storage Domain.
   *
   * Update a Storage Domain.
   * @param params The `StorageDomainService.UpdateStorageDomainParams` containing the following parameters:
   *
   * - `id`: Specified the Storage Domain id to update.
   *
   * - `body`: Specified the request to update a Storage Domain.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateStorageDomain(params: StorageDomainService.UpdateStorageDomainParams): __Observable<StorageDomain> {
    return this.UpdateStorageDomainResponse(params).pipe(
      __map(_r => _r.body as StorageDomain)
    );
  }

  /**
   * Delete a Storage Domain.
   *
   * Delete a Storage Domain.
   * @param params The `StorageDomainService.DeleteStorageDomainParams` containing the following parameters:
   *
   * - `id`: Specified the Storage Domain id to delete.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteStorageDomainResponse(params: StorageDomainService.DeleteStorageDomainParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/storage-domains/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a Storage Domain.
   *
   * Delete a Storage Domain.
   * @param params The `StorageDomainService.DeleteStorageDomainParams` containing the following parameters:
   *
   * - `id`: Specified the Storage Domain id to delete.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteStorageDomain(params: StorageDomainService.DeleteStorageDomainParams): __Observable<null> {
    return this.DeleteStorageDomainResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module StorageDomainService {

  /**
   * Parameters for CreateCadStorageDomains
   */
  export interface CreateCadStorageDomainsParams {

    /**
     * Specified the request to create a CAD Storage Domain.
     */
    body: CreateCadStorageDomainParam;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetStorageDomains
   */
  export interface GetStorageDomainsParams {

    /**
     * Specifies a view template id for Storage Domain. Storage Domains with same deduplication and compression settings will be recommended.
     */
    viewTemplateId?: number;

    /**
     * TenantIds contains ids of the tenants for which Storage Domains are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Filter by a list of Storage Domain names.
     */
    names?: Array<string>;

    /**
     * If true, the names in viewNames are matched by any partial rather than exactly matched.
     */
    matchPartialNames?: boolean;

    /**
     * Whether to include Storage Domain time series schema in response.
     */
    includeTimeSeriesSchema?: boolean;

    /**
     * IncludeTenants specifies if Storage Domains of all the tenants under the hierarchy of the logged in user's organization should be returned.
     */
    includeTenants?: boolean;

    /**
     * Whether to include Storage Domain stats in response.
     */
    includeStats?: boolean;

    /**
     * Whether to include Storage Domain file count by size.
     */
    includeFileCountBySize?: boolean;

    /**
     * Filter by a list of Storage Domain ids.
     */
    ids?: Array<number>;

    /**
     * Filter by a list of cluster partition ids.
     */
    clusterPartitionIds?: Array<number>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateStorageDomain
   */
  export interface CreateStorageDomainParams {

    /**
     * Specified the request to create a Storage Domain.
     */
    body: CreateStorageDomainParam;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetStorageDomainById
   */
  export interface GetStorageDomainByIdParams {

    /**
     * Specified the Storage Domain id to fetch.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Whether to include Storage Domain time series schema in response.
     */
    includeTimeSeriesSchema?: boolean;

    /**
     * Whether to include Storage Domains that belong to Tenants. This param is only effective when the User has privilege to view Storage Domain details of a tenant.
     */
    includeTenants?: boolean;

    /**
     * Whether to include Storage Domain stats in response.
     */
    includeStats?: boolean;

    /**
     * Whether to include Storage Domain file count by size.
     */
    includeFileCountBySize?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateStorageDomain
   */
  export interface UpdateStorageDomainParams {

    /**
     * Specified the Storage Domain id to update.
     */
    id: number;

    /**
     * Specified the request to update a Storage Domain.
     */
    body: UpdateStorageDomainParam;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteStorageDomain
   */
  export interface DeleteStorageDomainParams {

    /**
     * Specified the Storage Domain id to delete.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { StorageDomainService }
