import { Injectable, Optional } from '@angular/core';
import { UIRouterGlobals } from '@uirouter/core';
import { PassthroughOptions } from 'src/app/shared';

@Injectable({
  providedIn: 'root'
})
export class PassthroughOptionsService implements Required<PassthroughOptions> {
  constructor(@Optional() private uiRouterGlobals: UIRouterGlobals) {}

  /**
   * Holds the current selected cluster.
   */
  private _accessClusterId: number;

  /**
   * Get the value of currently selected cluster from state.
   */
  get accessClusterId(): number {
    return this._accessClusterId || this.uiRouterGlobals?.params.cid;
  }

  /**
   * Override the state value for selected cluster. This is useful for dialog
   * scenarios where there might not be any state params, and the cluster
   * needs to be st.
   */
  set accessClusterId(value: number) {
    this._accessClusterId = value;
  }

  /**
   * Holds the current selected region.
   */
  private _regionId: string;

  /**
   * Get the value of currently selected region from state.
   */
  get regionId(): string {
    return this._regionId || this.uiRouterGlobals?.params.regionId;
  }

  /**
   * Override the state value for selected region. This is useful for dialog
   * scenarios where there might not be any state params, and the region
   * needs to be st.
   */
  set regionId(value: string) {
    this._regionId = value;
  }

  /**
   * Get request params for passthrough options. This is typically used
   * for API requests.
   *
   * @return The passthrough params values for an API request.
   */
  get requestParams(): PassthroughOptions {
    if (!this.accessClusterId && !this.regionId) {
      return {};
    }

    if (this.regionId) {
      return {regionId: this.regionId};
    }

    return {accessClusterId: this.accessClusterId};
  }

  /**
   * Get request params for passthrough options as headers. This is useful for
   * when using these params for non swagger generated services.
   *
   * @return The passthrough params values for http headers.
   */
  get requestHeaders(): Record<string, string> {
    if (!this.accessClusterId && !this.regionId) {
      // Return undefined here as when this is used with this.httpClient.get,
      // {} as headers causes an error.
      return;
    }

    if (this.regionId) {
      return {regionId: this.regionId};
    }

    return {accessClusterId: String(this.accessClusterId)};
  }

  /**
   * Get request params for ui router state params.
   *
   * @return The passthrough params values as ui router state params.
   */
  get routerParams(): {cid: number; regionId: string} {
    return {
      cid: this.accessClusterId,
      regionId: this.regionId,
    };
  }

  /**
   * Function to hydrate params with passthrough options if the passthrough
   * params were not present.
   *
   * @param params The params.
   * @returns The params with passthrough options.
   */
  getPassthroughOptionsParams<T>(params: T & PassthroughOptions): T & PassthroughOptions {
    return {
      ...params,
      regionId: params.regionId || this.regionId,
      accessClusterId: params.accessClusterId || this.accessClusterId,
    };
  }

  /**
   * Helper function to reset overridden passthrough values.
   */
  reset() {
    this._accessClusterId = null;
    this._regionId = null;
  }
}
