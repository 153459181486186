import { Component, Inject, Input, Optional, ViewEncapsulation } from '@angular/core';

import { HelixIntlService } from '../../../helix-intl.service';
import { AutoTableComponent } from '../../auto-table/auto-table.component';
import { TableComponent } from '../table.component';

/**
 * @description
 * Table multi-action container that appears in table header when items are selected.
 *
 * @example
 *  <cog-table>
 *    <cog-table-multi-action>
 *      <button>Delete</button>
 *      <button>Edit</button>
 *    </cog-table-multi-action>
 *  </cog-table>
 */
@Component({
  selector: 'cog-table-multi-action',
  templateUrl: './table-multi-action.component.html',
  styleUrls: ['./table-multi-action.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TableMultiActionComponent<T> {
  /**
   * Message shown when all available items in selection model (including those that are
   * not on the current page or invisible) are selected.
   */
  @Input() allSelectedLabel: string;

  /**
   * Message shown for "Select All" button which selects all items available in selection model.
   */
  @Input() selectAllLabel: string;

  /**
   * Message shown for "Clear All" button, to clear all selected items.
   */
  @Input() clearLabel: string;

  /**
   * Message shown when some items from selection model are selected.
   */
  @Input() anySelectedLabel: string;

  /**
   * True if selection status messages should be shown.
   */
  @Input() displayStatus = true;

  /**
   * True if the actions row should not be auto hidden without selection.
   */
  @Input() alwaysVisible = false;

  /**
   * Multi actions component will hide/show itself based on its parent table selection state.
   */
  get hidden(): boolean {
    if (this.alwaysVisible) {
      return false;
    }

    if (this.table.selection && this.table.selection.isMultipleSelection()) {
      return this.table.selection.isEmpty();
    }

    return true;
  }

  /**
   * Returns number of rows that are selected.
   */
  get selectedCount(): number {
    if (this.table.selection) {
      return this.table.selection.selected.length;
    }

    return 0;
  }

  /**
   * Returns length of table data source.
   */
  get sourceLength(): number {
    if (this.table) {
      return this.table.dataSource.data.filter(row => this.table.canSelectRow(row)).length;
    }

    return 0;
  }

  /**
   * Get a reference to the table from the parent table or auto table
   */
  get table(): TableComponent<T> {
    return this.parentTable || this.parentAutoTable.tableComponent;
  }

  /**
   * Constructor.
   */
  constructor(
    @Optional() @Inject(TableComponent) private parentTable: TableComponent<T>,
    @Optional() @Inject(AutoTableComponent) private parentAutoTable: AutoTableComponent,
    public intl: HelixIntlService
  ) { }

  /**
   * Return true if selection model has a value.
   */
  hasValue(): boolean {
    if (this.table.selection) {
      return this.table.selection.hasValue();
    }

    return false;
  }

  /**
   * Returns true if all items are selected.
   */
  isAllSelected(): boolean {
    return this.sourceLength === this.selectedCount;
  }

  /**
   * Indicates is all visible items are selected on current page.
   */
  isAllVisibleSelected(): boolean {
    return this.selectedCount >= this.table.renderedData.length;
  }

  /**
   * Clears table selected items.
   */
  clearAll() {
    if (this.table.selection) {
      this.table.selection.clear();
    }
  }

  /**
   * Toggles table selection.
   */
  toggleSelection() {
    const { selection } = this.table;
    if (selection) {
      if (!selection.isEmpty()) {
        selection.clear();
      } else {
        this.table.toggleSelectAll();
      }
    }
  }

  /**
   * Selects all items in selection model.
   */
  selectAll() {
    const newSelection = this.table.dataSource.data.filter(row => this.table.canSelectRow(row));

    this.table.selection.select(...newSelection);
  }
}
