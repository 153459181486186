import { distinctUntilChanged, map, filter, shareReplay } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';

/**
 * The map can use string or number keys
 */
type ItemMap<T> = Map<string | number, T>;

/**
 * Id could be a string or a number.
 */
type ItemId = string | number;

export class ItemCache<T = any> {
  /**
   * The main item cache
   */
  private cache = new BehaviorSubject<ItemMap<T>>(new Map());

  constructor(private getId: (val: T) => ItemId) {}

  /**
   * Saves an array of items in the cache
   *
   * @param   items   The items to cache
   */
  cacheItems(items: T[]) {
    const cache = this.cache.value;
    (items || []).forEach(item => cache.set(this.getId(item), item));
    this.cache.next(cache);
  }

  /**
   * Gets an observable of an item in the cache. This can be called before the item
   * has been added to the cache and will resolve if it is ever added.
   *
   * @param   id   The item id
   * @returns An observable that will resolve when the item has been added to the cache
   */
  getItem(id: ItemId): Observable<T> {
    return this.cache.pipe(
      map(cache => cache.get(id)),
      filter(item => item !== undefined),
      distinctUntilChanged(),
      shareReplay(1)
    );
  }

  /**
   * Gets an observable of multiple items the cache. This can be called before the items
   * have been added to the cache and will resolve if it is ever added.
   *
   * @param   ids   The item ids
   * @returns An observable that will resolve when the items have been added to the cache
   */
  getItems(ids: ItemId[]): Observable<T[]> {
    if (!ids.length) {
      return of([]);
    }
    return this.cache.pipe(
      map(cache => (ids || []).map(id => cache.get(id))),
      filter(items => items.every(item => item !== undefined)),
      distinctUntilChanged((x, y) => x.every((item, index) => item === y[index])),
      shareReplay(1)
    );
  }

  /**
   * Returns true if an item exists in the cache
   *
   * @param id The item id
   * @returns True if the item is in the cache. A null value is considered part of the cache.
   */
  hasItem(id: ItemId): boolean {
    return this.cache.value.has(id);
  }

  /**
   * Clears the entire cache.
   */
  reset() {
    this.cache.next(new Map());
  }
}
