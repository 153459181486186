import { Injectable } from '@angular/core';
import {
  ApplicationSourceRegistrationParams,
  ApplicationSourceRegistrationServiceApi,
  BackupSourcesServiceApi,
} from '@cohesity/api/private';
import {
  GetRegistrationInfoResponse,
  ProtectionSource,
  ProtectionSourcesServiceApi,
  RegisteredSourceInfo,
  RegisterProtectionSourceParameters,
} from '@cohesity/api/v1';
import { McmSourceRegistration, SourceServiceApi } from '@cohesity/api/v2';
import { IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { Poller } from '@cohesity/utils';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, take, timeout } from 'rxjs/operators';
import { PassthroughOptionsService, PollingService } from 'src/app/core/services';
import { Environment, envTypes, HostType, PhysicalEntityType } from 'src/app/shared/constants';

/**
 * Oracle db credentials interface.
 */
interface OracleDbCredentials {
  username: string;
  password: string;
}

/**
 * Oracle Registration service provides methods to register physical host and application.
 */
@Injectable({
  providedIn: 'root',
})
export class OracleRegistrationService {

  /**
   * Array of Registration poller references.
   */
  registrationPollers: Poller<GetRegistrationInfoResponse>[] = [];

  /**
   * Specify Region ID for DMaaS registration.
   */
  private _regionId: string;

  /**
   * Specify Connection ID for DMaaS registration.
   */
  private _connectionId: number;

  /**
   * Setter for Region ID.
   */
  set regionId(regionId: string) {
    this._regionId = regionId;
  }

  /**
   * Setter for Connection ID.
   */
  set connectionId(connectionId: number) {
    this._connectionId = connectionId;
  }

  constructor(
    private heliosSourceService: SourceServiceApi,
    private pollingService: PollingService,
    private appRegApi: ApplicationSourceRegistrationServiceApi,
    private backupApi: BackupSourcesServiceApi,
    private protectionSourcesApi: ProtectionSourcesServiceApi,
    private irisCtx: IrisContextService,
    private passthroughOptionsService: PassthroughOptionsService
  ) { }

  /**
   * Polls registration info until host or application registration is completed.
   *
   * @param    id             The id of the source to poll for.
   * @param    pollInterval   Time in sec between poll attempts.
   * @param    timeoutLimit   Timeout limit in sec.
   * @returns  An observable of the source registration info.
   */
  pollUntilRegistrationIsComplete(
    id: number,
    interval = 3,
    timeoutLimit = 180,
  ): Observable<RegisteredSourceInfo> {
    const params = { ids: [id], ...this.passthroughOptionsService.requestHeaders };
    const pollConfig = {
      acceptFirstIteration: false,
      interval,
      iterator: () => this.protectionSourcesApi.ListProtectionSourcesRegistrationInfo(params),
      shouldContinue: (source: GetRegistrationInfoResponse) => this.isRegistrationInProgress(source)
    };

    const registrationPoller = this.pollingService.createPoll(pollConfig);

    this.registrationPollers.push(registrationPoller);

    return registrationPoller.poller.pipe(
      filter(info => !this.isRegistrationInProgress(info)),

      // map to the registration info
      map(info => info.rootNodes[0].registrationInfo),
      take(1),

      // don't poll forever
      timeout(timeoutLimit * 1000),
    );
  }

  /**
   * Determines whether registration in progress.
   *
   * @param    info   registration info response.
   * @returns  true if the authenticationStatus is still in progress.
   */
  private isRegistrationInProgress(info: GetRegistrationInfoResponse) {
    return get(info, 'rootNodes[0].registrationInfo.authenticationStatus') !== 'kFinished';
  }

  /**
   * Uses the private API to register a source as physical server.
   * This is being used for Oracle Physical registration, which doesn't support the public API yet.
   *
   * @param   hostName       The hostname of the server to register.
   * @param   forceRegister  Default to false. Not being used right now.
   * @param   connectionId   bifrost connection id to associate source with
   * @param   scanName specifies the scanName information of oracle cluster registration
   * @returns An observable of the server response.
   */
  registerSource(hostName: string, forceRegister = false, connectionId: number, scanName?: string): Observable<any> {
    // @type SourceRegistrationParams but private API yaml docs are
    // incomplete.
    const physicalSourceParams = {
      entity: {
        physicalEntity: {
          hostType: 1,
          name: scanName || hostName,
          // Indicates '6' as 'kOracleCluster' registration and by default it is 'kHost' registration type.
          type: scanName ? 6 : 1,
        },
        type: 6,
      },
      entityInfo: {
        endPoint: hostName,
        hostType: 1,
        type: 6,
      },
      forceRegister: forceRegister,
      sourceSideDedupEnabled: true,

      // Encapsulates additional settings specified by user.
      // Refer RegisteredEntityParams within magneto/base/magneto.proto for
      // details.
      registeredEntityParams: {
        vlanParams: undefined,
        throttlingPolicy: {
          isThrottlingEnabled: false,
        },
      },
      connectionId
    };
    const headers = this.passthroughOptionsService.requestHeaders;
    return this.backupApi.registerSource(physicalSourceParams, headers);
  }

  /**
   * Registers a physical source and polling until registration is complete.
   * This will wait after the physical source registration until
   * the registration is complete.
   *
   * @param    hostName   The hostname to register
   * @param    connectionId specifies connection id information
   * @param    scanName     specifies the scanName info during oracle cluster registration
   * @returns  An observable of the Oracle ProtectionSource.
   */
  registerPhysicalAndPolling(hostName: string, connectionId: number, scanName?: string): Observable<ProtectionSource> {

    // Register physical source
    return this.registerSource(hostName, false, connectionId, scanName).pipe(
      // Wait until the host finishes registering,
      // then return the result of the original registration api call.
      mergeMap((source: any) =>
        this.pollUntilRegistrationIsComplete(source.entity.id).pipe(map(() => source))
      ),
    );
  }

  /**
   * Makes private api calls to update Oracle db credentials.
   *
   * @param   entityId  host entity id to get source data.
   * @param   credentials  db credentials.
   * @returns Observable of boolean. True if update is successful.
   */
  updateDbCredentials(entityId: number | string, credentials: OracleDbCredentials): Observable<boolean> {
    // Application params
    const appParams = {
      appEnvVec: [envTypes.kOracle],
      ownerEntity: undefined,
      appCredentialsVec: [
        {
          envType: envTypes.kOracle,
          credentials,
        },
      ],
    };

    return this.backupApi.getSource({entityId}).pipe(
      filter(source => !!source || !!source.entityHierarchy),
      mergeMap((source: any) => {
        // Update owner entity info.
        appParams.ownerEntity = source.entityHierarchy.entity;
        return this.appRegApi.updateAppOwner(appParams as ApplicationSourceRegistrationParams);
      })
    );
  }

  /**
   * Register Oracle application source on Helios.
   *
   * @params   endpoint   Source endpoint.
   * @returns  Observable of protection source.
   */
  dmsRegisterOracle(endpoint: string): Observable<McmSourceRegistration> {
    const params: SourceServiceApi.McmRegisterProtectionSourceParams = {
      regionId: this._regionId,
      body: {
        environment: Environment.kPhysical,
        connectionId: this._connectionId,
        physicalParams: {
          endpoint: endpoint,
          physicalType: PhysicalEntityType.kHost,
          applications: [Environment.kOracle],
          hostType: HostType.kLinux
        }
      }
    };

    return this.heliosSourceService.McmRegisterProtectionSource(params);
  }

  /**
   * Register Oracle application source in a single step.
   *
   * @params   endpoint   Source endpoint.
   * @returns  Observable of protection source.
   */
  registerOracleSingleStep(endpoint: string): Observable<ProtectionSource> {
    const params = {
      endpoint: endpoint,
      environment: Environment.kPhysical,
      physicalType: 'kHost',
      hostType: HostType.kLinux,
      physicalParams: {
        applications: [Environment.kOracle]
      }
    } as RegisterProtectionSourceParameters;

    return this.protectionSourcesApi.RegisterProtectionSource({body: params});
  }

  /**
   * Checks for the appropriate environment whether DMaaS or On-Prem and
   * calls the register function accordingly.
   *
   * @param    id   ID of the node to be registered
   * @returns  Observable of either ProtectionSource or McmSourceRegistration
   */
  registerOracleDmsOrOnPrem(id: string): Observable<any> {
    if (isDmsScope(this.irisCtx.irisContext)) {
      return this.dmsRegisterOracle(id);
    }
    return this.registerOracleSingleStep(id);
  }

  /**
   * Edits Oracle registration on Helios.
   *
   * @params   endpoint   Source endpoint.
   * @params   id         Source registration Id.
   * @returns  Observable of protection source.
   */
  dmsEditRegistration(endpoint: string, id: string): Observable<McmSourceRegistration> {
    const updateParams: SourceServiceApi.UpdateProtectionSourceRegistrationMixin1Params = {
      id,
      body: {
        environment: Environment.kPhysical,
        connectionId: this._connectionId,
        physicalParams: {
          endpoint: endpoint,
          physicalType: PhysicalEntityType.kHost,
          applications: [Environment.kOracle],
          hostType: HostType.kLinux
        }
      }
    };
    return this.heliosSourceService.UpdateProtectionSourceRegistrationMixin1(updateParams);
  }
}
