import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { EbsVolumeInfo } from '@cohesity/api/v1';
import { DataTreeNodeContext, DataTreeNodeDetail } from '@cohesity/helix';
import { AwsSourceDataNode } from '../aws-source-data-node';

interface Ec2SpecialParameters {
  excludeEbsVolumes: string[];
}

interface SourceSpecialParameters {
  ec2SpecialParameters: Ec2SpecialParameters;
}

/**
 * This component is used to set and update source special params for ec2 instance nodes.
 * It is not created directly, but returned from a call to getSpecialParametersComponent
 * in AwsSourceTreeService.
 */
@Component({
  selector: 'coh-aws-ec2-options',
  templateUrl: './aws-ec2-options.component.html',
  styleUrls: ['./aws-ec2-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AwsEc2OptionsComponent implements DataTreeNodeDetail<AwsSourceDataNode> {
  /**
   * The node context, including info about the node and it's selection status.
   */
  @Input() nodeContext: DataTreeNodeContext<AwsSourceDataNode>;

  /**
   * Gets the node from the nodeContext.
   */
  get node(): AwsSourceDataNode {
    return this.nodeContext.node;
  }

  /**
   * Gets the current options for the node. This either gets them from the selection options, or
   * the default params for the node.
   */
  get currentOptions(): SourceSpecialParameters {
    return this.nodeContext.selection.getOptionsForNode(this.node.id) || {};
  }

  /**
   * Gets the list of excluded ebs volumes, if any, from the special parameters.
   */
  get excludeEbsVolumes(): string[] {
    return (this.currentOptions.ec2SpecialParameters || {}).excludeEbsVolumes || [];
  }

  /**
   * Get the available ebs volumes that can be excluded.
   */
  get ebsVolumes(): EbsVolumeInfo[] {
    return this.node.ebsVolumes || [];
  }

  /**
   * Form Array getter.
   */
  get volumeFormArray(): UntypedFormArray {
    return this.form.controls.volumes as UntypedFormArray;
  }

  /**
   * The form to contain the options.
   */
  form: UntypedFormGroup;

  /**
   * Whether form values are changed.
   */
  showExcludeEbsVolumesWarning = false;

  constructor(private fb: UntypedFormBuilder, private cdr: ChangeDetectorRef) {}

  /**
   * Updates the form based on the current options setting in preparation for displaying the form
   * dialog.
   */
  updateForm() {
    this.form = this.fb.group({
      volumes:  new UntypedFormArray(
        this.ebsVolumes.map(volume => new UntypedFormControl({
          value: !this.excludeEbsVolumes.includes(volume.id),
          disabled: volume.isRootDevice,
        }))
      ),
    });

    this.form.valueChanges.subscribe(() => {
      const excludedVolumes = (this.form.getRawValue().volumes || [])
        .map((checked, i) => checked ? null : this.ebsVolumes[i].id)
        .filter(id => id !== null);
      this.showExcludeEbsVolumesWarning = excludedVolumes.length;
    });

    this.showExcludeEbsVolumesWarning = this.excludeEbsVolumes?.length > 0;
  }

  /**
   * Updates the selection options after the form has been saved and the dialog has been closed.
   */
  onSave() {
    const options = {
      sourceId: this.node.id,
      ec2SpecialParameters: {
        excludeEbsVolumes: (this.form.getRawValue().volumes || [])
          .map((checked, i) => checked ? null : this.ebsVolumes[i].id)
          .filter(id => id !== null),
      },
    };

    if (options.ec2SpecialParameters.excludeEbsVolumes.length) {
      this.nodeContext.selection.setOptionsForNode(this.node.id, options);
    } else {
      this.nodeContext.selection.removeOptionsForNode(this.node.id);
    }

    this.cdr.detectChanges();
    this.showExcludeEbsVolumesWarning = false;
  }
}
