import { HttpHeaders, HttpParameterCodec, HttpParams } from '@angular/common/http';

import { PassthroughOptions } from './private-models';

/**
 * Converts an arbitrary object to `HttpParams` object. Note that `HttpParams` requires the param value to be a
 * string. This method automatically tries to serialize the values by calling `toString()` on those.
 *
 * @param     object   The object, containing the params.
 * @returns   The `HttpParams` object created using `object`.
 */
export const convertToHttpParams = (object: any): HttpParams => {
  let params = new HttpParams();

  for (const [key, value] of Object.entries(object)) {
    if (Array.isArray(value)) {
      value.forEach(val => (params = params.append(key, val.toString())));
    } else {
      params = params.set(key, value.toString());
    }
  }

  return params;
};

/**
 * Creates and returns HTTP headers object which is suitable for passing to
 * the HTTP client for making pass-through calls.
 *
 * @param params The parameters object containing pass-through options.
 * @returns The HTTP headers object.
 */
export function getPassThroughHeaders(params: PassthroughOptions): HttpHeaders {
  let headers = new HttpHeaders();

  if (params.accessClusterId) {
    headers = headers.append('accessClusterId', String(params.accessClusterId));
  } else if (params.regionId) {
    headers = headers.append('regionId', params.regionId);
  }

  return headers;
}

/**
 * Provides full encoding and decoding of URL params and query-string values.
 *
 * Angular uses HttpUrlEncodingCodec which encodes all but un-encodes certain
 * characters that are allowed to be part of the query according to
 * IETF RFC 3986: https://www.rfc-editor.org/rfc/rfc3986.
 * Un-encoded characters include - '@',':','$',',',';','+','=','?','/'
 *
 * Refer: https://github.com/angular/angular/blob/8.2.13/packages/common/http/src/params.ts#L81-L92
 *
 * This codec can be used to ensure all components are fully encoded.
 */
export class AllParameterEncodingCodec implements HttpParameterCodec {
  /**
   * Encodes a key name for a URL parameter or query-string.
   *
   * @param key The key name.
   * @returns The encoded key name.
   */
  encodeKey(key: string): string { return encodeURIComponent(key); }

  /**
   * Encodes the value of a URL parameter or query-string.
   *
   * @param value The value.
   * @returns The encoded value.
   */
  encodeValue(value: string): string { return encodeURIComponent(value); }

  /**
   * Decodes an encoded URL parameter or query-string key.
   *
   * @param key The encoded key name.
   * @returns The decoded key name.
   */
  decodeKey(key: string): string { return decodeURIComponent(key); }

  /**
   * Decodes an encoded URL parameter or query-string value.
   *
   * @param value The encoded value.
   * @returns The decoded value.
   */
  decodeValue(value: string) { return decodeURIComponent(value); }
}
