import { Injectable } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NetworkResetServiceApi, NodePowerOperation, PlatformServiceApi, ResetStates } from '@cohesity/api/v2';
import { Observable, throwError } from 'rxjs';
import { DialogService } from './dialog.service';
import { AjaxHandlerService } from '@cohesity/utils';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NetworkingService {
  constructor(
    private dialogService: DialogService,
    private networkResetService: NetworkResetServiceApi,
    private platformService: PlatformServiceApi,
    private ajaxService: AjaxHandlerService,
    ) {
  }

  /**
   * Open dialog to reset Networking.
   *
   * @method showNetworkResetDialog
   * @returns Observable of the dialog result.
   */
  showNetworkResetDialog(): Observable<MatDialogRef<any>> {
    const dialogConfig = {
      width: '35vw',
    };
    return this.dialogService.showDialog('reset-networking-dialog', null, dialogConfig);
  }

  /**
   * Open dialog to reset Networking confirmation.
   *
   * @method showNetworkResetConfirmationDialog
   * @returns Observable of the dialog result.
   */
  showNetworkResetConfirmationDialog(): Observable<MatDialogRef<any>> {
    const dialogConfig = {
      width: '35vw',
    };
    return this.dialogService.showDialog('reset-networking-confirmation-dialog', null, dialogConfig);
  }

  /**
   * Performing nodes action.
   *
   * @method updateNetworkAction
   * @param clusterAction type of cluster action to be performed.
   * @returns Observable of either of the api action.
   */
  updateNetworkAction(clusterAction: string): Observable<any> {
    if (clusterAction === 'restore') {
      const resetNodesNetworkParams: NetworkResetServiceApi.ResetNodesNetworkParams = {
        body: {
          operation: clusterAction
        }
      };
      return this.networkResetService.ResetNodesNetwork(resetNodesNetworkParams);
    } else {
      const setNodePowerObj = {
        body: {
          operation: clusterAction as NodePowerOperation['operation']
        }
      };
      return this.platformService.SetNodePower(setNodePowerObj);
    }
  }

  /**
   * Opens network dialog according to the reset state.
   *
   * @method openNetworkResetActionDialog
   */
  openNetworkResetActionDialog() {
    this.networkResetService
      .GetNetworkResetStates()
      .pipe(
        catchError((error) => {
          this.ajaxService.handler(error);
          return throwError(error);
        })
      )
      .subscribe((data: ResetStates) => {
        const hasSomeNonPreRebootNodes = data.some((node) => node.state !== 'kPreReboot');
        if (hasSomeNonPreRebootNodes) {
          this.showNetworkResetDialog().subscribe();
        } else {
          this.showNetworkResetConfirmationDialog().subscribe();
        }
      });
  }

}
