import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { GraphComponentComponent } from './components/graph-component/graph-component.component';
import { TopologyGraphComponent } from './components/topology-graph/topology-graph.component';
import { License } from 'yfiles';
import { SvgIconGroupComponent } from './components/svg-icon-group/svg-icon-group.component';

/**
 * This module can only be used along with a yfiles license.
 * To import the module, import HelixYfilesModule.withLicense(licenseValue)
 */
@NgModule({
  imports: [CommonModule],
  declarations: [GraphComponentComponent, SvgIconGroupComponent, TopologyGraphComponent],
  exports: [GraphComponentComponent, SvgIconGroupComponent, TopologyGraphComponent],
})
export class HelixYfilesModule {
  static withLicense(license: any): ModuleWithProviders<HelixYfilesModule> {
    License.value = license;
    return {
      ngModule: HelixYfilesModule,
    };
  }
}
