import { Injectable } from '@angular/core';
import { Transition } from '@uirouter/core';

import { McmViewService, StateManagementService } from '../../services';
import { GuardPriority, GuardResult, StateGuard } from '../state-guard';

/**
 * Remote Clusters  Guard redirects all state traffic to the new page based on the
 * Feature flag 'ngRemoteClusters'.
 */
@Injectable({
  providedIn: 'root',
})
export class RemoteClustersGuard implements StateGuard {
  /**
   * This guard priority is set to App, which indicates it should run as part of the main application
   * after all bootstrapping and initializing is completed.
   */
  guardPriority = GuardPriority.App;

  /**
   * Match old state to ng-remote-clusters.
   *
   * TODO: As the state names for the edit/create/view states are different,
   *       need to take care of matchCriteria for them when the respective
   *       components are made.
   */
  matchCriteria = {
    to: 'remote-clusters',
  };

  /**
   * Constructor.
   */
  constructor(readonly stateManagementService: StateManagementService, readonly mcmView: McmViewService) {}

  /**
   * Handles the redirection to the ng remote clusters page based on feature flag.
   *
   * @param    transition   The UI Router Transition.
   * @returns  A redirect transition.
   */
  onStart(transition: Transition): GuardResult {
    if (this.mcmView.showFrame$.value) {
      // In case of iframe, maintain old state.
      return true;
    }
    const params = transition.params();
    if (this.stateManagementService.canUserAccessState('ng-remote-clusters', params, true) &&
      this.mcmView.canAccessState('ng-remote-clusters')) {
      return transition.router.stateService.target('ng-remote-clusters', params);
    }
  }
}
