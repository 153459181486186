import { CdkScrollable } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { debounceTime, filter, startWith, map } from 'rxjs/operators';
import { AutoDestroyable } from '@cohesity/utils';

import { Document } from '../folder-browser.models';
import { SelectionModel } from '@angular/cdk/collections';
import { ObservableInput } from 'ngx-observable-input';
import { Observable, combineLatest } from 'rxjs';

/**
 * Should fetch more items when the scroller is within the last 20% of the height.
 */
const scrollLoadThreshold = 0.2;

/**
 * This component shows a list of files for a directory in a table
 */
@Component({
  selector: 'coh-directory-list',
  templateUrl: './directory-list.component.html',
  styleUrls: ['./directory-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DirectoryListComponent extends AutoDestroyable implements OnInit {
  /**
   * The list of documents to show.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput(null) @Input('documents') documents$: Observable<Document[]>;

  /**
   * Whether there are more documents available to load.
   */
  @Input() isPartialListing = false;

  /**
   * The selection model for the table.
   */
  @Input() selection: SelectionModel<Document>;

  /**
   * To show the loader or not.
   */
  @Input() isLoading = false;

  /**
   * Set true, to only allow folder selection.
   */
  @Input() onlySelectFolders = false;

  /**
   * Callback to decide whether a row item is selectable.
   */
  @Input() canSelectRowFn: (item: Document) => boolean;

  /**
   * Callback to decide whether a row can be clicked to navigate through.
   */
  @Input() canNavigateRowFn: (item: Document) => boolean;

  /**
   * Callback to customize the icon of a row item.
   */
  @Input() rowIconProviderFn: (item: Document) => string;

  /**
   * Event is emitted when a user clicks an anchor row to browse to a directory.
   */
  @Output() browseToPath = new EventEmitter<string>();

  /**
   * This fires whenever isPartialListing is set to true and the use user has scrolled
   * to near the bottom of the table.
   */
  @Output() loadMore = new EventEmitter<void>();

  /**
   * Scrollable component for the table
   */
  @ViewChild(CdkScrollable, { static: true }) tableScroller: CdkScrollable;

  /**
   * This is used to track whether a parent of this directory is selected, if so, all of the items will
   * show as selected, but disalbed.
   */
  isParentDirectorySelected = false;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.tableScroller
      .elementScrolled()
      .pipe(
        debounceTime(200),
        filter(() => {
          // Trigger loading new data when the scroller is 20% of the way to the bottom
          const height = this.tableScroller.getElementRef().nativeElement.getBoundingClientRect().height;
          const fromBottom = this.tableScroller.measureScrollOffset('bottom');
          return fromBottom < height * scrollLoadThreshold;
        }),
        filter(() => !this.isLoading && this.isPartialListing),
        this.untilDestroy()
      )
      .subscribe(() => {
        this.loadMore.emit();
      });

    // Listen for changes to the files and the selection to determine if a parent directory has already
    // been selected. If it has, the checkbox should show as checked, but disabled.
    combineLatest([this.documents$, this.selection.changed.pipe(startWith(this.selection.selected))])
      .pipe(
        map(([documents]) => {
          if (!documents || !documents.length || !this.selection || this.selection.isEmpty()) {
            return false;
          }
          const pathTokens = documents[0].fullPath.split('/');
          pathTokens.pop();
          const parentDirectory = pathTokens.join('/');
          return this.selection.selected.some(selected => parentDirectory.startsWith(selected.fullPath));
        }),
        this.untilDestroy()
      )
      .subscribe(parentSelected => {
        this.isParentDirectorySelected = parentSelected;
        this.cdr.detectChanges();
      });
  }

  /**
   * Use the file path for the table track by function.
   */
  trackBy(index: number, document: Document) {
    return document.fullPath;
  }

  /**
   * Handle selection toggle and update the selection list to remove the child directory/files.
   *
   * @param   row   The row that was selected.
   */
  selectionUpdate(row: Document) {
    const selectedData = this.selection.selected;
    selectedData.forEach(selection => {
      if (selection.fullPath.startsWith(`${row.fullPath}/`)) {
        this.selection.deselect(selection);
      }
    });
    this.selection.toggle(row);
  }

  /**
   * Handle row click and toggle the selection if the parent directory isn't selected.
   *
   * @param   row   The file row that was clicked.
   */
  onRowClicked(row: Document) {
    if (this.isRowDisabled(row)) {
      return;
    }

    // Disallow the option to select the files if onlySelectFolders is true
    if (!this.onlySelectFolders || row.isFolder) {
      this.selection.toggle(row);
    }
  }

  /**
   * Returns whether the row item should be disabled.
   *
   * @param row The row item
   * @returns A boolean value to indicate whether the row should be disabled.
   */
  isRowDisabled(row: Document): boolean {
    return this.isParentDirectorySelected || !this.canSelectRowFn?.(row);
  }

  /**
   * Returns whether the row item can be clicked to navigate through.
   *
   * @param row The row item
   * @returns A boolean value to indicate whether the item can be navigated through.
   */
  isRowNavigable(row: Document): boolean {
    return row.isFolder && (this.canNavigateRowFn?.(row) ?? true);
  }
}
