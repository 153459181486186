import { GetProtectionRunProgressBody, ObjectRunResult, ReplicationTargetResult } from '@cohesity/api/v2';
import { clamp } from 'lodash';
import { decorateInterface } from '@cohesity/utils';
import { Environment, StatusType } from 'src/app/shared';

import { RunProgress } from './common.models';

/**
 * Data model for Protection Run Details page - Replication tab.
 */
export class ProtectionRunReplication extends decorateInterface<RunProgress>() {
  /**
   * Replication Result ID.
   */
  readonly id: number;

  /**
   * Start time of attempt in milliseconds.
   */
  readonly startTime?: number;

  /**
   * End time of attempt in milliseconds.
   */
  readonly endTime?: number;

  /**
   * Queue time of attempt in milliseconds.
   */
  readonly queueTime?: number;

  /**
   * Duration of run in milliseconds.
   */
  readonly duration?: number;

  /**
   * Status of snapshot.
   */
  readonly status?: StatusType;

  /**
   * Size in bytes transferred.
   */
  readonly physicalSizeTransferred?: number;

  /**
   * Size in bytes transferred.
   */
  readonly logicalBytesTransferred?: number;

  /**
   * Target clustser ID.
   */
  readonly targetId?: number;

  /**
   * Name of the target cluster.
   */
  readonly targetName?: string;

  /**
   * Message (usually error) for the replication.
   */
  readonly message?: string;

  /**
   * Task ID for a replication protection run.
   */
  readonly replicationTaskId?: string;

  /**
   * Specifies the number of metadata actions completed during the protection run.
   */
  readonly entriesChanged?: number;

  /**
   * Specifies the legal hold status for a replication target.
   */
  readonly onLegalHold?: boolean;

  /**
   * Specifies the direction of the replication.
   * If the snapshot is replicated to this cluster, then isInBound is true.
   * If the snapshot is replicated from this cluster to another cluster, then isInBound is false.
   */
  readonly isInBound?: boolean;

  /**
   * Whether replication is happening to a cloud target
   */
  isCloudReplication?: boolean;

  /**
   * Whether view based replication was performed. It will be set to true if magneto
   * performed view based replication. Currently only Office365 adapter uses this.
   */
  multiObjectReplication?: boolean;

  /**
   * Whether the replication environment is kSQL.
   */
  isSQL: boolean;

  /**
   * Constructor.
   *
   * @param   reference   Return data structure of Protection Run Replication from API
   */
  constructor(
    public reference: ReplicationTargetResult,
    environment: string,
    objectRunInfo?: ObjectRunResult) {
    super({});

    const {
      awsTargetConfig,
      azureTargetConfig,
      clusterId,
      clusterName,
      endTimeUsecs = 0,
      entriesChanged = 0,
      isInBound,
      message,
      onLegalHold,
      percentageCompleted,
      queuedTimeUsecs,
      replicationTaskId,
      startTimeUsecs = 0,
      status,
      stats: {
        physicalBytesTransferred = 0,
        logicalBytesTransferred = 0,
      } = {},
      multiObjectReplication,
    }: ReplicationTargetResult = reference;

    this.id = clusterId;
    this.startTime = startTimeUsecs / 1000;
    this.endTime = endTimeUsecs / 1000;
    this.queueTime = queuedTimeUsecs / 1000;

    // In case of failed replication or queued job,
    // endTimeUsecs is not valid in some scenario
    let duration = 0;
    if (startTimeUsecs > 0 && endTimeUsecs > 0) {
      duration = Math.max(0, endTimeUsecs - startTimeUsecs) / 1000;
    }
    this.duration = duration;

    this.status = status;
    this.percentageCompleted = Math.round(clamp(percentageCompleted, 0, 100));
    this.physicalSizeTransferred = physicalBytesTransferred;
    this.logicalBytesTransferred = logicalBytesTransferred;
    this.onLegalHold = onLegalHold;
    this.multiObjectReplication = multiObjectReplication;
    this.isInBound = isInBound;
    this.targetId = clusterId;
    this.targetName = clusterName;

    this.isSQL = environment === Environment.kSQL;
    this.isCloudReplication = !!(awsTargetConfig || azureTargetConfig);

    if (this.isCloudReplication) {
      this.targetName = awsTargetConfig ? awsTargetConfig.name : azureTargetConfig.name;
      this.targetId = awsTargetConfig ? awsTargetConfig.sourceId : azureTargetConfig.sourceId;

      if (objectRunInfo) {
        this.logicalBytesTransferred = objectRunInfo.localSnapshotInfo.snapshotInfo.stats.logicalSizeBytes;
      }
    }

    this.replicationTaskId = replicationTaskId;

    this.message = message;

    this.entriesChanged = entriesChanged;
  }

  /**
   * Updates progress info this replication target.
   *
   * @param  runProgress Protection run progress data.
   */
  updateProgress = (runProgress: GetProtectionRunProgressBody) => {
    const replication = runProgress.replicationRun?.find(({ clusterId }) => clusterId === this.id);
    this.percentageCompleted = replication?.percentageCompleted;
    this.events = replication?.events;
  };
}
