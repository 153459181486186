import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DataTreeDetailDirective, DataTreeNodeContext } from './data-tree-detail.directive';
import { DataTreeControl } from './shared/data-tree-control';
import { DataTreeSelection } from './shared/data-tree-selection-model';
import { DataTreeSource } from './shared/data-tree-source';
import { DataTreeNode } from './shared/data-tree.model';

/**
 * This is the root component for a data tree. It can be used to render any hierachical data as a flat
 * tree and provide additional logic around selecting individual items as well as auto selecting (and excluding)
 * portions of the tree hierarchy. For a complete usage example, see the data-tree component in the demo
 * application.
 *
 * The strings used in this example come from DataTreeIntl, which can be replaced via dependency
 * injection for the specific cases needed.
 */
@Component({
  templateUrl: './data-tree.component.html',
  selector: 'cog-data-tree',
  styleUrls: ['./data-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DataTreeComponent<T extends DataTreeNode<any>> implements OnDestroy, OnInit {

  /**
   * The data source for the tree.
   */
  @Input() data: DataTreeSource<T>;

  /**
   * The tree control provides methods for working with the tree's structure, such as finding ancestors or
   * descendant nodes as well as keeping track of which nodes are currently expanded. The data source subscribes
   * to changes from the tree control to determine which nodes should be rendered.
   */
  @Input() treeControl: DataTreeControl<T>;

  /**
   * The tree's current selection model. This tracks items which have been selected, auto selected, or excluded. It also
   * provides a mechanism to add custom selection data to a given node if necessary.
   */
  @Input() treeSelection: DataTreeSelection<T>;

  /**
   * Optional function used to decorate the node's context when it is generated.
   */
  @Input() nodeDecoratorFn: (ctx: DataTreeNodeContext<T>) => DataTreeNodeContext<T> = null;

  /**
   * This is a template to the tree as a content children which are used to render item-specific details for a node.
   * This is where the node's name, icon, details, metadata, etc... should go.
   */
  @ContentChildren(DataTreeDetailDirective, { descendants: true }) treeDetails: QueryList<DataTreeDetailDirective>;

  /**
   * The data tree uses virtual scrolling to enable strong performance. This scroller emits changes whenever the
   * viewport has been modified.
   */
  @ViewChild(CdkVirtualScrollViewport, { static: false }) set scroller(scroller: CdkVirtualScrollViewport) {
    if (scroller && this.data.scroller !== scroller) {
      this.data.scroller = scroller;
      this.cdr.detectChanges();
    }
  }

  /**
   * Use to clean up subscriptions on destroy.
   */
  private _destroy = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef) {

  }

  /**
   * Trigger change detection whenever data source changes.
   */
  ngOnInit() {
    this.data.currentView$.pipe(
      takeUntil(this._destroy)
    ).subscribe(() => this.cdr.markForCheck());
  }

  /**
   * Cleanup the change detection listener on destroy.
   */
  ngOnDestroy() {
    this._destroy.next();
  }

}
