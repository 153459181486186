<ng-container [formGroup]="form.formGroup">
  <mat-slide-toggle formControlName="enabled"
    cogDataId="enable-pre-post-script-{{label}}"
    (change)="toggleScriptForm($event)">
    <span class="flex-row-xs vertical-align">
      <span>{{ label | translate }}</span>
      <cog-icon shape="helix:status-info"
        size="sm"
        [matTooltip]="'job.settings.prePostScripts.tooltip.' + label | translate"
        matTooltipPosition="right">
      </cog-icon>
    </span>
  </mat-slide-toggle>

  <div *ngIf="form.formGroup.value.enabled" class="form-group-wrapper">
    <div *ngFor="let script of scriptsFormArray.controls; index as i"
      class="flex-row-sm"
      [formGroup]="script">
      <div class="form-control-wrapper">
        <div class="flex-row-sm">
          <!-- Shell type -->
          <mat-form-field class="shell-type-form-field">
            <mat-label>{{ 'shellType' | translate }}</mat-label>
            <mat-select cogDataId="{{label}}-shell-type-{{i}}"
              formControlName="shellType">
              <mat-option *ngFor="let option of shellTypeOptions"
                cogDataId="option-{{option}}"
                [inheritDataId]="true"
                [value]="option"
                [disabled]="disableShellTypeOption(i, option)">
                {{ 'shellType.' + option | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- Timeout -->
          <mat-form-field class="timeout-form-field">
            <mat-label>{{ 'timeoutSecs' | translate }}</mat-label>
            <input cogDataId="{{label}}-script-timeout-{{i}}"
              matInput
              type="number"
              formControlName="timeout"
              [min]="0">
          </mat-form-field>
        </div>

        <!-- Path form control -->
        <mat-form-field class="margin-bottom">
          <mat-label>{{ 'scriptPath' | translate }}</mat-label>
          <input cogDataId="{{label}}-script-path-{{i}}"
            matInput
            formControlName="path">
          <mat-hint align="start">{{ 'scriptPathHint' | translate }}</mat-hint>
          <mat-error>{{'errors.required' | translate}}</mat-error>
        </mat-form-field>

        <!-- Script parameters -->
        <mat-form-field class="parameters-form-field">
          <mat-label>{{ 'scriptParameters' | translate }}</mat-label>
          <input cogDataId="{{label}}-script-parameters-{{i}}"
            matInput
            formControlName="parameters">
          <mat-hint align="start">{{ 'optional' | translate }}</mat-hint>
        </mat-form-field>

        <div class="margin-top">
          <mat-checkbox cogDataId="{{label}}-script-continue-on-error-{{i}}"
            formControlName="continueOnError">
            {{ 'job.settings.prePostScripts.continueBackupOnFail' | translate }}
          </mat-checkbox>
        </div>
      </div>

      <coh-form-array-action-control
        cogDataId="actions"
        [inheritDataId]="true"
        [formArrayLength]="scriptsFormArray.controls.length"
        [index]="i"
        [addActionDisabled]="addActionDisabled(i)"
        (controlAdded)="addScript(i)"
        (controlRemoved)="removeScript(i)">
      </coh-form-array-action-control>
    </div>
  </div>
</ng-container>
