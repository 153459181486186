import { Pipe, PipeTransform } from '@angular/core';
import humanizeDuration from 'humanize-duration';
import { isFinite } from 'lodash';
import { duration, unitOfTime } from 'moment';
import { HelixIntlService } from '../../helix-intl.service';

/**
 * @ngdoc Pipe
 * @description
 *    Converts a duration to humanized string. By default, the value is assumed to be in milliseconds. The units of the
 *    given value can be supplied as pipe argument. Supply the third argument to use it in full format.
 *
 *    Leverages the package 'humanize-duration' (https://www.npmjs.com/package/humanize-duration) to make it more
 *    accurate as this is a known issue in moment.humanize (https://github.com/moment/moment/issues/348)
 *
 * @example
 *    {{1000 | cogDuration}} => '1s'
 *    {{2000 | cogDuration}} => '2s'
 *    {{600000 | cogDuration}} => '10m'
 *    {{624100 | cogDuration}} => '10m 24s'
 *    {{2 | cogDuration:'days'}} => '2d'
 *    {{30 | cogDuration:'h':true}} => '1 day, 6 hours'
 *    {{14 | cogDuration:'d':true}} => '2 weeks'
 */
@Pipe({
  name: 'cogDuration',
  standalone: true
})
export class DateDurationPipe implements PipeTransform {
  /**
   * The units in which the duration is to be shown.
   *
   * Note: The translate keys are of the form 'duration.y', 'duration.mo' etc.
   */
  units = ['y', 'mo', 'w', 'd', 'h', 'm', 's'];

  /**
   * The humanizer to implement the short form.
   */
  shortHumanizer: humanizeDuration.Humanizer;

  constructor(private intlSvc: HelixIntlService) {
    this.shortHumanizer = humanizeDuration.humanizer({
      language: 'shortEn',
      languages: {
        shortEn: this.units.reduce((out, unit) => ({
          ...out,
          [unit]: this.intlSvc.duration[unit]
        }), {})
      },
    });
  }

  /**
   * Transforms the duration (considering `unit` if supplied) to human readable string.
   *
   * @param    input   The value to transform.
   * @param    unit    The input value unit.
   * @param    fullFormat True, if the output should be in full format.
   * @return   The human readable string of the duration.
   */
  transform(input: number, unit?: unitOfTime.DurationConstructor, fullFormat?: boolean): string {
    const commonParams = {
      units: this.units as any,
      largest: 3,
      round: true,
    };

    if (typeof input !== 'number' || !isFinite(input)) {
      return this.intlSvc.naNotAvailable;
    }

    // Convert the value to ms according to the unit provided ('ms' by default)
    const durationValueInMs = duration(input, unit || 'ms').valueOf() as number;

    return fullFormat ? humanizeDuration(durationValueInMs, commonParams) :
      this.shortHumanizer(durationValueInMs, {
        ...commonParams,
        spacer: '',
        delimiter: ' ',
      });
  }
}
