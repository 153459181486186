import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  /**
   * Indicates if general page styling should be disabled.
   */
  _isDisabled = false;

  set isDisabled(newVal: boolean) {
    this._isDisabled = newVal;
  }

  get isDisabled(): boolean {
    return this._isDisabled;
  }

  constructor() { }
}
