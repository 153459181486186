import { SnackBarService } from '@cohesity/helix';
import { ConfirmationDialogComponent } from '@cohesity/shared-dialogs';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { DialogService } from 'src/app/core/services';
import {
  CaModel,
} from 'src/app/shared/dialogs/certificate-authority/certificate-authority-interface/certificate-authority-interface.model';

export function buildSettingsRules(
  result: CaModel['typeOfCa'][],
  snackBarService: SnackBarService,
  translateService: TranslateService,
  dialogService: DialogService,
  stateService: StateService
) {

  if (!result) {
    return;
  }

  if (result.find(item => item === 'Self-Signed')) {
    dialogService.showDialog(ConfirmationDialogComponent, {
      title: 'certificateAuthorityDialog.confirmationDialog.selfSigned.title',
      message: 'certificateAuthorityDialog.confirmationDialog.selfSigned.message',
      cogBannerMessage: 'certificateAuthorityDialog.confirmationDialog.selfSigned.cogBannerWarn',
      hasCogBanner: true,
      confirmLabel: 'yes',
      cancelLabel: 'no'
    }).subscribe((res) => {
      if (res) {
        // TODO - On click yes to save self-signed certificate, To be implemented API Integration
        snackBarService.openWithAction(
          translateService.instant('certificateAuthorityDialog.snackBar.message'),
          translateService.instant('certificateAuthorityDialog.snackBar.action')
        ).subscribe(() => {
          stateService.go('certificate-management.managed');
        });
      }
    });
    return;
  }

  if (result.find(item => item === 'External CA')) {
    // TODO - On click save will trigger api call to be implemented on this line

    snackBarService.openWithAction(
      translateService.instant('certificateAuthorityDialog.snackBar.message'),
      translateService.instant('certificateAuthorityDialog.snackBar.action')
    ).subscribe(() => {
      stateService.go('certificate-management.external');
    });

    dialogService.showDialog(ConfirmationDialogComponent, {
      title: 'certificateAuthorityDialog.confirmationDialog.externalCa.title',
      message: 'certificateAuthorityDialog.confirmationDialog.externalCa.message',
      confirmLabel: 'certificateAuthorityDialog.confirmationDialog.externalCa.confirm',
      cancelLabel: 'certificateAuthorityDialog.confirmationDialog.externalCa.cancel'
    }).subscribe((res) => {
      if (res) {
        stateService.go('certificate-management.external');
      }
    });
    return;
  }

  // TODO - On click save will trigger api call to be implemented on this line

  snackBarService.openWithAction(
    translateService.instant('certificateAuthorityDialog.snackBar.message'),
    translateService.instant('certificateAuthorityDialog.snackBar.action')
  ).subscribe(() => {
    stateService.go('certificate-management.managed');
  });
}
