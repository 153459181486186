<cog-settings-list-item [invalid]="control.invalid"
  cogFormGroup
  [label]="'taskName' | translate">
  <cog-settings-list-item-value-display [matTooltip]="control.value">
    {{control.value}}
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor>
    <mat-form-field>
      <input [formControl]="control"
        [inheritDataId]="true"
        cogDataId="task-name"
        [placeholder]="'taskName' | translate"
        matInput>
        <mat-error *ngIf="control.errors?.required">
          {{'errors.required' | translate}}
        </mat-error>
    </mat-form-field>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
