// Module: Access Management - Edit
import { isOneHeliosAppliance } from '@cohesity/iris-core';

/*
 * NOTE: The name Principal is a generic reference to any user or group.
 * This term comes from the naming convention in the API.
 */

;(function(angular) {
  'use strict';

  angular
    .module('C.accessManagement')
    .controller('modifyPrincipalCtrl', modifyPrincipalCtrlFn);

  // pageConfig is passed from state or model resolver and $uibModalInstance
  // will be mocked in case of page view
  function modifyPrincipalCtrlFn(_, $scope, $state, $q, $translate, cUtils,
    NgUserStoreService, UserService, NgUserServiceApi, GroupService, evalAJAX, ActiveDirectoryService, IdpService,
    SourceService, ENUM_ENTITY_TYPE, ENUM_AUTH_PRINCIPAL_TYPES, ENV_GROUPS,
    cMessage, $rootScope, $log, PubSourceService, pageConfig, $uibModalInstance,
    FEATURE_FLAGS, PubJobServiceFormatter, LocaleService, ClusterService, NgIrisContextService,
    FORMATS) {

    var $ctrl = this;
    $ctrl.isOneHeliosAppliance = isOneHeliosAppliance(NgIrisContextService.irisContext);

    var defaultUserConfig = {
      // This model domain gets updated to the first domain item after domain
      // list is loaded from the API.
      domain: ENUM_AUTH_PRINCIPAL_TYPES.includes(pageConfig.realm) ?
        pageConfig.realm.toUpperCase() : 'LOCAL',
      effectiveTimeMsecs: Date.clusterNow(),
      roles: [],
      restricted: false,
      type: pageConfig.type || 'user',
      _isDeletable: true,
      _principalType: pageConfig.realm === 'ad' ? 'ad' : 'sso',
    };

    // If user does not have PRINCIPAL_VIEW priv then we show a simplified
    // profile with only email address and change password fields.
    var hasViewUsersPrivilege = $rootScope.user.privs.PRINCIPAL_VIEW;

    // If LOCAL state param provided then overwrite `defaultUserConfig._principalType`.
    if (!ENUM_AUTH_PRINCIPAL_TYPES.includes(pageConfig.realm)) {
      defaultUserConfig._principalType =
        pageConfig.type === 'user' ? 'local_user' : 'local_group';
    }

    // Will be updated to hold the appropriate success message keys depending on
    // the particular circumstances.
    var deferredSuccessMessage = {};

    var parentSources = [];
    var physicalEntityParentSourceId;

    $scope.ENUM_ENTITY_TYPE = ENUM_ENTITY_TYPE;
    $scope.cUtils = cUtils;

    // Model for Roles ui-select
    $scope.rolesList = [];

    $scope.shared = {
      changePassword: false,
      effectiveDate: Date.clusterNow(),

      // Moves the header components inside the body and sets a different title.
      hideHeader: pageConfig.hideHeader,

      minExpirationDate: Date.now(),
      inModal: pageConfig.inModal,
      protectionSources: [],
      protectionViews: [],
      restrictedParentSources: {},
      allowedTypes: pageConfig.allowedTypes ||
        ['local_user', 'local_group', 'ad', 'sso'],
    };

    // Model for the new/edited principal
    $scope.principal = {};

    // List of principals to populate the principals ui-select
    $scope.listOfPrincipals = [];

    // List of domains to populate the domains ui-select for
    // Active Directory and SSO principal creation
    $scope.adDomainsList = [];
    $scope.ssoDomainsList = [];

    // List of SSO Users and SSO Groups to populate ui-select
    $scope.ssoUsersList = [];
    $scope.ssoGroupsList = [];

    _.assign($ctrl, {
      domainUsers: [],
      isTenantUser: $rootScope.isTenantUser(),
      FORMATS: FORMATS,
      hasViewUsersPrivilege: hasViewUsersPrivilege,

      $onInit: $onInit,
      filterDomains: filterDomains,
      onChangeType: onChangeType,
      canRestrictPrincipal: canRestrictPrincipal,
    });

    /**
     * Activate the controller
     *
     * @method   activate
     */
    function $onInit() {
      getData();
    }

    /**
     * close the modal or send user to principal listing page
     *
     * @method  cancel
     */
    $scope.cancel = function cancel() {
      if (pageConfig.inModal) {
        $uibModalInstance.dismiss();
      } else {
        gotoPrincipalListingPage();
      }
    };

    /**
     * on create/edit success close the modal or send the user to principal
     * listing page
     *
     * @method   onSaveSuccess
     */
    function onSaveSuccess() {
      // Change the locale if in edit mode and if the user is the logged in user
      if (FEATURE_FLAGS.localizationEnabled && !$scope.createMode &&
        $scope.principal._isLoggedInUser) {
        ClusterService.changeLocale($scope.principal._locale);
      }
      if (pageConfig.inModal) {
        $uibModalInstance.close($scope.principal);
      } else {
        cMessage.success(deferredSuccessMessage);
        gotoPrincipalListingPage();
      }
    }

    /**
     * goto a principal listing page
     *
     * @method   gotoPrincipalListingPage
     */
    function gotoPrincipalListingPage() {
      $state.go('access-management.user-groups');
    }

    /**
     * Validates the effective and expired times for the user.
     *
     * @method   validateTheDates
     */
    function validateTheDates() {
      // Special validation between effective and expired times.
      if ($scope.shared.effectiveDate && $scope.shared.expirationDate) {
        // Expired time cannot be older than than effective time. Invalid!
        $scope.shared.principalForm.endDate.$setValidity('min',
        $scope.shared.effectiveDate <= $scope.shared.expirationDate);
      } else {
        // Clear the model value because user cleared the date picker.
        $scope.principal.expiredTimeMsecs = undefined;
        $scope.shared.principalForm.endDate.$setValidity('min', true);
      }
    }

    /**
     * Sets the minimum expiration date based on the effective date.
     * Ensures the expiration date is not before today or the effective date
     *
     * @method   setMinExpirationDate
     */
    function setMinExpirationDate() {
      $scope.shared.minExpirationDate = $scope.shared.effectiveDate > Date.now() ? $scope.shared.effectiveDate : Date.now();
    };

    /**
     * Handles form submission (for create and edit) and upon successful
     * create/update of the principal(s) updates any restricted access sources
     * if necessary.
     *
     * @method  submitForm
     */
    $scope.submitForm = function submitForm() {
      var deferred;

      if ($scope.shared.principalForm.$invalid) {
        return;
      }

      // Do not send lastUpdatedTime.
      $scope.principal.lastUpdatedTimeMsecs = undefined;

      // If user has disabled File Access then remove the Primary Group.
      if (!$scope.principal._hasPrimaryGroup) {
        $scope.principal.primaryGroupName = undefined;
      }

      // Remove null entries which can happen with old users created before 6.3.
      $scope.principal.additionalGroupNames =
        _.compact($scope.principal.additionalGroupNames);

      // For any kComputer, change to kUser prior to POST/PUT.
      ($scope.principal.principals || []).forEach(
        function scrubPrincipal(principal) {
          if (principal.objectClass === 'kComputer') {
            principal.objectClass = 'kUser';
          }
        }
      );

      deferred = $q.defer();

      deferred.promise =
        $scope.createMode ? createPrincipal() : updatePrincipal();

      deferred.promise.then(
        function deferredSuccess(sids) {

          var successDeferred = $q.defer();

          if ($scope.user.privs.PRINCIPAL_MODIFY && !$ctrl.isOneHeliosAppliance) {
            // there's an additional API call to make
            if (sids && sids.length > 0 && sids[0] !== undefined){
              successDeferred.promise = updateLimitedAccessSources(sids);
            }
          } else {
            // wrap it up. there's nothing left to do.
            // TODO: or is there? is an API call neecessary to ensure
            // the principal(s) have no restricted access sources defined?
            successDeferred.resolve('nothing-to-do');
          }

          successDeferred.promise.then(onSaveSuccess,
            function updateSourcesFailure(resp) {

              // API error on secondary call.
              // Ensure createMode is no longer active so subsequent submits
              // will route through updatePrincipal() and not createPrincipal().
              // TODO: break the updateLimitedAccessSources() API endpoint
              // and test this.
              $scope.createMode = false;

              // convey the error message
              evalAJAX.errorMessage(resp);
            }
          ).finally(function UpdatedSourcesFinally() {
            $scope.submitting = false;
          });

        },
        function deferrredErr(resp) {
          evalAJAX.errorMessage(resp);
          $scope.submitting = false;
        }
      );

    };

    /**
     * Reduces the a list of principals, hashed by domain into a single flat
     * list of principals.
     *
     * @method   _reducePrincipals
     * @param    {Array}    domainUsers    List of SMB principals, hashed by
     *                                     domain.
     * @returns  {Array}    Flat list of SMB Principal objects.
     */
    function _reducePrincipals(domainUsers) {
      return _.reduce(domainUsers,
        function reduceDomainPrincipals(list, domain) {
          _.forEach(domain.principals,
            function forEachPrincipal(principal) {
              list.push({
                domain: principal.domain,
                name: principal.name || principal.principalName,
                sid: principal.sid,
                type: principal.objectClass === 'kUser' ? 'user' : 'group',
              });
            }
          );
          return list;
        }, []
      );
    }

    /**
     * Calls the API to create a new principal
     *
     * @method     createPrincipal
     *
     * @return     {promise}  promise to resolve the create request.
     */
    function createPrincipal() {
      var principal = $scope.principal;
      var deferred = $q.defer();
      var principalsToAdd = [];
      var usersToAdd = 0;
      var groupsToAdd = 0;
      var commonPrincipalProperties;

      $scope.submitting = true;

      if (ENUM_AUTH_PRINCIPAL_TYPES.includes(principal._principalType)) {
        commonPrincipalProperties = {
          // repeated properties (for all ad and sso principals)
          roles: principal.roles,
          description: principal.description,
          domain: principal.domain,
          restricted: principal.restricted,
        };

        // AD Principals
        if (principal._principalType === 'ad') {
          principalsToAdd = principal.principals.map(
            function mapPrincipals(currentPrincipal) {
              if (currentPrincipal.objectClass === 'kUser') {
                usersToAdd++;
              } else {
                groupsToAdd++;
              }

              return _.assign({
                // custom properties (specific to ad principal)
                principalName: currentPrincipal.principalName,
                objectClass: currentPrincipal.objectClass,
              }, commonPrincipalProperties);
            }
          );
        } else if (principal._principalType === 'sso') {
          // Either SSO Users or SSO Groups has to be present
          _.forEach(principal.ssoUsers,
            function buildPrincipals(ssoUser) {
              usersToAdd++;

              principalsToAdd.push(_.assign({
                // custom properties (specific to sso principal)
                principalName: ssoUser,
                objectClass: 'kUser',
              }, commonPrincipalProperties));
            }
          );

          _.forEach(principal.ssoGroups,
            function buildPrincipals(ssoGroup) {
              groupsToAdd++;

              principalsToAdd.push(_.assign({
                // custom properties (specific to sso principal)
                principalName: ssoGroup,
                objectClass: 'kGroup',
              }, commonPrincipalProperties));
            }
          );
        }

        deferred.promise = principal._principalType === 'ad' ?
          ActiveDirectoryService.addPrincipals(principalsToAdd) :
          IdpService.addPrincipals(principalsToAdd);

        // Customize messaging for users, groups, or combination
        if (usersToAdd && groupsToAdd) {
          deferredSuccessMessage.titleKey =
            'accessEdit.messages.created.hybrid.title';
          deferredSuccessMessage.textKey =
            'accessEdit.messages.created.hybrid.text';
        } else if (usersToAdd) {
          deferredSuccessMessage.titleKey = (usersToAdd === 1) ?
            'accessEdit.messages.created.user.title' :
            'accessEdit.messages.created.users.title';
          deferredSuccessMessage.textKey = (usersToAdd === 1) ?
            'accessEdit.messages.created.user.text' :
            'accessEdit.messages.created.users.text';
        } else if (groupsToAdd) {
          deferredSuccessMessage.titleKey = (groupsToAdd === 1) ?
            'accessEdit.messages.created.group.title' :
            'accessEdit.messages.created.groups.title';
          deferredSuccessMessage.textKey = (groupsToAdd === 1) ?
            'accessEdit.messages.created.group.text' :
            'accessEdit.messages.created.groups.text';
        }

      } else if (principal._principalType === 'local_group') {
        // Local Group
        deferredSuccessMessage.titleKey =
          'accessEdit.messages.created.group.title';
        deferredSuccessMessage.textKey =
          'accessEdit.messages.created.group.text';

        // Reassign the name property.
        principal.name = principal.username;

        // Assign the AD principals
        if ($ctrl.domainUsers.length) {
          principal.isSmbPrincipalOnly = true;
          principal.smbPrincipals = _reducePrincipals($ctrl.domainUsers);
        }

        deferred.promise = GroupService.createGroup(principal);
      } else {
        // Local User or Helios User
        deferredSuccessMessage.titleKey =
          'accessEdit.messages.created.user.title';
        deferredSuccessMessage.textKey =
          'accessEdit.messages.created.user.text';
        deferred.promise = UserService.createUser(principal);
      }

      return deferred.promise.then(
        function createPrincipalSuccess(resp) {
          // TODO(Sam): Move helios as a separate component. he-1.2
          if (['LOCAL', 'HELIOS'].includes(
            principal.domain.toUpperCase())) {
            // Adds sid to the local principal.
            principal.sid = resp.sid;

            // return an array with the sid of the created user
            return [resp.sid];
          } else {
            // If it is an SSO user/users. Then save the response to the
            // 'principals' inside the principal object to make it work similar
            // to AD users.
            if (principal._principalType === 'sso') {
              principal.principals = resp;
            }

            // return an array of sids of the newly created principals
            return resp.map(function getSid(principal) {
              return principal.sid;
            });
          }
        }
      );
    }

    /**
     * Calls the API to update the principal currently being editted
     *
     * @method     updatePrincipal
     *
     * @return     {promise}  promise to resolve the update request.
     */
    function updatePrincipal() {
      var principal = _.assign({}, $scope.principal);
      var promises = [];

      $scope.submitting = true;

      // Scrub password properties which should not be propagated.
      delete principal.passwordConfirm;
      if (!$scope.shared.changePassword) {
        delete principal.password;
        delete principal.currentPassword;
      }

      if ($scope.type === 'user') {
        let passwordObj = {};
        // Construct passwordObj to be used as input of the password update API.
        if ($scope.shared.changePassword && pageConfig.isSelfUpdate) {
          passwordObj = {
            currentPassword: principal.currentPassword,
            newPassword: principal.password
          };
          // Remove the password fields from the principal before
          // it is used for updating rest of the user information.
          delete principal.password;
          delete principal.currentPassword;
        }
        promises.push(UserService.updateUser(principal));
        if ($scope.shared.changePassword && pageConfig.isSelfUpdate) {
          // For updating own password, use the new password update API.
          promises.push(NgUserServiceApi.UpdateSessionUserPassword(passwordObj).toPromise());
        }
        // Save the locale if the user is the logged In user.
        if (FEATURE_FLAGS.localizationEnabled && principal._isLoggedInUser) {
          promises.push(LocaleService.setUserLocale(principal._locale)
            .toPromise());
        }

        deferredSuccessMessage.titleKey =
          'accessEdit.messages.updated.user.title';
        deferredSuccessMessage.textKey =
          'accessEdit.messages.updated.user.text';
      } else {
        // Assign the updated AD principals
        principal.isSmbPrincipalOnly = !!$ctrl.domainUsers.length;
        principal.smbPrincipals = _reducePrincipals($ctrl.domainUsers);
        promises.push(GroupService.updateGroup(principal));
        deferredSuccessMessage.titleKey =
          'accessEdit.messages.updated.group.title';
        deferredSuccessMessage.textKey =
          'accessEdit.messages.updated.group.text';
      }

      return $q.all(promises).then(
        function updatePrincipalSuccess(responses) {
          $scope.principal = _.assign($scope.principal, responses[0]);

          // to be consistent with the create scenario, return an array of the
          // sid of the updated principal
          return [principal.sid];
        }
      );
    }

    /**
     * updates the list of restricated access sources that the current principal
     * is allowed access to
     *
     * @param      {array}    sids    The sids to be updated
     * @return     {promise}  promise to resolve request to update sources
     */
    function updateLimitedAccessSources(sids) {
      var sourceIds = FEATURE_FLAGS.restrictedUserEnhancements ?
        PubSourceService.getSelectedSourceIds($scope.shared.protectionSources) :
        $scope.shared.protectionSources.map(
          function mapSources(entity) {
            return entity.id;
          }
        );

      var viewNames = $scope.shared.protectionViews.map(
        function mapViews(view) {
          return view.name;
        }
      );

      // clear the existing assigned object(VMs, folders, views etc) if object
      // level restriction is disabled.
      if (!$scope.principal.restricted) {
        // clearing the existing assigned object.
        sourceIds = [];
        viewNames = [];
      }

      return UserService.updateProtectionSources(sids, _.uniq(sourceIds), viewNames)
        .catch(evalAJAX.errorMessage);
    }

    /**
     * called on ngChange of the effective date in order to convert the
     * javascript date to milliseconds in the principal model
     *
     * @method     updateEffectiveTimeMsecs
     * @return     {integer}  date    the effective date in milliseconds
     */
    $scope.updateEffectiveTimeMsecs = function updateEffectiveTimeMsecs() {
      setMinExpirationDate();
      validateTheDates();
      return $scope.principal.effectiveTimeMsecs =
        $scope.shared.effectiveDate &&
        $scope.shared.effectiveDate.setHours(0,0,0,0);
    };

    /**
     * called on ngChange of the expiration date in order to convert the
     * javascript date to milliseconds in the principal model.
     *
     * @method     updateExpiredTimeMsecs
     * @return     {integer}  date    the expiration date in milliseconds
     */
    $scope.updateExpiredTimeMsecs = function updateExpiredTimeMsecs() {
      validateTheDates();
      return $scope.principal.expiredTimeMsecs =
        $scope.shared.expirationDate &&
        $scope.shared.expirationDate.setHours(0,0,0,0);
    };

    /**
     * Test whether the role is selected.
     * Used by ng-checked for each checkbox in ui-select
     *
     * @method     isRoleSelected
     * @param      {object}   role    a role object
     * @return     {boolean}  true if selected, false otherwise.
     */
    $scope.isRoleSelected = function isRoleSelected(role) {
      return $scope.principal.roles.includes(role.name);
    };

    /**
     * Upon selecting a role, enforce mutual exclusivity of admin and all other
     * roles.
     * Used by ui-select
     *
     * @method     onSelectRole
     * @param      {Array}  selectedRoles  The selected roles, listed in order
     *                                     they were selected
     */
    $scope.onSelectRole = function onSelectRole(selectedRoles) {
      var adminIndex;
      var latest = selectedRoles.slice(-1)[0];

      if (latest.name === 'COHESITY_ADMIN') {
        $scope.principal.roles = ['COHESITY_ADMIN'];
        // Close the dropdown since admin is exclusive
        angular.element('body').trigger('click');
      } else {
        adminIndex = $scope.principal.roles.indexOf('COHESITY_ADMIN');
        if (adminIndex > -1) {
          $scope.principal.roles.splice(adminIndex, 1);
        }
      }
    };

    /**
     * Upon selecting a principal, close the dropdown if it is the first
     * selection.
     * Used by ui-select
     *
     * @method     onSelectPrincipal
     */
    $scope.onSelectPrincipal = function onSelectPrincipal() {
      if ($scope.principal.principals.length < 2) {
        hackToCloseUiSelect('.c-ui-select.principals');
      }
    };

    /**
     * Empties list of users upon changing domain.
     */
    $scope.onChangeDomain = function onChangeDomain() {
      $scope.listOfPrincipals.length = 0;
    };

    /**
     * This is a hack because ui-select does not support an expression as
     * the value of the close-on-select attribute.
     * https://github.com/angular-ui/ui-select/issues/1895
     *
     * @method     hackToCloseUiSelect
     */
    function hackToCloseUiSelect(selector) {
      angular.element(selector).click();
    }

    /**
     * Group Roles by Admin and all others.
     * Used by ui-select
     *
     * @method     groupRolesFn
     * @param      {Object}            item    role from ui-select
     * @return     {String|undefined}  The group label. Using this to create
     *                                 groupings with no labels.
     */
    $scope.groupRolesFn = function groupRolesFn(item) {
      return item.name === 'COHESITY_ADMIN' ?
        undefined : $translate.instant('or');
    };

    /**
     * Group domains by main and trusted.
     * Used by ui-select
     *
     * @method  groupDomainsFn
     * @param   {String}  domain  domain from ui-select
     * @return  {String}  The group label
     */
    $scope.groupDomainsFn = function groupDomainsFn(domain) {
      // If there are multiple AD domains, then we don't use any group labels.
      if ($scope.hasMultipleAdDomains) {
        return;
      }

      // Otherwise we use the Main/Trusted labels as designed for a single AD
      // config.
      return domain.type === 'main' ?
        $translate.instant('accessEdit.labels.adGroups.main') :
        $translate.instant('accessEdit.labels.adGroups.trusted');
    };

    /**
     * Group principals by users and groups.
     * Used by ui-select
     *
     * @method  groupPrincipalsFn
     * @param   {Object}  item  principal from ui-select
     * @return  {String}  The group label
     */
    $scope.groupPrincipalsFn = function groupPrincipalsFn(item) {
      var label;

      switch (item.objectClass) {
        case 'kUser':
          label = 'users';
          break;
        case 'kGroup':
          label = 'groups';
          break;
        case 'kComputer':
          label = 'others';
          break;
      }

      return label;
    };

    /**
     * Onchange users/groups, search for partial matches.
     * Used by ui-select
     *
     * @method  refreshPrincipals
     * @param   {String}  searchTerm  Search query
     * @param   {String}  [row]       row containing specific domain name
     */
    $scope.refreshPrincipals = function refreshPrincipals(searchTerm, row) {
      var domainName = _.get(row, 'domain') || $scope.principal.domain;

      $scope.shared.refreshCompleted = false;

      if (domainName && searchTerm) {
        $scope.searching = true;
        var paramsObj = {
          search: searchTerm,
          includeComputers: true,
          domain: domainName,
        };
        ActiveDirectoryService.getDomainPrincipals(paramsObj).then(
          function getDomainPrincipalsSuccess(principals) {
            $scope.shared.refreshCompleted = true;
            if (row) {
              row.listOfPrincipals = principals;
            } else {
              $scope.listOfPrincipals = principals;
            }
          },
          evalAJAX.errorMessage
        ).finally(
          function getDomainPrincipalsFinally() {
            $scope.searching = false;
          }
        );
      }
    };

    /**
     * Allows the user to add limited access leaf sources
     *
     * @param      {Array|Integer}  envTypes  The environment types to add leaf
     *                                        sources of
     */
    $scope.addLimitedAccessSources = function addAccessSources(envTypes) {
      // VMware, physical, Pure, NetApp & NAS
      envTypes = envTypes || cUtils.onlyNumbers(ENV_GROUPS.standard);

      if (!FEATURE_FLAGS.restrictedUserEnhancements || envTypes === 4) {
        // Opens the old flow for the views and the sources.
        oldSourcesFlow(envTypes);
      } else {
        newSourcesFlow();
      }
    };

    /**
     * Opens the modal for selecting sources/views as per the old flow.
     *
     * @method   oldSourcesFlow
     * @param    {Array|Integer}  envTypes  The environment types to add leaf
     *                                        sources of
     */
    function oldSourcesFlow(envTypes) {
      var filters = {
        requireVmTools: false,
        singleSelect: false,
      };

      SourceService.browseForLeafEntities(envTypes, undefined, filters).then(
        function selectSourceSuccess(entities) {
          // TODO: dedup the entities list to ensure an entity isn't added
          // twice
          Array.prototype.push.apply(
            envTypes === 4 ?
              $scope.shared.protectionViews : $scope.shared.protectionSources,
            entities
          );
          rebuildRestrictedParentSources();
        }
      );
    }

    /**
     * Opens the modal for selecting the sources as per the new flow.
     *
     * @method   newSourcesFlow
     */
    function newSourcesFlow() {
      // find selected nodes from the tree
      var selectedSources = PubJobServiceFormatter.findNodes(
        $scope.shared.protectionSources,
        function eachNode(node) {
          return node._selectedAncestor;
        }
      );

      const principal = cUtils.simpleCopy($scope.principal);
      // We need to pass tenantId when creating,
      // for evaluating tenant based restrictions
      if ($scope.createMode) {
        if (principal.objectClass === 'kUser') {
          principal.tenantId = NgUserStoreService.getUserTenantId();
        } else if (principal.objectClass === 'kGroup') {
          principal.tenantIds = [NgUserStoreService.getUserTenantId()];
        }
      }

      PubSourceService.openSourceGroupModal(selectedSources, {
          principal: principal,
        }).then(function gotSelectedSources(selectedSources) {
            $scope.shared.protectionSources = selectedSources;
          }
        );
    }


    /**
     * rebuilds the list of restrictedParentSources() so restricted access
     * objects can be displayed per parentSource
     */
    function rebuildRestrictedParentSources() {
      // rebuild list of restrictedParentSources
      $scope.shared.restrictedParentSources = {};

      $scope.shared.protectionViews.forEach(function loopViewsFn(view) {
        if (!$scope.shared.restrictedParentSources[view.viewBoxId]) {
          $scope.shared.restrictedParentSources[view.viewBoxId] = {
            id: view.viewBoxId,
            name: view.viewBoxName,
            entityType: 0,
            envType: 4,
          };
        }
      });

      $scope.shared.protectionSources.forEach(function loopViewsFn(source) {

        var parentSourceName;
        var parentSourceEntityType;

        // Parent info isn't attached to physical source entities, add it.
        if (!source.parentId && source.type === 6) {
          source.parentId = physicalEntityParentSourceId;
        }

        if (parentSources.length &&
          !$scope.shared.restrictedParentSources[source.parentId]) {
          parentSources.some(function findParentSourceName(parentSource) {
            if (parentSource.entity.id === source.parentId) {
              parentSourceName =
                parentSource.entity[parentSource._entityKey].name;
              parentSourceEntityType =
                parentSource.entity[parentSource._entityKey].type;
              return true;
            }
          });

          $scope.shared.restrictedParentSources[source.parentId] = {
            id: source.parentId,
            name: parentSourceName,
            entityType: parentSourceEntityType,
            envType: source.type,
          };
        }
      });

    }

    /**
     * remove a limited access Source from the list, cleaning up the
     * parentSource if its no longer represented
     *
     * @param      {object}  entity  The source to remove
     */
    $scope.removeSource = function removeSource(source) {

      var isParentSourceStillRelevant;

      $scope.shared.protectionSources.some(
        function findAndRemove(entity, index, self) {
          if (SourceService.isSameEntity(source, entity)) {
            self.splice(index, 1);
            return true;
          }
        }
      );

      // a parentSource is still releveant if the user still has any restricted
      // objects assigned which belong to the parentSource.
      isParentSourceStillRelevant = $scope.shared.protectionSources.some(
        function checkForParent(searchSource) {
          return source.parentId === searchSource.parentId;
        }
      );

      if (!isParentSourceStillRelevant) {
        // remove the parentSource, as its only restricted access child was
        // just removed.
        delete $scope.shared.restrictedParentSources[source.parentId];
      }
    };

    /**
     * remove a limited access View from the list, cleaning up the parentSource
     * if its no longer represented
     *
     * @param      {object}  view    The view to be removed
     */
    $scope.removeView = function removeView(view) {

      var isViewBoxParentSourceStillRelevant;

      $scope.shared.protectionViews.some(
        function findAndRemove(searchView, index, self) {
          if (view.viewId === searchView.viewId) {
            self.splice(index, 1);
            return true;
          }
        }
      );

      // a parentSource is still releveant if the user still has any restricted
      // objects assigned which belong to the parentSource.
      isViewBoxParentSourceStillRelevant = $scope.shared.protectionViews.some(
        function checkForViewBox(searchView) {
          return view.viewBoxId === searchView.viewBoxId;
        }
      );

      if (!isViewBoxParentSourceStillRelevant) {
        // remove the parentSource, as its only restricted access child was
        // just removed.
        delete $scope.shared.restrictedParentSources[view.viewBoxId];
      }
    };

    /**
     * Handles transformations to the returned principal (user/group) when
     * in editMode
     *
     * @method   transformPrincipal
     */
    function transformPrincipal(requestedPrincipal) {
      if (!requestedPrincipal) {
        cMessage.error({
          textKey:'accessEdit.messages.noUser',
        });
        $scope.exitForm();
        return {};
      }

      if (requestedPrincipal.effectiveTimeMsecs) {
        $scope.shared.effectiveDate =
          new Date(requestedPrincipal.effectiveTimeMsecs);
      }

      if (requestedPrincipal.expiredTimeMsecs) {
        $scope.shared.expirationDate =
          new Date(requestedPrincipal.expiredTimeMsecs);
      }

      // If there is a primaryGroupName, then add it to additionalGroupNames
      requestedPrincipal.additionalGroupNames = requestedPrincipal.additionalGroupNames || [];
      if (requestedPrincipal.primaryGroupName) {
        requestedPrincipal.additionalGroupNames.push(requestedPrincipal.primaryGroupName);
      }

      // For AD users/groups
      if ($scope.principal.domain !== 'LOCAL') {

        // Copy 'username' prop to 'name' prop so model is consistent
        if (!requestedPrincipal.name) {
          requestedPrincipal.name = requestedPrincipal.username;
        }

        /* Construct default 'principals' object as expected by ui-select
         * in order to display selected tag.
         */
        requestedPrincipal.principals = [
          {
            principalName: requestedPrincipal.name,
            domain: requestedPrincipal.domain,
            objectClass: ($scope.type === 'user') ? 'kUser' : 'kGroup',
          }
        ];
      }

      return requestedPrincipal;

    }

    /**
     * provides a principal object via promise resolution
     *
     * @return     {promise}  Promise to resolve request for the principal.
     */
    function getPrincipal() {

      var deferred = $q.defer();

      switch(true) {
        case $scope.createMode:
          deferred.resolve(angular.copy(defaultUserConfig));
          break;
        case ($scope.editMode && $scope.type === 'user'):
          deferred.promise = UserService.getUser({
            usernames: pageConfig.name,
            domain: pageConfig.domain,

            // API accepts array of tenant IDs
            tenantIds: (pageConfig.tenantId ?
              [pageConfig.tenantId] : undefined),
          }).then(transformPrincipal);
          break;
        case ($scope.editMode && $scope.type === 'group'):
          deferred.promise =
            GroupService.getGroup(pageConfig).then(transformPrincipal);
          break;
        default:
          // No valid data. Return to list.
          $scope.exitForm();
      }

      return deferred.promise;
    }

    /**
     * for restricted access principals, gets the sources that the principal
     * has access to
     */
    function getRestrictedAccessSources() {
      var promises = {
        newRestrictedSources: FEATURE_FLAGS.restrictedUserEnhancements ?
          PubSourceService.getRestrictedUserSources($scope.principal.sid) :
          $q.resolve(),
        oldRestrictedSourcesAndViews:
          UserService.getProtectionSources($scope.principal.sid),
      };

      return $q.all(promises).then(function gotSourcesAndViews(res) {
        $scope.shared.protectionSources =
          res.oldRestrictedSourcesAndViews[0].protectionSources;
        $scope.shared.protectionViews =
          res.oldRestrictedSourcesAndViews[0].views;

        if (FEATURE_FLAGS.restrictedUserEnhancements) {
          $scope.shared.protectionSources = res.newRestrictedSources;

          // mark tenant assigned node in the hierarchy as selected ancestor.
          PubJobServiceFormatter.forEachNode(
            $scope.shared.protectionSources,
            function eachNode(node) {
              var principals = $scope.principal.type === 'user' ?
                _.get(node, 'entityPermissionInfo.users') :
                _.get(node, 'entityPermissionInfo.groups');

              if (_.find(principals, ['sid', $scope.principal.sid])) {
                node._selectedAncestor = true;
              }
            }
          );
        }

        rebuildRestrictedParentSources();
      }, evalAJAX.errorMessage);
    }

    /**
     * calls SourceService.getSources() to get a list of parentSources.
     * This list is needed for parentSource name lookups so the name can be
     * displayed when listing restricted access objects by parentSource
     *
     * @return     {promise}  promise to resolve the request for parentSources[]
     */
    function getParentSources() {
      return SourceService.getSources({
        onlyReturnOneLevel: true,
      }).then(
        function getSourcesSuccess(resp) {
          var sources = [];
          if (resp.entityHierarchy &&
            resp.entityHierarchy.children &&
            resp.entityHierarchy.children.length) {
            sources = resp.entityHierarchy.children;
          }
          return sources;
        }
      );
    }

    /**
     * Assemble an array of all AD Domains and Trusted Domains
     *
     * @method     assembleDomainsList
     * @param      {array}  domains  The array of AD domain objects
     * @return     {array}  string array of all associated domain names
     */
    function assembleDomainsList(domains) {
      var domainsList = [];

      if (!domains.length) {
        return domains;
      }

      domains.forEach(function addAdDomain(domain) {
        domainsList.push({
          type: 'main',
          group: domain.domainName,
          name: domain.domainName,
        });

        if (domain.trustedDomains) {
          domain.trustedDomains.forEach(
            function addTrustedDomain(trustedDomain, index) {
              domainsList.push({
                type: 'trusted',
                group: domain.domainName,
                name: trustedDomain,
                last: index === domain.trustedDomains.length - 1,
              });
            }
          );
        }
      });

      return domainsList;
    }

    /**
     * Returns true if the principal is in LOCAL domain.
     *
     * @method    isItLocal
     * @param     {object}    principal     user or a group
     * @returns   {boolean}   true if 'LOCAL' domain.
     */
    function isItLocal(principal) {
      return principal.domain === 'LOCAL';
    }

    /**
     * Takes the flat list of principals from a Local Group and populates the
     * `domainPrincipalsHash`. Then returns a list of domains, each with a hash
     * of principals. This is used by the two-part domain/principal ui-selects.
     *
     * @method   _compileDomainUsers
     * @param    {Array}    principals    List of principal objects
     * @returns  {Array}    List of domains, with hashed principals.
     */
    function _compileDomainUsers(principals) {
      var domainPrincipalsHash = {};

      if (!$scope.principal.smbPrincipals) {
        return [];
      }

      principals.forEach(function forEachPrincipal(principal) {

        // Stub the domain entry if new.
        if (!domainPrincipalsHash[principal.domain]) {
          domainPrincipalsHash[principal.domain] = {
            domain: principal.domain,
            principals: [],
          };
        }
        domainPrincipalsHash[principal.domain].principals.push(principal);
      });

      return _.values(domainPrincipalsHash);
    }

    /**
     * Get the domains, roles, and user/group information.
     *
     * @method     getData
     */
    function getData() {
      var promises;

      $scope.createMode = pageConfig.createMode || false;
      $scope.editMode = pageConfig.editMode || false;
      $scope.type = pageConfig.type;
      $scope.loading = true;

      $scope.adEnabled = $rootScope.user.privs.AD_LDAP_VIEW;

      $scope.showOptions = $scope.createMode &&
        ($scope.adEnabled || $rootScope.basicClusterInfo.mcmMode);

      /**
       * If user does not have PRINCIPAL_VIEW priv, then all related APIs will
       * fail, so only issue the getPrincipal API.
       */
      if (!hasViewUsersPrivilege) {
        promises = {
          principal: getPrincipal(),
        };
      } else {
        promises = {
          adDomains: $scope.adEnabled ?
            ActiveDirectoryService.getActiveDirectories() :
            $q.resolve([]),
          idpDomains: IdpService.getAllExternalIdp({
            // Only fetch the IDPs that the logged in user owns.
            allUnderHierarchy: false,
          }),
          roles: UserService.getAllRoles(),
          users: UserService.getAllUsers({ allUnderHierarchy: true }),
          groups: GroupService.getGroups({ allUnderHierarchy: true }),
          principal: getPrincipal(),

          // idpConfigured flag in GET basicClusterInfo gets updated when an SSO
          // is configured
          basicClusterInfo: ClusterService.getBasicClusterInfo(),
        };
        if (FEATURE_FLAGS.openIdEnabled) {
          promises.openIdDomains = IdpService.getOpenId();
        }
      }

      $q.all(promises).then(
        function getAllSuccess(responses) {
          var principalType;
          var isAdPrincipal;

          if (!hasViewUsersPrivilege) {
            // Stub out the missing APIs which are not allowed for this user.
            responses.basicClusterInfo = {};
            responses.adDomains = [];
            responses.idpDomains = [];
            responses.roles = [];
            responses.groups = [];
            responses.users = [];
          }

          $scope.idpConfigured = responses.basicClusterInfo.idpConfigured;
          $scope.principal = responses.principal;
          $scope.principal.type = $scope.principal.type || $scope.type;

          // Access denied if:
          // 1. Principal is not the logged-in User AND
          // 2. User does not have PRINCIPAL_MODIFY privilege OR
          // 3. loggedIn User is of a Tenant and this is built-in principal
          if (!$scope.principal._isLoggedInUser &&
            !$rootScope.user.privs.PRINCIPAL_MODIFY ||
            ($rootScope.isTenantUser() && $scope.principal._isBuiltIn)) {
            $log.warn($rootScope.user.username +
              ' attempted to edit User profile for ' +
              $scope.principal.username + '.');
            return $state.go('access-management');
          }

          $scope.adDomainsList = assembleDomainsList(responses.adDomains);
          $scope.hasMultipleAdDomains = responses.adDomains.length > 1;
          if (responses.openIdDomains && responses.openIdDomains.length) {
            $scope.ssoDomainsList = responses.idpDomains.concat(responses.openIdDomains);
          } else {
            $scope.ssoDomainsList = responses.idpDomains;
          }

          if (ENUM_AUTH_PRINCIPAL_TYPES.includes(
            $scope.principal.domain.toLowerCase())) {
            // The ui-select is default selected with this item
            var firstDomain = $scope.principal.domain === 'AD' ?
              $scope.adDomainsList[0] : $scope.ssoDomainsList[0];

            if (!firstDomain && $scope.createMode) {
              // If no AD domains during create mode then redirect to
              // 'local_user' context.
              return $state.go('new-principal', {
                realm: 'local',
                type: 'user',
                allowedTypes: $scope.shared.allowedTypes,
              }, { reload: true });
            }

            // If at least one AD has been joined then select first in list.
            if (firstDomain) {
              // For ad, use the 'name' key is ui-select string,
              // for sso, it is 'domain'.
              $scope.principal.domain = firstDomain[
                $scope.principal.domain === 'AD' ? 'name' : 'domain'
              ];
            }
          }

          $ctrl.domainUsers =
              _compileDomainUsers($scope.principal.smbPrincipals);

          $scope.rolesList = responses.roles;

          $scope.localGroups = responses.groups.filter(isItLocal);
          $scope.localUsers = responses.users.filter(isItLocal);

          if (!$scope.createMode && $scope.principal.restricted &&
            $scope.user.privs.PRINCIPAL_MODIFY) {
            // Gets the restricted sources and views.
            $scope.loadingSources = true;
            getRestrictedAccessSources()
              .finally(function getProSourcesFinally() {
                $scope.loadingSources = false;
              });
          }

          if ($scope.createMode) {
            return;
          }

          switch ($scope.principal.domain) {
            case 'LOCAL':
              principalType = $scope.principal.type === 'user' ? 'local_user' :
                'local_group';
              break;
            case 'HELIOS':
              principalType = 'helios';
              break;
            default:
              // For Active Directory and SSO, the domain is a user supplied
              // string, therefore match the domain to ad and sso lists
              // to figure out the principal type
              isAdPrincipal = $scope.adDomainsList.some(
                function checkPrincipalType(activeDirectory) {
                  return activeDirectory.name.toLowerCase() ===
                    $scope.principal.domain.toLowerCase();
                });

              // There are only two principal types, either an 'ad' or 'sso'
              principalType = isAdPrincipal ? 'ad' : 'sso';
              break;
          }

          $scope.principal._principalType = principalType;

          /**
           * Disable the roles picker during edit mode if it's the built-in
           * Admin or the logged-in user does not have privileges.
           */
          $scope.disableRoleSelection =
            $scope.editMode && ($scope.principal._isLocalAdmin || !$scope.user.privs.PRINCIPAL_MODIFY);
        },
        evalAJAX.errorMessage
      ).finally(
        function getAllFinally() {
          $scope.loading = false;
        }
      );

      // This promise is outside the $q.all because the page should remain
      // functional even without sources. If that API fails, you simply can't
      // restrict permissions to sources.
      getParentSources().then(function gotParentSources(response) {
        // Find and assign the physical parent source id so it can later be
        // injected into physical entities.
        parentSources = response.some(function findPhysical(source) {
          if (source.entity.type === 6) {
            physicalEntityParentSourceId = source.entity.id;
            return true;
          }
        });
      });
    }

    /**
     * Refreshes the S3 Secret Key for the current user.
     *
     * @method     refreshS3SecretKey
     */
    $scope.refreshS3SecretKey = function refreshS3SecretKey() {
      var requestObj = {
        domain: $scope.principal.domain,
        username: $scope.principal.username,
      };

      $scope.refreshingKey = true;

      UserService.refreshS3SecretKey(requestObj).then(
        function refreshS3SecretKeySuccess(newKey) {
          angular.extend($scope.principal, {
            s3SecretKey: newKey,
          });
        },
        evalAJAX.errorMessage
      ).finally(function refreshS3SecretKeyFinally() {
        $scope.refreshingKey = false;
      });
    };

    /**
     * Returns the appropriate page title depending upon domain and principal
     * type.
     *
     * @method    getEditTitle
     * @return    {String}    translation key for edit page title
     */
    $scope.getEditTitle = function getEditTitle() {
      var principal = $scope.principal;
      return [
        'accessEdit.header.edit',
        principal.domain === 'LOCAL' ? 'local' : principal._principalType,
        principal.type,
      ].join('.');
    };

    /**
     * Returns the appropriate page title depending upon domain and principal
     * type.
     *
     * @method    getAddTitle
     * @return    {String}    translation key for add page title
     */
    $scope.getAddTitle = function getAddTitle() {
      var principal = $scope.principal;
      return [
        'accessEdit.header.add',
        principal.domain === 'LOCAL' ? 'local' : principal._principalType,
        principal.type,
      ].join('.');
    };

    /**
     * Filters out specified domain if already has been added as a row.
     *
     * @method      filterDomains
     * @param       {String}     domain     domain name to test
     * @returns     {Boolean}    false if found it and want to exclude
     */
    function filterDomains(domain) {
      var includeDomain = true;

      $ctrl.domainUsers.some(function(row) {
        if ((row.principals && row.domainName === domain)) {
          includeDomain = false;
          return true;
        }
      });

      return includeDomain;
    }

    /**
     * Updates the state and model values based on the selected principal type.
     *
     * @method    onChangetype
     * @param     {String}     type     Derived type
     */
    function onChangeType(_principalType) {
      $scope.principal._principalType = _principalType;

      switch(_principalType) {
        case 'local_user':
          $scope.principal.type = 'user';
          $scope.principal.domain = 'LOCAL';
          break;
        case 'local_group':
          $scope.principal.type = 'group';
          $scope.principal.domain = 'LOCAL';
          break;
        case 'ad':
          $scope.principal.type = 'principal';
          $scope.principal.domain = $scope.adDomainsList[0].name;
          break;
        case 'sso':
          $scope.principal.type = 'principal';
          $scope.principal.domain = $scope.ssoDomainsList[0].domain;
          break;
        case 'helios':
          $scope.principal.type = 'user';
          $scope.principal.domain = 'HELIOS';
          break;
      }

      $state.go('.', {
        realm: ENUM_AUTH_PRINCIPAL_TYPES.includes(_principalType) ?
          _principalType : $scope.principal.domain.toLowerCase(),
        type: $scope.principal.type,
      }, { location: 'replace' });

    }

    /**
     * Determines whether the logged in user is allowed to restrict a principal.
     *
     * @param   {Object}   principal   The `principal` object.
     * @return  {Boolean}  True if allowed to restrict principal.
     */
    function canRestrictPrincipal(principal) {
      if ($scope.user.privs.PRINCIPAL_MODIFY) {
        if ($scope.createMode) {
          return true;
        }

        const userTenantId = NgUserStoreService.getUserTenantId();
        if (principal.tenantIds) {
          if (userTenantId) {
            // tenant can add restrictions to a group
            // only if the group is assigned to that one tenant
            return principal.tenantIds.length === 1 &&
              principal.tenantIds[0] === userTenantId;
          }

          // SP can add restrictions to a group
          // only if the group is not assigned to any tenants
          return !principal.tenantIds.length;
        }

        return principal.tenantId === userTenantId;
      }

      return false;
    }
  }

})(angular);
