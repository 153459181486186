// COMPONENT:  Smart-Table compatible custom filters.

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.stExtensions', ['smart-table'])
    .directive('stFilter', stFilterDirective)
    .directive('stFilteredCollection', stFilteredCollectionDirective);

  /**
   * @ngdoc directive
   * @name C.stExtensions:stFilter
   * @description
   *   This directive Is similar to smart-table's own st-filter except this
   *   can be applied to any element (not jsut form controls) and the filter
   *   expression can be assigned. It is not dependent on a trigger event,
   *   just changes to the `st-table` and `expression` attributes in the DOM.
   *
   *   Influenced by https://github.com/lorenzofox3/Smart-Table/blob/master/src/stSearch.js
   *
   * @restrict 'A'
   * @element ANY
   * @example
      <example module="C.stExtensions" animation="false">
        <ui-select
          id="volumeFilters"
          class="c-ui-select"
          name="volumeFilters"
          ng-model="volumeFilter"
          st-filter="{{$select.selected.predicate}}"
          expression="{{$select.selected.value}}">
        <ui-select-match placeholder="{{::text.filter}}">
          <span ng-bind-html="$select.selected.name || text.filter"></span>
        </ui-select-match>
        <ui-select-choices repeat="filter in customStFilters">
          <span ng-bind-html="filter.name"></span>
        </ui-select-choices>
        </ui-select>
      </example>
   */
  function stFilterDirective(stConfig, $timeout) {
    var directive = {
      restrict: 'A',
      require: '^stTable',
      link: linkFn
    };
    var tableState;

    /**
     * Angular Directive post-compile Link func.
     */
    function linkFn(scope, elem, attrs, stTable) {
      // @type   {integer}  Configured search delay, or use the default
      var throttle = attrs.stDelay || stConfig.search.delay || 0;
      // @type   {string}   Optional trigger event to trigger the search
      var event = attrs.stEvent;
      // @type   {object}   Holder for a $timeout promise
      var promise;

      tableState = stTable.tableState().search;

      if (!event) {
        // Observe the attrs object for changes to these attributes, then
        // update the search.
        attrs.$observe('stFilter', applyFilter);
        // TODO: Potentially drop this in favor of ngModel
        attrs.$observe('expression', applyFilter);
      } else {
        // There was an event provided, only apply the filter when this
        // event occurs
        elem.bind(event, applyFilter);
      }

      /**
       * Apply the filters in their current state.
       *
       * @method   applyFilter
       */
      function applyFilter() {
        var expression = (angular.isDefined(attrs.expression)) ?
          attrs.expression :  null;
        var property = (angular.isDefined(attrs.stFilter)) ?
          attrs.stFilter :  null;

        // If prior promise, cancel it
        if (angular.isDefined(promise)) {
          $timeout.cancel(promise);
        }

        // Create new promise to search after the prescribed delay.
        promise = $timeout(function delayer() {
          stTable.search(expression, property);
          promise = undefined;
        }, throttle);
      }
    }

    return directive;
  }

  /**
   * @ngdoc directive
   * @name C.stExtensions:stFilteredCollection
   * @description
   *   This directive exposes filtered list from st-table
   *
   * @restrict 'A'
   * @element ANY
   * @example
      <div
        st-table="displayedSource"
        st-filtered-collection="$ctrl.filteredSource"
        st-safe-src="::$ctrl.safeSource">
        <div>
        <h3>Summary</h3>
        <span>safe Source list {{$ctrl.safeSource.length}}</span>
        <span>displayed Source list {{displayedSource.length}}</span>
        <span>filtered Source list {{$ctrl.filteredSource.length}}</span>
        <!--
          // when search filter is not applied
          safe Source list 50
          displayed Source list 10
          filtered Source list 50

          // when search filter is applied
          safe Source list 50
          displayed Source list 10
          filtered Source list 14
        -->
        </div>

        <input st-search="name"
        type="text"
        name="search"
        id="search">

        <div st-pagination></div>

        <table class="c-table">
        <thead>
          <tr>
          <th st-sort="id">ID</th>
          <th st-sort="name">Name</th>
          </tr>
        </thead>
        <tbody>
          <tr class="c-fade-sm animate-staggered"
          ng-repeat="source in displayedSource track by source.id">
          <td>{{source.id}}</td>
          <td>{{source.name}}</td>
          </tr>
        </tbody>
        </table>
      </div>
   */
  function stFilteredCollectionDirective($parse) {
    return {
      restrict: 'A',
      require: '^stTable',
      link: linkFn,
    };

    /**
     * st filtered collection directive linking function
     *
     * @method  linkFn
     * @param   {Object}     scope   The scope
     * @param   {Object}     elem    The element
     * @param   {Object}     attrs   The attributes
     * @param   {function}   stTable   The st table controller
     */
    function linkFn(scope, elem, attrs, stTable) {
      var setter = angular.noop();

      /**
       * set value to scope model
       *
       * @method   modelSetter
       * @param    {Object[]}   newVal   The new filtered collection list
       */
      function modelSetter(newVal) {
        newVal = newVal || stTable.getFilteredCollection();
        setter(scope, newVal);
      }

      /**
       * parse stFilteredCollection attribute and update the setter
       *
       * @method   expressionParser
       */
      function expressionParser() {
        setter = $parse(attrs.stFilteredCollection).assign;
      }

      // watch for filtered collection list changes
      scope.$watch(stTable.getFilteredCollection, modelSetter);

      // observe the expression changes and update the setter
      attrs.$observe('stFilteredCollection', expressionParser);

      // manual initialization since observe and watch both will get
      // called next digest cycle but we can't guarantee the order of
      // execution which creates race condition.
      expressionParser();
      modelSetter();
    }
  }

})(angular);
