// Component: App Launch

;(function(angular, undefined) {
  'use strict';

  angular.module('C.appsManagement')
    .component('appLaunch', {
      templateUrl: 'app/apps-management/app-launch/app-launch.html',
      controller: appLaunchCtrlFn,
   });

  /**
   * @ngdoc component
   * @name C.appsManagement:appLaunch
   * @function appLaunchCtrlFn
   *
   * @description
   * Provides component to launch app.
   * It uses AppsService to get information about the app and trigger launch.
   * @example
     <app-launch></app-launch>
   */
  function appLaunchCtrlFn(_, $state, AppsService, evalAJAX, cMessage,
    APPS_CONST, SourceService, $translate) {
    var $ctrl = this;

    _.assign($ctrl, {
      // constants
      APPS_CONST: APPS_CONST,

      // data
      viewPrivilegesTypes: APPS_CONST.viewPrivilegesTypes,
      data: {
        qosTier: 'kMedium',
        viewPrivileges: {
          type: 'kNone',
          allType: 'read',
          read: [],
          write: [],
        },
        protectedObjectsPrivileges: {
          type: 'kNone'
        },
        description: '',
        vlan: {},
        instanceSize: 'medium',
        deploymentParameters: {},
      },

      app: {},
      loading: false,
      submitting: false,
      showExternalNetworks: false,
      showInstanceSizes: false,
      viewIndex: {},
      formElements: [],

      // Methods
      addMoreViews: addMoreViews,
      cancel: cancel,
      launchApp: launchApp,
      removeReadViewPrivilege: removeReadViewPrivilege,
      removeWriteViewPrivilege: removeWriteViewPrivilege,
      selectViewType: selectViewType,
      selectViews: selectViews,
      setElementValue: setElementValue,
      parseSchema: parseSchema,
      onCheckboxChange: onCheckboxChange,
      addRow: addRow,
      removeRow: removeRow,
      fetchData: fetchData,

      // component lifecycle methods
      $onInit: $onInit,
    });

    /**
     * Initialize the component
     *
     * @method   $onInit
     */
    function $onInit() {
      _getApp();
    }

    /**
     * Function to handle "Add" button click on form arrays
     * @param {array} data Selected Form Array
     */
    function addRow(data) {
      let extractKey = [...[], ...data.items[0]];
      data.items.push(extractKey);
      let model = { ...{}, ...$ctrl.data.deploymentParameters[data.key][0] };
      for (const key in model) {
        model[key] = '';
      }
      $ctrl.data.deploymentParameters[data.key].push(model);
    }

    /**
     * Function to handle remove row from array of elements
     * @param {object} data Selected Form Array
     * @param {number} index Index of selected row
     */
    function removeRow(data, index) {
      data.items.splice(index, 1);
      $ctrl.data.deploymentParameters[data.key].splice(index, 1);
    }

    /**
     * Function to handle checkbox change event
     * @param {array} formElement Checked form element
     */
    function onCheckboxChange(formElement) {
      $ctrl.data.deploymentParameters[formElement.key] = [];
      formElement.titleMap.forEach(element => {
        if (element.selected) {
          $ctrl.data.deploymentParameters[formElement.key].push(element.value);
        }
      });
    }

    /**
     * Set Configuration for form elements
     * @param {array} formElementsArray Array of form elements
     * @param {string} key key of form elements
     * @param {object} value value object of form elements
     * @param {object} schema Json schema object
     */
    async function setElementValue(formElementsArray, key, value, schema) {
      let elementObj = {
        key,
        type: value.meta || value.type,
        title: value.title || key,
        required: schema.required && schema.required.includes(key),
        minlength: value.minLength,
        maxlength: value.maxLength,
        description: value.description || '',
        pattern: value.pattern || '',
        placeholder: value.placeholder || value.title || key,
        validationMessage: value.validationMessage || '',
        readonly: value.readonly || false,
        titleMap: value.titleMap || [],
        info: value.info || '',
      };

      if (value.url) {
        elementObj.titleMap = [];
        let data = await $ctrl.fetchData(value.url).catch(() => evalAJAX.errorMessage);
        data.forEach(view => {
          elementObj.titleMap.push({ name: view[value.url.name], value: view[value.url.value] });
        });
        return formElementsArray.push(elementObj);
      } else {
        return formElementsArray.push(elementObj);
      }
    }

    /**
     * Fetches Data from the Service
     *
     * @return     {promise}  promise to resolve with the list of data
     */
    async function fetchData(urlObj) {
      var viewParams = {
        maxCount: 1000,
      };
      return AppsService.fetchData(viewParams, urlObj.endpoint, urlObj.method, urlObj.extractKey);
    }

    /**
     * Parse JSON Schema to prepare model and formelements
     * @param {object} schema Json schema object
     * @param {object} obj json model object
     * @param {array} formElementsArray Array of form elements
     */
    async function parseSchema(schema, obj, formElementsArray) {
      let tempObj = {};
      let arr = [];
      for (let key in schema) {
        if (key === 'type' && schema[key] === 'object') {
          for (let key1 in schema['properties']) {
            if (schema['properties'][key1].type === 'array') {
              tempObj[key1] = [];
              let elementObj = {
                key: key1,
                type: schema['properties'][key1].type,
                title: schema['properties'][key1].title || key1,
                description: schema['properties'][key1].description,
                items: [],
                required: schema.required && schema.required.includes(key1),
                info: schema['properties'][key1].info || '',
              };
              formElementsArray.push(elementObj);
              await parseSchema(schema['properties'][key1].items, tempObj[key1], elementObj.items);
            } else if (schema['properties'][key1].type === 'string' && schema['properties'][key1].meta === 'checkbox') {
              await setElementValue(arr, key1, schema['properties'][key1], schema);
              tempObj[key1] = [];
            } else {
              await setElementValue(arr, key1, schema['properties'][key1], schema);
              tempObj[key1] = '';
            }
          }
          if (Array.isArray(obj)) {
            obj.push(tempObj);
            formElementsArray.push(arr);
          } else {
            obj = { ...obj, ...tempObj };
            $ctrl.formElements = [...formElementsArray, ...arr];
          }

          $ctrl.data.deploymentParameters = obj;
        }
      }
    }

    /**
     * Get the app information
     *
     * @method   _getApp
     */
    function _getApp() {
      $ctrl.loading = true;
      AppsService.getApp($state.params.appUid, $state.params.version)
        .then(function gotApp(app) {
          if(!app || app.installState !== 'kInstalled') {
            let msg = $translate.instant((!app ? 'apps.appNotInstalled' : 'apps.appNotInstalledTryAgain'),
              { appName: $state.params.appName, appVersion: $state.params.devVersion});
            setTimeout(() => {
              cMessage.error({
                textKey: msg,
                timeout: 10000,
              });
            });
            cancel();
            return;
          }

          $ctrl.app = app;

          // parse dynamic form for app
          if ($ctrl.app.metadata.deploymentParameters) {
            parseSchema(
              JSON.parse($ctrl.app.metadata.deploymentParameters),
              $ctrl.data.deploymentParameters,
              $ctrl.formElements
            );
          }

          // Set vlan to first option.
          $ctrl.data.vlan = _.get(app, 'externalNetworks[0]', null);
          $ctrl.showExternalNetworks = _.get(app, 'externalIpRequired', false);

          if ($ctrl.showExternalNetworks && !$ctrl.data.vlan) {
            $ctrl.app.externalNetworks = [];
          }

          // Set instance size to first option.
          $ctrl.data.instanceSize = _.get(app, 'instanceSizes[0]', null);
          $ctrl.showInstanceSizes = _.get(app, 'instanceSizes.length', false);

          // We only need specific views in case of automount
          if($ctrl.app._requiredPrivileges.kAutoMountAccess) {
            $ctrl.viewPrivilegesTypes = ['kSpecific'];
            $ctrl.data.viewPrivileges.type = '';
          }
        })
        .finally(function getAppDone(){
          $ctrl.loading = false;

          // Initializing the vm num replicas list to be sent as an argument to launch app instance.
          $ctrl.data.vmNumReplicasList = [];

          if($ctrl.app.vmNameInfoList !== undefined) {
            for (var vmNameInfo of $ctrl.app.vmNameInfoList) {
              // By default, allocating 1 replica to each vm.
              $ctrl.data.vmNumReplicasList.push({
                vmName: vmNameInfo.vmName,
                numReplicas: 1
              });
            }
          }
        });
    }

    /**
     * Cancel launch
     *
     * @method   cancel
     */
    function cancel() {
      $state.go('apps-management');
    }

    /**
     * Initiate application launch
     *
     * @method   launchApp
     */
    function launchApp() {

      if ($ctrl.appRunForm.$invalid) {
        return false;
      }

      var id = $state.params.appUid;
      var version = $state.params.version;
      $ctrl.submitting = true;

      AppsService.launchApp(id, version, $ctrl.data)
        .then(function launchAppSuccess(resp) {
          $state.go('apps-management.instances');
        }, evalAJAX.errorMessage)
        .finally(function launchAppDone() {
          $ctrl.submitting = false;
        });

      return true;
    }

    /**
     * Handles selection of View type
     *
     * @method   selectViewType
     * @param    {string}   oldSelection   previously selected viewType
     */
    function selectViewType(oldSelection) {
      if( $ctrl.data.viewPrivileges.type == 'kSpecific') {
        // reset View index
        $ctrl.data.viewPrivileges.read = [];
        $ctrl.data.viewPrivileges.write = [];
        $ctrl.viewIndex = {};
        selectViews(oldSelection);
      }
    }

    /**
     * Initiates View privilege selection
     *
     * @method   selectViews
     * @param    {string}   oldSelection   previously selected viewType
     */
    function selectViews(oldSelection) {
      var filters = {
        requireVmTools: false,
        singleSelect: false,
      };

      var setting = {
        permissionSelect: {
          read: true,
          readWrite: !!$ctrl.app._requiredPrivileges.kReadWriteAccess,
        },
      };

      // 4 is used here for Views
      SourceService.browseForLeafEntities(4, undefined, filters, setting)
        .then(function selectSourceSuccess(entities) {
          var readPriv = $ctrl.data.viewPrivileges.read;
          var writePriv = $ctrl.data.viewPrivileges.write;

          entities.forEach(function getPrivileges(view) {
            switch(view._permission) {
              case 'read':
                _addViewUniquely(readPriv, view);
                break;

              case 'write':
                _addViewUniquely(writePriv, view);
                break;
            }
          });
        },
        function selectSourceCancelled(info) {
          if(info == 'canceled') {
            $ctrl.data.viewPrivileges.type = oldSelection;
          }
        });
    }

    /**
     * Adds Views to given array if its not already added
     * For optimization, added Views are maintained in View index.
     *
     * @method   _addViewUniquely
     * @param    {array}   views   list of Views
     * @param    {object}  view    View to be added
     */
    function _addViewUniquely(views, view) {
      if($ctrl.viewIndex[view.viewId]) {
        return;
      }

      $ctrl.viewIndex[view.viewId] = view;
      views.push(view);
    }

    /**
     * Trigger View selection witout resetting existing Views array
     *
     * @method   addMoreViews
     */
    function addMoreViews() {
      selectViews('kSpecific');
    }

    /**
     * Remove View from read privilege list and index
     *
     * @method   removeReadViewPrivilege
     * @param    {string}   viewId   Id of the View to remove
     */
    function removeReadViewPrivilege(viewId) {
      _.remove($ctrl.data.viewPrivileges.read, {viewId: viewId});
      $ctrl.viewIndex[viewId] = undefined;
      _resetViewPrivilegeSelection();
    }

    /**
     * Remove View from write privilege list and index
     *
     * @method   removeReadViewPrivilege
     * @param    {string}   viewId   Id of the View to remove
     */
    function removeWriteViewPrivilege(viewId) {
      _.remove($ctrl.data.viewPrivileges.write, {viewId: viewId});
      $ctrl.viewIndex[viewId] = undefined;
      _resetViewPrivilegeSelection();
    }

    /**
     * Resets the view privilege selection if required.
     *
     * @method   _resetViewPrivilegeSelection
     */
    function _resetViewPrivilegeSelection() {
      if(!$ctrl.data.viewPrivileges.read.length &&
         !$ctrl.data.viewPrivileges.write.length) {
            $ctrl.data.viewPrivileges.type = '';
      }
    }
  }
})(angular);
