// Component: color dot

;(function(angular, undefined) {

  /**
   * @ngdoc component
   * @name C.colorDot:cColorDot
   * @function
   *
   * @description
   * Component which renders the color dot either solid or linear gradient color
   *
   *
   * @example
   * <c-color-dot color="linearGradient"></c-color-dot>
   * <c-color-dot color="#e1e1e1"></c-color-dot>
   *
   */
  angular.module('C.colorDot', [])
    .component('cColorDot', {
      bindings: {
        // @type   {Object|String}   color    The linear gradient config or
        //                                    solid color hash
        color: '<',
      },
      controller: cColorDotFn,
    });

  function cColorDotFn(_, $element) {
    var $ctrl = this;
    var defaultLinearGradientColor = {
      linearGradient: { x1: 0, y1: 0, x2: 1, y2: 0 },
      stops: [],
    };

    /**
     * Handles on change of color binding & update the internal color styling
     *
     * @method   $onChanges
     */
    $ctrl.$onChanges = function $onChanges() {
      if (typeof $ctrl.color === 'string') {
        // render solid color
        $element.css('background-color', $ctrl.color);
      } else {
        $ctrl.color = _.merge(defaultLinearGradientColor, $ctrl.color);

        // render linear gradient color
        $element.css('background', getGradientColor());
      }
    };

    /**
     * Return linear gradient background styling
     *
     * @method   getGradientColor
     * @return   {string}   linear gradient background styling
     */
    function getGradientColor() {
      // calculate gradient direction
      var angleRadians = Math.atan2(
        ($ctrl.color.linearGradient[3] - $ctrl.color.linearGradient[1]),
        ($ctrl.color.linearGradient[2] - $ctrl.color.linearGradient[0])
      );
      var angleDeg = (angleRadians * 180 / Math.PI) + 90;

      // calculate linear gradient stops
      var stopSize = 100 / ($ctrl.color.stops.length - 1);
      var stops = $ctrl.color.stops.map(function eachStop(stop, index) {
        var stopPercentage;

        if (index === $ctrl.color.stops - 1) {
          stopPercentage = 100;
        } else {
          stopPercentage = stopSize * index;
        }

        // ensure gradient stop percentage to not go beyond 'xx.xxxx%' else
        // we may run compatibility issues with CSS number and JS number limits.
        stopPercentage =
          Number(parseFloat(stopPercentage).toPrecision(6));

        return (stop[1] + stopPercentage + '%');
      }).join(',');

      return 'linear-gradient({angleDeg}deg, {stops})'
        .replace('{angleDeg}', angleDeg)
        .replace('{stops}', stops);
    }
  }
})(angular);
