import { Injectable } from '@angular/core';
import { NavItem } from '@cohesity/helix';
import { appConfigMap, AppName, AppType, IrisContextService, PillarType, translateAppName } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';

import { ServiceNavProvider } from '../service-nav-provider';

/**
 * This Service configures the Security Center view navigation menu with iris.
 */
@Injectable({ providedIn: 'root' })
export class SmartFilesNavService implements ServiceNavProvider {
  pillar: PillarType = 'access';

  app: AppType = 'smartFiles';

  private appName: AppName = 'smartFiles';

  private appConfig = appConfigMap[this.appName];

  get serviceNavTitle(): string {
    return translateAppName(this.translate)(this.appName);
  }

  showNavBranding = true;

  serviceNavTitleIcon = this.appConfig.icon;

  serviceNavTitleClass = 'smartfiles';

  getNavItems(): NavItem[] {
    return [
      {
        displayName: 'dashboard',
        state: 'helios-smartfiles-dashboard',
        icon: 'helix:dashboard',
      },
      {
        displayName: 'clusters',
        state: 'software-upgrade.cluster-list',
        icon: 'helix:node',
      },
      {
        displayName: 'health',
        state: 'health',
        icon: 'notifications!outline',
      },
      {
        displayName: 'reports',
        state: 'reporting.list',
        icon: 'assessment!outline',
        activeStates: ['reporting.detail', 'reporting.schedule'],
      },
    ];
  }

  constructor(
    private translate: TranslateService,
    private irisContext: IrisContextService
  ) {}
}
