// Module: Alerts Parent Module

;(function(angular, undefined) {
  'use strict';

  angular.module('C.alerts', ['C.NgLocaleModules'])
    .config(ConfigFn)
    .controller('alertsCtrl', AlertsCtrlFn);

  function ConfigFn($stateProvider) {
    var viewAccess = 'ALERT_VIEW';
    var modifyAccess = 'ALERT_MODIFY';

    $stateProvider
      .state('alerts', {
        url: [
          '/monitoring/alerts/?',
          '{alertsStatus}&',
          '{alertsCategory}&',
          '{alertsSeverity}&',
          '{alertsTypeBucket}&',
          '{alertsDateRange}&',
          '{sortByOccurrence}&',
          '{sortByLastOccurrence}&',
          '{sortBySeverity}'
        ].join(''),
        help: 'monitoring_alerts',
        title: 'Alerts',
        canAccess: viewAccess,
        params: {
          alertsStatus: { type: 'string' },
          alertsCategory: { type: 'string' },
          alertsSeverity: { type: 'string' },
          alertsDateRange: { type: 'string' },
          sortByOccurrence: { type: 'cBool' },
          sortByLastOccurrence: { type: 'cBool' },
          sortBySeverity: { type: 'cBool' },
        },
        allClustersSupport: true,
        reloadOnSearch: false,
        views: {
          '': {
            templateUrl: 'app/views/page-layouts/ls.html',
            controller: 'alertsCtrl'
          },
          'col-l@alerts': {
            templateUrl: 'app/monitoring/alerts/alerts.content.html',
            controller: 'alertsContentCtrl'
          },
          // unless overwritten by a child state, default tab is Alerts
          'tab-content@alerts': {
            templateUrl: 'app/monitoring/alerts/tab-alerts/alerts.html',
            controller: 'alertsTabAlertsCtrl'
          }
        }
      })
      // this state allows us to return to the initial alerts screen
      // without reloading the entire state
      .state('alerts.content', {
        url: '^/monitoring/alerts',
        help: 'monitoring_alerts',
        title: 'Alerts',
        canAccess: viewAccess,
        parentState: 'alerts',
        allClustersSupport: true,
        views: {
          'col-l@alerts': {
            templateUrl: 'app/monitoring/alerts/alerts.content.html',
            controller: 'alertsContentCtrl'
          },
          // unless overwritten by a child state, default tab is Alerts
          'tab-content@alerts.content': {
            templateUrl: 'app/monitoring/alerts/tab-alerts/alerts.html',
            controller: 'alertsTabAlertsCtrl'
          }
        }
      })
      .state('alerts.alerts', {
        url: '^/monitoring/alerts',
        help: 'monitoring_alerts',
        title: 'Alerts',
        canAccess: viewAccess,
        parentState: 'alerts',
        allClustersSupport: true,
        views: {
          'tab-content@alerts': {
            templateUrl: 'app/monitoring/alerts/tab-alerts/alerts.html',
            controller: 'alertsTabAlertsCtrl'
          }
        }
      })
      .state('alerts.resolutions', {
        url: '^/monitoring/alerts/resolutions',
        help: 'monitoring_alerts_resolutions',
        title: 'Alert Resolutions',
        canAccess: viewAccess,
        parentState: 'alerts',
        views: {
          'tab-content@alerts': {
            templateUrl:
              'app/monitoring/alerts/tab-resolutions/resolutions.html',
            controller: 'alertsTabResolutionsCtrl'
          }
        }
      })
      .state('alerts.analytics', {
        url: '^/monitoring/alerts/analytics',
        help: 'monitoring_alerts_analytics',
        title: 'Alert Analytics',
        canAccess: viewAccess,
        parentState: 'alerts',
        allClustersSupport: true,
        views: {
          'tab-content@alerts': {
            templateUrl: 'app/monitoring/alerts/tab-analytics/analytics.html',
            controller: 'alertsTabAnalyticsCtrl'
          }
        }
      })
      .state('alerts.settings', {
        url: '^/monitoring/alerts/settings',
        help: 'monitoring_alerts_settings',
        title: 'Alert Settings',
        canAccess: 'ALERT_VIEW',
        parentState: 'alerts',
        views: {
          'tab-content@alerts': {
            templateUrl: 'app/monitoring/alerts/tab-settings/settings.html',
            controller: 'AlertsTabSettingsCtrl',
            controllerAs: '$ctrl',
          }
        }
      })
      .state('alerts.resolve', {
        url: '^/monitoring/alerts/resolve',
        help: 'monitoring_alerts_resolutions',
        title: 'Alert Resolutions',
        params: {
          selectedAlerts: null,
        },
        canAccess: modifyAccess,
        parentState: 'alerts',
        views: {
          'col-l@alerts': {
            templateUrl: 'app/monitoring/alerts/resolve/resolve.html',
            controller: 'alertsResolveCtrl'
          }
        }
      })
      .state('alerts.resolve.listing', {
        url: '^/monitoring/alerts/resolve/listing',
        help: 'monitoring_alerts_resolutions',
        title: 'Alert Resolution Index',
        canAccess: viewAccess,
        parentState: 'alerts',
        views: {
          'col-l@alerts': {
            templateUrl: 'app/monitoring/alerts/resolve/listing/listing.html',
            controller: 'alertsResolveListingCtrl'
          }
        }
      })
      .state('alerts.alert-detail', {
        url: '^/monitoring/alerts/alert/{id}',
        help: 'monitoring_alerts',
        title: 'Alert Detail',
        canAccess: viewAccess,
        params: {
          id: { type: 'string' },
        },
        allClustersSupport: true,
        parentState: 'alerts',
        views: {
          'col-l@alerts': {
            templateUrl: 'app/monitoring/alerts/alert/detail.html',
            controller: 'alertsDetailCtrl as $ctrl'
          },
        }
      })
      .state('alerts.resolution-detail', {
        url: '^/monitoring/alerts/resolution/{id}',
        help: 'monitoring_alerts_resolutions',
        title: 'Alert Resolution Detail',
        canAccess: viewAccess,
        params: {
          id: { type: 'string' },
        },
        parentState: 'alerts',
        views: {
          'col-l@alerts': {
            templateUrl: 'app/monitoring/alerts/resolution/detail.html',
            controller: 'alertsResolutionDetailCtrl'
          }
        }
      });
  }

  function AlertsCtrlFn($q, $scope, $state, evalAJAX,
    DateTimeService, AlertService, TenantService,
    ENUM_ALERTS_STATUS_LABEL_CLASSNAME, ENUM_ALERTS_SEVERITY) {

    // adding $state to $scope so we can watch for changes
    $scope.$state = $state;

    // convenience functions
    $scope.ENUM_ALERTS_STATUS_LABEL_CLASSNAME =
      ENUM_ALERTS_STATUS_LABEL_CLASSNAME;
    $scope.ENUM_ALERTS_SEVERITY = ENUM_ALERTS_SEVERITY;

    // array that holds a list of alert objects the user has selected, this will
    // be used through the resolve flow
    $scope.selectedAlerts = [];

    // setup default filter values for alert querying
    var startingDate =
      DateTimeService.beginningOfDay(DateTimeService.getWeekAgo());
    var endingDate = DateTimeService.endOfDay(new Date());
    $scope.alertFilters = {
      startDate: startingDate,
      endDate: endingDate,
      alertStateList: ['kOpen'],
      alertCategoryList: [],
      alertDateRange: $state.params && $state.params.alertsDateRange ? $state.params.alertsDateRange : 'custom',
      alertSeverityList: $state.params && $state.params.alertsSeverity ? [ $state.params.alertsSeverity ] : [],
      alertTypeBucketList: $state.params && $state.params.alertsTypeBucket ? [ $state.params.alertsTypeBucket ] : []
    };

    $scope.resolutionFilters = {
      tenantIds: [],
    };

    // arrays to hold our alerts and resolutions for tabular display
    $scope.filteredAlerts = [];
    $scope.filteredResolutions = [];

    /**
     * Initialize the controller and fetch dependencies.
     *
     * @method   init
     */
    this.$onInit = function $onInit() {
      AlertService.getAlertCategories(true).then(
        function fetchedAlertCategories(categoriesEnum) {
          $scope.alertCategoriesEnum = categoriesEnum;
        }
      );
      getAlerts();
      getResolutions();
    };

    /**
     * gets the alerts from the API and loads them into $scope.filteredAlerts
     * for display in the table
     *
     * @method   getAlerts
     */
    function getAlerts() {
      // build our API parameters
      var apiParams = TenantService.extendWithTenantParams({
        maxAlerts: 1000,
        _includeTenantInfo: true,
      }, $scope.alertFilters.tenantIds);

      // last 24 hours
      var minusValue = 24 * 60 * 60 * 1000000;

      if ($scope.alertFilters.alertDateRange && $scope.alertFilters.alertDateRange !== 'custom') {
        if ($scope.alertFilters.alertDateRange === 'last7Days') {
          minusValue *= 7;
        } else if ($scope.alertFilters.alertDateRange === 'last13Weeks') {
          minusValue *= 7 * 13;
        }
        apiParams.endDateUsecs = DateTimeService.dateToUsecs(new Date());
        apiParams.startDateUsecs =  apiParams.endDateUsecs - minusValue;
      } else {
        if ($scope.alertFilters.startDate) {
          apiParams.startDateUsecs =
            DateTimeService.dateToUsecs($scope.alertFilters.startDate);
        }
        if ($scope.alertFilters.endDate) {
          apiParams.endDateUsecs =
            DateTimeService.dateToUsecs($scope.alertFilters.endDate);
        }
      }
      if ($scope.alertFilters.alertStateList.length) {
        apiParams.alertStateList = $scope.alertFilters.alertStateList;
      }
      if ($scope.alertFilters.alertCategoryList.length) {
        apiParams.alertCategoryList = $scope.alertFilters.alertCategoryList;
      }
      if ($scope.alertFilters.alertSeverityList.length) {
        apiParams.alertSeverityList = $scope.alertFilters.alertSeverityList;
      }
      if ($scope.alertFilters.alertTypeBucketList.length) {
        apiParams.alertTypeBucketList = $scope.alertFilters.alertTypeBucketList;
      }

      $scope.filteredAlertsReady = false;
      $scope.filteredAlerts = [];

      AlertService.getAlerts(apiParams).then(
        function getAlertsSuccess(response) {
          $scope.filteredAlerts = response.data;
        },
        evalAJAX.errorMessage
      ).finally(
        function getAlertsFinally() {
          $scope.filteredAlertsReady = true;
        }
      );
    }

    /**
     * gets the resolutions from the API and loads them into
     * $scope.filteredAlerts for display in the table
     *
     * @method   getResolutions
     */
    function getResolutions() {
      var params = TenantService.extendWithTenantParams({
        maxResolutions: 1000,
        _includeTenantInfo: true,
      }, $scope.resolutionFilters.tenantIds);

      $scope.isFetchingResolutions = true;

      AlertService.getResolutions(params)
        .then(function getResolutionsSuccess(resolutionsResult) {
          if (evalAJAX.success(resolutionsResult) && resolutionsResult.data) {
            $scope.filteredResolutions = resolutionsResult.data;
          }
        }).finally(function getResolutionsFinally() {
          $scope.isFetchingResolutions = false;
        });
    }

    /**
     * Filter resolutions when the tenant filter changes.
     *
     * @method   filterResolutions
     */
    $scope.filterResolutions = function filterResolutions() {
      getResolutions();
    };

    /**
     * resets our table data
     *
     * @method   resetData
     */
    $scope.resetData = function resetData() {
      $scope.selectedAlerts = [];
      getAlerts();
      getResolutions();
    };

    // watch for changes to our filters, and rerun alerts API call if any are
    // made
    $scope.$watch(
      'alertFilters',
      function(newValue, oldValue) {
        if (newValue !== oldValue) {
          getAlerts();
        }
      },
      true
    );

    // on state change update the lists of alerts and resolutions to ensure
    // we're showing the latest information
    $scope.$watch(
      '$state.current.name',
      function(newValue, oldValue) {
        if (newValue !== oldValue) {
          getAlerts();
          getResolutions();
        }
      }
    );

  }

})(angular);
