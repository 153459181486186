import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, NgControl } from '@angular/forms';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, finalize, map, startWith, tap } from 'rxjs/operators';

import { cohesityGroups, Environment, envTypes, SourceStatus } from '../../constants';
import { ONLY_OWN } from '../../directives';
import { DecoratedProtectionSourceNode } from '../../models/decorated-protection-source.model';
import { noSizeAdapters } from '../../source-tree/protection-source/nas/nas.constants';
import { ItemPickerFormControl } from '@cohesity/shared-forms';
import { ParentSourceSelectorService, ProtectionSourceGroup } from './parent-source-selector.service';

/**
 * The type of Selected Source.
 */
export interface SelectedSource {
  id: number;
  type: string;
  name: string;
}

/**
 * Component for selecting registered sources for cloud deploy in protection policy.
 * It implements ControlValueAccessor so that it can be used in angular's form modules.
 *
 * @example
 * <coh-parent-source-selector formControlName="source"
 *   label="My Label"
 *   [addNewEnable]="true"
 *   environments=["kAWS", "kAzure"]
 *   [selectedSource]="source">
 * </coh-parent-source-selector>
 */
@Component({
  selector: 'coh-parent-source-selector',
  templateUrl: './parent-source-selector.component.html',
  styleUrls: ['./parent-source-selector.component.scss'],
})
export class ParentSourceSelectorComponent extends ItemPickerFormControl<DecoratedProtectionSourceNode>
  implements OnInit, OnChanges {
  /**
   * Whether add new source button should be enabled.
   */
  @Input() addNewEnable = false;

  /**
   * Add new callback, gets called after a new source is added successfully.
   */
  @Output() addNewCallback = new EventEmitter<SelectedSource>();

  /**
   * Array of supported environments.
   */
  @Input() environments: string[] = [];

  /**
   * Array of Environments to restrict the selection when adding new source.
   * undefined will allow all source types to be registered.
   * This can be either numeric or kValues.
   */
  @Input() allowedEnvTypes: (number | string)[];

  /**
   * Optional. If specified sources from only these source group types will be
   * displayed.
   */
  @Input() allowedSourceGroupTypes: string[];

  /**
   * Whether the component should show in read only mode.
   */
  @Input() readOnly = false;

  /**
   * Selected source details.
   * This is used to map to the correct source based on id and type.
   */
  @Input() selectedSource: SelectedSource;

  /**
   * Specifies the selected ProtectionSourceNode instance.
   * If the caller sets this, then API call to fetch root nodes is skipped and
   * the selector pre-selects this source.
   */
  @Input() selectedProtectionSourceNode: ProtectionSourceNode;

  /**
   * Selected source ID.
   * This is used if only a selected source ID is available (and not along
   * its name and type).
   */
  @Input() selectedSourceId: number;

  /**
   * Label to display for <mat-label>.
   */
  @Input() label: string;

  /**
   * Float label passed on to determine mat-form-field behavior.
   */
  @Input() floatLabel = 'auto';

  /**
   * Whether the source field is required.
   */
  @Input() required = false;

  /**
   * Optional. If enabled, source selector will have searching.
   */
  @Input() allowSearch = true;

  /**
   * Whether a source should be auto selected.
   * Applicable when there is only a single source.
   */
  @Input() autoSelectSource = false;

  /**
   * Use the legacy source selection component if true.
   */
  @Input() useClassicSourceRegistration = false;

  /**
   * override add new source workflow
   */
  @Input() overrideAddNewWorkflow = false;

  /**
   * event emitted to override default workflow for source
   * registration
   */
  @Output() registerNewSourceClick = new EventEmitter<{
    defaultEnv: string;
    allowedEnvTypes: number[];
    useClassicSourceRegistration: boolean;
  }>();

  /**
   * Whether an API call is pending right now.
   */
  loading = false;

  /**
   * Form Control for searching the values.
   */
  searchCtrl = new UntypedFormControl();

  /**
   * List of all filtered sources
   */
  filteredSources$: Observable<DecoratedProtectionSourceNode[]>;

  /**
   * An observable stream of registered sources after grouping.
   */
  public registeredSources$: Observable<ProtectionSourceGroup[]>;

  /**
   * Observable of search string.
   */
  private search$: Observable<string>;

  /**
   * Array of adapters which do not have determined logical size
   */
  public noSizeAdapters: Environment[] = noSizeAdapters;

  /**
   * Make SourceStatus available for the template.
   */
  SourceStatus = SourceStatus;

  /**
   * Custom function to filter the sources fetched from backend
   */
  @Input() sourcesFilter: Function =
    (sources: DecoratedProtectionSourceNode[]): DecoratedProtectionSourceNode[] => sources;

  /**
   * Gets numeric env types from the allowedEnvTypes. It converts kValues to numericvalues.
   */
  get privateAllowedEnvTypes(): number[] | undefined {
    if (this.allowedEnvTypes) {
      return this.allowedEnvTypes.map(env => (typeof env === 'number' ? env : envTypes[env]));
    }
  }

  // TODO (Tung) 6.4.2: switch to implement MatFormFieldControl for better error handling.
  // https://material.angular.io/guide/creating-a-custom-form-field-control
  constructor(
    private parentSourceService: ParentSourceSelectorService,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() @Inject(ONLY_OWN) private onlyOwn: boolean = false
  ) {
    super();

    // Set the value accessor directly.
    // This is equivalent to injecting NG_VALUE_ACCESSOR to the provider.
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    if (this.selectedSource && this.selectedSourceId) {
      throw new Error('Either selectedSourceId or selectedSource is allowed');
    }
  }

  /**
   * Initialize and retrieve the registered sources.
   */
  ngOnInit() {
    this.search$ = this.searchCtrl ?
      this.searchCtrl.valueChanges.pipe(startWith('')) :
      of('');

    this.updateSources();
  }

  /**
   * On selecting a source, update the value accordingly
   * and emit event to parent form.
   *
   * @param   source   The selected source.
   */
  onSelect(source: DecoratedProtectionSourceNode) {
    if (!source) {
      return;
    }

    if (this.value) {
      this.parentSourceService.setSelection(this.value.protectionSource.id, false);
    }

    this.value = source;
    this.parentSourceService.setSelection(source.protectionSource.id, true);
  }

  /**
   * Triggers when mat select panel is open/closed.
   * Manually reset selectedTarget if value is undefined/empty.
   * Once we switch to MatFormFieldControl, this should not be necessary.
   *
   * @param  isOpen  Whether mat-select panel is open.
   */
  openedChange(isOpen: boolean) {
    this.propagateOnTouch();
    if (!this.ngControl.value && !isOpen) {
      this.value = undefined;
    }
  }

  /**
   * Triggers the Register New Source modal and uses the newly registered target.
   */
  registerNewSource() {
    const defaultEnv = this.environments.length ? this.environments[0] : '';

    // override register new source workflow
    if (this.overrideAddNewWorkflow) {
      this.registerNewSourceClick.emit({
        allowedEnvTypes: this.privateAllowedEnvTypes,
        defaultEnv,
        useClassicSourceRegistration: this.useClassicSourceRegistration
      });
      return;
    }

    this.loading = true;
    this.parentSourceService
      .registerNew(defaultEnv, this.privateAllowedEnvTypes, this.useClassicSourceRegistration)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((target: SelectedSource) => {
        if (target) {
          // Set selectedSource and re-fetch all registered sources.
          this.selectedSource = target;
          this.addNewCallback.emit(target);
        }
        // Clear the selected value while the new list is being fetched.
        this.value = undefined;

        this.updateSources();
      });
  }

  /**
   * Fetches sources and updates registeredSources and selected source.
   */
  private updateSources() {
    // Check if the API call to fetch the root nodes is to be skipped.
    if (this.selectedProtectionSourceNode) {
      this.registeredSources$ = of(this.groupSources([this.selectedProtectionSourceNode]));
      this.updateFilteredSources();
      this.value = this.selectedProtectionSourceNode;
      return;
    }

    this.loading = true;
    this.registeredSources$ = this.parentSourceService.fetchSources(this.environments, this.onlyOwn).pipe(
      // Emit when there is a valid value.
      filter(sources => !!sources && Array.isArray(sources)),

      // Group sources for UI display
      map(source => this.groupSources(this.sourcesFilter(source))),

      // Filter source groups if a filter is specified.
      map(sourceGroups => {
        if (this.allowedSourceGroupTypes) {
          return sourceGroups.filter(sourceGroup => this.allowedSourceGroupTypes.includes(sourceGroup.sourceType));
        }

        return sourceGroups;
      }),

      // Update the selected source if available.
      tap(sourceGroups => {
        const sources = sourceGroups.reduce((allSources, sourceGroup) => [...allSources, ...sourceGroup.sources], []);

        if (this.selectedSource || this.value?.protectionSource) {
          const type = this.value ? this.value.protectionSource.environment : this.selectedSource.type;
          const id = this.value ? this.value.protectionSource.id : this.selectedSource.id;

          // First check for a source that directly matches the selected source. This will pick up vcenter servers, etc.
          let selected = sources.find(source => source.protectionSource.id === id);

          // If there's no direct match and this is a cohesity group, find the first environment
          // that matches.
          if (!selected && cohesityGroups.includes(type as any)) {
            selected = sources.find(source => source.protectionSource.environment === type);
          }

          this.value = selected || null;
        }

        if (this.selectedSourceId) {
          // If a selectedSourceId is passed, set this.value to that source.
          this.value = sources.find(src => src.protectionSource.id === this.selectedSourceId);

          // If there is only one source with matching environment, pre-select the source.
          if (!this.value) {
            const matchedSources = sources.filter(src =>
              this.environments.includes(src.protectionSource.environment));

            if (matchedSources.length === 1) {
              this.value = matchedSources[0];
            }
          }
        }

        if ((this.autoOpen || this.autoSelectSource)
          && !this.readOnly && !this.selectedSource && !this.selectedSourceId && !this.value) {
          // If there is only one source, select it by default instead of opening the dropdown.
          if (sources.length === 1) {
            this.value = sources[0];
          } else if (this.autoOpen) {
            this.matSelect.open();
          }
        }
      }),

      // Set loading to false
      finalize(() => (this.loading = false))
    );

    this.updateFilteredSources();
  }

  /**
   * Set filteredSources$ observable after registeredSources$ is set.
   */
  private updateFilteredSources() {
    this.filteredSources$ = combineLatest([
      this.registeredSources$,
      this.search$,
    ]).pipe(map(([sources, search]) => {
      if (!search) {
        return sources;
      }

      const searchStringRegex = new RegExp(search, 'i');
      const filteredSources = [];

      for (const source of sources) {
        if (source.groupName.search(searchStringRegex) > -1) {
          filteredSources.push(source);
          continue;
        }

        const sourceCopy = {
          ...source,
          sources: source.sources.filter(sourceItem => {
            let searchAttribute = sourceItem.protectionSource.name;
            if (sourceItem.protectionSource.customName) {
              searchAttribute = sourceItem.protectionSource.customName;
            }
            return searchAttribute.search(searchStringRegex) > -1;

          })
        };

        if (sourceCopy.sources.length) {
          filteredSources.push(sourceCopy);
        }
      }

      return filteredSources;
    }));
  }

  /**
   * Group sources into groups for UI display.
   *
   * @param   sources   The sources model from API.
   * @return  List of ProtectionSourceGroup.
   */
  private groupSources(sources: DecoratedProtectionSourceNode[]): ProtectionSourceGroup[] {
    if (!sources) {
      return undefined;
    }
    const mappedSources = sources.reduce((groups, obj) => {
      groups[obj._sourceTypeNameKey] = groups[obj._sourceTypeNameKey] || {
        groupName: obj._sourceTypeNameKey,
        sources: [],
        sourceType: obj._type,
      };
      groups[obj._sourceTypeNameKey].sources.push(obj);
      return groups;
    }, {});

    return Object.keys(mappedSources).map(key => mappedSources[key]);
  }

  /**
   * When allowedSourceGroupTypes or environments is changed, the sources have to be updated.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.allowedSourceGroupTypes || changes.environments || changes.selectedSourceId) {
      this.updateSources();
    }
  }

  /**
   * Returns if the source is under/ scheduled/ not in maintenance.
   */
  sourceMaintenanceStatus(source: ProtectionSourceNode): string {
    if (source.maintenanceModeConfig?.activationTimeIntervals?.
      length) {
        const { startTimeUsecs, endTimeUsecs } = source.maintenanceModeConfig.activationTimeIntervals[0];
        const currentTimeUsecs = Date.now() * 1000;
        if (startTimeUsecs <= currentTimeUsecs) {
          if (endTimeUsecs === -1) {
            return SourceStatus.UNDER_MAINTENANCE_INFINITE;
          }
          if (currentTimeUsecs < endTimeUsecs) {
            return SourceStatus.UNDER_MAINTENANCE_FINITE;
          }
        }
        if (startTimeUsecs > currentTimeUsecs) {
          return SourceStatus.SCHEDULED_MAINTENANCE;
        }
      }
    return SourceStatus.NO_MAINTENANCE_CONFIGURED;
  }
}
