import { DataRenderer, IconDataRendererComponent } from '@cohesity/helix';

function getStatusIcon(value: string): string {
  switch (value) {
    case 'kAccepted':
    case 'kRunning':
    case 'kOnHold':
      return 'helix:status-in-progress!info';
    case 'kSuccess':
      return 'helix:status-success!success';
    case 'kCanceled':
    case 'kCanceling':
      return 'helix:status-cancel!warning';
    case 'kWarning':
      return 'helix:status-warning!warning';
    case 'kFailure':
      return 'helix:status-error!critical';
    case 'kSkipped':
      return 'helix:status-skip!warning';
  }
  return '';
}

/**
 * Renders a status icon based on a status enum.
 */
export const statusIconRenderer = {
  transform: (renderValues: DataRenderer<string, undefined, undefined>) => ({
    ...renderValues,
    value: getStatusIcon(renderValues.value),
  }),
  component: IconDataRendererComponent,
};
