import { NavBanner } from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';
import { kebabCase } from 'lodash-es';
import { appList, AppType, PillarItem } from './app-pillars.model';

/**
 * Interface for ServiceNavProvider.
 */
interface NavProvider {
  readonly app?: AppType;
  readonly showNavBranding?: boolean;
}

/**
 * Get Nav Banner by pillar data.
 *
 * @param pillar  Pillar Type.
 * @param app     Pillar Application.
 * @param translate  Translate function.
 * @param showNavBranding   Show branding if true.
 * @returns NavBanner.
 */
export function getNavBanner(
  app: PillarItem,
  translate: TranslateService,
  showNavBranding = true,
  isNav = false,
): NavBanner {
  return {
    title: translate.instant(app?.bannerTitle || app?.title || 'global'),

    // The icons and named based on pillar name.
    // Need to separate the icons for nav and header even though they are the same icons.
    // Because the id in defs in Nav icons is always used by the header icon and when nav is
    // hidden by user action, the header icon will miss the id and corresponding style.
    logo: `helix:pillar${isNav ? '2' : ''}-${kebabCase(app?.pillar)}`,
    showBranding: (app.app === 'clusterManager' && isNav) ? false : showNavBranding,
    subtitle: app?.subtitle ? translate.instant(app?.subtitle) : null,
  };
}

/**
 * Map ServiceNavProvider to NavBanner data structure used in Helix.
 *
 * @param provider  Map from ServiceNavProvider.
 * @param translate  Translate service.
 * @param isNav  Show banner differently for clusterManger under nav and header.
 * @returns Map to NavBanner.
 */
export function mapNavProvider(
  provider: NavProvider,
  translate: TranslateService,
  isNav = false,
): NavBanner {
  const app = appList.find(a => a.app === provider.app);

  return getNavBanner(app, translate, provider.showNavBranding, isNav);
}
