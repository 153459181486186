// MODULE: Cluster Setup, Nodes Select

angular.module('C.clusterSetup')
  .controller(
    'clusterSetupNodesSelectController',
    ['$rootScope', '$scope', '$state', function($rootScope, $scope, $state) {

      var init;

      $scope.text = angular.extend($scope.text,
        $rootScope.text.adminClusterSetupNodesSelect);

      init = function init() {
        if (!$scope.shared.discoveredNodes.length) {
          $state.go('cluster-setup.detect', {}, {
            location: 'replace'
          });
        }

        /**
         * make a temporary copy of discovered nodes for use
         * in the modal
         */
        $scope.shared.discoveredNodesTmp =
          angular.copy($scope.shared.discoveredNodes);
      };

      /**
       * Select all nodes
       */
      $scope.selectAll = function selectAll() {
        angular.forEach($scope.shared.discoveredNodesTmp,
          function selectNode(node) {
            node.selected = !node.missingNode;
        });
      };

      /**
       * Submit the node selection window
       * Apply node selection to shared.discoveredNodes
       */
      $scope.selectNodes = function selectNodes() {
        // Overwrite discovered nodes with temporary copy
        $scope.shared.discoveredNodes =
          angular.copy($scope.shared.discoveredNodesTmp);
        $state.go('cluster-setup.nodes');
      };

      // Whoa whoa whoa whoooa sweet child of mine!
      init();

    }
  ]);
