import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'cog-settings-list-item-value-display',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./settings-list-item-value-display.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SettingsListItemValueDisplayComponent {
}
