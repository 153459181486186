import { Injectable, NgModule } from '@angular/core';
import { appConfigMap, pluginConfigMap } from '@cohesity/iris-core';
import { MicroFrontendHostModule, MicroFrontendHostOptions } from '@cohesity/micro-frontend';
import { FeatureFlagsService, HomeService, ScopeSelectorService, UserService } from 'src/app/core/services';

@Injectable()
export class AppMicroFrontendHostOptions extends MicroFrontendHostOptions {
  constructor(
    private featureFlagsService: FeatureFlagsService,
    private homeService: HomeService,
    private scopeSelectorService: ScopeSelectorService,
    private userService: UserService,
  ) {
    super();
    this.options = {
      appConfigs: Object.values(appConfigMap),
      pluginConfigs: Object.values(pluginConfigMap),
      goHome: () => this.homeService.goHome(),
      getCustomPropsFn: (app, location) => ({
        app,
        appName: app.name,
        appSwitcherConfigs: this.scopeSelectorService.getAvailableServiceConfigs(),
        featureFlags: this.featureFlagsService.featureFlags,
        location,
        logout: this.userService.logout.bind(this.userService),
        user: this.userService.user,
      }),
    };
  }
}

@NgModule({
  exports: [MicroFrontendHostModule],
  imports: [MicroFrontendHostModule],
  providers: [
    {
      provide: MicroFrontendHostOptions,
      useClass: AppMicroFrontendHostOptions,
    },
  ],
})
export class AppMicroFrontendHostModule {}
