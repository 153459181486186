import { Range, ScanHealthStatus } from '@cohesity/api/argus';
import { ActivityItemSeverity } from '@cohesity/helix';

/**
 * Map of Activity Item severity based on sensitivity levels.
 */
export const SensitivityToActivityItemSeverityMap: Record<Range, ActivityItemSeverity> = Object.freeze({
  'high': 'error',
  'medium': 'warn',
  'low': 'warn',
});

/**
 * Map of Activity icons based on sensitivity levels.
 */
export const SensitivityToActivityItemIconMap: Record<Range, string> = Object.freeze({
  'high': 'helix:status-error!critical',
  'medium': 'helix:status-error!warning',
  'low': 'helix:status-error!warning',
});

/**
 * Map of On Demand Classification Scans severity based on its health status
 */
export const ODClassifcationScanStatusToSeverityMap: Record<ScanHealthStatus, ActivityItemSeverity> = Object.freeze({
  [ScanHealthStatus.Accepted]: 'info',
  [ScanHealthStatus.Running]: 'info',
  [ScanHealthStatus.Canceled]: 'warn',
  [ScanHealthStatus.Canceling]: 'warn',
  [ScanHealthStatus.Failed]: 'error',
  [ScanHealthStatus.Succeeded]: 'success',
  [ScanHealthStatus.SucceededWithWarning]: 'warn',
  [ScanHealthStatus.Skipped]: 'warn',
});
