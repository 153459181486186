import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const protectModify = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngProtectionGroupGlobalModify &&
  ctx.PROTECTION_MODIFY;

export const SfdcConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'protection-builder-sfdc.**',
      url: '/protection/protect/sfdc',
      loadChildren: () => import('./sfdc.module').then(m => m.SfdcModule),
    },
  ],
  allClusterMap: {
    'protection-builder-sfdc.create': true,
    'protection-builder-sfdc.edit': true,
    'protection-builder-sfdc.edit-object': true,
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'protection-builder-sfdc.create': protectModify,
      'protection-builder-sfdc.edit': protectModify,
      'protection-builder-sfdc.edit-object': protectModify,
    };
  },
};
