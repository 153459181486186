import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SmbPermission } from '@cohesity/api/v2';
import { DialogService } from 'src/app/core/services';

import { SMB_ACL_PERMISSIONS } from '../../models';
import { NewPermission, PrincipalDialogComponent } from '../principal-dialog/principal-dialog.component';

/**
 * An arbitrary type emitted by the component.
 */
export interface EmittedSnapshotPermissions {
  allowAccessSids: string[];
  denyAccessSids: string[];
}

@Component({
  selector: 'coh-view-snapshot-permissions',
  templateUrl: './view-snapshot-permissions.component.html',
})
export class ViewSnapshotPermissionsComponent implements OnInit, OnChanges {
  /**
   * Lists of SIDs with access to snapshots.
   */
  @Input() allowAccessSids: string[] = [];

  /**
   * Lists of SIDs denied access to snapshots.
   */
  @Input() denyAccessSids: string[] = [];

  /**
   * Holds a hash of principals in their domains.
   */
  @Input() domainPrincipalsHash: any = {};

  /**
   * Viewbox trusted ad domains.
   */
  @Input() trustedAdDomains: string[] = ['LOCAL'];

  /**
   * Determines whether the actions are readonly or not.
   */
  @Input() readOnly = false;

  /**
   * Event emitter to update permissions.
   */
  @Output() updatePermissions = new EventEmitter();

  /**
   * The list of columns to be displayed in the mat-table.
   */
  displayedColumns: string[] = [
    'principal',
    'type',
    'actions',
  ];

  /**
   * Map of SMB Permissions.
   */
  smbPermissions = SMB_ACL_PERMISSIONS;

  /**
   * Aggregated list of SMB Permissions as the table source.
   */
  permissionsList: SmbPermission[];

  /**
   * Determines whether data is loaded or not.
   */
  get isLoading(): boolean {
    return !this.allowAccessSids && !this.denyAccessSids;
  }

  constructor(
    private dialog: DialogService,
  ) { }

  /**
   * Init Component.
   */
  ngOnInit() {
    this.updatePermissionsList();
  }

  /**
   * Implements onChange.
   */
  ngOnChanges() {
    this.updatePermissionsList();
  }

  /**
   * Updates the aggregated list of all SMB Permissions, both allow and deny.
   */
  private updatePermissionsList() {
    const permissions = [];
    this.allowAccessSids?.forEach(sid => {
      permissions.push({
        sid: sid,
        type: 'Allow',
      });
    });
    this.denyAccessSids?.forEach(sid => {
      permissions.push({
        sid: sid,
        type: 'Deny',
      });
    });
    this.permissionsList = [...permissions];
  }

  /**
   * Emits changes to output channel.
   */
  private emitChanges() {
    this.updatePermissions.emit({
      allowAccessSids: this.allowAccessSids,
      denyAccessSids: this.denyAccessSids
    } as EmittedSnapshotPermissions);
  }

  /**
   * Opens dialog to add new SMB Permission.
   */
  public addSnapshotPermission() {
    const data = {
      visibleDomains: this.trustedAdDomains,
      snapshots: true,
      title: 'addSmbAccessPermission',
      domainPrincipalsHash: this.domainPrincipalsHash,
      permissionList: this.permissionsList,
    };

    this.dialog.showDialog(PrincipalDialogComponent, data, { restoreFocus: false })
      .subscribe((newPermission: NewPermission) => {
        if (newPermission) {
          // Push new permission object into the respect bucket.
          if (newPermission.type === 'Allow') {
            this.allowAccessSids = [...(this.allowAccessSids || []), newPermission.sid];
          } else {
            this.denyAccessSids = [...(this.denyAccessSids || []), newPermission.sid];
          }
          this.emitChanges();
        }
      });
  }

  /**
   * Removes the selected SMB Permission.
   *
   * @param   permission   SMB Permission
   */
  public removeSnapshotPermission(permission: SmbPermission) {
    if (permission.type === 'Allow') {
      this.allowAccessSids = this.allowAccessSids?.filter(sid => permission.sid === sid ? undefined : sid);
    } else {
      this.denyAccessSids = this.denyAccessSids?.filter(sid => permission.sid === sid ? undefined : sid);
    }
    this.emitChanges();
  }

  /**
   * Returns principal name and domain (unless Well-Known) for the given SID.
   * Examples:
   *   admin (LOCAL)
   *   vijay (q01.eng.cohesity.com)
   *   Administrators
   *
   * @param   sid   SID.
   */
  public getPrincipalName(sid: string): string {
    const principal = this.domainPrincipalsHash[sid] || {
      name: sid,
    };
    const name = principal?.fullName || principal?.name;
    const domain = principal?.domain;

    if (domain) {
      return name + ' (' + domain + ')';
    }

    return name;
  }
}
