import { Injectable } from '@angular/core';
import { flagEnabled, isClusterScope, IrisContextService } from '@cohesity/iris-core';
import { UserService } from './user.service';

import { AjsUpgradeService } from './ajs-upgrade.service';


/**
 * Angular replacement for legacy SourceService service.
 * Some methods are pass-through for now and will be replaced.
 */
@Injectable({
  providedIn: 'root'
})
export class SourceService {

  /**
   * Legacy SourceService AngularJS service.
   */
  private ajsSourceService: any;

  constructor(
    private ajsUpgrade: AjsUpgradeService,
    private irisContextService: IrisContextService,
    private userService: UserService,
  ) {
    this.ajsSourceService = ajsUpgrade.get('SourceService');
  }

  /**
   * Get the list of resource pools.
   *
   * @param   params  Conatains id of vCenter
   * @param   headers Contains the accessClusterId for MCM mode
   * @return  Promise object that resolves to list of resource pools.
   */
  getResourcePools(params, headers): any {
    return this.ajsSourceService.getResourcePool(params, headers);
  }

  /**
   * Get the list of datastores.
   *
   * @param  params  Contains vCenterId and resourcePoolId
   * @param   headers Contains the accessClusterId for MCM mode
   * @return Promise object that resolves to list of datastores.
   */
  getDatastores(params, headers): any {
    return this.ajsSourceService.getDatastores(params, headers);
  }

  /**
   * Get the list of networks.
   *
   * @param  params  Contains vCenterId and resourcePoolId
   * @param   headers Contains the accessClusterId for MCM mode
   * @return Promise object that resolves to list of datastores.
   */
  getNetworkEntities(params, headers): any {
    return this.ajsSourceService.getNetworkEntities(params, headers);
  }

  /**
   * This method checks if the logged in user can fetch default
   * bifrost connection for source regisration ONLY.
   *
   * @returns True if state/dialog params has to be augmented
   * with bifrost connection details, else False
   */
  private canFetchDefaultBifrostConnection(): boolean {
    return (
      // Association to Bifrost connection only applies when
      // Bifrost tenant is trying to register a source
      this.userService.isBifrostTenantUser() &&
      // Check if cluster compat flag is enabled
      flagEnabled(
        this.irisContextService.irisContext,
        'enableHyxRealmClusterCompatChanges') &&
      // Hyx realms compat changes are to be applied on
      // cluster since on Helios, user has to manually choose
      // bifrost connection during source registration on UI.
      isClusterScope(this.irisContextService.irisContext)
    );
  }

  /**
   * This method fetches the default bifrost connection for logged
   * in user. Use this method ONLY to associate sources with fallback
   * default bifrost connection during source regisration in context
   * of Cluster and Bifrost Tenant User.
   *
   * @returns default bifrost connection for logged in user
   */
  private getDefaultConnectionId(): number {
    if (this.canFetchDefaultBifrostConnection()) {
      return this.userService.getDefaultBifrostConnectionId();
    }
    return undefined;
  }

  /**
   * This function returns the Bifrost connectionId to associate the source with.
   * It abstracts the logic for fetching Bifrost connectionId
   * from different source registration components. It returns the connectionId
   * based on the workflow which component is executing, mode in which component is
   * operating, and route/state params passed to the component.
   *
   * @param isEditMode True if source update workflow; else false
   * @param isDialogMode True if component is opened in dialog mode
   * @param dialogParams dialog params passed to the component
   * @param stateParams route params passed to the component
   */
  getSourceConnectionId(
    isEditMode: boolean,
    isDialogMode: boolean,
    dialogParams?: any,
    stateParams?: any
  ): number {
    // cannot update source with a new connectionId
    if (isEditMode) {
      return undefined;
    }

    // target object for looking for connectionId
    const targetParams = isDialogMode ? dialogParams : stateParams;

    // check if connectionId is passed to the component
    const connectionId = targetParams?.connectionId;

    // return a valid connectionId
    if (connectionId) {
      return connectionId;
    }

    // try to fetch default connectionId
    return this.getDefaultConnectionId();
  }
}
