import { ChangeDetectorRef, Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntilDestroyed } from 'ngx-sub-form';
import { distinctUntilChanged } from 'rxjs/operators';
import {
  BaseProtectionBuilderComponent,
} from 'src/app/modules/protection/protection-builder/components/base-protection-builder/base-protection-builder.component';
import { ProtectionItemName } from '../../../models/protection-item-name.model';
import { ModifyProtectionService } from '../../../services/modify-protection.service';

/**
 * System Keyspace backup option for a Cassandra Source.
 */
@Component({
  selector: 'coh-settings-list-system-keyspaces',
  templateUrl: './settings-list-system-keyspaces.component.html',
})
export class SettingsListSystemKeyspacesComponent
  extends BaseProtectionBuilderComponent<boolean, any> {


  /**
   * Local form group to get system keyspace backup selection values.
   */
  systemKeyspacesBackupFormGroup: UntypedFormGroup;

  constructor(
    private formService: ModifyProtectionService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  /**
   * Init form control.
   */
  initFormControl() {
    let backupSystemKeyspaces = false;

    if (this.protectionGroup && this.protectionGroup.id) {
      const protectionGroup = this.formService.protectionGroup;
      backupSystemKeyspaces = protectionGroup.isSystemKeyspaceBackup;
    }
    this.systemKeyspacesBackupFormGroup = new UntypedFormGroup({
      systemKeyspaceSelection: new UntypedFormControl({
        value: backupSystemKeyspaces,
        disabled: this.formService.isEdit
      }, Validators.required),
    });
    this.formControl.setValue(backupSystemKeyspaces);
    const systemKeyspaceControl = this.systemKeyspacesBackupFormGroup.controls.systemKeyspaceSelection;

    if (!this.formService.isEdit) {
      // Respond to changes originating from the Cassandra System Keysaces option to update the selection.
      this.formService.onControlChange(ProtectionItemName.LogBackupSelection, true).pipe(
        this.untilDestroy(),
        // Trigger an update only if the value actually changes
        distinctUntilChanged(),
      ).subscribe(logBackupSelection => {
        systemKeyspaceControl.reset();
        systemKeyspaceControl.setValue(false);
        if (logBackupSelection) {
          systemKeyspaceControl.disable();
        } else {
          systemKeyspaceControl.enable();
        }
        this.systemKeyspacesBackupFormGroup.updateValueAndValidity();
        this.cdr.detectChanges();
      });

      // Respond to changes originating from the Cassandra Source selection.
      this.formService.onControlChange(ProtectionItemName.Source, true).pipe(
        this.untilDestroy(),
        // Trigger an update only if the value actually changes
        distinctUntilChanged(),
      ).subscribe(() => {
        systemKeyspaceControl.reset();
        systemKeyspaceControl.setValue(false);
        systemKeyspaceControl.updateValueAndValidity();
        this.systemKeyspacesBackupFormGroup.updateValueAndValidity();
        this.cdr.detectChanges();
      });
    }

    // On change
    systemKeyspaceControl.valueChanges.pipe(
      takeUntilDestroyed(this),
      distinctUntilChanged()
    ).subscribe(value => {
      this.formControl.setValue(!!value);
      this.systemKeyspacesBackupFormGroup.updateValueAndValidity();
    });
  }
}
