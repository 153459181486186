<div class="dl-row">
  <dt translate="sources.couchbase.seeds"></dt>
  <dd>{{ (couchbaseParams?.seeds || []).join(', ') }}</dd>
</div>
<div class="dl-row">
  <dt translate="httpPort"></dt>
  <dd>
    {{ couchbaseParams?.httpDirectPort }}
  </dd>
</div>
<div class="dl-row">
  <dt translate="carrierPort"></dt>
  <dd>
    {{ couchbaseParams?.carrierDirectPort }}
  </dd>
</div>
<div class="dl-row">
  <dt translate="sslRequirement"></dt>
  <dd>{{ couchbaseParams?.requiresSsl | boolLabel }}</dd>
</div>
