import { Injectable } from '@angular/core';
import { NavItem } from '@cohesity/helix';
import { AppType, flagEnabled, IrisContext, PillarType } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';

import { ServiceNavProvider } from '../service-nav-provider';

/**
 * This Service configures the ransomware site recovery navigation menu with iris.
 */
@Injectable({ providedIn: 'root' })
export class RpaasNavService implements ServiceNavProvider {
  pillar: PillarType = 'security';

  app: AppType = 'rpaas';


  get serviceNavTitle(): string {
    return this.translate.instant('rpaas.shortTitle');
  }

  serviceNavTitleIcon = 'helix:logo-fort-knox';

  serviceNavTitleClass = 'fort-knox';

  showNavBranding = true;

  getNavItems(irisContext: IrisContext): NavItem[] {
    return [
      {
        displayName: 'dashboard',
        state: 'rpaasDashboard.summary',
        icon: 'dashboard!outline',
      },
      {
        displayName: 'anomalies',
        state: 'rpaasDashboard.anomalies',
        icon: 'notifications_none',
      },
      {
        displayName: 'cloudVault',
        icon: 'helix:vault',
        state: 'rpaas-protection.landing.backup',
        subNavList: [
          {
            displayName: 'protection',
            state: 'rpaas-protection.landing.backup',
          },
          {
            displayName: 'recoveries',
            state: 'rpaasActivity.recoveries',
          },
          {
            displayName: 'configuration',
            state: 'rpaasVaults.list',
          },
        ]
      },
      {
        displayName: 'clusters',
        state: 'rpaas-protection-clusters.list',
        icon: 'helix:cluster',
      },
      {
        displayName: 'security',
        state: 'helios-security-advisor',
        icon: 'fingerprint',
        subNavList: [
          {
            displayName: 'quorum.pageTitle',
            state: 'helios-security-quorum'
          },
          flagEnabled(irisContext, 'rpaasAuditLogs') ? {
            displayName: 'auditLogs',
            state: 'helios-audit-logs.list'
          } : undefined,
          {
            displayName: 'health',
            state: 'health.alerts',
            stateParams: {
              alertCategoryList: ['kSecurity']
            }
          },
        ].filter(Boolean),
      },
      {
        displayName: 'reports',
        state: 'reporting.list',
        icon: 'assessment!outline',
        activeStates: ['reporting.detail', 'reporting.schedule'],
      },
      {
        displayName: 'settings',
        icon: 'settings!outline',
        state: 'helios-access-management',
        subNavList: [
          {
            displayName: 'accessManagement',
            state: 'helios-access-management',
          }
        ],
      },
    ];
  }
  constructor(private translate: TranslateService) {}
}
