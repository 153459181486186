<mat-form-field
  [formGroup]="formGroup"
  [appearance]="floatingLabel ? 'standard' : 'legacy'"
  [floatLabel]="floatingLabel ? 'auto' : 'never'">
  <mat-label>{{ label }}</mat-label>

  <mat-chip-list #chipList cogDataId="chip-list" [inheritDataId]="true">
    <mat-chip
      *ngFor="let item of formGroupValues.value"
      [cogDataId]="'item-' + item"
      [inheritDataId]="true"
      (removed)="onRemove(item)">
      {{ item }}
      <cog-icon shape="cancel" matChipRemove cogDataId="remove" [inheritDataId]="true"></cog-icon>
    </mat-chip>

    <input
      cogDataId="new-item-input"
      [attr.maxlength]="maxInputLength ? maxInputLength : null"
      [inheritDataId]="true"
      [formControlName]="formControlNames.input"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
      [matChipInputAddOnBlur]="true"
      (matChipInputTokenEnd)="onMatChipInputTokenEnd($event)">
  </mat-chip-list>
</mat-form-field>
