<ng-container *ngFor="let entry of extensionConfigs">
  <ng-container [matColumnDef]="entry.config.columnName">
    <!-- Non Flex Tables -->
    <ng-container *ngIf="!isFlex">
      <th mat-header-cell *matHeaderCellDef>
        {{entry.config.label}}
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-template [cdkPortalOutlet]="entry.portal" (attached)="attachCellPortal($event, row)">
        </ng-template>
      </td>
    </ng-container>

    <!-- Flex Tables-->
    <ng-container *ngIf="isFlex">
      <mat-header-cell *matHeaderCellDef>
        {{entry.config.label}}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <ng-template [cdkPortalOutlet]="entry.portal" (attached)="attachCellPortal($event, row)">
        </ng-template>
      </mat-cell>
    </ng-container>
  </ng-container>
</ng-container>
