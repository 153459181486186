import { Injectable, Injector } from '@angular/core';
import { IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { SourceTreeDataProvider, SourceTreeService } from '@cohesity/iris-source-tree';

import { ENV_GROUPS, envGroups, Environment } from '../../constants';
import { AdSourceTreeService } from '../protection-source/ad/ad-source-tree.service';
import { AwsSourceTreeService } from '../protection-source/aws/aws-source-tree.service';
import { AzureSourceTreeService } from '../protection-source/azure/azure-source-tree.service';
import { CassandraSourceTreeService } from '../protection-source/cassandra/cassandra-source-tree-service';
import { CouchbaseSourceTreeService } from '../protection-source/couchbase/couchbase-source-tree.service';
import { ExchangeSourceTreeService } from '../protection-source/exchange/exchange-source-tree.service';
import { GcpSourceTreeService } from '../protection-source/gcp/gcp-source-tree.service';
import { HBaseSourceTreeService } from '../protection-source/hbase/hbase-source-tree.service';
import { HiveSourceTreeService } from '../protection-source/hive/hive-source-tree.service';
import { KubernetesSourceTreeService } from '../protection-source/kubernetes/kubernetes-source-tree.service';
import { MongoDBSourceTreeService } from '../protection-source/mongodb/mongodb-source-tree.service';
import { GenericNasSourceTreeService } from '../protection-source/nas/genericnas-source-tree.service';

import { IbmFlashSystemSourceTreeService } from '../protection-source/ibmflashsystem/ibmflashsystem-source-tree-service';
import { IsilonSourceTreeService } from '../protection-source/nas/isilon-source-tree.service';
import { NasSourceTreeService } from '../protection-source/nas/nas-source-tree.service';
import { NetappSourceTreeService } from '../protection-source/nas/netapp-source-tree.service';
import { Office365SourceTreeService } from '../protection-source/office365/office365-source-tree-service';
import { OracleSourceTreeService } from '../protection-source/oracle/oracle-source-tree-service';
import {
  PhysicalBlockSourceTreeService,
} from '../protection-source/physical/physical-block/physical-block-source-tree-service';
import {
  PhysicalFilesSourceTreeService,
} from '../protection-source/physical/physical-files/physical-files-source-tree.service';
import { PureSourceTreeService } from '../protection-source/pure/pure-source-tree.service';
import { SapHanaSourceTreeService } from '../protection-source/saphana/saphana-source-tree-service';
import { SfdcSourceTreeService } from '../protection-source/sfdc/sfdc-source-tree.service';
import { SqlSourceTreeService } from '../protection-source/sql/sql-source-tree.service';
import { UdaSourceTreeService } from '../protection-source/uda/uda-source-tree-service';
import { AcropolisSourceTreeService } from '../protection-source/vm/acropolis-source-tree.service';
import { HypervSourceTreeService } from '../protection-source/vm/hyperv-source-tree.service';
import { KvmSourceTreeService } from '../protection-source/vm/kvm-source-tree.service';
import { VmwareSourceTreeService } from '../protection-source/vm/vmware-source-tree.service';
import { AwsSourceDataProvider } from './aws/aws-source-data-provider';
import { AzureSourceDataProvider } from './azure/azure-source-data-provider';
import { Office365SourceDataProvider } from './office365/office365-source-data-provider';
import { PhysicalFilesSourceDataProvider } from './physical/physical-files/physical-files-source-data-provider';
import { AppProtectionSourceDataProvider } from './shared/app-protection-source-data-provider';
import { ProtectionSourceDataProvider } from './shared/protection-source-data-provider';

/**
 * Source tree factory - given an environment, this will return the correct tree service.
 */
@Injectable()
export class ProtectionSourceTreeServiceFactory {
  /**
   * List of available service types that can be created.
   */
  private availableServiceTypes = {
    // VMs
    [Environment.kAcropolis]: AcropolisSourceTreeService,
    [Environment.kHyperV]: HypervSourceTreeService,
    [Environment.kKVM]: KvmSourceTreeService,
    [Environment.kVMware]: VmwareSourceTreeService,

    // Cloud VMs
    [Environment.kAWS]: AwsSourceTreeService,
    [Environment.kAzure]: AzureSourceTreeService,
    [Environment.kGCP]: GcpSourceTreeService,

    // Apps
    [Environment.kAD]: AdSourceTreeService,
    [Environment.kExchange]: ExchangeSourceTreeService,
    [Environment.kO365]: Office365SourceTreeService,

    // Physical
    // TODO(mythri.m): Remove this once DMaaS is supported for Block based type as well.
    [Environment.kPhysical]: isDmsScope(this.irisContextService.irisContext)
      ? PhysicalFilesSourceTreeService
      : PhysicalBlockSourceTreeService,
    [Environment.kPhysicalFiles]: PhysicalFilesSourceTreeService,

    // SAN
    [Environment.kPure]: PureSourceTreeService,
    [Environment.kIbmFlashSystem]: IbmFlashSystemSourceTreeService,

    // DB
    [Environment.kCassandra]: CassandraSourceTreeService,
    [Environment.kCouchbase]: CouchbaseSourceTreeService,
    [Environment.kHBase]: HBaseSourceTreeService,
    [Environment.kHive]: HiveSourceTreeService,
    [Environment.kMongoDB]: MongoDBSourceTreeService,
    [Environment.kOracle]: OracleSourceTreeService,
    [Environment.kSQL]: SqlSourceTreeService,

    // Container
    [Environment.kKubernetes]: KubernetesSourceTreeService,

    // Nas overrides
    [Environment.kNetapp]: NetappSourceTreeService,
    [Environment.kIsilon]: IsilonSourceTreeService,
    [Environment.kGenericNas]: GenericNasSourceTreeService,

    // Salesforce
    [Environment.kSfdc]: SfdcSourceTreeService,

    // Uda
    [Environment.kUDA]: UdaSourceTreeService,

    // Sap Hana
    [Environment.kSAPHANA]: SapHanaSourceTreeService,
  };

  /**
   * Source tree data providers are written to provide consistent and adapter-specific methods for loading
   * the source tree.
   */
  private availableDataProviderTypes = {
    [Environment.kO365]: Office365SourceDataProvider,
    [Environment.kPhysicalFiles]: PhysicalFilesSourceDataProvider,
    [Environment.kAWS]: AwsSourceDataProvider,
    [Environment.kAzure]: AzureSourceDataProvider,
  };
  /**
   * A cache of already created tree services.
   */
  private serviceMap: {
    [key: string]: SourceTreeService<any, any>;
  } = {};

  constructor(private injector: Injector, private irisContextService: IrisContextService) {
    // NAS service types
    // Note the difference between envGroups and ENV_GROUPS. ENV_GROUPS, does not kGenericNas, while envGroups.nas does.
    envGroups.nas.forEach((env: string) => {
      // Don't override if there's already an existing service.
      this.availableServiceTypes[env] = this.availableServiceTypes[env] || NasSourceTreeService;
    });

    // Application adapter types. Note the difference between envGroups and ENV_GROUPS. These are different constants
    // and do not match exactly. envGroups.applications includes all of the environments we group as applications in
    // the UI, while ENV_GROUPS includes the environments that we register as applications.
    ENV_GROUPS.applicationSources.forEach((env: string) => {
      this.availableDataProviderTypes[env] = this.availableDataProviderTypes[env] || AppProtectionSourceDataProvider;
    });
  }

  /**
   * Gets the source tree service for the given environment.
   *
   * @param   environment  The current environment.
   * @return  The correct source tree service.
   */
  getSourceTreeService(environment: Environment): SourceTreeService<any, any> {
    if (!this.availableServiceTypes[environment]) {

      throw new Error(`No service implemented for environment ${environment}`);
    }
    if (!this.serviceMap[environment]) {
      this.serviceMap[environment] = this.injector.get<SourceTreeService<any, any>>(
        this.availableServiceTypes[environment]
      );
    }
    return this.serviceMap[environment];
  }

  /**
   * Gets a source tree data provider for the given environment.
   *
   * @param   environment   The current environment.
   * @returns The correct source tree data provider.
   */
  getSourceTreeDataProvider(environment: Environment): SourceTreeDataProvider<any> {
    if (this.availableDataProviderTypes[environment]) {
      return this.injector.get<SourceTreeDataProvider<any>>(this.availableDataProviderTypes[environment]);
    }
    return this.injector.get<SourceTreeDataProvider<any>>(ProtectionSourceDataProvider);
  }
}

/**
 * This is a list of all of the protection source providers. These need to be
 * explicitly provided so that separate instances of the source tree will get
 * separate instances of the services.
 */
export const protectionSourceProviders = [
  AdSourceTreeService,
  AwsSourceTreeService,
  AzureSourceTreeService,
  CassandraSourceTreeService,
  CouchbaseSourceTreeService,
  ExchangeSourceTreeService,
  GcpSourceTreeService,
  HBaseSourceTreeService,
  HiveSourceTreeService,
  IsilonSourceTreeService,
  KubernetesSourceTreeService,
  MongoDBSourceTreeService,
  NasSourceTreeService,
  NetappSourceTreeService,
  GenericNasSourceTreeService,
  Office365SourceTreeService,
  OracleSourceTreeService,
  PhysicalBlockSourceTreeService,
  PhysicalFilesSourceTreeService,
  PureSourceTreeService,
  IbmFlashSystemSourceTreeService,
  SfdcSourceTreeService,
  SqlSourceTreeService,
  AcropolisSourceTreeService,
  HypervSourceTreeService,
  KvmSourceTreeService,
  UdaSourceTreeService,
  VmwareSourceTreeService,
  SapHanaSourceTreeService,

  // Data providers
  AppProtectionSourceDataProvider,
  AwsSourceDataProvider,
  AzureSourceDataProvider,
  Office365SourceDataProvider,
  PhysicalFilesSourceDataProvider,
  ProtectionSourceDataProvider,
];
