import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AnomalyObjectIconPipeModule, RbacModule } from '@cohesity/data-govern/shared';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { IrisRouterModule, TenantColumnModule } from '@cohesity/iris-core';
import { EnvironmentTypeModule } from '@cohesity/utils';
import { TranslateModule } from '@ngx-translate/core';

import { SecuritySharedPipesModule } from '../../pipes';
import { AiBotColumnComponent } from '../ai-bot-column';
import { AnomalyTagsModule } from '../anomaly-tags';
import { DcScanRunStatsModule } from '../dc-scan-run-stats/dc-scan-run-stats.module';
import { ScanRunStatusModule } from '../scan-run-status';
import { ThScanRunStatsModule } from '../th-scan-run-stats';
import { AnomalyTableComponent } from './anomaly-table.component';

const COMPONENTS = [AnomalyTableComponent];

@NgModule({
  imports: [
    AiBotColumnComponent,
    AnomalyObjectIconPipeModule,
    AnomalyTagsModule,
    CohesityHelixModule,
    CommonModule,
    DcScanRunStatsModule,
    EnvironmentTypeModule,
    IrisRouterModule,
    RbacModule,
    ScanRunStatusModule,
    SecuritySharedPipesModule,
    TenantColumnModule,
    ThScanRunStatsModule,
    TranslateModule,
  ],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
})
export class AnomalyTableModule {}
