import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ProtectionSourceNode, ProtectionSourcesServiceApi } from '@cohesity/api/v1';
import { DataTreeFilter, DataTreeSelectComponent } from '@cohesity/helix';
import { FilterOption, SourceTreeNode, SourceTreeSelectDirective } from '@cohesity/iris-source-tree';
import { fromControlValueChange, updateFormControlStatus } from '@cohesity/shared-forms';
import { Controls, NgxSubFormRemapComponent, subformComponentProviders, takeUntilDestroyed } from 'ngx-sub-form';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { PassthroughOptionsService } from 'src/app/core/services';
import { Environment } from 'src/app/shared';
import { ProtectionSourceDataNode } from 'src/app/shared/source-tree/protection-source';
import { HasConnectionState } from 'src/app/shared/source-tree/protection-source/shared/behaviors/has-connection-state';

import { TargetSelectorFormService, targetSelectorFormServiceProvider } from './target-selector-form.service';

/**
 * The target selector requires selecting a source and a target.
 */
interface TargetForm {
  /**
   * The parent source node
   */
  source: ProtectionSourceNode;

  /**
   * The target node
   */
  target: ProtectionSourceDataNode;
}

/**
 * This is a resuable set of form fields for selecting a target. The output is a
 * single ProtectionSourceDataObject. Protection source nodes are cached so that switching
 * between sources in the source selector will not reload protection sources for the same source
 * constantly. In order to persist the cache throughout the entire form, you can add the
 * `targetSelectorFormServiceProvider` to the main form component's list of providers.
 */
@Component({
  selector: 'coh-target-selector-form',
  templateUrl: './target-selector-form.component.html',
  styleUrls: ['./target-selector-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...subformComponentProviders(TargetSelectorFormComponent), targetSelectorFormServiceProvider],
})
export class TargetSelectorFormComponent extends NgxSubFormRemapComponent<ProtectionSourceNode, TargetForm>
  implements OnInit, OnDestroy {

  /**
   * If the component should be displayed as read-only.
   */
  @Input() readOnly: boolean;

  /**
   * Tracks the currently selected source id.
   */
  _sourceId = new BehaviorSubject<number>(null);

  /**
   * Sets the source id
   */
  @Input() set sourceId(sourceId: number) {
    this._sourceId.next(sourceId);
  }

  /**
   * Gets the source id
   */
  get sourceId(): number {
    return this._sourceId.value;
  }

  /**
   * A list of environments that should be available in the parent source selector
   */
  @Input() environments: Environment[];

  /**
   * A target id to initialize to.
   */
  @Input() initialTargetId: number;

  /**
   * Source tree by default selects the first filter. If set to true,
   * this option unsets that filters.
   */
  @Input() overrideSourceTreeFilter = false;

  /**
   * Param config to pass to the protectin source api. This source id will be added automatically
   * based on the selection.
   */
  @Input() sourceParams: ProtectionSourcesServiceApi.ListProtectionSourcesParams;

  /**
   * A subject tracking the currently available source nodes.
   */
  sourceNodes = new BehaviorSubject<ProtectionSourceNode[]>([]);

  /**
   * Additional filters to apply to the source nodes.
   */
  @Input() targetFilters: DataTreeFilter<SourceTreeNode<any>>[] = [];

  /**
   * Allowable types that the selector should be configured to select.
   */
  @Input() targetTypes: string[] = ['kVirtualMachine'];

  /**
   * Reference to source-tree-select directive to manipulate filter.
   */
  @ViewChild(SourceTreeSelectDirective, {static: true})
  private sourceTreeSelectDirective: SourceTreeSelectDirective<ProtectionSourceNode, SourceTreeNode<any>>;

  /**
   * Save original filters to reset when component is destroyed.
   */
  originalFilters: FilterOption[];

  /**
   * The current environment, based on the source selector's value.
   */
  get currentEnvironment(): Environment {
    return (
      (this.formGroupValues.source && this.formGroupValues.source.protectionSource.environment) ||
      (this.environments && this.environments[0])
    ) as any;
  }

  /**
   * True if this is a GCP recovery, which has slightly different options.
   */
  get isGCP() {
    return [Environment.kGCP, Environment.kGCPNative].includes(this.currentEnvironment);
  }

  /**
   * A handle to the data tree select component.
   */
  @ViewChild(DataTreeSelectComponent, { static: true }) treeSelect: DataTreeSelectComponent<ProtectionSourceDataNode>;

  /**
   * Convert the node to an instance of the HasConnectionState interface. Returns
   * undefined if the node does not implement the interface.
   */
  getNodeAsConnectionState(node: ProtectionSourceDataNode): HasConnectionState | undefined {
    return (node as any).asConnectionState;
  }

  /**
   * Callback method to determine what types should be selectable.
   */
  canSelectFn = (node: ProtectionSourceDataNode) =>
    ((this.targetTypes ? this.targetTypes.includes(node.type) : true) &&
    (this.getNodeAsConnectionState(node) ? !this.getNodeAsConnectionState(node).hasConnectionStateProblem : true));

  /**
   * Constructor.
   */
  constructor(
    private cdr: ChangeDetectorRef,
    readonly targetService: TargetSelectorFormService,
    private passthroughOptionsService: PassthroughOptionsService) {
    super();
  }

  /**
   * Initialization.
   */
  ngOnInit() {
    // Update the source id when the source control changes
    fromControlValueChange<ProtectionSourceNode>(this.formGroupControls.source)
      .pipe(takeUntilDestroyed(this))
      .subscribe(source => (this.sourceId = source.protectionSource.id));

    // Fetch source details when the source changes.
    this._sourceId
      .pipe(
        takeUntilDestroyed(this),
        switchMap(id => this.targetService.getSourceNodes(id, {
          ...this.sourceParams,
          ...this.passthroughOptionsService.requestParams,
        }))
      )
      .subscribe(nodes => this.sourceNodes.next(nodes));

    // Enable or Disable the target control whenever the source changes
    combineLatest([fromControlValueChange(this.formGroupControls.source), this.sourceNodes])
      .pipe(
        takeUntilDestroyed(this),
        map(([source, nodes]) => !!source && nodes && !!nodes.length),
        filter(() => !!this.formGroup)
      )
      .subscribe(enableTarget => {
        updateFormControlStatus(this.formGroup, { target: enableTarget });
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    if (this.sourceTreeSelectDirective) {
      this.sourceTreeSelectDirective.filters.setViewFilters(this.originalFilters);
    }
    super.ngOnDestroy();
  }

  /**
   * Listen for when the data in the select is initialized.
   * If an initial target has been specified, then set the value on the control, otherwise,
   * open the select control.
   *
   * @param   treeData   The current list of data nodes.
   */
  onDataInitialized(treeData: ProtectionSourceDataNode[]) {
    if (!treeData.length) {
      return;
    }
    if (this.initialTargetId) {
      const initialTarget = treeData.find(node => node.id === this.initialTargetId);
      if (initialTarget) {
        this.initialTargetId = null;
        this.formGroupControls.target.setValue(initialTarget);
      }
    }

    if (this.overrideSourceTreeFilter && this.sourceTreeSelectDirective) {
      const nullFilter = [null];
      const viewFilter = this.sourceTreeSelectDirective.filters.getViewFilters();
      if (viewFilter !== nullFilter) {
        this.originalFilters = viewFilter;
      }
      this.sourceTreeSelectDirective.filters.setViewFilters(nullFilter);
    }
  }

  /**
   * Gets form controls.
   *
   * @returns  Form controls.
   */
  getFormControls(): Controls<TargetForm> {
    return {
      source: new UntypedFormControl(null, Validators.required),
      target: new UntypedFormControl(null, Validators.required),
    };
  }

  /**
   * Tranforms to FormGroup from data structure.
   *
   * @param  obj  ProtectionSourceNode data.
   * @returns  TargetForm data.
   */
  transformToFormGroup(obj: ProtectionSourceNode | null): TargetForm {
    if (obj && obj.protectionSource) {
      this.sourceId = (obj.protectionSource as any).sourceId;
      this.initialTargetId = obj.protectionSource.id;
    }
    return {
      source: null,
      target: null,
    };
  }

  /**
   * Tranforms from FormGroup to data structure.
   *
   * @param  formValue  TargetForm data value.
   * @returns  ProtectionSourceNode data.
   */
  transformFromFormGroup(formValue: TargetForm): ProtectionSourceNode | null {
    return formValue.target && formValue.target.data;
  }
}
