<cog-settings-list-item [formGroup]="formGroup"
  *ngIf="formControl"
  [invalid]="formControl.invalid"
  [label]="'quietTimes' | translate">
  <cog-settings-list-item-value-display>
    {{quietTimesEnabledControl.value | boolLabel}}
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor [formGroup]="quietTimesFormGroup"
    cogDataId="settings-quiet-times">
    <mat-slide-toggle formControlName="enabled"
      [inheritDataId]="true"
      (change)="toggleQuietTimes()"
      cogDataId="enabled">
      {{'protectionGroups.stopInQuietTimes' | translate}}
    </mat-slide-toggle>
    <mat-radio-group formControlName="action" *ngIf="quietTimesEnabledControl.value">
      <mat-radio-button class="margin-top"
        cogDataId="pause-action"
        value="pause">{{'pauseRuns' | translate}}</mat-radio-button>
      <small class="metadata margin-left-lg">
        {{'protectionGroups.pauseInQuietTimesHint' | translate}}
      </small>
      <mat-radio-button class="margin-top"
        cogDataId="resume-action"
        value="abort">{{'abortRuns' | translate}}</mat-radio-button>
      <small class="metadata margin-left-lg">
        {{'protectionGroups.abortInQuietTimesHint' | translate}}
      </small>
    </mat-radio-group>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
