/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Ability } from '../models/ability';
import { BulkCreateDataPoolRequest } from '../models/bulk-create-data-pool-request';
import { BulkDataPoolResponse } from '../models/bulk-data-pool-response';
import { BulkUpdateDataPoolRequest } from '../models/bulk-update-data-pool-request';
import { GetDataPoolsResponse } from '../models/get-data-pools-response';
import { Name } from '../models/name';
import { ResourceId } from '../models/resource-id';
import { Visibility } from '../models/visibility';

@Injectable({
  providedIn: 'root',
})
export class DataPoolsApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getDataPools
   */
  static readonly GetDataPoolsPath = '/data-pools';

  /**
   * Get the list of Data Pools.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDataPools()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDataPools$Response(params?: {

    /**
     * Filter by a list of Data Pool ids.
     */
    ids?: null | Array<ResourceId>;

    /**
     * Filter by a list of registered Data Source Ids.
     */
    dataSourceIds?: null | Array<ResourceId>;

    /**
     * Filter by a list of Data Pool Names.
     */
    names?: null | Array<Name>;

    /**
     * Specifies the account ID of the user.
     */
    sfAccountId?: ResourceId;

    /**
     * Filter by Data Sources enabled/disabled ability.
     */
    abilities?: null | Array<Ability>;

    /**
     * Filter by Data Sources enabled/disabled visibility.
     */
    visibilities?: null | Array<Visibility>;

  }): Observable<StrictHttpResponse<GetDataPoolsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataPoolsApiService.GetDataPoolsPath, 'get');
    if (params) {

      rb.query('ids', params.ids, {});
      rb.query('dataSourceIds', params.dataSourceIds, {});
      rb.query('names', params.names, {});
      rb.query('sfAccountId', params.sfAccountId, {});
      rb.query('abilities', params.abilities, {});
      rb.query('visibilities', params.visibilities, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetDataPoolsResponse>;
      })
    );
  }

  /**
   * Get the list of Data Pools.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDataPools$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDataPools(params?: {

    /**
     * Filter by a list of Data Pool ids.
     */
    ids?: null | Array<ResourceId>;

    /**
     * Filter by a list of registered Data Source Ids.
     */
    dataSourceIds?: null | Array<ResourceId>;

    /**
     * Filter by a list of Data Pool Names.
     */
    names?: null | Array<Name>;

    /**
     * Specifies the account ID of the user.
     */
    sfAccountId?: ResourceId;

    /**
     * Filter by Data Sources enabled/disabled ability.
     */
    abilities?: null | Array<Ability>;

    /**
     * Filter by Data Sources enabled/disabled visibility.
     */
    visibilities?: null | Array<Visibility>;

  }): Observable<GetDataPoolsResponse> {

    return this.getDataPools$Response(params).pipe(
      map((r: StrictHttpResponse<GetDataPoolsResponse>) => r.body as GetDataPoolsResponse)
    );
  }

  /**
   * Path part for operation bulkUpdateDataPools
   */
  static readonly BulkUpdateDataPoolsPath = '/data-pools-bulk';

  /**
   * Update Data Pools in bulk.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkUpdateDataPools()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkUpdateDataPools$Response(params: {
      body: BulkUpdateDataPoolRequest
  }): Observable<StrictHttpResponse<BulkDataPoolResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataPoolsApiService.BulkUpdateDataPoolsPath, 'put');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BulkDataPoolResponse>;
      })
    );
  }

  /**
   * Update Data Pools in bulk.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bulkUpdateDataPools$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkUpdateDataPools(params: {
      body: BulkUpdateDataPoolRequest
  }): Observable<BulkDataPoolResponse> {

    return this.bulkUpdateDataPools$Response(params).pipe(
      map((r: StrictHttpResponse<BulkDataPoolResponse>) => r.body as BulkDataPoolResponse)
    );
  }

  /**
   * Path part for operation bulkCreateDataPools
   */
  static readonly BulkCreateDataPoolsPath = '/data-pools-bulk';

  /**
   * Create Data Pools in bulk.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkCreateDataPools()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkCreateDataPools$Response(params: {
      body: BulkCreateDataPoolRequest
  }): Observable<StrictHttpResponse<BulkDataPoolResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DataPoolsApiService.BulkCreateDataPoolsPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BulkDataPoolResponse>;
      })
    );
  }

  /**
   * Create Data Pools in bulk.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bulkCreateDataPools$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkCreateDataPools(params: {
      body: BulkCreateDataPoolRequest
  }): Observable<BulkDataPoolResponse> {

    return this.bulkCreateDataPools$Response(params).pipe(
      map((r: StrictHttpResponse<BulkDataPoolResponse>) => r.body as BulkDataPoolResponse)
    );
  }

}
