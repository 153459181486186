import { ChangeTypeCountList } from '@cohesity/api/argus';
import { ObjectServiceApi, SnapshotDiffResult } from '@cohesity/api/v2';
import { AsyncTableDataSource } from '@cohesity/helix';
import { BehaviorSubject } from 'rxjs';

import { AffectedFilesFilter, AffectedFilesFilterArguments } from '../security-shared.models';
import { SnapshotInfo } from './../security-shared.models';

/**
 * Interface describing the available filters for the data.
 */
export interface InlineFileSearchFilter extends ObjectServiceApi.InternalApiGetSnapshotDiffParams {
  totalRecords?: number;
}

/**
 * Interface describing the available filters for the data.
 */
export interface RegularFileSearchFilter {
  searchQuery?: string;
  jobId?: number;
  snapshot1TimeUsecs?: number;
  snapshot2TimeUsecs?: number;
  clusterId?: number;
}

/**
 * Api response type for inline and regular file diff
 */
type ApiResponse = SnapshotInfo | SnapshotDiffResult;

/**
 * Table filters for file data sources
 */
type TableFilter = RegularFileSearchFilter | InlineFileSearchFilter | AffectedFilesFilter;

/**
 * Abstract class that must be implemented by all File data sources
 */
export abstract class FileDataSource extends AsyncTableDataSource<ApiResponse, TableFilter> {
  /**
   * This reflects whether the data source currently has any data.
   */
  hasData$ = new BehaviorSubject<boolean>(false);

  /**
   * This reflects whether the data source currently has any data.
   */
  loadingData$ = new BehaviorSubject<boolean>(true);

  /**
   * Holds affected files stats
   */
  changeTypeCounts: ChangeTypeCountList;

  /**
   * Sets required filter data based on table source
   */
  abstract setFilters(filters: AffectedFilesFilterArguments): void;

  /**
   * Emit observable and return undefined.
   */
  abstract emptyDataSet(): void;

  /**
   * Resets all essential data when component is destroyed
   */
  abstract cleanUp(): void;
}
