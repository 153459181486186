import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const performanceWorkloadsAccess = (ctx: StateAccessContext) =>
  ctx.CLUSTER_VIEW && ctx.FEATURE_FLAGS['ngPerformanceWorkloads'];

export const PerformanceConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'performance.**',
      url: '/performance',
      loadChildren: () => import('./performance.module').then(m => m.PerformanceModule),
    },
    {
      name: 'workload-details.**',
      url: '^/performance/workloads/details?{metricName}&{workloadName}&{timeframe}',
      loadChildren: () => import('./performance.module').then(m => m.PerformanceModule),
    },
  ],
  getStateAccessMap(): StateAccessMap {
    return {
      'performance': (ctx: StateAccessContext) => ctx.CLUSTER_VIEW,
      'performance.workloads': performanceWorkloadsAccess,
      'workload-details': performanceWorkloadsAccess,
    };
  }
};
