/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccountId } from '../models/account-id';
import { ForceSyncFlow } from '../models/force-sync-flow';
import { ListAssociatedTagsParams } from '../models/list-associated-tags-params';
import { ListAssociatedTagsResp } from '../models/list-associated-tags-resp';
import { PaginationToken } from '../models/pagination-token';
import { Tag } from '../models/tag';
import { TagCategoryList } from '../models/tag-category-list';
import { TagListWithPagination } from '../models/tag-list-with-pagination';
import { TagNameList } from '../models/tag-name-list';
import { TagSubCategoryList } from '../models/tag-sub-category-list';
import { TagThirdPartyNameList } from '../models/tag-third-party-name-list';
import { TagTypeList } from '../models/tag-type-list';
import { TagUuid } from '../models/tag-uuid';
import { TagUuidList } from '../models/tag-uuid-list';
import { TagsActionParams } from '../models/tags-action-params';
import { TagsActionResp } from '../models/tags-action-resp';
import { TagsAsyncStatusResp } from '../models/tags-async-status-resp';
import { TagsDeletionResp } from '../models/tags-deletion-resp';
import { TenantId } from '../models/tenant-id';
import { TrackingId } from '../models/tracking-id';
import { UpdateTagParams } from '../models/update-tag-params';

@Injectable({
  providedIn: 'root',
})
export class HeliosTaggingApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation listTagsOp
   */
  static readonly ListTagsOpPath = '/tags';

  /**
   * List the tags.
   *
   * Returns a list of tags based on the defined filter criteria.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listTagsOp()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTagsOp$Response(params?: {

    /**
     * Sf Account Id
     */
    account_id?: AccountId;

    /**
     * Should only be passed in case of DMaas. Should be format &lt;CloudOpAccountID&gt;:&lt;TenantID&gt; Warning please passed in case of DMaas account only.
     */
    tenant_id?: TenantId;

    /**
     * Specifies Uuids to filter the tags.
     */
    uuids?: TagUuidList;

    /**
     * Specifies exact Names to filter the tags.
     */
    names?: TagNameList;

    /**
     * Specifies categories to filter the tags.
     */
    categories?: TagCategoryList;

    /**
     * Specifies sub-categories to filter the tags.
     */
    subCategories?: TagSubCategoryList;

    /**
     * Specifies types to filter the tags.
     */
    types?: TagTypeList;

    /**
     * Specifies names to search the third party tags.
     */
    thirdPartyNames?: TagThirdPartyNameList;

    /**
     * Query parameter to specify the number of results per page
     */
    pageSize?: null | number;

    /**
     * Specifies the pagination token used to get the next page
     */
    paginationToken?: PaginationToken;

  }): Observable<StrictHttpResponse<TagListWithPagination>> {

    const rb = new RequestBuilder(this.rootUrl, HeliosTaggingApiService.ListTagsOpPath, 'get');
    if (params) {

      rb.header('account_id', params.account_id, {});
      rb.header('tenant_id', params.tenant_id, {});
      rb.query('uuids', params.uuids, {});
      rb.query('names', params.names, {});
      rb.query('categories', params.categories, {});
      rb.query('subCategories', params.subCategories, {});
      rb.query('types', params.types, {});
      rb.query('thirdPartyNames', params.thirdPartyNames, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('paginationToken', params.paginationToken, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TagListWithPagination>;
      })
    );
  }

  /**
   * List the tags.
   *
   * Returns a list of tags based on the defined filter criteria.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listTagsOp$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listTagsOp(params?: {

    /**
     * Sf Account Id
     */
    account_id?: AccountId;

    /**
     * Should only be passed in case of DMaas. Should be format &lt;CloudOpAccountID&gt;:&lt;TenantID&gt; Warning please passed in case of DMaas account only.
     */
    tenant_id?: TenantId;

    /**
     * Specifies Uuids to filter the tags.
     */
    uuids?: TagUuidList;

    /**
     * Specifies exact Names to filter the tags.
     */
    names?: TagNameList;

    /**
     * Specifies categories to filter the tags.
     */
    categories?: TagCategoryList;

    /**
     * Specifies sub-categories to filter the tags.
     */
    subCategories?: TagSubCategoryList;

    /**
     * Specifies types to filter the tags.
     */
    types?: TagTypeList;

    /**
     * Specifies names to search the third party tags.
     */
    thirdPartyNames?: TagThirdPartyNameList;

    /**
     * Query parameter to specify the number of results per page
     */
    pageSize?: null | number;

    /**
     * Specifies the pagination token used to get the next page
     */
    paginationToken?: PaginationToken;

  }): Observable<TagListWithPagination> {

    return this.listTagsOp$Response(params).pipe(
      map((r: StrictHttpResponse<TagListWithPagination>) => r.body as TagListWithPagination)
    );
  }

  /**
   * Path part for operation createTagOp
   */
  static readonly CreateTagOpPath = '/tags';

  /**
   * Creates a tag.
   *
   * Creates a new tag within the Helios to associate with objects or snapshots.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTagOp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTagOp$Response(params: {

    /**
     * Sf Account Id
     */
    account_id?: AccountId;

    /**
     * Should only be passed in case of DMaas. Should be format &lt;CloudOpAccountID&gt;:&lt;TenantID&gt; Warning please passed in case of DMaas account only.
     */
    tenant_id?: TenantId;
      body: Tag
  }): Observable<StrictHttpResponse<Tag>> {

    const rb = new RequestBuilder(this.rootUrl, HeliosTaggingApiService.CreateTagOpPath, 'post');
    if (params) {

      rb.header('account_id', params.account_id, {});
      rb.header('tenant_id', params.tenant_id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tag>;
      })
    );
  }

  /**
   * Creates a tag.
   *
   * Creates a new tag within the Helios to associate with objects or snapshots.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createTagOp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTagOp(params: {

    /**
     * Sf Account Id
     */
    account_id?: AccountId;

    /**
     * Should only be passed in case of DMaas. Should be format &lt;CloudOpAccountID&gt;:&lt;TenantID&gt; Warning please passed in case of DMaas account only.
     */
    tenant_id?: TenantId;
      body: Tag
  }): Observable<Tag> {

    return this.createTagOp$Response(params).pipe(
      map((r: StrictHttpResponse<Tag>) => r.body as Tag)
    );
  }

  /**
   * Path part for operation deleteTagByUuidOp
   */
  static readonly DeleteTagByUuidOpPath = '/tags/{uuid}';

  /**
   * Delete a tag by uuid.
   *
   * Delete a tag by Uuid.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTagByUuidOp()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTagByUuidOp$Response(params: {

    /**
     * Sf Account Id
     */
    account_id?: AccountId;

    /**
     * Should only be passed in case of DMaas. Should be format &lt;CloudOpAccountID&gt;:&lt;TenantID&gt; Warning please passed in case of DMaas account only.
     */
    tenant_id?: TenantId;

    /**
     * Specifies the uuid of the tag.
     */
    uuid: TagUuid;

  }): Observable<StrictHttpResponse<TagsDeletionResp>> {

    const rb = new RequestBuilder(this.rootUrl, HeliosTaggingApiService.DeleteTagByUuidOpPath, 'delete');
    if (params) {

      rb.header('account_id', params.account_id, {});
      rb.header('tenant_id', params.tenant_id, {});
      rb.path('uuid', params.uuid, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TagsDeletionResp>;
      })
    );
  }

  /**
   * Delete a tag by uuid.
   *
   * Delete a tag by Uuid.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTagByUuidOp$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTagByUuidOp(params: {

    /**
     * Sf Account Id
     */
    account_id?: AccountId;

    /**
     * Should only be passed in case of DMaas. Should be format &lt;CloudOpAccountID&gt;:&lt;TenantID&gt; Warning please passed in case of DMaas account only.
     */
    tenant_id?: TenantId;

    /**
     * Specifies the uuid of the tag.
     */
    uuid: TagUuid;

  }): Observable<TagsDeletionResp> {

    return this.deleteTagByUuidOp$Response(params).pipe(
      map((r: StrictHttpResponse<TagsDeletionResp>) => r.body as TagsDeletionResp)
    );
  }

  /**
   * Path part for operation updateTagByUuidOp
   */
  static readonly UpdateTagByUuidOpPath = '/tags/{uuid}';

  /**
   * Update a tag by uuid.
   *
   * Update a tag by uuid.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTagByUuidOp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTagByUuidOp$Response(params: {

    /**
     * Sf Account Id
     */
    account_id?: AccountId;

    /**
     * Should only be passed in case of DMaas. Should be format &lt;CloudOpAccountID&gt;:&lt;TenantID&gt; Warning please passed in case of DMaas account only.
     */
    tenant_id?: TenantId;

    /**
     * Specifies the Uuid of the tag.
     */
    uuid: TagUuid;
      body: UpdateTagParams
  }): Observable<StrictHttpResponse<Tag>> {

    const rb = new RequestBuilder(this.rootUrl, HeliosTaggingApiService.UpdateTagByUuidOpPath, 'patch');
    if (params) {

      rb.header('account_id', params.account_id, {});
      rb.header('tenant_id', params.tenant_id, {});
      rb.path('uuid', params.uuid, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tag>;
      })
    );
  }

  /**
   * Update a tag by uuid.
   *
   * Update a tag by uuid.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateTagByUuidOp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTagByUuidOp(params: {

    /**
     * Sf Account Id
     */
    account_id?: AccountId;

    /**
     * Should only be passed in case of DMaas. Should be format &lt;CloudOpAccountID&gt;:&lt;TenantID&gt; Warning please passed in case of DMaas account only.
     */
    tenant_id?: TenantId;

    /**
     * Specifies the Uuid of the tag.
     */
    uuid: TagUuid;
      body: UpdateTagParams
  }): Observable<Tag> {

    return this.updateTagByUuidOp$Response(params).pipe(
      map((r: StrictHttpResponse<Tag>) => r.body as Tag)
    );
  }

  /**
   * Path part for operation tagsActionOp
   */
  static readonly TagsActionOpPath = '/tags/actions';

  /**
   * Tags actions on objects or snapshots.
   *
   * Performs tags actions (association/dissociation) on objects or snapshots. When asynchronous execution is enabled then it will return 202 response code with trackingId, otherwise returns 200.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tagsActionOp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tagsActionOp$Response(params: {

    /**
     * Sf Account Id
     */
    account_id?: AccountId;

    /**
     * Should only be passed in case of DMaas. Should be format &lt;CloudOpAccountID&gt;:&lt;TenantID&gt; Warning please passed in case of DMaas account only.
     */
    tenant_id?: TenantId;

    /**
     * Specifies to forcelly execute the sync flow for tag actions.
     */
    forceSyncFlow?: ForceSyncFlow;
      body: TagsActionParams
  }): Observable<StrictHttpResponse<TagsActionResp>> {

    const rb = new RequestBuilder(this.rootUrl, HeliosTaggingApiService.TagsActionOpPath, 'patch');
    if (params) {

      rb.header('account_id', params.account_id, {});
      rb.header('tenant_id', params.tenant_id, {});
      rb.query('forceSyncFlow', params.forceSyncFlow, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TagsActionResp>;
      })
    );
  }

  /**
   * Tags actions on objects or snapshots.
   *
   * Performs tags actions (association/dissociation) on objects or snapshots. When asynchronous execution is enabled then it will return 202 response code with trackingId, otherwise returns 200.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tagsActionOp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tagsActionOp(params: {

    /**
     * Sf Account Id
     */
    account_id?: AccountId;

    /**
     * Should only be passed in case of DMaas. Should be format &lt;CloudOpAccountID&gt;:&lt;TenantID&gt; Warning please passed in case of DMaas account only.
     */
    tenant_id?: TenantId;

    /**
     * Specifies to forcelly execute the sync flow for tag actions.
     */
    forceSyncFlow?: ForceSyncFlow;
      body: TagsActionParams
  }): Observable<TagsActionResp> {

    return this.tagsActionOp$Response(params).pipe(
      map((r: StrictHttpResponse<TagsActionResp>) => r.body as TagsActionResp)
    );
  }

  /**
   * Path part for operation tagsAsyncStatusOp
   */
  static readonly TagsAsyncStatusOpPath = '/tags/async/status';

  /**
   * Tags async request status.
   *
   * Returns the status of an async request.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tagsAsyncStatusOp()` instead.
   *
   * This method doesn't expect any request body.
   */
  tagsAsyncStatusOp$Response(params: {

    /**
     * Sf Account Id
     */
    account_id?: AccountId;

    /**
     * Should only be passed in case of DMaas. Should be format &lt;CloudOpAccountID&gt;:&lt;TenantID&gt; Warning please passed in case of DMaas account only.
     */
    tenant_id?: TenantId;

    /**
     * Specifies trackingId of an async request.
     */
    trackingId: TrackingId;

  }): Observable<StrictHttpResponse<TagsAsyncStatusResp>> {

    const rb = new RequestBuilder(this.rootUrl, HeliosTaggingApiService.TagsAsyncStatusOpPath, 'get');
    if (params) {

      rb.header('account_id', params.account_id, {});
      rb.header('tenant_id', params.tenant_id, {});
      rb.query('trackingId', params.trackingId, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TagsAsyncStatusResp>;
      })
    );
  }

  /**
   * Tags async request status.
   *
   * Returns the status of an async request.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tagsAsyncStatusOp$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tagsAsyncStatusOp(params: {

    /**
     * Sf Account Id
     */
    account_id?: AccountId;

    /**
     * Should only be passed in case of DMaas. Should be format &lt;CloudOpAccountID&gt;:&lt;TenantID&gt; Warning please passed in case of DMaas account only.
     */
    tenant_id?: TenantId;

    /**
     * Specifies trackingId of an async request.
     */
    trackingId: TrackingId;

  }): Observable<TagsAsyncStatusResp> {

    return this.tagsAsyncStatusOp$Response(params).pipe(
      map((r: StrictHttpResponse<TagsAsyncStatusResp>) => r.body as TagsAsyncStatusResp)
    );
  }

  /**
   * Path part for operation listAssociatedTagsOp
   */
  static readonly ListAssociatedTagsOpPath = '/list-associated-tags';

  /**
   * Listing the assosiated tags for objects and snapshots.
   *
   * Listing the assosiated tags based on request body.
   * For GlobalObjectIds, asocciated tags along with tags through snapshots
   * will be returned.
   * For SnapshotIds, asocciated tags will be returned.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAssociatedTagsOp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  listAssociatedTagsOp$Response(params: {

    /**
     * Sf Account Id
     */
    account_id?: AccountId;

    /**
     * Should only be passed in case of DMaas. Should be format &lt;CloudOpAccountID&gt;:&lt;TenantID&gt; Warning please passed in case of DMaas account only.
     */
    tenant_id?: TenantId;
      body: ListAssociatedTagsParams
  }): Observable<StrictHttpResponse<ListAssociatedTagsResp>> {

    const rb = new RequestBuilder(this.rootUrl, HeliosTaggingApiService.ListAssociatedTagsOpPath, 'post');
    if (params) {

      rb.header('account_id', params.account_id, {});
      rb.header('tenant_id', params.tenant_id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListAssociatedTagsResp>;
      })
    );
  }

  /**
   * Listing the assosiated tags for objects and snapshots.
   *
   * Listing the assosiated tags based on request body.
   * For GlobalObjectIds, asocciated tags along with tags through snapshots
   * will be returned.
   * For SnapshotIds, asocciated tags will be returned.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listAssociatedTagsOp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  listAssociatedTagsOp(params: {

    /**
     * Sf Account Id
     */
    account_id?: AccountId;

    /**
     * Should only be passed in case of DMaas. Should be format &lt;CloudOpAccountID&gt;:&lt;TenantID&gt; Warning please passed in case of DMaas account only.
     */
    tenant_id?: TenantId;
      body: ListAssociatedTagsParams
  }): Observable<ListAssociatedTagsResp> {

    return this.listAssociatedTagsOp$Response(params).pipe(
      map((r: StrictHttpResponse<ListAssociatedTagsResp>) => r.body as ListAssociatedTagsResp)
    );
  }

}
