// Component: This component checks connection to external network
// to determine if it is a dark/connected site

;(function (angular) {
  'use strict';

  angular
    .module('C.clusterClaim')
    .config(configFn)
    .component('checkConnectionToNetwork', {
      controller: 'checkConnectionToNetworkCtrl',
      templateUrl: 'app/cluster-connect/check-connection.html',
      bindings: {
        // tells if page is being load before cluster claim or after cluster
        // claim
        redirectedFromHelios: '<?',
      },
    })
    .controller('checkConnectionToNetworkCtrl', checkConnectionToNetworkCtrl);

  /**
   * Config Fn for this module.
   */
  function configFn($stateProvider) {
    $stateProvider
      .state('check-connection', {
        title: 'Check Connection',
        url: '^/check-connection',
        component: 'checkConnectionToNetwork',
        help: 'check_helios_connection',
        canAccess: 'true',
        params: {
          redirectedFromHelios: {
            type: 'cBool',
            value: false,
          },
        },
        resolve: {
          redirectedFromHelios: function setRedirecFromHelios($transition$) {
            return $transition$.params().redirectedFromHelios;
          },
        },
      });
  }

  function checkConnectionToNetworkCtrl(_, $state, API, $window,
    HeliosService, ClusterService, NgClusterService, SlideModalService, evalAJAX, FEATURE_FLAGS) {
    var $ctrl = this;

    _.assign($ctrl, {
      //properties
      connectionStatus: 'inProgress',
      showSkipForNowMessage:
        ClusterService.clusterInfo.licenseState.failedAttempts > 2,

      //methods
      $onInit: $onInit,
      checkConnectionToHelios: checkConnectionToHelios,
      configureProxy: configureProxy,
      redirectWhenNoConnection: redirectWhenNoConnection,
      redirectToDashboard: redirectToDashboard,
      gotoDashboard: gotoDashboard,
      setAccessPermission: setAccessPermission,
      skipLicensing: ClusterService.skipLicensing,
    })

    /**
     * Activate the controller
     *
     * @method   $onInit
     */
    function $onInit() {
      if (!$ctrl.redirectedFromHelios) {
        $ctrl.checkConnectionToHelios();
      } else {
        HeliosService.getHeliosConfiguration()
          .then(function getHeliosConfiguration (response) {
            if (response.registrationStatus === 'kRegistrationNotDone') {
              $ctrl.connectionStatus = 'failed';
              return;
            }
            $ctrl.connectionStatus = 'success';
            $ctrl.permissionValue =
              response.mcmReadOnly ? 'ViewOnly' : 'FullHeliosConnect';
          })
      }
    }

    /**
     * Function will call upgradeHeliosConfiguration to set mcmReadOnly value
     * true or false on radio button click, and will disable radio button until
     * last api all is not completed
     *
     * @method setAccessPermission
     * @param {boolean} permissionType signifies the value for mcmReadOnly true
     */
    function setAccessPermission(permissionValue) {
      var readOnly = permissionValue === 'ViewOnly' ? true : false;

      // disabling Access Permission buttons
      $ctrl.savingAccessPermission = true;

      HeliosService.updateHeliosConfiguration({
        mcmReadOnly: readOnly,
      }).catch(function handleUpdateRequestFail(response) {
        evalAJAX.errorMessage(response);
        // restoring previous value
        $ctrl.permissionValue =
          !readOnly ? 'ViewOnly' : 'FullHeliosConnect';
      }).finally(function enableButtonAgain() {
        // enabling access permission buttons
        $ctrl.savingAccessPermission = false;
      });
    }

    /**
     * Function to take user to dashboard after cluster claim is done
     *
     * @method gotoDashboard
     *
     */
    function gotoDashboard() {
      if (FEATURE_FLAGS.clusterLicensePageRevamp) {
        var failedAttempts =
          ClusterService.clusterInfo.licenseState.failedAttempts;
        const uploadFilePromise = ClusterService.uploadLicenseFile(null, false);
        const updateLicensePromise = ClusterService.updateLicenseState('claimed', failedAttempts);
        Promise.all([uploadFilePromise, updateLicensePromise])
          .then(
            redirectToDashboard,
            redirectToDashboard
          );
      } else {
        redirectToDashboard
      }
    }

    function redirectToDashboard() {
      $state.goHome();
    }

    /**
     * This function checks connection to helios endpoint and
     * redirects to salesforce if successfull connection
     *
     * @method   checkConnectionToHelios
     */
    function checkConnectionToHelios() {
      var failedAttempts;
      var redirectToSalesForceUrl;
      var inProgressState;
      HeliosService.getHeliosConfiguration()
        .then(function checkMcmConnectivity(response) {
          if (response.registrationStatus === 'kRegistrationCompleted') {
            $ctrl.connectionStatus = 'success';
            $ctrl.permissionValue =
              response.mcmReadOnly ? 'ViewOnly' : 'FullHeliosConnect';

              return;
          }
          if (response.mcmReachableWhenNotRegisterd) {
            redirectToSalesForceUrl = [
              'mcm/registerCluster',
              'redirectState=check-connection-redirect-handler'
            ].join('?');

            failedAttempts =
              ClusterService.clusterInfo.licenseState.failedAttempts ?
                ClusterService.clusterInfo.licenseState.failedAttempts + 1 : 1;

            inProgressState =
              ClusterService.clusterInfo.licenseState.state === 'kStarted' ?
                'inProgressNC' : 'inProgressOC';

            ClusterService.updateLicenseState(inProgressState, failedAttempts)
              .then(function redirectToHelios() {
                $window.location.href = API.private(redirectToSalesForceUrl);
              });
          } else {
            $ctrl.connectionStatus = 'failed';
          }
        });
    }

    /**
     * If connection to helios is not established and user cancelled
     * the check then this function will redirect to cluster management state
     *
     * @method   redirectWhenNoConnection
     */
    function redirectWhenNoConnection() {
      if (FEATURE_FLAGS.clusterLicensePageRevamp) {
        $state.go('connected-site-ng.select-mode');
      } else {
        $state.go('connected-site', { registrationType: 'on-cluster' });
      }
    }

    /**
     * This function opens the modal for configure proxy and
     * sets the config of the modal
     *
     * @method   configureProxy
     */
    function configureProxy() {
      var modalConfig = {
        size: 'md',
        autoHeight: true,
        resolve: {
          actionButtonKey: false,
          closeButtonKey: false,
          innerComponent: 'proxyConfigModal',
        },
      };

      return SlideModalService.newModal(modalConfig);
    }
  }
})(angular);
