import { ChangeDetectionStrategy, Component, HostBinding, Inject, ViewEncapsulation } from '@angular/core';
import { NODE_INSTANCE, TOPOLOGY_SELECTION } from '@cohesity/helix-yfiles';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { INode, IRectangle } from 'yfiles';

import { NodeActivityStats, RpaasTopologyGraphNode } from '../../../models';
import { RpaasDashboardService } from '../../../services';


/**
 * This is a simple graph node renderer
 */
@Component({
  templateUrl: './rpaas-topology-node.component.svg',
  styleUrls: ['./rpaas-topology-node.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RpaasTopologyNodeComponent {
  /**
   * Use a host binding to add a style to this component, since style encapsulation is off, it is dynamically created
   * and it has no selector.
   */
  @HostBinding('class.rpaas-topology-node') componentClassName = true;

  /**
   * Gets the graph node item that was set on the topology graph compnent
   */
  get item(): RpaasTopologyGraphNode {
    return this.node.tag;
  }

  /**
   * Reads the yfiles layout object, this is a rectangle with the size of the node
   */
  get layout(): IRectangle {
    return this.node.layout;
  }

  /**
   * Gets the radius for the node - this is 1/2 of the layout width
   */
  get radius(): number {
    return this.node.layout.width / 2;
  }

  /**
   * Gets the icon shape to render inside of the node. Any svg icon registered with material icon registry can be used
   * here
   */
  get shape(): string {
    if (this.item.type === 'vault') {
      return 'helix:vault';
    }
    switch (this.item.style) {
      case 'success':
        return 'helix:cluster-success';
      case 'warning':
        return 'helix:cluster-warning';
      case 'error':
      case 'disconnected':
        return 'helix:cluster-error';
      case 'inProgress':
        return 'helix:cluster-running';
      case 'noActivity':
        return 'helix:cluster';
    }
  }

  /**
   * Gets the date range end time
   */
  get endTime(): number {
    return this.item.dateRange?.[1];
  }

  /**
   * Gets the date range start time
   */
  get startTime(): number {
    return this.item.dateRange?.[0];
  }

  /**
   * This reads the selection value and returns true if the current node is selected in the graph.
   */
  isSelected$: Observable<boolean> = this.nodeSelection$.pipe(
    map(selection => selection === this.node),
    distinctUntilChanged()
  );

  /**
   * Opens the anchor link. For some reason, yfiles seems to prevent navigation when you click directly on the href.
   * It works fine with uiSref
   *
   * @param anchor The clicked anchor tag
   */
  openLink(anchor: HTMLAnchorElement) {
    window.open(anchor.href, anchor.target);
  }

  constructor(
    /**
     * This is the yFiles node item that we are currently rendering. It includes layout and style information as well
     * as a tag property which returns the data object the node was created from.
     */
    @Inject(NODE_INSTANCE) private node: INode,

    /**
     * This is controlled by the topology-graph component and is updated whenever the selected node changes.
     */
    @Inject(TOPOLOGY_SELECTION) private nodeSelection$: Observable<INode>,
    readonly dashboardService: RpaasDashboardService,
  ) {}

  /**
   * Gets the activity stats to show for this node
   *
   * @returns Activity stats
   */
  getStats(): NodeActivityStats[] {
    return this.dashboardService.getStats(this.item.activities);
  }
}
