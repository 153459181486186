import { ProtectionSourceNode } from '@cohesity/api/v1';
import { DataTreeFilter, DataTreeTransformer } from '@cohesity/helix';
import { Office365LeafNodeType } from '@cohesity/iris-shared-constants';
import { SourceTreeFilters, ViewFilterOption } from '@cohesity/iris-source-tree';
import {
  Office365SourceDetailsService
} from 'src/app/modules/sources/office365/services/office365-source-details.service';
import {
  Office365BackupType,
  Office365ContainerNodeType,
  Office365HierarchyType,
  OFFICE365_GROUPS
} from 'src/app/shared/constants';
import { Office365UtilityService } from 'src/app/shared/office365/services/office365-utility.service';
import { Office365SourceDataNode } from './office365-source-data-node';

/**
 * Specifies the filters for viewing the Office365 Hierarchy.
 * Office365 currently supports default view for all workloads and now has an
 * additional view for only Mailbox & Onedrives which is very similar to tags
 * and is known as Security Groups/
 */
export class Office365ViewFilters<T extends Office365SourceDataNode> {
  /**
   * Specifies the default filter view applicable to all workloads within M365.
   */
  defaultViewFilter: ViewFilterOption;

  /**
   * Specifies the Security Group filter view applicable only for Mailbox &
   * OneDrive workload.
   */
  securityGroupFilter: ViewFilterOption;

  /**
   * Specifies the workload for Office365.
   */
  workload: Office365BackupType;

  constructor(
    private filters: SourceTreeFilters<T>,
    private dataTransformer: DataTreeTransformer<ProtectionSourceNode>,
    private office365SourceDetailsService: Office365SourceDetailsService,
    private office365UtilityService: Office365UtilityService,
  ) {
    this.workload = this.office365SourceDetailsService.getWorkload();

    this.defaultViewFilter = {
      id: Office365HierarchyType.Default,
      tooltip: 'sourceTreePub.tooltips.defaultView',
      filter: this.handleDefaultView,
      icon: 'helix:hierarchy-flat',
    };

    this.securityGroupFilter = {
      id: Office365HierarchyType.SecurityGroup,
      tooltip: 'sourceTreePub.tooltips.securityGroups',
      filter: this.handleSecurityGroupView,
      icon: 'helix:hierarchy-physical',
    };
    this.setViewFilters();
  }

  /**
   * Sets the filters for M365 entity hierarchy.
   */
  setViewFilters() {
    this.workload = this.office365SourceDetailsService.getWorkload();
    const isSecurityGroupsViewSupportedForCurrentWorkload =
      this.office365SourceDetailsService.isSecurityGroupsViewSupportedForCurrentWorkload();

    this.filters.setViewFilters([
      isSecurityGroupsViewSupportedForCurrentWorkload && this.securityGroupFilter,
      this.defaultViewFilter
    ].filter(Boolean));
  }

  /**
   * TODO(tauseef): Post perf testing with 300k look at the benefit of using
   * DataTreeFilterUtils.searchFilter(...). The function will have to be
   * modified to
   *
   * Filters away the root node and only keeps the container applicable within
   * the view.
   *
   * @param nodes Specifies the list of nodes
   * @returns Returns the DataTreeFilter nodes.
   */
  handleDefaultView: DataTreeFilter<any> = (nodes: Office365SourceDataNode[]) => {
    let containerNode: Office365SourceDataNode;
    const filteredNodes = [];

    // Determine container, internal & leaf nodes within a single iteration on
    // all nodes instead of Utility functions. This is needed to filter away
    // the root node.
    nodes.forEach(node => {
      this.workload = this.office365SourceDetailsService.getWorkload();
      // Handle container nodes.
      if (OFFICE365_GROUPS.office365EntityContainers.includes(node.type)) {
        if ((this.workload === Office365BackupType.kMailbox &&
            node.type === Office365ContainerNodeType.kUsers) ||
          (this.workload === Office365BackupType.kMailboxCSM &&
            node.type === Office365ContainerNodeType.kUsers) ||
          (this.workload === Office365BackupType.kOneDrive &&
            node.type === Office365ContainerNodeType.kUsers) ||
          (this.workload === Office365BackupType.kOneDriveCSM &&
            node.type === Office365ContainerNodeType.kUsers) ||
          (this.workload === Office365BackupType.kSharePoint &&
            node.type === Office365ContainerNodeType.kSites) ||
          (this.workload === Office365BackupType.kSharePointCSM &&
            node.type === Office365ContainerNodeType.kSites) ||
          (this.workload === Office365BackupType.kPublicFolders &&
            node.type === Office365ContainerNodeType.kPublicFolders) ||
          (this.workload === Office365BackupType.kTeams &&
            node.type === Office365ContainerNodeType.kTeams) ||
          (this.workload === Office365BackupType.kGroups &&
            node.type === Office365ContainerNodeType.kGroups)) {
            containerNode = node;
            filteredNodes.push(this.dataTransformer.transformData({
                ...containerNode.data,
                nodes: containerNode.data.nodes
              }, 0));
          }
        }

      // Handle internal or leaf entities. Since the original is sorted and
      // all children of a container are guaranteed to be present after the
      // container, push all internal/leaf nodes within filteredNodes.
      if (containerNode && this.office365UtilityService.getLeafNodeTypeForContainerType(
        containerNode.type as Office365ContainerNodeType) === node.type) {
        filteredNodes.push(this.dataTransformer.transformData(node.data, node.level - 1));
      }
    });

    return filteredNodes;
  };

  /**
   * TODO(tauseef): Post perf testing with 300k look at the benefit of using
   * DataTreeFilterUtils.searchFilter(...).
   *
   * Filters away the root node and only keeps the container applicable within
   * the view. This is only applicable for Mailbox & OneDrive workloads.
   *
   * @param nodes Specifies the list of nodes
   * @returns Returns the DataTreeFilter nodes.
   */
  handleSecurityGroupView: DataTreeFilter<any> = (nodes: Office365SourceDataNode[]) => {
    const filteredNodes = [];
    nodes.forEach(node => {
      if (Office365LeafNodeType.kGroup === node.type as any) {
        filteredNodes.push(this.dataTransformer.transformData(node.data, 0));
      }
    });
    return filteredNodes;
  };
}
