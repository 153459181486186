<cog-settings-list-item
  [isReadOnly]="true"
  [label]="'readerSlashWriter' | translate">
  <cog-settings-list-item-value-display>
    {{'writer' | translate}}: {{writerCount}}, {{'reader' | translate}}: {{readerCount}}
    <div class="margin-top c-message-inline info">
      <div class="icon-message info margin-top margin-bottom">
        <div>{{ 'readerWriterNote'| translate }}</div>
      </div>
    </div>
  </cog-settings-list-item-value-display>
</cog-settings-list-item>
