// Component: Recover & Clone Common Snapshot Selector

// TODO(spencer): P2 (because this currently works fine) Go through this and
// make it generic (typedDocument, typedEntity, etc.)
;(function(angular, undefined) {
  'use strict';
  angular
    .module('C.restoreCommon', [])
    .controller('commonRestoreSnapshotModalController',
      commonRestoreSnapshotModalControllerFn);

  function commonRestoreSnapshotModalControllerFn($rootScope, $scope, $state,
    $filter, ExternalTargetService, SourceService, evalAJAX, $q, RestoreService,
    SearchService, SearchServiceFormatter, task, entity, JobRunsService) {

    var allowedTargetTypes = [0, 1, 3, 4];
    var allowedTargetTypeIcons = {
      1: 'local',
      3: 'cloud',
      4: 'tape',
    };
    var typeDoc = SearchService.getTypedDocument(entity);

    angular.extend($scope, {
      // General Scope Vars
      entity: entity,
      externalTargetsMap: {},
      isBulkRetrievalSupported: false,
      targetFilter: 0,
      typeDoc: typeDoc,
      typeEntity: SourceService.getTypedEntity(typeDoc),

      // Text Strings
      text: $rootScope.text.protectionRecoveryCommonSnapshotSelectorCommonSnapshotSelectorText,

      // Scope Methods
      filterTargetType: filterTargetType,
      getArchiveTargetTooltip: ExternalTargetService.getTargetName,
      getTargetFilterOptions: getTargetFilterOptions,
      saveSnapshotSelection: saveSnapshotSelection,
      setArchiveTarget: setArchiveTarget,
      setSnapshot: setSnapshot,
      updateTargetFilter: updateTargetFilter,
    });

    /**
     * Initialize all the things!
     *
     * @method   activate
     */
    function activate() {
      var snapshot;
      var archiveTarget;

      // Get usable (restorable) versions
      typeDoc.versions = RestoreService.getRestorableVersions(typeDoc.versions);

      if (entity._volumeType === 2) {
        RestoreService.getDataProtectSnapshotVersions(
          typeDoc.versions,
          entity._jobId,
          entity._protectionSourceId
        ).then((versions) => (typeDoc.versions = versions));
      }

      // Set the snapshot and archivalTarget from the entity coming in if
      // we've previously selected either of them.
      snapshot = findEqualSnapshot(entity._snapshot);
      archiveTarget = findEqualArchivalTarget(snapshot, entity._archiveTarget);
      setSnapshot(snapshot, archiveTarget);

      $scope.targetFilterOptions = getTargetFilterOptions();
      updateTargetFilter();

      if ($rootScope.user.privs.CLUSTER_EXTERNAL_TARGET_VIEW) {
        getExternalTargetsList();
      }
    }

    /**
     * Gets the archive target tooltip string.
     *
     * @method     getArchiveTargetTooltip
     * @param      {object}  archiveTarget  The archive target
     * @return     {string}  The archive target tooltip.
     */
    function getArchiveTargetTooltip(archiveTarget) {
      var tooltip;

      switch (true) {
        // Show the vault name for tape & cloud targets
        case (~[3, 4].indexOf(archiveTarget.target.type)):
          tooltip = $scope.externalTargetsMap[archiveTarget.target.id] &&
            $scope.externalTargetsMap[archiveTarget.target.id].name;
          break;

        // Just show the vault type for anything else
        default:
          tooltip = $scope.text.tooltips[archiveTarget.target.type];
      }

      // In case the specific vault wasn't identified, fall back on the
      // vault type
      return tooltip || $scope.text.tooltips[archiveTarget.target.type];
    }

    /**
     * Get the list of external targets and create a map of them by id.
     *
     * @method     getExternalTargetsList
     */
    function getExternalTargetsList() {
      ExternalTargetService.getTargets()
        .then(function getTargetsSuccessFn(targets) {
          $scope.externalTargetsMap = targets.reduce(
            function targetsMapperFn(_targets, target) {
              _targets[target.id] = target;

              return _targets;
            },

            {}
          );
        });
    }

    /**
     * Find an equal snapshot in the list since the passed in versions are
     * copies and not references to the same objects.
     *
     * @method     findEqualSnapshot
     * @param      {object}  snapshot  Out-modal Entity snapshot
     * @return     {object}  Reference to in-modal equal snapshot
     */
    function findEqualSnapshot(snapshot) {
      var equalSnapshot;

      if (snapshot) {
        typeDoc.versions.some(function findSnapshotFn(snap) {
          if (snap &&
            (snapshot.instanceId.jobInstanceId === snap.instanceId.jobInstanceId)) {
            equalSnapshot = snap;
          }

          return equalSnapshot;
        });
      }

      return equalSnapshot;
    }

    /**
     * Find an equal archivalTarget on the given snapshot
     *
     * @method     findEqualArchivalTarget
     * @param      {object}            snapshot        The snapshot to
     *                                                 search
     * @param      {object}            archivalTarget  Out-modal
     *                                                 archivalTarget to
     *                                                 find
     * @return     {object|undefined}  Reference to in-modal archivalTarget
     *                                 that matches, or undefined if no
     *                                 match is found.
     */
    function findEqualArchivalTarget(snapshot, archivalTarget) {
      var equalTarget;

      if (archivalTarget && snapshot && snapshot.replicaInfo &&
        snapshot.replicaInfo.replicaVec) {
        snapshot.replicaInfo.replicaVec
            .some(function targetFinderFn(vec) {
              if (ExternalTargetService.isSameTarget(archivalTarget.target, vec.target)) {
                equalTarget = vec;
              }

              // If a match was found, this will be true and this loop terminates.
              return !!equalTarget;
            });
      }

      return equalTarget;
    }

    /**
     * cMultiselect update handler to update the filter on the list of
     * snapshots
     *
     * @method     updateTargetFilter
     * @param      {integer}  selection  Target type to apply. 0 clears the
     *                                   filter
     */
    function updateTargetFilter(selection) {
      $scope.targetFilter = (selection) ? selection.value : 0;
      $scope.versions = (!$scope.targetFilter) ?

        // No filter. Show all versions
        typeDoc.versions :

        // Filter selected, lets apply it
        typeDoc.versions.filter(filterTargetType);
    }

    /**
     * Generates a list of cMultiselect compatible options from a list of
     * allowed target types. See `allowedTargetTypes` above for the allowed
     * types.
     *
     * @method     getTargetFilterOptions
     * @return     {array}  List of cMultiselect options
     */
    function getTargetFilterOptions() {
      return allowedTargetTypes.map(function optionMapperFn(type) {
        return {
          selected: type === $scope.targetFilter,
          icon: allowedTargetTypeIcons[type] || undefined,
          disabled: false,
          name: $scope.text.tooltips[type],
          value: type,
        };
      });
    }

    /**
     * JS filter Function to filter list of snapshots by selected target
     * Type.
     *
     * @method     filterTargetType
     * @param      {object}   val     The Snapshot object
     * @param      {integer}  ii      The Snapshot's index in the list
     * @param      {array}    list    The list to be filtered
     * @return     {boolean}  True keeps the val row. False filters it out.
     */
    function filterTargetType(val, ii, list) {
      return !$scope.targetFilter ||
        snapshotHasTargetType(val, $scope.targetFilter);
    }

    /**
     * Determines if a given snapshot has any targets of the given type.
     *
     * @method     snapshotHasTargetType
     * @param      {object}   snapshot    The snapshot object
     * @param      {integer}  targetType  The target type: [1,3,4]
     * @return     {boolean}  True if found. False otherwise.
     */
    function snapshotHasTargetType(snapshot, targetType) {
      return !snapshot || !targetType || snapshot.replicaInfo.replicaVec
        .some(function findTargetTypeFn(target) {
          switch (targetType) {
            case 3:

              // cloud
              return target.target.archivalTarget && target.target.archivalTarget.type === 0;

            case 4:

              // tape
              return target.target.archivalTarget && target.target.archivalTarget.type === 1;

            default:
              return targetType === target.target.type;
          }

        });
    }

    /**
     * Finds an archiveTarget by it's ID for the selected entity snapshot.
     *
     * @method     findArchiveTargetById
     * @param      {string=}           archiveId  The archiveUid.objectId
     * @return     {object|undefined}  The found object, or undefined.
     */
    function findArchiveTargetById(archiveId) {
      var out;

      if (archiveId) {
        $scope._snapshot.replicaInfo.replicaVec
          .some(function findTargetFn(target) {
            if (target.archiveUid &&
              target.archiveUid.objectId === archiveId) {
              out = target;

              return true;
            }
          });
      }

      return out;
    }

    /**
     * Sets the archive target.
     *
     * @method     setArchiveTarget
     * @param      {object=}           archiveTarget  The archive target. If
     *                                                empty, defaults to
     *                                                first available on
     *                                                currently selected
     *                                                version.
     * @return     {object|undefiend}  The set archiveTarget
     */
    function setArchiveTarget(archiveTarget) {
      switch (true) {
        // Explicitly set the target
        case (angular.isDefined(archiveTarget)):
          $scope._archiveTarget = archiveTarget;
          break;

        // archvieTarget set on the entity, lets use it
        case ($scope._snapshot && entity._archiveTarget):
          $scope._archiveTarget = entity._archiveTarget;
          break;

        // Finally, use the first in the list for the selected snapshot
        default:
          $scope._archiveTarget = $scope._snapshot.replicaInfo &&
                      $scope._snapshot.replicaInfo.replicaVec[0];
      }

      // This is used in public api instead of archieve target
      $scope._archivalTarget = SearchServiceFormatter
        .getArchiveTargetPub($scope._archiveTarget);

      return $scope._archiveTarget;
    }

    /**
     * Sets the snapshot.
     *
     * @method     setSnapshot
     * @param      {object=}           snapshot       The recover point
     *                                                selected. if empty,
     *                                                defaults to the index
     *                                                set on the `entity`.
     * @param      {object=}           archiveTarget  Optional archiveTarget
     *                                                to set too.
     */
    function setSnapshot(snapshot, archiveTarget) {
      if (snapshot) {
        $scope._snapshot = snapshot;
        setArchiveTarget(archiveTarget);

        // For public api contract
        $scope._pubSnapshot =
          SearchServiceFormatter.getPublicSnapshot(snapshot);

          if (archiveTarget) {
            ExternalTargetService.isBulkRetrievalSupported(
              _.get(archiveTarget, 'target.archivalTarget.vaultId'))
            .then(function supportCallSuccess(isSupported) {
              $scope.isBulkRetrievalSupported = isSupported;
            });
          }
      }
    }

    /**
     * Prepare the modal output and close it out.
     *
     * @method     saveSnapshotSelection
     */
    function saveSnapshotSelection() {
      var result = {
        snapshot: $scope._snapshot,

        // From SNAPSHOT_TARGET_TYPE_STRINGS, we are testing if the
        // kArchival subtype is either:
        //   0: 'Cloud Archive',
        //   1: 'Tape Archive'
        archiveTarget: ($scope._archiveTarget &&
          2 < $scope._archiveTarget.target.type) ?
          $scope._archiveTarget :
          undefined,

        // For public api contract
        pubSnapshot: $scope._pubSnapshot,
        archivalTarget: $scope._archivalTarget,
      };

      /* If the snapshot is archive and archive is a tape, we need to also
      return tapeListParams. */
      if (result.archiveTarget &&
      result.archiveTarget.target.archivalTarget &&
      result.archiveTarget.target.archivalTarget.type === 1) {

        result.tapeListParams = angular.copy($scope._archiveTarget.archiveUid);

        // Duplicating objectId prop to qstarArchiveJobId prop for API call
        result.tapeListParams.qstarArchiveJobId = result.tapeListParams.objectId;

        // We are not using objectId anymore so we delete it.
        delete result.tapeListParams.objectId;
      }

      $scope.$close(result);
    }

    // Initialize!
    activate();
  }

})(angular);
