<h2 mat-dialog-title>
  <button mat-icon-button mat-dialog-close type="button" cogDataId="close-btn">
    <cog-icon shape="close"></cog-icon>
  </button>
</h2>
<mat-dialog-content>
  <coh-objects-select-tree #sourceTree
    cogDataId="tree-dialog"
    [selection]="{ sourceIds: data.selectedIds }"
    [environment]="getSourceEnvironment(data.protectionGroup.environment)"
    [job]="data.job"
    [data]="data.sourceNodes"
    [run]="data.protectionRun">
  </coh-objects-select-tree>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button
    color="primary"
    cogDataId="continue"
    [disabled]="getSelectedSourceIds(sourceTree?.dataTreeSelection?.currentSelection?.selected)?.length === 0"
    [mat-dialog-close]="getSelectedSourceIds(sourceTree?.dataTreeSelection?.currentSelection?.selected)">
    {{ 'continue' | translate }}
  </button>
</mat-dialog-actions>
