import { Injectable } from '@angular/core';
import { BannerConfig, BannerStatus, BANNER_PRIORITY, WindowRef } from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

import {
  flagEnabled,
  getMinSubscriptionDaysRemaining,
  IrisContext,
  IrisContextService,
  isDmsFreeTrialUser,
  isDmsScope
} from '../../../iris-context';
import { BaseBannerConfig } from '../base-banner.config';

@Injectable({
  providedIn: 'root',
})
export class DmsSubscriptionBannerConfig extends BaseBannerConfig {
  /**
   * Unique banner representation
   */
  id = 'dms-subscription-banner';

  /**
   * provides banner config as observable
   */
  bannerInfo$ = this.irisCtx.irisContext$.pipe(
    map(ctx => ([{
      id: this.id,
      priority: BANNER_PRIORITY.HIGH,
      isVisible: this.allowBanner(ctx),
      status: this.getStatus(ctx),
      allowClose: this.allowClose(ctx),
      text: this.getText(ctx),
      actionText: this.translateService.instant('freeTrialBanner.subscriptionText'),
      actionCallback: () => {
        this.windowRef.nativeWindow.open('mailto:support@cohesity.com');
      },
    } as BannerConfig]))
  );

  constructor(
    private irisCtx: IrisContextService,
    private translateService: TranslateService,
    private windowRef: WindowRef
  ) {
    super();
  }

  /**
   * Decides the banner status based on context
   *
   * @param ctx iris context
   * @returns banner status
   */
  getStatus(ctx: IrisContext): BannerStatus {
    if (getMinSubscriptionDaysRemaining(ctx) < 1) {
      return 'critical';
    }

    return 'warn';
  }

  /**
   * Gets the translated text based on context
   *
   * @param ctx iris context
   * @returns translated text
   */
  getText(ctx: IrisContext): string {
    const minDays = getMinSubscriptionDaysRemaining(ctx);
    const translateKey = minDays < 1 ? 'dataProtect.expired' : 'dataProtect.active';
    return this.translateService.instant(translateKey, { n: minDays });
  }

  /**
   * Make the banner persistent if the subscription is expired.
   *
   * @param ctx iris context
   * @returns true/false based on context
   */
  allowClose(ctx: IrisContext): boolean {
    return getMinSubscriptionDaysRemaining(ctx) > 0;
  }

  /**
   * Allow banner based dms context
   *
   * @param ctx iris context
   * @returns true/false based on context
   */
  allowBanner(ctx: IrisContext): boolean {
    return (
      !flagEnabled(ctx, 'bannerNotificationCrmService') &&
      this.isLoggedIn(ctx) &&
      flagEnabled(ctx, 'dmsSubscriptionBanner') &&
      isDmsScope(ctx) &&
      !isDmsFreeTrialUser(ctx) &&
      getMinSubscriptionDaysRemaining(ctx) <= 45
    );
  }
}
