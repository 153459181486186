<h1 mat-dialog-title>
  <button *ngIf="dialogParams.showBackArrow"
    cogDataId="go-back-button"
    mat-icon-button
    (click)="closeDialog()">
    <cog-icon shape="arrow_back"></cog-icon>
  </button>
  {{ (dialogParams.isEditMode ? 'editNisProvider' : 'addNisProvider') | translate }}
</h1>

<form cogDataId="modify-nis-form"
  [formGroup]="formGroup"
  (ngSubmit)="modifyNIS(dialogParams.isEditMode)">
  <mat-dialog-content class="margin-bottom">
    <div class="row">
      <!-- domain name field -->
      <mat-form-field class="col-xs-12">
        <input cogDataId="nis-domain-name-input"
          matInput
          placeholder="{{'nisDomainName' | translate}}"
          [formControlName]="formControlNames.nisDomainName">
        <mat-error cogDataId="domain-name-invalid" *ngIf="formGroupControls.nisDomainName.errors?.required">
          {{'errors.required' | translate}}
        </mat-error>
      </mat-form-field>

      <!-- master server field -->
      <mat-form-field class="col-xs-12">
        <input cogDataId="nis-master-server-input"
          [inheritDataId]="true"
          matInput
          placeholder="{{'nisMasterServer' | translate}}"
          [formControlName]="formControlNames.nisMasterServer">
        <mat-error cogDataId="master-server-invalid" *ngIf="formGroupControls.nisMasterServer.errors?.required">
          {{'errors.required' | translate}}
        </mat-error>
      </mat-form-field>

      <!-- slave server field -->
      <mat-form-field class="col-xs-12">
        <mat-chip-list #slaveServerList>
          <mat-chip *ngFor="let server of formGroupControls.nisSlaveServers.value; let index = index"
            [selectable]="true"
            (removed)="removeSlaveServer(index)">
            {{server}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
        <input cogDataId="slave-server-input"
          [inheritDataId]="true"
          class="slave-domain-input"
          placeholder="{{'nisSlaveServersOptional' | translate}}"
          [matChipInputFor]="slaveServerList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="addSlaveServer($event)">
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button
      cogCancelButton
      cogDataId="cancel-nis"
      type="button"
      class="margin-right"
      [disabled]="saving"
      (click)="closeDialog()">
      {{ 'cancel' | translate }}
    </button>
    <button mat-flat-button
      cogSubmitButton
      cogDataId="modify-nis"
      type="submit"
      color="primary"
      loading="saving"
      [disabled]="saving || formGroup.invalid">
      {{ (dialogParams.isEditMode ? 'save' : 'add') | translate }}
    </button>
  </mat-dialog-actions>
</form>
