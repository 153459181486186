// Controller: schduled-emails

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.scheduledEmails',
      ['C.scheduledEmailsUtil', 'C.reportsControlsConstants'])
    .controller('scheduledEmailsController', scheduledEmailsControllerFn);

  function scheduledEmailsControllerFn($q, ReportsService,
    ScheduledEmailsUtil, evalAJAX, cMessage, cReportsControlsUtil, ReportsUtil,
    ActiveDirectoryService) {

    var scheduledEmailCtrl = this;

    scheduledEmailCtrl.schedules = [];
    scheduledEmailCtrl.schedulesCopy = [];
    scheduledEmailCtrl.REPORT_TYPES =
      cReportsControlsUtil.getReportsSelectorOptions();
    scheduledEmailCtrl.onEdit = onEdit;
    scheduledEmailCtrl.onDelete = onDelete;
    scheduledEmailCtrl.filterSchedules = filterSchedules;

    /**
     * activate function
     */
    function activate() {
      getSchedules();
    }

    /**
     * get all report schedules
     */
    function getSchedules() {
      var params = {
        _includeTenantInfo: true,

        // Get the details for each schedule.
        _includeJobInfo: true,
        _includeSourceInfo: true,
        _includeObjectInfo: true,
        _includePrincipalInfo: true,
        _includeViewBoxInfo: true,
        _includeVaultInfo: true,
      };

      scheduledEmailCtrl.dataLoading = true;
      scheduledEmailCtrl.schedules.length = 0;

      ReportsService.getReportSchedules(params).then(
        function getSchedulesSuccess(data) {
          scheduledEmailCtrl.schedules = data;
          scheduledEmailCtrl.schedulesCopy = angular.copy(data);

          getFiltersData();
        }, evalAJAX.errorMessage)
      .finally(function getSchedulesFinally() {
        scheduledEmailCtrl.dataLoading = false;
      });
    }

    /**
     * filters the schedulesCopy array to return reports of a specific type.
     * the schedulesCopy array is used because its the source of truth.
     *
     * @param    {string}   reportType    string indicating the report selected
     *                                    ex. kBackupSummaryReport
     */
    function filterSchedules(reportType) {
      scheduledEmailCtrl.schedules = scheduledEmailCtrl.schedulesCopy.filter(
        function filterSchedules(schedule) {
          return reportType ===
            schedule.scheduleJobParameters.reportJobParameter.reports[0].type;
      });
    }

    /**
     * calls util methods to parse filter params from all reports so
     * we can display human readable filters instead of just Id's
     */
    function getFiltersData() {
      var filterIds =
        ScheduledEmailsUtil.getFilterIds(scheduledEmailCtrl.schedules);

      var promiseObject =
        ScheduledEmailsUtil.buildFiltersPromiseObject(filterIds);

      $q.all(promiseObject)
        .catch(evalAJAX.errorMessage)
        .finally(function getFiltersDataFinally() {
          scheduledEmailCtrl.filtersDataReady = true;
        });
    }

    /**
     * Handles report-specific needs and gets the report dataset.
     *
     * @param      {Object}    sidebarConfig   The sidebar configuration
     * @param      {Object}    reportFilters   The report filters
     * @param      {Function}  clearSpinnerFn  The clear-spinner function
     * @return     {Object}    $q promise
     */
    function getReportDataset(sidebarConfig, reportFilters, clearSpinnerFn) {
      // Intercept the `kUserQuotasReport` and seed the dataset pre-requisites.
      if (reportFilters.sid &&
        reportFilters.reportType === 'kUserQuotasReport') {
        sidebarConfig.datasetPromiseObject.initialPrincipals =
          ReportsUtil.getPrincipals(reportFilters.sid);
      }

      return $q.all(sidebarConfig.datasetPromiseObject).then(
        function getDatasetSuccess(dataset) {
          // Intercept the kUserQuotasReport and apply prerequisites
          if (reportFilters.reportType === 'kUserQuotasReport') {
            dataset.principalsByDomain = dataset.principalsByDomain || {};
            dataset.initialPrincipals = dataset.initialPrincipals || [];

            if (dataset.initialPrincipals[0]) {
              // Add principals for known SIDs.
              ActiveDirectoryService.addPrincipalsToHash(
                dataset.initialPrincipals, dataset.principalsByDomain);
            }

            // Set default state of user toggle.
            if (reportFilters.unixUid) {
              sidebarConfig.showUnixInput = reportFilters.unixUid > -1;
            }
          }

          return dataset;
        }
      ).finally(clearSpinnerFn);
    }

    /**
     * when editing a report we update our shared filters object, so the
     * email-scheduler modal can display the correct data
     *
     * @param      {Object}  filters         object containing filters for the
     *                                       email scheduler and sidebar
     *                                       component
     * @param      {Object}  clearSpinnerFn  The clear spinner function
     * @return     {Object}  $uibModal instance
     */
    function onEdit(id, filters, clearSpinnerFn) {
      var deferred = $q.defer();
      var sidebarConfig = ReportsUtil.getReportConfig(filters.reportType)();
      var reportFilters = cReportsControlsUtil.getDefaultFilters(filters);
      var dataset =
        getReportDataset(sidebarConfig, reportFilters, clearSpinnerFn);

      return ReportsUtil.openEmailScheduler(
        reportFilters,
        sidebarConfig,
        dataset,

        // launch edit mode.
        true
      ).then(function editReportSuccess() {
        getSchedules();
      });
    }

    /**
     * will delete a report
     *
     * @param    {Number}    id    id of the report we want to delete
     */
    function onDelete(id) {
      return ReportsService.deleteReportSchedule(id).then(
        function deleteSchedulesSuccess(data) {
          // schedule successfully deleted
          cMessage.success({
            textKey: 'reports.scheduleDeleted',
            timeout: 4000,
          });

          getSchedules();
        }, evalAJAX.errorMessage
      );
    }

    activate();
  }
})(angular);
