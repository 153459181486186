// Module: Cloud Edition Delete Page

;(function(angular) {
  'use strict';

  angular.module('C.externalTargets')
    .component('unregisterTargetModal', {
      bindings: {
        /**
         * externalTarget which is being unregistered
         *
         * @type  {Object}
         */
        target: '<',
      },
      require: {
        cModalHoc: '^^?cModalHoc',
      },
      controller: 'unregisterTargetModalCtrl',
      templateUrl: 'app/platform/external/unregister-target-modal.html',
    })
    .controller('unregisterTargetModalCtrl', unregisterTargetModalCtrlFn);

  /**
   * @ngdoc component
   * @name C.externalTargets:unregisterTargetModal
   * @function
   *
   * @description
   * Show a message when an external target is being register
   */
  function unregisterTargetModalCtrlFn($rootScope, $translate, $q,
    ExternalTargetService, PolicyService, RestoreService, evalAJAX) {
    var $ctrl = this;

    _.assign($ctrl, {
      // members
      confirmUnregisterString: '',
      stringFormat: new RegExp('^' + $translate.instant($rootScope.text.unregister) + '$'),
      policiesUsingTarget: [],
      cadPoliciesUsingTarget: [],
      recoveriesUsingTarget: [],

      //methods
      $onInit: $onInit,
      cancel: cancel,
      unregisterTarget: unregisterTarget
    });

    /**
     * Unregister an external target
     *
     * @method  unregisterTarget
     * @param   {Object}  form  The form object
     */
    function unregisterTarget(form) {
      if (!form.$valid) {
        return;
      }

      $ctrl.submitting = true;

      ExternalTargetService.unregisterTarget($ctrl.target.id, {
        forceDelete: !$ctrl.deleteData,
      }).then(function targetUnregistered(data) {
        $ctrl.cModalHoc.save(data);
      }, evalAJAX.errorMessage)
      .finally(function targetUnregisteredFinally() {
        $ctrl.submitting = false;
      });
    }

    /**
     * Cancel form submission and close the modal
     *
     * @method   cancel
     */
    function cancel() {
      $ctrl.cModalHoc.close();
    }

    /**
     * Initialize this controller
     */
    function $onInit() {
      $ctrl.loading = true;

      var promises = {
        policies: checkForTargetUseInPolicies($ctrl.target),
        recoveries: checkForTargetUseInRecoveries($ctrl.target),
      };

      $q.all(promises)
        .then(function afterCheck() {
          if ($ctrl.policiesUsingTarget.length ||
            $ctrl.cadPoliciesUsingTarget.length ||
            $ctrl.recoveriesUsingTarget.length) {

            $ctrl.targetInUse = true;
            $ctrl.heading = 'externalTarget.externalTargetInUse';
          } else {
            $ctrl.targetInUse = false;
            $ctrl.heading = 'externalTarget.unregisterTargetTitle';
          }

          $ctrl.loading = false;
        });
    }

    /**
     * Check for a targets use in any of the policies before unregistering it.
     *
     * @method  checkForTargetUseInPolicies
     * @param   {Object}  target  The target object which is being unregistered
     */
    function checkForTargetUseInPolicies(target) {
      return PolicyService.getNGPolicies().then(function onSuccess(policies) {
        policies.forEach(function eachPolicy(policy) {
          if ((_.get(policy, 'remoteTargetPolicy.archivalTargets') || [])
            .find(function findVault(vault) {

            return vault.targetId === target.id;
          })) {
            $ctrl.policiesUsingTarget.push(policy.name);
          } else if (_.get(policy,
            'backupPolicy.regular.primaryBackupTarget.archivalTargetSettings.targetId') === target.id) {

            $ctrl.cadPoliciesUsingTarget.push(policy.name);
          }
        });
      });
    }

    /**
     * Check for a targets use in any of the recoveries before unregistering it.
     *
     * @method  checkForTargetUseInRecoveries
     * @param   {Object}  target  The target object which is being unregistered
     */
    function checkForTargetUseInRecoveries(target) {
      return RestoreService.getRestoreTasks({
        targetType: ['kCloud', 'kTape'],
      }).then(function onSuccess(tasks) {

        tasks.forEach(function(task) {
          var taskObj = task.performRestoreTaskState;
          var targetIds = [];

          if (['kRunning', 'kAccepted', 'kCanceling']
            .includes(taskObj.base.publicStatus)) {

            var targetIds = taskObj.retrieveArchiveTaskVec
              .filter(function eachArchiveTask(archiveTask) {
                return archiveTask.archivalTarget.vaultId === target.id;
              });
          }

          if (targetIds.length) {
            $ctrl.recoveriesUsingTarget.push(taskObj.base.name);
          }
        });

      }, evalAJAX.errorMessage);
    }
  }

})(angular);