<!-- Modify View details Component -->
<div *ngIf="parentFormGroup" [formGroup]="parentFormGroup">
  <!-- Lock until duration input -->
  <div class="margin-bottom">
    <mat-form-field class="view-input" *ngIf="parentFormGroup.controls.dataLockExpiryUsecs?.value">
      <mat-label>{{'lockUntil' | translate}}</mat-label>
      <input
        [min]="minDataLockExpiryDate"
        [value]="dateTimeService.usecsToDate(parentFormGroup.controls.dataLockExpiryUsecs.value)"
        cogDataId="override-datelock-expiry-value"
        [matDatepicker]="datalockExpiryDatePicker"
        [disabled]="parentFormGroup.controls.dataLockExpiryUsecs.disabled"
        (dateChange)="setLockOverrideTimestamp($event.value)"
        matInput>
      <mat-datepicker-toggle [for]="datalockExpiryDatePicker"
        matSuffix>
      </mat-datepicker-toggle>
      <mat-datepicker #datalockExpiryDatePicker>
      </mat-datepicker>
    </mat-form-field>
  </div>

  <!-- View name input -->
  <mat-form-field *ngIf="!isViewTemplate" class="view-input">
    <mat-label>{{'viewName' | translate}}</mat-label>
    <input
      cogDataId="view-name-input"
      formControlName="name" matInput>
    <mat-error *ngIf="hasError('name', 'required')">
      {{ 'errors.required.viewName' | translate }}
    </mat-error>
  </mat-form-field>

  <div class="c-message-inline-compact warn margin-bottom" *ngIf="nameWarningKeys?.length > 0">
    <div *ngFor="let namekey of nameWarningKeys; let last = last;"
      [ngClass]="{'margin-bottom-sm': !last}"
      [innerHtml]="namekey | translate">
    </div>
  </div>

  <!-- View category selector -->
  <div class="view-form-field">
    <mat-label>
      {{'category' | translate}}
      <cog-icon size="sm" shape="info"
        class="category-info-icn"
        cogDataId="inferred-category-info"
        *ngIf="isEditMode && !!parentFormGroup.controls.isCategoryInferred.value"
        matTooltip="{{'views.inferredCategory.description' | translate}}"
        matTooltipPosition="right">
      </cog-icon>
    </mat-label>
    <mat-radio-group class="flex-row padding-top-sm"
      formControlName="category"
      (change)="categoryChange()"
      cogDataId="view-category-input">
      <mat-radio-button *ngFor="let item of categories; let first = first;"
        [value]="item.value"
        [disabled]="isCategoryDisabled(item.value)"
        cogDataId="category-{{item.label}}"
        [ngClass]="{'padding-left': !first}">
        {{item.label | translate}}
        <cog-icon size="sm" shape="info" inline="true"
          *ngIf="isHelios && !isEditMode && item.value === 'ObjectServices'"
          matTooltip="{{'views.create.helios.noObjectServices' | translate}}"></cog-icon>
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <!-- Storage domain selector -->
  <coh-storage-domain-selector
    class="view-input margin-top"
    *ngIf="!isViewboxRecommended"
    [addNewEnable]="true"
    [readOnly]="(isEditMode && !isViewTemplate) || parentFormGroup.controls.storageDomainId.disabled"
    cogDataId="view-storage-domain-id"
    [isForArchiveServices]="parentFormGroup.controls.category === 'ArchiveServices'"
    [defaultStorageDomainId]="parentFormGroup.controls.storageDomainId.value || defaultStorageDomainId"
    formControlName="storageDomainId"
    [floatLabel]="never">
  </coh-storage-domain-selector>

  <!-- Storage domain selector with recommended settings -->
  <div *ngIf="isViewboxRecommended" class="viewbox-input margin-top">
    <coh-recommended-storage-domain-selector
      [(templateId)]="templateId"
      [(template)]="templateInfo"
      [defaultStorageDomainId]="parentFormGroup.controls.storageDomainId.value || defaultStorageDomainId"
      [addNewEnable]="true"
      cogDataId="view-storage-domain-id"
      formControlName="storageDomainId"
      [floatLabel]="never">
    </coh-recommended-storage-domain-selector>
  </div>

  <!-- Object key pattern selection -->
  <coh-object-key-pattern-selector
    *ngIf="parentFormGroup.controls?.category?.value === 'ObjectServices'"
    [isSwiftOnly]="isSwiftOnly"
    [control]="parentFormGroup.controls.objectServicesMappingConfig">
  </coh-object-key-pattern-selector>

  <!-- Protocol selector -->
  <coh-protocol-selector
    [category]="parentFormGroup.controls.category.value"
    [isHelios]="isHelios"
    [control]="parentFormGroup.controls.protocolAccess">
  </coh-protocol-selector>
  <div *ngIf="parentFormGroup.controls.protocolAccess?.errors?.readOnlyProtocolError"
    class="c-message-inline icon-message error"
    translate>
    views.errors.selectAtleastOneReadWriteProtocol
  </div>

  <!-- Swift Ownership section -->
  <div *ngIf="isSwiftOnly">
    <h4 class="margin-bottom-xs" translate>swiftOwnership</h4>
    <div class="flex-row">
      <mat-form-field>
        <mat-label>{{'projectDomain' | translate}}</mat-label>
        <input
          cogDataId="swift-project-domain-name"
          formControlName="swiftProjectDomain" matInput>
      </mat-form-field>
      <mat-form-field class="padding-left">
        <mat-label>{{'projectName' | translate}}</mat-label>
        <input
          cogDataId="swift-project-name"
          formControlName="swiftProjectName" matInput>
      </mat-form-field>
    </div>

    <div class="flex-row">
      <mat-form-field>
        <mat-label>{{'username' | translate}} ({{'optional' | translate}})</mat-label>
        <input
          cogDataId="swift-username"
          formControlName="swiftUsername" matInput>
      </mat-form-field>
      <mat-form-field class="padding-left" *ngIf="parentFormGroup?.value.swiftUsername">
        <mat-label>{{'usersDomainName' | translate}}</mat-label>
        <input
          cogDataId="swift-user-domain"
          formControlName="swiftUserDomain" matInput>
          <mat-error *ngIf="hasError('swiftUserDomain', 'required')">
            {{ 'errors.required' | translate }}
          </mat-error>
      </mat-form-field>
    </div>
  </div>
</div>
