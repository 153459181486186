// Module: functionality for Physical server BMR and Volume recovery
import { recoveryGroup } from 'src/app/shared/constants';

;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.physicalServerRecovery';
  var moduleDeps = [];

  angular
    .module(moduleName, moduleDeps)
    .config(physicalServerRecoveryConfigFn)
    .controller('physicalServerRecoveryController',
      physicalServerRecoveryControllerFn);

  /**
   * Physical Server recovery Config: States & Routing
   **************************************************************************/
  function physicalServerRecoveryConfigFn($stateProvider) {
    var restoreModifyAccess = ctx => {
      return ctx.RESTORE_MODIFY && ctx.canAccessSomeEnv(recoveryGroup.physicalServer);
    };

    $stateProvider
      .state('recover-physical-server', {
        url: '/protection/recovery/physical-server',
        title: 'Physical Server',
        canAccess: restoreModifyAccess,
        parentState: 'recover',
        templateUrl: 'app/protection/recovery/physical-server/physical-server.parent.html',
        controller: 'physicalServerRecoveryController',
        controllerAs: '$ctrl',
        redirectTo: 'recover-physical-server.search'
      })
      .state('recover-physical-server.search', {
        url: '^/protection/recovery/physical-server/search',
        title: 'Physical Server: Select Server',
        help: 'protection_recovery_phys_search',
        canAccess: restoreModifyAccess,
        parentState: 'recover-physical-server',
        templateUrl: 'app/protection/recovery/physical-server/physical-server.search.html',
        controller: 'physicalServerRecoverySearchController',
        controllerAs: '$ctrl',
      })
      .state('recover-physical-server.options', {
        url: '^/protection/recovery/physical-server/options?{entityId}&{hostId}&{jobId}&{jobInstanceId}',
        help: 'protection_recovery_phys_options',
        title: 'Physical Server: Options',
        canAccess: restoreModifyAccess,
        parentState: 'recover-physical-server',
        params: {
          targetEntity: undefined,
          host: undefined,
          inactive: false,
          entityId: { type: 'string' },
          hostId: { type: 'string' },
          jobId: { type: 'string' },
          jobInstanceId: { type: 'string' },
        },
        templateUrl: 'app/protection/recovery/physical-server/physical-server.options.html',
        controller: 'physicalServerRecoveryOptionsController',
        controllerAs: '$ctrl',
      });
  }

  /**
   * physicalServerRecovery Parent Controller
   **************************************************************************/
  function physicalServerRecoveryControllerFn($scope, $state, ENUM_HOST_TYPE,
    PhysicalServerRecoveryService, cSearchService) {
    var $ctrl = this;

    // Holder for actionType $watcher
    var actionTypeWatcher;

    angular.extend($scope, {
      // General scope vars

      /**
      * Ordered list of state names in this recovery flow
      *
      * @type       {Array}
      */
      stateNames: PhysicalServerRecoveryService.getRecoverStateNames(),

      /**
      * Object for sharing within and across descendant $scopes
      *
      * @type       {Object}
      */
      shared: PhysicalServerRecoveryService.getSharedState(),

      os: ENUM_HOST_TYPE,

      // Scope methods
      getOStype: PhysicalServerRecoveryService.getOStype,
    });


    /**
    * Watcher
    ******************************************************************/

    // Watch the task restore type for changes and make other updates
    // accordingly
    actionTypeWatcher = $scope.$watch(
      PhysicalServerRecoveryService.getTaskActionType,
      function actionTypeWatcherFn(nv) {
        var entity;
        var backupSourceEntity;

        if ($scope.shared.cart.length) {
          entity = $scope.shared.cart[0];
          backupSourceEntity = entity.vmDocument.objectId.entity;

          PhysicalServerRecoveryService.setAllVolumesSelected(false);
          PhysicalServerRecoveryService.updateTaskWithEntity(entity);
          PhysicalServerRecoveryService.resetSelectedSnapshot(entity);
          PhysicalServerRecoveryService.resetSourceTargetMapping();
          PhysicalServerRecoveryService
            .updateTaskWithSnapshot(entity._snapshot);

          // on selecting volume recovery, need to set target entity
          if (nv === 'kRecoverVolumes') {
            PhysicalServerRecoveryService.getVolumes(entity);
            PhysicalServerRecoveryService
              .updateTaskWithArchiveTarget(entity._archiveTarget);
            if (backupSourceEntity) {
              PhysicalServerRecoveryService
                .updateTaskWithTargetEntity(backupSourceEntity);
            }
          }
        }
      });


    /**
     * Methods
     ******************************************************************/

    /**
     * Initialize all the things!
     *
     * @method     _activate
     */
    function _activate() {
      $scope.shared.task = PhysicalServerRecoveryService.initRecoverTask();
      $scope.$on('$destroy', _destroyThisSearchSession);
    }

    /**
     * Destroy this search session completely.
     *
     * @method     destroyThisSearchSession
     */
    function _destroyThisSearchSession() {
      cSearchService.destroy($scope.shared.searchId);
      PhysicalServerRecoveryService.clearCart();
    }

    _activate();
  }


})(angular);
