import { Injectable } from '@angular/core';
import { KeyedSelectionModel } from '@cohesity/helix';

import { AnomalyAlert } from './../security-shared.models';

@Injectable({ providedIn: 'root' })
export class AnomalyCartService {
  /**
   * The cart holds the list of objects to be recovered.
   */
  readonly cartSelectionModel = new KeyedSelectionModel<AnomalyAlert>(alert => alert.id, true);

  /**
   * Adds the a new snapshot to be recovered for an object.
   *
   * @param   anomaly   The anomaly to be restored.
   */
  addToCart(anomaly: AnomalyAlert) {
    this.cartSelectionModel.select(anomaly);
  }

  /**
   * Returns the current cart with the list of objects.
   *
   * @returns   The cart with list of selected objects.
   */
  getCart(): AnomalyAlert[] {
    return this.cartSelectionModel.selected;
  }

  /**
   * Checks whether the object is already in cart.
   *
   * @param   anomalyId   The anomaly object id to be checked
   * @returns True, if present in cart.
   */
  isPresentInCart(anomalyId: string) {
    return this.cartSelectionModel.isSelected({ id: anomalyId });
  }

  /**
   * Updates the cart with a new value. Usually done with the page is refreshed
   * to update the state.
   *
   * @param   cart   The cart with list of selected objects.
   */
  setCart(cart: AnomalyAlert[]) {
    this.cartSelectionModel.clear();
    this.cartSelectionModel.select(...cart);
  }

  /**
   * Removes the anomaly from the cart.
   *
   * @param   anomalyId   The anomaly object id to be removed.
   */
  removeFromCart(anomalyId: string) {
    this.cartSelectionModel.deselect({ id: anomalyId });
  }
}
