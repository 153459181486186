import { Component, Input } from '@angular/core';
import { ProtectionSourceTreeInfo, UdaConnectParams } from '@cohesity/api/v1';
import { UdaSourceType } from 'src/app/shared';

/**
 * UDA source details settings components. Used for displaying the
 * registered UDA source's configuration values.
 */
@Component({
  selector: 'coh-uda-source-details-settings',
  templateUrl: './source-details-settings.component.html'
})
export class UdaSourceDetailsSettingsComponent {

  /**
   * Input source.
   */
  @Input() source: ProtectionSourceTreeInfo;

  /**
   * Label to display for the UDA source type.
   */
  sourceTypeLabel: string;

  /**
   * Exposing UDA source type for use in the html.
   */
  UdaSourceType = UdaSourceType;

  /**
   * Getter for retrieving udaParams from source input.
   */
  get udaParams(): UdaConnectParams {
    return this.source?.registrationInfo?.udaParams || null;
  }

  /**
   * Getter for source type of the UDA connector.
   */
  get sourceType(): UdaSourceType {
    return this.udaParams.sourceType as UdaSourceType;
  }
}
