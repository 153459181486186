import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { Controls, NgxSubFormRemapComponent, subformComponentProviders } from 'ngx-sub-form';

/**
 * The internal form interface for this subform component.
 */
interface FormInterface {
  /**
   * The value of the form control.
   */
  value: string[];

  /**
   * Form control for the input box.
   */
  input: string;
}

/**
 * Renders a simple material chip list with an input to add items.
 *
 * @example
 * <coh-simple-chip-list-input
 *   [label]="'inputLabel' | translate"
 *   [separatorKeyCodes]="arrayOfKeyCodes"
 *   (itemAdded)="onItemAdded($event)"
 *   (itemRemoved)="onItemRemoved($event)">
 * </coh-simple-chip-list-input>
 */
@Component({
  selector: 'coh-simple-chip-list-input',
  templateUrl: './simple-chip-list-input.component.html',
  styleUrls: ['./simple-chip-list-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [subformComponentProviders(SimpleChipListInputComponent)],
})
export class SimpleChipListInputComponent extends NgxSubFormRemapComponent<string[], FormInterface> {
  /**
   * The label to show for the control.
   */
  @Input() label: string;

  /**
   * The key codes to be used as delimiters of the input tokens inside the
   * chip list.
   */
  @Input() separatorKeyCodes: number[] = [ENTER, COMMA, SPACE];

  /**
   * Controls whether the placeholder/label should float. Specifying this as
   * `false` also sets the form control appearance as `legacy`.
   */
  @Input() floatingLabel = true;

  /**
   * True if update formControl automatically.
   */
  @Input() updateFormControl = false;

  /**
   * Max length for input (Optional).
   */
  @Input() maxInputLength: number;

  /**
   * Emits whenever an item is added to the list.
   */
  @Output() itemAdded = new EventEmitter<string>();

  /**
   * Emits whenever an item is removed from the list.
   */
  @Output() itemRemoved = new EventEmitter<string>();

  /**
   * Handler for removing a chip.
   *
   * @param item Chip value.
   */
  onRemove(item: string): void {
    if (this.updateFormControl) {
      this.formGroupControls.value.setValue(this.formGroupValues.value.filter(v => v !== item));
      this.formGroupControls.value.updateValueAndValidity();
    }
    this.itemRemoved.emit(item);
  }

  /**
   * Handler for the "matChipInputTokenEnd" event on the mat-chip-list.
   */
  onMatChipInputTokenEnd(event: MatChipInputEvent): void {
    const value = event.value;

    if (value?.trim().length) {
      if (this.updateFormControl) {
        this.formGroupControls.value.setValue([ ...this.formGroupValues.value, value]);
        this.formGroupControls.value.updateValueAndValidity();
      }
      this.itemAdded.emit(value);
      this.formGroupControls.input.setValue('');
    }
  }

  // Overrides of NgxSubFormRemapComponent.

  protected transformToFormGroup(obj: string[], defaultValues: Partial<FormInterface>): FormInterface {
    return { value: obj ?? defaultValues?.value, input: '' };
  }

  protected transformFromFormGroup(formValue: FormInterface): string[] {
    return formValue?.value ?? [];
  }

  protected getFormControls(): Controls<FormInterface> {
    return { value: new UntypedFormControl(), input: new UntypedFormControl() };
  }

  protected getDefaultValues(): Partial<FormInterface> {
    return { value: [] };
  }
}
