// COMPONENT: File Import

// TODO(rohit)
// Add support to read other file types such as images
// Add example to UI-Examples
// Add support to provide other ngf attributes
;(function(angular) {
  'use strict';

  var modName = 'C.import';
  var componentName = 'cFileImport';

  /**
   * @ngdoc component
   * @name C.import:cFileImport
   * @function
   * @requires ngModel
   *
   * @description
   * This component provides the option to import a file. File contents and
   * name can is available as result of import.
   *
   * @example
      <example module="C">
        <c-file-import
          type="link"
          ng-model="file.content"
          label-text="sources.importLabel">
        </c-file-import>
      </example>
   */
  var options = {
    bindings: {
      /**
       * Type of component to be displayed. Can display a link or a button.
       * Default is link.
       *
       * @type   {String}   type(link|button)
       */
      type: '@?',

      /**
       * Key to bind the file name.
       *
       * @type   {String}   fileName
       */
      fileName: '=?',

      /**
       * Text to be displayed as link or button label.
       *
       * @type   {String}   labelText
       */
      labelText: '@',

      /**
       * List of classes to be applied to link or button. Multiple classes
       * can be provided as a single string separated by spaces.
       *
       * @type   {String}   customClasses
       */
      customClasses: '@',
    },
    require: {
      /**
       * Bind the value of path to be updated with imported file content.
       */
      ngModel: 'ngModel',
    },
    controller: 'cFileImportCtrl',
    templateUrl: 'app/global/c-file-import/c-file-import.html'
  };

  angular
    .module(modName, [])
    .controller('cFileImportCtrl', cFileImportCtrlFn)
    .component(componentName, options);

  function cFileImportCtrlFn(cUtils, $element) {
    var $ctrl = this;
    var reader = cUtils.fileReader;
    var DEFAULT_CLASSES = 'c-btn-secondary sm';

    $ctrl.file = {
      content: ''
    };

    /**
     * $onInit function for the controller
     *
     * @method     $onInit
     */
    $ctrl.$onInit = function $onInit() {
      // Set type as link if not specified.
      $ctrl.type = $ctrl.type ? $ctrl.type : 'link';

      // Add default class for button if custom class is not provided.
      $ctrl.customClasses = $ctrl.customClasses ? $ctrl.customClasses :
        $ctrl.type === 'button' ? DEFAULT_CLASSES : '';
    };

    /**
     * Handler for file selection.
     *
     * @method     uploadHandler
     */
    $ctrl.uploadHandler = function uploadHandler() {
      var content = $ctrl.file.content;

      if (content) {
        reader.readAsText(content);
        $ctrl.fileName = content.name;
      }
    };

    /**
     * Event handler for setting the contents of file to sepecified path.
     *
     * @method     onload
     * @param      {Object}   e   Event object
     * @return     {void}
     */
    reader.onload = function readFile(e) {
      var fileContent = e.target.result;

      if (fileContent) {
        $ctrl.ngModel.$setViewValue(fileContent);
      }
    };
  };

})(angular);
