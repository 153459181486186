/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiDmsConfiguration as __Configuration } from '../cohesity-api-dms-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DmaasRegions } from '../models/dmaas-regions';
import { GetTenantRegionsResponse } from '../models/get-tenant-regions-response';
import { AddTenantRegionsRequest } from '../models/add-tenant-regions-request';
import { TenantRegionInfo } from '../models/tenant-region-info';
import { DeleteTenantRegionRequest } from '../models/delete-tenant-region-request';
import { ResumeTenantRegionProvisionRequest } from '../models/resume-tenant-region-provision-request';
import { KmsKeyInfo } from '../models/kms-key-info';
import { AddKmsKeyInfoRequest } from '../models/add-kms-key-info-request';
import { UpdateKmsKeyInfoRequest } from '../models/update-kms-key-info-request';
import { DeleteKmsKeyInfoRequest } from '../models/delete-kms-key-info-request';
import { TenantCloudSourceMetadataInfo } from '../models/tenant-cloud-source-metadata-info';
import { CreateTenantAwsCloudSourceRequest } from '../models/create-tenant-aws-cloud-source-request';
import { DeleteTenantAwsCloudSourceRequest } from '../models/delete-tenant-aws-cloud-source-request';
import { AuthenticateRoleResult } from '../models/authenticate-role-result';
import { AuthenticateRoleRequest } from '../models/authenticate-role-request';
import { Clusters } from '../models/clusters';
import { DmaasClusterInfo } from '../models/dmaas-cluster-info';
import { ModifyClusterInfoRequest } from '../models/modify-cluster-info-request';
import { DmaasClusterNodesInfo } from '../models/dmaas-cluster-nodes-info';
import { ScaleClusterNodeComputeRequest } from '../models/scale-cluster-node-compute-request';
import { GetDmaasClusterNodesStatusRequest } from '../models/get-dmaas-cluster-nodes-status-request';
import { ClaimDmaasClusterResult } from '../models/claim-dmaas-cluster-result';
import { ClaimDmaasClusterRequest } from '../models/claim-dmaas-cluster-request';
import { InternalTenantRegions } from '../models/internal-tenant-regions';
import { StandbyClusters } from '../models/standby-clusters';
import { StandbyClusterInfo } from '../models/standby-cluster-info';
import { AddStandbyClusterRequest } from '../models/add-standby-cluster-request';
import { DeleteStandbyClusterRequest } from '../models/delete-standby-cluster-request';
import { LowStandbyClusterCountNotifResponseInfo } from '../models/low-standby-cluster-count-notif-response-info';
import { LowStandbyClusterCountParams } from '../models/low-standby-cluster-count-params';
import { DmaasClusterLocks } from '../models/dmaas-cluster-locks';
import { LockDmaasClusterResult } from '../models/lock-dmaas-cluster-result';
import { LockDmaasClusterRequest } from '../models/lock-dmaas-cluster-request';
import { UnlockDmaasClusterResult } from '../models/unlock-dmaas-cluster-result';
import { UnlockDmaasClusterRequest } from '../models/unlock-dmaas-cluster-request';
import { ServiceGflags } from '../models/service-gflags';
import { UpdateServiceGflagsRequest } from '../models/update-service-gflags-request';
import { GetClusterGflagsResponse } from '../models/get-cluster-gflags-response';
import { AddNodesToDmaasClusterRequest } from '../models/add-nodes-to-dmaas-cluster-request';
import { RemoveNodesFromDmaasClusterRequest } from '../models/remove-nodes-from-dmaas-cluster-request';
import { ResourceTags } from '../models/resource-tags';
import { GetAllResourceTagsRequest } from '../models/get-all-resource-tags-request';
import { DmsSuccess } from '../models/dms-success';
import { UpdateResourceTagsRequest } from '../models/update-resource-tags-request';
import { InternalRpaasRegions } from '../models/internal-rpaas-regions';
import { RemoveCloudNodesFromDmaasClusterRequest } from '../models/remove-cloud-nodes-from-dmaas-cluster-request';
import { GetCloudNodesStatusRequest } from '../models/get-cloud-nodes-status-request';
import { MigrateTenantInfo } from '../models/migrate-tenant-info';
import { MigrateTenantReq } from '../models/migrate-tenant-req';
import { ClusterRebuildInfo } from '../models/cluster-rebuild-info';
import { ClusterRebuildRequest } from '../models/cluster-rebuild-request';
import { CleanupTenantRegionRequest } from '../models/cleanup-tenant-region-request';
import { UpdateDmaasInfoInClusterConfigResult } from '../models/update-dmaas-info-in-cluster-config-result';
import { UpdateDefaultGflagsRequest } from '../models/update-default-gflags-request';
import { IndexingConfigInfo } from '../models/indexing-config-info';
import { AddIndexingConfigRequest } from '../models/add-indexing-config-request';
import { DeletedTenantRegions } from '../models/deleted-tenant-regions';
import { UpdateDeletedRegionStatusRequest } from '../models/update-deleted-region-status-request';
import { GetTenantRegionShardsResult } from '../models/get-tenant-region-shards-result';
import { TenantRegionShardInfo } from '../models/tenant-region-shard-info';
import { AddTenantRegionShardRequest } from '../models/add-tenant-region-shard-request';
import { DeleteTenantRegionShardRequest } from '../models/delete-tenant-region-shard-request';
import { ResumeTenantRegionShardProvisionRequest } from '../models/resume-tenant-region-shard-provision-request';
@Injectable({
  providedIn: 'root',
})
class DmsService extends __BaseService {
  static readonly GetRegionsPath = '/dms/regions';
  static readonly GetTenantRegionsPath = '/dms/tenants/regions';
  static readonly AddTenantRegionsPath = '/dms/tenants/regions';
  static readonly RemoveTenantRegionPath = '/dms/tenants/regions';
  static readonly ResumeTenantRegionProvisionPath = '/dms/tenants/regions';
  static readonly GetTenantKmsKeyPath = '/dms/tenants/regions/kms-keys';
  static readonly AddTenantKmsKeyPath = '/dms/tenants/regions/kms-keys';
  static readonly UpdateTenantKmsKeyPath = '/dms/tenants/regions/kms-keys';
  static readonly DeleteTenantKmsKeyPath = '/dms/tenants/regions/kms-keys';
  static readonly VerifyTenantAwsCloudSourcePath = '/dms/tenants/regions/aws-cloud-source-verify';
  static readonly GetTenantAwsCloudSourcePath = '/dms/tenants/regions/aws-cloud-source';
  static readonly RegisterTenantAwsCloudSourcePath = '/dms/tenants/regions/aws-cloud-source';
  static readonly UpdateTenantAwsCloudSourcePath = '/dms/tenants/regions/aws-cloud-source';
  static readonly DeleteTenantAwsCloudSourcePath = '/dms/tenants/regions/aws-cloud-source';
  static readonly AuthenticateRolePath = '/dms/authenticate-role-arn';
  static readonly GetClustersInfoPath = '/dms/internal/clusters';
  static readonly ModifyClusterInfoPath = '/dms/internal/cluster';
  static readonly ScaleClusterNodeComputePath = '/dms/clusters/scale-node';
  static readonly GetScaleNodeStatusPath = '/dms/clusters/scale-node';
  static readonly ClaimDmaasClusterPath = '/dms/clusters/claim';
  static readonly GetInternalTenantRegionInfoPath = '/dms/internal/tenants/regions';
  static readonly GetStandbyClustersPath = '/dms/internal/standby-clusters';
  static readonly AddStandbyClusterPath = '/dms/internal/standby-clusters';
  static readonly DeleteStandbyClusterPath = '/dms/internal/standby-clusters';
  static readonly HandleLowStandbyClusterCountPath = '/dms/internal/notify/low-standby-cluster-count';
  static readonly GetClusterLocksPath = '/dms/internal/clusters/lock';
  static readonly LockClusterPath = '/dms/internal/clusters/lock';
  static readonly UnlockClusterPath = '/dms/internal/clusters/lock';
  static readonly UpdateClusterGflagsPath = '/dms/internal/clusters/gflags';
  static readonly GetClusterGflagsPath = '/dms/internal/clusters/gflags';
  static readonly AddNodesToDMaaSClusterPath = '/dms/internal/cluster/nodes';
  static readonly RemoveNodesFromDMaaSClusterPath = '/dms/internal/cluster/nodes';
  static readonly GetNodesStatusPath = '/dms/internal/cluster/nodes';
  static readonly GetAllResourceTagsForTenantRegionPath = '/private/dms/internal/audit/resource-tags';
  static readonly UpdateResourceTagsForClusterPath = '/private/dms/internal/audit/resource-tags';
  static readonly GetInternalRpaasRegionsPath = '/dms/internal/rpaas/regions';
  static readonly RemoveCloudNodesFromDmaasClusterPath = '/dms/internal/cluster/cloud-nodes';
  static readonly GetRemoveCloudNodesStatusPath = '/dms/internal/cluster/cloud-nodes';
  static readonly GetMigrateTenantPath = '/private/dms/migrate-tenant';
  static readonly MigrateTenantPath = '/private/dms/migrate-tenant';
  static readonly ResumeMigrateTenantPath = '/private/dms/migrate-tenant';
  static readonly GetClusterRebuildPath = '/private/dms/cluster-rebuild';
  static readonly ClusterRebuildPath = '/private/dms/cluster-rebuild';
  static readonly ForceRemoveTenantRegionPath = '/private/dms/tenants/regions/cleanup';
  static readonly ChangeClustersGroupPath = '/private/dms/internal/clusters/change-group';
  static readonly SetDMaaSInfoPath = '/private/dms/internal/clusters/update-dmaas-info';
  static readonly UpdateDefaultGflagsPath = '/private/dms/internal/clusters/update-default-gflags';
  static readonly AddIndexingConfigPath = '/private/dms/tenants/indexingConfig';
  static readonly MigrateAwsCloudSourcePolicyPath = '/private/dms/awsCloudSource/fixPolicies';
  static readonly GetDeletedTenantRegionsPath = '/private/dms/deleted-tenants/regions';
  static readonly UpdateDeletedRegionStatusPath = '/private/dms/deleted-tenants/regions';
  static readonly RefreshAWSDataplaneAccountLimitsPath = '/private/dms/internal/awslimits/refresh';
  static readonly CheckStandbyAccountLimitsPath = '/private/dms/internal/awslimits/checkStandbyAccounts';
  static readonly GetTenantRegionShardsPath = '/private/dms/tenants/regions/shards';
  static readonly AddTenantRegionShardPath = '/private/dms/tenants/regions/shards';
  static readonly DeleteTenantRegionShardPath = '/private/dms/tenants/regions/shards';
  static readonly ResumeTenantRegionShardProvisionPath = '/private/dms/tenants/regions/shards';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get the list of supported regions by Cohesity DMaaS across all service providers.
   *
   * Get the list of supported regions by Cohesity DMaaS.
   * @param type Filter the results by service provider.
   * @return Success
   */
  GetRegionsResponse(type?: null | 'AWS'): __Observable<__StrictHttpResponse<DmaasRegions>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (type != null) __params = __params.set('type', type.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/dms/regions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DmaasRegions>;
      })
    );
  }
  /**
   * Get the list of supported regions by Cohesity DMaaS across all service providers.
   *
   * Get the list of supported regions by Cohesity DMaaS.
   * @param type Filter the results by service provider.
   * @return Success
   */
  GetRegions(type?: null | 'AWS'): __Observable<DmaasRegions> {
    return this.GetRegionsResponse(type).pipe(
      __map(_r => _r.body as DmaasRegions)
    );
  }

  /**
   * Get the list of regions enabled for a tenant in Cohesity DMaaS.
   *
   * Get the list of regions enabled for the DMaaS tenant.
   * @param params The `DmsService.GetTenantRegionsParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the identifier of a tenant.
   *
   * - `regionIds`: List of region IDs to filter the response.
   *
   * @return Success
   */
  GetTenantRegionsResponse(params: DmsService.GetTenantRegionsParams): __Observable<__StrictHttpResponse<GetTenantRegionsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    (params.regionIds || []).forEach(val => {if (val != null) __params = __params.append('regionIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/dms/tenants/regions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetTenantRegionsResponse>;
      })
    );
  }
  /**
   * Get the list of regions enabled for a tenant in Cohesity DMaaS.
   *
   * Get the list of regions enabled for the DMaaS tenant.
   * @param params The `DmsService.GetTenantRegionsParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the identifier of a tenant.
   *
   * - `regionIds`: List of region IDs to filter the response.
   *
   * @return Success
   */
  GetTenantRegions(params: DmsService.GetTenantRegionsParams): __Observable<GetTenantRegionsResponse> {
    return this.GetTenantRegionsResponse(params).pipe(
      __map(_r => _r.body as GetTenantRegionsResponse)
    );
  }

  /**
   * Add Cohesity DMaaS service in a given list of regions for the given tenant.
   *
   * Add Cohesity DMaaS service in a given set of regions for the tenant.
   * @param body Specifies the parameters to add DMaaS service in a region.
   */
  AddTenantRegionsResponse(body: AddTenantRegionsRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/dms/tenants/regions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Add Cohesity DMaaS service in a given list of regions for the given tenant.
   *
   * Add Cohesity DMaaS service in a given set of regions for the tenant.
   * @param body Specifies the parameters to add DMaaS service in a region.
   */
  AddTenantRegions(body: AddTenantRegionsRequest): __Observable<null> {
    return this.AddTenantRegionsResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Remove Cohesity DMaaS service from a given region for the given tenant.
   *
   * Remove Cohesity DMaaS service from a given region for the given tenant.
   * @param body Specifies the parameters for removing DMaaS service in a region.
   * @return Success
   */
  RemoveTenantRegionResponse(body: DeleteTenantRegionRequest): __Observable<__StrictHttpResponse<TenantRegionInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/dms/tenants/regions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantRegionInfo>;
      })
    );
  }
  /**
   * Remove Cohesity DMaaS service from a given region for the given tenant.
   *
   * Remove Cohesity DMaaS service from a given region for the given tenant.
   * @param body Specifies the parameters for removing DMaaS service in a region.
   * @return Success
   */
  RemoveTenantRegion(body: DeleteTenantRegionRequest): __Observable<TenantRegionInfo> {
    return this.RemoveTenantRegionResponse(body).pipe(
      __map(_r => _r.body as TenantRegionInfo)
    );
  }

  /**
   * Resume the provision of Cohesity DMaaS service from the last point of failure for a tenant in a region.
   *
   * Resume the provision of Cohesity DMaaS service from the last point of failure for the given tenant in a region.
   * @param body Specifies the parameters for resuming the provision DMaaS service in for a tenant in a region.
   * @return Success
   */
  ResumeTenantRegionProvisionResponse(body: ResumeTenantRegionProvisionRequest): __Observable<__StrictHttpResponse<TenantRegionInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/dms/tenants/regions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantRegionInfo>;
      })
    );
  }
  /**
   * Resume the provision of Cohesity DMaaS service from the last point of failure for a tenant in a region.
   *
   * Resume the provision of Cohesity DMaaS service from the last point of failure for the given tenant in a region.
   * @param body Specifies the parameters for resuming the provision DMaaS service in for a tenant in a region.
   * @return Success
   */
  ResumeTenantRegionProvision(body: ResumeTenantRegionProvisionRequest): __Observable<TenantRegionInfo> {
    return this.ResumeTenantRegionProvisionResponse(body).pipe(
      __map(_r => _r.body as TenantRegionInfo)
    );
  }

  /**
   * Get the KMS key assigned by the tenant to a given region.
   *
   * Get the KMS key assigned by the tenant to a given region.
   * @param params The `DmsService.GetTenantKmsKeyParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the identifier of a tenant.
   *
   * - `regionId`: Specifies the identifier of a region.
   *
   * - `accountId`: Specifies the identifier of an account.
   *
   * - `useCase`: The useCase of the KMS key. By default it is DMaaS.
   *
   * - `fortKnoxGlobalVaultId`: Specifies FortKnox global vault ID. It is only applicable for getting FortKnox KMS key.
   *
   * @return Success
   */
  GetTenantKmsKeyResponse(params: DmsService.GetTenantKmsKeyParams): __Observable<__StrictHttpResponse<KmsKeyInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.regionId != null) __params = __params.set('regionId', params.regionId.toString());
    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    if (params.useCase != null) __params = __params.set('useCase', params.useCase.toString());
    if (params.fortKnoxGlobalVaultId != null) __params = __params.set('fortKnoxGlobalVaultId', params.fortKnoxGlobalVaultId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/dms/tenants/regions/kms-keys`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<KmsKeyInfo>;
      })
    );
  }
  /**
   * Get the KMS key assigned by the tenant to a given region.
   *
   * Get the KMS key assigned by the tenant to a given region.
   * @param params The `DmsService.GetTenantKmsKeyParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the identifier of a tenant.
   *
   * - `regionId`: Specifies the identifier of a region.
   *
   * - `accountId`: Specifies the identifier of an account.
   *
   * - `useCase`: The useCase of the KMS key. By default it is DMaaS.
   *
   * - `fortKnoxGlobalVaultId`: Specifies FortKnox global vault ID. It is only applicable for getting FortKnox KMS key.
   *
   * @return Success
   */
  GetTenantKmsKey(params: DmsService.GetTenantKmsKeyParams): __Observable<KmsKeyInfo> {
    return this.GetTenantKmsKeyResponse(params).pipe(
      __map(_r => _r.body as KmsKeyInfo)
    );
  }

  /**
   * Add KMS key info to a region within Cohesity DMaaS service.
   *
   * Add KMS key info to a region within Cohesity DMaaS service.
   * @param body Specifies the KMS key info.
   * @return Success
   */
  AddTenantKmsKeyResponse(body: AddKmsKeyInfoRequest): __Observable<__StrictHttpResponse<KmsKeyInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/dms/tenants/regions/kms-keys`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<KmsKeyInfo>;
      })
    );
  }
  /**
   * Add KMS key info to a region within Cohesity DMaaS service.
   *
   * Add KMS key info to a region within Cohesity DMaaS service.
   * @param body Specifies the KMS key info.
   * @return Success
   */
  AddTenantKmsKey(body: AddKmsKeyInfoRequest): __Observable<KmsKeyInfo> {
    return this.AddTenantKmsKeyResponse(body).pipe(
      __map(_r => _r.body as KmsKeyInfo)
    );
  }

  /**
   * Update KMS key info that was previously added to a region within Cohesity DMaaS service.
   *
   * Update KMS key info that was previously added to a region within Cohesity DMaaS service.
   * @param body Specifies the KMS key info.
   * @return Success
   */
  UpdateTenantKmsKeyResponse(body: UpdateKmsKeyInfoRequest): __Observable<__StrictHttpResponse<KmsKeyInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/dms/tenants/regions/kms-keys`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<KmsKeyInfo>;
      })
    );
  }
  /**
   * Update KMS key info that was previously added to a region within Cohesity DMaaS service.
   *
   * Update KMS key info that was previously added to a region within Cohesity DMaaS service.
   * @param body Specifies the KMS key info.
   * @return Success
   */
  UpdateTenantKmsKey(body: UpdateKmsKeyInfoRequest): __Observable<KmsKeyInfo> {
    return this.UpdateTenantKmsKeyResponse(body).pipe(
      __map(_r => _r.body as KmsKeyInfo)
    );
  }

  /**
   * Delete a KMS key info associated region within Cohesity DMaaS service for a given tenant.
   *
   * Delete KMS key info associated with a region within Cohesity DMaaS service
   * @param body Specifies the parameters for deleting KMS key info for a region for the given tenant.
   */
  DeleteTenantKmsKeyResponse(body: DeleteKmsKeyInfoRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/dms/tenants/regions/kms-keys`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a KMS key info associated region within Cohesity DMaaS service for a given tenant.
   *
   * Delete KMS key info associated with a region within Cohesity DMaaS service
   * @param body Specifies the parameters for deleting KMS key info for a region for the given tenant.
   */
  DeleteTenantKmsKey(body: DeleteKmsKeyInfoRequest): __Observable<null> {
    return this.DeleteTenantKmsKeyResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Verify the AWS cloud source's connectivity. This is performed by trying to assume tenant's AWS IAM role internally in DMS.
   *
   * Verify the AWS cloud source's connectivity. This is performed by trying to assume tenant's AWS IAM role internally in DMS.
   * @param params The `DmsService.VerifyTenantAwsCloudSourceParams` containing the following parameters:
   *
   * - `tenantId`: ID of the DMaaS tenant.
   *
   * - `destinationRegionId`: Id of the region where customer has subscribed to Cohesity DMaaS service.
   *
   * - `awsAccountNumber`: Tenant's AWS account number used for AWS source registration.
   */
  VerifyTenantAwsCloudSourceResponse(params: DmsService.VerifyTenantAwsCloudSourceParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.destinationRegionId != null) __params = __params.set('destinationRegionId', params.destinationRegionId.toString());
    if (params.awsAccountNumber != null) __params = __params.set('awsAccountNumber', params.awsAccountNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/dms/tenants/regions/aws-cloud-source-verify`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Verify the AWS cloud source's connectivity. This is performed by trying to assume tenant's AWS IAM role internally in DMS.
   *
   * Verify the AWS cloud source's connectivity. This is performed by trying to assume tenant's AWS IAM role internally in DMS.
   * @param params The `DmsService.VerifyTenantAwsCloudSourceParams` containing the following parameters:
   *
   * - `tenantId`: ID of the DMaaS tenant.
   *
   * - `destinationRegionId`: Id of the region where customer has subscribed to Cohesity DMaaS service.
   *
   * - `awsAccountNumber`: Tenant's AWS account number used for AWS source registration.
   */
  VerifyTenantAwsCloudSource(params: DmsService.VerifyTenantAwsCloudSourceParams): __Observable<null> {
    return this.VerifyTenantAwsCloudSourceResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get the AWS cloud sources registered in DMaaS by a tenant.
   *
   * Get the AWS cloud source registered by a tenant.
   * @param params The `DmsService.GetTenantAwsCloudSourceParams` containing the following parameters:
   *
   * - `tenantId`: Id of the DMaaS tenant.
   *
   * - `destinationRegionId`: The Region Id where the customer has subscribed the cohesity DMaaS service.
   *
   * - `awsAccountNumber`: The AWS account number of the tenant's AWS account.
   *
   * @return Response to get tenant's AWS cloud sources call.
   */
  GetTenantAwsCloudSourceResponse(params: DmsService.GetTenantAwsCloudSourceParams): __Observable<__StrictHttpResponse<TenantCloudSourceMetadataInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.destinationRegionId != null) __params = __params.set('destinationRegionId', params.destinationRegionId.toString());
    if (params.awsAccountNumber != null) __params = __params.set('awsAccountNumber', params.awsAccountNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/dms/tenants/regions/aws-cloud-source`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantCloudSourceMetadataInfo>;
      })
    );
  }
  /**
   * Get the AWS cloud sources registered in DMaaS by a tenant.
   *
   * Get the AWS cloud source registered by a tenant.
   * @param params The `DmsService.GetTenantAwsCloudSourceParams` containing the following parameters:
   *
   * - `tenantId`: Id of the DMaaS tenant.
   *
   * - `destinationRegionId`: The Region Id where the customer has subscribed the cohesity DMaaS service.
   *
   * - `awsAccountNumber`: The AWS account number of the tenant's AWS account.
   *
   * @return Response to get tenant's AWS cloud sources call.
   */
  GetTenantAwsCloudSource(params: DmsService.GetTenantAwsCloudSourceParams): __Observable<TenantCloudSourceMetadataInfo> {
    return this.GetTenantAwsCloudSourceResponse(params).pipe(
      __map(_r => _r.body as TenantCloudSourceMetadataInfo)
    );
  }

  /**
   * Register an AWS cloud source for a tenant in DMaaS.
   *
   * Register an AWS cloud source for a tenant in DMaaS
   * @param body Specifies the parameters to register an AWS tenant's cloud sources in DMaaS.
   * @return Response to create tenant's AWS cloud sources call.
   */
  RegisterTenantAwsCloudSourceResponse(body: CreateTenantAwsCloudSourceRequest): __Observable<__StrictHttpResponse<TenantCloudSourceMetadataInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/dms/tenants/regions/aws-cloud-source`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantCloudSourceMetadataInfo>;
      })
    );
  }
  /**
   * Register an AWS cloud source for a tenant in DMaaS.
   *
   * Register an AWS cloud source for a tenant in DMaaS
   * @param body Specifies the parameters to register an AWS tenant's cloud sources in DMaaS.
   * @return Response to create tenant's AWS cloud sources call.
   */
  RegisterTenantAwsCloudSource(body: CreateTenantAwsCloudSourceRequest): __Observable<TenantCloudSourceMetadataInfo> {
    return this.RegisterTenantAwsCloudSourceResponse(body).pipe(
      __map(_r => _r.body as TenantCloudSourceMetadataInfo)
    );
  }

  /**
   * Update an AWS cloud source for a tenant in DMaaS.
   *
   * Update an AWS cloud source for a tenant in DMaaS
   * @param body Specifies the parameters to update an AWS tenant's cloud source in DMaaS.
   * @return Response to create tenant's AWS cloud sources call.
   */
  UpdateTenantAwsCloudSourceResponse(body: CreateTenantAwsCloudSourceRequest): __Observable<__StrictHttpResponse<TenantCloudSourceMetadataInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/dms/tenants/regions/aws-cloud-source`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantCloudSourceMetadataInfo>;
      })
    );
  }
  /**
   * Update an AWS cloud source for a tenant in DMaaS.
   *
   * Update an AWS cloud source for a tenant in DMaaS
   * @param body Specifies the parameters to update an AWS tenant's cloud source in DMaaS.
   * @return Response to create tenant's AWS cloud sources call.
   */
  UpdateTenantAwsCloudSource(body: CreateTenantAwsCloudSourceRequest): __Observable<TenantCloudSourceMetadataInfo> {
    return this.UpdateTenantAwsCloudSourceResponse(body).pipe(
      __map(_r => _r.body as TenantCloudSourceMetadataInfo)
    );
  }

  /**
   * Delete an AWS cloud source registered for a tenant in DMaaS.
   *
   * Delete an AWS cloud source registered for a tenant in DMaaS.
   * @param body Specifies the parameters to delete a tenant's registered AWS cloud source from DMaaS.
   */
  DeleteTenantAwsCloudSourceResponse(body: DeleteTenantAwsCloudSourceRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/dms/tenants/regions/aws-cloud-source`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete an AWS cloud source registered for a tenant in DMaaS.
   *
   * Delete an AWS cloud source registered for a tenant in DMaaS.
   * @param body Specifies the parameters to delete a tenant's registered AWS cloud source from DMaaS.
   */
  DeleteTenantAwsCloudSource(body: DeleteTenantAwsCloudSourceRequest): __Observable<null> {
    return this.DeleteTenantAwsCloudSourceResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Validate the given AWS token and return valid temporary credentials.
   *
   * Authenticate the AWS Role and return valid temporary credentials.
   * @param body Specifies the parameters to authenticate the IAM Role.
   * @return Response of authenticate AWS Role.
   */
  AuthenticateRoleResponse(body: AuthenticateRoleRequest): __Observable<__StrictHttpResponse<AuthenticateRoleResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/dms/authenticate-role-arn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AuthenticateRoleResult>;
      })
    );
  }
  /**
   * Validate the given AWS token and return valid temporary credentials.
   *
   * Authenticate the AWS Role and return valid temporary credentials.
   * @param body Specifies the parameters to authenticate the IAM Role.
   * @return Response of authenticate AWS Role.
   */
  AuthenticateRole(body: AuthenticateRoleRequest): __Observable<AuthenticateRoleResult> {
    return this.AuthenticateRoleResponse(body).pipe(
      __map(_r => _r.body as AuthenticateRoleResult)
    );
  }

  /**
   * Get list of DMaaS clusters information.
   *
   * Get list of DMaaS clusters information.
   * @param params The `DmsService.GetClustersInfoParams` containing the following parameters:
   *
   * - `tenantPlacementLabel`: Optional param if specified fetches the clusters matching the tenant placement label.
   *
   * - `regionId`: Optional region ID to filter the clusters.
   *
   * - `provisionedOnly`: Optional param if set to true, returns only provisioned clusters. Default is false.
   *
   * - `fetchClusterSpec`: Optional param if specified fetches the cluster spec and the cluster create spec.
   *
   * - `clusterIds`: Optional cluster IDs to filter the response. If both clusterGroupId and clusterIds are specified, information of clusters within the group is returned.
   *
   * - `clusterGroupId`: Optional cluster group ID to filter the response.
   *
   * - `cloudProvider`: Optional cloud provider if specified fetches the clusters for specific cloud provider.
   *
   * @return Response of get clusters call.
   */
  GetClustersInfoResponse(params: DmsService.GetClustersInfoParams): __Observable<__StrictHttpResponse<Clusters>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantPlacementLabel != null) __params = __params.set('tenantPlacementLabel', params.tenantPlacementLabel.toString());
    if (params.regionId != null) __params = __params.set('regionId', params.regionId.toString());
    if (params.provisionedOnly != null) __params = __params.set('provisionedOnly', params.provisionedOnly.toString());
    if (params.fetchClusterSpec != null) __params = __params.set('fetchClusterSpec', params.fetchClusterSpec.toString());
    (params.clusterIds || []).forEach(val => {if (val != null) __params = __params.append('clusterIds', val.toString())});
    if (params.clusterGroupId != null) __params = __params.set('clusterGroupId', params.clusterGroupId.toString());
    if (params.cloudProvider != null) __params = __params.set('cloudProvider', params.cloudProvider.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/dms/internal/clusters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Clusters>;
      })
    );
  }
  /**
   * Get list of DMaaS clusters information.
   *
   * Get list of DMaaS clusters information.
   * @param params The `DmsService.GetClustersInfoParams` containing the following parameters:
   *
   * - `tenantPlacementLabel`: Optional param if specified fetches the clusters matching the tenant placement label.
   *
   * - `regionId`: Optional region ID to filter the clusters.
   *
   * - `provisionedOnly`: Optional param if set to true, returns only provisioned clusters. Default is false.
   *
   * - `fetchClusterSpec`: Optional param if specified fetches the cluster spec and the cluster create spec.
   *
   * - `clusterIds`: Optional cluster IDs to filter the response. If both clusterGroupId and clusterIds are specified, information of clusters within the group is returned.
   *
   * - `clusterGroupId`: Optional cluster group ID to filter the response.
   *
   * - `cloudProvider`: Optional cloud provider if specified fetches the clusters for specific cloud provider.
   *
   * @return Response of get clusters call.
   */
  GetClustersInfo(params: DmsService.GetClustersInfoParams): __Observable<Clusters> {
    return this.GetClustersInfoResponse(params).pipe(
      __map(_r => _r.body as Clusters)
    );
  }

  /**
   * Modify DMaaS cluster info for a given cluster ID. Currently only enabling or disabling multiple tenant sharing flag is supported.
   *
   * Modify DMaaS cluster info for a given cluster ID. Currently only enabling or disabling multiple tenant sharing flag is supported.
   * @param body Specifies the parameters to modify a DMaaS cluster info
   * @return Success
   */
  ModifyClusterInfoResponse(body: ModifyClusterInfoRequest): __Observable<__StrictHttpResponse<DmaasClusterInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/dms/internal/cluster`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DmaasClusterInfo>;
      })
    );
  }
  /**
   * Modify DMaaS cluster info for a given cluster ID. Currently only enabling or disabling multiple tenant sharing flag is supported.
   *
   * Modify DMaaS cluster info for a given cluster ID. Currently only enabling or disabling multiple tenant sharing flag is supported.
   * @param body Specifies the parameters to modify a DMaaS cluster info
   * @return Success
   */
  ModifyClusterInfo(body: ModifyClusterInfoRequest): __Observable<DmaasClusterInfo> {
    return this.ModifyClusterInfoResponse(body).pipe(
      __map(_r => _r.body as DmaasClusterInfo)
    );
  }

  /**
   * Modify the compute of the node to the given instance type.
   *
   * Modify the compute of the node to the given instance type.
   * @param body Specifies the parameters to scale the compute of the given node.
   * @return Success
   */
  ScaleClusterNodeComputeResponse(body: ScaleClusterNodeComputeRequest): __Observable<__StrictHttpResponse<DmaasClusterNodesInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/dms/clusters/scale-node`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DmaasClusterNodesInfo>;
      })
    );
  }
  /**
   * Modify the compute of the node to the given instance type.
   *
   * Modify the compute of the node to the given instance type.
   * @param body Specifies the parameters to scale the compute of the given node.
   * @return Success
   */
  ScaleClusterNodeCompute(body: ScaleClusterNodeComputeRequest): __Observable<DmaasClusterNodesInfo> {
    return this.ScaleClusterNodeComputeResponse(body).pipe(
      __map(_r => _r.body as DmaasClusterNodesInfo)
    );
  }

  /**
   * An endpoint to get status of Scale Node operation.
   *
   * An endpoint to get status of Scale Node operation.
   * @param body Specifies the parameters to get Scale Node operation.
   * @return Success
   */
  GetScaleNodeStatusResponse(body: GetDmaasClusterNodesStatusRequest): __Observable<__StrictHttpResponse<DmaasClusterNodesInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/dms/clusters/scale-node`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DmaasClusterNodesInfo>;
      })
    );
  }
  /**
   * An endpoint to get status of Scale Node operation.
   *
   * An endpoint to get status of Scale Node operation.
   * @param body Specifies the parameters to get Scale Node operation.
   * @return Success
   */
  GetScaleNodeStatus(body: GetDmaasClusterNodesStatusRequest): __Observable<DmaasClusterNodesInfo> {
    return this.GetScaleNodeStatusResponse(body).pipe(
      __map(_r => _r.body as DmaasClusterNodesInfo)
    );
  }

  /**
   * Endpoint to claim a DMaaS cluster.
   *
   * Endpoint to claim a DMaaS cluster.
   * @param body Specifies the parameters to claim a DMaaS cluster.
   * @return Success
   */
  ClaimDmaasClusterResponse(body: ClaimDmaasClusterRequest): __Observable<__StrictHttpResponse<ClaimDmaasClusterResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/dms/clusters/claim`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClaimDmaasClusterResult>;
      })
    );
  }
  /**
   * Endpoint to claim a DMaaS cluster.
   *
   * Endpoint to claim a DMaaS cluster.
   * @param body Specifies the parameters to claim a DMaaS cluster.
   * @return Success
   */
  ClaimDmaasCluster(body: ClaimDmaasClusterRequest): __Observable<ClaimDmaasClusterResult> {
    return this.ClaimDmaasClusterResponse(body).pipe(
      __map(_r => _r.body as ClaimDmaasClusterResult)
    );
  }

  /**
   * Endpoint that returns tenant region info along with tenant FQDN and the associated cluster ID.
   *
   * Endpoint to get internal region info for a tenant.
   * @param params The `DmsService.GetInternalTenantRegionInfoParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the identifier of a tenant.
   *
   * - `regionIds`: Optional region IDs.
   *
   * - `paginationToken`: This token is passed by the caller to fetch next batch of tenant regions. If this is not passed, then first batch of tenant regions are returned applying all other specified filters.
   *   Use the pagination token returned as a part of the response in the subsequent calls to fetch next batch of tenant regions.
   *
   * - `numRecords`: Optional number of tenant regions to be returned. Maximum 100 records are returned.
   *
   * @return Success
   */
  GetInternalTenantRegionInfoResponse(params: DmsService.GetInternalTenantRegionInfoParams): __Observable<__StrictHttpResponse<InternalTenantRegions>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    (params.regionIds || []).forEach(val => {if (val != null) __params = __params.append('regionIds', val.toString())});
    if (params.paginationToken != null) __params = __params.set('paginationToken', params.paginationToken.toString());
    if (params.numRecords != null) __params = __params.set('numRecords', params.numRecords.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/dms/internal/tenants/regions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<InternalTenantRegions>;
      })
    );
  }
  /**
   * Endpoint that returns tenant region info along with tenant FQDN and the associated cluster ID.
   *
   * Endpoint to get internal region info for a tenant.
   * @param params The `DmsService.GetInternalTenantRegionInfoParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the identifier of a tenant.
   *
   * - `regionIds`: Optional region IDs.
   *
   * - `paginationToken`: This token is passed by the caller to fetch next batch of tenant regions. If this is not passed, then first batch of tenant regions are returned applying all other specified filters.
   *   Use the pagination token returned as a part of the response in the subsequent calls to fetch next batch of tenant regions.
   *
   * - `numRecords`: Optional number of tenant regions to be returned. Maximum 100 records are returned.
   *
   * @return Success
   */
  GetInternalTenantRegionInfo(params: DmsService.GetInternalTenantRegionInfoParams): __Observable<InternalTenantRegions> {
    return this.GetInternalTenantRegionInfoResponse(params).pipe(
      __map(_r => _r.body as InternalTenantRegions)
    );
  }

  /**
   * An endpoint to get the list of standby clusters and their current provision status
   *
   * An endpoint to get the list of standby clusters and their current provision status. The list will not return the clusters that are currently being used by DMaaS tenants. Clusters are identified by resource IDs because there will not be a cluster ID before a cluster is formed using the provisioned cluster nodes.
   * @param params The `DmsService.GetStandbyClustersParams` containing the following parameters:
   *
   * - `tenantPlacementLabel`: Optional param if specified fetches the clusters matching the tenant placement label.
   *
   * - `resourceId`: Resource ID is the identifier of a DMaaS cluster to filter the response
   *
   * - `regionId`: Region ID to filter the response
   *
   * - `dataplaneAccountId`: Dataplane aws account ID to filter the response
   *
   * @return Success
   */
  GetStandbyClustersResponse(params: DmsService.GetStandbyClustersParams): __Observable<__StrictHttpResponse<StandbyClusters>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantPlacementLabel != null) __params = __params.set('tenantPlacementLabel', params.tenantPlacementLabel.toString());
    if (params.resourceId != null) __params = __params.set('resourceId', params.resourceId.toString());
    if (params.regionId != null) __params = __params.set('regionId', params.regionId.toString());
    if (params.dataplaneAccountId != null) __params = __params.set('dataplaneAccountId', params.dataplaneAccountId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/dms/internal/standby-clusters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StandbyClusters>;
      })
    );
  }
  /**
   * An endpoint to get the list of standby clusters and their current provision status
   *
   * An endpoint to get the list of standby clusters and their current provision status. The list will not return the clusters that are currently being used by DMaaS tenants. Clusters are identified by resource IDs because there will not be a cluster ID before a cluster is formed using the provisioned cluster nodes.
   * @param params The `DmsService.GetStandbyClustersParams` containing the following parameters:
   *
   * - `tenantPlacementLabel`: Optional param if specified fetches the clusters matching the tenant placement label.
   *
   * - `resourceId`: Resource ID is the identifier of a DMaaS cluster to filter the response
   *
   * - `regionId`: Region ID to filter the response
   *
   * - `dataplaneAccountId`: Dataplane aws account ID to filter the response
   *
   * @return Success
   */
  GetStandbyClusters(params: DmsService.GetStandbyClustersParams): __Observable<StandbyClusters> {
    return this.GetStandbyClustersResponse(params).pipe(
      __map(_r => _r.body as StandbyClusters)
    );
  }

  /**
   * An endpoint to get add a standby DMaaS cluster in a given region
   *
   * An endpoint to add a standby DMaaS cluster to a given region. The input params include the size of the cluster i.e. the number of nodes to be provisioned the cluster with.
   * @param body Specifies the parameters to add a standby cluster
   * @return Success
   */
  AddStandbyClusterResponse(body: AddStandbyClusterRequest): __Observable<__StrictHttpResponse<StandbyClusterInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/dms/internal/standby-clusters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StandbyClusterInfo>;
      })
    );
  }
  /**
   * An endpoint to get add a standby DMaaS cluster in a given region
   *
   * An endpoint to add a standby DMaaS cluster to a given region. The input params include the size of the cluster i.e. the number of nodes to be provisioned the cluster with.
   * @param body Specifies the parameters to add a standby cluster
   * @return Success
   */
  AddStandbyCluster(body: AddStandbyClusterRequest): __Observable<StandbyClusterInfo> {
    return this.AddStandbyClusterResponse(body).pipe(
      __map(_r => _r.body as StandbyClusterInfo)
    );
  }

  /**
   * Delete a standby-cluster with given resource Id
   *
   * Delete a standby-cluster which is either fully provisioned (good cluster) or partially provisioned. It will cleanup all the EC2 instances, EBS volumes etc. related to that cluster
   * @param body Specifies the parameters to delete a standby-cluster
   * @return Success
   */
  DeleteStandbyClusterResponse(body: DeleteStandbyClusterRequest): __Observable<__StrictHttpResponse<StandbyClusterInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/dms/internal/standby-clusters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StandbyClusterInfo>;
      })
    );
  }
  /**
   * Delete a standby-cluster with given resource Id
   *
   * Delete a standby-cluster which is either fully provisioned (good cluster) or partially provisioned. It will cleanup all the EC2 instances, EBS volumes etc. related to that cluster
   * @param body Specifies the parameters to delete a standby-cluster
   * @return Success
   */
  DeleteStandbyCluster(body: DeleteStandbyClusterRequest): __Observable<StandbyClusterInfo> {
    return this.DeleteStandbyClusterResponse(body).pipe(
      __map(_r => _r.body as StandbyClusterInfo)
    );
  }

  /**
   * An endpoint to notify DMS about low standby cluster count.
   *
   * An endpoint to notify DMS about low standby cluster count for given region and cluster type.
   * @param body Specifies the parameters to notify low standby cluster count.
   * @return Success
   */
  HandleLowStandbyClusterCountResponse(body: LowStandbyClusterCountParams): __Observable<__StrictHttpResponse<LowStandbyClusterCountNotifResponseInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/dms/internal/notify/low-standby-cluster-count`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LowStandbyClusterCountNotifResponseInfo>;
      })
    );
  }
  /**
   * An endpoint to notify DMS about low standby cluster count.
   *
   * An endpoint to notify DMS about low standby cluster count for given region and cluster type.
   * @param body Specifies the parameters to notify low standby cluster count.
   * @return Success
   */
  HandleLowStandbyClusterCount(body: LowStandbyClusterCountParams): __Observable<LowStandbyClusterCountNotifResponseInfo> {
    return this.HandleLowStandbyClusterCountResponse(body).pipe(
      __map(_r => _r.body as LowStandbyClusterCountNotifResponseInfo)
    );
  }

  /**
   * An endpoint to get the list of DMaaS cluster locks.
   *
   * An endpoint to get the list of DMaaS cluster locks. The DMaaS cluster id is an optional input parameter. If provided, only the lock associated with the cluster will be in the response.
   * @param clusterId ID of the DMaaS cluster whose lock is to be fetched
   * @return Response of fetch dmaas cluster locks call.
   */
  GetClusterLocksResponse(clusterId?: number): __Observable<__StrictHttpResponse<DmaasClusterLocks>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (clusterId != null) __params = __params.set('clusterId', clusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/dms/internal/clusters/lock`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DmaasClusterLocks>;
      })
    );
  }
  /**
   * An endpoint to get the list of DMaaS cluster locks.
   *
   * An endpoint to get the list of DMaaS cluster locks. The DMaaS cluster id is an optional input parameter. If provided, only the lock associated with the cluster will be in the response.
   * @param clusterId ID of the DMaaS cluster whose lock is to be fetched
   * @return Response of fetch dmaas cluster locks call.
   */
  GetClusterLocks(clusterId?: number): __Observable<DmaasClusterLocks> {
    return this.GetClusterLocksResponse(clusterId).pipe(
      __map(_r => _r.body as DmaasClusterLocks)
    );
  }

  /**
   * An endpoint to lock a DMaaS cluster.
   *
   * An endpoint to lock a DMaaS cluster. Input parameters are unique lock ID and the cluster id.
   * @param body Specifies the parameters to lock a DMaaS cluster.
   * @return Response of lock a DMaaS cluster call.
   */
  LockClusterResponse(body: LockDmaasClusterRequest): __Observable<__StrictHttpResponse<LockDmaasClusterResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/dms/internal/clusters/lock`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LockDmaasClusterResult>;
      })
    );
  }
  /**
   * An endpoint to lock a DMaaS cluster.
   *
   * An endpoint to lock a DMaaS cluster. Input parameters are unique lock ID and the cluster id.
   * @param body Specifies the parameters to lock a DMaaS cluster.
   * @return Response of lock a DMaaS cluster call.
   */
  LockCluster(body: LockDmaasClusterRequest): __Observable<LockDmaasClusterResult> {
    return this.LockClusterResponse(body).pipe(
      __map(_r => _r.body as LockDmaasClusterResult)
    );
  }

  /**
   * An endpoint to unlock a DMaaS cluster.
   *
   * An endpoint to unlock a DMaaS cluster. Input parameters are unique lock ID and the cluster id.
   * @param body Specifies the parameters to lock a DMaaS cluster.
   * @return Response of unlock a DMaaS cluster call.
   */
  UnlockClusterResponse(body: UnlockDmaasClusterRequest): __Observable<__StrictHttpResponse<UnlockDmaasClusterResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/dms/internal/clusters/lock`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UnlockDmaasClusterResult>;
      })
    );
  }
  /**
   * An endpoint to unlock a DMaaS cluster.
   *
   * An endpoint to unlock a DMaaS cluster. Input parameters are unique lock ID and the cluster id.
   * @param body Specifies the parameters to lock a DMaaS cluster.
   * @return Response of unlock a DMaaS cluster call.
   */
  UnlockCluster(body: UnlockDmaasClusterRequest): __Observable<UnlockDmaasClusterResult> {
    return this.UnlockClusterResponse(body).pipe(
      __map(_r => _r.body as UnlockDmaasClusterResult)
    );
  }

  /**
   * An endpoint to update values of all the supplied gflags for a given service on a given clusterId.
   *
   * An endpoint to update values of gflags on a cluster. The input params include clusterId, name of the service and array of gflag names with their to be set values.
   * @param body Specifies the parameters to update gflags on cluster.
   * @return Success
   */
  UpdateClusterGflagsResponse(body: UpdateServiceGflagsRequest): __Observable<__StrictHttpResponse<ServiceGflags>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/dms/internal/clusters/gflags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ServiceGflags>;
      })
    );
  }
  /**
   * An endpoint to update values of all the supplied gflags for a given service on a given clusterId.
   *
   * An endpoint to update values of gflags on a cluster. The input params include clusterId, name of the service and array of gflag names with their to be set values.
   * @param body Specifies the parameters to update gflags on cluster.
   * @return Success
   */
  UpdateClusterGflags(body: UpdateServiceGflagsRequest): __Observable<ServiceGflags> {
    return this.UpdateClusterGflagsResponse(body).pipe(
      __map(_r => _r.body as ServiceGflags)
    );
  }

  /**
   * An endpoint to get values of all the gflags set on a cluster
   *
   * An endpoint to list all the gflags set on a cluster with their values. Cluster will be identified by its cluster ID
   * @param params The `DmsService.GetClusterGflagsParams` containing the following parameters:
   *
   * - `serviceName`: Optional parameter to filter the gflags based on the service they belong to.
   *
   * - `clusterId`: Id of the DMaaS cluster
   *
   * @return Success
   */
  GetClusterGflagsResponse(params: DmsService.GetClusterGflagsParams): __Observable<__StrictHttpResponse<GetClusterGflagsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.serviceName != null) __params = __params.set('serviceName', params.serviceName.toString());
    if (params.clusterId != null) __params = __params.set('clusterId', params.clusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/dms/internal/clusters/gflags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetClusterGflagsResponse>;
      })
    );
  }
  /**
   * An endpoint to get values of all the gflags set on a cluster
   *
   * An endpoint to list all the gflags set on a cluster with their values. Cluster will be identified by its cluster ID
   * @param params The `DmsService.GetClusterGflagsParams` containing the following parameters:
   *
   * - `serviceName`: Optional parameter to filter the gflags based on the service they belong to.
   *
   * - `clusterId`: Id of the DMaaS cluster
   *
   * @return Success
   */
  GetClusterGflags(params: DmsService.GetClusterGflagsParams): __Observable<GetClusterGflagsResponse> {
    return this.GetClusterGflagsResponse(params).pipe(
      __map(_r => _r.body as GetClusterGflagsResponse)
    );
  }

  /**
   * An endpoint to add nodes to a DMaaS cluster.
   *
   * An endpoint to perform add nodes to a DMaaS cluster.Input parameters are resource ID/clusterID and number of nodes. Either cluster Id or resource Id should be provided.
   * @param body Specifies the parameters to add nodes to a DMaaS cluster.
   * @return Success
   */
  AddNodesToDMaaSClusterResponse(body: AddNodesToDmaasClusterRequest): __Observable<__StrictHttpResponse<DmaasClusterNodesInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/dms/internal/cluster/nodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DmaasClusterNodesInfo>;
      })
    );
  }
  /**
   * An endpoint to add nodes to a DMaaS cluster.
   *
   * An endpoint to perform add nodes to a DMaaS cluster.Input parameters are resource ID/clusterID and number of nodes. Either cluster Id or resource Id should be provided.
   * @param body Specifies the parameters to add nodes to a DMaaS cluster.
   * @return Success
   */
  AddNodesToDMaaSCluster(body: AddNodesToDmaasClusterRequest): __Observable<DmaasClusterNodesInfo> {
    return this.AddNodesToDMaaSClusterResponse(body).pipe(
      __map(_r => _r.body as DmaasClusterNodesInfo)
    );
  }

  /**
   * An endpoint to remove nodes from a DMaaS cluster.
   *
   * An endpoint to remove nodes from a DMaaS cluster. Input parameters are AwsEc2InstanceIDs/NodeIDs and ClusterID. Either NodeID or awsEc2InstanceID should be provided.
   * @param body Specifies the parameters to remove nodes from DMaaS cluster.
   * @return Success
   */
  RemoveNodesFromDMaaSClusterResponse(body: RemoveNodesFromDmaasClusterRequest): __Observable<__StrictHttpResponse<DmaasClusterNodesInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/dms/internal/cluster/nodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DmaasClusterNodesInfo>;
      })
    );
  }
  /**
   * An endpoint to remove nodes from a DMaaS cluster.
   *
   * An endpoint to remove nodes from a DMaaS cluster. Input parameters are AwsEc2InstanceIDs/NodeIDs and ClusterID. Either NodeID or awsEc2InstanceID should be provided.
   * @param body Specifies the parameters to remove nodes from DMaaS cluster.
   * @return Success
   */
  RemoveNodesFromDMaaSCluster(body: RemoveNodesFromDmaasClusterRequest): __Observable<DmaasClusterNodesInfo> {
    return this.RemoveNodesFromDMaaSClusterResponse(body).pipe(
      __map(_r => _r.body as DmaasClusterNodesInfo)
    );
  }

  /**
   * An endpoint to get status of AddNodes/RemoveNodes operation.
   *
   * An endpoint to get status of AddNodes/RemoveNodes operation.
   * @param body Specifies the parameters to get AddNodes/RemoveNodes operation.
   * @return Success
   */
  GetNodesStatusResponse(body: GetDmaasClusterNodesStatusRequest): __Observable<__StrictHttpResponse<DmaasClusterNodesInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/dms/internal/cluster/nodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DmaasClusterNodesInfo>;
      })
    );
  }
  /**
   * An endpoint to get status of AddNodes/RemoveNodes operation.
   *
   * An endpoint to get status of AddNodes/RemoveNodes operation.
   * @param body Specifies the parameters to get AddNodes/RemoveNodes operation.
   * @return Success
   */
  GetNodesStatus(body: GetDmaasClusterNodesStatusRequest): __Observable<DmaasClusterNodesInfo> {
    return this.GetNodesStatusResponse(body).pipe(
      __map(_r => _r.body as DmaasClusterNodesInfo)
    );
  }

  /**
   * Endpoint that returns list of resource tags assigned to the cluster mapped to the given tenant in the region.
   *
   * Endpoint to get resource tags for a given tenant in a region.
   * @param body Specifies the parameters for get resource tags api.
   * @return Success
   */
  GetAllResourceTagsForTenantRegionResponse(body: GetAllResourceTagsRequest): __Observable<__StrictHttpResponse<ResourceTags>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/private/dms/internal/audit/resource-tags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResourceTags>;
      })
    );
  }
  /**
   * Endpoint that returns list of resource tags assigned to the cluster mapped to the given tenant in the region.
   *
   * Endpoint to get resource tags for a given tenant in a region.
   * @param body Specifies the parameters for get resource tags api.
   * @return Success
   */
  GetAllResourceTagsForTenantRegion(body: GetAllResourceTagsRequest): __Observable<ResourceTags> {
    return this.GetAllResourceTagsForTenantRegionResponse(body).pipe(
      __map(_r => _r.body as ResourceTags)
    );
  }

  /**
   * Endpoint to update resource tags assigned to the cluster mapped to the given tenant in the region.
   *
   * Endpoint to update resource tags for a given cluster. This api is used internally to update tags on existing clusters only.
   * @param body Specifies the params for update resource tags api.
   * @return Success
   */
  UpdateResourceTagsForClusterResponse(body: UpdateResourceTagsRequest): __Observable<__StrictHttpResponse<DmsSuccess>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/private/dms/internal/audit/resource-tags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DmsSuccess>;
      })
    );
  }
  /**
   * Endpoint to update resource tags assigned to the cluster mapped to the given tenant in the region.
   *
   * Endpoint to update resource tags for a given cluster. This api is used internally to update tags on existing clusters only.
   * @param body Specifies the params for update resource tags api.
   * @return Success
   */
  UpdateResourceTagsForCluster(body: UpdateResourceTagsRequest): __Observable<DmsSuccess> {
    return this.UpdateResourceTagsForClusterResponse(body).pipe(
      __map(_r => _r.body as DmsSuccess)
    );
  }

  /**
   * Get the list of internal RPaaS regions for a helios account and tenant.
   *
   * Get the list of internal RPaaS regions info for the given helios account and tenant.
   * @param params The `DmsService.GetInternalRpaasRegionsParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the identifier of a tenant
   *
   * - `regionIds`: List of region IDs to filter the response.
   *
   * - `paginationToken`: This token is passed by the caller to fetch next batch of RPaaS regions. If this is not passed, then first batch of RPaaS regions are returned applying all other specified filters.
   *   Use the pagination token returned as a part of the response in the subsequent calls to fetch next batch of RPaaS regions.
   *
   * - `numRecords`: Optional number of RPaaS regions to be returned. Maximum 100 records are returned.
   *
   * - `accountId`: Specifies the identifier of a helios account.
   *
   * @return Success
   */
  GetInternalRpaasRegionsResponse(params: DmsService.GetInternalRpaasRegionsParams): __Observable<__StrictHttpResponse<InternalRpaasRegions>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    (params.regionIds || []).forEach(val => {if (val != null) __params = __params.append('regionIds', val.toString())});
    if (params.paginationToken != null) __params = __params.set('paginationToken', params.paginationToken.toString());
    if (params.numRecords != null) __params = __params.set('numRecords', params.numRecords.toString());
    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/dms/internal/rpaas/regions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<InternalRpaasRegions>;
      })
    );
  }
  /**
   * Get the list of internal RPaaS regions for a helios account and tenant.
   *
   * Get the list of internal RPaaS regions info for the given helios account and tenant.
   * @param params The `DmsService.GetInternalRpaasRegionsParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the identifier of a tenant
   *
   * - `regionIds`: List of region IDs to filter the response.
   *
   * - `paginationToken`: This token is passed by the caller to fetch next batch of RPaaS regions. If this is not passed, then first batch of RPaaS regions are returned applying all other specified filters.
   *   Use the pagination token returned as a part of the response in the subsequent calls to fetch next batch of RPaaS regions.
   *
   * - `numRecords`: Optional number of RPaaS regions to be returned. Maximum 100 records are returned.
   *
   * - `accountId`: Specifies the identifier of a helios account.
   *
   * @return Success
   */
  GetInternalRpaasRegions(params: DmsService.GetInternalRpaasRegionsParams): __Observable<InternalRpaasRegions> {
    return this.GetInternalRpaasRegionsResponse(params).pipe(
      __map(_r => _r.body as InternalRpaasRegions)
    );
  }

  /**
   * An endpoint to perform cloud remove nodes operation.
   *
   * An endpoint to perform Cloud remove nodes operation. Input parameters are nodeIDs/awsEc2InstanceIDs and ClusterID/ResourceID. Either NodeID or awsEc2InstanceID and ClusterID or ResourceID should be provided.
   * @param body Specifies the parameters to remove cloud nodes operation.
   * @return Success
   */
  RemoveCloudNodesFromDmaasClusterResponse(body: RemoveCloudNodesFromDmaasClusterRequest): __Observable<__StrictHttpResponse<DmaasClusterNodesInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/dms/internal/cluster/cloud-nodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DmaasClusterNodesInfo>;
      })
    );
  }
  /**
   * An endpoint to perform cloud remove nodes operation.
   *
   * An endpoint to perform Cloud remove nodes operation. Input parameters are nodeIDs/awsEc2InstanceIDs and ClusterID/ResourceID. Either NodeID or awsEc2InstanceID and ClusterID or ResourceID should be provided.
   * @param body Specifies the parameters to remove cloud nodes operation.
   * @return Success
   */
  RemoveCloudNodesFromDmaasCluster(body: RemoveCloudNodesFromDmaasClusterRequest): __Observable<DmaasClusterNodesInfo> {
    return this.RemoveCloudNodesFromDmaasClusterResponse(body).pipe(
      __map(_r => _r.body as DmaasClusterNodesInfo)
    );
  }

  /**
   * An endpoint to get status of RemoveCloudNodes operation.
   *
   * An endpoint to get status of RemoveCloudNodes operation.
   * @param body Specifies the parameters to get RemoveCloudNodes operation.
   * @return Success
   */
  GetRemoveCloudNodesStatusResponse(body: GetCloudNodesStatusRequest): __Observable<__StrictHttpResponse<DmaasClusterNodesInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/dms/internal/cluster/cloud-nodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DmaasClusterNodesInfo>;
      })
    );
  }
  /**
   * An endpoint to get status of RemoveCloudNodes operation.
   *
   * An endpoint to get status of RemoveCloudNodes operation.
   * @param body Specifies the parameters to get RemoveCloudNodes operation.
   * @return Success
   */
  GetRemoveCloudNodesStatus(body: GetCloudNodesStatusRequest): __Observable<DmaasClusterNodesInfo> {
    return this.GetRemoveCloudNodesStatusResponse(body).pipe(
      __map(_r => _r.body as DmaasClusterNodesInfo)
    );
  }

  /**
   * Get the status of migrate tenant request
   *
   * Get the status of migrate tenant request either using the operation id, or using all of tenant id, region id and shard id
   * @param params The `DmsService.GetMigrateTenantParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the identifier of a tenant.
   *
   * - `shardId`: ID of the tenant region shard which is being migrated
   *
   * - `regionId`: Region to which tenant belongs to
   *
   * - `operationId`: Operation id of the migrate tenant request.
   *
   * @return Success
   */
  GetMigrateTenantResponse(params: DmsService.GetMigrateTenantParams): __Observable<__StrictHttpResponse<MigrateTenantInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.shardId != null) __params = __params.set('shardId', params.shardId.toString());
    if (params.regionId != null) __params = __params.set('regionId', params.regionId.toString());
    if (params.operationId != null) __params = __params.set('operationId', params.operationId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/private/dms/migrate-tenant`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MigrateTenantInfo>;
      })
    );
  }
  /**
   * Get the status of migrate tenant request
   *
   * Get the status of migrate tenant request either using the operation id, or using all of tenant id, region id and shard id
   * @param params The `DmsService.GetMigrateTenantParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the identifier of a tenant.
   *
   * - `shardId`: ID of the tenant region shard which is being migrated
   *
   * - `regionId`: Region to which tenant belongs to
   *
   * - `operationId`: Operation id of the migrate tenant request.
   *
   * @return Success
   */
  GetMigrateTenant(params: DmsService.GetMigrateTenantParams): __Observable<MigrateTenantInfo> {
    return this.GetMigrateTenantResponse(params).pipe(
      __map(_r => _r.body as MigrateTenantInfo)
    );
  }

  /**
   * An endpoint to migrate tenant from one DMaaS cluster to another DMaaS cluster
   *
   * An endpoint to migrate tenant from one DMaaS cluster to another. The parameters are tenant id and clusterID.
   * @param body Specifies the parameters to migrate tenant from a DMaaS cluster.
   * @return Response to migrate tenant from one DMaaS cluster to another.
   */
  MigrateTenantResponse(body: MigrateTenantReq): __Observable<__StrictHttpResponse<MigrateTenantInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/private/dms/migrate-tenant`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MigrateTenantInfo>;
      })
    );
  }
  /**
   * An endpoint to migrate tenant from one DMaaS cluster to another DMaaS cluster
   *
   * An endpoint to migrate tenant from one DMaaS cluster to another. The parameters are tenant id and clusterID.
   * @param body Specifies the parameters to migrate tenant from a DMaaS cluster.
   * @return Response to migrate tenant from one DMaaS cluster to another.
   */
  MigrateTenant(body: MigrateTenantReq): __Observable<MigrateTenantInfo> {
    return this.MigrateTenantResponse(body).pipe(
      __map(_r => _r.body as MigrateTenantInfo)
    );
  }

  /**
   * An endpoint to resume/retry migration of a tenant from one DMaaS cluster to another DMaaS cluster.
   *
   * An endpoint to resume/retry migration of a tenant from one DMaaS cluster to another DMaaS cluster.
   * @param params The `DmsService.ResumeMigrateTenantParams` containing the following parameters:
   *
   * - `skipSourceRegistration`: Skip source registration if the field is set.
   *
   * - `skipCloudDomainMigration`: Skip cloud domain migration if the field is set.
   *
   * - `operationId`: Operation Id related to the earlier failed migrate tenant task.
   *
   * @return Response to migrate tenant from one DMaaS cluster to another.
   */
  ResumeMigrateTenantResponse(params: DmsService.ResumeMigrateTenantParams): __Observable<__StrictHttpResponse<MigrateTenantInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.skipSourceRegistration != null) __params = __params.set('skipSourceRegistration', params.skipSourceRegistration.toString());
    if (params.skipCloudDomainMigration != null) __params = __params.set('skipCloudDomainMigration', params.skipCloudDomainMigration.toString());
    if (params.operationId != null) __params = __params.set('operationId', params.operationId.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/private/dms/migrate-tenant`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MigrateTenantInfo>;
      })
    );
  }
  /**
   * An endpoint to resume/retry migration of a tenant from one DMaaS cluster to another DMaaS cluster.
   *
   * An endpoint to resume/retry migration of a tenant from one DMaaS cluster to another DMaaS cluster.
   * @param params The `DmsService.ResumeMigrateTenantParams` containing the following parameters:
   *
   * - `skipSourceRegistration`: Skip source registration if the field is set.
   *
   * - `skipCloudDomainMigration`: Skip cloud domain migration if the field is set.
   *
   * - `operationId`: Operation Id related to the earlier failed migrate tenant task.
   *
   * @return Response to migrate tenant from one DMaaS cluster to another.
   */
  ResumeMigrateTenant(params: DmsService.ResumeMigrateTenantParams): __Observable<MigrateTenantInfo> {
    return this.ResumeMigrateTenantResponse(params).pipe(
      __map(_r => _r.body as MigrateTenantInfo)
    );
  }

  /**
   * Get the status of cluster rebuild
   *
   * Get the status of cluster rebuild operation
   * @param params The `DmsService.GetClusterRebuildParams` containing the following parameters:
   *
   * - `regionId`: Region to which cluster belongs to
   *
   * - `operationId`: Optionally query using operation id. Client can use a combination of clusterId and regionId or operation Id. Using operation id is better to identify the request. The operation id will be obtained as part of post request.
   *
   * - `clusterId`: ID of the DMaaS cluster which has to be rebuilt
   *
   * @return Success
   */
  GetClusterRebuildResponse(params: DmsService.GetClusterRebuildParams): __Observable<__StrictHttpResponse<ClusterRebuildInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __params = __params.set('regionId', params.regionId.toString());
    if (params.operationId != null) __params = __params.set('operationId', params.operationId.toString());
    if (params.clusterId != null) __params = __params.set('clusterId', params.clusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/private/dms/cluster-rebuild`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterRebuildInfo>;
      })
    );
  }
  /**
   * Get the status of cluster rebuild
   *
   * Get the status of cluster rebuild operation
   * @param params The `DmsService.GetClusterRebuildParams` containing the following parameters:
   *
   * - `regionId`: Region to which cluster belongs to
   *
   * - `operationId`: Optionally query using operation id. Client can use a combination of clusterId and regionId or operation Id. Using operation id is better to identify the request. The operation id will be obtained as part of post request.
   *
   * - `clusterId`: ID of the DMaaS cluster which has to be rebuilt
   *
   * @return Success
   */
  GetClusterRebuild(params: DmsService.GetClusterRebuildParams): __Observable<ClusterRebuildInfo> {
    return this.GetClusterRebuildResponse(params).pipe(
      __map(_r => _r.body as ClusterRebuildInfo)
    );
  }

  /**
   * An endpoint to rebuild the DMaaS cluster. The cluster will be rebuilt on a new cluster.
   *
   * An endpoint to rebuild the cluster. The parameters are clusterID and region in which the cluster belongs to
   * @param body Specifies the parameters to rebuild DMaaS cluster.
   * @return Response to rebuild the cluster to a new DMaaS cluster
   */
  ClusterRebuildResponse(body: ClusterRebuildRequest): __Observable<__StrictHttpResponse<ClusterRebuildInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/private/dms/cluster-rebuild`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterRebuildInfo>;
      })
    );
  }
  /**
   * An endpoint to rebuild the DMaaS cluster. The cluster will be rebuilt on a new cluster.
   *
   * An endpoint to rebuild the cluster. The parameters are clusterID and region in which the cluster belongs to
   * @param body Specifies the parameters to rebuild DMaaS cluster.
   * @return Response to rebuild the cluster to a new DMaaS cluster
   */
  ClusterRebuild(body: ClusterRebuildRequest): __Observable<ClusterRebuildInfo> {
    return this.ClusterRebuildResponse(body).pipe(
      __map(_r => _r.body as ClusterRebuildInfo)
    );
  }

  /**
   * Force remove Cohesity DMaaS service from a given region for the given tenant.
   *
   * Force remove Cohesity DMaaS service from a given region for the given tenant. This deletes tenant region info and KMS key corresponding to the tenant and region.
   * @param body Specifies the parameters for removing DMaaS service in a region.
   * @return Success
   */
  ForceRemoveTenantRegionResponse(body: CleanupTenantRegionRequest): __Observable<__StrictHttpResponse<DmsSuccess>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/private/dms/tenants/regions/cleanup`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DmsSuccess>;
      })
    );
  }
  /**
   * Force remove Cohesity DMaaS service from a given region for the given tenant.
   *
   * Force remove Cohesity DMaaS service from a given region for the given tenant. This deletes tenant region info and KMS key corresponding to the tenant and region.
   * @param body Specifies the parameters for removing DMaaS service in a region.
   * @return Success
   */
  ForceRemoveTenantRegion(body: CleanupTenantRegionRequest): __Observable<DmsSuccess> {
    return this.ForceRemoveTenantRegionResponse(body).pipe(
      __map(_r => _r.body as DmsSuccess)
    );
  }

  /**
   * An endpoint to change group of the given cluster IDs.
   *
   * An endpoint to change group of the given cluster IDs. If specified group does not exist, a new group is created.
   * @param params The `DmsService.ChangeClustersGroupParams` containing the following parameters:
   *
   * - `clusterGroupId`: ID of the cluster group to move the clusters.
   *
   * - `clusterIds`: IDs of the clusters to be moved to a different group.
   *
   * @return Response of change clusters group call. Returns updated information of the clusters.
   */
  ChangeClustersGroupResponse(params: DmsService.ChangeClustersGroupParams): __Observable<__StrictHttpResponse<Clusters>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.clusterGroupId != null) __params = __params.set('clusterGroupId', params.clusterGroupId.toString());
    (params.clusterIds || []).forEach(val => {if (val != null) __params = __params.append('clusterIds', val.toString())});
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/private/dms/internal/clusters/change-group`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Clusters>;
      })
    );
  }
  /**
   * An endpoint to change group of the given cluster IDs.
   *
   * An endpoint to change group of the given cluster IDs. If specified group does not exist, a new group is created.
   * @param params The `DmsService.ChangeClustersGroupParams` containing the following parameters:
   *
   * - `clusterGroupId`: ID of the cluster group to move the clusters.
   *
   * - `clusterIds`: IDs of the clusters to be moved to a different group.
   *
   * @return Response of change clusters group call. Returns updated information of the clusters.
   */
  ChangeClustersGroup(params: DmsService.ChangeClustersGroupParams): __Observable<Clusters> {
    return this.ChangeClustersGroupResponse(params).pipe(
      __map(_r => _r.body as Clusters)
    );
  }

  /**
   * An endpoint to set DMaaS info in cluster config of the given cluster IDs or the group.
   *
   * An endpoint to set DMaaS info of the given cluster IDs. If only the group ID is specified, DMaaS info all clusters within the group is set.
   * @param params The `DmsService.SetDMaaSInfoParams` containing the following parameters:
   *
   * - `groupId`: ID of the cluster group.
   *
   * - `clusterIds`: IDs of the clusters.
   *
   * @return Response to update DMaaS info in cluster config API call.
   */
  SetDMaaSInfoResponse(params: DmsService.SetDMaaSInfoParams): __Observable<__StrictHttpResponse<UpdateDmaasInfoInClusterConfigResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.groupId != null) __params = __params.set('groupId', params.groupId.toString());
    (params.clusterIds || []).forEach(val => {if (val != null) __params = __params.append('clusterIds', val.toString())});
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/private/dms/internal/clusters/update-dmaas-info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpdateDmaasInfoInClusterConfigResult>;
      })
    );
  }
  /**
   * An endpoint to set DMaaS info in cluster config of the given cluster IDs or the group.
   *
   * An endpoint to set DMaaS info of the given cluster IDs. If only the group ID is specified, DMaaS info all clusters within the group is set.
   * @param params The `DmsService.SetDMaaSInfoParams` containing the following parameters:
   *
   * - `groupId`: ID of the cluster group.
   *
   * - `clusterIds`: IDs of the clusters.
   *
   * @return Response to update DMaaS info in cluster config API call.
   */
  SetDMaaSInfo(params: DmsService.SetDMaaSInfoParams): __Observable<UpdateDmaasInfoInClusterConfigResult> {
    return this.SetDMaaSInfoResponse(params).pipe(
      __map(_r => _r.body as UpdateDmaasInfoInClusterConfigResult)
    );
  }

  /**
   * Update version specific default gflags for a given cluster ID
   *
   * Update default gflags specific to the cluster version for a given cluster ID. The cluster version is fetched from the cluster
   * @param body Specifies the parameters for removing DMaaS service in a region.
   * @return Success
   */
  UpdateDefaultGflagsResponse(body: UpdateDefaultGflagsRequest): __Observable<__StrictHttpResponse<DmsSuccess>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/private/dms/internal/clusters/update-default-gflags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DmsSuccess>;
      })
    );
  }
  /**
   * Update version specific default gflags for a given cluster ID
   *
   * Update default gflags specific to the cluster version for a given cluster ID. The cluster version is fetched from the cluster
   * @param body Specifies the parameters for removing DMaaS service in a region.
   * @return Success
   */
  UpdateDefaultGflags(body: UpdateDefaultGflagsRequest): __Observable<DmsSuccess> {
    return this.UpdateDefaultGflagsResponse(body).pipe(
      __map(_r => _r.body as DmsSuccess)
    );
  }

  /**
   * An endpoint to create the indexing cloud config for a given tenant and region
   *
   * An endpoint to create an indexing config for the given tenant and region. The input params include the tenant id and region id.
   * @param body Specifies the parameters to add a config.
   * @return Success
   */
  AddIndexingConfigResponse(body: AddIndexingConfigRequest): __Observable<__StrictHttpResponse<IndexingConfigInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/private/dms/tenants/indexingConfig`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IndexingConfigInfo>;
      })
    );
  }
  /**
   * An endpoint to create the indexing cloud config for a given tenant and region
   *
   * An endpoint to create an indexing config for the given tenant and region. The input params include the tenant id and region id.
   * @param body Specifies the parameters to add a config.
   * @return Success
   */
  AddIndexingConfig(body: AddIndexingConfigRequest): __Observable<IndexingConfigInfo> {
    return this.AddIndexingConfigResponse(body).pipe(
      __map(_r => _r.body as IndexingConfigInfo)
    );
  }

  /**
   * An endpoint to fix/remove explicit policies created on ODP role for the purpose of AWS source registration.
   *
   * An endpoint to fix/remove explicit policies created on ODP role for the purpose of AWS source registration.
   */
  MigrateAwsCloudSourcePolicyResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/private/dms/awsCloudSource/fixPolicies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * An endpoint to fix/remove explicit policies created on ODP role for the purpose of AWS source registration.
   *
   * An endpoint to fix/remove explicit policies created on ODP role for the purpose of AWS source registration.
   */
  MigrateAwsCloudSourcePolicy(): __Observable<null> {
    return this.MigrateAwsCloudSourcePolicyResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get the info of all deleted tenant regions for which post deletion tasks are pending
   *
   * Get all the deleted tenant regions for which post deletion tasks are pending
   * @param postDeletionStatus Filter the results by deletion status.
   * @return Success
   */
  GetDeletedTenantRegionsResponse(postDeletionStatus?: null | string): __Observable<__StrictHttpResponse<DeletedTenantRegions>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (postDeletionStatus != null) __params = __params.set('postDeletionStatus', postDeletionStatus.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/private/dms/deleted-tenants/regions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DeletedTenantRegions>;
      })
    );
  }
  /**
   * Get the info of all deleted tenant regions for which post deletion tasks are pending
   *
   * Get all the deleted tenant regions for which post deletion tasks are pending
   * @param postDeletionStatus Filter the results by deletion status.
   * @return Success
   */
  GetDeletedTenantRegions(postDeletionStatus?: null | string): __Observable<DeletedTenantRegions> {
    return this.GetDeletedTenantRegionsResponse(postDeletionStatus).pipe(
      __map(_r => _r.body as DeletedTenantRegions)
    );
  }

  /**
   * Update the status of a post deletion task for a deleted tenant region
   *
   * Update the post deletion task status for a tenant region
   * @param body Specifies the parameters to update the deletion status for a tenant region
   */
  UpdateDeletedRegionStatusResponse(body: UpdateDeletedRegionStatusRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/private/dms/deleted-tenants/regions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update the status of a post deletion task for a deleted tenant region
   *
   * Update the post deletion task status for a tenant region
   * @param body Specifies the parameters to update the deletion status for a tenant region
   */
  UpdateDeletedRegionStatus(body: UpdateDeletedRegionStatusRequest): __Observable<null> {
    return this.UpdateDeletedRegionStatusResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update AWS service quota limits for dataplane accounts.
   *
   * An endpoint to fetch AWS service quota limits for all dataplane
   * accounts and update aws account limits table in postgres.
   * The API que
   */
  RefreshAWSDataplaneAccountLimitsResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/private/dms/internal/awslimits/refresh`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update AWS service quota limits for dataplane accounts.
   *
   * An endpoint to fetch AWS service quota limits for all dataplane
   * accounts and update aws account limits table in postgres.
   * The API que
   */
  RefreshAWSDataplaneAccountLimits(): __Observable<null> {
    return this.RefreshAWSDataplaneAccountLimitsResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Check if a standby dataplane account needs to be toggled.
   *
   * An endpoint to check the current utilization of all dataplane cloud
   * accounts and if the utilization is more than a given threshold, filp
   * one of the standby accounts to not standby and as a result DMS
   * will start using it for provisioning new clusters.
   */
  CheckStandbyAccountLimitsResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/private/dms/internal/awslimits/checkStandbyAccounts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Check if a standby dataplane account needs to be toggled.
   *
   * An endpoint to check the current utilization of all dataplane cloud
   * accounts and if the utilization is more than a given threshold, filp
   * one of the standby accounts to not standby and as a result DMS
   * will start using it for provisioning new clusters.
   */
  CheckStandbyAccountLimits(): __Observable<null> {
    return this.CheckStandbyAccountLimitsResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get the details of the tenant region shards satisfying the given parameters.
   *
   * Get the information about the given tenant region shards
   * @param params The `DmsService.GetTenantRegionShardsParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the identifier of a tenant.
   *
   * - `regionId`: Region for which the shards are being requested
   *
   * - `shardIds`: List of shard IDs to filter the response.
   *
   * @return Success
   */
  GetTenantRegionShardsResponse(params: DmsService.GetTenantRegionShardsParams): __Observable<__StrictHttpResponse<GetTenantRegionShardsResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.tenantId != null) __params = __params.set('tenantId', params.tenantId.toString());
    if (params.regionId != null) __params = __params.set('regionId', params.regionId.toString());
    (params.shardIds || []).forEach(val => {if (val != null) __params = __params.append('shardIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/private/dms/tenants/regions/shards`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetTenantRegionShardsResult>;
      })
    );
  }
  /**
   * Get the details of the tenant region shards satisfying the given parameters.
   *
   * Get the information about the given tenant region shards
   * @param params The `DmsService.GetTenantRegionShardsParams` containing the following parameters:
   *
   * - `tenantId`: Specifies the identifier of a tenant.
   *
   * - `regionId`: Region for which the shards are being requested
   *
   * - `shardIds`: List of shard IDs to filter the response.
   *
   * @return Success
   */
  GetTenantRegionShards(params: DmsService.GetTenantRegionShardsParams): __Observable<GetTenantRegionShardsResult> {
    return this.GetTenantRegionShardsResponse(params).pipe(
      __map(_r => _r.body as GetTenantRegionShardsResult)
    );
  }

  /**
   * Provision a secondary shard for the given tenant region.
   *
   * Provision a secondary shard for the tenant region.
   * @param body Specifies the parameters to add a secondary shard for a tenant region
   * @return Success
   */
  AddTenantRegionShardResponse(body: AddTenantRegionShardRequest): __Observable<__StrictHttpResponse<TenantRegionShardInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/private/dms/tenants/regions/shards`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantRegionShardInfo>;
      })
    );
  }
  /**
   * Provision a secondary shard for the given tenant region.
   *
   * Provision a secondary shard for the tenant region.
   * @param body Specifies the parameters to add a secondary shard for a tenant region
   * @return Success
   */
  AddTenantRegionShard(body: AddTenantRegionShardRequest): __Observable<TenantRegionShardInfo> {
    return this.AddTenantRegionShardResponse(body).pipe(
      __map(_r => _r.body as TenantRegionShardInfo)
    );
  }

  /**
   * Delete the given secondary shard for a tenant region
   *
   * Delete the given secondary shard for a tenant region
   * @param body Specifies the parameters for deleting the secondary shard for a tenant region.
   */
  DeleteTenantRegionShardResponse(body: DeleteTenantRegionShardRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/private/dms/tenants/regions/shards`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete the given secondary shard for a tenant region
   *
   * Delete the given secondary shard for a tenant region
   * @param body Specifies the parameters for deleting the secondary shard for a tenant region.
   */
  DeleteTenantRegionShard(body: DeleteTenantRegionShardRequest): __Observable<null> {
    return this.DeleteTenantRegionShardResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Resume the provision of a tenant region secondary shard from the last point of failure.
   *
   * Resume the provision of a tenant region secondary shard from the last point of failure.
   * @param body Specifies the parameters for resuming the provision of the secondary shard for a tenant region
   * @return Success
   */
  ResumeTenantRegionShardProvisionResponse(body: ResumeTenantRegionShardProvisionRequest): __Observable<__StrictHttpResponse<TenantRegionShardInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/private/dms/tenants/regions/shards`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TenantRegionShardInfo>;
      })
    );
  }
  /**
   * Resume the provision of a tenant region secondary shard from the last point of failure.
   *
   * Resume the provision of a tenant region secondary shard from the last point of failure.
   * @param body Specifies the parameters for resuming the provision of the secondary shard for a tenant region
   * @return Success
   */
  ResumeTenantRegionShardProvision(body: ResumeTenantRegionShardProvisionRequest): __Observable<TenantRegionShardInfo> {
    return this.ResumeTenantRegionShardProvisionResponse(body).pipe(
      __map(_r => _r.body as TenantRegionShardInfo)
    );
  }
}

module DmsService {

  /**
   * Parameters for GetTenantRegions
   */
  export interface GetTenantRegionsParams {

    /**
     * Specifies the identifier of a tenant.
     */
    tenantId: string;

    /**
     * List of region IDs to filter the response.
     */
    regionIds?: null | Array<string>;
  }

  /**
   * Parameters for GetTenantKmsKey
   */
  export interface GetTenantKmsKeyParams {

    /**
     * Specifies the identifier of a tenant.
     */
    tenantId: string;

    /**
     * Specifies the identifier of a region.
     */
    regionId: string;

    /**
     * Specifies the identifier of an account.
     */
    accountId: string;

    /**
     * The useCase of the KMS key. By default it is DMaaS.
     */
    useCase?: 'DMaaS' | 'RPaaS';

    /**
     * Specifies FortKnox global vault ID. It is only applicable for getting FortKnox KMS key.
     */
    fortKnoxGlobalVaultId?: string;
  }

  /**
   * Parameters for VerifyTenantAwsCloudSource
   */
  export interface VerifyTenantAwsCloudSourceParams {

    /**
     * ID of the DMaaS tenant.
     */
    tenantId: string;

    /**
     * Id of the region where customer has subscribed to Cohesity DMaaS service.
     */
    destinationRegionId: string;

    /**
     * Tenant's AWS account number used for AWS source registration.
     */
    awsAccountNumber: string;
  }

  /**
   * Parameters for GetTenantAwsCloudSource
   */
  export interface GetTenantAwsCloudSourceParams {

    /**
     * Id of the DMaaS tenant.
     */
    tenantId: string;

    /**
     * The Region Id where the customer has subscribed the cohesity DMaaS service.
     */
    destinationRegionId: string;

    /**
     * The AWS account number of the tenant's AWS account.
     */
    awsAccountNumber: string;
  }

  /**
   * Parameters for GetClustersInfo
   */
  export interface GetClustersInfoParams {

    /**
     * Optional param if specified fetches the clusters matching the tenant placement label.
     */
    tenantPlacementLabel?: null | 'Poc' | 'Production';

    /**
     * Optional region ID to filter the clusters.
     */
    regionId?: null | string;

    /**
     * Optional param if set to true, returns only provisioned clusters. Default is false.
     */
    provisionedOnly?: null | boolean;

    /**
     * Optional param if specified fetches the cluster spec and the cluster create spec.
     */
    fetchClusterSpec?: null | boolean;

    /**
     * Optional cluster IDs to filter the response. If both clusterGroupId and clusterIds are specified, information of clusters within the group is returned.
     */
    clusterIds?: Array<number>;

    /**
     * Optional cluster group ID to filter the response.
     */
    clusterGroupId?: null | string;

    /**
     * Optional cloud provider if specified fetches the clusters for specific cloud provider.
     */
    cloudProvider?: 'Aws' | 'Azure';
  }

  /**
   * Parameters for GetInternalTenantRegionInfo
   */
  export interface GetInternalTenantRegionInfoParams {

    /**
     * Specifies the identifier of a tenant.
     */
    tenantId?: string;

    /**
     * Optional region IDs.
     */
    regionIds?: Array<string>;

    /**
     * This token is passed by the caller to fetch next batch of tenant regions. If this is not passed, then first batch of tenant regions are returned applying all other specified filters.
     * Use the pagination token returned as a part of the response in the subsequent calls to fetch next batch of tenant regions.
     */
    paginationToken?: null | number;

    /**
     * Optional number of tenant regions to be returned. Maximum 100 records are returned.
     */
    numRecords?: null | number;
  }

  /**
   * Parameters for GetStandbyClusters
   */
  export interface GetStandbyClustersParams {

    /**
     * Optional param if specified fetches the clusters matching the tenant placement label.
     */
    tenantPlacementLabel?: 'Poc' | 'Production';

    /**
     * Resource ID is the identifier of a DMaaS cluster to filter the response
     */
    resourceId?: string;

    /**
     * Region ID to filter the response
     */
    regionId?: string;

    /**
     * Dataplane aws account ID to filter the response
     */
    dataplaneAccountId?: string;
  }

  /**
   * Parameters for GetClusterGflags
   */
  export interface GetClusterGflagsParams {

    /**
     * Optional parameter to filter the gflags based on the service they belong to.
     */
    serviceName?: null | string;

    /**
     * Id of the DMaaS cluster
     */
    clusterId?: number;
  }

  /**
   * Parameters for GetInternalRpaasRegions
   */
  export interface GetInternalRpaasRegionsParams {

    /**
     * Specifies the identifier of a tenant
     */
    tenantId?: string;

    /**
     * List of region IDs to filter the response.
     */
    regionIds?: null | Array<string>;

    /**
     * This token is passed by the caller to fetch next batch of RPaaS regions. If this is not passed, then first batch of RPaaS regions are returned applying all other specified filters.
     * Use the pagination token returned as a part of the response in the subsequent calls to fetch next batch of RPaaS regions.
     */
    paginationToken?: null | number;

    /**
     * Optional number of RPaaS regions to be returned. Maximum 100 records are returned.
     */
    numRecords?: null | number;

    /**
     * Specifies the identifier of a helios account.
     */
    accountId?: string;
  }

  /**
   * Parameters for GetMigrateTenant
   */
  export interface GetMigrateTenantParams {

    /**
     * Specifies the identifier of a tenant.
     */
    tenantId?: null | string;

    /**
     * ID of the tenant region shard which is being migrated
     */
    shardId?: null | number;

    /**
     * Region to which tenant belongs to
     */
    regionId?: null | string;

    /**
     * Operation id of the migrate tenant request.
     */
    operationId?: null | string;
  }

  /**
   * Parameters for ResumeMigrateTenant
   */
  export interface ResumeMigrateTenantParams {

    /**
     * Skip source registration if the field is set.
     */
    skipSourceRegistration?: null | boolean;

    /**
     * Skip cloud domain migration if the field is set.
     */
    skipCloudDomainMigration?: null | boolean;

    /**
     * Operation Id related to the earlier failed migrate tenant task.
     */
    operationId?: string;
  }

  /**
   * Parameters for GetClusterRebuild
   */
  export interface GetClusterRebuildParams {

    /**
     * Region to which cluster belongs to
     */
    regionId?: string;

    /**
     * Optionally query using operation id. Client can use a combination of clusterId and regionId or operation Id. Using operation id is better to identify the request. The operation id will be obtained as part of post request.
     */
    operationId?: string;

    /**
     * ID of the DMaaS cluster which has to be rebuilt
     */
    clusterId?: number;
  }

  /**
   * Parameters for ChangeClustersGroup
   */
  export interface ChangeClustersGroupParams {

    /**
     * ID of the cluster group to move the clusters.
     */
    clusterGroupId: string;

    /**
     * IDs of the clusters to be moved to a different group.
     */
    clusterIds?: Array<number>;
  }

  /**
   * Parameters for SetDMaaSInfo
   */
  export interface SetDMaaSInfoParams {

    /**
     * ID of the cluster group.
     */
    groupId: string;

    /**
     * IDs of the clusters.
     */
    clusterIds?: Array<number>;
  }

  /**
   * Parameters for GetTenantRegionShards
   */
  export interface GetTenantRegionShardsParams {

    /**
     * Specifies the identifier of a tenant.
     */
    tenantId: string;

    /**
     * Region for which the shards are being requested
     */
    regionId: string;

    /**
     * List of shard IDs to filter the response.
     */
    shardIds?: null | Array<number>;
  }
}

export { DmsService }
