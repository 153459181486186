<cog-table name="source-health-check-table"
  reflowScrollSize="disabled"
  [source]="fiteredChecks"
  [topAlign]="true">
  <table class="pass-fail-matrix" mat-table>
    <!-- The test name column -->
    <ng-container matColumnDef="checkName">
      <th class="test-name-column" mat-header-cell *matHeaderCellDef translate>healthCheck</th>
      <td mat-cell *matCellDef="let check">
        <div class="user-data-xl" translate>sourceHealthChecks.tests.{{check.checkType}}</div>
      </td>
    </ng-container>

    <!-- The test status column -->
    <ng-container matColumnDef="checkStatus">
      <th class="status-column" mat-header-cell *matHeaderCellDef translate>status</th>
      <td mat-cell *matCellDef="let check">
        <cog-icon [class]="check.resultType"
          [shape]="statusIcons[check.resultType]"
          [color]="statusColors[check.resultType]"
          matTooltip="{{check.resultType | translate}}"></cog-icon>
      </td>
    </ng-container>

    <!-- The additional messages column -->
    <ng-container matColumnDef="messages">
      <th class="messages-column" mat-header-cell *matHeaderCellDef translate>messages</th>
      <td mat-cell *matCellDef="let check">
        <div class="user-data-lg"
          [innerHTML]="(check.userMessage || 'naNotAvailable') | translate">
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;"></tr>
  </table>
</cog-table>
