import { Subject } from 'rxjs';

import { DataTreeNode, DataTreeTransformer } from './data-tree.model';
import { DataTreeNodeContext } from '../data-tree-detail.directive';

export const flatTree = [
  {id: '0', level: 0, value: true, expandable: true},
  {id: '0.0', level: 1, value: true},
  {id: '0.1', level: 1, value: true},
  {id: '0.2', level: 1, value: true},
  {id: '0.3', level: 1, value: false},
  {id: '0.4', level: 1, value: true},
  {id: '1', level: 0, value: true, expandable: true},
  {id: 'dup', level: 1, value: true},
  {id: '1.0', level: 1, value: true},
  {id: '1.1', level: 1, value: true},
  {id: '1.2', level: 1, value: true},
  {id: '1.3', level: 1, value: true},
  {id: '1.4', level: 1, value: true, expandable: true},
  {id: 'dup', level: 2, value: true},
  {id: '1.4.0', level: 2, value: true},
  {id: '1.4.1', level: 2, value: true},
  {id: '1.4.2', level: 2, value: true},
  {id: '1.4.3', level: 2, value: true},
  {id: '1.4.4', level: 2, value: true},
];

flatTree.forEach((node: any) => {
  node.isSelectable = true;
  node.canSelect = () => true;
  node.canAutoSelect = () => true;
  node.canExclude = () => true;
});

/**
 * Simple mock interface for tree data
 */
export interface TestTreeData {
  id: string;
  children?: TestTreeData[];
}

/**
 * Generates a test tree with a given depth and width
 *
 * @param depth The depth of the tree to create.
 * @param width The number of children each level should have.
 * @param index The array index of the current item.
 */
export function createTree(depth: number, width: number, index: number = 0): TestTreeData {
  const object = { id: `${depth}.${index}`, children: [] };
  if (depth > 0) {
    for (let i = 0; i < width; i++) {
      object.children.push(createTree(depth - 1, width, i));
    }
  }
  return object;
}

export function getNodeDetailContext(node, selection, control): DataTreeNodeContext<any> {
  if (!node || !selection) {
    return undefined;
  }

  return {
    node: node,
    selection: selection,
    treeControl: control,
    selected: selection.isSelected(node),
    autoSelected: selection.isAutoSelected(node),
    excluded: selection.isExcluded(node),
    ancestorAutoSelected: selection.isAncestorAutoSelected(node),
    ancestorExcluded: selection.isAncestorExcluded(node),
    canSelect: selection.canSelectNode(node),
    canAutoSelect: selection.canAutoSelectNode(node),
    canExclude: selection.canExcludeNode(node),
  };
}


/**
 * DataTreeNode implementation for a flat tree list.
 */
export class TestTreeNode implements DataTreeNode<TestTreeData> {
  constructor(public data: TestTreeData, public level: number) {
  }

  get id() {
    return this.data.id;
  }

  get ids() {
    return [this.id];
  }

  get isSelectable() {
    return true;
  }

  canSelect(): boolean {
    return true;
  }
  canAutoSelect(): boolean {
    return true;
  }
  canExclude(): boolean {
    return true;
  }
}

/**
 * Simple tree tranformer.
 */
export class TestTreeTransformer implements DataTreeTransformer<TestTreeData> {

  getChildrenChanged$ = new Subject<void>();

  getChildren(structuredNode: TestTreeData): TestTreeData[] {
    return structuredNode.children;
  }

  transformData(structuredNode: TestTreeData, level: number): TestTreeNode {
    return new TestTreeNode(structuredNode, level);
  }

  getLevel(flatNode: TestTreeNode): number {
    return flatNode.level;
  }

  getExpandable(flatNode: TestTreeNode): boolean {
    return !!flatNode.data.children && !!flatNode.data.children.length;
  }
}
