import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Api } from '@cohesity/api/private';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FlagData, ServiceGflags, ServiceName } from 'src/app/models';
/**
 * Angular replacement for legacy FEATURE_FLAGS constant.
 */
@Injectable({
  providedIn: 'root'
})
export class ClusterGflagService {

  gFlagData: FlagData = {};

  constructor(private http: HttpClient) {
  }

  /**
   * Behavior Subject to hold the gFlagData value.
   */
  private gFlagDataBehaviorSubject$ = new BehaviorSubject<FlagData>({});

  /**
   * Observable for gFlagData value.
   */
  gFlagData$ = this.gFlagDataBehaviorSubject$.asObservable();

  /**
   * Loads and parses gFlags backend request.
   *
   */
  getGflagData(clusterId?: number): Observable<FlagData> {
    const headers = clusterId? new HttpHeaders({Clusterid: clusterId?.toString()}): {};
    return this.http.get<ServiceGflags[]>(Api.private('clusters/gflag'),{headers}).pipe(
      map(serviceGflags => {
        this.gFlagData = {};
        (serviceGflags || []).forEach(serviceGflag => {
          this.gFlagData[serviceGflag.serviceName] = {};
          (serviceGflag.gflags || []).forEach(gflag => {
            this.gFlagData[serviceGflag.serviceName][gflag.name] = gflag;
          });
        });
        this.gFlagDataBehaviorSubject$.next(this.gFlagData);
        return this.gFlagData;
      })
    );
  }

  /**
   * Returns raw value.
   *
   * @param serviceName service name.
   * @param flagName gflag name.
   * @param defaultVal default return value.
   * @return  flag value.
   */
  getRawValue(serviceName: ServiceName, flagName: string, defaultVal?: string): string {
    return this.gFlagData?.[serviceName]?.[flagName]?.value || defaultVal;
  }

  /**
   * Returns boolean value.
   *
   * @param serviceName service name.
   * @param flagName gflag name.
   * @param defaultVal default return value.
   * @return  flag value when specified flag is truthy or null, false otherwise.
   */
  getBoolValue(serviceName: ServiceName, flagName: string, defaultVal?: boolean): boolean {
    return this.getRawValue(serviceName, flagName,
      (typeof defaultVal !== 'boolean' ? undefined : String(defaultVal))) === 'true';
  }

  /**
   * Returns number value.
   *
   * @param serviceName service name.
   * @param flagName gflag name.
   * @param defaultVal default return value.
   * @return  flag number value.
   */
  getNumberValue(serviceName: ServiceName, flagName: string, defaultVal?: number): number {
    return Number(this.getRawValue(serviceName, flagName,
      (typeof defaultVal !== 'number' ? undefined : String(defaultVal))));
  }
}
