// Service: Policy Service Formatter utility service.

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.policyServiceFormatter', [])
    .service('PolicyServiceFormatter', PolicyServiceFormatterFn);

  function PolicyServiceFormatterFn(cUtils) {

    // This Service's API
    return {
      transformPolicy: transformPolicy,
      untransformPolicy: untransformPolicy,
      requiresJobStartTimes: requiresJobStartTimes,
    };

    /**
     * Transforms a policy by defaulting values and adding convenience
     * properties and returns the policy
     *
     * @param      {object}  policy  as provided from the API
     * @return     {object}  the same policy with added and transformed data
     */
    function transformPolicy(policy) {
      if (!policy) {
        return;
      }

      /* Every Policy should have an incremental schedule. In some upgrade
       * scenarios from 4.x => 5.x (particularly SQL policies) it is possible
       * for an existing policy to be missing the incrementalSchedulingPolicy{}.
       * Defaulting it here will prevent JS errors on edit of the policy, and
       * form validation will ensure it gets populated with the needed settings.
       */
      policy.incrementalSchedulingPolicy =
        policy.incrementalSchedulingPolicy || {};

      policy.extendedRetentionPolicies =
        policy.extendedRetentionPolicies || [];

      policy.snapshotReplicationCopyPolicies =
        policy.snapshotReplicationCopyPolicies || [];

      policy.snapshotArchivalCopyPolicies =
        policy.snapshotArchivalCopyPolicies || [];

      policy.cloudDeployPolicies =
        policy.cloudDeployPolicies || [];

      policy.blackoutPeriods =
        transformBlackoutPeriods(policy.blackoutPeriods || []);

      // determine the target types the policy utilizes
      policy._targets = {
        local: !!policy.extendedRetentionPolicies.length,
        remote: !!policy.snapshotReplicationCopyPolicies.length,
        archival: !!policy.snapshotArchivalCopyPolicies.length,
        cloudDeploy: !!policy.cloudDeployPolicies.length,

        // These properties are more granular and will be set to true in the
        // forEach loop below if appropriate
        cloud: false,
        tape: false,
        nas: false,
      };

      policy.snapshotArchivalCopyPolicies.forEach(
        function checkArchivalTypes(archivalCopyPolicy) {
          // While local policy should always be complete,
          // Linked policy may not have all the information.
          switch (_.get(archivalCopyPolicy, 'target.vaultType')) {
            case 'kTape':
              policy._targets.tape = true;
              break;
            case 'kCloud':
              policy._targets.cloud = true;
              break;
            case 'kNas':
              policy._targets.nas = true;
              break;
          }
        }
      );

      policy._requireStartTimes = requiresJobStartTimes(policy);

      // Set Worm Retention Lock decorator if the policy is locked
      policy._wormLocked = (policy.wormRetentionType === 'kCompliance');

      policy.cascadedTargetsConfig = policy.cascadedTargetsConfig || [];
      return policy;
    }

    /**
     * Normalizes a previously transformed Policy into an API friendly Policy.
     *
     * @param      {object}  policy  as setup by transformPolicy()
     * @return     {object}  the same policiy made API friendly
     */
    function untransformPolicy(policy) {

      if (!policy) {
        return;
      }

      // make a copy of the policy so transformations aren't reflected in the UI
      // in the event that related API calls fail.
      policy = cUtils.simpleCopy(policy);

      policy._targets = undefined;

      policy.blackoutPeriods =
        untransformBlackoutPeriods(policy.blackoutPeriods || []);

      // When creating or editing a Policy, the archivalTarget is added to the
      // snapshotArchivalCopyPolicy for reliable ngModel binding. Clean it up.
      (policy.snapshotArchivalCopyPolicies || []).forEach(
        function cleanupTarget(archivalPolicy) {
          archivalPolicy._vault = undefined;
        }
      );

      policy._requireStartTimes = undefined;

      return policy;
    }

    /**
     * Indicates if the policy's configuration requires startTime to be
     * captured for associated Jobs.
     *
     * @method   requiresJobStartTimes
     * @param    {object}    policy   The policy
     * @return   {boolean}   return true if policy jobs need a start time
     *                       else false.
     */
    function requiresJobStartTimes(policy) {
      var requiresStartTimePeriodicities = ['kMonthly', 'kDaily'];
      var incrementalSchedule = policy.incrementalSchedulingPolicy

      // Whether job start time is needed based on incremental scheduling
      var incrementalNeedsStartTime = false;

      if (incrementalSchedule) {
        incrementalNeedsStartTime =
          requiresStartTimePeriodicities.includes(incrementalSchedule.periodicity) ||

          // If the interval min is cleanly divisible by 24hours, it requires a job start time.
          (incrementalSchedule.periodicity === 'kContinuous' &&
          incrementalSchedule.continuousSchedule.backupIntervalMins % 1440 === 0)
      }

      var needStartTimes = incrementalNeedsStartTime ||
        (policy.fullSchedulingPolicy &&
          requiresStartTimePeriodicities.includes(policy.fullSchedulingPolicy.periodicity)) ||
        (policy.systemSchedulingPolicy &&
          requiresStartTimePeriodicities.includes(policy.systemSchedulingPolicy.periodicity));

      return !!needStartTimes;
    }

    /**
     * Transform blackoutPeriods as returned from API so that matching days are
     * grouped together. This can/should be reversed using
     * untransformBlackoutPeriods before resubmitting to the API
     *
     * @param      {array}  blackoutPeriods  as provided by API
     * @return     {array}  transformed array of blackoutPeriods
     */
    function transformBlackoutPeriods(blackoutPeriods) {
      var indexedPeriods = {};
      var transformedBlackoutPeriods = [];
      var currentIndex;

      if (blackoutPeriods && blackoutPeriods.length) {

        // build a map of start/end time so we can push related days into it
        blackoutPeriods.forEach(function mapRange(blackoutPeriod) {

          var timeFrameKey = [
            JSON.stringify(blackoutPeriod.startTime),
            JSON.stringify(blackoutPeriod.endTime),
          ].join();

          if (indexedPeriods[timeFrameKey]) {
            indexedPeriods[timeFrameKey].days.push(blackoutPeriod.day);
          } else {
            indexedPeriods[timeFrameKey] = {
              days: [blackoutPeriod.day],
              startTime: blackoutPeriod.startTime,
              endTime: blackoutPeriod.endTime
            };
          }
        });

        // now build our transformedBlackoutPeriods based on the index map
        for (var indexedPeriod in indexedPeriods) {

          if (indexedPeriods[indexedPeriod]) {

            transformedBlackoutPeriods.push({
              days: indexedPeriods[indexedPeriod].days,
              startTime: indexedPeriods[indexedPeriod].startTime,
              endTime: indexedPeriods[indexedPeriod].endTime
            });

            currentIndex = transformedBlackoutPeriods.length - 1;

          }
        }
      }

      return transformedBlackoutPeriods;
    }

    /**
     * Untransform blackoutPeriods[] as returned from transformBlackoutPeriods
     * so that each day for a range is represented individually as the API
     * expects.
     *
     * @param  {Array}  blackoutPeriods as transformed by transformBlackoutPeriods,
     *                                  if not previously transformed, returns array as-is
     * @return {Array}                  untransformed array of ranges
     */
    function untransformBlackoutPeriods(blackoutPeriods) {
      var untransformedBlackoutPeriods = [];

      // stringified map to prevent duplicates form being added
      var rangesMap = {};

      if (!blackoutPeriods ||
        !blackoutPeriods.length ||
        !blackoutPeriods[0].days) {
        return blackoutPeriods;
      }

      blackoutPeriods.forEach(function untransform(blackoutPeriod) {
        blackoutPeriod.days.forEach(
          function untransformDay(day) {

            var newPeriod = {
              day: day,
              startTime: blackoutPeriod.startTime,
              endTime: blackoutPeriod.endTime,
            };

            var newRangeString = JSON.stringify(newPeriod);

            // ensure this isn't a duplicate entry
            if (!rangesMap[newRangeString]) {
              rangesMap[newRangeString] = true;
              untransformedBlackoutPeriods.push(newPeriod);
            }
          }
        );
      });

      return untransformedBlackoutPeriods;
    }
  }

})(angular);
