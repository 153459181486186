import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts an enum value to a list of keys/properties
 *
 * The results is an array of {key: string, value: any}
 */
@Pipe({
  name: 'enumKeys',
  pure: true,
})
export class EnumKeysPipe implements PipeTransform {

  /**
   * transform the enum value
   *
   * @param   value   an enum definition
   * @returns a list of values
   */
  transform(value): {key: string; value: any}[] {
    const keys = [];
    for (const enumMember in value) {
      if (isNaN(parseInt(enumMember, 10))) {
        keys.push({key: enumMember, value: value[enumMember]});
      }
    }
    return keys;
  }
}
