<form [formGroup]="formGroup"
  (ngSubmit)="onDelete()">
  <h1 *ngIf="title" mat-dialog-title>
    {{ title | translate }}
  </h1>

  <mat-dialog-content>
    <p *ngIf="!isHtml" cogDataId="confirmation-dialog-msg-plaintext">{{ message | translate }}</p>
    <div class="delete-confirmation-dialog-content" *ngIf="isHtml" [innerHtml]="message" cogDataId="confirmation-dialog-msg-html"></div>

    <mat-form-field class="col-xs-12 no-padding" [hideRequiredMarker]="true">
      <input cogDataId="delete-dialog-input"
        required
        matInput
        placeholder="{{'typeLabelToConfirm' | translate: {label: keywordLabel | translate | uppercase} }}"
        formControlName="confirmDelete">
        <mat-error cogDataId="confirm-required-invalid"
          *ngIf="formGroup.controls.confirmDelete.errors?.required">
          {{'errors.required' | translate}}
        </mat-error>
      <mat-error *ngIf="formGroup.controls.confirmDelete.errors?.pattern"
        cogDataId="confirm-pattern-pattern">
        {{'mustInputLabel' | translate: {label: keywordLabel | translate | uppercase} }}
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button
      cogCancelButton
      cogDataId="delete-confirmation-cancel-action"
      type="button"
      mat-button
      color="primary"
      mat-dialog-close>
      {{ cancelLabel | translate }}
    </button>
    <button cogSubmitButton
      cogDataId="delete-confirmation-click-action"
      color="warn"
      type="submit"
      mat-flat-button>
      {{ confirmLabel | translate }}
    </button>
  </mat-dialog-actions>
</form>
