/// <reference types="webpack/module" />

/**
 * This file defines production environment configurations for loading angular js
 * and code and dtemplates. During compile, this file will replace file-loader.ts.
 */
export const fileLoader = {
  // In production mode, do not load test.js, mock.js, _dev, or examples files
  legacyJs: import.meta.webpackContext('../app-ajs/', {
    recursive: true,
    regExp: /(^(?!.*(\.test\.js$|\.mock\.js$|_dev\.js$|examples\/)).).+\.js/,
  }),
};
