<hyf-topology-graph *ngIf="graphData?.nodes?.length; else blankCard"
  [nodes]="graphData?.nodes"
  [edges]="graphData?.edges"
  [defaultNodeComponent]="nodeComponent">
</hyf-topology-graph>

<ng-template #blankCard>
  <cog-blank-card type="helix:security-center-vault" size="sm" [message]="'noVaults' | translate" noData>
    <h5>
      <a href="/../rpaas/protection/add-protection">
        {{ 'vaultData' | translate }}
      </a>
    </h5>
  </cog-blank-card>
</ng-template>
