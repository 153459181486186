import { Injectable } from '@angular/core';
import {
  HeliosStatsServiceApi,
  LastProtectionRunStats,
  LastProtectionRunStatsByEnv,
  StatsServiceApi,
} from '@cohesity/api/v1';
import { getDateRangeFilter } from '@cohesity/helix';
import { IrisContextService, isClusterScope, isMcm } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { PointOptionsObject, SeriesPieOptions } from 'highcharts';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { enumGroupMap, EnvGroup, GroupData } from 'src/app/shared/constants';

/**
 * Serialize group value into an array with env group name as label
 * and two numbers for met and missed SLA for column values.
 */
type GroupValues = [ EnvGroup, number, number ];

/**
 * Compliance card data.
 */
export interface ComplianceDonutData {
  totalMetSla?: number;
  totalMissedSla?: number;
  stats?: {
    nameKey: string;
    value: number;
  }[];
  series?: SeriesPieOptions[];
}

/**
 * @description
 * SLA Compliance service for SlaComplianceCardComponent.
 */
@Injectable()
export class SlaComplianceAltService {

  constructor(
    private heliosStatsService: HeliosStatsServiceApi,
    private irisContextService: IrisContextService,
    private statsService: StatsServiceApi,
    private translate: TranslateService,
  ) {}

  /**
   * Flag to indicate if dashboard is in MCM mode.
   */
  get isMcm() {
    return isMcm(this.irisContextService.irisContext);
  }

  /**
   * Flag to indicate single cluster selection scope.
   */
  get isClusterScope() {
    return isClusterScope(this.irisContextService.irisContext);
  }

  /**
   * Returns observable of `LastProtectionRunStats` based on MCM or standalone cluster.
   */
  private getData(): Observable<LastProtectionRunStats> {
    const dateRangeFilter = getDateRangeFilter('past24hours');
    const params = {
      fromTimeUsecs: dateRangeFilter.start.valueOf() * 1000,
      toTimeUsecs: dateRangeFilter.end.valueOf() * 1000,
    };

    if (this.isMcm) {
      const clusterIdentifiers = [];
      const { clusterId, clusterIncarnationId } = this.irisContextService.irisContext.selectedScope;

      if (clusterId && clusterIncarnationId) {
        clusterIdentifiers.push(`${clusterId}:${clusterIncarnationId}`);
      }

      return this.heliosStatsService.McmGetLastProtectionRunStats({
        clusterIdentifiers,
        ...params,
      });
    }

    return this.statsService.GetLastProtectionRunStats(params);
  }

  /**
   * Returns observable of ComplianceDonutData.
   */
  getComplianceDonutData(): Observable<ComplianceDonutData> {
    return this.getData().pipe(map((res: LastProtectionRunStats) => {
      const complianceDonutData: ComplianceDonutData = {
        totalMetSla: 0,
        totalMissedSla: 0,
        series: [],
        stats: [{
          nameKey: 'kPass',
          value: res.numRunsMetSla,
        }, {
          nameKey: 'kFail',
          value: res.numRunsFailedSla,
        }]
      };

      const objectData: GroupData = {};

      res.statsByEnv.forEach((env: LastProtectionRunStatsByEnv) => {
        const { environment, numObjectsMetSla, numObjectsFailedSla } = env;
        const envGroup = enumGroupMap[environment] || 'other';

        if (!(envGroup in objectData)) {
          objectData[envGroup] = [ 0, 0 ];
        }

        const data = objectData[envGroup];
        data[0] += numObjectsMetSla;
        data[1] += numObjectsFailedSla;

        complianceDonutData.totalMissedSla += numObjectsFailedSla;
        complianceDonutData.totalMetSla += numObjectsMetSla;
      });

      // Create new data structure, with first value is category,
      // second and third values are met and missed SLAs ["vm", 12, 14]
      let groupValues: GroupValues[] = Object.keys(objectData).map((group: EnvGroup) => {
        const [ metSla, missedSla ] = objectData[group];
        return [ group, metSla, missedSla ] as GroupValues;
      });

      // First, sort columns by aggregate met and missed SLA values (total object count).
      groupValues.sort((a, b) => a[1] + a[2] > b[1] + b[2] ? -1 : 1);

      if (groupValues.length > 3) {
        // Pick top 2 columns and move all other values to "other" column
        const other = groupValues.slice(2).reduce((c, a) => [c[0], c[1] + a[1], c[2] + a[2]], ['other', 0, 0]);
        groupValues = groupValues.slice(0, 2).concat([other]);
      }

      // Now sort values by percentage meeting sla. so that the percentage
      // decreases in the chart's concentric circles (visually pleasing).
      groupValues.sort((a, b) => (a[1] / (a[1] + a[2])) > (b[1] / (b[1] + b[2])) ? -1 : 1);

      groupValues.forEach(groupValue => {
        const categoryName = this.translate.instant(`enum.envGroup.${groupValue[0]}`);
        const groupData: PointOptionsObject[] = [
          {
            name: this.translate.instant('metSla'),
            y: groupValue[1],
          },
          {
            name: this.translate.instant('missedSla'),
            y: groupValue[2],
          },
        ].map(({ name, y }) => {
          const description = [y, categoryName, name].join(' ');

          return { name, y, description, accessibility: { description }};
        });

        complianceDonutData.series.push({
          type: 'pie',
          name: categoryName,
          accessibility: {
            point: {
              // Need to have empty content here but not empty string which will
              // cause Highchart to insert unnecessary default content.
              valueDescriptionFormat: ' ',
            },
          },
          data: groupData,
        });
      });

      return complianceDonutData;
    }));
  }
}
