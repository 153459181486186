import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { DataIdModule } from '../data-id/data-id.module';
import { FormsModule } from '../forms/forms.module';
import { IconModule } from '../icon/icon.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { AdvancedSearchComponent, AdvancedSearchFooterOptionDirective } from './advanced-search.component';

@NgModule({
  declarations: [AdvancedSearchComponent, AdvancedSearchFooterOptionDirective],
  exports: [AdvancedSearchComponent, AdvancedSearchFooterOptionDirective],
  imports: [
    CommonModule,
    DataIdModule,
    FormsModule,
    IconModule,
    MatAutocompleteModule,
    MatInputModule,
    MatTooltipModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    SpinnerModule,
  ],
})
export class AdvancedSearchModule { }
