<h1 class="applied-filters-header">
  {{ intl.dataFilters.appliedFilters(filterTags?.length) }}
</h1>
<button
  id="backdrop-filters-clear"
  mat-button
  color="primary"
  (click)="clearFilters()">
  {{ intl.dataFilters.clearFilters }}
</button>
