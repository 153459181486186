<span class="item-container"
  *ngIf="items?.length === 1">
  <span>
    {{items[0]}}
  </span>
</span>
<ng-container *ngIf="items?.length > 1">
  <span>
    {{items[0]}}
  </span>
  <span class="item-summary-plus"
    [matMenuTriggerFor]="menu">{{ items.length - 1 }} {{suffix}}</span>

  <mat-menu #menu="matMenu" class="item-menu-container">
    <button mat-menu-item disabled>
      <span>{{ items.length }} {{ itemType }}</span>
    </button>
    <button mat-menu-item
      *ngFor="let item of items">
      <span class="item-menu-item-text">{{ item }}</span>
    </button>
  </mat-menu>
</ng-container>
