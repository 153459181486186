import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DataFiltersModule } from '../data-filters';

import { DataRenderersModule } from '../data-renderers';
import { DatePipesModule } from '../date-pipe';
import { SpinnerModule } from '../spinner/spinner.module';
import { TableModule } from '../table';
import { AutoTableCellComponent } from './auto-table-cell/auto-table-cell.component';
import { AutoTableComponent } from './auto-table.component';

@NgModule({
  declarations: [AutoTableComponent, AutoTableCellComponent],
  imports: [CommonModule, SpinnerModule, TableModule, DataRenderersModule, DataFiltersModule, DatePipesModule],
  exports: [AutoTableComponent],
})
export class AutoTableModule {}
