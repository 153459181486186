// Component: clusterPubSshKey

;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.clusterPubSshKey';
  var moduleDependencies = ['C.clipboardCta'];
  var componentName = 'clusterPubSshKey';
  var componentConfig = {
    controller: 'SshKeyCtrl',
    template: require('raw-loader!./cluster-pub-ssh-key.html').default,
  };

  angular.module(moduleName, moduleDependencies)
    .controller('SshKeyCtrl', SshKeyCtrlFn)
    .component(componentName, componentConfig);

  /**
   * $ngdoc Component
   * @name C.clusterPubSshKey:clusterPubSshKey
   *
   * @description
   *   Displays the cluster's public SSH key, allowing user to copy it to the
   *   clipboard.
   *
   * @example
       <cluster-pub-ssh-key></cluster-pub-ssh-key>
   */
  function SshKeyCtrlFn(ClusterService, evalAJAX) {
    var $ctrl = this;

    angular.extend($ctrl, {
      $onInit: $onInit,
    });

    /**
     * Initialization function
     *
     * @method   $onInit
     */
    function $onInit() {
      ClusterService.getPublicKey().then(
        function getPublicKeySuccess(key) {
          $ctrl.sshPublicKey = key;
        },
        evalAJAX.errorMessage
      );
    }
  }


})(angular);
