/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { HeliosAccounts } from '../models/helios-accounts';
import { ImpersonationAction } from '../models/impersonation-action';
import { AccountSubscriptionInfo } from '../models/account-subscription-info';
import { McmGflags } from '../models/mcm-gflags';
import { McmTenantProfiles } from '../models/mcm-tenant-profiles';
import { McmTenantAction } from '../models/mcm-tenant-action';
import { McmTenantMigrationStatus } from '../models/mcm-tenant-migration-status';
@Injectable({
  providedIn: 'root',
})
class HeliosAccountsService extends __BaseService {
  static readonly GetAccountsPath = '/mcm/accounts';
  static readonly PerformImpersonationActionPath = '/mcm/accounts/impersonations/actions';
  static readonly McmGetAccountSubscriptionInfoPath = '/mcm/accounts/subscriptionInfo';
  static readonly McmGetGflagsPath = '/mcm/gflags';
  static readonly McmGetTenantProfilesPath = '/mcm/tenant-profiles';
  static readonly McmPerformTenantActionPath = '/mcm/tenants/actions';
  static readonly McmGetTenantMigrationStatusPath = '/mcm/tenants/migrations';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get Accounts
   *
   * Get Helios Accounts
   * @param params The `HeliosAccountsService.GetAccountsParams` containing the following parameters:
   *
   * - `supportCaseId`: Filter by Support Case ID. This must be specified if and only if the currently logged in user is a support user.
   *
   * - `searchString`: Filter by accounts which partially match the string.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  GetAccountsResponse(params: HeliosAccountsService.GetAccountsParams): __Observable<__StrictHttpResponse<HeliosAccounts>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.supportCaseId != null) __params = __params.set('supportCaseId', params.supportCaseId.toString());
    if (params.searchString != null) __params = __params.set('searchString', params.searchString.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/accounts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HeliosAccounts>;
      })
    );
  }
  /**
   * Get Accounts
   *
   * Get Helios Accounts
   * @param params The `HeliosAccountsService.GetAccountsParams` containing the following parameters:
   *
   * - `supportCaseId`: Filter by Support Case ID. This must be specified if and only if the currently logged in user is a support user.
   *
   * - `searchString`: Filter by accounts which partially match the string.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  GetAccounts(params: HeliosAccountsService.GetAccountsParams): __Observable<HeliosAccounts> {
    return this.GetAccountsResponse(params).pipe(
      __map(_r => _r.body as HeliosAccounts)
    );
  }

  /**
   * Start or Stop Impersonation Session
   *
   * Starts or stops an impersonation session for Helios support and sales users.
   * @param body Specifies the parameters to perform a tenant action.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  PerformImpersonationActionResponse(body: ImpersonationAction,
    regionId?: string): __Observable<__StrictHttpResponse<ImpersonationAction>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/accounts/impersonations/actions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ImpersonationAction>;
      })
    );
  }
  /**
   * Start or Stop Impersonation Session
   *
   * Starts or stops an impersonation session for Helios support and sales users.
   * @param body Specifies the parameters to perform a tenant action.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  PerformImpersonationAction(body: ImpersonationAction,
    regionId?: string): __Observable<ImpersonationAction> {
    return this.PerformImpersonationActionResponse(body, regionId).pipe(
      __map(_r => _r.body as ImpersonationAction)
    );
  }

  /**
   * Get Account SubscriptionInfo
   *
   * Get subscription info for a given account id.
   * @param params The `HeliosAccountsService.McmGetAccountSubscriptionInfoParams` containing the following parameters:
   *
   * - `sfAccountId`: The salesforce account id.
   *
   * - `serviceName`: Filter the subscription info by service name.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  McmGetAccountSubscriptionInfoResponse(params: HeliosAccountsService.McmGetAccountSubscriptionInfoParams): __Observable<__StrictHttpResponse<AccountSubscriptionInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sfAccountId != null) __params = __params.set('sfAccountId', params.sfAccountId.toString());
    if (params.serviceName != null) __params = __params.set('serviceName', params.serviceName.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/accounts/subscriptionInfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AccountSubscriptionInfo>;
      })
    );
  }
  /**
   * Get Account SubscriptionInfo
   *
   * Get subscription info for a given account id.
   * @param params The `HeliosAccountsService.McmGetAccountSubscriptionInfoParams` containing the following parameters:
   *
   * - `sfAccountId`: The salesforce account id.
   *
   * - `serviceName`: Filter the subscription info by service name.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * @return Success
   */
  McmGetAccountSubscriptionInfo(params: HeliosAccountsService.McmGetAccountSubscriptionInfoParams): __Observable<AccountSubscriptionInfo> {
    return this.McmGetAccountSubscriptionInfoResponse(params).pipe(
      __map(_r => _r.body as AccountSubscriptionInfo)
    );
  }

  /**
   * Get Mcm Gflags
   *
   * Get the gflags for the specified account.
   * @param params The `HeliosAccountsService.McmGetGflagsParams` containing the following parameters:
   *
   * - `serviceNames`: Filter the returned flags by service name.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `flagNames`: Filter the returned flags by flag name.
   *
   * @return Success
   */
  McmGetGflagsResponse(params: HeliosAccountsService.McmGetGflagsParams): __Observable<__StrictHttpResponse<McmGflags>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.serviceNames || []).forEach(val => {if (val != null) __params = __params.append('serviceNames', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.flagNames || []).forEach(val => {if (val != null) __params = __params.append('flagNames', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/gflags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmGflags>;
      })
    );
  }
  /**
   * Get Mcm Gflags
   *
   * Get the gflags for the specified account.
   * @param params The `HeliosAccountsService.McmGetGflagsParams` containing the following parameters:
   *
   * - `serviceNames`: Filter the returned flags by service name.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `flagNames`: Filter the returned flags by flag name.
   *
   * @return Success
   */
  McmGetGflags(params: HeliosAccountsService.McmGetGflagsParams): __Observable<McmGflags> {
    return this.McmGetGflagsResponse(params).pipe(
      __map(_r => _r.body as McmGflags)
    );
  }

  /**
   * Get Tenant profiles
   *
   * Get tenant profiles for currently logged in user.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  McmGetTenantProfilesResponse(regionId?: string): __Observable<__StrictHttpResponse<McmTenantProfiles>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/tenant-profiles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmTenantProfiles>;
      })
    );
  }
  /**
   * Get Tenant profiles
   *
   * Get tenant profiles for currently logged in user.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  McmGetTenantProfiles(regionId?: string): __Observable<McmTenantProfiles> {
    return this.McmGetTenantProfilesResponse(regionId).pipe(
      __map(_r => _r.body as McmTenantProfiles)
    );
  }

  /**
   * Perform Mcm Tenant Action
   *
   * Perform an action on a DMaaS tenant.
   * @param body Specifies the parameters to perform a tenant action.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  McmPerformTenantActionResponse(body: McmTenantAction,
    regionId?: string): __Observable<__StrictHttpResponse<McmTenantAction>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/mcm/tenants/actions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmTenantAction>;
      })
    );
  }
  /**
   * Perform Mcm Tenant Action
   *
   * Perform an action on a DMaaS tenant.
   * @param body Specifies the parameters to perform a tenant action.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  McmPerformTenantAction(body: McmTenantAction,
    regionId?: string): __Observable<McmTenantAction> {
    return this.McmPerformTenantActionResponse(body, regionId).pipe(
      __map(_r => _r.body as McmTenantAction)
    );
  }

  /**
   * Get Tenant Migration Status
   *
   * Get the migration status for the current tenant.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  McmGetTenantMigrationStatusResponse(regionId?: string): __Observable<__StrictHttpResponse<McmTenantMigrationStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/tenants/migrations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmTenantMigrationStatus>;
      })
    );
  }
  /**
   * Get Tenant Migration Status
   *
   * Get the migration status for the current tenant.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @return Success
   */
  McmGetTenantMigrationStatus(regionId?: string): __Observable<McmTenantMigrationStatus> {
    return this.McmGetTenantMigrationStatusResponse(regionId).pipe(
      __map(_r => _r.body as McmTenantMigrationStatus)
    );
  }
}

module HeliosAccountsService {

  /**
   * Parameters for GetAccounts
   */
  export interface GetAccountsParams {

    /**
     * Filter by Support Case ID. This must be specified if and only if the currently logged in user is a support user.
     */
    supportCaseId?: null | string;

    /**
     * Filter by accounts which partially match the string.
     */
    searchString?: null | string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;
  }

  /**
   * Parameters for McmGetAccountSubscriptionInfo
   */
  export interface McmGetAccountSubscriptionInfoParams {

    /**
     * The salesforce account id.
     */
    sfAccountId: null | string;

    /**
     * Filter the subscription info by service name.
     */
    serviceName?: null | string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;
  }

  /**
   * Parameters for McmGetGflags
   */
  export interface McmGetGflagsParams {

    /**
     * Filter the returned flags by service name.
     */
    serviceNames?: null | Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Filter the returned flags by flag name.
     */
    flagNames?: null | Array<string>;
  }
}

export { HeliosAccountsService }
