import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const cassandraModify = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngProtectionGroupGlobalModify &&
  ctx.FEATURE_FLAGS.ngProtectionGroupCassandraModify &&
  ctx.PROTECTION_MODIFY;

const hbaseModify = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngProtectionGroupGlobalModify &&
  ctx.FEATURE_FLAGS.ngProtectionGroupHBaseModify &&
  ctx.PROTECTION_MODIFY;

const hdfsModify = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngProtectionGroupGlobalModify &&
  ctx.FEATURE_FLAGS.ngProtectionGroupHdfsModify &&
  ctx.PROTECTION_MODIFY;

const hiveModify = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngProtectionGroupGlobalModify &&
  ctx.FEATURE_FLAGS.ngProtectionGroupHiveModify &&
  ctx.PROTECTION_MODIFY;

const mongodbModify = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngProtectionGroupGlobalModify &&
  ctx.FEATURE_FLAGS.ngProtectionGroupMongoDBModify &&
  ctx.PROTECTION_MODIFY;

export const NosqlConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'protection-builder-cassandra.**',
      url: '/protection/protect/cassandra',
      loadChildren: () => import('./nosql.module').then(m => m.NosqlModule),
    },
    {
      name: 'protection-builder-hbase.**',
      url: '/protection/protect/hbase',
      loadChildren: () => import('./nosql.module').then(m => m.NosqlModule),
    },
    {
      name: 'protection-builder-hdfs.**',
      url: '/protection/protect/hdfs',
      loadChildren: () => import('./nosql.module').then(m => m.NosqlModule),
    },
    {
      name: 'protection-builder-hive.**',
      url: '/protection/protect/hive',
      loadChildren: () => import('./nosql.module').then(m => m.NosqlModule),
    },
    {
      name: 'protection-builder-mongodb.**',
      url: '/protection/protect/mongodb',
      loadChildren: () => import('./nosql.module').then(m => m.NosqlModule),
    },
  ],
  getStateAccessMap(): StateAccessMap {
    return {
      'protection-builder-cassandra.create': cassandraModify,
      'protection-builder-cassandra.edit': cassandraModify,
      'protection-builder-hbase.create': hbaseModify,
      'protection-builder-hbase.edit': hbaseModify,
      'protection-builder-hdfs.create': hdfsModify,
      'protection-builder-hdfs.edit': hdfsModify,
      'protection-builder-hive.create': hiveModify,
      'protection-builder-hive.edit': hiveModify,
      'protection-builder-mongodb.create': mongodbModify,
      'protection-builder-mongodb.edit': mongodbModify,
    };
  }
};
