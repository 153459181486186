import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Moment } from 'moment';
import { Controls, NgxSubFormRemapComponent, subformComponentProviders } from 'ngx-sub-form';

/**
 * The form interface for this component.
 */
interface TimeFieldForm {
  value: string;
}

@Component({
  selector: 'coh-time-field',
  templateUrl: './time-field.component.html',
  styleUrls: ['./time-field.component.scss'],
  providers: [subformComponentProviders(TimeFieldComponent)],
})
export class TimeFieldComponent extends NgxSubFormRemapComponent<Moment, TimeFieldForm> implements OnChanges {

  /**
   * Label for the mat form field
   */
  @Input() label = '';

  /**
   * Whether to include seconds in the time input
   */
  @Input() showSeconds = false;

  /**
   * If true, marks the form control as disabled.
   */
  @Input() isDisabled = false;

  /**
   * Gets the time format for the field - currently this depends on whether the picker should show seconds or not.
   */
  get timeFormat(): string {
    return  this.showSeconds ? 'HH:mm:ss' : 'HH:mm';
  }

  /**
   * Stores the current date object, which is passed to this component. This is
   * useful in emitting the changed values with the same date.
   */
  private currentDate: Moment;

  /**
   * Prevent emitting null value when this component is destroyed.
   */
  protected emitNullOnDestroy = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isDisabled && this.isDisabled === true) {
      this.formGroupControls.value.disable();
    } else {
      this.formGroupControls.value.enable();
    }
  }

  protected transformToFormGroup(date: Moment): TimeFieldForm {
    this.currentDate = date;
    if (!date) {
      return null;
    }
    return { value: date.format(this.timeFormat) };
  }

  protected transformFromFormGroup(formValue: TimeFieldForm): Moment {
    if (!formValue) {
      return null;
    }
    const [hours, minutes, seconds] = formValue.value.split(':').map(Number);
    return this.currentDate.clone().set({ hours, minutes, seconds });
  }

  protected getFormControls(): Controls<TimeFieldForm> {
    return {
      value: new UntypedFormControl(null, [Validators.required]),
    };
  }

  /**
   * This function serves as a callback for the `change` event on the time input field.
   */
  onTimeChange(event: Event) {
    // Stop propagating this event. Without this, the event goes all the way to the snapshot selector modal.
    event.stopPropagation();
  }
}
