import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AutoTableColumnConfig, AutoTableFilter } from '@cohesity/helix';

import { ReportItemFilter } from '../../../iris-reporting.model';
import { BaseReportItemRendererComponent } from '../base-report-item-renderer.component';

/**
 * A report table item.
 */
@Component({
  selector: 'iris-rpt-table-item',
  templateUrl: './table-item.component.html',
  styleUrls: ['./table-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableItemComponent extends BaseReportItemRendererComponent implements OnChanges, OnInit {

  /**
   * Whether to show the table's header row.
   */
  @Input() noHeader = false;

  /**
   * The column configuration determines what columns to render and how they are mapped from
   * the data input.
   */
  @Input() columns: AutoTableColumnConfig[] = [];

  /**
   * Max number of rows that will be shown when printing to pdf. If the table size exceesd this
   * the component will show a warning message and
   */
  @Input() maxPrintableRows = 1000;

  /**
   * Message key to show when limiting the visible outputs.
   */
  @Input() limitedDataShownMessageKey = 'reporting.limitedDataShown';

  /**
   * whether to show filter for a table or not.
   */
  @Input() hideFilter = false;

  /**
   * Emit sort event when user changes sort.
   */
  @Output() itemFilterChange = new EventEmitter<ReportItemFilter>();

  @ViewChild('editTemplate', { static: true }) editTemplate: TemplateRef<any>;

  /**
   * Gets the tables data, truncated if necessary.
   */
  tableData: any[] = [];

  /**
   * Timestamp for display in table header if needed.
   */
  columnDate: number;

  /**
   * Returns whether the printed data should be truncated.
   */
  get limitDataShown(): boolean {
    return this.isPrintMedia && this.data?.length > this.maxPrintableRows;
  }

  /**
   * Returns table name based on report type and component id
   */
  get tableName(): string {
    return `report-table-${this.components?.[0]?.reportType || ''}-${this.components?.[0]?.id || ''}`;
  }

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnChanges() {
    if (this.data) {
      this.tableData = this.limitDataShown ? this.data.slice(0, this.maxPrintableRows) : this.data;
    }
  }

  ngOnInit() {
    // Don't allow customizing tables that don't show headers.
    if (this.noHeader) {
      this.editTemplate = null;
    }
    if (this.noHeader || this.isPrintMedia) {
      this.columns.forEach(column => delete column.tooltip);
    } else {
      this.columns.forEach(column => {
        if (!column.tooltip) {
          column.tooltip = `reporting.tooltip.${column.title}`;
        }
      });
    }
    this.columnDate = this.getFilterDate();
  }

  /**
   * Update the displayed columns and emit a change event.
   *
   * @param columns The new columns
   */
  onColumnsChange(columns: AutoTableColumnConfig[]) {
    this.columns = columns;
    this.itemEdited.emit({columns});
    this.cdr.detectChanges();
  }

  /**
   * Handles filter change including filter, sort.
   *
   * @param event  filter change event.
   */
  onFilterChange(event: AutoTableFilter) {
    this.itemFilterChange.emit({
      sort: event.sort,
    });
  }
}
