<!-- Use a simple layout if only the primary key is set -->
<cog-data-renderer *ngIf="!config.subtitle && !config.icon && config.primary"
  class="cell-title"
  [value]="primaryValue"
  [dataType]="config.primary.dataType"
  [secondaryValue]="row"
  [translate]="translate"
  [renderParams]="config.primary.renderParams">
</cog-data-renderer>

<!-- Use a more complex layout if there is an icon or subtitle -->
<ng-container *ngIf="config.icon || config.subtitle">
  <cog-data-renderer *ngIf="config.icon"
    class="cell-icon"
    [value]="iconValue"
    [dataType]="config.icon.dataType"
    [secondaryValue]="row"
    [translate]="translate"
    [renderParams]="config.icon.renderParams">
  </cog-data-renderer>


  <div class="cell-text" *ngIf="config.primary || config.icon">
    <cog-data-renderer *ngIf="config.primary"
      class="cell-title"
      [value]="primaryValue"
      [dataType]="config.primary.dataType"
      [secondaryValue]="row"
      [translate]="translate"
      [renderParams]="config.primary.renderParams">
    </cog-data-renderer>
    <cog-data-renderer *ngIf="config.subtitle"
      class="cell-subtitle cog-chart"
      [value]="subtitleValue"
      [dataType]="config.subtitle.dataType"
      [secondaryValue]="row"
      [translate]="translate"
      [renderParams]="config.subtitle.renderParams">
    </cog-data-renderer>
  </div>
</ng-container>
