<h1 mat-dialog-title>{{ action | translate }}</h1>
<mat-dialog-content [innerHTML]="description"></mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close cdkfocusinitial cogDataId="button-simple-dialog-decline">
    {{ 'cancel' | translate }}
  </button>
  <button
    mat-flat-button
    cogDataId="button-simple-dialog-confirm"
    cogSubmitButton
    color="primary"
    (click)="actionHandler()"
    [disabled]="isSubmitting">
    {{ 'confirm' | translate }}
  </button>
</mat-dialog-actions>
