

export enum NgtCapabilities {
  /**
   * In case we could not parse and get correct capability type.
   */
  kUnknown,

  /**
   * This specifies that NGT has enabled VM with Self Service Restore.
   * Currently this is not used anywhere in our workflows.
   */
  kSelfServiceRestore,

  /**
   * Capability of Volume Snapshot Service, this enables NGT to take Applciation Consistent Snapshot.
   * This helps in Application Consistent Backup using Nutanix Guest Tools workflow.
   */
  kVssSnapshot
}

export enum NgtEnableStatus {
  /**
   * In case we could not parse and get correct status.
   */
  kUnknown,

  /**
   * If NGT is enabled on VM.
   */
  kEnabled,

  /**
   * If NGT is not enabled on VM.
   */
  kDisabled
}

export enum NgtInstallStatus {
  /**
   * In case we could not parse and get correct status.
   */
  kUnknown,

  /**
   * If NGT is installed on VM.
   */
  kInstalled,

  /**
   * If NGT is not installed on VM.
   */
  kUninstalled
}
