import { Injectable } from '@angular/core';
import { BANNER_PRIORITY, BannerConfig } from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

import { appConfigMap, translateAppName } from '../../../app-configs';
import {
  canShowThreatProtectionSubExpiryBanner,
  IrisContext,
  IrisContextService,
  isThreatProtectionTrailExpired,
  threatProtectionDaysRemaining,
} from '../../../iris-context';
import { SubsExpiryBannerConfig, ToSubsExpiryData } from './subs-expiry-banner.config';

@Injectable({
  providedIn: 'root',
})
export class ThreatProtectionSubExpiryBannerConfig extends SubsExpiryBannerConfig {
  /**
   * Unique banner representation
   */
  id = 'threat-protection-sub-expiry-banner';

  /**
   * provides banner config as observable
   */
  bannerInfo$ = this.irisCtx.irisContext$.pipe(
    map(ctx => {
      this.data = this.toSubsExpiryData(ctx);
      return [{
        id: this.id,
        priority: BANNER_PRIORITY.LOW,
        isVisible: this.allowBanner(ctx),
        status: this.getStatus(),
        allowClose: this.allowClose(),
        text: this.getText(),
      } as BannerConfig];
    })
  );

  constructor(private irisCtx: IrisContextService, translateService: TranslateService) {
    super(translateService);
  }

  /**
   * Extract relevant expiry data from context
   *
   * @param ctx iris context for extracting subscription info
   * @returns relevant expiry info for Argus
   */
  toSubsExpiryData: ToSubsExpiryData = ctx => ({
    trialExpired: isThreatProtectionTrailExpired(ctx),
    daysRemaining: threatProtectionDaysRemaining(ctx),
    appName: translateAppName(this.translateService)(appConfigMap.securityCenter.name),
  });

  /**
   * Allow banner based on context
   *
   * @param ctx iris context
   * @returns true/false based on context
   */
  allowBanner(ctx: IrisContext) {
    return super.allowBanner(ctx) && this.isLoggedIn(ctx) && canShowThreatProtectionSubExpiryBanner(ctx);
  }
}
