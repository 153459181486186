<form cohValidateOnSubmit [formGroup]="formGroup" (validatedSubmit)="onSubmit()">
  <h2 mat-dialog-title>
    {{ 'accessibleItems' | translate }}
  </h2>

  <mat-dialog-content>
    <h4>
      {{ 'helios.accessManagement.accessScope.resourcesInstruction' | translate }}
    </h4>
    <coh-resource-selection-details
      [resources]="selectedResources$ | async"
      displayMode="statList"></coh-resource-selection-details>

    <mat-error
      *ngIf="formGroup.controls.resourceIds.touched && formGroup.controls.resourceIds.errors?.required"
      cogDataId="resources-field-error-message">
      {{ 'errors.required' | translate }}
    </mat-error>
    <coh-resources-tree-table
      *ngIf="resourcesTree$ | async"
      formControlName="resourceIds"
      [data]="resourcesTree$ | async"></coh-resources-tree-table>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button
      type="button"
      mat-button
      cogCancelButton
      cogDataId="resources-cancel-button"
      [inheritDataId]="true"
      mat-dialog-close>
      {{ 'cancel' | translate }}
    </button>

    <button
      type="submit"
      mat-button
      cogSubmitButton
      [disabled]="data.readOnly"
      cogDataId="continue-button"
      [inheritDataId]="true">
      {{ 'continue' | translate }}
    </button>
  </mat-dialog-actions>
</form>
