<button mat-icon-button
        [matMenuTriggerFor]="menu"
        [matTooltip]="label"
        [attr.aria-label]="label"
        cogDataId="switch-theme-button">
  <cog-icon [ariaLabel]="label"
            shape="palette"></cog-icon>
</button>

<mat-menu #menu="matMenu">
  <button mat-menu-item
          *ngFor="let theme of themes"
          (click)="switchTheme(theme.config)"
          cogDataId="{{ theme.config.name }}-button">
    {{ theme.displayName }}
  </button>
</mat-menu>