import { Component, Input, ViewEncapsulation } from '@angular/core';
import { SeriesLineOptions, SeriesSplineOptions, SeriesAreaOptions } from 'highcharts';

import { HighchartsComponent } from '../core/index';

/**
 * Type of series for Sparkline chart.
 */
export type SparklineSeriesOptions = SeriesLineOptions | SeriesSplineOptions | SeriesAreaOptions;

/**
 * Sparkline chart component - very small line chart, drawn without axes or coordinates.
 * It presents the general shape of the variation on Y-axis over time on X-axis.
 */
@Component({
  selector: 'cog-sparkline-chart',
  templateUrl: './sparkline-chart.component.html',
  styleUrls: ['./sparkline-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SparklineChartComponent extends HighchartsComponent<SparklineSeriesOptions> {

  /**
   * Optional title to be displayed above the sparkline.
   */
  @Input() title: string;

  constructor() {
    super({
      chart: {
        type: 'line',
        spacing: [0, 0, 0, 0],
        styledMode: true
      },

      title: null,

      legend: {
        enabled: false,
      },


      credits: {
        enabled: false,
      },

      tooltip: {
        enabled: true,
        shared: true,
        crosshairs: true,
      },

      xAxis: {
        visible: false,
      },

      yAxis: {
        visible: false,
        endOnTick: false,
        startOnTick: false,
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        tickPositions: [0],
      },

      plotOptions: {
        areaspline: {
          marker: {
            enabled: false,
          }
        },

        line: {
          marker: {
            enabled: false,
          }
        },

        spline: {
          marker: {
            enabled: false,
          }
        },
      },

      series: []
    });
  }
}
