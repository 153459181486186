<!-- This form control is dynamically added or removed based on the source type -->
<cog-settings-list-item
  [formGroup]="formGroup"
  [invalid]="formControl?.invalid"
  [label]="'qosPolicy' | translate">
  <cog-settings-list-item-value-display>
    {{getQoSSettingValue(formControl?.value) | translate}}
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor [formGroup]="formGroup"
    cogDataId="settings-qos-policy">
    <mat-form-field>
      <mat-label translate>qosPolicy</mat-label>
      <mat-select [formControlName]="name"
        [inheritDataId]="true"
        cogDataId="select">
        <mat-option [inheritDataId]="true"
          cogDataId="option-hdd"
          value="kBackupHDD">
          {{backupName  | translate}}
        </mat-option>

        <!-- Dont include ssd for PXG1 platform. -->
        <mat-option [inheritDataId]="true"
          *ngIf="!isDisAggregatedStorage"
          cogDataId="option-ssd"
          value="kBackupSSD">
          {{(allFlashDefault ? 'backupSsdDefault' : 'backupSsd')  | translate}}
        </mat-option>

        <!-- Only used by Oracle Adapter -->
        <mat-option [inheritDataId]="true"
          *ngIf="canSupportTestAndDevQoSPolicy"
          cogDataId="option-test-and-dev-high"
          value="kTestAndDevHigh">
          {{'testAndDevHigh' | translate}}
        </mat-option>

        <!-- Only used when flag enableBtAllQoSForBackups is set to true -->
        <mat-option [inheritDataId]="true"
          *ngIf="canSupportBackupAllQoSPolicy"
          cogDataId="option-backup-all"
          value="kBackupAll">
          {{(backupAllDefault ? 'backupAllDefault' : 'backupAll') | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
