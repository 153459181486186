// Component: user-groups component
import { getUserTenantId } from '@cohesity/iris-core';

;(function(angular) {
  'use strict';

  var modName = 'C.userGroups';
  var componentName = 'userGroups';

  var configOptions = {
    bindings: {
      /**
       * Navigation type string variable which can take the following values
       *  - 'modal'
       *  - undefined ('page' by default)
       *
       * If navigation type is modal then view/edit principal will
       * open in a modal else then will open in a page.
       */
      navigationType: '<?',

      /**
       * Optional function if provided this function will be used to determine
       * if a principal is selectable or not else by default all principals are
       * considered selectable when modifications are allowed.
       */
      canSelectPrincipal: '=?',

      /**
       * Optional enum with list of types to display. Default is undefined = all
       */
      restrictTypes: '<?',

      /**
       * Optional attribute sniffed value. If present then principal edit/delete
       * is allowed else those options will be hidden.
       */
      // allowModifications: '?',

      /**
       * Optional attribute sniffed value. If present then show create principal
       * button and allow new principal creation in modal.
       *
       * TODO: Haven't enabled modal for tjhe user creation yet. Just went with
       *       UI.
       */
      // allowUserCreation: '?',

      /**
       * Optional attribute sniffed value. If present then show all local and
       * organization users and it will show all groups irrespective of their
       * domain's ownership.
       */
      // includeAllPrincipals: '?',

      /**
       * Purpose for which the list is being rendered
       * possible values: 'access-management', 'tenant-assignment'
       */
      purpose: '=?',

      /**
       * The tenant which want to assign users/groups to.
       */
      tenantInformation: '<?',
    },
    require: {
      // Optional ngModel to bind the selected principals to the
      // parent (caller) => to the parent component
      ngModel: '?ngModel',
    },
    controller: 'UserGroupsCtrl',
    templateUrl: 'app/admin/access-management/user-groups/user-groups.html',
  };

  angular.module(modName)
    .controller('UserGroupsCtrl', userGroupsCtrlFn)
    .component(componentName, configOptions);

  /**
   * @ngdoc component
   * @name C.userGroups:userGroups
   * @function
   *
   * @description
   * Provides the list of user/groups which can be modified too.
   *
   * @example
   *
   * 1. Basic usage.
   *    <user-groups />
   *
   * 2. Usage where users can modify the list (edit/delete).
   *    <user-groups allow-modifications />
   *
   * 3. Usage where the parent can access the selected principals where the
   *    $ctrl.modelName is the name for the model inside the parent controller.
   *    <user-groups ng-model="$ctrl.modelName"/>
   *
   * 4. Usage where we want to enable user creation inside the component itself.
   *    <user-groups allow-user-creation />
   */
  function userGroupsCtrlFn($rootScope, $state, $q, $log, $attrs, cUtils,
    evalAJAX, UserService, GroupService, ActiveDirectoryService, FEATURE_FLAGS,
    TenantService, ClusterService, IdpService, $translate, cMessage, ngDialogService, NgIrisContextService,
    NgUserStoreService) {
    const userTenantId = getUserTenantId(NgIrisContextService.irisContext);

    var $ctrl = this;

    var loggedInUser = $rootScope.user;
    var isAdminLoggedIn = loggedInUser.username === 'admin' &&
      loggedInUser.domain === 'LOCAL';
    var undeletableAccounts = isAdminLoggedIn ? 1 : 2;
    var isRestrictedUser = NgUserStoreService.isRestrictedUser();
    var isTenantUser = $rootScope.isTenantUser();

    // Hash of principals selected in the UI
    var selectedPrincipals = {};

    var principalFilterTypes = [
      {
        name: $translate.instant('allUsersAndGroups'),
        value: undefined,
      },
      {
        name: $translate.instant('users'),
        value: 'users',
      },
      {
        name: $translate.instant('groups'),
        value: 'groups',
      },
    ];

    // declare component methods
    _.assign($ctrl, {
      FEATURE_FLAGS: FEATURE_FLAGS,
      filterDomains: [],
      filters: {},
      filterTypes: principalFilterTypes,
      numBuiltinPrincipals: 0,
      totalChecked: 0,

      // controller function exposed to template
      canDeletePrincipal: canDeletePrincipal,
      canLockPrincipal: canLockPrincipal,
      canEditPrincipal: canEditPrincipal,
      deletePrincipals: deletePrincipals,
      editPrincipal: editPrincipal,
      getPrincipals: getPrincipals,
      isSelected: isSelected,
      modifyPrincipalModal: modifyPrincipalModal,
      showCheckbox: showCheckbox,
      stringifyRoles: stringifyRoles,
      toggleRowSelection: toggleRowSelection,
      toggleSelectAll: toggleSelectAll,
      viewPrincipal: viewPrincipal,
      updateLockInfo: updateLockInfo,
      updateMFA: updateMFA,

      // component life cycle methods
      $onInit: $onInit,
    });

    /**
     * initialize component context
     *
     * @method   _initializeCtrl
     */
    function _initializeCtrl() {
      _.assign($ctrl, {
        // List of all principals
        allPrincipals: [],

        // All role options
        roleOptions: {},

        // map of applied filter.
        filter: {
          tenantIds: [],
        },

        allowModifications: $attrs.hasOwnProperty('allowModifications'),
        allowUserCreation: $attrs.hasOwnProperty('allowUserCreation'),
        includeAllPrincipals: $attrs.hasOwnProperty('includeAllPrincipals'),
        canSelectPrincipal: $ctrl.canSelectPrincipal || _.constant(true),
        restrictTypes: $ctrl.restrictTypes || ['user', 'group'],
        purpose: $ctrl.purpose || 'access-management',
      });
    }

    /**
     * initialize the component
     *
     * @method   $onInit
     */
    function $onInit() {
      // Check if ngModel exists. If it does, then assign the value to the
      // selectedPrincipals.
      if ($ctrl.ngModel) {
        $ctrl.ngModel.$render =
          function updateInternalModalOnExternalChange() {
            selectedPrincipals = $ctrl.ngModel.$modelValue;
            $ctrl.totalChecked = Object.keys(selectedPrincipals).length;
          };
      }
      _initializeCtrl();
      _fetchDependencies().then(
        function fetchedDependencies() {
          $ctrl.debouncedGetPrincipals();
        },
        evalAJAX.errorMessage
      );
    }

    /**
     * Update the list of selected Principals
     *
     * @method   updateSelected
     * @param   {string}  action      action to apply
     * @param   {object}  principal   A principal object
     */
    function updateSelected(action, principal) {
      var alreadySelected = selectedPrincipals[principal.sid] !== undefined;

      if (!alreadySelected && principal._isDeletable && action === 'add') {
        selectedPrincipals[principal.sid] = principal;
        $ctrl.totalChecked++;
      }

      if (alreadySelected && principal._isDeletable && action === 'remove') {
        delete selectedPrincipals[principal.sid];
        $ctrl.totalChecked--;
      }

      // Update the external modal if ngModel binding exists.
      if ($ctrl.ngModel) {
        $ctrl.ngModel.$setViewValue(selectedPrincipals);
      }
    }

    /**
     * Update the MFA preference for the user.
     *
     * @param   {object}   principal    The selected user principal
     */
    function updateMFA(principal) {

      // Update this field manually. As ng-model cannot handle the conversion.
      var isUserExemptFromMfa = !principal.mfaInfo.mfaEnabled;
      var dialog$ = isUserExemptFromMfa ? ngDialogService
        .showDialog('disable-mfa-current-user', {})
        .toPromise() : $q.resolve(true);

      principal.mfaInfo.isUserExemptFromMfa = isUserExemptFromMfa;

      dialog$.then(accepted => {

        // If the user has chosen to cancel the MFA disablement.
        if (!accepted) {
          principal.mfaInfo.isUserExemptFromMfa = true;
          principal.mfaInfo.mfaEnabled = true;
          return;
        }

        UserService.updateUser(principal).then(
          function userUpdated() {
            cMessage.success({
              textKey: 'mfa.user.updated',
            });
          },
          function handleError(err) {
            evalAJAX.errorMessage(err);
            principal.mfaInfo.isUserExemptFromMfa =
              !principal.mfaInfo.isUserExemptFromMfa;
          }
        );
      });
    }

    /**
     * Determines Whether user is part of ADP domain
     * @param {object} principal The selected user principal
     * @returns true if its adp user
     */
    function isAdpUser(principal) {
      return principal.type === 'user' && $ctrl.adpDomains.includes(principal.domain);
    }

    /**
     * Select all principals/rows
     *
     * @method   selectAll
     */
    function selectAll() {
      for (var i = 0, len = $ctrl.allPrincipals.length; i < len; i++) {
        if (canSelect($ctrl.allPrincipals[i])) {
          updateSelected('add', $ctrl.allPrincipals[i]);
        }
      }
    }

    /**
     * Deselect all principals/rows
     *
     * @method   deselectAll
     */
    function deselectAll() {
      for (var i = 0, len = $ctrl.allPrincipals.length; i < len; i++) {
        updateSelected('remove', $ctrl.allPrincipals[i]);
      }
    }

    /**
     * Toggle selection state of all principals/rows.
     * Used by ng-click for the select all checkbox
     *
     * @method  toggleSelectAll
     * @param   {object}  e       The event (click)
     */
    function toggleSelectAll(e) {
      if (e.target.checked) {
        selectAll();
      } else {
        deselectAll();
      }
    }

    /**
     * Toggle selection state for a single principal/row.
     * Used by ng-click for each <tr>.
     *
     * @method  toggleRowSelection
     * @param   {object}  principal   A principal object
     */
    function toggleRowSelection(principal) {
      if (!canSelect(principal)) {
        return;
      }
      updateSelected(isSelected(principal) ? 'remove' : 'add', principal);
    }

    /**
     * Test whether a provided principal is selected.
     * Used by ng-checked for each checkbox
     *
     * @method     isSelected
     * @param      {object}   principal  a principal object
     * @returns     {boolean}  true if selected, false otherwise.
     */
    function isSelected(principal) {
      return selectedPrincipals[principal.sid] !== undefined;
    }

    /**
     * Convert array of roles to comma seperated string for
     * pretty display in table
     *
     * @method     stringifyRoles
     * @param      {array}   roles   A list of roles
     * @return     {string}  string list of roles
     */
    function stringifyRoles(roles) {
      var rolesArray = [];
      var role;

      if (!roles) {
        return '-';
      }

      for (var i = 0, len = roles.length; i < len; i++) {
        role = $ctrl.roleOptions[roles[i]] || {};

        if (!role.label) {
          $log.error('Unknown role ID: ' + roles[i]);
        }

        rolesArray.push(role.label || '-');
      }

      return rolesArray.join(', ');
    }

    /**
     * Validate selected principals and remove principals which can not be
     * selected from the cart.
     *
     * @method   validateSelectedPrincipals
     */
    function validateSelectedPrincipals() {
      $ctrl.allPrincipals.forEach(function eachPrincipals(principal) {
        if (isSelected(principal) && !$ctrl.canSelectPrincipal(principal)) {
          updateSelected('remove', principal);
        }
      });
    }

    /**
     * Launches create new or edit principal modal and update the list on save
     *
     * @method   modifyPrincipalModal
     * @param    {Object}   principal   The principal that is supposed to be
     *                                  edited. If not present that means the
     *                                  modal was opened to create one.
     */
    function modifyPrincipalModal(principal) {
      UserService.modifyUserModal(principal).then(
        function gotModifyedUser(newPrincipal) {
          // If the principal exists, then the modal was opened for editing only
          if (principal) {
            getPrincipals();
          } else {
            // Create an array for handling the case of AD user creation as
            // when we create an AD user, it gives the newly created principals
            // as an Array because we can create multiple AD users.
            var principals = newPrincipal.domain === 'LOCAL' ? [newPrincipal] :
              newPrincipal.principals;

            _.map(principals, function mapPrincipals(principal) {
              var processedPrincipal = processUser(principal);

              $ctrl.allPrincipals.push(processedPrincipal);

              // Select the newly created principal if it can be.
              if ($ctrl.canSelectPrincipal(principal)) {
                updateSelected('add', processedPrincipal);
              }
            });
          }
        }
      );
    }

    /**
     * Go to the edit principal view or launch the edit user modal
     *
     * @method     editPrincipal
     * @param      {object}  principal  A principal object
     */
    function editPrincipal(principal) {
      if ($ctrl.navigationType === 'modal') {
        modifyPrincipalModal(principal);
        return;
      }

      $state.go('edit-principal', {
        name: principal.name,
        type: principal.type,
        domain: principal.domain,
        tenantId: principal.tenantId,
      });
    }

    /**
     * Makes a post request when lock/unlock action is performed.
     *
     * @function     updateLockInfo
     * @param      {object}  principal  A principal object.
     */
    function updateLockInfo(principal) {
      UserService.updateUser({ ...principal, isAccountLocked: !principal.isAccountLocked }).then(getPrincipals, evalAJAX.errorMessage);
    }

    /**
     * Go to the view principal page
     *
     * @method     viewPrincipal
     * @param      {object}  principal  A principal object
     */
    function viewPrincipal(principal) {
      if ($ctrl.navigationType === 'modal') {
        // refresh the principal list on close because provided principal may
        // have been removed or edited.
        UserService.viewUserDetailsModal(principal).then(getPrincipals);
        return;
      }

      $state.go('view-principal', {
        name: principal.name,
        type: principal.type,
        domain: principal.domain,
        sid: principal.sid,
      });
    }

    /**
     * Delete selected principals and update list
     *
     * @method     deletePrincipals
     * @param      {Object}  [principal]  A single principal to delete. If
     *                                    absent, will look at
     *                                    $ctrl.selectedPrincipals
     */
    function deletePrincipals(principal) {
      var principalsToDelete = {};

      $ctrl.deletingPrincipal = true;

      // Populate principalsToDelete with the single principal argument
      // or else the entire set of selectedPrincipals
      if (principal) {
        principalsToDelete[principal.sid] = principal;
      } else {
        principalsToDelete = selectedPrincipals;
      }

      function successFn(principals) {
        // Remove these from the list of selectedPrincipals
        angular.forEach(principals, function deselectPrincipal(principal) {
          updateSelected('remove', principal);
        });

        getPrincipals();
      }

      UserService.deletePrincipals(principalsToDelete, successFn).finally(
        function deletePrincipalsFinally() {
          $ctrl.deletingPrincipal = false;
        }
      );
    }

    /**
     * Process the users response and add to list of allPrincipals.
     *
     * @method  processUsersList
     * @param   {Array}  users   List of user objects
     */
    function processUsersList(users) {
      $ctrl.allPrincipals =
        $ctrl.allPrincipals.concat(users.map(processUser));
    }

    /**
     * Transforms the user object as required.
     *
     * @param   {Object}   user  The user object.
     * @returns {Object}   The processed user.
     */
    function processUser(user) {
      // Identify the local admin's sid
      if (!$ctrl.localAdminId && user._isLocalAdmin) {
        $ctrl.localAdminId = user.sid;
      }

      // Identify loggedInUser's sid
      if (!$ctrl.loggedInUserId && user._isLoggedInUser) {
        $ctrl.loggedInUserId = user.sid;
      }
      if (!user._isDeletable) {
        $ctrl.numBuiltinPrincipals++;
      }

      user._stringifiedRoles = stringifyRoles(user.roles);

      // User object is viewable if it's the logged-in user's own profile OR
      user._isViewable = user._isLoggedInUser || (

        // Logged-in user can modify principals/keys AND
        ($rootScope.user.privs.PRINCIPAL_MODIFY ||
         $rootScope.user.privs.MANAGE_S3_KEY) &&

        // Logged-in User is not restricted AND
        !isRestrictedUser &&

        // The user object is not the built-in local admin AND
        !user._isLocalAdmin &&

        // (Logged-in User is not a tenant OR
        //  Logged-in User is a tenant and the user object is not built-in.
        (!isTenantUser || !user._isBuiltIn)
      );

      user._hasTenantAccess = !UserService.isNotTenantAuthorized(user);

      // Initialize MFA Information for the user if not enabled.
      if (!user.mfaInfo) {
        user.mfaInfo = {
          isUserExemptFromMfa: false,
          mfaEnabled: true,
        };
      } else {
        user.mfaInfo.mfaEnabled = !user.mfaInfo.isUserExemptFromMfa;
      }

      user.adpMfaEnabled = isAdpUser(user);

      return user;
    }

    /**
     * Process the groups response and add to list of allPrincipals.
     *
     * @method  processGroups
     * @param   {Array}  groups   List of group objects
     */
    function processGroups(groups) {
      groups.forEach(function transformGroup(group) {
        if (!group._isDeletable) {
          $ctrl.numBuiltinPrincipals++;
        }
        group.type = 'group';
        group._stringifiedRoles = stringifyRoles(group.roles);
        $ctrl.allPrincipals.push(group);

        // User object is viewable if logged-in user can modify principals AND
        group._isViewable = $rootScope.user.privs.PRINCIPAL_MODIFY &&

          // Logged-in User is not restricted AND
          !isRestrictedUser &&

          // (Logged-in User is not a tenant OR
          //  Logged-in User is a tenant and the group object is not built-in.
          (!isTenantUser || !group._isBuiltIn);

        group._hasTenantAccess = !UserService.isNotTenantAuthorized(group);
      });
    }

    /**
     * Process the roles response and set up the Roles filter options.
     *
     * @method     _processRoles
     * @param      {Array}  roles   List of role objects
     */
    function _processRoles(roles) {
      // Seed list with the basic "All Roles" option.
      $ctrl.roleOptions.ALL_ROLES = {
        label: 'allRoles',
        name: 'ALL_ROLES',
      };

      // Add to the list any other roles in use on this Cluster.
      roles.forEach(function processRolesForEach(role) {
        $ctrl.roleOptions[role.name] = role;
      });
    }

    /**
     * Process the Active Directory and SSO Domain response and set up the
     * domain filter options.
     *
     * @method     _processDomains
     * @param      ads    The list of Active Directory domains
     * @param      idps   The list of IDP domains.
     */
    function _processDomains(ads, idps) {
      // Assign the set of available domains.
      $ctrl.filterDomains = _.concat(['allDomains', 'LOCAL'],
        ActiveDirectoryService.allTrustedDomainsCache,
        _.map(idps, 'domain'));
    }

    /**
     * Returns the query params based on active filters.
     *
     * @method    _getQueryParams
     * @returns   {Object}   query params
     */
    function _getQueryParams() {
      return {
        domain: $ctrl.filters.activeDomainFilter === 'allDomains' ? undefined :
          $ctrl.filters.activeDomainFilter,

        // The following have to explicitly be reset to `undefined` because
        // empty string does not yield expected result.
        usernames: $ctrl.filters.search || undefined,
        name: $ctrl.filters.search || undefined,
      };
    }

    /**
     * Gets dependencies needed before fetching principals.
     *
     * @method    _fetchDependencies
     * @returns   {Object}   Promise with dependent rest API calls.
     */
    function _fetchDependencies() {
      var promises = {
        roles: UserService.getAllRoles(),
        ads: ActiveDirectoryService.getActiveDirectories(),
        idps: IdpService.getAllExternalIdp(),
      };

      if (FEATURE_FLAGS.mfaEnabled) {
        promises.mfa = UserService.getMfaConfig();
      }
      $ctrl.loading = true;

      return $q.all(promises).then(
        function getDataSuccess(response) {
          _processRoles(response.roles);
          _processDomains(response.ads, response.idps);
          if (!!response.mfa) {
            $ctrl.isMfaEnabled = response.mfa.enabled;
          }
          $ctrl.adpDomains = response.ads ?
          _.map(response.ads, function (adp) { return adp.domainName })
          : [];
        }
      );
    }

    /**
     * Whether to show checkbox or not.
     *
     * @method    showCheckbox
     * @param   {Object}   User
     * @returns   {boolean}   Show checkbox or not.
     */
    function showCheckbox(principal) {
      if ($ctrl.purpose === 'tenant-assignment') {
        if ($ctrl.tenantInformation &&
          $ctrl.tenantInformation.bifrostEnabled &&
          $ctrl.tenantInformation.activeDirectories?.length) {
          // Loop all ad's domainName, to find whether principal's domain matches one of the ad domain.
          var containMatchedDomain = $ctrl.tenantInformation.activeDirectories.some(ad =>
            ad.domainName === principal.domain);

          if (containMatchedDomain) {
            return canAssignPrincipal(principal);
          } else {
            return false;
          }
        } else {
          return canAssignPrincipal(principal);
        }
      }

      // if purpose is access-management, checkbox is enabled only if
      //  the principal can be deleted, as delete is the only action available
      // canDelete should be executed only after canEdit for performance reasons
      return canEditPrincipal(principal) && canDeletePrincipal(principal);
    }

    /**
     * Returns boolean whether a principal can be selected or not
     *
     * @method    canSelect
     * @param     {Object}    User
     * @returns   {boolean}   can select or not
     */
    function canSelect(principal) {
      return showCheckbox(principal) && $ctrl.canSelectPrincipal(principal);
    }

    /**
     * Call API service to get all principals
     *
     * @method   getPrincipals
     */
    function getPrincipals() {
      var params = _getQueryParams();
      var promises = {};

      if ($ctrl.includeAllPrincipals) {
        _.assign(params, TenantService.extendWithTenantParams({
          _includeTenantInfo: $ctrl.includeAllPrincipals,
        }, $ctrl.filter.tenantIds));
      }

      if ($ctrl.restrictTypes.includes('user') &&
        ($ctrl.filters.activePrincipalType || {}).value !== 'groups') {
        promises.users = UserService.getAllUsers(params);
      }

      if (!$ctrl.includeAllPrincipals) {
        _.assign(params, {
          filterByOwnedDomains: true,
        });
      }

      if ($ctrl.restrictTypes.includes('group') &&
        ($ctrl.filters.activePrincipalType || {}).value !== 'users') {
        promises.groups = GroupService.getGroups(params);
      }

      $ctrl.loading = true;
      $q.all(promises).then(
        function getDataSuccess(response) {
          $ctrl.allPrincipals.length = 0;
          processUsersList(response.users || []);
          processGroups(response.groups || []);

          // If ng-model is present don't deselect the values, use the ng-model
          // value instead.
          if (!$ctrl.ngModel) {
            deselectAll();
          } else {
            validateSelectedPrincipals();
          }
        },
        evalAJAX.errorMessage
      ).finally(
        function getDataFinally() {
          $ctrl.loading = false;
        }
      );
    }

    /**
     * Determines whether the logged in user is allowed to edit a principal.
     *
     * @param   {Object}   principal   The `principal` object.
     * @return  {Boolean}  True if allowed to edit principal.
     */
    function canEditPrincipal(principal) {
      // If this is the logged-in user's own principal OR
      if (principal._isLoggedInUser ||
        // Logged-in user is allowed to edit principals AND
        loggedInUser.privs.PRINCIPAL_MODIFY &&
        // this principal is not the built-in local admin.
        !principal._isLocalAdmin) {
        if (principal.tenantIds) {
          if (userTenantId) {
            return principal.tenantIds.length === 1 &&
              principal.tenantIds[0] === userTenantId;
          }

          return principal.tenantIds.length !== 1;
        }

        return principal.tenantId === userTenantId;
      }

      return false;
    }

    /**
     * Determines whether the logged in user is allowed to delete a principal.
     * This condition should be used only when canEditPrincipal is true (for performance reasons)
     *
     * @param   {Object}   principal   The `principal` object.
     * @return  {Boolean}  True if allowed to delete principal.
     */
    function canDeletePrincipal(principal) {
      return principal._isDeletable && (userTenantId ||
        (!principal.tenantIds || !principal.tenantIds.length));
    }

    /**
     * Determines whether the logged in user is allowed to lock/unlock a principal.
     * This condition should be used only when canEditPrincipal is true (for performance reasons).
     *
     * @param   {object}   principal   The `principal` object.
     * @return  {boolean}  True if allowed to lock principal.
     */
    function canLockPrincipal(principal) {
      // don't show lock/un-lock during tenant assignment.
      // Default admin can't be edited.
      return $ctrl.purpose !== 'tenant-assignment' && !principal._isLocalAdmin &&

        // Only local users may be locked, not local groups nor any AD principal
        principal.domain === 'LOCAL' && principal.type === 'user' &&

        // ensure principal and the logged-in user belong to the same tenant.
        principal.tenantId === userTenantId &&

        // user with required privilege is allowed to edit.
        $rootScope.user.privs.PRINCIPAL_MODIFY;
    }

    /**
     * Determines whether the logged in user is allowed to assign a principal.
     *
     * @param   {Object}   principal   The `principal` object.
     * @return  {Boolean}  True if allowed to assign principal.
     */
    function canAssignPrincipal(principal) {
      // principal._isDeletable internally checks for
      // !isLoggedInUser, !isAdminUser and !isBuiltInUser
      if ($rootScope.user.privs.PRINCIPAL_MODIFY && principal._isDeletable) {
        if (principal.tenantIds) {
          if (userTenantId) {
            return principal.tenantIds.length === 1 &&
              principal.tenantIds[0] === userTenantId;
          }

          return true;
        }

        return principal.tenantId === userTenantId;
      }

      return false;
    }

    // Debounce until all the params from localStorage have been set to the
    // filters in order to ensure the data is loaded only once on page load.
    // Otherwise, it will load twice.
    $ctrl.debouncedGetPrincipals = _.debounce(function customLoadDataDebounce() {
        // Once the page load is finished remove the debounce, so that the
        // getPrincipals is called immediately upon changing a filter.
        $ctrl.debouncedGetPrincipals = getPrincipals;
        return getPrincipals();
      }, 200);
  }
})(angular);
