{{
  (
    config.value ? config.value :
    config.type === 'date' ? (columnValue | cogDate) :
    config.type === 'arrayLength' ? (columnValue?.length || ('naNotApplicable' | translate)) :
    config.durationStartTime ? (columnValue === null ? ('naNotApplicable' | translate) : (columnValue | cogDuration)) :
    (config.translatePrefix || config.translate) ? (((config.translatePrefix || '') + columnValue) | translate) :
      columnValue
  ) ||
  ('naNotApplicable' | translate)
}}
