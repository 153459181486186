/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DataProtectUsage } from '../models/data-protect-usage';
import { RpaasArchivalUsage } from '../models/rpaas-archival-usage';
import { RpaasUsage } from '../models/rpaas-usage';
@Injectable({
  providedIn: 'root',
})
class HeliosDataProtectStatsService extends __BaseService {
  static readonly GetDataProtectUsagePath = '/mcm/data-protect/usage-stats';
  static readonly GetRpaasArchivalUsagePath = '/mcm/ransomware-shield/archival-usage';
  static readonly GetRpaasUsagePath = '/mcm/ransomware-shield/usage-stats';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Data-Protect Usage Statistics
   *
   * Returns the current months usage for DMaaS. This internal api is to be used for display stats on DMaaS.
   * @param params The `HeliosDataProtectStatsService.GetDataProtectUsageParams` containing the following parameters:
   *
   * - `toUsecs`: End time to query for consumption of the current month
   *
   * - `regionIds`: Specifies the list of region ids. Only applicable in case of DMaaS.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `fromUsecs`: Start time to query for consumption of the current month
   *
   * @return Success
   */
  GetDataProtectUsageResponse(params: HeliosDataProtectStatsService.GetDataProtectUsageParams): __Observable<__StrictHttpResponse<DataProtectUsage>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.toUsecs != null) __params = __params.set('toUsecs', params.toUsecs.toString());
    (params.regionIds || []).forEach(val => {if (val != null) __params = __params.append('regionIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.fromUsecs != null) __params = __params.set('fromUsecs', params.fromUsecs.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/data-protect/usage-stats`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DataProtectUsage>;
      })
    );
  }
  /**
   * Data-Protect Usage Statistics
   *
   * Returns the current months usage for DMaaS. This internal api is to be used for display stats on DMaaS.
   * @param params The `HeliosDataProtectStatsService.GetDataProtectUsageParams` containing the following parameters:
   *
   * - `toUsecs`: End time to query for consumption of the current month
   *
   * - `regionIds`: Specifies the list of region ids. Only applicable in case of DMaaS.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `fromUsecs`: Start time to query for consumption of the current month
   *
   * @return Success
   */
  GetDataProtectUsage(params: HeliosDataProtectStatsService.GetDataProtectUsageParams): __Observable<DataProtectUsage> {
    return this.GetDataProtectUsageResponse(params).pipe(
      __map(_r => _r.body as DataProtectUsage)
    );
  }

  /**
   * RPaaS Archival Usage Statistics
   *
   * Returns the latest archival usage for RPaaS.
   * @param params The `HeliosDataProtectStatsService.GetRpaasArchivalUsageParams` containing the following parameters:
   *
   * - `toTimeUsecs`: End time in microseconds to query for RPaaS archival usage. Default value is the current time.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `fromTimeUsecs`: Start time in microseconds to query for RPaaS archival usage. Default value is a week ago from the current time.
   *
   * @return Success
   */
  GetRpaasArchivalUsageResponse(params: HeliosDataProtectStatsService.GetRpaasArchivalUsageParams): __Observable<__StrictHttpResponse<RpaasArchivalUsage>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.toTimeUsecs != null) __params = __params.set('toTimeUsecs', params.toTimeUsecs.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.fromTimeUsecs != null) __params = __params.set('fromTimeUsecs', params.fromTimeUsecs.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/ransomware-shield/archival-usage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RpaasArchivalUsage>;
      })
    );
  }
  /**
   * RPaaS Archival Usage Statistics
   *
   * Returns the latest archival usage for RPaaS.
   * @param params The `HeliosDataProtectStatsService.GetRpaasArchivalUsageParams` containing the following parameters:
   *
   * - `toTimeUsecs`: End time in microseconds to query for RPaaS archival usage. Default value is the current time.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `fromTimeUsecs`: Start time in microseconds to query for RPaaS archival usage. Default value is a week ago from the current time.
   *
   * @return Success
   */
  GetRpaasArchivalUsage(params: HeliosDataProtectStatsService.GetRpaasArchivalUsageParams): __Observable<RpaasArchivalUsage> {
    return this.GetRpaasArchivalUsageResponse(params).pipe(
      __map(_r => _r.body as RpaasArchivalUsage)
    );
  }

  /**
   * RPaaS Usage Statistics
   *
   * Returns the data usage for RPaaS.
   * @param params The `HeliosDataProtectStatsService.GetRpaasUsageParams` containing the following parameters:
   *
   * - `toTimeUsecs`: End time in microseconds to query for RPaaS data consumption.
   *
   * - `regionIds`: Specifies the list of region ids.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `fromTimeUsecs`: Start time in microseconds to query for RPaaS data consumption.
   *
   * @return Success
   */
  GetRpaasUsageResponse(params: HeliosDataProtectStatsService.GetRpaasUsageParams): __Observable<__StrictHttpResponse<RpaasUsage>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.toTimeUsecs != null) __params = __params.set('toTimeUsecs', params.toTimeUsecs.toString());
    (params.regionIds || []).forEach(val => {if (val != null) __params = __params.append('regionIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.fromTimeUsecs != null) __params = __params.set('fromTimeUsecs', params.fromTimeUsecs.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/mcm/ransomware-shield/usage-stats`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RpaasUsage>;
      })
    );
  }
  /**
   * RPaaS Usage Statistics
   *
   * Returns the data usage for RPaaS.
   * @param params The `HeliosDataProtectStatsService.GetRpaasUsageParams` containing the following parameters:
   *
   * - `toTimeUsecs`: End time in microseconds to query for RPaaS data consumption.
   *
   * - `regionIds`: Specifies the list of region ids.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `fromTimeUsecs`: Start time in microseconds to query for RPaaS data consumption.
   *
   * @return Success
   */
  GetRpaasUsage(params: HeliosDataProtectStatsService.GetRpaasUsageParams): __Observable<RpaasUsage> {
    return this.GetRpaasUsageResponse(params).pipe(
      __map(_r => _r.body as RpaasUsage)
    );
  }
}

module HeliosDataProtectStatsService {

  /**
   * Parameters for GetDataProtectUsage
   */
  export interface GetDataProtectUsageParams {

    /**
     * End time to query for consumption of the current month
     */
    toUsecs?: null | number;

    /**
     * Specifies the list of region ids. Only applicable in case of DMaaS.
     */
    regionIds?: null | Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Start time to query for consumption of the current month
     */
    fromUsecs?: null | number;
  }

  /**
   * Parameters for GetRpaasArchivalUsage
   */
  export interface GetRpaasArchivalUsageParams {

    /**
     * End time in microseconds to query for RPaaS archival usage. Default value is the current time.
     */
    toTimeUsecs?: null | number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Start time in microseconds to query for RPaaS archival usage. Default value is a week ago from the current time.
     */
    fromTimeUsecs?: null | number;
  }

  /**
   * Parameters for GetRpaasUsage
   */
  export interface GetRpaasUsageParams {

    /**
     * End time in microseconds to query for RPaaS data consumption.
     */
    toTimeUsecs?: null | number;

    /**
     * Specifies the list of region ids.
     */
    regionIds?: null | Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Start time in microseconds to query for RPaaS data consumption.
     */
    fromTimeUsecs?: null | number;
  }
}

export { HeliosDataProtectStatsService }
