import { getConfigByKey } from '@cohesity/iris-core';
import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const staticRoutesAccess = (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.staticRoutesEnabled &&
 ctx.CLUSTER_MODIFY && getConfigByKey(ctx.irisContext, 'networking.routes', true);

export const StaticRoutesConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'networking.routes.**',
      url: '^/platform/networking/routes',
      loadChildren: () => import('./static-routes.module').then(m => m.StaticRoutesModule)
    },
  ],
  getStateAccessMap(): StateAccessMap {
    return {
      'networking.routes': staticRoutesAccess,
    };
  }
};
