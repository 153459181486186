import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ProtectionSourcesServiceApi } from '@cohesity/api/v1';
import { filter, startWith } from 'rxjs/operators';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { Environment } from 'src/app/shared/constants';
import { BaseProtectionBuilderComponent } from '../../../../protection-builder/components/base-protection-builder/base-protection-builder.component';
import { ProtectionItemName } from '../../../../protection-builder/models';

type SnapshotsCarrier = 'storageArray' | 'hyperFlex' | 'nutanix';

interface StorageSnapshot {
  enabled: boolean;
  snapshotsCarrier: SnapshotsCarrier;
}

/**
 * Default value for storage snapshot.
 */
export const DefaultStorageSnapshot: StorageSnapshot = {
  enabled: false,
  snapshotsCarrier: 'storageArray',
};

@Component({
  selector: 'coh-settings-list-leverage-storage-snapshots',
  templateUrl: './settings-list-leverage-storage-snapshots.component.html',
  styleUrls: ['./settings-list-leverage-storage-snapshots.component.scss'],
})
export class SettingsListLeverageStorageSnapshotsComponent extends BaseProtectionBuilderComponent<
  StorageSnapshot,
  any
> {
  /**
   * Default value for Leverage Storage Snapshots for Data Protection.
   */
  _value: StorageSnapshot = DefaultStorageSnapshot;

  /**
   * Form group for storage snapshots settings.
   */
  storageSnapshotFormGroup = new UntypedFormGroup({
    enabled: new UntypedFormControl(this.value.enabled, Validators.required),
    snapshotsCarrier: new UntypedFormControl(this.value.snapshotsCarrier, Validators.required),
  });

  /**
   * Variable to hold the current environment.
   */
  environment: Environment;

  /**
   * feature flag nutanix snapshot feature is enabled.
   */
  leverageNutanixSnapshots = false;

  /**
   * Flag to indicate if the storage snapshot toggle has been disabled
   */
  isStorageSnapshotToggleDisabled = false;

  /**
   * feature flag ibm flashsystem snapshot feature is enabled.
   */
  leverageIbmSnapshots = flagEnabled(this.irisCtx.irisContext, 'leverageIbmSnapshots');

  constructor(private irisCtx: IrisContextService, private protectionSource: ProtectionSourcesServiceApi) {
    super();
  }

  /**
   * Add the leverage storage snapshots setting form group as control.
   */
  addFormControl() {
    this.formGroup.addControl(this.name, this.storageSnapshotFormGroup);
  }

  /**
   * Form control init.
   */
  initFormControl() {
    const sourceFormControl = this.formGroup.parent.get(ProtectionItemName.Source);

    if (sourceFormControl) {
      sourceFormControl.valueChanges
        .pipe(
          this.untilDestroy(),
          startWith(sourceFormControl.value),
          filter(value => !!value)
        )
        .subscribe(value => {
          this.environment = value.protectionSource.environment;

          // Show nutanix option only if flag is enabled and if source is not VCD.
          this.leverageNutanixSnapshots =
            flagEnabled(this.irisCtx.irisContext, 'leverageNutanixSnapshots') &&
            !['kvCloudDirector'].includes(value.protectionSource.vmWareProtectionSource?.type);
        });
    }

    if (this.protectionGroup?.id) {
      this.value.enabled = Boolean(
        this.protectionGroup.leverageStorageSnapshots ||
          this.protectionGroup.leverageHyperflexSnapshots ||
          this.protectionGroup.leverageNutanixSnapshots
      );
      this.value.snapshotsCarrier = this.getSnapshotCarrier();
      this.formControl.setValue(this.value);
    }

    this.storageSnapshotFormGroup
      .get('enabled')
      .valueChanges.pipe(
        this.untilDestroy(),

        // Once the component is loaded, check if the toggle was on in the previous setting.
        startWith(this.storageSnapshotFormGroup.get('enabled').value)
      )
      .subscribe(value => {
        if (!value) {
          this.storageSnapshotFormGroup.get('snapshotsCarrier').setValue('storageArray');
        }
      });

    this.formGroup.valueChanges.subscribe(() => {
      const sanTransportControl = this.formGroup.controls.leverageSanTransport?.get('sanTransportEnabled');
      if (sanTransportControl) {
        if (sanTransportControl?.value && !this.storageSnapshotFormGroup.get('enabled').disabled) {
          this.storageSnapshotFormGroup.get('enabled').disable();
          this.isStorageSnapshotToggleDisabled = true;
        } else if (sanTransportControl?.value === false && !this.storageSnapshotFormGroup.get('enabled').enabled) {
          this.storageSnapshotFormGroup.get('enabled').enable();
          this.isStorageSnapshotToggleDisabled = false;
        }
      }
    });
  }

  /**
   *
   * @returns text for snapshot info button
   */
  getSnapshotInfo(): string {
    if (this.isStorageSnapshotToggleDisabled) {
      return 'storageOrSanDisableInfoMsg';
    } else if (this.leverageNutanixSnapshots) {
      return this.leverageIbmSnapshots
        ? 'leverageStorageSnapshotsInfoWithIbm'
        : 'leverageStorageSnapshotsInfoWithNuntanix';
    } else {
      return 'leverageStorageSnapshotsInfo';
    }
  }

  /**
   * Returns snapshot carrier type.
   */
  getSnapshotCarrier(): SnapshotsCarrier {
    if (this.protectionGroup.leverageHyperflexSnapshots) {
      return 'hyperFlex';
    }

    if (this.protectionGroup.leverageNutanixSnapshots) {
      return 'nutanix';
    }

    return 'storageArray';
  }
}
