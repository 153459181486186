// Component: Clone Refresh Confirmation.

(function _CloneRefreshConfirm(angular) {
  'use strict';

  angular.module('C.cloneRefresh')
    .controller('CloneRefreshConfirmCtrl', CloneRefreshConfirmCtrl)
    .component('cloneRefreshConfirm', {
      templateUrl: 'app/devops/clone/clone-refresh/clone-refresh-confirm.html',
      controller: 'CloneRefreshConfirmCtrl',
      bindings: {
        /**
         * The clone task object.
         */
        cloneTask: '<',
      },
      require: {
        cModalHoc: '^^?cModalHoc',
      },
    });

  /**
   * @ngdoc controller
   * @name C.cloneRefresh.CloneRefreshConfirmCtrl
   *
   * @description
   * Controller for the cloneRefreshConfirm component.
   *
   * @requires _
   * @requires CloneService
   * @requires cMessage
   * @requires evalAJAX
   * @requires SearchService
   * @requires moment
   * @requires $q
   */
  function CloneRefreshConfirmCtrl(_, CloneService, cMessage, evalAJAX,
    SearchService, moment, $q) {
    var $ctrl = this;

    /**
     * Initialization function.
     *
     * @method   $onInit
     */
    $ctrl.$onInit = function initialize() {
      initMembers();
      loadData();
    };

    /**
     * Convenience method to assign controller members during initialization.
     *
     * @method   initMembers
     */
    function initMembers() {
      var timezone = moment.tz.guess();
      $ctrl.state = { loading: false };

      // Prepare the translation data. Will be used in the modal template for
      // the timezone translation string.
      $ctrl.translationData = {
        timezone: timezone,
        timezoneOffset: moment.tz(timezone).format('Z'),
      };

      // Override modal HOC's bindings. Returning a resolved promise is desired
      // on modal cancel and close events as we don't want the caller to deal
      // with these events, as the service rejects the promise in these cases.
      _.assign($ctrl.cModalHoc, {
        onModalCancel: function onModalCancel() {
          return $q.resolve('modal.cancel');
        },
        onModalClose: function onModalClose() {
          return $q.resolve('modal.close');
        },
        onModalSave: saveData,
        formCtrl: {
          $invalid: false,
        },
      });
    }

    /**
     * Calls API to create a clone refresh task.
     *
     * @method   saveData
     * @return   {Object}   A promise, which is resolved with the created task.
     */
    function saveData() {
      $ctrl.cModalHoc.formCtrl.$invalid = true;

      return CloneService.refreshClone($ctrl.cloneTask,
        $ctrl.sourceEntity)
        .then(function taskCreated(task) {
          cMessage.success({
            textKey: 'cloneRefresh.tasks.created',
          });

          return task;
        })
        .catch(evalAJAX.errorMessage)
        .finally(function taskCreationDone() {
          $ctrl.cModalHoc.formCtrl.$invalid = false;
        });
    }

    /**
     * Loads the required data and assigns the controller members for templates
     * to use.
     *
     * @method   loadData
     * @return   {Object}   A promise, which is resolved when the data has been
     *                      loaded.
     */
    function loadData() {
      $ctrl.state.loading = true;
      $ctrl.cModalHoc.formCtrl.$invalid = true;

      return SearchService
        .dbSearch({entityIds: $ctrl.cloneTask._cloneAppInfo._sourceEntityId},
          'databases', false)
        .then(function searchSucceeded(response) {
          if (response && response.length) {
            $ctrl.sourceEntity = response[0];
            $ctrl.snapshot = $ctrl.sourceEntity._versions[0];
          }
        })
        .catch(evalAJAX.errorMessage)
        .finally(function sourceEntityDone() {
          $ctrl.state.loading = false;
          $ctrl.cModalHoc.formCtrl.$invalid = !$ctrl.snapshot;
        });
    }
  }
})(angular);
