import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { every, filter, some } from 'lodash';

/**
 * Type definition for selectable table item.
 */
export interface Selectable {
  isSelected?: boolean;
  selectDisabled?: boolean;
}

/**
 * @description
 * Table controls will be added and replace table header with items are selected.
 *
 * @example
 *  <coh-multitask-table>
 *    <coh-multitask-controls
 *      [all-items]="items"
 *      [visible-items]="visibleItemsOnPage"
 *      [selection-label]="'someItemsAreSelectedLabel'"
 *      [select-all-label]="'allItemsAreSelectedLabel'"
 *      [all-selected-label]="'allItemsAreSelectedLabel'">
 *
 *     <label>
 *       This label and buttons will be transcluded inside table header when items are selected
 *     </label>
 *     <button>Start All</button>
 *     <button>Stop All</button>
 *
 *    </coh-multitask-controls>
 *    <table></table>
 *  </coh-multitask-table>
 */
@Component({
  selector: 'coh-multitask-controls',
  templateUrl: './multitask-controls.component.html',
  styleUrls: ['./multitask-controls.component.scss']
})
export class MultitaskControlsComponent implements OnInit {
  /**
   * Reference to template's checkbox.
   */
  @ViewChild('checkbox', { static: true }) checkboxRef: ElementRef;

  /**
   * Stores all available including non-visible items.
   */
  @Input() allItems: Selectable[] = [];

  /**
   * Items that are visible on page.
   */
  private _visibleItems: Selectable[];

  /**
   * Sets visible items array.
   */
  @Input() set visibleItems(items: Selectable[]) {
    this.checkbox.checked = false;
    this._visibleItems = items;
  }

  /**
   * Returns visible items.
   */
  get visibleItems(): Selectable[] {
    return this._visibleItems;
  }

  /**
   * Label for "Select All" button.
   */
  @Input() selectAllLabel = 'defaultSelectAllLabel';

  /**
   * Label for when some items are selected.
   */
  @Input() selectionLabel = 'defaultSelectionLabel';

  /**
   * Label for all items are selected.
   */
  @Input() allSelectedLabel = 'defaultAllSelectedLabel';

  /**
   * Returns template's checkbox element from checkbox reference.
   */
  get checkbox(): HTMLInputElement {
    return this.checkboxRef.nativeElement as HTMLInputElement;
  }

  /**
   * Returns true if some of all available items are selected.
   */
  get hasSelected(): boolean {
    return some(this.visibleItems, 'isSelected');
  }

  /**
   * Returns true if all visible items are selected.
   */
  get allVisibleSelected(): boolean {
    const nonDisabled = filter(this.visibleItems, item => !item.selectDisabled);
    return every(nonDisabled, 'isSelected');
  }

  /**
   * Returns true if all items are selected.
   */
  get isAllSelected() {
    return this.selectedCount === this.allItems.length;
  }

  /**
   * Return all selected items.
   */
  get selectedItems(): Selectable[] {
    return filter(this.allItems, 'isSelected');
  }

  /**
   * Returns selected items count.
   */
  get selectedCount(): number {
    return this.selectedItems.length;
  }

  /**
   * Returns selection label based on which items are selected.
   */
  get itemSelectionLabel(): string {
    const {
      selectedItems,
      allItems,
      allSelectedLabel,
      selectionLabel
    } = this;
    return selectedItems.length === allItems.length ? allSelectedLabel : selectionLabel;
  }

  /**
   * Init component.
   */
  ngOnInit() {
    this.checkbox.checked = false;
  }

  /**
   * Selected all available items including non-visible.
   */
  selectAll() {
    this.allItems.forEach(item => item.isSelected = !item.selectDisabled);
  }

  /**
   * Clears every selected item.
   */
  clearAll() {
    this.allItems.forEach(item => item.isSelected = false);
  }

  /**
   * Handles checkbox change event.
   */
  checkboxChange() {
    const {checked} = this.checkbox;
    const items = checked ? this.visibleItems : this.allItems;
    items.forEach(item => item.isSelected = !item.selectDisabled && checked);
  }
}
