import { HypervSnapshotParams, ObjectSnapshot, PhysicalSnapshotParams } from '@cohesity/api/v2';

import { DecoratedIndexedObjectSnapshot } from './indexed-object-snapshot.decorator';
import { Office365CSMEnvironmentTypes } from 'src/app/shared';

/**
 * Grouped snapshots can be either object snapshots or indexed object snapshots.
 */
export type AnySnapshotObject = ObjectSnapshot | DecoratedIndexedObjectSnapshot;

/**
 * This class is used to represent a grouped set of snapshots with the same protection group
 * run id. Each snapshot on the group should have a different snapshot target type.
 */
export class GroupedObjectSnapshot {
  /**
   * Gets the local snapshot from the list if it exists.
   */
  get localSnapshot(): AnySnapshotObject {
    return this.snapshots.find(snapshot => snapshot.snapshotTargetType === 'Local');
  }

  /**
   * Gets all archive snapshots from the list if they exist.
   */
  get archiveSnapshots(): AnySnapshotObject[] {
    return this.snapshots.filter(snapshot => snapshot.snapshotTargetType === 'Archival' &&
      snapshot.ownershipContext !== 'FortKnox');
  }

  /**
   * Gets all cloud vault snapshots from the list if they exist.
   */
  get cloudVaultSnapshots(): AnySnapshotObject[] {
    return this.snapshots.filter(snapshot => snapshot.ownershipContext === 'FortKnox');
  }

  /**
   * Specifies the indexing status of objects in this snapshot
   */
  get indexingStatus(): null | 'InProgress' | 'Done' | 'NoIndex' | 'Error' {
    return this.snapshots[0].indexingStatus;
  }

  /**
   * Specifies id of the Protection Group.
   */
  get protectionGroupId(): string {
    return this.snapshots[0].protectionGroupId;
  }

  /**
   * Specifies id of the Protection Group Run.
   */
  get protectionGroupRunId(): string {
    return this.snapshots[0].protectionGroupId;
  }

  /**
   * Gets the run instance id. This only applies to object snapshot, not indexed object snapshots.
   */
  get runInstanceId(): number {
    return (this.snapshots[0] as ObjectSnapshot).runInstanceId;
  }

  /**
   * Specifies the timestamp of the snapshot's run start time.
   */
  get runStartTimeUsecs(): number {
    return (this.snapshots[0] as ObjectSnapshot).runStartTimeUsecs;
  }

  /**
   * Checks if it is a CSM type snapshot.
   */
  get isCSM(): boolean {
    return Office365CSMEnvironmentTypes.includes((this.snapshots[0] as ObjectSnapshot).environment);
  }

  /**
   * Specifies the timestamp in Unix time epoch in microseconds when the snapshot is taken for the specified Object.
   */
  get snapshotTimestampUsecs(): number {
    return this.snapshots[0].snapshotTimestampUsecs;
  }

  /**
   * Gets the physical params of a snapshot.
   */
  get physicalSnapshotParams(): PhysicalSnapshotParams {
    return (this.snapshots[0] as ObjectSnapshot).physicalParams;
  }

  /**
   * Gets the hyperv params of a snapshot.
   */
  get hypervParams(): HypervSnapshotParams {
    return (this.snapshots[0] as ObjectSnapshot).hypervParams;
  }

  /**
   * Gets the source group id, if it is specified, this currently only applies to object snapshots.
   */
  get sourceGroupId(): string {
    return (this.snapshots[0] as ObjectSnapshot).sourceGroupId;
  }

  /**
   * Returns the default snapshot of this group. The default snapshot of a group is calculated as:
   * - If the group has a local snapshot, the local snapshot is the default snapshot.
   * - If the group doesn't have a local snapshot, then the first archival snapshot is the default snapshot.
   *
   * @returns The default snapshot object of the group.
   */
  get defaultSnapshot(): AnySnapshotObject {
    return this.localSnapshot || this.archiveSnapshots[0] || this.cloudVaultSnapshots[0];
  }

  /**
   * Returns the last modification timestamp of the snapshot. This is only for the indexed snapshots.
   */
  get lastModifiedTimeUsecs(): number {
    return (this.snapshots[0] as DecoratedIndexedObjectSnapshot).lastModifiedTimeUsecs;
  }

  /**
   * Returns the size (in bytes) of the snapshot. This is only for the indexed snapshots.
   */
  get sizeBytes(): number {
    return (this.snapshots[0] as DecoratedIndexedObjectSnapshot).sizeBytes;
  }

  /**
   * Returns the Run type of the snapshot
   */
  get runType(): DecoratedIndexedObjectSnapshot['runType'] {
    return (this.snapshots[0] as DecoratedIndexedObjectSnapshot).runType;
  }

  constructor(readonly snapshots: AnySnapshotObject[]) {}

  /**
   * Given a list of ObjectSnapshots, this combines snapshots from the same run id but of different types
   * into a single object.
   *
   * @param   snapshots   The list of snapshots to sort.
   * @returns An array of grouped snapshots.
   */
  static groupSnapshots(snapshots: AnySnapshotObject[]): GroupedObjectSnapshot[] {
    const uniqueSnapshotMap: {
      [id: number]: GroupedObjectSnapshot;
    } = {};

    snapshots.forEach(snapshot => {
      const key = (snapshot as ObjectSnapshot).protectionGroupRunId || snapshot.snapshotTimestampUsecs || snapshot.id;

      if (!uniqueSnapshotMap[key]) {
        uniqueSnapshotMap[key] = new GroupedObjectSnapshot([]);
      }
      uniqueSnapshotMap[key].addSnapshot(snapshot);
    });

    return Object.values(uniqueSnapshotMap).sort((a, b) => b.snapshotTimestampUsecs - a.snapshotTimestampUsecs);
  }

  /**
   * Adds a snapshot to the list.
   *
   * @param   snapshot  The snapshot to add
   */
  addSnapshot(snapshot: AnySnapshotObject) {
    if((snapshot as ObjectSnapshot)?.environment &&
      Office365CSMEnvironmentTypes.includes((snapshot as ObjectSnapshot).environment)) {
      this.snapshots.push({
        ...snapshot,
        snapshotTargetType: 'Local',
        runType: 'kRegular',
      });
      return;
    }
    this.snapshots.push(snapshot);
  }

  /**
   * Get the snapshot by type and target name.
   * If the snapshot could not be found, fall back to default snapshot.
   *
   * @param   snapshotTypeWithTarget  The snapshot type with the target name if applicable.
   * @returns The snapshot from grouped snapshots based on the current type and target name.
   */
  getSnapshotByType(snapshotTypeWithTarget = ''): AnySnapshotObject {
    let snapshot: AnySnapshotObject;

    // Extract the snapshot type and target name
    const [snapshotType, targetId = ''] = snapshotTypeWithTarget.split(':');

    switch (snapshotType) {
      case 'FortKnox':
        if (targetId) {
          snapshot = this.cloudVaultSnapshots?.find(
            cvSnapshot => cvSnapshot.externalTargetInfo?.targetId === +targetId);
        } else {
          snapshot = this.cloudVaultSnapshots?.[0];
        }
        break;
      case 'Archival':
        if (targetId) {
          snapshot = this.archiveSnapshots?.find(
            archiveSnapshot => archiveSnapshot.externalTargetInfo?.targetId === +targetId);
        } else {
          snapshot = this.archiveSnapshots?.[0];
        }
        break;
      default:
        snapshot = this.localSnapshot;
    }

    if (!snapshot) {
      snapshot = this.defaultSnapshot;
    }

    return snapshot;
  }
}
