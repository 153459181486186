import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { ActiveDirectoryServiceApi, GroupsServiceApi, User } from '@cohesity/api/v1';
import {
  CreateView,
  NfsConfig,
  ProtectionGroupInfo,
  ProtectionGroupServiceApi,
  SelfServiceSnapshotConfig,
  Share,
  Shares,
  SmbConfig,
  StorageDomain,
  StorageDomains,
  StorageDomainServiceApi,
  Subnet,
  Template,
  UserQuota,
  UserQuotaOverrides,
  View,
  ViewDirectoryQuota,
  ViewDirectoryQuotas,
  ViewProtocol,
  ViewServiceApi,
  ViewSharePermissions,
  ViewsSummary,
  ViewUserQuotas,
} from '@cohesity/api/v2';
import { flagEnabled, IrisContextService, isEntityOwner } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { clamp, get, isUndefined, keyBy } from 'lodash';
import moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AjsUpgradeService, DialogService, UserService } from 'src/app/core/services';
import { Environment, SMB_WELL_KNOWN_PRINCIPALS } from 'src/app/shared';

import { SubnetWhiteList } from '../components/whitelist-dialog/whitelist-dialog.component';
import {
  CreateViewData,
  CreateViewDialogForm,
  CreateViewDialogOverrides,
  CreateViewPageForm,
  DefaultCreateViewValue,
  DirQuotaPolicyInfo,
  FileLockConfigMode,
  SharesInfo,
  UserQuotaInfo,
  ViewInfo,
  ViewsResult,
  ViewsSummaryData,
} from '../models';
import { findEveryoneIndex, isNFSProtocolSelected, isSMBProtocolSelected, transformFromCreateViewForm } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class ViewsService {
  /**
   * Injected Ajs service.
   */
  private dateTimeService: any;

  constructor(
    private activeDirectoryServiceApiV1: ActiveDirectoryServiceApi,
    private ajsUpgrade: AjsUpgradeService,
    private dialogService: DialogService,
    private irisCtx: IrisContextService,
    private groupsService: GroupsServiceApi,
    private protectionGroupsService: ProtectionGroupServiceApi,
    private stateService: StateService,
    private storageDomainService: StorageDomainServiceApi,
    private translate: TranslateService,
    private userService: UserService,
    private viewService: ViewServiceApi,
    private irisContextService: IrisContextService) {
    this.dateTimeService = ajsUpgrade.get('DateTimeService');
  }

  /**
   * Returns all views.
   *
   * @param params request params for fetching views.
   */
  getViews(params: ViewServiceApi.GetViewsParams): Observable<ViewsResult> {
    return this.viewService.GetViews(params).pipe(
      map((response: ViewsResult) => {
        if (!response || !response.views) {
          response.views = [];
          return response;
        }

        // Transforming and mapping views information.
        response.views = response.views.map(view => this.transformView(view));

        return response;
      })
    );
  }

  /**
   * Returns summary data for views glance bar.
   *
   * @param params request params for fetching views.
   */
  getViewsSummary(params?: ViewServiceApi.GetViewsSummaryParams): Observable<ViewsSummaryData> {
    return this.viewService.GetViewsSummary({
      ...(params || {}),
      useCachedData: flagEnabled(this.irisContextService.irisContext, 'useMagnetoCachedData'),
    }).pipe(
      map((response: ViewsSummary) => {
        const {
          dataWrittenBytes,
          logicalUsageBytes,
          protectedViews,
          replicatedInViews,
          replicatedOutViews,
          localTierResiliencyImpactBytes,
          numDirectories,
          numFiles,
          storageConsumedBytes,
          storageConsumedBytesTimestampUsec,
          totalViews,
          viewEntityId
        } = response;

        const lastUpdated = moment.duration(moment().diff(moment(storageConsumedBytesTimestampUsec / 1000)));
        const summaryData: ViewsSummaryData = {
          totalViews: totalViews || 0,
          protection: {
            takingSnapshots: protectedViews || 0,
            replicatingIn: replicatedInViews || 0,
            replicatingOut: replicatedOutViews || 0,
          },
          consumption: {
            dataReduction: null,
            lastUpdated: moment.duration(lastUpdated).humanize({h: 47}),
            logical: logicalUsageBytes || 0,
            physical: dataWrittenBytes || 0,
            resiliencyImpact: localTierResiliencyImpactBytes,
            storageConsumed: storageConsumedBytes || 0,
            storageReduction: null,
            numDirectories: numDirectories || 0,
            numFiles: numFiles || 0,
          },
          viewEntityId,
        };

        summaryData.consumption.dataReduction = summaryData.consumption.physical ?
          summaryData.consumption.logical / summaryData.consumption.physical : 0;

        return summaryData;
      })
    );
  }

  /**
   * Delete view.
   *
   * @param   params   Request params for deleting view.
   */
  deleteView(params: ViewServiceApi.DeleteViewParams): Observable<null> {
    return this.viewService.DeleteView(params);
  }

  /**
   * Returns ratio of dataIn to dataWritten for a view.
   *
   * @param view view
   * @return view reduction ratio.
   */
  getViewReductionRatio(view: ViewInfo) {
    const dataUsageStats = get(view, 'stats.dataUsageStats', {});

    if (!dataUsageStats.storageConsumedBytes) {
      return this.translate.instant('naNotAvailable');
    }

    return clamp(
      dataUsageStats.dataInBytes / dataUsageStats.storageConsumedBytes,
      0, Number.MAX_VALUE
    ).toFixed(1) + 'x';
  }

  /**
   * Returns transformed view along with derived information.
   *
   * @param view View information.
   * @return  transformed view.
   */
  transformView(view: ViewInfo): ViewInfo {
    if (!view) {
      return;
    }

    const clusterNowUsecs = this.dateTimeService.clusterNow() * 1000;
    view._storageConsumedBytes = get(view, 'stats.dataUsageStats.storageConsumedBytes', null);
    view._logicalUsageBytes = get(view, 'stats.dataUsageStats.totalLogicalUsageBytes', null);
    view._storageReduction = this.getViewReductionRatio(view);

    // True if protected by at least one job.
    view._isProtected = !!view.viewProtection && (view.viewProtection.protectionGroups
      && !!view.viewProtection.protectionGroups.length);

    // True if it is protected by at least one Remote Adapter job.
    view._isRemoteAdapterView = view._isProtected && view.viewProtection.protectionGroups
      .some((group: ProtectionGroupInfo) => {
        ['Puppeteer'].includes(group.type);
      });

    // True if view is a Tracking View.
    view._isTrackingView = Boolean(view.isReadOnly);

    // True if view is a "DR View". These are legacy Views before we implemented
    // the more robust View DR workflows. These Views are no longer displayed in
    // UI unless a feature flag is enabled: showDRViewsInViewsLandingPage.
    view._isInactive = Boolean(!view.isReadOnly && view.viewProtection?.inactive);

    // User Quotas are not available for any 'S3' View nor any View which is
    // replicated from another Cluster.
    view._allowUserQuotas = flagEnabled(this.irisCtx.irisContext, 'viewsUserQuotas') &&
      !view._isInactive && view.protocolAccess?.some(protocol => protocol.type === 'S3');

    // Determines whether there is an active View DataLock by comparing the
    // expiry datetime with now.
    view._hasViewDataLock = view.dataLockExpiryUsecs > clusterNowUsecs;

    const hasReadWriteS3Protocol = view.protocolAccess?.some(protocol =>
      protocol.type === 'S3' && protocol.mode === 'ReadWrite');

    // Determines whether there is an active File DataLock by looking for
    // non-zero milliseconds remaining.
    view._hasFileDataLock = !hasReadWriteS3Protocol && !!view.fileLockConfig;

    // Determines whether there is S3 Object Lock
    view._hasS3ObjectLock = hasReadWriteS3Protocol && !!view.fileLockConfig;

    // This is `undefined` unless the it is a DataLock View. In that case,
    // `true` if lock has expired, otherwise `false`. This property is used
    // differently from _hasViewDataLock because we need to be able to show an
    // expired DataLock.
    view._dataLockExpired = view.dataLockExpiryUsecs ? view.dataLockExpiryUsecs <= clusterNowUsecs
      : undefined;

    // Determines whether this is or was ever a DataLock View.
    view._hadViewDataLock = view._hasViewDataLock || !isUndefined(view.dataLockExpiryUsecs);

    const hasStorageModifyPrivilege = get(this.userService, 'user.privs.STORAGE_MODIFY', false);
    const hasDataSecurityPrivilege = get(this.userService, 'user.privs.DATA_SECURITY', false);
    const hasPrivilegeToEdit =
      // If user has explicit privileges OR
      (hasStorageModifyPrivilege || hasDataSecurityPrivilege) ||

      // If user is View owner
      (view.ownerSid === this.userService.user?.sid);

    view._isEditable = hasPrivilegeToEdit &&
      // If View is not a DR View AND
      !view._isInactive &&

      // If View is not DataLock OR user has DATA_SECURITY privilege
      (!view._hadViewDataLock || hasDataSecurityPrivilege);

    view._isDeletable = hasPrivilegeToEdit &&
      // If File DataLock is enabled, it is deletable only by Data Security
      // Officer (also Local Admin in the case of Enterprise mode only)
      // Users can delete empty S3 views as well (validated at the backend)
      (!view._hasFileDataLock || hasDataSecurityPrivilege || (this.userService.isLocalAdminUser()
        && (hasReadWriteS3Protocol || view.fileLockConfig.mode === FileLockConfigMode.enterprise))) &&

      // If there is no active View DataLock.
      (!view._hadViewDataLock || !!view._dataLockExpired);

    /**
     * True if user belongs to view owner organization else false and used to
     * prevent user from making deleting, protecting, cloning and other view
     * requests.
     *
     * Note: As of ENG-199781, this property name is potentially confused with
     * `view.ownerSid` (see _isEditable) which is the actual user who created
     * the View.
     */
    view._isViewOwner = isEntityOwner(this.irisContextService.irisContext, view.tenantId);

    // Share list of view.
    view._sharesList = this.getViewShares(view);
    view.allSmbMountPaths = view.smbMountPaths;

    // If view protection run is paused.
    view._isPaused = view.viewProtection?.protectionGroups[0]?.isPaused;

    // Stub Snapshot Self Service info for legacy Views.
    view.selfServiceSnapshotConfig = view.selfServiceSnapshotConfig || { enabled: false };

    //  Undefined Squash values should be displayed as 0.
    if (view.nfsAllSquash) {
      view.nfsAllSquash.uid = view.nfsAllSquash.uid || 0;
      view.nfsAllSquash.gid = view.nfsAllSquash.gid || 0;
    }
    if (view.nfsRootSquash) {
      view.nfsRootSquash.uid = view.nfsRootSquash.uid || 0;
      view.nfsRootSquash.gid = view.nfsRootSquash.gid || 0;
    }

    return view;
  }

  /**
   * Helper function to get shares list from view alias.
   *
   * @param  view   View object.
   * @returns  List of shares.
   */
  getViewShares(view: ViewInfo): SharesInfo[] {
    const sharesList: SharesInfo[] = [];

    // Slice off the "fqdn:/"
    const rawNfsMountPaths = view.nfsMountPaths && view.nfsMountPaths.map(path => path.slice(0, path.indexOf('/') + 1));

    // Slice off the "https://fqdn:port/"
    const rawS3AccessPath = view.s3AccessPath && view.s3AccessPath.slice(0, view.s3AccessPath.indexOf('/', 8) + 1);

    // Slice off the "\\fqdn\" for each machine account
    const rawSmbMountPaths = view.smbMountPaths && view.smbMountPaths
      .map(mountpath => mountpath.slice(0, mountpath.indexOf('\\', 2) + 1));

    // Add default share.
    sharesList.push({
      viewName: view.name,
      name: view.name,
      isDefaultShare: true,
      auditingEnabled: !!view.enableFilerAuditLogging,
      viewPath: '/',
      nfsMountPaths: view.nfsMountPaths,
      s3AccessPath: view.s3AccessPath,
      smbMountPaths: view.smbMountPaths,
      tenantId: view.tenantId,
    });

    // Add entry for each Alias in this View.
    if (view.aliases) {
      view.aliases.forEach(alias => {
        sharesList.push({
          viewName: view.name,
          name: alias.aliasName,
          auditingEnabled: !!alias.enableFilerAuditLog,
          viewPath: '/' + alias.viewPath,
          s3AccessPath: rawS3AccessPath && rawS3AccessPath + alias.aliasName,
          smbMountPath: rawSmbMountPaths && rawSmbMountPaths[0] + alias.aliasName,
          smbMountPaths: (rawSmbMountPaths || []).map(mountpath => mountpath + alias.aliasName),
          nfsMountPath: rawNfsMountPaths && rawNfsMountPaths[0] + alias.aliasName,
          nfsMountPaths: (rawNfsMountPaths || []).map(path => path + alias.aliasName),
          tenantId: view.tenantId,
        });
      });
    }

    sharesList.forEach(share => this.transformShare(share));
    return sharesList;
  }

  /**
   * Determines if logged in user can create view with S3 only access.
   *
   * @param   share   The share to test.
   * @return         True if S3 view access is allowed, False otherwise.
   */
  isS3ViewAccessEnabled(share: SharesInfo) {
    return this.userService.isTenantUser() || share?.tenantId ?
      flagEnabled(this.irisCtx.irisContext, 'enableS3ViewsForTenants') : true;
  }

  /**
   * Transforms a Share as returned from API, adding derived information.
   * Decorates shares with mount paths.
   *
   * @param   share   share to update
   * @return          updated Share
   */
  transformShare(share: SharesInfo) {
    if (!share) {
      return;
    }

    share.aliasName = share.name;
    share.clientSubnetWhitelist = share.clientSubnetWhitelist || [];

    share._mountPaths = this.assemblePaths(share);

    share.smbMountPaths = share.smbMountPaths || [];
    if (!share.smbMountPath) {
      share.smbMountPath = share.smbMountPaths[0];
    }

    share.nfsMountPaths = share.nfsMountPaths || [];
    if (!share.nfsMountPath) {
      share.nfsMountPath = share.nfsMountPaths[0];
    }

    share.viewPath = share.viewPath || '/';
    share.isDefaultShare = share.viewName === share.name;

    // True if the user belongs to view share owner's organization and used to
    // prevent un-authorized user from making delete share request.
    share._isShareOwner = isEntityOwner(this.irisContextService.irisContext, share.tenantId);

    share.smbConfig = share.smbConfig || {};
    share.smbConfig.permissions = share.smbConfig.permissions || [];

    return share;
  }

  /**
   * Isolate the Share name in the NFS mount path and then scrub it.
   *
   * @param   mountPath    String mount path.
   * @return                Full mount path with Share name scrubbed.
   */
  scrubNfsMountPath(mountPath: string) {
    if (!mountPath) {
      return mountPath;
    }

    const parts = mountPath.split('/');

    parts.push(this.scrubShareName(parts.pop(), 'nfs'));
    return parts.join('/');
  }

  /**
   * Scrub Share name according to respective protocol requirements.
   *
   * @param   shareName    String Share name.
   * @param   protocol     String protocol name.
   * @return               Share name scrubbed according to respective protocol
   *                       requirements.
   */
  scrubShareName(shareName: string, protocol: string) {
    if (protocol === 's3') {

      // For S3 mount paths, URL encode the Share name.
      return encodeURI(shareName);
    }

    if (protocol === 'nfs' && shareName.match(/\s/)) {

      // For NFS mount paths, enclose Share name with single quotes if it
      // contains spaces. This ensures that the mount path will actually work
      // when pasted into a CLI.
      return `'` + shareName + `'`;
    }

    return shareName;
  }


  /**
   * Assembles the mount paths for a share.
   *
   * @param   share   The share object
   * @return          list of mount path objects
   */
  assemblePaths(share: Share) {

    // If this share is actually the root View, then we want to use the long
    // form mount path because that ensures an accurate path if the View and
    // its Storage Domain have the same name. Usually, we see a mount path in
    // the form of fqdn/share-name:
    //   sv4-dell85-c4-ve04.eng.cohesity.com:/share1
    //
    // However, if the Storage Domain and View have identical names, then they
    // need to be explicit in the mount path:
    //   sv4-dell85-c4-ve04.eng.cohesity.com:/same-name/same-name/fs
    //
    // Note: This does not apply to S3.
    const useLongPath = share.name === share.viewName;

    const nfsMountPath = share.nfsMountPaths?.[0];
    const s3AccessPath = share.s3AccessPath;
    const smbMountPath = share.smbMountPaths?.[0];
    const allSmbMountPaths = share.smbMountPaths || [];
    const mountPaths = [];

    if (nfsMountPath) {
      mountPaths.push({
        type: 'nfs',
        label: 'nfs',
        path: useLongPath ? this.scrubNfsMountPath(nfsMountPath) :
          // Slice off the "fqdn:/" and append this Share name.
          nfsMountPath.slice(0, nfsMountPath.indexOf('/') + 1) +
          this.scrubShareName(share.name, 'nfs'),
      });
    }

    // Stub `allSmbMountPaths` if missing.
    if (smbMountPath && !allSmbMountPaths[0]) {
      allSmbMountPaths.push(smbMountPath);
    }

    if (allSmbMountPaths[0]) {
      allSmbMountPaths.forEach(smbPath => {
        mountPaths.push({
          type: 'smb',
          label: 'smb',

          // Slice off the "\\fqdn\" and append this Share name.
          path: useLongPath ? smbPath :
            smbPath.slice(0, smbPath.indexOf('\\', 2) + 1) +
            share.name,
        });
      });
    }

    if (s3AccessPath && this.isS3ViewAccessEnabled(share)) {
      mountPaths.push({
        type: 's3',
        label: 's3',

        // Slice off the "https://fqdn:port/" and append this Share name.
        path: s3AccessPath.slice(0, s3AccessPath.indexOf('/', 8) + 1) +
        this.scrubShareName(share.name, 's3'),
      });
    }

    return mountPaths;
  }

  /**
   * Method called to get all shares.
   *
   * @param   params   Request params to get shares list.
   * @returns          Observable with array of shares details.
   */
  getShares(params: ViewServiceApi.GetSharesParams): Observable<Shares> {
    return this.viewService.GetShares(params).pipe(
      map((response: Shares) => {
        if (!response || !response.shares) {
          response.shares = [];
          return response;
        }

        // Transforming and mapping shares information.
        response.shares = response.shares.map(share => this.transformShare(share));
        return response;
      })
    );
  }

  /**
   * Method called to create a new share.
   *
   * @param params Request params to create share.
   * @returns Observable of new added share object.
   */
  createShare(params: ViewServiceApi.CreateShareParams): Observable<Share> {
    if (params.body.enableFilerAuditLogging !== undefined) {
      params.body.fileAuditLoggingState = this.getShareLoggingState(params.body.enableFilerAuditLogging);
    }
    return this.viewService.CreateShare(params);
  }

  /**
   * Method called to delete a share.
   *
   * @param params Request params to delete share.
   * @returns Observable of null. Status 204 indicates success.
   */
  deleteShare(params: ViewServiceApi.DeleteShareParams): Observable<null> {
    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        params[key] = encodeURIComponent(value);
      }
    });
    return this.viewService.DeleteShare(params);
  }

  /**
   * Converts `enableFilerAuditLogging` field of a share to `fileAuditLoggingState`. This is required as the
   * create/update share API only accepts the latter field.
   *
   * @param enableFilerAuditLogging
   * @returns
   */
  private getShareLoggingState(enableFilerAuditLogging: null | boolean): ViewServiceApi.UpdateShareParams['body']['fileAuditLoggingState'] {
    switch (enableFilerAuditLogging) {
      case true:
        return 'Enabled';
      case false:
        return 'Disabled';
      default:
        return 'Inherited';
    }
  }

  /**
   * Update share.
   *
   * @param   params   Request params to update view alias.
   * @returns          Observable of updated share.
   */
  UpdateShare(params: ViewServiceApi.UpdateShareParams): Observable<Share> {
    if (params.body.enableFilerAuditLogging !== undefined) {
      params.body.fileAuditLoggingState = this.getShareLoggingState(params.body.enableFilerAuditLogging);
    }
    return this.viewService.UpdateShare(params);
  }

  /**
   * Fetch all directory quotas of current view.
   *
   * @param   params   Request params to get directory quota list.
   * @returns          Observable of directory quota information.
   */
  getViewDirectoryQuotas(params: ViewServiceApi.GetViewDirectoryQuotasParams): Observable<ViewDirectoryQuotas> {
    return this.viewService.GetViewDirectoryQuotas(params).pipe(
      map((directoryQuotas: ViewDirectoryQuotas) => {
        // Transforming and mapping directoryQuota information.
        if (directoryQuotas.directoryQuotas) {
          directoryQuotas.directoryQuotas =
            directoryQuotas.directoryQuotas.map(quota => this.transformDirectoryQuota(quota));
          return directoryQuotas;
        }
      })
    );
  }

  /**
   * Transform directory quota.
   *
   * @param   quota   Directory quota.
   * @returns   Modified directory quota object with customized properties.
   */
  transformDirectoryQuota(quota: ViewDirectoryQuota): DirQuotaPolicyInfo {
    const {quotaPolicy = {}, usageBytes = 0} = quota;
    const {hardLimitBytes = 0, alertLimitBytes = 0} = quotaPolicy;

    (quota as DirQuotaPolicyInfo)._quotaOverride = hardLimitBytes;
    (quota as DirQuotaPolicyInfo)._alertThreshold = alertLimitBytes;
    (quota as DirQuotaPolicyInfo)._usageBytes = usageBytes;
    (quota as DirQuotaPolicyInfo)._folderName = quota.directoryPath.slice(quota.directoryPath.lastIndexOf('/') + 1) || '/';

    if (!hardLimitBytes) {
      (quota as DirQuotaPolicyInfo)._quotaUsagePercentage = 0;
    } else {
      (quota as DirQuotaPolicyInfo)._quotaUsagePercentage =
        Number(clamp(usageBytes / hardLimitBytes * 100, 0, Number.MAX_VALUE).toFixed(1));
    }
    return quota as DirQuotaPolicyInfo;
  }

  /**
   * Transform view user quota.
   *
   * @param   quota   User quota.
   * @returns   Modified user quota object with customized properties.
   */
  transformViewUserQuota(quota: UserQuota): UserQuotaInfo {
    (quota as UserQuotaInfo)._quotaOverride = get(quota, 'quotaPolicy.hardLimitBytes', 0);
    (quota as UserQuotaInfo)._alertThreshold = get(quota, 'quotaPolicy.alertLimitBytes', 0);

    return quota as UserQuotaInfo;
  }

  /**
   * Fetch user quota of current view.
   *
   * @param   params   Request params to get user quota.
   * @returns          Observable of user quota information.
   */
  getViewUserQuotas(params: ViewServiceApi.GetViewUserQuotasParams): Observable<ViewUserQuotas> {
    return this.viewService.GetViewUserQuotas(params).pipe(
      map((response: ViewUserQuotas) => {
        if (response.userQuotas?.length) {
          response.userQuotas = response.userQuotas.map(quota => this.transformViewUserQuota(quota));
        }
        return response;
      })
    );
  }

  /**
   * Enable or disable user quota of current view.
   *
   * @param   params   Request params to update user quota.
   * @returns   Observable of user quota settings.
   */
  toggleViewUserQuotas(params: ViewServiceApi.UpdateViewUserQuotaSettingsParams): Observable<ViewUserQuotas> {
    return this.viewService.UpdateViewUserQuotaSettings(params);
  }

  /**
   * Create a new quota policy for a user in a view.
   *
   * @param   params   Request params to create user quota.
   * @returns          Observable of new added user quota object.
   */
  addViewUserQuota(params: ViewServiceApi.AddViewUserQuotaOverridesParams): Observable<UserQuotaOverrides> {
    return this.viewService.AddViewUserQuotaOverrides(params);
  }

  /**
   * Delete the quota policy overrides for users in a view.
   *
   * @param   params   Request params to delete user quota.
   * @returns          Observable of null.
   */
  deleteViewUserQuota(params: ViewServiceApi.DeleteViewUserQuotaOverridesParams): Observable<null> {
    return this.viewService.DeleteViewUserQuotaOverrides(params);
  }

  /**
   * Update existing quota policy for a user in a view.
   *
   * @param   params   Request params to create user quota.
   * @returns          Observable of updated user quota object.
   */
  updateViewUserQuota(params: ViewServiceApi.UpdateViewUserQuotaOverrideParams): Observable<UserQuota> {
    return this.viewService.UpdateViewUserQuotaOverride(params);
  }

  /**
   * Update the default user quota settings in a view.
   *
   * @param   params   Request params to get user quota list.
   * @returns          Observable of user quota information.
   */
  updateViewDefaultUserQuota(params: ViewServiceApi.UpdateViewUserQuotaSettingsParams): Observable<ViewUserQuotas> {
    return this.viewService.UpdateViewUserQuotaSettings(params);
  }

  /**
   * Update directory quota of current view.
   *
   * @param   params   Request params to update directory quotas.
   * @returns          Observable of updated directory quota information.
   */
  updateViewDirectoryQuota(params: ViewServiceApi.UpdateViewDirectoryQuotaParams): Observable<ViewDirectoryQuota> {
    return this.viewService.UpdateViewDirectoryQuota(params);
  }

  /**
   * Delete directory quota of current view.
   *
   * @param   params   Request params to delete directory quotas.
   * @returns          Observable of null
   */
  deleteViewDirectoryQuota(params: ViewServiceApi.DeleteViewDirectoryQuotaParams): Observable<null>  {
    return this.viewService.DeleteViewDirectoryQuota(params);
  }

  /**
   * Get mapped view protocols.
   *
   * @param   view   The view object.
   * @return  Mapped view protocols.
   */
  getViewProtocols (view: ViewInfo) {
    return (view?.protocolAccess || []).map((protocol) => protocol.type);
  }

  /**
   * Initiates the View Job protection flow.
   *
   * @param view View information
   */
  protectView(view: ViewInfo) {
    this.stateService.go('job-modify', {
      environments: [Environment.kView],
      protectView: view,
    });
  }

  /**
   * Returns default storage domain value for view config.
   *
   * @param   id   storageDomain Id
   * @returns      Default storage domain value.
   */
  getDefaultStorageDomain(id?: number): Observable<StorageDomain> {
    const params = { includeTenants: false };

    return this.storageDomainService.GetStorageDomains(params).pipe(
      map((response: StorageDomains) => response.storageDomains.find(viewbox => (id && id !== null ? viewbox.id === id :
        viewbox.name === 'DefaultStorageDomain')) || response[0])
    );
  }

  /**
   * Set the protocol related fields in the payload according to the selected
   * protocols.
   *
   * @param  protocolAccess The selected protocols.
   * @returns The payload with properties set according to the selected protocols.
   */
  setProtocolRelatedPayload(protocolAccess: ViewProtocol[]): CreateView {
    const payload: CreateView = {};
    const protocols = protocolAccess.map(i => i.type);
    const hasS3 = protocols.includes('S3');
    const hasSmb = protocols.includes('SMB');
    const hasNfs = protocols.includes('NFS') || protocols.includes('NFS4');

    // Remove properties which are invalid per protocol.
    if (!hasS3) {
      payload.objectServicesMappingConfig = null;
    }
    if (!hasSmb) {
      payload.enableFastDurableHandle =
        payload.enableOfflineCaching =
        payload.enableSmbAccessBasedEnumeration =
        payload.enableSmbEncryption =
        payload.enableSmbOplock =
        payload.enableSmbViewDiscovery =
        payload.enforceSmbEncryption =
        payload.smbPermissionsInfo =
        payload.sharePermissions = null;
    }
    if (!hasNfs) {
      payload.enableNfsKerberosAuthentication =
        payload.enableNfsKerberosIntegrity =
        payload.enableNfsKerberosPrivacy =
        payload.enableNfsUnixAuthentication =
        payload.enableNfsViewDiscovery =
        payload.enableNfsWcc =
        payload.nfsAllSquash =
        payload.nfsRootPermissions =
        payload.nfsRootPermissions =
        payload.nfsRootSquash = null;
    }
    if (!hasSmb && !hasNfs) {
        payload.overrideGlobalNetgroupWhitelist =
        payload.selfServiceSnapshotConfig = null;
        if (!flagEnabled(this.irisCtx.irisContext, 's3LifecycleManagement')) {
          payload.fileLockConfig = null;
        }
    }

    if (!hasSmb && !hasNfs && !hasS3) {
      // Global list can now also be overriden with S3 protocol.
      payload.overrideGlobalSubnetWhitelist = null;
    }

    return payload;
  }

  /**
   * Method called for tranaformation of views payload.
   *
   * @param   data   untransformed view payload
   * @returns        transformed  view payload
   */
  transformViewsPayload(data: any) {
    const payload = {
      ...data,
      ...this.setProtocolRelatedPayload(data.protocolAccess),
    };

    // Remove 'objectServicesMappingConfig' if category is not 'ObjectServices'.
    if (payload.category !== 'ObjectServices') {
      payload.objectServicesMappingConfig = null;
      payload.versioning = null;
    }

    // alertThresholdPercentage should not be provided for view logical quota.
    if (payload.logicalQuota) {
      delete payload.logicalQuota.alertThresholdPercentage;
    }

    // Stub the View Pinning config if nil. Otherwise Iris will not clear it properly.
    if (!payload.viewPinningConfig || !payload.viewPinningConfig.enabled) {
      delete payload.viewPinningConfig;
    }

    // Delete selfServiceSnapshotConfig object for archive service category.
    if (payload.category === 'ArchiveServices') {
      delete payload.selfServiceSnapshotConfig;
      delete payload.antivirusScanConfig;
    }

    // Remove the File DataLock autoLock duration if it is zero.
    if (payload.fileLockConfig?.autoLockAfterDurationIdleMsecs === 0) {
      delete payload.fileLockConfig.autoLockAfterDurationIdleMsecs;
    }

    // delete un-necessary properties if s3LifecycleManagement is not enabled.
    if (!flagEnabled(this.irisCtx.irisContext, 's3LifecycleManagement')) {
      delete payload['lifecycleManagement'];
      delete payload['versioning'];
    }

    // Delete all other properties in lifecycleManagement and only keep rules.
    if (payload.lifecycleManagement) {
      const {rules} = payload.lifecycleManagement;
      payload.lifecycleManagement = {
        rules: (rules && rules.length > 0) ? rules : null,
      };
    }

    // Remove smb permissions in case of externally triggered backup target
    // since the view is immutable and causes 'kPermissionDenied' on trying to
    // create/update view with smb permissions.
    if (payload.isExternallyTriggeredBackupTarget) {
      payload.smbPermissionsInfo = null;
    }

    Object.keys(payload).forEach(key => {
      const value = payload[key];

      if (value !== null && typeof value === 'object' && !this.isEmptyObj(payload[key])
        && !Array.isArray(value)) {
        Object.keys(value).forEach(name => {
          if (value[name] === null) {
            delete value[name];
          }
        });
      }

      if (value === null || this.isEmptyObj(value)) {
        delete payload[key];
      }
    });

    // Don't send viewProtection property in updateViewParam because
    // 'viewProtection' is read-only property and should't be updated as part of the View update API.
    // https://jira.cohesity.com/browse/ENG-248096
    delete payload['viewProtection'];

    return payload;
  }

  /**
   * Method to get default SMB options based on the most secure settings toggle.
   *
   * @param options object
   * @param mostSecureSettingsEnabled Boolen value to check if most secure setting is enabled.
   * @returns
   */
  defaultSMBOptions(options: SmbConfig, mostSecureSettingsEnabled: boolean): SmbConfig {
    options = options || {};

    // Remove Everyone permissions from SMB and Share level permissions.
    if (mostSecureSettingsEnabled) {
      const smbEveryoneIndex = findEveryoneIndex(options?.smbPermissionsInfo?.permissions);
      const shareEveryoneIndex = findEveryoneIndex(options?.sharePermissions?.permissions);
      if (smbEveryoneIndex > -1) {
        options?.smbPermissionsInfo?.permissions?.splice(smbEveryoneIndex, 1);
      }
      if (shareEveryoneIndex > -1) {
        options?.sharePermissions?.permissions?.splice(shareEveryoneIndex, 1);
      }
      return options;
    }

    // override with default values
    options.smbPermissionsInfo = DefaultCreateViewValue.smbPermissionsInfo;
    options.sharePermissions = DefaultCreateViewValue.sharePermissions;
    return options;
  }

  /**
   * Method to get default Nfs options based on the most secure settings toggle.
   *
   * @param options object
   * @param mostSecureSettingsEnabled Boolen value to check if most secure setting is enabled.
   * @returns
   */
  defaultNfsOptions(options: NfsConfig, mostSecureSettingsEnabled: boolean): NfsConfig {
    options = (options || {}) as NfsConfig;

    // Enable only krb5p auth protocol.
    if (mostSecureSettingsEnabled) {
      options.enableNfsKerberosAuthentication = false;
      options.enableNfsKerberosIntegrity = false;
      options.enableNfsKerberosPrivacy = true;
      options.enableNfsUnixAuthentication = false;
      return options;
    }

    // Enable all auth protocols.
    options.enableNfsKerberosAuthentication = true;
    options.enableNfsKerberosIntegrity = true;
    options.enableNfsKerberosPrivacy = true;
    options.enableNfsUnixAuthentication = true;
    return options;
  }

  /**
   * Update various security settings based on Most Secure Settings toggle in the Create View dialog.
   *
   * @param formValue The form value.
   * @param viewInfo The transformed payload before secure settings.
   * @return CreateView The transformed payload after secure settings applied.
   */
  transformDialogSecureViewSettings(formValue: CreateViewDialogForm, viewInfo: ViewInfo): CreateView {
    let payload: CreateView = {
      ...viewInfo,
      smbPermissionsInfo: viewInfo.smbPermissionsInfo ?? structuredClone(DefaultCreateViewValue.smbPermissionsInfo),
      sharePermissions: viewInfo.sharePermissions ?? structuredClone(DefaultCreateViewValue.sharePermissions),
    };

    const protocolList = formValue.createViewBasicForm.protocolAccess;

    const mostSecureSettingsEnabled = formValue.createViewBasicForm.mostSecureSettings;

    if (isSMBProtocolSelected(protocolList)) {
      payload = this.defaultSMBOptions(payload, mostSecureSettingsEnabled);
    }

    if (isNFSProtocolSelected(protocolList)) {
      payload = this.defaultNfsOptions(payload, mostSecureSettingsEnabled);
    }

    return payload;
  }

  /**
   * Transforms the form value for Create View Dialog workflow.
   *
   * @param formValue The form value.
   * @param createViewData The create view data.
   * @param isCreateMode Creating a new template OR creating a view form default template OR creating a view by directly
   * visiting URL.
   * @returns The transformed payload.
   */
  transformCreateViewDialogPayload(
    formValue: CreateViewDialogForm,
    createViewData: CreateViewData,
    isCreateMode: boolean
  ): CreateView {
    if (createViewData?.defaultTemplateName === 'Unknown') {
      createViewData.viewParams.sharePermissions = createViewData.viewParams.sharePermissions || {};
    }

    const payload: CreateView = {
      enableNfsWcc: false,
      // Need to add some overrides as they are not present as dialog form fields.
      ...CreateViewDialogOverrides,

      // The view params if it's created from a template.
      ...(createViewData?.viewParams || {}),

      // Use the tranformCreateViewDialogPayload method to transform the form value.
      ...this.transformCreateViewPayload(formValue as CreateViewPageForm, createViewData),
    };

    // alertThresholdPercentage should not be provided for view/template logical quota.
    if (payload.logicalQuota) {
      delete payload.logicalQuota.alertThresholdPercentage;
    }

    // Add default values like secure settings only in create mode
    if (!isCreateMode) {
      return payload;
    }

    // When create file share view with smb protocol selected, enable fast durable by default.
    payload.enableFastDurableHandle = payload.category === 'FileServices' &&
    isSMBProtocolSelected(payload.protocolAccess);

    return this.transformDialogSecureViewSettings(formValue, payload);
  }

  /**
   * Transforms the create view payload using the form value and additional data.
   *
   * @param formValue The form value.
   * @param createViewData The create view data.
   * @returns The transformed payload.
   */
  transformCreateViewPayload(formValue: CreateViewPageForm, createViewData?: CreateViewData): CreateView {
    const { lifecycleManagement, versioning, bucketPolicy, ...data } = transformFromCreateViewForm(formValue);
    const payload: CreateView = {
      ...data,
      ...this.setProtocolRelatedPayload(data.protocolAccess),

      // delete un-necessary properties if s3LifecycleManagement is not enabled.
      ...(flagEnabled(this.irisCtx.irisContext, 's3LifecycleManagement') ? { lifecycleManagement, versioning } : {}),

      // delete un-necessary properties if s3BucketPolicyAndAcls is not enabled.
      ...(flagEnabled(this.irisCtx.irisContext, 's3BucketPolicyAndAcls') ? { bucketPolicy } : {})
    };

    // Delete whole bucketPolicy object if rawPolicy is empty string.
    if (!payload.bucketPolicy?.rawPolicy || payload.bucketPolicy?.rawPolicy === '') {
      delete payload.bucketPolicy;
    }

    // alertThresholdPercentage does not belong in the request.
    if (payload.logicalQuota) {
      delete payload.logicalQuota.alertThresholdPercentage;
    }

    // Remove the File DataLock autoLock duration if it is zero.
    if (payload.fileLockConfig?.autoLockAfterDurationIdleMsecs === 0) {
      delete payload.fileLockConfig.autoLockAfterDurationIdleMsecs;
    }

    // Add Intent when a template is used to create a view.
    if (createViewData?.id) {
      payload.intent = {
        templateId: createViewData.id,
        templateName: createViewData.name,
      };

      // Populate isExternallyTriggeredBackupTarget based on selected template details.
      if (createViewData?.viewParams?.isExternallyTriggeredBackupTarget) {
        payload.isExternallyTriggeredBackupTarget = true;
      }
    }

    return payload;
  }

  /**
   * Transform create view form value to create view template payload.
   *
   * @param formValue The form value
   * @returns The transformed payload.
   */
  transformCreateViewTemplatePayload(formValue: CreateViewPageForm): Template {
    const { name, ...viewParams } = this.transformCreateViewPayload(formValue);

    return {
      name,
      viewParams,
      isDefault: false,
    };
  }

  /**
   * Determines whether the value is an empty object or not.
   *
   * @param   value   value input
   */
  isEmptyObj(value: any) {
    if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
      return !Object.keys(value).length;
    }
    return false;
  }

  /**
   * Determines whether the File DataLock settings should be disabled.
   *
   * @param   view         view information.
   * @param   inEditMode   Determines whther in view edit mode or not.
   * @returns              True if the File DataLock settings should be
   *                       disabled from user interaction.
   */
  isFileDataLockDisabled(view: ViewInfo, inEditMode = false) {
    const isDso = !!this.userService.privs.DATA_SECURITY;

    // True if this is a DataLock View OR
    return view._hasViewDataLock ||

      // It is Edit mode AND one of the following is true:
      (inEditMode &&

      // Compliance mode OR
      (view.fileLockConfig && view.fileLockConfig.mode === 'Compliance' && !isDso) ||

      // Enterprise mode and neither DSO nor Local Admin
      ((view.fileLockConfig && view.fileLockConfig.mode === 'Enterprise' &&
        !isDso && !this.userService.isLocalAdminUser())));
  }

  /**
   * Examines View/Share name and returns warnings for certain combinations of
   * special characters and protocols.
   *
   * @param   name      View name
   * @param   protocols  View access protocols
   */
  getViewNameWarnings(name: string, viewProtocols: ViewProtocol[]) {
    const protocols = (viewProtocols || []).map(protocol => protocol.type);
    const warnings = [];
    name = name || '';

    // Look for S3 restricted characters: whitespace
    if (protocols.includes('S3') && name.match(/\s/)) {
      warnings.push('viewModify.nameWithSpaces');
    }

    // Look for SMB restricted characters: < : " \ | ? *
    if (protocols.includes('SMB') &&
      name.match(/[<:"\\|?*]/)) {
      warnings.push('viewModify.nameWithBackslashes');
    }

    return warnings;
  }

  /**
   * Aggregates ip/mask string from either the ipv4 or ipv6 address.
   * Example:
   * Input: subnetList = [{ip: "1.1.1.1", netmaskIp4: "255.255.255.1"}];
   * Output: subnetList = [{ip: "1.1.1.1", netmaskIp4: "255.255.255.1", _ip: "1.1.1.1/255.255.255.1"}];
   *
   * @param   subnetList   subnet list.
   * @return               transformed subnet list.
   */
  transformIPWhitelist(subnetList: Subnet[]): SubnetWhiteList[] {
    const updatedSubnetList = [ ...(subnetList || []) ] as SubnetWhiteList[];

    return updatedSubnetList.map(subnet => {
      const mask = parseInt(String(subnet.netmaskBits), 10) > -1 ?
        +subnet.netmaskBits : subnet.netmaskIp4;
      subnet._ip = subnet.ip + '/' + mask;

      return subnet;
    });
  }

  /**
   * Validator to check if the protocol has atleast one read/write protocol.
   *
   * @param control The formControl (protocolAccess)
   * @returns The error, if found.
   */
  protocolAccessValidator(control: AbstractControl): ValidationErrors | null {
    const protocolAccess: ViewProtocol[] = control.value ||  [];
    // Add the error when there is no read/write protocol.
    if (!protocolAccess.some(protocol => protocol.mode === 'ReadWrite')) {
      return { readOnlyProtocolError: true };
    }
    return null;
  }

  /**
   * Fetch all available principals of share.
   * It's constructed from 3 parts.
   * The first part is all active directory principals.
   * The second part is all local users.
   * The third part is all local groups.
   *
   * @param   share   The Share object.
   * @returns   Observable of principalHash.
   */
  getDomainPrincipalsHashForShare(share: SharesInfo): Observable<any> {
    let sidList = [];
    const sharePermissions = share?.smbConfig?.permissions;
    const superUserSids = share?.smbConfig?.superUserSids;

    sidList = sidList.concat(
      sharePermissions?.reduce((acc, prem) => {
        acc.push(prem?.sid);
        return acc;
      }, []),
      superUserSids
    );

    // Make 3 apis calls to fetch all kind of principals.
    const params: ActiveDirectoryServiceApi.SearchActiveDirectoryPrincipalsParams = {
      includeComputers: true,
      sids: sidList,
    };
    const adPrincipals$ = this.activeDirectoryServiceApiV1.SearchActiveDirectoryPrincipals(params);
    const localUsers$ = this.userService.getAllUsers({ domain: 'LOCAL' }) as Observable<User[]>;
    const localGroups$ = this.groupsService.GetGroups({ domain: 'LOCAL' });

    return forkJoin([adPrincipals$, localUsers$, localGroups$])
      .pipe(
        map(([principals, users, groups]) => {
          const allPrincipals = [...(principals || []), ...(users || []), ...(groups || [])];
          return keyBy(allPrincipals, 'sid');
        }));
  }

  /**
   * Fetch all available principals.
   * It's constructed from 3 parts.
   * The first part is all active directory principals.
   * The second part is all local users.
   * The third part is all local groups.
   *
   * @param   view   The view object.
   * @returns   Observable of principalHash.
   */
  getDomainPrincipalsHash(view: ViewInfo): Observable<any> {
    // Generate sid list which is used to fetch all active directory principals.
    let sidList = [];
    const wellKnownSids = SMB_WELL_KNOWN_PRINCIPALS.map(principle => principle.sid);
    const smbPermissionsInfo = view.smbPermissionsInfo || {};
    const sharePermissions = view.sharePermissions as ViewSharePermissions || {};
    const ownerIsWellKnown = wellKnownSids.includes(smbPermissionsInfo.ownerSid);
    const selfServiceSnapshotConfig = view.selfServiceSnapshotConfig as SelfServiceSnapshotConfig;

    // Combine the view permissions and share permissions into one list.
    const allPermissions = [
      ...(sharePermissions.permissions || []),
      ...(smbPermissionsInfo.permissions || [])
    ];

    // Add any Superusers to the sidList.
    sidList = [...sidList, sharePermissions.superUserSids || []];

    // Add any users with snapshots acls to the sidList.
    sidList = [
      ...sidList,
      selfServiceSnapshotConfig?.allowAccessSids || [],
      selfServiceSnapshotConfig?.denyAccessSids || [],
    ];

    // Lookup the existing ownerSid.
    if (smbPermissionsInfo.ownerSid && !ownerIsWellKnown) {
      sidList.push(smbPermissionsInfo.ownerSid);
    }

    // Lookup the users with permissions.
    if (allPermissions.length) {
      sidList = sidList.concat(
        allPermissions.reduce((acc, perm) => {
          acc.push(perm.sid);
          return acc;
        }, [])
      );
    }

    // Make 3 apis calls to fetch all kind of principals.
    const params: ActiveDirectoryServiceApi.SearchActiveDirectoryPrincipalsParams = {
      includeComputers: false,
      sids: sidList,
    };
    const adPrincipals$ = this.activeDirectoryServiceApiV1.SearchActiveDirectoryPrincipals(params);
    const localUsers$ = this.userService.getAllUsers({ domain: 'LOCAL' }) as Observable<User[]>;
    const localGroups$ = this.groupsService.GetGroups({ domain: 'LOCAL' });

    return forkJoin([adPrincipals$, localUsers$, localGroups$])
      .pipe(
        map(([principals, users, groups]) => {
          const allPrincipals = [...(principals || []), ...(users || []), ...(groups || [])];
          return keyBy(allPrincipals, 'sid');
        }));
  }

  /**
   * Returns the translation key for the corresponding scan trigger.
   *
   * @param   view   View object.
   * @returns    Translation key.
   */
  getScanTriggerKey(view: ViewInfo): string {
    const avConfig = view.antivirusScanConfig;

    switch (true) {
      case avConfig.scanOnAccess && avConfig.scanOnClose:
        return 'antivirus.scanOnOpenAndClose';

      case !avConfig.scanOnAccess && avConfig.scanOnClose:
        return 'antivirus.scanOnClose';

      case avConfig.scanOnAccess && !avConfig.scanOnClose:
        return 'antivirus.scanOnOpen';
    }
  }

  /**
   * Map view's whitelist with _ip which is used for coh-whitelist-table component.
   * The _ip might comes from v1 or v2.
   *
   * @param   view   View object.
   */
  mapSubnetIpBits (view: ViewInfo) {
    (view.subnetWhitelist || []).map((whitelist: Subnet) => {
      const netMask = whitelist.netmaskIp4 || whitelist.netmaskBits;
      (whitelist as any)._ip = whitelist.ip + '/' + netMask;
      return whitelist;
    });
  }

  /**
   * Method called to show warning which displays a notice and requires user confirmation before proceeding
   *
   * @param   confirmFn  function to execute upon confirmation
   * @param   cancelFn   function to execute upon cancel
   * @param   challengeText   translation key to show as part of dialog content
   */
  openChallengeDialog(confirmFn: () => void, cancelFn: () => void, challengeText: string): void {
    const options = {
      title: 'confirmation',
      copy: challengeText,
      confirmButtonLabel: 'confirm',
    };

    this.dialogService.simpleDialog(null, options, { width: '38rem', autoFocus: false })
      .subscribe(response => {
        // confirm
        if (response) {
          confirmFn();
          return;
        }
        // cancel
        cancelFn();
      }
     );
  }

  /**
   * Opens the new/old Create View Dialog depending on the feature flag
   * 'createViewRefactor'.
   *
   * @param dialogParams The dialog params
   * @returns Observable which resolved after dialog is closed/cancelled.
   */
  openCreateViewDialog(dialogParams: CreateViewData = {}): Observable<View | null> {
    const modalConfig = {
      minWidth: '34rem',
      maxWidth: '50rem',
    };

    const dialogName = flagEnabled(this.irisContextService.irisContext, 'createViewRefactor') ?
      'create-view-dialog-new' : 'modify-view-dialog';

    // Opens modify view template dialog.
    return this.dialogService.showDialog(dialogName, dialogParams, modalConfig)
      .pipe(filter(Boolean));
  }

  /**
   * Returns translation key for lock tooltip.
   *
   * @param   view   View information
   */
  getLockTooltipTranslationKey(view: ViewInfo): string {
    switch (true) {
      case view._hadViewDataLock:
        return 'views.tooltip.dataLock';
      case view._hasS3ObjectLock:
        return 'views.tooltip.s3ObjectLock';
      case view._hasFileDataLock:
        return 'views.tooltip.fileDataLock';
      case view.viewLockEnabled:
        return 'views.tooltip.locked';
      default:
        return '';
    }
  }
}
