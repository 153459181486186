/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClusterIdentifierList } from '../models/cluster-identifier-list';
import { GetViewsResponse } from '../models/get-views-response';

@Injectable({
  providedIn: 'root',
})
export class ViewsApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getViews
   */
  static readonly GetViewsPath = '/views';

  /**
   * List Views.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getViews()` instead.
   *
   * This method doesn't expect any request body.
   */
  getViews$Response(params?: {

    /**
     * Filter by Cohesity Cluster Identifier IDs.
     */
    clusterIdentifierIds?: ClusterIdentifierList;

  }): Observable<StrictHttpResponse<GetViewsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ViewsApiService.GetViewsPath, 'get');
    if (params) {

      rb.query('clusterIdentifierIds', params.clusterIdentifierIds, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetViewsResponse>;
      })
    );
  }

  /**
   * List Views.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getViews$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getViews(params?: {

    /**
     * Filter by Cohesity Cluster Identifier IDs.
     */
    clusterIdentifierIds?: ClusterIdentifierList;

  }): Observable<GetViewsResponse> {

    return this.getViews$Response(params).pipe(
      map((r: StrictHttpResponse<GetViewsResponse>) => r.body as GetViewsResponse)
    );
  }

}
