// Component: uib-dropdown decorator

;(function(angular, undefined) {
  'use strict';

  angular.module('C.uibDropdown', ['ui.bootstrap'])
    .config(ConfigFn);

  /**
   * Decorate uib-dropdown with alwaysOpen feature.
   */
  function ConfigFn($provide) {
    $provide.decorator('uibDropdownDirective', alwaysOpenDropdown);
  }

  /**
   * @ngdoc decorator
   * @name        ui.bootstrap
   * @method      alwaysOpenDropdown
   *
   * @description
   *   Decorate uibDropdown with the functionality to keep it always open which
   *   is used by c-filters when such dropdown are shows in the view all modal
   *   view.
   */
  function alwaysOpenDropdown(_, $delegate) {
    var directive = $delegate[0];
    var compile = directive.compile;

    directive.compile = function proxyCompileFn() {
      // Cache the existing linkFn to execute after our decorator.
      var linkFn = compile.apply(this, arguments);

      return function proxyLinkFn(scope, elem, attrs, ctrls) {
        var isOpen;

        // if present watch of isAlwaysOpen property and used to prevent
        // dropdown from closing when isAlwaysOpen is set to true.
        if (attrs.hasOwnProperty('isAlwaysOpen')) {
          attrs.$observe('isAlwaysOpen', function observingAlwaysOpen(value) {
            scope.isAlwaysOpen = (value === 'false' ? false :
              (value === 'true' ? true : undefined));
          });

          // prevent updates of isOpen property if isAlwaysOpen is true to keep
          // dropdown stay opened unit isAlwaysOpen is set to false.
          Object.defineProperty(scope, 'isOpen', {
            get: function getIsOpen() {
              return scope.isAlwaysOpen ? true : isOpen;
            },
            set: function setIsOpen(newValue) {
              if (scope.isAlwaysOpen) {
                return isOpen = true;
              }
              return isOpen = newValue;
            },
          });
        }

        // Executing the original linkFn now.
        return linkFn.apply(this, arguments);
      };
    };

    return $delegate;
  }

})(angular);
