// Modules: c-source-tree

;(function(angular, undefined) {
  'use strict';

  angular.module(
    'C.pubSourceTree',
    ['C.pubJobServiceFormatter', 'C.sourceMeta', 'treeControl']
  );

})(angular);
