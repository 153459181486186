import { Injectable } from '@angular/core';
import { ClusterMetadataDocsPurpose, PlatformServiceApi } from '@cohesity/api/v2';
import { IrisContextService, isIbmBaaSEnabled } from '@cohesity/iris-core';
import { distinctUntilChanged } from 'rxjs/operators';

/**
 * SLA model
 */
export interface Sla {
  full: number;
  incremental: number;
  log?: number;
}

@Injectable({
  providedIn: 'root',
})
export class ClusterMetadataService {
  /**
   * Default SLA value for a cluster
   */
  defaultSla: Sla = { full: 120, incremental: 60, log: 60 };

  /**
   * Record of Docs URL by purpose
   */
  urlByPurpose: Partial<Record<ClusterMetadataDocsPurpose['purpose'], string>> = {
    APIDocs: '',
    BackupServiceConnectionDocs: '',
    HelpDocs: '',
    SupportDocs: '',
    LicenseDocs: '',
  };

  constructor(private platformServiceApi: PlatformServiceApi, private irisContextService: IrisContextService) {
    // TODO: Populate URLS using metadata API
    this.irisContextService.irisContext$.pipe(distinctUntilChanged()).subscribe(context => {
      if (isIbmBaaSEnabled(context)) {
        this.urlByPurpose.SupportDocs = 'https://cloud.ibm.com/unifiedsupport/supportcenter';
        this.urlByPurpose.HelpDocs = 'https://cloud.ibm.com/docs';
        this.defaultSla = { full: 24 * 60, incremental: 12 * 60, log: 60 };
      }
    });
  }

  /**
   * Fetch cluster metadata
   *
   * TODO: Fetch from actual metadata API once backend support is available
   */
  fetchClusterMetadata() {
    this.platformServiceApi.GetCluster().subscribe(
      res => console.log(res),
      err => console.log(err)
    );
  }

  /**
   * Get documentation URL based on usage purpose
   *
   * @param purpose purpose of the docs URL
   * @returns URL of the requested documentation
   */
  getDocUrl(purpose: ClusterMetadataDocsPurpose['purpose'] = 'HelpDocs'): string {
    return this.urlByPurpose[purpose];
  }

  /**
   * Default SLA values for a protection job
   *
   * @returns default SLA value
   */
  getDefaultSla(): Sla {
    return this.defaultSla;
  }
}
