import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CreateForm, createFormProviders } from '@cohesity/shared-forms';
import moment from 'moment';

@Component({
  selector: 'coh-view-data-lock-expiry',
  templateUrl: './view-data-lock-expiry.component.html',
  providers: createFormProviders(ViewDataLockExpiryComponent),
})
export class ViewDataLockExpiryComponent {
  /**
   * The minimum date that can be selected. Set to the initial value of the
   * dataLockExpiryUsecs.
   */
  minDataLockExpiryDate: string;

  /**
   * The form.
   */
  form = new CreateForm<number, { dataLockExpiryDate: string }>(this, {
    formControls: {
      dataLockExpiryDate: new UntypedFormControl(null),
    },
    // The value is provided in usecs, convert to Date object to makes use of
    // datePicker.
    transformToFormGroup: dataLockExpiryUsecs => {
      // Set the min date for the first time when the form is initialized.
      this.minDataLockExpiryDate = this.minDataLockExpiryDate || moment(dataLockExpiryUsecs / 1000).toISOString();

      return {
        dataLockExpiryDate: moment(dataLockExpiryUsecs / 1000).toISOString()
      };
    },
    // Transform back from the Date object (end of that day) to usecs.
    transformFromFormGroup: ({ dataLockExpiryDate }) => moment(dataLockExpiryDate).endOf('day').valueOf() * 1000,
  });
}
