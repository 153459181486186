import { ChangeDetectorRef, ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BwLimit, SaaSConnectionBandwidthLimits, RigelmgmtServiceApi, BwTimeOfDay } from '@cohesity/api/rms';
import { ByteSizeService } from '@cohesity/helix';
import { AjaxHandlerService, userTimezone } from '@cohesity/utils';
import { Controls, DataInput, NgxRootFormComponent, subformComponentProviders, takeUntilDestroyed } from 'ngx-sub-form';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';
import { DayVal } from 'src/app/shared';

/**
 * Bandwidth type.
 */
export enum Bandwidth {
  Download = 'download',
  Upload = 'upload',
}

/**
 * Bandwidth rule config.
 */
export interface BandwidthRule {
  megabytesPerSecond: number;
  days: DayVal[];
  endTime: BwTimeOfDay;
  startTime: BwTimeOfDay;
  type: Bandwidth;
}

/**
 * Bandwidth rules form.
 */
export interface BandwidthRulesForm {
  timezone: string;
  rules: BandwidthRule[];
}

/**
 * @description
 *
 * Helios Connector Bandwidth Usage Rules modal form.
 */
@Component({
  selector: 'coh-bandwidth-usage',
  templateUrl: './bandwidth-usage.component.html',
  styleUrls: ['./bandwidth-usage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: subformComponentProviders(BandwidthUsageComponent),
})
export class BandwidthUsageComponent extends NgxRootFormComponent<BandwidthRulesForm> implements OnInit {
  /**
   * Bandwidth rules data used to populate rules input controls.
   */
  bandwidthRules$: Observable<BandwidthRulesForm>;

  /**
   * Ngx form data input.
   */
  @DataInput()
  dataInput: Required<BandwidthRulesForm>;

  /**
   * Ngx form data output.
   */
  @Output()
  readonly dataOutput = new EventEmitter<BandwidthRulesForm>();

  /**
   * Subject for disabling user inputs when form is updating.
   */
  readonly busy$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly group: any,
    private ajaxHandler: AjaxHandlerService,
    private bytesService: ByteSizeService,
    private cdr: ChangeDetectorRef,
    private rigelService: RigelmgmtServiceApi,
    readonly dialogRef: MatDialogRef<BandwidthUsageComponent>
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    const connectionId = this.group.connectorGroupId ? this.group.connectorGroupId : this.group.groupId;

    this.bandwidthRules$ = this.rigelService
      .GetConnectionBandwidth({ connectionId })
      .pipe(
        catchError(e => {
          this.ajaxHandler.handler(e);
          return of({});
        }),
        map((res: SaaSConnectionBandwidthLimits) => {
          const rules = [];

          if (Array.isArray(res.download)) {
            rules.push(...res.download.map(bandwidth => ({ type: Bandwidth.Download, ...bandwidth })));
          }

          if (Array.isArray(res.upload)) {
            rules.push(...res.upload.map(bandwidth => ({ type: Bandwidth.Upload, ...bandwidth })));
          }

          if (!rules.length) {
            const defaultValues = this.getDefaultValues();

            if (res.timezone) {
              defaultValues.timezone = res.timezone;
            }
            return defaultValues;
          }
          return {
            rules: rules.map(({ bytesPerSecond, type, timePeriods: { days, startTime, endTime } }) => ({
              megabytesPerSecond: +this.bytesService.bytesToSize(bytesPerSecond, 1, false, 'MiB').size,
              days,
              endTime,
              startTime,
              type,
            })),
            timezone: res.timezone,
          };
        }),
        shareReplay(1)
      );

    this.bandwidthRules$.pipe(takeUntilDestroyed(this)).subscribe(rules => {
      this.dataInput = rules;
      this.cdr.detectChanges();
    });
  }

  /**
   * Implementation of the abstracted NgxSubForm.
   * Returns the form controls that are linked to this NgxSubFormComponent.
   */
  getFormControls(): Controls<BandwidthRulesForm> {
    return {
      timezone: new UntypedFormControl(userTimezone, Validators.required),
      rules: new UntypedFormArray([this.getEmptyRule()]),
    };
  }

  /**
   * Adds bandwidth rule to the end of rules list.
   */
  addRule() {
    this.formGroupControls.rules.push(this.getEmptyRule());
  }

  /**
   * Returns empty rule object for form control.
   *
   * @returns empty rule object.
   */
  private getEmptyRuleObject(): BandwidthRule {
    return {
      type: Bandwidth.Download,
      megabytesPerSecond: 0,
      days: [],
      endTime: {
        hour: 0,
        minute: 0,
      },
      startTime: {
        hour: 0,
        minute: 0,
      },
    };
  }

  /**
   * Returns form control with empty inputs.
   */
  private getEmptyRule(): UntypedFormControl {
    return new UntypedFormControl(this.getEmptyRuleObject());
  }

  /**
   * Returns default values for form.
   *
   * @returns default values for form.
   */
  protected getDefaultValues(): BandwidthRulesForm {
    return {
      rules: [this.getEmptyRuleObject()],
      timezone: userTimezone,
    };
  }

  /**
   * Removes bandwidth rule at specified index.
   *
   * @param index Index of rule to be remove.
   */
  removeRuleAt(index = 0) {
    this.formGroupControls.rules.removeAt(index);
    this.cdr.detectChanges();
  }

  /**
   * Adds new rule at specified index.
   *
   * @param index Order where new rule will be inserted.
   */
  addRuleAt(index = 0) {
    this.formGroupControls.rules.insert(index, this.getEmptyRule());
    this.cdr.detectChanges();
  }

  /**
   * Converts bandwidth rule to API bandwidth payload.
   *
   * @param    bandwidthRule  Bandwidth rule to be converted.
   * @returns  Bandwidth limit for API payload.
   */
  private mapControlsToData({ megabytesPerSecond, days, startTime, endTime }: BandwidthRule): BwLimit {
    return {
      bytesPerSecond: Math.round(megabytesPerSecond) * Math.pow(1024, 2),
      timePeriods: {
        days: days as any,
        startTime,
        endTime,
      },
    };
  }

  /**
   * Handles connector bandwidth rules update.
   */
  onSubmit() {
    this.busy$.next(true);

    const connectionId = this.group.connectorGroupId ? this.group.connectorGroupId : this.group.groupId;

    this.rigelService
      .GetConnectionBandwidth({ connectionId })
      .pipe(
        takeUntilDestroyed(this),
        switchMap(({ tenantId }) => {
          const { value } = this.formGroupControls.rules;

          const upload = value.filter(({ type }) => type === Bandwidth.Upload).map(val => this.mapControlsToData(val));
          const download = value
            .filter(({ type }) => type === Bandwidth.Download)
            .map(val => this.mapControlsToData(val));

          return this.rigelService.UpdateConnectionBandwidth({
            connectionId: this.group.parentId || this.group.groupId,
            download,
            tenantId,
            connectorGroupId: this.group.parentId ? this.group.groupId : this.group.connectorGroupId,
            timezone: this.formGroupControls.timezone.value,
            upload,
          });
        }),
        catchError(error => {
          this.ajaxHandler.handler(error);
          this.busy$.next(false);
          return throwError(new Error(error));
        }),
      )
      .subscribe(() => this.dialogRef.close('bandwidthSettingsSaved'));
  }
}
