<div [ngClass]="formGroupControls.objects.length && !hideSelectedObjectsContainer ?
  'object-search-container-trunc' : 'object-search-container'" *ngIf="!hideSearch">
  <div class="flex-row-sm">
    <coh-object-search-field [placeholderText]="searchPlaceholderText"
      [searchPending]="searchPending"
      [query]="query"
      (queryChanged)="onQueryChanged($event)">
    </coh-object-search-field>
    <div *ngIf="advancedSearchOptions">
      <button mat-stroked-button
        type="button"
        color="primary"
        [matBadgeHidden]="!advancedFilterCount"
        matBadge="&#8288;"
        matBadgeColor="info"
        matBadgeSize="small"
        cogDataId="advanced-search"
        (click)="showAdvancedSearch()">
        {{ 'advancedSearch' | translate }}
      </button>
    </div>
  </div>
  <ng-content></ng-content>

  <coh-object-search-results [searchResults]="searchResults"
    [selection]="disableSelection ? null : selection"
    [noData]="(query?.length || advancedFilterCount > 0) && !searchPending && !searchResults?.length"
    [implicitObjectIds]="implicitObjectIds"
    [detailTemplate]="detailTemplate"
    [disabled]="formGroup.disabled">
  </coh-object-search-results>
</div>

<div class="selected-object-container" *ngIf="formGroupControls.objects.length">
  <ng-container *ngTemplateOutlet="selectionListTemplate || defaultSelectionListTemplate"></ng-container>
</div>

<ng-template #defaultSelectionListTemplate>
  <div class="selected-title">
    {{selectedItemText ? selectedItemText : 'selectedNItems' | translate : { items: formGroupControls.objects.length} }}
  </div>
  <mat-list>
    <cdk-virtual-scroll-viewport class="select-list-virtual-scroll"
      [itemSize]="48"
      [minBufferPx]="500"
      [maxBufferPx]="800">
      <mat-list-item *cdkVirtualFor="let object of formGroupValues.objects" >
        <ng-container
          *ngTemplateOutlet="selectionTemplate || defaultSelectionTemplate; context: {$implicit: object}">
        </ng-container>
        <button mat-icon-button
          class="remove-item-button"
          *ngIf="!hideSearch"
          type="button"
          (click)="removeObject(object.objectInfo)"
          cogDataId="remove-object-{{object.name}}"
          [attr.aria-label]="'remove' | translate">
          <cog-icon shape="close"></cog-icon>
        </button>
      </mat-list-item>
    </cdk-virtual-scroll-viewport>
  </mat-list>
</ng-template>

<ng-template #defaultSelectionTemplate
  let-snapshotSelection>
  <coh-restore-point-selection-detail icon="helix:object-vm"
    [wideLayout]="hideSearch"
    [restorePointSelection]="snapshotSelection"
    (editRestorePoint)="editObject(snapshotSelection)">
  </coh-restore-point-selection-detail>
</ng-template>
