import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule, DataIdModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';

import { ThreatsDiscoveredComponent } from './threats-discovered.component';

@NgModule({
  imports: [
    CohesityHelixModule,
    CommonModule,
    DataIdModule,
    TranslateModule,
  ],
  exports: [ThreatsDiscoveredComponent],
  declarations: [ThreatsDiscoveredComponent],
})
export class ThreatsDiscoveredModule {}
