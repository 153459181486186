import { SourceHealthCheckMatrixComponent } from 'src/app/shared/source-health-checks';
import { AwsLeafTypes } from 'src/app/shared/constants/cloud.constants';

;(function(angular, undefined) {
  'use strict';

  var modName = 'C.pubSourceTree';
  var componentName = 'cSourceTreePub';
  var options = {
    // These are bound to the controller, not the scope
    bindings: {
      // @type {object} the tree structure
      tree: '=',

      // @type {string} optional header to be displayed in the template
      header: '@?',

      /**
       * Optional options
       * @type  {object}  Properties {
       *   canSelect: bool [true],
       *   canSelectNode: function [undefined] If defined then used to
       *                                       Determines a node selection is
       *                                       allowed or not.
       *   contextMenu: bool [false],
       *   detailedView: bool [false],
       *   defaultNodePropertyFilter: string/array [undefined]
       *                                Sets the given filter instead of show
       *                                all for node property filters.
       *   singleSelect: bool [false],
       *   singleSelectNode: object [undefined],
       *   registeredSource: top-level source object entity (same level as
       *                     vmwareEntity) [undefined],
       *   vmwareToolsRequired: bool [false] defaults filtering to VMware Tools,
       *                                     and displays relevant messaging
       *   vmwareToolsForcedFiltering: bool [false] completely filters out non
       *                                            VMtool VMs via the filter fn
       *   showFullCloudTree: bool [false] if true then don't prune cloud tree.
       *   noFilters: bool [false] Indicates if tree filters should be hidden.
       *   asyncMode: bool [false] if true then node children will be loaded on
       *                          selection/expansion of a given node.
       *   getSourcesParams: object [undefined] custom param used in asyncMode
       *                                        to getSources call used for get
       *                                        full hierarchy for vCenter with
       *                                        resource pool.
       *   ancestorSelectionMode: bool [false] If true use ancestor node
       *                                       selection logic
       *   removeNode: function [undefined] If defined then show node remove
       *                                    icon and on click call provided
       *                                    removeNode callback fn.
       *   canRemoveNode: function [undefined] If defined then used to
       *                                       Determines a node removal is
       *                                       allowed or not.
       *   autoExpandRootNodes: bool [true] If true then root node is auto
       *                                    expanded on initialization.
       *   updateTree: function [undefined] If defined it will notify parent to
       *                                    get refreshed tree data after this
       *                                    component is done with the call for
       *                                    refreshing the tree.
       * }
       */
      options: '=?',
      selectedObjectsCounts: '=?',
      job: '=?',

      /**
       * @type  {string}  If it exists in a modal, the type is 'modal'; otherwise
       * the type is 'page'.
       */
      type: '<?',

      /**
       * @type  {boolean} If c-source-tree-pub is called by c-source-group, the
       * property is 'true'; otherwise it's undefined.
       */
      groupedList: '<?',

      // As source-groups component got introduced, which groups all the
      // c-source-tree-pub which makes this component a child. And as the
      // search bar is inside the source-group, we need to pass the search-text
      // attribute from the parent to child to enable searching.
      externalSearchString: '<?',
    },
    controller: 'SourceTreePubCtrl',
    templateUrl: 'app/global/c-source-tree/c-source-tree-public.html',
  };

  angular
    .module(modName)
    .controller('SourceTreePubCtrl', cSourceTreeFn)
    .component(componentName, options);

  /**
   * @ngdoc component
   * @name C.sourceTree:cSourceTree
   * @function
   *
   * @description
   * Displays a source tree with optional node selection functionality
   *
   * @example
     <c-source-tree
       tree="tree"
       expanded-nodes="expandedNodes"
       tree-ready="treeReady"
       options="options"></c-source-tree>
   */
  function cSourceTreeFn(
    _, $rootScope, $state, $stateParams, $timeout, $filter, $q, $translate,
    evalAJAX, SourceService, PubJobService, PubJobServiceFormatter, SourcesUtil,
    PubSourceService, JobService, cModal, cMessage, ENV_GROUPS, ENUM_ENV_TYPE,
    NAS_FILESYSTEM_MAP, SOURCE_TYPE_GROUPS, FEATURE_FLAGS, $attrs, cUtils,
    ENUM_HOST_TYPE_CONVERSION, ENV_TYPE_CONVERSION, PubSourceServiceUtil,
    ngDialogService, UserService) {
    var $ctrl = this;

    // default options used when they are missing in $ctrl.options
    var defaultsOptions = {
      autoExpandRootNodes: true,
      canRemoveNode: function canRemoveNodeDefault() {return true;},
      toggleNodeSelection: angular.noop,
    };

    var digestDelayMs = 20;

    /** @type {Object} represents the os filter to be applied to the tree. */
    var osTypeFilter = [];

    // Holds the reference to previous $ctrl.tree which is 2 way-binded and used
    // to update $ctrl.leaves if tree got changed.
    var prevTree;

    var filteringTimeoutPromise;

    // List of hierarchy levels to auto-expand, per source type
    var defaultExpandedTypes = {
      kAzure: [
        'kResourceGroup',
      ],
      kAzureNative: [
        'kResourceGroup',
      ],
      kAzureSnapshotManager: [
        'kResourceGroup',
      ],
      kAWS: [
        'kRegion',
        'kAvailabilityZone',
      ],
      kAWSNative: [
        'kRegion',
        'kAvailabilityZone',
      ],
      kAWSSnapshotManager: [
        'kRegion',
        'kAvailabilityZone',
      ],
      kRDSSnapshotManager: [
        'kRegion',
        'kAvailabilityZone',
      ],
      kGCP: [
        'kRegion',
        'kAvailabilityZone',
      ],
      kGCPNative: [
        'kRegion',
        'kAvailabilityZone',
      ],
      kVMware: [
        'kVCenter',
        'kFolder',
        'kDatacenter',
        'kComputerResource',
      ],
      kKVM: [
        'kCluster',
      ],
      kHyperV: [
        'kSCVMMServer',
        'kStandaloneHost',
        'kStandaloneCluster',
        'kHostGroup',
        'kHostCluster',
      ],
      kSQL: ['kInstance'],
      kOracle: ['kDatabase'],
      kPhysical: ['kHostGroup'],
      kPure: ['kStorageArray'],
      kIbmFlashSystem: ['kStorageArray'],
      kNetapp: ['kCluster'],
      kNimble: ['kStorageArray'],
      kGenericNas: ['kGroup'],
      kAcropolis: [
        'kPrismCentral',
        'kOtherHypervisorCluster',
        'kStandaloneCluster',
        'kCluster',
        'kHost',
        'kNetwork',
      ],
      kPhysicalFiles: ['kHostGroup'],
      kIsilon: ['kCluster'],
      kFlashBlade: ['kStorageArray'],
      kHyperVVSS: [
        'kSCVMMServer',
        'kStandaloneHost',
        'kStandaloneCluster',
        'kHostGroup',
        'kHostCluster',
      ],

      // TODO(Tauseef): Modify this as more Office 365 apps are supported.
      // Expanded types within the source details.
      kO365: [
        'kDomain',
        'kOutlook',
        'kMailbox',
        'kUsers',
        'kGroups',
        'kTeams',
        'kSites',
        'kPublicFolders',
      ],

      // Expanded types within the job creation.
      // NOTE: The job type may change to incorporate OneDrive and Outlook.
      kO365Outlook: [
        'kDomain',
        'kOutlook',
        'kMailbox',
        'kUsers',
        'kGroups',
        'kTeams',
        'kSites',
        'kPublicFolders',
      ],
      kAD: [
        'kDomainController',
      ]
    };

    /**
     * Internal cache of selected Nodes.
     *
     * @type   {array}
     */
    var _selectedNodes = [];

    /**
     * Determines whether a node is leaf or not and if node children are not
     * loaded then don't consider it as leaf until there children are loaded
     * asynchronously.
     *
     * NOTE: This notion of leaf is strictly treeControl related to indicate a
     * terminal node. This should not be confused with node._isLeaf decoration.
     *
     * @method   isLeafNodeForAsyncMode
     * @param    {Object}    node   The node
     * @return   {boolean}   true if leaf node else false.
     */
    function isLeafNodeForAsyncMode(node) {
      var isLeafNode = (!node[$ctrl.treecontrolOptions.nodeChildren] ||
        node[$ctrl.treecontrolOptions.nodeChildren].length === 0);

      return node._areChildrenLoaded ? isLeafNode : false;
    }

    /**
     * Traverses through the tree and marks the already loaded nodes with
     * _areChildrenLoaded marker so that they are not async loaded again when
     * clicked upon.
     *
     * @method   markTreeNodesLoaded
     * @param    {Object}   tree  The source tree
     */
    function markTreeNodesLoaded(tree) {
      tree.forEach(node => {
        if (node._isLeaf || (node.nodes && node.nodes[0])) {
          node._areChildrenLoaded = true;
        }

        if (node.nodes && node.nodes[0]) {
          markTreeNodesLoaded(node.nodes);
        }
      });
    }

    /**
     * Asynchronously loads the provided sql node's children. Used only when
     * $ctrl.options.sqlAsyncMode is enabled and on expand/select asynchronously
     * load their children.
     *
     * @method   asyncSqlLoadNodeChildren
     * @param    {Object}   node            The node
     * @param    {boolean}  autoProtect     True if we want to autoprotect this
     *                                      node with newly loaded children
     * @param    {boolean}  selectThisNode  True if we want to select this node
     *                                      with newly loaded children
     * @param    {boolean}  isSilent        When false, present a modal for
     *                                      additional AAG options. When
     *                                      undefined, determine silence by
     *                                      presence of aag configs
     */
    function asyncSqlLoadNodeChildren(node, autoProtect, selectThisNode, isSilent) {
      var params = $ctrl.options.getSourcesParams;

      var options = {
        preventRootNodeSuppression: true,
        rootEnvironment: _.get(node, 'rootNode.environment'),

        // API response will show parent node as instances which would make the
        // host environment 'kSQL' but actual host environnment is 'kPhysical'
        // This option passed will override the response
        hostEnvironment: 'kPhysical'
      };

      // Early exit if node children have already been loaded.
      if ((node._areChildrenLoaded || node._isSqlHost) && !selectThisNode) {
        return $q.resolve();
      }

      node._loadingChildren = true;

      // Load the tree for the toggled node
      return PubSourceService
        .getSource(node.protectionSource.id, params, options)
        .then(function getSourceSuccess(sources) {

            node._areChildrenLoaded = true;

            // Decorate the protected/autoprotected node and its newly loaded children
            PubJobServiceFormatter.forEachNode(sources,
              function eachNode(childNode) {
                if (node._isSelected || node._isAutoProtected ||
                  node._isAncestorAutoProtected) {
                  childNode._isSelected = true;
                  if (childNode._isLeaf) {
                    childNode._inJob = true;
                    $ctrl.selectedObjectsCounts.kDatabase =
                      ++$ctrl.selectedObjectsCounts.kDatabase || 1;
                    childNode._isAncestorAutoProtected =
                      node._isAutoProtected || node._isAncestorAutoProtected;
                  }
                }
              }
            );

            // recursively merge current node with detailed node hierarchy.
            _.merge(node, sources[0]);

            // Select the node now with the newly loaded children
            if (selectThisNode) {
              selectNode(node, autoProtect, undefined, isSilent);
            }
          }, evalAJAX.errorMessage)
        .finally(function getSourceFinally() {
          node._loadingChildren = false;
        });
    }
    /**
     * Determines whether all current displayed physical servers are selected or not
     *
     * @method   areAllPhysicalServersSelected
     * @return   {boolean}   true if all displayed physical servers are selected.
     */
     function areAllPhysicalServersSelected() {
      // for particular filter, get the current view objects which are physical servers and are selectable
      const filteredPhysicalNodes = getFilteredPhysicalNodes();
      const selectedFilteredPhysicalNodes = filteredPhysicalNodes
      ?.filter(node=>node._isSelected);

      return filteredPhysicalNodes?.length === selectedFilteredPhysicalNodes?.length && filteredPhysicalNodes?.length !== 0;
    }

    /**
     * For particular filter, returns current view objects which are physical servers and are selectable
     *
     * @method   getFilteredPhysicalNodes
     * @return   {Object}   tree  The filtered tree
     */
     function getFilteredPhysicalNodes() {
      return $ctrl.tree?.filter(node =>
        treeFilterExpressionFn(node) && node._environment === 'kPhysical' &&
        node._canSelect.result);
     }

     /**
     * Determines whether there are any physical servers present
     *
     * @method   areAnyPhysicalServersPresent
     * @return   {boolean}   true if physical servers are present.
     */
    function areAnyPhysicalServersPresent() {
      return getFilteredPhysicalNodes()?.length !== 0;
    }

    /**
     * Makes Select All appear / disappear
     *
     * @method   setShowSelectAll
     *
     */
    function setShowSelectAll() {
      $ctrl.showSelectAll = areAnyPhysicalServersPresent();
    }

    /**
     * Toggles select All checkbox if all physicals servers are selected/deselected
     *
     * @method   toggleSelectAll
     *
     */
    $ctrl.toggleSelectAll = function toggleSelectAll(){
      $ctrl.selectAllPhysicalSourceCheckbox = areAllPhysicalServersSelected();
    };

    /**
     * Selects all filtered physical servers if select all option is ticked
     *
     * @method   selectAllPhysicalSources
     *
     */
    $ctrl.selectAllPhysicalSources = function selectAllPhysicalSources(){
      if($ctrl.selectAllPhysicalSourceCheckbox !== true)
      { // for particular filter, get the current view objects which are physical servers and are selectable
        // set them to checked
        const filteredPhysicalNodes = getFilteredPhysicalNodes();
        filteredPhysicalNodes?.forEach(physicalNode => physicalNode._isSelected = true);
      }
    };
    /**
     * Asynchronously loads the provided node's children.
     * Used only when $ctrl.options.asyncMode is enabled and on expand/select
     * asynchronously load there children.
     *
     * @method   asyncLoadNodeChildren
     * @param    {Object}   node   The node
     */
    function asyncLoadNodeChildren(node) {
      var params = $ctrl.options.getSourcesParams || {
        includeVMFolders: true,
        excludeTypes: ['kResourcePool'],
        excludeKubernetesTypes: FEATURE_FLAGS.excludeKubernetesTypes ? ['kService'] : undefined,
      };

      var options = {
        preventRootNodeSuppression: true,
        rootEnvironment: _.get(node, 'rootNode.environment'),
      };

      // Early exit if node children have already been loaded.
      if (node._areChildrenLoaded) {
        return $q.resolve();
      }

      node._loadingChildren = true;

      return PubSourceService
        .getSource(node.protectionSource.id, params, options)
        .then(function getSourceSuccess(sources) {
            // find current node in the response since getSource returns 2 nodes
            // for environment registered sources.
            var nodeInSource = _.find(sources, [
              'protectionSource.id',

              // TODO(veetesh): try removing need of rootNode check by
              // transforming tenant-sources-modal group node with 1st
              // registered root node.
              node.rootNode ? node.rootNode.id : node.protectionSource.id
            ]);

            node._areChildrenLoaded = true;

            // Mark selected ancestor node and also mark all descendants as
            // selected.
            PubJobServiceFormatter.forEachNode([nodeInSource],
              function eachNode(childrenNode) {
                if (node._selectedAncestorIdsMap &&
                  node._selectedAncestorIdsMap[
                    childrenNode.protectionSource.id]) {
                  childrenNode._isSelected = true;
                  childrenNode._selectedAncestor = true;

                  // Select all descendant nodes
                  PubJobServiceFormatter.forEachNode(childrenNode.nodes || [],
                    function eachNode(grandChildrenNode) {
                      grandChildrenNode._isSelected = true;
                    }
                  );
                }
              }
            );

            // recursively merge current node with detailed node hierarchy.
            _.merge(node, nodeInSource);
          }, evalAJAX.errorMessage)
        .finally(function getSourceFinally() {
          node._loadingChildren = false;
        });
    }

    /**
     * Lifecycle hook function to handle changes for one-way bindings.
     *
     * @method   $onChanges
     * @param    {object}   changesObj   The AngularJS provided changes object.
     */
    function $onChanges(changesObj) {
      if (changesObj.externalSearchString.currentValue) {
        _expandNodesOnExternalSearch();
      }
    }

    /**
     * Component lifecycle method used to update $ctrl.leaves when tree got
     * updated.
     *
     * @method   $doCheck
     */
    $ctrl.$doCheck = function $doCheck() {
      if (prevTree !== $ctrl.tree) {
        prevTree = $ctrl.tree;
        $ctrl.leaves = getLeaves($ctrl.tree);

        // update environment and other stuff related to tree
        initEnvironment();
        updateOSTypeFilter(false);
      }
    }

    /**
     * Filters and expands the externally searched nodes.
     *
     * @method   _expandNodesOnExternalSearch
     */
    function _expandNodesOnExternalSearch() {
      var searchString = $ctrl.externalSearchString.toLowerCase();
      var newExpandedNodesMap = {};

      // Expand nodes only if the search texts' length is more than 3.
      // Because for less than 3 characters, it expands each and every node
      // which causes a lot of delay. But the search happens for any length.
      if (searchString.length >= 3) {
        PubJobServiceFormatter.forEachNode($ctrl.tree,
          function eachNode(node, index, list, path) {
            if (_.includes(node._nameLowerCase, searchString)) {
              path.forEach(function forEachParentNode(parentNode) {
                newExpandedNodesMap[parentNode.protectionSource.id] =
                  parentNode;
              });
            }
          });

        // Expand the searched nodes using $ctrl.expandedNodes.
        $ctrl.filtering.active = true;
        $timeout(function digestTimeout() {
          $ctrl.expandedNodes = _.values(newExpandedNodesMap);
          $ctrl.allExpanded = true;
        }, digestDelayMs);
      }
    }

    /**
     * Gets the count of selected leaf entities for this given job environment.
     *
     * @method    getNumSelectedLeaves
     * @returns   {number}   The count found.
     */
    $ctrl.getNumSelectedLeaves = function getNumSelectedLeaves() {
      return PubJobService.getLeafCountByEnvironment(
        $ctrl.environment,
        $ctrl.selectedObjectsCounts
      );
    };

    /**
     * indicates if a particular volume is currently protected, or
     * configured to be protected if the job is saved as-is
     *
     * @param      {object}   node    The node
     * @param      {object}   volume  The volume
     * @return     {boolean}  true if protected, false if not
     */
    $ctrl.volumeIsProtected = function volumeIsProtected(node, volume) {
      var selectedParameters;
      var selectedParametersVolume;

      // for static display (not currently editing a job)
      // or if the Server isn't part of the current job, show
      // its protection status from other job(s)
      if ($ctrl.options.canSelect || !node._isSelected) {
        return node._isProtected && volume.isProtected;
      }

      selectedParameters =
        $ctrl.job._sourceSpecialParametersMap[node.protectionSource.id];

      if (selectedParameters) {
        if (selectedParameters.physicalSpecialParameters.volumeGuid) {
          selectedParametersVolume =
            selectedParameters.physicalSpecialParameters.volumeGuid
              .includes(volume.guid);
        } else {
          // all LVM volumes are protected by default and guid present only for
          // LVM volumes only.
          selectedParametersVolume = !!volume.guid;
        }
      }

      return node._isSelected && selectedParametersVolume;
    };

    /**
     * Apply filters if they exist else reset the expansion to default view
     */
    $ctrl.applyFilters = function applyFilters() {
      $ctrl.allNodesFiltered = true;

      if ($ctrl.treeView === 'flat') {
        $ctrl.filteredLeaves = $ctrl.leaves.filter(treeFilterExpressionFn);
        return;
      }

      const newExpandedNodes = [];
      let defaultExpandNodeTypes;
      switch ($ctrl.treeView) {
        case 'physical':
          defaultExpandNodeTypes = defaultExpandedTypes[$ctrl.tree[0]._environment];
          break;
        case 'folder':
          // Special list to prevent folders from being expanded.
          defaultExpandNodeTypes = ['kVCenter', 'kDataCenter'];
          break;
        case 'flat':
          break;
      }

      // Should expand till leaf nodes if one of these filters is active
      const shouldExpandAll = Boolean($ctrl.objectNameFilter) ||
        $ctrl.nodePropertyFilter.value !== 'all';

      if (!FEATURE_FLAGS.collapseSourceTreeEnabled &&
        (shouldExpandAll || $ctrl.options.autoExpandRootNodes)) {
        let firstNode = $ctrl.tree;
        if (!shouldExpandAll) {
          // We always expand the root node of the tree.
          newExpandedNodes.push($ctrl.tree[0]);
          firstNode = $ctrl.tree[0].nodes;
        }

        // if shouldExpandAll is true, expand every node
        // else expand using defaultExpandNodeTypes for current treeView
        firstNode.forEach(function expandTree(branch) {
          expandBranch(branch, newExpandedNodes,
              !shouldExpandAll && defaultExpandNodeTypes);
        });
        $ctrl.expandedNodes = newExpandedNodes;
      }
      $ctrl.allExpanded = Boolean(newExpandedNodes.length);
    };

    /**
     * handles any cleanup or updates needed based on a change
     * of the ctrl.treeView (physical/folder/flat). called on
     * ngChange of the buttons. Applies to Hypervisor sources.
     */
    $ctrl.changeTreeView = function changeTreeView(selectedView) {
      if ($ctrl.treeView === selectedView) {
        return;
      }

      $ctrl.filtering.active = true;

      $timeout(function switchIt() {
        $ctrl.treeView = selectedView;
        $ctrl.applyFilters();
      }, digestDelayMs);
    };

    /**
     * indicates if the current node should be skipped/filtered based
     * on the user's ctrl.treeView (physical/folder/flat) selection
     *
     * @param      {object}   node    The node
     * @return     {boolean}  True if wrong for view type, False otherwise.
     */
    function isWrongForViewType(node) {

      // this filtering only applies to VMware environments
      if (node._environment !== 'kVMware') {
        return false;
      }

      switch ($ctrl.treeView) {
        case 'physical':
          // filter out "vm" folders
          if (node._type === 'kFolder' &&
            node._envProtectionSource.folderType === 'kVMFolder' &&
            node.protectionSource.name === 'vm') {
            // user is in "physical" view and this is a
            // "vm" folder. Wrong for View type!
            return true;
          }
          break;
        case 'folder':
          if (node._type === 'kFolder' &&
            node._envProtectionSource.folderType === 'kHostFolder' &&
            node.protectionSource.name === 'host') {
            // user is in "folder" view and this is a
            // "host" folder. Wrong for View type!
            return true;
          }
          break;
      }

      return false;
    }

    /**
     * Traverse the cloud tree and filter all the unwanted nodes
     *
     * @param    {Object[]}   nodes   Entity hierarchy tree nodes
     * @return   {Object[]}   The filtered nodes
     */
    function pruneCloudTree(nodes) {
      var env = _.get(nodes, '[0]._environment');
      var leafType = _.get($ctrl.options, 'cloudLeafType[' + env + ']');

      if (!env || !ENV_GROUPS.cloudSources.includes(env)) {
        return nodes;
      }

      var filteredNodes = [];
      var nodesAllowedWithNoChildren = {
        'kGCP': ['kProject'],
      };

      nodes.forEach(function pruneNode(node) {
        // filter out unwanted azure types
        switch (node._environment) {
          case 'kAzure':
            if (!['kSubscription', 'kResourceGroup',
              'kVirtualMachine'].includes(node._type)) {
              return;
            }
            break;

          case 'kAWS':
            if (!leafType) {
              leafType = AwsLeafTypes;
            }

            // Tag type is also a leaf in AWS
            leafType.push('kTag');

            if (!_.union(['kIAMUser', 'kRegion', 'kAvailabilityZone',
              'kTag', 'kService'], leafType).includes(node._type)) {
              return;
            }
            break;

          case 'kGCP':
            if (!['kIAMUser', 'kProject', 'kRegion', 'kAvailabilityZone',
              'kVirtualMachine', 'kTag']
                .includes(node._type)) {
              return;
            }
            break;
        }

        // recurse for children nodes
        if (node.nodes && node.nodes.length) {
          node.nodes = pruneCloudTree(node.nodes);
        }

        // If only certain leaves are to be displayed and this node is not that
        if (node._isLeaf && leafType && !leafType.includes(node._type)) {
          return;
        }

        // accept a node if it is a valid leaf, or has valid children
        if (node._isLeaf || (node.nodes && node.nodes.length) ||
          (nodesAllowedWithNoChildren[env] || []).includes(node._type)) {

          filteredNodes.push(node);

          // set the numLeaves since some children are filtered out
          if (!node._isLeaf) {
            node._numLeaves = _findNumLeaves(node);
          }
        }
      });

      return filteredNodes;
    }

    /**
     * Returns the number of (filtered) leaf nodes for a given node

     * @param    {Object}   node   The node
     * @return   {Number}   The number of leaves
     */
    function _findNumLeaves(node) {
      return (node.nodes || []).reduce(function getSum(total, child) {
        if (child._type === 'kTag') {
          return total;
        }
        return total + (child._isLeaf ? 1 : child._numLeaves);
      }, 0);
    }

    /**
     * Should checkbox be shown in the tree for node selection.
     *
     * @method   showCheckbox
     * @param    {object}    node   The node in question.
     * @return   {Boolean}   True to show the checkbox. False otherwise.
     */
    $ctrl.showCheckbox = function showCheckbox(node) {
      if (!FEATURE_FLAGS.protectSqlFileBasedVmware &&
        node._environment === 'kSQL' &&
        node._hostEnvironment === 'kVMware') {
        return false;
      }

      // Only Active Directory application hosts can be individually selected.
      if (node._environment === 'kAD' && !node._isApplicationHost) {
        return false;
      }

      return $ctrl.options.canSelect && !$ctrl.options.singleSelect;
    };

    /**
     * Determine if at least one row is selectable in the currently filtered
     * view.
     *
     * @method     isAnythingSelectable
     * @return     {boolean}  true if at least one selectable row
     */
    $ctrl.isAnythingSelectable = function isAnythingSelectable() {
      if (!$ctrl.options.registeredSource) {
        return false;
      }

      // Only Physical servers have the possibility of being unselectable.
      // All other types always return true.
      if ($ctrl.options.detailedView &&
        $ctrl.options.registeredSource.type === 6) {

        return $ctrl.tree.some(function findSelectable(node) {
          // The node must be filtered in view and also
          // must be upgradable (upgradability === 0)
          return treeFilterExpressionFn(node) &&
            node._agent.upgradability === 'kUpgradable' &&
            !node._isUpgrading;
        });
      }

      return true;
    };

    /**
     * called on select/unselect all checkbox ngChange
     */
    $ctrl.selectUnselectAll = function selectUnselectAll() {
      var selectionFn = $ctrl.allSelected ? selectNode : unselectNode;
      $ctrl.tree.forEach(function loopRootNodes(node) {
        // intentional passthrough function so forEach doesn't send index and
        // array params to selectNode/unselectNode
        selectionFn(node);
      });
    };

    /**
     * Intercept selection of the given node and present a challenge of some
     * kind. Circumstantial details within.
     *
     * @method    _specialSelectionPromise
     * @param     {object}   node   The node to challenge selection of.
     * @return    {object}   Promise to resolve after possible challenge.
     */
    function _specialSelectionPromise(node) {
      switch (true) {
        case node._isSystemDb:
          return _explainSelectingAllSystemDbs(node);

        case node._rootEnvironment === 'kSQL' && node._failedHealthChecks:
          return _explainSelectingFailedHealthChecks(node);

        // SQL Host in the SQL Servers hierarchy only and has AAG data
        case node._rootEnvironment === 'kSQL' && !!_.get(node._aags, 'length'):
          return PubSourceService.showAagNodeSelectDialog(node);

        default:
          return $q.resolve(node);
      }
    }

    /**
     * Show a modal explaining what may happen when selecting a node to protect
     * that has failed health checks.
     *
     * @param     {Object}   node   The node being selected.
     * @returns   {Object}   Promise resolving with the user's choice (accepted
     *                       or rejected). True will proceed with selection.
     *                       False will abandon selecting this node.
     */
    function _explainSelectingFailedHealthChecks(node) {
      const data = {
        confirmButtonLabel: 'select',
        declineButtonLabel: 'cancel',
        copy: 'cSourceTreePub.healthCheckModal.copy',
        node: node,
        component: SourceHealthCheckMatrixComponent,
        componentInputs: {
          // TODO(spencer): Need to figure out how to handle the array here.
          checks: node.registrationInfo.registeredAppsInfo[0].hostSettingsCheckResults,
        },
      };

      if (node._isSelected) {
        return $q.resolve(true);
      }

      return new Promise((resolve, reject) => {
        ngDialogService.simpleDialog(null, data).subscribe(result => {
          if (!result) {
            return reject(result);
          }
          resolve(result);
        });
      });
    }

    /**
     *
     * @method   _explainSelectingAllSystemDbs
     * @param    {object}   node   The node being selected.
     * @return   {object}   Promise resolving or rejecting with the node to be
     *                      selected.

     *
     */
    function _explainSelectingAllSystemDbs(node) {
      var deferred = $q.defer();

      cModal.standardModal(undefined, {
        id: 'select-sibling-system-dbs-modal',
        contentKey: 'cSourceTreePub.selectDeselectAllSystemDbsModal',
        contentKeyContext: {node: node},
        closeButtonKey: false,
      })
      .then(deferred.resolve.bind(null, node))
      .catch(deferred.reject.bind(null, node));

      return deferred.promise;
    }

    /**
     * Select a node.
     *
     * @param   {object}    node                     Node to be selected.
     * @param   {boolean}   [autoProtect=false]      True if autoprotect is
     *                                               enabled for the branch.
     * @param   {boolean}   [tagAutoProtect=false]   True if this selection is
     *                                               via auto protection of
     *                                               tags.
     * @param   {boolean}   [isSilent=]              When false, present a modal
     *                                               for additional AAG options.
     *                                               When undefined, determine
     *                                               silence by presence of aag
     *                                               configs
     */
    function selectNode(node, autoProtect, tagAutoProtect, isSilent) {
      var isSystemDb = !!node._isSystemDb;
      var promise;

      // If isSilent is defined
      isSilent = isSilent !== undefined ?

        // Use the passed value
        isSilent :

        // Otherwise, set it true if this is not a System DB, no AAGs are
        // present, and it has no failed health checks (default behavior for
        // most nodes).
        !isSystemDb && !_.get(node, '_aags.length') && !node._failedHealthChecks;

      // Promise: When silent is true
      promise = isSilent ?
        // Resolve a simple promise
        $q.resolve(isSilent) :

        // When it's not, Pass it to the handler for special selection
        // circumstances.
        _specialSelectionPromise(node);

      promise.then(function promised(resp) {
        // The user selected all nodes. Select them.
        if (resp === 'aagAll') {
          return $ctrl.selectAllAagNodes(node);
        }

        // When the promise response is the same as the node we're selecting,
        // and it's a System DB, select all the other System DBs on this same
        // instance.
        if (isSystemDb && angular.equals(node, resp)) {
          return selectAllInstanceSystemDbs(node);
        }

        // In case of node selection, the global host type needs to be set
        // so that nodes with other host types can be disabled.
        $ctrl.selectedHostType = node._hostType;

        // Either silent was true, or the user opted to select the single node.
        PubJobServiceFormatter.selectNode(node, {
          autoProtect: !!autoProtect,
          ancestorAutoProtect: node._isAncestorAutoProtected,
          expandedNodes: $ctrl.expandedNodes,
          selectedObjectsCounts: $ctrl.selectedObjectsCounts,
          tagAutoProtect: !!tagAutoProtect,
          unexclude: node._isExcluded,

          // Send $ctrl.tree regardless of circumstances so dupes get updated
          // correctly. Put another way, do not send  $ctrl.leaves when in flat
          // view as dupes won't get updated correctly.
          tree: $ctrl.tree,
          treeFilterExpressionFn: treeFilterExpressionFn,
          detailedView: $ctrl.options.detailedView,
        }, $ctrl.canSelectNode);

        // Add this node to the cache of selected Nodes.
        _selectedNodes.push(node);
      }, function declined() {
        // If the user closes the dialog, unselect this node.
        unselectNode(node, undefined, undefined, node._isSystemDb);
      });
    }

    /**
     * unselect a node
     *
     * @param   {object}    node                     The node to unselect.
     * @param   {boolean}   [autoProtect=false]      indicates if we are
     *                                               recursing through an auto
     *                                               protected branch.
     * @param   {boolean}   [tagAutoProtect=false]   indicates if this selction
     *                                               is via auto protection of
     *                                               tags.
     * @param   {boolean}   [isSilent]               Set true to challenge
     *                                               deselection and prompt user
     *                                               to make a decision.
     */
    function unselectNode(node, autoProtect, tagAutoProtect, isSilent) {
      var promise;

      isSilent = isSilent !== undefined ? isSilent : !node._isSystemDb;

      promise = isSilent ?
        // If true, quickly resolve this promise unchallenged.
        $q.resolve([node]) :

        // But if false, present a challenge before selecting the node.
        _specialDeselectionPromise(node);

      // Always perform deselection after a promsie resolves. This allows us to
      // interrupt selection with a challenge model, or other mechanism before
      // proceeding.
      promise.then(function unselectInterceptorResolved(nodes) {
        _.forEach(nodes, function doDeselection(_node) {
          var thisNodeIndex = _selectedNodes.indexOf(_node);
          var $checkbox =
            angular.element('#node-checkbox-' + _node.protectionSource.id);
          var anySelectedNodesLeft;

          PubJobServiceFormatter.unselectNode(_node, {
            autoProtect: !!autoProtect,
            excluding: autoProtect && !_node._isAutoProtected,
            expandedNodes: $ctrl.expandedNodes,
            selectedObjectsCounts: $ctrl.selectedObjectsCounts,
            tagAutoProtect: !!tagAutoProtect,

            // Send $ctrl.tree regardless of circumstances so dupes get updated
            // correctly. Put another way, do not send  $ctrl.leaves when in flat
            // view as dupes won't get updated correctly.
            tree: $ctrl.tree,
            treeFilterExpressionFn: treeFilterExpressionFn,
          });

          // This little hack is necessary because the checkbox does not use
          // ngModel, but only ngChecked. So when intercepting a selection with
          // a challenge modal, cancelling does correctly set the
          // node._isSelected to false, but does not clear the DOM checked
          // attribute.
          $checkbox.prop('checked', node._isSelected);

          // Remove this node from the cache of selected Nodes.
          _selectedNodes.splice(thisNodeIndex, 1);

          // While unselecting the nodes, check for any selected nodes left, and
          // if there are no selected nodes left then global host type will be
          // undefined, unaltered otherwise. This loops only the first level, as
          // this is only leverage for Physical, which is a flat structure.
          anySelectedNodesLeft = $ctrl.tree.some(function loopRootNodes(node) {
            return node._isSelected;
          });

          if (!anySelectedNodesLeft) {
            $ctrl.selectedHostType = undefined;
          }
        });

        $ctrl.allSelected = false;
      });
    }

    /**
     * For the given node, present a challenge of some kind before resolving
     * proceeding wiht deselection.
     *
     * @method    _specialDeselectionPromise
     * @param    {object}   node   The node being deselected.
     * @return   {object}   A promise resolving with the selected, or a set of
     *                      selected nodes to deselect.
     */
    function _specialDeselectionPromise(node) {
      if (!node._isSystemDb) {
        // The default for simple node selection is a single item array of the
        // node passed in.
        return $q.resolve([node]);
      }

      // But if it is a system db, then we need to locate the other selected
      // system db nodes and return them for simultaneous deselection.
      return $q.resolve(
        _selectedNodes.filter(function(otherNode) {
          // If this core check fails, exit early and avoid calculating the rest
          // of this: it's not compatible.
          if (!otherNode._isSystemDb) { return; }

          return PubSourceServiceUtil.isSameSqlInstance(node, otherNode);
        })
      );
    }

    /**
     * click checkbox
     *
     * @param   {Object}    node                  clicked node
     * @param   {Object}    [e]                   event object
     * @param   {Boolean}   [autoProtect=false]   indicates if auto protect is
     *                                            active for the provided node
     */
    $ctrl.clickCheckbox = function clickCheckbox(node, e, autoProtect) {
      var selectSilently = !e || undefined;

      // stopping propagation prevents node open/close from occurring
      if (e) {
        e.stopPropagation();
      }

      // if a node has been excluded via an ancestor or tag,
      // no direct action on the node is allowed
      if (node._isAncestorExcluded || node._isTagExcluded) {
        return;
      }

      // When `autoProtect` is defined,
      autoProtect = !!autoProtect;

      if (node._isAutoProtected) {
        $ctrl.toggleAutoProtect(node);
      } else if (node._isSelected) {
        unselectNode(node, autoProtect);
      } else if ($ctrl.options.sqlAsyncMode && !node._isSqlHost && !node._areChildrenLoaded) {
        $ctrl.onNodeToggle(node, autoProtect, true, selectSilently);
      } else {
        selectNode(node, autoProtect, undefined, selectSilently);
      }
    };

    /**
     * select one node
     *
     * @method   selectOneNode
     * @param    {Object}    newNode         The node
     * @param    {Object}    [event]         DOM event
     */
    $ctrl.selectOneNode = function selectOneNode(newNode, event) {
      // stopping propagation prevents node open/close from occurring
      if (event) {
        event.stopPropagation();
      }

      // unselect all Node then select current node
      if ($ctrl.options.singleSelectNode) {
        unselectNode($ctrl.options.singleSelectNode);
        $ctrl.options.singleSelectNode = undefined;
      }

      // select current node if it was not selected before because
      // selectOneNode() method is used to select and unselect a node.
      if (!newNode._isSelected) {
        selectNode(newNode);
        $ctrl.options.singleSelectNode = newNode;
      }
    };

    /**
     * Handle click of auto protect icon.
     *
     * @param   {Object}   node   clicked node
     * @param   {Object}   [e]    event object
     */
    $ctrl.toggleAutoProtect = function toggleAutoProtect(node, e) {
      // stopping propagation prevents node open/close from occuring
      if (e) {
        e.stopPropagation();
      }

      // Exit early if explicitly can not auto-protect, or
      if (!node._canAutoProtect ||
        // Is SQL partially protected by another Job.
        (node._rootEnvironment === 'kSQL' &&
          node._isPartiallyProtected &&
          !node._inJobDescendants.length)) {
        return;
      }

      if (!node._isAutoProtected) {
        if ($ctrl.options.sqlAsyncMode && !node._isSqlHost && !node._areChildrenLoaded) {
          $ctrl.onNodeToggle(node, true, true);
        } else {
          selectNode(node, true);
        }
      } else {
        unselectNode(node, true);
      }
    };

    /**
     * expand all tree nodes till selected ancestor's nodes.
     *
     * @method   expandTillSelectedAncestor
     */
    function expandTillSelectedAncestor() {
      var newExpandedNodes = [];

      $ctrl.filtering.active = true;

      // timeout allows a slot in the digest loop for filtering spinner to be
      // displayed in the UI before expandedNodes[] gets replaced
      $timeout(function boom() {
        // collect all nodes to be expand
        PubJobServiceFormatter.forEachNode($ctrl.tree,
          function eachNode(node, index, list, path) {
            // if node is selected then expand there parent nodes.
            if (node._selectedAncestor) {
              path.forEach(function eachParentNode(parentNode) {
                // keep duplicate nodes out from final selected expanded node.
                if (!newExpandedNodes.includes(parentNode)) {
                  newExpandedNodes.push(parentNode);
                }
              });
            }
          }
        );

        $ctrl.expandedNodes = newExpandedNodes;
        $ctrl.allExpanded = true;

      }, digestDelayMs);
    }

    /**
     * expand all tree nodes
     */
    $ctrl.expandAll = function expandAll() {

      var newExpandedNodes = [];

      $ctrl.filtering.active = true;

      // timeout allows a slot in the digest loop for filtering spinner to be
      // displayed in the UI before expandedNodes[] gets replaced
      $timeout(function boom() {

        $ctrl.tree.forEach(function expandTree(branch) {
          expandBranch(branch, newExpandedNodes);
        });

        $ctrl.expandedNodes = newExpandedNodes;
        $ctrl.allExpanded = true;

      }, digestDelayMs);

    };

    /**
     * full expands a branch in the tree by recursively adding it and its
     * children to the expanded nodes list
     *
     * @param      {Object}   node            to be expanded
     * @param      {Array}    expNodes        list of expanded nodes
     * @param      {Array}    [expNodeTypes]  list of integer node types to be
     *                                        expanded, if not provided, any/all
     *                                        node types will be expanded
     * @param      {Boolean}  [seekToType]    indicates if the expNodeTypes[]
     *                                        should be expanded based on
     *                                        _descendantTypes, in which case
     *                                        the expNodeTypes are not expanded
     *                                        themselves, but expansion ensures
     *                                        they are displayed.
     *
     */
    function expandBranch(node, expNodes, expNodeTypes, seekToType) {

      var canExpandNodeType;

      expNodes = expNodes || [];
      seekToType = !!seekToType;

      canExpandNodeType =
        !expNodeTypes ||
        expNodeTypes.includes(node._type);

      if (seekToType) {
        canExpandNodeType = expNodeTypes.some(function findType(nodeType) {
          return node._descendantTypes &&
            node._descendantTypes.includes(nodeType);
        });
      }

      // only expand nodes that have children and pass the filters
      if (canExpandNodeType &&
        Array.isArray(node.nodes) &&
        treeFilterExpressionFn(node)) {

        expNodes.push(node);

        // recurse through children to expand them if necessary
        node.nodes.forEach(function expandChild(child) {
          expandBranch(child, expNodes, expNodeTypes, seekToType);
        });

      }

    }

    /**
     * collapse all tree nodes
     *
     */
    $ctrl.collapseAll = function collapseAll() {
      $ctrl.filtering.active = true;

      $ctrl.expandedNodes.length = 0;

      $ctrl.allExpanded = false;
    };

    /**
     * Sets the removal link id to the node id provided, so the removal option
     * will be shown. This is for Physical Servers, as they can only be
     * protected by a single Protection Job. This exposes the option to remove
     * the already protected Server from any existing Job so it can be added to
     * the current Job.
     *
     * @method   updateViewRemovalLinkId
     * @param    {integer}   nodeId     The node identifier
     * @param    {object}    [$event]   The checkbox click event
     */
    $ctrl.updateViewRemovalLinkId = function updateViewRemovalLinkId(nodeId,
      $event) {
      if ($event) {
        // Don't check the checkbox. The node isn't part of the Job yet.
        $event.preventDefault();
      }

      // This value exposes the removal option in the template, and does it
      // based on ID so that the message will only be visible for a single
      // node at a time.
      $ctrl.viewRemovalLinkId = nodeId;
    };

    /**
     * presents user with modal to remove Physical Server
     * from existing Job before adding to the current Jbo
     *
     * @param      {object}  node    The node to be removed and added
     */
    $ctrl.removeAndAdd = function removeAndAdd(node) {
      JobService.removeFromExistingJob(node).then(
        function removedFromJob(confirmation) {
          node._isBlockProtected = false;
          node._isProtected = false;
          node._isSqlProtected = false;
          $ctrl.viewRemovalLinkId = undefined;
          selectNode(node);
        }
      );
    };

    /**
     * submits a request to refresh source nodes hierarchy sync from external
     * source
     *
     * @method     refreshPhysicalServer
     * @param      {object}   node         The node/server to be refreshed
     */
    $ctrl.refreshPhysicalServer = function refreshPhysicalServer(node) {
      PubSourceService.refreshSource(node.protectionSource.id)
        .catch(evalAJAX.errorMessage);
    };

    /**
     * Shows the upgrade button.
     *
     * @method   showUgradeButton
     * @return   {Boolean}   Show upgrade button for Physical and hyperV
     *                       entities
     */
    $ctrl.showUpgradeButton = function showUpgradeButton() {
      switch($ctrl.environment) {
        case 'kPhysical':
          return $ctrl.options.detailedView && $ctrl.getNumSelectedLeaves();

        case 'kHyperV':
        case 'kHyperVVSS':
          return $ctrl.options.detailedView;

        default:
          return false;
      }
    };

    /**
     * Initiate agent upgrade
     *
     * TODO(veetesh): update upgradeAgents to use public SourceService API
     * skipped while porting new job flow since agents upgrade can only happen
     * in detailed view
     *
     * @method     upgradeAgent
     * @param      {object}  [node]    The node from contextual menu
     */
    $ctrl.upgradeAgents = function upgradeAgents(node) {
      var agentIds = [];
      var names = [];
      // VCS source is a linux cluster. We need to warn user that vcs upgrade
      // will not upgrade all nodes of the cluster.
      var hasVcsSource = false;
      var nodes = [];

      if (!node) {
        // Invoked via checkbox selections and then upgrade button
        $ctrl.tree.forEach(function nodesForEach(thisNode) {
          if (thisNode._isSelected) {
            if (thisNode._isSqlCluster) {
              /**
               * SQL cluster. Iterate through all agents in the cluster and
               * create a batch of agents to upgrade.
               */
              thisNode.entity.physicalEntity.agentStatusVec.forEach(
                function queueUpgradableAgents(agent) {
                  if (agent.upgradability === 'kUpgradable' &&
                    !agentIds.includes(agent.id)) {

                    agentIds.push(agent.id);
                    names.push(agent.name);
                    thisNode._agent = agent.properties;
                  }
                }
              )
              node = thisNode;
            } else {
              agentIds.push(thisNode._envProtectionSource.agents[0].id);
              names.push(thisNode.protectionSource.name);
              node = thisNode;
            }
            hasVcsSource = hasVcsSource || thisNode._isVcsCluster;
          }
        });

        // Traverse the tree to capture the agentIds/names of valid hyperV nodes
        if (ENV_GROUPS.hyperv.includes($ctrl.environment)) {
          node = $ctrl.tree[0];
          setAgentIdsToUpgrade(node, agentIds, names, nodes);
          node = nodes.length > 0 ? nodes[0] : $ctrl.tree[0];
        }

      } else {
        switch(true) {
          case !!node._isSqlCluster:
            // SQL cluster. Iterate through all agents in the cluster and create
            //  a batch of agents to upgrade.
            node.protectionSource.physicalProtectionSource.agents.forEach(
              function queueUpgradableAgents(agent) {
                if (agent.upgradability === 'kUpgradable' &&
                  !agentIds.includes(agent.id)) {
                  agentIds.push(agent.id);
                }
              }
            );
            names.push(node.protectionSource.displayName);
            hasVcsSource = node._isVcsCluster;
            break;

          case !!node.protectionSource.physicalProtectionSource:
            // Single physical node. Function invoked via contextual menu
            agentIds.push(
              node.protectionSource.physicalProtectionSource.agents[0].id);
            names.push(node.protectionSource.displayName);
            hasVcsSource = node._isVcsCluster;
            break;

          case !!node.protectionSource.hypervProtectionSource:
            agentIds.push(
              node.protectionSource.hypervProtectionSource.agents[0].id);
            names.push(node.protectionSource.displayName || node.protectionSource.name);
            break;
        }
      }

      SourcesUtil.upgradeAgent({
        agentIds: agentIds,
        names: names,
        oldVersion: getOldVersion(node),
        isMulipleAgentsUpgrade: node._isSqlCluster || agentIds.length > 1,
        hasVcsSource: hasVcsSource
      });
    };

    /**
     * Gets the old upgrade version of node.
     *
     * @method   getOldVersion
     * @param    {object}   node   The node
     * @return   {string}   The old version.
     */
    function getOldVersion(node) {
      if (ENV_GROUPS.usesAgent.includes(node._environment) &&
        node._envProtectionSource.agents.length) {
        return node._envProtectionSource.agents[0].version;
      }
      return '';
    }

    /**
     * Traverse the tree and sets the agent identifiers to upgrade agents on
     * hyperV hosts.
     *
     * @method   setAgentIdsToUpgrade
     * @param    {object}   node       The node to be checked if it's avaliable for upgrading
     * @param    {Array}    agentIds   The agent identifiers
     * @param    {Array}    names      The names
     * @param    {Array}    nodes      Array of nodes that is avaliable for upgrading
     */
    function setAgentIdsToUpgrade(node, agentIds, names, nodes) {
      if (!node) { return; }

      // Upgrade agent action is available only to hyperV host and SCVMM server.
      if (SOURCE_TYPE_GROUPS.hypervHosts.includes(node._type) &&
        node._agent._isUpgradable) {

        node._envProtectionSource.agents.forEach(function addAgentIds(agent) {
          agentIds.push(agent.id);
        });
        names.push(node.protectionSource.name);
        nodes.push(node);
      }

      if (node.nodes) {
        // Recurse through children to get agentsIds
        node.nodes.forEach(function setAgentIds(child) {
          setAgentIdsToUpgrade(child, agentIds, names, nodes);
        });
      }
    }

    /**
     * presents challenge modal before deregistering/deleting a physical server
     *
     * @method     unregisterPhysicalServer
     * @param      {object}  node    The node to be deleted
     */
    function unregisterPhysicalServer(node) {
      PubSourceService.deleteSourceModal(node).then(
        function unregisterSourceSuccess(response) {
          cMessage.success({
            textKey: 'sourceTreePub.unregisterServerSuccess',
          });

          $state.go($state.current, {}, {
            reload: true
          });
        }
      );
    }

    /**
     * Retries application registration
     *
     * @method   retryRegistration
     * @param    {object}   node   The source node
     */
    $ctrl.retryRegistration = function retryRegistration(node) {
      node.retryEntityRegistration = true;

      // Clear the registration error so it can be triggered a new if necessary
      node._isApplicationRegError = false;

      PubSourceService.retrySourceRegistration(node).then(
        function retryRegSuccess() {
          cMessage.success({
            textKey: 'sources.retryRegistration.success',
            textKeyContext: node,
          });
        },
        evalAJAX.errorMessage
      ).finally(function retryRegFinally() {
        $state.go($state.current, {}, { reload: true });
      });
    };

    /**
     * Provides the auto protect checkbox tooltip based on the node
     *
     * @method   getAutoProtectTooltipKey
     * @param    {object}   node   The node
     * @return   {string}   The Auto Protect checkbox tooltip key
     */
    $ctrl.getAutoProtectTooltipKey = function getAutoProtectTooltipKey(node) {

      var textKey;

      switch (true) {
        case !$ctrl.job._supportsAutoProtectExclusion &&
          (node._isAncestorAutoProtected || node._isAutoProtected):
          textKey = 'autoProtectOnOnly';
          break;
        case node._isAutoProtected:
          textKey = 'autoProtectOn';
          break;
        case node._isTagExcluded:
          textKey = 'tagExcluded';
          break;
        case node._isExcluded:
          textKey = 'beingExcluded';
          break;
        case node._isAncestorExcluded:
          textKey = 'ancestorExcluded';
          break;
        case node._isAncestorAutoProtected && node._isTagAutoProtected:
          textKey = 'doubleAutoProtection';
          break;
        case node._isTagAutoProtected:
          textKey = 'tagProtected';
          break;
        case node._isSelected:
          textKey = 'beingAutoProtected';
          break;
      }

      return 'sourceTreePub.tooltips.' + textKey;

    };

    /**
     * initialize Node Property Filters
     *
     * @method   initializeNodePropertyFilters
     */
    function initializeNodePropertyFilters() {
      var quiesceFilterOpt;

      /** @type {Array} protected status filters */
      $ctrl.nodePropertyFilters = [{
        name: $ctrl.text.filterText.all,
        value: 'all',
      }, {
        name: $ctrl.text.filterText.unprotected,
        value: 'unprotected',
        icon: 'forbidden',
      }, {
        name: $ctrl.text.filterText.protectd,
        value: 'protected',
        icon: 'protected',
      }];

      // if forced VMtools filtering is not enabled and the tree is a VMware
      // environment add the VMWare tools / quiesce filter
      if (!$ctrl.options.vmwareToolsForcedFiltering && $ctrl.tree.length &&
        $ctrl.environment === 'kVMware') {

        quiesceFilterOpt = {
          name: $ctrl.text.filterText.vmwareToolsFound,
          value: 'quiesce',
          icon: 'app-aware',
        };

        $ctrl.nodePropertyFilters.push(quiesceFilterOpt);

        if ($ctrl.options.vmwareToolsRequired) {
          // start with VMtools filter enabled
          $ctrl.nodePropertyFilter = quiesceFilterOpt;
        }
      }

      // Show the SQL filter option if supported by the source type
      if ($ctrl.tree[0] &&
        ENV_GROUPS.sqlHosts.includes($ctrl.environment)) {
        $ctrl.nodePropertyFilters.push({
          name: $ctrl.text.filterText.sql,
          value: 'sql',
          icon: 'type-sql',
        });
      }

      // if this is the job flow in edit mode, add the option to filter by
      // "In Job" nodes.
      if ($ctrl.job && $ctrl.job.id) {
        $ctrl.nodePropertyFilters.push({
          name: $ctrl.text.filterText.job,
          value: 'job',
          selected: false,
          icon: 'protected',
        });
      }

      // if NAS, add two additional options
      if ($ctrl.tree[0] &&
        ENV_GROUPS.nas.includes($ctrl.tree[0]._environment)) {
        $ctrl.nodePropertyFilters.push(
          {
            name: $ctrl.text.filterText.nfs[$ctrl.tree[0]._environment],
            value: 'nfs',
            icon: 'nfs',
          },
          {
            name: $ctrl.text.filterText.smb[$ctrl.tree[0]._environment],
            value: 'smb',
            icon: 'smb',
          },
        );

        if ($ctrl.tree[0]._environment === 'kNetapp') {
          $ctrl.nodePropertyFilters.push(
            {
              name: $ctrl.text.filterText.readWriteVolumes,
              value: 'readWrite',
              icon: 'volume',
            },
            {
              name: $ctrl.text.filterText.dataProtectVolumes,
              value: 'dataProtect',
              icon: 'volume',
            },
          );
        }
      }

      if (FEATURE_FLAGS.enableNgtFilter &&
        $ctrl.tree[0] && $ctrl.tree[0]._environment === 'kAcropolis') {
        $ctrl.nodePropertyFilters.push({
          name: $ctrl.text.filterText.ngt,
          value: 'ngt',
          icon: 'protected',
        });
      }

      // If user provides a default filter set it
      // else set 'Show all' as default filter
      if ($ctrl.options.defaultNodePropertyFilter) {
        var selectedFilter = $ctrl.options.defaultNodePropertyFilter;

        // 1.If the given value is a string/number check the filters list to
        //   fetch filter object.
        // 2.If object just assign it directly.
        if (_.isString(selectedFilter) || _.isNumber(selectedFilter)) {
          $ctrl.nodePropertyFilter = _.filter($ctrl.nodePropertyFilters,
            function getFilter(filter) {
              return filter.value === $ctrl.options.defaultNodePropertyFilter;
            })[0];
        } else if (_.isObject(selectedFilter)) {
          $ctrl.nodePropertyFilter = selectedFilter;
        }
      } else {
        $ctrl.nodePropertyFilter = $ctrl.nodePropertyFilters[0];
      }

      if ($ctrl.tree.length && $ctrl.environment === 'kVMware') {
        $ctrl.nodePropertyFilters.push({
          name: $translate.instant('cohesityAgentInstalled'),
          value: 'agentInstalled',
          icon: 'cohesity-agent-installed',
        });

        $ctrl.nodePropertyFilters.push({
          name: $translate.instant('cohesityAgentNotInstalled'),
          value: 'agentNotInstalled',
          icon: 'cohesity-agent-not-installed',
        });
      }
    }

    /**
     * update the tree nodes as per single selected node option.
     * un-select the nodes which are not in singleSelectNode options else select
     * the node and update the singleSelectNode options with correct tree ref.
     *
     * @method   prepareTreeForSingleSelection
     */
    function prepareTreeForSingleSelection() {
      PubJobServiceFormatter.forEachNode(
        $ctrl.tree,
        function eachNode(node){
          if ($ctrl.options.singleSelectNode &&
            node.protectionSource.id ===
            $ctrl.options.singleSelectNode.protectionSource.id) {
            selectNode(node);
            angular.extend($ctrl.options.singleSelectNode, node);
          }
        }
      );
    }

    /**
     * Callback function for protected status filter uiSelect. Sets the filter
     * value and lets treecontrol handle filtering using treeFilterExpressionFn.
     *
     * @method   filterByNodeProperty
     * @param    {Object}   selectedFilter   to apply
     */
    $ctrl.filterByNodeProperty = function filterByNodeProperty(selectedFilter) {
      $ctrl.filtering.active = true;
      // Check if any physical servers are present to manipulate Select All Checkbox
      setShowSelectAll();
      // select all checkbox for physical server needs to be toggled after applying filters
      $ctrl.toggleSelectAll();
      // run the remainder of this code in a timeout block to give
      // the filtering.active spinner a brief moment to display before
      // filtering processing happens.
      $timeout(function filterByNodePropertyTimeout() {
        $ctrl.applyFilters();
      }, digestDelayMs);
    };

    /**
     * Callback function for entityType filter.
     *
     * @method   expandTreeToType
     * @param    {array}   expandToTypes   The entity types to expand
     */
    $ctrl.expandTreeToType = function expandTreeToType(expandToTypes) {

      var newExpandedNodes = [];

      if ($ctrl.tree.length) {
        $ctrl.filtering.active = true;
      }

      // run the remaineder of this code in a timeout block to give
      // the activelyFiltering spinner a brief moment to display before
      // filtering processing happens.
      $timeout(function filterTreeByEntityTypeTimeout() {

        $ctrl.tree.forEach(function expandTree(branch) {
          expandBranch(branch, newExpandedNodes, expandToTypes, true);
        });

        $ctrl.expandedNodes = newExpandedNodes;

        // unmark the selection, as this is an instant operation and not an
        // applied filter
        $ctrl.selectedExpandToType = undefined;

      }, digestDelayMs);
    };

    /**
     * sets OS type filtering into motion based on ng-change
     *
     * @param      {Array}  newFilterValue  array of OS types to display,
     *                                      empty array to display any/all
     */
    $ctrl.filterByOS = function filterByOS(newFilterValue) {

      $ctrl.filtering.active = true;

      // set all nodes filtered to true until filtering fn proves otherwise
      $ctrl.allNodesFiltered = true;

      osTypeFilter = newFilterValue.length ? newFilterValue : [];
      // Check if any physical servers are present to manipulate Select All Checkbox
      setShowSelectAll();
      // select all checkbox for physical server needs to be toggled after applying filters
      $ctrl.toggleSelectAll();

    };

    /** @type {String} string based filtering for comparing to entity name */
    $ctrl.objectNameFilter = '';

    /**
     * on objectNameFilter change, this function is fired to
     * update the allNodesFiltered value until the filtering function
     * proves otherwise, this allows for 'all nodes filtered messaging'
     */
    $ctrl.objectNameFilterValueChange = function objectNameFilterValueChange() {
      $ctrl.filtering.active = true;
      $ctrl.objectNameFilter = $ctrl.objectNameFilter.toLowerCase();
      // Check if any physical servers are present to manipulate Select All Checkbox
      setShowSelectAll();
      // select all checkbox for physical server needs to be toggled after applying filters
      $ctrl.toggleSelectAll();

      // timeout block gives the spinner a brief moment to display in the UI
      $timeout(function objectNameFilterValueChangeTimeout() {
        $ctrl.applyFilters();
      }, digestDelayMs);
    };

    /**
     * filter function for treecontrol, accounts for protectionStatus filter or
     * entityType filtering, and/or text based filtering.
     *
     * This function could also be used (but currently isn't) to limit recursive
     * select/unselect of nodes based on visibility
     *
     * @param  {Object} node  to be evaluated for filtering
     * @return {Boolean}      indicates if the node should be displayed
     */
    function treeFilterExpressionFn(node) {
      // all filter checks must pass in order for the entity to be displayed
      // start them all in the positive and only set negative value if the
      // particular filter is being applied
      var objNameFilterMatch = true;
      var nodePropertyFilterMatch = true;
      var osTypeFilterMatch = true;
      var tagFilterMatch = true;
      var nasEnvFSMap = {};
      var objectNameFilter = $ctrl.objectNameFilter ||
        $ctrl.externalSearchString;

      // if VMware tools filtering is forced (typically for restore file to
      // server operation) and this is a leaf node without VM Tools support, it
      // should be filter it out.
      var forcedVmToolsFilteringMatch =
        !($ctrl.options.vmwareToolsForcedFiltering &&
        node._isHypervisor &&
        node._isLeaf && !node._isQuiesceCompatible);

      var acropolisProtectionSource;

      // if a promise to disable activelyFiltering exists,
      // cancel it because filtering is still in progress
      if (filteringTimeoutPromise) {
        $timeout.cancel(filteringTimeoutPromise);
      }

      // if actively filtering, setup a promise to toggle
      // off ctrl.filtering.active when digest loop ends
      if ($ctrl.filtering.active) {
        filteringTimeoutPromise = $timeout(function filteringTimeoutFn() {
          $ctrl.filtering.active = false;
        }, digestDelayMs);
      }

      // Filter out AIX and Solaris hosts for block-based Physical Jobs.
      if ($ctrl.job && $ctrl.job.environment === 'kPhysical' &&
        ['kAix', 'kSolaris'].includes(node._hostType)) {
        return false;
      }

      // In vCloudDirector hierarchy, vCenters are put as folders and should
      // be hidden in UI
      if (node._rootSourceType === 'kvCloudDirector' &&
        node._type === 'kFolder') {
        return false;
      }

      // Never display VMware tag category branches or our derived tag branches.
      // NOTE: If showing auto protection / exclusion of tags in the tree is
      // desired, removing kTag from this evaluation will reveal those branches,
      // but some overly complex logic to interact with this branches will need
      // to be figured out.
      if (['kTagCategory', 'kTag', 'kCustomProperty'].includes(node._type)) {
        return false;
      }

      // filter out this node/branch if its incompatible with
      // the user's chosen view type (physical/folder)
      if (isWrongForViewType(node)) {
        return false;
      }

      // if user has typed into the string based filter, we need
      // to test for matches based on user input
      if (objectNameFilter) {

        // start by checking if the current node includes the filter string
        objNameFilterMatch = node._nameLowerCase.includes(objectNameFilter);

        // if the name doesn't match and its not a leaf, we need
        // to also check for any matches within children names
        if (!objNameFilterMatch && !node._isLeaf && node._descendantNames) {

          // if any descendants have a name that includes the search string this
          // entity should be displayed so its matching descendants will also be
          // displayed
          for (var x = 0; x < node._descendantNames.length; x++) {
            if (node._descendantNames[x].includes(objectNameFilter)) {
              objNameFilterMatch = true;
              break;
            }
          }
        }
      }

      // if there is a node property filter selected we need to determine if
      // this node is in agreement with it
      if ($ctrl.nodePropertyFilter) {
        // assume this entity will fail filter until we know otherwise
        nodePropertyFilterMatch = false;

        switch ($ctrl.nodePropertyFilter.value) {
          case 'all':
            nodePropertyFilterMatch = true;
            break;
          case 'job':
            nodePropertyFilterMatch = node._inJob ||
              !!node._inJobDescendants.length;
            break;
          case 'unprotected':
            nodePropertyFilterMatch =
              (!node._isProtected && !node._isSqlProtected) ||
              !!node._unprotectedDescendants.length;
            break;
          case 'protected':
            nodePropertyFilterMatch =
              (node._isProtected || node._isSqlProtected) ||
              !!node._protectedDescendants.length;
            break;
          case 'quiesce':
            nodePropertyFilterMatch = node._isQuiesceCompatible ||
              !!node._quiesceDescendants.length;
            break;
          case 'sql':
            nodePropertyFilterMatch = node._isSqlHost ||
              !!node._sqlHostDescendants.length;
            break;
          case 'readWrite':
            nodePropertyFilterMatch = node._volumeType === 'kReadWrite' ||
              !!node._readWriteDescendants.length;
            break;
          case 'dataProtect':
            nodePropertyFilterMatch = node._volumeType === 'kDataProtection' ||
              !!node._dataProtectDescendants.length;
            break;
          case 'smb':
          case 'nfs':
            nasEnvFSMap = (NAS_FILESYSTEM_MAP[$ctrl.environment] || {})[
              $ctrl.nodePropertyFilter.value
            ];

            nodePropertyFilterMatch =
              !!_.intersection(
                node._dataProtocols.map(cUtils.getDataProtocolFromKValue),
                nasEnvFSMap.map(cUtils.getDataProtocolFromKValue)
              ).length ||
              !!_.intersection(
                node._descendantDataProtocols.map(
                  cUtils.getDataProtocolFromKValue),
                nasEnvFSMap.map(cUtils.getDataProtocolFromKValue)
              ).length;
            break;
          case 'rds':
            if (node._envProtectionSource.type === 'kRDSInstance') {
              nodePropertyFilterMatch =
                (_.get(node, '_envProtectionSource.dbEngineId') || '')
                  .includes($ctrl.nodePropertyFilter.db.toLowerCase());
            } else {
              nodePropertyFilterMatch = true;
            }
            break;
          case 'agentInstalled':
            nodePropertyFilterMatch = node._isAgentInstalled ||
              !!node._agentInstalledDescendants.length;
            break;
          case 'agentNotInstalled':
            nodePropertyFilterMatch = node._isAgentNotInstalled ||
              !!node._agentNotInstalledDescendants.length;
            break;
          case 'ngt':
            if (!!node.nodes.length) {
              nodePropertyFilterMatch = true;
            } else {
              acropolisProtectionSource = node.protectionSource.acropolisProtectionSource;
              nodePropertyFilterMatch = acropolisProtectionSource.ngtEnableStatus === 'kEnabled' &&
                acropolisProtectionSource.ngtInstallStatus === 'kInstalled';
            }
            break;
          default:
            nodePropertyFilterMatch = true;
        }
      }

      // Filter nodes based on the host type / os type.
      // If the hostType doesn't match, we need
      // to also check for any matches within children hostTypes.
      if (osTypeFilter.length) {
        osTypeFilterMatch =
          osTypeFilter.includes(node._envProtectionSource.hostType) ||
            !!_.intersection(osTypeFilter, node._descendantHostTypes).length;
      }

      tagFilterMatch = !$ctrl.activeTags.length || PubJobServiceFormatter.isTagMatch(node, $ctrl.activeTags);

      if (objNameFilterMatch && nodePropertyFilterMatch &&  osTypeFilterMatch &&
        tagFilterMatch && forcedVmToolsFilteringMatch) {

        $ctrl.allNodesFiltered = false;

        // all filters passed,
        // update the boolean and return true to display this entity
        return true;

      }

      // one of filters didn't pass, filter out this node
      return false;

    }

    /**
     * Triggers the appropriate update SQL registration action based on the
     * given Node. Can either modify or unregister depending on the `type`
     * param.
     * TODO(new-job-flow): update this to public API (port service functions?)
     *
     * @method    updateSqlRegistration
     * @param     {object}   node     The Node object.
     * @param     {string}   [type]   One of 'update' or 'unregister'.
     *                                Default = 'update'
     */
    $ctrl.updateSqlRegistration = function updateSqlRegistration(node, type) {
      var host = PubSourceService.getRegisterableHostObject(node,
        ENV_TYPE_CONVERSION.kSQL);
      var isUnregister;
      var serviceMethodFn;
      var stateName;
      var stateParams;

      type = type || 'update';
      isUnregister = type === 'unregister';
      node._isSqlRegError = false;

      switch (true) {
        // If a Physical server, use $state-less mechanism
        case (node._environment === 'kPhysical'):
          serviceMethodFn = isUnregister ?
            SourceService.unregisterAppOwner : SourceService.registerAppOwner;

          serviceMethodFn(host).then(
            handleSqlRegChangeSuccess(node, type),
            evalAJAX.errorMessage
          );
          break;

        // If VMware, go to its modify registration state
        case (node._environment === 'kVMware'):
          stateName = isUnregister ? 'sql-unregister' : 'sql-modify';
          stateParams = {
            entityId: node.protectionSource.id,
            host: host,
            username: node.credentials && node.credentials.username,
          };
          $state.go(stateName, stateParams);
          break;

        // TODO: Add additional types here as we expand SQL support.
        // HyperV (hypervEntity) can be added to the above block when support
        // is added.
      }
    };

    /**
     * Factory Fn that returns a promise response handler which displays a
     * success cMessage when a node's SQL reg is updated and reloads the
     * view/tree.
     *
     * @method    handleSqlRegChangeSuccess
     * @param     {object}   node     The node object to update SQL reg on.
     * @param     {string}   [type]   One of 'update' or 'unregister'.
     *                                Default = 'update'
     * @returns   {function}   The promise handler Fn.
     */
    function handleSqlRegChangeSuccess(node, type) {
      var textKey = (type === 'unregister') ?
        'sourceTreePub.unregisterDBSuccess' :
        'sourceTreePub.updateDBRegSuccess';

      return function handlerFn(resp) {
        cMessage.success({
          textKey: textKey,
          textKeyContext: node.protectionSource,
        });

        // Delay reload a moment to let the user read the message before we
        // jarringly reload the tree.
        $timeout($state.reload, 2000);
      };
    }

    /**
     * Generates the cContextMenu items based on the node passed in.
     *
     * @method     buildContextActions
     * @param      {Object}  node    to build actions for
     * @return     {Array}   List of options based on the current state of
     *                       the protectionSource.
     */
    function buildContextActions(node) {
      var out = [];

      // editing node setting is not allowed for the user who is not from the
      // organization owning the source.
      if (!node._owner.isSourceOwner) {
        return out;
      }

      // All physical server actions (except unregister) are disabled during
      // any registration/verification event.
      // TODO: make public API friendly
      var isRegistrationComplete = !!node.registrationInfo &&
        node.registrationInfo.authenticationStatus === 'kFinished';

      // @type  {object}  - Holder for host entity in $state-less app host
      //                    registrations workflows (ie. register physical SQL
      //                    host).
      var host;

      if (!$rootScope.user.privs.PROTECTION_SOURCE_MODIFY) {
        return out;
      }

      // Physical entities
      if (node._environment === 'kPhysical' &&
        node._type !== 'kHostGroup' &&
        !node._isUpgrading) {

        // TODO: make this call public API friendly
        host = SourceService.getRegisterableHostObject(node);

        out.push(
          {
            icon: 'icn-edit',
            state: node._type === 'kWindowsCluster' ?
              'sql-cluster-edit' : 'physical-edit',
            stateParams: {
              id: node.protectionSource.id,
              parentId: $stateParams.id,
            },
            display: $ctrl.text.editServer,
          },
          {
            icon: 'icn-delete',
            display: $ctrl.text.unregisterServer,
            action: function deregisterPhysicalWrapper() {
              unregisterPhysicalServer(node);
            }
          },
          {
            icon: 'icn-refresh',
            display: $ctrl.text.refreshServer,
            disabled: !isRegistrationComplete,
            action: function refreshPhysicalServerWrapper() {
              $ctrl.refreshPhysicalServer(node);
            },
          }
        );

        // As of 4.0, these modify physical sql registration actions have no
        // discreet views like VM SQL registration does: they are $state-less.
        // Physical SQL registration status is displayed inline in the source
        // tree.
        switch (true) {
          // Node is aleady registered as SQL Host
          case (node._isSqlHost):
            out.push({
              // Unregister server as SQL server
              icon: 'icn-delete',

              // Active SQL protection job. Do not allow to unregister SQL
              disabled: node._isSqlProtected || !isRegistrationComplete,
              disabledTooltipKey: 'sources.unregister.disabledTooltip',
              action: function unregisterAppOwner() {
                $ctrl.updateSqlRegistration(node, 'unregister');
              },
              translateKey: 'unregisterSQLServer',
            });
            break;

          // Entity is registered AND is a Windows host AND SQL Server not
          // already registered
          case (!node._isSqlHost &&
            node._envProtectionSource.hostType === 'kWindows'):
            out.push({
              // Register as SQL server
              icon: 'icn-add',
              disabled: !node._isRegistered,
              action: function editSqlRegistration() {
                $ctrl.updateSqlRegistration(node, 'update');
              },
              translateKey: 'registerAsSQLServer',
            });
        }


        // If detailed view
        if ($ctrl.options.detailedView) {
          out.push({
            icon: 'icn-upgrade',
            display: node._isSqlCluster ? $ctrl.text.upgradeClusterAgents :
              $ctrl.text.upgradeAgent,
            disabled: !node._isRegistered || !node._agent._isUpgradable,
            action: function upgradeAgentWrapper() {
              $ctrl.upgradeAgents(node);
            },
          });
        }
      }

      // Show upgrade action for hyperV hosts on detail page and hosts isn't
      // already in the process of upgrading.
      if (SOURCE_TYPE_GROUPS.hypervHosts.includes(node._type) &&
        !node._isUpgrading && $ctrl.options.detailedView) {
        out.push({
          icon: 'icn-upgrade',
          display: $ctrl.text.upgradeAgent,
          disabled: !node._isRegistered || !node._agent._isUpgradable,
          action: function upgradeAgentWrapper() {
            $ctrl.upgradeAgents(node);
          },
        });
      }

      // Generic NAS entities
      if (node._environment === 'kGenericNas' && node._type === 'kHost') {
        out.push(
          {
            icon: 'icn-edit',
            state: 'nas-edit',
            stateParams: {
              id: node.protectionSource.id,
              parentId: $stateParams.id
            },
            translateKey: 'edit'
          },
          {
            icon: 'icn-refresh',
            translateKey: 'refreshMountPoint',
            action: function refreshPhysicalServerWrapper() {
              $ctrl.refreshPhysicalServer(node);
            }
          },
          {
            icon: 'icn-delete',
            translateKey: 'unregister',
            action: function unregisterGenericNasWrapper() {
              // This existing function works for NAS.
              unregisterPhysicalServer(node);
            }
          }
        );
      }

      var registeredSource = $ctrl.options.registeredSource;

      // VMware entities
      if (node._environment === 'kVMware' &&

        // Source type is not standalone ESXi host
        _.get(registeredSource,
          'protectionSource.vmWareProtectionSource.type') !== 'kStandaloneHost'
        ) {

        // Add SQL actions if...
        switch (true) {
          // Connection problems? Nothing more to do
          case (node._hasConnectionStateProblem):
            break;

          // vmwareEntity and SQL reg has been initiated
          case (node._isApplicationHost):
            out.push({
              translateKey: 'cSourceTree.editApplicationsRegistration',
              icon: 'icn-edit',
              action: function editSqlRegistration() {
                $ctrl.updateSqlRegistration(node, 'update');
              },
            });

            out.push({
              icon: 'icn-delete',

              // Can only unregister SQL if this VM isn't currently protected
              // by a SQL Job.
              disabled: node._isSqlProtected,
              disabledTooltipKey: 'sources.unregister.disabledTooltip',
              translateKey: 'cSourceTree.unregisterApplications',
              action: function unregisterSqlServer() {
                $ctrl.updateSqlRegistration(node, 'unregister');
              },
            });
            break;

          // vmwareEntity and is not registered as SQL Host
          default:
            out.push({
              translateKey: 'cSourceTree.registerApplications',
              icon: 'icn-add',
              state: 'sql-register',
              stateParams: {
                entityId: node.protectionSource.id,
              },
            });
        }
      }

      return out;
    }

    /**
     * handles toggling the open/closed state of the tags panel
     */
    $ctrl.toggleTagsPanel = function toggleTagsPanel() {
      $ctrl.tagsPanelOpen = !$ctrl.tagsPanelOpen;
      if (!$ctrl.tagsPanelOpen) {
        // user is closing the tags panel, clear the active tag filters
        $ctrl.activeTags.length = 0;
      }
    };

    /**
     * Filter the tree by applying search by tag and expand branches for matched nodes.
     */
    $ctrl.applyFilterByTag = function applyFilterByTag() {
      _addTag();

      PubJobServiceFormatter.expandTagBranch($ctrl.activeTags, {
        expandedNodes: $ctrl.expandedNodes,
        tree: $ctrl.tree,
      });
    };

    /**
     * adds a tag to the applied tags for filtering
     */
    function _addTag() {
      if (!$ctrl.activeTags.includes($ctrl.selectedTag)) {
        $ctrl.activeTags.push($ctrl.selectedTag);
        $ctrl.selectedTag = undefined;
        // all nodes have been filtered until proven otherwise
        $ctrl.allNodesFiltered = true;
      }
    }

    /**
     * removes a tag from the applied tags for filtering
     *
     * @param      {integer}  index   The index of the tag to remove
     */
    $ctrl.removeTag = function removeTag(index) {
      $ctrl.activeTags.splice(index, 1);
    };

    /**
     * handles adding the current set of ctrl.activeTags[] to jobTagSets and
     * updating the tree accordingly
     *
     * @param      {boolean}  exclude  true if the tagset is for exclusion,
     *                                     otherwise its for protection
     */
    $ctrl.addTagsToJob = function addTagsToJob(exclude) {

      var actionFn = exclude ? unselectNode : selectNode;

      var tagIds = $ctrl.activeTags.map(function getIds(tagNode) {
        return tagNode.protectionSource.id;
      });

      // cache tagIds.length as it can be referenced many times.
      var tagIdsLength = tagIds.length;

      // Build an array of tagId arrays already represented in the Job.
      var tagBranchTagIdSets = $ctrl.tree.reduce(
        function findTagBranches(tagIdSets, branchNode) {
          if (branchNode._isTagBranch) {
            // tag branches have the tag protectionSource.id values stored in
            // protectionSource.id
            tagIdSets.push(branchNode.protectionSource.id);
          }
          return tagIdSets;
        },
        []
      );

      // Determine if the tag or tags area already represented in the Job. Pay
      // no mind to wether they are excluded or auto protected. We don't want
      // duplicates regardless..
      var isTagSetAlreadyInJob = tagBranchTagIdSets.some(
        function findMatch(ids) {
          // If the two arrays have a matching length and their intersection
          // has a matching length then this is a positive match.
          return ids.length === tagIdsLength &&
            _.intersection(tagIds, ids).length === tagIdsLength;
        }
      );

      if (isTagSetAlreadyInJob) {
        // Inform user that the tag(s) is/are already in the Job and exit early.
        cMessage.warn({
          textKey: tagIdsLength > 1 ?
            'sourceTreePub.tagsAlreadyInJob' : 'sourceTreePub.tagAlreadyInJob',
        });
        return;
      }

      PubJobServiceFormatter.addTagBranch(
        $ctrl.activeTags,
        !exclude,
        $ctrl.leaves,

        // Send $ctrl.tree regardless of circumstances so dupes get updated
        // correctly. Put another way, do not send  $ctrl.leaves when in flat
        // view as dupes won't get updated correctly.
        $ctrl.tree,
        $ctrl.selectedObjectsCounts
      );

      // clear the active tags.
      $ctrl.activeTags.length = 0;

    };

    /**
     * Determines if Auto Protect is supported. Only applies to job flows.
     *
     * @method     isAutoProtectSupported
     * @return     {boolean}  True if Auto Protect supported, False otherwise.
     */
    $ctrl.isAutoProtectSupported = function isAutoProtectSupported() {
      return $ctrl.job &&
        ENV_GROUPS.autoProtectSupported.includes($ctrl.job.environment) &&
        $ctrl.options.canSelect &&
        !$ctrl.options.singleSelect;
    };

    /**
     * Additional tests for certain types to determine if entity is upgradable.
     * For other types, simply return existing value.
     *
     * @method     getUpgradability
     * @param      {object}  node    The node
     * @return     {string}  'kUpgradable' if upgradable
     */
    function getUpgradability(node) {
      // If SQL Cluster then we have to check each agent. If at least one is
      // upgradable, then the cluster is upgradable.
      if (node._isSqlCluster) {
        node._envProtectionSource.agents.some(
          function isAgentUpgradable(agent) {
            if (agent.upgradability === 'kUpgradable') {
              node._agent.upgradability = 'kUpgradable';
              return true;
            }
          }
        );
      }

      return _.get(node, '_agent.upgradability', '');
    }

    /**
     * recurses through the tree doing some initial setup
     *
     * @param      {object}   node      The node
     * @param      {integer}  index     The index of the node within siblings
     * @param      {Array}    siblings  The array of siblings
     */
    function recursiveTreeInit(node, index, siblings) {
      var groupNameMap = {
        kGCP: {
          kTag: 'networkTags',
          kLabel: 'labels',
          kMetadata: 'customMetadata',
        },
      };

      // To initialize node._isSelectable for each node
      node._isSelectable = $ctrl.canSelectNode(node);

      if (node._isLeaf) {

        if (ENV_GROUPS.usesAgent.includes(node._environment)) {
          // Update the upgradability property based on override param
          node._agent.upgradability = getUpgradability(node);
        }

        // build the context menu if enabled
        // TODO: restore this when implementing for Source detail page
        node._actions = $ctrl.options.contextMenu ?
          buildContextActions(node) : undefined;
      } else if ((node.protectionSource.environment === 'kGenericNas' &&
        node._type === 'kDfsGroup') ||
        node._environment === 'kHyperV') {
        // NAS DFS Groups are not leaf nodes, but they have the context menu.
        node._actions = $ctrl.options.contextMenu ?
          buildContextActions(node) : undefined;
      }

      // Process tag categories and tags. VMware only.
      if ((node._environment == 'kHyperV' && node._type === 'kCustomProperty') ||
      (node._environment === 'kVMware' && node._type === 'kTagCategory')) {

        $ctrl.tagCategories.push(node);

        // assuming all children of a tag category are tags and adding them to
        // the list of tags
        (node.nodes || []).forEach(function loopTags(tag) {
          tag._categoryName = node.protectionSource.name;
          $ctrl.tags.push(tag);
        });

        // exit early. There's no need for further processing as only tags will
        // be children of tag categories and those were just processed
        return;
      }

      // Process tag categories and tags for AWS
      if (['kGCP', 'kAWS'].includes(node._environment)) {
        if (['kTag', 'kLabel', 'kMetadata'].includes(node._type)) {
          if (node._environment === 'kGCP') {
            // GCP tags need to be grouped
            node._groupName = groupNameMap.kGCP[node._type];
          }

          $ctrl.tags.push(node);
          return;
        }
      }

      // Check for upgradable server for HyperV entity only
      if (node._environment === 'kHyperV' &&
        node._envProtectionSource.agents.length) {
        $ctrl.isAnyServerUpgradable = $ctrl.isAnyServerUpgradable ||
          node._envProtectionSource.agents[0].upgradability === 'kUpgradable';
      }

      // if current user is a restricted user,
      // filter out other users from entityPermissionInfo
      if (node.entityPermissionInfo && node.entityPermissionInfo.users) {
        node._usersWithEntityPersmission = node.entityPermissionInfo.users;

        if (UserService.user.restricted) {
          node._usersWithEntityPersmission = node._usersWithEntityPersmission
            .filter(({ sid }) => sid === UserService.user.sid);
        }
      }

      if (Array.isArray(node.nodes)) {
        node.nodes.forEach(recursiveTreeInit);
      }

      // Add this node to the internal cache of selected Nodes if it's selected.
      if (node._isSelected) {
        _selectedNodes.push(node);
      }
    }

    /**
     * Get the list of leaf nodes for the provided tree.
     *
     * @method   getLeaves
     * @param    {Array}   tree    The tree.
     * @return   {Array}   The list of leaf nodes.
     */
    function getLeaves(tree) {
      // Hash map of VM entity ids represented in $ctrl.leaves[].
      // Used to prevent adding duplicate VMs to $ctrl.leaves[].
      var vmHash = {};
      var leaves = [];

      PubJobServiceFormatter.forEachNode(tree,
        function eachNode(node) {
          if (node._isLeaf) {
            // if VM, add to cached flat VM list if not already represented
            if (node._isHypervisor && !vmHash[node.protectionSource.id]) {
              vmHash[node.protectionSource.id] = node.protectionSource;
              leaves.push(node);
            }
          }

          // Because DB hierarchies are blended, and contain real leaves, and
          // conditional leaves (Physical & VM hosts), we need to fake it with
          // these faux leaves, even though hosts aren't leaves in a DB
          // hierarchy.
          if (ENV_GROUPS.databaseSources.includes(node._rootEnvironment) &&
            ENV_GROUPS.databaseHosts.includes(node._environment)) {
            leaves.push(node);
          }
        }
      );

      return $filter('orderBy')(leaves, 'protectionSource.name');
    }

    /**
     * Determines whether to render the disabled checkbox for physical servers
     * and block-based jobs.
     *
     * @method     showDisabledCheckbox
     * @param      {object}   node    The node
     * @return     {boolean}  true if should show disabled checkbox
     */
    $ctrl.showDisabledCheckbox = function showDisabledCheckbox(node) {
      // Context is Protection Job flow AND
      return $ctrl.job &&

        // Job is Physical Block-based AND
        $ctrl.job.environment === 'kPhysical' &&

        // Object is Physical Server AND
        node._environment === 'kPhysical' &&

        // Object not already in this Job AND
        !node._inJob &&

        // Object protected by another block-based AND
        node._isBlockProtected &&

        // We only allow a single job per Physical Server
        !FEATURE_FLAGS.allowProtectPhysicalServerWithMultipleJobs;
    };

    /**
     * Add a wrapper fn to update leaves in 'Flat' tree view after the tree is
     * updated.
     *
     * @param node The node to remove.
     * @param $path The path to node.
     */
    $ctrl.removeNodeFn = function removeNode(node, $path) {
      $ctrl.options.removeNode(node, $path);

      // Update $ctrl.leaves and $ctrl.filteredLeaves when in 'flat' tree view.
      if ($ctrl.treeView === 'flat') {
        _.remove($ctrl.leaves,
          leaf => leaf.protectionSource.id === node.protectionSource.id);
        $ctrl.filteredLeaves = $ctrl.leaves.filter(treeFilterExpressionFn);
      }
    }

    /**
     * Determines whether physical server is already protected by another
     * block-based job.
     *
     * @method     isPhysicalAlreadyProtected
     * @param      {object}   node    The node
     * @return     {boolean}  true if physical server already protected
     */
    $ctrl.isPhysicalAlreadyProtected =
      function isPhysicalAlreadyProtected(node) {
        // Object is not already in this Job AND not already block protected.
        return !node._inJob && node._isBlockProtected;
      };

    /**
     * Gets the aag information.
     *
     * @method   getAagInfo
     * @param    {object}   node   The node
     */
    $ctrl.getAagInfo = function getAagInfo(node) {
      if (node._showAagServers) {
        node._showAagServers = false;

        return;
      }

      node._showAagServers = true;

      PubSourceService.bindAagDetailsToNode(node)
        .catch(evalAJAX.errorMessage);
    };

    /**
     * Select all host nodes in the AAG Network of the given node.
     *
     * @method   selectAllAagNodes
     * @param    {object}   node   The node in the tree.
     */
    $ctrl.selectAllAagNodes = function selectAllAagNodes(node) {
      // Since Auto-protect has no meaning for SQL VMs (as of 6.0.1), we don't
      // want to trigger auto-protect UI. Only if it's not VMware.
      var useAutoProtect = node._hostEnvironment !== 'kVMware';

      PubSourceService.getUniqueAagHosts(node, $ctrl.leaves).forEach(
        function eachHost(foundNode) {
          selectNode(foundNode, useAutoProtect, false, true);
        });
    };

    /**
     * Given a single System DB node, select is same-isntance sibling System
     * DBs.
     *
     * @method   selectAllInstanceSystemDbs
     * @param    {object}   [node]   The starting node
     */
    function selectAllInstanceSystemDbs(node) {
      var systemDbsOnIstance = PubJobServiceFormatter
        .findNodes($ctrl.tree, function compareFn(otherNode) {
          // If this core check fails, exit early and avoid calculating the rest
          // of this: it's not compatible.
          if (!otherNode._isSystemDb) { return; }

          return PubSourceServiceUtil.isSameSqlInstance(node, otherNode);
        });

      // Now select all the found nodes.
      systemDbsOnIstance.forEach(function eachHost(foundNode) {
        selectNode(foundNode, false, false, true);
      });
    }

    /**
     * Determines ability to select all AAG nodes.
     *
     * Criteria:
     * - This node is in AAG
     * - This AAG has no unknown members (not registered with Cohesity)
     * - Is not protected by another Job, or is protected by this Job
     * - All other nodes in the AAG are selectable by normal sourceTree criteria
     *
     * @method   canSelectAllAagNodes
     * @param    {object}    node   The node.
     * @return   {boolean}   True if able to select all AAG nodes, False
     *                       otherwise.
     */
    $ctrl.canSelectAllAagNodes = function canSelectAllAagNodes(node) {
      // Immediate false conditions.
      if (!node._aagDetails ||
        node._aagDetails.$hasUnknownAagNodes ||
        (node._isProtected && !_isNodeInJob(node))) {
        return false;
      }

      // Can all the nodes in this AAG group be selected?
      return PubSourceService.getUniqueAagHosts(node, $ctrl.leaves).every(
        function eachNode(aagNode) {
          return _isNodeInJob(aagNode) || $ctrl.canSelectNode(aagNode);
        });
    };

    /**
     * Determines if node is in the current Job.
     *
     * @method   _isNodeInJob
     * @param    {object}    node   The node.
     * @return   {boolean}   True if node in current Job, False otherwise.
     */
    function _isNodeInJob(node) {
      return _.includes($ctrl.job.sourceIds, node.protectionSource.id);
    }

    /**
     * Returns the agent status translation key.
     *
     * @method     getAgentStatusKey
     * @param      {Object}  agent   The agent
     * @return     {String}  The agent status translation key.
     */
    $ctrl.getAgentStatusKey = function getAgentStatusKey(agent) {

      switch (true) {
        case !!agent.refreshErrorMessage:
          return 'refreshError';

        case !!agent.authenticationErrorMessage:
          return 'registrationError';

        default:
          return 'healthy';
      }

    };

    /**
     * Determines ability to select node.
     * For job overlapping matrix please go through: https://goo.gl/JDm6L4
     *
     * @method   canSelectNode
     * @param    {object}    node   The tree Node.
     * @return   {boolean}   True if able to select node, False otherwise.
     */
    $ctrl.canSelectNode = function canSelectNode(node) {
      var job = $ctrl.job || {};
      var jobEnv = job.environment;
      var jobSourceType = _.get(_selectedNodes, '[0]._hostEnvironment');
      var jobHostType = _.get(_selectedNodes, '[0]._hostType');
      var jobVolumeType = _.get(_selectedNodes, '[0]._volumeType');

      // If options has canSelectNode then use it to check if node is
      // selectable or not
      if (_.get($ctrl, 'options.canSelectNode')) {
        return $ctrl.options.canSelectNode(node);
      }

      if (jobEnv === 'kSQL' && !_sqlCanSelectNode(node)) {
        return false;
      }

      switch (true) {
        // This bypass lets us add/remove sources already in the current job
        // and are selected. In case it is de-selected, it will be open to
        // disability
        case node._inJob && node._isSelected:
          return true;

        // This node's hostEnvironment is incompatible with others already
        // selected.
        case (!_.isUndefined(jobSourceType) &&
          jobSourceType !== node._hostEnvironment):
          return false;

        // For physical jobs only: Below condition provides
        // restrictions selecting nodes with different host type.
        case !!(ENV_GROUPS.physical.includes(jobEnv) && jobHostType &&
          node._hostType !== jobHostType):
          return false;

        case node._isTagExcluded:
          return false;

        case node._isAutoProtected:
          return true;

        case node._isAncestorAutoProtected || node._isTagAutoProtected:
          return $ctrl.job._supportsAutoProtectExclusion;

        // For Oracle Databases: can oracle entity be protectected?.
        case !!(jobEnv === 'kOracle' && node._isOracleSource):
          return (node._inJob || node._canProtectOracle)
            && !node._isOlderOracle;

        // Source details view: If the node is not upgradeable
        case !!($ctrl.options.detailedView &&
          node._agent &&
          (node._agent.upgradability !== 'kUpgradable' || node._isUpgrading)):
          return false;

        // For cloud sources only allow VMs which have physical agent installed
        case ($ctrl.isInvalidCloudVM(node)):
          return false;

        case (jobEnv === 'kOracle' && node._isOracleHost && node._isProtected):
          return false;

        // Active Directory hosts can only be backed up by one job.
        case (jobEnv === 'kAD' && node._isApplicationHost && node._isProtected):
          return false;

        // For a flashBlade node, if the node has no protocols, we should not
        // allow selecting that node as it results in crash loops.
        case (jobEnv === 'kFlashBlade' &&
          !_.get(node._envProtectionSource, 'fileSystem.protocols')):
          return false;

        case (jobEnv === 'kNetapp' && node._volumeType &&
          jobVolumeType && node._volumeType !== jobVolumeType):
          return false;
      }

      return true;
    };

    /**
     * Determines if a node can be selected in a SQL context.
     *
     * @method   _sqlCanSelectNode
     * @param    {object}    node   The node to check for selectability.
     * @return   {boolean}   True if selectable by SQL criteria.
     *                       False otherwise.
     */
    function _sqlCanSelectNode(node) {
      var job = $ctrl.job || {};
      var jobEnv = job.environment;
      var jobSourceType = _.get(_selectedNodes, '[0]._hostEnvironment');
      var jobEnvParams = job._envParams;

      switch (true) {
        // Editing a job with emptied sources and the original job's adapter
        // type doesn't match thisnode.
        case (!!job._jobHostEnvironment &&
          !job.sourceIds.length &&
          job._jobHostEnvironment !== node._hostEnvironment):
          return false;

        // SQL File-based backups are disabled.
        case (!FEATURE_FLAGS.protectSqlFileBased &&
          node._environment === 'kSQL'):
          return false;

        // This node's hostEnvironment is incompatible with others already
        // selected.
        case (!_.isUndefined(jobSourceType) &&
          jobSourceType !== node._hostEnvironment):
          return false;

        // This node is the parent of objects in this job but is not itself
        // protected
        case ((!node._isProtected || node._canAutoProtect) &&
          !!node._inJobDescendants.length):
          // Fall through to next case.

        // This node or its children are currently protected by this Job (regardless of current
        // selection).
        case (node._inJob):
          if (jobEnvParams.backupType !== 'kSqlVSSVolume') {
            // True if VM file-based SQL job support is enabled. Or, failing
            // that, if the host environment is not VMware.
            return FEATURE_FLAGS.protectSqlFileBasedVmware ||
              node._hostEnvironment !== 'kVMWare';
          }

          // In most cases, except those accounted for above, if an entity is
          // already protected by this job (regardless of whether or not its
          // currently selected during editing), it should be allowed to be
          // selected again.
          return true;

        // Prevent selection of DBs if not in a DB job flow.
        case (!ENV_GROUPS.databaseSources.includes(node._rootEnvironment) &&
          ENV_GROUPS.databaseSources.includes(node._environment)):
          return false;

        // VM SQL host environment && db object: false
        case (!FEATURE_FLAGS.protectSqlFileBasedVmware &&
          ENV_GROUPS.databaseSources.includes(node._environment) &&
          node._hostEnvironment === 'kVMWare'):
          return false;

        // Fresh SQL job, nothing selected yet.
        case (!job.id && !job.sourceIds.length):
          return node._canSqlFileProtect || node._canSqlVolumeProtect;

        // SQL Job, Volume-based: can volume-protect?
        case jobEnvParams.backupType === 'kSqlVSSVolume':
          return node._canSqlVolumeProtect;

        // 6.1.1+ SQL Job, File-based: can file-protect?
        case FEATURE_FLAGS.enableFilestream &&
          jobEnvParams.backupType !== 'kSqlVSSVolume':
          return node._canSqlFileProtect;

        // Pre 6.1.1 check. Remove after GA
        case !!(!FEATURE_FLAGS.enableFilestream &&
          jobEnvParams.backupType !== 'kSqlVSSVolume' &&
          (node._isProtected || node._isSqlProtected)):
          return node._canSqlFileProtect;
      }

      return true;
    }

    /**
     * Determines if a given node is a selectable Cloud VM.
     *
     * @method   isInvalidCloudVM
     * @param    {Object}    node   The node entity
     * @return   {Boolean}   True if invalid cloud vm, False otherwise.
     */
    $ctrl.isInvalidCloudVM = function(node) {
      // if the source tree is being used for restore and not protection,
      // physical agent check is not needed
      if ($ctrl.options.ignorePhysicalAgentCheck) {
        return false;
      }

      // Unmanaged VMs are not allowd for Azure CSM
      if ($ctrl.job.environment === 'kAzureSnapshotManager' && node._isLeaf
        && !node.protectionSource.azureProtectionSource.isManagedVm) {

        return true;
      }

      // This is applicable only for physical agent based snapshots
      if (ENV_GROUPS.cloudJobsWithoutPhysicalAgent
        .includes($ctrl.job.environment)) {

        return false;
      }

      return !!(ENV_GROUPS.cloudSources
        .includes(node.protectionSource.environment) &&
          node._isLeaf && !node._isPhysicalAgentInstalled);
    };

    /**
     * extends options with there default value if not provided explicitly.
     *
     * NOTE: _.assign will not work since options are two way binded & it will
     * make you loose the original reference.
     * $ctrl.options = _.assign({}, defaultsOptions, $ctrl.options);
     *
     * @method   extendDefaultOptions
     */
    function extendDefaultOptions() {
      var missingOptions = _.difference(
        Object.keys(defaultsOptions),
        Object.keys($ctrl.options)
      );

      missingOptions.forEach(function eachOption(missingOption) {
        $ctrl.options[missingOption] = defaultsOptions[missingOption];
      });
    }

    /**
     * $onInit function for the controller
     */
    $ctrl.$onInit = function $onInit() {
      extendDefaultOptions();
      $ctrl.treeView = 'physical';

      $ctrl.treecontrolOptions = {
        injectClasses: {
          ul: 'c-source-list-ul',
          li: 'c-source-list-li',
        },
        nodeChildren: 'nodes',
        equality: function equalityFn(node1, node2) {
          if (!node1 || !node2) {
            return;
          }
          return node1.protectionSource.id === node2.protectionSource.id;
        },
      };

      // setup tree-control option when rendering partial tree.
      if ($ctrl.options.asyncMode) {
        $ctrl.onNodeToggle = asyncLoadNodeChildren;
        $ctrl.treecontrolOptions.isLeaf = isLeafNodeForAsyncMode;
      }

      if ($ctrl.options.sqlAsyncMode) {
        $ctrl.onNodeToggle = asyncSqlLoadNodeChildren;
        $ctrl.treecontrolOptions.isLeaf = isLeafNodeForAsyncMode;
      }

      // Set the checkbox to select all physical servers to false
      $ctrl.selectAllPhysicalSourceCheckbox=false;

      $ctrl.allSelected = false;

      // Flag to disable parent upgrade agent button if there's no server to
      // upgrade.
      $ctrl.isAnyServerUpgradable = false;

      // Stores entity.id of the node for which the "remove from current job"
      // link should be displayed. This allows us to display only one of these
      // messages at a time. This is used in Physical Servers.
      $ctrl.viewRemovalLinkId = undefined;

      $ctrl.text = $rootScope.text.cSourceTree;
      $ctrl.ENV_GROUPS = ENV_GROUPS;

      // tag related properties
      $ctrl.tagCategories = [];
      $ctrl.tags = [];
      $ctrl.activeTags = [];
      $ctrl.showTagCategory = true;

      $ctrl.selectedObjectsCounts = $ctrl.selectedObjectsCounts || {};

      /* Indiciates whether the filters applied have resulted in no displayed
       * tree.
       */
      $ctrl.allNodesFiltered = false;

      $ctrl.filtering = {
        // indicates if filtering is currently active. set to true by our filter
        // change functions, and set to false via timeout in
        // treeFilterExpressionFn
        active: false,
        fn: treeFilterExpressionFn,
      };

      // Indicates if link to refresh the tree should be shown.
      $ctrl.allowRefresh = _.isFunction($ctrl.options.updateTree) &&
        ENV_GROUPS.sourceRefreshSupported.includes($ctrl.job.environment);

      // Indicates if the tree refresh is in progress.
      $ctrl.isRefreshingTree = false;

      // Defaulting $ctrl.job to a empty object if no job is passed in.
      // Supresses javascript errors in methods that expect $ctrl.job to be
      // defined.
      $ctrl.job = $ctrl.job || {};

      // TODO(veetesh): since we are not pruning cloud tree then some of the
      // cloud node need fixes.
      if (!$ctrl.options.showFullCloudTree) {
        // if cloud tree, filter out unnecessary nodes
        $ctrl.tree = pruneCloudTree($ctrl.tree);
      }

      // Filter out unwanted nodes for physical jobs.
      if (ENV_GROUPS.physical.includes($ctrl.job.environment)) {
        $ctrl.tree = PubJobServiceFormatter.removeNodes($ctrl.tree,
          function removeUnwanted(node) {
            // Incase of Physical block or file based jobs, only Oracle RAC
            // backup is unsupported.
            return  node._type === 'kOracleRACCluster';
          });
      }

      // In case of job creation for a cloud source, display a "Download
      // Cohesity Agent" message and disable selection of VMs which don't have
      // the agent installed.
      $ctrl.isJobView = !_.isEmpty($ctrl.job);
      if ($ctrl.isJobView) {

        if (!FEATURE_FLAGS.gcpTags) {
          ENV_GROUPS.taggable =
            _.without(ENV_GROUPS.taggable, 'kGCPNative', '27');
        }

        $ctrl.isTaggable = ENV_GROUPS.taggable.includes($ctrl.job.environment);

        // AWS Tags don't have categories. So don't show it.
        if (ENV_GROUPS.tagTypesWithoutCategories
          .includes($ctrl.job.environment)) {

          $ctrl.showTagCategory = false;
        }

        $ctrl.isCloudTree =
          ENV_GROUPS.cloudSources.includes($ctrl.job.environment);

        if ($ctrl.isCloudTree) {
          $ctrl.isCloudTreeWithNativeSupport =
            ENV_GROUPS.nativeSnapshotTypes.includes($ctrl.job.environment);
          $ctrl.treeType = ENUM_ENV_TYPE[$ctrl.job.environment];
          $ctrl.openDownloadAgentsModal = SourceService.downloadAgentsModal;
        }
      }

      /* Entity type filters, value represents the entity type that should be
       * expanded to, not expanding the type specified
       *
       * @type {Array}
       */
      $ctrl.entityTypeFilters = {
        kVMware: [
          {
            expandToTypes: ['kDatacenter'],
            name: $ctrl.text.filterText.datacenter,
          }, {
            expandToTypes: ['kClusterComputeResource'],
            name: $ctrl.text.filterText.cluster,
          }, {
            expandToTypes: ['kHostSystem'],
            name: $ctrl.text.filterText.esx,
          }, {
            expandToTypes: ['kVirtualMachine'],
            name: $ctrl.text.filterText.vms,
          }
        ],
        kHyperV: [
          {
            expandToTypes: ['kHostCluster'],
            name: $ctrl.text.filterText.cluster,
          }, {
            expandToTypes: ['kHypervHost'],
            name: $ctrl.text.filterText.hosts,
          }, {
            expandToTypes: ['kVirtualMachine'],
            name: $ctrl.text.filterText.vms,
          }
        ],
        kHyperVVSS: [
          {
            expandToTypes: ['kHostCluster'],
            name: $ctrl.text.filterText.cluster,
          }, {
            expandToTypes: ['kHypervHost'],
            name: $ctrl.text.filterText.hosts,
          }, {
            expandToTypes: ['kVirtualMachine'],
            name: $ctrl.text.filterText.vms,
          },
        ],
        kKVM: [
          {
            expandToTypes: ['kDatacenter'],
            name: $ctrl.text.filterText.datacenter,
          }, {
            expandToTypes: ['kHost'],
            name: $ctrl.text.filterText.hosts,
          }, {
            expandToTypes: ['kVirtualMachine'],
            name: $ctrl.text.filterText.vms,
          }
        ],
      };

      $ctrl.leaves = [];

      initEnvironment();
      updateOSTypeFilter(true);

      $ctrl.options = angular.extend($ctrl.options || {}, {
        // Enforce a boolean value for undefined properties
        canSelect: !!$ctrl.options.canSelect,
        singleSelect: !!$ctrl.options.singleSelect,
        detailedView: !$ctrl.job || !!$ctrl.options.detailedView,

        // VMware Tools is a notion only for VMware environments
        vmwareToolsForcedFiltering: !!$ctrl.options.vmwareToolsForcedFiltering,
      });

      initializeNodePropertyFilters();

      if ($ctrl.options.singleSelect) {
        prepareTreeForSingleSelection();
      }

      $ctrl.tree.forEach(recursiveTreeInit);
      $ctrl.leaves = getLeaves($ctrl.tree);

      // alpha sort the list of tags and categories
      $ctrl.tags = $filter('orderBy')($ctrl.tags, 'protectionSource.name');
      $ctrl.tagCategories =
        $filter('orderBy')($ctrl.tagCategories, 'protectionSource.name');

      $ctrl.applyFilters();

      // load entity hierarchy for root nodes having some selected ancestor's
      if ($ctrl.options.asyncMode) {
        $timeout(function deplayAutoExpanding() {
          autoExpandSelectedRootNodes();
        }, digestDelayMs);
      }

      if ($ctrl.options.sqlAsyncMode) {
        markTreeNodesLoaded($ctrl.tree);
      }

      $ctrl.$onChanges = $attrs.hasOwnProperty('externalSearchString') ?
        $onChanges : angular.noop;

      $ctrl.type = $ctrl.type === 'modal' ? 'modal' : 'page';

      // RDS needs filters for different DB Types that it supports
      if ($ctrl.job.environment === 'kRDSSnapshotManager') {
        _addRDSFilters();
      }

      // Set the visibility of Select All Checkbox
      setShowSelectAll();
    };

    /**
     * Add RDS Specific filters for source tree
     *
     * @method  _addRDSFilters
     */
    function _addRDSFilters() {
      var filterObjects =
        ['sqlServer', 'oracle', 'mySql', 'postgres', 'mariaDB']
          .map(function mapDB(name) {
            return {
              name: $translate.instant(name),
              db: name,
              value: 'rds'
            };
          });

      $ctrl.nodePropertyFilters =
        $ctrl.nodePropertyFilters.concat(filterObjects);
    }

    /**
     * Load entity hierarchy for the root nodes having some selected ancestor
     * nodes
     *
     * @method   autoExpandSelectedRootNodes
     */
    function autoExpandSelectedRootNodes() {
      // collect list of promises to load entity hierarchy.
      var promises = $ctrl.tree.map(function eachNode(node) {
        if (_.isEmpty(node._selectedAncestorIdsMap)) {
          return $q.resolve();
        }

        return $ctrl.onNodeToggle(node);
      });

      // expand tree node after entity hierarchy loaded.
      $q.all(promises).then(function allRootNodeResolved() {
        expandTillSelectedAncestor();
      });
    }

    /**
     * Gets the appropriate tooltip explaining why the checkbox is disabled
     * based on the job and the node.
     *
     * @method   getUnselectableNodeTooltip
     * @param    {object}   node   The node
     * @return   {string}   The tooltip key.
     */
    $ctrl.getUnselectableNodeTooltip = function getUnselectableNodeTooltip(node) {
      var jobSourceType = _.get(_selectedNodes, '[0]._hostEnvironment');
      var jobHostType = _.get(_selectedNodes, '[0]._hostType');
      var jobVolumeType = _.get(_selectedNodes, '[0]._volumeType');
      var job = $ctrl.job || {};
      var jobEnv = job.environment;

      switch (true) {
        // Get out early if this is selectable
        case $ctrl.canSelectNode(node):
          return;

        // SQL host environment && db object: false
        case (!FEATURE_FLAGS.protectSqlFileBasedVmware &&
          ENV_GROUPS.databaseSources.includes(node._environment) &&
          node._hostEnvironment === 'kVMWare'):
          return 'cSourceTree.tooltips.sqlNodesCantBeSelected';

        // SQL Volume-based incompatibility.
        case !!(jobEnv === 'kSQL' &&
          job._envParams.backupType === 'kSqlVSSVolume' &&
          !node._canSqlVolumeProtect):
          return 'cSourceTree.tooltips.sqlVolumeBasedIncompatible';

        // SQL File-based incompatibility.
        case !!(jobEnv === 'kSQL' &&
          job._envParams.backupType !== 'kSqlVSSVolume' &&
          (node._isProtected || node._isSqlProtected) &&
          !node._canSqlFileProtect):

          return ENV_GROUPS.sqlHosts.includes(node._type) ?
            'cSourceTree.tooltips.sqlHasEntitiesProtectedByAnotherJob' :
            'cSourceTree.tooltips.sqlFileAlreadyProtected';

        // Editing a SQL Job, the selection has been emptied, and the node isn't
        // selectable in a file-based job.
        case !!(jobEnv === 'kSQL' &&
          job.id &&
          !job.sourceIds.length &&
          job._envParams.backupType !== 'kSqlVSSVolume' &&
          !node._canSqlFileProtect):
          return 'sourceIncompatibleFileBasedBackup';

        // Prevent selection of DBs if not in the SQL job flow.
        case !!(!ENV_GROUPS.databaseSources.includes(node._rootEnvironment) &&
          ENV_GROUPS.databaseSources.includes(node._environment)):
          return 'cSourceTree.tooltips.sqlNotAllowedInThisJobFlow';

        // Source details view: If the node is not upgradeable, the checkbox
        // is disabled.
        case !!($ctrl.options.detailedView &&
          (node._agent.upgradability !== 'kUpgradable' || node._isUpgrading)):
          return 'cSourceTree.notUpgradable';

        // For physical jobs only: Below condition provides restricts
        // selecting nodes with different host type.
        case !!(ENV_GROUPS.physical.includes(jobEnv) &&
          jobHostType && node._hostType !== jobHostType):
          return 'cSourceTree.differentHostType';

        // If a physical host has been registered as oracle host then
        // selection of physical host for backup is prohibited.
        case node._isOracleHost:
          return 'cSourceTree.tooltips.oracleOnlyDatabases';

        // For Azure, only allow VMs whihch have physical agent installed.
        case (ENV_GROUPS.cloudSources.includes(node._environment) &&
          node._isLeaf && !node._isPhysicalAgentInstalled):
          return 'noPhysicalAgentInstalledMessage';

        // For Azure, only allow VMs whihch have physical agent installed.
        case (jobEnv === 'kAzureSnapshotManager' &&
          node._isLeaf && !node._envProtectionSource.isManagedVm):
          return 'unmanagedVmsNotSupportedMessage';

        // Editing a job with emptied sources and the original job's adapter
        // type doesn't match thisnode.
        case (!!job._jobHostEnvironment &&
          !job.sourceIds.length &&
          job._jobHostEnvironment !== node._hostEnvironment):
          // Fall through to next condition.

        // If the node in question is incompatible with other selected Nodes'
        // hosts.
        case (!_.isUndefined(jobSourceType) &&
          jobSourceType !== node._hostEnvironment):
          return 'cSourceTree.tooltips.incompatibleProtectionSources';

        case (jobEnv === 'kOracle' && node._isOlderOracle):
          return 'cSourceTree.oracle.OlderOracle';

        case (jobEnv === 'kFlashBlade' &&
          !_.get(node._envProtectionSource, 'fileSystem.protocols')):
          return 'cSourceTree.tooltips.noFlashBladeProtocols';

        case (jobEnv === 'kNetapp' && node._volumeType &&
          jobVolumeType && node._volumeType !== jobVolumeType):
          return 'cSourceTree.tooltips.differentVolumeType';
      }

      // Generic fall-back: "already protected".
      return 'cSourceTree.alreadyProtected';
    };

    /**
     * Gets the appropriate tooltip explaining why the radio button is disabled
     * based on the node type.
     *
     * @method   getSingleSelectNodeTooltipKey
     * @param    {object}   node   The node
     * @return   {string}   The tooltip key.
     */
    $ctrl.getSingleSelectNodeTooltipKey =
      function getSingleSelectNodeTooltipKey(node) {
      if (_.get(node._envProtectionSource, 'volumeInfo.type') === 'kDataProtection') {
        return 'cSourceTree.tooltips.dataProtectVolumeCantBeSelected';
      }
    }

    /**
     * Toggle the ancestor node selection and ensure node children are loaded
     * before toggling the flag.
     *
     * @method   toggleAncestorNodeSelectionFn
     * @param    {Object}     node            The node to toggle
     * @param    {Function}   getPathToNode   Function that returns path to the
     * node provided treecontrol under scope.$path
     */
    $ctrl.toggleAncestorNodeSelectionFn =
      function toggleAncestorNodeSelectionFn(node, getPathToNode, $event) {
        // show unassignment warning when node removal is not allowed.
        if (node._isSelected && !node._canRemove.result) {
          node._showUnselectionError = true;
          $event.preventDefault();
          return;
        }
        // ensure selected node childrens are loaded
        asyncLoadNodeChildren(node).then(function nodeLoaded() {
          $ctrl.options.toggleNodeSelection(node, getPathToNode);
          // if the node is selectable then
          if(node._canSelect.result)
          { // select all checkbox for physical server needs to be toggled after applying filters
            $ctrl.toggleSelectAll();
          }
        });
      };

    /**
     * Detremines whether the registered application has any error.
     *
     * @method    hasApplicationAuthenticationError
     * @param     {Object}    node   Specifies the current source node object
     * @return    {Boolean}   True, if the node has any app registration error.
     */
    $ctrl.hasApplicationAuthenticationError =
      function hasApplicationAuthenticationError(node) {
      return node.registrationInfo.registeredAppsInfo &&
        node.registrationInfo.registeredAppsInfo.length &&
        node.registrationInfo.registeredAppsInfo[0].authenticationErrorMessage;
    }

    /**
     * Refresh the tree to get any changes made in source.
     *
     * @method   refreshTree
     * @param    {Object}     node    Root node of the tree to be refreshed.
     * @return   {void}
     */
    $ctrl.refreshTree = function refreshTree(node) {
      $ctrl.isRefreshingTree = true;

      // Make the call to get the changes.
      PubSourceService.refreshSource(node.protectionSource.id).then(
        function refreshSuccess() {
          // Fetch the refreshed tree.
          $ctrl.options.updateTree()
            .then(function treeUpdated() {
              if (!$ctrl.options.showFullCloudTree) {
                // push this to next digest cycle. No delay time is required.
                $timeout(function() {
                  // if cloud tree, filter out unnecessary nodes
                  $ctrl.tree = pruneCloudTree($ctrl.tree);
                });
              }
            });
        },
        evalAJAX.errorMessage
      ).finally(
        function refreshTreeFinally() {
          $ctrl.isRefreshingTree = false;
        }
      );
    };

    /**
     * Gets the appropriate intl string key indicating node
     * assignment status
     *
     * @method   getAssignmentTranslateKey
     * @param    {object}   node   The node
     * @return   {string}   The string intl key.
     */
    $ctrl.getAssignmentTranslateKey =
      function getAssignmentTranslateKey(node) {
        // isRegisteredBySp implies node itself registered by SP user
        // if isRegisteredBySp is false then entity was registered by organisation
        // `isRegisteredBySp` flag is set only while assigning root node
        if (
          FEATURE_FLAGS.mtSourceRootUnassignment &&
          node._isRootNode &&
          !node._owner.isRegisteredBySp
        ) {
          return 'entityRegisteredByOrganization';
        }

        return 'entityAssignedToOrganization';
    }

    /**
     * Init controller environment based on the source tree
     */
    function initEnvironment() {
      // Using _.get here because if tree is empty (filteres tree cases like
      // Amazon RDS), then this throws exception
      $ctrl.environment = _.get($ctrl, 'tree[0]._rootEnvironment');

      onEnvironmentChange();
    }

    /**
     * Update controller state whenever environment changes
     */
    function onEnvironmentChange() {
      $ctrl.isHypervisor = ENV_GROUPS.hypervisor.includes($ctrl.environment);
      $ctrl._isHyperV = ENV_GROUPS.hyperv.includes($ctrl.environment);

      initializeNodePropertyFilters();
    }

    /**
     * Update OS type filter
     *
     * @param apply boolean apply OS filter after checking
     */
    function updateOSTypeFilter(apply) {
      // reset os type filters
      osTypeFilter = [];
      $ctrl.osTypeFilter = [];
      // if a hostType option was provided, force filtering of that host type
      if ($ctrl.environment === 'kPhysical' && angular.isDefined($ctrl.options.hostType)) {
        // default host type filtering
        // hostType of a node in c-source-tree-public treeData is a kValue
        // not an int(bec we use public api). So convert the int hostType
        // from options to a kValue.
        if (_.isNumber($ctrl.options.hostType)) {
          $ctrl.osTypeFilter =
            [ENUM_HOST_TYPE_CONVERSION[$ctrl.options.hostType]];
        } else {
          $ctrl.osTypeFilter = [$ctrl.options.hostType];
        }

        if (apply) {
          $ctrl.osTypeFilteringDisabled = true;
          $ctrl.filterByOS($ctrl.osTypeFilter);
        }
      }
    }

  }

})(angular);
