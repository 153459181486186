// Module & Component Controller: cChangeLocale

;(function(angular, undefined) {

  var configOptions = {
    controller: 'cChangeLocaleCtrl',
    templateUrl: 'app/global/c-change-locale/c-change-locale.html',
  };

  /**
   * @ngdoc component
   * @name C:cChangeLocale
   * @function
   *
   * @description
   * A button which provides the way to change the language(locale) on the go.
   *
   * @example
   * <c-change-locale></c-change-locale>
   */
  angular.module('C')
    .component('cChangeLocale', configOptions)
    .controller('cChangeLocaleCtrl', cChangeLocaleCtrlFn);

  function cChangeLocaleCtrlFn(ClusterService, LocaleService) {
    var $ctrl = this;

    // The locale subscription object.
    var localeSubscription;

    var locales = _.map(LocaleService.getLocalesList(),
      function mapLocales(locale) {
        return {
          display: locale.name,
          action: function changeLocale() {
            // Call the fn that actually changes the locale.
            ClusterService.changeLocale(locale.value);
          }
        };
      });

    _.assign($ctrl, {
      locales: locales,
      selectedLocaleDisplay: '',

      // Component Lifecycle methods.
      $onDestroy: $onDestroy,
      $onInit: $onInit,
    });

    /**
     * Activate the controller
     *
     * @method   $onInit
     */
    function $onInit() {
      // Special case of subscription in AJS where activeLocale$ is being
      // subscribed for changes.
      localeSubscription = LocaleService.activeLocale$.subscribe({
        next: function localeSubscription(activeLocale) {
          // Add the selected locale for display.
          $ctrl.selectedLocaleDisplay =
            LocaleService.getTranslatedLocaleKey(activeLocale);
        }
      })
    }

    /**
     * On component destroy, cancel the subscription.
     *
     * @method   $onDestroy
     */
    function $onDestroy() {
      localeSubscription.unsubscribe();
    }
  }
})(angular);