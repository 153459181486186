// COHESITY MODAL

;(function(angular, undefined) {
  'use strict';

  angular.module('C.modal', [])
    .service('cModal', cModalFn);

  function cModalFn($rootScope, $uibModal) {

    return {
      show: show,
      showModal: showModal,
      standardModal: standardModal,
    };

    /**
     * Convenience wrapper to open a standard modal with boilerplate window
     * template. The contents are in the control of the implementor and the
     * related template.
     *
     * Because we force the `windowTemplateUrl` in this method, when defining
     * `template` or `templateUrl` in the calling controllers you don't need to
     * include ANY of the modal chrome markup, just the guts: everything between
     * the close button (x) and the buttons.
     *
     * Available configOptions: ~All uibModal standard options. Only
     * `controllerAs`, `windowTemplate`, and a single `resolve` are enforced.
     *
     * Available windowOptions: {
     *   actionButtonKey: 'ok',
     *   closeButtonKey: 'cancel',
     *   titleKey: 'title of modal',
     *   severity: 'warn',
     *   titleKeyContext: {},
     *   contentKeyContext: {},
     *
     *   // These two Fns are called on button $close (ok) or $dismiss (close)
     *   // of the modal.
     *   close: function,
     *   ok: function,
     * }
     *
     * @method     standardModal
     * @param      {object}  [modalConfig]    Optional uibModal Config oobject.
     * @param      {object}  [windowOptions]  Optional windowTemplate setup.
     * @return     {object}  Promise to resolve with the modal's response.
     */
    function standardModal(modalConfig, windowOptions) {
      // Base translation keys. Set to false in the passed `options` map to hide
      // any.
      windowOptions = angular.merge({
        actionButtonKey: 'ok',
        closeButtonKey: 'cancel',
        titleKey: 'confirmation',

        // contentKey may be provided in place of a template for simple modals.
        contentKey: undefined,

        /**
         * Custom ok and dismiss functions can be provided via windowOptions{}.
         * Additionally, custom ok() and dismiss() functions can be provided by
         * attaching them to a provided controller's $scope. These functions can
         * modify $scope.submitting to leverage cBtnSpinner functionality.
         */
        ok: false,
        dimiss: false,

        /**
         * This ID will be use as a unique ID for this modal, as well as a
         * prefix for the action buttons within. This is primarily used for UI
         * automation testing.
         *
         * @type   {string}  [id]
         */
        // id: 'String'

        /**
         * Customize the modal action buttons for creating info or warning
         * modals. Default value is 'info' which is our standard modal with
         * primary button color.
         */
        severity: 'info',
      }, windowOptions);

      // Merge defaults, customizations, and enforced settings into one map.
      modalConfig = angular.merge(
        // Customizable defaults
        {
          backdrop: 'static',
          keyboard: true,
          modalFade: true,
          size: 'md',
        },

        // Overrides
        modalConfig,

        // Enforced config. Hence "standard" modal.
        {
          // This isn't a valid setting with the version of uibModal we're
          // using. it's in a newer version.
          // bindToController: true,
          controllerAs: '$ctrl',
          windowTemplateUrl: 'app/global/cModal/c-modal-standard-window.html',
          resolve: {
            modalOptions: function() { return windowOptions; },
          },
        }
      );

      /**
       * uibModal requires a template, templateUrl or component.
       * If contentKey is provided, this allows using the modal without a
       * template. If neither is provided, display the following error template.
       */
      if (!modalConfig.template && !modalConfig.templateUrl
        && !modalConfig.component) {
        modalConfig.template = '<div>no template</div>';
      }

      if (!modalConfig.controller && !modalConfig.component) {
        modalConfig.controller = basicModalCtrlFn;
      }

      // If an ID was defined, generate the unique button IDs.
      if (windowOptions.id !== undefined) {
        windowOptions.buttonIds = {
          cancel: [windowOptions.id, 'cancel'].join('-'),
          close: [windowOptions.id, 'close'].join('-'),
          ok: [windowOptions.id, 'ok'].join('-'),
        };
      }

      // Open the modal and return the promise when it's resolved.
      return $uibModal.open(modalConfig).result;

      /**
       * modalConfig needs at least a controller with $scope injected in order
       * for modalOptions, etc to be avaiable on $scope in the windowTemplate.
       * If modalOptions is completely static, then the controller is
       * unnecessary.
       */
      /** @ngInject */
      function basicModalCtrlFn($scope) {}
    }


    /**
     * External show modal method.
     *
     * THIS METHOD IS DEPRECATED. INSTEAD USE standardModal.
     *
     * @method   showModal
     * @param    {object}  [customConfig]    Optional modal config options.
     * @param    {object}  [customOptions]   Optional Modal $scope options.
     * @returns  {object}  Promise to resolve with the user's selected response.
     */
    function showModal(customConfig, customOptions) {

      var defaultConfig = {
        backdrop: 'static',
        keyboard: true,
        modalFade: true,
        templateUrl: 'app/global/cModal/cModal.html',
      };

      var defaultOptions = {
        actionButtonText: $rootScope.text.cModal.ok,
        closeButtonText: $rootScope.text.cModal.cancel,
        content: '',
        hideActionButton: false,
        hideCancelButton : false,
        title: '',
      };

      // Merge user configs with default configs.
      customConfig = angular.extend({}, defaultConfig, customConfig);
      customOptions = angular.extend({}, defaultOptions, customOptions);

      // Show the modal
      return this.show(customConfig, customOptions);
    }

    /**
     * Internal show method.
     *
     * @method   show
     * @param    {object}  customConfig   Modal config object.
     * @param    {object}  customOptions  Modal $scope options.
     * @returns  {object}  Promise to resolve with the user's selected response.
     */
    function show(customConfig, customOptions) {
      if (!customConfig.controller) {
        customConfig.controller = cModalControllerFn;
      }

      return $uibModal.open(customConfig).result;

      /** @ngInject */
      function cModalControllerFn($scope, $uibModalInstance) {
        $scope.modalOptions = customOptions;
        $scope.modalOptions.ok = $uibModalInstance.close;
        $scope.modalOptions.close = function modalCloseFn() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    }
  }

})(angular);
