import { HttpParams } from '@angular/common/http';
import { DefaultUrlSerializer } from '@angular/router';

/**
 * Normalize the url by removing leading and trailing slash.
 *
 * @param url The url.
 * @returns The normalize url value.
 */
export const normalizeUrl = (url: string) => (url || '').split('/').filter(Boolean).join('/');

/**
 * Combine the url parts and return normalize url.
 *
 * @param urls The urls.
 * @returns The Combine url value.
 */
export const joinUrl = (urls: string[]): string => normalizeUrl((urls || []).filter(Boolean).join('/'));

/**
 * Adds a leading slash to the given URL
 *
 * @param url url that need to be prefixed
 * @returns prefixed url
 */
export const prefixSlash = (url: string): string => url.startsWith('/') ? url : `/${url}`;

/**
 * Add the provided query params to the given urls
 *
 * @param urls The url.
 * @param params The query params object.
 * @returns The URL with added query params.
 */
export const addQueryParams = (
  urls: string[],
  params?: Record<string, string | number | boolean | string[] | number[] | boolean[]>
) => {
  const newUrl = joinUrl(urls);
  const urlTree = new DefaultUrlSerializer().parse(newUrl);
  const pathname = urlTree.toString().split('?')?.[0]?.trim();
  const paramStr = new HttpParams({ fromObject: { ...urlTree.queryParams, ...params } }).toString();

  return normalizeUrl([pathname, paramStr].filter(Boolean).join('?'));
};
