import { Component, ContentChild, Input, ViewEncapsulation, HostBinding } from '@angular/core';

/**
 * @description
 * Used for placing icon inside next to header title in `PageContentHeaderComponent`.
 *
 * @example
 *  <cog-page-content>
 *    <cog-page-content-header>
 *      <cog-page-content-header-icon>
 *         <cog-icon shape="access"></cog-icon>
 *       </cog-page-content-header-icon>
 *    </cog-page-content-header>
 *  </cog-page-content>
 */
@Component({
  selector: 'cog-page-content-header-icon',
  template: `
    <ng-content></ng-content>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class PageContentHeaderIconComponent {
}

/**
 * @description
 * Used for placing tag inside next to header title in `PageContentHeaderComponent`.
 *
 * @example
 *  <cog-page-content>
 *    <cog-page-content-header>
 *      <cog-page-content-header-tag>
 *         <div class="c-task-status-pill">Running</div>
 *       </cog-page-content-header-tag>
 *    </cog-page-content-header>
 *  </cog-page-content>
 */
@Component({
  selector: 'cog-page-content-header-tag',
  template: `
    <ng-content></ng-content>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class PageContentHeaderTagComponent {
}

/**
 * @description
 * Used for placing subtitle in `PageContentHeaderComponent`.
 *
 * @example
 *  <cog-page-content>
 *    <cog-page-content-header>
 *      <cog-page-content-header-subtitle>
 *        Page header subtitle
 *      </cog-page-content-header-subtitle>
 *    </cog-page-content-header>
 *  </cog-page-content>
 */
@Component({
  selector: 'cog-page-content-header-subtitle',
  template: `
    <ng-content></ng-content>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class PageContentHeaderSubtitleComponent {}

/**
 * @description
 * Used for placing controls on the right side inside `PageContentHeaderComponent`.
 *
 * @example
 *  <cog-page-content>
 *    <cog-page-content-header>
 *      <cog-page-content-header-controls>
 *        <button>Edit Page</button>
 *      </cog-page-content-header-controls>
 *    </cog-page-content-header>
 *  </cog-page-content>
 */
@Component({
  selector: 'cog-page-content-header-controls',
  template: `
    <ng-content></ng-content>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class PageContentHeaderControlsComponent {}

/**
 * @description
 * Used for placing horizontal navigation below the page title heading in `PageContentHeaderComponent`.
 *
 * @example
 *  <cog-page-content>
 *    <cog-page-content-header>
 *      <cog-page-content-header-navigation>
 *        <nav mat-tab-nav-bar>
 *          <span mat-tab-link>Home</span>
 *          <span mat-tab-link>Products</span>
 *          <span mat-tab-link>About</span>
 *        </nav>
 *      </cog-page-content-header-navigation>
 *    </cog-page-content-header>
 *  </cog-page-content>
 */
@Component({
  selector: 'cog-page-content-header-navigation',
  template: `
    <ng-content></ng-content>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class PageContentHeaderNavigationComponent {
  /**
   * By default navigation included in PageContentHeaderComponent will have
   * primary navigation styling. This can be opted out of via the
   * [isPrimary]="false" input.
   */
  @HostBinding('class.cog-page-content-header-navaigation-primary')
  @Input() isPrimary = true;
}

/**
 * @description
 * Page content header used within `PageContentComponent`
 *
 * @example
 *  <cog-page-content>
 *    <cog-page-content-header
 *      title="Page Header Title"
 *      subtitle="Page Header Subtitle"
 *      icon="helix:group-nas">
 *    </cog-page-content-header>
 *  </cog-page-content>
 */
@Component({
  selector: 'cog-page-content-header',
  templateUrl: './page-content-header.component.html',
  styleUrls: ['./page-content-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PageContentHeaderComponent {
  /**
   * ContentChild for header icon component.
   */
  @ContentChild(PageContentHeaderIconComponent, { static: false })
  headerIcon: PageContentHeaderIconComponent;

  /**
   * ContentChild for header tag component.
   */
  @ContentChild(PageContentHeaderTagComponent, { static: false })
  headerTag: PageContentHeaderTagComponent;

  /**
   * ContentChild for header subtitle component.
   */
  @ContentChild(PageContentHeaderSubtitleComponent, { static: false })
  headerSubtitle: PageContentHeaderSubtitleComponent;

  /**
   * ContentChild for header controls container.
   */
  @ContentChild(PageContentHeaderControlsComponent, { static: false })
  headerControls: PageContentHeaderControlsComponent;

  /**
   * ContentChild for header navigation.
   */
  @ContentChild(PageContentHeaderNavigationComponent, { static: false })
  headerNavigation: PageContentHeaderNavigationComponent;

  /**
   * Header title text.
   */
  @Input() title: string;

  /**
   * Header subtitle text.
   * Optionally use `PageContentHeaderSubtitleComponent` to project more complex content in subtitle space.
   */
  @Input() subtitle: string;

  /**
   * Icon shape name.
   * Optionally use `PageContentHeaderIconComponent` to project more complex content in icon space.
   */
  @Input() icon: string;

  /**
   * True if header title is in HTML.
   */
  @Input() htmlTitle = false;
}
