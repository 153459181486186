/**
 * Production license for yfiles
 */
export const yfilesProdLicense = {
  company: 'Cohesity',
  contact: 'Satish Menon',
  date: '06/19/2024',
  distribution: true,
  domains: ['cohesity.com'],
  email: 'satish.menon@cohesity.com',
  expires: '07/20/2025',
  fileSystemAllowed: false,
  licensefileversion: '1.1',
  localhost: false,
  oobAllowed: false,
  package: 'complete',
  product: 'yFiles for HTML',
  projectname: 'Floating Developer Seats',
  subscription: '06/20/2025',
  type: 'project',
  version: '2.6',
  key: 'ccb7ea087b68426b688e93bd6414c189630a4963',
};
