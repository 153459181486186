<cog-settings-list-item
  [formGroup]="formGroup"
  [invalid]="diskFilterFormGroup.invalid"
  [label]="'includeOrExcludeDisks' | translate">
  <cog-settings-list-item-value-display>
    <ng-container *ngIf="diskFilterFormGroup.value.enableDiskFilter">
      <ng-container [ngSwitch]="diskFilterFormGroup.value.type">
        <span *ngSwitchCase="'include'">
          {{'includedDisks' | label: (getDiskAddress(diskFilterFormGroup.value.disks) | simpleList)}}
        </span>
        <span *ngSwitchCase="'exclude'">
          {{'excludedDisks' | label: (getDiskAddress(diskFilterFormGroup.value.disks) | simpleList)}}
        </span>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!diskFilterFormGroup.value.enableDiskFilter">
      {{'no' | translate}}
    </ng-container>
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor [formGroup]="diskFilterFormGroup">
    <mat-slide-toggle class="margin-top-sm" formControlName="enableDiskFilter"
      cogDataId="enable-disk-inclusion-exclusion"
      [inheritDataId]="true">
      {{'enableDiskInclusionExclusion' | translate}}
    </mat-slide-toggle>

    <cog-banner class="margin-top">
      <p>{{'job.settings.exclusionInclusion.hyperv.selectGlobalDisks' | translate}}</p>
    </cog-banner>

    <div class="c-tile" *ngIf="diskFilterFormGroup.value.enableDiskFilter">
      <mat-radio-group class="margin-top" formControlName="type">
        <mat-radio-button cogDataId="include-disks-radio-button" [inheritDataId]="true" value="include">
          {{'include' | translate}}
        </mat-radio-button>
        <div class="select-disks-container">
          <mat-hint class="margin-bottom">{{'job.settings.exclusionInclusion.hyperv.includeHint' | translate}}</mat-hint>
          <ng-container *ngIf="diskFilterFormGroup.value.type === 'include'">
            <ng-container *ngTemplateOutlet="selectDisks"></ng-container>
          </ng-container>
        </div>
        <mat-radio-button cogDataId="exclude-disks-radio-button" [inheritDataId]="true" value="exclude">
          {{'exclude' | translate}}
        </mat-radio-button>
        <div class="select-disks-container">
          <mat-hint class="margin-bottom">{{'job.settings.exclusionInclusion.hyperv.excludeHint' | translate}}</mat-hint>
          <ng-container *ngIf="diskFilterFormGroup.value.type === 'exclude'">
            <ng-container *ngTemplateOutlet="selectDisks"></ng-container>
          </ng-container>
        </div>
      </mat-radio-group>

      <ng-template #selectDisks>
        <div *ngFor="let diskFormGroup of disksControl.controls; index as i;"
          [formGroup]="diskFormGroup">
          <mat-form-field class="controller-type-field">
            <mat-select [formControl]="diskFormGroup.get('controllerType')"
              id="controller-type-id"
              [inheritDataId]="true"
              cogDataId="select"
              placeholder="{{'controllerType' | translate}}">
              <mat-option *ngFor="let controllerType of controllerTypes"
                cogDataId="option-{{controllerType}}"
                [inheritDataId]="true"
                [value]="controllerType">
                {{'enum.controllerType.' + controllerType | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="controller-number-field">
            <mat-label translate>controllerNumber</mat-label>
            <input [formControl]="diskFormGroup.get('controllerNumber')"
              [inheritDataId]="true"
              cogDataId="controller-number-value"
              matInput
              min="0"
              required
              type="number">
            <mat-error>{{'errors.required' | translate}}</mat-error>
          </mat-form-field>
          <mat-form-field class="unit-number-field">
            <mat-label translate>unitNumber</mat-label>
            <input [formControl]="diskFormGroup.get('unitNumber')"
              [inheritDataId]="true"
              cogDataId="unit-number-value"
              matInput
              min="0"
              required
              type="number">
            <mat-error>{{'errors.required' | translate}}</mat-error>
          </mat-form-field>
          <button (click)="removeDiskFormControl(i)"
            cogDataId="remove-button"
            mat-icon-button
            type="button">
            <cog-icon shape="clear"></cog-icon>
          </button>
        </div>
        <button (click)="addDiskFormControl()"
          [inheritDataId]="true"
          cogDataId="add-button"
          class="margin-bottom-sm"
          color="primary"
          mat-stroked-button
          type="button">
          <cog-icon shape="add"></cog-icon>
          {{'add' | translate}}
        </button>
      </ng-template>
    </div>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
