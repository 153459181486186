<!-- Exchange Popover Template -->
<h2 class="title">{{'sources.exchange.databaseDetails' | translate}}</h2>
<div class="source-meta-grouping">
  <h3 class="no-margin-bottom">{{'protection' | translate}}</h3>
  <div class="source-meta-line-item">
    {{'sourceTreePub.tooltips.' + (isNodeProtected ? 'protected' : 'notProtected') | translate}}
  </div>

  <div class="source-meta-grouping margin-top-lg" *ngIf="exchangeDbInfo">
    <h3 class="no-margin-bottom">{{'databaseInformation' | translate}}</h3>

    <mat-accordion [multi]="true">
      <mat-expansion-panel class="mat-elevation-z0"
        *ngFor="let dbInfo of exchangeDbInfo; first as isFirst"
        [expanded]="isFirst"
        [hideToggle]="exchangeDbInfo.length === 1">
        <mat-expansion-panel-header expandedHeight="2.5rem" collapsedHeight="2rem">
          <mat-panel-title>
            <cog-icon shape="helix:server"></cog-icon>
            <span class="margin-left-xs">{{dbInfo.serverName || dbInfo.name}}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul class="db-info-list">
          <li *ngIf="dbInfo.isActiveCopy" class="source-meta-line-item">
            <span class="active-copy">{{'activeCopy' | translate}}</span>
          </li>
          <li *ngIf="dbInfo.databaseState as dbState"
            class="source-meta-line-item">
            <span class="source-meta-label">{{'state' | translate}}</span>
            {{'enum.exchange.databaseState.' + dbState | translate}}
          </li>
          <li class="source-meta-line-item">
            <span class="source-meta-label">{{'availableForBackup' | translate}}</span>
            {{dbInfo.backupSupported | boolLabel}}
            <cog-icon *ngIf="dbInfo.backupUnsupportedReasons"
              shape="helix:status-error!critical" size="sm" [inline]="true"
              [matTooltip]="dbInfo.backupUnsupportedReasons"></cog-icon>
          </li>
          <li *ngIf="dbInfo.dbguid as dbguid"
            class="source-meta-line-item">
            <span class="source-meta-label">{{'guid' | translate}}</span>
            {{dbguid}}
          </li>
          <li *ngIf="dbInfo.dbSizeBytes as dbSizeBytes"
            class="source-meta-line-item">
            <span class="source-meta-label">{{'size' | translate}}</span>
            {{dbSizeBytes | byteSize}}
          </li>
          <li *ngIf="dbInfo.createdTimeMsecs as createdTimeMsecs"
            class="source-meta-line-item">
            <span class="source-meta-label">{{'creationDate' | translate}}</span>
            {{(createdTimeMsecs * 1000) | cohDate}}
          </li>
        </ul>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
