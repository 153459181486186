
<button mat-stroked-button
  *ngIf="showAddNewVault"
  type="button"
  cogDataId="add-new-vault"
  color="primary"
  (click)="addNewVault.emit()">
  {{'createCloudVault' | translate}}
</button>

<cog-table [source]="vaults" name="rpaas-vaults-table">
  <table mat-table matSort>

    <!-- Vault Name -->
    <ng-container matColumnDef="vaultName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="vaultName">
        {{'name' | translate}}
      </th>
      <td mat-cell *matCellDef="let row">
        <strong>{{row.vaultName}}</strong>
      </td>
    </ng-container>

    <!-- Sotrage Class -->
    <ng-container matColumnDef="storageClass">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="storageClass">
        {{'storageClass' | translate}}
      </th>
      <td mat-cell *matCellDef="let row">
        <strong>{{ 'externalTargets.storageClass.Rpaas.' + row.storageClass | translate }}</strong>
      </td>
    </ng-container>

    <!-- Vault Type -->
    <ng-container matColumnDef="encryption">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="encryption">
        {{'encryption' | translate}}
      </th>
      <td mat-cell *matCellDef="let row">
        <span *ngIf="row.regionProvisionType; else notAvailable">
          {{'dms.encryptionType.' + row.regionProvisionType | translate}}
        </span>
        <ng-template #notAvailable>
          {{'naNotAvailable' | translate}}
        </ng-template>
      </td>
    </ng-container>

    <!-- Region Name -->
    <ng-container matColumnDef="regionId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="regionId">
        {{'region' | translate}}
      </th>
      <td mat-cell *matCellDef="let row">
        <strong>{{(row.regionId ? row.regionId : 'unknown') | translate}}</strong>
      </td>
    </ng-container>

    <!-- Encryption - Add this when/if it becomes available -->
    <!-- <ng-container matColumnDef="encryption">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="encryption">
        {{'encryptionOption' | translate}}
      </th>
      <td mat-cell *matCellDef="let row">
        <span *ngIf="!!row.encryptionType; else blank">
          {{'dms.encryptionType.' + row.encryptionType | translate}}
        </span>
      </td>
    </ng-container> -->

    <!-- Data Stored -->
    <ng-container matColumnDef="dataStored">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="dataStored">
        {{'dataStored' | translate}}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ usageByRegion[row.vaultName] !== undefined ?
          (usageByRegion[row.vaultName] | byteSize) :
          ('naNotAvailable' | translate)
        }}
      </td>
    </ng-container>

    <!-- Status -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>
        {{'status' | translate}}
      </th>
      <td mat-cell *matCellDef="let row">
        <div class="status" *ngIf="row.provisionStatus">
          <cog-spinner *ngIf="isProvisioningInProgress(row)" size="sm" inline="true">
          </cog-spinner>
          <cog-icon *ngIf="!isProvisioningInProgress(row)"
            class="margin-right-sm"
            size="md"
            [shape]="isMarkedForDeletion(row) || isProvisioningFailed(row) ?
              'helix:status-error!critical' : 'helix:status-success!success'">
          </cog-icon>
          <span [matTooltip]="row.provisionStatus.message">
            {{'dms.provisionStatus.' + row.provisionStatus.status | translate}}
          </span>
        </div>
      </td>
    </ng-container>

    <!-- Pairing Status -->
    <ng-container matColumnDef="pairingStatus">
      <th mat-header-cell *matHeaderCellDef>
        {{'pairedClusters' | translate}}
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="clusterCount && row.provisionStatus?.status === 'Completed'; else na">
          <div class="status" *ngIf="getPairingStatus(row); let pairingStatus">
            <cog-spinner *ngIf="pairingStatus === 'InProgress' || pairingStatus === 'Waiting'" size="sm" inline="true">
            </cog-spinner>
            <cog-icon *ngIf="pairingStatus !== 'InProgress' || pairingStatus === 'Waiting'"
              class="margin-right-sm"
              size="md"
              [shape]="pairingStatus === 'Failed' ? 'helix:status-error!critical' : 'helix:status-success!success'">
            </cog-icon>

            <a *ngIf="getPopoverComponent(row); let popover; else count"
              cogDataId="{{row.vaultName}}-pairing-detail"
              [cogPopover]="popover"
              [popoverData]="{vault: row, clusters: clusters}">
              <ng-container *ngTemplateOutlet="count"></ng-container>
            </a>
            <ng-template #count>
              {{'nOfTotal' | translate : {n: getCountByStatus(row, ['Completed']), total: clusterCount} }}
            </ng-template>
          </div>
        </ng-container>
        <ng-template #na>{{'naNotAvailable' | translate }}</ng-template>
      </td>
    </ng-container>

    <!-- Backup Window -->
    <ng-container matColumnDef="backupWindow">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'vaultingWindow' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <span cogDataId="{{row.vaultName}}-backup-window-status">
          {{ (backupWindowConfig?.[row.globalVaultId]?.transferTimes?.length > 0 ? 'enabled' : 'disabled') | translate }}
        </span>
      </td>
    </ng-container>

    <!-- Actions Menu -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="actions-col">
        <coh-vault-table-actions [vault]="row"
          (selected)="handleMenuAction($event)"
          cogDataId="{{row.vaultName}}-actions">
        </coh-vault-table-actions>
      </td>
    </ng-container>

    <!-- Vaults Table Definitions -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row
      *matRowDef="let row; columns: displayedColumns"
      [class.active]="row.regionId === activeVaultId"
      class="row-row">
    </tr>
  </table>
</cog-table>

<ng-template #blank>
  {{'notApplicable' | translate}}
</ng-template>
