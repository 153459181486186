import { Directive, ElementRef, EventEmitter, Host, HostListener, Inject, Input, Output } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';

/**
 * This directive is used to apply form validation on submit. This directive should be applied to a <form>
 * element with a reactiveForm formGroup set. It listens for the ngSubmit event and will force validation
 * errors to show by marking all elements as touched. If there is an error, it will scroll to the first
 * element with an error and set focus to it. If there is no error, it will emit the validatedSubmit
 * event.
 *
 * @example
 * <form cohValidateOnSubmit (validatedSubmit)="onSubmit()" [formGroup]="formGroup">
 *   ...
 * </form>
 *
 * onSubmit() {
 *   // the form is guaranteed to be validated.
 * }
 */
@Directive({
  selector: 'form [formGroup] [cohValidateOnSubmit]',
})
export class ValidateOnSubmitDirective {
  /**
   * Checking only for ng-invalid isn't sufficient here, because it will also return form group components
   * rather than individual controls. The default behavior is to look for invalid controls inside of a
   * mat-form-field element, but it is exposed as an input in case it needs to be modified.
   */
  @Input() invalidElementSelector = '.mat-form-field .ng-invalid';

  /**
   * Instead of using (ngSubmit) on the form, use (validatedSubmit) and which will only trigger if a form is
   * valid.
   */
  @Output() validatedSubmit = new EventEmitter();

  /**
   * Use (validationErrors), It will let host form handle some additional checks or show warnings.
   * This is specially useful when the fields on the form are not visible.
   * For e.g. In Simulation, we need atleast ONE event to run.
   * the form is already marked invalid but query selector will fail since there are no fields on the form yet.
   * So capture this in the host form and perform check to show additional error message.
   */
  @Output() validationErrors = new EventEmitter();

  constructor(
    @Inject(FormGroupDirective) @Host() private ngForm: FormGroupDirective,
    private el: ElementRef,
    private logger: NGXLogger) { }

  /**
   * Listen for the form submission, check validation, scroll errored elements into view and emite the
   * validatedSubmit event if the form is valid.
   */
  @HostListener('ngSubmit')
  onFormSubmit() {
    // Mark the form as touched, which should force any form validation errors to surface.
    this.ngForm.form.markAllAsTouched();
    if (this.ngForm.form.invalid) {

      // Find all elements marked invalid
      const invalidControls = this.el.nativeElement.querySelectorAll(this.invalidElementSelector);

      // Loop over to show mat-errors since markAllAsTouched doesn't show mat-error in ngx-sub-form
      if (invalidControls && invalidControls.length > 0) {
        invalidControls.forEach(control => {
          control.focus();
          control.blur();
        });

        // Get the first one to scroll to and focus
        const firstControl = invalidControls[0];
        firstControl.focus();
        try {
          firstControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } catch (e) {
          // this fails on some jest tests.
        }
      } else {
        // This is most likely an error with the UI code. A form control in
        // error state should be in the dom.
        this.logger.warn('Cannot find invalid form control in the DOM.');
      }
      this.validationErrors.emit();
    } else {
      this.validatedSubmit.emit();
    }
  }
}
