import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { AccessScope, AccessScopesApiService, Resource, ResourcesApiService } from '@cohesity/api/guardian';
import { DataIdModule, SnackBarService, SpinnerModule } from '@cohesity/helix';
import { getMcmAccountId, getUserTenantId, hasPrivilege, IrisContextService } from '@cohesity/iris-core';
import {
  CohesitySharedFormsModule,
  DataIdProviderFn,
  OnChange,
  OnTouched,
  SearchFn,
  valueAccessorProvider,
} from '@cohesity/shared-forms';
import { AjaxHandlerService, ClearSubscriptions, DialogService } from '@cohesity/utils';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, finalize, switchMap } from 'rxjs/operators';

import {
  AccessScopeDialogData,
  AccessScopeDialogResponse,
  AddUpdateAccessScopeDialogComponent,
} from '../add-update-access-scope-dialog/add-update-access-scope-dialog.component';

/**
 * Form type for access group selector
 */
export interface ScopeSelectorForm {
  /**
   * Indicates whether access scope selection is enabled
   */
  enableAccessScope: boolean;

  /**
   * Selected access scopes
   */
  accessScopes: AccessScope[];
}

/**
 * Default value of CVA control
 */
const defaultFormValue: ScopeSelectorForm = { enableAccessScope: false, accessScopes: [] };

@Component({
  standalone: true,
  imports: [
    DataIdModule,
    CommonModule,
    TranslateModule,
    CohesitySharedFormsModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    SpinnerModule,
  ],
  selector: 'coh-access-scope-selector',
  templateUrl: './access-scope-selector.component.html',
  styleUrls: ['./access-scope-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [valueAccessorProvider(AccessScopeSelectorComponent)],
})
export class AccessScopeSelectorComponent
  extends ClearSubscriptions
  implements AfterViewInit, ControlValueAccessor, OnInit {
  /**
   * List of access scopes
   */
  accessScopes$ = new BehaviorSubject<AccessScope[]>([]);

  /**
   * Form group for access scope selection form
   */
  formGroup = new FormGroup({
    enableAccessScope: new FormControl(false),
    accessScopes: new FormControl<AccessScope[]>([]),
  });

  /**
   * Indicates whether the component data is loading.
   */
  readonly loading$ = new BehaviorSubject<boolean>(false);

  /**
   * Callback to pass the change event back to a form control.
   */
  private onChange: OnChange<Partial<ScopeSelectorForm>>;

  /**
   * Callback to pass the onTouch event back to a form control. Defaults to a noop.
   */
  private onTouch: OnTouched;

  /**
   * Returns whether user has privilege to manage access scopes
   */
  get hasManagePrivilege(): boolean {
    return hasPrivilege(this.irisCtx.irisContext, 'ACCESS_SCOPE_MANAGE');
  }

  constructor(
    private ajaxHandler: AjaxHandlerService,
    private accessScopesApiService: AccessScopesApiService,
    private dialogService: DialogService,
    private irisCtx: IrisContextService,
    private resourcesApiService: ResourcesApiService,
    private snackbar: SnackBarService,
    private translate: TranslateService
  ) {
    super();
    this.fetchData();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.formGroup.controls.enableAccessScope.valueChanges.subscribe(enabled => {
        if (!enabled) {
          this.formGroup.controls.accessScopes.setValue(null);
        }
      })
    );
  }

  ngAfterViewInit() {
    this.subscriptions.push(
      this.formGroup.valueChanges.subscribe(newValue => {
        this.onChange(newValue);
        this.onTouch();
      })
    );
  }

  /**
   * Fetch data from backend
   */
  fetchData() {
    const params = {
      account_id: getMcmAccountId(this.irisCtx.irisContext),
      tenant_id: getUserTenantId(this.irisCtx.irisContext),
    };

    const subscription = this.accessScopesApiService.getAccessScopes(params).subscribe(({ accessScopes }) => {
      this.accessScopes$.next(accessScopes || []);
    }, this.ajaxHandler.handler);

    this.subscriptions.push(subscription);
  }
  /**
   * The comparator function for the access scopes dropdown.
   *
   * @returns true if options are identical
   */
  scopeComparatorFn(scope1: AccessScope, scope2: AccessScope): boolean {
    return scope1 && scope2 && scope1?.id === scope2?.id;
  }

  /**
   * The search function used by the searchable select component.
   */
  readonly entityDisplayNameSearchFn: SearchFn<AccessScope> = (items, term) =>
    items.filter(item => item.name.toLocaleLowerCase().includes(term.toLocaleLowerCase()));

  /**
   * The data-id generator function for searchable select component.
   */
  readonly optionDataIdGeneratorFn: DataIdProviderFn<AccessScope> = item => `access-scope-selector-option-${item.name}`;

  /**
   * Handler for the 'click' event on the 'Add access scope' dropdown item.
   */
  onAddAccessScopeClick(): void {
    this.loading$.next(true);

    const params = {
      account_id: getMcmAccountId(this.irisCtx.irisContext),
      tenant_id: getUserTenantId(this.irisCtx.irisContext),
    };
    const subscription = this.resourcesApiService
      .getResources(params)
      .pipe(
        finalize(() => {
          this.loading$.next(false);
        }),
        switchMap(({ resources }) => this.openAddAccessScopeDialog(resources))
      )
      .subscribe(({ accessScope }: AccessScopeDialogResponse) => {
        this.accessScopes$.next([accessScope, ...this.accessScopes$.value]);
        this.snackbar.open(this.translate.instant('helios.accessManagement.accessScope.creationSuccess'));
      }, this.ajaxHandler.handler);

    this.subscriptions.push(subscription);
  }

  /**
   * Open dialog to create a new access scope
   *
   * @param resources List of resources
   */
  openAddAccessScopeDialog(resources: Resource[]): Observable<AccessScopeDialogResponse> {
    return this.dialogService
      .open<AddUpdateAccessScopeDialogComponent, AccessScopeDialogResponse, AccessScopeDialogData>(
        AddUpdateAccessScopeDialogComponent,
        {
          data: {
            mode: 'add',
            resources: resources,
            selectedResources: [],
            resourcesByIdMap: new Map((resources || []).map(resource => [resource.id, resource])),
          },
        }
      )
      .pipe(filter(result => !!result));
  }

  // start: CVA overrides
  writeValue(value: Partial<ScopeSelectorForm>): void {
    if (value?.accessScopes?.length) {
      this.formGroup.setValue({ ...defaultFormValue, ...value, ...{ enableAccessScope: true } }, { emitEvent: false });
    } else {
      this.formGroup.setValue(defaultFormValue, { emitEvent: false });
    }
  }

  registerOnChange(fn: OnChange<Partial<ScopeSelectorForm>>): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: OnTouched): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.disable();
    } else {
      this.formGroup.enable();
    }
  }
  // end: CVA overrides
}
