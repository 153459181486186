import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { DataTreeSelection } from '@cohesity/helix';
import { IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { SourceSelection } from '@cohesity/iris-source-tree';
import { TranslateService } from '@ngx-translate/core';
import { HostType } from 'src/app/shared/constants';

import { BaseProtectionSourceService } from '../../shared/base-protection-source.service';
import { PhysicalFilters } from '../physical.filter';
import { PhysicalFilesHostOptionsComponent } from './physical-files-host-options/physical-files-host-options.component';
import { PhysicalFilesSourceDataNode } from './physical-files-source-data-node';

/**
 * Tree service for Physical Server (File-based)
 */
@Injectable({
  providedIn: 'root',
})
export class PhysicalFilesSourceTreeService extends BaseProtectionSourceService<PhysicalFilesSourceDataNode> {
  /**
   * A reference to physical view filters..
   */
  physicalFilters: PhysicalFilters;

  constructor(private translate: TranslateService, private irisContextService: IrisContextService) {
    super();
    const allowedOSes = [HostType.kLinux, HostType.kWindows];

    if (!isDmsScope(this.irisContextService.irisContext)) {
      allowedOSes.push(HostType.kAix, HostType.kSolaris, HostType.kHPUX, HostType.kVOS);
    }
    this.physicalFilters = new PhysicalFilters(this.filters, allowedOSes);
  }

  /**
   * Gets a component to render for a source's special parameters. This does not apply to every node
   * and can be null for certain data types.
   */
  getSpecialParametersComponent(node: PhysicalFilesSourceDataNode): ComponentType<any> {
    if (node.isPhysicalLeafEntity) {
      return PhysicalFilesHostOptionsComponent;
    }
    return null;
  }

  /**
   * Only expand certain node types by default.
   *
   * @param   treeNode   The treenode to check.
   * @return  True if the node should be expanded by default.
   */
  shouldExpandNodeOnLoad(treeNode: PhysicalFilesSourceDataNode): boolean {
    return treeNode.isRoot;
  }

  /**
   * Transforms the node object from the api into a Protection Source Tree node to pass to the tree.
   *
   * @param   node   The original node.
   * @param   level  The level in the tree.
   * @return  An PhysicalFilesSourceDataNode that can be displayed in the tree.
   */
  transformData(node: ProtectionSourceNode, level: number): PhysicalFilesSourceDataNode {
    return new PhysicalFilesSourceDataNode(node, level, this.irisContextService);
  }

  /**
   * Convert the data tree selection model to the job selection model.
   *
   * @param   selection   The selection from the tree.
   * @return  The job selection info.
   */
  transformFromDataTreeSelection(selection: DataTreeSelection<PhysicalFilesSourceDataNode>): SourceSelection {
    // Sources include explicitly selected leaf node, and auto selected items
    const sources = selection.selected.filter(item => item.isPhysicalLeafEntity);

    return {
      // source ids
      sourceIds: sources.map(item => Number(item.id)),

      // Special source params
      sourceSpecialParameters: Object.values(selection.options || {}).filter(Boolean),
    };
  }

  /**
   * Convert source selection to the data tree selection model.
   * source ids can be either selected items or auto selected items, nodes with children are
   * assumed to be auto selected. Nodes can be in the tree multiple times, and should not be
   * duplicated in the selection.
   *
   * @param   allNodes         The unfiltered list of tree nodes.
   * @param   sourceSelection  The job selection.
   * @return  A data tree selection model.
   */
  transformToDataTreeSelection(
    allNodes: PhysicalFilesSourceDataNode[],
    sourceSelection: SourceSelection
  ): DataTreeSelection<PhysicalFilesSourceDataNode> {
    const treeSelection = super.transformToDataTreeSelection(allNodes, sourceSelection);
    allNodes.forEach(node => {
      if ((sourceSelection.sourceIds || []).includes(Number(node.id))) {
        node.inCurrentJob = true;
      }
    });
    return treeSelection;
  }
}
