import { AfterViewInit, Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';
import { CityData, McmLocationServiceApi } from '@cohesity/api/private';
import { ClearSubscriptions } from '@cohesity/utils';
import { ObservableInput } from 'ngx-observable-input';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, switchMap } from 'rxjs/operators';

import { OnChange, OnTouched, TypedControlValueAccessor } from '../typed-control-value-accessor';
import { locationValidator } from '../validators/location.validator';

/**
 * The debounce time in milliseconds to perform the search.
 */
const searchDebounceTimeMs = 700;

/**
 * The minimum length of the search term to initiate a search.
 */
const minSearchTermLen = 3;

/**
 * The maximum number of suggestions to fetch from the API.
 */
const maxSearchResults = 50;

/**
 * Renders a CVA enabled form control which allows users to pick a location
 * from an autocompleted list.
 *
 * @example
 * <coh-location-selector [formControl]="formControl"></coh-location-selector>
 */
@Component({
  selector: 'coh-location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LocationSelectorComponent), multi: true }],
})
export class LocationSelectorComponent
  extends ClearSubscriptions
  implements TypedControlValueAccessor<CityData>, AfterViewInit, OnInit {
  /**
   * The label to show for the search input.
   */
  @Input() label: string;

  /**
   * Makes this control as mandatory in the form group.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('required') required$: Observable<boolean> = of(false);

  /**
   * The form control for the input box.
   */
  searchInput = new UntypedFormControl('');

  /**
   * The list of all the possible locations feteched using the API.
   */
  readonly locations$ = new BehaviorSubject<CityData[]>([]);

  /**
   * Indicates whether the search is in progress.
   */
  readonly searching$ = new BehaviorSubject<boolean>(false);

  // CVA provided callbacks.
  onChange: OnChange<CityData>;
  onTouched: OnTouched;

  /**
   * Callback for the autocomplete to form a string based on the location info.
   *
   * @param val The location object to format.
   * @returns The formatted value to show in the autocomplete dropdown.
   */
  displayWithFn: MatAutocomplete['displayWith'] = (val: CityData) => {
    if (!val || !val.lat || !val.lon) {
      return '';
    }

    return [val?.city, val?.state, val?.country].join(', ');
  };

  constructor(private locationsApi: McmLocationServiceApi) {
    super();
  }

  ngOnInit(): void {
    // Listen to the "required" input and update the validators of the search
    // input.
    const requiredSub = this.required$.subscribe(value => {
      value
        ? this.searchInput.addValidators([Validators.required, locationValidator])
        : this.searchInput.removeValidators([Validators.required, locationValidator]);

      this.searchInput.updateValueAndValidity();
    });

    this.subscriptions.push(requiredSub);
  }

  ngAfterViewInit(): void {
    // Listen to the changes on search input and search for matching locations.
    const searchLocationsSub = (this.searchInput.valueChanges as Observable<string | CityData>)
      .pipe(
        debounceTime(searchDebounceTimeMs),
        distinctUntilChanged(),
        switchMap(value => {
          if (typeof value === 'string') {
            return this.getLocationsByTerm(value);
          }

          this.onChange(value);
          return of(null);
        }),
        filter((data: CityData[] | null) => data !== null)
      )
      .subscribe(locations => this.locations$.next(locations));

    this.subscriptions.push(searchLocationsSub);
  }

  // Implementation of ControlValueAccessor methods.

  writeValue(value: CityData): void {
    this.searchInput.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: OnChange<CityData>): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: OnTouched): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.searchInput.disable() : this.searchInput.enable();
  }

  /**
   * Retrieves the locations matching the provided search term.
   *
   * @param term The term to search with.
   * @returns An observable of the location objects.
   */
  private getLocationsByTerm(term: string): Observable<CityData[]> {
    if (term?.trim().length < minSearchTermLen) {
      return of([]);
    }

    this.searching$.next(true);

    return this.locationsApi.fetchLocations({ search: term.toLocaleLowerCase(), limit: maxSearchResults }).pipe(
      catchError(() => []),
      finalize(() => this.searching$.next(false))
    );
  }
}
