import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import dayjs from 'dayjs/esm';

import { BaseProtectionBuilderComponent } from '../../base-protection-builder/base-protection-builder.component';

export interface EndDate {
  enabled: boolean;
  value?: Date;
}

/**
 * Default value for end date.
 */
export const DefaultEndDate: EndDate = {
  enabled: false,
};

@Component({
  selector: 'coh-settings-list-end-date',
  templateUrl: './settings-list-end-date.component.html',
  styleUrls: ['./settings-list-end-date.component.scss'],
})
export class SettingsListEndDateComponent
  extends BaseProtectionBuilderComponent<EndDate, any> {
  /**
   * End date as per requirement is after 1 year.
   */
  readonly endDate = dayjs().add(1, 'years').toDate();

  /**
   * By default end date is disabled.
   */
  _value: EndDate = DefaultEndDate;

  /**
   * Form group to hold end date value.
   */
  endDateFormGroup = new UntypedFormGroup({
    enabled: new UntypedFormControl(this.value.enabled, Validators.required),
  });

  /**
   * Attach end date form group to parent adapter form.
   */
  addFormControl() {
    this.formGroup.addControl(this.name, this.endDateFormGroup);
  }

  /**
   * Form control init.
   */
  initFormControl() {
    this.endDateFormGroup.get('enabled').valueChanges.subscribe(value => {
      if (value) {
        this.endDateFormGroup.addControl(
          'value', new UntypedFormControl(this.endDate, Validators.required)
        );
      } else {
        this.endDateFormGroup.removeControl('value');
      }
    });

    if (this.protectionGroup && this.protectionGroup.endTime) {
      this.value = {
        enabled: true,
        value: this.protectionGroup.endTime,
      };

      this.formControl.setValue(this.value);
    }
  }
}
