import { ENTER } from '@angular/cdk/keycodes';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FileExtensionFilter } from '@cohesity/api/v2';
import { CreateForm, createFormProviders } from '@cohesity/shared-forms';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { AllFilters } from 'src/app/modules/views/components';
import { REGEX_FORMATS } from 'src/app/shared/constants/formats.constants';

@Component({
  selector: 'coh-antivirus-scan-filter',
  templateUrl: './antivirus-scan-filter.component.html',
  providers: createFormProviders(AntivirusScanFilterComponent),
})
export class AntivirusScanFilterComponent implements OnInit {
  /**
   * Determines whether the antivirus scan filter should be readonly or not.
   */
  @Input() readOnly = false;

  /**
   * Specifies whether only S3 protocol is selected or not.
   */
  @Input() isS3Only = false;

  constructor() {}

  separatorKeys = [
    {
      key: ENTER,
      char: '\n',
    },
  ];

  /**
   * The form.
   */
  form = new CreateForm<AllFilters>(this, {
    formControls: {
      mode: new UntypedFormControl(null),
      extensionFilterList: new UntypedFormControl(null),
      prefixScanFilterList: new UntypedFormControl(null),
      s3TaggingFilterList: new UntypedFormArray([]),
    },
    transformToFormGroup: this.transformToFormGroup.bind(this),
    transformFromFormGroup: value => value,
  });

  /**
   * Returns the controls of the form.
   *
   * @returns The controls.
   */
  get formGroupControls() {
    return this.form.formGroup.controls;
  }

  /**
   * Returns the extension filter list of type UntypedFormArray.
   */
  get s3TaggingFilterList() {
    return this.formGroupControls.s3TaggingFilterList as UntypedFormArray;
  }

  /**
   * Transforms the API model to form friendly values.
   *
   * @param value The API value
   * @returns The form group value.
   */
  transformToFormGroup(value: AllFilters): AllFilters {
    if (!value) {
      return this.form.formGroup.getRawValue();
    }

    value?.s3TaggingFilterList?.forEach((tag) => {
      const tagGroup = new UntypedFormGroup({
        key: new UntypedFormControl(tag.key, Validators.required),
        value: new UntypedFormControl(tag.value, Validators.required),
      });
      this.s3TaggingFilterList.push(tagGroup);
    });

    return value;
  }

  /**
   * Init Component.
   */
  ngOnInit() {
    const initialModeValue = this.formGroupControls.mode.value;
    (this.formGroupControls.mode.valueChanges as Observable<any>)
      .pipe(startWith(initialModeValue))
      .subscribe((modeValue: FileExtensionFilter['mode']) => {
        if (!modeValue) {
          return;
        }
        // Resetting the file extension list in case of selecting all entities mode.
        this.formGroupControls.extensionFilterList.setValue([]);
        this.formGroupControls.prefixScanFilterList.setValue([]);
        this.s3TaggingFilterList.clear();
      });
  }

  /**
   * Validates the file extension value.
   *
   * @param element The file extension.
   * @returns True if the file extension is valid, false otherwise.
   */
  fileExtensionValidatorFn(element: string): boolean {
    return REGEX_FORMATS.alphanumeric.test(element);
  }

  /**
   * Transforms the file extension chip value to remove the '.' from the beginning.
   *
   * @param element
   * @returns
   */
  fileExtensionChipTransformer(element: string): string {
    if(element.startsWith('.')) {
      return element.slice(1);
    }
    return element;
  }

  /**
   * Adds tag set value to s3TaggingFilter form.
   */
  addTagSet() {
    this.s3TaggingFilterList.push(
      new UntypedFormGroup({
        key: new UntypedFormControl(null, Validators.required),
        value: new UntypedFormControl(null, Validators.required),
      })
    );
  }

  /**
   * Removes tag set value from s3TaggingFilter form.
   *
   * @param index The index of the file extension to remove.
   */
  removeTagSet(index: number) {
    this.s3TaggingFilterList.removeAt(index);
  }
}
