import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AzureProtectionSource, ProtectionSourceNode } from '@cohesity/api/v1';
import { AutoDestroyable } from '@cohesity/utils';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'coh-app-private-endpoints-row-recover',
  templateUrl: './private-endpoints-row-recover.component.html',
  styleUrls: ['./private-endpoints-row-recover.component.scss']
})
export class PrivateEndpointsRowRecoverComponent extends AutoDestroyable implements OnInit {
  /**
   * FormGroup for this component.
   */
  @Input() privateEndpointField: UntypedFormGroup;

  /**
   * The original protection source.
   */
  @Input() protectionSource$: Observable<ProtectionSourceNode>;

  /**
   * Indicates if new location recovery is selected.
   */
  @Input() isNewLocationRecovery = false;

  /**
   * The new source selected for recovery.
   */
  @Input() destinationSourceSelected$: Observable<ProtectionSourceNode>;

  /**
   * The resource group selected for new location recovery.
   */
  @Input() resourceGroupSelected$: Observable<ProtectionSourceNode>;

  /**
   * The list of regions available for selection.
   */
  regions$: Observable<ProtectionSourceNode[]>;

  /**
   * The list of virtual networks available for selection.
   */
  virtualNetworks$: Observable<ProtectionSourceNode[]>;

  /**
   * The list of subnets available for selection.
   */
  subnets$: Observable<ProtectionSourceNode[]>;

  constructor() {
    super();
  }

  ngOnInit() {
    const initialDataTransferInfo = this.privateEndpointField.value;
    // Get all applicable regions using the original source, the new source selected and the selected resource group.
    this.regions$ =
      combineLatest([this.protectionSource$, this.destinationSourceSelected$, this.resourceGroupSelected$]).pipe(
      map(([source, , resourceGroupSelected]) => {
        this.privateEndpointField.reset();

        const availableRegions = this.isNewLocationRecovery ?
          (resourceGroupSelected ? [resourceGroupSelected] : []) :
          this.getNodesByType(source, 'kVirtualMachine');

        // Set default initial value if available.
        if (initialDataTransferInfo?.region &&
          availableRegions.find(
            region => region.protectionSource.azureProtectionSource.location === initialDataTransferInfo?.region)) {
          this.privateEndpointField.get('region').setValue(initialDataTransferInfo?.region);
        }

        return availableRegions;
      }));

    // Get the list of virtual networks that belong to the selected region.
    this.virtualNetworks$ =
      combineLatest([
        this.privateEndpointField.get('region').valueChanges,
        this.protectionSource$,
        this.destinationSourceSelected$]).pipe(
          filter(([region, source, destinationSourceSelected]) => !!region &&
            (this.isNewLocationRecovery ? !!destinationSourceSelected : !!source)),
          map(([region, source, destinationSourceSelected]) => {
            const availableVirtualNetworks = this.getNodesByType(
              this.isNewLocationRecovery ? destinationSourceSelected : source, 'kVirtualNetwork', region);

            // Set default initial value if available.
            if (initialDataTransferInfo?.virtualNetwork &&
              availableVirtualNetworks.find(
                vpn => vpn.protectionSource.id === initialDataTransferInfo?.virtualNetwork)) {
              this.privateEndpointField.get('virtualNetwork').setValue(initialDataTransferInfo?.virtualNetwork);
            }

            return availableVirtualNetworks;
          })
        );

    // Get the list of subnets that belong to the selected virtual network.
    this.subnets$ =
      combineLatest([this.privateEndpointField.get('virtualNetwork').valueChanges, this.virtualNetworks$]).pipe(
      filter(([vid, vn]) => !!vid &&  !!vn),
      map(([vid, vn]) =>  {
        const availableSubnets = this.getNodesByTypeOneLvl(vid, vn, 'kSubnet');

        // Set default initial value if available.
        if (initialDataTransferInfo?.subnet &&
          availableSubnets.find(
            vpn => vpn.protectionSource.id === initialDataTransferInfo?.subnet)) {
          this.privateEndpointField.get('subnet').setValue(initialDataTransferInfo?.subnet);
        }

        return availableSubnets;
      })
    );
  }

  /**
   * Get the child nodes of a parent node given it's id, type of child nodes and the list of nodes.
   *
   * @param id    Id of the parent node.
   * @param nodes List of all nodes.
   * @param type  Type of the child nodes to filter.
   * @returns     The list of child nodes of given type.
   */
  getNodesByTypeOneLvl(
    id: number,
    nodes: ProtectionSourceNode[],
    type: AzureProtectionSource['type']): ProtectionSourceNode[] {
    const vnode  = nodes.find(vn => vn.protectionSource.id === id);

    const result =
      vnode?.nodes?.filter(
        (node: ProtectionSourceNode) => node.protectionSource?.azureProtectionSource?.type === type
      ) || [];

    return result;
  }

  /**
   * Given a tree, get all the nodes of specific type and belong to given location.
   *
   * @param sourceNode  Root node of the tree.
   * @param type        Type of nodes to filter.
   * @param location    Location of the nodes.
   * @returns           The list of child nodes of given type and location.
   */
  getNodesByType(
    sourceNode: ProtectionSourceNode,
    type: AzureProtectionSource['type'],
    location?: string): ProtectionSourceNode[] {
    const result = [];

    sourceNode?.nodes?.forEach((nodeLvlOne: ProtectionSourceNode) => {
      nodeLvlOne.nodes?.forEach((nodeLvlTwo: ProtectionSourceNode) => {
        if (location) {
          if (nodeLvlTwo.protectionSource?.azureProtectionSource?.type === type
            && nodeLvlTwo.protectionSource?.azureProtectionSource?.location === location ) {
            result.push(nodeLvlTwo);
          }
        } else {
          if (nodeLvlTwo.protectionSource?.azureProtectionSource?.type === type) {
            result.push(nodeLvlTwo);
          }
        }
      });
    });

    let uniqueResult = [];

    if (location) {
      this.privateEndpointField.get('virtualNetwork').enable();
    } else {
      this.privateEndpointField.get('region').enable();
      uniqueResult =
      [...new Map(result.map(item => [item.protectionSource.azureProtectionSource.location, item])).values()];
    }

    return location ? result : uniqueResult;
  }
}
