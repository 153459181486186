// Controller: Helios Reports

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.heliosReports', [
      'C.reports',
      'C.mcmJobService',
      'C.HeliosReportsService',
      'C.HeliosReportsServiceFormatter',

      // module needed for filters fields.
      'C.cFilters',
      'ui.select',
      'C.selectCluster',
      'C.inlineDateRange',
      'C.selectSourcePub',
      'C.selectTenant',
      'C.postForm',
      'C.postIframe',
    ])
    .config(ConfigFn)
    .component('heliosReportsTabs', {
      controller: 'HeliosReportsTabsCtrl',
      templateUrl: 'app/reports/helios/helios-reports-tabs.html',
    })
    .controller('HeliosReportsTabsCtrl', HeliosReportsTabsCtrlFn);

  function ConfigFn($stateProvider) {
    var heliosReports = [
      '$root.basicClusterInfo.mcmMode',
      'REPORTS_VIEW',
      '$root.FEATURE_FLAGS.enableHeliosReports'
    ].join(' && ');

    $stateProvider
      .state('helios-reports', {
        url: '/reports',
        help: 'helios_global_reports',
        title: 'Cohesity Reports',
        canAccess: heliosReports,
        parentState: 'reports',
        allClustersSupport: {
          heliosOnly: true,
          singleClusterState: 'reports',
          allClustersState: 'helios-reports',
        },
        component: 'heliosReportsTabs',
      })
      .state('helios-reports.builtin', {
        url: '^/reports/builtin/{reportType}',
        help: 'helios_global_reports',
        title: 'Cohesity Built-In Reports',
        params: {
          reportType: 'top-jobs',
        },
        reloadOnSearch: false,
        canAccess: heliosReports,
        parentState: 'helios-reports',
        allClustersSupport: {
          heliosOnly: true,
          singleClusterState: 'reports',
          allClustersState: 'helios-reports.builtin',
        },
        component: 'heliosBuiltInReports',
        resolve: {
          reportType: function getReportType($transition$) {
            return $transition$.params().reportType;
          }
        },
      })
      .state('helios-reports.custom', {
        url: '^/reports/custom',
        help: 'helios_global_reports',
        title: 'Cohesity Reports',
        canAccess: heliosReports,
        parentState: 'helios-reports',
        allClustersSupport: {
          heliosOnly: true,
          singleClusterState: 'reports',
          allClustersState: 'helios-reports.custom',
        },
        component: 'heliosCustomReports',
      })
      .state('helios-reports.scheduled-emails', {
        url: '^/reports/scheduled',
        help: 'helios_global_reports',
        title: 'Scheduled Emails',
        canAccess: [
          heliosReports,
          '$root.FEATURE_FLAGS.enableHeliosReportsEmailScheduling'
        ].join(' && '),
        parentState: 'helios-reports',
        allClustersSupport: {
          heliosOnly: true,
          singleClusterState: 'reports',
          allClustersState: 'helios-reports.scheduled-emails',
        },
        component: 'heliosReportsEmailSchedules',
      });
  }

  /**
   * Controller for Helios reports tabs.
   */
  function HeliosReportsTabsCtrlFn($state, ClusterService) {
    var $ctrl = this;

    _.assign($ctrl, {
      // Life-Cycle Hooks.
      $onInit: $onInit,
    });

    /**
     * Initialize this controller.
     *
     * @method     $onInit
     */
    function $onInit() {
      if (!ClusterService.clusterInfo._allClusters) {
        $state.go('reports');
        return;
      }

      $ctrl.reportsTabs = [
        {
          headingKey: 'helios.builtinReports',
          route: 'helios-reports.builtin',
        },
        // NOTE in MVP we are not adding custom reporting support.
        // {
        //   headingKey: 'helios.customReports',
        //   route: 'helios-reports.custom',
        // },
        {
          headingKey: 'scheduledEmails',
          route: 'helios-reports.scheduled-emails',
        }
      ];

      // goto builtin reports if landing to parent tabs state.
      if ($state.current.name === 'helios-reports') {
        $state.go('helios-reports.builtin');
      }
    }

  }
})(angular);
