<div class="text-diff-container">
  <div class="filter" *ngIf="enableFiltering">
    <mat-checkbox [formControl]="checkboxControl">{{ filterLabel }}</mat-checkbox>
  </div>

  <div class="code-container"
    [ngClass]="{'side-by-side': sideBySide, 'remove-border-and-spacing': removeBorderAndSpacing}">
    <div class="code">
      <ng-container *ngFor="let item of visibleData$ | async" class="line">
        <div [ngClass]="getClass(item, 'left')">{{ item.value }}</div>
      </ng-container>
    </div>

    <div *ngIf="sideBySide && displayMode === 'default'" class="code">
      <ng-container *ngFor="let item of visibleData$ | async" class="line">
        <div [ngClass]="getClass(item, 'right')">{{ item.value }}</div>
      </ng-container>
    </div>
  </div>
</div>