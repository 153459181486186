import { InjectionToken } from '@angular/core';

import { TimeFormat } from './date.models';

/**
 * Injection token to inject customized time into components.
 */
export const TIME_FORMAT = new InjectionToken<TimeFormat>(null);

/**
 * Matches 12-hour time format.
 */
export const match12HourFormatRegExp = /(0?[0-9]|1[0-2]):([0-5][0-9])(:([0-5][0-9]))?\s?([ap]m)/gi;

/**
 * Converts 12-hour time format value to 24-hour.
 *
 * @param time 12-hour time format.
 * @returns 24-hour value from specified 12-hour value.
 */
export function convertTo24HourFormat(time: string): string {
  time = time.toLowerCase();

  let hours = +time.match(/^(\d+)/)?.[1];
  const minutes = +time.match(/:(\d+)/)?.[1];
  const seconds = +time.match(/:(\d+):(\d+)/)?.[2];
  const amPm = time.match(/\s?[ap]m$/gi)?.[0];

  if (amPm?.match(/pm/gi) && hours < 12) {
    hours = hours + 12;
  }

  if (amPm?.match(/am/gi) && hours === 12) {
    hours = hours - 12;
  }

  let sHours = hours.toString();
  let sMinutes = minutes.toString();
  let sSeconds;

  if (hours < 10) {
    sHours = `0${sHours}`;
  }

  if (minutes < 10) {
    sMinutes = `0${sMinutes}`;
  }

  if (!isNaN(seconds)) {
    sSeconds = seconds < 10 ? `0${seconds}` : seconds.toString();
  }

  return `${sHours}:${sMinutes}${sSeconds ? `:${sSeconds}` : ''}`;
}
