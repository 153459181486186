import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { AngularMaterialModule } from '@cohesity/helix';
import { SharedModule } from 'src/app/shared';

import { LicenseNotificationComponent } from './license-notiification/license-notification.component';
import { NotificationsIndicatorComponent } from './notifications-indicator/notifications-indicator.component';
import { NotificationsItemComponent } from './notifications-item/notifications-item.component';
import { NotificationsPanelComponent } from './notifications-panel/notifications-panel.component';

const COMPONENTS = [
  NotificationsIndicatorComponent,
  NotificationsItemComponent,
  NotificationsPanelComponent,
  LicenseNotificationComponent,
];

@NgModule({
  imports: [
    CommonModule,
    AngularMaterialModule,
    SharedModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class NotificationsModule { }
