import { Component, ChangeDetectionStrategy, ViewEncapsulation, OnInit } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { HeliosLoginConfigurationServiceApi } from '@cohesity/api/v2';
import { SalesforceLoginRenderer } from '../../services';

@Component({
  selector: 'coh-salesforce-login',
  templateUrl: './salesforce-login.component.html',
  styleUrls: ['./salesforce-login.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    CohesityHelixModule
  ],
  providers: [
    HeliosLoginConfigurationServiceApi,
    SalesforceLoginRenderer
  ]
})
export class SalesforceLoginComponent implements OnInit {

  constructor(private heliosLoginConfigService: HeliosLoginConfigurationServiceApi,
    private sfdcRenderer: SalesforceLoginRenderer
  ) { }

  ngOnInit(): void {
    this.heliosLoginConfigService.GetHeliosLoginConfig().toPromise()
      .then((config) => {
        this.sfdcRenderer.addSfdcTags(config);
      });
  }

}
