// Decorator: decorator $http with request aborting feature

;(function(angular, undefined) {

  angular.module('C.appConfigs')
    .config(httpDecoratorConfig);

  function httpDecoratorConfig($provide) {
    $provide.decorator('$http', decorateHttpWithAbort);
  }

  /**
   * decorate $http response promise with an abort function.
   * use this on destroy where you want to abort the outstanding request made on
   * a page before leaving the page.
   *
   * @example
      var requestPromise = $http(reqConfig).then(...).catch(...).finally(...);

      $onDestroy() {
        requestPromise.abort();
      }
   */
  function decorateHttpWithAbort(_, $q, $delegate) {
    var originalHttpService = $delegate;

    function newHttpServiceConstructor(requestConfig) {
      var canceller = $q.defer();
      var proxyRequest = $q.defer();
      var onAbortCallback = angular.noop;

      // adding abortFn into request promise
      proxyRequest.promise.abort = function abortFn() {
        canceller.resolve();
      };

      // by using onAbort capture the callback function which will be called
      // when the request is aborted, use this to perform cleanups.
      proxyRequest.promise.onAbort = function onAbortFn(callback) {
        onAbortCallback = callback;
        return this;
      };

      // adding request canceller promise in the original request config.
      requestConfig.timeout = canceller.promise;

      originalHttpService(requestConfig).then(
        function onSuccess() {
          proxyRequest.resolve.apply(this, arguments);
        },
        function onError(resp) {
          // don't resolve the abort response with error instead call provided
          // on abort callback to give user a change to handle abort case.
          // natively angular abort is resolved with error.
          if (resp.status === -1) {
            onAbortCallback();
            return;
          }

          proxyRequest.reject.apply(this, arguments);
        },
        function onNotification() {
          proxyRequest.notify.apply(this, arguments);
        }
      );

      return proxyRequest.promise;
    }

    // inherit all derived methods from original $http like $get, $put etc
    _.assign(newHttpServiceConstructor, originalHttpService);

    return newHttpServiceConstructor;
  }

})(angular);
