// Service: Clone helper service formatter.

(function _CloneHelperFormatter(angular) {
  'use strict';

  angular
    .module('C.CloneServiceFormatter', ['C.restoreService'])
    .service('CloneServiceFormatter', CloneServiceFormatter);

  /**
   * @ngdoc service
   * @name C.CloneServiceFormatter
   *
   * @description
   * Convenience service for transforming API and AngularJS objects related to
   * clones.
   *
   * @param    {Object}   _                App dependency.
   * @param    {Object}   RestoreService   App dependency.
   *
   * @return   {Object}   The service object.
   */
  function CloneServiceFormatter(_, RestoreService) {
    return {
      getRefreshPayload: getRefreshPayload,
    };

    /**
     * Returns the API payload to create a clone refresh task. The payload is
     * created with the details of a restore task, and a snapshot, from which
     * the clone is to be refreshed.
     *
     * @method   getRefreshPayload
     * @param    {Object}    restoreTask       An object of restore task. The
     *                                         clone details will be fetched
     *                                         from this.
     * @param    {Object}    entity            The owner entity of the restore
     *                                         task.
     * @param    {Object}    snapshot          Object containing the details
     *                                         of a particular version of the
     *                                         `entity`.
     * @param    {number}    refreshTimeSecs   If this is set, the task is
     *                                         considered as point in time
     *                                         refresh, and `refreshTimeSecs`
     *                                         is set in the payload
     *                                         accordingly.
     * @return   {Object}    The API compatible payload, which can be used to
     *                       trigger a clone refresh task.
     */
    function getRefreshPayload(restoreTask, entity, snapshot,
      refreshTimeSecs) {
      var restoreParams = _.get(restoreTask,
        'performRestoreTaskState.restoreAppTaskState.restoreAppParams');
      var owner = _.get(restoreParams, 'ownerRestoreInfo.ownerObject');
      var restoredApp = _.get(restoreParams, 'restoreAppObjectVec[0]');

      // Default to latest version.
      var version = snapshot || entity._versions[0];
      var ownerObjectId = entity.vmDocument.objectId;

      // Prepare the owner details.
      var ownerDetails = {
        jobId: ownerObjectId.jobId,
        jobUid: {
          id: ownerObjectId.jobUid.objectId,
          clusterId: ownerObjectId.jobUid.clusterId,
          clusterIncarnationId: ownerObjectId.jobUid.clusterIncarnationId,
        },
        jobRunId: version.instanceId.jobInstanceId,
        startedTimeUsecs: version.instanceId.jobStartTimeUsecs,
        protectionSourceId: owner.entity.id,
      };

      var payload = {
        // Generate default name for the task.
        name: RestoreService.getDefaultTaskName('cloneRefresh', 'oracle',
          entity),
        objects: [],
        newParentId: restoredApp.restoreParams.targetHost.id,
        sourceDatabaseId: restoredApp.appEntity.id,
        cloneTaskId: restoreTask.performRestoreTaskState.base.taskId,
      };

      payload.objects.push(ownerDetails);

      // If PIT, add `refreshTimeSecs` too.
      if (refreshTimeSecs) {
        payload.refreshTimeSecs = refreshTimeSecs;
      }

      return payload;
    }
  }
})(angular);
