// MODULE: External Targets
import { KmsModel } from 'src/app/modules/cluster/kms/models/kms.model';

;(function(angular, undefined) {
  'use strict';

  angular.module('C.externalTargets')
    .controller('externalTargetDetailController',
      ExternalTargetDetailControllerFn)
    .controller('externalTargetModifyModalController',
      SharedTargetDetailControllerFn);

  function ExternalTargetDetailControllerFn(
    _, $q, $scope, $state, $translate, cModal, evalAJAX, cUtils, cMessage,
    ExternalTargetService, ExternalTargetsServiceFormatter, PubSourceService,
    ClusterService, ARCHIVE_EXTERNAL_TARGETS, FEATURE_FLAGS,
    EXTERNAL_TARGET_ENCRYPTION_SETTINGS, EXTERNAL_TARGET_COMPRESSION_SETTINGS,
    EXTERNAL_TARGET_USAGE_TYPE_STRINGS, EXTERNAL_TARGET_USAGE_TYPES,
    NgStorageDomainsService) {

    return SharedTargetDetailControllerFn.apply(this, arguments);
  }

  function SharedTargetDetailControllerFn(
    _, $q, $scope, $state, $translate, cModal, evalAJAX, cUtils, cMessage,
    ExternalTargetService, ExternalTargetsServiceFormatter, PubSourceService,
    ClusterService, ARCHIVE_EXTERNAL_TARGETS, FEATURE_FLAGS,
    EXTERNAL_TARGET_ENCRYPTION_SETTINGS, EXTERNAL_TARGET_COMPRESSION_SETTINGS,
    EXTERNAL_TARGET_USAGE_TYPE_STRINGS, EXTERNAL_TARGET_USAGE_TYPES,
    NgStorageDomainsService, $uibModalInstance, vaultId, restrictToType) {

    var defaultUsageType = EXTERNAL_TARGET_USAGE_TYPES.kArchival;
    var inModal = $scope.inModal = !!$uibModalInstance;
    var reader = cUtils.fileReader;

    $state.params.id = $state.params.id || vaultId;

    $scope.restrictToType = restrictToType;

    $scope.FEATURE_FLAGS = FEATURE_FLAGS;

    var defaultCredentials = {
      kAmazon: {
        amazon: {
          accessKeyId: undefined,
          region: undefined,
          secretAccessKey: undefined,
          credentialEndpoint: undefined,
          credentialBlob: undefined,
          tierType: undefined,
          authMethod: 'kUseIAMUser',
        },
      },
      kAzure: {
        azure: {
          storageAccessKey: undefined,
          storageAccountName: undefined,
          tierType: undefined,
        },
      },
      kGoogle: {
        google: {
          clientEmailAddress: undefined,
          clientPrivateKey: undefined,
          encryptedClientPrivateKey: undefined,
          projectId: undefined,
          tierType: undefined,
        },
      },
      kNAS: {
        host: '',
        mountPath: '',
        shareType: 'kNFS',
      },
      kOracle: {
        oracle: {
          accessKeyId: undefined,
          region: undefined,
          secretAccessKey: undefined,
          tenant: undefined,
          tierType: undefined,
        },
      },
      kQStarTape: {
        host: undefined,
        integralVolumeNames: [],
        password: undefined,
        port: 18082,
        shareType: 'CIFS',
        useHttps: true,
        username: undefined,
      },
      kS3Compatible: {
        accessKeyId: undefined,
        secretAccessKey: undefined,
        serviceUrl: undefined,
        signatureVersion: 2,
        useHttps: true,
      },
    };

    angular.extend($scope, {
      awsRegions: ['us-east-2', 'us-east-1', 'us-west-1', 'us-west-2', 'af-south-1',
        'ap-east-1', 'ap-south-1', 'ap-northeast-3', 'ap-northeast-2', 'ap-southeast-1',
        'ap-southeast-2', 'ap-northeast-1', 'ca-central-1', 'cn-north-1', 'cn-northwest-1',
        'eu-central-1', 'eu-west-1', 'eu-west-2', 'eu-south-1', 'eu-west-3', 'eu-north-1',
        'me-south-1', 'sa-east-1'],
      awsGovRegions: ['us-gov-east-1', 'us-gov-west-1'],
      awsC2SRegions: ['us-iso-east-1'],
      canModifyManagedKeys: canModifyManagedKeys,
      confirmManagedKeysSetting: confirmManagedKeysSetting,
      downloadKeyFile: downloadKeyFile,
      encryptionChanged: encryptionChanged,
      EXTERNAL_TARGET_COMPRESSION_SETTINGS: EXTERNAL_TARGET_COMPRESSION_SETTINGS,
      EXTERNAL_TARGET_ENCRYPTION_SETTINGS: EXTERNAL_TARGET_ENCRYPTION_SETTINGS,
      // NOTE(spencer): This help tag may be temporary. The id associated in
      // cHelp is correct, but the tag may change.
      helpLinkCustomerManagedKey: 'customer_managed_keys',
      isVaultEncrypted: isVaultEncrypted,
      isVaultKeyManagedByCohesity: isVaultKeyManagedByCohesity,
      normalizeGooglePrivateKey: cUtils.normalizeGooglePrivateKey,
      form: {},
      shared: {},
      // Default config structure for External Target object
      externalTarget: {
        compressionPolicy: 'kCompressionLow',
        config: {
          bucketName: undefined
        },
        dedupEnabled: true,
        description: undefined,
        encryptionPolicy: 'kEncryptionStrong',
        incrementalArchivesEnabled: false,
        kmsServerId: undefined,
        name: undefined,
        type: undefined,
        usageType: restrictToType ? restrictToType : defaultUsageType,
        customerManagingEncryptionKeys: false,
        vaultBandwidthLimits: undefined,
      },
    });

    /**
     * Activate this controller.
     *
     * @method  activate
     */
    function activate() {
      // Evaluates $state and redirects accordingly
      switch (true) {

        // Edit state
        case !isNaN(vaultId) || $state.current.name === 'external-targets-edit':
          if (!$state.params.id) {
            $state.go('external-targets');
            return;
          }
          getExternalTarget(vaultId || $state.params.id);
          setScopeMode('edit');
          break;

        // View state
        case $state.current.name === 'external-targets-view':
          if (!$state.params.id) {
            $state.go('external-targets');
            return;
          }
          var promises = {};

          promises.target = getExternalTarget($state.params.id);
          promises.allKms = ClusterService.getExternalKms();

          setScopeMode('view');

          $q.allSettled(promises).then(promiseObj => {
            $scope.externalTarget.kmsObj = promiseObj.allKms.resp.find(
              function findKms(kms) {
                return kms.vaultIdList.includes($scope.externalTarget.id);
              });
          }, evalAJAX.errorMessage);

          break;
        default:
         setScopeMode('new');
      }

      // Use `restrictToType` value if provided. Otherwise use the default.
      _setTargetType(restrictToType || $scope.externalTarget.usageType);

      $scope.targetTypes = getTargetTypeOptions();
    }

    /**
     * Show a confirmation modal when enabling customer managed vault key.
     *
     * @method   confirmManagedKeysSetting
     * @param    {boolean}  force  force key download if true
     */
    function confirmManagedKeysSetting(force) {
      var modalOptions = {
        actionButtonTextKey: 'yes',
        closeButtonTextKey: 'cancel',
        content: force ? 'externalTarget.downloadKMSKeyNote'
          : 'externalTarget.enableCustomerManagedKeysCopy',
        title: 'externalTarget.enableCustomerManagedKeysTitle',
      };

      // Show modal
      cModal.showModal(undefined, modalOptions)
        .then(
          // If yes, leave the setting alone (do nothing)
          undefined,
          // If no, revert to false
          function changeRejected() {
            $scope.externalTarget.customerManagingEncryptionKeys = false;

            if ($scope.externalTarget.kmsServerId) {
              $scope.externalTarget.kmsServerId = undefined;
            }
          }
        );
    }

    /**
     * Change handler when toggling Encryption config. Used with onChange at
     * this time.
     *
     * @method  encryptionChanged
     * @param   {string}   setting   The current encryption setting
     */
    function encryptionChanged(setting) {
      if ('kEncryptionNone' === setting) {
        delete $scope.externalTarget.customerManagingEncryptionKeys;
      }

      // Encryption can be enabled during edit mode.
      // But if its already enabled, it can't be disabled.
      // This property allows to check whether encryption is already enabled
      // or whether its been enabled while editing.
      // If encryption is enabled and _encryptionEdited is false, it means
      // encryption is enabled in backend.
      // If _encryptionEnabled is also true, then encryption is enabled in UI
      // and can still be toggled off.
      $scope.externalTarget._encryptionEdited = true;
    }

    /**
     * Determines if vault Encryption keys management can be modified.
     *
     * @method  canModifyManagedKeys
     * @return  {bool}  True if the config can be modified.
     */
    function canModifyManagedKeys() {
      // This is true when editing a vault and the encryption keys are not
      // currently managed by the customer.
      var isEditArchival = ($scope.isEditMode && !isVaultKeyManagedByCohesity());
      // This is true when adding a new vault, cloudspill is disabled, and
      // vault encryption is enabled.
      var isNewArchival = (!$scope.shared.isCloudSpill && $scope.isNewMode &&
        'kEncryptionNone' !== $scope.externalTarget.encryptionPolicy);

      return (isNewArchival || isEditArchival);
    }

    /**
     * Checks if the vault is currently configured for encryption.
     *
     * @method  isVaultEncrypted
     * @return  {bool}  True if the vault is configured for encryption
     */
    function isVaultEncrypted() {
      return !!$scope.isEcryptionAlreadyEnabled;
    }

    /**
     * Checks if the vault is configured so that Cohesity manages the key.
     *
     * @method  isVaultKeyManagedByCohesity
     * @return  {bool}  True if the vault key is configured for Cohesity
     *                  management.
     */
    function isVaultKeyManagedByCohesity() {
      return !!$scope.isVaultKeyAlreadyManaged;
    }

    /**
     * Download the key file for the given vaultId. Reload view after.
     *
     * @method   downloadKeyFile
     */
    function downloadKeyFile() {
      // Pop a confirmation modal
      ExternalTargetService.downloadKeyFile($scope.externalTarget)
        .then(function fileDownloaded() {
          //  This reloads the view to prevent additional key file downloads.
          goToDetailView();
        });
    }

    /**
     * Sets some $scope convenience Booleans for quick detection of
     * this view's current mode.
     *
     * @method   setScopeMode
     * @param    {string}  mode  One of: 'new', 'edit', or 'view'.
     */
    function setScopeMode(mode) {
      switch (mode) {
        case 'edit':
          $scope.mode = 'edit';
          $scope.isEditMode = true;
          $scope.isNewMode = false;
          $scope.isViewMode = false;
          break;
        case 'view':
          $scope.mode = 'view';
          $scope.isViewMode = true;
          $scope.isEditMode = false;
          $scope.isNewMode = false;
          break;
        default:
          $scope.mode = 'new';
          $scope.isNewMode = true;
          $scope.isEditMode = false;
          $scope.isViewMode = false;
      }
    }

    /**
     * Get a Single External Target.
     *
     * @method   getExternalTarget
     * @param    {int}   id   The target ID
     */
    function getExternalTarget(id) {
      $scope.loadingTarget = true;

      return ExternalTargetService.getTarget(id).then(
        function getExternalTargetSuccess(extTarget) {

          // Remove region field for snowball edge edit as it is set in the backend.
          if (_.get(extTarget, 'isAwsSnowball')) {
            delete extTarget.config.amazon.region;
          }

          $scope.externalTarget = extTarget;

          // Set archive value to archival dropdown.
          if ($scope.externalTarget.isForeverIncrementalArchiveEnabled) {
            $scope.externalTarget.archivalFormat = 'incrementalForeverArchival';
          } else if ($scope.externalTarget.incrementalArchivesEnabled) {
            $scope.externalTarget.archivalFormat = 'incremental';
          }

          if (FEATURE_FLAGS.ngCloudEditionEnabled) {
            getViewBoxById($scope.externalTarget.cloudDomainList[0].viewBoxId);
          }

          // These let us toggle the Encryption settings in the UI on and
          // off if they're setting is *not* saved already as true (enabled)
          $scope.isVaultKeyAlreadyManaged =
            !!extTarget.customerManagingEncryptionKeys;
          $scope.isEcryptionAlreadyEnabled =
            ('kEncryptionNone' !== extTarget.encryptionPolicy);
          $scope.isCompressionAlreadyEnabled =
            ('kCompressionNone' !== extTarget.compressionPolicy);
          $scope.shared.isCloudSpill = extTarget.usageType === 'kCloudSpill';
        },
        evalAJAX.errorMessage
      ).finally(
        function getTargetFinally() {
          $scope.loadingTarget = false;
        }
      );
    }

    /**
     * Get a single storage domain
     *
     * @method   getViewBoxById
     * @param    {int}   id   The storage domain ID
     */
    function getViewBoxById(id) {
      NgStorageDomainsService.getStorageDomainById(id).toPromise()
        .then(function onSuccess(response) {
          $scope.externalTarget.viewBoxName = _.get(response, 'name');
        }
      )
    }

    /**
     * Changes the External Target type used in 'new' mode only
     *
     * @method   setType
     * @param    {object}  selectedTarget   tierData like tierType, govVault etc
     */
    $scope.setType = function setType(selectedTarget) {
      const tierData = selectedTarget.tierData;
      var externalTarget = $scope.externalTarget;
      var referenceObj;

      _resetTarget();

      setDefaultValueForArchivalFormat();

      // Set isAwsSnowball flag/API key to true.
      if (selectedTarget.name === 'Snowball Edge') {
        externalTarget.isAwsSnowball = true;
        // Set default signatureVersion for Snowball and hide AWS Signature Version
        // from the template
        defaultCredentials.kS3Compatible.signatureVersion = 4;
      } else {
        defaultCredentials.kS3Compatible.signatureVersion = 2;
      }

      // Set authMethod 'kUseSTS' to vault type STS
      if (selectedTarget.name === 'S3-STS') {
        defaultCredentials.kAmazon.amazon.authMethod = 'kUseSTS';
      }

      switch (tierData.targetType) {
        case 'kGoogle':
          referenceObj = externalTarget.config.google =
            cUtils.simpleCopy(defaultCredentials.kGoogle.google);
          break;

        case 'kAmazon':
        case 'kAmazonGlacier':
          referenceObj = externalTarget.config.amazon =
            cUtils.simpleCopy(defaultCredentials.kAmazon.amazon);
          break;

        case 'kS3Compatible':
          referenceObj = externalTarget.config.amazon =
            cUtils.simpleCopy(defaultCredentials.kS3Compatible);
          break;

        case 'kAzure':
          referenceObj = externalTarget.config.azure =
            cUtils.simpleCopy(defaultCredentials.kAzure.azure);
          break;

        case 'kQStarTape':
          externalTarget.config.qstar =
            cUtils.simpleCopy(defaultCredentials.kQStarTape);

          // Deduplication is OFF by default for QStar tape vault.
          externalTarget.dedupEnabled = false;
          break;

        case 'kNAS':
          externalTarget.config.nas =
            cUtils.simpleCopy(defaultCredentials.kNAS);
          externalTarget.encryptionPolicy = 'kEncryptionStrong';
          externalTarget.compressionPolicy = 'kCompressionLow';
          break;

        case 'kOracle':
          referenceObj = externalTarget.config.oracle =
            cUtils.simpleCopy(defaultCredentials.kOracle.oracle);
          break;
      }

      // set the vault
      externalTarget.externalTargetType = tierData.vault;

      // set the tier
      if (referenceObj) {
        // accessing the first (and only) key of the object
        referenceObj.tierType = tierData.tier;
      }

      // if the vault has multiple tiers, select 'standard' as default
      externalTarget._title =
        (tierData.govVault || tierData.c2sVault) ? 'standard' : undefined;

      // Reset the form if it exists
      if ($scope.form && $scope.form.formModifyExternaTarget) {
        $scope.form.formModifyExternalTarget.$setPristine();
      }
    };

    /**
     * Set default value for Archival Format dropdown based on the vault type
     *
     * @method   setDefaultValueForArchivalFormat
     */
    function setDefaultValueForArchivalFormat() {
      if ($scope.getArchivalFormats().includes('incrementalForeverArchival')) {
        $scope.externalTarget.archivalFormat = 'incrementalForeverArchival';
      } else if ($scope.getArchivalFormats().includes('incremental')) {
        $scope.externalTarget.archivalFormat = 'incremental';
      }
      $scope.setArchiveFormatValueToObject();
    }

    /**
     * Reset the target config on target change
     *
     * @method   _resetTarget
     */
    function _resetTarget() {
      _.assign($scope.externalTarget, {
        config: {},
        type: undefined,
        viewBoxName: undefined,
        dedupEnabled: true,
        archivalFormat: undefined,
        isAwsSnowball: false,
        incrementalArchivesEnabled: false,
        isForeverIncrementalArchiveEnabled: FEATURE_FLAGS.vaultForeverIncremental &&
          $scope.doesVaultSupportForeverIncremental() ?
        (!_.isNil($scope.externalTarget.isForeverIncrementalArchiveEnabled) ?
          $scope.externalTarget.isForeverIncrementalArchiveEnabled : false)
            : undefined,
      });
    }

    /**
     * Sets the external target vault title to standard/gov
     *
     * @method   titleChanged
     */
    $scope.titleChanged = function titleChanged() {
      var externalTarget = $scope.externalTarget;

      switch (externalTarget._title) {
        case 'gov':
          externalTarget.externalTargetType = externalTarget._tierData.govVault;
          break;

        case 'c2s':
          externalTarget.externalTargetType = externalTarget._tierData.c2sVault;
          break;

        default:
          externalTarget.externalTargetType = externalTarget._tierData.vault;
      }

      if (!!externalTarget.config.amazon) {
        if (externalTarget._title === 'gov') {
          externalTarget.config.amazon.authMethod = 'kUseIAMUser';
        }
        externalTarget.config.amazon.region = undefined;
        $scope.updateAwsArn();
      }
    };

    /**
     * Cancel this flow. If in modal, it resolves the modal with false,
     * otherwise changes states.
     *
     * @method     cancel
     * @return     {*}   Modal promise object, or state change.
     */
    $scope.cancel = function cancel() {
      return (inModal) ?
        $uibModalInstance.dismiss('canceled') :
        $state.go('external-targets.list');
    };

    /**
     * Process the form based on $state, create or edit target
     *
     * @method  processForm
     * @param   {bool}   isValid   The calling form's $valid property
     */
    $scope.processForm = function processForm(isValid) {
      var extTarget = $scope.externalTarget;
      var actionFn;

      if (!isValid) {
        return false;
      }

      actionFn = $scope.isEditMode ?
        ExternalTargetService.updateTarget : ExternalTargetService.createTarget;

      $scope.submitting = true;

      actionFn(extTarget).then(
        function actionFnSuccess(updatedTarget) {
          extTarget.id = updatedTarget.id;

          cMessage.success({
            titleKey: 'success',
            textKey:
              $scope.isEditMode ? 'editTargetMessage' : 'createTargetMessage',
          });

          if (inModal) {
            $uibModalInstance.close(updatedTarget);
          } else {
            goToDetailView();
          }
        }, function errorCB(resp) {
          extTarget.vaultBandwidthLimits = ExternalTargetsServiceFormatter
            .transformBandwidthLimitData(extTarget.vaultBandwidthLimits);
          evalAJAX.errorMessage(resp);
        }
      ).finally(
        function promiseResolved(rep) {
          $scope.submitting = false;
        }
      );
    };

    /**
     * Form an arn string from accountId and role user inputs for AWS
     * @method updateAwsArn
     */
    $scope.updateAwsArn = function() {
      var awsCredentialsStr = 'externalTarget.config.amazon';

      if (_.get($scope, awsCredentialsStr + '.authMethod') === 'kUseIAMRole') {

        var accountId = _.get($scope, awsCredentialsStr + '._accountId');
        var iamRole = _.get($scope, awsCredentialsStr + '._iamRole');
        var arnStr =
          $scope.externalTarget._title === 'gov' ? 'aws-us-gov' : 'aws';

        // We take accountId and IAM Role Name as input from the user
        // but send their combination
        // (arn:aws:iam::<accountId>:role/<roleName>)
        // to the backend as iamRoleArn
        _.set($scope, awsCredentialsStr + '.iamRoleArn',
          'arn:' + arnStr + ':iam::' + accountId + ':role/' + iamRole);
      } else {
        _.unset($scope, awsCredentialsStr + '.iamRoleArn');
      }
    }

    /**
     * Custom validation for Integral Volume Name tags
     *
     * @method   addIVNames
     * @param    {string}   value   uiSelect[multiple] value
     */
    $scope.addIVNames = function addIVNames(value) {
      // Clean out commas because ui-select doesn't do it
      value = value.replace(',', '');
      if (value.match($scope.FORMATS.alphanumericPlus)) {
        $scope.externalTarget.ivNameInvalid = false;
        return value;
      } else {
        $scope.externalTarget.ivNameInvalid = true;
        return false;
      }
    };

    /**
     * handle removal of user/pass from NAS config if type is
     * toggled to NFS.
     *
     * @method   nasShareTypeChanged
     */
    $scope.nasShareTypeChanged = function nasShareTypeChanged() {
      if ($scope.externalTarget.config.nas.shareType === 'kNFS') {
        if ($scope.externalTarget.config.nas.password) {
          delete $scope.externalTarget.config.nas.password;
        }
        if ($scope.externalTarget.config.nas.username) {
          delete $scope.externalTarget.config.nas.username;
        }
      }
    };

    /**
     * Reload this view.
     *
     * @method   goToDetailView
     * @param    {object}   [opts]   Optional $state.go options.
     * @return   {object}            Promise to change state.
     */
    function goToDetailView(opts) {
      opts = opts || { reload: true };

      return $state.go('external-targets-view', {
        id: $scope.externalTarget.id
      }, opts);
    }

    /**
     * Create a list of target types based on currently select usageType. To be
     * used by ui-select form element
     *
     * @method     getTargetTypeOptions
     * @return     {Array}  list of target types compatible with current
     *                      usageType
     */
    function getTargetTypeOptions() {
      var targetTypes = ARCHIVE_EXTERNAL_TARGETS;
      var retMap = [];

      targetTypes = ExternalTargetsServiceFormatter
        .addFeatureControlledTargets(targetTypes);

      _.each(targetTypes, function eachTarget(target, key) {
        var isOtherType = key === 'other';
        var targetType;
        var vault = '';

        if (target.types.length) {
          _.each(target.types, function eachType(type) {
            if ($scope.shared.isCloudSpill && !type.cloudSpillSupported) {
              return;
            }
            vault = '';
            // is targetType google/aws/azure/nas etc and override vault value for Snowball Edge.
            switch (true) {
              case (type.name === 'Snowball Edge'):
                targetType = 'kS3Compatible';
                vault = 'kS3Compatible';
                break;
              case (key === 'other'):
                targetType = type.vault;
                break;
              case (key === 'aws' && type.tier === 'kAmazonGlacier'):
                targetType = type.tier;
                break;
              default:
                targetType = target.vaults.standard;
                break;
            }

            retMap.push({
              name: type.name,
              selectedName: isOtherType ?
                type.name : [target.name, type.name].join(' '),
              tierData: {
                tier: type.tier,
                vault: vault ? vault : type.vault || target.vaults.standard,
                govVault: type.hasGov ? target.vaults.gov : undefined,
                c2sVault: type.hasC2S ? target.vaults.c2s : undefined,
                targetType: targetType,
              },
              _typeStr: isOtherType ? '' : target.name,
            });
          });
        }
      });

      return _.sortBy(retMap, ['name']);
    }

    /**
     * This will add a target under its relevant group to
     * be displayed in dropdown
     *
     * @method   groupTargetTypes
     * @param    {Object}   target   The target to be grouped
     * @return   {String}   The group for the target
     */
    $scope.groupTargetTypes = function groupTargetTypes(target) {
      return target._typeStr !== 'other' ? target._typeStr : undefined;
    };

    /**
     * Sort the target groups to be displayed in dropdown
     *
     * @method   groupTargetSort
     * @param    {Object[]}   groups   The groups to be sorted
     * @return   {Object[]}   The sorted groups
     */
    $scope.groupTargetSort = function groupTargetSort(groups) {
      var index;
      var emptyGroupElement;

      groups.find(function findTarget(group, i) {
        if (group.name === '') {
          index = i;
          return true;
        }
      });

      groups = _.sortBy(groups, ['name']);

      // move empty group to end
      groups.push(groups.shift(0));

      return groups;
    };

    /**
     * Sets the properties which indicate which target types are allowed,
     * whether Archive or Cloud Tier.
     *
     * @method    _setTargetType
     * @param     {String}     usageType     The kValue of allowed types.
     */
    function _setTargetType(usageType) {
      // Set shared scope variable isCloudSpill to filter available target type
      // options
      $scope.shared.isCloudSpill = /spill/i.test(usageType);
      $scope.isArchival = !$scope.shared.isCloudSpill;
    }

    /**
     * Updates default values based on ng-change of usageType when creating
     * a new External Target.
     *
     * @method   usageTypeChanged
     * @param    {string}   usageType   The selected usageType ENUM (see
     *                                  Constant EXTERNAL_TARGET_USAGE_TYPES
     *                                  for possible values)
     */
    $scope.usageTypeChanged = function usageTypeChanged(usageType) {
      _resetTarget();
      $scope.externalTarget._tierData = undefined;
      $scope.form.formModifyExternalTarget.$setPristine();

      _setTargetType(usageType);

      // Update the available target types
      $scope.targetTypes = getTargetTypeOptions();

      switch (true) {
        // Force encryption and compression for archive purpose, compression is
        // off based on FEATURE_FLAG for 2.7
        case $scope.shared.isCloudSpill:
          angular.extend($scope.externalTarget, {
            customerManagingEncryptionKeys: undefined,
            encryptionPolicy: 'kEncryptionStrong',
            compressionPolicy: FEATURE_FLAGS.cloudSpillCompression ?
              'kCompressionLow' : 'kCompressionNone',
          });
          break;

        // set archival compression to high in case user toggled to
        // FEATURE_FLAGed cloud spill and back
        case $scope.isArchival:
          $scope.externalTarget.compressionPolicy = 'kCompressionLow';
          break;
      }

    };

    /**
     * Handler for file upload. When a certificate file is added from the ui,
     * this method converts it into byte object.
     *
     * @param    {String}   key  The key for the file model
     */
    $scope.certificateAdded = function certificateAdded(key) {
      var configKey = ['config', key].join('.');

      $scope.currentFileKey = key;
      reader.readAsArrayBuffer(_.get($scope.externalTarget, configKey));
    };

    /**
     * Reads the contents of the added file as byte array and converts it into
     * binary string to send with the request object.
     * @param   {object}   e     Event object fired when file is read
     */
    reader.onload = function readFile(e) {
      var arrayBuffer = e.target.result;
      var array = new Uint8Array(arrayBuffer);
      var binaryString = String.fromCharCode.apply(null, array);

      _.set($scope.externalTarget, $scope.currentFileKey, binaryString);
    };

    /**
     * Fetches the display string for usageType based on its type
     *
     * @method     getUsageTypeDisplayString
     * @param      {String}  usageType  ['kArchival', 'kCloudSpill']
     * @return     {String}             The string to display
     */
    $scope.getUsageTypeDisplayString = function getUsageTypeString(usageType) {
      return EXTERNAL_TARGET_USAGE_TYPE_STRINGS[usageType];
    };

    /**
     * getter setter for external target blackout windows & timezone settings.
     *
     * @method   getSetBandwidthLimitOverride
     * @return   {boolean}   true if remote cluster blackout windows or timezone
     *                       settings is present else false
     */
    $scope.getSetBandwidthLimitOverride =
      function getSetBandwidthLimitOverride(toSet) {
      if (angular.isDefined(toSet)) {
        if (toSet === false) {
          // cache the current value
          $scope.externalTarget._vaultBandwidthLimits =
            $scope.externalTarget.vaultBandwidthLimits;
          $scope.externalTarget.vaultBandwidthLimits = undefined;
        } else {
          // load the previous value if available, else load default
          $scope.externalTarget.vaultBandwidthLimits =
            $scope.externalTarget._vaultBandwidthLimits || {};
        }
      }

      return !!$scope.externalTarget.vaultBandwidthLimits;
    };

    /**
     * Returns whether the selected vault supports Archive V2
     *
     * @method   doesVaultSupportForeverIncremental
     * @param    {boolean} onlyS3  if True, consider only AWS S3 type vaults
     * @returns  {boolean}  True if vault supports Archive V2. False otherwise.
     */
    $scope.doesVaultSupportForeverIncremental = function doesVaultSupportForeverIncremental(onlyS3) {
      // Returns false if the selected vault is Glacier/DeepGlacier and its
      // corressponding feature flag is false.
      var tier = _.get($scope.externalTarget, '_tierData.tier');
      var targetType = _.get($scope.externalTarget, '_tierData.targetType');

      if ((tier === 'kAmazonS3Glacier' && !$scope.FEATURE_FLAGS.s3GlacierForeverIncremental)
          || (tier === 'kAmazonS3GlacierDeepArchive' && !$scope.FEATURE_FLAGS.s3DeepGlacierForeverIncremental)
          || !$scope.FEATURE_FLAGS.vaultForeverIncremental) {
        return false;
      }

      if ($scope.FEATURE_FLAGS.enableGcpTargetForCad && targetType === 'kGoogle') {
        return true;
      }

      const vaultTypes = onlyS3 ? ['kAmazon'] : ['kAmazon', 'kNAS', 'kS3Compatible'];
      return vaultTypes.includes(targetType);
    }

    /**
     * Download the CloudFormation template as a JSON file
     *
     * @method downloadCFT
     */
    $scope.downloadCFT = function downloadCFT() {
      PubSourceService.downloadAwsCFT({
        fileName: 'aws_lambda_resource_creation_template.cft',
      })
        .then(function cftDownloaded(resp) {
          var element = document.createElement('a');

          element.setAttribute('href', 'data:text/plain;charset=utf-8,' +
            encodeURIComponent(JSON.stringify(resp, null, 2)));
          element.setAttribute('download', 'cft.json');

          element.style.display = 'none';
          document.body.appendChild(element);

          element.click();

          document.body.removeChild(element);
        });
    };

    /**
     * Download the AWS CloudFormation template as a JSON file
     *
     * @method downloadAwsCFT
     */
    $scope.downloadAwsCFT = function downloadAwsCFT() {
      PubSourceService.downloadAwsCFT()
        .then(function cftDownloaded(resp) {
          var element = document.createElement('a');

          element.setAttribute('href', 'data:text/plain;charset=utf-8,' +
            encodeURIComponent(JSON.stringify(resp, null, 2)));
          element.setAttribute('download', 'cft.json');

          element.style.display = 'none';
          document.body.appendChild(element);

          element.click();

          document.body.removeChild(element);
        });
    };

    /**
     * Populate the AWS Regions dropdown.
     * Allow free text in addition to selection from given choices
     *
     * @method  getAwsRegions
     * @param   {string}   text  The text entered in dropdown field
     * @returns {string[]} The list of aws regions
     */
    $scope.getAwsRegions = function getAwsRegions(text) {
      var newRegions =
        ($scope.externalTarget._title === 'gov' ? $scope.awsGovRegions :
          ($scope.externalTarget._title === 'c2s' ? $scope.awsC2SRegions : $scope.awsRegions).slice());

      if (text && newRegions.indexOf(text) === -1) {
        newRegions.unshift(text);
      }

      var lowerCaseText = text.toLowerCase();

      return newRegions.filter(function filterRegions(region) {
        return region.indexOf(text) > -1 || $translate.instant(
          region).toLowerCase().indexOf(lowerCaseText) > 1;
      });
    }

    /**
     * Populate the Archival Format dropdown.
     *
     * @method  getArchivalFormats
     * @returns {string[]} The list of archival formats
     */
    $scope.getArchivalFormats = function() {
      var archivalFormats = [];
      const vault = _.get($scope.externalTarget, '_tierData.vault');
      if (!$scope.shared.isCloudSpill && $scope.doesVaultSupportForeverIncremental()) {
        archivalFormats.push('incrementalForeverArchival');
      }
      if (!$scope.shared.isCloudSpill && 'kQStarTape' !==  vault) {
        archivalFormats.push('incremental');
      }
      return archivalFormats;
    }

    /**
     * Set archival format value to its equivalent JSON key,
     * as backend maintains two different keys.
     *
     * @method  setArchiveFormatValueToObject
     */
    $scope.setArchiveFormatValueToObject = function() {
      switch($scope.externalTarget.archivalFormat) {
        case 'incrementalForeverArchival':
          $scope.externalTarget.isForeverIncrementalArchiveEnabled = true;
          $scope.externalTarget._foreverIncrementalEdited = true;
          $scope.externalTarget.incrementalArchivesEnabled = true;
          break;
        case 'incremental':
          $scope.externalTarget.incrementalArchivesEnabled = true;
          $scope.externalTarget.isForeverIncrementalArchiveEnabled = false;
          break;
      }
    }

    /**
     * Is the selected AWS Region not present in the list of aws regions
     *
     * @method  isNewAwsRegion
     * @param   {string}  text  The text entered in dropdown field
     * @returns {boolean} True if the region is a new region
     */
    $scope.isNewAwsRegion = function isNewAwsRegion(text) {
      return !!text && $scope.awsRegions.concat($scope.awsGovRegions, $scope.awsC2SRegions).indexOf(text) === -1;
    }

    /**
     * Show a popup to download key if KMIP KMS is selected
     *
     * @method  onKmsSelect
     * @param   {object}  kmsObj  object containing kms key and its type
     */
    $scope.onKmsSelect = function onKmsSelect() {
      var target = $scope.externalTarget;

      if (target.kmsServiceType === KmsModel.KeyTypes.kCryptsoftKMS && target.kmsServerId) {
        target.customerManagingEncryptionKeys = true;
        confirmManagedKeysSetting(true);
      }
    }

    /**
     * Should "download key" toggle be displayed.
     * It only needs to be displayed for internal/kmip kms
     *
     * @method  showCustomerManagedKeysToggle
     * @returns {boolean} True if toggle is to be shown.
     */
    $scope.showCustomerManagedKeysToggle = function showCustomerManagedKeysToggle() {
      var target = $scope.externalTarget;

      return target.kmsServiceType !== KmsModel.KeyTypes.kAwsKMS;
    }

    /**
     * Return a display label key for a kms service
     *
     * @method  getKMSLabel
     * @returns {string}  The display label key for kms type which can be translated
     */
    $scope.getKMSLabel = function getKMSLabel() {
      return KmsModel.KeyTypeLabels.find(function findLabel(obj) {
        return $scope.externalTarget.kmsObj && obj.value === $scope.externalTarget.kmsObj.serverType;
      });
    }

    /**
     * Return the software version to be used in documentaion link for Forever Incremental
     *
     * @method  getForeverIncrementalVersion
     * @returns {object}  Object containing major and minor version of current release
     */
    $scope.getForeverIncrementalVersion = function getForeverIncrementalVersion() {
      var versionObj = $scope.$root.clusterInfo._clusterSoftwareVersion.versionObj;

      if (versionObj.isMaster) {
        // This feature got introduced in 6.6. Use that for master builds.
        return {
          major: 6,
          minor: 6,
        }
      } else {
        return $scope.$root.clusterInfo._clusterSoftwareVersion.versionObj;
      }
    }

    activate();
  }

})(angular);
