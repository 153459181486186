export const RestoreStatusLabels = {
  kReadyToScheduled: 'enum.status.readyToSchedule',
  kFinished: 'enum.status.success',
  kCanceled: 'enum.status.canceled',
  kProgressMonitorCreated: 'enum.status.running',
  kRetrievedFromArchive: 'enum.status.running',
  kAdmitted: 'enum.status.admitted',
  kInProgress: 'enum.status.running',
  kFinishingProgressMonitor: 'enum.status.running',
  kInternalViewCreated: 'enum.status.running',
  kZipFileRequested: 'enum.status.running',
  kOnHold: 'enum.status.running',
};

/**
 * Enumerates the possible status of a restore task.
 */
export enum RestoreTaskStatus {
  /**
   * The task is waiting to be scheduled.
   */
  kReadyToSchedule = 0,

  /**
   * The task has been admitted. Once a task has been admitted, its status
   * does not move back to kReadyToSchedule even if it is being re-scheduled.
   */
  kAdmitted = 1,

  /**
   * This state indicates that the restore task is in progress. Restore type
   * specific protos can further have specific states if necessary.
   */
  kInProgress = 2,

  /**
   * The task or job has finished. Whether it was successful or not is in the
   * error code that's stored with the job/task.
   */
  kFinished = 3,

  /**
   * Progress monitor for the task has been created.
   */
  kProgressMonitorCreated = 4,

  /**
   * This state indicate that restore task is finishing its progress monitor.
   */
  kFinishingProgressMonitor = 5,

  /**
   * Indicates that the objects to be restored have been retrieved from the
   * specified archive (the task will only ever transition to this state if
   * such a retrieval was necessary).
   */
  kRetrievedFromArchive = 6,

  /**
   * The task or job has been cancelled.
   */
  kCancelled = 7,

  /**
   * The internal view for the task has been created. Right now only
   * applicable to a download files task.
   */
  kInternalViewCreated = 8,

  /**
   * For files and folders download, this state implies that we have
   * successfully sent a request to Yoda to create a zip file that contains
   * the files to be downloaded. Yoda reads these files or folders either
   * from the local snapshot or the stub view created by the Icebox. This
   * state is only going to be present for kDownloadFiles task.
   */
  kZipFileRequested = 9,

  /**
   * This state implies that the restore operation is on hold. It is used
   * by multi-stage restore tasks (such as SQL DB migration) when the
   * parent task is not executing any sub-tasks.
   */
  kOnHold = 10,

  /**
   * This state is only valid for Refresh op. This indicates that clone is
   * destroyed.
   */
  kCloneDestroyed = 11,
}

/**
 * Enumerates the possible status of a destroy clone task.
 */
export enum DestroyClonedTaskStatus {
  /**
   * The task is waiting to be scheduled.
   */
  kReadyToSchedule = 0,

  /**
   * The task has been admitted. Once a task has been admitted, its status
   * does not move back to kReadyToSchedule even if it is being re-scheduled.
   */
  kAdmitted = 1,

  /**
   * The task has finished. Whether it was successful or not is in the error
   * code that's stored with the destroy task.
   */
  kFinished = 2,
}

/**
 * Enumerates all the possible status a restore task can have. It maps the key
 * to the ENUM_UI_RESTORE_TASK_STATUS constant, which in turn, returns the
 * appropriate status to be shown on the UI.
 */
export enum RestoreTaskUiStatus {
  kScheduled = 0,
  kRunning = 1,
  kMigrating = 2,
  kSuccess = 3,
  kError = 4,
  kDestroyScheduled = 5,
  kDestroying = 6,
  kDestroyed = 7,
  kDestroyError = 8,
  kCanceled = 9,
  kRefreshing = 10,
  kRefreshError = 11,
}

/**
 * File subtask restore status.
 */
export const RestoreFileEntityStatus = {
  0: 'Running',
  1: 'Running',
  2: 'Finished',
  3: 'Running',
  4: 'Running',

  kRunning: [0, 1, 3, 4],
  kFinished: [2],
};
