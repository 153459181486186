import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const inventoryAccess = (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.keplerEnabled;

export const InventoryConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'inventory.**',
      url: '/inventory',
      loadChildren: () => import('./inventory.module').then(m => m.InventoryModule),
    },
  ],
  allClusterMap: {
    'inventory': {
      heliosOnly: true,
      allClustersState: 'inventory'
    }
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'inventory': inventoryAccess,
    };
  },
};
