import { ComponentType } from '@angular/cdk/overlay';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  HostBinding,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { AppBannerService, BannerLocation } from '../app-banner';
import { BackdropConfig, PageBackdropRef } from './page-backdrop/page-backdrop-ref';
import { PageBackdropComponent } from './page-backdrop/page-backdrop.component';
import { PageNavComponent, PageToolbarComponent } from './page-toolbar/page-toolbar.component';
import { PageService } from './page.service';

@Component({
  selector: 'cog-page-header',
  template: '<ng-content></ng-content>',
  styleUrls: ['./page-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PageHeaderComponent {
  /**
   * This input can be leveraged in scenarios where its undesirable to have margin
   * between the page header and the content (such as Dashboards).
   */
  @HostBinding('class.cog-page-header-no-margin') @Input() noMargin = false;

  /**
   * This input can be leveraged in scenarios where its undesirable to have padding
   * between the page header component above.
   */
  @HostBinding('class.cog-page-header-no-top-padding') @Input() noTopPadding = false;
}

@Component({
  selector: 'cog-page-drawer',
  template: '<ng-content></ng-content>',
  encapsulation: ViewEncapsulation.None,
})
export class PageDrawerComponent { }

@Component({
  selector: 'cog-page-drawer-anchor',
  template: '<ng-content></ng-content>',
  encapsulation: ViewEncapsulation.None,
})
export class PageDrawerAnchorComponent { }

/**
 * The PageComponent is leveraged to achieve consistent page layouts. It provides a 'sticky' header area via the
 * PageHeaderComponent and a scrollable content area. In most, if not all, cases the PageHeaderComponent should contain
 * a PageToolbarComponent for optimal styling and layout.
 *
 * @example
 * <cog-page>
 *   <cog-page-header>
 *     <cog-page-toolbar title="Page Title"></cog-page-toolbar>
 *   </cog-page-header>
 *   <p>Page Content Here!</p>
 * </cog-page>
 */
@Component({
  selector: 'cog-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  providers: [
    PageService,
  ],
})
export class PageComponent implements OnDestroy, OnInit, AfterViewInit {
  /**
   * Adds disabled class for styling purposes based on disabled @Input value.
   */
  @HostBinding('class.disabled') @Input()
  get disabled(): boolean {
    return this.pageService.isDisabled;
  }
  set disabled(disabled: boolean) {
    this.pageService.isDisabled = disabled;
  }

  /**
   * Sets the page to be a dashboard such that it is not wrapped in the
   * typical content wrapper, since the dashboard cards
   * will each have that type of styling
   */
  @Input() isDashboard = false;

  /**
   * Adds dashboard class for styling purposes based on isDashboard @Input value.
   */
  @HostBinding('class.dashboard')
  get dashboard(): boolean {
    return this.isDashboard;
  }

  /**
   * Use this to remove the top margin on dashboard pages that also need to be tightened up a bit
   * Sometimes the jazz padding and margins just push the content of the page
   * down a bit too much.  Use sparingly.
   */
  @Input() noTopPadding = false;

  @HostBinding('class.no-top-padding')
  get reducedpadding(): boolean {
    return this.isDashboard && this.noTopPadding;
  }

  /**
   * Use this to ensurere that the page content (below the toolbar) takes up the full available screen height.
   * This sets the page content to a relative position, so that another component can use absoluate positioning to
   */
  @HostBinding('class.flex-page-content') @Input() flexPageContent = false;

  @HostBinding('class.viewport-page-content') @Input() viewportPageContent = false;

  /**
   * Applies a class to the page component element if the user has scrolled down in the content area. This class is used
   * by PageToolbarComponent to style based on position.
   */
  @HostBinding('class.content-is-offset') hasScrollOffset$: boolean;

  /**
   * Applies a class to the page if the page width is set to small.
   */
  @HostBinding('class.page-width-sm') get isSmallPage(): boolean {
    return this.pageWidth === 'sm';
  }

  /**
   * Applies a class to the page if the page width is set to medium.
   */
  @HostBinding('class.page-width-md') get isMediumPage(): boolean {
    return this.pageWidth === 'md';
  }

  /**
   * Applies a class to the page if the page width is set to large.
   */
  @HostBinding('class.page-width-lg') get isLargePage(): boolean {
    return this.pageWidth === 'lg';
  }

  /**
   * Applies a class to the page to force the page width extra large.
   */
  @HostBinding('class.force-page-width-xl') get isForceExtraLargePageWidth(): boolean {
    return this.forcePageWidth === 'xl';
  }

  /**
   * Applies a class to the page if it contains a toolbar with tallHeader
   * set to true. This is used to nudge the page content up a few pixels
   * to hide the mad card's border.
   */
  @HostBinding('class.has-tall-header') get hasTallHeader(): boolean {
    return this.pageToolbar && this.pageToolbar.tallHeader;
  }

  /**
   * Applies a class to the page if it contains a drawer.
   */
  @HostBinding('class.has-page-drawer') get hasPageDrawer(): boolean {
    return !!this.pageDrawer || !!this.pageDrawerAnchor;
  }

  /**
   * Applies a class to the page if the page width is set to small.
   */
  @HostBinding('class.has-page-nav') get hasPageNav(): boolean {
    return !!this.pageNav;
  }

  @ViewChild(CdkScrollable, { static: false }) scrollable: CdkScrollable;

  /**
   * Reference to page `PageBackdropComponent` instance.
   */
  @ViewChild(PageBackdropComponent, { static: false }) backdrop: PageBackdropComponent;

  /**
   * Store backdrop ref to indicate backdrop is active.
   */
  backdropRef: PageBackdropRef<any>;

  /**
   * Set the page width. 'xl' Is the default and does not add additional styling
   * 'sm' creates a smaller content area centered on the page.
   */
  @Input() pageWidth: 'sm' | 'md' | 'lg' | 'xl' = 'xl';



  /**
   * Set the minimum page width so that extra wide content fits
   * inside the content container.  Use this sparingly.
   */
  @Input() forcePageWidth: 'xl' | '' = '';

  /**
   * Indicates whether the page drawer is opened or closes.
   * Default: always shows page drawer.
   */
  @Input() showPageDrawer = true;

  /**
   * The page toolbar, if it is included in the page.
   */
  @ContentChild(PageToolbarComponent, { static: false }) pageToolbar: PageToolbarComponent;

  /**
   * The page nav, if it is included in the page.
   */
  @ContentChild(PageNavComponent, { static: false }) pageNav: PageNavComponent;

  /**
   * The page drawer anchor component instance used in the page.
   */
  @ContentChild(PageDrawerAnchorComponent, { static: false }) pageDrawerAnchor: PageDrawerAnchorComponent;

  /**
   * The page drawer component instance used in the page.
   */
  @ContentChild(PageDrawerComponent, { static: false }) pageDrawer: PageDrawerComponent;

  private document: Document;

  private scrollSub: Subscription;

  hasTopBanners$ = this.appBannerService.getLocation$(BannerLocation.top).pipe(
    distinctUntilChanged(undefined, curr => curr?.length || 0),
    map(banners => Boolean(banners?.length)),
  );

  constructor(
    @Inject(DOCUMENT) document: any,
    private appBannerService: AppBannerService,
    private cdr: ChangeDetectorRef,
    private pageService: PageService,
    private renderer: Renderer2,
    private scroll: ScrollDispatcher,
    private zone: NgZone,
  ) {

    // Typing document as any and then assigning as Document type to avoid Angular complaining about being unable to
    // resolve type Document. See:
    // https://github.com/angular/angular/issues/20351#issuecomment-446025223
    this.document = document as Document;
  }

  /**
   * Initialization function.
   */
  ngOnInit() {
    if (!this.disabled) {
      // NOTE: adding this class to support legacy AngularJS implementations. Someday this class adding/removing
      // and related code can be cleaned up.
      this.renderer.addClass(this.document.body, 'has-page-component');
    }
  }

  /**
   * Destroy function.
   */
  ngOnDestroy() {
    if (!this.disabled) {
      this.renderer.removeClass(this.document.body, 'has-page-component');
    }

    if (this.scrollSub) {
      this.scrollSub.unsubscribe();
    }
  }

  /**
   * Post view initialization function.
   */
  ngAfterViewInit() {
    this.scrollSub = this.scroll.scrolled(150).pipe(
      filter((data: CdkScrollable) => data?.getElementRef() === this.scrollable?.getElementRef())
    ).subscribe((data: CdkScrollable) => {
      if (!data) {
        return this.hasScrollOffset$ = false;
      }
      this.zone.run(() => {
        const prevHasScrollOffset = this.hasScrollOffset$;
        this.hasScrollOffset$ = data.getElementRef().nativeElement.scrollTop > 1;
        if (this.hasScrollOffset$ !== prevHasScrollOffset) {
          // Fire change detection in case this component is a child of a component that has an OnPush strategy.
          // NOTE: This has the unfortunate downside of cascading change detection through that parent component.
          this.cdr.markForCheck();
        }
      });
      return this.hasScrollOffset$;
    });
  }

  /**
   * Renders component or template ref to page backdrop component.
   *
   * @param    componentOrTemplateRef  Component or template ref to render in backdrop.
   * @param    config                  Optional config to pass as injectable to rendered component inside backdrop.
   * @returns                          Backdrop reference to manage currently active backdrop.
   */
  setBackdrop<T>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config: BackdropConfig = null): PageBackdropRef<T> {
    if (this.backdrop && !this.backdropRef) {
      this.backdropRef = this.backdrop.attach(componentOrTemplateRef, config);
      this.backdropRef.afterClosed().subscribe(() => this.backdropRef = null);
    }

    this.scrollable.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    return this.backdropRef;
  }

  /**
   * Toggle the page drawer state from open to close or close to open.
   */
  togglePageDrawer() {
    this.showPageDrawer = !this.showPageDrawer;
  }
}
