<mat-chip class="{{tagColor}}"
  [class.is-removable]="isRemovable"
  [matTooltip]="tag.name | tagName"
  [disableRipple]="!isSelectable">
  <cog-icon shape="check" size="sm" matChipAvatar *ngIf="isSelected"></cog-icon>
  <span class="vendor-name" *ngIf="tag.type === TagType.ThirdParty">{{ tag.thirdPartyName }}</span>
  <span class="tag-name" cogDataId="coh-tag-{{tag.name}}">{{ tag.name | tagName }}</span>
  <button
    mat-icon-button
    class="remove-button"
    [attr.aria-label]="'remove' | translate"
    (click)="onRemove()"
    cogDataId="remove-tag-{{tag.name}}"
    *ngIf="isRemovable">
    <cog-icon shape="close" size="sm"></cog-icon>
  </button>
</mat-chip>
