<cog-value-property-filter
  *cogFilterDef="
    let params = params;
    let filter;
    quickFilter: true;
    key:  filterName;
    label: 'changeType' | translate;
    filterType: 'valueProperty'
  "
  [filterDefParams]="params"
  [filter]="filter"
  [allowMultiple]="true"
  [filterValues]="changeTypeFilterValues || []"
  [inheritDataId]="true"
  [preLabel]="'changeTypeColon' | translate"
  cogDataId="change-type-filter">
</cog-value-property-filter>
