import { DOCUMENT } from '@angular/common';
import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { legacyMixinColor as mixinColor } from '@angular/material/legacy-core';
import { NavBanner } from '../../app-frame/nav-banner/nav-banner.component';

import { PageService } from '../page.service';

@Component({
  selector: 'cog-page-actions',
  template: '<ng-content></ng-content>',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./page-actions.component.scss'],
})
export class PageActionsComponent {}

@Component({
  selector: 'cog-page-breadcrumbs',
  template: '<ng-content></ng-content>',
  encapsulation: ViewEncapsulation.None,
})
export class PageBreadcrumbsComponent {}

@Component({
  selector: 'cog-page-title',
  template: '<ng-content></ng-content>',
})
export class PageTitleComponent {}

// TODO: all of the logic in this dummy component is to serve classic UI styling and can be ripped out once classic Iris
// is eliminated. (The compomonent should stay, but requires no logic).
@Component({
  selector: 'cog-page-nav',
  template: '<ng-content></ng-content>',
  styleUrls: ['./page-nav.component.scss']
})
export class PageNavComponent implements OnInit, OnDestroy {
  /**
   * HTML Document.
   */
  document?: Document;

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) document: any) {
    // Typing document as any and then assigning as Document type to avoid Angular complaining about being unable to
    // resolve type Document. See:
    // https://github.com/angular/angular/issues/20351#issuecomment-446025223
    this.document = document as Document;
  }

  /**
   * Initialization function.
   */
  ngOnInit() {
    this.renderer.addClass(this.document.body, 'has-page-nav');
  }

  /**
   * Destroy function.
   */
  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, 'has-page-nav');
  }
}

/** @docs-private */
export class PageToolbarBase {
  constructor(public _elementRef: ElementRef) {}
}
export const _PageToolbarMixinBase: typeof PageToolbarBase = mixinColor(PageToolbarBase);

/**
 * Layout component that provides a page header container with appropriate
 * stylings built-in. Styles disabled via disabled attribute sniffing. Use
 * disabled directive to disable the styling programatically. This is primarily
 * for modal/cSlideModal implementation.
 *
 * @example
 *   <cog-page-toolbar [title]="'dashboard' | translate" [disabled]="inModal"></cog-page-toolbar>
 */
@Component({
  selector: 'cog-page-toolbar',
  templateUrl: './page-toolbar.component.html',
  styleUrls: ['./page-toolbar.component.scss'],

  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['color'],
})
export class PageToolbarComponent extends _PageToolbarMixinBase implements OnInit, OnDestroy {
  /**
   * Adds disabled class for styling purposes based on disabled @Input value.
   */
  @HostBinding('class.disabled') get isDisabled() {
    return this.pageService.isDisabled;
  }

  /**
   * Adds disabled class for styling purposes based on disabled @Input value.
   */
  get isTitleOverwrite(): boolean {
    return !!this.pageTitleComponent;
  }

  /**
   * A title to be displayed in the toolbar.
   * Shouldn't be provided if title is backText is provided.
   */
  @Input() title: string;

  /**
   * Optional text to be displayed next to the back button.
   * Shouldn't be provided if title is provided.
   */
  @Input() backText: string;

  /**
   * Optional cog-icon shape to be displayed besides the back button.
   */
  @Input() backButtonIcon: string;


  /**
   * Applies the class needed to make the toolbar shadow dynamaic. This will be applied
   * based on `dynamicShadow` input or automatically if `backText` is provided.
   */
  @HostBinding('class.dynamic-shadow')
  public get isShadowDynamic(): boolean {
    return (this.dynamicShadow === null || this.dynamicShadow === undefined)
      ? !!this.backText
      : this.dynamicShadow;
  }

  /**
   * An override to make the shadow dynamic (appear after scrolling down)
   */
  @Input() dynamicShadow = true;

  /**
   * This overrides both implementations above of dynamic shadow.
   * With this property set, there will be no shadow, even when content is offset.
   */
  @HostBinding('class.no-dynamic-shadow')
  @Input() noDynamicShadow = false;

  /**
   * Optional tooltip to be displayed when hovering over the back button.
   */
  @Input() backTooltip: string;

  /**
   * A flag to hide back button
   */
  @Input() hideBack = false;

  /**
   * Optional function handle back button clicks. If not assigned, back button won't be displayed.
   */
  @Output() back = new EventEmitter<void>();

  /**
   * If set, this shows the second row of the toolbar with the same color as the main toolbar to create a
   * 'tall' effect. The inside of the toolbar is set to a max-width to match the page content and uses the
   * card's background color.
   */
  @Input() tallHeader = false;

  /**
   * Nav Banner data. Show it if exist.
   */
  _navBanner: NavBanner;
  @Input() set navBanner(navBanner: NavBanner) {
    this._navBanner = navBanner;
  }

  get navBanner(): NavBanner {
    return this._navBanner;
  }

  /**
   * Indicates if the back button should be displayed based on wether the EventEmitter has been subscribed to.
   */
  get showBack(): boolean {
    return !this.hideBack && this.back.observers.length > 0;
  }

  /**
   * HTML Document.
   */
  document?: Document;

  /**
   * Expose PageBreadcrumbsComponent for ngIf.
   */
  @ContentChild(PageBreadcrumbsComponent, {static: false}) pageBreadcrumbsComponent: PageBreadcrumbsComponent;

  /**
   * Expose PageTitleComponent for ngIf.
   */
  @ContentChild(PageTitleComponent, {static: false}) pageTitleComponent: PageTitleComponent;

  /**
   * Expose PageNavComponent for ngIf.
   */
  @ContentChild(PageNavComponent, {static: false}) pageNavComponent: PageNavComponent;

  /**
   * Expose PageActionsComponent for ngIf.
   */
  @ContentChild(PageActionsComponent, {static: false}) pageActionsComponent: PageActionsComponent;

  constructor(
    private elementRef: ElementRef,
    private pageService: PageService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) document: any) {
    super(elementRef);

    // Typing document as any and then assigning as Document type to avoid Angular complaining about being unable to
    // resolve type Document. See:
    // https://github.com/angular/angular/issues/20351#issuecomment-446025223
    this.document = document as Document;
  }

  /**
   * Initialization function.
   */
  ngOnInit() {
    this.setPageHeaderStyles();

    // Default to "close" icon if this toolbar is inside a takeover page and no custom icon is specified.
    const parentTakeoverPage = (this.elementRef.nativeElement as Element).closest('.cog-takeover-theme');

    if (parentTakeoverPage && !this.backButtonIcon) {
      this.backButtonIcon = 'close';
    }
  }

  /**
   * Destroy function.
   */
  ngOnDestroy() {
    if (!this.pageService.isDisabled) {
      this.renderer.removeClass(this.document.body, 'has-page-toolbar');
    }
  }

  /**
   * Adds class to body if component is not disabled. Once added, the class is not removed from the body until component
   * is destroyed. Disabled should really only be used if the component is being used in a modal.
   */
  setPageHeaderStyles() {
    /**
     * Don't add or remove the body class if component is 'disabled'. Styling
     * won't be applied anyway, and there's a good chance there is a
     * cogPageToolbar on the underlying page that is relying on and managing
     * the class.
     */
    if (!this.pageService.isDisabled) {
      this.renderer.addClass(this.document.body, 'has-page-toolbar');
    }
  }
}
