import { Component } from '@angular/core';
import { ClusterConfig, IrisContextService } from '@cohesity/iris-core';
import { AppStateService, UserService } from 'src/app/core/services';

@Component({
  selector: 'coh-selected-scope',
  templateUrl: './selected-scope.component.html',
  styleUrls: ['./selected-scope.component.scss']
})
export class SelectedScopeComponent {
  constructor(
    private appStateService: AppStateService,
    private userService: UserService,
    private irisContext: IrisContextService,
  ) {}

  /**
   * Get the selected cluster.
   */
  get selectedScope(): ClusterConfig {
    return this.appStateService.selectedScope;
  }

  /**
   * Determines whether we need more cluster to allow switching scope.
   */
  get needMoreClusters(): boolean {
    if (!this.appStateService.remoteClusterList) {
      return true;
    }

    // If the user is restricted then do not allow the user to select any cluster.
    if (this.userService.user.restricted) {
      return true;
    }

    // Because one of the "remote clusters" is the local cluster, more clusters are needed if there is only one.
    return this.appStateService.remoteClusterList.length === 1;
  }
}
