import { Injectable } from '@angular/core';
import { ProtectdObjectsActionRequest } from '@cohesity/api/v2';
import { DataTreeSelection, NavItem } from '@cohesity/helix';
import { IrisContextService, isDmsScope, isIbmBaaSEnabled } from '@cohesity/iris-core';
import { SourceSelection } from '@cohesity/iris-source-tree';
import { StateService } from '@uirouter/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { StateManagementService } from 'src/app/core/services';
import { RestoreConfigService } from 'src/app/modules/restore/restore-shared';
import { Environment, HostType, RecoveryAction } from 'src/app/shared';
import { SqlSourceDataNode } from 'src/app/shared/source-tree';
import { OracleSourceDataNode } from 'src/app/shared/source-tree/protection-source/oracle/oracle-source-data-node';

import { DbObjectActionCreator } from './db-object-action-creator';
import { ObjectActionCreator } from './object-action-creator';
import { ObjectActionOptions } from './object-action-options.model';
import { ObjectActionProvider } from './object-action-provider';
import { ObjectInfoService, ObjectInfoServiceOptions } from './object-info.service';
import { SimpleObjectInfo } from './object-menu-provider';
import { ObjectProtectAction } from './object-protect-action.constants';

/**
 * Type for Database source data node. Currently, only applicable for SQL and Oracle.
 */
type DbSourceDataNode = SqlSourceDataNode | OracleSourceDataNode;

@Injectable()
export class DbObjectActionProvider extends ObjectActionProvider {
  /**
   * The provider for this service is manually set up in object-actions-menu.service, which must provide
   * the list of providers as an array in the correct order. In order to maintain some kind of sanity,
   * the providers are listed here, they should match the order of the constructor args.
   */
  static dbObjectActionProviderDependencies = [
    ObjectInfoService,
    RestoreConfigService,
    StateManagementService,
    StateService,
    ObjectActionCreator,
    IrisContextService,
    DbObjectActionCreator,
  ];

  exclusiveProtection = true;

  supportsBulkActions = false;

  constructor(
    readonly objectStore: ObjectInfoService,
    readonly restoreConfig: RestoreConfigService,
    readonly stateManagementService: StateManagementService,
    readonly stateService: StateService,
    readonly actionCreator: ObjectActionCreator,
    readonly irisContextService: IrisContextService,
    private dbActionCreator: DbObjectActionCreator,
  ) {
    super(objectStore, restoreConfig, stateManagementService, stateService, actionCreator, irisContextService);
  }

  /**
   * Creates and returns the db action for the specified object.
   *
   * @param object The protected object.
   * @param type   The type of db action to check for.
   * @returns An observable, which yields the NavItem or null.
   */
  getDbAction(object: SimpleObjectInfo, type: 'migrate' | 'clone'): Observable<NavItem> {

    // Return null if protected object info isn't available or if migrate action has
    // been selected which is not supported in case of oracle or in case of IBM Cloud
    // Cluster hide Clone/Migrate option.
    if (!this.objectStore.getProtectedObject ||
      (object.environment === Environment.kOracle && type === 'migrate') ||
      (['clone', 'migrate'].includes(type) && isIbmBaaSEnabled(this.irisContextService.irisContext))) {
      return of(null);
    }

    return this.objectStore.getProtectedObject(
      object.id,
      { accessClusterId: object.accessClusterId, regionId: object.regionId }
    ).pipe(
      map(storeEntry => storeEntry.item),
      map(protectedObject => {
        if (!protectedObject || !protectedObject?.latestSnapshotsInfo) {
          return null;
        }

        return this.dbActionCreator.createDbAction(
          object,
          protectedObject,
          type,
          { accessClusterId: object.accessClusterId, regionId: object.regionId }
        );
      })
    );
  }

  getObjectActions(object: SimpleObjectInfo): Observable<NavItem[]> {
    if (isDmsScope(this.irisContextService.irisContext)) {
      // DB specific actions are currently non NG and will not supported in DMaaS.
      return super.getObjectActions(object);
    }

    return combineLatest([
      super.getObjectActions(object),
      this.getDbAction(object, 'migrate'),
      this.getDbAction(object, 'clone'),
    ]).pipe(
      map(([baseActions, ...dbActions]) => [...baseActions, ...dbActions]),
      map(actions => actions.filter(action => Boolean(action)))
    );
  }

  /**
   * Gets a list of nav item options available for one or more objects. This assumes that
   * the selection is done within the context of a source tree, and will have additional
   * information about autoprotected, selected, or excluded items.
   *
   * @param   objects          The object to get actions for
   * @param   sourceSelection  The transformed api tree selection including excluded and special
   *                           params
   * @param   objectOptions    The object's action options
   * @returns Any applicable actions that can be run for all items
   */
  getBulkTreeObjectActions(
    selection: DataTreeSelection<DbSourceDataNode>,
    sourceSelection: SourceSelection,
    objectOptions: ObjectActionOptions = {}
  ): Observable<NavItem[]> {
    if (!selection || (!selection.selected.length && !selection.autoSelected.length)) {
      return of([]);
    }

    const toObjectInfo = (object: DbSourceDataNode) => ({
      id: object.protectionSource.id,
      environment: object.environment,

      // If a root node is selected, it will not have a parent source id,
      // so we can just use its own id instead.
      sourceId: object.protectionSource.parentId || object.protectionSource.id,
      isProtected: object.protected,
      objectType: object.type,
      accessClusterId: objectOptions.accessClusterId,
      regionId: objectOptions.regionId,
    });

    // For protection and recovery, we should remove the auto selected nodes from our list
    // and include the specific auto protected nodes instead.
    // Otherwise, a selected non-leaf node will end up being auto protected in the tree
    // even though that is not the user's intent.
    // However, for protected object actions, we should include the selected nodes and
    // _not_ the auto selected nodes, since the user can perform actions directly on these nodes.

    // For MSSQL and Oracle, the leaf nodes are databases. This ensures we do not select
    // other object types such as PhysicalHost which is not a leaf node nor is auto selectable.
    const selectedLeafNodes = selection.selected.filter(node => node.isLeaf);
    const objects = [...selectedLeafNodes, ...selection.autoSelected];
    const objectInfos: SimpleObjectInfo[] = objects.map(toObjectInfo);
    const allInfos = selection.selected.map(toObjectInfo);

    return combineLatest([
      this.getBulkProtectAction(objectInfos, sourceSelection),
      this.getBulkRecoveryAction(objectInfos, RecoveryAction.RecoverApps),
      this.getProtectedObjectAction(allInfos, ObjectProtectAction.ProtectNow),
      this.getProtectedObjectAction(allInfos, ObjectProtectAction.UnProtect),
      this.getProtectedObjectAction(allInfos, ObjectProtectAction.Resume),
    ]).pipe(
      map(actions => actions.filter(action => this.filterActionByAccess(action))),
      map(actions => this.sortActions(actions))
    );
  }

  /**
   * Returns whether we can protect the specified object.
   *
   * @param object The protected object.
   * @returns Whether it can be protected.
   */
  canProtect(object: SimpleObjectInfo): boolean {
    if (isDmsScope(this.irisContextService.irisContext) &&
        object.v1Object?.protectionSource?.physicalProtectionSource?.hostType === HostType.kWindows) {
      return false;
    }

    return super.canProtect(object);
  }

  /**
   * Determines the backup type for the given object.
   *
   * @param     object   Specifies the object on which action is to be
   *                     performed.
   * @returns   Returns the object action key.
   */
  private getObjectActionKey(object: SimpleObjectInfo): ProtectdObjectsActionRequest['objectActionKey'] {
    if (object?.objectActionKey) {
      return object.objectActionKey;
    }
    const objectActionKey = object.environment as ProtectdObjectsActionRequest['objectActionKey'];
    return objectActionKey;
  }

  /**
   * Extract the additional lookup options for an object from the simple object
   *
   * @param object The current object
   * @returns An options object with the accessClusster or regionId set and the action key if any.
   */
  protected getObjectInfoOptions(object: SimpleObjectInfo): ObjectInfoServiceOptions {
    return {
      accessClusterId: object.accessClusterId,
      regionId: object.regionId,
      actionKey: object.objectActionKey || this.getObjectActionKey(object),
    };
  }
}
