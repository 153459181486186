<p>{{ 'certificateAuthorityDialog.selectTypeOfCertificateAuthority' | translate }}</p>
<p>{{ 'certificateAuthorityDialog.applyingCertificatesText' |  translate }}</p>

<cog-banner *ngIf="showNotSecuredData" status="warn" class="margin-bottom-lg width-50">
    {{ 'certificateAuthorityDialog.bannerEnhanceSecurity' |  translate }}
</cog-banner>

<table class="table">
    <tr class="table-head">
        <th *ngFor="let column of displayedColumns">{{ column | translate }}</th>
    </tr>
    <tr *ngFor="let item of allInterfaceTypes">
        <td>
            <div class="table-interface-text">
                <h4 class="interface-item-title">{{ item.title | translate }}</h4>
                <p>{{ item.subtitle | translate }}</p>
            </div>
        </td>
        <td class="secured-button-container" [ngClass]="{'transparent-background': !showNotSecuredData}">
            <button
                mat-icon-button
                (click)="selectCa(item.type, 'Cohesity CA')"
                class="unselected-button"
                [ngClass]="{'selected-button': this[item.type] === 'Cohesity CA'}">
                <cog-icon
                    class="button-icon"
                    shape="helix:cohesity-logo-s-gray">
                </cog-icon>
                <cog-icon
                    class="button-icon-selected"
                    size="sm"
                    *ngIf="this[item.type] === 'Cohesity CA'"
                    shape="helix:status-success-alt">
                </cog-icon>
                <span>{{ 'certificateAuthorityDialog.cohesityCa' | translate }}</span>
            </button>
            <button
                mat-icon-button
                (click)="selectCa(item.type, 'External CA')"
                class="unselected-button"
                [ngClass]="{'selected-button': this[item.type] === 'External CA'}">
                <cog-icon
                    class="button-icon"
                    shape="helix:badge">
                </cog-icon>
                <cog-icon
                    class="button-icon-selected"
                    size="sm"
                    *ngIf="this[item.type] === 'External CA'"
                    shape="helix:status-success-alt">
                </cog-icon>
                <span>{{ 'certificateAuthorityDialog.externalCa' | translate }}</span>
            </button>
        </td>
        <td *ngIf="showNotSecuredData" class="not-secured-button-container">
            <button
                mat-icon-button
                (click)="selectCa(item.type, 'Self-Signed')"
                class="unselected-button"
                [ngClass]="{'selected-button': this[item.type] === 'Self-Signed'}">
                <cog-icon
                    class="button-icon"
                    shape="helix:edit-doc">
                </cog-icon>
                <cog-icon
                    class="button-icon-selected"
                    size="sm"
                    *ngIf="this[item.type] === 'Self-Signed'"
                    shape="helix:status-success-alt">
                </cog-icon>
                <span>{{ 'certificateAuthorityDialog.selfSigned' | translate }}</span>
            </button>
        </td>
    </tr>
</table>