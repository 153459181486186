import { Injectable } from '@angular/core';
import { Transition } from '@uirouter/core';
import { NGXLogger } from 'ngx-logger';
import { HomeService } from '../../services/home.service';
import { StateManagementService } from '../../services/state-management.service';
import { GuardPriority, GuardResult, StateGuard } from '../state-guard';

/**
 * State access guard is responsible for validating if the currently logged in user has access to the target state.
 * If the user cannot access the state, they will be redirected to the home state.
 */
@Injectable({
  providedIn: 'root'
})
export class StateAccessGuard implements StateGuard {
  /**
   * This guard should be run after the scope initialisation is complete in Scope Guard
   */
  guardPriority: GuardPriority = GuardPriority.StateAccessGuard;

  constructor(
    private homeService: HomeService,
    private log: NGXLogger,
    private stateManagementService: StateManagementService,
  ) {}

  /**
   * Perform state access check and redirect user to home state if the state is not accessible
   *
   * @param  transition  The current Transition object.
   */
  onStart(transition: Transition): GuardResult {
    const toState = transition.$to();
    const toParams = transition.params();
    const fromState = transition.$from();
    const canAccess = this.stateManagementService.canUserAccessState(toState.name, toParams);

    // don't load the state if user is not having access to target state
    if (!canAccess) {
      transition.abort();

      // If this state can't be loaded go to the home state.
      this.homeService.goHome();

      this.log.info(
        'Logged-in user does not have required access for the state',
        '`' + toState.name + '`',
        '(Navigated from',
        '`' + fromState.name + '`)'
      );
    }

    return true;
  }
}
