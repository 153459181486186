import { UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { Validator as IpNumValidator } from 'ip-num';
import { REGEX_FORMATS } from '@cohesity/utils';

/**
 * Validator for only ipv4 address.
 *
 * @param control The form control whose value will be validated.
 * @return null if a valid string, otherwise error object.
 */
export const Ipv4AddressValidator: ValidatorFn = (control: UntypedFormControl) => {
  if (!control.value) {
    return null;
  }

  const isIpv4 = REGEX_FORMATS.IPv4.test(control.value || '');
  return isIpv4 ? null : { ipv4AddressError: true };
};

/**
 * Validator for only ipv6 address.
 *
 * @param control The form control whose value will be validated.
 * @return null if a valid string, otherwise error object.
 */
export const Ipv6AddressValidator: ValidatorFn = (control: UntypedFormControl) => {
  if (!control.value) {
    return null;
  }
  const isIpv6 = IpNumValidator.isValidIPv6String(control.value || '')[0];
  return isIpv6 ? null : { ipv6AddressError: true };
};

/**
 * Validator for both ipv4 and ipv6 address.
 *
 * @param control The form control whose value will be validated.
 * @return null if a valid string, otherwise error object.
 */
export const IpAddressValidator: ValidatorFn = (control: UntypedFormControl) => {
  if (!control.value) {
    return null;
  }
  const isIpAddress = REGEX_FORMATS.IPv4AndIPv6.test(control.value || '');
  return isIpAddress ? null : { ipAddressError: true };
};

/**
 * Validator for ipv4 subnet cidr address.
 *
 * @param control The form control whose value will be validated.
 * @return null if a valid string, otherwise error object.
 */
export const Ipv4CidrAddressValidator: ValidatorFn = (control: UntypedFormControl) => {
  if (!control.value) {
    return null;
  }
  const isIpv4Cidr = IpNumValidator.isValidIPv4CidrNotation(control.value || '')[0];
  return isIpv4Cidr ? null : { ipv4CidrError: true };
};



/**
 * Validator for ipv4 subnet cidr address.
 *
 * @param control The form control whose value will be validated.
 * @return null if a valid string, otherwise error object.
 */
export const Ipv4CidrAddressValidatorWithSubnetFixed: ValidatorFn = (control: UntypedFormControl): ValidationErrors | null => {
  // Check for no values
  if (!control.value) {
    return { ipv4CidrError: true };
  }

  // Check if not valid ip addresses
  if (!IpNumValidator.isValidIPv4CidrNotation(control.value || '')[0]) {
    return { ipv4CidrError: true };
  }

  // Check if valid netmaskBits addresses
  const [, netmaskBits] = control.value && control.value.split('/') || '';
  if (Number(netmaskBits) > 22) {
    return { ipv4CidrSubnetUpto22Bits: true };
  }

  return null;
};

/**
 * Validator for ipv4 subnet mask address.
 *
 * @param control The form control whose value will be validated.
 * @return null if a valid string, otherwise error object.
 */
export const Ipv4SubnetMaskValidator: ValidatorFn = (control: UntypedFormControl) => {
  if (!control.value) {
    return null;
  }
  const isIpv4SubnetMask = IpNumValidator.isValidIPv4Mask(control.value || '')[0];
  return isIpv4SubnetMask ? null : { ipv4SubnetMaskError: true };
};

/**
 * Validator for ipv6 subnet cidr address.
 *
 * @param control The form control whose value will be validated.
 * @return null if a valid string, otherwise error object.
 */
export const Ipv6CidrAddressValidator: ValidatorFn = (control: UntypedFormControl) => {
  if (!control.value) {
    return null;
  }
  const address: string = control.value || '';
  const isIpv6Cidr = IpNumValidator.isValidIPv6CidrNotation(address)[0] && address.indexOf('/') !== -1;
  return isIpv6Cidr ? null : { ipv6CidrError: true };
};

/**
 * Validator for both ipv4 and ipv6 address/subnet.
 *
 * @param control The form control whose value will be validated.
 * @return null if a valid string, otherwise error object.
 */
export const IpAddressCidrValidator: ValidatorFn = (control: UntypedFormControl) => {
  if (!control || !control.value) {
    return null;
  }
  const isIpAddressOrCidrAddress = isValidIpOrSubnet(control.value);
  return isIpAddressOrCidrAddress ? null : {ipOrCidrAddress: true};
};

/**
 * Validator which returns true if control has either ipv4/ipv6 cidr address.
 *
 * @param control The form control whose value will be validated.
 * @return null if a valid string, otherwise error object.
 */
export const CidrValidator: ValidatorFn = (control: UntypedFormControl) => {
  const address = control.value;
  if (!control || !address) {
    return null;
  }
  return [
    IpNumValidator.isValidIPv4CidrNotation(address || '')[0],
    IpNumValidator.isValidIPv6CidrNotation(address || '')[0],
  ].some(Boolean) ? null : {cidrAddressError: true};
};

/**
 * Return true if address is valid ipv4/ipv6 address or subnet.
 *
 * @param address IP address string to validate.
 * @return True/false based on validity of address.
 */
export function isValidIpOrSubnet(address: string): boolean {
  return [
    IpNumValidator.isValidIPv4String(address || '')[0],
    IpNumValidator.isValidIPv6String(address || '')[0],
    IpNumValidator.isValidIPv4CidrNotation(address || '')[0],
    IpNumValidator.isValidIPv6CidrNotation(address || '')[0],
  ].some(Boolean);
}
