import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Type for observable map passed to combineLatestObject.
 */
type CombineLatestObject = Record<string, Observable<any>>;

/**
 * Type for value returned by combineLatestObject.
 */
type CombineLatestResult = Record<string, any>;

/**
 * Function which takes an observable map which can be subscribed to.
 *
 * @param object Object with value as observables to subscribe.
 * @return Subscription which emits an object.
 */
export function combineLatestObject<
  T = CombineLatestResult,
  R = CombineLatestObject
>(object: R): Observable<T> {
  const entries = Object.entries(object);

  return combineLatest(entries.map(item => item[1])).pipe(
    map(results => {
      const resultObj = {};

      for (let i = 0; i < results.length; i++) {
        resultObj[entries[i][0]] = results[i];
      }

      return resultObj as T;
    })
  );
}
