import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Controls, NgxSubFormComponent, subformComponentProviders, takeUntilDestroyed } from 'ngx-sub-form';

import { ClusterInterface } from '../../models';

@Component({
  selector: 'coh-settings-list-cluster-interface',
  templateUrl: './settings-list-cluster-interface.component.html',
  styleUrls: ['./settings-list-cluster-interface.component.scss'],
  providers: subformComponentProviders(SettingsListClusterInterfaceComponent),
})
export class SettingsListClusterInterfaceComponent
  extends NgxSubFormComponent<ClusterInterface> implements OnInit {
  /**
   * Component on init.
   */
  ngOnInit() {
    this.formGroupControls.auto.valueChanges.pipe(
      takeUntilDestroyed(this)
    ).subscribe(value => {
      if (value) {
        this.formGroupControls.interfaceGroup.setValue(null);
        this.formGroupControls.interfaceGroup.setValidators(null);
        this.formGroupControls.interfaceGroup.setErrors(null);
      } else {
        this.formGroupControls.interfaceGroup.setValidators(Validators.required);
      }
    });
  }

  getDefaultValues(): Partial<ClusterInterface> {
    return {
      auto: true,
    };
  }

  /**
   * Function to return all the form controls.
   *
   * @return The form controls object.
   */
  protected getFormControls(): Controls<ClusterInterface> {
    return {
      auto: new UntypedFormControl(null, Validators.required),
      interfaceGroup: new UntypedFormControl(),
    };
  }
}
