import { clamp, isEqual, merge } from 'lodash';
import { Observable, Subject, timer } from 'rxjs';
import { distinctUntilChanged, retry, switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';

import { AutoDestroyable } from './../common-behaviors';

const INTERVAL_MAX = 10e20;
const INTERVAL_MIN = 1000;

// NOTE: Keep these values in sync with the PollingService UTs
const DEFAULT_POLL_CONFIG: Partial<PollingConfig<any>> = {
  acceptFirstIteration: true,
  interval: 30,
  maxRetries: 3,
};

/**
 * Config object for poller.
 *
 * @export
 */
export interface PollingConfig<T> {
  /**
   * Tells the poller whether or not to accept the first iteration if it fails
   * the shouldContinue() check.
   */
  acceptFirstIteration?: boolean;

  /**
   * The polling interval, in seconds.
   */
  interval?: number;

  /**
   * Max nubmer of retries before self-terminating.
   */
  maxRetries?: number;

  /**
   * Whether to emit the data only when changed.
   *
   * Can be a boolean which would compare the whole object change or
   * can be a function which handles the comparator in the config itself.
   */
  distinctUntilChanged?: boolean | ((prev: T, curr: T) => boolean);

  /**
   * Function to run first, and on every subsequent poll iteration.
   *
   * @returns   The observable to poll.
   */
  iterator(): Observable<T>;

  /**
   * Function to run against each iteration response to determine if polling is
   * complete.
   *
   * @returns   True if polling should continue. False to stop
   */
  shouldContinue(data: any): boolean;
}

/**
 * Generic poller Class. Sets up an observable on a given iterator Fn and
 * various configs. Will continue indefinately until explicitely destroyed,
 * automatically destroyed, or the shouldContinue config function returns false.
 *
 * See interface#PollingConfig for configuration details.
 *
 * @example
 *   const config: PollingConfig = {...};
 *
 *   // Initialize the poller
 *   const myPoller = new Poller(config);
 *
 *   // Subscribe to iterator results.
 *   myPoller.poller.subscribe(data => console.log(data));
 *
 *   // All done, clean up.
 *   myPoller.finish();
 */
export class Poller<T> extends AutoDestroyable {
  /**
   * The Observable poller created by this Poller instance.
   */
  get poller(): Observable<T> {
    return this._poller.asObservable();
  }

  /**
   * The poller subject to emit events on.
   */
  private _poller = new Subject<T>();

  /**
   * Creates an instance of Poller.
   *
   * @param   config   The PollerConfig object to use.
   */
  constructor(public config: PollingConfig<T>) {
    super();
    this.config = merge({}, DEFAULT_POLL_CONFIG, this.config);
    this.start();
  }

  /**
   * Start this Poller instance.
   *
   * @returns    The Observable poller.
   */
  private start() {
    // Ensure the interval is at least 1 second.
    const pollInterval = clamp(this.config.interval * 1000, INTERVAL_MIN, INTERVAL_MAX);

    // This is used when checking if the poller should continue or not. The
    // value is incremented at each iteration and coerced to a boolean to
    // determine if checking should continue along with the specified
    // shoudlContinue function in the config.
    let iterationsCount = this.config.acceptFirstIteration ? 0 : 1;

    this.createTimer(pollInterval).pipe(
      // Map the timer to a new observable
      switchMap(() => this.config.iterator()),

      // If config.distinctUntilChanged is set to true or a defined function,
      // then only emit when data is changed or when the comparator function
      // returns true. If false/unset, then return as normal.
      distinctUntilChanged((prev, curr) => {
        if (!this.config.distinctUntilChanged) {
          return false;
        }

        return typeof this.config.distinctUntilChanged === 'function' ?
          this.config.distinctUntilChanged(prev, curr) : isEqual(prev, curr);
      }),

      // Emit the value on the poller each time new data is retrieved.
      tap(data => this._poller.next(data)),

      // Continue polling until the shouldContinue checks fail.
      takeWhile(data => {
        const shouldContinue =
          // !0 means continue (true). !anything greater than 0 (false) means
          // fall through to the shouldContinue check. The return value from
          // num++ expresisons is the value _before_ incrementing.
          !iterationsCount++ ||

          // The config defined shouldContinue check.
          this.config.shouldContinue(data);

        if (!shouldContinue) {
          this.finish();
        }

        return shouldContinue;
      }),

      // Stop when this thing is destroyed, Without this, there will be an extra poll
      // even after calling finish()
      takeUntil(this._destroy),

      // Retry this many times. These do not wait for interval, but retry
      // immediately.
      retry(this.config.maxRetries)
    ).subscribe();
  }

  /**
   * Creates the timer for the poll. Extracting this to its own method makes the
   * service easier to unit test.
   */
  private createTimer(pollInterval: number): Observable<number> {
    return timer(0, pollInterval);
  }

  /**
   * Finish this Poller instance.
   */
  finish() {
    this.config.shouldContinue = () => false;
    this._poller.complete();
    this.cleanUpSubscriptions();
  }
}
