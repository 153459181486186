import { LayoutModule } from '@angular/cdk/layout';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';
import { reportItemRendererProvider } from '../iris-reporting.model';
import { AppliedFilterListComponent } from './applied-filter-list/applied-filter-list.component';
import { DynamicReportComponent } from './dynamic-report/dynamic-report.component';
import { DynamicReportService } from './dynamic-report/dynamic-report.service';
import { ReportAppendixComponent } from './report-appendix/report-appendix.component';
import { ReportCardComponent } from './report-card/report-card.component';
import { ReportCoverComponent } from './report-cover/report-cover.component';
import { ReportEmailHeaderComponent } from './report-email-header/report-email-header.component';
import { ReportItemComponent } from './report-item/report-item.component';

@NgModule({
  declarations: [
    AppliedFilterListComponent,
    DynamicReportComponent,
    ReportAppendixComponent,
    ReportCardComponent,
    ReportCoverComponent,
    ReportEmailHeaderComponent,
    ReportItemComponent,
  ],
  imports: [CommonModule, LayoutModule, PortalModule, CohesityHelixModule, TranslateModule],
  exports: [DynamicReportComponent, ReportCardComponent, ReportItemComponent],
  providers: [DynamicReportService, reportItemRendererProvider({})],
})
export class CoreModule {}
