import { HttpClientModule } from '@angular/common/http';
import { inject, NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { downgradeComponent } from '@angular/upgrade/static';
import { CohesityAgentDeploymentApiModule } from '@cohesity/api/agent-deployment';
import { CohesityElixirApiModule } from '@cohesity/api/elixir';
import { ApiGuardianModule } from '@cohesity/api/guardian';
import { Api } from '@cohesity/api/private';
import { QuorumServiceApi } from '@cohesity/api/quorum';
import { CohesityApiSizerModule } from '@cohesity/api/sizer';
import { CohesityApiModule, ProtectionSourcesServiceApi } from '@cohesity/api/v1';
import { CohesityV2ApiModule, ObjectServiceApi, ProtectionGroupServiceApi, SourceServiceApi } from '@cohesity/api/v2';
import { HelixIntlService, ThemeSwitcherComponent } from '@cohesity/helix';
import { IrisContextService, IrisCoreModule, IrisNavigationService } from '@cohesity/iris-core';
import { PLATFORM_CONFIG_PATHS, getPlatformConfigPaths, IS_IBM_AQUA_ENV } from '@cohesity/shared/core';
import { AjaxHandlerConfigService, NaLabelPipeService } from '@cohesity/utils';
import { DialogsLibModule } from '@cohesity/shared-dialogs';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { APP_SERVICE_CONFIGS, AppServiceConfig, AppServiceManagerService } from '../app-services';
import { ClusterManagerServiceConfig } from '../app-services/cluster-manager/cluster-manager-service.config';
import { ApplianceManagerServiceConfig } from '../app-services/appliance-manager/appliance-manager-service.config';
import { DataInsightsServiceConfig } from '../app-services/data-insights/data-insights-service.config';
import { DmsServiceConfig } from '../app-services/dms/dms-service.config';
import { DraasServiceConfig } from '../app-services/draas/draas-service.config';
import { GlobalServiceConfig } from '../app-services/global/global-service.config';
import { PlatformInsightsServiceConfig } from '../app-services/platform-insights/platform-insights-service.config';
import { RpaasServiceConfig } from '../app-services/rpaas/rpaas-service.config';
import { SecurityCenterServiceConfig } from '../app-services/security-center/security-center-service.config';
import { SmartFilesServiceConfig } from '../app-services/smartfiles/smartfiles-service.config';
import { appModulesProvider } from '../app.states';
import { AppMicroFrontendHostModule } from '../modules/app-loader';
import { GlobalSearchSharedModule } from '../modules/global-search-shared/global-search-shared.module';
import { VulscannerPluginModule } from '../plugins/vulscanner-plugin/vulscanner-plugin.module';
import { SharedModule, SourceTreeModule } from '../shared';
import { AppHeliosStatusComponent } from './app-helios-status/app-helios-status.component';
import { AppLayoutNoticeComponent } from './app-layout-notice/app-layout-notice.component';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { HeliosClaimModule } from './helios-claim';
import { REQUEST_INTERCEPTORS } from './interceptors';
import { NotificationsModule } from './notifications/notifications.module';
import { ScopeSelectorModule } from './scope-selector/scope-selector.module';
import {
  AppAjaxHandlerConfigService,
  AppIrisContextService,
  AppNaLabelPipeService,
  AppNavigationService,
  EnhancedQuorumServiceApiService,
  EnhancedSourceServiceApiService,
  GetIndexedObjectSnapshotsService,
  HybridProtectionGroupApiService,
  IbmAquaIconService,
  ListProtectionSourcesService,
  TranslationLoaderService,
} from './services';
import { AppHelixIntlTranslations } from './services/app-helix-intl-translations.service';
import { IconService } from './services/icon.service';
import { TenantImpersonationModule } from './tenant-impersonation/tenant-impersonation.module';
import { UserPanelModule } from './user-panel/user-panel.module';
import { environment } from 'src/environments/environment';

const angular = (<any>window).angular;

/**
 * Configures and creates the translated loader for ngx-translate
 *
 * @param loader injected TranslationLoaderService
 */
export function createTranslateLoader(loader: TranslationLoaderService) {
  return loader;
}

/**
 * This function returns the various app service configuations to be provided
 * for the injection token in AppServiceManagerService.
 *
 * @param cmConfig       Cluster Manager service config.
 * @param globalConfig   Global service config.
 * @param dmsConfig      DataProtect service config.
 * @param draasConfig    SiteContinuity service config.
 * @param rpaasConfig    FortKnox service config.
 * @param diaasConfig    Data Insights service config.
 * @param piaasConfig    Platform Insights service config.
 * @param sfConfig      SmartFiles service config.
 * @returns              Service configs to be provided as an injection token.
 */
export function getAppServiceConfigs(
  applianceManagerConfig: ApplianceManagerServiceConfig,
  securityCenterConfig: SecurityCenterServiceConfig,
  cmConfig: ClusterManagerServiceConfig,
  globalConfig: GlobalServiceConfig,
  dmsConfig: DmsServiceConfig,
  draasConfig: DraasServiceConfig,
  rpaasConfig: RpaasServiceConfig,
  diaasConfig: DataInsightsServiceConfig,
  piaasConfig: PlatformInsightsServiceConfig,
  sfConfig: SmartFilesServiceConfig,
): AppServiceConfig[] {
  return [
    applianceManagerConfig,
    securityCenterConfig,
    cmConfig,
    globalConfig,
    dmsConfig,
    draasConfig,
    rpaasConfig,
    diaasConfig,
    piaasConfig,
    sfConfig,
  ];
}

/**
 * This is the core angular module. It loads most of the core modules and
 * services needed to run the application. This includes global providers
 * (logging, http, routing, translation, etc...), core app services
 * (states, login guards, etc...) and components needed to render the main
 * application frame. It should only ever be imported invoked once, from the
 * AppModule, and it will throw an error if another module tries to import it.
 *
 * Services provided in this module are generally available to the entire app and
 * do not need to be provided again elsewhere. Some modules need to be imported
 * differently depending on whether they are in the root, or core module and or
 * in a feature module. For instance, in Core, we load TranslateModule.forRoot()
 * to configure the translation services for the entire app. Other modules that
 * needed can use TranslateModule.forChild() to import only the directives that
 * are needed for that module.
 */
@NgModule({
  imports: [
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    GlobalSearchSharedModule,
    IrisCoreModule,
    LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,

        // If we specify a class here rather than a factory, then injecting
        // TranslationLoaderService into another service will result in a
        // different instance of the class than the one used by ngx-translate.
        // Using a factory ensures that all instances of our translation loader
        // will be the same.
        useFactory: createTranslateLoader,
        deps: [TranslationLoaderService],
      },
    }),

    // Cohesity API Module needs to be imported in CoreModule rather than from
    // Feature modules, otherwise the http request interceptors will be ignored
    CohesityApiModule,
    UIRouterUpgradeModule.forChild({}),
    NotificationsModule,
    HeliosClaimModule,
    ScopeSelectorModule,
    SourceTreeModule,
    UserPanelModule,
    DialogsLibModule,
    TenantImpersonationModule,
    CohesityElixirApiModule.forRoot({ rootUrl: Api.elixirRoot }),
    CohesityAgentDeploymentApiModule.forRoot({ rootUrl: Api.agentDeploymentRoot }),
    CohesityApiSizerModule.forRoot({ rootUrl: Api.sizerRoot }),
    CohesityV2ApiModule.forRoot({ rootUrl: Api.v2Root }),
    ApiGuardianModule.forRoot({ rootUrl: Api.guardianRoot }),
    VulscannerPluginModule,
    AppMicroFrontendHostModule,
    ThemeSwitcherComponent
  ],
  exports: [AppMicroFrontendHostModule],
  providers: [
    ...REQUEST_INTERCEPTORS,
    appModulesProvider,
    {
      provide: IrisContextService,
      useClass: AppIrisContextService,
    },
    {
      provide: IrisNavigationService,
      useClass: AppNavigationService,
    },
    {
      provide: AjaxHandlerConfigService,
      useClass: AppAjaxHandlerConfigService,
    },
    {
      provide: NaLabelPipeService,
      useClass: AppNaLabelPipeService,
    },
    {
      provide: ProtectionGroupServiceApi,
      useClass: HybridProtectionGroupApiService,
    },
    {
      provide: QuorumServiceApi,
      useClass: EnhancedQuorumServiceApiService,
    },
    {
      provide: SourceServiceApi,
      useClass: EnhancedSourceServiceApiService,
    },
    {
      provide: ProtectionSourcesServiceApi,
      useClass: ListProtectionSourcesService,
    },
    {
      provide: ObjectServiceApi,
      useClass: GetIndexedObjectSnapshotsService,
    },
    {
      provide: HelixIntlService,
      useClass: AppHelixIntlTranslations,
    },
    AppServiceManagerService,
    {
      provide: APP_SERVICE_CONFIGS,
      useFactory: getAppServiceConfigs,
      deps: [
        ApplianceManagerServiceConfig,
        SecurityCenterServiceConfig,
        ClusterManagerServiceConfig,
        GlobalServiceConfig,
        DmsServiceConfig,
        DraasServiceConfig,
        RpaasServiceConfig,
        DataInsightsServiceConfig,
        PlatformInsightsServiceConfig,
        SmartFilesServiceConfig,
      ],
    },
    {
      provide: PLATFORM_CONFIG_PATHS,
      useValue: getPlatformConfigPaths(!environment.production, '/')
    }
  ],
  declarations: [AppLayoutComponent, AppLayoutNoticeComponent, AppHeliosStatusComponent],
})
export class CoreModule {
  constructor(
    private ibmAquaIconService: IbmAquaIconService,
    private iconService: IconService,
    @Optional() @SkipSelf() coreModule: CoreModule
  ) {
    if (coreModule) {
      throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
    }

    if (inject(IS_IBM_AQUA_ENV)) {
      this.iconService = this.ibmAquaIconService;
    }

    this.iconService.registerIcons();
  }
}

angular.module('C').directive('ng2CohAppLayout', downgradeComponent({ component: AppLayoutComponent }));
