<cog-banner [status]="status" [formGroup]="formGroup">
  <div>{{ message }}</div>
  <mat-form-field class="margin-top">
    <input matInput
      type="text"
      placeholder="{{ 'typeYesToConfirm' | translate }}"
      formControlName="confirmActionInput"
      cogDataId="textual-confirmation-input"/>
    <mat-error *ngIf="formGroupErrors?.confirmActionInput?.required">
      {{ 'errors.required' | translate }}
    </mat-error>
  </mat-form-field>
</cog-banner>
