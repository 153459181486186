import { Environment, RecoveryAction } from '@cohesity/iris-shared-constants';

// TODO(ang): Update mapping when yaml file is updated.
/**
 * This is a mapping of recoveryAction to the keys of recover parameters used in v2.
 */
export const recoveryActionToParamsMap: { [key in RecoveryAction]?: string; } = {
  [RecoveryAction.DownloadFilesAndFolders]: 'downloadFileAndFolderParams',
  [RecoveryAction.InstantVolumeMount]: 'mountVolumeParams',
  [RecoveryAction.RecoverApps]: 'recoverAppParams',
  [RecoveryAction.RecoverFiles]: 'recoverFileAndFolderParams',
  [RecoveryAction.RecoverObjects]: 'recoverObjects',
  [RecoveryAction.RecoverNasVolume]: 'recoverNasVolumeParams',
  [RecoveryAction.RecoverPhysicalVolumes]: 'recoverVolumeParams',
  [RecoveryAction.RecoverSanGroup]: 'recoverSanGroupParams',
  [RecoveryAction.RecoverSanVolumes]: 'recoverSanVolumeParams',
  [RecoveryAction.RecoverVApps]: 'recoverVAppParams',
  [RecoveryAction.RecoverVAppTemplates]: 'recoverVAppTemplateParams',
  [RecoveryAction.RecoverVmDisks]: 'recoverVmDiskParams',
  [RecoveryAction.RecoverVMs]: 'recoverVmParams',
  [RecoveryAction.RecoverMailbox]: 'recoverMailboxParams',
  [RecoveryAction.RecoverMails]: 'recoverMailboxParams',
  [RecoveryAction.ConvertToPst]: 'downloadFileAndFolderParams',
  [RecoveryAction.DownloadChats]: 'downloadFileAndFolderParams',
};

// TODO(ang): Update mapping when yaml file is updated.
/**
 * This is a mapping of environment to the keys of target parameters used in v2.
 */
export const targetToParamsMap: { [key in Environment]?: string; } = {
  [Environment.kAcropolis]: 'acropolisTargetParams',
  [Environment.kAWS]: 'awsTargetParams',
  [Environment.kAzure]: 'azureTargetParams',
  [Environment.kElastifile]: 'elastifileTargetParams',
  [Environment.kExchange]: 'exchangeTargetParams',
  [Environment.kFlashBlade]: 'flashbladeTargetParams',
  [Environment.kGCP]: 'gcpTargetParams',
  [Environment.kGenericNas]: 'genericNasTargetParams',
  [Environment.kGPFS]: 'gpfsTargetParams',
  [Environment.kHyperV]: 'hypervTargetParams',
  [Environment.kIsilon]: 'isilonTargetParams',
  [Environment.kKubernetes]: 'kubernetesTargetParams',
  [Environment.kKVM]: 'kvmTargetParams',
  [Environment.kNetapp]: 'netappTargetParams',
  [Environment.kOracle]: 'oracleTargetParams',
  [Environment.kPhysical]: 'physicalTargetParams',
  [Environment.kPure]: 'pureTargetParams',
  [Environment.kSQL]: 'sqlTargetParams',
  [Environment.kView]: 'viewTargetParams',
  [Environment.kVMware]: 'vmwareTargetParams',
};
