import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { IconConfig } from '@cohesity/shared/core';

import { icons as baseIcons, IconService } from './icon.service';

/**
 * Icon set model
 */
interface IconSet {
  [key: string]: {
    namespace?: 'iris';
    getIcons: () => IconConfig;
  };
}

/**
 * Base path for IBM Aqua icons
 */
const ibmAquaBasePath = 'assets/i/icn-ibm-aqua';

/**
 * IBM Aqua icon sets in various namespaces
 */
const ibmAquaIconSet: IconSet = {
  iris: {
    namespace: 'iris',
    getIcons: () => {
      const basePath = `${ibmAquaBasePath}/iris`;

      return {
        ...baseIcons,

        // Other icons
        inventory: `${basePath}/inventory.svg`,

        // 'Empty' state icons
        'empty-access-management': `${basePath}/empty/access-management.svg`,
        'empty-cluster-claim': `${basePath}/empty/cluster-claim.svg`,
        'empty-docs': `${basePath}/empty/docs.svg`,
        'empty-license': `${basePath}/empty/license.svg`,
        'empty-rest-api': `${basePath}/empty/rest-api.svg`,
        'empty-support': `${basePath}/empty/support.svg`,
        'empty-view': `${basePath}/empty/view.svg`,

        // Reporting icons
        'reporting-all-objects': `${basePath}/reporting/all-objects.svg`,
        'reporting-backup-run': `${basePath}/reporting/backup-run.svg`,
        'reporting-cluster-connections': `${basePath}/reporting/cluster-connections.svg`,
        'reporting-external-targets': `${basePath}/reporting/external-targets.svg`,
        'reporting-failures': `${basePath}/reporting/failures.svg`,
        'reporting-m365': `${basePath}/reporting/m365.svg`,
        'reporting-object-growth': `${basePath}/reporting/object-growth.svg`,
        'reporting-protected-objects': `${basePath}/reporting/protected-objects.svg`,
        'reporting-protection-activity': `${basePath}/reporting/protection-activity.svg`,
        'reporting-protection-group-summary': `${basePath}/reporting/protection-group-summary.svg`,
        'reporting-protection-runs': `${basePath}/reporting/protection-runs.svg`,
        'reporting-recovery': `${basePath}/reporting/recovery.svg`,
        'reporting-sc-cluster': `${basePath}/reporting/sc-cluster.svg`,
        'reporting-sc-group': `${basePath}/reporting/sc-group.svg`,
        'reporting-sc-object': `${basePath}/reporting/sc-object.svg`,
        'reporting-sc-organization': `${basePath}/reporting/sc-organization.svg`,
        'reporting-sc-storage-domain': `${basePath}/reporting/sc-storage-domain.svg`,
        'reporting-sc-view': `${basePath}/reporting/sc-view.svg`,
        'reporting-service-consumption': `${basePath}/reporting/service-consumption.svg`,
        'reporting-sla-violations': `${basePath}/reporting/sla-violations.svg`,
        'reporting-source-growth': `${basePath}/reporting/source-growth.svg`,
        'reporting-storage-consumption': `${basePath}/reporting/storage-consumption.svg`,
        'reporting-system-protection': `${basePath}/reporting/system-protection.svg`,
      };
    },
  },
  default: {
    getIcons: () => {
      const basePath = `${ibmAquaBasePath}/default`;

      return {
        add_circle_outline: `${basePath}/add-circle-outline.svg`,
        add: `${basePath}/add.svg`,
        arrow_back: `${basePath}/arrow-back.svg`,
        arrow_drop_down: `${basePath}/arrow-drop-down.svg`,
        arrow_downward: `${basePath}/arrow-down.svg`,
        assessment: `${basePath}/assessment.svg`,
        chevron_left: `${basePath}/chevron-left.svg`,
        chevron_right: `${basePath}/chevron-right.svg`,
        close: `${basePath}/close.svg`,
        content_copy: `${basePath}/content-copy.svg`,
        dashboard: `${basePath}/dashboard.svg`,
        date_range: `${basePath}/date_range.svg`,
        delete_outline: `${basePath}/delete.svg`,
        delete: `${basePath}/delete.svg`,
        drag_indicator: `${basePath}/drag-indicator.svg`,
        edit: `${basePath}/edit.svg`,
        expand_less: `${basePath}/chevron-up.svg`,
        expand_more: `${basePath}/chevron-down.svg`,
        file_copy: `${basePath}/file-copy.svg`,
        filter_list: `${basePath}/filter-list.svg`,
        fingerprint: `${basePath}/fingerprint.svg`,
        headset_mic: `${basePath}/headset-mic.svg`,
        help_outline: `${basePath}/help-outline.svg`,
        info: `${basePath}/info.svg`,
        keyboard_arrow_down: `${basePath}/chevron-down.svg`,
        keyboard_arrow_right: `${basePath}/chevron-right.svg`,
        launch: `${basePath}/launch.svg`,
        lock: `${basePath}/lock.svg`,
        menu: `${basePath}/menu.svg`,
        more_vert: `${basePath}/more-vert.svg`,
        notifications_none: `${basePath}/notifications-none.svg`,
        open_in_new: `${basePath}/open-in-new.svg`,
        person_outline: `${basePath}/person-outline.svg`,
        refresh: `${basePath}/refresh.svg`,
        remove_circle_outline: `${basePath}/remove-circle-outline.svg`,
        remove: `${basePath}/remove.svg`,
        search: `${basePath}/search.svg`,
        settings: `${basePath}/settings.svg`,
        undo: `${basePath}/undo.svg`,
        view_list: `${basePath}/view_list.svg`,
        view_module: `${basePath}/view_module.svg`,
      };
    },
  },
};

/**
 * This service registers IBM Aqua icons with the MatIconRegistry in iris and default namespace.
 * Icons can be accessed with `<namespace>:<iconName> or <iconName>`
 */
@Injectable({
  providedIn: 'root',
})
export class IbmAquaIconService extends IconService {
  constructor(protected iconRegistry: MatIconRegistry, protected sanitizer: DomSanitizer) {
    super(iconRegistry, sanitizer);
  }

  /**
   * Registers all of the namespace icons with the MatIconRegistry.
   */
  private registerAllIcons() {
    Object.values(ibmAquaIconSet).forEach(config => {
      for (const [name, path] of Object.entries(config.getIcons())) {
        this.registerIconInNamespace(config.namespace, name, path);
      }
    });
  }

  /**
   * Registers all of the Helix Icons with the MatIconRegistry.
   */
  registerIcons() {
    if (!this.initialized) {
      this.registerAllIcons();
      this.initialized = true;
    }
  }

  /**
   * Update icon registration
   */
  updateIcons() {
    this.registerAllIcons();
  }
}
