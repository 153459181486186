<h2>
  {{'recovery.vm.archivalTapeDetails' | translate}}
</h2>
<div *ngIf="warningMessage" class="c-message-inline icon-message error">
  {{this.warningMessage}}
</div>
<cog-spinner *ngIf="loading"></cog-spinner>
<cog-table name="recovery-tape-media-info-table"
  [class.visuallyhidden]="loading"
  [source]="archiveMediaInfo$ | async">
  <table mat-table>
    <ng-container matColumnDef="barcode">
      <th *matHeaderCellDef mat-header-cell>
        {{'barcode' | translate}}
      </th>
      <td *matCellDef="let row" mat-cell>
        {{row.barcode}}
      </td>
    </ng-container>
    <ng-container matColumnDef="online">
      <th *matHeaderCellDef mat-header-cell>
        {{'status' | translate}}
      </th>
      <td *matCellDef="let row" mat-cell>
        {{row.online | boolLabel : 'online' : 'offline'}}
      </td>
    </ng-container>
    <ng-container matColumnDef="location">
      <th *matHeaderCellDef mat-header-cell>
        {{'location' | translate}}
      </th>
      <td *matCellDef="let row" mat-cell>
        {{row.location}}
      </td>
    </ng-container>
    <tr *matHeaderRowDef="['barcode', 'online', 'location']" mat-header-row>
    </tr>
    <tr *matRowDef="let row; columns: ['barcode', 'online', 'location']" mat-row>
    </tr>
  </table>
</cog-table>
