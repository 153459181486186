<div *ngIf="!protectionGroupList?.length" class="no-data-container">
  <coh-blank-card type="data-protection-dashboard"
    message="{{ 'deleteSdNoProtectionGroups' | translate}}">
  </coh-blank-card>
</div>

<cog-banner *ngIf="protectionGroupList?.length" class="margin-top" status="warn">
  {{ 'deleteSdReminder'| translate }}
</cog-banner>

<div *ngIf="protectionGroupList?.length" class="stepper-item-list margin-top margin-bottom">
  <div *ngFor="let group of protectionGroupList" class="c-media c-media-align-center single-item margin-top padding-bottom-sm">
    <cog-icon class="c-media-object"
      [shape]="{
        status: group.protectionType || group.environment,
        type: 'environment'
      } | statusIconMap">
    </cog-icon>
    <div class="c-media-body">
      <div class="flex-truncate-wrapper">
        <div class="flex-truncate-text"
          [matTooltip]="group.name"
          matTooltipPosition="above">
          {{ group.name }}
        </div>
      </div>
      <ul class="c-ul-inline no-margin mat-caption">
        <li *ngIf="policyMap[group.policyId]">
          {{ 'policyColon' | translate }}
          {{ policyMap[group.policyId] }}
        </li>
        <li *ngIf="group.lastRun?.replicationInfo?.replicationTargetResults[0]">
          {{ 'replicationColon' | translate}}
          {{ group.lastRun.replicationInfo.replicationTargetResults[0].clusterName }}
        </li>
        <li *ngIf="group.lastRun?.archivalInfo?.archivalTargetResults[0]">
          {{ 'archivalColon' | translate}}
          {{ group.lastRun.archivalInfo.archivalTargetResults[0].targetName }}
        </li>
      </ul>
    </div>
  </div>
</div>
