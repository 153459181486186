<div>
  <div class="dl-row">
    <dt>{{ 'hadoopDistribution' | translate }}</dt>
    <dd>
      {{
        hdfsParams?.hadoopDistribution === hadoopDistribution.CDH |
        boolLabel:'hadoopDistribution.CDH':'hadoopDistribution.HDP'
      }}
    </dd>
  </div>
  <div class="dl-row">
    <dt>{{ 'hadoopVersion' | translate }}</dt>
    <dd>{{ hdfsParams?.hadoopVersion }}</dd>
  </div>
  <div class="dl-row">
    <dt>{{ 'namenodeAddress' | translate }}</dt>
    <dd>{{ hdfsParams?.namenode }}</dd>
  </div>
  <div class="dl-row">
    <dt>{{ getHdfsPortLabel() | translate }}</dt>
    <dd>{{ hdfsParams?.port }}</dd>
  </div>
  <div class="dl-row" *ngIf="hdfsParams?.kerberosPrincipal">
    <dt>{{ 'kerberosPrincipal' | translate }}</dt>
    <dd>{{ hdfsParams?.kerberosPrincipal }}</dd>
  </div>
</div>
