<ng-container *ngIf="status === 'beforeUpload'">
    <p class="margin-top">{{ 'importExternalCertificateDialog.selectCertificateUpload' | translate }}</p>

    <form [formGroup]="formGroup">
        <input
            (change)="fileInputChanged($event)"
            #fileInput
            cogDataId="uploadFile"
            formControlName="uploadFile"
            type="file"
            style="display:none;">
        <button
            mat-button
            class="margin-top"
            cogDataId="manage-certificate-streams-button"
            class="upload-button"
            color="primary"
            (click)="uploadClicked()">
            {{'selectFile' | translate}}
        </button>
    </form>
</ng-container>

<ng-container *ngIf="status === 'afterUpload'">
    <p class="margin-top">{{ 'importExternalCertificateDialog.selectCertificateUpload' | translate }}</p>

    <div class="file-container margin-top">
        <mat-icon>content_copy</mat-icon>
        <div class="file-name">{{ uploadFileName }}</div>
        <button
            mat-icon-button
            type="button"
            (click)="deleteFile()"
            [matTooltip]="'delete' | translate">
            <cog-icon shape="delete_outline"></cog-icon>
        </button>
    </div>

    <form [formGroup]="formGroup" class="margin-top">
        <mat-form-field class="width-100 margin-top">
            <mat-label>{{ 'importExternalCertificateDialog.fields.passcode' | translate }}</mat-label>
            <input
                type="text"
                matInput
                formControlName="passcode"/>
            <mat-error *ngIf="formGroup.get('passcode').hasError('required')">
              {{'errors.required' | translate}}
            </mat-error>
        </mat-form-field>
    </form>
</ng-container>