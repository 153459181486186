// Component: App Eula

// TODO: Need to fetch app specific agreement content via backend service
//       (ENG-55323).
;(function(angular) {
  'use strict';

  angular.module('C.appsManagement')
    .controller('AppEulaCtrl', AppEulaCtrlFn)
    .component('appEula', {
      templateUrl: 'app/apps-management/app-store/app-eula/app-eula.html',
      controller: 'AppEulaCtrl',
      bindings: {
        /**
         * App information that need to be purchased from this component.
         *
         * @type {object}
         */
        app: '=',
      },
    });

  function AppEulaCtrlFn() {
    var $ctrl = this;
  }
})(angular);
