// Module: Alerts Management Resolve

;(function(angular, undefined) {

  angular.module('C.alerts')
    .controller('alertsResolveCtrl', alertsResolveControllerFn);

  function alertsResolveControllerFn($scope, $state, AlertService, evalAJAX,
    ENUM_ALERTS_SEVERITY, ENUM_ALERTS_STATUS) {
    // Ensures we have at least one selected alert from $scope (set from AJS) or
    // from UI Router state params (set from Angular), otherwise redirect
    if (!$scope.selectedAlerts.length) {
      if ($state.params.selectedAlerts && $state.params.selectedAlerts.length) {
        $scope.selectedAlerts = $state.params.selectedAlerts;
      } else {
        $state.go('alerts');
      }
    }

    $scope.ENUM_ALERTS_SEVERITY = ENUM_ALERTS_SEVERITY;
    $scope.ENUM_ALERTS_STATUS = ENUM_ALERTS_STATUS;

    // this flag is used in the view to display different content
    // based on number of alerts user is taking action on
    $scope.singleResolve = $scope.selectedAlerts.length === 1;

    // if its a single resolve, lets setup an object for easy display
    if ($scope.singleResolve) {
      $scope.theAlert = $scope.selectedAlerts[0];
    }

    // default our form to 'new resolution' option
    $scope.newResolution = true;

    /**
     * cancels action and returns user to the main alerts page
     */
    $scope.cancel = function cancel() {
      $state.go('alerts.content');
    };

    /**
     * If new resolution, submits the data to the API and reloads.
     * If existing resolution, send user to select resolution list.
     *
     * @method   submitForm
     * @param    {object}   [theForm]   The angular form object
     */
    $scope.submitForm = function submitForm(theForm) {
      var resolutionData;

      // This function will be called without the form when the
      // "associate with existing resolution" is selected, in
      // which case the user is directed to the resolution
      // selection screen.
      if (!theForm) {
        return $state.go('alerts.resolve.listing');
      }

      if (theForm.$invalid) {
        return;
      }

      $scope.submitting = true;

      // build API call data structure
      resolutionData = {
        resolutionDetails: {
          resolutionSummary: $scope.newResolutionSummary,
          resolutionDetails: $scope.newResolutionDescription
        },
        alertIdList: []
      };

      // loop through selected alerts and add each ID to our API call data
      // structure
      angular.forEach($scope.selectedAlerts, function(currentAlert) {
        resolutionData.alertIdList.push(currentAlert.id);
      });

      // make the API call
      AlertService.createResolution(resolutionData).then(
        function createResolutionSuccess(resp) {
          // API call was successful, redirect back to main alerts page
          $scope.resetData();
          $state.go('alerts');
        },
        evalAJAX.errorMessage
      ).finally(
        function createResolutionFinally() {
          $scope.submitting = false;
        }
      );

    };

  }

})(angular);
