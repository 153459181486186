import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  AutoDestroyable,
  DayToMsecs,
  getTimePeriodtoMsecsValue,
  GranularityInverse,
  GranularityToDays,
} from '@cohesity/utils';
import { ObservableInput } from 'ngx-observable-input';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';

import {
  defaultTimePeriodOptions,
  LimitInterval,
  TimePeriodOptions,
  TimePeriodSelectorForm,
} from './time-period-selector-form';

/**
 * A component for selected a value and a period type
 *
 * @example
 * - Older way.
 *   const form = new TimePeriodSelectorForm(...); // value can be in msecs.
 *
 *   <coh-time-period-selector form="form"></coh-time-period-selector>
 *
 *   // form.value would be of type TimePeriodValue;
 *
 * - Newer way.
 *   const timePeriod = new FormControl(...) // value can be in msecs.
 *
 *   <coh-time-period-selector timePeriod="timePeriod"
 *      timePeriodOptions="..."
 *      valueLimit="...">
 *   </coh-time-period-selector>
 *
 *   // timePeriod.value would of the type of initial value provided to
 *   // FormControl.
 */
@Component({
  selector: 'coh-time-period-selector',
  templateUrl: './time-period-selector.component.html',
  styleUrls: ['./time-period-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TimePeriodSelectorComponent extends AutoDestroyable implements OnInit {
  /**
   * Whether there is a label on the selector.
   */
  @HostBinding('class.no-label') get noLabelClass(): boolean {
    return !this.label;
  }

  // The form label
  @Input() label = '';

  // Auxiliary label to be displayed next to the main form label.
  // Example: display current selected values next to form label.
  @Input() auxLabel = '';

  // The form group
  @Input() form: TimePeriodSelectorForm;

  /**
   * The form control. Value should be passed in msecs preferably. Although, it
   * can also be passed as TimePeriodValue.
   */
  @Input() timePeriod?: UntypedFormControl;

  /**
   * The time period options.
   */
  @Input() timePeriodOptions?: TimePeriodOptions[] = defaultTimePeriodOptions;

  /**
   * The valueLimit input.
   *
   * TODO: This can be used differently from the existing valueLimit inside
   * TimePeriodSelectorForm as we can now control the values in msecs.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('valueLimit') valueLimit$: Observable<LimitInterval>;

  // Unique id for the component.
  @Input() id: string;

  /**
   * Indicate whether edit is allowed or not.
   * This is original value. Do not override it inside of component.
   * Use `internalReadonly` to set readonly state within component.
   */
  @Input() readonly = false;

  /**
   * Prefix value for aria-label.
   */
  @Input() ariaLabelPrefix: string;

  /**
   * Emits the time period value in msecs when set.
   */
  @Output() valueChange = new EventEmitter<number>();

  /**
   * Default value for min and max limits.
   */
  readonly defaultValueLimit: LimitInterval = {
    min: 1,
    max: 365000,
  };

  /**
   * The current min and max limits for the time period.
   */
  currentValueLimit = this.defaultValueLimit;

  /**
   * Gets the currently visible type options. Applying a filter of excluded
   * types if necessary.
   *
   * @return   type options to display
   */
  get typeOptions() {
    return this.form.excludedTypes && this.form.excludedTypes.length
      ? this.form.typeOptions.filter(type => !this.form.excludedTypes.includes(type.value))
      : this.form.typeOptions;
  }

  /**
   * Readonly value used inside ths component
   */
  internalReadonly = this.readonly;

  constructor() {
    super();
  }

  ngOnInit() {
    this.valueLimit$.pipe(
      this.untilDestroy(),
      filter(valueLimit => Boolean(valueLimit)),
      tap(valueLimit => this.currentValueLimit = valueLimit),
      distinctUntilChanged((a, b) => a.min === b.min && a.max === b.max && a.granularity === b.granularity &&
        a.readonlyMessage === b.readonlyMessage),
    ).subscribe((valueLimit: LimitInterval) => {
      const currentGranularity = GranularityInverse[this.form.typeControl.value];
      this.updateValueLimit(valueLimit, currentGranularity);
    });

    // If timePeriodSelector exists, use that to generate the form, and update
    // the timePeriod value on it's valueChanges.
    if (this.timePeriod) {
      this.form = new TimePeriodSelectorForm(this.timePeriod.value, this.timePeriodOptions, this.currentValueLimit);
      this.form.valueChanges.subscribe(val => {
        this.timePeriod.setValue(typeof this.timePeriod.value !== 'number' ? val : getTimePeriodtoMsecsValue(val));
      });
    }
  }

  /**
   * Emits the time period value in msecs when time period value or type is
   * updated.
   */
  updateValue() {
    const value = this.form.valueControl.value;
    const unit = this.form.typeControl.value;
    const granularity = GranularityInverse[unit];
    const valueInMsecs = Number(DayToMsecs[granularity]) * value;
    this.valueChange.emit(valueInMsecs);
    this.updateValueLimit(this.currentValueLimit, granularity);
  }

  /**
   * Triggers when the value limit is updated. The limit can be updated
   * when the input changes or when the granularity value changes.
   *
   * @param  valueLimit          The current limit interval for value control.
   * @param  currentGranularity  The granularity of the current type control.
   */
  private updateValueLimit(valueLimit: LimitInterval, currentGranularity: string) {
    if (!valueLimit || !currentGranularity) {
      return;
    }

    const { min, max, granularity } = valueLimit;
    let unitOffset = 1;

    // Only convert unit if the valueLimit granularity is passed.
    if (granularity) {
      const limit = GranularityToDays[GranularityInverse[granularity]];
      const current = GranularityToDays[currentGranularity];
      unitOffset = Number(limit) / Number(current);
    }

    const updatedValueLimit = {
      min: min ? Math.ceil(min * unitOffset) : this.defaultValueLimit.min,
      max: max ? Math.floor(max * unitOffset) : this.defaultValueLimit.max,
      granularity: currentGranularity,
    };

    // Run in a setTimeout to avoid ExpressionChangedAfterItHasBeenCheckedError.
    setTimeout(() => {
      this.form.updateValueControlLimits(updatedValueLimit);
      this.form.valueLimit = updatedValueLimit;

      if (this.form.value.value < updatedValueLimit.min) {
        this.form.valueControl.setValue(updatedValueLimit.min);
      }

      // If the value limit specified a readonly message, then we should set the status to readonly flag to true and
      // show a tooltip with the message.
      // to the current vaalue.
      this.internalReadonly = this.readonly || !!this.currentValueLimit.readonlyMessage;

      // Marking value control as touched to trigger mat-error if the value limit is violated.
      this.form.valueControl.markAsTouched();
    });
  }
}
