/* tslint:disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ConfigurationCommandsApiService } from './services/configuration-commands-api.service';
import { ClusterConfigApiService } from './services/cluster-config-api.service';
import { ShieldsApiService } from './services/shields-api.service';
import { ShieldRulesApiService } from './services/shield-rules-api.service';
import { LogSearchApiService } from './services/log-search-api.service';
import { LogsDownloadApiService } from './services/logs-download-api.service';
import { DataSourcesApiService } from './services/data-sources-api.service';
import { ActiveDirectoriesApiService } from './services/active-directories-api.service';
import { DataPoolsApiService } from './services/data-pools-api.service';
import { AnomaliesApiService } from './services/anomalies-api.service';
import { DlpApiService } from './services/dlp-api.service';
import { ConfigurationApiService } from './services/configuration-api.service';
import { IoCsIntegrationsApiService } from './services/io-cs-integrations-api.service';
import { IoCsScansApiService } from './services/io-cs-scans-api.service';
import { ThreatScanSchedulesApiService } from './services/threat-scan-schedules-api.service';
import { IoCsThreatsApiService } from './services/io-cs-threats-api.service';
import { UsersAndGroupsApiService } from './services/users-and-groups-api.service';
import { SuggestionsApiService } from './services/suggestions-api.service';
import { ViewsApiService } from './services/views-api.service';
import { DcScansApiService } from './services/dc-scans-api.service';
import { DcDlpIntegrationsApiService } from './services/dc-dlp-integrations-api.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ConfigurationCommandsApiService,
    ClusterConfigApiService,
    ShieldsApiService,
    ShieldRulesApiService,
    LogSearchApiService,
    LogsDownloadApiService,
    DataSourcesApiService,
    ActiveDirectoriesApiService,
    DataPoolsApiService,
    AnomaliesApiService,
    DlpApiService,
    ConfigurationApiService,
    IoCsIntegrationsApiService,
    IoCsScansApiService,
    ThreatScanSchedulesApiService,
    IoCsThreatsApiService,
    UsersAndGroupsApiService,
    SuggestionsApiService,
    ViewsApiService,
    DcScansApiService,
    DcDlpIntegrationsApiService,
    ApiConfiguration
  ],
})
export class ApiArgusModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiArgusModule> {
    return {
      ngModule: ApiArgusModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiArgusModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiArgusModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
