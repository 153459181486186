import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SecurityAdvisorServiceApi, SecurityCriteriaResult } from '@cohesity/api/secops';
import { finalize } from 'rxjs/operators';

import { ListIssuesDialogParams } from '../../models/list-issues-dialog-params';

/**
 * List issues dialog component.
 */
@Component({
  templateUrl: './list-issues-dialog.component.html',
})
export class ListIssuesDialogComponent implements OnInit {
  /**
   * Stores the issue data.
   */
  issueData: SecurityCriteriaResult[] = [];

  /**
   * True if table has to be in loading state.
   */
  loading = false;

  /**
   * Constructor .
   */
  constructor(
    private securityAdvisorServiceApi: SecurityAdvisorServiceApi,
    @Inject(MAT_DIALOG_DATA) private dialogParams: ListIssuesDialogParams
  ) {}

  /**
   * OnInit lifecycle hook.
   */
  ngOnInit() {
    this.loading = true;
    this.securityAdvisorServiceApi
      .GetScanIssuesSummary(this.dialogParams)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(res => this.issueData = res.securityCriteriaResultList);
  }
}
