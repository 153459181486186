<!-- View create/edit dialog component -->
<cog-spinner *ngIf="isLoading"></cog-spinner>
<form *ngIf="!isLoading" [formGroup]="viewDetailsForm"
  cohValidateOnSubmit
  (validatedSubmit)="onCreate()">
  <h1 mat-dialog-title>
    {{(isViewTemplateMode ? 'createViewTemplate' : 'createView') | translate}}
  </h1>

  <div mat-dialog-content>
    <mat-form-field *ngIf="isViewTemplateMode">
      <mat-label>{{'templateName' | translate}}</mat-label>
      <input
        cogDataId="view-template-name-input"
        [formControl]="templateName" matInput>
        <mat-error codDataId="template-name-invalid-error" *ngIf="templateName.invalid">
          {{'errors.required' | translate}}
        </mat-error>
    </mat-form-field>

    <coh-modify-view-details
      [isViewTemplate]="isViewTemplateMode"
      [(templateId)]="data.id"
      [(templateInfo)]="data"
      [parentFormGroup]="viewDetailsForm"
      [nameWarningKeys]="nameWarningKeys"
      [defaultStorageDomainId]="data.restrictViewBoxId"
      (viewBoxChange)="onViewBoxChange($event)">
    </coh-modify-view-details>
  </div>

  <mat-dialog-actions align="end">
    <button mat-button
      cogCancelButton
      cogDataId="update-view-cancel-btn"
      type="button"
      color="primary"
      mat-dialog-close>
      {{'cancel' | translate}}
    </button>
    <button mat-stroked-button
      class="margin-left-sm"
      type="button"
      cogDataId="update-view-more-options-btn"
      color="primary"
      (click)="modifyViewSettings()">
      {{'moreOptions' | translate}}
    </button>
    <button mat-raised-button
      cogSubmitButton
      class="margin-left-sm"
      cogDataId="create-view-btn"
      id="create-view-from-dialog-btn"
      type="submit"
      color="primary"
      (click)="onCreate()">
      {{'create' | translate}}
    </button>
  </mat-dialog-actions>
</form>
