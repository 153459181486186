import { Pipe, PipeTransform } from '@angular/core';

import { RecoveredObject } from '../models';

/**
 * The map between the type of the recovered object and it's icon.
 */
const typeIcon: Record<RecoveredObject['type'], string> = {
  mailbox: 'helix:object-o365-mailbox',
  file: 'helix:object-file-resting',
  folder: 'helix:object-folder',
};

/**
 * Returns the cog-icon shape for an M365 self service recovered object.
 *
 * @example
 * {{ item | cohM365RecoveredObjectIcon }}
 */
@Pipe({ name: 'cohM365RecoveredObjectIcon' })
export class RecoveredItemIconPipe implements PipeTransform {
  transform(value: RecoveredObject): string {
    return typeIcon[value.type];
  }
}
