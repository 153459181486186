import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RbacModule } from '@cohesity/data-govern/shared';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { ColoredTagModule } from '@cohesity/shared/tags';
import { HumanizeFromNumbersPipeModule, NaLabelModule } from '@cohesity/utils';
import { TranslateModule } from '@ngx-translate/core';

import { MetricTypePipeModule, MetricValuePipeModule } from '../../pipes';
import { AnomalyTagsModule } from '../anomaly-tags';
import { SnapshotIndicatorModule } from '../snapshot-indicator';
import { AntiRansomwareSnapshotListComponent } from './anti-ransomware-snapshot-list.component';

const COMPONENTS = [AntiRansomwareSnapshotListComponent];

@NgModule({
  imports: [
    AnomalyTagsModule,
    CohesityHelixModule,
    ColoredTagModule,
    CommonModule,
    HumanizeFromNumbersPipeModule,
    MetricTypePipeModule,
    MetricValuePipeModule,
    NaLabelModule,
    RbacModule,
    SnapshotIndicatorModule,
    TranslateModule,
  ],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
})
export class AntiRansomwareSnapshotListModule {}
