import { AuthenticationStatus } from './../../../../shared/constants/cloud.constants';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateTenantAwsCloudSourceRequest, DmsServiceApi, TenantCloudSourceMetadataInfo } from '@cohesity/api/dms';
import { SourceServiceApi } from '@cohesity/api/v2';
import { AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, of, Subscription, timer } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, takeWhile } from 'rxjs/operators';
import { Environment } from 'src/app/shared';

/**
 * Aws service for registering and unregistering Aws sources.
 */
@Injectable()
export class DmsAwsService extends AutoDestroyable {
  /**
   * Flag to indicate whether CFT is being generated.
   */
  generatingCft$ = new BehaviorSubject<boolean>(false);

  /**
   * Flag to indicate whether CFT is ready.
   */
  cftReady$ = new BehaviorSubject<boolean>(false);

  /**
   * Flag to indicate whether account is authenticated.
   */
  authenticated$ = new BehaviorSubject<boolean>(false);

  /**
   * Flag to indicate the account authentication status
   */
  authenticationStatus$ = new BehaviorSubject<AuthenticationStatus>(AuthenticationStatus.Pending);

  /**
   * Flag to store the authentication errror
   */
  authenticationErrorMsg: null | string = null;

  /**
   * Placeholder for DMS Api response.
   */
  cloudSourceMetadata$ = new BehaviorSubject<TenantCloudSourceMetadataInfo>(null);

  /**
   * Holds whether the source is getting registered.
   */
  registering$ = new BehaviorSubject<boolean>(false);

  /**
   * Stores polling subscription.
   */
  pollingSubscription: Subscription;

  constructor(
    readonly dmsServiceApi: DmsServiceApi,
    private sourceService: SourceServiceApi,
    private translate: TranslateService
  ) {
    super();
  }

  /**
   * Updates CFT if it exists otherwise creates a new cft.
   *
   * @param   params  Aws source params.
   */
  getCft(params: CreateTenantAwsCloudSourceRequest) {
    this.generatingCft$.next(true);
    return this.dmsServiceApi.GetTenantAwsCloudSource(params).pipe(
      this.untilDestroy(),
      catchError(err => {
        if (err.error.errorCode !== 500) {
          this.handleGetCftFailure();
          throw new Error(err.error.errorMessage);
        }

        return of(null);
      }),
      switchMap(response => {
        if (response === null) {
          return this.dmsServiceApi.RegisterTenantAwsCloudSource(params).pipe(
            this.untilDestroy(),
            catchError(err => {
              this.handleGetCftFailure();
              throw new Error(err.error.errorMessage);
            })
          );
        }

        // If we get a record and there is no cft associated with that record, the source
        // is probably in marked for deletion state. so we delete the record and try registering
        // the source again.
        if (!response.cloudFormationTemplate) {
          return this.dmsServiceApi.DeleteTenantAwsCloudSource(params).pipe(
            this.untilDestroy(),
            catchError(err => {
              this.handleGetCftFailure();
              throw new Error(err.error.errorMessage);
            }),
            switchMap(() =>
              this.dmsServiceApi.RegisterTenantAwsCloudSource(params).pipe(
                this.untilDestroy(),
                catchError(err => {
                  this.handleGetCftFailure();
                  throw new Error(err.error.errorMessage);
                })
              )
            )
          );
        }

        // Check if source is already registered.
        return this.sourceService
          .McmGetProtectionSources({
            regionIds: [params.destinationRegionId],
            excludeProtectionStats: true,
            environments: [Environment.kAWS],
          })
          .pipe(
            this.untilDestroy(),
            switchMap(sourcesResponse => {
              if (sourcesResponse.sources?.find(source => source.name === params.awsAccountNumber)) {
                this.handleGetCftFailure();
                throw new Error(this.translate.instant('sources.aws.sourceExists'));
              }

              // Update the source to include new changes.
              return this.dmsServiceApi.UpdateTenantAwsCloudSource(params).pipe(
                this.untilDestroy(),
                catchError(err => {
                  this.handleGetCftFailure();
                  throw new Error(err.error.errorMessage);
                })
              );
            })
          );
      }),
      map(response => {
        this.getCftSuccess(response, params);
      })
    );
  }

  /**
   * Handles Get CFT Api call failures.
   */
  handleGetCftFailure() {
    this.generatingCft$.next(false);
    this.cftReady$.next(false);
  }

  /**
   * set authentication status
   */
  setAuthStatus(status: AuthenticationStatus): void {
    this.authenticationStatus$.next(status);
  }

  /**
   * Updates DMS AWS Source.
   */
  updateSource(params: CreateTenantAwsCloudSourceRequest) {
    this.generatingCft$.next(true);
    return this.dmsServiceApi.UpdateTenantAwsCloudSource(params).pipe(
      this.untilDestroy(),
      catchError(err => {
        if (err.error.errorCode !== 500) {
          this.handleGetCftFailure();
          throw new Error(err.error.errorMessage);
        }

        return of(null);
      }),
      map(response => {
        this.getCftSuccess(response, params);
      })
    );
  }

  /**
   * Download CFT.
   */
  downloadCft() {
    if (this.cftReady$.value === false) {
      return;
    }

    const applicationType = 'text/json';
    const fileName = 'aws_iam_role_creation_template.cft';

    // Encode the data to be downloaded.
    const encodedValue = encodeURIComponent(this.cloudSourceMetadata$.value.cloudFormationTemplate);
    const downloadData = `data:${applicationType};charset=utf-8,${encodedValue}`;

    // Construct the download anchor tag.
    const element = document.createElement('a');
    element.setAttribute('href', downloadData);
    element.setAttribute('download', fileName);
    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();
    document.body.removeChild(element);
  }

  /**
   * Enables CFT download and starts polling Verify source Api.
   *
   * @param   metadata  Source metadata.
   * @param   params    Aws source params.
   */
  getCftSuccess(metadata: TenantCloudSourceMetadataInfo, params: DmsServiceApi.GetTenantAwsCloudSourceParams) {
    this.cloudSourceMetadata$.next(metadata);
    this.cftReady$.next(true);
    this.generatingCft$.next(false);

    // Call verify Api every 10 seconds until verification succeeds.
    this.pollingSubscription = timer(0, 10000)
      .pipe(
        this.untilDestroy(),
        switchMap(() =>
          this.dmsServiceApi.VerifyTenantAwsCloudSource(params).pipe(
            this.untilDestroy(),
            catchError((error: HttpErrorResponse) => {
              this.setAuthStatus(AuthenticationStatus.Failed);
              this.authenticationErrorMsg = error?.error?.errorMessage ?? null;;
              return of('kError');
            })
          )
        ),
        filter(data => data !== 'kError'),
        takeWhile(() => this.authenticationStatus$.value !== AuthenticationStatus.Confirmed)
      )
      .subscribe(() => {
        this.setAuthStatus(AuthenticationStatus.Confirmed);
      });
  }

  /**
   * Delete CFT.
   *
   * @param   params   Aws source params.
   */
  deleteCft(params: DmsServiceApi.GetTenantAwsCloudSourceParams) {
    return this.dmsServiceApi.DeleteTenantAwsCloudSource(params).pipe(this.untilDestroy());
  }

  /**
   * Register Aws source.
   *
   * @param   params  MCM register source params.
   */
  register(params: SourceServiceApi.McmRegisterProtectionSourceParams) {
    this.registering$.next(true);
    return this.sourceService.McmRegisterProtectionSource(params).pipe(
      this.untilDestroy(),
      finalize(() => this.registering$.next(false)),
    );
  }
}
