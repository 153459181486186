import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ProtectionGroup, ProtectionGroups, ProtectionGroupServiceApi } from '@cohesity/api/v2';
import { finalize, take } from 'rxjs/operators';
import { AutoDestroyable } from '@cohesity/utils';

/**
 * @description
 * Component providing messaging regarding how many groups/jobs failed their latest run.
 *
 * @example
 *  <coh-run-failures-status></coh-run-failures-status>
 */
@Component({
  selector: 'coh-missed-sla-status',
  templateUrl: './missed-sla-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./missed-sla-status.component.scss'],
})
export class MissedSlaStatusComponent extends AutoDestroyable implements OnInit {

  /**
   * Indicates if data is loading.
   */
  isLoading = true;

  /**
   * List of groups missing SLA on last run.
   */
  missedGroups: ProtectionGroup[] = [];

  /**
   * Constructor.
   */
  constructor(
    private groupsService: ProtectionGroupServiceApi,
    private change: ChangeDetectorRef,
  ) {
    super();
  }

  /**
   * Initialize component and load stats data.
   */
  ngOnInit() {
    const params: ProtectionGroupServiceApi.GetProtectionGroupsParams = {
      isLastRunSlaViolated: true,
      isDeleted: false,

      // Must include the run info. This param gets pased to magneto and then
      // the API layer filters based on this run info so not including run info
      // results in the API being unable to filter by sla.
      includeLastRunInfo: true,
      includeTenants: true,
    };

    this.groupsService.GetProtectionGroups(params)
      .pipe(
        take(1),
        finalize(() => this.isLoading = false)
      )
      .subscribe((resp: ProtectionGroups)  => {
        // Manually filter the response to double check, as proxying older
        // cluster APIs will result in all groups being returned regardless of
        // the params, as the params were added in 6.5.1.
        this.missedGroups = (resp.protectionGroups || []).filter(
          group => !!group.lastRun?.localBackupInfo?.isSlaViolated
        );
        this.change.markForCheck();
      });
  }
}
