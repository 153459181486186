<ng-container *ngIf="entityStatus$ | async as entityStatus">
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'duration'">
      <div class="spinner-container">
        <cog-spinner size="xs"></cog-spinner>
      </div>
      <div class="label-container">
        {{ 'protectionJobsDetailsRunRunProtection.analyzingFilesNg' | translate: entityStatus }}
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'backupStatus'">
      <div class="label-container">
        {{ entityStatus.totalChangedEntities }} / {{ entityStatus.totalEntities }}
      </div>
    </ng-container>
  </ng-container>

</ng-container>
