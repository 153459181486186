import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';

interface SimpleListOptions {
  /**
   * Provides an alternative delimiter if something besides a comma separated is desired.
   */
  delimiter?: string;

  /**
   * Provides the property name of a simple object to return.
   */
  propKey?: string;

  /**
   * Indicates if the provided values should be passed through TranslationService.instant().
   */
  translate?: boolean;

  /**
   * Specifies whether to skip the empty values like null, undefined, 0 & '' etc.
   */
  skipEmpty?: boolean;

  /**
   * Specifies whether to show list in alphabetic ascending order.
   */
  alphabeticOrder?: boolean;
}

/*
 * Returns a stringified list of array values.
 * Takes a string delimiter argument which defaults to ', '
 * Usage:
 *   value | simpleList:translate:delimiter:key
 * Example:
 *   {{ ['one', 'two']  | simpleList:{delimiter: ' '} }}
 *   formats to: 'one two'
 *
 *   {{ ['banana', 'apple']  | simpleList:{alphabeticOrder: true} }}
 *   formats to: 'apple banana'
 *
 *   {{ ['kOne', 'kTwo']  | simpleList:{translate: true} }}
 *   formats to: 'One, Two'
 *
 *   {{ [{name: 'one'}, {name: 'two'}]  | simpleList:{propKey: 'name'} }}
 *   formats to: 'One, Two'
*/
@Pipe({
  name: 'simpleList',
  pure: true,
})
export class SimpleListPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: any[], options: SimpleListOptions = {}): string {

    // Use a default delimiter if it's null, undefined or ''.
    const {
      translate = false,
      delimiter = ', ',
      propKey = '',
      skipEmpty = false,
      alphabeticOrder = false,
    } = options;

    // If these conditions match, just return the 'list' param as-is.
    if (!Array.isArray(value)) {
      return value;
    }

    // If propKey is provided, reduce the list to the desired values.
    if (propKey) {
      value = value.map(item => get(item, propKey));
    }

    if (skipEmpty) {
      value = value.filter(Boolean);
    }

    if (translate) {
      value = value.map((item: string) => this.translate.instant(item));
    }

    // If alphabeticOrder is provided, sort the list in alphabetical and ascending order.
    if (alphabeticOrder) {
      value = [...value].sort();
    }

    // Join it together with the delimiter.
    return value.join(delimiter);
  }
}
