import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';

import { SnapshotInfoComponent } from './snapshot-info/snapshot-info.component';
import { VulscannerPluginService } from './vulscanner-plugin.service';

@NgModule({
  declarations: [SnapshotInfoComponent],
  imports: [
    CohesityHelixModule,
    CommonModule,
    TranslateModule,
  ],
})
export class VulscannerPluginModule {
  constructor(
    private injector: Injector) {
    this.init();
  }

  /**
   * Vulscan service might throw AJS dependency error.
   * So Vulscan plugin service initialization is added inside try-catch block.
   * if there is any exception, catch it and retry initialization.
   */
  init() {
    setTimeout(() => {
      try {
        const service = this.injector.get(VulscannerPluginService);
        service.init();
      } catch (error) {
        this.init();
      }
    }, 50);
  }
}
