import { Component, Input, ViewEncapsulation } from '@angular/core';
import { StatlistItemSpacing } from '@cohesity/helix';

import { StatList } from '../models';

/**
 * @description
 * Component will display row of items with optional icon and description.
 *
 * @example
 *  <coh-stat-list [stats]="myStats"></coh-stat-list>
 */
@Component({
  selector: 'coh-stat-list',
  templateUrl: './stat-list.component.html',
  styleUrls: ['./stat-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StatListComponent {

  /**
   * Stat items.
   */
  @Input() stats: StatList;

  /**
   * itemSpacing value to be passed through to Helix component.
   */
  @Input() itemSpacing: StatlistItemSpacing = 'stretch';

}
