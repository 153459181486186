<!-- License Notification Component -->

<div class="c-message-inline warn" *ngIf="notifications.length">
  <div *ngFor="let notification of notifications">
    <div class="icon-message notification-msg {{notification.type}}">
      <div>
        <div class="icon-message-title">
          {{notification.titleKey | translate}}
        </div>
        {{notification.textKey | translate}}
      </div>
      <button class="c-btn-{{notification.type}}"
        (click)="notification.button.callback()"
        *ngIf="notification.button">
        {{notification.button.text | translate}}
      </button>
    </div>
  </div>
</div>
