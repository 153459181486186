import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { DecimalPipe } from '@angular/common';

import { ByteSize } from './byte-size.model';
import { ByteScale, FileSize, FileSizeBase10, FileSizeBytesUnits } from './file-size';
import { MathService } from '../../util/math/math.service';

// providers needed for downgrade ByteSizeService service.
// providers: [DecimalPipe, MathService],

/**
 * Byte-Size helper convertor utility service.
 */
@Injectable({
  providedIn: 'root'
})
export class ByteSizeService {
  private decimalPipe: DecimalPipe;
  private mathService: MathService;

  /**
   * The current scale to use for upscaling raw bytes.
   */
  byteScale: ByteScale = 'base2';

  constructor(@Inject(LOCALE_ID) private locale: string) {
    this.decimalPipe = new DecimalPipe(locale);
    this.mathService = new MathService();
  }

  /**
   * Converts a provided byte value into a human readable string.
   *
   * @param  bytes         The bytes to convert.
   * @param  precision     Number of decimal places to include.
   * @param  useNaLetters  Use 'N/A' instead of '-'
   * @param  units      Specify specific units to convert the bytes to
   * @param  byteScaleOverride Used to override set byte scale and use a different scale
   * @returns  The converted object containing 'string', 'size', 'unit' values.
   */
  bytesToSize(bytes: number, precision = 1, useNaLetters = false,
    unit?: string, byteScaleOverride?: ByteScale): ByteSize {
    const isBase2 = byteScaleOverride ? byteScaleOverride === 'base2' : this.byteScale === 'base2';
    const scaleFileSizeEnum = isBase2 ? FileSize : FileSizeBase10;
    const negative = bytes < 0;
    // Show - or N/A if bytes are undefined or equivalent to special value 1.
    if ((!bytes && bytes !== 0) || bytes === 1) {
      // to get translated values
      // TODO: translate n/a letter when cohesity common is aware of translation
      const naNotApplicable = useNaLetters ? 'N/A' : '-';

      return {
        string: naNotApplicable,
        displayValue: naNotApplicable,
        asHtml: naNotApplicable,
        size: naNotApplicable,
        unit: '',
        rawSize: bytes,
      };
    }

    // Converting negative bytes to positive Bytes.
    bytes = Math.abs(bytes);

    // If a unit wasn't specified, determine the most appropriate
    // unit to scale the raw bytes up to.
    if (!unit) {
      switch (true) {
        case (bytes >= scaleFileSizeEnum.petabyte):
          unit = isBase2 ? 'PiB' : 'PB';
          break;
        case (bytes >= scaleFileSizeEnum.terabyte):
          unit = isBase2 ? 'TiB' : 'TB';
          break;
        case (bytes >= scaleFileSizeEnum.gigabyte):
          unit = isBase2 ? 'GiB' : 'GB';
          break;
        case (bytes >= scaleFileSizeEnum.megabyte):
          unit = isBase2 ? 'MiB' : 'MB';
          break;
        case (bytes >= scaleFileSizeEnum.kilobyte):
          unit = isBase2 ? 'KiB' : 'KB';
          break;
        default:
          unit = 'Bytes';
      }
    }
    const sizeBase: number = FileSizeBytesUnits[unit];

    let size: any = this.mathService.toFixed((bytes / sizeBase), precision);
    size = negative ? size * -1 : size;

    const displayValue = [this.decimalPipe.transform(size), unit].join(' ');

    return {
      string: displayValue,
      displayValue: displayValue,
      asHtml: `${size} <small>${unit}</small>`,
      size: size,
      unit: unit,
      rawSize: bytes,
    };
  }

  /**
   * Converts provided size & unit value into a bytes.
   * TODO: Can we support scaling preference for implementations using this?
   *
   * @param  size     The size to convert.
   * @param  unit     The unit of the size.
   * @returns  Number of bytes.
   */
  sizeToBytes(size: number, unit: string): number {
    let bytes = 0;

    if (size <= 0) {
      return 0;
    }

    switch (unit) {
      case 'PiB':
        bytes = size * FileSize.petabyte;
        break;
      case 'TiB':
        bytes = size * FileSize.terabyte;
        break;
      case 'GiB':
        bytes = size * FileSize.gigabyte;
        break;
      case 'MiB':
        bytes = size * FileSize.megabyte;
        break;
      case 'KiB':
        bytes = size * FileSize.kilobyte;
        break;
      case 'PB':
        bytes = size * FileSizeBase10.petabyte;
        break;
      case 'TB':
        bytes = size * FileSizeBase10.terabyte;
        break;
      case 'GB':
        bytes = size * FileSizeBase10.gigabyte;
        break;
      case 'MB':
        bytes = size * FileSizeBase10.megabyte;
        break;
      case 'KB':
        bytes = size * FileSizeBase10.kilobyte;
        break;
      default:
        bytes = size * FileSize.byte;
    }

    return bytes;
  }
}
