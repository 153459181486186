<aside class="sql-aag-selection-challenge-modal" cogDataId="sql-aag-selection-challenge-modal">
  <header mat-dialog-title>
    <h2 translate>aagOptions</h2>
  </header>

  <section mat-dialog-content>
    <p translate>selectAagModal.intro</p>

    <a class="expansion-trigger" (click)="panelOpen = !panelOpen">
      {{(panelOpen ? 'hideAagNodes' : 'showAagNodes') | translate}}
    </a>

    <mat-card *ngIf="panelOpen">
      <coh-sql-aag-details [data]="aagData"></coh-sql-aag-details>
    </mat-card>

    <form [formGroup]="aagSelectionForm">
      <mat-radio-group formControlName="selectionOption">
        <mat-radio-button value="all"
          cogDataId="select-all-related-nodes"
          [inheritDataId]="true">
          <span [innerHTML]="'selectAagModal.optionSelectAllAag' | translate"></span>
        </mat-radio-button>
        <mat-radio-button value="one"
          cogDataId="select-this-node"
          [inheritDataId]="true">
          <span [innerHTML]="'selectAagModal.optionSelectOnlyThisNode' | translate"></span>
        </mat-radio-button>
      </mat-radio-group>
    </form>
  </section>

  <footer mat-dialog-actions align="end">
    <button type="button"
      cogDataId="cancel-choice"
      [inheritDataId]="true"
      mat-button
      mat-dialog-close
      (click)="cancel()">{{'cancel' | translate}}</button>
    <button type="button"
      cogDataId="save-choice"
      [inheritDataId]="true"
      mat-button
      mat-raised-button
      color="primary"
      (click)="saveChoice()">{{'save' | translate}}</button>
  </footer>
</aside>
