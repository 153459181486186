import { Component, Input } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MFAServiceApi } from '@cohesity/api/v2';
import { SnackBarService } from '@cohesity/helix';
import { AjaxHandlerService } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { Controls, NgxSubFormComponent, subformComponentProviders, takeUntilDestroyed } from 'ngx-sub-form';

import { MfaEmailToken } from '../../interfaces/mfa-verification';

@Component({
  selector: 'coh-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
  providers: subformComponentProviders(EmailVerificationComponent),
})
export class EmailVerificationComponent extends NgxSubFormComponent<MfaEmailToken> {
  /**
   * to determine this component used from heliosOnPrem
   */
  @Input() isHeliosOnPrem = false;

  /**
   * Returns the form controls for the group.
   *
   * @returns The form controls
   */
  getFormControls(): Controls<MfaEmailToken> {
    return {
      emailToken: new UntypedFormControl(null, Validators.required),
    };
  }

  constructor(
    private mfaService: MFAServiceApi,
    private snackbar: SnackBarService,
    private translate: TranslateService,
    private ajaxHandler: AjaxHandlerService
  ) {
    super();
  }

  /**
   * Triggers the send email code to user.
   */
  emailCode() {
    // Special case for HeliosOnPrem or OnPrem
    if (this.isHeliosOnPrem) {
      this.emailCodeOnHeliosOnPrem();
    } else {
      this.mfaService
        .SendEmailOtp()
        .pipe(takeUntilDestroyed(this))
        .subscribe(
          () => this.snackbar.open(this.translate.instant('mfa.email.success')),
          err => this.ajaxHandler.errorMessage(err)
        );
    }
  }

  /**
   * Triggers the send email code to user from heliosOnPrem
   */
  emailCodeOnHeliosOnPrem() {
    this.mfaService
      .CreateAuthenticatorKey({
        authenticator_type: 'email',
        emailParams: {},
      })
      .pipe(takeUntilDestroyed(this))
      .subscribe(
        () => this.snackbar.open(this.translate.instant('mfa.email.success')),
        err => this.ajaxHandler.errorMessage(err)
      );
  }
}
