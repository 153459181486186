import { Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { Tag, TagType, TagUiColor } from '@cohesity/api/helios-metadata';

/**
 * Default tag color if the color is not configured
 */
const DEFAULT_TAG_COLOR = TagUiColor.Red;

/**
 * type for the input tag data
 */
export type TagInput = Partial<Tag>;

@Component({
  selector: 'coh-colored-tag',
  templateUrl: './colored-tag.component.html',
  styleUrls: ['./colored-tag.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ColoredTagComponent {
  /**
   * shows tag as selected if its set to true
   */
  @Input()
  isSelected = false;

  /**
   * If set to true, enables the ripple effect for the interactions.
   */
  @Input()
  isSelectable = false;

  /**
   * If a tag is marked as removable, a cross icon will be shown on clicking of which remove event will be fired
   */
  @Input()
  isRemovable = false;

  /**
   * Tag that need to be rendered
   */
  @Input()
  tag: TagInput;

  /**
   * Remove tag event
   */
  @Output() tagRemoved = new EventEmitter<TagInput>();

  /**
   * Enforces max width contraint. By Default it is set to true.
   */
  @HostBinding('class.constraint-max-width') @Input() restrictWidth = true;

  /**
   * Tag types exposed for the template usage
   */
  readonly TagType = TagType;

  /**
   * Applies the appopriate class for the requested style.
   */
  get tagColor(): string {
    return (this.tag.uiColor || DEFAULT_TAG_COLOR).toLowerCase();
  }

  /**
   * fires tag removed event whenever user clicks on cross icon.
   */
  onRemove() {
    this.tagRemoved.emit(this.tag);
  }
}
