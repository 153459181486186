import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

/**
 * This is a directive for compare field validator.
 * It compare to two form input fields and compare if the values are equal.
 *
 * @example
 * <input name="form1">
 * <input name="form2" matchValue="form1">
 * <mat-error *ngIf="!!form2.errors.matchValue.notEqual"></mat-error>
 */
@Directive({
  selector: '[cohMatchValue]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: MatchValueValidator,
    multi: true
  }]
})

export class MatchValueValidator implements Validator {

  /**
   * The form field which need to be compared with the current form control.
   */
  @Input() cohMatchValue: string;

  /**
   * Validates the fields specified in the inputs with the current form field.
   *
   * @param   control   The current form control.
   * @return  The object with equal or not equal OR returns null.
   */
  validate(control: AbstractControl): {[key: string]: any} | null {
    const controlToCompare = control.parent.get(this.cohMatchValue);
    if (controlToCompare && control && controlToCompare.value !== control.value) {
      return { 'matchValue': { 'notEqual': true } };
    }
    return null;
  }
}
