<div class="flex-row">
  <div class="margin-right" *ngIf="enableRecipientType">
    <mat-form-field [ngStyle]="{'max-width': '3rem'}">
      <mat-select cogDataId="send-type"
        [formControl]="recipientTypeFormControl">
        <mat-option *ngFor="let recipientType of recipientTypes" [value]="recipientType">
          {{recipientType | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="margin-right-sm">
    <mat-form-field class="host-name-field"
      [ngClass]="{'hide-label' : hideLabel}"
      [floatLabel]="hideLabel ? 'never' : 'always'">
      <mat-label>{{'emailAddress' | translate}}</mat-label>
      <input id="email-address-with-locale"
        matInput
        required
        [formControl]="emailAddress">
      <mat-error *ngIf="emailAddress.invalid">
        {{'errors.email' | translate}}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="margin-right-sm" *ngIf="!withoutLocale">
    <coh-select-locale [(selectedLocale)]="model.locale"
      [hideLabel]="hideLabel"></coh-select-locale>
  </div>
</div>
