import { Injectable, Injector } from '@angular/core';
import {
  IrisNavigationService,
  NavOptions,
  NavParams,
  RouterLinkAdapter,
  UIRouterLinkAdapter,
} from '@cohesity/iris-core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

import { StateManagementService } from './state-management.service';

/**
 * This service implements IrisContext Service for iris based on existing services.
 */
@Injectable()
export class AppNavigationService extends IrisNavigationService {
  constructor(
    private stateManagementService: StateManagementService,
    private state: StateService,
    private routerGlobals: UIRouterGlobals
  ) {
    super();
  }

  get currentStateName(): string {
    return this.routerGlobals.current.name;
  }

  get currentStateParams(): NavParams {
    return this.routerGlobals.params;
  }

  go(state: string, params: NavParams, options: NavOptions) {
    return this.state.go(state, params, options);
  }

  canUserAccessState(state: string, params?: NavParams, noLogging = false): boolean {
    return this.stateManagementService.canUserAccessState(state, params, noLogging);
  }

  goToPreviousState(defaultGoBackState?: string, defaultStateParams?: NavParams) {
    return this.stateManagementService.goToPreviousState(defaultGoBackState, defaultStateParams);
  }

  getRouterLinkAdapter(injector: Injector): RouterLinkAdapter {
    return new UIRouterLinkAdapter(injector);
  }

  reload() {
    return from(this.state.reload()).pipe(map(state => !!state));
  }
}
