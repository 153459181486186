// Component: job-runs widget

;(function(angular, undefined) {

  var componentConfig = {
    controller: 'SqlJobRunsController',
    templateUrl: 'app/global/c-widgets/sql/job-runs/job-runs.html',
    bindings: {
      data: '<',
    },
  };

  angular.module('C.widgets')
    .controller('SqlJobRunsController', sqlJobRunsCtrl)
    .component('wSqlJobRuns', componentConfig);

  /**
   * @ngdoc component
   * @name C.widgets:wSqlJobRuns
   *
   * @param {Object} data job run data should look like this:
   *                      {
   *                        lastDayNumJobRuns: string;
   *                        lastDayNumJobSlaViolations: string;
   *                        lastDayNumJobErrors: string;
   *                        numJobRunning: string;
   *                      }
   *
   * @description
   * Widget for Job Runs
   *
   *  @example
   * <w-sql-job-runs data"$ctrl.data"></w-sql-job-runs>
   */
  function sqlJobRunsCtrl(_) {
    var $ctrl = this;

    _.assign($ctrl, {
      $onChanges: $onChanges
    });

    /**
     * Update stat list config whenever data binding changes
     *
     * @param {SimpleChanges} changes
     */
    function $onChanges(changes) {
      if (changes.data) {
        $ctrl.statusConfig = !$ctrl.data ? [] : [{
          label: 'runs',
          value: $ctrl.data.lastDayNumJobRuns,
        },
        {
          label: 'slaViolations',
          value: $ctrl.data.lastDayNumJobSlaViolations,
          classes: {
            'status-ok': $ctrl.data.lastDayNumJobSlaViolations === 0,
            'status-warning': $ctrl.data.lastDayNumJobSlaViolations > 0,
          },
        },
        {
          label: $ctrl.data.lastDayNumJobErrors === 1 ? 'error' : 'errors',
          value: $ctrl.data.lastDayNumJobErrors,
          classes: {
            'status-ok': $ctrl.data.lastDayNumJobErrors === 0,
            'status-critical': $ctrl.data.lastDayNumJobErrors > 0,
          },
        },
        {
          label: $ctrl.data.numJobsRunning === 1 ? 'jobRunning' : 'jobsRunning',
          value: $ctrl.data.numJobsRunning,
          classes: {
            'stat-group-start': true,
          },
        }];
      }
    }
  }

})(angular);
