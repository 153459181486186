import { Api } from '@cohesity/api/private';
import {
  hadoopEnvironments,
  noSqlEnvironments,
  SourceKeys,
  sourceTypesDisplayNames,
} from '@cohesity/iris-shared-constants';

import { AGENT_MIN_VERSION_PIT_RESTORE } from './agent.constants';
import {
  alertsDateRangeList,
  AlertsSeverity,
  alertsSeverityList,
  AlertsStatus,
  alertsStatusList,
  alertsTypeBucketList,
} from './alerts.constants';
import { ArchivalTarget } from './archival.constants';
import { HIGHCHART_SERIES_COLOR } from './chart-color.constants';
import { CloudTarget } from './cloud-target.constants';
import { AwsAuthType, CloudSubscription, CloudSubscriptionType } from './cloud.constants';
import { ENVIRONMENTS_WITH_HEALTH_CHECKS } from './entities-with-health-checks.constants';
import {
  ENUM_ENV_TYPE,
  ENUM_FETCH_TYPE,
  ENUM_TASK_TYPE,
  ENUM_TIMESPAN_TYPE,
  envTypes,
  FETCH_TYPE,
  FlrRestoreMethod,
  HypervisorEnvGroupNames,
  NasSourceNames,
  SchedulingPolicyPeriodicity,
  taskTypes,
  timeSpanType,
} from './env.constants';
import { ExchangeGroups } from './exchange.constants';
import { REGEX_FORMATS } from './formats.constants';
import { HELP_MAP } from './help_map.constants';
import { HOST_TYPE_GROUPS } from './host-type-groups.constants';
import { IndexedDocumentType } from './indexed-document-type.constants';
import { IndexingStatusType } from './indexing-status.constants';
import { JOB_GROUPS } from './job-groups.constants';
import { JobEnvParamsV1 } from './job.constants';
import { SERVICE_FEATURES } from './licensing.constant';
import { LangFile, LocaleFile } from './locale.constants';
import { NonBifrsostAwareNasEnvs } from './nas-sources.constants';
import { HadoopSourceEditMode } from './nosql-hadoop.constants';
import { OFFICE365_GROUPS, Office365ContainerNodeType, Office365SearchType } from './office365.constants';
import { OracleBackupType, OracleDataGuardDBRole } from './oracle.constants';
import { AllLocalDrives } from './physical.constants';
import { RestoreFileEntityStatus, RestoreTaskUiStatus } from './restore-status.constants';
import { RestoreType } from './restore-task.constants';
import { Routes } from './routes.constants';
import { SnapshotTargetType } from './snaspshot-target.constants';
import { globalStateParams } from './state.constants';


export { Api } from '@cohesity/api/private';
export * from '@cohesity/iris-shared-constants';
export * from '@cohesity/utils';
export * from './acropolis.constants';
export * from './agent.constants';
export * from './alerts.constants';
export * from './archival.constants';
export * from './azure.constants';
export * from './bifrost.constants';
export * from './cassandra.constants';
export * from './chart-options.constants';
export * from './clone-task-type.constants';
export * from './cloud-provider.constants';
export * from './cloud-target.constants';
export * from './cloud-tier.constants';
export * from './cloud.constants';
export * from './connection-state.constants';
export * from './disaster-recovery.constants';
export * from './entities-with-health-checks.constants';
export * from './env.constants';
export * from './exchange.constants';
export * from './grid-config.constants';
export * from './hbase.constants';
export * from './hdfs.constants';
export * from './help_map.constants';
export * from './hive.constants';
export * from './host-type-groups.constants';
export * from './hyperv.constants';
export * from './hypervisor-sources.constants';
export * from './icon.constants';
export * from './indexing-status.constants';
export * from './job-groups.constants';
export * from './job.constants';
export * from './kubernetes.constants';
export * from './locale.constants';
export * from './location.constants';
export * from './migration-status.constants';
export * from './mongodb.constants';
export * from './ms-sql.constants';
export * from './nas-sources.constants';
export * from './nosql-hadoop.constants';
export * from './office365.constants';
export * from './oracle.constants';
export * from './organization.constants';
export * from './physical.constants';
export * from './platform.constants';
export * from './public-status-mapping.constants';
export * from './recovery-os-type.constants';
export * from './registration.constants';
export * from './restore-params.constants';
export * from './restore-status.constants';
export * from './restore-task.constants';
export * from './role.constants';
export * from './routes.constants';
export * from './smb-principals.constants';
export * from './snaspshot-target.constants';
export * from './source-entity-params.constants';
export * from './sql-application-sources.constants';
export * from './state.constants';
export * from './storage-array-sources.constants';
export * from './storage-snapshot-provider-list.constants';
export * from './task-status.constants';
export * from './timeframe-options.constants';
export * from './uda.constants';
export * from './vm-tools.constants';
export * from './licensing.constant';

// For backwards compatibility with Angular JS, add constants modules
declare const angular: any;
const constModule = angular.module('C.constants');

constModule
  .constant('AGENT_MIN_VERSION_PIT_RESTORE', AGENT_MIN_VERSION_PIT_RESTORE)
  .constant('ALERTS_DATE_RANGE_LIST', alertsDateRangeList)
  .constant('ALERTS_SEVERITY_LIST', alertsSeverityList)
  .constant('ALERTS_STATUS_LIST', alertsStatusList)
  .constant('ALERTS_TYPE_BUCKET_LIST', alertsTypeBucketList)
  .constant('ALL_LOCAL_DRIVES', AllLocalDrives)
  .constant('API', Api)
  .constant('AWS_AUTH_TYPE', AwsAuthType)
  .constant('ENUM_ALERTS_SEVERITY', AlertsSeverity)
  .constant('ENUM_ALERTS_STATUS', AlertsStatus)
  .constant('ENUM_ARCHIVAL_TARGET', ArchivalTarget)
  .constant('ENUM_CLOUD_TARGET', CloudTarget)
  .constant('ENVIRONMENTS_WITH_HEALTH_CHECKS', ENVIRONMENTS_WITH_HEALTH_CHECKS)
  .constant('EXCHANGE_GROUPS', ExchangeGroups)
  .constant('CLOUD_SUBSCRIPTION_TYPE', CloudSubscriptionType)
  .constant('CLOUD_SUBSCRIPTION', CloudSubscription)
  .constant('HADOOP_ENVIRONMENTS', hadoopEnvironments)
  .constant('HADOOP_SOURCE_EDIT_MODE', HadoopSourceEditMode)
  .constant('HELP_MAP', HELP_MAP)
  .constant('HOST_TYPE_GROUPS', HOST_TYPE_GROUPS)
  .constant('INDEXED_DOCUMENT_TYPE', IndexedDocumentType)
  .constant('INDEXING_STATUS_TYPE', IndexingStatusType)
  .constant('ENV_PARAMS_ENUM', JobEnvParamsV1)
  .constant('JOB_GROUPS', JOB_GROUPS)
  .constant('LANG_FILE', LangFile)
  .constant('LOCALE_FILE', LocaleFile)
  .constant('NOSQL_ENVIRONMENTS', noSqlEnvironments)
  .constant('OFFICE365_GROUPS', OFFICE365_GROUPS)
  .constant('OFFICE365_CONTAINER_NODE_TYPE', Office365ContainerNodeType)
  .constant('OFFICE365_SEARCH_TYPE', Office365SearchType)
  .constant('ORACLE_BACKUP_TYPE', OracleBackupType)
  .constant('ORACLE_DG_DB_ROLE_TYPE', OracleDataGuardDBRole)
  .constant('RESTORE_FILE_ENTITY_STATUS', RestoreFileEntityStatus)
  .constant('RESTORE_TASK_UI_STATUS', RestoreTaskUiStatus)
  .constant('RESTORE_TYPE', RestoreType)
  .constant('Routes', Routes)
  .constant('SNAPSHOT_TARGET_TYPE', SnapshotTargetType)
  .constant('SOURCE_KEYS', SourceKeys)
  .constant('SOURCE_TYPE_DISPLAY_NAME', sourceTypesDisplayNames)
  .constant('ENUM_ENV_TYPE', ENUM_ENV_TYPE)
  .constant('ENUM_TASK_TYPE', ENUM_TASK_TYPE)
  .constant('ENUM_FETCH_TYPE', ENUM_FETCH_TYPE)
  .constant('ENUM_TIMESPAN_TYPE', ENUM_TIMESPAN_TYPE)
  .constant('ENV_TYPE_CONVERSION', envTypes)
  .constant('TASK_TYPE_CONVERSION', taskTypes)
  .constant('FETCH_TYPE_CONVERSION', FETCH_TYPE)
  .constant('TIMESPAN_TYPE_CONVERSION', timeSpanType)
  .constant('FORMATS', REGEX_FORMATS)
  .constant('NAS_SOURCE_NAMES', NasSourceNames)
  .constant('HV_ENV_GROUP_NAMES', HypervisorEnvGroupNames)
  .constant('SCHEDULING_POLICY_PERIODICITY', SchedulingPolicyPeriodicity)
  .constant('FLR_RESTORE_METHOD', FlrRestoreMethod)
  .constant('HIGHCHART_SERIES_COLOR', HIGHCHART_SERIES_COLOR)
  .constant('GLOBAL_STATE_PARAMS', globalStateParams)
  .constant('NON_BIFROST_AWARE_NAS_ENVS', NonBifrsostAwareNasEnvs)
  .constant('SERVICE_FEATURES', SERVICE_FEATURES);
