import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { RansomwareInfo } from '@cohesity/api/v1';
import {
  awsWarmStorageEntitlement,
  IrisContextService,
  isRpaasAwsSubscription,
  rpaasColdStorageQuantity,
  rpaasWarmStorageQuantity,
} from '@cohesity/iris-core';
import { combineLatest } from 'rxjs';

import { gridConfig } from '../../../models';
import { RpaasSummaryDashboardService } from '../../../services/cyber-vaulting-dashboard';


/**
 * Rpaas Summary Dashboard shows data over time, usage, status, and topology information
 */
@Component({
  selector: 'dg-sc-cyber-vaulting-dashboard',
  templateUrl: './cyber-vaulting-dashboard.component.html',
  styleUrls: ['./cyber-vaulting-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CyberVaultingDashboardComponent {
  /**
   * Notify parent to visit External Targets report page.
   */
  @Output() readonly gotoReport = new EventEmitter<null>();

  /**
   * Notify parent to visit Vault Protection page.
   */
  @Output() readonly gotoVaultProtection = new EventEmitter<any>(null);

  /**
   * Config for defining the dashboard grid properties.
   */
  readonly gridConfig = gridConfig;

  /**
   * Provides the user's RPaaS entitlement.
   */
  get rpaasEntitlement(): RansomwareInfo {
    return awsWarmStorageEntitlement(this.ctx.irisContext);
  }

  /**
   * Provides the RPaaS subscription quantity.
   */
  get isAwsSubscription(): boolean {
    return isRpaasAwsSubscription(this.ctx.irisContext);
  }

  /**
   * Provides the RPaaS warm storage subscription quantity.
   */
  get rpaasWarmStorageSize(): number {
    return rpaasWarmStorageQuantity(this.ctx.irisContext) ?? 0;
  }
  /**
   * Provides the RPaaS subscription quantity.
   */
  get rpaasColdStorageSize(): number {
    return rpaasColdStorageQuantity(this.ctx.irisContext) ?? 0;
  }

  constructor(
    private ctx: IrisContextService,
    readonly summaryDashboardService: RpaasSummaryDashboardService,
  ) {

    combineLatest([
      summaryDashboardService.showColdSubscription$,
      summaryDashboardService.showWarmSubscription$,
      summaryDashboardService.cardRowSpan$,
    ]).subscribe();
  }

  /**
   * Visit External Targets report page preset with the same filtered parameters.
   */
  performGotoReport() {
    localStorage.setItem(
      'report_filter_data-transferred-to-external-targets',
      JSON.stringify(this.summaryDashboardService.reportParamsSubject.value?.body.filters),
    );

    this.gotoReport.emit();
  }
}
