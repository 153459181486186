import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { AngularMaterialModule } from '@cohesity/helix';
import { SharedModule } from 'src/app/shared';

import { ClusterScopeSelectorComponent } from './cluster-scope-selector/cluster-scope-selector.component';
import { ClusterSwitcherComponent } from './cluster-switcher/cluster-switcher.component';
import { SelectedScopeComponent } from './selected-scope/selected-scope.component';

const COMPONENTS = [
  ClusterScopeSelectorComponent,
  SelectedScopeComponent,
  ClusterSwitcherComponent,
];

@NgModule({
  imports: [
    AngularMaterialModule,
    CommonModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    SharedModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class ScopeSelectorModule { }
