// Service: SearchService transformers and formatters

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.searchFormatters', ['C.utils'])
    .service('SearchServiceFormatter', SearchServiceUtilsFn);

  function SearchServiceUtilsFn(_, $q, $filter, JobRunsService,
    DateRangerService, ENUM_ARCHIVAL_TARGET, PubSourceServiceFormatter) {

    // The Service's API
    return {
      getArchiveTargetPub: getArchiveTargetPub,
      getPublicSnapshot: getPublicSnapshot,
      transformJobSearchesResponse: transformJobSearchesResponse,
      transformPublicSearchResultToPrivate:
        transformPublicSearchResultToPrivate,
      transformRemoteRestoreSearchResults: transformRemoteRestoreSearchResults,

    };


    /**
     * Transform the individual searchJob's results response and augmentit with
     * helper data. Also fetches any locally known Job summaries and decorates
     * the results lists with that info.
     *
     * @method    transformRemoteRestoreSearchResults
     * @param     {object}   resp   The server's response.
     * @returns   {object}   The searchJob object containing run's history.
     */
    function transformRemoteRestoreSearchResults(resp) {
      var data = angular.extend({
        protectionJobs: [],
      }, resp.data || {});

      // Use to query jobSummary for additional info (like if this job is known
      // on this cluster).
      var jobUids = [];

      data.protectionJobs.forEach(function eachRemoteJob(job) {
        // It can happen that there are no Runs (maybe only in beta), but this
        // prevents errors when there's no runs array. Also ensure they are
        // sorted newest first.
        job.protectionJobRuns = $filter('orderBy')(
          job.protectionJobRuns || [],
          '-snapshotTimeUsecs'
        );

        // Add this jobUid to the list.
        jobUids.push(job.jobUid);

        job._snapshot = job.protectionJobRuns[0];
        job._id = job.jobUid.id;

        // Because these results can come from multiple clusters, there's no
        // guarantee that the jobUid.id is unique. So we collapse the entire
        // jobUid into a unique string.
        job._trackBy = Object.values(job.jobUid).join('-');

        if (!job.protectionJobRuns.length) {
          job._selectedDateRange = {};
          return;
        }

        job._selectedDateRange = DateRangerService.generateRangeObject(
          job.protectionJobRuns.slice(-1)[0].snapshotTimeUsecs,
          job.protectionJobRuns[0].snapshotTimeUsecs
        );
      });

      // Now we fetch the jobSummaries for all the returned jobUids and decorate
      // the search's results list with that info, if found. The results of this
      // getJobSummary request are not directly returned here, but the
      // (potentially) enhanced results response instead.
      return !jobUids.length ?
        $q.resolve(data) :
        JobRunsService.getJobSummary({
            jobUidVec: jobUids,
            onlyReturnBasicSummary: true,
          })
          .then(
            function summariesReceived(summaries) {
              summaries = summaries.data || [];

              // If no summaries were found, shortcut this Fn and return the
              // search's results as is.
              if (!summaries.length) {
                return data;
              }

              // Additional info is available. Attach it to each of the previously
              // found search's results.
              data.protectionJobs.forEach(function eachResult(job) {
                summaries.some(function eachSummary(summary, idx) {
                  // If the remote search's job[] has the same jobUid as this one
                  // in the summaries list, add that info the the remote search's
                  // job[].
                  if (angular.equals(summary.backupJobSummary.jobDescription.jobUid, job.jobUid)) {
                    angular.extend(job, {
                      _jobSummary: summary.backupJobSummary,
                    });

                    // Since this Job summary was found and used, remove it from
                    // the list to progressively shorten this sub-loop.
                    return summaries.splice(idx, 1);
                  }
                });
              });

              return data;
            },
            function summariesError() {
              // Supress the error and just return the data as previously found.
              return data;
            }
          );
    }

    /**
     * Transform the the jobs response (list of searchJobs) and augment it with
     * helper data.
     *
     * @method    transformJobSearchesResponse
     * @param     {object}   resp   The server's response.
     * @returns   {array}    The list of transformed searchJobs.
     */
    function transformJobSearchesResponse(resp) {
      var data = [].concat(resp.data);
      var out = data.map(function eachSearchJob(job) {
        // Ensure an endTime for calculating duration and limiting date
        // ranges.
        var endTime = job.endTimeUsecs || Date.clusterNow() * 1000;
        var jobStatus = job.searchJobStatus;

        return angular.extend(job, {
          _id: job.searchJobUid.id,

          // These _isStatus properties are based on IRIS_VAULT_JOB_STATUS
          _isCanceled: jobStatus === 'kJobCanceled',
          _isFinished: jobStatus === 'kJobSucceeded',
          _isPaused: jobStatus === 'kJobPaused',
          _isRunning: jobStatus === 'kJobRunning' && !job.error,
          _duration: endTime - job.startTimeUsecs,
          _selectedDateRange:
            DateRangerService.generateRangeObject(job.startTimeUsecs, endTime),

          // For searchJobs, this is always a boolean.
          _snapshot: true,
        });
      });

      // If the input response was a single object (not an array), return just
      // the processed object. But if it was an array, return the array.
      return Array.isArray(resp.data) ? out : out[0];
    }

    /**
     * Get Snapshot
     * This snapshot structure matches with public api.
     *
     * @method     getPublicSnapshot
     * @param      {object}    snapshot  this is a private snapshot
     *                                   (i.e job run istance)
     * @return     {object}    pubSnapshot
     *                         {
     *                            jobRunId: 32520
     *                            startedTimeUsecs: 1534830864671945
     *                         }
     */
    function getPublicSnapshot(snapshot) {
      if (!snapshot) { return; }
      return {
        // Specifies the id of the Job Run that captured the snapshot.
        jobRunId: _.get(snapshot, 'instanceId.jobInstanceId'),

        // Specifies the time when the Job Run starts capturing a snapshot.
        // Specified as a Unix epoch Timestamp (in microseconds).
        startedTimeUsecs:
          _.get(snapshot, 'instanceId.jobStartTimeUsecs'),
      };
    }

    /**
     * gets Public archival target from private.
     *
     * @method   getArchiveTargetPub
     * @param    {object}   archiveTarget   private archive target.
     * @return   {object}   archival target which is used in public api
     */
    function getArchiveTargetPub(archiveTarget) {
      var archivalTarget = _.get(archiveTarget, 'target.archivalTarget');

      // This is required for the public api
      return archivalTarget ? {
        // Specifies the id of Archival Vault assigned
        // by the Cohesity Cluster.
        vaultId: archivalTarget.vaultId,

        // Name of the Archival Vault.
        vaultName: archivalTarget.name,

        // Specifies the type of the Archival External Target such as
        // 'kCloud' or 'kTape'.
        vaultType: ENUM_ARCHIVAL_TARGET[archivalTarget.type],
      } : undefined;
    }

    /**
     * Transforms a single object within the search result obtained from the
     * public API /public/restore/objects to the structure obtained from the
     * private API - /searchvms.
     *
     * This transformation is needed for the usage of cVmBrowser component
     * on the entities which are fetched by the public API and which can be
     * browsed through Yoda as servers or views.
     *
     * Incase any transformation is required for file search results to the
     * private API - /searchfiles, 'fileDocument' will have to be added similar
     * to the 'vmDocument'.
     *
     * @method   transformPublicSearchResultToPrivate
     * @param    {object}   publicSource   Specifies the object in the format
     *                                     received by the public API
     *                                     - /public/restore/objects
     * @return   {object}   Specifies the object in the format acceptable by
     *                      the cVmBrowser directive returned by private API
     *                      - /searchvms.
     */
    function transformPublicSearchResultToPrivate(publicSource) {
      return {
        registeredSource: PubSourceServiceFormatter
          // NOTE: ES returns only the ProtectionSource, instead of
          // ProtectionSourceNode hence the object is sent wrapped as the same.
          .transformSourceFromPublicToPrivate({
            protectionSource: publicSource.registeredSource,
            environment: publicSource.registeredSource.environment,
          }),
        vmDocument: {
          environment: publicSource._environment || publicSource.environment,
          jobName: publicSource.jobName,
          viewBoxId: publicSource.viewBoxId,
          objectId: {
            entity:
              // NOTE: ES returns only the ProtectionSource, instead of
              // ProtectionSourceNode hence it is sent wrapped as the same.
              PubSourceServiceFormatter.transformSourceFromPublicToPrivate({
                protectionSource: publicSource.snapshottedSource,
                environment: publicSource.snapshottedSource.environment,
              }).entity,
            jobId: publicSource.jobId,
            jobUid: {
              clusterId: publicSource.jobUid.clusterId,
              clusterIncarnationId: publicSource.jobUid.clusterIncarnationId,

              // This field is needed for consistency across public recovery
              // requests for the entities.
              id: publicSource.jobUid.id,

              // This field is needed by the cVmBrowser for its internal
              // requests. For all other operations, use 'id' field above.
              objectId: publicSource.jobUid.id
            },
          },
          versions: _.map(publicSource.versions,
            function eachVersion(version) {
              return {
                instanceId: {
                  jobInstanceId: version.jobRunId,
                  jobStartTimeUsecs: version.startedTimeUsecs,
                  attemptNum: version.attemptNumber,
                },
                indexingStatus: version.indexingStatus,
                replicaInfoList: version.replicaInfoList,
                isFullBackup: version.isFullBackup,
              };
            }
          ),
        },
      };
    }
  }

})(angular);
