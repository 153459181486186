import { NgModule } from '@angular/core';

import { DayValPipe } from './day-val.pipe';

/**
 * App Module. This is the root angular module.
 */
@NgModule({
  declarations: [DayValPipe],
  exports: [DayValPipe],
  providers: [DayValPipe],
})
export class DayValModule { }
