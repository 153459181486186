import { Pipe, PipeTransform } from '@angular/core';

import { IntegrationStatus } from '../../integration-configs';
import { IntegrationStatusToIconMap } from './integration-status-icon.model';

@Pipe({
  name: 'integrationStatusIcon',
  standalone: true,
})
export class IntegrationStatusIconPipe implements PipeTransform {
  /**
   * Transform health status type to corresponding icon
   *
   * @param value integration status
   * @returns integration status icon
   */
  transform(value: IntegrationStatus): string {
    return IntegrationStatusToIconMap[value] ?? null;
  }
}
