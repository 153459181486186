<!-- Open the template side panel if intent based views are enabled -->
<button type="button" mat-flat-button
  class="create-view-btn"
  *ngIf="hasPermissionToCreateView && createViewFromTemplateOnlyEnabled"
  (click)="openPanel()"
  cogDataId="create-view-button"
  color="primary">
  {{'createView' | translate}}
</button>

<button type="button" mat-flat-button
  class="create-view-btn"
  [matMenuTriggerFor]="menu"
  *ngIf="hasPermissionToCreateView && !createViewFromTemplateOnlyEnabled"
  cogDataId="create-view-button"
  color="primary">
  {{'create' | translate}}
</button>

<mat-menu #menu="matMenu">
  <ng-container *ngFor="let source of sources">
    <button mat-menu-item
      *ngIf="!source?.hidden"
      class="cog-primary"
      cogDataId="{{source.id}}"
      (click)="source.action && source.action()"
      [disabled]="source?.disabled">
      <cog-icon *ngIf="source?.icon" shape="{{source?.icon}}" size="md">
      </cog-icon>
      {{ source.translateKey | translate }}
    </button>
  </ng-container>
</mat-menu>
