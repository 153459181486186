/* eslint-disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CohesityElixirApiConfiguration, CohesityElixirApiConfigurationInterface } from './cohesity-elixir-api-configuration';

import { RunbooksService } from './services/runbooks.service';

/**
 * Provider for all CohesityElixirApi services, plus CohesityElixirApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    CohesityElixirApiConfiguration,
    RunbooksService
  ],
})
export class CohesityElixirApiModule {
  static forRoot(customParams: CohesityElixirApiConfigurationInterface): ModuleWithProviders<CohesityElixirApiModule> {
    return {
      ngModule: CohesityElixirApiModule,
      providers: [
        {
          provide: CohesityElixirApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
