import { Injectable } from '@angular/core';
import { AppName, ClusterConfig } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { StateGuardService } from 'src/app/core/state/state-guard.service';

import { AppServiceConfig, AppServiceType } from '../app-service.config';
import { SmartFilesNavService } from './smartfiles-nav.service';
import { SmartFilesGuard } from './smartfiles.guard';

/**
 * This service configures the SmartFiles service configuration within iris.
 */
@Injectable({ providedIn: 'root' })
export class SmartFilesServiceConfig extends AppServiceConfig {
  serviceType: AppServiceType = 'smartFiles';

  appName: AppName = 'smartFiles';

  // TODO: Use feature flag to determine landing state when adding v2 SmartFiles app.
  // homeState = 'helios-smartfiles-dashboard';
  homeState = 'software-upgrade.cluster-list';

  get clusterConfigPartial() {
    return {
      name: this.serviceTitle,
      _allClusters: false,
      _cohesityService: true,
      _globalContext: false,
      _nonCluster: true,
      _serviceType: this.serviceType,
    } as Partial<ClusterConfig>;
  }

  // Conditionally suports global search based on logic in AppLayoutComponent.
  supportsGlobalSearch = true;

  navProvider = this.navService;

  constructor(
    private navService: SmartFilesNavService,
    stateGuardService: StateGuardService,
    translate: TranslateService,
  ) {
    super(translate);
    stateGuardService.registerStateGuards([SmartFilesGuard]);
  }
}
