/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ApplyPoliciesRequest } from '../models/apply-policies-request';
import { BulkUpdateDataPoolsInclusionExclusionRequest } from '../models/bulk-update-data-pools-inclusion-exclusion-request';
import { DcStatsResponse } from '../models/dc-stats-response';
import { DlpSource } from '../models/dlp-source';
import { DlpSourceId } from '../models/dlp-source-id';
import { DlpSourceParams } from '../models/dlp-source-params';
import { DataPoolId } from '../models/data-pool-id';
import { GetDlpSourcesResponse } from '../models/get-dlp-sources-response';
import { GetDataPoolsInclusionExclusionResponse } from '../models/get-data-pools-inclusion-exclusion-response';
import { GetPatternsResponse } from '../models/get-patterns-response';
import { GetPoliciesResponse } from '../models/get-policies-response';
import { GetPolicyRunResultsResponse } from '../models/get-policy-run-results-response';
import { Pattern } from '../models/pattern';
import { PatternId } from '../models/pattern-id';
import { PatternParams } from '../models/pattern-params';
import { PatternValidationParams } from '../models/pattern-validation-params';
import { Policy } from '../models/policy';
import { PolicyId } from '../models/policy-id';
import { PolicyParams } from '../models/policy-params';
import { PolicyStatus } from '../models/policy-status';
import { Range } from '../models/range';
import { TimestampMsecs } from '../models/timestamp-msecs';

@Injectable({
  providedIn: 'root',
})
export class DlpApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getDcStats
   */
  static readonly GetDcStatsPath = '/dlp/dcStats';

  /**
   * Get the dc stats to power dashboard.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDcStats()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDcStats$Response(params: {

    /**
     * Number of patterns for which data will be returned in descending order of matched file count.
     */
    patternCount: null | number;

    /**
     * The number of objects for which sensitivity info needs to be returned ordered by sensitivity in descending order.
     */
    objectCount: null | number;

    /**
     * Start time in milli seconds after which the pattern matching happened.
     */
    startTimeMsecs: TimestampMsecs;

    /**
     * End time in milli seconds before which the pattern matching happened.
     */
    endTimeMsecs?: TimestampMsecs;

  }): Observable<StrictHttpResponse<DcStatsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DlpApiService.GetDcStatsPath, 'get');
    if (params) {

      rb.query('patternCount', params.patternCount, {});
      rb.query('objectCount', params.objectCount, {});
      rb.query('startTimeMsecs', params.startTimeMsecs, {});
      rb.query('endTimeMsecs', params.endTimeMsecs, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DcStatsResponse>;
      })
    );
  }

  /**
   * Get the dc stats to power dashboard.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDcStats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDcStats(params: {

    /**
     * Number of patterns for which data will be returned in descending order of matched file count.
     */
    patternCount: null | number;

    /**
     * The number of objects for which sensitivity info needs to be returned ordered by sensitivity in descending order.
     */
    objectCount: null | number;

    /**
     * Start time in milli seconds after which the pattern matching happened.
     */
    startTimeMsecs: TimestampMsecs;

    /**
     * End time in milli seconds before which the pattern matching happened.
     */
    endTimeMsecs?: TimestampMsecs;

  }): Observable<DcStatsResponse> {

    return this.getDcStats$Response(params).pipe(
      map((r: StrictHttpResponse<DcStatsResponse>) => r.body as DcStatsResponse)
    );
  }

  /**
   * Path part for operation getPolicies
   */
  static readonly GetPoliciesPath = '/dlp/policies';

  /**
   * Get the list of DLP policies.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPolicies()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPolicies$Response(params?: {

    /**
     * Filter by policy status.
     */
    status?: null | Array<PolicyStatus>;

    /**
     * Filter by policy.
     */
    policyIds?: null | Array<PolicyId>;

    /**
     * Filter by Data Pools.
     */
    dataPoolIds?: null | Array<DataPoolId>;

    /**
     * Filter by sensitivities.
     */
    sensitivities?: Array<Range>;

    /**
     * Filter to include/exclude deleted policies.
     */
    includeDeletedPolicies?: null | boolean;

  }): Observable<StrictHttpResponse<GetPoliciesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DlpApiService.GetPoliciesPath, 'get');
    if (params) {

      rb.query('status', params.status, {});
      rb.query('policyIds', params.policyIds, {});
      rb.query('dataPoolIds', params.dataPoolIds, {});
      rb.query('sensitivities', params.sensitivities, {});
      rb.query('includeDeletedPolicies', params.includeDeletedPolicies, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetPoliciesResponse>;
      })
    );
  }

  /**
   * Get the list of DLP policies.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPolicies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPolicies(params?: {

    /**
     * Filter by policy status.
     */
    status?: null | Array<PolicyStatus>;

    /**
     * Filter by policy.
     */
    policyIds?: null | Array<PolicyId>;

    /**
     * Filter by Data Pools.
     */
    dataPoolIds?: null | Array<DataPoolId>;

    /**
     * Filter by sensitivities.
     */
    sensitivities?: Array<Range>;

    /**
     * Filter to include/exclude deleted policies.
     */
    includeDeletedPolicies?: null | boolean;

  }): Observable<GetPoliciesResponse> {

    return this.getPolicies$Response(params).pipe(
      map((r: StrictHttpResponse<GetPoliciesResponse>) => r.body as GetPoliciesResponse)
    );
  }

  /**
   * Path part for operation createPolicy
   */
  static readonly CreatePolicyPath = '/dlp/policies';

  /**
   * Create a new policy.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPolicy()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPolicy$Response(params: {
      body: PolicyParams
  }): Observable<StrictHttpResponse<Policy>> {

    const rb = new RequestBuilder(this.rootUrl, DlpApiService.CreatePolicyPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Policy>;
      })
    );
  }

  /**
   * Create a new policy.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createPolicy$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPolicy(params: {
      body: PolicyParams
  }): Observable<Policy> {

    return this.createPolicy$Response(params).pipe(
      map((r: StrictHttpResponse<Policy>) => r.body as Policy)
    );
  }

  /**
   * Path part for operation downloadReports
   */
  static readonly DownloadReportsPath = '/dlp/download-reports';

  /**
   * Download the CSV report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadReports()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadReports$Response(params?: {

    /**
     * Filter by policy.
     */
    policyIds?: null | Array<PolicyId>;

    /**
     * Filter by Data Pools.
     */
    dataPoolIds?: null | Array<DataPoolId>;

    /**
     * Filter by sensitivities.
     */
    sensitivities?: Array<Range>;

    /**
     * Return rawData of the files.
     */
    rawData?: boolean;

  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, DlpApiService.DownloadReportsPath, 'get');
    if (params) {

      rb.query('policyIds', params.policyIds, {});
      rb.query('dataPoolIds', params.dataPoolIds, {});
      rb.query('sensitivities', params.sensitivities, {});
      rb.query('rawData', params.rawData, {});

    }
    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/csv'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Download the CSV report.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadReports$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadReports(params?: {

    /**
     * Filter by policy.
     */
    policyIds?: null | Array<PolicyId>;

    /**
     * Filter by Data Pools.
     */
    dataPoolIds?: null | Array<DataPoolId>;

    /**
     * Filter by sensitivities.
     */
    sensitivities?: Array<Range>;

    /**
     * Return rawData of the files.
     */
    rawData?: boolean;

  }): Observable<string> {

    return this.downloadReports$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation applyPolicies
   */
  static readonly ApplyPoliciesPath = '/dlp/policies/apply';

  /**
   * Apply the list of DLP policies for the provided data pools.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `applyPolicies()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  applyPolicies$Response(params: {
      body: ApplyPoliciesRequest
  }): Observable<StrictHttpResponse<GetPoliciesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DlpApiService.ApplyPoliciesPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetPoliciesResponse>;
      })
    );
  }

  /**
   * Apply the list of DLP policies for the provided data pools.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `applyPolicies$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  applyPolicies(params: {
      body: ApplyPoliciesRequest
  }): Observable<GetPoliciesResponse> {

    return this.applyPolicies$Response(params).pipe(
      map((r: StrictHttpResponse<GetPoliciesResponse>) => r.body as GetPoliciesResponse)
    );
  }

  /**
   * Path part for operation getPolicyById
   */
  static readonly GetPolicyByIdPath = '/dlp/policies/{id}';

  /**
   * Get the policy by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPolicyById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPolicyById$Response(params: {

    /**
     * The policy id.
     */
    id: PolicyId;

  }): Observable<StrictHttpResponse<Policy>> {

    const rb = new RequestBuilder(this.rootUrl, DlpApiService.GetPolicyByIdPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Policy>;
      })
    );
  }

  /**
   * Get the policy by ID.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPolicyById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPolicyById(params: {

    /**
     * The policy id.
     */
    id: PolicyId;

  }): Observable<Policy> {

    return this.getPolicyById$Response(params).pipe(
      map((r: StrictHttpResponse<Policy>) => r.body as Policy)
    );
  }

  /**
   * Path part for operation updatePolicy
   */
  static readonly UpdatePolicyPath = '/dlp/policies/{id}';

  /**
   * Update the policy.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePolicy()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePolicy$Response(params: {

    /**
     * The policy id.
     */
    id: PolicyId;
      body: PolicyParams
  }): Observable<StrictHttpResponse<Policy>> {

    const rb = new RequestBuilder(this.rootUrl, DlpApiService.UpdatePolicyPath, 'put');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Policy>;
      })
    );
  }

  /**
   * Update the policy.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updatePolicy$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePolicy(params: {

    /**
     * The policy id.
     */
    id: PolicyId;
      body: PolicyParams
  }): Observable<Policy> {

    return this.updatePolicy$Response(params).pipe(
      map((r: StrictHttpResponse<Policy>) => r.body as Policy)
    );
  }

  /**
   * Path part for operation deletePolicy
   */
  static readonly DeletePolicyPath = '/dlp/policies/{id}';

  /**
   * Delete the policy.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePolicy()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePolicy$Response(params: {

    /**
     * The policy id.
     */
    id: PolicyId;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DlpApiService.DeletePolicyPath, 'delete');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete the policy.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePolicy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePolicy(params: {

    /**
     * The policy id.
     */
    id: PolicyId;

  }): Observable<void> {

    return this.deletePolicy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getPolicyRunResults
   */
  static readonly GetPolicyRunResultsPath = '/dlp/policies/{id}/results';

  /**
   * Get the policy run results.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPolicyRunResults()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPolicyRunResults$Response(params: {

    /**
     * The policy id.
     */
    id: PolicyId;

  }): Observable<StrictHttpResponse<GetPolicyRunResultsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DlpApiService.GetPolicyRunResultsPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetPolicyRunResultsResponse>;
      })
    );
  }

  /**
   * Get the policy run results.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPolicyRunResults$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPolicyRunResults(params: {

    /**
     * The policy id.
     */
    id: PolicyId;

  }): Observable<GetPolicyRunResultsResponse> {

    return this.getPolicyRunResults$Response(params).pipe(
      map((r: StrictHttpResponse<GetPolicyRunResultsResponse>) => r.body as GetPolicyRunResultsResponse)
    );
  }

  /**
   * Path part for operation getPatterns
   */
  static readonly GetPatternsPath = '/dlp/patterns';

  /**
   * Get a list of DLP patterns.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPatterns()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPatterns$Response(params?: {

  }): Observable<StrictHttpResponse<GetPatternsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DlpApiService.GetPatternsPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetPatternsResponse>;
      })
    );
  }

  /**
   * Get a list of DLP patterns.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPatterns$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPatterns(params?: {

  }): Observable<GetPatternsResponse> {

    return this.getPatterns$Response(params).pipe(
      map((r: StrictHttpResponse<GetPatternsResponse>) => r.body as GetPatternsResponse)
    );
  }

  /**
   * Path part for operation createPattern
   */
  static readonly CreatePatternPath = '/dlp/patterns';

  /**
   * Create a new pattern.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPattern()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPattern$Response(params: {
      body: PatternParams
  }): Observable<StrictHttpResponse<Pattern>> {

    const rb = new RequestBuilder(this.rootUrl, DlpApiService.CreatePatternPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Pattern>;
      })
    );
  }

  /**
   * Create a new pattern.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createPattern$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPattern(params: {
      body: PatternParams
  }): Observable<Pattern> {

    return this.createPattern$Response(params).pipe(
      map((r: StrictHttpResponse<Pattern>) => r.body as Pattern)
    );
  }

  /**
   * Path part for operation getPatternById
   */
  static readonly GetPatternByIdPath = '/dlp/patterns/{id}';

  /**
   * Get pattern by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPatternById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPatternById$Response(params: {

    /**
     * The pattern id.
     */
    id: PatternId;

  }): Observable<StrictHttpResponse<Pattern>> {

    const rb = new RequestBuilder(this.rootUrl, DlpApiService.GetPatternByIdPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Pattern>;
      })
    );
  }

  /**
   * Get pattern by ID.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPatternById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPatternById(params: {

    /**
     * The pattern id.
     */
    id: PatternId;

  }): Observable<Pattern> {

    return this.getPatternById$Response(params).pipe(
      map((r: StrictHttpResponse<Pattern>) => r.body as Pattern)
    );
  }

  /**
   * Path part for operation updatePattern
   */
  static readonly UpdatePatternPath = '/dlp/patterns/{id}';

  /**
   * Update pattern.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePattern()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePattern$Response(params: {

    /**
     * The pattern id.
     */
    id: PatternId;
      body: PatternParams
  }): Observable<StrictHttpResponse<Pattern>> {

    const rb = new RequestBuilder(this.rootUrl, DlpApiService.UpdatePatternPath, 'put');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Pattern>;
      })
    );
  }

  /**
   * Update pattern.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updatePattern$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePattern(params: {

    /**
     * The pattern id.
     */
    id: PatternId;
      body: PatternParams
  }): Observable<Pattern> {

    return this.updatePattern$Response(params).pipe(
      map((r: StrictHttpResponse<Pattern>) => r.body as Pattern)
    );
  }

  /**
   * Path part for operation deletePattern
   */
  static readonly DeletePatternPath = '/dlp/patterns/{id}';

  /**
   * Delete pattern.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePattern()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePattern$Response(params: {

    /**
     * The pattern id.
     */
    id: PatternId;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DlpApiService.DeletePatternPath, 'delete');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete pattern.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePattern$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePattern(params: {

    /**
     * The pattern id.
     */
    id: PatternId;

  }): Observable<void> {

    return this.deletePattern$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation validatePattern
   */
  static readonly ValidatePatternPath = '/dlp/patterns/validate';

  /**
   * Validate a pattern against a test string.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validatePattern()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validatePattern$Response(params: {
      body: PatternValidationParams
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DlpApiService.ValidatePatternPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Validate a pattern against a test string.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `validatePattern$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validatePattern(params: {
      body: PatternValidationParams
  }): Observable<void> {

    return this.validatePattern$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getDataPoolsInclusionExclusion
   */
  static readonly GetDataPoolsInclusionExclusionPath = '/dlp/data-pools/inclusion-exclusion';

  /**
   * Get a list of Data pools file path & file extensions inclusion exclusion rules.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDataPoolsInclusionExclusion()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDataPoolsInclusionExclusion$Response(params?: {

  }): Observable<StrictHttpResponse<GetDataPoolsInclusionExclusionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DlpApiService.GetDataPoolsInclusionExclusionPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetDataPoolsInclusionExclusionResponse>;
      })
    );
  }

  /**
   * Get a list of Data pools file path & file extensions inclusion exclusion rules.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDataPoolsInclusionExclusion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDataPoolsInclusionExclusion(params?: {

  }): Observable<GetDataPoolsInclusionExclusionResponse> {

    return this.getDataPoolsInclusionExclusion$Response(params).pipe(
      map((r: StrictHttpResponse<GetDataPoolsInclusionExclusionResponse>) => r.body as GetDataPoolsInclusionExclusionResponse)
    );
  }

  /**
   * Path part for operation bulkUpdateDataPoolsInclusionExclusion
   */
  static readonly BulkUpdateDataPoolsInclusionExclusionPath = '/dlp/data-pools/inclusion-exclusion';

  /**
   * Bulk updates update the Data pools file path & file extensions inclusion exclusion rules.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkUpdateDataPoolsInclusionExclusion()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkUpdateDataPoolsInclusionExclusion$Response(params: {
      body: BulkUpdateDataPoolsInclusionExclusionRequest
  }): Observable<StrictHttpResponse<GetDataPoolsInclusionExclusionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DlpApiService.BulkUpdateDataPoolsInclusionExclusionPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetDataPoolsInclusionExclusionResponse>;
      })
    );
  }

  /**
   * Bulk updates update the Data pools file path & file extensions inclusion exclusion rules.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bulkUpdateDataPoolsInclusionExclusion$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkUpdateDataPoolsInclusionExclusion(params: {
      body: BulkUpdateDataPoolsInclusionExclusionRequest
  }): Observable<GetDataPoolsInclusionExclusionResponse> {

    return this.bulkUpdateDataPoolsInclusionExclusion$Response(params).pipe(
      map((r: StrictHttpResponse<GetDataPoolsInclusionExclusionResponse>) => r.body as GetDataPoolsInclusionExclusionResponse)
    );
  }

  /**
   * Path part for operation getDlpSources
   */
  static readonly GetDlpSourcesPath = '/dlp/sources';

  /**
   * Get a list of DLP sources. A DLP Source is a DataPool with filters.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDlpSources()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDlpSources$Response(params?: {

  }): Observable<StrictHttpResponse<GetDlpSourcesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DlpApiService.GetDlpSourcesPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetDlpSourcesResponse>;
      })
    );
  }

  /**
   * Get a list of DLP sources. A DLP Source is a DataPool with filters.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDlpSources$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDlpSources(params?: {

  }): Observable<GetDlpSourcesResponse> {

    return this.getDlpSources$Response(params).pipe(
      map((r: StrictHttpResponse<GetDlpSourcesResponse>) => r.body as GetDlpSourcesResponse)
    );
  }

  /**
   * Path part for operation createDlpSource
   */
  static readonly CreateDlpSourcePath = '/dlp/sources';

  /**
   * Create a new DLP source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createDlpSource()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDlpSource$Response(params: {
      body: DlpSourceParams
  }): Observable<StrictHttpResponse<DlpSource>> {

    const rb = new RequestBuilder(this.rootUrl, DlpApiService.CreateDlpSourcePath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DlpSource>;
      })
    );
  }

  /**
   * Create a new DLP source.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createDlpSource$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDlpSource(params: {
      body: DlpSourceParams
  }): Observable<DlpSource> {

    return this.createDlpSource$Response(params).pipe(
      map((r: StrictHttpResponse<DlpSource>) => r.body as DlpSource)
    );
  }

  /**
   * Path part for operation getDlpSourceById
   */
  static readonly GetDlpSourceByIdPath = '/dlp/sources/{id}';

  /**
   * Get DLP Source by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDlpSourceById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDlpSourceById$Response(params: {
    id: DlpSourceId;

  }): Observable<StrictHttpResponse<DlpSource>> {

    const rb = new RequestBuilder(this.rootUrl, DlpApiService.GetDlpSourceByIdPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DlpSource>;
      })
    );
  }

  /**
   * Get DLP Source by ID.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDlpSourceById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDlpSourceById(params: {
    id: DlpSourceId;

  }): Observable<DlpSource> {

    return this.getDlpSourceById$Response(params).pipe(
      map((r: StrictHttpResponse<DlpSource>) => r.body as DlpSource)
    );
  }

  /**
   * Path part for operation updateDlpSource
   */
  static readonly UpdateDlpSourcePath = '/dlp/sources/{id}';

  /**
   * Update DLP Source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDlpSource()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDlpSource$Response(params: {
    id: DlpSourceId;
      body: DlpSourceParams
  }): Observable<StrictHttpResponse<DlpSource>> {

    const rb = new RequestBuilder(this.rootUrl, DlpApiService.UpdateDlpSourcePath, 'put');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DlpSource>;
      })
    );
  }

  /**
   * Update DLP Source.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateDlpSource$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDlpSource(params: {
    id: DlpSourceId;
      body: DlpSourceParams
  }): Observable<DlpSource> {

    return this.updateDlpSource$Response(params).pipe(
      map((r: StrictHttpResponse<DlpSource>) => r.body as DlpSource)
    );
  }

  /**
   * Path part for operation deleteDlpSource
   */
  static readonly DeleteDlpSourcePath = '/dlp/sources/{id}';

  /**
   * Delete DLP Source.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDlpSource()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDlpSource$Response(params: {
    id: DlpSourceId;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DlpApiService.DeleteDlpSourcePath, 'delete');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete DLP Source.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteDlpSource$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDlpSource(params: {
    id: DlpSourceId;

  }): Observable<void> {

    return this.deleteDlpSource$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
