import { AppModuleConfig, CanAccess } from 'src/app/app-module.config';

const viewAccess: CanAccess = ctx =>
  ctx.PROTECTION_VIEW &&
  !ctx.clusterInfo._isDisaggregatedStorage &&
  !ctx.isTenantUser &&
  !ctx.isHeliosTenantUser;
const modifyAccess: CanAccess = ctx => ctx.PROTECTION_MODIFY;

export const DataTieringConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'data-tiering.**',
      url: '/data-tiering',
      loadChildren: () => import('./data-tiering.module').then(m => m.DataTieringModule),
    },
  ],
  allClusterMap: {
    'data-tiering': false,
    'data-tiering.dashboard': false,
    'data-tiering.analysis': false,
    'data-tiering.analysis-new': false,
    'data-tiering.analyse-source': false,
    'data-tiering.create': false,
    'data-tiering.edit': false,
    'data-tiering.edit-new': false,
    'data-tiering.uptier': false,
    'data-tiering.task': false,
    'data-tiering.task.details': false,
    'data-tiering.task.audit': false,
  },
  getStateAccessMap() {
    return {
      'data-tiering': viewAccess,
      'data-tiering.dashboard': viewAccess,
      'data-tiering.analysis': viewAccess,
      'data-tiering.analysis-new': viewAccess,
      'data-tiering.analyse-source': modifyAccess,
      'data-tiering.create': modifyAccess,
      'data-tiering.edit': modifyAccess,
      'data-tiering.edit-new': modifyAccess,
      'data-tiering.uptier': modifyAccess,
      'data-tiering.task': viewAccess,
      'data-tiering.task.details': viewAccess,
      'data-tiering.task.audit': viewAccess,
    };
  }
};
