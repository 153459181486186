import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HadoopSourcesUtilityService {

  constructor() { }

  /**
   * Get the HDFS Port label based on the protocol chosen in hdfsConnectionType.
   *
   * @returns HDFS Port label string.
   */
  getHdfsPortLabelStr(hdfsConnectionType: string): string {
    let portLabel = 'webhdfsPort';
    if (hdfsConnectionType) {
      portLabel = `${hdfsConnectionType.toLowerCase()}Port`;
    }
    return portLabel;
  }
}
