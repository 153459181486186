import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AutoDestroyable } from '@cohesity/utils';
import { merge, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'dg-scan-object-search-field',
  templateUrl: './object-search-field.component.html',
  styleUrls: ['./object-search-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectSearchFieldComponent extends AutoDestroyable implements OnInit {
  /**
   * Flag to show a loading spinner when the query is running.
   */
  @Input() searchPending = false;

  /**
   * Output indicating that a search should be triggered.
   */
  @Output() queryChanged = new EventEmitter<string>();

  /**
   * The input search control.
   */
  searchControl = new UntypedFormControl();

  /**
   * Subject that triggers when a user presses enter.
   */
  enterKeyUp = new Subject<string>();

  constructor(){
    super();
  }

  /**
   * Listen for input changes on a debounce time, or enter events. Don't emit the event unless the search
   * query has changed.
   */
  ngOnInit() {
    merge(this.searchControl.valueChanges.pipe(debounceTime(600)), this.enterKeyUp)
      .pipe(
        this.untilDestroy(),
        distinctUntilChanged()
      )
      .subscribe(query => this.queryChanged.emit(query));
  }
}
