import { getConfigByKey } from '@cohesity/iris-core';
import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const networkingAccess = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngNetworking && ctx.CLUSTER_MODIFY && !ctx.isHeliosTenantUser;

const hostMappingAccess = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngNetworking && ctx.CLUSTER_MODIFY &&
  !ctx.isHeliosTenantUser && getConfigByKey(ctx.irisContext, 'networking.hostMapping', true);

const routingAccess = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngNetworking && ctx.CLUSTER_MODIFY &&
  !ctx.isHeliosTenantUser && getConfigByKey(ctx.irisContext, 'networking.routes', true);

const ipmiAccess = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngNetworking && ctx.CLUSTER_MODIFY &&
  ctx.clusterInfo._isPhysicalInstall;

export const NetworkingConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'ng-networking.**',
      url: '^/networking/',
      loadChildren: () => import('./networking.module').then(m => m.NetworkingModule)
    },
  ],
  getStateAccessMap(): StateAccessMap {
    return {
      'ng-networking': networkingAccess,
      'ng-networking.firewall': networkingAccess,
      'ng-networking.ipmi': ipmiAccess,
      'ng-networking.vipVlan': networkingAccess,
      'ng-networking.routes': routingAccess,
      'ng-networking.hostMap': hostMappingAccess,
      'ng-networking.interfaces': networkingAccess,
    };
  }
};
