import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { NaLabelPipeService } from '@cohesity/utils';
import moment from 'moment';
import { LocaleService } from 'src/app/core/services';

/**
 * Description
 *  Converts passed start and end timestamp into time range string.
 *
 * Example
 *  {{ "2020-10-08T16:00:48.475Z" | timeRange: "2020-10-09T00:00:48.475Z" }} // 09:00 AM - 05:00 PM
 */
@Injectable({ providedIn: 'root' })
export class TimeRangePipeService implements PipeTransform {

  constructor(private naPipeService: NaLabelPipeService, private localeService: LocaleService) {}

  /**
   * Transform passed start and end date to time range e.g. '09:00 AM - 05:00 PM'
   * if either value is null or undefined return '-' .
   *
   * @param startTime instance of date
   * @param endTime instance of date
   */
  transform(startTime: Date | number, endTime: Date | number): string {
    const localeTimeFormat = this.localeService.dateLocale.preferredTimeFormat;
    if (!startTime || !endTime) {
      return this.naPipeService.transform(null);
    }
    return `${moment(startTime).format(localeTimeFormat)} -
      ${moment(endTime).format(localeTimeFormat)}`;
  }

}

@Pipe({ name: 'timeRange' })
export class TimeRangePipe extends TimeRangePipeService implements PipeTransform { }
