import { Injectable } from '@angular/core';
import { PhysicalProtectionSource } from '@cohesity/api/v1';
import { ProtectdObjectsActionRequest } from '@cohesity/api/v2';
import { NavItem } from '@cohesity/helix';
import { IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { StateService } from '@uirouter/core';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { StateManagementService } from 'src/app/core/services';
import { RestoreConfigService } from 'src/app/modules/restore/restore-shared';
import { Environment, HostType, RecoveryAction } from 'src/app/shared';

import { AgentService } from '../agent/shared/agent.service';
import { ObjectActionCreator } from './object-action-creator';
import { ObjectActionProvider } from './object-action-provider';
import { ObjectInfoService, ObjectInfoServiceOptions } from './object-info.service';
import { SimpleObjectInfo } from './object-menu-provider';
import { ObjectProtectAction } from './object-protect-action.constants';
import { PhysicalObjectActionCreator } from './physical-object-action-creator';

@Injectable()
export class PhysicalObjectActionProvider extends ObjectActionProvider {
  /**
   * The provider for this service is manually set up in object-actions-menu.service, which must provide
   * the list of providers as an array in the correct order. In order to maintain some kind of sanity,
   * the providers are listed here, they should match the order of the constructor args.
   */
  static physicalObjectActionProviderDependencies = [
    ObjectInfoService,
    RestoreConfigService,
    StateManagementService,
    StateService,
    ObjectActionCreator,
    IrisContextService,
    PhysicalObjectActionCreator,
    AgentService,
  ];

  exclusiveProtection = true;

  supportsBulkActions = false;

  constructor(
    readonly objectStore: ObjectInfoService,
    readonly restoreConfig: RestoreConfigService,
    readonly stateManagementService: StateManagementService,
    readonly stateService: StateService,
    readonly actionCreator: ObjectActionCreator,
    readonly irisContextService: IrisContextService,
    readonly physicalActionCreator: PhysicalObjectActionCreator,
    readonly agentService: AgentService,
  ) {
    super(objectStore, restoreConfig, stateManagementService, stateService, actionCreator, irisContextService);
  }

  getObjectActions(object: SimpleObjectInfo): Observable<NavItem[]> {
    return combineLatest([
      this.getPhysicalObjectActions(object),
      of(super.canProtect(object)).pipe(
        map(canProtect => (canProtect ? this.physicalActionCreator.createPhysicalProtectAction([object]) : [])),
        map(actions => actions.filter(action => this.filterActionByAccess(action)))
      ),
    ]).pipe(
      map(([baseActions, physicalActions]) => [...baseActions, ...physicalActions]),
      map(actions => this.sortActions(actions))
    );
  }

  /**
   * Determines the backup type for the given object.
   *
   * @param     object   Specifies the object on which action is to be
   *                     performed.
   * @returns   Returns the object action key.
   */
  private getObjectActionKey(object: SimpleObjectInfo): ProtectdObjectsActionRequest['objectActionKey'] {
    if (object?.objectActionKey) {
      return object.objectActionKey;
    }

    const objectActionKey = (isDmsScope(this.irisContextService.irisContext) &&

      // Remove this null value once kPhysicalFiles environment is supported as part of object action keys request.
      [Environment.kPhysical, Environment.kPhysicalFiles].includes(object.environment) ? null :
      object.environment) as ProtectdObjectsActionRequest['objectActionKey'];

    return objectActionKey;
  }

  /**
   * Extract the additional lookup options for an object from the simple object
   *
   * @param object The current object
   * @returns An options object with the accessClusster or regionId set and the action key if any.
   */
  protected getObjectInfoOptions(object: SimpleObjectInfo): ObjectInfoServiceOptions {
    return {
      accessClusterId: object.accessClusterId,
      regionId: object.regionId,
      actionKey: object.objectActionKey || this.getObjectActionKey(object),
    };
  }

  /**
   * Returns an observable of physical source details based on object info.
   *
   * @param object The protected object.
   * @returns An observable whichyields protected source node info.
   */
  private getSourceDataNode(object: SimpleObjectInfo): Observable<PhysicalProtectionSource> {
    const protectionSource = object.v1Object
      ? of(object.v1Object)
      : this.objectStore.getV1ObjectInfo(
        object.id,
        { accessClusterId: object.accessClusterId, regionId: object.regionId }
      ).pipe(
          filter(entry => !entry.loading),
          map(entry => entry.item)
        );
    return protectionSource.pipe(map(source => source?.protectionSource?.physicalProtectionSource));
  }

  /**
   * Override the parent method so that we only make lookups for leaf nodes.
   *
   * @param object The protected object.
   * @param type The type of recovery action.
   * @returns An observable, which yields the NavItem or null.
   */
  getRecoveryAction(object: SimpleObjectInfo, type: RecoveryAction): Observable<NavItem> {
    return this.getSourceDataNode(object).pipe(
      switchMap(dataNode =>

        // Filtering out IVM action since it is not supported for Physical AIX host type.
        dataNode?.hostType === HostType.kAix && type === RecoveryAction.InstantVolumeMount ?
          of(null) : super.getRecoveryAction(object, type)
      )
    );
  }

  /**
   * Gets a list of nav item options available for a single object.
   *
   * @param object The object to edit protection settings for.
   * @returns An observable, which yields the NavItem or Null.
   */
  getPhysicalObjectActions(object: SimpleObjectInfo): Observable<NavItem[]> {
    return combineLatest([
      this.getRecoveryAction(object, RecoveryAction.RecoverFiles),
      this.getRecoveryAction(object, RecoveryAction.DownloadFilesAndFolders),
      this.getRecoveryAction(object, RecoveryAction.InstantVolumeMount),
      this.getEditObjectProtectionAction(object),
      this.getProtectedObjectAction([object], ObjectProtectAction.ProtectNow),
      this.getProtectedObjectAction([object], ObjectProtectAction.UnProtect),
      this.getProtectedObjectAction([object], ObjectProtectAction.Resume),
      this.getProtectedObjectAction([object], ObjectProtectAction.CancelRun),
    ]).pipe(
      map(actions => actions.filter(action => this.filterActionByAccess(action))),
      map(actions => this.sortActions(actions))
    );
  }

  /**
   * Creates and returns the upgrade agent action for Physical
   *
   * @param object The object to edit protection settings for.
   * @returns An observable, which yields the NavItem or Null.
   */
  getPhysicalUpgradeAgentAction(object: SimpleObjectInfo): Observable<NavItem> {
    if (!object) {
      return of(null);
    }

    return of({
      displayName: 'upgradeAgent',
      icon: 'helix:upgrade',
      subNavList: [
        {
          displayName: 'upgradeNow',
          action: () => this.agentService.openDmsAgentUpgradeDialog(object?.v1Object),
        },
        {
          displayName: 'scheduleForLater',
          action: () => this.agentService.openDmsScheduleAgentUpgradeDialog(object?.v1Object),
        },
      ]
    });
  }

  /**
   * Creates and returns the edit protection action for Physical, only if it is protected
   * as an object.
   *
   * @param object The object to edit protection settings for.
   * @returns An observable, which yields the NavItem or Null.
   */
  getEditObjectProtectionAction(object: SimpleObjectInfo): Observable<NavItem> {
    if (!this.objectStore.getObjectInfo || !object.isProtected) {
      return of(null);
    }
    return this.objectStore
      .getObjectInfo(object.id, { accessClusterId: object.accessClusterId, regionId: object.regionId })
      .pipe(
        filter(entry => !entry.loading),
        map(entry => entry.item),
        map(item => {
          if (!item || !item.objectBackupConfiguration || item?.objectBackupConfiguration?.isAutoProtectConfig) {
            return null;
          }
          // TODO (Geetha) Update this condition once block protection implemented
          return this.actionCreator.createEditObjectProtectionAction(
            (isDmsScope(this.irisContextService.irisContext)
              ? Environment.kPhysicalFiles
              : item.objectBackupConfiguration.environment) as any,
            item.id,
            { accessClusterId: object.accessClusterId, regionId: object.regionId }
          );
        })
      );
  }

  getBulkObjectActions(objects: SimpleObjectInfo[]): Observable<NavItem[]> {
    return combineLatest([
      super.getBulkObjectActions(objects).pipe(
        // Filter out the default protect action to switch with block and file
        // based protection.
        map(actions => actions.filter(action => action.displayName !== 'protect')),
      ),
      super.canProtectAll(objects).pipe(
        map(canProtect => canProtect ? this.physicalActionCreator.createPhysicalProtectAction(objects) : []),
        map(actions => actions.filter(action => this.filterActionByAccess(action))),
      ),
    ]).pipe(
      map(([baseActions, physicalActions]) => [...baseActions, ...physicalActions]),
      map(actions => this.sortActions(actions)),
    );
  }
}
