import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { RpaasConfig } from '@cohesity/api/v2';
import { ObservableInput } from 'ngx-observable-input';
import { Observable } from 'rxjs';
import { PolicyUtils } from 'src/app/modules/policy-shared/protection-policy-utils';
import { TimePeriodSelectorForm, TypedFormGroup } from 'src/app/shared';
import { PolicyResponse } from 'src/app/shared/policy';
import {
  ArchivalTargetType,
  BaseTargetForm,
  GroupActionCloudVaultFormModel,
  ProtectionGroup,
  RetentionUnit
} from '../../../models';


/**
 * Group Action CloudVault From section populated from policy information.
 */
export class GroupActionCloudVaultForm
  extends TypedFormGroup<GroupActionCloudVaultFormModel>
  implements BaseTargetForm {
  /**
   * Rpaas target name.
   */
  get targetName(): string {
    return this.get('target')?.value?.vaultName;
  }

  /**
   * Rpaas target ID.
   */
  get targetId(): number {
    return this.get('target')?.value?.vaultId;
  }

  /**
   * Retention units like 'Week', 'Day', 'Month'.
   */
  get retentionUnit(): RetentionUnit {
    return this.get('retention')?.value?.type;
  }

  /**
   * Retention duration number.
   */
  get retentionDuration(): number {
    return this.get('retention')?.value?.value;
  }

  /**
   * CloudVault retention form group.
   */
  get retentionForm(): TimePeriodSelectorForm {
    return this.get('retention') as TimePeriodSelectorForm;
  }

  /**
   * True if this CloudVault form is enabled and its values should be submitted.
   */
  get enabled(): boolean {
    return this.get('enabled')?.value;
  }

  /**
   * CloudVault target type.
   */
  readonly targetType: ArchivalTargetType;

  constructor(initialValue: GroupActionCloudVaultFormModel) {
    super({
      enabled: new UntypedFormControl(initialValue?.enabled),
      onlySuccessful: new UntypedFormControl(initialValue?.onlySuccessful),
      retention: new TimePeriodSelectorForm(initialValue?.retention, PolicyUtils.retentionTypeOptions),
      target: new UntypedFormControl(initialValue?.target, Validators.required),
    });

    this.targetType = initialValue?.target?.targetType;

    this.markAsTouched();
  }
}

/**
 * Group Action CloudVault Form.
 */
export class GroupActionCloudVaultFormArray extends UntypedFormArray {
  constructor(rpaasTargets: RpaasConfig[]) {
    super(
      rpaasTargets.map(
        rpaasTarget =>
          new GroupActionCloudVaultForm({
            enabled: true,
            onlySuccessful: true,
            retention: {
              value: rpaasTarget.retention.duration,
              type: rpaasTarget.retention.unit,
            },
            target: {
              vaultName: rpaasTarget.targetName,
              vaultId: rpaasTarget.targetId,
              targetType: rpaasTarget.targetType,
            },
          })
      )
    );
  }
}

/**
 * @description
 *
 * Group action modal CloudVault form.
 */
@Component({
  selector: 'coh-group-cloudvault-form',
  templateUrl: './group-cloudvault-form.component.html',
  styleUrls: ['./group-cloudvault-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class GroupCloudVaultFormComponent {
  /**
   * Protection group instance.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('protectionGroup') protectionGroup$: Observable<ProtectionGroup>;

  /**
   * Protection group policy data.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('policy') policy$: Observable<PolicyResponse>;

  /**
   * Group Action CloudVault form group.
   */
  @Input() formGroup: GroupActionCloudVaultForm;
}
