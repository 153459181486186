import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const dmsSfdcAccess = (ctx: StateAccessContext) =>
  (ctx.isDmsUser && ctx.FEATURE_FLAGS.dmsSfdcRegistration) ||
  (ctx.FEATURE_FLAGS.ngSourceEnabled && ctx.PROTECTION_VIEW);

export const SfdcSourceConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'sfdc.**',
      url: '/sfdc/authenticate',
      loadChildren: () => import('./sfdc.module').then(m => m.SfdcSourceModule),
    }
  ],
  allClusterMap: {
    'sfdc.authenticate': true,
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'sfdc.authenticate': dmsSfdcAccess,
    };
  },
};
