import {
  Clause,
  ClusterIdentifierId,
  DataPoolId,
  DataSource,
  DataSourceId,
  Policy,
  PolicyId,
  Shield,
  ShieldType,
  View,
} from '@cohesity/api/argus';
import { DataPool } from '@cohesity/api/inventory-mgr';
import { Environment } from '@cohesity/iris-shared-constants';
import { getClusterIdentifier } from '@cohesity/utils';

/**
 * Specifies the Data Pool id
 */
export type ViewUuid = null | string;

/**
 * Data Pools Glance Bar model.
 */
export interface DataPoolsMetadataSummary {
  /** Total number of data pools for glancebar */
  totalDataPools?: number;

  /** The number of data pools getting govern */
  dataPoolsToGovern?: number;

  /** The number of data pools getting classified using DLP */
  dataPoolsToClassify?: number;

   /** Protected data pools for glancebar */
  totalShields?: number;

  /** Total number of data sources for glancebar */
  totalDataSources?: number;

  /** Total data for glancebar */
  totalDataSize?: number;

  /** Total number of objects for glancebar */
  totalObjects?: number;
}

/**
 * Return the data pool clause for the given view.
 *
 * @param view The view details.
 * @param dataSourceId The Data Source id.
 * @returns The Data pools cause for the given view.
 */
export const getViewDataPoolClause = (view: View, dataSourceId: DataSourceId): Clause => ({
  dataSourceId,
  predicate: `environment = '${Environment.kView}' AND viewId = ${view.viewId} AND viewName = '${view.name}'`,
});

/**
 * Decode the view predicate.
 *
 * @param clause The Data pool clause.
 * @returns The extracted env, viewId and viewName.
 */
export const decodeViewPredicate = (clause: Clause) => {
  const supportedFields = new Set(['environment', 'viewId', 'viewName']);
  return clause.predicate
    .split(' AND ')
    .filter((exp) => {
      const fieldName = exp.split(' = ')[0];
      return supportedFields.has(fieldName);
    })
    .map((c) => c.split(' = ')[1].replace(/'/g, ''))
    .filter(Boolean);
};

/**
 * Extract ids from view data pool clause.
 *
 * @param clause The Data pool clause.
 * @returns the extracted ids from data pool clause.
 */
export const getViewDetailsFromClause = (
  clause: Clause,
  sourceIdToClusterIdentifierId?: Map<DataSourceId, ClusterIdentifierId>,
) => {
  const [environment, viewId, viewName] = decodeViewPredicate(clause);
  return {
    environment,
    viewId,
    viewName,
    dataSourceId: clause.dataSourceId,
    viewUuid: sourceIdToClusterIdentifierId ? getViewUuid({
      viewId: +viewId,
      name: viewName,
      clusterIdentifierId: sourceIdToClusterIdentifierId.get(clause.dataSourceId),
    }) : null,
  };
};

/**
 * get viewUuid from a given view
 *
 * @param view given view
 * @returns viewUuid corresponding to view
 */
export const getViewUuid = (view: View): ViewUuid => `${view.clusterIdentifierId}:${view.viewId}:${view.name}`;

/** The custom prefix value for the views found for which data pools are not yet created */
export const newViewIdPrefix = 'new-view:';

/** The regex for match new view */
export const newViewIdRegex = new RegExp(`^${newViewIdPrefix}.*`, 'gmi');

/**
 * Transforms a given view to a data pool
 *
 * @param view the given view
 * @returns transformed data pool
 */
export const transformViewToDataPool = (view: View, dataSourceId: DataSourceId): DataPool => ({
  // Fabricating the data pool id using which will be later removed during creating the data pool.
  id: `${newViewIdPrefix}${getViewUuid(view)}`,
  name: view.name,
  clauses: [getViewDataPoolClause(view, dataSourceId)],
  enabled: false,
});

/**
 * Spit the give data pool into 2 set 1st existing and 2nd new data pools.
 *
 * @param dataPools The data pools.
 * @returns The new and existing data pools.
 */
export const splitByNewView = (dataPools: DataPool[]): Record<'dataPools' | 'newDataPools', DataPool[]> => {
  const out: Record<'dataPools' | 'newDataPools', DataPool[]> = { dataPools: [], newDataPools: [] };

  (dataPools || []).forEach(dataPool => {
    if (dataPool.id.match(newViewIdRegex)) {
      // removing the fabricated id to new views added using transformViewToDataPool.
      out.newDataPools.push({ ...dataPool, id: undefined });
    } else {
      out.dataPools.push(dataPool);
    }
  });

  return out;
};

/**
 * returns source id to cluster identifier id map
 *
 * @param sources provided data sources
 * @returns source id to cluster identifier id map
 */
export const sourceIdToClusterIdentifierIdMap = (sources: DataSource[]): Map<DataSourceId, ClusterIdentifierId> => {
  const out = new Map<DataSourceId, ClusterIdentifierId>();
  sources.forEach(({ id, cohesityParams }) =>
    cohesityParams ? out.set(id, getClusterIdentifier(cohesityParams)) : null
  );
  return out;
};

/**
 * Return the map of data source id to data source.
 *
 * @param sources The data sources.
 * @returns The map of data source id to data source.
 */
export const dataSourceIdToSourceMap = (sources: DataSource[]): Map<DataSourceId, DataSource> => {
  const out = new Map<DataSourceId, DataSource>();
  sources.forEach(source => out.set(source.id, source));
  return out;
};

/**
 * Return the map of data pool id to data source.
 *
 * @param sources The data sources.
 * @param dataPools The data pools.
 * @returns The map of data pool id to data source.
 */
export const dataPoolIdToSourceMap = (sources: DataSource[], dataPools: DataPool[]): Map<DataPoolId, DataSource> => {
  const sourceByIdMap = dataSourceIdToSourceMap(sources);
  const dataSourcesMap = new Map<DataPoolId, DataSource>();

  (dataPools || []).forEach(dataPool => {
    dataPool.clauses.forEach(clause => {
      const source = sourceByIdMap.get(clause.dataSourceId);
      if (source) {
        dataSourcesMap.set(dataPool.id, source);
      }
    });
  });

  return dataSourcesMap;
};

/**
 * Return the map of data pool id to shields.
 *
 * @param shields The shields.
 * @param dataPools The data pools.
 * @returns The map of data pool id to shields.
 */
export const dataPoolIdToShieldsMap = (shields: Shield[], dataPools: DataPool[]): Map<DataPoolId, Shield[]> => {
  const out = new Map<DataPoolId, Shield[]>();
  const shieldByTypeMap = new Map<ShieldType, Shield>();
  const dataPoolIdToShieldTypeSet = new Map<DataPoolId, Set<ShieldType>>();
  const enabledDataPoolIds = dataPools.filter(({ enabled }) => enabled).map(({ id }) => id);

  shields.forEach(shield => {
    let dataPoolIds = shield.dataPoolIds || [];

    shieldByTypeMap.set(shield.type, shield);

    if (shield.type === ShieldType.SMARTFILES) {
      dataPoolIds = dataPoolIds.concat(enabledDataPoolIds);
    }

    dataPoolIds.forEach(dataPoolId => {
      const shieldSet = dataPoolIdToShieldTypeSet.get(dataPoolId) || new Set<ShieldType>();

      shieldSet.add(shield.type);
      dataPoolIdToShieldTypeSet.set(dataPoolId, shieldSet);
    });
  });

  dataPoolIdToShieldTypeSet.forEach((shieldSet, dataPoolId) => {
    out.set(dataPoolId, shields.filter(shield => shieldSet.has(shield.type)));
  });

  return out;
};

/**
 * Return the map of data pool id to policies.
 *
 * @param policies The policies.
 * @returns The map of data pool id to policies.
 */
export const dataPoolIdToPoliciesMap = (policies: Policy[]): Map<DataPoolId, Policy[]> => {
  const out = new Map<DataPoolId, Policy[]>();
  const policyByIdMap = new Map<PolicyId, Policy>();
  const dataPoolIdToPolicyIdSet = new Map<DataPoolId, Set<PolicyId>>();

  policies.forEach(policy => {
    policyByIdMap.set(policy.id, policy);

    (policy.dataPoolIds || []).forEach(dataPoolId => {
      const policySet = dataPoolIdToPolicyIdSet.get(dataPoolId) || new Set<PolicyId>();

      policySet.add(policy.id);
      dataPoolIdToPolicyIdSet.set(dataPoolId, policySet);
    });
  });

  dataPoolIdToPolicyIdSet.forEach((policySet, dataPoolId) => {
    out.set(dataPoolId, policies.filter(policy => policySet.has(policy.id)));
  });

  return out;
};

/**
 * Return the map of cluster identifiers to data source.
 *
 * @param sources The data source.
 * @returns The map of cluster identifiers to data source.
 */
export const clusterIdentifierIdToDataSourceMap = (sources: DataSource[]): Map<ClusterIdentifierId, DataSource> => {
  const out = new Map<ClusterIdentifierId, DataSource>();
  sources.forEach(source =>
    source.cohesityParams ? out.set(getClusterIdentifier(source.cohesityParams), source) : null
  );
  return out;
};


/**
 * Returns map form data pool id to view uuid
 *
 * @param dataPools list of dataPools
 * @param sources list of sources
 * @returns map from data pool id to view uuid
 */
export const dataPoolIdToViewUuidMap = (dataPools: DataPool[], sources: DataSource[]): Map<DataPoolId, ViewUuid> => {
  const dataPoolIdToViewUuid = new Map<DataPoolId, ViewUuid>();
  const sourceIdToClusterIdentifierId = sourceIdToClusterIdentifierIdMap(sources);

  dataPools.forEach(dataPool => {
    dataPool.clauses.forEach(clause => {
      const { viewUuid } = getViewDetailsFromClause(clause, sourceIdToClusterIdentifierId);
      dataPoolIdToViewUuid.set(dataPool.id, viewUuid);
    });
  }
  );
  return dataPoolIdToViewUuid;
};

/**
 * returns a map from viewUuid to views
 *
 * @param views list of views
 * @returns map from viewUuid to views
 */
export const viewUuidToViewMap = (views: View[]): Map<ViewUuid, View> => {
  const viewUuidToView = new Map<ViewUuid, View>();
  views.forEach(view => {
    const viewUuid = `${view.clusterIdentifierId}:${view.viewId}:${view.name}`;
    viewUuidToView.set(viewUuid, view);
  });
  return viewUuidToView;
};

/**
 * Return the map from dataPools to views
 *
 * @param views views
 * @param dataPools data pools for mapping purposes
 * @returns Map from data pool id to view
 */
export const dataPoolIdToViewMap = (
  views: View[],
  dataPools: DataPool[],
  sources: DataSource[],
): Map<DataPoolId, View> => {
  const dataPoolIdToViewUuid = dataPoolIdToViewUuidMap(dataPools, sources);
  const viewUuidToView = viewUuidToViewMap(views);
  const dataPoolIdToView = new Map<DataPoolId, View>();

  dataPools.forEach(dataPool => {
    const viewUuid = dataPoolIdToViewUuid.get(dataPool.id);
    const view = viewUuidToView.get(viewUuid);
    dataPoolIdToView.set(dataPool.id, view);
  });

  return dataPoolIdToView;
};

/**
 * Return a unique set of data pool ids for the provided shields.
 *
 * @param shields The list of shields.
 * @returns The data pools ids.
 */
export const getShieldDataPoolIdSet = (shields: Shield[]): Set<DataPoolId> => {
  const dataPoolIds = new Set<DataPoolId>();
  (shields || []).forEach(shield => (shield.dataPoolIds || []).forEach(dataPoolIds.add, dataPoolIds));
  return dataPoolIds;
};

/**
 * Return the data pools grouped by data source id.
 *
 * @param dataPools The data pools.
 * @returns The data pools grouped by data source id.
 */
export const getDataPoolsByDataSourceIdMap = (dataPools: DataPool[]) => {
  const dataPoolsByDataSourceId = new Map<DataSourceId, DataPool[]>();

  dataPools.forEach(dataPool => {
    const dataSourceId = dataPool.clauses[0].dataSourceId;

    dataPoolsByDataSourceId.set(
      dataSourceId,
      [].concat(...(dataPoolsByDataSourceId.get(dataSourceId) || []), dataPool)
    );
  });

  return dataPoolsByDataSourceId;
};

/**
 * Return the number of data pools.
 *
 * @param dataPools The data pools.
 * @returns The number of data pools.
 */
export const getDataPoolsCount = (dataPools: DataPool[]) => dataPools?.length || 0;

/**
 * Return the list of data pool ids.
 *
 * @param dataPools The data pools.
 * @returns The data pools ids.
 */
export const getDataPoolIds = (dataPools: DataPool[]) => (dataPools || []).map(({ id }) => id);

/**
 * Return the unique set of data pool ids.
 *
 * @param dataPools The data pools.
 * @returns The unique set of data pool ids.
 */
export const getDataPoolIdsSet = (dataPools: DataPool[]) => new Set<DataPoolId>(getDataPoolIds(dataPools));


/**
 * Return the data pools metadata summary.
 *
 * @param dataPools The list of data Pools.
 * @returns The data pools metadata.
 */
export const getDataPoolsMetadataSummary = (dataPools: DataPool[]): DataPoolsMetadataSummary => {
  const metadata: DataPoolsMetadataSummary = { totalDataSize: 0, totalObjects: 0 };

  dataPools.forEach(dataPool => {
    const { totalDataSize, totalObjects } = getDataPoolMetadata(dataPool);
    metadata.totalDataSize += totalDataSize;
    metadata.totalObjects += totalObjects;
  });

  return metadata;
};

/**
 * Return the data pool metadata having totalDataSize & totalObjects.
 *
 * @param dataPool The data pool.
 * @returns The data pool metadata.
 */
export const getDataPoolMetadata = (dataPool: DataPool): DataPoolsMetadataSummary => {
  const metadata: DataPoolsMetadataSummary = { totalDataSize: 0, totalObjects: 0 };

  (dataPool?.metadata?.keyValueStore || []).forEach(store => {
    if (store.key === 'logicalUsageBytes') {
      metadata.totalDataSize += (+store.value);
    }
    if (store.key === 'objectsCount') {
      metadata.totalObjects += (+store.value);
    }
  });

  return metadata;
};

/**
 * Return the map of data pool id to its metadata.
 *
 * @param dataPools The data pools.
 * @returns The map of data pool id to its metadata.
 */
export const getDataPoolsMetadataMap = (dataPools: DataPool[]): Map<DataPoolId, DataPoolsMetadataSummary> => {
  const out = new Map<DataPoolId, DataPoolsMetadataSummary>();
  dataPools.forEach(dataPool => out.set(dataPool.id, getDataPoolMetadata(dataPool)));
  return out;
};
