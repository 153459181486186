import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yesNo',
})
export class YesNoPipe implements PipeTransform {
  /**
   * Evaluates the value and returns true or false label string
   *
   * @method   transform
   * @param    expr                 The value which is to be evaluated
   * @param    [trueLabel='yes']    Label used  if value is true
   * @param    [falseLabel='no']    Label used if the the value is false
   * @return   String Label returned according to the value evaluated
   */
  transform(value: boolean, trueLabel: string = 'yes', falseLabel: string = 'no'): string {
    return value ? trueLabel : falseLabel;
  }
}
