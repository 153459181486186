import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, startWith } from 'rxjs/operators';
import { AcropolisDiskFilterType } from 'src/app/shared';

import {
  BaseProtectionBuilderComponent,
} from '../../../../protection-builder/components/base-protection-builder/base-protection-builder.component';

/**
 * Interface for Acropolis disk info.
 */
interface AcropolisDisk {
  controllerType: string;
  unitNumber: number;
}
/**
 * Interface for Acropolis disk filter.
 */
interface AcropolisDiskFilter {
  enableDiskFilter: boolean;
  type?: string;
  disks?: AcropolisDisk[];
}

/**
 * Default value for Acropolis disk filter.
 */
 export const defaultDiskFilter: AcropolisDiskFilter = {
  enableDiskFilter: false,
  type: AcropolisDiskFilterType.Include,
  disks: [],
};

@Component({
  selector: 'coh-settings-list-acropolis-disk-filter',
  templateUrl: './settings-list-acropolis-disk-filter.component.html',
  styleUrls: ['./settings-list-acropolis-disk-filter.component.scss'],
})
export class SettingsListAcropolisDiskFilterComponent extends BaseProtectionBuilderComponent<AcropolisDiskFilter, any>
  implements OnInit {

  /**
   * List of supported controller type
   */
  readonly controllerTypes = ['scsi', 'ide', 'pci', 'sata', 'spapr', 'nvme'];

  /**
   * Default protection type value.
   */
  _value: AcropolisDiskFilter = defaultDiskFilter;

  /**
   * Get the disks form control.
   *
   * @return   FormArray of disks controls.
   */
  get disksControl(): UntypedFormArray {
    return this.diskFilterFormGroup.get('disks') as UntypedFormArray;
  }

  /**
   * Disk exclusions form group for the parent adapter form.
   */
  diskFilterFormGroup = new UntypedFormGroup({
    enableDiskFilter: new UntypedFormControl(false, Validators.required),
    type: new UntypedFormControl(AcropolisDiskFilterType.Include),
    disks: new UntypedFormArray([]),
  });

  constructor(private translate: TranslateService) {
    super();
  }

  ngOnInit() {
    // Update validators for disks control
    this.diskFilterFormGroup.get('enableDiskFilter').valueChanges.pipe(
      startWith(this.value.enableDiskFilter),
      this.untilDestroy(),
    ).subscribe((isEnabled: boolean) => {
      if (!isEnabled) {
        this.diskFilterFormGroup.removeControl('disks');
      } else {
        this.diskFilterFormGroup.addControl('disks', new UntypedFormArray([], Validators.required));
      }
    });

    // Reset the include/exclude disk selection.
    this.diskFilterFormGroup.get('type').valueChanges.pipe(
      this.untilDestroy(),
      distinctUntilChanged(),
    ).subscribe(() => {
      this.diskFilterFormGroup.removeControl('disks');
      this.diskFilterFormGroup.addControl('disks', new UntypedFormArray([], Validators.required));
    });
  }

  /**
   * Attach internal form group to the parent component.
   */
  addFormControl() {
    this.formGroup.addControl(this.name, this.diskFilterFormGroup);
  }

  /**
   * Form control init.
   */
  initFormControl() {
    if (this.protectionGroup?.acropolisGlobalExcludeDisks || this.protectionGroup?.acropolisGlobalIncludeDisks) {
      let disks = [];
      if (this.protectionGroup.acropolisGlobalExcludeDisks) {
        disks = this.protectionGroup.acropolisGlobalExcludeDisks;
        this.value = {
          enableDiskFilter: true,
          type: AcropolisDiskFilterType.Exclude,
          disks,
        };
      } else if (this.protectionGroup.acropolisGlobalIncludeDisks) {
        disks = this.protectionGroup.acropolisGlobalIncludeDisks;
        this.value = {
          enableDiskFilter: true,
          type: AcropolisDiskFilterType.Include,
          disks,
        };
      }

      for (const disk of disks) {
        // Create a new form control item for each new existing disk.
        this.addDiskFormControl(disk);
      }

      this.formControl.patchValue(this.value);
    }
   }

  /**
   * Add a new disk to excluded disks.
   *
   * @param   value   default disk value.
   */
   addDiskFormControl(value: AcropolisDisk) {
    const {controllerType, unitNumber} = value || {};

    this.disksControl.push(
      new UntypedFormGroup({
        controllerType: new UntypedFormControl(controllerType, Validators.required),
        unitNumber: new UntypedFormControl(unitNumber, [Validators.required, Validators.min(0)])
      })
    );
  }

  /**
   * Remove a disk at an index.
   *
   * @param   index   index to remove the disk at.
   */
  removeDiskFormControl(index: number) {
    this.disksControl.removeAt(index);
  }

  /**
   * Returns list of disk addresses as string format.
   *
   * @param disks   List of disks to be filtered
   * @returns       Array of corresponding disk addresses.
   */
  getDiskAddress(disks: AcropolisDisk[] = []): string[] {
    return disks.map(disk => {
      const { controllerType, unitNumber } = disk;
      const type = this.translate.instant(`enum.controllerType.${controllerType}`);
      return `${type}.${unitNumber}`;
    });
  }
}
