import { Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { NavItem } from '@cohesity/helix';
import { isEqual } from 'lodash';

/**
 * A select/title dropdown for switching between items.
 *
 * @example
 *   <coh-title-selector></coh-title-selector>
 */
@Component({
  selector: 'coh-title-selector',
  templateUrl: './title-selector.component.html',
  styleUrls: ['./title-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TitleSelectorComponent {
  /**
   * Selector for key press event handling.
   */
  @ViewChild('selector') selector: ElementRef;

  /**
   * MatMenuTrigger for determining open status of menu.
   */
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

  /**
   * HTML id to use for automation.
   */
  @Input() titleId = 'title-selector-button';

  /**
   * List of dashboards to be displayed.
   */
  @Input() navItems: NavItem[] = [];

  /**
   * The currently selected item.
   */
  @Input() currentItem: NavItem;

  /**
   * Indicates if provided NavItem is the currently selected/active NavItem.
   *
   * @param item   The item for evaluation.
   */
  isItemActive(item: NavItem) {
    if (this.currentItem?.state) {
      return item?.state === this.currentItem?.state && (
        (!this.currentItem.stateParams && !item.stateParams) ||
        isEqual(this.currentItem.stateParams, item?.stateParams)
      );
    }
    return item.displayName === this.currentItem?.displayName;
  }

  /**
   * Indicates if the select component is enabled. Otherwise the component simply
   * displays a title for the dashboard.
   */
  isSelectEnabled(): boolean {
    return this.navItems.length > 1;
  }

  /**
   * Handle the click of an item.
   *
   * @param item   the item thats been clicked.
   */
  handleClick(item: NavItem) {
    if (typeof item.action === 'function') {
      item.action();
      this.menuTrigger.closeMenu();
    }
  }

  /**
   * Handle Enter key press on selector, same as mouse click.
   */
  onEnterKeyPress() {
    this.selector.nativeElement.click();
  }
}
