import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from '../icon/icon.module';
import { SlicerComponent } from './slicer.component';


@NgModule({
  imports: [
    CommonModule,
    IconModule,
  ],
  declarations: [SlicerComponent],
  exports: [SlicerComponent],
})
export class SlicerModule { }
