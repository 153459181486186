<coh-form-field-object-selector
  cogDataId="entities-of-type"
  [control]="control"
  [label]="label"
  [loading]="loading"
  [optionTemplate]="optionTemplate"
  [required]="required"
  [values]="values$ | async"
  optionKey="displayName"
  [inheritDataId]="true">
</coh-form-field-object-selector>
