// Jobs Inventory and Schedule

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.reports')
    .controller('reportsJobsInventoryController',
      reportsJobsInventoryControllerFn);

  function reportsJobsInventoryControllerFn($rootScope, $scope, $state, $q,
    $stateParams, $filter, ReportsService, evalAJAX, cUtils, SourceService,
    SourceData, JobService, ENUM_ENV_TYPE, cReportsControlsUtil, ReportsUtil) {

    $scope.jobs = [];
    $scope.text = $rootScope.text.reportsJobsInventoryInventory;
    $scope.cUtils = cUtils;
    $scope.SourceService = SourceService;
    $scope.ENUM_ENV_TYPE = ENUM_ENV_TYPE;

    $scope.downloadReport = downloadReport;
    $scope.getJobData = getJobData;

    $scope.dataReady = false;

    $scope.inventoryAndScheduleConfig =
      ReportsUtil.getReportConfig('kProtectionJobsInventoryAndScheduleReport')();

    /**
     * activate this controller
     */
    function activate() {
      getJobData(cReportsControlsUtil.getDefaultFilters());
    }

    /**
    * Configuration Array for custom sorting cSelect list
    * @type {Array}
    */
    $scope.sortOptions = [
      {
        value: 'backupJob.name',
        name: $scope.text.jobs.inventory.sortByName
      },
      {
        value: 'backupJob.parentSource.displayName',
        name: $scope.text.jobs.inventory.sortBySource
      }
    ];

    /**
    * Custom sort method for c-table-nested
    * @param  {string} prop the property on which to be sorted
    */
    $scope.sortJobs = function sortJobs(prop) {
      $scope.jobs = $filter('orderBy')($scope.jobs, prop);
    };


    /**
     * returns params object for api call
     *
     * @param      {object}    filters    filters from c-reports-controls
     *
     * @return    {object}               filter values to be passed to API
     */
    function getParams(filters) {
      var params = {
        jobIds: filters.jobId,
      };

      // only objectName
      if (filters.vmName) {
        params.objectNames = [filters.vmName];
      }

      return ReportsService.extendWithTenantParams(params);
    }

    /**
    * requests a csv download via JobRunsService
    */
    function downloadReport() {
      var params = getParams(cReportsControlsUtil.filters);
      params.outputFormat = 'csv';

      ReportsService.getJobInventory(params);
    }

    /**
    * requests and process the data for the chart
    *
    * @param      {object}    filters    filters from c-reports-controls
    */
    function getJobData(filters) {

      var params = getParams(filters);
      var i = 0;

      $scope.dataReady = false;
      $scope.jobs = [];

      ReportsService.getJobInventory(params).then(
        function getJobInvSuccess(jobs) {
          $scope.jobs = jobs.map(function mapJobsFn(job, i) {

            job.leafSources = job.leafSources || [];
            job.leafSourcesDisplay = job.leafSources;

            job._ends = JobService.getEndString(job.backupJob);
            job._repeats = job._policy ?
              [
                JobService.getRepeatString(
                  job._policy.incrementalSchedulingPolicy, job.backupJob),
                job.backupJob.timezone || ''
              ].join(' ') :
              undefined;

            job._sourceAvailable =
              isSourceAvailable(job.backupJob.parentSource);

            return job;
          });
        }, evalAJAX.errorMessage)
      .finally(function getJobInvFinally() {
        $scope.dataReady = true;
      });
    }

    /**
     * checks if source is available
     *
     * @param     {object    source    source object
     *
     * @return    {Boolean}
     */
    function isSourceAvailable(source) {
      return SourceService.parentSourceIds.includes(source.id);
    }

    /**
     * go to files report
     *
     * @param     {object}    vm     vm object
     * @param     {object}    job    job object
     */
    $scope.getVM = function getVM(vm, job) {
      SourceData.object = angular.extend({}, vm.source);

      $state.go('reports.files-category', {
        jobId: job.backupJob.jobId,
        objId: vm.source.id,
        vmName: SourceService.getEntityName(vm.source)
      });
    };

    // run setup function on first load
    activate();
  }
})(angular);
