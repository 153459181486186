<div [formGroup]="formGroup" class="item-container">
  <div class="key-wrapper item-col1">
    <coh-form-field-object-selector
      cogDataId="property-selector"
      [control]="formGroupControls.key"
      [label]="'keys' | translate"
      [optionTemplate]="optionTemplate"
      [allowSearch]="false"
      [triggerTemplate]="optionTemplate"
      optionKey="id"
      [values]="propertyKeys">
   </coh-form-field-object-selector>
    <!-- Template for dropdown -->
    <ng-template #optionTemplate let-value>
      {{ value }}
    </ng-template>
  </div>
  <div class="value-wrapper item-col2">
    <mat-form-field>
      <input
        matInput
        [formControl]="formGroupControls.value"
        [placeholder]="'addJson' | translate">
    </mat-form-field>
  </div>
  <div class="action-wrapper">
    <button mat-icon-button
      type="button"
      cogDataId="remove-prop"
      (click)="removeProperty()">
      <cog-icon shape="remove_circle_outline"></cog-icon>
    </button>
    <button mat-icon-button
      type="button"
      cogDataId="add-prop"
      (click)="addProperty()"
      [disabled]="allowAddingProperty()">
      <cog-icon shape="add_circle_outline"></cog-icon>
    </button>
  </div>
</div>
