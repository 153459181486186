<h3>{{ 'recoveryOptions' | translate }}</h3>
<div class="c-box-meta margin-bottom-lg">
  <ul class="c-ul">
    <li *ngIf="recoverJobOptions.mounts">
      <strong>{{ 'mounts' | translate }}:</strong>
      {{ recoverJobOptions.mounts }}
    </li>
    <li *ngIf="recoverJobOptions.concurrency">
      <strong>{{ 'concurrencyColon' | translate }}</strong>
      {{ recoverJobOptions.concurrency }}
    </li>
    <li *ngIf="recoverJobOptions.startDatabase">
      <strong>{{ 'startDatabase' | translate }}</strong>
      {{ recoverJobOptions.startDatabase }}
    </li>
    <li *ngIf="recoverJobOptions.bandwidthMBPS">
      <strong>{{ 'bandwidthColon' | translate }}</strong>
      {{ recoverJobOptions.bandwidthMBPS }} {{ 'mbps' | translate }}
    </li>
    <li *ngIf="recoverJobOptions.overwrite">
      <strong>{{ 'overwriteColon' | translate }}</strong>
      {{ recoverJobOptions.overwrite }}
    </li>
    <li *ngIf="recoverJobOptions.targetDirectory">
      <strong>{{ 'targetDirectoryColon' | translate }}</strong>
      {{ recoverJobOptions.targetDirectory }}
    </li>
    <li *ngIf="recoverJobOptions.suffix">
      <strong>{{ 'suffixColon' | translate }}</strong>
      {{ recoverJobOptions.suffix }}
    </li>
    <li *ngIf="recoverJobOptions.selectedDataCenters">
      <strong>{{ 'selectedDataCentersColon' | translate }}</strong>
      {{ recoverJobOptions.selectedDataCenters }}
    </li>
    <li *ngIf="recoverJobOptions.logRestoreDirectory">
      <strong>{{ 'logRestoreDirectoryColon' | translate }}</strong>
      {{ recoverJobOptions.logRestoreDirectory }}
    </li>
    <li *ngIf="recoverJobOptions.appendDocuments">
      <strong>{{ 'appendDocumentsColon' | translate }}</strong>
      {{ recoverJobOptions.appendDocuments }}
    </li>
    <li *ngIf="recoverJobOptions.ddlOnlyRecovery">
      <strong>{{ 'ddlRestore' | translate }}:</strong>
      {{ recoverJobOptions.ddlOnlyRecovery }}
    </li>
    <li *ngIf="recoverJobOptions.overwriteUsers">
      <strong>{{ 'overwriteUsers' | translate }}:</strong>
      {{ recoverJobOptions.overwriteUsers }}
    </li>
    <li *ngIf="recoverJobOptions.recoveryArgs">
      <strong>{{ 'customOptions' | translate }}:</strong>
      {{ recoverJobOptions.recoveryArgs }}
    </li>
    <ng-container *ngIf="!isDmsScope">
      <li *ngFor="let arg of (customArguments$ | async)">
        <strong>{{ arg.label }}:</strong>
        {{ arg.value }}
      </li>
    </ng-container>
  </ul>
</div>
