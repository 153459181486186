/**
 * Recovery OS Type enum (should match GET recoveries API).
 */
export enum RecoveryOsType {
  kLinux = 'kLinux',
  kWindows = 'kWindows',
  kAix = 'kAix',
  kSolaris = 'kSolaris',
  kSapHana = 'kSapHana',
  kOther = 'kOther',
}
