import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GcpDiskExclusionParams } from '@cohesity/api/v2';
import { AntlrSuggester, GetSuggestionFn, maybeEnquote, Result, TranslationResultFn } from '@cohesity/helix';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  BaseProtectionBuilderComponent
} from '../../../../protection-builder/components/base-protection-builder/base-protection-builder.component';
import { GcpExclusionKeys, GcpGrammarOpts } from './gcp-grammar-opts/gcp-grammar-opts';

/**
 * The list of all possible values for key attribute.
 */
export const gcpKeys: Result[] =
 Object.values(GcpExclusionKeys).map(value => ({ label: null, value: maybeEnquote(value) }));

@Component({
  selector: 'coh-settings-list-gcp-volume-exclusion',
  templateUrl: './settings-list-gcp-volume-exclusion.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class SettingsListGcpVolumeExclusionComponent extends
  BaseProtectionBuilderComponent<GcpDiskExclusionParams, any> {

  /**
   * The raw query grammar options for advanced search component.
   */
  readonly gcpGrammarOpts = new GcpGrammarOpts();

  /**
   * The suggester class used to get suggestions for value formats based on grammar.
   */
  readonly antlrSuggester = new AntlrSuggester(this.gcpGrammarOpts);

  /**
   * Form Group for Disk Exclusions.
   */
  diskExclusionFormGroup = new UntypedFormGroup({
    enableExcludeDisk: new UntypedFormControl(false),
    rawQuery: new UntypedFormControl(''),
    enableExcludeVmWithNoDisk: new UntypedFormControl(false)
  });

  /**
   * Get Enable exlcude disk form control.
   */
  get enableExcludeDiskControl(): UntypedFormControl {
    return this.diskExclusionFormGroup?.get('enableExcludeDisk') as UntypedFormControl;
  }

  /**
   * Get Raw Query Form Control.
   */
  get rawQueryControl(): UntypedFormControl {
    return this.diskExclusionFormGroup?.get('rawQuery') as UntypedFormControl;
  }

  /**
   * Get Exclude Vm with no Disk Form Control.
   */
  get excludeVmWithNoDiskControl(): UntypedFormControl {
    return this.diskExclusionFormGroup?.get('excludeVmWithNoDiskEnabled') as UntypedFormControl;
  }

  constructor(
    private translateService: TranslateService,
    public cdr: ChangeDetectorRef
  ) {
    super();
  }

  /**
   * Function to add to form group.
   */
  addFormControl() {
    this.formGroup.addControl(this.name, this.diskExclusionFormGroup);
  }

  /**
   * Initialize the form control.
   */
  initFormControl() {
    const { rawQuery, excludeVmWithNoDisk } = this.protectionGroup?.gcpDiskExclusions ?? {};

    this.diskExclusionFormGroup.setValue({
      enableExcludeDisk: typeof rawQuery === 'string',
      rawQuery: rawQuery ?? '',
      enableExcludeVmWithNoDisk: excludeVmWithNoDisk ?? false,
    });
  }

  /**
   * Fetch the suggested values for a given suggestion by advance search component.
   *
   * @param  suggestion The suggestion from advance search.
   * @return            Observable stream of updated suggestion with values.
   */
  getSuggestions: GetSuggestionFn = (suggestion) => {
    switch (true) {
      // return keys.
      case suggestion.field === 'key': {
        return of({ ...suggestion, resultValues: gcpKeys }).pipe(take(1));
      }

      default: {
        return of(suggestion).pipe(take(1));
      }
    }
  };

  /**
   * Return the transaltion for a given input. Used by the advanced search component
   * to translate value formats.
   * Must be an arrow function to bind the context.
   *
   * @param  result The result object whose label needs to be translated.
   * @return        The translated text.
   */
  translationResult: TranslationResultFn = (result: Result): string =>
   this.translateService.instant(`gcp.token.${result.label}`);
}
