/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GetTagsResult } from '../models/get-tags-result';
import { Tag } from '../models/tag';
@Injectable({
  providedIn: 'root',
})
class TagService extends __BaseService {
  static readonly GetTagsPath = '/tags';
  static readonly CreateTagPath = '/tags';
  static readonly GetTagByIdPath = '/tags/{id}';
  static readonly UpdateTagPath = '/tags/{id}';
  static readonly DeleteTagPath = '/tags/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get tags based on filters.
   *
   * If no parameters are specified, all tags are returned.
   * Specifying parameters filters the results that are returned.
   * @param params The `TagService.GetTagsParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which tags are to be
   *   returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `namespaces`: Filter by a list of Namespaces.
   *
   * - `names`: Filter by a list of Tag names.
   *
   * - `includeTenants`: IncludeTenants specifies if tags of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *   False, by default.
   *
   * - `includeMarkedForDeletion`: Specifies if tags marked for deletion should be shown. These are
   *   tags which are undergoing deletion. False, by default.
   *
   * - `ids`: Filter by a list of Tag Ids. If Ids are mentioned all other fields
   *   will be ignored.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetTagsResponse(params: TagService.GetTagsParams): __Observable<__StrictHttpResponse<GetTagsResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.namespaces || []).forEach(val => {if (val != null) __params = __params.append('namespaces', val.toString())});
    (params.names || []).forEach(val => {if (val != null) __params = __params.append('names', val.toString())});
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.includeMarkedForDeletion != null) __params = __params.set('includeMarkedForDeletion', params.includeMarkedForDeletion.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/tags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetTagsResult>;
      })
    );
  }
  /**
   * Get tags based on filters.
   *
   * If no parameters are specified, all tags are returned.
   * Specifying parameters filters the results that are returned.
   * @param params The `TagService.GetTagsParams` containing the following parameters:
   *
   * - `tenantIds`: TenantIds contains ids of the tenants for which tags are to be
   *   returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `namespaces`: Filter by a list of Namespaces.
   *
   * - `names`: Filter by a list of Tag names.
   *
   * - `includeTenants`: IncludeTenants specifies if tags of all the tenants under the
   *   hierarchy of the logged in user's organization should be returned.
   *   False, by default.
   *
   * - `includeMarkedForDeletion`: Specifies if tags marked for deletion should be shown. These are
   *   tags which are undergoing deletion. False, by default.
   *
   * - `ids`: Filter by a list of Tag Ids. If Ids are mentioned all other fields
   *   will be ignored.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetTags(params: TagService.GetTagsParams): __Observable<GetTagsResult> {
    return this.GetTagsResponse(params).pipe(
      __map(_r => _r.body as GetTagsResult)
    );
  }

  /**
   * Create a Tag
   *
   * Creates a Tag.
   * @param params The `TagService.CreateTagParams` containing the following parameters:
   *
   * - `body`: Request to create a Tag.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateTagResponse(params: TagService.CreateTagParams): __Observable<__StrictHttpResponse<Tag>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/tags`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Tag>;
      })
    );
  }
  /**
   * Create a Tag
   *
   * Creates a Tag.
   * @param params The `TagService.CreateTagParams` containing the following parameters:
   *
   * - `body`: Request to create a Tag.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateTag(params: TagService.CreateTagParams): __Observable<Tag> {
    return this.CreateTagResponse(params).pipe(
      __map(_r => _r.body as Tag)
    );
  }

  /**
   * Get Tag by id.
   *
   * Get Tag by id.
   * @param params The `TagService.GetTagByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the Id of the tag.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetTagByIdResponse(params: TagService.GetTagByIdParams): __Observable<__StrictHttpResponse<Tag>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/tags/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Tag>;
      })
    );
  }
  /**
   * Get Tag by id.
   *
   * Get Tag by id.
   * @param params The `TagService.GetTagByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the Id of the tag.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetTagById(params: TagService.GetTagByIdParams): __Observable<Tag> {
    return this.GetTagByIdResponse(params).pipe(
      __map(_r => _r.body as Tag)
    );
  }

  /**
   * Update a Tag
   *
   * Updates a Tag by id.
   * @param params The `TagService.UpdateTagParams` containing the following parameters:
   *
   * - `id`: Specifies the Id of the tag.
   *
   * - `body`: Request to update a tag.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateTagResponse(params: TagService.UpdateTagParams): __Observable<__StrictHttpResponse<Tag>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/tags/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Tag>;
      })
    );
  }
  /**
   * Update a Tag
   *
   * Updates a Tag by id.
   * @param params The `TagService.UpdateTagParams` containing the following parameters:
   *
   * - `id`: Specifies the Id of the tag.
   *
   * - `body`: Request to update a tag.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateTag(params: TagService.UpdateTagParams): __Observable<Tag> {
    return this.UpdateTagResponse(params).pipe(
      __map(_r => _r.body as Tag)
    );
  }

  /**
   * Delete a Tag
   *
   * Deletes a Tag by id.
   * @param params The `TagService.DeleteTagParams` containing the following parameters:
   *
   * - `id`: Specifies the Id of the tag.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteTagResponse(params: TagService.DeleteTagParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/tags/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a Tag
   *
   * Deletes a Tag by id.
   * @param params The `TagService.DeleteTagParams` containing the following parameters:
   *
   * - `id`: Specifies the Id of the tag.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteTag(params: TagService.DeleteTagParams): __Observable<null> {
    return this.DeleteTagResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module TagService {

  /**
   * Parameters for GetTags
   */
  export interface GetTagsParams {

    /**
     * TenantIds contains ids of the tenants for which tags are to be
     * returned.
     */
    tenantIds?: Array<string>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Filter by a list of Namespaces.
     */
    namespaces?: Array<string>;

    /**
     * Filter by a list of Tag names.
     */
    names?: Array<string>;

    /**
     * IncludeTenants specifies if tags of all the tenants under the
     * hierarchy of the logged in user's organization should be returned.
     * False, by default.
     */
    includeTenants?: boolean;

    /**
     * Specifies if tags marked for deletion should be shown. These are
     * tags which are undergoing deletion. False, by default.
     */
    includeMarkedForDeletion?: boolean;

    /**
     * Filter by a list of Tag Ids. If Ids are mentioned all other fields
     * will be ignored.
     */
    ids?: Array<string>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateTag
   */
  export interface CreateTagParams {

    /**
     * Request to create a Tag.
     */
    body: Tag;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetTagById
   */
  export interface GetTagByIdParams {

    /**
     * Specifies the Id of the tag.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateTag
   */
  export interface UpdateTagParams {

    /**
     * Specifies the Id of the tag.
     */
    id: string;

    /**
     * Request to update a tag.
     */
    body: Tag;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteTag
   */
  export interface DeleteTagParams {

    /**
     * Specifies the Id of the tag.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { TagService }
