// Component: Providers list

;(function(angular, undefined) {
  'use strict';

  angular.module('C.antivirus')
    .component('providerList', {
      templateUrl: 'app/antivirus/providers/list/list.html',
      controller: providerListCtrlFn,
      bindings: {
        // @type   {object}   list of providers
        providers: '<',

        // @type   {boolean}   Whether to show loading animation
        loading: '<',
      },
    });

  /**
   * @ngdoc component
   * @name C.antivirus:providerList
   * @function providerListCtrlFn
   *
   * @description
   * Provides list of providers and related functionality.
   *
   * @example
     <provider-list
       providers="$ctrl.providers"
       loading="$ctrl.loading">
     </provider-list>
   */
  function providerListCtrlFn() {
    var $ctrl = this;
  }

})(angular);
