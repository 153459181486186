/**
 * Specifies the type of backup for an Office365 User entity(kUser).
 *
 * Although Magneto is capable of backing up both Mailbox & Onedrive for a given user, but this segregation is required
 * so as to not run into scale issues & increased backup times. Hence, only one of listed backup types is allowed
 * within an Office365 Protection Job. This may converge in future.
 */
export enum Office365BackupType {
  kMailbox = 'kMailbox',
  kOneDrive = 'kOneDrive',
  kSharePoint = 'kSharePoint',
  kPublicFolders = 'kPublicFolders',
  kTeams = 'kTeams',
  kGroups = 'kGroups',
  kMailboxCSM = 'kMailboxCSM',
  kOneDriveCSM = 'kOneDriveCSM',
  kSharePointCSM = 'kSharePointCSM'
}

/**
 * Specifies the type of container entities for Office365. Refer teh above entity hierarchy diagram for details.
 */

export enum Office365LeafNodeType {
  /**
   * Specifies the leaf entity within Magneto's EH for an Office365 user.
   */
  kUser = 'kUser',

  /**
   * Specifies the leaf entity within Magneto's EH for an Office365 group.
   */
  kGroup = 'kGroup',

  /**
   * Specifies the leaf entity within Magneto's EH for an Office365 Sharepoint site.
   */
  kSite = 'kSite',

  /**
   * Specifies the leaf entity within Magneto's EH for an Office365 Public Folder.
   */
  kPublicFolder = 'kPublicFolder',

  /**
   * Specifies the leaf entity within Magneto's EH for an MS Team.
   */
  kTeam = 'kTeam',

  /**
   * Specifies the leaf entity within global_entity index in ES for an Office365 Exchange.
   * Magneto's leaf entity is still kUser.
   */
  kO365Exchange = 'kO365Exchange',

  /**
   * Specifies the leaf entity within global_entity index in ES for an Office365 Sharepoint.
   * Magneto's leaf entity is still kUser.
   */
  kO365Sharepoint = 'kO365Sharepoint',

  /**
   * Specifies the leaf entity within global_entity index in ES for an Office365 One Drive.
   * Magneto's leaf entity is still kUser.
   */
  kO365OneDrive = 'kO365OneDrive',
}

/**
 * M365 Object Types used in reporting filter.
 */
export const reportingM365ObjectTypes = [
  Office365LeafNodeType.kO365Exchange,
  Office365LeafNodeType.kO365OneDrive,
  Office365LeafNodeType.kSite,
];
