// Controller: VLAN view, Create & Edit details

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.vlan')
    .controller('vlanDetailCtrl', vlanDetailCtrlFn);

  // pageConfig is passed from state or model resolver and $uibModalInstance
  // will be mocked in case of page view
  function vlanDetailCtrlFn(_, $rootScope, pageConfig, $state, $q, VlanService,
    NetworkService, evalAJAX, FORMATS, NETWORK, $uibModalInstance, $translate,
    StateManagementService, cUtils, UserService, IP_FAMILY, ROUTING) {

    var $ctrl = this;

    var defaultVlan = {
      addToClusterPartition: true,
      id: undefined,
      gateway: undefined,
      subnet: {
        netmaskIp4: undefined,
        _CIDR: undefined,
      },
      ifaceGroupName: undefined,
      ips: [],
    };

    _.assign($ctrl, {
      FORMATS: FORMATS,
      IP_FAMILY: IP_FAMILY,
      ROUTING: ROUTING,
      selectedIpFamily: IP_FAMILY.IPv4,
      selectedRouting: ROUTING.GLOBAL,
      cancel: cancel,
      createVlan: createVlan,
      interfaces: [],
      protocols: ['IPv4', 'IPv6'],
      internetProtocol : {
        selected: 'IPv6'
      },
      loadingInterfaces: false,
      vlanReady: false,
      showEnableForAllTenantsOption: false,
      selectedVips: [],
      defaultIfaceGroupName: '',
      isIpV6Supported: NetworkService.isIpV6Supported(),

      $onInit: $onInit,
    });

    /**
     * Get the interfaces list
     *
     * @method    getInterfaces
     */
    function _getInterfaces() {
      $ctrl.loadingInterfaces = true;
      NetworkService.getInterfaces(['bond'])
        .then(function gotInterfaces(interfaces) {
          $ctrl.interfaces = interfaces.filter(function eachInterface(item) {
            return _.get(item, 'ifaceGroup.length');
          });
        }, evalAJAX.errorMessage)
        .finally(function getInterfaceGroupsFinally() {
          if (!$ctrl.interfaceName) {
            var selectedInterface = _getDefaultSelectedInterface();
            $ctrl.interfaceName = selectedInterface.ifaceGroup;
          }
          $ctrl.loadingInterfaces = false;
        });
    }

    /**
     * Gets the vlan.
     *
     * @method   getVlan
     * @param    {string}   id   The identifier
     */
    function getVlan(id) {
      if ($ctrl.mode === 'new') {
        return $q.resolve(defaultVlan);
      }

      return VlanService.getVlan(id);
    }

    /**
     * cancels the view/edit/create
     *
     * @method     cancel
     */
    function cancel() {
      if ($ctrl.inModal) {
        return $uibModalInstance.dismiss();
      }

      StateManagementService.goToPreviousState('networking.vlans');
    }

    /**
     * Return the default selected interface group object.
     *
     * @method    _getDefaultSelectedInterface
     * @return  {Object}   The selected Interface.
     */
     function _getDefaultSelectedInterface() {
      var out;

      // select the default interface group if found in the list of interfaces.
      if ($ctrl.defaultIfaceGroupName) {
        out = _.find(
          $ctrl.interfaces, ['ifaceGroup', $ctrl.defaultIfaceGroupName]);
      }

      // by default select the 1st interfaces group.
      return out || $ctrl.interfaces[0];
    }

    /**
     * if form is valid, submits a request to create/update vlan
     *
     * @method     createVlan
     */
    function createVlan() {
      var cidr;
      if ($ctrl.frmVlan.$invalid) {
        return;
      }

      var data = _.assign($ctrl.vlan, {
        ips: $ctrl.selectedVips,
        id: Number($ctrl.vlan.id),
      });

      data.ipFamily = $ctrl.selectedIpFamily;

      if ($ctrl.selectedRouting == ROUTING.GATEWAY) {
        // subnetV6 field is used for ipv6 and subnet is used for ipv4.
        if (data.subnetV6 && data.subnetV6._CIDR) {
          cidr = cUtils.cidrToIpBits(data.subnetV6._CIDR);
          data.subnetV6.ip = cidr[0];
          data.subnetV6.netmaskBits = cidr[1];
        }

        if (data.subnet && data.subnet._CIDR) {
          cidr = cUtils.cidrToIpBits(data.subnet._CIDR);
          data.subnet.ip = cidr[0];
          data.subnet.netmaskBits = cidr[1];
        }

        // To avoid error that netmask and netmask bits both cannot be specified.
        if (data.subnet) {
          data.subnet.netmaskIp4 = undefined;
        }

        // Remove empty gateway
        if (_.isEmpty(data.gateway)) {
          data.gateway = undefined;
        }
        // Remove empty gateway
        if (_.isEmpty(data.gatewayV6)) {
          data.gatewayV6 = undefined;
        }
      } else {
        data.gateway = undefined;
        data.gatewayV6 = undefined;
        // To avoid error that netmask and netmask bits both cannot be specified.
        if (data.subnet) {
          data.subnet.netmaskIp4 = undefined;
        }
      }

      data.ifaceGroupName = $ctrl.interfaceName + '.' + data.id;
      if ($ctrl.mode == 'new') {
        _createVlan(data);
      } else {
        _updateVlan(data);
      }
    }


    /**
     * Create new vlan.
     * It checks if vlan already exists before submitting.
     * This check is added since we do not have POST API which is preferred way
     * to deal with it.
     *
     * @method  _createVlan
     * @param   {object}   data   Data to send to api
     */
    function _createVlan(data) {
      $ctrl.submitting = true;

      VlanService.getVlan(data.ifaceGroupName)
        .then(function vlanExist(vlan) {

          // If vlan already exists, fail
          // Reject message mimics server error response
          return $q.reject({ data: {
            message: $translate.instant('vlanExists',
              { ifaceName: data.ifaceGroupName })
          }});

        }, function vlanNotFound() {
          // Existing vlan not found, create new one
          return VlanService.createVlan(data);

        })
        .then(_createVlanSuccess, evalAJAX.errorMessage)
        .finally(function() {
          $ctrl.submitting = false;
        });
    }

    /**
     * Update existing vlan
     *
     * @method  _updateVlan
     * @param   {object}   data   Data to send to api
     */
    function _updateVlan(data) {
      $ctrl.submitting = true;
      VlanService.updateVlan(data)
        .then(_createVlanSuccess, evalAJAX.errorMessage)
        .finally(function() {
          $ctrl.submitting = false;
        });
    }

    /**
     * Handles success respose for create/update vlan
     *
     * @param    {object}   response   http response
     */
    function _createVlanSuccess(response) {
      // Creating vlan in modal view
      if ($ctrl.inModal) {
        $uibModalInstance.close(response);
        return;
      }

      $state.go('networking.vlans');
    }

    /**
     * initialize the controller
     *
     * @method   $onInit
     */
    function $onInit() {
      $ctrl.inModal = typeof $uibModalInstance === 'object';
      $ctrl.mode = pageConfig.mode;

      if (!pageConfig.id && pageConfig.mode !== 'new') {
        // Invalid URL pattern, so go back to vlan index.
        return $state.go('networking.vlans');
      }

      $ctrl.vlanReady = false;
      getVlan(pageConfig.id).then(
        function getVlanSuccess(vlan) {
          $ctrl.vlanReady = true;
          $ctrl.vlan = vlan;
          $ctrl.selectedVips = _.assign(
              $ctrl.selectedVips, $ctrl.vlan.ips);
          $ctrl.interfaceName = vlan.interfaceName || (
            vlan.ifaceGroupName ?
              vlan.ifaceGroupName.split('.')[0] :
              vlan.ifaceGroupName
          );
          if (vlan.gateway){
            $ctrl.selectedRouting = ROUTING.GATEWAY;
            $ctrl.selectedIpFamily = IP_FAMILY.IPv4;
          } else if (vlan.gatewayV6) {
            $ctrl.selectedRouting = ROUTING.GATEWAY;
            $ctrl.selectedIpFamily = IP_FAMILY.IPv6;
          } else {
            $ctrl.selectedRouting = ROUTING.GLOBAL;
          }

          // Show/hide the enableForTenants option.
          $ctrl.showEnableForAllTenantsOption =
            $rootScope.clusterInfo.multiTenancyEnabled &&
            !UserService.isTenantUser() && !vlan.tenantId;

          // Fetch interfaces
          _getInterfaces();
        }, evalAJAX.errorMessage);
    }
  }
})(angular);
