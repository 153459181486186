<mat-form-field [class.mat-form-field-invalid]="ngControl.dirty && ngControl.invalid">
  <mat-label translate="replicationTarget"></mat-label>
  <mat-select panelClass="cog-mat-select-panel-lg"
    id="{{id}}-select"
    cogDataId="select"
    [inheritDataId]="true"
    [(value)]="selectedTarget"
    (openedChange)="openedChange($event)">

    <!-- Search filter -->
    <mat-option *ngIf="allowSearch" [inheritDataId]="true" cogDataId="option-search">
      <ngx-mat-select-search
        [formControl]="searchCtrl"
        [noEntriesFoundLabel]="'noMatchingItems' | translate"
        [placeholderLabel]="'search' | translate">
      </ngx-mat-select-search>
    </mat-option>

    <mat-option *ngFor="let cluster of filteredClusters$ | async"
      [value]="cluster._target"
      id="{{id}}-option-{{cluster.clusterName}}"
      cogDataId="option-{{cluster.clusterName}}"
      [inheritDataId]="true"
      [disabled]="viewBoxId && !cluster._hasCompatibleViewBoxPairing">
      {{cluster.clusterName}}
    </mat-option>
    <mat-option #add
      id="{{id}}-register-remote-cluster"
      cogDataId="register-remote-cluster"
      [inheritDataId]="true"
      (click)="!add.disabled && registerNewCluster()"
      [disabled]="addNewEnable">
      <coh-add-item-option>
        {{'registerRemoteCluster' | translate}}
      </coh-add-item-option>
    </mat-option>
  </mat-select>
</mat-form-field>
<!-- TODO (Tung): move error inside mat-form-field when component implements MatFormFieldControl -->
<mat-error *ngIf="ngControl.dirty && ngControl.invalid">{{'errors.required' | translate}}</mat-error>
