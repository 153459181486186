/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiAlertServerConfiguration as __Configuration } from '../cohesity-api-alert-server-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AlertResolution } from '../models/alert-resolution';

/**
 * Alert resolution to resolve alerts in the system
 */
@Injectable({
  providedIn: 'root',
})
class AlertResolutionService extends __BaseService {
  static readonly GetResolutionsPath = '/alerts/resolutions';
  static readonly CreateResolutionPath = '/alerts/resolutions';
  static readonly DeleteResolutionPath = '/alerts/resolutions/{resolutionId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List the Alert Resolutions in Cohesity system.
   *
   * Returns the Alert Resolution objects found in Cohesity system that
   * match the filter criteria from given parameters. If no filter
   * parameters are specified, MaxResolutions Alert Resolution objects
   * are returned. Each object provides details about the Alert Resolution
   * info and the resolved alert info.
   * @param params The `AlertResolutionService.GetResolutionsParams` containing the following parameters:
   *
   * - `maxResolutions`: Specifies the max number of Resolutions to be returned, from the
   *   latest created to the earliest created
   *
   * - `accountId`: Current Account Id.
   *
   * - `tenantIds`: TenantId contains ids of the current tenant for which objects are to be returned.
   *
   * - `resolutionName`: Specifies Alert Resolution Name to query.
   *
   * - `resolutionId`: Specifies Alert Resolution id to query.
   *
   * @return Success
   */
  GetResolutionsResponse(params: AlertResolutionService.GetResolutionsParams): __Observable<__StrictHttpResponse<Array<AlertResolution>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.maxResolutions != null) __params = __params.set('maxResolutions', params.maxResolutions.toString());
    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.resolutionName != null) __params = __params.set('resolutionName', params.resolutionName.toString());
    if (params.resolutionId != null) __params = __params.set('resolutionId', params.resolutionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/alerts/resolutions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AlertResolution>>;
      })
    );
  }
  /**
   * List the Alert Resolutions in Cohesity system.
   *
   * Returns the Alert Resolution objects found in Cohesity system that
   * match the filter criteria from given parameters. If no filter
   * parameters are specified, MaxResolutions Alert Resolution objects
   * are returned. Each object provides details about the Alert Resolution
   * info and the resolved alert info.
   * @param params The `AlertResolutionService.GetResolutionsParams` containing the following parameters:
   *
   * - `maxResolutions`: Specifies the max number of Resolutions to be returned, from the
   *   latest created to the earliest created
   *
   * - `accountId`: Current Account Id.
   *
   * - `tenantIds`: TenantId contains ids of the current tenant for which objects are to be returned.
   *
   * - `resolutionName`: Specifies Alert Resolution Name to query.
   *
   * - `resolutionId`: Specifies Alert Resolution id to query.
   *
   * @return Success
   */
  GetResolutions(params: AlertResolutionService.GetResolutionsParams): __Observable<Array<AlertResolution>> {
    return this.GetResolutionsResponse(params).pipe(
      __map(_r => _r.body as Array<AlertResolution>)
    );
  }

  /**
   * Create an Alert Resolution.
   *
   * Apply resolution to alerts, create the resolution if not exists
   * @param body Alert resolution to be created, with alerts to resolve. Alerts.
   * @return Success
   */
  CreateResolutionResponse(body: AlertResolution): __Observable<__StrictHttpResponse<AlertResolution>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/alerts/resolutions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AlertResolution>;
      })
    );
  }
  /**
   * Create an Alert Resolution.
   *
   * Apply resolution to alerts, create the resolution if not exists
   * @param body Alert resolution to be created, with alerts to resolve. Alerts.
   * @return Success
   */
  CreateResolution(body: AlertResolution): __Observable<AlertResolution> {
    return this.CreateResolutionResponse(body).pipe(
      __map(_r => _r.body as AlertResolution)
    );
  }

  /**
   * Delete an existing Alert Resolution.
   *
   * Delete an existing Alert Resolution if no alert associated.
   * @param params The `AlertResolutionService.DeleteResolutionParams` containing the following parameters:
   *
   * - `resolutionId`: Unique id of the Alert Resolution to apply.
   *
   * - `accountId`: Current Account Id.
   *
   * - `tenantIds`: TenantId contains ids of the current tenant for which objects are to be returned.
   */
  DeleteResolutionResponse(params: AlertResolutionService.DeleteResolutionParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.accountId != null) __params = __params.set('accountId', params.accountId.toString());
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/alerts/resolutions/${encodeURIComponent(params.resolutionId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete an existing Alert Resolution.
   *
   * Delete an existing Alert Resolution if no alert associated.
   * @param params The `AlertResolutionService.DeleteResolutionParams` containing the following parameters:
   *
   * - `resolutionId`: Unique id of the Alert Resolution to apply.
   *
   * - `accountId`: Current Account Id.
   *
   * - `tenantIds`: TenantId contains ids of the current tenant for which objects are to be returned.
   */
  DeleteResolution(params: AlertResolutionService.DeleteResolutionParams): __Observable<null> {
    return this.DeleteResolutionResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module AlertResolutionService {

  /**
   * Parameters for GetResolutions
   */
  export interface GetResolutionsParams {

    /**
     * Specifies the max number of Resolutions to be returned, from the
     * latest created to the earliest created
     */
    maxResolutions: number;

    /**
     * Current Account Id.
     */
    accountId: string;

    /**
     * TenantId contains ids of the current tenant for which objects are to be returned.
     */
    tenantIds?: Array<string>;

    /**
     * Specifies Alert Resolution Name to query.
     */
    resolutionName?: string;

    /**
     * Specifies Alert Resolution id to query.
     */
    resolutionId?: string;
  }

  /**
   * Parameters for DeleteResolution
   */
  export interface DeleteResolutionParams {

    /**
     * Unique id of the Alert Resolution to apply.
     */
    resolutionId: string;

    /**
     * Current Account Id.
     */
    accountId: string;

    /**
     * TenantId contains ids of the current tenant for which objects are to be returned.
     */
    tenantIds?: Array<string>;
  }
}

export { AlertResolutionService }
