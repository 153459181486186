import { Injectable } from '@angular/core';
import { RenewClaimTokenResult, RigelmgmtServiceApi } from '@cohesity/api/rms';
import { IrisContextService } from '@cohesity/iris-core';
import { AjaxHandlerService } from '@cohesity/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services';
import { CommonDmsClusterConnectionService } from './common-dms-cluster-connection.service';
import { DmsConnection } from './models/dms-connection';

/**
 * Connection token information.
 */
export interface DmsConnectionToken {
  token?: string;
  expiration?: number;
}

/**
 * Provide Rigel connection token API service.
 * This is separated from DmsConnectionService to avoid circular dependency as
 * the token dialog depends on this service and DmsConnectionService depends on token dialog.
 */
@Injectable()
export class DmsConnectionTokenService {
  constructor(
    private ajaxService: AjaxHandlerService,
    private commonDmsClusterConnectionService: CommonDmsClusterConnectionService,
    private dialogService: DialogService,
    private irisCtx: IrisContextService,
    private rigelService: RigelmgmtServiceApi,
  ) { }

  /**
   * Gets the DMaaS connection token.
   *
   * @param     connection  The connection object.
   * @returns   Connection token information.
   */
  getConnectionToken(connection: DmsConnection): Observable<DmsConnectionToken> {
    return this.commonDmsClusterConnectionService.getConnectionToken(connection);
  }

  /**
   * Renews the DMaaS connection token.
   *
   * @param     connectionId  Connection ID.
   * @returns   Connection token information.
   */
  renewConnectionToken(connectionId: number): Observable<DmsConnectionToken> {
    return this.rigelService.RenewToken(connectionId).pipe(
      map((response: RenewClaimTokenResult) => ({
        token: response.claimToken,
        expiration: response.tokenExpiryTimeUsecs
      }))
    );
  }
}
