import { Injectable } from '@angular/core';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { DataTreeSelection } from '@cohesity/helix';
import { SourceSelection, TagCategory } from '@cohesity/iris-source-tree';
import { TranslateService } from '@ngx-translate/core';
import { flagEnabled, IrisContextService, isClusterScope } from '@cohesity/iris-core';

import { BaseProtectionSourceService } from '../shared/base-protection-source.service';
import { KubernetesSourceDataNode } from './kubernetes-source-data-node';
import { KubernetesViewFilters } from './kubernetes-view-filters';
import { KubernetesProtectionTypes } from './kubernetes.constants';
import { KubernetesSourceOptionsComponent } from './kubernetes-source-options/kubernetes-source-options.component';
import { ComponentType } from '@angular/cdk/portal';
@Injectable({
  providedIn: 'root'
})
export class KubernetesSourceTreeService extends BaseProtectionSourceService<KubernetesSourceDataNode> {
  /**
   * A reference to vm view filters, to show a physical, folder, flat, and tagged list view.
   */
  kubernetesViewFilters: KubernetesViewFilters;

  constructor(
    readonly translate: TranslateService,
    private irisCtx: IrisContextService,
  ) {
    super();

    if (flagEnabled(this.irisCtx.irisContext, 'enableKubernetesLabel')) {
      this.kubernetesViewFilters = new KubernetesViewFilters(
        this.filters,
        this.treeControl,
        this.treeTransformer,
      );
    }
  }

  /**
   * Parse a list of tree nodes to determine available tags that can be filtered by.
   *
   * @param   allNodes   The complete list of nodes.
   * @param   selection  The current selection.
   * @returns A list of tag categories that can be filtered by.
   */
  getTagCategories(allNodes: KubernetesSourceDataNode[],
    selection: DataTreeSelection<KubernetesSourceDataNode>): TagCategory[] {

    if (!flagEnabled(this.irisCtx.irisContext, 'enableKubernetesLabel')) {
      return;
    }

    const tagNodes = (allNodes.filter(node => node.type === 'kLabel' && node.expandable &&
      node?.children[0]?.protectionSource?.kubernetesProtectionSource.type === KubernetesProtectionTypes.kNamespace)
      || []);
    const tags: TagCategory[] = [];
    const tagMap: any = {};

    // All available labels, grouped by category
    tags.push({ name: this.translate.instant('allLabels'),
      tagGroups: tagNodes.map((tagNode: ProtectionSourceNode) => {
        tagMap[tagNode.protectionSource.id] = tagNode.protectionSource.name;
        return [
          {
            id: tagNode.protectionSource.id,
            uuid: tagNode.protectionSource.kubernetesProtectionSource.uuid,
            name: tagNode.protectionSource.name,
          }
        ];
      }),
    });

    // Add a category for currently excluded tags
    const excludedTags = (selection.excluded || []).filter(node => node.type === 'kLabel');
    if (excludedTags.length) {
      tags.unshift({
        name: this.translate.instant('excluded'),
        tagGroups: excludedTags.map(tagNode =>
          tagNode.ids.map(tagId => ({
            id: Number(tagId),
            name: tagMap[tagId],
          }))
        ),
      });
    }

    // Add a category for currently auto protected tags
    const autoProtectedTags = (selection.autoSelected || []).filter(node => node.type === 'kLabel');
    if (autoProtectedTags.length) {
      tags.unshift({
        name: this.translate.instant('autoProtected'),
        tagGroups: autoProtectedTags.map(tagNode =>
          tagNode.ids.reduce((tagsUnderCategory, tagId) => {
            if (tagMap[tagId]) {
              tagsUnderCategory.push({
                id: Number(tagId),
                name: tagMap[tagId],
              });
            }
            return tagsUnderCategory;
          }, [])
        ),
      });
    }

    return tags;
  }

  /**
   * Transforms the node object from the api into a Kubernetes Tree node to pass to the tree.
   *
   * @param   node   The original node.
   * @param   level  The level in the tree.
   * @return  An KubernetesSourceDataNode that can be displayed in the tree.
   */
  transformData(node: ProtectionSourceNode, level: number): KubernetesSourceDataNode {
    return new KubernetesSourceDataNode(node, this.irisCtx, level);
  }

  /**
   * Convert the data tree selection model to the job selection model.
   *
   * @param   selection   The selection from the tree.
   * @return  The job selection info.
   */
  transformFromDataTreeSelection(selection: DataTreeSelection<KubernetesSourceDataNode>): SourceSelection {
    // Sources include explicitly selected leaf node, and auto selected items
    const autoSelected = selection.autoSelected.filter(item => !item.isTag);
    const sources = selection.selected.filter(item => item.isLeaf).concat(autoSelected);

    return {
      // non-tag excluded items
      excludeSourceIds: selection.excluded.filter(item => !item.isTag).map(item => Number(item.id)),

      // excluded tags. The ids for these need to be converted to number since the ids property splits
      // a string and leaves them as strings. This comes out to an array of arrays.
      excludeVmTagIds: selection.excluded.filter(item => item.isTag).map(item => item.ids.map(id => Number(id))),

      // non-tag source ids
      sourceIds: sources.map(item => Number(item.id)),

      sourceSpecialParameters: Object.values(selection.options || {}).filter(option => {
        if (!option) {
          return false;
        }
        return Object.values(option.kubernetesSpecialParameters || {}).some(param => !!param);
      }),

      // tag source ids, an array of arrays.
      vmTagIds: selection.autoSelected.filter(item => item.isTag).map(item => item.ids.map(id => Number(id))),
    };
  }

  /**
   * Convert source selection to the data tree selection model.
   * source ids can be either selected items or auto selected items, nodes with children are
   * assumed to be auto selected. Nodes can be in the tree multiple times, and should not be
   * duplicated in the selection.
   *
   * @param   allNodes         The unfiltered list of tree nodes.
   * @param   sourceSelection  The job selection.
   * @return  A data tree selection model.
   */
  transformToDataTreeSelection(
    allNodes: KubernetesSourceDataNode[],
    sourceSelection: SourceSelection
  ): DataTreeSelection<KubernetesSourceDataNode> {
    const treeSelection = super.transformToDataTreeSelection(allNodes, sourceSelection);

    if (sourceSelection.vmTagIds) {
      sourceSelection.vmTagIds.forEach(tagIds =>
        treeSelection.autoSelected.push(this.kubernetesViewFilters.createTagNode(tagIds, allNodes))
      );

      // If a node is in the selection list but is protected by a tag, we shouldn't mark it as explicitly
      // selected.
      treeSelection.selected = treeSelection.selected.filter(
        selected => !this.treeControl.matchTagSelection(treeSelection.autoSelected, selected)
      );
    }

    if (sourceSelection.excludeVmTagIds) {
      sourceSelection.excludeVmTagIds.forEach(tagIds =>
        treeSelection.excluded.push(this.kubernetesViewFilters.createTagNode(tagIds, allNodes))
      );
    }
    return treeSelection;
  }

  /**
   * Only expand certain node types by default.
   *
   * @param   node   The treenode to check.
   * @return  True if the node should be expanded by default.
   */
  shouldExpandNodeOnLoad(node: KubernetesSourceDataNode): boolean {
    return node.type !== KubernetesProtectionTypes.kNamespace;
  }

  /**
   * Gets a component to render for a source's special parameters. This does not apply to every node
   * and can be null for certain data types.
   */
  getSpecialParametersComponent(node: KubernetesSourceDataNode): ComponentType<any> {
    if (
      flagEnabled(this.irisCtx.irisContext, 'includeExcludePvcKubernetes') &&
      isClusterScope(this.irisCtx.irisContext) && (node.level === 1 ||
      (node.level === 0 && node.type === KubernetesProtectionTypes.kNamespace)) &&
      node?.children?.length && !this.dataTreeSelection.currentSelection.autoSelected.find(autoSelectedNode =>
      autoSelectedNode.data.protectionSource.id === node.protectionSource.id) ||
      flagEnabled(this.irisCtx.irisContext, 'enableKubernetesQuiesceRules') ) {
      return KubernetesSourceOptionsComponent;
    }
    return null;
  }

  /**
   * Returns whether a node is a leaf or not. This can be used to calculate selection totals.
   */
  isLeaf(treeNode: KubernetesSourceDataNode): boolean {
    return treeNode.isLeaf;
  }
}
