import { inject, Injectable } from '@angular/core';
import { EventTrackingService } from '@cohesity/helix';
import { datadogRum } from '@datadog/browser-rum';
import { debounce, startCase } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';
import { flagEnabled, IrisContextService, isClusterScope, isMcm, isMcmGovCloud } from '../iris-context';
import { HeliosAnalyticsUser } from './helios-analytics-user.model';
import {
  DataIdTrackingEventMap,
  StartsWithDataIdsMap,
  TrackingEvent,
  TrackingEventKeys,
} from './tracking-events.constants';

/**
 * Overwrite global window object to add keys which will be dynamically added.
 */
declare global {
  interface Window {
    /**
     * Intercom's javascript bundle is stored in the "Intercom" variable in
     * window object.
     */
    Intercom: any;

    /**
     * Window variable for intercom settings.
     */
    intercomSettings: any;

    /**
     * Window variable for intercom app id.
     */
    intercomAppId: any;
  }
}

/**
 * Properties associcated with the captured event
 */
export interface EventProperties {
  /**
   * Current scope of the user
   */
  scopeName: 'allClusters' | 'cluster' | string;
  /**
   * Query params associated with the event page
   */
  searchParams?: Record<string, unknown>;
  /**
   * Name of the UI router state
   */
  stateName?: string;
  /**
   * URL of the event page
   */
  pageUrl?: string;
}

/**
 * Base analytics service to set up Intercom messenger and Mixpanel analytics.
 */
@Injectable({
  providedIn: 'root',
})
export abstract class CoreAnalyticsService {
  /**
   * Function to call the track event in a debounced way.
   */
  debouncedTrackDataIdEvent: (item: string, eventTrackingId: string, element: HTMLElement) => void;

  /**
   * Username used for identification.
   */
  readonly analyticsUserName = new BehaviorSubject<string>(null);

  /**
   * Service to track events generated by helix component interactions
   */
  readonly eventTrackingService = inject(EventTrackingService);

  /**
   * Iris context service
   */
  protected irisContextService = inject(IrisContextService);

  /**
   * Logger service
   */
  readonly ngxLogger = inject(NGXLogger);

  /**
   * Function to determine whether analytics is enabled.
   *
   * @return Whether analytics is enabled.
   */
  get analyticsEnabled(): boolean {
    const irisContext = this.irisContextService.irisContext;

    // The order of checks here is important since both isMcmGovCloud and
    // isMcmOnPrem will also return true for isMcm.

    if (isMcmGovCloud(irisContext)) {
      // Whether Helios is hosted in gov cloud.
      return flagEnabled(irisContext, 'heliosAnalyticsGovCloudEnabled');
    }

    if (isMcm(irisContext)) {
      // Regular Helios.
      return flagEnabled(irisContext, 'heliosAnalyticsEnabled');
    }

    return false;
  }

  /**
   * Whether intercom is enabled.
   */
  get intercomEnabled(): boolean {
    return this.analyticsEnabled && flagEnabled(
      this.irisContextService.irisContext,
      'heliosAnalyticsIntercomEnabled'
    );
  }

  /**
   * Whether mixpanel is enabled.
   */
  get mixpanelEnabled(): boolean {
    return this.analyticsEnabled && flagEnabled(
      this.irisContextService.irisContext,
      'heliosAnalyticsMixpanelEnabled'
    );
  }

  /**
   * Whether datadog RUM is enabled.
   */
  get datadogRumEnabled(): boolean {
    return this.analyticsEnabled && flagEnabled(
      this.irisContextService.irisContext,
      'datadogRumEnabled'
    );
  }


  /**
   * Function to return the formatted event based on the cog data id.
   *
   * @param item The cog data id value.
   * @param eventTrackingId The event tracking id value.
   * @param element The element which was interacted with.
   *
   * @return The formatted event.
   */
  getFormattedEvent(
    item: string,
    eventTrackingId: string,
    element: HTMLElement
  ): {
    event: string;
    properties: { optionValue: string };
  } {
    let value;
    let optionValue;

    if (eventTrackingId) {
      value = eventTrackingId;
    } else {
      const indexOfAnchor = item.indexOf('anchor');
      const indexOfOption = item.indexOf('option');
      value = item;

      if (indexOfAnchor > -1) {
        // Ignore anything after "anchor" in cog data ids.
        value = item.slice(0, indexOfAnchor + 6);
      }

      if (indexOfOption > -1) {
        // Ignore anything after "option" in cog data ids.
        // But store the value to pass to analytics properties.
        value = item.slice(0, indexOfOption + 6);
        optionValue = item.slice(indexOfOption + 7);
      }
    }

    // Container path is the prefix which contains information for where
    // the tracking event occurred. This is derived from the URL of the app.
    let containerPath = '';

    if (['mat-sidenav', 'mat-toolbar'].every(selector => !document.querySelector(selector)?.contains(element))) {
      // Don't add container path to navigation and toolbar clicks as they are
      // not part of any page.
      containerPath = window.location.pathname
        .split('/')
        .filter(pathValue => !/\d/.test(pathValue))
        .join('-');
    }
    // Remove multiple occurrences of - with one.
    return {
      event: containerPath
        ? [startCase(containerPath), startCase(value.replace(/-+/g, ' '))].join(' - ')
        : startCase(value.split('-').join('-').replace(/-+/g, ' ')),
      properties: { optionValue },
    };
  }

  /**
   * The default properties for a tracking event.
   *
   * @return The default properties.
   */
  getDefaultProperties(): EventProperties {
    const fromUrlObject = new URL(window.location.href);

    return {
      scopeName: 'allClusters',
      searchParams: undefined,
      stateName: '',
      pageUrl: `${fromUrlObject.origin}${fromUrlObject.pathname}`,
    };
  }

  /**
   * Function to call to track an event and send it to mixpanel.
   *
   * @param key Key of the analytics event.
   * @param properties Any associated properties of the analytics event.
   * @param legacy Whether the destination is legacy mixpanel or new.
   */
  track(key: string, properties?: any, legacy = true) {
    if (!key || !this.analyticsUserName.value) {
      return;
    }

    const eventName = TrackingEventKeys[key];

    if (!eventName) {
      // There are two maps DataIdTrackingEventMap and TrackingEventKeys. This
      // warning will show up when someone has added this to
      // `DataIdTrackingEventMap` but not `TrackingEventKeys`, which should
      // typically be an error.
      this.ngxLogger.warn(`A key name is missing for the event key ${key}`);
    }

    // Put this data everywhere except Mixpanel New
    this.analyticsTrack(eventName || key, properties, legacy);
  }

  /**
   * Function to make the analytics based on the given keys.
   *
   * @param event The event to be tracked.
   * @param properties The properties of the event.
   * @param legacy Whether the destination is legacy mixpanel or new.
   */
  analyticsTrack<T>(event: string, properties: T, legacy: boolean = false) {
    if (isClusterScope(this.irisContextService.irisContext)) {
      // Don't send analytics tracking events in cluster scope.
      return;
    }

    if (this.mixpanelEnabled) {
      if (legacy) {
        // Legacy events are the events which are hardcoded in the codebase (as
        // opposed to cogDataId based auto tracking). No new legacy events are
        // added, but to ensure the existing Mixpanel dashboards and reports
        // continue to work, send these events to a separate project.
        mixpanel.legacy.track(event, {
          ...properties,

          // While this key is not needed for Mixpanel's tracking purposes,
          // having this key show up when looking at events in a Mixpanel
          // debugger helps distinguish which project the event is going in.
          legacyEvent: true,
        });
      } else {
        mixpanel.track(event, properties);
      }
    }
  }

  /**
   * Function to setup tracking.
   */
  setupTracking() {
    if (this.mixpanelEnabled) {
      // This step is required for marking the user as identified if the tracking
      // has been set up from a micro frontend app.
      // `mixpanel.get_property('distinct_id')` will be user's username.
      this.analyticsUserName.next(mixpanel.get_property('distinct_id'));
    }

    // Multiple calls are fired to this. Only track the first call and ignore rest.
    this.debouncedTrackDataIdEvent = debounce<typeof this.debouncedTrackDataIdEvent>(
      (item, eventTrackingId, element) => {
        if (!item) {
          return;
        }
        const { event, properties } = this.getFormattedEvent(item, eventTrackingId, element);
        // Put this data everywhere except classic Mixpanel
        this.analyticsTrack(
          event,
          {
            ...properties,
            ...this.getDefaultProperties(),
          },
          false
        );
      },
      0,
      {
        leading: true,
        trailing: false,
      }
    );

    // Listen to tracking events coming from helix components and track them for
    // analytics.
    this.eventTrackingService.event$.subscribe(event => {
      const { id, key, properties, element, dataId, eventTrackingId } = event;

      this.debouncedTrackDataIdEvent(dataId, eventTrackingId, element);

      if (key) {
        // Bypass trying to lookup analytics key and properties.
        this.track(key, properties);

        return;
      }

      const analyticsEvents = this.getAnalyticsEvent(id);

      if (!analyticsEvents.length) {
        return;
      }

      for (const analyticsEvent of analyticsEvents) {
        if (
          analyticsEvent.parentSelector &&
          !document.querySelector(analyticsEvent.parentSelector)?.contains(element)
        ) {
          // If the event has a parentSelector restriction, make sure the event's
          // element is within the specified parent selector.
          continue;
        }

        this.track(typeof analyticsEvent === 'string' ? analyticsEvent : analyticsEvent.key, {
          ...analyticsEvent.properties,
          ...properties,
        });
      }
    });
  }

  /**
   *
   * @param userName username of the logged-in user
   * @param userDetails details of the user that might be useful for analytics
   */
  identifyAnalyticsUser(userName: string, userDetails: HeliosAnalyticsUser) {
    if (this.intercomEnabled) {
      // Initialize intercom with the logged-in user and set the user attributes.
      window.Intercom('boot', {
        ...userDetails,
        app_id: window.intercomAppId,
        user_id: userName,
      });
    }

    if (this.mixpanelEnabled) {
      // Identify the logged-in user for both mixpanel projects.
      mixpanel.identify(userName);
      mixpanel.legacy.identify(userName);

      // Set the user attributes for the logged-in user for both mixpanel projects.
      mixpanel.people.set(userDetails);
      mixpanel.legacy.people.set(userDetails);
    }

    if (this.datadogRumEnabled) {
      datadogRum.setUser({
        ...userDetails,
        id: userName,
        email: userDetails.email,
        name: userDetails.name,
      });
    }

    this.analyticsUserName.next(userName);
  }
  /**
   * Resets user identity.
   * Useful when the user logs out.
   */
  resetAnalyticsUser(): void {
    if (this.mixpanelEnabled) {
      mixpanel.reset();
      mixpanel.legacy.reset();
    }

    this.analyticsUserName.next(null);
  }

  /**
   * Function to return all the associated tracking events of a click event with
   * a data id.
   */
  getAnalyticsEvent(id: string): TrackingEvent[] {
    const exactMatches = [].concat(DataIdTrackingEventMap[id]);
    const startsWithMatches = [];

    for (const [key, value] of Object.entries(StartsWithDataIdsMap)) {
      if (id.startsWith(key)) {
        startsWithMatches.push(...value);
      }
    }

    return [...exactMatches, ...startsWithMatches].filter(Boolean);
  }
}
