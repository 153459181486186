import { Directive, HostBinding, Input } from '@angular/core';

/**
 * This directive positions an element at the top of a table so that it displays on top of the header row.
 */
@Directive({
  selector: '[cogTableActionsRow]',
})
export class TableActionsRowDirective {
  /**
   * Apply the style to position the row,
   */
  @HostBinding('class.cog-table-actions-row') style = true;

  /**
   * Hide or show the header component.
   */
  @HostBinding('class.hidden') @Input() hidden = true;
}
