import { Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';

import { OPEN_CLOSE_ANIMATION } from '../../animations/index';
import { HelixIntlService } from '../../helix-intl.service';

/**
 * Used in combination with SettingsListItemComponent to house a list of setting items, and managing their display.
 *
 * See demo application for an implementation example.
 */
@Component({
  selector: 'cog-settings-list',
  templateUrl: './settings-list.component.html',
  styleUrls: ['./settings-list.component.scss'],

  // TODO: investigate providing this functionality via a service provided at this component level.
  viewProviders: [SettingsListComponent],

  // Disabling encapsulation so list items can be styled according to their position in the list.
  encapsulation: ViewEncapsulation.None,
  animations: [OPEN_CLOSE_ANIMATION],
})
export class SettingsListComponent {
  /**
   * A title to be displayed above the list of settings.
   */
  @Input() title: string;

  /**
   * Remove top padding if true.
   */
  @Input() noTopPadding = false;

  /**
   * Settings list will be rendered in different ways based on the mode.
   */
  @Input() mode: 'list' | 'card' | 'insideCard' | 'default' = 'default';

  /**
   * Indicates if the settings list should always be visible.
   */
  @Input() alwaysOpen = false;

  /**
   * Indicates if the list should be in "always edit" mode. This also applies alwaysOpen behavior.
   */
  @Input() alwaysEdit = false;

  /**
   * Indicates if the list should be in "read only" mode.
   */
  @Input() readOnly = false;

  /**
   * Indicates if settings list is currently invalid.
   */
  @Input() invalid = false;

  /**
   * Privately cached value indicating if settings list is visible or collapsed.
   */
  private _settingsVisible = false;

  /**
   * Additional settings scroll container reference
   */
  @ViewChild('additionalSettingsContainer') private additionalSettingsContainer: ElementRef;

  /**
   * Indicates if the list of settings is currently visible or not. Initial value can be set via Input().
   */
  @Input('settingsVisible')
  get settingsVisible(): boolean {
    // forced visibility if any fields are invalid, otherwise return the cached value.
    return this.alwaysEdit || this.alwaysOpen || this.invalid || this._settingsVisible;
  }

  /**
   * Setter for visibility value.
   */
  set settingsVisible(newVal: boolean) {
    this._settingsVisible = newVal;
  }

  /**
   * Toggles the visibility Value.
   */
  toggleSettingsVisibility() {
    this.settingsVisible = !this.settingsVisible;
    if (this.settingsVisible) {
      setTimeout(() => {
        this.scrollToSettings();
      }, 400);
    }
  }

  /**
   * Scrolls additional settings section to the view port
   */
  scrollToSettings(): void {
    try {
      const el = this.additionalSettingsContainer.nativeElement;
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    } catch (err) { }
  }

  constructor(public intlService: HelixIntlService) { }
}
