import { Component, Input } from '@angular/core';

@Component({
  selector: 'coh-sd-clones-list',
  templateUrl: './clones-list.component.html',
  styleUrls: ['../delete-storage-domain-stepper.scss'],
})
export class ClonesListComponent {
  /**
   * Clone list.
   */
  @Input() cloneList: any[];
}
