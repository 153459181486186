import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from 'src/app/shared/pipes';

import { AzureSourceDetailsSettingsComponent } from './azure/source-details-settings.component';
import { CassandraSourceDetailsSettingsComponent } from './cassandra/source-details-settings/source-details-settings.component';
import { CouchbaseSourceDetailsSettingsComponent } from './couchbase/source-details-settings/source-details-settings.component';
import { HBaseSourceDetailsSettingsComponent } from './hbase/source-details-settings/source-details-settings.component';
import { HdfsSourceDetailsSettingsComponent } from './hdfs/source-details-settings/source-details-settings.component';
import { HiveSourceDetailsSettingsComponent } from './hive/source-details-settings/source-details-settings.component';
import { KubernetesSourceDetailsSettingsComponent } from './kubernetes/k8-source-details-settings/k8-source-details-settings.component';
import { MongoDBSourceDetailsSettingsComponent } from './mongodb/source-details-settings/source-details-settings.component';
import { SfdcSourceDetailsSettingsComponent } from './sfdc/source-details-settings/source-details-settings.component';
import { UdaGenericSourceDetailsSettingsComponent } from './uda/generic-source/generic-source-details-settings.component';
import { UdaManagedSourceDetailsSettingsComponent } from './uda/managed-source/managed-source-details-settings.component';
import { UdaSourceDetailsSettingsComponent } from './uda/source-details-settings/source-details-settings.component';

const COMPONENTS = [
  AzureSourceDetailsSettingsComponent,
  CassandraSourceDetailsSettingsComponent,
  CouchbaseSourceDetailsSettingsComponent,
  HBaseSourceDetailsSettingsComponent,
  HdfsSourceDetailsSettingsComponent,
  HiveSourceDetailsSettingsComponent,
  KubernetesSourceDetailsSettingsComponent,
  MongoDBSourceDetailsSettingsComponent,
  SfdcSourceDetailsSettingsComponent,
  UdaGenericSourceDetailsSettingsComponent,
  UdaManagedSourceDetailsSettingsComponent,
  UdaSourceDetailsSettingsComponent,
];

@NgModule({
  declarations: COMPONENTS,
  exports: COMPONENTS,
  imports: [
    CommonModule,
    PipesModule,
    TranslateModule.forChild()
  ],
})
export class SourceDetailsModule { }

// AJS Downgrades
declare const angular: any;
angular
  .module('C')
  .directive('ngCassandraSourceDetailsSettings', downgradeComponent({
    component: CassandraSourceDetailsSettingsComponent,
    inputs: ['source']
  }))
  .directive('ngCouchbaseSourceDetailsSettings', downgradeComponent({
    component: CouchbaseSourceDetailsSettingsComponent,
    inputs: ['source']
  }))
  .directive('ngHbaseSourceDetailsSettings', downgradeComponent({
    component: HBaseSourceDetailsSettingsComponent,
    inputs: ['source']
  }))
  .directive('ngHdfsSourceDetailsSettings', downgradeComponent({
    component: HdfsSourceDetailsSettingsComponent,
    inputs: ['source']
  }))
  .directive('ngHiveSourceDetailsSettings', downgradeComponent({
    component: HiveSourceDetailsSettingsComponent,
    inputs: ['source']
  }))
  .directive('ngMongodbSourceDetailsSettings', downgradeComponent({
    component: MongoDBSourceDetailsSettingsComponent,
    inputs: ['source']
  }))
  .directive('ngSfdcSourceDetailsSettings', downgradeComponent({
    component: SfdcSourceDetailsSettingsComponent,
    inputs: ['source']
  }))
  .directive('ngUdaSourceDetailsSettings', downgradeComponent({
    component: UdaSourceDetailsSettingsComponent,
    inputs: ['source']
  }))
  .directive('ngKubernetesSourceDetailsSettings', downgradeComponent({
    component: KubernetesSourceDetailsSettingsComponent,
    inputs: ['source']
  }));
