// Module: Alerts Management Notifications

;(function(angular, undefined) {
  'use strict';

  angular.module('C.alerts')
    .controller('alertsTabResolutionsCtrl', AlertsTabResolutionsCtrlFn);

  function AlertsTabResolutionsCtrlFn() {}

})(angular);
