import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';

@Pipe({
  name: 'principalName'
})
export class PrincipalNamePipe implements PipeTransform {

  constructor (private userService: UserService) {}

  /**
   * Returns a formatted display name for the provided principal.
   * The type for principal is any because more than one type of principal object is consumed.
   */
  transform(principal: any, hideDomain: boolean): string {
    if (!principal) {
      return;
    }
    if (typeof principal === 'string') {
      return principal;
    }

    // Select the name part which is different for many APIs.
    const derivedNameParts: string[] = [
      // public/principals (primary)
      // public/principals/searchPrincipals (primary)
      principal.fullName ||

      // public/principals
      // public/principals/searchPrincipals
      principal.principalName ||

      // public/groups
      principal.name ||

      // public/users
      principal.username ||

      // public/views
      principal.ownerSid
    ];

    // If a local tenant user, then show the user with the tenant Id.
    // Else if a non-local tenant user, show the domain name.
    const tenantId = this.userService.getUserTenantId();
    if (tenantId && principal.domain === 'LOCAL') {
      derivedNameParts.push(' (' + tenantId + ')');
    } else if (!hideDomain && principal.domain && principal.domain !== 'allDomains') {
      derivedNameParts.push(' (' + principal.domain + ')');
    }
    return derivedNameParts.join('');
  }

}
