import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Controls, NgxSubFormComponent, subformComponentProviders, takeUntilDestroyed } from 'ngx-sub-form';

import { MfaService } from '../../../services/mfa.service';
import { MfaSecurityToken, MFAType } from '../../interfaces/mfa-verification';

@Component({
  selector: 'coh-mfa-verify-settings',
  templateUrl: './verify-settings.component.html',
  styleUrls: ['./verify-settings.component.scss'],
  providers: subformComponentProviders(VerifySettingsComponent),
})
export class VerifySettingsComponent extends NgxSubFormComponent<MfaSecurityToken> {

  /**
   * Outputs whether the next button is clicked.
   */
  @Output() next = new EventEmitter<string>();

  /**
   * Outputs whether the previous button is clicked.
   */
  @Output() previous = new EventEmitter();

  /**
   * Holds whether the token has been validated.
   */
  verified = false;

  /**
   * Returns the next button text.
   */
  get nextBtnText(): string {
    if (this.mfaService.isHybridAuthNeeded) {
      return 'next';
    } else {
      return 'complete';
    }
  }

  getFormControls(): Controls<MfaSecurityToken> {
    return {
      securityToken: new UntypedFormControl(null, Validators.required),
    };
  }

  constructor(readonly mfaService: MfaService) {
    super();
    this.mfaService.isTokenVerified$
      .pipe(takeUntilDestroyed(this))
      .subscribe(verified => this.verified = verified);
  }

  /**
   * Verifies whether the One Time Password entered is valid.
   */
  verifyToken() {
    this.mfaService.verify(this.formGroupValues.securityToken, MFAType.totp)
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  /**
   * Moves next in the configuration step.
   */
  moveAhead() {
    this.next.emit(this.nextBtnText);
  }

}
