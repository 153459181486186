import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { MatLegacyButton as MatButton } from '@angular/material/legacy-button';
import { PopoverRef } from '@cohesity/helix';

/**
 * Interface for app helios status.
 */
export interface AppHeliosStatus {
   /**
    * Whether the cluster is registered to Helios.
    */
  isRegistered: boolean;

  /**
   * Whether the cluster is connected to Helios.
   */
  isConnected: boolean;

  /**
   * Reference to the mat button which opened the popover.
   */
  button: MatButton;
}

@Component({
  selector: 'coh-app-helios-status',
  templateUrl: './app-helios-status.component.html',
  styleUrls: ['./app-helios-status.component.scss']
})
export class AppHeliosStatusComponent implements AfterViewInit {
  /**
   * Whether the cluster is registered to Helios.
   */
  isRegistered: boolean;

  /**
   * Whether the cluster is connected to Helios.
   */
  isConnected: boolean;

  /**
   * Element reference to helios link.
   */
  @ViewChild('heliosLink') heliosLink: ElementRef;

  constructor(private popoverRef: PopoverRef<AppHeliosStatus>) {
    this.isRegistered = this.popoverRef.data.isRegistered;
    this.isConnected = this.popoverRef.data.isConnected;
  }

  ngAfterViewInit(): void {
    // Focus the link by default.
    this.heliosLink.nativeElement.focus();
  }

  close() {
    this.popoverRef.close();

    setTimeout(() => {
      // Focus the button which closed this popover to preserve the focus flow of elements.
      this.popoverRef.data.button.focus();
    });
  }
}
