// Factory: shared util for c-reports-controls component
import { getConfigByKey } from '@cohesity/iris-core';

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.reportsControlsUtil', [])
    .factory('cReportsControlsUtil', cReportsControlsUtilFn);

  function cReportsControlsUtilFn($filter, $translate, UserService, ReportsUtil,
    REPORTS_SELECTOR_OPTIONS, FEATURE_FLAGS, moment, $rootScope, ClusterService, NgIrisContextService) {

    var cReportsControlsUtil = {
      filters: {},
      canAccessReport: canAccessReport,
      getDefaultFilters: getDefaultFilters,
      getReportsSelectorOptions: getReportsSelectorOptions,
      getReportsTabs: getReportsTabs,
      updateFilters: updateFilters,
    };

    /**
     * Hide these reports from tenants.
     */
    const hiddenTenantReports = ReportsUtil.getConfigByKeyAsMap('reportsAjs.hiddenTenantReports');

    /**
     * Hide these reports from landlords.
     */
    const hiddenReports = ReportsUtil.getConfigByKeyAsMap('reportsAjs.hiddenReports');

    /**
     * wrapper function to get all our report options. when adding new reports
     * that are FEATURE_FLAGGED add them here
     *
     * @return    {array}    sorted array of objects by report name
     */
    function getReportsSelectorOptions() {
      var options = angular.copy(REPORTS_SELECTOR_OPTIONS);

      if (FEATURE_FLAGS.enableReportFilesBySize) {
        options.push({
          value: 'reports.files-topfiles',
          name: 'reportsControls.names.filesTopReport',
        });
      }

      if (FEATURE_FLAGS.viewsUserQuotas) {
        options.push({
          value: 'reports.user-quotas',
          name: 'reportsControls.names.userQuotas',
          type: 'kUserQuotasReport',
        });
      }

      if (FEATURE_FLAGS.directoryQuotas) {
        options.push({
          value: 'reports.dir-quotas',
          name: 'reportsControls.names.dirQuotas',
          type: 'kDirQuotasReport',
        });
      }

      if (FEATURE_FLAGS.gdprReportEnabled) {
        options.push({
          value: 'reports.gdpr-objects',
          name: 'reportsControls.names.gdprObjectsSummary',
          type: 'kGdprReport',
        });
      }

      options.push({
        value: 'reports.agent-status',
        name: 'reportsControls.names.agentStatus',
        type: 'kAgentDeploymentReport',
      });

      if ($rootScope.clusterInfo.multiTenancyEnabled ||
        UserService.isTenantUser()) {
        options.push({
          value: 'reports.storage-tenants',
          name: 'reportsControls.names.storageTenantsReport',
          type: 'kStorageConsumedByTenantsReport',
        });
      }

      if (FEATURE_FLAGS.jobRunsReportEnabled) {
        options.push({
          value: 'reports.protection-runs',
          name: 'reportsControls.names.protectionRunsSummary',
          type: 'kProtectionRunsSummaryReport',
        });
      }

      if (FEATURE_FLAGS.heatmapReportEnabled) {
        options.push({
          value: 'reports.protection-heatmap',
          name: 'reportsControls.names.protectedObjectsHeatmap',
          type: 'kProtectedObjectsTrendsReport',
        });
      }

      // resolving the report config.
      options.forEach(function eachReport(report) {
        // get config w/o datasetPromiseObject
        report.config = ReportsUtil.getReportConfig(report.type)(true);
      });

      // filter reports that a tenant user can view
      if (UserService.isTenantUser()) {
        // Include only those reports viewable by a tenant and are not cluster
        // specific reports.
        // TODO(veetesh): remove traces of showTenantsFilter when backend
        // qualifies all other reports.
        options = options.filter(function eachReport(report) {
          // Enable storage consumption by storage domains report for tenant
          // when viewbox sharing is disabled.
          if (report.type === 'kStorageConsumedByViewBoxReport') {
            return !ClusterService.clusterInfo.tenantViewboxSharingEnabled;
          }
          return !report.isClusterSpecific && !!report.config.showTenantsFilter;
        });
      }

      /**
       * This special case if the feature enabler has a list of reports to filter out.
       * Tenant users might have more hidden reports than super admins on this cluster.
       *
       */
      if (UserService.isTenantUser()) {
        options = options.filter(opt => !hiddenTenantReports[opt.value]);
      } else {
        options = options.filter(opt => !hiddenReports[opt.value]);
      }

      return $filter('orderBy')(options, function sortByName(reportOption) {
        return $translate.instant(reportOption.name);
      });
    }

    /**
     * centralized function to get default filters when a report first loads,
     * we set the default timeObject values to 1 week span but can be overriden
     * by passing in a filters object
     *
     * @param     {object}    defaultFilters   the default filters the report
     *                                         needs, can also be used to reset
     *                                         filters to new state, usually
     *                                         defined in controller init
     *                                         functions
     *
     * @return    {object}                    object containing filters
     */
    function getDefaultFilters(defaultFilters) {
      var reportsDefaultRange =
        FEATURE_FLAGS.reportsDefaultRangeOneDayEnabled ? 1 : 7;
      cReportsControlsUtil.filters = {
        timeObject: {
          from: moment().subtract(reportsDefaultRange, 'days').startOf('day'),
          until: moment().endOf('day'),
        }
      };

      return angular.extend(cReportsControlsUtil.filters, defaultFilters);
    }

    /**
     * updates our filters object that is shared across report and the
     * sidebar component
     *
     * @param     {object}    newFilters    new filter values to be set on
     *                                     fitlers object
     *
     * @return    {object}                 object with your newly set filters
     */
    function updateFilters(newFilters) {
      return angular.extend(cReportsControlsUtil.filters, newFilters);
    }

    /**
     * Get the list of reports tab.
     *
     * @method   getReportsTabs
     * @returns  Return a list of reports tab.
     */
    function getReportsTabs() {
      const hideEmailScheduler = getConfigByKey(NgIrisContextService.irisContext, 'reportsAjs.hideEmailScheduler', false);
      return [
        {
          headingKey: 'reports',
          'default': true,
          route: 'reports.protection-heatmap',
        },
        ...( hideEmailScheduler ? [] : [{
          headingKey: 'scheduledEmails',
          route: 'reports.scheduled-emails',
        }])
      ];
    }

    /**
     * Determines whether the logged-in user can access the provided state.
     *
     * @method   canAccessReport
     * @param    state    The state to check.
     * @returns  Return true if user can access otherwise return false.
     */
    function canAccessReport(state) {
      var availableReports = getReportsSelectorOptions();

      return !!_.find(availableReports, ['value', state.name]);
    }

    return cReportsControlsUtil;
  }
})(angular);
