import {
  ElastifileProtectionSource,
  FlashBladeProtectionSource,
  GpfsProtectionSource,
  IsilonProtectionSource,
  NasProtectionSource,
  NetappProtectionSource,
  ProtectionSourceNode,
} from '@cohesity/api/v1';
import { Memoize } from '@cohesity/utils';
import { get } from 'lodash';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { Environment } from 'src/app/shared';

import { ProtectionSourceDataNode } from '../shared/protection-source-data-node';
import { HasMetadata, SourceNodeMetadata } from '../shared/protection-source-metadata/source-node-metadata';
import {
  filesystemEnvMap,
  FileSystemMap,
  mixedModeSupportedAdapters,
  nasProtocols,
  noSizeAdapters,
  supportedFilesystemsMap,
} from './nas.constants';

/**
 * Represents an nas source node and job tree selection behavior.
 */
export class NasSourceDataNode extends ProtectionSourceDataNode implements HasMetadata {
  /**
   * Metadata for NAS nodes
   */
  readonly metadata: SourceNodeMetadata;

  constructor(environment: Environment,
    data: ProtectionSourceNode,
    readonly level: number,
    private irisCtx: IrisContextService,
    private isDmsScope: boolean,
  ) {
    super(environment, data, level);
    this.metadata = {
      logicalSize: !noSizeAdapters.includes(environment) ? this.logicalSize : undefined,
      leafCount: this.expandable ? this.leafCount : undefined,
      dataProtocols: this.dataProtocols,
      volumeType: this.volumeType,
      extendedStyle: this.extendedStyle,
    };
  }

  /**
   * Casts this.envSoure to NetappProtectionSource if the node environment matches.
   */
  get envSourceNetapp(): NetappProtectionSource {
    return this.environment === 'kNetapp' && this.envSource;
  }

  /**
   * Casts this.envSoure to NasProtectionSource if the node environment matches.
   */
  get envSourceGeneric(): NasProtectionSource {
    return this.environment === 'kGenericNas' && this.envSource;
  }

  /**
   * Casts this.envSoure to IsilonProtectionSource if the node environment matches.
   */
  get envSourceIsilon(): IsilonProtectionSource {
    return this.environment === 'kIsilon' && this.envSource;
  }

  /**
   * Casts this.envSoure to FlashBladeProtectionSource if the node environment matches.
   */
  get envSourceFlashBlade(): FlashBladeProtectionSource {
    return this.environment === 'kFlashBlade' && this.envSource;
  }

  /**
   * Casts this.envSoure to GpfsProtectionSource if the node environment matches.
   */
  get envSourceGpfs(): GpfsProtectionSource {
    return this.environment === 'kGPFS' && this.envSource;
  }

  /**
   * Casts this.envSoure to ElastifileProtectionSource if the node environment matches.
   */
  get envSourceElastifile(): ElastifileProtectionSource {
    return this.environment === 'kElastifile' && this.envSource;
  }

  /**
   * Gets the correct filesystem map for this node type.
   */
  get fileSystemMap(): FileSystemMap {
    return filesystemEnvMap[this.environment];
  }

  /**
   * Returns true if the ndoe supports SMB and not NFS. Or if mixed mode is
   * supported and either mixed mode volumes or smb volume is selected.
   */
  get hasSmbVolume(): boolean {
    return (this.supportSMB && flagEnabled(this.irisCtx.irisContext, 'nasMixedModeSupportEnabled') &&
      mixedModeSupportedAdapters.includes(this.environment)) ||
      (this.supportSMB && !this.supportNfS);
  }

  /**
   * Returns true if this is considered a leaf node.
   */
  get isLeaf(): boolean {
    return ['kVolume', 'kFileSystem', 'kHost', 'kMountPoint', 'kFileset', 'kContainer'].includes(this.type);
  }

  /**
   * If mixed mode support is enabled, mixed mode preference is not required.
   * mixedModeSettingRequired is true when at least one mode in selected
   * job node is either of kNetapp vServer type OR node support
   * both NFS and SMB.
   */
  get mixedModeSettingRequired(): boolean {
    return !(flagEnabled(this.irisCtx.irisContext, 'nasMixedModeSupportEnabled') &&
      mixedModeSupportedAdapters.includes(this.environment)) && (
      !!this.envSourceNetapp?.vserverInfo || (this.supportNfS && this.supportSMB)
    );
  }

  /**
   * Gets the supported file system types for this node.
   */
  get supportedFileSystems(): string[] {
    return supportedFilesystemsMap[this.environment];
  }

  /**
   * Returns true if the node supports nfs (kNfs/ kNfs3/ kNfs4_1).
   */
  get supportNfS(): boolean {
    return !!this.dataProtocols.filter(value =>
      [nasProtocols.kNfs, nasProtocols.kNfs3, nasProtocols.kNfs4_1].includes(value)).length;
  }

  /**
   * Returns true if the node supports nfs v4.1.
   */
  get supportNfS4(): boolean {
    return (this.envSourceNetapp || this.envSourceGeneric)
     && !!this.dataProtocols.filter(value => value === nasProtocols.kNfs4_1).length;
  }

  /**
   * Returns true if the node supports smb.
   */
  get supportSMB(): boolean {
    return !!this.dataProtocols.filter(value => this.fileSystemMap.smb.includes(value)).length;
  }

  /**
   * Returns Node Registration Info
   */
  get registrationInfo(): any {
    return this.data.registrationInfo;
  }

  /**
   * returns the supported data protocols list
   *
   * @param    node   The node
   * @return   Supported protocols list
   */
  @Memoize()
  get dataProtocols(): string[] {
    let protocolList = [];

    switch (this.envSource) {
      case this.envSourceNetapp:
        // We need to test all three levels of the hierarchy because the
        // filesystem protocol is defined at all levels.
        if (this.envSourceNetapp.volumeInfo) {
          protocolList = this.envSourceNetapp.volumeInfo.dataProtocols || [];
        }
        if (this.envSourceNetapp.vserverInfo) {
          protocolList = this.envSourceNetapp.vserverInfo.dataProtocols || [];
        }
        if (this.envSourceNetapp.clusterInfo) {
          // clusterInfo.dataProtocols is not defined in the api definition, so casting to
          // any for now to maintain the original check from public-job-service-formatter.js
          protocolList = (this.envSourceNetapp.clusterInfo as any).dataProtocols || [];
        }
        break;
      case this.envSourceGeneric:
        protocolList = [this.envSourceGeneric.protocol];
        break;
      case this.envSourceIsilon:
        // Protocol info only at mount point share level
        if (this.envSourceIsilon.mountPoint) {
          protocolList = this.envSourceIsilon.mountPoint.protocols || [];
        }
        break;
      case this.envSourceFlashBlade:
        if (this.envSourceFlashBlade.fileSystem) {
          protocolList = this.envSourceFlashBlade.fileSystem.protocols || [];
        }
        break;
      case this.envSourceGpfs:
        if (this.envSourceGpfs.fileset) {
          protocolList = this.envSourceGpfs.fileset.protocols || [];
        }
        break;
      case this.envSourceElastifile:
        if (this.envSourceElastifile.container) {
          protocolList = this.envSourceElastifile.container.protocols || [];
        }
        break;
    }

    // protocolList list contains some protocol that are not yet supported by
    // us for backup thus we need filter out those protocols.
    return protocolList.filter(protocol => this.supportedFileSystems.includes(protocol));
  }

  /**
   * Volume Type for a Netapp source.
   */
  get volumeType(): string {
    return get(this.envSourceNetapp, 'volumeInfo.type');
  }

  /**
   * Extended Style for a NetApp Source.
   */
  get extendedStyle(): string {
    return get(this.envSourceNetapp, 'volumeInfo.extendedStyle');
  }

  /**
   * Returns true if node is ineligible for auto select.
   */
  get ineligibleForAutoSelect(): boolean {
    // Blocking kDataProtection volume backup in DMaaS
    // TODO: remove when the same is supported in backend.
    if (this.isDmsScope && this.volumeType === 'kDataProtection') {
      return true;
    }
    return false;
  }

  /**
   * Auto select is enabled only for netapp cluster and vserver
   */
  canAutoSelect(): boolean {
    return this.environment === 'kNetapp' && this.type !== 'kVolume';
  }

  /**
   * Exclusion is disabled for netapp vserver and volumes
   */
  canExclude(): boolean {
    return this.environment === 'kNetapp' && this.type !== 'kCluster';
  }

  /**
   * A node can't be selected if its not a leaf node and singleSelect is enabled
   *
   * @return   True if this node can be selected.
   */
  canSelect(currentSelection, isSingleSelect: boolean): boolean {
    // Blocking kDataProtection volume backup in DMaaS
    // TODO: remove when the same is supported in backend.
    if (this.isDmsScope && this.volumeType === 'kDataProtection') {
      return false;
    }

    return !isSingleSelect || !this.expandable;
  }
}
