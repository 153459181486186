// Decorator: configure state provider to add cid (clusterId) and lockClusterId
// as an optional params to each and every state registered.
//
// The cid param is used to switch between clusters (change cluster context)
// when transitioning states (from global search for instance).
//
// The lockClusterId param is intended to be passed in on application load and
// will lock the application experience into a particular cluster. This is
// intended for use with iframe-based app version switching.

;(function(angular, undefined) {
  'use strict';

  angular.module('C.appConfigs')
    .config(stateProviderConfig);

  function stateProviderConfig($stateProvider, GLOBAL_STATE_PARAMS) {

    $stateProvider.decorator('url', decorateUrlFn);

    /**
     * Checks if a state is lazy loaded. if so, we should not decorate it.
     * UI Router runs a reg-ex against urls to determine whether a url might
     * belong to a lazy-loaded module. When we modify the url for all states, we
     * break this check. The solution is to check for lazy loaded states and
     * to make sure we don't modify them. Lazy loaded states are 'special' state
     * definitions that act as pointers to a more complete state definition.
     * Skipping these decorations on them will not hurt anything.
     *
     * @param   {object}   state   State Object
     * @return  {boolean}  true if the state is lazy loaded
     */
    function isLazyLoadedState(state) {
      return !!state.loadChildren;
    }

    /**
     * Adds additional parameters to the url for ALL registered states.
     *
     * @param  {object}  state   State object
     * @param  {function} orig   The original url function
     * @return {object} returns a
     * {@link ui.router.util.type:UrlMatcher UrlMatcher} or `null`.
     */
    function decorateUrlFn(state, orig) {
      // add global params only if state is the parent state or if state has absolute url
      if (isLazyLoadedState(state) || (/\./.test(state.self.name) && !/^\^/.test(state.self.url))) {
        return orig(state);
      }

      state.self.params = state.self.params || {};
      Object.assign(state.self.params, GLOBAL_STATE_PARAMS);

      const globalParamsInUrl =
        Object.keys(GLOBAL_STATE_PARAMS)
          .reduce((output, key) => {
            const paramDefinition = GLOBAL_STATE_PARAMS[key];
            const persistInUrl = paramDefinition._persistInUrl !== false;
            return output + (persistInUrl ? `{${key}}` : '');
          }, '');

      state.self.url = state.self.url +
        (hasQueryParam(state.self.url) ? '&' : '?') + globalParamsInUrl;

      return orig(state);
    }

    /**
     * Determines if the provided url contains query param or not.
     *
     * @examples
     * '/user/:id/?{domain:string}'                true
     * '/user/{id:[^/]*}'                          false
     * '/user/{mode}/{id:/?.*}'                    false
     * '/user/{mode}/{id:/?.*}?{domain:string}'    true
     *
     * @param  {string}   url   The url to test.
     * @return {Boolean}  Return True if url contains query param else false.
     */
    function hasQueryParam(url) {
      var balancedCurlyBrace = /\{[^\{]*\}/g;

      // removing all params inside curly brace since ui-router allows us to
      // define params regex expression inside that which may contain question
      // mark(?) character hence removing them before testing.
      return url.replace(balancedCurlyBrace, '').includes('?');
    }
  }

})(angular);
