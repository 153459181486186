import { Injectable } from '@angular/core';
import { Environment } from '@cohesity/iris-shared-constants';

import { IrisContextService } from '../iris-context';
import { AdaptorCanAccessFn, AdaptorCanAccessFnMap } from './adaptor-access.model';

@Injectable({
  providedIn: 'root',
})
export abstract class AdaptorAccessService {
  /**
   * Source access mapped based on datahawk features
   */
  abstract config: AdaptorCanAccessFnMap;

  /**
   * Default config to extend config
   */
  readonly defaultConfigFn: AdaptorCanAccessFn = () => false;

  /**
   * checks whether we can access certain environment
   */
  private canAccess(environment: Environment) {
    return this.config[environment](this.irisContextService.irisContext, environment);
  }

  /**
   * Gets list of supported sources
   */
  getSupportedEnvironments(): Environment[] {
    return (Object.keys(this.config) as Environment[]).filter(env => this.canAccess(env));
  }

  constructor(private irisContextService: IrisContextService) {}
}
