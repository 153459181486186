// MODULE: Job Details - Job Audit

var componentName = 'cJobDetailsProtectionAudit';
var configOptions = {
  controller: 'jobDetailsProtectionAuditCtrl',
  templateUrl: 'app/protection/jobs/details/job-audit.html',
  bindings: {
    /**
     * Page Config
     *
     * @param  pageConfig    routeParams and any other params required for
     *                       component init
     *                       {
     *                         jobId: '',
     *                       }
     * @type   {object}
     */
    pageConfig: '<',
  },
};
/**
 * @ngdoc module
 * @name        C.jobDetails
 * @controller jobDetailsProtectionAuditCtrl
 *
 * @description
 * Module for managing audit trails.
 *
 */
angular.module('C.jobDetails')
  .controller('jobDetailsProtectionAuditCtrl', JobDetailsProtectionAuditCtrlFn)
  .component(componentName, configOptions)
  .filter('customAuditSearchFilter', customAuditSearchFilter);

function JobDetailsProtectionAuditCtrlFn(_, $filter ,$translate, $scope,
  JobRunsService, ENUM_BACKUP_JOB_FIELD) {

    var $ctrl  = this;

    _.assign($ctrl, {
      // Life-Cycle Hooks.
      $onInit: onInit,

      fetching: false,
    });

  /**
   * Activate the controller.
   *
   * @method     onInit
   */
  function onInit() {
      getJobAudit();
  }

  /**
   * Get Job Audit Data
   *
   * @method getJobAudit
   */
  function getJobAudit() {
    $ctrl.fetching = true;
    // Get Job Audit data for the jobAudit table
    return JobRunsService.getJobAudit(+$ctrl.pageConfig.jobId).then(
      function getJobAuditSuccess(r) {
        // suppressing this error message, as it isn't friendly
        // for invalid / unknown id's and overwrites our cMessage above
        $ctrl.jobAudits = [];
        if (r.data.length) {
          $ctrl.jobAudits = r.data;
        }
        $ctrl.jobAudits = $ctrl.jobAudits.map(transformDataForDisplay);
        $ctrl.fetching = false;
      },
      undefined
    );
  }

  /**
   * Generates for the table to be displayed
   *
   * @method transformDataForDisplay
   *
   * @param {object} job The job from which the data is to be extracted.
   * @return {object} The object containing the extracted data.
   */
  function transformDataForDisplay(job) {
    return _.assign(job, {
      _lastModificationTimeUsecs: $filter('usecsToDate')(
        job.after.backupJob.lastModificationTimeUsecs),
      _lastUpdatedUsername: job.after.backupJob.lastUpdatedUsername,
      _description: transformJobDescription(job),
    });
  }

  /**
   * Returns strings based on change obejects from JobAudit API
   *
   * @method transformJobDescription
   *
   * @param  {object} job The job whose changes are to be evaluated.
   * @return {string} string of changes
   */
  function transformJobDescription(job) {
    var readableChanges = [];
    var i = 0;
    var len;
    if (job.hasOwnProperty('changes')) {
      len = job.changes.length;
      for (i; i < len; i++) {
        readableChanges.push(ENUM_BACKUP_JOB_FIELD[job.changes[i]]);
      }
      return readableChanges.join(", ");
    } else {
      if (job.hasOwnProperty('before') && job.before.
        hasOwnProperty('backupJob')) {
        return $translate.instant('protectionJobsDetailsJobAudit.noChanges');
      } else {
        return $translate.instant('protectionJobsDetailsJobAudit.firstEntry');
      }
    }
  }
}

/**
 * Filter for searching only a part of the displayed table.
 *
 * @method customAuditSearchFilter
 * @param {Object} $filter
 * @return {Object} Filter that filters certain properties.
 */
function customAuditSearchFilter($filter) {
  return function searchFilter(input, predicate) {
    var searchable = input.map(function searchableData(element) {
      return _.pick(element , [
        '_lastUpdatedUsername',
        '_lastModificationTimeUsecs',
        '_description',
      ]);
    });
    return $filter('filter')(searchable, predicate);
  };
}
