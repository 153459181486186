import { CdpObjectInfo } from '@cohesity/api/v2';

import { NoSqlSearchResult } from './nosql-search-results';
import { RestoreSearchResult } from './restore-search-result';


/**
 * This class groups multiple NoSql & Hadoop search results together into a single object that can be used
 * within a restore point selection. This assumes that all of the objects share the same source id, storage
 * domaind id and protection group.
 */
export class NoSqlSearchResultGroup implements RestoreSearchResult {
  /**
   * Static property for the result type. Which can be referenced by other classes.
   */
  static readonly searchResultType = 'objectGroup';

  // The below properties are implementations for the RestoreSearchResult interface.
  readonly environment: string;
  readonly id: string;
  readonly name = null;
  readonly objectId: number;
  readonly indexedObjectId: string;
  readonly objectName: string;
  readonly objectType: string;
  readonly sourceEnvironment: string;
  readonly protectionGroupId: string;
  readonly protectionGroupName: string;
  readonly restoreTimestampUsecs: number;
  readonly resultType: string;
  readonly sourceId: number;
  readonly parentSourceName: string;
  readonly storageDomainId: number;
  readonly cdpObjectInfo: CdpObjectInfo;
  readonly keyspaceType: string;

  constructor(readonly noSqlObjectSearchResults: NoSqlSearchResult[]) {
    const noSqlObject = noSqlObjectSearchResults[0];
    this.environment = noSqlObject.environment;
    this.id = noSqlObject.id;
    this.name = noSqlObject.name;
    this.objectId = noSqlObject.objectId;
    this.indexedObjectId = noSqlObject.indexedObjectId;
    this.objectName = noSqlObject.objectName;
    this.objectType = noSqlObject.objectType;
    this.protectionGroupId = noSqlObject.protectionGroupId;
    this.protectionGroupName = noSqlObject.protectionGroupName;
    this.restoreTimestampUsecs = noSqlObject.restoreTimestampUsecs;
    this.sourceEnvironment = noSqlObject.sourceEnvironment;
    this.sourceId = noSqlObject.sourceId;
    this.parentSourceName = noSqlObject.parentSourceName;
    this.storageDomainId = noSqlObject.storageDomainId;
    this.resultType = noSqlObject.resultType === 'object' ?
      NoSqlSearchResultGroup.searchResultType : noSqlObject.resultType;
    this.cdpObjectInfo = noSqlObject.cdpObjectInfo;
    this.keyspaceType = noSqlObject.keyspaceType;
  }
}
