<coh-form-field-object-selector
  cogDataId="cluster-interface"
  [control]="control"
  [label]="label || ('interfaceGroup' | translate)"
  [loading]="loading"
  [optionTemplate]="optionTemplate"
  [required]="required"
  [values]="vlans$ | async"
  [showNoneOption]="showNoneOption"
  optionKey="ifaceGroupName">
</coh-form-field-object-selector>

<ng-template #optionTemplate
  let-value>
  <strong>{{value?.ifaceGroupName}}</strong>
  <div class="metadata">
    <ng-container *ngIf="value?.subnet?._CIDR">
      {{ 'subnetColon' | translate }} {{ ' ' + value.subnet._CIDR }}
    </ng-container>
  </div>
</ng-template>
