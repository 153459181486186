import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const auditViewAccess = (ctx: StateAccessContext) => ctx.CLUSTER_AUDIT && ctx.FEATURE_FLAGS['ngAuditLogsEnabled']
  && ctx.FEATURE_FLAGS['auditLogsEnabled'];
const auditFilerAccess = (ctx: StateAccessContext) => ctx.CLUSTER_AUDIT && ctx.FEATURE_FLAGS['ngAuditLogsEnabled']
  && ctx.FEATURE_FLAGS['auditLogsEnabled'] && !ctx.isTenantUser;
const auditModifyAccess = (ctx: StateAccessContext) => ctx.CLUSTER_MODIFY && ctx.FEATURE_FLAGS['ngAuditLogsEnabled']
  && ctx.FEATURE_FLAGS['auditLogsEnabled'] && !ctx.isTenantUser;

export const AuditLogsConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'ng-audit-logs.**',
      url: '^/monitoring/audit',
      loadChildren: () => import('./audit-logs.module').then(m => m.AuditLogsModule)
    },
  ],
  allClusterMap: {
    'ng-audit-logs': false,
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'ng-audit-logs': auditViewAccess,
      'ng-audit-logs.cluster': auditViewAccess,
      'ng-audit-logs.file-system': auditFilerAccess,
      'ng-audit-logs.settings': auditModifyAccess,
    };
  }
};
