// Generated from libs/grammar/gcp-volume-exclusion/Gcp.g4 by ANTLR 4.9.0-SNAPSHOT


import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { CharStream } from "antlr4ts/CharStream";
import { Lexer } from "antlr4ts/Lexer";
import { LexerATNSimulator } from "antlr4ts/atn/LexerATNSimulator";
import { NotNull } from "antlr4ts/Decorators";
import { Override } from "antlr4ts/Decorators";
import { RuleContext } from "antlr4ts/RuleContext";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";


export class GcpLexer extends Lexer {
	public static readonly OPEN_PAREN = 1;
	public static readonly CLOSE_PAREN = 2;
	public static readonly K_AND = 3;
	public static readonly K_OR = 4;
	public static readonly EQ = 5;
	public static readonly CONTAINS = 6;
	public static readonly IDENTIFIER = 7;
	public static readonly SPACES = 8;

	// tslint:disable:no-trailing-whitespace
	public static readonly channelNames: string[] = [
		"DEFAULT_TOKEN_CHANNEL", "HIDDEN",
	];

	// tslint:disable:no-trailing-whitespace
	public static readonly modeNames: string[] = [
		"DEFAULT_MODE",
	];

	public static readonly ruleNames: string[] = [
		"OPEN_PAREN", "CLOSE_PAREN", "K_AND", "K_OR", "EQ", "CONTAINS", "IDENTIFIER",
		"SPACES", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L",
		"M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, "'('", "')'", undefined, undefined, "'='", "'~'",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, "OPEN_PAREN", "CLOSE_PAREN", "K_AND", "K_OR", "EQ", "CONTAINS",
		"IDENTIFIER", "SPACES",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(GcpLexer._LITERAL_NAMES, GcpLexer._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return GcpLexer.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace


	constructor(input: CharStream) {
		super(input);
		this._interp = new LexerATNSimulator(GcpLexer._ATN, this);
	}

	// @Override
	public get grammarFileName(): string { return "Gcp.g4"; }

	// @Override
	public get ruleNames(): string[] { return GcpLexer.ruleNames; }

	// @Override
	public get serializedATN(): string { return GcpLexer._serializedATN; }

	// @Override
	public get channelNames(): string[] { return GcpLexer.channelNames; }

	// @Override
	public get modeNames(): string[] { return GcpLexer.modeNames; }

	public static readonly _serializedATN: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x02\n\xDD\b\x01\x04" +
		"\x02\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06\x04" +
		"\x07\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r\t\r" +
		"\x04\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x04\x11\t\x11\x04\x12\t\x12" +
		"\x04\x13\t\x13\x04\x14\t\x14\x04\x15\t\x15\x04\x16\t\x16\x04\x17\t\x17" +
		"\x04\x18\t\x18\x04\x19\t\x19\x04\x1A\t\x1A\x04\x1B\t\x1B\x04\x1C\t\x1C" +
		"\x04\x1D\t\x1D\x04\x1E\t\x1E\x04\x1F\t\x1F\x04 \t \x04!\t!\x04\"\t\"\x04" +
		"#\t#\x03\x02\x03\x02\x03\x03\x03\x03\x03\x04\x03\x04\x03\x04\x03\x04\x03" +
		"\x05\x03\x05\x03\x05\x03\x06\x03\x06\x03\x07\x03\x07\x03\b\x03\b\x03\b" +
		"\x07\bZ\n\b\f\b\x0E\b]\v\b\x03\b\x03\b\x06\ba\n\b\r\b\x0E\bb\x07\be\n" +
		"\b\f\b\x0E\bh\v\b\x03\b\x03\b\x03\b\x03\b\x07\bn\n\b\f\b\x0E\bq\v\b\x03" +
		"\b\x03\b\x06\bu\n\b\r\b\x0E\bv\x07\by\n\b\f\b\x0E\b|\v\b\x03\b\x03\b\x03" +
		"\b\x03\b\x07\b\x82\n\b\f\b\x0E\b\x85\v\b\x03\b\x03\b\x06\b\x89\n\b\r\b" +
		"\x0E\b\x8A\x07\b\x8D\n\b\f\b\x0E\b\x90\v\b\x03\b\x03\b\x03\b\x07\b\x95" +
		"\n\b\f\b\x0E\b\x98\v\b\x03\b\x03\b\x07\b\x9C\n\b\f\b\x0E\b\x9F\v\b\x03" +
		"\b\x05\b\xA2\n\b\x05\b\xA4\n\b\x03\t\x03\t\x03\t\x03\t\x03\n\x03\n\x03" +
		"\v\x03\v\x03\f\x03\f\x03\r\x03\r\x03\x0E\x03\x0E\x03\x0F\x03\x0F\x03\x10" +
		"\x03\x10\x03\x11\x03\x11\x03\x12\x03\x12\x03\x13\x03\x13\x03\x14\x03\x14" +
		"\x03\x15\x03\x15\x03\x16\x03\x16\x03\x17\x03\x17\x03\x18\x03\x18\x03\x19" +
		"\x03\x19\x03\x1A\x03\x1A\x03\x1B\x03\x1B\x03\x1C\x03\x1C\x03\x1D\x03\x1D" +
		"\x03\x1E\x03\x1E\x03\x1F\x03\x1F\x03 \x03 \x03!\x03!\x03\"\x03\"\x03#" +
		"\x03#\x02\x02\x02$\x03\x02\x03\x05\x02\x04\x07\x02\x05\t\x02\x06\v\x02" +
		"\x07\r\x02\b\x0F\x02\t\x11\x02\n\x13\x02\x02\x15\x02\x02\x17\x02\x02\x19" +
		"\x02\x02\x1B\x02\x02\x1D\x02\x02\x1F\x02\x02!\x02\x02#\x02\x02%\x02\x02" +
		"\'\x02\x02)\x02\x02+\x02\x02-\x02\x02/\x02\x021\x02\x023\x02\x025\x02" +
		"\x027\x02\x029\x02\x02;\x02\x02=\x02\x02?\x02\x02A\x02\x02C\x02\x02E\x02" +
		"\x02\x03\x02#\x06\x022;C\\aac|\b\x02--/<??B\\aac|\x07\x02--/<B\\aac|\x03" +
		"\x02c|\x05\x02//2;c|\x04\x022;c|\x05\x02\v\r\x0F\x0F\"\"\x04\x02CCcc\x04" +
		"\x02DDdd\x04\x02EEee\x04\x02FFff\x04\x02GGgg\x04\x02HHhh\x04\x02IIii\x04" +
		"\x02JJjj\x04\x02KKkk\x04\x02LLll\x04\x02MMmm\x04\x02NNnn\x04\x02OOoo\x04" +
		"\x02PPpp\x04\x02QQqq\x04\x02RRrr\x04\x02SSss\x04\x02TTtt\x04\x02UUuu\x04" +
		"\x02VVvv\x04\x02WWww\x04\x02XXxx\x04\x02YYyy\x04\x02ZZzz\x04\x02[[{{\x04" +
		"\x02\\\\||\x02\xD2\x02\x03\x03\x02\x02\x02\x02\x05\x03\x02\x02\x02\x02" +
		"\x07\x03\x02\x02\x02\x02\t\x03\x02\x02\x02\x02\v\x03\x02\x02\x02\x02\r" +
		"\x03\x02\x02\x02\x02\x0F\x03\x02\x02\x02\x02\x11\x03\x02\x02\x02\x03G" +
		"\x03\x02\x02\x02\x05I\x03\x02\x02\x02\x07K\x03\x02\x02\x02\tO\x03\x02" +
		"\x02\x02\vR\x03\x02\x02\x02\rT\x03\x02\x02\x02\x0F\xA3\x03\x02\x02\x02" +
		"\x11\xA5\x03\x02\x02\x02\x13\xA9\x03\x02\x02\x02\x15\xAB\x03\x02\x02\x02" +
		"\x17\xAD\x03\x02\x02\x02\x19\xAF\x03\x02\x02\x02\x1B\xB1\x03\x02\x02\x02" +
		"\x1D\xB3\x03\x02\x02\x02\x1F\xB5\x03\x02\x02\x02!\xB7\x03\x02\x02\x02" +
		"#\xB9\x03\x02\x02\x02%\xBB\x03\x02\x02\x02\'\xBD\x03\x02\x02\x02)\xBF" +
		"\x03\x02\x02\x02+\xC1\x03\x02\x02\x02-\xC3\x03\x02\x02\x02/\xC5\x03\x02" +
		"\x02\x021\xC7\x03\x02\x02\x023\xC9\x03\x02\x02\x025\xCB\x03\x02\x02\x02" +
		"7\xCD\x03\x02\x02\x029\xCF\x03\x02\x02\x02;\xD1\x03\x02\x02\x02=\xD3\x03" +
		"\x02\x02\x02?\xD5\x03\x02\x02\x02A\xD7\x03\x02\x02\x02C\xD9\x03\x02\x02" +
		"\x02E\xDB\x03\x02\x02\x02GH\x07*\x02\x02H\x04\x03\x02\x02\x02IJ\x07+\x02" +
		"\x02J\x06\x03\x02\x02\x02KL\x05\x13\n\x02LM\x05-\x17\x02MN\x05\x19\r\x02" +
		"N\b\x03\x02\x02\x02OP\x05/\x18\x02PQ\x055\x1B\x02Q\n\x03\x02\x02\x02R" +
		"S\x07?\x02\x02S\f\x03\x02\x02\x02TU\x07\x80\x02\x02U\x0E\x03\x02\x02\x02" +
		"VW\x07$\x02\x02W[\t\x02\x02\x02XZ\t\x03\x02\x02YX\x03\x02\x02\x02Z]\x03" +
		"\x02\x02\x02[Y\x03\x02\x02\x02[\\\x03\x02\x02\x02\\f\x03\x02\x02\x02]" +
		"[\x03\x02\x02\x02^`\x07\"\x02\x02_a\t\x03\x02\x02`_\x03\x02\x02\x02ab" +
		"\x03\x02\x02\x02b`\x03\x02\x02\x02bc\x03\x02\x02\x02ce\x03\x02\x02\x02" +
		"d^\x03\x02\x02\x02eh\x03\x02\x02\x02fd\x03\x02\x02\x02fg\x03\x02\x02\x02" +
		"gi\x03\x02\x02\x02hf\x03\x02\x02\x02i\xA4\x07$\x02\x02jk\x07b\x02\x02" +
		"ko\t\x02\x02\x02ln\t\x03\x02\x02ml\x03\x02\x02\x02nq\x03\x02\x02\x02o" +
		"m\x03\x02\x02\x02op\x03\x02\x02\x02pz\x03\x02\x02\x02qo\x03\x02\x02\x02" +
		"rt\x07\"\x02\x02su\t\x03\x02\x02ts\x03\x02\x02\x02uv\x03\x02\x02\x02v" +
		"t\x03\x02\x02\x02vw\x03\x02\x02\x02wy\x03\x02\x02\x02xr\x03\x02\x02\x02" +
		"y|\x03\x02\x02\x02zx\x03\x02\x02\x02z{\x03\x02\x02\x02{}\x03\x02\x02\x02" +
		"|z\x03\x02\x02\x02}\xA4\x07b\x02\x02~\x7F\x07)\x02\x02\x7F\x83\t\x02\x02" +
		"\x02\x80\x82\t\x03\x02\x02\x81\x80\x03\x02\x02\x02\x82\x85\x03\x02\x02" +
		"\x02\x83\x81\x03\x02\x02\x02\x83\x84\x03\x02\x02\x02\x84\x8E\x03\x02\x02" +
		"\x02\x85\x83\x03\x02\x02\x02\x86\x88\x07\"\x02\x02\x87\x89\t\x03\x02\x02" +
		"\x88\x87\x03\x02\x02\x02\x89\x8A\x03\x02\x02\x02\x8A\x88\x03\x02\x02\x02" +
		"\x8A\x8B\x03\x02\x02\x02\x8B\x8D\x03\x02\x02\x02\x8C\x86\x03\x02\x02\x02" +
		"\x8D\x90\x03\x02\x02\x02\x8E\x8C\x03\x02\x02\x02\x8E\x8F\x03\x02\x02\x02" +
		"\x8F\x91\x03\x02\x02\x02\x90\x8E\x03\x02\x02\x02\x91\xA4\x07)\x02\x02" +
		"\x92\x96\t\x02\x02\x02\x93\x95\t\x04\x02\x02\x94\x93\x03\x02\x02\x02\x95" +
		"\x98\x03\x02\x02\x02\x96\x94\x03\x02\x02\x02\x96\x97\x03\x02\x02\x02\x97" +
		"\xA4\x03\x02\x02\x02\x98\x96\x03\x02\x02\x02\x99\xA1\t\x05\x02\x02\x9A" +
		"\x9C\t\x06\x02\x02\x9B\x9A\x03\x02\x02\x02\x9C\x9F\x03\x02\x02\x02\x9D" +
		"\x9B\x03\x02\x02\x02\x9D\x9E\x03\x02\x02\x02\x9E\xA0\x03\x02\x02\x02\x9F" +
		"\x9D\x03\x02\x02\x02\xA0\xA2\t\x07\x02\x02\xA1\x9D\x03\x02\x02\x02\xA1" +
		"\xA2\x03\x02\x02\x02\xA2\xA4\x03\x02\x02\x02\xA3V\x03\x02\x02\x02\xA3" +
		"j\x03\x02\x02\x02\xA3~\x03\x02\x02\x02\xA3\x92\x03\x02\x02\x02\xA3\x99" +
		"\x03\x02\x02\x02\xA4\x10\x03\x02\x02\x02\xA5\xA6\t\b\x02\x02\xA6\xA7\x03" +
		"\x02\x02\x02\xA7\xA8\b\t\x02\x02\xA8\x12\x03\x02\x02\x02\xA9\xAA\t\t\x02" +
		"\x02\xAA\x14\x03\x02\x02\x02\xAB\xAC\t\n\x02\x02\xAC\x16\x03\x02\x02\x02" +
		"\xAD\xAE\t\v\x02\x02\xAE\x18\x03\x02\x02\x02\xAF\xB0\t\f\x02\x02\xB0\x1A" +
		"\x03\x02\x02\x02\xB1\xB2\t\r\x02\x02\xB2\x1C\x03\x02\x02\x02\xB3\xB4\t" +
		"\x0E\x02\x02\xB4\x1E\x03\x02\x02\x02\xB5\xB6\t\x0F\x02\x02\xB6 \x03\x02" +
		"\x02\x02\xB7\xB8\t\x10\x02\x02\xB8\"\x03\x02\x02\x02\xB9\xBA\t\x11\x02" +
		"\x02\xBA$\x03\x02\x02\x02\xBB\xBC\t\x12\x02\x02\xBC&\x03\x02\x02\x02\xBD" +
		"\xBE\t\x13\x02\x02\xBE(\x03\x02\x02\x02\xBF\xC0\t\x14\x02\x02\xC0*\x03" +
		"\x02\x02\x02\xC1\xC2\t\x15\x02\x02\xC2,\x03\x02\x02\x02\xC3\xC4\t\x16" +
		"\x02\x02\xC4.\x03\x02\x02\x02\xC5\xC6\t\x17\x02\x02\xC60\x03\x02\x02\x02" +
		"\xC7\xC8\t\x18\x02\x02\xC82\x03\x02\x02\x02\xC9\xCA\t\x19\x02\x02\xCA" +
		"4\x03\x02\x02\x02\xCB\xCC\t\x1A\x02\x02\xCC6\x03\x02\x02\x02\xCD\xCE\t" +
		"\x1B\x02\x02\xCE8\x03\x02\x02\x02\xCF\xD0\t\x1C\x02\x02\xD0:\x03\x02\x02" +
		"\x02\xD1\xD2\t\x1D\x02\x02\xD2<\x03\x02\x02\x02\xD3\xD4\t\x1E\x02\x02" +
		"\xD4>\x03\x02\x02\x02\xD5\xD6\t\x1F\x02\x02\xD6@\x03\x02\x02\x02\xD7\xD8" +
		"\t \x02\x02\xD8B\x03\x02\x02\x02\xD9\xDA\t!\x02\x02\xDAD\x03\x02\x02\x02" +
		"\xDB\xDC\t\"\x02\x02\xDCF\x03\x02\x02\x02\x10\x02[bfovz\x83\x8A\x8E\x96" +
		"\x9D\xA1\xA3\x03\b\x02\x02";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!GcpLexer.__ATN) {
			GcpLexer.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(GcpLexer._serializedATN));
		}

		return GcpLexer.__ATN;
	}

}

