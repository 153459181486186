<!--
  Provide an actual H1 for the page. If the dropdown/select is enabled then
  apply a visuallyhidden class so its exclusively for screen readers. If
  the select is not enabled (there is only one item) then this h1 should
  be displayed for all user.
-->
<h1 class="no-margin"
  [class.visuallyhidden]="isSelectEnabled()">
  {{currentItem?.displayName | translate}}
</h1>

<ng-container *ngIf="isSelectEnabled()">
  <div #selector
    [attr.id]="titleId"
    class="board-select-title cog-h1 no-margin"
    [class.board-select-open]="!!menuTrigger?.menuOpen"
    [matMenuTriggerFor]="dashboardSelectMenu"
    (keyup.enter)="onEnterKeyPress()"
    role="button"
    tabindex="0">
    <span>
      {{currentItem?.displayName | translate}}
    </span>
    <cog-icon shape="expand_more!outline" size="md"></cog-icon>
  </div>

  <mat-menu class="title-selector-panel" #dashboardSelectMenu="matMenu" xPosition="after">
    <button
      mat-menu-item
      *ngFor="let item of navItems"
      id="{{item.displayName}}-nav-anchor"
      [cogDataId]="item.displayName + 'title-selector-option'"
      class="board-select-link"
      [class.board-select-link-active]="isItemActive(item)"
      [uiSref]="!item.disabled ? item.state : ''"
      [uiParams]="item.stateParams"
      [uiOptions]="item.stateOpts"
      (click)="$event.stopPropagation(); handleClick(item)">
      <cog-icon *ngIf="isItemActive(item)" shape="check"></cog-icon>
      <span>{{item.displayName | translate}}</span>
    </button>
  </mat-menu>
</ng-container>
