// Directive: Dynamic Form generation directive.
(function (angular, undefined) {
  'use strict';

  var dirName = 'appDynamicForm';

  angular.module('C.appsManagement').directive(dirName, appDynamicFormFn);

  /**
     * @ngdoc directive
     * @name C.appsManagement:appDynamicForm
     * @function
     *
     * @description
     *
     * Simple directive to prepare dynamic form to generate form elements
     * @example
       <app-dynamic-form
              onCheckboxChecked="$ctrl.onCheckboxChange(message)"
              element-name="username"
              element-id="userid"
              form-element="formElement"
              ng-model="user.name">
       </app-dynamic-form>
    */
  function appDynamicFormFn() {
    return {
      link: linkFn,
      scope: {
        // Form element object
        formElement: '=',

        // ng-model for form element
        ngModel: '=',

        // callback function to call on checkbox checked events
        onCheckboxChecked: '&',

        // Name for form element
        elementName: '@',

        // Id for form element
        elementId: '@',

        // boolean attribute to identify whether form element is child of array of
        // form element or not. If true, provided form element is child of array,
        // false otherwise
        isChildElement: '@',
      },
      restrict: 'EA',
      require: ['^^form'],
      replace: true,
      templateUrl: 'app/apps-management/app-launch/app-dynamic-form.html',
    };

    /**
     * Angular Directive Link Fn.
     *
     * @method   linkFn
     */
    function linkFn(scope, element, attrs, ctrls) {
      scope.formName = ctrls[0].$name;

      /**
       * Function to handle checkbox checked events
       * @param {object} formElement checked form element
       */
      scope.checkboxChecked = function (formElement) {
        scope.onCheckboxChecked({ message: formElement });
       };
    }
  }
})(angular);