// Component: View snmp component

;(function(angular, undefined) {
  'use strict';

  angular.module('C.snmp')
    .component('viewSnmp', {
      templateUrl: 'app/admin/snmp/view.html',
      controller: viewSnmpCtrlFn,
    });

  function viewSnmpCtrlFn(_, $window, $rootScope, evalAJAX, SNMP_CONST,
    SNMPService, API, RemoteAccessClusterService) {

    var $ctrl = this;

    var pageActions = [{
      stateName: 'snmp-modify',
      disabled: !$rootScope.user.privs.CLUSTER_MODIFY,
      displayKey: 'edit',
      icon: 'icn-edit',
    }];

    _.assign($ctrl, {
      // Constant
      SNMP_CONST: SNMP_CONST,

      // UI state
      pageActions: pageActions,
      state: SNMPService.state,

      // Other methods
      getMibFile: getMibFile,

      // Lifecycle methods
      $onInit: $onInit,
    });

    /**
     * Fetch snmp config
     *
     * @method    fetchConfig
     */
    function fetchConfig() {
      SNMPService.fetchConfig()
        .catch(evalAJAX.errorMessage);
    }

    /**
     * Downloads the SNMP mibs file.
     *
     * @method     getMibFile
     */
    function getMibFile() {
      $window.open(
        RemoteAccessClusterService.decorateApiPathForRemote(
          API.private('snmp/mibsFile')
        )
      );
    }

    /**
     * Initialize controller
     *
     * @method   $onInit
     */
    function $onInit() {
      fetchConfig();
    }
  }
})(angular);
