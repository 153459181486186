import { Component } from '@angular/core';
import { AgentInformation, ProtectionSourceNode, ProtectionSourcesServiceApi, RegisteredSourceInfo } from '@cohesity/api/v1';
import { PopoverRef } from '@cohesity/helix';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';

import { SourceKeys } from '../../constants';

@Component({
  selector: 'coh-hypervisor-popover',
  templateUrl: './hypervisor-popover.component.html',
  styleUrls: ['./hypervisor-popover.component.scss'],
})
export class HypervisorPopoverComponent {

  /**
   * Return true if feature flag for VMC is turned on.
   */
  readonly vmwareVMCFlag = flagEnabled(this.irisCtx.irisContext, 'vmwareVMC');

  /**
   * Access to the current node property.
   */
  get node(): ProtectionSourceNode {
    return this.popoverRef.data;
  }

  /**
   * Access to the Hypervisor Protection Source object.
   */
  get hypervisorSource(): any {
    const key = SourceKeys[this.node.protectionSource.environment];
    return this.node.protectionSource[key];
  }

  /**
   * Return the registered agent if any.
   */
  get agent(): AgentInformation | null {
    if (this.node.protectionSource.environment !== 'kKVM' && this.hypervisorSource.agents) {
      return this.hypervisorSource.agents[0];
    }
    return null;
  }

  /**
   * Return the registerationInfo of the source.
   */
  get registrationInfo(): RegisteredSourceInfo {
    return this.node.registrationInfo;
  }

  /**
   * Get applied tags if there are any
   */
  get tags(): string[] {
    return this.hypervisorSource?.tagAttributes?.map(tag => tag.name.replace('#~#', ': '));
  }

  /**
   * Checks whether the node uses a persistent agent.
   */
  get isCohesityAgentInstalled(): boolean {
    return !!(this.hypervisorSource as any)?.hasPersistentAgent;
  }

  /**
   * Checks if this node has a sql application registered
   */
  get isSqlHost(): boolean {
    return !!(this.node.registrationInfo?.environments || []).find(env => env === 'kSQL');
  }


  /**
   * Checks whether the node is a SaaS Connector or not.
   */
  get isSaasConnector(): boolean {
    return this.hypervisorSource.isSaasConnector;
  }


  /**
   * Returns true if the object is migrated.
   */
  get isVcenterMigrated(): boolean {
    return (this.node.protectionSource.vmWareProtectionSource as any)?.vmLinkingInfo?.isMigrated;
  }

  /**
   * Get the UUID, if there is one.
   */
  get uuid(): string {
    return this.hypervisorSource?.id?.uuid;
  }


  /**
   * Returns the time of migrations.
   */
  get vmMigrationUsecs(): number {
    return (this.node.protectionSource.vmWareProtectionSource as any)?.vmLinkingInfo?.migratedTimeUsecs;
  }

  /**
   * Whether node is protected or not
   */
  isNodeProtected: boolean;

  /**
   * Node type.
   */
  type: string;

  /**
   * If the API is fetching the data.
   */
  vcenterIsLoading = true;

  /**
   *  The name of vcenter.
   */
  migratedVcenter: string;

  /**
   * Constructor.
   */
  constructor(
    private popoverRef: PopoverRef<ProtectionSourceNode>,
    private translate: TranslateService,
    private protectionSourcesService: ProtectionSourcesServiceApi,
    readonly irisCtx: IrisContextService,
  ) {
    const protectionSummary = this.node.protectedSourcesSummary;
    let vcenterId;

    this.isNodeProtected = !!(protectionSummary &&
      protectionSummary[0].leavesCount && protectionSummary[0].leavesCount > 0);
    this.type = this.translate.instant(`enum.sourceType.kVMware.${this.hypervisorSource.type}`);

    if (!this.popoverRef || !this.popoverRef.data) {
      throw new Error('Popover data is not defined');
    }

    if (this.isVcenterMigrated) {
      vcenterId = (this.node.protectionSource.vmWareProtectionSource as any).vmLinkingInfo.previousVmParentSourceId;
      this.protectionSourcesService
        .ListProtectionSources({ id: vcenterId })
        .pipe(finalize(() => this.vcenterIsLoading = false))
        .subscribe((sources: ProtectionSourceNode[]) => {
          this.migratedVcenter = sources[0].protectionSource.name;
        });
    }
  }

  /**
   * Returns whether VMWare node has popover or not based on node specific logic
   *
   * @param    node Protection source node
   * @returns  true/false whether node has popover or not
   */
  static hasPopover(node: ProtectionSourceNode): boolean {
    const key = SourceKeys[node.protectionSource.environment];

    return [
      'kVirtualMachine',
      'kHostSystem',
      'kVCenter',
      'kvCloudDirector',
      'kStandaloneHost',
      'kHyperV',
      'kSCVMMServer',
      'kOVirtManager',
      'kEC2Instance',
      'kS3Bucket'

    ].includes(node.protectionSource[key].type);
  }
}
