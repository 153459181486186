import { AppModuleConfig, StateAccessMap, StateAccessContext } from 'src/app/app-module.config';

const abacViewAccess = (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.abacModule && ctx.S3_ABAC_VIEW;
const abacModifyAccess = (ctx: StateAccessContext) => ctx.FEATURE_FLAGS.abacModule && ctx.S3_ABAC_MODIFY;

export const AbacConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'access-management.abac.**',
      url: '^/admin/access/abac',
      loadChildren: () => import('./abac.module').then(m => m.AbacModule),
    },
    {
      name: 'abac-add.**',
      url: '^/access/abac/add',
      loadChildren: () => import('./abac.module').then(m => m.AbacModule),
    },
    {
      name: 'abac-edit.**',
      url: '^/access/abac/edit/{id}',
      loadChildren: () => import('./abac.module').then(m => m.AbacModule),
    },
  ],

  getStateAccessMap(): StateAccessMap {
    return {
      'access-management.abac': abacViewAccess,
      'abac-add': abacModifyAccess,
      'abac-edit': abacModifyAccess,
    };
  },
};
