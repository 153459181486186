import { DataTreeTransformer } from '@cohesity/helix';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { ProtectionSourceDataNode } from './protection-source-data-node';

/**
 * This class implements the DataTreeTransformer interface and contains the callback
 * methods used by mat data tree to interact with the node.
 */
export class ProtectionSourceDataTransformer<T extends ProtectionSourceDataNode>
  implements DataTreeTransformer<ProtectionSourceNode> {
  constructor(private transformFn: (structuredNode: ProtectionSourceNode, level: number) => T) {}

  /**
   * Gets the source node's children. This can be either child nodes, or application nodes.
   *
   * @param   sourceNode   The source node.
   * @return  The node's children.
   */
  getChildren(sourceNode: ProtectionSourceNode): ProtectionSourceNode[] {
    return (sourceNode.nodes || sourceNode.applicationNodes) as T[];
  }

  /**
   * Retrieves the node's level in the tree.
   *
   * @param   treeNode   The node entry in the flattened tree.
   * @return  The node's level.
   */
  getLevel(treeNode: T): number {
    return treeNode.level;
  }

  /**
   * Returns true if the ndoe can be expanded.
   *
   * @param   treeNode   The node entry in the flattened tree.
   * @return  True if the node can be expanded.
   */
  getExpandable(treeNode: T): boolean {
    return treeNode.expandable;
  }

  /**
   * This should be implemented separately for each adapter to provide adapater-specific configuration.
   *
   * @param   sourceNode  The source node.
   * @param   level       The node's deptch within the tree.
   * @return  A transformed data tree node.
   */
  transformData(sourceNode: ProtectionSourceNode, level: number): T {
    return this.transformFn(sourceNode, level);
  }
}
