import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatternFilterComponent } from './pattern-filter.component';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';

const COMPONENTS = [PatternFilterComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CohesityHelixModule, CommonModule, TranslateModule],
  exports: [...COMPONENTS],
})
export class PatternFilterModule {}
