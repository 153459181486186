// Component: modify tenant component
import { isIbmBaaSEnabled } from '@cohesity/iris-core';

;(function(angular, undefined) {
  'use strict';

  var modName = 'C.tenants';
  var componentName = 'modifyTenant';

  var configOptions = {
    bindings: {
      /**
       * Optional provide the tenant ID to view or edit else allow create tenant
       *
       * @type  {String}  [tenantId=undefined]
       */
      tenantId: '<?',

      /**
       * Optional provide the inEditMode flag and if true then allow tenant
       * properties editing
       *
       * @type  {Boolean}  [inEditMode=undefined]
       */
      inEditMode: '<?',

      /**
       * Optional provide the inViewMode flag and if true then show tenant
       * properties only.
       *
       * @type  {Boolean}  [inViewMode=undefined]
       */
      inViewMode: '<?',

      /**
       * Computed internally if !inEditMode && !inViewMode then allow new tenant
       * addition in the system.
       *
       * @type  {Boolean}  [inViewMode=!inEditMode && !inViewMode]
       */
      // inCreateMode: '?',
    },
    controller: 'ModifyTenantCtrlFn',
    templateUrl: 'app/admin/access-management/tenants/modify/modify.html',
  };

  angular.module(modName)
    .controller('ModifyTenantCtrlFn', modifyTenantCtrlFn)
    .component(componentName, configOptions);

  function modifyTenantCtrlFn(_, $rootScope, evalAJAX, $state, cMessage, cModal,
    TenantService, UserService, $q, FORMATS, VlanService, NgProtectionPolicyService,
    NgIrisContextService,
    ViewService, PubSourceService, PubJobServiceFormatter, $translate,
    StateManagementService, FEATURE_FLAGS, cUtils, ngDialogService) {

    var $ctrl = this;
    var hyxClusterVersionMismatch = false;
    var tenantDetailsProperties = [
      'active',
      'bifrostEnabled',
      'clusterHostname',
      'clusterIps',
      'description',
      'name',
      'tenantId',
    ];

    // declare component methods
    _.assign($ctrl, {
      addBifrostProxyModal: addBifrostProxyModal,
      addNewView: addNewView,
      assignAdLdap: assignAdLdap,
      assignPoliciesAndProtectionJobs: assignPoliciesAndProtectionJobs,
      assignPrincipals: assignPrincipals,
      assignSources: assignSources,
      assignSwiftRoles: assignSwiftRoles,
      assignVlans: assignVlans,
      filterViewBoxes: filterViewBoxes,
      filterViewsForViewBoxes: filterEntityForViewBoxes,
      getPrincipalUnassignmentReason: getPrincipalUnassignmentReason,
      goBack: goBack,
      isLoadingProperties: isLoadingProperties,
      markPolicyForRemoval: markPolicyForRemoval,
      modifyPrincipal: modifyPrincipal,
      onTenantNetworkChange: onTenantNetworkChange,
      onTenantViewBoxesChange: onTenantViewBoxesChange,
      removeTenant: removeTenant,
      saveSetting: saveSetting,
      toggleTenantStatus: toggleTenantStatus,
      uiSelectIPValidator: cUtils.uiSelectIPValidator,
      decorateSourceAsync: decorateSourceAsync,

      // component life cycle methods
      $onInit: $onInit,
    });

    /**
     * Navigates to previous router state.
     */
    function goBack() {
      StateManagementService.goToPreviousState('access-management.tenants');
    }

    /**
     * initialize component context
     *
     * @method   initializeCtrl
     */
    function initializeCtrl() {
      $ctrl.inEditMode = !!$ctrl.inEditMode;
      $ctrl.inViewMode = !!$ctrl.inViewMode;
      $ctrl.inCreateMode = !$ctrl.inEditMode && !$ctrl.inViewMode;

      _.assign($ctrl, {
        // UI states
        pageLoading: false,
        submitting: false,
        showAdLdapKeystoneWindow: false,
        FORMATS: FORMATS,
        isLoading: {
          page: false,
          views: false,
          sources: false,
          policies: false,
          vlans: false,
        },

        tenant: getDefaultTenantInfo(),
        views: [],
        maxVisiblePrincipals: 4,
      });
    }

    /**
     * Gets the default tenant information.
     *
     * @method   getDefaultTenantInfo
     * @return   {Object}   The default tenant information.
     */
    function getDefaultTenantInfo() {
      var defaultInfo = TenantService.transformTenant({});
      var uiProperties = {
        _ads: [],
        _assignedDomainNamesSet: new Set(),
        _details: {},
        _entityIds: [],
        _jobs: [],
        _ldaps: [],
        _policies: [],
        _proxies: [],
        _sharedVlans: [],
        _sources: [],
        _tenantPrincipals: [],
        _viewBoxes: [],
        _views: [],
        _vlans: [],
      };

      return _.assign(defaultInfo, uiProperties);
    }

    /**
     * initialize the component & fetch the data.
     *
     * @method   $onInit
     */
    function $onInit() {
      initializeCtrl();

      if ($ctrl.tenantId) {
        $ctrl.tenant.tenantId = $ctrl.tenantId;

        getData();
      }
    }

    /**
     * get tenant and their properties like viewboxes, users, sources and vLANs.
     *
     * @method   getData
     */
    function getData() {
      // The list of properties to get with the tenant details.
      $ctrl.isLoading.page = true;
      var getTenantParams = {
        properties: [
          'Entity',
          'ProtectionJob',
          'ProtectionPolicy',
          'SwiftConfig',
          'ViewBox',
          'Views',
          'Vlan',
          'ActiveDirectory',
          'LdapProvider',
        ],
        _includeBifrostCapabilities: true,
      };
      var promises = {
        tenant: TenantService.getTenantById($ctrl.tenant.tenantId, getTenantParams),
        users: getTenantUsers(),
        groups: getTenantGroups(),
        proxies: getTenantProxies(),
      };
      return $q.all(promises).then(function onSuccess(responses) {
          _.assign($ctrl.tenant, responses.tenant);

          // If the tenant is marked for deletion, then go back to previous
          // state.
          if ($ctrl.tenant.deleted) {
            $state.go('access-management.tenants');
          }

          $ctrl.tenant._details = _.chain($ctrl.tenant)
            .pick(tenantDetailsProperties).cloneDeep().value();

          // Initialized selected values for ADs/LDAPs.
          $ctrl.tenant._ads = $ctrl.tenant.activeDirectories || [];
          $ctrl.tenant._assignedDomainNamesSet = new Set(_.map($ctrl.tenant._ads, 'domainName'));
          $ctrl.tenant._ldaps = $ctrl.tenant.ldapProviders || [];

          // Initialize swift operator roles.
          $ctrl.tenant._operatorRoles = _.get($ctrl.tenant, 'swiftConfig.operatorRoles');

          // get selected keystone.
          if ($ctrl.tenant.swiftConfig && $ctrl.tenant.swiftConfig.keystoneId) {
            getKeystoneById($ctrl.tenant.swiftConfig.keystoneId);
          } else {
            $ctrl.tenant._keystone = null;
            $ctrl.showAdLdapKeystoneWindow = true;
          }

          // get keystone list.
          getKeystones();
          getViews();
          getVlans();

          getPolicies()
            .then(getJobs, evalAJAX.errorMessage)
            .then(getSources, evalAJAX.errorMessage)
            .catch(evalAJAX.errorMessage);
        }, evalAJAX.errorMessage
      ).finally(function initializationDone() {
        $ctrl.isLoading.page = false;
      });
    }

    /**
     * Gets keystone details by id and assign $ctrl.tenant._keystone.
     *
     * @param    {number}   keyStoneId   ID of the keystone.
     * @method   getKeystoneById
     */
    function getKeystoneById(keystoneId) {
      TenantService.getKeystoneById(keystoneId).then(
        function success(keystoneObj) {
          $ctrl.tenant._keystone = keystoneObj;
          $ctrl.tenant._keystoneId = keystoneObj.id;
          $ctrl.showAdLdapKeystoneWindow = true;
        },
        evalAJAX.errorMessage,
      );
    }

    /**
     * Get all available keystones and assign $ctrl.tenant._keystoneList.
     *
     *  @method    getKeystons
     */
    function getKeystones() {
      TenantService.getKeystones().then(
        function success(allkeystones) {
          $ctrl.tenant._keystoneList = allkeystones;
        },
        evalAJAX.errorMessage,
      );
    }

    /**
     * Gets the list of tenant configured proxies.
     *
     * @method   getTenantProxies
     * @return   {promise}    Promise resolved with tenant proxies else rejected
     *                        with error.
     */
    function getTenantProxies() {
      var promise = FEATURE_FLAGS.bifrostEnabled ?
        TenantService.getTenantProxies($ctrl.tenant.tenantId) :
        $q.resolve([]);

      $ctrl.isLoading.proxies = true;
      return promise.then(function gotProxies(proxies) {
        const currentClusterVersion = $rootScope.clusterInfo.clusterSoftwareVersion;
        if (proxies && proxies.length && currentClusterVersion)
        {
          $ctrl.hyxClusterVersionMismatch = proxies.some(hyx =>
            hyx.version? hyx.version !== currentClusterVersion: false);
        }
        return $ctrl.tenant._proxies = proxies;
      }).finally(function finallyGetTenantProxies() {
        $ctrl.isLoading.proxies = false;
      });
    }

    /**
     * Used to determine is any one of tenant properties is getting loaded and
     * used to prevent saving the form
     *
     * @method   isLoadingProperties
     * @return   {booelan}    Return true if any one of the tenant properties is
     *                        getting loaded else return false.
     */
    function isLoadingProperties() {
      return _.reduce($ctrl.isLoading, function eachProperty(result, value) {
        if (!result) {
          result = value;
        }

        return result;
      }, false);
    }

    /**
     * Gets the tenant users.
     *
     * @method   getTenantUsers
     * @return   {promise}    Promise resolved with tenant users else rejected
     *                        with error
     */
    function getTenantUsers() {
      return UserService.getTenantUsers([$ctrl.tenant.tenantId]).then(
        function onSuccess(tenantUsers) {
          $ctrl.tenant._tenantPrincipals =
            _.concat($ctrl.tenant._tenantPrincipals, tenantUsers);

          $ctrl.tenant.tenantUserSids = _.map(tenantUsers, 'sid');
        }
      );
    }

    /**
     * Gets the tenant Groups.
     *
     * @method   getTenantGroups
     * @return   {promise}    Promise resolved with tenant users else rejected
     *                        with error
     */
    function getTenantGroups() {
      return UserService.getTenantGroups([$ctrl.tenant.tenantId]).then(
        function onSuccess(tenantGroups) {
          $ctrl.tenant._tenantPrincipals =
            _.concat($ctrl.tenant._tenantPrincipals, tenantGroups);

          $ctrl.tenant.tenantGroupSids = _.map(tenantGroups, 'sid');
        }
      );
    }

    /**
     * Gets tenant vlans
     *
     * @method   getVlans
     * @return   {promise}    Promise resolved with tenant vlans else rejected
     *                        with error
     */
    function getVlans() {
      var params = TenantService.extendWithTenantParams({});
      $ctrl.isLoading.vlans = true;

      return VlanService.getVlans(params).then(function onSuccess(vlans) {
        // filter out vlans assigned to tenant.
        $ctrl.tenant._vlans = vlans.filter(function forEachVlan(vlan) {
          return vlan.tenantId === $ctrl.tenant.tenantId;
        });

        // extract shared vlan and kept it with tenants under _sharedVlan.
        updateSharedVlans(vlans);

        // appending shared list after dedicated vlans assigned to the tenant.
        $ctrl.tenant._vlans = $ctrl.tenant._vlans.concat(
          $ctrl.tenant._sharedVlans);
      }, evalAJAX.errorMessage).finally(function gotResponseFinally() {
        $ctrl.isLoading.vlans = false;
      });
    }

    /**
     * Gets tenant vlans
     *
     * @method   updateSharedVlans
     * @param    {Array}   vlans   The vlans list.
     */
    function updateSharedVlans(vlans) {
      $ctrl.tenant._sharedVlans = vlans.filter(function eachVlan(vlan) {
        return vlan.allTenantAccess;
      });
    }

    /**
     * Get all the Views that can be assigned to the tenant.
     *
     * @method   getViews
     * @return   {promise}    Promise resolved with views else rejected
     *                        with error
     */
    function getViews() {
      $ctrl.isLoading.views = true;
      return ViewService.getViews().then(function onSuccess(views) {
        const viewByTenantId = _.groupBy(views, 'tenantId');

        // keep a copy of assigned views which is used later to detect changes
        // in views association
        $ctrl.tenant._views = _.cloneDeep(viewByTenantId[$ctrl.tenant.tenantId]);

        // keep tenant assigned views in the list to show them selected
        $ctrl.views = [].concat(viewByTenantId['undefined'] || [], viewByTenantId[$ctrl.tenant.tenantId] || []);
      }, evalAJAX.errorMessage).finally(function gotResponseFinally() {
        $ctrl.isLoading.views = false;
      });
    }

    /**
     * Gets tenant policies.
     *
     * @method   getPolicies
     * @return   {promise}    Promise resolved with tenant policies else
     *                        rejected with error
     */
    function getPolicies() {
      if (!$ctrl.tenant.policyIds.length) {
        return $q.resolve();
      }

      $ctrl.isLoading.policies = true;
      return NgProtectionPolicyService.getPolicies(NgIrisContextService.irisContext.clusterInfo.id).toPromise().then(
        function onSuccess(policiesResponse) {
          const policies = policiesResponse?.policies ?? [];
          $ctrl.tenant._policies = policies.filter(function eachPolicy(policy) {
          return $ctrl.tenant.policyIds.includes(policy.id);
        });
      }, evalAJAX.errorMessage).finally(function gotResponseFinally() {
        $ctrl.isLoading.policies = false;
      });
    }

    /**
     * Gets tenant protection jobs.
     *
     * @method   getJobs
     * @return   {promise}    Promise resolved with tenant protection jobs else
     *                        rejected with error
     */
    function getJobs() {
      if (!$ctrl.tenant.protectionJobs.length) {
        return $q.resolve();
      }

      var policiesJobsGroup =
        _.groupBy($ctrl.tenant.protectionJobs, 'policyId');

      $ctrl.tenant._policies = $ctrl.tenant._policies.map(
        function eachPolicy(policy) {
          policy._assignedJobs = policiesJobsGroup[policy.id] || [];

          return policy;
        }
      );

      return $q.resolve();
    }

    /**
     * Gets tenant sources.
     *
     * @method   getSources
     * @return   {promise}    Promise resolved with tenant sources else
     *                        rejected with error
     */
    function getSources() {
      if (!$ctrl.tenant.entityIds.length) {
        return $q.resolve();
      }

      $ctrl.isLoading.sources = true;
      return PubSourceService.getTenantSources(
        $ctrl.tenant.tenantId,
        {
          pruneNonCriticalInfo: FEATURE_FLAGS.assignObjectsV2
        },
        { skipTransform: true }
      ).then(function gotTenantSources(sources) {
          var jobsByEntityIdsMap = getJobsByEntityIdsMap();
          $ctrl.tenant._sources = sources;

          $ctrl.tenant._sources = PubJobServiceFormatter.decorateSources(
            $ctrl.tenant._sources,
            {
              jobsByEntityIdsMap: jobsByEntityIdsMap,
            }
          );


          return PubJobServiceFormatter.yieldToMainThread();
        }, evalAJAX.errorMessage).then(
          async () => {
            if (FEATURE_FLAGS.assignObjectsV2) {
              await Promise.all(($ctrl.tenant._sources || []).map(
                (source) => new Promise(
                  (res) => {
                    // old school for starting a new UI task
                    setTimeout(async () => {
                      await $ctrl.decorateSourceAsync([source]);
                      res(null);
                    }, 0);
                  }
                )
              ));
            } else {
              PubJobServiceFormatter.forEachNode(
                $ctrl.tenant._sources,
                function eachNode(node, index, list, path) {
                  // keep non root node assigned to tenant in _entityIds since those
                  // can be modified user.
                  if (_.get(node, 'entityPermissionInfo.tenant.tenantId') === $ctrl.tenant.tenantId &&
                    !node._owner.isInferred) {
                    node._selectedAncestor = true;
                    $ctrl.tenant._entityIds.push(node.protectionSource.id);
                  }

                  PubJobServiceFormatter.decorateSourceForAssignment(node, {
                    purpose: 'assignToTenant',
                    pathToRoot: path,
                    tenantId: $ctrl.tenant.tenantId,
                  });
                }
              );
            }
          }, evalAJAX.errorMessage
        ).finally(function gotResponseFinally() {
          $ctrl.isLoading.sources = false;
        });
    }


    /**
     * Decorate tenant sources for assignment
     *
     * @param {*} sources list of sources to decorate
     */
    async function decorateSourceAsync(sources) {
      const targetSource = !!sources ?
        (_.isArray(sources) ? sources : [sources]) : $ctrl.tenant._sources;

      await PubJobServiceFormatter.forEachNodeAsync(
        targetSource,
        async function eachNode(node, index, list, path) {
          // keep non root node assigned to tenant in _entityIds since those
          // can be modified user.
          if (_.get(node, 'entityPermissionInfo.tenant.tenantId') === $ctrl.tenant.tenantId &&
            !node._owner.isInferred) {
            node._selectedAncestor = true;
            $ctrl.tenant._entityIds.push(node.protectionSource.id);
          }

          await PubJobServiceFormatter.decorateSourceForAssignment(node, {
            purpose: 'assignToTenant',
            pathToRoot: path,
            tenantId: $ctrl.tenant.tenantId,
          });
        }
      );
    }

    /**
     * Opens modal to assign AD/LDAP/keystone to a tenant.
     *
     * @method   assignAdLdap
     */
    function assignAdLdap() {
      var adsMap = _.keyBy($ctrl.tenant._ads, 'domainName');
      var ldapsMap = _.keyBy($ctrl.tenant._ldaps, 'name');

      TenantService.assignTenantAdLdapModal(
        $ctrl.tenant.tenantId,
        adsMap,
        ldapsMap,
        $ctrl.tenant._keystone,
        $ctrl.tenant._keystoneList,
        $ctrl.tenant._tenantPrincipals || []
      ).then(function gotTenantAdLdaps(res) {
        $ctrl.tenant = _.clone($ctrl.tenant);
        $ctrl.tenant._ads = _.filter(res.ads);
        $ctrl.tenant._ldaps = _.filter(res.ldaps);
        $ctrl.tenant._keystone = res.keystone;
        $ctrl.tenant._keystoneId = res.keystone && res.keystone.id;
      });
    }

    /**
     * Opens modal to assign swift role to a tenant.
     *
     *  @method   assignSwiftRoles
     */
    function assignSwiftRoles() {
      var dialogParams = {
        enableModifyKeystone: false,
        defaultKeystone: $ctrl.tenant._keystone,
        keystoneList: $ctrl.tenant._keystoneList.keystones,
        swiftRoles: $ctrl.tenant._operatorRoles,
      };

      ngDialogService.showAddSwiftRolesDialog(dialogParams)
      .toPromise()
      .then(function afterClose (updatedSwiftConfig){
        if (updatedSwiftConfig) {
          // Assign temporary keystone and swift roles with updated value.
          $ctrl.tenant._keystone.id = updatedSwiftConfig.keystoneId;
          $ctrl.tenant._operatorRoles = updatedSwiftConfig.operatorRoles;
        }
      });
    }

    /**
     * Opens modal to assign user/groups to a tenant.
     *
     * @method   assignPrincipals
     */
    function assignPrincipals() {
      var assignedUsers = [];
      var unAssignedUsersMap = {};

      $ctrl.tenant._tenantPrincipals.forEach(function eachUser(user) {
        if (user.tenantId || user.restricted) {
          assignedUsers.push(user);
        } else {
          unAssignedUsersMap[user.sid] = user;
        }
      });

      TenantService.assignTenantPrincipalsModal(unAssignedUsersMap, $ctrl.tenant)
        .then(function gotTenantPrincipals(principals) {
          // retain already assigned users and replace un-assigned users with
          // newly selected users.
          $ctrl.tenant._tenantPrincipals = assignedUsers.concat(
            _.values(principals));
        });
    }

    /**
     * Modify Tenant assigned user/group either update or delete.
     *
     * @method   modifyPrincipal
     * @param    {Object}   principal     The user or Group to modify
     * @param    {String}   action   The action to perform 'edit', 'delete' or
     *                               'remove'
     */
    function modifyPrincipal(principal, action) {
      var principalsHash = {};
      principalsHash[principal.sid] = principal;

      switch(action) {
        // edit tenant user
        case 'edit':
          UserService.modifyUserModal(principal).then(
            function modifySuccess(updatedPrincipal) {
              $ctrl.tenant._tenantPrincipals.forEach(
                function eachUser(userInList) {
                  if (userInList.sid === updatedPrincipal.sid) {
                    _.assign(principal, updatedPrincipal);
                  }
                }
              );
            }
          );
          break;

        // delete tenant user from system
        case 'delete':
          UserService.deletePrincipals(principalsHash).then(
            function modifySuccess() {
              _.remove($ctrl.tenant._tenantPrincipals, ['sid', principal.sid]);
            }
          );
          break;

        // remove/un-assigned tenant user
        case 'remove':
          _.remove($ctrl.tenant._tenantPrincipals, ['sid', principal.sid]);
          return;
      }
    }

    /**
     * Return the provided principal's un-assignment reason text.
     *
     * @method   getPrincipalUnassignmentReason
     * @param    principal   The principal details.
     * @returns  The principal's un-assignment reason text.
     */
    function getPrincipalUnassignmentReason(principal) {
      const hasAssignedAd = $ctrl.tenant._ads.some(ad => ad.domainName === principal.domain);

      // AD Users/Groups can be un-assigned after un-assigning the AD domain.
      if (hasAssignedAd) {
        return $translate.instant('tenants.disablePrincicalUnassignment');
      }

      // Restricted AD Groups can't be un-assigned.
      if (principal.restricted) {
        return $translate.instant('disabledRestrictedGroupUnassignment');
      }

      return '';
    }

    /**
     * Open modal to assign sources to a tenant.
     *
     * @method   assignSources
     */
    function assignSources() {
      // find selected nodes from the tree
      var selectedSources = PubJobServiceFormatter.findNodes(
        $ctrl.tenant._sources,
        function eachNode(node) {
          return node._selectedAncestor;
        }
      );

      PubSourceService.openSourceGroupModal(selectedSources, {
          showTenantWarning: true,
          tenant: $ctrl.tenant,
          jobsByEntityIdsMap: getJobsByEntityIdsMap(),
        }).then(function gotSelectedSources(selectedSources) {
          if (FEATURE_FLAGS.assignObjectsV2) {
            updateTenantSources(selectedSources);
          } else {
            $ctrl.tenant._sources = selectedSources;
          }
        }
      );
    }

    /**
     * Updates the tenant sources with the source selection from the modal dialog.
     *
     * @param {object} selectedSources
     */
    function updateTenantSources(selectedSources) {
      var tenantSources = [...$ctrl.tenant._sources];
      var selectedSource = selectedSources[0];

      // check if source is already present in tenantSources
      var tenantSourceIndex = tenantSources
        .findIndex((tenantSource) => tenantSource.protectionSource.id === selectedSource.protectionSource.id);

      // if we already have the selected source in tenant source, update it.
      // else we will push it to tenent sources.
      if (tenantSourceIndex !== -1) {
        tenantSources[tenantSourceIndex].nodes = selectedSource.nodes;
      } else {
        tenantSources.push(selectedSource);
      }

      $ctrl.tenant._sources = tenantSources;
    }

    /**
     * Returns jobs grouped by entity Ids they are protecting.
     * NOTE: works with private jobs API response.
     *
     * @method   getJobsByEntityIdsMap
     */
    function getJobsByEntityIdsMap() {
      var resultMap = {};

      ($ctrl.tenant.protectionJobs || []).forEach(function eachJob(job) {
        _.chain(job.sources).map('entities').flatten().forEach(
          function eachNode(node) {
            if (!resultMap[node.id]) {
              resultMap[node.id] = [];
            }

            resultMap[node.id].push(job);
          }
        ).value();
      });

      return resultMap;
    }

    /**
     * Opens the instruction modal for adding Bifrost Proxy.
     *
     * @method   addBifrostProxyModal
     */
    function addBifrostProxyModal() {
      // The list of instructions that are to be rendered.
      var instructions = [{
        icon: 'type-vm-h',
        contentHtml: 'app/admin/access-management/tenants/download-bifrost/download-bifrost.html',
        data: {
          tenantId: $ctrl.tenant.tenantId,
        }
      },
      {
        content: 'bifrostSetup.donwloadConfig',
        icon: 'config-file',
        action: {
          idKey: 'upload-config',
          textKey: 'download',
          callback: function uploadConfig() {

            if (!FEATURE_FLAGS.enableHyxRealmClusterCompatChanges) {
              TenantService.getTenantProxy('config', $ctrl.tenant.tenantId);
              return ;
            }

            (
              UserService.isBifrostTenantUser() ?
              // if its a bifrost tenant user then data would already be
              // there in local storage - fetch that data
              $q.resolve(UserService.getDefaultBifrostConnectionId()) :
              // fetch default connection id for this tenant
              TenantService.getDefaultBifrostConnection($ctrl.tenant.tenantId).then(
                (defaultConnection) => !!defaultConnection ? defaultConnection.id : null
              ).catch(() => null)
            ).then((defaultConnectionId) => {
              TenantService.getTenantProxy('config', $ctrl.tenant.tenantId, defaultConnectionId);
            }).catch(() => TenantService.getTenantProxy('config', $ctrl.tenant.tenantId));
          },
          isDisabled: function isConfigDownloadDisabled() {
            return !$ctrl.tenant._isBifrostAlreadyEnabled;
          },
          getTooltip: function getDisablingReason() {
            return !$ctrl.tenant._isBifrostAlreadyEnabled ?
              'tenants.bifrostConfigDownloadTooltip' : null;
          },
        }
      },
      {
        content: 'bifrostSetup.uploadStep',
        icon: 'upload',
        contentContext: {
          uploadUrl: TenantService.getTenantConfigProxyUploadUrl(),
        },
      }];

      TenantService.addBifrostProxyModal(instructions);
    }

    /**
     * Opens modal to assign Policies & protection jobs to a tenant.
     *
     * @method   assignPoliciesAndProtectionJobs
     */
    function assignPoliciesAndProtectionJobs() {
      // find selected ancestor's nodes to filter out there parent nodes which
      // are there for display purpose.
      var selectedNodes = PubJobServiceFormatter.findNodes(
        $ctrl.tenant._sources,
        function eachNode(node) {
          return node._selectedAncestor;
        }
      );

      TenantService.assignTenantPoliciesModal(
        $ctrl.tenant._jobs,
        selectedNodes,
        $ctrl.tenant._policies,
        $ctrl.tenant._viewBoxes,
        $ctrl.tenant._views,
        markPolicyForRemoval
      ).then(
        function gotSelectedPolicies(selected) {
          $ctrl.tenant._jobs = _.filter(selected.jobsMap);
          $ctrl.tenant._policies = _.filter(selected.policiesMap);
        }
      );
    }

    /**
     * Determines if provided policy is marked for removal when it is assigned
     * to tenant
     *
     * @method   markPolicyForRemoval
     * @param    {Object}    policy     The policy to test
     * @return   {Boolean}   Return true when policy is to be marked for removal
     *                       else false when want to remove selected policy from
     *                       the list
     */
    function markPolicyForRemoval(policy) {
      return $ctrl.tenant.policyIds.includes(policy.id);
    }

    /**
     * Opens modal to assign VLANs to a tenant.
     *
     * @method   assignVlans
     */
    function assignVlans() {
      var selectedVlansMap = _.keyBy($ctrl.tenant._vlans, '_uid');

      TenantService.assignTenantVlansModal(selectedVlansMap, $ctrl.tenant).then(
        function gotSelectedVlans(vlansMap) {
          $ctrl.tenant._vlans = _.filter(vlansMap);
          updateSharedVlans($ctrl.tenant._vlans);
        }
      );
    }

    /**
     * Opens the create view modal and on successful creation adds it to
     * the selected views list.
     *
     * @method   addNewView
     */
    function addNewView() {
      return ngDialogService.showDialog('create-view-dialog-new',
        {
          viewParams: { storageDomainId: $ctrl.tenant._viewBoxes[0].id },
        },
        {
          panelClass: 'create-view-container',
          width: '47rem',
          height: '30rem',
        }
      ).toPromise().then(function createViewSuccess(view) {
        // Bare minimum details of the view box.
        var tempViewBox = {
          id: view.storageDomainId,
          name: view.storageDomainName,
        };

        // If the created view is mapped to a view box which is not already
        // tagged earlier, this block of code adds the untagged viewbox.
        if (!$ctrl.tenant.viewBoxIds.includes(view.storageDomainId)) {
          if (!_.find($ctrl.tenant._viewBoxes, ['id', tempViewBox.id])) {
              $ctrl.tenant._viewBoxes.push(tempViewBox);
            }
        }

        $ctrl.views.push(view);
        $ctrl.tenant._views.push(view);
      });
    }

    /**
     * Determines whether we can create a new s3 only view for tenants before
     * assignment.
     *
     * @method  isS3ViewAccessEnabledForNewView
     * @return  {boolean}  True if S3 view access is allowed, False otherwise.
     */
    function isS3ViewAccessEnabledForNewView() {
      return FEATURE_FLAGS.enableS3ViewsForTenants;
    }

    /**
     * Assigns the selected View Boxes to the tenant.
     *
     * @method   assignViewBoxes
     */
    function assignViewBoxes() {
      var oldViewBoxesIds = $ctrl.tenant.viewBoxIds.sort();
      var viewBoxesIds = _.map($ctrl.tenant._viewBoxes, 'id').sort();

      if (_.isEqual(viewBoxesIds, oldViewBoxesIds)) {
        return $q.resolve();
      }

      return TenantService.assignViewBoxes($ctrl.tenant.tenantId, viewBoxesIds)
        .then(function onSuccess() {
          return $ctrl.tenant.viewBoxIds = viewBoxesIds;
        });
    }

    /**
     * Assigns the selected users to the tenant.
     *
     * @method   assignTenantUsers
     */
    function assignTenantUsers() {
      // filter out un-tagged users only.
      var userSids = _.chain($ctrl.tenant._tenantPrincipals)
        .reject('tenantId')
        .filter({ type: 'user' })
        .map('sid').value();

      if (!userSids.length) {
        return $q.resolve();
      }

      return TenantService.assignTenantUsers($ctrl.tenant.tenantId, userSids);
    }

    /**
     * Assigns the selected groups to the tenant.
     *
     * @method   assignTenantGroups
     */
    function assignTenantGroups() {
      // filter out un-tagged groups only.
      var groupSids = _.chain($ctrl.tenant._tenantPrincipals)
        .filter({ type: 'group' })
        .map('sid').value().sort();
      var oldGroupSids = $ctrl.tenant.tenantGroupSids.sort();

      if (_.isEqual(groupSids, oldGroupSids)) {
        return $q.resolve();
      }

      return TenantService.assignTenantGroups($ctrl.tenant.tenantId, groupSids);
    }

    /**
     * Assigns the selected sources with tenant.
     *
     * @method   saveSources
     */
    function saveSources() {
      var oldEntityIds = $ctrl.tenant._entityIds.sort();
      var entityIds = PubSourceService.getSelectedSourceIds($ctrl.tenant._sources);

      if (_.isEqual(oldEntityIds, entityIds)) {
        return $q.resolve();
      }

      return TenantService.assignSources($ctrl.tenant.tenantId, entityIds).then(
        function onSuccess() {
          return $ctrl.tenant._entityIds = entityIds;
        }
      );
    }

    /**
     * Assigns the selected policies to the tenant.
     *
     * @method   savePolicies
     */
    function savePolicies() {
      if (isIbmBaaSEnabled(NgIrisContextService.irisContext)) {
        return $q.resolve();
      }

      var oldPolicyIds = $ctrl.tenant.policyIds.sort();
      var policyIds = $ctrl.tenant._policies.map(
        function eachPolicy(policy) {
          // filter out policy that are marked for removal and doesn't have
          // any tenant job linked to it.
          return policy._removed && !_.get(policy._assignedJobs, 'length') ?
            undefined : policy.id;
        }
      ).filter(_.identity).sort();

      if (_.isEqual(oldPolicyIds, policyIds)) {
        return $q.resolve();
      }

      return TenantService.assignPolicies($ctrl.tenant.tenantId, policyIds)
        .then(function onSuccess() {
          return $ctrl.tenant.policyIds = policyIds;
        });
    }

    /**
     * Assigns the selected ADS to the tenant.
     *
     * @method   saveADs
     * @return   {Object}  Promise resolved with success/failure of assignment
     *                     of ADs to the tenant.
     */
    function saveADs() {
      var domainNames = _.map($ctrl.tenant._ads, 'domainName').sort();
      var oldDomainNames = _.map($ctrl.tenant.activeDirectories, 'domainName')
        .sort();

      if (_.isEqual(domainNames, oldDomainNames)) {
        return $q.resolve();
      }

      return TenantService.assignADs($ctrl.tenant.tenantId, domainNames);
    }

    /**
     * Assigns the selected LDAPs to the tenant.
     *
     * @method   saveLdaps
     * @return   {Object}  Promise resolved with success/failure of assignment
     *                     of LDAPs to the tenant.
     */
    function saveLdaps() {
      var ldapIds = _.map($ctrl.tenant._ldaps, 'id').sort();
      var oldLdapIds = _.map($ctrl.tenant.ldapProviders, 'id').sort();

      if (_.isEqual(ldapIds, oldLdapIds)) {
        return $q.resolve();
      }

      return TenantService.assignLdaps($ctrl.tenant.tenantId, ldapIds);
    }

    /**
     * Assigns the selected swift config(keystone and swift roles) to the tenant.
     *
     * @method   saveSwiftConfig
     * @return   {Object}  Promise resolved with success/failure of assignment
     *                     of SwiftConfig to the tenant.
     */
    function saveSwiftConfig() {
      var oldKeystoneId = _.get($ctrl.tenant, 'swiftConfig.keystoneId');
      var oldSwiftRoles = _.get($ctrl.tenant, 'swiftConfig.operatorRoles');

      var keystoneId = _.get($ctrl.tenant, '_keystone.id');
      var swiftRoles = _.get($ctrl.tenant, '_operatorRoles');

      if(_.isEqual(keystoneId, oldKeystoneId) && _.isEqual(swiftRoles, oldSwiftRoles)) {
        return $q.resolve();
      }

      return TenantService.assignSwiftConfig($ctrl.tenant.tenantId, keystoneId, swiftRoles);
    }

    /**
     * Assigns the VLANs to the tenant.
     *
     * @method   assignVlansToTenant
     *
     * @return   {Object}   Promise which resolves the new tenant config
     *                      assigned with the new VLANs else rejected with error
     */
    function assignVlansToTenant() {
      var oldVlanIfaceNames = $ctrl.tenant.vlanIfaceNames.sort();

      // remove the shared VLANs before diffing for changes in assigned vlans.
      var vlanIfaceNames = _.chain($ctrl.tenant._vlans)
        .filter(function eachVlan(vlan) { return !vlan.allTenantAccess; })
        .map('_uid').sort().value();

      if (_.isEqual(oldVlanIfaceNames, vlanIfaceNames)) {
        return $q.resolve();
      }

      return TenantService.assignVlans(
        $ctrl.tenant.tenantId, vlanIfaceNames).then(
        function onSuccess() {
          return $ctrl.tenant.vlanIfaceNames = vlanIfaceNames;
        }
      );
    }

    /**
     * Assign protection jobs to the tenant.
     *
     * @method   assignJobsToTenant
     *
     * @param    {Object}   assignViewboxesResult   Viewbox assignment response
     *                                              object
     * @param    {Object}   assignSourcesResult     Sources assignment response
     *                                              object
     * @param    {Object}   assignPolicesResult     Polices assignment response
     *                                              object
     * @return   {Object}   Promise which resolves the new tenant config
     *                      assigned with the new VLANs else rejected with error
     */
    function assignJobsToTenant(assignViewboxesResult, assignSourcesResult,
      assignPolicesResult) {
      var assignViewboxesError = assignViewboxesResult.$status === 'error';
      var assignSourcesError = assignSourcesResult.$status === 'error';
      var assignPolicesError = assignPolicesResult.$status === 'error';

      if (!$ctrl.tenant._jobs.length) {
        return $q.resolve();
      }

      // don't try assigning job if not able to assign viewboxes, sources or
      // polices
      if (assignViewboxesError || assignSourcesError || assignPolicesError) {
        cMessage.info({
          textKey: 'tenants.error.jobs',
          textKeyContext: {
            properties: [
              assignViewboxesError ? 'storageDomains' : null,
              assignSourcesError ? 'sources' : null,
              assignPolicesError ? 'policies' : null,
            ].filter(_.identity),
          },
        });
        return $q.reject({$state: 'error'});
      }

      return TenantService.assignJobs($ctrl.tenant.tenantId, $ctrl.tenant._jobs)
        .then(function onSuccess() {
          Array.prototype.push.apply(
            $ctrl.tenant.protectionJobs,
            $ctrl.tenant._jobs
          );
          return $ctrl.tenant.protectionJobs;
        });
    }

    /**
     * Asssign the views to the tenant.
     *
     * @method   assignViews
     * @param    {Object}   assignViewboxesResult   Viewbox assignment response
     *                                              object
     * @return   {Object}   Promise which resolves the new tenant config
     *                      assigned with the new views else rejected with error
     */
    function assignViews(assignViewboxesResult) {
      var oldViewNames = _.map($ctrl.tenant.views, 'name').sort();
      var viewNames = _.map($ctrl.tenant._views, 'name').sort();

      if (_.isEqual(oldViewNames, viewNames)) {
        return $q.resolve();
      }

      // don't try assigning views if viewboxes assignment failed
      if (assignViewboxesResult && assignViewboxesResult.$state === 'error') {
        cMessage.info({textKey: 'tenants.error.views'});
        return $q.reject({$state: 'error'});
      }

      return TenantService.assignViews($ctrl.tenant.tenantId, viewNames).then(
        function onSuccess() {
          return $ctrl.tenant.viewNames = viewNames;
        }
      );
    }

    /**
     * Updates tenant's basic details like name, description or active/in-active
     * status.
     *
     * @method   updateTenantDetails
     */
    function updateTenantDetails() {
      var oldDetails = $ctrl.tenant._details;
      var details = _.pick($ctrl.tenant, tenantDetailsProperties);

      if (_.isEqual(oldDetails, details)) {
        return $q.resolve();
      }

      return TenantService.updateTenant(details);
    }
    /**
     * update the tenant with new setting.
     *
     * @method   updateTenant
     *
     * @return   {Object}   Promise which resolves the new tenant config else
     *                      rejected with error.
     */
    function updateTenant() {
      var promises = {
        swiftConfig: saveSwiftConfig(),
        policies: savePolicies(),
        sources: saveSources(),
        users: assignTenantUsers(),
        groups: assignTenantGroups(),
        'tenants.details': updateTenantDetails(),
        viewBoxes: assignViewBoxes(),
        vlans: assignVlansToTenant(),
      };

      if (!FEATURE_FLAGS.keystoneModuleEnabled) {
        delete promises.swiftConfig;
      }

      return $q.allSettled(promises).then(
        function updatedIndependentProperties(result) {
          var hasErrorOnLevelOne = isUpdateError(result);
          var dependentPromises = {
            ads: saveADs(),
            ldaps: saveLdaps(),
            views: assignViews(result.viewBoxes),
            jobsKey: assignJobsToTenant(
              result.viewBoxes,
              result.sources,
              result.policies
            ),
          };

          return $q.allSettled(dependentPromises).then(
            function gotResponse(resp) {
              var hasErrorOnLevelTwo = isUpdateError(resp);

              // reject the promise and keep user on the page if update finished
              // with error
              if (hasErrorOnLevelOne || hasErrorOnLevelTwo) {
                const { message, quorumResponse } =
                  getErrorMessage(_.assign({}, result, resp));
                if (!quorumResponse) {
                  cMessage.error({
                    titleKey: 'tenants.errors.title',
                    textKey: message,
                  });
                }
                return $q.reject(quorumResponse);
              }

              return resp;
            }
          );
        }
      );
    }

    /**
     * Returns the accumulated error message string.
     *
     * @method   getErrorMessage
     * @param    {Object}   responses   The responses object from $q.allSettled
     * @return   {string}   Return the accumulated error message
     */
    function getErrorMessage(responses) {
      var message = [];
      var quorumResponse;
      _.forEach(responses, function eachResponse(response, property) {
        if (response.$status === 'error') {
          if (response.resp) {
            quorumResponse = response.resp;
          } else {
            message.push(
              $translate.instant(property) + ': ' + response.resp.data?.message
            );
          }
        }
      });

      return {
        message: message.join('</br>'),
        quorumResponse: quorumResponse,
      };
    }

    /**
     * Determines if update tenants properties failed with error or not.
     *
     * @method   isUpdateError
     * @param    {Object}   responses   The responses object from $q.allSettled
     * @return   {boolean}  Return True if any one of the $q.allSettled promise
     *                      resolved with error else return false
     */
    function isUpdateError(responses) {
      var hasError = false;

      _.forEach(responses, function eachResponse(value, key){
        if (!hasError && value.$status === 'error') {
          hasError = true;
        }
      });

      return hasError;
    }

    /**
     * Add the tenant for the first time.
     *
     * @method   addTenant
     */
    function addTenant() {
      return TenantService.addTenant($ctrl.tenant);
    }

    /**
     * Add or update the tenant config.
     *
     * @method   saveSetting
     * @param    tenantForm  The tenant form data
     */
    function saveSetting(tenantForm) {
      if (tenantForm.$invalid) {
        return;
      }

      if ($ctrl.inCreateMode || ($ctrl.tenant.bifrostEnabled || $ctrl.tenant._vlans.length)) {
        issueApiRequest();
        return;
      }

      // Open a challenge dialog because no network segments are configured.
      var options = {
        actionButtonText: $rootScope.text['continue'],
        closeButtonText: $rootScope.text['cancel'],
        content: 'tenants.networkSegments.warning.body',
        title: 'tenants.networkSegments.warning.title',
      };
      cModal.showModal({}, options).then(issueApiRequest);
    }

    /**
     * Issue API request to add or update the tenant config.
     *
     * @method   issueApiRequest
     */
    function issueApiRequest() {
      var promise;
      $ctrl.submitting = true;
      promise = $ctrl.inEditMode ? updateTenant() : addTenant();

      promise
        .then(function onSuccess(updatedTenant) {
          cMessage.success({
            textKey: $ctrl.inEditMode ? 'tenants.saveSuccess' :
              'tenants.addSuccess.details',
            textKeyContext: {
              // don't send tenant info since it is having self referencing
              // properties because of which cMessage is not able to serialize
              // it and causing UI to freeze
              name: $ctrl.tenant.name,
            },
          });

          // If in edit mode redirect to the listing page else redirect
          // to the edit tenant page.
          if($ctrl.inEditMode) {
            $state.go('access-management.tenants');
          } else {
            $state.go('edit-tenant', {
              id: updatedTenant.tenantId,
            });
          }
        }, evalAJAX.errorMessage)
        .finally(function finallyAfterModify() {
          $ctrl.submitting = false;
        });
    }

  /**
     * Toggles the active status for the tenant.
     *
     * @method   toggleTenantStatus
     */
    function toggleTenantStatus() {
      TenantService.toggleTenantStatus($ctrl.tenant)
        .then(function toggleSuccess(updatedTenant) {
          getData();
        });
    }

    /**
     * If view box sharing is disabled filter out the tagged view
     * boxes (except the one tagged to the current tenant)
     *
     * @method   filterViewBoxes
     * @param    {Array}   viewBoxes   The list of view boxes.
     * @returns  {Array}   The modified view box list.
     */
    function filterViewBoxes(viewBoxes) {
      return $rootScope.clusterInfo.tenantViewboxSharingEnabled ? viewBoxes :
        _.filter(viewBoxes, function filterViewBoxes(viewBox) {
          return !(viewBox.tenantIdVec && viewBox.tenantIdVec
            .indexOf($ctrl.tenant.tenantId) === -1);
        });
    }

    /**
     * Checks if the displayed entity (view or job) is tagged to any of the
     * selected view boxes or the view is S3 only (which is disabled for MT
     * admins).
     *
     * @method   filterEntityForViewBoxes
     * @param    {Object}   entity   The Entity which is to be checked.
     * @returns  {Boolean}  True if it is tagged and false otherwise.
     */
    function filterEntityForViewBoxes(entity) {
      var found = !!_.find($ctrl.tenant._viewBoxes, ['id', entity.viewBoxId]);

      // S3 only views are not assignable to tenants when FF is disabled.
      if (entity.hasOwnProperty('viewId')) {
        found = found && (entity.protocolAccess === 'kS3Only' ?
          FEATURE_FLAGS.enableS3ViewsForTenants : true
        );
      }

      return found;
    }

    /**
     * On change handler for view boxes. When happens, the selcted views and
     * jobs should be filtered accordingly.
     *
     * @method   onTenantViewBoxesChange
     */
    function onTenantViewBoxesChange() {
      $ctrl.tenant._views =
        _.filter($ctrl.tenant._views, filterEntityForViewBoxes);

      $ctrl.tenant._jobs =
        _.filter($ctrl.tenant._jobs, filterEntityForViewBoxes);
    }

    /**
     * Function to delete the tenant.
     *
     * @method   removeTenant
     */
    function removeTenant() {
      TenantService.openDeleteTenantModal($ctrl.tenant)
       .then(function removeTenantSuccess() {
          $state.go('access-management.tenants');
        });
    }

    /**
     * Show the warning message when the network details are changed.
     *
     * @method   onTenantNetworkChange
     */
    function onTenantNetworkChange() {
      var oldNetwork = _.pick($ctrl.tenant._details,
        'clusterHostname', 'clusterIps');
      var newNetwork = _.pick($ctrl.tenant, 'clusterHostname', 'clusterIps');

      // Check if the tenant has proxy lists and has added the hostname/ips for
      // the first time or removed them all.
      $ctrl.showTenantNetworkChangeWarning = $ctrl.tenant._proxies.length &&
        (!oldNetwork.clusterHostname !== !newNetwork.clusterHostname) ||
        (!oldNetwork.clusterIps.length !== !newNetwork.clusterIps.length);
    }
  }
})(angular);
