// component: End User License Agreement Page

(function(angular, undefined) {
  'use strict';

  angular
    .module('C.eula', ['C.clusterClaim'])
    .config(configFn)
    .component('eula', {
      bindings: {
        /**
         * dontShowAgreeCancelButton {boolean} if true, dont show Agree and
         * cancel button
         */
        dontShowAgreeCancelButton: '<'
      },
      controller: 'eulaCtrl',
      templateUrl: 'app/global/c-eula/eula-new.html',
    })
    .controller('eulaCtrl', eulaCtrlFn);

  function configFn($stateProvider) {
    $stateProvider.state('eulaNew', {
      title: 'Cluster Connect',
      url: '^/eula/{eulaType}',
      help: 'eula',
      component: 'eula',
      canAccess: 'true',
      params: {
        eulaType: { type: 'string' },
      },
    });
  }

  function eulaCtrlFn(_, API, $state, ClusterService, $rootScope,
    $stateParams, $window, UserService, HeliosService, evalAJAX, FEATURE_FLAGS) {
    // TODO: Manthan remove VARS...
    // inject NgEulaService instead, eula.service.ts. It also can provide eula paths.
    var VARS = {
      eulaVersion: 4,
      heliosEulaVersion: 3,
    }
    var $ctrl = this;
    var eulaType = $stateParams.eulaType;
    var eulaPaths = {
      hp: [
        {
          path: '/assets/eula/hp-eula.html',
          name: 'HPE EULA'
        }
      ],
      cohesity: [
        {
          path:[
            '/assets/eula/cohesity-eula-',
            VARS.eulaVersion,
            '.pdf'
          ].join(''),
          name: 'Cohesity EULA'
        },
        {
          path:[
            '/assets/eula/cohesity-helios-eula-',
            VARS.heliosEulaVersion,
            '.pdf'
          ].join(''),
          name: 'Cohesity Helios EULA'
        }
      ],
      helios:[
        {
          path: [
            '/assets/eula/cohesity-helios-eula-',
            VARS.heliosEulaVersion,
            '.html'
          ].join(''),
          name: 'Cohesity Helios EULA'
        }
      ]
    };

    _.assign($ctrl, {
      $onInit: $onInit,
      cancel: cancelEula,
      agree: agreeEula,
      isHpEula: isHpEula,
      registerCluster: registerCluster,
    });


    /**
     * Logout the user if user diagrees EULA
     *
     * @method  cancelEula
     */
    function cancelEula() {
      UserService.logout();

      // Force a reload of this state so app will be re-bootstrapped. This
      // is necessary because $rootScope.basicClusterInfo is cleared on
      // logout.
      $state.reload();
    }

    /**
     * Register cluster if user accepts the required EULA's
     * Present HP EULA if required.
     * If trial period is on, inform the user.
     */
    function agreeEula() {
      var clusterInfo = ClusterService.clusterInfo;
      var signedByUser;
      var eulaConfig;
      var newCluster = ClusterService.isNewCluster();
      var updateEulaOrlicense = !!ClusterService.clusterInfo.eulaConfig?
        ClusterService.updateEula : ClusterService.acceptLicense;

      if ($rootScope.basicClusterInfo.mcmMode) {
        signedByUser = $rootScope.user.salesforceAccount.userId;
      } else {
        signedByUser = clusterInfo.user ?
          clusterInfo.user.username : $rootScope.user.username;
      }

      eulaConfig = {
        signedVersion: VARS.eulaVersion,
        signedByUser: signedByUser,

        // Current time in seconds
        signedTime: Math.floor(Date.now() / 1000),

        // TODO Manthan/Shishir remove this key from backend api
        licenseKey: 'AYFN-1080-5VW2-GBOT',
      };

      updateEulaOrlicense(eulaConfig).then(function changeState() {

        // if its a old cluster, no need to show user the license Flow
        // after eula acceptance
        if (!newCluster) {
          ClusterService.updateRootWithLicenseSuccess();
          ClusterService.showLicenseNotDeployedWarning();
          $state.goHome();
          return;
        }

        HeliosService.getHeliosConfiguration().then(
          function getConfiguration(config) {

          // If the cluster is already claimed, then update licensing
          // states and return
          if (config.registrationStatus === 'kRegistrationCompleted') {
            ClusterService.updateRootWithLicenseSuccess();
            $state.goHome();
            return;
          }
          // set licenseState condition first, so  even for new cluster,
          // if state change fails, atleast we will know, that its a new
          // cluster
          ClusterService.updateLicenseState('started')
            .then(function changeState() {
              if (FEATURE_FLAGS.clusterLicensePageRevamp) {
                $state.go('connected-site-ng.select-mode');
              } else {
                $state.go('connected-site', { registrationType: 'saas' });
              }
            });
        });
      }, evalAJAX.errorMessage);

    }

    /**
     * Register the cluster with license server
     *
     * @method registerCluster
     */
    function registerCluster() {
      $window.location.href = API.private('mcm/registerCluster');
    }

    /**
     * is this hp hardware EULA to be shown?
     *
     * @method isHpEula
     * @return {Boolean}  True if it is hp hardware
     */
    function isHpEula() {
      return eulaType === 'hpEula';
    }

    /**
     * returns the html eula path to be used in eula acceptence modal
     *
     * @method getEulaPath
     * @return {string} path to eula html file
     */
    function getEulaPath() {
      switch (eulaType) {
        case 'hpEula':
          return eulaPaths.hp;
        case 'heliosEula':
          return eulaPaths.helios;
        case 'cohesity':
          return eulaPaths.cohesity;
        default:
          eulaPaths.cohesity
            .push(ClusterService.isHpEulaNeeded() ? eulaPaths.hp[0] : null);
          return eulaPaths.cohesity.filter(Boolean);
      }
    }

     /**
     * Initialize the controller.
     *
     * @method   $onInit
     */
    function $onInit() {
      $ctrl.eulaToShow = 0;
      $ctrl.loading = true
      $ctrl.eulaPaths = getEulaPath();
      $ctrl.eulaPath = $ctrl.eulaPaths[$ctrl.eulaToShow].path;

      // if eula file is in html, render response, otherwise show pdf itself
      if (!($ctrl.eulaPath).endsWith('pdf')) {
        ClusterService.getEula($ctrl.eulaPath).then(function setEulaText(text) {
          $ctrl.eulaText = text;
          $ctrl.loading = false;
        })
      } else {
        $ctrl.loading = false;
      }
    };
  }
})(angular);
