<ng-container *ngIf="health">
  <ng-container *ngIf="isFailedState(health.status)" [ngTemplateOutlet]="errorState"></ng-container>
  <ng-container *ngIf="!isFailedState(health.status)" [ngTemplateOutlet]="nonErrorState"></ng-container>
</ng-container>

<ng-template #errorState>
  <div class="flex-row-xs vertical-align" [matTooltip]="health?.message" [matTooltipDisabled]="!health?.message">
    <cog-icon shape="{{ health.status | scanHealthStatusIcon: true }}" size="md"></cog-icon>
    <span cogDataId="{{ 'scan-health-status-type-' + health.status }}">
      {{ ('argus.enum.healthStatusType.' + health.status) | translate }}
    </span>
  </div>
</ng-template>

<ng-template #nonErrorState>
  <ng-content></ng-content>
</ng-template>

<ng-template #noHealth>
  <span>{{'naNotAvailable' | translate }}</span>
</ng-template>
