// Directive: Handles all the error & validation boilerplate for form fields

;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C';
  var dirName = 'fieldStatus';
  var msgKeyAttrPrefix = 'msg';
  var linkAttemptRetryInterval = 250;

  angular.module(moduleName)
    .directive('fieldStatus', FieldStatusFn)
    .directive('customValidation', CustomValidationFn);

  /**
   * $ngdoc Directive
   * @name C:fieldStatus
   * @link
   *
   * @requires form
   * @description
   *   This directive simplifies the boilerplate markup for field validation
   *   setup. It self-manages error messages, and "error" and "required"
   *   classes.
   *
   *   To override default error messages, add an attribute to the same element
   *   as fieldStatus for each model $validator you want to override by name
   *   prefixed with "msg-", ie.
   *     `msg-pattern="errors.wholeNumber"` or
   *     `msg-max="max.value.translationKey"`
   *
   *   To pass translate values for a validator, use 'translate-msg' as prefix
   *   and 'value' as suffix. ie.
   *      `translate-msg-pattern-values="{key: value}"`
   *
   *
   *   To do the validation real-time add an attribute 'real-time'.
   *   This makes sure the fields are validated once they are $dirty.
   *
   *   The required marker can be omitted (see login form) by adding a
   *   `no-required-marker` attribute the `fieldset[field-status]`.
   *
   *   NOTE: This _must_ be used within a form/ngForm node and the form control
   *   _must_ have an ngModel.
   *
   *   NOTE: If the error message doesn't come out, please make sure no dash
   *   used in name attribute.
   *
   * @example
      <fieldset field-status real-time msg-pattern="errors.patterns.jobName">
        <label for="textInput4">Text Field 4</label>
        <input type="text"
          name="textInput4"
          id="textInput4"
          class="c-input"
          required
          pattern="{{::FORMATS.names}}"
          ng-model="textInput4">
      </fieldset>
   */
  function FieldStatusFn($compile, $log, $interpolate, $timeout) {
    return {
      link: linkFn,
      priority: 0,
      require: ['^^form'],
      scope: false,
    };

    /**
     * Angular Directive link Fn.
     *
     * @method   linkFn
     */
    function linkFn(scope, $elem, attrs, ctrls) {
      var args = arguments;
      var _this = this;
      var linkRetries = 5;
      var fieldName;
      var $form;
      var $model;

      /**
       * Poller to test if the ngModel directive within has registered on the
       * ngForm yet. if not, try again.
       *
       * @method   attemptLink
       * @param    {number}   [delay=0]   Millisecond delay for this attempt.
       */
      function attemptLink(delay) {
        $timeout(
          function linkDelayed() {
            fieldName = getFieldName($elem.find('[ng-model]'), attrs, scope);
            $form = ctrls[0];
            $model = $form[fieldName];

            if (!($form && $model) && linkRetries) {
              linkRetries--;

              return attemptLink(linkAttemptRetryInterval);
            }

            delayedLinkFn.apply(_this, args);
          },
          delay || 0
        );
      }

      attemptLink();
    }

    /**
     * Angular Directive LinkFn, only delayed.
     *
     * @method   delayedLinkFn
     */
    function delayedLinkFn(scope, $elem, attrs, ctrls) {
      var inputControl = $elem.find('[ng-model]');
      var customId;

      // validate fields real-time flag
      var isRealTime = attrs.hasOwnProperty('realTime');
      var fieldName = getFieldName(inputControl, attrs, scope);
      var fieldId = getFieldId(inputControl, attrs, scope);
      var $form = ctrls[0];

      // getTopForm is removed in 6.1 as angular 1.7 has auto child form submit
      var $topForm = $form;
      var $model = $form[fieldName];
      var inputLabel = $elem.find(['label[for="', fieldId, '"]'].join(''));
      var formField;
      var formFieldDirty;
      var formSubmitted;
      var ngMessages;

      // In the case of a wrapping label sans `for` attribute, like for
      // checkboxes, radios, and cToggles, look at the parent.
      if (!inputLabel.length) {
        inputLabel = inputControl.parent('label');
      }

      // Throw a console error and exit if the required dependencies aren't
      // available. But don't block other JS outside this directive.
      if (!($form && $model)) {
        return $log.error(
          generateErrorMessage(fieldName),
          $form,
          angular.copy($elem),
          angular.copy($model)
        );
      }

      if (!$form.$name) {
        $log.error('Form name is required for parent form of ' + fieldName);
      }

      formField = [ $form.$name, fieldName ].join('.');
      formFieldDirty = [ $form.$name, fieldName, '$dirty' ].join('.');
      formSubmitted = [ $topForm.$name, '$submitted' ].join('.');

      ngMessages = [
        '<div ng-messages="',
        formField,
        '.$error" ng-if="',
        isRealTime ? formFieldDirty + ' || ' : '',
        formSubmitted,
        '">',
      ];

      angular.forEach($model.$validators, function eachValidator(vFn, vName) {
        var messageKey = getMessageKey(vName, $elem) || ('errors.' + vName);
        var messageValue;
        var msgVNameValue = 'translateMsg' + _.upperFirst(vName) + 'Values';

        // Determine which message to use for this validator
        switch (vName) {
          case 'required':
            // Add the required class to the label if appropriate
            if (inputLabel.length && !attrs.$attr.noRequiredMarker &&
              $model.$$attr.required) {
              inputLabel.addClass('required');
            }
            break;

          case 'min':
            messageValue = _.get(inputControl[0].attributes, 'min.value');
            break;

          case 'max':
            messageValue = inputControl[0].attributes.max.value;
            break;

          case 'minDate':
            messageValue = inputControl[0].attributes['min-date'].value;
            break;

          case 'maxDate':
            messageValue = inputControl[0].attributes['max-date'].value;
            break;

          case 'minlength':
            messageValue = inputControl[0].attributes.minlength.value;
            break;

          case 'maxlength':
            messageValue = inputControl[0].attributes.maxlength.value;
            break;

          case 'custom-validation':
            messageValue = formField + '.$viewValue';
            break;

          case 'url':
            break;

          default:
            messageKey = messageKey || 'errors.invalid';
        }

        customId = 'error-' + vName + '-' + fieldId;

        // Add the ngMessage for this validator
        ngMessages.push(
          [
            '<small class="error-msg" ng-message="',
            vName,
            '" translate="',
            messageKey,
            '" id="',
            customId,
            '"',
          ].join('')
        );

        // Only add translate-values if a value was assigned to messageValues.
        if (angular.isDefined(messageValue || attrs[msgVNameValue])) {
          ngMessages.push(
            [
              'translate-values="{ value: ',
              messageValue || attrs[msgVNameValue],
              '}"',
            ].join('')
          );
        }

        // Close the ngMessage for this validator
        ngMessages.push('></small>');
      });

      // Close the template.
      ngMessages.push('</div>');

      // $compile and insert $error messages markup
      $elem.append($compile(ngMessages.join(''))(scope));

      // Emulate ngClass for setting .error on the input's parent
      // (fieldset) and adding aria-describedby on the input
      scope.$watch(
        function validityWatcher() {
          return isFieldInvalid($topForm, $model, isRealTime);
        },
        function fauxNgClass(showError) {
          var method = showError ? 'addClass' : 'removeClass';

          $elem[method]('error');

          method = showError ? 'setAttribute' : 'removeAttribute';

          // We need to wait the HTML to render
          requestAnimationFrame(function(){
            inputControl[0][method]("aria-describedby", customId);
          })
        },
      );
    }

    /**
     * Generates the Directive error message for when the form or model are
     * missing.
     *
     * @method   generateErrorMessage
     * @return   {string}   The message.
     */
    function generateErrorMessage(fieldName) {
      return [
        'The "',
        dirName,
        '" directive must be used within an ngForm ',
        'and must have an ngModel child. ',
        'Here is the problem field: ',
        fieldName,
      ].join('');
    }

    /**
     * Get the name of the node with the ngModel bound to it.
     *
     * @method   getFieldName
     * @param    {object}   $input   jQ object with ngModel.
     * @param    {object}   attrs    The ng-normalized attrs object.
     * @param    {object}   data     Data to interpolate against.
     * @return   {string}   The field name or fallback.
     */
    function getFieldName($input, attrs, data) {
      // Interpolation allows the fieldStatus to function with names/ids which
      // are dynamically created inside an ngRepeat, i.e `fieldName{{$index}}`
      return $interpolate(
        $input.attr('name') ||
        $input.attr('id') ||
        attrs[dirName]
      )(data);
    }

    /**
     * Get the name of the node with the ngModel bound to it.
     *
     * @method   getFieldId
     * @param    {object}   $input   jQ object with ngModel.
     * @param    {object}   attrs    The ng-normalized attrs object.
     * @param    {object}   data     Data to interpolate against.
     * @return   {string}   The field id or fallback.
     */
    function getFieldId($input, attrs, data) {
      // Interpolation allows the fieldStatus to function with names/ids which
      // are dynamically created inside an ngRepeat, i.e `fieldName{{$index}}`
      return $interpolate(
        $input.attr('id') ||
        $input.attr('name') ||
        attrs[dirName]
      )(data);
    }

    /**
     * Checks if the field is valid or not.
     *
     * @method     isFieldInvalid
     * @param      {object}   $form    ngForm controller
     * @param      {object}   $model   ngModel controller
     * @return     {boolean}  True if field invalid, False otherwise.
     */
    function isFieldInvalid($form, $model, isRealTime) {
      // In case of realtime validate fields if they are dirty / form submitted.
      // In normal scenario just validate when form is submitted
      var validateCondition = (isRealTime && $model.$dirty) || $form.$submitted;

      // If $model or $field aren't defined, it's invalid.
      return (!$form || !$model) || (validateCondition && !$model.$valid);
    }

    /**
     * Gets the message key from the elem node. Looks at attributes called
     * msg-<validatorName].
     *
     * @method     getMessageKey
     * @param      {string}  validatorName  The $model provided $validator name
     * @param      {object}  $elem          The DOM node element this directive
     *                                      is bound to.
     * @return     {string}  The found message key, or undefined.
     */
    function getMessageKey(validatorName, $elem) {
      var msgKeyAttr = [msgKeyAttrPrefix, validatorName].join('-');
      return $elem.attr(msgKeyAttr);
    }
  }

  /**
   * $ngdoc Directive
   * @name C:customValidation
   * @link
   *
   * @requires form
   * @description
   *   This directive is used to add custom validation like unique name
   *   validation for the fields and compatible with field-status.
   *
   * @example
      <fieldset
        field-status
        real-time
        msg-custom-validation="errors.customValidation">
        <label for="textInput4">Text Field 4</label>
        <input type="text"
          name="textInput4"
          id="textInput4"
          class="c-input"
          required
          custom-validation="isUniqueName($viewValue, $modelValue)"
          ng-model="textInput4">
      </fieldset>
   */
  function CustomValidationFn() {
    return {
      link: linkFn,
      require: ['ngModel'],
      scope: false,
    };

    function linkFn(scope, element, attrs, ctrls) {
      var ngModel = ctrls[0];

      ngModel.$validators['custom-validation'] =
        function customValidationFn(modelValue, viewValue) {
          return scope.$eval(attrs.customValidation, {
            $modelValue: modelValue,
            $viewValue: viewValue,
          });
        };
    }
  }

})(angular);
