import { Injectable } from '@angular/core';
import { RawParams, Transition } from '@uirouter/core';
import dayjs from 'dayjs/esm';
import { IrisContextService, flagEnabled, isDmsUser } from '@cohesity/iris-core';

import { GuardPriority, GuardResult, StateGuard } from '../state-guard';

/**
 * Health Guard
 * Redirects all alerts.alerts traffic to the ng health state
 * If ngHealth feature flag is eanbled and not in DMaaS context.
 */
@Injectable({
  providedIn: 'root',
})
export class HealthGuard implements StateGuard {
  /**
   * This guard priority is set to App, which indicates it should run as part of the main application
   * After all bootstrapping and initializing is completed.
   */
  guardPriority = GuardPriority.App;

  statesNotAllowed = ['alerts.settings', 'alerts.analytics'];

  /**
   * Match all navigation to alerts.alerts(classic UI).
   * Health settings tab is currently redirecting to the classic alerts settings tab
   * Todo(Sudeeksha)
   * alert.settings and alerts.analytics check to be removed after the classic view is changed to ng view
   */
  matchCriteria = {
    to: state => {
      const transitionTo = state.name;
      return transitionTo.startsWith('alerts') && !this.statesNotAllowed.includes(transitionTo);
    },
  };

  constructor(
    private irisCtx: IrisContextService,
    private irisContextService: IrisContextService
  ) {}

  /**
   * Convert old alerts params to the new health params model.
   * Changing alertsSeverity, and date range to last one day.
   *
   * @param   params   The state params from the ajs alerts flow
   * @returns Updates params that can be used for the ng health flow.
   */
  convertToNgParams(params: RawParams): RawParams {
    const newParams: RawParams = {};

    // Alerts Severity
    newParams.alertSeverityList = params.alertsSeverity ? [params.alertsSeverity] : undefined;

    // Alerts Status param
    newParams.alertStateList = params.alertsStatus ? [params.alertsStatus] : undefined;

    // Alerts Category
    newParams.alertCategoryList = params.alertsCategory ? [params.alertsCategory] : undefined;

    // Alerts bucket type
    newParams.alertTypeBucketList = params.alertsTypeBucket ? [params.alertsTypeBucket] : undefined;

    // Last 24 hrs date filter
    if (params.alertsDateRange === 'last24Hours') {
      newParams.endTime = dayjs().valueOf();
      newParams.startTime = dayjs().subtract(1, 'd').valueOf();
    }

    return newParams;
  }

  /**
   * Redirect to ng Health.
   *
   * @param   transition  The UI router transition
   * @returns a redirect transtion if the ngHealth feature flag is enabled.
   */
  onStart(transition: Transition): GuardResult {
    const params = transition.params();
    const ngParams = this.convertToNgParams(params);
    if (flagEnabled(this.irisCtx.irisContext, 'ngHealth') &&
      (!isDmsUser(this.irisContextService.irisContext) || flagEnabled(this.irisCtx.irisContext, 'ngAlertsDms'))) {
      return transition.router.stateService.target('health.alerts', ngParams);
    }
  }
}
