import { SmbPermission } from '@cohesity/api/v2';
import { DEFAULT_ACL_PERMISSIONS } from '../views.model';
import { CreateViewDecorated } from './basic-form';

/**
 * The default ACL permissions.
 *
 * @see {DEFAULT_ACL_PERMISSIONS}
 */
export const DefaultAclPermissions: SmbPermission[] = [{
  sid: 'S-1-1-0',
  access: 'FullControl',
  mode: 'FolderSubFoldersAndFiles',
  type: 'Allow',
}];

/**
 * This is the default value for the create view form. It's the source of truth
 * for the new create view workflow.
 *
 * @see {DEFAULT_VIEW_CONFIG}
 */
export const DefaultCreateViewValue: CreateViewDecorated = {
  antivirusScanConfig: {
    isEnabled: false,
    blockAccessOnScanFailure: false,
    maximumScanFileSize: 26214400,
    scanFilter: {
      isEnabled: false,
      mode: null,
      fileExtensionsList: [],
    },
    prefixScanFilter: {
      isEnabled: false,
      mode: null,
      fileExtensionsList: [],
    },
    s3TaggingFilter: {
      isEnabled: false,
      mode: null,
      tagSet: [],
    },
    scanOnAccess: false,
    scanOnClose: true,
    scanOnPut: false,
    scanTimeoutUsecs: 180000000,
  },
  caseInsensitiveNamesEnabled: true,
  category: 'FileServices',
  enableAbac: false,
  enableFastDurableHandle: false,
  enableNfsKerberosAuthentication: true,
  enableNfsKerberosIntegrity: true,
  enableNfsKerberosPrivacy: true,
  enableNfsUnixAuthentication: true,
  enableNfsViewDiscovery: false,
  enableNfsWcc: false,
  enableSmbOplock: true,
  enableSmbViewDiscovery: true,
  fileExtensionFilter: {
    isEnabled: false,
    mode: 'Blacklist',
    fileExtensionsList: [],
  },
  lifecycleManagement: {
    rules: [],
    versionId: 1
  },
  netgroupWhitelist: {
    nisNetgroups: null,
  },
  nfsAllSquash: {
    gid: 65534,
    uid: 65534,
  },
  nfsRootPermissions: {
    gid: 0,
    mode: 493,
    uid: 0,
  },
  nfsRootSquash: {
    gid: 65534,
    uid: 65534,
  },
  overrideGlobalNetgroupWhitelist: true,
  overrideGlobalSubnetWhitelist: true,
  qos: {
    name: 'TestAndDevHigh',
  },
  s3FolderSupportEnabled: false,
  securityMode: 'NativeMode',
  selfServiceSnapshotConfig: {
    allowAccessSids: [
      'S-1-1-0',
    ],
    alternateSnapshotDirectoryName: '~snapshot',
    enabled: false,
    nfsAccessEnabled: true,
    previousVersionsEnabled: true,
    smbAccessEnabled: true,
    snapshotDirectoryName: '.snapshot',
    denyAccessSids: [],
  },
  sharePermissions: {
    permissions: [...DEFAULT_ACL_PERMISSIONS],
    superUserSids: null,
  },
  smbPermissionsInfo: {
    ownerSid: 'S-1-5-32-544',
    permissions: [...DEFAULT_ACL_PERMISSIONS],
  },
  subnetWhitelist: null,
  versioning: 'UnVersioned',
  viewPinningConfig: {
    enabled: false,
    pinnedTimeSecs: -1,
    lastUpdatedTimestampSecs: null,
  }
};
