<!-- Recovery snapshot confirmation dialog template -->
<h2 mat-dialog-title>{{ headerMsgKey | translate }}</h2>

<form
  id="anomaly-restore-snapshot-selector"
  [formGroup]="form"
  (validatedSubmit)="recoveryDialogClose()"
  cohValidateOnSubmit>
  <mat-dialog-content class="margin-bottom">
    <span [innerHTML]="confirmationMsgKey | translate: { object: dialogParams.object }"></span>
    <!-- Snapshot selection -->
    <div class="margin-top flex-row-lg vertical-align">
      <mat-form-field class="flex-grow">
        <mat-select
          cogDataId="snapshot-selector"
          placeholder="{{ 'snapshotTime' | translate }}"
          formControlName="snapshotTimestampUsec">
          <mat-option *ngFor="let timestamp of cleanDataPoints" [value]="timestamp">
            {{ timestamp | cogDate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <label *ngIf="form.get('snapshotTimestampUsec').value === cleanDataPoints[0]">
        ({{ 'latestCleanSnapshot' | translate }})
      </label>
    </div>
    <!-- Environment kPhysical doesn't need username and password -->
    <div *ngIf="showCredentials">
      <span [innerHTML]="'anomalyFileRecovery.enterCredentials' | translate: { object: dialogParams.object }"></span>
      <!-- Username and password -->
      <div class="flex-row flex-equal-division margin-top-sm">
        <mat-form-field>
          <mat-label>{{ 'username' | translate }}</mat-label>
          <input cogDataId="object-user-name-input" type="text" matInput required formControlName="username" />
          <mat-error>{{ 'errors.required' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'password' | translate }}</mat-label>
          <button
            cogDataId="toggle-password-visible"
            type="button"
            mat-icon-button
            matSuffix
            (click)="showPassword = !showPassword">
            <mat-icon>{{ showPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
          </button>
          <input
            cogDataId="object-password-input"
            [type]="showPassword ? 'text' : 'password'"
            matInput
            required
            formControlName="password" />
          <mat-error>{{ 'errors.required' | translate }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button cogCancelButton mat-button cogDataId="cancel-anomaly-snapshot-selector" mat-dialog-close>
      {{ 'cancel' | translate }}
    </button>
    <button cogSubmitButton mat-button cogDataId="confirm-anomaly-snapshot-selector">
      {{ 'recover' | translate }}
    </button>
  </mat-dialog-actions>
</form>
