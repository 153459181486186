import { Injectable } from '@angular/core';
import { NavItem } from '@cohesity/helix';
import { AppType, flagEnabled, IrisContext, PillarType } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { DmsService } from 'src/app/core/services/dms.service';

import { ServiceNavProvider } from '../service-nav-provider';

/**
 * This Service configures the data protect navigation menu with iris.
 */
@Injectable({ providedIn: 'root' })
export class DmsNavService implements ServiceNavProvider {
  pillar: PillarType = 'protection';

  app: AppType = 'dms';

  get serviceNavTitle(): string {
    return this.translate.instant('dataProtectNoSpace');
  }

  serviceNavTitleIcon = 'helix:logo-data-protect';

  serviceNavTitleClass = 'data-protect';

  showNavBranding = true;

  /**
   * States that are allowed in DMaaS profile.
   */
  private enabledInDmsState = [
    'dashboards',
    'dashboards.dms',
    'helios-access-management',
    'dms.regions',
    'feature-flags',
  ];

  constructor(private dmsService: DmsService, private translate: TranslateService) {}

  getNavItems(irisContext: IrisContext): NavItem[] {
    return [
      {
        displayName: 'dashboard',
        state: 'dashboards.dms',
        icon: 'dashboard!outline',
      },

      // Data Protection Section
      flagEnabled(irisContext, 'ngDmsProtectionGroup') && {
        displayName: 'dataProtection',
        state: 'protection-group',
        icon: 'helix:protection',
        subNavList: [
          {
            displayName: 'protection',
            state: 'protection-group',
          },
          {
            displayName: 'sources',
            state: 'dms-sources.landing',
          },
          {
            displayName: 'policies',
            state: 'protection-policy',
          },
        ],
      },
      !flagEnabled(irisContext, 'ngDmsProtectionGroup') && {
        displayName: 'sources',
        state: 'dms-sources.landing',
        icon: 'helix:object-cluster',
      },
      !flagEnabled(irisContext, 'ngDmsProtectionGroup') && {
        displayName: 'policies',
        state: 'protection-policy',
        icon: 'helix:protection',
      },
      {
        displayName: 'activity',
        state: 'object-activity.listing',
        icon: 'aspect_ratio!outline',
      },
      {
        displayName: 'health',
        state: 'health.alerts',
        icon: 'notifications!outline',
      },
      flagEnabled(irisContext, 'dmsAuditLogs') && {
        displayName: 'auditLogs',
        state: 'helios-audit-logs',
        icon: 'iris:audit-log!outline',
      },
      flagEnabled(irisContext, 'ngReport') && {
        displayName: 'reports',
        state: 'reporting.list',
        icon: 'assessment!outline',
        activeStates: ['reporting.detail', 'reporting.schedule'],
      },
      {
        displayName: 'settings',
        state: 'helios-access-management',
        icon: 'settings!outline',
        subNavList: [
          {
            displayName: 'accessManagement',
            state: 'helios-access-management',
          },
          {
            displayName: 'regions',
            state: 'dms.regions',
          },
          {
            displayName: 'saasConnections',
            state: 'dms-connections',
          },
        ],
      },
    ].filter(Boolean);
  }

  isNavItemDisabled(navItem: NavItem, _irisContext: IrisContext): boolean {
    // Check whether setup is done or else only enable states are allowed
    // in initial configuration.
    if (['NotDone', 'PartiallyDone'].includes(this.dmsService.isSetupDone$.value)) {
      return !this.enabledInDmsState.includes(navItem.state);
    }
    return false;
  }
}
