import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

/**
 * Settings list item to display power on vm value.
 */
@Component({
  selector: 'coh-settings-list-continue-on-error',
  templateUrl: './settings-list-continue-on-error.component.html',
})
export class SettingsListContinueOnErrorComponent {
  /**
   * The form control for the settings list item.
   */
  @Input() control: UntypedFormControl;

  /**
   * Alternate Label to use for the control.
   */
  @Input() label: string;
}
