import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'coh-form-array-action-control',
  templateUrl: './form-array-action-control.component.html',
  styleUrls: ['./form-array-action-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormArrayActionControlComponent {
  /**
   * The form array length.
   */
  @Input() formArrayLength: number;

  /**
   * The current form control index in the form array.
   */
  @Input() index: number;

  /**
   * Determines if addiing new entry to formArray action has to be disabled or not.
   */
  @Input() addActionDisabled = false;

  /**
   * Determines if remove formArray action has to be disabled or not.
   */
  @Input() removeActionDisabled = false;

  /**
   * Determines if remove button is visible or not when index is 0.
   */
  @Input() showRemoveWhenFirst = false;

  /**
   * EventEmitter when clicking on the (+) button.
   */
  @Output() controlAdded = new EventEmitter<void>();

  /**
   * EventEmitter when clicking on the (-) button.
   */
  @Output() controlRemoved = new EventEmitter<void>();

  /**
   * Specifies if the current control is the first in the form array.
   */
  get isFirst() {
    return this.index === 0;
  }

  /**
   * Specifies if the current control is the last control in the form array.
   */
  get isLast() {
    return this.index === this.formArrayLength - 1;
  }

  /**
   * Specifies if the remove button is shown or not.
   */
  showRemoveButton(): boolean {
    return !this.isFirst || this.formArrayLength > 1 || (this.isFirst && this.showRemoveWhenFirst);
  }
}
