import { ReportSection } from '../iris-reporting.model';

/**
 * This is the layout config for the DR plan summary report.
 */
export const drPlanSummaryReport: ReportSection[] = [
  {
    title: 'reporting.sites',
    items: [
      {
        id: 'statlistItem',
        componentNames: ['DrPlanSites'],
        params: {
          items: [
            {
              title: 'reporting.primarySite',
              translate: true,
              measurement: { valueKey: 'primarySite', dataType: 'text' },
            },
            {
              title: 'reporting.drSite',
              translate: true,
              measurement: { valueKey: 'drSite', dataType: 'text' },
            },
          ],
        },
      },
    ],
  },
  {
    title: 'reporting.summary',
    items: [
      {
        id: 'statlistItem',
        componentNames: ['DrPlanActivitySummary'],
        params: {
          items: [
            {
              title: 'reporting.total',
              translate: true,
              measurement: { valueKey: 'total', dataType: 'number' },
            },
            {
              title: 'reporting.success',
              translate: true,
              measurement: { valueKey: 'success', dataType: 'number' },
            },
            {
              title: 'reporting.failure',
              translate: true,
              measurement: { valueKey: 'failure', dataType: 'number' },
            },
          ],
        },
      },
    ],
  },
  {
    title: 'reporting.details',
    items: [
      {
        id: 'statlistItem',
        componentNames: ['DrPlanDetails'],
        params: {
          items: [
            {
              title: 'reporting.applicationName',
              translate: true,
              measurement: { valueKey: 'applicationName', dataType: 'text' },
            },
          ],
        },
      },
    ],
  },
  {
    title: 'reporting.activity',
    isFullData: true,
    items: [
      {
        id: 'tableItem',
        componentNames: ['DrPlanActivity'],
        params: {
          columns: [
            {
              title: 'reporting.activity',
              translate: true,
              key: 'activityType',
              primary: {
                key: 'activityType',
                dataType: 'text',
              },
            },
            {
              title: 'startTime',
              translate: true,
              key: 'startTimeUsecs',
              primary: {
                key: 'startTimeUsecs',
                dataType: 'date',
                renderParams: {
                  usecs: true,
                },
              },
            },
            {
              title: 'duration',
              translate: true,
              key: 'duration',
              primary: {
                key: 'duration',
                dataType: 'duration',
                renderParams: {
                  usecs: true,
                },
              },
            },
            {
              title: 'status',
              translate: true,
              key: 'status',
              primary: {
                key: 'status',
                dataType: 'text',
              },
            },
          ],
        },
      },
    ],
  },
];
