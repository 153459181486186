import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FilesDeletedByUserList, FilesOpenedByUserList, LogSearchResponse } from '@cohesity/api/argus';
import { AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { Options, SeriesBarOptions, SeriesLineOptions } from 'highcharts';
import moment from 'moment';
import { ObservableInput } from 'ngx-observable-input';
import { BehaviorSubject } from 'rxjs';

/**
 * Interface for component data
 */
export interface UserActivity {
  filesDeleted: FilesDeletedByUserList;
  filesOpened: FilesOpenedByUserList;
  logs: LogSearchResponse;
}

/**
 * @description
 * User Activity component visualizes activities by sytem users that could
 * have caused an attack.
 *
 * @example
 *   <dg-sc-user-activity [data]="data"></dg-sc-user-activity>
 */
@Component({
  selector: 'dg-sc-user-activity',
  templateUrl: './user-activity.component.html',
  styleUrls: ['./user-activity.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserActivityComponent extends AutoDestroyable implements OnInit {
  /**
   * Chart data.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('data') data$: BehaviorSubject<UserActivity>;

  /**
   * The date filter timeframe label.
   */
  @Input() timeFrameLabel: string;

  /**
   * Loading state of the card.
   */
  @Input() loading = false;

  /**
   * The resolved data
   */
  data: UserActivity;

  /**
   * Data for the bar chart
   */
  barSeriesData: SeriesBarOptions = {type: 'bar'};

  /**
   * Data for the line chart
   */
  lineSeriesData: SeriesLineOptions = {type: 'line'};

  /**
   * Categories (x axis) for chart data
   */
  categories: string[];

  /**
   * Custom chart options.
   */
  customOptions: Highcharts.Options = {
    chart: {
      type: 'bar',
      height: 130,
      styledMode: true,
    },
    tooltip: {
      enabled: false
    },
    xAxis: {
      type: 'category'
    },
    yAxis: {
      visible: true,
      title: {
        text: '',
      },
      opposite: true,

    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        pointWidth: 12,
        groupPadding: 1,
        pointPadding: 1,
        dataLabels: {
          enabled: true,
          style: {
            textOutline: 'none',
          },
          formatter() {
            return this.point.options?.custom?.yFormattedValue || this.point.y;
          },
        },
        cursor: 'pointer',
      },
    },
  };

  /**
   * Additional options used to format the chart.
   */
  options: Options = {
    chart: {
      spacing: [5, 0, 0, 0],
      height: 130
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      type: 'datetime',

      // Show only extreme values in x axis
      labels: {
        formatter() {
          if (this.isFirst || this.isLast) {
            return moment(this.value).format('d MMM');
          } else {
            return '';
          }
        }
      }
    },
    yAxis: {
      visible: true,
      title: {
        enabled: false,
      } as any,
    },
  };

  constructor(
    readonly cdr: ChangeDetectorRef,
    readonly translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.data$.pipe(
      this.untilDestroy()
    ).subscribe((data) => {
      this.data = data;
      this.createChartData(0);
    });
  }

  /**
   * Create data to be displayed in chart
   *
   * @param selectedTabIndex The index of the tab for which data is to be created
   */
  createChartData(selectedTabIndex: number) {
    let key = 'filesDeleted', event = 'FileDelete';
    this.categories = [];

    if (selectedTabIndex === 1) {
      key = 'filesOpened';
      event = 'FileOpen';
    }

    this.barSeriesData = {...this.barSeriesData, ...{
      data: this.data?.[key]?.map(data => {
        this.categories.push(data.principal.name);
        return {
          y: data.count,
          custom: {
            category: data.principal.name
          }
        };
      }).sort((a, b) =>  b.y - a.y)
    }};

    this.lineSeriesData = {...this.lineSeriesData, ...{
      data: this.createLineSeriesData(this.data?.logs?.resultRows, event)
    }};

    this.cdr.detectChanges();
  }

  /**
   * Convert log search data into a series data for line chart
   *
   * @param data  The event log data
   * @param eventType The event against which logs are to be filtered
   */
  createLineSeriesData(data, eventType) {
    const dayMap = {};

    // Bucket all data into "days" based on timestamp. This is needed to convert
    // all timestamps of a given day into a single day.
    data?.forEach(item => {
      if (item[11] === eventType) {
        const date = new Date(parseInt(item[0], 10)).toDateString();
        if (dayMap[date]) {
          dayMap[date] += 1;
        } else {
          dayMap[date] = 1;
        }
      }
    });

    // Convert all dates back to timestamp. With this step, all timestamps for a
    // day will be uniform.
    const convertedDayMap = {};
    Object.keys(dayMap).forEach(key => convertedDayMap[new Date(key).valueOf()] = dayMap[key]);

    // Sort the map
    const sortedDayMap = Object.keys(convertedDayMap).sort().reduce(
      (obj, key) => {
        obj[key] = convertedDayMap[key];
        return obj;
      },
      {}
    );

    const lineSeriesData = [];
    Object.keys(sortedDayMap).forEach((key) => {
      lineSeriesData.push({
        x: parseInt(key, 10),
        y: sortedDayMap[key]
      });
    });

    return lineSeriesData;
  }
}
