import { RestoreSearchResult } from './restore-search-result';
import {
  ObjectSnapshotsInfo,
  ProtectedObject,
  UdaIndexedObject,
} from '@cohesity/api/v2';

/**
 * This class provides search results information for UDA protection groups.
 *
 * @example
 * const searchResult = new UdaProtectionGroupSearchResult(object);
 */
export class UdaProtectionGroupSearchResult implements RestoreSearchResult {

  /**
   * Static property for the result type. Which can be referenced by other classes.
   */
  static readonly searchResultType = 'protectionGroup';

  get environment(): string {
    return this.object.sourceInfo.environment;
  }

  get id(): string {
    return `${this.name}:${this.protectionGroupId}:${this.sourceId}:${this.objectId}`;
  }

  get name(): string {
    return this.protectionGroupName;
  }

  get objectId(): number {
    return this.object.id;
  }

  get objectName(): string {
    return this.latestSnapshotInfo?.protectionGroupName;
  }

  /**
   * Object type will always be kCluster for protection groups.
   */
  get objectType(): string {
    return 'kCluster';
  }

  /**
   * Gets the group id from the latest snapshot info.
   */
  get protectionGroupId(): string {
    return this.latestSnapshotInfo?.protectionGroupId;
  }

  get protectionGroupName(): string {
    return this.latestSnapshotInfo?.protectionGroupName;
  }

  /**
   * Gets the group run id from the latest snapshot info.
   */
  get protectionRunId(): string {
    return this.latestSnapshotInfo?.protectionRunId;
  }

  /**
   * Specifies the id of the indexed object.
   */
  get indexedObjectId(): string {
    return `${this.name}:${this.protectionGroupId}:${this.sourceId}:${this.objectId}`;
  }

  /**
   * By default, objects can't be selected along with this object type.
   */
  requiresExclusiveSelection = false;

  get restoreTimestampUsecs(): number {
    return this.latestSnapshotInfo?.protectionRunStartTimeUsecs;
  }

  readonly resultType = UdaProtectionGroupSearchResult.searchResultType;

  get storageDomainId(): number {
    return this.latestSnapshotInfo?.storageDomainId;
  }

  get sourceEnvironment(): string {
    return this.object.sourceInfo.environment || this.environment;
  }

  get sourceId(): number {
    return this.object.sourceInfo.id;
  }

  get parentSourceName(): string {
    return this.object.sourceInfo?.name;
  }

  get hasEntitySupport(): boolean {
    if (this.environment !== 'kUDA') {

      // It is a pre-requisite for UDA 'First Class Citizen' env types, to have
      // entity hierarchy support.
      return true;
    }

    return this.object.udaParams?.hasEntitySupport;
  }

  /**
   * Convenience getter to return the first item in the last snapshots info array.
   */
  get latestSnapshotInfo(): ObjectSnapshotsInfo {
    return this.object.latestSnapshotsInfo?.[0];
  }

  constructor(private object: ProtectedObject) {}
}

/**
 * This class provides search results information for UDA objects.
 *
 * @example
 * const searchResult = new UDAObjectSearchResult(object);
 */
export class UDAObjectSearchResult implements RestoreSearchResult {
  /**
   * Static property for the result type. Which can be referenced by other classes.
   */
  static readonly searchResultType = 'object';

  get environment(): string {
    return this.udaIndexedObject.sourceInfo.environment;
  }

  /**
   * Gets a unique id for a object based on the name, path, object id and protection group id.
   * This is only used internally.
   */
  get id(): string {
    return `${this.name}:${this.path}:${this.objectId}:${this.protectionGroupId}`;
  }

  get name(): string {
    return this.udaIndexedObject.fullName;
  }

  get objectType(): string {
    return this.udaIndexedObject.objectType;
  }

  get objectId(): number {
    return this.udaIndexedObject.sourceInfo.id;
  }

  get objectName(): string {
    return this.udaIndexedObject.fullName;
  }

  /**
   * Specifies the id of the indexed object.
   */
  get indexedObjectId(): string {
    return this.udaIndexedObject.id;
  }

  /**
   * Gets the fully qualified name of the object.
   */
  get path(): string {
    return this.udaIndexedObject.path;
  }

  /**
   * Gets the group id from the latest snapshot info.
   */
  get protectionGroupId(): string {
    return this.udaIndexedObject.protectionGroupId;
  }

  get protectionGroupName(): string {
    // Returning 'Deleted' as a safety measure for testing. Need a better
    // way of handling this scenario.
    return this.protectionGroupsMap.get(this.protectionGroupId) || 'Deleted';
  }

  /**
   * By default, objects can be selected along with other object types.
   */
  requiresExclusiveSelection = false;

  get restoreTimestampUsecs(): number {
    return null;
  }

  readonly resultType = UDAObjectSearchResult.searchResultType;

  get sourceEnvironment(): string {
    return this.udaIndexedObject.sourceInfo.environment;
  }

  get sourceId(): number {
    return this.udaIndexedObject.sourceInfo.sourceId || null;
  }

  get parentSourceName(): string {
    return this.udaIndexedObject.sourceInfo.sourceName;
  }

  get storageDomainId(): number {
    return this.udaIndexedObject.storageDomainId;
  }

  constructor(
    private udaIndexedObject: UdaIndexedObject,
    private protectionGroupsMap: Map<string, string>,
  ) {}
}
