<div [formGroup]="formGroup">
  <coh-form-field-object-selector
    [readonly]="readOnly || (!(loading$ | async) && !dataCenters?.length)"
    cogDataId="data-centers-multi-select"
    [control]="formGroupControls.value"
    [label]="'dataCenters' | translate"
    [multiple]="true"
    [loading]="loading$ | async"
    [optionTemplate]="optionTemplate"
    [allowSearch]="false"
    [triggerTemplate]="optionTemplate"
    [values]="dataCenters">
  </coh-form-field-object-selector>
  <div *ngIf="!(loading$ | async) && !dataCenters?.length">
    <!-- Template for Displaying No data center available -->
    <strong>{{ 'noDataCenterAvailable' | translate }}</strong>
  </div>
</div>

<!-- Template for Cassandra Data Centers dropdown -->
<ng-template #optionTemplate let-value>
  <strong>{{ value }}</strong>
</ng-template>
