import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { IconModule } from '../icon/icon.module';

import { ActivityItemComponent } from './activity-item/activity-item.component';

@NgModule({
  imports: [CommonModule, IconModule, MatTooltipModule, ActivityItemComponent],
  exports: [ActivityItemComponent]
})
export class ActivityItemModule {}
