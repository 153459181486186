import { Component } from '@angular/core';

import { PostureAdvisorService } from '../../services';


@Component({
  selector: 'dg-pa-rule-details-definitions',
  templateUrl: './rule-details-definitions.component.html',
  styleUrls: ['./rule-details-definitions.component.scss']
})
export class RuleDetailsDefinitionComponent {
  /**
   * Local variable to store observable of securityRuleDetails$
   */
  securityRuleDetails$ = this.postureAdvisorService.securityRuleDetails$;

  constructor(readonly postureAdvisorService: PostureAdvisorService) { }
}
