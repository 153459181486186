import { ProtectionSourceNode } from '@cohesity/api/v1';
import { Environment, ObjectTypeToIconMap } from 'src/app/shared/constants';
import { ProtectionSourceDataNode } from '../shared/protection-source-data-node';
import { HasMetadata, SourceNodeMetadata } from '../shared/protection-source-metadata/source-node-metadata';



/**
 * Cassandra table type.
 */
export type CassandraTableType =  null | 'kRegular' | 'kGraph' | 'kSystem';

/**
 * Represents an active Cassandra source node and job tree selection behavior.
 */
export class CassandraSourceDataNode extends ProtectionSourceDataNode implements HasMetadata {

  /**
   * Stores the metadata for the source node.
   */
  readonly metadata: SourceNodeMetadata;

  constructor(data: ProtectionSourceNode, readonly level: number) {
    super(Environment.kCassandra, data, level, []);
    this.metadata = {
      logicalSize: this.logicalSize,
      leafCount: this.expandable ? this.leafCount : undefined,
      nodeIdentifierKey: Environment.kCassandra + '.' + this.envSource.type
    };
  }

  /**
   * Whether the node is a leaf which can be directly selected or not.
   */
  get isLeaf() {
    return this.type === 'kTable';
  }

  /**
   * Get the keyspace type for cassandra.
   *
   * @returns   Number of record in salesforce object.
   */
  get keyspaceType(): string {
    return this.protectionSource?.cassandraProtectionSource?.keyspaceInfo?.type;
  }

  /**
   * Get the Table type for cassandra.
   *
   * @returns   type of cassandra table i.e kRegular,kGraph and kSystem.
   */
  get tableType(): CassandraTableType {
    return this.protectionSource?.cassandraProtectionSource?.tableInfo?.type;
  }

  /**
   * Used as a callback function to determine if a node can be selected.
   *
   * @return  True if the node can be selected.
   */
  canSelect(): boolean {
    // A node should only be selectable if it is not a system keyspace table.
    return !(this.isLeaf && this.tableType === 'kSystem');
  }

  /**
   * Whether a node could be selected in the tree. If this is false, the checkbox will be hidden
   * in the tree. This is different from canSelect, which can be used to disable a checkbox.
   */
  get isSelectable(): boolean {
    return !(this.type === 'kKeyspace' && this.keyspaceType === 'kSystem');
  }

  /**
   * Used as a callback function to determine if a node can be excluded from auto protection.
   *
   * @return  True if the node can be excluded from auto protection.
   */
  canExclude(): boolean {
    // Tags can always be exlcuded.
    return !(this.isLeaf && this.tableType === 'kSystem');
  }

  /**
   * Get the helix icon name to use for rendering the icon next to the tree node.
   */
  get icon(): string {

    const envIconMap = ObjectTypeToIconMap[this.protectionSource.environment];
    let iconName =  envIconMap && envIconMap[this.type];

    if (this.type === 'kKeyspace' && this.keyspaceType === 'kSystem') {
      iconName = 'helix:object-system-keyspace';
    }

    // Object nodes which are auto protected should show the auto protected icon too
    const suffix = (this.isAutoProtectedByObject || (!this.isLeaf && this.isObjectProtected)) ? '-auto' :
      this.protected ? '-protected' :
      this.partialProtected ? '-partial' : '';

    return iconName.concat(suffix);
  }
}
