<cog-spinner *ngIf="isLoading || (hideRecoveryBtn ? false : isSubmitting)"></cog-spinner>

<cog-blank-card
  *ngIf="!hideRecoveryBtn && !isLoading && !anomalies.length"
  message="{{ 'recovery.disableMessage.emptyCart' | translate }}">
</cog-blank-card>

<div class="flex-column-md" *ngIf="!isLoading && anomalies.length">
  <!-- Header and recovery button -->
  <div class="flex-row vertical-align" *ngIf="!hideRecoveryBtn">
    <span>
      <h2>{{ 'reviewBulkRecovery' | translate }}</h2>
    </span>
    <button
      id="recovery-button"
      class="flex-pull-right"
      mat-raised-button
      color="primary"
      [disabled]="isSubmitting"
      (click)="openRecoveryDialog()">
      {{ 'recover' | translate }}
    </button>
  </div>

  <!-- Default settings banner -->
  <cog-banner status="info">
    <div class="flex-row-lg vertical-align">
      <span>{{ 'anomalyRecovery.defaultSettings' | translate }}</span>
      <a (click)="openDefaultRecoverySettings()">{{ 'showDetails' | translate }}</a>
    </div>
  </cog-banner>

  <!-- Objects -->
  <div [ngClass]="{ 'flex-row-lg': !verticalView, 'flex-column-md': verticalView }">
    <div [ngClass]="{ 'flex-column-sm': !verticalView, 'flex-row-sm': verticalView }">
      <strong>{{ 'anomalyRecovery.bulkPointSelection' | translate }}</strong>
      <mat-radio-group
        [ngClass]="{ 'flex-column-xs': !verticalView, 'flex-row-xs': verticalView }"
        [disabled]="isSubmitting"
        (change)="onRadioChange($event)">
        <mat-radio-button
          *ngFor="let option of recoveryOptions; index as i"
          id="recovery-radio-{{ i }}"
          cogDataId="recovery-option-{{option}}"
          [value]="option"
          [checked]="i === 1">
          {{ option | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="flex-column-md flex-grow panel-content">
      <mat-expansion-panel
        *ngFor="let anomaly of anomalies; index as i"
        [disabled]="!customSelectionEnabled || isSubmitting"
        [expanded]="anomaly.isExpanded"
        (opened)="expansionPanelOpened(anomaly)"
        (closed)="anomaly.isExpanded = false"
        hideToggle>
        <mat-expansion-panel-header class="padding-top-xl padding-bottom-xl">
          <mat-panel-description class="panel-description">
            <cog-statlist>
              <cog-statlist-item
                label="{{ 'object' | translate }}"
                iconName="{{ anomaly.properties.environment | anomalyObjectIcon }}">
                <strong cogDataId="object-{{ anomaly.properties.object }}" [inheritDataId]="true">{{
                  anomaly.properties.object | naLabel
                }}</strong>
              </cog-statlist-item>
              <cog-statlist-item
                label="{{ 'anomalousSnapshot' | translate }}"
                iconName="error_outline"
                iconClass="status-critical">
                <strong>{{ anomaly.latestTimestampUsecs | cogDate | naLabel }}</strong>
              </cog-statlist-item>
              <cog-statlist-item
                [label]="getSelectedSnapshotLabel(anomaly)"
                iconName="check_circle_outline"
                iconClass="status-info">
                <strong>{{ anomaly.selectedSnapshotPoint?.x | cogDate | naLabel }}</strong>
              </cog-statlist-item>
              <cog-statlist-item
                label="{{ 'restoreFrom' | translate }}"
                [iconName]="getIconName(anomaly)">
                <strong>{{ getVaultName(anomaly) | naLabel }}</strong>
              </cog-statlist-item>
              <cog-statlist-item label="{{ 'stageTo' | translate }}" iconName="helix:cluster">
                <strong>{{ anomaly.properties.cluster | naLabel }}</strong>
              </cog-statlist-item>
              <cog-statlist-item>
                <!-- Edit recovery point icon -->
                <cog-icon
                  [class.invisible]="!customSelectionEnabled || anomaly.isExpanded"
                  class="flex-pull-right"
                  size="md"
                  shape="edit"
                  cogDataId="anomaly-recovery-edit-{{ i }}"
                  matTooltip="{{ 'changeRecoverPoint' | translate }}">
                </cog-icon>
              </cog-statlist-item>
            </cog-statlist>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="flex-column-xs" *ngIf="anomaly.isExpanded">
          <span *ngIf="getArchivalTargets(anomaly)?.length > 0"
            class="flex-row-xs vertical-align">
            <mat-slide-toggle
              (change)="chooseSnapshot($event, anomaly)"
              class="cloud-toggle"
              labelPosition="before"
              cogDataId="choose-snapshot-toggle"
              [checked]="restoreFrom === 'vault'">
              {{ 'rpaas.ransomware.chooseSnapshot' | translate }}
            </mat-slide-toggle>
            <mat-form-field [class.invisible]="restoreFrom === 'local'">
              <mat-select [formControl]="vaultControl">
                <mat-option *ngFor="let snapshot of getArchivalTargets(anomaly)"
                  [value]="snapshot.globalVaultId">
                  {{ snapshot.archivalTarget?.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </span>
          <cog-spinner *ngIf="!anomaly.seriesData"></cog-spinner>
          <dg-ar-anomaly-chart
            #anomalyChart
            *ngIf="anomaly.seriesData"
            [seriesData]="anomaly.seriesData"
            [metric]="anomaly.seriesData.chartMetric"
            type="kRecovery"
            [selectedSnapshot]="anomaly.selectedSnapshotPoint"
            (pointSelection)="snapshotSelected($event, anomaly)">
          </dg-ar-anomaly-chart>
          <dg-ar-snapshot-info
            [selectedSnapshot]="anomaly.selectedSnapshotPoint"
            [seriesData]="anomaly.seriesData"
            *ngIf="isCleanRoomRecoveryPhase1Enabled">
          </dg-ar-snapshot-info>
        </div>

        <!-- CogDataId is referenced with index as this is the simplest unique factor.
        Cluster name / VM can be same with multiple anomalies. -->
        <mat-action-row *ngIf="anomaly.isExpanded">
          <button
            mat-button
            cogDataId="anomaly-recovery-cancel-{{ i }}"
            [disabled]="isSubmitting"
            (click)="revertToLastCleanSnapshot(anomaly)">
            {{ 'cancel' | translate }}
          </button>
          <button
            mat-button
            cogDataId="anomaly-recovery-apply-{{ i }}"
            color="primary"
            [disabled]="isSubmitting"
            (click)="anomaly.isExpanded = false">
            {{ 'apply' | translate }}
          </button>
        </mat-action-row>
      </mat-expansion-panel>
    </div>
  </div>
</div>
