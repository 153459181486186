import { AppsConfig, Cluster, NetworkInterface, Vlan } from '@cohesity/api/v1';

/**
 * Represents application settings.
 *
 * This wraps both application vlans and config.
 */
export class AppSettings {
  /** Application configuration */
  config: AppsConfig;

  /** Application vlans */
  vlans: Vlan[];


  /** Application VIPS -> Virtual IPs */
  vip: string[];

  /** Cluster information  */
  clusterInfo: Cluster;

  /** Returns true if app is enabled, else false. */
  get appsEnabled(): boolean {
    return this.config.marketplaceAppsMode !== 'kDisabled';
  }

  /** Returns true if external traffic is allowed, else false. */
  get allowExternalTraffic(): boolean {
    return !!this.config.allowExternalTraffic;
  }

  constructor(config: AppsConfig, vlans: Vlan[] = [], clusterInfo: Cluster = {}, vips: NetworkInterface[] = []) {
    this.config = config;
    this.vlans = vlans;
    this.vip = (vips || []).flatMap((nInterface: NetworkInterface) => nInterface.virtualIp?.split(',') || []);
    this.clusterInfo = clusterInfo;
  }
}
