import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConnectionData } from '../../../dms-registrations/aws/dms-aws-saas-connection/dms-aws-saas-connection.component';

/**
 * Data structure for the dialog component.
 */
interface DialogContext {
  /**
   * Connetion data
   */
  data?: ConnectionData;
}

/**
 * Retry dialog that allows user to retry a DMaas connection.
 */
@Component({
  selector: 'coh-retry-connection-dialog',
  templateUrl: './retry-connection-dialog.component.html',
  styleUrls: ['./retry-connection-dialog.component.scss']
})
export class RetryConnectionDialogComponent implements OnInit {
  /**
   * Title of dialog.
   */
  title = 'sources.aws.retrySaasConnections';

  /**
   * Cancel button label. Default to 'Cancel'.
   */
  cancelLabel = 'cancel';

  /**
   * Confirmation button label. Default to 'Retry'.
   */
  confirmLabel = 'retry';

  /**
   * Form group to validate.
   */
  formGroup: UntypedFormGroup;

  /**
   * Connection data to be displayed for the user.
   */
  data: ConnectionData;

  /**
   * Tags after being formatted as string from array format.
   */
  formattedTags = '';

  /**
   * Dns server after being formatted as string from array format.
   */
  formattedDns = '';

  /**
   * Ntp server after being formatted as string from array format.
   */
  formattedNtp = '';

  constructor(
    public dialogRef: MatDialogRef<RetryConnectionDialogComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public context: DialogContext
  ) {
    if (context) {
      if (context.data) {
        this.data = context.data;
      }
    }
    // Set numConnectors value from setup connections dialog.
    this.formGroup = this.fb.group({
      numConnectors: new FormControl(this.data.currentNumOfConnectors ||
        this.data.expectedNumberOfRigels || null),
    });
  }

  ngOnInit() {
    // Iterate through tags array and format to display as comma separated values.
    if (this.data.rigelCloudInfraInfo?.awsRigelInfraInfo?.tags?.length) {
      this.data.rigelCloudInfraInfo?.awsRigelInfraInfo?.tags.forEach(tag => {
        this.formattedTags = this.formattedTags.concat(tag.key, '=', tag.value, ',');
      });
    }
    if (this.data.rigelCloudInfraInfo?.awsRigelInfraInfo?.dnsServers?.length) {
      const dnsServers = this.data.rigelCloudInfraInfo?.awsRigelInfraInfo?.dnsServers;
      this.formattedDns = dnsServers.join(', ');
    }

    if (this.data.rigelCloudInfraInfo?.awsRigelInfraInfo?.ntpServers?.length) {
      const ntpServers = this.data.rigelCloudInfraInfo?.awsRigelInfraInfo?.ntpServers;
      this.formattedNtp = ntpServers.join(', ');
    }

    if (this.data.rigelCloudInfraInfo?.awsRigelInfraInfo?.securityGroupId) {
      // Format security group for display
      this.data.rigelCloudInfraInfo.awsRigelInfraInfo.securityGroupId = this.data.rigelCloudInfraInfo?.awsRigelInfraInfo?.securityGroupId?.split(',').join(', ');
    }
  }

  /**
   * Method called to emit retry event.
   */
  onRetry() {
    this.dialogRef.close(this.formGroup.get('numConnectors').value);
  }
}
