import { Component, Inject, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DmsServiceApi } from '@cohesity/api/dms';
import { McmSource, McmSourceInfo, SourceServiceApi } from '@cohesity/api/v2';
import { SnackBarService } from '@cohesity/helix';
import { getUserTenantId, IrisContextService } from '@cohesity/iris-core';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { finalize } from 'rxjs/operators';
import { Environment } from 'src/app/shared';

import { DmsAwsService } from '../../../aws/services/aws.service';

/**
 * This is a dialog component that unregister source.
 *
 * Ex. this.dialogService.open('unregister-source')
 */
@Component({
  selector: 'coh-dms-unregister-source',
  templateUrl: './dms-unregister-source.component.html',
  styleUrls: ['./dms-unregister-source.component.scss']
})
export class DmsUnregisterSourceComponent extends AutoDestroyable {

  /**
   * Holds the name of the source.
   */
  name: string;

  /**
   * Holds the type of the source.
   */
  type: string;

  /**
   * Holds the name of the rigel.
   */
  rigelName = '';

  /**
   * Remove rigel with the source.
   */
  removeRigel = new UntypedFormControl(false);

  /**
   * Indicates whether any API call is processing.
   */
  loading = false;

  /**
   * Form group that represents the source
   */
  unregisterForm: UntypedFormGroup = new UntypedFormGroup({
    removeRigel: this.removeRigel,
    confirmUnregister: new UntypedFormControl(null, [Validators.pattern('^([yY][eE][sS])$')]),
  });

  /**
   * constructor
   *
   * @param   data   The Dialog data.
   */
  constructor(
    @Optional() private dialogRef: MatDialogRef<DmsUnregisterSourceComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {
      source: McmSource;
      sourceInfo: McmSourceInfo;
    },
    private sourceService: SourceServiceApi,
    private ajaxHandler: AjaxHandlerService,
    private translate: TranslateService,
    private snackbarService: SnackBarService,
    readonly dmsServiceApi: DmsServiceApi,
    readonly dmsAwsService: DmsAwsService,
    private irisCtx: IrisContextService,
    private stateService: StateService,
  ) {
    super();
    const {environment, type= 'kVCenter', name} = data.source;
    this.name = name;
    this.type = this.translate.instant(`enum.sourceType.${environment}.${type}`);
  }

  /**
   * Triggers the unregister the source.
   */
  unregister() {
    this.loading = true;

    // For AWS, two Api calls have to be made.
    if (this.data.source.environment === Environment.kAWS) {
      this.deleteAwsSource();
      return;
    }

    if (this.unregisterForm.value.removeRigel) {
      // TODO(Sam): Handle unregister for rigel as well.
      // The API is not yet ready.
    } else {
      this.deleteMcmSource();
    }
  }

  /**
   * Delete MCM Protection
   */
  deleteMcmSource() {
    const id = this.data.sourceInfo.registrationId;
    const regionId = this.data.sourceInfo.regionId;
    this.sourceService.McmDeleteProtectionSourceRegistration(id, regionId)
      .pipe(
        this.untilDestroy(),
        finalize(() => this.loading = false),
      )
      .subscribe(
        () => {
          this.snackbarService.open(
            this.translate.instant('unregisterSourceSuccessful'),
            'success',
          );

          if (this.data.source.environment === Environment.kPhysical) {
            this.stateService.go('dms-sources.landing');
          }

          this.dialogRef.close(true);
        },
        err => this.ajaxHandler.errorMessage(err),
      );
  }

  /**
   * Delete AWS source.
   */
  deleteAwsSource() {
    const id = this.data.sourceInfo.registrationId;
    const regionId = this.data.sourceInfo.regionId;
    this.sourceService.McmDeleteProtectionSourceRegistration(id, regionId)
      .pipe(
        this.untilDestroy(),
        finalize(() => this.loading = false),
      )
      .subscribe(
        () => {
          // Can't track this call because source is already unregistered.
          this.dmsAwsService.deleteCft({
            tenantId: getUserTenantId(this.irisCtx.irisContext),
            destinationRegionId: this.data.sourceInfo.regionId,
            awsAccountNumber: this.name.split('/', 1)[0],
          }).pipe(this.untilDestroy()).subscribe();

          this.snackbarService.open(
            this.translate.instant('unregisterSourceSuccessful'),
            'success',
          );
          this.dialogRef.close(true);
        },
        err => this.ajaxHandler.errorMessage(err),
      );
  }
}
