/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AppliedPatches } from '../models/applied-patches';
import { ServicePatchLevels } from '../models/service-patch-levels';
import { RevertPatchesRequest } from '../models/revert-patches-request';
import { AvailablePatches } from '../models/available-patches';
import { PatchesDetails } from '../models/patches-details';
import { ApplyPatchesRequest } from '../models/apply-patches-request';
import { PatchOperationStatus } from '../models/patch-operation-status';
import { PatchesHistory } from '../models/patches-history';
@Injectable({
  providedIn: 'root',
})
class PatchManagementService extends __BaseService {
  static readonly GetAppliedPatchesPath = '/patch-management/applied-patches';
  static readonly RevertPatchesPath = '/patch-management/applied-patches';
  static readonly GetAvailablePatchesPath = '/patch-management/available-patches';
  static readonly ImportPatchesPath = '/patch-management/available-patches';
  static readonly ApplyPatchesPath = '/patch-management/available-patches';
  static readonly GetPatchOperationStatusPath = '/patch-management/operation-status';
  static readonly GetPatchesHistoryPath = '/patch-management/patches-history';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get applied patches
   *
   * Returns a list of currently applied patches that are running on the cluster.
   * @param params The `PatchManagementService.GetAppliedPatchesParams` containing the following parameters:
   *
   * - `service`: Specifies optional service name whose current patch is returned. If it is not specified, all the applied patches are returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeDetails`: Specifies whether to return the details of all the fixes in the patch. By default, returns only the most recent fix made for the service in the patch.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAppliedPatchesResponse(params: PatchManagementService.GetAppliedPatchesParams): __Observable<__StrictHttpResponse<AppliedPatches>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.service != null) __params = __params.set('service', params.service.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeDetails != null) __params = __params.set('includeDetails', params.includeDetails.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patch-management/applied-patches`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AppliedPatches>;
      })
    );
  }
  /**
   * Get applied patches
   *
   * Returns a list of currently applied patches that are running on the cluster.
   * @param params The `PatchManagementService.GetAppliedPatchesParams` containing the following parameters:
   *
   * - `service`: Specifies optional service name whose current patch is returned. If it is not specified, all the applied patches are returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeDetails`: Specifies whether to return the details of all the fixes in the patch. By default, returns only the most recent fix made for the service in the patch.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAppliedPatches(params: PatchManagementService.GetAppliedPatchesParams): __Observable<AppliedPatches> {
    return this.GetAppliedPatchesResponse(params).pipe(
      __map(_r => _r.body as AppliedPatches)
    );
  }

  /**
   * Revert patches
   *
   * Revert an applied service patch and its dependencies.
   * @param params The `PatchManagementService.RevertPatchesParams` containing the following parameters:
   *
   * - `body`: Request to revert patches.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RevertPatchesResponse(params: PatchManagementService.RevertPatchesParams): __Observable<__StrictHttpResponse<ServicePatchLevels>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/patch-management/applied-patches`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ServicePatchLevels>;
      })
    );
  }
  /**
   * Revert patches
   *
   * Revert an applied service patch and its dependencies.
   * @param params The `PatchManagementService.RevertPatchesParams` containing the following parameters:
   *
   * - `body`: Request to revert patches.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RevertPatches(params: PatchManagementService.RevertPatchesParams): __Observable<ServicePatchLevels> {
    return this.RevertPatchesResponse(params).pipe(
      __map(_r => _r.body as ServicePatchLevels)
    );
  }

  /**
   * Get available patches
   *
   * Returns a list of patches that are available and ready to apply on the cluster.
   * @param params The `PatchManagementService.GetAvailablePatchesParams` containing the following parameters:
   *
   * - `service`: Specifies optional service name whose available patch is returned. If it is not specified, available patches for all the serivces are returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeDetails`: Specifies whether to return the description of all the fixes in the patch. By default, returns only the most recent fix made for the service in the patch.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAvailablePatchesResponse(params: PatchManagementService.GetAvailablePatchesParams): __Observable<__StrictHttpResponse<AvailablePatches>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.service != null) __params = __params.set('service', params.service.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeDetails != null) __params = __params.set('includeDetails', params.includeDetails.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patch-management/available-patches`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AvailablePatches>;
      })
    );
  }
  /**
   * Get available patches
   *
   * Returns a list of patches that are available and ready to apply on the cluster.
   * @param params The `PatchManagementService.GetAvailablePatchesParams` containing the following parameters:
   *
   * - `service`: Specifies optional service name whose available patch is returned. If it is not specified, available patches for all the serivces are returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeDetails`: Specifies whether to return the description of all the fixes in the patch. By default, returns only the most recent fix made for the service in the patch.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetAvailablePatches(params: PatchManagementService.GetAvailablePatchesParams): __Observable<AvailablePatches> {
    return this.GetAvailablePatchesResponse(params).pipe(
      __map(_r => _r.body as AvailablePatches)
    );
  }

  /**
   * Import patches
   *
   * Import a patch or a hotfix to the cluster.
   * @param params The `PatchManagementService.ImportPatchesParams` containing the following parameters:
   *
   * - `patch`:
   *
   * - `fileName`:
   *
   * - `checksum`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ImportPatchesResponse(params: PatchManagementService.ImportPatchesParams): __Observable<__StrictHttpResponse<PatchesDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.patch != null) { __formData.append('patch', params.patch as string | Blob);}
    if (params.fileName != null) { __formData.append('fileName', params.fileName as string | Blob);}
    if (params.checksum != null) { __formData.append('checksum', params.checksum as string | Blob);}
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/patch-management/available-patches`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PatchesDetails>;
      })
    );
  }
  /**
   * Import patches
   *
   * Import a patch or a hotfix to the cluster.
   * @param params The `PatchManagementService.ImportPatchesParams` containing the following parameters:
   *
   * - `patch`:
   *
   * - `fileName`:
   *
   * - `checksum`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ImportPatches(params: PatchManagementService.ImportPatchesParams): __Observable<PatchesDetails> {
    return this.ImportPatchesResponse(params).pipe(
      __map(_r => _r.body as PatchesDetails)
    );
  }

  /**
   * Apply patches
   *
   * Apply a service patch and its dependencies.
   * @param params The `PatchManagementService.ApplyPatchesParams` containing the following parameters:
   *
   * - `body`: Request to apply patches.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ApplyPatchesResponse(params: PatchManagementService.ApplyPatchesParams): __Observable<__StrictHttpResponse<ServicePatchLevels>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/patch-management/available-patches`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ServicePatchLevels>;
      })
    );
  }
  /**
   * Apply patches
   *
   * Apply a service patch and its dependencies.
   * @param params The `PatchManagementService.ApplyPatchesParams` containing the following parameters:
   *
   * - `body`: Request to apply patches.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ApplyPatches(params: PatchManagementService.ApplyPatchesParams): __Observable<ServicePatchLevels> {
    return this.ApplyPatchesResponse(params).pipe(
      __map(_r => _r.body as ServicePatchLevels)
    );
  }

  /**
   * Get patch operation status
   *
   * Returns the status of the current or the last patch operation. There can be only one active patch operation at any given time.
   * @param params The `PatchManagementService.GetPatchOperationStatusParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeDetails`: Specifies whether to return details of all service patch opertions on all nodes. By default, returns whether there is a patch operation in progress or not.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetPatchOperationStatusResponse(params: PatchManagementService.GetPatchOperationStatusParams): __Observable<__StrictHttpResponse<PatchOperationStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeDetails != null) __params = __params.set('includeDetails', params.includeDetails.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patch-management/operation-status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PatchOperationStatus>;
      })
    );
  }
  /**
   * Get patch operation status
   *
   * Returns the status of the current or the last patch operation. There can be only one active patch operation at any given time.
   * @param params The `PatchManagementService.GetPatchOperationStatusParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeDetails`: Specifies whether to return details of all service patch opertions on all nodes. By default, returns whether there is a patch operation in progress or not.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetPatchOperationStatus(params: PatchManagementService.GetPatchOperationStatusParams): __Observable<PatchOperationStatus> {
    return this.GetPatchOperationStatusResponse(params).pipe(
      __map(_r => _r.body as PatchOperationStatus)
    );
  }

  /**
   * Get patches history
   *
   * Get the history of all the patch management operations.
   * @param params The `PatchManagementService.GetPatchesHistoryParams` containing the following parameters:
   *
   * - `service`: Specifies optional service name whose patch operation history is returned. If it is not specified, patch operations of all the serivces are returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetPatchesHistoryResponse(params: PatchManagementService.GetPatchesHistoryParams): __Observable<__StrictHttpResponse<PatchesHistory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.service != null) __params = __params.set('service', params.service.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patch-management/patches-history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PatchesHistory>;
      })
    );
  }
  /**
   * Get patches history
   *
   * Get the history of all the patch management operations.
   * @param params The `PatchManagementService.GetPatchesHistoryParams` containing the following parameters:
   *
   * - `service`: Specifies optional service name whose patch operation history is returned. If it is not specified, patch operations of all the serivces are returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetPatchesHistory(params: PatchManagementService.GetPatchesHistoryParams): __Observable<PatchesHistory> {
    return this.GetPatchesHistoryResponse(params).pipe(
      __map(_r => _r.body as PatchesHistory)
    );
  }
}

module PatchManagementService {

  /**
   * Parameters for GetAppliedPatches
   */
  export interface GetAppliedPatchesParams {

    /**
     * Specifies optional service name whose current patch is returned. If it is not specified, all the applied patches are returned.
     */
    service?: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies whether to return the details of all the fixes in the patch. By default, returns only the most recent fix made for the service in the patch.
     */
    includeDetails?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for RevertPatches
   */
  export interface RevertPatchesParams {

    /**
     * Request to revert patches.
     */
    body: RevertPatchesRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetAvailablePatches
   */
  export interface GetAvailablePatchesParams {

    /**
     * Specifies optional service name whose available patch is returned. If it is not specified, available patches for all the serivces are returned.
     */
    service?: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies whether to return the description of all the fixes in the patch. By default, returns only the most recent fix made for the service in the patch.
     */
    includeDetails?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ImportPatches
   */
  export interface ImportPatchesParams {
    patch: Blob;
    fileName: string;
    checksum: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ApplyPatches
   */
  export interface ApplyPatchesParams {

    /**
     * Request to apply patches.
     */
    body: ApplyPatchesRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetPatchOperationStatus
   */
  export interface GetPatchOperationStatusParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies whether to return details of all service patch opertions on all nodes. By default, returns whether there is a patch operation in progress or not.
     */
    includeDetails?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetPatchesHistory
   */
  export interface GetPatchesHistoryParams {

    /**
     * Specifies optional service name whose patch operation history is returned. If it is not specified, patch operations of all the serivces are returned.
     */
    service?: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { PatchManagementService }
