// Component: widgets-layout

; (function (angular, undefined) {

  var componentNameParts = 'widgets-layout';
  var componentName = 'widgetsLayout';
  var instanceCount = 0;
  var widgetsInjections =
    ['C.compileComponent', 'C.colorDot', 'C.logList', 'C.statList'];

  angular.module('C.widgets', widgetsInjections)
    .controller('WidgetsLayoutCtrl', widgetsLayoutFn)
    .component(componentName, {
      bindings: {
        /**
         * widgets
         * @type   {Object[]}   Properties [{
         *   component:   {String}   Component to render in grid cell.
         *   posX:        {Number}   X coordinates of widget cell.
         *   posY:        {Number}   Y coordinates of widget cell.
         *   colSpan:     {Number}   number of grid cells widget should span
         *                           horizontally (left to right).
         *   rowSpan:     {Number}   number of grid cells widget should span
         *                           vertically (top to bottom).
         *   bindings:    {Object}   Optional bindings for the component.
         *   scope:       {Object}   Optional scope under which widget to be
         *                           compiled else parentScope would be used.
         * }]
         *
         * @example
         * widgets = [{
         *    component:      'jobRun',
         *    posX:           2,
         *    posY:           1,
         *    colSpan:        2,
         *    rowSpan:        1,
         *    bindings: {
         *      viewType:     'pie',
         *      data:         '$ctrl.chartData',
         *      titleKey:     'The title is {{$ctrl.name}}',
         *      onClick:      '$ctrl.handleOnClick(message)',
         *    },
         * }]
         *
         * <job-run
            view-type="pie"
            data="$ctrl.chartData"
            title-key="The title is {{$ctrl.name}}"
            on-click="$ctrl.handleOnClick(message)"></job-run>
         */
        widgets: '<',

        // Optional parentWidget config is required for rendering child widgets
        parentWidget: '<?',

        /**
         * Optional config
         * @type   {Object}   Properties {
         *   nColumn:     {Number}   [3]          Number of grid columns needed,
         *   minHeight:   {String}   [15.625rem]  min height of grid cell,
         *   gutter:      {String}   [0.625rem]   gutter width b/w cells,
         *   autoHeight:  {boolea}   [false]      allow widgets to expand
         * }
         */
        config: '<?',
      },
      require: {
        widgetsLayout: '^^?widgetsLayout',
      },
      transclude: true,
      controller: 'WidgetsLayoutCtrl',
      templateUrl: 'app/global/c-widgets/widgets-layout.html',
    });

  /**
   * @ngdoc component
   * @name C.widgets:widgetsLayout
   * @function
   *
   * @description
   * This component creates the grid layout and renders the widgets in the grid
   * cells as per there X,Y coordinates.
   *
   * @example
   * <widgets-layout
      config="$ctrl.layoutConfig"
      widgets="$ctrl.widgets"></widgets-layout>
   */
  function widgetsLayoutFn(_, $element, $attrs, $transclude) {
    var $ctrl = this;
    var instanceClass = [componentNameParts, instanceCount++].join('-');

    var gridOps = {
      nColumn: 3,
      minHeight: '15.625rem',
      gutter: '0.625rem',
      autoHeight: false
    };

    var defaultWidgetConfig = {
      component: undefined,
      bindings: undefined,
      posX: 1,
      posY: 1,
      offsetX: 0,
      offsetY: 0,
      colSpan: 1,
      rowSpan: 1,
    };

    // adding unique instance class to allow multiple widgets layout to co-exist
    // in a same page.
    $attrs.$addClass(instanceClass);

    if (!$ctrl.config) {
      $ctrl.config = {};
    }

    $ctrl.config.nColumn = $ctrl.config.nColumn || gridOps.nColumn;
    $ctrl.config.minHeight = $ctrl.config.minHeight || gridOps.minHeight;
    $ctrl.config.gutter = $ctrl.config.gutter || gridOps.gutter;
    $ctrl.config.autoHeight = $ctrl.config.autoHeight || gridOps.autoHeight;

    _.assign($ctrl, {
      parentScope: undefined,

      // widgets layout API
      addMoreRows: addMoreRows,

      // component life cycle methods
      $onChanges: $onChanges,
      $onDestroy: $onDestroy,
    });

    // capture the parent scope to be used while compiling the widgets.
    $transclude(function getParentScopeFn(clone, scope) {
      $ctrl.parentScope = scope;
    });

    /**
     * Watch for binding changes and act on them accordingly.
     *
     * @method   $onChanges
     * @param    {Object}   changes   The binding changes Object
     */
    function $onChanges(changes) {
      // crafting space for child widget layout.
      if ($ctrl.widgetsLayout) {
        craftSpaceForLayout();
        updateLayoutStyling();
      }

      // update layout styling if config changed
      if (changes.config && !angular.equals(
        changes.config.currentValue, changes.config.previousValue)) {
        updateLayoutStyling();
      }

      if (changes.widgets) {
        transformWidgets();
        updateLayoutStyling();
      }
    }

    /**
     * clean up on component destroy
     *
     * @method   $onDestroy
     */
    function $onDestroy() {
      if ($ctrl.removeAddedRows) {
        $ctrl.removeAddedRows();
        updateLayoutStyling();
      }
    }

    /**
     * Transform widgets by adding default widget config for each widget.
     *
     * @method   transformWidgets
     */
    function transformWidgets() {
      $ctrl.widgets = $ctrl.widgets.map(
        function eachWidget(widget) {
          return _.assign({}, defaultWidgetConfig, widget);
        }
      );
    }

    /**
     * Will add number of rows after an position y for each widget.
     *
     * @method   addMoreRows
     * @param    {number}     nRows      The number of rows to add.
     * @param    {number}     afterPosY  The posY after which rows to be added.
     * @return   {Function}   The fn which remove the added rows.
     */
    function addMoreRows(nRows, afterPosY) {
      var widgetsMofified;

      widgetsMofified = $ctrl.widgets.filter(
        function eachWidget(widget) {
          if (widget.posY > afterPosY) {
            widget.offsetY += nRows;
            return true;
          }

          return false;
        }
      );

      return function removeAddedRows() {
        widgetsMofified.forEach(function eachWidget(widget) {
          widget.offsetY -= nRows;
        });
      };
    }

    /**
     * Gets space for child layout from parent widget
     *
     * @method   craftSpaceForLayout
     */
    function craftSpaceForLayout() {
      $ctrl.config.nRows = getNumberOfRows();

      // remove previously added rows
      if ($ctrl.removeAddedRows) {
        $ctrl.removeAddedRows();
      }

      // add rows after parent widget
      $ctrl.removeAddedRows = $ctrl.widgetsLayout.addMoreRows(
        $ctrl.config.nRows,
        $ctrl.parentWidget.posY
      );
    }

    /**
     * Gets the number of rows.
     *
     * @method   getNumberOfRows
     * @return   {number}   The number of rows.
     */
    function getNumberOfRows() {
      var startRowPos = $ctrl.widgets[0].posY;
      var lastRowPos = startRowPos;

      $ctrl.widgets.forEach(function eachWidget(widget) {
        if (startRowPos > widget.posY) {
          startRowPos = widget.posY;
        }

        if (lastRowPos < widget.posY + widget.rowSpan) {
          lastRowPos = widget.posY + widget.rowSpan;
        }
      });

      return lastRowPos - startRowPos;
    }

    /**
     * update CSS grid model layout styling.
     *
     * @method   updateLayoutStyling
     */
    function updateLayoutStyling() {
      var layoutStyling =
        '{componentNameParts}.{instanceClass} {' +
        'display: grid;' +

        // allow fixed width grid columns & if a widget want to expand then it
        // should contact widgets layout for providing more cells.
        'grid-template-columns: repeat({nColumn}, minmax(0, 1fr));' +
        'grid-auto-rows: {minHeight};' +
        'grid-gap: {gutter};' +

        // add IE specific styling
        '{stylingForIE}' +

        // set child widget layout position inside a parent widget layout.
        ($ctrl.widgetsLayout ? getChildLayoutPosition() : '') +
        '}';

      // set min height for cell and allow child widgets layout to expand the
      // cell dynamically.
      var minHeight = 'minmax(' + $ctrl.config.minHeight + ', auto)';

      layoutStyling = layoutStyling
        .replace('{componentNameParts}', componentNameParts)
        .replace('{instanceClass}', instanceClass)
        .replace('{nColumn}', $ctrl.config.nColumn)
        .replace('{minHeight}', minHeight)
        .replace('{gutter}', $ctrl.config.gutter)
        .replace('{stylingForIE}', getIELayoutStyling());

      $element.find('style')[0].innerHTML = layoutStyling;
    }

    /**
     * Gets the CSS grid model layout styling for IE10/11.
     *
     * @method   getIELayoutStyling
     * @return   {Array}   The ie layout styling.
     */
    function getIELayoutStyling() {
      var gridRows;
      var gridColumns;
      var gutter = ' ' + $ctrl.config.gutter + ' ';

      // max number of rows needed
      var rowCount = getNumberOfRows();

      gridRows = _.fill(new Array(rowCount), 'auto').join(gutter);
      gridColumns = _.fill(new Array($ctrl.config.nColumn), '1fr').join(gutter);

      // TODO(veetesh): try moving static styling to css file.
      return [
        'display: -ms-grid',
        '-ms-grid-rows: ' + gridRows,
        '-ms-grid-columns: ' + gridColumns,
        null // an empty item so that join will add semi-colon for last item.
      ].join('; ');
    }

    /**
     * Returns position of an child widget layout inside a widget layout.
     *
     * @method   getChildLayoutPosition
     */
    function getChildLayoutPosition() {
      var styleStr = '';
      var widgetStyling = {
        'grid-area': [
          $ctrl.parentWidget.posY + 1,
          1,
          $ctrl.parentWidget.posY + 1 + $ctrl.config.nRows,
          $ctrl.config.nColumn + 1
        ].join('/'),
        '-ms-grid-row':
          (2 * ($ctrl.parentWidget.posY + 1) - 1),
        '-ms-grid-row-span': (2 * $ctrl.config.nRows - 1),
        '-ms-grid-column': 1,
        '-ms-grid-column-span': (2 * $ctrl.config.nColumn - 1),
      };

      styleStr = Object.keys(widgetStyling).map(
        function eachStyle(key) {
          return key + ': ' + widgetStyling[key];
        }
      ).join('; ');

      return styleStr + ';';
    }

  }

})(angular);
