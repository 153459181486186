import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LifecycleRuleFilterTag } from '@cohesity/api/v2';
import { Controls, NgxSubFormComponent, subformComponentProviders, takeUntilDestroyed } from 'ngx-sub-form';

/**
 * Rule filter form models.
 */
export interface RuleFilterFormModel {
  prefix?: string;
  tags?: LifecycleRuleFilterTag[];
}

@Component({
  selector: 'coh-rule-scope-filter',
  templateUrl: './rule-scope-filter.component.html',
  styleUrls: ['./rule-scope-filter.component.scss'],
  providers: subformComponentProviders(RuleScopeFilterComponent),
})
export class RuleScopeFilterComponent extends NgxSubFormComponent<RuleFilterFormModel> implements OnInit {

  /**
   * Filter tags.
   */
  tags: LifecycleRuleFilterTag[];

  /**
   *  Get default form controls.
   *
   * @returns Default form controls.
   */
  protected getFormControls(): Controls<RuleFilterFormModel> {
    return {
      prefix: new UntypedFormControl(null, Validators.required),
      tags: new UntypedFormArray([]),
    };
  }

  ngOnInit() {
    (this.formGroupControls.tags as UntypedFormArray).valueChanges
      .pipe(takeUntilDestroyed(this))
      .subscribe(newValue => {
        if (this.containsNotNullValue(newValue)) {
          this.formGroupControls.prefix.clearValidators();
          this.formGroupControls.prefix.setErrors(null);
        } else {
          this.formGroupControls.prefix.setValidators(Validators.required);
        }
      });
  }

  /**
   * ngx-subform transform to form group.
   *
   * Required to append the formArray control.
   */
  transformToFormGroup(
    newValue: RuleFilterFormModel,
    defaultValue: RuleFilterFormModel
  ): RuleFilterFormModel {
    newValue?.tags?.forEach(({ key, value }) => {
      (this.formGroupControls.tags as UntypedFormArray).push(new UntypedFormGroup({
        key: new UntypedFormControl(key || null, Validators.required),
        value: new UntypedFormControl(value || null, Validators.required),
      }));
    });

    return {
      ...defaultValue,
      ...newValue,
    };
  }

  constructor() {
    super();
  }

  /**
   * Add new tag.
   */
  addTag() {
    (this.formGroupControls.tags as UntypedFormArray).push(new UntypedFormGroup({
      key: new UntypedFormControl(null, Validators.required),
      value: new UntypedFormControl(null, Validators.required),
    }));
  }

  /**
   * Remove tag.
   *
   * @param   index   The index of the tag to be removed.
   */
  removeTag(index: number) {
    (this.formGroupControls.tags as UntypedFormArray).removeAt(index);
  }

  /**
   * Helper function to identify if formGroupControls.tags control contains at least one tag.
   *
   * @param   formValue   Tab form value.
   * @returns   True if tag form contains at least one tag.
   */
  private containsNotNullValue(formValue: LifecycleRuleFilterTag[]): boolean {
    let containsNotNullValue = false;
    formValue?.forEach((value) => {
      if (value.key !== null && value.value !== null) {
        containsNotNullValue = true;
      }
    });
    return containsNotNullValue;
  }

}
