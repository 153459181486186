// Component: Search result container

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.office365Recovery')
    .controller('Office365SearchContainerCtrl', office365SearchContainerCtrlFn)
    .component('office365SearchContainer', {
      templateUrl:
        'app/protection/recovery/office365/search/container/container.html',
      controller: 'Office365SearchContainerCtrl',
    });

  /**
   * @ngdoc component
   * @name C.office365Recovery:office365SearchContainer
   * @function
   *
   * @description
   * Component to render the search results for Office 365 entities.
   *
   * @example
   * <office365-search-container></office365-search-container>
   */
  function office365SearchContainerCtrlFn(_, RecoveryStore, $state, evalAJAX,
    PubRestoreService, JobRunsService, $translate, SlideModalService,
    SearchServiceFormatter, PubRestoreServiceFormatter, cMessage) {
    var $ctrl = this;

    _.assign($ctrl, {
      // Lifecycle methods.
      $onInit: $onInit,

      // Methods exposed to template.
      browseOneDrive: browseOneDrive,
      getJobName: getJobName,
      modifyCart: modifyCart,
      recoverSingleMailbox: recoverSingleMailbox,
    });

    /**
     * Component initialization hook.
     *
     * @method   $onInit
     */
    function $onInit() {
      $ctrl.shared = RecoveryStore.get();
    }

    /**
     * Modifies the cart to add/remove selected entity.
     *
     * @method   modifyCart
     * @param    {object}    entity    Entity selected to be added to the cart
     * @param    {object}    event     specifies the event object.
     */
    function modifyCart(entity, event) {
      if (event.target.checked && !$ctrl.shared.cart.has(entity)) {
        _updateItemVersionInCart(entity);
      } else if ($ctrl.shared.cart.has(entity)) {
        $ctrl.shared.cart.remove(entity);
      }
    }

    /**
     * Adds single mailbox to the cart and redirects to the recovery options
     * page.
     *
     * @method   recoverSingleMailbox
     * @param    {object}   entity    Specifies mailbox entity to be recovered.
     */
    function recoverSingleMailbox(entity) {
      if (!$ctrl.shared.cart.has(entity)) {
        _updateItemVersionInCart(entity);
      }
      $state.go('recover-office365.options');
    }

    /**
     * Fetches the Protection job name from the cart map using Job ID.
     *
     * @method   getJobName
     * @param    {number}   jobId   Specifies the job ID.
     * @return   {string}   Returns the job name
     */
    function getJobName(jobId) {
      if (!jobId) { return ''; }

      return $ctrl.shared.jobsCart.cartMap[jobId] ?
        $ctrl.shared.jobsCart.cartMap[jobId].name :
        $translate.instant('deletedJob');
    }

    /**
     * Launches the cVmBrowser and enables browse of OneDrive for the specified
     * office365 user. Items selected within the cVmBrowser are then added to
     * the recovery cart.
     *
     * NOTE: An office365 job may backup a user's Mailbox, OneDrive or both.
     * Currently, there is no way to know beforehand that a user has a
     * OneDrive backed up as the leaf level entity is still 'kUser'. Hence,
     * this method assumes that the user's OneDrive is backed up along with its
     * mailbox.
     *
     * Error will be thrown for the /fileStat and /directoryList API calls for
     * users with no OneDrive backed up.
     *
     * TODO(tauseef): Once the info is persisted in ES at the object level for
     * the given user's Mailbox or OneDrive(s), use the same to filter the
     * result within the OneDrive browse flow.
     *
     * @method   browseOneDrive
     * @param    {object}   office365Entity   Specifies the office365 object
     *                                        whose OneDrive is to be browsed.
     */
    function browseOneDrive(office365Entity) {
      SlideModalService.newModal({
        templateUrl: 'app/global/c-vm-browser/c-vm-browser.html',
        controller: 'cVMBrowserParentController',
        size: 'full',
        keyboard: false,
        fullHeight: true,
        resolve: {
          pageConfig: {
            // Transform the office365 user selected to be browsed to the
            // compatible structure for browsing.
            // Refer transformPublicSearchResultToPrivate(...) for details.
            vm: SearchServiceFormatter
              .transformPublicSearchResultToPrivate(office365Entity),
          },
        },
      }).then(function onBrowseSuccess(response) {
        // Add the items to the cart with the current version.
        $ctrl.shared.cart.addAll(
          // Transform the selected items within the cVMBrowser's cart to
          // compatible structure for recovery.
          // Refer transformPrivateBrowseResultToPublic(...) for details.
          PubRestoreServiceFormatter.transformPrivateBrowseResultToPublic(
            response.files, 'kO365')
        );

        if (response.saveAndContinue) {
          $state.go('recover-office365.options');
        }
      });
    }

    /**
     * Fetches and adds/updates the versions for the mailbox item/job. Versions
     * aren't required in case of mailbox, as the search result for Objects
     * contains the versions too, but incase of emails and folders version info
     * has to separately requested from Yoda.
     *
     * @method   _updateItemVersionInCart
     * @param    {object}   item    Specifies the Outlook item object
     * @return   {object}   Outlook item object with decorated properties.
     */
    function _updateItemVersionInCart(item) {

      switch(true) {
        // Incase the item is the mailbox or the office365 User then versions
        // are not to be fetched as the ES search result contains the
        // snapshot versions and the item can be directly added to the cart.
        case item._isMailbox:
        case item._isOffice365User:
        case item._isOffice365SharePointSite:
          $ctrl.shared.cart.add(item);
          break;

        // Incase of Office365 Job, restorable Job Runs are fetched from
        // Magneto.
        case item._isOffice365UserJob:
          _updateJobRuns(item);
          break;

        // Incase of Emails/Folders, file versions are separately fetched from
        // Yoda.
        case item._isEmail:
        case item._isEmailFolder:
        case item._isFile:
        case item._isDirectory:
          _updateFileVersions(item);
          break;
      }
    }

    /**
     * Fetches and updates the Job Runs within the recovery cart.
     *
     * @method   _updateJobRuns
     * @param    {object}   item   Specifies the Job whose items are to be
     *                             recovered.
     */
    function _updateJobRuns(item) {
      var jobRunsParams = {
        jobId: item._jobId,
        excludeTasks: true,
        excludeNonRestoreableRuns: true,
      };

      $ctrl.shared.fetchingVersions = true;

      // Fetch the Job Runs.
      JobRunsService.getPublicJobRuns(jobRunsParams).then(
        function onSuccessfulRequest(response) {
          item.versions = response;
         if (item.versions[0]) {
            item._snapshot = item.versions[0];
            $ctrl.shared.cart.add(item);
          } else {
            cMessage.error({
              textKey:'snapshotAbsent',
            });
            return;
          }

          // TODO(tauseef): Update the Recovery Cart to show only selected Job.
        }, evalAJAX.errorMessage
      ).finally(function versionsReady() {
        $ctrl.shared.fetchingVersions = false;
      });
    }

    /**
     * Fetches and updates the recovery cart with the Outlook Email/Folder
     * recovery versions.
     *
     * @method   _updateFileVersions
     * @param    {object}   item   Specifies the Outlook item to be recovered.
     */
    function  _updateFileVersions(item) {
      var fileVersionParams = {
        clusterId: item._clusterId,
        clusterIncarnationId: item._clusterIncarnationId,
        filename: item._filename,
        jobId: item._jobUidId,
        sourceId: item._sourceId,
      };

      $ctrl.shared.fetchingVersions = true;

      // Fetch the available file versions.
      PubRestoreService.getFileVersions(fileVersionParams).then(
        function onSuccessfulRequest(response) {
          item.versions = response;
          if (item.versions[0]) {
            item._snapshot = item.versions[0];
            $ctrl.shared.cart.add(item);
          } else {
            cMessage.error({
              textKey:'snapshotAbsent',
            });
            return;
          }
        }, evalAJAX.errorMessage
      ).finally(function versionsReady() {
        $ctrl.shared.fetchingVersions = false;
      });
    }
  }
})(angular);