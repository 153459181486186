// Component: Browse For Leaf Entities

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.browseForTarget', [])
    .controller('BrowseForLeafEntitesController', browseFn);

  function browseFn($scope, $timeout, $q, SourceService, PubSourceService,
    PubJobService, $uibModalInstance, opts, evalAJAX, EXPANDED_NODES,
    ENV_GROUPS, ENV_TYPE_CONVERSION, _) {

    var envTypes = [].concat(opts.envTypes);

    var isPhysical = (envTypes.includes(6) && envTypes.length === 1);
    var isVM = ((envTypes.includes(1) || envTypes.includes(2)) &&
      envTypes.length === 1);
    var isView = (envTypes.includes(4) && envTypes.length === 1);

    // Always exclude resource pools as they will contain duplicated VM info
    var excludeTypes = [5];

    var objCountMap = {};

    /**
     * function to run on initial controller activation
     */
    function activate() {

      if (isView) {
        // Views will be handled internally in cSourceViews
        $scope.tree = [];
      } else {
        getSources();
      }

    }

    $scope.selectedObjectsCounts = angular.copy(objCountMap);

    // Optional additional excludeTypes params for SourceService.getSources()
    if (opts.filters.excludeTypes) {
      excludeTypes = excludeTypes.concat(opts.filters.excludeTypes);
    }

    opts.singleSelect = opts.singleSelect !== false;

    $scope.opts = opts;
    $scope.isPhysical = isPhysical;
    $scope.isVM = isVM;
    $scope.isView = isView;
    $scope.ENV_GROUPS = ENV_GROUPS;
    $scope.ENV_TYPE_CONVERSION = ENV_TYPE_CONVERSION;

    // TODO: invert vmToolsRequired so its false by defualt. VMtools required
    // was the original use case so it was defaulted true, but now it seems like
    // the exception.
    $scope.vmToolsRequired = opts.filters.requireVmTools !== undefined ?
      opts.filters.requireVmTools : envTypes.includes(1);
    $scope.sources = [];
    $scope.tree = [];
    $scope.sourceListOpts = {
      permissionSelect: _.get(opts, 'setting.permissionSelect'),
      canSelect: true,
      hostType: opts.hostType,
      singleSelect: opts.singleSelect,
      singleSelectNode: undefined,
      vmwareToolsForcedFiltering: $scope.vmToolsRequired,
      defaultNodePropertyFilter:
        _.get(opts, 'filters.defaultNodePropertyFilter'),
      canSelectNode: opts.filters && opts.filters.canSelectNode,
      ignorePhysicalAgentCheck: opts.ignorePhysicalAgentCheck,
      cloudLeafType: {kAWS: ['kEC2Instance']},
    };

    /**
    * expanded nodes of tree structure,
    * will be initialized by SourceService.processHierarchyBranch
    *
    * @type {Array}
    */
    $scope.expandedNodes = [];

    /**
    * Reset the Modal Tree
    * @param  {Int} value Source Id
    */
    $scope.selectSource = function selectSource(sourceId) {

      // reset the selections, this will disable the save/submit button
      $scope.sourceListOpts.singleSelectNode = undefined;
      $scope.selectedObjectsCounts = angular.copy(objCountMap);

      // Get New Tree Data
      getTreeData(sourceId);
    };

     /**
     * Add new source, pop the Register Source modal. When that's done,
     * select the newly created source.
     *
     * @method   registerSource
     */
    $scope.registerSource = function registerSource() {
      SourceService.registerSourceSlider(envTypes[0]).then(
        function modalSuccess(source) {
          getSources(_.get(source, 'entity.entity.id'));
        },
        function modalCanceled() {
          // reset the selections, this will disable the save/submit button
          $scope.sourceListOpts.singleSelectNode = undefined;
        }
      );
    };

    /**
    * Get Sources Data
    *
    * @param   sourceId   [number]   sourceId to preselect
    */
    function getSources(sourceId) {
      $scope.loadingSources = true;
      SourceService.getSources({
          envTypes: envTypes,
          excludeTypes: excludeTypes,
          onlyReturnOneLevel: true,
        })
        .then(
          function getSourcesSuccess(resp) {
            var selectedSourceIndex;

            if (resp &&
              resp.entityHierarchy &&
              resp.entityHierarchy.children) {
              $scope.sources = resp.entityHierarchy.children;
            }
            if ($scope.sources.length) {
              $scope.selectSource(sourceId || $scope.sources[0].entity.id);
              selectedSourceIndex = sourceId ? $scope.sources.findIndex(
                function findSelectedSource(source) {
                  return source.entity.id === sourceId;
                }) : 0;

              // use magic dot notation for selected source, since uiSelect
              // creates scoping issues.
              $scope.selectedSource = $scope.sources[selectedSourceIndex];
            }
          },

          evalAJAX.errorMessage
        ).finally(
          function afterGetSources() {
            $scope.loadingSources = false;
          }
        );

    }

    /**
    * Gets the Tree Data
    * @param  {Int} id of parent Source
    */
    function getTreeData(id) {
      var params = {
        entityId: id,
        excludeTypes: excludeTypes,
        includeVMFolders: true,
      };

      // clear tree array in case user has changed parent source
      $scope.tree = [];
      $scope.loadingTree = true;

      return SourceService.getSources(params)
        .then(function sourcesCallSuccess(response) {
          var sourceEntity =
            (response && response.entityHierarchy) ||
            { children: [] };

          var options = {
            rootEnvironment: ENV_TYPE_CONVERSION[sourceEntity.entity.type],
          };

          return PubSourceService.getOwnSource(sourceEntity.entity.id, {},
            options).then(function sourceTreeFetched(response) {
              /**
               * Remove Hosts already containing app for which registration is
               * being initiated.
               */
              if ($scope.opts.filters.registeredApp) {
                filterRegisteredAppHosts(
                  response[0],
                  ENV_TYPE_CONVERSION[$scope.opts.filters.registeredApp],
                  true);
              }

              /**
               * Remove hosts which do not have the app for which a recovery
               * is being initiated.
               */
              if ($scope.opts.filters.onlyRegisteredApp) {
                filterRegisteredAppHosts(
                  response[0],
                  ENV_TYPE_CONVERSION[$scope.opts.filters.onlyRegisteredApp],
                  false);
              }

              if (!response[0].nodes || !response[0].nodes.length) {
                $scope.sources = [];
                return;
              }
              $scope.tree = response;

            }).finally(function afterGetTreeData() {
              $scope.loadingTree = false;
            });
          }
        );
    }


    /**
     * Iterates over resources to check for Entities pre registered as Apps
     * such as Oracle/MS SQL and removes them from the tree
     *
     * @method   filterRegisteredAppHosts
     * @param    {object}   sources        The selected source.
     * @param    {string}   registeredApp  The app to filter for.
     * @param    {boolean}  exclude        True to remove app hosts, false
     *                                     to return only app hosts.
     */
    function filterRegisteredAppHosts(source, registeredApp, exclude) {
      if (!source || !source.nodes) {
        return;
      }

      source.nodes = source.nodes
        .filter(
          function checkAppExistence(node) {
            var found = !!(node.applicationNodes || []).find(
              function findApp(appNode) {
                return appNode.protectionSource.environment === registeredApp;
              });
            return exclude ? !found : found;
          }
        );
    }

    /**
     * Resolves the uibModalInstance with the selected node(s). If singleSelect
     * mode, resolves with a single entity object. If !singleSelect resolves
     * with an array of entity objects
     */
    $scope.saveSelection = function saveSelection() {
      var selectedNodes;
      var selectedHash = {};
      if (opts.singleSelect) {
        selectedNodes = isView ?
          $scope.sourceListOpts.singleSelectNode :
          $scope.sourceListOpts.singleSelectNode.protectionSource;
      } else {
        selectedNodes = [];

        if (isView) {
          selectedNodes = $scope.tree.filter(function isViewSelected(view) {
            return view._isSelected;
          });
        } else {
          $scope.tree.forEach(function buildSelectedNodes(node) {
            addSelectedLeafNodes(node, selectedNodes, selectedHash);
          });
        }
      }

      if (!isView) {
        if (angular.isArray(selectedNodes)) {
          selectedNodes =
            SourceService.publicEntitiesToPrivateEntities(selectedNodes);
        } else {
          selectedNodes =
            SourceService.publicEntityToPrivateEntity(selectedNodes);
        }
      }

      $uibModalInstance.close(selectedNodes);
    };

    /**
     * recursive function which builds and returns an array of selected nodes
     *
     * @param      {object}  node           The node
     * @param      {Array}   selectedNodes  The selected nodes
     * @param      {object}  selectedHash   The selected nodes hash, used to
     *                                      prevent duplicate nodes from being
     *                                      added to the selected nodes list
     */
    function addSelectedLeafNodes(node, selectedNodes, selectedHash) {

      selectedNodes = selectedNodes || [];
      selectedHash = selectedHash || {};

      if (node._isLeaf &&
        node._isSelected &&
        !selectedHash[node.protectionSource.id]) {
        selectedNodes.push(node.protectionSource);
        selectedHash[node.protectionSource.id] = true;
      }

      if (Array.isArray(node.nodes)) {
        node.nodes.forEach(function passThru(node) {
          addSelectedLeafNodes(node, selectedNodes, selectedHash);
        });
      }

    }

    /**
     * indicates if at least one leaf entity has been selected
     *
     * @return     {boolean}  True if leaf selected, False otherwise.
     */
    $scope.isLeafSelected = function isLeafSelected() {

      var envType =
        isView ? 'kView' : $scope.tree[0]._environment;

      if (opts.singleSelect) {
        return angular.isDefined($scope.sourceListOpts.singleSelectNode);
      }

      return PubJobService.getLeafCountByEnvironment(
        envType,
        $scope.selectedObjectsCounts
      );
    };

    /**
    * Cancel the modal, dismisses/rejects the
    * uibModalInstances with a value of 'canceled'
    */
    $scope.close = function close() {
      $uibModalInstance.dismiss('canceled');
    };

    activate();
  }

})(angular);
