<div class="login-page">
  <section class="login-form-section">
    <!-- <c-spinner ng-if="$ctrl.checkingValidCertificate"></c-spinner> -->
    <div class="login-form-section-container">
      <h1 class="login-title text-center">{{ (title || 'welcome') | translate }}</h1>
      <h2 class="login-description text-center">{{ description || 'toCohesityDataCloud' | translate }}</h2>
      <h4 class="login-description2 text-center">{{ description2 || 'login.helios.marketingBlurb' | translate }}</h4>
      <div class="helios-powered-by text-center">{{ poweredBy || 'poweredByHelios' | translate }}</div>
      <coh-login
        [domains]="domains"
        [authModes]="availableAuthModes"
        [initialAuthMode]="initialAuthMode"
        (authSuccess)="handleLoginSuccess($event)"
        (authFailure)="handleLoginFailure($event)"
        [authFunction]="authFunction"
      ></coh-login>
      <coh-m365-msft-login-redirect
        *ngIf="displayM365SelfServiceRecovery">
      </coh-m365-msft-login-redirect>
    </div>
  </section>
  <section class="hero-graphic">
    <img
      class="helios-logo"
      alt="{{ title }} Image"
      [src]="illustrationUrl || '/assets/i/cohesity-data-cloud-login.svg'"
      draggable="false" />
  </section>
</div>
<div class="login-footer">
    {{ 'copyrightNg' | translate : { year: copyrightYear, company: ( companyName || 'cohesityInc' ) | translate } }}
</div>
