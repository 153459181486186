import { Component } from '@angular/core';
import { OracleProtectionSource, ProtectionSourceNode } from '@cohesity/api/v1';
import { PopoverRef } from '@cohesity/helix';
import { OracleUtilityService } from 'src/app/shared/oracle/services/oracle-utility.service';

import { DatabaseOpenMode, OracleObjectType } from '../../constants';

@Component({
  selector: 'coh-oracle-popover',
  templateUrl: './oracle-popover.component.html',
  styleUrls: ['./oracle-popover.component.scss']
})
export class OraclePopoverComponent {

  /**
   * Returns protection source node context.
   */
  get node(): ProtectionSourceNode {
    return this.popoverRef && this.popoverRef.data;
  }

  /**
   * Returns Oracle Protection Source object.
   */
  get oracleSource(): OracleProtectionSource {
    return this.node.protectionSource && this.node.protectionSource.oracleProtectionSource;
  }

  /**
   * Determines if the node is a database.
   */
  get isDatabase(): boolean {
    return this.oracleSource.type === 'kDatabase';
  }

  /**
   * Determines if the source is an Oracle RAC database.
   */
  get isRacDatabase(): boolean {
    return this.oracleSource.dbType === 'kRACDatabase';
  }

  /**
   * Specifies whether the database is a standby within DataGuard configuration
   */
  get isOracleDGStandby(): boolean {
    return this.oracleSource?.dataGuardInfo?.role === 'kStandby';
  }

  /**
   * Returns db config type string.
   */
  get dbConfigTypeString(): string {
    return this.oracleUtilityService.getDatabaseConfigTypeString(this.node);
  }

  /**
   * Returns dataguard standby type.
   */
  get standbyType(): string {
    return this.oracleUtilityService.getDataGuardStandbyTypeString(this.node);
  }

  /**
   * Specifies whether node is protected or not
   */
  get isNodeProtected(): boolean {
    return this.node?.protectedSourcesSummary?.[0]?.leavesCount > 0;
  }

  constructor(
    private oracleUtilityService: OracleUtilityService,
    private popoverRef: PopoverRef<ProtectionSourceNode>) { }

  /**
   * Returns whether node has popover or not based on protection source info.
   *
   * @returns  True/False whether node has popover or not
   */
  static hasPopover(oracleNode: ProtectionSourceNode) {
    return !!(oracleNode.protectionSource.oracleProtectionSource.type as any === OracleObjectType.kDatabase);
  }

  /**
   * Returns the string representation for the database Open mode.
   *
   * @param    openMode   open mode for the database.
   * @return   String representation of the database open mode.
   */
  getDatabaseOpenMode(openMode: DatabaseOpenMode): string {
    return this.oracleUtilityService.getDatabaseOpenModeString(openMode);
  }
}
