<div class="cog-statlist-title" *ngIf="title && !titleTemplate">{{title}}</div>
<div class="cog-statlist-title" *ngIf="titleTemplate">
  <ng-container *ngTemplateOutlet="titleTemplate"></ng-container>
</div>
<div class="cog-statlist
  cog-statlist-content-align-{{contentAlign}}
  cog-statlist-item-align-{{itemAlign}}
  cog-statlist-{{valueSize}}
  cog-statlist-item-spacing-{{itemSpacing}}">
  <ng-content></ng-content>
</div>
