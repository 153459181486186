import { ReportColumnConfigs } from '../common-report-items/custom-reports.service';
import { ReportSection } from '../iris-reporting.model';

/**
 * Layout for the protection group summary report
 */
export const protectionGroupSummaryReport: ReportSection[] = [
  {
    title: 'reporting.summary',
    items: [
      {
        id: 'statlistItem',
        componentIds: ['201'],
        componentNames: ['ProtectionGroupStatusCount'],
        params: {
          items: [
            {
              title: 'reporting.successRate',
              translate: true,
              showChart: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'percent',
                filter: {
                  filterKey: 'backupStatus',
                  value: 'HasSuccessfulBackups',
                },
                aggregation: 'percent',
              },
            },
            {
              title: 'reporting.totalProtectionGroups',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
              },
            },
            {
              title: 'reporting.protectionGroupsWithBackups',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
                filter: {
                  filterKey: 'backupStatus',
                  value: 'HasSuccessfulBackups',
                },
              },
            },
            {
              title: 'reporting.protectionGroupsWithNoBackups',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
                filter: {
                  filterKey: 'backupStatus',
                  value: 'HasNoSuccessfulBackups',
                },
              },
            },
            {
              title: 'reporting.slaMet',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
                filter: {
                  filterKey: 'slaStatus',
                  value: 'Met',
                },
              },
            },
            {
              title: 'reporting.slaMissed',
              translate: true,
              measurement: {
                valueKey: 'countGroupId',
                dataType: 'number',
                filter: {
                  filterKey: 'slaStatus',
                  value: 'Missed',
                },
              },
            },
          ],
        },
      },
    ],
  },
  {
    items: [
      {
        id: 'chartItem',
        componentIds: ['203'],
        componentNames: ['ProtectionGroupsByEnvironment'],
        title: 'reporting.protectionGroupsByType',
        params: {
          chartType: 'pie',
          measurements: [
            {
              valueKey: 'countGroupId',
            },
          ],
          dimensions: [
            {
              dimensionKey: 'environment',
              maxValues: 3,
            },
          ],
        },
      },
      {
        id: 'chartItem',
        componentIds: ['202'],
        componentNames: ['ProtectionGroupStatusByEnvironment'],
        title: 'reporting.successfulBackupsByGroupType',
        params: {
          chartType: 'column',
          measurements: [
            {
              valueKey: 'countGroupId',
            },
          ],
          dimensions: [
            {
              dimensionKey: 'environment',
              maxValues: 3,
            },
            {
              dimensionKey: 'backupStatus',
            },
          ],
        },
      },
    ],
  },
  {
    title: 'reporting.data',
    isFullData: true,
    items: [
      {
        id: 'tableItem',
        componentIds: ['200'],
        componentNames: ['AllProtectionGroups'],
        params: {
          columns: [
            {
              title: 'protectionGroup',
              translate: true,
              key: 'groupName',
              icon: {
                key: 'environment',
                dataType: 'envGroupIcon',
              },
              primary: {
                key: 'groupName',
                dataType: 'text',
              },
              subtitle: {
                key: 'environment',
                dataType: 'environmentName',
              },
            },
            {
              title: 'source',
              translate: true,
              key: 'sourceNames',
              primary: {
                key: 'sourceNames',
                dataType: 'textList',
                renderParams: {
                  isArray: true,
                },
              },
            },
            {
              title: 'organization',
              enabled: false,
              translate: true,
              key: 'tenantName',
              primary: {
                key: 'tenantName',
                dataType: 'text',
                renderParams: {
                  emptyKey: 'naNotAvailable',
                },
              },
              featureFlag: 'ngReportSPView',
            },
            {
              title: 'system',
              translate: true,
              key: 'system',
              primary: {
                key: 'system',
                dataType: 'text',
              },
            },
            {
              title: 'policy',
              translate: true,
              key: 'policyName',
              primary: {
                key: 'policyName',
                dataType: 'text',
              },
              subtitle: {
                key: 'policyRetentionDays',
                dataType: 'text',
                renderParams: {
                  translate: true,
                  translateValue: 'reporting.retainValueD',
                },
              },
            },
            {
              title: 'lastRun',
              translate: 'true',
              key: 'lastRunTimeUsecs',
              icon: {
                key: 'lastRunStatus',
                dataType: 'statusIcon',
              },
              primary: {
                key: 'lastRunTimeUsecs',
                dataType: 'date',
                renderParams: {
                  usecs: true,
                },
              },
            },
            {
              title: 'reporting.successfulBackups.wrapped',
              translate: 'true',
              key: 'successfulBackups',
              primary: {
                key: 'successfulBackups',
                dataType: 'number',
              },
            },
            {
              title: 'reporting.unsuccessfulBackups.wrapped',
              translate: 'true',
              key: 'failedBackups',
              primary: {
                key: 'failedBackups',
                dataType: 'number',
              },
            },
            {
              title: 'reporting.successRate.wrapped',
              enabled: false,
              translate: 'true',
              key: 'successRate',
              primary: {
                key: 'successRate',
                dataType: 'number',
                renderParams: {
                  suffix: '%',
                  format: '1.0-2',
                },
              },
            },
            {
              title: 'sla',
              enabled: true,
              translate: true,
              key: 'slaStatus',
              primary: {
                key: 'slaStatus',
                dataType: 'enumType',
              },
            },
            {
              title: 'dataIngested',
              enabled: false,
              translate: true,
              key: 'dataIngestBytes',
              primary: {
                key: 'dataIngestBytes',
                dataType: 'bytes',
              },
            },
          ],
        },
      },
    ],
  },
];

/**
 * This is metadata needed to determine available chart customizations.
 */
export const protectionGroupSummaryColumns: ReportColumnConfigs = {
  lastRunStatus: {
    aggs: [],
    canGroup: true,
    dataType: 'text',
  },
  lastRunTimeUsecs: {
    aggs: [],
    dataType: 'date'
  },
  policyId: {
    aggs: ['count'],
    dataType: 'uuid'
  },
  policyName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text'
  },
  groupName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  groupId: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  environment: {
    aggs: ['count'],
    canGroup: true,
    maxGroupingValues: 3,
    dataType: 'text',
  },
  backupStatus: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 3,
    dataType: 'text',
  },
  system: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  systemId: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  slaStatus: {
    aggs: ['count'],
    canGroup: true,
    maxGroupingValues: 3,
    dataType: 'text',
  },
};
