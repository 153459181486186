import { Injectable } from '@angular/core';
import { getNavList } from '@cohesity/data-insights/nav';
import { NavItem, updateNavBaseHref } from '@cohesity/helix';
import { appConfigMap, AppName, AppType, IrisContextService, PillarType, translateAppName } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';

import { ServiceNavProvider } from '../service-nav-provider';

/**
 * This Service configures the Data Insights (gaia) view navigation menu with iris.
 */
@Injectable({ providedIn: 'root' })
export class DataInsightsNavService implements ServiceNavProvider {
  pillar: PillarType = 'insights';

  app: AppType = 'diaas';

  private appName: AppName = 'gaia';

  private appConfig = appConfigMap[this.appName];

  get serviceNavTitle(): string {
    return translateAppName(this.translate)(this.appName);
  }

  showNavBranding = true;

  serviceNavTitleIcon = this.appConfig.icon;

  serviceNavTitleClass = 'dataInsights';

  getNavItems(): NavItem[] {
    return updateNavBaseHref(getNavList(this.translate, this.irisContext.irisContext), this.appConfig.urlSegment);
  }

  constructor(
    private translate: TranslateService,
    private irisContext: IrisContextService
  ) {}
}
