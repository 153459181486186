import { Injectable } from '@angular/core';
import { ObjectServiceApi, VolumeInfo } from '@cohesity/api/v2';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Service used for getting object snapshots info
 */
@Injectable()
export class ObjectsService {

  /**
   * Constructor.
   */
  constructor(
    private objectsService: ObjectServiceApi,
  ) {}

  /**
   * Get list of volume info from the supplied snapshot details.
   *
   * @param  params  The parameters to pass to the API.
   * @returns Observable of array of volume info.
   */
  getVolumes(params: ObjectServiceApi.GetObjectSnapshotVolumeInfoParams): Observable<VolumeInfo[]> {
    return this.objectsService.GetObjectSnapshotVolumeInfo(params).pipe(
      map(response => (response && response.volumes) ? response.volumes : [])
    );
  }
}
