<cog-settings-list-item [invalid]="control.invalid"
  cogFormGroup
  [label]="'overrideOriginals' | translate">
  <cog-settings-list-item-value-display>
    {{ control.value | boolLabel}}
  </cog-settings-list-item-value-display>
  <cog-settings-list-item-value-editor>
    <mat-slide-toggle [formControl]="control"
      [inheritDataId]="true"
      [disabled]="!!lockControl"
      cogDataId="overwrite-existing-file">
      {{'overwrite' | translate}}
    </mat-slide-toggle>
  </cog-settings-list-item-value-editor>
</cog-settings-list-item>
