import { ProtectionSourceNode } from '@cohesity/api/v1';
import { Environment } from 'src/app/shared/constants';

import { ProtectionSourceDataNode } from '../shared/protection-source-data-node';
import { HasMetadata, SourceNodeMetadata } from '../shared/protection-source-metadata/source-node-metadata';

/**
 * Represents an active Salesforce source node and job tree selection behavior.
 */
export class SfdcSourceDataNode extends ProtectionSourceDataNode implements HasMetadata {

  /**
   * Stores the metadata for the source node.
   */
  readonly metadata: SourceNodeMetadata;

  constructor(data: ProtectionSourceNode, readonly level: number) {
    super(Environment.kSfdc, data, level, []);
    this.metadata = {
      leafCount: this.expandable ? this.children && this.children.length || 0 : undefined,
      recordCount: this.getRecordCount(data),
      totalSfdcUserLicenses: this.getTotalSfdcUserLicenses(data),
      usedSfdcUserLicenses: this.getUsedSfdcUserLicenses(data),
      nodeIdentifierKey: Environment.kSfdc + '.' + this.envSource.type
    };
  }

  /**
   * Used to determine if a node is Org level entity.
   *
   * @return  True if the node is of type 'kOrg'.
   */
  isOrg(): boolean {
    return this.envSource.type === 'kOrg';
  }

  /**
   * Used as a callback function to determine if a node can be selected.
   *
   * @return  True if the node can be selected.
   */
  canSelect(): boolean {
    // Individual object selection is not required for salesforce.
    return this.isOrg();
  }

  /**
   * Used as a callback function to determine if a node can be auto protected.
   *
   * @return  True if the ndoe can be auto protected.
   */
  canAutoSelect(): boolean {
    // Autoprotect is allowed only for Org entity.
    return this.isOrg();
  }

  /**
   * Whether a node could be selected in the tree. If this is false, the checkbox will be hidden
   * in the tree. This is different from canSelect, which can be used to disable a checkbox.
   */
  get isSelectable(): boolean {
    return this.isOrg() ? true : !this.protected;
  }

  /**
   * Get the record count associated with salesforce object.
   *
   * @returns   Number of record in salesforce object.
   */
  getRecordCount(node: ProtectionSourceNode): number {
    return node.protectionSource?.sfdcProtectionSource?.objectInfo?.recordCount || 0;
  }

  /**
   * Get the total number of salesforce user licenses in the organization.
   *
   * @returns   Number of Licenses in salesforce organization.
   */
  getTotalSfdcUserLicenses(node: ProtectionSourceNode): number {
    return node.protectionSource?.sfdcProtectionSource?.orgInfo?.totalSfLicenses || 0;
  }

  /**
   * Get the number of used salesforce user licenses in the organization.
   *
   * @returns   Number of used Licenses in salesforce organization.
   */
  getUsedSfdcUserLicenses(node: ProtectionSourceNode): number {
    return node.protectionSource?.sfdcProtectionSource?.orgInfo?.usedSfLicenses || 0;
  }

  /**
   * Specifies whether the node supports viewing the last run status within
   * the source tree.
   */
  get shouldShowLastRunStatus(): boolean {
    return true;
  }

  /**
   * Specifies whether the node supports viewing the last run SLA within the
   * source tree.
   */
  get shouldShowLastRunSla(): boolean {
    return true;
  }

}
