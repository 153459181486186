import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export enum ViewportSize {
  'xs' = 'xs',
  'sm' = 'sm',
  'md' = 'md',
  'lg' = 'lg',
  'xl' = 'xl'
};

/**
 * Possible named viewport options.
 */
export type NamedViewportSize =
  ViewportSize.xs |
  ViewportSize.sm |
  ViewportSize.md |
  ViewportSize.lg |
  ViewportSize.xl;

/**
 * The class that will be applied to the document body when reflow is enabled.
 */
const reflowClass = 'cog-reflow-enabled';

/**
 * Helix Reflow service. Manages responsive/reflow logic.
 */
@Injectable({
  providedIn: 'root'
})
export class ReflowService {
  /**
   * Document reference.
   */
  private document?: Document;

  /**
   * Indicates if reflow is enabled. This will always be false until an
   * external implementation says otherwise.
   */
  private _isReflowEnabled = false;

  /**
   * Indicates if responsive/refow is currently enabled.
   */
  set isReflowEnabled(newVal: boolean) {
    this._isReflowEnabled = newVal;

    // Value changed. Ensure the class gets added/removed as appropriate.
    this.updateReflowClass();
  }

  get isReflowEnabled(): boolean {
    return this._isReflowEnabled;
  }

  /**
   * Provides the current viewport width.
   */
  get viewportWidth(): number {
    return this.document.defaultView.innerWidth;
  }

  /**
   * Provides the current NamedViewportSize.
   */
  private _currentViewport = new BehaviorSubject<NamedViewportSize>(null);

  /**
   * The current named viewport size.
   */
  get currentViewport(): NamedViewportSize {
    return this._currentViewport.value;
  }

  /**
   * An observable of the current named viewport size.
   */
  currentViewport$: Observable<NamedViewportSize> = this._currentViewport.asObservable();

  constructor(
    public breakpointObserver: BreakpointObserver,
    @Inject(DOCUMENT) document: any
  ) {
    // Typing document as any and then assigning as Document type to avoid Angular complaining about being unable to
    // resolve type Document. See:
    // https://github.com/angular/angular/issues/20351#issuecomment-446025223
    // TODO: is this still needed now that we can possibly hand this over to angular cdk.
    this.document = document as Document;

    // Observe the size-based named Breakpoints. We don't care about the device
    // centric breakpoints curently.
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
    ]).subscribe((state: BreakpointState) => {
      switch (true) {
        case (state.breakpoints[Breakpoints.XSmall]):
          this._currentViewport.next(ViewportSize.xs);
          break;
        case (state.breakpoints[Breakpoints.Small]):
          this._currentViewport.next(ViewportSize.sm);
          break;
        case (state.breakpoints[Breakpoints.Medium]):
          this._currentViewport.next(ViewportSize.md);
          break;
        case (state.breakpoints[Breakpoints.Large]):
          this._currentViewport.next(ViewportSize.lg);
          break;
        case (state.breakpoints[Breakpoints.XLarge]):
          this._currentViewport.next(ViewportSize.xl);
          break;
      }
    });
  }

  /**
   * Toggles the reflow class from body.
   */
  updateReflowClass() {
    this.document.body.classList[this.isReflowEnabled ? 'add' : 'remove'](reflowClass);
  }
}
