import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { AttributeFilter } from '@cohesity/api/reporting';
import { SearchServiceApi } from '@cohesity/api/v2';
import { FiltersComponent, ValueFilterSelection } from '@cohesity/helix';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { BaseReportFilterComponent } from '../base-report-filter.component';
import { ReportUrlParamDelimiter } from '../filters-map.model';

@Component({
  selector: 'iris-rpt-object-search-filter',
  templateUrl: './object-search-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectSearchFilterComponent extends BaseReportFilterComponent {
  /**
   * User typed search string.
   */
  searchString: string;

  /**
   * Behavior subject for search total.
   */
  readonly searchTotal$ = new BehaviorSubject<number>(0);

  /**
   * Event triggers when options are loaded.
   */
  @Output() optionsLoaded = new EventEmitter<ValueFilterSelection[]>();

  constructor(
    filters: FiltersComponent,
    private api: SearchServiceApi,
  ) {
    super(filters, 'objectUuid', true);
  }

  /**
   * Initialize filter values if it is preset when loading the page from URL parameter.
   */
  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.initValues && !this.initialized) {
      const filterValues = this.initValues.map((item: string) => {
        const split = item.split(ReportUrlParamDelimiter);

        return {
          label: split[0],
          value: split[1]
        };
      });

      this.initialized = true;
      this.filterOptions$.next(filterValues);
      this.filterDef?.filter?.setValue(filterValues);
    }
  }

  /**
   * Fetch objects and update the filter options behavior subject with the result.
   *
   * @param  searchString  User typed text used for query string for search API.
   */
  search(searchString: string) {
    if (searchString?.length < 1) {
      return;
    }
    this.searchString = searchString;
    this.getFilterValues()
      .pipe(this.untilDestroy())
      .subscribe(filterValues => {
        this.filterOptions$.next(filterValues);
        this.optionsLoaded.emit(filterValues);
      });
  }

  /**
   * The async object search using global search API and populate filter
   * options from API response.
   *
   * @returns  Async list of filter options for the filter.
   */
  getFilterValues(): Observable<ValueFilterSelection[]> {
    return this.api.SearchObjects({
      searchString: this.searchString,
      count: 5,
    }).pipe(
      tap(response => this.searchTotal$.next(response?.count)),
      // Get the environment from each source
      map(response => {
        let result = [];

        if (response?.objects?.length) {
          result = response.objects.map(obj => ({
            label: obj.name,
            value: obj.globalId,
          }));
        }
        return result;
      }),
    );
  }

  toApiValue(filterValues: ValueFilterSelection[]): AttributeFilter {
    if (!filterValues?.length) {
      return null;
    }
    return {
      attribute: this.property,
      filterType: 'In',
      inFilterParams: {
        attributeDataType: 'String',
        stringFilterValues: filterValues.map(value => value.value as string),
        attributeLabels: filterValues.map(value => value.label),
      }
    };
  }

  fromApiValue(apiValue: AttributeFilter): ValueFilterSelection[] {
    const filterSelections = (apiValue?.inFilterParams.stringFilterValues || []).map((value, index) => ({
      label: apiValue.inFilterParams.attributeLabels[index],
      value: value
    }));

    // Filter options is empty at the beginning so need to add the selected
    // filter values to filter options and mark them selected.
    if (!this.filterOptions$.value?.length) {
      this.filterOptions$.next(filterSelections);
    }
    return filterSelections;
  }
}
