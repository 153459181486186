// Restore Summary Reports Controller

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.reports')
    .controller('reportsRestoreSummaryController', reportsRestoreSummaryControllerFn);

  function reportsRestoreSummaryControllerFn($rootScope, $scope, $state, $log,
    cUtils, evalAJAX, DateTimeService, RestoreService, ENUM_RESTORE_TYPE,
    CHART_COLORS, cReportsControlsUtil, ReportsService, UserService,
    ReportsUtil) {

    // convenience functions
    $scope.taskType = ENUM_RESTORE_TYPE;
    var dateTimeFormat = DateTimeService.getPreferredFormat();

    $scope.text = $rootScope.text.reportsRestoreSummary;

    $scope.dataReady = false;

    $scope.restoreSummaryControlConfig =
      ReportsUtil.getReportConfig('kRecoverSummaryReport')();

    $scope.usecsToDate = DateTimeService.usecsToDate;

    $scope.restoreTasks = [];

    $scope.updateData = updateData;

    var operationCounts = {
      vmsRestores: 0,
      vmsClones: 0
    };

    /**
     * active controller
     */
    function activate() {
      var defaultFilters = cReportsControlsUtil.getDefaultFilters();

      // run setup function on first load
      updateData(defaultFilters);
    }

    /**
     * reset operation counts
     */
    function resetOperationCounts() {
      operationCounts = {
        vmRestores: 0,
        vmClones: 0
      };
    }

    // Restore Operations chart data
    $scope.operationsChart = {
      chartType: 'donut',
      loading: true,
      titleText: 'totalTasks',
      series: [{
        type: 'pie',
        name: 'Tasks',
        data: []
      }],
      chart: {
        height: 225
      },
      colors: [CHART_COLORS.yellow, CHART_COLORS.green, CHART_COLORS.brandDark]
    };

    /**
     * updates our operationsChart data after an update to our restoreTasks object
     */
    function updateOperationsChartData() {
      $scope.operationsChart.series[0].data = [
        [$scope.text.chartLabels.vmRecoveries, operationCounts.vmRestores],
        [$scope.text.chartLabels.vmClones, operationCounts.vmClones]
      ];
    }

    // Latest Operations (run time) chart data
    $scope.runsChart = {
      chartType: 'basic',
      loading: true,
      maximumRuns: 7,
      series: [{
        name: 'Minutes',
        data: []
      }],
      chart: {
        height: 250
      },
      tooltip: {
        pointFormat: '<b>{point.y:.1f} Minutes</b>'
      },
      xAxis: {
        categories: [],
        labels: {
          useHTML: true,
          formatter: function xAxisLabelFormatter() {
            // do truncation here and return string
            if (this.value.length > 15) {
              return [this.value.slice(0, 15), '...'].join('');
            } else {
              return this.value;
            }
          }
        }
      },
      yAxis: {
        allowDecimals: false,
        title: {
          text: 'Minutes'
        }
      }
    };

    /**
     * private function for clearing the chart data for Latest Operations chart
     */
    function clearRunsChartData() {
      $scope.runsChart.series[0].data = [];
      $scope.runsChart.xAxis.categories = [];
    }

    /**
     * private function for updating the Latest Operations chart
     * @param  {Object} newSeries     new series data for the chart
     * @param  {Array} newCategories list of categories (x-axis labels) for the chart
     */
    function updateRunsChartData(newSeries, newCategories) {
      $scope.runsChart.series[0].data = newSeries;
      $scope.runsChart.xAxis.categories = newCategories;
    }

    /**
     * returns params object for api call
     *
     * @param      {object}    filters    filters from c-reports-controls
     *
     * @return    {object}               filter values to be passed to API
     */
    function getParams(filters) {
      return ReportsService.extendWithTenantParams({
        restoreTypes: ['kCloneVMs', 'kRecoverVMs'],
        startTimeUsecs: filters.timeObject.from.toUsecDate(),
        endTimeUsecs: filters.timeObject.until.toUsecDate(),
        allUnderHierarchy: true,
        _includeTenantInfo: !UserService.isTenantUser(),
      });
    }

  /**
   * gets the data for the API based on filters set in the sidebar,
   * and updates the chart and table data accordingly.
   *
   * @param      {object}    filters    filters from c-reports-controls
   */
    function updateData(filters) {
      $scope.dataReady = false;
      $scope.runsChart.loading = true;
      $scope.operationsChart.loading = true;
      // reset our list of tasks, and clear chart info
      $scope.restoreTasks = [];
      $scope.operationsChart.series[0].data = [];
      resetOperationCounts();
      clearRunsChartData();

      RestoreService.getTasks(getParams(filters)).then(
        function getTasksSuccess(restoreTasks) {
          // setup vars for building chart data
          var newRunsChartData = [];
          var newRunsChartCategories = [];

          $scope.restoreTasks = restoreTasks;

          $scope.restoreTasks.forEach(function eachRestoreTaskFn(task) {
            switch(task.performRestoreTaskState.base.type) {
              // VM recovery / restore
              case 1:
              operationCounts.vmRestores = operationCounts.vmRestores + 1;
              break;
              // clone
              case 2:
              operationCounts.vmClones = operationCounts.vmClones + 1;
              break;
            }

            // if the job finished (successfully or with error), add it to our latest operations chart,
            // but we only want to display the 5 most recent tasks
            if (task.performRestoreTaskState.base.status === 3 && newRunsChartData.length < 5) {
              newRunsChartData.push(task._runTime);
              newRunsChartCategories.push(task.performRestoreTaskState.base.name + ': ' + ENUM_RESTORE_TYPE[task.performRestoreTaskState.base.type]);
            }
          });

          // update our charts based on new data
          updateRunsChartData(newRunsChartData, newRunsChartCategories);
          updateOperationsChartData();
        }, evalAJAX.errorMessage)
      .finally(function getTasksFinally() {
        $scope.dataReady = true;
        $scope.operationsChart.loading = false;
        $scope.runsChart.loading = false;
      });
    }

    activate();
  }
})(angular);
