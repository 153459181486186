import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { PopoverRef } from '@cohesity/helix';

@Component({
  selector: 'coh-exchange-popover',
  templateUrl: './exchange-popover.component.html',
  styleUrls: ['./exchange-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExchangePopoverComponent {

  /**
   * Reference to ProtectionSourceNode which is passed as part of PopoverRef
   * once popover is opened.
   */
  node: ProtectionSourceNode;

  /**
   * Whether node is protected or not.
   */
  isNodeProtected: boolean;

  /**
   * Gets exchange database info.
   */
  get exchangeDbInfo() {
    let exchangeDbInfo = [];

    const { exchangeProtectionSource } = this.node.protectionSource;
    const { dagDatabaseInfo, standaloneDatabaseCopyInfo } = exchangeProtectionSource;

    // DAG database info can have more than 1 database copy info.
    if (dagDatabaseInfo) {
      exchangeDbInfo = dagDatabaseInfo.databaseCopyInfoList;

    // Standalone database info only has 1 database copy info.
    } else if (standaloneDatabaseCopyInfo) {
      exchangeDbInfo = [standaloneDatabaseCopyInfo];
    }

    return exchangeDbInfo;
  }

  constructor(private popoverRef: PopoverRef<ProtectionSourceNode>) {
    this.node = this.popoverRef.data;
    const protectionSummary = this.node.protectedSourcesSummary;
    if (protectionSummary && protectionSummary[0].leavesCount && protectionSummary[0].leavesCount > 0) {
      this.isNodeProtected = true;
    }
  }

  /**
   * Returns whether exchange node has popover or not based on node specific logic
   *
   * @params   node   Protection source node
   * @returns  true/false whether node has popover or not
   */
  static hasPopover(node: ProtectionSourceNode) {
    if (!node.nodes) {
      return true;
    }
    return false;
  }

}
