import { Injectable } from '@angular/core';
import { RawParams, StateOrName } from '@uirouter/core';
import { AjsUpgradeService } from 'src/app/core/services/ajs-upgrade.service';
import { AppStateDeclaration } from 'src/app/core/state/app-state-declaration';

/**
 * Angular replacement for legacy StateManagementService service.
 * Some methods are pass-through for now and will be replaced.
 */
@Injectable({
  providedIn: 'root'
})
export class AjsStateManagementService {

  /**
   * Legacy StateManagementService AngularJS service.
   */
  private ajsService: any;

  constructor(
    ajsUpgrade: AjsUpgradeService,
  ) {
    this.ajsService = ajsUpgrade.get('StateManagementService');
  }

  /**
   * Redirects the user according the fallback states.
   * 1. Check if the state is accessible, if yes, then go to the state.
   * 2. If not, then check for the fallback state recursively. If not found,
   *    then go to default state (Home).
   *
   * @param  state  The state to check for.
   * @param  params   Params to be passed to transition
   */
  goToEffectiveFallbackState(state?: AppStateDeclaration, params?: RawParams) {
    this.ajsService.goToEffectiveFallbackState(state, params);
  }

  /**
   * Indicates if the currently logged in user has appropriate permissions to
   * access the provided state.
   *
   * @param   stateOrName  The state or state name
   * @param   stateParams  The state params
   * @param   noLogging    If true then dont log if state is not accessible
   * @return               True if user has access to navigate to given state else false.
   */
  canUserAccessState(stateOrName?: StateOrName, stateParams?: RawParams, noLogging = false): boolean {
    return this.ajsService.canUserAccessState(stateOrName, stateParams, noLogging);
  }

  /** directs the user to the previous $state. if no previous $state exists,
   * sends the user to the parent state or the optionally provided default
   * $state
   *
   * @param   [defaultGoBackState]   If a state doesn't have a parent, this
   *                                 should be used to provide a default go
   *                                 back state
   */
  goToPreviousState(defaultGoBackState?: string) {
    this.ajsService.goToPreviousState(defaultGoBackState);
  }
}
