import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { sanitizeFileName } from '@cohesity/utils';
import { DatePipeWrapper } from 'src/app/shared/pipes';

import { RestoreService } from '../../../services/restore.service';

/**
 * Settings list item to display restore task name.
 */
@Component({
  selector: 'coh-settings-list-task-name',
  templateUrl: './settings-list-task-name.component.html',
  styleUrls: ['./settings-list-task-name.component.scss'],
  providers: [DatePipeWrapper],
  encapsulation: ViewEncapsulation.None,
})
export class SettingsListTaskNameComponent implements OnInit {
  /**
   * The form control for the settings list item.
   */
  @Input() control: UntypedFormControl;

  /**
   * Task name key for the recovery.
   */
  @Input() taskNameKey: string;

  constructor(private restoreService: RestoreService) {}

  /**
   * Component init.
   */
  ngOnInit() {
    const value = this.control.value ? this.control.value : this.restoreService.getDefaultTaskName(this.taskNameKey);

    // Strip all special characters and replace with '_' as some environments,
    // like HyperV do not support ":".
    this.control.setValue(sanitizeFileName(value));
  }
}
