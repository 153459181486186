/**
 * VM migration status for multistage restore.
 */
export enum MigrationStatus {
  Accepted = 'Accepted',
  Canceled = 'Canceled',
  Canceling = 'Canceling',
  Failed = 'Failed',
  OnHold = 'OnHold',
  Running = 'Running',
  Succeeded = 'Succeeded',
  SucceededWithWarning = 'SucceededWithWarning',
  Finalizing = 'Finalizing',
  PendingSync = 'PendingSync',
}

/**
 * List of migration status for VM recovery.
 */
export const migrationStatusList = Object.values(MigrationStatus);
