import { AfterContentChecked, ContentChild, Directive, Renderer2 } from '@angular/core';

import { IconComponent } from './icon.component';

const PrefixClass = 'cog-button-icon-prefix';
const SuffixClass = 'cog-button-icon-suffix';

/**
 * This directive manages IconComponents that are nested in MatButton implementations.
 * It will automatically apply a class to the icon so it's position is tweaked
 * because default handling of icons within buttons (other than fabs and mat-icon-buttons)
 * is fugly.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[mat-flat-button], [mat-stroked-button], [mat-raised-button], [mat-button]',
  standalone: true
})
export class ButtonIconDecoratorDirective implements AfterContentChecked {
  /**
   * The icon inside the button, if any.
   */
  @ContentChild(IconComponent) icon: IconComponent;

  constructor(private renderer: Renderer2) {}

  ngAfterContentChecked() {
    this.setIconClass();
  }

  /**
   * Determines if the icon inside of the button is a prefix or suffix
   * (has text after it or before it) and applies the appropriate class.
   */
  private setIconClass() {
    if (!this.icon) {
      return;
    }

    const el = this.icon._elementRef.nativeElement as HTMLElement;

    // clean up any existing classes previously added.
    this.renderer.removeClass(el, PrefixClass);
    this.renderer.removeClass(el, SuffixClass);

    const nextSibling = this.getNextNonCommentSibling(el);

    this.renderer.addClass(el, nextSibling ? PrefixClass : SuffixClass);
  }

  /**
   * Resursively walks next siblings trying to find one that is not a comment block.
   *
   * @param el   The element to find the next, non-comment sibling for
   * @returns    The next, non-comment sibling.
   */
  private getNextNonCommentSibling(el: HTMLElement): HTMLElement | undefined {
    const nextSibling = this.renderer.nextSibling(el) as HTMLElement;

    // There are no more siblings, all siblings (if there were any) are comments.
    // Return undefined as there are no relevant siblings.
    if (!nextSibling) {
      return;
    }

    const isCommentNode = nextSibling.nodeType === Node.COMMENT_NODE;

    // If this sibling is a comment, keep going to try to find a non-comment,
    // otherwise return the sibling.
    return isCommentNode
      ? this.getNextNonCommentSibling(nextSibling)
      : nextSibling;
  }
}
