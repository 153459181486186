import { Injectable } from '@angular/core';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { Transition } from '@uirouter/core';
import { ClusterService, UserService } from 'src/app/core/services';
import { AjsClusterService } from 'src/app/shared';

import { GuardResult, StateGuard } from '../state-guard';
import { GuardPriority } from './../state-guard';

/**
 * This guard runs for dashboard pages to force user to change password immediately
 * after cluster is created. User could still potentially access other urls of app.
 */
@Injectable({
  providedIn: 'root',
})
export class ChangePasswordGuard implements StateGuard {
  /**
   * App level guard priority.
   */
  guardPriority = GuardPriority.App;

  /**
   * Match for all the incoming states except login and change-password.
   */
  matchCriteria: any = {
    to: state => !(['login', 'change-password', 'eulaNew'].includes(state.name)),
  };
  constructor(
    private userService: UserService,
    private irisCtx: IrisContextService,
    private ajsClusterService: AjsClusterService,
    private clusterService: ClusterService,
  ) { }

  /**
   * This is run on the onStart ui router transition.
   */
  onStart(transition: Transition): GuardResult {
    const user = this.userService.user;

    // Change Password is not for Helios.
    if (this.clusterService.isMcm) {
      return true;
    }

    // Run only if user is local admin user and forcePasswordChange field is set to true.
    if (flagEnabled(this.irisCtx.irisContext, 'enableForcePasswordChange') &&
        this.userService.loginData?.isNodeInCluster &&
        !this.ajsClusterService.isNewCluster() && user.forcePasswordChange) {
      return transition.router.stateService.target('change-password', {force: true});
    } else {
      return true;
    }
  }
}
