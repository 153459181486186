import { Injectable } from '@angular/core';
import { from, fromEvent, Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

/**
 * Interface to provide configuration for the file selector.
 */
export interface FileSelectorConfig {
  /**
   * Whether to allow multiple files selection.
   */
  allowMultiple?: boolean;

  /**
   * The file extensions to allow. For example, to allow the TypeScript and
   * JavaScript files, specify the value as ['ts', 'js'].
   */
  extensions?: string[];
}

/**
 * Interface for returning select file info.
 */
export interface FileInfo {
  /**
   * The name of the file.
   */
  name: string;

  /**
   * File size.
   */
  size: number;

  /**
   * File type.
   */
  type: string;

  /**
   * The contents of the file.
   */
  fileContent: string;
}

/**
 * The default configuration for the file selector.
 */
const defaultConfig: FileSelectorConfig = {
  allowMultiple: true,
  extensions: [],
};

/**
 * This service shows a File picker dialog and emits the attributes and
 * contents of the selected files.
 */
@Injectable({
  providedIn: 'root',
})
export class FileSelectorService {
  /**
   * Opens a File picker and returns an Observable with the file name, type,
   * size and content of the selected file(s).
   *
   * @example
   *    fileInputService.showFileInput(Element).subscribe(results => console.log(results));
   *
   * @param   element   The Element to which this FileInput will be added
   */
  showFileInput(element: Element, config: FileSelectorConfig = {}): Observable<FileInfo[]> {
    const appliedConfig = { ...defaultConfig, ...config };

    // To keep track of the click event listener.
    let clickEventListener: any;

    // Create and configure the file input
    const fileInputElement: HTMLInputElement = document.createElement('input');

    fileInputElement.setAttribute('type', 'file');
    fileInputElement.style.display = 'none';
    fileInputElement.hidden = true;
    fileInputElement.multiple = appliedConfig.allowMultiple;

    if (appliedConfig.extensions?.length) {
      fileInputElement.setAttribute('accept', appliedConfig.extensions.map(ext => `.${ext}`).join(','));
    }

    element.appendChild(fileInputElement);

    // Register the click event listener for the file input. This will
    // automatically clean up the event listener and will remove the element
    // from the DOM as soon as it is clicked.
    // eslint-disable-next-line prefer-const
    clickEventListener = fileInputElement.addEventListener('click', () => {
      fileInputElement.removeEventListener('click', clickEventListener);
      element.removeChild(fileInputElement);
    });

    const observable = fromEvent(fileInputElement, 'input').pipe(
      // Complete this observable as soon as the files are selected.
      take(1),

      // Map the files data to FileInfo[].
      switchMap(event => {
        const fileInput = event.target as HTMLInputElement;
        const files = Array.from(fileInput.files || new FileList());

        const promises = files.map(file =>
          file.text().then(
            content =>
              ({
                name: file.name,
                size: file.size,
                type: file.type,
                fileContent: content,
              } as FileInfo)
          )
        );

        return from(Promise.all(promises));
      })
    );

    // Trigger the click event.
    fileInputElement.click();
    return observable;
  }
}
