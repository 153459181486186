import { ChangeDetectionStrategy, Component, Optional } from '@angular/core';
import { DmsServiceApi, TenantRegions } from '@cohesity/api/dms';
import { McmClusterServiceApi } from '@cohesity/api/private';
import { AttributeFilter } from '@cohesity/api/reporting';
import { FiltersComponent, ValueFilterSelection } from '@cohesity/helix';
import { dmsTenantId, IrisContextService } from '@cohesity/iris-core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BaseReportFilterComponent } from '../base-report-filter.component';

@Component({
  selector: 'iris-rpt-system-filter',
  templateUrl: './system-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemFilterComponent extends BaseReportFilterComponent {
  constructor(
    @Optional() private irisCtx: IrisContextService,
    filters: FiltersComponent,
    private dmsApi: DmsServiceApi,
    private mcmApi: McmClusterServiceApi
  ) {
    super(filters, 'systemId');
  }

  getFilterValues(): Observable<ValueFilterSelection[]> {
    return forkJoin([this.getRegionValues(), this.getClusterValues()]).pipe(
      map(([regions, clusters]) => [...regions, ...clusters])
    );
  }

  /**
   * Fetch the available dms regions. This could fail if the user doesn't use dms, if so, it
   * should just return an empty array.
   *
   * @returns Filter values for regions
   */
  getRegionValues(): Observable<ValueFilterSelection[]> {
    const tenantId = this.irisCtx ? dmsTenantId(this.irisCtx?.irisContext) : null;
    if (!tenantId || !this.includeDmsScope) {
      return of([]);
    }

    return forkJoin([
      this.dmsApi.GetTenantRegions({ tenantId }).pipe(map((regions: TenantRegions) => regions?.tenantRegionInfoList)),
      this.dmsApi.GetRegions().pipe(map(regions => regions?.regions)),
    ]).pipe(
      map(([tenantRegions, allRegions]) =>
        allRegions.filter(region => tenantRegions.find(tenantRegion => tenantRegion.regionId === region.id))
      ),
      map(regions =>
        regions.map(region => ({
          label: region.name,
          value: region.id,
        }))
      ),
      catchError(() => of([]))
    );
  }

  /**
   * Fetch the available helios systems. This could fail if the user doesn't use helios, if so, it
   * should just return an empty array.
   *
   * @returns Filter values for clusters
   */
  getClusterValues(): Observable<ValueFilterSelection[]> {
    if (!this.includeMcmScope) {
      return of([]);
    }

    return this.mcmApi.getClustersConnectionStatus().pipe(
      map(clusters =>
        clusters.map(({ clusterId, clusterIncarnationId, name }) => ({
          label: name,
          value: `${clusterId}:${clusterIncarnationId}`,
        }))
      ),
      catchError(() => of([]))
    );
  }

  toApiValue(filterValues: ValueFilterSelection[]): AttributeFilter {
    if (!filterValues?.length) {
      return null;
    }
    return {
      attribute: this.property,
      filterType: 'Systems',
      systemsFilterParams: {
        systemIds: filterValues.map(value => value.value as string),
        systemNames: filterValues.map(value => value.label),
      },
    };
  }

  fromApiValue(apiValue: AttributeFilter): ValueFilterSelection[] {
    return (apiValue?.systemsFilterParams.systemIds || []).map((value, index) => ({
      label: apiValue.systemsFilterParams.systemNames[index],
      value: value,
    }));
  }
}
