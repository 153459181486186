import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, Type } from '@angular/core';
import { McmClusterConnectionStatus } from '@cohesity/api/private';
import { RpaasRegionInfo, TransferTimeConfigParams } from '@cohesity/api/v2';
import {
  PairingStatus,
  VaultMenuActionEvent,
  vaultProvisionInProgressStatuses,
} from '@cohesity/data-govern/security-center';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';

import { UnpairedClusterPopoverComponent } from '../unpaired-cluster-popover/unpaired-cluster-popover.component';

/**
 * This renders vaults within a table, it is similar too the dmaas regions table and intended to be used with the
 * region map component.
 */
@Component({
  selector: 'coh-vaults-table',
  templateUrl: './vaults-table.component.html',
  styleUrls: ['./vaults-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VaultsTableComponent implements OnInit {
  /**
   * The list of configured vaults.
   */
  @Input() vaults: RpaasRegionInfo[];

  /**
   * A list of all clusters and their versions
   */
  @Input() clusters: McmClusterConnectionStatus[];

  /**
   * The ID of the vault which is treated as currently active.
   */
  @Input() activeVaultId: string;

  /**
   * Set this to include a footer row in the table that will trigger showing the add vault dialog
   */
  @Input() showAddNewVault = false;

  /**
   * Optional input of a map of usage by region id. If this is set, we will show the data usage values.
   */
  @Input() usageByRegion: { [vaultName: string]: number } = null;

  /**
   * Event outputs whenever the user clicks the add new vault button
   */
  @Output() addNewVault = new EventEmitter<void>();

  /**
   * Enable Actions table column.
   */
  @Input() hasActions = true;

  /**
   * Enable Actions table column.
   */
  @Input() showBackupWindow = false;

  /**
   * Maps globalVaultId to backup windows config.
   */
  @Input() backupWindowConfig: { [globalVaultId: string]: TransferTimeConfigParams };

  /**
   * Emits table action event.
   */
  @Output() readonly actionSelected = new EventEmitter<VaultMenuActionEvent>();

  /**
   * The columns to be displayed in the table.
   */
  get columns(): string[] {
    const columns = ['vaultName', 'encryption', 'regionId', 'status', 'pairingStatus'];

    if (flagEnabled(this.irisContext.irisContext, 'rpaasStorageClass')) {
      columns.splice(1, 0, 'storageClass');
    }

    return columns;
  }

  /**
   * The columns to be displayed in the table, including usage
   */
  readonly columnsWithUsage = [...this.columns, 'dataStored'];

  /**
   * Returns a count of all registered clusters
   */
  get clusterCount(): number {
    return this.clusters?.length || 0;
  }

  /**
   * The columns visible in the table, depending on whether usage information is available or not.
   */
  get displayedColumns() {
    return this.usageByRegion ? this.columnsWithUsage : this.columns;
  }

  constructor(private irisContext: IrisContextService) {}

  ngOnInit() {
    if (this.showBackupWindow) {
      this.columnsWithUsage.push('backupWindow');
      this.columns.push('backupWindow');
    }

    if (this.hasActions) {
      this.columnsWithUsage.push('actions');
      this.columns.push('actions');
    }
  }

  /**
   * Checks if a vault is marked for deletion.
   *
   * @param vault The current vault.
   * @returns True if it is marked for delection.
   */
  isMarkedForDeletion(vault: RpaasRegionInfo): boolean {
    return vault.provisionStatus?.status === 'MarkedForDeletion';
  }

  /**
   * Checks if a vault provisioning failed.
   *
   * @param vault The current vault.
   * @returns True if vault provisioning failed.
   */
  isProvisioningFailed(vault: RpaasRegionInfo): boolean {
    return vault.provisionStatus?.status === 'Failed';
  }

  /**
   * Checks if vault provisioning is in progress.
   *
   * @param vault The current vault.
   * @returns True if vault provisioning is in progress.
   */
  isProvisioningInProgress(vault: RpaasRegionInfo): boolean {
    return vaultProvisionInProgressStatuses.includes(vault.provisionStatus?.status);
  }

  /**
   * Get the overall pairing status for the vault.
   *
   * @param vault The current vault
   * @returns The pairing status.
   */
  getPairingStatus(vault: RpaasRegionInfo): PairingStatus {
    if (this.getCountByStatus(vault, ['Waiting', 'InProgress'])) {
      return 'InProgress';
    }
    if (
      !this.clusterCount ||
      this.clusterCount !== this.getCountByStatus(vault, ['Completed']) ||
      this.getCountByStatus(vault, ['Failed'])
    ) {
      return 'Failed';
    }
    return 'Completed';
  }

  /**
   * Returns a popover component to showo unpaired clusters. This should
   * only show after we have a valiid cluster list, the vault was provisioned,
   * and we have failed attempts at pairing.
   *
   * @param  vault   The current vault
   * @returns A popover component if it should be shown, or null if not.
   */
  getPopoverComponent(vault: RpaasRegionInfo): Type<any> | null {
    if (
      this.clusterCount &&
      ['Failed', 'InProgress'].includes(this.getPairingStatus(vault)) &&
      vault.provisionStatus?.status === 'Completed'
    ) {
      return UnpairedClusterPopoverComponent;
    }
    return null;
  }

  /**
   * Get the a count of cluster pairing status for this vault
   *
   * @param vault The current vault
   * @param status The status to get info for
   * @returns The number of clusters with the given pairing status
   */
  getCountByStatus(vault: RpaasRegionInfo, status: PairingStatus[]): number {
    // Pairing info sometimes returns data for no longer registered clusters, so we should
    // filter out anythinig that isn't registered.
    const registeredClusters = this.clusters
      ?.filter(cluster => cluster.connectedToCluster)
      .map(cluster => cluster.clusterId);
    return (
      vault.pairingInfo?.filter(
        info => registeredClusters.includes(info.clusterId) && status.includes(
          info.pairingStatus?.status as PairingStatus)
      )?.length || 0
    );
  }

  /**
   * Handles row actions events.
   */
  handleMenuAction(event: VaultMenuActionEvent) {
    this.actionSelected.emit(event);
  }
}
