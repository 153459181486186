import { Injectable } from '@angular/core';
import { StateService, TransitionOptions, TransitionPromise, RawParams } from '@uirouter/core';

import { StateContext } from '../state/state-context';

/**
 * Contains the application home state by default it would be dashboard and
 * for tenant user it will be protection jobs page.
 */
@Injectable({
  providedIn: 'root'
})
export class HomeService implements StateContext {

  /**
   * Default state name - if set, setDefaultState will revert back to this
   */
  defaultName: string;

  /**
   * Default state params - if set, setDefaultState will revert back to this
   */
  defaultParams: RawParams;

  /**
   * The current home state name.
   */
  name = 'dashboards';

  /**
   * The current home state params.
   */
  params: RawParams;

  constructor(private stateService: StateService) {
    // pick the initial home state as a default state.
    this.setDefaultHomeState(this.name, this.params);
  }

  /**
   * goHome is an shortcut utility used to quickly navigate to the set home page.
   *
   * @param    options   Optional state transition options. 'stateName' is used if found. Otherwise default.
   * @return   The promise for the transition.
   */
  goHome(options?: TransitionOptions, extraParams = {}): TransitionPromise {
    const stateName = options?.custom?.stateName || this.name;
    return this.stateService.go(stateName, { ...(this.params || {}), ...extraParams }, options);
  }

  /**
   * Sets home state to use. If it's not already set, it sets the default
   * state info to the previsouly set state name and params.
   *
   * @param   stateName   The name of the default home state.
   * @param   params      Params to use for the default home state.
   */
  set(stateName: string, params?: RawParams) {
    this.name = stateName;
    this.params = params;
  }

  /**
   * Resets the home state to it's default.
   */
  resetToDefault() {
    this.name = this.defaultName;
    this.params = this.defaultParams;
  }

  /**
   * Sets the default state for the home service
   *
   * @param   stateName   The name of the default home state.
   * @param   params      Params to use for the default home state.
   */
  setDefaultHomeState(name: string, params?: RawParams) {
    this.defaultName = name;
    this.defaultParams = params;
  }
}
