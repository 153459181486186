<div [formGroup]="privateEndpointField">
  <div class="region-label">
    <div>{{ privateEndpointField.get('subscription').value }} . {{ privateEndpointField.get('region').value }}</div>
  </div>
  <div class="endpoint-row">
    <div class="sas-url-select-field">
      <mat-form-field>
        <mat-label>{{ 'virtualNetwork' | translate }}</mat-label>
        <mat-select cogDataId="virtual-network" formControlName="virtualNetwork">
          <mat-option
            cogDataId="virtual-network-{{ virtualNetwork.protectionSource.name }}"
            *ngFor="let virtualNetwork of virtualNetworks$ | async"
            [value]="virtualNetwork.protectionSource.id">
            {{ virtualNetwork.protectionSource.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="sas-url-select-field">
      <mat-form-field>
        <mat-label>{{ 'subnet' | translate }}</mat-label>
        <mat-select cogDataId="subnet" formControlName="subnet">
          <mat-option
            cogDataId="subnet-{{ subnet.protectionSource.name }}"
            *ngFor="let subnet of subnets$ | async"
            [value]="subnet.protectionSource.id">
            {{ subnet.protectionSource.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
