<!-- Component: Select Policy -->
<mat-form-field [class.loading]="loading" [floatLabel]="floatLabel">
  <mat-label>{{'policy' | translate}} {{subTitle}}</mat-label>
  <cog-spinner *ngIf="loading"
    [inline]="true"
    matPrefix
    size="xs">
  </cog-spinner>
  <mat-select id="select-policy"
    [formControl]="policyControl"
    cogDataId="select-policy"
    placeholder="{{'policyName' | translate }}"
    panelClass="cog-mat-select-panel-lg">
    <mat-select-trigger *ngIf="policyControl.value">
      <ng-container *ngTemplateOutlet="policyNameTemplate;
        context: {policy: policyControl.value}">
      </ng-container>
    </mat-select-trigger>

    <!-- Show search filter if the parent component sets the allowSearch -->
    <mat-option *ngIf="allowSearch"
      [inheritDataId]="true"
      cogDataId="option-search">
      <ngx-mat-select-search
        [formControl]="searchCtrl"
        [noEntriesFoundLabel]="'noMatchingItems' | translate"
        [placeholderLabel]="'search' | translate">
      </ngx-mat-select-search>
    </mat-option>
    <!-- Show 'None' option if the parent component sets the allowUnselect-->
    <mat-option
      *ngIf="allowUnselect"
      [inheritDataId]="true"
      cogDataId="option-none">
      <ng-container *ngTemplateOutlet="policyNameTemplate; context:{policy: {name: 'none' | translate}}">
      </ng-container>
    </mat-option>
    <mat-option *ngFor="let policy of filteredPolicies$ | async; index as i"
      [cogDataId]="'option-' + policy.name"
      [inheritDataId]="true"
      [disabled]="isInvalidPolicy(policy)"
      id="select-policy-{{i}}"
      [value]="policy">
      <ng-container *ngTemplateOutlet="policyNameTemplate; context: {policy: policy}">
      </ng-container>
      <coh-policy-synopsis [policy]="policy"></coh-policy-synopsis>
    </mat-option>
    <mat-option class="sticky-option bottom" *ngIf="canCreatePolicy" #add (click)="addNewPolicy()">
      <coh-add-item-option>
        {{'createPolicy' | translate}}
      </coh-add-item-option>
    </mat-option>
  </mat-select>
  <mat-error>
    {{'errors.required' | translate}}
  </mat-error>
</mat-form-field>

<button id="edit-policy-button"
  class="edit-policy"
  type="button"
  *ngIf="canEditPolicy"
  (click)="editPolicy()"
  mat-icon-button
  [matTooltip]="'editPolicy' | translate">
  <cog-icon shape="edit!outline"></cog-icon>
</button>

<!-- Policy Name Template -->
<ng-template #policyNameTemplate let-policy="policy">
  <span class="flex-row-xs vertical-align">
    {{policy?.name}}
    <cog-icon *ngIf="isDataLockPolicy(policy)"
      class="margin-left-xs"
      shape="lock"
      size="sm">
    </cog-icon>
    <cog-icon *ngIf="!policy.isUsable && policy.templateId"
      class="margin-left-xs"
      shape="error_outline!critical"
      size="sm"
      [matTooltip]="'policy.linkedTemplate.incompleteMessage' | translate">
    </cog-icon>
  </span>
</ng-template>
