import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from '../icon/index';
import { BlankCardComponent } from './blank-card.component';

@NgModule({
  imports: [CommonModule, IconModule, BlankCardComponent],
  exports: [BlankCardComponent]
})
export class BlankCardModule {}
