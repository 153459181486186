import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { AutoDestroyable } from '@cohesity/utils';
import { ThrottlingSettings } from '../../protection-builder/models';

@Component({
  selector: 'coh-source-throttling',
  templateUrl: './source-throttling.component.html'
})
export class SourceThrottlingComponent extends AutoDestroyable implements OnInit {

  constructor() {
    super();
  }
  /**
   * Specifies the input form control value
   */
  @Input() control: UntypedFormControl;

  /**
   * Specifies if throttling is enabled or not.
   */
  @Input() enabled = false;

  /**
   * Default throttling config
   */
  defaultThrottlingConfig: ThrottlingSettings = {
    maxMetadataFetchFullPercentage: 100,
    maxMetadataFetchIncrementalPercentage: 100,
    maxReadWriteFullPercentage: 100,
    maxReadWriteIncrementalPercentage: 100,
  };

  /**
   * Form Group for the component
   */
  sourceThrottlingFormGroup: UntypedFormGroup = new UntypedFormGroup({
    maxMetadataFetchFullPercentage: new UntypedFormControl(100, Validators.required),
    maxMetadataFetchIncrementalPercentage: new UntypedFormControl(100, Validators.required),
    maxReadWriteFullPercentage: new UntypedFormControl(100, Validators.required),
    maxReadWriteIncrementalPercentage: new UntypedFormControl(100, Validators.required),
  });

  /**
   * Metadata fetch for full backups form control
   */
  get maxMetadataFetchFullControl(): UntypedFormControl {
    return this.sourceThrottlingFormGroup.get('maxMetadataFetchFullPercentage') as UntypedFormControl;
  }

  /**
   * Metadata fetch for incremental backups form control
   */
  get maxMetadataFetchIncrementalControl(): UntypedFormControl {
    return this.sourceThrottlingFormGroup.get('maxMetadataFetchIncrementalPercentage') as UntypedFormControl;
  }

  /**
   * Read/Write for full backups form control
   */
  get maxReadWriteFullControl(): UntypedFormControl {
    return this.sourceThrottlingFormGroup.get('maxReadWriteFullPercentage') as UntypedFormControl;
  }

  /**
   * Read/Write for incremental backups form control
   */
  get maxReadWriteIncrementalControl(): UntypedFormControl {
    return this.sourceThrottlingFormGroup.get('maxReadWriteIncrementalPercentage') as UntypedFormControl;
  }

  ngOnInit(): void {
    this.sourceThrottlingFormGroup.setValue(this.control?.value || this.defaultThrottlingConfig);
    this.sourceThrottlingFormGroup.valueChanges
    .pipe(this.untilDestroy())
    .subscribe((value: ThrottlingSettings) => this.control.setValue(value));
  }

  /**
   * Called when the setting toggle is changed. The input control is added or
   * removed according to the toggle value.
   */
  toggleSourceThrottling(value: MatSlideToggleChange) {
    this.enabled = value.checked;

    if (this.enabled && !this.maxMetadataFetchFullControl.value) {
      this.sourceThrottlingFormGroup.setValue(this.defaultThrottlingConfig);
    } else if (!this.enabled) {
      this.sourceThrottlingFormGroup.reset({});
    }
  }

}
