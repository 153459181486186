<cog-nested-value-property-filter
  cogDataId="object-type-filter"
  [inheritDataId]="true"
  *cogFilterDef="
    let params = params;
    let filter;
    quickFilter: true;
    key: 'objectType';
    label: 'objectType' | translate;
    filterType: 'valueProperty'
  "
  [filterDefParams]="params"
  [filter]="filter"
  [filterValues]="filterValues || []">
</cog-nested-value-property-filter>
