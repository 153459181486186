import { FlatTreeControl } from '@angular/cdk/tree';
import { KeyedSelectionModel } from '../../../util';
import { DataTreeNode } from './data-tree.model';

/**
 * Data tree control extends the FlatTreeControl and adds additional functionality useful for
 * the data tree such as running checks on node descendants and ancestors.
 */
export class DataTreeControl<T extends DataTreeNode<any>> extends FlatTreeControl<T> {
  /**
   * This refers to all of the data nodes within a the tree, visible or not.
   */
  _allDataNodes: T[];

  /**
   * Gets the entire data node list
   */
  get allDataNodes(): T[] {
    return this._allDataNodes;
  }

  /**
   * Sets the data node list and creates a map of ids to node indicies. This allows fast lookup of duplicate nodes
   * in the tree.
   */
  set allDataNodes(allDataNodes: T[]) {
    this._allDataNodes = allDataNodes;
    this.dataNodes = allDataNodes;

    const indexMap = {};
    const parentMap = {};

    // The parent stack tracks the hierarchy of the current node.
    const parentStack: {
      index: number;
      level: number;
    }[] = [];

    this.allDataNodes.forEach((node, index) => {
      indexMap[node.id] = indexMap[node.id] || [];
      indexMap[node.id].push(index);

      let parent = parentStack.length && parentStack[parentStack.length - 1];
      if (index > 0 && node.level >  this.allDataNodes[index - 1].level) {
        // If this node's level is higher than the previous one, we are in a new branch
        // Push the parent (previous node) onto the stack
        parentStack.push({
          index: index - 1,
          level: this.allDataNodes[index - 1].level,
        });
        parent = parentStack[parentStack.length - 1];
      } else {
        // This node is at the same level or lower than the last parent
        while (parent && node.level <= parent.level) {
          parentStack.pop();
          parent = parentStack.length && parentStack[parentStack.length - 1];
        }
      }
      parentMap[index] = parent?.index !== undefined ? parent.index : -1;
    });
    this.nodeIndexMap = indexMap;
    this.nodeParentIndexMap = parentMap;
  }

  /**
   * A map of node ids to indicies in the allDataNodes list.
   */
  private nodeIndexMap: {
    [key: string]: number[];
    [key: number]: number[];
  } = {};

  /**
   * A map of node ids to their immediate parent's index
   */
  private nodeParentIndexMap: {
    [key: string]: number;
    [key: number]: number;
  } = {};

  /**
   * Override expansion model of tree control to use key based selection instead of ref.
   */
  expansionModel: KeyedSelectionModel<T> = new KeyedSelectionModel<T>(node => node.id, true);

  constructor(getLevel: (dataNode: T) => number, isExpandable: (dataNode: T) => boolean) {
    super(getLevel, isExpandable);
  }

  /**
   * Override getDescendants so that we can pass a null value to it and get the
   * entire list of child nodes.
   *
   * @param dataNode The node to check
   * @returns A list of descendants or all nodes.
   */
  getDescendants(dataNode?: T): T[] {
    if (!dataNode) {
      return this.dataNodes || [];
    }
    return super.getDescendants(dataNode);
  }

  /**
   * Gets a list of the all the data node's subtree of descendent data nodes
   * regardless of whether they are visible or not.
   */
  getAllDescendants(dataNode?: T): T[] {
    if (!dataNode) {
      return this._allDataNodes || [];
    }

    const startIndex = this._allDataNodes.indexOf(dataNode);
    const results: T[] = [];

    // Goes through flattened tree nodes in the `_allDataNodes` array, and get all descendants.
    // The level of descendants of a tree node must be greater than the level of the given
    // tree node.
    // If we reach a node whose level is equal to the level of the tree node, we hit a sibling.
    // If we reach a node whose level is greater than the level of the tree node, we hit a
    // sibling of an ancestor.
    for (
      let i = startIndex + 1;
      i < this._allDataNodes.length && this.getLevel(dataNode) < this.getLevel(this._allDataNodes[i]);
      i++
    ) {
      results.push(this._allDataNodes[i]);
    }
    return results;
  }

  /**
   * Function to return whether a node id exists in the source tree.
   *
   * @param nodeId Id of the node.
   * @return True if node exists.
   */
  hasNode(nodeId: number): boolean {
    return Boolean(this.nodeIndexMap[nodeId]);
  }

  /**
   * Returns a node from the source tree based on node id.
   *
   * @param   nodeId   The id of the node.
   * @return  The node in the source tree.
   */
  getNode(nodeId: number): T {
    const nodes = this.nodeIndexMap[nodeId];
    if (nodes && nodes.length) {
      // Getting the first node from the index map should be enough.
      // A node can exist multiple times in a tree.
      return this.allDataNodes[nodes[0]];
    }
    return null;
  }

  /**
   * Runs a predicate against all of a node's descendants and returns true if every child
   * returns true.
   *
   * When checking descendants, only consider the filtered view of the tree. Selection cascades
   * down through the filtered view.
   *
   * @param   node         The current node.
   * @param   predicate    The the predicate to run against each descendant.
   * @param   descendants  A cached list of descendants to avoid making an additional check.
   *
   * @return  True if all descendants match the predicate.
   */
  checkAllDescendants(node: T, predicate: (child: T) => boolean, descendants?: T[]): boolean {
    descendants = descendants || this.getDescendants(node);
    if (!descendants.length) {
      return node ? predicate(node) : false;
    }
    return descendants.every(child => predicate(child));
  }

  /**
   * Runs a predicate against all of a node's descendants and returns true if every child
   * returns true.
   *
   * When checking descendants, all descendants are checked visible or not.
   *
   * @param   node         The current node.
   * @param   predicate    The the predicate to run against each descendant.
   * @param   descendants  A cached list of descendants to avoid making an additional check.
   *
   * @return  True if all descendants match the predicate.
   */
  checkAllDescendantsUnfiltered(node: T, predicate: (child: T) => boolean, descendants?: T[]): boolean {
    descendants = descendants || this.getAllDescendants(node);
    if (!descendants.length) {
      return node ? predicate(node) : false;
    }
    return descendants.every(child => predicate(child));
  }

  /**
   * Runs a predicate against a node's descendants and returns true if any child returns true.
   *
   * When checking descendants, only consider the filtered view of the tree. Selection cascades
   * down through the filtered view.
   *
   * @param   node         The current node.
   * @param   predicate    The the predicate to run against each descendant.
   * @param   descendants  A cached list of descendants to avoid making an additional check.
   *
   * @return  True if any descendants match the predicate.
   */
  checkSomeDescendants(node: T, predicate: (child: T) => boolean, descendants?: T[]): boolean {
    descendants = descendants || this.getDescendants(node);
    if (!descendants.length) {
      return node ? predicate(node) : false;
    }
    return descendants.some(child => predicate(child));
  }

  /**
   * Runs a predicate against a node's descendants and returns true if any child returns true.
   *
   * When checking descendants, all descendants are checked visible or not.
   *
   * @param   node         The current node.
   * @param   predicate    The the predicate to run against each descendant.
   * @param   descendants  A cached list of descendants to avoid making an additional check.
   *
   * @return  True if any descendants match the predicate.
   */
  checkSomeDescendantsUnfiltered(node: T, predicate: (child: T) => boolean, descendants?: T[]): boolean {
    descendants = descendants || this.getAllDescendants(node);
    if (!descendants.length) {
      return node ? predicate(node) : false;
    }
    return descendants.some(child => predicate(child));
  }

  /**
   * Runs a callback against each ancestor of a given node, all of the way up to the
   * root of the tree.
   *
   * Because a node can exist multiple times in a tree, this must first locate all of the
   * node's ancestors and then check each of their parents.
   *
   * @param   node   The node to start with.
   */
  checkAnyAncestor(node: T, predicate: (parent: T) => any): boolean {
    // It's possible to add 'virtual' nodes into the tree purely for display purposes. These
    // would not show up as ancestors and can be ignored.
    const indices = this.nodeIndexMap[node.id] || [];
    for (const nodeIndex of indices) {
      let parentIndex = this.getParentNode(nodeIndex);
      while (parentIndex !== -1) {
        if (predicate(this.allDataNodes[parentIndex])) {
          return true;
        }
        parentIndex = this.getParentNode(parentIndex);
      }
    }
    return false;
  }

  /**
   * Runs a callback against each ancestor of a given node, all of the way up to the
   * root of the tree.
   *
   * Because a node can exist multiple times in a tree, this must first locate all of the
   * node's ancestors and then check each of their parents.
   *
   * @param   node   The node to start with.
   */
  forEachAncestor(node: T, callback: (parent: T) => any): void {
    // It's possible to add 'virtual' nodes into the tree purely for display purposes. These
    // would not show up as ancestors and can be ignored.
    const indices = this.nodeIndexMap[node.id] || [];

    indices.forEach(nodeIndex => {
      let parentIndex = this.getParentNode(nodeIndex);
      while (parentIndex !== -1) {
        callback(this.allDataNodes[parentIndex]);
        parentIndex = this.getParentNode(parentIndex);
      }
    });
  }

  /**
   * Given a node and a list of nodes, check the node's tag ids against each node in the list
   * for a match. The node id is read as an array which will split on '_' in order to parse
   * combined tag ids.
   *
   * @param   nodeList   A list of nodes to check.
   * @param   node       A node to check for matching tags.
   * @returns True if any nodes in the nodeList match the node's tags.
   */
  matchTagSelection(nodeList: T[], node: T): boolean {
    return node.tagIds &&
    nodeList.some(item =>
      item.ids.every(id => node.tagIds.find(tagId => id === String(tagId)))
    );
  }

  /**
   * Finds the parent of a given node.
   *
   * @param   node   The node to look up the parent for
   * @return  The parent node index, or -1 if this is the root node.
   */

  private getParentNode(nodeIndex: number): number {
    const parentIndex = this.nodeParentIndexMap[nodeIndex];
    return parentIndex;
  }
}
