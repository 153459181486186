import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';
import { TranslateModule } from '@ngx-translate/core';

import { ConfirmationDialogComponent } from './confirmation/confirmation-dialog.component';
import { DeleteConfirmationDialogComponent } from './delete-confirmation/delete-confirmation-dialog.component';
import { NavigationWarningDialogComponent } from './navigation-warning/navigation-warning-dialog.component';

@NgModule({
  declarations: [ConfirmationDialogComponent, DeleteConfirmationDialogComponent, NavigationWarningDialogComponent],
  exports: [ConfirmationDialogComponent, DeleteConfirmationDialogComponent, NavigationWarningDialogComponent],
  imports: [CommonModule, TranslateModule, CohesityHelixModule, FormsModule, ReactiveFormsModule],
})
export class DialogsLibModule {}
