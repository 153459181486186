import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TimeRangeSettings } from '@cohesity/api/v1';
import { ObjectSnapshot } from '@cohesity/api/v2';
import { IrisContextService, isRpaasUser } from '@cohesity/iris-core';
import { ClearSubscriptions } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import Highcharts, { SeriesZonesOptionsObject } from 'highcharts';
import moment from 'moment-timezone';
import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SnapshotsService } from 'src/app/core/services';
import { ClusterService } from 'src/app/core/services/cluster.service';

import { GroupedObjectSnapshot } from '../../models/grouped-object-snapshot';
import { SnapshotLabels } from '../../models/snapshot-labels';
import { SnapshotMetadata } from '../../models/snapshot-metadata';
import { SnapshotTimelineValue } from '../../models/snapshot-timeline-value';
import { SnapshotMetadataService } from '../../services/snapshot-metadata.service';
import { SnapshotSelectorUtilsService } from '../../services/snapshot-selector-utils.service';

/**
 * The current timezone, as per user's locale.
 */
const userTimezone = moment.tz.guess();

/**
 * Renders a timeline view for a collection of snapshots and available time ranges.
 *
 * @example
 *   <coh-snapshot-selector-timeline-view
 *     [snapshots]="groupedSnapshots"
 *     [timeRanges]="timeRanges"
 *     [timestampUsecs]="initialTimestamp"
 *     (change)="onSnapshotChange($event)">
 *   </coh-snapshot-selector-timeline-view>
 */
@Component({
  selector: 'coh-snapshot-selector-timeline-view',
  templateUrl: './timeline-view.component.html',
  styleUrls: ['./timeline-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnapshotSelectorTimelineViewComponent extends ClearSubscriptions implements OnInit, OnChanges {
  /**
   * The reference to the highcharts module.
   */
  Highcharts: typeof Highcharts = Highcharts;

  /**
   * Determines if we need to show Optimized Timeline view or not.
   */
  @Input() optimizedTimelineView = false;

  /**
   * The list of grouped full/incremental snapshots for one day.
   */
  @Input() snapshots: GroupedObjectSnapshot[] = [];

  /**
   * The list of point-in-time ranges for one day.
   */
  @Input() timeRanges: TimeRangeSettings[] = [];

  /**
   * An initial timestamp (in usecs from epoch) to select in the timeline.
   */
  @Input() timestampUsecs: number;

  /**
   * Labels to display for PIT/automated snapshots.
   */
  @Input() snapshotLabels: SnapshotLabels;

  /**
   * The current selected snapshot type with target name if applicable.
   */
  @Input() snapshotType: string;

  /**
   * Determines if timestamp values with in Point in time range is supported for selection or not.
   * Defaults to 'true'.
   */
  @Input() pointInTimeSupported = true;

  /**
   * This binding specifies a default snapshot to use when the binding `snapshots` is empty and `timeRanges` is non-
   * empty. In this case, user sees a timeline to pick a point in time. Since a snapshot ID is required for the API
   * to know job details etc., this snapshot serves the purpose of filling in those details.
   */
  @Input() defaultSnapshot: GroupedObjectSnapshot;

  /**
   * Specifies if snapshot location picker should be hidden or not.
   */
  @Input() hideSnapshotLocationPicker = false;

  /**
   * The snapshot metadata information for the currently loaded snapshots. This metadata includes information like
   * tags associated with snapshots.
   */
  @Input() snapshotMetadata: SnapshotMetadata;

  /**
   * An event to indicate changes to this component's value.
   */
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change = new Subject<SnapshotTimelineValue>();

  /**
   * The PIT timeline chart configuration.
   */
  chartConfig: Highcharts.Options;

  /**
   * The currently selected snapshot group.
   */
  snapshotGroup$ = new BehaviorSubject<GroupedObjectSnapshot>(null);

  /**
   * The control for the time field.
   */
  timeFieldControl = new UntypedFormControl(moment());

  /**
   * Class Name for the Highcharts timeline
   */
  timelineClassName: string;

  /**
   * The collection of highchart zones. Used for marking the PIT ranges on the timeline.
   */
  private zones: SeriesZonesOptionsObject[] = [];

  /**
   * Signifies the date, to which, the snapshots belong.
   */
  private date: moment.Moment;

  /**
   * Stores the current value of this component. This value is changed whenever:
   * - A new point is picked from the timeline.
   * - The time is changed in the time field.
   */
  private _value: SnapshotTimelineValue;

  /**
   * Returns the current value of this component.
   */
  get value(): SnapshotTimelineValue {
    return this._value;
  }

  /**
   * Sets a new value of this component.
   */
  set value(value: SnapshotTimelineValue) {
    if (!this.areValuesEqual(value, this._value)) {
      this._value = value;

      if (value.isValidSnapshot) {
        // Force update the highcharts options.
        this.chartConfig = this.timelineConfig;
      }

      // Propagate the changes to callers.
      this.change.next(this.value);
    }
  }

  /**
   * Return true if cluster is NGCE.
   */
  get cloudEditionEnabled(): boolean {
    return this.clusterInfo.isClusterNGCE;
  }

  /**
   * Returns true if phase 1 for the clean room recovery is enabled.
   */
  get isCleanRoomRecoveryPhase1Enabled(): boolean {
    return this.snapshotMetadataService.isCleanRoomRecoveryPhase1Enabled;
  }

  /**
   * Whether there is a cloudVault snapshot, and it is selectable for recovery.
   */
  hasCloudVaultSnapshot$ = this.snapshotGroup$.pipe(
    map(group => !!group?.cloudVaultSnapshots?.length && this.isRpaasUser));

  /**
   * Return true if the component is in RPaaS scope.
   */
  private isRpaasUser = false;

  constructor(
    private clusterInfo: ClusterService,
    private irisCtx: IrisContextService,
    private snapshotMetadataService: SnapshotMetadataService,
    private snapshotsService: SnapshotsService,
    readonly snapshotSelectorUtilsService: SnapshotSelectorUtilsService,
    private translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    this.isRpaasUser = isRpaasUser(this.irisCtx.irisContext);
    this.timelineClassName = this.optimizedTimelineView ? 'optimized-timeline' : 'timeline';

    // Listen to the changes in time field control and update this component's value.
    const timeFieldSubscription = this.timeFieldControl.valueChanges.subscribe(updatedDate => {
      // Prune milliseconds as we only show till seconds granularity.
      const newDate = moment(updatedDate).milliseconds(0).tz(userTimezone);

      // Set a new value of this component.
      const newValue = this.getSnapshotValue(newDate.valueOf() * 1000);
      this.value = newValue.isValidSnapshot ? newValue : this.defaultValue;
    });

    if (!this.snapshotLabels) {
      this.snapshotLabels = {
        incremental: 'cohesityIncremental',
        pointInTime: 'log',
        full: 'cohesityFull',
        system: 'bmrBackup',
      };
    }

    this.subscriptions.push(timeFieldSubscription);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.snapshots && this.snapshots && this.snapshots.length) ||
      (changes.timeRanges && this.timeRanges && this.timeRanges.length)
    ) {
      this.date = this.identifyDate();
      this.zones = this.identifyHighchartsZones();
      this.value = this.initialValue;
      this.updateTimeFieldValue();
    } else {
      this.value = { isValidSnapshot: false };
    }

    if (changes.timestampUsecs && this.timestampUsecs) {
      this.value = this.getSnapshotValue(changes.timestampUsecs.currentValue);
      this.updateTimeFieldValue();
    }

    if (
      changes.snapshotMetadata &&
      this.snapshotMetadataService.isCleanRoomRecoveryPhase1Enabled &&
      this.value?.isValidSnapshot
    ) {
      // If snapshot metadata is changed, Force update the highcharts options as it is dependent on metadata.
      this.chartConfig = this.timelineConfig;
    }
  }

  /**
   * Returns the configuration for the timeline chart.
   *
   * @returns A highcharts options object, which defines the relevant configuration for the timeline chart.
   */
  get timelineConfig(): any {
    return {
      time: { timezone: userTimezone },
      chart: {
        zoomType: 'x',
        height: 150,
        styledMode: true,
        type: 'timeline',
        events: { click: ev => this.onChartClick(ev) },
        resetZoomButton: { position: { x: 0, y: 0 } },
      },
      credits: { enabled: false },
      xAxis: {
        type: 'datetime',
        max: this.date.endOf('day').valueOf(),
        min: this.date.startOf('day').valueOf(),
        crosshair: { snap: false },
        startOnTick: true,
        endOnTick: true,
        minTickInterval: 21_600_000 /** 6 hours */,
        dateTimeLabelFormats: { day: '%l %p', hour: '%l %p' },
        plotLines: this.plotLines,
      },
      yAxis: { visible: false },
      legend: { enabled: false },
      title: { text: null },
      subtitle: { text: null },
      tooltip: {
        shadow: false,
        formatter() {
          // if a data point has its own tooltip, show that
          if (this.point.tooltip) {
            return this.point.tooltip;
          }

          return false;
        },
      },
      series: [
        {
          type: 'timeline',
          marker: {
            symbol: 'circle',
            lineWidth: 0,
            states: {
              hover: { enabled: false },
              normal: { animation: false },
              select: { enabled: false },
            },
          },
          // Wrap the data points with dummy markers to show the series bar end-to-end, and to facilitate zone coloring.
          data: [
            { x: this.date.startOf('day').valueOf(), className: 'extreme-marker' },
            ...this.snapshots.map(snapshot => ({
              x: snapshot.snapshotTimestampUsecs / 1000,
              y: 1,
              events: { click: ev => this.onPointClick(ev) },
            })),
            { x: this.date.endOf('day').valueOf(), className: 'extreme-marker' },
          ],
          zoneAxis: 'x',
          zones: this.zones,
        },
        {
          type: 'bubble',
          marker: {
            symbol: 'url(helix-assets/i/icn/core/action-tag.svg)',
            lineWidth: 0,
          },
          data: this.snapshots
            .map(snapshot => {
              const tags = this.snapshotMetadata?.tags?.[snapshot.localSnapshot?.id];
              return tags && tags.length > 0 ? ({
                  x: snapshot.snapshotTimestampUsecs / 1000,
                  y: 2,
                })
              : null;
            })
            .filter(Boolean),
        }
      ],
    };
  }

  /**
   * Returns an initial value for this component. The initial value is:
   * - If an initial timestamp (`timestampUsecs`) binding is provided, then the initial value is a snapshot in
   *   proximity, or a value in the time range setting, whichever is valid.
   * - If the above is an invalid snapshot, then the default value of this component is the initial value.
   *
   * @returns An initial value for this component.
   */
  get initialValue(): SnapshotTimelineValue {
    const value = this.getSnapshotValue(this.timestampUsecs);
    return value.isValidSnapshot ? value : this.defaultValue;
  }

  /**
   * Returns an array of exactly one item to mark the current selected value on the timeline.
   *
   * @returns An array of highcharts plot lines.
   */
  get plotLines() {
    const timestamp = this.value.pointInTimeUsecs || this.value.snapshot.snapshotTimestampUsecs;
    return [{ value: timestamp / 1000 }];
  }

  /**
   * Returns the date of the first snapshot/time range from the list of snapshots/time ranges supplied.
   *
   * @param snapshots A list of full/incremental snapshots.
   * @param timeRanges A list of point in time ranges.
   * @returns A moment date object.
   */
  identifyDate(): moment.Moment {
    if (this.snapshots.length) {
      return moment(this.snapshots[0].snapshotTimestampUsecs / 1000).tz(userTimezone);
    }

    return moment(this.timeRanges[0].startTimeUsecs / 1000).tz(userTimezone);
  }

  /**
   * Identifies and returns the highchart zones from an array of time range settings.
   *
   * @returns An array of highchart zones.
   */
  identifyHighchartsZones(): SeriesZonesOptionsObject[] {
    return this.timeRanges.reduce((zones, range) => {
      zones.push({ className: 'normal-zone', value: range.startTimeUsecs / 1000 });

      // Show pit-zone(log backup point-in-time) selection only if point-in-time range selection is supported.
      if (this.pointInTimeSupported) {
        zones.push({ className: 'pit-zone', value: range.endTimeUsecs / 1000 });
      }
      return zones;
    }, []);
  }

  /**
   * Returns whether the two timestamps (in usecs from epoch) match upto the 'seconds' level.
   *
   * @param time1 The first timestamp value.
   * @param time2 The second timestamp value.
   * @returns `true` if the supplied timestamps match upto the 'seconds' level, `false` otherwise.
   */
  matchUptoSeconds(time1: number, time2: number): boolean {
    return Math.floor(time1 / 1_000_000) === Math.floor(time2 / 1_000_000);
  }

  /**
   * Searches for a timestamp (in usecs from epoch) in the list of supplied snapshots and time ranges, and
   * constructs a `SnapshotSelectorValue` object. If the supplied value is in the proximity of a full or
   * incremental snapshot, then this method automatically selects that snapshot.
   *
   * @param timeUsecs The timestamp in usecs from epoch.
   * @returns The `SnapshotSelectorValue` object.
   */
  getSnapshotValue(timeUsecs: number): SnapshotTimelineValue {
    if (!timeUsecs) {
      // The supplied timestamp isn't valid at all.
      return { isValidSnapshot: false };
    }

    // Search in the supplied snapshots.
    // Since the granularity exposed to the user is till seconds, we match the two
    // timestamps upto the seconds level only.
    const groupedSnapshot = this.snapshots.find(snap =>
      this.matchUptoSeconds(timeUsecs, snap.snapshotTimestampUsecs)
    );

    if (groupedSnapshot) {
      // A full/incremental snapshot is found.
      this.snapshotGroup$.next(groupedSnapshot);
      return { snapshot: groupedSnapshot.getSnapshotByType(this.snapshotType), isValidSnapshot: true };
    }

    // Search in the time ranges.
    const isInTimeRange = this.timeRanges.some(
      range => range.startTimeUsecs <= timeUsecs && timeUsecs <= range.endTimeUsecs
    );

    if (isInTimeRange && this.pointInTimeSupported) {
      // A valid point-in-time value is found.
      const nearestSnapshot = this.getNearestSnapshot(timeUsecs);
      this.snapshotGroup$.next(nearestSnapshot);
      return {
        snapshot: nearestSnapshot.getSnapshotByType(this.snapshotType),
        isValidSnapshot: true,
        pointInTimeUsecs: timeUsecs
      };
    }

    // The supplied timestamp isn't valid at all.
    return { isValidSnapshot: false };
  }

  /**
   * Returns the tooltip data for the selected snapshot
   *
   * @param snapshot The selected snapshot data.
   */
  getTargetTooltip(snapshot: ObjectSnapshot): string {
    if (this.cloudEditionEnabled) {
      return 'cloud';
    }
    return snapshot.runType === 'kStorageArraySnapshot' ? 'storageArraySnapshot' : 'local';
  }

  /**
   * Returns the target icon for the selected snapshot
   *
   * @param snapshot The selected snapshot data.
   */
  getTargetIcon(snapshot: ObjectSnapshot): string {
    return snapshot.runType === 'kStorageArraySnapshot' ? snapshot.runType : 'local';
  }

  /**
   * This function serves as a callback for the click event on the full/incremental snapshot markers of the timeline
   * view.
   *
   * @param event The highcharts event object.
   */
  onPointClick(event: Highcharts.PointClickEventObject) {
    this.value = this.getSnapshotValue(Number(event.point.category) * 1000);
    this.updateTimeFieldValue();
  }

  /**
   * This function serves as a callback for the click event on the point-in-time range of the timeline view.
   *
   * @param event The highcharts event object.
   */
  onChartClick(event: any) {
    const timestamp = event.xAxis[0].value * 1000;
    const newValue = this.getSnapshotValue(timestamp);
    if (newValue.isValidSnapshot) {
      this.value = newValue;
    } else {
      // Snap to the nearest snapshot
      const nearestSnapshot = this.getNearestSnapshot(timestamp, true);

      this.snapshotGroup$.next(nearestSnapshot);
      this.value = {
        snapshot: nearestSnapshot.getSnapshotByType(this.snapshotType),
        isValidSnapshot: true,
      };
    }
    this.updateTimeFieldValue();
  }

  /**
   * Returns the default value of this component. The default value is calculated as:
   * - If a list of snapshots is provided, then the default value is based on the first item of that list.
   * - If the above is not provided and a list of time ranges is provided, then the default value is the start time of
   *   the first time range.
   *
   * @returns The default value of this component, calculated using the supplied snapshots/time ranges.
   */
  get defaultValue(): SnapshotTimelineValue {
    if (this.snapshots.length) {
      const firstGroup = this.snapshots[0];
      this.snapshotGroup$.next(firstGroup);
      return { snapshot: firstGroup.getSnapshotByType(this.snapshotType), isValidSnapshot: true };
    }

    const timestamp = this.timeRanges[0].startTimeUsecs;
    const nearestSnapshot = this.getNearestSnapshot(timestamp);
    this.snapshotGroup$.next(nearestSnapshot);
    return {
      snapshot: nearestSnapshot.getSnapshotByType(this.snapshotType),
      isValidSnapshot: true,
      pointInTimeUsecs: timestamp
    };
  }

  /**
   * This method serves as a callback for the `click` event on the target icon buttons.
   *
   * @param snapshot The new snapshot as per the target picked by the user.
   */
  onSnapshotLocationClick(snapshot: ObjectSnapshot) {
    this.value = { ...this.value, snapshot };

    // Update the current selected snapshot type.
    this.snapshotType = this.snapshotsService.getSnapshotType(snapshot);
  }

  /**
   * Traverses the list of snapshots supplied to the component and returns the latest snapshot which occurred before the
   * specified timestamp if lookAhead = false, or the closest snapshot to the selected timestamp
   * including snapshots occur after the timestamp if lookAhead = true.
   * If such snapshot can't be found, then it returns the oldest snapshot from the list.
   *
   * @param timeUsecs The timestamp in usecs from epoch.
   * @param lookAhead Whether to look ahead to get the nearest snapshot. Default to false.
   * @returns The snapshot group object.
   */
  getNearestSnapshot(timeUsecs: number, lookAhead = false): GroupedObjectSnapshot {
    if (!lookAhead) {
      for (let i = this.snapshots.length - 1; i > 0; i--) {
        if (this.snapshots[i].snapshotTimestampUsecs <= timeUsecs) {
          return this.snapshots[i];
        }
      }
    } else {
      const closestSnapshot = (this.snapshots || []).reduce((prev, curr) =>
        Math.abs(curr.snapshotTimestampUsecs - timeUsecs) < Math.abs(prev.snapshotTimestampUsecs - timeUsecs)
        ? curr : prev);
      if (closestSnapshot) {
        return closestSnapshot;
      }
    }

    // Return the oldest snapshot, as a default value.
    return this.snapshots[0] || this.defaultSnapshot;
  }

  /**
   * Updates the time field control's value with the current value of this component.
   */
  updateTimeFieldValue() {
    if (this.value?.isValidSnapshot) {
      // Assign value to the time field.
      const date = moment((this.value.pointInTimeUsecs || this.value.snapshot.snapshotTimestampUsecs) / 1000).tz(
        userTimezone
      );

      this.timeFieldControl.setValue(date);
    }
  }

  /**
   * Retrieves the tag information for the snapshots present in the group
   *
   * @param group group for which the tag information is required
   * @returns list of tags associated with the snapshots in the group
   */
  getSnapshotTags(group: GroupedObjectSnapshot) {
    return this.snapshotMetadataService.getSnapshotTags(group, this.snapshotMetadata);
  }

  /**
   * Returns whether the specified two values of this component are equal.
   *
   * @param val1 The first value to compare.
   * @param val2 The second value to compare.
   *
   * @returns `true` if both values are equal, `false` otherwise.
   */
  private areValuesEqual(val1: SnapshotTimelineValue, val2: SnapshotTimelineValue): boolean {
    if (!val1 || !val2) {
      return false;
    }

    return (
      val1.isValidSnapshot === val2.isValidSnapshot &&
      (val1.pointInTimeUsecs === val2.pointInTimeUsecs ||
        // Match the msecs, as MomentJS doesn't support values in usecs.
        Math.floor(val1.pointInTimeUsecs / 1000) === Math.floor(val2.pointInTimeUsecs / 1000)) &&
      val1.snapshot &&
      val2.snapshot &&
      val1.snapshot.id === val2.snapshot.id
    );
  }
}
