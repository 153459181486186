/* tslint:disable */

/**
 * Specifies the unit for a schedule.
 */
export enum ScheduleUnit {
  Once = 'Once',
  Minutes = 'Minutes',
  Weeks = 'Weeks',
  Months = 'Months'
}
