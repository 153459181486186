import { HttpClient } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Api } from '@cohesity/api/private';
import { GetIndexedObjectSnapshotsResponseBody, ObjectServiceApi } from '@cohesity/api/v2';

import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetIndexedObjectSnapshotsService extends ObjectServiceApi {
  constructor(
    @Optional() private irisContextService: IrisContextService,
    httpClient: HttpClient,
  ) {
    super({ rootUrl: Api.v2Root }, httpClient);
  }

  GetIndexedObjectSnapshots(params: ObjectServiceApi.GetIndexedObjectSnapshotsParams):
  Observable<GetIndexedObjectSnapshotsResponseBody> {
    return super.GetIndexedObjectSnapshots({
      ...params,
      useCachedData: flagEnabled(this.irisContextService.irisContext, 'useMagnetoCachedData'),
    });
  }
}
