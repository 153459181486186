import { Injectable } from '@angular/core';
import { ProtectedObject } from '@cohesity/api/v2';
import { NavItem } from '@cohesity/helix';
import { IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { StateService } from '@uirouter/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { StateManagementService } from 'src/app/core/services';
import { RestoreConfigService } from 'src/app/modules/restore/restore-shared';
import { RecoveryAction } from 'src/app/shared';

import { AdObjectActionCreator } from './ad-object-action-creator';
import { ObjectActionCreator } from './object-action-creator';
import { ObjectActionProvider } from './object-action-provider';
import { checkObjectsForBulkAction } from './object-action-utils';
import { ObjectInfoService } from './object-info.service';
import { SimpleObjectInfo } from './object-menu-provider';

@Injectable()
export class AdObjectActionProvider extends ObjectActionProvider {
  /**
   * The provider for this service is manually set up in object-actions-menu.service, which must provide
   * the list of providers as an array in the correct order. In order to maintain some kind of sanity,
   * the providers are listed here, they should match the order of the constructor args.
   */
  static adObjectActionProviderDependencies = [
    ObjectInfoService,
    RestoreConfigService,
    StateManagementService,
    StateService,
    ObjectActionCreator,
    IrisContextService,
    AdObjectActionCreator,
  ];

  exclusiveProtection = true;

  supportsBulkActions = true;

  constructor(
    readonly objectStore: ObjectInfoService,
    readonly restoreConfig: RestoreConfigService,
    readonly stateManagementService: StateManagementService,
    readonly stateService: StateService,
    readonly actionCreator: ObjectActionCreator,
    readonly irisContextService: IrisContextService,
    private adActionCreator: AdObjectActionCreator,
  ) {
    super(objectStore, restoreConfig, stateManagementService, stateService, actionCreator, irisContextService);
  }

  /**
   * Checks a protected object and recovery action to determine if the provided action is applicable.
   * For AD, restore configuration is not available.
   *
   * @param object The protected object
   * @param actionType A recovery action type
   * @returns True if the action can be applied to this object.
   */
  canRecover(actionType: RecoveryAction, object: ProtectedObject): boolean {
    return Boolean(object?.latestSnapshotsInfo) && actionType === RecoveryAction.RecoverApps;
  }

  getObjectActions(object: SimpleObjectInfo): Observable<NavItem[]> {
    if (isDmsScope(this.irisContextService.irisContext)) {
      return super.getObjectActions(object);
    }

    return combineLatest([
      super.getObjectActions(object),
      this.getAdRecoverAction([object]),
    ]).pipe(
      map(([baseActions, ...adActions]) => [...baseActions, ...adActions]),
      map(actions => actions.filter(action => Boolean(action)))
    );

  }

  /**
   * Creates and returns the AD action for the specified object.
   *
   * @param objects The protected objects.
   * @returns An observable, which yields the NavItem or null.
   */
  getAdRecoverAction(objects: SimpleObjectInfo[]): Observable<NavItem> {
    const type = RecoveryAction.RecoverApps;

    if (!checkObjectsForBulkAction(objects, type)) {
      return of(null);
    }

    if (!this.objectStore.getProtectedObject) {
      return of(null);
    }

    return super.getProtectedObjectSearch(objects).pipe(
      map(infos => infos.filter(info => info && this.canRecover(type, info))),
      map(infos => this.adActionCreator.createAdRecoverAction(infos[0]))
    );
  }
}
