import { ArchivalTargetResult, GetProtectionRunProgressBody } from '@cohesity/api/v2';
import { decorateInterface } from '@cohesity/utils';
import { StatusObject } from 'src/app/shared';

import { ArchivalTargetType, RunProgress } from './common.models';

/**
 * Base interface for ArchivalTarget that extends other interfaces.
 */
interface ArchivalTargetBase extends RunProgress, ArchivalTargetResult {}

/**
 * Transformer class for API ArchivalTargetResult class.
 */
export class ArchivalTarget extends decorateInterface<ArchivalTargetBase>() {
  /**
   * Archival Target ID.
   */
  readonly id: number;

  /**
   * Archival Target name.
   */
  readonly name?: string;

  /**
   * Duration in milliseconds.
   */
  readonly durationMs?: number;

  /**
   * Logical size in bytes.
   */
  readonly size?: number;

  /**
   * Specifies the physical bytes transferred.
   */
  readonly physicalTransferred?: number;

  /**
   * Specifies total logical bytes read for creating the snapshot.
   */
  readonly bytesRead?: number;

  /**
   * Specifies the logical bytes transferred.
   */
  readonly logicalTransferred?: number;

  /**
   * Specifies the average rate of transfer in bytes per second.
   */
  readonly transferRate?: number;

  /**
   * Type of archive, cloud or tape.
   */
  readonly type?: ArchivalTargetType;

  /**
   * Specifies the archival task id.
   */
  readonly taskId?: string;

  /**
   * Returns true if Archival Target is deleted.
   */
  readonly isDeleted?: boolean;

  /**
   * Indicates if archival target can be canceled based on ArchivalTargetResult.
   */
  readonly canCancel?: boolean;

  /**
   * Indicates if archival target can be deleted based on ArchivalTargetResult.
   */
  readonly canDelete?: boolean;

  /**
   * Archival target status.
   */
  readonly status?: ArchivalTargetResult['status'];

  /**
   * SLA status.
   */
  readonly slaStatus?: StatusObject;

  /**
   * Archival status object.
   */
  readonly statusObject?: StatusObject;

  /**
   * True if archival target in one of running state.
   */
  readonly isRunning?: boolean;

  /**
   * Indicted archival target is RPaaS.
   */
  readonly isRpaas?: boolean;

  constructor(ref: ArchivalTargetResult, isReplicatedCluster?: boolean) {
    super(Object.assign({
      cancelledAppObjectsCount: 0,
      cancelledObjectsCount: 0,
      failedAppObjectsCount: 0,
      failedObjectsCount: 0,
      successfulAppObjectsCount: 0,
      successfulObjectsCount: 0,
    }, ref));

    const {
      archivalTaskId,
      endTimeUsecs = 0,
      expiryTimeUsecs,
      isManuallyDeleted,
      isSlaViolated,
      queuedTimeUsecs,
      startTimeUsecs,
      stats,
      status,
      targetId,
      targetName,
      targetType,
    } = this;

    const nowUsecs = new Date().getTime() * 1000;

    this.id = targetId;
    this.name = targetName;

    this.isRunning = ['Accepted', 'Canceling', 'Running'].includes(status);

    const durationUsecs = Math.max(0, (this.isRunning ? nowUsecs : endTimeUsecs) - (startTimeUsecs || queuedTimeUsecs));
    this.durationMs = durationUsecs / 1000;

    this.taskId = archivalTaskId;
    this.type = targetType;
    this.isDeleted = !this.isRunning && (isManuallyDeleted || expiryTimeUsecs === 0 ||
      (expiryTimeUsecs <= nowUsecs && !this.onLegalHold));

    this.isRpaas = this.ownershipContext === 'FortKnox';

    this.statusObject = {
      id: targetId,
      name: targetName,
      status,
      type: targetType === 'Tape' ? 'tape' : 'cloud',
    };

    if (this.isRpaas) {
      this.statusObject.type = 'vault';
    }

    // if isSlaViolated is undefined or null, then SLA status is empty/undefined.
    if (typeof isSlaViolated === 'boolean') {
      this.slaStatus = {
        status: isSlaViolated ? 'kWarning' : 'kSuccess',
        type: 'sla',
      };
    }

    // Cancel Task button should be enabled if snapshot is not deleted and if status of task is
    // 'Running' or 'Accepted'. It should also be enabled if snapshot is deleted and task is still
    // running(indicates archival running beyond expiry).
    this.canCancel = (!this.isDeleted && this.taskId && ['Accepted', 'Running'].includes(status)) ||
      (this.isDeleted && ['Running'].includes(status));
    this.canDelete = !this.isDeleted && (status === 'Succeeded' || status === 'OnHold') &&
      !isReplicatedCluster && (!expiryTimeUsecs || expiryTimeUsecs > nowUsecs) && !this.isRpaas;

    if (stats) {
      const {
        avgLogicalTransferRateBps = 0,
        backupFileCount = 0,
        bytesRead = 0,
        logicalBytesTransferred = 0,
        logicalSizeBytes = 0,
        physicalBytesTransferred = 0,
        totalFileCount = 0,
        numChangedGranularObjects = 0,
        numProtectedGranularObjects = 0,
        numSuccessfulBackedGranularObjects = 0,
      } = stats;
      this.backupFileCount = backupFileCount;
      this.bytesRead = bytesRead;
      this.logicalTransferred = logicalBytesTransferred;
      this.physicalTransferred = physicalBytesTransferred;
      this.size = logicalSizeBytes;
      this.totalFileCount = totalFileCount;
      this.transferRate = avgLogicalTransferRateBps;
      this.numChangedGranularObjects = numChangedGranularObjects;
      this.numProtectedGranularObjects = numProtectedGranularObjects;
      this.numSuccessfulBackedGranularObjects = numSuccessfulBackedGranularObjects;
    }
  }

  /**
   * Updates progress info this archival target.
   *
   * @param  runProgress Protection run progress data.
   */
  updateProgress = (runProgress: GetProtectionRunProgressBody) => {
    const archivalTarget = runProgress.archivalRun?.find(archival => archival?.targetId === this.id);
    this.percentageCompleted = archivalTarget?.percentageCompleted;
    this.events = archivalTarget?.events;
  };
}
