/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiConfiguration as __Configuration } from '../cohesity-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PrivilegeInfo } from '../models/privilege-info';
@Injectable({
  providedIn: 'root',
})
class PrivilegesService extends __BaseService {
  static readonly GetPrivilegesPath = '/public/privileges';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * List the privileges defined on the Cohesity Cluster.
   *
   * If the 'name' parameter is not specified, all privileges defined
   * on the Cohesity Cluster are returned.
   * In addition, information about each privilege is returned such as the
   * associated category, description, name,  etc.
   * If an exact privilege name (such as PRINCIPAL_VIEW) is specified in the
   * 'name' parameter, only information about that single privilege is returned.
   * @param params The `PrivilegesService.GetPrivilegesParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `name`: Specifies the name of the privilege.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetPrivilegesResponse(params: PrivilegesService.GetPrivilegesParams): __Observable<__StrictHttpResponse<Array<PrivilegeInfo>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.name != null) __params = __params.set('name', params.name.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/public/privileges`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PrivilegeInfo>>;
      })
    );
  }
  /**
   * List the privileges defined on the Cohesity Cluster.
   *
   * If the 'name' parameter is not specified, all privileges defined
   * on the Cohesity Cluster are returned.
   * In addition, information about each privilege is returned such as the
   * associated category, description, name,  etc.
   * If an exact privilege name (such as PRINCIPAL_VIEW) is specified in the
   * 'name' parameter, only information about that single privilege is returned.
   * @param params The `PrivilegesService.GetPrivilegesParams` containing the following parameters:
   *
   * - `regionId`: This field represents the region id.
   *
   * - `name`: Specifies the name of the privilege.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetPrivileges(params: PrivilegesService.GetPrivilegesParams): __Observable<Array<PrivilegeInfo>> {
    return this.GetPrivilegesResponse(params).pipe(
      __map(_r => _r.body as Array<PrivilegeInfo>)
    );
  }
}

module PrivilegesService {

  /**
   * Parameters for GetPrivileges
   */
  export interface GetPrivilegesParams {

    /**
     * This field represents the region id.
     */
    regionId?: string;

    /**
     * Specifies the name of the privilege.
     */
    name?: string;

    /**
     * This field uniquely represents a Cohesity             Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { PrivilegesService }
