import { Injectable } from '@angular/core';
import { AppConfig } from '@cohesity/iris-core';
import { BehaviorSubject, combineLatest, from, of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { addErrorHandler, LifeCycles, ParcelConfig } from 'single-spa';

import { CustomProps } from '../../shared';
import { ScriptLoaderService } from './script-loader.service';

@Injectable({
  providedIn: 'root',
})
export class PluginLoaderService {
  /**
   * Indicates whether plugins are loaded.
   */
  get isLoaded() {
    return this.isLoaded$.value;
  }

  isLoaded$ = new BehaviorSubject(false);

  /**
   * The list of of plugins parcel configs.
   */
  parcelConfigs: ParcelConfig[] = [];

  constructor(
    private scriptLoaderService: ScriptLoaderService,
  ) { }

  /**
   * Start the plugin registration and loading.
   */
  start(pluginConfigs: AppConfig[]) {
    this.loadPlugins(pluginConfigs || []);

    // capture exceptions while loading plugins.
    addErrorHandler(err => {
      // logging the error reason why we are not able to load the plugin.
      console.error('PluginLoaderService: Error while loading', err.appOrParcelName, err);
    });
  }

  /**
   * Load the plugins.
   *
   * @param The list of plugin configs.
   */
  loadPlugins(pluginConfigs: AppConfig[]) {
    this.isLoaded$.next(false);
    combineLatest(
      pluginConfigs.map(plugin => from(this.scriptLoaderService.loadScript(plugin.publicPath, plugin.libName)).pipe(
        catchError(error => {
          console.error('PluginLoaderService: error while loading plugin', plugin.name, error);
          return of<LifeCycles<CustomProps>>(null);
        })
      ))
    ).pipe(
      finalize(() => {
        this.isLoaded$.next(true);
        this.isLoaded$.complete();
      }),
      tap(parcelConfigs => this.parcelConfigs = (parcelConfigs || []).filter(Boolean)),
    ).subscribe();
  }
}
