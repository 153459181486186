import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FilterDefDirective, FiltersComponent, ValueFilterSelection } from '@cohesity/helix';
import { AdaptorAccessService } from '@cohesity/iris-core';
import { Memoize } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';

import { getEnvGroupConstantsFilterValues, pruneEnvGroup } from './environment-group-filter.models';
import { Environment } from '@cohesity/iris-shared-constants';

/**
 * Filter components which populates static environments
 */
@Component({
  selector: 'dg-environment-group-filter',
  templateUrl: './environment-group-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnvironmentGroupFilterComponent implements OnInit, OnDestroy {
  /**
   * Supported Environments
   */
  @Input() adapterAccessService: AdaptorAccessService;

  /**
   * The filter def directive for the filter
   */
  @ViewChild(FilterDefDirective, { static: true }) filterDef: FilterDefDirective;

  /**
   * Supported Environments
   */
  @Memoize()
  get supportedEnvs(): Environment[] {
    return this.adapterAccessService?.getSupportedEnvironments() ?? [];
  }

  /**
   * Environment group filter value
   */
  @Memoize()
  get filterValues(): ValueFilterSelection[] {
    const envGroupFilterValues = getEnvGroupConstantsFilterValues(this.translateService);
    return this.supportedEnvs.length > 0
      ? pruneEnvGroup(envGroupFilterValues, this.supportedEnvs)
      : envGroupFilterValues;
  }

  constructor(private filters: FiltersComponent, private translateService: TranslateService) {}

  ngOnInit() {
    if (this.filters && this.filterDef) {
      this.filters.addFilterDef(this.filterDef);
    }
  }

  ngOnDestroy() {
    if (this.filters && this.filterDef) {
      this.filters.removeFilterDef(this.filterDef);
    }
  }
}
