// Component: cLicenseUploadDownload

(function(angular) {
  'use strict';

  angular
    .module('C.licenseUploadDownload', [])
    .controller('CLicenseUploadDownloadCtrl', CLicenseUploadDownloadCtrlFn)
    .component('cLicenseUploadDownload', {
      bindings: {
        /*
        @type   {Object}
          {
            heading: {String}
            mainDescription: {String}
            steps: [
                  {
                    img: {String}           Contains the class of icon to be
                                            placed for step1
                    description: {String}   Text to be shown in step1
                    button:{
                      Text: {String}        Text of the button
                      callback: {function}  function to be called on click
                    }
                  },

                  {
                    img: {String}           Contains the class of icon to be
                                            placed for step2
                    description: {String}   Text to be shown in step2
                    button:{
                      Text: {String}        Text of the button
                      callback: {function}  function to be called on click
                    }
                  },

                  {
                    img: {String}           Contains the class of icon to be
                                            placed for step3
                    description: {String}   Text to be shown in step3
                    button:{
                      Text: {String}        Text of the button
                      callback: {function}  function to be called on click
                    }
                  }
                ]
          }
        */
        config: '=',
      },
      controller: 'CLicenseUploadDownloadCtrl',
      templateUrl: 'app/global/c-license-upload-download/c-license-upload-download.html',
    });

  function CLicenseUploadDownloadCtrlFn(_) {
    var $ctrl = this;
  }
})(angular);
