import { CollectionViewer, DataSource, ListRange } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MatTreeFlattener } from '@angular/material/tree';
import { BehaviorSubject, combineLatest, merge, Observable, of, Subject } from 'rxjs';
import { map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

import { DataTreeControl } from './data-tree-control';
import { DataTreeNode, DataTreeTransformer } from './data-tree.model';

/**
 * TODO: Expand this to be an object containing either a filter ID (for server-side filtering) or a
 * method (for client-side filtering).
 */
export type DataTreeFilter<T> = (list: DataTreeNode<T>[]) => DataTreeNode<T>[];

/**
 * This manages the data source for a cog-data-tree. It should manage virtual scrolling,
 * filtering the initial data set, and managing tree flatting strategies.
 */
export class DataTreeSource<T> extends DataSource<DataTreeNode<T>> {
  /**
   * The underlying data property that drives the data source
   */
  private data$: BehaviorSubject<T[]>;

  /**
   * A flag to let us know if we should scroll back to the top when loading data.
   * If we are lazy loading more rows for the tree, we should not reset the scroll
   * after receiving new data.
   */
  skipScroll = false;

  /**
   * Gets the current data property.
   */
  get data(): T[] {
    return this.data$.value;
  }

  /**
   * Sets the data property and flattens its nodes.
   */
  set data(data: T[]) {
    if (this.scroller && this.data$.value?.length) {
      this.skipScroll = true;
    }
    this.data$.next(data);
    this.treeControl.allDataNodes = this.flattener.flattenNodes(this.data);
    this.flattenedData$.next(this.treeControl.allDataNodes);
  }

  /**
   * An observable of the currently applied filters. This gets applied to the flattened data
   * source object.
   */
  private filters$ = new BehaviorSubject<DataTreeFilter<T>[]>([]);

  /**
   * Updates the filters for the data source.
   */
  set filters(filters: DataTreeFilter<T>[]) {
    this.filters$.next(filters);
  }

  /**
   * Gets the current filters for the data source.
   */
  get filters(): DataTreeFilter<T>[] {
    return this.filters$.value;
  }

  /**
   * This is a flattened list of the entire tree before filters are applied.
   */
  flattenedData$ = new BehaviorSubject<DataTreeNode<T>[]>([]);

  /**
   * This is a filtered list of the flattened tree.
   */
  filteredData$ = new BehaviorSubject<DataTreeNode<T>[]>([]);

  /**
   * This is a flattened list of the the current tree state, only nodes that are part of the filter
   * and are currently expanded are shown.
   */
  private expandedData$ = new BehaviorSubject<DataTreeNode<T>[]>([]);

  /**
   * The data tree is rendered inside of a virtual scroller. When the scroller
   * changes, the data source output will update with the slice of data currently
   * shown by the scroller.
   */
  private _scroller: CdkVirtualScrollViewport;

  /**
   * Gets the scroller
   */
  get scroller(): CdkVirtualScrollViewport {
    return this._scroller;
  }

  /**
   * Sets the scroller and subscribes to changes.
   */
  set scroller(scroller: CdkVirtualScrollViewport) {
    this._scroller = scroller;
    this.updateSubscriptions();
  }

  /**
   * This is the entire, expanded view of the tree. This is used by a cdkVirtualScroll
   * directive inside the scroll viewport to render a container based on the entire tree
   * size.
   */
  currentView$ = new BehaviorSubject<DataTreeNode<T>[]>([]);

  /**
   * This is the current range of the list as shown by the scroller. The output will be
   * a slice of currentView$ based on this value.
   */
  private listRange$ = new BehaviorSubject<ListRange>(undefined);

  /**
   * Emits values to clean up subscriptions.
   */
  private destroy$ = new Subject<void>();

  constructor(
    private treeTransformer: DataTreeTransformer<T>,
    readonly treeControl: DataTreeControl<DataTreeNode<T>>,
    initialData: T[] = [],
    private flattener = new MatTreeFlattener<T, DataTreeNode<T>>(
      (node, level) => treeTransformer.transformData(node, level),
      node => treeTransformer.getLevel(node),
      node => treeTransformer.getExpandable(node),
      node => this.getNodeChildren(node)
    )
  ) {
    super();
    this.data$ = new BehaviorSubject(initialData);
  }

  /**
   * Retrieve the children of a node. This method could overridden to load the node's children asynchronously.
   *
   * @param   node   The node object.
   * @return  An array of children or observable that resolves to an array of children.
   */
  getNodeChildren: (T) => T[] | Observable<T[]> = (node: T) => this.treeTransformer.getChildren(node);

  /**
   * Apply the filteres to a set of data. This method could be overridden to filter the data asynchronoously.
   *
   * @param    flatData   The full data list.
   * @param    filters    The filters to apply.
   * @return   An observable of the filtered data.
   */
  applyFilters(flatData: DataTreeNode<T>[], filters: DataTreeFilter<T>[]): Observable<DataTreeNode<T>[]> {
    let filteredData = flatData;
    filters.forEach(filter => (filteredData = filter(filteredData)));
    return of(filteredData);
  }

  /**
   * Connects a view to the data source
   *
   * @param   collectionViewer   This is passed automatically by mat-tree.
   * @return   An observable of the current tree view.
   */
  connect(collectionViewer: CollectionViewer): Observable<DataTreeNode<T>[]> {
    this.updateSubscriptions();

    // Listen for changes to the flattended data and the filter, then apply the filter and update
    // the filtered data.
    const filterChanges$ = combineLatest([this.flattenedData$, this.filters$]).pipe(
      switchMap(([flatData, filters]) => this.applyFilters(flatData, filters)),
      tap(filteredData => {
        this.filteredData$.next(filteredData);
        this.treeControl.dataNodes = this.filteredData$.value;
      }),
      tap(() => {
        // Scroll to the top when the filter changes
        if (this.scroller) {
          if (!this.skipScroll) {
            this.scroller.scrollToIndex(0);
          }
          this.skipScroll = false;
        }
      })
    );

    // Listen for changes to the filtered data, the convert it to an expanded view.
    const changes = [collectionViewer.viewChange, this.treeControl.expansionModel.changed, filterChanges$];
    const filteredView$ = merge(...changes).pipe(
      map(() => {
        this.expandedData$.next(this.flattener.expandFlattenedNodes(this.filteredData$.value, this.treeControl));
        return this.expandedData$.value;
      })
    );

    // Finally, return a slice of the filtered view based on the current scrolling view.
    return combineLatest([filteredView$, this.listRange$]).pipe(
      tap(([currentView]) => {
        // This prevents the observable from emitting an empty value multiple times.
        if (currentView.length || this.currentView$.value.length !== currentView.length) {
          this.currentView$.next(currentView);
        }
      }),
      map(([currentView, range]) => (range ? currentView.slice(range.start, range.end) : currentView))
    );
  }

  /**
   * Cleans up the data source connection.
   */
  disconnect() {
    this.cleanUpSubscriptions();
  }

  /**
   * Subscribes to scroller changes to update the range.
   */
  private updateSubscriptions() {
    this.cleanUpSubscriptions();
    if (this.scroller) {
      this.scroller.renderedRangeStream.pipe(
        // Initiate with the currently rendered range.
        startWith(this.scroller.getRenderedRange()),
        takeUntil(this.destroy$)
      ).subscribe(range => {
        this.listRange$.next(range);
      });
    }
  }

  /**
   * Cleans up scroller subscription.
   */
  private cleanUpSubscriptions() {
    this.destroy$.next();
  }
}
