import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { ProtectionSource, ProtectionSourceNode, ProtectionSourcesServiceApi } from '@cohesity/api/v1';
import { FilterObjectsRequest, ObjectServiceApi } from '@cohesity/api/v2';
import { DataTreeFilter, DataTreeFilterUtils, DataTreeSelection, DataTreeSource } from '@cohesity/helix';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { ExcludeOptions, ExcludeFilter, FilterOption, SourceSelection } from '@cohesity/iris-source-tree';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services';
import { Environment, selectableHostEntities } from 'src/app/shared/constants';
import { SourceHealthCheckMatrixComponent } from 'src/app/shared/source-health-checks';

import { SqlExcludeComponent } from '../../sql-exclude/sql-exclude.component';
import { BaseProtectionSourceService } from '../shared/base-protection-source.service';
import {
  PhysicalBlockHostOptionsComponent,
} from '../shared/physical-block-host-options/physical-block-host-options.component';
import {
  SqlAagSelectionChallengeModalComponent,
} from './sql-aag-selection-challenge-modal/sql-aag-selection-challenge-modal.component';
import { SqlFilters } from './sql-filters.service';
import { isSystemDatabase, SqlSourceDataNode } from './sql-source-data-node';
import { SqlSourceDetailsComponent } from './sql-source-details/sql-source-details.component';
import { SqlSourceMetadataComponent } from './sql-source-metadata/sql-source-metadata.component';

type SqlTreeSelection = DataTreeSelection<SqlSourceDataNode>;

/**
 * Options used to determine async loading nature of the source tree.
 */
interface AsyncOptions {
  /**
   * Determines if the nodes being async loaded are auto selected in the job or not.
   */
  autoSelected?: boolean;

  /**
   * Determines if the nodes being async loaded need to be manually selected or not.
   */
  selectNodes?: boolean;
}

/**
 * Tree service for SQL.
 */
@Injectable()
export class SqlSourceTreeService extends BaseProtectionSourceService<SqlSourceDataNode> {

  /**
   * SQL Filters to filter the source tree on type of databases.
   */
  sqlFilters: SqlFilters<SqlSourceDataNode>;

  /**
   * Additional filter options related to sql source tree
   */
  sqlFilterOptions: FilterOption[];

  /**
   * Exclude filters component to display
   */
  excludeComponent = SqlExcludeComponent;

  /**
   * The data tree source.
   */
  dataTreeSource: DataTreeSource<any>;

  /**
   * Exclusion filters to exclude objects from auto protected hosts
   */
  excludeFilters: ExcludeFilter[];

  /**
   * The components to display
   */
  detailComponents = [SqlSourceDetailsComponent, SqlSourceMetadataComponent];

  /**
   * The pre-translated display name for the artificial System Databases group
   * node. Used for UI perposes only.
   */
  private systemDatabaseGroupName: string;

  constructor(readonly translate: TranslateService,
    private dialogService: DialogService,
    private miscService: ObjectServiceApi,
    private protectionSourcesServiceApi: ProtectionSourcesServiceApi,
    private irisContextService: IrisContextService,
  ) {
    super();
    this.systemDatabaseGroupName = translate.instant('systemDatabases');
    this.dataTreeSource = new DataTreeSource(this.treeTransformer, this.treeControl);
    this.sqlFilters = new SqlFilters(this.treeControl, this.filters, this.translate);
    this.sqlFilterOptions = [
      {
        id: 'exclude-objects',
        label: 'excludeObjects',
        filter: this.excludeObjectsFilter,
      }
    ];
    this.filterOptions.addFilterOptions(this.sqlFilterOptions);

    // If SQL source tree is in async mode, whenever any instance is expanded and
    // its children are not loaded, then async load the children. Instances are
    // always loaded in advance so we do not need to check the hosts for children.
    if (flagEnabled(this.irisContextService.irisContext, 'ngSqlAsyncMode')) {
      this.treeControl.expansionModel.changed.subscribe(change => {
        (change.added || []).forEach(node => {
          // Do not async load for hosts or it will load the entire tree.
          if (node.type === 'kInstance') {
            this.asyncLoadChildren(node).subscribe();
          }
        });
      });
    }
  }

  /**
   * Gets a component to render for a source's special parameters. This does not apply to every node
   * and can be null for certain data types.
   */
  getSpecialParametersComponent(node: SqlSourceDataNode): ComponentType<any> {
    if (node.isPhysicalHost) {
      return PhysicalBlockHostOptionsComponent;
    }

    return null;
  }

  /**
   * Returns whether a node is a leaf or not. This can be used to calculate selection totals.
   */
  isLeaf(treeNode: SqlSourceDataNode): boolean {
    return treeNode.isLeaf;
  }

  /**
   * Filters a list of nodes which are excluded.
   *
   * @param    nodes         The list of nodes to filter.
   * @return   The filtered list.
   */
  excludeObjectsFilter: DataTreeFilter<SqlSourceDataNode> = (nodes: any[]) =>
    DataTreeFilterUtils.searchFilter(nodes, this.treeControl,
      (node: SqlSourceDataNode) => this.dataTreeSelection.isExcluded(node), false, true);

  /**
   * Override the unwrap root contains to modify the data source by grouping system
   * databases together.
   *
   * @param  nodes   The list of nodes from the raw api response.
   * @returns   The unwrapped root container, with system databases grouped together.
   *
   */
  unwrapRootContainers(nodes: ProtectionSourceNode[]): ProtectionSourceNode[] {
    const unwrapped = super.unwrapRootContainers(nodes);
    unwrapped.forEach(node => this.groupSystemDatabases(node));
    return unwrapped;
  }

  /**
   * Recurseively check each node to find and group system databases together.
   * Grouped databases will be added to a dummy node of type 'kSystemDatabases'.
   *
   * @param   node   The node to check.
   */
  groupSystemDatabases(node: ProtectionSourceNode) {
    const sqlSource = node.protectionSource.sqlProtectionSource;
    if (sqlSource && sqlSource.type === 'kInstance') {
      const systemDbs: ProtectionSourceNode[]  = (node.nodes || []).filter(child => isSystemDatabase(child));
      const nonSystemDbs: ProtectionSourceNode[] = (node.nodes || []).filter(child => !isSystemDatabase(child));
      if (systemDbs.length) {
        // Create a fake system db group node.
        const systemDbNode = {
          protectionSource: {
            // Use the negative instance ID for this container group. It will be
            // unique and associated with each instance.
            id: -1 * Number(node.protectionSource.id),
            name: this.systemDatabaseGroupName,
            environment: 'kSQL',
            parentId: node.protectionSource.parentId,
            sqlProtectionSource: {
              // Copy properties from the first system db node to fill in most of what is needed.
              ...systemDbs[0].protectionSource.sqlProtectionSource,
              databaseName: undefined,
              name: this.systemDatabaseGroupName,
              type: 'kSystemDatabases',
            }
          },
          nodes: systemDbs,
        };
        // The new nodes list puts system databases first followed by non system databases.
        node.nodes = [systemDbNode, ...nonSystemDbs];
      }
    } else {
      // If this isn't a sql node, process its children if they exist.
      (node.nodes || node.applicationNodes || []).forEach(child => this.groupSystemDatabases(child));
    }
  }

  /**
   * Tells the Source Tree to only expand the tree to the instance level.
   */
  shouldExpandNodeOnLoad(treeNode: SqlSourceDataNode): boolean {
    // If there are nodes auto selected or selected(VM-based SQL nodes) and is not in an editing flow,
    // only expand those nodes and collapse the rest.
    if (!treeNode.isEditing && this.previousSelection &&
      (this.previousSelection.autoSelected.length || this.previousSelection.selected.length)) {
      return selectableHostEntities.includes(treeNode.type) && treeNode.inCurrentJob;
    }

    return selectableHostEntities.includes(treeNode.type);
  }

  /**
   * Transforms the node object from the api into a SqlSourceDataNode to pass to
   * the tree.
   *
   * @param   node   The original node.
   * @param   level  The level in the tree.
   * @return  A SqlSourceDataNode that can be displayed in the tree.
   */
  transformData(node: ProtectionSourceNode, level: number): SqlSourceDataNode {
    return new SqlSourceDataNode(node, level, this.treeControl, this.job, this.irisContextService);
  }

  /**
   * Convert the data tree selection model to the job selection model.
   *
   * @param   selectedEntitiesToHostsMap   The selection from the tree.
   * @return  The job selection info.
   */
  transformFromDataTreeSelection(selection: SqlTreeSelection): SourceSelection {
    const output: SourceSelection = {
      excludeSourceIds: [],
      sourceIds: [],
      sourceSpecialParameters: [],
      excludeFilters: [],
    };
    const fullSelection = [
      // Only deal with leaf & VM host nodes. Nothing else can be in this list.
      ...this.getSelectedObjects(selection),

      // Anything else that's not a leaf node nor VM host can only be here.
      ...selection.autoSelected,
    ];

    output.sourceIds = fullSelection.map(node => node.id);
    output.excludeSourceIds = selection.excluded.map(node => node.id);
    output.sourceSpecialParameters = Object.values(selection.options || {})
      .filter(option => output.sourceIds.includes(option.sourceId) ||

      // volume based additional host params option for physical nodes
      option.physicalSpecialParameters);

    output.excludeFilters = this.excludeFilters;

    return output;
  }

  /**
   * Convert source selection to the data tree selection model.
   *
   * @param   allNodes          The unfiltered list of tree nodes.
   * @param   sourceSelection   The job selection.
   * @return  A data tree selection model.
   */
  transformToDataTreeSelection(allNodes: SqlSourceDataNode[], sourceSelection: SourceSelection): SqlTreeSelection {
    const sourceMap: any = {};
    const selection: SqlTreeSelection = {
      autoSelected: [],
      excluded: [],
      selected: [],
      options: {},
    };

    if (!sourceSelection) {
      return selection;
    }

    const nodesLength = allNodes.length;
    for (let i = 0; i < nodesLength; i++) {
      const node = allNodes[i];
      const nodeId = node.id;
      const isEntityOnVmHost = !node.isVmHost && node.parentHost.isVmHost;

      if (!isEntityOnVmHost &&
        (sourceSelection.sourceIds || []).includes(nodeId) &&
        !sourceMap[nodeId]) {
        sourceMap[nodeId] = node;
        if (!node.isSQLDatabase && !node.isVmHost && !node.isSQLAagDatabase) {
          selection.autoSelected.push(node);
        } else {
          selection.selected.push(node);
        }
      } else if ((sourceSelection.excludeSourceIds || []).includes(nodeId) && !sourceMap[nodeId]) {
        sourceMap[nodeId] = node;
        selection.excluded.push(node);
      }
    }

    // Mark all selected nodes as in this job.
    [...selection.selected, ...selection.autoSelected].forEach(node => {
      node.inCurrentJob = true;
      if (node.hostEnvironment !== Environment.kSQL) {
        node.checkAllDescendants(child => child.inCurrentJob = true);
      }
    });

    if (sourceSelection.sourceSpecialParameters) {
      selection.options = {};
      sourceSelection.sourceSpecialParameters.forEach(params => {
        selection.options[params.sourceId] = params;
      });
    }

    return selection;
  }

  /**
   * Loads missing objects if the source tree is working in an async mode. It
   * will return empty observable if async mode is not activated. This async
   * loads all autoprotected entities and combines that with loading of hosts
   * which have individual dbs selected in the job and returns the combined
   * result.
   *
   * @param     allNodes          List of all SQL data tree source nodes.
   * @param     sourceSelection   Data tree selection.
   * @returns   Observable indicating that the source tree has now been loaded
   */
  loadMissingObjects(allNodes: SqlSourceDataNode[], sourceSelection: SourceSelection): Observable<any> {
    if (!flagEnabled(this.irisContextService.irisContext, 'ngSqlAsyncMode') || !sourceSelection) {
      return of(null);
    }

    const missingSourceIds = sourceSelection.sourceIds.slice();
    const subs$ = [];

    // Adding all autoprotected entities to observable stream
    allNodes.forEach(node => {
      if ((sourceSelection.sourceIds || []).includes(node.id) &&
        !node.isSQLDatabase && !node.isVmHost && !node.isSQLAagDatabase) {
          // In async mode, load children for the nodes which are pre-existing
          // in the protection group.
        subs$.push(this.asyncLoadChildren(node, {autoSelected: true}));
        // Node is already loaded so can be removed from missing source ids
        missingSourceIds.splice(missingSourceIds.indexOf(node.id), 1);
      } else if (missingSourceIds.includes(node.id)) {
        // This DB is already loaded and not missing.
        missingSourceIds.splice(missingSourceIds.indexOf(node.id), 1);
      }
    });

    // Autoprotections are handled by just loading their children. Individual DBs
    // which are now left in missingSourceIds need to be specially handled as
    // we do not know their instance details.
    return forkJoin([...subs$, this.asyncLoadMissingDbs(missingSourceIds, allNodes)]);
  }

  /**
   * When a job is edited and it has exclude filters, filters are sent to get
   * list of exclude object ids and are setup here when source tree is loaded
   *
   * @param   filters    Exclude filters to be applied on the source tree
   * @param   regionId   Dms Region Id
   */
  handleFilterExclusions(filters: ExcludeFilter[], regionId) {
    const selection = this.dataTreeSelection.currentSelection;
    // If we do not have filters applied or autoprotected objects no handling needed
    if (!selection.autoSelected[0] || !filters || !filters[0]) {
      return;
    }

    const params: FilterObjectsRequest = {
      filterType: 'exclude',
      filters,
      applicationEnvironment: 'kSQL',
      objectIds: selection.autoSelected.map(node => +node.id),
    };

    this.miscService.FilterObjects({body: params, regionId})
      .pipe(
        take(1),
        filter(result => !!result),
        map(result => result.filteredObjects.map(object => object.id)),
        tap(nodeIds => this.applyExclusions(nodeIds))
      ).subscribe();
  }

  /**
   * Handles user-initiated selection changes to determine if any intercepts or
   * challenge modals are required to make selection modifications.
   *
   * This receives the selection _after_ it has occurred.
   *
   * @param     selection   The current selection.
   * @returns   The selection, potentially mutated by User decisions.
   */
  selectionChangeHandler(selection: SqlTreeSelection): SqlTreeSelection {
    const deltas: SqlTreeSelection = this.getSelectionDeltas(selection);
    const selectedThings = [...deltas.selected, ...deltas.autoSelected];
    const nodeWithHealthCheckProblems: SqlSourceDataNode = selectedThings.find(node => node.hasHealthCheckProblems);
    const aagMemberNode: SqlSourceDataNode = selectedThings.find(node => node.isAagNode);

    // While editing a protection group having AAG sources, if AAG node is not in selected state
    // then while selecting, dialogue option will be prompted. For AAG nodes in selected/autoselected
    // state only deselection can be done.
    if (nodeWithHealthCheckProblems) {
      this.handleHealthCheckProblemsSelection(nodeWithHealthCheckProblems, selection);
    } else if (aagMemberNode && !aagMemberNode.isSelected && !aagMemberNode.isAagHost) {
      this.handleAagMemberNodeSelection(aagMemberNode, selection);
    }

    // In SQL Async mode, if any instance is selected or autoprotected whose
    // children are not loaded, load the children first.
    if (flagEnabled(this.irisContextService.irisContext, 'ngSqlAsyncMode')) {
      deltas.selected.forEach(node => this.asyncLoadChildren(node, {selectNodes: true}).subscribe());
      deltas.autoSelected.forEach(node => this.asyncLoadChildren(node).subscribe());
    }
    return this.previousSelection = selection;
  }

  /**
   * Handles displaying the failed health checks notice when user tries to
   * select a node with known failures. Will deselect if decline, and accept
   * selection if accepted. Will not reprompt once accepted.
   *
   * @param   node        The node with the failed health checks.
   * @param   selection   The current selection.
   */
  private handleHealthCheckProblemsSelection(node: SqlSourceDataNode, selection: SqlTreeSelection) {
    const data = {
      confirmButtonLabel: 'select',
      declineButtonLabel: 'cancel',
      title: 'healthChecksErrorTitle',
      copy: 'healthChecksModal.copy',
      node: node.data,
      component: SourceHealthCheckMatrixComponent,
      componentInputs: {
        // TODO(spencer): Need to figure out how to handle the array here.
        checks: node.healthChecks,
      },
    };
    const deltas: SqlTreeSelection = this.getSelectionDeltas(selection);
    const selectedThings = [...deltas.selected, ...deltas.autoSelected];
    const aagMemberNode: SqlSourceDataNode = selectedThings.find(dataNode => dataNode.isAagNode);
    const isSelectionChanged = this.hasSelectionChanged(selection);

    this.dialogService.simpleDialog(null, data).subscribe((proceed: boolean) => {
      if (!proceed) {
        // Update selection only when source tree selection has been updated.
        if (isSelectionChanged) {
          const deselectionMethod: string =
            selection.selected.includes(node) ? 'toggleNodeSelection' : 'toggleNodeAutoSelection';
          this.dataTreeSelection[deselectionMethod](node);

          // Update cached selection with the latest current selection after manually select/deselect nodes.
          this.previousSelection = this.dataTreeSelection.currentSelection;
        }
        return;
      }

      // Prompt AAG Modal when unhealthy AAG node is selected.
      if (aagMemberNode) {
        this.handleAagMemberNodeSelection(aagMemberNode, selection);
        return;
      }
    });
  }

  /**
   * Handles AAG member node selection
   *
   * @param   node   The node selected
   * @param   selection   The current selection.
   */
  private handleAagMemberNodeSelection(node: SqlSourceDataNode, selection: SqlTreeSelection) {
    const dialogConfig = {
      panelClass: 'aag-selection-challenge-modal',
      maxWidth: '50rem',
    };
    const dialogData = { node: node };

    this.dialogService.showDialog(SqlAagSelectionChallengeModalComponent, dialogData, dialogConfig)
      .subscribe((hostIdsToSelect: number[]) => {
        switch (hostIdsToSelect?.length) {
          // Cancel and deselect.
          case 0:
            this.smartDeselectNode(node);
            break;

          case 1:
            // Do nothing. The single node is already selected. This could
            // potentially be combined with the default case though.
            break;

          // All nodes in AAG.
          default:
            this.selectAllAagNodes(hostIdsToSelect, selection);
        }

        // Update cached selection with the latest current selection after manually select/deselect nodes.
        this.previousSelection = this.dataTreeSelection.currentSelection;
      });
  }

  /**
   * Selects all aag nodes related to the given node.
   *
   * @param   hostIds   The node to find relate4d AAG nodes from.
   * @param   [selection]   The current selection
   */
  selectAllAagNodes(hostIds: number[], selection?: SqlTreeSelection) {
    const useAutoSelect = selection && selection.autoSelected.some(node => (hostIds || []).includes(node.id));
    this.selectNodesById(hostIds, useAutoSelect);
  }

  /**
   * Selects nodes by id.
   *
   * @param   nodeIds         The IDs to select.
   * @param   [autoProtect=false]   Use auto-selection instead of standard selection.
   */
  selectNodesById(nodeIds: number[], autoProtect = false) {
    const selectionMethod = autoProtect ? this.autoSelectNode : this.selectNode;
    const nodeIdsHash = (nodeIds || []).reduce((hashResult, id) => {
      hashResult[id] = true;
      return hashResult;
    }, {});

    // Find all matching nodes by ID and select them.
    this.treeControl.allDataNodes.forEach((node: SqlSourceDataNode) => {
      if (nodeIdsHash[Number(node.id)]) {
        selectionMethod(node);
      }
    });
  }

  /**
   * Selects a node. Determines the best selection method (auto-select or
   * standard).
   *
   * @param   node   The node to select.
   */
  smartSelectNode(node: SqlSourceDataNode) {
    // If this node can't be selected in any way, exit early.
    if (!node.canSelect(this.dataTreeSelection.currentSelection) && !node.canAutoSelect()) {
      return;
    }

    const selectionMethod = (!node.isSQLDatabase && !node.isSQLAagDatabase && node.canAutoSelect())
      ? this.autoSelectNode
      : this.selectNode;

    selectionMethod(node);
  }

  /**
   * Deselects the given node regardless of whether it's auto-selected or
   * standard selected.
   *
   * @param   node   The node to deselect.
   */
  smartDeselectNode(node: SqlSourceDataNode) {
    const deselectionMethod = this.dataTreeSelection.isAutoSelected(node)
      ? this.deselectAutoSelectedNode
      : this.deselectNode;

    deselectionMethod(node);
  }

  /**
   * Selects a node.
   *
   * @param   node   The node to select.
   */
  selectNode = (node: SqlSourceDataNode) => {
    if (!this.dataTreeSelection.isSelected(node)) {
      this.dataTreeSelection.toggleNodeSelection(node);
    }
  };

  /**
   * Deselects a node.
   *
   * @param   node   THe node to deselect.
   */
  deselectNode = (node: SqlSourceDataNode) => {
    if (this.dataTreeSelection.isSelected(node)) {
      this.dataTreeSelection.toggleNodeSelection(node);
    }
  };

  /**
   * Auto-selects a node.
   *
   * @param   node   The node to select.
   */
  autoSelectNode = (node: SqlSourceDataNode) => {
    if (!this.dataTreeSelection.isAutoSelected(node)) {
      this.dataTreeSelection.toggleNodeAutoSelection(node);
    }
  };

  /**
   * Deselects an auto-selected node.
   *
   * @param   node   The node to deselect.
   */
  deselectAutoSelectedNode = (node: SqlSourceDataNode) => {
    if (this.dataTreeSelection.isAutoSelected(node)) {
      this.dataTreeSelection.toggleNodeAutoSelection(node);
    }
  };

  /**
   * Gets all actual selected objects. This enforces some restrictions to limit
   * the results to supported types.
   *
   * @param   selection   The current selection.
   */
  getSelectedObjects(selection: DataTreeSelection<SqlSourceDataNode>): SqlSourceDataNode[] {
    return selection.selected.filter(node => {
      const isNodeOnVmHost = !node.isVmHost && node.parentHost.isVmHost;
      return node.isVmHost || (!isNodeOnVmHost && (node.isSQLDatabase || node.isSQLAagDatabase));
    });
  }

  /**
   * Applies the exclusions on the source tree nodes.
   *
   * @param   nodeIds   Node ids which are to be excluded
   */
  applyExclusions(nodeIds: number[]) {
    this.treeControl._allDataNodes.forEach((node) => {
      // If system db group, check child node for the system db
      const nodeId = node.isSystemDbGroup ? node.children[0].protectionSource.id : node.id;

      // If node is part of filter result and not excluded
      if ((nodeIds.includes(nodeId) && !this.dataTreeSelection.isExcluded(node)) ||

        // OR if node is not a part of filter result and excluded
        (!nodeIds.includes(nodeId) && this.dataTreeSelection.isExcluded(node))) {
        // then programatically toggle the exclusion on node.
        this.dataTreeSelection.toggleNodeExclude(node, true);
      }
    });
  }

  /**
   * Queries for the selected objects and loads the part of tree where individual
   * dbs are part of job but not loaded.
   *
   * @param   nodeIds    Node ids for the selection
   * @param   allNodes   All the nodes in the tree
   */
  asyncLoadMissingDbs(nodeIds: number[], allNodes: SqlSourceDataNode[]) {
    if (!nodeIds || !nodeIds.length) {
      return of(null);
    }

    const missingHostIds = new Set();
    nodeIds = nodeIds.filter(nodeId => !this.missingObjectsCache[nodeId]);
    if (nodeIds.length === 0) {
      return of(null);
    }
    nodeIds.forEach(nodeId => this.missingObjectsCache[nodeId] = true);

    return this.protectionSourcesServiceApi.GetProtectionSourcesObjects({objectIds: nodeIds})
      .pipe(switchMap(objects => {
        objects.forEach(object => {
          if (object.sqlProtectionSource && object.sqlProtectionSource.type === 'kDatabase' &&
            !missingHostIds.has(object.parentId)) {
            missingHostIds.add(object.parentId);
          }
        });
        const missingHosts = allNodes.filter(node => missingHostIds.has(node.id));
        if (!missingHosts.length) {
          return of(null);
        }
        return forkJoin(missingHosts.map(host => this.asyncLoadChildren(host)));
      }));
  }

  /**
   * Loads the child nodes in async manner when required and not automatically
   * when the source tree is initialized.
   *
   * @param   node   SQL source data node
   */
  private asyncLoadChildren(node: SqlSourceDataNode, options: AsyncOptions = {}): Observable<any> {
    // If node is child or if the children are already loaded, no
    // opertation needed.
    if (!['kHost', 'kInstance'].includes(node.type) || node.asyncLoadedChildren) {
      return of(null);
    }
    node.asyncLoadedChildren = true;

    const params = {
      id: node.id,
      allUnderHierarchy: false
    };
    return this.protectionSourcesServiceApi.ListProtectionSources(params)
      .pipe(tap(sources => {
        if (!sources || !sources.length) {
          return;
        }
        if (sources[0].applicationNodes) {
          // If node is a host, merge dbs for each instance inside the host.
          sources[0].applicationNodes.forEach((appNode: ProtectionSourceNode) =>
            this.mergeAsyncLoadedDbs(appNode.protectionSource, appNode.nodes, options));
        } else if (sources[0].nodes) {
          // If node is an instance, merge the dbs for this instance.
          this.mergeAsyncLoadedDbs(sources[0].protectionSource, sources[0].nodes, options);
        }
      }, () => {
        node.asyncLoadedChildren = false;
      }));
  }

  /**
   * Constructs the database nodes according to the nature of selection (auto
   * selection or individual selection) and merges them to flat nodes in the
   * data tree source.
   *
   * @param   sqlInstance   SQL Instance to be loaded
   * @param   dbs           List of dbs inside the SQL instance to be merged
   * @param   options       Options to determine the nature of merge. Determines
   *                        auto selection or individual selection cases.
   */
  private mergeAsyncLoadedDbs(
    sqlInstance: ProtectionSource,
    dbs: ProtectionSourceNode[],
    options: AsyncOptions = {}
  ) {
    let sqlSourceNodes = [];
    let systemDbs = dbs.filter(node => isSystemDatabase(node));
    let nonSystemDbs = dbs.filter(node => !isSystemDatabase(node));

    if (systemDbs.length) {
      systemDbs = this.transformSystemDbs(sqlInstance.id, systemDbs);
      nonSystemDbs = nonSystemDbs.map(node => this.transformData(node, 2));
      sqlSourceNodes = [...systemDbs, ...nonSystemDbs];
    } else {
      sqlSourceNodes = dbs.map(node => this.transformData(node, 2));
    }

    if (this.treeControl.allDataNodes.findIndex(n => n.id === sqlSourceNodes[0].id) === -1) {
      const sqlInstanceIndex = this.treeControl.allDataNodes.findIndex(n => n.id === sqlInstance.id);
      this.treeControl.allDataNodes.splice(sqlInstanceIndex + 1, 0, ...sqlSourceNodes);
      this.treeControl.allDataNodes[sqlInstanceIndex].asyncLoadedChildren = true;
      this.treeControl.allDataNodes = [...this.treeControl.allDataNodes];

      // Remember the offset of scroller so that we can set it again after
      // adding nodes to the data tree source
      const offset = this.dataTreeSource.scroller.measureScrollOffset('top');
      this.dataTreeSource.flattenedData$.next(this.treeControl.allDataNodes);
      this.dataTreeSource.scroller.scrollToOffset(offset);
    }

    sqlSourceNodes.forEach(node => {
      // If the node is selected or pre-selected database selection needs to be
      // manually selected after the tree is loaded.
      if (options.selectNodes || (this.missingObjectsCache[node.id] && node.type === 'kDatabase')) {
        node.inCurrentJob = true;
        this.selectNode(node);
      }

      // If the node is auto-selected when job is loaded, the nodes that are
      // loaded needs to be marked with current job so that they can be as job
      // nodes.
      if (options.autoSelected) {
        node.inCurrentJob = true;
      }
    });

  }

  /**
   * Creates a system db group and puts all system dbs inside the folder and
   * returns the transformed systemss DB group.
   *
   * @param   instanceId   SQL instance which has the system DBs
   * @param   systemDbs    List of system dbs to be decorated
   */
  private transformSystemDbs(instanceId: number, systemDbs: ProtectionSourceNode[]) {
    // Create a fake system db group node.
    const systemDbNode = {
      protectionSource: {
        // Use the negative instance ID for this container group. It will be
        // unique and associated with each instance.
        id: -1 * Number(instanceId),
        name: this.systemDatabaseGroupName,
        environment: Environment.kSQL,
        parentId: systemDbs[0].protectionSource.parentId,
        sqlProtectionSource: {
          // Copy properties from the first system db node to fill in most of what is needed.
          ...systemDbs[0].protectionSource.sqlProtectionSource,
          databaseName: undefined,
          name: this.systemDatabaseGroupName,
          type: 'kSystemDatabases',
        }
      },
      nodes: systemDbs,
    };

    const systemSourceNode = this.transformData(systemDbNode as ProtectionSourceNode, 2);
    systemDbs = systemDbs.map(node => this.transformData(node, 3));
    return [systemSourceNode, ...systemDbs];
  }

  /**
   * Returns exclude options
   *
   * @param excludeFilters exclude filters
   * @returns exclude options
   */
  getExcludeOptions(excludeFilters: ExcludeFilter[]): ExcludeOptions {
    let isSqlAgDatabase = false;
    const autoSelectedSourceIds = (this.dataTreeSelection.currentSelection.autoSelected || [])
      .map((node) => {
        if (node.isAagNode) {
          isSqlAgDatabase = true;
        }
        return node.id;
      });

    return {
      autoSelectedSourceIds: autoSelectedSourceIds,
      filters: excludeFilters,
      exclusionDescription: isSqlAgDatabase ? this.translate.instant('sqlAgExcludeDialog.description') :
        this.translate.instant('sqlExcludeDialog.description')
    };
  }

}
