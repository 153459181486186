// COMPONENT: File Backup Paths

;(function(angular, undefined) {
  'use strict';

  var modName = 'C.filePaths';
  var componentName = 'cFileBackupPaths';

  /**
   * @ngdoc component
   * @name C.filePaths:cFileBackupPaths
   * @function
   *
   * @description
   * Handles the addition and modification of file backup paths per
   * node selected on the c-source-tree view
   *
   * @example
      <example module="C">
        <c-file-backup-paths
          ng-if="sourceTree.length"
          job="job"
          node="node">
        </c-file-backup-paths>
      </example>
   */
  var options = {
    bindings: {
      job: '=?',
      node: '=',
    },
    controller: cFileBackupPathsFn,
    templateUrl: 'app/global/c-file-paths/c-file-paths.html'
  };

  angular
    .module(modName)
    .component(componentName, options);

  function cFileBackupPathsFn($rootScope, $scope) {

    var ctrl = this;

    var sourceId = ctrl.node.entity.id;

    // Default backupSourceParams object
    var backupParams = {
      sourceId: sourceId,
      physicalParams: {
        fileBackupParams: {
          backupPathInfoVec: [],
        },
      },
    };

    // Create the job.backupSourceParams array if does not exist.
    ctrl.job.backupSourceParams = ctrl.job.backupSourceParams || [];

    // Ensure node._backupSourceParams exists and is stubbed out. We store the
    // param data in this property and it is used in the objects.js controller
    // to compile the job.backupSourceParams in the request object for API call
    // when user advances to next step.
    ctrl.node._backupSourceParams =
      angular.merge({}, ctrl.node._backupSourceParams, backupParams);

    // Cache the backup path info because it's deep in the hierarchy.
    ctrl._backupPathInfoVec =
      ctrl.node._backupSourceParams.physicalParams
      .fileBackupParams.backupPathInfoVec;

    /**
     * $onInit function for the controller
     *
     * @method     $onInit
     */
    ctrl.$onInit = ctrl.setupFilePath;

    /**
     * Watch to see when a node is selected and setup the file path input
     *
     * @method     $doCheck
     */
    ctrl.$doCheck = function doCheck() {
      if (ctrl.node._isSelected) {
        ctrl.setupFilePath();
      }
    };

    /**
     * Setup model for new file path config
     *
     * @method     setupFilePath
     */
    ctrl.setupFilePath = function setupFilePath() {
      // If there are not yet any paths to backup, include the whole filesystem
      // root as default.
      if (!ctrl._backupPathInfoVec.length) {
        ctrl._backupPathInfoVec.push({
          includePath: '/',
          skipNestedVolumes: true,
        });
      }
    };

    /**
     * Adds an inclusion path for this node
     *
     * @method     addFilePath
     */
    ctrl.addFilePath = function addFilePath(backupPathInfoVec) {
      backupPathInfoVec.push({
          includePath: '',
          skipNestedVolumes: true,
        });
    };

    /**
     * Adds an exclusion path for this node
     *
     * @method     addExclusionPath
     */
    ctrl.addExclusionPath = function addExclusionPath(pathInfo) {
      pathInfo.excludePaths = pathInfo.excludePaths || [];
      pathInfo.excludePaths.push('');
    };

    /**
     * Removes the file path
     *
     * @method     removeFilePath
     * @param      {number}  index   The array index of the path to remove
     */
    ctrl.removeFilePath = function removeFilePath(index) {
      ctrl._backupPathInfoVec.splice(index, 1);
    };

    /**
     * Removes the exclusion path from the path it modifies
     *
     * @method     removeExclusionPath
     * @param      {object}  pathInfo  The path info for a node
     * @param      {number}  index     The array index of the path to remove
     */
    ctrl.removeExclusionPath = function removeExclusionPath(pathInfo, index) {
      pathInfo.excludePaths.splice(index, 1);
    };

  }

})(angular);
