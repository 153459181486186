import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import { DataTreeNodeContext, DataTreeNodeDetail } from '@cohesity/helix';
import { Environment } from '@cohesity/iris-shared-constants';
import { SOURCE_TREE_CONTEXT, SourceTreeContext } from '@cohesity/iris-source-tree';
import { AutoDestroyable } from '@cohesity/utils';
import { PassthroughOptionsService } from 'src/app/core/services';

import { Office365SourceDataNode } from '../../office365/office365-source-data-node';
import { PhysicalFilesSourceDataNode } from '../../physical/physical-files/physical-files-source-data-node';
import { HasConnectionState } from '../behaviors/has-connection-state';
import { InvalidCloudVmState } from '../behaviors/invalid-cloud-vm-state';
import { ProtectionSourceDataNode } from '../protection-source-data-node';

/**
 * Details view for a protection-source based node. This renders a protected/partial protected icon and
 * the node's name.
 *
 * @example
 * Manually added:
 * <cog-data-tree ...>
 *   <ng-container *cogDataTreeDetail="let ctx">
 *     <coh-protection-source-detail [nodeContext]="ctx">
 *   </ng-container>
 * </cog-data-tree>
 *
 * Dyanmically Added:
 * <cog-data-tree ...>
 *   <ng-container *cogDataTreeDetail="let ctx">
 *     <cog-data-tree-detail-outlet [component]="treeService.detailComponent" [nodeContext]="ctx">
 *     </cog-data-tree-detail-outlet>
 *   </ng-container>
 * </cog-data-tree>
 */
@Component({
  selector: 'coh-protection-source-detail',
  templateUrl: './protection-source-detail.component.html',
  styleUrls: ['./protection-source-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProtectionSourceDetailComponent extends AutoDestroyable
  implements DataTreeNodeDetail<ProtectionSourceDataNode>, OnInit {
  /**
   * Apply this class to the detail outlet containing this component, which allows it
   * to set the width properly. This requires style encapsulation to be turned off for
   * the component.
   */
  nodeClass = 'protection-source-detail';

  /**
   * The current search query, if any, applied in the main filter.
   */
  searchQuery: string;

  /**
   * The node context, including info about the node and it's selection status.
   */
  @Input() nodeContext: DataTreeNodeContext<ProtectionSourceDataNode>;

  /**
   * Access to the current node property.
   */
  get node(): ProtectionSourceDataNode {
    return this.nodeContext && this.nodeContext.node;
  }

  /**
   * Boolean that returns true if the Environment is kPure and node is of type
   * Pure Protection Group.
   */
  get isPureEnv(): boolean {
    return this.node.environment === Environment.kPure &&
      this.node.isPureProtectionGroup;
  }

  /**
   * Convert the node to an instance of the HasConnectionState interface. Returns
   * undefined if the node does not implement the interface.
   */
  get asConnectionState(): HasConnectionState | undefined {
    return (this.node as any).asConnectionState;
  }

  /**
   * Gets a label to show for the connection state, if it applies.
   */
  get connectionState(): string {
    return this.asConnectionState && this.asConnectionState.connectionState
      ? `enum.connectionState.${this.asConnectionState.connectionState}`
      : '';
  }

  /**
   * Adds a connection-statep-problem class to the node if there are any connection state issues on
   * the node.
   */
  @HostBinding('class.connection-state-problem')
  get hasConnectionStateProblem(): boolean {
    return this.asConnectionState && this.asConnectionState.hasConnectionStateProblem;
  }

  /**
   * Convert the node to an instance of the HasConnectionState interface. Returns
   * undefined if the node does not implement the interface.
   */
  get asInvalidCloudVmState(): InvalidCloudVmState | undefined {
    return (this.node as any).asInvalidCloudVmState;
  }

  /**
   * Gets a label to show for the connection state, if it applies.
   */
  get invalidCloudVmStateMessage(): string {
    return this.asInvalidCloudVmState && this.asInvalidCloudVmState.invalidCloudVmStateMessage
      ? this.asInvalidCloudVmState.invalidCloudVmStateMessage
      : '';
  }

  /**
   * Adds a connection-statep-problem class to the node if there are any connection state issues on
   * the node.
   */
  @HostBinding('class.connection-state-problem')
  get isInvalidCloudVm(): boolean {
    return this.asInvalidCloudVmState && this.asInvalidCloudVmState.isInvalidCloudVm;
  }

  /**
   * The index in the node name where the query match starts.
   * Returns -1 if no match.
   */
  get queryMatchIndex(): number {
    if (!this.searchQuery) {
      return -1;
    }
    return this.node.name.toLocaleLowerCase().indexOf(this.searchQuery);
  }

  /**
   * Returns true if the object details link should be shown.
   */
  get showDetailLink(): boolean {
    return this.sourceTreeContext?.showNavigationLinks;
  }

  /**
   * Returns true if the object is migrated from different vCenter.
   */
  get isVcenterMigrated(): boolean {
    return !!this.node.envSource.vmLinkingInfo?.isMigrated;
  }

  /**
   * Determines whether the agent upgrade is inprogress.
   */
  get isAgentUpgrading(): boolean {
    return (this.node as PhysicalFilesSourceDataNode)?.isAgentUpgrading;
  }

  /**
   * Determines the upgrade status of the agent.
   */
  get upgradeStatus(): string {
    return (this.node as PhysicalFilesSourceDataNode)?.upgradeStatus;
  }

  /**
   * Determines whether the agent installed  is upgradable or not.
   */
  get isAgentUpgradable(): boolean {
    return (this.node as PhysicalFilesSourceDataNode)?.isUpgradable;
  }

  /**
   * Determines the agent upgrade error status.
   */
  get upgradeError(): boolean {
    return !!(this.node as PhysicalFilesSourceDataNode)?.upgradeAgentError;
  }

  /**
   * Speciifes the workload type.
   */
  get workloadType(): any {
    return (this.node as Office365SourceDataNode)?.workloadType;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    readonly passthroughOptionsService: PassthroughOptionsService,
    @Optional() @Inject(SOURCE_TREE_CONTEXT) private sourceTreeContext: SourceTreeContext
  ) {
    super();
  }

  ngOnInit() {
    if (this.sourceTreeContext && this.sourceTreeContext.searchQuery$) {
      this.sourceTreeContext.searchQuery$.pipe(this.untilDestroy()).subscribe(query => {
        this.searchQuery = query && query.toLocaleLowerCase().trim();
        this.cdr.detectChanges();
      });
    }
  }

  /**
   * Returns source name label tooltip.
   *
   * @returns source name label tooltip.
   */
  getSourceLabelTooltip(): string {
    if (!this.nodeContext?.canSelect && this.node?.parentHostName && this.nodeContext?.selected) {
      return `${this.node.name} (${this.node.parentHostName})`;
    }
    return this.node.name;
  }
}
