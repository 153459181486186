import { ReportColumnConfigs } from '../common-report-items/custom-reports.service';
import { ReportSection } from '../iris-reporting.model';
import {
  commonReportConfigItems,
  getStorageConsumptionColumns,
  storageConsumptionCommonColumns,
} from './common-report-config-items';

/**
 * This is the layout config for the storage consumption by storage domain report.
 */
export const storageConsumptionStorageDomainReport: ReportSection[] = [
  {
    title: 'reporting.summary',
    items: [
      {
        id: 'statlistItem',
        componentIds: ['1201'],
        componentNames: ['StorageConsumptionStorageDomainSummary'],
        params: {
          items: [
            {
              title: 'storageDomains',
              translate: true,
              measurement: {
                valueKey: 'countViewBoxId',
                dataType: 'number',
              },
            },
            ...commonReportConfigItems.storageConsumption.glanceBar,
          ],
        },
      },
    ],
  },
  {
    title: 'reporting.data',
    isFullData: true,
    items: [
      {
        id: 'tableItem',
        componentIds: ['1200'],
        componentNames: ['AllStorageConsumptionByStorageDomains'],
        params: {
          columns: [
            {
              title: 'storageDomain',
              translate: true,
              key: 'viewBoxName',
              primary: {
                key: 'viewBoxName',
                dataType: 'text',
              },
              subtitle: {
                key: 'systemName',
                dataType: 'text',
              },
            },
            {
              title: 'system',
              enabled: true,
              translate: 'true',
              key: 'systemName',
              primary: {
                key: 'systemName',
                dataType: 'text',
              },
            },
            {
              title: 'organization',
              enabled: false,
              translate: true,
              key: 'tenantName',
              primary: {
                key: 'tenantName',
                dataType: 'text',
                renderParams: {
                  emptyKey: 'naNotAvailable',
                },
              },
              featureFlag: 'ngReportSPView',
            },
            ...getStorageConsumptionColumns('AllStorageConsumptionByStorageDomains'),
          ],
        },
      },
    ],
  },
];

/**
 * This is metadata needed to determine available chart customizations.
 */
export const storageConsumptionStorageDomainColumns: ReportColumnConfigs = {
  viewBoxName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  viewBoxId: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  system: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  systemId: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  ...storageConsumptionCommonColumns,
};
