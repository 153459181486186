import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { SeriesPieOptions } from 'highcharts';
import { ObservableInput } from 'ngx-observable-input';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { SecurityCenterDashboardService } from '../../../services';

/**
 * Format for legend data.
 */
interface LegendData {
  label: string;
  value: number;
}

/**
 * THreats discovered card data.
 */
export interface ThreatsDiscoveredData {
  /**
   * Count of infected snapshot.
   */
  infected: number;

  /**
   * Count of clean snapshot.
   */
  clean: number;

  /**
   * Count of tatal IOCs.
   */
  totalIOCCount: number;

  /**
   * Count of total Objects scanned.
   */
  totalObjectCount: number;

  /**
   * Count of total scana.
   */
  totalScanCount: number;

  /**
   * No of critical IOCs detected in the scan
   */
  criticalIocs: number;

  /**
   * No of non-critical IOCs detected in the scan
   */
  nonCriticalIocs: number;
}

/**
 * @description
 * Threats Discovered Component visualizes threats distribution across snapshots.
 *
 * @example
 *   <dg-sc-threats-discovered [data]="cardData"></dg-sc-threats-discovered>
 */
@Component({
  selector: 'dg-sc-threats-discovered',
  templateUrl: './threats-discovered.component.html',
  styleUrls: ['./threats-discovered.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThreatsDiscoveredComponent extends AutoDestroyable implements OnInit {

  /**
   * The data recieved by the component using which chart data is generated.
   */
  @ObservableInput() @Input() data: BehaviorSubject<ThreatsDiscoveredData>;

  /**
   * Loading state of the card.
   */
  @Input() loading: boolean;

  /**
   * Skips IOC chart if true.
   */
  @Input() timeframeLabel: string;

  /**
   * total number of snapshot that are scanned
   */
  totalSnapshots = 0;

  /**
   * Formatted data to display in the infected snapshots chart.
   */
  infectedChartData: SeriesPieOptions;

  /**
   * Legends for the infected snapshots.
   */
  infectedChartlegends: LegendData[] = [];

  /**
   * total number of iocs count
   */
  totalIOCs = 0;

  /**
   * total number of objects scanned
   */
  totalObjectsScanned = 0;

  /**
   * total number of scans
   */
  totalScans = 0;

  /**
   * Formatted data to display in the IOCs distribution chart.
   */
  iocDistributionChartData: SeriesPieOptions;

  /**
   * Legends for the IOCs distribution.
   */
  iocDistributionChartlegends: LegendData[] = [];

  constructor(
    readonly translate: TranslateService,
    readonly dashboardService: SecurityCenterDashboardService
  ) {
    super();
  }

  ngOnInit() {
    this.data.pipe(
      filter(data => !!data),
      this.untilDestroy(),
    ).subscribe((data) => {
      this.totalSnapshots = data.clean + data.infected;
      this.totalIOCs = data.totalIOCCount;
      this.totalObjectsScanned = data.totalObjectCount;
      this.totalScans= data.totalScanCount;

      this.infectedChartData = this.createInfectedSnapshotsChartData(data);
      this.infectedChartlegends = this.createInfectedSnapshotsLegends(data);
    });
  }

  /**
   * Create Infected Chart based on passed data to the component.
   *
   * @param data threat protection data
   * @returns Array of values to be displayed in the chart in the order -> infected, clean snapshots.
   */
  createInfectedSnapshotsChartData(data: ThreatsDiscoveredData): SeriesPieOptions {
    return {
      type: 'pie',
      data: [
        {
          y: data.infected,
          name: this.translate.instant('dg.sc.dashboard.threatsDiscovered.infected'),
        },
        {
          y: data.clean,
          name: this.translate.instant('dg.sc.dashboard.threatsDiscovered.clean'),
        },
      ],
    };
  }

  /**
   * Create Infected chart Legends based on passed data to the component.
   *
   * @param data threat protection data
   * @returns Array of legends to display in chart.
   */
  createInfectedSnapshotsLegends(data: ThreatsDiscoveredData): LegendData[] {
    return [
      { label: 'dg.sc.dashboard.threatsDiscovered.infected', value: data.infected },
      { label: 'dg.sc.dashboard.threatsDiscovered.clean', value: data.clean },
    ];
  }
}
