import { DataTreeControl, DataTreeFilter, DataTreeFilterUtils } from '@cohesity/helix';
import { FilterGroup, SourceTreeFilters } from '@cohesity/iris-source-tree';
import { TranslateService } from '@ngx-translate/core';

import { SqlSourceDataNode } from './sql-source-data-node';

/**
 * Provide view filters for NAS sources.
 */
export class SqlFilters<T extends SqlSourceDataNode> {
  /**
   * These are the default filters.
   */
  sourceFilter: FilterGroup;

  constructor(
    private treeControl: DataTreeControl<any>,
    private filters: SourceTreeFilters<any>,
    private translate: TranslateService
  ) {
    this.sourceFilter = {
      id: 'source-filter',
      label: 'sourceType',
      options: [
        {
          id: 'aag',
          label: this.translate.instant('aag'),
          filter: this.filterAagDatabases,
        },
        {
          id: 'fci',
          label: this.translate.instant('fci'),
          filter: this.filterFciDatabases,
        },
      ]
    };
    this.filters.addFilterGroup(this.sourceFilter);
  }

  /**
   * Filter callback function to show only AAG databases.
   */
  filterAagDatabases: DataTreeFilter<any> = (nodes: any[]) =>
    DataTreeFilterUtils.searchFilter(nodes, this.treeControl, (node: T) => node.parentHostIsAagNode, false, true);

  /**
   * Filter callback function to show only FCI databases.
   */
  filterFciDatabases: DataTreeFilter<any> = (nodes: any[]) =>
    DataTreeFilterUtils.searchFilter(nodes, this.treeControl, (node: T) => node.isFciNode, false, true);

}
