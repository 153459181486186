// Component: licenseFileDetail

(function(angular) {
  'use strict';

  angular
    .module('C.licenseServer')
    .controller('licenseFileDetailsCtrl', licenseFileDetailsCtrlFn)
    .component('licenseFileDetails', {
      bindings: {
        // license description json
        license: '=',
      },
      controller: 'licenseFileDetailsCtrl',
      templateUrl: 'app/license/license-file-details.html',
    });

  function licenseFileDetailsCtrlFn(_) {
    var $ctrl = this;
  }
})(angular);
