import { Component, HostBinding, Input } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { SimpleTags } from '@cohesity/api/v2';
import { CreateForm, createFormProviders } from '@cohesity/shared-forms';
import { TranslateService } from '@ngx-translate/core';


/**
 * FormGroup Model for Tag control
 * */
interface AWSTagsForm {
  tagList: SimpleTags[];
}


@Component({
  selector: 'coh-recover-vm-aws-tags-form',
  templateUrl: './recover-vm-aws-tags-form.component.html',
  styleUrls: ['./recover-vm-aws-tags-form.component.scss'],
  providers: createFormProviders(RecoverVmAwsTagsFormComponent)
})
export class RecoverVmAwsTagsFormComponent {

  /**
   * If the component should be displayed as read-only and set class.
   */
  @HostBinding('class.recovery-options-read-only') @Input() readOnly: boolean;

  /**
   * If the control is required
   * */
  @Input() controlLabel?: string;

  /**
   * Maximum characters for tag key
   * */
  tagKeyMaxLength = 128;

  /**
   * Maximum characters for tag value
   * */
  tagValueMaxLength = 256;

  /**
   * Maximum number of tags allowed
   * */
  maximumTags = 48;

  /**
   * Declaration for form control
   * */
  form = new CreateForm<SimpleTags[], AWSTagsForm>(this, {
    formControls: {
      tagList: new FormArray([])
    },
    transformFromFormGroup: (value) => (value.tagList ?? []).filter(tag => !!tag.key && !!tag.value),
    transformToFormGroup: (value) => {
      const tags = value?.length ? value : [{key: '', value: ''}];
      if (tags.length) {
        tags.forEach((tag) => {
          this.addTagControl(tag);
        });
      } else {
        this.addTagControl();
      }
      return { tagList: tags };
    }
  });

  /**
   * Returns tag list formArray
   * */
  get tagListForm() {
    return this.form.formGroup.controls.tagList as FormArray;
  }

  /**
   * Returns the component formGroup instance
   * */
  get formGroup() {
    return this.form.formGroup;
  }

  /**
   * The settings label displayed in the component
   * */
  get _controlLabel() {
    return this.controlLabel ?? this.translateService
      .instant('protection_sharedRestore_restoreText.customResourceTags');
  }

  /**
   * Returns the summary text displayed when setting is minimized
   * */
  get tagLabel() {
    const translateKey = !this.form?.value?.length ? 'none': `protection_sharedRestore_restoreText.tagsAdded`+
    `.${this.form?.value?.length === 1 ? 'singular' : 'plural'}`;
    return this.translateService.instant( translateKey, {tagNumber: this.form?.value?.length });
  }

  /**
   * Returns control readOnly values in comma separated pairs
   * */
  get readOnlyValues() {
    const tags = (this.tagListForm.value ?? []).filter(tag => !!tag.key && !!tag.value);
    if(tags.length) {
      return tags.reduce((value, tag, index) => {
        value = value + `${tag.key}=${tag.value}${(index === (tags.length - 1) ? '' : ', ')}`;
        return value;
      }, '');
    } else {
      return this.translateService.instant('none');
    }
  }

  constructor(private translateService: TranslateService) {
  }

  /**
   * Adds a new tag control to the form array
   *
   * @param tag Optional Tag object for default initial values
   * */
  addTagControl(tag?: SimpleTags) {
    if(this.tagListForm.length < this.maximumTags) {
      const control = new FormGroup({
        key: new FormControl(tag?.key ?? '', [
          this.tagNameValidator,
          this.valueRequiredValidator('value'),
          Validators.maxLength(this.tagKeyMaxLength)
        ]),
        value: new FormControl(tag?.value ?? '', [
          this.valueRequiredValidator('key'),
          Validators.maxLength(this.tagValueMaxLength)
        ])
      });
      this.tagListForm.push(control);
    }
  }

  /**
   * Removes a tag control to the form array at the specified index
   *
   * @param index The control index
   * */
  removeTagControl(index: number) {
    this.tagListForm.removeAt(index);
  }

  /**
   * Validate that a tag property is required when corresponding  tag attribute is defined
   *
   * @param controlKey The control name to determine the required validation state for the form control
   * @returns Error state for the control
   */
  valueRequiredValidator(controlKey: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const keyControl = control.parent?.get(controlKey);
      if(keyControl) {
        if (!keyControl.value && !control.value) {
          if(keyControl?.errors) {
            keyControl.setErrors(null);
          }
          return null;
        }
        if (!control.value && keyControl.value) {
          return { 'required': true };
        }
        if(!keyControl.value) {
          keyControl.updateValueAndValidity();
        }
      }
      return null;
    };
  }

  /**
   * Validate that a tag name is unique and required when a tag value is defined
   *
   * @param control The control to be validated
   * @returns Validation error for the control
   */
  tagNameValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const form = (control.parent?.parent as FormArray);
    if(form) {
      // Internal keys prohibited from users
      const internalKeys = ['uniquetag'];
      if(control.value && internalKeys.includes(control.value.toLowerCase())) {
        return { 'internalTagName': true };
      }
      form.controls.forEach(tagControl => tagControl.updateValueAndValidity({onlySelf: false, emitEvent: true}));
      const duplicateKeys = (form.value ?? []).map((tag) => tag.key).filter(val => val === control.value);
      if (control.value && (duplicateKeys.length > 1)) {
        return { 'tagName': true };
      }
    }
    return null;
  }

}
