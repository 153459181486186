<mat-form-field>
  <mat-label>{{ 'recipientsEmails' | translate }}</mat-label>
  <mat-chip-list #recipientList>
    <mat-chip *ngFor="let recipient of control?.value; index as i"
      [ngClass]="{ 'recipient-chip-error': recipient.error }"
      cogDataId="email-chip-{{i}}"
      removable="true"
      (removed)="remove(i)">
      {{ recipient.value }}
      <mat-icon cogDataId="email-chip-remove-{{i}}" matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input cogDataId="email-chip-input"
      [placeholder]="'addRecipientsEmails' | translate"
      [matChipInputFor]="recipientList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      matChipInputAddOnBlur="true"
      (matChipInputTokenEnd)="add($event)">
  </mat-chip-list>
</mat-form-field>
