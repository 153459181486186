<div *ngIf="!recoveryList?.length" class="no-data-container">
  <coh-blank-card
    type="recovery"
    message="{{ 'deleteSdNoRecoveries' | translate}}">
  </coh-blank-card>
</div>

<div *ngIf="recoveryList?.length" class="stepper-item-list margin-top margin-bottom">
  <div *ngFor="let recovery of recoveryList" class="c-media c-media-align-center single-item margin-top padding-bottom-sm">
    <cog-icon class="c-media-object"
      [shape]="recovery.action | recoveryActionIcon: recovery.environment">
    </cog-icon>
    <div class="c-media-body">
      <div class="flex-truncate-wrapper">
        <div class="flex-truncate-text user-data-xl">
          {{ recovery.name }}
        </div>
        <cog-tag *ngIf="recovery.tearDownStatus">
          {{ recovery.tearDownStatus | translate }}
        </cog-tag>
      </div>
      <coh-recovery-description [objects]="recovery.objects"></coh-recovery-description>
    </div>
  </div>
</div>
