import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { SnapshotSelectorUtilsService } from 'src/app/modules/restore/restore-shared/snapshot-selector/services/snapshot-selector-utils.service';
import { Environment, RecoveryAction } from 'src/app/shared';
import { RestorePointSelection } from '../../../model/restore-point-selection';

@Component({
  selector: 'coh-snapshot-selector-menu',
  templateUrl: './snapshot-selector-menu.component.html',
  styleUrls: ['./snapshot-selector-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnapshotSelectorMenuComponent implements OnChanges {
  /**
   * An initial restore point to select.
   */
  @Input() restorePoint: RestorePointSelection;

  /**
   * List of actions available for the snapshot
   */
  @Input() snapshotActions: RecoveryAction[];

  /**
   * Object actions keys by which to filter snapshots.
   */
  @Input() objectActionKeys: Environment[];

  /**
   * An event which is fired when a new snapshot is selected by the user.
   */
  @Output() snapshotChanged = new EventEmitter<RestorePointSelection>();

  /**
   * The trigger for the snapshot menu.
   */
  @ViewChild(MatMenuTrigger) snapshotMenu: MatMenuTrigger;

  /**
   * The currently selected restore point using the snapshot selector.
   */
  restorePointSelection: RestorePointSelection;

  constructor(readonly snapshotSelectorUtilsService: SnapshotSelectorUtilsService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.restorePoint && this.restorePoint) {
      this.restorePointSelection = this.restorePoint;
    }
  }

  /**
   * Callback for the "snapshotChange" event of snapshot selector component.
   */
  onSnapshotChange(restorePoint: RestorePointSelection) {
    this.restorePointSelection = restorePoint;
  }

  /**
   * Callback for the click event on the Cancel button.
   */
  onCancelClick() {
    this.snapshotMenu.closeMenu();
  }

  /**
   * Callback for the click event on the Apply button.
   */
  onApplyClick() {
    this.snapshotMenu.closeMenu();
    this.snapshotChanged.emit(this.restorePointSelection);

    // Reset restore point selection to the initial value.
    this.restorePointSelection = this.restorePoint;
  }
}
