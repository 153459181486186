// Controller: Hypervisor Registration/Edit
// NOTE: cSlideModal support currently only works for registering a new Source

;(function(angular, undefined) {
  'use strict';

  angular.module('C.sources')
    .controller('hypervisorModifyController', hypervisorModifyControllerFn);

  function hypervisorModifyControllerFn(_, StateManagementService, $scope,
    $state, $q, cMessage, cUtils, evalAJAX, SourceService, ENUM_ENV_TYPE,
    ENUM_ENTITY_TYPE, envType, envTypes, $uibModalInstance, NavStateService,
    FEATURE_FLAGS, ENV_GROUPS, ENV_TYPE_CONVERSION, ENTITY_KEYS, $rootScope,
    CLOUD_SUBSCRIPTION_TYPE, PUB_TO_PRIVATE_ENV_STRUCTURES,
    HV_ENV_GROUP_NAMES, AWS_AUTH_TYPE, PubSourceService, FORMATS, connectionId,
    UserService, NgGCPMultiSubnetService) {

    var $ctrl = this;

    var reader = cUtils.fileReader;

    const defaultSubnetConfig = {
      ip: '',
      netmaskBits: undefined,
    };

    _.assign($scope, {
      ENUM_ENTITY_TYPE: ENUM_ENTITY_TYPE,
      MAX_SAFE_INTEGER: Number.MAX_SAFE_INTEGER,
      isAwsInstall: $rootScope.clusterInfo._isAWSInstall,
      spaceThresholdType: 'gb',

      shared: {
        scvmmDifferentEndpoint: false,
        vCloudDirectorConnected: false,
        addPreferredSubnetVec: false,
        preferredSubnetVec: [angular.copy(defaultSubnetConfig)],
      },
      processPrivateKey: processPrivateKey,
      vcenterInfoList: [],
      caCertificate: {
        content: ''
      },
      certificates: {},

      // This is used here as the component that emits the values are not in AJS
      // So we need to maintain an additional flag for that inner upgradedform
      gcpSubnetFormInvalid: false,

      fleetSettingsWithIdEnabled: FEATURE_FLAGS.fleetSettingsWithId,
    });

    var defaultEntityTypeConfig = {
      // VMware
      type: 1,
      vmwareEntity: {
        // Entity_kVCenter
        type: 0,
      },
    };

    // NOTE: there is a mismatch between what we submit and what we get
    // back, so some level of rearrangement is necessary for interacting
    // with this API endpoint
    var defaultHypervisorSourceConfig = {
      entity: defaultEntityTypeConfig,
      entityInfo: {
        endpoint: undefined,

        // VMware
        type: 1,
        credentials: {
          username: undefined,
          password: undefined,
        },
        _isSelected: false,
      },
      registeredEntityParams: {
        isSpaceThresholdEnabled: false,
        spaceUsagePolicy: {
          minFreeDatastoreSpaceForBackupGb: undefined,
          minFreeDatastoreSpaceForBackupPercentage: undefined,
        },
        throttlingPolicy: {
          isThrottlingEnabled: false,
          isDatastoreStreamsConfigEnabled: false,
          datastoreStreamsConfig: {
            maxConcurrentStreams: undefined,
          },

          /**
           * This object will be added by copying defaultLatencyThresholds when
           * throttling is toggled on
           */
          // latencyThresholds: {
          //     newTaskLatencyThresholdMsecs: undefined,
          //     activeTaskLatencyThresholdMsecs: undefined
          // }
        },
        vmwareParams: {},
      },
    };

    var defaultLatencyThresholds = {
      newTaskLatencyThresholdMsecs: 30,
      activeTaskLatencyThresholdMsecs: 30
    };

    /**
     * @type    {Array}   List of VMware entities we allow to register.
     *                    `value` is the EntityProto
     */
    // TODO: Rename this property. It's not entity types, but environments
    // types. Entity types are a property within.
    $scope.entityTypes =
      NavStateService.getHypervisorSourceTypes().map(sourceType => {
        const { environment, entity } = sourceType;
        const environmentObject = PUB_TO_PRIVATE_ENV_STRUCTURES[environment];
        const envType = environmentObject.envType;
        const entityType = environmentObject.entityTypes[entity];

        return {
          name: [
            ENUM_ENV_TYPE[environment],
            ENUM_ENTITY_TYPE[envType][entityType]
          ].join(': '),
          group: HV_ENV_GROUP_NAMES[environment],
          value: {
            type: envType,
            [environmentObject.privateEntityKey]: {
              type: entityType,
            },
          },
        };
      });

    /**
     * filtering out the types of Hypervisor source type allowed
     * based on given envTypes if envTypes are given.
     */
    if (envTypes) {
      $scope.entityTypes = $scope.entityTypes.filter(
        function checkTypesAllowed (entityType) {
          return envTypes.includes(entityType.value.type);
        }
      );
    }

    $scope.defaultHypervisorSourceEntity = $scope.entityTypes[0];

    /**
     * Initialize this controller
     *
     * @method   $onInit
     */
    this.$onInit = function $onInit() {
      $scope.inModal = typeof $uibModalInstance === 'object';

      $scope.pageConfig = {
        // the entity ID to edit
        // 1. for page view read it from state params
        // TOOD: 2. for modal view read it from uib-modal resolver
        id: $scope.inModal ? undefined : $state.params.id,
      };

      $scope.viewMode = $scope.pageConfig.id ? 'modify' : 'new';

      getHypervisorSource().then(
        function getHypervisorSourceSuccess(source) {
          var getEntitiesOfTypeParams;
          $scope.hypervisorSource = source;

          if (_.get($scope.hypervisorSource.registeredEntityParams, 'vmwareParams.preferredSubnetVec')) {
            $scope.shared.addPreferredSubnetVec = true;
            setupPreferredSubnetVec($scope.hypervisorSource.registeredEntityParams.vmwareParams.preferredSubnetVec);
          }

          if (_.get($scope.hypervisorSource.registeredEntityParams, 'isSpaceThresholdEnabled') &&
            _.get($scope.hypervisorSource.registeredEntityParams, 'spaceUsagePolicy')) {
            $scope.spaceThresholdType = $scope.hypervisorSource.registeredEntityParams
              .spaceUsagePolicy.hasOwnProperty('minFreeDatastoreSpaceForBackupPercentage') ?
              'percentage' :
              'gb';
          }

          /**
           * Set the proper Hypervisor type in the ui-select. We have to do this
           * explicity because the actual `entity` object for an existing source
           * has more info than the objects in the list of ui-select options.
           * Therefore we have to merge the existing object into the appropriate
           * object in the list of matches so ui-select will recognize there is
           * a matching option.
           */
          $scope.entityTypes.some(function selectSource(entityType) {
            var entityKey = ENTITY_KEYS[source.entity.type];

            // TODO: It appears that `source.type` may be deprecated.
            // Please remove if confirmed.
            if ((source.type || source.entity.type) === entityType.value.type &&
              // Entity type(1) is same for vCenter and Standalone ESXi Host,
              // also check for one more level deep, sub entity type
              // (e.g vmwareEntity.type)
              source.entity[entityKey].type === entityType.value[entityKey].type) {
              return $scope.hypervisorSource.entity =
                _.merge(entityType.value, source.entity);
            }
          });

          $scope.shared.scvmmDifferentEndpoint = $scope.viewMode === 'modify' &&
            source.entityInfo &&
            source.entityInfo.agentEndpoint !== source.entityInfo.endpoint;

          // Fetch datastore for vmware, hyperv, acropolis, and kvm edit flow
          if ($scope.viewMode === 'modify') {
            switch(source.entity.type) {
              // vmwareEntity
              case 1:
                getEntitiesOfTypeParams = {
                  environmentTypes: ['kVMware'],
                  vmwareEntityTypes: ['kDatastore'],
                  rootEntityId: $scope.pageConfig.id,
                };
                break;

              // hypervEntity
              case 2:
                getEntitiesOfTypeParams = {
                  environmentTypes: ['kHyperV'],
                  hypervEntityTypes: ['kDatastore'],
                  rootEntityId: $scope.pageConfig.id,
                };
                break;

              // acropolisEntity
              case 12:
                getEntitiesOfTypeParams = {
                  environmentTypes: ['kAcropolis'],
                  acropolisEntityTypes: ['kStorageContainer'],
                  rootEntityId: $scope.pageConfig.id,
                };
                break;

              // kvmEntity
              case 15:
                getEntitiesOfTypeParams = {
                  environmentTypes: ['kKVM'],
                  kvmEntityTypes: ['kStorageDomain'],
                  rootEntityId: $scope.pageConfig.id,
                };
                break;
            }
          }

          // Fetch datastore for vmwareEntity, hypervEntity, Acropolis(AHV), or
          // KVM (RHV) edit flow
          if (getEntitiesOfTypeParams) {
            SourceService.getEntitiesOfType(getEntitiesOfTypeParams).then(
              function getDatastores(datastores) {
                $scope.datastores = datastores;
              });
          }

          // Trigger this once on init.
          $scope.onSourceTypeChange($scope.hypervisorSource.entity);
        },
        evalAJAX.errorMessage
      ).finally(
        function getHypervisorSourceFinally() {
          $scope.hypervisorSourceReady = true;
        }
      );

    };

    /**
   * Invoked by ng-gcp-fleet-settings output function which is in turned invoked by changing dropdown values.
   *
   * @param multiSubnetFormValue Object that should be copied to gcpFleetParams.
   */
    $ctrl.updateHypervisor = function check(multiSubnetFormValue) {
      const { value, valid } = multiSubnetFormValue;

      $scope.hypervisorSource.entity.gcpEntity.gcpFleetParams = NgGCPMultiSubnetService.convertMultiSubnetFormValuesToApiValues(value);

      $scope.gcpSubnetFormInvalid = !valid;
    }

    /**
     * Exit back to list view
     *
     * @method   cancel
     */
    $scope.cancel = function cancel() {

      if ($scope.inModal) {
        $uibModalInstance.dismiss('user.cancel');
        return;
      }

      StateManagementService.goToPreviousState('sources-new');
    };

    /**
     * Handles when the source type is changed.
     *
     * @method   onSourceTypeChange
     * @param    {object}   entity   The selected EntityProto
     */
    $scope.onSourceTypeChange = function onSourceTypeChange(entity) {
      var cloudEntity;
      var entityCredentials;
      var defaultSubscription;
      var defaultAuthentication;

      $scope.cloudSourceSelected =
        ENV_GROUPS.cloudSources.includes(entity.type);

      // if Azure/AWS is selected, default the subscription type to 'standard'
      if ([ENV_TYPE_CONVERSION.kAWS, ENV_TYPE_CONVERSION.kAzure]
        .includes(entity.type)) {

        if (ENV_TYPE_CONVERSION.kAWS === entity.type) {
          cloudEntity = 'awsEntity';
          entityCredentials = 'awsCredentials';
          defaultSubscription = CLOUD_SUBSCRIPTION_TYPE.kAWSCommercial;
          defaultAuthentication = AWS_AUTH_TYPE.kUseIAMUser;

          var awsCredentialsKey = [
            'entityInfo',
            'credentials',
            'cloudCredentials',
            'awsCredentials',
          ].join('.');

          var awsSubscriptionType =
            _.get(entity, cloudEntity + '.commonInfo.subscriptionType');
          var authMethodKey = awsCredentialsKey + '.authMethod';
          var authMethod = _.get($scope.hypervisorSource, authMethodKey);
          var defaultAuthentication = authMethod ?
            AWS_AUTH_TYPE[authMethod] : defaultAuthentication;

          // Default auth mode to 'IAM User'
          _.set($scope.hypervisorSource, authMethodKey, defaultAuthentication);

          // If 'IAM Role' is present in "Edit" mode, split the iamRoleArn into
          // accountId and iamRole name.
          if (_.get($scope.hypervisorSource, awsCredentialsKey + '.authMethod')
            === AWS_AUTH_TYPE.kUseIAMRole) {

            var iamRoleArn = _.get($scope.hypervisorSource,
              awsCredentialsKey + '.iamRoleArn');

            if (iamRoleArn) {
              // Account ID from iamRoleArn
              _.set($scope.hypervisorSource, 'entity.awsEntity.ownerId',
                iamRoleArn.split('::')[1].split(':')[0]);
              // IAM Role Name from iamRoleArn
              _.set($scope.hypervisorSource,  awsCredentialsKey + '._iamRole',
                iamRoleArn.split('/')[1]);
            }
          }

          // Fleet Instance Locations for AWS
          $scope.fleetInstanceLocations = [
            {
              display: 'sameSubnetAsVM',
              value: 'kSourceVM',
            }, {
              display: 'selectNew',
              value: 'kCustom',
            }
          ];

          $ctrl.specifyFleetSettings = Object.keys(
            _.get(entity, 'awsEntity.awsFleetParams') || []).length > 0;
          $ctrl.tags =
            _.get(entity, 'awsEntity.awsFleetParams.fleetTagVec') || [];

          $ctrl.specifyInventoryLocation =
            _.get(entity, 'awsEntity.s3ProtectionParams.s3InventoryReportDestinationBucket') !== undefined;

          if ($scope.isAwsInstall &&
            awsSubscriptionType !== CLOUD_SUBSCRIPTION_TYPE.kAWSGovCloud) {

            $scope.fleetInstanceLocations.unshift({
              display: 'sameSubnetAsCE',
              value: 'kCluster',
            });
          }

          // If a fleet instance location is already selected, init related values
          $scope.onFleetInstanceSelection(
            _.get(entity, 'awsEntity.awsFleetParams.fleetSubnetType'));

          SourceService.getSources({entityId: entity.id}).then(
            function foundSource(resp){
              var region;
              var fleetRegion = _.get(entity,
                'awsEntity.awsFleetParams.networkParamsVec[0].region');
              var ceRegion =
                _.get(entity, 'awsEntity.clusterNetworkInfo.region');

              if ($scope.viewMode === 'modify') {
                $ctrl.awsRegions = resp.entityHierarchy.children
                  .filter(function filterNodes(node) {
                    if (node.entity.displayName === fleetRegion) {
                      region = node;
                    }
                    if (node.entity.displayName === ceRegion) {
                      $ctrl.ceRegion = node;
                      _.set(entity,
                        'awsEntity.awsFleetParams.networkParamsVec[0].region',
                          ceRegion);
                    }
                    return node.entity.awsEntity.type ===
                      PUB_TO_PRIVATE_ENV_STRUCTURES.kAWS.entityTypes.kRegion;
                  });

                if (region) {
                  $scope.selectAWSRegion(region, true);
                }
                if (ceRegion) {
                  $scope.selectAWSRegion($ctrl.ceRegion, true);
                }
              }
            }, evalAJAX.errorMessage);

            reader.onload = readerOnLoad(ENV_TYPE_CONVERSION.kAWS);
        } else {
          cloudEntity = 'azureEntity';
          entityCredentials = 'azureCredentials';
          defaultSubscription = CLOUD_SUBSCRIPTION_TYPE.kAzureCommercial;

          _.set($scope.hypervisorSource, '_subscriptionType', defaultSubscription);
        }

        _.set($scope.hypervisorSource.entityInfo.credentials,
          'cloudCredentials.' + entityCredentials + '.subscriptionType',
            // if this is edit mode, subscriptionType will already be available
            CLOUD_SUBSCRIPTION_TYPE[
              _.get(entity, cloudEntity + '.commonInfo.subscriptionType')] ||
                // else use default
                defaultSubscription);
      }

      if (entity.type === ENV_TYPE_CONVERSION.kGCP) {
        $scope.hypervisorSource._useJsonKeyFile = true;
        $scope.hypervisorSource._uploadConfig = {
          usePadding: true,
          onFileSelect: _getJsonFileContents,
        };

        reader.onload = readerOnLoad(ENV_TYPE_CONVERSION.kGCP);
      }
    };

    /**
     * If JSON Key File is being used for getting gcp credentials,
     * read the file contents
     *
     * @method  _getJsonFileContents
     * @param   {Object}  file  The selected json file object
     */
    function _getJsonFileContents(file) {
      $scope.hypervisorSource._jsonKeyFile = file;

      reader.onerror = function fileLoadFailed(err) {
        cMessage.error({
          title: 'errorReadingFile',
        });
      };

      reader.readAsText($scope.hypervisorSource._jsonKeyFile);
    }

    /**
     * Download the AWS CloudFormation template as a JSON file
     *
     * @method downloadAwsCFT
     */
    $scope.downloadAwsCFT = function downloadAwsCFT() {
      PubSourceService.downloadAwsCFT()
        .then(function cftDownloaded(resp) {
          var element = document.createElement('a');

          element.setAttribute('href', 'data:text/plain;charset=utf-8,' +
            encodeURIComponent(JSON.stringify(resp, null, 2)));
          element.setAttribute('download', 'cft.json');

          element.style.display = 'none';
          document.body.appendChild(element);

          element.click();

          document.body.removeChild(element);
        });
    };

    /**
     * Handler for file upload. When a certificate file is added from the ui,
     * this method converts it into byte object and sets the relevant scope variables.
     *
     * @param    {String}   key  The key for the file model
     */
    $scope.awsCertificateAdded = function certificateAdded(key) {
      reader.readAsArrayBuffer(_.get($scope, 'certificates.' + key));
      $scope.certificateKey = key;
    };

    /**
     * Callback function for uploading files
     * @param {*} env
     */
    function readerOnLoad(env) {
      return function onFileLoad(e) {
        switch (env) {
          case ENV_TYPE_CONVERSION.kAWS:
            var arrayBuffer = e.target.result;
            var array = new Uint8Array(arrayBuffer);
            var binaryString = String.fromCharCode.apply(null, array);

            _.set($scope.hypervisorSource,
              'entityInfo.credentials.cloudCredentials.awsCredentials.c2SServerCapInfo.' + $scope.certificateKey,
              binaryString);
            break;

          case ENV_TYPE_CONVERSION.kGCP:
            var accessCredentials = JSON.parse(reader.result);

            var gcpCredentials = {
              clientEmailAddress: accessCredentials.client_email,
              clientPrivateKey: accessCredentials.private_key,
            }

            _.set($scope.hypervisorSource.entityInfo.credentials,
              'cloudCredentials.gcpCredentials', gcpCredentials);

            processPrivateKey();

            break;
        }
      }
    }

    /**
     * Adds a new datastore to override number of stream.
     *
     * @method   addNewDatastoreOverride
     */
    $scope.addNewDatastoreOverride = function addNewDatastoreOverride() {
      $scope.hypervisorSource.registeredEntityParams.throttlingPolicy.
        datastoreThrottlingPolicies = $scope.hypervisorSource.
          registeredEntityParams.throttlingPolicy.
          datastoreThrottlingPolicies || [];

      $scope.hypervisorSource.registeredEntityParams.throttlingPolicy.
        datastoreThrottlingPolicies.push({
          datastoreEntity: undefined,
          isDatastoreStreamsConfigEnabled: false,
        });
    };

    /**
     * Determines if the datastore override exists.
     *
     * @method   overrideExists
     * @param    {object}    datastore datastore entity
     * @return   {boolean}   True if datastore is already selected to override
                             number of streams.
     */
    $scope.overrideExists = function overrideExists(selectedDatastore, datastore) {
      return ($scope.hypervisorSource.registeredEntityParams.throttlingPolicy.
        datastoreThrottlingPolicies || []).some(
        function checkDatastoreThrottlingPolicy(throttlingPolicy) {
            if (throttlingPolicy.datastoreEntity) {
              return throttlingPolicy.datastoreEntity.id === datastore.id &&
                (!selectedDatastore || selectedDatastore.id !== datastore.id);
            }
        });
    };

    /**
     * handle form submission
     *
     * @param    {object}             form   the angular form object being
     *                                       submitted
     * @return   {object|undefined}   if in modal and form valid, returns
     *                                $uibModalInstance.close otherwise
     *                                undefined
     */
    $scope.submitForm = function submitForm(theForm) {

      var actionFn = $scope.pageConfig.id ?
        SourceService.updateSource : SourceService.createSource;
      var entity = $scope.hypervisorSource.entity;
      var entityInfo = $scope.hypervisorSource.entityInfo;

      // Check for GCP subnet form validity as well.
      if (theForm.$invalid || $scope.gcpSubnetFormInvalid) {
        return;
      }

      // The type must be duplicated both places in the proto.
      entityInfo.type = entity.type;

      // Sync HyperV agentEndpoint if scvmmDifferentEndpoint option selected.
      if (entity.type === 2 && !$scope.shared.scvmmDifferentEndpoint) {
        entityInfo.agentEndpoint = entityInfo.endpoint;
      }

      // Credentials are not needed for Standalone HyperV
      if (_.get(entity, 'hypervEntity.type') === 1) {
        entityInfo.credentials = undefined;
      }

      // AWS -> Check if fleet settings are specified
      if (_.get(entity, 'awsEntity.type') === 0) {
        if (entityInfo.entity) {
          entityInfo.entity.awsEntity.awsFleetParams =
            entity.awsEntity.awsFleetParams;
        }

        if ($ctrl.tags && entity.awsEntity.awsFleetParams) {
          entity.awsEntity.awsFleetParams.fleetTagVec = $ctrl.tags;
        }

        if (!$ctrl.specifyFleetSettings) {
          entity.awsEntity.awsFleetParams = undefined;
        } else {
          if (entity.awsEntity.awsFleetParams.fleetSubnetType !== 'kCustom') {
            delete entity.awsEntity.awsFleetParams.networkParamsVec;
          }
        }

        if (!$ctrl.specifyInventoryLocation) {
          entity.awsEntity.s3ProtectionParams = undefined;
          entityInfo.entity.awsEntity.s3ProtectionParams = undefined;
        }

        // For C2S, send special ownerId to backend which is a concatenation of
        // agency, mission and role separated with special character '~'
        if (entity.awsEntity.commonInfo.subscriptionType ===
          CLOUD_SUBSCRIPTION_TYPE.kAWSC2S) {

          var c2sInfo = entityInfo.credentials
            .cloudCredentials.awsCredentials.c2SServerCapInfo;

          entity.awsEntity.ownerId = entity.awsEntity.commonInfo.id =
            [c2sInfo.agency, c2sInfo.mission, c2sInfo.role].join('~');
        }
      }

      // Registering vCenters under a vCloud Director requires
      // the vmwareParams property to be populated.
      // 17 = vCloud Director
      if (_.get(entity, 'vmwareEntity.type') === 17) {
        $scope.hypervisorSource.registeredEntityParams = {};

        _.assign($scope.hypervisorSource.registeredEntityParams, {
          vmwareParams: {
            vcdParams: {
              vcenterInfoList: _selectedVcenterInfoList(),
            },
          },
        });
      }

      // If Preferred Subnet Vec toggle is on convert netmaskBits from string to number and pass into param.
      if ($scope.shared.addPreferredSubnetVec) {
        if (!$scope.hypervisorSource.registeredEntityParams.vmwareParams) {
          $scope.hypervisorSource.registeredEntityParams.vmwareParams = {};
        }

        _.assign($scope.hypervisorSource.registeredEntityParams.vmwareParams, {
            preferredSubnetVec: $scope.shared.preferredSubnetVec.map(subnetVec => {
              return {
                ip: subnetVec.ip,
                netmaskBits: Number(subnetVec.netmaskBits),
              };
            }),
        });
      } else if (_.get($scope.hypervisorSource.registeredEntityParams,
        'vmwareParams.preferredSubnetVec')) {
        // In edit mode preferredSubnetVec has been set, but the user toggles addPreferredSubnetVec off,
        // set the param to undefined.
        $scope.hypervisorSource.registeredEntityParams.vmwareParams.preferredSubnetVec = undefined;
      }

      // Remove spaceUsagePolicy from api payload if the toggle is set to false.
      if (!_.get($scope.hypervisorSource.registeredEntityParams, 'isSpaceThresholdEnabled') &&
        !!_.get($scope.hypervisorSource.registeredEntityParams, 'spaceUsagePolicy')) {
        $scope.hypervisorSource.registeredEntityParams.spaceUsagePolicy = undefined;
      }

      _assignConnectionId();

      // If the flag for GCP Subnet is enabled then during registration, we miss
      // out the owner info which gets populated in updateCloudEntity. So we do
      // that manually here before submitting.
      if (FEATURE_FLAGS.gcpMultiSubnetEnabled &&
        $scope.hypervisorSource.entity.type === ENV_TYPE_CONVERSION.kGCP &&
        $scope.viewMode === 'new') {
        $scope.updateCloudEntity();
      }

      $scope.submitting = true;

      // Omit out fields which are not required in the payload, if any.
      const payload = _.omit($scope.hypervisorSource, 'availableSubnetConfigs');

      actionFn(payload).then(
        function actionFnSuccess(source) {
          if ($scope.inModal) {
            return $uibModalInstance.close(source);
          }

          cMessage.success({
            textKey: $scope.pageConfig.id ?
              'sources.hypervisor.success.editText' :
              'sources.hypervisor.success.newText',
            textKeyContext: $scope.hypervisorSource,
          });

          StateManagementService.goToPreviousState('sources-new');

        },
        evalAJAX.errorMessage
      ).finally(
        function actionFnFinally(response) {
          $scope.submitting = false;
        }
      );
    };

    /**
     * Gets the VMware source object, either via API call or copying the default
     * object for new VMware source registration
     *
     * @method   getHypervisorSource
     * @return   {object}   resolves with VMware source object, or rejects with
     *                      raw server response
     */
    function getHypervisorSource() {
      var deferred;
      var params;
      var source;

      if ($scope.pageConfig.id) {
        // This is an modify flow. We need to get the source form the API.

        params = {
          onlyReturnOneLevel: false,
          entityId: $scope.pageConfig.id,
        };

        return SourceService.getSources(params).then(
          function getSourceSuccess(resp) {
            var hierarchy = resp && resp.entityHierarchy;
            $ctrl.source = hierarchy;

            // If response is missing anything, reject and return
            if (!hierarchy) {
              return { data: { message: $scope.text.errorLoadingServer } };
            }

            return {
              entity: hierarchy.entity,
              entityInfo: hierarchy.registeredEntityInfo.connectorParams,
              availableSubnetConfigs: NgGCPMultiSubnetService.getAvailableSubnetConfigs(hierarchy.children),
              registeredEntityParams: _.merge({},
                hierarchy.registeredEntityInfo.registeredEntityParams, {
                  isSpaceThresholdEnabled:
                    hierarchy.registeredEntityInfo.registeredEntityParams &&
                    hierarchy.registeredEntityInfo.registeredEntityParams
                    .spaceUsagePolicy ?
                    (!!hierarchy.registeredEntityInfo.registeredEntityParams
                    .spaceUsagePolicy.minFreeDatastoreSpaceForBackupGb ||
                    !!hierarchy.registeredEntityInfo.registeredEntityParams
                    .spaceUsagePolicy.minFreeDatastoreSpaceForBackupPercentage) :
                    false,
                }),
            };
          }
        );

      }

      // This is a new Hypervisor source registration.
      deferred = $q.defer();

      // If an envType was injected, preselect that type. Otherwise, use the
      // default (first in list).
      source = $scope.entityTypes.find(function findMatchingType(entityType) {
        return (!envType || envType === entityType.value.type);
      });

      if (source) {
        deferred.resolve(angular.merge({}, defaultHypervisorSourceConfig, {
          entity: angular.copy(source.value) }));
      } else {
        deferred.reject();
      }

      return deferred.promise;
    }

    /**
     * Function to return whether concurrent streams for vmware is available.
     *
     * @method enableCapConcurrentStreams
     * @return  true if the setting is applicable
     */
    $scope.enableCapConcurrentStreams = function enableCapConcurrentStreams() {
      var hypervisorEntity = _.get($scope, 'hypervisorSource.entity');

      return (FEATURE_FLAGS.enableVmwareConcurrentStreams &&
        [0, 10].includes(_.get(hypervisorEntity, 'vmwareEntity.type'))) ||

        (FEATURE_FLAGS.enableHypervConcurrentStreams &&
        [0].includes(_.get(hypervisorEntity, 'hypervEntity.type'))) ||

        (FEATURE_FLAGS.enableKvmConcurrentStreams &&
        [0].includes(_.get(hypervisorEntity, 'kvmEntity.type'))) ||

        (FEATURE_FLAGS.enableAcropolisConcurrentStreams &&
        [1].includes(_.get(hypervisorEntity, 'acropolisEntity.type')));
    }


    /**
     * Return true if enableVmwareConcurrentBackups feature flag is enabled, and
     * it's VCenter.
     *
     * @method  enableCapConcurrentBackups
     * @return  true if the source is applicable
     */
    $scope.enableCapConcurrentBackups = function enableCapConcurrentBackups() {
      return FEATURE_FLAGS.enableVmwareConcurrentBackups &&

        // if vmwareEntity type is VCenter (0)
        _.get($scope, 'hypervisorSource.entity.vmwareEntity.type') === 0;
    }

    /**
     * adds or removes latencyThresholds based on ng-change of
     * isThrottlingEnabled.
     *
     * @method   updateThrottlingPolicy
     */
    $scope.updateThrottlingPolicy = function updateThrottlingPolicy() {

      // if the source isn't setup yet or it has no throttlingPolicy{},
      // exit early as all other logic is dependent on these being present.
      if (!$scope.hypervisorSource ||
          !$scope.hypervisorSource.registeredEntityParams.throttlingPolicy) {
        return;
      }

      if (!$scope.hypervisorSource.registeredEntityParams.throttlingPolicy
        .isThrottlingEnabled) {
        // remove latencyThresholds as throttling is disabled
        delete $scope.hypervisorSource.registeredEntityParams.throttlingPolicy.latencyThresholds;
      } else if (!$scope.hypervisorSource.registeredEntityParams
        .throttlingPolicy.latencyThresholds) {
        $scope.hypervisorSource.registeredEntityParams.throttlingPolicy
          .latencyThresholds = angular.copy(defaultLatencyThresholds);
      }
    };

   /**
     * Updates a cloud entity with additional data required by the backend.
     * AWS for instance requires the 'Access Key ID' in a field called
     * 'commonInfo' in addition to the 'awsEntity' field which is populated by
     * ngModel.
     *
     * @method   updateCloudEntity
     */
    $scope.updateCloudEntity = function updateCloudEntity() {
      var entity = $scope.hypervisorSource.entity;
      var credentials;

      switch (entity.type) {
        case ENV_TYPE_CONVERSION.kAWS:
          _.set(entity, 'awsEntity.commonInfo.id', entity.awsEntity.ownerId);
          $scope.updateAwsArn();
          break;

        case ENV_TYPE_CONVERSION.kAzure:
          credentials = $scope.hypervisorSource.entityInfo
            .credentials.cloudCredentials.azureCredentials;

          _.set(entity, 'azureEntity.name', credentials.subscriptionId);
          _.set(entity, 'azureEntity.id', [
            '/subscriptions/',
            credentials.subscriptionId,
          ].join(''));
          break;

        case ENV_TYPE_CONVERSION.kGCP:
          credentials = $scope.hypervisorSource.entityInfo
            .credentials.cloudCredentials.gcpCredentials;

          _.set(entity, 'gcpEntity.commonInfo.id',
             credentials.clientEmailAddress);
          _.set(entity, 'gcpEntity.ownerId', entity.gcpEntity.commonInfo.id);
          _.set(entity, 'gcpEntity.projectId', credentials.projectId);
          break;
      }
    };

    /**
     * Form an arn string from accountId and role user inputs for AWS
     * @method updateAwsArn
     */
    $scope.updateAwsArn = function() {
      var awsCredentialsStr =
        'entityInfo.credentials.cloudCredentials.awsCredentials';
      var subscriptionType = _.get($scope.hypervisorSource,
        awsCredentialsStr + '.subscriptionType');

      // C2S also uses iam role, but doesn't need ARN
      if (_.get($scope.hypervisorSource, awsCredentialsStr + '.authMethod')
        === AWS_AUTH_TYPE.kUseIAMRole && ![
          CLOUD_SUBSCRIPTION_TYPE.kAWSC2S,
        ].includes(subscriptionType)) {

        var accountId = _.get($scope.hypervisorSource,
          'entity.awsEntity.ownerId');
        var iamRole = _.get($scope.hypervisorSource,
          awsCredentialsStr + '._iamRole');
        var arnStr =
          subscriptionType === CLOUD_SUBSCRIPTION_TYPE.kAWSCommercial ?
            'aws' : 'aws-us-gov';

        // We take accountId and IAM Role Name as input from the user
        // but send their combination
        // (arn:aws:iam::<accountId>:role/<roleName>)
        // to the backend as iamRoleArn
        _.set($scope.hypervisorSource, awsCredentialsStr + '.iamRoleArn',
          'arn:' + arnStr + ':iam::' + accountId + ':role/' + iamRole);
      } else {
        _.unset($scope.hypervisorSource, awsCredentialsStr + '.iamRoleArn');
      }
    }

    /**
     * updates spaceUsagePolicy based on ng-change of
     * isSpaceThresholdEnabled.
     *
     * @method   updateSpaceUsagePolicy
     */
    $scope.updateSpaceUsagePolicy = function updateSpaceUsagePolicy() {
      if (!$scope.hypervisorSource.registeredEntityParams.isSpaceThresholdEnabled) {
        $scope.hypervisorSource.registeredEntityParams.spaceUsagePolicy
          .minFreeDatastoreSpaceForBackupGb = undefined;
        $scope.hypervisorSource.registeredEntityParams.spaceUsagePolicy
          .minFreeDatastoreSpaceForBackupPercentage = undefined;
      }
    };

    /**
     * Connect to to a vCloud Director
     */
    $scope.connectToVcloudDirector = function connectToVcloudDirector() {
      $scope.vcenterInfoList.length = 0;
      $scope.vCloudConnecting = true;
      $scope.hypervisorSource.registeredEntityParams.vmwareParams = {};

      SourceService.createSource($scope.hypervisorSource)
        .then(function connectVcloud(data) {
          $scope.vcenterInfoList =
            data.envRegisterEntityResult.vcdRegisterEntityResult.vcenterInfoList.slice(0);

          $scope.shared.vCloudDirectorConnected = true;
        }, evalAJAX.errorMessage)
      .finally(function connectVcloudFinally() {
        $scope.vCloudConnecting = false;
      });
    };

    /**
     * processes private key into a format suitable for backend
     *
     * @method  processPrivateKey
     */
    function processPrivateKey() {
      var gcpCredentials = $scope.hypervisorSource.entityInfo.credentials
        .cloudCredentials.gcpCredentials;

      gcpCredentials.clientPrivateKey =
        cUtils.normalizeGooglePrivateKey(gcpCredentials.clientPrivateKey);

      gcpCredentials.encryptedClientPrivateKey =
        cUtils.convertStringToBytesArr(gcpCredentials.clientPrivateKey);
    }

    /**
     * update cloudEntity when category (standard/gov) is changed
     *
     * @method  cloudCategoryChanged
     * @param   {String}  entityType  type of cloud entity
     */
    $scope.cloudCategoryChanged = function cloudCategoryChanged(entityType) {
      var entityCredentials = entityType === 'kAWS' ?
        'awsCredentials' : 'azureCredentials';
      var cloudEntity = entityType === 'kAWS' ?
        'awsEntity' : 'azureEntity';

      _.set($scope, 'hypervisorSource.entity.' +
        cloudEntity + '.commonInfo.subscriptionType',
          $scope.hypervisorSource.entityInfo
            .credentials.cloudCredentials[entityCredentials].subscriptionType);

      if (entityType === 'kAWS') {
        $scope.fleetInstanceLocations = $scope.fleetInstanceLocations
          .filter(function filterFleetLocations(location) {
            return location.value !== 'kCluster';
          });

        if ($scope.hypervisorSource.entity.awsEntity.commonInfo
            .subscriptionType === CLOUD_SUBSCRIPTION_TYPE.kAWSCommercial) {

          $scope.fleetInstanceLocations.unshift({
            display: 'sameSubnetAsCE',
            value: 'kCluster',
          });

          $scope.hypervisorSource.entityInfo.credentials.cloudCredentials
            .awsCredentials.authMethod = AWS_AUTH_TYPE.kUseIAMUser;

          $scope.isAwsInstall = $rootScope.clusterInfo._isAWSInstall;
        } else if ($scope.hypervisorSource.entity.awsEntity.commonInfo
          .subscriptionType === CLOUD_SUBSCRIPTION_TYPE.kAWSGovCloud) {

          $scope.hypervisorSource.entityInfo.credentials.cloudCredentials
            .awsCredentials.authMethod = AWS_AUTH_TYPE.kUseIAMUser;

          $scope.isAwsInstall = false;
        } else {
          $scope.hypervisorSource.entityInfo.credentials.cloudCredentials
            .awsCredentials.authMethod = AWS_AUTH_TYPE.kUseIAMRole;

          $scope.isAwsInstall = false;
        }

        $scope.updateAwsArn();
        $scope.initFleetSettings();
      } else {
        _.set($scope, 'hypervisorSource.entityInfo.credentials' +
          '.cloudCredentials.azureCredentials.subscriptionType',
            $scope.hypervisorSource._subscriptionType);

        _.set($scope, 'hypervisorSource.entity.azureEntity' +
          '.commonInfo.subscriptionType',
            $scope.hypervisorSource._subscriptionType);

        if (!ENV_GROUPS.azureStackTypes.includes(
            _.get($scope, 'hypervisorSource.entity.' +
              cloudEntity + '.commonInfo.subscriptionType'))) {

          $scope.hypervisorSource.entityInfo.credentials
            .cloudCredentials.azureCredentials.azureStackRegion =
          $scope.hypervisorSource.entityInfo.credentials
            .cloudCredentials.azureCredentials.azureStackHubDomainName =
              undefined;
        }
      }
    }

    /**
     * Is the current subscription type of Azure Stack type
     *
     * @method   isAzureStackType
     *
     * @returns  {boolean} True if selected subscription type is of Azure Stack Type
     */
    $scope.isAzureStackType = function isAzureStackType() {
      return ENV_GROUPS.azureStackTypes.includes(_.get($scope, [
        'hypervisorSource.entityInfo.credentials',
        'cloudCredentials.azureCredentials.subscriptionType'
      ].join('.')));
    }

    /**
     * Whether to show azure stack type in UI or not.
     * This radio group is to beshown only if both the feature flags are on.
     * If just one feature flag is on, that should become the default selection
     * without the need to show radio group
     *
     * @method showAzureStackType
     */
    $scope.showAzureStackType = function showAzureStackType() {
      return $scope.isAzureStackType() &&
        FEATURE_FLAGS.azureStack && FEATURE_FLAGS.azureStackADFS;
    }

    /**
     * Set 'commonInfo' when azure stack type is changed
     *
     * @method azureStackTypeChanged
     */
    $scope.azureStackTypeChanged = function azureStackTypeChanged() {
      _.set($scope, 'hypervisorSource.entity.azureEntity' +
        '.commonInfo.subscriptionType',
          $scope.hypervisorSource.entityInfo.credentials
            .cloudCredentials.azureCredentials.subscriptionType);
    }

    /**
     * Determine to disable submit button
     *
     * @method    submitDisabled
     * @returns   {Boolean}   True to disable submit button
     */
    $scope.isSubmitDisabled = function isSubmitDisabled() {
      return $scope.submitting ||

        // Register a vCD(17) and
        // no vCenter is connected or no vCenter Detail is selected
        (_.get($scope, 'hypervisorSource.entity.vmwareEntity.type') ===
          PUB_TO_PRIVATE_ENV_STRUCTURES.kVMware.entityTypes.kvCloudDirector &&
            (!$scope.shared.vCloudDirectorConnected ||
              !_selectedVcenterInfoList().length));
    }

    /**
     * Return the selected vcenterInfo
     *
     * @method    _selectedVcenterInfoList
     * @returns   {Array} An array of selected vcenter details
     */
    function _selectedVcenterInfoList() {
      return _.filter($scope.vcenterInfoList, ['_isSelected', true]);
    }

    /**
     * Set/Unset the ability to add tags to the aws source based on
     * "specifyFleetSettings" value.
     *
     * @method  toggleFleetSettings
     */
    $scope.toggleFleetSettings = function() {
      $scope.hypervisorSource.entity.awsEntity.awsFleetParams =
        $scope.hypervisorSource.entity.awsEntity.awsFleetParams || {};

      $scope.initFleetSettings();
    }

    /**
     * Initialize Fleet Related Settings
     *
     * @method initFleetSettings
     */
    $scope.initFleetSettings = function() {
      if ($scope.isAwsInstall && $ctrl.specifyFleetSettings &&
        _.get($scope,
          'hypervisorSource.entity.awsEntity.clusterNetworkInfo.region')) {

        _.set($scope.hypervisorSource.entity,
          'awsEntity.awsFleetParams.networkParamsVec[0].region',
            $scope.hypervisorSource.entity.awsEntity.clusterNetworkInfo.region);

        $scope.selectAWSRegion($ctrl.ceRegion, true);
      }
    }

    /**
     * Callback for aws region selection.
     * Filter the VPC list based on selected region.
     *
     * @method  selectAWSRegion
     * @param   {object}  region          The region protectionSource object
     * @param   {boolean} suppressReset  Don't reset dependant controls if true
     *                                    This is needed if data is being loaded
     *                                    from API
     */
    $scope.selectAWSRegion = function(region, suppressReset) {
      var vpc;
      var fleetVpc = _.get($scope.hypervisorSource.entity,
        'awsEntity.awsFleetParams.networkParamsVec[0].vpc');

      $ctrl.awsSubnets = [];

      $ctrl.awsVPCs =
        region.children.filter(function filterNodes(node) {
          // If fleet settings with ID is enabled, then use ID instead of VPC name
          const vpcMatch = $scope.fleetSettingsWithIdEnabled ?
            node.entity.awsEntity.commonInfo.id : node.entity.displayName;

          if (vpcMatch === fleetVpc) {
            vpc = node;
          }

          return node.entity.awsEntity.type ===
            PUB_TO_PRIVATE_ENV_STRUCTURES.kAWS.entityTypes.kVPC;
        });

      if (!suppressReset) {
        _.set($scope.hypervisorSource,
          'entity.awsEntity.awsFleetParams.networkParamsVec[0].vpc',  undefined);
        _.set($scope.hypervisorSource,
          'entity.awsEntity.awsFleetParams.networkParamsVec[0].subnet',
            undefined);
      } else {
        if (vpc) {
          $scope.selectAWSVpc(vpc, true);
        }
      }
    };


    /**
     * Callback for aws vpc selection.
     * Filter the Subnet list based on selected vpc.
     *
     * @method  selectAWSRegion
     * @param   {object}  vpc  The vpc protectionSource object
     * @param   {boolean} suppressReset  Don't reset dependant controls if true
     *                                    This is needed if data is being loaded
     *                                    from API
     */
    $scope.selectAWSVpc = function(vpc, suppressReset) {
      if (!suppressReset) {
        _.set($scope.hypervisorSource,
          'entity.awsEntity.awsFleetParams.networkParamsVec[0].subnet',
            undefined);
      }

      $ctrl.awsSubnets =
        vpc.children.filter(function filterNodes(node) {
          return node.entity.awsEntity.type ===
            PUB_TO_PRIVATE_ENV_STRUCTURES.kAWS.entityTypes.kSubnet;
        });
    };

    /**
     * Callback for fleet instance selection dropdown.
     * Initialises networkParamsVec Object.
     *
     * @param   {string}  location  The selected fleet location
     */
    $scope.onFleetInstanceSelection = function(location) {
      if (location === 'kCustom') {
        if (!$scope.hypervisorSource.entity.awsEntity
          .awsFleetParams.networkParamsVec) {

          $scope.hypervisorSource.entity.awsEntity
            .awsFleetParams.networkParamsVec = [];
        }
      }
    }

    /**
     * The operation of add or remove subnet vec.
     *
     * @param   {string}  operation   'add' or 'remove' the vec
     * @param   {number}  index       THe place the user wants to add vec or remove vec.
     */
    $scope.modifyPreferredSubnetVec = function(operation, index) {
      if (operation === 'add') {
        $scope.shared.preferredSubnetVec.splice(index, 0, angular.copy(defaultSubnetConfig));
      } else {
        if ($scope.shared.preferredSubnetVec.length > 1) {
          $scope.shared.preferredSubnetVec.splice(index, 1);
        } else {
          $scope.shared.preferredSubnetVec[0] = angular.copy(defaultSubnetConfig);
        }
      }
    }

    /**
     * Function  invoked on space threshold radio button select.
     *
     * @param   {string}  metric   The space threshold metric selected.
     */
    $scope.spaceThresholdMetricUpdated = function spaceThresholdMetricUpdated(metric) {
      $scope.spaceThresholdType = metric;
      if (metric == "gb") {
        $scope.hypervisorSource.registeredEntityParams.spaceUsagePolicy
          .minFreeDatastoreSpaceForBackupPercentage = null;
      } else {
        $scope.hypervisorSource.registeredEntityParams.spaceUsagePolicy
          .minFreeDatastoreSpaceForBackupGb = null;
      }
    }

    /**
     * Apply the given subnet to preferredSubnetVec for displaying.
     *
     * @param {array} subnetVecs
     */
    function setupPreferredSubnetVec(subnetVecs) {
      // Remove the default empty vec.
      $scope.shared.preferredSubnetVec.pop();
      subnetVecs.forEach((vec) => {
        $scope.shared.preferredSubnetVec.push({
          ip: vec.ip,
          netmaskBits: vec.netmaskBits.toString(),
        });
      });
    }

    /**
     * patch the existing source details with
     * connection id if applicable
     */
    function _assignConnectionId() {
      // assign connection id if applicable
      // create case
      if (!$scope.pageConfig.id) {
        // assign passed connection id or fallback to default connection id
        const defaultConnectionId = UserService.getDefaultBifrostConnectionId();
        const assignConnectionId = connectionId ? connectionId : defaultConnectionId;
        if (assignConnectionId) {
          // create case from MCM helios
          $scope.hypervisorSource.connectionId = assignConnectionId;
        }
      }
    }
  }

}(angular));
