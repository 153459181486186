// Component: App Review

;(function(angular, undefined) {
  'use strict';

  angular.module('C.appsManagement')
  .controller('appReviewCtrl', AppReviewCtrlFn)
    .component('appReview', {
      templateUrl:
        'app/apps-management/app-store/app-details/reviews/reviews.html',
      controller: 'appReviewCtrl',
      bindings: {
        // @type   {Object}   App specific details
        app: '=',
      }
    });

  /**
   * @ngdoc component
   * @name C.appsManagement:appReview
   * @function AppReviewCtrlFn
   *
   * @description
   * App reviews and rating details.
   *
   * @example
     <app-review
       app="$ctrl.appDetails">
     </app-review>
   */
  function AppReviewCtrlFn(_, AppStoreService) {
    var $ctrl = this;

    _.assign($ctrl, {
      isLoading: false,
      reviews: [],

      // Methods
      openAppReviewRatingModal: openAppReviewRatingModal,

      // Component life-cycle methods
      $onInit: $onInit,
    });

    /**
     * Initializes the controller and gets details of the specified application.
     *
     * @method   $onInit
     */
    function $onInit() {
      _getReviewDetails();
    }

    /**
     * Provides details of the specified application.
     *
     * @method   _getReviewDetails
     */
    function _getReviewDetails() {
      $ctrl.isLoading = true;
      AppStoreService.getAppReviews($ctrl.app.appId)
      .then(function gotDetailsSuccess(response) {
        $ctrl.reviews = response;
      })
      .finally(function finallyAfterResponse() {
        $ctrl.isLoading = false;
      });
    }

    /**
     * Launch app review modal to submit app review.
     *
     * @method   openAppReviewRatingModal
     */
    function openAppReviewRatingModal() {
      AppStoreService.launchAppReviewModal($ctrl.app);
    }
  }
})(angular);
