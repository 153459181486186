import { AppModuleConfig, StateAccessMap } from 'src/app/app-module.config';

// The direct link to the Self Service Portal will now remain unguarded which
// will allow any user to Sign IN. Additional authorization is driven through
// iris backend flags.
const m365SelfServicePortalAccess = () => true;

/**
 * Specifies the config for Self-Service for all adaptors.
 * Adaptors may choose to add their corresponding states to support self
 * service in future. Currently this is only used by M365(Office365).
 */
export const SelfServicePortalConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'self-service-portal.**',
      url: '^/self-service-portal/',
      loadChildren: () => import('./self-service-portal.module').then(m => m.SelfServicePortalModule),
    },
  ],
  allClusterMap: {
    'self-service-portal': true,
    'self-service-portal.auth': true,
    'self-service-portal.m365': true,
  },
  getStateAccessMap(): StateAccessMap {
    return {
      'self-service-portal': m365SelfServicePortalAccess,
      'self-service-portal.auth': m365SelfServicePortalAccess,

      // TODO(tauseef): Add a Guard for this state.
      'self-service-portal.m365': m365SelfServicePortalAccess,
    };
  },
};
