// Component: clipboardCta

;(function(angular, undefined) {
  'use strict';

  var moduleName = 'C.clipboardCta';
  var moduleDependencies = ['C', 'C.help', 'ngclipboard'];
  var componentName = 'clipboardCta';
  var componentConfig = {
    controller: 'ClipboardCtaCtrl',
    templateUrl: 'app/global/clipboard-cta/clipboard-cta.html',
    bindings: {
      /**
       * The content that gets copied to the clipboard.
       *
       * @type {string} clipboardContent
       */
      clipboardContent: '<',

      /**
       * If header text is desired, provide a ui.json text key.
       *
       * @type {string} [headerKey]
       */
      headerKey: '@?',

      /**
       * If a help icon with link to documentation is desired, provide the help
       * key as found in cHelp.js.
       *
       * @type {string} [headerHelpKey]
       */
      headerHelpKey: '@?',

      /**
       * The ui.json key for the call-to-action anchor.
       *
       * @type {string} [copyActionKey=copyToClipboard]
       */
      copyActionKey: '@?',

      /**
       * The ui.json key for the desired success message.
       *
       * @type {string} [successMessageKey=copiedToClipboard]
       */
      successMessageKey: '@?',

      /**
       * The ui.json key for the call-to-action anchor.
       *
       * @type {string} [downloadActionKey=download]
       */
      downloadActionKey: '@?',


      /**
       * Filename to be used when downloading the context as a text file.
       */
      downloadFilename: '<?',

      /**
       * The context object for passing data into successMessageKey.
       *
       * @type {string} [successMessageKeyContext={
                          item: $ctrl.clipboardContent,
                        }]
       */
      successMessageKeyContext: '<?',

      /**
       * Whether to reverse the rows for the content and actions (i.e. show
       * text to copy first and then header and copy message underneath).
       *
       * @type {boolean} [reverseRows=true]
       */
      reverseRows: '<?',

      /**
       * Whether to allow downloading the content as a plain text file.
       *
       * @type {boolean} [allowDownload=true]
       */
      allowDownload: '<?',
    },
    transclude: true,
  };

  angular.module(moduleName, moduleDependencies)
    .controller('ClipboardCtaCtrl', clipboardCtaCtrlFn)
    .component(componentName, componentConfig);

  /**
   * $ngdoc Component
   * @name C.clipboardCta:clipboardCta
   *
   * @description
   *   Displays a box with a string in it and exposes copy-to-clipboard
   *   functionality for the string.
   *
   * @example
       <clipboard-cta
         c-message-key="job.script.sshKeySuccessfullyCopied"
         clipboard-contents="$ctrl.sshPublicKey"
         copy-action-key="copyKeyToClipboard"
         header-key="sshPublicKey"
         header-help-key="help_key_for_inline_help">
         {{$ctrl.sshPublicKey}} (transcluded content)
       </clipboard-cta>
   */
  function clipboardCtaCtrlFn(cMessage) {
    var $ctrl = this;

    angular.extend($ctrl, {
      successMessage: successMessage,
    });

    /**
     * Displays a success message to indicate copying to clipboard was
     * successful. Third-party library doesn't give us a hook for this, so we
     * just assume it worked and message accordingly.
     */
    function successMessage() {
      cMessage.success({
        textKey: $ctrl.successMessageKey || 'copiedToClipboard',
        textKeyContext: $ctrl.successMessageKeyContext || {
          item: $ctrl.clipboardContent,
        },
      });
    }
  }


})(angular);
