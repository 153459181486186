<h2 mat-dialog-title class="margin-bottom-lg">
  {{ 'createView' | translate }}
</h2>

<mat-dialog-content>
  <form id="create-view-form"
    cohValidateOnSubmit
    [formGroup]="formGroup"
    (validatedSubmit)="onSubmit()">

    <coh-create-view-basic-form formControlName="createViewBasicForm">
    </coh-create-view-basic-form>

  </form>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button
    cogCancelButton
    cogDataId="update-view-cancel-btn"
    color="primary"
    [disabled]="isSubmitting"
    mat-dialog-close>
    {{ 'cancel' | translate}}
  </button>

  <button mat-stroked-button
    cogDataId="update-view-more-options-btn"
    color="primary"
    [disabled]="isSubmitting"
    (click)="modifyViewSettings()">
    {{ 'moreOptions' | translate }}
  </button>

  <button mat-raised-button
    cogSubmitButton
    cogDataId="create-view-btn"
    id="create-view-from-dialog-btn"
    form="create-view-form"
    [disabled]="isSubmitting"
    [loading]="isSubmitting">
    {{ 'create' | translate }}
  </button>
</mat-dialog-actions>
