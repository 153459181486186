import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RpaasRegionInfo } from '@cohesity/api/v2';
import { ObservableInput } from 'ngx-observable-input';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Convenience interface for the activity stats.
 */
interface ActivityStats {
  running: number;
  completed: number;
  failed: number;
}

/**
 * This renders rpaas activity stats in a dashboard card
 *
 * @example
 *  <dg-sc-rpaas-activities-card [activiityStats]="activityStats"></dg-sc-rpaas-activities-card>
 */
@Component({
  selector: 'dg-sc-rpaas-activities-card',
  templateUrl: './rpaas-activities-card.component.html',
})
export class RpaasActivitiesCardComponent implements OnInit {
  /**
   * Notify parent to visit Vault Protection page.
   */
  @Output() readonly gotoVaultProtection = new EventEmitter<any>(null);

  /**
   * This should the activity stats summary returned by the api
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('activityStats') activityStats$: Observable<any[]> = null;

  /**
   * The current region filter.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('regions') regions$: Observable<RpaasRegionInfo[]> = null;

  /**
   * Map the region filter to the vault ids
   */
  regionIds$ = new BehaviorSubject<string[]>(null);

  /**
   * The current date range filter
   */
  @Input() dateRange: [number, number];

  /**
   * Gets the date range end time
   */
  get endTime(): number {
    return this.dateRange?.[1];
  }

  /**
   * Gets the date range start time
   */
  get startTime(): number {
    return this.dateRange?.[0];
  }

  /**
   * The stats for the activites.
   */
  stats$: Observable<ActivityStats> = this.activityStats$.pipe(
    map(stats => {
      if (stats) {
        return stats.reduce(
          (counts, stat) => {
            if (['Accepted', 'Canceling', 'Running', 'OnHold', 'Finalizing'].includes(stat.status)) {
              counts.running += stat.count;
            } else if (stat.status === 'Succeeded') {
              counts.completed += stat.count;
            } else if (['Missed', 'Failed'].includes(stat.status)) {
              counts.failed += stat.count;
            }
            return counts;
          },
          { running: 0, failed: 0, completed: 0 }
        );
      }

      return { running: 0, failed: 0, completed: 0 };
    })
  );

  ngOnInit() {
    this.regions$.pipe(map(regions => {
      const regionIds = regions?.map(region => region.globalVaultId);
      this.regionIds$.next(regionIds);
      return regionIds;
    }));
  }

  /**
   * Notify parent to navigate to Vault Protection Page by passing right parameters
   *
   * @param status The state parameters to navigate with
   */
  performGotoVaultProtection(status: string[]) {
    this.gotoVaultProtection.emit({
      status: status,
      vault: this.regionIds$.value,
      startTime: this.startTime,
      endTime: this.endTime,
      serviceType: 'rpaas'
    });
  }
}
