<h2 mat-dialog-title translate>
  renameConnection
</h2>
<form cohValidateOnSubmit [formGroup]="formGroup" (validatedSubmit)="onSubmit()">
  <mat-dialog-content class="mat-typography">
    <mat-form-field>
      <mat-label translate>name</mat-label>
      <input matInput formControlName="name" cogDataId="name-input" cdkFocusInitial>
      <mat-error *ngIf="formGroupErrors?.name?.required" cogDataId="name-input-error">
        {{ 'errors.required' | translate }}
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button cogCancelButton mat-stroked-button mat-dialog-close cogDataId="cancel-btn" [disabled]="busy$ | async">
      {{ 'cancel' | translate }}
    </button>
    <button cogSubmitButton mat-flat-button type="submit" color="primary"
      [disabled]="formGroup.invalid || (busy$ | async)" [loading]="busy$ | async" cogDataId="save-btn">
      {{ 'save' | translate }}
    </button>
  </mat-dialog-actions>
</form>
