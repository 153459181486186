// Module: hypervisor tab

;(function(angular) {
  'use strict';

  angular
    .module('C.devOps')
    .controller('HypervisorSummaryCtrl', HypervisorSummaryCtrlFn)
    .component('cHypervisorVm', {
      templateUrl: 'app/devops/hypervisor/hypervisor-vm.html',
      controller: 'HypervisorSummaryCtrl',
    });

  function HypervisorSummaryCtrlFn(_, $timeout, HypervisorVmService,
    cMessage, evalAJAX, $state, $window, ENUM_KUIPER_VM_STATE, $q,
    PollTaskStatus) {

    var $ctrl = this;

    var pollingInterval = 30;
    var isDoneDeferredMap = {};

    // Declare component methods and variable.
    _.assign($ctrl, {
      // Component life cycle methods.
      $onInit: getHypervisorVms,

      // Component methods
      deleteVm: deleteVm,
      filterHypervisorVms: filterHypervisorVms,
      getHypervisorVms: getHypervisorVms,
      isVMBeingCreated: isVMBeingCreated,
      launchConsole: launchConsole,
      updateVmStatus: updateVmStatus,

      // UI States
      status: 'all',
      vmDataReady: false,

      // Filtered list of hypervisor VMs
      filteredHypervisorVms: [],

      // Specifies the list of hypervisor VMs
      hypervisorVms: [],

      // Specifies the list of status filters
      statusTypes: [],

      // Specifies the usage data for hypervisor VMs
      usageConfig: [],
    });

    /**
     * List of filters for VM list.
     *
     * @type   {array}
     */
    $ctrl.statusTypes = [
      {
        value: 'all',
        name: 'allVms',
      },
      {
        value: 'running',
        name: 'poweredOnVms',
      },
      {
        value: 'shut-off',
        name: 'poweredOffVms',
      },
      {
        value: 'paused',
        name: 'suspendedVms',
      }
    ];

    /**
     * Sets the default values and calls for vm list
     *
     * @method   getHypervisorVMs
     */
    function getHypervisorVms() {
      $ctrl.vmDataReady = false;
      $ctrl.hypervisorVms = [];
      $ctrl.usageConfig = getDefaultUsageConfig();

      getHypervisorVMList();
    }

    /**
     * Retrieve list of hypervisor VMs and updates the usage config accordingly
     *
     * @method   getHypervisorVMList
     */
    function getHypervisorVMList() {
      var params = {
        query: 'all',
      }

      HypervisorVmService.getHypervisorVMs(params).then(
        function getHypervisorVmSuccess(hypervisorVms) {
          $ctrl.hypervisorVms = hypervisorVms;
          $ctrl.usageConfig = HypervisorVmService.getUsageConfig($ctrl.
          hypervisorVms);
          filterHypervisorVms();
          pollHypervisorVms();
        },evalAJAX.errorMessage).finally(
        function getHypervisorVmFinally() {
          $ctrl.vmDataReady = true;
          $ctrl.hypervisorVms.forEach(
            function updateOperationStatus(hypervisorVm) {
              hypervisorVm.operationCompleted = true;
          });
      });
    }

    /**
     * Sets the filtered hypervisorVms based on the filter state
     *
     * @method   filterHypervisorVms
     */
    function filterHypervisorVms() {
      if ($ctrl.status !== 'all') {
        $ctrl.filteredHypervisorVms = $ctrl.hypervisorVms.filter(
          function filterVMs(value, index, arr) {
            return value.state === $ctrl.status;
        });
      } else {
        $ctrl.filteredHypervisorVms = _.cloneDeep($ctrl.hypervisorVms);
      }
    }

    /**
     * Checks is the VM require polling and calls the poll function based on the
     * status
     *
     * @method   pollHypervisorVms
     */
    function pollHypervisorVms() {
      $ctrl.hypervisorVms.forEach(
        function checkForPolling(vm) {
          if (isVMBeingCreated(vm)) {
            isDoneDeferredMap[vm.uuid] = $q.defer();

            PollTaskStatus.createPoller({
              interval: pollingInterval,
              isDonePromise: isDoneDeferredMap[vm.uuid].promise,
              iteratorFn: function createVMPolling() {
                return initiatePolling(vm.uuid);
              },
            });
          } else if (vm.status === ENUM_KUIPER_VM_STATE.kFailed) {
            cMessage.error({
              textKey: 'devopsHypervisor.createVmFailure',
              textKeyContext: {name: vm.name},
            });

            HypervisorVmService.deleteVmConfig(vm.uuid);
            updateHypervisorVmList(vm, 'delete', []);
          }
        }
      );
    }

    /**
     * Checks if polling is need to be done, pollong is intiated in 4 conditions
     *     - When VM is in Creating state
     *     - When VM is in Removing state
     *     - When VM is in Converting state
     *     - When VM is in Converted state
     *
     * @method   initiatePolling
     * @param    {string}    uuid    Uuid of the particular VM
     */
    function initiatePolling(uuid) {
      var index = _.findIndex($ctrl.hypervisorVms, { uuid: uuid });
      switch($ctrl.hypervisorVms[index].status) {
        case ENUM_KUIPER_VM_STATE.kConverting:
        case ENUM_KUIPER_VM_STATE.kConverted:
        case ENUM_KUIPER_VM_STATE.kCreating:
        case ENUM_KUIPER_VM_STATE.kRemoving:
          return HypervisorVmService.getHypervisorVMs({query: uuid}).then(
            function hypervisorVmListSuccess(vms) {
              if(vms.length) {
                $ctrl.hypervisorVms[index] = vms[0];
              }
              filterHypervisorVms();
            }, evalAJAX.errorMessage);
          break;

        case ENUM_KUIPER_VM_STATE.kRunning:
          resolvedPollingPromise($ctrl.hypervisorVms[index],
            ENUM_KUIPER_VM_STATE.kRunning);
          break;

        case ENUM_KUIPER_VM_STATE.kFailed:
          resolvedPollingPromise($ctrl.hypervisorVms[index],
            ENUM_KUIPER_VM_STATE.kFailed);
          break;
      }
    }

    /**
     * Resolves the promise for create VM polling based on the status
     *
     * @method   resolvedPollingPromise
     * @param    {object}    vm       Details of the particular VM
     * @param    {string}    status   status of the create VM action
     */
    function resolvedPollingPromise(vm, status) {
      if (status === ENUM_KUIPER_VM_STATE.kRunning) {
        cMessage.success({
          textKey: 'devopsHypervisor.createVmSuccess',
          textKeyContext: {name: vm.name},
        });

        HypervisorVmService.getUsageConfig($ctrl.hypervisorVms);
        filterHypervisorVms();
      } else {
        cMessage.error({
          textKey: 'devopsHypervisor.createVmFailure',
          textKeyContext: {name: vm.name},
        });

        HypervisorVmService.deleteVmConfig(vm.uuid);
        updateHypervisorVmList(vm, 'delete', []);
      }

      isDoneDeferredMap[vm.uuid].resolve();
    }

    /**
     * Returns the default config for hypervisor VMs
     *
     * @method   getDefaultUsageConfig
     * @return   {object}    returns the default config
     */
    function getDefaultUsageConfig() {
      return [{
        value: 0,
        label: 'totalVms'
      },
      {
        value: 0,
        label: 'poweredOnVms',
        classes: 'status-ok'
      },
      {
        value: 0,
        label: 'poweredOffVms',
        classes: 'status-critical'
      },
      {
        value: 0,
        filterName: 'byteSize',
        label: 'usedStorage'
      },
      {
        value: 0,
        label: 'vcpusAllocated'
      },
      {
        value: 0,
        filterName: 'byteSize',
        label: 'memoryAllocated'
      },];
    }

    /**
     * Launch the VNC console for specified VM
     *
     * @method   launchConsole
     * @param    {object}    vm  Information of specified VM
     * @return   {Void}
     */
    function launchConsole(vm) {
      var url = `//${vm.hostIP}/devops/test-dev/console/${vm.uuid}`;

      $window.open(url, 'Launch_Console_' + vm.name, 'height=768,width=1248');
    }

    /**
     * Updates the state of vm based on the specified operation
     *
     * @method   updateVmStatus
     * @param    {object}    vm   Information of specified VM
     * @param    {string}    op   operation which need to be performed like
     *                            shutdown, resume, suspended etc.
     * @return   {Void}
     */
    function updateVmStatus(vm, op) {
      updateVmOperationStatus(vm, false);

      // TODO: Need to remove the delay.
      // JIRA Ticket: https://cohesity.atlassian.net/browse/ENG-49342
      HypervisorVmService.getHypervisorVMs({query: vm.uuid}).then(
        function getHypervisorVmSuccess(vms) {
          var params = {};

          if(vms.length){
            params = {
              vmHandle: vms[0].name,
              vmOp: op,
              hostIp: vms[0].hostIP,
            };

            // Adding delay for the api to complete the operation.
            HypervisorVmService.updateVmStatus(params).then(
              function updateVMStatusSuccess() {
                $timeout(function delay() {
                  updateHypervisorVms(vm, op);
                }, 5000);
              }, function updateVMStatusFail(resp) {
                updateVmOperationStatus(vm, true);
                evalAJAX.errorMessage(resp);
            });
          }
      });
    }

    /**
     * Deletes the specified VM
     *
     * @method   deleteVm
     * @param    {object}    vm   Information of specified VM
     * @return   {Void}
     */
    function deleteVm(vm) {
      updateVmOperationStatus(vm, false);
      HypervisorVmService.getHypervisorVMs({query: vm.uuid}).then(
        function getHypervisorVmSuccess(vms) {
          var params = {};

          if(vms.length){
            params = {
              vmName: vms[0].name,
              hostIp: vms[0].hostIP,
            };

            // Adding delay for the api to complete the operation.
            HypervisorVmService.deleteVm(params).then(
              function deleteVmSuccess() {
                $timeout(function delay() {
                  updateHypervisorVms(vm, 'delete');
                }, 5000);
              }, function deleteVMFail(resp) {
                updateVmOperationStatus(vm, true);
                evalAJAX.errorMessage(resp);
            });
          }
      });
    }

    /**
     * Updates the hypervisor list after operations like power on, power off,
     * delete, etc.
     *
     * @method   updateHypervisorVms
     * @param    {Object}    vm   Information of specified VM
     * @param    {string}    op   operation which got performed on the VM
     * @return   {Void}
     */
    function updateHypervisorVms(vm, op) {
      var params = {
        query: vm.uuid,
      }
      if (op === 'delete') {
        updateHypervisorVmList(vm, op, []);
      } else {
        HypervisorVmService.getHypervisorVMs(params).then(
          function getHypervisorVmSuccess(updatedVm) {
            updateHypervisorVmList(vm, op, updatedVm);
          },evalAJAX.errorMessage).finally(
          function getHypervisorVmFinally() {
            updateVmOperationStatus(vm, true);
          }
        );
      }
      cMessage.success({
        titleKey: 'success',
        textKey: 'devopsHypervisor.vmStatusSuccessMessage',
        textKeyContext: {
          op: op,
          vmName: vm.name,
        },
      });
    }

    /**
     * Updates the vm's operation status weather operation is completed or not
     *
     * @method   updateVmOperationStatus
     * @param    {Object}     vm       Information of specified VM
     * @param    {boolean}    status   specifies weather operation is completed
     *                                 or not
     */
    function updateVmOperationStatus(vm, status) {
      var hypervisorVm = _.find($ctrl.hypervisorVms, { uuid: vm.uuid });
      hypervisorVm.operationCompleted = status;
      filterHypervisorVms();
    }

    /**
     * Updates the hypervisorVm List based on the operation
     *
     * @method updateHypervisorVmList
     * @param    {Object}    vm         Information of specified VM
     * @param    {string}    op         operation which got performed on the VM
     * @param    {array}     updatedVm  updated list of the VMs
     *
     */
    function updateHypervisorVmList(vm, op, updatedVm) {
      var index = _.findIndex($ctrl.hypervisorVms, {uuid: vm.uuid});
      if (op === 'delete') {
        $ctrl.hypervisorVms.splice(index, 1);
      } else {
        $ctrl.hypervisorVms[index] = updatedVm[0];
      }
      $ctrl.usageConfig =
        HypervisorVmService.getUsageConfig($ctrl.hypervisorVms);
      filterHypervisorVms();
    }

    /**
     * Checks if VM is being created
     *
     * @method   isVMBeingCreated
     * @param    {object}    vm    Details of the particualr VM
     * @return   {bool}      returns true if vm is being created otherwise false
     */
    function isVMBeingCreated(vm) {
      return [ENUM_KUIPER_VM_STATE.kCreating, ENUM_KUIPER_VM_STATE.kConverting,
        ENUM_KUIPER_VM_STATE.kConverted, ENUM_KUIPER_VM_STATE.kRemoving]
        .includes(vm.status);
    }
  }

})(angular);