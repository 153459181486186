import { Injectable } from '@angular/core';
import { ProtectionSource, ProtectionSourceNode, ProtectionSourcesServiceApi } from '@cohesity/api/v1';
import {
  McmSource,
  McmSourceInfo,
  McmSources,
  ObjectServiceApi,
  ProtectedObjectInfo,
  SourceServiceApi,
} from '@cohesity/api/v2';
import { DataTreeControl, DataTreeNode, DataTreeSource } from '@cohesity/helix';
import { flagEnabled, IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { BehaviorSubject, combineLatest, forkJoin, iif, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, finalize, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { PassthroughOptionsService } from 'src/app/core/services';
import { McmSourceWithRmsConnections } from 'src/app/modules/helios-source-registration/azure-registration/azure-saas-connection-form.model';
import { AzureSaasConnectionFormService } from 'src/app/modules/helios-source-registration/azure-registration/azure-saas-connection/azure-saas-connection-form.service';
import { Environment, Memoize } from 'src/app/shared';
import { ProtectionSourceDataNode } from 'src/app/shared/source-tree/protection-source';
import {
  ProtectionSourceDataTransformer,
} from 'src/app/shared/source-tree/protection-source/shared/protection-source-data-transformer';

import { RestorePointSelection } from '../../../restore-shared';
import { extractRegionalNetworkResourceData, extractResourceName } from '../shared/recover-vm-azure.utils';

/**
 * Interface for protected object details
 */
interface ProtectedObjectsData {
  /**
   * List of protected object nodes
   */
  objects: ProtectionSource[];

  /**
   * List of object protection configurations
   */
  objectProtectionDetails: ProtectedObjectInfo[];
}

/**
 * Azure service for managing target recovery location.
 */
@Injectable()
export class RecoverAzureService {
  /**
   * Transformer for data nodes.
   */
  private transformer = new ProtectionSourceDataTransformer(
    (node: ProtectionSourceNode, level: number) => new ProtectionSourceDataNode(Environment.kAzure, node, level)
  );

  /**
   * Private loading property
   */
  private loading = new BehaviorSubject<boolean>(false);

  /**
   * The loading observable emits true whenver details for a new source are being fetched.
   */
  loading$ = this.loading.asObservable();

  /**
   * Subscription Data, in tree form which can be shown in a data tree select control.
   * (Applicable to DMaaS workflow).
   */
  subscriptionData: DataTreeSource<ProtectionSourceNode>;

  /**
   * Regions Data, in tree form which can be shown in a data tree select control.
   *  (Applicable to DMaaS workflow).
   */
  regionData: DataTreeSource<ProtectionSourceNode>;

  /**
   * Resource Group Data, in tree form (though still a flat list), which can be shown in a data tree select
   * field.
   */
  resourceGroupData: DataTreeSource<ProtectionSourceNode>;

  /**
   * Storage Resource Group Data needed to identify the resource group the selected storageAccount belongs to.
   */
  storageResourceGroupData: DataTreeSource<ProtectionSourceNode>;

  /**
   * Network Resource Group Data needed to identify the resource group the selected storageAccount belongs to.
   */
  networkResourceGroupData: DataTreeSource<ProtectionSourceNode>;

  /**
   * Available storage containers for selection based on the current resource group.
   */
  storageContainerData: DataTreeSource<ProtectionSourceNode>;

  /**
   * Available subnets for selection based on the current resource group.
   */
  subnetData: DataTreeSource<ProtectionSourceNode>;

  /**
   * Available computeOptions for selection based on the current resource group.
   */
  computeOptionData: DataTreeSource<ProtectionSourceNode>;

  /**
   * Available availabilitySets for selection based on the current resource group.
   */
  availabilitySetData: DataTreeSource<ProtectionSourceNode>;

  /**
   * Available storage account hierarchy data for selection based on the current storage resource group
   * (Applicable to DMaaS workflow).
   */
  storageAccountOptions: DataTreeSource<ProtectionSourceNode>;

  /**
   * Available virtual network hierarchy data for selection based on the current network resource group
   * (Applicable to DMaaS workflow).
   */
  virtualNetworkOptions: DataTreeSource<ProtectionSourceNode>;

  /**
   * Observable for the selected objects source
   */
  selectedObjectSource$ = new BehaviorSubject<ProtectionSourceNode>(null);

  /**
   * Observable for the selected objects details
   */
  selectedObjectDetails$ = new BehaviorSubject<ProtectionSource[]>([]);

  /**
   * Observable for the selected objects protection configurations
   */
  selectedObjectsProtectionDetails$ = new BehaviorSubject<ProtectedObjectInfo[]>([]);

  /**
   * Observable for the selected subscription for new location recovery.
   */
  selectedSubscription$ = new BehaviorSubject<ProtectionSourceNode>(null);

  /**
   * Observable for the selected resource group for new location recovery.
   */
  selectedResourceGroup$ = new BehaviorSubject<ProtectionSourceNode>(null);

  /**
   * Observable for the selected resource group for new location recovery.
   */
  selectedDestinationSource$ = new BehaviorSubject<ProtectionSourceNode>(null);

  /**
   * Observable for the selected resource registration detail ( DMaaS new location recovery).
   */
  selectedSourceRegistrationDetail$ = new BehaviorSubject<McmSourceWithRmsConnections>(null);

  /**
   * List of sources with registration details
   */
  mcmSources: McmSource[];

  /**
   * A list of all resource groups. This includes the entire tree hierarchy for resource groups, while the
   * resourceGroupData source removes the children completely from the nodes.
   */
  private resourceGroups = new BehaviorSubject<ProtectionSourceNode[]>([]);

  /**
   * A list of all regions associated with current subscription.
   */
  private regions = new BehaviorSubject<ProtectionSourceNode[]>([]);

  /**
   * A list of all azure subscriptions. This includes the entire tree hierarchy for resource groups, while the
   * subscriptionData source removes the children completely from the nodes.
   */
  private subscriptions = new BehaviorSubject<ProtectionSourceNode[]>([]);

  /**
   * The selected source node.
   */
  private _selectedSource: ProtectionSourceNode;

  /**
   * The selected region
   */
  private _selectedRegion = new BehaviorSubject<DataTreeNode<ProtectionSourceNode>>(null);

  /**
   * The selected Network ResourceGroup
   */
  private selectedNetworkResourceGroup: DataTreeNode<ProtectionSourceNode>;

  /**
   * The selected Storage ResourceGroup
   */
  private selectedStorageResourceGroup: DataTreeNode<ProtectionSourceNode>;

  /**
   * Get the selected source node.
   */
  get selectedSource(): ProtectionSourceNode {
    return this._selectedSource;
  }

  /**
   * Set the selected source node. Whenever this changes, we need to trigger an api call to fetch
   * the source tree.
   */
  set selectedSource(selectedSource: ProtectionSourceNode) {
    this._selectedSource = selectedSource;
    if (selectedSource && selectedSource.protectionSource) {
      this.getSourceInfo(selectedSource.protectionSource.id);
    }
  }

  /**
   * The selected source node.
   */
  private _selectedObjects: RestorePointSelection[];

  /**
   * Specifies if user context is in dmaas mode and the recovery feature for dmaas workflow is enabled
   */
  private readonly dmaasRecoveryEnabled = isDmsScope(this.ctxService.irisContext) &&
    flagEnabled(this.ctxService.irisContext, 'dmsAzureVmWorkload');

  /**
   * Get the selected source node.
   */
  get selectedObjects(): RestorePointSelection [] {
    return this._selectedObjects;
  }

  /**
   * (Returns the selectedObjectIds
   */
  get selectedObjectIds(): number[] {
    return (this._selectedObjects).map(obj => obj.objectInfo.id) as number[];
  }

  /**
   * Set the selected object for recovery. Whenever this changes, we need to trigger an api call to fetch
   * the selected object info.
   */
  set selectedObject(selectedObjects: RestorePointSelection[]) {
    this._selectedObjects = selectedObjects;
    if (selectedObjects?.length) {
      this.getObjectSourceInfo((selectedObjects.map(obj => obj.objectInfo.id) as number[]),
        selectedObjects[0]?.objectInfo?.sourceId);
    }
  }

  /**
   * The selected subscription.
   */
  private _selectedSubscription = new BehaviorSubject<DataTreeNode<ProtectionSourceNode>>(null);

  /**
   * Get the selected subscription.
   */
  get selectedSubscription(): DataTreeNode<ProtectionSourceNode> {
    return this._selectedSubscription.value;
  }

  /**
   * Set the selected subscription, when this changes, the subnet and storage container
   * values need to be updated.
   */
  set selectedSubscription(subscription: DataTreeNode<ProtectionSourceNode>) {
    this._selectedSubscription.next(subscription);
    this.selectedSubscription$.next(subscription?.data);
    if (subscription?.data) {
      // Get subscription with full data nodes
      const subSource = this.subscriptions.value.find((node: ProtectionSourceNode) =>
        node.protectionSource.id === subscription.id);
      this.resourceGroups.next(this.filterChildrenByType(subSource, 'kResourceGroup'));
      this.regions.next(this.filterChildrenByType(subSource, 'kRegion'));
    } else {
      this.resourceGroups.next([]);
      this.regions.next([]);
    }
  }

  /**
   * The selected resource group.
   */
  private _selectedResourceGroup = new BehaviorSubject<DataTreeNode<ProtectionSourceNode>>(null);

  /**
   * Subject for cleaning up subscriptions in the service when the cleanupSubscriptions is called
   */
  private destroy$ = new Subject<void> ();

  /**
   * Get the selected resource group.
   */
  get selectedResourceGroup(): DataTreeNode<ProtectionSourceNode> {
    return this._selectedResourceGroup.value;
  }

  /**
   * Set the selected resource group, when this changes, the subnet and storage container
   * values need to be updated.
   */
  set selectedResourceGroup(selectedResourceGroup: DataTreeNode<ProtectionSourceNode>) {
    this._selectedResourceGroup.next(selectedResourceGroup);
    this.selectedResourceGroup$.next(selectedResourceGroup?.data);
  }

  /**
   * Set the selected region, when this changes, the vnet and storage account
   * values need to be updated.
   */
  setSelectedRegion(region: DataTreeNode<ProtectionSourceNode>) {
    this._selectedRegion.next(region);
  }

  /**
   * Control value for the selected region
   */
  get selectedRegion(): Observable<DataTreeNode<ProtectionSourceNode>> {
    return this._selectedRegion.asObservable().pipe(distinctUntilChanged());
  }

  /**
   * Specifies if the protected objects all have a private network configuration
   */
  get isPrivateNetworkConfigProtected(): Observable<boolean> {
    return this.selectedObjectsProtectionDetails$
      .pipe(
        map(protectionDetails => (protectionDetails.length ?
          protectionDetails.every(protection => protection.objectBackupConfiguration.azureParams.
            nativeProtectionTypeParams?.dataTransferInfo.isPrivateNetwork) : false)
        ),
        distinctUntilChanged()
      );
  }

  /**
   * Specifies if selected objects contains one with at least a managedVm
   */
  get isManagedVmObject(): Observable<boolean> {
    return this.selectedObjectDetails$
      .pipe(
        map(objects => objects.some((object) => object?.azureProtectionSource?.isManagedVm)),
        distinctUntilChanged()
      );
  }

  constructor(
    private azureSaasConnectionService: AzureSaasConnectionFormService,
    private mcmSourceService: SourceServiceApi,
    private sourceService: ProtectionSourcesServiceApi,
    private passthroughOptionsService: PassthroughOptionsService,
    private ctxService: IrisContextService,
    private objectsService: ObjectServiceApi,
  ) {

    // Initialize DMaaS specific control data objects
    if(this.dmaasRecoveryEnabled) {
      this.subscriptionData = this.getDataTreeSourceInstance();
      this.regionData = this.getDataTreeSourceInstance();
    }

    // Configure the tree data sources for target location settings.
    this.resourceGroupData = this.getDataTreeSourceInstance();
    this.storageResourceGroupData = this.getDataTreeSourceInstance();
    this.networkResourceGroupData = this.getDataTreeSourceInstance();

    this.storageContainerData = this.getDataTreeSourceInstance();
    this.subnetData = this.getDataTreeSourceInstance();
    this.computeOptionData = this.getDataTreeSourceInstance();
    this.availabilitySetData = this.getDataTreeSourceInstance();

    this.virtualNetworkOptions = this.getDataTreeSourceInstance();
    this.storageAccountOptions = this.getDataTreeSourceInstance();
  }

  /**
   * Initializes the service core listeners and state
   * Should be called onInit in the associated component class
   */
  initService() {
    this.initControlChangeListener();
  }

  /**
   * Emits a next value to clean up the subscriptions.
   * Should be called when the parent recovery component class is destroyed.
   */
  cleanupSubscriptionsAndData() {
    this.destroy$.next();

    // Reset data to initial state
    this.selectedObjectSource$.next(null);
    this.selectedObjectDetails$.next([]);
    this.selectedSubscription$.next(null);
    this.selectedResourceGroup$.next(null);
    this.selectedDestinationSource$.next(null);
    this.resourceGroups.next([]);
    this.regions.next([]);
    this.subscriptions.next([]);
    this._selectedRegion.next(null);
    this._selectedSubscription.next(null);
    this._selectedResourceGroup.next(null);
  }

  /**
   * Extracts Virtual Networks in a region including networks belonging to a specified resource group
   *
   * @param regions The regions source containing virtual network child nodes
   * @param resourceGroup The network resourceGroup
   * @param location The region name
   *
   * @returns The filtered virtual network hierarchy data
   */
  private extractVirtualNetworkData(
    regions: ProtectionSourceNode[],
    resourceGroup: DataTreeNode<ProtectionSourceNode>,
    location: string): ProtectionSourceNode[] {
    const resourceName = resourceGroup.data.protectionSource.name;
    return this.filterTreeHierarchy(regions, ['kRegion', 'kVirtualNetwork', 'kSubnet'])
      .reduce((res, node) => res.concat(...node.nodes), [])
      .filter((node: ProtectionSourceNode) =>
        extractResourceName(node.protectionSource.azureProtectionSource.resourceId,
          'resourceGroups') === resourceName &&
        node.protectionSource.azureProtectionSource.location === location);
  }


  /**
   * Extracts Storage Accounts in a region including storage accounts belonging to a specified resource group
   *
   * @param resourceGroups The ResourceGroup source data containing the all resource groups belonging to a subscription
   * @param selectedResourceGroup The storage resourceGroup
   * @param location The region name
   *
   * @returns The filtered storage account hierarchy data
   */
  private extractStorageAccountData(
    resourceGroups: ProtectionSourceNode[],
    selectedResourceGroup: DataTreeNode<ProtectionSourceNode>,
    location: string): ProtectionSourceNode[] {
    return this.filterTreeHierarchy(resourceGroups,
      ['kResourceGroup', 'kStorageAccount', 'kStorageContainer'])
      .reduce((res: ProtectionSourceNode[], resourceGroup) => res.concat(...(resourceGroup.nodes ?? [])
        .filter((node: ProtectionSourceNode) =>
          resourceGroup.protectionSource.id === selectedResourceGroup.data.protectionSource.id &&
          node.protectionSource.azureProtectionSource.location === location)
      ), []);
  }

  /**
   * Initializes data listeners on observable properties for data selection options
   */
  private initControlChangeListener(): void {
    // Sets the list of subscriptions and regions on update to the source observables
    if (this.dmaasRecoveryEnabled) {

      this.subscriptions.pipe(takeUntil(this.destroy$)).subscribe(subscriptions => {
        this.subscriptionData.data = this.filterTreeHierarchy(subscriptions, ['kSubscription']);
      });
      this.regions.pipe(takeUntil(this.destroy$)).subscribe(regions => {
        this.regionData.data = this.filterTreeHierarchy(regions, ['kRegion']);
      });

      // The resource group list is a flat list of all the resource groups and should be updated
      // whenever the source is updated.
      this.resourceGroups.pipe(takeUntil(this.destroy$)).subscribe(groups => {
        const resourceGroupData = this.filterTreeHierarchy(groups, ['kResourceGroup']);
        this.resourceGroupData.data = resourceGroupData;
        this.networkResourceGroupData.data = resourceGroupData;
        this.storageResourceGroupData.data = resourceGroupData;
      });
    } else {
      // Update flat list of resource groups
      this.resourceGroups.pipe(takeUntil(this.destroy$)).subscribe(groups => {
        this.resourceGroupData.data = this.filterTreeHierarchy(groups, ['kResourceGroup']);
        this.storageResourceGroupData.data = this.filterTreeHierarchy(groups, ['kResourceGroup', 'kStorageAccount']);
        this.networkResourceGroupData.data = this.filterTreeHierarchy(groups, ['kResourceGroup', 'kVirtualNetwork']);
      });
    }

    // When the resource group is updated, the storage container and subnet lists need to be updated
    // They should show only the items in the same location as the selected resource group.
    combineLatest([this.resourceGroups, this._selectedResourceGroup.pipe(distinctUntilChanged()), this.regions])
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        ([groups, selectedGroup, regions]) => {
          if (!selectedGroup) {
            this.storageContainerData.data = [];
            this.subnetData.data = [];
            this.computeOptionData.data = [];
            this.availabilitySetData.data = [];
            return;
          }
          const location = selectedGroup.data.protectionSource.azureProtectionSource.location;

          const filteredGroups = groups.filter(
            group => group.protectionSource.azureProtectionSource.location === location
          );

          const selectedGroupWithNodes = groups.filter(
            group =>  group.protectionSource.id === selectedGroup.data.protectionSource.id
          );

          this.storageContainerData.data =
            this.filterTreeHierarchy(filteredGroups,
              ['kResourceGroup', 'kStorageAccount', 'kStorageContainer'], location) || [];

          // For backward compatibility for on-prem subnet data, if entity hierarchy response for vnet is attached
          // to regions, extract subnet hierarchy from region tree structure, otherwise adopt existing hierarchy impl.
          if(!this.dmaasRecoveryEnabled) {
            const regionVnets = this.filterTreeHierarchy(regions, ['kRegion', 'kVirtualNetwork', 'kSubnet']);
            if(regionVnets.length) {
              this.subnetData.data = extractRegionalNetworkResourceData(groups, regionVnets, location);
            } else {
              const subnets =
                this.filterTreeHierarchy(groups,
                  ['kResourceGroup', 'kVirtualNetwork', 'kSubnet']) || [];
              this.subnetData.data = this.filterSubnets(subnets, location) || [];
            }
          }

          this.computeOptionData.data = this.filterTreeHierarchy(selectedGroupWithNodes,
            ['kResourceGroup', 'kComputeOptions']) || [];

          this.availabilitySetData.data = this.filterTreeHierarchy(selectedGroupWithNodes,
            ['kResourceGroup', 'kAvailabilitySet']) || [];
        }
      );

    // On change of the selected region update descendant controls accordingly (Storage accounts/Virtual Networks)
    // Handles scenario where users may change region after selection of vnet/subnets
    combineLatest([this.selectedRegion, this.regions, this.resourceGroups])
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(([oldRegion], [newRegion]) => oldRegion?.id === newRegion?.id))
      .subscribe(([region, regions, resourceGroups]) => {
        if(this.selectedNetworkResourceGroup) {
          this.virtualNetworkOptions.data = this.extractVirtualNetworkData(regions,
            this.selectedNetworkResourceGroup, region.data.protectionSource.name);
        }

        if(this.selectedStorageResourceGroup) {
          this.storageAccountOptions.data = this.extractStorageAccountData(resourceGroups,
            this.selectedStorageResourceGroup, region.data.protectionSource.name);
        }
      });
  }

  /**
   * Returns a new instance of a data tree source object
   */
  private getDataTreeSourceInstance(): DataTreeSource<ProtectionSourceNode> {
    return new DataTreeSource(
      this.transformer,
      new DataTreeControl(this.transformer.getLevel, this.transformer.getExpandable)
    );
  }

  /**
   * Updates the value of the descendant control based on control value
   *
   * @param value The control value
   * @param key The updates control key/identifier
   */
  updateDescendantNodeData(
    value: DataTreeNode<ProtectionSourceNode>,
    key: 'networkResourceGroup' | 'storageResourceGroup') {
    // Given that the resourceGroups and regions are populated and have values
    if(this.dmaasRecoveryEnabled) {
      combineLatest([this.regions, this.resourceGroups, this.selectedRegion])
        .pipe(
          takeUntil(this.destroy$),
          filter(([regions, resourceGroups, selectedRegion]) =>
            !!regions?.length && !!resourceGroups?.length && !!selectedRegion),
          take(1)
        )
        .subscribe(([regions, resourceGroups, selectedRegion]) => {
          if(!value || !selectedRegion) {
            this.virtualNetworkOptions.data = [];
            this.storageAccountOptions.data = [];
            return;
          }
          const regionName = selectedRegion.data.protectionSource.name;
          switch (key) {
            case 'networkResourceGroup': {
              this.virtualNetworkOptions.data = this.extractVirtualNetworkData(regions, value, regionName);
              this.selectedNetworkResourceGroup = value;
              break;
            }
            case 'storageResourceGroup': {
              this.storageAccountOptions.data = this.extractStorageAccountData(resourceGroups, value, regionName);
              this.selectedStorageResourceGroup = value;
              break;
            }
          }
        });
    }
  }

  /**
   * Fetches the source registration details
   *
   * @param sourceRegId The source registration ID
   * @return The source registration details
   */
  @Memoize()
  private fetchSelectedSourceRegistrationDetail(sourceRegId: string) {
    return this.azureSaasConnectionService.fetchSourceRegistrationData(
      sourceRegId, this.passthroughOptionsService.regionId);
  }

  /**
   * Retrieves the selected source registration details
   *
   * @param sourceId The selected source ID
   * @return Observable of source with full registration and connection details
   */
  private getSourceRegistrationDetails(sourceId: number) {
    // Check local service data for available sources, if not available make api call
    return iif(
      () => !this.mcmSources?.length,
      this.mcmSourceService
        .McmGetProtectionSources({
          excludeProtectionStats: true,
          ...this.passthroughOptionsService.requestParams,
        })
        .pipe(
          map((response: McmSources) => {
            // Cache response to avoid multiple calls to api service on change of selected source
            this.mcmSources = response.sources?.length ? response.sources : [];
            return this.mcmSources;
          })
        ),
      of(this.mcmSources)
      ).pipe(
        map((sources) => {
        for (const source of sources) {
          const sourceInfo = source.sourceInfoList.find((s: McmSourceInfo) => Number(s.sourceId) === Number(sourceId) &&
              s.regionId === this.passthroughOptionsService.regionId);
          if (sourceInfo) {
            return sourceInfo.registrationId;
          }
        }
      }),
        switchMap(sourceRegId => this.fetchSelectedSourceRegistrationDetail(sourceRegId)));
  }


  /**
   * Look up protection source info when a source is selected.
   *
   * @param   sourceId   The source's id.
   */
  getSourceInfo(sourceId: number)  {
    this.loading.next(true);
    if (this.dmaasRecoveryEnabled) {
      this.subscriptions.next([]);
    }
    this.resourceGroups.next([]);
    forkJoin([
      iif(() => this.dmaasRecoveryEnabled, this.getSourceRegistrationDetails(sourceId), of(null)),
      this.sourceService
        .ListProtectionSources({
          id: sourceId,
          ...this.passthroughOptionsService.requestParams
        }).pipe(map(sources => sources[0]))
    ]).pipe(
        takeUntil(this.destroy$),
        finalize(() => this.loading.next(false))
      )
      .subscribe(([sourceRegistrationDetail, source]) => {
        // If in DMaaS context, change in source selected should update subscriptions control data.
        // Resource groups updated in an on-prem context as top level in onPrem EH is subscription
        if (this.dmaasRecoveryEnabled) {
          this.subscriptions.next(this.filterChildrenByType(source, 'kSubscription'));
        } else {
          this.resourceGroups.next(this.filterChildrenByType(source, 'kResourceGroup'));
          this.regions.next(this.filterChildrenByType(source, 'kRegion'));
        }
        this.selectedDestinationSource$.next(source);
        if(sourceRegistrationDetail) {
          this.selectedSourceRegistrationDetail$.next(sourceRegistrationDetail);
        }
      });
  }

  /**
   * Look up object's source info when an object is selected for recovery.
   *
   * @param objectIds Ids of recovery objects.
   * @param sourceId Id of object's parent source.
   */
  getObjectSourceInfo(objectIds: number[], sourceId: number) {
    combineLatest([
      this.sourceService.ListProtectionSources({
        id: sourceId,
        ...this.passthroughOptionsService.requestParams,
      }),
      // Getting object details to assert presence of managedVm objects (Applicable in DMaaS)
      iif(() => isDmsScope(this.ctxService.irisContext),
        this.getObjectProtectionDetails(objectIds),
        of(null)
      )
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([sources, objectDetails]) => {
        this.selectedObjectSource$.next(sources[0]);
        if(objectDetails) {
          this.selectedObjectDetails$.next(objectDetails.objects);
          this.selectedObjectsProtectionDetails$.next(objectDetails.objectProtectionDetails);
        }
      });
  }

  /**
   * Retrieves object details and the protection configuration details on selected objects
   *
   * @param objectIds The protected object IDs
   */
  private getObjectProtectionDetails(objectIds: number[]): Observable<ProtectedObjectsData> {
    return forkJoin(
      [
        this.sourceService.GetProtectionSourcesObjects({
          objectIds: objectIds,
          ...this.passthroughOptionsService.requestParams,
        }).pipe(catchError(() => of([]))),
        this.objectsService.GetProtectedObjectsOfAnyType({
          ids: objectIds,
          includeLastRunInfo: false,
          ...this.passthroughOptionsService.requestParams,
        }).pipe(
          map(response => response.objects),
          catchError(() => of([])))
      ]
    ).pipe(
      map(([objects, objectProtectionDetails]) => ({objects, objectProtectionDetails})));
  }

  /**
   * Filter the tree to a given hierarchy and remove all other objects.
   *
   * @param   nodes   The list of nodes to filter
   * @param   types   An array of types to filter by. This should be in the hierarchy order starting with
   *                  the top level node. Only trees that include the entire structure will be returned,
   *                  child nodes that are not in the hiearchy will not be included in the filtered tree.
   * @returns A filtered version of the tree. Nodes are copied so that the original values are not modified.
   */
  private filterTreeHierarchy(
    nodes: ProtectionSourceNode[], types: string[], location?: string): ProtectionSourceNode[] {

    if (!types || !types.length) {
      return null;
    }

    const filtered = (nodes || [])
      .filter(node =>
        types[0] === node.protectionSource.azureProtectionSource.type &&
        (!location || !node.protectionSource.azureProtectionSource.location ||
          node.protectionSource.azureProtectionSource.location === location))
      .map(node => ({
        ...node,
        nodes: node.nodes ? this.filterTreeHierarchy(node.nodes, types.slice(1), location) : undefined,
      }))
      .filter(node => types.length === 1 || (node.nodes && node.nodes.length));

    return filtered;
  }

  /**
   * Filter virtual networks by location.
   *
   * @param   nodes     The list of nodes to filter.
   * @param   location  Location of the virtual networks.
   * @returns A filtered version of the tree. Nodes are copied so that the original values are not modified.
   */
  private filterSubnets(
    nodes: ProtectionSourceNode[], location: string): ProtectionSourceNode[] {

    if (!location) {
      return null;
    }

    const filtered = (nodes || [])
      .filter(node =>
        node.protectionSource.azureProtectionSource.location === location ||
        node.protectionSource.azureProtectionSource.type !== 'kVirtualNetwork')
      .map(node => ({
        ...node,
        nodes: node.nodes ? this.filterSubnets(node.nodes, location) : undefined,
      }))
      .filter(node => node.protectionSource.azureProtectionSource.type === 'kSubnet' ||
        (node.nodes && node.nodes.length));

    return filtered;
  }

  /**
   * Filters the direct children of a node by type without working recursively through the entire tree.
   *
   * @param   node   The node to filter children for.
   * @param   type   The type to filter for.
   * @returns A list of node's children matching the type.
   */
  private filterChildrenByType(node: ProtectionSourceNode, type: string): ProtectionSourceNode[] {
    if (!node) {
      return [];
    }

    return (node.nodes || []).filter(
      (child: ProtectionSourceNode) => child.protectionSource.azureProtectionSource.type === type
    );
  }
}
