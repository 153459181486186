import { Component, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { BaseRendererComponent } from '@cohesity/helix';

/**
 * Available params that can be specified for difference data
 */
export interface DelimitedTextRendererParams {
  /**
   * If value is array instead of delimited text.
   */
  isArray?: boolean;

  /**
   * Delimiter character.
   */
  delimiter?: string;

  /**
   * Show summary if set and list length > 1.
   * Need to translate it because the summary text can be based on systems, sources,
   * or other data, e.g., '3 systems'.
   */
  translateSummary?: string;
}

/**
 * Renders a delimited text in a list.
 */
@Component({
  selector: 'iris-rpt-delimited-text-renderer',
  templateUrl: './delimited-text-renderer.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DelimitedTextRendererComponent extends
  BaseRendererComponent<string | string[], DelimitedTextRendererParams> {
  /**
   * Gets the list of text values.
   */
  get list(): string[] {
    if (this.renderParams?.isArray) {
      return this.value as string[];
    }
    return (this.value as string)
      .replace('{', '')
      .replace('}', '')
      .split(this.renderParams?.delimiter || ',');
  }

  /**
   * Gets the summary text of list.
   */
  getSummary(): string {
    const count = this.list?.length;

    if (this.translate) {
      return this.translate(this.renderParams?.translateSummary, { count });
    } else {
      return String(count);
    }
  }
}
