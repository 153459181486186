import { HostBinding } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation } from '@angular/core';
import { HelixIntlService } from '../../helix-intl.service';

/**
 * The expansion card sizes.
 */
export type Size = 'sm' | 'md';

/**
 * The expansion card header component.
 */
@Component({
  selector: 'cog-expansion-card-header',
  template: '<ng-content></ng-content>',
  encapsulation: ViewEncapsulation.None,
})
export class ExpansionCardHeaderComponent { }

/**
 * The expansion card icon component.
 */
@Component({
  selector: 'cog-expansion-card-icon',
  template: '<ng-content></ng-content>',
  encapsulation: ViewEncapsulation.None,
})
export class ExpansionCardIconComponent { }

/**
 * The expansion card content component.
 */
@Component({
  selector: 'cog-expansion-card-content',
  template: '<ng-content></ng-content>',
  encapsulation: ViewEncapsulation.None,
})
export class ExpansionCardContentComponent { }

/**
 * The expansion card component.
 */
@Component({
  selector: 'cog-expansion-card',
  templateUrl: './expansion-card.component.html',
  styleUrls: ['./expansion-card.component.scss'],
  exportAs: 'expansionCard',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpansionCardComponent {
  /**
   * Indicate whether the expansion card content is visible or not.
   */
  @Input() showContent = false;

  /**
   * Displays a toggle button so that the user can
   * toggle this component open or closed.
   */
  @Input() showToggle = false;

  /**
   * The icon for the toggle button changes
   * depending on whether this component is open or closed.
   */
  get toggleIcon() {
    return this.showContent ? 'helix:nav-expanded' : 'helix:nav-collapsed';
  }

  /**
   * Adds a class to the component so that the styles
   * can target whether it is open or closed.
   */
  @HostBinding('class.is-expanded')
  get isExpanded() {
    return this.showContent;
  }

  /**
   * The expansion card size.
   */
  @Input() size: Size = 'md';

  /**
   * The aria label for this changes depending
   * if it's open or closed.
   */
  get toggleLabel() {
    return this.showContent ? this.intl.close : this.intl.open;
  }

  constructor(private cdr: ChangeDetectorRef, readonly intl: HelixIntlService) {}

  /**
   * Toggle the visibility of expansion card content.
   */
  toggle() {
    this.showContent = !this.showContent;
    this.cdr.detectChanges();
  }
}
