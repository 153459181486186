import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogModule as MatDialogModule, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DataIdModule, FormsModule } from '@cohesity/helix';
import { AjaxHandlerService, ClearSubscriptions, DialogService } from '@cohesity/utils';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

/**
 * Defines a model to pass information to the dialog.
 */
export interface SimpleConfirmationDialogData<T> {
  /**
   * The title of the dialog.
   */
  title: string;

  /**
   * The message to show in the dialog.
   */
  message: string;

  /**
   * Submit button text
   */
  successButtonText?: string;

  /**
   * The action observable to trigger on confirmation.
   */
  action$: Observable<T>;
}

/**
 * Presents the users with a simple-confirmation dialog before performing an action on an object.
 */
@Component({
  selector: 'coh-simple-confirmation-dialog',
  templateUrl: './simple-confirmation-dialog.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, DataIdModule, FormsModule, MatButtonModule, MatDialogModule, TranslateModule],
})
export class SimpleConfirmationDialogComponent<T> extends ClearSubscriptions {
  /**
   * Indicates whether the form is being submitted.
   */
  isSubmitting$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: SimpleConfirmationDialogData<T>,
    private ajaxHandler: AjaxHandlerService,
    private dialogRef: MatDialogRef<SimpleConfirmationDialogComponent<T>>
  ) {
    super();
  }

  /**
   * Launch confirmation dialog box
   */
  static launchDialog<V = any, D = any>(dialog: DialogService, config: MatDialogConfig<V>) {
    return dialog.open<SimpleConfirmationDialogComponent<D>, D, V>(
      SimpleConfirmationDialogComponent,
      config
    );
  }

  /**
   * Handler for the 'validatedSubmit' event on the form.
   */
  onSubmit(): void {
    this.isSubmitting$.next(true);

    this.subscriptions.push(
      this.data.action$.pipe(finalize(() => this.isSubmitting$.next(false))).subscribe(
        (response) => this.dialogRef.close(response),
        (err) => this.ajaxHandler.errorMessage(err)
      )
    );
  }

  /**
   * Handler for the 'click' event on the cancel button.
   */
  onCancelClick(): void {
    this.dialogRef.close();
  }
}
