/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DcScan } from '../models/dc-scan';
import { DcScanActionParams } from '../models/dc-scan-action-params';
import { DcScanActionResponse } from '../models/dc-scan-action-response';
import { DcScanId } from '../models/dc-scan-id';
import { DcScanParams } from '../models/dc-scan-params';
import { DcScanRun } from '../models/dc-scan-run';
import { DcScanRunActionParams } from '../models/dc-scan-run-action-params';
import { DcScanRunId } from '../models/dc-scan-run-id';
import { DcScanSortColumn } from '../models/dc-scan-sort-column';
import { Environment } from '../models/environment';
import { GetAllDcScanRunsResponse } from '../models/get-all-dc-scan-runs-response';
import { GetDcScanRunSensitiveFilesParams } from '../models/get-dc-scan-run-sensitive-files-params';
import { GetDcScanRunSensitiveFilesResponse } from '../models/get-dc-scan-run-sensitive-files-response';
import { GetDcScanRunSensitivityResultsParams } from '../models/get-dc-scan-run-sensitivity-results-params';
import { GetDcScanRunSensitivityResultsResponse } from '../models/get-dc-scan-run-sensitivity-results-response';
import { GetDcScanRunsResponse } from '../models/get-dc-scan-runs-response';
import { GetDcScansResponse } from '../models/get-dc-scans-response';
import { GetScannedObjectsResponse } from '../models/get-scanned-objects-response';
import { ObjectId } from '../models/object-id';
import { ObjectType } from '../models/object-type';
import { PaginationToken } from '../models/pagination-token';
import { ScanHealthStatus } from '../models/scan-health-status';
import { ScanTrigger } from '../models/scan-trigger';
import { SnapshotId } from '../models/snapshot-id';
import { SortOrder } from '../models/sort-order';
import { TimestampMsecs } from '../models/timestamp-msecs';

@Injectable({
  providedIn: 'root',
})
export class DcScansApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getDcScans
   */
  static readonly GetDcScansPath = '/dlp/scans';

  /**
   * Get a list of Data Classification (DC) scans.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDcScans()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDcScans$Response(params?: {

    /**
     * Specifies the filter by scan ids.
     */
    ids?: Array<DcScanId>;

    /**
     * Specifies the filter by type of the scan run statuses.
     */
    statuses?: Array<ScanHealthStatus>;

    /**
     * Specifies the filter by object ids.
     */
    objectIds?: Array<ObjectId>;

    /**
     * Specifies the filter by snapshot ids.
     */
    snapshotIds?: Array<SnapshotId>;

    /**
     * Specifies the filter by scan start time in milli seconds.
     */
    startTimeMsecs?: TimestampMsecs;

    /**
     * Specifies the filter by scan end time in milli seconds.
     */
    endTimeMsecs?: TimestampMsecs;

    /**
     * Specifies filter by environment types of objects included in scan.
     */
    environments?: Array<Environment>;

    /**
     * Specifies the filter by how scan was triggered (manually or ransomware alert triggered).
     */
    scanTriggers?: Array<ScanTrigger>;

    /**
     * Specifies filter by objectTypes of objects included in scan.
     */
    objectTypes?: Array<ObjectType>;

    /**
     * Specifies the search substring string of scan name.
     */
    scanSearchTerm?: string;

    /**
     * Specifies the scan column sorting field.
     */
    sortField?: DcScanSortColumn;

    /**
     * Specifies the scan column sorting order.
     */
    sortOrder?: SortOrder;

    /**
     * Specifies the maximum number of scans per response and it should be less than 100.
     */
    pageSize?: null | number;

    /**
     * Specifies the pagination token used to get the next page.
     */
    paginationToken?: PaginationToken;

  }): Observable<StrictHttpResponse<GetDcScansResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DcScansApiService.GetDcScansPath, 'get');
    if (params) {

      rb.query('ids', params.ids, {});
      rb.query('statuses', params.statuses, {});
      rb.query('objectIds', params.objectIds, {});
      rb.query('snapshotIds', params.snapshotIds, {});
      rb.query('startTimeMsecs', params.startTimeMsecs, {});
      rb.query('endTimeMsecs', params.endTimeMsecs, {});
      rb.query('environments', params.environments, {});
      rb.query('scanTriggers', params.scanTriggers, {});
      rb.query('objectTypes', params.objectTypes, {});
      rb.query('scanSearchTerm', params.scanSearchTerm, {});
      rb.query('sortField', params.sortField, {});
      rb.query('sortOrder', params.sortOrder, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('paginationToken', params.paginationToken, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetDcScansResponse>;
      })
    );
  }

  /**
   * Get a list of Data Classification (DC) scans.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDcScans$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDcScans(params?: {

    /**
     * Specifies the filter by scan ids.
     */
    ids?: Array<DcScanId>;

    /**
     * Specifies the filter by type of the scan run statuses.
     */
    statuses?: Array<ScanHealthStatus>;

    /**
     * Specifies the filter by object ids.
     */
    objectIds?: Array<ObjectId>;

    /**
     * Specifies the filter by snapshot ids.
     */
    snapshotIds?: Array<SnapshotId>;

    /**
     * Specifies the filter by scan start time in milli seconds.
     */
    startTimeMsecs?: TimestampMsecs;

    /**
     * Specifies the filter by scan end time in milli seconds.
     */
    endTimeMsecs?: TimestampMsecs;

    /**
     * Specifies filter by environment types of objects included in scan.
     */
    environments?: Array<Environment>;

    /**
     * Specifies the filter by how scan was triggered (manually or ransomware alert triggered).
     */
    scanTriggers?: Array<ScanTrigger>;

    /**
     * Specifies filter by objectTypes of objects included in scan.
     */
    objectTypes?: Array<ObjectType>;

    /**
     * Specifies the search substring string of scan name.
     */
    scanSearchTerm?: string;

    /**
     * Specifies the scan column sorting field.
     */
    sortField?: DcScanSortColumn;

    /**
     * Specifies the scan column sorting order.
     */
    sortOrder?: SortOrder;

    /**
     * Specifies the maximum number of scans per response and it should be less than 100.
     */
    pageSize?: null | number;

    /**
     * Specifies the pagination token used to get the next page.
     */
    paginationToken?: PaginationToken;

  }): Observable<GetDcScansResponse> {

    return this.getDcScans$Response(params).pipe(
      map((r: StrictHttpResponse<GetDcScansResponse>) => r.body as GetDcScansResponse)
    );
  }

  /**
   * Path part for operation createDcScan
   */
  static readonly CreateDcScanPath = '/dlp/scans';

  /**
   * Create a new DC scan.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createDcScan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDcScan$Response(params: {
      body: DcScanParams
  }): Observable<StrictHttpResponse<DcScan>> {

    const rb = new RequestBuilder(this.rootUrl, DcScansApiService.CreateDcScanPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DcScan>;
      })
    );
  }

  /**
   * Create a new DC scan.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createDcScan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDcScan(params: {
      body: DcScanParams
  }): Observable<DcScan> {

    return this.createDcScan$Response(params).pipe(
      map((r: StrictHttpResponse<DcScan>) => r.body as DcScan)
    );
  }

  /**
   * Path part for operation performActionOnDcScan
   */
  static readonly PerformActionOnDcScanPath = '/dlp/scans/actions';

  /**
   * Perform Actions on DC scan.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `performActionOnDcScan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  performActionOnDcScan$Response(params: {
      body: DcScanActionParams
  }): Observable<StrictHttpResponse<DcScanActionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DcScansApiService.PerformActionOnDcScanPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DcScanActionResponse>;
      })
    );
  }

  /**
   * Perform Actions on DC scan.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `performActionOnDcScan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  performActionOnDcScan(params: {
      body: DcScanActionParams
  }): Observable<DcScanActionResponse> {

    return this.performActionOnDcScan$Response(params).pipe(
      map((r: StrictHttpResponse<DcScanActionResponse>) => r.body as DcScanActionResponse)
    );
  }

  /**
   * Path part for operation getDcScanById
   */
  static readonly GetDcScanByIdPath = '/dlp/scans/{id}';

  /**
   * Get DC scan by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDcScanById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDcScanById$Response(params: {

    /**
     * The Data Classification (DC) scan id.
     */
    id: DcScanId;

  }): Observable<StrictHttpResponse<DcScan>> {

    const rb = new RequestBuilder(this.rootUrl, DcScansApiService.GetDcScanByIdPath, 'get');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DcScan>;
      })
    );
  }

  /**
   * Get DC scan by ID.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDcScanById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDcScanById(params: {

    /**
     * The Data Classification (DC) scan id.
     */
    id: DcScanId;

  }): Observable<DcScan> {

    return this.getDcScanById$Response(params).pipe(
      map((r: StrictHttpResponse<DcScan>) => r.body as DcScan)
    );
  }

  /**
   * Path part for operation updateDcScan
   */
  static readonly UpdateDcScanPath = '/dlp/scans/{id}';

  /**
   * Update DC scan.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDcScan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDcScan$Response(params: {

    /**
     * The Data Classification (DC) scan id.
     */
    id: DcScanId;
      body: DcScanParams
  }): Observable<StrictHttpResponse<DcScan>> {

    const rb = new RequestBuilder(this.rootUrl, DcScansApiService.UpdateDcScanPath, 'put');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DcScan>;
      })
    );
  }

  /**
   * Update DC scan.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateDcScan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDcScan(params: {

    /**
     * The Data Classification (DC) scan id.
     */
    id: DcScanId;
      body: DcScanParams
  }): Observable<DcScan> {

    return this.updateDcScan$Response(params).pipe(
      map((r: StrictHttpResponse<DcScan>) => r.body as DcScan)
    );
  }

  /**
   * Path part for operation deleteDcScan
   */
  static readonly DeleteDcScanPath = '/dlp/scans/{id}';

  /**
   * Delete DC scan.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDcScan()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDcScan$Response(params: {

    /**
     * The Data Classification (DC) scan id.
     */
    id: DcScanId;

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DcScansApiService.DeleteDcScanPath, 'delete');
    if (params) {

      rb.path('id', params.id, {});

    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete DC scan.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteDcScan$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDcScan(params: {

    /**
     * The Data Classification (DC) scan id.
     */
    id: DcScanId;

  }): Observable<void> {

    return this.deleteDcScan$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation performActionOnDcScanRuns
   */
  static readonly PerformActionOnDcScanRunsPath = '/dlp/scans/{id}/actions';

  /**
   * Perform Actions on DC scan runs.
   *
   * Privileges: DGAAS_MODIFY
   *
   * Perform Actions on DC scan runs.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `performActionOnDcScanRuns()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  performActionOnDcScanRuns$Response(params: {

    /**
     * The Data Classification (DC) scan id.
     */
    id: DcScanId;
      body: DcScanRunActionParams
  }): Observable<StrictHttpResponse<DcScanActionResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DcScansApiService.PerformActionOnDcScanRunsPath, 'post');
    if (params) {

      rb.path('id', params.id, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DcScanActionResponse>;
      })
    );
  }

  /**
   * Perform Actions on DC scan runs.
   *
   * Privileges: DGAAS_MODIFY
   *
   * Perform Actions on DC scan runs.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `performActionOnDcScanRuns$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  performActionOnDcScanRuns(params: {

    /**
     * The Data Classification (DC) scan id.
     */
    id: DcScanId;
      body: DcScanRunActionParams
  }): Observable<DcScanActionResponse> {

    return this.performActionOnDcScanRuns$Response(params).pipe(
      map((r: StrictHttpResponse<DcScanActionResponse>) => r.body as DcScanActionResponse)
    );
  }

  /**
   * Path part for operation getDcScanRuns
   */
  static readonly GetDcScanRunsPath = '/dlp/scans/{id}/runs';

  /**
   * Get the list of runs for a Data Classification (DC) scan.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDcScanRuns()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDcScanRuns$Response(params: {

    /**
     * The Data Classification (DC) scan id.
     */
    id: DcScanId;

    /**
     * Specifies the filter by DC scan start time in milli seconds.
     */
    startTimeMsecs?: TimestampMsecs;

    /**
     * Specifies the filter by DC scan end time in milli seconds.
     */
    endTimeMsecs?: TimestampMsecs;

    /**
     * Specifies the maximum number of DC scan runs per response.
     */
    pageSize?: null | number;

    /**
     * Specifies the pagination token used to get the next page.
     */
    paginationToken?: PaginationToken;

  }): Observable<StrictHttpResponse<GetDcScanRunsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DcScansApiService.GetDcScanRunsPath, 'get');
    if (params) {

      rb.path('id', params.id, {});
      rb.query('startTimeMsecs', params.startTimeMsecs, {});
      rb.query('endTimeMsecs', params.endTimeMsecs, {});
      rb.query('pageSize', params.pageSize, {});
      rb.query('paginationToken', params.paginationToken, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetDcScanRunsResponse>;
      })
    );
  }

  /**
   * Get the list of runs for a Data Classification (DC) scan.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDcScanRuns$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDcScanRuns(params: {

    /**
     * The Data Classification (DC) scan id.
     */
    id: DcScanId;

    /**
     * Specifies the filter by DC scan start time in milli seconds.
     */
    startTimeMsecs?: TimestampMsecs;

    /**
     * Specifies the filter by DC scan end time in milli seconds.
     */
    endTimeMsecs?: TimestampMsecs;

    /**
     * Specifies the maximum number of DC scan runs per response.
     */
    pageSize?: null | number;

    /**
     * Specifies the pagination token used to get the next page.
     */
    paginationToken?: PaginationToken;

  }): Observable<GetDcScanRunsResponse> {

    return this.getDcScanRuns$Response(params).pipe(
      map((r: StrictHttpResponse<GetDcScanRunsResponse>) => r.body as GetDcScanRunsResponse)
    );
  }

  /**
   * Path part for operation getDcScanRunByRunId
   */
  static readonly GetDcScanRunByRunIdPath = '/dlp/scans/{id}/runs/{runId}';

  /**
   * Get a scan run.
   *
   * Privileges: DGAAS_VIEW
   *
   * Returns a Data Classification (DC) scan run by runId for the given scan Id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDcScanRunByRunId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDcScanRunByRunId$Response(params: {

    /**
     * The Data Classification (DC) scan id.
     */
    id: DcScanId;

    /**
     * Specifies the scan run id.
     */
    runId: DcScanRunId;

  }): Observable<StrictHttpResponse<DcScanRun>> {

    const rb = new RequestBuilder(this.rootUrl, DcScansApiService.GetDcScanRunByRunIdPath, 'get');
    if (params) {

      rb.path('id', params.id, {});
      rb.path('runId', params.runId, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DcScanRun>;
      })
    );
  }

  /**
   * Get a scan run.
   *
   * Privileges: DGAAS_VIEW
   *
   * Returns a Data Classification (DC) scan run by runId for the given scan Id.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDcScanRunByRunId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDcScanRunByRunId(params: {

    /**
     * The Data Classification (DC) scan id.
     */
    id: DcScanId;

    /**
     * Specifies the scan run id.
     */
    runId: DcScanRunId;

  }): Observable<DcScanRun> {

    return this.getDcScanRunByRunId$Response(params).pipe(
      map((r: StrictHttpResponse<DcScanRun>) => r.body as DcScanRun)
    );
  }

  /**
   * Path part for operation getAllDcScansRuns
   */
  static readonly GetAllDcScansRunsPath = '/dlp/scans/runs';

  /**
   * Get the list of Data Classification (DC) scan runs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllDcScansRuns()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDcScansRuns$Response(params?: {

    /**
     * Specifies the filter by DC scan start time in milli seconds.
     */
    startTimeMsecs?: TimestampMsecs;

    /**
     * Specifies the filter by DC scan end time in milli seconds.
     */
    endTimeMsecs?: TimestampMsecs;

    /**
     * Specifies from which source scan is triggered. If not specified, scan runs from all the sources will be listed.
     */
    scanTrigger?: ScanTrigger;

  }): Observable<StrictHttpResponse<GetAllDcScanRunsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DcScansApiService.GetAllDcScansRunsPath, 'get');
    if (params) {

      rb.query('startTimeMsecs', params.startTimeMsecs, {});
      rb.query('endTimeMsecs', params.endTimeMsecs, {});
      rb.query('scanTrigger', params.scanTrigger, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetAllDcScanRunsResponse>;
      })
    );
  }

  /**
   * Get the list of Data Classification (DC) scan runs.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllDcScansRuns$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDcScansRuns(params?: {

    /**
     * Specifies the filter by DC scan start time in milli seconds.
     */
    startTimeMsecs?: TimestampMsecs;

    /**
     * Specifies the filter by DC scan end time in milli seconds.
     */
    endTimeMsecs?: TimestampMsecs;

    /**
     * Specifies from which source scan is triggered. If not specified, scan runs from all the sources will be listed.
     */
    scanTrigger?: ScanTrigger;

  }): Observable<GetAllDcScanRunsResponse> {

    return this.getAllDcScansRuns$Response(params).pipe(
      map((r: StrictHttpResponse<GetAllDcScanRunsResponse>) => r.body as GetAllDcScanRunsResponse)
    );
  }

  /**
   * Path part for operation getDcScanRunSensitivitiyResults
   */
  static readonly GetDcScanRunSensitivitiyResultsPath = '/dlp/sensitivity-results';

  /**
   * Get sensitivity results for Data Classification (DC) scan run.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDcScanRunSensitivitiyResults()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDcScanRunSensitivitiyResults$Response(params: {
      body: GetDcScanRunSensitivityResultsParams
  }): Observable<StrictHttpResponse<GetDcScanRunSensitivityResultsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DcScansApiService.GetDcScanRunSensitivitiyResultsPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetDcScanRunSensitivityResultsResponse>;
      })
    );
  }

  /**
   * Get sensitivity results for Data Classification (DC) scan run.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDcScanRunSensitivitiyResults$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDcScanRunSensitivitiyResults(params: {
      body: GetDcScanRunSensitivityResultsParams
  }): Observable<GetDcScanRunSensitivityResultsResponse> {

    return this.getDcScanRunSensitivitiyResults$Response(params).pipe(
      map((r: StrictHttpResponse<GetDcScanRunSensitivityResultsResponse>) => r.body as GetDcScanRunSensitivityResultsResponse)
    );
  }

  /**
   * Path part for operation downloadDcScanRunSensitiveFiles
   */
  static readonly DownloadDcScanRunSensitiveFilesPath = '/dlp/sensitive-files';

  /**
   * Download sensitive files for Data Classification (DC) scan run.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadDcScanRunSensitiveFiles$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadDcScanRunSensitiveFiles$Json$Response(params: {

    /**
     * Specifies the params for download sensitive files for Data Classification (DC) scan run.
     */
    params: GetDcScanRunSensitiveFilesParams;

  }): Observable<StrictHttpResponse<GetDcScanRunSensitiveFilesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DcScansApiService.DownloadDcScanRunSensitiveFilesPath, 'get');
    if (params) {

      rb.query('params', params.params, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetDcScanRunSensitiveFilesResponse>;
      })
    );
  }

  /**
   * Download sensitive files for Data Classification (DC) scan run.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadDcScanRunSensitiveFiles$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadDcScanRunSensitiveFiles$Json(params: {

    /**
     * Specifies the params for download sensitive files for Data Classification (DC) scan run.
     */
    params: GetDcScanRunSensitiveFilesParams;

  }): Observable<GetDcScanRunSensitiveFilesResponse> {

    return this.downloadDcScanRunSensitiveFiles$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetDcScanRunSensitiveFilesResponse>) => r.body as GetDcScanRunSensitiveFilesResponse)
    );
  }

  /**
   * Download sensitive files for Data Classification (DC) scan run.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadDcScanRunSensitiveFiles$Csv()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadDcScanRunSensitiveFiles$Csv$Response(params: {

    /**
     * Specifies the params for download sensitive files for Data Classification (DC) scan run.
     */
    params: GetDcScanRunSensitiveFilesParams;

  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, DcScansApiService.DownloadDcScanRunSensitiveFilesPath, 'get');
    if (params) {

      rb.query('params', params.params, {});

    }
    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/csv'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Download sensitive files for Data Classification (DC) scan run.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadDcScanRunSensitiveFiles$Csv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadDcScanRunSensitiveFiles$Csv(params: {

    /**
     * Specifies the params for download sensitive files for Data Classification (DC) scan run.
     */
    params: GetDcScanRunSensitiveFilesParams;

  }): Observable<string> {

    return this.downloadDcScanRunSensitiveFiles$Csv$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getDcScanRunSensitiveFiles
   */
  static readonly GetDcScanRunSensitiveFilesPath = '/dlp/sensitive-files';

  /**
   * Get sensitive files for Data Classification (DC) scan run.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDcScanRunSensitiveFiles$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDcScanRunSensitiveFiles$Json$Response(params: {
      body: GetDcScanRunSensitiveFilesParams
  }): Observable<StrictHttpResponse<GetDcScanRunSensitiveFilesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DcScansApiService.GetDcScanRunSensitiveFilesPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetDcScanRunSensitiveFilesResponse>;
      })
    );
  }

  /**
   * Get sensitive files for Data Classification (DC) scan run.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDcScanRunSensitiveFiles$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDcScanRunSensitiveFiles$Json(params: {
      body: GetDcScanRunSensitiveFilesParams
  }): Observable<GetDcScanRunSensitiveFilesResponse> {

    return this.getDcScanRunSensitiveFiles$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetDcScanRunSensitiveFilesResponse>) => r.body as GetDcScanRunSensitiveFilesResponse)
    );
  }

  /**
   * Get sensitive files for Data Classification (DC) scan run.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDcScanRunSensitiveFiles$Csv()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDcScanRunSensitiveFiles$Csv$Response(params: {
      body: GetDcScanRunSensitiveFilesParams
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, DcScansApiService.GetDcScanRunSensitiveFilesPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/csv'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Get sensitive files for Data Classification (DC) scan run.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDcScanRunSensitiveFiles$Csv$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDcScanRunSensitiveFiles$Csv(params: {
      body: GetDcScanRunSensitiveFilesParams
  }): Observable<string> {

    return this.getDcScanRunSensitiveFiles$Csv$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getDataClassificationScanObjects
   */
  static readonly GetDataClassificationScanObjectsPath = '/dlp/scanned-objects';

  /**
   * Get a list of objects for which DC scans were triggered.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDataClassificationScanObjects()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDataClassificationScanObjects$Response(params?: {

  }): Observable<StrictHttpResponse<GetScannedObjectsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, DcScansApiService.GetDataClassificationScanObjectsPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetScannedObjectsResponse>;
      })
    );
  }

  /**
   * Get a list of objects for which DC scans were triggered.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDataClassificationScanObjects$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDataClassificationScanObjects(params?: {

  }): Observable<GetScannedObjectsResponse> {

    return this.getDataClassificationScanObjects$Response(params).pipe(
      map((r: StrictHttpResponse<GetScannedObjectsResponse>) => r.body as GetScannedObjectsResponse)
    );
  }

}
