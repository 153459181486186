// Group Management Service

;(function(angular) {
  'use strict';

  angular
    .module('C')
    .service('GroupService', GroupServiceFn);

  function GroupServiceFn($q, $http, API, GroupServiceFormatter, TenantService,
    cUtils) {
    var groupService = {
      createGroup: createGroup,
      getGroup: getGroup,
      getGroups: getGroups,
      updateGroup: updateGroup,
      deleteGroups: deleteGroups,
    };
    var endpoint = API.public('groups');

    ////////////////////////////////////////
    // PUBLIC METHODS
    ////////////////////////////////////////

    /**
     * Create a new Group
     *
     * @method     createGroup
     * @param      {Object}   newGroup  to be created
     * @return     {Promise}  Q promise carrying the server's response
     */
    function createGroup(newGroup) {
      return $http({
        method: 'post',
        url: endpoint,
        data: newGroup,
      }).then(function transformCreateGroupResponse(response) {
        return GroupServiceFormatter.transformGroup(response.data);
      });
    }

    /**
     * Get the group.
     *
     * @method     getGroup
     * @param      {Object}   params to constrain query
     * @return     {Promise}  Q promise carrying the server's response
     */
    function getGroup(params) {
      params = params || {};

      // Set allUnderHierarchy true when params.name is specified.
      cUtils.selfOrDefault(params, 'allUnderHierarchy', !!params.name);

      return $http({
        method: 'get',
        url: endpoint,
        params: params,
      }).then(
        function transformGetGroupResponse(response) {
          // Find the exact match because the API returns partial matches.
          var group = (response.data || []).find(function findGroup(item) {
            return item.name === params.name;
          });

          if (!group) {
            return $q.reject();
          }

          return GroupServiceFormatter.transformGroup(group);
        }
      );
    }

    /**
     * Get groups.
     *
     * @method     getGroups
     * @return     {Promise}  Q promise carrying the server's response
     */
    function getGroups(params) {
      params = params|| {};

      return $http({
        method: 'get',
        url: endpoint,
        params: params,
      }).then(function transformCreateGroupResponse(response) {
        var transformedGroups = _.map((response.data || []),
          GroupServiceFormatter.transformGroup);

        if (params._includeTenantInfo) {
          return TenantService.resolveTenantDetails(transformedGroups,
            'tenantIds');
        }
        return transformedGroups;
      });
    }

    /**
     * update a group's information
     *
     * @method     updateGroup
     * @param      {Object}   group  updated group information
     * @return     {Promise}  Q promise carrying the server's response
     */
    function updateGroup(group) {
      return $http({
        method: 'put',
        url: endpoint,
        data: group,
      }).then(function transformEditGroupResponse(response) {
        return GroupServiceFormatter.transformGroup(response.data);
      });
    }

    /**
     * delete a list of groups
     *
     * @method     deleteGroups
     * @param      {Object}   params  hash of group objects to be deleted
     * @return     {Promise}  Q promise carrying the server's response
     */
    function deleteGroups(params) {
      return $http({
        method: 'delete',
        url: endpoint,
        data: params,
      });
    }

    return groupService;
  }

})(angular);
