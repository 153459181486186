<cog-value-property-filter *cogFilterDef="let filter;
    let params = params;
    quickFilter true;
    key: property;
    label: 'cluster' | translate;
    filterType: 'valueProperty';
    position: position"
  [class.hidden]="hideFilter || !hasClusters"
  [filterDefParams]="params"
  [filter]="filter"
  [allowMultiple]="multiSelect"
  [autoApply]="!multiSelect"
  [filterValues]="filterOptions$ | async">
</cog-value-property-filter>
