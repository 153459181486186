import { Resource } from '@cohesity/api/guardian';
import { ResourceNode } from '../models';

/**
 * Transforms a list of resources into a tree structure.
 *
 * @param dataset
 * @returns tree structure
 */
export const transformArrayToTree = (array: Resource[]): ResourceNode[] => {
  const resourcesById: Record<string, ResourceNode> = {};
  const dataTree = [];

  array.forEach(item => {
    resourcesById[item.id] = structuredClone(item);
  });

  array.forEach(item => {
    if (item.parentId) {
      if (!resourcesById[item.parentId].children) {
        resourcesById[item.parentId].children = [];
      }
      resourcesById[item.parentId].children.push(resourcesById[item.id]);
    } else {
      dataTree.push(resourcesById[item.id]);
    }
  });
  return dataTree;
};
