import { Injectable } from '@angular/core';
import { DmaasRegion, DmsServiceApi, GetTenantRegionsResponse } from '@cohesity/api/dms';
import { Site, SitesResponse, SitesServiceApi } from '@cohesity/api/kepler';
import { McmClusterInfo, McmClusterServiceApi } from '@cohesity/api/private';
import { draasTenantId, IrisContextService } from '@cohesity/iris-core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { minSupportedClusterVersion } from '../constants';
import { dataSiteDetails, dataSiteReadinessHistory } from '../testing/mocks';

/**
 * Interface for site summary stat.
 */
export interface SiteSummary {
  /**
   * Specifies the total number of sites.
   */
  numSites?: number;
  /**
   * Specifies the total number of clusters across all sites.
   */
  numClusters?: number;
}

/**
 * Interface for SiteResponse decorated with summary info.
 */
export interface DecoratedSitesResponse extends SitesResponse {
  summary: SiteSummary;
}

/**
 * Data Sites service for getting data sites info.
 */
@Injectable()
export class DataSitesService {
  constructor(
    private sitesService: SitesServiceApi,
    private dmsApi: DmsServiceApi,
    private mcmClustersApi: McmClusterServiceApi,
    private irisCtx: IrisContextService
  ) {}

  /**
   * Gets the list of sites.
   *
   * @returns An observable for sites response with summary stats.
   */
  getSites(): Observable<DecoratedSitesResponse> {
    return this.sitesService.GetSites({}).pipe(
      map(resp => resp?.sites ?? []),
      map(sites => {
        // Gets total number of clusters across all sites.
        const numClusters = sites
          .filter(site => site.type === 'OnPrem')
          .reduce((total, site) => total + site.clusters?.length ?? 0, 0);

        return { sites, summary: { numSites: sites.length ?? 0, numClusters } };
      })
    );
  }

  /**
   * Gets site details.
   * TODO: wire up API when available.
   */
  getSiteDetails() {
    return of(dataSiteDetails);
  }

  /**
   * Gets site readiness.
   * TODO: wire up API when available.
   */
  getSiteReadiness() {
    return of({ readiness: 76 });
  }

  /**
   * Gets site history time series data.
   * TODO: wire up API when available.
   */
  getSiteHistory() {
    return of(dataSiteReadinessHistory).pipe(
      map(metric => {
        const { dataPointVec = [] } = metric;
        return dataPointVec.map(dataPoint => ({
          x: dataPoint.timestampMsecs,
          y: dataPoint.data.int64Value,
        }));
      })
    );
  }

  /**
   * Updates site by id.
   *
   * @param    site    Site info to update.
   * @returns  An observable of updated site info.
   */
  updateSite(params: SitesServiceApi.UpdateSiteByIdParams): Observable<Site> {
    return this.sitesService.UpdateSiteById(params);
  }

  /**
   * Returns the currently provisioned regions for a tenant user.
   *
   * @returns An observable of the provisioned DMaaS region objects.
   */
  getProvisionedRegions(): Observable<DmaasRegion[]> {
    return forkJoin([
      this.dmsApi.GetRegions('AWS'),
      this.dmsApi.GetTenantRegions({ tenantId: draasTenantId(this.irisCtx.irisContext) }),
    ]).pipe(
      map(([regionsResp, tenantRegionsResp]) => {
        const regions = regionsResp?.regions ?? [];
        const tenantRegions = (tenantRegionsResp as GetTenantRegionsResponse)?.tenantRegionInfoList ?? [];

        // We're interested in only the provisioned regions.
        const provisionedRegions = regions.filter(region =>
          tenantRegions.find(
            tenantRegion => tenantRegion.regionId === region.id && tenantRegion.provisionStatus?.status === 'Completed'
          )
        );

        return provisionedRegions;
      })
    );
  }

  /**
   * Returns a list of clusters which are eligible for site creation.
   *
   * @returns An observable of the eligible clusters.
   */
  getClustersForSiteCreation(): Observable<McmClusterInfo[]> {
    return forkJoin([
      this.sitesService.GetSites({ types: ['OnPrem'] }),
      this.mcmClustersApi.getUpgradeInfo({ operator: 'Gte', softwareVersion: minSupportedClusterVersion }),
    ]).pipe(
      map(([sitesResponse, clusterUpgradeInfoResponse]) => {
        const usedClusterIds = new Set<number>(
          (sitesResponse?.sites ?? []).flatMap(site => site?.clusters?.map(cluster => cluster.id) ?? [])
        );

        return (clusterUpgradeInfoResponse?.upgradeInfo ?? []).filter(
          clusterInfo => !usedClusterIds.has(clusterInfo.clusterId)
        );
      })
    );
  }
}
