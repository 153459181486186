import { NavItem } from '@cohesity/helix';
import { TimePeriodOptions } from '@cohesity/shared-forms';
import { GranularityInverse, TimePeriodValue } from '@cohesity/utils';
import { MFAType } from '../login/mfa';
import { ClusterUpgradeInfo } from './models/decorated-cluster-upgrade-info';
import { ClusterActionId, ClusterHealth, ClusterListStat, ClusterUpgradeStatus } from './models/misc.model';

/**
 * List of columns to be shown in cluster listing table in this
 * order from left to right.
 */
export const SoftwareUpgradeColDefs = {
  default: [
    {
      key: '_name',
      titleKey: 'cluster',
    },
    {
      key: 'clusterHealth',
      titleKey: 'health',
    },
    {
      key: 'connection',
      titleKey: 'connection',
    },
    {
      key: '_softwareVersion',
      titleKey: 'softwareVersion',
    },
    {
      key: 'diskUsage',
      titleKey: 'usage',
    },
    {
      key: 'diskCapacity',
      titleKey: 'capacity',
    },
    {
      key: 'lastRunUsecs',
      titleKey: 'lastRun',
    },
    {
      key: 'action',
      titleKey: 'action',
    },
  ],
  smartFiles: [
    {
      key: '_name',
      titleKey: 'cluster',
    },
    {
      key: 'numOfViews',
      titleKey: 'views',
    },
    {
      key: 'clusterLogicalOfAllViews',
      titleKey: 'logicalData',
    },
    {
      key: 'growthRate',
      titleKey: '30DayGrowth',
    },
    {
      key: 'dataReduction',
      titleKey: 'dataReduction',
    },
    {
      key: 'clusterHealth',
      titleKey: 'health',
    },
    {
      key: 'connection',
      titleKey: 'connection',
    },
    {
      key: 'lastRunUsecs',
      titleKey: 'lastRun',
    },
    {
      key: 'action',
      titleKey: 'action',
    },
  ],
};

/**
 * all the actions available in context of a single
 * cluster row
 */
export const ClusterContextMenuItems: NavItem[] = [
  {
    id: ClusterActionId.updateLocation,
    displayName: 'updateLocation',
  },
  {
    id: ClusterActionId.upgradeNow,
    displayName: 'upgradeNow',
  },
  {
    id: ClusterActionId.scheduleUpgrade,
    displayName: 'scheduleUpgrade',
  },
  {
    id: ClusterActionId.editScheduledUpgrade,
    displayName: 'editScheduledUpgrade',
  },
  {
    id: ClusterActionId.deleteScheduledUpgrade,
    displayName: 'deleteScheduledUpgrade',
  },
  {
    id: ClusterActionId.unregister,
    displayName: 'unregister',
  },
  {
    id: ClusterActionId.viewLog,
    displayName: 'viewLog',
  },
  {
    id: ClusterActionId.export,
    displayName: 'backUpConfiguration',
  },
  {
    id: ClusterActionId.import,
    displayName: 'restoreConfiguration',
  },
];

/**
 * A mapping of cluster health vs corresponding icon to be shown.
 */
export const healthIconMap: Record<ClusterHealth, string> = {
  'Critical': 'helix:status-error-alt!critical',
  'NonCritical': 'helix:status-success-alt!success',
  'Warning': 'helix:status-warning-alt!warning'
};

/**
 * A mapping of cluster upgrade status vs corresponding icon to be shown
 */
export const upgradeStatusIconMap: Record<ClusterUpgradeStatus, string> = {
  'UpgradeAvailable': 'helix:status-info-alt!info',
  'Failed': 'helix:status-error-alt!critical',
  'InProgress': 'helix:status-in-progress-alt!info',
  'Scheduled': 'helix:cluster-scheduled!info',
  'UpToDate': 'helix:status-success-alt!success',
  'ClusterUnreachable': 'helix:status-in-progress-alt!info',
};

/**
 * list of filter supported with urls. The values in the list
 * are "filter keys" which are associated with filter components
 */
export const URL_SUPPORTED_FILTERS = [
  'upgradeStatus',
  'currentVersion',
  'clusterHealth',
  'connected',
  'backupStatus',
  'hasViews',
] as const;

/**
 * Warning threshold for cluster usage in percentage.
 */
export const CLUSTER_USAGE_WARN_THRESHOLD = 80;

/**
 * This method servers as a hash key generator function
 * for a given cluster information data. This is majorly
 * utilised in cluster listing table for memoizing computations
 * that wont likely change during the page visit for a single row.
 *
 * @param info Cluster upgrade info as input data
 * @returns key for hashing cluster info
 */
export const CLUSTER_HASH_FN = (info: ClusterUpgradeInfo): string => `
  ${info?.clusterId}_${info?.status}
`;

/**
 * Minimum length of query required for calling `/mcm/places` API.
 */
export const MIN_LOCATION_SEARCH_LEN = 3;

/**
 * Offset from current time for minimum schedule time
 * for cluster upgrade.
 *
 * This value will be added to current time and that will
 * be set as minimum time that user can select to schedule upgrade.
 */
export const SCHEDULE_TIME_OFFSET_IN_MINS = 15;

/**
 * Show tooltip for scheduled clusters within this time window.
 * Depicted using [start, end] relative to a timestamp in MINs.
 */
export const SCHEDULED_CLUSTER_TOOLTIP_WINDOW_IN_MINS: [number, number] = [0, 15];

/**
 * Options for setting interval times between rolling upgrades.
 */
export const RollingUpgradeIntervalOptions: TimePeriodOptions[] = [
  {
    label: 'enum.granularity.singular.kHour',
    value: GranularityInverse.Hours,
    labelPlural: 'enum.granularity.plural.kHour'
  },
  {
    label: 'enum.granularity.singular.kDay',
    value: GranularityInverse.Days,
    labelPlural: 'enum.granularity.plural.kDay'
  },
];

/**
 * Default interval selected for rolling upgrade
 */
export const DEFAULT_INTERVAL_FOR_ROLLING_UPGRADE: TimePeriodValue = {
  value: 1,
  type: GranularityInverse.Hours
};

/**
 * Select ALL option for cluster nodes
 */
export const CLUSTER_NODE_SELECT_ALL = 'ALL';

/**
 * Default stat list for cluster glance bar.
 */
export const INIT_STAT_LIST: ClusterListStat[] = [
  {
    count: 0,
    key: 'clusterHealth',
    value: 'NonCritical',
    label: 'enum.clusterHealth.NonCritical',
    icon: healthIconMap['NonCritical'],
  },
  {
    count: 0,
    key: 'clusterHealth',
    value: 'Warning',
    label: 'enum.clusterHealth.Warning',
    icon: healthIconMap['Warning'],
  },
  {
    count: 0,
    key: 'clusterHealth',
    value: 'Critical',
    label: 'enum.clusterHealth.Critical',
    icon: healthIconMap['Critical'],
  },
  {
    count: 0,
    key: 'connected',
    value: 1,
    label: 'connected',
    icon: 'helix:status-success-alt!success',
  },
  {
    count: 0,
    key: 'connected',
    value: 0,
    label: 'disconnected',
    icon: 'helix:status-error-alt!critical',
  },
  {
    count: 0,
    key: 'upgradeStatus',
    value: 'UpgradeAvailable',
    label: 'softwareUpgrade.status.UpgradeAvailable',
    icon: upgradeStatusIconMap['UpgradeAvailable'],
  },
  {
    count: 0,
    key: 'backupStatus',
    value: 'backedup',
    label: 'configurationsBackedUp',
    icon: 'helix:object-cluster-protected-alt',
  },
];

/**
 * OTP Types for cluster claim with MFA.
 */
export const MFA_OTP_TYPES = [
  {
     label: 'access.mfa.authenticator.otp',
     value: MFAType.totp
  },
  {
     label: 'access.mfa.email.otp',
     value: MFAType.email
  }
];
