import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { NfsSquash } from '@cohesity/api/v2';
import { Controls, NgxSubFormComponent, subformComponentProviders } from 'ngx-sub-form';

/**
 * Modify Nfs all squash options component.
 *
 * @example
 *   <coh-nfs-all-squash-options></coh-nfs-all-squash-options>
 */
@Component({
  selector: 'coh-nfs-all-squash-options',
  templateUrl: './nfs-all-squash-options.component.html',
  providers: subformComponentProviders(NfsAllSquashOptionsComponent),
})
export class NfsAllSquashOptionsComponent extends NgxSubFormComponent<NfsSquash> implements AfterViewInit, OnInit {
  /**
   * Label for nfs squash option.
   */
  @Input() label: string;

  /**
   * Indicates whether in edit mode.
   */
  @Input() isEditMode = false;

  /**
   * Indicates whether one of the fields has a value of zero.
   */
  showAllSquashWarning = false;

  /**
   * Get the form controls needed for the sub form.
   *
   * @returns   The form controls object.
   */
  getFormControls(): Controls<NfsSquash> {
    return {
      gid: new UntypedFormControl(null),
      uid: new UntypedFormControl(null),
    };
  }

  /**
   * Initialize the component
   */
  ngOnInit() {
    if (this.isEditMode) {
      this.formGroupControls.gid.setValidators(Validators.required);
      this.formGroupControls.uid.setValidators(Validators.required);
    }

    this.formGroup.valueChanges
      .subscribe(squashObj => {
        this.checkForAllSquashWarning(squashObj);
      });
  }

  ngAfterViewInit() {
    this.checkForAllSquashWarning(this.formGroup.value);
  }

  /**
   * Checks to see if a zero has been entered for any All Squash field and
   * sets warning indicator.
   *
   * @param squashObj FormGroup value
   */
  checkForAllSquashWarning(squashObj: any) {
    if (this.label === 'All Squash' && (squashObj.gid === 0 || squashObj.uid === 0)) {
      this.showAllSquashWarning = true;
    } else {
      this.showAllSquashWarning = false;
    }
  }
}
