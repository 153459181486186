// Component: recovery activty

;(function(angular, undefined) {
  'user strict';

  var componentConfig = {
    controller: 'RecoverActivityController',
    templateUrl: 'app/dashboard/sql/detail/summary/recovery-activity.html',
    bindings: {
      tasks: '<',
    },
  };

  angular.module('C.dbDetails')
    .controller('RecoverActivityController', RecoveryActivityCtrlFn)
    .component('recoveryActivity', componentConfig);

  /**
   * @ngdoc component
   * @name C.dbDetails.recoveryActivity
   *
   * @param   {array}    tasks   List of recovery tasks
   *
   * @description
   * Recovery activity datagrid
   *
   * @example
   * <recovery-activity tasks="$ctrl.tasks"></recovery-activity>
   */
  function RecoveryActivityCtrlFn($rootScope, FEATURE_FLAGS) {
    var $ctrl = this;

    _.assign($ctrl, {
      getUiState: getUiState,
      getUiStateParam: getUiStateParam,
    });

    /**
     * Gets the corresponding state for recovery or clone details.
     * Ideally it should be handled at recover-detail guard;
     * however, ngRecoveryDetail is not ready for all adapters.
     * TODO (Tung): let the guard handle the redirection to NG page
     * when ngRecoveryDetail is enabled.
     *
     * @method   getUiState
     * @param    {object}  restoreTask   The restore task
     * @returns  The ui state name
     */
    function getUiState(restoreTask) {
      if (restoreTask._restoreType === 'kCloneApp') {
        return 'clone-detail';
      } else if (FEATURE_FLAGS.ngRestoreMsSql) {
        return 'recovery.detail';
      } else {
        return 'recover-detail-local';
      }
    }

    /**
     * Gets the corresponding state param for recovery or clone details.
     *
     * @method   getUiStateParam
     * @param    {object}  restoreTask   The restore task
     * @returns  The ui state param
     */
    function getUiStateParam(restoreTask) {
      let taskId = restoreTask.performRestoreTaskState.base.taskId;

      // Reconstruct the task id in NG format
      if (FEATURE_FLAGS.ngRestoreMsSql) {
        taskId = [
          $rootScope.clusterInfo.id,
          $rootScope.clusterInfo.incarnationId,
          taskId,
        ].join(':');
      }
      return taskId;
    }
  }

})(angular);
