import { AfterViewInit, Component, inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { PrincipalsServiceApi, User } from '@cohesity/api/v1';
import { SecurityServiceApi } from '@cohesity/api/v2';
import {
  AppFrameComponent,
  AppPanelService,
  IbmAquaIconService as HelixIbmAquaIconService,
  ibmAquaTheme,
  jazzDarkTheme,
  jazzLightTheme,
  NavBanner,
  NavItem,
  ReflowService,
  ThemeOption,
  ThemeService,
  ViewportSize
} from '@cohesity/helix';
import {
  appConfigMap,
  BannerConfigsService,
  flagEnabled,
  hasCompanyContext,
  IrisContext,
  IrisContextService,
  isAuthenticatedUser,
  isDmsScope,
  isDmsSelfServiceUser,
  isDmsUser,
  isDraasScope,
  isFlashRecover,
  isGlobalScope,
  isHeliosTenantUser,
  isMcm,
  isMcmOnPrem,
  isOneHeliosAppliance,
  isOrganizationEnabled,
  isTenantUser,
  mapNavProvider,
  translateAppName,
  getConfigByKey,
  isIbmBaaSEnabled,
} from '@cohesity/iris-core';
import { IS_IBM_AQUA_ENV } from '@cohesity/shared/core';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { TransitionService, UIRouterGlobals } from '@uirouter/core';
import { get, isNil } from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { AppServiceManagerService, getNavBanner, ServiceNavProvider } from 'src/app/app-services';
import {
  AjsUpgradeService,
  AppLayoutService,
  AutoAppCloseLogoutService,
  CookieNoticeService,
  GlobalSearchRedirectService,
  IbmAquaIconService,
  HeliosAnalyticsService,
  HeliosMonitoringService,
  HomeService,
  LicenseService,
  LocaleService,
  McmViewService,
  OrganizationsService,
  PreloadModulesService,
  ScopeSelectorService,
  StateManagementService,
  UserService,
  WhitelabelingService
} from 'src/app/core/services';
import { GlobalSearchInputComponent } from 'src/app/modules/global-search-shared';
import { customBannerConfigs } from 'src/app/shared/banner-configs';
import { AjsClusterService } from 'src/app/shared/ng-upgrade/services';
import { environment } from 'src/environments/environment';

import { AppHeliosStatusComponent } from '../app-helios-status/app-helios-status.component';
import { NotificationsPanelComponent } from '../notifications';
import {
  ClusterScopeSelectorComponent,
} from '../scope-selector/cluster-scope-selector/cluster-scope-selector.component';
import { AppStateDeclaration } from '../state/app-state-declaration';
import { UserPanelComponent } from '../user-panel';
import { NavService } from './nav.service';
import { HeliosClaimComponent } from '../helios-claim';
import { HeliosStatus } from '../models';

@Component({
  selector: 'coh-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [NavService],
})
export class AppLayoutComponent extends AutoDestroyable implements OnInit, AfterViewInit {

  /**
   * AppFrameComponent template reference.
   */
  @ViewChild(AppFrameComponent, {static: true}) appFrame: AppFrameComponent;

  /**
   * GlobalSearchInputComponent template reference.
   */
  @ViewChild(GlobalSearchInputComponent) globalSearchInputComponent: GlobalSearchInputComponent;

  /**
   * Environment constant for checking production vs dev mode, etc.
   */
  env = environment;

  /**
   * Returns array of navigation items for main navigation.
   */
  navList: NavItem[] = [];

  /**
   * True if sidebar nav is displayed.
   */
  navDisplayed: boolean;

  /**
   * Returns the separate navigation for small screens or
   * zoomed for accessibility
   */
  responsiveTopNavItems$ =  new BehaviorSubject<NavItem[]>([]);

  /**
   * Cohesity app's configuration.
   */
  readonly appConfigs = Object.values(appConfigMap);

  /**
   * The app config map.
   */
  readonly appConfigMap = appConfigMap;

  /**
   * List of available themes.
   */
  readonly themes: ThemeOption[] = [
    { displayName: this.translateService.instant('theme.jazzLight'), config: jazzLightTheme },
    { displayName: this.translateService.instant('theme.jazzDark'), config: jazzDarkTheme },
    { displayName: this.translateService.instant('theme.ibmAquaLight'), config: ibmAquaTheme },
  ];

  /**
   * Message to show if the app is classified.
   */
  classifiedDataMessage: string;

  /**
   * Indicates whether user has access to at least one tenant account
   */
  hasTenantAccess = false;

  /**
   * Url for custom logo
   */
  customLogoUrl: SafeUrl = '';

  /**
   * Get list of classes to be applied on the logo element.
   */
  get logoClasses() {
    const classes = ['logo', 'cohesity-logo'];

    if (this.isIBMAquaEnv) {
      classes.push('ibm-aqua-logo');
    } else {
      const logoClass = getConfigByKey(this.irisContextService.irisContext, 'productLogoClass', '');
      classes.push(logoClass);
    }
    return classes;
  }

  /**
   * Reference to ajs helios service.
   */
  heliosService: any;

  /**
   * Component for helios status popover.
   */
  appHeliosStatusComponent = AppHeliosStatusComponent;

  /**
   * True if appPillars is enabled.
   */
  get appPillarsEnabled(): boolean {
    return flagEnabled(this.irisContextService.irisContext, 'appPillarsEnabled');
  };

  /**
   * Helios Token Claim side panel feature flag
   */
  readonly heliosTokenClaimEnabled = flagEnabled(this.irisContextService.irisContext, 'heliosTokenClaimEnabled');

  /**
   * True if Helios on prem.
   */
  isMcmOnPrem = isMcmOnPrem(this.irisContextService.irisContext);

  /**
   * Behavior Subject for helios status.
   */
  heliosStatusSubject = new BehaviorSubject<HeliosStatus>(null);

  /**
   * The helios status observable.
   */
  heliosStatus$ = this.heliosStatusSubject.asObservable();

  getAppDisplayName = translateAppName(this.translateService);

  /**
   * User last login timestamp.
   */
  readonly lastLoginTime$: Observable<number> = this.userService.user$.pipe(
    filter(user => !isNil(user) && this.isLoggedIn),
    take(1),
    switchMap(() => this.principalService.GetSessionUser()),
    map(({ previousLoginTimeMsecs }) => previousLoginTimeMsecs),
    shareReplay(1),
  );

  /**
   * Indicates if the dark mode toggle should be shown or not.
   */
  get showDarkModeToggle(): boolean {
    return this.flagEnabled('darkMode') && !this.isIBMAquaEnv && !this.isIBMBaaS;
  }

  /**
   * Indicates if the app switcher should be shown or not.
   */
  get showAppSwitcher(): boolean {
    if (this.isIBMAquaEnv) {
      return false;
    }

    const ctx = this.irisContextService.irisContext;

    // If user has Argus priv, always show the app-switcher as that was the original
    // implementation for such cases. This condition can be cleaned up when appSwitcher
    // is enabled.
    if (this.irisContextService.irisContext.privs.DGAAS_VIEW) {
      return true;
    }

    if (isDmsSelfServiceUser(ctx)) {
      return false;
    }

    return this.flagEnabled('appSwitcher') && this.isLoggedIn && isMcm(ctx) &&
      (!isTenantUser(ctx) || !isHeliosTenantUser(ctx));
  }

  /**
   * An observable of the active service.
   */
  serviceNavProvider$ = this.irisContextService.irisContext$.pipe(
    map(() => this.appServiceManager.getActiveService()?.navProvider)
  );

  /**
   * Specifies whether the site continuity version switcher should be shown.
   */
  readonly shouldShowScVersionSwitcher$ = combineLatest([
    this.irisContextService.irisContext$,
    this.serviceNavProvider$,
  ]).pipe(
    map(
      ([ctx, navProvider]) =>
        navProvider?.serviceNavTitleClass === 'site-continuity' &&
        flagEnabled(ctx, 'siteCon2App') &&
        flagEnabled(ctx, 'siteCon2Transition')
    )
  );

  /**
   * Determines whether the UI is running in IBM Aqua mode.
   */
  readonly isIBMAquaEnv = inject(IS_IBM_AQUA_ENV);


  /**
   * Determines whether the UI is running in IBM BaaS mode.
   */
  get isIBMBaaS() {
    return isIbmBaaSEnabled(this.irisContextService.irisContext);
  };

  /**
   * This sets the behavior of the app frame component.
   * This will keep the Left Nav Open and removes the hamburger menu when
   * the viewport is large enough.
   */
  keepNavDrawerOpen = false;

  /**
   * Is the current context helios?
   */
  get isHelios(): boolean {
    return isMcm(this.irisContextService.irisContext);
  }

  /**
   * Checks if the current application is the OneHelios Appliance.
   */
  get isOneHeliosAppliance(): boolean {
    return isOneHeliosAppliance(this.irisContextService.irisContext);
  }

  /**
   * Is the feature flag for rebranding
   * that shows helios in the masthead?
   */
  get showHeliosInMasthead(): boolean {
    return (
      flagEnabled(this.irisContextService.irisContext, 'mastheadShowHelios') &&
      this.isHelios &&
      this.isLoggedIn &&
      !this.isIBMAquaEnv &&
      !this.isIBMBaaS
    );
  }

  /**
   * Helper function for feature flag checks that is easier to expose to the html templae
   *
   * @param flag The flag name
   * @returns true if the flag is enabled
   */
  flagEnabled(flag: string): boolean {
    return flagEnabled(this.irisContextService.irisContext, flag);
  }

  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private ajsClusterService: AjsClusterService,
    private ajsUpgrade: AjsUpgradeService,
    private appServiceManager: AppServiceManagerService,
    private autoAppCloseLogoutService: AutoAppCloseLogoutService,
    private bannerConfigsService: BannerConfigsService,
    private cookieNoticeService: CookieNoticeService,
    private globalSearchRedirectService: GlobalSearchRedirectService,
    private heliosMonitoringService: HeliosMonitoringService,
    readonly helixIbmAquaIconService: HelixIbmAquaIconService,
    readonly ibmAquaIconService: IbmAquaIconService,
    private irisContextService: IrisContextService,
    private lazyLoader: PreloadModulesService,
    private localeService: LocaleService,
    private navService: NavService,
    private organizationsService: OrganizationsService,
    private principalService: PrincipalsServiceApi,
    private reflowService: ReflowService,
    private securityServiceApi: SecurityServiceApi,
    private stateManagementService: StateManagementService,
    private transitionService: TransitionService,
    private translateService: TranslateService,
    private whitelabelingService: WhitelabelingService,
    protected uiRouterGlobals: UIRouterGlobals,
    public appLayoutService: AppLayoutService,
    public appPanelService: AppPanelService,
    public licenseService: LicenseService,
    public userService: UserService,
    readonly heliosAnalyticsService: HeliosAnalyticsService,
    readonly homeService: HomeService,
    readonly mcmService: McmViewService,
    readonly scopeSelectorService: ScopeSelectorService,
    readonly themeService: ThemeService,
  ) {
    super();

    this.heliosService = this.ajsUpgrade.get('HeliosService');
    this.navService.customizedNavList$
      .pipe(this.untilDestroy())
      .subscribe(list => this.navList = list);
    if (this.isIBMBaaS) {
      themeService.setTheme('ibm-aqua', 'light');
      helixIbmAquaIconService.updateIcons();
      ibmAquaIconService.updateIcons();
    }
  }

  ngOnInit() {
    this.setIndefiniteSpinnerTransitionHook();

    this.transitionService.onStart({}, () => this.stateTransitionHandler());

    // ibm aqua requires the nav drawer to stay open
    this.keepNavDrawerOpen = this.isIBMAquaEnv ? true : false;

    // subscribe for changes to isMcm so we know
    // which reflow flag to check for
    this.irisContextService.irisContext$.pipe(
      this.untilDestroy(),
      map((ctx: IrisContext) => isMcm(ctx)),
      distinctUntilChanged()
    ).subscribe((isMcmCtx) => {
      this.reflowService.isReflowEnabled = isMcmCtx
        ? this.flagEnabled('reflowHelios')
        : this.flagEnabled('reflowOnPrem');
    });

    // Is any custom logo available for masthead
    this.whitelabelingService.customLogoUrl$.pipe(
      this.untilDestroy()
    ).subscribe(url => this.customLogoUrl = url);

    // Reload helios confifguration each time user object changes.
    this.userService.user$.pipe(
      filter(user => this.filterLoggedInUser(user)),
    ).subscribe(() => {
      if (this.showHeliosStatus) {
        this.heliosService.getHeliosConfiguration().then(
          value => this.heliosStatusSubject.next(value)
        );
      }
    });

    // Wait for the user profile to load to initialize user dependent services.
    // All these only need to load once.
    this.userService.user$.pipe(
      filter(user => this.filterLoggedInUser(user)),
      map(user => user),
      take(1),
    ).subscribe(user => {
      this.heliosAnalyticsService.initHeliosAnalytics(user);
      this.heliosMonitoringService.initDatadog();

      const ctx = this.irisContextService.irisContext;

      if (isDmsUser(ctx) && !this.flagEnabled('ngGlobalSearchHelios')) {
        // Listen to global search state changes for users which have a dms
        // scope. This is to switch to classic global search, as All Clusters
        // and Global scope does not support NG global search.
        this.globalSearchRedirectService.initGlobalSearchStateHandler();
      }

      if (isMcm(ctx) && !isHeliosTenantUser(ctx)) {
        this.fetchTenantAccess();
      }

      this.loadSecurityConfig();
    });

    this.localeService.userLocale$.pipe(
      this.untilDestroy(),
      filter(locale => !!locale),
      switchMap(() => this.bannerConfigsService.loadBanners(customBannerConfigs))
    ).subscribe();

    this.initializeResponsiveNavMenu();
  }

  /**
   * Function to filter a logged in user.
   *
   * @param user The user.
   */
  filterLoggedInUser(user: User) {
    // For cluster, to check whether user have proper access
    const hasUserAccess = Boolean(user?.roles?.length);

    // In mcm, if the two-step verification is not complete. A temporary
    // user session is created without any privileges.
    return isMcm(this.irisContextService.irisContext)
      ? isAuthenticatedUser(this.irisContextService.irisContext)
      : hasUserAccess;
  }

  ngAfterViewInit() {
    if (this.flagEnabled('autoAppCloseLogout')) {
      // If configured, users will be automatically logged out when closing the
      // app.
      this.autoAppCloseLogoutService.initAutoLogout();
    }

    if (this.flagEnabled('cookieNoticeMcmEnabled')) {
      // Cookie notice will be visible to all Helios users (DMaaS and regular).
      this.cookieNoticeService.initCookieNotice();
    }
    this.lazyLoader.loadModules();
  }

  /**
   * This method sets up the nav items that are used when the view
   * width is small enough to trigger a responsive menu to display.
   */
  initializeResponsiveNavMenu() {
    const buildReflowNav: NavItem[] = [
      !this.isIBMAquaEnv
        ? {
            displayName: 'toggleDarkMode',
            action: () => this.themeService.toggleMode(),
          }
        : undefined,
      {
        displayName: 'userAccount',
        icon: 'person_outline',
        action: () => this.openUserPanel(),
      },
    ].filter(item => !!item);

    this.helpList$.pipe(
      this.untilDestroy()
    ).subscribe(helpList => {
      const reflowNav = [...buildReflowNav, ...helpList];
      this.responsiveTopNavItems$.next(reflowNav);
    });
  }

  /**
   * HACK: There is an issue where when the app is not focussed, and there
   * is a state transition, the async pipe doesn't emit the newest value.
   * This can be seen when going back/forward using the browser back/forward
   * as they make the application lose focus. This is also noticeable when
   * opening the app to a page with spinner sometimes, but not clicking into
   * the app to focus it.
   */
  setIndefiniteSpinnerTransitionHook() {
    let interval;

    this.transitionService.onFinish({}, () => {
      if (interval) {
        clearInterval(interval);
      }

      let timer = 0;
      interval = setInterval(() => {
        timer += 250;

        if (timer === 5000) {
          // Keep doing this for around five seconds.
          clearInterval(interval);
        }

        if (!document.hasFocus() && !document.hidden) {
          // Only click the document if the document does not have focus
          // and the tab is visible.
          document.body.click();
        }
      }, 250);
    });
  }

  /**
   * Function to fetch security config and show an app classified message if
   * necessary.
   *
   * TODO: In the future, this will need to get refetched using notifications
   * service if this setting is updated from the UI.
   */
  loadSecurityConfig() {
    if (isMcm(this.irisContextService.irisContext)) {
      // Dont show this info for helios or dmaas users.
      return;
    }

    this.securityServiceApi.GetSecurityConfig().pipe(
      take(1),
    ).subscribe(response => {
      const {
        dataClassification: {
          isDataClassified = false,
          classifiedDataMessage = '',
        } = {}
      } = response || {};

      if (isDataClassified) {
        this.classifiedDataMessage = classifiedDataMessage;
      }

      // set custom activity timeout if provided by the user
      this.userService.setInactivityTimeout(response.inactivityTimeoutMSecs);
    });
  }

  /**
   * Returns true if user is not a sales/support user or already selected account for impersonation.
   */
  get hasCompanyContext(): boolean {
    return hasCompanyContext(this.irisContextService.irisContext);
  }

  /**
   * Reads a property from the current state config to determine whether the
   * navigation should be hidden or not. When the nav frame is hidden, the
   * component will be allowed to take up the entire browser screen.
   */
  public get hideAppFrame() {

    // If we are running in a Helios IFrame to support lower version hide the app-frame.
    if (this.env.heliosInFrame) {
      return true;
    }
    return get(this.uiRouterGlobals, 'current.data.hideAppFrame', false) && !this.mcmService.showFrame$.value;
  }

  /**
   * Reads a property from the current state config OR url to determine whether the sidebar
   * navigation and button should be hidden or not.
   */
  public get hideNav() {
    if (isDmsSelfServiceUser(this.irisContextService.irisContext)) {
      return true;
    }
    return Boolean(this.uiRouterGlobals.current?.data?.hideNav || this.uiRouterGlobals.params?.hideNav);
  }

  /**
   * Handles transition start change by TransitionService.
   */
  public stateTransitionHandler() {
    if (this.appFrame.shouldOverlayMenu) {
      this.appFrame.isNavOpen = false;
    }
  }

  /**
   * Indicates if the "Page Help" link should be shown in the help flyout.
   *
   * @returns true if page help link should be shown, false otherwise.
   */
  get showPageHelp(): boolean {
    const currentState = this.uiRouterGlobals.current as AppStateDeclaration;
    return Boolean(currentState?.help);
  }

  /**
   * Indicates if the Generic "Help" link should be shown in the help flyout.
   *
   * @returns true if help link should be shown, false otherwise.
   */
  get showGenericHelp(): boolean {
    const ctx = this.irisContextService.irisContext;

    // If there is no page specific help, provide the generic help link except
    // for in Helios, as we provide a "Help Center" link that will serve such
    // a need.
    return !this.showPageHelp && !isMcm(ctx);
  }

  /**
   * Returns if cluster is found.
   */
  get clusterFound(): boolean {
    return this.ajsClusterService.clusterState.found || false;
  }

  /**
   * Returns cluster basic info.
   */
  get basicClusterInfo(): any {
    return this.irisContextService.irisContext.basicClusterInfo;
  }

  /**
   * Returns whether current user is in DMaaS scope.
   */
  get isDmsScope(): boolean {
    return isDmsScope(this.irisContextService.irisContext);
  }

  /**
   * Returns whether current scope is of individual clsuter.
   */
  get isMcmOrMcmOnPrem(): boolean {
    return isMcm(this.irisContextService.irisContext) ||
      isMcmOnPrem(this.irisContextService.irisContext);
  }

  /**
   * Returns whether current user is in DRaaS scope.
   */
  get isDraasScope(): boolean {
    return isDraasScope(this.irisContextService.irisContext);
  }

  /**
   * Returns whether current user has DMaaS context.
   */
  get isDmsUser(): boolean {
    return isDmsUser(this.irisContextService.irisContext);
  }

  /**
   * Returns cluster info.
   */
  get clusterInfo(): any {
    return this.ajsClusterService.clusterInfo;
  }

  /**
   * Getter for help NavItem list.
   */
  get helpList$(): Observable<NavItem[]> {
    return this.navService.helpList$;
  }

  /**
   * Returns true is session is active.
   */
  get isLoggedIn(): boolean {
    return !!this.userService.user;
  }

  /**
   * Returns true is user is impersonated via different tenant.
   */
  get isImpersonated(): boolean {
    return this.userService.isImpersonated;
  }

  /**
   * Returns true is user has switchable accounts
   */
  get isAccountSwitchable(): boolean {
    return this.userService.isAccountSwitchable;
  }

  /**
   * Returns whether app controls (side nav, app bar controls) should be enabled.
   */
  get areControlsEnabled(): boolean {
    return this.isLoggedIn && this.clusterFound && this.isLicenseActivated &&
      !(this.flagEnabled('enableForcePasswordChange') &&
        this.userService.user.forcePasswordChange);
  }

  /**
   * Returns whether global search is active. If active, global search input
   * takes width of the entire remaining top nav bar with no space for other
   * controls.
   */
  get globalSearchActive(): boolean {
    if (!this.globalSearchInputComponent) {
      // If classic global search, this component will not be present. In
      // classic global search, the width of the search input doesn't change.
      return false;
    }

    return this.globalSearchInputComponent.searchWrapperFocused;
  }

  /**
   * Indicates whether the licence workflow has completed or not.
   * If this returns false, the nav bar needs to be hidden.
   *
   * @returns True when license Activation is completed or skipped else false.
   */
  get isLicenseActivated(): boolean {
    return this.licenseService.isLicenseActivated;
  }

  /**
   * Returns true if showing Nav Banner.
   */
  get showNavBanner(): boolean {
    const selectedScope = this.irisContextService.irisContext?.selectedScope;
    // This is to check if selectedScope is an empty object which will cause the banner to show
    // 'Cluster Manager' by default. We want to avoid showing the default in empty object case.
    return selectedScope && Object.keys(selectedScope).length > 0;
  }

  /**
   * Returns true is  selected cluster scope should be shown.
   */
  get showSelectedClusterScope(): boolean {
    const { privs } = this.userService;
    const ctx = this.irisContextService.irisContext;

    // Don't show the selected cluster scope in primary nav if appSwitcher or reflow
    // are enabled, as it ClusterSwitcherComponent will be displayed in the nav.
    if (this.showAppSwitcher ||
      [ViewportSize.xs, ViewportSize.sm].includes(this.reflowService.currentViewport)) {
      return false;
    }

    return this.isLoggedIn &&
      (!isTenantUser(ctx) || !isHeliosTenantUser(ctx)) &&
      this.ajsClusterService.clusterInfo && privs.CLUSTER_REMOTE_VIEW;
  }

  /**
   * Indicates if the NavBanner ClusterSwitcherComponent should be displayed.
   * This is for the new approach to context/app/cluster switching.
   * Switching between apps and clusters are now different mechanisms.
   */
  get showNavBannerClusterScope(): boolean {
    return this.reflowService.isReflowEnabled || this.flagEnabled('appSwitcher');
  }

  /**
   * Indicates of Helios Status should be displayed or not.
   */
  get showHeliosStatus(): boolean {
    const ctx = this.irisContextService.irisContext;
    const { privs } = ctx;
    return this.flagEnabled('heliosEnabled') &&
      !isMcm(ctx) &&
      privs.CLUSTER_VIEW &&
      !isTenantUser(ctx) &&
      !isOneHeliosAppliance(ctx) &&
      !this.isIBMBaaS;
  }

  /**
   * Indicates if the Notifications Indicator should be displayed or not.
   */
  get showNotifications(): boolean {

    // For now. we don't support notifications for DMaaS Scope.
    if (this.isDmsScope) {
      return false;
    }

    if (isDmsSelfServiceUser(this.irisContextService.irisContext)) {
      return false;
    }

    /**
     * Notifications are not supported for tenants
     */
    if (isHeliosTenantUser(this.irisContextService.irisContext)) {
      return false;
    }

    return !!this.basicClusterInfo && this.flagEnabled('notificationEnabled') &&

      // Don't show notification for Helios On-Prem mode.
      !this.basicClusterInfo.mcmOnPremMode &&

      // Don't show notifications for non-Helios if a user is being impersonated.
      ((!this.isImpersonated && !this.basicClusterInfo.mcmMode) ||

      // In Helios/mcmMode, only show notifications in the all clusters context.
      (this.basicClusterInfo.mcmMode && this.clusterInfo && this.clusterInfo._allClusters));
  }

  /**
   * Indicates if the Global Search input should be displayed or not.
   */
  get showGlobalSearchInput(): boolean {
    const activeService = this.appServiceManager.getActiveService();

    // clusterManager supports global search, but only according to the logic below,
    // therefore skip it in allowing the functionality based purely on this flag.
    if (activeService && activeService.serviceType !== 'clusterManager') {
      return activeService.supportsGlobalSearch;
    }

    return this.userService.privs.OBJECT_SEARCH && this.clusterFound &&
      this.uiRouterGlobals.current.name !== 'search' &&
      (this.flagEnabled('globalSearchEnabled') ||
        this.basicClusterInfo?.mcmMode);
  }

  /**
   * Whether to show NG Global Search.
   */
  get showNgGlobalSearch(): boolean {
    if (this.basicClusterInfo?.mcmMode && !this.isDmsScope) {
      // Show classic search when "All Clusters" is selected
      return this.flagEnabled('ngGlobalSearchHelios');
    } else if (this.isDmsScope) {
      // Show ng search when "DataProtect" is selected
      return this.flagEnabled('ngGlobalSearchDms');
    }

    // Show ng search if enabled for regular clusters
    return this.flagEnabled('ngGlobalSearchSingleCluster');
  }

  /**
   * Whether to hide the menu by default and show the hamburger menu instead.
   */
  get collapseMenu(): boolean {
    return Boolean(this.uiRouterGlobals.params?.collapseMenu);
  }

  /**
   * Whether to show the close button instead of hamburger menu.
   */
  get showClose(): boolean {
    return Boolean(this.uiRouterGlobals.params?.showClose);
  }

  /**
   * Function to decide whether to show/hide global search.
   *
   * @return Value of global search enabled.
   */
  get globalSearchEnabled(): boolean {
    if (this.isDraasScope) {
      return false;
    }

    if (isDmsSelfServiceUser(this.irisContextService.irisContext)) {
      return false;
    }

    return isGlobalScope(this.irisContextService.irisContext)
      ? this.flagEnabled('ngGlobalSearchHelios')
      : true;
  }

  /**
   * Function to decide whether to show/hide organization switch
   *
   * @returns True/False based on conditions
   */
  get showOrganizationSwitch(): boolean {
    if (this.isIBMAquaEnv) {
      return false;
    }

    const ctx = this.irisContextService.irisContext;

    // Applicable scopes to show Org switch
    // Corresponds to list of AppServiceConfig serviceType values
    const applicableScopes = [
      // Cluster scope
      'cluster',
      // Helios All Cluster scope
      'allClusters',
      // cluster manager scope serviceType
      'clusterManager'
    ];

    const scopeApplicable = applicableScopes.includes(this.appServiceManager.getScopeName());
    const organizationsFlagEnabled = isOrganizationEnabled(ctx);
    const hasUserAccess = (
      // User is not a helios tenant user
      // and has enough privs to switch or,
      (
        !isHeliosTenantUser(ctx) &&
        this.irisContextService.irisContext.privs.ORGANIZATION_IMPERSONATE
      ) ||
      // user is already in impersonation mode
      this.isImpersonated
    );
    const hasAccessToOrganizations = this.hasTenantAccess;

    // All of the below conditions must satisfy
    return [
      this.isLoggedIn,
      scopeApplicable,
      organizationsFlagEnabled,
      hasUserAccess,
      hasAccessToOrganizations
    ].every(Boolean);
  }

  /**
   * Displays last login information on the bottom of side column.
   */
  get displayLastLoginInfo(): boolean {
    return this.flagEnabled('displayLastLoginTime');
  }

  /**
   * Gets Nav Banner based on service nav provider.
   *
   * @param navProvider
   */
  getNavBanner(navProvider: ServiceNavProvider): NavBanner {
    if (this.showNavBanner && navProvider?.serviceNavTitle) {
      return this.appPillarsEnabled ?
        mapNavProvider(navProvider, this.translateService, true) :
        getNavBanner(navProvider);
    }
    return null;
  }

  /**
   * Fetch Tenant Access Information to check whether tenant has access to
   * organizations or not
   */
  fetchTenantAccess() {
    this.organizationsService.organizations$.pipe(
      this.untilDestroy()
    ).subscribe((value) => {
      this.hasTenantAccess = value?.tenantAccesses?.length ? true : false;
    }, (error) => {
      this.ajaxHandlerService.handler(error);
    });
  }

  /**
   * Opens Cluster Select component overlay.
   */
  openClusterSelect() {
    this.appPanelService.open(
      ClusterScopeSelectorComponent,
      { panelClass: 'coh-scope-selector' }
    );
  }

  /**
   * Opens User Panel component in overlay.
   */
  openUserPanel() {
    this.appPanelService.open(UserPanelComponent);
  }

  /**
   *  Opens Notifications component in overlay.
   */
  openNotifications() {
    this.appPanelService.open(NotificationsPanelComponent);
  }

  /**
   * Handle clicking on close button in main header.
   */
  handleCloseButtonClick() {
    this.stateManagementService.goToPreviousState(this.homeService.name);
  }

  /**
   * Opens Helios Token Claim side panel
   */
  openHeliosTokenClaim() {
    this.appPanelService.open(HeliosClaimComponent);
  }

  /**
   * Indicates whether cluster is Pure hardware offering or not (FlashRecover).
   * If this returns false, the branding side nav footer needs to be hidden.
   *
   * @returns True when cluster is FlashRecover, else false.
   */
  get isFlashRecover(): boolean {
    return isFlashRecover(this.irisContextService.irisContext);
  }

  goToOneHelios() {
    const heliosURL = 'https://' +
      this.irisContextService.irisContext.clusterInfo?.loadBalancerVipConfig?.hostName;
    window.open(heliosURL, '_');
  }
}
