<cog-page class="cog-takeover-theme"
  pageWidth="lg">
  <cog-page-header>
    <cog-page-toolbar [title]="headerTitle || ('newRecovery' | translate)"
      [tallHeader]="true"
      color="accent"
      (back)="close.emit()">
      <cog-page-nav>
        <ng-container *ngIf="pageHeader">
          <ng-container *ngTemplateOutlet="pageHeader"></ng-container>
        </ng-container>
        <ng-container *ngIf="!pageHeader">
          <div class="restore-page-header">
            <cog-icon *ngIf="pageIcon"
              [shape]="pageIcon"></cog-icon>
            <div class="page-title">{{pageTitle}}</div>
          </div>
        </ng-container>
      </cog-page-nav>
    </cog-page-toolbar>
  </cog-page-header>
  <ng-content></ng-content>
</cog-page>
