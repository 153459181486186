import {
  MSSQLFileProtectionGroupObjectParams,
  MSSQLNativeProtectionGroupObjectParams,
  MSSQLVolumeProtectionGroupObjectParams,
} from '@cohesity/api/v2';
import { RecoveryAction } from '@cohesity/iris-shared-constants';
import { RecoverMsSqlFormOptions } from 'src/app/modules/restore/recover-ms-sql/model';
import { CreateRecoveryForm } from 'src/app/modules/restore/restore-shared';

/*
 * Protection Group related constants
 ******************************************************************************/
/**
 * RexExp for testing if a database name is a System DB.
 */
export const rxSystemDatabase = /^(?:model|msdb|master)$/i;

/**
 * SQL host entities which can be selected.
 */
export const selectableHostEntities = ['kHost', 'kWindowsCluster', 'kVirtualMachine', 'kAAG'];

/**
 * SQL backup types.
 */
export enum SqlBackupTypes {
  Native = 'kNative',
  File = 'kFile',
  Volume = 'kVolume',
}

/**
 * SQL typed protection params keys.
 *
 * Usage:
 *   group.mssqlParams[SQLTypedProtectionParamsKeys.kFile].objects
 */
export enum SQLTypedProtectionParamsKeys {
  kFile = 'fileProtectionTypeParams',
  kVolume = 'volumeProtectionTypeParams',
  kNative = 'nativeProtectionTypeParams',
}

/**
 * SQL typed object protection params keys.
 *
 * Usage:
 *   object.mssqlParams[SQLTypedObjectProtectionParamsKeys.kFile].objects
 */
export enum SQLTypedObjectProtectionParamsKeys {
  kFile = 'commonFileObjectProtectionTypeParams',
  kNative = 'commonNativeObjectProtectionTypeParams',
}

/**
 * MS-SQL Server Types.
 */
export enum ServerTypes {
  kWindows = 'Windows',
  kLinux = 'Linux',
}

/**
 * MS-SQL Linux Server Authentication Types.
 */
export enum LinuxAuthTypes {
  kAD = 'AD',
  kDB = 'DB',
}

/**
 * All possible SQL backup type object properties in one.
 */
export interface MSSQLObjectParams extends
  MSSQLFileProtectionGroupObjectParams,
  MSSQLVolumeProtectionGroupObjectParams,
  MSSQLNativeProtectionGroupObjectParams {}

/*
 * Recovery related constants
 ******************************************************************************/
export type MsSqlRecoveryForm = CreateRecoveryForm<RecoverMsSqlFormOptions>;

export const msSqlRestoreType = RecoveryAction.RecoverApps;
