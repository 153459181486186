<!-- Protocol selector Component -->
<div class="flex-row flex-justify-space-between padding-top">
  <mat-form-field class="col-xs-6 no-padding">
    <mat-label>{{'readWriteProtocol' | translate}}</mat-label>
    <mat-select
      *ngIf="category !== 'FileServices'"
      [value]="control.value && control.value[0]"
      id="single-read-write-protocol-selector"
      cogDataId="read-write-protocol-selector"
      (selectionChange)="updateViewProtocols($event, 'ReadWrite')"
      [compareWith]="compareProtocols"
      [disabled]="control.disabled">
      <mat-option *ngFor="let protocol of viewReadWriteProtocols"
        [value]="protocol"
        [inheritDataId]="true"
        cogDataId="option-{{protocol.type}}"
        [class.hidden]="protocol?.hidden">
        {{viewProtocols[protocol.type] | translate}}
      </mat-option>
    </mat-select>
    <mat-select
      *ngIf="category === 'FileServices'"
      [value]="control.value"
      [disabled]="control.disabled"
      id="multiple-read-write-protocol-selector"
      cogDataId="read-write-protocol-selector"
      (selectionChange)="updateViewProtocols($event, 'ReadWrite')"
      [compareWith]="compareProtocols" multiple>
      <mat-option *ngFor="let protocol of viewReadWriteProtocols"
        [value]="protocol"
        [inheritDataId]="true"
        cogDataId="option-{{protocol.type}}"
        [class.hidden]="protocol?.hidden">
        {{viewProtocols[protocol.type] | translate}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="!['ObjectServices', 'ArchiveServices'].includes(category) && !isHelios"
    class="col-xs-6 padding-left-lg no-padding">
    <mat-label>{{'readOnlyProtocolOptional' | translate}}</mat-label>
    <mat-select
      [value]="control.value"
      cogDataId="read-only-protocol-selector"
      [compareWith]="compareProtocols"
      [disabled]="control.disabled"
      (selectionChange)="updateViewProtocols($event, 'ReadOnly')" multiple>
      <mat-option *ngFor="let protocol of viewReadOnlyProtocols"
        [value]="protocol"
        [inheritDataId]="true"
        cogDataId="option-{{protocol.type}}"
        [class.hidden]="protocol?.hidden">
        {{viewProtocols[protocol.type] | translate}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
