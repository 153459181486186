import { ReportColumnConfigs } from '../common-report-items/custom-reports.service';
import { ReportSection } from '../iris-reporting.model';

/**
 * This is the layout config for the protected object report.
 */
export const protectedObjectsReport: ReportSection[] = [
  {
    title: 'reporting.summary',
    items: [
      {
        id: 'statlistItem',
        componentIds: ['003'],
        componentNames: ['ObjectStatusCount'],
        params: {
          items: [
            {
              title: 'reporting.successRate',
              translate: true,
              showChart: true,
              measurement: {
                valueKey: 'countObjectUuid',
                dataType: 'percent',
                filter: {
                  filterKey: 'backupStatus',
                  value: 'HasSuccessfulBackups',
                },
                aggregation: 'percent',
              },
            },
            {
              title: 'reporting.totalObjects',
              translate: true,
              measurement: {
                valueKey: 'countObjectUuid',
                dataType: 'number',
              },
            },
            {
              title: 'reporting.objectsWithSuccessfulBackups',
              translate: true,
              measurement: {
                valueKey: 'countObjectUuid',
                dataType: 'number',
                filter: {
                  filterKey: 'backupStatus',
                  value: 'HasSuccessfulBackups',
                },
              },
            },
            {
              title: 'reporting.objectsWithNoSuccessfulBackups',
              translate: true,
              measurement: {
                valueKey: 'countObjectUuid',
                dataType: 'number',
                filter: {
                  filterKey: 'backupStatus',
                  value: 'HasNoSuccessfulBackups',
                },
              },
            },
            {
              title: 'reporting.objectsWithSnapshots',
              translate: true,
              measurement: {
                valueKey: 'countObjectUuid',
                dataType: 'number',
                filter: {
                  filterKey: 'snapshotStatus',
                  value: 'HasActiveSnapshots',
                },
              },
            },
            {
              title: 'reporting.objectsWithNoSnapshots',
              translate: true,
              measurement: {
                valueKey: 'countObjectUuid',
                dataType: 'number',
                filter: {
                  filterKey: 'snapshotStatus',
                  value: 'HasNoActiveSnapshots',
                },
              },
            },
          ],
        },
      },
    ],
  },
  {
    items: [
      {
        id: 'chartItem',
        componentIds: ['001'],
        componentNames: ['ProtectedObjectsByEnvironment'],
        title: 'reporting.protectedObjectsByObjectType',
        params: {
          chartType: 'pie',
          measurements: [
            {
              valueKey: 'countObjectUuid',
            },
          ],
          dimensions: [
            {
              dimensionKey: 'environment',
              maxValues: 3,
            },
          ],
        },
      },
      {
        id: 'chartItem',
        componentIds: ['002'],
        componentNames: ['ObjectsStatusByEnvironment'],
        title: 'reporting.successfulBackupsByObjectType',
        params: {
          chartType: 'column',
          measurements: [
            {
              valueKey: 'countObjectUuid',
            },
          ],
          dimensions: [
            {
              dimensionKey: 'environment',
              maxValues: 3,
            },
            {
              dimensionKey: 'backupStatus',
            },
          ],
        },
      },
    ],
  },
  {
    title: 'reporting.data',
    isFullData: true,
    items: [
      {
        id: 'tableItem',
        componentIds: ['000'],
        componentNames: ['AllObjects'],
        params: {
          columns: [
            {
              title: 'objectName',
              translate: true,
              key: 'objectName',
              icon: {
                key: 'objectType',
                dataType: 'objectTypeIcon',
                renderParams: {
                  environmentKey: 'environment',
                  protectionStatusKey: 'protectionStatus',
                },
              },
              primary: {
                key: 'objectName',
                dataType: 'text',
                renderParams: {
                  width: 'lg',
                },
              },
              subtitle: {
                key: 'environment',
                dataType: 'environmentName',
              },
            },
            {
              title: 'source',
              translate: true,
              key: 'sourceName',
              primary: {
                key: 'sourceName',
                dataType: 'text',
                renderParams: {
                  width: 'lg',
                },
              },
            },
            {
              title: 'protectionGroup',
              enabled: false,
              translate: true,
              key: 'groupName',
              primary: {
                key: 'groupName',
                dataType: 'text',
              },
            },
            {
              title: 'policy',
              translate: true,
              key: 'policyName',
              primary: {
                key: 'policyName',
                dataType: 'text',
              },
              subtitle: {
                key: 'policyRetentionDays',
                dataType: 'text',
                renderParams: {
                  translate: true,
                  translateValue: 'reporting.retainValueD'
                }
              },
            },
            {
              title: 'lastRun',
              translate: 'true',
              key: 'lastRunTime',
              icon: {
                key: 'lastRunStatus',
                dataType: 'statusIcon',
              },
              primary: {
                key: 'lastRunTime',
                dataType: 'date',
                renderParams: {
                  usecs: true,
                },
              },
            },
            {
              title: 'lastSuccessfulBackup',
              translate: 'true',
              enabled: false,
              key: 'lastSuccessfulBackup',
              primary: {
                key: 'lastSuccessfulBackup',
                dataType: 'date',
                renderParams: {
                  usecs: true,
                },
              },
            },
            {
              title: 'reporting.protectionStatus.wrapped',
              translate: 'true',
              key: 'protectionStatus',
              primary: {
                key: 'protectionStatus',
                dataType: 'enumType',
              },
            },
            {
              title: 'reporting.activeSnapshots.wrapped',
              translate: 'true',
              key: 'numSnapshots',
              primary: {
                key: 'numSnapshots',
                dataType: 'number',
              },
            },
            {
              title: 'reporting.successfulBackups.wrapped',
              translate: 'true',
              key: 'numSuccessfulBackups',
              primary: {
                key: 'numSuccessfulBackups',
                dataType: 'number',
              },
            },
            {
              title: 'reporting.unsuccessfulBackups.wrapped',
              translate: 'true',
              key: 'numUnsuccessfulBackups',
              primary: {
                key: 'numUnsuccessfulBackups',
                dataType: 'number',
              },
            },
            {
              title: 'organization',
              enabled: false,
              translate: true,
              key: 'tenantName',
              primary: {
                key: 'tenantName',
                dataType: 'text',
                renderParams: {
                  emptyKey: 'naNotAvailable',
                },
              },
              featureFlag: 'ngReportSPView',
            },
            {
              title: 'system',
              translate: 'true',
              enabled: false,
              key: 'system',
              primary: {
                key: 'system',
                dataType: 'text',
              },
            },
          ],
        },
      },
    ],
  },
];


/**
 * This is metadata needed to determine available chart customizations.
 */
export const protectedObjectsColumns: ReportColumnConfigs = {
  lastRunStatus: {
    aggs: [],
    canGroup: true,
    dataType: 'text',
  },
  lastRunTime: {
    aggs: [],
    dataType: 'date'
  },
  backupStatus: {
    aggs: [],
    canGroup: true,
    dataType: 'text',
  },
  policyId: {
    aggs: ['count'],
    dataType: 'uuid'
  },
  policyName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text'
  },
  objectName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  objectUuid: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  environment: {
    aggs: ['count'],
    canGroup: true,
    maxGroupingValues: 3,
    dataType: 'text',
  },
  protectionStatus: {
    aggs: [],
    canGroup: true,
    dataType: 'text',
  },
  sourceName: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  sourceUuid: {
    aggs: ['count'],
    dataType: 'uuid',
  },
  system: {
    aggs: [],
    canGroup: true,
    maxGroupingValues: 5,
    dataType: 'text',
  },
  systemId: {
    aggs: ['count'],
    dataType: 'uuid',
  },
};
