import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ThreatMatchedStatusModule } from '@cohesity/data-govern/threat-detection';
import { IconModule } from '@cohesity/helix';
import { IrisRouterModule } from '@cohesity/iris-core';
import { TranslateModule } from '@ngx-translate/core';

import { ScanRunStatusModule } from '../scan-run-status/scan-run-status.module';
import { ThScanRunStatsComponent } from './th-scan-run-stats.component';

const COMPONENTS = [ThScanRunStatsComponent];

@NgModule({
  imports: [
    CommonModule,
    IconModule,
    IrisRouterModule,
    ScanRunStatusModule,
    ThreatMatchedStatusModule,
    TranslateModule,
  ],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
})
export class ThScanRunStatsModule { }
