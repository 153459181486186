<ng-container *ngIf="data?.length">
  <cog-bubble-chart class="bubble-chart-theme" [customChartOptions]="options" [seriesData]="bubbleChartSeries"> </cog-bubble-chart>
  <div class="bubble-chart-theme" *ngFor="let column of legendValues">
    <cog-statlist [vertical]="true">
        <cog-statlist-item *ngFor="let item; of column"
          [label]="item.label || item.name"
          labelLegend="highcharts-color-{{ item.index }}">
          <cog-data-renderer [value]="item.value" [dataType]="item.dataType">
          </cog-data-renderer>
        </cog-statlist-item>
    </cog-statlist>
  </div>
  </ng-container>
<div *ngIf="!data?.length">{{translate('reporting.noChartFilterDataAvailable')}}</div>
