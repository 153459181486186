import { Component, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IrisContextService } from '@cohesity/iris-core';
import { StateService } from '@uirouter/core';
import { Controls, DataInput, NgxRootFormComponent, takeUntilDestroyed } from 'ngx-sub-form';

import { LoginService, MfaService } from '../../services';
import { MFAType, MfaVerification } from '../interfaces/mfa-verification';

/**
 * MFA View types
 */
type MfaView = 'token' | 'email';

@Component({
  selector: 'coh-mfa-verify',
  templateUrl: './mfa-verify.component.html',
  styleUrls: ['./mfa-verify.component.scss']
})
export class MfaVerifyComponent extends NgxRootFormComponent<MfaVerification> {

  /**
   * The data input for the MFA Verification form.
   */
  @DataInput()
  dataInput: Required<MfaVerification>;

  /**
   * The data output for the MFA verification form.
   */
  dataOutput = new EventEmitter<MfaVerification>();

  /**
   * The current view to be shown for the user.
   */
  currentSelectedView: MfaView = 'token';

  /**
   * Returns the form controls for the group.
   *
   * @returns The form controls
   */
  getFormControls(): Controls<MfaVerification> {
    return {
      emailToken: new UntypedFormControl(),
      securityToken: new UntypedFormControl(),
    };
  }

  /**
   * The back button text.
   */
  get backBtnText(): string {
    return this.isTokenView ? 'mfa.verify.alternativeVerification' : 'back';
  }

  /**
   * Returns whether to show the token view.
   */
  get isTokenView(): boolean {
    return this.currentSelectedView === 'token' &&
      this.mfaService.isTokenAuthentication;
  }

  /**
   * Returns whether to show the email view.
   */
  get isEmailView(): boolean {
    return this.currentSelectedView === 'email' &&
      this.mfaService.isEmailAuthentication;
  }

  /**
   * Checks whether the user is fully authenticated.
   */
  get isUserAuthenticated(): boolean {
    return Boolean(this.irisCtx?.irisContext?.user?.roles?.length);
  }

  /**
   * Checks whether the instance of the user is loaded into context.
   */
  get hasNoUserContext(): boolean {
    return !Object.keys(this.irisCtx?.irisContext?.user).length;
  }

  constructor(
    readonly mfaService: MfaService,
    private loginService: LoginService,
    private irisCtx: IrisContextService,
    private stateService: StateService,
  ) {
    super();

    // If the user is fully authenticated. Then redirect him to dashboard.
    // This scenario is a corner case, were a previous login failed and we
    // track the user
    if (this.isUserAuthenticated) {
      this.stateService.go('dashboards.summary');
      return;
    }

    // If there is no user context then go back to login.
    if (this.hasNoUserContext) {
      this.stateService.go('login');
      return;
    }

    this.currentSelectedView = this.mfaService.isTokenAuthentication ? 'token' : 'email';
  }

  /**
   * Handles the back click on the user.
   * This switches between email and token view.
   */
  handleBackClick() {
    this.currentSelectedView = this.isTokenView ? 'email' : 'token';
  }

  /**
   * Handles the login complete call on successful verification.
   */
  login() {
    const { securityToken, emailToken } = this.formGroupValues;
    const token = this.isTokenView ? securityToken.securityToken : emailToken.emailToken;
    const type: MFAType = this.isTokenView ? MFAType.totp : MFAType.email;
    this.mfaService.verify(token, type)
      .pipe(takeUntilDestroyed(this))
      .subscribe(user => this.loginService.authenticateUser(user));
  }
}
