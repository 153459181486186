import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AutoDestroyable } from '@cohesity/utils';

import {
  PerformScanDialogComponent,
  PerformScanDialogInputParams,
} from '../components/perform-scan-dialog/perform-scan-dialog.component';

/**
 * Personal service for dashboard access card to fetch & transform data from Audit logs service.
 */
@Injectable()
export class PerformScanDialogService extends AutoDestroyable {
  /**
   * True if data fetching is in progress.
   */
  loading = false;

  constructor(
    private dialog: MatDialog
  ) {
    super();
  }

  openPerformScanDialog(dialogParams: PerformScanDialogInputParams = undefined,
    callback: (r: unknown) => void = undefined) {
    this.dialog.open(PerformScanDialogComponent, { data: dialogParams, maxHeight: '80%', maxWidth: '80%' })
      .afterClosed()
      .pipe(this.untilDestroy())
      .subscribe(result => {
        callback && callback(result);
      });
  }
}
