// Reports: Available Local Snapshots

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.reports')
    .controller('reportsAvailableLocalSnapshotsController', reportsAvailableLocalSnapshotsControllerFn);

  function reportsAvailableLocalSnapshotsControllerFn(
    $rootScope, $scope, $q, JobService, ReportsService, LocalSnapshotsUtil,
    evalAJAX, cReportsControlsUtil, ReportsUtil) {

    $scope.getJobSummaries = getJobSummaries;
    $scope.dwnldAvailableLocalSnapshotReport =
      dwnldAvailableLocalSnapshotReport;

    $scope.snapshots = [];

    $scope.localSnapshotsConfig =
      ReportsUtil.getReportConfig('kAvailableLocalSnapshotsReport')();

    /**
     * Loads Controller data
     *
     * Resets Job Name and Selected Id so we don't carry them
     * over from a previous report.
     */
    function activate() {
      var defaultFilters;

      defaultFilters = cReportsControlsUtil.getDefaultFilters({
        typeAhead: {},
      });

      getJobSummaries(defaultFilters);
    }

    /**
     * returns params object for API call
     *
     * @param      {object}    filters    filters from c-reports-controls
     *
     * @return {object} sets values from filters used in API Call
     */
    function getParams(filters) {
      return {
        reportType: $scope.localSnapshotsConfig.reportType,
        jobIds: filters.jobId,
        startTimeUsecs: filters.timeObject.from.toUsecDate(),
        endTimeUsecs: filters.timeObject.until.toUsecDate(),
      };
    }

    /**
     * Refreshes data when applying new filters
     *
     * @param      {object}    filters    filters from c-reports-controls
     */
    function getJobSummaries(filters) {
      $scope.snapshots.length = 0;

      ReportsService.getProtectionSourceObjects(getParams(filters))
        .then(function getSources(protectionSourcesJobsSummary) {
          $scope.snapshots = protectionSourcesJobsSummary;

          if ($scope.snapshots.length) {
            calculateSummaryStats();
          }

          $scope.dataReady = true;
        }, evalAJAX.errorMessage);
    }

    /**
     * Initiates CSV download
     */
    function dwnldAvailableLocalSnapshotReport() {
      var params = getParams(cReportsControlsUtil.filters);
      params.outputFormat = 'csv';

      ReportsService.getProtectionSourceObjects(params);
    }

    /**
     * Call util methods to populate values for summary
     * stats row
     */
    function calculateSummaryStats() {
      $scope.summaryStats = {
        objectCount: $scope.snapshots.length,
        snapshotsCount: LocalSnapshotsUtil.getSnapshotsCount($scope.snapshots),
        errorCount: LocalSnapshotsUtil.getErrorCount($scope.snapshots),
        latestRun: LocalSnapshotsUtil.getLatestSuccessfulRun($scope.snapshots),
        firstRun: LocalSnapshotsUtil.getFirstSuccessfulRun($scope.snapshots),
      };
    }

    activate();
  }
})(angular);
