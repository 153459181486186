<cog-dashcard
  [title]="'dg.sc.dashboard.postureAdvisorScore' | translate"
  [subtitle]="lastScanTime">
  <ng-container *ngIf="!loading; else spinner">
    <ng-container *ngTemplateOutlet="postureAdvisorScore"></ng-container>
  </ng-container>
</cog-dashcard>

<ng-template #postureAdvisorScore>
  <mat-card-content *ngIf="(data$ | async)?.riskScore; else blankCard">
    <div class="flex-row vertical-align risk-score-donut
      {{getRiskProperties((data$ | async)?.riskScore).class}}">
      <cog-donut-chart
        [isClickable]="true"
        [reflowOnFirstRender]="true"
        [seriesData]="[chartData]">
        <a cogDataId="sla-link" (click)="handleChartClick()">
          {{ (data$ | async)?.riskScore }}
          <label>{{ riskString | translate }}</label>
        </a>
      </cog-donut-chart>

      <cog-thermometer-chart [chartData]="thermometerData"></cog-thermometer-chart>
    </div>
  </mat-card-content>
</ng-template>

<ng-template #blankCard>
  <cog-blank-card></cog-blank-card>
</ng-template>

<ng-template #spinner>
  <cog-spinner></cog-spinner>
</ng-template>
