<h1 mat-dialog-title>
  {{ (data.index > -1 ? 'inboundDnsDialogEditTitle' : 'inboundDnsDialogAddTitle') | translate }}
</h1>

<mat-dialog-content class="no-margin padding-left-sm">
  <coh-dns-vips-entry
    [model]="model"
    [ipPreference]="data.ipPreference">
  </coh-dns-vips-entry>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button (click)="cancel()"
    cogCancelButton
    color="primary"
    mat-button
    mat-dialog-close>
    {{ 'cancel' | translate }}
  </button>

  <button (click)="submit()"
    cogSubmitButton
    color="primary"
    mat-button>
    {{ (data.index > -1 ? 'edit' : 'add') | translate }}
  </button>
</mat-dialog-actions>
