/* eslint-disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for CohesityAgentDeploymentApi services
 */
@Injectable({
  providedIn: 'root',
})
export class CohesityAgentDeploymentApiConfiguration {
  rootUrl: string = '/v1';
}

export interface CohesityAgentDeploymentApiConfigurationInterface {
  rootUrl?: string;
}
