import { Injectable } from '@angular/core';
import { IrisContextService } from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { Environment } from 'src/app/shared/constants';

import { VmSourceDataNode } from './vm-source-data-node';
import { VmSourceTreeService } from './vm-source-tree.service';

@Injectable({
  providedIn: 'root'
})
export class HypervSourceTreeService extends VmSourceTreeService {
  constructor(translate: TranslateService, irisContext: IrisContextService) {
    super(Environment.kHyperV, translate, irisContext);
  }

  /**
   * Only expand certain node types by default.
   *
   * @param   treeNode   The treenode to check.
   * @return  True if the node should be expanded by default.
   */
  shouldExpandNodeOnLoad(treeNode: VmSourceDataNode): boolean {
    return super.shouldExpandNodeOnLoad(treeNode) || treeNode.type === 'kSCVMMServer';
  }
}
