/**
 * The Calendar configuration which can be used to customize input/output similar to DateAdapter.
 *
 * @example
 *  @Injectable()
 *  export class AppCalendarConfig extends CalendarConfig<Date> {
 *    getCurrentUtcOffset() => {
 *      return moment().utcOffset();
 *    }
 *
 *    addMinutes(date: Date, nMinutes: number): Date {
 *      return moment(date).add(nMinutes, 'minutes').toDate();
 *    }
 *  }
 *
 *  @NgModule({
 *    imports: [ CalendarModule ],
 *    providers: [
 *      {
 *        provide: CalendarConfig,
 *        useValue: AppCalendarConfig,
 *      },
 *    ],
 *  })
 *  export class MyModule { }
 */
export abstract class CalendarConfig<D> {
  /**
   * Get the current user UTC offset value used to make adjustments in input/output calendar's date.
   *
   * @return  The current user UTC offset value.
   */
  abstract getCurrentUtcOffset: () => number;

  /**
   * Add the number of minutes to the provided date.
   *
   * @param   date      The date.
   * @param   nMinutes  The number of minutes to add.
   * @return  A new date by adding provided minutes.
   */
  abstract addMinutes: (date: D, nMinutes: number) => D;
}
