// Module: Audit Logs

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.auditCluster', [])
    .config(AuditClusterConfigFn)
    .controller('auditClusterController', AuditClusterControllerFn);

  function AuditClusterConfigFn($stateProvider, $urlRouterProvider) {
    $stateProvider
      .state('audit.cluster', {
        url: '^/monitoring/audit/cluster',
        help: 'admin_audit',
        canAccess: 'CLUSTER_AUDIT && FEATURE_FLAGS.auditLogsEnabled',
        parentState: 'audit',
        title: 'Cluster Audit Logs',
        params: {
          startTimeMsecs: { type: 'int', value: null },
          endTimeMsecs: { type: 'int', value: null },
          actions: { type: 'string', value: null, array: true },
        },
        views: {
          'tab-view@audit': {
            templateUrl: 'app/admin/audit/cluster.html',
            controller: 'auditClusterController as $ctrl',
          },
        }
      });
  }

  function AuditClusterControllerFn(
    $rootScope, $scope, $state, $filter, evalAJAX, ClusterService,
    DateRangerService, ActiveDirectoryService, $translate, cMessage) {

    var $ctrl = this;

    _.assign($ctrl, {
      // True by default so the spinner is shown immediately on page load.
      loading: true,

      // List of all logs
      auditLogs: [],

      // Translate the filter names here because they are reused every time the
      // filters are changed.
      filterTypes: {
        actions: $translate.instant('audit.filters.actions.title'),
        categories: $translate.instant('audit.filters.categories.title'),
        search: $translate.instant('audit.filters.search.title'),
        users: $translate.instant('audit.filters.users.title'),
      },

      // Model for all of the filters.
      filters: {
        dates: DateRangerService.getDateRangerValues(),
        categories: [],
        actions: [],
        users: [],
      },

      // List of selected filters. Model for the set of pills.
      activeFilters: [],

      showDateRanger: false,

      $onInit: $onInit,
      onFilter: onFilter,
      removeFilter: removeFilter,
      refreshUsers: refreshUsers,
      downloadCsv: downloadCsv,
    });

    /**
     * Activate the controller
     *
     * @method   $onInit
     */
    function $onInit() {
      // Load audit log filters
      ClusterService.getAuditFilters().then(
        function getAuditFiltersSuccess(response) {
          // Backend hash of filter options.
          $ctrl.filterLabels = response;

          // Populate the filterOptions hash used by ui-select
          $ctrl.filterOptions = {};
          angular.forEach($ctrl.filterLabels,
            function compileFilterTypes(values, key) {
              $ctrl.filterOptions[key] = [];
              angular.forEach(values, function
                compileFilterTypeValues(name, value) {
                  $ctrl.filterOptions[key].push({
                    value: value,
                    name: name
                  });
                }
              );
            }
          );

          // Now load the audit logs and apply the filter in provided via
          // stateParam.
          _applyInitialFilter();
        },
        evalAJAX.errorMessage
      );
    }

    /**
     * Initialize the filter if filter params are present in the state params.
     *
     * @method     _applyInitialFilter
     */
    function _applyInitialFilter() {
      if ($state.params.startTimeMsecs && $state.params.endTimeMsecs) {
        $ctrl.filters.dates.startDate = new Date($state.params.startTimeMsecs);
        $ctrl.filters.dates.endDate = new Date($state.params.endTimeMsecs);

        // c-date-ranger-toggle will auto select the date range internally based
        // on provided start and end date.
        $ctrl.filters.dates.range = 'auto';
      }

      if ($state.params.actions && $state.params.actions.length) {
        $ctrl.filters.actions = $ctrl.filterOptions.actions.filter(
          function eachFilter(action) {
            return ($state.params.actions || []).includes(action.name);
          }
        );
      }

      $ctrl.onFilter();
    }

    /**
     * Update the activeFilters list which is the model for the set of pills.
     *
     * @method     updateActiveFilters
     */
    function _updateActiveFilters() {
      $ctrl.activeFilters = [];
      angular.forEach($ctrl.filters, function forEachUpdateFilters(type, key) {
        switch(key) {
          case 'search':
            if (type) {
              $ctrl.activeFilters.push({type: key, value: type});
            }
            break;
          case 'users':
            angular.forEach(type, function forEachUpdateUserFilter(user) {
              $ctrl.activeFilters.push({
                type: key,
                value: $filter('principalName')(user),
              });
            });
            break;
          case 'categories':
          case 'actions':
            angular.forEach(type, function forEachUpdateOtherFilter(filter) {
              $ctrl.activeFilters.push({type: key, value: filter.value});
            });
            break;
        }
      });
    }

    /**
     * Update the pills and get new audit log data
     *
     * @method     onFilter
     */
    function onFilter() {
      _updateActiveFilters();
      _loadAuditLogs();
    }

    /**
     * Build request object from model.
     *
     * @method     _assembleRequestObj
     * @param      {object}  filtersObj  The filter model object
     * @return     {object}  proper request object
     */
    function _assembleRequestObj(filtersObj) {
      var requestObj = {
        startTimeUsecs: filtersObj.dates.startDate.getTime() * 1000,
        endTimeUsecs: filtersObj.dates.endDate.getTime() * 1000,
      };

      if (filtersObj.search) {
        requestObj.search = filtersObj.search;
      }

      if (filtersObj.categories.length) {
        requestObj.entityTypes = [];
        filtersObj.categories.forEach(function(cat) {
          requestObj.entityTypes.push(cat.value);
        });
      }

      if (filtersObj.actions.length) {
        requestObj.actions = [];
        filtersObj.actions.forEach(function(action) {
          requestObj.actions.push(action.value);
        });
      }

      if (filtersObj.users && filtersObj.users.length) {
        requestObj.userNames = [];
        requestObj.domains = [];
        filtersObj.users.forEach(function(user){
          requestObj.userNames.push(user.principalName);
          if (user.domain) {
            requestObj.domains.push(user.domain);
          }
        });
      }

      return requestObj;
    }

    /**
     * Removes the specified filter config from the filters model
     *
     * @method     removeFilter
     * @param      {object}  filter  The clicked filter
     */
    function removeFilter(filter) {
      switch(filter.type) {
        case 'search':
          $ctrl.filters[filter.type] = undefined;
          break;

        case 'users':
          angular.forEach($ctrl.filters[filter.type],
            function removeUserFilter(item, index) {
              if (filter.value === $filter('principalName')(item)) {
                // Remove the clicked filter
                $ctrl.filters[filter.type].splice(index, 1);
              }
            }
          );
          break;

        case 'categories':
        case 'actions':
          $ctrl.filters[filter.type].some(
            function removeFromFilters(item, index) {
              if (item.value === filter.value) {
                // Remove the clicked filter
                $ctrl.filters[filter.type].splice(index, 1);

                // Also remove from the filterOptions used by c-multiselect
                // which don't use ng-model
                $ctrl.filterOptions[filter.type].some(
                  function removeFromFilterOptions(item2, index2) {
                    if (item2.value === filter.value) {
                      $ctrl.filterOptions[filter.type][index2].selected = false;
                      return true;
                    }
                  }
                );

                return true;
              }
            }
          );
          break;
      }
      $ctrl.onFilter();
    }

    /**
     * Onchange users, search for partial matches. Used by ui-select
     *
     * @method     refreshUsers
     * @param      {String}  searchTerm  Search query
     */
    function refreshUsers(searchTerm) {
      if (searchTerm) {
        $ctrl.refreshing = true;
        var paramsObj = {
          search: searchTerm,
          includeComputers: true,
          objectClass: 'kUser',
        };

        ActiveDirectoryService.searchPrincipals(paramsObj).then(
          function searchPrincipalsSuccess(users) {
            users.unshift({
              // Need to add an arbitrary SID for `track by`.
              sid: '1',
              principalName: paramsObj.search,
              fullName: ['"', paramsObj.search, '"'].join(''),
            });
            $ctrl.listOfUsers = _transformUsers(users);
          },
          evalAJAX.errorMessage
        ).finally(
          function searchPrincipalsFinally() {
            $ctrl.refreshing = false;
          }
        );
      }
    }

    /**
     * Transform a users object. If fullName is missing then copy from
     * principalName
     *
     * @method     _transformUsers
     * @param      {object}  users   The users object
     * @return     {object}  the transformed users object
     */
    function _transformUsers(users) {
      return users.map(function mapUsers(user) {
        user.fullName = user.fullName || user.principalName;
        return user;
      });
    }

    /**
     * Download CSV of audit logs
     *
     * @method     downloadCsv
     */
    function downloadCsv() {
      var params = _assembleRequestObj($ctrl.filters);
      params.outputFormat = 'csv';

      $ctrl.downloading = true;

      _getAuditLogs(params, undefined, 'downloading');
    }

    /**
     * Load audit logs with current filters
     *
     * @method     _loadAuditLogs
     */
    function _loadAuditLogs() {
      var params = _assembleRequestObj($ctrl.filters);

      $ctrl.loading = true;

      function successCallback(r) {
        $ctrl.auditLogs = r.clusterAuditLogs;
      }

      _getAuditLogs(params, successCallback, 'loading');
    }

    /**
     * Call API service getAuditLogs
     *
     * @method     _getAuditLogs
     * @param      {object}    params           The request object of filters
     * @param      {Function}  successCallback  The success callback method
     * @param      {string}    loadingProperty  The loading property on scope
     */
    function _getAuditLogs(params, successCallback, loadingProperty) {
      ClusterService.getAuditLogs(params).then(
        successCallback,
        evalAJAX.errorMessage
      ).finally(
        function getAuditLogsFinally() {
          $ctrl[loadingProperty] = false;
        }
      );
    }
  }

})(angular);
