<coh-form-field-object-selector
  cogDataId="recover-to-selector"
  class="margin-top"
  [required]="true"
  [control]="formGroupControls.value"
  [label]="'target' | translate"
  [optionTemplate]="recoverToTemplate"
  [allowSearch]="true"
  [triggerTemplate]="recoverToTemplate"
  optionKey="name"
  [values]="servers">
</coh-form-field-object-selector>

<!-- Template for RecoverTo dropdown -->
<ng-template #recoverToTemplate
  let-value>
  {{value.customName ? value.customName : value.name}}
</ng-template>