<a class="run-misses"
  *ngIf="!isLoading"
  uiSref="protection-group.groups"
  [uiParams]="{ lastRunSlaStatus: missedGroups.length ? 'kMissed' : undefined }">
  <cog-icon iconSize="sm"
    [shape]="missedGroups.length ? 'helix:status-warning!warning' : 'helix:status-success!success'">
  </cog-icon>
  <div class="run-misses-label"
    [class.run-misses-emphasis]="missedGroups.length"
    [ngSwitch]="missedGroups.length">
    <span *ngSwitchCase="0">{{ 'missedSlaStatus.noMisses' | translate }}</span>
    <span *ngSwitchCase="1">{{ 'missedSlaStatus.oneMiss' | translate }}</span>
    <span *ngSwitchDefault>{{ 'missedSlaStatus.nMisses' | translate : { n: missedGroups.length } }}</span>
  </div>
</a>
