import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Controls } from 'ngx-sub-form';
import { Recovery } from '../../model/recovery';

/**
 * Interface for complete migration form inputs.
 */
export interface VmCompleteMigration {
  powerOnTarget?: boolean;
}
/**
 * @description
 * Complete Vm migration dialog.
 */
@Component({
  selector: 'coh-vm-complete-migration-dialog',
  templateUrl: './vm-complete-migration-dialog.component.html',
  styleUrls: ['./vm-complete-migration-dialog.component.scss'],
})
export class VmCompleteMigrationDialogComponent implements OnInit {

  /**
   * Form Group for the complete migration form
   */
  completeVmMigrationForm: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: Recovery,
    private dialogRef: MatDialogRef<VmCompleteMigrationDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.completeVmMigrationForm = new UntypedFormGroup(this.getFormControls());
  }

  /**
   * Get default form controls for complete migration form.
   */
  getDefaultValues(): Partial<VmCompleteMigration> {
    return {
      powerOnTarget: false,
    };
  }

  /**
   * Returns controls for complete migration form.
   * Will have more form controls in the near future.
   */
  getFormControls(): Controls<VmCompleteMigration> {
    return {
      powerOnTarget: new UntypedFormControl(null),
    };
  }
}
