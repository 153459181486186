import { Injectable } from '@angular/core';
import { HeliosNotificationsServiceApi, Notifications } from '@cohesity/api/v2';
import { IrisContextService, isMcm } from '@cohesity/iris-core';
import { BehaviorSubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';


/**
 * This service manages network interactions for
 * Helios notifications for the following -
 * 1. cluster upgrades
 * 2. cluster certificate expiry
 *
 * Please add more as and when {@link Notifications} object
 * is enhanced
 */
@Injectable({
  providedIn: 'root'
})
export class HeliosNotificationService {
  /**
   * Internal construct to hold helios notification info
   */
  private heliosNotificationSubject$ = new BehaviorSubject<Notifications>(null);

  /**
   * API to expose helios notification info.
   */
  heliosNotifications$ = this.heliosNotificationSubject$.asObservable().pipe(
    shareReplay(1)
  );

  constructor(
    private notificationServiceAPI: HeliosNotificationsServiceApi,
    private irisContextService: IrisContextService,
  ) {
    this.init();
  }

  /**
   * Initialize service
   */
  private init() {
    if (isMcm(this.irisContextService.irisContext)) {
      this.fetchNotifications();
    }
  }

  /**
   * Fetch helios notifications
   */
  fetchNotifications() {
    this.notificationServiceAPI.GetHeliosNotifications().subscribe(
      (notificationData) => this.heliosNotificationSubject$.next(notificationData)
    );
  }
}
