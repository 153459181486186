import { Injectable } from '@angular/core';

/**
 * Returns the native window object
 */
function _window(): any {
  return window;
}

/**
 * Returns the native document object
 */
function _document(): any {
  return document;
}

/**
 * Utility services for accessing references to window and document. It's
 * generally preferrably to avoid referencing these objects directly in Angular,
 * since Angular can in theory be outside of a browser. This approach makes it
 * easier to inject different implementations on other platforms should it ever
 * be necessary.
 */
@Injectable({
  providedIn: 'root'
})
export class WindowRef {

  /**
   * Returns a reference to the native window object
   */
  get nativeWindow() {
    return _window();
  }

  /**
   * Returns a reference to the native document object
   */
  get nativeDocument() {
    return _document();
  }

  /**
   * A cross-browser method for creating an event. This will work in IE11 and modern browsers.
   *
   * @param   eventName   Name of the event to create
   */
  createEvent(eventName: string, type = 'Event') {
    let newEvent;
    const event = this.nativeWindow[type];

    // Modern browsers will allow events to be created with new
    if (typeof(event) === 'function') {
      newEvent = new event(eventName);
    } else {
      // On IE 11, fall back to using createEvent and initEvent;
      newEvent = document.createEvent(type);
      newEvent['init' + type](eventName, true, true);
    }
    return newEvent;
  }

  /**
   * Create and dispatch a window resize event.
   */
  dispatchResize() {
    this.nativeWindow.dispatchEvent(this.createEvent('resize', 'UIEvent'));
  }


  /**
   * Open the external link in a new tab with noopener and noreferrer flag set.
   *
   * @param link The external link.
   */
  openExternalLink = (link: string) => {
    this.nativeWindow.open(link, '_blank', 'noopener noreferrer');
  };
}
