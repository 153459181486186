import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { SecurityCriteriaResultList } from '@cohesity/api/secops';
import { AutoDestroyable } from '@cohesity/utils';
import { ObservableInput } from 'ngx-observable-input';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { SecurityCenterDashboardService } from '../../../services';

/**
 * Exposes an interface for top issues widget.
 */
export interface TopIssues {
  /**
   * Map of issue with its count
   */
  issueMap: IssueMap;

  /**
   * total number of clusters scanned.
   */
  totalClusterCount: number;
}

/**
 * The data to be displayed in table
 */
export interface TableData {
  issueList: SecurityCriteriaResultList;
  totalClusterCount: number;
}

/**
 * Map of issue against its count
 */
export interface IssueMap {[key: string]: number}

/**
 * @description
 * Top issues component the top issues found in anti ransomware scans.
 *
 * @example
 *   <dg-sc-top-issues [data]="data"></dg-sc-top-issues>
 */
@Component({
  selector: 'dg-sc-top-issues',
  templateUrl: './top-issues.component.html',
  styleUrls: ['./top-issues.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopIssuesComponent extends AutoDestroyable implements OnInit{
  /**
   * Bar chart series data.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput() @Input('data') data$: BehaviorSubject<TableData>;

  /**
   * Loading state of the card.
   */
  @Input() loading = false;

  /**
   * The data to be displayed in table
   */
  tableData: TopIssues;

  constructor(
    readonly dashboardService: SecurityCenterDashboardService
  ) {
    super();
  }

  ngOnInit() {
    this.data$.pipe(
      filter(data => !!data?.issueList?.securityCriteriaResultList),
      this.untilDestroy()
    ).subscribe(data => {
      this.tableData = this.createTableData(data);
    });
  }

  /**
   * Create Data to be displayed in table
   *
   * @param data The scanned issues data to be converted
   * @returns The data to be displayed in table
   */
  createTableData(data: TableData): TopIssues {
    const issueMap: IssueMap = {};

    const failedScans = data?.issueList?.securityCriteriaResultList?.filter(issue => issue.scanResult === 'Failed');
    failedScans.forEach(issue => {
      if (!issueMap[issue.description]) {
        issueMap[issue.description] = 1;
      } else {
        issueMap[issue.description] += 1;
      }
    });

    const sortedIssueMap = Object.fromEntries(
      Object.entries(issueMap).sort(([,a],[,b]) => b-a)
    );

    return {...{issueMap: sortedIssueMap}, ...{totalClusterCount: data?.totalClusterCount}};
  }

  /**
   * Return List of found issues
   */
  get issues(): string[] {
    return Object.keys(this.tableData.issueMap);
  }
}
