import { Pipe, PipeTransform } from '@angular/core';
import { envGroups, Environment } from '@cohesity/iris-shared-constants';

import { ObjectSearchResult } from '../../model/object-search-result';
import { RestoreSearchResult } from '../../model/restore-search-result';

/**
 * Shows an object icon for a restore search result object.
 * This doesn't support all types yet, nas, phsyical, and vm types so far.
 */
@Pipe({
  name: 'searchObjectIcon'
})
export class SearchObjectIconPipe implements PipeTransform {

  transform(object: RestoreSearchResult): string {
    if (envGroups.office365.includes(object.environment as Environment)) {
      return 'helix:group-application';
    }
    if (envGroups.nas.includes(object.environment as Environment)) {
      return 'helix:object-volume';
    }
    if (envGroups.physical.includes(object.environment as Environment)) {
      if (object.objectType === 'kWindowsCluster') {
        return 'helix:object-cluster';
      }
      return 'helix:object-server';
    }
    if (object.environment === 'kView') {
      return 'helix:view';
    }
    if (object.resultType === ObjectSearchResult.objectResultType && object.recoveryOsType) {
      // Casting this to any, because the api types do not appear to match what
      // is actually being returned.
      switch (object.recoveryOsType as any) {
        case 'Linux':
        case 'kLinux':
          return 'helix:object-vm-linux';
        case 'Windows':
        case 'kWindows':
          return 'helix:object-vm-windows';
        case 'Aix':
        case 'kAix':
          return 'helix:object-vm-aix';
        case 'Solaris':
        case 'kSolaris':
          return 'helix:object-vm-solaris';
      }
      return 'helix:object-vm';
    }

    if (envGroups.vms.includes(object.environment as Environment)) {
      return 'helix:object-vm';
    }

    return 'helix:object-generic';
  }
}
