/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityApiAlertStreamConfiguration as __Configuration } from '../cohesity-api-alert-stream-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GetStreams } from '../models/get-streams';
import { StreamCreate } from '../models/stream-create';
import { StreamCreateParams } from '../models/stream-create-params';
import { StreamUpdateParams } from '../models/stream-update-params';
import { GetAlerts } from '../models/get-alerts';
import { PaginationToken } from '../models/pagination-token';
@Injectable({
  providedIn: 'root',
})
class StreamsService extends __BaseService {
  static readonly GetStreamsPath = '/streams';
  static readonly CreateStreamPath = '/streams';
  static readonly DeleteStreamByIdPath = '/streams/{id}';
  static readonly UpdateStreamByIdPath = '/streams/{id}';
  static readonly PerformActionOnStreamPath = '/streams/{id}/actions';
  static readonly GetAlertsOnStreamPath = '/{streamId}/alerts';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Returns a stream information.
   *
   * Returns a list of streams based on the defined
   * filter criteria.
   * @param params The `StreamsService.GetStreamsParams` containing the following parameters:
   *
   * - `targetTypes`: Specifies Target Types to filter the stream response. For now only target type crowdstrike is supported.
   *
   * - `statuses`: Specifies statuses to filter the stream response. By default it will return for all the statuses.
   *
   * - `ids`: Specifies the IDs to filter stream response.
   *
   * @return Specifies the response of get stream.
   */
  GetStreamsResponse(params: StreamsService.GetStreamsParams): __Observable<__StrictHttpResponse<GetStreams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.targetTypes || []).forEach(val => {if (val != null) __params = __params.append('targetTypes', val.toString())});
    (params.statuses || []).forEach(val => {if (val != null) __params = __params.append('statuses', val.toString())});
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/streams`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetStreams>;
      })
    );
  }
  /**
   * Returns a stream information.
   *
   * Returns a list of streams based on the defined
   * filter criteria.
   * @param params The `StreamsService.GetStreamsParams` containing the following parameters:
   *
   * - `targetTypes`: Specifies Target Types to filter the stream response. For now only target type crowdstrike is supported.
   *
   * - `statuses`: Specifies statuses to filter the stream response. By default it will return for all the statuses.
   *
   * - `ids`: Specifies the IDs to filter stream response.
   *
   * @return Specifies the response of get stream.
   */
  GetStreams(params: StreamsService.GetStreamsParams): __Observable<GetStreams> {
    return this.GetStreamsResponse(params).pipe(
      __map(_r => _r.body as GetStreams)
    );
  }

  /**
   * Creates a Stream.
   *
   * Creates a new stream within the Helios service to enable
   * incremental push/pull of alerts to different targets. It
   * consists of Name, TargetDetail TargetSpecificOptions etc.
   * @param body Specifies the parameters to create a stream.
   * @return Specifies the response of create stream.
   */
  CreateStreamResponse(body: StreamCreateParams): __Observable<__StrictHttpResponse<StreamCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/streams`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StreamCreate>;
      })
    );
  }
  /**
   * Creates a Stream.
   *
   * Creates a new stream within the Helios service to enable
   * incremental push/pull of alerts to different targets. It
   * consists of Name, TargetDetail TargetSpecificOptions etc.
   * @param body Specifies the parameters to create a stream.
   * @return Specifies the response of create stream.
   */
  CreateStream(body: StreamCreateParams): __Observable<StreamCreate> {
    return this.CreateStreamResponse(body).pipe(
      __map(_r => _r.body as StreamCreate)
    );
  }

  /**
   * Deletes a Stream by ID.
   *
   * Deletes a stream using its ID.
   * @param id Specifies the ID of the Stream.
   */
  DeleteStreamByIdResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/streams/${encodeURIComponent(id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes a Stream by ID.
   *
   * Deletes a stream using its ID.
   * @param id Specifies the ID of the Stream.
   */
  DeleteStreamById(id: string): __Observable<null> {
    return this.DeleteStreamByIdResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Updates a Stream by ID.
   *
   * Updates Stream's information using its ID.
   * @param params The `StreamsService.UpdateStreamByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the Stream.
   *
   * - `body`: Specifies the parameters to update a Stream.
   *
   * @return Specifies the response of an update stream.
   */
  UpdateStreamByIdResponse(params: StreamsService.UpdateStreamByIdParams): __Observable<__StrictHttpResponse<StreamCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/streams/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<StreamCreate>;
      })
    );
  }
  /**
   * Updates a Stream by ID.
   *
   * Updates Stream's information using its ID.
   * @param params The `StreamsService.UpdateStreamByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the Stream.
   *
   * - `body`: Specifies the parameters to update a Stream.
   *
   * @return Specifies the response of an update stream.
   */
  UpdateStreamById(params: StreamsService.UpdateStreamByIdParams): __Observable<StreamCreate> {
    return this.UpdateStreamByIdResponse(params).pipe(
      __map(_r => _r.body as StreamCreate)
    );
  }

  /**
   * Performs an action on the given Stream.
   *
   * Performs actions (Pause or Resume) on a given Stream.
   * @param params The `StreamsService.PerformActionOnStreamParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the Stream.
   *
   * - `actionType`: Specifies the action to be performed on Stream.
   */
  PerformActionOnStreamResponse(params: StreamsService.PerformActionOnStreamParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.actionType != null) __params = __params.set('actionType', params.actionType.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/streams/${encodeURIComponent(params.id)}/actions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Performs an action on the given Stream.
   *
   * Performs actions (Pause or Resume) on a given Stream.
   * @param params The `StreamsService.PerformActionOnStreamParams` containing the following parameters:
   *
   * - `id`: Specifies the ID of the Stream.
   *
   * - `actionType`: Specifies the action to be performed on Stream.
   */
  PerformActionOnStream(params: StreamsService.PerformActionOnStreamParams): __Observable<null> {
    return this.PerformActionOnStreamResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Return alerts on the given Stream.
   *
   * Return a list of alerts for a given stream id. It depends upon state
   * of stream as well.
   * @param params The `StreamsService.GetAlertsOnStreamParams` containing the following parameters:
   *
   * - `streamId`: Specifies the ID of the Stream.
   *
   * - `paginationToken`: Specifies the pagination token used to get next page.
   *
   * - `pageSize`: Specifies the maximum number of file paths returned per response and it should be less that 1000.
   *
   * @return Specifies the response of alerts Response.
   */
  GetAlertsOnStreamResponse(params: StreamsService.GetAlertsOnStreamParams): __Observable<__StrictHttpResponse<GetAlerts>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.paginationToken;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/${encodeURIComponent(params.streamId)}/alerts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetAlerts>;
      })
    );
  }
  /**
   * Return alerts on the given Stream.
   *
   * Return a list of alerts for a given stream id. It depends upon state
   * of stream as well.
   * @param params The `StreamsService.GetAlertsOnStreamParams` containing the following parameters:
   *
   * - `streamId`: Specifies the ID of the Stream.
   *
   * - `paginationToken`: Specifies the pagination token used to get next page.
   *
   * - `pageSize`: Specifies the maximum number of file paths returned per response and it should be less that 1000.
   *
   * @return Specifies the response of alerts Response.
   */
  GetAlertsOnStream(params: StreamsService.GetAlertsOnStreamParams): __Observable<GetAlerts> {
    return this.GetAlertsOnStreamResponse(params).pipe(
      __map(_r => _r.body as GetAlerts)
    );
  }
}

module StreamsService {

  /**
   * Parameters for GetStreams
   */
  export interface GetStreamsParams {

    /**
     * Specifies Target Types to filter the stream response. For now only target type crowdstrike is supported.
     */
    targetTypes?: Array<'CrowdStrike' | 'Splunk' | 'IBMQradar' | 'MicrosoftSentinal' | 'ServiceNow'>;

    /**
     * Specifies statuses to filter the stream response. By default it will return for all the statuses.
     */
    statuses?: Array<'Paused' | 'Active' | 'Failed'>;

    /**
     * Specifies the IDs to filter stream response.
     */
    ids?: Array<string>;
  }

  /**
   * Parameters for UpdateStreamById
   */
  export interface UpdateStreamByIdParams {

    /**
     * Specifies the ID of the Stream.
     */
    id: string;

    /**
     * Specifies the parameters to update a Stream.
     */
    body: StreamUpdateParams;
  }

  /**
   * Parameters for PerformActionOnStream
   */
  export interface PerformActionOnStreamParams {

    /**
     * Specifies the ID of the Stream.
     */
    id: string;

    /**
     * Specifies the action to be performed on Stream.
     */
    actionType: 'Pause' | 'Resume';
  }

  /**
   * Parameters for GetAlertsOnStream
   */
  export interface GetAlertsOnStreamParams {

    /**
     * Specifies the ID of the Stream.
     */
    streamId: string;

    /**
     * Specifies the pagination token used to get next page.
     */
    paginationToken?: PaginationToken;

    /**
     * Specifies the maximum number of file paths returned per response and it should be less that 1000.
     */
    pageSize?: number;
  }
}

export { StreamsService }
