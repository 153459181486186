import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { BaseProtectionBuilderComponent } from '../../../../protection-builder/components/base-protection-builder/base-protection-builder.component';

interface BackupType {
  createAmi: boolean;
  amiCreationFrequency?: number;
}

/**
 * Default value for backup type.
 */
export const DefaultBackupType = {
  createAmi: false,
};

@Component({
  selector: 'coh-settings-list-backup-type',
  templateUrl: './settings-list-backup-type.component.html',
  styleUrls: ['./settings-list-backup-type.component.scss']
})

export class SettingsListBackupTypeComponent
  extends BaseProtectionBuilderComponent<BackupType, any> {

  /**
   * Default value for create ami.
   */
  _value: BackupType = DefaultBackupType;

 /**
  * Form group for backup-type.
  */
  backupTypeFormGroup = new UntypedFormGroup({
    createAmi: new UntypedFormControl(this.value.createAmi, Validators.required),
  });

 /**
  * Add the backup-type form group as control.
  */
  addFormControl() {
    this.formGroup.addControl(this.name, this.backupTypeFormGroup);
  }

  /**
   * Form control init.
   */
  initFormControl() {
    this.backupTypeFormGroup.get('createAmi').valueChanges.subscribe(value => {
      if (value) {
        this.backupTypeFormGroup.addControl(
          'amiCreationFrequency', new UntypedFormControl(1, Validators.required)
        );
      } else {
        this.backupTypeFormGroup.removeControl('amiCreationFrequency');
      }
    });

    if (this.protectionGroup?.createAmi) {
      this.value = {
        createAmi: Boolean(this.protectionGroup.createAmi),
      };

      // Set AMI Creation Frequency only if createAmi is true
      if (this.value.createAmi) {
        this.value.amiCreationFrequency = Number(this.protectionGroup.amiCreationFrequency) || 1;
      }

      // Add AMI Creation Frequency Control only if it has a value
      if (this.value.amiCreationFrequency) {
        this.backupTypeFormGroup.addControl(
          'amiCreationFrequency', new UntypedFormControl(1, Validators.required)
        );
      }

      this.formControl.setValue(this.value);
    }
  }
}
