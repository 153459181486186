import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ActionsMenuModule, DataIdModule, IconModule, TableModule } from '@cohesity/helix';
import { ClearSubscriptions } from '@cohesity/utils';
import { TranslateModule } from '@ngx-translate/core';
import { ObservableInput } from 'ngx-observable-input';
import { BehaviorSubject, Observable } from 'rxjs';

import { AccessTableRow, AccessTableRowAction, AccessTableRowActionId } from '../../models';
import { ResourceSelectionDetailsComponent } from '../resource-selection-details/resource-selection-details.component';

/**
 * Model for row action
 */
interface RowAction {
  /**
   * Action ID
   */
  id: AccessTableRowActionId;

  /**
   * Label for action
   */
  displayName: string;

  /**
   * Indicates whether action should be shown
   */
  hide?: boolean;
}

@Component({
  standalone: true,
  imports: [
    ResourceSelectionDetailsComponent,
    ActionsMenuModule,
    CommonModule,
    DataIdModule,
    IconModule,
    MatButtonModule,
    TableModule,
    TranslateModule,
  ],
  selector: 'coh-object-access-roles-table',
  templateUrl: './object-access-roles-table.component.html',
  styleUrls: ['./object-access-roles-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectAccessRolesTableComponent extends ClearSubscriptions implements OnInit {
  /**
   * Data to render in the table.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput([]) @Input('data') data$: Observable<AccessTableRow[]>;

  /**
   * Disable actions for readonly user
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @ObservableInput(false) @Input('isReadOnlyUser') isReadOnlyUser$: Observable<boolean>;

  /**
   * Emits when a user takes an action on a table row.
   */
  @Output() rowAction = new EventEmitter<AccessTableRowAction>();

  /**
   * Columns in the table.
   */
  readonly columns = ['roleName', 'description', 'actions'];

  /**
   * Collection of possible actions on a row.
   */
  readonly allActions: RowAction[] = [
    { id: AccessTableRowActionId.edit, displayName: 'edit' },
    { id: AccessTableRowActionId.delete, displayName: 'delete' },
  ];

  visibleRowActions$ = new BehaviorSubject<RowAction[]>(this.allActions);

  /**
   * Returns a filtered list of actions based on the current user's permissions.
   *
   * @returns The filtered list of actions.
   */
  getRowActions(isReadOnlyUser: boolean): RowAction[] {
    const validActionIds = isReadOnlyUser ? [AccessTableRowActionId.edit] : this.allActions.map(action => action.id);
    return this.allActions.filter(action => validActionIds.includes(action.id));
  }

  ngOnInit() {
    this.subscriptions.push(
      this.isReadOnlyUser$.subscribe(isReadOnly => {
        this.visibleRowActions$.next(this.getRowActions(isReadOnly));
      })
    );
  }
}
