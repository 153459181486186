<section class="timeline" *ngFor="let day of days">
  <div class="timeline-day">
    <h6>
      {{ day | cogDate: 'longDate' }} ({{ humanizeDaysAgo(day) }})
    </h6>
    <div class="activity-timeline-header" *ngIf="headerTemplate">
      <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    </div>
    <div class="timeline-time" *ngFor="let minute of dayMinutes[day]">
      <div class="time-text">{{ minute | cogDate: 'shortTime' }}</div>
      <ng-container
        *ngTemplateOutlet="itemsTemplate; context: { $implicit: (groups$ | async)?.get(day).get(minute) }">
      </ng-container>
    </div>
  </div>
</section>
