import { Injectable } from '@angular/core';
import { FilesApiService } from '@cohesity/api/private';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CreateShareDialogParam } from 'src/app/modules/views/components';
import {
  DocumentsResponse,
  DocumentStat,
  FileStatMap,
  FileTypeMap,
  FolderBrowserCookies,
  FolderBrowserProvider,
} from 'src/app/shared';

@Injectable({ providedIn: 'root' })
export class ViewFileBrowserService implements FolderBrowserProvider {
  /**
   * The data required for listDirectoryFn to work.
   */
  data: CreateShareDialogParam;

  /**
   * Whether to use Volumes. This parameter is set to true in initData, only
   * to make mocking of the root folder possible. We are not making any request
   * to get Volumes or use them. listFiles is continued to be used for listing
   * out files in the root directory and its sub-directories.
   */
  usesVolumes: boolean;

  constructor(private filesApi: FilesApiService) {}

  /**
   * Sets the data from the calling component.
   *
   * @param data The data.
   */
  initData(data: CreateShareDialogParam) {
    this.data = data;
    this.usesVolumes = false;
  }

  /**
   * Look up stats for a file, to check if it exists and what kind of file it is.
   *
   * @param fullPath  The full path to check
   * @returns  An observable with the DocumentStat response.
   */
  statFile(fullPath: string): Observable<DocumentStat> {
    return this.filesApi.getFileStat({
      filePath: fullPath,
      viewName: this.data.viewName,
      viewBoxId: this.data.storageDomainId,
    })
    .pipe(
      map(({fstatInfo}) => ({
        type: FileStatMap[fstatInfo.type],
        size: fstatInfo.size,
        lastModifiedUsecs: fstatInfo.mtimeUsecs,
      }))
    );
  }

  /**
   * The function to get the folders inside the view.
   *
   * @param   fullPath   The full path to browse to, starting with the volume name.
   */
  listFiles(
    fullPath: string,
    fetchMore: boolean = false,
    splitPath: string[],
    cookies?: FolderBrowserCookies,
    maxEntries?: number
  ): Observable<DocumentsResponse> {
    // Check for the cookie if we are loading more entries.
    const cookie = (fetchMore && cookies.directoryCookie) || null;

    return this.filesApi.getDirectoryList({
      statFileEntries: false,
      useLibrarian: true,
      dirPath: fullPath,
      viewName: this.data.viewName,
      viewBoxId: this.data.storageDomainId,
      cookie: cookie,
      maxEntries: maxEntries,
    })
      .pipe(
        map(res => ({
          cookie: res.cookie,
          documents: res.entries.map(file => ({
            name: file.name,
            fullPath: file.fullPath,
            type: FileTypeMap[file.type],
            size: file.fstatInfo?.size,
            lastModifiedUsecs: file.fstatInfo?.mtimeUsecs,
          })),
        }))
      );
  }

  /**
   * Function to call if we need to list the volumes.
   * This is just a mock to return the root folder. We are not actually
   * making any API calls to get the list of volumes present.
   */
  listVolumes(): Observable<DocumentsResponse> {
    return of({
      cookie: 'foo',
      documents: [{
        name: '/',
        fullPath: '/',
        volume: '/',
        type: 'Volume'
      }]
    });
  }
}
