import { Directive, HostListener, Input } from '@angular/core';

/**
 * Directive to stop click event from propagation to parent element.
 *
 * @example
 *   <mat-tab-group cohStopPropagation (selectedTabChange)="onTabChanged($event)">
 *     <mat-tab>Tab</mat-tab>
 *   </mat-tab-group>
 */
@Directive({
  selector: '[cohStopPropagation]',
})
export class StopPropagationDirective {

  /**
   * Array of target class names to stop event propagation to parent.
   *
   * @example
   *   This will only stop event propagation for elements within mat-tab-group containing
   *   'mat-tab-label' and 'mat-tab-label-content' classes.
   *
   *   <mat-tab-group [cohStopPropagation]="['mat-tab-label', 'mat-tab-label-content']">
   *     Link
   *   </mat-tab-group>
   */
  @Input('cohStopPropagation') targetClasses = [];

  /**
   * Prevents click event from propagation to parent if the event target
   * contains certain class names.
   *
   * Include an empty string in list of class names if you want to block click
   * events on elements with no classes.
   *
   * Pass an empty list if you want to block every click event.
   *
   * @param   event   Click Event
   */
  @HostListener('click', ['$event']) onClick(event: Event) {
    const { classList } = event.target as Element;
    let stopPropagationForThisClick = false;

    if (!this.targetClasses.length) {
      // Stop propagation for any captured click because no whitelist provided.
      stopPropagationForThisClick = true;
    } else if (!classList.length) {
      // The clicked element has no classes.
      if (this.targetClasses.includes('')) {
        // Stop Propagation because whitelist includes empty string.
        stopPropagationForThisClick = true;
      }
    } else {
      // Stop Propagation if element has a class found in whitelist.
      stopPropagationForThisClick = this.targetClasses.some(className => classList.contains(className));
    }

    if (stopPropagationForThisClick) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
