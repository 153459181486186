// Component: Policies Mini View Component

;(function(angular, undefined) {
  'use strict';

  var modName = 'C.tenants';
  var componentName = 'policiesMiniView';

  var configOptions = {
    bindings: {
      // @type  {Array}  jobs   List of selected jobs
      jobs: '=',

      // @type  {Array}  policies   List of selected policies
      policies: '=',

      /**
       * Optional provide the inViewMode flag and if true then don't allow
       * polices removal
       *
       * @type  {Boolean}  [inViewMode=undefined]
       */
      inViewMode: '<?',

      /**
       * Optional provide a callback fn used to determine does selected policy
       * to be removed from provided list of policies or just mark it for
       * removal by setting policy._removed = true
       *
       * @type  {Function}
       */
      markPolicyForRemoval: '&?',

      /**
       * Optional provide a text key shown when policy is marked from removal.
       *
       * @type  {String}
       */
      policyRemovalWarningKey: '@?',
    },
    controller: 'PoliciesMiniViewCtrl',
    templateUrl: 'app/admin/access-management/tenants/policies-mini-view/policies-mini-view.html',
  };

  /**
   * @ngdoc component
   * @name C.tenants:policiesMiniView
   * @function
   *
   * @description
   * An accordion based component to render the selected policies list along
   * with the list of jobs with the policies.
   *
   * @example
   *
   *  <policies-mini-view
   *    jobs="$ctrl.jobs"
   *    policies="$ctrl.policies">
   *  </view-more-button>
   */
  angular.module(modName)
    .controller('PoliciesMiniViewCtrl', policiesMiniViewCtrlFn)
    .component(componentName, configOptions);

  function policiesMiniViewCtrlFn(_, $attrs, PolicyService) {

    var $ctrl = this;
    var oldJobs;
    var oldPolicies;

    _.assign($ctrl, {
      removePolicy: removePolicy,
      openEditPolicyModal: PolicyService.openEditPolicyModal,
      undoRemove: undoRemove,

      // Component life-cycle methods.
      $doCheck: $doCheck,
    });

    /**
     * On bindings update the policies with selected jobs
     *
     * @method   $doCheck
     */
    function $doCheck() {
      // early exit when polices & job not changed
      if (oldJobs === $ctrl.jobs && oldPolicies === $ctrl.policies) {
        return;
      }

      var jobsIdsMap = _.keyBy($ctrl.jobs, 'id');

      oldJobs = $ctrl.jobs;
      oldPolicies = $ctrl.policies;

      $ctrl.policies.forEach(function forEachPolicy(policy) {
        policy._selectedJobs = (policy._jobs || []).filter(
          function eachJob(job) {
            return jobsIdsMap[job.id];
          }
        );

        policy._associatedJobsCount = (policy._selectedJobs || []).length +
          (policy._assignedJobs || []).length;
      });
    }

    /**
     * reset policy marked for removal
     *
     * @method   undoRemove
     */
    function undoRemove(policy) {
      policy._removed = false;
    }

    /**
     * Remove the policy
     *
     * @param    {Object}   policy     The policy to remove
     * @method   $removePolicy
     */
    function removePolicy(policy) {
      // mark policy for removal when provided markPolicyForRemoval fn returned
      // true used to keep the policy in the view w/o removing it from the list
      if ($attrs.hasOwnProperty('markPolicyForRemoval') &&
        $ctrl.markPolicyForRemoval({policy: policy})) {
        policy._removed = true;
        return;
      }

      var removedPolicies = _.remove($ctrl.policies, ['id', policy.id]);

      removedPolicies.forEach(function forEachPolicy(removedPolicy) {
        removedPolicy._selectedJobs.forEach(
          function eachJob(job) {
            _.remove($ctrl.jobs, ['id', job.id]);
          }
        );
      });
    }
  }

})(angular);
