import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Api } from '@cohesity/api/private';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { AutoDestroyable } from '@cohesity/utils';
import { PassthroughOptionsService } from 'src/app/core/services';

/**
 * Component to query entitiesOfType API to return entities of certain types
 * and return values based on passed params.
 *
 * These are all the filters entitiesOfType API supports:
 * rootEntityId
 * parentEntityId
 * environmentTypes
 * vmwareEntityTypes
 * physicalEntityTypes
 * viewEntityTypes
 * pureEntityTypes
 * azureEntityTypes
 * awsEntityTypes
 * netappEntityTypes
 * genericNasEntityTypes
 * hypervEntityTypes
 * acropolisEntityTypes
 * kvmEntityTypes
 * sqlEntityTypes
 * office365EntityTypes
 * gcpEntityTypes
 * adEntityTypes
 * agentEntityTypes
 * flashBladeEntityTypes
 * gpfsEntityTypes
 * hyperflexEntityTypes
 * isilonEntityTypes
 * oracleEntityTypes
 * isProtected
 *
 * multiTenancyFilterParams can be given for filtering when multi-tenancy is
 * enabled.
 */
@Component({
  selector: 'coh-entities-of-type-selector',
  templateUrl: './entities-of-type-selector.component.html',
  styleUrls: ['./entities-of-type-selector.component.scss'],
})
export class EntitiesOfTypeSelectorComponent
  extends AutoDestroyable implements OnChanges {
  /**
   * Form control for entities of type selector.
   */
  @Input() control: UntypedFormControl;

  /**
   * Whether the field is disabled.
   */
  @Input() disabled: boolean;

  /**
   * Label for the entities of type selector.
   */
  @Input() label: string;

  /**
   * Params to pass to entities of type API.
   */
  @Input() params: {
    [param: string]: string | string[];
  };

  /**
   * Value for parent entity ID. Either this or rootEntityId must be provided.
   */
  @Input() parentEntityId: string;

  /**
   * Value for root entity ID. Either this or parentEntityId must be provided.
   */
  @Input() rootEntityId: string;

  /**
   * Template for select trigger.
   */
  @Input() triggerTemplate: TemplateRef<any>;

  /**
   * Template for option.
   */
  @Input() optionTemplate: TemplateRef<any>;

  /**
   * Optional. Whether the field is required.
   */
  @Input() required = false;

  /**
   * Whether an API call is in progress.
   */
  loading = false;

  /**
   * An observable stream of all values.
   */
  values$ = new BehaviorSubject([]);

  /**
   * Initial value for the control.
   */
  initialValue: any;

  constructor(
    private httpClient: HttpClient,
    private passthroughOptionsService: PassthroughOptionsService,
  ) {
    super();
  }

  /**
   * Component on change.
   */
  ngOnChanges(changes: SimpleChanges) {
    this.initialValue = this.initialValue || this.control.value;
    this.control.disable();

    if (!this.params) {
      return;
    }

    if (changes.rootEntityId || changes.parentEntityId) {
      this.control.setValue(null);
      this.values$.next([]);
      this.cleanUpSubscriptions();
    }

    const params = {...this.params};

    if (this.rootEntityId) {
      params.rootEntityId = this.rootEntityId;
    }

    if (this.parentEntityId) {
      params.parentEntityId = this.parentEntityId;
    }

    this.loading = true;
    this.httpClient.get(Api.private('entitiesOfType'), {
      params,
      headers: this.passthroughOptionsService.requestHeaders,
    }).pipe(
      this.untilDestroy(),
      finalize(() => this.loading = false)
    ).subscribe((value: any) => {
      if (!this.disabled) {
        this.control.enable();
      }

      this.values$.next(value);

      if (this.initialValue) {
        // Select a value by default if an initial value is provided
        const foundItem = value.find(
          item => item.id === this.initialValue.id
        );

        if (foundItem) {
          this.control.setValue(foundItem);
        }
      }
    });
  }
}
