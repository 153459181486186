<div *ngIf="!cloneList?.length" class="no-data-container">
  <coh-blank-card
    type="clone"
    message="{{ 'deleteSdNoClones'| translate }}"
    ></coh-blank-card>
</div>

<div *ngIf="cloneList?.length" class="stepper-item-list margin-top margin-bottom">
  <div *ngFor="let clone of cloneList" class="c-media c-media-align-center single-item margin-top padding-bottom-sm">
    <cog-icon class="c-media-object" shape="helix:clone"></cog-icon>
    <div class="c-media-body">
      <div class="flex-truncate-wrapper">
        <div class="flex-truncate-text user-data-xl">
          {{ clone.name }}
        </div>
      </div>
    </div>
  </div>
</div>
