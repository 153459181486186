import { BannerConfig, BannerStatus } from '@cohesity/helix';

/**
 * This method will take the status from the api and return
 * the status that is required by the Banner Component
 *
 * @param apiStatus
 * @returns the transformed results as a BannerStatus
 */
export function mapApiStatusToBannerStatus(apiStatus: string): BannerStatus {
  // Info Warning Error
  switch (apiStatus) {
    case 'Warning':
      return 'warn';
    case 'Error':
      return 'critical';
    default:
      return 'info';
  }
}

/**
 * An enum based on the api response
 */
export enum BannerExpirationType {
  PreExpiration = 'PreExpiration',
  Expired = 'Expired',
  GracePeriod = 'GracePeriod'
}

/**
 * Banner specific crm entitlement data
 */
export interface CrmServiceBannerData {
  /**
   * Indicates if the banner should be shown
   */
  showBanner: boolean;

  /**
   * Indicates the type of the banner
   */
  bannerType: BannerExpirationType;

  /**
   * Indicates the severity of the banner warning
   */
  bannerSeverity: BannerStatus;
}

/**
 * Properties that are derived from the crm entitlement data
 */
export interface DerivedSubscriptionExpirationData {
  /**
   * Indicates if the user's free trial has expired or not.
   */
  trialExpired: boolean;

  /**
   * Number of days remaining in the user's free
   */
  daysRemaining?: number;
}

/**
 * General crm entitlement data
 */
export interface CrmAppEntitlementData {
  /**
   * App for which this banner is being showed
   */
  appName: string;

  /**
   * Whether this entitlement is free trial
   */
  isFreeTrial?: boolean;
}

/**
 * A combination of all the interfaces that makeup
 * the crm subscription and entitlement data
 * consumed by the irisContext observable
 */
export interface CrmSubscriptionEntitlementData
  extends CrmAppEntitlementData, DerivedSubscriptionExpirationData, CrmServiceBannerData { }

/**
 * This interface defines what is required to be returned
 * from the bannerInfo$ observable of each BannerConfig
 */
export type BannerConfigReturnProperties = Omit<BannerConfig, 'title' | 'tooltip' | 'secondaryButtons' | 'location'>;
