<cog-statlist [vertical]="false" class="margin-bottom-lg" valueSize="md" itemSpacing="md" cogDataId="change-type-stats">
  <cog-statlist-item
    *ngFor="let stat of changeTypeStats"
    label="{{ 'argus.enum.glanceBar.changeType.' + stat.changeType | translate }}"
    [cogDataId]="stat.changeType"
    [inheritDataId]="true">
    {{ stat.count | humanizeFromNumbers:1 }}
  </cog-statlist-item>

  <ng-container *ngIf="showPatternsMatched">
    <mat-divider vertical="true"></mat-divider>
    <cog-statlist-item label="{{ 'patternsMatched' | translate }}" cogDataId="patterns-matched" [inheritDataId]="true">
      {{ patternsMatched | humanizeFromNumbers }}
    </cog-statlist-item>
  </ng-container>
</cog-statlist>
