import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { SettingsListModule, FormsModule, DataIdModule } from '@cohesity/helix';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { ReactiveFormsModule } from '@angular/forms';
import { Controls, NgxSubFormRemapComponent, subformComponentProviders, takeUntilDestroyed } from 'ngx-sub-form';
import { fromControlValueChange } from 'src/app/shared/forms';
import { BoolLabelModule } from '@cohesity/utils';
import { TranslateModule } from '@ngx-translate/core';
import { RecoverMailboxesPstFormModel } from 'src/app/modules/restore/office365-shared/mailboxes/model';

/**
 * Settings list item to display pst password field.
 */
@Component({
  standalone: true,
  selector: 'coh-settings-list-password-protection',
  templateUrl: './settings-list-password-protection.component.html',
  providers: subformComponentProviders(SettingsListPasswordProtectionComponent),
  imports: [
    TranslateModule,
    BoolLabelModule,
    SettingsListModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatLegacyInputModule,
    FormsModule,
    ReactiveFormsModule,
    DataIdModule,
  ],
})
export class SettingsListPasswordProtectionComponent extends
  NgxSubFormRemapComponent<string, RecoverMailboxesPstFormModel> implements OnInit {

  /**
   * Alternate Label to use for the control.
   */
  @Input() label: string;

  ngOnInit() {
    fromControlValueChange(this.formGroupControls.pstPasswordEnabled)
    .pipe(takeUntilDestroyed(this))
    .subscribe(value => {
        if (value) {
          this.formGroupControls.pstPassword.setValidators([Validators.required]);
        } else {
          this.formGroupControls.pstPassword.clearValidators();
        }
        this.formGroupControls.pstPassword.updateValueAndValidity();
      }
    );
  }

  getDefaultValues(): RecoverMailboxesPstFormModel {
    return {
      pstPasswordEnabled: false,
      pstPassword: ''
    };
  }

  protected getFormControls(): Controls<RecoverMailboxesPstFormModel> {
    return {
      pstPasswordEnabled: new UntypedFormControl(null, [Validators.required]),
      pstPassword: new UntypedFormControl(null)
    };
  }

  protected transformToFormGroup(): RecoverMailboxesPstFormModel {
    return {
      pstPasswordEnabled: false,
      pstPassword: ''
    };
  }

  protected transformFromFormGroup(formValue: RecoverMailboxesPstFormModel): string {
    return formValue.pstPasswordEnabled ? formValue.pstPassword : '';
  }
}
