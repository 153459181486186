// FORM ELEMENT: cMultiselect
//
// styled multiselect dropdown list
// extends the angular bootstrap UI styled select list
// updates the passed options 'selected' values.
//
// options - json list {value (we're not using this here), name, selected (boolean)}
// buttonLabel - text for update/close button
// instantUpdate - if true, selected values are updated instantly, otherwise only on button push

angular.module('C.multiselect', []).directive('cMultiselect', ['$rootScope', '$timeout', '$log', 'cUtils',
    function($rootScope, $timeout, $log, cUtils) {
        return {
            restrict: 'E',
            scope: {
                customText: '=',
                options: '=',
                onUpdate: '&'
            },
            templateUrl: 'app/global/formElements/c-multiselect.html',
            link: function cMultiSelectLinkFn(scope, elem, attrs) {

                var defaultText = $rootScope.text.cMultiselectDefaultText;

                var updateManualInputValue;

                /** @type {Boolean} used to disable $watch based $scope.option
                                    updates so onUpdate doesn't fire twice */
                var selfUpdateInProgress = false;

                // tracks enable/disable of element
                scope.isDisabled = false;

                // tracks number of selected items
                scope.numSelectedOptions = 0;

                // This watch allows our options to be added async and then we update our
                // queuedOptions to match.
                scope.$watch(function() {
                    return scope.options;
                }, function(newValue, oldValue) {
                    var optionsCount = cUtils.jsObjectPropertyCount(newValue);
                    if (!selfUpdateInProgress && (newValue !== oldValue || optionsCount > 0)) {
                        scope.queuedOptions = angular.copy(scope.options);
                        updateSelected();
                    }

                }, true);

                // watch for ng-disabled value changes on c-multiselect and set our
                // scope boolean accordingly.
                scope.$parent.$watch(attrs.ngDisabled, function(newValue) {
                    scope.isDisabled = newValue;
                });

                // create a placeholder copy of our options for queuing changes if instantUpdate===false
                scope.queuedOptions = angular.copy(scope.options);
                scope.singleItem = attrs.singleItem === "true";
                if (scope.singleItem) {
                    // single items always update instantly
                    scope.instantUpdate = true;
                } else {
                    // if multiselect, users config determines instantUpdate
                    scope.instantUpdate = attrs.instantUpdate === "true";
                }

                // set scope.manualInputEnabled based on attrs
                scope.manualInputEnabled = !!attrs.manualInput;

                // model for manual input value
                scope.manualInputVal = null;

                // watch for manualInputVal changes and fire custom update function
                scope.$watch(
                    'manualInputVal',
                    function manualInputValueChanged(newValue, oldValue) {
                        if (!selfUpdateInProgress && (newValue !== oldValue)) {
                            updateManualInputValue();
                        }
                    },
                    true);

                scope.text = angular.extend(defaultText, scope.customText);
                scope.selectedItemsLabel = scope.singleItem ? scope.text.defaultSelectedSingleItemMessage : scope.text.defaultSelectedItemsMessage;
                scope.selectedItemIcon = undefined;

                /**
                 * responds to click events on individual options
                 * @param  {Integer} index               of the item that was clicked
                 * @param  {Object}  $event              click event object
                 * @param  {Boolean} [optDisabled=false] indicates if no action should be taken
                 * @return {Void}
                 */
                scope.clickItem = function clickItem(index, $event, optDisabled) {

                    // if multiselect, prevent dropdown closure
                    if (!scope.singleItem) {
                        $event.preventDefault();
                        $event.stopPropagation();
                    }

                    if (optDisabled) {
                        return;
                    }

                    // if only a single item is allowed toggle all items to unselected
                    if (scope.singleItem) {
                        angular.forEach(scope.queuedOptions, function(option) {
                            option.selected = false;
                        });
                    }
                    // mark the clicked item as selected
                    scope.queuedOptions[index].selected = !scope.queuedOptions[index].selected;

                    if (scope.instantUpdate || scope.singleItem) {
                        updateSelected();
                    }

                };

                /**
                 * opens the select dropdown
                 * @param  {Object} $event click event Object
                 * @return {Void}
                 */
                scope.openMultiselect = function openMultiselect($event) {
                    // TODO: Hoist these assignments to the top of the linking function?
                    // on second thought, we should gut this and accomplish it via the stylesheet
                    $event.preventDefault();
                    $event.stopPropagation();
                    var parentWidth = $($event.currentTarget).parent('.c-multiselect-list').width();
                    var ddmenu = $($event.currentTarget).next('.dropdown-menu');
                    $(ddmenu).width(parentWidth - 18);
                };

                /**
                 * updates the value of the manualInput object in scope.queuedOptions
                 */
                updateManualInputValue = function updateManualInputValue() {
                    var manualValueExists = false;
                    var manualInputValueIndex;
                    if (scope.manualInputEnabled) {

                        if (scope.manualInputVal) {
                            // if maualInputVal is set, loop though queuedOptions
                            // and update the manualInput value if it exists
                            angular.forEach(scope.queuedOptions, function(option) {
                                if (option.hasOwnProperty('manualInput')) {
                                    manualValueExists = true;
                                    option.name = scope.manualInputVal;
                                    option.value = scope.manualInputVal;
                                    option.selected = true;
                                }
                            });
                            // No manualInput value exists in scope.queuedOptions, so let's add one now
                            if (!manualValueExists) {
                                scope.queuedOptions.push({
                                    name: scope.manualInputVal,
                                    value: scope.manualInputVal,
                                    manualInput: true,
                                    selected: true
                                });
                            }
                        } else {
                            // If there is no manualInputVal,
                            // let's check queuedOptions for objects with property of 'manualInput',
                            // if found, let's remove it because it doesn't belong there any more!
                            angular.forEach(scope.queuedOptions, function(option, index) {
                                if (option.hasOwnProperty('manualInput')) {
                                    manualInputValueIndex = index;
                                }
                            });
                            // Be gone with ye!!
                            scope.queuedOptions.splice(manualInputValueIndex, 1);
                        }

                    }
                    updateSelected();
                };

                // Filters out manual input values for display within ng-repeat
                scope.filterManualObjects = function filterManualObjects(object) {
                    return (!object.hasOwnProperty('manualInput') && !object.manualInput);
                };

                /**
                 * updates the list of selected options, running
                 * callback function if appropriate
                 * @return {Void}
                 */
                var updateSelected = function updateSelected() {
                    var selectedOptions = [];
                    var callbackSelectedOptions = [];
                    var callbackSelectedOption = {};
                    selfUpdateInProgress = true;

                    //copy our queued changes back to the original options object
                    angular.copy(scope.queuedOptions, scope.options);
                    angular.forEach(scope.options, function(option, index) {
                        if (option.selected) {
                            selectedOptions.push(option.name);
                            if (scope.singleItem) {
                                callbackSelectedOption = option;
                            } else {
                                callbackSelectedOptions.push(option);
                            }
                        }
                    });

                    scope.numSelectedOptions = selectedOptions.length;

                    if (selectedOptions.length) {
                        scope.selectedItemsLabel = selectedOptions.join(', ');
                        scope.selectedItemIcon = undefined;
                        if (scope.singleItem && callbackSelectedOption.hasOwnProperty('icon')) {
                            scope.selectedItemIcon = callbackSelectedOption.icon;
                        }
                    } else {
                        scope.selectedItemsLabel = scope.singleItem ? scope.text.defaultSelectedSingleItemMessage : scope.text.defaultSelectedItemsMessage;
                        scope.selectedItemIcon = undefined;
                    }

                    // if callback function was provided, send it the updated list
                    if (typeof scope.onUpdate === 'function') {
                        if (scope.singleItem && callbackSelectedOption.hasOwnProperty('name')) {
                            scope.onUpdate({
                                selectedOption: callbackSelectedOption
                            });
                        } else if (!scope.singleItem) {
                            scope.onUpdate({
                                selectedOptions: callbackSelectedOptions
                            });
                        }
                    }

                    // in a timeout, as the $watch function will pick up
                    // these changes after this function finishes do to
                    // the nature of the digest loop
                    $timeout(function() {
                        selfUpdateInProgress = false;
                    });

                };
            }
        };
    }
]);
