/**
 * Specifies a Cluster Identifier ID which is of the format clusterId:clusterIncarnationId.
 */
export type ClusterIdentifierId = null | string;

interface ClusterObj {
  clusterId?: number;
  clusterIncarnationId?: number;
}

/**
 * Return the cluster identifier id for a given cluster.
 *
 * @param cluster The cluster details having clusterId and clusterIncarnationId.
 * @returns The cluster ClusterIdentifier id.
 */
export const getClusterIdentifier = (obj: ClusterObj): ClusterIdentifierId =>
  (typeof obj?.clusterId === 'number') && typeof obj?.clusterIncarnationId === 'number' ?
    `${obj?.clusterId}:${obj?.clusterIncarnationId}` :
    undefined;


export const parseClusterIdentifier = (clusterIdentifierId: ClusterIdentifierId): ClusterObj => {
  const parts = (clusterIdentifierId || '').split(':').filter(Boolean).map(val => Number(val) || undefined);
  const [clusterId, clusterIncarnationId] = parts.length === 2 ? parts : [undefined, undefined];

  return { clusterId, clusterIncarnationId };
};
