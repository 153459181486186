// Module: Apps Management

import { isAllClustersScope, isClusterScope, isGlobalScope } from '@cohesity/iris-core';

/*
 * AppsManagement module provides athena application management functionality
 */

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.appsManagement', [])
    .config(configFn)
    .constant('APPS_CONST', appsConstants())
    .component('appsManagement', {
      templateUrl: 'app/apps-management/apps-management.html',
      controller: appsManagementCtrlFn,
    });

  function configFn($stateProvider, $urlRouterProvider) {
    let viewAccess = 'APP_LAUNCH && FEATURE_FLAGS.appsManagementEnabled';
    let appsAccess = [
      'APP_LAUNCH',
      '$root.FEATURE_FLAGS.appsManagementEnabled',
      '($root.clusterInfo._allClusters ? $root.basicClusterInfo.mcmMode' +
        ': true)'
    ].join(' && ');

    $stateProvider
      .state('apps-management', {
        url: '^/apps-management',
        help: 'marketplace_apps',
        title: 'Apps Management',
        canAccess: appsAccess,
        allClustersSupport: {
          allClustersState: 'apps-management',
          globalContext: true,
          singleClusterState: 'apps-management',
        },
        component: 'appsManagement',
      })
      .state('apps-management.apps', {
        url: '^/apps-management/apps?{id}&{upgradeApp}',
        help: 'marketplace_apps',
        title: 'Apps',
        canAccess: appsAccess,
        parentState: 'apps-management',
        reloadOnSearch: false,
        params: {
          cid: {dynamic: true},
          id: {
            type: 'cNum',
            dynamic: true,
            squash: true,
          },
          upgradeApp: {
            type: 'cBool',
            value: false,
            dynamic: true,
            squash: true,
            _persistOnImpersonation: true,
          },
        },
        allClustersSupport: {
          allClustersState: 'apps-management.apps',
          singleClusterState: 'apps-management.apps',
          globalContext: true,
        },
        views: {
          'tab-view@apps-management': 'apps',
        }
      })
      .state('apps-management-applaunch', {
        url: '^/apps-management/apps/{appUid}/versions/{version}/launch',
        help: 'marketplace_apps',
        title: 'App Launch',
        canAccess: viewAccess,
        parentState: 'apps-management',
        component: 'appLaunch',
        params: {
          appUid: {type: 'int'},
          version: {type: 'int'},
          appName: {type: 'string'},
          devVersion: {type: 'string'},
        },
      })
      .state('apps-management.instances', {
        url: '^/apps-management/instances',
        help: 'marketplace_apps',
        title: 'App Instances',
        canAccess: viewAccess,
        parentState: 'apps-management',
        allClustersSupport: false,
        views: {
          'tab-view@apps-management': 'instances',
        }
      })
      .state('apps-management-instances', {
        url: '^/apps-management/apps/{appUid}/versions/{version}/instances',
        help: 'marketplace_apps',
        title: 'App Instances',
        canAccess: viewAccess,
        parentState: 'apps-management',
        component: 'instances',
        params: {
          appUid: {type: 'int'},
          version: {type: 'int'},
        },
      })
      .state('apps-management.browse-apps', {
        url: '^/apps-management/browse-apps',
        help: 'marketplace_apps',
        title: 'Browse Apps',
        canAccess: viewAccess,
        parentState: 'apps-management',
        allClustersSupport: {
          allClustersState: 'apps-management.browse-apps',
          singleClusterState: 'apps-management.browse-apps',
          globalContext: true,
        },
        views: {
          'tab-view@apps-management': 'browseApps',
        }
      });
  }

  function appsManagementCtrlFn(_, $rootScope, $state, NgAppsService,
    AppsService, NgIrisContextService) {
    let $ctrl = this;
    let globalMode = isGlobalScope(NgIrisContextService.irisContext);
    let allClustersMode = isAllClustersScope(NgIrisContextService.irisContext);
    let tabConfig = [
      {
        headingKey: 'myApps',
        route: 'apps-management.apps',
      },
      {
        headingKey: 'allInstances',
        route: 'apps-management.instances',
        hidden: allClustersMode,
      },
      {
        headingKey: 'browseApps',
        route: 'apps-management.browse-apps',
        hidden: !(allClustersMode || globalMode),
      },
    ];

    _.assign($ctrl, {
      tabConfig: tabConfig,

      // Component loading state
      loading: false,

      // True if app management is enabled.
      appsEnabled: false,

      // Component life-cycle methods.
      $onInit: $onInit,

      // Methods
      uploadAppPackage: uploadAppPackage,

      get isGlobalOrAllClusterScope() {
        return !isClusterScope(NgIrisContextService.irisContext)
      }
    });

    /**
     * Calls when component is initialized
     *
     * @method   $onInit
     */
    function $onInit() {
      // When working on the all cluster mode, Do not do any additional processing to
      // get app states and navigate to apps-management.
      if ($ctrl.isGlobalOrAllClusterScope &&
        $state.current.name === 'apps-management') {
        $state.go('apps-management.apps');
        return;
      }

      $ctrl.loading = true;
      NgAppsService.fetchAppSettings()
        .toPromise()
        .then(function GotAppSettings(settings) {

          // Open settings page if apps management is not enabled.
          $ctrl.appsEnabled = settings.appsEnabled;
          if (!settings.appsEnabled) {
            $state.go('ng-apps-management');
            return;
          }
        })
        .finally(function DoneAppSettings() {
          $ctrl.loading = false;
        });

      // Set default tab state.
      if ($state.current.name === 'apps-management') {
        $state.go('apps-management.instances');
      }
    }

    /**
     * Show upload modal dialog
     *
     * @method   uploadAppPackage
     */
    function uploadAppPackage() {
      AppsService.launchUploadAppModal().then(function success() {
        $state.reload();
      });
    }
  }

  /**
   * Defines basic constants required for apps-management
   *
   * @method   appsConstants
   */
  function appsConstants() {
    return {
      protectedObjectsPrivilegesType: [
        'kNone',
        'kAll',
        'kSpecific',
      ],
      qosTiers: [
        'kLow',
        'kMedium',
        'kHigh',
        'kMax',
      ],
      viewPrivilegesTypes: [
        'kNone',
        'kAll',
        'kSpecific',
      ],
      instanceSize: [
        'small',
        'medium',
        'large',
      ],
      secCenterIntegratedAppNames: ['Cohesity for CrowdStrike'],
    };
  }
})(angular);
