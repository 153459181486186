import { module } from 'angular';

/**
 * AJS Service to assist with using the vm-browser from within NG code.
 */
export class VmBrowserService {
  constructor(SlideModalService, SearchService) {
    this.slideModalService = SlideModalService;
    this.searchService = SearchService;
  }

  /**
   * Uses VM Search to fetch the vmDocument, given an object and job id.
   *
   * @param {number} objectId
   * @param {number} jobId
   * @param {string} type
   * @returns the vmdocument object.
   */
  fetchVm(objectId, jobId, type = 'vm') {
    return this.searchService
      .vmSearch(
        {
          entityIds: [objectId],
          jobIds: [jobId],
        },
        type,
      )
      .then(vms => vms[0]);
  }

  /**
   * Browse a VM
   *
   * @param      {Object}  [vm]               Optional vm to browse, or cached vm if not provided.
   * @param      {Object}  [lockedSnapshot]   Optional snapshot to lock the browser to.
   */
  browseVm(vm, lockedSnapshot) {
    return this.slideModalService.newModal({
      templateUrl: 'app/global/c-vm-browser/c-vm-browser.html',
      controller: 'cVMBrowserParentController',
      size: 'full',

      // prevent escape key from closing modal
      keyboard: false,
      fullHeight: true,
      resolve: {
        pageConfig: {
          hideSaveAndContinue: true,
          mode: 'file',
          vm: vm,
          lockedSnapshot: lockedSnapshot,
        },
      },
    });
  }
}

/**
 * Configure the service to work with AJS.
 */
var vMBrowser = module('C.VMBrowser');
vMBrowser.service('VmBrowserService', VmBrowserService);
