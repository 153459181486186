import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Api } from '@cohesity/api/private';
import { HeliosRolesServiceApi, HeliosUsersServiceApi, McmApiKeyInfo, Role, User } from '@cohesity/api/v1';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

/**
 * A service for Helios Access Management. The service would facilitate read and
 * write of RBAC data.
 */
@Injectable({ providedIn: 'root' })
export class HeliosAccessManagementService {
  /**
   * Roles async behavior subject.
   */
  private _roles$ = new BehaviorSubject<Role[]>(null);

  /**
   * Expose roles list as an observable.
   */
  readonly roles$: Observable<Role[]> = this._roles$.asObservable();

  /**
   * The currently cached value of roles.
   */
  get roles() {
    return this._roles$.value || [];
  }

  // Maintain a cache of created API Keys as API Keys when created, will return
  // a 'key' property which is only returned once in the POST response of
  // creation and never after. When a user creates an API Key, we land them
  // in the API Key details page with key visible.
  createdApiKeysCache: Map<string, Observable<McmApiKeyInfo>>;

  /**
   *  Refers to the loading status of Roles and Accsess component
   */
  private rolesAndAccessloadingSubject = new BehaviorSubject<boolean>(false);
  rolesAndAccessloading$ = this.rolesAndAccessloadingSubject.asObservable();

  /**
   * Constructor
   *
   * @param    http   Http Client
   */
  constructor(private http: HttpClient,
    private heliosUsersServiceApi: HeliosUsersServiceApi,
    private heliosRolesServiceApi: HeliosRolesServiceApi) {
    this.createdApiKeysCache = new Map<string, Observable<McmApiKeyInfo>>();
  }

  /**
   * Returns the list of MCM User for the current logged in Account.
   *
   * @param params Filter parameters for user list
   * @return   An observable with array of users for account or error.
   */
  getUsers(params?: any): Observable<User[]> {
    return this.heliosUsersServiceApi.GetMcmUsersOp(params || {});
  }

  /**
   * Creates a new MCM User.
   *
   * @param   user   The MCM User Object.
   * @return  An observable with response or error.
   */
  createUser(user: User): Observable<User> {
    const url = Api.mcmPublic('users');
    return this.http.post(url, user);
  }

  /**
   * Updates an existing MCM User.
   *
   * @param   user   The MCM User Object.
   * @return  An observable with response or error.
   */
  updateUser(user: User): Observable<User> {
    const url = Api.mcmPublic('users', user.sid);
    return this.http.put(url, user);
  }

  /**
   * Delete a existing MCM User.
   *
   * @param   userSid   User SID to be deleted.
   * @return  An observable with response or error.
   */
  deleteUser(userSid: string): Observable<any> {
    return this.heliosUsersServiceApi.DeleteMcmUser(userSid, null);
  }

  /**
   * Updates the user consent to be deleted.
   *
   * @param   user   The MCM User Object.
   * @return  An observable with response or error.
   */
  updateDsoUserConsent(user: User): Observable<User> {
    const url = Api.mcm('users/dso-user-consent', user.sid);
    return this.http.put(url, user);
  }

  /**
   * Reset an users password.
   *
   * @param   userSid   User SID that needs to be reset.
   * @return  An observable with response or error.
   */
  resetUserPassword(userSid: string): Observable<any> {
    const url = Api.mcm('users', userSid, 'resetPassword');
    return this.http.post(url, {});
  }

  /**
   * Function to return all the available MCM Role
   *
   * @param forceQuery   Indicates if API call should forced even if there is a cached result.
   * @return             An observable with array of Roles or error
   */
  getRoles(forceQuery = false): Observable<Role[]> {

    if (forceQuery || !this.roles?.length) {
      // Clear any existing roles list so implementations requesting forceQuery
      // will return the newly fetched result instead of a stale set.
      this._roles$.next(null);
      this.heliosRolesServiceApi.GetMcmRolesOp().subscribe(
        roles => this._roles$.next(roles)
      );
    }

    return this.roles$.pipe(
      filter(roles => !!roles),

      // Take only one so this resolves and finalize works in existing implementation
      // points that use finalize() based on the above get call resolving.
      take(1)
    );
  }

  /**
   * Function to return a MCM Role
   *
   * @param   roleName   Role name to be retrieved
   * @return   An observable of the Role or error
   */
  getRole(roleName: string): Observable<Role> {
    return this.heliosRolesServiceApi.GetMcmRoleByNameOp(roleName);
  }

  /**
   * Function to create a new custom MCM  Role
   *
   * @param   role   Role to be created
   * @return   An observable of the Role or error
   */
  addRole(role: Role): Observable<Role> {
    return this.heliosRolesServiceApi.CreateMcmRoleOp(null, role);
  }

  /**
   * Function to update an existing custom MCM  Role
   *
   * @param   role   Role to be edited
   * @return   An observable of the Role or error
   */
  editRole(role: Role): Observable<Role> {
    const roleParams: HeliosRolesServiceApi.UpdateMcmRoleOpParams = {name: role.name, body: role};
    return this.heliosRolesServiceApi.UpdateMcmRoleOp(roleParams);
  }

  /**
   * Function to delete a custom MCM  Role
   *
   * @param   role   Role to be deleted
   * @return   An observable of the Role or error
   */
  deleteRole(role: Role): Observable<any> {
    const url = Api.mcmPublic('roles', role.name);
    return this.http.delete(url);
  }

  /**
   * Function to return all the API Keys belonging to the passed user
   *
   * @param   user   User object
   * @return  An observable with an array of user's API Keys or error
   */
  getApiKeys(user: User): Observable<McmApiKeyInfo[]> {
    return this.heliosUsersServiceApi.McmGetApiKeys(user.sid);
  }

  /**
   * Function to return an API Key belonging to the passed user
   *
   * @param   user   User object
   * @param   apiKeyId   API Key ID to be retrieved
   * @return  An observable with an array of user's API Keys or error
   */
  getApiKey(user: User, apiKeyId: string): Observable<McmApiKeyInfo> {
    if (this.createdApiKeysCache.has(apiKeyId)) {
      // If the API Key was just created, the cache using the POST response was
      // populated which will have the 'key' key in the API Key.
      const cachedApiKey = this.createdApiKeysCache.get(apiKeyId);

      // This key should only be accessible once, so once the user has moved
      // away from the page, it should no longer use the cached value (which
      // will have the 'key' key).
      this.createdApiKeysCache.delete(apiKeyId);

      return cachedApiKey;
    }

    const params: HeliosUsersServiceApi.McmGetApiKeyParams = {sid: user.sid, keyId: apiKeyId};
    return this.heliosUsersServiceApi.McmGetApiKey(params);
  }

  /**
   * Function to add an API Key for the passed user
   *
   * @param   user   User object
   * @param   apiKey   API Key object
   * @return  An observable of an API Key response or error
   */
  addApiKey(user: User, apiKey: McmApiKeyInfo): Observable<McmApiKeyInfo> {
    const url = Api.mcmPublic('users', user.sid, 'apiKeys');
    return this.http.post<McmApiKeyInfo>(url, apiKey).pipe(map(result => {
      // Cache the response from add API Key API call so that it can used to
      // populate the API Key detail page when the user is redirected to that
      // after creation of the API Key. This is the only time when the 'key' key
      // will be present in the response of GET API Key or GET API Keys.
      this.createdApiKeysCache.set(result.id, of(result));

      return result;
    }));
  }

  /**
   * Function to delete an API Key belonging to a user
   *
   * @param   user   User object
   * @param   apiKey   API Key object
   * @return  An observable or error
   */
  deleteApiKey(user: User, apiKey: McmApiKeyInfo): Observable<any> {
    const params: HeliosUsersServiceApi.McmDeleteApiKeyParams = {sid: user.sid, keyId: apiKey.id};
    return this.heliosUsersServiceApi.McmDeleteApiKey(params);
  }

  /**
   * Returns the list of Salesforce contact associated with the account.
   *
   * @return An observable with list of contact or error.
   */
  getSalesforceUsers(): Observable<any> {
    const url = Api.mcmPublic('salesforceUsers');
    return this.http.get(url);
  }

  /**
   *
   * @param loading indicates the current loading status of roles and access component
   */
  setLoading(loading: boolean) {
    this.rolesAndAccessloadingSubject.next(loading);
  }
}
